import type { OAuthProvider } from "@glide/plugins";
import { assertNever } from "@glideapps/ts-necessities";

import { AppKind } from "./app-kind";

const sandboxBuilderMeasurementID = "G-FVHNHKMBR3";
const stagingBuilderMeasurementID = "G-5C1TJQMXGT";
const prodBuilderMeasurementID = "G-MB6SN3XQYK";

interface FirebaseConfig {
    projectId: string;
    apiKey?: string;
    authDomain?: string;
    databaseURL?: string;
    storageBucket?: string;
    appId?: string;
    measurementId?: string;
    messagingSenderId?: string;
}

const FirebaseProdConfigGlide: FirebaseConfig = {
    apiKey: "AIzaSyCdlydoa5aGY9eVkZODhJFbD_lx_q7Pmks",
    authDomain: "glide-prod.firebaseapp.com",
    projectId: "glide-prod",
    databaseURL: "https://glide-prod.firebaseio.com",
    storageBucket: "glide-prod.appspot.com",

    // Builder Analytics
    appId: "1:397046215911:web:1069e1411afebe99fa5e42",
    measurementId: prodBuilderMeasurementID,

    // Keep this in sync with the corresponding service worker file
    messagingSenderId: "397046215911",
};

const FirebaseStagingConfigGlide = {
    apiKey: "AIzaSyB2dCXFckok-ArS35WdSlsYKHkwpfQEeIo",
    authDomain: "glide-staging.firebaseapp.com",
    projectId: "glide-staging",
    databaseURL: "https://glide-staging.firebaseio.com",
    storageBucket: "glide-staging.appspot.com",

    // Builder Analytics
    appId: "1:707347437040:web:99b2cf5e6eddb7c226f935",
    measurementId: stagingBuilderMeasurementID,
    messagingSenderId: "707347437040",
};

// this configuration comes from Firebase `Project Settings` tab, "Web apps", in the case of `sandbox` located here:
// https://console.firebase.google.com/u/0/project/glide-sandbox-301022/settings/general/web:MDBiOTg3MTEtODYxNC00ODk0LWExOGEtZmIyZmY5NDAzNzQ4
const FirebaseSandboxConfigGlide = {
    apiKey: "AIzaSyBkY03XRIYkNrv4O_8Hno4mHKMDMlR9jf4",
    authDomain: "glide-sandbox-301022.firebaseapp.com",
    projectId: "glide-sandbox-301022",
    databaseURL: "https://glide-sandbox-301022.firebaseio.com",
    storageBucket: "glide-sandbox-301022.firebasestorage.app",

    // Builder Analytics
    appId: "1:515782381978:web:589dba9da3c71215a82a23",
    measurementId: sandboxBuilderMeasurementID,
    messagingSenderId: "515782381978",
};

const FirebaseEmulatorsConfigGlide = {
    ...FirebaseStagingConfigGlide,

    // I don't think this is needed. Dev already had that before the emulators came in
    // so I'm leaving it.
    databaseURL: "http://localhost:8080",

    apiKey: "AIzaSyB2dCXFckok-ArS35WdSlsYKHkwpfQEeIo",
    projectId: "glide-staging",
    storageBucket: "glide-staging.appspot.com",

    // Builder Analytics
    appId: "1:707347437040:web:99b2cf5e6eddb7c226f935",
    measurementId: stagingBuilderMeasurementID,
    messagingSenderId: "707347437040",
};

export function firebaseConfigFor(loc: "sandbox" | "staging" | "prod" | "emulator") {
    switch (loc) {
        case "sandbox":
            return FirebaseSandboxConfigGlide;
        case "staging":
            return FirebaseStagingConfigGlide;
        case "prod":
            return FirebaseProdConfigGlide;
        case "emulator":
            return FirebaseEmulatorsConfigGlide;
        default:
            assertNever(loc, "Invalid location");
    }
}

const prodCredentials = {
    appId: "397046215911",
    developerKey: "AIzaSyDqeyCb3qU-V05e4VK4DJJjmJltSp4iAIw",
    clientId: "397046215911-aeid2ln7du7cgivjcf8ib8d4v8blvrdg.apps.googleusercontent.com",
    discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
};

const stagingCredentials = {
    appId: "707347437040",
    developerKey: "AIzaSyD_yse1U-pVdMmbuixF696cJe-u-tETfXU",
    clientId: "707347437040-v76o2du6vhja2tqja19ohv6mps5h5h0b.apps.googleusercontent.com",
    discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
};

const sandboxCredentials = {
    appId: "515782381978",
    developerKey: "AIzaSyDVVV-9PpwgHl7qztrWgs8DrOmqf7itExY",
    clientId: "515782381978-6uph475unpbnanaaiffcsfiarmhmjfg6.apps.googleusercontent.com",
    discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
};

const stagingAppDescriptionStoreConfig = {
    maxBytes: 5 << 10, // 5 KiB. This is set artificially low on stage so the large app code-path gets exercised
    headroomBytes: 0, // Not necessary when setting such a low max size
    numSerialsToKeep: 2,
};

const prodAppDescriptionStoreConfig = {
    // 1MiB, Firestore max document size: https://firebase.google.com/docs/firestore/quotas#collections_documents_and_fields
    maxBytes: 1 << 20,
    // Buffer ontop of the full calculated document size to ensure safety
    headroomBytes: 10 << 10,
    numSerialsToKeep: 5,
};

export interface LocationSettings {
    measurementID: string;
    firebaseConfig: FirebaseConfig;
    firebaseEmulators?: {
        firestore: string;
        auth: string;
    };
    serviceWorkerLocation: string;
    showBetaWarning: boolean;
    urlPrefix: string;
    // When deployed, this is the same as urlPrefix.
    // In development, this is the deployed instance.
    // We need this information to work around limited OAuth implementations
    // that only support one URL.
    deployedURLPrefix: string;
    stripeClientKey: string;
    googleCredentials: typeof prodCredentials;
    oAuthCallbackURL: string;
    gcpOAuthCallbackURL: string;
    fcmKey: string;
    containerURLPrefix: string;
    honeycombDatasetName: string;
    alwaysLogReloadCauses: boolean;
    useProductionTemplateIDs: boolean;
    hubspotAccountID: string;
    mixpanelAPIToken: string;
    gtmContainerID: string;
    msalAuth: {
        // Microsoft/Excel Authentication
        tenant: string;
        clientID: string;
        redirectURL: string;
    };
    isDevelopment: boolean;
    appDescriptionStoreConfig: typeof prodAppDescriptionStoreConfig;
    templates: {
        approvedOrgID: string;
        reviewOrgID: string;
        tutorialAppID: string;
    };
    sendReloadEvent: boolean;
    alwaysUsePlayURLs: boolean;
    allowExperimentalSettings: boolean;
    oauthClientIDs: Record<OAuthProvider, string>;
    sendCacheTimingsToHoneycomb: boolean;
    // This is applied to the firebase config during firebase init, but only for the player
    analyticsMixin: Partial<FirebaseConfig>;
    cloudinaryAPIKey: string;
    datadog: {
        applicationId: string;
        clientToken: string;
        env: string;
    };
    partnerStack: {
        publicKey: string;
    };
    aiCustomComponentDomain: string;
}

export interface LocationSettingsWithURL extends LocationSettings {
    // ##shortnameSuffix:
    // This should be the only place where we configure the suffix for the
    // short names, but alas, it is not.
    shortnameSuffix: string;
    pagesShortnameSuffix: string;
}

const productionSettings: LocationSettingsWithURL = {
    measurementID: prodBuilderMeasurementID,
    firebaseConfig: FirebaseProdConfigGlide,
    serviceWorkerLocation: "/sw-prod-v4.js",
    showBetaWarning: false,
    shortnameSuffix: ".glideapp.io",
    pagesShortnameSuffix: ".glide.page",
    urlPrefix: "https://go.glideapps.com/",
    deployedURLPrefix: "https://go.glideapps.com/",
    stripeClientKey: "pk_live_amBWTtPUS7d0wATlgjvx3GwV",
    googleCredentials: prodCredentials,
    oAuthCallbackURL: "https://go.glideapps.com/signInWith/google",
    gcpOAuthCallbackURL: "https://go.glideapps.com/connectGCP",
    fcmKey: "BLf98Y1yClC0Y3gAQ_fqx8jD75c-Ns0MZ8t3oPTbsPIz0xEsBV1ECpEY-toa0bQ5Ane7ArB-WKbWKAkmwROFxLo",
    containerURLPrefix: "https://functions.prod.internal.glideapps.com",
    honeycombDatasetName: "glide-prod",
    alwaysLogReloadCauses: false,
    useProductionTemplateIDs: true,
    hubspotAccountID: "19936848",
    mixpanelAPIToken: "4b0e7a406c99710357a1b6aad2d9ad9f",
    gtmContainerID: "GTM-5MXCZVT",
    msalAuth: {
        tenant: "common",
        clientID: "65f4b7a7-3d3f-4dce-8da5-dbf2c2680d52",
        redirectURL: "https://go.glideapps.com/connectMicrosoft",
    },
    isDevelopment: false,
    appDescriptionStoreConfig: prodAppDescriptionStoreConfig,
    templates: {
        reviewOrgID: "uF6gluP7iSyM8y5Et7lj",
        approvedOrgID: "Cs7n7MCVVcLCp6NktfOV",
        tutorialAppID: "CDz7mHzVuJ9JygmXPJqK-template-builder",
    },
    sendReloadEvent: true,
    alwaysUsePlayURLs: false,
    oauthClientIDs: {
        github: "61a33bd46f936ff0ab00",
        google: "397046215911-d5ms73vfd1jf2q4s83uqu4lucc2tbosa.apps.googleusercontent.com",
        slack: "435723908452.4478073099923",
        "slack-bot": "435723908452.4478073099923",
        discord: "1052369859939414088",
        hubspot: "d9c1be7c-8650-4ccb-9466-9f6c816bcf0e",
        zoom: "u7bHDY34Qfmnjr_AvAMZ_A",
        asana: "1204187346352015",
        "msal-plugins": "2ceadcca-c163-4508-be0b-6b407ddd2175",
        paypal: "ATb6p3y1jEB7-krgzdZTgc70qw92-X-NoyVqVuQWK09tnrhEH9rAtK9wYEMU7zE7IbZBdwYCHBscfYFb",
        docusign: "07cd4297-35fd-4830-b5f5-fe3ce535d09c",
        airtable: "2f403893-fab4-4700-bbfa-f31395d315a4",
    },
    sendCacheTimingsToHoneycomb: false,
    allowExperimentalSettings: false,
    analyticsMixin: {
        appId: "1:397046215911:web:0799978223eea405fa5e42",
        measurementId: "G-HP5L2HJ0QR",
    },
    cloudinaryAPIKey: "232385157652988",
    datadog: {
        // TODO: These are staging tokens. Should we share this with staging?
        applicationId: "bf0251dc-150d-4096-b30f-558a1c69f629",
        clientToken: "pubfdd86cfe925dc22d9242ab4c2abb5a37",
        env: "prod",
    },
    partnerStack: {
        publicKey: "pk_WjHdLw4wEWV2YzneXc7ghUUDUQ2Yu2FM",
    },
    aiCustomComponentDomain: "https://components.glide.info",
};

const stagingSettings: LocationSettingsWithURL = {
    measurementID: stagingBuilderMeasurementID,
    firebaseConfig: FirebaseStagingConfigGlide,
    serviceWorkerLocation: "/sw-dev-v4.js",
    showBetaWarning: true,
    shortnameSuffix: ".staging.glideapp.io",
    pagesShortnameSuffix: ".staging.glide.page",
    urlPrefix: "https://staging.heyglide.com/",
    deployedURLPrefix: "https://staging.heyglide.com/",
    stripeClientKey: "pk_test_aOhgsKuDpbZzBgt7QVRJQFOQ",
    googleCredentials: stagingCredentials,
    oAuthCallbackURL: "https://staging.heyglide.com/signInWith/google",
    gcpOAuthCallbackURL: "https://staging.heyglide.com/connectGCP",
    fcmKey: "BLRbQkRSZfWYDStUSpLCBIOFO3E-TkXZ-O0s0Qr1V3bsIQCt5IYqhxYDnh_85yt_EO1cnn_0sGZRVznHnF-JgKo",
    containerURLPrefix: "https://functions.staging.internal.glideapps.com",
    honeycombDatasetName: "glide-staging",
    alwaysLogReloadCauses: true,
    useProductionTemplateIDs: false,
    hubspotAccountID: "20075072",
    mixpanelAPIToken: "b7ffa5b34b46ea7ad11b4a7104a1dd59",
    gtmContainerID: "GTM-PBVWKDF",
    msalAuth: {
        tenant: "common",
        clientID: "b13035c3-9b26-4b62-b6d4-9c464f1fe15b",
        redirectURL: "https://staging.heyglide.com/connectMicrosoft",
    },
    isDevelopment: false,
    appDescriptionStoreConfig: stagingAppDescriptionStoreConfig,
    templates: {
        reviewOrgID: "D27lW8J9ZitvHBF8lJNF",
        approvedOrgID: "LGgYteSIJvJmJlGFDjuB",
        tutorialAppID: "aWXvBbNHH0dMOnAFtF8s-template-builder",
    },
    sendReloadEvent: true,
    alwaysUsePlayURLs: false,
    oauthClientIDs: {
        github: "9ddb712ca685485f1fcc",
        google: "707347437040-mc3rirnvf7ctgebf48ps8uu47q9539ai.apps.googleusercontent.com",
        slack: "435723908452.4478122972755",
        "slack-bot": "435723908452.4478122972755",
        zoom: "jg_NoBKnRMWOlwCc8V7yFQ",
        hubspot: "d9c1be7c-8650-4ccb-9466-9f6c816bcf0e",
        discord: "1052008313207918593",
        asana: "1204187346352016",
        "msal-plugins": "783e5948-1958-4307-8cd3-202902d01ebc",
        paypal: "ATb6p3y1jEB7-krgzdZTgc70qw92-X-NoyVqVuQWK09tnrhEH9rAtK9wYEMU7zE7IbZBdwYCHBscfYFb",
        docusign: "7ee34ed1-50b6-47e2-9bdc-1f2afb92ce15",
        airtable: "f31d9bba-3fc2-404f-b680-8d019c62a589",
    },
    sendCacheTimingsToHoneycomb: true,
    allowExperimentalSettings: true,
    analyticsMixin: {
        appId: "1:707347437040:web:b3b838304228044726f935",
        measurementId: "G-6DZ2G0GYES",
    },
    cloudinaryAPIKey: "232385157652988",
    datadog: {
        applicationId: "bf0251dc-150d-4096-b30f-558a1c69f629",
        clientToken: "pubfdd86cfe925dc22d9242ab4c2abb5a37",
        env: "staging",
    },
    partnerStack: {
        publicKey: "pk_1698MODG7kpg3qAhEYN1oLGiJJpL5jfS",
    },
    aiCustomComponentDomain: "https://components.glide.info",
};

const sandboxSettings: LocationSettingsWithURL = {
    ...stagingSettings,
    firebaseConfig: FirebaseSandboxConfigGlide,
    serviceWorkerLocation: "/sw-sandbox-v4.js",
    showBetaWarning: false,
    shortnameSuffix: ".sandbox.glideapp.io",
    pagesShortnameSuffix: ".sandbox.glide.page",
    urlPrefix: "https://sandbox.heyglide.com/",
    deployedURLPrefix: "https://sandbox.heyglide.com/",
    containerURLPrefix: "https://functions.sandbox.internal.glideapps.com",
    honeycombDatasetName: "glide-sandbox",
    googleCredentials: sandboxCredentials,
    oAuthCallbackURL: "https://sandbox.heyglide.com/signInWith/google",
    analyticsMixin: {
        appId: "1:515782381978:web:24b30c1d337e85a0a82a23",
        measurementId: "G-V5PTHHMXDB",
    },
};

// Exported so we can get to the URL prefix.
export const devSettings: LocationSettingsWithURL = {
    ...stagingSettings,
    firebaseConfig: FirebaseEmulatorsConfigGlide,
    showBetaWarning: false,
    urlPrefix: "http://localhost:3000/",
    deployedURLPrefix: "https://staging.heyglide.com/",
    // As weird as this looks, it's correct: we need to go through Staging
    // proper even when developing.
    // This is due to how the Google OAuth2 Code Flow requires the redirectURL
    // to match _exactly_, or else you land yourself an invalid_grant.
    //
    // This doesn't actually improve security. Attackers definitely know our
    // redirectURL. But we don't! Thanks, Cloud.
    oAuthCallbackURL: "https://staging.heyglide.com/signInWith/google",
    gcpOAuthCallbackURL: "http://localhost:3000/connectGCP",
    containerURLPrefix: "http://localhost:5005",
    msalAuth: {
        ...stagingSettings.msalAuth,
        redirectURL: "http://localhost:3000/connectMicrosoft",
    },
    isDevelopment: true,
};

const localSettings: LocationSettingsWithURL = {
    measurementID: "",
    firebaseConfig: {
        apiKey: "mustBeNotEmpty",
        authDomain: "builder.local.internal.glideapps.com",
        projectId: "glide-local",
        databaseURL: "http://firestore.local.internal.glideapps.com:30008",
        storageBucket: "glide-local.appspot.com",
        appId: "mustBeNotEmpty",
    },
    firebaseEmulators: {
        firestore: "firestore.local.internal.glideapps.com:30008",
        auth: "http://firebase-auth.local.internal.glideapps.com:30009",
    },
    serviceWorkerLocation: "/sw-dev-v4.js",
    showBetaWarning: false,
    shortnameSuffix: ".local.internal.glideapps.com",
    pagesShortnameSuffix: ".local.internal.glideapps.com",
    urlPrefix: "http://builder.local.internal.glideapps.com:30000/",
    deployedURLPrefix: "https://staging.heyglide.com/",
    stripeClientKey: "pk_test_aOhgsKuDpbZzBgt7QVRJQFOQ",
    googleCredentials: {
        appId: "",
        developerKey: "",
        clientId: "",
        discoveryDocs: [],
    },
    oAuthCallbackURL: "https://staging.heyglide.com/signInWith/google",
    gcpOAuthCallbackURL: "http://builder.local.internal.glideapps.com:30000/connectGCP",
    fcmKey: "",
    containerURLPrefix: "http://functions.local.internal.glideapps.com:30000",
    honeycombDatasetName: "",
    alwaysLogReloadCauses: true,
    useProductionTemplateIDs: false,
    hubspotAccountID: "",
    mixpanelAPIToken: "857ca491304993ad7459b8379e0a8265",
    gtmContainerID: "GTM-5M4K96R",
    msalAuth: {
        ...stagingSettings.msalAuth,
        redirectURL: "http://builder.local.internal.glideapps.com:30000/connectMicrosoft",
    },
    isDevelopment: false, // this enables dev-specific things, so even though local is for development purposes, it's not "development"
    appDescriptionStoreConfig: stagingAppDescriptionStoreConfig,
    templates: stagingSettings.templates,
    sendReloadEvent: false,
    alwaysUsePlayURLs: true,
    oauthClientIDs: {
        github: "b8e6c129c33c0018b546",
        google: "",
        slack: "",
        zoom: "",
        hubspot: "64433ff2-7838-4e0b-9edd-01f1a64d83da",
        "slack-bot": "",
        discord: "",
        asana: "",
        "msal-plugins": "",
        paypal: "",
        docusign: "",
        airtable: "",
    },
    sendCacheTimingsToHoneycomb: false,
    allowExperimentalSettings: true,
    analyticsMixin: {},
    cloudinaryAPIKey: "232385157652988",
    datadog: {
        applicationId: "bf0251dc-150d-4096-b30f-558a1c69f629",
        clientToken: "pubfdd86cfe925dc22d9242ab4c2abb5a37",
        // These are the settings for local-env, we'll consider it staging, same as dev
        env: "staging",
    },
    partnerStack: {
        // Test/Staging PartnerStack public key:
        publicKey: "pk_1698MODG7kpg3qAhEYN1oLGiJJpL5jfS",
    },
    aiCustomComponentDomain: "",
};

function generatePreviewSettings(name: string): LocationSettingsWithURL {
    const envDomain = `${name}.env.staging.internal.glideapps.com`;
    const builderURL = `https://builder.${envDomain}`;
    return {
        ...localSettings,
        firebaseConfig: {
            ...localSettings.firebaseConfig,
            projectId: "glide-preview",
            authDomain: envDomain,
            databaseURL: `http://${envDomain}:30008`,
            storageBucket: "glide-preview.appspot.com",
        },
        firebaseEmulators: {
            firestore: `${envDomain}:30008`,
            auth: `http://${envDomain}:30009`,
        },
        shortnameSuffix: `.${envDomain}`,
        pagesShortnameSuffix: `.${envDomain}`,
        urlPrefix: builderURL,
        gcpOAuthCallbackURL: `${builderURL}/connectGCP`,
        containerURLPrefix: `https://functions.${envDomain}`,
        msalAuth: {
            ...localSettings.msalAuth,
            redirectURL: `${builderURL}/connectMicrosoft`,
        },
    };
}

// This used to be a more strict enumeration, but to support ephemeral envs where
// we have a dynamic name, unfortunately this needs to be string.
// We've kept the type definition here in case we can restrict it again later,
// and for clarity.
export type Location = string;

const locationMap: Record<Location, LocationSettingsWithURL> = {
    production: productionSettings,
    staging: stagingSettings,
    sandbox: sandboxSettings,
    dev: devSettings,
    local: localSettings,
};

// These locations are of form PREFIX-NAME, and this maps a PREFIX to a function
// which takes a NAME and dynamically generates a location settings for that name
// (generally this means interpolating the NAME into various URLs).
const dynamicLocationMap: Record<Location, (name: string) => LocationSettingsWithURL> = {
    preview: generatePreviewSettings,
};

export function getLocationSettingsUnsafe<T extends AppKind | undefined>(
    location: Location,
    appKind: T
): T extends AppKind ? LocationSettingsWithURL : LocationSettings {
    let settings: LocationSettingsWithURL | undefined;
    if (location.includes("-")) {
        const [prefix, name] = location.split("-");
        settings = dynamicLocationMap[prefix]?.(name);
    } else {
        settings = locationMap[location];
    }

    if (settings === undefined) {
        throw new Error(`No such location ${location}`);
    }

    // We need to make sure the common location settings are not overridden
    // by the mutations below.
    settings = { ...settings };

    if (appKind === AppKind.Page) {
        settings.shortnameSuffix = settings.pagesShortnameSuffix;
    }

    return settings;
}

export function getAllShortnameEndings(): string[] {
    return new Array(
        ...new Set([
            productionSettings.shortnameSuffix,
            productionSettings.pagesShortnameSuffix,
            stagingSettings.shortnameSuffix,
            stagingSettings.pagesShortnameSuffix,
            sandboxSettings.shortnameSuffix,
            sandboxSettings.pagesShortnameSuffix,
            localSettings.shortnameSuffix,
            localSettings.pagesShortnameSuffix,
        ])
    );
}
