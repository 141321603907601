import { type RuntimeTheme, AppIcon, UrlHandling, getUrlHandling } from "@glide/common";
import { trackLinkClick } from "@glide/common-core/dist/js/analytics/link-clicks";
import { getAnyLocalizedString, type DevStringKey, type LocalizedStringKey } from "@glide/localization";
import { PortalObserver } from "@glide/common-core/dist/js/components/portal-observer/portal-observer";
import { ActionResult } from "@glide/common-core/dist/js/components/types";
import type { OnClickHandler } from "@glide/common-core/dist/js/hooks/use-on-click-from-action";
import { Appearance } from "@glide/component-utils";
import type { AppKind } from "@glide/location-common";
import { safeURL } from "@glide/support";
import * as React from "react";
import { useTheme } from "styled-components";
import type { Subtract } from "utility-types";
import { withScrollOverlayID } from "webapp/chrome/views/scroll-view/scroll-view-overlay";
import { FloatingButtonSimple } from "webapp/ui/views/floating-button/floating-button";

import { type ButtonViewProps, ButtonView } from "../../views/button/button-view";

function getURLHandling(url: string | undefined): {
    url: string | undefined;
    useTag: boolean;
    target: "_blank" | undefined;
} {
    url = safeURL(url);
    if (url === undefined) {
        return { url: url, useTag: false, target: undefined };
    }
    const handling = getUrlHandling(url, false);
    const useTag = handling === UrlHandling.UseATag || handling === UrlHandling.UseATagWithBlank;
    const target = handling === UrlHandling.UseATagWithBlank ? "_blank" : undefined;
    return { url: url, useTag, target };
}

interface Handled {
    readonly useTag?: boolean;
    readonly showConfirm?: boolean;
    readonly onShowConfirm?: (show: boolean) => void;
    readonly onClick?: (handled: boolean) => void;
}

interface Props extends Subtract<ButtonViewProps, Handled> {
    readonly scrollOverlayID: string;
    readonly floatingIcon?: string;
    readonly appKind: AppKind;
    readonly onClick: OnClickHandler;
}

const Button: React.FC<React.PropsWithChildren<Props>> = p => {
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [confirmationLocalizedString, setConfirmationLocalizedString] = React.useState<
        LocalizedStringKey | DevStringKey | undefined
    >(undefined);
    const [disabled, setDisabled] = React.useState(false);

    const { onClick, floatingIcon } = p;
    const { url, useTag } = getURLHandling(p.url);

    let title = p.title;
    if (confirmationLocalizedString !== undefined) {
        title = getAnyLocalizedString(confirmationLocalizedString, p.appKind);
    }

    const onButtonClick = React.useCallback(
        async (handled: boolean) => {
            if (onClick === undefined) return;
            if (confirmationLocalizedString !== undefined) return;

            if (url !== undefined) {
                trackLinkClick(url);
            }

            const result = await onClick(handled);

            if (result === ActionResult.CopiedToClipboard) {
                setConfirmationLocalizedString("copied");
                setTimeout(() => {
                    setConfirmationLocalizedString(undefined);
                }, 2000);
            }
        },
        [onClick, confirmationLocalizedString, url]
    );

    const onShowConfirm = React.useCallback((show: boolean) => {
        setShowConfirm(show);
        setDisabled(show);
    }, []);

    const theme: RuntimeTheme = useTheme() as RuntimeTheme;

    const buttonClickURLIsUndefined = React.useCallback(() => {
        void onButtonClick(url === undefined);
    }, [onButtonClick, url]);

    if (p.appearance === Appearance.Floating) {
        return (
            <PortalObserver selector={`#${p.scrollOverlayID}fab-target`}>
                <FloatingButtonSimple
                    {...p}
                    isEnabled={!p.disabled && !disabled}
                    onClick={buttonClickURLIsUndefined}
                    title={title}
                >
                    <AppIcon icon={floatingIcon ?? ""} size={22} color={theme.accentForegroundColor} />
                </FloatingButtonSimple>
            </PortalObserver>
        );
    }

    return (
        <ButtonView
            {...p}
            onClick={onButtonClick}
            title={title}
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            disabled={p.disabled || disabled}
            url={url}
            useTag={useTag}
            showConfirm={showConfirm}
            icon={floatingIcon}
            onShowConfirm={onShowConfirm}
        />
    );
};

export default withScrollOverlayID(Button);
