import { AppIcon, TailwindThemeProvider, getRuntimeThemeForPlatform, type RuntimeTheme } from "@glide/common";
import {
    convertOAuth2RedirectTokenToState,
    decodeOAuth2AuthErrorMessage,
    decodeOAuth2AuthTokenMessage,
    extractAppLoginTokenFromCurrentURL,
    extractPrivateInviteLinkTokenFromCurrentURL,
} from "@glide/common-core/dist/js/authorization/auth";
import { getAnyLocalizedString, getLocalizedString, type LocalizedStringKey } from "@glide/localization";
import { type AppFeatures, type IconImage, AppAuthenticationKind } from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import type { AppUserAuthenticator } from "@glide/common-core/dist/js/components/types";
import { AuthenticationMethod } from "@glide/common-core/dist/js/Database";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { panic } from "@glideapps/ts-necessities";
import {
    MaxPinLifeMins,
    Watchable,
    getAllowLoginSaveKey,
    getEmailKey,
    getPasswordKey,
    getPinKey,
    getUsernameKey,
    isEmptyOrUndefined,
    isEmptyOrUndefinedish,
    isValidEmailAddress,
    localStorageGetItem,
    localStorageRemoveItem,
    localStorageSetItem,
    logError,
} from "@glide/support";
import classNames from "classnames";
import color from "color";
import * as React from "react";
import { css, withTheme } from "styled-components";
import tw from "twin.macro";
import { CustomWireSignIn, CustomSignIn } from "@glide/wire-renderer";
import { styled, type SignInState } from "@glide/common-components";
import {
    getLocalAppLoginToken,
    getLocalEmailLoginCredentials,
    getDefaultPagesGreeting,
    type PagesLoginSource,
    type AppCredentials,
} from "@glide/auth-controller-core";
import { withCredentials } from "../../../lib/with-credentials";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import { withEminence } from "../../../../designer/lib/with-eminence";
import type { EminenceFlags } from "@glide/billing-types";
import type { NotificationTarget } from "@glide/plugins";
import { getWireAppThemeForPlatform } from "@glide/theme";
import { withOSTheme } from "@glide/player-core";

export interface AuthControllerProps {
    readonly appID: string;
    readonly authKind: AppAuthenticationKind;
    readonly withBranding: boolean;
    readonly accentColor: string;
    readonly previewMode: boolean;

    // if these are set, then show username first
    readonly getUsername?: boolean;
    readonly username?: string;
    readonly onUsernameSet?: (username: string) => void;

    // only call this when logging in with email
    readonly onLoggedIn: ((email: string) => Promise<void>) | undefined;

    // passcode auth method
    readonly submitPasscode?: (passcode: string, loginToken: AppLoginTokenContainer | undefined) => Promise<boolean>;

    // allows user to simply exit
    readonly onClose?: () => void;

    // This is used when we just try to log in, but start the app
    // if it doesn't work.  Used in public apps that can still sign
    // in.
    readonly onFailed?: () => void;

    // email auth controller doohicky

    // used to figure out if we can show gmail auth
    readonly appFeatures: AppFeatures;
    readonly authMethod?: AuthenticationMethod;

    // app title for now until builder side is finished
    readonly appTitle?: string;

    readonly pagesSource?: "main-sign-in" | "modal-sign-in" | "modal-sign-up";
    readonly onPageSourceChanged?: (newPageSource: PagesLoginSource) => void;
    readonly flags: EminenceFlags | undefined;
    readonly isOSThemeDark: boolean;
}

interface Props extends AuthControllerProps {
    // FIXME: Should be core theme or WireAppTheme ?
    readonly theme: RuntimeTheme;
    readonly credentials: AppCredentials;
    readonly customCssClassName?: string;

    readonly iconImage?: IconImage;
}
interface State {
    readonly currentState: SignInState;
    readonly email: string;
    readonly passcode: string;
    readonly username: string;
    readonly pin: string;
    readonly userAgreed: boolean;
    readonly allowSaveLogin: boolean;
    readonly canContinue: boolean;
    readonly canPressPin: boolean;
    readonly error: string | undefined;
    readonly isSending: boolean;
    readonly pinTarget: string;
    readonly pinMethod: NotificationTarget["method"];
}

const CloseContainer = styled.div<{ color: string }>`
    position: absolute;

    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    color: ${p => p.color};
`;

function canRequestAccess(
    authKind: AppAuthenticationKind,
    authMethod: AuthenticationMethod,
    appFeatures: AppFeatures
): boolean {
    return (
        appFeatures.canAppUserRequestAccess === true &&
        authKind === AppAuthenticationKind.EmailPin &&
        authMethod === AuthenticationMethod.UserProfileEmailPin
    );
}

type NativeAuthControllerCallback = Readonly<{
    onTokenExchangeStart: () => void;
    onTokenExchangeError: (errorKey: LocalizedStringKey) => void;
    onTokenExchangeSuccess: (token: string) => void;
}>;

const nativeAuthControllerCallbacks = new Set<NativeAuthControllerCallback>();

// FIXME: Move this Google-specific code into a Google-specific module.
async function handleGoogleAuthCodeFromNative(authCode: string): Promise<void> {
    const state = convertOAuth2RedirectTokenToState();
    if (state === undefined) return;

    for (const callbackGroup of nativeAuthControllerCallbacks) {
        callbackGroup.onTokenExchangeStart();
    }

    const reportError = () => {
        for (const callbackGroup of nativeAuthControllerCallbacks) {
            callbackGroup.onTokenExchangeError("tryAgain");
        }
    };

    try {
        const response = await getAppFacilities().callAuthIfAvailableCloudFunction(
            "signInWith/google",
            {
                state,
                code: authCode,
                respondDirectly: true,
            },
            {}
        );
        if (response?.ok !== true) {
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void response?.text();
            // Note that at this point, we aren't actually verifying whether the
            // user has access to the app or not. We're just converting a Google
            // auth code into a token we can possibly exchange for credentials.
            logError("Native Google sign-in response status was", response?.status);
            reportError();
        } else {
            const { oauth2Token } = await response.json();
            if (typeof oauth2Token !== "string") {
                return reportError();
            }

            for (const callbackGroup of nativeAuthControllerCallbacks) {
                callbackGroup.onTokenExchangeSuccess(oauth2Token);
            }
        }
    } catch (e: unknown) {
        logError("While converting native Google sign-in auth code:", e);
        reportError();
    }
}

// FIXME: Find a better place to establish this hook.
(window as any).glideNativeGoogleSignInHook = (authCode: string) => {
    void handleGoogleAuthCodeFromNative(authCode);
};

const samePageAuthToken = new Watchable<string | undefined>(undefined);
export function setSamePageAuthToken(token: string) {
    samePageAuthToken.current = token;
}

class AuthControllerImpl extends React.PureComponent<Props, State> {
    private expectedAuthMessageSource: Window | null = null;

    public state: State = {
        currentState: "credentials",
        passcode: "",
        email: "",
        pin: "",
        pinTarget: "",
        pinMethod: "email",
        userAgreed: false,
        allowSaveLogin: false,
        username: "",
        canContinue: false,
        canPressPin: false,
        error: undefined,
        isSending: false,
    };

    private get appKind(): AppKind {
        return getAppKindFromFeatures(this.props.appFeatures);
    }

    private nativeGoogleOauthCallbacks = {
        onTokenExchangeStart: () => this.setState({ currentState: "sign-in" }),
        onTokenExchangeError: (err: LocalizedStringKey) =>
            this.setState({ error: getLocalizedString(err, this.appKind), currentState: "credentials" }),
        onTokenExchangeSuccess: (token: string) => this.onOAuth2Token(token),
    };

    private loginTokenFromHash: AppLoginTokenContainer | undefined;
    private privateInviteLinkToken: string | undefined;

    public UNSAFE_componentWillMount() {
        // In order to support Sign in with Google, et alia, without also leaking more
        // pages into the history state (specifically for PWAs to keep feeling like apps)
        // we have to perform the entire OAuth2 flow in a pop-up of our own creation.
        // The pop-up will send us a message with the token we use to authenticate
        // with Glide. We just need to listen for it.
        window.addEventListener("message", this.onWindowMessageForOauth2Token);
        nativeAuthControllerCallbacks.add(this.nativeGoogleOauthCallbacks);
        this.loginTokenFromHash = extractAppLoginTokenFromCurrentURL();
        this.privateInviteLinkToken = extractPrivateInviteLinkTokenFromCurrentURL();
    }

    public async componentDidMount() {
        const { previewMode, appID, getUsername } = this.props;

        // IdP initiated sign-in won't give us an opener, we'll have to handle
        // OAuth tokens opened on the same page.
        samePageAuthToken.subscribe(this.onMaybeOAuth2Token);
        if (samePageAuthToken.current !== undefined) {
            void this.onMaybeOAuth2Token(samePageAuthToken.current);
            return;
        }
        if (previewMode) return;
        const username = localStorageGetItem(getUsernameKey(appID));
        if (username !== undefined) {
            this.onUsernameChange(username);
        }

        if (getUsername === true && isEmptyOrUndefinedish(username)) {
            this.setCurrentState("username", { ...this.state, username: username ?? "" });
        } else {
            await this.checkEmailPasswordCanAuth();
        }
    }

    private async checkEmailPasswordCanAuth() {
        const { credentials, appID, authKind } = this.props;

        const inMemoryPasscode = credentials.password;
        const inMemoryEmail = credentials.email;
        const inMemoryEmailPassword = credentials.emailPassword;
        // We sometimes pass in an app login token via the hash fragment,
        // mainly for testing purposes. However, if the credentials already
        // have a login token it's expected to be "fresher" so we should
        // defer to it first.
        const inMemoryLoginToken = credentials.loginToken ?? this.loginTokenFromHash;

        const localStoragePasscode = localStorageGetItem(getPasswordKey(appID));
        const localStorageEmailCredentials = getLocalEmailLoginCredentials(appID);
        const localStorageEmail = inMemoryLoginToken?.overrideEmail ?? localStorageEmailCredentials?.email;
        const localStorageEmailPassword = localStorageEmailCredentials?.emailPassword;
        const localStorageLoginToken = getLocalAppLoginToken(appID);

        // The reason we need to pull from this.props.credentials is in the case of chat and comments.
        // It is possible to log in and opt out of auth cookie storage. This will keep the credentials in memory but will
        // wipe out the local storage items. We should first try the in-memory credentials to avoid having
        // to log in again while using the app. If the in memory creditials are not defined, we fallback to
        // the credentials stored in local storage for initial log-in flow
        const passcode = !isEmptyOrUndefined(inMemoryPasscode) ? inMemoryPasscode : localStoragePasscode;
        const email = !isEmptyOrUndefined(inMemoryEmail) ? inMemoryEmail : localStorageEmail;
        const emailPassword = !isEmptyOrUndefined(inMemoryEmailPassword)
            ? inMemoryEmailPassword
            : localStorageEmailPassword;
        const loginToken = inMemoryLoginToken ?? localStorageLoginToken;

        if (email !== undefined) {
            this.onEmailChange(email);
        }

        if (!isEmptyOrUndefined(passcode) && authKind === AppAuthenticationKind.Password) {
            await this.checkLocalStoragePasscode(passcode, loginToken);
        } else if (
            (!isEmptyOrUndefined(emailPassword) || loginToken !== undefined) &&
            !isEmptyOrUndefined(email) &&
            authKind === AppAuthenticationKind.EmailPin
        ) {
            await this.checkLocalStoragePin(emailPassword, email, loginToken);
        } else if (
            !isEmptyOrUndefined(email) &&
            this.isWithinDateTimestamp() &&
            authKind === AppAuthenticationKind.EmailPin &&
            loginToken === undefined
        ) {
            this.setCurrentState("challenge");
        } else if (loginToken !== undefined) {
            await this.checkLocalStorageToken(loginToken, email, authKind);
        }
    }

    private onEnterOAuth2Flow = () => {
        this.setCurrentState("sign-in");
        this.setState({ email: "" });
    };

    private onFailedOAuth2Flow = () => {
        // FIXME: Should we say something about what failed?
        this.setCurrentState("credentials");
    };

    private onUnverifiedOAuth2Flow = (email: string) => {
        // FIXME: We shouldn't be dropping the email in the input,
        // but that's all we currently have for communication.
        // FIXME: We need a dedicated state for unverified emails.
        this.setState({ email });
        this.setCurrentState("credentials");
    };

    private onMaybeOAuth2Token = async (authToken: string | undefined) => {
        if (authToken === undefined) return;
        // We want to make sure that we only ever respond once for a given
        // auth token, but that we respond at least once for every given
        // auth token. It's unfortunately possible for
        // `samePageAuthToken.current` to be set to multiple auth tokens
        // before we fire for each: we won't rely on the behavior of Watchable
        // to synchronously execute this. So to make sure that we're definitely
        // reacting to all auth tokens, we only reset `current` to `undefined`
        // if it's equal to our `authToken`.
        if (samePageAuthToken.current === authToken) {
            samePageAuthToken.current = undefined;
        }
        await this.onOAuth2Token(authToken);
    };

    private onOAuth2Token = async (authToken: string) => {
        this.onEnterOAuth2Flow();
        const maybeEmailPassword = await this.props.credentials.emailAndPasswordForOAuth2Token(
            authToken,
            this.state.userAgreed
        );

        if (maybeEmailPassword === undefined) {
            // FIXME: We should use AuthState.OAuth2Unauthorized
            this.setCurrentState("credentials");
        } else if (maybeEmailPassword.password === undefined && maybeEmailPassword.loginToken === undefined) {
            // FIXME: We shouldn't be dropping the email in the input,
            // but that's all we currently have for communication.
            this.setState({ email: maybeEmailPassword.email });
            this.setCurrentState("credentials");
            this.setState({ error: getLocalizedString("emailAddressDoesntHaveAccess", this.appKind) });
        } else if (this.props.onLoggedIn !== undefined) {
            this.setCurrentState("sign-in");
            await this.props.onLoggedIn(maybeEmailPassword.email);
        }
    };

    private onWindowMessageForOauth2Token = async (message: MessageEvent) => {
        if (this.expectedAuthMessageSource === null) {
            // We used to validate that the message source was definitely the Window
            // we opened, but Mobile Safari on iOS 13.3.1 broke that. So now we
            // only check that the origins are the same (the browser controls those,
            // not third-party scripts) and that we can extract a message.
            return;
        }

        // With one exception: if the message comes from the relevant builder,
        // this is still allowed. We're making this allowance to support
        // SSO plugins, which need to communicate back to the user that
        // an error occurred.
        if (message.origin !== window.location.origin && !getLocationSettings().urlPrefix.startsWith(message.origin)) {
            // urlPrefix will be something like https://go.glideapps.com/
            // but message.origin will be something like https://go.glideapps.com without the trailing slash
            // So we'll perform a startsWith to work around this.
            return;
        }

        const authTokenPayload = decodeOAuth2AuthTokenMessage(message.data);
        if (authTokenPayload !== undefined) {
            await this.onOAuth2Token(authTokenPayload.authToken);
            return;
        }

        const authErrorPayload = decodeOAuth2AuthErrorMessage(message.data);
        if (authErrorPayload !== undefined) {
            this.setState({
                error: getLocalizedString(authErrorPayload.viaSSO === true ? "ssoAuthError" : "tryAgain", this.appKind),
            });
            return;
        }
    };

    public componentWillUnmount() {
        window.removeEventListener("message", this.onWindowMessageForOauth2Token);
        nativeAuthControllerCallbacks.delete(this.nativeGoogleOauthCallbacks);
        samePageAuthToken.unsubscribe(this.onMaybeOAuth2Token);
    }

    private createPopupForOAuth2Flow = (
        locationConstructor: (statePayload: string, authenticator: AppUserAuthenticator | undefined) => URL
    ) => {
        const oauth2State = convertOAuth2RedirectTokenToState();
        if (oauth2State === undefined) return;

        this.saveAllowLogin(this.state.allowSaveLogin);

        const flowStartLocation = locationConstructor(oauth2State, this.props.credentials.getAuthenticator());
        this.expectedAuthMessageSource = window.open(flowStartLocation.href, "_blank");
    };

    private createPopupForSignOnFlow = (url: string) => {
        this.saveAllowLogin(this.state.allowSaveLogin);

        this.expectedAuthMessageSource = window.open(url, "_blank");
    };

    private async checkLocalStoragePin(
        password: string | undefined,
        email: string,
        loginToken: AppLoginTokenContainer | undefined
    ) {
        this.setCurrentState("sign-in");
        const authed = await this.props.credentials.authenticate(email, password, loginToken);
        if (authed && this.props.onLoggedIn !== undefined) {
            await this.props.onLoggedIn(email);
        } else {
            this.props.onFailed?.();

            this.setCurrentState("credentials");
        }
    }

    private async checkLocalStoragePasscode(passcode: string, loginToken: AppLoginTokenContainer | undefined) {
        this.setCurrentState("sign-in");
        if (this.props.submitPasscode === undefined) {
            return panic("Forgot to assign submit passcode");
        }
        const worked = await this.props.submitPasscode(passcode, loginToken);
        if (!worked) {
            this.setCurrentState("credentials");
        }
        return;
    }

    private async checkLocalStorageToken(
        loginToken: AppLoginTokenContainer,
        email: string | undefined,
        authKind: AppAuthenticationKind
    ) {
        if (authKind !== AppAuthenticationKind.Password && authKind !== AppAuthenticationKind.EmailPin) return;
        this.setCurrentState("sign-in");
        let worked: boolean;
        if (authKind === AppAuthenticationKind.Password) {
            if (this.props.submitPasscode === undefined) {
                return panic("Forgot to assign submit passcode");
            }
            // FIXME: We shouldn't even be trying a passcode in this case
            worked = await this.props.submitPasscode("", loginToken);
        } else {
            const targetEmail = email ?? loginToken.overrideEmail ?? "";
            worked = await this.props.credentials.authenticate(targetEmail, undefined, loginToken);
            if (worked && this.props.onLoggedIn !== undefined) {
                this.onEmailChange(targetEmail);
                this.setCurrentState("sign-in");
                await this.props.onLoggedIn(targetEmail);
            }
        }
        if (!worked) {
            this.setCurrentState("credentials");
        }
        return;
    }

    private saveAllowLogin(allowSaveLogin: boolean) {
        const { appID } = this.props;

        const value = allowSaveLogin ? "true" : "false";
        localStorageSetItem(getAllowLoginSaveKey(appID), value);
    }

    private onPasscodeChange = (passcode: string) => {
        this.setState({ passcode, canContinue: passcode !== "" });
    };

    private onEmailChange = (email: string) => {
        const isValid = isValidEmailAddress(email);
        this.setState({ email, canContinue: isValid, canPressPin: isValid });
    };

    private onPinChange = (pin: string) => {
        this.setState({ pin, canContinue: pin.length > 3, canPressPin: true });
    };

    private onUserAgreedChange = (userAgreed: boolean) => {
        this.setState({ userAgreed });
    };

    private onUserUpdateSaveLogin = (allowSaveLogin: boolean) => {
        this.saveAllowLogin(allowSaveLogin);
        this.setState({ allowSaveLogin });
    };

    private setCurrentState(newState: SignInState, state?: State) {
        state = state ?? this.state;
        this.setState({ currentState: newState, error: undefined });
        if (newState === "credentials") {
            this.onEmailChange(state.email);
        } else if (newState === "challenge") {
            this.onPinChange(state.pin);
        } else if (newState === "username") {
            this.onUsernameChange(state.username);
        }
    }

    private onPasscodePressedContinue = async () => {
        this.setCurrentState("sign-in");
        if (this.props.submitPasscode === undefined) {
            panic("Forgot to assign submit passcode");
        }
        const worked = await this.props.submitPasscode(this.state.passcode, undefined);
        if (worked) {
            localStorageSetItem(getPasswordKey(this.props.appID), this.state.passcode);
        } else {
            this.setState({ error: getLocalizedString("wrongPassword", this.appKind), currentState: "credentials" });
        }
    };

    private onUsernamePressedContinue = async () => {
        const creds = this.props.credentials;
        if (creds.canChangeUsername) {
            await creds.setUsername(this.state.username);
        }
        this.setCurrentState("credentials");
        await this.checkEmailPasswordCanAuth();
    };

    private get canRequestAccess(): boolean {
        const { authKind, authMethod, appFeatures } = this.props;
        if (authMethod === undefined) return false;
        return canRequestAccess(authKind, authMethod, appFeatures);
    }

    private onRequestAccessPressedContinue = async () => {
        const { credentials } = this.props;
        const { appKind } = this;
        if (this.canRequestAccess) {
            this.setState({ isSending: true, canContinue: false });
            const result = await credentials.requestAccess(this.state.email);
            if (result.status === "Forbidden") {
                this.setState({
                    error: getLocalizedString("emailAddressDoesntHaveAccess", appKind),
                    isSending: false,
                    canContinue: true,
                });
                return;
            } else if (result.status === "Offline") {
                this.setState({
                    error: getLocalizedString("networkAppearsOffline", appKind),
                    isSending: false,
                });
                return;
            } else if (result.status === "PaymentRequired") {
                this.setState({
                    error: "This team has reached its user limit. Please contact the app owner to resolve this issue.",
                    isSending: false,
                });
                return;
            } else if (result.status === "Success") {
                this.setState({ isSending: false, canContinue: true });
                this.setCurrentState("request-access-complete");
            }
        }
    };

    private onPressedBack = async () => {
        this.setCurrentState("credentials");
    };

    private onEmailPressedContinue = async () => {
        const { appID, credentials, onLoggedIn, appFeatures } = this.props;
        const { email, userAgreed, pin, currentState, isSending, allowSaveLogin } = this.state;
        const { appKind } = this;

        if (currentState === "credentials") {
            // Some browsers (I'm looking at you Firefox) like to
            // trigger button press callbacks multiple times per press.
            // So we can't rely on the button being deactivated, we have
            // to do this ourselves.
            if (isSending) return;

            this.setState({ canContinue: false, isSending: true });
            const {
                status: sent,
                link,
                target,
                method,
            } = await credentials.sendPin(email, this.privateInviteLinkToken);
            if (sent === "Forbidden") {
                if (this.canRequestAccess) {
                    this.setCurrentState("request-access-prompt");
                    this.setState({ isSending: false, canContinue: true });
                } else {
                    this.setState({
                        error: getLocalizedString("emailAddressDoesntHaveAccess", appKind),
                        isSending: false,
                    });
                }
                return;
            } else if (sent === "Offline") {
                this.setState({
                    error: getLocalizedString("networkAppearsOffline", appKind),
                    isSending: false,
                });
                return;
            } else if (sent === "PaymentRequired") {
                this.setState({
                    error: "This team has reached its user limit. Please contact the app owner to resolve this issue.",
                    isSending: false,
                });
                return;
            }

            // Required here for pin flow to handle the case
            // where backgrounding iOS could terminate the app. We need
            // the email address to continue from the 1/2 pin state
            // This will be removed if appropriate in the credential.authenticate method.
            localStorageSetItem(getEmailKey(appID), email);
            localStorageSetItem(getPinKey(appID), new Date().getTime().toString());

            // Store opt in locally to avoid cookie pop up
            if (appFeatures.askUserToSaveAuthCookie === true) {
                this.saveAllowLogin(allowSaveLogin);
            }

            this.setCurrentState("challenge");
            this.setState({ isSending: false, pinTarget: target ?? "", pinMethod: method ?? "email" });

            // In local-env, it returns a link because no email is sent.
            if (!!link) {
                try {
                    // Don't blow up if the link is invalid
                    const hash = new URL(link).hash;
                    const newURL = new URL(window.location.href);
                    newURL.hash = hash;
                    window.location.href = newURL.toString();
                } catch (e: unknown) {
                    logError("Failed to create URL from returned link", e);
                }
            }
        } else if (currentState === "challenge") {
            this.setCurrentState("sign-in");
            const pw = await credentials.passwordForPin(email, pin, userAgreed);
            if (pw !== undefined) {
                const authed = await credentials.authenticate(email, pw.password, pw.loginToken);
                if (authed && onLoggedIn !== undefined) {
                    await onLoggedIn(email);
                    localStorageRemoveItem(getPinKey(appID));
                } else {
                    this.setState({ error: getLocalizedString("tryAgain", appKind) });
                    this.setCurrentState("credentials");
                }
            } else {
                this.setCurrentState("challenge");
                this.setState({ error: getLocalizedString("wrongPasscode", appKind) });
                return;
            }
        }
    };

    private onPressedPin = () => {
        const { authKind } = this.props;
        if (authKind === AppAuthenticationKind.EmailPin) {
            if (this.state.currentState === "credentials") {
                this.setCurrentState("challenge");
                this.setState({ error: undefined });
            } else if (this.state.currentState === "challenge") {
                this.setState({ error: undefined, pin: "" });
                this.setCurrentState("credentials");
            }
        }
    };

    private onUsernameChange = (newVal: string) => {
        this.setState({ username: newVal, canContinue: newVal.length > 0 });
    };

    private isWithinDateTimestamp(): boolean {
        const date = localStorageGetItem(getPinKey(this.props.appID));
        if (date === undefined) return false;

        return new Date().getTime() - parseInt(date, 10) < MaxPinLifeMins * 60 * 1000;
    }

    public render(): React.ReactNode {
        const {
            authKind,
            withBranding,
            appTitle,
            authMethod,
            appFeatures,
            theme,
            previewMode,
            onClose,
            pagesSource,
            onPageSourceChanged,
            customCssClassName,
            iconImage,
        } = this.props;
        const { appKind } = this;

        const {
            currentState,
            passcode,
            email,
            pin,
            userAgreed,
            allowSaveLogin,
            canContinue,
            canPressPin,
            error,
            username,
            pinTarget,
            pinMethod,
        } = this.state;
        let content: React.ReactNode;
        let colorTheme: "white" | "black" = "white";
        const lumin = color(this.props.accentColor).luminosity();
        if (lumin > 0.9 && theme.signInBackground === undefined) {
            // force if illegible
            colorTheme = "black";
        } else if (lumin < 0.1 && theme.signInBackground === undefined) {
            // force if illegible
            colorTheme = "white";
        } else if (theme.signInColorTheme !== undefined) {
            // take provided value
            colorTheme = theme.signInColorTheme as "white" | "black";
        } else if (lumin > 0.5) {
            // no provided value, guess
            colorTheme = "black";
        }
        const isPage = this.appKind === AppKind.Page;
        const isPro = !withBranding;

        let defaultGreeting = getLocalizedString("welcome", this.appKind);
        if (isPage) {
            defaultGreeting = getDefaultPagesGreeting(pagesSource, appTitle, this.appKind);
        }

        const removeBranding = this.props.flags?.removeBranding ?? false;
        const canCustomizeSignIn = this.props.flags?.canCustomizeSignIn ?? false;

        const commonProps = {
            colorTheme,
            isPro,
            appKind,
            greeting: theme.signInGreetingText ?? defaultGreeting,
            currentState,
            userEmail: email,
            username,
            passcode,
            pin,
            userAgreed,
            allowSaveLogin,
            backgroundOverlay: isPro ? (theme.signInBackgroundOverlay as "none" | "dark" | "gradient") : "none",
            background: theme.signInBackground,
            icon: theme.signInLogo,
            logoSize: theme.signInLogoSize as "small" | "medium" | "large",
            onEmailChange: this.onEmailChange,
            onPasscodeChange: this.onPasscodeChange,
            onUsernameChange: this.onUsernameChange,
            onPinChange: this.onPinChange,
            onUserAgreedChange: this.onUserAgreedChange,
            onUserUpdateSaveLogin: this.onUserUpdateSaveLogin,
            onPressedPin: this.onPressedPin,
            canContinue,
            error,
            appTitle,
            onEnterOAuth2Flow: this.onEnterOAuth2Flow,
            onFailedOAuth2Flow: this.onFailedOAuth2Flow,
            onUnverifiedOAuth2Flow: this.onUnverifiedOAuth2Flow,
            onOauth2Token: this.onOAuth2Token,
            createPopupForOAuth2Flow: this.createPopupForOAuth2Flow,
            createPopupForSignOnFlow: this.createPopupForSignOnFlow,
            authMethod,
            appFeatures,
            isSending: this.state.isSending,
            previewMode,
            theme,
            iconImage,
            pinTarget,
            pinMethod,
            removeBranding,
            canCustomizeSignIn,
            isOSThemeDark: this.props.isOSThemeDark,
            appID: this.props.appID,
        };

        if (currentState === "username") {
            content = isPage ? (
                <CustomWireSignIn
                    {...commonProps}
                    pagesSource={pagesSource}
                    onPageSourceChanged={onPageSourceChanged}
                    loginType="none"
                    greeting={getLocalizedString("whatsYourName", appKind)}
                    onPressedContinue={this.onUsernamePressedContinue}
                />
            ) : (
                <CustomSignIn
                    {...commonProps}
                    loginType="none"
                    greeting={getLocalizedString("whatsYourName", appKind)}
                    onPressedContinue={this.onUsernamePressedContinue}
                />
            );
        } else if (this.canRequestAccess && currentState === "request-access-prompt") {
            content = isPage ? (
                <CustomWireSignIn
                    {...commonProps}
                    pagesSource={pagesSource}
                    onPageSourceChanged={onPageSourceChanged}
                    loginType="email"
                    greeting={getAnyLocalizedString("requestAppUserAccessHeader", appKind)}
                    description={getAnyLocalizedString("requestAppUserAccessDescription", appKind)}
                    onPressedContinue={this.onRequestAccessPressedContinue}
                    onPressedBack={this.onPressedBack}
                />
            ) : (
                <CustomSignIn
                    {...commonProps}
                    loginType="email"
                    greeting={getAnyLocalizedString("requestAppUserAccessHeader", appKind)}
                    description={getAnyLocalizedString("requestAppUserAccessDescription", appKind)}
                    onPressedContinue={this.onRequestAccessPressedContinue}
                    onPressedBack={this.onPressedBack}
                />
            );
        } else if (this.canRequestAccess && currentState === "request-access-complete") {
            content = isPage ? (
                <CustomWireSignIn
                    {...commonProps}
                    pagesSource={pagesSource}
                    onPageSourceChanged={onPageSourceChanged}
                    loginType="email"
                    greeting={getAnyLocalizedString("requestSent", appKind)}
                    description={getAnyLocalizedString("requestCompleteAppUserAccessDescription", appKind)}
                    onPressedBack={this.onPressedBack}
                />
            ) : (
                <CustomSignIn
                    {...commonProps}
                    loginType="email"
                    greeting={getAnyLocalizedString("requestSent", appKind)}
                    description={getAnyLocalizedString("requestCompleteAppUserAccessDescription", appKind)}
                    onPressedBack={this.onPressedBack}
                />
            );
        } else if (authKind === AppAuthenticationKind.EmailPin) {
            content = isPage ? (
                <CustomWireSignIn
                    {...commonProps}
                    pagesSource={pagesSource}
                    onPageSourceChanged={onPageSourceChanged}
                    loginType="email"
                    description={theme.signInDescriptionText ?? getLocalizedString("pleaseEnterEmail", appKind)}
                    onPressedContinue={this.onEmailPressedContinue}
                    canPressPin={canPressPin}
                />
            ) : (
                <CustomSignIn
                    {...commonProps}
                    loginType="email"
                    description={theme.signInDescriptionText ?? getLocalizedString("pleaseEnterEmail", appKind)}
                    onPressedContinue={this.onEmailPressedContinue}
                    canPressPin={canPressPin}
                />
            );
        } else if (authKind === AppAuthenticationKind.Password) {
            content = isPage ? (
                <CustomWireSignIn
                    {...commonProps}
                    pagesSource={pagesSource}
                    onPageSourceChanged={onPageSourceChanged}
                    loginType="password"
                    description={theme.signInDescriptionText ?? getLocalizedString("pleaseEnterPassword", appKind)}
                    onPressedContinue={this.onPasscodePressedContinue}
                />
            ) : (
                <CustomSignIn
                    {...commonProps}
                    loginType="password"
                    description={theme.signInDescriptionText ?? getLocalizedString("pleaseEnterPassword", appKind)}
                    onPressedContinue={this.onPasscodePressedContinue}
                />
            );
        } else {
            panic("Hell froze over");
        }
        const platformTheme = isPage
            ? getWireAppThemeForPlatform(
                  {
                      themeOverlay: theme.themeOverlay ?? "none",
                      primaryAccentColor: this.props.accentColor,
                      showTabLabels: true,
                      increaseContrast: false,
                      showDesktopSideBar: false,
                      themeIsAdaptive: theme.themeIsAdaptive,
                      pageTheme: theme.pageTheme,
                  },
                  this.props.isOSThemeDark
              )
            : getRuntimeThemeForPlatform({
                  themeOverlay: theme.themeOverlay ?? "none",
                  primaryAccentColor: this.props.accentColor,
                  showTabLabels: true,
                  increaseContrast: false,
                  showDesktopSideBar: false,
                  themeIsAdaptive: theme.themeIsAdaptive,
              });

        return (
            <TailwindThemeProvider
                theme={platformTheme}
                className={classNames(customCssClassName, isPage ? "page-controller" : "app-controller")}
                css={css`
                    &.page-controller {
                        ${tw`w-full`}
                    }
                `}
            >
                {content}
                {onClose !== undefined && (
                    <CloseContainer color={colorTheme} onClick={onClose} role="button" aria-label="close">
                        <AppIcon icon="00-01-glide-close" />
                    </CloseContainer>
                )}
            </TailwindThemeProvider>
        );
    }
}

const AuthController = withOSTheme(withEminence(withTheme(withCredentials(AuthControllerImpl))));
export default AuthController;
