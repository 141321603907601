import { backCompatIcon, type IconName } from "@glide/common";
import type { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { getColumnNameAndGroup } from "@glide/generator/dist/js/description-utils";
import type { SpecialValueDescriptor } from "@glide/generator/dist/js/components/special-values";
import type { TableName } from "@glide/type-schema";
import { hasOwnProperty } from "@glideapps/ts-necessities";
import React from "react";

export type ItemPath = readonly number[];

export type Separator = null;
export const separator: Separator = null;

export type Header = {
    kind: "Header";
    name: string;
};

export function isSeparator(x: unknown): x is Separator {
    return x === separator;
}

export type ItemsType<T> = T | Group<T> | Separator | Header;

export interface Group<T> {
    readonly kind: "group";
    readonly name: string;
    readonly items: readonly ItemsType<T>[];
    readonly hint?: string;
    readonly icon?: string | React.ReactNode;
    readonly iconColor?: string;
    readonly isEnabled?: boolean;
    readonly badge?: string;
}

export type VisualStyle = "normal" | "destructive" | "warning" | "callout";

export interface MenuItem {
    name: string;
    icon: JSX.Element;
    action: () => void;
    visualStyle?: VisualStyle;
}

export function isMenuItem(x: unknown): x is MenuItem {
    if (typeof x !== "object" || x === null) return false;
    const { action, name, icon } = x as any;
    return typeof action === "function" && typeof name === "string" && React.isValidElement(icon);
}

export interface ItemDescription {
    readonly name: string;
    readonly namePrefix?: string;
    readonly wrapName?: boolean;
    readonly selectedName?: string;
    readonly hint?: string;
    readonly tooltip?: string;
    readonly accelerator?: [string] | [string, string];
    readonly icon?: string | React.ReactNode | IconName;
    readonly iconColor?: string;
    readonly badge?: string;
    readonly selectedIcon?: string | React.ReactNode | IconName;
    readonly actionIcon?: string | React.ReactNode | IconName;
    readonly overlayIcon?: IconName;
    readonly columnWritten?: [TableName, string];
    readonly isEnabled?: boolean;
    readonly layoutStyle?: "horizontal" | "vertical";
    readonly visualStyle?: VisualStyle;
    readonly requiredPlan?: PlanKind;
    readonly requiredV4Plan?: string;
    readonly additionalSearchTerms?: string[];
    readonly disablePrelight?: boolean;
}

interface FlatSortArg<T> {
    readonly item: T;
    readonly path: readonly Group<T>[];
}

export interface BaseDropdownProps<T> {
    readonly items: readonly ItemsType<T>[];
    readonly className?: string;
    readonly selected?: T;
    readonly keyboardListenerMode?: "default" | "none" | "navigation" | "accelerators";
    readonly width?: number;
    readonly height?: number;
    readonly searchable?: boolean;
    readonly showSearchLineage?: boolean | number; // if true, show all lineage, if number, show the last n items
    readonly prelightDebounceMs?: number;
    readonly testId?: string;

    // callbacks
    readonly descriptionForItem: (item: T, button?: boolean) => ItemDescription | string;
    readonly flatSort?: (a: FlatSortArg<T>, b: FlatSortArg<T>) => number;

    // events
    readonly onItemClicked?: (item: T, path: ItemPath, e: React.MouseEvent) => void;
    readonly onItemActionClicked?: (item: T, path: ItemPath, e: React.MouseEvent) => void;
    readonly onGroupClicked?: (group: Group<T>, path: ItemPath, e: React.MouseEvent) => void;
    readonly onItemSelect?: (item: T, path: ItemPath) => void;
}

export interface InnerItemDescription<T> extends Omit<ItemDescription, "accelerator"> {
    readonly item: T | Group<T> | Header;
    readonly accelerator?: string;
    readonly lineage?: readonly InnerItemDescription<T>[];
    readonly prelight: boolean;
    readonly containsPrelight: boolean;
    readonly prelighting: boolean;
    readonly containsPrelighting: boolean;
    readonly isSelected: boolean;
    readonly children?: readonly (InnerItemDescription<T> | null)[];
    readonly indexPath: readonly number[];
    readonly iconColor?: string;
}

export function isGroup<T>(obj: ItemsType<T> | undefined): obj is Group<T> {
    return (
        obj !== undefined &&
        obj !== null &&
        hasOwnProperty(obj, "kind") &&
        obj.kind === "group" &&
        typeof obj.name === "string"
    );
}

export function isHeader<T>(obj: ItemsType<T> | undefined): obj is Header {
    return (
        obj !== undefined &&
        obj !== null &&
        hasOwnProperty(obj, "kind") &&
        obj.kind === "Header" &&
        typeof obj.name === "string"
    );
}

export function isValue<T>(obj: ItemsType<T> | undefined): obj is T {
    return obj !== undefined && obj !== null && !isGroup(obj) && !isHeader(obj);
}

export function findPathInGroup<T>(root: readonly ItemsType<T>[], needle: T): readonly (T | Group<T>)[] | undefined {
    const walk = (toWalk: readonly ItemsType<T>[]): readonly (T | Group<T>)[] | undefined => {
        for (const i of toWalk) {
            if (i === null) continue;
            if (i === needle) return [needle];

            if (isGroup(i)) {
                const walked = walk(i.items);
                if (walked !== undefined) {
                    return [i, ...walked];
                }
            }
        }

        return undefined;
    };

    return walk(root);
}

export function pointIsInShadow(
    start: [number, number],
    current: [number, number],
    throat: number,
    slopeDeg: number,
    deadZone: number = 0
) {
    const [x1, y1] = start;
    const [x2, y2] = current;
    const s = throat;
    const alpha = slopeDeg * (Math.PI / 180);
    const w = deadZone;
    const d = Math.abs(x2 - x1);
    const h = s / 2 + Math.tan(alpha) * d;
    return Math.abs(y2 - y1) <= h && d >= w;
}

export function groupSpecialValues(
    specialValues: readonly SpecialValueDescriptor[]
): ItemsType<SpecialValueDescriptor>[] {
    const groups = new Map<string, SpecialValueDescriptor[]>();
    const standalone: SpecialValueDescriptor[] = [];

    for (const specialValue of specialValues) {
        if (typeof specialValue.kind === "string") {
            standalone.push(specialValue);
            continue;
        }

        const [groupName, label] = getColumnNameAndGroup(specialValue.label);
        if (groupName === undefined) {
            standalone.push(specialValue);
            continue;
        }

        if (!groups.has(groupName)) {
            groups.set(groupName, []);
        }
        groups.get(groupName)?.push({
            ...specialValue,
            label,
        });
    }

    const result: ItemsType<SpecialValueDescriptor>[] = [...standalone];

    for (const [groupName, items] of groups) {
        const group: Group<SpecialValueDescriptor> = {
            kind: "group",
            name: groupName,
            items,
            icon: backCompatIcon(items[0]?.img, 16),
        };
        result.push(group);
    }

    return result;
}
