import type { AppDescription } from "@glide/app-description";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { Icon } from "@glide/common-core/dist/js/render/icon";

import { UnifiedProjectIcon } from "./unified-project-icon";

interface AppIconProps {
    readonly features: AppDescription["features"];
    readonly theme: AppDescription["theme"];
    readonly iconImage: AppDescription["iconImage"];
    readonly iconSize?: number;
    readonly hideClassicIndicator?: boolean;
    readonly appID: string;
}

export const AppOrPageIcon: React.FC<AppIconProps> = p => {
    const { features, theme, iconImage, hideClassicIndicator, iconSize = 32, appID } = p;

    const appKind = getAppKindFromFeatures(features);

    // TODO: When this feature is turned on, we should remove this component
    return (
        <UnifiedProjectIcon
            appKind={appKind}
            hideClassicIndicator={hideClassicIndicator}
            classicIcon={
                <Icon
                    clipCorners={true}
                    color={theme.primaryAccentColor}
                    size={iconSize}
                    image={iconImage}
                    appID={appID}
                />
            }
        />
    );
};
