import { getLocalizedString } from "@glide/localization";
import { type LoadedGroundValue, isLoadingValue, isPrimitiveValue } from "@glide/computation-model-types";
import { asMaybeBoolean, asMaybeString, asString } from "@glide/common-core/dist/js/computation-model/data";
import { QuotaKind } from "@glide/common-core/dist/js/Database/quotas";
import {
    type ActionDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
    PropertyKind,
    getSwitchProperty,
} from "@glide/app-description";
import type { DeliverEmailFromActionBody } from "@glide/common-core/dist/js/firebase-function-types";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    getStringTypeOrStringTypeArrayColumnsSpec,
    makeInlineTemplatePropertyDescriptor,
    makeTextPropertyDescriptor,
    PropertySection,
} from "@glide/function-utils";
import { isArray, nullToUndefined, splitEmailAddresses } from "@glide/support";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import omit from "lodash/omit";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import type { DescriptionToken } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { getFeatureSetting } from "@glide/common-core";
import type { BillablesConsumed } from "@glide/plugins-codecs";
import { ICON_BASE, PURPLE_500 } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins";

interface DeliverEmailActionDescription extends ActionDescription {
    readonly recipient: PropertyDescription;
    readonly cc: PropertyDescription;
    readonly bcc: PropertyDescription;
    readonly subject: PropertyDescription;
    readonly body: PropertyDescription;
    readonly replyTo: PropertyDescription | undefined;
    readonly includeAppLink: PropertyDescription | undefined;
    readonly isReply: PropertyDescription;
    readonly inReplyTo: PropertyDescription | undefined;
}

const cleanEmails = (value: LoadedGroundValue): string[] => {
    const result: string[] = [];
    if (isArray(value)) {
        for (const v of value) {
            result.push(...splitEmailAddresses(asString(v)));
        }
    } else if (isPrimitiveValue(value)) {
        result.push(...splitEmailAddresses(asString(value)));
    }
    return result;
};

function makeEmailFieldProperties(mutatingScreenKind: MutatingScreenKind | undefined): PropertyDescriptor[] {
    return [
        makeInlineTemplatePropertyDescriptor(
            "recipient",
            "To",
            "Enter email addresses",
            true,
            "withLabel",
            mutatingScreenKind,
            {
                preferredNames: ["to", "email"],
                preferredType: "email-address",
                searchable: false,
                columnFirst: true,
                columnFilter: getStringTypeOrStringTypeArrayColumnsSpec,
            }
        ),
        makeInlineTemplatePropertyDescriptor(
            "cc",
            "Cc",
            "Enter email addresses",
            true,
            "withLabel",
            mutatingScreenKind,
            {
                preferredNames: ["cc"],
                preferredType: "email-address",
                searchable: false,
                columnFirst: true,
                emptyByDefault: true,
                columnFilter: getStringTypeOrStringTypeArrayColumnsSpec,
            }
        ),
        makeInlineTemplatePropertyDescriptor(
            "bcc",
            "Bcc",
            "Enter email addresses",
            true,
            "withLabel",
            mutatingScreenKind,
            {
                preferredNames: ["bcc"],
                preferredType: "email-address",
                searchable: false,
                columnFirst: true,
                emptyByDefault: true,
                columnFilter: getStringTypeOrStringTypeArrayColumnsSpec,
            }
        ),
        makeInlineTemplatePropertyDescriptor(
            "subject",
            "Subject",
            "Enter subject line",
            true,
            "withLabel",
            mutatingScreenKind,
            {
                preferredNames: ["subject", "title"],
                searchable: false,
                columnFirst: true,
            }
        ),
        makeInlineTemplatePropertyDescriptor(
            "body",
            "Body",
            "Enter message body",
            true,
            "withLabel",
            mutatingScreenKind,
            {
                preferredNames: ["body", "message"],
                searchable: false,
                columnFirst: true,
            }
        ),
        makeInlineTemplatePropertyDescriptor(
            "replyTo",
            "Reply to",
            "Enter email address",
            true,
            "withLabel",
            mutatingScreenKind,
            {
                preferredNames: ["replyto", "sender", "from"],
                preferredType: "email-address",
                searchable: false,
                emptyByDefault: true,
            }
        ),
    ];
}

export class DeliverEmailHandler extends BaseActionHandler<DeliverEmailActionDescription> {
    public readonly kind = ActionKind.DeliverEmail;
    public readonly iconName: GlideIconProps = {
        icon: "st-paper-plane-top-right",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: PURPLE_500,
    };

    public readonly name = "Send email";

    public getBillablesConsumed(_env: StaticActionContext<AppDescriptionContext>): BillablesConsumed | undefined {
        if (getFeatureSetting("billingV4EnableDeliverEmailFromActionUsage")) {
            return 1;
        }
        return undefined;
    }

    public getDescriptor(
        _desc: DeliverEmailActionDescription | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Communication,
            groupItemOrder: 2,
            needsScreenContext: true,
            quotaWarning:
                ccc.eminenceFlags.quotas[QuotaKind.DeliverEmail].prepaidUsage <= 0
                    ? "Please upgrade to a plan that offers sending Email."
                    : undefined,
            properties: [
                ...makeEmailFieldProperties(mutatingScreenKind),
                {
                    label: "Is reply",
                    property: { name: "isReply" },
                    section: PropertySection.Options,
                    kind: PropertyKind.Switch,
                    defaultValue: false,
                },
                makeTextPropertyDescriptor(
                    "inReplyTo",
                    "Message ID",
                    "ID of the message to reply to",
                    false,
                    mutatingScreenKind,
                    {
                        preferredNames: ["inreplyto", "in-reply-to", "message id"],
                        searchable: false,
                        columnFirst: true,
                        helpText: "The Email trigger returns this. It will be used as the `In-Reply-To` header.",
                    },
                    undefined,
                    desc => {
                        return getSwitchProperty(desc.isReply) ?? false;
                    }
                ),
                {
                    label: "Include button with link to app",
                    property: { name: "includeAppLink" },
                    section: PropertySection.Options,
                    kind: PropertyKind.Switch,
                    defaultValue: true,
                },
                {
                    kind: PropertyKind.Warning,
                    property: { name: "dummy" },
                    label: "Notice",
                    section: PropertySection.Data,
                    text: "Teams on trials or with past due payments cannot use this action.",
                },
            ],
        };
    }

    public getTokenizedDescription(
        desc: DeliverEmailActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const columnToken = tokenForProperty(desc.recipient, env);
        if (columnToken === undefined || columnToken.value.trim().length === 0) return undefined;

        return [
            {
                kind: "string",
                value: "Email",
            },
            columnToken,
        ];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: DeliverEmailActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            appFacilities,
            adc: { appID, appKind },
        } = ib;
        const [recipientGetter] = ib.getValueGetterForProperty(desc.recipient, false);
        const [ccGetter] = ib.getValueGetterForProperty(desc.cc, false);
        const [bccGetter] = ib.getValueGetterForProperty(desc.bcc, false);
        const [subjectGetter] = ib.getValueGetterForProperty(desc.subject, true);
        const [bodyGetter] = ib.getValueGetterForProperty(desc.body, true);
        const [replyToGetter] = ib.getValueGetterForProperty(desc.replyTo, false);
        const [appLinkGetter] = ib.getValueGetterForProperty(desc.includeAppLink, false);
        const [inReplyToGetter] = ib.getValueGetterForProperty(desc.inReplyTo, false);

        return (vp, skipLoading) => {
            const recipientValue = nullToUndefined(recipientGetter(vp));
            if (isLoadingValue(recipientValue)) return arbBase.maybeSkipLoading(skipLoading, "Recipient");

            const ccValue = nullToUndefined(ccGetter(vp));
            if (isLoadingValue(ccValue)) return arbBase.maybeSkipLoading(skipLoading, "Cc");

            const bccValue = nullToUndefined(bccGetter(vp));
            if (isLoadingValue(bccValue)) return arbBase.maybeSkipLoading(skipLoading, "Bcc");

            const subjectValue = nullToUndefined(subjectGetter(vp));
            if (isLoadingValue(subjectValue)) return arbBase.maybeSkipLoading(skipLoading, "Subject");
            const subject = asMaybeString(subjectValue);

            const bodyValue = nullToUndefined(bodyGetter(vp));
            if (isLoadingValue(bodyValue)) return arbBase.maybeSkipLoading(skipLoading, "Body");
            const messageBody = asMaybeString(bodyValue);

            const replyToValue = nullToUndefined(replyToGetter(vp));
            if (isLoadingValue(replyToValue)) return arbBase.maybeSkipLoading(skipLoading, "Reply to");
            const replyTo = asMaybeString(replyToValue);

            const appLinkValue = nullToUndefined(appLinkGetter(vp));
            if (isLoadingValue(appLinkValue)) return arbBase.maybeSkipLoading(skipLoading, "App link");
            const includeAppLink = asMaybeBoolean(appLinkValue) ?? true;

            const inReplyToValue = nullToUndefined(inReplyToGetter(vp));
            if (isLoadingValue(inReplyToValue)) return arbBase.maybeSkipLoading(skipLoading, "In reply to");
            const inReplyTo = asMaybeString(inReplyToValue);
            const isReply = getSwitchProperty(desc.isReply) ?? false;

            const to = cleanEmails(recipientValue);

            const cc = cleanEmails(ccValue);
            const bcc = cleanEmails(bccValue);

            if (subject === undefined || messageBody === undefined)
                return arbBase.error(true, "Missing subject or body");

            const body: DeliverEmailFromActionBody = {
                to,
                cc,
                bcc,
                subject: isReply ? `Re: ${subject}` : subject,
                body: messageBody,
                replyTo,
                appID,
                includeAppLink,
                inReplyTo: isReply ? inReplyTo : undefined,
            };
            const arb = arbBase.addData(omit(body, ["appID"]));

            if (to.length === 0) return arb.nothingToDo("No email addresses to send to");

            return async ab => {
                const response = await appFacilities.callAuthIfAvailableCloudFunction(
                    "deliverEmailFromAction",
                    body,
                    {}
                );
                const textResponse = await response?.text();
                if (response?.ok !== true) {
                    let errorMessage: string | undefined;
                    try {
                        errorMessage = JSON.parse(textResponse ?? "").error;
                    } catch {
                        //
                    }
                    ab.actionCallbacks.showToast(false, getLocalizedString("error", appKind));
                    return arb.errorFromHTTPStatus(
                        response?.status ?? 500,
                        errorMessage ?? "Unknown error sending email"
                    );
                }
                return arb.success();
            };
        };
    }
}
