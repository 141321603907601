import * as React from "react";
import { coreTheme, type CoreTheme } from "./core-theme.js";
import { wireAppThemeKind, type WireAppTheme } from "./wireapp-theme.js";
import { builderThemeKind, type BuilderTheme } from "./builder-theme.js";

const ThemeContext = React.createContext<CoreTheme | WireAppTheme | BuilderTheme | undefined>(undefined);

function setAttributes(portal: Element, css: Record<string, string>): void {
    const result = Object.keys(css)
        .map(key => `${key}:${css[key]}`)
        .join("; ");
    portal.setAttribute("style", result);
}

function keyToVariableName(str: string): string {
    return `--gv-${str.replaceAll(/[a-z][A-Z]/g, x => [...x].map(c => c.toLowerCase()).join("-"))}`;
}

const coreThemeKeys = Object.keys(coreTheme) as (keyof CoreTheme)[];

interface TailwindThemeProviderProps {
    theme: CoreTheme;
    children: React.ReactNode;
    setPortal?: boolean;
    setModalRoot?: boolean;
    setAllProperties?: boolean;
    className?: string;
}

// borrowed from webapp/lib/constants
// This is the 7th time we copy this value
const APP_MODAL_ROOT = "app-modal-root";

export const TailwindThemeProvider: React.FC<TailwindThemeProviderProps> = p => {
    const { theme, children, setPortal = false, setModalRoot, className, setAllProperties = false } = p;

    const parentTheme = React.useContext(ThemeContext);

    const [css, portalCss] = React.useMemo(() => {
        const vars: Record<string, string> = {};
        const forPortal: Record<string, string> = {};
        for (const key of coreThemeKeys) {
            if (typeof theme[key] !== "string") continue;
            if (theme[key] !== parentTheme?.[key] || setAllProperties) {
                vars[keyToVariableName(key)] = theme[key];
            }
            if (setPortal === true) {
                forPortal[keyToVariableName(key)] = theme[key];
            }
        }
        return [vars, forPortal];
    }, [parentTheme, setAllProperties, setPortal, theme]);

    React.useLayoutEffect(() => {
        const portal = document.getElementById("portal");
        if (portal !== null && setPortal) {
            setAttributes(portal, portalCss);
        }

        const modalRoot = document.getElementById(APP_MODAL_ROOT);
        if (modalRoot !== null && setModalRoot) {
            setAttributes(modalRoot, portalCss);
        }
    });

    return (
        <ThemeContext.Provider value={theme}>
            <div className={className} style={css}>
                {children}
            </div>
        </ThemeContext.Provider>
    );
};

export function useCoreTheme(): CoreTheme {
    const theme = React.useContext(ThemeContext);

    if (theme === undefined) {
        throw new Error("Cannot use CoreTheme outside of a TailwindThemeProvider");
    }

    return theme;
}

export function isWireAppTheme(theme: CoreTheme | WireAppTheme | BuilderTheme): theme is WireAppTheme {
    return theme.themeKind === wireAppThemeKind;
}

export function isBuilderTheme(theme: CoreTheme | WireAppTheme | BuilderTheme): theme is BuilderTheme {
    return theme.themeKind === builderThemeKind;
}
