import type { GlideFC } from "@glide/common";
import { ErrorBoundary, GlideIcon } from "@glide/common";
import { PastAndOrFuture } from "@glide/common-core/dist/js/components/past-future";
import { lazilyLoadedComponent } from "@glide/common-core/dist/js/support/react-lazy-loading";

import {
    convertDateToTimeZoneAgnostic,
    dateClearedTime,
    DateFormat,
    dateNoSeconds,
    formatDateTime,
    TimeFormat,
} from "@glide/data-types";
import type { AppKind } from "@glide/location-common";
import type { DateTimeVariant } from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";
import * as React from "react";
import { Suspense } from "react";
import type { FormElementProps } from "../../wire-lib";

import { WireBasePicker } from "../wire-base-picker/wire-base-picker";

const WireMaterialDateTimePicker = lazilyLoadedComponent(
    "material-date-time-picker",
    () => import("./private/wire-material-date-time-picker")
);

interface Props extends FormElementProps {
    readonly variant: DateTimeVariant;
    readonly placeholder: string;
    readonly value: Date | undefined;
    readonly pastAndOrFuture: PastAndOrFuture;
    readonly appKind: AppKind;
    readonly onChange: (value: Date | undefined) => void;
}

export const WireDateTimePicker: GlideFC<Props> = p => {
    const [picker, setPicker] = React.useState<Boolean>(false);
    const [pickerValue, setPickerValue] = React.useState<Date | undefined>(undefined);
    const { variant, title, placeholder, value, pastAndOrFuture, appKind, onChange, isRequired, isEnabled } = p;

    const showPicker = React.useCallback((): void => {
        if (value === undefined) {
            const defaultDateValue = variant === "date" ? dateClearedTime(new Date()) : dateNoSeconds(new Date());
            setPickerValue(defaultDateValue);
        }
        setPicker(true);
    }, [value, variant]);

    const hidePicker = React.useCallback((): void => {
        setPicker(false);
    }, []);

    const onClear = React.useCallback(
        (ev: React.MouseEvent<HTMLElement>): void => {
            onChange?.(undefined);
            ev.stopPropagation();
        },
        [onChange]
    );

    const onUpdate = React.useCallback((dateTime: Date | undefined): void => {
        setPickerValue(dateTime);
    }, []);

    const onCancel = React.useCallback((): void => {
        hidePicker();
    }, [hidePicker]);

    const onAccept = React.useCallback((): void => {
        if (onChange === undefined || pickerValue === null) return;

        onChange(pickerValue);

        hidePicker();
    }, [hidePicker, onChange, pickerValue]);

    const hasValue = value !== undefined;

    let valueString = placeholder;
    const localDate = definedMap(value, convertDateToTimeZoneAgnostic);
    if (variant === "date" && localDate !== undefined) {
        valueString = formatDateTime(localDate, DateFormat.Short, undefined) ?? "";
    } else if (variant === "date-time" && localDate !== undefined) {
        valueString = formatDateTime(localDate, DateFormat.Short, TimeFormat.WithSeconds) ?? "";
    }

    return (
        <>
            <WireBasePicker
                isEnabled={isEnabled}
                isRequired={isRequired}
                title={title}
                onClick={showPicker}
                leftItem={<GlideIcon kind="monotone" icon="mt-page-calendar" iconSize={20} />}
                rightItem={hasValue ? "clear" : undefined}
                rightItemOnClick={onClear}
                {...p}>
                {valueString}
            </WireBasePicker>
            {picker && (
                <ErrorBoundary>
                    <Suspense fallback={<></>}>
                        <WireMaterialDateTimePicker
                            type={variant}
                            value={pickerValue ?? value ?? new Date()}
                            disablePast={pastAndOrFuture === PastAndOrFuture.TodayOrFuture}
                            disableFuture={pastAndOrFuture === PastAndOrFuture.TodayOrPast}
                            onUpdate={onUpdate}
                            onCancel={onCancel}
                            onAccept={onAccept}
                            appKind={appKind}
                        />
                    </Suspense>
                </ErrorBoundary>
            )}
        </>
    );
};
