import type { NonUserAppFeatures } from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import { isGoogleSignInEnabled } from "@glide/common-core/dist/js/utility/pins";
import { getLocalizedString } from "@glide/localization";
import { WireButton, useAuthAgreement, useWireAppTheme } from "@glide/wire-renderer";
import { GoogleLogo, isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import classNames from "classnames";
import { defined } from "@glideapps/ts-necessities";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { usePlayerEminenceFlags } from "@glide/player-core";
import { isDefined } from "@glide/support";
import { UIButtonAppearance } from "@glide/wire";
import "twin.macro";
import type { OAuthAuthenticator } from "@glide/auth-controller-core";
import React from "react";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import { useOAuthListener } from "./use-oauth-listener";
import { GlideIcon } from "@glide/common";

interface GoogleAuthControllerProps extends React.PropsWithChildren {
    readonly appFeatures: NonUserAppFeatures;
    readonly appTitle: string;
    readonly authenticator: OAuthAuthenticator;
    readonly onLoggedIn: () => void;
    /**
     * Call this method if you need the entire auth screen for yourself.
     * For example: to show a "signing in..." message
     */
    readonly requestExclusiveRender: () => void;
    readonly yieldExclusiveRender: () => void;
}

export const GoogleAuthController: React.VFC<GoogleAuthControllerProps> = p => {
    const { appFeatures, authenticator, onLoggedIn, requestExclusiveRender, yieldExclusiveRender } = p;
    const { requiresTerms, userAgreed } = useAuthAgreement();

    if (!isGoogleSignInEnabled(appFeatures)) {
        return null;
    }

    return (
        <GoogleAuthButton
            authenticator={authenticator}
            onLoggedIn={onLoggedIn}
            requestExclusiveRender={requestExclusiveRender}
            yieldExclusiveRender={yieldExclusiveRender}
            disabled={requiresTerms && !userAgreed}
        />
    );
};

interface GoogleAuthButtonProps {
    readonly authenticator: OAuthAuthenticator;
    readonly onLoggedIn: () => void;
    readonly requestExclusiveRender: () => void;
    readonly yieldExclusiveRender: () => void;
    readonly disabled: boolean;
}

const GoogleAuthButton: React.VFC<GoogleAuthButtonProps> = p => {
    const { authenticator, onLoggedIn, requestExclusiveRender, yieldExclusiveRender, disabled } = p;

    const theme = useWireAppTheme();
    const { pageTheme } = theme;

    const appID = defined(useAppID());
    const eminenceFlags = usePlayerEminenceFlags(appID);
    const hasBackgroundImage = isDefined(theme.signInBackground) && eminenceFlags.canCustomizeSignIn;

    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    const themeAndSizeClasses = classNames(
        pageTheme,
        isMobile ? "mobile" : "desktop",
        hasBackgroundImage ? "has-background-image" : "no-background-image"
    );

    const { isWaitingForAuth, error, clearError } = useOAuthListener({
        authenticator,
        onLoggedIn,
        requestExclusiveRender,
        yieldExclusiveRender,
    });

    const onClick = React.useCallback(() => {
        clearError();
        const url = getOAuthFlowStartURL(appID);
        authenticator.startOAuthFlow(url);
    }, [appID, authenticator, clearError]);

    if (isWaitingForAuth) {
        return (
            <div tw="flex justify-center w-full py-4">
                <GlideIcon kind="stroke" icon="st-half-spinner" spin={true} strokeColor={theme.darkAccent} />
            </div>
        );
    }

    return (
        <>
            <div
                className={themeAndSizeClasses}
                tw="h-px mx-3 my-3 bg-n100 page-md:my-4
                    [&.Highlight]:bg-n200A
                    [&.Accent.mobile]:bg-n200A
                    [&.has-background-image]:bg-w10A
                    [&.Dark]:bg-w10A"
            />
            <WireButton
                className={themeAndSizeClasses}
                tw="w-full h-12 rounded-[10px] hover:after:([background: rgba(0,0,0,0.02)]) mt-3 page-md:mt-4 [box-shadow: none]!
                    [&.has-background-image]:(bg-w20A)
                    [&.Dark]:(bg-w20A)
                    [&.Accent.mobile]:(bg-w20A text-white)
                    [&.Highlight]:(bg-n100)
                    [&.Accent]:(bg-n100)"
                id="sign-in-with-google-button"
                appearance={UIButtonAppearance.Bordered}
                onClick={onClick}
                disabled={disabled}>
                <div tw="flex justify-center items-center cursor-pointer">
                    <GoogleLogo fill={undefined} />
                    <label
                        className={themeAndSizeClasses}
                        tw="ml-2.5 pointer-events-none [&.Dark]:text-white [&.has-background-image]:text-white">
                        {getLocalizedString("continueWithGoogle", AppKind.Page)}
                    </label>
                </div>
            </WireButton>

            <div tw="text-text-danger text-xs py-4">{error}</div>
        </>
    );
};

function getOAuthFlowStartURL(appID: string): string {
    const baseScopes = ["email", "profile", "openid"];

    const { oauth2RedirectToken } = window as any;
    if (oauth2RedirectToken === undefined) {
        throw new Error("oauth2RedirectToken was not found. Should be in window, populated by play");
    }

    const source = `${window.location.origin}/oauth-redirect-play2/${appID}`;
    const oauth2State = btoa(JSON.stringify({ source, redirectToken: oauth2RedirectToken }));

    const locationSettings = getLocationSettings();
    const redirectHref = locationSettings.oAuthCallbackURL;
    const creds = locationSettings.googleCredentials;

    const redirectURL = new URL("https://accounts.google.com/o/oauth2/v2/auth");
    const { searchParams } = redirectURL;
    searchParams.append("scope", baseScopes.join(" "));
    searchParams.append("access_type", "offline");
    searchParams.append("include_granted_scopes", "false");
    searchParams.append("state", oauth2State);
    searchParams.append("redirect_uri", redirectHref);
    searchParams.append("response_type", "code");
    searchParams.append("client_id", creds.clientId);

    return redirectURL.href;
}
