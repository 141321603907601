import {
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    makeNumberProperty,
} from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { ProgressType } from "@glide/component-utils";
import type { WireAppProgressComponent } from "@glide/fluent-components/dist/js/base-components";
import type { WirePageProgressDescription } from "@glide/fluent-components/dist/js/fluent-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    EnumPropertyHandler,
    PropertySection,
    RequiredKind,
    SwitchPropertyHandler,
    makeNumberPropertyDescriptor,
    makeTextPropertyDescriptor,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import {
    inflateNumberProperty,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import { makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

const ComponentKindProgress: ComponentKind = "progress";

const valuePropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable, ColumnPropertyFlag.DefaultCaption],
    undefined,
    [],
    getPrimitiveColumnsSpec,
    "number",
    PropertySection.Data
);

const showTotalInCenterPropertyHandler = new SwitchPropertyHandler(
    { showTotalInCenter: true },
    "Show total in center",
    PropertySection.Design
);

const stylePropertyHandler = new EnumPropertyHandler(
    { style: ProgressType.BarProgress },
    "Style",
    "Style",
    [
        {
            value: ProgressType.BarProgress,
            label: "Bar",
            icon: "co-progress-bar",
        },
        {
            value: ProgressType.CircleProgress,
            label: "Circle",
            icon: "donutChartReverse",
        },
        { value: ProgressType.HalfCircleProgress, label: "Half Circle", icon: "halfCircleProgressBar" },
    ],

    PropertySection.Style,
    "large-images"
);

interface ProgressComponentDescription extends ComponentDescription {
    readonly propertyName: PropertyDescription;
    readonly minimumProperty: PropertyDescription;
    readonly maximumProperty: PropertyDescription;
    readonly subtitle: PropertyDescription | undefined;
    readonly caption: PropertyDescription | undefined;
    readonly showTotalInCenter: PropertyDescription;
    readonly showLegendByDefault: PropertyDescription;
    readonly style: PropertyDescription;
}
export class ProgressComponentHandler extends ComponentHandlerBase<ProgressComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindProgress);
    }

    public getDescriptor(
        desc: ProgressComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const props: PropertyDescriptor[] = [
            valuePropertyHandler,
            makeNumberPropertyDescriptor(
                "minimumProperty",
                "Minimum",
                "0",
                RequiredKind.Required,
                0,
                mutatingScreenKind,
                {
                    preferredNames: ["minimum", "min", "low"],
                }
            ),
            makeNumberPropertyDescriptor(
                "maximumProperty",
                "Maximum",
                "100",
                RequiredKind.Required,
                100,
                mutatingScreenKind,
                {
                    preferredNames: ["maximum", "max", "high"],
                }
            ),
            makeCaptionStringPropertyDescriptor("Progress", false, mutatingScreenKind),
            makeTextPropertyDescriptor(
                "subtitle",
                "Caption",
                "Enter the component's caption",
                false,
                mutatingScreenKind,
                {
                    propertySection: PropertySection.Design,
                    emptyByDefault: true,
                }
            ),
            stylePropertyHandler,

            ...this.getBasePropertyDescriptors(),
        ];

        if (desc !== undefined) {
            const style = stylePropertyHandler.getEnum(desc);

            if (style !== ProgressType.BarProgress) {
                props.push(showTotalInCenterPropertyHandler);
            }
        }
        return {
            name: "Progress Bar",
            description: "A bar showing progress",
            img: "co-progress-bar",
            group: "Charts",
            helpUrl: getDocURL("progress"),
            properties: props,
        };
    }

    public newComponent(
        tables: InputOutputTables,
        usedColumns: ReadonlySet<TableColumn>,
        editedColumns: ReadonlySet<TableColumn>,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ProgressComponentDescription | undefined {
        const desc = super.newComponent(tables, usedColumns, editedColumns, iccc, mutatingScreenKind);
        if (desc === undefined) return undefined;

        return {
            ...desc,
            minimumProperty: makeNumberProperty(0),
            maximumProperty: makeNumberProperty(100),
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: ProgressComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const [valueGetter, valueType] = inflateNumberProperty(ib, desc.propertyName);
        if (valueType === undefined) return undefined;

        const [valueWithFormatGetter] = inflateStringProperty(ib, desc.propertyName, true);

        const [minimumGetter] = inflateNumberProperty(ib, desc.minimumProperty);
        const [maximumGetter] = inflateNumberProperty(ib, desc.maximumProperty);

        const [titleGetter] = inflateStringProperty(ib, desc.caption, true);
        const [captionGetter] = inflateStringProperty(ib, desc.subtitle, true);

        const showTotal = showTotalInCenterPropertyHandler.getSwitch(desc);
        const style = stylePropertyHandler.getEnum(desc);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const value = valueGetter(hb);
            if (value === undefined || value === null) return undefined;

            const formatted = valueWithFormatGetter(hb) ?? "";

            const minimum = minimumGetter(hb) ?? 0;
            const maximum = maximumGetter(hb) ?? 100;

            const title = titleGetter(hb) ?? "";
            const caption = captionGetter(hb) ?? "";

            const component: WireAppProgressComponent = {
                kind: WireComponentKind.AppProgress,
                ...spreadComponentID(desc.componentID, forBuilder),
                value,
                formatted,
                minimum,
                maximum,
                title,
                caption,
                style,
                showTotal,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(desc: ProgressComponentDescription, _ccc: AppDescriptionContext): ComponentDescription {
        const progress: WirePageProgressDescription = {
            ...makeEmptyComponentDescription(WireComponentKind.PageProgress),
            min: desc.minimumProperty,
            max: desc.maximumProperty,
            title: desc.caption,
            description: desc.subtitle,
            value: desc.propertyName,
        };
        return progress;
    }
}
