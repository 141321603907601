import { definedMap } from "@glideapps/ts-necessities";
import type { SelectModeInfo } from "../utils/use-select-mode-handlers";
import { useSelectModeHandlers } from "../utils/use-select-mode-handlers";
import { makeValidHTMLId } from "@glide/common";
import "twin.macro";
import classNames from "classnames";

interface SelectModeContainerProps {
    readonly selectModeInfo: SelectModeInfo;
    readonly className?: string;
}

export const SelectModeContainer: React.FC<React.PropsWithChildren<SelectModeContainerProps>> = p => {
    const { selectModeInfo, className, children } = p;

    const { onClick, onMouseLeave, onMouseOver, blockInteractions } = useSelectModeHandlers(selectModeInfo);

    const validId = definedMap(selectModeInfo.id, i => makeValidHTMLId(i));

    return (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div
            tw="w-full flex"
            id={validId}
            className={classNames("component-root", className)}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseOver}>
            <div
                tw="w-full flex flex-col"
                style={{
                    pointerEvents: blockInteractions ? "none" : "auto",
                }}>
                {children}
            </div>
        </div>
    );
};
