import { GlideIcon, isStrokeIcon } from "@glide/common";
import type { StrokeIcons } from "@glide/plugins";
import React from "react";
import "twin.macro";

interface BarValue {
    value: number;
    color: string;
}

interface UsageBarIcon {
    icon: StrokeIcons;
    color: string;
}

type NumberFormatter = (value: number) => string;

export type UsageBarProps = {
    label: string;
    icon?: UsageBarIcon;
    values: BarValue[];
    maxValue: number;
    readonly formatter?: NumberFormatter;
};

const valueAsPercentage = (num: number, maxValue: number) => {
    return Math.ceil((num / maxValue) * 100);
};

function formatValue(maxValue: number, formatter: NumberFormatter = value => Intl.NumberFormat().format(value)) {
    return maxValue === Number.MAX_SAFE_INTEGER ? "Unlimited" : formatter(maxValue);
}

export const UsageBar: React.VFC<UsageBarProps> = ({ label, icon, values, maxValue, formatter }) => {
    const usedValue = React.useMemo(() => values.reduce((acc, curr) => acc + curr.value, 0), [values]);
    const formattedUsedValue = React.useMemo(() => formatValue(usedValue, formatter), [usedValue, formatter]);
    const formattedMaxValue = React.useMemo(() => formatValue(maxValue, formatter), [maxValue, formatter]);
    return (
        <div tw="w-full">
            <div tw="w-full flex justify-between">
                <div tw="flex text-base">
                    {icon ? (
                        <GlideIcon
                            kind="stroke"
                            icon={isStrokeIcon(icon.icon) ? icon.icon : "st-action-bolt"}
                            tw="mr-2.5"
                            strokeColor={icon.color}
                        />
                    ) : null}
                    {label}
                </div>
                <div tw="text-sm flex gap-x-1">
                    <span tw="text-text-dark">{formattedUsedValue}</span>
                    <span>/</span>
                    <span tw="text-text-pale">{formattedMaxValue}</span>
                </div>
            </div>
            <HorizontalStackedBars tw="mt-3" values={values} limit={maxValue} />
        </div>
    );
};

interface HorizontalStackedBarsProps {
    values: BarValue[];
    limit: number;
    className?: string;
}

// If we have unlimited usage, we want to show the full bar if we have at least one,
// So we use 1 as limit if that's the case.
function normalizeMaxSafeIntegerLimit(limit: number) {
    return limit === Number.MAX_SAFE_INTEGER ? 1 : limit;
}

export const HorizontalStackedBars: React.VFC<HorizontalStackedBarsProps> = p => {
    const { values, limit, className } = p;
    const totalValue = React.useMemo(() => values.reduce((acc, curr) => acc + curr.value, 0), [values]);
    const limitToNormalize = totalValue > limit ? totalValue : normalizeMaxSafeIntegerLimit(limit);
    const normalizedValues: BarValue[] = React.useMemo(
        () =>
            values.map(value => ({
                value: valueAsPercentage(value.value, limitToNormalize),
                color: value.color,
            })),
        [limitToNormalize, values]
    );
    const computeLeftFromIndex = React.useCallback(
        (i: number) => {
            let left = 0;
            while (i > 0) {
                left += normalizedValues[i - 1].value;
                i--;
            }
            return left;
        },
        [normalizedValues]
    );
    return (
        <div className={className} tw="relative w-full h-1 bg-border-dark rounded-[4px] overflow-hidden">
            {normalizedValues.map((value, i) => (
                <div
                    tw="absolute h-full not-first:(border-l border-bg-front)"
                    key={i}
                    style={{
                        backgroundColor: value.color,
                        width: `${value.value}%`,
                        left: `${computeLeftFromIndex(i)}%`,
                    }}
                />
            ))}
        </div>
    );
};
