import type { MutatingScreenKind, PropertyDescription, ArrayScreenDescription } from "@glide/app-description";
import {
    ArrayScreenFormat,
    getActionProperty,
    getColumnProperty,
    getSourceColumnProperty,
    getSwitchProperty,
    makeActionProperty,
    makeArrayProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import {
    ComponentKindInlineList,
    makeEmptyComponentDescription,
    type InputOutputTables,
} from "@glide/common-core/dist/js/description";
import { getTableName, type TableGlideType } from "@glide/type-schema";
import { GroupingSupport } from "@glide/component-utils";
import type { WireAppCheckListComponent, WireAppCheckListItem } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type InlineListComponentDescription,
    type PropertyDescriptor,
    type PropertyTableGetter,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
    getPrimitiveColumnsSpec,
    getTitlePropertyFromInlineListCaption,
} from "@glide/function-utils";
import {
    type WireTableComponentHydratorConstructor,
    type WireAction,
    WireComponentKind,
    type WireInflationBackend,
    CardStyle,
} from "@glide/wire";
import { defined } from "@glideapps/ts-necessities";
import { getActionsForArrayContent } from "../components/component-utils";
import {
    type WireStringGetter,
    getAppArrayScreenEmptyMessage,
    inflateActionsWithCanAutoRun,
    inflateBooleanProperty,
    makeSimpleWireTableComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import {
    type SummaryArrayContentDescription,
    type SummaryArrayScreenDescription,
    SummaryArrayScreenHandler,
    getNumberToTruncateTo,
    hydrateListItem,
    inflateSummary,
    makeAppListGroups,
} from "./summary-array-screen";
import type { CardCollectionComponentDescription } from "@glide/fluent-components/dist/js/fluent-components";
import { definedMap } from "collection-utils";
import type { ComponentScreenContextForConversion } from "./array-content";

function makeCheckedFlagNamePropertyHandler(getIndirectTable: PropertyTableGetter | undefined): ColumnPropertyHandler {
    return new ColumnPropertyHandler(
        "checkedFlagName",
        "Check Value",
        [
            ColumnPropertyFlag.Required,
            ColumnPropertyFlag.Editable,
            ColumnPropertyFlag.DefaultCaption,
            ColumnPropertyFlag.EditedInApp,
        ],
        getIndirectTable,
        undefined,
        getPrimitiveColumnsSpec,
        "boolean",
        PropertySection.Content
    );
}

const allowWrappingPropertyHandler = new SwitchPropertyHandler(
    { allowWrapping: false },
    "Allow text wrapping",
    PropertySection.TextStyle
);

interface CheckListArrayContentDescription extends SummaryArrayContentDescription {
    // This is the legacy of a bug.  The `checkedFlagName` was previously
    // named `propertyName` which collides with the same property in
    // inline list.
    readonly propertyName?: PropertyDescription;
    readonly checkedFlagName: PropertyDescription;
    readonly allowWrapping?: PropertyDescription;
}

function getCheckedFlagName(desc: CheckListArrayContentDescription, insideInlineList: boolean): string | undefined {
    // FIXME: It's a hack to make this property handler without a `getIndirectTable`
    // here.  We're only using it to get the value in the description, for which
    // case the indirect table doesn't matter, but this is not a nice way to do it.
    let propertyName = makeCheckedFlagNamePropertyHandler(undefined).getColumnName(desc);
    // See comment on `propertyName` above.
    if (propertyName === undefined && !insideInlineList) {
        propertyName = getColumnProperty(desc.propertyName);
    }
    return propertyName;
}

interface CheckListArrayScreenDescription extends SummaryArrayScreenDescription, CheckListArrayContentDescription {}

export class CheckListArrayScreenHandler extends SummaryArrayScreenHandler<
    CheckListArrayContentDescription,
    CheckListArrayScreenDescription
> {
    protected readonly supportsNonURLImages = false;
    protected readonly supportsEmojiImages = true;
    protected readonly supportsTruncateList = true;

    constructor() {
        super(ArrayScreenFormat.CheckList, "Checklist", "checklist", false);
    }

    protected get groupingSupport(): GroupingSupport {
        return GroupingSupport.Regular;
    }

    protected getInterSummaryPropertyDescriptors(
        getPropertyTable: PropertyTableGetter | undefined,
        _insideInlineList: boolean
    ): readonly PropertyDescriptor[] {
        return [makeCheckedFlagNamePropertyHandler(getPropertyTable)];
    }

    public getContentPropertyDescriptors<T extends CheckListArrayContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined
    ): ReadonlyArray<PropertyDescriptor> {
        const descrs: PropertyDescriptor[] = [
            ...super.getContentPropertyDescriptors(
                getPropertyTable,
                insideInlineList,
                containingScreenTables,
                desc,
                ccc,
                mutatingScreenKind,
                isDefaultArrayScreen,
                withTransforms,
                forEasyTabConfiguration,
                isFirstComponent
            ),
        ];

        descrs.push(allowWrappingPropertyHandler);
        return descrs;
    }

    public inflateContent<T extends CheckListArrayContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        captionGetter: WireStringGetter | undefined,
        containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        const {
            forBuilder,
            adc: { appKind },
        } = ib;
        const tableName = getTableName(ib.tables.input);
        const columnName = getCheckedFlagName(desc, containingRowIB !== undefined);
        if (columnName === undefined) return undefined;

        const [checkedGetter, checkedGetterType] = inflateBooleanProperty(ib, makeColumnProperty(columnName));
        if (checkedGetterType === undefined) return undefined;

        const allowWrapping = allowWrappingPropertyHandler.getSwitch(desc);

        const summaryGetters = inflateSummary(ib, desc);
        const groupByGetters = this.makeGroupByGetters(ib, desc);
        const { actionHydrator, canAutoRunAction } = inflateActionsWithCanAutoRun(
            ib,
            getActionsForArrayContent(this, ib.tables, desc, ib.adc).actions
        );
        const numToTruncate = getNumberToTruncateTo(desc, containingRowIB !== undefined, 1, this.groupingSupport);

        return makeSimpleWireTableComponentHydratorConstructor(ib, (thb, chb, searchActive) => {
            let firstListItemActionToRun: WireAction | undefined;
            const groups = makeAppListGroups<WireAppCheckListItem>(
                thb,
                groupByGetters,
                numToTruncate,
                tableName,
                rhb => {
                    const token = rhb.registerOnValueChange("edit", columnName);
                    if (token === false) return undefined;
                    const item = {
                        ...hydrateListItem(rhb, summaryGetters, actionHydrator),
                        // It's important that the key is the row ID because
                        // we use it for the ##selectedMasterItemKey.
                        key: defined(rhb.rowContext?.inputRows[0].$rowID),
                        checked: { value: checkedGetter(rhb) ?? false, onChangeToken: token },
                        image: summaryGetters.imageGetter(rhb),
                    };
                    if (canAutoRunAction && firstListItemActionToRun === undefined) {
                        firstListItemActionToRun = item.action;
                    }
                    return item;
                }
            );
            if (groups === undefined) return undefined;

            const component: WireAppCheckListComponent = {
                ...spreadComponentID(componentID, forBuilder),
                kind: WireComponentKind.List,
                format: ArrayScreenFormat.CheckList,
                title: captionGetter?.(defined(chb)) ?? "",
                emptyMessage: getAppArrayScreenEmptyMessage(searchActive, appKind),

                allowWrapping,
                groups,
            };
            return {
                component,
                isValid: true,
                firstListItemActionToRun,
            };
        });
    }

    private convertContentToPage(desc: CheckListArrayContentDescription) {
        return {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            format: makeEnumProperty(ArrayScreenFormat.CardCollection),
            caption: undefined,
            cardStyle: makeEnumProperty(CardStyle.Checklist),
            transforms: desc.transforms,
            action: definedMap(getActionProperty(desc.actions), a => makeActionProperty(a)),
            title: definedMap(getSourceColumnProperty(desc.titleProperty), sc => makeSourceColumnProperty(sc)),
            subtitle: definedMap(getSourceColumnProperty(desc.subtitleProperty), sc => makeSourceColumnProperty(sc)),
            image: definedMap(getSourceColumnProperty(desc.imageURLProperty), sc => makeSourceColumnProperty(sc)),
            groupByColumn: desc.groupByColumn,
            checked: desc.checkedFlagName,
        };
    }

    public convertInlineToPage(
        desc: InlineListComponentDescription & CheckListArrayContentDescription
    ): CardCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: desc.propertyName,
            allowSearch: desc.allowSearch,
            componentTitle: getTitlePropertyFromInlineListCaption(desc),
        };
    }

    public convertArrayScreenToPage(
        desc: ArrayScreenDescription & CheckListArrayContentDescription,
        table: TableGlideType,
        adc: AppDescriptionContext,
        screenContext: ComponentScreenContextForConversion
    ): CardCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: makeTableProperty(getTableName(table)),
            allowSearch: makeSwitchProperty(getSwitchProperty(desc.search)),
            titleActions: definedMap(screenContext.titleAction, a => makeArrayProperty([a])),
            componentTitle: definedMap(screenContext.screenTitle, t => makeStringProperty(t)),
            multipleDynamicFilters: this.getDynamicMultipleFiltersForPageConversion(
                adc,
                table,
                desc.dynamicFilterColumn,
                desc.pivots
            ),
        };
    }
}
