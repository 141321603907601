import { AuthenticationMethod, type AppLoginAuthenticationMethod } from "@glide/common-core/dist/js/Database";
import { assertNever } from "@glideapps/ts-necessities";

export enum Play2AuthKind {
    disabled = "disabled",
    mandatory = "mandatory",
    optional = "optional",
    unsupportedMethod = "unsupported-method",
}

export function getSupportedAuthenticationMethod(appLoginAuthInfo: AppLoginAuthenticationMethod): Play2AuthKind {
    const { authenticationMethod, authenticationOptional } = appLoginAuthInfo;

    if (authenticationOptional) {
        return Play2AuthKind.optional;
    }

    switch (authenticationMethod) {
        case AuthenticationMethod.Disabled:
            return Play2AuthKind.disabled;

        case AuthenticationMethod.PublicEmailPin:
        case AuthenticationMethod.WhitelistEmailPin:
        case AuthenticationMethod.UserProfileEmailPin:
        case AuthenticationMethod.JustMe:
        case AuthenticationMethod.OrgMembers:
        case AuthenticationMethod.AllowedEmailDomains:
            return Play2AuthKind.mandatory;

        case AuthenticationMethod.None:
        case AuthenticationMethod.Password:
            return Play2AuthKind.unsupportedMethod;

        default:
            assertNever(authenticationMethod);
    }
}
