import { styled } from "@glide/common";
import { assertNever } from "@glideapps/ts-necessities";

type EnumPickerDisplayMode = "icon" | "text" | "both";

function getSizeForDisplayMode(displayMode: EnumPickerDisplayMode) {
    switch (displayMode) {
        case "icon":
            return "36px";

        case "text":
            return "auto";

        case "both":
            return "auto";

        default:
            assertNever(displayMode);
    }
}

export const EnumPickerStyle = styled.div<{ displayMode: EnumPickerDisplayMode }>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 13px;

    margin: 8px 0;

    .icon-wrapper {
        width: ${p => getSizeForDisplayMode(p.displayMode)};
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${p => p.theme.enumPicker.iconColor};

        svg {
            fill: ${p => p.theme.enumPicker.iconColor};
            transition: fill ${p => p.theme.transitionBase};

            --bg-color: ${p => p.theme.enumPicker.iconColor};
        }

        &:hover,
        &:focus {
            color: ${p => p.theme.enumPicker.iconColorHover};
            svg {
                fill: ${p => p.theme.enumPicker.iconColorHover};

                --bg-color: ${p => p.theme.enumPicker.iconColorHover};
            }
        }
    }

    .icon-wrapper.selected {
        color: ${p => p.theme.enumPicker.iconColorHover};
        svg {
            fill: ${p => p.theme.enumPicker.iconSelectedColor};

            --bg-color: ${p => p.theme.enumPicker.iconSelectedColor};
        }
    }
`;
