import { ErrorBoundary, GlideIcon, TailwindThemeProvider, makeValidHTMLId } from "@glide/common";
import type { ComponentIndexes } from "@glide/common-core/dist/js/component-indexes";
import { type IconImage, ArrayScreenFormat } from "@glide/app-description";
import { getFirstElementFromArrayOrSingleElement } from "@glide/common-core/dist/js/components/component-helpers";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { asMaybeDate, asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import {
    ContainerAlignment,
    ContainerLayout,
    ContainerPadding,
    ContainerWidth,
} from "@glide/common-core/dist/js/description";
import { useIsPlayerOrPreview } from "@glide/common-core/dist/js/hooks/use-is-player-or-preview";
import { GlideDateTime } from "@glide/data-types";
import type {
    WireActivitySpinnerComponent,
    WireAppCodeScannerScreenComponent,
    WireBreadcrumbsComponent,
    WireChartsComponent,
    WireChoiceComponent,
    WireCommentsComponent,
    WireDataGridListComponent,
    WireNewDataGridListComponent,
    WireDateTimeFieldComponent,
    WireEmailFieldComponent,
    WireFilePickerComponent,
    WireForEachContainer,
    WireImagePickerComponent,
    WireInlineScannerComponent,
    WireKanbanComponent,
    WireListCalendarCollection,
    WireMultipleFilterComponent,
    WireNumberFieldComponent,
    WirePageSignatureFieldComponent,
    WirePageSignaturePadComponent,
    WirePhoneFieldComponent,
    WireRadialChartComponent,
    WireSignInComponent,
    WireSuperTableListComponent,
    WireTextFieldComponent,
    WireToggleComponent,
    WirePageVoiceEntryComponent,
    WireDataPlotComponent,
} from "@glide/fluent-components/dist/js/base-components";
import type {
    WireActionRowComponent,
    WireAudioComponent,
    WireAudioRecorderComponent,
    WireBigNumbersComponent,
    WireButtonComponent,
    WireButtonsBlockComponent,
    WireButtonsComponent,
    WireContactFormComponent,
    WireCustomComponent,
    WireFieldsComponent,
    WireHintComponent,
    WireImageComponent,
    WireLinkComponent,
    WireListCardCollection,
    WireListMapCollection,
    WireLocationComponent,
    WireNoteComponent,
    WirePageProgressComponent,
    WireRatingComponent,
    WireRenderableContentComponent,
    WireRichTextComponent,
    WireSeparatorComponent,
    WireSimpleImageComponent,
    WireSimpleVideoComponent,
    WireTextComponent,
    WireTitleComponent,
    WireVideoComponent,
    WireWebViewComponent,
    WireAICustomComponent,
    WireAICustomChatComponent,
    WireStopwatch,
    WireSpinnerComponent,
} from "@glide/fluent-components/dist/js/fluent-components";
import type {
    WireContainerComponent,
    WireFormContainerComponent,
} from "@glide/fluent-components/dist/js/fluent-components-containers";
import type { WireListComponentGeneric } from "@glide/fluent-components/dist/js/fluent-components-spec";
import { isFirstComponentIndex } from "@glide/generator/dist/js/sub-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { ResponsiveProvider, ResponsiveReset, css, styled } from "@glide/common-components";
import { anyDefined, isDefined, logError, parseNumber } from "@glide/support";
import {
    type WireComponent,
    ButtonBarSize,
    HeroImageEffect,
    UIAlignment,
    UIBackgroundStyle,
    UIOrientation,
    UISize,
    UIStyleVariant,
    ValueChangeSource,
    WireComponentKind,
    isAccentBackgroundStyle,
} from "@glide/wire";
import { defined, definedMap } from "@glideapps/ts-necessities";
import classNames from "classnames";
import * as React from "react";
import tw from "twin.macro";

import { getRenderableComponentIndexAtPosition } from "../chrome/content/lib/get-renderable-component-for-possition";
import { shouldBreadcrumbsPortal } from "../chrome/content/lib/should-breadcrumbs-portal";
import { Fields } from "../components/fields/fields";
import { Img } from "../components/img/img";
import {
    NavBarPortalContext,
    PortalIdContext,
    extractActions,
    makeSingleActionSpreadProps,
    runActionAndHandleURL,
} from "../wire-lib";
import { CalendarCollectionRenderer } from "./calendar-collection/calendar-collection";
import { CardCollectionRenderer, Pager } from "./card-collection/card-collection";
import { CardContainer, getRingFocusCss } from "./card-collection/card-collection-card";
import { FormSection } from "./form-section/form-section";
import { getComponentsFromRenderableDescriptions } from "./renderable-content/get-components-from-renderable-descriptions";
import { WireActionRow } from "./wire-action-row/wire-action-row";
import { WireAudio } from "./wire-audio/wire-audio";
import { WireAudioRecorder } from "./wire-audio/wire-audio-recorder";
import { WireBigNumbers } from "./wire-big-numbers/wire-big-numbers";
import { Breadcrumbs } from "./wire-breadcrumbs/wire-breadcrumbs";
import { WireButtonBar } from "./wire-button-bar/wire-button-bar";
import { WireButtonBlock } from "./wire-button-block/wire-button-block";
import { WireButton } from "./wire-button/wire-button";
import { WireChoice } from "./wire-choice/wire-choice";
import { WireComments } from "./wire-comment/wire-comment";
import {
    ContainerBackgroundProvider,
    SectionStyleProvider,
    WireContainer,
    getContainerBackgroundColor,
    useContainerBackground,
} from "./wire-container/wire-container";
import { WireCustom } from "./wire-custom/wire-custom";
import { WireDateTimePicker } from "./wire-date-time-picker/wire-date-time-picker";
import { WireField } from "./wire-field/wire-field";
import { WireFilePicker, WireImagePicker } from "./wire-file-picker/wire-file-picker";
import { WireHero } from "./wire-hero/wire-hero";
import { WireHint } from "./wire-hint/wire-hint";
import { WireImage } from "./wire-image/wire-image";
import { WireLink } from "./wire-link/wire-link";
import {
    WireListContainer,
    useDynamicFilter,
    useMultipleDynamicFilters,
    useSearchBar,
} from "./wire-list-container/wire-list-container";
import { WireLocation } from "./wire-location/wire-location";
import { WireMultipleFilters } from "./wire-multiple-filters/wire-multiple-filters";
import { WireNoteLoader } from "./wire-note/wire-note-lazy-loader";
import { WirePageProgress } from "./wire-page-progress/wire-page-progress";
import { WireRating } from "./wire-rating/wire-rating";
import type { WireRenderer } from "./wire-renderer";
import { WireRichText } from "./wire-rich-text/wire-rich-text";
import { Separator } from "./wire-separator/wire-separator";
import { WireSignatureField } from "./wire-signature-field/wire-signature-field";
import { WireSignaturePad } from "./wire-signature-pad/wire-signature-pad";
import { WireSimpleImage } from "./wire-simple-image/wire-simple-image";
import { WireSimpleVideo } from "./wire-simple-video/wire-simple-video";
import { WireSwitch } from "./wire-switch/wire-switch";
import { WireText, textStyleToContainerSpacing } from "./wire-text/wire-text";
import { WireVideo } from "./wire-video/wire-video";
import { WireWebView } from "./wire-web-view/wire-web-view";
import { useWireAppTheme } from "../utils/use-wireapp-theme";
import {
    defaultWireAppAccentContextOverlay,
    defaultWireAppAccentContextOverlayUndo,
    defaultWireAppImageBrightenContextOverlay,
    defaultWireAppImageDarkenContextOverlay,
    mergeTheme,
} from "@glide/theme";
import { WireSuperTable } from "./wire-super-table/wire-super-table";
import type { SelectModeInfo } from "../utils/use-select-mode-handlers";
import { SelectModeContainer } from "../components/select-mode-container";
import { WireCharts } from "./charts/charts";
import { WireRadialChart } from "./charts/radial-chart";
import { MapCollectionRenderer } from "./map-collection/map-collection";
import { WireKanban } from "./wire-kanban/wire-kanban-impl";
import { WireNewDataGrid } from "./new-data-grid/data-grid";
import { WireAICustom } from "./wire-ai-custom-component/wire-ai-custom-component";
import { WireAICustomChat } from "./wire-ai-custom-chat-component/wire-ai-custom-chat-component";
import { IsWireComponentInCollectionContext } from "../utils/use-wire-component-in-collection";
import { WireStopwatchContainer } from "./wire-stopwatch/wire-stopwatch";
import { Spinner } from "./wire-spinner/wire-spinner";
import { WireVoiceEntry } from "./wire-voice-entry/wire-voice-entry";
import { WireDataPlot } from "./wire-data-plot/wire-data-plot";

const WireDataGrid = React.lazy(async () => await import("./data-grid/data-grid"));

function isFirstRenderableComponent(
    componentIndex: ComponentIndexes,
    firstRenderableComponentIndex: ComponentIndexes | null
): boolean {
    if (firstRenderableComponentIndex === null) {
        return false;
    }

    if (firstRenderableComponentIndex.length === 2) {
        return (
            componentIndex[0] === firstRenderableComponentIndex[0] &&
            componentIndex[1] === firstRenderableComponentIndex[1]
        );
    }

    return componentIndex[0] === firstRenderableComponentIndex[0];
}
interface UseComponentsSpecialCases {
    firstBreadcrumbsNeedsPortal: boolean;
    firstRenderableComponentIndex: ComponentIndexes | null;
    secondRenderableComponentIndex: ComponentIndexes | null;
}

function useComponentSpecialCases(components: readonly (WireComponent | null)[]): UseComponentsSpecialCases {
    const firstBreadcrumbsNeedsPortal = React.useMemo(() => shouldBreadcrumbsPortal(components), [components]);
    const firstRenderableComponentIndexNumber = React.useMemo(
        () => getRenderableComponentIndexAtPosition(components, 0),
        [components]
    );
    const secondRenderableComponentIndexNumber = React.useMemo(
        () => getRenderableComponentIndexAtPosition(components, 1),
        [components]
    );

    let firstRenderableComponentIndex: ComponentIndexes | null =
        firstRenderableComponentIndexNumber === null ? null : [firstRenderableComponentIndexNumber];
    const secondRenderableComponentIndex: ComponentIndexes | null =
        secondRenderableComponentIndexNumber === null ? null : [secondRenderableComponentIndexNumber];

    // We have the special case on where if the first renderable component is a container, we should look for the first renderable child
    if (
        firstRenderableComponentIndex !== null &&
        components[firstRenderableComponentIndex[0]]?.kind === WireComponentKind.Container
    ) {
        const firstRenderableContainer = components[firstRenderableComponentIndex[0]] as WireContainerComponent;
        const firstRenderableContainerFirstRenderableComponentIndex = getRenderableComponentIndexAtPosition(
            firstRenderableContainer.components,
            0
        );
        if (firstRenderableContainerFirstRenderableComponentIndex !== null) {
            firstRenderableComponentIndex = [
                ...firstRenderableComponentIndex,
                firstRenderableContainerFirstRenderableComponentIndex,
            ];
        }
    }

    return {
        firstBreadcrumbsNeedsPortal,
        firstRenderableComponentIndex,
        secondRenderableComponentIndex,
    };
}

export interface ContainerBuilderEmptyStateProps {
    componentIndex: ComponentIndexes;
}

// Some components are _very_ hard to move over to the wire-renderer package.
// We'll take them as dependencies to simplify
export interface PagesRendererComponentDependencies {
    readonly WireAuthController: WireRenderer<WireSignInComponent, { customCssClassName?: string }>;
    readonly PageCodeScannerScreenRenderer: WireRenderer<WireAppCodeScannerScreenComponent>;
    readonly ContainerBuilderEmptyState: React.VFC<ContainerBuilderEmptyStateProps>;
    readonly InlineCodeScannerRenderer: WireRenderer<WireInlineScannerComponent>;
}

export interface PagesComponentRenderer {
    readonly backend: WireBackendInterface;
    readonly components: readonly (WireComponent | null)[];
    readonly hasPrimary?: boolean;
    readonly componentDependencies: PagesRendererComponentDependencies;
    readonly iconImage?: IconImage;
}

export const PagesComponentRenderer: React.VFC<PagesComponentRenderer> = p => {
    const { components, backend, hasPrimary, componentDependencies, iconImage } = p;
    const portalID = React.useContext(PortalIdContext);
    const { firstBreadcrumbsNeedsPortal, firstRenderableComponentIndex, secondRenderableComponentIndex } =
        useComponentSpecialCases(components);
    const isOnlyComponent = components.filter(c => c !== null).length === 1;
    const content = React.useMemo(() => {
        return components.map((i, index) => {
            if (i === null) return undefined;

            const breadcrumbsShouldPortal = index === firstRenderableComponentIndex?.[0] && firstBreadcrumbsNeedsPortal;
            // We have a special treatment for first elements: on Desktop, we want to combine Breadcrumbs and Title into "one" component.
            // So, we portal Breadcrumbs inside Title component, and therefore we need to treat Title as first component.
            const treatTitleAsFirstComponent =
                index === secondRenderableComponentIndex?.[0] && firstBreadcrumbsNeedsPortal;
            let r = (
                <WireComponentRenderer
                    key={index}
                    component={i}
                    backend={backend}
                    componentIndex={[index]}
                    componentDependencies={componentDependencies}
                    breadcrumbsShouldPortal={breadcrumbsShouldPortal}
                    treatTitleAsFirstComponent={treatTitleAsFirstComponent}
                    firstRenderableComponentIndex={firstRenderableComponentIndex}
                    iconImage={iconImage}
                    isOnlyComponent={isOnlyComponent}
                />
            );

            // This is only used on wire-list-container, so we want this only for lists collections.
            if (
                index === firstRenderableComponentIndex?.[0] &&
                i.kind === WireComponentKind.List &&
                hasPrimary === true
            ) {
                r = (
                    <NavBarPortalContext.Provider key={index} value={portalID}>
                        {r}
                    </NavBarPortalContext.Provider>
                );
            }
            return r;
        });
    }, [
        components,
        firstRenderableComponentIndex,
        firstBreadcrumbsNeedsPortal,
        secondRenderableComponentIndex,
        backend,
        componentDependencies,
        hasPrimary,
        portalID,
        iconImage,
        isOnlyComponent,
    ]);

    return <>{content}</>;
};

interface WireComponentRendererProps {
    readonly component: WireComponent;
    readonly backend: WireBackendInterface;
    readonly isInMultipleColumnLayout?: boolean;
    readonly componentIndex: ComponentIndexes;
    readonly componentDependencies: PagesRendererComponentDependencies;
    readonly breadcrumbsShouldPortal: boolean;
    readonly treatTitleAsFirstComponent: boolean;
    readonly firstRenderableComponentIndex: ComponentIndexes | null;
    readonly iconImage?: IconImage;
    readonly isOnlyComponent: boolean;
}

const WireComponentRenderer: React.VFC<WireComponentRendererProps> = p => {
    const {
        component: untypedComponent,
        backend,
        isInMultipleColumnLayout,
        componentIndex,
        componentDependencies,
        breadcrumbsShouldPortal,
        treatTitleAsFirstComponent,
        firstRenderableComponentIndex,
        iconImage,
        isOnlyComponent,
    } = p;

    const { WireAuthController, PageCodeScannerScreenRenderer, InlineCodeScannerRenderer } = componentDependencies;

    const { appKind } = backend;

    const selectModeInfo: SelectModeInfo = React.useMemo(
        () => ({
            id: untypedComponent.id,
            indexes: componentIndex,
        }),
        [componentIndex, untypedComponent.id]
    );

    if (untypedComponent.kind === WireComponentKind.Button) {
        const component = untypedComponent as WireButtonComponent;
        if (component.action === undefined) return null;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireButtonBar
                        overrideAppearance={component.appearance}
                        alignment={UIAlignment.Center}
                        size={ButtonBarSize.Wide}
                        buttons={[component]}
                        backend={backend}
                    />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Link) {
        const component = untypedComponent as WireLinkComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireLink {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.TextField) {
        const component = untypedComponent as WireTextFieldComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireField
                        placeholder={component.placeholder}
                        dataType="text"
                        isEnabled={component.value.onChangeToken !== undefined}
                        error={component.value.error}
                        value={component.value.value}
                        size={component.size}
                        maximumChars={component.maxChars ?? undefined}
                        minimumChars={component.minChars ?? undefined}
                        onChange={newVal =>
                            backend.valueChanged(defined(component.value.onChangeToken), newVal, ValueChangeSource.User)
                        }
                        isRequired={component.isRequired}
                        title={component.title}
                        autoFocus={component.autofocus}
                        voiceTranscription={component.voiceTranscription}
                    />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.NumberField) {
        const component = untypedComponent as WireNumberFieldComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireField
                        placeholder={component.placeholder}
                        dataType="number"
                        isEnabled={component.value.onChangeToken !== undefined}
                        error={component.value.error}
                        value={asMaybeString(component.value.value) ?? ""}
                        onChange={newVal =>
                            backend.valueChanged(
                                defined(component.value.onChangeToken),
                                parseNumber(newVal) ?? newVal,
                                ValueChangeSource.User
                            )
                        }
                        isRequired={component.isRequired}
                        maximumChars={undefined}
                        title={component.title}
                        autoFocus={component.autofocus}
                    />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.PhoneField) {
        const component = untypedComponent as WirePhoneFieldComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireField
                        placeholder={component.placeholder}
                        dataType="phone"
                        isEnabled={component.value.onChangeToken !== undefined}
                        error={component.value.error}
                        value={asMaybeString(component.value.value) ?? ""}
                        onChange={newVal =>
                            backend.valueChanged(defined(component.value.onChangeToken), newVal, ValueChangeSource.User)
                        }
                        isRequired={component.isRequired}
                        maximumChars={undefined}
                        title={component.title}
                    />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.EmailField) {
        const component = untypedComponent as WireEmailFieldComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireField
                        placeholder={component.placeholder}
                        dataType="email"
                        isEnabled={component.value.onChangeToken !== undefined}
                        error={component.value.error}
                        value={asMaybeString(component.value.value) ?? ""}
                        onChange={newVal =>
                            backend.valueChanged(defined(component.value.onChangeToken), newVal, ValueChangeSource.User)
                        }
                        isRequired={component.isRequired}
                        maximumChars={undefined}
                        title={component.title}
                        autoFocus={component.autofocus}
                    />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.DateTimeField) {
        const component = untypedComponent as WireDateTimeFieldComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireDateTimePicker
                        variant={component.withTime ? "date-time" : "date"}
                        title={component.title}
                        placeholder={component.placeholder}
                        value={asMaybeDate(component.value.value)?.asTimeZoneAwareDate()}
                        pastAndOrFuture={component.pastOrFuture}
                        isEnabled={component.value.onChangeToken !== undefined}
                        onChange={newVal => {
                            let newGlideDateTime: GlideDateTime | undefined;
                            if (newVal instanceof Date) {
                                newGlideDateTime = GlideDateTime.fromTimeZoneAwareDate(newVal);
                                if (!component.withTime) {
                                    newGlideDateTime = newGlideDateTime.toLocalTimeZoneAgnostic();
                                }
                            }
                            backend.valueChanged(
                                defined(component.value.onChangeToken),
                                newGlideDateTime ?? "",
                                ValueChangeSource.User
                            );
                        }}
                        isRequired={component.isRequired}
                        appKind={appKind}
                    />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.ImagePicker) {
        const component = untypedComponent as WireImagePickerComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireImagePicker {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.FilePicker) {
        const component = untypedComponent as WireFilePickerComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireFilePicker {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Toggle) {
        const component = untypedComponent as WireToggleComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireSwitch {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Hero) {
        const component = untypedComponent as WireTitleComponent;
        const isFirstComponent =
            isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex) || treatTitleAsFirstComponent;
        return (
            <SelectModeContainer selectModeInfo={selectModeInfo}>
                <WireHero
                    {...component}
                    backend={backend}
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    isFirstComponent={isFirstComponent}
                />
            </SelectModeContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.ContactForm) {
        const component = untypedComponent as WireContactFormComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <FormSection
                        title={component.title ?? undefined}
                        description={component.description ?? undefined}
                        email={component.email?.value}
                        emailError={component.email?.error}
                        message={component.message?.value}
                        messageError={component.message?.error}
                        name={component.name?.value}
                        nameError={component.name?.error}
                        phone={component.phone?.value}
                        phoneError={component.phone?.error}
                        onEmailChange={definedMap(
                            component.email?.onChangeToken,
                            token => x => backend.valueChanged(token, x, ValueChangeSource.User)
                        )}
                        onMessageChange={definedMap(
                            component.message?.onChangeToken,
                            token => x => backend.valueChanged(token, x, ValueChangeSource.User)
                        )}
                        onNameChange={definedMap(
                            component.name?.onChangeToken,
                            token => x => backend.valueChanged(token, x, ValueChangeSource.User)
                        )}
                        onPhoneChange={definedMap(
                            component.phone?.onChangeToken,
                            token => x => backend.valueChanged(token, x, ValueChangeSource.User)
                        )}
                        onSubmit={definedMap(
                            component.submitAction?.token,
                            _ => () => runActionAndHandleURL(component.submitAction, backend)
                        )}
                        appKind={backend.appKind}
                    />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.DynamicButtonBar) {
        const component = untypedComponent as WireButtonsComponent;

        if (!component.buttons.some(a => isDefined(a.action))) {
            return null;
        }

        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireButtonBar {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.ButtonsBlock) {
        const component = untypedComponent as WireButtonsBlockComponent;

        if (!component.buttons.some(a => isDefined(a.action))) {
            return null;
        }

        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireButtonBlock {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Breadcrumbs) {
        const component = untypedComponent as WireBreadcrumbsComponent;
        return (
            <WireContainer
                css={[breadcrumbsShouldPortal && tw`mt-0`]}
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}
                dontAnimate={true}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <Breadcrumbs {...component} backend={backend} breadcrumbShouldPortal={breadcrumbsShouldPortal} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Container) {
        const component = untypedComponent as WireContainerComponent;
        const isFirstComponent =
            isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex) || treatTitleAsFirstComponent;
        return (
            <WireComponentStack
                {...component}
                id={component.id}
                backend={backend}
                componentIndex={componentIndex}
                componentDependencies={componentDependencies}
                breadcrumbsShouldPortal={breadcrumbsShouldPortal}
                treatTitleAsFirstComponent={treatTitleAsFirstComponent}
                firstRenderableComponentIndex={firstRenderableComponentIndex}
                isFirstComponent={isFirstComponent}
                isOnlyComponent={isOnlyComponent} // This is needed so that if we have a single Container with a Notes component inside, we render the Notes in fullWidthBleed.
            />
        );
    } else if (untypedComponent.kind === WireComponentKind.FormContainer) {
        const component = untypedComponent as WireFormContainerComponent;
        const isFirstComponent =
            isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex) || treatTitleAsFirstComponent;
        return (
            <WireComponentStack
                {...component}
                id={component.id}
                componentIndex={componentIndex}
                layout={ContainerLayout.Full}
                alignment={ContainerAlignment.center}
                backgroundEffect={HeroImageEffect.None}
                withBlur={false}
                width={ContainerWidth.Normal}
                padding={ContainerPadding.Medium}
                backend={backend}
                componentDependencies={componentDependencies}
                breadcrumbsShouldPortal={breadcrumbsShouldPortal}
                treatTitleAsFirstComponent={treatTitleAsFirstComponent}
                firstRenderableComponentIndex={firstRenderableComponentIndex}
                isFirstComponent={isFirstComponent}
                isOnlyComponent={isOnlyComponent}
            />
        );
    } else if (untypedComponent.kind === WireComponentKind.Fields) {
        const component = untypedComponent as WireFieldsComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <Fields {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Location) {
        const component = untypedComponent as WireLocationComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireLocation {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Hint) {
        const component = untypedComponent as WireHintComponent;
        if (!anyDefined(component.action, component.actionTitle, component.description)) return null;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireHint {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Separator) {
        const component = untypedComponent as WireSeparatorComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <Separator {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Spinner) {
        const component = untypedComponent as WireSpinnerComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <Spinner {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Text) {
        const component = untypedComponent as WireTextComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                spacing={textStyleToContainerSpacing(component.style)}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireText {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.RichText) {
        const component = untypedComponent as WireRichTextComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireRichText {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Note) {
        const component = untypedComponent as WireNoteComponent;
        return (
            <ErrorBoundary>
                <React.Suspense fallback={<div />}>
                    <WireNoteLoader // This is lazy loading WireNoteContainer.
                        isInMultipleColumnLayout={isInMultipleColumnLayout}
                        component={component}
                        backend={backend}
                        isOnlyComponent={isOnlyComponent}
                        selectModeInfo={selectModeInfo}
                        customCssClassName={component.customCssClassName}
                    />
                </React.Suspense>
            </ErrorBoundary>
        );
    } else if (untypedComponent.kind === WireComponentKind.Image) {
        const component = untypedComponent as WireImageComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireImage {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.SimpleImage) {
        const component = untypedComponent as WireSimpleImageComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                itemsAlignment={component.align}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireSimpleImage {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.SimpleVideo) {
        const component = untypedComponent as WireSimpleVideoComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireSimpleVideo {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Stopwatch) {
        const component = untypedComponent as WireStopwatch;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireStopwatchContainer {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.Video) {
        const component = untypedComponent as WireVideoComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireVideo {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.WebView) {
        const component = untypedComponent as WireWebViewComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                expand={component.size === UISize.Full}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireWebView {...component} backend={backend} isInMultipleColumnLayout={isInMultipleColumnLayout} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.AICustomComponent) {
        const component = untypedComponent as WireAICustomComponent;
        return (
            <WireContainer isInMultipleColumnLayout={isInMultipleColumnLayout}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireAICustom
                        {...component}
                        backend={backend}
                        isInMultipleColumnLayout={isInMultipleColumnLayout}
                    />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.AICustomChatComponent) {
        const component = untypedComponent as WireAICustomChatComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                fullWidthBleed
                tw="py-1"
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireAICustomChat
                        {...component}
                        backend={backend}
                        isInMultipleColumnLayout={isInMultipleColumnLayout}
                    />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.SignIn) {
        const component = untypedComponent as WireSignInComponent;
        return <WireAuthController {...component} backend={backend} iconImage={iconImage} />;
    } else if (untypedComponent.kind === WireComponentKind.Custom) {
        const component = untypedComponent as WireCustomComponent;
        return <WireCustom {...component} backend={backend} />;
    } else if (untypedComponent.kind === WireComponentKind.BigNumbers) {
        const component = untypedComponent as WireBigNumbersComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireBigNumbers {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.List) {
        const listComponent = untypedComponent as WireListComponentGeneric<ArrayScreenFormat>;
        if (listComponent.format === ArrayScreenFormat.CardCollection) {
            const component = untypedComponent as WireListCardCollection;
            const isFirstComponent = isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex);
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <CardCollectionRenderer {...component} backend={backend} isFirstComponent={isFirstComponent} />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.CalendarCollection) {
            const component = untypedComponent as WireListCalendarCollection;
            const isFirstComponent = isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex);
            return (
                <WireContainer
                    className="calendar-collection-container"
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <CalendarCollectionRenderer
                            {...component}
                            backend={backend}
                            isFirstComponent={isFirstComponent}
                        />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.PagesSimpleMap) {
            const component = untypedComponent as WireListMapCollection;
            const isFirstComponent = isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex);
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <MapCollectionRenderer {...component} backend={backend} isFirstComponent={isFirstComponent} />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.Choice) {
            const component = untypedComponent as WireChoiceComponent;
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <WireChoice {...component} backend={backend} />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.Charts) {
            const component = untypedComponent as WireChartsComponent;
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <WireCharts {...component} backend={backend} />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.RadialChart) {
            const component = untypedComponent as WireRadialChartComponent;
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <WireRadialChart {...component} backend={backend} />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.NewDataGrid) {
            const component = untypedComponent as WireNewDataGridListComponent;
            const isFirstComponent = isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex);
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <WireNewDataGrid {...component} backend={backend} isFirstComponent={isFirstComponent} />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.DataGrid) {
            const component = untypedComponent as WireDataGridListComponent;
            const isFirstComponent = isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex);
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <React.Suspense fallback={null}>
                            <WireDataGrid
                                {...component}
                                backend={backend}
                                componentIndex={componentIndex}
                                onPageChange={(v: unknown) =>
                                    component.paging?.pageIndex.onChangeToken &&
                                    backend.valueChanged(
                                        component.paging.pageIndex.onChangeToken,
                                        v,
                                        ValueChangeSource.User
                                    )
                                }
                                isFirstComponent={isFirstComponent}
                            />
                        </React.Suspense>
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.Kanban) {
            const component = untypedComponent as WireKanbanComponent;
            const isFirstComponent = isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex);
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <WireKanban {...component} backend={backend} isFirstComponent={isFirstComponent} />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.ForEachContainer) {
            const component = untypedComponent as WireForEachContainer;
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <WireForEachCollection
                            {...component}
                            componentIndex={componentIndex}
                            backend={backend}
                            componentDependencies={componentDependencies}
                        />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.Comments) {
            const component = untypedComponent as WireCommentsComponent;
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <WireComments {...component} backend={backend} />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.SuperTable) {
            const component = untypedComponent as WireSuperTableListComponent;
            const isFirstComponent = isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex);
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <WireSuperTable {...component} backend={backend} isFirstComponent={isFirstComponent} />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else if (listComponent.format === ArrayScreenFormat.DataPlot) {
            const component = untypedComponent as WireDataPlotComponent;
            const isFirstComponent = isFirstRenderableComponent(componentIndex, firstRenderableComponentIndex);
            return (
                <WireContainer
                    isInMultipleColumnLayout={isInMultipleColumnLayout}
                    customCssClassName={component.customCssClassName}>
                    <SelectModeContainer selectModeInfo={selectModeInfo}>
                        <WireDataPlot {...component} backend={backend} isFirstComponent={isFirstComponent} />
                    </SelectModeContainer>
                </WireContainer>
            );
        } else {
            logError(`Unknown list format ${listComponent.format}`);
            return null;
        }
    } else if (untypedComponent.kind === WireComponentKind.InternalSeparator) {
        return null;
    } else if (untypedComponent.kind === WireComponentKind.ActivitySpinner) {
        const component = untypedComponent as WireActivitySpinnerComponent;
        if (component.isInline) {
            return (
                <div tw="flex justify-center items-center pt-3 w-full text-icon-base">
                    <GlideIcon kind="stroke" icon="st-half-spinner" iconSize={24} spin={true} />
                </div>
            );
        }
        // Returning null because returning the activity spinner requires design review
        // https://github.com/quicktype/glide/pull/15412#discussion_r953084574
        return null;
    } else if (untypedComponent.kind === WireComponentKind.AppCodeScannerScreen) {
        const component = untypedComponent as WireAppCodeScannerScreenComponent;
        return <PageCodeScannerScreenRenderer {...component} backend={backend} />;
    } else if (untypedComponent.kind === WireComponentKind.SignatureField) {
        const component = untypedComponent as WirePageSignatureFieldComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireSignatureField {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.SignaturePad) {
        const component = untypedComponent as WirePageSignaturePadComponent;
        return <WireSignaturePad {...component} backend={backend} />;
    } else if (untypedComponent.kind === WireComponentKind.VoiceEntry) {
        const component = untypedComponent as WirePageVoiceEntryComponent;
        return (
            <WireContainer
                css={css`
                    flex: 1;

                    .single-column {
                        ${tw`[--container-x-pad: 0px] px-[var(--container-x-pad)]
                              gp-sm:([--container-x-pad: 0px])
                              gp-md:([--container-x-pad: 0px])
                              gp-lg:([--container-x-pad: 0px])
                              gp-xl:([--container-x-pad: 0px])`}
                    }
                `}
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <WireVoiceEntry {...component} backend={backend} />
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.InlineScanner) {
        const component = untypedComponent as WireInlineScannerComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={true}
                className="code-scanner-container"
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo} tw="h-full">
                    <InlineCodeScannerRenderer {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.ActionRow) {
        const component = untypedComponent as WireActionRowComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireActionRow {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.PageRating) {
        const component = untypedComponent as WireRatingComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireRating {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.PageProgress) {
        const component = untypedComponent as WirePageProgressComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WirePageProgress {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.PageAudio) {
        const component = untypedComponent as WireAudioComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireAudio {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.PageAudioRecorder) {
        const component = untypedComponent as WireAudioRecorderComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <WireAudioRecorder {...component} backend={backend} />
                </SelectModeContainer>
            </WireContainer>
        );
    } else if (untypedComponent.kind === WireComponentKind.MultipleFilters) {
        const component = untypedComponent as WireMultipleFilterComponent;
        return <WireMultipleFilters {...component} backend={backend} />;
    } else if (untypedComponent.kind === WireComponentKind.RenderableContent) {
        const component = untypedComponent as WireRenderableContentComponent;
        return (
            <WireContainer
                isInMultipleColumnLayout={isInMultipleColumnLayout}
                customCssClassName={component.customCssClassName}>
                <SelectModeContainer selectModeInfo={selectModeInfo}>
                    <RenderableContentRenderer
                        {...component}
                        backend={backend}
                        componentDependencies={componentDependencies}
                    />
                </SelectModeContainer>
            </WireContainer>
        );
    } else {
        logError(`Unknown component ${untypedComponent.kind}`);
        return null;
    }
};

interface RenderableContentExtraProps {
    readonly componentDependencies: PagesRendererComponentDependencies;
}

const RenderableContentRenderer: WireRenderer<WireRenderableContentComponent, RenderableContentExtraProps> = p => {
    const { backend, componentDependencies, descriptions } = p;

    const components = getComponentsFromRenderableDescriptions(descriptions);

    return (
        <PagesComponentRenderer
            backend={backend}
            components={components}
            componentDependencies={componentDependencies}
        />
    );
};

interface ForEachItemsWrapperProps {
    isGrid: boolean;
    gridSize: UISize | undefined;
}

const ForEachItemsWrapper: React.FC<React.PropsWithChildren<ForEachItemsWrapperProps>> = p => {
    const { isGrid, children, gridSize } = p;

    if (isGrid) {
        return (
            <CardContainer size={gridSize} orientation={UIOrientation.Vertical} onSeeAll={undefined}>
                {children}
            </CardContainer>
        );
    } else {
        return <div>{children}</div>;
    }
};

const WireForEachCollection: WireRenderer<
    WireForEachContainer & {
        componentIndex: ComponentIndexes;
        componentDependencies: PagesRendererComponentDependencies;
    }
> = p => {
    const { backend, componentIndex, componentDependencies, searchBar } = p;
    const pageIndexToken = p.paging?.pageIndex.onChangeToken;
    const { numPages, pageIndex } = p.paging ?? {};
    const isFirstComponent = isFirstComponentIndex(componentIndex);
    const isGrid = p.layout === ContainerLayout.Grid;

    const filterArgs = useDynamicFilter(p.dynamicFilter, backend);
    const multipleFilterProps = useMultipleDynamicFilters(p.multipleDynamicFilters, backend);
    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, pageIndexToken);
    return (
        <>
            <WireListContainer
                title={p.title ?? ""}
                titleStyle={p.titleStyle}
                titleActions={extractActions(p.titleActions, backend)}
                {...filterArgs}
                multipleFilterProps={multipleFilterProps}
                searchValue={searchValue}
                onSearchChange={onSearchChange}
                styleVariant={UIStyleVariant.Default}
                appKind={backend.appKind}
                isFirstComponent={isFirstComponent}>
                <ForEachItemsWrapper isGrid={isGrid} gridSize={p.gridSize}>
                    {p.rowIDs.map(rowID => {
                        const item = p.itemForRowID[rowID];
                        if (item === undefined) return null;
                        const { components } = item;
                        const hasAction = item.action?.token !== undefined;
                        if (components.length === 0) return null;

                        return (
                            <IsWireComponentInCollectionContext.Provider
                                value={{
                                    hasAction,
                                    onClick: () => {
                                        if (!hasAction) return;
                                        runActionAndHandleURL(item.action, backend);
                                    },
                                }}
                                key={rowID}>
                                <div
                                    tw="first:-mt-6"
                                    className={classNames({ hasAction, isGrid })}
                                    css={css`
                                        &.hasAction {
                                            ${tw`cursor-pointer`}
                                        }

                                        &.isGrid {
                                            ${tw`first:mt-0 all-child:mt-0`}
                                        }
                                    `}
                                    onClick={e => {
                                        if (!hasAction) return;
                                        e.stopPropagation();
                                        const origin = e.target;
                                        if (origin instanceof HTMLElement) {
                                            let current: HTMLElement | null = origin;
                                            while (current !== null) {
                                                switch (current.tagName.toLowerCase()) {
                                                    case "a":
                                                    case "label":
                                                    case "button":
                                                    case "input":
                                                    case "textarea":
                                                        return;
                                                }
                                                current = current.parentElement;
                                            }
                                        }
                                        runActionAndHandleURL(item.action, backend);
                                    }}>
                                    <WireComponentStack
                                        // No need for one id per item
                                        id={undefined}
                                        containerStyle={p.containerStyle}
                                        components={components}
                                        componentIndex={componentIndex}
                                        layout={p.layout}
                                        alignment={p.alignment}
                                        backgroundEffect={HeroImageEffect.None}
                                        withBlur={false}
                                        width={ContainerWidth.Normal}
                                        padding={ContainerPadding.Medium}
                                        backend={backend}
                                        horizontalPadding={false}
                                        componentDependencies={componentDependencies}
                                        breadcrumbsShouldPortal={false}
                                        treatTitleAsFirstComponent={false}
                                        firstRenderableComponentIndex={null}
                                        isFirstComponent={false}
                                        isOnlyComponent={false}
                                    />
                                </div>
                            </IsWireComponentInCollectionContext.Provider>
                        );
                    })}
                </ForEachItemsWrapper>
            </WireListContainer>
            {numPages !== undefined && pageIndex !== undefined && numPages > 1 && (
                <div tw="self-center">
                    <Pager
                        numPages={numPages}
                        page={pageIndex.value}
                        onPageChange={page => {
                            if (pageIndexToken === undefined) return;
                            backend.valueChanged(pageIndexToken, page, ValueChangeSource.User);
                        }}
                    />
                </div>
            )}
        </>
    );
};

const ContainerSectionStyle = styled.section`
    &.style-highlight {
        box-shadow: 0 1px 0 ${p => p.theme.borderBase};
    }

    &.darken .background-image,
    &.lighten .background-image {
        opacity: 0.5;
    }

    &.blur {
        overflow: hidden;
    }

    &:first-child.full-bleed,
    &:first-child.style-none,
    .full-bleed + &.full-bleed,
    .style-none + &.style-none,
    .full-bleed + &.style-none {
        margin-top: 0;
    }
`;

// TODO: This doesn't belong in here, but there's a dependency cycle between
// this and `WireComponentRenderer`
const WireComponentStack: WireRenderer<
    Omit<WireContainerComponent, "layout">, // this hack seems like it shouldn't be needed.
    {
        id: string | undefined;
        specialComponents?: readonly (WireButtonComponent | null)[];
        customCssClassName?: string;
        componentIndex: ComponentIndexes;
        alignment: ContainerAlignment;
        horizontalPadding?: boolean;
        layout: ContainerLayout;
        componentDependencies: PagesRendererComponentDependencies;
        breadcrumbsShouldPortal: boolean;
        treatTitleAsFirstComponent: boolean;
        firstRenderableComponentIndex: ComponentIndexes | null;
        isFirstComponent: boolean;
        isOnlyComponent: boolean;
    }
> = React.memo(props => {
    const {
        id,
        backend,
        backgroundImage,
        backgroundEffect = HeroImageEffect.None,
        withBlur,
        width,
        padding,
        layout,
        componentIndex,
        components,
        customCssClassName,
        alignment,
        horizontalPadding = true,
        componentDependencies,
        breadcrumbsShouldPortal,
        treatTitleAsFirstComponent,
        firstRenderableComponentIndex,
        isFirstComponent,
        isOnlyComponent,
    } = props;
    let { containerStyle: background } = props;

    if ((background as UIBackgroundStyle) === UIBackgroundStyle.AccentLegacy) background = UIBackgroundStyle.Accent;

    let theme = useWireAppTheme();
    const currentBackgroundColor = useContainerBackground();

    const isPlayerOrPreview = useIsPlayerOrPreview();

    if (
        isPlayerOrPreview &&
        props.components.every(comp => comp === null || comp.kind === WireComponentKind.InternalSeparator)
    ) {
        return null;
    }

    if (!isPlayerOrPreview && props.components.every(comp => comp === null)) {
        return null;
    }

    let specialComponents: React.ReactNode = undefined;

    if (props.specialComponents !== undefined && props.specialComponents.length > 0) {
        specialComponents = (
            <WireContainer>
                <div tw="flex">
                    {props.specialComponents.map((c, i) => {
                        if (c === null) return null;
                        return (
                            <WireButton
                                appearance={c.appearance}
                                {...makeSingleActionSpreadProps(c.action, backend)}
                                key={i}
                                tw="mr-4">
                                {c.title ?? "Err"}
                            </WireButton>
                        );
                    })}
                </div>
            </WireContainer>
        );
    }

    const columns: (WireComponent | null)[][] = [];
    let columnItems: (WireComponent | null)[] = [];
    for (const component of components) {
        if (component?.kind === WireComponentKind.InternalSeparator) {
            columns.push(columnItems);
            columnItems = [];
        } else {
            columnItems.push(component);
        }
    }
    columns.push(columnItems);
    const isMultiColumn = columns.length > 1;

    const hasAnyRenderableChildren = columns.some(column => column.some(component => component !== null));
    if (!hasAnyRenderableChildren) {
        return null;
    }

    let contents: React.ReactNode = (
        <div>
            <div
                className={classNames(
                    layout,
                    width,
                    padding,
                    !horizontalPadding && background !== UIBackgroundStyle.Card && "no-hpad"
                )}
                css={css`
                    width: 100%;
                    margin: auto;
                    gap: 0 20px;

                    &.no-hpad.no-hpad.no-hpad.no-hpad.no-hpad.no-hpad.no-hpad {
                        padding-left: 0px !important;
                        padding-right: 0px !important;
                    }

                    &.${ContainerWidth.Normal}, &.${ContainerWidth.Small}, &.${ContainerWidth.XSmall} {
                        .section-container {
                            width: 100%;
                            min-width: 100%;
                        }
                    }

                    &.${ContainerWidth.Normal} {
                        max-width: 1260px;
                    }

                    .max-size-xl &&,
                    &&.${ContainerWidth.Small} {
                        max-width: 1024px;
                    }

                    .max-size-lg &&&,
                    &&&.${ContainerWidth.XSmall} {
                        max-width: 768px;
                    }

                    &.${ContainerLayout.Full} {
                        ${tw`grid grid-cols-1 p-0`}
                    }

                    &.${ContainerLayout.FourColumns},
                        &.${ContainerLayout.OneToOne},
                        &.${ContainerLayout.OneToThree},
                        &.${ContainerLayout.OneToTwo},
                        &.${ContainerLayout.ThreeColumns},
                        &.${ContainerLayout.ThreeToOne},
                        &.${ContainerLayout.TwoToOne} {
                        ${tw`grid p-4 gp-sm:px-5 gp-md:px-6 gp-lg:px-7 gp-xl:px-8`}
                    }

                    &.${ContainerLayout.OneToOne} {
                        ${tw`grid grid-cols-1
                          gp-lg:(grid-cols-2 all-child:min-w-0)`}
                    }

                    &.${ContainerLayout.ThreeColumns} {
                        ${tw`grid grid-cols-1
                          gp-xl:(grid-cols-3 all-child:min-w-0)`}
                    }

                    &.${ContainerLayout.TwoToOne} {
                        ${tw`grid grid-cols-1
                          gp-lg:([grid-template-columns:2fr 1fr] all-child:min-w-0)`}
                    }

                    &.${ContainerLayout.ThreeToOne} {
                        ${tw`grid grid-cols-1
                          gp-md:([grid-template-columns:3fr 1fr] all-child:min-w-0)`}
                    }

                    &.${ContainerLayout.OneToTwo} {
                        ${tw`grid grid-cols-1
                          gp-lg:([grid-template-columns:1fr 2fr] all-child:min-w-0)`}
                    }

                    &.${ContainerLayout.OneToThree} {
                        ${tw`grid grid-cols-1
                          gp-md:([grid-template-columns:1fr 3fr] all-child:min-w-0)`}
                    }

                    &.${ContainerLayout.FourColumns} {
                        ${tw`grid grid-cols-1
                          gp-lg:(grid-cols-2 all-child:min-w-0)
                          gp-xl:grid-cols-4`}
                    }

                    &.${ContainerPadding.Medium} {
                        padding-top: 0;
                        padding-bottom: 0;
                    }

                    &.${ContainerPadding.Large} {
                        padding-top: 24px;
                        padding-bottom: 24px;
                    }

                    &.${ContainerPadding.XLarge} {
                        padding-top: 48px;
                        padding-bottom: 48px;
                    }
                `}>
                {columns.map((column, columnIdx) => {
                    /**
                     * This calculates the subindex for an empty column.
                     * NOT used if the column has components in it.
                     */
                    const subIndex = columns.slice(0, columnIdx).reduce((acc, curr) => acc + curr.length, columnIdx);

                    return (
                        <ResponsiveReset
                            key={columnIdx}
                            tw="flex flex-col h-full"
                            css={css`
                                &:has(.calendar-collection-container) {
                                    min-width: 0;
                                }
                            `}>
                            <ResponsiveProvider
                                tw="flex flex-col flex-grow"
                                className={alignment}
                                css={css`
                                    &.${ContainerAlignment.center} {
                                        justify-content: center;
                                        align-items: center;
                                    }
                                `}>
                                <WireContainerOrEmptyState
                                    column={column}
                                    columnIdx={columnIdx}
                                    // It's safe to drop the second component because we don't allow for nested containers.
                                    componentIndex={[componentIndex[0], subIndex]}
                                    backend={props.backend}
                                    isMultiColumn={isMultiColumn}
                                    componentDependencies={componentDependencies}
                                    breadcrumbsShouldPortal={breadcrumbsShouldPortal}
                                    treatTitleAsFirstComponent={treatTitleAsFirstComponent}
                                    firstRenderableComponentIndex={firstRenderableComponentIndex}
                                    isOnlyComponent={isOnlyComponent}
                                />
                            </ResponsiveProvider>
                        </ResponsiveReset>
                    );
                })}
            </div>
            {specialComponents}
            {background === UIBackgroundStyle.None && <div />}
        </div>
    );

    if (background === UIBackgroundStyle.Card) {
        theme = mergeTheme(theme, [defaultWireAppAccentContextOverlayUndo]);
        contents = (
            <div
                className={classNames(!horizontalPadding && "no-hpad")}
                tw="z-10 w-full relative flex flex-col px-4 self-center
                    gp-sm:px-5
                    gp-md:px-6
                    gp-lg:px-7
                    gp-xl:px-8
                    gp-2xl:([max-width:1260px])"
                css={css`
                    .max-size-lg & {
                        max-width: 768px;
                    }

                    .max-size-xl & {
                        max-width: 1024px;
                    }

                    &.no-hpad.no-hpad.no-hpad.no-hpad.no-hpad.no-hpad.no-hpad {
                        padding-left: 0px !important;
                        padding-right: 0px !important;
                    }
                `}>
                <div
                    tw="pb-6 rounded-xl transition bg-bg-front"
                    css={css`
                        ${tw`border border-border-base`}

                        .max-size-lg & {
                            max-width: 704px;
                        }

                        .max-size-xl & {
                            max-width: 960px;
                        }

                        .hasAction & {
                            ${getRingFocusCss(theme)}
                        }

                        .custom-collection-container & {
                            ${tw`pb-0`}
                        }
                    `}>
                    <TailwindThemeProvider theme={theme}>{contents}</TailwindThemeProvider>
                </div>
            </div>
        );
    } else if (background === UIBackgroundStyle.Image && backgroundEffect === HeroImageEffect.Lighten) {
        theme = mergeTheme(theme, [defaultWireAppImageBrightenContextOverlay]);
        contents = <TailwindThemeProvider theme={theme}>{contents}</TailwindThemeProvider>;
    } else if (background === UIBackgroundStyle.Image) {
        theme = mergeTheme(theme, [defaultWireAppImageDarkenContextOverlay]);
        contents = <TailwindThemeProvider theme={theme}>{contents}</TailwindThemeProvider>;
    } else if (isAccentBackgroundStyle(background) || background === UIBackgroundStyle.Dark) {
        theme = mergeTheme(theme, [defaultWireAppAccentContextOverlay]);
        contents = <TailwindThemeProvider theme={theme}>{contents}</TailwindThemeProvider>;
    } else if (background === UIBackgroundStyle.Highlight) {
        theme = mergeTheme(theme, [defaultWireAppAccentContextOverlayUndo]);
        contents = <TailwindThemeProvider theme={theme}>{contents}</TailwindThemeProvider>;
    }

    const isFullBleed = background !== UIBackgroundStyle.None && background !== UIBackgroundStyle.Card;

    const validId = definedMap(id, i => makeValidHTMLId(i));

    const singleBgImage = getFirstElementFromArrayOrSingleElement(backgroundImage);

    const backgroundColor = getContainerBackgroundColor(
        theme,
        background,
        backgroundEffect === HeroImageEffect.Darken,
        backgroundEffect === HeroImageEffect.Lighten
    );

    return (
        <SectionStyleProvider value={background}>
            <ContainerBackgroundProvider value={currentBackgroundColor ?? backgroundColor}>
                <ContainerSectionStyle
                    style={{
                        backgroundColor,
                    }}
                    id={validId}
                    data-testid={`wire-component-stack${customCssClassName ? `-${customCssClassName}` : ""}`}
                    className={classNames(
                        "component-root",
                        "component-stack",
                        isFullBleed ? "full-bleed" : "partial-bleed",
                        isAccentBackgroundStyle(background) && "accent-bg",
                        background === UIBackgroundStyle.Image && withBlur && "blur",
                        backgroundEffect.toLocaleLowerCase(),
                        `style-${background.toLowerCase()}`,
                        customCssClassName,
                        isFirstComponent && "first-component"
                    )}
                    tw="flex isolate relative justify-center pb-6 mt-6 w-full transition-opacity z-wire-container-section"
                    css={css`
                        &.partial-bleed {
                            ${tw`pb-0`}
                        }

                        &.first-component:not(.style-${UIBackgroundStyle.Card.toLowerCase()}) {
                            ${tw`mt-0`}
                        }
                    `}>
                    {background === UIBackgroundStyle.Image && isDefined(singleBgImage) && (
                        <Img
                            className={classNames("background-image", { blur: withBlur })}
                            tw="object-cover absolute inset-0 w-full h-full filter pointer-events-none"
                            css={css`
                                &.blur {
                                    ${tw`transform scale-125 blur-md`}
                                }
                            `}
                            data-test="hero-background-image"
                            src={massageImageUrl(
                                singleBgImage,
                                {
                                    width: window.innerWidth,
                                    thumbnail: false,
                                },
                                backend.appID
                            )}
                            width={"100%"}
                            height={400}
                            draggable={false}
                            isPages={true}
                        />
                    )}
                    <div className="section-container" tw="flex relative flex-col w-full">
                        {contents}
                    </div>
                </ContainerSectionStyle>
            </ContainerBackgroundProvider>
        </SectionStyleProvider>
    );
});

interface WireContainerOrEmptyStateProps {
    readonly column: (WireComponent | null)[];
    readonly columnIdx: number;
    readonly componentIndex: ComponentIndexes;
    readonly backend: WireBackendInterface;
    readonly isMultiColumn: boolean;
    readonly componentDependencies: PagesRendererComponentDependencies;
    readonly breadcrumbsShouldPortal: boolean;
    readonly treatTitleAsFirstComponent: boolean;
    readonly firstRenderableComponentIndex: ComponentIndexes | null;
    readonly isOnlyComponent: boolean;
}

const WireContainerOrEmptyState: React.VFC<WireContainerOrEmptyStateProps> = p => {
    const {
        column,
        columnIdx,
        componentIndex,
        backend,
        isMultiColumn,
        componentDependencies,
        breadcrumbsShouldPortal,
        treatTitleAsFirstComponent,
        firstRenderableComponentIndex,
        isOnlyComponent,
    } = p;

    const { ContainerBuilderEmptyState } = componentDependencies;
    const isPlayerOrPreview = useIsPlayerOrPreview();

    if (column.length === 0 && !isPlayerOrPreview) {
        return (
            <WireContainer expand={true} isInMultipleColumnLayout={isMultiColumn}>
                <ContainerBuilderEmptyState componentIndex={componentIndex} />
            </WireContainer>
        );
    } else {
        const isStillOnlyComponent = isOnlyComponent && !isMultiColumn && column.filter(c => c !== null).length === 1;
        const innerComponents: React.ReactNode = column.map((item: WireComponent | null, itemIdx: number) => {
            // We cannot just filter out the `null` components because `itemIdx` should take them into account to
            // generate proper indexes.
            if (item === null) return null;

            const index: ComponentIndexes = [componentIndex[0], (componentIndex[1] ?? 0) + itemIdx];
            return (
                <WireComponentRenderer
                    componentIndex={index}
                    key={`${columnIdx}-${itemIdx}`}
                    component={item}
                    backend={backend}
                    isInMultipleColumnLayout={isMultiColumn}
                    componentDependencies={componentDependencies}
                    breadcrumbsShouldPortal={breadcrumbsShouldPortal}
                    treatTitleAsFirstComponent={treatTitleAsFirstComponent}
                    firstRenderableComponentIndex={firstRenderableComponentIndex}
                    isOnlyComponent={isStillOnlyComponent}
                />
            );
        });
        return <NavBarPortalContext.Provider value={undefined}>{innerComponents}</NavBarPortalContext.Provider>;
    }
};
