import "twin.macro";

import type { UnsplashResult } from "@glide/backend-api";
import { getUnsplashImages, pingUnsplashDownload } from "@glide/backend-api";
import type { EminenceFlags } from "@glide/billing-types";
import { GlideIcon, LoadingIndicator, styled } from "@glide/common";
import { QuotaKind } from "@glide/common-core/dist/js/Database/quotas";
import { trackEvent } from "@glide/common-core/dist/js/analytics";
import type { DropImageHandler } from "@glide/common-core/dist/js/components/portable-renderers";
import { handleFileUpload } from "@glide/common-core/dist/js/components/upload-handlers";
import { extractDropData } from "@glide/common-core/dist/js/drop-controller";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { getCurrentAppAndState, type RootState } from "@glide/action-reducer-utils";
import { isUrl } from "@glide/support";
import { DroppableDiv } from "@glide/wire-renderer";
import debounce from "lodash/debounce";
import sample from "lodash/sample";
import * as React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { css } from "styled-components";
import { Button } from "../button/button";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import InputLabel from "../input-label/input-label";

interface Props extends React.PropsWithChildren {
    readonly eminenceFlags: EminenceFlags;
    readonly onImagePicked: (newUrl: string) => void;
    readonly onClose: (e: React.MouseEvent<HTMLDivElement> | undefined) => void;
}

export const ImageSearchPicker: React.VFC<Props> = p => {
    const { onImagePicked, onClose, eminenceFlags } = p;

    const appID = useAppID() ?? "";

    const app = useSelector((state: RootState) => {
        return getCurrentAppAndState(appID, state.apps);
    });

    const maxFileSizeUsage = eminenceFlags.quotas[QuotaKind.FileBytesUsed].prepaidUsage;
    const fileBytesUsed = app?.quotas?.[QuotaKind.FileBytesUsed].current ?? 0;

    const [query, setQuery] = React.useState<string>(
        () => sample(["abstract", "landscape", "architecture", "gradient", "sky"]) ?? "abstract"
    );

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>();
    const [images, setImages] = React.useState<UnsplashResult[]>([]);
    const [selectedImage, setSelectedImage] = React.useState<UnsplashResult | undefined>();
    const [uploading, setUploading] = React.useState(false);

    const [selected, setSelected] = React.useState<"upload" | "unsplash" | "url">("upload");
    const [url, setUrl] = React.useState("");

    const setUnsplashSelectedResult = React.useCallback(
        async (evt: React.MouseEvent<HTMLDivElement>, image: UnsplashResult) => {
            evt.stopPropagation();
            setSelectedImage(image);
            trackEvent("unsplash_image_picked", {});
            onImagePicked(image.urls.raw);
            const usePexelsStockPhotos = getFeatureSetting("usePexelsStockPhotos");
            if (!usePexelsStockPhotos) {
                await pingUnsplashDownload(appID, image.id, getAppFacilities());
            }
        },
        [appID, onImagePicked]
    );

    const searchImages = React.useCallback(
        async (searchQuery: string) => {
            if (searchQuery.length === 0) {
                return;
            }

            setLoading(true);
            const imgs = await getUnsplashImages(appID, searchQuery, getAppFacilities());
            if (imgs.error !== undefined) {
                setImages([]);
                setError("Please try again in a bit.");
            } else {
                if (imgs.results !== undefined) {
                    setImages(imgs.results);
                    setError(undefined);
                }
            }
            setLoading(false);
        },
        [appID]
    );

    const debouncedOnQueryChange = React.useMemo(() => debounce(searchImages, 1000), [searchImages]);

    // Track if we've done the initial search
    const didInitialSearch = React.useRef(false);

    // Perform initial search when component mounts
    React.useEffect(() => {
        if (!didInitialSearch.current) {
            didInitialSearch.current = true;
            void searchImages(query);
        }
    }, [query, searchImages]);

    const onQueryChange = React.useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation();
            const newQuery = e.target.value;
            setQuery(newQuery);
            await debouncedOnQueryChange(newQuery);
        },
        [debouncedOnQueryChange]
    );

    const handleFileUploadInner = React.useCallback(
        async (file: File): Promise<void> => {
            const path = await handleFileUpload(file, appID);
            if (path !== undefined) {
                onImagePicked(path);
            }
        },
        [appID, onImagePicked]
    );

    const handleImageChange = React.useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            const input = e.target;
            const { files } = input;
            if (files === null || files.length === 0) return;

            setUploading(true);
            await handleFileUploadInner(files[0]);
            setUploading(false);
            onClose(undefined);
        },
        [handleFileUploadInner, onClose]
    );

    const handleImageDrop: DropImageHandler = React.useCallback(
        async (handle: string) => {
            const dropData = extractDropData(handle);
            if (dropData === undefined) return;

            setUploading(true);
            if (dropData.kind === "file") {
                await handleFileUploadInner(dropData.file);
            } else {
                const uri = dropData.uri;
                onImagePicked(uri);
            }

            setUploading(false);
            onClose(undefined);
        },
        [handleFileUploadInner, onClose, onImagePicked]
    );

    const usePexelsStockPhotos = getFeatureSetting("usePexelsStockPhotos");
    let content: React.ReactNode;

    if (selected === "url") {
        content = (
            <PickerBody>
                <InputWrapper>
                    <InputLabel
                        label={undefined}
                        placeholder="Public image URL"
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                        onClick={e => e.stopPropagation()}
                    />
                    <Button
                        tw="mb-8"
                        buttonType="primary"
                        label="Save"
                        disabled={!isUrl(url)}
                        size="md"
                        variant="default"
                        onClick={async () => {
                            onImagePicked(url);
                            onClose(undefined);
                        }}
                    />
                </InputWrapper>
            </PickerBody>
        );
    } else if (selected === "unsplash") {
        const searchResults = (
            <PickerResultsContainer>
                {images.map(image => {
                    const imageUrl = `${image.user.links.html}?utm_source=Glide&utm_medium=referral`;
                    return (
                        <PickerResult
                            key={image.id}
                            selected={image === selectedImage}
                            onClick={e => setUnsplashSelectedResult(e, image)}>
                            <img src={image.urls.thumb} alt={image.id} />
                            <ResultAttribution>
                                by{" "}
                                <AttributionLink href={imageUrl} target="_blank" rel="noopener noreferrer">
                                    {image.user.name}
                                </AttributionLink>
                            </ResultAttribution>
                        </PickerResult>
                    );
                })}
            </PickerResultsContainer>
        );

        content = (
            <PickerBody>
                <InputWrapper>
                    <InputLabel
                        label={undefined}
                        placeholder="Search for images"
                        value={query}
                        onChange={onQueryChange}
                        onClick={e => e.stopPropagation()}
                    />
                </InputWrapper>
                <Attribution>
                    Images by{" "}
                    <AttributionLink
                        href={
                            usePexelsStockPhotos
                                ? "https://www.pexels.com/"
                                : "https://unsplash.com/?utm_source=Glide&utm_medium=referral"
                        }
                        target="_blank"
                        rel="noopener noreferrer">
                        {usePexelsStockPhotos ? "Pexels" : "Unsplash"}
                    </AttributionLink>
                </Attribution>
                <PickerResultsScrollWrapper>
                    {loading && <InfoWrapper tw="text-text-base">Searching for images...</InfoWrapper>}
                    {error !== undefined && <InfoWrapper>{error}</InfoWrapper>}
                    {!loading && images.length === 0 && (
                        <InfoWrapper tw="text-text-base">
                            {usePexelsStockPhotos ? "Pexels" : "Unsplash"} cannot find images matching <i>{query}</i>
                        </InfoWrapper>
                    )}
                    {!loading && images.length > 0 && searchResults}
                </PickerResultsScrollWrapper>
            </PickerBody>
        );
    } else {
        if (
            getFeatureSetting("showFileQuotas") &&
            fileBytesUsed !== undefined &&
            fileBytesUsed > 0 &&
            maxFileSizeUsage > 0 &&
            fileBytesUsed > maxFileSizeUsage
        ) {
            content = (
                <div tw="p-4 m-4 text-center">
                    <p>
                        You have exceeded the file storage included in your team plan. Additional files cannot be
                        uploaded. Upgrade now to increase your team's file storage capacity or delete files to free up
                        space.
                    </p>
                    <NavLink
                        tw="py-2 px-4 m-4 mt-8 block text-center rounded-full bg-n200A"
                        to={`/o/${app?.ownerID}/billing`}>
                        Upgrade Plan
                    </NavLink>
                </div>
            );
        } else {
            if (uploading) {
                content = (
                    <label className="upload-wrapper">
                        <LoadingIndicator />
                    </label>
                );
            } else {
                content = (
                    <label className="upload-wrapper">
                        <input hidden={true} type="file" onChange={handleImageChange} accept="image/*" />
                        <DroppableDiv
                            className="upload-inner"
                            dragOverClassName="dragging"
                            onDropImage={handleImageDrop}>
                            <GlideIcon
                                className="upload-icon"
                                kind="stroke"
                                icon="st-upload"
                                tw="mb-6"
                                iconSize={64}
                                strokeWidth={3}
                            />
                            <div tw="text-builder-lg text-text-base mb-1">Select files to upload</div>
                            <div tw="text-builder-sm text-text-pale">or drop your files here</div>
                        </DroppableDiv>
                    </label>
                );
            }
        }
    }

    return (
        <PickerContainer className="click-outside-ignore" onClick={e => e.stopPropagation()}>
            <PickerHeader>
                <div className={selected === "upload" ? "tab selected" : "tab"} onClick={() => setSelected("upload")}>
                    <div tw="relative">
                        <span aria-hidden="true" tw="opacity-0 font-semibold">
                            Upload
                        </span>
                        <span tw="absolute inset-0">Upload</span>
                    </div>
                </div>
                <div className={selected === "url" ? "tab selected" : "tab"} onClick={() => setSelected("url")}>
                    <div tw="relative">
                        <span aria-hidden="true" tw="opacity-0 font-semibold">
                            From URL
                        </span>
                        <span tw="absolute inset-0">From URL</span>
                    </div>
                </div>
                <div
                    className={selected === "unsplash" ? "tab selected" : "tab"}
                    onClick={() => setSelected("unsplash")}>
                    <div tw="relative">
                        <span aria-hidden="true" tw="opacity-0 font-semibold">
                            Stock Image
                        </span>
                        <span tw="absolute inset-0">Stock Image</span>
                    </div>
                </div>
                <div className="spacer" />
                <div
                    onClick={onClose}
                    tw="w-8 h-8 p-1.5 mr-3 mt-2.5 rounded-full bg-bg-back text-icon-base cursor-pointer hover:(text-icon-dark bg-bg-behind)">
                    <GlideIcon kind="stroke" icon="st-close" iconSize={20} />
                </div>
            </PickerHeader>
            {content}
        </PickerContainer>
    );
};

const PickerContainer = styled.div`
    overflow: hidden;
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;

    top: calc(50% - 220px);
    left: calc(50%);
    width: 440px;
    border-radius: 12px;

    background-color: ${p => p.theme.bgColorLight};

    transform: translateX(-50%);

    box-shadow: ${p => p.theme.shadowDark4};
    z-index: 10;

    .upload-wrapper {
        padding: 8px;

        height: 280px;
        width: 100%;

        display: flex;
        flex-direction: column;

        position: relative;
    }

    .upload-inner {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 12px;
        color: ${p => p.theme.iconPale};

        cursor: pointer;
        border: 2px dashed transparent;

        transition: border 0.2s;

        .upload-icon {
            color: ${p => p.theme.iconPale};
            transition: color ${p => p.theme.transitionBase};
        }

        &:hover .upload-icon {
            color: ${p => p.theme.iconDark};
        }
    }

    .upload-inner.dragging {
        border: 2px dashed ${p => p.theme.blue};
        .upload-icon {
            color: ${p => p.theme.blue};
        }
    }
`;

const PickerHeader = styled.div`
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    column-gap: 8px;
    text-align: center;

    :before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        bottom: 0;
        background-color: ${p => p.theme.borderBase};
    }

    padding: 0 0 0 12px;

    .spacer {
        flex-grow: 1;
    }

    .tab {
        display: flex;
        justify-content: center;
        align-items: center;

        color: ${p => p.theme.textPale};
        font-size: 14px;
        padding: 0 4px;
        cursor: pointer;
        transition: color ${p => p.theme.transitionBase};

        &:hover {
            color: ${p => p.theme.textBase};
        }
    }

    .tab.selected {
        padding-top: 2px;
        border-bottom: 2px solid ${p => p.theme.textDark};
        color: ${p => p.theme.textDark};
        font-weight: 600;
    }
`;

const PickerBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`;

const InputWrapper = styled.div`
    width: 100%;
    padding: 0px 24px;
    margin-top: 8px;
`;

const PickerResultsScrollWrapper = styled.div`
    width: 100%;
    display: flex;
    max-height: 204px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
`;

const InfoWrapper = styled.div`
    margin: 14px 0px 24px 0px;
`;

const PickerResultsContainer = styled.div`
    margin: 0px 24px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 120px 120px 120px;
    grid-gap: 14px;
`;

const PickerResult = styled.div<{ selected: boolean }>`
    > img {
        width: 120px;
        height: 60px;
        object-fit: cover;

        cursor: pointer;

        :hover {
            opacity: 0.5;
        }
    }

    ${p =>
        p.selected &&
        css`
            > img {
                border: 2px solid ${p.theme.acceptColor};
            }
        `}
`;

const ResultAttribution = styled.div`
    color: ${p => p.theme.fgColorMedium};
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AttributionLink = styled.a`
    color: ${p => p.theme.fgColorMedium};
    :hover {
        color: ${p => p.theme.fgColorMedium};
        text-decoration: underline !important;
    }
`;

const Attribution = styled.div`
    font-size: 10px;
    color: ${p => p.theme.fgColorMedium};
    margin-bottom: 14px;
`;
