import type { WireDataPlotComponent } from "@glide/fluent-components/dist/js/base-components";
import type { WireRenderer } from "../wire-renderer";
import React from "react";
import { ComposedChart as RechartsComposedChart } from "../charts/composed-loader";
import { TextComponentStyle } from "@glide/component-utils";
import { isEmptyOrUndefinedish } from "@glide/support";
import { defaultWireAppAccentContextOverlayUndo, mergeTheme, TailwindThemeProvider } from "@glide/theme";
import { Text } from "../../components/text/text";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import {
    useDynamicFilter,
    useMultipleDynamicFilters,
    useSearchBar,
    WireListContainer,
} from "../wire-list-container/wire-list-container";
import { UIStyleVariant } from "@glide/wire";
import "twin.macro";

type WireDataPlotRenderer = WireRenderer<Omit<WireDataPlotComponent, "format">, { isFirstComponent: boolean }>;

export const WireDataPlot: WireDataPlotRenderer = React.memo(p => {
    const {
        data,
        columnsToDisplay,
        graphTitle,
        caption,
        gradientColors,
        gradientMapping,
        colorScheme,
        showYAxisLabels,
        showLegend,
        chartSize,
        backend,
        searchBar,
    } = p;
    const mergedTheme = mergeTheme(useWireAppTheme(), [defaultWireAppAccentContextOverlayUndo]);

    const filterArgs = useDynamicFilter(p.dynamicFilter, backend);
    const multipleFilterProps = useMultipleDynamicFilters(p.multipleDynamicFilters, backend);
    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, undefined);

    return (
        <div tw="px-4 pb-4 rounded-xl border border-border-base bg-bg-front gp-sm:px-5 gp-md:px-6">
            {!isEmptyOrUndefinedish(graphTitle) && (
                <TailwindThemeProvider theme={mergedTheme}>
                    <Text
                        tw="my-3 font-semibold [font-size: 22px]
                                    gp-md:([font-size: 23px] tracking-tight)
                                    gp-lg:[font-size: 24px]
                                    gp-sm:([letter-spacing: -0.01em])"
                        variant={TextComponentStyle.headlineMedium}
                        element="h2">
                        {graphTitle}
                    </Text>
                </TailwindThemeProvider>
            )}
            <WireListContainer
                appKind={backend.appKind}
                titleActions={[]}
                {...filterArgs}
                multipleFilterProps={multipleFilterProps}
                styleVariant={UIStyleVariant.Default}
                searchValue={searchValue}
                onSearchChange={onSearchChange}
                isFirstComponent={false}>
                <div tw="[span]:(text-xs text-text-xpale) [tspan]:(text-xs text-text-xpale)">
                    <RechartsComposedChart
                        data={data}
                        columnsToDisplay={columnsToDisplay}
                        colorScheme={colorScheme}
                        gradientMapping={gradientMapping}
                        gradientColors={gradientColors}
                        showYAxisLabels={showYAxisLabels}
                        showLegend={showLegend}
                        chartSize={chartSize}
                    />
                </div>
                {!isEmptyOrUndefinedish(caption) && (
                    <TailwindThemeProvider theme={mergedTheme}>
                        <Text
                            tw="text-center my-3 [font-size: 11px]
                                    gp-md:([font-size: 12px] tracking-tight)
                                    gp-lg:[font-size: 13px]
                                    gp-sm:([letter-spacing: -0.01em])"
                            variant={TextComponentStyle.footnote}
                            element="h5">
                            {caption}
                        </Text>
                    </TailwindThemeProvider>
                )}
            </WireListContainer>
        </div>
    );
});
