import { styled } from "@glide/common";
import * as Elements from "@glide/common-core/dist/js/shared-ui/style";

export const Wrapper = styled.div<{ width: number }>`
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${p => p.width + 24}px;
`;

export const CodeWrapper = styled.div`
    border: 1px solid ${p => p.theme.borderColor};
    background: white;
    border-radius: 10px;
    padding: 20px;

    box-shadow: ${p => p.theme.shadow4};
`;

export const Title = styled(Elements.Label)`
    font-size: 11px;
    padding: 12px 24px;
`;

export const HelpLink = styled.a`
    color: ${p => p.theme.linkColor};
    :hover {
        color: ${p => p.theme.linkColor};
    }
`;
