import { GlideIcon } from "@glide/common";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import { type WireCommentsComponent, CommentsStyle } from "@glide/fluent-components/dist/js/base-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { isDefined } from "@glide/support";
import {
    type WireAction,
    type WireAlwaysEditableValue,
    UIBackgroundStyle,
    UIButtonAppearance,
    ValueChangeSource,
} from "@glide/wire";
import classNames from "classnames";
import * as React from "react";

import { useIsCommentScreen } from "../../../chrome/content/lib/screen-special-cases";
import { Img } from "../../../components/img/img";
import { SectionStyleProvider, useSectionStyle } from "../../wire-container/wire-container";
import { WireButton } from "../../wire-button/wire-button";
import { useComments } from "./use-comments";
import { ChatInput } from "./chat-input";

import "twin.macro";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";

interface CommentInputProps {
    readonly id: string;
    readonly newComment: WireAlwaysEditableValue<string>;
    readonly postNewComment: WireAction;
    readonly backend: WireBackendInterface;
    readonly loggedUser: WireCommentsComponent["loggedUser"];
    readonly textAreaActive: boolean;
    readonly setTextAreaActive: (active: boolean) => void;
    readonly testId?: string;
    readonly buttonText: string | null;
    readonly allowAdd: boolean;
    readonly componentStyle: CommentsStyle;
}

export const CommentInput: React.VFC<CommentInputProps> = p => {
    const {
        newComment,
        postNewComment,
        backend,
        loggedUser,
        textAreaActive,
        setTextAreaActive,
        testId,
        id,
        buttonText,
        allowAdd,
        componentStyle,
    } = p;

    if (componentStyle === CommentsStyle.Comments) {
        return (
            <CommentInputImpl
                newComment={newComment}
                postNewComment={postNewComment}
                backend={backend}
                loggedUser={loggedUser}
                textAreaActive={textAreaActive}
                setTextAreaActive={setTextAreaActive}
                testId={testId}
                id={id}
                buttonText={buttonText}
                allowAdd={allowAdd}
            />
        );
    } else {
        return (
            <ChatInput
                newComment={newComment}
                postNewComment={postNewComment}
                backend={backend}
                loggedUser={loggedUser}
                textAreaActive={textAreaActive}
                setTextAreaActive={setTextAreaActive}
                allowAdd={allowAdd}
            />
        );
    }
};

interface CommentInputImplProps {
    readonly id: string;
    readonly newComment: WireAlwaysEditableValue<string>;
    readonly postNewComment: WireAction;
    readonly backend: WireBackendInterface;
    readonly loggedUser: WireCommentsComponent["loggedUser"];
    readonly textAreaActive: boolean;
    readonly setTextAreaActive: (active: boolean) => void;
    readonly testId?: string;
    readonly buttonText: string | null;
    readonly allowAdd: boolean;
}

const CommentInputImpl: React.VFC<CommentInputImplProps> = p => {
    const {
        newComment,
        backend,
        postNewComment,
        loggedUser,
        textAreaActive,
        setTextAreaActive,
        testId,
        id,
        buttonText,
        allowAdd,
    } = p;

    const formRef = React.useRef<HTMLFormElement>(null);
    const { sendNewComment, submitOnEnter } = useComments(backend, postNewComment, formRef, newComment);
    const isCommentScreen = useIsCommentScreen();

    const sendNewCommentAndResetHeight = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            sendNewComment(e);
            setTextAreaActive(document.activeElement?.id === id);
            e.currentTarget.style.height = "";
        },
        [id, sendNewComment, setTextAreaActive]
    );

    const hasText = newComment.value.length > 0;
    const textAreaHasMultiline = formRef.current?.scrollHeight ?? 0 > 48;
    const needsToSignIn = !isDefined(loggedUser);
    const disabledForm = needsToSignIn || !allowAdd;

    const sectionStyle = useSectionStyle();

    return (
        <form
            data-testid={testId}
            ref={formRef}
            onSubmit={sendNewCommentAndResetHeight}
            className={classNames(
                isCommentScreen ? "comment-screen" : "regular-screen",
                hasText && "has-text",
                `style-${sectionStyle}`,
                disabledForm ? "form-disabled" : "form-enabled"
            )}
            tw="relative flex flex-col gap-y-1 max-w-full px-2 pl-[46px] pb-1.5 pt-3 transition-all h-12 max-h-32 focus-within:(h-20 pt-1.5 pl-1.5) page-md:focus-within:(h-12 pt-3 pl-[46px])
            ring-1 ring-border-base [&.form-enabled]:page-hover:ring-2 bg-bg-front page-md:(rounded-[10px] flex-row)
            [&.comment-screen]:(ring-0 border-x border-border-base border-t page-md:(border-0 ring-1))
            [&.regular-screen]:(rounded-[10px] focus-within:ring-text-contextual-accent)
            [&.has-text]:(pl-1.5 pt-1.5 page-md:(pt-3 pl-[46px]))
            [&.style-dark, &.style-accent-bg]:(bg-bg-back)
            [&.form-disabled]:(bg-n100 cursor-not-allowed)
            "
        >
            <GrowingTextArea
                id={id}
                setInputActive={setTextAreaActive}
                submitOnEnter={submitOnEnter}
                formRef={formRef}
                newComment={newComment}
                backend={backend}
                allowAdd={allowAdd}
                needsToSignIn={needsToSignIn}
            />
            <Img
                className={classNames(!textAreaActive && !hasText && "show")}
                tw="absolute top-[9px] left-2 rounded-md w-[30px] h-[30px] object-cover hidden opacity-0 [&.show]:(block opacity-100) page-md:(block opacity-100) transition-all"
                src={massageImageUrl(
                    loggedUser?.userPhoto,
                    {
                        thumbnail: false,
                        width: 30,
                        height: 30,
                    },
                    backend.appID
                )}
                alternate={
                    <div tw="w-[30px] h-[30px] rounded-[5px] bg-n200 flex items-center justify-center">
                        <GlideIcon tw="text-n500 w-4 h-4" kind="stroke" icon="st-user" />
                    </div>
                }
            />
            <SectionStyleProvider value={UIBackgroundStyle.Highlight}>
                <WireButton
                    data-testid="comment-button"
                    disabled={!hasText || !allowAdd}
                    className={classNames(
                        (textAreaActive || hasText) && "show-button",
                        textAreaHasMultiline && "multi-line-textarea"
                    )}
                    tw="self-end shrink-0 page-md:(self-center scale-100 -translate-y-0.5) scale-0 [&.show-button]:scale-100 duration-75 
                [&.multi-line-textarea]:page-md:self-end"
                    size="mini"
                    appearance={UIButtonAppearance.Filled}
                >
                    {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
                    {buttonText || "Comment"}
                </WireButton>
            </SectionStyleProvider>
        </form>
    );
};

interface GrowingTextAreaProps {
    readonly id: string;
    readonly setInputActive: (active: boolean) => void;
    readonly submitOnEnter: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    readonly formRef: React.RefObject<HTMLFormElement>;
    readonly newComment: WireCommentsComponent["newComment"];
    readonly backend: WireBackendInterface;
    readonly allowAdd: boolean;
    readonly needsToSignIn: boolean;
}

const GrowingTextArea: React.VFC<GrowingTextAreaProps> = p => {
    const {
        setInputActive: setInputActive,
        submitOnEnter,
        formRef,
        id,
        newComment,
        backend,
        allowAdd,
        needsToSignIn,
    } = p;
    const { value, onChangeToken } = newComment;
    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    const heightIsSet = React.useRef(false);
    const setProperHeight = React.useCallback(
        (textarea: HTMLTextAreaElement) => {
            if (isDefined(formRef) && isDefined(formRef.current)) {
                const textareaWidth = textarea.clientWidth;
                const fontSize = 15;
                const newHeight = getAdjustedTextareaHeight(textarea, isMobile, textareaWidth, fontSize);
                formRef.current.style.height = newHeight;
                heightIsSet.current = true;
            }
        },
        [formRef, isMobile]
    );

    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const textarea = e.currentTarget;
            const content = textarea.value;
            setProperHeight(textarea);

            // This allows the submit button to not be disabled when we start writing an empty input
            if (content.length === 1 && value === "") {
                backend.valueChanged(onChangeToken, content, ValueChangeSource.User);
            }

            // Cleaning up value if we had any and we delete it all
            if (content === "" && value.length > 0) {
                backend.valueChanged(onChangeToken, content, ValueChangeSource.User);
            }
        },
        [backend, onChangeToken, setProperHeight, value]
    );
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);
    const removeFixedHeight = React.useCallback(
        (e: React.FocusEvent<HTMLTextAreaElement>) => {
            const textarea = e.currentTarget;

            if (textarea.value.length === 0) {
                if (isDefined(formRef) && isDefined(formRef.current)) {
                    formRef.current.style.height = "";
                }
            }
        },
        [formRef]
    );
    const onBlur = React.useCallback(
        (e: React.FocusEvent<HTMLTextAreaElement>) => {
            removeFixedHeight(e);
            setInputActive(false);
            const content = e.currentTarget.value;

            backend.valueChanged(onChangeToken, content, ValueChangeSource.User);
        },
        [backend, onChangeToken, removeFixedHeight, setInputActive]
    );

    const onFocus = React.useCallback(
        (_e: React.FocusEvent<HTMLTextAreaElement>) => {
            setInputActive(true);
        },
        [setInputActive]
    );

    const onKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (isMobile && isPlayer()) return;
            submitOnEnter(e);
        },
        [isMobile, submitOnEnter]
    );

    React.useEffect(() => {
        if (!heightIsSet.current && value.length > 0 && isDefined(textareaRef.current)) {
            setProperHeight(textareaRef.current);
        }
    }, [setProperHeight, value]);

    const sectionStyle = useSectionStyle();
    const placeholder = getTextareaPlaceholder(allowAdd, needsToSignIn);
    const disabledTextarea = !allowAdd || needsToSignIn;
    return (
        <textarea
            disabled={disabledTextarea}
            id={id}
            className={classNames(`style-${sectionStyle}`)}
            defaultValue={value}
            data-testid="comment-input"
            name="comment-input"
            ref={textareaRef}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            tw="text-base text-text-contextual-dark placeholder:text-text-contextual-xpale w-full resize-none h-full min-h-[28px] max-h-32 transition-all max-w-full bg-transparent
            [&.style-dark, &.style-accent-bg]:text-text-dark
            [&.style-accent-bg]:placeholder:text-text-xpale
            [&.style-dark]:placeholder:text-text-xpale
            disabled:cursor-not-allowed"
        />
    );
};

function getAdjustedTextareaHeight(
    textarea: HTMLTextAreaElement,
    isMobile: boolean,
    textareaWidth: number,
    fontSize: number
) {
    const content = textarea.value;
    // Get textarea width and font size

    // Estimate number of characters per row
    const averageCharWidth = fontSize * 0.5; // Rough estimate of average character width
    const charsPerRow = Math.floor(textareaWidth / averageCharWidth);

    // Calculate the number of rows
    let rows = isMobile ? 4 : 2;
    const lines = content.split("\n");
    for (const line of lines) {
        rows += Math.ceil((line.length + 1) / charsPerRow);
    }
    const rowPadding = isMobile ? 5 : 3;
    const newHeight = rows * fontSize + rowPadding;
    return `${newHeight}px`;
}

function getTextareaPlaceholder(allowAdd: boolean, needsToSignIn: boolean) {
    if (needsToSignIn) {
        return "Sign in to add comments";
    }

    if (!allowAdd) {
        return "Comments are restricted";
    }

    return getLocalizedString("writeSomething", AppKind.Page);
}
