import type { StrokeIcons } from "@glide/plugins";
import { Modal } from "../windowed-modal-next";
import { GlideIcon } from "@glide/common";

interface Item {
    icon: StrokeIcons;
    text: string;
}

type UpgradeModalProps = {
    title: string;
    onClose: () => void;
    onConfirm: () => void;
    upgradeLabel: string;
    description: React.ReactNode;
    items: Item[];
    isLoadingUpgradeURL: boolean;
    className?: string;

    // TODO: if we want to migrate these per plan we can.
    // imageUrl?: string;
    // videoUrl?: string;
};

export const UpgradeModal: React.FC<React.PropsWithChildren<UpgradeModalProps>> = p => {
    const { title, description, items, upgradeLabel, onClose, onConfirm, isLoadingUpgradeURL, className } = p;
    return (
        <Modal.Container onClose={onClose}>
            <Modal.Window size={"md"} className={className} tw="min-w-fit">
                <Modal.TitleBar tw="pr-5 pl-7 items-center">
                    <Modal.Title tw="text-builder-3xl py-6 px-4 pb-0">{title}</Modal.Title>
                    <Modal.Close onClose={onClose} />
                </Modal.TitleBar>

                <Modal.Content tw="px-12 pb-6 text-builder-lg">
                    {description}
                    <p tw="py-5 text-builder-xs">INCLUDES</p>
                    <div tw="grid grid-cols-2 gap-2">
                        {items.map((i, k) => (
                            <div tw="flex items-center" key={k}>
                                <div tw="p-1.5 bg-aqua200 rounded-full mr-2 text-aqua500">
                                    <GlideIcon kind="stroke" icon={i.icon} iconSize={20} strokeWidth={1.5} />
                                </div>
                                <div tw="text-builder-lg">{i.text}</div>
                            </div>
                        ))}
                    </div>

                    <Modal.ButtonBar align="stretch" tw="gap-2 pt-6">
                        <Modal.Button
                            buttonType="primary"
                            size="lg"
                            label={upgradeLabel}
                            variant="default"
                            onClick={onConfirm}
                            isProcessing={isLoadingUpgradeURL}
                            tw="bg-accent"
                        />
                    </Modal.ButtonBar>
                    <p tw="pt-4 text-builder-sm text-center">
                        Need more?{" "}
                        <a href="https://www.glideapps.com/pricing" target="_blank" rel="noreferrer" tw="text-aqua400">
                            View all plans
                        </a>
                    </p>
                </Modal.Content>
            </Modal.Window>
        </Modal.Container>
    );
};
