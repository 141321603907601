// NOTE: This is part of the lower level of the New Computation Model, and
// should be kept isolated from non-trivial Glide dependencies.  In
// particular, it shouldn't have to know anything about Glide table/column
// types/schemas.

import {
    type PathForColumn,
    type Path,
    type RootPath,
    type ValueAndFormatPaths,
    makeKeyPath,
} from "@glide/computation-model-types";

export function makeValueAndFormatPaths(
    columnName: string,
    valueInfo: PathForColumn | undefined,
    formatInfo: PathForColumn | undefined,
    baseRootPath: RootPath | undefined
): ValueAndFormatPaths {
    const valueRootPath = valueInfo?.tablePath ?? baseRootPath;
    let valuePath: Path;
    if (valueInfo !== undefined) {
        valuePath = valueInfo.valuePath;
    } else {
        valuePath = makeKeyPath(columnName);
    }

    const formatRootPath = formatInfo?.tablePath;
    const formatPath = formatInfo?.valuePath;

    return [
        [valuePath, valueRootPath],
        [formatPath, formatRootPath],
    ];
}

export class PluginError extends Error {
    constructor(public readonly message: string, public readonly showInBuilder?: boolean) {
        super(message);
    }
}

export function getRootPathForPathForColumn(p: PathForColumn): RootPath {
    if (p.isGlobal) {
        return p.valuePath;
    } else {
        return p.tablePath;
    }
}
