import { sleep } from "@glideapps/ts-necessities";
import { ConditionVariable } from "./condition-variable";

export const nowFromHrtime = (): number => {
    return Number(process.hrtime.bigint() / BigInt(1000000));
};

export class QuotaGovernor {
    constructor(public readonly concurrency: number, public readonly periodMS: number) {}

    private ongoing: number = 0;
    private ongoingCV = new ConditionVariable();
    // If we were dealing with any more than 100 per period in terms
    // of concurrency, this would be best replaced with a binary heap.
    // For now an array scan is completely fine.
    private operationTimes: number[] = [];

    public async run<T>(fn: () => Promise<T>): Promise<T> {
        while (this.ongoing >= this.concurrency) {
            await this.ongoingCV.wait();
        }

        this.ongoing++;
        const rightNow = nowFromHrtime();
        const inspectTime = rightNow - this.periodMS;

        this.operationTimes.sort();
        while (this.operationTimes[0] !== undefined && this.operationTimes[0] < inspectTime) {
            this.operationTimes.shift();
        }

        const earliest = this.operationTimes[0];
        if (earliest !== undefined && earliest >= inspectTime) {
            // We have to shift early here so a concurrent execution
            // doesn't also consider this its own "earliest" time.
            //
            // Thankfully this is all synchronous from the condition variable
            // guard to this following sleep, so we can do this safely.
            this.operationTimes.shift();
            const targetTime = earliest + this.periodMS;
            let sleepTime = targetTime - nowFromHrtime();
            while (sleepTime > 0) {
                await sleep(sleepTime);
                sleepTime = targetTime - nowFromHrtime();
            }
        }

        try {
            return await fn();
        } finally {
            this.operationTimes.push(nowFromHrtime());
            this.ongoing--;
            this.ongoingCV.notifyAll();
        }
    }
}
