import { getLocalizedString } from "@glide/localization";
import { isLoadingValue } from "@glide/computation-model-types";
import type { GlideIconProps } from "@glide/plugins";
import {
    type ActionDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
    ArrayTransformKind,
    PropertyKind,
    getTransformsProperty,
} from "@glide/app-description";
import { type Formula, getTableColumnDisplayName, isSourceColumn } from "@glide/type-schema";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    type TransformsPropertyDescriptor,
    RequiredKind,
    makeInlineTemplatePropertyDescriptor,
    makeNumberPropertyDescriptor,
    resolveSourceColumn,
    type ActionAvailability,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { isArray } from "@glide/support";
import type {
    WireActionResult,
    WireValueGetterGeneric,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
    WireRowActionHydrationValueProvider,
} from "@glide/wire";
import { assert, defined, mapFilterUndefined, sleep } from "@glideapps/ts-necessities";
import type { StaticActionContext } from "../static-context";
import { decomposePredicateCombinationFormula } from "@glide/formula-specifications";
import { inflateNumberProperty, inflateStringProperty } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";

interface WaitActionDescriptionBase extends ActionDescription {
    readonly kind: ActionKind.Wait | ActionKind.WaitForCondition;
    readonly durationSeconds: PropertyDescription | undefined;
    readonly message: PropertyDescription | undefined;
}

interface WaitActionDescription extends WaitActionDescriptionBase {
    readonly kind: ActionKind.Wait;
}

interface WaitForConditionActionDescription extends WaitActionDescriptionBase {
    readonly kind: ActionKind.WaitForCondition;
    readonly waitCondition: PropertyDescription | undefined;
    readonly failMessage: PropertyDescription | undefined;
}

const defaultDurationSeconds = 3;

interface BaseGetters {
    readonly durationSecondsGetter: WireValueGetterGeneric<number | undefined>;
    readonly messageGetter: WireValueGetterGeneric<string>;
}

function inflateBaseGetters(ib: WireActionInflationBackend, desc: WaitActionDescriptionBase): BaseGetters {
    const [durationSecondsGetter] = inflateNumberProperty(ib, desc.durationSeconds);
    const [messageGetter] = inflateStringProperty(ib, desc.message, true);
    return { durationSecondsGetter, messageGetter };
}

function hydrateBase(
    vp: WireRowActionHydrationValueProvider,
    getters: BaseGetters,
    skipLoading: boolean,
    appKind: AppKind,
    arb: WireActionResultBuilder
): [durationSeconds: number, message: string] | WireActionResult {
    const maybeDurationSeconds = getters.durationSecondsGetter(vp);
    if (isLoadingValue(maybeDurationSeconds)) {
        if (!skipLoading) return arb.loading("Duration");
    }
    const durationSeconds = maybeDurationSeconds ?? defaultDurationSeconds;

    const maybeMessage = getters.messageGetter(vp);
    if (isLoadingValue(maybeMessage)) {
        if (!skipLoading) return arb.loading("Message");
    }
    const message = maybeMessage ?? getLocalizedString("loading", appKind);

    return [durationSeconds, message];
}

function getPredicateFormula(desc: WaitForConditionActionDescription) {
    const transforms = getTransformsProperty(desc.waitCondition);
    if (transforms === undefined) return undefined;
    const filters = mapFilterUndefined(transforms, t =>
        t.kind === ArrayTransformKind.Filter && t.isActive !== false ? t : undefined
    );
    if (filters.length !== 1) return undefined;
    return filters[0].predicate;
}

function getPredicates(predicateFormula: Formula | undefined) {
    if (predicateFormula === undefined) return [];

    const decomposed = decomposePredicateCombinationFormula(predicateFormula);
    return decomposed?.spec.predicates ?? [];
}

function makeTokenizedDescription(tokens: readonly DescriptionToken[]): readonly DescriptionToken[] {
    return [
        {
            kind: "string",
            value: "for ",
        },
        ...tokens,
    ];
}

abstract class WaitActionHandlerBase<TDesc extends WaitActionDescriptionBase> extends BaseActionHandler<TDesc> {
    constructor(
        public readonly kind: ActionKind.Wait | ActionKind.WaitForCondition,
        private readonly _name: string,
        private readonly _durationLabel: string
    ) {
        super();
    }

    public get iconName(): GlideIconProps {
        return {
            icon: this.kind === ActionKind.Wait ? "st-clock-wait" : "st-clock-snooze",
            kind: "stroke" as const,
            strokeFgColor: ICON_PALE,
        };
    }

    public get name(): string {
        return this._name;
    }

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getIsIdempotent(): boolean {
        return true;
    }

    protected abstract makeProperties(
        durationProperty: PropertyDescriptor,
        messageProperty: PropertyDescriptor,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly PropertyDescriptor[];

    public getDescriptor(
        _desc: TDesc | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const durationProperty = makeNumberPropertyDescriptor(
            "durationSeconds",
            this._durationLabel,
            `${defaultDurationSeconds} seconds`,
            RequiredKind.Required,
            defaultDurationSeconds,
            mutatingScreenKind,
            { preferredType: "number", searchable: false, applyFormat: false, emptyByDefault: true }
        );
        const messageProperty = makeInlineTemplatePropertyDescriptor(
            "message",
            "Message",
            "Enter a message",
            false,
            "withLabel",
            mutatingScreenKind,
            {
                searchable: false,
                emptyByDefault: true,
            }
        );
        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 14,
            isLegacy: ccc.appKind !== AppKind.Page,
            needsScreenContext: false,
            properties: this.makeProperties(durationProperty, messageProperty, mutatingScreenKind),
        };
    }
}

export class WaitActionHandler extends WaitActionHandlerBase<WaitActionDescription> {
    constructor() {
        super(ActionKind.Wait, "Wait", "Seconds");
    }

    protected makeProperties(
        durationProperty: PropertyDescriptor,
        messageProperty: PropertyDescriptor
    ): readonly PropertyDescriptor[] {
        return [durationProperty, messageProperty];
    }

    public getTokenizedDescription(
        desc: WaitActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        return makeTokenizedDescription([
            tokenForProperty(desc.durationSeconds, env) ?? {
                kind: "string",
                value: defaultDurationSeconds.toString(),
            },
            {
                kind: "string",
                value: " seconds",
            },
        ]);
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: WaitActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { appKind },
        } = ib;
        const getters = inflateBaseGetters(ib, desc);
        return (vp, skipLoading) => {
            const hydrated = hydrateBase(vp, getters, skipLoading, appKind, arb);
            if (!isArray(hydrated)) return hydrated;
            const [durationSeconds, message] = hydrated;
            return async ab => {
                return await ab.withBusy(message, async () => {
                    await sleep(durationSeconds * 1000);
                    return arb.addData({ durationSeconds, message }).success();
                });
            };
        };
    }
}

export class WaitForConditionActionHandler extends WaitActionHandlerBase<WaitForConditionActionDescription> {
    constructor() {
        super(ActionKind.WaitForCondition, "Wait for condition", "Timeout seconds");
    }

    public getIsIdempotent(): boolean {
        return true;
    }

    protected makeProperties(
        durationProperty: PropertyDescriptor,
        messageProperty: PropertyDescriptor,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly PropertyDescriptor[] {
        const filterProperty: TransformsPropertyDescriptor = {
            kind: PropertyKind.Transforms,
            property: { name: "waitCondition" },
            label: "Condition",
            section: { name: "Condition", order: 0 },
            addText: "Add Condition",
            description: "Wait for a specific condition to be true.",
            allowContextTable: true,
            allowLHSUserProfileColumns: true,
            forFilteringRows: false,
            withContainingScreen: false,
            allowSpecialValues: false,
        };
        const failMessageProperty = makeInlineTemplatePropertyDescriptor(
            "failMessage",
            "Failure message",
            "Enter a message",
            false,
            "withLabel",
            mutatingScreenKind,
            {
                searchable: false,
                emptyByDefault: true,
            }
        );
        return [filterProperty, durationProperty, messageProperty, failMessageProperty];
    }

    public getTokenizedDescription(
        desc: WaitForConditionActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const predicates = getPredicates(getPredicateFormula(desc));
        if (predicates.length > 0) {
            if (predicates.length === 1) {
                const column = predicates[0].column;
                // FIXME: Currently we don't support special values in wait action.
                // We should remove this once we do, if we do.
                assert(isSourceColumn(column));

                // FIXME: it would be nice to have `actionNodesInScope` here.
                const resolved = resolveSourceColumn(env.context, column, env.tables?.input, undefined, undefined);
                if (resolved?.tableAndColumn?.column !== undefined) {
                    return makeTokenizedDescription([
                        {
                            kind: "column",
                            value: getTableColumnDisplayName(resolved.tableAndColumn.column),
                        },
                    ]);
                }
            } else {
                return makeTokenizedDescription([
                    {
                        kind: "string",
                        value: `${predicates.length} conditions`,
                    },
                ]);
            }
        }
        return makeTokenizedDescription([
            {
                kind: "string",
                value: " up to ",
            },
            tokenForProperty(desc.durationSeconds, env) ?? {
                kind: "string",
                value: defaultDurationSeconds.toString(),
            },
            {
                kind: "string",
                value: " seconds",
            },
        ]);
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: WaitForConditionActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { appKind },
        } = ib;
        const getters = inflateBaseGetters(ib, desc);
        const formula = getPredicateFormula(desc);
        // We have to use ##shortCircuitPredicateEvaluation here.
        const [predicate] =
            getPredicates(formula).length > 0 ? ib.inflatePredicate(defined(formula), false, false) ?? [] : [];
        const [failMessageGetter] = inflateStringProperty(ib, desc.failMessage, true);

        return (vp, skipLoading) => {
            const hydrated = hydrateBase(vp, getters, skipLoading, appKind, arbBase);
            if (!isArray(hydrated)) return hydrated;
            const [durationSeconds, message] = hydrated;

            const maybeFailMessage = failMessageGetter(vp);
            if (isLoadingValue(maybeFailMessage)) {
                if (!skipLoading) return arbBase.loading("Failure message");
            }
            const failMessage = maybeFailMessage ?? getLocalizedString("error", appKind);

            const arb = arbBase.addData({ durationSeconds, message, failMessage });

            if (predicate === undefined || predicate(vp) === true) {
                return arb.nothingToDo("Condition already met");
            }

            return async ab => {
                const success = await ab.withBusy(message, async () => {
                    const subVP = ab.makeBackendForSubAction();
                    return await subVP.listenForChanges(durationSeconds * 1000, () => predicate(subVP) === true);
                });
                if (success) {
                    return arb.success();
                } else {
                    ab.actionCallbacks.showToast(false, failMessage);
                    // The reason we treat this as a permanent error is that
                    // the user specified a timeout, and we waited for that
                    // long.
                    return arb.error(
                        true,
                        "Wait for condition step timed out. All steps after condition were not processed."
                    );
                }
            };
        };
    }
}
