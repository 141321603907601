import type { MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import { type Row, Table, isLoadingValue } from "@glide/computation-model-types";
import { asMaybeDate } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ArrayContentDescription,
    type ArrayScreenDescription,
    type ArrayTransform,
    type ComponentDescription,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    type ScreenDescriptionKind,
    ArrayScreenFormat,
    PropertyKind,
    getColumnProperty,
} from "@glide/app-description";
import { type InputOutputTables, getScreenComponentsArray } from "@glide/common-core/dist/js/description";
import { getTableColumn, getTableName, type SchemaInspector } from "@glide/type-schema";
import { GlideDateTime } from "@glide/data-types";
import type { WireAppTinderCard, WireAppTinderComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type InlineListComponentDescription,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    type PropertyTableGetter,
    PropertySection,
    getPrimitiveNonHiddenColumnsSpec,
} from "@glide/function-utils";
import {
    type InflatedColumn,
    type WireTableComponentHydratorConstructor,
    type WireTableTransformValueProvider,
    type WireAction,
    WireActionResult,
    ValueChangeSource,
    WireComponentKind,
    type WireInflationBackend,
} from "@glide/wire";
import { assert, definedMap } from "@glideapps/ts-necessities";
import { getDefaultPrimitiveActionKinds } from "../actions";
import type { ScreenContext } from "../components/component-handler";
import { getActionsForArrayContent } from "../components/component-utils";
import { makeDefaultClassComponents } from "../pages";
import {
    type WireStringGetter,
    getAppArrayScreenEmptyMessage,
    hydrateSubAction,
    inflateActions,
    inflateComponent,
    makeSimpleWireTableComponentHydratorConstructor,
    sortItems,
    spreadComponentID,
} from "../wire/utils";
import { ArrayScreenHandlerBase } from "./array-screen";

interface TinderArrayContentDescription extends ArrayContentDescription {
    readonly swipedTimestamp: PropertyDescription;

    readonly leftActions: LegacyPropertyDescription | undefined;

    readonly swipeCondition: readonly ArrayTransform[] | undefined;
}

interface TinderArrayScreenDescription extends ArrayScreenDescription, TinderArrayContentDescription {
    readonly kind: ScreenDescriptionKind.Array;
    readonly format: ArrayScreenFormat.Tinder;
}

interface Item {
    readonly row: Row;
    readonly timestamp: GlideDateTime;
}

const defaultTimestamp = GlideDateTime.fromTimeZoneAgnosticDate(new Date(2018, 8, 5));

function deconstructItems(
    ttvp: WireTableTransformValueProvider,
    rows: readonly Row[],
    inflatedTimestamp: InflatedColumn | undefined
): Item[] {
    return rows.map(r => {
        let timestamp = inflatedTimestamp?.getter(r, ttvp);
        if (isLoadingValue(timestamp)) {
            timestamp = defaultTimestamp;
        } else {
            timestamp = asMaybeDate(timestamp) ?? defaultTimestamp;
        }
        return { row: r, timestamp };
    });
}

export class TinderArrayScreenHandler extends ArrayScreenHandlerBase<
    TinderArrayContentDescription,
    TinderArrayScreenDescription
> {
    constructor() {
        super(ArrayScreenFormat.Tinder, "Swipe", "tinder");
    }

    public get hasComponents(): boolean {
        return true;
    }

    public get supportsInlineList(): boolean {
        return false;
    }

    protected get canReverse(): boolean {
        return false;
    }

    public getNeedsOrder(): boolean {
        return false;
    }

    public getBasicSearchProperties(_desc: TinderArrayContentDescription): readonly string[] {
        // FIXME: Get search properties from components.  Right now we can't
        // search Swipe screens, but maybe we will at some point.
        return [];
    }

    public getContentPropertyDescriptors<T extends TinderArrayContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined,
        screenContext?: ScreenContext,
        appEnvironment?: MinimalAppEnvironment
    ): readonly PropertyDescriptor[] {
        const descriptors: PropertyDescriptor[] = [
            ...super.getContentPropertyDescriptors(
                getPropertyTable,
                insideInlineList,
                containingScreenTables,
                desc,
                ccc,
                mutatingScreenKind,
                isDefaultArrayScreen,
                withTransforms,
                forEasyTabConfiguration,
                isFirstComponent,
                screenContext,
                appEnvironment
            ),
            {
                kind: PropertyKind.Column,
                property: { name: "swipedTimestamp" },
                label: "Save last swipe",
                emptyWarningText: "Assign this to a column to enable swiping.",
                required: false,
                editable: true,
                isEditedInApp: true,
                searchable: false,
                // This is so that we don't overwrite random columns when somebody switches to Swipe
                emptyByDefault: true,
                getIndirectTable: getPropertyTable,
                columnFilter: getPrimitiveNonHiddenColumnsSpec,
                preferredNames: ["swiped"],
                preferredType: "date-time",
                section: PropertySection.Content,
            },
            {
                kind: PropertyKind.Transforms,
                property: { name: "swipeCondition" },
                // FIXME: We don't actually use this label
                label: "Swipe condition",
                getIndirectTable: getPropertyTable,
                section: PropertySection.SwipeCondition,
                addText: "Add condition",
                description: "Only allow swiping when a condition is met.",
                allowContextTable: true,
                allowLHSUserProfileColumns: false,
                forFilteringRows: false,
                withContainingScreen: false,
                allowSpecialValues: false,
            },
        ];

        return descriptors;
    }

    public getActionDescriptors<T extends TinderArrayContentDescription>(
        _desc: T,
        _containingScreenTables: InputOutputTables | undefined,
        getIndirectTable: PropertyTableGetter | undefined,
        schema: SchemaInspector | undefined
    ): readonly ActionPropertyDescriptor[] {
        const kinds = getDefaultPrimitiveActionKinds(schema, undefined);
        return [
            {
                kind: PropertyKind.Action,
                property: { name: "leftActions" },
                label: "Left action",
                required: false,
                kinds,
                defaultAction: false,
                getIndirectTable,
                section: PropertySection.LeftAction,
            },
            {
                kind: PropertyKind.Action,
                property: { name: "actions" },
                label: "Right action",
                required: false,
                kinds,
                defaultAction: true,
                getIndirectTable,
                section: PropertySection.RightAction,
            },
        ];
    }

    public defaultDescription(
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        components: readonly ComponentDescription[],
        forNewApp: boolean
    ): TinderArrayScreenDescription | undefined {
        let desc = super.defaultDescription(tables, ccc, components, forNewApp);
        if (desc === undefined) return undefined;

        if ((desc.components?.length ?? 0) === 0) {
            desc = { ...desc, components: makeDefaultClassComponents(ccc, tables.input, true) };
        }

        return desc;
    }

    public adjustContentDescriptionAfterUpdate(
        desc: TinderArrayContentDescription,
        updates: Partial<TinderArrayContentDescription & InlineListComponentDescription> | undefined,
        tables: InputOutputTables,
        ccc: InteractiveComponentConfiguratorContext,
        getPropertyTable: PropertyTableGetter | undefined
    ): TinderArrayContentDescription {
        desc = super.adjustContentDescriptionAfterUpdate(desc, updates, tables, ccc, getPropertyTable);
        if ((desc.components?.length ?? 0) === 0 && updates?.components === undefined) {
            desc = { ...desc, components: makeDefaultClassComponents(ccc, tables.input, true) };
        }
        return desc;
    }

    public inflateContent<T extends TinderArrayContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        _captionGetter: WireStringGetter | undefined,
        _containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        const {
            forBuilder,
            adc: { appKind },
        } = ib;
        const inputTable = ib.tables.input;
        const tableName = getTableName(inputTable);

        const components = getScreenComponentsArray(desc);
        const componentIB = ib.makeInflationBackendForTables(ib.tables, ib.mutatingScreenKind);
        const hydrators = components.map(c => inflateComponent(componentIB, c, false));

        const swipedTimestampColumnName = getColumnProperty(desc.swipedTimestamp);
        const swipedTimestampColumn = definedMap(swipedTimestampColumnName, n => getTableColumn(inputTable, n));
        const inflatedTimestamp = definedMap(swipedTimestampColumnName, n =>
            ib.getValueGetterForColumnInRow(n, false, false)
        );

        const { leftActions, actions: rightActions } = getActionsForArrayContent(this, ib.tables, desc, ib.adc);
        const leftActionHydrator = inflateActions(ib, leftActions);
        const rightActionHydrator = inflateActions(ib, rightActions);

        const [conditionHydrator] = ib.inflateFilters(desc.swipeCondition ?? [], false);

        return makeSimpleWireTableComponentHydratorConstructor(
            ib,
            (thb, _rhb, searchActive, _dynamicFilter, _rowBackends, builder) => {
                const rows = thb.tableScreenContext.asArray();
                if (rows === undefined) return undefined;

                const ttvp = thb.makeTableTransformValueProvider(tableName);
                inflatedTimestamp?.subscribe(ttvp);
                const items = deconstructItems(ttvp, rows, inflatedTimestamp);

                const sortedItems = sortItems(items, i => i.timestamp);
                builder?.overrideTableData(componentID, new Table(sortedItems.map(i => i.row)), inputTable);

                const topRow = sortedItems[0]?.row;
                const bottomRow = sortedItems[1]?.row;

                let allValid: boolean;
                let haveValues: boolean | undefined;

                let topCard: WireAppTinderCard | undefined;
                if (topRow !== undefined) {
                    const {
                        components: topComponents,
                        allValid: topAllValid,
                        haveValues: topHaveValues,
                    } = thb.hydrateSubComponents("top", topRow, hydrators, c => {
                        assert(c.kind === WireComponentKind.AppTinder);
                        return (c as WireAppTinderComponent).top?.components ?? [];
                    });
                    topCard = {
                        components: topComponents,
                        key: topRow.$rowID,
                    };
                    allValid = topAllValid;
                    haveValues = topHaveValues;
                } else {
                    allValid = true;
                }

                let bottomCard: WireAppTinderCard | undefined;
                if (bottomRow !== undefined) {
                    const { components: bottomComponents } = thb.hydrateSubComponents(
                        "bottom",
                        bottomRow,
                        hydrators,
                        c => {
                            assert(c.kind === WireComponentKind.AppTinder);
                            return (c as WireAppTinderComponent).bottom?.components ?? [];
                        }
                    );
                    bottomCard = {
                        components: bottomComponents,
                        key: bottomRow.$rowID,
                    };
                } else {
                    // If we don't have a bottom card, we repeat the top card.
                    bottomCard = topCard;
                }

                let swipeLeftAction: WireAction | undefined;
                let swipeRightAction: WireAction | undefined;

                if (topRow !== undefined && swipedTimestampColumn !== undefined) {
                    const rhb = thb.makeHydrationBackendForRow(topRow);
                    const canSwipe = conditionHydrator(rhb);
                    const lastSwipedToken =
                        canSwipe === true
                            ? rhb.registerOnValueChange("lastSwiped", swipedTimestampColumn.name)
                            : undefined;

                    if (lastSwipedToken !== false && lastSwipedToken !== undefined) {
                        swipeLeftAction = {
                            token: rhb.registerAction("swipeLeft", async ab => {
                                ab.valueChanged(lastSwipedToken, GlideDateTime.now(), ValueChangeSource.User);

                                if (leftActionHydrator instanceof WireActionResult) {
                                    return leftActionHydrator;
                                } else {
                                    const runner = await hydrateSubAction(ab, leftActionHydrator);
                                    return await ab.invoke("swipe left", runner, false);
                                }
                            }),
                        };
                        swipeRightAction = {
                            token: rhb.registerAction("swipeRight", async ab => {
                                ab.valueChanged(lastSwipedToken, GlideDateTime.now(), ValueChangeSource.User);

                                if (rightActionHydrator instanceof WireActionResult) {
                                    return rightActionHydrator;
                                } else {
                                    const runner = await hydrateSubAction(ab, rightActionHydrator);
                                    return await ab.invoke("swipe right", runner, false);
                                }
                            }),
                        };
                    }
                }

                const component: WireAppTinderComponent = {
                    kind: WireComponentKind.AppTinder,
                    ...spreadComponentID(componentID, forBuilder),
                    top: topCard,
                    bottom: bottomCard,
                    swipeLeftAction: swipeLeftAction,
                    swipeRightAction: swipeRightAction,
                    emptyMessage: getAppArrayScreenEmptyMessage(searchActive, appKind),
                };
                return {
                    component,
                    isValid: allValid,
                    hasValue: haveValues,
                };
            }
        );
    }

    public convertArrayScreenToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }

    public convertInlineToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }
}
