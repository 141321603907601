import { GlideIcon, browserOnPhone } from "@glide/common";
import { isSmallScreen, useIsHoverable, useResponsiveSizeClass, css } from "@glide/common-components";
import { isDefined } from "@glide/support";
import { UIBackgroundStyle, UIButtonAppearance, UIStyleVariant } from "@glide/wire";
import classNames from "classnames";
import type { MouseEventHandler } from "react";
import tw from "twin.macro";

import type { ExtractedActions } from "../../wire-lib";
import { breakoutActions, makeActionSpreadProps } from "../../wire-lib";
import { WireButton } from "../wire-button/wire-button";
import { WireMenuButton } from "../wire-menu-button/wire-menu-button";

export interface TablePropertiesBindState {
    title: boolean;
    subtitle: boolean;
    emphasis: boolean;
}

interface TableActionsButtonProps {
    menuActions: ExtractedActions;
    hasClickAction: boolean;
    onItemClick: MouseEventHandler<HTMLElement>;
}

/**
 * An empty actions array makes this invisible, but we still want take that space.
 * Is this a hack? Yes. But This way we're _sure_ the space in every row is the same
 * even if we change the style of the visible button.
 */
export const TableActionsButton: React.VFC<TableActionsButtonProps> = p => {
    const { menuActions, hasClickAction, onItemClick } = p;

    const isVisible = menuActions.length > 0 || hasClickAction;

    const [primary, secondary, ...extra] = breakoutActions(menuActions, 2);

    const sizeClass = useResponsiveSizeClass();
    const isHoverable = useIsHoverable();

    const showActionsInExtraMenu = isSmallScreen(sizeClass) || !isHoverable;
    const itemsInExtraMenu = showActionsInExtraMenu ? menuActions.filter(isDefined) : extra;

    const isExtraVisible = itemsInExtraMenu.length > 0;

    const primaryAndSecondaryButtons = (
        <div
            data-testid="card-primary-and-secondary"
            tw="flex absolute right-0 transform transition-all duration-75 invisible opacity-0
                group-page-hover:(visible opacity-100) focus-within:(visible opacity-100)"
            css={css`
                ${extra.length > 0 ? tw`-translate-x-10` : ""}

                .group:focus-visible & {
                    ${tw`visible opacity-100`}
                }
            `}>
            {primary !== undefined && (
                <WireButton
                    tw="hidden
                        gp-md:flex no-hover:hidden"
                    data-testid="card-primary-button"
                    appearance={UIButtonAppearance.Filled}
                    {...makeActionSpreadProps(primary)}
                />
            )}
            {secondary !== undefined && (
                <WireButton
                    tw="ml-2 hidden
                        gp-md:flex no-hover:hidden"
                    appearance={UIButtonAppearance.Bordered}
                    {...makeActionSpreadProps(secondary)}
                />
            )}
        </div>
    );

    const extraActionsButton = (
        <div
            data-testid="card-extra-actions"
            tw="hidden right-0 transform"
            className={isExtraVisible ? "extra-visible" : ""}
            css={css`
                &.extra-visible {
                    ${tw`block gp-md:translate-x-2`}
                }
            `}>
            <WireMenuButton
                css={css`
                    /* Fix(mauri): This doesn't belong here, remove it once buttons support dark theme. */
                    .${UIBackgroundStyle.Image} .${UIStyleVariant.Minimal} &,
                    .${UIBackgroundStyle.Accent} .${UIStyleVariant.Minimal} &,
                    .${UIBackgroundStyle.Dark} .${UIStyleVariant.Minimal} & {
                        ${tw`text-text-contextual-dark! focus-visible:after:(ring-text-contextual-dark)
                          page-hover:after:(bg-bg-hovered) active:after:(bg-bg-hovered)`}
                    }
                `}
                tw="ml-2"
                appearance={UIButtonAppearance.Floating}
                menuItems={itemsInExtraMenu}
            />
        </div>
    );

    const clickIcon = hasClickAction && (
        <button onClick={onItemClick} tw="self-center gp-md:hidden">
            <GlideIcon
                className="arrow"
                iconSize={20}
                css={css`
                    /* Fix(mauri): This doesn't belong here, remove it once buttons support dark theme. */
                    .${UIBackgroundStyle.Accent} .${UIStyleVariant.Minimal} &,
                    .${UIBackgroundStyle.Dark} .${UIStyleVariant.Minimal} & {
                        ${tw`text-text-contextual-dark`}
                    }
                `}
                tw="text-text-pale self-center"
                icon="st-chevron-right"
                kind="stroke"
            />
        </button>
    );

    return (
        <div
            tw="flex transition-opacity items-center relative justify-self-end
                gp-md:w-10"
            className={classNames(browserOnPhone && "mobile", !isVisible && "invisible")}
            css={css`
                ${tw`row-start-1 [grid-row-end:-1] [grid-column-end:-1]
                  gp-md:([grid-row-start:unset] [grid-row-end:unset])`}
                &.mobile {
                    opacity: 1;
                }

                &.invisible {
                    visibility: hidden;
                    max-height: 0px;
                }
            `}
            onKeyDown={e => {
                if (e.key === "Enter") e.stopPropagation();
            }}
            onClick={e => e.stopPropagation()}>
            {primaryAndSecondaryButtons}
            {extraActionsButton}
            {!isExtraVisible ? clickIcon : null}
        </div>
    );
};

interface TextWithEllipsisProps {
    clickable?: boolean;
    className?: string;
    testId?: string;
}

export const TextWithEllipsis: React.FC<React.PropsWithChildren<TextWithEllipsisProps>> = p => {
    const { clickable = false, children, className, testId } = p;

    return (
        <p
            data-testid={testId}
            tw="truncate select-text"
            className={classNames(clickable ? "clickable" : "not-clickable", className)}
            css={css`
                &.clickable {
                    ${tw`cursor-pointer`}
                }

                &.not-clickable {
                    ${tw`cursor-text`}
                }
            `}>
            {children}
        </p>
    );
};
