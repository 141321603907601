import { GlideIcon } from "@glide/common";
import { isEmptyOrUndefinedish } from "@glide/support";
import { useBuilderTheme } from "../../hooks/use-builder-theme";
import classNames from "classnames";
import { marked } from "marked";
import { useHover, useLayer } from "react-laag";
import type { Options } from "react-laag/dist/types";
import { css } from "styled-components";
// import tw from "twin.macro";
const renderer = new marked.Renderer();
renderer.link = (href, title, text) =>
    `<a target="_blank" href="${href}" ${isEmptyOrUndefinedish(title) ? "" : `title="${title}"`}>${text}</a>`;

type DisplayProps = {
    content: string;
};

type MDTooltipProps = {
    placement?: Options["placement"];
} & DisplayProps;

const MDTooltipContent: React.VFC<MDTooltipProps> = props => {
    return (
        <div tw="rounded-lg p-4 bg-bg-front [width:272px] text-text-base shadow-xl-dark">
            <p
                tw="text-builder-sm"
                css={css`
                    a {
                        color: ${p => p.theme.blue};
                    }
                `}
                dangerouslySetInnerHTML={{ __html: marked(props.content, { renderer }) }}
            />
        </div>
    );
};

export const MDTooltip: React.VFC<MDTooltipProps> = props => {
    const { placement = "top-center" } = props;

    const [isHovered, hoverProps] = useHover({ delayEnter: 100, delayLeave: 100 });
    const theme = useBuilderTheme();

    const { layerProps, renderLayer, triggerProps } = useLayer({
        isOpen: isHovered,
        container: "portal",
        auto: true,
        containerOffset: 14,
        triggerOffset: 4,
        placement,
    });

    return (
        <span {...hoverProps} tw="flex pl-1 items-center">
            <button
                {...triggerProps}
                css={css`
                    /*  transform: translateY(-1px); // potential fix to optical illusion */
                    &.active {
                        color: ${theme.n600A};
                    }
                `}
                className={classNames({ active: isHovered })}
                tw="hover:text-n600A text-n500A transition-colors">
                <GlideIcon iconSize={16} kind="monotone" icon="mt-info-circle-filled" />
            </button>

            {isHovered &&
                renderLayer(
                    <div
                        className="rich-tooltip-container click-outside-ignore"
                        onClick={e => e.stopPropagation()}
                        {...layerProps}>
                        <MDTooltipContent {...props} />
                    </div>
                )}
        </span>
    );
};
