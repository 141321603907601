import type { DropImageHandler } from "@glide/common-core/dist/js/components/portable-renderers";
import type { DropData } from "@glide/common-core/dist/js/drop-controller";
import { getFeatureSettingProbability } from "@glide/common-core/dist/js/feature-settings";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import { maybeFrontendTrace } from "@glide/common-core/dist/js/tracing";
import { getEmoji, logInfo } from "@glide/support";
import { GeneratedImage } from "@glide/generated-image";
import React from "react";

import { Droppable } from "../droppable/droppable";
import { AnimatedImage, EmojiImg, EmojiImgWrapper } from "./img-style";

interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    className?: string;
    src?: string;
    alt?: string;
    alternate?: React.ReactNode;
    onDropImage?: DropImageHandler;
    altBehavior?: "loading" | "error" | "both";
    fadeIn?: boolean;
    isPages?: boolean;
    "data-testid"?: string;
}
interface State {
    loaded: boolean;
    error: boolean;
    src?: string;
    lastSrc?: string;
    dragOver: boolean;
    transitioning: boolean;
    isPages: boolean;
}

const cache: any = {};

export class Img extends Droppable<Props, State> {
    private _imgRef = React.createRef<HTMLImageElement>();

    public state: State = {
        loaded: false,
        error: false,
        dragOver: false,
        transitioning: false,
        lastSrc: "",
        isPages: false,
    };

    private image: HTMLImageElement | undefined;

    public constructor(props: Props) {
        super(props);

        this.state.src = props.src;
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        this.state.isPages = props.isPages || false;

        if (this.state.src !== undefined && cache[this.state.src]) {
            this.state.loaded = true;
        }
    }

    public async componentDidMount() {
        if (!this.state.loaded) {
            this.loadImage().catch(r => logInfo(r));
        }
    }

    public componentWillUnmount() {
        this.unloadImage();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.src === nextProps.src) return;
        const nextSrc = nextProps.src;

        if (this.state.loaded && nextProps.src !== undefined && cache[nextProps.src]) {
            this.setState({ src: nextSrc });
            return;
        }

        this.changeImage(nextSrc);
    }

    protected onDataDropped(dropData: DropData): void {
        if (isPlayer()) return;
        switch (dropData.kind) {
            case "uri":
                this.setState({ src: dropData.uri, loaded: true, error: false });
                break;
            case "file":
                const fr = new FileReader();
                fr.onload = (_e: Event) => {
                    const newUrl = fr.result as string;
                    this.setState({ src: newUrl, loaded: true, error: false });
                };

                fr.readAsDataURL(dropData.file);
                break;
        }

        super.onDataDropped(dropData);
    }

    private unloadImage() {
        if (this.image === undefined) return;

        try {
            delete (this.image as any).onerror;
            delete (this.image as any).onload;
            delete (this.image as any).src;
        } catch {
            // do nothing
        }

        delete this.image;
        this.image = undefined;
    }

    private onError = () => {
        const src = this.state.src;
        if (src !== undefined) {
            cache[src] = false;
        }
        if (this.image !== undefined) {
            this.setState({ error: true });
        }
    };

    private onLoad = () => {
        const src = this.state.src;
        if (src !== undefined) {
            cache[src] = true;
        }
        if (this.image !== undefined) {
            this.setState({ loaded: true, error: false });
        }
    };

    private changeImage(nextSrc: string | undefined) {
        if (this.state.isPages) {
            this.setState({ transitioning: true, lastSrc: this.state.src });
        }

        this.unloadImage();

        this.setState({ loaded: false, error: false, src: nextSrc }, async () =>
            this.loadImage()
                .then(() => this.setState({ transitioning: false }))
                .catch(r => logInfo(r))
        );
    }

    private async loadImage() {
        const src = this.state.src;
        if (src === undefined || src.startsWith("glide:")) return;

        // This is treated as an error on purpose
        if (src.trim() === "") {
            this.onError();
            return;
        }

        this.image = new Image();
        if (src.startsWith("https://lh3.googleusercontent.com")) {
            this.image.referrerPolicy = "no-referrer";
        }
        const i = this.image;
        i.src = src;
        if (i.decode === undefined) {
            i.onload = this.onLoad;
            i.onerror = this.onError;
        } else {
            try {
                await maybeFrontendTrace(
                    "imageLoad",
                    undefined,
                    getFeatureSettingProbability("traceImageLoadProbability"),
                    async fields => {
                        await i.decode();
                        fields.width = i.naturalWidth;
                        fields.height = i.naturalHeight;
                    }
                );
                // `loadImage` is reentrant, so this might not be true anymore
                if (i === this.image) {
                    this.onLoad();
                }
            } catch (err: unknown) {
                if (src === this.state.src) {
                    // Fun story, safari isn't spec compliant. I know big shocker! But if you read:
                    // https://html.spec.whatwg.org/multipage/embedded-content.html#dom-img-decode
                    // You will find that a browser should throw EncodingError if it can't decode an
                    // an image. Unfortunately Safari just does this any time it sees an SVG for...
                    // reasons? Anyway it can load the SVG, it's just a dumb browser soooooo...
                    if (src.includes(".svg") && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                        this.onLoad();
                    } else {
                        this.onError();
                    }
                }
            }
        }
    }

    public render(): React.ReactNode {
        const { className, altBehavior, src: propSrc, alt, onDropImage, fadeIn, isPages: _, ...rest } = this.props;
        const { src, lastSrc, transitioning, loaded, error } = this.state;

        const droppableProps = this.getDroppableProps();
        let style = {
            ...rest.style,
            ...this.getDroppableStyle(),
        };
        style.display = "block";

        // This is purely here to get rid of a react warning because of a nasty
        // interaction between TS and styled components.
        const { isOverlayOpened, ...realRest } = rest as any;

        const emoji = propSrc === undefined ? undefined : getEmoji(propSrc);
        if (emoji !== undefined) {
            return (
                <EmojiImgWrapper {...droppableProps} style={style} className={className} {...realRest}>
                    <EmojiImg>{emoji}</EmojiImg>
                </EmojiImgWrapper>
            );
        }

        if (propSrc?.startsWith("glide:")) {
            const [mode, hash] = propSrc.substr("glide:".length).split(",", 2);
            return <GeneratedImage hash={hash} mode={mode} className={className} {...droppableProps} style={style} />;
        }

        if (transitioning) {
            return (
                <AnimatedImage
                    {...droppableProps}
                    style={style}
                    src={lastSrc}
                    alt={`Loading: ${alt}`}
                    className={className}
                    {...realRest}
                    fadeIn={fadeIn}
                    ref={this._imgRef}
                />
            );
        }

        if (loaded && !error) {
            style.backgroundColor = "transparent";
            return (
                <AnimatedImage
                    {...droppableProps}
                    style={style}
                    src={src}
                    alt={alt}
                    className={className}
                    {...realRest}
                    fadeIn={fadeIn}
                    ref={this._imgRef}
                />
            );
        }

        const alternate = this.props.alternate;
        // const showAlt = alternate !== undefined && this.state.error;
        let showAlt: boolean = (src ?? "").trim().length === 0;
        if (alternate !== undefined && !showAlt) {
            switch (altBehavior ?? "error") {
                case "error":
                    showAlt = this.state.error;
                    break;
                case "loading":
                    showAlt = !this.state.error;
                    break;
                case "both":
                    showAlt = true;
                    break;
            }
        }

        if (showAlt && this.props.alternate !== undefined) {
            style = {
                ...style,
                backgroundColor: "transparent",
            };
        }

        return (
            <div {...droppableProps} className={className} style={style} {...realRest}>
                {showAlt && this.props.alternate}
            </div>
        );
    }
}
