import { ErrorBoundary } from "@glide/common";
import type { WireRichTextComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isDefined, isUndefinedish } from "@glide/support";

import * as React from "react";
import { runActionAndHandleURL } from "../../wire-lib";
import { MarkdownView } from "../../components/markdown/markdown-view";
import type { WireRenderer } from "../wire-renderer";
import { Wrapper } from "./wire-rich-text-style";

export const WireRichText: WireRenderer<WireRichTextComponent> = React.memo(p => {
    const { text, action, backend } = p;
    if (!isDefined(text)) return null;
    const token = action?.token;
    const onClick = isUndefinedish(token)
        ? undefined
        : async () => {
              runActionAndHandleURL(action, backend);
              return undefined;
          };
    return (
        <ErrorBoundary>
            <React.Suspense fallback={<div />}>
                <Wrapper contextual={true}>
                    <MarkdownView onClick={onClick} disableStyleInjection={true} markdown={text} />
                </Wrapper>
            </React.Suspense>
        </ErrorBoundary>
    );
});
