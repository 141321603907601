import {
    type RelativePath,
    type RelativePathRest,
    type GroundValue,
    type Path,
    type RootPathResolver,
    type LoadedGroundValue,
    type Row,
    type ArrayValue,
    type LoadingValue,
    type ComputationValueGetters,
    type RootPath,
    type Handler,
    type ConditionValuePath,
    type LoadingValueWrapper,
    LoadingValueWrapperImpl,
    isIDPath,
    isIndexPath,
    isKeyPath,
    isLoadingValue,
    isRootPath,
} from "@glide/computation-model-types";
import {
    asPrimitive,
    asTable,
    getArrayItem,
    getRowColumn,
    isArrayValue,
    isRow,
    tableMap,
} from "@glide/common-core/dist/js/computation-model/data";
import { assert, panic } from "@glideapps/ts-necessities";
import { logError } from "@glide/support";

function asRow(v: LoadedGroundValue): Row {
    if (!isRow(v)) {
        logError("Not a row", JSON.stringify(v));
        return panic("Not a row");
    }
    return v;
}

function asArrayValue(v: LoadedGroundValue): ArrayValue {
    assert(isArrayValue(v));
    return v;
}

export function follow(gv: GroundValue, path: RelativePath): GroundValue {
    let p: RelativePathRest = path;
    while (p !== undefined) {
        if (gv === undefined) return undefined;
        if (isLoadingValue(gv)) return gv;
        if (isKeyPath(p)) {
            const r = asRow(gv);
            gv = getRowColumn(r, p.key);
        } else if (isIndexPath(p)) {
            const a = asArrayValue(gv);
            if (p.index >= 0) {
                gv = getArrayItem(a, p.index);
            } else {
                gv = getArrayItem(a, a.length + p.index);
            }
        } else if (isIDPath(p)) {
            const t = asTable(gv);
            gv = t.get(p.id);
        } else {
            const { column } = p;
            let hasLoading: LoadingValue | undefined;
            gv = tableMap(asTable(gv), false, r => {
                const v = getRowColumn(r, column);
                if (isLoadingValue(v)) {
                    hasLoading = v;
                    return undefined;
                }
                return asPrimitive(v);
            });
            if (hasLoading !== undefined) return hasLoading;
        }
        p = p.rest;
    }
    return gv;
}

// This is only for internal use in the computation model.
export function getValueAt(ns: RootPathResolver, context: GroundValue, p: Path): GroundValue {
    if (isRootPath(p)) {
        return ns.get(p, true);
    } else {
        return follow(context, p);
    }
}

// This the same as `getValueAt`, except it can be called anywhere because it
// doesn't assert that dependencies are not dirty.
export function getValueAtPath(ns: RootPathResolver, context: GroundValue, p: Path): GroundValue {
    if (isRootPath(p)) {
        return ns.get(p);
    } else {
        return follow(context, p);
    }
}

// FIXME: We're invoking this one for every row in some computations, which,
// given how `getValueAt` calls back to the namespace, is probably very
// expensive.
// ##wrapLoadingValues
// The getter returns the unwrapped value
export function makePathOrGroundValueGetter(
    ns: RootPathResolver,
    context: GroundValue,
    hostRow: Row | undefined,
    wrapper: LoadingValueWrapper
): (pov: ConditionValuePath) => GroundValue {
    return (pov: ConditionValuePath) => {
        // TS complains that this is not a boolean even though it is.
        if (pov.inHostRow === true) {
            assert(hostRow !== undefined && !isRootPath(pov.path));
            return wrapper.unwrap(follow(hostRow, pov.path));
        } else {
            return wrapper.unwrap(getValueAt(ns, context, pov.path));
        }
    };
}

class WrappedComputationValueGetters
    extends LoadingValueWrapperImpl
    implements ComputationValueGetters, LoadingValueWrapper
{
    getValueAt = (ns: RootPathResolver, ctx: GroundValue, p: Path) => {
        return this.unwrap(getValueAt(ns, ctx, p));
    };

    follow = (gv: GroundValue, path: RelativePath) => {
        return this.unwrap(follow(gv, path));
    };

    getRowColumn = (r: Row, c: string) => {
        return this.unwrap(getRowColumn(r, c));
    };

    resolveQueryWithFixup = (
        ns: RootPathResolver,
        maybeQuery: GroundValue,
        path: Path,
        context: GroundValue,
        contextPath: RootPath | undefined,
        handler: Handler,
        asRowB: boolean
    ) => {
        return this.unwrap(ns.resolveQueryWithFixup(maybeQuery, path, context, contextPath, handler, asRowB));
    };

    makePathOrGroundValueGetter = (ns: RootPathResolver, context: GroundValue, hostRow: Row | undefined) => {
        return makePathOrGroundValueGetter(ns, context, hostRow, this);
    };
}

export function makeWrappedComputationValueGetters() {
    return new WrappedComputationValueGetters();
}
