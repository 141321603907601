import {
    type ActionDescription,
    type MutatingScreenKind,
    ActionKind,
    getCompoundActionProperty,
} from "@glide/app-description";
import type { TableGlideType } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import type { AppDescriptionContext, SuperPropertySection } from "@glide/function-utils";
import { assert } from "@glideapps/ts-necessities";
import { logError } from "@glide/support";
import { setUnionInto } from "collection-utils";
import {
    type AppDescriptionVisitor,
    type Location,
    type WalkAppDescriptionOptions,
    AppVisitorCache,
    walkAppDescription,
} from "../components/walk-app-description";
import type { CompoundActionDescription } from "./compound-handler";
import { getFeatureSetting } from "@glide/common-core";

export type ActionProcessorInclusionOptions = Partial<
    Pick<
        WalkAppDescriptionOptions<unknown>,
        "includeHiddenTabs" | "includeDefaultScreens" | "includeAutomations" | "includeAllActions"
    >
>;

export function forEachActionInApp(
    adc: AppDescriptionContext,
    processAction: (
        action: ActionDescription,
        location: Location,
        propertySection: SuperPropertySection | undefined
    ) => void,
    {
        includeHiddenTabs = true,
        includeDefaultScreens = true,
        includeAutomations = false,
        includeAllActions = false,
    }: ActionProcessorInclusionOptions = {}
): void {
    class Visitor implements AppDescriptionVisitor {
        public visitAction(
            location: Location,
            action: ActionDescription,
            _tables: InputOutputTables | undefined,
            _mutatingScreenKind: MutatingScreenKind | undefined,
            propertySection: SuperPropertySection | undefined
        ): void {
            processAction(action, location, propertySection);
        }
    }

    walkAppDescription(adc, new Visitor(), [], {
        includeHiddenTabs,
        includeDefaultScreens,
        includeAutomations,
        includeAllActions,
    });
}

export function forEachBuilderActionInApp(
    adc: AppDescriptionContext,
    processAction: (
        action: CompoundActionDescription | undefined,
        actionID: string,
        location: Location | undefined,
        propertySection: SuperPropertySection | undefined
    ) => void,
    actionProcessorOptions: ActionProcessorInclusionOptions = {}
): void {
    if (getFeatureSetting("useVisitBuilderActionWhenCopyingApps")) {
        class Visitor implements AppDescriptionVisitor {
            public visitBuilderAction(actionID: string, _table: TableGlideType | undefined): void {
                processAction(undefined, actionID, undefined, undefined);
            }

            public visitAction(
                location: Location,
                action: ActionDescription,
                _tables: InputOutputTables | undefined,
                _mutatingScreenKind: MutatingScreenKind | undefined,
                propertySection: SuperPropertySection | undefined
            ): void {
                if (action.kind !== ActionKind.Compound) return;
                const compound = action as CompoundActionDescription;
                const actionID = getCompoundActionProperty(compound.actionID);
                if (actionID === undefined) {
                    logError("No action ID for compound action");
                    return;
                }
                processAction(compound, actionID, location, propertySection);
            }
        }

        walkAppDescription(adc, new Visitor(), [], actionProcessorOptions);
    } else {
        forEachActionInApp(
            adc,
            (action, location, propertySection) => {
                if (action.kind !== ActionKind.Compound) return;
                const compound = action as CompoundActionDescription;
                const actionID = getCompoundActionProperty(compound.actionID);
                if (actionID === undefined) {
                    logError("No action ID for compound action");
                    return;
                }
                processAction(compound, actionID, location, propertySection);
            },
            actionProcessorOptions
        );
    }
}

const builderActionsUsedCache = new AppVisitorCache<Set<string>>(() => new Set());

export function getAllBuilderActionIDsUsedInApp(
    adc: AppDescriptionContext,
    actionProcessorOptions: ActionProcessorInclusionOptions = {}
): Set<string> {
    class Visitor implements AppDescriptionVisitor<Set<string>> {
        public readonly actionIDs = new Set<string>();
        private currentActionIDs: Set<string> | undefined;

        public addState(state: Set<string>): void {
            assert(this.currentActionIDs === undefined);
            setUnionInto(this.actionIDs, state);
        }

        public willVisitWithState(state: Set<string>): void {
            assert(this.currentActionIDs === undefined);
            this.currentActionIDs = state;
        }

        public finishedVisitingWithState(state: Set<string>): void {
            assert(this.currentActionIDs === state);
            setUnionInto(this.actionIDs, state);
            this.currentActionIDs = undefined;
        }

        public visitAction(_location: Location, action: ActionDescription): void {
            if (action.kind !== ActionKind.Compound) return;
            const compound = action as CompoundActionDescription;
            const actionID = getCompoundActionProperty(compound.actionID);
            if (actionID === undefined) {
                logError("No action ID for compound action");
                return;
            }

            const actionIDs = this.currentActionIDs ?? this.actionIDs;

            actionIDs.add(actionID);
        }
    }

    const visitor = new Visitor();
    walkAppDescription(adc, visitor, [], {
        ...actionProcessorOptions,
        includeHiddenTabs: true,
        includeDefaultScreens: true,
        cache: builderActionsUsedCache,
    });
    return visitor.actionIDs;
}
