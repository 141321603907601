import { convertValueToSerializable } from "@glide/data-types";
import {
    type ArrayOverlapFilterCondition as ArrayOverlapQueryCondition,
    type BinaryStringFilterCondition as BinaryStringQueryCondition,
    type EqualsFilterCondition as EqualsQueryCondition,
    type FilterCondition as QueryCondition,
    type FilterConditionBase as QueryConditionBase,
    type FilterDataValue,
    type TableGlideType,
    type TableName,
    BinaryPredicateFormulaOperator,
    filterConditionCodec,
} from "@glide/type-schema";
import { assert } from "@glideapps/ts-necessities";
import { isArray, objectEntries, getTimezoneOffsetForDate } from "@glide/support";
import * as t from "io-ts";
import { type DefinedPrimitiveValue, Table, type ResolvedGroundValue } from "./data";

export function generateEqualsQueryCondition(columnName: string, value: DefinedPrimitiveValue): EqualsQueryCondition {
    const rhs = convertValueToSerializable(value);
    return {
        kind: BinaryPredicateFormulaOperator.Equals,
        lhs: { columnName },
        rhs,
    };
}

export function generateArrayOverlapQueryCondition(
    columnName: string,
    array: readonly FilterDataValue[],
    roleHashLHS?: true
): ArrayOverlapQueryCondition | undefined {
    return {
        kind: "array-overlap",
        column: { columnName },
        array,
        roleHashLHS,
    };
}

export function isBinaryStringQueryCondition(term: QueryConditionBase): term is BinaryStringQueryCondition {
    return (
        term.kind === BinaryPredicateFormulaOperator.ContainsString ||
        term.kind === BinaryPredicateFormulaOperator.IsContainedInString ||
        term.kind === BinaryPredicateFormulaOperator.MatchesEmailAddress
    );
}

// These are in conjunctive normal form, which is to say:
// the inner array conditions are all OR'd together, and these OR'd arrays are AND'd together.
export const conjunctiveNormalFormConditionsCodec = t.readonlyArray(t.readonlyArray(filterConditionCodec));
export type ConjunctiveNormalFormConditions = t.TypeOf<typeof conjunctiveNormalFormConditionsCodec>;

// Sorting works by converting (see definition) the values to the type of the
// given column, and then comparing (see definition) those values.
export const querySortCodec = t.type({
    columnName: t.string,
    order: t.union([t.literal("asc"), t.literal("desc")]),
});
export type QuerySort = t.TypeOf<typeof querySortCodec>;

// To search for a given needle, convert all given column values to strings,
// and check whether the needle is contained in at least one of them, case
// insensitively.
//
// A search can be present with or without other conditions.  If a query has
// both a search and conditions, then only rows that match the conditions as
// well as the search must be returned.
const querySearchCodec = t.type({
    needle: t.string,
    columnNames: t.readonlyArray(t.string),
});
export type QuerySearch = t.TypeOf<typeof querySearchCodec>;

export enum RollupKind {
    AllTrue = "all-true",
    SomeTrue = "some-true",
    Sum = "sum",
    CountNonEmpty = "count-non-empty",
    Average = "average",
    Minimum = "minimum",
    Maximum = "maximum",
    Earliest = "earliest",
    Latest = "latest",
    Range = "range",
    CountTrue = "count-true",
    CountNotTrue = "count-not-true",
    CountUnique = "count-unique",
}

const rollupKindCodec = t.union([
    t.literal(RollupKind.AllTrue),
    t.literal(RollupKind.SomeTrue),
    t.literal(RollupKind.Sum),
    t.literal(RollupKind.CountNonEmpty),
    t.literal(RollupKind.Average),
    t.literal(RollupKind.Minimum),
    t.literal(RollupKind.Maximum),
    t.literal(RollupKind.Earliest),
    t.literal(RollupKind.Latest),
    t.literal(RollupKind.Range),
    t.literal(RollupKind.CountTrue),
    t.literal(RollupKind.CountNotTrue),
    t.literal(RollupKind.CountUnique),
    // This rolls up over an array column and returns an array with all unique
    // elements across the arrays in all the rows.
    t.literal("unique-array-elements"),
]);

const queryAggregateTypeCodec = t.union([
    t.type({
        kind: rollupKindCodec,
    }),
    t.type({
        kind: t.literal("join-strings"),
        separator: t.string,
    }),
]);
export type QueryAggregateType = t.TypeOf<typeof queryAggregateTypeCodec>;

const queryAggregateCodec = t.intersection([
    t.type({
        // The name of the column to aggregate
        column: t.string,
        // The name of the aggregate in the output rows
        name: t.string,
    }),
    queryAggregateTypeCodec,
]);
export type QueryAggregate = t.TypeOf<typeof queryAggregateCodec>;

const queryGroupByCodec = t.type({
    // The names of the columns by which to group
    columns: t.readonlyArray(t.string),
    aggregates: t.readonlyArray(queryAggregateCodec),
    limit: t.number,
    // This sort is applied after grouping.  Right now it only accepts column
    // names that are part of `columns`, i.e. it can't sort by aggregates.
    sort: t.readonlyArray(querySortCodec),
});
export type QueryGroupBy = t.TypeOf<typeof queryGroupByCodec>;

// ##queryFields:
// NOTE: Make sure that when fields get added here they are also compared in
// `areSerializedQueriesEquivalent`.
// ##queryColumns:
// NOTE: Make sure that when column references get added here they are also walked in
// `walkQueryColumns`.
export const serializedQueryCodec = t.intersection([
    t.type({
        conditions: conjunctiveNormalFormConditionsCodec,
        sort: t.readonlyArray(querySortCodec),
    }),
    t.partial({
        limit: t.number,
        search: querySearchCodec,
        groupBy: queryGroupByCodec,
        deviceTzMinutesOffset: t.number,
        // Row version is used in the case where the client wants a diff changes. Queries containing a row version will
        // return only rows and tombstones with a version newer than this value.
        rowVersion: t.number,
        // Optional cause for this query. Used to gather metrics on different request paths.
        cause: t.literal("requery"),
    }),
]);
export type SerializedQuery = t.TypeOf<typeof serializedQueryCodec>;

export function isLiveUpdateQuery(query: SerializedQuery): boolean {
    return query.cause === "requery";
}

export function isRowVersionQuery(query: SerializedQuery): boolean {
    return query.rowVersion !== undefined;
}

export function isRowVersionCapableQuery(query: SerializedQuery): boolean {
    return query.conditions.length === 0 && query.search === undefined && query.groupBy === undefined;
}

export function lowerSearchInQuery(q: SerializedQuery): SerializedQuery {
    const { search } = q;
    if (search === undefined) {
        return q;
    }
    return {
        ...q,
        conditions: [
            ...q.conditions,
            Array.from(search.columnNames).map(c => ({
                kind: BinaryPredicateFormulaOperator.ContainsString,
                lhs: { columnName: c },
                rhs: search.needle,
                negated: false,
            })),
        ],
    };
}

// Removes any undefined fields and any fields that aren't part of SerializedQuery
export function stripExtraneousFieldsFromQuery(q: SerializedQuery): SerializedQuery {
    // Strip fields that aren't in the codec
    const exact = t.exact(serializedQueryCodec).encode(q);
    // Strip undefined fields
    for (const [k, v] of objectEntries(exact)) {
        if (v === undefined) {
            delete exact[k];
        }
    }
    return exact;
}

type QueryPostProcessor = (table: Table) => ResolvedGroundValue;

export abstract class QueryBase {
    private conditions: ConjunctiveNormalFormConditions = [];
    // undefined means we apply a default limit
    // false means we don't apply limit at all.
    private limit: number | false | undefined;
    private sort: readonly QuerySort[] = [];
    private search: QuerySearch | undefined;
    private groupBy: QueryGroupBy | undefined;

    // This is not serialized
    private postProcessor: QueryPostProcessor | undefined;

    // We cache the serialized query
    private serialized: SerializedQuery | undefined;

    constructor(serialized?: SerializedQuery) {
        if (serialized !== undefined) {
            this.conditions = serialized.conditions;
            this.limit = serialized.limit;
            this.sort = serialized.sort;
            this.search = serialized.search;
            this.groupBy = serialized.groupBy;

            this.serialized = serialized;
        }
    }

    protected copyBaseFieldsFrom(q: QueryBase): void {
        this.limit = q.limit;
        this.conditions = q.conditions;
        this.sort = q.sort;
        this.search = q.search;
        this.groupBy = q.groupBy;
        this.postProcessor = q.postProcessor;
    }

    // The subclasses have to call `copyBaseFields` in their `clone` methods.
    protected abstract clone(): this;

    public withLimit(limit: number): this {
        if (this.limit !== undefined && this.limit !== false && this.limit <= limit) {
            return this;
        }

        const q = this.clone();
        q.limit = limit;
        return q;
    }

    // We use this when making aggregation queries - they don't usually limit
    // the rows over which they aggregate.
    // This is also used in the New Table component for in-memory queries, where we don't want to limit
    public withoutLimit(): this {
        if (this.limit === false) {
            return this;
        }

        const q = this.clone();
        q.limit = false;
        return q;
    }

    // This condition will be ANDed with all the others.  If it's an array,
    // then those will be ORed, and then ANDed with the existing conditions.
    public withCondition(conditions: QueryCondition | readonly QueryCondition[]): this {
        if (!isArray(conditions)) {
            conditions = [conditions];
        }
        if (conditions.length === 0) return this;

        // We should eventually be smart and recognize whether the new
        // condition can be combined with existing ones, or is even already
        // included in them.
        const q = this.clone();
        q.conditions = [...this.conditions, conditions];
        return q;
    }

    // If there is a `groupBy`, then this sort is applied before grouping.
    // The `groupBy` clause has its own sort that is applied after.
    public withSort(sort: readonly QuerySort[]): this {
        assert(sort.length > 0);
        const q = this.clone();
        // With the "FilterSortLimit" column it's possible to change an
        // existing sort to a different one, so we just overwrite it.
        q.sort = sort;
        return q;
    }

    public withSearch(search: QuerySearch): this {
        assert(this.search === undefined);
        const q = this.clone();
        q.search = search;
        return q;
    }

    public withGroupBy(groupBy: QueryGroupBy): this {
        assert(this.groupBy === undefined);
        const q = this.clone();
        q.groupBy = groupBy;
        return q;
    }

    public withPostProcess(postProcess: QueryPostProcessor): this {
        assert(this.postProcessor === undefined);
        const q = this.clone();
        q.postProcessor = postProcess;
        return q;
    }

    public serialize(): SerializedQuery {
        if (this.serialized === undefined) {
            this.serialized = {
                conditions: this.conditions,
                // We always run with a limit if there's no grouping
                limit: this.limit === false ? undefined : this.limit ?? (this.groupBy === undefined ? 1000 : undefined),
                deviceTzMinutesOffset: getTimezoneOffsetForDate(new Date()),
                sort: this.sort,
                search: this.search,
                groupBy: this.groupBy,
            };
        }
        return this.serialized;
    }

    public postProcess(value: Table): ResolvedGroundValue {
        if (this.groupBy === undefined && this.limit !== undefined && this.limit !== false && value.size > this.limit) {
            value = new Table(value.asArray().slice(0, this.limit));
        }

        if (this.postProcessor === undefined) {
            return value;
        } else {
            return this.postProcessor(value);
        }
    }
}

// These kinds of queries are used in the computation model.
export class Query extends QueryBase {
    constructor(public readonly tableName: TableName, serialized?: SerializedQuery) {
        super(serialized);
    }

    protected clone(): this {
        const q = new Query(this.tableName);
        q.copyBaseFieldsFrom(this);
        return q as this;
    }
}

// These queries are used solely in the component model.
export class QueryFromRows extends QueryBase {
    constructor(public readonly tableType: TableGlideType, public readonly rows: Table) {
        super();
    }

    protected clone(): this {
        const q = new QueryFromRows(this.tableType, this.rows);
        q.copyBaseFieldsFrom(this);
        return q as this;
    }
}

export function isQuery(v: unknown): v is QueryBase {
    return v instanceof QueryBase;
}
