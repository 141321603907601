import {
    type ActionDescription,
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getActionProperty,
    getEnumProperty,
    getIconProperty,
    makeArrayProperty,
} from "@glide/app-description";
import { makeEmptyComponentDescription, type InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { Appearance, Mood, reducedMoodCases } from "@glide/component-utils";
import type { WireAppButtonBarComponent } from "@glide/fluent-components/dist/js/base-components";
import type { SchemaInspector } from "@glide/type-schema";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentDescriptor,
    type EnumPropertyCase,
    type PropertyDescriptor,
    PropertySection,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import {
    type WireRowComponentHydratorConstructor,
    WireComponentKind,
    WireActionOffline,
    type WireInflationBackend,
    ButtonLabelsVisibility,
    ButtonBarSize,
    UIBUttonStyle,
} from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";

import { getDefaultPrimitiveActionKinds } from "../actions";
import { makeShowToastAction } from "../form-on-submit";
import { makeSimpleWireRowComponentHydratorConstructor, spreadComponentID } from "../wire/utils";
import { inflateAppButton } from "./button";
import { ComponentHandlerBase } from "./handler";

const ComponentKindButtonBar: ComponentKind = "button-bar";

interface ButtonBarComponentDescription extends ComponentDescription {
    readonly appearanceLeft: PropertyDescription;
    readonly iconLeft: PropertyDescription | undefined;
    readonly titleLeft: PropertyDescription | undefined;
    readonly actionLeft: PropertyDescription;

    readonly appearanceRight: PropertyDescription;
    readonly iconRight: PropertyDescription | undefined;
    readonly titleRight: PropertyDescription | undefined;
    readonly actionRight: PropertyDescription;

    readonly moodLeft: PropertyDescription | undefined;
    readonly moodRight: PropertyDescription | undefined;
}

function makeAppearanceCases(): readonly EnumPropertyCase<Appearance>[] {
    const cases: EnumPropertyCase<Appearance>[] = [
        {
            value: Appearance.Filled,
            label: "Filled",
            icon: "buttonFilled",
        },
        {
            value: Appearance.Transparent,
            label: "Transparent",
            icon: "buttonTransparent",
        },
        {
            value: Appearance.Bordered,
            label: "Bordered",
            icon: "buttonBordered",
        },
        {
            value: Appearance.Simple,
            label: "Simple",
            icon: "buttonSimple",
        },
    ];

    return cases;
}

function getActions(desc: ButtonBarComponentDescription): {
    actionLeft: readonly ActionDescription[];
    actionRight: readonly ActionDescription[];
} {
    return {
        actionLeft: definedMap(getActionProperty(desc.actionLeft), a => [a]) ?? [],
        actionRight: definedMap(getActionProperty(desc.actionRight), a => [a]) ?? [],
    };
}

export class ButtonBarComponentHandler extends ComponentHandlerBase<ButtonBarComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindButtonBar);
    }

    public getDescriptor(
        _desc: ButtonBarComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [
            makeTextPropertyDescriptor("titleLeft", "Title", "Enter title", true, mutatingScreenKind, {
                isCaption: undefined,
                preferredNames: ["caption", "title", "name", "description"],
                propertySection: PropertySection.LeftButton,
                searchable: false,
                columnFirst: false,
                defaultValue: "Left",
            }),

            makeTextPropertyDescriptor("titleRight", "Title", "Enter title", true, mutatingScreenKind, {
                isCaption: undefined,
                preferredNames: ["caption", "title", "name", "description"],
                propertySection: PropertySection.RightButton,
                searchable: false,
                columnFirst: false,
                defaultValue: "Right",
            }),
            {
                kind: PropertyKind.Enum,
                property: { name: "moodLeft" },
                label: "Mood",
                menuLabel: "Mood",
                cases: reducedMoodCases,
                defaultCaseValue: Mood.Default,
                section: PropertySection.LeftButton,
                visual: "dropdown",
            },
            {
                kind: PropertyKind.Enum,
                property: { name: "moodRight" },
                label: "Mood",
                menuLabel: "Mood",
                cases: reducedMoodCases,
                defaultCaseValue: Mood.Default,
                section: PropertySection.RightButton,
                visual: "dropdown",
            },
            {
                kind: PropertyKind.Icon,
                property: { name: "iconLeft" },
                label: "Icon",
                defaultIcon: undefined,
                section: PropertySection.LeftButton,
            },
            {
                kind: PropertyKind.Icon,
                property: { name: "iconRight" },
                label: "Icon",
                defaultIcon: undefined,
                section: PropertySection.RightButton,
            },
            {
                kind: PropertyKind.Enum,
                property: { name: "appearanceLeft" },
                label: "Style",
                menuLabel: "Show as",
                cases: makeAppearanceCases(),
                defaultCaseValue: Appearance.Filled,
                section: PropertySection.LeftButton,
                visual: "small-images",
            },
            {
                kind: PropertyKind.Enum,
                property: { name: "appearanceRight" },
                label: "Style",
                menuLabel: "Show as",
                cases: makeAppearanceCases(),
                defaultCaseValue: Appearance.Transparent,
                section: PropertySection.RightButton,
                visual: "small-images",
            },

            ...this.getBasePropertyDescriptors(),
        ];

        return {
            name: "Button Bar",
            description: "Tap to perform an action",
            img: "co-button-bar",
            group: "Buttons",
            helpUrl: getDocURL("buttonBar"),
            properties,
        };
    }

    public getActionDescriptors(
        _desc: ButtonBarComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        schema: SchemaInspector | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly ActionPropertyDescriptor[] {
        const actionKinds = getDefaultPrimitiveActionKinds(schema, mutatingScreenKind);
        return [
            {
                kind: PropertyKind.Action,
                property: { name: "actionLeft" },
                label: "Action",
                required: true,
                kinds: actionKinds,
                defaultAction: () => makeShowToastAction("Left"),
                section: PropertySection.LeftAction,
            },
            {
                kind: PropertyKind.Action,
                property: { name: "actionRight" },
                label: "Action",
                required: true,
                kinds: actionKinds,
                defaultAction: () => makeShowToastAction("Right"),
                section: PropertySection.RightAction,
            },
        ];
    }

    public inflate(
        ib: WireInflationBackend,
        desc: ButtonBarComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const { actionLeft, actionRight } = getActions(desc);

        const appearanceLeft = getEnumProperty<Appearance>(desc.appearanceLeft) ?? Appearance.Filled;
        const appearanceRight = getEnumProperty<Appearance>(desc.appearanceRight) ?? Appearance.Transparent;

        const moodLeft = getEnumProperty<Mood>(desc.moodLeft) ?? Mood.Default;
        const moodRight = getEnumProperty<Mood>(desc.moodRight) ?? Mood.Default;

        const iconLeft = getIconProperty(desc.iconLeft);
        const iconRight = getIconProperty(desc.iconRight);

        const hydratorLeft = inflateAppButton(
            ib,
            "left",
            desc.titleLeft,
            appearanceLeft,
            moodLeft,
            iconLeft,
            actionLeft,
            true
        );
        const hydratorRight = inflateAppButton(
            ib,
            "right",
            desc.titleRight,
            appearanceRight,
            moodRight,
            iconRight,
            actionRight,
            true
        );
        if (hydratorLeft === undefined && hydratorRight === undefined) return undefined;

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const left = hydratorLeft?.(hb);
            const right = hydratorRight?.(hb);
            if (
                (left === undefined || left.onTap.token === WireActionOffline) &&
                (right === undefined || right.onTap.token === WireActionOffline)
            ) {
                return undefined;
            }

            const component: WireAppButtonBarComponent = {
                kind: WireComponentKind.AppButtonBar,
                ...spreadComponentID(desc.componentID, forBuilder),
                left,
                right,
            };

            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: ButtonBarComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        const mapLeftButton = {
            title: desc.titleLeft,
            action: desc.actionLeft,
        };
        const mapRightButton = {
            title: desc.titleRight,
            action: desc.actionRight,
        };
        return {
            ...makeEmptyComponentDescription(WireComponentKind.ButtonsBlock),
            buttonStyle: UIBUttonStyle.Basic,
            size: ButtonBarSize.Wide,
            labelsVisibility: ButtonLabelsVisibility.Show,
            buttons: makeArrayProperty([mapLeftButton, mapRightButton]),
        } as ComponentDescription;
    }
}
