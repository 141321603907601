import { GlideIcon, useResizeDetector } from "@glide/common";
import { css, isSmallScreen, useResponsiveSizeClass } from "@glide/common-components";
import type { WireModalScreen, WireScreen } from "@glide/wire";
import { WireModalSize } from "@glide/wire";
import { defined } from "@glideapps/ts-necessities";
import type { Transition } from "framer-motion";
import { m } from "framer-motion";
import * as React from "react";
import tw from "twin.macro";

export interface LayerInfo {
    screen: WireScreen | WireModalScreen;
    transition: PageTransitions | undefined;
    persist: boolean;
}

export interface LayerTransition {
    bottom: LayerInfo;
    top: LayerInfo;
}

export interface PageTransitionInfo {
    layers: LayerTransition | undefined;
    onAnimationComplete(): void;
}

const TransitionContext = React.createContext<PageTransitions | undefined>(undefined);

export function useCurrentTransition(): PageTransitions | undefined {
    return React.useContext(TransitionContext);
}

interface TransitionProviderProps {
    transition: PageTransitions | undefined;
}

export const TransitionProvider: React.FC<React.PropsWithChildren<TransitionProviderProps>> = p => {
    const { transition, children } = p;

    return <TransitionContext.Provider value={transition}>{children}</TransitionContext.Provider>;
};

export enum PageTransitions {
    pushIn = "transition-push-in",
    popOut = "transition-pop-out",
    fadeIn = "transition-fade-in",
    fadeOut = "transition-fade-out",
}

// Full layers just do opacity cross-fades. Movement is handled inside, so we can selectively move things.
export function getFullLayerTransition(pageTransition: PageTransitions | undefined): PageTransitions | undefined {
    if (pageTransition === PageTransitions.fadeIn || pageTransition === PageTransitions.pushIn) {
        return PageTransitions.fadeIn;
    }

    if (pageTransition === PageTransitions.fadeOut || pageTransition === PageTransitions.popOut) {
        return PageTransitions.fadeOut;
    }

    return undefined;
}

// Inner stuff does not need to do opacity cross-fades, the full layer takes care of that.
export function getInnerLayersTransition(pageTransition: PageTransitions | undefined): PageTransitions | undefined {
    if (pageTransition === PageTransitions.pushIn || pageTransition === PageTransitions.popOut) {
        return pageTransition;
    }

    return undefined;
}

export function useOverlayHeaderHeight() {
    const { ref, height } = useResizeDetector<HTMLDivElement>();
    const size = useResponsiveSizeClass();
    const isMobile = isSmallScreen(size);
    const padding = isMobile ? 32 : 16;
    const actualHeight = `${(height ?? 0) + padding}px`; // The resize detector doesn't grab the padding for some reason

    return { headerRef: ref, height: actualHeight };
}

interface OverlayHeaderBackButtonProps {
    readonly onBack: (() => void) | undefined;
}

export const OverlayHeaderBackButton: React.FC<OverlayHeaderBackButtonProps> = p => {
    const { onBack } = p;

    // Need a span for layout
    if (onBack === undefined) {
        return <span />;
    }

    return (
        <button
            onClick={onBack}
            tw="flex justify-center items-center text-icon-base transition h-7 w-7 rounded-full page-md:(w-10
                h-10 rounded-lg) page-md:hover:(bg-n100 text-icon-dark)">
            <GlideIcon tw="text-text-accent" kind="stroke" icon="st-arrow-full" rotateDeg={180} strokeWidth={1.5} />
        </button>
    );
};

interface OverlayHeaderCloseButtonProps {
    readonly onClose: (() => void) | undefined;
}

export const OverlayHeaderCloseButton: React.FC<OverlayHeaderCloseButtonProps> = p => {
    const { onClose } = p;

    // Need a span for layout
    if (onClose === undefined) {
        return <span />;
    }

    return (
        <button
            tw="flex justify-center items-center text-icon-base transition h-7 w-7 rounded-full
                    bg-n200A p-1 page-md:(w-10 h-10 bg-transparent rounded-lg) page-md:hover:(bg-n300A text-icon-dark)"
            onClick={onClose}>
            <GlideIcon kind="stroke" icon="st-close" />
        </button>
    );
};

interface OverlayHeaderProps {
    readonly title: string;
    readonly isDraggable: boolean;
    readonly isOverlayScrolled: boolean;
    readonly headerRef: (el: HTMLDivElement | null) => void;
    readonly leftButton: React.ReactNode;
    readonly rightButton: React.ReactNode;
    readonly className?: string;
}

export const OverlayHeader: React.FC<OverlayHeaderProps> = p => {
    const { title, isDraggable, isOverlayScrolled, headerRef, leftButton, rightButton, className } = p;

    return (
        <div
            ref={headerRef}
            tw="bg-bg-front absolute w-full left-0 top-0 grid [grid-template-columns:28px 1fr 28px] items-center
                justify-between p-4 z-overlay-layer page-md:(p-2 [grid-template-columns:40px 1fr 40px])"
            className={className}>
            {leftButton}
            <span tw="font-semibold text-lg text-center text-text-base overflow-hidden break-words">{title}</span>
            {rightButton}
            {isDraggable && (
                <span tw="absolute left-1/2 top-2 w-10 height[3px] transform -translate-x-1/2 bg-n300 rounded-lg" />
            )}
            <HeaderSeparatorLine isOverlayScrolled={isOverlayScrolled} />
        </div>
    );
};

interface HeaderSeparatorLineProps {
    readonly isOverlayScrolled: boolean;
}

const HeaderSeparatorLine: React.VFC<HeaderSeparatorLineProps> = p => {
    const { isOverlayScrolled } = p;
    return (
        <div
            className={isOverlayScrolled ? "scrolled" : "not-scrolled"}
            tw="absolute bottom-0 left-0 w-full h-px bg-border-base transition duration-300 origin-center"
            css={css`
                &.scrolled {
                    transform: scaleX(1);
                }

                &.not-scrolled {
                    transform: scaleX(0);
                }
            `}
        />
    );
};

const backdropTransition: Transition = {
    duration: 0.2,
};

interface OverlayBackdropProps {
    readonly opacity: number;
    readonly zIndex: number;
    readonly modalSize: WireModalSize | undefined;
    readonly onClick?: () => void;
}

export const OverlayBackdrop: React.VFC<OverlayBackdropProps> = p => {
    const { opacity, onClick, zIndex, modalSize } = p;

    const sizeClass = useResponsiveSizeClass();
    const isMobileSize = isSmallScreen(sizeClass);

    const isMobileOverlay = isMobileSize && modalSize !== WireModalSize.SlideIn;
    const actualZIndex = isMobileOverlay ? 0 : zIndex;

    return (
        <m.div
            style={{ zIndex: actualZIndex }}
            onClick={onClick}
            initial={{ opacity: 0 }}
            animate={{ opacity }}
            exit={{ opacity: 0 }}
            transition={backdropTransition}
            tw="absolute inset-0 flex flex-col items-center"
            className={isMobileOverlay ? "mobile-overlay-backdrop" : "default-backdrop"}
            css={css`
                &.default-backdrop {
                    ${tw`background-color[rgba(0, 0, 0, 0.33)]`}
                }

                &.mobile-overlay-backdrop {
                    ${tw`bg-bg-front`}
                }
            `}
        />
    );
};

type LayerPosition = "top" | "bottom";
const LayerPositionContext = React.createContext<LayerPosition | undefined>(undefined);

export function useCurrentLayerPosition() {
    return defined(React.useContext(LayerPositionContext), "could not find layer position");
}

export const LayerPositionProvider: React.FC<React.PropsWithChildren<{ readonly position: LayerPosition }>> = p => {
    const { position, children } = p;
    return <LayerPositionContext.Provider value={position}>{children}</LayerPositionContext.Provider>;
};
