import { v4 as uuid } from "uuid";
import type { PluginConfig } from "@glide/app-description";

export function makeNewPluginConfig(
    pluginID: string,
    existingPluginConfigs: readonly PluginConfig[]
): PluginConfig & { readonly configID: string } {
    // We try to create use the plugin ID as the config ID because it makes
    // builder URLs nicer, but more importantly, because it allows us to
    // combine apps for TemplateGPT easier because we don't have to rewrite
    // plugin config IDs.

    const canonicalConfigIDExists = existingPluginConfigs.some(c => c.configID === pluginID);
    return {
        configID: canonicalConfigIDExists ? uuid() : pluginID,
        pluginID,
        parameters: {},
    };
}
