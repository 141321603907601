import { ArrayScreenFormat } from "@glide/app-description";
import { Mood } from "@glide/component-utils";
import type {
    WireNewDataGridCell,
    WireNewDataGridColumn,
    WireNewDataGridListComponent,
    WireNewDataGridRow,
} from "@glide/fluent-components/dist/js/base-components";
import type {
    WireBigNumbersComponent,
    WireHintComponent,
    WireListCardCollection,
    WireRenderableContentComponent,
    WireRichTextComponent,
    WireSimpleImageComponent,
    WireTextComponent,
} from "@glide/fluent-components/dist/js/fluent-components";
import { mapFilterUndefined } from "@glideapps/ts-necessities";
import { logError } from "@glide/support";
import {
    CardStyle,
    UIAlignment,
    UIAspect,
    UIImageFill,
    UIImageStyle,
    UIOrientation,
    UISize,
    UIStyleVariant,
    UITitleStyle,
    WireComponentKind,
    type WireComponent,
    UILayoutVariant,
} from "@glide/wire";
import type {
    BigNumbersRenderableDescription,
    ComponentRenderableDescription,
    HintRenderableDescription,
    ImageRenderableDescription,
    ListRenderableDescription,
    RichTextRenderableDescription,
    TableRenderableDescription,
    TextRenderableDescription,
} from "@glide/fluent-components/dist/js/fluent-components-types";

export function getComponentsFromRenderableDescriptions(
    rawDescriptions: WireRenderableContentComponent["descriptions"]
): readonly WireComponent[] {
    const renderableDescriptions = getRenderableDescriptions(rawDescriptions);
    if (renderableDescriptions !== null && renderableDescriptions.length > 0) {
        const componentDescriptions = mapFilterUndefined(renderableDescriptions, desc =>
            getWireComponentFromRenderableDescription(desc)
        );
        return componentDescriptions;
    }
    return [];
}

function getRenderableDescriptions(
    rawDescriptions: WireRenderableContentComponent["descriptions"]
): ComponentRenderableDescription[] {
    if (rawDescriptions === null || rawDescriptions === undefined) {
        return [];
    }

    try {
        const parsedDescriptions = JSON.parse(rawDescriptions);

        // FIXME: Don't `as`, verify and/or filter.
        return parsedDescriptions as ComponentRenderableDescription[];
    } catch (err: unknown) {
        logError(err);
        return [
            {
                kind: "hint",
                text: "Could not parse Glide components from your descriptions",
                mood: Mood.Warning,
            },
        ];
    }
}

function getWireComponentFromRenderableDescription(
    description: ComponentRenderableDescription
): WireComponent | undefined {
    const { kind } = description;
    switch (kind) {
        case "big-numbers":
            return getBigNumbersComponentFromRenderableDescription(description);

        case "hint":
            return getHintComponentFromRenderableDescription(description);

        case "image":
            return getImageComponentFromRenderableDescription(description);

        case "list":
            return getListComponentFromRenderableDescription(description);

        case "text":
            return getTextComponentFromRenderableDescription(description);

        case "table":
            return getTableComponentFromRenderableDescription(description);

        case "rich-text":
            return getRichTextComponentFromRenderableDescription(description);

        default:
            logError(`Unexpected renderable kind: ${kind}`);
            return undefined;
    }
}

type WireBigNumbersItem = WireBigNumbersComponent["data"][number];

function getBigNumbersComponentFromRenderableDescription(
    structuredDescription: BigNumbersRenderableDescription
): WireBigNumbersComponent {
    const { items, title } = structuredDescription;
    return {
        kind: WireComponentKind.BigNumbers,
        title,
        data: items.map(item => {
            const bigNumberItem: WireBigNumbersItem = {
                name: item.name,
                value: item.value,
                description: item.description,
            };
            return bigNumberItem;
        }),
    };
}

function getTextComponentFromRenderableDescription(
    structuredDescription: TextRenderableDescription
): WireTextComponent {
    return {
        kind: WireComponentKind.Text,
        text: structuredDescription.text,
        style: structuredDescription.style,
        align: UIAlignment.Start,
    };
}

function getHintComponentFromRenderableDescription(
    structuredDescription: HintRenderableDescription
): WireHintComponent {
    return {
        kind: WireComponentKind.Hint,
        description: structuredDescription.text,
        mood: structuredDescription.mood ?? Mood.Default,
    };
}

function getImageComponentFromRenderableDescription(
    structuredDescription: ImageRenderableDescription
): WireSimpleImageComponent {
    return {
        kind: WireComponentKind.SimpleImage,
        image: structuredDescription.image,
        aspectRatio: structuredDescription.aspectRatio ?? UIAspect.Auto,
        align: UIAlignment.Center,
        style: structuredDescription.style ?? UIImageStyle.Rectilinear,
        size: structuredDescription.size ?? UISize.Full,
    };
}

type WireListItem = WireListCardCollection["groups"][number]["items"][number];

function getListComponentFromRenderableDescription(
    structuredDescription: ListRenderableDescription
): WireListCardCollection {
    const { title, items } = structuredDescription;

    return {
        kind: WireComponentKind.List,
        format: ArrayScreenFormat.CardCollection,
        componentTitle: title,
        cardStyle: CardStyle.List,
        size: UISize.Medium,
        style: UIStyleVariant.Minimal,
        cardCollectionComponentStyle: UIStyleVariant.Minimal,
        imageStyle: UIImageStyle.Rectilinear,
        imageSize: UISize.Medium,
        alignment: UIAlignment.Start,
        imageFill: UIImageFill.Cover,
        aspectRatio: UIAspect.Square,
        orientation: UIOrientation.Vertical,
        showSeeAll: false,
        titleStyle: UITitleStyle.Simple,
        titleActions: [],
        groups: [
            {
                title: "",
                groupKey: "",
                paging: {
                    pageSize: items.length,
                    numPages: 1,
                    itemsCount: items.length,
                    pageIndex: {
                        value: 1,
                        onChangeToken: undefined,
                    },
                },
                items: items.map(item => {
                    const listItem: WireListItem = {
                        title: item.title,
                        subtitle: item.subtitle,
                        emphasis: item.emphasis,
                        image: item.image,
                        itemActions: [],
                    };

                    return listItem;
                }),
            },
        ],
        layoutVariant: UILayoutVariant.Default,
    };
}

function getTableComponentFromRenderableDescription<T extends Record<string, string | number>>(
    structuredDescription: TableRenderableDescription<T>
): WireNewDataGridListComponent | undefined {
    const { title, items } = structuredDescription;

    if (items.length === 0) {
        return undefined;
    }

    const columns: WireNewDataGridColumn[] = Object.entries(items[0]).map(([columnName, value]) => {
        return {
            isEditable: false,
            kind: typeof value === "string" ? "text" : "number",
            header: columnName,
            sizeOptions: {
                sizeKind: "auto",
                widthRatio: 1,
            },
            columnName,
            headerAction: undefined,
        };
    });

    const rows: WireNewDataGridRow[] = items.map(item => {
        const cells: WireNewDataGridCell[] = Object.values(item).map(value => {
            if (typeof value === "string") {
                const cell: WireNewDataGridCell = {
                    editableValue: {
                        value: value,
                        onChangeToken: undefined,
                    },
                    kind: "text",
                };
                return cell;
            } else {
                const cell: WireNewDataGridCell = {
                    editableValue: {
                        value: value,
                        onChangeToken: undefined,
                    },
                    kind: "number",
                };
                return cell;
            }
        });

        return { cells, deleteAction: undefined };
    });

    return {
        kind: WireComponentKind.List,
        format: ArrayScreenFormat.NewDataGrid,
        title: title ?? null,
        titleActions: [],
        columns,
        rows,
        hasNoMoreRows: false,
        viewWindow: {
            // TODO
            onChangeToken: "",
            value: {
                start: 0,
                end: 0,
                maxRequested: 0,
            },
        },
    };
}

function getRichTextComponentFromRenderableDescription(
    structuredDescription: RichTextRenderableDescription
): WireRichTextComponent {
    return {
        kind: WireComponentKind.RichText,
        text: structuredDescription.text,
    };
}
