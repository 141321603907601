import type { CoreTheme } from "@glide/theme";
import type { OverlayTheme } from "../../overlay-theme";
import { darkCoreTheme } from "@glide/theme";

const shadowColorDark = (opacity: number) => `rgba(0, 0, 0, ${opacity})`;

export const darkAppCoreTheme: CoreTheme = {
    ...darkCoreTheme,

    n0: "#15181E",
    n0A: "rgba(21, 24, 30, 0)",
    n50: "#171A21",
    n100: "#171A21",
    n100A: "rgba(225,225,255,0.08)",
    n200: "#1E222B",
    n200A: "rgba(255,255,255,0.12)",
    n300: "#2C2C30",
    n300A: "rgba(225,225,255,0.2)",
    n400: "#3D3D3F",
    n400A: "rgba(225,225,255,0.27)",
    n500: "#6c6c6c",
    n500A: "rgba(240,240,240,0.36)",
    n600: "#999999",
    n600A: "rgba(240,240,240,0.52)",
    n700: "#BEBEBE",
    n700A: "rgba(245,245,245,0.75)",
    n800: "#F1F1F1",
    n800A: "rgba(245,245,245,0.93)",
    n900: "#FFFFFF",

    b100: "rgba(125,140,255,0.14)",
    b200: "rgba(125,140,255,0.33)",
    b300: "rgba(125,140,255,0.6)",
    b400: "#7283FF", // default
    b500: "#929FFF",

    // green
    g100: "rgba(0,201,107,0.16)",
    g200: "rgba(0,201,107,0.3)",
    g300: "rgba(0,201,107,0.6)",
    g400: "#00CB5E", // default
    g500: "#68E7A2",

    // red
    r100: "rgba(249,69,66,0.2)",
    r200: "rgba(249,69,66,0.33)",
    r300: "rgba(249,69,66,0.6)",
    r400: "#F94542", // default
    r500: "#FF7674",

    // yellow
    y100: "rgba(250,183,0,0.2)",
    y200: "rgba(250,183,0,0.33)",
    y300: "rgba(250,183,0,0.6)",
    y400: "#FAB700", // default
    y500: "#FFD666",

    // purple
    pink100: "rgba(210,57,182,0.25)",
    pink200: "rgba(210,57,182,0.33)",
    pink300: "rgba(210,57,182,0.6)",
    pink400: "#D239B6", // default
    pink500: "#F182DE",

    textDark: "_n900",
    textBase: "_n800",
    textPale: "_n600",
    textXpale: "_n500",
    textDisabled: "_n400",

    iconDark: "_n800",
    iconBase: "_n600",
    iconPale: "_n500",
    iconXpale: "_n400",
    iconDisabled: "_n400A",

    shadowSoft0: `0px 1px 0px ${shadowColorDark(0.04)}`,
    shadowSoft1: `box-shadow: 0px 1px 3px ${shadowColorDark(0.08)};`,
    shadowSoft2: `0px 6px 12px ${shadowColorDark(0.1)}`,
    shadowSoft3: `0px 10px 20px ${shadowColorDark(0.1)}`,
    shadowSoft4: `0px 24px 48px ${shadowColorDark(0.1)}`,

    shadow0: `0px 1px 0px ${shadowColorDark(0.06)}`,
    shadow1: `0px 0px 0px 1px ${shadowColorDark(0.06)}, 0px 1px 3px ${shadowColorDark(0.08)}`,
    shadow2: `0px 0px 0px 1px ${shadowColorDark(0.06)}, 0px 6px 12px ${shadowColorDark(0.08)}`,
    shadow3: `0px 0px 1px 1px ${shadowColorDark(0.06)}, 0px 10px 20px ${shadowColorDark(0.08)}`,
    shadow4: `0px 0px 1px 1px ${shadowColorDark(0.06)}, 0px 24px 48px ${shadowColorDark(0.08)}`,

    shadowDark0: `0px 1px 0px ${shadowColorDark(0.12)}`,
    shadowDark1: `0px 1px 1px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px ${shadowColorDark(
        0.08
    )}, 0px 1px 3px ${shadowColorDark(0.08)}`,
    shadowDark2: `0px 0px 0px 1px ${shadowColorDark(
        0.08
    )}, 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 6px 12px ${shadowColorDark(0.08)}`,
    shadowDark3: `0px 0px 0px 1px ${shadowColorDark(
        0.08
    )}, 0px 3px 6px rgba(0, 0, 0, 0.06), 0px 10px 20px ${shadowColorDark(0.1)}`,
    shadowDark4: `0px 0px 0px 1px ${shadowColorDark(
        0.08
    )}, 0px 4px 8px rgba(0, 0, 0, 0.06), 0px 24px 48px ${shadowColorDark(0.12)}`,

    shadowXdark0: `0px 1px 0px ${shadowColorDark(0.25)}`,
    shadowXdark1: `0px 1px 1px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px ${shadowColorDark(
        0.08
    )}, 0px 1px 3px ${shadowColorDark(0.25)}`,
    shadowXdark2: `0px 0px 0px 1px ${shadowColorDark(
        0.08
    )}, 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 12px ${shadowColorDark(0.25)}`,
    shadowXdark3: `0px 0px 0px 1px ${shadowColorDark(
        0.08
    )}, 0px 3px 6px rgba(0, 0, 0, 0.06), 0px 10px 20px ${shadowColorDark(0.25)}`,
    shadowXdark4: `0px 6px 12px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px ${shadowColorDark(
        0.08
    )}, 0px 24px 48px ${shadowColorDark(0.25)}`,
};

export const darkOverlay: OverlayTheme = {
    overlayName: "DarkTheme",

    ...darkAppCoreTheme,

    fgColorLight: "_n500",
    fgColorMedium: "_n600",
    fgColorDark: "_n900",
    fgColorOpaque: "_n900",
    baseColor: "_n0",
    offBaseColor: "_n200",
    skeletonColor: "_n300",
    linkColor: "#5db7ef",
    separatorColor: "_n200A",
    veilColor: "rgba(0, 0, 0, 0.5)",

    primaryOverlayColor: "#000000F9",
    transparentOverlayColor: "#000000C9",
    mapTheme: "dark",

    avoidColors: [
        { color: "#15181E", contrast: 3.0, isBase: true },
        { color: "#ffffffe6", contrast: 1.2, isBase: false },
    ],

    calendar: {
        pastEventColor: "rgba(255, 255, 255, 0.12)",
        pastEventAccent: "#6c6c6c",
    },

    statusBar: {
        color: "#fff",
    },

    homeBar: {
        color: "#fff",
    },

    textField: {
        underlineColor: "rgba(255, 255, 255, 0.15)",
    },

    list: {
        topMargin: "_pageMarginMedium" as any,
    },

    cards: {
        bgColor: "_offBaseColor",
    },

    listItem: {
        selectedColor: "rgba(255, 255, 255, 0.05)",
        selectedColorOpaque: "rgba(35, 38, 44, 1)",
        textDarkenAmount: -25,
    },

    picker: {
        backgroundColor: "rgba(255, 255, 255, 0.08)",
        hoverBgColor: "rgba(255, 255, 255, 0.1)",
        activeBgColor: "rgba(255, 255, 255, 0.12)",
        currentBgColor: "rgba(255, 255, 255, 0.15)",
        currentColor: "_fgColorDark",
    },

    navBar: {
        fadeTo: "black",
    },

    signature: {
        invert: true,
    },

    tabBar: {
        unselectedColor: "#6C6C6C",
    },

    searchBar: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
    },

    switch: {
        compact: {
            backgroundColor: "#FFFFFF",
        },
        big: {
            backgroundColor: "_picker.activeBgColor",
            borderColor: "rgba(255, 255, 255, 0.24)",
        },
    },

    formSubmissionSuccessToast: {
        background: "rgba(33, 35, 39, 0.96)",
        translucentBackground: "rgba(33, 35, 39, 0.8)",
        checkmark: "white",
        labelColor: "#FFFFFF",
    },

    flyout: {
        overlayColor: "#00000099",
        bgColor: "#182026",
        headerFg: "white",
        headerBg: "#182026",
        headerBorderColor: "#E0E0E033",
        rightBorderColor: "transparent",
        headerImgColor: "_primaryAccentColor",
    },

    expandableOptions: {
        modal: {
            bgColor: "#000000",
        },
        options: {
            fgColorSelected: "#000000",
            fgColorUnselected: "#000000",
            bgColorUnselected: "#22252C",
        },
        button: {
            fgColorSelected: "#000000",
            fgColorUnselected: "#999999",
            bgColorUnselected: "#22252C",
        },
    },

    materialDateTimePicker: {
        header: {
            background: "#000000",
        },
        tabs: {
            background: "#000000",
        },
    },
};
