import { documentIDColumnName } from "@glide/common-core/dist/js/Database";
import { commentsTableName } from "@glide/common-core/dist/js/database-strings";
import { type TableGlideType, makePrimitiveType } from "@glide/type-schema";

export const commentsTable: TableGlideType = {
    name: commentsTableName,
    columns: [
        {
            name: documentIDColumnName,
            type: makePrimitiveType("string"),
            hidden: true,
            isProtected: false,
        },
        {
            name: "topic",
            type: makePrimitiveType("string"),
            isProtected: false,
        },
        {
            name: "email",
            type: makePrimitiveType("string"),
            isProtected: false,
        },
        {
            name: "username",
            type: makePrimitiveType("string"),
            isProtected: false,
        },
        {
            name: "dateTime",
            type: makePrimitiveType("date-time"),
            isProtected: false,
        },
        {
            name: "comment",
            type: makePrimitiveType("string"),
            isProtected: false,
        },
    ],
    emailOwnersColumn: undefined,
};
