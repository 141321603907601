import { GlideIcon } from "@glide/common";
import type { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { Button } from "../button/button";
import { UpgradePill } from "../paywall/upgrade-pill";
import { glideIconPropsCodec, type GlideIconProps } from "@glide/plugins-codecs";
import SwitchToggle from "../switch-toggle/switch-toggle";
import "twin.macro";

interface Props extends React.PropsWithChildren {
    icon?: string | GlideIconProps;
    name: string;
    description?: string;
    enabled: boolean;
    onToggle: (newValue: boolean) => void;
    requiredPlan?: PlanKind;
    onConfigureClick?: () => Promise<void>;
}

export const PluginPinAuthListItem: React.VFC<Props> = ({
    icon,
    name,
    description,
    enabled,
    onToggle,
    requiredPlan,
    onConfigureClick,
}) => {
    return (
        <div
            tw="flex w-full justify-between items-center py-2 transition-colors
                rounded-lg"
        >
            <div tw="flex grow gap-3 items-center w-full overflow-hidden">
                {icon && glideIconPropsCodec.is(icon) && <GlideIcon {...icon} iconSize={8} />}
                {icon && typeof icon === "string" && (
                    <div tw="w-8 h-8 rounded-full shadow-md p-1.5 shrink-0">
                        <img tw="w-5 h-5" src={icon} alt="Icon" />
                    </div>
                )}
                <div tw="grow truncate">
                    <div tw="flex space-x-1">
                        <div tw="text-text-base">{name}</div>
                        {requiredPlan && (
                            <UpgradePill planKind={requiredPlan} location={`integration_settings_${name}`} />
                        )}
                    </div>
                    <p tw="text-xs text-text-pale truncate">{description}</p>
                </div>
            </div>
            <div tw="flex items-center ml-2">
                {onConfigureClick !== undefined ? (
                    <Button
                        variant="default"
                        buttonType="tertiary"
                        size="sm"
                        label="Connect"
                        tw="self-start"
                        onClick={onConfigureClick}
                    />
                ) : (
                    <SwitchToggle toggled={enabled} size="default" onChange={onToggle} />
                )}
            </div>
        </div>
    );
};
