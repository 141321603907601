import * as React from "react";
import ReactDOM from "react-dom";

import { PortalWrapperContainer } from "./portal-wrapper-style";

interface Props extends React.PropsWithChildren {
    target: React.RefObject<HTMLElement>;
    blockTarget?: boolean;
    onTargetClick?: () => void;
}

interface State {
    bounds?: ClientRect | DOMRect;
}

const PortalWrapperContext = React.createContext<ClientRect | DOMRect | undefined>(undefined);

export class PortalWrapper extends React.PureComponent<Props, State> {
    public state: State = {};

    private fromUpdate = false;

    private interval = 0;

    public componentDidUpdate() {
        if (!this.fromUpdate) {
            this.updateBounds();
        }
        this.fromUpdate = false;
    }

    public componentDidMount() {
        this.updateBounds();

        this.interval = window.setInterval(this.updateBounds, 100 as number | undefined);
    }

    public componentWillUnmount() {
        clearInterval(this.interval);
    }

    private updateBounds = () => {
        const { target } = this.props;
        const node = target.current;
        if (node !== null && node instanceof Element) {
            const bounds = node.getBoundingClientRect();
            if (
                this.state.bounds === undefined ||
                bounds.left !== this.state.bounds.left ||
                bounds.right !== this.state.bounds.right ||
                bounds.top !== this.state.bounds.top ||
                bounds.bottom !== this.state.bounds.bottom
            ) {
                this.fromUpdate = true;
                this.setState({ bounds });
            }
        }
    };

    public render(): React.ReactNode {
        const { children, blockTarget, onTargetClick } = this.props;

        const target = document.getElementById("portal");
        const bounds = this.state.bounds;
        if (target === null || bounds === undefined) return null;

        const style: React.CSSProperties = {
            position: "absolute",
            left: bounds.left,
            top: bounds.top,
            width: bounds.width,
            height: bounds.height,
        };

        if (blockTarget === false) {
            style.pointerEvents = "none";
        }

        const portal = ReactDOM.createPortal(
            <PortalWrapperContainer
                style={style}
                onClick={e => {
                    if (e.target === e.currentTarget) {
                        e.stopPropagation();
                        onTargetClick?.();
                    }
                }}
                className="click-outside-ignore">
                <PortalWrapperContext.Provider value={bounds}>{children}</PortalWrapperContext.Provider>
            </PortalWrapperContainer>,
            target
        );
        return portal;
    }
}
