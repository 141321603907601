import type { EminenceFlags } from "@glide/billing-types";
import { waitForDocumentVisibility } from "@glide/common-core/dist/js/support/browser-hacks";
import { callCloudFunctionWeb } from "@glide/common-core/dist/js/utility/function-utils";

export async function getAppEminenceFlags(appID: string): Promise<EminenceFlags> {
    await waitForDocumentVisibility();
    const eminenceResponse = await callCloudFunctionWeb("getAppEminence", { appID });
    if (eminenceResponse === undefined) throw new Error("Could not get Eminence for app");
    const flags = await eminenceResponse.json();
    return flags as EminenceFlags;
}
