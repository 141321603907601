import { useBuilderEminenceFlags } from "@glide/billing-ui";
import type { EminenceFlags } from "@glide/billing-types";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { usePlayerEminenceFlags } from "@glide/player-core";
import type { Subtract } from "utility-types";
interface WithEminenceFlags {
    appID: string | undefined;
    flags: EminenceFlags | undefined;
}

export const withEminence =
    <P extends WithEminenceFlags>(
        Component: React.ComponentType<P>
    ): React.FC<React.PropsWithChildren<Subtract<P, WithEminenceFlags>>> =>
    p => {
        const appID = useAppID();
        if (appID !== undefined) {
            const eminenceFlags = useEminenceFlags(appID);
            return <Component {...(p as P)} appID={appID} flags={eminenceFlags} />;
        }
        return <Component {...(p as P)} appID={appID} flags={undefined} />;
    };

export const useEminenceFlags = isPlayer() ? usePlayerEminenceFlags : useBuilderEminenceFlags;
