import { styled } from "./glide-styled-components";

export const CheckBoxContainer = styled.div`
    .checkbox-container {
        display: flex;
        margin: 20px 0px;
        padding: 0px 16px;

        .check-box {
            align-self: flex-start;
        }

        .agreement {
            margin-left: 12px;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.1875rem;
        }

        .allow-auth {
            margin-left: 12px;
            align-self: flex-start;

            .allow-auth-primary {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.1875rem;
            }
            .allow-auth-secondary {
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.0625rem;
                opacity: 0.5;
            }
        }
    }

    .links-container {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        display: flex;
        margin: 12px 0px 20px 0px;
        flex-wrap: wrap;
        margin-left: 16px;

        .link {
            margin: 8px 12px 0px 0px;
            text-decoration: underline !important;
        }
    }
`;
