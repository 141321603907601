import { GlideDateTime } from "@glide/data-types";
import { isArray, logError } from "@glide/support";
import { mapFilterUndefined } from "@glideapps/ts-necessities";
import { isValueAllowedInAction } from "../components/computation-types";
import {
    type GroundValue,
    type WritableValue,
    isPrimitiveValue,
    isThunk,
    isLoadingValue,
    type Thunk,
} from "@glide/computation-model-types";

// We're being extra permissive here and allow `null`s, thunks, and `Date`s,
// just so we're never ever writing incorrect actions.
export function extractActionValues(
    columnValues: Record<string, GroundValue | Thunk>,
    columnNames: ReadonlySet<string> | undefined
): Record<string, WritableValue> {
    const values: { [column: string]: WritableValue } = {};

    for (const [c, v] of Object.entries(columnValues)) {
        if (columnNames !== undefined && !columnNames.has(c)) continue;

        let value: WritableValue;

        if (isLoadingValue(v) || isThunk(v)) {
            continue;
        } else if (v instanceof Date) {
            value = GlideDateTime.fromTimeZoneAgnosticDate(v);
        } else if (isPrimitiveValue(v)) {
            if (!isValueAllowedInAction(v)) {
                logError("Value is not allowed in action", v);
                continue;
            }
            value = v;
        } else if (isArray(v)) {
            value = mapFilterUndefined(v, i =>
                i instanceof Date
                    ? GlideDateTime.fromTimeZoneAgnosticDate(i)
                    : isPrimitiveValue(i) && isValueAllowedInAction(i)
                    ? i
                    : undefined
            );
        } else {
            logError("Trying to add a non-primitive value to a table", v);
            continue;
        }

        values[c] = value ?? "";
    }

    return values;
}
