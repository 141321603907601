import { trackEvent } from "@glide/common-core/dist/js/analytics";
import * as React from "react";
import type { ReactPlayerProps } from "react-player";
import ReactPlayer from "react-player";

interface State {
    lastProgressPercentage: number;
}

export default class ProgressReportingReactPlayer extends React.Component<ReactPlayerProps, State> {
    public state: State = {
        lastProgressPercentage: -1,
    };

    private onProgress(params: { played: number }) {
        const { url, onProgress } = this.props;
        const { lastProgressPercentage } = this.state;

        const progressPercentage = Math.round(params.played * 100);
        if (progressPercentage > lastProgressPercentage && progressPercentage % 25 === 0) {
            trackEvent(`video progress`, { label: url?.toString() ?? "", value: progressPercentage });
            this.setState({ lastProgressPercentage: progressPercentage });
        }

        if (onProgress !== undefined) {
            onProgress(params as any);
        }
    }

    public render(): React.ReactNode {
        return <ReactPlayer {...this.props} onProgress={s => this.onProgress(s)} />;
    }
}
