import { backCompatIcon } from "@glide/common";
import { definedMap } from "@glideapps/ts-necessities";
import classNames from "classnames";
import * as React from "react";

import { default as PropertyTitle } from "../property-title/property-title";
import { default as SectionTabBar } from "../section-tab-bar/section-tab-bar";
import { Tooltip } from "@glide/common-components";
import { EnumPickerStyle } from "./enum-picker-style";

interface Props<T> {
    readonly title: string;
    readonly getName: (value: T) => string;
    readonly getIcon: (value: T) => string;
    readonly getTooltip?: (value: T) => string | undefined;
    readonly onChange: (newValue: T) => void;
    readonly hideHeader?: boolean;
    readonly items: readonly T[];
    readonly value: T | undefined;
    readonly displayMode: "icon" | "text" | "both";
}
interface State {}

export default class EnumPicker<T extends {}> extends React.PureComponent<Props<T>, State> {
    public state: State = {};

    public render(): React.ReactNode {
        const { title, items, value, onChange, getName, getIcon, getTooltip, hideHeader, displayMode } = this.props;

        const inner = (
            <SectionTabBar<T>
                sizeMode={displayMode === "icon" ? "natural" : "wide"}
                onChange={onChange}
                items={items}
                value={value ?? items[0]}
                itemRenderer={t => {
                    const name = getName(t);

                    const showIcon = displayMode === "icon" || displayMode === "both";
                    const showText = displayMode === "text" || displayMode === "both";

                    const tooltip = !showText && getTooltip !== undefined ? getTooltip(t) : undefined;
                    const ref = React.createRef<any>();
                    return (
                        <div
                            aria-label={name}
                            ref={ref}
                            className={classNames("icon-wrapper", t === value && "selected", `stb-item-${name}`)}>
                            {showIcon && backCompatIcon(getIcon(t), 24)}
                            {showText && <span>{name}</span>}
                            {tooltip !== undefined && (
                                <Tooltip position="top" target={ref}>
                                    {tooltip}
                                </Tooltip>
                            )}
                        </div>
                    );
                }}
            />
        );

        return (
            <EnumPickerStyle data-testid="glideEnumPicker" displayMode={displayMode}>
                {hideHeader !== true && <PropertyTitle title={title} subtitle={definedMap(value, getName) ?? ""} />}
                {inner}
            </EnumPickerStyle>
        );
    }
}
