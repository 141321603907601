import type { ClientGlideAPI } from "@glide/plugins";
import { HiddenCopyButton } from "../hidden-copy-button/hidden-copy-button";
import type { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { isEmpty, logError } from "@glide/support";

export type ClientDetails = {
    clients: readonly ClientGlideAPI[];
    appTier: PlanKind | undefined;
};

interface Props {
    readonly ownerID: string;
    readonly clientDetails: ClientDetails;
    readonly useAPIKeyForPlugin: (
        userID: string,
        clientDetails: ClientDetails
    ) => {
        isLoading: boolean;
        error: unknown;
        apiKeys: Record<string, string | undefined> | undefined;
    };
}

export const ApiKeysList: React.VFC<Props> = props => {
    const { ownerID, clientDetails, useAPIKeyForPlugin } = props;

    const { isLoading, error, apiKeys } = useAPIKeyForPlugin(ownerID, clientDetails);

    if (isLoading) return <div>Loading...</div>;
    if (!isEmpty(error)) {
        logError(error);
        return <div>Error fetching api keys.</div>;
    }

    return (
        <>
            {clientDetails.clients.map(client => {
                const { id } = client;
                const apiKey = apiKeys?.[id];
                // tier check in the api aquirement
                if (apiKey === undefined) return null;
                return (
                    <div key={id}>
                        <HiddenCopyButton label="API Key" value={apiKey} />
                    </div>
                );
            })}
        </>
    );
};
