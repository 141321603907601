import { type GlideFC, GlideIcon, useEventListener } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { PortalObserver } from "@glide/common-core/dist/js/components/portal-observer/portal-observer";
import { formatNumberFixed, isDefined, isEmptyOrUndefinedish } from "@glide/support";
import { TextComponentStyle } from "@glide/component-utils";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { AppKind } from "@glide/location-common";
import { isSmallScreen, useResponsiveSizeClass, useRootResponsiveSizeClass, css } from "@glide/common-components";
import {
    type DynamicFilterEntriesWithCaption,
    type DynamicFilterEntry,
    type UIStyleVariant,
    type WireAction,
    type WireDynamicFilter,
    type WireEditableValue,
    type WireMultipleDynamicFilters,
    UIBackgroundStyle,
    UIButtonAppearance,
    UITitleStyle,
    ValueChangeSource,
} from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";
import { default as classNames, default as classnames } from "classnames";
import * as React from "react";
import { useLayer } from "react-laag";
import tw from "twin.macro";
import { Text } from "../../components/text/text";
import { useIsFlat } from "../../utils/use-is-flat";
import {
    type ExtractedActions,
    APP_MODAL_ROOT,
    NavBarPortalContext,
    breakoutActions,
    makeActionSpreadProps,
    runActionAndHandleURL,
} from "../../wire-lib";
import { WireButton } from "../wire-button/wire-button";
import { SectionStyleProvider } from "../wire-container/wire-container";
import { WireMenuButton } from "../wire-menu-button/wire-menu-button";
import { SelectedBadge, WireMultipleFiltersImpl } from "../wire-multiple-filters/wire-multiple-filters";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

interface UseDynamicFilterResult {
    filterValue?: string;
    filterItems?: readonly DynamicFilterEntry[];
    filterLoading?: boolean;
    hasFilter: boolean;
    onFilterChange?: (newIndex: number) => void;
    onFilterOpen?: () => void;
}

export function useDynamicFilter(
    filter: WireDynamicFilter | undefined,
    backend: WireBackendInterface
): UseDynamicFilterResult {
    const onFilterOpen = definedMap(filter?.onOpen, a => () => runActionAndHandleURL(a, backend));
    const onFilterChange = definedMap(
        filter?.entries,
        entries => (newIndex: number) => runActionAndHandleURL(entries[newIndex].onToggle, backend)
    );

    const filterLoading = filter?.onOpen?.token === null;

    return {
        hasFilter: filter !== undefined,
        onFilterOpen,
        filterValue: filter?.displayValue ?? undefined,
        filterLoading,
        filterItems: filter?.entries,
        onFilterChange,
    };
}

interface FilterMenuProps extends UseDynamicFilterResult {
    appKind: AppKind;
    styleVariant: UIStyleVariant;
    className?: string;
}

const WireFilterMenu: GlideFC<FilterMenuProps> = p => {
    const {
        filterItems,
        filterValue,
        onFilterChange,
        appKind,
        styleVariant,
        className,
        onFilterOpen,
        hasFilter,
        filterLoading,
    } = p;

    const [open, setOpen] = React.useState(false);

    const { layerProps, triggerProps, renderLayer } = useLayer({
        isOpen: open,
        auto: true,
        placement: "bottom-end",
        possiblePlacements: ["top-end", "top-start", "bottom-end", "bottom-start"],
        container: APP_MODAL_ROOT,
        triggerOffset: 5,
        onOutsideClick: () => setOpen(false),
    });

    useEventListener(
        "keydown",
        e => {
            if (e.key === "Escape") {
                setOpen(false);
            }
        },
        window,
        false,
        false
    );

    if (!hasFilter) return null;

    const filterLabel = filterValue ?? getLocalizedString("filterBy", appKind);

    return (
        <button
            {...triggerProps}
            onClick={() => {
                onFilterOpen?.();
                setOpen(cv => !cv);
            }}
            tw="relative bg-n200A border border-transparent flex shrink-0 grow-0 text-base text-text-contextual-xpale 
            items-center justify-between h-9 p-2 transition duration-75 rounded-lg first:ml-0 gp-md:px-3
            page-hover:(ring-1 ring-border-dark border-border-dark) focus-within:(ring-1 border-text-contextual-accent ring-text-contextual-accent)"
            className={classNames(styleVariant, className)}
            css={css`
                .style-accent-bg &,
                .style-dark & {
                    ${tw`bg-w10A page-hover:bg-w20A`}
                }
            `}>
            <div tw="relative">
                {filterValue && (
                    <span
                        tw="h-1.5 w-1.5 absolute -top-0.5 -right-0.5 bg-text-contextual-accent rounded-full
                            gp-md:hidden"
                    />
                )}
                <GlideIcon iconSize={20} kind="stroke" icon="st-filter-2" tw="shrink-0" />
            </div>
            <div
                className={filterValue === undefined ? "no-value" : undefined}
                css={css`
                    &.no-value {
                        ${tw`opacity-75`}
                    }
                `}
                tw="hidden text-ellipsis shrink min-w-0 whitespace-nowrap overflow-hidden leading-none ml-1.5
                    opacity-100
                    gp-md:block">
                {filterLabel}
            </div>
            <span
                tw="sr-only
                    gp-md:hidden">
                {filterLabel}
            </span>
            <GlideIcon
                iconSize={16}
                kind="stroke"
                icon="st-caret"
                tw="hidden ml-2 shrink-0
                    gp-md:flex"
            />
            {open &&
                renderLayer(
                    <ul
                        css={css`
                            ${tw`[box-shadow:0px 2px 20px rgba(0, 0, 0, 0.06)]`}
                        `}
                        tw="relative [max-width:300px] [min-width:192px] flex flex-col bg-bg-front rounded-lg p-1
                            max-h-96 overflow-y-auto border border-border-base"
                        {...layerProps}>
                        {filterLoading === true && (
                            <div tw="w-full py-3 flex items-center justify-center text-icon-base">
                                <GlideIcon kind="stroke" icon="st-half-spinner" iconSize={24} spin={true} />
                            </div>
                        )}
                        {filterItems?.map(({ displayValue: name, count }, i) => {
                            const showCheckmark = (filterValue === undefined && i === 0) || name === filterValue;
                            return (
                                <li
                                    key={i}
                                    tw="p-2 rounded-md cursor-pointer text-sm text-text-dark hover:bg-bg-back flex
                                        justify-between focus-visible:underline"
                                    onClick={() => onFilterChange?.(i)}>
                                    <span>{`${name ?? getLocalizedString("all", appKind)}${
                                        count === undefined ? "" : ` (${formatNumberFixed(count, 0, true, undefined)})`
                                    }`}</span>
                                    {showCheckmark && (
                                        <GlideIcon
                                            kind="stroke"
                                            icon="st-checkmark"
                                            tw="text-text-contextual-accent"
                                            iconSize={18}
                                        />
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                )}
        </button>
    );
};

interface MultipleFilterProps {
    readonly isLoading: boolean;
    readonly onToggleMultipleFilters: () => void;
    readonly entriesWithCaption: readonly DynamicFilterEntriesWithCaption[];
    readonly isDesktopFilterOpen: boolean;
    readonly toggleFilterEntry: (onToggleAction: WireAction) => void;
    readonly onClear: (() => void) | undefined;
    readonly numFiltersSelected: number;
}

export function useMultipleDynamicFilters(
    multipleDynamicFilters: WireMultipleDynamicFilters | undefined,
    backend: WireBackendInterface
): MultipleFilterProps | undefined {
    const rootSize = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(rootSize);
    return React.useMemo(() => {
        if (multipleDynamicFilters === undefined) {
            return undefined;
        }

        const { value: isOpen, onChangeToken } = multipleDynamicFilters.isOpen;

        if (onChangeToken === undefined) {
            return undefined;
        }

        const entriesWithCaption = multipleDynamicFilters.entries;
        const isDesktopFilterOpen = isOpen && !isMobile && entriesWithCaption.length > 0;

        const onToggleMultipleFilters = () => {
            backend.valueChanged(onChangeToken, !isOpen, ValueChangeSource.User);
        };

        const toggleFilterEntry = (onToggleAction: WireAction) => {
            runActionAndHandleURL(onToggleAction, backend, true);
        };

        const onClear = () => {
            runActionAndHandleURL(multipleDynamicFilters.clearAction, backend, true);
        };

        return {
            onToggleMultipleFilters,
            entriesWithCaption,
            isDesktopFilterOpen,
            toggleFilterEntry,
            onClear,
            isLoading: multipleDynamicFilters.isLoading,
            numFiltersSelected: multipleDynamicFilters.numFiltersSelected ?? 0,
        };
    }, [backend, isMobile, multipleDynamicFilters]);
}

interface MultipleFiltersButtonProps {
    readonly styleVariant: UIStyleVariant;
    readonly className?: string;
    readonly multipleFilterProps: MultipleFilterProps;
}

export const MultipleFiltersButton: React.VFC<MultipleFiltersButtonProps> = p => {
    const { styleVariant, className, multipleFilterProps } = p;
    const {
        onToggleMultipleFilters,
        isDesktopFilterOpen,
        entriesWithCaption,
        toggleFilterEntry,
        onClear,
        isLoading,
        numFiltersSelected,
    } = multipleFilterProps;

    const scrollingElementRef = React.useRef<HTMLDivElement>(null);

    const { layerProps, triggerProps, renderLayer } = useLayer({
        isOpen: isDesktopFilterOpen,
        auto: true,
        placement: "bottom-end",
        possiblePlacements: ["top-end", "top-start", "bottom-end", "bottom-start"],
        container: APP_MODAL_ROOT,
        triggerOffset: 5,
        onOutsideClick: onToggleMultipleFilters,
    });

    useEventListener(
        "keydown",
        e => {
            if (e.key === "Escape" && isDesktopFilterOpen) {
                onToggleMultipleFilters();
            }
        },
        window,
        false,
        false
    );

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onToggleMultipleFilters();
    };

    return (
        <div>
            <button
                {...triggerProps}
                data-testid="multiple-filters-trigger"
                onClick={onClick}
                tw="relative bg-n200A border border-transparent flex shrink-0 grow-0 text-base text-text-contextual-xpale 
                items-center justify-between h-9 p-2 transition duration-75 rounded-lg first:ml-0 gp-md:px-3
                page-hover:(ring-1 ring-border-dark border-border-dark) focus-within:(ring-1 border-text-contextual-accent ring-text-contextual-accent)"
                className={classNames(styleVariant, className)}
                css={css`
                    .style-accent-bg &,
                    .style-dark & {
                        ${tw`bg-w10A page-hover:bg-w20A`}
                    }
                `}>
                <div tw="relative">
                    {numFiltersSelected > 0 && (
                        <span tw="h-1.5 w-1.5 absolute -top-0.5 -right-0.5 bg-text-contextual-accent rounded-full gp-md:hidden" />
                    )}
                    <GlideIcon iconSize={20} kind="stroke" icon="st-filter-2" tw="shrink-0" />
                </div>
                <div
                    tw="hidden text-ellipsis shrink min-w-0 whitespace-nowrap overflow-hidden leading-none ml-1.5
                    gp-md:(flex items-center)">
                    {getLocalizedString("filter", AppKind.Page)}
                    <SelectedBadge selectedAmount={numFiltersSelected} />
                </div>
                <GlideIcon
                    iconSize={16}
                    kind="stroke"
                    icon="st-caret"
                    tw="hidden ml-2 shrink-0
                    gp-md:flex"
                />
            </button>
            {isDesktopFilterOpen &&
                renderLayer(
                    <div {...layerProps} tw="max-h-[430px] w-[400px] bg-bg-front shadow-2xl rounded-xl flex flex-col">
                        <SectionStyleProvider value={UIBackgroundStyle.White}>
                            {isLoading ? (
                                <div tw="w-full py-3 flex items-center justify-center text-icon-base">
                                    <GlideIcon kind="stroke" icon="st-half-spinner" iconSize={24} spin={true} />
                                </div>
                            ) : (
                                <div tw="overflow-y-auto grow" ref={scrollingElementRef}>
                                    <WireMultipleFiltersImpl
                                        filterValuesEntries={entriesWithCaption}
                                        toggleFilterEntry={toggleFilterEntry}
                                        scrollingElementRef={scrollingElementRef}
                                    />
                                </div>
                            )}

                            <div tw="flex w-full p-4 border-t border-border-pale gap-x-4">
                                <WireButton
                                    tw="grow"
                                    appearance={UIButtonAppearance.Bordered}
                                    onClick={onClear}
                                    disabled={isLoading}>
                                    {getLocalizedString("clearAll", AppKind.Page)}
                                </WireButton>
                                <WireButton
                                    tw="grow"
                                    appearance={UIButtonAppearance.Filled}
                                    onClick={onToggleMultipleFilters}>
                                    {getLocalizedString("done", AppKind.Page)}
                                </WireButton>
                            </div>
                        </SectionStyleProvider>
                    </div>
                )}
        </div>
    );
};
interface UseSeachBarProps {
    readonly onSearchChange?: (newVal: string) => void;
    readonly searchValue?: string;
}

export const useSearchBar = (
    searchBar: WireEditableValue<string> | undefined,
    backend: WireBackendInterface,
    pageIndexToken: string | undefined
): UseSeachBarProps => {
    const onSearchChange = React.useMemo(() => {
        return definedMap(searchBar?.onChangeToken, x => (newVal: string) => {
            backend.valueChanged(x, newVal, ValueChangeSource.User);
            // Changing the search makes paging jump to the first page
            if (pageIndexToken !== undefined) {
                backend.valueChanged(pageIndexToken, 0, ValueChangeSource.Internal);
            }
        });
    }, [backend, pageIndexToken, searchBar?.onChangeToken]);

    const searchValue = searchBar?.value;
    return React.useMemo(() => ({ onSearchChange, searchValue }), [onSearchChange, searchValue]);
};

interface SearchBarProps {
    readonly searchValue?: string;
    readonly styleVariant?: UIStyleVariant;
    readonly appKind: AppKind;
    readonly onSearchChange?: (newVal: string) => void;
    readonly className?: string;
}
export const SearchBar: React.VFC<SearchBarProps> = p => {
    const { searchValue, styleVariant, onSearchChange, appKind, className } = p;

    const clearSearch = React.useCallback(() => {
        onSearchChange?.("");
    }, [onSearchChange]);

    const onChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onSearchChange?.(event.target.value);
        },
        [onSearchChange]
    );

    return (
        <label
            className={classnames(
                styleVariant,
                !isEmptyOrUndefinedish(searchValue) && "is-searching-something",
                className
            )}
            tw="relative transition duration-75 rounded-lg bg-n200A border border-transparent h-9 pl-2 text-text-contextual-xpale flex grow 
                [min-width:156px] items-center cursor-text not-last:mr-2 gp-md:max-w-[200px] gp-lg:max-w-[300px] 
                page-hover:(ring-1 ring-border-dark border-border-dark)
                focus-within:(ring-1 border-text-contextual-accent ring-text-contextual-accent)
                page-hover:focus-within:(ring-text-contextual-accent border-text-contextual-accent)"
            css={css`
                .style-accent-bg &,
                .style-dark & {
                    ${tw`bg-w10A page-hover:bg-w20A`}

                    .clear-search-button {
                        ${tw`bg-w10A hocus:bg-w20A`}
                    }
                }
            `}>
            <GlideIcon iconSize={20} kind="stroke" icon="st-search" tw="mr-1.5 shrink-0 transition" />
            <input
                tw="text-base text-text-contextual-base placeholder-text-contextual-xpale leading-none w-full shrink"
                placeholder={getLocalizedString("search", appKind)}
                value={searchValue}
                onChange={onChange}
            />
            <button
                onClick={clearSearch}
                css={css`
                    .is-searching-something & {
                        ${tw`visible opacity-100`}
                    }
                `}
                tw="mx-1.5 bg-n200A hocus:bg-n300A p-1 invisible opacity-0 text-text-contextual-pale rounded-full transition-all"
                className="clear-search-button">
                <GlideIcon iconSize={16} kind="stroke" icon="st-close" tw="shrink-0" />
            </button>
        </label>
    );
};

interface Props extends FilterMenuProps {
    title?: string;
    searchValue?: string;
    className?: string;
    onSearchChange?: (newVal: string) => void;
    titleActions: ExtractedActions;
    styleVariant: UIStyleVariant;
    titleStyle?: UITitleStyle;
    isFirstComponent: boolean;
    multipleFilterProps?: MultipleFilterProps;
}

function gpsm(selector: string): string {
    return `.gp-sm ${selector}:not(.responsive-blocker ${selector}), .responsive-blocker .gp-sm ${selector}`;
}

function gpmd(selector: string): string {
    return `.gp-md.gp-md.gp-md ${selector}:not(.responsive-blocker ${selector}), .responsive-blocker .gp-md.gp-md.gp-md ${selector}`;
}

export const WireListContainer = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((p, ref) => {
    const {
        title,
        children,
        onSearchChange,
        className,
        searchValue,
        titleActions,
        appKind,
        hasFilter,
        styleVariant,
        titleStyle: maybeHeroTitleStyle = UITitleStyle.Simple,
        isFirstComponent = false,
        multipleFilterProps,
    } = p;

    const [firstAction, secondAction, ...maybeAllMenuActions] = breakoutActions(titleActions, 2);
    const AddOrEdit = [getLocalizedString("add", appKind), getLocalizedString("edit", appKind)].includes(
        firstAction?.title ?? ""
    );
    let menuActions = maybeAllMenuActions;

    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    const columnSizeClass = useResponsiveSizeClass();

    if (isMobile || isSmallScreen(columnSizeClass)) {
        menuActions = [secondAction, ...menuActions].filter(isDefined);
    }

    const hasSearch = onSearchChange !== undefined;
    const hasActions = firstAction !== undefined || secondAction !== undefined || menuActions.length > 0;

    const theme = useWireAppTheme();

    const navPortalId = React.useContext(NavBarPortalContext);

    const isSidebarPage = theme.showDesktopSideBar === true;
    const titleStyle =
        maybeHeroTitleStyle === UITitleStyle.Hero || isSidebarPage ? UITitleStyle.Simple : maybeHeroTitleStyle;
    const couldPortalDesktop = titleStyle !== UITitleStyle.Simple && (title !== undefined || hasActions || hasSearch);
    const couldPortalMobile = isMobile && isFirstComponent;

    const canPortal = navPortalId !== undefined && (couldPortalDesktop || couldPortalMobile);

    const isFlat = useIsFlat();

    const shouldShowMultipleFilters = multipleFilterProps !== undefined;

    const hasSomeDynamicFilter = hasFilter || shouldShowMultipleFilters;

    const numButtons = menuActions.length + (firstAction !== undefined ? 1 : 0) + (secondAction !== undefined ? 1 : 0);
    const numElement = numButtons + (hasSomeDynamicFilter ? 1 : 0) + (hasSearch ? 1 : 0);
    const needsSearchArea = hasSomeDynamicFilter || hasSearch;
    const useMagicLayout = numElement <= 2 && numButtons <= 1;
    const useSuperMagic = hasSomeDynamicFilter && hasSearch && numButtons === 1;

    const header = (
        <div
            className={classNames(
                needsSearchArea && numButtons === 0 && "needs-search-only",
                numButtons > 0 && !needsSearchArea && "needs-actions-only",
                needsSearchArea && !hasSearch && "filter-grow",
                useMagicLayout && "magic",
                useSuperMagic && "super-magic",
                isEmptyOrUndefinedish(title) && "no-title",
                numButtons === 0 && "no-buttons",
                !needsSearchArea && "no-search-area",
                !canPortal && "no-portal",
                isFlat && "flat",
                isMobile && "is-mobile",
                isFirstComponent && "first-collection",
                isSidebarPage && "sidebar-page",
                titleStyle,
                `theme-${(theme.pageTheme ?? "").toLowerCase()}`,
                `background-${theme.pageBackground?.toLowerCase()}`
            )}
            tw="grid mb-4 mt-4 gap-y-3
                gp-md:(gap-y-4)"
            css={css`
                grid-template-columns: 1fr auto;
                grid-template-areas:
                    "t a"
                    "s s";
                column-gap: 8px;
                align-items: center;

                &&&&&.no-portal {
                    ${tw`mt-0`}
                }

                &.needs-actions-only {
                    grid-template-areas: "t a";
                    grid-template-columns: auto;
                }

                ${gpsm("&&.needs-actions-only")} {
                    grid-template-areas: "t a";
                }

                ${gpmd("&&")} {
                    grid-template-columns: auto 1fr auto;
                    grid-template-areas: "t s a";
                    .filter-wrapper {
                        max-width: 150px;
                    }
                }

                ${gpmd("&&.needs-actions-only")} {
                    grid-template-columns: 1fr auto;
                    justify-content: center;
                    grid-template-areas: "t a";
                }

                ${gpmd("&&.needs-search-only")} {
                    justify-content: center;
                    grid-template-columns: 1fr auto;
                    grid-template-areas: "t s";
                }

                &.magic.needs-actions-only {
                    grid-template-areas: "t a";
                    grid-template-columns: 1fr auto;
                }

                &.no-title.no-buttons.no-search-area {
                    ${tw`hidden`}
                }

                &.is-mobile.first-collection {
                    ${tw`mt-1 gp-md:mt-6`}
                }

                &.background-highlight.theme-highlight.first-collection.${UITitleStyle.Bold} {
                    ${tw`mb-4 gp-md:mb-7`}
                }
            `}>
            {title && (
                <Title
                    isMobile={isMobile}
                    tw="font-semibold [font-size: 22px]
                        gp-md:([font-size: 23px] tracking-tight)
                        gp-lg:[font-size: 24px]
                        gp-sm:([letter-spacing: -0.01em])
                    "
                    title={title}
                />
            )}
            {needsSearchArea && (
                <div tw="flex [grid-area: s] justify-end">
                    {hasSearch && (
                        <SearchBar
                            searchValue={searchValue}
                            appKind={appKind}
                            onSearchChange={onSearchChange}
                            styleVariant={styleVariant}
                        />
                    )}
                    {shouldShowMultipleFilters ? (
                        <MultipleFiltersButton
                            styleVariant={styleVariant}
                            multipleFilterProps={multipleFilterProps}
                            className="filter-wrapper"
                        />
                    ) : (
                        <WireFilterMenu className="filter-wrapper" {...p} />
                    )}
                </div>
            )}
            {hasActions && (
                <div
                    className="action-container"
                    tw="flex justify-end items-stretch w-full min-w-0 space-x-2 [grid-area: a]">
                    {firstAction !== undefined && (
                        <WireButton
                            {...makeActionSpreadProps(firstAction)}
                            size={isMobile ? "mini" : undefined}
                            iconOnly={isMobile && AddOrEdit}
                            appearance={firstAction.appearanceOverride ?? UIButtonAppearance.Filled}
                        />
                    )}
                    {secondAction !== undefined && (
                        <WireButton
                            tw="hidden
                                gp-md:flex"
                            {...makeActionSpreadProps(secondAction)}
                            appearance={secondAction.appearanceOverride ?? UIButtonAppearance.Bordered}
                        />
                    )}
                    {menuActions.length > 0 && (
                        <WireMenuButton
                            size={isMobile ? "mini" : undefined}
                            appearance={UIButtonAppearance.Bordered}
                            menuItems={menuActions}
                        />
                    )}
                </div>
            )}
        </div>
    );

    let headerNode = header;
    if (canPortal) {
        headerNode = (
            <SectionStyleProvider
                value={
                    theme.pageTheme === "Highlight"
                        ? UIBackgroundStyle.White
                        : theme.pageTheme === "Dark"
                        ? UIBackgroundStyle.Dark
                        : UIBackgroundStyle.Accent
                }>
                <PortalObserver selector={`#${navPortalId}`}>{header}</PortalObserver>
            </SectionStyleProvider>
        );
    }

    return (
        <div
            data-testid={canPortal ? "first-collection-portal" : undefined}
            className={className}
            tw="flex flex-col"
            ref={ref}>
            {headerNode}
            {children}
        </div>
    );
});

type TitleProps = Required<Pick<Props, "title">> & { className?: string; isMobile: boolean };

const Title: React.VFC<TitleProps> = p => {
    const { title, className } = p;
    return (
        <div
            className={classNames(className, "title-header")}
            tw="[grid-area: t] flex items-center overflow-hidden [line-height: 120%] [min-height:32px]">
            <Text
                title={title}
                element="h2"
                variant={TextComponentStyle.headlineMedium}
                tw="truncate text-text-contextual-dark">
                {title}
            </Text>
        </div>
    );
};
