import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import type { Editor } from "@tiptap/react";
import type { MenuOption, TokenAttrs } from "./editor";
import { useEditorContext } from "./editor-context";
import { useIsDarkTheme } from "../../hooks/use-builder-theme";
import { isDefined, isEmptyOrUndefined } from "@glide/support";
import { IconRenderer } from "./icon-renderer";
import { getMenuOptionIconSize } from "./token-view";

export type TokenListDropdownRefMethods = {
    onKeyDown: (obj: { event: KeyboardEvent }) => boolean;
};

export const TokenListDropdown = forwardRef(
    <T,>(
        props: {
            items: MenuOption<T>[];
            command: (arg: TokenAttrs<T>) => void;
            editor: Editor;
        },
        ref: React.Ref<TokenListDropdownRefMethods>
    ) => {
        const [selectedIndex, setSelectedIndex] = useState(0);
        const items: MenuOption<T>[] = props.items.flatMap(group => group.items ?? []);
        const { activeMenu } = useEditorContext();
        const menuRef = useRef<HTMLDivElement>(null);

        const isDarkTheme = useIsDarkTheme();

        const selectItem = (index: number) => {
            const item = items[index];

            if (isDefined(item)) {
                props.command({
                    label: item.label,
                    preview: item.preview,
                    value: item.value,
                    icon: item.icon,
                    withDivider: item.withDivider,
                    invalid: item.invalid,
                    singleValue: item.singleValue,
                });
            }
        };

        const upHandler = () => {
            setSelectedIndex((selectedIndex + items.length - 1) % items.length);
        };

        const downHandler = () => {
            setSelectedIndex((selectedIndex + 1) % items.length);
        };

        const enterHandler = () => {
            if (items.length === 0) {
                return;
            }
            selectItem(selectedIndex);
        };

        // when items change, reset the selected index
        useEffect(() => {
            if (props.items.length > 0) {
                setSelectedIndex(0);
            }
        }, [props.items]);

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }: { event: KeyboardEvent }) => {
                if (event.key === "ArrowUp") {
                    upHandler();
                    return true;
                }

                if (event.key === "ArrowDown") {
                    downHandler();
                    event.stopPropagation();
                    return true;
                }

                if (event.key === "Enter") {
                    enterHandler();
                    return true;
                }

                return false;
            },
        }));

        /**
         * Automatically scrolls the menu to keep the selected item in view.
         * This effect runs whenever the selectedIndex changes.
         *
         * It works by:
         * 1. Finding the currently selected element in the menu using its ID
         * 2. Calling scrollIntoView() on the selected element
         * 3. Using 'nearest' for both block and inline options to minimize unnecessary scrolling
         *
         * This ensures that as users navigate through the menu with arrow keys,
         * the selected item always remains visible, scrolling only when necessary.
         */
        useEffect(() => {
            if (menuRef.current) {
                const selectedElement = menuRef.current.querySelector(`[id="menu-item-${selectedIndex}"]`);
                if (selectedElement) {
                    selectedElement.scrollIntoView({
                        behavior: "instant" as ScrollBehavior,
                        block: "nearest",
                        inline: "nearest",
                    });
                }
            }
        }, [selectedIndex]);

        if (activeMenu !== "search") return null;

        return (
            <div
                role="menu"
                data-is-dark={isDarkTheme}
                ref={menuRef}
                style={{
                    overflow: "auto",
                    overscrollBehavior: "contain",
                    colorScheme: isDarkTheme ? "dark" : "light",
                }}
                tw="rounded-md p-1 text-sm shadow-lg-dark
                text-text-dark
                relative z-50 flex flex-col overflow-auto overscroll-contain 
                bg-bg-middle
                data-[is-dark=false]:bg-n0
                max-h-[min(var(--popover-available-height,300px),300px)]
                w-[254px]
                ">
                {props.items.length > 0 ? (
                    props.items.map((group, groupIndex) => {
                        const groupStartingIndex = props.items
                            .slice(0, groupIndex)
                            .reduce((acc, g) => acc + (g.items?.length ?? 0), 0);
                        return (
                            <React.Fragment key={group.label}>
                                {!isEmptyOrUndefined(group.label.trim()) ? (
                                    group.items ? (
                                        <div tw="px-2.5 py-1.5">
                                            <h3 tw="font-semibold truncate text-builder-xs text-text-xpale">
                                                {group.label}
                                            </h3>
                                        </div>
                                    ) : (
                                        <button
                                            id={`menu-item-${groupIndex.toString()}`}
                                            data-selected={groupIndex === selectedIndex}
                                            tw="text-builder-base rounded-md gap-1 flex items-center justify-between px-2 py-1.5 text-left data-[selected=true]:bg-n200A transition"
                                            key={groupIndex}
                                            onMouseEnter={() => {
                                                setSelectedIndex(groupIndex);
                                            }}
                                            onClick={() => {
                                                selectItem(groupIndex);
                                            }}>
                                            <span tw="inline-flex gap-2 items-center w-full">
                                                {isDefined(group.icon) && (
                                                    <IconRenderer
                                                        icon={group.icon}
                                                        size={getMenuOptionIconSize(group.icon)}
                                                        tw="flex-none text-icon-base"
                                                    />
                                                )}
                                                <span tw="flex-grow flex-shrink-0 truncate max-w-[90%]">
                                                    {group.label}
                                                </span>
                                                {isDefined(group.preview) && (
                                                    <span tw="flex-shrink ml-2 truncate transition-colors text-builder-xs text-text-xpale group-hover:hidden">
                                                        {group.preview}
                                                    </span>
                                                )}
                                            </span>
                                        </button>
                                    )
                                ) : null}
                                {group?.items?.map((item, itemIndex) => {
                                    const currentIndex = groupStartingIndex + itemIndex;

                                    return (
                                        <button
                                            id={`menu-item-${currentIndex.toString()}`}
                                            data-selected={currentIndex === selectedIndex}
                                            tw="text-builder-base rounded-md gap-1 flex items-center justify-between px-2 py-1.5 text-left data-[selected=true]:bg-n200A transition"
                                            key={currentIndex}
                                            onMouseEnter={() => {
                                                setSelectedIndex(currentIndex);
                                            }}
                                            onClick={() => {
                                                selectItem(currentIndex);
                                            }}>
                                            <span tw="inline-flex gap-2 items-center w-full">
                                                {isDefined(item.icon) && (
                                                    <IconRenderer
                                                        icon={item.icon}
                                                        size={getMenuOptionIconSize(item.icon)}
                                                        tw="flex-none text-icon-base"
                                                    />
                                                )}
                                                <span tw="flex-grow flex-shrink-0 truncate max-w-[90%]">
                                                    {item.label}
                                                </span>
                                                {isDefined(item.preview) && (
                                                    <span tw="flex-shrink ml-2 truncate transition-colors text-builder-xs text-text-xpale group-hover:hidden">
                                                        {item.preview}
                                                    </span>
                                                )}
                                            </span>
                                        </button>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <button type="button" disabled tw="px-2 py-1.5 text-left">
                        <span tw="inline-flex gap-1 items-center">No results</span>
                    </button>
                )}
            </div>
        );
    }
);
