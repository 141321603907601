// This just excludes values we particularly don't want.  You still have to
// make sure that you're only writing primitive values, or converted
// GlideDates, for example.
export function isValueAllowedInAction(v: unknown): boolean {
    if (v === undefined) return false;
    if (v === null) return false;
    if (typeof v === "number" && isNaN(v)) return false;
    return true;
}

// FIXME: This shouldn't be here, but it's entangled with `Evaluable`.
export enum ActionSource {
    // FIXME: This is stupid.  The Clickable needs to take its action source
    // as an argument.
    Clickable = "clickable",
    ListItem = "list-item",
    GridItem = "grid-item",
    CalendarListItem = "calendar-list-item",
    ColumnItem = "column-item",
    GalleryItem = "gallery-item",
    Image = "image",
    Summary = "summary",
    OpenLink = "open-link",
    OpenMap = "open-map",
    SendEmail = "send-email",
    PostComment = "post-comment",
    DeleteComments = "delete-comments",
    Button = "button",
    Phone = "phone",
    TextMessage = "text-message",
    Note = "note",
    Signature = "signature",
    Switch = "switch",
    TextField = "text-field",
    PivotPicker = "pivot-picker",
    EmailAuthentication = "email-authentication",
    DateTimeField = "date-time-field",
    ImagePicker = "image-picker",
    FilePicker = "file-picker",
    BuyScreen = "buy-screen",
    UserProfile = "user-profile",
    MapScreen = "map-screen",
    Tinder = "tinder",
    Markdown = "markdown",
    None = "none",
}
