import {
    type DBSchema,
    type IDBPDatabase,
    type IDBPObjectStore,
    type IDBPTransaction,
    type StoreNames,
    openDB,
} from "idb";
import { sleep } from "@glideapps/ts-necessities";
import { withTimeout, ConditionVariable, logInfo, SyncJobQueue } from "@glide/support";

const maxConsecutiveFailures = 20;

class DatabaseUnavailableError extends Error {
    constructor() {
        super("IndexedDB is not available for use");
    }
}

class PermanentlyFailedError extends Error {
    constructor() {
        super("IndexedDB Connection permanently failed");
    }
}

class ObjectStoreInvalidStateError extends Error {
    constructor(objectStore: string) {
        super(`InvalidStateError occurred when accessing ${objectStore}`);
    }
}

class FinishingTransactionInvalidStateError extends Error {
    constructor(type: "commit" | "abort") {
        super(`Invalid state error while performing ${type}`);
    }
}

class QuotaExceededError extends Error {
    constructor() {
        super("IndexedDB Quota was exceeded");
    }
}

export class IndexedDBTransactionContext<T extends DBSchema> {
    constructor(private readonly txn: IDBPTransaction<T>) {}

    public objectStore<StoreName extends StoreNames<T>[][number]>(
        name: StoreName
    ): IDBPObjectStore<T, StoreNames<T>[], StoreName> {
        try {
            return this.txn.objectStore(name);
        } catch (e: unknown) {
            if (e instanceof DOMException && e.name === "InvalidStateError") {
                throw new ObjectStoreInvalidStateError(name.toString());
            }
            throw e;
        }
    }

    public abort(): void {
        try {
            return this.txn.abort();
        } catch (e: unknown) {
            if (e instanceof DOMException && e.name === "InvalidStateError") {
                throw new FinishingTransactionInvalidStateError("abort");
            }
        }
    }
}

interface IndexedDBContextArgs<T extends DBSchema> {
    readonly name: string;
    readonly version: number;
    readonly onUpgrade: (db: IDBPDatabase<T>, oldVersion: number) => Promise<void>;
    readonly injectFaults?: boolean;
}

// iOS aggressively terminates IndexedDB connections on loss of visibility.
// We need to be able to deal with that in a sane manner, so this class takes
// care of connection retries automatically.

let monkeyPatchEnforcer: ((s: typeof globalThis) => void) | undefined;

// Mobile Safari likes to delete our monkey-patches immediately after
// we install them. We cannot tolerate this.
export function setMonkeyPatchEnforcer(p: typeof monkeyPatchEnforcer) {
    if (p === undefined) return;
    monkeyPatchEnforcer = p;
}

function enforceMonkeyPatch() {
    if (monkeyPatchEnforcer === undefined) return;
    // eslint-disable-next-line no-restricted-globals
    monkeyPatchEnforcer(self);
}

const beforeReconnectTimeout = 7_000;

export class IndexedDBContext<T extends DBSchema> {
    private isDatabaseAvailable: boolean = true;
    // We use the initialDBConnection as a signalling mechanism to determine
    // whether or not IndexedDB is usable for us. In certain circumstances,
    // it's not. As an example Firefox Private Browsing won't let us even
    // open an IndexedDB session.
    //
    // We don't actually use it for transaction processing; we use
    // currentDBConnection instead (even though it starts out as the same
    // Promise). The idea being that an inability to connect the first time
    // is permanent, but loss of connectivity is transient.
    private readonly initialDBConnection: Promise<IDBPDatabase<T>>;
    private currentDBConnection: Promise<IDBPDatabase<T>>;

    private isDatabaseConnected: boolean = false;
    private liveConnectionCV: ConditionVariable = new ConditionVariable();
    private connectionSerial = 0;
    private consecutiveFailures = 0;

    private reconnectTimeout() {
        return this.consecutiveFailures <= 7 ? 5 * Math.pow(2, this.consecutiveFailures) : 1280;
    }

    private connectMutex = new SyncJobQueue();

    private async connectWithTimeout(
        timeout: number,
        beforeReconnect: (() => Promise<void>) | undefined
    ): Promise<IDBPDatabase<T>> {
        return await this.connectMutex.run(async () => {
            if (timeout > 0) {
                await sleep(timeout);
            }

            enforceMonkeyPatch();

            if (beforeReconnect !== undefined) {
                await withTimeout(beforeReconnect(), undefined, undefined, beforeReconnectTimeout);
            }

            // Just to be _extra_ sure...
            enforceMonkeyPatch();
            const db = await openDB(this.name, this.version, this.connectionCallbacks);

            this.isDatabaseConnected = true;
            this.consecutiveFailures = 0;
            this.connectionSerial++;
            this.liveConnectionCV.notifyAll();

            return db;
        });
    }

    private attemptReconnect(initial: boolean, beforeReconnect?: () => Promise<void>): Promise<IDBPDatabase<T>> {
        const reconnectTimeout = this.reconnectTimeout();
        logInfo("Attempting IndexedDB connection", this.connectionSerial, this.consecutiveFailures, reconnectTimeout);

        this.currentDBConnection = this.connectWithTimeout(initial ? 0 : reconnectTimeout, beforeReconnect);
        this.currentDBConnection.catch(e => {
            logInfo("While attempting an IndexedDB reconnect", e);
            if (this.permanentlyFailed()) {
                this.liveConnectionCV.notifyAll();
                return;
            }
            this.consecutiveFailures++;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.attemptReconnect(false);
        });

        return this.currentDBConnection;
    }

    private permanentlyFailed(): boolean {
        return !this.isDatabaseAvailable || this.consecutiveFailures >= maxConsecutiveFailures;
    }

    private connectionCallbacks = {
        upgrade: (db: IDBPDatabase<T>, oldVersion: number) => this.onUpgrade(db, oldVersion),
        terminated: async () => {
            logInfo("IndexedDB connection was terminated");
            if (this.permanentlyFailed()) {
                this.liveConnectionCV.notifyAll();
                return;
            }
            this.consecutiveFailures++;
            return this.attemptReconnect(false);
        },
    };

    public readonly name: string;
    private readonly version: number;
    private readonly onUpgrade: (db: IDBPDatabase<T>, oldVersion: number) => Promise<void>;
    private readonly injectFaults: boolean;

    constructor({ name, version, onUpgrade, injectFaults }: IndexedDBContextArgs<T>) {
        this.name = name;
        this.version = version;
        this.onUpgrade = onUpgrade;
        this.injectFaults = injectFaults ?? false;

        this.initialDBConnection = this.attemptReconnect(true);
        this.currentDBConnection = this.initialDBConnection;
    }

    public async checkAvailability(): Promise<boolean> {
        if (this.permanentlyFailed()) return false;
        try {
            await this.initialDBConnection;
            this.isDatabaseAvailable = true;
        } catch (e: unknown) {
            logInfo("IndexedDB is not available for local cache", e);
            this.isDatabaseAvailable = false;
        }
        return this.isDatabaseAvailable;
    }

    private async ensuringConnection(): Promise<IDBPDatabase<T>> {
        while (!this.isDatabaseConnected) {
            if (this.permanentlyFailed()) {
                throw new PermanentlyFailedError();
            }
            await this.liveConnectionCV.wait();
        }
        return await this.currentDBConnection;
    }

    private closeConnectionPolitely(conn: IDBPDatabase<T>) {
        this.isDatabaseConnected = false;

        try {
            conn.close();
        } catch (e: unknown) {
            logInfo("While closing failed connection", e);
        }
        void this.attemptReconnect(false);
    }

    public abandonCurrentConnection(beforeReconnect?: () => Promise<void>) {
        this.isDatabaseConnected = false;
        if (this.permanentlyFailed()) return;

        this.currentDBConnection
            .then(conn => conn.close())
            .catch(e => logInfo("While closing abandoned connection", e));
        void this.attemptReconnect(false, beforeReconnect);
    }

    private async ensuringTransaction(
        stores: StoreNames<T>[],
        write: boolean
    ): Promise<{ txn: IDBPTransaction<T>; connection: IDBPDatabase<T> }> {
        let failures = 0;

        do {
            // See runTransaction() for why we're capturing currentDBConnection, then
            // later comparing against this.currentDBConnection here: it's a mechanism
            // to ensure we don't accidentally deadlock when recovering from a temporary
            // IndexedDB failure.
            const { currentDBConnection } = this;
            let connection: IDBPDatabase<T> | undefined;

            try {
                connection = await this.ensuringConnection();

                if (this.injectFaults && Math.random() < 0.25) {
                    logInfo("Prematurely closing IndexedDB connection in ensuringTransaction");
                    connection.close();
                }
                const txn = connection.transaction(stores, write ? "readwrite" : "readonly");
                return { txn, connection };
            } catch (e: unknown) {
                if (e instanceof DOMException) {
                    const { name } = e;
                    switch (name) {
                        case "UnknownError":
                        // fallthrough
                        case "OperationError":
                        // fallthrough
                        case "NetworkError":
                        // fallthrough
                        case "TimeoutError":
                        // fallthrough
                        case "QuotaExceededError":
                        // fallthrough
                        case "AbortError":
                        // fallthrough
                        case "InvalidStateError": {
                            logInfo("IndexedDB failure", name);
                            if (
                                connection !== undefined &&
                                this.isDatabaseConnected &&
                                currentDBConnection === this.currentDBConnection
                            ) {
                                // ensuringConnection() will block until isDatabaseConnected === true.
                                this.closeConnectionPolitely(connection);
                            }
                            if (failures++ < maxConsecutiveFailures) {
                                continue;
                            }
                            break;
                        }
                    }
                    logInfo("Unrecoverable IndexedDB DOMException in ensuringTransaction", name);
                }
                logInfo("Unrecoverable IndexedDB failure in ensuringTransaction", e);
                throw e;
            }
        } while (true);
    }

    public async runTransaction<R>(
        stores: StoreNames<T>[],
        write: boolean,
        cb: (txn: IndexedDBTransactionContext<T>) => Promise<R>
    ): Promise<R> {
        if (!this.isDatabaseAvailable) throw new DatabaseUnavailableError();
        let failures = 0;
        // We are capturing the currentDBConnection Promise here, even though
        // we are not directly using it, to determine whether or not we should
        // set the failure state for reconnect purposes.
        //
        // If we fail for some reason, but recover from the failure asynchronously
        // before attempting to signal a failure, we could end up in a deadlock.
        // Failure recovery will synchronously clear the condition variable and
        // replace this Promise, so if we encounter a failure condition that
        // requires reconnection but the stored Promise on `this` is different,
        // that means the recovery already happened.
        let { currentDBConnection } = this;

        const flagDatabaseFailure = (connection: IDBPDatabase<T>) => {
            if (this.isDatabaseConnected && currentDBConnection === this.currentDBConnection) {
                logInfo("Flagging IndexedDB failure in runTransaction");
                this.closeConnectionPolitely(connection);
            }
        };

        do {
            const { txn, connection } = await this.ensuringTransaction(stores, write);
            currentDBConnection = this.currentDBConnection;

            try {
                if (this.injectFaults && Math.random() > 0.25) {
                    if (Math.random() > 0.5) {
                        logInfo("Prematurely closing IndexedDB connection in runTransaction");
                        connection.close();
                    } else {
                        logInfo("Prematurely aborting IndexedDB transaction in runTransaction");
                        txn.abort();
                    }
                }

                const ret = await cb(new IndexedDBTransactionContext(txn));
                await txn.done;
                return ret;
            } catch (e: unknown) {
                failures++;

                if (failures >= maxConsecutiveFailures) {
                    if (e instanceof DOMException && e.name === "QuotaExceededError") {
                        throw new QuotaExceededError();
                    }
                    logInfo("Unrecoverable IndexedDB failure in runTransaction", e);
                    throw e;
                }

                if (e instanceof ObjectStoreInvalidStateError || e instanceof FinishingTransactionInvalidStateError) {
                    logInfo("IndexedDB failure", e.message);
                    flagDatabaseFailure(connection);
                    continue;
                }

                if (e instanceof DOMException) {
                    // We retry on QuotaExceededErrors, but if they persist, we throw
                    // a specific exception wrapping them.
                    switch (e.name) {
                        case "UnknownError":
                        // fallthrough
                        case "NetworkError":
                        // fallthrough
                        case "TimeoutError":
                        // fallthrough
                        case "InvalidStateError": {
                            logInfo("IndexedDB failure", e.name);
                            flagDatabaseFailure(connection);
                            continue;
                        }
                        case "TransactionInactiveError":
                        // fallthrough
                        case "QuotaExceededError":
                        // fallthrough
                        case "AbortError": {
                            logInfo("IndexedDB failure", e.name);
                            continue;
                        }
                        default: {
                            logInfo("Unrecoverable IndexedDB DOMException in runTransaction", e.name);
                            break;
                        }
                    }
                }

                logInfo("Unrecoverable IndexedDB failure in runTransaction", e);
                throw e;
            }
        } while (true);
    }
}
