import {
    type LoadedRow,
    type Row,
    type Query,
    type Unbound,
    UnboundVal,
    isBound,
} from "@glide/computation-model-types";
import {
    type PropertyDescription,
    ArrayScreenFormat,
    getActionProperty,
    getColumnProperty,
    getEnumProperty,
    getSwitchProperty,
} from "@glide/app-description";
import {
    BinaryPredicateFormulaOperator,
    getTableColumn,
    getTableName,
    isColumnWritable,
    maybeGetTableColumn,
} from "@glide/type-schema";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import { CalendarDefaultDate, GroupingSupport } from "@glide/component-utils";
import { GlideDateTime } from "@glide/data-types";
import type {
    WireListCalendarCollection,
    WireListCalendarCollectionItemGroup,
    WireListCalendarCollectionViewMode,
} from "@glide/fluent-components/dist/js/base-components";
import { CalendarCollection } from "@glide/fluent-components/dist/js/fluent-components";
import type { EditedColumn, InlineListComponentDescription } from "@glide/function-utils";
import {
    type RowBackends,
    type WireAlwaysEditableValue,
    type WireRowComponentHydrationBackend,
    type WireTableComponentHydrationBackend,
    type WireTableComponentHydrationResult,
    type WireTableComponentHydrator,
    type WireEditableValue,
    WireActionResult,
    ValueChangeSource,
    WireComponentKind,
    UITitleStyle,
} from "@glide/wire";
import { assert, assertNever, defined, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import add from "date-fns/add";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import uuid from "uuid";
import { makeGroupByGetters } from "../array-screens/array-content";
import {
    doTitleActionsForceComponentToShow,
    hydrateAndRegisterAction,
    inflateActions,
    inflateActionsWithTitles,
    inflateComponentEnricher,
    inflateDateTimeProperty,
    inflateStringProperty,
    inflateSwitchWithCondition,
    makeGroups,
} from "../wire/utils";
import { makeFluentArrayContentHandler } from "./fluent-array-handler";

function getViewRange(
    viewDate: Date,
    viewMode: WireListCalendarCollectionViewMode
): { viewStart: Date; viewEnd: Date } {
    let viewStart = viewDate;
    let viewEnd = viewDate;
    switch (viewMode) {
        case "month":
            viewStart = add(startOfMonth(viewStart), { days: -7 });
            viewEnd = add(viewStart, { months: 1, days: 15 });
            break;
        case "week":
            // Because we're not using locale in the startOfWeek, we need
            // to overly select our data in the case of italian style week formating.
            // Also, `English (Ireland)` requires expanding this overselection by 1 day
            // to grab sunday. Otherwise we'll drop alot of events.
            viewStart = add(startOfWeek(viewStart), { days: -7 });
            viewEnd = add(viewStart, { weeks: 2, days: 1 });
            break;
        case "day":
            viewStart = add(startOfDay(viewStart), { days: -1 });
            viewEnd = add(viewStart, { days: 3 });
            break;
        default:
            assertNever(viewMode);
    }

    return { viewStart, viewEnd };
}

function eventIsInRange(
    eventStart: Date,
    eventEnd: Date,
    viewDate: Date,
    viewMode: WireListCalendarCollectionViewMode
): boolean {
    const { viewStart, viewEnd } = getViewRange(viewDate, viewMode);
    return (
        (eventStart >= viewStart && eventStart <= viewEnd) ||
        (eventEnd >= viewStart && eventEnd <= viewEnd) ||
        (eventStart < viewStart && eventEnd > viewEnd)
    );
}

const handCraftedArtisnalUUID = "d96ca51a-35a6-48ca-b954-cce0e50adddf";

export const calendarCollectionArrayContentHandler = makeFluentArrayContentHandler(
    CalendarCollection,
    (ib, desc, containingRowIB, _componentID, _filterColumnName) => {
        if (containingRowIB === undefined) return undefined;

        const { tables } = ib;
        const inputTableName = getTableName(tables.input);

        const [componentTitleGetter] = inflateStringProperty(containingRowIB, desc.componentTitle, true);
        const titleStyle = getEnumProperty<UITitleStyle>(desc.titleStyle) ?? UITitleStyle.Simple;
        const defaultMode = getEnumProperty<WireListCalendarCollectionViewMode>(desc.defaultMode) ?? "month";
        const [titleActionsHydrator] = inflateActionsWithTitles(containingRowIB, desc.titleActions, "title");

        const maybeDefaultDayEnum = getEnumProperty<CalendarDefaultDate>(desc.defaultDate);
        // We only need the default date getter if it's not an enum.
        const [defaultDateGetter] =
            maybeDefaultDayEnum === undefined ? inflateDateTimeProperty(containingRowIB, desc.defaultDate) : [];

        const [itemTitleGetter] = inflateStringProperty(ib, desc.title, true);
        const [itemStartDateGetter] = inflateDateTimeProperty(ib, desc.startDate);
        const [itemEndDateGetter] = inflateDateTimeProperty(ib, desc.endDate);
        const itemAction = getActionProperty(desc.action);
        const itemActionGetter = definedMap(itemAction, x => inflateActions(ib, [x]));

        const startDateColumnName = getColumnProperty(desc.startDate);
        const endDateColumnName = getColumnProperty(desc.endDate);
        const titleColumnName = getColumnProperty(desc.title);

        const startCol = maybeGetTableColumn(tables.input, startDateColumnName);
        const endCol = maybeGetTableColumn(tables.input, endDateColumnName);
        const titleCol = maybeGetTableColumn(tables.input, titleColumnName);

        const titleWriteable = titleCol !== undefined && isColumnWritable(titleCol, tables.input, true);

        const allowAddGetter = inflateSwitchWithCondition(containingRowIB, desc.allowAdd);
        const allowEditGetter = inflateSwitchWithCondition(ib, desc.allowEdit);

        // This will be stable across hydrations.
        const uniqueID = uuid();

        const canMakeRow = startCol !== undefined;

        const componentEnricher = inflateComponentEnricher<WireListCalendarCollection>(
            ib,
            desc as unknown as InlineListComponentDescription
        );
        const groupByGetters = makeGroupByGetters(ib, desc, GroupingSupport.InMemory);

        interface ViewValues {
            readonly viewMode: WireAlwaysEditableValue<WireListCalendarCollectionViewMode>;
            readonly viewDate: WireAlwaysEditableValue<GlideDateTime | false>;
        }

        class Hydrator implements WireTableComponentHydrator {
            private viewValues: ViewValues | undefined;

            constructor(
                private readonly chb: WireRowComponentHydrationBackend | undefined,
                private readonly rowBackends: RowBackends,
                private readonly searchActive: boolean
            ) {}

            private getDefaultDateFromRows(rows: Iterable<Row>): GlideDateTime | undefined {
                assert(
                    maybeDefaultDayEnum === CalendarDefaultDate.Earliest ||
                        maybeDefaultDayEnum === CalendarDefaultDate.Latest
                );

                let defaultDate: GlideDateTime | undefined;
                for (const row of rows) {
                    const rhb = this.rowBackends.get(row);
                    const startDate = itemStartDateGetter(rhb);
                    if (!isBound(startDate) || startDate === undefined) continue;

                    let isNewDefault: boolean;
                    if (defaultDate === undefined) {
                        isNewDefault = true;
                    } else {
                        const relative = startDate.compareTo(defaultDate);
                        if (maybeDefaultDayEnum === CalendarDefaultDate.Earliest) {
                            isNewDefault = relative < 0;
                        } else {
                            isNewDefault = relative > 0;
                        }
                    }

                    if (isNewDefault) {
                        defaultDate = startDate;
                    }
                }
                return defaultDate;
            }

            // We pass `undefined` on the first call when we're hydrating from
            // a query.
            private makeViewValues(thb: WireTableComponentHydrationBackend | undefined): ViewValues | undefined {
                if (this.viewValues !== undefined) {
                    return this.viewValues;
                }

                const { chb } = this;
                if (chb === undefined) return undefined;

                // We're adding the unique ID to the key here so that in the
                // builder, when the default view mode is changed, the state
                // gets reset to that.  This obviously has downsides, most
                // notably:
                //
                // * whenever any other setting changes anywhere, the view is
                //   also reset to the default.
                // * a reload in the player will reset the view, too.
                //
                // FIXME: Figure out a way to fix these problems.
                const viewMode = chb.getState<WireListCalendarCollectionViewMode>(
                    "view-mode" + uniqueID,
                    (v): v is WireListCalendarCollectionViewMode => v === "month" || v === "week" || v === "day",
                    defaultMode,
                    true
                );

                // We set this to `false` when we're hydrating from a query
                // and we have to use either the earliest or latest date.  In
                // those cases we modify the query such that it just gives us
                // the earliest or latest dates, and we don't even bother to
                // set this to a "proper" date, until the user interacts.
                let defaultDate: GlideDateTime | false | undefined;
                if (defaultDateGetter !== undefined) {
                    defaultDate = defaultDateGetter(chb) ?? GlideDateTime.now();
                } else if (
                    maybeDefaultDayEnum === CalendarDefaultDate.Earliest ||
                    maybeDefaultDayEnum === CalendarDefaultDate.Latest
                ) {
                    if (thb !== undefined) {
                        defaultDate = this.getDefaultDateFromRows(thb.tableScreenContext.values());
                    } else {
                        defaultDate = false;
                    }
                }

                if (defaultDate === undefined) {
                    defaultDate = GlideDateTime.now();
                }

                const viewDate = chb.getState<GlideDateTime | false>(
                    "view-date",
                    undefined, // (x): x is GlideDateTime => x instanceof GlideDateTime,
                    defaultDate,
                    false
                );

                this.viewValues = { viewMode, viewDate };
                return this.viewValues;
            }

            public modifyQuery(query: Query): Query | undefined {
                if (startDateColumnName === undefined) return undefined;

                const viewValues = this.makeViewValues(undefined);
                if (viewValues === undefined) return undefined;
                const { viewMode, viewDate } = viewValues;

                if (viewDate.value === false) {
                    // If we need to get the earliest/latest date from a
                    // query, we simply modify the query such that it gives us
                    // the first/last 1000 events sorted by start date-time.
                    const existingSort = query.serialize().sort;
                    query = query
                        .withSort([
                            {
                                columnName: startDateColumnName,
                                order: maybeDefaultDayEnum === CalendarDefaultDate.Earliest ? "asc" : "desc",
                            },
                            ...existingSort,
                        ])
                        .withLimit(1000);
                } else {
                    const { viewStart, viewEnd } = getViewRange(viewDate.value.asTimeZoneAwareDate(), viewMode.value);

                    const startIsAfterViewStart = {
                        kind: BinaryPredicateFormulaOperator.IsGreaterOrEqualTo,
                        lhs: { columnName: startDateColumnName },
                        rhs: GlideDateTime.fromTimeZoneAwareDate(viewStart).toDocumentData(),
                        negated: false,
                    } as const;

                    // We have to make the Calendar send queries to get only the
                    // rows that need to be shown in the current "range".
                    //
                    // The query we send to get the  has to deal with two
                    // non-obvious cases:
                    //
                    // 1. A calendar event can start before the range and end
                    //    after the range, i.e. neither start nor end date-times
                    //    need necessarily within the range to qualify.
                    // 2. Calendar events are valid even if they don't have an end
                    //    time.  In that case we show them as one-hour events.
                    //
                    // I think that this query should work:
                    //
                    // ```
                    // (start <= range_end) &&
                    // (is_empty(end) || end >= range_start) &&
                    // (!is_empty(end) || start >= range_start)
                    // ```
                    //
                    // If `end` is empty then this simplifies to
                    //
                    // ```
                    // start <= range_end && start >= range_start
                    // ```
                    //
                    // If `end` is not empty then it simplifies to
                    //
                    // ```
                    // start <= range_end && end >= range_start
                    // ```
                    //
                    // Which I believe is what we want.

                    query = query.withCondition({
                        kind: BinaryPredicateFormulaOperator.IsLessOrEqualTo,
                        lhs: { columnName: startDateColumnName },
                        rhs: GlideDateTime.fromTimeZoneAwareDate(viewEnd).toDocumentData(),
                        negated: false,
                    });
                    if (endDateColumnName !== undefined) {
                        const endIsEmptyCondition = {
                            kind: "is-empty",
                            column: { columnName: endDateColumnName },
                        } as const;
                        query = query.withCondition([
                            {
                                ...endIsEmptyCondition,
                                negated: false,
                            },
                            {
                                kind: BinaryPredicateFormulaOperator.IsGreaterOrEqualTo,
                                lhs: { columnName: endDateColumnName },
                                rhs: GlideDateTime.fromTimeZoneAwareDate(viewStart).toDocumentData(),
                                negated: false,
                            },
                        ]);
                        query = query.withCondition([
                            {
                                ...endIsEmptyCondition,
                                negated: true,
                            },
                            startIsAfterViewStart,
                        ]);
                    } else {
                        query = query.withCondition(startIsAfterViewStart);
                    }
                }

                return query;
            }

            public hydrate(thb: WireTableComponentHydrationBackend): WireTableComponentHydrationResult | undefined {
                const viewValues = this.makeViewValues(undefined);
                if (viewValues === undefined) return undefined;
                const { viewMode, viewDate } = viewValues;

                const { chb, rowBackends, searchActive } = this;
                if (chb === undefined) return undefined;

                let actualViewDate: WireAlwaysEditableValue<GlideDateTime>;
                if (viewDate.value === false) {
                    actualViewDate = {
                        ...viewDate,
                        value: this.getDefaultDateFromRows(thb.tableScreenContext.values()) ?? GlideDateTime.now(),
                    };
                } else {
                    // TS doesn't like the direct assignment
                    actualViewDate = { ...viewDate, value: viewDate.value };
                }

                const newStartState = chb.getState<GlideDateTime | undefined>("new-start", undefined, undefined, false);
                const newEndState = chb.getState<GlideDateTime | undefined>("new-end", undefined, undefined, false);
                const newTitleState = chb.getState<string | undefined>("new-title", undefined, undefined, false);
                const newAccept = chb.registerAction("new-accept", async ab => {
                    if (newStartState.value === undefined || startCol === undefined) {
                        return WireActionResult.nondescriptError(true);
                    }
                    const row: LoadedRow = {
                        $rowID: makeRowID(),
                        $isVisible: false,
                        [startCol.name]: newStartState.value,
                    };

                    if (newEndState.value !== undefined && endCol !== undefined) {
                        row[endCol.name] = newEndState.value;
                    }

                    if (newTitleState.value !== undefined && titleCol !== undefined) {
                        row[titleCol.name] = newTitleState.value;
                    }

                    const addResult = await ab.addRow(inputTableName, row, undefined, true);

                    ab.valueChanged(newTitleState.onChangeToken, "", ValueChangeSource.Internal);
                    ab.valueChanged(newEndState.onChangeToken, undefined, ValueChangeSource.Internal);
                    ab.valueChanged(newStartState.onChangeToken, undefined, ValueChangeSource.Internal);

                    return WireActionResult.fromResult(addResult);
                });

                const rowGroups = makeGroups(
                    thb,
                    groupByGetters,
                    inputTableName,
                    (title, rows) => [title, rows] as const,
                    // In the Calendar, we use grouping to apply different
                    // colors.  With grouping by default, groups that don't
                    // have a value in their group column, or a title, are
                    // omitted, but here we don't want that.  To avoid it, we
                    // use the unique ID as the group value and title if there
                    // is none, which will definitely not collide with a
                    // "real" group, and since we don't show the group title
                    // it's ok that it's a UUID. It's a const so it will be stable
                    // run to run and things wont change colors constantly.
                    { value: handCraftedArtisnalUUID, title: handCraftedArtisnalUUID }
                );
                if (rowGroups === undefined) return undefined;

                const titleActions = titleActionsHydrator?.(chb, "") ?? [];
                const showIfEmpty = doTitleActionsForceComponentToShow(titleActions);

                const allowAdd = allowAddGetter(chb) === true;

                const showCalendarEvenWhenEmpty = getFeatureSetting("showCalendarEvenWhenEmpty");
                if (
                    !showCalendarEvenWhenEmpty &&
                    !allowAdd &&
                    !showIfEmpty &&
                    !searchActive &&
                    rowGroups.every(g => g[1].length === 0)
                ) {
                    return undefined;
                }

                const groups = rowGroups.map(([title, rows]) => {
                    const group: WireListCalendarCollectionItemGroup = {
                        title: title ?? "",
                        items: mapFilterUndefined(rows, row => {
                            const rhb = rowBackends.get(row);

                            const startDate = itemStartDateGetter(rhb);
                            const endDate = itemEndDateGetter(rhb);

                            if (
                                startDate === null ||
                                startDate === undefined ||
                                !eventIsInRange(
                                    startDate.asTimeZoneAwareDate(),
                                    (endDate ?? startDate).asTimeZoneAwareDate(),
                                    actualViewDate.value.asTimeZoneAwareDate(),
                                    viewMode.value
                                )
                            ) {
                                return undefined;
                            }

                            let startDateWEV: WireEditableValue<GlideDateTime>;
                            if (startCol !== undefined) {
                                const startToken = rhb.registerOnValueChange("startChange", startCol.name);
                                startDateWEV = {
                                    onChangeToken: typeof startToken === "string" ? startToken : undefined,
                                    value: startDate,
                                };
                            } else {
                                startDateWEV = {
                                    onChangeToken: undefined,
                                    value: startDate,
                                };
                            }

                            let endDateWEV: WireEditableValue<GlideDateTime | undefined> | Unbound | undefined;
                            if (endDate === UnboundVal) {
                                endDateWEV = endDate;
                            } else {
                                if (endCol !== undefined) {
                                    const endToken = rhb.registerOnValueChange("endChange", endCol.name);
                                    endDateWEV = {
                                        onChangeToken: typeof endToken === "string" ? endToken : undefined,
                                        value: endDate,
                                    };
                                } else {
                                    endDateWEV = {
                                        onChangeToken: undefined,
                                        value: endDate,
                                    };
                                }
                            }

                            const allowEdit = allowEditGetter(rhb) === true;

                            return {
                                title: itemTitleGetter(rhb),
                                action: hydrateAndRegisterAction(
                                    "itemAction",
                                    itemActionGetter ?? WireActionResult.nothingToDo(),
                                    rhb,
                                    false,
                                    undefined
                                ),
                                endDate: endDateWEV,
                                startDate: startDateWEV,
                                allowEdit,
                            };
                        }),
                    };
                    return group;
                });

                const component: WireListCalendarCollection = componentEnricher({
                    kind: WireComponentKind.List,
                    format: ArrayScreenFormat.CalendarCollection,
                    componentTitle: componentTitleGetter(chb),
                    allowAdd,
                    titleActions,
                    titleStyle,
                    groups,
                    defaultGroup: handCraftedArtisnalUUID,
                    newItemStart: canMakeRow ? newStartState : undefined,
                    newItemEnd: canMakeRow ? newEndState : undefined,
                    newItemTitle: canMakeRow && titleWriteable ? newTitleState : undefined,
                    viewMode,
                    viewDate: actualViewDate,
                    submitNewItem: canMakeRow ? { token: newAccept } : undefined,
                });

                return {
                    component,
                    isValid: true,
                };
            }
        }

        return {
            makeHydrator(
                rhb: WireRowComponentHydrationBackend | undefined,
                rowBackends: RowBackends,
                searchActive: boolean
            ) {
                return new Hydrator(rhb, rowBackends, searchActive);
            },
        };
    },
    (desc, _tables, itemTable) => {
        if (itemTable === undefined) return undefined;

        const allowAdd = getSwitchProperty(desc.allowAdd) === true;
        const allowEdit = getSwitchProperty(desc.allowEdit) === true;
        if (!allowAdd && !allowEdit) return undefined;

        const tableName = getTableName(itemTable);
        const editedColumns: EditedColumn[] = [];

        function addColumn(pd: PropertyDescription | undefined, forEdit: boolean) {
            assert(itemTable !== undefined);

            const columnName = getColumnProperty(pd);
            if (columnName === undefined) return;
            const column = getTableColumn(defined(itemTable), columnName);
            if (column === undefined) return;

            if (allowAdd && isColumnWritable(column, itemTable, true)) {
                editedColumns.push([columnName, false, true, tableName]);
            }
            if (forEdit && allowEdit && isColumnWritable(column, itemTable, false)) {
                editedColumns.push([columnName, false, false, tableName]);
            }
        }

        addColumn(desc.startDate, true);
        addColumn(desc.endDate, true);
        // The title is added for new rows, but not editable
        addColumn(desc.title, false);

        return { editedColumns, deletedTables: [] };
    }
);
