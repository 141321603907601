import { dispatchActivationProgressEvent } from "@glide/common-core/dist/js/activation";
import { AppKind } from "@glide/location-common";
import { isLoadingValue } from "@glide/computation-model-types";
import { asRow, asString } from "@glide/common-core/dist/js/computation-model/data";
import {
    rowIndexColumnName,
    areSourceColumnsEqual,
    getTableName,
    makeSourceColumn,
    sheetNameForTable,
} from "@glide/type-schema";
import {
    type ActionDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
    PropertyKind,
    getStringProperty,
    getSwitchProperty,
    makeStringProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    type RewritingComponentConfiguratorContext,
    classScreenName,
    getAppKindFromAppDescriptionContext,
    thisRowSourceColumn,
    PropertySection,
    makeSingleRelationOrThisItemPropertyDescriptor,
    type ActionAvailability,
} from "@glide/function-utils";
import { nullToUndefined } from "@glide/support";
import {
    type WireValueGetterGeneric,
    type WireActionResult,
    type WireActionResultBuilder,
    PageScreenTarget,
    type WireActionHydrator,
    type WireActionInflationBackend,
} from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";
import { v4 as uuid } from "uuid";
import { makeScreenTitlePropertyDescriptor } from "../components/descriptor-utils";
import { getMenuScreenNames, getSourceColumnOrThis } from "../description-utils";
import type { StaticActionContext } from "../static-context";
import { inflateStringProperty, makeRowGetter } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import {
    type NavigationTargetDescription,
    type SingleRelationOrThisDescription,
    getTableForSourceColumnOrThis,
    makeNavigationPropertyDescriptors,
    navigationTargetPropertyHandlers,
} from "./link-to-screen";
import { getSupportsUserProfileRowAccess } from "./set-columns";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

interface PushDetailScreenActionDescription
    extends ActionDescription,
        SingleRelationOrThisDescription,
        NavigationTargetDescription {
    readonly kind: ActionKind.PushDetailScreen;

    readonly menuID: PropertyDescription | undefined;
    readonly isMenu: PropertyDescription | undefined;

    readonly title: PropertyDescription | undefined;

    readonly navigationTarget: PropertyDescription | undefined;
}

function getIsMenu(desc: PushDetailScreenActionDescription | undefined): boolean {
    return getSwitchProperty(desc?.isMenu) ?? false;
}

export class PushDetailScreenHandler extends BaseActionHandler<PushDetailScreenActionDescription> {
    public readonly kind = ActionKind.PushDetailScreen;

    public readonly iconName: GlideIconProps = {
        icon: "st-show-details",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Show detail screen";

    public get canAutoRun(): boolean {
        return true;
    }

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        desc: PushDetailScreenActionDescription | undefined,
        { context: ccc, tables, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const properties: PropertyDescriptor[] = [];
        if (ccc.appKind === AppKind.App) {
            properties.push(makeScreenTitlePropertyDescriptor(mutatingScreenKind));
        }
        properties.push(
            makeSingleRelationOrThisItemPropertyDescriptor(
                "sourceColumn",
                "Data",
                PropertySection.Source,
                false,
                getSupportsUserProfileRowAccess(ccc)
            )
        );

        if (
            tables !== undefined &&
            (desc === undefined ||
                areSourceColumnsEqual(
                    getSourceColumnOrThis(desc.sourceColumn) ?? thisRowSourceColumn,
                    thisRowSourceColumn
                )) &&
            getAppKindFromAppDescriptionContext(ccc) === AppKind.App
        ) {
            // FIXME: Make sure this also works in detail screens
            properties.push({
                kind: PropertyKind.Switch,
                property: {
                    id: "isMenu",
                    get: d => (d as PushDetailScreenActionDescription).isMenu,
                    update: (d, pd, updater) => {
                        const a = d as PushDetailScreenActionDescription;
                        const newIsMenu = getSwitchProperty(pd) ?? false;
                        if (newIsMenu && tables.input.rowIDColumn === undefined) {
                            updater?.demandRowIDColumn(
                                getTableName(tables.input),
                                "Without a Row ID Glide can't associate the items in your sheet with the screens in your app."
                            );
                            return {};
                        }
                        let updates: Partial<PushDetailScreenActionDescription> = {
                            isMenu: makeSwitchProperty(newIsMenu),
                        };
                        if (newIsMenu && getStringProperty(a.menuID) === undefined) {
                            updates = { ...updates, menuID: makeStringProperty(uuid()) };
                        }
                        return updates;
                    },
                },
                label: "Independent screen configuration per item",
                section: PropertySection.Data,
                defaultValue: false,
            });
        }

        properties.push(...makeNavigationPropertyDescriptors(ccc, PageScreenTarget.Current));

        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 1,
            needsScreenContext: true,
            properties,
        };
    }

    public getScreensUsed(
        desc: PushDetailScreenActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly string[] {
        const { context: adc, tables } = env;

        if (getIsMenu(desc)) {
            const screens: string[] = [];

            if (tables !== undefined) {
                screens.push(classScreenName(getTableName(tables.input)));
            }

            if (adc.appDescription === undefined) return screens;

            const menuID = getStringProperty(desc.menuID);
            if (menuID === undefined) return screens;

            screens.push(...getMenuScreenNames(adc.appDescription, menuID));
            return screens;
        } else {
            const table = getTableForSourceColumnOrThis(desc.sourceColumn, true, true, env);
            if (table === undefined) return [];

            return [classScreenName(getTableName(table))];
        }
    }

    public rewriteAfterReload(
        desc: PushDetailScreenActionDescription,
        tables: InputOutputTables,
        ccc: RewritingComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): PushDetailScreenActionDescription | undefined {
        if (getIsMenu(desc)) {
            const menuID = getStringProperty(desc.menuID);
            if (menuID === undefined) return undefined;

            if (!ccc.requireMenuScreen(menuID)) return undefined;
        }

        return super.rewriteAfterReload(desc, tables, ccc, mutatingScreenKind);
    }

    public getTokenizedDescription(
        desc: PushDetailScreenActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const isMenu = getIsMenu(desc);
        if (isMenu) {
            return [{ kind: "string", value: "for menu" }];
        }

        const token = tokenForProperty(desc.sourceColumn, env);
        if (token !== undefined) {
            return [{ kind: "string", value: "for " }, token];
        }

        return [{ kind: "string", value: "for this item" }];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: PushDetailScreenActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { appKind },
        } = ib;

        let titleGetter: WireValueGetterGeneric<string> | undefined;
        if (appKind === AppKind.App) {
            titleGetter = inflateStringProperty(ib, desc.title, true)[0];
        }

        if (getIsMenu(desc) && appKind === AppKind.App) {
            const menuID = getStringProperty(desc.menuID);
            if (menuID === undefined) return arbBase.inflationError("Invalid menu");

            const primaryKeyColumn = ib.tables.input.rowIDColumn ?? rowIndexColumnName;
            const [primaryKeyGetter, primaryKeyType] = ib.getValueGetterForSourceColumn(
                makeSourceColumn(primaryKeyColumn),
                false,
                false
            );
            if (primaryKeyType === undefined) return arbBase.inflationError("Invalid menu");

            const defaultScreenName = classScreenName(getTableName(ib.tables.input));

            return (vp, skipLoading) => {
                let arb = arbBase.addData({ defaultScreenName, menuID });
                const row = vp.rowContext?.inputRows[0];
                if (row === undefined) return arb.error(true, "No row for screen data");
                arb = arb.addData({ rowID: row.$rowID });

                const maybePrimaryKey = primaryKeyGetter(vp);
                if (isLoadingValue(maybePrimaryKey)) return arb.maybeSkipLoading(skipLoading, "Primary key");
                const primaryKey = asString(maybePrimaryKey ?? "");
                if (primaryKey === "") return arb.error(true, "No primary key");

                const maybeTitle = titleGetter?.(vp) ?? "";
                const title = maybeTitle === "" ? undefined : maybeTitle;

                arb = arb.addData({ title, primaryKey });

                return async ab => {
                    ab.pushMenuScreen(defaultScreenName, menuID, primaryKey, row, title, row.$rowID);
                    return arb.success();
                };
            };
        } else {
            const maybeGetter = makeRowGetter(ib, desc.sourceColumn, { inOutputRow: false, defaultToThisRow: true });
            if (maybeGetter === undefined || maybeGetter === false) return arbBase.inflationError("No screen data");
            const { rowGetter, table } = maybeGetter;
            const tableName = getTableName(table);

            const target = navigationTargetPropertyHandlers.get(PageScreenTarget.Current).getEnum(desc);

            return (vp, skipLoading, defaultTitle) => {
                const arb = arbBase.addData({ tableName: sheetNameForTable(table), target });

                const rowValue = nullToUndefined(rowGetter(vp));
                if (isLoadingValue(rowValue)) return arb.maybeSkipLoading(skipLoading, "Data row");
                const row = definedMap(rowValue, asRow);
                if (row === undefined) return arb.error(true, "No row for screen");

                const maybeTitle = titleGetter?.(vp) ?? "";
                const title = maybeTitle === "" ? (appKind === AppKind.App ? defaultTitle : undefined) : maybeTitle;
                // The RowActionHydrationValueProvider might include a reference to a context which contains references
                // to actions. The returned function below is saved in the actions of the _next_ RowActionHydrationValueProvider,
                // so if we don't pull the rowContext out now, we end up with a memory leak.
                // This is currently possible because WireRowHydrationValueProvider.rowContext is readonly.
                const rowContext = vp.rowContext;

                return async ab => {
                    dispatchActivationProgressEvent("edit-details-page");
                    ab.pushDefaultClassScreen(tableName, row, title, target, rowContext?.inputRows[0]?.$rowID);
                    return arb.addData({ title, rowID: row.$rowID }).success();
                };
            };
        }
    }
}
