import { filterRecordByKey } from "@glide/support";

import { type QuotaDocument, isQuotaKind } from "./quotas";
import type { Database } from "./core";
import type { DocumentData } from ".";

export function quotasFromDocument(db: Database, data: DocumentData): QuotaDocument {
    data = db.convertFromDocument(data);
    // We've removed a quota kind at least once, but ##quotasArePersisted, so
    // we filter them here to make sure we only look at the "real" ones.
    // https://github.com/quicktype/glide/pull/20586
    return {
        quotas: filterRecordByKey(data.quotas, isQuotaKind),
        quotaVersions: filterRecordByKey(data.quotaVersions, isQuotaKind),
    };
}
