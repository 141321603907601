import type { AppDescription, BuilderAction } from "@glide/app-description";
import type { StartAutomationRunResponseBody } from "@glide/common-core";
import { defaultAppFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import type { ExistingAppDescriptionContext } from "@glide/function-utils";
import { makeSimpleAppDescriptionContext } from "@glide/generator/dist/js/components/simple-ccc";
import { AppKind } from "@glide/location-common";
import { decodeTypeSchema } from "@glide/type-schema";
import { defined } from "@glideapps/ts-necessities";

type AutomationAppDescriptionOptions = Pick<
    StartAutomationRunResponseBody,
    "sourceMetadata" | "pluginConfigs" | "appFeatures" | "schema" | "eminenceFlags" | "builderActions"
>;

export function makeAppDescriptionContextForAutomation(
    appID: string,
    {
        sourceMetadata,
        pluginConfigs,
        appFeatures: nonUserAppFeatures,
        schema: iotsSchema,
        eminenceFlags,
        builderActions,
    }: AutomationAppDescriptionOptions
): ExistingAppDescriptionContext {
    const schema = defined(decodeTypeSchema(iotsSchema));
    const appFeatures = { ...defaultAppFeatures, ...nonUserAppFeatures };

    const appDescription: AppDescription = {
        title: "The App",
        theme: {
            primaryAccentColor: "#000000",
            showTabLabels: true,
        },
        features: appFeatures,
        screenDescriptions: {},
        sourceMetadataArray: sourceMetadata,
        pluginConfigs,
    };

    return makeSimpleAppDescriptionContext(
        appID,
        AppKind.Page,
        appDescription,
        new Map((builderActions ?? []) as [string, BuilderAction][]),
        schema,
        appFeatures,
        eminenceFlags
    );
}
