import type { JSONObject } from "@glide/support";
import { hasOwnProperty } from "@glideapps/ts-necessities";

type OkResult<TResult = void> = TResult extends void
    ? {
          ok: true;
          result: undefined;
      }
    : {
          ok: true;
          result: TResult;
      };

export interface ErrorResult {
    readonly ok: false;
    readonly message: string;
    readonly data?: ResultStructuredData;
}

type ResultStructuredData = {
    // This should be set when the error is anything other than the plugin
    // input being incorrect.  Input being incorrect can show for example as a
    // 404 because the user picked a thing that doesn't exist.  A subtle
    // example of an error being a 404 and still a plugin error is when OpenAI
    // removed a model that the plugin offered.  OpenAI reported a 404, but
    // the error was in the plugin because it shouldn't have offered that
    // model.
    //
    // We use this in Honeycomb, but it won't show up as used in the code.
    isPluginError?: boolean;
    // Report this error to the user?
    showInBuilder?: boolean;
    // Permanent errors are in most cases the same as non-plugin errors.
    // Permanent errors are never retried in Automations.
    isPermanent?: boolean;

    // This flag is for automation configuration issues which we want to handle
    // specifically, in that they fail the Temporal workflow gracefully
    isAutomationConfigurationError?: boolean;
} & JSONObject;

export type Result<TResult = void> = OkResult<TResult> | ErrorResult;

class ResultClass {
    Ok(): OkResult<void>;
    Ok<T>(result: T): OkResult<T>;
    Ok(result?: any): OkResult<any> {
        return { ok: true, result };
    }
    Fail(message: string, data?: ResultStructuredData): ErrorResult {
        return { ok: false, message, data };
    }
    FailPermanent(message: string, data?: Omit<ResultStructuredData, "isPermanent">): ErrorResult {
        return { ok: false, message, data: { ...data, isPermanent: true } };
    }
    // `status` is assumed to be an error status code.  If `data` includes
    // `isPermanent`, then that overrides the status code.
    FailFromHTTPStatus(message: string, status: number, data?: ResultStructuredData): ErrorResult {
        const isPermanent = typeof data?.isPermanent === "boolean" ? data.isPermanent : status < 500;
        return { ok: false, message, data: { status, ...data, isPermanent } };
    }

    map<T extends {}, U>(r: Result<T>, fn: (x: T) => U): Result<U> {
        if (!r.ok) return r;
        const { result } = r;
        // FIXME get rid of cast – we need it because of the void case
        return Result.Ok(fn(result as T));
    }

    bind<T, U>(r: Result<T>, fn: (x: T) => Result<U>): Result<U> {
        if (!r.ok) return r;
        // FIXME get rid of cast – we need it because of the void case
        return fn(r.result as T);
    }

    unwrap<T extends {}>(r: Result<T>): T {
        if (!r.ok) throw new Error(r.message);
        // FIXME get rid of cast – we need it because of the void case
        return r.result as T;
    }
}

export const Result = new ResultClass();

export function isResult<T>(x: Result<T> | unknown): x is Result<T> {
    if (!hasOwnProperty(x, "ok")) return false;
    if (typeof x.ok !== "boolean") return false;
    if (x.ok) {
        if (!hasOwnProperty(x, "result")) return false;
    } else {
        if (!hasOwnProperty(x, "message")) return false;
    }
    return true;
}
