import { type LoadedGroundValue, type LoadedRow, isLoadingValue } from "@glide/computation-model-types";
import { type ActionDescription, type PropertyDescription, ActionKind } from "@glide/app-description";
import { sheetNameForTable } from "@glide/type-schema";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
    makeInlineTemplatePropertyDescriptor,
    getPrimitiveNonHiddenColumnsSpec,
    voiceEntryScreenName,
    type ActionAvailability,
} from "@glide/function-utils";
import {
    type WireActionHydrator,
    type WireActionInflationBackend,
    type WireActionResult,
    type WireActionResultBuilder,
    PageScreenTarget,
} from "@glide/wire";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { inflateStringProperty } from "../wire/utils";

/**
 * This action pushes a new slide-in with a special component to record voice,
 * and automatically get a transcript in a text input.
 * You can also just write to the transcript.
 */

export interface VoiceEntryActionDescription extends ActionDescription {
    readonly saveTranscriptTo: PropertyDescription;
    readonly autoRecord: PropertyDescription;
    readonly drawerTitle: PropertyDescription;
    readonly textareaPlaceholder: PropertyDescription;
}

const autoRecordHandler = new SwitchPropertyHandler({ autoRecord: true }, "Auto-record", PropertySection.Data);

const drawerTitleHandler = makeInlineTemplatePropertyDescriptor(
    "drawerTitle",
    "Title",
    "Record voice",
    false,
    "withLabel",
    undefined,
    {
        preferredNames: ["title"],
        searchable: false,
        applyFormat: false,
    }
);

const textareaPlaceholderHandler = makeInlineTemplatePropertyDescriptor(
    "textareaPlaceholder",
    "Placeholder",
    "Record or type",
    false,
    "withLabel",
    undefined,
    {
        preferredNames: ["placeholder"],
        searchable: false,
        applyFormat: false,
    }
);

const transcriptColumnPropertyHandler = new ColumnPropertyHandler(
    "saveTranscriptTo",
    "Save transcript to",
    [
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    ["notes", "transcript"],
    getPrimitiveNonHiddenColumnsSpec,
    "string",
    PropertySection.Data
);

type VoiceEntryRow = LoadedRow & {
    transcript: LoadedGroundValue;
    shouldSave: LoadedGroundValue;
    autoRecord: LoadedGroundValue;
    drawerTitle: LoadedGroundValue;
    textareaPlaceholder: LoadedGroundValue;
};

export class VoiceEntryActionHandler extends BaseActionHandler<VoiceEntryActionDescription> {
    public readonly kind = ActionKind.PushVoiceEntry;

    public readonly iconName: GlideIconProps = {
        kind: "monotone",
        icon: "mt-column-audio",
        // FIXME: How do these colors work?
        fgColor: ICON_PALE,
    };

    public readonly name = "Record and transcribe voice";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: VoiceEntryActionDescription | undefined,
        env: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const properties: PropertyDescriptor[] = [
            transcriptColumnPropertyHandler,
            drawerTitleHandler,
            textareaPlaceholderHandler,
            autoRecordHandler,
        ];

        const isEnabled = isExperimentEnabled("audioEntryAction", env.context.userFeatures);

        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 17,
            needsScreenContext: true,
            isLegacy: !isEnabled,
            properties,
        };
    }

    public getTokenizedDescription(
        desc: VoiceEntryActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const token = tokenForProperty(desc.saveTranscriptTo, env);
        if (token === undefined) return undefined;
        return [{ kind: "string", value: "into " }, token];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: VoiceEntryActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const { tableAndColumn: transcriptTAC, setterMaker: transcriptSetterMaker } = ib.getValueSetterForProperty(
            desc.saveTranscriptTo,
            "set-transcript"
        );
        if (transcriptTAC === undefined) return arb.inflationError("Invalid column to save transcript to");

        const [currentTranscriptGetter] = inflateStringProperty(ib, desc.saveTranscriptTo, true, { inOutputRow: true });
        const autoRecord = autoRecordHandler.getSwitch(desc);
        const [drawerTitleGetter] = inflateStringProperty(ib, desc.drawerTitle, true);
        const [textareaPlaceholderGetter] = inflateStringProperty(ib, desc.textareaPlaceholder, true);

        arb = arb.addData({ tableName: sheetNameForTable(ib.tables.output) });

        return (vp, skipLoading) => {
            const transcriptSetter = transcriptSetterMaker(vp);
            if (isLoadingValue(transcriptSetter)) {
                return arb.errorIfSkipLoading(skipLoading, "Transcript destination column");
            }
            if (transcriptSetter === undefined) {
                return arb.error(true, "No column to save transcript to");
            }

            const currentTranscript = currentTranscriptGetter(vp) ?? undefined;
            const drawerTitle = drawerTitleGetter(vp) ?? "Record voice";
            const textareaPlaceholder = textareaPlaceholderGetter(vp) ?? "Record or type";

            const row: VoiceEntryRow = {
                $rowID: makeRowID(),
                $isVisible: false,
                // The recording screen will set this value.
                transcript: currentTranscript,
                shouldSave: false,
                autoRecord,
                drawerTitle,
                textareaPlaceholder,
            };

            return ab => {
                return new Promise(resolve => {
                    try {
                        ab.addSpecialScreenRow(row);
                        ab.pushFreeScreen(
                            voiceEntryScreenName,
                            [row],
                            "",
                            PageScreenTarget.SlideIn,
                            undefined,
                            async () => {
                                try {
                                    if (row.shouldSave !== true) {
                                        return resolve(arb.success());
                                    }

                                    const transcriptResult = await transcriptSetter(ab, row.transcript);
                                    return resolve(arb.fromResult(transcriptResult));
                                } catch (e: unknown) {
                                    return resolve(arb.fromException(true, e));
                                }
                            }
                        );
                    } catch (e: unknown) {
                        return resolve(arb.fromException(true, e));
                    }
                });
            };
        };
    }
}
