import { useCallback, useEffect, useRef, useState } from "react";
import type { VoiceRecorderControls, VoiceRecorderOptions } from "./types";
import { isDefined } from "@glide/support";

const INITIAL_AUDIO_DATA_SIZE = 2048;
const INACTIVE_STATE = "inactive";
const RECORDING_STATE = "recording";
const SMOOTHING_TIME_CONSTANT = 0.8;
const FFT_SIZE = 2048;

const SUPPORTED_MIME_TYPES = ["audio/mp4", "audio/wav", "audio/ogg", "audio/webm"] as const;

function getSupportedMimeType(): string {
    // Safari typically supports audio/mp4
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari && MediaRecorder.isTypeSupported("audio/mp4")) {
        return "audio/mp4";
    }

    for (const mimeType of SUPPORTED_MIME_TYPES) {
        if (MediaRecorder.isTypeSupported(mimeType)) {
            return mimeType;
        }
    }

    // If no supported types found, fallback to WAV as it's widely supported
    return "audio/wav";
}

export function useVoiceRecorder(options: VoiceRecorderOptions = {}): VoiceRecorderControls {
    const { onRecordingComplete, onError } = options;

    const [state, setState] = useState<"inactive" | "recording">(INACTIVE_STATE);
    const isProcessingRef = useRef<boolean>(false);

    // Refs for audio context and nodes
    const audioContextRef = useRef<AudioContext | null>(null);
    const sourceNodeRef = useRef<MediaStreamAudioSourceNode | null>(null);
    const analyserNodeRef = useRef<AnalyserNode | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const chunksRef = useRef<Blob[]>([]);
    const startTimeRef = useRef<number>(0);
    const audioDataRef = useRef<Float32Array>(new Float32Array(INITIAL_AUDIO_DATA_SIZE));
    const isCancelledRef = useRef<boolean>(false);

    const cleanupResources = useCallback(() => {
        if (isDefined(mediaRecorderRef.current)) {
            if (mediaRecorderRef.current.state !== INACTIVE_STATE) {
                mediaRecorderRef.current.stop();
            }
            mediaRecorderRef.current = null;
        }

        if (isDefined(sourceNodeRef.current)) {
            sourceNodeRef.current.disconnect();
            sourceNodeRef.current = null;
        }

        if (isDefined(streamRef.current)) {
            const tracks = streamRef.current.getTracks();
            tracks.forEach(track => {
                track.stop();
                streamRef.current?.removeTrack(track);
            });
            streamRef.current = null;
        }

        if (isDefined(audioContextRef.current) && audioContextRef.current.state !== "closed") {
            void audioContextRef.current.close();
            audioContextRef.current = null;
        }

        chunksRef.current = [];
        setState(INACTIVE_STATE);
        audioDataRef.current = new Float32Array(INITIAL_AUDIO_DATA_SIZE);
        isProcessingRef.current = false;
    }, []);

    const checkAudioSupport = useCallback(() => {
        const support = {
            supported: true,
            reason: undefined as string | undefined,
        };

        if (!isDefined(navigator.mediaDevices?.getUserMedia)) {
            support.supported = false;
            support.reason = "MediaDevices API not supported";
        } else if (!isDefined(window.AudioContext) && !isDefined(window.webkitAudioContext)) {
            support.supported = false;
            support.reason = "AudioContext not supported";
        } else if (!isDefined(window.MediaRecorder)) {
            support.supported = false;
            support.reason = "MediaRecorder not supported";
        }

        return support;
    }, []);

    const initiateRecording = useCallback(async () => {
        try {
            cleanupResources();

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            streamRef.current = stream;

            const AudioContextClass = (window.AudioContext ?? window.webkitAudioContext) as typeof AudioContext;
            const audioContext = new AudioContextClass();
            audioContextRef.current = audioContext;

            const sourceNode = audioContext.createMediaStreamSource(stream);
            sourceNodeRef.current = sourceNode;

            const analyserNode = audioContext.createAnalyser();
            analyserNode.fftSize = FFT_SIZE;
            analyserNode.smoothingTimeConstant = SMOOTHING_TIME_CONSTANT;
            analyserNodeRef.current = analyserNode;

            sourceNode.connect(analyserNode);

            let animationFrame: number;
            const updateAudioData = () => {
                if (analyserNodeRef.current !== null && state === RECORDING_STATE) {
                    const dataArray = new Float32Array(analyserNode.frequencyBinCount);
                    analyserNode.getFloatTimeDomainData(dataArray);
                    audioDataRef.current = dataArray;
                    animationFrame = requestAnimationFrame(updateAudioData);
                }
            };
            updateAudioData();

            const cleanupAnimationFrame = () => {
                if (typeof animationFrame === "number") {
                    cancelAnimationFrame(animationFrame);
                }
            };

            const mimeType = getSupportedMimeType();
            const mediaRecorder = new MediaRecorder(stream, { mimeType });
            mediaRecorderRef.current = mediaRecorder;

            mediaRecorder.ondataavailable = (event: BlobEvent) => {
                if (event.data.size > 0) {
                    chunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                // Prevent multiple executions
                if (isProcessingRef.current) return;
                isProcessingRef.current = true;

                cleanupAnimationFrame();

                const blob = new Blob(chunksRef.current, { type: mimeType });

                // Create a File object and call onRecordingComplete only if not cancelled
                if (!isCancelledRef.current && isDefined(onRecordingComplete)) {
                    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
                    const extension = mimeType.split("/")[1];
                    const file = new File([blob], `recording-${timestamp}.${extension}`, { type: mimeType });
                    if (file.size > 0) {
                        onRecordingComplete(file);
                    }
                }
                isCancelledRef.current = false;

                // Clean up resources after ensuring all data is processed
                cleanupResources();
            };

            startTimeRef.current = Date.now();
            setState(RECORDING_STATE);
            mediaRecorder.start(1000);
        } catch (error: unknown) {
            onError?.(error as Error);
            cleanupResources();
        }
    }, [cleanupResources, onError, onRecordingComplete, state]);

    const terminateRecording = useCallback(
        (isCancelled = false) => {
            if (state === RECORDING_STATE) {
                isCancelledRef.current = isCancelled;

                if (isDefined(mediaRecorderRef.current)) {
                    // Request final data before stopping
                    if (mediaRecorderRef.current.state !== INACTIVE_STATE) {
                        mediaRecorderRef.current.requestData();
                    }

                    // Stop the recorder - cleanup will happen in the onstop handler
                    mediaRecorderRef.current.stop();
                } else {
                    cleanupResources();
                }
            }
        },
        [cleanupResources, state]
    );

    const calculateDuration = useCallback(() => {
        return state === INACTIVE_STATE ? 0 : Date.now() - startTimeRef.current;
    }, [state]);

    const getFormattedTime = useCallback(() => {
        if (state === INACTIVE_STATE) return "00:00";

        const duration = calculateDuration();
        const seconds = Math.floor((duration / 1000) % 60);
        const minutes = Math.floor((duration / (1000 * 60)) % 60);
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }, [state, calculateDuration]);

    const clearChunks = useCallback(() => {
        chunksRef.current = [];
    }, []);

    const getStream = useCallback(() => {
        return streamRef.current;
    }, []);

    const getAudioData = useCallback(() => {
        if (analyserNodeRef.current !== null && state === RECORDING_STATE) {
            const dataArray = new Float32Array(analyserNodeRef.current.frequencyBinCount);
            analyserNodeRef.current.getFloatTimeDomainData(dataArray);
            audioDataRef.current = dataArray;
        }
        return audioDataRef.current;
    }, [state]);

    const getPartialRecording = useCallback(() => {
        const mimeType = getSupportedMimeType();
        const blob = new Blob(chunksRef.current, { type: mimeType });
        const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
        const extension = mimeType.split("/")[1];
        return new File([blob], `recording-${timestamp}.${extension}`, { type: mimeType });
    }, []);

    useEffect(() => {
        return () => {
            cleanupResources();
        };
    }, [cleanupResources]);

    return {
        state,
        getFormattedTime,
        startRecording: initiateRecording,
        stopRecording: terminateRecording,
        clear: clearChunks,
        checkSupport: checkAudioSupport,
        getStream,
        getAudioData,
        getPartialRecording,
    };
}

declare global {
    interface Window {
        webkitAudioContext: typeof AudioContext;
    }
}
