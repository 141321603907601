import type { UpgradeComponentLocation } from "@glide/common-core/dist/js/analytics/app-events";
import type { PlanKind, Subscription } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import type { SubscriptionContextProps } from "../context";
import { canCurrentPlanPassGate } from "./can-pass-gate";
import { getPlanInfoForSubscription } from "./get-plan-for-subscription";

export async function unlockPayloadPassthrough(
    targetPlan: PlanKind,
    subscription: Subscription,
    showUpgradeModal: SubscriptionContextProps["showUpgradeModal"],
    location?: UpgradeComponentLocation,
    f: () => void = () => undefined
) {
    const plan = getPlanInfoForSubscription(subscription);
    const allowed = canCurrentPlanPassGate(plan.planKind, targetPlan);

    if (allowed) {
        f();
    } else {
        await showUpgradeModal({ unsubscribeAppPlans: false, upsell: true, targetPlan, location });
    }
}
