import { useCallback, useState } from "react";
import { styled } from "@glide/common";
import WindowedModalV2, { WindowedModalButtonBar } from "../windowed-modal/windowed-modal";

const VerifyEmailStyle = styled.div`
    padding: 24px 0;
    width: 380px;

    .title {
        font-size: 16px;
        margin-bottom: 8px;
        font-weight: 800;
    }

    .info {
        color: ${p => p.theme.textPale};
        font-size: 14px;
        margin-bottom: 16px;
    }
`;

interface Props extends React.PropsWithChildren {
    onClose(): void;
    children: string;
    resendVerificationEmail: () => Promise<void>;
}

export const VerifyEmailModal: React.FC<Props> = p => {
    const { onClose, children, resendVerificationEmail } = p;

    const [isSending, setIsSending] = useState(false);

    const onResendClick = useCallback(async () => {
        setIsSending(true);
        await resendVerificationEmail();
        onClose();
    }, [onClose, resendVerificationEmail]);

    return (
        <WindowedModalV2 onClose={p.onClose} canClose={false}>
            <VerifyEmailStyle>
                <h2 className="title">Please verify your email!</h2>
                <p className="info">{children}</p>
                <WindowedModalButtonBar
                    align="right"
                    buttons={[
                        {
                            title: "Close",
                            type: "secondary",
                            variant: "default",
                            onClick: onClose,
                        },
                        {
                            title: "Resend verification email",
                            type: "primary",
                            variant: "default",
                            onClick: onResendClick,
                            disabled: isSending,
                        },
                    ]}
                />
            </VerifyEmailStyle>
        </WindowedModalV2>
    );
};
