import { type LoadedRow, isLoadingValue, type Unbound, isBound } from "@glide/computation-model-types";
import { asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import {
    ActionKind,
    ArrayScreenFormat,
    PropertyKind,
    getActionProperty,
    getColumnProperty,
    getEnumProperty,
    getSwitchProperty,
} from "@glide/app-description";
import { SourceColumnKind, getTableName, makeSourceColumn } from "@glide/type-schema";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import {
    type WireCommentItem,
    type WireCommentsComponent,
    CommentsStyle,
} from "@glide/fluent-components/dist/js/base-components";
import { CommentsCollection } from "@glide/fluent-components/dist/js/fluent-components";
import {
    FluentArrayContent,
    type WireListComponentGeneric,
} from "@glide/fluent-components/dist/js/fluent-components-spec";
import type { PropertyDescriptorConstructor } from "@glide/fluent-components/dist/js/fluent-properties-spec";
import { type InlineListComponentDescription, getInlineListPropertyTable } from "@glide/function-utils";
import { isDefined, logInfo } from "@glide/support";
import { type WireAction, WireActionResult, ValueChangeSource, WireComponentKind } from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import { makeEditedColumnsFromColumnAssignments } from "../actions/add-row";
import { getColumnAssignments } from "../description-utils";
import {
    hydrateOnSubmitAction,
    inflateActions,
    inflateActionsWithTitles,
    inflateColumnAssignments,
    inflateComponentEnricher,
    inflateDateTimeProperty,
    inflateImageProperty,
    inflateNumberProperty,
    inflateStringProperty,
    inflateSwitchWithCondition,
    makeSimpleWireTableComponentHydratorConstructor,
} from "../wire/utils";
import { makeFluentArrayContentHandler } from "./fluent-array-handler";
import { hydrateOutputValueGetters } from "./fluent-components-handlers";

type InferFluentArrayContent<T> = T extends FluentArrayContent<
    infer U,
    infer V,
    WireListComponentGeneric<ArrayScreenFormat.Comments>
>
    ? { T: U; V: V }
    : never;

type FluentCommentCollectionTypes = InferFluentArrayContent<typeof CommentsCollection>;

const makeCommentCollectionWithPostCommentAction = () => {
    const makePostCommentActionDescriptor: PropertyDescriptorConstructor = ({ actionKinds: primitiveActionKinds }) => {
        return {
            kind: PropertyKind.Action,
            property: { name: "onCommentAdded" },
            label: "On post comment",
            kinds: primitiveActionKinds,
            getIndirectTable: (tables, rootDesc, _desc, schema) => {
                const sourceProperty = (rootDesc as any).propertyName;
                return getInlineListPropertyTable(tables?.input, sourceProperty, schema);
            },
            section: { name: "After submit action", order: 1 },
            defaultAction: () => ({ kind: ActionKind.ShowToast }),
        };
    };

    return new FluentArrayContent<
        FluentCommentCollectionTypes["T"],
        FluentCommentCollectionTypes["V"] & { onCommentAdded: WireAction | Unbound | undefined },
        WireListComponentGeneric<ArrayScreenFormat.Comments>
    >({
        ...CommentsCollection.spec,
        componentProperties: {
            ...CommentsCollection.spec.componentProperties,
            actionSpecs: [
                ...CommentsCollection.spec.componentProperties.actionSpecs,
                {
                    name: "onCommentAdded",
                    descriptorConstructor: makePostCommentActionDescriptor,
                    showIfCollectionEmpty: false,
                },
            ],
        },
    });
};

export const commentsFluentArrayContentHandler = makeFluentArrayContentHandler(
    makeCommentCollectionWithPostCommentAction(),
    (ib, desc, containingRowIB, _componentID, _filter, additionalTargetColumnsNames) => {
        if (containingRowIB === undefined) return undefined;

        const userProfilePhotoColumnName = ib.adc.userProfileTableInfo?.imageColumnName;
        const userProfileEmailColumnName = ib.adc.userProfileTableInfo?.emailColumnName;
        const userProfileNameColumnName = ib.adc.userProfileTableInfo?.nameColumnName;

        const componentStyle = getEnumProperty<CommentsStyle>(desc.componentStyle) ?? CommentsStyle.Comments;

        const [userPhotoGetter] =
            definedMap(userProfilePhotoColumnName, c =>
                ib.getValueGetterForSourceColumn(makeSourceColumn(c, SourceColumnKind.UserProfile), false, true)
            ) ?? [];

        const [userEmailGetter] =
            definedMap(userProfileEmailColumnName, c =>
                ib.getValueGetterForSourceColumn(makeSourceColumn(c, SourceColumnKind.UserProfile), false, true)
            ) ?? [];

        const [userNameGetter] =
            definedMap(userProfileNameColumnName, c =>
                ib.getValueGetterForSourceColumn(makeSourceColumn(c, SourceColumnKind.UserProfile), false, true)
            ) ?? [];

        const tableName = getTableName(ib.tables.output);

        const [titleGetter] = inflateStringProperty(containingRowIB, desc.title, true);
        const [emptyMessageGetter] = inflateStringProperty(containingRowIB, desc.emptyMessage, true);
        const [buttonTextGetter] = inflateStringProperty(containingRowIB, desc.buttonText, true);
        const [pageSizeGetter] = inflateNumberProperty(containingRowIB, desc.pageSize);

        const [itemActionsHydrator] = inflateActionsWithTitles(ib, desc.itemActions, "item");

        const [commentGetter] = inflateStringProperty(ib, desc.comment, true);
        const [timestampGetter] = inflateDateTimeProperty(ib, desc.timestamp);
        const [userNameCommentGetter] = inflateStringProperty(ib, desc.userName, true);
        const [userEmailCommentGetter] = inflateStringProperty(ib, desc.userEmail, true);
        const [userPhotoCommentGetter] = inflateImageProperty(ib, desc.userPhoto);

        const commentTimestampColumnName = getColumnProperty(desc.timestamp);
        const userNameColumnName = getColumnProperty(desc.userName);
        const userPhotoColumnName = getColumnProperty(desc.userPhoto);
        const saveCommentColumnName = getColumnProperty(desc.saveComment);

        if (
            !isDefined(saveCommentColumnName) ||
            !isDefined(commentTimestampColumnName) ||
            !isDefined(userNameColumnName) ||
            !isDefined(userPhotoColumnName)
        )
            return undefined;

        const { tables } = ib;
        const columnAssignments = getColumnAssignments(desc).filter(a =>
            additionalTargetColumnsNames.has(a.destColumn)
        );
        const columnAssignmentGetters = inflateColumnAssignments(
            containingRowIB,
            tables.output,
            columnAssignments,
            true
        );

        const componentEnricher = inflateComponentEnricher<WireCommentsComponent>(
            ib,
            desc as unknown as InlineListComponentDescription
        );

        const onCommentAddedAction = getActionProperty(desc.onCommentAdded);
        const onCommentAddedHydrator = definedMap(onCommentAddedAction, action =>
            inflateActions(ib, [action], true, undefined)
        );

        const allowAddGetter = inflateSwitchWithCondition(containingRowIB, desc.allowAdd, true);

        return makeSimpleWireTableComponentHydratorConstructor(ib, (hb, chb) => {
            if (chb === undefined) return undefined;

            const rows = hb.tableScreenContext.asArray();

            const newComment = chb.getState("newComment", isString, "", true);
            const currentPageIndex = chb.getState("currentPageIndex", isNumber, 0, true);
            const pageSize = pageSizeGetter(chb) ?? 16;

            const numPages = Math.ceil(rows.length / pageSize);

            let userPhoto: string | undefined;
            let userEmail: string | undefined;
            let userName: string | undefined;

            if (userPhotoGetter !== undefined) {
                const maybeUserPhoto = userPhotoGetter(chb);
                if (isBound(maybeUserPhoto) && !isLoadingValue(maybeUserPhoto)) {
                    userPhoto = asMaybeString(maybeUserPhoto);
                }
            }

            if (userEmailGetter !== undefined) {
                const maybeUserEmail = userEmailGetter(chb);
                if (isBound(maybeUserEmail) && !isLoadingValue(maybeUserEmail)) {
                    userEmail = asMaybeString(maybeUserEmail);
                }
            }

            if (userNameGetter !== undefined) {
                const maybeUserName = userNameGetter(chb);
                if (isBound(maybeUserName) && !isLoadingValue(maybeUserName)) {
                    userName = asMaybeString(maybeUserName);
                }
            }

            const rowID = makeRowID();
            let rowToAdd: LoadedRow = {
                $rowID: rowID,
                $isVisible: false,
            };

            const onCommentAddedResult = definedMap(onCommentAddedHydrator, submitHydrator =>
                hydrateOnSubmitAction(submitHydrator, () => chb.makeHydrationBackendForRow(rowToAdd, undefined, tables))
            );

            const hydratedColumnAssignments = hydrateOutputValueGetters(chb, columnAssignmentGetters);

            const allowAdd = allowAddGetter(chb) === true;
            let token;
            if (allowAdd) {
                token = chb.registerAction("addComment", async ab => {
                    logInfo("New comment", newComment.value);
                    rowToAdd[saveCommentColumnName] = newComment.value;

                    rowToAdd = {
                        ...rowToAdd,
                        ...hydratedColumnAssignments,
                    };

                    ab.valueChanged(newComment.onChangeToken, "", ValueChangeSource.Internal);

                    const addResult = await ab.addRow(tableName, rowToAdd, undefined, true);
                    if (!addResult.ok) return WireActionResult.fromResult(addResult);
                    const newRow = addResult.result;

                    if (isDefined(onCommentAddedResult) && onCommentAddedResult !== false) {
                        const onSubmitAB = ab.makeActionBackendForOnSubmit(newRow);
                        const actionSucceeded = await onSubmitAB.invoke("", onCommentAddedResult, false);
                        return actionSucceeded;
                    }

                    return WireActionResult.nondescriptSuccess();
                });
            }

            let loggedUser: WireCommentsComponent["loggedUser"];

            if (isDefined(userEmail) || isDefined(userPhoto)) {
                loggedUser = { userEmail, userPhoto, userName };
            }

            const paginationToken = chb.registerAction("showMore", async ab => {
                const nextIndex = Math.min(numPages, currentPageIndex.value + 1);
                return ab.valueChanged(currentPageIndex.onChangeToken, nextIndex, ValueChangeSource.User);
            });

            const sliceIndex = Math.min(rows.length, (currentPageIndex.value + 1) * pageSize);
            const component: WireCommentsComponent = componentEnricher({
                kind: WireComponentKind.List,
                format: ArrayScreenFormat.Comments,
                componentStyle,
                title: titleGetter(chb),
                emptyMessage: emptyMessageGetter(chb),
                buttonText: buttonTextGetter(chb),
                items: rows.slice(0, sliceIndex).map(row => {
                    const rhb = hb.makeHydrationBackendForRow(row);

                    const commentUserEmail = userEmailCommentGetter(rhb);

                    const commentRow: WireCommentItem = {
                        comment: commentGetter(rhb),
                        timestamp: timestampGetter(rhb),
                        itemActions: itemActionsHydrator?.(rhb, row.$rowID) ?? [],
                        userName: userNameCommentGetter(rhb),
                        userPhoto: userPhotoCommentGetter(rhb),
                        userEmail: commentUserEmail,
                        isSelfComment: loggedUser?.userEmail === commentUserEmail,
                    };

                    return commentRow;
                }),
                newComment,
                postNewComment: { token },
                loggedUser,
                showMoreComments: sliceIndex === rows.length ? undefined : { token: paginationToken },
                allowAdd: allowAdd === true,
            });
            return {
                component,
                isValid: true,
            };
        });
    },
    (desc, _tables, itemTable, adc) => {
        if (itemTable === undefined) return undefined;
        const allowAdd = getSwitchProperty(desc.allowAdd) === true;
        let editedColumns;
        if (allowAdd) {
            editedColumns = makeEditedColumnsFromColumnAssignments(
                {
                    assignments: getColumnAssignments(desc),
                    table: itemTable,
                    isAddRow: true,
                },
                false,
                adc
            );
        }
        return { editedColumns: editedColumns ?? [], deletedTables: [] };
    }
);
