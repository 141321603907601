import { GlideIcon, useIsWireThemeDark, useResizeDetector } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import { type WireTab, UIButtonAppearance } from "@glide/wire";
import classNames from "classnames";
import { css } from "styled-components";
import tw from "twin.macro";

import { WireButton } from "../../renderers/wire-button/wire-button";
import { WireContainer, WireContainerSpacing } from "../../renderers/wire-container/wire-container";
import { TabIcon, getContainerTheme } from "./nav-bar-common";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";

interface DesktopNavBarProps {
    readonly containerRef: React.Ref<HTMLDivElement>;
    readonly className?: string;
    readonly navigateToRoot: (() => void) | undefined;
    readonly logoAndTitle: React.ReactNode;
    readonly onSignInPressed: (() => void) | undefined;
    readonly onSignUpPressed: (() => void) | undefined;
    readonly userButton: React.ReactNode;
    readonly selected: WireTab | undefined;
    readonly onSelectionChange: (newTab: WireTab) => void;
    readonly tabs: { primary: readonly WireTab[]; secondary: readonly WireTab[] };
}

export const DesktopNavBar: React.VFC<DesktopNavBarProps> = p => {
    const {
        containerRef,
        className,
        navigateToRoot,
        logoAndTitle,
        onSignInPressed,
        onSignUpPressed,
        userButton,
        selected,
        onSelectionChange,
        tabs,
    } = p;

    const theme = useWireAppTheme();
    const isWireThemeDark = useIsWireThemeDark(theme.pageEnvironment);

    const containerTheme = getContainerTheme(theme.pageTheme, false);

    const { ref: headerRef, width: headerWidth = 0 } = useResizeDetector();
    const { ref: navRef, width: navWidth = 0 } = useResizeDetector();
    const { ref: sideContentRef, width: sideContentWidth = 0 } = useResizeDetector();

    const outOfSpace = navWidth + 2 * Math.max(120, sideContentWidth) > headerWidth;

    return (
        <WireContainer
            ref={containerRef}
            spacing={WireContainerSpacing.collapsed}
            ignoreHighlight={true}
            background={containerTheme}
            className={classNames(isWireThemeDark || theme.pageTheme !== "Highlight" ? "dark" : "")}
            css={css`
                box-shadow: 0 1px 0 ${theme.n200A};
                @supports (-webkit-touch-callout: none) {
                    @media (hover: none) and (pointer: coarse) and (display-mode: standalone) {
                        & .section-container {
                            padding-top: var(--safe-area-inset-top);
                        }
                    }
                }

                &.dark {
                    box-shadow: 0 1px 0 ${theme.w05A};
                }
            `}>
            <header
                ref={headerRef}
                className={classNames(className, outOfSpace ? "bottom-nav" : "inline-nav")}
                tw="grid overflow-hidden relative w-full text-text-contextual-dark"
                css={css`
                    &.inline-nav {
                        grid-template-columns: 1fr auto 1fr;
                        grid-template-areas: "lef nav rig";
                    }

                    &.bottom-nav {
                        grid-template-columns: minmax(0, 1fr) auto;
                        grid-template-areas:
                            "lef rig"
                            "nav nav";
                    }
                `}>
                <div
                    onClick={navigateToRoot}
                    className={classNames(navigateToRoot !== undefined ? "active" : "")}
                    tw="h-14 flex items-center shrink-0 font-semibold text-lg mr-6 truncate grid-area[lef] min-w-0"
                    css={css`
                        &.active {
                            ${tw`cursor-pointer`}
                        }
                    `}>
                    {logoAndTitle}
                </div>
                <nav
                    ref={navRef}
                    className="scrollbar-hide main-nav"
                    tw="[min-height:56px] flex gap-1 overflow-x-auto items-center [width:fit-content] max-w-full min-w-0
                        grid-area[nav]
                        gp-lg:gap-2
                        gp-xl:gap-3
                        px-0.5
                        ">
                    <NavRegion tabs={tabs} onSelectionChange={onSelectionChange} selected={selected} />
                </nav>
                <div
                    ref={sideContentRef}
                    id="nav-bar-button-region"
                    tw="h-12 flex justify-end justify-self-end items-center grid-area[rig] min-w-0 [width:fit-content]
                        gp-md:h-14">
                    {onSignInPressed !== undefined && (
                        <WireButton
                            data-testid="sign-in-button"
                            onClick={onSignInPressed}
                            appearance={UIButtonAppearance.Bordered}
                            tw="mr-px shrink-0 not-last:mr-2">
                            {getLocalizedString("signIn", AppKind.Page)}
                        </WireButton>
                    )}
                    {onSignUpPressed !== undefined && (
                        <WireButton
                            onClick={onSignUpPressed}
                            size="sm"
                            appearance={UIButtonAppearance.Filled}
                            tw="shrink-0 not-last:mr-2">
                            {getLocalizedString("signUp", AppKind.Page)}
                        </WireButton>
                    )}
                    {userButton}
                </div>
            </header>
        </WireContainer>
    );
};

interface NavRegionProps {
    readonly tabs: { primary: readonly WireTab[]; secondary: readonly WireTab[] };
    readonly onSelectionChange: (tab: WireTab) => void;
    readonly selected: WireTab | undefined;
}

const NavRegion: React.VFC<NavRegionProps> = p => {
    const { tabs, onSelectionChange, selected } = p;

    const theme = useWireAppTheme();
    const isWireThemeDark = useIsWireThemeDark(theme.pageEnvironment);

    const pageThemeClass = (theme.pageTheme ?? "accent").toLowerCase();
    const pageEnvironmentClass = isWireThemeDark ? "dark-system-theme" : "light-system-theme";

    const hidePrimary = tabs.secondary.length === 0 && tabs.primary.length === 1;

    return (
        <>
            {hidePrimary
                ? null
                : tabs.primary.map((t, index) => {
                      return (
                          <button
                              type="button"
                              onClick={() => {
                                  onSelectionChange?.(t);
                              }}
                              className={classNames(selected === t && "selected", pageThemeClass, pageEnvironmentClass)}
                              css={css`
                                  ${tw`font-medium text-text-contextual-pale`}

                                  &.selected {
                                      ${tw`text-text-contextual-dark`}
                                  }

                                  &.light-system-theme.highlight,
                                  &.light-system-theme.neutral {
                                      ${tw`hover:bg-n50A`}
                                      &.selected {
                                          ${tw`bg-n100A`}
                                      }
                                  }

                                  &.accent,
                                  &.dark,
                                  &.dark.light-system-theme,
                                  &.dark.dark-system-theme,
                                  &.dark-system-theme.highlight,
                                  &.dark-system-theme.neutral {
                                      ${tw`hover:bg-w05A`}
                                      &.selected {
                                          ${tw`bg-w10A`}
                                      }
                                  }
                              `}
                              tw="px-2.5 py-2 rounded-lg transition duration-75 cursor-pointer
                        whitespace-nowrap focus-visible:(ring-2 ring-offset-1
                        ring-n0A) text-left text-base flex justify-center items-center"
                              key={index}>
                              {theme.showIconsInNavBar === true && <TabIcon tw="relative mr-2" icon={t.icon} />}
                              <div>{t.title}</div>
                          </button>
                      );
                  })}
            {tabs.secondary.length > 0 && (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <button
                            type="button"
                            css={css`
                                ${tw`font-medium text-text-contextual-pale`}
                                &.selected {
                                    .dropdown-more {
                                        ${tw`text-text-contextual-dark`}
                                    }
                                }

                                &.light-system-theme.highlight,
                                &.light-system-theme.neutral {
                                    ${tw`hover:bg-n50A`}
                                    &.selected {
                                        ${tw`bg-n100A`}
                                    }
                                }

                                &.accent,
                                &.dark,
                                &.dark.light-system-theme,
                                &.dark.dark-system-theme,
                                &.dark-system-theme.highlight,
                                &.dark-system-theme.neutral {
                                    ${tw`hover:bg-w05A`}
                                    &.selected {
                                        ${tw`bg-w10A`}
                                    }
                                }
                            `}
                            className={classNames(
                                tabs?.secondary?.some(tab => tab.isActive) && "selected",
                                pageThemeClass,
                                pageEnvironmentClass
                            )}
                            tw="flex gap-x-2.5 items-center justify-center px-2.5 py-2 text-base font-medium tracking-tight rounded-lg cursor-pointer hover:bg-n100A whitespace-nowrap transition duration-75 focus-visible:(ring-2 ring-offset-1
                                ring-n0A)">
                            {theme.showIconsInNavBar === true && (
                                <TabIcon tw="relative" icon="path:/svg/stroke/st-burger.svg" />
                            )}
                            <div
                                tw="flex relative justify-center items-center w-full h-full"
                                className={"dropdown-more"}>
                                <div tw="font-[inherit] flex justify-center items-center capitalize">
                                    {getLocalizedString("more", AppKind.Page)}
                                </div>
                                <GlideIcon icon="st-chevron-down" kind="stroke" iconSize={16} tw="ml-1" />
                            </div>
                        </button>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent
                        tw="flex z-10 flex-col gap-y-1 p-1 rounded-lg bg-n0 shadow-xl-dark min-w-[192px] max-h-[300px] overflow-y-auto"
                        align="start"
                        sideOffset={4}>
                        {tabs.secondary.map((t, index) => {
                            return (
                                <DropdownMenuItem
                                    key={index}
                                    css={css`
                                        &.selected {
                                            ${tw`bg-n100A`}
                                        }
                                    `}
                                    className={classNames(t.isActive && "selected")}
                                    tw="flex gap-x-2.5 items-center justify-start px-2.5 py-2 text-base font-medium tracking-tight rounded-md cursor-pointer text-n700A hover:bg-n100A whitespace-nowrap transition duration-75 focus-visible:(ring-2 ring-offset-1 ring-n0A)"
                                    onSelect={() => {
                                        onSelectionChange?.(t);
                                    }}>
                                    {theme.showIconsInNavBar === true && (
                                        <TabIcon tw="relative" size={16} icon={t.icon} />
                                    )}
                                    <div>{t.title}</div>
                                </DropdownMenuItem>
                            );
                        })}
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </>
    );
};
