import { exceptionToError } from "@glideapps/ts-necessities";

interface Request<T> {
    readonly run: () => Promise<T>;
    readonly resolve: (result: T) => void;
    readonly reject: (e: Error) => void;
}

export class SyncJobQueue {
    private readonly queue: Request<any>[] = [];
    private promise: Promise<void> | undefined;
    private running: boolean = false;

    private async work(): Promise<void> {
        for (;;) {
            const item = this.queue.shift();
            if (item === undefined) break;

            const { run, resolve, reject } = item;
            this.running = true;

            try {
                const ret = await run();
                this.running = false;
                resolve(ret);
            } catch (e: unknown) {
                this.running = false;
                reject(exceptionToError(e));
            }
        }

        // Since we don't want to hang around forever servicing requests, we'll "shut ourselves down"
        // and signal to clients that they need to start another one.
        this.promise = undefined;
    }

    public async run<T>(fn: () => Promise<T>): Promise<T> {
        return new Promise((resolve, reject) => {
            this.queue.push({ run: fn, resolve, reject });
            // Servicing Promises don't stick around forever, because there's no good
            // (documented) condition variable equivalent in JavaScript. They do, however,
            // signal when they're done by removing themselves from this Map.
            // So if they aren't in the Map, they're not running, and we need to start one up.
            if (this.promise === undefined) {
                this.promise = this.work();
            }
        });
    }

    public hasOutstandingWork(): boolean {
        return this.queue.length > 0 || this.running;
    }
}
