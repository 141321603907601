import { type CellValue, type ColumnCellValues, GlideDateTime, GlideJSON } from "@glide/data-types";
import type { ParameterPropsBase, ParameterRecord, UnwrapGeneric } from "@glide/plugins";
import { mapRecord } from "@glideapps/ts-necessities";
import { isEmptyOrUndefined } from "@glide/support";
import { convertValueToJSONValue } from "../json-utils";

// This only exist for stringifying a result 'json' type.
export function pluginResultToGlideTypeWithParameterProps(
    resultsParameter: Record<string, ParameterPropsBase>,
    executionResult: Partial<UnwrapGeneric<ParameterRecord>>
): ColumnCellValues {
    const result: ColumnCellValues = {};
    for (const [resultName, resultValue] of Object.entries(resultsParameter)) {
        const maybeJSONValue = executionResult[resultName];
        if (maybeJSONValue === undefined) continue;

        const resultType = resultValue.type;
        if (resultType === "json") {
            if (typeof maybeJSONValue === "string" && isEmptyOrUndefined(maybeJSONValue)) {
                continue;
            }

            try {
                const jsonValue = convertValueToJSONValue(maybeJSONValue);
                result[resultName] = jsonValue;
            } catch (error: unknown) {
                // Do nothing
            }
        } else {
            function convert(v: (typeof executionResult)[string]): CellValue | undefined {
                if (v === undefined || v === null) return undefined;
                if (typeof v === "string" || typeof v === "number" || typeof v === "boolean") return v;
                if (v instanceof GlideDateTime || v instanceof GlideJSON) return v;
                // I don't understand why `isArray` doesn't work here
                if (v instanceof Array) {
                    const arr: CellValue[] = [];
                    for (const x of v) {
                        const y = convert(x);
                        if (y === undefined) continue;
                        arr.push(y);
                    }
                    return arr;
                }
                return undefined;
            }

            const converted = convert(maybeJSONValue);
            if (converted === undefined) continue;
            result[resultName] = converted;
        }
    }

    return result;
}

export function pluginResultToGlideType(
    resultsParameter: ParameterRecord | undefined,
    executionResult: Partial<UnwrapGeneric<ParameterRecord>>
): ColumnCellValues {
    if (resultsParameter === undefined) return {};

    return pluginResultToGlideTypeWithParameterProps(
        mapRecord(resultsParameter, p => p.props),
        executionResult
    );
}
