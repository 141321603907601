import { GlideIcon } from "@glide/common";
import type { GlideIconProps, PluginTierList, TriggerProps } from "@glide/plugins-codecs";
import { Modal } from "../windowed-modal-next";
import "twin.macro";
import type { Owner } from "@glide/common-core/dist/js/Database";
import type { AutomationTriggerKind } from "@glide/automations-codecs";
import { useEminenceFlags } from "@glide/common-components";
import { useCallback } from "react";
import { type Subscription, PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { UpgradePillBadge } from "../../paywall/upgrade-pill";
import type { UpgradeModalConfig } from "../../paywall/upgrade-utils";
import type { SimpleModalContextProps } from "../../modal-provider-v2/modal-context";
import { Button } from "../../button/button";
import { getRequiredPlan } from "@glide/component-utils";
import {
    CONTACT_SALES_URL,
    useCanUseAutomationsEditorHelpers,
} from "../../../hooks/use-can-use-automations-editor-helpers";
import { defaultEminenceFlagsValues } from "@glide/billing-types";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { defaultUserFeatures } from "@glide/app-description";

export interface ConfiguredPluginTrigger {
    readonly pluginID: string;
    // If this is `undefined`, then the plugin isn't configured yet
    readonly pluginConfigID: string | undefined;
    readonly trigger: TriggerProps & { icon: string | GlideIconProps };
    readonly badge?: string;
    readonly tier?: PluginTierList;
}

interface Props {
    onClose: () => void;
    onSelect: (kind: AutomationTriggerKind, pluginTrigger: ConfiguredPluginTrigger | undefined) => void;
    owner: Owner | undefined;
    pluginTriggers: readonly ConfiguredPluginTrigger[];
    showUpgradeModal: (
        modals: SimpleModalContextProps,
        owner: Owner,
        config: UpgradeModalConfig,
        subscription?: Subscription
    ) => Promise<void>;
}

interface OptionProps {
    onSelect: (kind: AutomationTriggerKind, pluginTrigger: ConfiguredPluginTrigger | undefined) => void;
    icon: string | GlideIconProps;
    title: string;
    description: string;
    kind: AutomationTriggerKind;
    pluginTrigger: ConfiguredPluginTrigger | undefined;
    shouldUpgrade: boolean;
    isEnterprise: boolean;
    badge?: string;
    targetPlan?: PlanKind;
}

const Badge: React.FC<{ value: string | undefined }> = p => {
    const { value } = p;
    if (value === undefined) return null;
    return (
        <div tw=" text-builder-xs font-semibold rounded-full py-0.5 px-2 text-text-pale border border-border-base">
            {p.value}
        </div>
    );
};

const Option: React.FC<OptionProps> = p => {
    const { shouldUpgrade, isEnterprise, targetPlan } = p;
    return (
        <div
            tw="p-4 rounded-lg border cursor-pointer border-border-base hover:bg-n100A"
            onClick={() => p.onSelect(p.kind, p.pluginTrigger)}>
            <div tw="flex justify-between items-start mb-2.5">
                {typeof p.icon === "string" ? (
                    <img src={p.icon} tw="w-10 h-10 rounded-lg" alt="Trigger icon" />
                ) : (
                    <div tw="p-2 rounded-[10px] bg-n900">
                        <GlideIcon {...p.icon} tw="text-text-inverse" iconSize={24} />
                    </div>
                )}
                {shouldUpgrade &&
                    (isEnterprise ? (
                        <div
                            tw="inline-flex items-center text-builder-xs font-semibold rounded-full py-0.5 px-2 cursor-pointer
                bg-aqua100 text-aqua400 hover:bg-aqua200 whitespace-nowrap">
                            Contact Sales
                        </div>
                    ) : (
                        <UpgradePillBadge planKind={targetPlan ?? PlanKind.V3Starter} />
                    ))}
            </div>
            <div tw="flex items-center gap-1 mb-0.5">
                <div tw="font-semibold text-text-base">{p.title}</div>
                <Badge value={p.badge} />
            </div>
            <div tw="text-text-pale text-builder-sm">{p.description}</div>
        </div>
    );
};

export const SelectActionTriggerType: React.FC<Props> = p => {
    const { onClose, onSelect, owner, pluginTriggers, showUpgradeModal } = p;
    const { canUseAutomationTrigger, showUpgradeOrContactSalesFlow } = useCanUseAutomationsEditorHelpers(
        owner,
        showUpgradeModal
    );

    const { data: maybeEminenceFlags } = useEminenceFlags(owner?.id);
    const eminenceFlags = maybeEminenceFlags ?? defaultEminenceFlagsValues;
    const isEnteprise = eminenceFlags.pluginTier === "enterprise";
    const onSelectIfEminenceAllows = useCallback<Props["onSelect"]>(
        async (kind, pluginTrigger) => {
            const canUseAutomation = canUseAutomationTrigger(kind);
            if (!canUseAutomation) {
                await showUpgradeOrContactSalesFlow(pluginTrigger);
                return;
            }
            return onSelect(kind, pluginTrigger);
        },
        [canUseAutomationTrigger, onSelect, showUpgradeOrContactSalesFlow]
    );

    const options: React.ReactNode[] = [];
    options.push(
        <Option
            key="app"
            icon={{ kind: "stroke", icon: "st-navigation" }}
            title="App interaction"
            description="Triggered by users of your app"
            kind="app"
            pluginTrigger={undefined}
            onSelect={onSelectIfEminenceAllows}
            shouldUpgrade={!canUseAutomationTrigger("app")}
            isEnterprise={isEnteprise}
        />
    );

    if (isExperimentEnabled("manualTriggers", owner?.features ?? defaultUserFeatures)) {
        options.push(
            <Option
                key="manual"
                icon={{ kind: "stroke", icon: "st-navigation" }}
                title="Manual"
                description="Manually triggered"
                kind={"manual"}
                pluginTrigger={undefined}
                onSelect={onSelectIfEminenceAllows}
                shouldUpgrade={!canUseAutomationTrigger("manual")}
                isEnterprise={false}
                badge="Beta"
            />
        );
    }

    options.push(
        <Option
            key="schedule"
            icon={{ kind: "stroke", icon: "st-clock" }}
            title="Schedule"
            description="Triggered on a schedule"
            kind="schedule"
            pluginTrigger={undefined}
            onSelect={onSelectIfEminenceAllows}
            shouldUpgrade={!canUseAutomationTrigger("schedule")}
            isEnterprise={isEnteprise}
            badge="Beta"
        />
    );

    for (const pluginTrigger of pluginTriggers) {
        options.push(
            <Option
                key={pluginTrigger.pluginConfigID}
                icon={pluginTrigger.trigger.icon}
                title={pluginTrigger.trigger.name}
                description={pluginTrigger.trigger.description}
                kind="plugin"
                pluginTrigger={pluginTrigger}
                onSelect={onSelectIfEminenceAllows}
                shouldUpgrade={!canUseAutomationTrigger("plugin")}
                isEnterprise={isEnteprise}
                badge={pluginTrigger.badge}
                targetPlan={getRequiredPlan(pluginTrigger.tier, eminenceFlags.pluginTier)}
            />
        );
    }
    const showContactSalesBanner =
        isEnteprise && !(canUseAutomationTrigger("plugin") && canUseAutomationTrigger("schedule"));
    return (
        <Modal.Container onClose={onClose}>
            <Modal.Window size={"md"} tw="min-w-fit">
                <Modal.TitleBar tw="items-center py-4 pr-5 pl-6 text-builder-2xl">
                    <Modal.Title>Choose trigger</Modal.Title>
                    <Modal.Close onClose={onClose} />
                </Modal.TitleBar>
                <Modal.Content tw="px-6 pb-6">
                    {showContactSalesBanner && (
                        <div tw="bg-aqua100 p-4 rounded-lg mb-2 flex items-center gap-4">
                            <div tw="grow">
                                <div tw="text-builder-xl font-semibold text-text-dark">
                                    Automate time-consuming tasks with workflows
                                </div>
                                <div tw="text-text-pale text-builder-base">
                                    Get in touch with your account manager to get access today.
                                </div>
                            </div>
                            <Button href={CONTACT_SALES_URL} label="Contact Sales" variant="gradient" />
                        </div>
                    )}
                    <div tw="grid grid-cols-2 gap-2">{options}</div>
                </Modal.Content>
            </Modal.Window>
        </Modal.Container>
    );
};
