import * as glide from "@glide/plugins";
import { parseStringPromise } from "xml2js";

const { Result } = glide;

export const plugin = glide.newPlugin({
    id: "xml",
    name: "XML",
    description: "Work with XML data in Glide",
    icon: "https://res.cloudinary.com/glide/image/upload/v1739467072/plugins/xml.png",
    tier: "free",
});

const xmlStringParam = glide.makeParameter({
    name: "XML String",
    type: "string",
    description: "The XML string to convert into JSON",
    required: true,
});

const json = glide.makeParameter({
    name: "JSON",
    type: "json",
});

async function xmlToJSONObject(xml: string): Promise<glide.Result<glide.JSONValue>> {
    if (xml.trim() === "") {
        return Result.FailPermanent("XML string cannot be empty", { isPluginError: false });
    }

    try {
        const result = (await parseStringPromise(xml, { explicitArray: false })) as glide.JSONValue;
        return Result.Ok(result);
    } catch (error: unknown) {
        return Result.FailPermanent(`Error parsing XML: ${error}`, { isPluginError: false });
    }
}

plugin.addClientComputation({
    id: "xml-to-json",
    name: "XML to JSON",
    description: "Converts an XML string into a JSON string",
    parameters: {
        xmlString: xmlStringParam,
    },
    results: {
        json,
    },
    execute: async (_context, { xmlString = "" }) => {
        const result = await xmlToJSONObject(xmlString);
        if (result.ok) {
            return Result.Ok({ json: result.result });
        } else {
            return result;
        }
    },
});
