import type { WireAICustomChatComponent } from "@glide/fluent-components/dist/js/fluent-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { WireRenderer } from "../wire-renderer";

import { ValueChangeSource } from "@glide/wire";
import fromPairs from "lodash/fromPairs";
import camelCase from "lodash/camelCase";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import {
    CustomComponentView,
    type Theme as CustomComponentTheme,
    type ThemeV2 as CustomComponentThemeV2,
    type Config as CustomComponentConfig,
} from "@glideapps/custom-component";
import { definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import { isUndefinedish } from "@glide/support";
import { getTokenList, replaceReferences } from "../wire-ai-custom-component/custom-component-theme";
import { GlideDateTime, GlideJSON } from "@glide/data-types";
import type { PrimitiveValue } from "@glide/computation-model-types";
import { asMaybeBoolean, asMaybeDate, asMaybeNumber } from "@glide/common-core/dist/js/computation-model/data";
import { useWireComponentInCollection } from "../../utils/use-wire-component-in-collection";
import { getUniqueActions } from "@glideapps/custom-component-client";
import { isDateTimeTypeKind, isNumberTypeKind } from "@glide/type-schema";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { useMediaQuery } from "@glide/common";

interface ExtraProps {
    isInMultipleColumnLayout?: boolean;
}

function formatTitleKeys(title: string | undefined): string {
    // we need standized action keys with no spaces so lets try camelCase
    return camelCase(title);
}

type CustomComponentPrimitiveValue = string | number | boolean | undefined;

const convertLoadedValueToPrimitive = (value: PrimitiveValue): CustomComponentPrimitiveValue => {
    if (value instanceof GlideDateTime) {
        return value.asLocalTimeZoneAgnosticDate().toISOString();
    } else if (value instanceof GlideJSON) {
        return value.jsonString;
    } else {
        return value;
    }
};

const isEqual = (a: any, b: any) => {
    if (a instanceof GlideDateTime && b instanceof GlideDateTime) {
        return a.compareTo(b) === 0;
    }
    return a === b;
};

export const WireAICustomChat: WireRenderer<WireAICustomChatComponent, ExtraProps> = React.memo(p => {
    const { uuid: id, fields, backend, actions } = p;

    const isWireComponentInCollection = useWireComponentInCollection();
    const theme = useWireAppTheme();
    const tokenList = getTokenList(replaceReferences(theme));

    const state: Record<string, CustomComponentPrimitiveValue> = useMemo(() => {
        return fromPairs(
            fields.map(f => {
                const value = convertLoadedValueToPrimitive(f.value?.value);

                return [formatTitleKeys(f.name ?? undefined), value];
            })
        );
    }, [fields]);

    const [internalState, setInternalState] = useState<Record<string, any>>(state);
    const [sendingUpdate, setSendingUpdate] = useState(false);

    const customComponentTheme = React.useMemo<CustomComponentTheme>(() => {
        const { primaryAccentColor } = backend.getAIComponentInstructionsMetadata();
        return {
            colors: {
                foreground: theme.textBase,
                background: theme.bgContainerBase,
                accent: primaryAccentColor,
            },
        };
    }, [backend, theme.textBase, theme.bgContainerBase]);

    const customComponentThemeV2 = React.useMemo<CustomComponentThemeV2>(() => {
        const { primaryAccentColor } = backend.getAIComponentInstructionsMetadata();
        return {
            accent: primaryAccentColor,
            ...tokenList,
        };
    }, [backend, tokenList]);

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const colorScheme = useMemo(() => {
        const { pageEnvironment } = backend.getAIComponentInstructionsMetadata();

        if (pageEnvironment === "Dark") {
            return "dark";
        }

        if (pageEnvironment === "Light") {
            return "light";
        }

        if (prefersDarkMode) {
            return "dark";
        } else {
            return "light";
        }
    }, [backend, prefersDarkMode]);

    const config = React.useMemo<CustomComponentConfig>(() => {
        const { primaryAccentColor } = backend.getAIComponentInstructionsMetadata();

        return {
            colorScheme,
            cssVariables: {
                "--color-accent": primaryAccentColor,
            },
            alpineConfig: {
                mode: isWireComponentInCollection ? "collection" : "standalone",
                hasBodyAction: isWireComponentInCollection && isWireComponentInCollection.hasAction,
            },
        };
    }, [backend, colorScheme, isWireComponentInCollection]);

    const setFieldDataFromComponent = useCallback(
        (key: string, setValue: any) => {
            const field = fields.find(f => formatTitleKeys(f.name ?? undefined) === key);
            const onChangeToken = field?.value?.onChangeToken;
            if (onChangeToken === undefined) return;
            let processedValue = setValue;
            if (definedMap(field?.value?.typeKind, isDateTimeTypeKind) === true) {
                processedValue = asMaybeDate(setValue);
            } else if (definedMap(field?.value?.typeKind, isNumberTypeKind) === true) {
                processedValue = asMaybeNumber(setValue);
            } else if (field?.value?.typeKind === "boolean") {
                processedValue = asMaybeBoolean(setValue);
            }
            if (isEqual(field?.value?.value, processedValue)) return;
            backend.valueChanged(onChangeToken, processedValue, ValueChangeSource.User);
        },
        [backend, fields]
    );

    const setState = useCallback(
        (newState: Record<string, any>) => {
            for (const k of Object.keys(newState)) {
                setFieldDataFromComponent(k, newState[k]);
            }
        },
        [setFieldDataFromComponent]
    );

    const setViewState = useCallback(
        (newState: Record<string, any>) => {
            setSendingUpdate(true);
            setInternalState(newState);
            setState(newState);
        },
        [setState]
    );

    const uniqueActions = useMemo(() => {
        const titledActions = mapFilterUndefined(actions, a =>
            typeof a.title !== "string" ? undefined : { title: formatTitleKeys(a.title) }
        );
        return getUniqueActions(titledActions);
    }, [actions]);

    const onActionTriggered = useCallback(
        (key: string) => {
            if (key === "click-iframe-body") {
                if (isWireComponentInCollection && isWireComponentInCollection.hasAction) {
                    isWireComponentInCollection.onClick();
                }

                return;
            }

            const action = actions.find((_, i) => uniqueActions[i].id === key);
            if (action === undefined) return;
            backend.runAction(action.action?.token, action.action?.url !== undefined);
        },
        [actions, backend, isWireComponentInCollection, uniqueActions]
    );

    useEffect(() => {
        if (!sendingUpdate) {
            setInternalState(state);
        }
        setSendingUpdate(false);
    }, [sendingUpdate, state]);

    if (isUndefinedish(id)) return null;

    const host = getFeatureSetting("frontCustomComponents") ? window.location.origin : undefined;

    return (
        <CustomComponentView
            key={id}
            component={id}
            host={host}
            shellHost={host}
            state={internalState}
            setState={setViewState}
            theme={customComponentTheme}
            themeV2={customComponentThemeV2}
            config={config}
            actions={uniqueActions}
            onActionTriggered={onActionTriggered}
            debug={false}
        />
    );
});
