import { datadogRum } from "@datadog/browser-rum";
import type { FeatureSettingName, FeatureSettingValue, FeatureSettings } from "@glide/app-description";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import { logError, logInfo } from "@glide/support";

interface PlayerObservabilityOptions {
    // Useful for debugging.
    logToCLI: boolean;
    forLegacy: boolean;
}

export class PlayerObservability {
    private static instance: PlayerObservability | undefined;

    private constructor(
        private readonly options: PlayerObservabilityOptions,
        private readonly datadogEnabled: boolean
    ) {}

    static initialize(options: PlayerObservabilityOptions): void {
        const locationSettings = getLocationSettings();
        const { applicationId, clientToken, env } = locationSettings.datadog;

        const isEnabled = getDatadogEnabledStatus();
        const sampleRate = getDatadogSampleRate();

        if (isEnabled) {
            logInfo(`Initializing Datadog with sample rate ${sampleRate}`);

            datadogRum.init({
                applicationId,
                clientToken,
                env,

                // No need to get these from location-settings for now.
                // Maybe we'll want to reduce the sessionSampleRate if it's too expensive in prod.
                site: "us5.datadoghq.com",
                service: "glide-ui",
                version: "1.0.0",
                sessionSampleRate: sampleRate,
                sessionReplaySampleRate: 0,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: "mask-user-input",

                // Tracking views manually cause we're a SPA
                trackViewsManually: true,
            });

            // We'll also track a single view as soon as we start. We don't care about events per route.
            // We might want to track navigations, but Views group events together and that's not desireable.
            datadogRum.startView({});
        } else {
            logInfo(`Datadog not initialized.`);
        }

        this.instance = new PlayerObservability(options, isEnabled);
    }

    static get(): PlayerObservability {
        if (this.instance === undefined) {
            throw new Error("PlayerObservability was not initialized");
        }

        return this.instance;
    }

    // unused attributes only for type inference
    static makePlayerMetric<Attributes extends string>(metricName: string, _attributes: Attributes[]) {
        return {
            start: () => {
                const startTime = performance.now();

                return (attributes: Record<Attributes, string | number | boolean>) => {
                    const observe = PlayerObservability.get();
                    const endTime = performance.now();
                    const timeMS = endTime - startTime;
                    const forLegacy = this.instance?.options.forLegacy;
                    observe.trackAction(metricName, { ...attributes, timeMS, forLegacy });
                };
            },
        };
    }

    static makePlayerEvent<Attributes extends string>(metricName: string, _attributes: Attributes[]) {
        return {
            track: (attributes: Record<Attributes, string | number | boolean>) => {
                const observe = PlayerObservability.get();
                const forLegacy = this.instance?.options.forLegacy;
                observe.trackAction(metricName, { ...attributes, forLegacy });
            },
        };
    }

    static makePlayerTiming(event: string) {
        return {
            track: () => {
                const observe = PlayerObservability.get();
                observe.trackTiming(event);
            },
        };
    }

    private trackAction(action: string, attributes: Record<string, string | number | boolean>) {
        if (this.options.logToCLI) {
            // eslint-disable-next-line no-console
            console.log(`ACTION ${action} - `, attributes);
        }

        if (this.datadogEnabled) {
            // to graph this in datadog, create a facet first:
            // https://docs.datadoghq.com/real_user_monitoring/guide/send-rum-custom-actions/?tab=npm#create-facets-and-measures-on-attributes
            datadogRum.addAction(action, attributes);
        }
    }

    private trackTiming(event: string) {
        if (this.options.logToCLI) {
            // eslint-disable-next-line no-console
            console.log(`TIMING EVENT ${event}`);
        }

        if (this.datadogEnabled) {
            const forLegacy = this.options.forLegacy;
            datadogRum.setGlobalContext({ forLegacy });
            datadogRum.addTiming(event);
        }
    }
}

/**
 * The play function injects Glide's feature settings in `window.glideFeatureSettings`.
 * Since we still didn't initialize the feature settings by the time we initialize this,
 * we'll read from that directly.
 */
function unsafelyGetPlayerFeatureSetting(featureSetting: FeatureSettingName): FeatureSettingValue {
    try {
        const injectedFeatureSettings = (window as any).glideFeatureSettings as FeatureSettings;
        return injectedFeatureSettings[featureSetting];
    } catch {
        // This should never happen, but leaving the defensive thing just in case.
        logError(`Can't find featureSetting to determine the value of ${featureSetting}. Defaulting to false`);
        return false;
    }
}

function getDatadogEnabledStatus(): boolean {
    const isDatadogEnabled = unsafelyGetPlayerFeatureSetting("playerDatadogObservability");

    return isDatadogEnabled === true;
}

function getDatadogSampleRate(): number {
    const sampleRate = unsafelyGetPlayerFeatureSetting("playerDatadogSampleRate");

    if (typeof sampleRate !== "number") {
        return 0;
    }

    return sampleRate;
}
