import { AppIcon } from "@glide/common";
import { useIsHoverable, css, styled } from "@glide/common-components";
import { isDefined } from "@glide/support";
import {
    APP_MODAL_ROOT,
    ButtonBarSize,
    HeroImageEffect,
    UIButtonAppearance,
    isAccentBackgroundStyle,
    isDarkBackgroundStyle,
    isImageBackgroundStyle,
} from "@glide/wire";
import chroma from "chroma-js";
import classNames from "classnames";
import { hasOwnProperty } from "collection-utils";
import * as React from "react";
import type { UseLayerProps } from "react-laag";
import { useLayer } from "react-laag";
import tw from "twin.macro";

import { useSectionStyle } from "../wire-container/wire-container";
import { useMouseHover } from "../../utils/use-mouse-hover";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

export interface WireButtonProps
    extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    readonly appearance: UIButtonAppearance;
    readonly href?: string;
    readonly target?: string;
    readonly size?: "xs" | "sm" | "md" | "lg" | "xl" | "mini";
    readonly iconName?: string;
    readonly iconOnly?: boolean;
    readonly iconPlacement?: "left" | "right";
}

const WireButtonStyle = styled.button``;

export const WireButton = React.forwardRef<HTMLButtonElement, WireButtonProps>((p, ref) => {
    const {
        appearance,
        children,
        disabled,
        size = "sm",
        href,
        target,
        iconName,
        iconOnly: iconOnlyProp,
        iconPlacement = "left",
        ...rest
    } = p;

    const theme = useWireAppTheme();

    const sectionStyle = useSectionStyle();
    const isAccent = isAccentBackgroundStyle(sectionStyle);
    const isDark = isDarkBackgroundStyle(sectionStyle);
    const isImage = isImageBackgroundStyle(sectionStyle);
    const f = appearance === UIButtonAppearance.Filled;
    const b = appearance === UIButtonAppearance.Bordered;
    const simple = appearance === UIButtonAppearance.Simple;
    const transparent = appearance === UIButtonAppearance.Transparent;
    const mobilePrimary = appearance === UIButtonAppearance.MobilePrimary;
    const mobileSecondary = appearance === UIButtonAppearance.MobileSecondary;
    const floating = appearance === UIButtonAppearance.Floating;
    const minimalPrimary = appearance === UIButtonAppearance.MinimalPrimary;
    const minimalSecondary = appearance === UIButtonAppearance.MinimalSecondary;
    const tiles = appearance === UIButtonAppearance.Tiles;
    const miniTiles = appearance === UIButtonAppearance.MiniTiles;
    const darkNeutral = appearance === UIButtonAppearance.DarkNeutral;

    const filledAccent = f && isAccent;
    const filled = f && !isAccent;
    const borderedAccent = b && isAccent;
    const borderedDark = b && isDark;
    const bordered = b && !isAccent && !isDark;

    // Tiles will have an icon either way.
    const defaultIcon = tiles ? "path:/svg/stroke/st-action-bolt.svg" : undefined;
    const actualIconName = iconName ?? defaultIcon;
    const hasIcon = isDefined(actualIconName) && actualIconName.length > 0;
    const hasLabel = typeof children === "string" && children.trim().length > 0;
    const iconOnly = hasIcon && (iconOnlyProp || !hasLabel);
    const { isHovering, hoverProps } = useMouseHover({ delayEnter: 500 });
    const canHover = useIsHoverable();
    const showTooltip = canHover && iconOnly === true && isHovering && hasLabel;

    const { triggerProps, layerProps, renderLayer } = useLayer({
        isOpen: showTooltip,
        placement: "top-center",
        auto: true,
        triggerOffset: 4,
        container: APP_MODAL_ROOT,
    });

    let a11yLabel: React.HTMLAttributes<HTMLButtonElement> = {};

    if (typeof children === "string") {
        a11yLabel = {
            "aria-label": children,
        };
    }

    return (
        <>
            <WireButtonStyle
                {...a11yLabel}
                {...hoverProps}
                {...(rest as any)}
                as={href !== undefined ? "a" : undefined}
                disabled={disabled}
                href={href}
                target={target}
                rel={"noopener noreferrer"}
                ref={(node: HTMLButtonElement | null) => {
                    if (ref !== null && hasOwnProperty(ref, "current")) {
                        ref.current = node;
                    } else if (typeof ref === "function") {
                        ref(node); // This is for WireMenuButton
                    }

                    if (node !== null && iconOnly === true) {
                        triggerProps.ref(node);
                    }
                }}
                className={classNames(rest.className, size, {
                    disabled,
                    filledAccent,
                    filled,
                    borderedAccent,
                    bordered,
                    simple,
                    transparent,
                    mobilePrimary,
                    mobileSecondary,
                    floating,
                    "has-icon-left": hasIcon && iconPlacement === "left",
                    "has-icon-right": hasIcon && iconPlacement === "right",
                    "icon-only": iconOnly,
                    borderedDark,
                    minimalPrimary,
                    minimalSecondary,
                    tiles,
                    "btn-background-accent": isAccent,
                    "btn-background-dark": isDark,
                    "btn-background-image": isImage,
                    miniTiles,
                    darkNeutral,
                })}
                tw="relative flex items-center justify-center gap-x-1.5 text-center disabled:(opacity-40
                pointer-events-none) after:(content-[''] inset-0 absolute pointer-events-none rounded-lg transition-colors
                duration-75 z-0) text-base font-semibold leading-normal transition rounded-lg text-text-base min-w-0"
                css={css`
                    &.mini {
                        ${tw`rounded-full after:rounded-full [padding-top: 3px] [padding-bottom: 3px] px-3 gap-1`}

                        &.has-icon-right {
                            ${tw`pr-1`};
                        }

                        svg {
                            ${tw`w-4 h-4`}
                        }
                    }

                    &.mini.icon-only {
                        ${tw`p-1.5`}

                        svg {
                            ${tw`w-5 h-5`}
                        }
                    }

                    &.xs {
                        ${tw`px-2.5 py-1 text-sm h-8 gap-x-1`}
                        svg {
                            ${tw`w-4 h-4`}
                        }
                    }

                    &.sm {
                        ${tw`px-4 py-1.5`};
                    }

                    &.md {
                        ${tw`px-4 py-2`}
                    }

                    &.lg {
                        ${tw`padding-left[18px] padding-right[18px] py-3`}
                    }

                    &.xl {
                        ${tw`py-4 px-7`}
                    }

                    --btn-bg-hovered: rgba(0, 0, 0, 0.05);
                    &.filledAccent {
                        ${tw`text-text-accent bg-w100A page-hover:after:([background: var(--btn-bg-hovered)])
                      active:after:([background: var(--btn-bg-hovered)])
                      focus-visible:after:([background: var(--btn-bg-hovered)]) focus-visible:after:(ring-w100A ring-2
                      -inset-px)`}
                    }
                    &.filled {
                        ${tw`text-w100A bg-accent filter page-hover:after:([background: var(--btn-bg-hovered)])
                      active:after:([background: var(--btn-bg-hovered)]) focus-visible:after:(ring-2
                      ring-text-contextual-accent -inset-px [background: var(--btn-bg-hovered)])`}
                    }

                    &.borderedDark,
                    &.borderedAccent {
                        ${tw`text-w100A ring-inset ring-1 ring-w20A bg-w10A page-hover:after:bg-w20A active:after:bg-w20A
                      focus-visible:after:(ring-white ring-2 -inset-px)`}
                    }
                    &.bordered {
                        ${tw`bg-bg-front ring-1 ring-border-dark page-hover:after:bg-n100A active:after:bg-n100A
                      focus-visible:after:([background: var(--btn-bg-hovered)] ring-2 -inset-px ring-text-contextual-accent)`}

                        svg {
                            ${tw`text-text-xpale`}
                        }
                    }
                    &.bordered.icon-only {
                        svg {
                            ${tw`text-text-base`}
                        }
                    }
                    &.simple {
                        ${tw`text-text-contextual-accent page-hover:after:([background: var(--btn-bg-hovered)])
                      active:after:([background: var(--btn-bg-hovered)])`}
                    }
                    &.transparent {
                        ${tw`text-text-contextual-accent focus-visible:after:(ring-2 ring-text-contextual-accent -inset-px)`}
                    }
                    &.minimalPrimary {
                        --btn-bg-hovered: ${chroma(theme.textContextualAccent).alpha(0.1).css()};
                        ${tw`text-text-accent focus-visible:after:(ring-2 ring-text-accent -inset-px
                      [background: var(--btn-bg-hovered)]) page-hover:after:([background: var(--btn-bg-hovered)])
                      active:after:([background: var(--btn-bg-hovered)])`}
                    }

                    .${HeroImageEffect.Darken.toLowerCase()} &.minimalPrimary.btn-background-image,
                    &.minimalPrimary.btn-background-accent,
                    &.minimalPrimary.btn-background-dark {
                        ${tw`text-text-contextual-accent focus-visible:after:(ring-2 ring-text-accent -inset-px bg-w05A)
                      page-hover:after:(bg-w05A) active:after:(bg-w05A)`}
                    }

                    &.minimalSecondary {
                        --btn-bg-hovered: rgba(0, 0, 0, 0.02);
                        ${tw`text-text-base focus-visible:after:(ring-2 ring-text-base -inset-px
                      [background: var(--btn-bg-hovered)]) page-hover:after:([background: var(--btn-bg-hovered)])
                      active:after:([background: var(--btn-bg-hovered)])`}
                        svg {
                            ${tw`text-text-xpale`}
                        }
                    }
                    .${HeroImageEffect.Darken.toLowerCase()} &.minimalSecondary.btn-background-image,
                    &.minimalSecondary.btn-background-accent,
                    &.minimalSecondary.btn-background-dark {
                        --btn-bg-hovered: rgba(0, 0, 0, 0.02);
                        ${tw`text-w80A focus-visible:after:(ring-2 ring-text-contextual-accent -inset-px bg-w05A)
                      page-hover:after:(bg-w05A) active:after:(bg-w05A)`}
                        svg {
                            ${tw`text-w100A`}
                        }
                    }

                    &.tiles {
                        --btn-bg: ${chroma(theme.textContextualAccent).alpha(0.07).css()};
                        --btn-bg-hovered: ${chroma(theme.textContextualAccent).alpha(0.12).css()};
                        ${tw`text-text-contextual-accent flex-col font-medium rounded-xl after:([background: var(--btn-bg)]
                      rounded-xl) focus-visible:after:(ring-2 ring-text-accent -inset-px
                      [background: var(--btn-bg-hovered)]) page-hover:after:([background: var(--btn-bg-hovered)])
                      active:after:([background: var(--btn-bg-hovered)])`}

                        &.has-icon-left.sm, &.has-icon-left.md, &.has-icon-left.lg, &.has-icon-left.xl,
                    &.has-icon-right.sm, &.has-icon-right.md, &.has-icon-right.lg, &.has-icon-right.xl {
                            ${tw`pl-2 pr-2`}
                        }

                        & .button-text {
                            ${tw`[line-height: 140%] [font-size: 10px] mt-1 max-w-full`}
                        }

                        .${ButtonBarSize.Auto} & {
                            ${tw`[width:88px]`}
                        }
                    }

                    &.miniTiles {
                        --btn-bg: ${chroma(theme.textContextualAccent).alpha(0.07).css()};
                        --btn-bg-hovered: ${chroma(theme.textContextualAccent).alpha(0.12).css()};
                        ${tw`text-sm leading-normal text-text-contextual-accent rounded-full px-4 py-1.5 gap-x-2
                      after:([background: var(--btn-bg)] px-4 py-1.5 rounded-full) focus-visible:after:(ring-2
                      ring-text-accent -inset-px [background: var(--btn-bg-hovered)])
                      page-hover:after:([background: var(--btn-bg-hovered)])
                      active:after:([background: var(--btn-bg-hovered)])`}

                        &.icon-only {
                            ${tw`p-1.5`}
                        }
                    }

                    &.darkNeutral {
                        ${tw`text-n50 bg-n800`}
                    }

                    &.floating {
                        ${tw`focus-visible:after:(ring-2 ring-accent)`}
                    }
                    &.disabled {
                        ${tw`pointer-events-none opacity-40`}
                    }
                    &.mobilePrimary {
                        ${tw`text-accent bg-n300A`}
                    }
                    &.mobileSecondary {
                        ${tw`text-text-base bg-n300A`}
                    }

                    &.has-icon-left.sm {
                        ${tw`pl-2.5`}
                    }

                    &.has-icon-left.md {
                        ${tw`pl-3`}
                    }

                    &.has-icon-left.lg {
                        ${tw`pl-3.5`}
                    }

                    &.has-icon-left.xl {
                        ${tw`pl-5`}
                    }

                    &.has-icon-right.sm {
                        ${tw`pr-2.5`}
                    }

                    &.has-icon-right.md {
                        ${tw`pr-3`}
                    }

                    &.has-icon-right.lg {
                        ${tw`pr-3.5`}
                    }

                    &.has-icon-right.xl {
                        ${tw`pr-5`}
                    }

                    &.icon-only {
                        ${tw`[min-width:unset]`}
                    }

                    &.icon-only.xs {
                        ${tw`px-1.5`}
                    }

                    &.icon-only.sm {
                        ${tw`p-2`}
                    }

                    &.icon-only.md {
                        ${tw`p-2.5`}
                    }

                    &.icon-only.lg {
                        ${tw`p-3.5`}
                    }

                    &.icon-only.xl {
                        ${tw`padding[18px]`}
                    }
                `}>
                {isDefined(actualIconName) && iconPlacement === "left" && <AppIcon icon={actualIconName} size={20} />}
                {iconOnly ? (
                    <div tw="sr-only">{children}</div>
                ) : (
                    <div className="button-text" tw="relative z-10 leading-6 truncate">
                        {children}
                    </div>
                )}
                {isDefined(actualIconName) && iconPlacement === "right" && <AppIcon icon={actualIconName} size={20} />}
            </WireButtonStyle>
            <TooltipLayer layerProps={layerProps} renderLayer={renderLayer} showTooltip={showTooltip}>
                {children}
            </TooltipLayer>
        </>
    );
});

interface TooltipLayerProps {
    readonly showTooltip: boolean;
    readonly renderLayer: UseLayerProps["renderLayer"];
    readonly layerProps: UseLayerProps["layerProps"];
}

const TooltipLayer: React.FC<React.PropsWithChildren<TooltipLayerProps>> = p => {
    const { showTooltip, renderLayer, layerProps, children } = p;
    if (!showTooltip) {
        return null;
    }

    return renderLayer(
        <div
            data-testid="button-tooltip-layer"
            {...layerProps}
            tw="bg-n800 text-xs text-n0 font-medium px-2.5 py-1.5 rounded-lg [box-shadow:0px 0px 0px 1px
                            rgba(255, 255, 255, 0.1), 0px 6px 12px rgba(62, 65, 86, 0.2); ]">
            {children}
        </div>
    );
};
