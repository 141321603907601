import * as iots from "io-ts";
import type {
    BinaryPredicateCompositeOperator,
    BinaryPredicateFormulaOperator,
    UnaryPredicateFormulaOperator,
    Formula,
    PrimitiveGlideType,
    TableRefGlideType,
    TableGlideType,
    SourceColumn,
} from "@glide/type-schema";
import type { LocalizedStringKey } from "@glide/localization";
import { hasOwnProperty } from "@glideapps/ts-necessities";

export enum PropertyKind {
    Column = "column",
    Table = "table",
    Enum = "enum",
    String = "string",
    Number = "number",
    Switch = "switch",
    Constant = "constant",
    SpecialValue = "special-value",
    Secret = "secret",
    GeneratedKeyPair = "generated-key-pair",
    PaymentMethod = "payment-method",
    Screen = "screen",
    Filter = "filter",
    Formula = "formula",
    Transforms = "transforms",
    Sorts = "sorts",
    Array = "array",
    Zap = "zap",
    Webhook = "webhook",
    Icon = "icon",
    Emoji = "emoji",
    Action = "action",
    CompoundAction = "compound-action",
    ConfigurationButton = "configuration-button",
    Warning = "warning",
    TableView = "table-view",
    JSONPath = "json-path",
    InlineComputation = "inline-computation",
}

export interface PropertyDescription {
    readonly kind: PropertyKind;
    readonly value: unknown;
}

export type LegacyPropertyDescription = PropertyDescription | unknown;

export enum ActionKind {
    OpenLink = "open-link",
    OpenLinkWithArgs = "open-link-with-args",
    OpenWebView = "open-web-view",
    PhoneCall = "phone-call",
    PhoneCallWithArgs = "phone-call-with-args",
    TextMessage = "text-message",
    TextMessageWithArgs = "text-message-with-args",
    SendEmail = "send-email",
    SendEmailWithArgs = "send-email-with-args",
    CopyToClipboard = "copy-to-clipboard",
    CopyToClipboardWithArgs = "copy-to-clipboard-with-args",
    OpenMap = "open-map",
    OpenMapWithArgs = "open-map-with-args",
    AddRow = "add-row",
    SetColumns = "set-columns",
    DeleteRow = "delete-row",
    EnlargeImage = "enlarge-image",
    UploadImage = "upload-image",
    FormScreen = "form-screen",
    PushFreeScreen = "push-free-screen",
    ShowShareSheet = "show-share-sheet",
    ShowShareSheetWithArgs = "show-share-sheet-with-args",
    PushDetailScreen = "push-detail-screen",
    PushEditScreen = "push-edit-screen",
    Zapier = "zapier",
    Reshuffle = "reshuffle",
    Increment = "Increment",
    SignIn = "sign-in",
    PopScreen = "pop-screen",
    CloseModal = "close-modal",
    NavigateToTab = "navigate-to-tab",
    ShowToast = "show-toast",
    ScanCode = "scan-code",
    LiveScanLinearBarcode = "live-scan-linear-barcode",
    PlaySound = "play-sound",
    Compound = "compound",
    DeliverEmail = "deliver-email",
    Webhook = "webhook",
    PushUserProfileScreen = "push-user-profile-screen",
    ReloadQuery = "reload-query",
    YesCode = "yes-code",
    RequestSignature = "request-signature",
    Wait = "wait",
    WaitForCondition = "wait-for-condition",
    PushVoiceEntry = "push-voice-entry",
    TriggerAutomation = "trigger-automation",
    // We return this whenever we can't find a handler for a given action
    // kind, so we don't crash.
    Dummy = "dummy",
}

export interface ActionDescription {
    readonly kind: ActionKind;
    readonly condition?: FilterArrayTransform;
    // We don't use this for new workflows.  In those, the custom title is in
    // the action node.
    readonly customTitle?: string;
    readonly enabled?: boolean; // only read when part of a custom (i.e. compound) actions
}

export type ComponentKind = string;

interface HideableDescription {
    readonly visibilityFilters?: readonly ArrayTransform[];
}

export interface ComponentDescription extends HideableDescription {
    readonly kind: ComponentKind;
    readonly builderDisplayName?: string;

    // NOTE: Do not depend on this being unique or particularly stable.
    // Also see ##removeComponentIDsFromAppDescription.
    readonly componentID: string;
    readonly customCssClassName?: PropertyDescription;
    // Notes is a builder-only property used to describe the component.
    // It is not included in the published app
    readonly notes?: PropertyDescription;
}

interface DescriptionWithAction {
    readonly actions?: LegacyPropertyDescription;
}

export interface ActionComponentDescription extends ComponentDescription, DescriptionWithAction {}

export interface BaseContainerComponentDescription extends ComponentDescription {
    // ##subComponents:
    // Container components have sub components.
    readonly components: readonly ComponentDescription[] | undefined;
}

export enum ScreenDescriptionKind {
    Class = "class",
    Array = "array",
    Chat = "chat",
    ShoppingCart = "shopping-cart",
}

export enum UnaryPredicateCompositeOperator {
    IsEmpty = "is-empty",
    IsFalsey = "is-falsey",
    MatchesVerifiedEmailAddress = "matches-verified-email-address",
    DoesNotMatchVerifiedEmailAddress = "does-not-match-verified-email-address",
}

export type UnaryPredicateOperator = UnaryPredicateFormulaOperator | UnaryPredicateCompositeOperator;

export type BinaryPredicateOperator = BinaryPredicateFormulaOperator | BinaryPredicateCompositeOperator;

export type PredicateOperator = UnaryPredicateOperator | BinaryPredicateOperator;

export interface ArrayFilter {
    // In an email filter, `key` is the email special value, and `value` is
    // the column to be matched.  In a simple reference filter, `key` is the
    // column in the table where the reference lives, and `value` is the
    // column in the target table.
    readonly key: PropertyDescription;
    readonly operator: typeof BinaryPredicateFormulaOperator.Equals;
    readonly value: PropertyDescription;
}

export interface TransformableContentDescription {
    // FIXME: This is legacy and shouldn't be in use anymore - remove
    // eventually.
    readonly filter?: LegacyPropertyDescription;

    readonly transforms?: readonly ArrayTransform[];
}

export enum ArrayTransformKind {
    Filter = "filter",
    Sort = "sort",
    Shuffle = "shuffle",
    TableOrder = "table-order",
    Limit = "limit",
}

export interface ClassScreenDescription extends TransformableContentDescription {
    readonly kind: ScreenDescriptionKind.Class;
    readonly type: TableRefGlideType;

    readonly isForm: boolean | undefined;
    readonly fetchesData: boolean | undefined; // defaults to `false`
    readonly title: PropertyDescription | undefined;

    readonly components: ReadonlyArray<ComponentDescription>;

    // Pages doesn't support the properties below:
    readonly canEdit?: boolean;
    readonly canEditFilters?: readonly ArrayTransform[];

    readonly canDelete?: boolean;
    readonly canDeleteFilters?: readonly ArrayTransform[];

    readonly searchPlaceholder?: PropertyDescription;

    // Notes is a builder-only property used to describe the screen.
    // It is not included in the published app
    readonly notes?: PropertyDescription;
}

interface LegacyColumnAssignments {
    [destColumn: string]: PropertyDescription;
}

export interface ColumnAssignment {
    readonly destColumn: string;
    readonly value: PropertyDescription;
}

export interface ColumnAssignmentsDescription {
    readonly columnAssignments?: readonly ColumnAssignment[];
}

export interface ColumnAssignmentsWithLegacyDescription extends ColumnAssignmentsDescription {
    readonly columns?: LegacyColumnAssignments;
}

// Add screens are of this type, too.
export interface EditScreenDescription extends ClassScreenDescription, ColumnAssignmentsWithLegacyDescription {
    readonly onSubmitActions: readonly ActionDescription[] | undefined;
}

export interface FormScreenDescription extends EditScreenDescription, ColumnAssignmentsWithLegacyDescription {
    readonly isForm: true;

    readonly formType: TableRefGlideType;
}

export enum ArrayScreenFormat {
    List = "list",
    Grid = "grid",
    CalendarList = "calendar-list",
    Columns = "columns",
    Gallery = "gallery",
    Map = "map-list",
    SmallList = "small-list",
    CheckList = "check-list",
    Tiles = "tiles",
    Cards = "cards",
    PieChart = "pie-chart",
    Choice = "choice",
    EventPicker = "event-picker",
    Tinder = "tinder",
    CalendarCollection = "calendar-collection",
    CardCollection = "card-collection",
    DataGrid = "data-grid",
    DataPlot = "data-plot",
    Kanban = "kanban",
    Charts = "pages-charts",
    RadialChart = "pages-radial-chart",
    PagesSimpleMap = "pages-simple-map",
    ForEachContainer = "for-each-container",
    Comments = "comments",
    SuperTable = "super-table",
    NewDataGrid = "new-data-grid",
}

// ##favoritesPivots:
// This is another case where we overbuilt.  All we needed at the time was a
// flag for whether the Favorites pivot would show up, but we built this,
// thinking we'd use it for user-configurable pivots.  We didn't.  Now it
// basically acts as a boolean with an optionally configurable `title`.
export type ArrayPivot = {
    readonly filter?: ArrayFilter;
} & (
    | {
          readonly titleLocalizedStringKey: LocalizedStringKey;
          readonly title: undefined;
      }
    | {
          readonly titleLocalizedStringKey: undefined;
          readonly title: string;
      }
);

export interface FilterArrayTransform {
    readonly kind: ArrayTransformKind.Filter;
    readonly predicate: Formula;
    readonly isActive: boolean | undefined; // true by default
}

export enum SortOrder {
    Ascending = "asc",
    Descending = "desc",
}

interface SortArrayKey {
    readonly key: Formula;
    readonly order: SortOrder;
}

export interface SortArrayTransform {
    readonly kind: ArrayTransformKind.Sort;
    readonly keys: readonly SortArrayKey[];
}

export interface ShuffleArrayTransform {
    readonly kind: ArrayTransformKind.Shuffle;
}

export interface TableOrderArrayTransform {
    readonly kind: ArrayTransformKind.TableOrder;
    readonly reverse: boolean;
}

export interface LimitArrayTransform {
    readonly kind: ArrayTransformKind.Limit;
    readonly numRows: PropertyDescription;
}

export type ArrayTransform =
    | FilterArrayTransform
    | SortArrayTransform
    | ShuffleArrayTransform
    | TableOrderArrayTransform
    | LimitArrayTransform;

export interface ArrayContentDescription extends DescriptionWithAction, TransformableContentDescription {
    // FIXME: This is legacy and fixed in `fixApp` - remove eventually
    readonly reverse?: PropertyDescription;

    readonly groupByColumn?: PropertyDescription;
    readonly components?: readonly ComponentDescription[];
    // Some collections like comments uses this to have additional values.
    readonly columnAssignments?: readonly ColumnAssignment[];
}

// We don't have primitive array screens anymore, but we still need to
// have this type here so as to not crash old apps.
export type ArrayScreenItemType = PrimitiveGlideType | TableRefGlideType;

export interface ArrayScreenDescription extends ArrayContentDescription {
    readonly kind: ScreenDescriptionKind.Array;
    readonly type: ArrayScreenItemType;

    // FIXME: Why is this not part of `ArrayContentDescription`?  In
    // `InlineListComponentDescription` this is a `PropertyDescription`.
    readonly format: ArrayScreenFormat;
    readonly fetchesData: boolean | undefined;
    readonly search?: LegacyPropertyDescription;
    readonly searchPlaceholder: PropertyDescription | undefined;

    readonly canAddRow: PropertyDescription | undefined;
    readonly canAddRowFilters: readonly FilterArrayTransform[] | undefined;

    readonly pivots?: ReadonlyArray<ArrayPivot>;
    readonly dynamicFilterColumn: PropertyDescription | undefined;
    readonly dynamicSortColumns: PropertyDescription | undefined;
}

export type ClassOrArrayScreenDescription = ClassScreenDescription | ArrayScreenDescription;

export interface ChatScreenDescription {
    readonly kind: ScreenDescriptionKind.Chat;
}

export interface ShoppingCartScreenDescription {
    readonly kind: ScreenDescriptionKind.ShoppingCart;
}

export type ScreenDescription =
    | ClassScreenDescription
    | FormScreenDescription
    | ArrayScreenDescription
    | ChatScreenDescription
    | ShoppingCartScreenDescription;

// FIXME: implement validation
export const screenDescriptionCodec = new iots.Type<ScreenDescription>(
    "ScreenDescription",
    (_u): _u is ScreenDescription => true,
    i => iots.success(i as ScreenDescription),
    a => a
);

// FIXME: implement validation
export const propertyDescriptionCodec = new iots.Type<PropertyDescription>(
    "PropertyDescription",
    (_u): _u is PropertyDescription => true,
    i => iots.success(i as PropertyDescription),
    a => a
);

export const userProfileDescriptionCodec = iots.intersection([
    iots.type({
        userProfileTable: propertyDescriptionCodec,
        allowImageUpload: propertyDescriptionCodec,
    }),
    iots.partial({
        emailColumn: propertyDescriptionCodec,
        nameColumn: propertyDescriptionCodec,
        imageColumn: propertyDescriptionCodec,
        rolesColumn: propertyDescriptionCodec,
    }),
]);
export type UserProfileDescription = iots.TypeOf<typeof userProfileDescriptionCodec>;

const tabAppearance = iots.intersection([
    iots.type({
        icon: iots.string,
        hidden: iots.boolean,
    }),
    iots.partial({
        title: iots.string,
        inFlyout: iots.boolean,
        // These are only used in Pages:
        slug: iots.string,
        // As I just learned: This defaults to true
        showInMenu: iots.boolean,
    }),
]);

// FIXME: implement validation
const stringLegacyPropertyDescription = new iots.Type<LegacyPropertyDescription>(
    "LegacyPropertyDescription",
    (_u): _u is LegacyPropertyDescription => true,
    i => iots.success(i as LegacyPropertyDescription),
    a => a
);

// FIXME: implement validation
const arrayTransformCodec = new iots.Type<ArrayTransform>(
    "ArrayTransform",
    (_u): _u is ArrayTransform => true,
    i => iots.success(i as ArrayTransform),
    a => a
);

export const tabDescriptionCodec = iots.intersection([
    tabAppearance,
    iots.type({
        screenName: stringLegacyPropertyDescription,
    }),
    iots.partial({
        // If `propertyName` is undefined, the tab is for the root
        // context.
        propertyName: iots.string,
        visibilityFilters: iots.readonlyArray(arrayTransformCodec),
    }),
]);
export type TabDescription = iots.TypeOf<typeof tabDescriptionCodec>;

// ##inlineTemplatesAsComputations
// An inline computation property is stored as a table where basic columns represent "inputs",
// and the last computed column is an "output".
//
// The bindings map the internal computation table's columnNames
// to the data source sourceColumns.
//
// At this time we only support inline templates.
//
// See `makeInlineTemplateSpecFromInlineComputation`
// and `makeInlineComputationFromInlineTemplateSpec`
export interface InlineComputation {
    readonly computationTable: TableGlideType;
    readonly bindings: readonly [columnName: string, sourceColumn: SourceColumn][];
}

export enum MutatingScreenKind {
    AddScreen,
    EditScreen,
    FormScreen,
}

export function isFormScreen(desc: ScreenDescription | undefined): desc is FormScreenDescription {
    if (desc?.kind !== ScreenDescriptionKind.Class) return false;
    return desc.isForm === true;
}

export const paymentProcessorCodec = iots.keyof({ stripe: null });
type PaymentProcessor = iots.TypeOf<typeof paymentProcessorCodec>;

export interface PaymentMethod {
    readonly processor: PaymentProcessor;
}

export function isPaymentMethod(x: unknown): x is PaymentMethod {
    return typeof x === "object" && hasOwnProperty(x, "processor");
}

export const defaultPaymentMethod: PaymentMethod = { processor: "stripe" };
