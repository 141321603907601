import classNames from "classnames";
import type { Header, InnerItemDescription } from "../../lib/dropdown-types";
import { isGroup, isHeader } from "../../lib/dropdown-types";
import * as React from "react";
import { useLayer } from "react-laag";
import type { Options } from "react-laag/dist/types";
import { ClickOutsideContainer } from "@glide/common";
import { DropdownItem } from "./dropdown-item";
import { PushDropdownStyle } from "./push-dropdown-style";
import { useShallowMemo } from "../../hooks/use-shallow-memo";
import { isDefined } from "@glide/support";

interface Props<T> {
    readonly describedItems: readonly (InnerItemDescription<T> | null | Header)[];
    readonly onClickOutside: () => void;
    readonly onItemClick: (
        item: InnerItemDescription<T> | null,
        e?: React.MouseEvent<Element, MouseEvent> | undefined
    ) => void;
    readonly onItemActionClicked: (
        item: InnerItemDescription<T> | null,
        e?: React.MouseEvent<Element, MouseEvent> | undefined
    ) => void;

    readonly className?: string;
    readonly style?: React.CSSProperties;
    readonly footer?: React.ReactNode;
    readonly prefer: "left" | "right";
    readonly isSubmenu?: boolean;
    readonly showGroupCounts?: boolean;
    readonly search: string;
    readonly showSearchLineage?: boolean | number; // if true, show all lineage, if number, show the last n items
    readonly prelight: {
        fromHover: boolean;
        prelit: InnerItemDescription<T> | undefined;
        prelighting: InnerItemDescription<T> | undefined;
    };
    readonly onSearchChange?: (newVal: string) => void;
    readonly hideSearch?: boolean;
    readonly onItemHovered?: (item: InnerItemDescription<T> | undefined) => void;
    readonly testId?: string;
}

interface PopoutItemProps<T> extends Props<T> {
    readonly desc: InnerItemDescription<T>;
    readonly open: boolean;
    readonly hovered: boolean;
    readonly onHoverChange?: (hovered: boolean) => void;
    readonly testId?: string;
}

function PopoutItem<T>(p: PopoutItemProps<T>) {
    const { desc, onHoverChange, open, hovered, testId, ...rest } = p;

    const { onItemClick, onItemActionClicked, prefer, showGroupCounts, search, showSearchLineage } = p;

    const baseOptions: Partial<Options> = {
        overflowContainer: true,
        auto: true,
        placement: prefer === "left" ? "left-start" : "right-start",
        possiblePlacements: ["right-start", "left-start", "left-end", "left-center", "right-center", "right-end"],
        triggerOffset: -4,
        container: "portal",
    };

    let isOpen = false;
    if (isGroup(desc?.item) && search === "") {
        if (open) {
            isOpen = true;
        } else if (desc?.containsPrelight && !desc.prelight) {
            isOpen = true;
        }
    }
    const { layerProps, renderLayer, triggerProps } = useLayer({
        ...baseOptions,
        isOpen,
    });

    return (
        <>
            <DropdownItem
                desc={desc}
                hovered={hovered}
                showGroupCounts={showGroupCounts}
                onHoverChange={onHoverChange}
                showSearchLineage={showSearchLineage}
                {...triggerProps}
                onItemClick={onItemClick}
                onSecondaryActionClick={onItemActionClicked}
            />
            {isOpen &&
                renderLayer(
                    <div {...layerProps}>
                        <PopoutDropdownContent
                            {...rest}
                            describedItems={desc.children ?? []}
                            isSubmenu={true}
                            testId={testId}
                        />
                    </div>
                )}
        </>
    );
}

export function PopoutDropdownContent<T>(p: Props<T>): React.ReactElement | null {
    const { style, isSubmenu, onSearchChange, footer, testId, hideSearch, ...rest } = p;
    const { onClickOutside, className, describedItems, prelight, onItemHovered, search } = rest;

    const onMouseDownRoot = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const { fromHover, prelighting } = prelight;
    const stableRest = useShallowMemo(rest);
    const items = React.useMemo(
        () =>
            describedItems.map((i, index) => {
                if (i === null) return <div key={index} className="sep" />;
                if (isHeader(i))
                    return (
                        <div key={index} className="header">
                            i.name
                        </div>
                    );

                let open = false;
                if (i.containsPrelight && !i.prelight) {
                    open = true;
                } else if (i.prelight && fromHover) {
                    open = true;
                }
                const hover =
                    prelighting !== undefined ? i?.containsPrelighting === true : i?.containsPrelight === true;
                return (
                    <PopoutItem
                        testId={testId}
                        key={index}
                        desc={i}
                        hovered={hover}
                        onHoverChange={(hovered: boolean) => {
                            if (!hovered) return;
                            onItemHovered?.(i);
                        }}
                        open={open}
                        {...stableRest}
                    />
                );
            }),
        [describedItems, fromHover, prelighting, testId, stableRest, onItemHovered]
    );

    const onSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onSearchChange?.(e.target.value);
        },
        [onSearchChange]
    );

    const onInputMouseDown = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
    }, []);

    const mouseOver = React.useRef(false);
    const onMouseEnter = React.useCallback((e: React.MouseEvent) => {
        const domRect = e.currentTarget.getBoundingClientRect();
        // There are two bugs this is resolving.
        // 1) React for some insane reason is bubbling the MouseEnter event up to the parent menu
        // even though that event is not supposed to bubble. This seems to be due to it being a
        // synthesized event. This event is seemingly bubbling according to vDOM
        // instead of real DOM and ignoring bounds.
        //
        // 2) react-laag initially positions new menus at 0,0, if your mouse happens to be positioned
        // just right to catch the menu for that non-rendered flicker you will get an enter/leave
        // event which triggers bad behavior. So we are filtering that out here as well.
        if ((domRect.x !== 0 || domRect.y > 0) && domRectContains(domRect, e.clientX, e.clientY)) {
            mouseOver.current = true;
        }
    }, []);

    const onMouseLeave = React.useCallback(
        (_e: React.MouseEvent) => {
            if (mouseOver.current === false) return;
            mouseOver.current = false;
            onItemHovered?.(undefined);
        },
        [onItemHovered]
    );

    if (describedItems.length === 0 && onSearchChange === undefined) return null;
    const dropdownTestId = isDefined(testId)
        ? isSubmenu
            ? `${testId}-dropdown-submenu`
            : `${testId}-dropdown`
        : testId;
    return (
        <ClickOutsideContainer onClickOutside={onClickOutside}>
            <PushDropdownStyle
                data-testid={dropdownTestId}
                style={style}
                className={classNames(className, "click-outside-ignore", isSubmenu && "submenu")}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
                onMouseDown={onMouseDownRoot}>
                {onSearchChange !== undefined && (
                    <div className={classNames("search-input", { "hide-search": hideSearch === true })}>
                        <input
                            onChange={onSearch}
                            value={search}
                            placeholder="Search items"
                            autoFocus={true}
                            onMouseDown={onInputMouseDown}
                        />
                    </div>
                )}
                <div>
                    <ul>{items}</ul>
                </div>
                {footer}
            </PushDropdownStyle>
        </ClickOutsideContainer>
    );
}

function domRectContains(domRect: DOMRect, x: number, y: number) {
    return domRect.x <= x && domRect.right >= x && domRect.y <= y && domRect.bottom >= y;
}
