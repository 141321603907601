import type { ActionDescription, AutomationTrigger, MutatingScreenKind } from "@glide/app-description";
import { PropertyKind } from "@glide/app-description";
import type { Description } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import type { ActionsRecord, InteractiveComponentConfiguratorContext } from "@glide/function-utils";
import { logError, mapFilterUndefinedAsync } from "@glide/support";
import type { Writable } from "@glideapps/ts-necessities";
import { assertNever } from "@glideapps/ts-necessities";
import entries from "lodash/entries";
import { v4 as uuid } from "uuid";
import type { CompoundActionDescription } from "./compound-handler";
import { handlerForActionKind } from ".";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import fromPairs from "lodash/fromPairs";
import { duplicatePluginSecret } from "@glide/backend-api";
import { isErrorInfo } from "@glide/error-info";

export async function copyActions(
    actions: readonly ActionDescription[],
    rootDesc: Description,
    copyTables: InputOutputTables | undefined,
    iccc: InteractiveComponentConfiguratorContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    screensCreated: Set<string>,
    appFacilities: ActionAppFacilities
): Promise<readonly ActionDescription[]> {
    return await mapFilterUndefinedAsync(actions, async a => {
        const handler = handlerForActionKind(a.kind);
        const copy = await handler.reuseOrDuplicateAction(
            a as CompoundActionDescription,
            rootDesc,
            copyTables,
            iccc,
            mutatingScreenKind,
            screensCreated,
            appFacilities
        );
        return copy;
    });
}

export async function copyActionsRecord(
    actionsRecord: ActionsRecord,
    rootDesc: Description,
    copyTables: InputOutputTables | undefined,
    iccc: InteractiveComponentConfiguratorContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    screensCreated: Set<string>,
    appFacilities: ActionAppFacilities
): Promise<ActionsRecord> {
    const copy: Writable<ActionsRecord> = { ...actionsRecord };
    for (const [name, actions] of entries(copy)) {
        copy[name] = await copyActions(
            actions,
            rootDesc,
            copyTables,
            iccc,
            mutatingScreenKind,
            screensCreated,
            appFacilities
        );
    }
    return copy;
}

export async function copyTrigger(
    appID: string,
    trigger: AutomationTrigger,
    appFacilities: ActionAppFacilities
): Promise<AutomationTrigger> {
    const copy: AutomationTrigger = {
        ...trigger,
        triggerID: uuid(),
        enabled: false,
    };

    if (copy.kind === "schedule" || copy.kind === "manual") {
        return copy;
    } else if (copy.kind === "plugin") {
        const parameters = fromPairs(
            await mapFilterUndefinedAsync(Object.entries(copy.parameters), async ([k, v]) => {
                if (v.kind === PropertyKind.Secret) {
                    if (typeof v.value !== "string") return undefined;
                    const newSecretID = await duplicatePluginSecret(appID, v.value, appFacilities);
                    if (isErrorInfo(newSecretID)) {
                        logError("Could not duplicate plugin secret", v.value, newSecretID.message);
                        return undefined;
                    }
                    return [k, { ...v, value: newSecretID }];
                } else {
                    return [k, v];
                }
            })
        );
        return {
            ...copy,
            parameters,
        };
    } else {
        return assertNever(copy);
    }
}
