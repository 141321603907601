import { maybe } from "@glide/support";
import type { GlideFC } from "@glide/common";
import React from "react";
import { createPortal } from "react-dom";

interface Props extends React.PropsWithChildren {
    selector: string;
    forceDocumentObserver?: boolean;
}

function maybeGetElement(selector: string): HTMLElement | null {
    // selector might not actually be a valid DOM selector.
    // When it isn't, .querySelector throws, and we don't want that.
    return maybe(() => document.querySelector(selector), null);
}

export const PortalObserver: GlideFC<React.PropsWithChildren<Props>> = p => {
    const { selector, children, forceDocumentObserver } = p;
    const [element, setElement] = React.useState<HTMLElement>();

    const mounted = React.useRef(false);
    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }
        setElement(undefined);
    }, []);

    React.useLayoutEffect(() => {
        if (element === undefined) {
            const el = maybeGetElement(selector);
            if (el instanceof HTMLElement) {
                setElement(el);
                return;
            } else {
                // watch for element to arrive
                const observer = new MutationObserver(records => {
                    for (const record of records) {
                        for (let i = 0; i < record.addedNodes.length; i++) {
                            const added = record.addedNodes.item(i);
                            if (!(added instanceof HTMLElement)) continue;

                            if (added.matches(selector)) {
                                setElement(added);
                                return;
                            }
                            // Sometimes portals live inside elements (like Title component), so in that case we should check if the element is present
                            // and if it's a child of the added element.
                            const maybeElement = maybeGetElement(selector);
                            if (maybeElement instanceof HTMLElement && added.contains(maybeElement)) {
                                setElement(maybeElement);
                                return;
                            }
                        }
                    }
                });
                observer.observe(document.body, {
                    childList: true,
                    subtree: true,
                });
                return () => {
                    observer.disconnect();
                };
            }
        } else {
            // watch for element to leave
            const observer = new MutationObserver(records => {
                for (const record of records) {
                    for (let i = 0; i < record.removedNodes.length; i++) {
                        const removed = record.removedNodes.item(i);
                        if (removed === element || removed?.contains(element)) {
                            setElement(undefined);
                            return;
                        }
                    }
                }
            });
            observer.observe(forceDocumentObserver === true ? document.body : element.parentElement ?? document.body, {
                childList: true,
                subtree: forceDocumentObserver === true,
            });
            return () => {
                observer.disconnect();
            };
        }
    }, [element, forceDocumentObserver, selector]);

    if (element === undefined) return null;

    return createPortal(children, element);
};
