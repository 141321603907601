import { type BasePrimitiveValue, isBasePrimitiveValue } from "@glide/data-types";
import { getLocalizedString } from "@glide/localization";
import { areValuesEqual } from "@glide/common-core/dist/js/components/primitives";
import { AppKind } from "@glide/location-common";
import type { MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import {
    type DefinedPrimitiveValue,
    type GroundValue,
    type LoadedGroundValue,
    type PrimitiveValue,
    type Row,
    isLoadingValue,
    isPrimitive,
    isPrimitiveValue,
    type Query,
    generateEqualsQueryCondition,
    isBound,
} from "@glide/computation-model-types";
import {
    asMaybeString,
    asString,
    isNotEmpty,
    isRow,
    isTable,
    nullLoadingToUndefined,
} from "@glide/common-core/dist/js/computation-model/data";
import { convertToRelationKey } from "@glide/common-core/dist/js/computation-model/relation-keys";
import {
    type TableName,
    type SourceColumn,
    type TableColumn,
    type TableGlideType,
    SourceColumnKind,
    getSourceColumnSinglePath,
    getTableColumn,
    getTableColumnDisplayName,
    getTableName,
    isColumnWritable,
    isDataSourceColumn,
    isMultiRelationType,
    isPrimitiveType,
    isSingleRelationType,
    makeSourceColumn,
    isBigTableOrExternal,
    type SchemaInspector,
    maybeGetTableColumn,
} from "@glide/type-schema";
import {
    type ArrayContentDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ArrayScreenFormat,
    PropertyKind,
    getColumnProperty,
    getSourceColumnProperty,
    getTableProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import {
    type InputOutputTables,
    ComponentKindInlineList,
    doesMutatingScreenAddRows,
} from "@glide/common-core/dist/js/description";
import { memoizeFunction, isArray, isEmptyOrUndefined, makeComponentID } from "@glide/support";
import { ChoiceStyle, ListItemAccessoryPosition, ListItemFlags } from "@glide/component-utils";
import {
    type WireAppListListComponent,
    type WireAppMenuItem,
    type WireAppSearchBar,
    type WireChoiceComponent,
    type WireChoiceItem,
    WireImageFallback,
    getColumnLinkTargetFromValueProperty,
    getLabelAndImageForChosenItems,
} from "@glide/fluent-components/dist/js/base-components";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentSpecialCaseDescriptor,
    type EditedColumnsAndTables,
    type EnumPropertyCase,
    type InlineListComponentDescription,
    type InteractiveComponentConfiguratorContext,
    type ListSourcePropertyDescription,
    type PropertyDescriptor,
    type PropertyTableGetter,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    EnumPropertyHandler,
    NumberPropertyHandler,
    NumberPropertyStyle,
    PropertySection,
    QueryableTableSupport,
    RequiredKind,
    StringPropertyHandler,
    SwitchPropertyHandler,
    imageProperties,
    isRequiredPropertyHandler,
    resolveSourceColumn,
    getPrimitiveNonHiddenColumnsSpec,
} from "@glide/function-utils";
import {
    ValueChangeSource,
    WireComponentKind,
    WireActionBusy,
    WireActionResult,
    type DynamicFilterResult,
    type WireAlwaysEditableValue,
    type WireComponentPreHydrationResult,
    type WireRowComponentHydrationBackend,
    type WireTableComponentHydrationBackend,
    type WireTableComponentHydrationResult,
    type WireTableComponentHydrator,
    type WireTableComponentHydratorConstructor,
    type WireTableComponentPreHydrator,
    type WireTableComponentQueryHydrator,
    type WireScreen,
    type WireAction,
    type WireComponent,
    type WireEditableValue,
    type WireActionBackend,
    type WireInflationBackend,
    type WireValueGetter,
} from "@glide/wire";
import { assert, defined, filterUndefined, mapFilterUndefined } from "@glideapps/ts-necessities";
import isBoolean from "lodash/isBoolean";
import isString from "lodash/isString";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";

import type { ScreenContext } from "../components/component-handler";
import {
    type CaptionPropertyDescriptorFlags,
    doesMutatingScreenKindSupportDefaultValues,
    labelCaptionStringOptions,
    makeDefaultValuePropertyDescriptor,
} from "../components/descriptor-utils";
import { summaryColumnsForTable } from "../description-utils";
import { type LinkTarget, getTargetForLink } from "../link-columns";
import { findTitleAndImageColumns } from "../schema-utils";
import {
    type WireStringGetter,
    DefinedPrimitiveValueMap,
    applySearchToQuery,
    encodeScreenKey,
    inflateComponentEnricher,
    inflateEditableColumn,
    inflateStringProperty,
    makeSearchableColumnsForList,
    makeSimpleWireTableComponentHydratorConstructor,
    registerActionRunner,
} from "../wire/utils";
import { type SearchSupport, ArrayContentHandlerBase } from "./array-content";
import { isTimeOnlyColumn } from "@glide/formula-specifications";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import type { ComponentDescription } from "@glide/app-description";

export interface ChoiceArrayContentDescription extends ArrayContentDescription {
    readonly style: PropertyDescription;
    readonly valueProperty?: PropertyDescription;
    readonly defaultValue?: PropertyDescription;
    readonly optionProperty?: PropertyDescription;
    readonly imageProperty?: PropertyDescription;
    readonly displayProperty?: PropertyDescription;
    readonly isRequired: PropertyDescription;
    readonly isMulti: PropertyDescription | undefined;
    readonly maxChoices?: PropertyDescription;
}

interface LinkTargetWithSourceColumn extends LinkTarget {
    readonly linkColumn: TableColumn;
    readonly hostSourceColumn: SourceColumn;
    readonly isInScreenContext: boolean;
}

function getRelationKeys(v: PrimitiveValue | readonly PrimitiveValue[]): readonly PrimitiveValue[] {
    if (isArray(v)) {
        return v;
    } else {
        return [v];
    }
}

function areKeysEqual(k1: PrimitiveValue, k2: string): boolean {
    return convertToRelationKey(k1) === k2;
}

function doRelationValuesMatch(hostKeys: readonly PrimitiveValue[], targetKey: string): boolean {
    return hostKeys.some(v => areKeysEqual(v, targetKey));
}

const makeValuePropertyHandler = memoizeFunction(
    "makeValuePropertyHandler",
    (withLinks: boolean) =>
        new ColumnPropertyHandler(
            "valueProperty",
            "Write to",
            [
                ColumnPropertyFlag.Required,
                ColumnPropertyFlag.Editable,
                ColumnPropertyFlag.DefaultCaption,
                // We need this so that links are reported as written
                withLinks ? ColumnPropertyFlag.EditedInAppEvenIfNotWritable : ColumnPropertyFlag.EditedInApp,
                ColumnPropertyFlag.AllowUserProfileColumns,
            ],
            undefined,
            withLinks ? ["link"] : undefined,
            {
                getCandidateColumns: (t, _d, s) =>
                    // We sort here to have the link columns first, which makes it
                    // easier in the column picker to find them, but it also means
                    // that Glide will prefer them for new components.
                    sortBy(
                        t.columns.filter(
                            c =>
                                c.hidden !== true &&
                                ((isPrimitiveType(c.type) && isColumnWritable(c, t, false)) ||
                                    (withLinks && getTargetForLink(t, c, s, true) !== undefined))
                        ),
                        c => (isPrimitiveType(c.type) ? 1 : 0)
                    ),
                columnTypeIsAllowed: () => true,
            },
            withLinks ? isSingleRelationType : "string",
            PropertySection.Data
        )
);

const dropdownChoice = {
    value: ChoiceStyle.Dropdown,
    label: "Dropdown",
    icon: "choiceDropdown",
};
const chipsChoice = {
    value: ChoiceStyle.Chips,
    label: "Chips",
    icon: "mt-enum-choice-chips",
};
const radioChoice = {
    value: ChoiceStyle.RadioButtons,
    label: "Radio Buttons",
    icon: "mt-enum-choice-radio",
};

function makeStylePropertyHandler(styles: readonly EnumPropertyCase<ChoiceStyle>[]) {
    return new EnumPropertyHandler(
        { style: ChoiceStyle.Dropdown },
        "Selection Type",
        "Style",
        styles,
        PropertySection.Design,
        "small-images"
    );
}

const choiceAppsStylePropertyHandler = makeStylePropertyHandler([
    dropdownChoice,
    {
        value: ChoiceStyle.Inline,
        label: "Segmented",
        icon: "choiceInline",
    },
    chipsChoice,
    radioChoice,
]);
const choicePagesStylePropertyHandler = makeStylePropertyHandler([dropdownChoice, chipsChoice, radioChoice]);

const isMultiPropertyHandler = new SwitchPropertyHandler(
    { isMulti: false },
    "Allow selecting multiple",
    PropertySection.Options
);

const maxChoicesPropertyHandler = new NumberPropertyHandler(
    { maxChoices: 10 },
    "Max choices",
    "max",
    RequiredKind.NotRequiredDefaultMissing,
    NumberPropertyStyle.Stepper,
    PropertySection.Options
);

const customCssClassNamePropertyHandler = new StringPropertyHandler(
    "customCssClassName",
    "CSS class",
    "",
    false,
    undefined,
    PropertySection.CustomCSS
);

function supportsMulti(desc: ChoiceArrayContentDescription | undefined): boolean {
    if (desc === undefined) return false;

    const style = choiceAppsStylePropertyHandler.getEnum(desc);
    return style === ChoiceStyle.Dropdown || style === ChoiceStyle.Chips || style === ChoiceStyle.RadioButtons;
}

function supportsImage(desc: ChoiceArrayContentDescription | undefined): boolean {
    if (desc === undefined) return false;

    const appStyle = choiceAppsStylePropertyHandler.getEnum(desc);
    return appStyle !== ChoiceStyle.Inline;
}

function supportsSearch(desc: ChoiceArrayContentDescription | undefined): boolean {
    if (desc === undefined) return false;

    const style = choicePagesStylePropertyHandler.getEnum(desc);
    return style === ChoiceStyle.Dropdown;
}

// This is complicated, and it doesn't even handle relations coming
// from the user profile row!
function getChoiceOptions(
    schema: SchemaInspector,
    desc: InlineListComponentDescription & ChoiceArrayContentDescription,
    screenContext: ScreenContext | undefined,
    appEnvironment: MinimalAppEnvironment | undefined
): readonly BasePrimitiveValue[] {
    const optionSourceColumn = getSourceColumnProperty(desc?.optionProperty);
    if (optionSourceColumn?.kind !== SourceColumnKind.DefaultContext) return [];
    const optionColumnName = getSourceColumnSinglePath(optionSourceColumn);
    if (optionColumnName === undefined) return [];

    if (screenContext === undefined) return [];

    const computationModel = appEnvironment?.dataStore?.getComputationModelObservable(true).current;
    if (computationModel === undefined) return [];

    let table: GroundValue;
    let sourceTableName: TableName | undefined;

    const sourceColumnProperty = getSourceColumnProperty(desc.propertyName);
    if (sourceColumnProperty !== undefined) {
        const resolved = resolveSourceColumn(
            schema,
            sourceColumnProperty,
            screenContext.inputTable,
            undefined,
            undefined
        );
        const sourceColumn = resolved?.tableAndColumn?.column;
        if (sourceColumn === undefined || !isMultiRelationType(sourceColumn.type)) return [];

        sourceTableName = getTableName(defined(resolved?.tableAndColumn?.table));

        let contextRow: Row | undefined;
        if (sourceColumnProperty.kind === SourceColumnKind.DefaultContext) {
            contextRow = screenContext.inputRows[0];
        } else if (sourceColumnProperty.kind === SourceColumnKind.UserProfile) {
            const path = computationModel.getUserProfileRowPath();
            if (path === undefined) return [];
            const maybeRow = computationModel.getValueAtPath(undefined, path);
            if (isLoadingValue(maybeRow)) return [];
            if (!isRow(maybeRow)) return [];
            contextRow = maybeRow;
        } else {
            return [];
        }

        const sourcePaths = computationModel.getColumnPaths(sourceTableName)?.get(sourceColumn.name);
        if (sourcePaths === undefined) return [];

        const [[sourceValuePath, sourceRootPath]] = sourcePaths;
        if (sourceRootPath !== undefined) {
            computationModel.ns.get(sourceRootPath);
        }

        table = computationModel.getValueAtPath(contextRow, sourceValuePath);
    } else {
        sourceTableName = getTableProperty(desc.propertyName);
        if (sourceTableName === undefined) return [];

        table = computationModel.getBaseDataForTable(sourceTableName);
    }

    if (isLoadingValue(table) || !isTable(table)) return [];
    const rows = table.asArray() ?? [];

    const optionPaths = computationModel.getColumnPaths(sourceTableName)?.get(optionColumnName);
    if (optionPaths === undefined) return [];
    const [[optionValuePath, optionRootPath]] = optionPaths;
    if (optionRootPath !== undefined) {
        computationModel.ns.get(optionRootPath);
    }

    const values: BasePrimitiveValue[] = [];

    for (const r of rows) {
        const optionValue = computationModel.getValueAtPath(r, optionValuePath);
        if (isBasePrimitiveValue(optionValue)) {
            values.push(optionValue);
        }
    }

    return values;
}

function makeSubsidiaryOpenState(
    chb: WireRowComponentHydrationBackend,
    withSubsidiary: boolean
): WireAlwaysEditableValue<boolean> | undefined {
    if (!withSubsidiary) return undefined;
    return chb.getState("subsidiaryOpen", isBoolean, false, false);
}

function closeSubsidiaryIfNecessary(
    ab: WireActionBackend,
    subsidiaryOpen: WireAlwaysEditableValue<boolean> | undefined,
    isMulti: boolean
): void {
    if (subsidiaryOpen !== undefined && !isMulti) {
        ab.valueChanged(subsidiaryOpen.onChangeToken, false, ValueChangeSource.User);
    }
}

function makeSubsidiaryScreen(
    chb: WireRowComponentHydrationBackend,
    appKind: AppKind,
    componentID: string | undefined,
    items: readonly WireChoiceItem[],
    title: string,
    hasImages: boolean,
    subsidiaryOpen: WireAlwaysEditableValue<boolean> | undefined,
    searchEditable: WireAlwaysEditableValue<string> | undefined
): {
    readonly onTap: WireAction | undefined;
    readonly subsidiaryScreen: WireScreen | undefined;
} {
    if (subsidiaryOpen === undefined || !chb.getIsOnline()) {
        return { onTap: undefined, subsidiaryScreen: undefined };
    }

    const onTapToken = chb.registerAction("tap", async ab => {
        assert(subsidiaryOpen !== undefined);
        return ab.valueChanged(subsidiaryOpen.onChangeToken, !subsidiaryOpen.value, ValueChangeSource.User);
    });
    const onTap = { token: onTapToken };

    let subsidiaryScreen: WireScreen | undefined;
    if (subsidiaryOpen.value) {
        const listItems = items.map((i, index) => ({
            key: index.toString(),
            title: i.displayAs,
            subtitle: null,
            caption: null,
            image: hasImages ? { value: i.image ?? "", onChangeToken: undefined } : null,
            icon: null,
            action: i.onChange,
            accessory: i.isSelected
                ? {
                      component: {
                          kind: WireComponentKind.AppIconAccessory as const,
                          icon: "01-check-1",
                          action: undefined,
                      },
                      position: ListItemAccessoryPosition.Right,
                  }
                : undefined,
        }));
        const list: WireAppListListComponent<ArrayScreenFormat.SmallList> = {
            kind: WireComponentKind.List,
            format: ArrayScreenFormat.SmallList,
            title: "",
            emptyMessage: "",
            allowWrapping: true,
            imageFallback: WireImageFallback.None,
            flags: ListItemFlags.DrawSeparator + ListItemFlags.DisableChevron + ListItemFlags.WrapText,
            groups: [{ title: "", items: listItems, seeAllAction: undefined }],
        };
        const doneMenuItem: WireAppMenuItem = {
            kind: WireComponentKind.AppMenuItem,
            title: getLocalizedString("done", appKind),
            icon: "00-01-glide-close",
            style: "platform-accept",
            purpose: undefined,
            action: onTap,
        };
        let searchBar: WireAppSearchBar | undefined;
        if (searchEditable !== undefined) {
            searchBar = {
                kind: WireComponentKind.AppSearchBar,
                value: searchEditable,
                placeholder: getLocalizedString("search", appKind),
            };
        }
        subsidiaryScreen = {
            key: encodeScreenKey(`choice-${componentID}`),
            title,
            flags: [],
            specialComponents: filterUndefined([searchBar, doneMenuItem]),
            components: [list],
            isInModal: true,
            tabIcon: "",
        };
    }

    return { onTap, subsidiaryScreen };
}

function getNoSelectionLabel(appKind: AppKind, isRequired: boolean): string | undefined {
    if (appKind === AppKind.App) {
        return undefined;
    } else if (isRequired) {
        return getLocalizedString("chooseSomething", AppKind.Page);
    } else {
        return "—";
    }
}

function splitValue(value: PrimitiveValue): readonly string[] {
    if (value !== undefined) {
        return Array.from(
            new Set(
                asString(value)
                    .split(",")
                    .filter(s => s !== "")
            )
        );
    } else {
        return [];
    }
}

interface SubsidiaryOpen {
    current: WireAlwaysEditableValue<boolean> | undefined;
}

function applySearch(
    desc: ChoiceArrayContentDescription,
    appKind: AppKind,
    chb: WireRowComponentHydrationBackend,
    items: WireChoiceItem[]
): { items: WireChoiceItem[]; searchEditable: WireAlwaysEditableValue<string> | undefined } {
    const searchThreshold = appKind === AppKind.Page ? 7 : 10;
    if (items.length < searchThreshold || !supportsSearch(desc)) {
        return { items, searchEditable: undefined };
    }

    const searchEditable = chb.getState("search", isString, "", false);
    items = items.filter(item => item.displayAs.toLowerCase().includes(searchEditable.value.toLowerCase()));

    return { items, searchEditable };
}

function makeOnChangeActionWithoutAccidentalLeak(
    chb: WireRowComponentHydrationBackend,
    subsidiaryOpen: SubsidiaryOpen,
    isMulti: boolean,
    selectedValueOnChangeToken: string,
    index: number,
    v: DefinedPrimitiveValue | (() => DefinedPrimitiveValue)
): WireAction | undefined {
    const token = chb.registerAction(`set-${index}`, async ab => {
        if (typeof v === "function") {
            v = v();
        }
        ab.valueChanged(selectedValueOnChangeToken, v, ValueChangeSource.User);
        closeSubsidiaryIfNecessary(ab, subsidiaryOpen.current, isMulti);
        return WireActionResult.nondescriptSuccess();
    });
    return { token };
}

export class ChoiceArrayContentHandler extends ArrayContentHandlerBase<ChoiceArrayContentDescription> {
    constructor() {
        super(ArrayScreenFormat.Choice);
    }

    public get isEditor(): boolean {
        return true;
    }

    public get defaultIsTable(): boolean {
        return true;
    }

    public get allowChangePageSize(): boolean {
        return true;
    }

    public get supportsSearch(): SearchSupport | undefined {
        return undefined;
    }

    public get sourcePropertySection(): PropertySection {
        return PropertySection.Content;
    }

    public get doesCustomLimit(): boolean {
        return true;
    }

    public get queryableTableSupport(): QueryableTableSupport {
        return QueryableTableSupport.LoadAll;
    }

    public get allowBigTables(): boolean {
        return true;
    }

    public getListSourceProperty(
        desc: ChoiceArrayContentDescription & InlineListComponentDescription,
        tables: InputOutputTables | undefined,
        schema: SchemaInspector
    ): [desc: ListSourcePropertyDescription, sourcePicker: boolean] | undefined {
        if (tables !== undefined) {
            const linkTarget = getColumnLinkTargetFromValueProperty(schema, tables, desc?.valueProperty);
            if (linkTarget !== undefined) {
                return [makeTableProperty(getTableName(linkTarget.targetTable)), false];
            }
        }

        return [desc.propertyName, true];
    }

    public getSpecialCaseDescriptors(ccc: AppDescriptionContext): readonly ComponentSpecialCaseDescriptor[] {
        return [
            {
                name: "Choice",
                analyticsName: "choice",
                description: "Choose out of many options",
                img: "co-choice",
                group: ccc.appKind === AppKind.Page ? "Form Elements" : "Pickers",
                appKinds: "both",
            },
        ];
    }

    public getCaptionFlags(
        _desc: ChoiceArrayContentDescription | undefined
    ): CaptionPropertyDescriptorFlags | undefined {
        return { ...labelCaptionStringOptions, propertySection: PropertySection.Design };
    }

    public getDescriptiveName(
        desc: ChoiceArrayContentDescription,
        caption: string | undefined,
        inlineListHostTable: TableGlideType | undefined
    ): [string, string] | undefined {
        const propertyName = makeValuePropertyHandler(false).getColumnName(desc);
        if (propertyName !== undefined && inlineListHostTable !== undefined) {
            const column = getTableColumn(inlineListHostTable, propertyName);
            if (column !== undefined) {
                caption = getTableColumnDisplayName(column);
            }
        }

        if (caption === undefined) return undefined;
        return ["Choice", caption];
    }

    public get needsOutputContext(): boolean {
        return true;
    }

    public get sourcePropertyLabel(): readonly [string, boolean] {
        return ["Source", false];
    }

    public getContentPropertyDescriptors<T extends ChoiceArrayContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined,
        screenContext?: ScreenContext,
        appEnvironment?: MinimalAppEnvironment
    ): readonly PropertyDescriptor[] {
        assert(insideInlineList);
        const isValueChoice =
            desc === undefined ||
            (this.getListSourceProperty(desc as T & InlineListComponentDescription, containingScreenTables, ccc)?.[1] ??
                true);

        const properties: PropertyDescriptor[] = [makeValuePropertyHandler(true)];

        // The check `doesMutatingScreenKindSupportDefaultValues` is
        // technically redudant because `makeDefaultValuePropertyDescriptor`
        // checks for it, too, but if we don't need it we save on calling
        // `getChoiceOptions`, which can be slow.
        if (
            isValueChoice &&
            doesMutatingScreenKindSupportDefaultValues(mutatingScreenKind, desc?.valueProperty) &&
            desc !== undefined
        ) {
            const options = getChoiceOptions(
                ccc,
                desc as unknown as InlineListComponentDescription & ChoiceArrayContentDescription,
                screenContext,
                appEnvironment
            );
            const defaultProperty = makeDefaultValuePropertyDescriptor(
                ccc,
                desc.valueProperty,
                mutatingScreenKind,
                "string",
                undefined,
                true,
                uniq(options)
            );
            if (defaultProperty !== undefined) {
                properties.push(defaultProperty);
            }
        }

        properties.push(
            ...super.getContentPropertyDescriptors(
                getPropertyTable,
                insideInlineList,
                containingScreenTables,
                desc,
                ccc,
                mutatingScreenKind,
                isDefaultArrayScreen,
                withTransforms,
                forEasyTabConfiguration,
                isFirstComponent,
                screenContext,
                appEnvironment
            ),
            isRequiredPropertyHandler,
            ccc.appKind === AppKind.App ? choiceAppsStylePropertyHandler : choicePagesStylePropertyHandler
        );

        if (isValueChoice) {
            properties.push(isMultiPropertyHandler, {
                kind: PropertyKind.Column,
                property: { name: "optionProperty" },
                section: PropertySection.Content,
                label: "Values",
                required: true,
                editable: true,
                // If there's no separate display property, then search will
                // operate on the value.
                searchable: getColumnProperty(desc?.displayProperty) === undefined,
                getIndirectTable: getPropertyTable,
                columnFilter: getPrimitiveNonHiddenColumnsSpec,
                preferredNames: ["option", "options", "choice", "choices"],
                preferredType: "string",
            });
        }

        if (supportsImage(desc)) {
            properties.push({
                kind: PropertyKind.Column,
                property: { name: "imageProperty" },
                section: PropertySection.Content,
                label: "Images",
                required: false,
                editable: true,
                searchable: false,
                getIndirectTable: getPropertyTable,
                columnFilter: getPrimitiveNonHiddenColumnsSpec,
                preferredNames: imageProperties,
                preferredType: "image-uri",
            });
        }

        properties.push({
            kind: PropertyKind.Column,
            property: { name: "displayProperty" },
            section: PropertySection.Content,
            label: "Display as",
            required: !isValueChoice,
            editable: true,
            searchable: true,
            emptyByDefault: isValueChoice,
            getIndirectTable: getPropertyTable,
            columnFilter: getPrimitiveNonHiddenColumnsSpec,
            preferredNames: ["option", "options", "choice", "choices", "display"],
            preferredType: "string",
        });

        const linkTarget = getColumnLinkTargetFromValueProperty(ccc, containingScreenTables, desc?.valueProperty);
        const isMulti = supportsMulti(desc) && isMultiPropertyHandler.getSwitch(desc);
        // We don't support "max choices" in the Link Picker
        if (isMulti && ccc.appKind === AppKind.Page && linkTarget === undefined) {
            properties.push(maxChoicesPropertyHandler);
        }

        if (ccc.appKind === AppKind.Page && ccc.eminenceFlags.pagesCustomCss) {
            properties.push(customCssClassNamePropertyHandler);
        }

        return properties;
    }

    public getBasicSearchProperties(): readonly string[] {
        return [];
    }

    public getActionDescriptors(): readonly ActionPropertyDescriptor[] {
        return [];
    }

    public needValidation(desc: ChoiceArrayContentDescription): boolean {
        return isRequiredPropertyHandler.getSwitch(desc);
    }

    public adjustContentDescriptionAfterUpdate(
        desc: ChoiceArrayContentDescription,
        updates: Partial<ChoiceArrayContentDescription & InlineListComponentDescription> | undefined,
        tables: InputOutputTables,
        ccc: InteractiveComponentConfiguratorContext,
        getPropertyTable: PropertyTableGetter | undefined
    ): ChoiceArrayContentDescription {
        desc = super.adjustContentDescriptionAfterUpdate(desc, updates, tables, ccc, getPropertyTable);

        if (!supportsMulti(desc) && isMultiPropertyHandler.getSwitch(desc)) {
            // The `isMulti` property is incompatible with `style`, so we have
            // to change one or the other.  We don't want to negate the user's
            // choice, so if they changed `isMulti` then we'll change the
            // style, and vice versa.

            if (updates?.isMulti !== undefined) {
                desc = { ...desc, style: makeEnumProperty(ChoiceStyle.Dropdown) };
            } else {
                desc = { ...desc, isMulti: makeSwitchProperty(false) };
            }
        }

        return desc;
    }

    public getEditedColumns<T extends ChoiceArrayContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        desc: T,
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        isDefaultArrayScreen: boolean,
        withActions: boolean
    ): EditedColumnsAndTables {
        if (containingScreenTables !== undefined) {
            const maybeTarget = getColumnLinkTargetFromValueProperty(ccc, containingScreenTables, desc?.valueProperty);
            if (maybeTarget !== undefined) {
                const { hostColumn, linkColumn, isInScreenContext } = maybeTarget;
                const isAdd = doesMutatingScreenAddRows(mutatingScreenKind);
                const outputTableName = getTableName(tables.output);
                return {
                    editedColumns: [
                        // This is where Choice handles ##editedLinkColumns.
                        // We report here both the link column as well the
                        // target, so that action validation allows writing to
                        // the target, and we can find the link column with
                        // "Find Uses".
                        [hostColumn.name, isInScreenContext, isAdd, outputTableName],
                        [linkColumn.name, isInScreenContext, isAdd, outputTableName],
                    ],
                    deletedTables: [],
                };
            }
        }

        return super.getEditedColumns(
            getPropertyTable,
            desc,
            tables,
            ccc,
            mutatingScreenKind,
            insideInlineList,
            containingScreenTables,
            isDefaultArrayScreen,
            withActions
        );
    }

    public static defaultComponentForLink(
        linkColumn: TableColumn,
        hostTable: TableGlideType,
        schema: SchemaInspector
    ): (InlineListComponentDescription & ChoiceArrayContentDescription) | undefined {
        const linkTarget = getTargetForLink(hostTable, linkColumn, schema, true);
        if (linkTarget === undefined) return undefined;

        const summary = summaryColumnsForTable(linkTarget.targetTable, true, true);
        if (summary === undefined) return undefined;

        const component: InlineListComponentDescription & ChoiceArrayContentDescription = {
            kind: ComponentKindInlineList,
            componentID: makeComponentID(),
            format: makeEnumProperty(ArrayScreenFormat.Choice),
            propertyName: makeTableProperty(getTableName(linkTarget.targetTable)),
            valueProperty: makeColumnProperty(linkColumn.name),
            displayProperty: summary.titleProperty,
            imageProperty: summary.imageURLProperty,
            caption: makeStringProperty(getTableColumnDisplayName(linkColumn)),
            style: makeEnumProperty(ChoiceStyle.Dropdown),
            isRequired: makeSwitchProperty(false),
            isMulti: makeSwitchProperty(linkTarget.isMulti),
        };
        return component;
    }

    // `ib` here refers to the containing screen's IB, and `targetIB` to the
    // link's target table's.
    private inflateLinkPicker(
        ib: WireInflationBackend,
        targetIB: WireInflationBackend,
        componentID: string | undefined,
        desc: ChoiceArrayContentDescription,
        style: ChoiceStyle,
        isRequired: boolean,
        withSubsidiary: boolean,
        linkTarget: LinkTargetWithSourceColumn,
        captionGetter: WireStringGetter | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        const {
            adc: { appKind },
        } = ib;

        const { hostColumn, hostSourceColumn, targetTable, targetColumn, isMulti } = linkTarget;
        const hostColumnIsArray = hostColumn.type.kind === "array";
        const emptyValue = hostColumnIsArray ? [] : "";

        const { getter: hostKeyGetter, isInContext } = inflateEditableColumn<
            PrimitiveValue | readonly PrimitiveValue[]
        >(ib, "host", hostSourceColumn, v =>
            isPrimitiveValue(v) || (isArray(v) && v.every(isPrimitiveValue)) ? v : undefined
        );
        if (hostKeyGetter === undefined) return undefined;

        const [titleColumn] = findTitleAndImageColumns(targetTable);
        if (titleColumn === undefined) return undefined;

        const [nameGetter, nameType] = inflateStringProperty(targetIB, desc.displayProperty, true);
        if (nameType === undefined) return undefined;

        const [targetKeyGetter, targetKeyType] = targetIB.getValueGetterForSourceColumn(
            makeSourceColumn(targetColumn.name),
            false,
            false
        );
        if (targetKeyType === undefined) return undefined;

        const [imageSourceGetter, imageType] = targetIB.getValueGetterForProperty(desc.imageProperty, false);
        const hasImages = supportsImage(desc) && imageType !== undefined;

        function makeItems(
            thb: WireTableComponentHydrationBackend,
            hostKeys: readonly PrimitiveValue[],
            hostKeyEditable: WireEditableValue<PrimitiveValue | readonly PrimitiveValue[]>,
            subsidiaryOpen: WireAlwaysEditableValue<boolean> | undefined
        ): WireChoiceItem[] {
            const items: WireChoiceItem[] = [];
            const keyValuesSeen = new Set<string>();
            const table = thb.tableScreenContext;
            for (const r of table.asArray()) {
                const rowHB = thb.makeHydrationBackendForRow(r);

                // We use the converted key for comparison, but we write the
                // value to preserve it as-is.
                const keyValue = targetKeyGetter(rowHB);
                if (keyValue === null || isLoadingValue(keyValue) || !isPrimitiveValue(keyValue)) continue;
                const key = convertToRelationKey(keyValue);
                if (key === undefined || isLoadingValue(key)) continue;

                // In single relations where the key is not unique, only the
                // first occurrence "counts".
                if (!isMulti) {
                    if (keyValuesSeen.has(key)) continue;
                    keyValuesSeen.add(key);
                }

                const nameValue = nameGetter(rowHB);
                if (!isBound(nameValue) || isLoadingValue(nameValue)) continue;
                const itemName = asMaybeString(nameValue);
                if (isEmptyOrUndefined(itemName)) continue;

                const isSelected = doRelationValuesMatch(hostKeys, key);

                const image = hasImages ? asMaybeString(nullLoadingToUndefined(imageSourceGetter(rowHB))) : undefined;

                const onChange = registerActionRunner(rowHB, `set-${r.$rowID}`, async ab => {
                    if (isMulti) {
                        let newKeys: PrimitiveValue[];
                        if (isSelected) {
                            newKeys = hostKeys.filter(k => !areKeysEqual(k, key));
                        } else {
                            newKeys = [...hostKeys, keyValue];
                        }
                        ab.valueChanged(defined(hostKeyEditable.onChangeToken), newKeys, ValueChangeSource.User);
                    } else if (!isSelected) {
                        let valueToSave: LoadedGroundValue;
                        if (hostColumnIsArray) {
                            valueToSave = [keyValue];
                        } else {
                            valueToSave = keyValue;
                        }
                        ab.valueChanged(defined(hostKeyEditable.onChangeToken), valueToSave, ValueChangeSource.User);
                    } else if (!isRequired) {
                        ab.valueChanged(defined(hostKeyEditable.onChangeToken), emptyValue, ValueChangeSource.User);
                    }
                    closeSubsidiaryIfNecessary(ab, subsidiaryOpen, isMulti);
                    return WireActionResult.nondescriptSuccess();
                });

                items.push({
                    displayAs: itemName,
                    isSelected,
                    onChange,
                    image,
                });
            }

            return items;
        }

        return makeSimpleWireTableComponentHydratorConstructor(targetIB, (thb, hb) => {
            assert(hb !== undefined);

            const hostKeyEditable = hostKeyGetter(hb);
            if (hostKeyEditable?.onChangeToken === undefined) return undefined;

            const subsidiaryOpen = makeSubsidiaryOpenState(hb, withSubsidiary);

            const hostKeys = getRelationKeys(hostKeyEditable.value);

            let items = makeItems(thb, hostKeys, hostKeyEditable, subsidiaryOpen);

            const allSelected = items.filter(i => i.isSelected);

            const hasValue = allSelected.length > 0;

            const searchResult = applySearch(desc, appKind, hb, items);
            items = searchResult.items;
            const searchEditable = searchResult.searchEditable;

            if (!isRequired && style !== ChoiceStyle.Chips && hostKeyEditable.onChangeToken !== undefined) {
                items.unshift({
                    displayAs: "—",
                    isSelected: !isMulti && !hasValue,
                    onChange: registerActionRunner(hb, "unset", async ab => {
                        ab.valueChanged(defined(hostKeyEditable.onChangeToken), emptyValue, ValueChangeSource.User);
                        closeSubsidiaryIfNecessary(ab, subsidiaryOpen, isMulti);
                        return WireActionResult.nondescriptSuccess();
                    }),
                    image: undefined,
                });
            }

            const selectedItems = items.filter(i => i.isSelected);
            const labelAndImageForChosenItems = getLabelAndImageForChosenItems(
                selectedItems.length,
                selectedItems,
                getNoSelectionLabel(appKind, isRequired),
                appKind
            );

            const title = captionGetter?.(hb) ?? "";
            const { onTap, subsidiaryScreen } = makeSubsidiaryScreen(
                hb,
                appKind,
                componentID,
                items,
                title,
                hasImages,
                subsidiaryOpen,
                searchEditable
            );

            const component: WireChoiceComponent = {
                kind: WireComponentKind.List,
                format: ArrayScreenFormat.Choice,
                title,
                style,
                isMulti,
                maxChoices: undefined,
                isRequired,
                items,
                labelAndImageForChosenItems,
                onTap,
                hasImages,
                pagesSearch: searchEditable,
            };

            return {
                component,
                editsInContext: isInContext,
                hasValue,
                isValid: hasValue || !isRequired,
                subsidiaryScreen,
            };
        });
    }

    public inflateContent<T extends ChoiceArrayContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        captionGetter: WireStringGetter | undefined,
        containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        assert(containingRowIB !== undefined);

        const {
            adc: { appKind },
        } = ib;

        const style = choicePagesStylePropertyHandler.getEnum(desc);
        const isRequired = isRequiredPropertyHandler.getSwitch(desc);
        const withSubsidiary = style === ChoiceStyle.Dropdown && appKind === AppKind.App;

        const linkTarget = getColumnLinkTargetFromValueProperty(ib.adc, containingRowIB.tables, desc?.valueProperty);
        if (linkTarget !== undefined) {
            return this.inflateLinkPicker(
                containingRowIB,
                ib,
                componentID,
                desc,
                style,
                isRequired,
                withSubsidiary,
                linkTarget,
                captionGetter
            );
        }

        const {
            tokenMaker: valueTokenMaker,
            tableAndColumn: valueTableAndColumn,
            isInContext,
        } = containingRowIB.getValueSetterForProperty(desc.valueProperty, "select");
        if (valueTableAndColumn === undefined) return undefined;

        const [valueGetter] = containingRowIB.getValueGetterForProperty(desc.valueProperty, false, {
            inOutputRow: true,
        });

        const [choiceValueGetter] = ib.getValueGetterForProperty(desc.optionProperty, false);
        const choiceValueColumnName = getColumnProperty(desc.optionProperty);
        const choiceValueColumn = maybeGetTableColumn(ib.tables.input, choiceValueColumnName);

        const displayValueColumnName = getColumnProperty(desc.displayProperty);

        let choiceColumnName: string | undefined;
        let choiceNameGetter: WireValueGetter;
        const [maybeNameGetter, nameType] = ib.getValueGetterForProperty(desc.displayProperty, true);
        if (nameType !== undefined && isPrimitiveType(nameType)) {
            choiceColumnName = displayValueColumnName;
            choiceNameGetter = maybeNameGetter;
        } else {
            choiceColumnName = choiceValueColumnName;
            choiceNameGetter = ib.getValueGetterForProperty(desc.optionProperty, true)[0];
        }

        const [imageSourceGetter, imageType] = ib.getValueGetterForProperty(desc.imageProperty, false);
        const hasImages = supportsImage(desc) && imageType !== undefined;

        let defaultValueGetter: WireValueGetter | undefined;
        if (
            doesMutatingScreenKindSupportDefaultValues(ib.mutatingScreenKind, desc.valueProperty) &&
            desc.defaultValue !== undefined
        ) {
            defaultValueGetter = containingRowIB.getValueGetterForProperty(desc.defaultValue, false)[0];
        }

        const isMulti = supportsMulti(desc) && isMultiPropertyHandler.getSwitch(desc);
        const maxChoices = isMulti ? maxChoicesPropertyHandler.getNumber(desc) : undefined;

        const componentEnricher = inflateComponentEnricher<WireChoiceComponent>(
            ib,
            desc as unknown as InlineListComponentDescription
        );

        const forQuery =
            style === ChoiceStyle.Dropdown && isBigTableOrExternal(ib.tables.input) && appKind === AppKind.Page;
        const searchableColumns = makeSearchableColumnsForList(ib, true, forQuery, componentID);

        function makeSelectedValue(chb: WireRowComponentHydrationBackend) {
            let selectedValue = valueGetter(chb);
            // We set the empty string to `undefined` here so that we don't
            // display the em-dash for empty values.
            if (!isPrimitiveValue(selectedValue) || selectedValue === "") {
                selectedValue = undefined;
            }

            const selectedValueOnChangeToken = valueTokenMaker(chb);
            if (selectedValueOnChangeToken === false) return undefined;

            const hasValue = isBound(selectedValue) && isNotEmpty(selectedValue);

            return { selectedValue, selectedValueOnChangeToken, hasValue };
        }

        function makeItems(
            thb: WireTableComponentHydrationBackend,
            chb: WireRowComponentHydrationBackend,
            data: readonly Row[],
            selectedValueOrStrings: readonly DefinedPrimitiveValue[],
            selectedValueOnChangeToken: string | undefined,
            numLimitRows: number | undefined,
            subsidiaryOpen: SubsidiaryOpen
        ) {
            const namesAndImagesForValues = new DefinedPrimitiveValueMap<{
                name: string;
                image: string | undefined;
            }>();
            for (const row of data) {
                const rhb = thb.makeHydrationBackendForRow(row);

                const value = choiceValueGetter(rhb);
                if (!isPrimitiveValue(value) || value === undefined) continue;
                if (namesAndImagesForValues.has(value)) continue;

                const displayAs = asString(nullLoadingToUndefined(choiceNameGetter(rhb)));
                const image = hasImages ? asMaybeString(nullLoadingToUndefined(imageSourceGetter(rhb))) : undefined;

                // Empty strings are treated like `undefined`, and we
                // can't display empty strings, so we ignore items that
                // don't have display values.
                if (value === "" || displayAs === "") continue;

                namesAndImagesForValues.set(value, {
                    name: displayAs,
                    image,
                });

                if (numLimitRows !== undefined && namesAndImagesForValues.size >= numLimitRows) {
                    break;
                }
            }

            const valuesAndNamesArray = Array.from(namesAndImagesForValues);
            let selectedValues: Set<DefinedPrimitiveValue>;
            if (forQuery) {
                // TODO: When our options table is queryable, then
                // `valuesAndNamesArray` will in general only contain a subset
                // of the available options, so we can't use it to determine
                // whether an option is valid or not, so we just don't do
                // that.  The exact way to handle this would be to make a
                // query that matches all option rows that are currently
                // selected.  Then we can use that query result as a
                // substitute for `valuesAndNamesArray`.
                selectedValues = new Set(selectedValueOrStrings);
            } else {
                // In some rare-ish use cases (shifts) for example,
                // we want to compare the time only of the selected value
                // and the option value.
                // https://github.com/glideapps/glide/issues/30199
                const targetOrChoiceIsTimeOnly =
                    (choiceValueColumn !== undefined && isTimeOnlyColumn(choiceValueColumn)) ||
                    (valueTableAndColumn?.column !== undefined && isTimeOnlyColumn(valueTableAndColumn.column));
                const areValuesEqualOpts = {
                    compareTimesOnly: targetOrChoiceIsTimeOnly && getFeatureSetting("enableTimeOnlyColumnComparisons"),
                };
                selectedValues = new Set(
                    mapFilterUndefined(valuesAndNamesArray, ([v]) => {
                        if (selectedValueOrStrings.some(s => areValuesEqual(s, v, areValuesEqualOpts))) {
                            return v;
                        }
                        return undefined;
                    })
                );
            }

            const makeOnChangeAction = (
                index: number,
                v: DefinedPrimitiveValue | (() => DefinedPrimitiveValue)
            ): WireAction | undefined => {
                if (selectedValueOnChangeToken === undefined) return undefined;
                // Either Typescript or Chrome 105.0.5195.127 has an issue where
                // breaking subsidiaryOpen out of `this` doesn't work, and the closure
                // includes `this`. That's bad, that's a memory leak. We work around that
                // by laundering subsidiaryOpen into a function call, which plugs the leak.
                return makeOnChangeActionWithoutAccidentalLeak(
                    chb,
                    subsidiaryOpen,
                    isMulti,
                    selectedValueOnChangeToken,
                    index,
                    v
                );
            };

            let haveSelected = false;
            const items: WireChoiceItem[] = valuesAndNamesArray.map(([v, nameAndImage], i) => {
                const isSelected = selectedValues.has(v);
                if (isSelected) {
                    haveSelected = true;
                }

                let onChange: WireAction | undefined;
                if (isMulti) {
                    const canSelectMore = maxChoices === undefined || selectedValues.size < maxChoices;
                    if (!isSelected && canSelectMore) {
                        if (forQuery) {
                            onChange = makeOnChangeAction(i, () => [...selectedValues, v].join(","));
                        } else {
                            onChange = makeOnChangeAction(i, () =>
                                mapFilterUndefined(valuesAndNamesArray, ([w]) => {
                                    if (v === w || selectedValues.has(w)) {
                                        return asString(w);
                                    } else {
                                        return undefined;
                                    }
                                }).join(",")
                            );
                        }
                    } else if (selectedValues.size > 1 || !isRequired) {
                        onChange = makeOnChangeAction(i, () =>
                            Array.from(selectedValues)
                                .filter(w => w !== v)
                                .join(",")
                        );
                    }
                } else {
                    if (!isSelected) {
                        onChange = makeOnChangeAction(i, v);
                    } else if (!isRequired) {
                        onChange = makeOnChangeAction(i, "");
                    }
                }
                const { name, image } = nameAndImage;
                return { displayAs: name, image, isSelected, onChange };
            });

            let needsNone: boolean;
            if (appKind === AppKind.App) {
                needsNone = !isRequired && !isMulti;
            } else {
                needsNone = !isRequired && !isMulti && style !== ChoiceStyle.Chips;
            }

            let noneItem: WireChoiceItem | undefined;
            if (needsNone) {
                noneItem = {
                    displayAs: "—",
                    isSelected: !isMulti && selectedValues.size === 0,
                    onChange: makeOnChangeAction(-1, ""),
                    image: undefined,
                };
            }

            const isValid = !isRequired || haveSelected;

            return { items, isValid, noneItem };
        }

        class HydratorBase implements WireTableComponentPreHydrator {
            protected hasValue: boolean | undefined;
            protected selectedValues: readonly DefinedPrimitiveValue[] | undefined;
            protected selectedValueOnChangeToken: string | undefined;

            constructor(protected readonly chb: WireRowComponentHydrationBackend | undefined) {}

            public preHydrate(): WireComponentPreHydrationResult {
                const { chb } = this;
                assert(chb !== undefined);

                const selectedValueResult = makeSelectedValue(chb);
                if (selectedValueResult === undefined) return [false, undefined];

                const { selectedValue } = selectedValueResult;
                if (isMulti) {
                    this.selectedValues = splitValue(selectedValue);
                } else {
                    if (selectedValue !== undefined && selectedValue !== "") {
                        this.selectedValues = [selectedValue];
                    } else {
                        this.selectedValues = [];
                    }
                }

                ({ selectedValueOnChangeToken: this.selectedValueOnChangeToken, hasValue: this.hasValue } =
                    selectedValueResult);

                // ##ncmDefaultValues:
                // The way we're using state here is quite tricky: `hadValue`
                // should be set if we've ever had a valid value for this
                // component.  If we haven't, and we have a default value, we set
                // the choice to the default value.  The only time we change this
                // state in a follow-up is when setting the default value.  The
                // other two times it's set implicitly via the `getState` default
                // value.
                if (this.hasValue) {
                    // If this is the first time we `getValue`, then the value
                    // will always be true, and we'll never use the default value.
                    chb.getState("hadValue", (v): v is boolean => typeof v === "boolean", true, false);
                }

                let followUp: ((ab: WireActionBackend) => void) | undefined;
                if (!this.hasValue && this.selectedValueOnChangeToken !== undefined) {
                    const defaultValue = defaultValueGetter?.(chb);
                    if (
                        isBound(defaultValue) &&
                        !isLoadingValue(defaultValue) &&
                        isPrimitive(defaultValue) &&
                        defaultValue !== undefined
                    ) {
                        // Here we know that we have a default value that we can
                        // set, so we check whether the state is `true` by getting
                        // it with a default value of `false`.  Note that we
                        // immediately set it to `true` in the follow-up.
                        const hadValue = chb.getState("hadValue", isBoolean, false, false);
                        if (!hadValue.value) {
                            followUp = ab => {
                                ab.valueChanged(
                                    defined(this.selectedValueOnChangeToken),
                                    defaultValue,
                                    ValueChangeSource.Internal
                                );
                                ab.valueChanged(hadValue.onChangeToken, true, ValueChangeSource.Internal);
                            };
                        }
                    }
                }

                return [true, followUp];
            }
        }

        // We need a manual hydrator class so we can have a follow-up for
        // default values.
        class Hydrator extends HydratorBase implements WireTableComponentHydrator {
            // If we don't break this out into its own object, makeOnChangeAction will depend on
            // `this`, which will contain a reference to `chb`. This will eventually contain a
            // reference to an old result of makeOnChangeAction, creating a memory leak.
            private subsidiaryOpen: SubsidiaryOpen = { current: undefined };

            public hydrate(
                thb: WireTableComponentHydrationBackend,
                _dynamicFilterResult: DynamicFilterResult | undefined,
                numLimitRows: number | undefined
            ): WireTableComponentHydrationResult | undefined {
                const data = thb.tableScreenContext.asArray();
                if (data.length === 0) return undefined;

                const { chb } = this;
                assert(chb !== undefined);

                const title = captionGetter?.(chb) ?? "";

                const { subsidiaryOpen } = this;
                subsidiaryOpen.current = makeSubsidiaryOpenState(chb, withSubsidiary);

                const itemsResult = makeItems(
                    thb,
                    chb,
                    data,
                    defined(this.selectedValues),
                    this.selectedValueOnChangeToken,
                    numLimitRows,
                    subsidiaryOpen
                );
                const { isValid, noneItem } = itemsResult;
                let items = itemsResult.items;

                // Get the info for chosen items _before_ applying search.
                const selectedItems = items.filter(i => i.isSelected);
                const labelAndImageForChosenItems = getLabelAndImageForChosenItems(
                    selectedItems.length,
                    selectedItems,
                    getNoSelectionLabel(appKind, isRequired),
                    appKind
                );

                // Now we can apply search.
                const searchResult = applySearch(desc, appKind, chb, items);
                items = searchResult.items;
                const searchEditable = searchResult.searchEditable;

                if (noneItem !== undefined) {
                    items.unshift(noneItem);
                }

                const { onTap, subsidiaryScreen } = makeSubsidiaryScreen(
                    chb,
                    appKind,
                    componentID,
                    items,
                    title,
                    hasImages,
                    this.subsidiaryOpen.current,
                    searchEditable
                );

                return {
                    component: componentEnricher({
                        kind: WireComponentKind.List,
                        format: ArrayScreenFormat.Choice,
                        title,
                        style,
                        items,
                        labelAndImageForChosenItems,
                        hasImages,
                        isRequired,
                        isMulti,
                        maxChoices,
                        onTap,
                        pagesSearch: searchEditable,
                    }) as WireComponent,
                    isValid,
                    editsInContext: isInContext,
                    hasValue: defined(this.hasValue),
                    subsidiaryScreen,
                };
            }
        }

        class HydratorForQuery extends HydratorBase implements WireTableComponentQueryHydrator {
            constructor(
                chb: WireRowComponentHydrationBackend,
                private readonly query: Query,
                private readonly contentHB: WireTableComponentHydrationBackend | undefined
            ) {
                super(chb);
            }

            public hydrateForQuery(): WireTableComponentHydrationResult | undefined {
                const { chb, contentHB } = this;
                assert(chb !== undefined && contentHB !== undefined);

                const isOpenEditable = chb.getState("choiceIsOpen", isBoolean, false, false);

                const title = captionGetter?.(chb) ?? "";

                // FIXME: Only apply search to the display value, like we do
                // in the regular Choice component?  Unfortunately the display
                // value could be a computed column.
                const { query, searchEditable } = applySearchToQuery(
                    chb,
                    searchableColumns,
                    this.query,
                    500,
                    choiceColumnName
                );
                if (query === undefined) return undefined;

                // Note that we only query a single selected item to show its
                // display value in the component.
                let selectedItems: readonly WireChoiceItem[] | undefined;
                let isValid: boolean | undefined;

                const selectedValues = defined(this.selectedValues);
                if (
                    selectedValues.length === 1 &&
                    selectedValues[0] !== undefined &&
                    choiceValueColumn !== undefined &&
                    isDataSourceColumn(choiceValueColumn, true)
                ) {
                    const selectedValueQuery = this.query.withCondition({
                        ...generateEqualsQueryCondition(choiceValueColumn.name, selectedValues[0]),
                        negated: false,
                    });
                    const selectedValueTable = chb.resolveQueryAsTable(selectedValueQuery);
                    if (selectedValueTable !== undefined && !isLoadingValue(selectedValueTable)) {
                        const [row] = selectedValueTable.asMutatingArray();
                        if (row !== undefined) {
                            const maybeItems = makeItems(
                                contentHB,
                                chb,
                                [row],
                                selectedValues,
                                this.selectedValueOnChangeToken,
                                undefined,
                                { current: undefined }
                            );
                            ({ items: selectedItems, isValid } = maybeItems);
                        }
                    }
                }

                let items: WireChoiceItem[] | undefined;
                let onTap: WireAction | undefined;
                if (!isOpenEditable.value) {
                    const onOpenToken = chb.registerAction("choice-open", async ab => {
                        return ab.valueChanged(isOpenEditable.onChangeToken, true, ValueChangeSource.User);
                    });
                    onTap = { token: onOpenToken };
                } else {
                    const table = chb.resolveQueryAsTable(query);
                    if (table === undefined) return undefined;

                    if (!isLoadingValue(table)) {
                        const itemsResult = makeItems(
                            contentHB,
                            chb,
                            table.asArray(),
                            selectedValues,
                            this.selectedValueOnChangeToken,
                            undefined,
                            { current: undefined }
                        );
                        ({ items, isValid } = itemsResult);
                        if (itemsResult.noneItem !== undefined) {
                            items.unshift(itemsResult.noneItem);
                        }
                    } else {
                        onTap = { token: WireActionBusy };
                    }
                }

                const labelAndImageForChosenItems = getLabelAndImageForChosenItems(
                    selectedValues.length,
                    // This is the single selected item, or `undefined`
                    selectedItems,
                    getNoSelectionLabel(appKind, isRequired),
                    appKind
                );

                let isComponentValid: boolean;
                if (isValid !== undefined) {
                    // We know for sure whether we're valid.
                    isComponentValid = isValid;
                } else if (!isRequired) {
                    // We don't know for sure, but we don't require a value,
                    // so we suppose we're valid.  This can occur if we don't
                    // have a value, so we never checked, or because we have
                    // more than one and we don't want to do a query for
                    // multiple, just to check whether our initial value is
                    // valid.
                    isComponentValid = true;
                } else {
                    // We do require a value, so we better have at least one.
                    isComponentValid = selectedValues.length > 0;
                }

                return {
                    component: componentEnricher({
                        kind: WireComponentKind.List,
                        format: ArrayScreenFormat.Choice,
                        title,
                        style,
                        items,
                        labelAndImageForChosenItems,
                        hasImages,
                        isRequired,
                        isMulti,
                        maxChoices,
                        onTap,
                        pagesSearch: searchEditable,
                    }) as WireComponent,
                    // If we know for sure whether we have something valid, we
                    // take that.  If we don't,
                    isValid: isComponentValid,
                    editsInContext: isInContext,
                    hasValue: defined(this.hasValue),
                };
            }
        }

        function makeHydrator(rhb: WireRowComponentHydrationBackend) {
            return new Hydrator(rhb);
        }

        if (forQuery && style === ChoiceStyle.Dropdown) {
            return {
                makeHydrator,
                makeHydratorForQuery(
                    rhb: WireRowComponentHydrationBackend,
                    query: Query,
                    contentHB: WireTableComponentHydrationBackend | undefined
                ): WireTableComponentQueryHydrator {
                    return new HydratorForQuery(rhb, query, contentHB);
                },
            };
        } else {
            return { makeHydrator };
        }
    }

    public convertArrayScreenToPage(): ComponentDescription | undefined {
        // Choice is not supported in array screens, even though the handler suggests it is.
        return undefined;
    }

    public convertInlineToPage(
        desc: InlineListComponentDescription & ChoiceArrayContentDescription
    ): (InlineListComponentDescription & ChoiceArrayContentDescription) | undefined {
        // Pages don't support radio buttons, but a dropdown should be good enough.
        const currentStyle = choicePagesStylePropertyHandler.getEnum(desc);
        const newStyle = currentStyle === ChoiceStyle.Inline ? ChoiceStyle.Chips : currentStyle;

        return { ...desc, style: makeEnumProperty(newStyle) };
    }
}
