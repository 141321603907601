import process from "process";

import { hasOwnProperty, exceptionToError } from "@glideapps/ts-necessities";

export function timestampMs(): number {
    if (process.hrtime !== undefined) {
        const [nowSecs, nowNs] = process.hrtime();
        return nowSecs * 1000 + nowNs / 1e6;
    } else {
        return performance.now();
    }
}

type TimedReturnError = Readonly<{ error: Error; durationMs: number }>;
type TimedReturnSuccess<T> = Readonly<{ response: T; durationMs: number }>;

type TimedReturn<T> = TimedReturnSuccess<T> | TimedReturnError;

export function isTimedError<T>(x: TimedReturn<T>): x is TimedReturnError {
    return hasOwnProperty(x, "error");
}

export async function timed<T>(op: () => Promise<T>): Promise<TimedReturn<T>> {
    const startTime = timestampMs();
    try {
        const response = await op();
        return { durationMs: timestampMs() - startTime, response };
    } catch (error: unknown) {
        return { durationMs: timestampMs() - startTime, error: exceptionToError(error) };
    }
}

export function timedSync<T>(op: () => T): TimedReturn<T> {
    const startTime = timestampMs();
    try {
        const response = op();
        return { durationMs: timestampMs() - startTime, response };
    } catch (error: unknown) {
        return { durationMs: timestampMs() - startTime, error: exceptionToError(error) };
    }
}
