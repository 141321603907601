import type { AppLoginAuthDataFromPlay, PublishedAppSnapshot } from "@glide/common-core/dist/js/Database";
import { getLastKnownAppLogin, saveAppLogin } from "./utils/app-login";
import { defined } from "@glideapps/ts-necessities";
import { logError } from "@glide/support";
import React from "react";
import { getAppFacilities, setAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { MaterialAppFacilities } from "@glide/common-core/dist/js/utility/material-app-facilities";
import type { SerializedApp } from "@glide/app-description";
import { getAppFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { reloadBrowserWindow } from "@glide/common-core/dist/js/support/browser-reload";
import type { SerializablePluginMetadata } from "@glide/plugins";
import { forEachPluginAction, makePluginActionKind } from "@glide/plugins-utils";
import { registerPluginActionHandler } from "@glide/generator/dist/js/plugins/generate";
import { registerEventTrackers } from "@glide/plugin-events";
import { getPlayerEminenceFlags, makeAppEnvironmentForAppDescription } from "@glide/player-core";
import { definedMap } from "collection-utils";
import type { AppPublishInfo, MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import once from "lodash/once";
import "twin.macro";
import { registerDescriptionHandlers } from "@glide/generator/dist/js/components/description-handlers/register-all";
import { registerActionHandlers } from "@glide/generator/dist/js/actions/register-all";
import { registerArrayScreenHandlers } from "@glide/generator/dist/js/array-screens/register-all";
import { registerComponentHandlers } from "@glide/generator/dist/js/components/register-all";
import { registerYesCode } from "@glide/yes-code";
import { PagesPlayerWireApp } from "./player-wire-app";
import { AppContainer } from "./app-container";
import { setAppMetadata } from "./utils/set-app-metadata";
import { Play2AuthKind, getSupportedAuthenticationMethod } from "./player-auth/auth-kind";
import { AppThemeProvider } from "./app-theme-provider";
import { PlayerAuthScreen } from "./player-auth/auth-screen";
import { getWebDatabase } from "@glide/client-database";
import type { AuthState } from "./wire-utils/use-wire-backend";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { Play2Authenticator } from "@glide/auth-controller-core";
import { usePreAuth } from "./player-auth/use-pre-auth";
import { PlayerLoading } from "./player-loading";
import { WireAuthControllerProvider } from "./player-auth/wire-auth-controller";
import { PlayerObservability } from "@glide/player-frontend-observability";
import { setAppEnvironmentForAppID } from "@glide/common-core/dist/js/app-state-context";
import { PlayerIntegrationsAggregator } from "@glide/generator/dist/js/plugins/integrations-lib";
import { BillingEnforcement } from "./billing-enforcement";
import { HeartbeatManager } from "@glide/billing-heartbeat";

const registerForWire = once(() => {
    registerDescriptionHandlers();
    registerActionHandlers();
    registerArrayScreenHandlers();
    registerComponentHandlers();
    registerYesCode();
});

async function loadPublishedAppFromSnapshot(appID: string): Promise<PublishedAppSnapshot | undefined> {
    for (let i = 0; i < 10; i++) {
        const publishedAppFromSnapshot = await getAppFacilities().loadPublishedAppSnapshot(appID);

        if (publishedAppFromSnapshot !== undefined) {
            return publishedAppFromSnapshot;
        }

        logError(`Could not load app snapshot after ${i} retries`);
    }

    return undefined;
}

const registeredActionKinds = new Set<string>();
function registerPlugins(pluginMetadata: readonly SerializablePluginMetadata[]): void {
    forEachPluginAction(pluginMetadata, (plugin, action) => {
        const kind = makePluginActionKind(plugin, action);
        if (registeredActionKinds.has(kind)) return;
        registeredActionKinds.add(kind);
        registerPluginActionHandler(plugin, action);
    });

    registerEventTrackers(pluginMetadata);
}

const ensureAppFacilitiesAreSet = once(() => {
    // This is very ugly, but the constructor of the WebDatabase intializes the feature settings.
    getWebDatabase();

    setAppFacilities(new MaterialAppFacilities(PlayerIntegrationsAggregator));
});

interface LoadedAppState {
    readonly app: SerializedApp;
    readonly appEnvironment: MinimalAppEnvironment;
}

export const GlideAppPlayer: React.VFC = () => {
    // This is bad. We should not do this in render. This should be done by the App entry point.
    ensureAppFacilitiesAreSet();

    // I think we need to listen for a firestore document as well.
    const loginData = React.useMemo(() => getLastKnownAppLogin(), []);

    React.useEffect(() => {
        if (loginData === undefined) {
            return;
        }
        setAppMetadata(loginData);
    }, [loginData]);

    if (loginData === undefined) {
        return <div>No login data...</div>;
    }

    return (
        <AppThemeProvider baseTheme={loginData.theme}>
            <AppContainer appFeatures={loginData.features}>
                <GlideAppPlayerWithLoginData loginData={loginData} />
            </AppContainer>
        </AppThemeProvider>
    );
};

interface GlideAppPlayerWithLoginDataProps {
    readonly loginData: AppLoginAuthDataFromPlay;
}

const GlideAppPlayerWithLoginData: React.VFC<GlideAppPlayerWithLoginDataProps> = p => {
    const { loginData } = p;

    const authKind = getSupportedAuthenticationMethod(loginData);

    const appID = defined(useAppID());
    const [authenticator] = React.useState(() => new Play2Authenticator(appID));

    const preAuthState = usePreAuth(authenticator, authKind, appID);

    if (authKind === Play2AuthKind.unsupportedMethod) {
        return <p>Authentication method not supported</p>;
    }

    if (preAuthState === "pre-authing") {
        return <PlayerLoading iconImage={loginData.iconImage} />;
    }

    return <PreAuthedGlideAppPlayer loginData={loginData} authenticator={authenticator} preAuthState={preAuthState} />;
};

interface PreAuthedGlideAppPlayerProps {
    readonly loginData: AppLoginAuthDataFromPlay;
    readonly authenticator: Play2Authenticator;
    readonly preAuthState: "needs-authentication" | "authed-or-unnecessary";
}

const PreAuthedGlideAppPlayer: React.VFC<PreAuthedGlideAppPlayerProps> = p => {
    const { loginData, authenticator, preAuthState } = p;

    const [authState, setAuthState] = React.useState<AuthState>(
        preAuthState === "needs-authentication" ? "needs-authentication" : "not-authenticated"
    );

    const onLoggedIn = React.useCallback(() => {
        setAuthState("authenticated");
    }, []);

    if (authState === "needs-authentication") {
        const { features, iconImage, title } = loginData;

        return (
            <PlayerAuthScreen
                onLoggedIn={onLoggedIn}
                appFeatures={features}
                iconImage={iconImage}
                appTitle={title}
                authenticator={authenticator}
            />
        );
    }

    return (
        <AuthenticatedGlideAppPlayer
            loginData={loginData}
            authState={authState}
            preAuthState={preAuthState}
            authenticator={authenticator}
        />
    );
};

interface AuthenticatedGlideAppPlayerProps {
    readonly loginData: AppLoginAuthDataFromPlay;
    readonly authState: AuthState;
    readonly authenticator: Play2Authenticator;
    readonly preAuthState: "needs-authentication" | "authed-or-unnecessary";
}

const publishedAppLoadedMetric = PlayerObservability.makePlayerMetric("published-app-loaded", []);
const appLoadedTiming = PlayerObservability.makePlayerTiming("app-loaded");

const AuthenticatedGlideAppPlayer: React.VFC<AuthenticatedGlideAppPlayerProps> = p => {
    const { loginData, authState, authenticator, preAuthState } = p;

    const { customDomain, shortName, title } = loginData;

    const [loadedAppState, setLoadedAppState] = React.useState<LoadedAppState | undefined>();

    // This is `this.loadApp` in the original GlideAppPlayer
    const loadApp = React.useCallback(
        async (appID: string) => {
            const stop = publishedAppLoadedMetric.start();
            const publishedAppFromSnapshot = await loadPublishedAppFromSnapshot(appID);

            if (publishedAppFromSnapshot === undefined) {
                reloadBrowserWindow("Could not load app snapshot");
                return;
            }

            const { app } = publishedAppFromSnapshot;

            registerPlugins(app.pluginMetadata ?? []);
            registerForWire();

            const features = getAppFeatures(app);

            const appFacilities = getAppFacilities();

            const getPublishInfo = (): AppPublishInfo => {
                return {
                    isPublished: true,
                    shortName: shortName,
                    customDomain: customDomain,
                };
            };

            const appEnvironment = makeAppEnvironmentForAppDescription(
                app,
                definedMap(appID, id => appFacilities.getInitialSchemaForAppID?.(id)),
                appFacilities,
                authenticator,
                features,
                false,
                "player",
                getPublishInfo,
                app.pluginMetadata ?? [],
                getPlayerEminenceFlags
            );
            setAppEnvironmentForAppID(appEnvironment);

            stop({});

            // We only care about time from HTML to app loaded if we pre-authed.
            // If not, we'd be counting the time it takes a user to authenticate...
            if (preAuthState === "authed-or-unnecessary") {
                appLoadedTiming.track();
            }

            setLoadedAppState({
                app,
                appEnvironment,
            });
        },
        [authenticator, customDomain, preAuthState, shortName]
    );

    React.useEffect(() => {
        const appID = loginData.appID;
        const heartbeatManager = new HeartbeatManager(appID, getAppFacilities());

        heartbeatManager.enter();

        return () => {
            heartbeatManager.exit();
        };
    }, [loginData.appID]);

    React.useEffect(() => {
        const appID = loginData.appID;
        saveAppLogin(loginData, appID, Date.now());

        // This comes from `this.init` in the original GlideAppPlayer.
        // I don't know why we wait 2 seconds there.
        // It might just be some "hopeful" amount of time.
        if ("serviceWorker" in navigator && navigator.serviceWorker.controller !== null) {
            navigator.serviceWorker.controller.postMessage("loadAppID/" + appID);
        } else {
            setTimeout(() => {
                if ("serviceWorker" in navigator && navigator.serviceWorker.controller !== null) {
                    navigator.serviceWorker.controller.postMessage("loadAppID/" + appID);
                }
            }, 2000);
        }

        void loadApp(appID);
    }, [loadApp, loginData]);

    if (loadedAppState === undefined) {
        return <PlayerLoading iconImage={loginData.iconImage} />;
    }

    const { app, appEnvironment } = loadedAppState;

    return (
        <WireAuthControllerProvider authenticator={authenticator} appTitle={title}>
            <PagesPlayerWireApp appEnvironment={appEnvironment} serializedApp={app} authState={authState} />
            <BillingEnforcement serializedApp={app} />
        </WireAuthControllerProvider>
    );
};

// Default export for lazy loading.
// Only used to lazy load play2 in the existing Webpack app.
// Once we have a proper player App we should eagerly load this.
export default GlideAppPlayer;
