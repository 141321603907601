import { createPortal } from "react-dom";
import styled, { useTheme } from "styled-components";

import { isPlayer } from "../../routes";
import { ActivitySpinnerStyle } from "./activity-spinner-style";
import type { RuntimeTheme } from "@glide/common";

// borrowed from lib/constants
const APP_MODAL_ROOT = "app-modal-root";
const SpinDiv = styled.div`
animation: spin linear infinite 0.5s;
@keyframes spin {
    from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
}
`;

export const getSpinner = (fgColor: string, id?: string) => {
    // If no id is specified, the svg will use the default styles of absolute positioning
    // By passing an different id, you can use the spinner inline.
    const svgID = id === undefined ? "loader-1" : id;
    return (
        <SpinDiv>
            <svg
                version="1.1"
                id={svgID}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="40px"
                height="40px"
                viewBox="0 0 40 40"
                enableBackground="new 0 0 40 40"
                xmlSpace="preserve">
                <path
                    opacity="0.2"
                    fill={fgColor}
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                />
                <path
                    fill={fgColor}
                    d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
C22.32,8.481,24.301,9.057,26.013,10.047z"></path>
            </svg>
        </SpinDiv>
    );
};

export const ActivitySpinner: React.VFC = () => {
    const theme = useTheme() as RuntimeTheme;

    const spinner = <ActivitySpinnerStyle>{getSpinner(theme.primaryAccentColor)}</ActivitySpinnerStyle>;

    let target = document.getElementById(APP_MODAL_ROOT);
    if (target === null && isPlayer()) {
        target = document.getElementById("portal");
    }

    if (target === null) return null;
    const portal = createPortal(spinner, target);
    return portal;
};
