// NOTE: This is part of the lower level of the New Computation Model, and
// should be kept isolated from non-trivial Glide dependencies.  In
// particular, it shouldn't have to know anything about Glide table/column
// types/schemas.

import { assert } from "@glideapps/ts-necessities";
import toPairs from "lodash/toPairs";
import { convertValueFromSerializable } from "@glide/data-types";
import {
    type RowIndex,
    isBaseRowIndex,
    MutableTable,
    makeLoadingValue,
    type Table,
    type GroundValue,
    type Row,
    isLoadingValue,
    type ActionTableKeeper,
    type LoadingValue,
    type Namespace,
    type IncomingSlot,
    fullDirt,
} from "@glide/computation-model-types";
import type { DocumentData } from "@glide/support";

// Dirt:
// * pushes dirt on all cells that are modified.
export class SimpleTableKeeper implements ActionTableKeeper {
    private _ns: Namespace | undefined;
    private _table: MutableTable | LoadingValue = makeLoadingValue();
    public readonly symbolicRepresentation = "simple-table-keeper";

    private forceTable(): MutableTable {
        if (isLoadingValue(this._table)) {
            this._table = new MutableTable();
        }
        return this._table;
    }

    public get table(): Table {
        const needsPush = isLoadingValue(this._table);
        const table = this.forceTable();
        if (needsPush) {
            this._ns?.pushDirt(this, fullDirt);
        }
        return table;
    }

    public setIsLoaded(): void {
        // for side-effect
        this.table;
    }

    public getSlots(): readonly IncomingSlot[] {
        return [];
    }

    public recompute(): GroundValue {
        return this._table;
    }

    private pushDirtForRow(rowID: string): void {
        this._ns?.pushDirt(this, { kind: "row", rowID, columns: true });
    }

    public getRow(rowID: string): Row | LoadingValue | undefined {
        if (isLoadingValue(this._table)) return this._table;
        return this._table.get(rowID);
    }

    // Takes possession of the row
    public addRow(row: Row): void {
        const table = this.forceTable();

        assert(!table.has(row.$rowID));

        table.set(row.$rowID, row);
        this.pushDirtForRow(row.$rowID);
    }

    public updateRowWithPartialData(rowIndex: RowIndex, updates: DocumentData): void {
        assert(!isBaseRowIndex(rowIndex));
        assert(rowIndex.keyColumnName === "$rowID");

        const rowID = rowIndex.keyColumnValue;
        assert(typeof rowID === "string");

        const row = this.forceTable().get(rowID);
        if (row === undefined) return;

        const columns = new Set<string>();
        for (const [k, v] of toPairs(updates)) {
            row[k] = convertValueFromSerializable(v);
            columns.add(k);
        }

        this._ns?.pushDirt(this, { kind: "row", rowID, columns });
    }

    public setColumnInRow(rowID: string, columnName: string, value: GroundValue): void {
        this.updateRowWithPartialData({ keyColumnName: "$rowID", keyColumnValue: rowID }, { [columnName]: value });
    }

    public addInvisibleRow(row: Row): void {
        assert(!row.$isVisible);

        this.addRow(row);
    }

    public deleteRow(rowID: string): void {
        const table = this.forceTable();

        const row = table.get(rowID);
        if (row === undefined) return;

        assert(row.$isVisible);
        table.delete(rowID);
        this.pushDirtForRow(rowID);
    }

    public deleteInvisibleRow(rowID: string): void {
        const table = this.forceTable();

        const row = table.get(rowID);
        if (row === undefined) return;

        assert(!row.$isVisible);
        table.delete(rowID);
        this.pushDirtForRow(rowID);
    }

    public deleteAllRows(): void {
        this.forceTable().clear();
        this._ns?.pushDirt(this, fullDirt);
    }

    public setDirty(): void {
        return;
    }

    public get isDirty(): boolean {
        return false;
    }

    public connect(ns: Namespace): void {
        this._ns = ns;
    }
}
