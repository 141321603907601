import "twin.macro";

import { ErrorBoundary, TailwindThemeProvider } from "@glide/common";
import {
    type WireCommentItem,
    type WireCommentsComponent,
    CommentsStyle,
} from "@glide/fluent-components/dist/js/base-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { isDefined } from "@glide/support";
import { UIBackgroundStyle, UIButtonAppearance } from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";
import classNames from "classnames";
import * as React from "react";

import { useScrollRef } from "../../../chrome/content/content-common";
import { useIsCommentScreen } from "../../../chrome/content/lib/screen-special-cases";
import { MarkdownView } from "../../../components/markdown/markdown-view";
import { Wrapper } from "../../wire-rich-text/wire-rich-text-style";
import { extractActions, runActionAndHandleURL, useIsInsideModal } from "../../../wire-lib";
import { WireButton } from "../../wire-button/wire-button";
import { ControlledWireMenuButton, WireMenuButton } from "../../wire-menu-button/wire-menu-button";
import { ChatAuthorAndTime, CommentAuthorAndTime, UserAvatar } from "./comment-author-and-time";
import { EmptyComments } from "./empty-comment";
import { SectionStyleProvider } from "../../wire-container/wire-container";
import isEmpty from "lodash/isEmpty";
import { useWireAppTheme } from "../../../utils/use-wireapp-theme";
import { defaultWireAppAccentContextOverlay, defaultWireAppAccentContextOverlayUndo, mergeTheme } from "@glide/theme";

interface CommentsGroupProps {
    readonly items: readonly WireCommentItem[];
    readonly backend: WireBackendInterface;
    readonly showMoreComments: WireCommentsComponent["showMoreComments"];
    readonly emptyMessage: WireCommentsComponent["emptyMessage"];
    readonly commentsListRef?: React.RefObject<HTMLUListElement>;
    readonly textAreaActive: boolean;
    readonly componentStyle: CommentsStyle;
}

export const CommentsGroup: React.VFC<CommentsGroupProps> = p => {
    const { items, backend, showMoreComments, emptyMessage, commentsListRef, textAreaActive, componentStyle } = p;
    const alreadyScrolledRef = React.useRef(false);
    const showMore = React.useCallback(
        () =>
            definedMap(showMoreComments, action => {
                runActionAndHandleURL(action, backend, true);
            }),
        [backend, showMoreComments]
    );

    const isCommentsScreen = useIsCommentScreen();
    const scrollRef = useScrollRef();
    React.useEffect(() => {
        if (isCommentsScreen && !alreadyScrolledRef.current && isDefined(scrollRef) && isDefined(scrollRef.current)) {
            const scrollHeight = scrollRef.current.scrollHeight;
            scrollRef.current.scrollTop = scrollHeight;
            alreadyScrolledRef.current = true;
        }
    }, [isCommentsScreen, scrollRef]);

    useInfiniteScroll(commentsListRef, isDefined(showMoreComments) ? showMore : undefined, alreadyScrolledRef.current);

    if (items.length === 0) {
        return <EmptyComments emptyMessage={emptyMessage} componentStyle={componentStyle} />;
    }

    return (
        <div tw="flex flex-col max-w-full">
            {componentStyle === CommentsStyle.Comments ? (
                <CommentsStyleGroup
                    commentsListRef={commentsListRef}
                    isCommentsScreen={isCommentsScreen}
                    textAreaActive={textAreaActive}
                    items={items}
                    backend={backend}
                />
            ) : (
                <ChatStyleGroup
                    commentsListRef={commentsListRef}
                    isCommentsScreen={isCommentsScreen}
                    textAreaActive={textAreaActive}
                    items={items}
                    backend={backend}
                />
            )}
            {isDefined(showMoreComments) && !isCommentsScreen && (
                <WireButton appearance={UIButtonAppearance.Bordered} onClick={showMore}>
                    Show more
                </WireButton>
            )}
        </div>
    );
};

interface StyledGroupProps {
    readonly commentsListRef: React.RefObject<HTMLUListElement> | undefined;
    readonly isCommentsScreen: boolean;
    readonly textAreaActive: boolean;
    readonly items: readonly WireCommentItem[];
    readonly backend: WireBackendInterface;
}

interface ItemWithContext extends WireCommentItem {
    readonly prevIsSame: boolean;
    readonly prevIsSelf: boolean;
    readonly prevTimestampIsClose: boolean;
}

const A_MINUTE = 1000 * 60;

const ChatStyleGroup: React.VFC<StyledGroupProps> = p => {
    const { commentsListRef, items, backend, isCommentsScreen } = p;

    const itemsWithContext: ItemWithContext[] = [];
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        // Array access in our TS config always gives defined values, even though that makes no sense.
        const prevItem = items[i - 1] as (typeof items)[number] | undefined;
        const timeDifference = item.timestamp?.compareTo(prevItem?.timestamp);

        itemsWithContext.push({
            ...item,
            prevIsSame: prevItem?.userEmail === item.userEmail,
            prevIsSelf: prevItem?.isSelfComment ?? false,
            prevTimestampIsClose: timeDifference === undefined ? false : timeDifference < A_MINUTE,
        });
    }

    return (
        <ul
            data-testid="chat-group"
            ref={commentsListRef}
            className={isCommentsScreen ? "chat-screen" : "inline-chat"}
            tw="flex flex-col break-all [&.chat-screen]:pb-12"
        >
            {itemsWithContext.map((item, i) => {
                if (item.isSelfComment) {
                    return <SelfChatBubble key={i} item={item} backend={backend} />;
                } else {
                    return <ChatBubble key={i} item={item} backend={backend} />;
                }
            })}
        </ul>
    );
};

interface ChatBubbleProps {
    readonly item: ItemWithContext;
    readonly backend: WireBackendInterface;
}

const SelfChatBubble: React.VFC<ChatBubbleProps> = p => {
    const { item, backend } = p;

    const baseTheme = useWireAppTheme();
    const theme = mergeTheme(baseTheme, [defaultWireAppAccentContextOverlay]);

    const needsIntro = !item.prevIsSame;

    return (
        <li className={needsIntro ? "with-intro" : "without-intro"} tw="mt-3 [&.without-intro]:mt-1 first-of-type:mt-0">
            <SectionStyleProvider value={UIBackgroundStyle.Accent}>
                <TailwindThemeProvider theme={theme}>
                    <div tw="flex">
                        <BubbleContent
                            item={item}
                            backend={backend}
                            tw="bg-accent ml-auto rounded-tr-md [&.with-intro]:(pb-3)"
                            isSelfChat={true}
                        />
                    </div>
                </TailwindThemeProvider>
            </SectionStyleProvider>
        </li>
    );
};

const ChatBubble: React.VFC<ChatBubbleProps> = p => {
    const { item, backend } = p;

    const needsIntro = !item.prevIsSame;
    const needsExtraTopSpace = item.prevIsSelf;

    const baseTheme = useWireAppTheme();
    const theme = mergeTheme(baseTheme, [defaultWireAppAccentContextOverlayUndo]);

    return (
        <li
            className={classNames(
                needsIntro ? "with-intro" : "without-intro",
                needsExtraTopSpace ? "extra-top-space" : "regular-top-space"
            )}
            tw="mt-2 [&.extra-top-space]:(mt-3) [&.without-intro]:mt-1 first-of-type:mt-0"
        >
            <SectionStyleProvider value={UIBackgroundStyle.Highlight}>
                <TailwindThemeProvider theme={theme} tw="flex gap-x-2">
                    <div
                        className={needsIntro ? "with-intro" : "without-intro"}
                        tw="shrink-0 invisible [&.with-intro]:visible"
                    >
                        <UserAvatar photo={item.userPhoto} size="large" appID={backend.appID} />
                    </div>
                    <BubbleContent
                        item={item}
                        backend={backend}
                        tw="bg-n100 rounded-tl-md [&.with-intro]:(pb-3)"
                        isSelfChat={false}
                    />
                </TailwindThemeProvider>
            </SectionStyleProvider>
        </li>
    );
};

interface BubbleContentProps {
    readonly item: ItemWithContext;
    readonly backend: WireBackendInterface;
    readonly isSelfChat: boolean;
    readonly className?: string;
}

const BubbleContent: React.VFC<BubbleContentProps> = p => {
    const { item, backend, className, isSelfChat } = p;

    const needsIntro = !item.prevIsSame || !item.prevTimestampIsClose;

    const menuItems = React.useMemo(
        () => extractActions(item.itemActions, backend).filter(isDefined),
        [item.itemActions, backend]
    );

    const [isOpen, setIsOpen] = React.useState(false);

    const onContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (isEmpty(menuItems)) {
            return;
        }

        setIsOpen(true);
    };

    const timerID = React.useRef<ReturnType<typeof setTimeout>>();

    const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        e.preventDefault();
        timerID.current = setTimeout(() => {
            setIsOpen(true);
        }, 500);
    };

    const onTouchEnd = () => {
        if (timerID.current !== undefined) {
            clearTimeout(timerID.current);
        }
    };

    return (
        <div
            onContextMenu={onContextMenu}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            className={classNames(className, "group", needsIntro ? "with-intro" : "without-intro")}
            tw="max-w-[80%] px-3 py-2 rounded-2xl [user-select: none] relative"
        >
            {needsIntro && (
                <ChatAuthorAndTime
                    name={item.userName}
                    date={item.timestamp}
                    className={isSelfChat ? "self-chat" : "other-chat"}
                    tw="mb-1 [&.self-chat]:justify-end"
                />
            )}
            <Wrapper contextual={true}>
                {isDefined(item.comment) && <MarkdownView disableStyleInjection={true} markdown={item.comment} />}
            </Wrapper>
            {!isEmpty(menuItems) && (
                <ControlledWireMenuButton
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    menuItems={menuItems}
                    appearance={UIButtonAppearance.Floating}
                    className={isSelfChat ? "self-chat" : "other-chat"}
                    tw="absolute top-0 right-0 rounded-full [&.self-chat]:bg-accent [&.other-chat]:bg-n100 invisible group-page-hover:visible text-text-contextual-pale"
                />
            )}
        </div>
    );
};

const CommentsStyleGroup: React.VFC<StyledGroupProps> = p => {
    const { commentsListRef, isCommentsScreen, textAreaActive, items, backend } = p;

    const isInsideOverlay = useIsInsideModal();

    return (
        <ul
            data-testid="comment-group"
            ref={commentsListRef}
            className={classNames(isCommentsScreen && "comments-screen")}
            tw="flex flex-col break-all gap-y-0 gp-md:gap-y-2 [&.comments-screen]:(flex-col-reverse)"
        >
            {items.map((item, i) => (
                <Comment
                    testId={`comment-${i}`}
                    className={classNames(
                        isCommentsScreen && "comments-screen",
                        textAreaActive && "textarea-active",
                        isInsideOverlay && "inside-overlay"
                    )}
                    tw="[&.comments-screen.textarea-active]:first:(mb-14 gp-md:mb-8 [&.inside-overlay]:(mb-11 gp-md:mb-11)) [&.comments-screen]:first:(mb-7 [&.inside-overlay]:mb-11)"
                    key={i}
                    item={item}
                    backend={backend}
                />
            ))}
        </ul>
    );
};

interface CommentProps {
    readonly item: WireCommentItem;
    readonly backend: WireBackendInterface;
    readonly className?: string;
    readonly testId?: string;
}

const Comment: React.VFC<CommentProps> = p => {
    const { item, backend, className, testId } = p;
    const menuItems = React.useMemo(
        () => extractActions(item.itemActions, backend).filter(isDefined),
        [item.itemActions, backend]
    );
    const isCommentScreen = useIsCommentScreen();

    return (
        <li
            data-testid={testId}
            className={classNames(className, isCommentScreen ? "comment-screen" : "regular-screen")}
            tw="flex flex-col gap-y-3 py-3 not-last:[&.regular-screen]:(border-b border-border-base) not-first:[&.comment-screen]:(border-b border-border-base)"
        >
            <div tw="flex justify-between">
                <CommentAuthorAndTime
                    name={item.userName}
                    photo={item.userPhoto}
                    date={item.timestamp}
                    appID={backend.appID}
                />
                {menuItems.length > 0 && (
                    <WireMenuButton
                        tw="px-1.5! py-0.5!"
                        appearance={UIButtonAppearance.MinimalSecondary}
                        menuItems={menuItems}
                    />
                )}
            </div>
            <ErrorBoundary>
                <React.Suspense fallback={<div />}>
                    <Wrapper contextual={true}>
                        {isDefined(item.comment) && (
                            <MarkdownView disableStyleInjection={true} markdown={item.comment} />
                        )}
                    </Wrapper>
                </React.Suspense>
            </ErrorBoundary>
        </li>
    );
};

function useInfiniteScroll(
    listRef: React.RefObject<HTMLUListElement> | undefined,
    showMore: (() => void) | undefined,
    listening: boolean
) {
    const isCommentScreen = useIsCommentScreen();

    React.useEffect(() => {
        const handleObserver = (entities: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            if (!isDefined(showMore)) {
                observer.disconnect();
                return;
            }

            const target = entities[0];
            if (target.isIntersecting) {
                showMore();
                target.target.scrollIntoView({ behavior: "auto" });
                return;
            }
        };
        const observer = new IntersectionObserver(handleObserver, undefined);
        if (
            isCommentScreen &&
            listening &&
            isDefined(listRef) &&
            isDefined(listRef.current) &&
            isDefined(listRef.current.lastElementChild)
        ) {
            const el = listRef.current.lastElementChild;
            observer.observe(el);
        }

        return () => observer.disconnect();
    }, [isCommentScreen, listRef, listening, showMore]);
}
