import React from "react";
import toast from "react-hot-toast";
import classNames from "classnames";
import "twin.macro";
import { copyToClipboard } from "@glide/common";
import { AudioPlayer } from "@glide/common-core/dist/js/audio-player";
import { defined } from "@glideapps/ts-necessities";
import { logInfo } from "@glide/support";
import { clearExistingUserFromStorage } from "@glide/auth-controller-core";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import type { WireFrontendActionCallbacks } from "@glide/wire";

type CallbackUrlOpenerContext = {
    blockedWindowOpenUrl?: string;
    resetBlockedWindowUrl: () => void;
};
type WireFrontendActionCallbacksResult = CallbackUrlOpenerContext & WireFrontendActionCallbacks;

export const CallbackURLOpenerContext = React.createContext<CallbackUrlOpenerContext | undefined>(undefined);

export function useWireFrontendActionCallbacks(
    override: Partial<WireFrontendActionCallbacks> = {}
): WireFrontendActionCallbacksResult {
    const appID = defined(useAppID());
    const overrideRef = React.useRef(override);
    const [blockedWindowOpenUrl, setBlockedWindowOpenUrl] = React.useState<string | undefined>();
    const resetBlockedWindowUrl = React.useCallback(() => {
        setBlockedWindowOpenUrl(undefined);
    }, []);
    overrideRef.current = override;
    const actionCallbacks: WireFrontendActionCallbacks = React.useMemo(
        () => ({
            openLink: url => {
                if (overrideRef.current.openLink !== undefined) {
                    overrideRef.current.openLink(url);
                } else {
                    const windowOpenRef = window.open(url);
                    if (windowOpenRef === null) {
                        setBlockedWindowOpenUrl(url);
                    }
                }
            },
            showToast: (success: boolean, message: string) => {
                if (overrideRef.current.showToast !== undefined) {
                    overrideRef.current.showToast(success, message);
                    return;
                }
                toast.custom(t => (
                    <div
                        className={classNames(t.visible ? "enter" : "leave", success && "success")}
                        tw="bg-[#DC2C26] px-3 text-white font-medium text-sm rounded-[10px] ring-1 ring-w05A py-1.5 shadow-2xl-soft [&.success]:bg-[#059669] [&.enter]:animate-enter [&.leave]:animate-leave"
                    >
                        {message}
                    </div>
                ));
            },
            copyToClipboard: data => {
                if (overrideRef.current.copyToClipboard !== undefined) {
                    overrideRef.current.copyToClipboard(data);
                    return;
                }
                copyToClipboard(data);
            },
            playSound: sound => {
                if (overrideRef.current.playSound !== undefined) {
                    overrideRef.current.playSound(sound);
                    return;
                }
                const player = new AudioPlayer(sound);
                player.play();
            },
            showShareSheet: (url, message) => {
                if (overrideRef.current.showShareSheet !== undefined) {
                    overrideRef.current.showShareSheet(url, message);
                    return;
                }
                const nav = navigator as any;
                try {
                    logInfo("share sheet", message, url);
                    nav.share({
                        ...(message !== undefined ? { text: message } : { url: url }),
                    });
                } catch {
                    // do nothing
                }
            },
            signOut: () => {
                if (overrideRef.current.signOut !== undefined) {
                    overrideRef.current.signOut();
                    return;
                }

                clearExistingUserFromStorage(appID);
                window.location.reload();
            },
        }),
        [appID]
    );

    return {
        ...actionCallbacks,
        blockedWindowOpenUrl,
        resetBlockedWindowUrl,
    };
}
