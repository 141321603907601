import * as t from "io-ts";

export const msalScopeCodec = t.union([
    t.literal("openid"),
    t.literal("profile"),
    t.literal("offline_access"),
    t.literal("Files.ReadWrite.All"),
    t.literal("GroupMember.Read.All"),
    t.literal("User.Read"),
]);
export const msalScopeArrayCodec = t.array(msalScopeCodec);

export type MSALScope = t.TypeOf<typeof msalScopeCodec>;

export const minimumMSALScopes = new Set<MSALScope>([
    "openid",
    "profile",
    "User.Read",
    "Files.ReadWrite.All",
    "offline_access",
]);

export const sharepointMSALScopes = new Set<MSALScope>([
    ...minimumMSALScopes,
    // The way that drive discovery "works" in the Microsoft Graph involves
    // happening to know which groups your account can access, then
    // introspecting on those groups to figure out which drives they own.
    //
    // This cannot be a part of the minmum scopes, because it requires
    // administrator consent to use. Thus it has to be severable.
    "GroupMember.Read.All",
]);
