import "twin.macro";

import { browserIsOSX, useAccelerator, useEventListener } from "@glide/common";
import { assertNever } from "@glideapps/ts-necessities";
import { VisitResult, nullToUndefined, visit } from "@glide/support";
import flatMap from "lodash/flatMap";
import type Mousetrap from "mousetrap";
import * as React from "react";

import {
    type BaseDropdownProps,
    type Group,
    type InnerItemDescription,
    type ItemPath,
    type ItemsType,
    isGroup,
    isHeader,
    pointIsInShadow,
} from "../../lib/dropdown-types";
import { PopoutDropdownContent } from "./popout-dropdown-content";

interface Props<T> extends BaseDropdownProps<T> {
    prefer?: "left" | "right";
}

function itemPathEqual(a: ItemPath | undefined, b: ItemPath | undefined): boolean {
    if (a === b) return true;
    if (a === undefined || b === undefined) return false;
    if (a.length !== b.length) return false;
    return a.every((val, ind) => val === b[ind]);
}

export function PushDropdown<T extends {}>(p: Props<T>): React.ReactElement | null {
    const {
        items: itemsRaw,
        descriptionForItem,
        onItemClicked,
        selected,
        className,
        keyboardListenerMode,
        onItemActionClicked,
        onItemSelect: onItemSelectOuter,
        width,
        height,
        searchable,
        showSearchLineage,
        prefer,
        prelightDebounceMs,
        testId,
    } = p;

    interface State {
        needle: string;
        fromHover: boolean;
        prelighting: ItemPath | undefined;
        prelightPath: ItemPath | undefined;
    }
    interface MessageSetPrelight {
        kind: "prelight";
        prelight: InnerItemDescription<T> | undefined;
        fromHover: boolean;
    }
    interface MessageSetNeedle {
        kind: "needle";
        value: string;
    }
    interface MessageSetPrelighting {
        kind: "prelighting";
        prelighting: InnerItemDescription<T> | undefined;
    }
    type Message = MessageSetPrelight | MessageSetNeedle | MessageSetPrelighting;
    const [expand, setExpand] = React.useState(false);
    const [{ needle, fromHover, prelighting, prelightPath }, dispatchState] = React.useReducer(
        React.useCallback((s: State, a: Message): State => {
            if (a.kind === "needle") {
                return {
                    fromHover: false,
                    needle: a.value,
                    prelightPath: undefined,
                    prelighting: undefined,
                };
            } else if (a.kind === "prelight") {
                if (isHeader(a.prelight?.item)) return s;
                return {
                    ...s,
                    fromHover: a.fromHover,
                    prelightPath: a.prelight?.indexPath,
                    prelighting: undefined,
                };
            } else if (a.kind === "prelighting") {
                if (isHeader(a.prelighting?.item)) return s;
                return {
                    ...s,
                    prelighting: a.prelighting?.indexPath,
                };
            } else {
                assertNever(a);
            }
        }, []),
        {
            needle: "",
            fromHover: false,
            prelighting: undefined,
            prelightPath: undefined,
        }
    );

    const describeItem = React.useCallback(
        (item: ItemsType<T>, path: number[], group?: Group<T>): InnerItemDescription<T> | null => {
            if (item === null) return null;
            const isPrelight = itemPathEqual(path, prelightPath);
            const isPrelighting = itemPathEqual(path, prelighting);
            if (isGroup(item)) {
                const children = item.items.map((i, index) => describeItem(i, [...path, index], group ?? item));
                const result: InnerItemDescription<T> = {
                    item,
                    name: item.name,
                    icon: item.icon,
                    hint: item.hint,
                    prelight: isPrelight,
                    containsPrelight: isPrelight || children.some(c => c?.containsPrelight),
                    prelighting: isPrelighting,
                    containsPrelighting: isPrelighting || children.some(c => c?.containsPrelighting),
                    children,
                    isSelected: children.some(c => c?.isSelected === true),
                    indexPath: path,
                    layoutStyle: children[0]?.layoutStyle ?? "vertical",
                    badge: item.badge,
                    iconColor: item.iconColor,
                };
                return result;
            }
            if (isHeader(item)) {
                return {
                    name: item.name,
                    item,
                    prelighting: false,
                    prelight: false,
                    containsPrelight: false,
                    containsPrelighting: false,
                    isSelected: false,
                    indexPath: path,
                };
            }
            const raw = descriptionForItem(item);
            const unpacked = typeof raw === "string" ? { name: raw } : raw;

            const isPrelightInner = unpacked.disablePrelight === true ? false : isPrelight;
            const isPrelightingInner = unpacked.disablePrelight === true ? false : isPrelighting;

            const result: InnerItemDescription<T> = {
                item,
                ...unpacked,
                accelerator: browserIsOSX ? unpacked.accelerator?.slice(-1)[0] : unpacked.accelerator?.[0],
                isSelected: item === selected,
                prelight: isPrelightInner,
                containsPrelight: isPrelightInner,
                prelighting: isPrelightingInner,
                containsPrelighting: isPrelightingInner,
                indexPath: path,
                iconColor: group?.iconColor,
            };
            return result;
        },
        [prelightPath, prelighting, descriptionForItem, selected]
    );

    let describedItems = React.useMemo(() => {
        const raw = itemsRaw.map((i, index) => describeItem(i, [index]));
        const result: Readonly<typeof raw> = raw;
        return result;
    }, [describeItem, itemsRaw]);
    const hasMultipleLevels = describedItems.some(i => i?.children !== undefined);
    const hideSearch = searchable === true && !hasMultipleLevels && describedItems.length < 10;
    const prelightDescription = visit(
        describedItems,
        item => item?.children,
        item => {
            if (item === null || !item.containsPrelight) return VisitResult.SkipChildren;
            if (item.prelight) return item;
            return VisitResult.Continue;
        }
    );
    const prelight = isHeader(prelightDescription?.item) ? undefined : prelightDescription?.item;
    const hasHints =
        visit(
            describedItems,
            item => item?.children,
            item => (item?.hint === undefined || item?.hint.length === 0 ? VisitResult.Continue : true)
        ) ?? false;

    const [flattenedDescribedItems, footer] = React.useMemo(() => {
        const trimmedNeedle = needle.trim();
        if (searchable && trimmedNeedle !== "") {
            function flatten(
                i: InnerItemDescription<T> | null,
                lineage: readonly InnerItemDescription<T>[]
            ): readonly (InnerItemDescription<T> | null)[] {
                if (i === null) return [null];
                if (i.children === undefined || i.children.length === 0) {
                    if (lineage.length === 0) {
                        return [i];
                    }
                    return [
                        {
                            ...i,
                            lineage,
                        },
                    ];
                }
                return flatMap(i.children, x => flatten(x, [...lineage, i]));
            }

            function hasNeedle(i: InnerItemDescription<T>) {
                const haystack = [
                    ...(i?.lineage?.map(x => x.name) ?? []),
                    i.name,
                    i.hint ?? "",
                    i.tooltip ?? "",
                    ...(i.additionalSearchTerms ?? []),
                ].join("🐳");
                return haystack.toLowerCase().includes(trimmedNeedle.toLowerCase());
            }

            let f: string | undefined;
            let r = flatMap(describedItems, i => flatten(i, [])).filter(
                i => i !== null && i.isEnabled !== false && hasNeedle(i)
            );

            const numTotal = r.length;
            // We'll never show "X more" with X less than 10.
            if (numTotal >= 60 && !expand) {
                r = r.slice(0, 50);
                f = `${numTotal - r.length} more`;
            }

            return [r, f];
        }
        return [];
    }, [describedItems, needle, searchable, expand]);

    describedItems = flattenedDescribedItems ?? describedItems;

    const itemsRef = React.useRef(describedItems);
    itemsRef.current = describedItems;
    React.useEffect(() => {
        if (needle !== "") {
            dispatchState({
                kind: "prelight",
                fromHover: false,
                prelight: itemsRef.current[0] ?? undefined,
            });
        }
    }, [needle]);

    const setNeedle = React.useCallback((newVal: string) => {
        dispatchState({
            kind: "needle",
            value: newVal,
        });
    }, []);

    const onItemSelect = React.useCallback(
        (item: T, selectPath: ItemPath) => {
            dispatchState({
                kind: "prelight",
                fromHover: false,
                prelight: undefined,
            });
            onItemSelectOuter?.(item, selectPath);
        },
        [onItemSelectOuter]
    );

    const onClick = React.useCallback(
        (item: InnerItemDescription<T> | null, e?: React.MouseEvent) => {
            if (item === null || isHeader(item.item)) return;

            if (item.isEnabled === false) {
                return;
            }

            if (!isGroup(item.item) && e !== undefined) {
                onItemClicked?.(item.item, item.indexPath, e);

                if (!e.defaultPrevented) {
                    onItemSelect(item.item, item.indexPath);
                }
            }
        },
        [onItemClicked, onItemSelect]
    );

    const onActionClick = React.useCallback(
        (item: InnerItemDescription<T> | null, e?: React.MouseEvent) => {
            if (item === null || isHeader(item.item)) return;

            if (item.isEnabled === false) {
                return;
            }

            if (e !== undefined && !isGroup(item.item)) {
                onItemActionClicked?.(item.item, item.indexPath, e);
            }
        },
        [onItemActionClicked]
    );

    const onAccelPressed = React.useCallback(
        (e: Mousetrap.ExtendedKeyboardEvent, combo: string) => {
            if (keyboardListenerMode === "none" || keyboardListenerMode === "navigation") {
                return;
            }

            const toActivate = visit(
                describedItems,
                i => i?.children,
                i => {
                    if (i?.accelerator === combo) return i;
                    return VisitResult.Continue;
                }
            );

            if (toActivate?.item === undefined || isGroup(toActivate.item) || isHeader(toActivate.item)) return;
            e.stopImmediatePropagation();
            e.preventDefault();
            onItemSelect(toActivate.item, toActivate.indexPath);
        },
        [describedItems, keyboardListenerMode, onItemSelect]
    );

    const accelerators: string[] = [];
    visit(
        describedItems,
        i => i?.children,
        i => {
            if (i?.accelerator !== undefined) {
                accelerators.push(i.accelerator);
            }
            return VisitResult.Continue;
        }
    );
    useAccelerator(accelerators, onAccelPressed);

    const onNavigation = React.useCallback(
        (e: Mousetrap.ExtendedKeyboardEvent, combo: string) => {
            if (keyboardListenerMode === "accelerators" || keyboardListenerMode === "none") return;

            const itemsToRender =
                visit(
                    describedItems,
                    i => i?.children,
                    item => {
                        if (item === null || item.containsPrelight === false || isHeader(item))
                            return VisitResult.SkipChildren;
                        if (item.containsPrelight && item.children?.find(c => c?.prelight === true) !== undefined) {
                            return item.children;
                        }
                        return VisitResult.Continue;
                    }
                ) ?? describedItems;
            switch (combo) {
                case "up":
                    if (prelight === undefined) {
                        const desc = itemsToRender.slice(-1)[0];
                        dispatchState({
                            kind: "prelight",
                            prelight: nullToUndefined(desc),
                            fromHover: false,
                        });
                        e.preventDefault();
                    } else if (itemsToRender.length > 1) {
                        const curIndex = itemsToRender.findIndex(i => i?.item === prelight);
                        let nextIndex = (curIndex - 1 + itemsToRender.length) % itemsToRender.length;
                        while (
                            itemsToRender[nextIndex] === null ||
                            itemsToRender[nextIndex]?.isEnabled === false ||
                            isHeader(itemsToRender[nextIndex]?.item)
                        ) {
                            nextIndex = (nextIndex - 1 + itemsToRender.length) % itemsToRender.length;
                        }
                        const desc = itemsToRender[nextIndex];
                        dispatchState({
                            kind: "prelight",
                            prelight: nullToUndefined(desc),
                            fromHover: false,
                        });
                        e.preventDefault();
                    }
                    break;
                case "down":
                    if (prelight === undefined) {
                        dispatchState({
                            kind: "prelight",
                            prelight: nullToUndefined(itemsToRender.find(c => !isHeader(c?.item))),
                            fromHover: false,
                        });
                        e.preventDefault();
                    } else if (itemsToRender.length > 1) {
                        const curIndex = itemsToRender.findIndex(i => i?.item === prelight);
                        let nextIndex = (curIndex + 1) % itemsToRender.length;
                        while (
                            itemsToRender[nextIndex] === null ||
                            itemsToRender[nextIndex]?.isEnabled === false ||
                            isHeader(itemsToRender[nextIndex]?.item)
                        ) {
                            nextIndex = (nextIndex + 1) % itemsToRender.length;
                        }
                        const desc = itemsToRender[nextIndex];
                        dispatchState({
                            kind: "prelight",
                            prelight: nullToUndefined(desc),
                            fromHover: false,
                        });
                        e.preventDefault();
                    }
                    break;
                case "left":
                    if (prelightDescription !== undefined && prelightDescription.indexPath.length > 0) {
                        const prevGroupPath = prelightDescription.indexPath.slice(0, -2);
                        let prev = describedItems;
                        for (const ind of prevGroupPath) {
                            const itm = prev[ind];
                            if (!isGroup(itm?.item) || itm?.children === undefined) return;
                            prev = itm.children;
                        }
                        const desc = prev[prelightDescription.indexPath.slice(-2)[0]];
                        dispatchState({
                            kind: "prelight",
                            prelight: nullToUndefined(desc),
                            fromHover: false,
                        });
                        e.preventDefault();
                    }
                    break;
                case "right":
                    if (isGroup(prelight) && prelightDescription !== undefined) {
                        const desc = prelightDescription.children?.find(c => !isHeader(c?.item));
                        if (desc === undefined) break;
                        dispatchState({
                            kind: "prelight",
                            prelight: nullToUndefined(desc),
                            fromHover: false,
                        });
                        onClick(prelightDescription);
                        e.preventDefault();
                    }
                    break;
                case "enter":
                    if (prelight === undefined) return;
                    if (isGroup(prelight)) {
                        if (prelightDescription === undefined) break;
                        onClick(prelightDescription);
                        const desc = prelightDescription.children?.[0];
                        dispatchState({
                            kind: "prelight",
                            prelight: nullToUndefined(desc),
                            fromHover: false,
                        });
                    } else if (prelightDescription !== undefined && prelightDescription.isEnabled !== false) {
                        onItemSelect(prelight, prelightDescription.indexPath);
                        dispatchState({
                            kind: "prelight",
                            prelight: undefined,
                            fromHover: false,
                        });
                    }
                    e.preventDefault();
                    break;
                case "escape":
                    if (prelight === undefined && needle === "") return;
                    if (needle !== "") {
                        setNeedle("");
                        e.preventDefault();
                    } else if (prelight !== undefined) {
                        dispatchState({
                            kind: "prelight",
                            prelight: undefined,
                            fromHover: false,
                        });
                        e.preventDefault();
                    }
                    break;
            }
        },
        [describedItems, keyboardListenerMode, needle, onClick, onItemSelect, prelight, prelightDescription, setNeedle]
    );

    useAccelerator(["up", "down", "left", "right", "enter", "escape"], onNavigation);

    const onClickOutside = React.useCallback(() => {
        dispatchState({
            kind: "prelight",
            prelight: undefined,
            fromHover: false,
        });
    }, []);

    const mouseData = React.useRef<[number, number]>();
    const onMouseMove = React.useCallback((ev: MouseEvent) => {
        mouseData.current = [ev.clientX, ev.clientY];
    }, []);
    useEventListener("mousemove", onMouseMove, window, false, true);

    const motionData = React.useRef<{
        start: [number, number];
        iterations: number;
    }>();

    const isDead = React.useRef(false);
    React.useEffect(() => {
        return () => {
            isDead.current = true;
        };
    }, []);

    const timeoutHandle = React.useRef<number>();
    const onItemHovered = React.useCallback(
        (desc: InnerItemDescription<T> | undefined) => {
            window.clearTimeout(timeoutHandle.current);
            window.clearInterval(timeoutHandle.current);
            timeoutHandle.current = undefined;

            const prelightItem = prelightDescription?.item;

            if (desc === undefined) {
                const newTarget = visit(
                    describedItems,
                    i => i?.children,
                    i => {
                        if (i === null) return VisitResult.SkipChildren;
                        if (!i.containsPrelight) return VisitResult.SkipChildren;

                        if (i.children?.find(x => x?.prelight) !== undefined) return i;
                        return VisitResult.Continue;
                    }
                );
                if (newTarget !== undefined) {
                    dispatchState({
                        kind: "prelighting",
                        prelighting: newTarget,
                    });
                    timeoutHandle.current = window.setTimeout(() => {
                        if (isDead.current) return;
                        dispatchState({
                            kind: "prelight",
                            fromHover: true,
                            prelight: newTarget,
                        });
                    }, prelightDebounceMs ?? 400);
                    return;
                }
            }

            const contained = desc !== undefined && isGroup(prelightItem) && prelightItem.items.includes(desc.item);
            const isSiblingOfAncestor =
                desc?.containsPrelight === false &&
                desc.indexPath.length < (prelightDescription?.indexPath.length ?? 0);
            if (isGroup(prelightItem) && mouseData.current !== undefined && !contained && !isSiblingOfAncestor) {
                if (motionData.current === undefined) {
                    motionData.current = { start: mouseData.current, iterations: 0 };
                }
                dispatchState({
                    kind: "prelighting",
                    prelighting: desc,
                });
                timeoutHandle.current = window.setInterval(() => {
                    if (
                        motionData.current !== undefined &&
                        motionData.current.iterations < 20 &&
                        mouseData.current !== undefined &&
                        pointIsInShadow(motionData.current.start, mouseData.current, 6, 30)
                    ) {
                        motionData.current = {
                            ...motionData.current,
                            iterations: motionData.current.iterations + 1,
                        };
                        return;
                    }
                    motionData.current = undefined;
                    dispatchState({
                        kind: "prelight",
                        fromHover: true,
                        prelight: desc,
                    });
                }, 50);
            } else {
                dispatchState({
                    kind: "prelighting",
                    prelighting: desc,
                });
                timeoutHandle.current = window.setTimeout(() => {
                    if (isDead.current) return;
                    dispatchState({
                        kind: "prelight",
                        fromHover: true,
                        prelight: desc,
                    });
                }, prelightDebounceMs ?? 200);
            }
        },
        [describedItems, prelightDescription, prelightDebounceMs]
    );

    const style = React.useMemo((): React.CSSProperties | undefined => {
        if (width === undefined && height === undefined) return undefined;

        return {
            width,
            maxHeight: height,
        };
    }, [height, width]);

    const prelightingDesc = visit(
        describedItems,
        item => item?.children,
        item => {
            if (item === null || !item.containsPrelighting) return VisitResult.SkipChildren;
            if (item.prelighting) return item;
            return VisitResult.Continue;
        }
    );

    const prelightData = React.useMemo(
        () => ({
            prelit: prelightDescription,
            fromHover,
            prelighting: prelightingDesc ?? undefined,
        }),
        [fromHover, prelightDescription, prelightingDesc]
    );

    const footerNode = React.useMemo(
        () =>
            footer === undefined ? undefined : (
                <div tw="p-2 font-medium text-center text-b400 cursor-pointer" onClick={() => setExpand(true)}>
                    {footer}
                </div>
            ),
        [footer]
    );

    return (
        <PopoutDropdownContent
            testId={testId}
            search={searchable === true ? needle : ""}
            onSearchChange={searchable === true ? setNeedle : undefined}
            hideSearch={hideSearch}
            showSearchLineage={showSearchLineage}
            onClickOutside={onClickOutside}
            onItemActionClicked={onActionClick}
            className={className}
            onItemHovered={onItemHovered}
            describedItems={describedItems}
            onItemClick={onClick}
            style={style}
            footer={footerNode}
            prelight={prelightData}
            showGroupCounts={hasHints}
            prefer={prefer ?? "right"}
        />
    );
}
