import type { WireAudioComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isEmptyOrUndefinedish } from "@glide/support";

import type { WireRenderer } from "../wire-renderer";
import { getFirstElementFromArrayOrSingleElement } from "@glide/common-core/dist/js/components/component-helpers";

export const WireAudio: WireRenderer<WireAudioComponent> = p => {
    const { audio } = p;

    const firstAudio = getFirstElementFromArrayOrSingleElement(audio);

    if (isEmptyOrUndefinedish(firstAudio)) return null;

    return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio tw="w-full" controls src={firstAudio} />
    );
};
