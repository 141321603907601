import type { ClassicFeatures } from "@glide/common-core/dist/js/firebase-function-types";
import { Modal } from "../windowed-modal-next";
import "twin.macro";
import { AppIcon, GlideIcon } from "@glide/common";

type ClassicAppMigrationModalProps = {
    onClose: () => void;
    onConfirm: () => void;
    confirmLabel?: string;
    cancelLabel?: string;
    className?: string;
    appHasFeatures: ClassicFeatures;
};

type NonMigratableFeatureProps = {
    feature: string;
    description: string;
    icon?: React.ReactNode;
    wontMigrate: boolean;
};

const NonMigratableFeature = (props: NonMigratableFeatureProps) => {
    return (
        <div tw="flex items-center gap-4 my-1.5">
            <div tw="relative">
                <div tw="bg-n100 rounded-lg w-10 h-10 flex items-center justify-center">
                    {props.icon ? (
                        <div tw="opacity-50">{props.icon}</div>
                    ) : (
                        <GlideIcon icon="st-alert-warning" kind="stroke" iconSize={24} tw="opacity-50" />
                    )}
                </div>
                <div tw="absolute -bottom-2 -right-2 w-5 h-5 rounded-full flex items-center justify-center bg-white">
                    {props.wontMigrate ? (
                        <div tw="bg-r200 rounded-full p-1 border-white border-2">
                            <GlideIcon icon="st-close" kind="stroke" iconSize={12} tw="text-r500" />
                        </div>
                    ) : (
                        <div tw="bg-y200 rounded-full p-1 border-white border-2">
                            <GlideIcon icon="st-alert-warning" kind="stroke" iconSize={14} tw="text-text-warning" />
                        </div>
                    )}
                </div>
            </div>
            <div key={props.feature} tw="my-1.5">
                <h2 tw="text-builder-lg font-bold">{props.feature}</h2>
                <p tw="text-builder-base text-n700">{props.description}</p>
            </div>
        </div>
    );
};

type NonMigratableFeaturesProps = {
    features: ClassicFeatures;
};

const NonMigratableFeatures = (p: NonMigratableFeaturesProps) => {
    const { features } = p;
    return (
        <>
            {features.swipe ? (
                <NonMigratableFeature
                    feature="Swipe"
                    description="The swipe screen will not be migrated."
                    icon={<AppIcon icon="tinder" color="grey" />}
                    wontMigrate={true}
                />
            ) : null}
            {features.buyButton || features.shoppingCart ? (
                <NonMigratableFeature
                    feature="Buy Button / Shopping Cart"
                    description="No shopping cart features will be present in the new app, including any buy buttons."
                    icon={<GlideIcon icon="co-buy-button" kind="component" />}
                    wontMigrate={true}
                />
            ) : null}
            {features.independentScreenConfig ? (
                <NonMigratableFeature
                    feature="Independent Screen Config"
                    description="All detail screens will have the same component configuration."
                    wontMigrate={false}
                />
            ) : null}
            {features.stackChart ? (
                <NonMigratableFeature
                    feature="Stack Chart"
                    description="These will be converted to a normal bar chart."
                    icon={<GlideIcon icon="mt-stacked-bar-chart" kind="monotone" />}
                    wontMigrate={false}
                />
            ) : null}
            {features.floatingButton ? (
                <NonMigratableFeature
                    feature="Floating Button"
                    description="The button will be a regular button and not float on screen."
                    icon={<GlideIcon icon="co-floating-button" kind="component" />}
                    wontMigrate={false}
                />
            ) : null}
            {features.eventPicker ? (
                <NonMigratableFeature
                    feature="Event Picker"
                    description="Any event picker components will not be migrated but can be replaced by a choice component."
                    icon={<GlideIcon icon="co-date-picker" kind="component" />}
                    wontMigrate={false}
                />
            ) : null}
            {features.chat || features.comments ? (
                <NonMigratableFeature
                    feature="Chat / Comments"
                    description="This will not be migrated but comments and chat features can be re-added in the new app."
                    icon={<GlideIcon icon="co-comments" kind="component" />}
                    wontMigrate={false}
                />
            ) : null}
            {features.reaction ? (
                <NonMigratableFeature
                    feature="Reaction"
                    description="Reaction components will not be migrated but can be replaced by a choice component."
                    icon={<GlideIcon icon="mt-header-emoji" kind="monotone" />}
                    wontMigrate={false}
                />
            ) : null}
            {features.listRelation ? (
                <NonMigratableFeature
                    feature="List relation"
                    description="The list relation component will not be migrated but can be replaced by a list component in a new screen"
                    icon={<GlideIcon icon="co-list-relation" kind="component" />}
                    wontMigrate={false}
                />
            ) : null}
        </>
    );
};
export const ClassicAppMigrationModal: React.FC<React.PropsWithChildren<ClassicAppMigrationModalProps>> = p => {
    const { cancelLabel = "Cancel", confirmLabel = "Proceed", onClose, onConfirm, className, appHasFeatures } = p;

    const hasNonMigratableFeatures = Object.values(appHasFeatures).some(feature => feature === true);

    return (
        <Modal.Container onClose={onClose}>
            <Modal.Window tw="p-6 gap-2" size="lg" className={className}>
                <Modal.Title tw="text-builder-2xl">Duplicate your Classic App as a New Glide App</Modal.Title>
                <Modal.Content tw="text-text-base">
                    <div tw="my-2">
                        <div tw="text-builder-lg my-2.5">
                            We will create a copy of your Classic App as a New Glide App. This will give your app access
                            to the latest features like integrations and workflows.
                        </div>
                        <div tw="text-builder-lg my-2">
                            Your tables will be linked to your original Classic App. Some components may experience
                            subtle formatting and design changes. The migration may take a few minutes to complete.
                        </div>
                    </div>
                    {hasNonMigratableFeatures ? (
                        <div>
                            <div tw="bg-y100 rounded-lg p-4 my-4 flex items-center gap-3">
                                <span tw="text-builder-lg">
                                    This app includes legacy features that won't be automatically migrated
                                </span>
                            </div>

                            <NonMigratableFeatures features={appHasFeatures} />
                        </div>
                    ) : null}
                </Modal.Content>
                <Modal.ButtonBar align="right" tw="gap-4">
                    <Modal.Button
                        size="xlg"
                        label={cancelLabel}
                        onClick={onClose}
                        tw="text-n700"
                        buttonType="minimal"
                    />
                    <Modal.Button
                        buttonType="primary"
                        size="xlg"
                        label={confirmLabel}
                        onClick={onConfirm}
                        tw="bg-aqua400"
                    />
                </Modal.ButtonBar>
            </Modal.Window>
        </Modal.Container>
    );
};
