import { styled } from "@glide/common";
import { css } from "styled-components";
import tw from "twin.macro";

const isDraggingProps = css`
    background-color: ${p => p.theme.n0} !important;
    box-shadow: ${p => p.theme.shadow1};
    cursor: grabbing;
`;

const isHighlightedProps = css`
    background-color: ${p => p.theme.borderColorLight}99;
`;

export const DragItemStyle = styled.div<{ isDragging: boolean; isHighlighted: boolean; hasBottom: boolean }>`
    position: relative;
    font-size: 13px;
    border-radius: 8px;
    padding-left: 10px;
    color: ${p => p.theme.fgColorDark};
    ${p => p.isDragging && isDraggingProps}
    ${p => p.isHighlighted && isHighlightedProps}
    transition: background-color ${p => p.theme.transitionBase};
    background: ${p => p.theme.bgFront};

    .ghost &:after {
        ${tw`absolute inset-0 rounded-lg content-[''] bg-n200`};
    }

    &.warn {
        border: 1px solid ${p => p.theme.errorColor};
    }

    &.prelight {
        box-shadow: 0 0 1px ${p => p.theme.b400};
        margin-top: 2px;
    }

    .layout .selected && {
        background-color: var(--tint-color-bg);
        color: var(--tint-color-text);
    }

    .layout .selected &&.warn {
        background-color: ${p => p.theme.r100};
        color: ${p => p.theme.errorColor};
    }

    .selected && {
        background-color: ${p => p.theme.n200A};
        color: ${p => p.theme.textBase};
    }

    .in-path &&,
    :hover {
        background-color: ${p => p.theme.n100};
    }

    .top-wrapper {
        height: 32px;
        display: flex;
        align-items: center;

        .icon-area {
            display: inline-flex;
            margin-right: 8px;
            border-radius: 3px;
        }

        .remove-wrap {
            width: 16px;
            height: 16px;
            margin: 0 8px;
            justify-content: center;
            align-items: center;
            color: ${p => p.theme.fgColorMedium};
            display: none;
            :hover {
                color: ${p => p.theme.fgColorDark};
            }
        }

        .child-button + .child-button {
            margin: 0 8px;
        }

        &:hover .remove-wrap {
            display: flex;
        }

        .with-ring {
            border-radius: 1000px;
            transition: background-color ${p => p.theme.transitionBase};

            &:hover,
            &:focus {
                background-color: ${p => p.theme.borderColorLight};
            }
        }

        & .remove-wrap.child-button {
            width: 24px;
            height: 24px;
            border-radius: 4px;

            &,
            & + .child-button {
                margin: 0 4px 0 0;
            }
        }

        .input-rename {
            flex-grow: 1;
            color: ${p => p.theme.textDark};
        }

        .tooltip {
            margin-right: 10px;
        }
    }

    // TODO: Undo this ugly mess once vOld is out
    .layout & .top-wrapper .icon-area {
        color: ${p => p.theme.iconBase};
    }

    .layout .selected & .top-wrapper .icon-area {
        color: ${p => p.theme.accentPrimary};
    }

    .layout .selected &.warn .top-wrapper .icon-area {
        color: ${p => p.theme.errorColor};
    }

    .queries & .top-wrapper,
    .data & .top-wrapper {
        height: 36px;

        .icon-glide-table {
            color: ${p => p.theme.n800A} !important;
        }
    }
`;

export const ErrorMessage = styled.div`
    color: ${p => p.theme.errorColor};
    padding-bottom: 12px;
    font-size: 10px;
`;

export const OuterWrapper = styled.div<{ isHidden: boolean }>`
    font-family: ${p => p.theme.labelFontFamily};
    cursor: pointer;

    ${DragItemStyle} {
        ${p =>
            p.isHidden &&
            css`
                opacity: 0.4;
            `}
    }

    padding-bottom: 1px;
`;

export const Title = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
    font-size: 13px;
`;

export const Secondary = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    width: 1px;
    margin-right: 8px;
    color: ${p => p.theme.textXpale};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .selected & {
        color: inherit;
        opacity: 0.5;
    }
`;

export const VisibilityWrapper = styled.div`
    width: 24px;
    height: 24px;
    margin: 0 4px 0 auto;
    padding: 4px;
    border-radius: 4px;
    color: ${p => p.theme.fgColorMedium};
    transition: ${p => p.theme.transitionBase};
    pointer-events: auto;
    cursor: pointer;

    svg {
        width: 100%;
        height: 100%;
    }

    :hover {
        color: ${p => p.theme.fgColorDark};
        background-color: ${p => p.theme.n100A};
    }
`;
