import { isEmoji, parseURL } from "@glide/support";
import { definedMap } from "@glideapps/ts-necessities";
import { getFeatureSetting } from "../feature-settings";
import { ImageGravity } from "./image-types";
import type { ImageUrlOptions } from "./image-url-options";

export interface ImageURLHandler {
    handle(src: string, options: ImageUrlOptions, appID: string): string | undefined;
}

function devicePixelRatioIfAvailable(): number {
    // We're not always running in a browser with a `window`.
    // Particularly, when generating app assets, we're not in a browser.
    // In that specific case, it's safe to assume that the devicePixelRatio
    // is 1.
    try {
        return window.devicePixelRatio;
    } catch {
        return 1;
    }
}

function applyDPR(value: number): number {
    return value * devicePixelRatioIfAvailable();
}

class UnsplashHandler implements ImageURLHandler {
    private UNSPLASH_IMAGE_REGEX = /^https:\/\/images\.unsplash\.com\/photo-\S+/;
    private isUnsplashUrl(url: string): boolean {
        const match = this.UNSPLASH_IMAGE_REGEX.test(url);
        return match;
    }

    public handle(src: string, options: ImageUrlOptions): string | undefined {
        if (!this.isUnsplashUrl(src)) {
            return undefined;
        }

        const url = new URL(src);
        url.searchParams.set("utm_source", "Glide");
        url.searchParams.set("utm_medium", "referral");
        const dpi = Math.round(devicePixelRatioIfAvailable());

        if (options.thumbnail) {
            url.searchParams.set("w", "200");
            url.searchParams.set("h", "200");
            url.searchParams.set("dpr", dpi.toString());
            url.searchParams.set("fit", "crop");
            url.searchParams.set("auto", "format");
        } else if (options.width !== undefined && options.height !== undefined) {
            const width = Math.round(options.width);
            const height = Math.round(options.height);

            url.searchParams.set("w", width.toString());
            url.searchParams.set("h", height.toString());
            url.searchParams.set("dpr", dpi.toString());
            url.searchParams.set("fit", "crop");
            url.searchParams.set("auto", "format");
        } else {
            url.searchParams.set("w", "1400");
        }

        return url.href;
    }
}
class PexelsHandler implements ImageURLHandler {
    private PEXELS_IMAGE_REGEX = /^https:\/\/images\.pexels\.com\/photos\S+/;
    private isPexelsUrl(url: string): boolean {
        const match = this.PEXELS_IMAGE_REGEX.test(url);
        return match;
    }

    public handle(src: string, options: ImageUrlOptions): string | undefined {
        if (!this.isPexelsUrl(src)) {
            return undefined;
        }

        const url = new URL(src);
        // TODO: see if Pexles has referral
        // url.searchParams.set("utm_source", "Glide");
        // url.searchParams.set("utm_medium", "referral");
        const dpi = Math.round(devicePixelRatioIfAvailable());

        url.searchParams.set("cs", "tinysrgb");
        url.searchParams.set("auto", "compress");

        if (options.thumbnail) {
            url.searchParams.set("w", "200");
            url.searchParams.set("h", "200");
            url.searchParams.set("dpr", dpi.toString());
            url.searchParams.set("fit", "crop");
        } else if (options.width !== undefined && options.height !== undefined) {
            const width = Math.round(options.width);
            const height = Math.round(options.height);
            url.searchParams.set("dpr", dpi.toString());
            url.searchParams.set("w", width.toString());
            url.searchParams.set("h", height.toString());
            url.searchParams.set("fit", "crop");
        } else {
            url.searchParams.set("w", "1400");
        }

        return url.href;
    }
}

class GoogleDriveDirectHandler implements ImageURLHandler {
    private googleDrivePrefix = "https://drive.google.com/uc?id=";
    private googleDriveThumbnailPrefix = "https://lh3.googleusercontent.com/d/";
    public handle(src: string, options: ImageUrlOptions): string | undefined {
        if (!src.startsWith(this.googleDrivePrefix)) {
            return undefined;
        }

        const parts: string[] = ["="];
        if (options.thumbnail) {
            parts.push("w150-h150");
        } else {
            const width = options.width === undefined ? undefined : Math.round(applyDPR(options.width));
            const height = options.height === undefined ? undefined : Math.round(applyDPR(options.height));
            if (width !== undefined && height !== undefined) {
                parts.push(`w${width}-h${height}`);
                parts.push("-" + (options.gravity === ImageGravity.Faces ? "p" : "n"));
            } else if (width !== undefined) {
                parts.push(`w${width}`);
            } else if (height !== undefined) {
                parts.push(`h${height}`);
            }
        }

        const sizeString = parts.join("");
        const queryParams = sizeString.includes("w") || sizeString.includes("h") ? `${sizeString}?authuser=0` : "";

        return this.googleDriveThumbnailPrefix + src.substr(this.googleDrivePrefix.length) + queryParams;
    }
}

class GifHandler implements ImageURLHandler {
    public handle(_src: string, _options: ImageUrlOptions): string | undefined {
        return undefined;
    }
}

class GoogleSpreadsheetChartHandler implements ImageURLHandler {
    public handle(src: string, options: ImageUrlOptions): string | undefined {
        if (/https:\/\/docs\.google\.com\/spreadsheets\/.*&format=image/.test(src)) {
            const url = parseURL(src);
            if (url !== undefined) {
                const msInHour = 1000 * 60 * 60;
                const param = Math.round(Date.now() / msInHour) * msInHour;
                url.searchParams.append("CACHE_BUSTER", param.toString());
                src = url.href;
                return cloudinary.handle(src, options);
            }
        }
        return undefined;
    }
}

class CloudinaryHandler implements ImageURLHandler {
    private CLOUDINARY_ROUNDING = 75;

    private roundToNearest(value: number, nearest: number): number {
        return Math.ceil(value / nearest) * nearest;
    }

    private roundWidthAndHeight(width: number, height: number): [number, number] {
        const resultWidth = this.roundToNearest(width, this.CLOUDINARY_ROUNDING);
        const resultHeight = Math.ceil(resultWidth * (height / width));

        return [resultWidth, resultHeight];
    }

    public handle(src: string, options: ImageUrlOptions): string | undefined {
        const isFromGlide = src.includes("glide-prod.appspot.com") || src.includes("glide-staging.appspot.com");

        if (getFeatureSetting("cloudinaryGlideOnly") && !isFromGlide) {
            return undefined;
        }

        // Cloudinary breaks with HTTP 400 if any of the request characters are
        // emoji. We just have to bypass Cloudinary entirely when this happens.
        if (Array.from(src).some(isEmoji)) return undefined;

        const base = `https://res.cloudinary.com/glide/image/fetch`;
        const encoded = encodeURIComponent(src);

        if (encoded.length >= 256) return undefined;

        if (options.thumbnail) {
            return `${base}/t_media_lib_thumb/${encoded}`;
        } else if (options.width !== undefined && options.height !== undefined) {
            const [width, height] = this.roundWidthAndHeight(applyDPR(options.width), applyDPR(options.height));
            const gravity = options.gravity === ImageGravity.Faces ? ",g_faces" : "";
            return `${base}/f_auto,w_${width},h_${height},c_lfill${gravity}/${encoded}`;
        } else if (options.width === undefined && options.height !== undefined) {
            const height =
                definedMap(options.height, h => this.roundToNearest(applyDPR(h), this.CLOUDINARY_ROUNDING)) ?? 500;
            return `${base}/f_auto,h_${height},c_limit/${encoded}`;
        }
        const width = definedMap(options.width, w => this.roundToNearest(applyDPR(w), this.CLOUDINARY_ROUNDING)) ?? 500;
        return `${base}/f_auto,w_${width},c_limit/${encoded}`;
    }
}

class CheapCloudinaryHandler implements ImageURLHandler {
    private CLOUDINARY_ROUNDING = 300;

    private roundToNearest(value: number, nearest: number): number {
        return Math.ceil(value / nearest) * nearest;
    }

    private roundWidthAndHeight(width: number, height: number): [number, number] {
        const resultWidth = this.roundToNearest(width, this.CLOUDINARY_ROUNDING);
        const resultHeight = Math.ceil(resultWidth * (height / width));

        return [resultWidth, resultHeight];
    }

    public handle(src: string, options: ImageUrlOptions, appID: string): string | undefined {
        if (!getFeatureSetting("makeCloudinaryCheaper")) {
            return undefined;
        }

        const isFromGlide = src.includes("glide-prod.appspot.com") || src.includes("glide-staging.appspot.com");

        if (getFeatureSetting("cloudinaryGlideOnly") && !isFromGlide) {
            return undefined;
        }

        // Cloudinary breaks with HTTP 400 if any of the request characters are
        // emoji. We just have to bypass Cloudinary entirely when this happens.
        if (Array.from(src).some(isEmoji)) return undefined;

        const base = `https://res.cloudinary.com/glide/image/fetch`;
        const encoded = encodeURIComponent(
            `https://di.glideapps.com/${appID.substring(0, 7)}/${encodeURIComponent(src)}`
        );

        if (encoded.length >= 256) return undefined;

        if (options.thumbnail) {
            return `${base}/t_media_lib_thumb/${encoded}`;
        } else if (options.width !== undefined && options.height !== undefined) {
            const [width, height] = this.roundWidthAndHeight(applyDPR(options.width), applyDPR(options.height));
            const gravity = options.gravity === ImageGravity.Faces ? ",g_faces" : "";
            return `${base}/q_auto,f_auto,w_${width},h_${height},c_lfill${gravity}/${encoded}`;
        } else if (options.width === undefined && options.height !== undefined) {
            const height =
                definedMap(options.height, h => this.roundToNearest(applyDPR(h), this.CLOUDINARY_ROUNDING)) ?? 500;
            return `${base}/q_auto,f_auto,h_${height},c_limit/${encoded}`;
        }
        const width = definedMap(options.width, w => this.roundToNearest(applyDPR(w), this.CLOUDINARY_ROUNDING)) ?? 500;
        return `${base}/q_auto,f_auto,w_${width},c_limit/${encoded}`;
    }
}

const gifHandler = new GifHandler();
const unsplash = new UnsplashHandler();
const pexels = new PexelsHandler();
const googleDrive = new GoogleDriveDirectHandler();
const spreadsheets = new GoogleSpreadsheetChartHandler();
const cloudinary = new CloudinaryHandler();
const cheapCloudinary = new CheapCloudinaryHandler();

// order matters!
export const imageURLHandlers: ImageURLHandler[] = [
    gifHandler,
    spreadsheets,
    unsplash,
    pexels,
    googleDrive,
    cheapCloudinary,
    cloudinary,
];

export function addImageURLHandler(handler: ImageURLHandler, position: "first" | "last"): void {
    if (position === "first") {
        imageURLHandlers.unshift(handler);
    } else {
        imageURLHandlers.push(handler);
    }
}
