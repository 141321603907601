import { makeStartOfDayTimeZoneAgnostic } from "@glide/data-types";
import { assert } from "@glideapps/ts-necessities";
import { digitsAndLetters, escapeStringAsRegexp } from "@glide/support";
import md5 from "blueimp-md5";
// @ts-ignore
import utf8ByteLength from "utf8-byte-length";
import type { TableName } from "@glide/type-schema";

export const documentIDChars = digitsAndLetters;

export const glideAppsCollectionName = "glide-apps-v4";
export const glideAppsDataCollectionName = "glide-apps-v4-data";
export const glideAppsDataVersionCollectionName = "glide-apps-v4-data-versions";
export const glideAppsMetadataCollectionName = "glide-apps-v4-metadata";
export const builderAppsCollectionName = "builder-apps-v1";
export const builderActionsCollectionName = "builder-actions";
export const tileDescriptionsCollectionName = "tile-descriptions-v1";
export const usersCollectionName = "users-v1";
export const actionsCollectionName = "actions";
export const actionsMetadataCollectionName = "actions-metadata";
export const appAnalyticsCollectionName = "analytics";
export const userAnalyticsCollectionName = "analytics-user";
export const globalSettingsCollectionName = "global-settings";
export const jobsCollectionName = "jobs";
export const newFeaturesCollectionName = "new-features";
export const newFeaturesSeenCollectionName = "new-features-seen";
export const userAppUsersCollectionName = "user-app-users";
export const geocodingCollectionName = "geocoding";
export const appsGrandfatheringCollectionName = "grandfather-apps";
export const appLoginsCollectionName = "app-logins";
export const builderAppLocksCollectionName = "builder-app-locks";
export const reloadsCollectionName = "reloads";
export const ownerAppIssuesCollectionName = "owner-app-issues";
export const orgPrivateCollectionName = "org-private";
export const invitesCollectionName = "invites";
export const orgPlansCollectionName = "org-plans";
export const videosCollectionName = "videos";
export const templateSubmissionsCollectionName = "template-submissions";
export const cronJobsCollectionName = "cron-jobs";
export const nativeTablesCollectionName = "native-tables";
export const supportCodesCollectionName = "support-codes";
export const virtualEmailsCollectionName = "virtual-emails";
export const deleteCollectionsCollectionName = "delete-collections";
export const emailsCollectionName = "emails";
export const publishedAppLocksCollectionName = "glide-apps-v4-publish-locks";
export const stripeUpgradeLocksCollectionName = "unified-stripe-upgrades";
export const freeAppsCollectionName = "user-free-apps";
export const virtualEmailsMetadataCollectionName = "virtual-emails-metadata";
export const apiKeysCollectionName = "api-keys";
export const userRolesCollectionName = "user-roles";
export const privateTemplateAccessCollectionName = "private-template-access";

export const ownerAppUserIDsFieldName = "$ownerAppUserIDs";
export const appUserIDFieldName = "appUserID";
export const rowVersionFieldName = "$rowVersion";

export const uniqueAppUsersWindowDays = 28;
export const uniqueAppUsersWindowDuration = uniqueAppUsersWindowDays * 24 * 60 * 60 * 1000;

export const uniqueUsersFieldName = `last${uniqueAppUsersWindowDays}DayUniqueAppUsers`;

export const purchasesCollectionName = "purchases";
export const appUsageCountersCollectionName = "app-usage-counters";

export const zapierDocName = "zapier";
export const integrationsCollectionName = "integrations";

export const inAppPathComponent = "in-app";

const appReadWriteCountsCollectionName = "frontend-analytics";
const appReadWriteCountsSubcollectionName = "firestore-read-write";

export const supportAccessSubcollectionName = "support-access";

export const userProfilesDocumentPath = "user-profiles";
export const allowedEmailsDocumentName = "allowed-emails";

export const dataPrivacyRequestsCollectionName = "data-privacy-requests";

export const neverRefreshDocumentName = "never-refresh";

export const isCopiedFieldName = "$isCopied";

export function inAppPurchasesCollection(appID: string): string {
    return `${purchasesCollectionName}/${appID}/${inAppPathComponent}`;
}

export function makeInAppPurchaseActionPath(appID: string, purchaseID: string): string {
    return `${inAppPurchasesCollection(appID)}/${purchaseID}`;
}

export function appDailyUsageCountersCollectionForAppID(appID: string): string {
    return `${appUsageCountersCollectionName}/${appID}/usage`;
}

export function makeOrgInvitesCollectionPath(orgID: string): string {
    return `${orgPrivateCollectionName}/${orgID}/${invitesCollectionName}`;
}

const appUserIDsDocumentNamePrefix = "emails";
// NOTE: We've come dangerously close to the prior 40,000 limit so we're doubling
// this to 8. It realistically cannot go above 10, and might not even hit 10, due
// to Firestore's 10 MB transaction limit.
//
// There is a slight cost associated with this number being 8 instead of 4. Every
// time we read the "aggregate" user profile mapping, we delete documents with
// higher indexes than the ones we saw, up to this number. We could remove
// that cost by keeping track of what the highest index was that we read, and
// then when we save only delete up to that index.
export const maxAppUserIDDocuments = 8;
// We overflowed at 16k once, so this is fairly conservative.
export const numAppUserIDsPerDocument = 10000;

export function makeAppUserIDsDocumentName(i: number): string {
    assert(i >= 0 && i < maxAppUserIDDocuments);
    if (i === 0) {
        // This must be compatible with the existing documents from when there
        // was only one, so we can't append a number.
        return appUserIDsDocumentNamePrefix;
    } else {
        return `${appUserIDsDocumentNamePrefix}-${i}`;
    }
}

export function isAppUserIDsDocumentName(name: string): boolean {
    return name.startsWith(appUserIDsDocumentNamePrefix);
}

export function documentIDForUsageCounterTime(t: Date): string {
    // We expect this to have the format of e.g. 2019-08-14T23:32:24.939Z
    // We only want everything up to the T. Thankfully, this is a standardized
    // format so we can do this awful nasty thing.
    const asString = makeStartOfDayTimeZoneAgnostic(t).toISOString();
    return asString.substring(0, asString.indexOf("T"));
}

export function appBihourlyUsageCountersAccessForAppID(
    appID: string,
    startDate: Date
): { collection: string; documentID: string } {
    const startOfDay = makeStartOfDayTimeZoneAgnostic(startDate);
    // Because getTime() is always in UTC, we don't have to convert startDate to anything timezone agnostic.
    // This is still all correct with respect to UTC.
    const millisecondOffset = startDate.getTime() - startOfDay.getTime();
    const millisecondBucket = millisecondOffset - (millisecondOffset % (30 * 60 * 1000));
    return {
        collection: `${appDailyUsageCountersCollectionForAppID(appID)}/${documentIDForUsageCounterTime(
            startDate
        )}/bihourly`,
        documentID: millisecondBucket.toString(),
    };
}

export const zapsSubcollectionName = "zaps";

export function zapsCollectionForAppID(appID: string): string {
    return `${glideAppsDataCollectionName}/${appID}/${zapsSubcollectionName}`;
}

export function appIntegrationsCollection(appID: string): string {
    return `${glideAppsDataCollectionName}/${appID}/${integrationsCollectionName}`;
}

export function actionsMetadataCollectionForDevice(deviceID: string): string {
    return `${actionsMetadataCollectionName}/${deviceID}/actions`;
}

export function frontendAnalyticsCollectionName(isoDateString: string): string {
    return `${appReadWriteCountsCollectionName}/${isoDateString}/${appReadWriteCountsSubcollectionName}`;
}

export const commentsTableName: TableName = { name: "comments", isSpecial: true };
export const shoppingCartTableName: TableName = { name: "shopping-cart", isSpecial: true };
export const localDataStoreName = "local";

export function isSample(appID: string): boolean {
    return appID.startsWith("sample-") || appID.endsWith("-template");
}

export function isShadowPublishedTemplate(appID: string): boolean {
    return appID.endsWith("-template-published");
}

export function isBuilderTemplate(appID: string): boolean {
    return appID.endsWith(templateBasePostfix);
}

export function isReviewTemplate(appID: string): boolean {
    return appID.endsWith("-review");
}

export function isTemplate(appID: string): boolean {
    return isSample(appID) || isBuilderTemplate(appID) || isShadowPublishedTemplate(appID) || isReviewTemplate(appID);
}

export const getPreinstalledAppID = (isProduction: boolean): string => {
    return isProduction ? "e2QOdM9thkrYs1Zb9haz" : "jqkt1JMxROl8SA7kn3EI";
};

const templateBasePostfix = "-template-builder";
const templateApprovedPostfix = "-template";
const getBaseAppID = (sourceAppID: string) => {
    return sourceAppID.replace(templateBasePostfix, "").replace(templateApprovedPostfix, "");
};

export function getReviewTemplateID(sourceAppID: string) {
    const baseAppID = getBaseAppID(sourceAppID);
    return `${baseAppID}-review`;
}

export function getApprovedTemplateID(sourceAppID: string) {
    const baseAppID = getBaseAppID(sourceAppID);
    return `${baseAppID}${templateApprovedPostfix}`;
}

export function getBuilderTemplateID(sourceAppID: string) {
    const baseAppID = getBaseAppID(sourceAppID);
    return `${baseAppID}${templateBasePostfix}`;
}

const tutorialBeginnerSuffix = "-tutorial-beginner";
export function getTutorialAppID(sourceAppID: string) {
    const baseAppID = getBaseAppID(sourceAppID);
    return `${baseAppID}${tutorialBeginnerSuffix}`;
}

export function getShadowPublishedTemplateID(sourceAppID: string) {
    const baseAppID = getBaseAppID(sourceAppID);
    return `${baseAppID}-template-published`;
}

export function isStoreSubmissionGlideTeam(
    orgID: string | undefined,
    glideOrgs: {
        approvedOrgID: string;
        reviewOrgID: string;
    }
): boolean {
    if (orgID === undefined) {
        return false;
    }
    const teams = [glideOrgs.approvedOrgID, glideOrgs.reviewOrgID];
    return teams.includes(orgID);
}

// https://firebase.google.com/docs/firestore/quotas#collections_documents_and_fields

// Cannot contain a newline character (this is not documented)

// Cannot contain a forward slash (/)

// Cannot solely consist of a single period (.) or double periods (..)
// The Firestore web UI is more strict here: it can't start with a
// period and can't contain double periods.  Not documented: it also
// can't end with a period.
// Since we sometimes use `updateNested`, any period will introduce a
// map, so we just disallow them completely.

// Also, field names can, but shouldn't, include dots:
// https://github.com/quicktype/glide/issues/5521

// Brackets, asterisks, and tildes aren't allowed, either:
// https://github.com/quicktype/glide/issues/8410
const invalidCharRegexp = /[*~\n/.[\]]/;

export function isLegalFirebaseName(id: string, byteLimit: number): boolean {
    if (id === "") return false;

    if (invalidCharRegexp.test(id)) return false;

    // Cannot match the regular expression __.*__
    if (id.startsWith("__") && id.endsWith("__")) return false;

    // Must be no longer than 1,500 bytes
    if (utf8ByteLength(id) > byteLimit) return false;

    // FIXME: Check: Must be valid UTF-8 characters

    return true;
}

const documentIDsForTableNames = new Map<string, string>();

export const documentIDByteLimit = 1500;

export function documentIDForTableName(tableName: TableName): string {
    const { name, isSpecial } = tableName;

    if (isSpecial) {
        return "$" + name;
    }

    const cachedID = documentIDsForTableNames.get(name);
    if (cachedID !== undefined) return cachedID;

    let id =
        "_" +
        Array.from(name)
            .map(c => {
                if (c === "/") return "#_";
                if (c === "#") return "##";
                return c;
            })
            .join("");
    if (!isLegalFirebaseName(id, documentIDByteLimit)) {
        id = md5(id);
        assert(isLegalFirebaseName(id, documentIDByteLimit));
    }

    documentIDsForTableNames.set(name, id);
    return id;
}

export const tablesSubcollectionName = "tables";

export function makeTablesPath(appID: string): string {
    return `${glideAppsDataCollectionName}/${appID}/${tablesSubcollectionName}`;
}

export function tableNameAsDocumentID(nameOrDocumentID: string | TableName): string {
    if (typeof nameOrDocumentID === "string") {
        assert(nameOrDocumentID !== "");
        return nameOrDocumentID;
    } else {
        return documentIDForTableName(nameOrDocumentID);
    }
}

// ##makeRoleHash:
export function makeRoleHash(role: string): string | undefined {
    role = role.trim().toLowerCase();
    if (role === "") return undefined;
    return md5(role);
}

export const publicRowsSubcollectionName = "rows";
export const privateRowsSubcollectionName = "private-rows";
export const userRowsSubcollectionName = "user-rows";
const preliminaryRowsCleanupSubcollectionName = "preliminary-cleanup";

export function makePublicRowsPath(tablesPath: string, nameOrDocumentID: string | TableName): string {
    return `${tablesPath}/${tableNameAsDocumentID(nameOrDocumentID)}/${publicRowsSubcollectionName}`;
}

export function makePrivateRowsPath(tablesPath: string, nameOrDocumentID: string | TableName): string {
    return `${tablesPath}/${tableNameAsDocumentID(nameOrDocumentID)}/${privateRowsSubcollectionName}`;
}

export function makePreliminaryCleanupPath(tablesPath: string, nameOrDocumentID: string | TableName): string {
    return `${tablesPath}/${tableNameAsDocumentID(nameOrDocumentID)}/${preliminaryRowsCleanupSubcollectionName}`;
}

export function makeUserRowsPath(tablesPath: string, tableName: TableName): string {
    return `${tablesPath}/${documentIDForTableName(tableName)}/${userRowsSubcollectionName}`;
}

export function makeCommentsPath(appID: string): string {
    return makePublicRowsPath(makeTablesPath(appID), commentsTableName);
}

export const loginLogSubcollectionName = "login-log";
export const loginSummaryPathComponent = "login-summary";

export function makeLoginLogPath(appID: string): string {
    return `${glideAppsMetadataCollectionName}/${appID}/${loginLogSubcollectionName}`;
}

export function makeLoginSummaryPath(appID: string): string {
    return `${glideAppsMetadataCollectionName}/${appID}/${loginSummaryPathComponent}`;
}

export enum ActionKind {
    AddRowToTable = "add-row-to-table",
    SetColumnsInRow = "set-columns-in-row",
    DeleteRow = "delete-row",
    WritePurchaseToSheet = "purchases",
    DeleteColumn = "delete-column",
    // ##simulatedError:
    // This is only used for error injection in testing and should never occur
    // in production.
    SimulatedError = "simulated-error",
}

export function isBasicTableMutation(kind: ActionKind): boolean {
    return kind === ActionKind.AddRowToTable || kind === ActionKind.SetColumnsInRow || kind === ActionKind.DeleteRow;
}

export function makeActionDocumentPath(appID: string, kind: ActionKind): string {
    return `${actionsCollectionName}/${appID}/${kind}`;
}

const actionKindRegexp = [
    ActionKind.AddRowToTable,
    ActionKind.SetColumnsInRow,
    ActionKind.DeleteRow,
    ActionKind.DeleteColumn,
    ActionKind.SimulatedError,
]
    .map(escapeStringAsRegexp)
    .join("|");

const documentIDMatch = "([^/]+)";

const actionDocumentPathRegexp = new RegExp(
    `^(${escapeStringAsRegexp(actionsCollectionName)}/${documentIDMatch}/(${actionKindRegexp}))/${documentIDMatch}$`
);

const purchasesDocumentPathRegexp = new RegExp(
    `^(${escapeStringAsRegexp(purchasesCollectionName)}/${documentIDMatch}/in-app)/${documentIDMatch}$`
);

export interface ActionDocumentMetadata {
    readonly kind: ActionKind;
    readonly appID: string;

    readonly collection: string;
    readonly docID: string;
    // the full path, i.e. `${collection}/${docID}`
    readonly actionPath: string;
}

export function makeActionDocumentMetadata(appID: string, kind: ActionKind, docID: string): ActionDocumentMetadata {
    const collection = makeActionDocumentPath(appID, kind);
    return {
        kind,
        appID,
        collection,
        docID,
        actionPath: `${collection}/${docID}`,
    };
}

export function parseActionDocumentPath(path: string | ActionDocumentMetadata): ActionDocumentMetadata | undefined {
    if (typeof path !== "string") {
        return path;
    }

    let match = path.match(actionDocumentPathRegexp);
    if (match !== null) {
        return {
            appID: match[2],
            kind: match[3] as ActionKind,
            collection: match[1],
            docID: match[4],
            actionPath: path,
        };
    }

    match = path.match(purchasesDocumentPathRegexp);
    if (match !== null) {
        return {
            appID: match[2],
            kind: ActionKind.WritePurchaseToSheet,
            collection: match[1],
            docID: match[3],
            actionPath: path,
        };
    }

    return undefined;
}

export const backupsSubcollectionName = "backups";

export function makeBuilderAppBackupsPath(appID: string): string {
    return `${builderAppsCollectionName}/${appID}/${backupsSubcollectionName}`;
}

export function makeBuilderActionsBackupsPath(actionID: string): string {
    return `${builderActionsCollectionName}/${actionID}/${backupsSubcollectionName}`;
}

export function makeAppUsersForAppPath(appID: string): string {
    return `${glideAppsDataCollectionName}/${appID}/app-users`;
}

export function makeUserProfileDocumentPathForApp(appID: string): string {
    return `${glideAppsDataCollectionName}/${appID}/${userProfilesDocumentPath}`;
}
