import "twin.macro";

import { TextComponentStyle } from "@glide/component-utils";
import type { WirePageProgressComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isUndefinedish } from "@glide/support";
import chroma from "chroma-js";
import * as React from "react";
import { css } from "styled-components";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

import { Text } from "../../components/text/text";
import type { WireRenderer } from "../wire-renderer";

function clamp01(value: number): number {
    return Math.min(1, Math.max(0, value));
}

// Isn't there a proper name for inverse lerp???
function inverseLerp(min: number, max: number, value: number): number {
    return clamp01((value - min) / (max - min));
}

export const WirePageProgress: WireRenderer<WirePageProgressComponent> = React.memo(p => {
    const { min, max, value, title, description } = p;

    const theme = useWireAppTheme();

    if (isUndefinedish(min) || isUndefinedish(max) || isUndefinedish(value)) {
        return null;
    }

    const fillRatio = inverseLerp(min.value, max.value, value.value);
    const xPercentage = (fillRatio - 1) * 100;

    return (
        <div>
            <div tw="flex justify-between items-end mb-2">
                <div>
                    <Text element="p" variant={TextComponentStyle.headlineXXXSmall}>
                        {title}
                    </Text>
                    <Text element="p" variant={TextComponentStyle.small}>
                        {description}
                    </Text>
                </div>
                <Text element="p" variant={TextComponentStyle.small}>
                    {value.formattedValue}
                </Text>
            </div>
            <div
                tw="h-2 w-full rounded-full overflow-hidden"
                css={css`
                    background-color: ${chroma(theme.textContextualAccent).alpha(0.1).css()};
                `}>
                <div
                    tw="h-full w-full rounded-full bg-text-contextual-accent transition-transform"
                    style={{ transform: `translateX(${xPercentage}%)` }}
                />
            </div>
        </div>
    );
});
