import { styled } from "@glide/common";
import { PureComponent } from "react";

import type { SettingsElement } from "./list-config-ui-types";

interface Props extends React.PropsWithChildren {
    item: SettingsElement;
}

const ListConfigItemContainer = styled.div`
    padding: 0 12px 1px;

    .item-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 8px;
        cursor: pointer;
        border-radius: 9px;

        &:hover,
        &:focus {
            background-color: ${p => p.theme.n100A};
        }
    }

    .selected & .item-wrapper {
        background-color: ${p => p.theme.n200A};
    }

    & .left {
        margin-right: 10px;
        padding: 7px;
        border-radius: 8px;
        background-color: ${p => p.theme.n200A};
        color: ${p => p.theme.iconDark};
    }

    & .right {
        flex: 1;

        .title {
            font-size: 14px;
            line-height: 17px;
            color: ${p => p.theme.textDark};
            margin-bottom: 1px;
        }

        .caption {
            font-size: 12px;
            line-height: 16px;
            color: ${p => p.theme.textPale};
        }
    }
`;

export default class ListConfigUIItemView extends PureComponent<Props, {}> {
    render() {
        const { item } = this.props;
        const { icon, title, caption, showDot } = item;

        return (
            <ListConfigItemContainer>
                <div className="item-wrapper">
                    <div className="left">{icon}</div>
                    <div className="right">
                        <div className="title">{title}</div>
                        <div className="caption">{caption}</div>
                    </div>
                    {showDot && <div tw="relative right-2.5 w-2 h-2 rounded-full bg-r400" />}
                </div>
            </ListConfigItemContainer>
        );
    }
}
