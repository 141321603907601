export function getFolderName(actionName: string): [string | undefined, string] {
    if (!actionName.includes("/")) {
        return [undefined, actionName];
    }
    const parts = actionName.split("/");
    const folderName = parts.shift()?.trim();
    const finalName = parts.join("/").trim();
    if (folderName?.length === 0 || finalName.length === 0) {
        return [undefined, actionName];
    }
    return [folderName, finalName];
}
