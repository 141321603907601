import * as t from "io-ts";

export function makeUncheckedStringCodec<T extends string>() {
    return new t.Type<T, string, unknown>(
        "uncheckedString",
        (u): u is T => typeof u === "string",
        (u, c) => (typeof u === "string" ? t.success(u as T) : t.failure(u, c)),
        t.identity
    );
}
