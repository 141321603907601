import {
    type LocalizedStringKey,
    formatLocalizedString,
    getLocalizedString,
    makeLocalizedNumberOfItems,
} from "@glide/localization";
import { compareValues } from "@glide/common-core/dist/js/components/primitives";
import {
    type DefinedPrimitiveValue,
    type GroundValue,
    type LoadedGroundValue,
    type LoadingValue,
    type PrimitiveValue,
    type Row,
    type WritableValue,
    Table,
    isLoadingValue,
    isPrimitiveValue,
    type Query,
    generateArrayOverlapQueryCondition,
    isQuery,
    type Unbound,
    UnboundVal,
    isBound,
    type QueryBase,
} from "@glide/computation-model-types";
import {
    getRowColumn,
    asBoolean,
    asMaybeBoolean,
    asMaybeNumber,
    asMaybeString,
    asString,
    isArrayValue,
    isNotEmpty,
    isRow,
    isTable,
    nullLoadingToUndefined,
    parseValueAsGlideDateTimeSync,
} from "@glide/common-core/dist/js/computation-model/data";
import { convertToArrayOverlapKey } from "@glide/common-core/dist/js/computation-model/relation-keys";
import {
    type TableName,
    isFavoritedColumnName,
    type ColumnType,
    type Description,
    type SourceColumn,
    type TableColumn,
    type TableGlideType,
    SpecialValueKind,
    getTableColumn,
    getTableName,
    isDataSourceColumn,
    isPrimitiveArrayType,
    isPrimitiveType,
    isSingleRelationType,
    makeSourceColumn,
    getSourceMetadataFlags,
    maybeGetTableColumn,
} from "@glide/type-schema";
import type { EminenceFlags } from "@glide/billing-types";
import {
    type ActionDescription,
    type ColumnAssignment,
    type ComponentDescription,
    type LegacyPropertyDescription,
    type PropertyDescription,
    ArrayTransformKind,
    MutatingScreenKind,
    getActionProperty,
    getArrayProperty,
    getColumnProperty,
    getEnumProperty,
    getIconProperty,
    getSourceColumnProperty,
    getSpecialValueProperty,
    getStringProperty,
    getSwitchWithConditionProperty,
    makeColumnProperty,
    makeSourceColumnProperty,
} from "@glide/app-description";
import { ImageKind, makeInputOutputTables } from "@glide/common-core/dist/js/description";
import { doesTableAllowAddingUserSpecificColumns } from "@glide/common-core/dist/js/schema-properties";
import { GlideDateTime, GlideJSON } from "@glide/data-types";
import type {
    WireActionWithTitle,
    WireImageSource,
    WireSignInComponent,
} from "@glide/fluent-components/dist/js/base-components";
import type { ActionWithTitleDescription, WireHintComponent } from "@glide/fluent-components/dist/js/fluent-components";
import {
    type InlineListComponentDescription,
    QueryableTableSupport,
    dynamicFilterColumnPropertyHandler,
    thisRowSourceColumn,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import {
    type JSONObject,
    ImageSourceKind,
    MapLocationKind,
    compareStrings,
    isArray,
    isDefined,
    isEmptyOrUndefined,
    isUndefinedish,
    logError,
    withTimeoutSync,
} from "@glide/support";
import {
    mapFilterUndefined,
    sleep,
    DefaultMap,
    assert,
    defined,
    definedMap,
    panic,
    assertNever,
} from "@glideapps/ts-necessities";
import {
    type BooleanMultipleDynamicFilterEntry,
    type BuilderCallbacks,
    type DynamicFilterEntriesWithCaption,
    type DynamicFilterEntry,
    type DynamicFilterResult,
    type InflatedColumn,
    type InflatedProperty,
    type RowBackends,
    type StringMultipleDynamicFilterEntry,
    type ValueGetterOptions,
    type WireActionBackend,
    type WireActionRunnerWithURL,
    type WireAlwaysEditableValue,
    type WireComponentHydrationResult,
    type WireDynamicFilter,
    type WireFilterValueAndFormatGetter,
    type WireHydrationBackend,
    type WireHydrationFollowUp,
    type WireMultipleDynamicFilters,
    type WirePredicate,
    type WireRowComponentHydrator,
    type WireRowComponentHydratorConstructor,
    type WireRowHydrationValueProvider,
    type WireScreenStateProvider,
    type WireStateSaver,
    type WireTableComponentHydrationBackend,
    type WireTableComponentHydrationResult,
    type WireTableComponentHydrator,
    type WireTableComponentHydratorConstructor,
    type WireTableComponentQueryHydrator,
    type WireTableTransformValueProvider,
    type WireValueGetter,
    type WireValueGetterGeneric,
    type WireActionInflationBackend,
    type WireValueInflationBackend,
    type WireScreen,
    type WireScreenKey,
    type WireAction,
    type WireComponent,
    type WireEditableValue,
    type WirePaging,
    WireActionResultBuilder,
    WireActionResultKind,
    WireActionResult,
    ValueChangeSource,
    WireComponentKind,
    makeContextTableTypes,
    WireScreenFlag,
    WireActionBusy,
    type WireActionHydrationResult,
    type WireActionHydrator,
    type WireActionRunner,
    type WireComponentHydratorWithID,
    type WireInflationBackend,
    type WireRowActionHydrationValueProvider,
    type WireRowComponentHydrationBackend,
} from "@glide/wire";
import md5 from "blueimp-md5";
import { iterableEnumerate, setUnion } from "collection-utils";
import produce from "immer";
import isBoolean from "lodash/isBoolean";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import type { NonUndefined } from "utility-types";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { handlerForActionKind } from "../actions";
import type { SpecializedActionDebugger } from "../actions/compound-handler";
import type { DynamicFilterSupport } from "../array-screens/array-content";
import type { GroupByGetters } from "../array-screens/summary-array-screen";
import { getWritableColumnsForColumnAssignment, isTypeAssignableToLink } from "../components/descriptor-utils";
import { CollectingVisitor, SearchResultCollector } from "../components/find-column-uses";
import { walkAction } from "../components/walk-app-description";
import { getColumnsUsedInColumn } from "../computed-columns";
import { getSourceColumnOrThis } from "../description-utils";
import { formatValueWithSpecification } from "../format-value";
import { getEffectiveDisplayFormulaForColumn } from "../formulas/compiler";
import {
    type ValueFormatSpecification,
    decomposeFormatFormula,
    getDefaultFormatForTypeKind,
} from "@glide/formula-specifications";
import { handlerForComponentKind } from "../handlers";
import { getTargetForLink } from "../link-columns";
import { getSourceMetadataForTable } from "@glide/common-core/dist/js/components/SerializedApp";
import { decomposeAggregateRow } from "./aggregates";
import { hasRequiredPlan, Mood } from "@glide/component-utils";
import startCase from "lodash/startCase";
import { isColumnAllowedForSearching } from "../allowed-columns";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";

export function encodeScreenKey(s: string): WireScreenKey {
    return md5(s) as WireScreenKey;
}

export function isNotEmptyDisplayValue(v: GroundValue | null): boolean {
    return v !== undefined && v !== null && v !== "";
}

export type WireCanonicalActionHydrationResult = WireActionRunnerWithURL | WireActionResult;

function canonizeResult(result: WireActionHydrationResult): WireCanonicalActionHydrationResult {
    if (result instanceof WireActionResult) {
        return result;
    } else if (isArray(result)) {
        return result;
    } else {
        return [result, undefined];
    }
}

export function hydrateAction(
    hydrator: WireActionHydrator | WireActionResult,
    vp: WireRowActionHydrationValueProvider,
    skipLoading: boolean,
    detailScreenTitle: string | undefined
): WireCanonicalActionHydrationResult {
    if (hydrator instanceof WireActionResult) return hydrator;

    // This gives us a VP that ignores loading value display values
    vp = vp.makeHydrationBackendForAction();

    return canonizeResult(hydrator(vp, skipLoading, detailScreenTitle));
}

function makeActionForResult(
    key: string,
    rhb: WireHydrationBackend,
    result: WireCanonicalActionHydrationResult
): WireAction | undefined {
    if (result instanceof WireActionResult) {
        switch (result.kind) {
            case WireActionResultKind.PermanentError:
            case WireActionResultKind.RetryableError:
            case WireActionResultKind.InflationError:
                return undefined;
            case WireActionResultKind.Loading:
                return { token: null };
            case WireActionResultKind.Offline:
                return { token: undefined };
            case WireActionResultKind.Success:
                logError("Unexpected success result in makeActionForResult");
                return undefined;
            default:
                return assertNever(result.kind);
        }
    }

    const [runner, url] = result;
    return {
        token: rhb.registerAction(key, runner),
        url,
    };
}

export function hydrateAndRegisterAction(
    key: string,
    hydrator: WireActionHydrator | WireActionResult,
    rhb: WireRowComponentHydrationBackend,
    skipLoading: boolean,
    detailScreenTitle: string | undefined
): WireAction | undefined {
    const result = hydrateAction(hydrator, rhb, skipLoading, detailScreenTitle);
    return makeActionForResult(key, rhb, result);
}

function shouldAbortForActionResult(result: WireActionResult): boolean {
    switch (result.kind) {
        case WireActionResultKind.Success:
        // We used to not abort for inflation errors, which feels wrong,
        // but changing this might break existing apps, so we continue
        // doing it.
        case WireActionResultKind.InflationError:
        // We use the "disabled" state for actions that can't run because the
        // device is offline.
        case WireActionResultKind.Offline:
            return false;
        case WireActionResultKind.PermanentError:
        case WireActionResultKind.RetryableError:
        case WireActionResultKind.Loading:
            return true;
        default:
            return assertNever(result.kind);
    }
}

function canActionRun(result: WireCanonicalActionHydrationResult): boolean {
    if (result instanceof WireActionResult) {
        switch (result.kind) {
            case WireActionResultKind.PermanentError:
            case WireActionResultKind.RetryableError:
            case WireActionResultKind.InflationError:
            case WireActionResultKind.Loading:
            case WireActionResultKind.Offline:
                return false;
            case WireActionResultKind.Success:
                return true;
            default:
                return assertNever(result.kind);
        }
    } else {
        return true;
    }
}

function decomposeAction(result: WireCanonicalActionHydrationResult): WireActionRunnerWithURL {
    if (result instanceof WireActionResult) {
        return [async () => result, undefined];
    } else {
        return result;
    }
}

export function hydrateActionWithRunnerAfter(
    // If `hydrator` is `undefined` then the `runnerAfter` should still run.
    hydrator: WireActionHydrator | WireActionResult,
    vp: WireRowActionHydrationValueProvider,
    skipLoading: boolean,
    detailScreenTitle: string | undefined,
    runnerAfter: WireActionRunner,
    urlAfter: string | undefined
): WireCanonicalActionHydrationResult {
    const hydrationResult = hydrateAction(hydrator, vp, skipLoading, detailScreenTitle);
    if (!canActionRun(hydrationResult)) return hydrationResult;

    const [actionRunner, actionURL] = decomposeAction(hydrationResult);

    return [
        async (ab, handled) => {
            // If `handled` is set, then it can apply to either the action, or
            // to the link we have to open, depending on whether the action
            // has a URL in the first place.
            //
            // If we have an `actionURL`, then `handled` refers to that URL
            // because we do that action first, otherwise it refers to the
            // `urlAfter`.
            //
            // Note that we have to call the runner no matter what.  But we
            // have to make sure that its `handled` applies to its URL, not
            // the `urlAfter`.
            if (actionRunner !== undefined) {
                const result = await ab.invoke(
                    "action with runner after",
                    actionRunner,
                    actionURL !== undefined && handled
                );
                if (shouldAbortForActionResult(result)) return result;
            }
            return await ab.invoke("runner after", runnerAfter, actionURL === undefined && handled);
        },
        actionURL ?? urlAfter,
    ];
}

async function hydrateActionWithTimeout(
    hydrator: WireActionHydrator | WireActionResult,
    vp: WireRowActionHydrationValueProvider,
    detailScreenTitle: string | undefined,
    timesOutAt: Date | undefined
): Promise<WireCanonicalActionHydrationResult> {
    // TODO: This is a somewhat bad way to do this.  The proper way is to
    // subscribe to all the necessary paths and try hydrating the action on
    // every update, which will produce a new set of paths to subscribe to.
    while (timesOutAt === undefined || new Date() < timesOutAt) {
        const result = hydrateAction(hydrator, vp, false, detailScreenTitle);
        if (result instanceof WireActionResult && result.kind === WireActionResultKind.Loading) {
            await sleep(10);
            continue;
        }
        return result;
    }
    return hydrateAction(hydrator, vp, true, detailScreenTitle);
}

export async function hydrateSubAction(
    ab: WireActionBackend,
    hydrator: WireActionHydrator | WireActionResult
): Promise<WireActionRunner> {
    const hb = ab.makeBackendForSubAction();
    // sub actions don't get detail screen titles
    const maybeRunner = await hydrateActionWithTimeout(hydrator, hb, undefined, ab.timesOutAt);
    if (maybeRunner instanceof WireActionResult) {
        return async () => maybeRunner;
    }
    const [runner] = maybeRunner;
    return runner;
}

// If this returns `false` it means that there's an error hydrating the
// action.  If it returns `undefined` it means that nothing nees to be run.
export function hydrateOnSubmitAction(
    onSubmitHydrator: WireActionHydrator | WireActionResult,
    makeHydrationBackend: () => WireRowComponentHydrationBackend | undefined
): WireActionRunner | false | undefined {
    if (onSubmitHydrator === undefined) return undefined;

    const onSubmitHB = makeHydrationBackend();
    if (onSubmitHB === undefined) return false;

    // ##onSubmitActionContext:
    // The on-submit action runs in a context where the screen's output row is
    // the input row.  That's why we make the backend for the output row here,
    // but then we also make give `hydrateAction` a runner that doesn't use it
    // and only hydrates again, which defeats the purpose.  So, if that method
    // ever bugs us, we can remove it.  The reason we're not hydrating the
    // action here directly and instead return a runner that hydrates it is
    // that at the point of hydration (i.e. here) we haven't set the column
    // assignments yet, so the hydrator wouldn't get them.  `hydrateSubAction`
    // hydrates its action with a timeout, so even if we need to load anything
    // for the action to run, that will happen as long as it's within the
    // timeout.
    // https://github.com/quicktype/glide/issues/12462

    const onSubmit = hydrateAction(
        () => {
            return async (ab, handled) => {
                const runner = await hydrateSubAction(ab, onSubmitHydrator);
                return await ab.invoke("on submit", runner, handled);
            };
        },
        onSubmitHB,
        false,
        undefined
    );
    if (!canActionRun(onSubmit)) return false;

    if (onSubmit instanceof WireActionResult) return async () => onSubmit;
    return onSubmit[0];
}

export interface CompoundActionLogger {
    logActionResult(ab: WireActionBackend, startedAt: Date, finishedAt: Date, result: WireActionResult): void;
    logCondition(ab: WireActionBackend, evaluatedAt: Date, title: string): void;
}

// This will return the result of the last action that was run.  If `maybeARB`
// was passed, it will be "laundered" through it, i.e. we will use `maybeARB`
// to produce the final result, and it will be either a success or an error,
// depending on what `shouldAbortForActionResult` returns.
export function makeActionRunner(
    hydrators: readonly (WireActionHydrator | WireActionResult)[],
    useURL: boolean,
    vp: WireRowActionHydrationValueProvider,
    skipLoading: boolean,
    detailScreenTitle: string | undefined,
    maybeARB: WireActionResultBuilder | undefined,
    logger: CompoundActionLogger | undefined,
    conditionUsed: string | undefined,
    actionDebugger?: SpecializedActionDebugger
): WireCanonicalActionHydrationResult {
    if (hydrators.length === 0) return maybeARB?.nothingToDo() ?? WireActionResult.nothingToDo();

    const [firstHydrator, ...otherHydrators] = hydrators;

    const maybeFirstRunner = hydrateAction(firstHydrator, vp, skipLoading, detailScreenTitle);
    if (!canActionRun(maybeFirstRunner)) return maybeFirstRunner;
    if (hydrators.length === 1 && maybeFirstRunner instanceof WireActionResult) return maybeFirstRunner;

    const [firstRunner, maybeURL] = decomposeAction(maybeFirstRunner);
    const url = useURL ? maybeURL : undefined;

    return [
        async (ab, handled) => {
            const compoundStartedAt = new Date();

            if (actionDebugger !== undefined) {
                if (!(await actionDebugger.shouldRun())) {
                    return WireActionResult.nondescriptError(true, "Action debugger cancelled action");
                }
            }

            if (conditionUsed !== undefined) {
                logger?.logCondition(ab, compoundStartedAt, conditionUsed);
            }

            function finish(result: WireActionResult) {
                actionDebugger?.didRun(result);
                if (maybeARB !== undefined) {
                    let compoundResult: WireActionResult;
                    if (shouldAbortForActionResult(result)) {
                        compoundResult = maybeARB.error(result.isPermanentError, "Action failed", result.errorData);
                    } else {
                        compoundResult = maybeARB.success();
                    }
                    logger?.logActionResult(ab, compoundStartedAt, new Date(), compoundResult);
                    return compoundResult;
                } else {
                    return result;
                }
            }

            let startedAt = compoundStartedAt;
            let lastResult = await ab.invoke(`action 0`, firstRunner, handled && url !== undefined);
            logger?.logActionResult(ab, startedAt, new Date(), lastResult);
            if (shouldAbortForActionResult(lastResult)) return finish(lastResult);

            for (const [i, hydrator] of iterableEnumerate(otherHydrators)) {
                startedAt = new Date();
                const runner = await hydrateSubAction(ab, hydrator);
                lastResult = await ab.invoke(`action ${i + 1}`, runner, false);
                logger?.logActionResult(ab, startedAt, new Date(), lastResult);
                if (shouldAbortForActionResult(lastResult)) return finish(lastResult);
            }

            return finish(lastResult);
        },
        url,
    ];
}

export function hydrateOpenURL(
    desc: ActionDescription | undefined,
    url: string,
    data: JSONObject
): [WireActionRunner, string] {
    return [
        async (ab, handled) => {
            if (!handled) {
                ab.actionCallbacks.openLink(url);
            }
            const arb =
                desc !== undefined
                    ? WireActionResultBuilder.fromDescription(desc, undefined)
                    : WireActionResultBuilder.nondescript();
            return arb.addData(data).success();
        },
        url,
    ];
}

function getAllTablesUsedInActions(
    ib: WireValueInflationBackend,
    actions: readonly ActionDescription[],
    mutatingScreenKind: MutatingScreenKind | undefined,
    actionIsIndirect: boolean
): ReadonlySet<TableGlideType> {
    const toVisit: [TableGlideType, string][] = [];

    class Visitor extends CollectingVisitor {
        protected shouldAddColumnUsed(tn: TableName, cn: string, isWrite: boolean): boolean {
            if (isWrite) return false;

            const table = ib.adc.findTable(tn);
            if (table === undefined) return false;

            toVisit.push([table, cn]);

            return true;
        }
    }

    const collector = new SearchResultCollector();
    const visitor = new Visitor(ib.adc, collector, undefined);

    for (const action of actions) {
        walkAction(ib.adc, visitor, undefined, action, ib.tables, mutatingScreenKind, actionIsIndirect, true);
    }

    const tablesAndColumns = new DefaultMap<TableGlideType, Set<TableColumn>>(() => new Set());

    for (;;) {
        const tableAndColumn = toVisit.pop();
        if (tableAndColumn === undefined) break;

        const [table, columnName] = tableAndColumn;
        const column = getTableColumn(table, columnName);
        if (column === undefined) continue;

        if (tablesAndColumns.get(table).has(column)) continue;

        tablesAndColumns.get(table).add(column);

        for (const [t, cns] of getColumnsUsedInColumn(ib.adc, getTableName(table), column)[0]) {
            for (const cn of cns) {
                toVisit.push([t, cn]);
            }
        }
    }

    return new Set(tablesAndColumns.keys());
}

export function inflateActionsWithCanAutoRun(
    ib: WireActionInflationBackend,
    actions: readonly ActionDescription[],
    obeyConditions: boolean = true,
    actionDebugger?: SpecializedActionDebugger
): { readonly actionHydrator: WireActionHydrator | WireActionResult; readonly canAutoRunAction: boolean } {
    let canAutoRunAction = false;
    const hydrators: WireActionHydrator[] = mapFilterUndefined(actions, a => {
        // FIXME: This function should get the action node keys, too, so it
        // can pass it in here.
        const arb = WireActionResultBuilder.fromDescription(a, undefined);

        if (a.enabled === false) return undefined;

        const handler = handlerForActionKind(a.kind);
        if (handler.canAutoRun === true) {
            canAutoRunAction = true;
        }

        const tier = handler.getTier(ib.adc.appKind);
        // We don't enforce action tiers for Classic Apps.
        if (
            ib.adc.appKind !== AppKind.App &&
            tier !== undefined &&
            !hasRequiredPlan(tier, ib.adc.eminenceFlags.pluginTier) &&
            getFeatureSetting("enforceActionTiers")
        ) {
            return undefined;
        }

        const actionHydrator = handler.inflate?.(ib, a, arb);
        if (actionHydrator === undefined) return undefined;

        // This is where we ignore ##conditionsInCustomActions.
        if (obeyConditions && a.condition !== undefined) {
            const [predicateInflator] = ib.inflateFilters([a.condition], false);
            return (
                vp: WireRowActionHydrationValueProvider,
                skipLoading: boolean,
                detailScreenTitle: string | undefined
            ) => {
                const isPredicateTrue = predicateInflator(vp);
                if (isPredicateTrue === false) return arb.nothingToDo("Condition not met");
                if (isLoadingValue(isPredicateTrue)) return arb.maybeSkipLoading(skipLoading, "Condition");
                if (actionHydrator instanceof WireActionResult) return actionHydrator;
                return actionHydrator(vp, skipLoading, detailScreenTitle);
            };
        } else if (actionHydrator instanceof WireActionResult) {
            return () => actionHydrator;
        } else {
            return actionHydrator;
        }
    });
    if (hydrators.length === 0)
        return { actionHydrator: () => WireActionResult.nothingToDo("No actions to run"), canAutoRunAction: false };

    // I don't think it's a problem that we're not passing in the mutating
    // screen kind or the indirect flag here.
    const tables = getAllTablesUsedInActions(ib, actions, undefined, false);

    return {
        actionHydrator: (vp, skipLoading, detailScreenTitle) => {
            for (const table of tables) {
                vp.fetchTable(getTableName(table));
            }
            return makeActionRunner(
                hydrators,
                true,
                vp,
                skipLoading,
                detailScreenTitle,
                undefined,
                undefined,
                undefined,
                actionDebugger
            );
        },
        canAutoRunAction,
    };
}

export function inflateActions(
    ib: WireActionInflationBackend,
    actions: readonly ActionDescription[],
    obeyConditions: boolean = true,
    actionDebugger?: SpecializedActionDebugger
): WireActionHydrator | WireActionResult {
    return inflateActionsWithCanAutoRun(ib, actions, obeyConditions, actionDebugger).actionHydrator;
}

export type OutputValueGetters<T extends WireRowHydrationValueProvider> = readonly [
    string,
    (hb: T) => GroundValue | null
][];

export function inflateColumnAssignments(
    ib: WireActionInflationBackend,
    destTable: TableGlideType,
    columnAssignments: readonly ColumnAssignment[],
    forAddingRow: boolean
): OutputValueGetters<WireRowHydrationValueProvider> {
    // We only inflate ##writableColumnsForColumnAssignments.
    const writableColumns = getWritableColumnsForColumnAssignment(ib.adc, destTable, {
        withLinkColumns: true,
        withArrays: true,
        forAddingRow,
    });

    const outputValueGetters: [string, WireValueGetter][] = [];
    for (const a of columnAssignments) {
        // We're being defensive here.  It seems to be the case that the
        // builder never makes column assignments where the right-hand-side is
        // an empty string (which is indistinguishable to the user from "no
        // value").  But we're still guarding against that case here.
        if (getStringProperty(a.value) === "") continue;

        const destColumn = getTableColumn(writableColumns, a.destColumn);
        if (destColumn === undefined) continue;

        const [getter, type] = ib.getValueGetterForProperty(a.value, false);
        if (type === undefined) continue;

        const linkTarget = getTargetForLink(destTable, destColumn, ib.adc, false);
        if (isPrimitiveArrayType(destColumn.type)) {
            if (isPrimitiveArrayType(type)) {
                outputValueGetters.push([a.destColumn, getter]);
            } else if (isPrimitiveType(type)) {
                // if the column in an array, use a getter that returns empty array
                if (getSpecialValueProperty(a.value) === SpecialValueKind.ClearColumn) {
                    const clearColumnGetter = () => (destColumn.type.kind === "array" ? [] : "");
                    outputValueGetters.push([a.destColumn, clearColumnGetter]);
                }
                // uncomment this to support loading values and other types in arrays if needed
                // const arrayGetter: WireValueGetterGeneric<PrimitiveValue | LoadingValue | PrimitiveValue[]> = hb => {
                //     const v = getter(hb);
                //     if (isLoadingValue(v)) return v;
                //     if (!isPrimitiveValue(v)) return undefined;
                //     // this handles cases where clear column getter
                //     // returns empty string but we need array values here.
                //     if (v === "") return [];
                //     return v;
                // };
                // outputValueGetters.push([a.destColumn, arrayGetter]);
            } else {
                continue;
            }
        } else if (linkTarget === undefined) {
            if (!isPrimitiveType(type)) continue;
            outputValueGetters.push([a.destColumn, getter]);
        } else {
            // `getTargetForLink` already checks whether the host column is
            // writable, so we don't have to worry about that here.
            const hostColumnIsArray = linkTarget.hostColumn.type.kind === "array";
            let keysGetter: WireValueGetter;

            if (getSpecialValueProperty(a.value) === SpecialValueKind.ClearColumn) {
                keysGetter = () => (hostColumnIsArray ? [] : "");
            } else {
                if (!isTypeAssignableToLink(linkTarget, type, ib.adc)) continue;

                const targetTables = makeInputOutputTables(linkTarget.targetTable);
                const targetIB = ib.makeInflationBackendForTables(
                    makeContextTableTypes(targetTables),
                    ib.mutatingScreenKind
                );
                const [targetKeyGetter, targetKeyType] = targetIB.getValueGetterForSourceColumn(
                    makeSourceColumn(linkTarget.targetColumn.name),
                    false,
                    false
                );
                if (targetKeyType === undefined || !isPrimitiveType(targetKeyType)) continue;

                keysGetter = hb => {
                    const relation = getter(hb);
                    if (relation === null || isLoadingValue(relation)) return relation;

                    let rows: readonly Row[];
                    if (isRow(relation)) {
                        rows = [relation];
                    } else if (isTable(relation)) {
                        rows = relation.asArray();
                    } else {
                        return null;
                    }

                    let loadingValue: LoadingValue | undefined;
                    const keys = mapFilterUndefined(rows, r => {
                        const rowHB = hb.makeHydrationBackendForRow(r, undefined, targetTables);
                        const target = targetKeyGetter(rowHB);
                        if (target === null) return undefined;
                        if (isLoadingValue(target)) {
                            loadingValue = target;
                            return undefined;
                        }
                        if (!isPrimitiveValue(target)) return undefined;
                        return target;
                    });

                    if (loadingValue !== undefined) return loadingValue;

                    if (hostColumnIsArray) {
                        return keys;
                    } else {
                        return keys[0] ?? "";
                    }
                };
            }

            outputValueGetters.push([linkTarget.hostColumn.name, keysGetter]);
        }
    }
    return outputValueGetters;
}

export function invokeRowComponentHydrator(
    hydrator: WireRowComponentHydrator,
    checkVisibility?: () => boolean
): WireComponentHydrationResult | undefined {
    // If `preHydrate` is implemented, it will always return a result tuple,
    // so the default value here is for the case where it's not implemented.
    const [shouldHydrate, preHydrationFollowUp] = hydrator.preHydrate?.() ?? [true, undefined];

    const noComponentResult = definedMap(preHydrationFollowUp, followUp => ({
        component: undefined,
        isValid: true,
        followUp,
    }));

    if (!shouldHydrate) return noComponentResult;
    if (checkVisibility?.() === false) return noComponentResult;

    let result = hydrator.hydrate();
    if (result === undefined) return noComponentResult;

    if (preHydrationFollowUp === undefined) {
        return result;
    }

    const hydrationFollowUp = result.followUp;
    if (hydrationFollowUp === undefined) {
        result = { ...result, followUp: preHydrationFollowUp };
    } else {
        result = {
            ...result,
            followUp: ab => {
                preHydrationFollowUp(ab);
                hydrationFollowUp(ab);
            },
        };
    }

    return result;
}

const emptyHydrator = makeSimpleWireRowComponentHydratorConstructor(() => undefined);

export function inflateComponent(
    ib: WireInflationBackend,
    desc: ComponentDescription,
    visibilityFromOutputRow: boolean
): WireComponentHydratorWithID {
    const { componentID } = desc;
    // ##hydratedComponentsCorrespondToDescriptions:
    // We always have to make a hydrator, because we depend on the component
    // descriptions and the hydrated components to correspond 1:1, for example
    // for highlighting.
    const emptyResponse: WireComponentHydratorWithID = [emptyHydrator, componentID];

    const handler = handlerForComponentKind(desc.kind);

    // We don't enforce component tiers in Classic Apps
    const tier = handler?.getTier(ib.adc.appKind);
    if (
        ib.adc.appKind !== AppKind.App &&
        tier !== undefined &&
        !hasRequiredPlan(tier, ib.adc.eminenceFlags.pluginTier) &&
        getFeatureSetting("enforceComponentTiers")
    ) {
        const hint: WireHintComponent = {
            kind: WireComponentKind.Hint,
            description: formatLocalizedString(
                "thisComponentIsAvailableOnPlan",
                [startCase(tier[0] ?? "?")],
                ib.adc.appKind
            ),
            mood: Mood.Warning,
            id: componentID,
        };
        return [
            makeSimpleWireRowComponentHydratorConstructor(() => ({
                component: hint,
                isValid: true,
            })),
            componentID,
        ];
    }

    const hydrator = handler?.inflate?.(ib, desc);
    if (hydrator === undefined) return emptyResponse;

    const wantsSearch = hydrator.wantsSearch;

    const filters = (desc.visibilityFilters ?? []).filter(t => t.kind === ArrayTransformKind.Filter);
    if (filters.length === 0) {
        return [hydrator, componentID];
    }

    const [visibilityPredicate] = ib.inflateFilters(filters, visibilityFromOutputRow);

    class Hydrator implements WireRowComponentHydrator {
        public static readonly wantsSearch = wantsSearch;

        private readonly componentHydrator: WireRowComponentHydrator;

        constructor(private readonly hb: WireRowComponentHydrationBackend, builder: BuilderCallbacks | undefined) {
            const hydratorClass = defined(hydrator);
            this.componentHydrator = new hydratorClass(hb, builder);
        }

        public hydrate(): WireComponentHydrationResult | undefined {
            return invokeRowComponentHydrator(this.componentHydrator, () => {
                const isVisible = visibilityPredicate(this.hb);
                if (isLoadingValue(isVisible) || !isVisible) {
                    return false;
                }
                return true;
            });
        }
    }

    return [Hydrator, componentID];
}

// The result `undefined` means that there is no action, or there was an
// error, or there's nothing to do.
export function registerActionRunner(
    hb: WireHydrationBackend,
    // This must be unique for this action in this component.
    key: string,
    result: WireActionHydrationResult
): WireAction | undefined {
    return makeActionForResult(key, hb, canonizeResult(result));
}

// Only use this when the action can be busy
export function registerBusyActionRunner(
    hb: WireHydrationBackend & WireStateSaver,
    // This must be unique for this action in this component.
    key: string,
    makeRunner: () => WireActionHydrationResult
): WireAction | undefined {
    const isBusy = hb.getState(`busy-${key}`, isBoolean, false, false);
    if (isBusy.value) {
        return { token: null, url: undefined };
    }

    const maybeRunner = canonizeResult(makeRunner());
    if (maybeRunner instanceof WireActionResult) return makeActionForResult(key, hb, maybeRunner);

    const [runner, url] = maybeRunner;
    const token = hb.registerAction(key, async (ab, handled) => {
        ab.valueChanged(isBusy.onChangeToken, true, ValueChangeSource.Internal);
        try {
            return await ab.invoke("busy action", runner, handled);
        } finally {
            ab.valueChanged(isBusy.onChangeToken, false, ValueChangeSource.Internal);
        }
    });
    return { token, url };
}

export function spreadComponentID(componentID: string | undefined, forBuilder: boolean): { readonly id?: string } {
    if (!forBuilder || componentID === undefined) {
        return {};
    } else {
        return { id: componentID };
    }
}

export type WireStringGetter = WireValueGetterGeneric<string>;

function inflateProperty<T>(
    ib: WireValueInflationBackend,
    propertyDesc: unknown,
    withFormat: boolean,
    opts: (ValueGetterOptions & { allowArrays?: boolean }) | undefined,
    convert: (v: GroundValue) => T
): InflatedProperty<T> {
    const [getter, type, hasFormat] = ib.getValueGetterForProperty(propertyDesc, withFormat, opts);
    return [
        hb => {
            const v = getter(hb);
            if (v === null) return null;
            if (opts?.allowArrays === true && !isLoadingValue(v) && isArrayValue(v) && v.length > 0) {
                return convert(v[0]);
            }
            return convert(v);
        },
        type,
        hasFormat,
    ];
}

export function inflatePropertyWithLoading<T>(
    ib: WireValueInflationBackend,
    propertyDesc: unknown,
    withFormat: boolean,
    opts: (ValueGetterOptions & { allowArrays?: boolean }) | undefined,
    convert: (v: LoadedGroundValue) => T
): InflatedProperty<T | LoadingValue> {
    return inflateProperty(ib, propertyDesc, withFormat, opts, v => {
        if (isLoadingValue(v)) return v;
        return convert(v);
    });
}

function inflatePropertyWithDefault<T>(
    ib: WireValueInflationBackend,
    propertyDesc: unknown,
    withFormat: boolean,
    opts: (ValueGetterOptions & { allowArrays?: boolean }) | undefined,
    defaultValue: T,
    convert: (v: LoadedGroundValue) => T
): InflatedProperty<T> {
    return inflateProperty(ib, propertyDesc, withFormat, opts, v => {
        if (isLoadingValue(v)) return defaultValue;
        return convert(v);
    });
}

export function inflateStringProperty(
    ib: WireValueInflationBackend,
    propertyDesc: unknown,
    withFormat: boolean,
    opts?: ValueGetterOptions & { allowArrays?: boolean }
): InflatedProperty<string> {
    return inflatePropertyWithDefault(ib, propertyDesc, withFormat, opts, "", asString);
}

// `undefined` means could not be converted to number
export function inflateNumberProperty(
    ib: WireValueInflationBackend,
    propertyDesc: unknown,
    opts?: ValueGetterOptions
): InflatedProperty<number | undefined> {
    return inflatePropertyWithDefault(ib, propertyDesc, false, opts, undefined, asMaybeNumber);
}

// `undefined` means could not be converted to boolean
export function inflateBooleanProperty(
    ib: WireValueInflationBackend,
    propertyDesc: unknown,
    opts?: ValueGetterOptions
): InflatedProperty<boolean | undefined> {
    return inflatePropertyWithDefault(ib, propertyDesc, false, opts, undefined, asMaybeBoolean);
}

export function inflateImageProperty(
    ib: WireValueInflationBackend,
    propertyDesc: unknown,
    opts?: ValueGetterOptions
): InflatedProperty<string> {
    return inflateStringProperty(ib, propertyDesc, false, { allowArrays: true, ...opts });
}

// `undefined` means could not be converted to date/time
export function inflateDateTimeProperty(
    ib: WireValueInflationBackend,
    propertyDesc: unknown,
    opts?: ValueGetterOptions
): InflatedProperty<GlideDateTime | undefined> {
    // TODO: We need to make sure that we rehydrate the component once
    // "chrono" is loaded.
    return inflatePropertyWithDefault(ib, propertyDesc, false, opts, undefined, parseValueAsGlideDateTimeSync);
}

interface InflatedEditableColumn<T extends GroundValue> {
    readonly getter: ((hb: WireRowComponentHydrationBackend) => WireEditableValue<T> | undefined) | undefined;
    readonly type: ColumnType | undefined;
    readonly isInContext: boolean;
}

const errorInflatedEditableColumn = {
    getter: undefined,
    type: undefined,
    isInContext: false,
};

export function inflateEditableColumn<T extends GroundValue>(
    ib: WireValueInflationBackend,
    key: string,
    sourceColumn: SourceColumn,
    convert: (v: LoadedGroundValue) => T,
    primaryKeyDesc?: PropertyDescription
): InflatedEditableColumn<T> {
    const [getter, type] = ib.getValueGetterForSourceColumn(sourceColumn, true, false);
    if (type === undefined) return errorInflatedEditableColumn;

    const { tokenMaker, tableAndColumn, isInContext } = ib.getValueSetterForProperty(
        makeSourceColumnProperty(sourceColumn),
        key
    );
    if (tableAndColumn === undefined) return errorInflatedEditableColumn;

    const primaryKeyColumnName = getColumnProperty(primaryKeyDesc);

    return {
        getter: hb => {
            const value = getter(hb);
            if (value === null || isLoadingValue(value)) return undefined;

            const onChangeToken = tokenMaker(hb, undefined, primaryKeyColumnName);
            if (onChangeToken === false) return undefined;

            return {
                value: convert(value),
                onChangeToken,
            };
        },
        type,
        isInContext,
    };
}

export function inflateEditableProperty<T extends WritableValue>(
    ib: WireValueInflationBackend,
    key: string,
    desc: LegacyPropertyDescription | undefined,
    convert: (v: LoadedGroundValue) => T,
    primaryKeyDesc?: PropertyDescription
): InflatedEditableColumn<T> {
    const sourceColumn = getSourceColumnProperty(desc);
    if (sourceColumn === undefined) return errorInflatedEditableColumn;

    return inflateEditableColumn(ib, key, sourceColumn, convert, primaryKeyDesc);
}

// TODO: Change this, or make an alternate version/overload, where `convert`
// doesn't return `undefined`, and `defaultValue` isn't needed.  Instead, it
// uses `convert(undefined)` as the default value.
export function inflateEditablePropertyWithDefault<T extends NonUndefined<WritableValue>>(
    ib: WireValueInflationBackend,
    key: string,
    desc: LegacyPropertyDescription | undefined,
    convert: (v: LoadedGroundValue) => T | undefined,
    defaultValue: T,
    primaryKeyDesc?: PropertyDescription
): InflatedEditableColumn<T> {
    return inflateEditableProperty(ib, key, desc, v => convert(v) ?? defaultValue, primaryKeyDesc);
}

export function inflateFavorite(
    ib: WireValueInflationBackend,
    primaryKeyDesc: PropertyDescription | undefined
):
    | ((hb: WireRowComponentHydrationBackend) =>
          | {
                editable: WireEditableValue<boolean>;
                onToggle: WireAction | undefined;
                subsidiaryScreen: WireScreen | undefined;
            }
          | undefined)
    | undefined {
    const tableName = getTableName(ib.tables.input);

    const { getter: editableHydrator } =
        inflateEditablePropertyWithDefault(
            ib,
            "isFavorited",
            makeColumnProperty(isFavoritedColumnName),
            asMaybeBoolean,
            false,
            primaryKeyDesc
        ) ?? {};
    if (editableHydrator === undefined) return undefined;

    return hb => {
        const row = hb.rowContext?.inputRows[0];
        if (row === undefined) return undefined;

        const editable = editableHydrator(hb);
        if (editable === undefined) return undefined;

        // We need to be aware of  ##uniqueStateNames here.
        const { action, subsidiaryScreen } = registerActionWithForcedSignIn(
            hb,
            `favorite-${row.$rowID}`,
            false,
            async ab =>
                WireActionResult.fromResult(
                    await ab.setColumnsInRow(
                        tableName,
                        row,
                        {
                            [isFavoritedColumnName]: !editable.value,
                        },
                        true,
                        undefined,
                        undefined
                    )
                )
        );

        return { editable, onToggle: action, subsidiaryScreen };
    };
}

export function inflateBuilderEditableImage(
    ib: WireValueInflationBackend,
    desc: LegacyPropertyDescription | undefined
): (hb: WireRowComponentHydrationBackend) => WireEditableValue<string> | null {
    const [stringGetter] = inflateStringProperty(ib, desc, false, { allowArrays: true });
    const defaultGetter = (hb: WireRowComponentHydrationBackend) => {
        const image = stringGetter(hb);
        if (image === null) return null;
        return { value: image, onChangeToken: undefined };
    };

    if (!ib.forBuilder) return defaultGetter;

    const sourceColumn = getSourceColumnProperty(desc);
    if (sourceColumn === undefined) return defaultGetter;

    const { getter: editableGetter, type } = inflateEditableColumn(ib, "image", sourceColumn, asString);
    if (editableGetter === undefined) return defaultGetter;
    if (!isPrimitiveType(type)) return defaultGetter;

    return hb => {
        const editable = editableGetter(hb);
        if (editable !== undefined) {
            return editable;
        } else {
            return defaultGetter(hb);
        }
    };
}

interface RowGetterOptions {
    readonly inOutputRow: boolean;
    readonly defaultToThisRow: boolean;
}

// `false` means error, `undefined` means it's not a property of the correct
// kind.
export function makeRowGetter(
    ib: WireValueInflationBackend,
    desc: LegacyPropertyDescription | undefined,
    { inOutputRow, defaultToThisRow }: RowGetterOptions
): { table: TableGlideType; rowGetter: WireValueGetter } | false | undefined {
    let sourceColumn = getSourceColumnOrThis(desc);
    if (sourceColumn === undefined && defaultToThisRow) {
        sourceColumn = thisRowSourceColumn;
    }
    if (sourceColumn === undefined) return undefined;

    const [getter, type] = ib.getValueGetterForSourceColumn(sourceColumn, inOutputRow, false);
    if (type === undefined) return false;

    if (!isSingleRelationType(type)) return false;
    const table = ib.adc.findTable(type);
    if (table === undefined) return false;

    return {
        table,
        rowGetter: hb => {
            let v = getter(hb);
            if (!isBound(v) || isLoadingValue(v)) return v;
            if (isQuery(v)) {
                v = hb.resolveQueryAsTable(v);
                if (!isBound(v) || isLoadingValue(v)) return v;
                if (isTable(v)) {
                    // Can be `undefined`, which is fine
                    v = v.asMutatingArray()[0];
                }
            }
            return v;
        },
    };
}

export function getAppArrayScreenEmptyMessage(
    searchActive: boolean,
    appKind: AppKind,
    defaultMessage: LocalizedStringKey = "thereAreNoItemsYet"
): string {
    return getLocalizedString(searchActive ? "noResultsFound" : defaultMessage, appKind);
}

type ActionsWithTitleHydrator = (
    hb: WireRowComponentHydrationBackend,
    itemKey: string
) => readonly WireActionWithTitle[];

// Returns the actions hydrator and the amount of properly configured actions
export function inflateActionsWithTitles(
    ib: WireActionInflationBackend,
    desc: PropertyDescription | undefined,
    key: string
): [ActionsWithTitleHydrator | undefined, number] {
    const array = getArrayProperty<ActionWithTitleDescription>(desc);
    if (array === undefined) return [undefined, 0];

    const hydrators = mapFilterUndefined(array, item => {
        const [titleGetter, titleType] = inflateStringProperty(ib, item.title, true);
        const icon = getIconProperty(item.icon);
        if (titleType === undefined) return undefined;

        const action = getActionProperty(item.action);
        if (action === undefined) return undefined;

        const actionHydrator = inflateActions(ib, [action], true, undefined);
        if (actionHydrator === undefined) return undefined;

        return [titleGetter, actionHydrator, icon] as const;
    });

    return [
        (hb, itemKey) =>
            hydrators.map(([titleGetter, actionHydrator, icon], i) => {
                const title = titleGetter(hb);
                const action = registerBusyActionRunner(hb, `${key}-${itemKey}-${i}`, () =>
                    hydrateAction(actionHydrator, hb, false, undefined)
                );
                return { title, action, icon };
            }),
        hydrators.length,
    ];
}

export function shouldActionShow(action: WireAction): boolean {
    // If we have a token, then the action can be triggered.  If it's busy
    // then we're either waiting for it to load, or it was recently triggered
    // and will probably become triggerable again.
    return typeof action.token === "string" || action.token === WireActionBusy;
}

export function doTitleActionsForceComponentToShow(actions: readonly WireActionWithTitle[]): boolean {
    return actions.some(a => {
        if (a.action === undefined || !isBound(a.action)) return false;
        return shouldActionShow(a.action);
    });
}

export function makeSimpleWireRowComponentHydratorConstructor(
    hydrate: (hb: WireRowComponentHydrationBackend) => WireComponentHydrationResult | undefined
): WireRowComponentHydratorConstructor {
    class Hydrator implements WireRowComponentHydrator {
        public static readonly wantsSearch = false;

        constructor(private readonly hb: WireRowComponentHydrationBackend) {}

        public hydrate(): WireComponentHydrationResult | undefined {
            return hydrate(this.hb);
        }
    }

    return Hydrator;
}

export type SimpleTableComponentHydratorFromQuery = (
    rhb: WireRowComponentHydrationBackend,
    query: Query,
    contentHB: WireTableComponentHydrationBackend | undefined,
    searchActive: boolean,
    pageIndexState: WireAlwaysEditableValue<number>,
    selectedPageSize: number | undefined,
    rowBackends: RowBackends
) => WireTableComponentHydrationResult | undefined;

export function makeSimpleWireTableComponentHydratorConstructor(
    contentIB: WireInflationBackend,
    hydrate: (
        thb: WireTableComponentHydrationBackend,
        rhb: WireRowComponentHydrationBackend | undefined,
        searchActive: boolean,
        dynamicFilterResult: DynamicFilterResult | undefined,
        rowBackends: RowBackends,
        builderCallbacks?: BuilderCallbacks
    ) => WireTableComponentHydrationResult | undefined,
    // NOTE: We're assuming that this hydrator requires aggregates to work,
    // which means that right now we won't use it for BigQuery.
    hydrateFromQuery?: SimpleTableComponentHydratorFromQuery
): WireTableComponentHydratorConstructor {
    class Constructor implements WireTableComponentHydratorConstructor {
        public makeHydrator(
            rhb: WireRowComponentHydrationBackend | undefined,
            rowBackends: RowBackends,
            searchActive: boolean,
            builderCallbacks?: BuilderCallbacks
        ) {
            class Hydrator implements WireTableComponentHydrator {
                public hydrate(
                    thb: WireTableComponentHydrationBackend,
                    dynamicFilterResult: DynamicFilterResult | undefined
                ): WireTableComponentHydrationResult | undefined {
                    return hydrate(thb, rhb, searchActive, dynamicFilterResult, rowBackends, builderCallbacks);
                }
            }
            return new Hydrator();
        }
    }

    class ConstructorFromQuery extends Constructor {
        public makeHydratorForQuery(
            rhb: WireRowComponentHydrationBackend,
            query: Query,
            contentHB: WireTableComponentHydrationBackend | undefined,
            searchActive: boolean,
            pageIndexState: WireAlwaysEditableValue<number>,
            selectedPageSize: number | undefined,
            rowBackends: RowBackends
        ) {
            class Hydrator implements WireTableComponentQueryHydrator {
                public hydrateForQuery(): WireTableComponentHydrationResult | undefined {
                    return defined(hydrateFromQuery)(
                        rhb,
                        query,
                        contentHB,
                        searchActive,
                        pageIndexState,
                        selectedPageSize,
                        rowBackends
                    );
                }
            }
            return new Hydrator();
        }
    }

    const sourceMetadata = getSourceMetadataForTable(contentIB.adc.sourceMetadata, contentIB.tables.input);

    if (
        hydrateFromQuery === undefined ||
        (sourceMetadata?.type === "Native table" && sourceMetadata.externalSource?.type === "bigquery")
    ) {
        return new Constructor();
    } else {
        return new ConstructorFromQuery();
    }
}
interface AsyncComputationState<T, U> {
    readonly input: T;
    readonly result: U | undefined;

    obsolete: boolean;
}

export function hydrateAsyncComputation<T, U>(
    hb: WireStateSaver,
    input: T,
    f: (i: T) => Promise<U>
): { result: U | undefined; followUp: WireHydrationFollowUp | undefined } {
    const linkState = hb.getState<AsyncComputationState<T, U> | undefined>("link", undefined, undefined, false);

    if (linkState.value?.input === input) {
        return { result: linkState.value.result, followUp: undefined };
    } else {
        if (linkState.value !== undefined) {
            linkState.value.obsolete = true;
        }

        const followUp: WireHydrationFollowUp = async ab => {
            const state: AsyncComputationState<T, U> = {
                input,
                result: undefined,
                obsolete: false,
            };
            // FIXME: This causes a superfluous rehydration
            ab.valueChanged(linkState.onChangeToken, state, ValueChangeSource.Internal);

            const result = await f(input);

            if (state.obsolete) return;
            ab.valueChanged(linkState.onChangeToken, { ...state, result }, ValueChangeSource.Internal);
        };

        return { result: undefined, followUp };
    }
}

export function getScreenSearchState(
    ssp: WireScreenStateProvider
): [editableValue: WireEditableValue<string>, needle: string] {
    const ev = ssp.getScreenState("search", isString, "");
    return [ev, ev.value.trim().toLowerCase()];
}

export function getPivotState(ssp: WireScreenStateProvider): WireEditableValue<number> {
    return ssp.getScreenState("pivotIndex", isNumber, 0);
}

// column name -> selected values
type MultipleFiltersState = Record<string, PrimitiveValue[]>;

export interface MultipleDynamicFilterState {
    readonly filterEditable: WireEditableValue<MultipleFiltersState>;
    readonly isOpenEditable: WireEditableValue<boolean>;
}

export function getMultipleDynamicFilterState(wss: WireStateSaver): MultipleDynamicFilterState {
    const validate = (v: unknown): v is MultipleFiltersState => {
        if (typeof v !== "object" || v === null) {
            return false;
        }

        for (const value of Object.values(v)) {
            if (!isArray(value)) {
                return false;
            }

            if (!value.every(isPrimitiveValue)) {
                return false;
            }
        }

        return true;
    };

    const filterEditable = wss.getState("multipleFilters", validate, {}, true);
    const isOpenEditable = wss.getState("isFilterOpen", isBoolean, false, false);

    return { filterEditable, isOpenEditable };
}

export interface DynamicFilterState {
    readonly filterEditable: WireEditableValue<readonly PrimitiveValue[]>;
    readonly isOpenEditable: WireEditableValue<boolean>;
}

export function getDynamicFilterState(wss: WireStateSaver, useComponentState: true): DynamicFilterState;
export function getDynamicFilterState(ssp: WireScreenStateProvider, useComponentState: false): DynamicFilterState;
export function getDynamicFilterState(
    sp: WireStateSaver | WireScreenStateProvider,
    useComponentState: boolean
): DynamicFilterState {
    const validate = (v: unknown): v is PrimitiveValue[] => isArray(v) && v.every(isPrimitiveValue);
    if (useComponentState) {
        const wss = sp as WireStateSaver;
        const filterEditable = wss.getState("filter", validate, [], true);
        const isOpenEditable = wss.getState("filterIsOpen", isBoolean, false, false);
        return { filterEditable, isOpenEditable };
    } else {
        const ssp = sp as WireScreenStateProvider;
        const filterEditable = ssp.getScreenState("filter", validate, []);
        const isOpenEditable = ssp.getScreenState("filterIsOpen", isBoolean, false);
        return { filterEditable, isOpenEditable };
    }
}

export function getShowFilterSortScreenState(ssp: WireScreenStateProvider): WireEditableValue<boolean> {
    return ssp.getScreenState("showFilterSortScreen", isBoolean, false);
}

export function getSortScreenStates(
    ssp: WireScreenStateProvider
): [column: WireEditableValue<string>, ascending: WireEditableValue<boolean>] {
    return [ssp.getScreenState("sortColumn", isString, ""), ssp.getScreenState("sortAscending", isBoolean, true)];
}

export function makeSearchableColumnsForList(
    ib: WireInflationBackend,
    supportsSearch: boolean,
    forQuery: boolean,
    componentID: string | undefined
): ReadonlySet<string> {
    if (!supportsSearch) return new Set();

    const inputTable = ib.tables.input;
    const tableName = getTableName(inputTable);
    const { columnsUsedByTable, columnsUsedByInlineLists } = ib.searchableColumns;
    const fromTable = columnsUsedByTable.get(tableName) ?? new Set();
    // ##slowColumnsInSearch:
    // We don't want to search slow columns, unless they are displayed in the
    // list.
    const fromInlineList = definedMap(componentID, id => columnsUsedByInlineLists.get(id)) ?? new Set();
    let searchableColumns = setUnion(fromTable, fromInlineList);
    if (forQuery) {
        searchableColumns = new Set(
            Array.from(searchableColumns).filter(cn => {
                const c = getTableColumn(inputTable, cn);
                if (c === undefined) return undefined;
                // We support user-specific search in queries only for Big
                // Tables.
                if (isDataSourceColumn(c, doesTableAllowAddingUserSpecificColumns(inputTable))) return true;

                return isColumnAllowedForSearching(
                    ib.adc,
                    inputTable,
                    c,
                    undefined, // We don't need the computation model for queryables
                    isExperimentEnabled("gbtComputedColumnsAlpha", ib.adc.userFeatures),
                    isExperimentEnabled("gbtDeepLookups", ib.adc.userFeatures)
                );
            })
        );
    }
    return searchableColumns;
}

export function makeSearchPropertyGetters(
    ib: WireValueInflationBackend,
    searchableColumns: Iterable<string>
): readonly InflatedColumn[] | undefined {
    const searchPropertyGetters = mapFilterUndefined(Array.from(searchableColumns), p => {
        const result = ib.getValueGetterForColumnInRow(p, false, true);
        if (result === undefined) return undefined;
        const { type, isGlobal } = result;
        // `isGlobal` means that the column has the same value in
        // every row, in which case it makes no sense to include it in
        // the search.
        if (isGlobal || !isPrimitiveType(type)) return undefined;
        return result;
    });
    if (searchPropertyGetters.length === 0) return undefined;
    return searchPropertyGetters;
}

export function searchRows(
    needle: string,
    rows: Table,
    searchPropertyGetters: readonly InflatedColumn[],
    ttvp: WireTableTransformValueProvider,
    timeout: number | undefined
): Table {
    needle = needle.trim().toLowerCase();

    for (const { subscribe } of defined(searchPropertyGetters)) {
        subscribe(ttvp);
    }

    const newRows: Row[] = [];
    const didTimeOut = withTimeoutSync(isTimedOut => {
        for (const r of rows.values()) {
            for (const { getter } of defined(searchPropertyGetters)) {
                const v = asMaybeString(nullLoadingToUndefined(getter(r, ttvp)));
                if (v === undefined) continue;
                if (v.toLowerCase().includes(needle)) {
                    newRows.push(r);
                    break;
                }
            }
            if (isTimedOut()) return true;
        }
        return false;
    }, timeout);
    if (didTimeOut) {
        logError("Search timed out");
    }

    return new Table(newRows);
}

export function applySearchToQuery(
    hb: WireStateSaver,
    searchableColumns: ReadonlySet<string>,
    query: QueryBase | Table,
    maybeDebounceMs: number | undefined,
    preferredColumn?: string
): {
    readonly query: QueryBase | undefined;
    readonly searchActive: boolean;
    readonly searchEditable: WireAlwaysEditableValue<string> | undefined;
} {
    let searchActive = false;
    let searchEditable: WireAlwaysEditableValue<string> | undefined;
    if (searchableColumns.size > 0) {
        searchEditable = hb.getState("search", isString, "", true, maybeDebounceMs);
        const searchNeedle = searchEditable.value.toLowerCase().trim();

        if (!isEmptyOrUndefined(searchNeedle)) {
            searchActive = true;
            if (isQuery(query)) {
                const columnNames =
                    isDefined(preferredColumn) && searchableColumns.has(preferredColumn)
                        ? [preferredColumn]
                        : Array.from(searchableColumns);
                query = query.withSearch({
                    needle: searchNeedle,
                    columnNames,
                });
            }
        }
    }

    return {
        query: isQuery(query) ? query : undefined,
        searchActive,
        searchEditable,
    };
}

// NOTE: This will mutate `items`
export function sortItems<T>(items: T[], keyGetter: (item: T) => GroundValue): T[] {
    return items.sort((oneItem, twoItem) => {
        let one = keyGetter(oneItem);
        let two = keyGetter(twoItem);
        if (typeof one === "string") {
            one = one.trim().toLowerCase();
        }
        if (typeof two === "string") {
            two = two.trim().toLowerCase();
        }
        if (!isLoadingValue(one) && !isLoadingValue(two)) {
            const result = compareValues(one, two);
            if (result !== undefined) {
                return result;
            }
        }
        if (one === undefined) {
            return -1;
        } else {
            return 1;
        }
    });
}

export function sortRows(
    keyGetter: InflatedColumn,
    reverse: boolean,
    rows: Table,
    ttvp: WireTableTransformValueProvider
): Table {
    keyGetter.subscribe(ttvp);

    const rowsWithKeys = Array.from(rows.values()).map(r => [keyGetter.getter(r, ttvp), r] as const);

    // ##sorting:
    // These two are copies and must be kept in sync.
    let sorted = sortItems(rowsWithKeys, ([v]) => v).map(([, r]) => r);

    if (reverse) {
        sorted = sorted.reverse();
    }

    return new Table(sorted);
}

export function getFormatForInputColumn(
    ib: WireValueInflationBackend,
    columnName: string | undefined
): ValueFormatSpecification | undefined {
    const column = maybeGetTableColumn(ib.tables.input, columnName);
    if (column === undefined) return undefined;

    const formula = getEffectiveDisplayFormulaForColumn(ib.adc, ib.tables.input, column);
    return (
        definedMap(formula, decomposeFormatFormula) ??
        definedMap(column.type.kind, k => getDefaultFormatForTypeKind(k, "local"))
    );
}

interface InflatedMultipleDynamicFiltersColumnsInfo {
    readonly filterValueAndFormatSubscribe: (ttvp: WireTableTransformValueProvider) => void;
    readonly filterValueAndFormatGetter: WireFilterValueAndFormatGetter;
    readonly captionGetter: WireValueGetterGeneric<string>;
    readonly filterColumn: TableColumn;
    readonly valueFormat: ValueFormatSpecification | undefined;
    readonly columnType: ColumnType;
}

interface InflatedMultipleDynamicFilters {
    readonly columnsInfo: readonly InflatedMultipleDynamicFiltersColumnsInfo[];
    readonly isQueryable: boolean;
    readonly supportsUniqueArrayElements: boolean;
}

interface MultipleDynamicFilterColumn {
    readonly column: PropertyDescription;
    readonly caption: PropertyDescription;
}

export function inflateMultipleDynamicFilters(
    ib: WireValueInflationBackend,
    desc: InlineListComponentDescription,
    supportsDynamicFilter: DynamicFilterSupport | undefined,
    // Some collections, like New Table, only uses queries despite of have a queryable data source bound to it.
    onlyUseQueries: boolean
): InflatedMultipleDynamicFilters | undefined {
    if (supportsDynamicFilter === undefined) {
        return undefined;
    }

    const columnsInfo: InflatedMultipleDynamicFiltersColumnsInfo[] = [];

    const columns = getArrayProperty<MultipleDynamicFilterColumn>(desc.multipleDynamicFilters);

    if (isEmptyOrUndefined(columns)) {
        return undefined;
    }

    for (const column of columns) {
        const columnName = getColumnProperty(column.column);
        const [captionGetter] = inflateStringProperty(ib, column.caption, true);
        if (columnName === undefined) {
            continue;
        }

        const filterColumn = getTableColumn(ib.tables.input, columnName);
        if (filterColumn === undefined) {
            continue;
        }

        const valueFormat = getFormatForInputColumn(ib, columnName);

        const inflatedGetter = ib.getValueGetterForColumnInRow(columnName, false, false);
        if (inflatedGetter === undefined) {
            continue;
        }
        const { subscribe, getter, type, hasFormat } = inflatedGetter;

        let filterValueAndFormatSubscribe: ((ttvp: WireTableTransformValueProvider) => void) | undefined;
        let filterValueAndFormatGetter: WireFilterValueAndFormatGetter | undefined;

        if (isPrimitiveType(type)) {
            const formatter = hasFormat ? defined(ib.getValueGetterForColumnInRow(columnName, false, true)) : undefined;
            filterValueAndFormatSubscribe = hb => {
                subscribe(hb);
                formatter?.subscribe(hb);
            };
            filterValueAndFormatGetter = (r, hb) => {
                const v = getter(r, hb);
                if (isLoadingValue(v) || isUndefinedish(v) || !isPrimitiveValue(v)) return [];
                const f = formatter !== undefined ? formatter.getter(r, hb) : v;
                if (isLoadingValue(f) || !isBound(f)) return [];
                const s = asString(f);
                if (s === "") return [];
                return [[v, s]];
            };
        } else if (type !== undefined && isPrimitiveArrayType(type)) {
            filterValueAndFormatSubscribe = subscribe;
            filterValueAndFormatGetter = (r, hb) => {
                const arr = getter(r, hb);
                if (isLoadingValue(arr) || !isBound(arr) || !isArrayValue(arr)) return [];
                return mapFilterUndefined(arr, v => {
                    if (v === undefined || isArrayValue(v)) return undefined;
                    const s = asString(v);
                    if (s === "") return undefined;
                    return [v, s];
                });
            };
        } else {
            continue;
        }

        columnsInfo.push({
            filterValueAndFormatSubscribe,
            filterValueAndFormatGetter,
            captionGetter,
            filterColumn,
            valueFormat,
            columnType: type,
        });
    }

    const { queryable } = getSourceMetadataFlags(ib.tables.input.sourceMetadata);
    const isQueryable =
        (supportsDynamicFilter.viaQueries && queryable?.supportsAggregations === true) || onlyUseQueries;

    // Query-only components in regular tables also support arrays!
    const supportsUniqueArrayElements =
        queryable?.supportsUniqueArrayElements === true || (queryable === undefined && onlyUseQueries);

    return {
        columnsInfo,
        isQueryable,
        supportsUniqueArrayElements,
    };
}

export interface InflatedDynamicFilter {
    readonly filterValueAndFormatSubscribe: (ttvp: WireTableTransformValueProvider) => void;
    readonly filterValueAndFormatGetter: WireFilterValueAndFormatGetter;
    readonly filterColumn: TableColumn;
    readonly valueFormat: ValueFormatSpecification | undefined;
    readonly supportsQueryables: boolean;
    readonly supportsUniqueArrayElements: boolean;
}

export function inflateDynamicFilter(
    ib: WireValueInflationBackend,
    desc: Description,
    supportsDynamicFilter: DynamicFilterSupport | undefined
): InflatedDynamicFilter | undefined {
    let filterValueAndFormatSubscribe: ((ttvp: WireTableTransformValueProvider) => void) | undefined;
    let filterValueAndFormatGetter: WireFilterValueAndFormatGetter | undefined;

    if (supportsDynamicFilter === undefined) return undefined;

    const dynamicFilterColumnName = dynamicFilterColumnPropertyHandler.getColumnName(desc);
    if (dynamicFilterColumnName === undefined) return undefined;
    const dynamicFilterColumn = getTableColumn(ib.tables.input, dynamicFilterColumnName);
    if (dynamicFilterColumn === undefined) return undefined;

    const valueFormat = getFormatForInputColumn(ib, dynamicFilterColumnName);

    const inflatedGetter = ib.getValueGetterForColumnInRow(dynamicFilterColumnName, false, false);
    if (inflatedGetter === undefined) return undefined;
    const { subscribe, getter, type, hasFormat } = inflatedGetter;

    if (isPrimitiveType(type)) {
        const formatter = hasFormat
            ? defined(ib.getValueGetterForColumnInRow(dynamicFilterColumnName, false, true))
            : undefined;
        filterValueAndFormatSubscribe = hb => {
            subscribe(hb);
            formatter?.subscribe(hb);
        };
        filterValueAndFormatGetter = (r, hb) => {
            const v = getter(r, hb);
            if (isLoadingValue(v) || isUndefinedish(v) || !isPrimitiveValue(v)) return [];
            const f = formatter !== undefined ? formatter.getter(r, hb) : v;
            if (isLoadingValue(f) || f === null) return [];
            const s = asString(f);
            if (s === "") return [];
            return [[v, s]];
        };
    } else if (type !== undefined && isPrimitiveArrayType(type)) {
        filterValueAndFormatSubscribe = subscribe;
        filterValueAndFormatGetter = (r, hb) => {
            const arr = getter(r, hb);
            if (isLoadingValue(arr) || arr === null || !isArrayValue(arr)) return [];
            return mapFilterUndefined(arr, v => {
                if (v === undefined || isArrayValue(v)) return undefined;
                const s = asString(v);
                if (s === "") return undefined;
                return [v, s];
            });
        };
    } else {
        return undefined;
    }

    // BigQuery doesn't support aggregates at the moment, so we exclude it
    // here.
    const { queryable } = getSourceMetadataFlags(ib.tables.input.sourceMetadata);
    const supportsQueryables = supportsDynamicFilter.viaQueries && queryable?.supportsAggregations === true;

    return {
        filterValueAndFormatSubscribe,
        filterValueAndFormatGetter,
        filterColumn: dynamicFilterColumn,
        valueFormat,
        supportsQueryables,
        supportsUniqueArrayElements: queryable?.supportsUniqueArrayElements === true,
    };
}

// Booleans can be "true", "yes", "1"... We'll use the same logic we use across Glide to compare them
function areBooleanPrimitiveValuesEqual(v1: PrimitiveValue, v2: PrimitiveValue): boolean {
    const booleanV1 = asBoolean(v1);
    const booleanV2 = asBoolean(v2);

    return booleanV1 === booleanV2;
}

export function arePrimitiveValuesStrictlyEqual(v1: PrimitiveValue, v2: PrimitiveValue): boolean {
    if (v1 === v2) {
        return true;
    }
    if (v1 instanceof GlideDateTime && v2 instanceof GlideDateTime) {
        return v1.getTimeZoneAwareValue() === v2.getTimeZoneAwareValue();
    }
    if (v1 instanceof GlideJSON && v2 instanceof GlideJSON) {
        // FIXME: It's not correct to just look at the strinified JSON when
        // ##comparingJSON.
        return v1.jsonString === v2.jsonString;
    }
    return false;
}

export class DefinedPrimitiveValueMap<T> {
    private readonly glideDateTimeInterning = new Map<number, GlideDateTime>();
    private readonly glideJSONInterning = new Map<string, GlideJSON>();
    private readonly map = new Map<DefinedPrimitiveValue, T>();

    private normalizeKey(v: DefinedPrimitiveValue): DefinedPrimitiveValue {
        if (v instanceof GlideDateTime) {
            const n = v.getTimeZoneAwareValue();
            const interned = this.glideDateTimeInterning.get(n);
            if (interned !== undefined) {
                return interned;
            }
            this.glideDateTimeInterning.set(n, v);
            return v;
        } else if (v instanceof GlideJSON) {
            const n = v.jsonString;
            const interned = this.glideJSONInterning.get(n);
            if (interned !== undefined) {
                return interned;
            }
            this.glideJSONInterning.set(n, v);
            return v;
        } else {
            return v;
        }
    }

    public get size(): number {
        return this.map.size;
    }

    public has(k: DefinedPrimitiveValue): boolean {
        return this.map.has(this.normalizeKey(k));
    }

    public get(k: DefinedPrimitiveValue): T | undefined {
        return this.map.get(this.normalizeKey(k));
    }

    public set(k: DefinedPrimitiveValue, v: T): void {
        this.map.set(this.normalizeKey(k), v);
    }

    public entries(): IterableIterator<[DefinedPrimitiveValue, T]> {
        return this.map.entries();
    }

    public keys(): IterableIterator<DefinedPrimitiveValue> {
        return this.map.keys();
    }

    public values(): IterableIterator<T> {
        return this.map.values();
    }

    // eslint-disable-next-line require-yield
    public [Symbol.iterator](): IterableIterator<[DefinedPrimitiveValue, T]> {
        return this.map.entries();
    }
}

// The previous version was showing up as a memory leak if we put the function inline,
//  so keep it here just in case.
function makeFilterActionRunner(
    value: PrimitiveValue | undefined,
    filterEditable: WireEditableValue<readonly PrimitiveValue[]>,
    allowMultipleFilterValues: boolean
): WireActionRunner {
    return async ab => {
        if (filterEditable.onChangeToken === undefined) {
            return WireActionResult.nondescriptError(true, "Filter cannot be changed");
        }
        let newFilter: readonly PrimitiveValue[];
        if (value === undefined) {
            newFilter = [];
        } else if (allowMultipleFilterValues) {
            newFilter = filterEditable.value.includes(value)
                ? filterEditable.value.filter(x => x !== value)
                : [...filterEditable.value, value];
        } else {
            newFilter = [value];
        }
        return ab.valueChanged(filterEditable.onChangeToken, newFilter, ValueChangeSource.Internal);
    };
}

export interface FilterEntry {
    readonly value: PrimitiveValue;
    readonly count: number;
}

function queryFilterEntries(
    hb: WireRowHydrationValueProvider,
    filterColumn: TableColumn,
    supportsUniqueArrayElements: boolean,
    query: QueryBase
): readonly FilterEntry[] | LoadingValue | undefined {
    // To present the filter to the user we need to get all unique values in
    // the `filterColumn` across the `query`.  We prefer using
    // `unique-array-elements` for this because it works on primitive values
    // as well as on arrays.  Only GBT supports it (yet), though, so on other
    // queryable data sources (i.e. SQL) we don't support arrays, and we
    // `groupBy` the `filterColumn` to get its unique values.
    if (supportsUniqueArrayElements) {
        const dynamicFilterQuery = query.withGroupBy({
            columns: [],
            aggregates: [
                {
                    kind: "unique-array-elements",
                    column: filterColumn.name,
                    name: "unique",
                },
            ],
            sort: [],
            limit: 1000,
        });
        const maybeTable = hb.resolveQueryAsTable(dynamicFilterQuery);
        if (isLoadingValue(maybeTable) || maybeTable === undefined) return maybeTable;

        if (maybeTable.size !== 1) {
            if (maybeTable.errorMessage !== undefined) {
                logError("Error querying filter entries", maybeTable.errorMessage);
                return undefined;
            } else {
                return panic("Expected exactly one row from filter entries query");
            }
        }
        const [row] = maybeTable.asMutatingArray();
        const arr = getRowColumn(row, "unique");
        assert(isArray(arr));
        return mapFilterUndefined(arr, e => {
            if (!isPrimitiveValue(e)) return undefined;
            // We don't have counts here
            return { value: e, count: 1 };
        });
    } else if (isPrimitiveArrayType(filterColumn.type)) {
        // Non-GBT queryable data sources don't (yet?) support dynamic filters
        // with arrays.
        return undefined;
    } else {
        const dynamicFilterQuery = query.withGroupBy({
            columns: [filterColumn.name],
            aggregates: [],
            sort: [],
            limit: 1000,
        });
        const maybeTable = hb.resolveQueryAsTable(dynamicFilterQuery);
        if (isLoadingValue(maybeTable) || maybeTable === undefined) return maybeTable;

        return maybeTable.asMutatingArray().map(r => {
            const decomposed = decomposeAggregateRow(r);
            let value = decomposed.group;
            // Date/times are sent as strings, so we don't do any special
            // handling here.
            if (!isPrimitiveValue(value) || value === "") {
                value = undefined;
            }
            return { value, count: decomposed.count };
        });
    }
}

export function queryDynamicFilter(
    hb: WireRowComponentHydrationBackend,
    inflatedDynamicFilter: InflatedDynamicFilter,
    query: QueryBase | Table
): {
    readonly filterState: DynamicFilterState;
    // The query with the dynamic filter applied, if applicable
    readonly query: QueryBase | undefined;
    readonly filterEntries: readonly FilterEntry[] | LoadingValue | undefined;
} {
    const filterState = getDynamicFilterState(hb, true);

    if (!inflatedDynamicFilter.supportsQueryables || !isQuery(query)) {
        return { query: undefined, filterState, filterEntries: undefined };
    }

    // This gets the values available for the filter
    let filterEntries: readonly FilterEntry[] | LoadingValue | undefined;
    if (filterState.isOpenEditable.value) {
        filterEntries = queryFilterEntries(
            hb,
            inflatedDynamicFilter.filterColumn,
            inflatedDynamicFilter.supportsUniqueArrayElements,
            query
        );
    }

    // This modifies the `query` to include the filter.  It has to happen
    // after querying for the filter entries because that uses the `query`
    // without these conditions.
    if (filterState.filterEditable.value.length > 0) {
        const keys = mapFilterUndefined(filterState.filterEditable.value, v => {
            const k = convertToArrayOverlapKey(v);
            if (isLoadingValue(k)) return undefined;
            return k;
        });
        const condition = generateArrayOverlapQueryCondition(inflatedDynamicFilter.filterColumn.name, keys);
        if (condition !== undefined) {
            query = query.withCondition({ ...condition, negated: false });
        }
    }

    return { filterEntries, filterState, query };
}

export interface QueryableMultipleFilterEntriesWithCaption {
    // These are the rows that come back from the aggregate query
    readonly filterEntries: readonly FilterEntry[] | LoadingValue | undefined;
    readonly caption: string;
}

type MultipleFilterEntriesByColumnName = Record<string, QueryableMultipleFilterEntriesWithCaption>;

/**
 * This applies the multiple dynamic filter to the query, resulting in a query
 * with all the filters applied.
 *
 * filterEntries for multiple filters look different from the ones in regular
 * dynamic filter.
 *
 * The ones in dynamicFilter are the options for the single column you're
 * filtering by Here we need a map of columns to possible values.
 */
export function queryMultipleDynamicFilters(
    hb: WireRowComponentHydrationBackend,
    inflatedMultipleDynamicFilters: InflatedMultipleDynamicFilters,
    query: QueryBase | Table
): {
    readonly multipleFiltersState: MultipleDynamicFilterState;
    // The query with the dynamic filter applied
    readonly query: QueryBase | undefined;
    readonly multipleFilterEntriesByColumnName: MultipleFilterEntriesByColumnName | undefined;
} {
    const multipleFiltersState = getMultipleDynamicFilterState(hb);

    if (!inflatedMultipleDynamicFilters.isQueryable || !isQuery(query)) {
        return { query: undefined, multipleFiltersState, multipleFilterEntriesByColumnName: undefined };
    }

    const columnsToFilterBy = inflatedMultipleDynamicFilters.columnsInfo;

    let multipleFilterEntriesByColumnName: MultipleFilterEntriesByColumnName | undefined;
    if (multipleFiltersState.isOpenEditable.value) {
        multipleFilterEntriesByColumnName = {};

        for (const columnInfo of columnsToFilterBy) {
            const { filterColumn, captionGetter } = columnInfo;

            const filterEntries = queryFilterEntries(
                hb,
                filterColumn,
                inflatedMultipleDynamicFilters.supportsUniqueArrayElements,
                query
            );
            multipleFilterEntriesByColumnName[filterColumn.name] = {
                caption: captionGetter(hb) ?? "",
                filterEntries,
            };
        }
    }

    for (const columnInfo of columnsToFilterBy) {
        const { filterColumn } = columnInfo;

        const filterStateForColumn = multipleFiltersState.filterEditable.value[filterColumn.name];
        if (filterStateForColumn === undefined || filterStateForColumn.length === 0) {
            continue;
        }

        const keys = mapFilterUndefined(filterStateForColumn, v => {
            const k = convertToArrayOverlapKey(v);
            if (isLoadingValue(k)) return undefined;
            return k;
        });
        const condition = generateArrayOverlapQueryCondition(filterColumn.name, keys);

        if (condition !== undefined) {
            query = query.withCondition({ ...condition, negated: false });
        }
    }

    return {
        multipleFiltersState,
        multipleFilterEntriesByColumnName,
        query,
    };
}

interface DynamicFilterEntryExceptAll extends DynamicFilterEntry {
    readonly displayValue: string;
    // mutable because we modify it during counting for performance
    count: number;
}

function makeBooleanMultipleFilterActionRunner(
    column: string,
    filterEditable: WireEditableValue<MultipleFiltersState>
): WireActionRunner {
    return async ab => {
        if (filterEditable.onChangeToken === undefined) {
            return WireActionResult.nondescriptError(true, "Filter cannot be changed");
        }

        const newValue = produce(filterEditable.value, prev => {
            if (prev[column] === undefined) {
                prev[column] = [true];
            } else {
                delete prev[column];
            }
        });

        return ab.valueChanged(filterEditable.onChangeToken, newValue, ValueChangeSource.Internal);
    };
}

function makeStringMultipleFilterActionRunner(
    column: string,
    toggledValue: PrimitiveValue | undefined,
    filterEditable: WireEditableValue<MultipleFiltersState>
): WireActionRunner {
    return async ab => {
        if (filterEditable.onChangeToken === undefined) {
            return WireActionResult.nondescriptError(true, "Filter cannot be changed");
        }

        const newValue = produce(filterEditable.value, prev => {
            if (prev[column] === undefined) {
                prev[column] = [];
            }

            const prevValues = defined(prev[column]);
            const prevValueIndex = prevValues.indexOf(toggledValue);

            if (prevValueIndex === -1) {
                prevValues.push(toggledValue);
            } else {
                prevValues.splice(prevValueIndex, 1);
                if (prevValues.length === 0) {
                    delete prev[column];
                }
            }
        });

        return ab.valueChanged(filterEditable.onChangeToken, newValue, ValueChangeSource.Internal);
    };
}

function makeStringFilterEntry(
    hb: WireRowComponentHydrationBackend,
    filterEditable: WireEditableValue<MultipleFiltersState>,
    displayValue: string,
    columnName: string,
    value: PrimitiveValue,
    actionIndex: number
): StringMultipleDynamicFilterEntry {
    const selectedValuesByColumnName = filterEditable.value;
    const selectedValuesForColumn = selectedValuesByColumnName[columnName];

    const selected = selectedValuesForColumn?.some(f => arePrimitiveValuesStrictlyEqual(f, value)) ?? false;

    return {
        kind: "string",
        displayValue,
        selected,
        onToggle: {
            token: hb.registerAction(
                `multiple-filter-${actionIndex}`,
                makeStringMultipleFilterActionRunner(columnName, value, filterEditable)
            ),
        },
    };
}

function makeBooleanFilterEntry(
    hb: WireRowComponentHydrationBackend,
    filterEditable: WireEditableValue<MultipleFiltersState>,
    columnName: string,
    actionIndex: number
): BooleanMultipleDynamicFilterEntry {
    const selectedValuesByColumnName = filterEditable.value;
    const selectedValuesForColumn = selectedValuesByColumnName[columnName];

    const selected = selectedValuesForColumn !== undefined && selectedValuesForColumn[0] === true;

    return {
        kind: "boolean",
        selected,
        onToggle: {
            token: hb.registerAction(
                `multiple-filter-${actionIndex}`,
                makeBooleanMultipleFilterActionRunner(columnName, filterEditable)
            ),
        },
    };
}

export interface MultipleDynamicFiltersResult {
    readonly table: Table;
    readonly multipleDynamicFilters: WireMultipleDynamicFilters;
}

interface StringFilterValuesWithCaption {
    kind: "string";
    filterEntries: DefinedPrimitiveValueMap<StringMultipleDynamicFilterEntry>;
    caption: string;
}

interface BooleanFilterValuesWithCaption {
    kind: "boolean";
    filterEntry: BooleanMultipleDynamicFilterEntry;
    caption: string;
}

type FilterValuesWithCaption = StringFilterValuesWithCaption | BooleanFilterValuesWithCaption;

export function applyMultipleDynamicFilters(
    hb: WireRowComponentHydrationBackend,
    ttvp: WireTableTransformValueProvider,
    inflatedMultipleDynamicFilters: InflatedMultipleDynamicFilters,
    rows: Table | undefined,
    filterState: MultipleDynamicFilterState,
    // Some collections need this, in particular Kanban.
    needsFilterValues: boolean,
    queryableMultipleFilterEntriesByColumnName: MultipleFilterEntriesByColumnName | undefined
): MultipleDynamicFiltersResult {
    const { filterEditable, isOpenEditable } = filterState;

    const selectedValuesByColumnName = filterEditable.value;
    const columnsToFilterBy = inflatedMultipleDynamicFilters.columnsInfo;
    const isQueryable = inflatedMultipleDynamicFilters.isQueryable;

    let hasSomeFilterValue = false;
    for (const filterValues of Object.values(selectedValuesByColumnName)) {
        if (filterValues.length > 0) {
            hasSomeFilterValue = true;
            break;
        }
    }

    // The reason we also don't take this path when there are filter
    // values is that in that case we need to filter the rows anyway, so
    // we might as well also collect all the values.
    const doesNotNeedFiltering = !needsFilterValues && !isOpenEditable.value && !hasSomeFilterValue;
    // If we have a queryable and don't have entries loaded yet, we just need to send actions to open/close.
    // we can't build any entries until this is loaded.
    const isLoadingQueryables = isQueryable && queryableMultipleFilterEntriesByColumnName === undefined;
    if (doesNotNeedFiltering || isLoadingQueryables) {
        return makeMultipleFiltersResult(hb, filterState, [], rows, true);
    }

    if (queryableMultipleFilterEntriesByColumnName === undefined && rows !== undefined) {
        const { filteredRows, entries } = applyMultipleDynamicFiltersToTable(
            hb,
            columnsToFilterBy,
            ttvp,
            rows,
            selectedValuesByColumnName,
            filterEditable
        );

        return makeMultipleFiltersResult(hb, filterState, entries, filteredRows, false);
    }

    if (queryableMultipleFilterEntriesByColumnName !== undefined) {
        const { entries, isLoading } = applyMultipleDynamicFiltersToQuery(
            hb,
            columnsToFilterBy,
            queryableMultipleFilterEntriesByColumnName,
            filterEditable
        );

        return makeMultipleFiltersResult(hb, filterState, entries, rows, isLoading);
    }

    return makeMultipleFiltersResult(hb, filterState, [], rows, false);
}

function makeMultipleFiltersResult(
    hb: WireRowComponentHydrationBackend,
    filterState: MultipleDynamicFilterState,
    entries: DynamicFilterEntriesWithCaption[],
    filteredRows: Table | undefined,
    isLoading: boolean
): MultipleDynamicFiltersResult {
    const { filterEditable, isOpenEditable } = filterState;

    const clearActionToken = definedMap(filterEditable.onChangeToken, t =>
        hb.registerAction("clear-all-filters", async ab => {
            const emptyFilters: MultipleFiltersState = {};
            return ab.valueChanged(t, emptyFilters, ValueChangeSource.Internal);
        })
    );

    // Compute the number of selections here because for query-able
    // sources we only fetch entries when the filter menu is open and
    // the selected state is no longer available to the UI.
    // https://github.com/glideapps/glide/issues/29003
    let numFiltersSelected = 0;
    for (const filterableColumnValuesArray of Object.values(filterEditable.value)) {
        numFiltersSelected += filterableColumnValuesArray.length;
    }

    const multipleDynamicFilters: WireMultipleDynamicFilters = {
        isLoading,
        entries,
        isOpen: isOpenEditable,
        clearAction: definedMap(clearActionToken, t => ({ token: t })),
        numFiltersSelected,
    };

    return {
        table: filteredRows ?? new Table(),
        multipleDynamicFilters,
    };
}

function getSortedFilterEntries(
    filterValuesByFilteringColumn: Record<string, FilterValuesWithCaption>
): DynamicFilterEntriesWithCaption[] {
    const entries: DynamicFilterEntriesWithCaption[] = [];

    for (const filterValuesWithCaption of Object.values(filterValuesByFilteringColumn)) {
        if (filterValuesWithCaption.kind === "boolean") {
            entries.push(filterValuesWithCaption);
        } else {
            entries.push({
                kind: "string",
                filterEntries: [...filterValuesWithCaption.filterEntries.values()].sort((a, b) =>
                    compareStrings(a.displayValue, b.displayValue)
                ),
                caption: filterValuesWithCaption.caption,
            });
        }
    }

    return entries;
}

interface MultipleFiltersForQueryResult {
    entries: DynamicFilterEntriesWithCaption[];
    isLoading: boolean;
}

function applyMultipleDynamicFiltersToQuery(
    hb: WireRowComponentHydrationBackend,
    columnsToFilterBy: readonly InflatedMultipleDynamicFiltersColumnsInfo[],
    multipleFilterEntriesByColumnName: MultipleFilterEntriesByColumnName,
    filterEditable: WireEditableValue<MultipleFiltersState>
): MultipleFiltersForQueryResult {
    const filterValuesByFilteringColumn: Record<string, FilterValuesWithCaption> = {};

    let actionIndex = 0;
    let isLoading = false;
    for (const columnInfo of columnsToFilterBy) {
        const { filterColumn, captionGetter, valueFormat, columnType } = columnInfo;
        const columnName = filterColumn.name;

        const queryableFilterEntriesForColumn = defined(multipleFilterEntriesByColumnName[columnName]);

        // If any filter entry is loading, the whole thing will be considered loading.
        const { filterEntries } = queryableFilterEntriesForColumn;
        if (isLoadingValue(filterEntries)) {
            isLoading = true;
            break;
        }

        if (filterEntries === undefined) {
            continue;
        }

        if (columnType.kind === "boolean") {
            filterValuesByFilteringColumn[filterColumn.name] = {
                kind: "boolean",
                caption: captionGetter(hb) ?? "",
                filterEntry: makeBooleanFilterEntry(hb, filterEditable, columnName, actionIndex),
            };
            actionIndex++;

            continue;
        }

        const filteredValues: StringFilterValuesWithCaption = {
            kind: "string",
            caption: captionGetter(hb) ?? "",
            filterEntries: new DefinedPrimitiveValueMap(),
        };

        for (const { value: primitiveValue } of filterEntries) {
            if (primitiveValue === undefined) continue;

            const displayValue = formatValueWithSpecification(valueFormat, primitiveValue) ?? asString(primitiveValue);

            if (!filteredValues.filterEntries.has(primitiveValue)) {
                filteredValues.filterEntries.set(
                    primitiveValue,
                    makeStringFilterEntry(hb, filterEditable, displayValue, columnName, primitiveValue, actionIndex)
                );
                actionIndex++;
            }
        }

        filterValuesByFilteringColumn[columnName] = filteredValues;
    }

    return {
        entries: getSortedFilterEntries(filterValuesByFilteringColumn),
        isLoading,
    };
}

interface MultipleFiltersForTableResult {
    filteredRows: Table;
    entries: DynamicFilterEntriesWithCaption[];
}

function applyMultipleDynamicFiltersToTable(
    hb: WireRowComponentHydrationBackend,
    columnsToFilterBy: readonly InflatedMultipleDynamicFiltersColumnsInfo[],
    ttvp: WireTableTransformValueProvider,
    rows: Table,
    selectedValuesByColumnName: MultipleFiltersState,
    filterEditable: WireEditableValue<MultipleFiltersState>
): MultipleFiltersForTableResult {
    const filterValuesByFilteringColumn: Record<string, FilterValuesWithCaption> = {};

    let actionIndex = 0;

    for (const columnInfo of columnsToFilterBy) {
        const { filterValueAndFormatSubscribe, filterColumn, columnType, captionGetter } = columnInfo;
        const columnName = filterColumn.name;

        filterValueAndFormatSubscribe(ttvp);

        if (columnType.kind === "boolean") {
            filterValuesByFilteringColumn[columnName] = {
                kind: "boolean",
                caption: captionGetter(hb) ?? "",
                filterEntry: makeBooleanFilterEntry(hb, filterEditable, columnName, actionIndex),
            };

            actionIndex++;
        } else {
            filterValuesByFilteringColumn[columnName] = {
                kind: "string",
                caption: captionGetter(hb) ?? "",
                filterEntries: new DefinedPrimitiveValueMap<StringMultipleDynamicFilterEntry>(),
            };
        }
    }

    const filteredRows: Row[] = [];
    for (const row of rows.values()) {
        // The row has to be included in all the columns we're filtering by. Default is true cause empty operations work like that.
        let isIncludedInAll = true;

        for (const columnInfo of columnsToFilterBy) {
            const { filterValueAndFormatGetter, filterColumn, columnType } = columnInfo;
            const columnName = filterColumn.name;

            const values = defined(filterValueAndFormatGetter)(row, ttvp);

            // This is the array of selected values
            const selectedValuesForColumn = selectedValuesByColumnName[columnName];

            const isIncluded =
                selectedValuesForColumn === undefined ||
                values.some(([v]) => {
                    if (columnType.kind === "boolean") {
                        return selectedValuesForColumn.some(f => areBooleanPrimitiveValuesEqual(f, v));
                    } else {
                        return selectedValuesForColumn.some(f => arePrimitiveValuesStrictlyEqual(f, v));
                    }
                });

            if (!isIncluded) {
                isIncludedInAll = false;
            }

            if (columnType.kind === "boolean") {
                // We don't need to build the entry for booleans,
                // we already built it when we created the `filterValuesByFilteringColumn` for it.
                continue;
            }

            const entriesByColumnName = filterValuesByFilteringColumn[columnName] as StringFilterValuesWithCaption;

            for (const [primitiveValue, displayValue] of values) {
                if (!isNotEmpty(primitiveValue)) continue;
                assert(primitiveValue !== undefined);

                if (!entriesByColumnName.filterEntries.has(primitiveValue)) {
                    entriesByColumnName.filterEntries.set(
                        primitiveValue,
                        makeStringFilterEntry(hb, filterEditable, displayValue, columnName, primitiveValue, actionIndex)
                    );

                    actionIndex++;
                }
            }
        }

        if (isIncludedInAll) {
            filteredRows.push(row);
        }
    }

    return {
        filteredRows: new Table(filteredRows),
        entries: getSortedFilterEntries(filterValuesByFilteringColumn),
    };
}

export function applyDynamicFilter(
    hb: WireHydrationBackend,
    ttvp: WireTableTransformValueProvider,
    inflatedDynamicFilter: InflatedDynamicFilter,
    rows: Table | undefined,
    filterState: DynamicFilterState,
    appKind: AppKind,
    needsFilterValues: boolean,
    queryableFilterEntries: readonly FilterEntry[] | LoadingValue | undefined
): DynamicFilterResult {
    const { filterValueAndFormatSubscribe, filterValueAndFormatGetter } = inflatedDynamicFilter;

    const { filterEditable, isOpenEditable } = filterState;
    const { value: filterValue } = filterEditable;

    let dynamicFilterValues: DynamicFilterResult["dynamicFilterValues"] = {
        valueAndFormatGetter: filterValueAndFormatGetter,
        filterValues: filterEditable.value,
        filterEditable,
    };

    // only apps allow multiple filter values
    const allowMultipleFilterValues = appKind === AppKind.App;

    let displayValue: string | null;
    if (filterValue.length === 1) {
        displayValue = formatValueWithSpecification(inflatedDynamicFilter.valueFormat, filterEditable.value[0]) ?? null;
    } else {
        displayValue = makeLocalizedNumberOfItems(filterValue.length, appKind) ?? null;
    }

    // We haven't implemented the "open" action for ##dynamicFilterInApps, so
    // we never take this path and always fully hydrate below.
    if (appKind !== AppKind.App) {
        // The reason we also don't take this path when there are filter
        // values is that in that case we need to filter the rows anyway, so
        // we might as well also collect all the values.
        const needsFiltering = needsFilterValues || isOpenEditable.value || filterValue.length > 0;
        // If we have a queryable, we never want to filter over the rows we
        // have, so if we don't have entries loaded yet, we always hydrate the
        // "open" action.
        const isQueryable = inflatedDynamicFilter.supportsQueryables && queryableFilterEntries === undefined;

        if (!needsFiltering || isQueryable) {
            const onOpenToken = definedMap(isOpenEditable.onChangeToken, t =>
                hb.registerAction("filter-open", async ab => {
                    return ab.valueChanged(t, true, ValueChangeSource.Internal);
                })
            );
            return {
                dynamicFilterValues,
                dynamicFilter: {
                    onOpen: definedMap(onOpenToken, token => ({ token })),
                    displayValue,
                },
                table: rows ?? new Table(),
                isActive: filterValue.length > 0,
            };
        }
    }

    let hasSelectedEntry = false;

    function makeEntry(value: PrimitiveValue, display: string, index: number) {
        const selected = filterValue.some(f => arePrimitiveValuesStrictlyEqual(f, value));
        if (selected) {
            hasSelectedEntry = true;
        }
        return {
            displayValue: display,
            count: 1,
            selected,
            onToggle: {
                token: hb.registerAction(
                    `filter-${index}`,
                    makeFilterActionRunner(value, filterEditable, allowMultipleFilterValues)
                ),
            },
        };
    }

    const entriesForValues = new DefinedPrimitiveValueMap<DynamicFilterEntryExceptAll>();
    let totalRows: number;

    if (queryableFilterEntries === undefined && rows !== undefined) {
        totalRows = rows.size;

        let index = 0;

        filterValueAndFormatSubscribe?.(ttvp);
        const filteredRows: Row[] = [];
        for (const r of rows.values()) {
            const values = defined(filterValueAndFormatGetter)(r, ttvp);

            const isIncluded = values.some(([v]) => filterValue.some(f => arePrimitiveValuesStrictlyEqual(f, v)));
            if (isIncluded) {
                filteredRows.push(r);
            }

            for (const [v, n] of values) {
                if (!isNotEmpty(v)) continue;
                assert(v !== undefined);

                let entry = entriesForValues.get(v);
                if (entry === undefined) {
                    entry = makeEntry(v, n, index);
                } else {
                    entry.count += 1;
                }
                entriesForValues.set(v, entry);
                index++;
            }
        }

        // `filteredValue` might have a value that's not even one
        // of the allowed values, in which case the filter
        // dropdown will show "All", so we must also show all
        // items in that case, which is why we're doing this
        // check.
        if (hasSelectedEntry) {
            rows = new Table(filteredRows);
        }
    } else if (queryableFilterEntries !== undefined && !isLoadingValue(queryableFilterEntries)) {
        totalRows = 0;

        let index = 0;
        for (const { value, count } of queryableFilterEntries) {
            totalRows += count;

            if (value === undefined) continue;

            const display = formatValueWithSpecification(inflatedDynamicFilter.valueFormat, value) ?? asString(value);

            const entry = makeEntry(value, display, index);
            entry.count = count;
            entriesForValues.set(value, entry);

            index++;
        }
    } else {
        // We don't modify `rows` here, but we don't create a dynamic filter.
        totalRows = 0;
    }

    // `displayValue === null` is the entry for "All".
    // We don't include any other undefined values.
    //
    // The `tagActions` in `kanban-array-content.ts` assume the "All" filter
    // is the first entry.
    const allEntry: DynamicFilterEntry = {
        displayValue: null,
        count: totalRows,
        selected: !hasSelectedEntry,
        onToggle: {
            token: hb.registerAction(
                "filter-all",
                makeFilterActionRunner(undefined, filterEditable, allowMultipleFilterValues)
            ),
        },
    };
    const dynamicFilterEntries = [allEntry, ...sortItems(Array.from(entriesForValues.values()), e => e.displayValue)];

    dynamicFilterValues = {
        ...dynamicFilterValues,
        filterValues: Array.from(entriesForValues.keys()),
    };

    let dynamicFilter: WireDynamicFilter = {
        entries: dynamicFilterEntries,
        displayValue: displayValue,
    };
    if (isLoadingValue(queryableFilterEntries)) {
        dynamicFilter = {
            ...dynamicFilter,
            entries: undefined,
            onOpen: { token: WireActionBusy },
        };
    }

    return {
        dynamicFilterValues,
        dynamicFilter,
        table: rows ?? new Table(),
        isActive: hasSelectedEntry,
    };
}

export type ComponentEnricher<Comp extends WireComponent> = (
    component: Omit<Comp, "id" | "customCssClassName">
) => Comp;

export function inflateComponentEnricher<Comp extends WireComponent>(
    ib: WireValueInflationBackend,
    desc: ComponentDescription
): ComponentEnricher<Comp> {
    const customCssClassName = getStringProperty(desc.customCssClassName);

    return component => {
        return {
            ...component,
            ...spreadComponentID(desc.componentID, ib.forBuilder),
            customCssClassName,
        } as Comp;
    };
}

export function hydrateSubsidiaryScreenFlag(
    hb: WireRowComponentHydrationBackend,
    key: string,
    onToggle: ((ab: WireActionBackend, willOpen: boolean) => void) | undefined
): [WireAlwaysEditableValue<boolean>, WireActionRunner] {
    const subsidiaryOpen = hb.getState(`${key}-subsidiaryOpen`, isBoolean, false, false);
    const runner: WireActionRunner | undefined = async ab => {
        const shouldOpen = !subsidiaryOpen.value;
        onToggle?.(ab, shouldOpen);
        return ab.valueChanged(subsidiaryOpen.onChangeToken, shouldOpen, ValueChangeSource.User);
    };
    return [subsidiaryOpen, runner];
}

export function inflateImageSource(
    ib: WireValueInflationBackend,
    imageKindProperty: LegacyPropertyDescription | undefined,
    imageProperty: LegacyPropertyDescription | undefined
): (hb: WireRowComponentHydrationBackend) => WireImageSource | Unbound | undefined {
    const imageKind = getEnumProperty<ImageKind>(imageKindProperty) ?? ImageKind.URL;
    if (imageKind === ImageKind.MapFromAddress) {
        const [imageGetter] = inflateStringProperty(ib, imageProperty, false, { allowArrays: true });
        return hb => {
            let imageValue = imageGetter(hb);
            if (imageValue === UnboundVal) return UnboundVal;

            imageValue = imageValue.trim();
            if (imageValue === "") return { value: "", onChangeToken: undefined };

            return {
                kind: ImageSourceKind.GeoCoordinates,
                location: {
                    kind: MapLocationKind.Address,
                    address: imageValue,
                },
            };
        };
    } else {
        return inflateBuilderEditableImage(ib, imageProperty);
    }
}

export function inflateSwitchWithCondition(
    ib: WireValueInflationBackend,
    desc: PropertyDescription | undefined,
    defaultValue: boolean = false
): WirePredicate {
    const switchDesc = getSwitchWithConditionProperty(desc);
    if (switchDesc === undefined) {
        return () => defaultValue;
    }
    // ##switchWithConditionCanBeFalse:
    // If a switch's value is `false` then that takes precendence over whether
    // it has a condition or not.
    if (switchDesc.condition === undefined || switchDesc.value !== true) {
        return () => switchDesc.value === true;
    }
    const [predicate] = ib.inflateFilters([switchDesc.condition], false);
    return predicate;
}

export function makeGroups<T>(
    thb: WireTableComponentHydrationBackend,
    groupByGetters: GroupByGetters | undefined,
    tableName: TableName,
    makeGroup: (title: string | undefined, rows: readonly Row[], groupValue: DefinedPrimitiveValue, index: number) => T,
    defaultGroup?: { title: string; value: GroundValue }
): readonly T[] | undefined {
    const allRows = thb.tableScreenContext.asArray();
    if (allRows === undefined) return undefined;
    if (allRows.length === 0) return [];

    if (groupByGetters === undefined) {
        return [makeGroup(undefined, allRows, "", 0)];
    }

    const ttvp = thb.makeTableTransformValueProvider(tableName);

    groupByGetters.valueGetter.subscribe(ttvp);
    groupByGetters.titleGetter.subscribe(ttvp);

    const allItems = mapFilterUndefined(allRows, row => {
        const groupValue = groupByGetters?.valueGetter.getter(row, ttvp) ?? defaultGroup?.value;
        // TODO: We only need the title for one of the rows in each group,
        // but we get it for every row.
        const groupTitleRaw = definedMap(groupByGetters?.titleGetter, g => g.getter(row, ttvp)) ?? groupValue;
        if (isLoadingValue(groupTitleRaw)) return undefined;
        const groupTitle = asMaybeString(groupTitleRaw) ?? defaultGroup?.title;

        return [groupValue, groupTitle, row] as const;
    });

    // ##listGrouping:
    // We have two implementations of this that must be kept in sync.
    const groupMap = new DefinedPrimitiveValueMap<[string, Row[]]>();
    for (const [groupValue, groupTitle, row] of allItems) {
        if (!isPrimitiveValue(groupValue) || groupValue === undefined) continue;
        let entry = groupMap.get(groupValue);
        if (entry === undefined) {
            assert(typeof groupTitle === "string");
            entry = [groupTitle, []];
            groupMap.set(groupValue, entry);
        }
        entry[1].push(row);
    }
    return Array.from(groupMap).map(([groupValue, [title, rows]], i) => makeGroup(title, rows, groupValue, i));
}

export function getPageSizeForPaging(
    selectedPageSize: number | undefined,
    // `undefined` means no paging
    defaultPageSize: number | undefined,
    hasFixedPageSize: boolean
): number | undefined {
    if (defaultPageSize === undefined) return undefined;

    if (hasFixedPageSize) {
        return defaultPageSize;
    }

    return Math.max(1, selectedPageSize ?? defaultPageSize);
}

export function getQueryLimitForPaging(
    support: QueryableTableSupport,
    selectedPageSize: number | undefined,
    pageIndexState: WireAlwaysEditableValue<number>
): number {
    if (support === QueryableTableSupport.LoadOnDemand) {
        if (selectedPageSize !== undefined) {
            // We pick a limit for the query that allows us to show 10
            // more pages, rounded up to a multiple of 500.
            const position = pageIndexState.value * selectedPageSize;
            const requiredPosition = position + 10 * selectedPageSize;
            return Math.ceil(requiredPosition / 500) * 500;
        } else {
            return 1000;
        }
    } else {
        // We have to set some limit
        return 5000;
    }
}

export function applyPaging<T>(
    items: readonly T[],
    pageIndexState: WireEditableValue<number>,
    selectedPageSize: number | undefined
): [paging: WirePaging, pageItems: readonly T[]] {
    let pageSize: number;
    let numPages: number;
    let pageIndex: number;
    let pageItems: readonly T[];
    if (selectedPageSize !== undefined) {
        pageSize = Math.min(items.length, selectedPageSize);
        numPages = pageSize > 0 ? Math.ceil(items.length / pageSize) : 0;
        pageIndex = pageIndexState.value;
        if (numPages === 0 || pageIndex < 0) {
            pageIndex = 0;
        } else if (pageIndex >= numPages) {
            pageIndex = numPages - 1;
        }

        const firstIndex = pageIndex * pageSize;
        pageItems = items.slice(firstIndex, firstIndex + pageSize);
    } else {
        pageSize = items.length;
        numPages = 1;
        pageIndex = 0;
        pageItems = items;
    }

    return [
        {
            pageSize,
            pageIndex: { ...pageIndexState, value: pageIndex },
            numPages,
            itemsCount: items.length,
        },
        pageItems,
    ];
}

export function applyGroupPaging<TGroup>(thb: WireStateSaver, items: readonly TGroup[]) {
    const groupPageIndexState = thb.getState("groupPageIndex", isNumber, 0, true);
    // We hardcode the number of groups in a page to 20 for now.
    return applyPaging(items, groupPageIndexState, 20);
}

export function getCanEditFromNetworkStatus(
    hb: WireRowActionHydrationValueProvider,
    eminenceFlags: EminenceFlags,
    mutatingScreenKind: MutatingScreenKind
): boolean {
    if (hb.getIsOnline()) return true;

    if (mutatingScreenKind === MutatingScreenKind.EditScreen) return false;
    return eminenceFlags.offlineActionQueue;
}

export function getCanEditRowFromNetworkStatus(
    row: Row,
    hb: WireRowActionHydrationValueProvider,
    eminenceFlags: EminenceFlags,
    mutatingScreenKind: MutatingScreenKind
): boolean {
    // Invisible rows are temporary and can always be edited
    if (!row.$isVisible) return true;

    return getCanEditFromNetworkStatus(hb, eminenceFlags, mutatingScreenKind);
}

export function registerActionWithForcedSignIn(
    hb: WireRowComponentHydrationBackend,
    key: string,
    withUserName: boolean,
    runner: WireActionRunner
): { action: WireAction | undefined; subsidiaryScreen: WireScreen | undefined } {
    const [subsidiaryOpen] = hydrateSubsidiaryScreenFlag(hb, `${key}-signInOpen`, undefined);
    if (subsidiaryOpen.value) {
        const onSuccess = registerActionRunner(hb, `${key}-signedIn`, async ab => {
            ab.valueChanged(subsidiaryOpen.onChangeToken, false, ValueChangeSource.User);
            return await ab.invoke("with sign-in", runner, false);
        });
        const onFailure = registerActionRunner(hb, `${key}-signInFailed`, async ab => {
            return ab.valueChanged(subsidiaryOpen.onChangeToken, false, ValueChangeSource.User);
        });
        if (onSuccess === undefined || onFailure === undefined) {
            return { action: undefined, subsidiaryScreen: undefined };
        }

        const component: WireSignInComponent = {
            kind: WireComponentKind.SignIn,
            onSuccess,
            onFailure,
            isSignUp: false,
            withUserName,
        };
        const subsidiaryScreen: WireScreen = {
            key: encodeScreenKey("signIn"),
            title: "",
            components: [component],
            specialComponents: [],
            closeAction: onFailure,
            flags: [WireScreenFlag.IsSignIn],
            isInModal: true,
            tabIcon: "",
        };

        return { action: undefined, subsidiaryScreen };
    } else {
        if (hb.getIsUserSignedIn()) {
            const action = registerActionRunner(hb, key, runner);
            return { action, subsidiaryScreen: undefined };
        }

        const wrapperAction = registerActionRunner(hb, key, async ab => {
            return ab.valueChanged(subsidiaryOpen.onChangeToken, true, ValueChangeSource.User);
        });
        return { action: wrapperAction, subsidiaryScreen: undefined };
    }
}

// We use this function so that the inline list hydrator as well as the
// content hydrator produce the same state, so that they can call this, vs
// having to pass the result to each other.
export function getItemsPageIndex(
    ss: WireStateSaver,
    withGrouping: boolean,
    groupKey: string
): WireAlwaysEditableValue<number> {
    let key: string;
    if (withGrouping) {
        key = `itemsPageIndex[${groupKey}]`;
    } else {
        key = "pageIndex";
    }
    return ss.getState(key, isNumber, 0, true);
}
