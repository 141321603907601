import type { ComponentIndexes } from "@glide/common-core/dist/js/component-indexes";
import { type PrimitiveValue, type Unbound, UnboundVal } from "@glide/computation-model-types";
import type { WireActionWithTitle } from "@glide/fluent-components/dist/js/base-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import type { AppKind } from "@glide/location-common";
import { defined, definedMap } from "@glideapps/ts-necessities";
import { isDefined, isUndefinedish } from "@glide/support";
import {
    type WireScreen,
    type ValueChangeSource,
    type UIButtonAppearance,
    type WireAction,
    type WireEditableValue,
    WireActionBusy,
} from "@glide/wire";
import React from "react";
const WireActionOffline = "";

import type { WireButtonProps } from "./renderers/wire-button/wire-button";

const PortalBaseNameContext = React.createContext("");

interface PortalBaseNameProviderProps {
    readonly baseName: string;
}

export const PortalBaseNameProvider: React.FC<React.PropsWithChildren<PortalBaseNameProviderProps>> = p => {
    const { baseName, children } = p;

    return <PortalBaseNameContext.Provider value={baseName}>{children}</PortalBaseNameContext.Provider>;
};

export function usePortalBaseName(): string {
    return React.useContext(PortalBaseNameContext);
}

export const NavBarPortalContext = React.createContext<string | undefined>(undefined);

export const PortalIdContext = React.createContext<string | undefined>(undefined);

export const createNavBarPortalIdFromKey = (
    baseName: string,
    key: WireScreen["key"],
    layerPosition: "top" | "bottom"
) => `nav-bar-portal-${baseName}-${key}-${layerPosition}`;

export const createSlideInPortalIdFromKey = (baseName: string, key: WireScreen["key"]) => `slide-in-${baseName}-${key}`;

export const createOverlayPortalIdFromKey = (baseName: string, key: WireScreen["key"]) => `overlay-${baseName}-${key}`;

const usePortalId = () => React.useContext(PortalIdContext);

export const useTitleBreadcrumbsPortalId = () => {
    const portalId = usePortalId();
    return portalId === undefined ? undefined : `${portalId}-breadcrumbs`;
};

export const useFloatingLayerPortalId = () => {
    const portalId = usePortalId();
    return portalId === undefined ? undefined : `${portalId}-floating-layer`;
};

export const useIsInsideModal = () => {
    const portalId = usePortalId();
    return portalId !== undefined && (portalId.startsWith("overlay") || portalId.startsWith("slide-in"));
};
export interface ExtractedAction {
    run?: (handled: boolean) => void;
    title: string;
    icon?: string;
    // Defaults to "left"
    iconPlacement?: "left" | "right";
    appearanceOverride?: UIButtonAppearance;
    url?: string;
    enabled: boolean;
}

export interface FormElementProps {
    title?: string;
    isEnabled?: boolean;
    isRequired?: boolean;
    errorMessage?: string;
    hint?: string;
    appKind: AppKind;
}

// Copied from app/src/store/types.ts for wire-renderer porting
export interface ComponentSelectorState {
    isOpen: boolean;
    targetIndexes: ComponentIndexes | undefined;
}

export interface LeftPanelState {
    shouldExpand: boolean;
    copyingSample: boolean;
}

export interface SelectedComponentPath {
    appID: string;
    screenName: string;
    indexes: ComponentIndexes;
    subcomponent?: string;
}

// from app/src/webapp/lib/constants.ts
export const GLIDE_COMPONENT_INDEX_DATA_FORMAT = "text/glide-component-index";
export const GLIDE_TABLE_COLUMN_DATA_FORMAT = "text/glide-column";
export const GLIDE_NEW_COMPONENT_DATA_FORMAT = "text/glide-component-type";
export const TITLE_BAR_TAP = "TITLE_BAR_TAP";
export const COMPONENTS_CHANGED = "COMPONENTS_CHANGED";
export const SCROLL_CHANGED = "SCROLL_CHANGED";

export const APP_MODAL_ROOT = "app-modal-root";
export const PUSH_MODAL_ROOT = "push-modal-root";
export const FLYOUT_ROOT = "flyout-root";

export const APP_ROOT = "app-root";

export const TABLET_CONTENT_ROOT = "tablet";
export const PHONE_CONTENT_ROOT = "phone-content";

export const softEnforcementDismissTimeoutMS = 10000;

export type ExtractedActions = readonly (ExtractedAction | undefined)[];

type WireButtonSpreadProps = Pick<
    WireButtonProps,
    "target" | "href" | "onClick" | "children" | "disabled" | "iconName" | "iconPlacement"
>;

export function makeActionSpreadProps(action: ExtractedAction): WireButtonSpreadProps {
    return {
        disabled: !action.enabled,
        children: action.title,
        iconName: action.icon,
        iconPlacement: action.iconPlacement,
        onClick:
            action.run === undefined
                ? undefined
                : e => {
                      e.stopPropagation();
                      action.run?.(action.url !== undefined);
                  },
        href: action.url,
        target: action.url === undefined ? undefined : "_blank",
    };
}

export function makeSingleActionSpreadProps(
    action: WireAction | undefined | null,
    backend: WireBackendInterface
): Pick<WireButtonProps, "target" | "href" | "onClick" | "disabled"> {
    return {
        disabled: isUndefinedish(action) || action.token === WireActionOffline || action.token === WireActionBusy,
        onClick: e => {
            e.stopPropagation();
            if (!isUndefinedish(action) && !isUndefinedish(action.token)) {
                backend.runAction(action.token, action.url !== undefined);
            }
        },
        href: action?.url,
        target: action?.url === undefined ? undefined : "_blank",
    };
}

// This should generally be used on the frontend instead of calling backend.runAction directly,
//  because pop-up blockers can prevent the openURL action if some user interaction event is not
//  on the call stack (which it won't be in e.g. backend NCM when the action is triggered async by the server)
export function runActionAndHandleURL(
    action: WireAction | undefined | null,
    backend: WireBackendInterface,
    handled: boolean = false
): void {
    const token = action?.token;
    if (isUndefinedish(token)) return;

    const url = action?.url;
    if (!handled && isDefined(url)) {
        handled = isDefined(window.open(url));
    }

    backend.runAction(token, handled);
}

export function extractActions(
    actions: readonly WireActionWithTitle[] | Unbound | undefined,
    backend: WireBackendInterface
): ExtractedActions {
    return (
        actions?.map(a => {
            if (a.action === UnboundVal || a.action === undefined || !isDefined(a.title)) return undefined;
            return {
                run:
                    a.action.token === WireActionOffline
                        ? undefined
                        : (handled: boolean) => backend.runAction(defined(a.action?.token), handled),
                title: a.title,
                icon: a.icon,
                iconPlacement: a.iconPlacement,
                appearanceOverride: a.appearanceOverride,
                url: a.action?.url ?? undefined,
                enabled: a.action !== undefined && a.action.token !== null,
            };
        }) ?? []
    );
}

export function breakoutActions(
    actions: ExtractedActions | undefined,
    numSpecial: 1
): [ExtractedAction | undefined, ...ExtractedAction[]];
export function breakoutActions(
    actions: ExtractedActions | undefined,
    numSpecial: 2
): [ExtractedAction | undefined, ExtractedAction | undefined, ...ExtractedAction[]];
export function breakoutActions(
    actions: ExtractedActions | undefined,
    numSpecial: 3
): [ExtractedAction | undefined, ExtractedAction | undefined, ExtractedAction | undefined, ...ExtractedAction[]];
export function breakoutActions(
    actions: ExtractedActions | undefined,
    numSpecial: number
): (ExtractedAction | undefined)[] {
    if (actions === undefined) return [];
    const start = actions.slice(0, numSpecial);
    const end = actions.slice(numSpecial).filter(isDefined);
    return [...start, ...end];
}

export function useWireValue<T extends PrimitiveValue>(
    val: WireEditableValue<T>,
    backend: WireBackendInterface
): [T, ((newVal: T, source: ValueChangeSource) => void) | undefined] {
    return [
        val.value,
        definedMap(
            val.onChangeToken,
            x => (newVal: T, source: ValueChangeSource) => backend.valueChanged(x, newVal, source)
        ),
    ];
}
