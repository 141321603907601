import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useHistory } from "react-router-dom";
import type { OrgMember } from "../lib/orgs-lib";
import { getOrgMembers } from "../lib/orgs-lib";

interface UseMembers {
    readonly orgMembers: readonly OrgMember[];
    readonly viewMembers: () => void;
}

export function useMembers(orgID: string | undefined): UseMembers {
    const history = useHistory();

    const { data } = useQuery(["getMembers", orgID], async () => {
        return await getOrgMembers(orgID);
    });

    const viewMembers = React.useCallback(() => {
        history.push(`/o/${orgID}/members`);
    }, [history, orgID]);

    return {
        orgMembers: data ?? [],
        viewMembers,
    };
}
