import { type TypeSchema, makeTypeSchema, type TableName, nativeTableRowIDColumnName } from "@glide/type-schema";
import {
    type RowIndex,
    isBaseRowIndex,
    type ComputationModel,
    type Row,
    type LoadedGroundValue,
    type TableKeeper,
    type TableKeeperStore,
    TableKeeperStoreImpl,
} from "@glide/computation-model-types";
import {
    type LocalDataStore,
    type ActionOutstandingOperationsHandler,
    type ActionOperationsState,
    emptyActionOperationsState,
    type MinimalAppEnvironment,
    type DataStoreMutationOptions,
    type AddRowToTableResult,
    type MutationResult,
} from "@glide/common-core/dist/js/components/types";
import { extractActionValues } from "@glide/common-core/dist/js/computation-model/row-data";
import { type ChangeObservable, Watchable, checkString, logInfo } from "@glide/support";
import { assert } from "@glideapps/ts-necessities";
import { DatastoreIndexedDBCache } from "./local-datastore-persistence";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import { SimpleTableKeeper } from "./simple-table-keeper";

/**
 * @deprecated This class is only used by the Buy Button.
 */
export class LocalDataStoreImpl implements LocalDataStore {
    private _schema: TypeSchema | undefined;
    public readonly persistence: DatastoreIndexedDBCache | undefined;
    private readonly _computationModelObservable = new Watchable<ComputationModel | undefined>(undefined);

    constructor(public readonly appID: string, usePersistence: boolean = true) {
        this.persistence = !usePersistence ? undefined : new DatastoreIndexedDBCache(appID);
    }

    subscribeToOutstandingOperations(handler: ActionOutstandingOperationsHandler): void {
        handler(this.getActionOutstandingOperations());
    }

    unsubscribeFromOutstandingOperations(): void {
        return;
    }

    getActionOutstandingOperations(): ActionOperationsState {
        return emptyActionOperationsState;
    }

    private readonly _tableKeeperStore = new TableKeeperStoreImpl(tn => {
        // We can't do this right away or it'll lead to an infinite recursion.
        // The non-lazy, non-ugly way to solve this would be to have a
        // separate map for the table keepers and only use this table keeper
        // store as a public front.
        setTimeout(() => this.fetchTableRows(tn), 0);
        return new SimpleTableKeeper();
    });

    private readonly rehydratedTable = new Set<string>();
    private readonly keyForRowID: Map<string, number> = new Map();

    private async rehydrateTable(tableName: TableName): Promise<void> {
        if (this.rehydratedTable.has(tableName.name)) return;
        if (this.persistence === undefined) return;
        this.rehydratedTable.add(tableName.name);

        const keeper = this.getKeeperForTable(tableName);
        for (const { columns, rowID: rowKey } of await this.persistence.restore(tableName)) {
            const rowID = makeRowID();
            keeper.addRow({
                ...columns,
                $rowID: rowID,
                $isVisible: true,
            });
            if (rowKey !== undefined) {
                this.keyForRowID.set(rowID, rowKey);
            }
        }
    }

    public get tableKeeperStore(): TableKeeperStore<TableKeeper> {
        return this._tableKeeperStore;
    }

    public setAppEnvironment(_appEnvironment: MinimalAppEnvironment): void {
        return;
    }

    public appEnvironmentUpdated(): void {
        return;
    }

    public setAppUser(_appUserID: string): void {
        return;
    }

    private getKeeperForTable(tableName: TableName): SimpleTableKeeper {
        return this._tableKeeperStore.getTableKeeperForTable(tableName);
    }

    protected async fetchTableRowsRehydrating(tableName: TableName): Promise<SimpleTableKeeper> {
        await this.rehydrateTable(tableName);
        return this.getKeeperForTable(tableName);
    }

    public fetchTableRows(tableName: TableName): void {
        logInfo("Local datastore fetch table rows", this, tableName);
        void this.fetchTableRowsRehydrating(tableName);
    }

    public getFirstRowObservable(): ChangeObservable<Row | undefined> | undefined {
        return undefined;
    }

    // We use this in tests to make test cases work that have no rows.  If we
    // didn't call this then those tables would be stuck in the loading state.
    public setTableIsLoaded(tableName: TableName): void {
        const keeper = this.getKeeperForTable(tableName);
        keeper.setIsLoaded();
    }

    public async addRowToTable(
        { tableName }: DataStoreMutationOptions,
        columnValues: Record<string, LoadedGroundValue>,
        columnNames: ReadonlySet<string> | undefined
    ): Promise<AddRowToTableResult> {
        logInfo("Local datastore add row", this, tableName);
        const values = extractActionValues(columnValues, columnNames);
        logInfo("Local datastore add row", values);

        const keeper = await this.fetchTableRowsRehydrating(tableName);

        let rowID: string;
        if (typeof columnValues[nativeTableRowIDColumnName] === "string") {
            // We support this for our tests.
            rowID = columnValues[nativeTableRowIDColumnName] as string;
        } else {
            rowID = makeRowID();
        }

        const row: Row = { ...values, $rowID: rowID, $isVisible: true };
        keeper.addRow(row);

        if (this.persistence !== undefined) {
            const rowKey = await this.persistence?.persist(tableName, values);
            if (rowKey !== undefined) {
                this.keyForRowID.set(rowID, rowKey);
            }
        }

        return { didAdd: true, playerRow: row, builderRow: undefined, jobID: undefined, confirmedAtVersion: true };
    }

    public async setColumnsInRow(
        _options: DataStoreMutationOptions,
        _rowIndex: RowIndex,
        _columnValues: Record<string, LoadedGroundValue>,
        _withDebounce: boolean
    ): Promise<MutationResult> {
        return { jobID: undefined, confirmedAtVersion: false };
    }

    // ##deleteIndividualShoppingCartRow:
    // We only call this from the new computation model, with the row ID.
    public async deleteRowsAtIndexes(
        { tableName }: DataStoreMutationOptions,
        rowIndexes: readonly RowIndex[]
    ): Promise<MutationResult> {
        const keeper = await this.fetchTableRowsRehydrating(tableName);

        for (const rowIndex of rowIndexes) {
            assert(!isBaseRowIndex(rowIndex));
            assert(rowIndex.keyColumnName === "$rowID");

            // It's super inefficient to do this nested loop, but we only use
            // this for the shopping cart, and we only ever delete single
            // items.
            for (const row of keeper.table.values()) {
                if (row.$rowID === rowIndex.keyColumnValue) {
                    await this.deleteRow(tableName, row);
                    return { jobID: undefined, confirmedAtVersion: true };
                }
            }
        }

        return { jobID: undefined, confirmedAtVersion: false };
    }

    private async deleteFromPersistence(tableName: TableName, row: Row): Promise<void> {
        if (this.persistence === undefined) return;

        const key = this.keyForRowID.get(row.$rowID);
        if (key === undefined) return;

        await this.persistence.remove(tableName, key);
    }

    private async deleteRow(tableName: TableName, row: Row): Promise<void> {
        const keeper = await this.fetchTableRowsRehydrating(tableName);
        keeper.deleteRow(row.$rowID);
        await this.deleteFromPersistence(tableName, row);
    }

    public setSchema(_schema: TypeSchema): void {
        this._schema = _schema;
    }

    public userProfileTableChanged(): void {
        return;
    }

    public get schema(): TypeSchema {
        return this._schema ?? makeTypeSchema([]);
    }

    public setEmailOwnersColumns(_tableName: TableName, _emailOwnersColumns: readonly string[]): void {
        return;
    }

    public isRowOwnedByUser(_tableName: TableName, _row: Row): boolean {
        return true;
    }

    public resetFromUpstream(): void {
        return;
    }

    // We use this in testing
    public setComputationModel(computationModel: ComputationModel): void {
        this._computationModelObservable.current = computationModel;
    }

    public getComputationModelObservable(): ChangeObservable<ComputationModel | undefined> {
        return this._computationModelObservable;
    }

    public addRowOwnerChangeCallback(_cb: () => void): void {
        return;
    }

    public removeRowOwnerChangeCallback(_cb: () => void): void {
        return;
    }

    public retire(): void {
        return;
    }

    public getAppUserID(): string | undefined {
        return undefined;
    }
}

export class TestLocalDataStoreImpl extends LocalDataStoreImpl {
    // This doesn't do persistent, because we only use it for testing.
    public async setColumnsInRow(
        { tableName, setUnderlyingData }: DataStoreMutationOptions,
        rowIndex: RowIndex,
        columnValues: Record<string, LoadedGroundValue>,
        _withDebounce: boolean
    ): Promise<MutationResult> {
        if (!setUnderlyingData) return { jobID: undefined, confirmedAtVersion: true };

        const keeper = await this.fetchTableRowsRehydrating(tableName);

        assert(!isBaseRowIndex(rowIndex));
        assert(rowIndex.keyColumnName === nativeTableRowIDColumnName);

        const rowID = checkString(rowIndex.keyColumnValue);

        for (const [k, v] of Object.entries(columnValues)) {
            keeper.setColumnInRow(rowID, k, v);
        }

        return { jobID: undefined, confirmedAtVersion: true };
    }
}
