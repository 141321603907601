import "twin.macro";

import { GlideIcon } from "@glide/common";
import type { StrokeIcons } from "@glide/plugins";
import { getPlanInfoForPlanKind } from "@glide/common-core/dist/js/billing-vnext/org-plan-lib";
import { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";

import { Button } from "../button/button";

export const FeatureBox: React.FC<{
    onClick?(): void;
    onComparePlans?(): void;
    icon: StrokeIcons;
    name: string;
    description: string;
    note?: string;
    targetPlan?: PlanKind;
    targetPlanLabel?: string;
}> = props => {
    const { onClick, icon, name, description, targetPlan, note, onComparePlans, targetPlanLabel } = props;
    const { short } = getPlanInfoForPlanKind(targetPlan ?? PlanKind.Free);
    const planLabel = targetPlanLabel ?? short;
    const hasTrial = targetPlan === PlanKind.V3Business;
    return (
        <div tw="bg-aqua100 rounded-md p-4 flex flex-col items-center justify-center">
            <div tw="flex flex-col justify-center items-center">
                <GlideIcon tw="text-aqua400 mb-3" kind="stroke" icon={icon} strokeWidth={2} iconSize={32} />
                <h4 tw="mb-1 font-medium">{name}</h4>
                <p tw="text-center text-xs text-text-pale mb-3">{description}</p>
                {targetPlan !== undefined && onClick !== undefined && (
                    <div tw="flex flex-col">
                        <Button
                            variant={"gradient"}
                            buttonType={"primary"}
                            size={"xsm"}
                            label={hasTrial ? `Try ${planLabel} for free` : `Upgrade to ${planLabel}`}
                            onClick={onClick}
                        />
                        <button tw="mt-1.5 text-aqua500 hover:!underline text-builder-sm" onClick={onComparePlans}>
                            Compare plans
                        </button>
                    </div>
                )}
                {note && <p tw="text-center text-xs text-text-warning">{note}</p>}
            </div>
        </div>
    );
};
