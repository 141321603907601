import { assertNever } from "@glideapps/ts-necessities";

import { PlanKind } from "./subscriptions";

// Fix this so it's orgPlan objects
export type OrgPlanIconNames =
    | "st-personal-team"
    | "st-starter-team"
    | "st-pro-team"
    | "st-business-team"
    | "st-enterprise-team"
    | "st-test-team"
    | "st-scaled-updates"
    | "st-fixed-updates"
    | "st-trial";

export type OrgPlanNames =
    | "Free"
    | "Test Team"
    | "Maker plan"
    | "Team plan"
    | "Business plan"
    | "Enterprise"
    | "Non-profit";

export type OrgPlanInfoBlock = {
    planKind: PlanKind;
    short: string;
    long: OrgPlanNames;
    url: string;
    icon: OrgPlanIconNames;
    details: string;
    price: number;
    units: string;
};

function getPrice(price: number, annual: boolean) {
    return annual ? price * 12 - price * 12 * 0.2 : price;
}

// FIXME: Retrieve the price for plans in the backend instead, and use the $US
// totals from that to show totals here. This allows us to update prices without
// pushing a code change.
export function getPlanInfoForPlanKind(plan: PlanKind, annual: boolean = false): OrgPlanInfoBlock {
    const unit = annual ? "year" : "month";
    switch (plan) {
        case PlanKind.Free:
            return {
                planKind: PlanKind.Free,
                short: "Free",
                long: "Free",
                url: "https://www.glideapps.com/pricing",
                icon: "st-personal-team",
                details: "For individuals and small teams building a few apps.",
                price: getPrice(0, annual),
                units: unit,
            };
        case PlanKind.TestPro:
            return {
                planKind: PlanKind.TestPro,
                short: "Test",
                long: "Test Team",
                url: "https://www.glideapps.com/pricing",
                icon: "st-test-team",
                details: "For individuals and small teams building a few apps.",
                price: getPrice(25, annual),
                units: unit,
            };
        case PlanKind.Unlimited:
        case PlanKind.V3EnterpriseBaseNoUP:
            return {
                planKind: PlanKind.Unlimited,
                short: "Enterprise",
                long: "Enterprise",
                url: "https://www.glideapps.com/pricing",
                icon: "st-enterprise-team",
                details: "Need more? We’ll create a plan for you.",
                price: getPrice(0, annual),
                units: unit,
            };
        case PlanKind.V3Starter:
            return {
                planKind: PlanKind.V3Starter,
                short: "Maker",
                long: "Maker plan",
                url: "https://www.glideapps.com/pricing",
                icon: "st-starter-team",
                details: "Create an app for your idea, community, or school.",
                price: getPrice(86, annual),
                units: unit,
            };
        case PlanKind.V3Pro:
            return {
                planKind: PlanKind.V3Pro,
                short: "Team",
                long: "Team plan",
                url: "https://www.glideapps.com/pricing",
                icon: "st-pro-team",
                details: "Turn spreadsheets into tools for your team.",
                price: getPrice(125, annual),
                units: unit,
            };
        case PlanKind.V3Business:
            return {
                planKind: PlanKind.V3Business,
                short: "Business",
                long: "Business plan",
                url: "https://www.glideapps.com/pricing",
                icon: "st-business-team",
                details: "Turn spreadsheets into tools for your team.",
                price: getPrice(249, annual),
                units: unit,
            };
        case PlanKind.V3NonProfit:
            return {
                planKind: PlanKind.V3NonProfit,
                short: "Non-profit",
                long: "Non-profit",
                url: "https://glideapps.com/pricing",
                icon: "st-business-team",
                details: "Discounted business plan for non-profits.",
                price: getPrice(25, annual),
                units: unit,
            };
        default:
            assertNever(plan);
    }
}
