import {
    type AppAuthentication,
    type AppDescription,
    type AppFeatures,
    type PluginConfig,
    type UserFeatures,
    defaultUserFeatures,
    type BuilderAction,
    type ActionDescription,
    type ArrayTransform,
    type ClassScreenDescription,
    type ColumnAssignment,
    type ComponentDescription,
    type EditScreenDescription,
    type FilterArrayTransform,
    type FormScreenDescription,
    type PropertyDescription,
    type ScreenDescription,
    type UserProfileDescription,
    ActionKind,
    ArrayScreenFormat,
    ArrayTransformKind,
    ScreenDescriptionKind,
    ActionNodeKind,
    makeActionProperty,
    makeArrayProperty,
    makeColumnProperty,
    makeCompoundActionProperty,
    makeEnumProperty,
    makeNumberProperty,
    makeScreenProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
    makeWebhookProperty,
} from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import {
    type TableName,
    type Formula,
    type SourceColumn,
    FormulaKind,
    UnaryPredicateFormulaOperator,
    makeSourceColumn,
    makeTableName,
    makeTableRef,
    type AndOrFormula,
    type SourceMetadata,
} from "@glide/type-schema";
import { unlimitedEminenceFlags } from "@glide/common-core/dist/js/Database/eminence";
import { ComponentKindInlineList, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import {
    type ExistingAppDescriptionContext,
    editClassScreenName,
    freeScreenPrefix,
    isEditClassScreenName,
    isFormScreenName,
    isFreeScreenName,
    makeFormScreenName,
} from "@glide/function-utils";
import type { PluginTier } from "@glide/plugins";
import { makePluginActionKindFromIDs } from "@glide/plugins-utils";
import { isArray, isDefined } from "@glide/support";
import { type PageScreenTarget, type UIOrientation, WireComponentKind, CardStyle } from "@glide/wire";
import { assert, assertNever, defined, definedMap, hasOwnProperty, panic } from "@glideapps/ts-necessities";
import fromPairs from "lodash/fromPairs";

import { registerActionHandlers } from "../actions/register-all";
import { registerArrayScreenHandlers } from "../array-screens/register-all";
import { ComponentKindButton } from "../components/button";
import { registerDescriptionHandlers } from "../components/description-handlers/register-all";
import { ComponentKindEmail } from "../components/email";
import { ComponentKindFavorite } from "../components/favorite";
import { registerComponentHandlers } from "../components/register-all";
import { makeSimpleAppDescriptionContext } from "../components/simple-ccc";
import { fixAppDescription } from "../fix-app";
import { makeUnaryPredicateFormula } from "@glide/formula-specifications";
import { type MakeSchemaOptions, type TableSpec, makeSchema } from "./schema-support";
import type { SuperTableColumn } from "@glide/component-utils";
import type { ConfigurableSuperTableColumnKindWithAuto } from "@glide/fluent-components/dist/js/fluent-components";
import type { BaseChoiceColumnDescription } from "../components/new-table-shared";
import { iterableEnumerate } from "collection-utils";

registerComponentHandlers();
registerArrayScreenHandlers();
registerActionHandlers();
registerDescriptionHandlers();

// Right now we only support "is not empty"
interface IsNotEmptyConditionSpec {
    readonly column: ColumnSpec;
}

// If there are multiple conditions, they are ANDed together
type ConditionSpec = IsNotEmptyConditionSpec | readonly IsNotEmptyConditionSpec[];

export function conditionIsNotEmpty(column: ColumnSpec): IsNotEmptyConditionSpec {
    return { column };
}

interface ActionOptions {
    readonly condition?: ConditionSpec;
}

interface ShowToastActionSpec extends ActionOptions {
    readonly kind: ActionKind.ShowToast;
    readonly messageColumn: ColumnSpec;
}

export function actionShowToast(messageColumn: ColumnSpec, options?: ActionOptions): ActionSpec {
    return { ...options, kind: ActionKind.ShowToast, messageColumn };
}

interface PushScreenActionOptions {
    readonly target?: PageScreenTarget;
}

interface PushFormScreenActionSpec extends ActionOptions {
    readonly kind: ActionKind.FormScreen;
    readonly formName: string;
    readonly options: PushScreenActionOptions;
}

export function actionPushFormScreen(formName: string, options: PushScreenActionOptions = {}): ActionSpec {
    return { kind: ActionKind.FormScreen, formName, options };
}

interface PushEditScreenActionSpec extends ActionOptions {
    readonly kind: ActionKind.PushEditScreen;
    readonly options: PushScreenActionOptions;
}

export function actionPushEditScreen(options: PushScreenActionOptions = {}): ActionSpec {
    return { kind: ActionKind.PushEditScreen, options };
}

type ColumnSpec = string | SourceColumn;

interface IncrementActionSpec extends ActionOptions {
    readonly kind: ActionKind.Increment;
    readonly column: ColumnSpec;
    readonly row: ColumnSpec | undefined;
}

export function actionIncrement(column: ColumnSpec, row?: ColumnSpec): ActionSpec {
    return { kind: ActionKind.Increment, column, row };
}

interface AddRowActionSpec extends ActionOptions {
    readonly kind: ActionKind.AddRow;
    readonly table: TableSpec;
    readonly columnName: string;
}

export function actionAddRow(table: TableSpec, columnName: string): ActionSpec {
    return { kind: ActionKind.AddRow, table, columnName };
}

interface SetColumnOptions {
    readonly row?: ColumnSpec;
    readonly rhs?: ColumnSpec;
    readonly columnAssignments?: ColumnAssignment[];
}

export interface SetColumnActionSpec extends ActionOptions, SetColumnOptions {
    readonly kind: ActionKind.SetColumns;
    readonly columnName: string;
}

export function actionSetColumn(columnName: string, opts?: SetColumnOptions): ActionSpec {
    return { ...opts, kind: ActionKind.SetColumns, columnName };
}

interface WaitActionSpec extends ActionOptions {
    readonly kind: ActionKind.Wait;
    readonly durationSeconds: number;
    readonly message: string;
}

export function actionWait(durationSeconds: number, message: string): ActionSpec {
    return { kind: ActionKind.Wait, durationSeconds, message };
}

interface WaitForConditionActionSpec extends ActionOptions {
    readonly kind: ActionKind.WaitForCondition;
    readonly condition: ConditionSpec;
    readonly durationSeconds: number;
    readonly message: string;
}

export function actionWaitForCondition(condition: ConditionSpec, durationSeconds: number, message: string): ActionSpec {
    return { kind: ActionKind.WaitForCondition, condition, durationSeconds, message };
}

interface ActionRequestSignatureSpec extends ActionOptions {
    readonly kind: ActionKind.RequestSignature;
    readonly saveTo: ColumnSpec;
}

export function actionRequestSignature(saveTo: ColumnSpec): ActionSpec {
    return { kind: ActionKind.RequestSignature, saveTo };
}

interface ActionDeleteRowSpec extends ActionOptions {
    readonly kind: ActionKind.DeleteRow;
    // `undefined` means "this row", otherwise it means the whole `table`
    readonly table: TableSpec | undefined;
}

export function actionDeleteRow(table: TableSpec | undefined): ActionSpec {
    return { kind: ActionKind.DeleteRow, table };
}

interface ActionWebhookSpec extends ActionOptions {
    readonly kind: ActionKind.Webhook;
    readonly writeBackRow: SourceColumn;
    readonly writeBackColumn: string;
}

export function actionWebhook(writeBackRow: SourceColumn, writeBackColumn: string): ActionSpec {
    return { kind: ActionKind.Webhook, writeBackRow, writeBackColumn };
}

interface ActionPluginSpec extends ActionOptions {
    readonly kind: "plugin";
    readonly pluginID: string;
    readonly actionID: string;
    readonly params: Record<string, ColumnSpec>;
    readonly result?: Record<string, ColumnSpec>;
}

export function actionPlugin(
    pluginID: string,
    actionID: string,
    params: Record<string, ColumnSpec>,
    result: Record<string, ColumnSpec> = {}
): ActionSpec {
    return { kind: "plugin", pluginID, actionID, params, result };
}

interface ActionCompound extends ActionOptions {
    readonly kind: ActionKind.Compound;
    readonly actionID: string;
    readonly action: ActionSpec;
    readonly table: TableSpec;
}

export function actionCompound(actionID: string, action: ActionSpec, table: TableSpec): ActionSpec {
    return { kind: ActionKind.Compound, actionID, action, table };
}

interface ActionPushDetailScreen extends ActionOptions {
    readonly kind: ActionKind.PushDetailScreen;
    readonly options: PushScreenActionOptions;
}

export function actionPushDetailScreen(options: PushScreenActionOptions = {}): ActionSpec {
    return { kind: ActionKind.PushDetailScreen, options };
}

interface PushFreeScreenActionOptions extends PushScreenActionOptions {
    readonly column?: SourceColumn;
}

interface ActionPushFreeScreen extends ActionOptions {
    readonly kind: ActionKind.PushFreeScreen;
    readonly screenName: string;
    readonly options: PushFreeScreenActionOptions;
}

export function actionPushFreeScreen(screenName: string, options: PushFreeScreenActionOptions = {}): ActionSpec {
    return { kind: ActionKind.PushFreeScreen, screenName, options };
}

interface ActionCustom extends ActionOptions {
    readonly kind: "custom";
    readonly desc: ActionDescription;
}

export function actionCustom(desc: ActionDescription): ActionSpec {
    return { kind: "custom", desc };
}

export type ActionSpec =
    | ShowToastActionSpec
    | PushFormScreenActionSpec
    | PushEditScreenActionSpec
    | IncrementActionSpec
    | AddRowActionSpec
    | SetColumnActionSpec
    | WaitActionSpec
    | WaitForConditionActionSpec
    | ActionRequestSignatureSpec
    | ActionDeleteRowSpec
    | ActionWebhookSpec
    | ActionPluginSpec
    | ActionCompound
    | ActionPushDetailScreen
    | ActionPushFreeScreen
    | ActionCustom;

interface ButtonComponentSpec {
    readonly kind: "button";
    readonly action: ActionSpec;
    readonly title?: string;
}

interface TextComponentSpec {
    readonly kind: "text";
    readonly column: string;
    readonly notes?: string;
}

interface EmailComponentSpec {
    readonly kind: "email";
    readonly toColumn: string;
    readonly action: ActionSpec;
}

interface TextFieldComponentSpec {
    readonly kind: "text-field";
    readonly column: ColumnSpec;
    readonly defaultValue: string | undefined;
}

interface NumberFieldComponentSpec {
    readonly kind: "number-field";
    readonly column: ColumnSpec;
    readonly defaultValue: number | undefined;
}

interface CollectionOptions {
    readonly pageSize: number | undefined;
    readonly dynamicFilterColumn: string | undefined;
    readonly orientation?: UIOrientation;
    readonly cardStyle?: CardStyle;
    readonly image?: ColumnSpec;
    readonly transforms?: ArrayTransform[];
}

interface CollectionComponentSpec extends CollectionOptions {
    readonly kind: "collection";
    readonly source: ColumnSpec | TableSpec;
    readonly title: ColumnSpec;
    readonly action: ActionSpec;
}

interface ForEachComponentSpec {
    readonly kind: "for-each";
    readonly source: ColumnSpec | TableSpec;
    readonly component: ComponentSpec;
}

interface ContactFormComponentSpec {
    readonly kind: "contact-form";
    readonly table: TableSpec;
    readonly nameColumn: string;
    readonly assignedColumn: string;
    readonly onSubmitAction: ActionSpec;
}

interface ContainerComponentSpec {
    readonly kind: "container";
    readonly subComponents: readonly ComponentSpec[];
}

interface FormContainerComponentSpec {
    readonly kind: "form-container";
    readonly table: TableSpec;
    readonly subComponents: readonly ComponentSpec[];
    readonly assignedColumn: string | undefined;
    readonly onSubmitAction: ActionSpec | undefined;
}

interface ChoiceOptions {
    readonly isRequired: boolean;
    readonly isMulti: boolean;
}

interface ChoiceComponentSpec extends ChoiceOptions {
    readonly kind: ArrayScreenFormat.Choice;
    readonly column: string; // column to write to
    readonly valuesTable: TableSpec; // table with the options
    readonly valueColumn: ColumnSpec; // value column in `valuesTable`
    readonly defaultValue: string | undefined;
}

export interface CalendarOptions {
    readonly allowAdd: boolean;
    readonly allowEdit: boolean;
}

interface CalendarComponentSpec extends CalendarOptions {
    readonly kind: ArrayScreenFormat.CalendarCollection;
    readonly table: TableSpec;
    readonly defaultDateColumn: string;
    readonly titleColumn: string;
    readonly startColumn: string;
}

interface FavoriteComponentSpec {
    readonly kind: "favorite";
}

export interface CommentsOptions {
    readonly allowAdd: boolean;
}
interface CommentComponentSpec extends CollectionOptions, CommentsOptions {
    readonly kind: ArrayScreenFormat.Comments;
    readonly source: ColumnSpec | TableSpec;
    readonly comment: string;
    readonly timestamp: string;
    readonly userName: string;
    readonly userPhoto: string;
    readonly columnAssignments: ColumnAssignment[];
}

interface NewTableColumn extends Omit<SuperTableColumn & Partial<BaseChoiceColumnDescription>, "kind"> {
    kind: ConfigurableSuperTableColumnKindWithAuto;
}
interface NewTableComponentSpec extends CollectionOptions {
    readonly kind: ArrayScreenFormat.SuperTable;
    readonly source: ColumnSpec | TableSpec;
    readonly title: ColumnSpec;
    readonly columns: NewTableColumn[];
}

interface CustomComponentSpec {
    readonly kind: "custom";
    readonly desc: ComponentDescription;
}

export type ComponentSpec =
    | ButtonComponentSpec
    | TextComponentSpec
    | EmailComponentSpec
    | TextFieldComponentSpec
    | NumberFieldComponentSpec
    | CollectionComponentSpec
    | ForEachComponentSpec
    | ContactFormComponentSpec
    | ContainerComponentSpec
    | FormContainerComponentSpec
    | ChoiceComponentSpec
    | CalendarComponentSpec
    | FavoriteComponentSpec
    | CommentComponentSpec
    | CustomComponentSpec
    | NewTableComponentSpec;

export function componentButton(action: ActionSpec, title?: string): ComponentSpec {
    return { kind: "button", action, title };
}

export function componentText(column: string, notes?: string): ComponentSpec {
    return { kind: "text", column, notes };
}

export function componentEmail(toColumn: string, action: ActionSpec): ComponentSpec {
    return { kind: "email", toColumn, action };
}

export function componentTextField(column: ColumnSpec, defaultValue?: string): ComponentSpec {
    return { kind: "text-field", column, defaultValue };
}

export function componentNumberField(column: ColumnSpec, defaultValue?: number): ComponentSpec {
    return { kind: "number-field", column, defaultValue };
}

export function componentCollection(
    source: ColumnSpec | TableSpec,
    title: ColumnSpec,
    action: ActionSpec,
    options?: Partial<CollectionOptions>
): ComponentSpec {
    return {
        kind: "collection",
        source,
        title,
        action,
        dynamicFilterColumn: options?.dynamicFilterColumn ?? undefined,
        pageSize: options?.pageSize,
        orientation: options?.orientation,
        cardStyle: options?.cardStyle,
        image: options?.image,
    };
}

export function componentForEach(source: ColumnSpec | TableSpec, component: ComponentSpec): ComponentSpec {
    return { kind: "for-each", source, component };
}

export function componentContactForm(
    table: TableSpec,
    nameColumn: string,
    assignedColumnName: string,
    onSubmitAction: ActionSpec
): ComponentSpec {
    return { kind: "contact-form", table, nameColumn, assignedColumn: assignedColumnName, onSubmitAction };
}

export function componentContainer(subComponents: readonly ComponentSpec[]): ComponentSpec {
    return { kind: "container", subComponents };
}

export function componentFormContainer(
    table: TableSpec,
    subComponents: readonly ComponentSpec[],
    assignedColumn: string | undefined,
    onSubmitAction: ActionSpec | undefined
): ComponentSpec {
    return { kind: "form-container", table, subComponents, assignedColumn, onSubmitAction };
}

export function componentChoice(
    column: string,
    valuesTable: TableSpec,
    valueColumn: ColumnSpec,
    defaultValue: string | undefined,
    options?: Partial<ChoiceOptions>
): ComponentSpec {
    const { isRequired = false, isMulti = false } = options ?? {};
    return { kind: ArrayScreenFormat.Choice, column, valuesTable, valueColumn, defaultValue, isRequired, isMulti };
}

export function componentCalendar(
    table: TableSpec,
    defaultDateColumn: string,
    titleColumn: string,
    startColumn: string,
    options?: Partial<CalendarOptions>
): ComponentSpec {
    const { allowAdd = false, allowEdit = false } = options ?? {};
    return {
        kind: ArrayScreenFormat.CalendarCollection,
        table,
        defaultDateColumn,
        titleColumn,
        startColumn,
        allowAdd,
        allowEdit,
    };
}

export function componentFavorite(): ComponentSpec {
    return { kind: "favorite" };
}

export function componentComments(
    source: ColumnSpec | TableSpec,
    comment: string,
    timestamp: string,
    userName: string,
    userPhoto: string,
    columnAssignments: ColumnAssignment[],
    options?: Partial<CollectionOptions & CommentsOptions>
): ComponentSpec {
    return {
        kind: ArrayScreenFormat.Comments,
        source,
        dynamicFilterColumn: options?.dynamicFilterColumn ?? undefined,
        pageSize: options?.pageSize,
        orientation: options?.orientation,
        comment,
        timestamp,
        userName,
        userPhoto,
        columnAssignments,
        allowAdd: options?.allowAdd ?? true,
    };
}

export function componentCustom(desc: ComponentDescription): ComponentSpec {
    return {
        kind: "custom",
        desc,
    };
}

export function componentNewTable(
    source: ColumnSpec | TableSpec,
    title: ColumnSpec,
    columns: NewTableColumn[],
    options?: Partial<CollectionOptions>
): ComponentSpec {
    return {
        kind: ArrayScreenFormat.SuperTable,
        source,
        title,
        dynamicFilterColumn: options?.dynamicFilterColumn ?? undefined,
        pageSize: options?.pageSize,
        columns,
        transforms: options?.transforms ?? [],
    };
}

interface ColumnAssignmentSpec {
    readonly destColumn: string;
    readonly value: string;
}

interface ScreenSpec {
    readonly screenName: string;
    readonly tableName: string | TableName;
    readonly components: readonly ComponentSpec[];
    readonly columnAssignment?: ColumnAssignmentSpec;
    readonly onSubmit?: ActionSpec;
    // Only used in free screens
    readonly fetchesData: boolean;
    readonly notes?: string;
}

// NOTE: We're assuming here that the input and output tables are the same,
// but for more complex tests we'll have to support different ones.
export function screenForm(
    formName: string,
    table: TableSpec,
    components: readonly ComponentSpec[],
    onSubmit?: ActionSpec
): ScreenSpec {
    return {
        screenName: makeFormScreenName(formName),
        tableName: table.name,
        components,
        columnAssignment: undefined,
        onSubmit,
        fetchesData: false,
    };
}

export function makeDetailScreenName(detailName: string): string {
    return freeScreenPrefix + detailName;
}

export function screenDetail(
    detailName: string,
    table: TableSpec,
    components: readonly ComponentSpec[],
    fetchesData: boolean = true,
    notes?: string
): ScreenSpec {
    return {
        screenName: makeDetailScreenName(detailName),
        tableName: table.name,
        components,
        columnAssignment: undefined,
        onSubmit: undefined,
        fetchesData,
        notes,
    };
}

export function screenEdit(
    table: TableSpec,
    components: readonly ComponentSpec[],
    columnAssignment: ColumnAssignmentSpec | undefined,
    onSubmit?: ActionSpec
): ScreenSpec {
    return {
        screenName: editClassScreenName(makeTableName(table.name)),
        tableName: table.name,
        components,
        columnAssignment,
        onSubmit,
        fetchesData: false,
    };
}

interface FullTabSpec {
    readonly screenName: string;
    readonly hidden: boolean;
}

// screen name or full spec
type TabSpec = string | FullTabSpec;

export interface UserProfileSpec {
    readonly table: TableSpec;
    readonly emailColumnName: string;
    readonly nameColumnName: string;
    readonly imageColumnName: string;
    readonly rolesColumnName?: string;
}

export interface AppSpec {
    readonly tableSpecs: readonly TableSpec[];
    readonly tabSpecs: readonly TabSpec[];
    readonly screenSpecs: readonly ScreenSpec[];
    readonly userProfileSpec: UserProfileSpec | undefined;
    readonly pluginConfigs: readonly PluginConfig[] | undefined;
    readonly automationSpecs?: readonly ActionSpec[];
}

export function makeApp(
    tableSpecs: readonly TableSpec[],
    tabSpecs: readonly TabSpec[],
    screenSpecs: readonly ScreenSpec[],
    userProfileSpec: UserProfileSpec | undefined,
    pluginConfigs?: readonly PluginConfig[],
    automationSpecs?: readonly ActionSpec[]
): AppSpec {
    return {
        tableSpecs,
        tabSpecs,
        screenSpecs,
        userProfileSpec,
        pluginConfigs,
        automationSpecs,
    };
}

function makeSourceColumnFromSpec(column: ColumnSpec): SourceColumn {
    if (typeof column === "string") {
        return makeSourceColumn(column);
    } else {
        return column;
    }
}

function makeColumnAssignment(column: string): ColumnAssignment {
    return {
        destColumn: column,
        value: makeStringProperty("assigned"),
    };
}

interface MakeAppDescriptionContextOptions extends MakeSchemaOptions {
    readonly features?: Partial<AppFeatures>;
    readonly userFeatures?: Partial<UserFeatures>;
    readonly authentication?: AppAuthentication;
    readonly allowedEmailDomains?: readonly string[];
    readonly sourceMetadata?: readonly SourceMetadata[];
    readonly pluginTier?: PluginTier;
    readonly builderActions?: Map<string, BuilderAction>;
}

function makeColumnOrTableProperty(spec: ColumnSpec | TableSpec): PropertyDescription {
    if (hasOwnProperty(spec, "columns")) {
        return makeTableProperty(makeTableName(spec.name));
    } else {
        return makeSourceColumnProperty(makeSourceColumnFromSpec(spec));
    }
}

function makeCondition(spec: ConditionSpec): FilterArrayTransform {
    const conditions = isArray(spec) ? Array.from(spec) : [spec];
    assert(conditions.length > 0);

    let predicate: Formula | undefined;
    for (const c of [...conditions].reverse()) {
        const f = makeUnaryPredicateFormula({
            kind: "unary",
            operator: UnaryPredicateFormulaOperator.IsNotEmpty,
            column: makeSourceColumnFromSpec(c.column),
        });
        if (predicate === undefined) {
            predicate = f;
        } else {
            predicate = { kind: FormulaKind.And, left: f, right: predicate } as AndOrFormula;
        }
    }

    return {
        kind: ArrayTransformKind.Filter,
        predicate: defined(predicate),
        isActive: true,
    };
}

function makeNewTableColumnDescription(column: NewTableColumn): SuperTableColumn {
    const description: any = {
        value: makeColumnProperty(column.columnName),
        header: makeStringProperty(column.header),
        kind: makeEnumProperty(column.kind),
        sizeKind: makeEnumProperty(column.sizeOptions.sizeKind),
    };

    if (column.sizeOptions.sizeKind === "fixed") {
        description.widthSize = makeEnumProperty(column.sizeOptions.size);
    }

    if (column.kind === "choice") {
        description.choiceValues = column.choiceValues;
        description.choiceSource = column.choiceSource;
    }

    return description;
}

export const mockAppID = "APP-ID";

export function makeAction(spec: ActionSpec, builderActions: Map<string, BuilderAction>): ActionDescription {
    const condition = definedMap(spec.condition, makeCondition);
    switch (spec.kind) {
        case ActionKind.ShowToast:
            return {
                kind: ActionKind.ShowToast,
                message: makeSourceColumnProperty(makeSourceColumnFromSpec(spec.messageColumn)),
                condition,
            } as ActionDescription;
        case ActionKind.PushDetailScreen:
            return {
                kind: ActionKind.PushDetailScreen,
                navigationTarget: definedMap(spec.options.target, makeEnumProperty),
            } as ActionDescription;
        case ActionKind.FormScreen:
            return {
                kind: ActionKind.FormScreen,
                formScreenName: makeFormScreenName(spec.formName),
                condition,
                navigationTarget: definedMap(spec.options.target, makeEnumProperty),
            } as ActionDescription;
        case ActionKind.PushFreeScreen:
            return {
                kind: ActionKind.PushFreeScreen,
                screenName: makeDetailScreenName(spec.screenName),
                sourceColumn: definedMap(spec.options.column, makeSourceColumnProperty),
                condition,
                navigationTarget: definedMap(spec.options.target, makeEnumProperty),
            } as ActionDescription;
        case ActionKind.PushEditScreen:
            return {
                kind: ActionKind.PushEditScreen,
                navigationTarget: definedMap(spec.options.target, makeEnumProperty),
            } as ActionDescription;
        case ActionKind.Increment:
            return {
                kind: ActionKind.Increment,
                sourceColumn: makeSourceColumnProperty(makeSourceColumnFromSpec(spec.column)),
                amount: makeNumberProperty(1),
                outputRow: definedMap(spec.row, r => makeSourceColumnProperty(makeSourceColumnFromSpec(r))),
                condition,
            } as ActionDescription;
        case ActionKind.AddRow:
            return {
                kind: ActionKind.AddRow,
                tableName: makeTableProperty(makeTableName(spec.table.name)),
                columnAssignments: [
                    {
                        destColumn: spec.columnName,
                        value: makeNumberProperty(123),
                    },
                ],
            } as ActionDescription;
        case ActionKind.SetColumns:
            return {
                kind: ActionKind.SetColumns,
                columnAssignments: isDefined(spec.columnAssignments)
                    ? spec.columnAssignments
                    : [
                          {
                              destColumn: spec.columnName,
                              value:
                                  definedMap(spec.rhs, r => makeSourceColumnProperty(makeSourceColumnFromSpec(r))) ??
                                  makeNumberProperty(123),
                          },
                      ],
                outputRow: definedMap(spec.row, r => makeSourceColumnProperty(makeSourceColumnFromSpec(r))),
                condition,
            } as ActionDescription;
        case ActionKind.Wait:
            return {
                kind: ActionKind.Wait,
                durationSeconds: makeNumberProperty(spec.durationSeconds),
                message: makeStringProperty(spec.message),
            } as ActionDescription;
        case ActionKind.WaitForCondition:
            return {
                kind: ActionKind.WaitForCondition,
                waitCondition: [makeCondition(spec.condition)],
                durationSeconds: makeNumberProperty(spec.durationSeconds),
                message: makeStringProperty(spec.message),
                failMessage: makeStringProperty("Wait error"),
            } as ActionDescription;
        case ActionKind.DeleteRow:
            return {
                kind: ActionKind.DeleteRow,
                rowToDelete: definedMap(spec.table, t => makeTableProperty(makeTableName(t.name))),
                condition,
            } as ActionDescription;
        case ActionKind.Webhook:
            return {
                kind: ActionKind.Webhook,
                webhook: makeWebhookProperty("WEBHOOK"),
                namesAndValues: makeArrayProperty([]),
                outputRow: makeSourceColumnProperty(spec.writeBackRow),
                resultColumns: makeArrayProperty([
                    {
                        name: makeStringProperty("result"),
                        column: makeColumnProperty(spec.writeBackColumn),
                    },
                ]),
                condition,
            } as ActionDescription;
        case ActionKind.RequestSignature:
            return {
                kind: ActionKind.RequestSignature,
                saveTo: makeSourceColumnProperty(makeSourceColumnFromSpec(spec.saveTo)),
            } as ActionDescription;
        case ActionKind.Compound:
            builderActions.set(spec.actionID, {
                action: {
                    kind: ActionNodeKind.Conditional,
                    key: "root",
                    conditionalFlows: [],
                    elseFlow: {
                        kind: ActionNodeKind.Flow,
                        key: "else",
                        actions: [
                            {
                                kind: ActionNodeKind.Primitive,
                                key: "primitive",
                                actionDescription: makeAction(spec.action, builderActions),
                            },
                        ],
                    },
                },
                appIDs: [mockAppID],
                perApp: {
                    [mockAppID]: {
                        tableName: makeTableName(spec.table.name),
                    },
                },
                hasAutomation: false,
            });
            return {
                kind: ActionKind.Compound,
                actionID: makeCompoundActionProperty(spec.actionID),
                condition,
            } as ActionDescription;
        case "custom":
            return { condition, ...spec.desc };
        case "plugin":
            return {
                kind: makePluginActionKindFromIDs(spec.pluginID, spec.actionID),
                ...fromPairs(
                    Object.entries(spec.params).map(([k, v]) => [
                        `param_${k}`,
                        makeSourceColumnProperty(makeSourceColumnFromSpec(v)),
                    ])
                ),
                ...fromPairs(
                    Object.entries(spec.result ?? {}).map(([k, v]) => [
                        `result_${k}`,
                        makeSourceColumnProperty(makeSourceColumnFromSpec(v)),
                    ])
                ),
                condition,
            } as ActionDescription;

        default:
            return assertNever(spec);
    }
}

function makeComponent(
    spec: ComponentSpec,
    appKind: AppKind,
    builderActions: Map<string, BuilderAction>
): ComponentDescription {
    switch (spec.kind) {
        case "button":
            if (appKind === AppKind.Page) {
                return {
                    ...makeEmptyComponentDescription(WireComponentKind.ButtonsBlock),
                    buttons: makeArrayProperty([
                        {
                            title: makeStringProperty(spec.title ?? "Go"),
                            action: makeActionProperty(makeAction(spec.action, builderActions)),
                        },
                    ]),
                } as ComponentDescription;
            } else {
                return {
                    ...makeEmptyComponentDescription(ComponentKindButton),
                    actions: makeActionProperty(makeAction(spec.action, builderActions)),
                } as ComponentDescription;
            }
        case "text":
            return {
                ...makeEmptyComponentDescription(WireComponentKind.Text),
                text: makeColumnProperty(spec.column),
                notes: makeStringProperty(spec.notes),
            } as ComponentDescription;
        case "email":
            return {
                ...makeEmptyComponentDescription(ComponentKindEmail),
                propertyName: makeColumnProperty(spec.toColumn),
                actions: makeActionProperty(makeAction(spec.action, builderActions)),
            } as ComponentDescription;
        case "text-field":
            return {
                ...makeEmptyComponentDescription(WireComponentKind.TextField),
                propertyName: makeSourceColumnProperty(makeSourceColumnFromSpec(spec.column)),
                defaultValue: definedMap(spec.defaultValue, makeStringProperty),
            } as ComponentDescription;
        case "number-field":
            return {
                ...makeEmptyComponentDescription(WireComponentKind.NumberField),
                propertyName: makeSourceColumnProperty(makeSourceColumnFromSpec(spec.column)),
                defaultValue: definedMap(spec.defaultValue, makeNumberProperty),
            } as ComponentDescription;
        case "collection": {
            return {
                ...makeEmptyComponentDescription(ComponentKindInlineList),
                format: makeEnumProperty(ArrayScreenFormat.CardCollection),
                propertyName: makeColumnOrTableProperty(spec.source),
                cardStyle: makeEnumProperty(spec.cardStyle ?? CardStyle.Table),
                title: makeSourceColumnProperty(makeSourceColumnFromSpec(spec.title)),
                image: definedMap(spec.image, i => makeSourceColumnProperty(makeSourceColumnFromSpec(i))),
                action: makeActionProperty(makeAction(spec.action, builderActions)),
                allowSearch: makeSwitchProperty(true),
                pageSize: definedMap(spec.pageSize, makeNumberProperty),
                dynamicFilterColumn: definedMap(spec.dynamicFilterColumn, makeColumnProperty),
                orientation: definedMap(spec.orientation, makeEnumProperty),
            } as ComponentDescription;
        }
        case "for-each":
            return {
                ...makeEmptyComponentDescription(ComponentKindInlineList),
                format: makeEnumProperty(ArrayScreenFormat.ForEachContainer),
                propertyName: makeColumnOrTableProperty(spec.source),
                components: [makeComponent(spec.component, appKind, builderActions)],
            } as ComponentDescription;
        case "contact-form":
            return {
                ...makeEmptyComponentDescription(WireComponentKind.ContactForm),
                targetTable: makeTableProperty(makeTableName(spec.table.name)),
                formName: makeColumnProperty(spec.nameColumn),
                columnAssignments: [makeColumnAssignment(spec.assignedColumn)],
                onSubmitAction: makeActionProperty(makeAction(spec.onSubmitAction, builderActions)),
            } as ComponentDescription;
        case "container":
            return {
                ...makeEmptyComponentDescription(WireComponentKind.Container),
                components: spec.subComponents.map(s => makeComponent(s, appKind, builderActions)),
            } as ComponentDescription;
        case "form-container":
            return {
                ...makeEmptyComponentDescription(WireComponentKind.FormContainer),
                targetTable: makeTableProperty(makeTableName(spec.table.name)),
                components: spec.subComponents.map(s => makeComponent(s, appKind, builderActions)),
                columnAssignments: definedMap(spec.assignedColumn, c => [makeColumnAssignment(c)]) ?? [],
                onSubmitAction: definedMap(spec.onSubmitAction, a => makeActionProperty(makeAction(a, builderActions))),
            } as ComponentDescription;
        case ArrayScreenFormat.Choice:
            return {
                ...makeEmptyComponentDescription(ComponentKindInlineList),
                format: makeEnumProperty(ArrayScreenFormat.Choice),
                propertyName: makeTableProperty(makeTableName(spec.valuesTable.name)),
                valueProperty: makeColumnProperty(spec.column),
                optionProperty: makeSourceColumnProperty(makeSourceColumnFromSpec(spec.valueColumn)),
                defaultValue: makeEnumProperty(spec.defaultValue),
                isRequired: makeSwitchProperty(spec.isRequired),
                isMulti: makeSwitchProperty(spec.isMulti),
            } as ComponentDescription;
        case ArrayScreenFormat.CalendarCollection:
            return {
                ...makeEmptyComponentDescription(ComponentKindInlineList),
                format: makeEnumProperty(ArrayScreenFormat.CalendarCollection),
                propertyName: makeTableProperty(makeTableName(spec.table.name)),
                defaultDate: makeColumnProperty(spec.defaultDateColumn),
                title: makeColumnProperty(spec.titleColumn),
                startDate: makeColumnProperty(spec.startColumn),
                allowAdd: makeSwitchProperty(spec.allowAdd),
                allowEdit: makeSwitchProperty(spec.allowEdit),
            } as ComponentDescription;
        case "favorite":
            return makeEmptyComponentDescription(ComponentKindFavorite);
        case ArrayScreenFormat.Comments:
            return {
                ...makeEmptyComponentDescription(ComponentKindInlineList),
                format: makeEnumProperty(ArrayScreenFormat.Comments),
                title: makeStringProperty("Comments"),
                saveComment: makeColumnProperty(spec.comment),
                comment: makeColumnProperty(spec.comment),
                timestamp: makeColumnProperty(spec.timestamp),
                userName: makeColumnProperty(spec.userName),
                userPhoto: makeColumnProperty(spec.userPhoto),
                itemActions: makeArrayProperty([]),
                propertyName: makeColumnOrTableProperty(spec.source),
                allowSearch: makeSwitchProperty(true),
                dynamicFilterColumn: undefined,
                emptyMessage: makeStringProperty("No comments yet. Write the first comment."),
                columnAssignments: spec.columnAssignments,
                allowAdd: makeSwitchProperty(spec.allowAdd),
            } as ComponentDescription;
        case ArrayScreenFormat.SuperTable:
            return {
                ...makeEmptyComponentDescription(ComponentKindInlineList),
                format: makeEnumProperty(ArrayScreenFormat.SuperTable),
                title: makeStringProperty("New Table"),
                columns: makeArrayProperty(spec.columns.map(makeNewTableColumnDescription)),
                propertyName: makeColumnOrTableProperty(spec.source),
                transforms: spec.transforms ?? [],
            } as ComponentDescription;
        case "custom":
            return spec.desc;
        default:
            return assertNever(spec);
    }
}

export function makeScreenDescription(
    screenSpec: ScreenSpec,
    appKind: AppKind,
    builderActions: Map<string, BuilderAction>
): ScreenDescription {
    const { screenName, columnAssignment, onSubmit } = screenSpec;
    const inputType = makeTableRef(makeTableName(screenSpec.tableName));
    const components = screenSpec.components.map(s => makeComponent(s, appKind, builderActions));
    let screenDescription: ClassScreenDescription;
    if (isFreeScreenName(screenName)) {
        screenDescription = {
            kind: ScreenDescriptionKind.Class,
            isForm: false,
            type: inputType,
            fetchesData: screenSpec.fetchesData,
            title: undefined,
            components,
        };
    } else if (isFormScreenName(screenName)) {
        assert(!screenSpec.fetchesData);
        const screen: FormScreenDescription = {
            kind: ScreenDescriptionKind.Class,
            isForm: true,
            type: inputType,
            formType: inputType,
            fetchesData: screenSpec.fetchesData,
            title: undefined,
            components,
            onSubmitActions: definedMap(onSubmit, a => [makeAction(a, builderActions)]),
        };
        screenDescription = screen;
    } else if (isEditClassScreenName(screenName)) {
        assert(!screenSpec.fetchesData);
        const screen: EditScreenDescription = {
            kind: ScreenDescriptionKind.Class,
            isForm: false,
            type: inputType,
            fetchesData: screenSpec.fetchesData,
            title: undefined,
            components,
            onSubmitActions: definedMap(onSubmit, a => [makeAction(a, builderActions)]),
            columnAssignments: definedMap(columnAssignment, ca => [
                { destColumn: ca.destColumn, value: makeStringProperty(ca.value) },
            ]),
        };
        screenDescription = screen;
    } else {
        return panic("We don't support other screens yet");
    }

    if (screenSpec.notes !== undefined) {
        screenDescription = { ...screenDescription, notes: makeStringProperty(screenSpec.notes) };
    }

    return screenDescription;
}

export function makeAppDescriptionContext(
    appSpec: AppSpec,
    opts?: MakeAppDescriptionContextOptions
): ExistingAppDescriptionContext {
    const appKind = opts?.features?.appKind ?? AppKind.Page;
    const builderActions = opts?.builderActions ?? new Map<string, BuilderAction>();

    const screenDescriptions = fromPairs(
        appSpec.screenSpecs.map(s => [s.screenName, makeScreenDescription(s, appKind, builderActions)])
    );

    if (appSpec.automationSpecs !== undefined) {
        for (const [num, automation] of iterableEnumerate(appSpec.automationSpecs)) {
            const action = makeAction(automation, builderActions);
            builderActions.set(`automation-${automation.kind}-${num}`, {
                action: {
                    kind: ActionNodeKind.AutomationRoot,
                    key: "root",
                    inputs: [],
                    flow: {
                        kind: ActionNodeKind.Flow,
                        key: "else",
                        actions: [
                            {
                                kind: ActionNodeKind.Primitive,
                                key: "primitive",
                                actionDescription: action,
                            },
                        ],
                    },
                },
                appIDs: [mockAppID],
                perApp: {
                    [mockAppID]: {
                        tableName: makeTableName(appSpec.tableSpecs[0].name),
                        automation: {
                            filter: {
                                kind: ArrayTransformKind.Filter,
                                isActive: true,
                                predicate: {
                                    kind: FormulaKind.Empty,
                                },
                            },
                            limit: 123,
                            triggers: [],
                        },
                    },
                },
                hasAutomation: true,
            });
        }
    }

    const makeUserProfileDescription = (spec: UserProfileSpec): UserProfileDescription => {
        return {
            userProfileTable: makeTableProperty(makeTableName(spec.table.name)),
            emailColumn: makeColumnProperty(spec.emailColumnName),
            nameColumn: makeColumnProperty(spec.nameColumnName),
            imageColumn: makeColumnProperty(spec.imageColumnName),
            allowImageUpload: makeSwitchProperty(false),
            rolesColumn: undefined,
        };
    };

    let sourceMetadataArray: SourceMetadata[];
    if (opts?.sourceMetadata !== undefined) {
        sourceMetadataArray = [...opts.sourceMetadata];
    } else {
        sourceMetadataArray = [];
        for (const t of appSpec.tableSpecs) {
            if (t.nativeTableID !== undefined) {
                sourceMetadataArray.push({
                    type: "Native table",
                    id: t.nativeTableID,
                    tableName: makeTableName(t.name),
                });
            } else if (!sourceMetadataArray.some(s => s.type === "Google Sheet")) {
                sourceMetadataArray.push({
                    type: "Google Sheet",
                    id: "SHEET-ID",
                    title: "Gogol Sheet",
                });
            }
        }
    }

    const appDescription: AppDescription = {
        title: "dummy",
        theme: {
            primaryAccentColor: "pink",
            showTabLabels: false,
        },
        features: {
            appKind,
            ...opts?.features,
        },
        screenDescriptions,
        tabs: appSpec.tabSpecs.map(tabSpec => {
            let screenName: string;
            let hidden: boolean;
            if (typeof tabSpec === "string") {
                screenName = tabSpec;
                hidden = false;
            } else {
                screenName = tabSpec.screenName;
                hidden = tabSpec.hidden;
            }
            return {
                icon: "dummy",
                hidden,
                screenName: makeScreenProperty(screenName),
            };
        }),
        sourceMetadataArray,
        userProfile: definedMap(appSpec.userProfileSpec, makeUserProfileDescription),
        pluginConfigs: appSpec.pluginConfigs,
        authentication: opts?.authentication,
        allowedEmailDomains: opts?.allowedEmailDomains,
    };
    const schema = makeSchema(appSpec.tableSpecs, opts);

    const fixedAppDescription = fixAppDescription(
        mockAppID,
        appDescription,
        builderActions,
        schema,
        unlimitedEminenceFlags,
        { fromBuilder: true }
    );
    const ccc = makeSimpleAppDescriptionContext(
        mockAppID,
        appKind,
        fixedAppDescription,
        builderActions,
        schema,
        {
            ...defaultUserFeatures,
            ...opts?.userFeatures,
        },
        { ...unlimitedEminenceFlags, pluginTier: opts?.pluginTier ?? "enterprise" }
    );
    return ccc;
}
