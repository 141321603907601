/* eslint-disable no-restricted-globals */

import { isUndefinedish, logError, logInfo } from "@glide/support";
// This is necessary to support iOS before 14; we need to use
// the EventTarget constructor which iOS 13 and below do not have.
import { DefaultMap, defined } from "@glideapps/ts-necessities";
import EventTarget from "event-target-shim";

interface AnxiousIDBOpenDBRequest extends IDBOpenDBRequest {
    _setError(e: DOMException): void;
    _setForwardee(f: IDBOpenDBRequest): void;
}

const baseTimeout = 500;
const timeoutScale = 1.4;
const retries = 7;

(self as any)._indexedDBOpenDatabases = new DefaultMap<string, IDBDatabase[]>(() => []);

function refIDBOpenDatabases() {
    return (self as any)._indexedDBOpenDatabases as DefaultMap<string, IDBDatabase[]>;
}

export function getIndexedDBOpenDatabase(name: string): IDBDatabase | undefined {
    const dbs = refIDBOpenDatabases();
    if (!dbs.has(name)) return undefined;

    return dbs.get(name)[0];
}

function addIndexedDBOpenDatabase(db: IDBDatabase) {
    refIDBOpenDatabases().get(db.name).push(db);
}

function removeIndexedDBOpenDatabase(db: IDBDatabase) {
    const prior = refIDBOpenDatabases().get(db.name);
    // Note that this is intentionally reference equality here.
    const after = prior.filter(d => d !== db);
    if (after.length === 0) {
        refIDBOpenDatabases().delete(db.name);
    } else {
        refIDBOpenDatabases().set(db.name, after);
    }
}

// Sometimes, we need to perform mitigations on newly-opened
// Indexed DB instances before we hand them off to consumers.
// Particularly, in consumers we don't control (looking at you, Firestore
// Web SDK: your Persistence Lease code is broken). This is all facilitated
// with "open hooks": Before consumers know that the Indexed DB database
// is opened (but importantly, _after_ upgradeneeded handlers are fired)
// we can perform any mitigations we need to perform.

type IndexedDBOpenHook = (db: IDBDatabase) => Promise<void>;
const openHooks = new Set<IndexedDBOpenHook>();

async function executeOpenHooks(db: IDBDatabase) {
    const targets = Array.from(openHooks);
    for (const target of targets) {
        try {
            await target(db);
        } catch (e: unknown) {
            logError("Exception while hooking open IndexedDB", e);
        }
    }
}

export function addIDBOpenHook(hook: IndexedDBOpenHook) {
    openHooks.add(hook);
}

function anxiousIDBOpenWithResponder<T extends (...argv: any[]) => IDBOpenDBRequest>(
    timeout: number,
    remainingRetries: number,
    respondTo: AnxiousIDBOpenDBRequest,
    factory: IDBFactory,
    opener: T,
    args: any[]
) {
    let gaveUp: boolean = false;
    const internal = opener.apply(factory, args);
    respondTo._setForwardee(internal);

    const retryTimeout = setTimeout(() => {
        // We're actually done, not pending. Here's hoping Safari isn't so buggy
        // that it doesn't fire events when the readyState is "done".
        // Note that we check whether we've given up so that injected faults
        // won't cause a deadlock; fault injection also sets gaveUp to true.
        if (!gaveUp && internal.readyState === "done") return;

        gaveUp = true;
        if (remainingRetries > 0) {
            anxiousIDBOpenWithResponder(timeout * timeoutScale, remainingRetries - 1, respondTo, factory, opener, args);
        } else {
            const error = new DOMException("Database connection failed", "UnknownError");
            respondTo._setError(error);
            respondTo.dispatchEvent(new Event("error"));
        }
    }, timeout);

    const handleEvent = (ev: Event) => {
        if (gaveUp) {
            gaveUp = true;
            if (!isUndefinedish(internal.result)) {
                logError("Closing valid IndexedDB connection because we gave up", remainingRetries);
                internal.result.close();
            }
            return;
        }
        clearTimeout(retryTimeout);
        if (ev.type === "success") {
            logInfo("Opened IndexedDB", internal.result.name);
            addIndexedDBOpenDatabase(internal.result);
            internal.result.addEventListener("close", () => {
                logInfo("Closed IndexedDB", internal.result.name);
                removeIndexedDBOpenDatabase(internal.result);
            });
        }
        const cloned =
            ev instanceof IDBVersionChangeEvent
                ? new IDBVersionChangeEvent(ev.type, { oldVersion: ev.oldVersion, newVersion: ev.newVersion })
                : new Event(ev.type);
        // Open hooks only run on "success", and only when there is a database to work with.
        // As a slight optimization, if we don't have any open hooks, we don't enqueue another
        // microtask, we just respond immediately.
        if (ev.type === "success" && openHooks.size > 0 && internal.result !== undefined) {
            // Note that if an open hook throws, we still tell consumers that the database is open.
            // So... make sure the open hook works.
            executeOpenHooks(internal.result).finally(() => respondTo.dispatchEvent(cloned));
        } else {
            respondTo.dispatchEvent(cloned);
        }
    };

    internal.onblocked = handleEvent;
    internal.onerror = handleEvent;
    internal.onsuccess = handleEvent;
    internal.onupgradeneeded = handleEvent;
}

// It's not possible to extend IDBOpenDBRequest; if you do so you incur
// several runtime type errors. However, it's possible to extend EventTarget.
// The only downside here is that IDBOpenDBRequest is not part of the inheritance
// chain, but who's checking anyway?
class InternalIDBOpenDBRequest extends EventTarget implements AnxiousIDBOpenDBRequest {
    public onblocked: ((this: IDBOpenDBRequest, ev: Event) => any) | null = null;
    public onupgradeneeded: ((this: IDBOpenDBRequest, ev: IDBVersionChangeEvent) => any) | null = null;
    public onerror: ((this: IDBRequest<IDBDatabase>, ev: Event) => any) | null = null;
    public onsuccess: ((this: IDBRequest<IDBDatabase>, ev: Event) => any) | null = null;

    constructor() {
        super();
        this.addEventListener("blocked", ev => this.onblocked?.(ev));
        this.addEventListener("upgradeneeded", ev => this.onupgradeneeded?.(ev as any));
        this.addEventListener("error", ev => this.onerror?.(ev));
        this.addEventListener("success", ev => this.onsuccess?.(ev));
    }

    private _explicitError: DOMException | undefined;
    private _forwardee: IDBOpenDBRequest | undefined;

    public get error(): DOMException | null {
        if (this._explicitError !== undefined) return this._explicitError;
        return defined(this._forwardee).error;
    }

    public get readyState(): "pending" | "done" {
        if (this._explicitError !== undefined) return "done";
        return defined(this._forwardee).readyState;
    }

    public get result(): any {
        if (this._explicitError !== undefined) return undefined;
        return defined(this._forwardee).result;
    }

    public get source(): any {
        if (this._explicitError !== undefined) return null;
        return defined(this._forwardee).source;
    }

    public get transaction(): any {
        if (this._explicitError !== undefined) return null;
        return defined(this._forwardee).transaction;
    }

    public _setError(e: DOMException) {
        this._explicitError = e;
    }

    public _setForwardee(f: IDBOpenDBRequest) {
        this._forwardee = f;
    }
}

function anxiousIDBOpenGeneric<T extends (...argv: any[]) => IDBOpenDBRequest>(
    factory: IDBFactory,
    opener: T,
    args: any[]
): IDBOpenDBRequest {
    const response = new InternalIDBOpenDBRequest();
    anxiousIDBOpenWithResponder(baseTimeout, retries, response, factory, opener, args);

    // idb uses instanceof checks to do nice things, but we would break those checks
    // if we didn't intentionally establish a proxy that lies about its inheritance chain.

    const boundAddEventListener = (
        type: string,
        listener: EventListenerOrEventListenerObject | null,
        options?: boolean | AddEventListenerOptions
    ) => {
        // Mind the as any here. We have to do this to trick this line
        // into compiling.
        return response.addEventListener(type, listener, options as any);
    };

    const boundDispatchEvent = (ev: Event) => {
        return response.dispatchEvent(ev as any);
    };

    const boundRemoveEventListener = (
        type: string,
        callback: EventListenerOrEventListenerObject | null,
        options?: EventListenerOptions | boolean
    ) => {
        return response.removeEventListener(type, callback, options as any);
    };

    const proxy = new Proxy(response as any, {
        get: (_, prop) => {
            // We have to explicitly trap the event listener methods to ensure
            // the call flows down into the actual response. Since those are all
            // native functions, the usual swizzling attempts don't work out.
            switch (prop.toString()) {
                case "addEventListener":
                    return boundAddEventListener;
                case "dispatchEvent":
                    return boundDispatchEvent;
                case "removeEventListener":
                    return boundRemoveEventListener;
            }
            const resp = (response as any)[prop];
            return resp;
        },
        getPrototypeOf: () => {
            // To make idb happy: we're an IDBOpenDBRequest.
            return IDBOpenDBRequest.prototype;
        },
    });

    return proxy;
}

interface IndexedDBFactoryContainer {
    indexedDB: IDBFactory | undefined;
}

const boundTargets: Map<IndexedDBFactoryContainer, (name: string, version: number | undefined) => IDBOpenDBRequest> =
    new Map();

// See https://bugs.webkit.org/show_bug.cgi?id=226547 for the motivation behind
// this. It is possible on certain versions of Safari for the initial connection
// request to hang indefinitely. However, a valid workaround is to make multiple
// connection requests: the later ones succeed where the eariler ones fail.
//
// It turns out we have other uses for this polyfill: we can now arbitrarily
// access vendor IndexedDB instances without having to reverse-engineer their
// schemas! We use this to debug the broken Firestore Persistence mutex implementation.
export function hookIndexedDBForUnreliableOpens(target: IndexedDBFactoryContainer) {
    if (target.indexedDB === undefined) return;
    if (boundTargets.has(target)) return;

    const origIDB = target.indexedDB;
    const origOpen = origIDB.open;
    const patchOpen = (name: string, version: number | undefined) =>
        anxiousIDBOpenGeneric(origIDB, origOpen, [name, version]);

    // Mobile Safari likes to drop any monkey-patching done to the
    // IDBFactory during early-load. This is extremely problematic, because
    // Mobile Safari is the whole reason we're doing this.
    //
    // Chrome won't let us replace indexedDB with a proxy so we'll have to live
    // with aggressively calling forceIndexedDBReplacementIfNecessary to fix
    // the Safari counter-fix.

    try {
        target.indexedDB.open = patchOpen;
        boundTargets.set(target, patchOpen);
    } catch {
        // Oh well.
    }
}

// Mobile Safari likes to replace .indexedDB.open with native code
// aggressively. We really cannot tolerate that.
export function forceIndexedDBReplacementIfNecessary(target: IndexedDBFactoryContainer) {
    if (target.indexedDB === undefined) return;

    const replacement = boundTargets.get(target);
    if (replacement === undefined) return;

    try {
        target.indexedDB.open = replacement;
    } catch {
        // Guess we aren't allowed to do this...
    }
}

export function isMissingObjectStoreError(e: unknown): e is DOMException {
    return e instanceof DOMException && e.name === "NotFoundError";
}
