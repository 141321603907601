import { isDefined, isUndefinedish } from "@glide/support";
import { ErrorBoundary } from "@glide/common";
import { lazilyLoadedComponent } from "@glide/common-core/dist/js/support/react-lazy-loading";
import type { WireSimpleVideoComponent } from "@glide/fluent-components/dist/js/fluent-components";
import * as React from "react";
import "twin.macro";
import { WiredAspectContainer } from "../wire-image/wire-image";
import type { WireRenderer } from "../wire-renderer";
import { ValueChangeSource } from "@glide/wire";

const VideoPlayer = lazilyLoadedComponent("video-player", () => import("../../components/video-player/video-player"));

export const WireSimpleVideo: WireRenderer<WireSimpleVideoComponent> = p => {
    const { video, aspectRatio, viewedPercentage, showControls, backend } = p;
    if (!isDefined(video)) return null;
    return (
        <WiredAspectContainer {...p} aspectRatio={aspectRatio}>
            <ErrorBoundary>
                <React.Suspense fallback={<div />}>
                    <VideoPlayer
                        url={video}
                        tw="absolute! inset-0! w-full! h-full! object-cover!"
                        showControls={showControls}
                        onProgress={newVal => {
                            const token = viewedPercentage?.onChangeToken;
                            if (!isUndefinedish(token)) {
                                backend.valueChanged(token, newVal, ValueChangeSource.Internal);
                            }
                        }}
                    />
                </React.Suspense>
            </ErrorBoundary>
        </WiredAspectContainer>
    );
};
