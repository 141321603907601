import type { Logger, MetadataContext, Span } from "@glide/observability";
import { exceptionToString, defined } from "@glideapps/ts-necessities";
import type * as functions from "firebase-functions";
import { ResultStatus } from "./result-status";

export interface ErrorInfoOptions {
    readonly exception: unknown;
    // This will be logged and traced.
    readonly metadata: MetadataContext;
    // This is not logged or traced, it's just for passing more information
    // about the error.
    readonly extraData: any;
}

export class ErrorInfo {
    private readonly exception: unknown;
    private readonly stack: string | undefined;
    private readonly metadata: MetadataContext | undefined;
    public readonly extraData: any;

    constructor(public readonly status: number, public readonly message: string, opts: Partial<ErrorInfoOptions> = {}) {
        this.exception = opts.exception;
        if (opts.exception instanceof Error) {
            this.stack = opts.exception.stack;
        }
        if (this.stack === undefined) {
            this.stack = new Error().stack;
        }
        this.metadata = opts.metadata;
        this.extraData = opts.extraData;
    }

    public get isPermanent(): boolean {
        return this.status < 500;
    }

    public trace(span: Span): void {
        const { status, message, stack, exception } = this;
        span.addToContext("function.status_code", status);
        span.addToContext("function.message", message);
        if (stack !== undefined) {
            span.addToContext("function.stacktrace", stack);
        }
        if (exception !== undefined) {
            span.addToContext("function.exception", exceptionToString(exception));
        }
    }

    public send(span: Span | undefined, response: functions.Response, body?: any): void {
        const { status, message, stack, exception } = this;
        span?.addToGlobalContext("result.message", message);
        if (stack !== undefined) {
            span?.addToGlobalContext("result.stacktrace", stack);
        }
        if (exception !== undefined) {
            span?.addToGlobalContext("result.exception", exceptionToString(exception));
        }
        for (const [key, value] of Object.entries(this.metadata ?? {})) {
            span?.addToContext(`result.metadata.${key}`, value);
        }
        if (!response.headersSent) {
            response.status(status);
            // We're being conservative here and assuming that if a status was
            // sent, the body was also sent.
            response.send({ message, ...body });
        }
    }

    public log(logger: Logger, context?: MetadataContext, messagePrefix?: string): void {
        let message = this.message;
        if (messagePrefix !== undefined) {
            message = `${messagePrefix}: ${message}`;
        }
        logger.error(message, { status: this.status, stack: this.stack, ...this.metadata, ...context }, this.exception);
    }

    public logAndSend(ctx: { readonly log: Logger; readonly honeycombSpan: Span }, response: functions.Response): void {
        this.log(ctx.log, undefined);
        this.send(ctx.honeycombSpan, response);
    }
}

export type ErrorOr<T> = ErrorInfo | T;

export function respondWithError(span: Span | undefined, response: functions.Response, data: ErrorInfo): unknown;
export function respondWithError(
    span: Span | undefined,
    response: functions.Response,
    status: number,
    message: string
): unknown;
export function respondWithError(
    span: Span | undefined,
    response: functions.Response,
    second: ErrorInfo | number,
    third?: string
): unknown {
    let info: ErrorInfo;

    if (typeof second === "number") {
        info = new ErrorInfo(second, defined(third));
    } else {
        info = second;
    }

    return info.send(span, response);
}

export function isErrorInfo<T>(result: ErrorOr<T>): result is ErrorInfo {
    return result instanceof ErrorInfo;
}

export function respondWithErrorOrOK<T>(span: Span, response: functions.Response, result: ErrorOr<T>): unknown {
    if (isErrorInfo(result)) {
        return result.send(span, response);
    } else {
        if (!response.headersSent) {
            return response.sendStatus(200);
        }
        return;
    }
}

export function getResultStatusForErrorOr<T>(result: ErrorOr<T>): ResultStatus {
    return isErrorInfo(result) ? ResultStatus.Error : ResultStatus.OK;
}

interface ErrorInfoJSON {
    readonly status: number;
    readonly message: string;
}

export function getJSONForErrorOr<T>(result: ErrorOr<T>): T | ErrorInfoJSON;
export function getJSONForErrorOr<T, U>(result: ErrorOr<T>, f: (r: T) => U): U | ErrorInfoJSON;
export function getJSONForErrorOr<T, U>(result: ErrorOr<T>, f?: (r: T) => U): U | ErrorInfoJSON {
    if (isErrorInfo(result)) {
        return {
            status: result.status,
            message: result.message,
        };
    } else if (f !== undefined) {
        return f(result);
    } else {
        return result as unknown as U;
    }
}
