import * as glide from "@glide/plugins";

const DEFAULT_VERSION = "5.2.1";

/**
 * Validates that a version string follows semantic versioning format (x.y.z)
 * and contains only numbers and dots. This prevents potential JavaScript injection
 * attacks through the version parameter when constructing the script URL.
 */
function isValidVersion(version: string): boolean {
    const semverRegex = /^\d+\.\d+\.\d+$/;
    return semverRegex.test(version);
}

const versionParameter = glide.makeParameter({
    type: "string",
    name: "iFrame Resizer Version",
    description: "The version of iframe-resizer to use (must be in format x.y.z)",
    defaultValue: DEFAULT_VERSION,
    placeholder: `e.g. ${DEFAULT_VERSION}`,
});

const offsetSizeParameter = glide.makeParameter({
    type: "number",
    name: "Offset Size",
    description:
        "Modify the computed size of the iframe. Can be positive or negative. Set to 0 to disable in parent page.",
    emptyByDefault: true,
});

const sizeSelectorParameter = glide.makeParameter({
    type: "string",
    name: "Size Selector",
    description: "CSS Selector to specify which element should be used to calculate the content size in the iframe.",
    placeholder: "e.g. #content-wrapper",
    emptyByDefault: true,
});

const targetOriginParameter = glide.makeParameter({
    type: "string",
    name: "Target Origin",
    description: "Restrict the domain of the parent page. Use '*' to allow any domain.",
    defaultValue: "*",
    placeholder: "e.g. https://example.com",
});

export const plugin = glide.newPlugin({
    id: "iframe-embed",
    name: "iFrame Embedding",
    description: "Allows embedding iframes into your glide app",
    tier: "business",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/glide.png",
    isExperimental: true,
    experimentFlag: "iframeEmbedding",
    parameters: {
        version: versionParameter,
        offsetSize: offsetSizeParameter,
        sizeSelector: sizeSelectorParameter,
        targetOrigin: targetOriginParameter,
    },
});

plugin.addHeader(({ version, offsetSize, sizeSelector, targetOrigin }) => {
    if (!isValidVersion(version)) {
        return `<script>console.error("Invalid iframe-resizer version: ${version}. Version must be in format x.y.z (e.g. ${DEFAULT_VERSION})");</script>`;
    }

    const config = {
        ...(offsetSize !== undefined && { offsetSize }),
        ...(sizeSelector !== undefined && { sizeSelector }),
        ...(targetOrigin !== undefined && { targetOrigin }),
    };
    const configString =
        Object.keys(config).length > 0 ? `<script>window.iFrameResizer = ${JSON.stringify(config)};</script>` : "";
    return `${configString}
<script rel="preload" src="https://cdn.jsdelivr.net/npm/@iframe-resizer/child@${version}"></script>`;
});
