import * as React from "react";
import { type WireAction, type WireAlwaysEditableValue, ValueChangeSource } from "@glide/wire";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import type { WireCommentsComponent } from "@glide/fluent-components/dist/js/base-components";
import { useComments } from "./use-comments";
import { isDefined } from "@glide/support";

import "twin.macro";
import { GlideIcon } from "@glide/common";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { useIsCommentScreen } from "../../../chrome/content/lib/screen-special-cases";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";

interface ChatInputProps {
    readonly newComment: WireAlwaysEditableValue<string>;
    readonly postNewComment: WireAction;
    readonly backend: WireBackendInterface;
    readonly loggedUser: WireCommentsComponent["loggedUser"];
    readonly textAreaActive: boolean;
    readonly setTextAreaActive: (active: boolean) => void;
    readonly allowAdd: boolean;
}

export const ChatInput: React.VFC<ChatInputProps> = p => {
    const { newComment, postNewComment, backend, loggedUser, textAreaActive, setTextAreaActive, allowAdd } = p;

    const formRef = React.useRef<HTMLFormElement>(null);
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);

    const { sendNewComment, submitOnEnter } = useComments(backend, postNewComment, formRef, newComment);

    const sendNewCommentAndResetHeight = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            sendNewComment(e);
            e.currentTarget.style.height = "";
            textareaRef.current?.focus();
            setTextAreaActive(true);
        },
        [sendNewComment, setTextAreaActive]
    );

    const hasText = newComment.value.length > 0;
    const needsToSignIn = !isDefined(loggedUser);
    const shouldHideForm = needsToSignIn || !allowAdd;

    const isCommentScreen = useIsCommentScreen();

    if (shouldHideForm) {
        return null;
    }

    return (
        <form
            className={isCommentScreen ? "chat-screen" : "regular-screen"}
            ref={formRef}
            onSubmit={sendNewCommentAndResetHeight}
            tw="[&.chat-screen]:(mb-4 mx-4) [&.regular-screen]:mt-4 max-h-80"
        >
            <div
                className={textAreaActive ? "active" : "inactive"}
                tw="flex bg-bg-front text-base [border-radius: 20px] min-h-[40px] h-full ring-1 ring-border-base page-hover:ring-2 [&.active]:(ring-2 ring-accent) transition-all"
            >
                <ChatTextArea
                    textareaRef={textareaRef}
                    newComment={newComment}
                    backend={backend}
                    setTextAreaActive={setTextAreaActive}
                    formRef={formRef}
                    submitOnEnter={submitOnEnter}
                />
                <button
                    className={hasText ? "with-text" : "without-text"}
                    disabled={!hasText}
                    data-testid="chat-submit-button"
                    tw="h-8 w-8 m-1 flex items-center justify-center text-text-xpale rounded-full transition-colors [&.with-text]:(text-white bg-accent)"
                >
                    <GlideIcon kind="stroke" icon="st-send" rotateDeg={45} iconSize={18} />
                </button>
            </div>
        </form>
    );
};

interface ChatTextAreaProps {
    readonly newComment: WireCommentsComponent["newComment"];
    readonly backend: WireBackendInterface;
    readonly setTextAreaActive: (active: boolean) => void;
    readonly formRef: React.RefObject<HTMLFormElement>;
    readonly submitOnEnter: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    readonly textareaRef: React.RefObject<HTMLTextAreaElement>;
}

const ChatTextArea: React.VFC<ChatTextAreaProps> = p => {
    const { newComment, backend, setTextAreaActive, formRef, submitOnEnter, textareaRef } = p;

    const { value, onChangeToken } = newComment;

    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const textarea = e.currentTarget;
            if (isDefined(formRef.current)) {
                formRef.current.style.height = "auto";
                formRef.current.style.height = textarea.scrollHeight + "px";
            }

            const content = textarea.value;
            // This allows the submit button to not be disabled when we start writing an empty input
            if (content.length === 1 && value === "") {
                backend.valueChanged(onChangeToken, content, ValueChangeSource.User);
            }

            // Cleaning up value if we had any and we delete it all
            if (content === "" && value.length > 0) {
                backend.valueChanged(onChangeToken, content, ValueChangeSource.User);
            }
        },
        [backend, formRef, onChangeToken, value]
    );

    React.useEffect(() => {
        if (isDefined(formRef.current) && isDefined(textareaRef.current)) {
            formRef.current.style.height = "auto";
            formRef.current.style.height = textareaRef.current.scrollHeight + "px";
        }
    }, [formRef, textareaRef]);

    const onBlur = React.useCallback(
        (e: React.FocusEvent<HTMLTextAreaElement>) => {
            setTextAreaActive(false);

            const textarea = e.currentTarget;
            const content = textarea.value;
            backend.valueChanged(onChangeToken, content, ValueChangeSource.User);
        },
        [backend, onChangeToken, setTextAreaActive]
    );

    const onFocus = React.useCallback(
        (_e: React.FocusEvent<HTMLTextAreaElement>) => {
            setTextAreaActive(true);
            // Sadly we sometimes focus the input _before_ getting new data,
            // so this 100ms delay makes this behavior more consistent.
            // We don't want to scrollIntoView whenever we get new data
            // because we could scroll the screen randomly and the user wouldn't know why.
            // Scrolling on focus makes this more intentional.
            setTimeout(() => {
                textareaRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 100);
        },
        [setTextAreaActive, textareaRef]
    );

    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    const onKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (isMobile && isPlayer()) return;
            submitOnEnter(e);
        },
        [isMobile, submitOnEnter]
    );

    return (
        <textarea
            ref={textareaRef}
            tw="outline-none bg-transparent grow resize-none box-border px-4 py-2 h-full text-base text-text-base"
            rows={1}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            placeholder={getLocalizedString("writeSomething", AppKind.Page)}
            defaultValue={value}
            name="comment-input"
            data-testid="comment-input"
        />
    );
};
