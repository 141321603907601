import { TextComponentStyle } from "@glide/component-utils";
import type { WireTextComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { UIAlignment } from "@glide/wire";
import { assertNever } from "@glideapps/ts-necessities";
import classNames from "classnames";
import * as React from "react";

import { css } from "@glide/common-components";
import { Text } from "../../components/text/text";
import { WireContainerSpacing } from "../wire-container/wire-container";
import type { WireRenderer } from "../wire-renderer";

function textStyleToComponent(style: TextComponentStyle) {
    switch (style) {
        case TextComponentStyle.headlineXLarge:
            return "h2";
        case TextComponentStyle.headlineLarge:
            return "h3";
        case TextComponentStyle.headlineMedium:
            return "h4";
        case TextComponentStyle.headlineSmall:
            return "h5";
        case TextComponentStyle.headlineXSmall:
        case TextComponentStyle.headlineXXSmall:
        case TextComponentStyle.headlineXXXSmall:
            return "h6";
        case TextComponentStyle.large:
        case TextComponentStyle.regular:
        case TextComponentStyle.small:
        case TextComponentStyle.metaText:
        case TextComponentStyle.footnote:
            return "p";
        default:
            assertNever(style, "Invalid text style");
    }
}

export function textStyleToContainerSpacing(style: TextComponentStyle) {
    switch (style) {
        case TextComponentStyle.headlineXLarge:
        case TextComponentStyle.headlineLarge:
        case TextComponentStyle.headlineMedium:
        case TextComponentStyle.headlineSmall:
        case TextComponentStyle.headlineXSmall:
        case TextComponentStyle.headlineXXSmall:
        case TextComponentStyle.headlineXXXSmall:
        case TextComponentStyle.large:
        case TextComponentStyle.regular:
        case TextComponentStyle.small:
        case TextComponentStyle.footnote:
            return WireContainerSpacing.small;

        case TextComponentStyle.metaText:
            return WireContainerSpacing.xsmall;

        default:
            assertNever(style, "Invalid text style");
    }
}

export const WireText: WireRenderer<WireTextComponent> = React.memo(props => {
    const className = classNames(`align-${props.align}`);

    const nativeComponent = textStyleToComponent(props.style);

    return (
        <Text
            data-testid="wire-text"
            element={nativeComponent}
            className={className}
            variant={props.style}
            tw="whitespace-pre-wrap break-words"
            css={css`
                // Alignments:

                &.align-${UIAlignment.Start} {
                    text-align: left;
                }

                &.align-${UIAlignment.Center} {
                    text-align: center;
                }

                &.align-${UIAlignment.End} {
                    text-align: right;
                }
            `}>
            {props.text}
        </Text>
    );
});
