import type { ComponentIndexes } from "@glide/common-core/dist/js/component-indexes";
import {
    type TableName,
    areTableNamesEqual,
    isFavoritedColumnName,
    type Description,
    type SourceColumn,
    type TableGlideType,
    getTableName,
} from "@glide/type-schema";
import {
    type ComponentDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    type ScreenDescription,
    PropertyKind,
    ScreenDescriptionKind,
    getInlineComputationProperty,
    getSourceColumnProperty,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import {
    type AppDescriptionContext,
    type ColumnPropertyDescriptorCase,
    type InlineComputationPropertyDescriptorCase,
    type PropertyDescriptor,
    type PropertyDescriptorCase,
    type SuperPropertySection,
    getMutatingKindForScreen,
    resolveSourceColumn,
} from "@glide/function-utils";
import { assertNever, DefaultMap, definedMap } from "@glideapps/ts-necessities";
import { ArrayMap, DefaultArrayMap } from "@glide/support";
import flatten from "lodash/flatten";
import { areComponentIndexesEqual } from "../description-utils";
import { handlerForComponentKind } from "../handlers";
import type { PriorStep } from "../prior-step";
import { handlerForPropertyKind } from "./description-handlers";
import { getInputOrOutputTableForProperty } from "./descriptor-utils";
import {
    type Location,
    type AppDescriptionVisitor,
    type WalkBuilderActionPriorStepsBuilder,
    type ColumnsUsedInTables,
    LocationKind,
} from "./walk-app-description";

export enum SearchResultKind {
    Column,
    Action,
    Component,
    ScreenColumnAssignment,
    Screen,
    Tab,
    UserProfile,
    FavoritesPivot,
    PluginConfig,
}

export interface SearchResultBase {
    readonly isWritten: boolean;
    readonly isAction?: boolean;
    readonly propertySection: SuperPropertySection | undefined;
}

interface ColumnSearchResult extends SearchResultBase {
    readonly kind: SearchResultKind.Column;

    readonly tableName: TableName;
    readonly columnName: string;

    readonly isWritten: false;
}

export interface ActionSearchResult extends SearchResultBase {
    readonly kind: SearchResultKind.Action;

    readonly actionID: string;
    readonly nodeKeys: readonly string[];

    readonly actionDisplayName: string | undefined;
}

export interface ScreenSearchResultBase extends SearchResultBase {
    readonly screenName: string;
    readonly mutatingScreenKind: MutatingScreenKind | undefined;
    // If this is present, the screen is the root screen of that tab.
    readonly tabIndex: number | undefined;
}

interface ScreenSearchResult extends ScreenSearchResultBase {
    readonly kind: SearchResultKind.Screen;

    readonly descriptorCase: ColumnPropertyDescriptorCase | undefined;
}

interface ComponentSearchResult extends ScreenSearchResultBase {
    readonly kind: SearchResultKind.Component;

    readonly componentID: string;
    readonly componentIndexes: ComponentIndexes;
    readonly componentDisplayName: string;
    readonly componentIsGlideManaged: boolean;
    readonly descriptorCase: ColumnPropertyDescriptorCase | undefined;
}

export interface ScreenColumnAssignmentSearchResult extends ScreenSearchResultBase {
    readonly kind: SearchResultKind.ScreenColumnAssignment;

    readonly tableName: TableName;
    readonly columnName: string;
    readonly assignmentIndex: number;

    readonly isWritten: boolean;
}

// Can only be a visibility condition at this point
interface TabSearchResult extends SearchResultBase {
    readonly kind: SearchResultKind.Tab;
    readonly index: number;

    readonly isWritten: false;
}

interface UserProfileSearchResult extends SearchResultBase {
    readonly kind: SearchResultKind.UserProfile;
    readonly boundTo: string;
}

interface FavoritesPivotSearchResult extends ScreenSearchResultBase {
    readonly kind: SearchResultKind.FavoritesPivot;
    readonly tableName: TableName;
}

interface PluginConfigSearchResult extends SearchResultBase {
    readonly kind: SearchResultKind.PluginConfig;
    readonly pluginConfigID: string;
}

export type SearchResult =
    | UserProfileSearchResult
    | ColumnSearchResult
    | ActionSearchResult
    | ScreenSearchResult
    | ComponentSearchResult
    | ScreenColumnAssignmentSearchResult
    | TabSearchResult
    | FavoritesPivotSearchResult
    | PluginConfigSearchResult;

function updateSearchResult<T extends SearchResultBase>(
    existing: T | undefined,
    incoming: T,
    updatesForIsWritten?: Partial<T>
): T {
    if (existing === undefined) return incoming;
    let propertySection: SuperPropertySection | undefined;
    if (!existing.isWritten && incoming.isWritten && incoming.propertySection !== undefined) {
        propertySection = incoming.propertySection;
    } else {
        propertySection = existing.propertySection ?? incoming.propertySection;
    }
    const isWritten = existing.isWritten || incoming.isWritten;
    return {
        ...existing,
        isWritten,
        propertySection,
        ...(!existing.isWritten && isWritten ? updatesForIsWritten : undefined),
    };
}

function doesPDCWrite(pdc: PropertyDescriptorCase | undefined): boolean {
    if (pdc?.kind !== PropertyKind.Column) return false;
    const isEditedInApp = (pdc as ColumnPropertyDescriptorCase).isEditedInApp;
    // FIXME: We have to actually check whether the column is writable
    return isEditedInApp === true || isEditedInApp === "if-writable" || isEditedInApp === "even-if-not-writable";
}

export class SearchResultCollector {
    private readonly columns = new DefaultArrayMap<TableName, Map<string, ColumnSearchResult>>(
        areTableNamesEqual,
        () => new Map()
    );
    private readonly actions = new Map<string, ActionSearchResult>();
    private readonly components = new DefaultMap<string, ArrayMap<ComponentIndexes, ComponentSearchResult>>(
        () => new ArrayMap<ComponentIndexes, ComponentSearchResult>(areComponentIndexesEqual)
    );
    private readonly screens = new Map<string, ScreenSearchResult>();
    private readonly screenColumnAssignments = new DefaultMap<string, Map<string, ScreenColumnAssignmentSearchResult>>(
        () => new Map()
    );
    private readonly tabs = new Map<number, TabSearchResult>();
    private userProfile: UserProfileSearchResult | undefined;
    // table name -> screen name -> result
    private readonly favoritePivots = new DefaultArrayMap<TableName, Map<string, FavoritesPivotSearchResult>>(
        areTableNamesEqual,
        () => new Map()
    );
    // plugin config ID -> result
    private readonly pluginConfigs = new Map<string, PluginConfigSearchResult>();

    public add(result: SearchResult): void {
        switch (result.kind) {
            case SearchResultKind.Column:
                this.columns
                    .get(result.tableName)
                    .set(
                        result.columnName,
                        updateSearchResult(this.columns.get(result.tableName).get(result.columnName), result)
                    );
                break;
            case SearchResultKind.Action:
                let existing = this.actions.get(result.actionID);
                if (existing !== undefined) {
                    existing = { ...existing, nodeKeys: [...existing.nodeKeys, ...result.nodeKeys] };
                }
                this.actions.set(result.actionID, updateSearchResult(existing, result));
                break;
            case SearchResultKind.Component:
                if (result.componentIsGlideManaged) return;
                this.components
                    .get(result.screenName)
                    .set(
                        result.componentIndexes,
                        updateSearchResult(this.components.get(result.screenName).get(result.componentIndexes), result)
                    );
                break;
            case SearchResultKind.Screen:
                this.screens.set(result.screenName, updateSearchResult(this.screens.get(result.screenName), result));
                break;
            case SearchResultKind.ScreenColumnAssignment:
                this.screenColumnAssignments
                    .get(result.screenName)
                    .set(
                        result.columnName,
                        updateSearchResult(
                            this.screenColumnAssignments.get(result.screenName).get(result.columnName),
                            result
                        )
                    );
                break;
            case SearchResultKind.Tab:
                this.tabs.set(result.index, updateSearchResult(this.tabs.get(result.index), result));
                break;
            case SearchResultKind.UserProfile:
                this.userProfile = updateSearchResult(this.userProfile, result, { boundTo: result.boundTo });
                break;
            case SearchResultKind.FavoritesPivot:
                this.favoritePivots
                    .get(result.tableName)
                    .set(
                        result.screenName,
                        updateSearchResult(this.favoritePivots.get(result.tableName).get(result.screenName), result)
                    );
                break;
            case SearchResultKind.PluginConfig:
                this.pluginConfigs.set(
                    result.pluginConfigID,
                    updateSearchResult(this.pluginConfigs.get(result.pluginConfigID), result)
                );
                break;
            default:
                return assertNever(result);
        }
    }

    public getResults(): readonly SearchResult[] {
        const columns = flatten(Array.from(this.columns.values()).map(m => Array.from(m.values())));
        const actions = Array.from(this.actions.values());
        const components = flatten(Array.from(this.components.values()).map(m => Array.from(m.values())));
        const screens = Array.from(this.screens.values());
        const screenColumnAssignments = flatten(
            Array.from(this.screenColumnAssignments.values()).map(m => Array.from(m.values()))
        );
        const tabs = Array.from(this.tabs.values());
        const favoritesPivots = flatten(Array.from(this.favoritePivots.values()).map(m => Array.from(m.values())));
        const pluginConfigs = Array.from(this.pluginConfigs.values());
        const results: SearchResult[] = [
            ...columns,
            ...actions,
            ...components,
            ...screens,
            ...screenColumnAssignments,
            ...tabs,
            ...favoritesPivots,
            ...pluginConfigs,
        ];
        if (this.userProfile !== undefined) {
            results.push(this.userProfile);
        }
        return results;
    }
}

export function makeSearchResultFromLocation(
    l: Location,
    section: SuperPropertySection | undefined,
    isWritten: boolean | undefined
): SearchResult {
    switch (l.kind) {
        case LocationKind.Tab:
            return { kind: SearchResultKind.Tab, index: l.index, isWritten: false, propertySection: undefined };
        case LocationKind.Screen:
            return {
                kind: SearchResultKind.Screen,
                screenName: l.screenName,
                mutatingScreenKind: l.mutatingScreenKind,
                tabIndex: l.tabIndex,
                isWritten: isWritten ?? doesPDCWrite(l.descriptorCase),
                propertySection: l.propertySection,
                descriptorCase:
                    l.descriptorCase?.kind === PropertyKind.Column
                        ? (l.descriptorCase as ColumnPropertyDescriptorCase)
                        : undefined,
            };
        case LocationKind.Component:
            return {
                kind: SearchResultKind.Component,
                screenName: l.screenName,
                mutatingScreenKind: l.mutatingScreenKind,
                tabIndex: l.tabIndex,
                componentID: l.componentID,
                componentIndexes: l.componentIndexes,
                componentDisplayName: l.componentDisplayName,
                componentIsGlideManaged: l.componentIsGlideManaged,
                descriptorCase:
                    l.descriptorCase?.kind === PropertyKind.Column
                        ? (l.descriptorCase as ColumnPropertyDescriptorCase)
                        : undefined,
                isWritten: isWritten ?? doesPDCWrite(l.descriptorCase),
                propertySection: section,
            };
        case LocationKind.ScreenColumnAssignment:
            return {
                kind: SearchResultKind.ScreenColumnAssignment,
                screenName: l.screenName,
                mutatingScreenKind: l.mutatingScreenKind,
                tabIndex: l.tabIndex,
                tableName: l.tableName,
                columnName: l.columnName,
                assignmentIndex: l.assignmentIndex,
                isWritten: isWritten ?? false,
                propertySection: undefined,
            };
        case LocationKind.Action:
            return {
                kind: SearchResultKind.Action,
                actionID: l.actionID,
                nodeKeys: [l.nodeKey],
                actionDisplayName: l.actionDisplayName,
                isWritten: isWritten ?? doesPDCWrite(l.descriptorCase),
                propertySection: section,
            };
        case LocationKind.UserProfile:
            return {
                kind: SearchResultKind.UserProfile,
                boundTo: l.name,
                isWritten: isWritten ?? doesPDCWrite(l.descriptorCase),
                propertySection: section,
            };
        case LocationKind.Column:
            return {
                kind: SearchResultKind.Column,
                tableName: l.tableName,
                columnName: l.columnName,
                isWritten: false,
                propertySection: undefined,
            };
        case LocationKind.PluginConfig:
            return {
                kind: SearchResultKind.PluginConfig,
                pluginConfigID: l.pluginConfigID,
                isWritten: false,
                propertySection: undefined,
            };
        default:
            return assertNever(l);
    }
}

// NOTE: This does not implement `visitColumnWritten`, so it won't catch
// those.  Subclasses need to do that themselves if they require those uses.
// It also doesn't visit formulas.
export abstract class ColumnsUsedVisitor<T = undefined> implements AppDescriptionVisitor<T> {
    constructor(protected readonly ccc: AppDescriptionContext) {}

    protected abstract addColumnUsed(tn: TableName, cn: string, searchResult: SearchResult): void;

    private addSourceColumnUsed(
        sourceColumn: SourceColumn,
        contextTableName: TableName | undefined,
        containingScreenTableName: TableName | undefined,
        searchResult: SearchResult,
        priorSteps: readonly PriorStep[] | undefined
    ): void {
        const { ccc: iccc } = this;

        const resolved = resolveSourceColumn(
            iccc,
            sourceColumn,
            iccc.findTable(contextTableName),
            iccc.findTable(containingScreenTableName),
            priorSteps?.map(s => s.node)
        )?.path;
        if (resolved === undefined) return;

        for (const { table: t, column: c } of resolved) {
            this.addColumnUsed(getTableName(t), c.name, searchResult);
        }
    }

    public visitComponent(
        desc: ComponentDescription,
        tables: InputOutputTables | undefined,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        location: Location
    ): void {
        if (tables === undefined) return;

        const handler = handlerForComponentKind(desc.kind);
        if (handler === undefined) return;

        const additionalReads = handler.getAdditionalColumnsRead(tables);
        for (const tac of additionalReads) {
            this.addColumnUsed(
                getTableName(tac.table),
                tac.column.name,
                makeSearchResultFromLocation(location, undefined, false)
            );
        }
    }

    public visitScreen(screenName: string, screen: ScreenDescription, tables: InputOutputTables | undefined): void {
        if (screen.kind !== ScreenDescriptionKind.Array) return;
        if (screen.pivots === undefined) return;
        if (tables === undefined) return;

        const tableName = getTableName(tables.input);
        this.addColumnUsed(tableName, isFavoritedColumnName, {
            kind: SearchResultKind.FavoritesPivot,
            screenName,
            mutatingScreenKind: getMutatingKindForScreen(screenName, screen),
            tableName,
            isWritten: false,
            propertySection: undefined,
            tabIndex: undefined,
        });
    }

    public visitProperty(
        location: Location,
        desc: PropertyDescription,
        propertyDescr: PropertyDescriptor | undefined,
        pdc: PropertyDescriptorCase | undefined,
        rootDesc: Description,
        containingDesc: Description,
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        priorSteps: readonly PriorStep[] | undefined
    ): void {
        const searchResult = makeSearchResultFromLocation(location, propertyDescr?.section, undefined);

        const sourceColumn = getSourceColumnProperty(desc);
        if (sourceColumn === undefined) {
            if (pdc === undefined || tables === undefined) return;
            if (pdc.kind === PropertyKind.CompoundAction || pdc.kind === PropertyKind.Action) return;

            if (pdc.kind === PropertyKind.InlineComputation) {
                // ##walkingInlineComputation:
                // We're doing something similar in walk-app-description. We should unify these.
                // fllowing walk-app-desciription comment.

                /**
                 * FIXME: When we make generic inline computations
                 * we should instead visit the entire computation table
                 * cause we might be referring to user profile columns,
                 * or a rollup, or something else
                 */

                const inlineComputation = getInlineComputationProperty(desc);

                if (inlineComputation === undefined) {
                    return;
                }

                const icpdc = pdc as InlineComputationPropertyDescriptorCase;
                // FIXME: We need to pass the action nodes in scope here, but we need to build them as we walk the app.
                // That's not trivial to do right now.
                const indirectTable = icpdc.getIndirectTable?.(tables, rootDesc, containingDesc, this.ccc, [])?.table;

                const contextTable =
                    indirectTable ??
                    definedMap(tables, t => getInputOrOutputTableForProperty(t, icpdc, mutatingScreenKind));

                const contextTableName = definedMap(contextTable, getTableName);
                const containingScreenTableName = definedMap(tables?.input, getTableName);

                for (const binding of inlineComputation.bindings) {
                    const [, col] = binding;
                    this.addSourceColumnUsed(
                        col,
                        contextTableName,
                        containingScreenTableName,
                        searchResult,
                        priorSteps
                    );
                }

                return;
            }

            const handler = handlerForPropertyKind(pdc.kind);
            const { editedColumns } = handler.getEditedColumns(
                pdc,
                desc,
                rootDesc,
                {
                    tables,
                    mutatingScreenKind,
                    context: this.ccc,
                    isAutomation: false,
                },
                true
            );
            for (const [cn, , , tn] of editedColumns) {
                this.addColumnUsed(tn, cn, searchResult);
            }
            return;
        }

        if (pdc !== undefined && pdc.kind !== PropertyKind.Column && pdc.kind !== PropertyKind.InlineComputation) {
            return;
        }

        const cpdc = pdc as ColumnPropertyDescriptorCase | InlineComputationPropertyDescriptorCase | undefined;

        let indirectTable: TableGlideType | undefined;
        if (cpdc?.getIndirectTable !== undefined) {
            if (tables === undefined) return;
            // FIXME: We need to pass the action nodes in scope here, but we need to build them as we walk the app.
            // That's not trivial to do right now.
            indirectTable = cpdc.getIndirectTable(tables, rootDesc, containingDesc, this.ccc, [])?.table;
        }

        const contextTable =
            indirectTable ?? definedMap(tables, t => getInputOrOutputTableForProperty(t, cpdc, mutatingScreenKind));

        this.addSourceColumnUsed(
            sourceColumn,
            definedMap(contextTable, getTableName),
            definedMap(tables?.input, getTableName),
            searchResult,
            priorSteps
        );
    }
}

export abstract class CollectingVisitor<T = undefined> extends ColumnsUsedVisitor<T> {
    constructor(
        ccc: AppDescriptionContext,
        public readonly collector: SearchResultCollector,
        readonly priorStepsBuilder: WalkBuilderActionPriorStepsBuilder | undefined
    ) {
        super(ccc);
    }

    // Will be called for every use of a column, should return whether to add
    // the search result for it.
    protected abstract shouldAddColumnUsed(tn: TableName, cn: string, isWritten: boolean): boolean;

    protected addColumnUsed(tn: TableName, cn: string, searchResult: SearchResult): void {
        if (!this.shouldAddColumnUsed(tn, cn, searchResult.isWritten)) return;

        this.collector.add(searchResult);
    }

    public addFromColumnsUsed(
        columnsUsed: ReadonlyMap<TableGlideType, ReadonlySet<string>>,
        searchResult: SearchResult
    ): boolean {
        let found = false;
        for (const [table, columns] of columnsUsed) {
            const tableName = getTableName(table);
            for (const column of columns) {
                if (this.shouldAddColumnUsed(tableName, column, searchResult.isWritten)) {
                    found = true;
                    // We don't break because we want to call
                    // `shouldAddColumnUsed` for all columns because the
                    // subclass that collects all used tables depends on that.
                }
            }
        }
        if (!found) return false;

        this.collector.add(searchResult);
        return true;
    }

    public visitColumnsUsed(
        location: Location,
        columnsUsed: ColumnsUsedInTables,
        propertyDescr: PropertyDescriptor | undefined
    ): void {
        this.addFromColumnsUsed(columnsUsed, makeSearchResultFromLocation(location, propertyDescr?.section, undefined));
    }

    public visitColumnWritten(location: Location, assignedToTableName: TableName, assignedToColumnName: string): void {
        if (!this.shouldAddColumnUsed(assignedToTableName, assignedToColumnName, true)) return;

        this.collector.add(makeSearchResultFromLocation(location, undefined, true));
    }
}
