import type { BasePrimitiveValue, GlideDateTimeDocumentData, GlideJSONDocumentData } from "@glide/data-types";
import type { JSONObject } from "@glide/support";
import * as iots from "io-ts";

export const queryTableVersionsCodec = iots.record(iots.string, iots.number);
/**
 * ##queryTableVersions:
 * Record of table IDs and version number. Used by the frontend to send the
 * latest versions of all the tables it's concerned with to the backend.
 */
export type QueryTableVersions = iots.TypeOf<typeof queryTableVersionsCodec>;

// This type must only be changed in a backwards compatible way.
//  (e.g. any added fields must be optional)
export const bigTableQueryContinuationCodec = iots.type({
    rowIndex: iots.string,
    sortKeys: iots.readonlyArray(iots.unknown),
});
export type BigTableQueryContinuation = iots.TypeOf<typeof bigTableQueryContinuationCodec>;

interface BigTableQueryResultBase {
    readonly version: number | undefined;
    readonly numRowsInTable: number;
    readonly continuation: BigTableQueryContinuation | undefined;
    readonly tableVersions: QueryTableVersions;
}

interface BigTableQueryRowsResult<T> extends BigTableQueryResultBase {
    readonly isGroupBy: false;
    readonly rows: readonly T[];
}

export interface BigTableQueryGroupByResult extends BigTableQueryResultBase {
    readonly isGroupBy: true;
    readonly rows: readonly GroupByRow[];
}

export type BigTableQueryResult<T = JSONObject> = BigTableQueryRowsResult<T> | BigTableQueryGroupByResult;

export type QueryablePrimitiveValue = BasePrimitiveValue | GlideDateTimeDocumentData | GlideJSONDocumentData;

export interface GroupByRowBase<TNonBase> {
    group: readonly (BasePrimitiveValue | TNonBase)[];
    count: number;
    // If we don't include `number` here then `count` will be illegal
    [aggregateName: string]: (BasePrimitiveValue | TNonBase) | readonly (BasePrimitiveValue | TNonBase)[] | number;
}

export type GroupByRow = GroupByRowBase<GlideDateTimeDocumentData | GlideJSONDocumentData>;
