import { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";

export const PLAN_WEIGHTS = [
    {
        planKind: PlanKind.Free,
        weight: 0,
    },
    {
        planKind: PlanKind.V3Starter,
        weight: 1,
    },
    {
        planKind: PlanKind.V3Pro,
        weight: 2,
    },
    {
        planKind: PlanKind.V3Business,
        weight: 3,
    },
    {
        planKind: PlanKind.V3NonProfit,
        weight: 3,
    },
    {
        planKind: PlanKind.Unlimited,
        weight: 4,
    },
];
