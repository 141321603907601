import type { ActionNode } from "@glide/app-description";
import { ActionNodeKind } from "@glide/app-description";
import type { AppDescriptionContext } from "@glide/function-utils";
import { getLabelForConditionFormula } from "@glide/generator/dist/js/formulas/formula-labels";
import { assertNever } from "@glideapps/ts-necessities";
import { getHandlerForLoop } from "./loops";
import { getPrimitiveNodeName } from "./names";
import type { PriorStep } from "@glide/generator/dist/js/prior-step";

export function getStepTitle(
    node: ActionNode,
    adc: AppDescriptionContext | undefined,
    priorSteps: readonly PriorStep[]
): string | undefined {
    if (node.customTitle !== undefined) {
        return node.customTitle;
    }
    if (adc === undefined) {
        return undefined;
    }
    const { kind } = node;
    switch (kind) {
        case ActionNodeKind.Primitive: {
            return getPrimitiveNodeName(node, adc, priorSteps);
        }
        case ActionNodeKind.Loop: {
            const handler = getHandlerForLoop(node, false);
            return handler.getStepTitle(adc, priorSteps);
        }
        case ActionNodeKind.ConditionalFlow: {
            return getLabelForConditionFormula(
                node.condition,
                adc,
                undefined,
                priorSteps.map(p => p.node),
                []
            );
        }
        case ActionNodeKind.Flow: {
            return "Else";
        }
        case ActionNodeKind.AutomationRoot:
        case ActionNodeKind.Conditional:
            return undefined;
        default:
            assertNever(kind);
    }
}
