import type { AppDescription, TabDescription } from "@glide/app-description";
import type { TableName } from "@glide/type-schema";
import type { ExistingAppDescriptionContext } from "@glide/function-utils";

import type { OnPopCallback, ParsedMainScreen, ParsedScreen, ParsedScreens } from "./parsed-path";

export interface NavigationPathTransformer {
    readonly invisiblePath: NavigationPath;

    makeRootForApp(app: AppDescription): NavigationPath | undefined;

    parse(urlPath: string, app: AppDescription): NavigationPath | undefined;
}

export interface NavigationPath {
    readonly transformer: NavigationPathTransformer;

    isValidForApp(app: AppDescription, allowHidden: boolean): boolean;
    isVisible(): boolean;
    isEqualTo(other: NavigationPath, onlyCompareFirstRowID: boolean): boolean;
    canPop(): boolean;

    getTabScreenName(): string | undefined;
    getParsedScreens(): ParsedScreens;

    getRootScreen(): ParsedMainScreen | undefined;
    getScreenStack(): readonly ParsedScreen[];

    // The ##tabIconOfScreen is only used in apps.
    getTabOfCurrentScreen(app: AppDescription): TabDescription | undefined;

    push(screen: ParsedScreen): NavigationPath;

    pop(): [NavigationPath, OnPopCallback | undefined] | undefined;
    popToTab(): [NavigationPath, OnPopCallback | undefined];
    closeModal(): [NavigationPath, OnPopCallback | undefined];
    removeModals(): [NavigationPath, OnPopCallback | undefined];
    // If this is ever used to remove hydrated screens, it has to return an
    // `OnPopCallback`, too.
    removeScreen(screenName: string): NavigationPath;

    navigateToTab(tabScreenName: string): NavigationPath;

    // `screen`, if defined, must be the root screen or in the screen stack.
    getDepthOfScreen(screen: ParsedScreen): number;

    updateWithTabVisibility(
        visibleRootScreens: ReadonlySet<string>,
        invisibleRootScreens: ReadonlySet<string>,
        defaultTabScreenName: string | undefined
    ): NavigationPath;

    updateWithDeletedRows(deletedRowIDs: ReadonlySet<string>): NavigationPath;

    unparseForApp(app: AppDescription): string;

    getDetailScreenForMaster(): ParsedMainScreen | undefined;

    withScreenStack(screenStack: readonly ParsedScreen[]): NavigationPath;

    getRowIDsForTable(adc: ExistingAppDescriptionContext, tableName: TableName): ReadonlySet<string>;
}
