import type { PrimitiveValue } from "@glide/computation-model-types";
import type { PrimitiveGlideTypeKind } from "@glide/type-schema";
import { type PropertyDescription, ActionKind } from "@glide/app-description";
import * as iots from "io-ts";
import type { WireComponentKind } from "./component-kind";
import { fromEnum } from "./iots-enum";

// from app/src/webapp/lib/constants.ts
export const GLIDE_COMPONENT_INDEX_DATA_FORMAT = "text/glide-component-index";
export const GLIDE_TABLE_COLUMN_DATA_FORMAT = "text/glide-column";
export const GLIDE_NEW_COMPONENT_DATA_FORMAT = "text/glide-component-type";
export const TITLE_BAR_TAP = "TITLE_BAR_TAP";
export const COMPONENTS_CHANGED = "COMPONENTS_CHANGED";
export const SCROLL_CHANGED = "SCROLL_CHANGED";

export const APP_MODAL_ROOT = "app-modal-root";
export const APP_OVERLAY_ROOT = "app-overlay-root";
export const PUSH_MODAL_ROOT = "push-modal-root";
export const FLYOUT_ROOT = "flyout-root";

export const APP_ROOT = "app-root";

export type WireActionBusy = null;
export const WireActionBusy = null;
export type WireActionOffline = undefined;
export const WireActionOffline = undefined;

export function isWireActionEnabled(action: WireAction | undefined): boolean {
    return action?.token !== WireActionOffline && action?.token !== WireActionBusy;
}

export interface WireComponent {
    readonly kind: WireComponentKind;
    readonly id?: string;
    readonly customCssClassName?: string;
}

export interface WireAction {
    // `WireActionBusy` means the action is currently running or some value is
    // loading, i.e. display a busy state.  `WireActionOffline` means the
    // action is (currently) disabled because the client is offline.
    readonly token: string | WireActionBusy | WireActionOffline;
    readonly url?: string;
}

export interface WireMessage {
    readonly message: string;
    readonly title: string;
    readonly cta?: string;
    readonly link?: {
        text: string;
        url?: string;
    };
}

export interface WirePrimitiveValueWithType {
    readonly value: PrimitiveValue;
    readonly formattedValue: string | undefined;
    readonly typeKind: PrimitiveGlideTypeKind;
}

export interface WireValueWithFormatted<T> {
    readonly value: T;
    readonly formattedValue: string | undefined;
}

export interface WireEditableValuePrimitiveValue {
    readonly value: PrimitiveValue;
    readonly typeKind: PrimitiveGlideTypeKind;
    // If this is set, use `value` only when editing.
    readonly displayValue?: string;
    // If the token is `undefined` then editing should be disabled.
    readonly onChangeToken: string | undefined;
    // The frontend must debounce if this is set.
    readonly debounceMS?: number;
    // Validation error.  If it's an empty string, it means invalid, but don't
    // show an error message.
    readonly error?: string;
}

export interface WireEditableValue<T> {
    readonly value: T;
    // If this is set, use `value` only when editing.
    readonly displayValue?: string;
    // If the token is `undefined` then editing should be disabled.
    readonly onChangeToken: string | undefined;
    // The frontend must debounce if this is set.
    readonly debounceMS?: number;
    // Validation error.  If it's an empty string, it means invalid, but don't
    // show an error message.
    readonly error?: string;
}

export interface WirePaging {
    readonly pageSize: number;
    readonly numPages: number;
    readonly itemsCount: number;
    readonly pageIndex: WireEditableValue<number>;
}

export interface WirePagedGroup<T> {
    readonly title: string;
    readonly items: readonly T[];
    readonly paging: WirePaging;

    // CardCollection horizontal collections have a See All action that toggles a subsidiary
    // With a regular ("vertical") collection.
    readonly toggleSeeAllAction?: WireAction;
    readonly groupKey: string;
}

// This is only used for logging and show not be apart of any app or action description
export interface ActionConditionLog {
    readonly kind: "conditional";
    readonly actionID: PropertyDescription;
}

const actionKindCodec = fromEnum("ActionKind", ActionKind);
// TODO plugins show up as dummy right now - if needing the distiction, we need figure how how we want the type designed
export const actionLogKindCodec = iots.union([actionKindCodec, iots.literal("conditional"), iots.literal("start")]);

export type ActionLogKind = iots.TypeOf<typeof actionLogKindCodec>;

export function isAccentBackgroundStyle(style: UIBackgroundStyle) {
    return (
        style === UIBackgroundStyle.Accent ||
        style === UIBackgroundStyle.AccentLegacy ||
        style === UIBackgroundStyle.AccentTranslucent ||
        style === UIBackgroundStyle.Transparent
    );
}

export function isDarkBackgroundStyle(style: UIBackgroundStyle) {
    return style === UIBackgroundStyle.Dark || style === UIBackgroundStyle.DarkTranslucent;
}

export function isImageBackgroundStyle(style: UIBackgroundStyle) {
    return style === UIBackgroundStyle.Image;
}

export enum UIBackgroundStyle {
    None = "none",
    Card = "card",
    Soft = "soft",
    Image = "image",
    Highlight = "highlight",
    HighlightTranslucent = "highlight-translucent",
    Dark = "dark",
    DarkTranslucent = "dark-translucent",
    Accent = "accent-bg",
    AccentLegacy = "accent",
    AccentTranslucent = "accent-translucent",
    Transparent = "accent-transparent",
    TransparentLight = "light-transparent",
    TransparentDark = "dark-transparent",
    White = "white",
}

export enum UIAlignment {
    Start = "start",
    Center = "center",
    End = "end",
    Stretch = "stretch",
}

export enum UIAspect {
    Auto = "aspect-auto",
    Square = "aspect-square",
    FourByThree = "aspect-four-by-three",
    SixteenByNine = "aspect-sixteen-by-nine",
    ThreeByOne = "aspect-three-by-one",
    ThreeByTwo = "aspect-three-by-two",
}

export enum UIMapAspect {
    Large = "large",
    Medium = "medium",
    Small = "small",
}

export enum UIImageStyle {
    Rectilinear = "image-style-rectilinear",
    Circle = "image-style-circle",
}

export enum UIImageFill {
    Cover = "image-fill-cover",
    Contain = "image-fill-contain",
}

export enum UIImageSize {
    Default = "image-size-default",
    Compact = "image-size-compact",
}

export enum UISize {
    XSmall = "size-xsmall",
    Small = "size-small",
    Medium = "size-medium",
    Large = "size-large",
    XLarge = "size-xlarge",
    Full = "size-full",
}

export enum UILayoutVariant {
    Compact = "layout-compact",
    Default = "layout-default",
}

export type UISizeSimple = UISize.XSmall | UISize.Small | UISize.Medium | UISize.Large | UISize.XLarge;

export enum LinkStyle {
    Row = "Row",
    Compact = "Compat",
}

// FIXME: Audit usages and remove all unused
export enum UIButtonAppearance {
    MobilePrimary = "button-appearance-mobile-primary",
    MobileSecondary = "button-appearance-mobile-secondary",
    Filled = "filled",
    Transparent = "transparent",
    Bordered = "bordered",
    Simple = "simple",
    Floating = "floating",
    MinimalPrimary = "minimal-primary",
    MinimalSecondary = "minimal-secondary",
    Tiles = "tiles",
    MiniTiles = "mini-tiles",
    DarkNeutral = "dark-neutral",
}

export enum UIBUttonStyle {
    Basic = "Basic",
    Minimal = "Minimal",
    Tiles = "Tiles",
}

export enum UIInsetStyle {
    Padded = "inset-padded",
    Fill = "inset-fill",
}

export enum UIStyleVariant {
    Default = "style-variant-default",
    Minimal = "style-variant-minimal",
}

export enum UITitleStyle {
    Simple = "title-style-simple",
    Bold = "title-style-bold",
    Hero = "title-style-hero",
}

export enum ButtonBarSize {
    Auto = "auto",
    Wide = "wide",
}

export enum ButtonLabelsVisibility {
    Show = "show",
    Hide = "hide",
}

export enum ButtonBarPrimaryLocation {
    Left = "left",
    None = "none",
    Right = "right",
}

export enum CardStyle {
    /** @deprecated Use Cover instead. */
    Card = "card",
    List = "list",
    Table = "table",
    Cover = "cover",
    Checklist = "checklist",
}

export enum HeroImageEffect {
    None = "None",
    Darken = "Darken",
    Lighten = "Lighten",
}

export enum HeroSize {
    XSmall = "size-xs",
    Small = "size-sm",
    Medium = "size-md",
    Large = "size-lg",
    XLarge = "size-xl",
}

export enum SeparatorSize {
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
}

export enum SpinnerSize {
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
}

export enum CustomComponentSource {
    URL = "url",
    InlineCode = "inline-code",
}

export enum CustomComponentSourceTransform {
    HTML = "html",
    React = "react",
}

export enum CustomComponentWidth {
    Full = "full",
    Container = "container",
}

export enum WireScreenPosition {
    Main = "main",
    Modal = "modal",
    // Only used in apps
    Master = "master",
}

export enum WireModalSize {
    XSmall = "x-small",
    Small = "small",
    Large = "large",
    SlideIn = "slide-in",
}

export enum ChartType {
    Bar = "BAR",
    Line = "LINE",
}

export enum DataPlotType {
    Bar = "BAR",
    Line = "LINE",
    Scatter = "SCATTER",
    Area = "AREA",
}

export enum BarChartWeights {
    Line = "line",
    Block = "block",
}

export enum BarChartType {
    Single = "single",
    Group = "group",
    Stack = "stack",
}

export enum RadialChartWeights {
    Small = "small",
    Medium = "medium",
    Chonk = "chonk",
}

export enum UIOrientation {
    Vertical = "vertical",
    Horizontal = "horizontal",
}

export enum UIWireTitleStyle {
    Simple = "simple-title-style",
    Image = "image-title-style",
    Cover = "cover-title-style",
    Profile = "profile-title-style",
}
