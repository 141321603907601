import { getFirstElementFromArrayOrSingleElement } from "@glide/common-core/dist/js/components/component-helpers";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { TextComponentStyle } from "@glide/component-utils";
import type { WireActionRowComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isSmallScreen, useResponsiveSizeClass } from "@glide/common-components";
import { isDefined, isEmptyOrUndefinedish } from "@glide/support";
import { UIButtonAppearance, UIImageStyle } from "@glide/wire";
import classNames from "classnames";
import * as React from "react";
import { css } from "styled-components";
import tw from "twin.macro";

import { Img } from "../../components/img/img";
import { Text } from "../../components/text/text";
import { WireButton } from "../wire-button/wire-button";
import type { WireRenderer } from "../wire-renderer";
import { makeSingleActionSpreadProps } from "../../wire-lib";

export const WireActionRow: WireRenderer<WireActionRowComponent> = React.memo(p => {
    const { image, text, label, actions, imageStyle, textStyle, backend } = p;
    const sizeClass = useResponsiveSizeClass();
    const isSmallColumn = isSmallScreen(sizeClass);
    const hasNoText = !image && !text && !label;

    let maybeSingleActionProps: React.HTMLAttributes<HTMLDivElement | HTMLButtonElement> = {};

    if (actions.length === 1) {
        maybeSingleActionProps = {
            ...makeSingleActionSpreadProps(actions[0].action, p.backend),
            role: "button",
            tabIndex: 0,
            "aria-pressed": false,
        };
    }

    const textContent = [
        <React.Fragment key="label">
            {label && (
                <Text
                    tw="text-base text-text-contextual-pale"
                    element="p"
                    variant={textStyle === "Field" ? TextComponentStyle.regular : TextComponentStyle.small}>
                    {label}
                </Text>
            )}
        </React.Fragment>,
        <React.Fragment key="text">
            {!isEmptyOrUndefinedish(text) && (
                <Text
                    tw="text-text-contextual-base"
                    element="p"
                    variant={textStyle === "Field" ? TextComponentStyle.regular : TextComponentStyle.headlineXXXSmall}>
                    {text}
                </Text>
            )}
        </React.Fragment>,
    ];

    if (textStyle === "Title") {
        textContent.reverse();
    }

    const singleImage = getFirstElementFromArrayOrSingleElement(image);

    return (
        <div
            data-testid="action-row-container"
            className={classNames(
                actions.length === 1 && "single-action",
                actions.length === 2 && "two-actions",
                hasNoText && "has-no-text"
            )}
            css={css`
                &.single-action {
                    ${tw`transition-colors relative page-hover:before:bg-bg-hovered active:before:bg-bg-hovered
                      focus-visible:after:(absolute ring-text-contextual-accent ring-2 -inset-px rounded-lg) before:(absolute inset-y-0 -inset-x-2 pointer-events-none rounded-lg)`}
                }
                &.has-no-text,
                &.has-no-text > div {
                    ${tw`justify-center`}
                }
            `}
            tw="flex justify-between relative py-1.5"
            {...maybeSingleActionProps}>
            <div tw="flex items-center gap-x-2.5">
                {isDefined(singleImage) && (
                    <Img
                        alt={text ?? undefined}
                        data-testid="action-row-image"
                        src={massageImageUrl(singleImage, { thumbnail: false }, backend.appID)}
                        isPages={true}
                        css={css`
                            &.${UIImageStyle.Circle} {
                                ${tw`rounded-full`}
                            }
                        `}
                        className={classNames(imageStyle)}
                        tw="object-cover w-12 h-12 rounded-lg shrink-0 bg-n50"
                    />
                )}
                <div>{textContent}</div>
            </div>
            {actions && (
                <div
                    css={css`
                        .two-actions & {
                            ${tw`flex-nowrap`}
                        }
                    `}
                    tw="flex flex-wrap justify-end items-center gap-x-2.5 gap-y-2">
                    {actions.map((action, i) => (
                        <WireButton
                            key={i}
                            appearance={UIButtonAppearance.MiniTiles}
                            {...makeSingleActionSpreadProps(action.action, p.backend)}
                            iconName={action.icon}
                            iconOnly={isSmallColumn || isEmptyOrUndefinedish(action.title)}>
                            {action.title}
                        </WireButton>
                    ))}
                </div>
            )}
        </div>
    );
});
