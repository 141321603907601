import { useResponsiveSizeClass } from "@glide/common-components";
import type { WireTab } from "@glide/wire";
import chroma from "chroma-js";
import classNames from "classnames";
import { css } from "styled-components";
import tw from "twin.macro";

import { TabIcon } from "./nav-bar-common";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

interface ChromeMobileTabBarProps {
    readonly tabs: readonly WireTab[];
    readonly measureRef: React.Ref<HTMLDivElement>;
    readonly onSelectionChange: (tab: WireTab) => void;
}

export const ChromeMobileTabBar: React.VFC<ChromeMobileTabBarProps> = p => {
    const { tabs, measureRef, onSelectionChange } = p;

    const size = useResponsiveSizeClass();

    const theme = useWireAppTheme();

    const isMobile = size === undefined || size === "sm";
    const hasTabs = tabs.length > 0;
    if (!isMobile || !hasTabs) {
        return null;
    }

    return (
        <div tw="absolute bottom-0 left-0 w-full border-t border-n100A z-mobile-tab-bar" ref={measureRef}>
            <div
                className="main-nav"
                tw="flex p-2 w-full"
                aria-label="Tab Navigation"
                css={css`
                    padding-bottom: max(var(--safe-area-inset-bottom), 8px);
                    background-color: ${chroma(theme.bgFront).alpha(0.9).css()};
                    backdrop-filter: blur(12px);
                `}>
                {tabs.map((t, idx) => {
                    return (
                        <button
                            data-testid={`tab-${t.title}`}
                            onClick={() => onSelectionChange(t)}
                            key={idx}
                            tw="flex flex-col flex-1 justify-center items-center min-w-0 h-12 transition-colors cursor-pointer"
                            className={classNames(t.isActive ? "tab-active" : "tab-inactive")}>
                            <TabIcon
                                icon={t.icon}
                                size={24}
                                strokeWidth={t.isActive ? 2 : 1.5}
                                css={css`
                                    .tab-active & {
                                        ${tw`text-text-contextual-accent`}
                                    }

                                    .tab-inactive & {
                                        ${tw`text-text-xpale`}
                                    }
                                `}
                            />
                            {theme.showLabelsInTabBar === true && (
                                <p
                                    tw="mt-0.5 font-size[10px] truncate px-0.5 max-w-full"
                                    css={css`
                                        .tab-active & {
                                            ${tw`text-text-contextual-accent`}
                                        }

                                        .tab-inactive & {
                                            ${tw`text-text-pale`}
                                        }
                                    `}>
                                    {t.title}
                                </p>
                            )}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};
