import { DefaultMap, exceptionToString } from "@glideapps/ts-necessities";
import type { ChangeObservable } from "@glide/support";
import { Watchable, logError, logInfo } from "@glide/support";

import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import { frontendTrace } from "@glide/common-core/dist/js/tracing";
import { convertYesCodeValue, getYesCodeManifest, makeParams, normalizeYesCodeURL } from "./yes-code";
import type { YesCodeColumnRequest, YesCodeValue } from "@glide/common-core/dist/js/yes-code-types";
import { yesCodeColumnResponse } from "@glide/common-core/dist/js/yes-code-types";
import { isLeft } from "fp-ts/lib/Either";
import type { YesCodeParameterMap } from "@glide/common-core/dist/js/components/types";
import { traceAsync, registerYesCodeAsyncInterface } from "./utils";

const iframes = new DefaultMap<string, Promise<HTMLIFrameElement>>(addIFrame);

const resolvers = new Map<string, (v: YesCodeValue | undefined, e: string | undefined) => void>();
const errorObservables = new DefaultMap<string, Watchable<string | undefined>>(
    () => new Watchable<string | undefined>(undefined)
);

function addIFrame(url: string): Promise<HTMLIFrameElement> {
    return frontendTrace(
        "loadYesCode",
        { "yes_code.url": url },
        () =>
            new Promise(resolve => {
                const node = document.createElement("iframe");
                node.id = "iframe";
                node.src = normalizeYesCodeURL(url);
                node.style.display = "none";
                if ("sandbox" in node) {
                    node.sandbox.value = "allow-scripts";
                }
                node.onload = function () {
                    logInfo("loaded", url);

                    node.onload = null; // clear the handler, not to run it after the location change

                    resolve(node);
                };

                document.body.appendChild(node);
            }),
        "always"
    );
}

// `window` is not defined in node, but we import this in `functions`
const globalWindow = (function () {
    try {
        return window;
    } catch {
        return undefined;
    }
})();

globalWindow?.addEventListener("message", event => {
    const { origin, data } = event;

    const parsed = yesCodeColumnResponse.decode(data);
    if (isLeft(parsed)) {
        // logError("Could not parse yes-code response", data);
        return;
    }

    const { key, result, error } = parsed.right;
    logInfo("yes-code", origin, key, result, error);
    const resolve = resolvers.get(key);
    if (resolve === undefined) {
        logError("No resolver", key);
        return;
    }
    resolvers.delete(key);
    resolve(result?.value, error);
});

async function callYesCodeAsync(url: string, paramsMap: YesCodeParameterMap): Promise<YesCodeValue | undefined> {
    const [iframe, maybeManifest] = await Promise.all([iframes.get(url), getYesCodeManifest(url)]);

    if (typeof maybeManifest === "string") return undefined;
    const manifest = maybeManifest;

    const { contentWindow: maybeWindow } = iframe;
    if (maybeWindow === null) return undefined;
    const contentWindow = maybeWindow;

    const key = makeRowID();
    const params = makeParams(manifest, paramsMap);
    const request: YesCodeColumnRequest = { key, params };

    function makePromise(): Promise<YesCodeValue | undefined> {
        return new Promise(resolve => {
            resolvers.set(key, (resultValue, error) => {
                const result = convertYesCodeValue([resultValue, resultValue], manifest.result.type);
                if (error !== undefined) {
                    errorObservables.get(url).current = error;
                }
                resolve(result.value);
            });
            contentWindow.postMessage(request, "*");
        });
    }

    return traceAsync(url, makePromise);
}

function unload(url: string): void {
    if (iframes.has(url)) {
        const iframe = iframes.get(url);
        iframes.delete(url);
        void iframe.then(e => e.remove());
    }

    if (errorObservables.has(url)) {
        errorObservables.get(url).current = undefined;
    }
}

export function getYesCodeErrorChangeObservable(url: string): ChangeObservable<string | undefined> {
    return errorObservables.get(url);
}

let isRegistered = false;

export function registerYesCode(): void {
    if (isRegistered) return;
    isRegistered = true;

    registerYesCodeAsyncInterface({
        importURL: async url => {
            try {
                // On the browser, we need this eval to ensure webpack doesn't mess with this import.
                // On node, we need to use eval here to ensure typescript doesn't translate the import to a require.
                //  The tsconfig { "moduleResolution": "nodenext" } setting introduced in TS 4.7 might allow
                //  us to avoid this eval, but it also requires all imports to use file extensions, which
                //  would be a huge diff across our codebase.
                //  See https://github.com/microsoft/TypeScript/issues/43329
                return await eval(`import("${url}")`);
            } catch (e: unknown) {
                logError(`Failed to import yescode from '${url}': ${exceptionToString(e)}`);
            }
        },
        callAsync: callYesCodeAsync,
        unload,
    });
}
