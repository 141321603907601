import isString from "lodash/isString";
import React from "react";

import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { formatLocalizedString, getLocalizedString } from "@glide/localization";
import type { AppFeatures, IconImage } from "@glide/app-description";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

import { GreetingStyles } from "../custom-sign-in/greeting-styles";
import { MadeWithGlide } from "../made-with-glide";
import { SignInBranding } from "../custom-sign-in/sign-in-branding";
import { WireField } from "../../renderers/wire-field/wire-field";
import { SignInPrimaryButton, SignInSecondaryButton } from "../sign-in-v2/sign-in-buttons";

interface Props extends React.PropsWithChildren {
    readonly appFeatures: AppFeatures;
    readonly canContinue: boolean;
    readonly canCustomizeSignIn: boolean; // from EminenceFlags ideally
    readonly canRequestAnotherPin: boolean;
    readonly error?: string;
    readonly iconImage?: IconImage;
    readonly isSending: boolean;
    readonly onPinChange: (newPin: string) => void;
    readonly onPressedContinue: () => void;
    readonly onPressedPin: () => void;
    readonly pin: string;
    readonly removeBranding: boolean; // from EminenceFlags ideally
    readonly userAgreed: boolean;
    readonly userEmail: string;
    readonly appID: string;
}

export const EmailPinStage: React.VFC<Props> = p => {
    const {
        userEmail,
        canContinue,
        error,
        isSending,
        iconImage,
        userAgreed,
        appFeatures,
        onPressedContinue,
        canCustomizeSignIn,
        removeBranding,
        canRequestAnotherPin,
        onPressedPin,
        onPinChange,
        pin,
        appID,
    } = p;

    const theme = useWireAppTheme();

    const logo = theme.pagesSignInLogo;

    const onFormSubmit = React.useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();

            const userAgreementRequired = theme.requiresAgreement === true;
            const userAgreedValidated = !userAgreementRequired || (userAgreementRequired && userAgreed);

            if (onPressedContinue && userAgreedValidated) onPressedContinue();
        },
        [onPressedContinue, theme.requiresAgreement, userAgreed]
    );

    const appKind = getAppKindFromFeatures(appFeatures);

    const hasBackgroundImage = isString(theme.signInBackground) && canCustomizeSignIn;

    const topContent = (
        <div>
            <SignInBranding
                theme={theme}
                iconImage={iconImage}
                appFeatures={appFeatures}
                logo={logo}
                canCustomizeSignIn={canCustomizeSignIn}
                appID={appID}
            />

            <GreetingStyles greeting={getLocalizedString("checkYourEmail", appKind)} />
            <form action="" onSubmit={onFormSubmit}>
                <WireField
                    isSignInEmail={true}
                    data-test="app-pin-input"
                    title={formatLocalizedString("weHaveSentAPinTo", [userEmail], appKind)}
                    isEnabled={!isSending}
                    placeholder="00000"
                    value={pin}
                    onChange={onPinChange}
                    dataType="tel"
                    dataTypePattern="[0-9]*"
                    error={error}
                    autoFocus={true}
                />
            </form>
        </div>
    );

    const buttons = (
        <div>
            <SignInPrimaryButton
                onClick={onPressedContinue}
                disabled={!canContinue}
                label={getLocalizedString("signIn", appKind)}
            />
            <SignInSecondaryButton
                onClick={onPressedPin}
                disabled={!canRequestAnotherPin}
                label={getLocalizedString("iNeedAnotherPin", appKind)}
            />
        </div>
    );

    return (
        <>
            {topContent}
            {buttons}
            {!removeBranding ? (
                <MadeWithGlide position={hasBackgroundImage ? "relative" : "static"} background="none" />
            ) : (
                <div tw="gp-sm:h-8"></div>
            )}
        </>
    );
};
