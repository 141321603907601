import type { IconName } from "@glide/common";
import { TextBoxJustify, TextBoxLineLimit, TextBoxStyle } from "@glide/common-core/dist/js/components/text-box-types";
import {
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getEnumProperty,
} from "@glide/app-description";
import { makeEmptyComponentDescription, type InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireAppTextBoxComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type PropertyDescriptor,
    PropertySection,
    SwitchPropertyHandler,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import {
    type WireRowComponentHydratorConstructor,
    WireComponentKind,
    type WireInflationBackend,
    UIAlignment,
} from "@glide/wire";
import { definedMap } from "collection-utils";
import { inflateStringProperty, makeSimpleWireRowComponentHydratorConstructor, spreadComponentID } from "../wire/utils";
import { ComponentHandlerBase } from "./handler";
import { TextComponentStyle } from "@glide/component-utils";
import { assertNever } from "@glideapps/ts-necessities";

const ComponentKindTextBox: ComponentKind = "text-box";

const componentName = "Text";

const expand = (val: string, icon?: IconName) => ({
    value: val,
    label: val,
    icon,
});

interface TextBoxComponentDescription extends ComponentDescription {
    readonly textProperty: PropertyDescription;
    readonly style: PropertyDescription;
    readonly justify: PropertyDescription;
    readonly allCaps: PropertyDescription;
    readonly allowWrapping: PropertyDescription;
    readonly truncate: PropertyDescription;
    readonly lineLimit: PropertyDescription;
}
const defaultStyle = TextBoxStyle.Body;

function convertTextBoxStyleToTextComponentStyle(style: TextBoxStyle): TextComponentStyle {
    switch (style) {
        case TextBoxStyle.BodySmall:
            return TextComponentStyle.small;
        case TextBoxStyle.Body:
            return TextComponentStyle.regular;
        case TextBoxStyle.BodyLarge:
            return TextComponentStyle.large;
        case TextBoxStyle.Footnote:
            return TextComponentStyle.footnote;
        case TextBoxStyle.H1:
            return TextComponentStyle.headlineXLarge;
        case TextBoxStyle.H2:
            return TextComponentStyle.headlineLarge;
        case TextBoxStyle.H3:
            return TextComponentStyle.headlineMedium;
        case TextBoxStyle.H4:
            return TextComponentStyle.headlineSmall;
        default:
            assertNever(style);
    }
}
function convertTextBoxJustifyToUIAlignment(justify: TextBoxJustify): UIAlignment {
    switch (justify) {
        case TextBoxJustify.Left:
            return UIAlignment.Start;
        case TextBoxJustify.Center:
            return UIAlignment.Center;
        case TextBoxJustify.Right:
            return UIAlignment.End;
        case TextBoxJustify.Justify:
            return UIAlignment.Stretch;
        default:
            assertNever(justify);
    }
}

const textBoxStyleProperty: PropertyDescriptor = {
    kind: PropertyKind.Enum,
    property: { name: "style" },
    label: "Style",
    menuLabel: "Style",
    cases: [
        expand(TextBoxStyle.BodySmall),
        expand(TextBoxStyle.Body),
        expand(TextBoxStyle.BodyLarge),
        expand(TextBoxStyle.Footnote),
        expand(TextBoxStyle.H1),
        expand(TextBoxStyle.H2),
        expand(TextBoxStyle.H3),
        expand(TextBoxStyle.H4),
    ],
    defaultCaseValue: TextBoxStyle.Body,
    section: PropertySection.Design,
    visual: "dropdown",
};

function getTextBoxStyle(desc: TextBoxComponentDescription): TextBoxStyle {
    return getEnumProperty(desc.style) ?? defaultStyle;
}

const defaultJustify = TextBoxJustify.Left;
const textBoxJustifyProperty: PropertyDescriptor = {
    kind: PropertyKind.Enum,
    property: { name: "justify" },
    label: "Align",
    menuLabel: "Align",
    cases: [
        expand(TextBoxJustify.Left, "leftAlign"),
        expand(TextBoxJustify.Center, "centerAlign"),
        expand(TextBoxJustify.Right, "rightAlign"),
        expand(TextBoxJustify.Justify, "justifyAlign"),
    ],
    defaultCaseValue: defaultJustify,
    section: PropertySection.Design,
    visual: "small-images",
};
function getTextBoxJustify(desc: TextBoxComponentDescription): TextBoxJustify {
    return getEnumProperty(desc.justify) ?? defaultJustify;
}

const defaultLineLimit = TextBoxLineLimit.Three;
const textBoxLineLimitProperty: PropertyDescriptor = {
    kind: PropertyKind.Enum,
    property: { name: "lineLimit" },
    label: "Lines",
    menuLabel: "Lines",
    cases: [
        expand(TextBoxLineLimit.One),
        expand(TextBoxLineLimit.Two),
        expand(TextBoxLineLimit.Three),
        expand(TextBoxLineLimit.Four),
        expand(TextBoxLineLimit.Five),
        expand(TextBoxLineLimit.Six),
    ],
    defaultCaseValue: defaultLineLimit,
    section: PropertySection.Design,
    visual: "slider",
};
function getTextBoxLineLimit(desc: TextBoxComponentDescription): TextBoxLineLimit {
    return getEnumProperty(desc.lineLimit) ?? defaultLineLimit;
}

const allowWrappingPropertyHandler = new SwitchPropertyHandler(
    {
        allowWrapping: true,
    },
    "Allow text wrapping",
    PropertySection.Design
);

const allCapsPropertyHandler = new SwitchPropertyHandler(
    {
        allCaps: false,
    },
    "All Caps",
    PropertySection.Design
);

const truncatePropertyHandler = new SwitchPropertyHandler(
    {
        truncate: false,
    },
    "Truncate text lines",
    PropertySection.Design
);

export class TextBoxComponentHandler extends ComponentHandlerBase<TextBoxComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindTextBox);
    }

    public get needsColumns(): boolean {
        return false;
    }

    public getDescriptor(
        desc: TextBoxComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const allowWrapping = definedMap(desc, d => allowWrappingPropertyHandler.getSwitch(d)) ?? true;
        const truncate = definedMap(desc, d => truncatePropertyHandler.getSwitch(d)) ?? false;

        const properties: PropertyDescriptor[] = [
            makeTextPropertyDescriptor("textProperty", "Text", "Enter text", true, mutatingScreenKind, {
                isMultiLine: true,
                preferredNames: ["text"],
                columnFirst: true,
            }),
            ...this.getBasePropertyDescriptors(),
            textBoxStyleProperty,
            textBoxJustifyProperty,
            allCapsPropertyHandler,
        ];

        if (allowWrapping) {
            properties.push(truncatePropertyHandler);
            if (truncate) {
                properties.push(textBoxLineLimitProperty);
            }
        }

        return {
            name: componentName,
            helpUrl: getDocURL("text"),
            description: "A box full of text",
            img: "co-basic-text",
            group: "Text",
            properties,
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: TextBoxComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const [textGetter] = inflateStringProperty(ib, desc.textProperty, true);

        const allowWrapping = allowWrappingPropertyHandler.getSwitch(desc);
        const truncate = truncatePropertyHandler.getSwitch(desc);
        let lineLimit = getTextBoxLineLimit(desc);

        if (!truncate || !allowWrapping) {
            lineLimit = TextBoxLineLimit.None;
        }

        const style = getTextBoxStyle(desc);
        const justify = getTextBoxJustify(desc);
        const allCaps = allCapsPropertyHandler.getSwitch(desc);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const text = textGetter(hb);
            const component: WireAppTextBoxComponent = {
                kind: WireComponentKind.AppTextBox,
                ...spreadComponentID(desc.componentID, forBuilder),
                text: text ?? "",
                style,
                justify,
                lineLimit,
                allowWrapping,
                allCaps,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: TextBoxComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return {
            ...makeEmptyComponentDescription(WireComponentKind.Text),
            text: desc.textProperty,
            style: convertTextBoxStyleToTextComponentStyle(getEnumProperty(desc.style) ?? TextBoxStyle.Body),
            align: convertTextBoxJustifyToUIAlignment(getEnumProperty(desc.justify) ?? TextBoxJustify.Left),
        } as ComponentDescription;
    }
}
