import "twin.macro";

export const aiGeneratedTemplate = "template-ai";

export const templateSvgToSourceNameMap = {
    "template-basic": "Basic App",
    [aiGeneratedTemplate]: "AI Generated",
    "template-portal": "Portal",
    // "template-form": "Form",
    "template-blank": "Blank",
} as const;

export function isValidTemplate(key: string): key is keyof typeof templateSvgToSourceNameMap {
    return key in templateSvgToSourceNameMap;
}

export type TemplateImageProps = {
    icon: keyof typeof templateSvgToSourceNameMap;
    className?: string;
};
