import "twin.macro";

import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import type { WireAppTheme } from "@glide/theme";
import type { WireCustomComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { CustomComponentSource, CustomComponentSourceTransform, CustomComponentWidth } from "@glide/wire";
import { iframeResizer } from "iframe-resizer";
import { useEffect, useState } from "react";

import type { WireRenderer } from "../wire-renderer";
import { getFirstElementFromArrayOrSingleElement } from "@glide/common-core/dist/js/components/component-helpers";

// We maintain our own container geometry that should match WireContainer
// widths, padding, geometry, etc.
const wireContainerMirrorClasses = [
    "container",
    // FIXME WireContainer manages vertical margins between components.
    // We use padding here, to put the drawable margin within the component.
    // But WireContainer manages spacing with top margin, so we cannot manage
    // a simulated bottom margin using padding here, because it causes double
    // the amount of intended padding between components. We'll need some coordination
    // with the component stack to get this right.
    "pt-6",
    "px-5",
    "sm:px-6",
    "md:px-7",
    "lg:px-8",
    "xl:px-8",
].join(" ");

// gp-sm:([max-width:400px] px-6) // content-area: 352
// gp-md:([max-width:620px] px-7) // content-area: 564
// gp-lg:([max-width:748px] px-8) // content-area: 684
// gp-xl:([max-width:1004px] px-8) // content-area: 940
// gp-2xl:([max-width:1516px] px-8) // content-area 1216 1260

function cssForTheme(theme: WireAppTheme): string {
    const { accent, textDark, textPale } = theme;
    return `
        .container {
            margin: 0 auto;
            max-width: 1260px;
        }
        .text-accent { color: ${accent}; }
        .bg-accent { background-color: ${accent}; }
        .border-accent { border-color: ${accent}; }
        .text-dark { color: ${textDark}; }
        .text-pale { color: ${textPale}; }
    `;
}

function generateHTMLDocument(html: string, theme: WireAppTheme, width: CustomComponentWidth): string {
    const useContainer = width === CustomComponentWidth.Container;
    return [
        `<!DOCTYPE html>`,
        `<script src="/plugins/client.min.js"></script>`,
        `<link href="/plugins/tailwind.min.css" rel="stylesheet" />`,
        `<style>${cssForTheme(theme)}</style>`,
        `<div class="${useContainer ? wireContainerMirrorClasses : ""}">`,
        html,
        `</div>`,
    ].join("");
}

function generateReactDocument(jsx: string, theme: WireAppTheme, width: CustomComponentWidth): string {
    const containerClassName = width === CustomComponentWidth.Container ? wireContainerMirrorClasses : "";

    return [
        `<!DOCTYPE html>`,
        `<style>${cssForTheme(theme)}</style>`,
        // We load the plugin client combined with react and babel
        // for client-side jsx support. This is ~1MB or more. We should consider
        // transpiling the JSX when we publish the app to avoid this fat file
        // loading in apps.
        `<script src="/plugins/client-babel.min.js"></script>`,
        `<link href="/plugins/tailwind.min.css" rel="stylesheet" />`,
        `<script type="text/jsx">
             const Component = (() => { ${jsx} })();
             glide.component(props => (
                 <div className="${containerClassName}">
                    <Component {...props} />
                </div>
             ));
         </script>`,
    ].join("");
}

const documentGenerator: Record<
    CustomComponentSourceTransform,
    (code: string, theme: WireAppTheme, width: CustomComponentWidth) => string
> = {
    [CustomComponentSourceTransform.HTML]: generateHTMLDocument,
    [CustomComponentSourceTransform.React]: generateReactDocument,
};

function generateDocument(
    code: string | undefined,
    transform: CustomComponentSourceTransform | undefined,
    theme: WireAppTheme,
    width: CustomComponentWidth
): string | undefined {
    if (code === undefined) return undefined;

    const minimzed = code.split("\n").join(" ").replace(/\s+/g, " ");
    return transform === undefined ? code : documentGenerator[transform](minimzed, theme, width);
}

export const WireCustom: WireRenderer<WireCustomComponent> = p => {
    const { url: arrayOrSingleUrl, code, fields, width, source, sourceTransform } = p;
    const theme = useWireAppTheme();

    const url = getFirstElementFromArrayOrSingleElement(arrayOrSingleUrl);

    const [ref, setRef] = useState<HTMLIFrameElement | null>(null);
    const [loaded, setLoaded] = useState(false);

    function pushChanges() {
        ref?.contentWindow?.postMessage({ glideEventKind: "change", fields }, "*");
    }

    function onWindowMessage(event: MessageEvent<any>) {
        if (ref?.contentWindow !== event.source) return;
        // eslint-disable-next-line no-console
        if (event.data.glideEventKind === "change-send") {
            const {
                name,
                value: { value },
            } = event.data.field;
            // eslint-disable-next-line no-console
            console.log({ name, value });

            // TODO how do we send values
            //onChange={newVal => backend.valueChanged(defined(component.value.onChangeToken), newVal)}
        }
    }

    // TODO this needs to be called when components are reordered?
    useEffect(() => {
        if (ref !== null) {
            iframeResizer({ checkOrigin: false /* TODO What are the implications of this? */ }, ref);
        }
    }, [ref, url, source, code]);

    useEffect(() => {
        window.addEventListener("message", onWindowMessage);
        pushChanges();

        return () => {
            window.removeEventListener("message", onWindowMessage);
        };
    });

    pushChanges();

    if (url === undefined && code === undefined) return null;

    const embedUrlProps: React.IframeHTMLAttributes<HTMLIFrameElement> = {
        src: url ?? undefined,
        srcDoc: undefined,
    };

    const inlineCodeProps: React.IframeHTMLAttributes<HTMLIFrameElement> = {
        src: undefined,
        srcDoc: generateDocument(code ?? undefined, sourceTransform, theme, width),
    };

    return (
        <iframe
            title="custom-component"
            sandbox="allow-scripts"
            ref={setRef}
            style={{ display: loaded ? "block" : "none" }}
            onLoad={() => setLoaded(true)}
            onLoadStart={() => setLoaded(false)}
            {...(source === CustomComponentSource.URL
                ? embedUrlProps
                : source === CustomComponentSource.InlineCode
                ? inlineCodeProps
                : undefined)}
        ></iframe>
    );
};
