import type { SpriteMap } from "@glideapps/glide-data-grid";

const remapFgColor = (p: { readonly fgColor: string; readonly bgColor: string }) => {
    return p.bgColor === "white" ? "white" : p.fgColor;
};

export const glideHeaderIcons: SpriteMap = {
    headerArray: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8333 14.1667H16.6666M10.8333 5.83341H16.6666M6.66659 5.83341C6.66659 6.75389 5.92039 7.50008 4.99992 7.50008C4.07944 7.50008 3.33325 6.75389 3.33325 5.83341C3.33325 4.91294 4.07944 4.16675 4.99992 4.16675C5.92039 4.16675 6.66659 4.91294 6.66659 5.83341ZM6.66659 14.1667C6.66659 15.0872 5.92039 15.8334 4.99992 15.8334C4.07944 15.8334 3.33325 15.0872 3.33325 14.1667C3.33325 13.2462 4.07944 12.5001 4.99992 12.5001C5.92039 12.5001 6.66659 13.2462 6.66659 14.1667Z" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round"/>
    </svg>
    `,
    headerAudioUri:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3471 3.9361C11.9338 2.4023 14.4559 2.42424 16.0156 3.9854C17.5754 5.54656 17.5973 8.07086 16.0649 9.65892L16.0545 9.66948L14.1392 11.5865C13.3097 12.417 12.1608 12.846 10.9905 12.7621C9.82028 12.6782 8.74412 12.0897 8.04136 11.1494C7.75975 10.7726 7.83666 10.2386 8.21314 9.95673C8.58963 9.67487 9.12313 9.75184 9.40474 10.1287C9.8116 10.6731 10.4346 11.0138 11.1122 11.0623C11.7897 11.1109 12.4549 10.8625 12.9351 10.3817L12.9352 10.3816L14.845 8.47014C15.7274 7.55045 15.7131 6.09261 14.8117 5.19039C13.91 4.2879 12.4529 4.2739 11.5341 5.15789L10.4406 6.24598C10.1072 6.57775 9.56815 6.57618 9.23667 6.24246C8.90519 5.90875 8.90676 5.36926 9.24018 5.03748L10.3384 3.94473L10.3471 3.9361Z" fill="${remapFgColor(
            p
        )}"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.86082 8.41344C6.69028 7.58301 7.83919 7.15407 9.00939 7.23795C10.1796 7.32184 11.2558 7.91032 11.9586 8.85065C12.2402 9.22747 12.1633 9.76144 11.7868 10.0433C11.4103 10.3252 10.8768 10.2482 10.5952 9.87137C10.1883 9.32697 9.56528 8.98627 8.88777 8.93771C8.21025 8.88914 7.54506 9.1375 7.06485 9.61831L5.15495 11.5299C4.27254 12.4496 4.28679 13.9074 5.18821 14.8097C6.08962 15.7119 7.54616 15.7261 8.46504 14.8429L9.5512 13.7558C9.88365 13.4231 10.4227 13.4231 10.7551 13.7558C11.0876 14.0886 11.0876 14.6281 10.7551 14.9608L9.66332 16.0536L9.65277 16.0639C8.06612 17.5977 5.54407 17.5758 3.98429 16.0146C2.42451 14.4535 2.4026 11.9292 3.93503 10.3411L3.9454 10.3306L5.86082 8.41344Z" fill="${remapFgColor(
            p
        )}"  />
        </svg>
        `,
    headerBoolean: p => {
        return `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14.1667 14.1666H5.83341C3.53223 14.1666 1.66675 12.3011 1.66675 9.99992C1.66675 7.69874 3.53223 5.83325 5.83341 5.83325H14.1667M14.1667 14.1666C16.4679 14.1666 18.3334 12.3011 18.3334 9.99992C18.3334 7.69874 16.4679 5.83325 14.1667 5.83325M14.1667 14.1666C11.8656 14.1666 10.0001 12.3011 10.0001 9.99992C10.0001 7.69874 11.8656 5.83325 14.1667 5.83325"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
    />
    </svg>`;
    },
    headerCode: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.3335 16.6666L11.6668 3.33325" stroke="${remapFgColor(
        p
    )}" stroke-width="1.5" vector-effect="non-scaling-stroke"  stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.0004 6.66602L17.1552 8.82082C17.8061 9.47173 17.8061 10.527 17.1552 11.1779L15.0004 13.3326M5.0004 13.3326L2.84558 11.1779C2.1947 10.527 2.19471 9.47173 2.84558 8.82082L5.0004 6.66602" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    headerDate: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33325 8.83333H16.6666M6.66659 4.66667V3M13.3333 4.66667V3M4.99992 17.1667H14.9999C15.9204 17.1667 16.6666 16.4205 16.6666 15.5V6.33333C16.6666 5.41286 15.9204 4.66667 14.9999 4.66667H4.99992C4.07944 4.66667 3.33325 5.41286 3.33325 6.33333V15.5C3.33325 16.4205 4.07944 17.1667 4.99992 17.1667Z" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    headerEmail: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0001 3.83341C6.31818 3.83341 3.33341 6.81818 3.33341 10.5001C3.33341 14.182 6.31818 17.1667 10.0001 17.1667C11.2888 17.1667 12.4897 16.8019 13.5082 16.1702C13.8993 15.9277 14.4131 16.0482 14.6557 16.4392C14.8982 16.8304 14.7777 17.3441 14.3867 17.5867C13.112 18.3772 11.6081 18.8334 10.0001 18.8334C5.39771 18.8334 1.66675 15.1024 1.66675 10.5001C1.66675 5.89771 5.39771 2.16675 10.0001 2.16675C14.6024 2.16675 18.3334 5.89771 18.3334 10.5001C18.3334 11.4204 18.1079 12.4252 17.5426 13.2025C16.9458 14.023 16.0017 14.536 14.7664 14.4237C13.7636 14.3326 12.9013 13.8307 12.3253 13.1024C11.5413 14.1103 10.3348 14.7186 9.00425 14.5316C6.89641 14.2353 5.63786 12.1063 5.93617 9.98375C6.23448 7.86121 8.03111 6.16156 10.139 6.4578C10.8937 6.56387 11.5396 6.90493 12.0441 7.39654C12.14 6.97764 12.5432 6.69588 12.976 6.75873C13.4315 6.82489 13.7471 7.24773 13.6809 7.70319L13.2517 10.6578L13.2517 10.6588C13.1023 11.7077 13.862 12.668 14.9172 12.7639C15.5424 12.8207 15.9275 12.5895 16.1947 12.2222C16.4934 11.8115 16.6667 11.1849 16.6667 10.5001C16.6667 6.81818 13.682 3.83341 10.0001 3.83341ZM9.907 8.10824C8.91083 7.96823 7.78689 8.79077 7.58662 10.2157C7.38636 11.6407 8.24 12.7412 9.23625 12.8812C10.2324 13.0212 11.3563 12.1987 11.5566 10.7737C11.7569 9.34875 10.9032 8.24826 9.907 8.10824Z" fill="${remapFgColor(
        p
    )}"  />
    </svg>
    `,
    headerEmoji: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.357 12.357C11.0552 13.6587 8.94475 13.6587 7.64297 12.357M7.70833 7.91667V7.90833M12.2917 7.91667V7.90833M15.3032 4.6967C18.2322 7.62563 18.2322 12.3743 15.3032 15.3032C12.3743 18.2322 7.62562 18.2322 4.6967 15.3032C1.76777 12.3743 1.76777 7.62562 4.6967 4.6967C7.62563 1.76777 12.3743 1.76777 15.3032 4.6967ZM7.91667 7.91667C7.91667 8.14678 7.82339 8.33333 7.70833 8.33333C7.59327 8.33333 7.5 8.14678 7.5 7.91667C7.5 7.68655 7.59327 7.5 7.70833 7.5C7.82339 7.5 7.91667 7.68655 7.91667 7.91667ZM12.5 7.91667C12.5 8.14678 12.4067 8.33333 12.2917 8.33333C12.1766 8.33333 12.0833 8.14678 12.0833 7.91667C12.0833 7.68655 12.1766 7.5 12.2917 7.5C12.4067 7.5 12.5 7.68655 12.5 7.91667Z" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    headerFilterSortLimit:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6667 16.6667L13.375 13.375" stroke="${remapFgColor(
            p
        )}"   stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.0002 9.16634C15.0002 12.388 12.3885 14.9997 9.16683 14.9997C5.94517 14.9997 3.3335 12.388 3.3335 9.16634C3.3335 5.94468 5.94517 3.33301 9.16683 3.33301C12.3885 3.33301 15.0002 5.94468 15.0002 9.16634Z" stroke="${
            p.bgColor
        }" stroke-width="1.5" vector-effect="non-scaling-stroke"  stroke-linecap="round"/>
        </svg>
        `,
    headerGeoDistance:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 7.5V14.1667M11.7676 3.23212C12.7442 4.20875 12.7442 5.7922 11.7676 6.76788C10.791 7.74356 9.20767 7.74452 8.23207 6.76788C7.25646 5.79125 7.2555 4.2078 8.23207 3.23212C9.20867 2.25644 10.791 2.25548 11.7676 3.23212Z" stroke="${
            p.bgColor
        }" stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.66667 11.9707C4.19713 12.448 2.5 13.439 2.5 14.5841C2.5 16.195 5.85787 17.5008 10 17.5008C14.1422 17.5008 17.5 16.195 17.5 14.5841C17.5 13.439 15.8028 12.448 13.3333 11.9707" stroke="${remapFgColor(
            p
        )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
    headerIfThenElse:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.75 6.66667C14.9006 6.66667 15.8333 5.73392 15.8333 4.58333C15.8333 3.43274 14.9006 2.5 13.75 2.5C12.5994 2.5 11.6667 3.43274 11.6667 4.58333C11.6667 5.73392 12.5994 6.66667 13.75 6.66667ZM13.75 6.66667V8.33333C13.75 9.25383 13.0038 10 12.0833 10H7.91667C6.99619 10 6.25 10.7462 6.25 11.6667V13.3333" stroke="${
            p.bgColor
        }" stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linejoin="round"/>
        <path d="M6.24984 6.66667C7.40043 6.66667 8.33317 5.73392 8.33317 4.58333C8.33317 3.43274 7.40043 2.5 6.24984 2.5C5.09925 2.5 4.1665 3.43274 4.1665 4.58333C4.1665 5.73392 5.09925 6.66667 6.24984 6.66667ZM6.24984 6.66667V13.3333M6.24984 13.3333C5.09925 13.3333 4.1665 14.2661 4.1665 15.4167C4.1665 16.5672 5.09925 17.5 6.24984 17.5C7.40043 17.5 8.33317 16.5672 8.33317 15.4167C8.33317 14.2661 7.40043 13.3333 6.24984 13.3333Z" stroke="${remapFgColor(
            p
        )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linejoin="round"/>
    </svg>
    `,
    headerImage: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3333 17.1666L7.84509 11.6784C7.19422 11.0276 6.13895 11.0276 5.48808 11.6784L3.33325 13.8333M4.99992 17.1666H14.9999C15.9204 17.1666 16.6666 16.4204 16.6666 15.4999V5.49992C16.6666 4.57944 15.9204 3.83325 14.9999 3.83325H4.99992C4.07944 3.83325 3.33325 4.57944 3.33325 5.49992V15.4999C3.33325 16.4204 4.07944 17.1666 4.99992 17.1666ZM13.7499 8.41659C13.7499 9.33709 13.0038 10.0833 12.0833 10.0833C11.1628 10.0833 10.4166 9.33709 10.4166 8.41659C10.4166 7.49611 11.1628 6.74992 12.0833 6.74992C13.0038 6.74992 13.7499 7.49611 13.7499 8.41659Z" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke"/>
    </svg>
    `,
    headerJoinStrings:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M3.33488 3.33325H16.6683M3.33488 6.66659H8.75159M3.33325 9.99992H5.83509"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M16.3232 8.70834L15.4596 7.8446C14.8087 7.19373 13.7534 7.19372 13.1026 7.8446L6.66846 14.2787V17.4994H9.88917L16.3232 11.0653C16.9742 10.4144 16.9742 9.35917 16.3232 8.70834Z"
        stroke="${p.bgColor}"
        stroke-width="1.5" vector-effect="non-scaling-stroke" 
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    </svg>
    `,
    headerLookup: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2499 13.75L18.3332 15.8333M17.5032 10C17.5032 12.7614 15.2646 15 12.5032 15C9.74175 15 7.50317 12.7614 7.50317 10C7.50317 7.23857 9.74175 5 12.5032 5C15.2646 5 17.5032 7.23857 17.5032 10Z" stroke="${
        p.bgColor
    }" stroke-width="1.5" vector-effect="non-scaling-stroke"  stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.50342 9.99967H4.17008M2.50342 5.83301H5.00342M2.50342 14.1663H5.00342" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    headerMarkdown:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.3333 8.33341V11.6667M13.3333 11.6667L14.5833 10.6251M13.3333 11.6667L12.0833 10.6251M5.83333 11.6667V8.33341L7.70833 10.0001L9.58333 8.33341V11.6667M3.33333 4.16675H16.6667C17.1269 4.16675 17.5 4.53985 17.5 5.00008V15.0001C17.5 15.4603 17.1269 15.8334 16.6667 15.8334H3.33333C2.8731 15.8334 2.5 15.4603 2.5 15.0001V5.00008C2.5 4.53985 2.8731 4.16675 3.33333 4.16675Z"
            stroke="${remapFgColor(p)}"
             
            stroke-width="1.5" vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
    `,
    headerMath:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.16082 11.4941C3.48626 11.1686 4.01389 11.1686 4.33933 11.4941L5.83342 12.9881L7.32749 11.4941C7.65293 11.1686 8.18056 11.1686 8.506 11.4941C8.83141 11.8195 8.83141 12.3471 8.506 12.6726L7.01194 14.1666L8.506 15.6607C8.83141 15.9861 8.83141 16.5138 8.506 16.8392C8.18056 17.1646 7.65292 17.1646 7.32749 16.8392L5.83342 15.3451L4.33934 16.8392C4.0139 17.1646 3.48626 17.1646 3.16082 16.8392C2.83539 16.5138 2.83539 15.9861 3.16082 15.6607L4.65491 14.1666L3.16083 12.6726C2.83539 12.3471 2.83539 11.8195 3.16082 11.4941Z"
        fill="${p.bgColor}"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8333 5.83333C10.8333 5.3731 11.2063 5 11.6666 5H16.6666C17.1268 5 17.4999 5.3731 17.4999 5.83333C17.4999 6.29357 17.1268 6.66667 16.6666 6.66667H11.6666C11.2063 6.66667 10.8333 6.29357 10.8333 5.83333Z"
        fill="${p.bgColor}"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.83333 2.5C6.29357 2.5 6.66667 2.8731 6.66667 3.33333V5H8.33333C8.79358 5 9.16667 5.3731 9.16667 5.83333C9.16667 6.29357 8.79358 6.66667 8.33333 6.66667H6.66667V8.33333C6.66667 8.79358 6.29357 9.16667 5.83333 9.16667C5.3731 9.16667 5 8.79358 5 8.33333V6.66667H3.33333C2.8731 6.66667 2.5 6.29357 2.5 5.83333C2.5 5.3731 2.8731 5 3.33333 5H5V3.33333C5 2.8731 5.3731 2.5 5.83333 2.5Z"
        fill="${remapFgColor(p)}"
         
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8335 14.1663C10.8335 13.7061 11.2066 13.333 11.6668 13.333H16.6668C17.1271 13.333 17.5002 13.7061 17.5002 14.1663C17.5002 14.6266 17.1271 14.9997 16.6668 14.9997H11.6668C11.2066 14.9997 10.8335 14.6266 10.8335 14.1663Z"
        fill="${remapFgColor(p)}"
         
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.25 11.6667C13.25 11.1604 13.6604 10.75 14.1667 10.75C14.6729 10.75 15.0834 11.1604 15.0834 11.6667C15.0834 12.1729 14.673 12.5833 14.1667 12.5833C13.6605 12.5833 13.25 12.1729 13.25 11.6667Z"
        fill="${remapFgColor(p)}"
         
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.25 16.6667C13.25 16.1604 13.6604 15.75 14.1667 15.75C14.6729 15.75 15.0834 16.1604 15.0834 16.6667C15.0834 17.1729 14.673 17.5833 14.1667 17.5833C13.6605 17.5833 13.25 17.1729 13.25 16.6667Z"
        fill="${remapFgColor(p)}"
         
    />
    </svg>
    `,
    headerNumber: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6776 15.4403C14.2124 15.4403 13.2626 14.8438 12.8282 14.004C12.7182 13.8014 12.6719 13.6102 12.6719 13.4133C12.6719 13.0195 12.9209 12.7705 13.3379 12.7705C13.6448 12.7705 13.8301 12.9037 13.9865 13.2106C14.2761 13.8419 14.7799 14.2183 15.6949 14.2183C16.6447 14.2183 17.2875 13.6739 17.2875 12.9153C17.2933 12.0234 16.6447 11.5254 15.5733 11.5254H15.0984C14.7278 11.5254 14.5077 11.2995 14.5077 10.9694C14.5077 10.6393 14.7278 10.4134 15.0984 10.4134H15.5386C16.4594 10.4134 17.0733 9.88644 17.0733 9.14515C17.0733 8.42124 16.5984 7.93477 15.6718 7.93477C14.8841 7.93477 14.4266 8.25909 14.1487 8.9135C13.9865 9.27256 13.8243 9.40576 13.4769 9.40576C13.0599 9.40576 12.8398 9.16253 12.8398 8.7803C12.8398 8.57182 12.8804 8.38649 12.9846 8.1838C13.3784 7.35564 14.305 6.74756 15.666 6.74756C17.3976 6.74756 18.5095 7.651 18.5095 8.94246C18.5095 9.99068 17.7624 10.6914 16.7547 10.8999V10.9289C17.9883 11.0447 18.8049 11.786 18.8049 12.95C18.8049 14.4326 17.5076 15.4403 15.6776 15.4403Z" fill="${remapFgColor(
        p
    )}" fillOpacity="0.84"/>
    <path d="M6.60815 15.2723C6.14485 15.2723 5.90161 15.0175 5.90161 14.6411C5.90161 14.3515 6.00006 14.1778 6.31858 13.8766L8.87254 11.3458C9.93814 10.2918 10.2045 9.86906 10.2045 9.23202C10.2045 8.48495 9.6312 7.94636 8.82621 7.94636C8.07335 7.94636 7.54634 8.32858 7.25098 9.08145C7.10041 9.3826 6.92667 9.53896 6.58498 9.53896C6.17959 9.53896 5.93636 9.28994 5.93636 8.91929C5.93636 8.80926 5.95373 8.70502 5.98269 8.60656C6.20855 7.70312 7.21044 6.74756 8.8378 6.74756C10.5115 6.74756 11.6639 7.73787 11.6639 9.13936C11.6639 10.1181 11.1949 10.7783 9.81073 12.1335L7.89382 14.0214V14.0504H11.2296C11.6234 14.0504 11.8666 14.2936 11.8666 14.6643C11.8666 15.0291 11.6234 15.2723 11.2296 15.2723H6.60815Z" fill="${remapFgColor(
        p
    )}" fillOpacity="0.84"/>
    <path d="M3.4575 15.3883C3.02315 15.3883 2.72201 15.0871 2.72201 14.6354V8.36345H2.69305L1.34368 9.30164C1.1989 9.40589 1.08307 9.44642 0.920914 9.44642C0.608185 9.44642 0.382324 9.22056 0.382324 8.89046C0.382324 8.65881 0.474985 8.48507 0.72401 8.31133L2.43244 7.11253C2.80308 6.85193 3.03474 6.7998 3.34747 6.7998C3.87447 6.7998 4.19299 7.11833 4.19299 7.63375V14.6354C4.19299 15.0871 3.89185 15.3883 3.4575 15.3883Z" fill="${remapFgColor(
        p
    )}" fillOpacity="0.84"/>
    </svg>
    `,
    headerPhone: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M3.43614 4.99661C4.18702 11.0305 8.96948 15.813 15.0034 16.5638C15.9168 16.6775 16.6667 15.9204 16.6667 14.9999V13.9067C16.6667 13.1706 16.184 12.5218 15.479 12.3103L13.8101 11.8096C13.5165 11.7215 13.1982 11.8018 12.9815 12.0185L12.7646 12.2354C12.2616 12.7383 11.4876 12.8773 10.8862 12.4972C9.52265 11.6353 8.36473 10.4773 7.5028 9.11375C7.12272 8.51242 7.26162 7.73836 7.7646 7.23538L7.98144 7.01854C8.19821 6.80177 8.27846 6.48347 8.19037 6.18984L7.68972 4.521C7.47823 3.81604 6.82936 3.33325 6.09335 3.33325H5.00006C4.07959 3.33325 3.32247 4.08318 3.43614 4.99661Z"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linejoin="round"
    />
    </svg>
    `,
    headerReference:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7.125 16.041V9.79102C7.125 8.41027 8.24429 7.29102 9.625 7.29102H14.0417"
        stroke="${p.bgColor}"
        stroke-width="1.5" vector-effect="non-scaling-stroke" 
        stroke-linejoin="round"
    />
    <path
        d="M3.125 16.0413V9.79134C3.125 8.41059 4.24429 7.29134 5.625 7.29134H16.0417M13.5417 10.6247L16.875 7.29134L13.5417 3.95801"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linejoin="round"
    />
    </svg>
    `,
    headerReferenceSingle:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7.125 16.041V9.79102C7.125 8.41027 8.24429 7.29102 9.625 7.29102H14.0417"
        stroke="${p.bgColor}"
        stroke-width="1.5" vector-effect="non-scaling-stroke" 
        stroke-linejoin="round"
    />
    <path
        d="M3.125 16.0413V9.79134C3.125 8.41059 4.24429 7.29134 5.625 7.29134H16.0417M13.5417 10.6247L16.875 7.29134L13.5417 3.95801"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linejoin="round"
    />
    </svg>
    `,
    headerRollup: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0003 8.60467C9.22969 8.60408 8.60494 9.22883 8.60436 9.99942C8.60377 10.77 9.22852 11.3948 9.99911 11.3953C10.7697 11.3959 11.3944 10.7712 11.395 10.0006C11.3956 9.23 10.7709 8.60525 10.0003 8.60467Z" fill="${
        p.bgColor
    }"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0001 3.33341C6.31818 3.33341 3.33341 6.31818 3.33341 10.0001C3.33341 13.682 6.31818 16.6667 10.0001 16.6667C10.4603 16.6667 10.8334 17.0398 10.8334 17.5001C10.8334 17.9603 10.4603 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6024 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6024 1.66675 18.3334 5.39771 18.3334 10.0001C18.3334 12.5314 16.2814 14.5834 13.7501 14.5834C11.2187 14.5834 9.16675 12.5314 9.16675 10.0001C9.16675 9.53983 9.53983 9.16675 10.0001 9.16675C10.4603 9.16675 10.8334 9.53983 10.8334 10.0001C10.8334 11.6109 12.1392 12.9167 13.7501 12.9167C15.3609 12.9167 16.6667 11.6109 16.6667 10.0001C16.6667 6.31818 13.682 3.33341 10.0001 3.33341Z" fill="${remapFgColor(
        p
    )}"  />
    <path d="M18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699V3.33366C13.6818 3.33366 16.6665 6.31843 16.6665 10.0003C16.6665 11.6112 15.3607 12.917 13.7498 12.917C12.139 12.917 10.8332 11.6112 10.8332 10.0003C10.8332 9.54008 10.4601 9.16699 9.99984 9.16699C9.53959 9.16699 9.1665 9.54008 9.1665 10.0003C9.1665 12.5317 11.2185 14.5837 13.7498 14.5837C16.2812 14.5837 18.3332 12.5317 18.3332 10.0003Z" fill="${
        p.bgColor
    }"/>
    </svg>
    `,
    headerRowID: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12.8333 7.08341H12.9167H13M13.3333 7.08341C13.3333 7.31353 13.1467 7.50008 12.9167 7.50008C12.6866 7.50008 12.5 7.31353 12.5 7.08341C12.5 6.8533 12.6866 6.66675 12.9167 6.66675C13.1467 6.66675 13.3333 6.8533 13.3333 7.08341Z"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M12.9167 11.6667C15.448 11.6667 17.5 9.61467 17.5 7.08333C17.5 4.55203 15.448 2.5 12.9167 2.5C10.3854 2.5 8.33337 4.55203 8.33337 7.08333C8.33337 7.46745 8.38062 7.84052 8.46962 8.19708L3.82153 12.8452C3.50897 13.1578 3.33337 13.5817 3.33337 14.0237V15.8333C3.33337 16.2936 3.70647 16.6667 4.16671 16.6667H5.97635C6.41838 16.6667 6.8423 16.4911 7.15487 16.1785L8.33337 15V12.9167H10.4167L11.803 11.5304C12.1595 11.6194 12.5326 11.6667 12.9167 11.6667Z"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    </svg>
    `,
    headerSingleValue:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.99829 2.5V17.5"
        stroke="${p.bgColor}"
        stroke-width="1.5" vector-effect="non-scaling-stroke" 
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M3.50302 6.25L16.4934 13.75M3.50244 13.75L16.4928 6.25"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linecap="round"
    />
    </svg>
    `,
    headerSplitString:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.66667 4.16675H4.16667C3.24619 4.16675 2.5 4.91294 2.5 5.83341V14.1667C2.5 15.0872 3.24619 15.8334 4.16667 15.8334H6.66667M13.3333 15.8334H15.8333C16.7538 15.8334 17.5 15.0872 17.5 14.1667V5.83341C17.5 4.91294 16.7538 4.16675 15.8333 4.16675H13.3333"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M10 2.5V17.5"
        stroke="${p.bgColor}"
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    </svg>
    `,
    headerString: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M4.66699 5.99959V4.66626H10.0003M10.0003 4.66626H15.3337V5.99959M10.0003 4.66626V15.3329M10.0003 15.3329H8.66699M10.0003 15.3329H11.3337"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    </svg>
    `,
    headerTextTemplate:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3333 2.50008C13.3333 2.03985 13.7063 1.66675 14.1666 1.66675H17.4999C17.9602 1.66675 18.3333 2.03985 18.3333 2.50008C18.3333 2.96031 17.9602 3.33341 17.4999 3.33341H16.6666V16.6667H17.4999C17.9602 16.6667 18.3333 17.0398 18.3333 17.5001C18.3333 17.9603 17.9602 18.3334 17.4999 18.3334H14.1666C13.7063 18.3334 13.3333 17.9603 13.3333 17.5001C13.3333 17.0398 13.7063 16.6667 14.1666 16.6667H14.9999V3.33341H14.1666C13.7063 3.33341 13.3333 2.96031 13.3333 2.50008Z"
        fill="${p.bgColor}"
    />
    <path
        d="M2.49984 4.16699C2.0396 4.16699 1.6665 4.54009 1.6665 5.00033C1.6665 5.46056 2.0396 5.83366 2.49984 5.83366H5.83317V15.0003C5.83317 15.4606 6.20627 15.8337 6.6665 15.8337C7.12674 15.8337 7.49984 15.4606 7.49984 15.0003V5.83366H10.8332C11.2934 5.83366 11.6665 5.46056 11.6665 5.00033C11.6665 4.54009 11.2934 4.16699 10.8332 4.16699H2.49984Z"
        fill="${remapFgColor(p)}"
         
    />
    </svg>
    `,
    headerTime: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8333 7.16675C10.8333 6.70651 10.4602 6.33341 9.99992 6.33341C9.53967 6.33341 9.16659 6.70651 9.16659 7.16675H10.8333ZM9.99992 10.5001H9.16659C9.16659 10.7211 9.25442 10.9331 9.41067 11.0893L9.99992 10.5001ZM11.494 13.1727C11.8194 13.4981 12.3471 13.4981 12.6725 13.1727C12.9979 12.8472 12.9979 12.3196 12.6725 11.9942L11.494 13.1727ZM1.07733 4.28582C0.751893 4.61126 0.751893 5.1389 1.07733 5.46434C1.40277 5.78977 1.9304 5.78977 2.25584 5.46434L1.07733 4.28582ZM4.75584 2.96434C5.08128 2.6389 5.08128 2.11126 4.75584 1.78582C4.4304 1.46039 3.90277 1.46039 3.57733 1.78582L4.75584 2.96434ZM17.744 5.46434C18.0694 5.78977 18.5971 5.78977 18.9225 5.46434C19.2479 5.1389 19.2479 4.61126 18.9225 4.28582L17.744 5.46434ZM16.4225 1.78582C16.0971 1.46039 15.5694 1.46039 15.244 1.78582C14.9186 2.11126 14.9186 2.6389 15.244 2.96434L16.4225 1.78582ZM16.6666 10.5001C16.6666 14.182 13.6818 17.1667 9.99992 17.1667V18.8334C14.6023 18.8334 18.3333 15.1024 18.3333 10.5001H16.6666ZM9.99992 17.1667C6.31802 17.1667 3.33325 14.182 3.33325 10.5001H1.66659C1.66659 15.1024 5.39754 18.8334 9.99992 18.8334V17.1667ZM3.33325 10.5001C3.33325 6.81818 6.31802 3.83341 9.99992 3.83341V2.16675C5.39754 2.16675 1.66659 5.89771 1.66659 10.5001H3.33325ZM9.99992 3.83341C13.6818 3.83341 16.6666 6.81818 16.6666 10.5001H18.3333C18.3333 5.89771 14.6023 2.16675 9.99992 2.16675V3.83341ZM9.16659 7.16675V10.5001H10.8333V7.16675H9.16659ZM9.41067 11.0893L11.494 13.1727L12.6725 11.9942L10.5892 9.91083L9.41067 11.0893ZM2.25584 5.46434L4.75584 2.96434L3.57733 1.78582L1.07733 4.28582L2.25584 5.46434ZM18.9225 4.28582L16.4225 1.78582L15.244 2.96434L17.744 5.46434L18.9225 4.28582Z" fill="${remapFgColor(
        p
    )}"  />
    </svg>
    `,
    headerUri: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.38696 7.60149C7.01415 5.97431 9.65231 5.97431 11.2795 7.60149L11.5655 7.88745C12.2457 8.56767 12.6423 9.42683 12.7534 10.3135C12.8106 10.7702 12.4868 11.1867 12.0301 11.2439C11.5735 11.3012 11.1569 10.9773 11.0996 10.5207C11.033 9.98858 10.7961 9.47508 10.387 9.066L10.101 8.78C9.12473 7.80369 7.54178 7.80369 6.56547 8.78L3.77951 11.566C2.80321 12.5422 2.80321 14.1252 3.77951 15.1015L4.06547 15.3874C5.04178 16.3637 6.6247 16.3637 7.60101 15.3874L7.74396 15.2445C8.06938 14.919 8.59698 14.919 8.92248 15.2444C9.2479 15.5698 9.2479 16.0975 8.92248 16.4229L8.77956 16.5659C7.15236 18.1931 4.51414 18.1932 2.88696 16.5659L2.60101 16.28C0.973823 14.6528 0.973814 12.0147 2.60101 10.3874L5.38696 7.60149Z"
        fill="${remapFgColor(p)}"
         
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2204 4.26799C12.8476 2.64081 15.4858 2.64081 17.113 4.26799L17.3989 4.55395C19.0262 6.18114 19.0262 8.81934 17.3989 10.4465L14.613 13.2325C12.9858 14.8597 10.3476 14.8597 8.72042 13.2324L8.43451 12.9465C7.75425 12.2663 7.35762 11.4072 7.24654 10.5205C7.18933 10.0638 7.51317 9.64725 7.96983 9.59C8.42651 9.53284 8.84309 9.85667 8.90026 10.3133C8.96692 10.8454 9.20384 11.3588 9.61301 11.768L9.89892 12.0539C10.8753 13.0303 12.4582 13.0303 13.4345 12.0539L16.2204 9.268C17.1968 8.29169 17.1968 6.70877 16.2204 5.73246L15.9345 5.44651C14.9582 4.47022 13.3753 4.4702 12.399 5.44643C12.399 5.44645 12.3991 5.4464 12.399 5.44643L12.2561 5.5894C11.9307 5.91489 11.4031 5.91495 11.0776 5.58956C10.7521 5.26416 10.752 4.73653 11.0774 4.41105L11.2204 4.26799Z"
        fill="${remapFgColor(p)}"
         
    />
    </svg>
    `,
    headerUserAPI: p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2068 3.41837C6.56587 3.41837 3.61429 6.36994 3.61429 10.0109C3.61429 13.6518 6.56587 16.6034 10.2068 16.6034C13.8478 16.6034 16.7993 13.6518 16.7993 10.0109C16.7993 6.36994 13.8478 3.41837 10.2068 3.41837ZM2.41565 10.0109C2.41565 5.70795 5.90388 2.21973 10.2068 2.21973C14.5098 2.21973 17.998 5.70795 17.998 10.0109C17.998 14.3138 14.5098 17.8021 10.2068 17.8021C5.90388 17.8021 2.41565 14.3138 2.41565 10.0109Z" fill="${p.bgColor}"/>
    <g opacity="0.65">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.8252 7.6136C2.8252 7.28261 3.09352 7.01428 3.42452 7.01428H16.989C17.32 7.01428 17.5883 7.28261 17.5883 7.6136C17.5883 7.9446 17.32 8.21292 16.989 8.21292H3.42452C3.09352 8.21292 2.8252 7.9446 2.8252 7.6136Z" fill="${p.bgColor}"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.82549 12.4082C2.82549 12.0772 3.09381 11.8088 3.42481 11.8088H16.9892C17.3202 11.8088 17.5885 12.0772 17.5885 12.4082C17.5885 12.7392 17.3202 13.0075 16.9892 13.0075H3.42481C3.09381 13.0075 2.82549 12.7392 2.82549 12.4082Z" fill="${p.bgColor}"/>
    </g>
    <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M8.63891 5.29789C8.13556 6.47352 7.8096 8.1394 7.8096 10.0109C7.8096 11.8824 8.13556 13.5483 8.63891 14.7239C8.89105 15.3128 9.1759 15.751 9.46166 16.0333C9.74487 16.3131 9.99602 16.4104 10.2069 16.4104C10.4178 16.4104 10.6689 16.3131 10.9521 16.0333C11.2379 15.751 11.5227 15.3128 11.7749 14.7239C12.2782 13.5483 12.6042 11.8824 12.6042 10.0109C12.6042 8.1394 12.2782 6.47352 11.7749 5.29789C11.5227 4.709 11.2379 4.27076 10.9521 3.98846C10.6689 3.7087 10.4178 3.61142 10.2069 3.61142C9.99602 3.61142 9.74487 3.7087 9.46166 3.98846C9.1759 4.27076 8.89105 4.709 8.63891 5.29789ZM8.61929 3.13574C9.0531 2.70719 9.59027 2.41278 10.2069 2.41278C10.8235 2.41278 11.3607 2.70719 11.7945 3.13574C12.2257 3.56176 12.5866 4.14846 12.8768 4.82611C13.458 6.18354 13.8028 8.01705 13.8028 10.0109C13.8028 12.0047 13.458 13.8382 12.8768 15.1957C12.5866 15.8733 12.2257 16.46 11.7945 16.886C11.3607 17.3146 10.8235 17.609 10.2069 17.609C9.59027 17.609 9.0531 17.3146 8.61929 16.886C8.18803 16.46 7.82716 15.8733 7.53702 15.1957C6.95582 13.8382 6.61096 12.0047 6.61096 10.0109C6.61096 8.01705 6.95582 6.18354 7.53702 4.82611C7.82716 4.14846 8.18803 3.56176 8.61929 3.13574Z" fill="${p.bgColor}"/> 
    </svg>
    `,
    headerDuration: p => `
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M10.8333 7.16675C10.8333 6.70651 10.4602 6.33341 9.99992 6.33341C9.53967 6.33341 9.16659 6.70651 9.16659 7.16675H10.8333ZM9.99992 10.5001H9.16659C9.16659 10.7211 9.25442 10.9331 9.41067 11.0893L9.99992 10.5001ZM11.494 13.1727C11.8194 13.4981 12.3471 13.4981 12.6725 13.1727C12.9979 12.8472 12.9979 12.3196 12.6725 11.9942L11.494 13.1727ZM1.07733 4.28582C0.751893 4.61126 0.751893 5.1389 1.07733 5.46434C1.40277 5.78977 1.9304 5.78977 2.25584 5.46434L1.07733 4.28582ZM4.75584 2.96434C5.08128 2.6389 5.08128 2.11126 4.75584 1.78582C4.4304 1.46039 3.90277 1.46039 3.57733 1.78582L4.75584 2.96434ZM17.744 5.46434C18.0694 5.78977 18.5971 5.78977 18.9225 5.46434C19.2479 5.1389 19.2479 4.61126 18.9225 4.28582L17.744 5.46434ZM16.4225 1.78582C16.0971 1.46039 15.5694 1.46039 15.244 1.78582C14.9186 2.11126 14.9186 2.6389 15.244 2.96434L16.4225 1.78582ZM16.6666 10.5001C16.6666 14.182 13.6818 17.1667 9.99992 17.1667V18.8334C14.6023 18.8334 18.3333 15.1024 18.3333 10.5001H16.6666ZM9.99992 17.1667C6.31802 17.1667 3.33325 14.182 3.33325 10.5001H1.66659C1.66659 15.1024 5.39754 18.8334 9.99992 18.8334V17.1667ZM3.33325 10.5001C3.33325 6.81818 6.31802 3.83341 9.99992 3.83341V2.16675C5.39754 2.16675 1.66659 5.89771 1.66659 10.5001H3.33325ZM9.99992 3.83341C13.6818 3.83341 16.6666 6.81818 16.6666 10.5001H18.3333C18.3333 5.89771 14.6023 2.16675 9.99992 2.16675V3.83341ZM9.16659 7.16675V10.5001H10.8333V7.16675H9.16659ZM9.41067 11.0893L11.494 13.1727L12.6725 11.9942L10.5892 10.0893L9.41067 11.0893ZM2.25584 5.46434L4.75584 2.96434L3.57733 1.78582L1.07733 4.28582L2.25584 5.46434ZM18.9225 4.28582L16.4225 1.78582L15.244 2.96434L17.744 5.46434L18.9225 4.28582Z"
            fill="${remapFgColor(p)}"
             
        />
    </svg>
    `,
    headerIntegration:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M3.333 10.5a6.667 6.667 0 0012.442 3.333M3.333 10.5a6.667 6.667 0 0112.442-3.333M3.333 10.5H1.666"
        stroke="${remapFgColor(p)}"
         
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M13.333 10.5a3.333 3.333 0 11-6.667 0 3.333 3.333 0 016.667 0zm0 0h5"
        stroke="${p.bgColor}"
        stroke-width="1.5" vector-effect="non-scaling-stroke"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    </svg>`,
    headerGlideAIGenerateText:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" stroke="none"  xmlns="http://www.w3.org/2000/svg">
<path d="M3.33325 15H5.83325M3.33325 10H7.49992M3.33325 5H16.6666" stroke="${remapFgColor(
            p
        )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5714 9.57143C14.5714 9.25584 14.3156 9 14 9C13.6844 9 13.4286 9.25584 13.4286 9.57143C13.4286 10.6922 13.1803 11.3687 12.7745 11.7745C12.3687 12.1803 11.6922 12.4286 10.5714 12.4286C10.2558 12.4286 10 12.6844 10 13C10 13.3156 10.2558 13.5714 10.5714 13.5714C11.6922 13.5714 12.3687 13.8197 12.7745 14.2255C13.1803 14.6313 13.4286 15.3079 13.4286 16.4286C13.4286 16.7442 13.6844 17 14 17C14.3156 17 14.5714 16.7442 14.5714 16.4286C14.5714 15.3079 14.8197 14.6313 15.2255 14.2255C15.6313 13.8197 16.3079 13.5714 17.4286 13.5714C17.7442 13.5714 18 13.3156 18 13C18 12.6844 17.7442 12.4286 17.4286 12.4286C16.3079 12.4286 15.6313 12.1803 15.2255 11.7745C14.8197 11.3687 14.5714 10.6922 14.5714 9.57143Z" fill="${
            p.bgColor
        }"/>
</svg>

    `,
    headerGlideAIChoice:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" stroke="none"  fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.41675 8.33325C3.41675 7.91904 3.75253 7.58325 4.16675 7.58325H15.8334C16.2476 7.58325 16.5834 7.91904 16.5834 8.33325V16.6666C16.5834 17.541 15.8745 18.2499 15.0001 18.2499H5.00008C4.12564 18.2499 3.41675 17.5411 3.41675 16.6666V8.33325ZM4.91675 9.08325V16.6666C4.91675 16.7126 4.95405 16.7499 5.00008 16.7499H15.0001C15.0461 16.7499 15.0834 16.7126 15.0834 16.6666V9.08325H4.91675Z" fill="${remapFgColor(
            p
        )}"  />
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 11.6666C7.5 11.2063 7.8731 10.8333 8.33333 10.8333H11.6667C12.1269 10.8333 12.5 11.2063 12.5 11.6666C12.5 12.1268 12.1269 12.4999 11.6667 12.4999H8.33333C7.8731 12.4999 7.5 12.1268 7.5 11.6666Z" fill="${remapFgColor(
            p
        )}"  />
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.262 3.45213C16.6566 3.68892 16.7846 4.20081 16.5478 4.59546L14.2978 8.34546C14.061 8.74011 13.5491 8.86808 13.1545 8.63129C12.7598 8.3945 12.6318 7.88261 12.8686 7.48796L15.1186 3.73796C15.3554 3.34331 15.8673 3.21534 16.262 3.45213Z" fill="${remapFgColor(
            p
        )}"  />
<path d="M10.1322 2.46363L10.4945 1.73914C10.634 1.46 11.0325 1.46 11.172 1.73914L11.5343 2.46363C11.5708 2.53692 11.6303 2.59635 11.7036 2.633L12.4281 2.99525C12.7072 3.13481 12.7072 3.53315 12.4281 3.67272L11.7036 4.03499C11.6303 4.07158 11.5708 4.13104 11.5343 4.20436L11.172 4.92885C11.0325 5.20796 10.634 5.20796 10.4945 4.92885L10.1322 4.20436C10.0957 4.13104 10.0362 4.07158 9.96288 4.03499L9.23839 3.67272C8.95927 3.53315 8.95927 3.13481 9.23839 2.99525L9.96288 2.633C10.0362 2.59635 10.0957 2.53692 10.1322 2.46363Z" fill="${
            p.bgColor
        }"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4281 3.67272C12.7072 3.53315 12.7072 3.13481 12.4281 2.99525L11.7036 2.633C11.6303 2.59635 11.5708 2.53692 11.5343 2.46363L11.172 1.73914C11.0325 1.46 10.634 1.46 10.4945 1.73914L10.1322 2.46363C10.0957 2.53692 10.0362 2.59635 9.96288 2.633L9.23839 2.99525C8.95927 3.13481 8.95927 3.53315 9.23839 3.67272L9.96288 4.03499C10.0362 4.07158 10.0957 4.13104 10.1322 4.20436L10.4945 4.92885C10.634 5.20796 11.0325 5.20796 11.172 4.92885L11.5343 4.20436C11.5708 4.13104 11.6303 4.07158 11.7036 4.03499L12.4281 3.67272ZM12.0554 2.92737L12.0554 2.92737ZM11.25 3.33399C11.0791 3.23159 10.9358 3.08848 10.8333 2.91719C10.7307 3.08848 10.5874 3.23159 10.4165 3.33399C10.5876 3.43646 10.7308 3.57967 10.8333 3.75078C10.9357 3.57967 11.0789 3.43646 11.25 3.33399ZM9.61109 2.92737C9.61118 2.92741 9.61126 2.92745 9.61134 2.9275Z" fill="${
            p.bgColor
        }"/>
<path d="M5.54901 4.07194L5.67519 3.45546C5.72376 3.21795 6.02271 3.13785 6.18353 3.31925L6.60104 3.79005C6.64323 3.83769 6.6998 3.87033 6.76218 3.88309L7.37865 4.00924C7.61616 4.05784 7.69625 4.35675 7.51487 4.51759L7.04407 4.9351C6.99641 4.97729 6.96375 5.03387 6.95104 5.09624L6.82486 5.71272C6.77629 5.95021 6.47733 6.03031 6.31652 5.84893L5.899 5.37813C5.85681 5.33047 5.80024 5.29781 5.73786 5.2851L5.12139 5.15892C4.88389 5.11032 4.8038 4.81141 4.98518 4.65057L5.45598 4.23308C5.50363 4.19084 5.53629 4.13429 5.54901 4.07194Z" fill="${
            p.bgColor
        }"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.51487 4.51759C7.69625 4.35675 7.61616 4.05784 7.37865 4.00924L6.76218 3.88309C6.6998 3.87033 6.64323 3.83769 6.60104 3.79005L6.18353 3.31925C6.02271 3.13785 5.72376 3.21795 5.67519 3.45546L5.54901 4.07194C5.53629 4.13429 5.50363 4.19084 5.45598 4.23308L5.27871 4.39028L4.98518 4.65057C4.8038 4.81141 4.88389 5.11032 5.12139 5.15892L5.73786 5.2851C5.80024 5.29781 5.85681 5.33047 5.899 5.37813L6.31652 5.84893C6.47733 6.03031 6.77629 5.95021 6.82486 5.71272L6.95104 5.09624C6.96375 5.03386 6.99641 4.97729 7.04407 4.9351L7.51487 4.51759ZM6.27025 4.57866C6.2652 4.58714 6.26026 4.59569 6.25544 4.60431C6.24696 4.59926 6.23841 4.59432 6.22979 4.5895C6.23484 4.58101 6.23978 4.57246 6.2446 4.56385C6.25309 4.5689 6.26164 4.57383 6.27025 4.57866Z" fill="${
            p.bgColor
        }"/>
</svg>

    `,
    headerGlideAITextToDate:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" stroke="none"  fill='none' xmlns="http://www.w3.org/2000/svg">
<path d="M9.16659 16.6667H4.99992C4.07944 16.6667 3.33325 15.9205 3.33325 15V7.5M3.33325 7.5V5.83333C3.33325 4.91286 4.07944 4.16667 4.99992 4.16667H14.9999C15.9204 4.16667 16.6666 4.91286 16.6666 5.83333V7.5M3.33325 7.5H16.6666M16.6666 7.5V9.16667M6.66659 4.16667V2.5M13.3333 4.16667V2.5" stroke="${remapFgColor(
            p
        )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5709 11.5714C15.5709 11.2558 15.3151 11 14.9995 11C14.6839 11 14.4281 11.2558 14.4281 11.5714C14.4281 12.6922 14.1798 13.3687 13.774 13.7745C13.3682 14.1803 12.6917 14.4286 11.5709 14.4286C11.2554 14.4286 10.9995 14.6844 10.9995 15C10.9995 15.3156 11.2554 15.5714 11.5709 15.5714C12.6917 15.5714 13.3682 15.8197 13.774 16.2255C14.1798 16.6313 14.4281 17.3079 14.4281 18.4286C14.4281 18.7442 14.6839 19 14.9995 19C15.3151 19 15.5709 18.7442 15.5709 18.4286C15.5709 17.3079 15.8192 16.6313 16.225 16.2255C16.6308 15.8197 17.3074 15.5714 18.4281 15.5714C18.7437 15.5714 18.9995 15.3156 18.9995 15C18.9995 14.6844 18.7437 14.4286 18.4281 14.4286C17.3074 14.4286 16.6308 14.1803 16.225 13.7745C15.8192 13.3687 15.5709 12.6922 15.5709 11.5714Z" fill="${
            p.bgColor
        }"/>
</svg>
    `,
    headerGlideAIBoolean:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" stroke="none"  fill='none' xmlns="http://www.w3.org/2000/svg">
<path d="M16.3752 9.875V6.125C16.3752 4.74429 15.256 3.625 13.8752 3.625H5.12524C3.74454 3.625 2.62524 4.74429 2.62524 6.125V14.875C2.62524 16.2558 3.74454 17.375 5.12524 17.375H8.87524" stroke="${remapFgColor(
            p
        )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.49976 10.9973L8.75229 12.5L12.4998 7.5" stroke="${remapFgColor(
            p
        )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.0712 12.0714C15.0712 11.7558 14.8154 11.5 14.4998 11.5C14.1842 11.5 13.9284 11.7558 13.9284 12.0714C13.9284 13.1922 13.6801 13.8687 13.2743 14.2745C12.8685 14.6803 12.1919 14.9286 11.0712 14.9286C10.7556 14.9286 10.4998 15.1844 10.4998 15.5C10.4998 15.8156 10.7556 16.0714 11.0712 16.0714C12.1919 16.0714 12.8685 16.3197 13.2743 16.7255C13.6801 17.1313 13.9284 17.8079 13.9284 18.9286C13.9284 19.2442 14.1842 19.5 14.4998 19.5C14.8154 19.5 15.0712 19.2442 15.0712 18.9286C15.0712 17.8079 15.3195 17.1313 15.7253 16.7255C16.1311 16.3197 16.8077 16.0714 17.9284 16.0714C18.244 16.0714 18.4998 15.8156 18.4998 15.5C18.4998 15.1844 18.244 14.9286 17.9284 14.9286C16.8077 14.9286 16.1311 14.6803 15.7253 14.2745C15.3195 13.8687 15.0712 13.1922 15.0712 12.0714Z" fill="${
            p.bgColor
        }"/>
</svg>`,
    headerGlideAINumber:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
<path  d="M7.29191 3.625L5.62524 17.375M14.3752 3.625L13.7237 9M3.12524 6.95833H16.8752M3.12524 14.0417H8.50024" stroke="${remapFgColor(
            p
        )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
<path  d="M14.5716 11.0714C14.5716 10.7558 14.3158 10.5 14.0002 10.5C13.6846 10.5 13.4288 10.7558 13.4288 11.0714C13.4288 12.1922 13.1805 12.8687 12.7747 13.2745C12.3689 13.6803 11.6924 13.9286 10.5717 13.9286C10.2561 13.9286 10.0002 14.1844 10.0002 14.5C10.0002 14.8156 10.2561 15.0714 10.5717 15.0714C11.6924 15.0714 12.3689 15.3197 12.7747 15.7255C13.1805 16.1313 13.4288 16.8079 13.4288 17.9286C13.4288 18.2442 13.6846 18.5 14.0002 18.5C14.3158 18.5 14.5716 18.2442 14.5716 17.9286C14.5716 16.8079 14.8199 16.1313 15.2257 15.7255C15.6315 15.3197 16.3081 15.0714 17.4288 15.0714C17.7444 15.0714 18.0002 14.8156 18.0002 14.5C18.0002 14.1844 17.7444 13.9286 17.4288 13.9286C16.3081 13.9286 15.6315 13.6803 15.2257 13.2745C14.8199 12.8687 14.5716 12.1922 14.5716 11.0714Z" fill="${
            p.bgColor
        }"/>
</svg>

    `,
    headerGlideAIAudio:
        p => `<svg viewBox="0 0 20 20" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0008 15.8333V17.5M10.0008 15.8333C6.93565 15.8333 5.12319 13.9542 4.17944 12.5M10.0008 15.8333C13.066 15.8333 14.8785 13.9542 15.8223 12.5" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3344 7.83333V9.16667C13.3344 11.0076 11.842 12.5 10.0011 12.5C8.16011 12.5 6.66772 11.0076 6.66772 9.16667V5.83333C6.66772 3.99238 8.16011 2.5 10.0011 2.5" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.2137 3.02741L12.6123 2.23033C12.7658 1.92322 13.2041 1.92322 13.3576 2.23033L13.7562 3.02741C13.7965 3.10805 13.8619 3.17343 13.9426 3.21376L14.7396 3.6123C15.0467 3.76585 15.0467 4.2041 14.7396 4.35765L13.9426 4.75622C13.8619 4.79647 13.7965 4.86189 13.7562 4.94256L13.3576 5.73964C13.2041 6.04672 12.7658 6.04672 12.6123 5.73964L12.2137 4.94256C12.1735 4.86189 12.1081 4.79647 12.0274 4.75622L11.2303 4.35765C10.9232 4.2041 10.9232 3.76585 11.2303 3.6123L12.0274 3.21376C12.1081 3.17343 12.1735 3.10805 12.2137 3.02741Z" fill="${
        p.bgColor
    }"/>
    </svg>
    `,
    headerGlideAIImage:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 5C2.5 3.61929 3.61929 2.5 5 2.5H15C16.3808 2.5 17.5 3.61929 17.5 5V15C17.5 16.3808 16.3808 17.5 15 17.5H5C3.61929 17.5 2.5 16.3808 2.5 15V5ZM5 4.16667C4.53977 4.16667 4.16667 4.53977 4.16667 5V11.3215L4.8989 10.5892C5.87521 9.61292 7.45812 9.61292 8.43442 10.5892L13.6785 15.8333H15C15.4602 15.8333 15.8333 15.4602 15.8333 15V5C15.8333 4.53977 15.4602 4.16667 15 4.16667H5ZM11.3215 15.8333L7.25593 11.7677C6.93048 11.4423 6.40285 11.4423 6.07741 11.7677L4.16667 13.6785V15C4.16667 15.4602 4.53977 15.8333 5 15.8333H11.3215Z" fill="${remapFgColor(
        p
    )}" />
    <path d="M11.3124 6.95906L11.7109 6.16197C11.8644 5.85486 12.3028 5.85486 12.4563 6.16197L12.8549 6.95906C12.8951 7.03969 12.9605 7.10507 13.0412 7.1454L13.8383 7.54394C14.1454 7.69749 14.1454 8.13574 13.8383 8.28929L13.0412 8.68786C12.9605 8.72811 12.8951 8.79353 12.8549 8.8742L12.4563 9.67128C12.3028 9.97836 11.8644 9.97836 11.7109 9.67128L11.3124 8.8742C11.2721 8.79353 11.2067 8.72811 11.126 8.68786L10.3289 8.28929C10.0219 8.13574 10.0219 7.69749 10.3289 7.54394L11.126 7.1454C11.2067 7.10507 11.2721 7.03969 11.3124 6.95906Z" fill="${
        p.bgColor
    }"/>
    </svg>
    `,
    headerGlideAIDocument:
        p => `<svg width="20" height="20" viewBox="0 0 20 20" stroke="none"   fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.33325 4.16675C3.33325 2.78604 4.45254 1.66675 5.83325 1.66675H15.8333C16.2935 1.66675 16.6666 2.03985 16.6666 2.50008V17.5001C16.6666 17.9603 16.2935 18.3334 15.8333 18.3334H5.83325C4.45254 18.3334 3.33325 17.2142 3.33325 15.8334V4.16675ZM4.99992 13.4757V4.16675C4.99992 3.70651 5.37302 3.33341 5.83325 3.33341H14.9999V13.3334H5.83325C5.54105 13.3334 5.26057 13.3836 4.99992 13.4757ZM14.9999 15.0001H5.83325C5.37302 15.0001 4.99992 15.3732 4.99992 15.8334C4.99992 16.2937 5.37302 16.6667 5.83325 16.6667H14.9999V15.0001Z" fill="${remapFgColor(
            p
        )}"  />
<path d="M10.1538 6.17317C10.2344 6.13285 10.2999 6.06747 10.3401 5.98683L10.8776 4.91197C11.0311 4.60486 11.4694 4.60486 11.6229 4.91197L12.1604 5.98683C12.2007 6.06747 12.266 6.13285 12.3467 6.17317L13.4215 6.7106C13.7287 6.86415 13.7287 7.30241 13.4215 7.45596L12.3467 7.99339C12.266 8.0337 12.2007 8.09909 12.1604 8.17973L11.6229 9.25461C11.4694 9.5617 11.0311 9.5617 10.8776 9.25461L10.3401 8.17973C10.2999 8.09909 10.2344 8.0337 10.1538 7.99339L9.07895 7.45596C8.77186 7.30241 8.77186 6.86415 9.07895 6.7106L10.1538 6.17317Z" fill="${
            p.bgColor
        }"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.4215 6.7106L12.3467 6.17317C12.266 6.13285 12.2007 6.06747 12.1604 5.98683L11.6229 4.91197C11.4694 4.60486 11.0311 4.60486 10.8776 4.91197L10.3401 5.98683C10.2999 6.06747 10.2344 6.13285 10.1538 6.17317L9.57589 6.46213L9.07895 6.7106C8.77186 6.86415 8.77186 7.30241 9.07895 7.45596L9.57587 7.70443L10.1538 7.99339C10.2344 8.0337 10.2999 8.09909 10.3401 8.17973L10.8776 9.25461C11.0311 9.5617 11.4694 9.5617 11.6229 9.25461L12.1604 8.17973C12.2007 8.09909 12.266 8.0337 12.3467 7.99339L13.4215 7.45596C13.7287 7.30241 13.7287 6.86415 13.4215 6.7106Z" fill="${
            p.bgColor
        }"/>
<path d="M7.14792 9.75974C7.1963 9.73558 7.23553 9.69633 7.25972 9.64791L7.69328 8.78083C7.78542 8.59649 8.04837 8.59649 8.1405 8.78083L8.57408 9.64791C8.59825 9.69633 8.6375 9.73558 8.68583 9.75974L9.553 10.1933C9.73725 10.2854 9.73725 10.5484 9.553 10.6405L8.68583 11.0741C8.6375 11.0982 8.59825 11.1375 8.57408 11.1859L8.1405 12.053C8.04837 12.2373 7.78542 12.2373 7.69329 12.053L7.25972 11.1859C7.23553 11.1375 7.1963 11.0982 7.14792 11.0741L6.28078 10.6405C6.09652 10.5484 6.09651 10.2854 6.28078 10.1933L7.14792 9.75974Z" fill="${
            p.bgColor
        }"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.1405 12.053C8.04837 12.2373 7.78542 12.2373 7.69329 12.053L7.25972 11.1859C7.23553 11.1375 7.1963 11.0982 7.14792 11.0741L6.28078 10.6405C6.09652 10.5484 6.09651 10.2854 6.28078 10.1933L7.14792 9.75974C7.1963 9.73558 7.23553 9.69633 7.25972 9.64791L7.69328 8.78083C7.78542 8.59649 8.04837 8.59649 8.1405 8.78083L8.57408 9.64791C8.59825 9.69633 8.6375 9.73558 8.68583 9.75974L9.553 10.1933C9.73725 10.2854 9.73725 10.5484 9.553 10.6405L8.68583 11.0741C8.6375 11.0982 8.59825 11.1375 8.57408 11.1859L8.1405 12.053ZM8.60134 10.5151C8.58467 10.4817 8.56697 10.449 8.5483 10.4169Z" fill="${
            p.bgColor
        }"/>
</svg>`,
    headerGlideAIJson:
        p => `<svg width="20" height="20"  viewBox="0 0 20 20" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8908_3178)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.20841 5.625C3.20841 3.83008 4.66349 2.375 6.45841 2.375H6.87508C7.28929 2.375 7.62508 2.71079 7.62508 3.125C7.62508 3.53921 7.28929 3.875 6.87508 3.875H6.45841C5.49192 3.875 4.70841 4.65851 4.70841 5.625V8.33333C4.70841 8.97949 4.45483 9.56641 4.04176 10C4.45483 10.4336 4.70841 11.0205 4.70841 11.6667V14.375C4.70841 15.3415 5.49191 16.125 6.45841 16.125H6.87508C7.28929 16.125 7.62508 16.4608 7.62508 16.875C7.62508 17.2892 7.28929 17.625 6.87508 17.625H6.45841C4.6635 17.625 3.20841 16.17 3.20841 14.375V11.6667C3.20841 11.1604 2.79802 10.75 2.29175 10.75C1.87753 10.75 1.54175 10.4142 1.54175 10C1.54175 9.58579 1.87753 9.25 2.29175 9.25C2.79802 9.25 3.20841 8.83961 3.20841 8.33333V5.625ZM12.3751 3.125C12.3751 2.71079 12.7109 2.375 13.1251 2.375H13.5417C15.3367 2.375 16.7917 3.83009 16.7917 5.625V8.33333C16.7917 8.83962 17.2021 9.25 17.7084 9.25C18.1226 9.25 18.4584 9.58579 18.4584 10C18.4584 10.4142 18.1226 10.75 17.7084 10.75C17.2021 10.75 16.7917 11.1604 16.7917 11.6667V14.375C16.7917 16.17 15.3367 17.625 13.5417 17.625H13.1251C12.7109 17.625 12.3751 17.2892 12.3751 16.875C12.3751 16.4608 12.7109 16.125 13.1251 16.125H13.5417C14.5083 16.125 15.2917 15.3415 15.2917 14.375V11.6667C15.2917 11.0205 15.5453 10.4336 15.9584 10C15.5453 9.5664 15.2917 8.97949 15.2917 8.33333V5.625C15.2917 4.6585 14.5083 3.875 13.5417 3.875H13.1251C12.7109 3.875 12.3751 3.53921 12.3751 3.125Z" fill="${remapFgColor(
            p
        )}"  />
<path d="M10.5714 6.57143C10.5714 6.25584 10.3156 6 10 6C9.6844 6 9.4286 6.25584 9.4286 6.57143C9.4286 7.69215 9.1803 8.36869 8.7745 8.77451C8.3687 9.1803 7.69215 9.4286 6.57143 9.4286C6.25584 9.4286 6 9.6844 6 10C6 10.3156 6.25584 10.5714 6.57143 10.5714C7.69215 10.5714 8.3687 10.8197 8.7745 11.2255C9.1803 11.6313 9.4286 12.3079 9.4286 13.4286C9.4286 13.7442 9.6844 14 10 14C10.3156 14 10.5714 13.7442 10.5714 13.4286C10.5714 12.3079 10.8197 11.6313 11.2255 11.2255C11.6313 10.8197 12.3079 10.5714 13.4286 10.5714C13.7442 10.5714 14 10.3156 14 10C14 9.6844 13.7442 9.4286 13.4286 9.4286C12.3079 9.4286 11.6313 9.1803 11.2255 8.77451C10.8197 8.36869 10.5714 7.69215 10.5714 6.57143Z" fill="${
            p.bgColor
        }"/>
</g>
<defs>
<clipPath id="clip0_8908_3178">
<rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)"/>
</clipPath>
</defs>
</svg>`,
    headerGenerateImage: p => `
    <svg width="20" height="20" viewBox="0 0 20 20" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 5C2.5 3.61929 3.61929 2.5 5 2.5H15C16.3807 2.5 17.5 3.61929 17.5 5V15C17.5 16.3807 16.3807 17.5 15 17.5H5C3.61929 17.5 2.5 16.3807 2.5 15V5ZM5 4.16667C4.53977 4.16667 4.16667 4.53977 4.16667 5V11.3215L4.8989 10.5892C5.87521 9.61292 7.45812 9.61292 8.43442 10.5892L13.6785 15.8333H15C15.4602 15.8333 15.8333 15.4602 15.8333 15V5C15.8333 4.53977 15.4602 4.16667 15 4.16667H5ZM11.3215 15.8333L7.25593 11.7677C6.93048 11.4423 6.40285 11.4423 6.07741 11.7677L4.16667 13.6785V15C4.16667 15.4602 4.53977 15.8333 5 15.8333H11.3215Z" fill="${remapFgColor(
        p
    )}"  />
<path d="M11.3121 6.9593L11.7107 6.16222C11.8642 5.85511 12.3025 5.85511 12.456 6.16222L12.8546 6.9593C12.8949 7.03993 12.9603 7.10532 13.041 7.14564L13.838 7.54418C14.1451 7.69773 14.1451 8.13598 13.838 8.28953L13.041 8.68811C12.9603 8.72836 12.8949 8.79377 12.8546 8.87444L12.456 9.67152C12.3025 9.97861 11.8642 9.97861 11.7107 9.67152L11.3121 8.87444C11.2719 8.79377 11.2065 8.72836 11.1258 8.68811L10.3287 8.28953C10.0216 8.13598 10.0216 7.69773 10.3287 7.54418L11.1258 7.14564C11.2065 7.10532 11.2719 7.03993 11.3121 6.9593Z" fill="${
        p.bgColor
    }"/>
    </svg>
`,
    headerGlobe: p => `
<svg width="20" height="20"   viewBox="0 0 20 20" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 10H2.5M10 17.5C8.15905 17.5 6.66667 14.1422 6.66667 10C6.66667 5.85787 8.15905 2.5 10 2.5C11.8409 2.5 13.3333 5.85787 13.3333 10C13.3333 14.1422 11.8409 17.5 10 17.5Z" stroke="${
        p.bgColor
    }" stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="square"/>
<path d="M10 17.5C14.1422 17.5 17.5 14.1422 17.5 10C17.5 5.85787 14.1422 2.5 10 2.5C5.85787 2.5 2.5 5.85787 2.5 10C2.5 14.1422 5.85787 17.5 10 17.5Z" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="square"/>
</svg>
`,
    headerMakeArray: p => `
    <svg width="20" height="20"  viewBox="0 0 20 20" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1667 3.33325H15.0001C15.9206 3.33325 16.6667 4.07944 16.6667 4.99992V14.9999C16.6667 15.9204 15.9206 16.6666 15.0001 16.6666H14.1667" stroke="${
        p.bgColor
    }" stroke-width="1.5" vector-effect="non-scaling-stroke"  stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.8335 3.33301H5.00016C4.07969 3.33301 3.3335 4.0792 3.3335 4.99967V14.9997C3.3335 15.9202 4.07969 16.6663 5.00016 16.6663H5.8335" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,

    headerMultipleFiles: p => `
    <svg width="20" height="20" viewBox="0 0 20 20" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.375 15.2084V16.0417C14.375 16.9622 13.6289 17.7084 12.7084 17.7084H5.62504C4.70457 17.7084 3.95837 16.9622 3.95837 16.0417V6.45842C3.95837 5.53794 4.70457 4.79175 5.62504 4.79175H6.45837M12.5665 2.44934V4.94934C12.5665 5.86982 13.3127 6.61601 14.2332 6.61601H16.7332M12.7084 2.29175H8.12504C7.20457 2.29175 6.45837 3.03794 6.45837 3.95841V13.5417C6.45837 14.4622 7.20457 15.2084 8.12504 15.2084H15.2084C16.1289 15.2084 16.875 14.4622 16.875 13.5417V6.45842L12.7084 2.29175Z" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke" stroke-linejoin="round"/>

    </svg>
    `,

    headerMultipleImages: p => `
    <svg width="20" height="20" viewBox="0 0 20 20" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.3334 15.6666L8.84526 10.1784C8.19438 9.52758 7.13912 9.52758 6.48824 10.1784L4.33342 12.3333M13.712 15.6672V16.5004C13.712 17.4207 12.9658 18.1667 12.0453 18.1667C11.1248 18.1667 3.50004 18.1667 3.50004 18.1667C2.57957 18.1667 1.83337 17.4207 1.83337 16.5004V7.91857C1.83337 6.99826 2.57957 6.2522 3.50004 6.2522H4.33337M6.00008 15.6666H16.0001C16.9206 15.6666 17.6667 14.9204 17.6667 13.9999V3.99992C17.6667 3.07944 16.9206 2.33325 16.0001 2.33325H6.00008C5.07961 2.33325 4.33342 3.07944 4.33342 3.99992V13.9999C4.33342 14.9204 5.07961 15.6666 6.00008 15.6666ZM14.7501 6.91658C14.7501 7.83708 14.0039 8.58325 13.0834 8.58325C12.1629 8.58325 11.4167 7.83708 11.4167 6.91658C11.4167 5.99611 12.1629 5.24992 13.0834 5.24992C14.0039 5.24992 14.7501 5.99611 14.7501 6.91658Z" stroke="${remapFgColor(
        p
    )}"   stroke-width="1.5" vector-effect="non-scaling-stroke"/>

    </svg>
    `,

    headerMultipleStrings: p => `
    <svg id="icon-import" viewBox="0 0 20 20" fill="none" stroke="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 5.77419V4H12M12 4H16.5V5.77419M12 4V15M12 15H10.38M12 15H13.62"
        stroke="${remapFgColor(p)}" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    <path
        d="M3 10.1053V9H5.98037M5.98037 9H9V10.1053M5.98037 9V15M5.98037 15H4.65633M5.98037 15H7.3044"
        stroke="${remapFgColor(p)}" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
</svg>
    `,
};
