import type { EminenceFlags } from "@glide/billing-types";
import { withBuilderEminenceFlags } from "@glide/billing-ui";
import {
    type ThemePlatform,
    TailwindThemeProvider,
    browserCertainlyIsOniOS,
    browserIsAndroidChrome,
    browserIsSafari,
    getMemoizeTheme,
} from "@glide/common";
import type { AppFeatures, IconImage } from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import type {
    AppUserAuthenticator,
    AppUserChangedCallback,
    ResponseStatus,
} from "@glide/common-core/dist/js/components/types";
import type {
    RegisterForPushNotificationsBody,
    RequestAppUserAccessBody,
} from "@glide/common-core/dist/js/firebase-function-types";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import { DeviceFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { reloadBrowserWindow } from "@glide/common-core/dist/js/support/browser-reload";
import { standalone } from "@glide/common-core/dist/js/support/device";
import { withPlayerEminenceFlags } from "@glide/player-core";
import {
    getAddToHomeKey,
    getEmailKey,
    getLoginTokenKey,
    getPasswordForEmailKey,
    getPasswordKey,
    getUsernameKey,
    isDefined,
    isResponseOK,
    localStorageGetItem,
    localStorageRemoveItem,
    localStorageSetItem,
    logError,
    logInfo,
    safeURL,
} from "@glide/support";
import { isOSThemeDark, watchOSThemeDark } from "@glide/theme";
import classNames from "classnames";
import MobileDetect from "mobile-detect";
import * as React from "react";
import { signOutAppUser } from "../../../lib/sign-out";

import {
    type AppCredentials,
    type UsernameProxy,
    CredentialsProvider,
    clearAppPasswordLocalStorage,
    getAppLoginTokenExpirationTimestamp,
    shouldSaveLogin,
    tryRegisterOnPushTokenLoadCallback,
    tryUnregisterOnPushTokenLoadCallback,
} from "@glide/auth-controller-core";
import deploymentVersion from "../../../../deployment-version.json";
import App from "../../views/app/app";
import HomeBar from "../../views/home-bar/home-bar";
import StatusBar from "../../views/status-bar/status-bar";
import FontScaleProvider from "../font-scale-provider/font-scale-provider";
import type { BaseTheme } from "@glide/base-theme";

interface Props extends React.PropsWithChildren {
    title: string | undefined;
    iconImage: IconImage | undefined;
    theme: BaseTheme | undefined;
    useFrame: boolean;
    forceTheme?: ThemePlatform;
    isSplash?: boolean;
    appID: string | undefined;
    deviceFormFactor: DeviceFormFactor;
    isBuilder: boolean;
    showBranding: boolean;
    showingApp: boolean;
    appFeatures: AppFeatures;
    eminenceFlags: EminenceFlags;
    authenticator: AppUserAuthenticator | undefined;
}

interface State {
    showAddToHome: boolean;
    isDarkMode: boolean;
}

let hasShownAddToHomeOnce: boolean = false;

// This is so that we can share authentication context
// across many apps in the builder
let credStore = {
    username: "",
    email: "",
    emailPassword: "",
    canChangeEmail: true,
    canChangeUsername: true,
    isAuthenticated: false,
};

class AppContainer extends React.Component<Props, State> {
    private appUserPushCallbacks: { [appUserID: string]: (token: string) => Promise<void> } = {};
    private verifiedEmailChangeCallback?: AppUserChangedCallback;
    private urlSanitizationObserver: MutationObserver | undefined;
    private proxy: UsernameProxy | undefined;

    public state: State = {
        showAddToHome: false,
        isDarkMode: isOSThemeDark(),
    };

    constructor(props: Props) {
        super(props);

        const appID = props.appID;

        if (props.isBuilder === true) {
            this.appCredentials = { ...this.appCredentials, isAuthenticated: true };
        }

        if (appID !== undefined) {
            const username = localStorageGetItem(getUsernameKey(appID));
            if (username !== undefined) {
                this.appCredentials = { ...this.appCredentials, username };
            }

            const email = localStorageGetItem(getEmailKey(appID));
            if (email !== undefined) {
                this.appCredentials = { ...this.appCredentials, email };
            }

            const emailPassword = localStorageGetItem(getPasswordForEmailKey(appID));
            if (emailPassword !== undefined) {
                this.appCredentials = { ...this.appCredentials, emailPassword };
            }

            const password = localStorageGetItem(getPasswordKey(appID));
            if (password !== undefined) {
                this.appCredentials = { ...this.appCredentials, password };
            }

            this.verifiedEmailChangeCallback = async (
                appUserID,
                realEmail,
                _virtualEmail,
                loginToken,
                fromMagicLink
            ) => {
                if (appUserID === undefined) return;
                logInfo(`appUserID is ${appUserID}`);

                if (this.props.isBuilder) {
                    this.appCredentials = { ...this.appCredentials, email: realEmail ?? "" };
                } else if (
                    // There is a race condition between this and updateCredentials:
                    // someone (either us or them) might be attempting to assert a
                    // relatively stale login token over the other.
                    loginToken !== undefined &&
                    (this.appCredentials.loginToken === undefined ||
                        getAppLoginTokenExpirationTimestamp(this.appCredentials.loginToken) <
                            getAppLoginTokenExpirationTimestamp(loginToken))
                ) {
                    this.appCredentials = { ...this.appCredentials, loginToken };
                    if (shouldSaveLogin(appID, this.props.appFeatures)) {
                        localStorageSetItem(getLoginTokenKey(appID), JSON.stringify(loginToken));
                    }
                }

                if (fromMagicLink) {
                    this.updateCredentials(realEmail, undefined, undefined, loginToken, false);
                    this.setAuthenticated(true);
                }
            };

            this.props.authenticator?.addCallback(this.verifiedEmailChangeCallback);
        }
        this.checkAppLoginToken();
    }

    // FIXME: This is not the most appropriate way to do this.
    // We really should be more vigilant about sanitizing URLs as they render in apps
    // _before_ they hit the DOM. What's currently thwarting us is that we allow HTML
    // in Markdown components, and there's no solid way to fix _those_ URLs.
    private hookURLSanitizationObserver = (element: HTMLElement) => {
        // element === null when the component unmounts.
        // That means it's time to disconnect.
        if (element === null) {
            if (this.urlSanitizationObserver !== undefined) {
                this.urlSanitizationObserver.disconnect();
                this.urlSanitizationObserver = undefined;
            }
            return;
        }

        const fixupHrefs = (target: Element) => {
            const origHREF = target.getAttribute("href");
            if (origHREF === null) return;

            const fixedHREF = safeURL(origHREF);

            if (fixedHREF === undefined) {
                target.removeAttribute("href");
            } else if (fixedHREF !== origHREF) {
                target.setAttribute("href", fixedHREF);
            }
        };

        this.urlSanitizationObserver = new MutationObserver(mutations => {
            for (const mutation of mutations) {
                const { target, type, attributeName, addedNodes } = mutation;

                if (type === "attributes" && attributeName === "href" && target instanceof Element) {
                    fixupHrefs(target);
                } else if (type === "childList") {
                    addedNodes.forEach(node => {
                        if (!(node instanceof Element)) return;
                        fixupHrefs(node);

                        const anchorNodes = node.getElementsByTagName("a");
                        // Yuck, yes, but this is all the types allow us to do.
                        for (let i = 0; i < anchorNodes.length; i++) {
                            fixupHrefs(anchorNodes[i]);
                        }
                    });
                }
            }
        });

        this.urlSanitizationObserver.observe(element, {
            subtree: true,
            childList: true,
            attributes: true,
            attributeFilter: ["href"],
        });
    };

    private setAuthenticated(isAuthenticated: boolean) {
        this.setAppCredentials({
            ...this.appCredentials,
            isAuthenticated,
            canChangeEmail: !isAuthenticated,
        });

        credStore = this.appCredentials;
        this.forceUpdate();
    }

    private updateCredentials(
        email: string | undefined,
        emailPassword: string | undefined,
        username: string | undefined,
        loginToken: AppLoginTokenContainer | undefined,
        forceUpdate: boolean
    ) {
        const newCredentials = { ...this.appCredentials };
        if (email !== undefined) {
            newCredentials.email = email;
        }
        if (emailPassword !== undefined) {
            newCredentials.emailPassword = emailPassword;
        }
        if (username !== undefined) {
            newCredentials.username = username;
        }
        if (
            // There is a race condition between this and verifiedEmailChangeCallback:
            // someone (either us or them) might be attempting to assert a
            // relatively stale login token over the other.
            loginToken !== undefined &&
            (this.appCredentials.loginToken === undefined ||
                getAppLoginTokenExpirationTimestamp(this.appCredentials.loginToken) <
                    getAppLoginTokenExpirationTimestamp(loginToken))
        ) {
            newCredentials.loginToken = loginToken;
        }
        this.setAppCredentials(newCredentials);

        if (this.props.appID !== undefined) {
            if (shouldSaveLogin(this.props.appID, this.props.appFeatures)) {
                localStorageSetItem(getUsernameKey(this.props.appID), this.appCredentials.username);
                localStorageSetItem(getEmailKey(this.props.appID), this.appCredentials.email);
                localStorageSetItem(getPasswordForEmailKey(this.props.appID), this.appCredentials.emailPassword);

                const loginTokenKey = getLoginTokenKey(this.props.appID);
                if (this.appCredentials.loginToken === undefined) {
                    localStorageRemoveItem(loginTokenKey);
                } else {
                    localStorageSetItem(
                        getLoginTokenKey(this.props.appID),
                        JSON.stringify(this.appCredentials.loginToken)
                    );
                }
            } else {
                clearAppPasswordLocalStorage(this.props.appID);
            }
        }

        credStore = this.appCredentials;

        if (forceUpdate) {
            this.forceUpdate();
        }
    }

    private sendPin = async (
        email: string,
        privateMagicLinkToken: string | undefined
    ): Promise<{ status: ResponseStatus; link?: string }> => {
        const { authenticator } = this.props;
        if (authenticator === undefined) return { status: "Offline" };
        const result = await authenticator.sendPinForEmail(
            email,
            deploymentVersion.deploymentVersion,
            privateMagicLinkToken
        );
        return result;
    };

    private passwordForPin = async (
        email: string,
        pin: string,
        userAgreed: boolean
    ): Promise<{ password: string | undefined; loginToken: AppLoginTokenContainer | undefined } | undefined> => {
        const { authenticator } = this.props;
        if (authenticator === undefined) return undefined;

        const result = await authenticator.getPasswordForEmailPin(email, pin, userAgreed);
        if (result !== undefined) {
            this.updateCredentials(email, result.password, undefined, result.loginToken, false);
            this.setAuthenticated(true);
        }
        return result;
    };

    private emailAndPasswordForOAuth2Token = async (
        authToken: string,
        userAgreed: boolean
    ): Promise<
        { email: string; password: string | undefined; loginToken: AppLoginTokenContainer | undefined } | undefined
    > => {
        const { authenticator } = this.props;
        if (authenticator === undefined) return undefined;

        const result = await authenticator.getPasswordForOAuth2Token(authToken, userAgreed);
        if (result !== undefined) {
            const { email, password, displayName, appLoginToken } = result;
            if (password !== undefined || appLoginToken !== undefined) {
                this.updateCredentials(email, password, displayName, appLoginToken, false);
                this.setAuthenticated(true);
            }
            return { email, password, loginToken: appLoginToken };
        } else {
            return undefined;
        }
    };

    private authenticate = async (
        email: string,
        password: string | undefined,
        authToken: AppLoginTokenContainer | undefined
    ): Promise<boolean> => {
        const { authenticator } = this.props;
        if (authenticator === undefined) return false;

        let result: boolean;
        if (authToken !== undefined) {
            result = await authenticator.authorizeForAppWithLoginToken(authToken, email, password);
        } else if (password !== undefined) {
            result = await authenticator.authorizeForAppWithEmail(email, password);
        } else {
            return false;
        }
        if (result) {
            this.updateCredentials(email, password, undefined, authToken, false);
            this.setAuthenticated(true);
        }
        return result;
    };

    private setUsername = async (username: string): Promise<boolean> => {
        this.updateCredentials(undefined, undefined, username, undefined, true);
        this.proxy?.setUsername(username);
        return true;
    };

    private getAuthenticator = () => {
        return this.props.authenticator;
    };

    private setUsernameProxy = (proxy: UsernameProxy) => {
        if (this.proxy === undefined) {
            this.forceUpdate();
        }
        this.proxy = proxy;
    };

    private async requestAccess(email: string): Promise<{ status: ResponseStatus }> {
        if (this.props.appID !== undefined) {
            const body: RequestAppUserAccessBody = { appID: this.props.appID, email };
            const response = await getAppFacilities().callAuthIfAvailableCloudFunction(
                "requestAppUserAccess",
                body,
                {}
            );

            if (!isResponseOK(response)) {
                if (response === undefined || Math.floor(response.status / 100) !== 4) {
                    const responseKind = response === undefined ? "no" : "bad";
                    logError(`Could not request app access - ${responseKind} response, check network status`, response);
                    return { status: "Offline" };
                }
                if (response.status === 402) {
                    return { status: "PaymentRequired" };
                }
                return { status: "Forbidden" };
            }
        }
        return { status: "Success" };
    }

    private appCredentials: AppCredentials = {
        ...credStore,
        password: "",
        loginToken: undefined,
        sendPin: this.sendPin,
        passwordForPin: this.passwordForPin,
        emailAndPasswordForOAuth2Token: this.emailAndPasswordForOAuth2Token,
        authenticate: this.authenticate,
        setUsername: this.setUsername,
        getAuthenticator: this.getAuthenticator,
        setUsernameProxy: this.setUsernameProxy,
        requestAccess: async (email: string) => this.requestAccess(email),
    };

    private setAppCredentials(newCredentials: AppCredentials): void {
        this.appCredentials = newCredentials;
        this.checkAppLoginToken();
    }

    public componentDidMount() {
        if (
            !standalone &&
            browserCertainlyIsOniOS &&
            browserIsSafari &&
            getAppKindFromFeatures(this.props.appFeatures) === AppKind.App &&
            !hasShownAddToHomeOnce &&
            localStorageGetItem(getAddToHomeKey(this.props.appID)) !== "seen"
        ) {
            hasShownAddToHomeOnce = true;
            setTimeout(() => {
                this.setState({ showAddToHome: true });
            }, 200);
        }

        watchOSThemeDark(e => {
            if (this.state.isDarkMode === e) return;
            if (browserIsAndroidChrome) {
                reloadBrowserWindow("dark mode changed");
            } else {
                this.setState({ isDarkMode: e });
            }
        });

        const appUserID = this.props.authenticator?.appUserID;
        const appID = this.props.authenticator?.appID;
        if (!isDefined(appUserID) || !isDefined(appID)) {
            return;
        }

        const handlePushNotificationToken = async (token: string) => {
            const body: RegisterForPushNotificationsBody = { appID, appUserID, registrationToken: token };
            const response = await getAppFacilities().callAuthCloudFunction("registerForPushNotifications", body, {});
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void response?.text();
            if (!isResponseOK(response)) {
                const reason = response === undefined ? "Network error" : response.statusText;
                logError(`Could not register ${appUserID} in ${appID} for push notifications: ${reason}`);
            }
        };

        this.appUserPushCallbacks[appUserID] = handlePushNotificationToken;
        void tryRegisterOnPushTokenLoadCallback(handlePushNotificationToken);
    }

    public componentWillUnmount() {
        if (this.verifiedEmailChangeCallback !== undefined) {
            this.props.authenticator?.removeCallback(this.verifiedEmailChangeCallback);
        }
        for (const appUserIDKey in this.appUserPushCallbacks) {
            if (this.appUserPushCallbacks[appUserIDKey] === undefined) continue;
            void tryUnregisterOnPushTokenLoadCallback(this.appUserPushCallbacks[appUserIDKey]);
        }
    }

    // We log the user out here if the timestamp in the ##authOnlyData is
    // expired.
    private recheckAppLoginTokenTimeout: ReturnType<typeof setTimeout> | undefined;
    private readonly checkAppLoginToken = (): void => {
        if (this.recheckAppLoginTokenTimeout !== undefined) {
            clearTimeout(this.recheckAppLoginTokenTimeout);
            this.recheckAppLoginTokenTimeout = undefined;
        }

        if (this.props.isBuilder || this.props.appID === undefined) return;

        const { loginToken } = this.appCredentials;
        if (loginToken === undefined) return;

        const timestamp = getAppLoginTokenExpirationTimestamp(loginToken);
        const timeLeft = timestamp - Date.now();
        if (timeLeft <= 0) {
            signOutAppUser(this.props.appID);
            return;
        }

        this.recheckAppLoginTokenTimeout = setTimeout(this.checkAppLoginToken, timeLeft);
    };

    public render(): React.ReactNode {
        const { theme, useFrame, isSplash, deviceFormFactor, appFeatures } = this.props;
        const { isDarkMode } = this.state;
        let platform: ThemePlatform | undefined = this.props.forceTheme;
        if (platform === undefined) {
            if (new MobileDetect(window.navigator.userAgent).is("AndroidOS")) {
                platform = "Android";
            } else {
                platform = "iOS";
            }
        }
        const appKind = getAppKindFromFeatures(appFeatures);
        const themeForPlatform = getMemoizeTheme(
            appKind === AppKind.Page,
            theme,
            platform,
            deviceFormFactor,
            isDarkMode,
            isDarkMode
        );
        let statusBar = null;
        let homeBar = null;

        if (useFrame && deviceFormFactor !== DeviceFormFactor.Desktop) {
            statusBar = (
                <StatusBar
                    isSplash={isSplash === true}
                    appKind={appKind}
                    isPageLight={
                        /* Prop name makes this so confusing, but return true if 
                        in a light OS and Env mode while style is Highlight
                        or if the style is Highlight + Env are Light and OS is dark mode */
                        (isDarkMode === false &&
                            themeForPlatform.pageEnvironment !== "Dark" &&
                            themeForPlatform.pageTheme === "Highlight") ||
                        (themeForPlatform.pageTheme === "Highlight" &&
                            (themeForPlatform.pageEnvironment === "Light" ||
                                themeForPlatform.pageEnvironment === undefined) &&
                            isDarkMode)
                    }
                />
            );
            homeBar = <HomeBar isSplash={isSplash === true} />;
        }

        // The types for `ref` are wrong. It doesn't _have_ to be & string.
        const fixedRef = this.hookURLSanitizationObserver as ((element: HTMLElement) => void) & string;

        return (
            <CredentialsProvider value={this.appCredentials}>
                <TailwindThemeProvider theme={themeForPlatform}>
                    <FontScaleProvider>
                        <App
                            className={classNames(useFrame ? "builder" : "player", deviceFormFactor)}
                            appFeatures={appFeatures}
                            refCallback={fixedRef}
                        >
                            {statusBar}
                            {this.props.children}
                            {homeBar}
                        </App>
                    </FontScaleProvider>
                </TailwindThemeProvider>
            </CredentialsProvider>
        );
    }
}

export default withPlayerEminenceFlags(AppContainer);

export const BuilderAppContainer = withBuilderEminenceFlags(AppContainer);
