import type { WireAppTheme } from "@glide/theme";
import { makeValidHTMLId } from "@glide/common";
import { getFirstElementFromArrayOrSingleElement } from "@glide/common-core/dist/js/components/component-helpers";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { TextComponentStyle } from "@glide/component-utils";
import type { WireRatingComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { useIsHoverable } from "@glide/common-components";
import { isDefined, isEmptyOrUndefinedish } from "@glide/support";
import { ValueChangeSource } from "@glide/wire";
import * as React from "react";
import { css } from "styled-components";
import tw from "twin.macro";
import { v4 as uuid } from "uuid";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

import { Img } from "../../components/img/img";
import { Text } from "../../components/text/text";
import type { WireRenderer } from "../wire-renderer";

type WireRatingInteractionEvents = "hover" | "pointer";

const useDesiredRate = (currentRate: number) => {
    const [desiredRate, setDesiredRateState] = React.useState<number | null>(null);

    const setDesiredRate = (event: WireRatingInteractionEvents, newRate: number) => {
        if (newRate === currentRate && event === "pointer") {
            setDesiredRateState(0);
        } else if (currentRate === 0 && event !== "pointer") {
            setDesiredRateState(newRate);
        } else if (event === "hover") {
            setDesiredRateState(newRate);
        }
    };

    const clearDesiredRate = () => setDesiredRateState(null);

    return {
        desiredRate,
        setDesiredRate,
        clearDesiredRate,
    };
};

export const WireRating: WireRenderer<WireRatingComponent> = p => {
    const { maxRating, rate, backend, text, image, label } = p;
    const id = makeValidHTMLId(uuid());
    const currentRate = rate?.value ?? 0;
    const { desiredRate, clearDesiredRate, setDesiredRate } = useDesiredRate(currentRate);

    const theme = useWireAppTheme();
    const canHover = useIsHoverable();
    const starAmount = Number(maxRating);

    const setRate = (possibleNewRate: number) => {
        if (isDefined(rate) && isDefined(rate.onChangeToken)) {
            const newRate = possibleNewRate === rate.value ? "" : possibleNewRate;
            backend.valueChanged(rate.onChangeToken, newRate, ValueChangeSource.User);
            clearDesiredRate();
        }
    };

    const firstImage = getFirstElementFromArrayOrSingleElement(image);
    const hasImage = !isEmptyOrUndefinedish(firstImage);
    const hasLabel = !isEmptyOrUndefinedish(label);
    const hasText = !isEmptyOrUndefinedish(text);

    const noContent = [hasImage, hasLabel, hasText].every(pred => pred === false);

    const rateToShow = desiredRate !== null ? desiredRate : currentRate;
    const emptyStroke =
        rateToShow === 0 ||
        currentRate === 0 ||
        // We're using "" for clearing the rate, but fluent doesn't know about it.
        (rate?.value as number | "") === "";

    return (
        <div
            css={css`
                &.no-content {
                    ${tw`justify-center`}
                }
            `}
            className={noContent ? "no-content" : ""}
            tw="flex justify-between items-center"
        >
            <div tw="flex gap-x-2.5">
                {hasImage && (
                    <Img
                        data-testid="action-row-image"
                        src={massageImageUrl(firstImage, { thumbnail: false }, backend.appID)}
                        isPages={true}
                        tw="shrink-0 object-cover bg-n50 rounded-lg h-12 w-12"
                        alt={text ?? undefined}
                    />
                )}
                <div tw="flex flex-col">
                    {hasLabel && (
                        <Text tw="text-text-contextual-pale" element="p" variant={TextComponentStyle.regular}>
                            {label}
                        </Text>
                    )}
                    {hasText && (
                        <Text tw="text-text-contextual-dark" element="p" variant={TextComponentStyle.regular}>
                            {text}
                        </Text>
                    )}
                </div>
            </div>
            <div tw="flex gap-x-2.5" onMouseLeave={clearDesiredRate}>
                {[...Array(starAmount).keys()].map(starPosition => (
                    <Star
                        componentId={id}
                        key={starPosition}
                        clipProgress={getRateForStar(rateToShow, starPosition)}
                        setRate={setRate}
                        rate={starPosition + 1}
                        setDesiredRate={setDesiredRate}
                        emptyStroke={emptyStroke}
                        canHover={canHover}
                        theme={theme}
                    />
                ))}
            </div>
        </div>
    );
};

const getRateForStar = (rate: number, starPosition: number) => {
    const x = (rate - starPosition) * 100;
    return Math.max(0, Math.min(x, 100));
};

// I'm creating a component from this SVG so we can use clipPath with a variable. I tried to do it using css variables,
// but something went wrong trying to increase the width of the clipPath
interface StarProps {
    setRate: (rate: number) => void;
    clipProgress: number;
    rate: number;
    setDesiredRate: (event: WireRatingInteractionEvents, potentialRate: number) => void;
    componentId: string | undefined;
    emptyStroke: boolean;
    canHover: boolean;
    theme: WireAppTheme;
}

const Star: React.VFC<StarProps> = ({
    clipProgress,
    setRate,
    rate,
    setDesiredRate,
    componentId,
    emptyStroke,
    canHover,
    theme,
}) => {
    return (
        <button
            tw="w-5 h-5 page-hover:scale-150 active:scale-150 transition-all"
            data-testid={`rating-star-${rate}`}
            onClick={() => setRate(rate)}
            onPointerDown={_e => {
                if (canHover) {
                    setDesiredRate("pointer", rate);
                }
            }}
            onMouseEnter={_e => {
                if (canHover) {
                    setDesiredRate("hover", rate);
                }
            }}
        >
            <svg id={`${componentId}-${rate}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <clipPath id={`${componentId}-star-mask-${rate}`}>
                    <rect x="0" y="0" width={`${clipProgress}%`} height="100%" />
                </clipPath>

                <path
                    d="M13.653 2.47114C13.1082 0.903905 10.8918 0.90391 10.347 2.47114L8.46355 7.88958C8.42921 7.98834 8.33704 8.05531 8.2325 8.05744L2.49724 8.17432C0.838372 8.20812 0.153459 10.3161 1.47564 11.3185L6.04686 14.7841C6.13019 14.8473 6.16539 14.9557 6.13512 15.0558L4.47398 20.5464C3.99351 22.1345 5.78664 23.4373 7.14856 22.4896L11.8572 19.2131C11.943 19.1534 12.0569 19.1534 12.1428 19.2131L16.8514 22.4896C18.2133 23.4373 20.0065 22.1345 19.526 20.5464L17.8648 15.0558C17.8346 14.9557 17.8698 14.8473 17.9531 14.7841L22.5243 11.3185C23.8465 10.3161 23.1616 8.20812 21.5027 8.17432L15.7675 8.05744C15.6629 8.05531 15.5707 7.98835 15.5364 7.88958L13.653 2.47114Z"
                    fill={emptyStroke ? "none" : theme.textContextualDisabled}
                    strokeWidth={emptyStroke && clipProgress === 0 ? 1.5 : 0}
                    stroke={emptyStroke ? theme.textContextualDisabled : "none"}
                />

                <path
                    d="M13.653 2.47114C13.1082 0.903905 10.8918 0.90391 10.347 2.47114L8.46355 7.88958C8.42921 7.98834 8.33704 8.05531 8.2325 8.05744L2.49724 8.17432C0.838372 8.20812 0.153459 10.3161 1.47564 11.3185L6.04686 14.7841C6.13019 14.8473 6.16539 14.9557 6.13512 15.0558L4.47398 20.5464C3.99351 22.1345 5.78664 23.4373 7.14856 22.4896L11.8572 19.2131C11.943 19.1534 12.0569 19.1534 12.1428 19.2131L16.8514 22.4896C18.2133 23.4373 20.0065 22.1345 19.526 20.5464L17.8648 15.0558C17.8346 14.9557 17.8698 14.8473 17.9531 14.7841L22.5243 11.3185C23.8465 10.3161 23.1616 8.20812 21.5027 8.17432L15.7675 8.05744C15.6629 8.05531 15.5707 7.98835 15.5364 7.88958L13.653 2.47114Z"
                    fill={theme.textContextualAccent}
                    clipPath={`url(#${componentId}-star-mask-${rate})`}
                    stroke="none"
                />
            </svg>
        </button>
    );
};
