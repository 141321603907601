import tw from "twin.macro";

import { TextComponentStyle } from "@glide/component-utils";
import { Text } from "../../components/text/text";
import { AnimatePresence, m } from "framer-motion";
import { useClientVersionChecks, isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import React from "react";
import { GlideIcon } from "@glide/common";

interface UpdateBannerProps {
    readonly version?: string;
}

const MOBILE_TAB_BAR_HEIGHT_PX = 56;

export const UpdateVersionBanner: React.FC<{ isTabBarVisible: boolean }> = ({ isTabBarVisible }) => {
    const { latestVersionInfo, newVersionAvailable, hasDismissedLatestVersion } = useClientVersionChecks("player");

    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    const inBuilder = !isPlayer();

    // for the player, we are only going to show the banner if we want to force a transition
    if (inBuilder || latestVersionInfo === undefined || latestVersionInfo.forceTransition === false) {
        return null;
    }

    if (latestVersionInfo.inMaintenanceMode ?? false) {
        const url = new URL(window.location.href);
        window.location.href = url.href;
        return null;
    }

    const bottomPosition = isTabBarVisible && isMobile ? MOBILE_TAB_BAR_HEIGHT_PX + 16 : 8;
    const show = newVersionAvailable && !hasDismissedLatestVersion;
    return (
        <AnimatePresence>
            {show ? (
                <m.div
                    initial={{ y: "100%" }}
                    animate={{
                        y: `-${bottomPosition}px`,
                        transition: {
                            type: "spring",
                            mass: 1,
                            stiffness: 850,
                            damping: 55,
                        },
                    }}
                    exit={{ y: "100%", transition: { duration: 0.2 } }}
                    tw="absolute bottom-[var(--safe-area-inset-bottom)] left-2 rounded-[10px] [background-color: #333] flex justify-center"
                    css={[show && tw`pointer-events-none right-2 page-md:right-[unset]`]}>
                    <UpdateBannerContent version={latestVersionInfo.deploymentVersion} />
                </m.div>
            ) : null}
        </AnimatePresence>
    );
};

const UpdateBannerContent: React.FC<UpdateBannerProps> = ({ version }) => {
    const [requestedReload, setRequestedReload] = React.useState(false);
    const handleVersionReload = React.useCallback(() => {
        const url = new URL(window.location.href);
        if (version !== undefined) {
            url.searchParams.set("dv", version);
        }
        setRequestedReload(true);
        window.location.href = url.href;
    }, [version]);

    return (
        <div tw="flex flex-col gap-y-[22px] px-2 py-4 w-full items-center page-sm:(max-w-[377px])">
            <div tw="flex flex-col items-center">
                <Text tw="text-w90A" variant={TextComponentStyle.regular}>
                    Your app needs to refresh. Save your changes before refreshing.
                </Text>
            </div>
            <div tw="flex gap-x-2 items-center justify-center pointer-events-auto">
                <button onClick={handleVersionReload} tw="rounded-full bg-w100A py-[5px] px-[13px]">
                    <Text variant={TextComponentStyle.headlineXXXSmall} tw="text-n700">
                        {requestedReload ? (
                            <GlideIcon kind="stroke" icon="st-arrow-sync" iconSize={20} tw="animate-spin" />
                        ) : (
                            `Refresh`
                        )}
                    </Text>
                </button>
            </div>
        </div>
    );
};
