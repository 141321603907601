import "twin.macro";

import { GlideIcon } from "@glide/common";

export const calloutMoods = ["warning"] as const;

export type Mood = (typeof calloutMoods)[number];

export interface CalloutProps {
    mood: Mood;
    className?: string;
}

export const Callout: React.FC<React.PropsWithChildren<React.PropsWithChildren<CalloutProps>>> = props => {
    const { children, className } = props;
    return (
        <div tw="rounded-lg bg-bg-front shadow-sm" className={className}>
            <div tw="rounded-lg bg-y100 text-y500 px-4 py-3 text-builder-base flex items-center">
                <div tw="rounded-full p-2">
                    <GlideIcon kind="monotone" icon="mt-warning" iconSize={20} tw="text-y400" />
                </div>
                <div>{children}</div>
            </div>
        </div>
    );
};
