import { getFeatureFlag } from "../feature-flags";
import { frontendSendEvent } from "../tracing";
import { sessionStorageGetItem, sessionStorageRemoveItem, sessionStorageSetItem } from "@glide/support";
import { getLocationSettings } from "../location";

let tryingToReload = false;

export function isTryingToReload(): boolean {
    return tryingToReload;
}

const lastReloadReasonKey = "glide-last-reload-reason";

export function reloadBrowserWindow(reason: string, ...args: any[]): void {
    tryingToReload = true;
    if (getLocationSettings().sendReloadEvent) {
        sessionStorageSetItem(lastReloadReasonKey, reason);
    }
    if (getFeatureFlag("debugBrowserReload")) {
        // eslint-disable-next-line no-console
        console.trace("Reloading browser window", reason, ...args);
        setTimeout(() => {
            // eslint-disable-next-line no-debugger
            debugger;
            window.location.reload();
        }, 10 * 1000);
        // eslint-disable-next-line no-debugger
        debugger;
    } else {
        window.location.reload();
    }
}

export function reportLastReloadReason(): void {
    const lastReason = sessionStorageGetItem(lastReloadReasonKey);
    if (lastReason === undefined) return;

    sessionStorageRemoveItem(lastReloadReasonKey);
    frontendSendEvent("reload", 0, { reason: lastReason });
}
