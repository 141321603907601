import { type ActionDescription, type PropertyDescription, ActionKind } from "@glide/app-description";
import { isLinkTypeKind } from "@glide/type-schema";
import type { AppDescriptionContext, ActionAvailability } from "@glide/function-utils";
import type {
    WireActionHydrator,
    WireActionInflationBackend,
    WireActionResult,
    WireActionResultBuilder,
} from "@glide/wire";
import { UnboundVal } from "@glide/computation-model-types";
import type { StaticActionContext } from "../static-context";
import { inflateStringProperty } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { makeDataPropertyDescriptor } from "./copy-to-clipboard";
import { ICON_BASE, PURPLE_500 } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins";

interface ShowShareScreenActionDescription extends ActionDescription {
    // This property name is also hardcoded in ##convertLegacyActions
    readonly data?: PropertyDescription;
}

export class ShowShareScreenWithArgsHandler extends BaseActionHandler<ShowShareScreenActionDescription> {
    public readonly kind = ActionKind.ShowShareSheetWithArgs;
    public readonly iconName: GlideIconProps = {
        icon: "st-share",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: PURPLE_500,
    };

    public readonly name: string = "Share link";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: ShowShareScreenActionDescription | undefined,
        { mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Communication,
            groupItemOrder: 5,
            needsScreenContext: true,
            properties: [makeDataPropertyDescriptor("Enter URL or text", mutatingScreenKind)],
        };
    }

    public getTokenizedDescription(
        desc: ShowShareScreenActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const columnToken = tokenForProperty(desc.data, env);
        if (columnToken === undefined) return undefined;

        return [
            {
                kind: "string",
                value: "with data from ",
            },
            columnToken,
        ];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: ShowShareScreenActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const [dataGetter, dataType] = inflateStringProperty(ib, desc.data, false);
        if (dataType === undefined) return arb.inflationError("Invalid data");

        const isURL = isLinkTypeKind(dataType.kind);
        arb = arb.addData({ isURL });

        return vp => {
            const data = dataGetter(vp);
            if (data === UnboundVal) return arb.error(true, "Data is not bound");

            return async ab => {
                ab.actionCallbacks.showShareSheet(isURL ? data : undefined, isURL ? undefined : data);
                return arb.addData({ data }).success();
            };
        };
    }
}
