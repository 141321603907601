import "twin.macro";

import { AppIcon, ClickOutsideContainer } from "@glide/common";
import classNames from "classnames";
import React from "react";
import ReactDOM from "react-dom";
import uuid from "uuid/v4";
import { Button } from "../button/button";

const ARIA_LABEL_SUFFIX = "modal-title";
const ARIA_DESCRIPTION_SUFFIX = "modal-description";

type ModalContext = {
    dialogId: string;
};

type CloseProps = {
    onClose?: () => void;
    absolute?: boolean;
};

type ContainerProps = CloseProps & {
    showBackdrop?: boolean;
    className?: string | undefined;
};

type DescriptorProps = {
    className?: string | undefined;
    isDarkMode?: boolean;
};

export type WindowProps = DescriptorProps & {
    size?: "xs" | "sm" | "md" | "lg" | "xl" | number | undefined;
};

type ButtonBarProps = {
    align?: "left" | "right" | "split" | "stretch";
    className?: string | undefined;
};

const ModalContext = React.createContext<ModalContext | undefined>(undefined);

const useModalContext = () => {
    const context = React.useContext(ModalContext);
    if (context === undefined) {
        throw new Error("Modal components must be used within a Modal.Container");
    }
    return context;
};

export const Container: React.FC<React.PropsWithChildren<ContainerProps>> = p => {
    const { showBackdrop = true, onClose, children, className } = p;
    const dialogId = React.useRef(uuid());
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [portalReady, setPortalReady] = React.useState(false);

    React.useEffect(() => {
        const div = document.createElement("div");
        div.id = `dialog-${dialogId.current}`;
        const portal = document.getElementById("portal");
        portal?.appendChild(div);
        containerRef.current = div;
        setPortalReady(true);

        return () => {
            portal?.removeChild(div);
        };
    }, []);

    const onClickOffImpl = React.useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
        return;
    }, [onClose]);

    return containerRef.current && portalReady
        ? ReactDOM.createPortal(
              <ModalContext.Provider value={{ dialogId: dialogId.current }}>
                  <div
                      className={classNames(className, !showBackdrop && "no-backdrop")}
                      tw="absolute grid place-items-center left-0 top-0 w-full h-full bg-black bg-opacity-50 p-2
                            [&.no-backdrop]:(bg-transparent)
                    ">
                      <ClickOutsideContainer onClickOutside={onClose ? onClickOffImpl : () => null}>
                          {children}
                      </ClickOutsideContainer>
                  </div>
              </ModalContext.Provider>,
              containerRef.current
          )
        : null;
};

/**
 * Wraps the modal window and creates a dialog instance for a11y labels
 */
const Window: React.FC<React.PropsWithChildren<WindowProps>> = p => {
    const { children, className, size } = p;
    const { dialogId } = useModalContext();

    // Determine the className and styles based on the size prop
    const sizeClass = typeof size === "string" ? size : undefined;
    const sizeStyle = typeof size === "number" ? { width: `${size}px` } : undefined;

    return (
        <dialog
            className={classNames(className, sizeClass)}
            style={sizeStyle}
            tw="relative flex flex-col bg-bg-front shadow-2xl-dark rounded-xl overflow-hidden max-h-[91vh] w-[91vw]
            [&.xs]:(max-w-sm)
            [&.sm]:(max-w-md)
            [&.md]:(max-w-2xl)
            [&.lg]:(max-w-3xl)
            [&.xl]:(w-[95vw])"
            aria-labelledby={`${dialogId}-${ARIA_LABEL_SUFFIX}`}
            aria-describedby={`${dialogId}-${ARIA_DESCRIPTION_SUFFIX}`}
            aria-modal={true}>
            {children}
        </dialog>
    );
};

/**
 *  Renders TitleBar to avoid collision with close button.
 */
const TitleBar: React.FC<React.PropsWithChildren<DescriptorProps>> = p => {
    const { children, className } = p;
    return (
        <div tw="flex justify-end items-center px-8 py-5" className={className}>
            {children}
        </div>
    );
};

/**
 *  Renders Title children inside aria-labeledby wrapper
 */
export const Title: React.FC<React.PropsWithChildren<DescriptorProps>> = p => {
    const { children, className } = p;
    const { dialogId } = useModalContext();
    return (
        <h1
            id={`${dialogId}-${ARIA_LABEL_SUFFIX}`}
            tw="flex-1 font-semibold leading-tight text-builder-2xl text-text-dark"
            className={className}>
            {children}
        </h1>
    );
};

/**
 *  Renders Content children inside aria-describedby wrapper
 */
export const Content: React.FC<React.PropsWithChildren<DescriptorProps>> = p => {
    const { children, className, isDarkMode } = p;
    const { dialogId } = React.useContext(ModalContext) ?? {};
    return (
        <div id={`${dialogId}-${ARIA_DESCRIPTION_SUFFIX}`} className={className} data-dark-mode={isDarkMode}>
            {children}
        </div>
    );
};

/**
 *  Renders a simple close button as well as "close" for screen reader.
 */
const Close: React.FC<React.PropsWithChildren<CloseProps>> = p => {
    const { onClose, absolute } = p;
    return (
        <button
            onClick={onClose}
            className={absolute === true ? "absolute" : undefined}
            tw="flex items-center justify-center p-1.5 cursor-pointer text-n700 transition
                hover:(text-n800 bg-n200A) 
                focus-visible:(text-n800 bg-n200A ring-2 ring-accent) 
                [&.absolute]:(absolute bg-white right-1.5 top-1.5 bg-opacity-50 hover:bg-opacity-70) rounded-xl">
            <AppIcon icon="close" size={20} aria-hidden="false" />
            <span tw="sr-only">Close</span>
        </button>
    );
};

/**
 *  Renders a button bar that maintains tab focus until the modal is unmounted.
 */
const ButtonBar: React.FC<React.PropsWithChildren<ButtonBarProps>> = p => {
    const { align = "right", className, children } = p;

    return (
        <div
            className={classNames(align, className)}
            tw="flex
                [&.right]:(justify-end)
                [&.split]:(justify-between)
                [&.stretch]:(items-stretch) [&.stretch > button]:(grow)
                ">
            {children}
        </div>
    );
};

export const Modal = {
    Container,
    Window,
    Title,
    TitleBar,
    Content,
    Close,
    ButtonBar,
    Button,
} as const;

Modal.Container.displayName = "Modal.Container";
Modal.Window.displayName = "Modal.Window";
Modal.Title.displayName = "Modal.Title";
Modal.TitleBar.displayName = "Modal.TitleBar";
Modal.Content.displayName = "Modal.Content";
Modal.Close.displayName = "Modal.Close";
Modal.ButtonBar.displayName = "Modal.ButtonBar";
Modal.Button.displayName = "Modal.Button";
