/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

const { Result } = glide;

export const plugin = glide.newPlugin({
    name: "Files",
    id: "files",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/glide.png",
    description: "Create and upload files to Glide storage",
    parameters: {},
});

const fileURL = glide.makeParameter({
    type: "url",
    name: "URL",
    description: "A publicly accessible URL to the file you want to upload",
    placeholder: "e.g. report",
    required: true,
});

const glideFileURL = glide.makeParameter({
    type: "url",
    name: "Glide URL",
    description: "The resulting URL of the uploaded file in Glide storage",
    placeholder: "e.g. report",
    required: true,
});

const fileName = glide.makeParameter({
    type: "string",
    name: "File Name",
    description: "The name to use for the file",
    required: false,
    useTemplate: "withLabel",
});

const fileContent = glide.makeParameter({
    type: "string",
    name: "Content",
    description: "The content of the file to create",
    required: true,
    multiLine: true,
    useTemplate: "withLabel",
});

const fileType = glide.makeParameter({
    type: "enum",
    name: "File Type",
    description: "The type of file to create",
    required: true,
    values: [
        // Text and Documents
        { value: "text/plain", label: "Text" },
        { value: "text/csv", label: "CSV" },
        { value: "text/markdown", label: "Markdown" },
        { value: "text/html", label: "HTML" },

        // Data Formats
        { value: "application/json", label: "JSON" },
        { value: "application/yaml", label: "YAML" },
        { value: "application/json5", label: "JSON5" },
        { value: "application/toml", label: "TOML" },

        // XML-based Formats
        { value: "application/xml", label: "XML" },
        { value: "application/rss+xml", label: "RSS" },
        { value: "application/atom+xml", label: "Atom Feed" },
        { value: "image/svg+xml", label: "SVG" },
    ],
});

function filenameFromURL(url: string): string {
    try {
        const urlObject = new URL(url);
        const pathname = urlObject.pathname;
        const filename = pathname.split("/").pop();
        return filename ?? crypto.randomUUID();
    } catch {
        return crypto.randomUUID();
    }
}

plugin.addAction({
    id: "upload-file",
    name: "Upload File",
    description: "Saves a file to Glide storage",
    billablesConsumed: 1,
    parameters: { fileURL, fileName },
    results: {
        glideFileURL,
    },

    execute: async (context, { fileURL, fileName }) => {
        if (fileURL === undefined) return Result.FailPermanent("No file URL provided");

        const resolvedFileName = fileName ?? filenameFromURL(fileURL);
        const uploaded = await context.rehostFile(resolvedFileName, fileURL);
        if (!uploaded.ok) return uploaded;

        context.consumeBillable();
        return Result.Ok({ glideFileURL: uploaded.result });
    },
});

plugin.addAction({
    id: "create-file",
    name: "Create File",
    description: "Creates a new file with the specified content and type",
    billablesConsumed: 1,
    parameters: { fileContent, fileType, fileName },
    results: {
        glideFileURL,
    },

    execute: async (context, { fileContent, fileType, fileName }) => {
        if (fileContent === undefined || fileContent.trim() === "") {
            return Result.FailPermanent("No file content provided");
        }
        if (fileType === undefined || fileType.trim() === "") {
            return Result.FailPermanent("No file type specified");
        }

        const resolvedFileName = fileName ?? `file-${crypto.randomUUID()}`;
        const fullFileName = resolvedFileName + getFileExtension(fileType);

        const uploaded = await context.uploadFile(fullFileName, fileType, fileContent);

        if (!uploaded.ok) return uploaded;

        context.consumeBillable();
        return Result.Ok({ glideFileURL: uploaded.result });
    },
});

function getFileExtension(mimeType: string): string {
    switch (mimeType) {
        // Text and Documents
        case "text/plain":
            return ".txt";
        case "text/csv":
            return ".csv";
        case "text/markdown":
            return ".md";
        case "text/html":
            return ".html";

        // Data Formats
        case "application/json":
            return ".json";
        case "application/yaml":
            return ".yaml";
        case "application/json5":
            return ".json5";
        case "application/toml":
            return ".toml";

        // XML-based Formats
        case "application/xml":
            return ".xml";
        case "application/rss+xml":
            return ".rss";
        case "application/atom+xml":
            return ".atom";
        case "image/svg+xml":
            return ".svg";
        default:
            return "";
    }
}
