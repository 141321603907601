import { mapFilterUndefined } from "@glideapps/ts-necessities";
import chroma from "chroma-js";

// Different types of color generation
type ColorType = "basic" | "bright" | "muted" | "gradient" | "interpolate";

// Cache structure using composite keys
const COLORS_CACHE: Map<string, string> = new Map();
let lastBaseColor: string | undefined = undefined;
let lastThemeWasDark: boolean = false;

const MIN_LUMINOSITY = 40;
const MIN_CONTRAST = 3.0;
const MAX_CONTRAST_ITERATIONS = 20;

export const DEFAULT_COLOR = "#666666";

// Generate cache keys for different color types
function generateCacheKey(type: ColorType, ...args: any[]): string {
    // Create a string key that includes all relevant parameters
    return `${type}_${JSON.stringify(args)}`;
}

function getAngleForIndex(index: number): number {
    // Use larger multiplication factor for more dramatic hue shifts
    const goldenRatio = 0.618033988749895;
    return index * goldenRatio * 360;
}

export function getGenerativeColor(base: string, index: number, isDarkTheme: boolean = false): string {
    if (lastBaseColor !== base || lastThemeWasDark !== isDarkTheme) {
        lastBaseColor = base;
        lastThemeWasDark = isDarkTheme;
        COLORS_CACHE.clear();
    }

    const cacheKey = generateCacheKey("basic", base, index, isDarkTheme);
    const cachedColor = COLORS_CACHE.get(cacheKey);
    if (cachedColor !== undefined) {
        return cachedColor;
    }

    let color = chroma(base);
    if (index > 0) {
        // min saturation
        color = color.set("lch.c", Math.max(40, color.get("lch.c"))); // Ensure saturation is at least 0.2
        // change hue
        const hueShift = getAngleForIndex(index);
        color = color.set("lch.h", (color.get("lch.h") + hueShift) % 360);
    }

    if (!isDarkTheme) {
        // min luminosity
        color = color.set("lch.l", Math.max(MIN_LUMINOSITY, color.get("lch.l")));
        let iterations = 0;
        while (chroma.contrast(color, "white") < MIN_CONTRAST && iterations < MAX_CONTRAST_ITERATIONS) {
            color = color.darken(0.1);
            iterations++;
        }
    } else {
        // max luminosity
        color = color.set("lch.l", Math.min(100 - MIN_LUMINOSITY, color.get("lch.l")));
        let iterations = 0;
        while (chroma.contrast(color, "black") < MIN_CONTRAST && iterations < MAX_CONTRAST_ITERATIONS) {
            color = color.brighten(0.1);
            iterations++;
        }
    }

    const hex = color.hex();
    COLORS_CACHE.set(cacheKey, hex);
    return hex;
}

function getStartingHue(base: string): number {
    // get base saturation and lightness
    // if the base color is not grey, use its hue
    const baseColor = chroma(base);
    const baseSaturation = baseColor.get("hsl.s");
    const baseLightness = baseColor.get("hsl.l");
    // if the base color is grey, use a default hue
    // this is a bit of a hack, but it works for now
    const isGrey = baseSaturation < 0.1 && baseLightness > 0.4 && baseLightness < 0.6;
    return isGrey ? 250 : baseColor.get("hsl.h");
}

export function getGenerativeBrightColor(
    base: string,
    index: number,
    length: number,
    isDarkTheme: boolean = false
): string {
    // Check cache first
    const cacheKey = generateCacheKey("bright", base, index, length, isDarkTheme);
    const cachedColor = COLORS_CACHE.get(cacheKey);
    if (cachedColor !== undefined) {
        return cachedColor;
    }

    const hueOffset = getStartingHue(base);
    // Calculate evenly distributed hue based on index and total length
    const hueStep = 360 / length;
    // Calculate the hue - distribute evenly around the color wheel
    const hue = (index * hueStep + hueOffset) % 360;

    // To add more variation when there are few items, vary saturation and lightness
    // Use the golden ratio to create a sequence that doesn't repeat with index
    const goldenRatio = 0.618033988749895;
    const variationSeed = index * goldenRatio;

    // For bright colors, use high saturation with some variation
    const saturation = 85 - ((variationSeed * 15) % 20);

    // Base lightness depends on theme
    let lightness = isDarkTheme ? 65 : 55;
    // Add variation to lightness
    lightness += ((variationSeed * 10) % 20) - 10;

    // Create the bright color
    let color = chroma.hsl(hue, saturation / 100, lightness / 100);

    // Ensure contrast requirements are met
    if (!isDarkTheme) {
        let iterations = 0;
        while (chroma.contrast(color, "white") < MIN_CONTRAST && iterations < MAX_CONTRAST_ITERATIONS) {
            color = color.darken(0.05);
            iterations++;
        }
    } else {
        let iterations = 0;
        while (chroma.contrast(color, "black") < MIN_CONTRAST && iterations < MAX_CONTRAST_ITERATIONS) {
            color = color.brighten(0.05);
            iterations++;
        }
    }

    const hex = color.hex();
    COLORS_CACHE.set(cacheKey, hex);
    return hex;
}

export function getGenerativeMutedColor(
    base: string,
    index: number,
    length: number,
    isDarkTheme: boolean = false
): string {
    // Check cache first
    const cacheKey = generateCacheKey("muted", base, index, length, isDarkTheme);
    const cachedColor = COLORS_CACHE.get(cacheKey);
    if (cachedColor !== undefined) {
        return cachedColor;
    }

    const hueOffset = getStartingHue(base);
    // Calculate evenly distributed hue based on index and total length
    const hueStep = 360 / length;
    // Calculate the hue - distribute evenly around the color wheel
    const hue = (index * hueStep + hueOffset) % 360;

    // Use the golden ratio for variation
    const goldenRatio = 0.618033988749895;
    const variationSeed = index * goldenRatio;

    // For muted colors, use lower saturation with some variation
    const saturation = 45 - ((variationSeed * 10) % 15);

    // Base lightness adjusts for theme
    let lightness = isDarkTheme ? 60 : 65;
    // Add variation to lightness
    lightness += ((variationSeed * 10) % 15) - 7.5;

    // Create the muted color
    let color = chroma.hsl(hue, saturation / 100, lightness / 100);

    // Ensure contrast requirements are met
    if (!isDarkTheme) {
        let iterations = 0;
        while (chroma.contrast(color, "white") < MIN_CONTRAST && iterations < MAX_CONTRAST_ITERATIONS) {
            color = color.darken(0.05);
            iterations++;
        }
    } else {
        let iterations = 0;
        while (chroma.contrast(color, "black") < MIN_CONTRAST && iterations < MAX_CONTRAST_ITERATIONS) {
            color = color.brighten(0.05);
            iterations++;
        }
    }

    const hex = color.hex();
    COLORS_CACHE.set(cacheKey, hex);
    return hex;
}

// Generate colors along a gradient using the provided stops
export function getGenerativeGradientColor(
    index: number,
    dataPoints: number,
    gradientColors: (string | undefined)[]
): string {
    // Filter out undefined colors for caching and calculation
    const definedGradientColors = mapFilterUndefined(gradientColors, c => c);

    // Generate cache key including the array of colors
    const cacheKey = generateCacheKey("gradient", index, dataPoints, definedGradientColors);
    const cachedColor = COLORS_CACHE.get(cacheKey);
    if (cachedColor !== undefined) {
        return cachedColor;
    }

    // If no gradient colors provided, fall back to base color
    if (definedGradientColors.length === 0) {
        return DEFAULT_COLOR;
    }

    if (definedGradientColors.length === 1) {
        return definedGradientColors[0] ?? DEFAULT_COLOR;
    }

    try {
        // Generate color scale between gradient stops
        const colorScale = chroma.scale(definedGradientColors).mode("rgb").colors(dataPoints);

        // Return color at index, bounded by array length
        const boundedIndex = Math.min(Math.max(0, index), dataPoints - 1);
        const hex = colorScale[boundedIndex];
        COLORS_CACHE.set(cacheKey, hex);
        return hex;
    } catch {
        // bad colors can get passed in from config
        return DEFAULT_COLOR;
    }
}

// Interpolate between colors based on a value within a range
export function interpolateColor(value: number, min: number, max: number, colors: string[]): string {
    // Generate cache key for interpolation
    const cacheKey = generateCacheKey("interpolate", value, min, max, colors);
    const cachedColor = COLORS_CACHE.get(cacheKey);
    if (cachedColor !== undefined) {
        return cachedColor;
    }

    // Normalize the value to [0,1]
    const range = max - min;
    const normalizedValue = range === 0 ? 0 : (value - min) / range;

    // Create a scale between all provided colors and get the interpolated color
    try {
        const hex = chroma.scale(colors).mode("rgb")(normalizedValue).hex();
        COLORS_CACHE.set(cacheKey, hex);
        return hex;
    } catch {
        // bad colors can get passed in from config
        return DEFAULT_COLOR;
    }
}
