import { TailwindThemeProvider } from "@glide/common";

type TailwindThemeProviderProps = React.ComponentProps<typeof TailwindThemeProvider>;

export const AppsTailwindThemeProvider: React.FC<TailwindThemeProviderProps> = p => {
    const { children, ...rest } = p;

    return (
        <TailwindThemeProvider
            {...rest}
            tw="[--text-size-base: 16px] [--text-size-xs: 12px] [--text-size-sm: 14px] [--text-size-lg: 18px] 
                page-md:([--text-size-base: 14px] [--text-size-xs: 11px] [--text-size-sm: 12px] [--text-size-lg: 16px])">
            {children}
        </TailwindThemeProvider>
    );
};
