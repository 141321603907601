import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import type {
    TableName,
    ColumnType,
    Description,
    TableColumn,
    TableGlideType,
    SchemaInspector,
} from "@glide/type-schema";
import type { MutatingScreenKind, PropertyDescription, PropertyKind, UserFeatures } from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import type {
    AppDescriptionContext,
    EditedColumnsAndTables,
    InteractiveComponentConfiguratorContext,
    PropertyDescriptor,
    PropertyDescriptorCase,
    RewritingComponentConfiguratorContext,
} from "@glide/function-utils";
import { assert, defined } from "@glideapps/ts-necessities";
import type { StaticActionContext } from "../../static-context";
import type { SerializablePluginMetadata } from "@glide/plugins-codecs";

export interface CompatibilityHelper extends SchemaInspector {
    readonly screenName: string | undefined;
    readonly oldComponentConfiguratorContext: AppDescriptionContext;
    findNewColumn(table: TableGlideType, columnName: string): TableColumn | undefined;
    addOtherIssue(issue: string): void;
}

export enum PopulationMode {
    // We're rewriting an existing description because something changed, but
    // it's still using the same table.
    Rewrite,
    // We're rewriting an existing description with a new table.
    RewriteWithNewTable,
    // We're filling in the default value.
    Default,
    // ##userRequestPopulationMode:
    // I think this is a vestige of a refactor.
    UserRequest,
}

export function isRewritePopulationMode(m: PopulationMode): boolean {
    return m === PopulationMode.Rewrite || m === PopulationMode.RewriteWithNewTable;
}

export interface SearchedColumn {
    readonly tableName?: TableName;
    readonly columnName?: string;
}

export interface ColumnAssignmentInfo {
    readonly name: string;
    readonly icon: string | undefined;
    readonly label: string | undefined;
}

export interface DescriptionHandler<TDescriptorCase extends PropertyDescriptorCase> {
    readonly kind: PropertyKind;

    // This is the most important method to implement.  It's called to fill in
    // default values, or to rewrite an existing value in case
    // `rewriteWithCase` returns `undefined`.
    defaultUpdateForPropertyCase(
        // The full descriptor, which could be a multi-case descriptor.
        descr: PropertyDescriptor,
        // The description for which the descriptor was generated.  This is
        // usually a component description, or action description, or
        // sometimes a screen description.
        rootDesc: Description,
        // The description which directly contains the property.  Note that
        // this can be the same as `rootDesc`, but doesn't have to be.  It
        // could be a description within an array property, for example.
        desc: Description,
        // The descriptor case for the property we're creating a default for.
        pdc: TDescriptorCase,
        // These three are only relevant when assigning columns.  They help us
        // avoid assigning the same column to multiple properties.
        avoidDirectColumns: Set<TableColumn>,
        avoidEditedColumns: Set<TableColumn>,
        avoidIndirectColumns: Set<TableColumn>,
        // The tables for the root description.
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        adc: AppDescriptionContext,
        populationMode: PopulationMode,
        // Only relevant for assigning string properties that are the caption.
        getDefaultCaption: () => string | undefined
    ): Partial<Description> | undefined;

    // If the description describes a column that is edited within the app,
    // this has to return which columns are edited.  We use these in action
    // checks: if this is missing, edits will not be accepted.
    getEditedColumns(
        pdc: TDescriptorCase,
        pd: PropertyDescription,
        rootDesc: Description,
        env: StaticActionContext<AppDescriptionContext>,
        withActions: boolean
    ): EditedColumnsAndTables;

    // If this returns `undefined`, we call back to
    // `defaultUpdateForPropertyCase`, which is probably the best way to go
    // unless there are good reasons not to. Returning an empty object means
    // no update.
    rewriteWithCase(
        descr: PropertyDescriptor,
        pdc: TDescriptorCase,
        pd: PropertyDescription,
        newRootdesc: Description,
        newdesc: Description,
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        ignoreRequired: boolean,
        ccc: RewritingComponentConfiguratorContext,
        populationMode: PopulationMode
    ): Partial<Description> | undefined;

    // Returns the changes that need to be applied when duplicating the
    // description.  We currently only need this to duplicate builder actions.
    duplicate(
        descr: PropertyDescriptor,
        pdc: TDescriptorCase,
        propertyDescription: PropertyDescription,
        containingDescription: Description,
        rootDesc: Description,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<Partial<Description>>;

    // This is only used for column assignments in forms at this point, to
    // decide which columns a property can be assigned to.
    getType(schemaInspector: SchemaInspector, pd: PropertyDescription, table: TableGlideType): ColumnType | undefined;

    // This is also only relevant for column assignments.  If a property can't
    // be assigned in a column assignment then this doesn't need to be
    // implemented.
    getColumnAssignmentInfo(
        schemaInspector: SchemaInspector,
        pd: PropertyDescription,
        table: TableGlideType,
        pluginMetadata: readonly SerializablePluginMetadata[] | undefined,
        userFeatures: UserFeatures
    ): ColumnAssignmentInfo;

    // Only needs to be implemented by column properties.
    isSearchedColumn(
        searched: SearchedColumn,
        schemaInspector: SchemaInspector,
        pd: PropertyDescription,
        table: TableGlideType
    ): boolean;

    // This is used to check for compatibility to give warnings when reloading
    // sheets.
    checkCompatibility(
        _propertyDescription: PropertyDescription,
        _desc: Description,
        _propertyDescriptor: PropertyDescriptor,
        _oldTables: InputOutputTables,
        _helper: CompatibilityHelper
    ): void;
}

const handlers: Map<PropertyKind, DescriptionHandler<any>> = new Map();

export function registerDescriptionHandler<TDescriptorCase extends PropertyDescriptorCase = PropertyDescriptorCase>(
    handler: DescriptionHandler<TDescriptorCase>
): void {
    const { kind } = handler;
    assert(!handlers.has(kind));
    handlers.set(kind, handler);
}

export function handlerForPropertyKind<TDescriptorCase extends PropertyDescriptorCase = PropertyDescriptorCase>(
    kind: PropertyKind
): DescriptionHandler<TDescriptorCase> {
    return defined(handlers.get(kind));
}
