type RadioTileProps = {
    label: string;
    name: string;
    value: string;
    checked: boolean;
    icon: React.ReactNode;
    onSelect: (value: string) => void | Promise<void>;
    pill?: React.ReactNode;
    className?: string;
    autoFocus?: boolean;
};

export const RadioTile: React.VFC<RadioTileProps> = p => {
    const { className, icon, label, pill, onSelect, checked, name, value, autoFocus } = p;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            void onSelect(e.target.value);
        }
    };
    return (
        <label tw="flex flex-col items-center justify-between">
            <input
                autoFocus={autoFocus}
                className="peer"
                name={name}
                type="radio"
                value={value}
                checked={checked}
                onChange={handleChange}
                tw="w-0 h-0"
            />
            <div
                tw="p-6 w-full ring-0 ring-offset-bg-front ring-transparent rounded-xl relative flex justify-center items-center cursor-pointer
                bg-n100A transition duration-100
                hover:(bg-n200A)
                peer-checked:(ring-2 ring-accent bg-aqua100)
                peer-focus-visible:(ring-offset-1 bg-aqua100)
                "
                className={className}>
                {typeof pill !== "undefined" ? pill : null}
                <div tw="flex justify-center items-center">{icon}</div>
            </div>
            <p tw="text-builder-sm text-text-base my-2">{label}</p>
        </label>
    );
};
