import { type LoadedGroundValue, isPrimitive } from "@glide/computation-model-types";
import { parseValueAsGlideDateTime } from "@glide/common-core/dist/js/computation-model/data";
import type { GlideDateTime } from "@glide/data-types";

export class DateTimeAsyncParser {
    private _waitingForParsing = false;

    protected parseDateTime(v: LoadedGroundValue, setAllDirty: () => void): GlideDateTime | undefined {
        if (!isPrimitive(v)) return undefined;
        const maybeParsed = parseValueAsGlideDateTime(v);
        if (maybeParsed instanceof Promise) {
            if (this._waitingForParsing) return undefined;

            this._waitingForParsing = true;
            setTimeout(async () => {
                try {
                    await maybeParsed;
                } finally {
                    setAllDirty();
                }
            }, 0);
            return undefined;
        } else {
            return maybeParsed;
        }
    }
}
