// This is a drop-in replacement for Promise.all that waits for all Promises
// to settle before resolving or rejecting. Particularly, it neither resolves
// nor rejects until all consumed promises have resolved or rejected. This is
// similar to the behavior of Promise.allSettled, except that we end up
// swallowing all rejections except for one.

// We can't use the actual Promise.allSettled here for two reasons:
//
// 1. Safari didn't start supporting it until 13, and we still have to support
//    Safari 12
// 2. Even if we could use it, our compile target types don't include a
//    definition for it.

// There is a polyfill available at https://github.com/es-shims/Promise.allSettled
// but it can't directly be used without hacks, as a consequence of our TypeScript
// compiler configurations, as per https://github.com/es-shims/Promise.allSettled/issues/5.
// Because it was too easy to become confused and import the wrong library,
// which would still compile but crash during runtime, we've just avoided
// it altogether.

// These definitions have been lifted from the default TypeScript definition.
export function allSettled<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>(
    values: readonly [
        T1 | PromiseLike<T1>,
        T2 | PromiseLike<T2>,
        T3 | PromiseLike<T3>,
        T4 | PromiseLike<T4>,
        T5 | PromiseLike<T5>,
        T6 | PromiseLike<T6>,
        T7 | PromiseLike<T7>,
        T8 | PromiseLike<T8>,
        T9 | PromiseLike<T9>,
        T10 | PromiseLike<T10>
    ]
): Promise<[T1, T2, T3, T4, T5, T6, T7, T8, T9, T10]>;
export function allSettled<T1, T2, T3, T4, T5, T6, T7, T8, T9>(
    values: readonly [
        T1 | PromiseLike<T1>,
        T2 | PromiseLike<T2>,
        T3 | PromiseLike<T3>,
        T4 | PromiseLike<T4>,
        T5 | PromiseLike<T5>,
        T6 | PromiseLike<T6>,
        T7 | PromiseLike<T7>,
        T8 | PromiseLike<T8>,
        T9 | PromiseLike<T9>
    ]
): Promise<[T1, T2, T3, T4, T5, T6, T7, T8, T9]>;
export function allSettled<T1, T2, T3, T4, T5, T6, T7, T8>(
    values: readonly [
        T1 | PromiseLike<T1>,
        T2 | PromiseLike<T2>,
        T3 | PromiseLike<T3>,
        T4 | PromiseLike<T4>,
        T5 | PromiseLike<T5>,
        T6 | PromiseLike<T6>,
        T7 | PromiseLike<T7>,
        T8 | PromiseLike<T8>
    ]
): Promise<[T1, T2, T3, T4, T5, T6, T7, T8]>;
export function allSettled<T1, T2, T3, T4, T5, T6, T7>(
    values: readonly [
        T1 | PromiseLike<T1>,
        T2 | PromiseLike<T2>,
        T3 | PromiseLike<T3>,
        T4 | PromiseLike<T4>,
        T5 | PromiseLike<T5>,
        T6 | PromiseLike<T6>,
        T7 | PromiseLike<T7>
    ]
): Promise<[T1, T2, T3, T4, T5, T6, T7]>;
export function allSettled<T1, T2, T3, T4, T5, T6>(
    values: readonly [
        T1 | PromiseLike<T1>,
        T2 | PromiseLike<T2>,
        T3 | PromiseLike<T3>,
        T4 | PromiseLike<T4>,
        T5 | PromiseLike<T5>,
        T6 | PromiseLike<T6>
    ]
): Promise<[T1, T2, T3, T4, T5, T6]>;
export function allSettled<T1, T2, T3, T4, T5>(
    values: readonly [
        T1 | PromiseLike<T1>,
        T2 | PromiseLike<T2>,
        T3 | PromiseLike<T3>,
        T4 | PromiseLike<T4>,
        T5 | PromiseLike<T5>
    ]
): Promise<[T1, T2, T3, T4, T5]>;
export function allSettled<T1, T2, T3, T4>(
    values: readonly [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>, T3 | PromiseLike<T3>, T4 | PromiseLike<T4>]
): Promise<[T1, T2, T3, T4]>;
export function allSettled<T1, T2, T3>(
    values: readonly [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>, T3 | PromiseLike<T3>]
): Promise<[T1, T2, T3]>;
export function allSettled<T1, T2>(values: readonly [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>]): Promise<[T1, T2]>;
export async function allSettled<T>(values: readonly (T | PromiseLike<T>)[]): Promise<T[]>;

export async function allSettled<T>(values: readonly (T | PromiseLike<T>)[]) {
    let hadFirstError = false;
    let firstError: unknown | undefined = undefined;
    const results = await Promise.all(
        values.map(
            v =>
                new Promise<T | undefined>(async resolve => {
                    let result: T | undefined;
                    try {
                        result = await v;
                    } catch (e: unknown) {
                        if (!hadFirstError) {
                            firstError = e;
                            hadFirstError = true;
                        }
                        // We swallow all errors except for the first.
                        // The swallowing is important: if they aren't swallowed
                        // they become uncaught and this turns into a full
                        // process crash.
                    } finally {
                        // result might be undefined due to an exception, but
                        // if we've ever had an exception, we throw that instead
                        // of resolving. So this returns the results in the
                        // right order if no exception was thrown, and is
                        // undefined if we're throwing an exception instead.
                        resolve(result);
                    }
                })
        )
    );
    if (hadFirstError) {
        throw firstError;
    }
    return results;
}
