import type { PropertyDescription } from "@glide/app-description";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    SwitchPropertyHandler,
    PropertySection,
} from "@glide/function-utils";

const awaitSendPropertyHandler = new SwitchPropertyHandler(
    { awaitSend: true },
    "Wait for confirmation",
    PropertySection.Configuration
);

export interface AwaitSendDescription {
    readonly awaitSend?: PropertyDescription;
}

function supportsAwaitSendProperty(adc: AppDescriptionContext): boolean {
    if (adc.appDescription === undefined) return false;
    if (!adc.userFeatures.onChangeAction) return false;
    return true;
}

export function getAwaitSendPropertyDescriptor(adc: AppDescriptionContext): readonly PropertyDescriptor[] {
    if (supportsAwaitSendProperty(adc)) {
        return [awaitSendPropertyHandler];
    } else {
        return [];
    }
}

export function shouldAwaitSend(adc: AppDescriptionContext, desc: AwaitSendDescription): boolean {
    if (!supportsAwaitSendProperty(adc)) return true;
    return awaitSendPropertyHandler.getSwitch(desc);
}
