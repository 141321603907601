/* eslint-disable @typescript-eslint/no-shadow */
import { isAbortError } from "@glide/microsoft-graph";
import * as glide from "@glide/plugins";
import { exceptionToString } from "@glideapps/ts-necessities";
import { isEmptyOrUndefinedish } from "@glide/support";

const { Result } = glide;

export const plugin = glide.newNativePlugin({
    id: "trigger-webhook-plugin",
    name: "Trigger webhook",
    description: "Send data to other apps from Glide.",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/glide-webhook.png",
    tier: "starter",
    documentationUrl: "https://www.glideapps.com/docs/automation/actions/webhook",
    experimentFlag: "pricingv4-triggerWebhookPlugin",
});

const endpoint = glide.makeParameter({
    type: "string",
    name: "URL",
    description: "Your webhook URL",
    placeholder: "https://example.com",
    required: true,
});

const body = glide.makeParameter({
    type: "jsonObject",
    name: "Body",
    description: "The data you are sending in the HTTP request",
    propertySection: {
        name: "Request Body",
        order: 2,
        collapsed: false,
    },
});

plugin.addAction({
    id: "trigger-webhook",
    name: "Trigger webhook",
    keywords: ["trigger", "web hook", "webhook", "zap", "zapier", "make", "api", "endpoint", "scenario"],
    parameters: {
        endpoint,
        body,
    },
    description: "Send data to other apps from Glide.",
    billablesConsumed: 1,
    execute: async (context, { endpoint, body }) => {
        // Evaluate. Should we return an empty OK if no endpoint? Sounds glide-y.
        if (isEmptyOrUndefinedish(endpoint)) {
            return Result.Ok({});
        }

        context.trackMetric("triggerWebhookEndpoint", endpoint);
        const stringifiedBody = JSON.stringify(body ?? {});
        context.trackMetric("triggerWebhookRequestBodySize", stringifiedBody?.length ?? 0);

        const requestStartTime = performance.now();

        try {
            const response = await context.fetch(endpoint, {
                method: "POST",
                body: stringifiedBody,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const responseTime = performance.now();

            context.trackEventMetadata("triggerWebhookStatus", response.status);
            context.trackEventMetadata("triggerWebhookEndpoint", endpoint);
            context.trackMetric("triggerWebhookLatency", responseTime - requestStartTime);

            if (!response.ok) {
                return Result.FailFromHTTPStatus(`Request failed with status ${response.status}`, response.status);
            }

            context.consumeBillable();
            return Result.Ok();
        } catch (err: unknown) {
            if (isAbortError(err)) {
                return Result.Fail("Webhook request timed out");
            }

            return Result.Fail(`Webhook request failed: ${exceptionToString(err)}`);
        }
    },
});
