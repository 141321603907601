import { getLocationSettings } from "./location";
import { getFeatureSetting } from "./feature-settings";
import { base64UrlEncode } from "@glide/support";

const knownDomains = [
    "go.glideapps.com",
    "staging.heyglide.com",
    "sandbox.heyglide.com",
    "builder.local.internal.glideapps.com",
    "localhost",
    "localhost:3000",
];

export function isKnownDomain(hostname: string) {
    const builderHost = new URL(getLocationSettings().urlPrefix).host;

    // While in theory we should never need to care about any builder domain except
    // the correct one for the current environment, this causes tests that use other builder domains
    // to fail. We should fix the tests to not rely on this, but for now we're just preserving
    // the old behaviour to keep things working, while also checking the correct builder host
    // in case it's not on the knownDomains list.

    return hostname === builderHost || knownDomains.includes(hostname);
}

export function isCustomDomain(hostname: string, ipv4IsCustom: boolean = false): boolean {
    return (
        !isKnownDomain(hostname) &&
        !(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(hostname) && !ipv4IsCustom) &&
        !hostname.endsWith(".firebaseapp.com")
    );
}

export function makeMagicLinkURL(appURL: string, token: string): string {
    let appLoginToken: string = token;
    if (getFeatureSetting("useBase64EncodingForAppLoginToken")) {
        // We use `base64url` encoding here, which is a URL-safe variant of base64.
        appLoginToken = base64UrlEncode(token);
        // The token should not have any forbidden characters, but we keep `encodeURIComponent()` just in case since
        // we'll call decode on the full url when we extract it, we may delete this after some testing and remove the FF.
    }
    return `${appURL}#appLoginToken=${encodeURIComponent(appLoginToken)}`;
}
