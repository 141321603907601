import { getAppFeatures, getEmailClient } from "@glide/common-core/dist/js/components/SerializedApp";
import { type LoadingValue, isLoadingValue } from "@glide/computation-model-types";
import { asMaybeString } from "@glide/common-core/dist/js/computation-model/data";

import { type TableColumn, getTableColumnDisplayName } from "@glide/type-schema";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { ListItemFlags } from "@glide/component-utils";
import type { WireAppAdaptiveListItemComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type PropertyDescriptor,
    makeInlineTemplatePropertyDescriptor,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type EmailClient, makeMailtoURL, nullToUndefined } from "@glide/support";
import {
    type WireRowComponentHydratorConstructor,
    type WireValueInflationBackend,
    WireComponentKind,
    type WireInflationBackend,
    type WireRowHydrationValueProvider,
    type WireValueGetter,
} from "@glide/wire";
import {
    hydrateActionWithRunnerAfter,
    hydrateOpenURL,
    inflateActions,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerBusyActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { ActionComponentHandlerBase } from "./action-component";
import { getActionsForComponent } from "./component-utils";
import { makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import type {
    ComponentKind,
    LegacyPropertyDescription,
    MutatingScreenKind,
    ActionComponentDescription,
    ComponentDescription,
    ActionDescription,
} from "@glide/app-description";
import {
    makeColumnProperty,
    makeStringProperty,
    makeIconProperty,
    makeActionProperty,
    ActionKind,
    makeEnumProperty,
    makeArrayProperty,
} from "@glide/app-description";

export const ComponentKindEmail: ComponentKind = "email";

export interface AdditionalEmailProperties {
    readonly subjectProperty?: LegacyPropertyDescription;
    readonly bodyProperty?: LegacyPropertyDescription;
    readonly ccProperty?: LegacyPropertyDescription;
    readonly bccProperty?: LegacyPropertyDescription;
}

export function makeEmailPropertyDescriptors(
    emailPropertyName: string,
    withAdditionalProperties: boolean,
    mutatingScreenKind: MutatingScreenKind | undefined
): readonly PropertyDescriptor[] {
    const props: PropertyDescriptor[] = [
        makeInlineTemplatePropertyDescriptor(
            emailPropertyName,
            "To",
            "Recipient",
            true,
            "withLabel",
            mutatingScreenKind,
            {
                preferredType: "email-address",
                isDefaultCaption: true,
                columnFirst: true,
                preferredNames: ["email"],
            }
        ),
    ];

    if (withAdditionalProperties) {
        props.push(
            makeInlineTemplatePropertyDescriptor(
                "subjectProperty",
                "Subject",
                "Email subject",
                false,
                "withLabel",
                mutatingScreenKind,
                {
                    emptyByDefault: true,
                    searchable: false,
                    columnFirst: true,
                }
            ),
            makeInlineTemplatePropertyDescriptor(
                "bodyProperty",
                "Body",
                "Email text",
                false,
                "withLabel",
                mutatingScreenKind,
                {
                    emptyByDefault: true,
                    searchable: false,
                    columnFirst: true,
                }
            ),
            makeInlineTemplatePropertyDescriptor(
                "ccProperty",
                "CC",
                "Other recipients",
                false,
                "withLabel",
                mutatingScreenKind,
                {
                    preferredType: "email-address",
                    emptyByDefault: true,
                    searchable: false,
                    columnFirst: true,
                }
            ),
            makeInlineTemplatePropertyDescriptor(
                "bccProperty",
                "BCC",
                "Silent recipients",
                false,
                "withLabel",
                mutatingScreenKind,
                {
                    preferredType: "email-address",
                    emptyByDefault: true,
                    searchable: false,
                    columnFirst: true,
                }
            )
        );
    }

    return props;
}

interface EmailGetters {
    readonly recipientGetter: WireValueGetter;
    readonly subjectGetter: WireValueGetter;
    readonly bodyGetter: WireValueGetter;
    readonly ccGetter: WireValueGetter;
    readonly bccGetter: WireValueGetter;

    // This shouldn't really be in here, but it makes using this much more
    // convenient.
    readonly emailClient: EmailClient;
}

export function makeEmailGetters(
    ib: WireValueInflationBackend,
    recipientDesc: LegacyPropertyDescription | undefined,
    desc: AdditionalEmailProperties | undefined
): EmailGetters | undefined {
    if (recipientDesc === undefined || desc === undefined) return undefined;

    const [recipientGetter, recipientType] = ib.getValueGetterForProperty(recipientDesc, false);
    const [subjectGetter, subjectType] = ib.getValueGetterForProperty(desc.subjectProperty, true);
    const [bodyGetter, bodyType] = ib.getValueGetterForProperty(desc.bodyProperty, true);
    const [ccGetter, ccType] = ib.getValueGetterForProperty(desc.ccProperty, false);
    const [bccGetter, bccType] = ib.getValueGetterForProperty(desc.bccProperty, false);

    if (
        recipientType === undefined &&
        subjectType === undefined &&
        bodyType === undefined &&
        ccType === undefined &&
        bccType === undefined
    ) {
        return undefined;
    }
    const emailClient = getEmailClient(getAppFeatures(ib.adc.appDescription));

    return { recipientGetter, subjectGetter, bodyGetter, ccGetter, bccGetter, emailClient };
}

export function hydrateEmailURL(
    vp: WireRowHydrationValueProvider,
    getters: EmailGetters
): string | undefined | LoadingValue {
    const { recipientGetter, subjectGetter, bodyGetter, ccGetter, bccGetter, emailClient } = getters;

    const recipientValue = nullToUndefined(recipientGetter(vp));
    if (isLoadingValue(recipientValue)) return recipientValue;
    const recipient = asMaybeString(recipientValue);

    const subjectValue = nullToUndefined(subjectGetter(vp));
    if (isLoadingValue(subjectValue)) return subjectValue;
    const subject = asMaybeString(subjectValue);

    const bodyValue = nullToUndefined(bodyGetter(vp));
    if (isLoadingValue(bodyValue)) return bodyValue;
    const body = asMaybeString(bodyValue);

    const ccValue = nullToUndefined(ccGetter(vp));
    if (isLoadingValue(ccValue)) return ccValue;
    const cc = asMaybeString(ccValue);

    const bccValue = nullToUndefined(bccGetter(vp));
    if (isLoadingValue(bccValue)) return bccValue;
    const bcc = asMaybeString(bccValue);

    return makeMailtoURL(emailClient, recipient, subject, body, cc, bcc);
}

interface EmailComponentDescription extends ActionComponentDescription, AdditionalEmailProperties {
    readonly propertyName: LegacyPropertyDescription;
    readonly caption: LegacyPropertyDescription | undefined;
}

export class EmailComponentHandler extends ActionComponentHandlerBase<EmailComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindEmail);
    }

    public getDescriptor(
        _desc: EmailComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Email",
            description: "Display and compose an email",
            img: "co-email",
            group: "Buttons",
            helpUrl: getDocURL("email"),
            properties: [
                makeTextPropertyDescriptor("propertyName", "To", "Recipient", true, mutatingScreenKind, {
                    preferredType: "email-address",
                    isDefaultCaption: true,
                    columnFirst: true,
                }),
                makeTextPropertyDescriptor("subjectProperty", "Subject", "Sender", false, mutatingScreenKind, {
                    emptyByDefault: true,
                    searchable: false,
                    columnFirst: true,
                }),
                makeTextPropertyDescriptor("bodyProperty", "Body", "Email text", false, mutatingScreenKind, {
                    emptyByDefault: true,
                    searchable: false,
                    columnFirst: true,
                }),
                makeTextPropertyDescriptor("ccProperty", "CC", "Other recipients", false, mutatingScreenKind, {
                    preferredType: "email-address",
                    emptyByDefault: true,
                    searchable: false,
                    columnFirst: true,
                }),
                makeTextPropertyDescriptor("bccProperty", "BCC", "Silent recipients", false, mutatingScreenKind, {
                    preferredType: "email-address",
                    emptyByDefault: true,
                    searchable: false,
                    columnFirst: true,
                }),
                makeCaptionStringPropertyDescriptor("Email", false, mutatingScreenKind),
                ...this.getBasePropertyDescriptors(),
            ],
        };
    }

    public static defaultComponent(column: TableColumn): EmailComponentDescription {
        return {
            ...makeEmptyComponentDescription(ComponentKindEmail),
            propertyName: makeColumnProperty(column.name),
            subjectProperty: undefined,
            bodyProperty: undefined,
            ccProperty: undefined,
            bccProperty: undefined,
            caption: makeStringProperty(getTableColumnDisplayName(column)),
            actions: [],
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: EmailComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const getters = makeEmailGetters(ib, desc.propertyName, desc);
        if (getters === undefined) return undefined;

        const [toWithFormatGetter] = inflateStringProperty(ib, desc.propertyName, true);
        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);

        const { actions } = getActionsForComponent(this, desc, ib.tables, ib.adc, ib.mutatingScreenKind);
        const actionHydrator = inflateActions(ib, actions);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const url = hydrateEmailURL(hb, getters);
            if (url === undefined || isLoadingValue(url)) return undefined;

            const title = toWithFormatGetter(hb);

            const onTap = registerBusyActionRunner(hb, "onTap", () =>
                hydrateActionWithRunnerAfter(
                    actionHydrator,
                    hb,
                    false,
                    title ?? undefined,
                    ...hydrateOpenURL(undefined, url, {})
                )
            );
            if (onTap === undefined) return undefined;

            const component: WireAppAdaptiveListItemComponent = {
                kind: WireComponentKind.AppAdaptiveListItem,
                ...spreadComponentID(desc.componentID, forBuilder),
                title,
                subtitle: captionGetter(hb),
                caption: null,
                image: null,
                icon: "mail",
                onTap,
                flags: ListItemFlags.InvertTitleAndSubtitle,
            };

            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: EmailComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        const sendEmailAction = {
            title: makeStringProperty("Compose email"),
            icon: makeIconProperty("path:/svg/stroke/st-mail.svg"),
            action: makeActionProperty({
                kind: ActionKind.SendEmailWithArgs,
                email: desc.propertyName,
                subjectProperty: desc.subjectProperty,
                bodyProperty: desc.bodyProperty,
                ccProperty: desc.ccProperty,
                bccProperty: desc.bccProperty,
            } as ActionDescription),
        };

        return {
            ...makeEmptyComponentDescription(WireComponentKind.ActionRow),
            text: desc.propertyName,
            label: desc.caption,
            textStyle: makeEnumProperty("Field"),
            imageStyle: makeEnumProperty("image-style-circle"),
            actions: makeArrayProperty([sendEmailAction]),
        } as ComponentDescription;
    }
}
