export async function withTimeout<T>(p: Promise<T>, timeoutValue: T, errorValue: T, timeoutMS: number): Promise<T> {
    return await new Promise<T>(async resolve => {
        let isResolved = false;

        setTimeout(() => {
            if (!isResolved) {
                isResolved = true;
                resolve(timeoutValue);
            }
        }, timeoutMS);

        let result: T;
        try {
            result = await p;
        } catch {
            result = errorValue;
        }
        if (!isResolved) {
            isResolved = true;
            resolve(result);
        }
    });
}

export function withTimeoutError<T>(p: Promise<T>, timeoutMs: number, errorMessage?: string): Promise<T> {
    return new Promise(async (resolve, reject) => {
        let isResolved = false;

        setTimeout(() => {
            if (!isResolved) {
                isResolved = true;
                reject(new Error(errorMessage ?? "timeout"));
            }
        }, timeoutMs);

        try {
            const result = await p;
            if (!isResolved) {
                isResolved = true;
                resolve(result);
            }
        } catch (err: unknown) {
            if (!isResolved) {
                isResolved = true;
                reject(err);
            }
        }
    });
}

// `undefined` means no timeout
export function withTimeoutSync<T>(f: (isTimedOut: () => boolean) => T, timeoutMS: number | undefined): T {
    if (timeoutMS === undefined) {
        return f(() => false);
    } else {
        const end = Date.now() + timeoutMS;
        return f(() => Date.now() > end);
    }
}

export function cancellableSleep(timeMS: number, signal: AbortSignal): Promise<void> {
    return new Promise<void>(resolve => {
        if (signal.aborted) return resolve();

        let resolved = false;
        const onEnd = () => {
            signal.removeEventListener("abort", onEnd);
            clearTimeout(timeout);

            if (!resolved) {
                resolved = true;
                resolve();
            }
        };

        const timeout = setTimeout(onEnd, timeMS);
        signal.addEventListener("abort", onEnd);
    });
}
