import type { QuotaDocument } from "@glide/common-core/dist/js/Database/quotas";
import { useQuery } from "@tanstack/react-query";
import { getQuotaStateForApp } from "../lib/usage-lib";

const TEN_MINUTES_IN_MILLISECONDS = 10 * 60 * 1000;

/**
 * Retrieves the quota state for an app.
 *
 * @param appID - The ID of the Glide app.
 * @param enabled - Whether the query is enabled or not.
 * @returns The quota document for the Glide app.
 */
export function useQuotaStateForApp(appID: string, enabled: boolean): { quotaStateForApp: QuotaDocument | undefined } {
    const { data } = useQuery(
        ["useQuotaStateForApp", appID],
        async () => {
            return await getQuotaStateForApp(appID);
        },
        {
            enabled,
            refetchInterval: TEN_MINUTES_IN_MILLISECONDS,
            refetchOnWindowFocus: false,
        }
    );

    return { quotaStateForApp: data };
}
