export class ExpiringCache<TKey, TValue> {
    // key -> [value, expires at]
    private readonly cache = new Map<TKey, [TValue, number]>();
    private lastClearedAt: number;

    // We clear the cache at most every `clearThottle` ms.
    constructor(private readonly clearThrottle: number) {
        this.lastClearedAt = Date.now();
    }

    private clearExpired(): void {
        const now = Date.now();

        // We don't clear until `lastClearedAt+clearThrottle` has passed.
        if (this.lastClearedAt + this.clearThrottle > now) return;

        // The set of keys to delete
        const toDelete = new Set<TKey>();
        for (const [k, [, e]] of this.cache) {
            if (e <= now) {
                toDelete.add(k);
            }
        }
        for (const k of toDelete) {
            this.cache.delete(k);
        }

        this.lastClearedAt = now;
    }

    // `create` has to return the value and the date at which the value
    // expires.
    public getOrCreate(key: TKey, create: () => [TValue, Date]): TValue {
        const existing = this.cache.get(key);
        // Do we have a value?
        if (existing !== undefined) {
            const [value, expiration] = existing;
            // If so, is it still within expiration time?
            if (expiration > Date.now()) {
                return value;
            }
        }

        // We either don't have a value, or it's expired.

        // Clear all the expired values.
        this.clearExpired();

        // Then make a new one for this key.
        const [newValue, newExpiration] = create();
        this.cache.set(key, [newValue, newExpiration.getTime()]);
        return newValue;
    }

    public remove(key: TKey) {
        this.cache.delete(key);
    }
}
