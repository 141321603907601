import type { JSONObject } from "@glide/support";
import { parseJSONSafely } from "@glide/support";
import { isLeft, isRight } from "fp-ts/lib/Either";
import * as iots from "io-ts";

export const glideJSONDocumentDataCodec = iots.type({
    kind: iots.literal("glide-json"),
    value: iots.string,
});

export type GlideJSONDocumentData = iots.TypeOf<typeof glideJSONDocumentDataCodec>;

export class GlideJSON {
    private constructor(private readonly _docData: GlideJSONDocumentData) {}

    public static fromDocumentData(d: GlideJSONDocumentData): GlideJSON {
        return new GlideJSON(d);
    }

    public static fromJSON(json: JSONObject | null): GlideJSON {
        return GlideJSON.fromDocumentData({ kind: "glide-json", value: JSON.stringify(json) });
    }

    public toDocumentData(): GlideJSONDocumentData {
        return { ...this._docData };
    }

    public toJSON(): GlideJSONDocumentData {
        return this.toDocumentData();
    }

    get jsonString(): string {
        return this._docData.value;
    }

    // This will return either some JSON value or `null` if the string can't
    // be parsed.  Ideally this would use the type `JSONValue` but that ends
    // up causing a stack overflow in the TypeScript type checker.
    get jsonValue(): unknown {
        // This should never fail because we only make GlideJSONs with
        // `fromJSON`, but better safe than sorry.
        return parseJSONSafely(this.jsonString) ?? null;
    }

    get isNull(): boolean {
        // This is potentially a lot faster than using `this.jsonValue`, but
        // that could involve parsing a huge JSON string, only to find out
        // that it's not null.
        return this.jsonString.trim() === "null";
    }
}

export function isGlideJSONDocumentData(x: unknown): x is GlideJSONDocumentData {
    // This check significantly speeds up the case where `x` is not a
    // `GlideJSON`.
    if (typeof x !== "object") return false;
    return isRight(glideJSONDocumentDataCodec.decode(x));
}

export const glideJSONCodec = new iots.Type<GlideJSON, GlideJSONDocumentData, unknown>(
    "glideJSONCodec",
    (i: unknown): i is GlideJSON => i instanceof GlideJSON,
    i => {
        if (i instanceof GlideJSON) return iots.success(i);
        const docData = glideJSONDocumentDataCodec.decode(i);
        return isLeft(docData) ? docData : iots.success(GlideJSON.fromDocumentData(docData.right));
    },
    i => i.toDocumentData()
);
