import * as React from "react";

import { Wrapper } from "./growing-entry-view-style";

import { assert } from "@glideapps/ts-necessities";
import { browserMightBeOniOS } from "@glide/common";
import "twin.macro";

interface Props
    extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    placeholder?: string;
    inputRef?: any;
}

const GrowingEntryImpl: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
    const { placeholder, value, inputRef, className, ...rest } = props;
    const { onChange } = rest;

    const useText = value ?? "";

    assert(onChange !== undefined, "GrowingEntry must be a controlled input area");

    return (
        <Wrapper iOSHack={browserMightBeOniOS} className={className}>
            <div className={"shadow-box"}>{useText + "\n"}</div>

            {useText === "" && (
                <div
                    className="placeholder"
                    tw="whitespace-pre-wrap absolute top-0 right-0 w-full h-full max-h-full overflow-hidden"
                >
                    {placeholder}
                </div>
            )}
            <textarea
                className={"ta-inputbox"}
                data-test="app-growing-entry"
                ref={inputRef}
                value={useText}
                placeholder=""
                {...rest}
            />
        </Wrapper>
    );
};

const GrowingEntryView = React.memo(GrowingEntryImpl);
export default GrowingEntryView;
