import { ConditionVariable } from "@glide/support";

const exitBlocksCV = new ConditionVariable();
const exitBlocks = new Set<number>();
let exitBlockSerial = 0;

function blockWindowExit(): number {
    const handle = exitBlockSerial++;
    exitBlocks.add(handle);
    exitBlocksCV.notifyAll();
    return handle;
}

function unblockWindowExit(handle: number) {
    exitBlocks.delete(handle);
    exitBlocksCV.notifyAll();
}

export async function blockingWindowExit<T>(op: () => Promise<T>): Promise<T> {
    const handle = blockWindowExit();
    try {
        return await op();
    } finally {
        unblockWindowExit(handle);
    }
}

export function hasWindowExitBlocks(): boolean {
    return exitBlocks.size > 0;
}

export async function waitForUnblockedWindowExit(): Promise<void> {
    while (hasWindowExitBlocks()) {
        await exitBlocksCV.wait();
    }
}

// We might have been imported on Node, which is lacking a Window.
// This is fine, you can't exit or reload the nonexistent window anyway.
if (typeof window !== "undefined" && typeof window.addEventListener === "function") {
    window.addEventListener("beforeunload", ev => {
        if (exitBlocks.size === 0) return;
        ev.preventDefault();
        // Nobody actually uses this for display, but it needs to be
        // explicitly set on Chrome or else we won't be able to cancel
        // the tab closing.
        // See https://caniuse.com/?search=beforeunload
        ev.returnValue = "";
        return "";
    });
}
