import { PastAndOrFuture } from "@glide/common-core/dist/js/components/past-future";
import type { AppKind } from "@glide/location-common";
import { parseValueAsGlideDateTimeSync } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    getEnumProperty,
    makeEnumProperty,
} from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { GlideDateTime } from "@glide/data-types";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    EnumPropertyHandler,
    PropertySection,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import {
    type WireRowComponentHydratorConstructor,
    WireComponentKind,
    type WireInflationBackend,
    type WireValueGetter,
} from "@glide/wire";
import { type BaseFieldComponentDescription, BaseFieldComponentHandler, defaultFieldComponent } from "./base-field";

const ComponentKindDateField: ComponentKind = "date-field";

export const propertyNamePropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    undefined,
    getPrimitiveColumnsSpec,
    "date-time",
    PropertySection.Data
);

export interface DateTimeFieldComponentDescription extends BaseFieldComponentDescription {
    readonly pastAndOrFuture: PropertyDescription | undefined;
}

export const pastAndOrFuturePropertyHandler = new EnumPropertyHandler(
    { pastAndOrFuture: PastAndOrFuture.All },
    "Range",
    "Range",
    [
        {
            label: "All dates",
            value: PastAndOrFuture.All,
        },
        {
            label: "Today or earlier",
            value: PastAndOrFuture.TodayOrPast,
        },
        {
            label: "Today or later",
            value: PastAndOrFuture.TodayOrFuture,
        },
    ],
    PropertySection.Options,
    "dropdown"
);

export class DateFieldComponentHandler extends BaseFieldComponentHandler<DateTimeFieldComponentDescription> {
    constructor() {
        super(ComponentKindDateField, "date");
    }

    public get appKinds(): AppKind | "both" {
        return "both";
    }

    public getDescriptor(
        desc: DateTimeFieldComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return this.getFieldDescriptor(
            desc,
            ccc,
            "Date",
            "Display and edit a date",
            "Pickers",
            "co-date",
            propertyNamePropertyHandler,
            false,
            getDocURL("dateField"),
            mutatingScreenKind,
            "Date",
            [pastAndOrFuturePropertyHandler]
        );
    }

    protected getDefaultValueGetter(
        ib: WireInflationBackend,
        desc: DateTimeFieldComponentDescription
    ): WireValueGetter | undefined {
        const enumValue = getEnumProperty(desc.defaultValue);
        if (enumValue === "now") {
            return () => GlideDateTime.now().localStartOfDay();
        }
        return super.getDefaultValueGetter(ib, desc);
    }

    public inflate(
        ib: WireInflationBackend,
        desc: DateTimeFieldComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        return super.inflateComponent(ib, desc, (base, value, token) => ({
            ...base,
            kind: WireComponentKind.DateTimeField,
            value: { value: parseValueAsGlideDateTimeSync(value), onChangeToken: token },
            pastOrFuture: pastAndOrFuturePropertyHandler.getEnum(desc),
            withTime: false,
        }));
    }

    public static defaultComponent(column: TableColumn): DateTimeFieldComponentDescription {
        return {
            ...defaultFieldComponent(column, ComponentKindDateField),
            pastAndOrFuture: makeEnumProperty(PastAndOrFuture.All),
        };
    }
}
