/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

const { Result } = glide;

export const plugin = glide.newPlugin({
    id: "push-notifications",
    name: "Push Notifications",
    description: "Send push notifications to your users",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/push-notifications.png",
    tier: "starter",
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/push-notifications",
    experimentFlag: "plugin-push-notifications",
});

const email = glide.makeParameter({
    type: "stringArray",
    name: "Recipient email",
    description:
        "The email of the user you would like to send the notification to. You can use the Split Text column to notify multiple users.",
    placeholder: "e.g. alma@example.com",
    required: true,
});

const title = glide.makeParameter({
    type: "string",
    name: "Title",
    description: "The title of the notification",
    required: true,
    useTemplate: "withLabel",
});

const message = glide.makeParameter({
    type: "string",
    name: "Message",
    description: "The body of the notification",
    required: true,
    useTemplate: "withLabel",
});

const link = glide.makeParameter({
    type: "string",
    name: "Link to screen",
    description: "The screen the user will see when they click the notification",
    placeholder: "e.g. https://my-app.glide.page/dl/6471c6",
    useTemplate: "withLabel",
});

const sendTo = glide.makeParameter({
    name: "Send to",
    type: "enum",
    values: [
        {
            label: "Send to everyone",
            value: "everyone",
        },
        {
            label: "Send to specific users",
            value: "users",
        },
    ],
});

plugin.addClientAction({
    id: "send-push-notification",
    name: "Send push notification",
    description: "Send a push notification to users",
    billablesConsumed: {
        number: 1,
        per: "recipient",
    },
    parameters: {
        sendTo,
        email: email.when(sendTo, "is", "users"),
        title,
        message,
        link,
    },
    execute: async (ctx, { sendTo, email, title, message, link }) => {
        let emails: string[] | undefined;
        if (sendTo === "users") {
            emails = [];
            for (const e of email ?? []) {
                emails.push(
                    ...e
                        .split(",")
                        .map(e => e.trim())
                        .filter(e => e !== "")
                );
            }

            if (emails.length === 0) {
                return Result.FailPermanent("Cannot send message to empty email", {
                    isPluginError: false,
                });
            }
        }
        // don't call on frontend as the backend already consumes the billables automatically. This
        // is because the frontend doesn't have the registration data.
        return await ctx.sendPushNotification(
            title ?? "",
            message ?? "",
            link,
            sendTo === "users" ? emails : undefined
        );
    },
});

// another client action to request permissions for push notifcations
plugin.addClientAction({
    id: "request-push-permissions",
    name: "Request to allow push notifications",
    description: "Request to enable push notifications on user's device",
    parameters: {},
    needsClient: true,
    execute: async () => {
        (window as any).glideNotifications.requestPermission();
        return Result.Ok();
    },
});

plugin.addClientColumn({
    id: "has-request-push-permissions",
    name: "Push notifications access granted",
    description: "Check if the user has granted access to send push notifications",
    parameters: {},
    result: "boolean",
    needsClient: true,
    execute: async () => {
        const requestPermission = (await (window as any).glideNotifications.hasRequestPermission()) as boolean;
        return Result.Ok(requestPermission);
    },
});
