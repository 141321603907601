import React from "react";
import { isDefined, localStorageGetItem, localStorageSetItem, logError } from "@glide/support";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { getFeatureSetting } from "@glide/common-core";
import { isPlayer } from "@glide/common-core/dist/js/routes";

type GetClientVersionResponse = {
    deploymentVersion: string;
    forceTransition: boolean;
    transitionTimeMillis: number;
    ttl: number;
    inMaintenanceMode: boolean;
};

type ClientVersionInfoGetter = (builderOrPlayer: "builder" | "player") => Promise<GetClientVersionResponse | undefined>;

const clientVersionStaleGetter: ClientVersionInfoGetter = async () => {
    return undefined;
};

const clientVersionLiveGetter: ClientVersionInfoGetter = async (builderOrPlayer: "builder" | "player") => {
    const cloudFunctionToCall =
        builderOrPlayer === "player" ? "getClientDeploymentVersionForPlayer" : "getClientDeploymentVersion";

    const versionInfoRequest = await getAppFacilities().callCloudFunction(
        cloudFunctionToCall,
        {
            builderOrPlayer,
        },
        {}
    );
    if (versionInfoRequest === undefined || versionInfoRequest.status !== 200) {
        logError("Failed to fetch deployment version", versionInfoRequest?.status, versionInfoRequest?.statusText);
        return;
    }
    const versionInfo = (await versionInfoRequest?.json()) as GetClientVersionResponse;
    return versionInfo;
};

const ClientVersionInfoContext = React.createContext<ClientVersionInfoGetter>(clientVersionStaleGetter);

function useClientVersionInfoGetter() {
    return React.useContext(ClientVersionInfoContext);
}

export const LiveVersionCheckerProvider: React.FC<React.PropsWithChildren> = p => {
    const { children } = p;

    return (
        <ClientVersionInfoContext.Provider value={clientVersionLiveGetter}>
            {children}
        </ClientVersionInfoContext.Provider>
    );
};

// we need this info on client load since it will not be the case post navigation.
const hasLoadedSpecificVersion = new URLSearchParams(window.location.search).has("dv");

export const useClientVersionChecks = (builderOrPlayer: "builder" | "player") => {
    const currentVersion = (window as any).glideDeploymentVersion;
    const [latestVersionInfo, setLatestVersionInfo] = React.useState<GetClientVersionResponse | undefined>(undefined);
    const localStorageSkipUpgrade =
        localStorageGetItem(`skip-upgrade-${latestVersionInfo?.deploymentVersion ?? "dev"}`) === "true";
    const [hasDismissedLatestVersion, setDismissedLatestVersion] = React.useState(localStorageSkipUpgrade);

    const versionGetter = useClientVersionInfoGetter();

    const checkForNewVersion = React.useCallback(async () => {
        const versionInfo = await versionGetter(builderOrPlayer);
        setLatestVersionInfo(versionInfo);
    }, [builderOrPlayer, versionGetter]);

    const handleDismissedLatestVersion = React.useCallback(() => {
        localStorageSetItem(`skip-upgrade-${latestVersionInfo?.deploymentVersion}`, "true");
        setDismissedLatestVersion(true);
    }, [latestVersionInfo?.deploymentVersion]);

    React.useEffect(() => {
        const isDisabled =
            (isPlayer() && !getFeatureSetting("clientVersionPollingForUpdatesInPlayer")) ||
            (!isPlayer() && !getFeatureSetting("clientVersionPollingForUpdates"));

        const skipPaths = ["support", "join", "preConnect", "connectGCP", "verify"];
        const shouldSkipBasedOnUrl = skipPaths.some(path => window.location.href.includes(path));
        if (currentVersion === "dev" || isDisabled || hasLoadedSpecificVersion || shouldSkipBasedOnUrl) {
            // for local dev, we don't need to check for new versions
            return;
        }
        void checkForNewVersion();
        const pollingInterval = setInterval(checkForNewVersion, latestVersionInfo?.ttl ?? 10 * 60 * 1000); // Use TTL or default to 10 min
        return () => clearInterval(pollingInterval);
    }, [checkForNewVersion, currentVersion, latestVersionInfo?.ttl]);

    const newVersionAvailable = isDefined(latestVersionInfo) && latestVersionInfo.deploymentVersion !== currentVersion;

    return {
        latestVersionInfo,
        newVersionAvailable,
        hasDismissedLatestVersion: hasDismissedLatestVersion || localStorageSkipUpgrade,
        handleDismissedLatestVersion,
    };
};
