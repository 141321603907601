import { WireButton } from "../../renderers/wire-button/wire-button";
import { signInCTAButtonStyles, signInSecondaryButtonStyles } from "../custom-sign-in/custom-sign-in-styles";
import { UIButtonAppearance } from "@glide/wire";

interface Props {
    readonly onClick: (() => void) | undefined;
    readonly disabled?: boolean;
    readonly label: string;
}

export const SignInPrimaryButton: React.FC<Props> = p => {
    const { onClick, disabled, label } = p;
    return (
        <WireButton
            onClick={onClick}
            disabled={disabled}
            appearance={UIButtonAppearance.Filled}
            size={"lg"}
            css={signInCTAButtonStyles}
            tw="w-full mt-3 gp-md:mt-4 rounded-[10px]">
            {label}
        </WireButton>
    );
};

export const SignInSecondaryButton: React.FC<Props> = p => {
    const { onClick, disabled, label } = p;
    return (
        <WireButton
            onClick={onClick}
            disabled={disabled}
            appearance={UIButtonAppearance.Simple}
            size={"lg"}
            css={signInSecondaryButtonStyles}
            tw="w-full mt-3 text-accent gp-md:mt-4 rounded-[10px]">
            {label}
        </WireButton>
    );
};
