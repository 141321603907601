import { formatLocalizedString, getLocalizedString } from "@glide/localization";
import type { AppFeatures, AppManifest, IconImage } from "@glide/app-description";
import {
    defaultAppDescriptionText,
    getAppKindFromFeatures,
    isDefaultAppDescriptionText,
} from "@glide/common-core/dist/js/components/SerializedApp";
import { loadPublishedApp } from "@glide/common-core/dist/js/Database";
import { Icon } from "@glide/common-core/dist/js/render/icon";
import { fullScreen } from "@glide/common-core/dist/js/routes";
import { copyToClipboard as copy } from "@glide/common";
import * as React from "react";
import { type RouteComponentProps, withRouter } from "react-router-dom";
import { shareOnFacebook, shareOnTwitter } from "sharedUI/lib/share-utils";
import { Dropdown } from "sharedUI/ui/smart/dropdown-menu/dropdown-menu";
import { type DropdownItem, DropdownAnchor } from "sharedUI/ui/smart/dropdown-menu/dropdown-menu-types";
import { getWebDatabase } from "@glide/client-database";
import { localhostURL, urlForApp } from "../../../lib/appid-utils";
import { Body, Header, IconWrapper, ShareLink, ShareWrapper, Subtitle, Wrapper } from "./app-info-pad-style";

interface Props extends RouteComponentProps {
    title: string;
    manifest: AppManifest;
    features: AppFeatures;
    id: string;
    showBranding: boolean;
    allowTabletMode: boolean;
    iconImage: IconImage;
}

class AppInfoPad extends React.Component<Props> {
    // FIXME: We should have a loaded app, we don't need to load it again here.
    private async urlForAppID(): Promise<string> {
        const appID = this.props.id;
        const maybeURL = localhostURL(appID);
        if (maybeURL !== undefined) {
            return maybeURL;
        }

        const db = getWebDatabase();
        const app = await loadPublishedApp(db, appID);
        return urlForApp(appID, getAppKindFromFeatures(this.props.features), app);
    }

    private async handleShare(type: string): Promise<void> {
        switch (type) {
            case "Facebook": {
                shareOnFacebook(await this.urlForAppID());
                break;
            }
            case "Twitter": {
                shareOnTwitter(await this.urlForAppID(), this.props.title, this.props.showBranding);
                break;
            }
            case "Copy": {
                copy(window.location.href);
                break;
            }
        }

        this.setState({ dropdownVisible: false });
    }

    public render() {
        const { manifest, title, id, features, showBranding, allowTabletMode, iconImage } = this.props;
        let author = "an anonymous benefactor";
        let desc = defaultAppDescriptionText;

        if (manifest !== undefined) {
            if (manifest.description !== undefined) {
                desc = manifest.description;
            }
            if (manifest.author !== undefined) {
                author = manifest.author;
            }
        }

        const { disableSharing } = features;
        const appKind = getAppKindFromFeatures(features);

        const menu: DropdownItem[] = [
            { isEnabled: false, content: getLocalizedString("shareOn", appKind) },
            { content: "Facebook", onClick: async () => await this.handleShare("Facebook") },
            { content: "Twitter", onClick: async () => await this.handleShare("Twitter") },
            { content: "Copy Link", onClick: async () => await this.handleShare("Copy") },
        ];

        const shareLink =
            disableSharing !== true ? (
                <Dropdown menu={menu} anchor={DropdownAnchor.BottomLeft} minWidth={340}>
                    <ShareLink className="share-link" color="#000000">
                        <p>{getLocalizedString("shareApp", appKind)}</p>
                    </ShareLink>
                </Dropdown>
            ) : undefined;

        const authorText = formatLocalizedString("authoredBy", [author], appKind);
        const [authorTextBeforeItalic, authorTextRest] = authorText.split("<i>");
        const [authorItalicText, authorTextAfterItalic] = authorTextRest.split("</i>");

        return (
            <Wrapper>
                <IconWrapper>
                    <Icon
                        size={64}
                        rounding={12}
                        fullscreen={false}
                        omitShadow={false}
                        color={"transparent"}
                        image={iconImage}
                        clipCorners={true}
                        appID={id}
                    />
                </IconWrapper>
                <Header>{title}</Header>
                <Subtitle>
                    {authorTextBeforeItalic}
                    <i>{authorItalicText}</i>
                    {authorTextAfterItalic}
                </Subtitle>
                <Body>
                    {isDefaultAppDescriptionText(desc) && showBranding
                        ? getLocalizedString("madeWithGlidePlay", appKind)
                        : desc}
                </Body>

                <ShareWrapper>
                    {allowTabletMode && (
                        <ShareLink
                            className="share-link"
                            color="#000000"
                            onClick={() => this.props.history.push(fullScreen(id, true))}
                        >
                            <p>{getLocalizedString("showFullscreen", appKind)}</p>
                        </ShareLink>
                    )}
                    {shareLink}
                </ShareWrapper>
            </Wrapper>
        );
    }
}

export default withRouter(AppInfoPad);
