import type React from "react";
type MousePositionHandler = (screenX: number, screenY: number) => void;

// The purpose of this "swipe-out" controller is to allow a child component
// to trick an ancestor component into forwarding its mouse and touch events.
//
// This was specifically built for the Swipe Stack, to allow some degree of
// forgiveness when touch events "leave" the virtual phone area.

const currentMousePositionHandlers = new Set<MousePositionHandler>();
const mouseExitHandlers = new Set<() => void>();

export function addMousePositionHandler(h: MousePositionHandler) {
    currentMousePositionHandlers.add(h);
}

export function removeMousePositionHandler(h: MousePositionHandler) {
    currentMousePositionHandlers.delete(h);
}

export function addMouseExitHandler(h: () => void) {
    mouseExitHandlers.add(h);
}

export function removeMouseExitHandler(h: () => void) {
    mouseExitHandlers.delete(h);
}

let triggerDepth = 0;

function enterMousePositionTriggering() {
    triggerDepth++;
}

function triggerMousePositionHandling(ev: React.MouseEvent<any>) {
    if (triggerDepth <= 0) return;

    ev.preventDefault();
    ev.stopPropagation();

    for (const handler of currentMousePositionHandlers) {
        handler(ev.clientX, ev.clientY);
    }
}

function triggerTouchPositionHandling(ev: React.TouchEvent<any>) {
    if (triggerDepth <= 0) return;

    if (ev.touches.length !== 1) return;

    if (ev.cancelable && !ev.isDefaultPrevented()) {
        ev.preventDefault();
    }
    ev.stopPropagation();

    const { clientX, clientY } = ev.touches[0];

    for (const handler of currentMousePositionHandlers) {
        handler(clientX, clientY);
    }
}

function exitContainerMouseHandlers(ev: React.MouseEvent<any>, ref: React.RefObject<HTMLDivElement> | null) {
    if (triggerDepth === 0) return;
    if (ref !== null && ref.current !== null && ev.target !== ref.current) return;

    triggerDepth = 0;

    for (const handler of mouseExitHandlers) {
        handler();
    }
}

function exitContainerTouchHandlers(ev: React.TouchEvent<any>, ref: React.RefObject<HTMLDivElement> | null) {
    if (triggerDepth === 0) return;
    if (ref !== null && ref.current !== null && ev.target !== ref.current) return;

    triggerDepth = 0;

    for (const handler of mouseExitHandlers) {
        handler();
    }
}

// The far "ancestor" must use the output of this function as part of its props.
//
// For example,
// <OutputEditorStyle ref={ref} {...domMovementContainerHandlers(ref)}>...
//
// The "ref" is important to allow us to continue to function correctly in
// the bubbling phase instead of mandating all of these event handlers fire
// in the capture phase. We only actually "exit" a movement session if the target
// is equal to our ref, so that children can have touches and mice leave without
// accidentally stopping all events.
export function domMovementContainerHandlers(ref: React.RefObject<HTMLDivElement>) {
    const mouseOutWithRef = (ev: React.MouseEvent<any>) => exitContainerMouseHandlers(ev, ref);
    const mouseOutWithoutRef = (ev: React.MouseEvent<any>) => exitContainerMouseHandlers(ev, null);
    const touchOutWithRef = (ev: React.TouchEvent<any>) => exitContainerTouchHandlers(ev, ref);
    const touchOutWithoutRef = (ev: React.TouchEvent<any>) => exitContainerTouchHandlers(ev, null);

    return {
        onMouseMoveCapture: triggerMousePositionHandling,
        onTouchMoveCapture: triggerTouchPositionHandling,
        onMouseUpCapture: mouseOutWithoutRef,
        onMouseLeave: mouseOutWithRef,
        onTouchEndCapture: touchOutWithoutRef,
        onTouchCancelCapture: touchOutWithRef,
    };
}

// The "child" must use the output of this function as part of its props.
//
// We don't use this directly currently. Instead, see use-swipe-out-controller.ts instead.
export function movementClientHandlers() {
    let started = false;

    const onEnter = () => {
        if (started) return;
        enterMousePositionTriggering();
        started = true;
    };

    const onEnterMouse = (ev: React.MouseEvent<any>) => {
        onEnter();
        triggerMousePositionHandling(ev);
    };

    const onEnterTouch = (ev: React.TouchEvent<any>) => {
        onEnter();
        triggerTouchPositionHandling(ev);
    };

    return {
        onMouseDownCapture: onEnterMouse,
        onTouchStartCapture: onEnterTouch,
    };
}
