import md5 from "blueimp-md5";
import fromPairs from "lodash/fromPairs";
import type { JSONObject, DocumentData } from "@glide/support";
import { panic, defined, hasOwnProperty } from "@glideapps/ts-necessities";
import { type TableColumn, type TableGlideType, getTableName } from "@glide/type-schema";
import { isLegalFirebaseName } from "@glide/common-core/dist/js/database-strings";
import type { FieldNameCache } from "@glide/common-core/dist/js/components/datastore/data-row-store";

function getDefaultFieldName(columnName: string): string {
    let fieldName = columnName;
    if (!isLegalFirebaseName(fieldName, 1000)) {
        fieldName = md5(fieldName);
    }
    return fieldName;
}

// We used to just look at individual columns for field names, but we
// ended up with clashes for some reason.  See
// https://github.com/quicktype/glide/issues/4480
export function computeFieldNamesForColumns(columns: readonly TableColumn[]): ReadonlyMap<string, string> {
    const namesGiven = new Set<string>();
    const m = new Map<string, string>();

    for (const { name } of columns) {
        // Field names are limited in size by Firestore to 1500 bytes,
        // but field paths have the same limit, so we limit the names
        // to 1000 to have 500 more left for a full path in case it
        // ever becomes an issue.

        let fieldName = getDefaultFieldName(name);
        while (namesGiven.has(fieldName)) {
            fieldName = fieldName + "x";
        }

        m.set(name, fieldName);
        namesGiven.add(fieldName);
    }

    return m;
}

export function fieldNameForColumn(cache: FieldNameCache, table: TableGlideType, columnName: string): string {
    const tableName = getTableName(table).name;
    let tableFields = cache.get(tableName);
    if (tableFields === undefined) {
        tableFields = computeFieldNamesForColumns(table.columns);
        cache.set(tableName, tableFields);
    }

    const fieldName = tableFields.get(columnName);
    if (fieldName === undefined) {
        return panic(`Column not present in table ${tableName}: ${columnName}`);
    }
    return fieldName;
}

export function makeColumnMappingForDocumentDataFromJSON(
    cache: FieldNameCache,
    // The table's columns are used to create the mapping, and its name is
    // used for caching.
    table: TableGlideType
): Record<string, string> {
    return fromPairs(table.columns.map(({ name }) => [name, fieldNameForColumn(cache, table, name)]));
}

export function convertColumnNamesToFieldNamesWithMapping(
    columns: readonly TableColumn[],
    columnsValues: JSONObject,
    mapping: Record<string, string>,
    removeProtectedColumns: boolean
): DocumentData {
    const result: DocumentData = {};
    for (const { name, isProtected } of columns) {
        if (removeProtectedColumns && isProtected === true) continue;

        if (!hasOwnProperty(columnsValues, name)) continue;
        const v = columnsValues[name];
        if (v === undefined) continue;
        result[defined(mapping[name])] = v;
    }
    return result;
}

export function convertColumnNamesToFieldNames(
    cache: FieldNameCache,
    table: TableGlideType,
    columnValues: JSONObject
): DocumentData {
    const mapping = makeColumnMappingForDocumentDataFromJSON(cache, table);
    return convertColumnNamesToFieldNamesWithMapping(table.columns, columnValues, mapping, false);
}

export type ColumnNameMapping = Record<string, string>;

export function makeColumnMappingForJSONFromDocumentData(
    cache: FieldNameCache,
    table: TableGlideType
): ColumnNameMapping {
    return fromPairs(table.columns.map(({ name }) => [fieldNameForColumn(cache, table, name), name]));
}

export function convertFieldNamesToColumnNamesWithMapping(data: DocumentData, mapping: ColumnNameMapping): JSONObject {
    const ret: JSONObject = {};
    for (const key of Object.keys(data)) {
        ret[mapping[key] ?? key] = data[key];
    }

    return ret;
}

export function convertFieldNamesToColumnNames(
    cache: FieldNameCache,
    table: TableGlideType | undefined,
    data: DocumentData
): JSONObject {
    if (table === undefined) {
        return data;
    }

    const mapping = makeColumnMappingForJSONFromDocumentData(cache, table);
    return convertFieldNamesToColumnNamesWithMapping(data, mapping);
}
