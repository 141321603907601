import type { GroundValue } from "@glide/computation-model-types";
import {
    asBoolean,
    asMaybeNumber,
    asString,
    nullLoadingToUndefined,
} from "@glide/common-core/dist/js/computation-model/data";
import {
    ArrayScreenFormat,
    getArrayProperty,
    getColumnProperty,
    getEnumProperty,
    getSourceColumnProperty,
    getStringProperty,
    getSwitchProperty,
} from "@glide/app-description";
import { getTableColumn, getTableColumnDisplayName, getTableName, isPrimitiveType } from "@glide/type-schema";
import type {
    WireDataGridCellValue,
    WireDataGridColumn,
    WireDataGridColumnKind,
    WireDataGridListComponent,
    WireDataGridRow,
} from "@glide/fluent-components/dist/js/base-components";
import {
    type DataGridColumnDescription,
    DataGrid,
    dataGridColumnSupportsDisplayValue,
    extractDataGridType,
} from "@glide/fluent-components/dist/js/fluent-components";
import {
    type InlineListComponentDescription,
    emptyEditedColumnsAndTables,
    getTableAndColumnForSourceColumn,
} from "@glide/function-utils";
import {
    type WireValueGetterGeneric,
    type WireAction,
    type WireEditableValue,
    WireActionResult,
    WireComponentKind,
    UITitleStyle,
} from "@glide/wire";
import { assertNever, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import {
    doTitleActionsForceComponentToShow,
    inflateActionsWithTitles,
    inflateComponentEnricher,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
    registerActionRunner,
} from "../wire/utils";
import { makeFluentArrayContentHandler } from "./fluent-array-handler";

function extractGridValueFromGroundValue(
    groundValue: GroundValue | null,
    kind: WireDataGridColumnKind
): WireDataGridCellValue {
    const rawValue = nullLoadingToUndefined(groundValue);

    switch (kind) {
        case "boolean":
            return asBoolean(rawValue);

        case "image-uri":
            return asString(rawValue);

        case "number":
            return asMaybeNumber(rawValue) ?? 0;

        case "string":
            return asString(rawValue);

        default:
            assertNever(kind);
    }
}

function makeEditableValue(
    kind: WireDataGridColumnKind,
    groundValue: GroundValue | null,
    groundDisplay: GroundValue | null,
    onChangeToken: string | undefined
): WireEditableValue<WireDataGridCellValue> {
    const value = extractGridValueFromGroundValue(groundValue, kind);

    let editableValue: WireEditableValue<WireDataGridCellValue> = {
        value,
        onChangeToken,
    };

    if (dataGridColumnSupportsDisplayValue(kind)) {
        const rawValue = nullLoadingToUndefined(groundValue);
        const rawDisplay = nullLoadingToUndefined(groundDisplay);
        const displayValue = rawDisplay !== undefined ? asString(rawDisplay) : asString(rawValue);

        // Can't mutate, editableValue is readonly.
        editableValue = {
            ...editableValue,
            displayValue,
        };
    }

    return editableValue;
}

interface InflatedColumn {
    readonly wireColumn: WireDataGridColumn;
    readonly valueGetter: WireValueGetterGeneric<GroundValue>;
    readonly displayGetter: WireValueGetterGeneric<GroundValue>;
    readonly columnName: string | undefined;
}

export const dataGridFluentArrayContentHandler = makeFluentArrayContentHandler(
    DataGrid,
    (ib, desc, containingRowIB) => {
        if (containingRowIB === undefined) return undefined;

        const tableName = getTableName(ib.tables.input);

        const columnDescs = getArrayProperty<DataGridColumnDescription>(desc.columns);
        const rowMarkers = getSwitchProperty(desc.rowMarkers) ?? false;
        const allowDeletingRows = getSwitchProperty(desc.allowDeletingRows) ?? false;
        const titleStyle = getEnumProperty<UITitleStyle>(desc.titleStyle) ?? UITitleStyle.Simple;
        const [componentTitleGetter] = inflateStringProperty(containingRowIB, desc.title, true);
        const [titleImageGetter] = inflateStringProperty(containingRowIB, desc.titleImage, false);

        if (columnDescs === undefined || columnDescs.length === 0) {
            return undefined;
        }

        // Everything needed to register the actions and hydrate values
        const inflatedColumns: InflatedColumn[] = [];

        for (const c of columnDescs) {
            const [valueGetter, type] = ib.getValueGetterForProperty(c.value, true);
            if (!isPrimitiveType(type)) continue;

            const isEditable = getSwitchProperty(c.isEditable) ?? false;
            const [displayGetter] = ib.getValueGetterForProperty(c.displayValue, true);

            let title = getStringProperty(c.title) ?? "";
            if (title === "") {
                let maybeColumn = definedMap(getSourceColumnProperty(c.value), sc =>
                    getTableAndColumnForSourceColumn(ib.adc, sc, ib.tables.input, undefined)
                )?.column;
                if (maybeColumn === undefined) {
                    maybeColumn = definedMap(getSourceColumnProperty(c.displayValue), sc =>
                        getTableAndColumnForSourceColumn(ib.adc, sc, ib.tables.input, undefined)
                    )?.column;
                }
                if (maybeColumn !== undefined) {
                    title = getTableColumnDisplayName(maybeColumn);
                }
            }

            const kind = extractDataGridType(type);

            inflatedColumns.push({
                wireColumn: {
                    title,
                    kind,
                    isEditable,
                },
                valueGetter,
                displayGetter,
                columnName: getColumnProperty(c.value),
            });
        }

        if (inflatedColumns.length === 0) {
            return undefined;
        }

        const [titleActionsHydrator] = inflateActionsWithTitles(containingRowIB, desc.titleActions, "title");

        const componentEnricher = inflateComponentEnricher<WireDataGridListComponent>(
            ib,
            desc as unknown as InlineListComponentDescription
        );

        const gridColumns = inflatedColumns.map(c => c.wireColumn);

        return makeSimpleWireTableComponentHydratorConstructor(ib, (hb, chb, searchActive) => {
            if (chb === undefined) return undefined;

            const rows = hb.tableScreenContext.asArray();

            const titleActions = titleActionsHydrator?.(chb, "") ?? [];
            const showIfEmpty = doTitleActionsForceComponentToShow(titleActions);

            if (rows.length === 0 && !searchActive && !showIfEmpty) return undefined;

            const component: WireDataGridListComponent = componentEnricher({
                kind: WireComponentKind.List,
                format: ArrayScreenFormat.DataGrid,
                titleStyle,
                titleImage: titleImageGetter(chb),
                columns: gridColumns,
                rows: rows.map((row, idx) => {
                    const rhb = hb.makeHydrationBackendForRow(row);

                    const cells: WireEditableValue<WireDataGridCellValue>[] = [];
                    for (const c of inflatedColumns) {
                        const {
                            wireColumn: { kind, isEditable },
                            valueGetter,
                            displayGetter,
                            columnName,
                        } = c;
                        const value = valueGetter(rhb);
                        const display = isEditable ? displayGetter(rhb) : undefined;
                        const onChangeToken = isEditable
                            ? definedMap(columnName, n => rhb.registerOnValueChange(`set-${columnName}-${idx}`, n))
                            : undefined;

                        const item = makeEditableValue(
                            kind,
                            value,
                            display,
                            onChangeToken === false ? undefined : onChangeToken
                        );

                        cells.push(item);
                    }

                    let deleteAction: WireAction | undefined;
                    if (allowDeletingRows) {
                        deleteAction = registerActionRunner(rhb, `delete-${row.$rowID}`, async ab =>
                            WireActionResult.fromResult(await ab.deleteRows(tableName, [row], true))
                        );
                    }

                    const wireRow: WireDataGridRow = {
                        cells,
                        deleteAction,
                    };
                    return wireRow;
                }),
                rowMarkers,
                title: componentTitleGetter(chb),
                titleActions,
            });

            return {
                component,
                isValid: true,
            };
        });
    },
    (desc, _tables, itemTable, _adc) => {
        let edited = emptyEditedColumnsAndTables;

        if (itemTable === undefined) return edited;

        const columnDescs = getArrayProperty<DataGridColumnDescription>(desc.columns) ?? [];
        const allowDeletingRows = getSwitchProperty(desc.allowDeletingRows) ?? false;

        if (allowDeletingRows) {
            edited = {
                ...edited,
                deletedTables: [getTableName(itemTable)],
            };
        }

        edited = {
            ...edited,
            editedColumns: mapFilterUndefined(columnDescs, c => {
                const isEditable = getSwitchProperty(c.isEditable) ?? false;
                if (!isEditable) return undefined;

                const columnName = getColumnProperty(c.value);
                if (columnName === undefined) return undefined;

                const column = getTableColumn(itemTable, columnName);
                if (column === undefined) return undefined;

                return [columnName, false, false, getTableName(itemTable)];
            }),
        };

        return edited;
    }
);
