import {
    isBasePrimitiveValue,
    areGlideDateTimeDocumentDatasEqual,
    canGlideDateTimeDocumentDatasBeOnTheSameDay,
    isGlideDateTimeDocumentData,
    isGlideJSONDocumentData,
} from "@glide/data-types";
import type { BinaryPredicateOperator } from "@glide/app-description";
import {
    BinaryPredicateCompositeOperator,
    BinaryPredicateFormulaOperator,
    UnaryPredicateFormulaOperator,
    type FilterColumn as QueryColumn,
    type FilterDataValue as QueryDataValue,
} from "@glide/type-schema";
import type { SerializedQuery } from "@glide/computation-model-types";
import { assertNever } from "@glideapps/ts-necessities";
import { areSetsEqual, shallowEqualArrays } from "@glide/support";

function areQueryColumnsEqual(a: QueryColumn, b: QueryColumn): boolean {
    return a.columnName === b.columnName;
}

function areQueryColumnsOrDataValuesEqual(
    a: QueryColumn | QueryDataValue,
    b: QueryColumn | QueryDataValue,
    compareDaysOnly: boolean
): boolean {
    if (isBasePrimitiveValue(a)) {
        if (a !== b) return false;
    } else if (isGlideDateTimeDocumentData(a)) {
        if (!isGlideDateTimeDocumentData(b)) return false;
        if (compareDaysOnly) {
            if (!canGlideDateTimeDocumentDatasBeOnTheSameDay(a, b)) return false;
        } else {
            if (!areGlideDateTimeDocumentDatasEqual(a, b)) return false;
        }
    } else if (isGlideJSONDocumentData(a)) {
        if (!isGlideJSONDocumentData(b)) return false;
        return a.value === b.value;
    } else {
        if (isBasePrimitiveValue(b) || isGlideDateTimeDocumentData(b) || isGlideJSONDocumentData(b)) return false;
        if (!areQueryColumnsEqual(a, b)) return false;
    }
    return true;
}

function doesOperatorUseDays(op: BinaryPredicateOperator): boolean {
    return (
        op === BinaryPredicateCompositeOperator.IsOnOrBefore ||
        op === BinaryPredicateCompositeOperator.IsOnOrAfter ||
        op === BinaryPredicateCompositeOperator.IsWithin
    );
}

// FIXME: fix this comment and unify with the one below

// This is where we compare all ##queryFields.  If `strictEquality` is not
// set, then we treat two queries as equal if they should give the same
// result, even if they're not strictly equal.  In particular, comparisons
// between days are not sensitive to the time of day, so we can be lenient
// there.
// https://github.com/quicktype/glide/issues/18796

// Returns `false` if the queries are too different, otherwise returns `0` if
// they're the same (with the caveat above), and a number larger than `0` if
// they're close.  Each increment of `1` means about a minute difference in
// timestamps.
export function getSerializedQueryDistance(a: SerializedQuery, b: SerializedQuery): number | false {
    let distance = 0;

    if (a.limit !== b.limit) {
        distance += 1;
    }
    if (a.deviceTzMinutesOffset !== b.deviceTzMinutesOffset) return false;

    if (a.sort.length !== b.sort.length) return false;
    for (let i = 0; i < a.sort.length; i++) {
        if (a.sort[i].columnName !== b.sort[i].columnName) return false;
        if (a.sort[i].order !== b.sort[i].order) {
            distance += 1;
        }
    }

    if (a.search === undefined) {
        if (b.search !== undefined) return false;
    } else {
        if (b.search === undefined) return false;
        // Should we use an edit distance here to get a distance measure?
        if (a.search.needle !== b.search.needle) return false;
        if (!areSetsEqual(a.search.columnNames, b.search.columnNames)) return false;
    }

    if (a.groupBy === undefined) {
        if (b.groupBy !== undefined) return false;
    } else {
        if (b.groupBy === undefined) return false;
        if (a.groupBy.limit !== b.groupBy.limit) {
            distance += 1;
        }
        if (!shallowEqualArrays(a.groupBy.columns, b.groupBy.columns)) return false;

        if (a.groupBy.sort.length !== b.groupBy.sort.length) return false;
        for (let i = 0; i < a.groupBy.sort.length; i++) {
            if (a.groupBy.sort[i].columnName !== b.groupBy.sort[i].columnName) return false;
            if (a.groupBy.sort[i].order !== b.groupBy.sort[i].order) {
                distance += 1;
            }
        }

        const len = a.groupBy.aggregates.length;
        if (len !== b.groupBy.aggregates.length) return false;
        for (let i = 0; i < len; i++) {
            const aa = a.groupBy.aggregates[i];
            const ab = b.groupBy.aggregates[i];
            if (aa.column !== ab.column) return false;
            if (aa.name !== ab.name) return false;
            if (aa.kind === "join-strings") {
                if (ab.kind !== "join-strings") return false;
                if (aa.separator !== ab.separator) return false;
            } else {
                if (aa.kind !== ab.kind) return false;
            }
        }
    }

    const numConditions = a.conditions.length;
    if (numConditions !== b.conditions.length) return false;
    for (let i = 0; i < numConditions; i++) {
        const ca = a.conditions[i];
        const cb = b.conditions[i];

        const len = ca.length;
        if (len !== cb.length) return false;
        for (let j = 0; j < len; j++) {
            const da = ca[j];
            const db = cb[j];

            if (da.negated !== db.negated) return false;

            switch (da.kind) {
                case "array-overlap":
                    if (da.kind !== db.kind) return false;
                    if (!areQueryColumnsEqual(da.column, db.column)) return false;
                    if (
                        !shallowEqualArrays(da.array, db.array, (x, y) => areQueryColumnsOrDataValuesEqual(x, y, false))
                    ) {
                        return false;
                    }
                    if (da.roleHashLHS !== db.roleHashLHS) return false;
                    break;

                // We don't yet support ##arrayIncludesInQueries.

                // case BinaryPredicateFormulaOperator.ArrayIncludes:
                //     if (da.kind !== db.kind) return false;
                //     if (!areQueryColumnsEqual(da.lhs, db.lhs)) return false;
                //     if (isArray(da.rhs)) {
                //         if (!isArray(db.rhs)) return false;
                //         if (!shallowEqualArrays(da.rhs, db.rhs)) return false;
                //     } else {
                //         if (isArray(db.rhs)) return false;
                //         if (!areQueryColumnsEqual(da.rhs, db.rhs)) return false;
                //     }
                //     break;

                case UnaryPredicateFormulaOperator.IsTruthy:
                case "is-empty":
                    if (da.kind !== db.kind) return false;
                    if (!areQueryColumnsEqual(da.column, db.column)) return false;
                    break;

                case BinaryPredicateFormulaOperator.Equals:
                case BinaryPredicateFormulaOperator.IsLessThan:
                case BinaryPredicateFormulaOperator.IsGreaterThan:
                case BinaryPredicateFormulaOperator.IsLessOrEqualTo:
                case BinaryPredicateFormulaOperator.IsGreaterOrEqualTo:
                    if (da.kind !== db.kind) return false;
                    if (!areQueryColumnsEqual(da.lhs, db.lhs)) return false;
                    if (!areQueryColumnsOrDataValuesEqual(da.rhs, db.rhs, doesOperatorUseDays(da.kind))) return false;
                    break;

                case BinaryPredicateCompositeOperator.IsBefore:
                case BinaryPredicateCompositeOperator.IsAfter:
                case BinaryPredicateCompositeOperator.IsOnOrBefore:
                case BinaryPredicateCompositeOperator.IsOnOrAfter:
                case BinaryPredicateCompositeOperator.IsWithin:
                    if (da.kind !== db.kind) return false;
                    if (!areQueryColumnsEqual(da.lhs, db.lhs)) return false;
                    if (areQueryColumnsOrDataValuesEqual(da.rhs, db.rhs, doesOperatorUseDays(da.kind))) {
                        continue;
                    } else if (isGlideDateTimeDocumentData(da.rhs) && isGlideDateTimeDocumentData(db.rhs)) {
                        const diff = Math.abs(da.rhs.value - db.rhs.value);
                        distance += diff / 1000 / 60;
                    } else if (typeof da.rhs === "string" && typeof db.rhs === "string") {
                        if (da.rhs !== db.rhs) return false;
                    } else {
                        return false;
                    }
                    break;

                case BinaryPredicateFormulaOperator.ContainsString:
                case BinaryPredicateFormulaOperator.IsContainedInString:
                case BinaryPredicateFormulaOperator.MatchesEmailAddress:
                    if (da.kind !== db.kind) return false;
                    if (!areQueryColumnsEqual(da.lhs, db.lhs)) return false;
                    if (typeof da.rhs === "string") {
                        if (da.rhs !== db.rhs) return false;
                    } else {
                        if (typeof db.rhs === "string") return false;
                        if (!areQueryColumnsEqual(da.rhs, db.rhs)) return false;
                    }
                    break;

                default:
                    return assertNever(da);
            }
        }
    }

    return distance;
}

export function areSerializedQueriesEquivalent(a: SerializedQuery, b: SerializedQuery): boolean {
    return getSerializedQueryDistance(a, b) === 0;
}
