import type {
    AppUserData,
    DataRowStore,
    FieldNameCache,
    TableDataEntries,
} from "@glide/common-core/dist/js/components/datastore/data-row-store";
import type { ActionPoster } from "@glide/post-action";
import {
    convertFieldNamesToColumnNames,
    convertFieldNamesToColumnNamesWithMapping,
    makeColumnMappingForJSONFromDocumentData,
    adaptValuesForWriting,
    addRowIDToColumnValues,
    type AddRowQueueItem,
} from "@glide/post-action";
import { sortByRowIndex } from "@glide/common-core/dist/js/components/table-index";
import {
    type ComputationModel,
    type LoadedGroundValue,
    type Row,
    type WritableValue,
    type TableKeeperStore,
    TableKeeperStoreImpl,
    type Handler,
    type RowIndex,
    type BaseRowIndex,
    isBaseRowIndex,
} from "@glide/computation-model-types";
import type {
    ActionAppEnvironment,
    ActionAppFacilities,
    ActionOperationsState,
    ActionOutstandingOperationsHandler,
    AddRowToTableResult,
    AppUserProvider,
    DataSnapshotProvider,
    DataStore,
    DataStoreMutationOptions,
    MutationResult,
    NativeTableSnapshotRow,
    OfflineQueue,
    SnapshotFormatVersion,
} from "@glide/common-core/dist/js/components/types";
import { extractActionValues } from "@glide/common-core/dist/js/computation-model/row-data";
import type { Database, DiffResult } from "@glide/common-core/dist/js/Database/core";
import {
    type DiffResults,
    type DocumentData,
    type DocumentDataWithID,
    type RowDocumentDatas,
    type UserSpecificRow,
    combineRowDocumentDatas,
    deleteRowDocumentData,
    listenToPublishedAppData,
    makeAppUserForAppFromDocument,
    postDeleteRowAction,
    setRowDocumentData,
    userSpecificRowCodec,
} from "@glide/common-core/dist/js/Database";
import {
    areTableNamesEqual,
    type TableName,
    rowIndexColumnName,
    type TableGlideType,
    type TypeSchema,
    findTable,
    getEmailOwnersColumnNames,
    getTableColumn,
    getTableName,
    isComputedColumn,
    isUserAgnosticDataColumn,
    isolateTypeSchema,
    makeTypeSchema,
    tableHasRowOwners,
    isBigTableOrExternal,
    isQueryableTable,
    type SchemaInspector,
    getNativeTableSourceMetadata,
    isGlideBigTablesSourceMetadata,
} from "@glide/type-schema";
import {
    documentIDForTableName,
    makeAppUsersForAppPath,
    rowVersionFieldName,
} from "@glide/common-core/dist/js/database-strings";
import { eminenceFull } from "@glide/common-core/dist/js/Database/eminence";
import { QuotaKind, getQuotaLimitIncludingOverage } from "@glide/common-core/dist/js/Database/quotas";
import { getDeviceID } from "@glide/common-core/dist/js/device-id";
import type { EnqueueDeleteRowsRequestBody, WriteSourceType } from "@glide/common-core/dist/js/firebase-function-types";
import { ShouldAgnostifyDateTimes } from "@glide/common-core/dist/js/schema-properties";
import {
    ComputationModelImpl,
    getRowIDColumnNameOrProxy,
    ComputationModelDataRowStore,
    isNewRowOwnedByUser,
    QueryableLayeredTableKeeper,
    type QueryFetcher,
    type ComputationModelOptions,
    forceLoadQueryableTable,
} from "@glide/computation-model";
import { convertValueFromSerializable } from "@glide/data-types";
import { makeSimpleSchemaInspector } from "@glide/generator/dist/js/components/simple-ccc";
import { compareSchemas, setEmailOwnersColumnsInSchema } from "@glide/generator/dist/js/description-utils";
import type { SerializablePluginMetadata } from "@glide/plugins";
import {
    type ChangeObservable,
    Watchable,
    areSetsEqual,
    isDefined,
    logError,
    logInfo,
    normalizeEmailAddress,
    objectWithUndefinedProperties,
    waitForChangeObservableValue,
} from "@glide/support";
import { assert, assertNever, defined, mapFilterUndefined, panic, type Writable } from "@glideapps/ts-necessities";
import { areEqual, definedMap } from "collection-utils";
import deepEqual from "deep-equal";
import { isLeft } from "fp-ts/lib/Either";
import debounce from "lodash/debounce";
import fromPairs from "lodash/fromPairs";
import type { DurableStorageController } from "./durable-storage-controller";
import { NonblockingResilientDurableStorageController } from "./durable-storage-controller/nonblocking-resilient";
import type {
    FirestoreTableListener,
    MakeFirestoreTableListener,
    DocumentsFirestoreHandler,
    SnapshotDiffResults,
    TableFetcher,
    TableMutationHandler,
} from "./types";
import { compareUserSpecificRowsForApplication, liftUserSpecificData } from "./row-data";
import { getFeatureSetting } from "@glide/common-core";

function getBaseRowIndexForDocumentData(data: DocumentData): BaseRowIndex | undefined {
    const index = data[rowIndexColumnName];
    if (!isBaseRowIndex(index)) return undefined;
    return index;
}

function getRowVersionForDocumentData(data: DocumentData): number | undefined {
    const index = data[rowVersionFieldName];
    if (typeof index !== "number") return undefined;
    return index;
}

function getForRowDocumentDatas<T>(
    { publicData, privateData }: RowDocumentDatas,
    get: (data: DocumentData) => T
): T | undefined {
    if (publicData !== undefined) {
        const v = get(publicData);
        if (v !== undefined) return v;
    }
    if (privateData !== undefined) {
        return get(privateData);
    }
    return undefined;
}

function getBaseRowIndex(datas: RowDocumentDatas): BaseRowIndex | undefined {
    return getForRowDocumentDatas(datas, getBaseRowIndexForDocumentData);
}

function getRowIndexForDocumentData(table: TableGlideType, data: DocumentData): RowIndex | undefined {
    const baseRowIndex = getBaseRowIndexForDocumentData(data);

    const { rowIDColumn } = table;
    if (rowIDColumn !== undefined) {
        const rowID = data[rowIDColumn];
        if (typeof rowID === "string") {
            return {
                keyColumnName: rowIDColumn,
                keyColumnValue: rowID,
                rowIndexHint: baseRowIndex,
            };
        }
    }

    return baseRowIndex;
}

interface DataRowStores {
    readonly playerNCM: ComputationModelDataRowStore | undefined;
    readonly builderNCM: ComputationModelDataRowStore | undefined;
}

function getAllDataRowStores(drs: DataRowStores): readonly [rowStore: DataRowStore, isBuilder: boolean][] {
    const stores: [DataRowStore, boolean][] = [];
    if (drs.playerNCM !== undefined) {
        stores.push([drs.playerNCM, false]);
    }
    if (drs.builderNCM !== undefined) {
        stores.push([drs.builderNCM, true]);
    }
    return stores;
}

type PlayerAndBuilderRows = Omit<AddRowToTableResult, "didAdd" | "jobID" | "confirmedAtVersion">;

// exported only for testing
export interface TableDataHelper {
    getTable(): TableGlideType | undefined;
    getFieldNameCache(): FieldNameCache;
    getRowsLeftInQuota(): number;
}

// * Whenever we see a new user-agnostic row, we try to assign unassigned user-specific
//   documents. We combine whichever documents match, and then process them via the
//   durable storage controller, and store the result.

// exported only for testing
export class TableDataHandler {
    private _gotInitialResults = false;
    // This is the snapshot version, if we have it
    private _defaultRowVersion: number | undefined;
    // Indexed by row ID
    private _tableData = new Map<string, RowDocumentDatas>();

    // User-specific documents that we haven't assigned to rows yet we keep
    // indexed by keyColumnName, keyColumnValue.
    private readonly _unassignedUserData = new Map<string, Map<unknown, UserSpecificRow[]>>();
    // For user-specific documents that we have already assigned to rows, just keep one
    // combined object per row, indexed by row index, with its last version.
    private readonly _assignedUserData = new Map<BaseRowIndex, [DocumentData, number]>();

    private _deletedRowIndexes: Set<BaseRowIndex> = new Set();
    // See updateWithDiffResults: 'false' means we have not established any version
    // for the tombstones yet, and we also haven't received any tombstones either.
    // So we're completely in the dark as to what is and isn't deleted.
    private _deletedRowsVersion: number | false = false;
    // We use this to keep track of the rows we have deleted locally.  It maps
    // from row index to the confirmed version of the deletion, or `undefined`
    // if it's not confirmed yet, in which case we always consider it deleted.
    private readonly _locallyDeletedRowIndexes: Map<BaseRowIndex, number | undefined> = new Map();

    private _numRowsUsed: number = 0;
    private _didSetUserTableData: boolean = false;

    private _lastUserSpecificRows: readonly UserSpecificRow[] | undefined;
    private _lastUserSpecificAppUserID: string | undefined;

    // See updateWithDiffResults: these are needed to reconsider which rows
    // were truly deleted when we start off with an indeterminate deletedRowsVersion.
    // "Indeterminate" meaning either 'false' or 'Number.MAX_SAFE_INTEGER'.
    // In the first case, we haven't loaded the deleted row index set yet.
    // In the second case, we have, but it doesn't have a version yet.
    private readonly publicIndeterminateReaddedResults: DiffResult[] = [];
    private readonly privateIndeterminateReaddedResults: DiffResult[] = [];

    private readonly ignoredRowIDs = new Set<string>();
    private rowsWithIgnoredIDs: { rowID: string; version: number; diffResult: DiffResult; isPrivate: boolean }[] = [];

    constructor(
        // We only use the database here to convert Firestore objects.
        private readonly _db: Database | undefined,
        private readonly _isBuilder: boolean,
        private readonly _helper: TableDataHelper,
        private readonly _durableStorageController: DurableStorageController,
        private readonly _dataRowStores: DataRowStores
    ) {}

    public getRowsUsed(): number {
        return this._numRowsUsed;
    }

    private getUserSpecificDataForRow(
        rowIndex: BaseRowIndex,
        rowData: DocumentData
    ): { data: DocumentData; version: number } | undefined {
        const fromRowIndex = this._assignedUserData.get(rowIndex);
        if (fromRowIndex !== undefined) {
            return { data: fromRowIndex[0], version: fromRowIndex[1] };
        }

        const table = this._helper.getTable();
        if (table === undefined) return undefined;

        const results: UserSpecificRow[] = [];
        for (const key of Object.keys(rowData)) {
            const valueIndex = this._unassignedUserData.get(key);
            if (valueIndex === undefined) continue;
            const value = rowData[key];
            const rowArray = valueIndex.get(value);
            if (rowArray === undefined) continue;
            results.push(...rowArray);
            valueIndex.delete(value);
        }

        if (results.length === 0) return undefined;

        // `liftUserSpecificData` will take the first value for each column
        // that it finds, so we need to sort by highest row version first.
        // https://github.com/quicktype/glide/issues/10713
        results.sort((l, r) => -compareUserSpecificRowsForApplication(l, r));
        const version = results[0].$rowVersion ?? Number.MIN_SAFE_INTEGER;

        const data = liftUserSpecificData(table, results);

        this._assignedUserData.set(rowIndex, [data, version]);

        return { data, version };
    }

    // When the user-specific documents update, we reassign the updated
    // documents to rows, run them through the durable storage controller, and
    // store the result.
    //
    // If `results` is `undefined, we'll use the last results.
    //
    // `appUserID === undefined` means we're resetting the app user, so
    // `results` must be empty.
    private setUserTableDataDirect = (
        results: readonly UserSpecificRow[] | undefined,
        reset: boolean,
        appUserID: string | undefined
    ): void => {
        if (results === undefined) {
            results = this._lastUserSpecificRows;
            if (results === undefined) return;
        }

        const table = this._helper.getTable();
        if (table === undefined) return;
        const tableName = getTableName(table);

        if (appUserID === undefined) {
            assert(results.length === 0);
        }

        const appUserChanged = appUserID !== this._lastUserSpecificAppUserID;
        this._lastUserSpecificAppUserID = appUserID;

        this._assignedUserData.clear();
        this._unassignedUserData.clear();

        const mapping = makeColumnMappingForJSONFromDocumentData(this._helper.getFieldNameCache(), table);

        const preferRowID = getFeatureSetting("preferRowIDInStorageController");

        for (const userDataRow of results) {
            const { keyColumnName, keyColumnValue, data } = userDataRow;
            const converted = convertFieldNamesToColumnNamesWithMapping(
                this._db?.convertFromDocument(data) ?? data,
                mapping
            );

            let valueIndex = this._unassignedUserData.get(keyColumnName);
            if (valueIndex === undefined) {
                valueIndex = new Map();
                this._unassignedUserData.set(keyColumnName, valueIndex);
            }

            let rowArray = valueIndex.get(keyColumnValue);
            if (rowArray === undefined) {
                rowArray = [];
                valueIndex.set(keyColumnValue, rowArray);
            }
            rowArray.push({ ...userDataRow, data: converted });
        }

        let resetUserData: DocumentData | undefined;
        if (reset) {
            this._durableStorageController.resetUserSpecificData(tableName);
            resetUserData = objectWithUndefinedProperties(table.columns.map(c => c.name));
        }

        for (const [id, rowDocumentDatas] of this._tableData.entries()) {
            const tableDocument = combineRowDocumentDatas(rowDocumentDatas);
            let rowIndex: RowIndex | undefined;
            let baseRowIndex: BaseRowIndex | undefined;
            if (preferRowID) {
                rowIndex = getRowIndexForDocumentData(table, tableDocument);
                if (isBaseRowIndex(rowIndex)) {
                    baseRowIndex = rowIndex;
                } else {
                    baseRowIndex = rowIndex?.rowIndexHint;
                }
            } else {
                rowIndex = baseRowIndex = getBaseRowIndexForDocumentData(tableDocument);
            }
            if (rowIndex === undefined || baseRowIndex === undefined) {
                logError("No row index", table, id);
                continue;
            }

            const userData = this.getUserSpecificDataForRow(baseRowIndex, tableDocument);
            let userUpdates: DocumentData | undefined;

            if (userData === undefined && (appUserChanged || table.rowIDColumn === undefined)) {
                // If the `userData` is undefined that can have a couple of
                // different reasons:
                //
                // 1. The row doesn't, and never had, any user-specific data
                // 2. The user-specific document(s) was deleted for some
                //    reason.
                // 3. User-specific data was added locally, but the
                //    document(s) have not been created yet.
                // 4. The app user changed.
                //
                // Here we are dealing with case 4 by deleting all
                // user-specific columns in this row, but we have to make sure
                // that we don't also hit case 3.  Note that the condition
                // `table.rowIDColumn === undefined` makes sure that for
                // tables without row IDs we're erring on the side of deleting
                // user-specific data.
                //
                // Here's how all the cases are affected by this code path,
                // for tables with row IDs:
                //
                // 1. This code path doesn't hit.  It wouldn't hurt if it hit,
                //    or it would even be better if it hit, because it would
                //    make double sure that those rows don't have
                //    user-specific columns by deleting them, but they
                //    shouldn't be there in the first place anyway.
                // 2. This code path doesn't hit either, but I don't know of a
                //    case where we delete user-specific documents without the
                //    row having been deleted.
                // 3. This code path doesn't hit here, and that's what we want
                //    because those rows should keep whichever user-specific
                //    data it got locally.
                // 4. This should be the only case (for tables with row IDs)
                //    where this code path hits and resets all user-specific
                //    data.
                if (resetUserData === undefined) {
                    userUpdates = this._durableStorageController.handleUserSpecificDataDeletion(tableName, rowIndex);
                    if (userUpdates === undefined) continue;
                } else {
                    userUpdates = resetUserData;
                }
            } else if (userData !== undefined) {
                userUpdates = this._durableStorageController.handleUserSpecificDocumentUpdate(
                    tableName,
                    rowIndex,
                    userData.version,
                    userData.data
                );
            } else {
                continue;
            }

            for (const [dataRowStore] of getAllDataRowStores(this._dataRowStores)) {
                dataRowStore.updateRowIfPresent(id, userUpdates);
            }
        }

        this._lastUserSpecificRows = results;
    };

    private setUserTableDataDebounced = debounce(this.setUserTableDataDirect, 10000, { trailing: true });

    public setUserTableData(results: readonly UserSpecificRow[], appUserID: string): void {
        if (!this._didSetUserTableData || this._isBuilder) {
            this.setUserTableDataDirect(results, false, appUserID);
            this._didSetUserTableData = true;
        } else {
            this.setUserTableDataDebounced(results, false, appUserID);
        }
    }

    private setTableData(id: string, data: DocumentData, isPrivate: boolean): void {
        const entry = this._tableData.get(id);
        const altered = setRowDocumentData(entry, data, isPrivate);
        this._tableData.set(id, altered);
    }

    // returns whether all data with this `id` was deleted
    private deleteTableData(id: string, isPrivate: boolean): boolean {
        const entry = this._tableData.get(id);
        if (entry === undefined) return true;
        const altered = deleteRowDocumentData(entry, isPrivate);
        if (altered === undefined) {
            this._tableData.delete(id);
            return true;
        } else {
            this._tableData.set(id, altered);
            return false;
        }
    }

    // FIXME: Use `haveOwnership` to not copy the row
    public updateWithDiffResults(
        results: SnapshotDiffResults,
        isPrivate: boolean,
        // `undefined` means `results` is not not from a snapshot but from
        // Firestore
        formatVersion: SnapshotFormatVersion | undefined,
        haveOwnership: boolean
    ): void {
        const table = this._helper.getTable();
        if (table === undefined) {
            logError("No table");
            return;
        }
        const tableName = getTableName(table);

        // See below: we may have to reconsider whether a row is actually deleted
        // when we start with an indeterminate deleted row version, meaning we haven't
        // loaded any of the deleted rows, or we have, and they don't yet have a version.
        const indeterminateReaddedResults = isPrivate
            ? this.privateIndeterminateReaddedResults
            : this.publicIndeterminateReaddedResults;
        const previouslyIndeterminateResults = indeterminateReaddedResults.splice(
            0,
            indeterminateReaddedResults.length
        );
        const processedResults: SnapshotDiffResults =
            previouslyIndeterminateResults.length === 0 ? results : [...previouslyIndeterminateResults, ...results];

        let rowsLeft = this._helper.getRowsLeftInQuota();
        rowsLeft += processedResults.filter(({ kind }) => kind === "removed").length;
        // we always allow reading at least 10 rows in each table
        rowsLeft = Math.max(rowsLeft, 10 - this.getRowsUsed());

        const fromSnapshot = formatVersion !== undefined;
        const snapshotFormatIsNew = fromSnapshot && formatVersion > 1;
        const mapping =
            // New snapshot versions already use column names and don't need
            // to be converted.
            snapshotFormatIsNew
                ? undefined
                : makeColumnMappingForJSONFromDocumentData(this._helper.getFieldNameCache(), table);

        let reprocessUserSpecificData = false;
        const rowIDColumnName = getRowIDColumnNameOrProxy(table);

        const preferRowID = getFeatureSetting("preferRowIDInStorageController");

        for (const { kind, id, data, convert: columnNamesToConvert } of processedResults) {
            switch (kind) {
                // We treat these the same because I suspect that under some conditions
                // (connection loss and/or clearance of local storage) we might get
                // "added" events for documents we already have.
                case "added":
                case "modified":
                    const convertedFromDocument = fromSnapshot ? data : this._db?.convertFromDocument(data) ?? data;
                    const converted =
                        mapping === undefined
                            ? convertedFromDocument
                            : convertFieldNamesToColumnNamesWithMapping(convertedFromDocument, mapping);

                    const rowVersion =
                        getRowVersionForDocumentData(converted) ?? this._defaultRowVersion ?? Number.MIN_SAFE_INTEGER;

                    // This exists to ensure that deleted rows by ID stay deleted, even
                    // when they briefly come into existence due to listener lag.
                    const rowID = converted[rowIDColumnName];
                    if (typeof rowID === "string" && this.ignoredRowIDs.has(rowID)) {
                        this.rowsWithIgnoredIDs.push({
                            rowID,
                            version: rowVersion,
                            diffResult: { kind, id, data },
                            isPrivate,
                        });
                        continue;
                    }

                    // Data without a row index of any kind (both numeric and key-value based)
                    // should never occur under correct conditions.
                    const baseRowIndex = getBaseRowIndexForDocumentData(converted);
                    if (baseRowIndex === undefined) continue;

                    let rowIndex: RowIndex;
                    if (preferRowID && typeof rowID === "string") {
                        rowIndex = {
                            keyColumnName: rowIDColumnName,
                            keyColumnValue: rowID,
                            rowIndexHint: baseRowIndex,
                        };
                    } else {
                        rowIndex = baseRowIndex;
                    }

                    this.setTableData(id, converted, isPrivate);

                    // Deletion tombstones are generally versioned. For any row data we receive, if
                    // the row index for the row data is in the deletion tombstone set,
                    // 1. The deletion tombstone set wins if its version is greater than or equal to than the row.
                    // 2. Otherwise, the row wins.
                    // This all assumes that we have a definitive version for the deletion tombstones.
                    if (this.isRowIndexDeletedAtVersion(baseRowIndex, rowVersion)) {
                        // We don't always have a definitive version for the deletion tombstones, though.
                        // It is possible that we have rows but not a deleted row set yet.
                        // Assuming that we do have a deleted row set, while all changes from the sheet mirror
                        // now result in a versioned deletion tombstone set, there are still valid mirrored sheets
                        // that don't have this version.
                        // In this case, we internally treat the set as having the maximum possible version.
                        // Whenever this happens, we _think_ that the rows are going to stay deleted.
                        //
                        // We'll thus need to revisit this change entry in two circumstances:
                        // 1. We received row documents before we established which ones were deleted.
                        //    We consider every row to be deleted until we receive the actual set of deleted rows,
                        //    with the exception of snapshot rows, which are not deleted until proven otherwise.
                        // 2. An update occurs that revives a deleted row, but the update notification occurs
                        //    _before_ the deleted row notification that establishes a definitive version.
                        // Unless we save these rows for future processing, we'll never get them back.
                        //
                        // Note that we don't do this for snapshots: we just take whatever the snapshot says
                        // as a given until the table listeners are established. This is a performance
                        // optimization that is close enough in terms of consistency.
                        if (this.isDeletedRowVersionIndeterminate()) {
                            // Note that we push `data`, not `converted`, because indeterminate data goes
                            // through conversion (again) above.
                            indeterminateReaddedResults.push({ kind, id, data });
                        }
                        continue;
                    }

                    const dataRowStores = getAllDataRowStores(this._dataRowStores);

                    // If we're adding this row to more than a single data row
                    // store then it can't take ownership of it.  This happens
                    // in the builder, where we have one store for the
                    // builder, and one for the player.
                    const takeOwnership = haveOwnership && dataRowStores.length === 1;

                    const updates = this._durableStorageController.handleUserAgnosticDocumentUpdate(
                        tableName,
                        rowIndex,
                        rowVersion,
                        converted,
                        // If the data row store takes ownership of the row
                        // then the durable storage controller can't rely on
                        // it being immutable.
                        // https://github.com/quicktype/glide/issues/18093
                        takeOwnership
                    );
                    const userSpecificData = this.getUserSpecificDataForRow(baseRowIndex, converted);
                    let userDataUpdates: DocumentData | undefined;
                    if (userSpecificData !== undefined) {
                        userDataUpdates = this._durableStorageController.handleUserSpecificDocumentUpdate(
                            tableName,
                            rowIndex,
                            userSpecificData.version,
                            userSpecificData.data
                        );
                    }

                    let didConsumeRow = false;
                    for (const [dataRowStore] of dataRowStores) {
                        // We're assuming here that there's only one document
                        // ID per row index, which is wrong if we can't load
                        // the snapshot.  See
                        // https://github.com/quicktype/glide/pull/9465
                        const result = dataRowStore.updateRow(
                            id,
                            kind === "added",
                            rowsLeft > 0,
                            updates,
                            userDataUpdates,
                            converted,
                            userSpecificData?.data,
                            snapshotFormatIsNew ? columnNamesToConvert : false,
                            takeOwnership
                        );
                        if (result === undefined) continue;

                        if (result.reprocessUserSpecificData) {
                            reprocessUserSpecificData = true;
                        }
                        if (result.didAddRow) {
                            didConsumeRow = true;
                        }
                    }
                    if (didConsumeRow) {
                        rowsLeft -= 1;
                    }
                    break;

                case "removed":
                    // When rows move between non-owned and
                    // owned, we get these diffs. Does this have to
                    // go through the durable storage controller?
                    if (this.deleteTableData(id, isPrivate)) {
                        for (const [dataRowStore] of getAllDataRowStores(this._dataRowStores)) {
                            dataRowStore.deleteDataRow(id);
                        }
                    }
                    break;

                default:
                    return assertNever(kind);
            }
        }

        if (reprocessUserSpecificData) {
            // The app user hasn't changed here.
            this.setUserTableDataDirect(undefined, true, this._lastUserSpecificAppUserID);
        }
    }

    private isDeletedRowVersionIndeterminate(): boolean {
        return this._deletedRowsVersion === false || this._deletedRowsVersion === Number.MAX_SAFE_INTEGER;
    }

    private isRowIndexDeletedAtVersion(rowIndex: BaseRowIndex, version: number): boolean {
        if (this._locallyDeletedRowIndexes.has(rowIndex)) {
            const deletedVersion = this._locallyDeletedRowIndexes.get(rowIndex);
            if (deletedVersion === undefined) {
                // The backend has not confirmed the deletion yet, so we have
                // to consider the row deleted.
                return true;
            }

            // We have a confirmed version for the deletion, so it's possible
            // that after that version the row comes back again.  If we
            // haven't reached the confirmed version yet, we consider the row
            // deleted (since it happened locally).  Anything that happens
            // after that version is handled by the fallthrough case below.
            if (deletedVersion >= version) {
                return true;
            }
        }

        // We receive a defined _deletedRowsVersion whenever we load a snapshot,
        // and only whenever we load a snapshot. We want the snapshot data to be
        // considered non-deleted for as long as we're waiting for the initial
        // deleted rows, but only the snapshot data should be considered non-deleted
        // until we have an initial set of deleted rows.
        if (this._deletedRowsVersion === false) {
            return (this._defaultRowVersion ?? Number.MIN_SAFE_INTEGER) < version;
        }
        if (version > this._deletedRowsVersion) return false;
        return this._deletedRowIndexes.has(rowIndex);
    }

    public setDeletedRowIndexes(rowIndexes: readonly BaseRowIndex[], version: number): void {
        this._deletedRowIndexes = new Set(rowIndexes);
        this._deletedRowsVersion = version;
    }

    public updateWithSnapshotResults(
        formatVersion: SnapshotFormatVersion,
        results: readonly NativeTableSnapshotRow[],
        version: number | undefined,
        force: boolean,
        haveOwnership: boolean
    ): void {
        if (this._gotInitialResults && !force) return;
        this._gotInitialResults = true;
        if (version !== undefined) {
            this._defaultRowVersion = version;
        }

        if (this._tableData.size > 0 && !force) return;

        if (force) {
            this._durableStorageController.purgeConfirmedEntries();
        }
        this.updateWithDiffResults(
            results.map(r => ({ ...r, kind: "added" })),
            false,
            formatVersion,
            haveOwnership
        );
    }

    private deleteOutdatedRow(id: string): void {
        this._tableData.delete(id);
        for (const [dataRowStore] of getAllDataRowStores(this._dataRowStores)) {
            dataRowStore.deleteDataRow(id);
        }
    }

    private getRowVersion(datas: RowDocumentDatas): number | undefined {
        return getForRowDocumentDatas(datas, getRowVersionForDocumentData) ?? this._defaultRowVersion;
    }

    private deleteOldRows(sortedEntries: TableDataEntries): TableDataEntries {
        const cleanEntries: [string, RowDocumentDatas][] = [];

        // All `entries` have the same row index.  Find the one with the
        // highest version, push it to `cleanEntries`, and get rid of all
        // the other rows for good.
        const processEntries = (isDeleted: boolean, entries: TableDataEntries): void => {
            assert(entries.length > 0);

            let maxVersion: number | undefined;
            // We keep the entry with this index.  This will typically
            // be the entry with the highest version, but we might end
            // up not preserving one at all, because the row should be
            // deleted, or preserving the first one because none of them
            // had versions.
            let preserveIndex: number | undefined;
            for (let i = 0; i < entries.length; i++) {
                const version = this.getRowVersion(entries[i][1]);
                if (version === undefined) continue;

                if (maxVersion === undefined || version > maxVersion) {
                    maxVersion = version;
                    preserveIndex = i;
                }
            }

            if (preserveIndex === undefined) {
                // This should only happen if no entries have row versions,
                // which shouldn't happen, but who knows?
                preserveIndex = 0;
            }

            if (!isDeleted || (maxVersion !== undefined && maxVersion > this._deletedRowsVersion)) {
                cleanEntries.push(entries[preserveIndex]);
            } else {
                // This row has been deleted in a version at least as recent as
                // the row version, so we don't push it, and in the loop below
                // all documents get deleted from memory.
                preserveIndex = undefined;
            }

            for (let i = 0; i < entries.length; i++) {
                if (i === preserveIndex) continue;
                this.deleteOutdatedRow(entries[i][0]);
            }
        };

        const numEntries = sortedEntries.length;

        // Find groups of entries with the same row index.
        // We're manually incrementing `i` in this loop.
        for (let i = 0; i < numEntries; ) {
            const entry = sortedEntries[i];
            const rowIndex = getBaseRowIndex(entry[1]);
            const rowVersion = this.getRowVersion(entry[1]);
            const deletedByIndexAndVersion =
                rowIndex !== undefined && rowVersion !== undefined
                    ? this.isRowIndexDeletedAtVersion(rowIndex, rowVersion)
                    : undefined;

            if (rowIndex === undefined) {
                cleanEntries.push(entry);
                i++;
                continue;
            }

            let j: number;
            for (j = i + 1; j < numEntries; j++) {
                if (getBaseRowIndex(sortedEntries[j][1]) !== rowIndex) {
                    break;
                }
            }

            const isDeleted = deletedByIndexAndVersion ?? this._deletedRowIndexes.has(rowIndex);
            // This is a shortcut to avoid `processEntries` in almost all cases.
            if (!isDeleted && j === i + 1) {
                cleanEntries.push(entry);
                i++;
                continue;
            }

            // These all have the same row index
            processEntries(isDeleted, sortedEntries.slice(i, j));
            i = j;
        }

        return cleanEntries;
    }

    public updateAppWithTableData(setAppDataIfLoading: boolean): void {
        const numRowsUsed: number[] = [];
        // `deleteOldRows` has the side effect of potentially deleting rows
        // from this object and the data row stores.
        // FIXME: Change this so we don't have to sort anymore
        const sortedEntries = this.deleteOldRows(
            sortByRowIndex(this._tableData.entries(), ([, x]) => getBaseRowIndex(x))
        );

        for (const [dataRowStore] of getAllDataRowStores(this._dataRowStores)) {
            const numRows = dataRowStore.setAppDataForSortedEntries(sortedEntries, setAppDataIfLoading);
            if (numRows === undefined) continue;

            numRowsUsed.push(numRows);
        }

        if (numRowsUsed.length > 0) {
            this._numRowsUsed = Math.max(...numRowsUsed);
        }
    }

    // We're returning the row for the app, which is from the first store.
    public addRow(values: Record<string, WritableValue>, rowID: string | undefined): PlayerAndBuilderRows | undefined {
        const rows: Writable<PlayerAndBuilderRows> = { builderRow: undefined, playerRow: undefined };
        for (const [dataRowStore, isBuilder] of getAllDataRowStores(this._dataRowStores)) {
            const newRow = dataRowStore.addRowWithValues(values, rowID);
            if (isBuilder) {
                rows.builderRow = newRow;
            } else {
                rows.playerRow = newRow;
            }
        }
        return rows;
    }

    public setColumnsInRow(rowIndex: RowIndex, values: Record<string, WritableValue>): void {
        for (const [dataRowStore] of getAllDataRowStores(this._dataRowStores)) {
            dataRowStore.setColumnsInRow(rowIndex, values);
        }
    }

    public updateRowWithPartialData(rowIndex: RowIndex, doc: DocumentData): void {
        for (const [dataRowStore] of getAllDataRowStores(this._dataRowStores)) {
            dataRowStore.updateRowWithPartialData(rowIndex, doc);
        }
    }

    public deleteRowAtIndex(rowIndex: RowIndex): void {
        const baseRowIndex = isBaseRowIndex(rowIndex) ? rowIndex : rowIndex.rowIndexHint;
        if (baseRowIndex !== undefined && !this._locallyDeletedRowIndexes.has(baseRowIndex)) {
            this._locallyDeletedRowIndexes.set(baseRowIndex, undefined);
        }

        for (const [dataRowStore] of getAllDataRowStores(this._dataRowStores)) {
            dataRowStore.deleteRowAtIndex(rowIndex);
        }
    }

    public confirmDeletedRow(rowIndex: BaseRowIndex, serial: number): void {
        if (this._locallyDeletedRowIndexes.has(rowIndex)) {
            this._locallyDeletedRowIndexes.set(rowIndex, serial);
        }
    }

    public clearUserData(): void {
        this.setUserTableDataDirect([], true, undefined);
    }

    public reset(): void {
        this._tableData.clear();
        for (const [dataRowStore] of getAllDataRowStores(this._dataRowStores)) {
            dataRowStore.clear();
        }
        // FIXME: Why does this not clear the user rows and the added rows?
    }

    public getTableKeeper(forBuilder: boolean): Handler | undefined {
        if (forBuilder) {
            return this._dataRowStores.builderNCM;
        } else {
            return panic("FIXME: implement");
        }
    }

    public addIgnoredRowID(rowID: string): void {
        this.ignoredRowIDs.add(rowID);
    }

    public removeIgnoredRowID(rowID: string, version: number): void {
        this.ignoredRowIDs.delete(rowID);
        const retriggers = this.rowsWithIgnoredIDs.filter(r => r.rowID === rowID && r.version > version);
        this.rowsWithIgnoredIDs = this.rowsWithIgnoredIDs.filter(r => r.rowID !== rowID);
        this.updateWithDiffResults(
            retriggers.filter(r => !r.isPrivate).map(r => r.diffResult),
            false,
            undefined,
            false
        );
        this.updateWithDiffResults(
            retriggers.filter(r => r.isPrivate).map(r => r.diffResult),
            true,
            undefined,
            false
        );
    }
}

function didEmailOwnersColumnsChange(
    oldTables: readonly TableGlideType[],
    newTables: readonly TableGlideType[]
): boolean {
    for (const table of oldTables) {
        const newTable = findTable(newTables, getTableName(table));
        if (newTable === undefined) continue;
        if (!areSetsEqual(getEmailOwnersColumnNames(table), getEmailOwnersColumnNames(newTable))) {
            return true;
        }
    }
    return false;
}

type ChangedComputedColumns = ReadonlyMap<TableName, readonly string[]>;

// If there is a new computed column in an existing table, or an existing
// computed column that is now different, or a computed column that was deleted,
// we count that as changed.  We already handle computed columns in new tables
// correctly, since we construct new row objects for those tables.
function getChangedComputedColumns(
    oldTables: readonly TableGlideType[],
    newTables: readonly TableGlideType[]
): ChangedComputedColumns | undefined {
    const changedColumnsForTables: Map<TableName, readonly string[]> = new Map();

    for (const table of newTables) {
        const tableName = getTableName(table);
        const oldTable = findTable(oldTables, tableName);
        if (oldTable === undefined) continue;

        const changedColumns: string[] = [];

        for (const c of table.columns) {
            if (!isComputedColumn(c)) continue;

            const oldColumn = getTableColumn(oldTable, c.name);
            if (oldColumn === undefined || !deepEqual(c.formula, oldColumn.formula, { strict: true })) {
                changedColumns.push(c.name);
            }
        }

        for (const c of oldTable.columns) {
            if (!isComputedColumn(c)) continue;

            if (getTableColumn(table, c.name) === undefined) {
                changedColumns.push(c.name);
            }
        }

        if (changedColumns.length > 0) {
            changedColumnsForTables.set(tableName, changedColumns);
        }
    }

    if (changedColumnsForTables.size === 0) return undefined;
    return changedColumnsForTables;
}

// This exists entirely for testing purposes.
type FirestoreDataStoreCallbacks = {
    onUpdateWithDiffResults?: () => void;
    onUpdateWithUserSpecificData?: () => void;
};

class DocumentsFirestoreHandlerImpl implements DocumentsFirestoreHandler, TableFetcher, TableMutationHandler {
    private readonly _listeners = new Map<string, FirestoreTableListener>();
    private readonly _tableDataHandlers = new Map<string, TableDataHandler>();
    private readonly _appUserDataWatchable: Watchable<AppUserData>;

    private _appUserRolesUnlisten: (() => void) | undefined;

    constructor(
        private readonly _makeTableListener: MakeFirestoreTableListener,
        public readonly database: Database | undefined,
        private readonly _tables: ReadonlyMap<string, TableGlideType>,
        private readonly _playerTableKeeperStore: TableKeeperStore<ComputationModelDataRowStore>,
        private readonly _builderTableKeeperStore: TableKeeperStore<ComputationModelDataRowStore> | undefined,
        private readonly _dataRowStores: Map<string, DataRowStores>,
        private readonly _fieldNameCache: FieldNameCache,
        public readonly appID: string,
        appUserID: string | undefined,
        appUserEmail: string | undefined,
        private readonly _isBuilder: boolean,
        private readonly _getRowQuota: () => number,
        private readonly _durableStorageController: DurableStorageController,
        private readonly _callbacks: FirestoreDataStoreCallbacks,
        private readonly _runRowOwnerChangeCallbacks: () => void
    ) {
        this._appUserDataWatchable = new Watchable<AppUserData>({
            appUserID,
            email: definedMap(appUserEmail, normalizeEmailAddress),
            roles: new Set(),
        });

        this.startLoading();
    }

    // FIXME: We don't seem to wait with querying data, or filtering in the
    // builder, for the roles to arrive, which seems very racy?  If we get the
    // roles before we query/filter, everything is fine, but if it's the other
    // way around, we end up with incorrectly filtered rows, or too narrow a
    // query.
    //
    // https://github.com/quicktype/glide/issues/7541
    private listenToAppUserRoles(db: Database): void {
        if (this._appUserRolesUnlisten !== undefined) {
            this._appUserRolesUnlisten();
            this._appUserRolesUnlisten = undefined;
        }

        const { appUserID } = this._appUserDataWatchable.current;
        if (appUserID === undefined) return;

        const path = makeAppUsersForAppPath(this.appID);
        this._appUserRolesUnlisten = db.listenToDocument(
            path,
            appUserID,
            data => {
                if (this._appUserDataWatchable.current.appUserID !== appUserID) return;

                let roles: readonly string[];

                if (data !== undefined) {
                    roles = makeAppUserForAppFromDocument(data).roles;
                } else {
                    roles = [];
                }

                logInfo("app user roles", roles);

                if (areEqual(new Set(roles), new Set(this._appUserDataWatchable.current.roles))) return;
                this._appUserDataWatchable.current = {
                    ...this._appUserDataWatchable.current,
                    roles: new Set(roles),
                };
                this._runRowOwnerChangeCallbacks();
                this.unlistenUserSpecifics();
                this.listenUserSpecifics();
                if (this._isBuilder) {
                    this.updateHandlersWithRowOwners();
                }
            },
            error => {
                logError("Error listening to app user roles", error);
            }
        );
    }

    private startLoading(): void {
        if (this.database !== undefined) {
            this.listenToAppUserRoles(this.database);
        }
    }

    private getListener(tableName: TableName): FirestoreTableListener | undefined {
        const documentID = documentIDForTableName(tableName);
        let listener = this._listeners.get(documentID);
        if (listener === undefined) {
            const getTable = () => this.getTable(tableName);

            listener = this._makeTableListener(tableName, getTable, this, this._durableStorageController);
            if (listener === undefined) return undefined;

            this._listeners.set(documentID, listener);
        }
        return listener;
    }

    public onPublishedAppDataUpdate(
        ownerColumnsChanged: boolean,
        changedComputedColumns: ChangedComputedColumns | undefined
    ): void {
        const updateDataHandlers = (withReset: boolean) => {
            for (const dataHandler of this._tableDataHandlers.values()) {
                if (withReset) {
                    dataHandler.reset();
                }
                dataHandler.updateAppWithTableData(false);
            }
        };

        if (this._isBuilder && !ownerColumnsChanged && changedComputedColumns === undefined) {
            updateDataHandlers(false);
            return;
        }

        if (!ownerColumnsChanged) return;

        for (const listener of this._listeners.values()) {
            listener.unlisten();
        }

        updateDataHandlers(true);

        for (const listener of this._listeners.values()) {
            listener.listenToTable(true);
        }
    }

    private unlistenUserSpecifics(): void {
        for (const listener of this._listeners.values()) {
            listener.unlistenUserSpecifics();
        }
        for (const handler of this._tableDataHandlers.values()) {
            handler.clearUserData();
        }
    }

    private listenUserSpecifics(): void {
        for (const listener of this._listeners.values()) {
            listener.listenToTable(false);
        }
    }

    private updateHandlersWithRowOwners(): void {
        assert(this._isBuilder);

        for (const [documentID, dataHandler] of this._tableDataHandlers.entries()) {
            const table = defined(this._tables.get(documentID));
            if (tableHasRowOwners(table)) {
                dataHandler.updateAppWithTableData(false);
            }
        }
    }

    public setAppUser(appUserID: string, appUserEmail: string | undefined): void {
        const { appUserID: currentAppUserID, email: currentAppUserEmail } = this._appUserDataWatchable.current;

        appUserEmail = definedMap(appUserEmail, normalizeEmailAddress);

        assert(currentAppUserID !== appUserID || currentAppUserEmail !== appUserEmail);

        if (currentAppUserID !== undefined) {
            // We're already checking this in the caller, so technically this
            // is superfluous.
            if (currentAppUserID === appUserID && !this._isBuilder) {
                return;
            }

            if (currentAppUserID !== appUserID) {
                this.unlistenUserSpecifics();
            }
        }

        if (currentAppUserID === appUserID && this._isBuilder) {
            // Only the email has changed.  In the builder we have to
            // re-filter to account for row owners and admins.
            assert(currentAppUserEmail !== appUserEmail);

            this._appUserDataWatchable.current = {
                ...this._appUserDataWatchable.current,
                email: appUserEmail,
            };

            this.updateHandlersWithRowOwners();

            return;
        }

        this._appUserDataWatchable.current = {
            appUserID,
            email: appUserEmail,
            roles: new Set(),
        };

        this._runRowOwnerChangeCallbacks();

        this.listenUserSpecifics();

        if (this.database !== undefined) {
            this.listenToAppUserRoles(this.database);
        }
    }

    private getRowsUsed(): number {
        let total = 0;
        for (const n of Array.from(this._tableDataHandlers.values()).map(h => h.getRowsUsed())) {
            total += n;
        }
        return total;
    }

    public get appUserDataObservable(): ChangeObservable<AppUserData> {
        return this._appUserDataWatchable;
    }

    private getTableDataHandler(tableName: TableName): TableDataHandler {
        const documentID = documentIDForTableName(tableName);
        let handler = this._tableDataHandlers.get(documentID);
        if (handler === undefined) {
            const helper: TableDataHelper = {
                getTable: () => this.getTable(tableName),
                getFieldNameCache: () => this._fieldNameCache,
                getRowsLeftInQuota: () => this._getRowQuota() - this.getRowsUsed(),
            };

            let dataRowStores = this._dataRowStores.get(documentID);
            if (dataRowStores === undefined) {
                // We need to keep these when we restart from snapshots.
                const stores: Writable<DataRowStores> = {
                    playerNCM: undefined,
                    builderNCM: undefined,
                };
                stores.playerNCM = this._playerTableKeeperStore.getTableKeeperForTable(tableName);
                stores.builderNCM = this._builderTableKeeperStore?.getTableKeeperForTable(tableName);
                dataRowStores = stores;
                this._dataRowStores.set(documentID, dataRowStores);
            }

            handler = new TableDataHandler(
                this.database,
                this._isBuilder,
                helper,
                this._durableStorageController,
                dataRowStores
            );
            this._tableDataHandlers.set(documentID, handler);
        }
        return handler;
    }

    private getTableDataHandlerIfTableExists(tableName: TableName): TableDataHandler | undefined {
        const table = this.getTable(tableName);
        if (table === undefined) return undefined;

        return this.getTableDataHandler(tableName);
    }

    public updateWithSnapshotResults(
        table: TableGlideType,
        formatVersion: SnapshotFormatVersion,
        results: readonly NativeTableSnapshotRow[],
        version: number | undefined,
        force: boolean,
        haveOwnership: boolean
    ): void {
        const tableName = getTableName(table);

        logInfo("updating initial", tableName, results.length);
        const handler = this.getTableDataHandler(tableName);
        handler.updateWithSnapshotResults(formatVersion, results, version, force, haveOwnership);
        handler.updateAppWithTableData(true);
    }

    public updateWithUserSpecificColumns(
        table: TableGlideType,
        results: readonly DocumentDataWithID[],
        appUserID: string
    ): void {
        const tableName = getTableName(table);
        const handler = this.getTableDataHandler(tableName);

        const decoded = mapFilterUndefined(results, r => {
            const d = userSpecificRowCodec.decode(r.data);
            if (isLeft(d)) return undefined;
            // The backend shouldn't be making empty documents, but we've had
            // a bug where it does, so we're ignoring those.
            if (Object.keys(d.right.data).length === 0) return undefined;
            return d.right;
        });
        logInfo("updating user-specific table", tableName, results.length, decoded.length);
        handler.setUserTableData(decoded, appUserID);
        // Once we have refs depending on favorites, we might have to update them
        // here.
        // this.updateWithDiffResults(tableName, []);
        const { onUpdateWithUserSpecificData } = this._callbacks;
        if (onUpdateWithUserSpecificData !== undefined) {
            onUpdateWithUserSpecificData();
        }
    }

    public fetchTableRows(tableName: TableName): void {
        this.getListener(tableName)?.listenToTable(false);
    }

    private getTable(tableName: TableName): TableGlideType | undefined {
        const documentID = documentIDForTableName(tableName);
        return this._tables.get(documentID);
    }

    public updateWithDiffResults(tableName: TableName, results: DiffResults, isPrivate: boolean): void {
        const handler = this.getTableDataHandler(tableName);

        handler.updateWithDiffResults(results, isPrivate, undefined, false);
        logInfo("updating data for table", tableName.name, isPrivate, results.length, results);
        handler.updateAppWithTableData(true);

        const { onUpdateWithDiffResults } = this._callbacks;
        if (onUpdateWithDiffResults !== undefined) {
            onUpdateWithDiffResults();
        }
    }

    public setDeletedRowIndexes(tableName: TableName, rowIndexes: readonly BaseRowIndex[], version: number): void {
        logInfo("deleted rows", tableName.name, rowIndexes, version);

        const handler = this.getTableDataHandler(tableName);

        handler.setDeletedRowIndexes(rowIndexes, version);

        // We may have just established the first known set of deleted rows,
        // or changed the version of deleted rows from Number.MAX_SAFE_INTEGER
        // to a definitive version. In both cases, we need to re-trigger the
        // diff result handler, which may have saved indeterminate changes for
        // future consideration.
        handler.updateWithDiffResults([], false, undefined, false);
        handler.updateWithDiffResults([], true, undefined, false);

        handler.updateAppWithTableData(false);
    }

    public addRowToTable(
        tableName: TableName,
        values: Record<string, WritableValue>
    ): PlayerAndBuilderRows | undefined {
        const table = this.getTable(tableName);
        if (table === undefined) return undefined;

        let rowID: string | undefined;
        const { rowIDColumn } = table;
        if (rowIDColumn !== undefined) {
            const maybeRowID = values[rowIDColumn];
            if (typeof maybeRowID === "string") {
                rowID = maybeRowID;
            }
        }

        const handler = this.getTableDataHandler(tableName);
        return handler.addRow(values, rowID);
    }

    public setColumnsInRow(tableName: TableName, rowIndex: RowIndex, values: Record<string, WritableValue>): void {
        const handler = this.getTableDataHandlerIfTableExists(tableName);
        handler?.setColumnsInRow(rowIndex, values);
    }

    public deleteRowAtIndex(tableName: TableName, rowIndex: RowIndex): void {
        const handler = this.getTableDataHandlerIfTableExists(tableName);
        handler?.deleteRowAtIndex(rowIndex);
    }

    public confirmDeletedRow(tableName: TableName, rowIndex: BaseRowIndex, serial: number): void {
        const handler = this.getTableDataHandler(tableName);
        handler?.confirmDeletedRow(rowIndex, serial);
    }

    public updateRowWithPartialData(tableName: TableName, rowIndex: RowIndex, data: DocumentData): void {
        const handler = this.getTableDataHandlerIfTableExists(tableName);
        handler?.updateRowWithPartialData(rowIndex, data);
    }

    public retire(): void {
        for (const listener of this._listeners.values()) {
            listener.unlisten();
        }
        this.unlistenUserSpecifics();
        this._appUserRolesUnlisten?.();
    }

    public resetFromFirestore(): void {
        for (const listener of this._listeners.values()) {
            listener.resetFromFirestore();
        }
    }

    public addIgnoredRowID(tableName: TableName, rowID: string): void {
        const handler = this.getTableDataHandlerIfTableExists(tableName);
        handler?.addIgnoredRowID(rowID);
    }

    public removeIgnoredRowID(tableName: TableName, rowID: string, version: number): void {
        const handler = this.getTableDataHandlerIfTableExists(tableName);
        handler?.removeIgnoredRowID(rowID, version);
    }
}

interface ComputationModelData {
    computationModel: ComputationModel | undefined;
    // The player computation cleans up unused query handlers automatically
    // after each hydration, but in the builder one we have to do it via an
    // interval.
    cleanUpInterval: ReturnType<typeof setInterval> | undefined;
    readonly tableKeeperStore: TableKeeperStore<ComputationModelDataRowStore>;
    readonly observable: Watchable<ComputationModel | undefined>;
}

interface FirestoreDataStoreOptions {
    readonly isBuilder: boolean;
    readonly writeSource: WriteSourceType;
    // In Automations we don't make a builder computation model, and we don't
    // filter by email.
    readonly isAutomations: boolean;
    readonly deviceIDOverride?: string;
    readonly isPersistent: boolean;
    readonly callbacks?: FirestoreDataStoreCallbacks;
    // We only use this in the consistency tests which can't deal with the
    // computation model changing.
    readonly keepComputationModel?: boolean;
    // This is only for testing
    readonly durableStorageController?: DurableStorageController;
    readonly makeTableListener: MakeFirestoreTableListener;
    readonly computationModelOptions?: Partial<ComputationModelOptions>;
    readonly simpleActionPoster?: ActionPoster;
}

export class FirestoreDataStore implements DataStore {
    // Indexed by document ID.  FIXME: This is not named correctly.  It's just
    // aping what presence in `_appDataForTables` meant.
    private readonly _tablesSeenInSchema: Set<string> = new Set();
    private _listeningToPublishedAppData: boolean = false;
    private _handler: DocumentsFirestoreHandlerImpl | undefined;
    private _tablesFetched: Set<string> = new Set();
    private _tables: Map<string, TableGlideType> = new Map();
    private _dataRowStores: Map<string, DataRowStores> = new Map();

    private _appUserID: string | undefined;
    private _appUserEmail: string | undefined;
    private _appEnvironment: ActionAppEnvironment | undefined;
    private _fieldNameCache: FieldNameCache = new Map();
    private _lastSchema: TypeSchema | undefined;
    private _durableStorageController: DurableStorageController;
    private _didAddRowsFromOfflineQueue: boolean = false;
    private _unlistenPublishedAppData: (() => void) | undefined;
    private _earlySnapshotProvider: DataSnapshotProvider | undefined;

    private readonly _builderComputationModel: ComputationModelData | undefined;
    private readonly _playerComputationModel: ComputationModelData;

    // This is a bit of technical debt. The DurableStorageController needs a
    // defined TableMutationHandler, but can't get it until an initial schema
    // shows up. So we give it a Watchable instead; it'll wait until the
    // handler is actually defined and go about its business.
    private _handlerContainer = new Watchable<DocumentsFirestoreHandlerImpl | undefined>(undefined);

    private _fullSchema: TypeSchema | undefined;

    constructor(
        private readonly _appID: string,
        private readonly _database: Database | undefined,
        private _schema: TypeSchema | undefined,
        private readonly _appFacilities: ActionAppFacilities,
        private readonly _authenticator: AppUserProvider,
        private readonly _options: FirestoreDataStoreOptions,
        private readonly _pluginMetadata: readonly SerializablePluginMetadata[]
    ) {
        logInfo("********* new data store", _schema);
        this._fullSchema = _schema;
        this._schema = definedMap(_schema, s => ({
            tables: s.tables.filter(t => !isBigTableOrExternal(t)),
        }));

        this._appUserID = _authenticator.appUserID;
        this._appUserEmail = _authenticator.virtualEmail;

        // If we're in Automations, we send actions like we're in the builder,
        // but we don't want a builder computation model, and we don't want to
        // filter emails because we don't respect row owners at all.
        //
        // This is equivalent to `_options.isBuilder` if
        // `_options.isAutomations` is `false`.
        const computationModelsForBuilder = _options.isBuilder && !_options.isAutomations;
        const filterByEmailInPlayer = computationModelsForBuilder;
        const filterAddedRowsByEmailInPlayer = !_options.isBuilder;
        if (computationModelsForBuilder) {
            this._builderComputationModel = this.makeComputationModelData(true, false, false);
        }
        this._playerComputationModel = this.makeComputationModelData(
            false,
            filterByEmailInPlayer,
            filterAddedRowsByEmailInPlayer
        );

        _authenticator.addCallback(this.setAppUser);

        if (this._appUserID === undefined) {
            // This is another place where we set a ##previewAsUser.  I don't
            // think this is a good place to initiate this.
            void _authenticator.tryGetAppUserID?.();
        }

        if (_schema !== undefined) {
            this.addTables(_schema.tables);
        }

        this._durableStorageController = _options.durableStorageController ?? this.makeDurableStorageController();
    }

    public subscribeToOutstandingOperations(handler: ActionOutstandingOperationsHandler): void {
        this._durableStorageController.subscribeToOutstandingOperations(handler);
    }

    public unsubscribeFromOutstandingOperations(handler: ActionOutstandingOperationsHandler): void {
        this._durableStorageController.unsubscribeFromOutstandingOperations(handler);
    }

    public getActionOutstandingOperations(): ActionOperationsState {
        return this._durableStorageController.getActionOutstandingOperations();
    }

    private makeComputationModelData(
        forBuilder: boolean,
        filterByEmail: boolean,
        filterAddedRowsByEmail: boolean
    ): ComputationModelData {
        // this is a temporary feature flag being used to safely deploy some heavy changes to some old code.
        // https://github.com/glideapps/glide/issues/26412
        const newDataStore = (tn: { name: string; isSpecial: boolean }) =>
            new ComputationModelDataRowStore(
                filterByEmail,
                filterAddedRowsByEmail,
                () => this._tables.get(documentIDForTableName(tn)),
                () => this._handler?.appUserDataObservable.current,
                // `fetchTableRows` can indirectly cause us to create
                // a new computation model and retire the current one,
                // but this can also be called from an ongoing
                // computation.  It's not a good idea to retire the
                // computation model that's running the current
                // computation.
                // https://github.com/quicktype/glide/issues/16030
                () => setTimeout(() => this.fetchTableRows(tn, forBuilder), 0)
            );

        return {
            computationModel: undefined,
            cleanUpInterval: undefined,
            tableKeeperStore: new TableKeeperStoreImpl(tn => newDataStore(tn) as ComputationModelDataRowStore),
            observable: new Watchable<ComputationModel | undefined>(undefined),
        };
    }

    private makeDurableStorageController(): DurableStorageController {
        return new NonblockingResilientDurableStorageController(
            {
                appID: () => this._appID,
                appUserID: () => this._appUserID,
                appFacilities: () => this._appFacilities,
                database: () => this._database,
                tableMutationHandler: () => waitForChangeObservableValue(this._handlerContainer, isDefined),
                schema: () => this.schema,
                userAgnosticColumnsForTable: (tableName: TableName) => this.getUserAgnosticColumnsForTable(tableName),
                userSpecificColumnsForTable: (tableName: TableName) => this.getUserSpecificColumnsForTable(tableName),
                makeOfflineQueue: (name: string, onOnline: () => void) => {
                    const queue: OfflineQueue<AddRowQueueItem> | undefined = this._appEnvironment?.makeOfflineQueue?.(
                        name,
                        onOnline,
                        i => i.data.jobID
                    );
                    if (queue === undefined) return undefined;
                    const schema = this._schema;
                    if (!this._didAddRowsFromOfflineQueue && schema !== undefined) {
                        this._didAddRowsFromOfflineQueue = true;
                        queue.forEach(item => {
                            const { tableName, columnValues } = item.data;
                            const table = findTable(schema, tableName);
                            if (table === undefined) return;
                            // It's very unfortunate we have to convert field
                            // names and decode date/times here, but the code
                            // path we use to add to the queue saves the data
                            // as it goes out to Firestore, so we have to
                            // reverse those transforms here.  We'd have to
                            // use a different codepath to avoid this.
                            const converted = convertFieldNamesToColumnNames(
                                this._fieldNameCache,
                                table,
                                fromPairs(
                                    Object.entries(columnValues).map(([k, v]) => [k, convertValueFromSerializable(v)])
                                )
                            );
                            this._handler?.addRowToTable(tableName, converted as Record<string, WritableValue>);
                        });
                    }
                    return queue;
                },
                makeReloadResiliencyQueue: (name: string, onOnline: () => void) => {
                    return this._appEnvironment?.makeReloadResiliencyQueue?.(name, onOnline, i => i.data.jobID);
                },
            },
            this._appFacilities,
            this._options.deviceIDOverride,
            this._options.isBuilder,
            this._options.writeSource,
            this._options.isPersistent,
            {
                actionPoster: this._options.simpleActionPoster,
            }
        );
    }

    private getUserAgnosticColumnsForTable(tableName: TableName): string[] {
        const targetTable = this._tables.get(documentIDForTableName(tableName));
        if (targetTable === undefined) return [];

        return targetTable.columns.filter(isUserAgnosticDataColumn).map(c => c.name);
    }

    private getUserSpecificColumnsForTable(tableName: TableName): string[] {
        const targetTable = this._tables.get(documentIDForTableName(tableName));
        if (targetTable === undefined) return [];

        return targetTable.columns.filter(c => c.isUserSpecific === true).map(c => c.name);
    }

    private tryUseEarlySnapshots(): void {
        if (this._handler === undefined || this._earlySnapshotProvider === undefined) return;

        for (const table of this._tables.values()) {
            const snapshot = this._earlySnapshotProvider.getSnapshotForTable(getTableName(table));
            if (snapshot === undefined) continue;
            this._handler.updateWithSnapshotResults(
                table,
                snapshot.formatVersion,
                snapshot.rows,
                snapshot.version,
                false,
                false
            );
        }

        this._earlySnapshotProvider = undefined;
    }

    public setEarlySnapshotProvider(provider: DataSnapshotProvider): void {
        assert(this._earlySnapshotProvider === undefined);
        this._earlySnapshotProvider = provider;
        this.tryUseEarlySnapshots();
    }

    public getComputationModelObservable(
        forBuilder: boolean,
        noInit: boolean = false
    ): ChangeObservable<ComputationModel | undefined> {
        if (!noInit && this._playerComputationModel.computationModel === undefined) {
            this.updateComputationModel();
        }
        return forBuilder ? defined(this._builderComputationModel).observable : this._playerComputationModel.observable;
    }

    public setAppEnvironment(appEnvironment: ActionAppEnvironment): void {
        assert(this._appEnvironment === undefined);
        this._appEnvironment = appEnvironment;
        // If we don't call this, the data storage controller will never
        // instantiate the offline queue.
        this.appEnvironmentUpdated();
    }

    public appEnvironmentUpdated(): void {
        if (
            typeof this._playerComputationModel.computationModel === "object" ||
            typeof this._builderComputationModel?.computationModel === "object"
        ) {
            this.updateComputationModel();
        }
        this._durableStorageController.appEnvironmentUpdated();
    }

    private getLoadRowQuota(): number {
        const flags = this._appEnvironment?.eminenceFlags ?? eminenceFull;
        return getQuotaLimitIncludingOverage(flags.quotas[QuotaKind.RowsUsed]);
    }

    private setAppUser = (
        appUserID: string | undefined,
        realEmail: string | undefined,
        virtualEmail: string | undefined
    ) => {
        if (appUserID === undefined) return;

        const appUserEmail = virtualEmail ?? realEmail;

        if (this._appUserID === appUserID && this._appUserEmail === appUserEmail) return;

        if (this._appUserID === appUserID && !this._options.isBuilder) {
            // Why do we exit early here?  Because we don't care about the
            // email?
            return;
        }

        this._appUserID = appUserID;
        this._appUserEmail = appUserEmail;

        if (this._handler !== undefined) {
            this._handler.setAppUser(appUserID, appUserEmail);
        }
        this._durableStorageController.appUserChanged();
    };

    private addTables(tables: Iterable<TableGlideType>): void {
        for (const table of tables) {
            const name = getTableName(table);
            const documentID = documentIDForTableName(name);
            this._tables.set(documentID, table);
            this._tablesSeenInSchema.add(documentID);
        }
    }

    private listenIfNecessary(): void {
        if (this._lastSchema === undefined) {
            const initialSchema = this._appFacilities.getInitialSchemaForAppID?.(this._appID);
            if (initialSchema !== undefined) {
                const setSchema = isolateTypeSchema(initialSchema);
                this.setSchema(setSchema);
                this._lastSchema = setSchema;
            }
        }

        if (this._database === undefined) return;

        if (this._listeningToPublishedAppData) return;
        this._listeningToPublishedAppData = true;

        this._unlistenPublishedAppData = listenToPublishedAppData(this._database, this._appID, appData => {
            logInfo("published app data", appData);

            if (compareSchemas(this._lastSchema, appData.schema, false)?.tablesOrColumns === true) {
                this.setSchema(appData.schema);
            }
            this._lastSchema = appData.schema;
        });
    }

    public get schema(): TypeSchema {
        return this._schema ?? makeTypeSchema([]);
    }

    private retireComputationModel(data: ComputationModelData): void {
        const { computationModel, cleanUpInterval } = data;
        if (cleanUpInterval !== undefined) {
            clearInterval(cleanUpInterval);
        }

        if (computationModel !== undefined) {
            computationModel.retire();
        }

        // Just in case
        data.computationModel = undefined;
        data.cleanUpInterval = undefined;
    }

    private updateComputationModelWithData(
        data: ComputationModelData,
        inspector: SchemaInspector,
        forBuilder: boolean
    ): void {
        const { computationModel } = data;

        if (this._options.keepComputationModel === true && computationModel !== undefined) {
            logInfo("Not updating computation model");
            return;
        }

        if (this._appEnvironment === undefined) {
            assert(computationModel === undefined);
            return;
        }

        this.retireComputationModel(data);

        let queryFetcher: QueryFetcher | undefined;
        const qds = this._appEnvironment.queryableDataStore;
        if (qds !== undefined) {
            queryFetcher = {
                fetchQuery(q, cb) {
                    return qds.fetchQuery(q, cb, forBuilder);
                },
                getLocallyModifiedRowIDs(tn) {
                    return qds.getLocallyModifiedRowIDs(tn);
                },
            };
        }

        data.computationModel = new ComputationModelImpl(
            inspector,
            new QueryableLayeredTableKeeper(
                this._appEnvironment?.queryableDataStore,
                data.tableKeeperStore,
                forBuilder,
                tn => {
                    // The `_fullSchema` has queryable tables, too.
                    if (this._fullSchema === undefined) return false;
                    const t = findTable(this._fullSchema, tn);
                    if (t === undefined) return false;
                    return isQueryableTable(t);
                }
            ),
            this._appEnvironment,
            queryFetcher,
            this._pluginMetadata,
            this._options.computationModelOptions ?? {},
            false
        );
        if (forBuilder) {
            data.cleanUpInterval = setInterval(() => data.computationModel?.cleanUpUnusedQueryHandlers(), 5 * 1000);
        }
        data.observable.current = data.computationModel;
    }

    private updateComputationModel(): void {
        // this.schema explicitly "forgets" about all of the queryable tables,
        // but the inspector needs to know about all of them. Hence the use
        // of _fullSchema instead.
        const inspector = makeSimpleSchemaInspector(
            this._fullSchema ?? this.schema,
            this._appEnvironment?.sourceMetadata,
            this._appEnvironment?.userProfileTableInfo
        );

        this.updateComputationModelWithData(this._playerComputationModel, inspector, false);
        if (this._builderComputationModel !== undefined) {
            this.updateComputationModelWithData(this._builderComputationModel, inspector, true);
        }
    }

    public setSchema(schemaMaybeWithQueryable: TypeSchema | undefined): void {
        this._fieldNameCache.clear();

        // FIXME: This is a pretty gross layering violation.
        const qds = this._appEnvironment?.queryableDataStore;
        qds?.setSchema(schemaMaybeWithQueryable);
        this._fullSchema = schemaMaybeWithQueryable;
        const oldTables = this.schema.tables;
        const schema = definedMap(schemaMaybeWithQueryable, s => ({
            tables: s.tables.filter(t => !isBigTableOrExternal(t)),
        }));

        // If we're running in the builder, the schema could change
        // and we have to adapt.
        if (schema !== undefined) {
            this._schema = schema;
            this.addTables(schema.tables);
        }

        if (this._handler !== undefined) {
            qds?.setAppUserDataObservable(this._handler.appUserDataObservable);

            // ##computationModelSchemaChange:
            // Here we update the computation model when the computed columns
            // have changed in the schema, which, in the old computation
            // model, is the only reason it needs to be updated.  The new
            // computation model probably has more reasons to do that, for
            // example when the user profile changes in the app description.
            // In addition to that, this is not the code path we want to take
            // when the app creator plays with computed columns in the data
            // editor, because it's probably too slow.
            const ownersChanged = schema !== undefined && didEmailOwnersColumnsChange(oldTables, schema.tables);
            const changedComputedColumns = definedMap(schema, s => getChangedComputedColumns(oldTables, s.tables));

            this.updateComputationModel();
            this._handler.onPublishedAppDataUpdate(ownersChanged, changedComputedColumns);
            return;
        }

        this.updateComputationModel();

        this._handler = new DocumentsFirestoreHandlerImpl(
            this._options.makeTableListener,
            this._database,
            this._tables,
            this._playerComputationModel.tableKeeperStore,
            this._builderComputationModel?.tableKeeperStore,
            this._dataRowStores,
            this._fieldNameCache,
            this._appID,
            this._appUserID,
            this._appUserEmail,
            this._options.isBuilder,
            () => this.getLoadRowQuota(),
            this._durableStorageController,
            this._options.callbacks ?? {},
            this.runRowOwnerChangeCallbacks
        );
        this._handlerContainer.current = this._handler;
        qds?.setAppUserDataObservable(this._handler.appUserDataObservable);

        this.tryUseEarlySnapshots();

        for (const documentID of this._tablesFetched) {
            // Check whether we ever learned about this table from a schema
            if (!this._tablesSeenInSchema.has(documentID)) continue;

            const table = this._tables.get(documentID);
            if (table === undefined) {
                logError("Table not found", documentID);
                continue;
            }
            this._handler.fetchTableRows(getTableName(table));
        }
    }

    public userProfileTableChanged(): void {
        this.updateComputationModel();
    }

    public setEmailOwnersColumns(tableName: TableName, emailOwnersColumn: readonly string[]): void {
        if (this._appEnvironment === undefined) return;

        const result = setEmailOwnersColumnsInSchema(this.schema, [[tableName, emailOwnersColumn]]);
        if (typeof result === "number") return;

        const [schema] = result;
        this.setSchema(schema);
    }

    public fetchTableRows(tableName: TableName, forBuilder: boolean): void {
        const docID = documentIDForTableName(tableName);
        this._tablesSeenInSchema.add(docID);
        this.listenIfNecessary();
        this._tablesFetched.add(docID);

        if (this._handler !== undefined) {
            this._handler.fetchTableRows(tableName);
        }

        // We also need to force fetch GBTs used as user tables when we're in the builder.
        const appEnv = this._appEnvironment;
        if (!forBuilder || appEnv?.queryableDataStore === undefined || !getFeatureSetting("gbtUserProfileTables"))
            return;

        const isUserTable = areTableNamesEqual(tableName, appEnv.userProfileTableInfo?.tableName);
        if (!isUserTable) return;

        const sm = getNativeTableSourceMetadata(appEnv.sourceMetadata ?? [], tableName);
        if (!isGlideBigTablesSourceMetadata(sm)) return;

        forceLoadQueryableTable(tableName, appEnv.queryableDataStore, 100, forBuilder);
    }

    public getFirstRowObservable(
        tableName: TableName,
        isBuilder: boolean
    ): ChangeObservable<Row | undefined> | undefined {
        return defined(
            isBuilder ? this._builderComputationModel : this._playerComputationModel
        ).tableKeeperStore.getTableKeeperForTable(tableName).firstRowObservable;
    }

    public async addRowToTable(
        { tableName, sendToBackend, setUnderlyingData, awaitSend, onError, ...metadata }: DataStoreMutationOptions,
        columnValues: Record<string, LoadedGroundValue>,
        columnNames: ReadonlySet<string> | undefined
    ): Promise<AddRowToTableResult> {
        // FIXME: Support special tables, too
        assert(!tableName.isSpecial);

        const table = this._tables.get(documentIDForTableName(tableName));
        const values = adaptValuesForWriting(
            table,
            this._appEnvironment?.sourceMetadata ?? ShouldAgnostifyDateTimes.AlwaysAgnostic,
            {
                convertToString: false,
                removeUnknownColumns: false,
                allowRowID: true,
                appUserEmail:
                    this._appEnvironment?.authenticator.virtualEmail ?? this._appEnvironment?.authenticator.realEmail,
                fromDataEditor: metadata.fromDataEditor,
            },
            extractActionValues(columnValues, columnNames)
        );

        const rowIndex = addRowIDToColumnValues(values, table);

        let rows: PlayerAndBuilderRows | undefined;
        // FIXME: Have the handler somehow interact with the durable storage
        // controller.  It's weird that this can succeed, but then the storage
        // controller can fail.
        if (this._handler !== undefined && setUnderlyingData) {
            const maybeRows = this._handler.addRowToTable(tableName, values);
            rows = maybeRows;
        }

        let didAdd: boolean;
        let jobID: string | undefined;
        let confirmedAtVersion: number | boolean | undefined;

        if (sendToBackend) {
            const sendPromise = this._durableStorageController.addRowToTable(
                tableName,
                rowIndex,
                values,
                metadata,
                onError
            );
            if (awaitSend) {
                const result = await sendPromise;
                if (typeof result !== "boolean") {
                    jobID = result.jobID;
                    confirmedAtVersion = result.confirmedAtVersion;
                    didAdd = result.confirmedAtVersion !== false;
                } else {
                    didAdd = result;
                }
            } else {
                didAdd = true;
            }
        } else {
            didAdd = false;
        }

        return {
            didAdd,
            ...(rows ?? { playerRow: undefined, builderRow: undefined }),
            jobID,
            confirmedAtVersion,
        };
    }

    public async setColumnsInRow(
        { tableName, sendToBackend, setUnderlyingData, awaitSend, onError, ...metadata }: DataStoreMutationOptions,
        rowIndex: RowIndex,
        columnValues: Record<string, LoadedGroundValue>,
        withDebounce: boolean,
        onCompletion: (() => void) | undefined,
        existingJobID: string | undefined
    ): Promise<MutationResult> {
        // FIXME: Support special tables, too
        assert(!tableName.isSpecial);

        const table = this._tables.get(documentIDForTableName(tableName));
        if (table === undefined) return { jobID: undefined, confirmedAtVersion: false };

        const values = adaptValuesForWriting(
            table,
            this._appEnvironment?.sourceMetadata ?? ShouldAgnostifyDateTimes.AlwaysAgnostic,
            {
                convertToString: false,
                removeUnknownColumns: false,
                allowRowID: false,
                fromDataEditor: metadata.fromDataEditor,
            },
            extractActionValues(columnValues, undefined)
        );

        if (setUnderlyingData && this._handler !== undefined) {
            this._handler.setColumnsInRow(tableName, rowIndex, values);
        }

        if (this._appUserID === undefined) {
            // If no user is logged in then there's no point sending
            // user-specific columns to the backend, since it won't save it
            // anyway.
            for (const c of table.columns) {
                if (c.isUserSpecific !== true) continue;
                delete values[c.name];
            }
        }

        if (Object.keys(values).length === 0) return { jobID: undefined, confirmedAtVersion: true };

        if (sendToBackend) {
            // The durable storage controller will wait until the debounce is
            // done, which is too long to block the app for.  We've already
            // evaluated the values to set, and we've set them in the runtime
            // data.  This is just about writing the action out, which can happen
            // asynchronously, but if the caller wants us to await, we will.
            const promise = this._durableStorageController.setColumnsInRow(
                tableName,
                rowIndex,
                values,
                withDebounce,
                metadata,
                onError,
                onCompletion,
                existingJobID
            );
            if (awaitSend) {
                return await promise;
            }
        }

        return { jobID: existingJobID, confirmedAtVersion: undefined };
    }

    public async deleteRowsAtIndexes(
        { tableName, sendToBackend, setUnderlyingData, awaitSend, onError, ...metadata }: DataStoreMutationOptions,
        rowIndexes: readonly RowIndex[]
    ): Promise<MutationResult> {
        if (rowIndexes.length === 0) return { jobID: undefined, confirmedAtVersion: true };

        if (tableName.isSpecial) {
            if (sendToBackend) {
                // This is just for the comments table and doesn't really belong
                // here.
                const authID = await this._appFacilities.getAuthUserID();
                for (const rowIndex of rowIndexes) {
                    await postDeleteRowAction(
                        defined(this._database),
                        this._appID,
                        authID,
                        this._appUserID,
                        this._options.deviceIDOverride,
                        tableName,
                        rowIndex,
                        this._options.isBuilder,
                        metadata.fromDataEditor,
                        metadata.screenPath,
                        this._options.writeSource
                    );
                }
            }
            return { jobID: undefined, confirmedAtVersion: true };
        }

        if (setUnderlyingData && this._handler !== undefined) {
            for (const rowIndex of rowIndexes) {
                this._handler.deleteRowAtIndex(tableName, rowIndex);
            }
        }

        // We only use `onConfirm` when we're deleting a single row, so it's
        // fine that we might assign it multiple times in the loop here.
        let onConfirm: ((serial: number) => void) | undefined;

        for (const rowIndex of rowIndexes) {
            if (isBaseRowIndex(rowIndex)) {
                onConfirm = serial => this._handler?.confirmDeletedRow(tableName, rowIndex, serial);
            } else if (typeof rowIndex.keyColumnValue === "string") {
                // We assume that the key column value is a row ID. It might not be one, for example
                // if it's a favorite, but that's not a big deal if it isn't: we'll just _not_ ignore
                // the row.
                const rowID = rowIndex.keyColumnValue;
                this._handler?.addIgnoredRowID(tableName, rowID);
                onConfirm = serial => this._handler?.removeIgnoredRowID(tableName, rowID, serial);
            }
        }

        if (sendToBackend) {
            if (rowIndexes.length > 1) {
                const body: EnqueueDeleteRowsRequestBody = {
                    appID: this._appID,
                    tableName,
                    rowIndexes,
                    deviceID: this._options.deviceIDOverride ?? getDeviceID(),
                    fromBuilder: this._options.isBuilder,
                    fromDataEditor: metadata.fromDataEditor,
                    writeSource: this._options.writeSource,
                    appUserID: this._appUserID,
                };
                const promise = this._appFacilities.callAuthIfAvailableCloudFunction("enqueueDeleteRows", body, {});
                if (awaitSend) {
                    await promise;
                }
                // We can't return a job ID here because the backend might enqueue
                // more than one job and doesn't return the IDs to us.  But that's
                // fine because we can just assume it's not confirmed and just
                // pretend the rows are deleted, because a deletion won't conflict
                // with anything.
                return { jobID: undefined, confirmedAtVersion: undefined };
            }

            const [rowIndex] = rowIndexes;
            const sendPromise = this._durableStorageController.deleteRowAtIndex(
                tableName,
                rowIndex,
                metadata,
                onError,
                onConfirm
            );
            if (awaitSend) {
                return await sendPromise;
            }
        }

        return { jobID: undefined, confirmedAtVersion: undefined };
    }

    public isRowOwnedByUser(tableName: TableName, row: Row): boolean {
        const table = this._tables.get(documentIDForTableName(tableName));

        const appUserData = this._handler?.appUserDataObservable.current;
        return isNewRowOwnedByUser(row, appUserData, table);
    }

    public resetFromUpstream(): void {
        this._handler?.resetFromFirestore();
    }

    public retire(): void {
        this._unlistenPublishedAppData?.();
        this._listeningToPublishedAppData = false;
        if (this._handler !== undefined) {
            this._handler.retire();
        }
        this._durableStorageController.retire();
        this._authenticator.removeCallback(this.setAppUser);

        this.retireComputationModel(this._playerComputationModel);
        if (this._builderComputationModel !== undefined) {
            this.retireComputationModel(this._builderComputationModel);
        }
    }

    private rowOwnerCallbacks: (() => void)[] = [];

    public addRowOwnerChangeCallback(cb: () => void): void {
        this.rowOwnerCallbacks.push(cb);
    }

    public removeRowOwnerChangeCallback(cb: () => void): void {
        this.rowOwnerCallbacks = this.rowOwnerCallbacks.filter(x => x !== cb);
    }

    private runRowOwnerChangeCallbacks = () => {
        for (const cb of this.rowOwnerCallbacks) {
            cb();
        }
    };

    public getAppUserID(): string | undefined {
        return this._appUserID;
    }
}
