import { type GlideFC, GlideIcon } from "@glide/common";
import { showCancelUploadModal } from "@glide/common-core/dist/js/components/in-app-modals";
import { getLocalizedString } from "@glide/localization";
import type { AppKind } from "@glide/location-common";
import type { MonotoneIcons } from "@glide/plugins";
import { decodeURIComponentOrElse, isEmptyOrUndefined, nonNull } from "@glide/support";
import * as React from "react";
import { createPortal } from "react-dom";

import { Img } from "../../components/img/img";
import { type FormElementProps, APP_MODAL_ROOT } from "../../wire-lib";
import { WireBasePicker } from "../wire-base-picker/wire-base-picker";
import { WireModal } from "../wire-modal/wire-modal";

export type WireFilePickerMode = "normal" | "uploading" | "upload-failed" | "deleting";

export interface WireFilePickerViewProps extends FormElementProps {
    readonly appKind: AppKind;

    readonly value?: string;
    readonly valueLabel?: string;
    readonly isRequired?: boolean;
    readonly iconSource?: MonotoneIcons;
    readonly showPreview?: boolean;
    readonly deleteModalTitle?: string;
    readonly deleteModalDescription?: string;

    readonly mode?: WireFilePickerMode;

    readonly capture?: string;
    readonly mimePattern?: string;
    readonly className?: string;

    readonly onClearClicked?: (ev: React.MouseEvent<HTMLDivElement>) => void;
    readonly onInputChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    readonly onDelete?: (shouldDelete: boolean) => void;
    readonly onCancel?: (shouldCancel: boolean) => void;
}

interface Props extends WireFilePickerViewProps {
    readonly progressPercentage?: number;
}

export const WireFilePickerView: GlideFC<Props> = p => {
    const {
        value,
        valueLabel,
        title,
        mode,
        mimePattern,
        showPreview,
        deleteModalTitle = "Delete File",
        deleteModalDescription = "Are you sure you want to delete this file?",
        className,
        isEnabled = true,
        capture,
        progressPercentage,
        onDelete,
        onClearClicked,
        onCancel,
        onInputChange,
        iconSource,
        appKind,
    } = p;

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const typeIcon = (): JSX.Element => {
        if (iconSource) {
            return <GlideIcon kind="monotone" icon={iconSource} iconSize={20} />;
        } else {
            return <GlideIcon kind="monotone" icon="mt-page-image-upload" iconSize={20} />;
        }
    };
    const onCancelClicked = React.useCallback(
        async (ev: React.MouseEvent<HTMLElement>) => {
            ev.preventDefault();
            ev.stopPropagation();

            if (inputRef.current !== null) {
                // If we don't do this, then you can't select the same file for upload more than once.
                inputRef.current.value = "";
            }

            onCancel?.(await showCancelUploadModal(appKind));
        },
        [appKind, onCancel, inputRef]
    );
    const onClearClickRemovingValue = React.useCallback(
        (ev: React.MouseEvent<HTMLInputElement>) => {
            ev.preventDefault();
            ev.stopPropagation();

            if (inputRef.current !== null) {
                // If we don't do this, then you can't select the same file for upload more than once.
                inputRef.current.value = "";
            }
            onClearClicked?.(ev);
        },
        [onClearClicked, inputRef]
    );

    let portal: React.ReactNode;
    if (mode === "deleting") {
        portal = createPortal(
            <WireModal
                title={deleteModalTitle}
                description={deleteModalDescription}
                accept={getLocalizedString("delete", appKind)}
                cancel={getLocalizedString("cancel", appKind)}
                onFinish={d => onDelete?.(d)}
            />,
            nonNull(document.getElementById(APP_MODAL_ROOT))
        );
    }

    const isUploading = mode === "uploading";
    const hasValue = !isEmptyOrUndefined(value);
    const isPlaceholder = mode !== "normal" || !hasValue;

    let displayLabel = valueLabel;
    if (isUploading) {
        displayLabel = getLocalizedString("uploading", appKind);
    }

    if (mode === "upload-failed") {
        displayLabel = getLocalizedString("uploadFailedInline", appKind);
    }

    return (
        <WireBasePicker
            {...p}
            className={className}
            data-test="app-file-picker"
            progressPercentage={isUploading ? progressPercentage : undefined}
            isPlaceholder={isPlaceholder}
            title={title}
            leftItem={
                hasValue && showPreview ? (
                    <Img className={(isUploading ? "fp-uploading" : undefined) + ` image`} src={value} alt={title} />
                ) : (
                    typeIcon()
                )
            }
            rightItem={!isEmptyOrUndefined(value) || isUploading ? "clear" : undefined}
            rightItemOnClick={isUploading ? onCancelClicked : hasValue ? onClearClickRemovingValue : undefined}
        >
            <label>
                {displayLabel === undefined ? displayLabel : decodeURIComponentOrElse(displayLabel)}
                <input
                    ref={inputRef}
                    capture={capture as any}
                    type="file"
                    accept={mimePattern}
                    onChange={onInputChange}
                    disabled={!isEnabled || mode === "uploading"}
                    aria-label={`${title} file browser`}
                />
            </label>
            {portal}
        </WireBasePicker>
    );
};
