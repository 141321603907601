import * as glide from "@glide/plugins";
import { checkString, isEmptyOrUndefined, isUndefinedish } from "@glide/support";
import FormData from "form-data";
import type { Result as ResultType } from "@glide/plugins";
import { getEmbeddings } from "./openai";
import * as iots from "io-ts";
import { isLeft } from "fp-ts/lib/Either";
import md5 from "blueimp-md5";
const { Result } = glide;

// exported for testing
export function getFilenameFromURL(url: string): string | undefined {
    try {
        const parsed = new URL(url);
        const pathname = parsed.pathname;
        const filename = pathname.substring(pathname.lastIndexOf("/") + 1);
        if (filename === "") return undefined;
        const decoded = decodeURIComponent(filename);

        // Check if there's a dot, not at the start or end
        const dotIndex = decoded.lastIndexOf(".");
        if (dotIndex <= 0 || dotIndex === decoded.length - 1) {
            return undefined;
        }
        return decoded;
    } catch {
        return undefined;
    }
}

const apiKeyParam = glide.makeParameter({
    type: "secret",
    name: "API Key",
    description: "Your Pinecone API key",
    required: true,
});

const assistantNameParam = glide.makeParameter({
    type: "string",
    name: "Assistant Name",
    description: "Name of the assistant",
    required: true,
    useTemplate: "withLabel",
});

const fileIdParam = glide.makeParameter({
    type: "string",
    name: "File ID",
    description: "ID of the file",
    required: true,
    useTemplate: "withLabel",
});

const fileUrlParam = glide.makeParameter({
    type: "url",
    name: "File URL",
    description: "URL of the file to upload",
    required: true,
});

const instructionsParam = glide.makeParameter({
    type: "string",
    name: "Description",
    description: "Description or directive for the assistant to apply to all responses",
    required: false,
    multiLine: true,
    useTemplate: "withLabel",
});

const hostDescription =
    "Host to upsert data into. [Create here](https://app.pinecone.io/organizations/-/projects/-/create-index/serverless). Must 1536 dimensions with cosine metric.";

const hostSetting = glide.makeParameter({
    type: "string",
    name: "Host",
    description: hostDescription,
});

const regionParam = glide.makeParameter({
    type: "enum",
    name: "Region",
    values: [
        {
            value: "us",
            label: "US",
        },
        {
            value: "eu",
            label: "EU",
        },
    ],
});

const messageParam = glide.makeParameter({
    type: "string",
    name: "Message",
    description: "Message to send to the assistant",
    required: true,
    multiLine: true,
    useTemplate: "withLabel",
});

const metadataParam = glide.makeParameter({
    type: "json",
    name: "Metadata",
    description: "Optional metadata to attach",
    required: false,
});

// Results
const fileIdResult = glide.makeParameter({
    type: "string",
    name: "File ID",
});

const fileNameResult = glide.makeParameter({
    type: "string",
    name: "File Name",
});

const assistantIdResult = glide.makeParameter({
    type: "string",
    name: "Assistant name",
});

const statusResult = glide.makeParameter({
    type: "string",
    name: "Status",
});

const createdAtResult = glide.makeParameter({
    type: "dateTime",
    name: "Created At",
});

const citationsResult = glide.makeParameter({
    type: "json",
    name: "Citations",
    description: "Citations from the assistant's response",
});

const finishReasonResult = glide.makeParameter({
    type: "string",
    name: "Finish Reason",
    description: "Reason why the assistant stopped generating",
    useTemplate: "withLabel",
});

const messageResult = glide.makeParameter({
    type: "string",
    name: "Message",
    description: "Assistant's response message",
    useTemplate: "withLabel",
});

const fileMetadataResult = glide.makeParameter({
    type: "json",
    name: "Metadata",
    description: "File metadata if any was attached",
});

export const plugin = glide.newPlugin({
    id: "pinecone",
    name: "Pinecone",
    description: "Integrate with Pinecone's Assistant API for AI-powered document interactions",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/v1737071358/plugins/pinecone.png",
    tier: "starter",
    documentationUrl: "https://www.glideapps.com/docs/pinecone",
    parameters: {
        apiKey: apiKeyParam,
        host: hostSetting,
    },
});

// Helper function to construct API URLs
function apiUrl(path: string): string {
    return `https://api.pinecone.io${path.startsWith("/") ? path : `/${path}`}`;
}

// Helper function to construct host-specific URLs
function hostUrl(host: string, path: string): string {
    return `${host}/assistant${path.startsWith("/") ? path : `/${path}`}`;
}

// Helper function to create headers
function getHeaders(apiKey: string) {
    return {
        "Api-Key": apiKey,
        "Content-Type": "application/json",
        Accept: "application/json",
    };
}

// Helper function to get assistant host
async function getAssistantHost(
    context: glide.ServerActionExecutionContext<any>,
    apiKey: string,
    assistantName: string
): Promise<ResultType<string>> {
    try {
        const response = await context.fetch(apiUrl(`/assistant/assistants/${assistantName}`), {
            method: "GET",
            headers: getHeaders(apiKey),
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => "Failed to read error response");
            return Result.FailFromHTTPStatus(`Failed to get assistant host: ${errorText}`, response.status);
        }

        const data = await response.json();
        const host = data.host;
        if (typeof host !== "string" || host === "") {
            return Result.FailPermanent("Assistant host not found in response");
        }

        return Result.Ok(host);
    } catch (error: unknown) {
        const errorMessage = error instanceof Error ? error.message : "Unknown error";
        return Result.FailPermanent(`Failed to get assistant host: ${errorMessage}`);
    }
}

// Returns the file ID
async function uploadFileFromBuffer(
    context: Pick<glide.ServerActionExecutionContext<any>, "fetch">,
    apiKey: string,
    assistantHost: string,
    assistantName: string,
    filename: string | undefined,
    contentType: string,
    buffer: Buffer,
    metadata: glide.JSONValue | undefined
) {
    const formData = new FormData();
    formData.append("file", buffer, {
        filename,
        contentType,
    });

    const url = new URL(hostUrl(assistantHost, `/files/${assistantName}`));
    if (metadata !== undefined) {
        // FIXME: Remove that string case once we've fixed
        // https://github.com/glideapps/glide/issues/31937
        const metadataString = typeof metadata === "string" ? metadata : JSON.stringify(metadata);
        url.searchParams.set("metadata", metadataString);
    }

    const response = await context.fetch(url.toString(), {
        method: "POST",
        headers: {
            "Api-Key": apiKey,
            Accept: "application/json",
            ...formData.getHeaders(),
        },
        body: formData.getBuffer(),
    });

    if (!response.ok) {
        const errorText = await response.text().catch(() => "Failed to read error response");
        return Result.FailFromHTTPStatus("Failed to upload file", response.status, { errorText });
    }

    const data = await response.json();
    return Result.Ok(checkString(data.id));
}

// Returns the file ID
async function uploadFile(
    context: Pick<glide.ServerActionExecutionContext<any>, "fetch">,
    apiKey: string,
    assistantHost: string,
    assistantName: string,
    fileUrl: string,
    metadata: glide.JSONValue | undefined
): Promise<glide.Result<string>> {
    const fileResponse = await context.fetch(fileUrl);
    if (!fileResponse.ok) {
        const errorText = await fileResponse.text().catch(() => "Failed to read error response");
        return Result.FailFromHTTPStatus("Failed to fetch file", fileResponse.status, { errorText });
    }

    const filename = getFilenameFromURL(fileUrl);
    const contentType = "application/octet-stream";
    const buffer = await fileResponse.arrayBuffer();

    return await uploadFileFromBuffer(
        context,
        apiKey,
        assistantHost,
        assistantName,
        filename,
        contentType,
        Buffer.from(buffer),
        metadata
    );
}

async function deleteFile(
    context: Pick<glide.ServerActionExecutionContext<any>, "fetch">,
    apiKey: string,
    assistantHost: string,
    assistantName: string,
    fileId: string
): Promise<glide.Result> {
    const response = await context.fetch(hostUrl(assistantHost, `/files/${assistantName}/${fileId}`), {
        method: "DELETE",
        headers: getHeaders(apiKey),
    });

    if (!response.ok) {
        const errorText = await response.text().catch(() => "Failed to read error response");
        return Result.FailFromHTTPStatus("Failed to delete file", response.status, { errorText });
    }

    return Result.Ok();
}

// Create Assistant Action
plugin.addAction({
    id: "create-assistant",
    name: "Create Assistant",
    description: "Create a new Pinecone Assistant",
    billablesConsumed: 1,
    parameters: {
        name: assistantNameParam,
        instructions: instructionsParam,
        metadata: metadataParam,
        region: regionParam,
    },
    results: {},
    async execute(context, { apiKey, name, instructions, metadata, region }) {
        if (isEmptyOrUndefined(apiKey)) return Result.FailPermanent("API key is required");
        if (isEmptyOrUndefined(name)) return Result.FailPermanent("Name is required");

        if (metadata === null || typeof metadata !== "object") {
            metadata = undefined;
        }

        const response = await context.fetch(apiUrl("/assistant/assistants"), {
            method: "POST",
            headers: getHeaders(apiKey),
            body: JSON.stringify({
                name,
                instructions,
                metadata,
                region,
            }),
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => "Failed to read error response");
            return Result.FailFromHTTPStatus(errorText, response.status);
        }

        await response.json();
        context.consumeBillable();
        return Result.Ok({});
    },
});

// Delete Assistant Action
plugin.addAction({
    id: "delete-assistant",
    name: "Delete Assistant",
    description: "Delete a Pinecone Assistant",
    billablesConsumed: 1,
    parameters: {
        assistantId: assistantIdResult,
    },
    async execute(context, { apiKey, assistantId }) {
        if (isUndefinedish(apiKey)) {
            return Result.FailPermanent("API key is required");
        }

        const response = await context.fetch(apiUrl(`/assistant/assistants/${assistantId}`), {
            method: "DELETE",
            headers: getHeaders(apiKey),
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => "Failed to read error response");
            return Result.FailFromHTTPStatus("Failed to delete assistant", response.status, { errorText });
        }

        context.consumeBillable();
        return Result.Ok();
    },
});

// Upload File Action
plugin.addAction({
    id: "upload-file",
    name: "Upload File",
    description: "Upload a file to be used with Pinecone Assistant",
    billablesConsumed: 1,
    parameters: {
        assistantName: assistantNameParam,
        fileUrl: fileUrlParam,
        metadata: metadataParam,
    },
    results: {
        fileId: fileIdResult,
    },
    async execute(context, { apiKey, assistantName, fileUrl, metadata }) {
        if (isEmptyOrUndefined(apiKey) || isEmptyOrUndefined(fileUrl) || isEmptyOrUndefined(assistantName)) {
            return Result.FailPermanent("API key, assistant name, and file URL are required");
        }

        const hostResult = await getAssistantHost(context, apiKey, assistantName);
        if (!hostResult.ok) {
            return hostResult;
        }

        const fileId = await uploadFile(context, apiKey, hostResult.result, assistantName, fileUrl, metadata);
        if (!fileId.ok) {
            return fileId;
        }

        context.consumeBillable();
        return Result.Ok({
            fileId: fileId.result,
        });
    },
});

// Get File Info Action
plugin.addAction({
    id: "get-file-info",
    name: "Get File Info",
    description: "Get information about a file",
    billablesConsumed: 1,
    parameters: {
        assistantName: assistantNameParam,
        fileId: fileIdParam,
    },
    results: {
        fileId: fileIdResult,
        fileName: fileNameResult,
        status: statusResult,
        createdAt: createdAtResult,
        metadata: fileMetadataResult,
    },
    async execute(context, { apiKey, assistantName, fileId }) {
        if (isUndefinedish(apiKey) || isUndefinedish(assistantName)) {
            return Result.FailPermanent("API key and assistant name are required");
        }

        const hostResult = await getAssistantHost(context, apiKey, assistantName);
        if (!hostResult.ok) {
            return hostResult;
        }

        const response = await context.fetch(hostUrl(hostResult.result, `/files/${assistantName}/${fileId}`), {
            method: "GET",
            headers: getHeaders(apiKey),
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => "Failed to read error response");
            return Result.FailFromHTTPStatus("Failed to get file info", response.status, { errorText });
        }

        const data = await response.json();
        context.consumeBillable();
        return Result.Ok({
            fileId: data.id,
            fileName: data.name,
            status: data.status,
            createdAt: data.created_at,
            metadata: data.metadata,
        });
    },
});

plugin.addAction({
    id: "delete-file",
    name: "Delete File",
    description: "Delete a file",
    billablesConsumed: 1,
    parameters: {
        assistantName: assistantNameParam,
        fileId: fileIdParam,
    },
    async execute(context, { apiKey, assistantName, fileId }) {
        if (isUndefinedish(apiKey) || isUndefinedish(assistantName) || isUndefinedish(fileId)) {
            return Result.FailPermanent("API key, assistant name, and file ID are required");
        }

        const hostResult = await getAssistantHost(context, apiKey, assistantName);
        if (!hostResult.ok) {
            return hostResult;
        }

        const result = await deleteFile(context, apiKey, hostResult.result, assistantName, fileId);
        if (!result.ok) {
            return result;
        }

        context.consumeBillable();
        return Result.Ok();
    },
});

// Helper function to validate and parse conversation messages
function parseConversationMessages(
    message: string | undefined
): { role: "user" | "assistant"; content: string }[] | undefined {
    if (isUndefinedish(message)) return undefined;

    try {
        const parsed = JSON.parse(message);
        if (!Array.isArray(parsed)) return undefined;

        // Validate each message in the array
        for (const msg of parsed) {
            if (typeof msg !== "object" || msg === null) return undefined;
            if (typeof msg.content !== "string") return undefined;
            if (msg.role !== "user" && msg.role !== "assistant") return undefined;
        }

        return parsed;
    } catch {
        return undefined;
    }
}

// Send Message Action
plugin.addAction({
    id: "send-message",
    name: "Send Message",
    description:
        "Send a message or conversation history to a Pinecone Assistant. For conversation history, provide a JSON string containing an array of messages with role ('user' or 'assistant') and content.",
    billablesConsumed: 1,
    parameters: {
        assistantName: assistantNameParam,
        message: messageParam,
    },
    results: {
        message: messageResult,
        finishReason: finishReasonResult,
        citations: citationsResult,
    },
    async execute(context, { apiKey, assistantName, message }) {
        if (isUndefinedish(apiKey) || isUndefinedish(assistantName)) {
            return Result.FailPermanent("API key and assistant name are required");
        }

        const hostResult = await getAssistantHost(context, apiKey, assistantName);
        if (!hostResult.ok) return hostResult;

        // Try to parse as conversation history, fallback to single message
        const messages = parseConversationMessages(message) ?? [
            {
                role: "user",
                content: message,
            },
        ];

        const response = await context.fetch(hostUrl(hostResult.result, `/chat/${assistantName}`), {
            method: "POST",
            headers: getHeaders(apiKey),
            body: JSON.stringify({ messages }),
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => "Failed to read error response");
            return Result.FailFromHTTPStatus("Failed to send message", response.status, { errorText });
        }

        const data = await response.json();
        context.consumeBillable();
        return Result.Ok({
            citations: data.citations,
            finishReason: data.finish_reason,
            message: data.message.content,
        });
    },
});

const namespaceParam = glide.makeParameter({
    type: "string",
    name: "Namespace",
    description: "Namespace to upsert data into",
    propertySection: {
        name: "Advanced",
        order: 1,
        collapsed: true,
    },
});

const textParam = glide.makeParameter({
    type: "string",
    name: "Text",
    description: "Text to upsert",
    required: true,
});

const hostParam = glide.makeParameter({
    type: "string",
    name: "Host",
    description: hostDescription,
    propertySection: {
        name: "Advanced",
        order: 1,
        collapsed: true,
    },
});

const idParam = glide.makeParameter({
    type: "string",
    name: "ID",
    description: "ID to upsert data into. Use something like rowID to query/delete over. Default: a id off text value.",
});

const hashText = md5;

const upsertReturnIdParam = glide.makeParameter({
    type: "string",
    name: "Return ID",
    description: "Return the ID of the upserted data",
});

// Upsert Data Action
plugin.addAction({
    id: "upsert-data",
    name: "Index Data",
    description: "Upsert data into a Pinecone Database",
    billablesConsumed: 1,
    parameters: {
        host: hostParam,
        namespace: namespaceParam,
        id: idParam,
        text: textParam,
    },
    results: {
        id: upsertReturnIdParam,
    },
    async execute(context, { apiKey, host, namespace, text, id }) {
        if (isUndefinedish(apiKey)) return Result.FailPermanent("API Key is required");
        if (isUndefinedish(host)) return Result.FailPermanent("Host is required");
        if (isUndefinedish(text)) return Result.FailPermanent("Text is required");

        const openaiApiKey = context.getSecret("openai");
        if (openaiApiKey === undefined) {
            return glide.Result.FailPermanent("Embedding API key is not set");
        }

        const embedding = await context.useCache(
            async () => {
                const result = await getEmbeddings(context, openaiApiKey, [text], "text-embedding-3-small");
                if (!result.ok) {
                    return result;
                }
                return Result.Ok(result.result[0]);
            },
            ["pinecone-embedding-cache", text],
            true
        );

        if (!embedding.ok) return embedding;

        const values = embedding.result;

        const upsertId = id ?? hashText(text);

        const response = await context.fetch(`${host}/vectors/upsert`, {
            method: "POST",
            headers: getHeaders(apiKey),
            body: JSON.stringify({ namespace, vectors: { id: upsertId, values, metadata: { text } } }),
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => "Failed to read error response");
            console.log(errorText); // eslint-disable-line no-console
            return Result.FailFromHTTPStatus("Failed to upsert data", response.status, { errorText });
        }

        context.consumeBillable();
        return Result.Ok({ id: upsertId });
    },
});

const rawQueryResultsParam = glide.makeParameter({
    type: "json",
    name: "Raw",
    description: "Raw results from the query",
});

const textQueryResultsParam = glide.makeParameter({
    type: "json",
    name: "Texts",
    description: "Results from the query, formatted as text",
});

const idsQueryResultsParam = glide.makeParameter({
    type: "json",
    name: "IDs",
    description: "IDs from the query",
});

const resultSchema = iots.type({
    matches: iots.array(
        iots.type({
            id: iots.string,
            score: iots.number,
            metadata: iots.type({
                text: iots.string,
            }),
        })
    ),
});

const numberOfResultsParam = glide.makeParameter({
    type: "number",
    name: "Number of Results",
    description: "Number of results to return",
    defaultValue: 1,
});

// Query Data Action
plugin.addAction({
    id: "query-data",
    name: "Query Data",
    description: "Query data from a Pinecone Database",
    billablesConsumed: 1,
    parameters: {
        host: hostParam,
        namespace: namespaceParam,
        text: textParam,
        numberOfResults: numberOfResultsParam,
    },

    results: {
        text: textQueryResultsParam,
        ids: idsQueryResultsParam,
        raw: rawQueryResultsParam,
    },
    async execute(context, { apiKey, host, namespace, text, numberOfResults }) {
        if (isUndefinedish(apiKey)) return Result.FailPermanent("API Key is required");
        if (isUndefinedish(host)) return Result.FailPermanent("Host is required");
        if (isUndefinedish(text)) return Result.FailPermanent("Text is required");

        const openaiApiKey = context.getSecret("openai");
        if (openaiApiKey === undefined) {
            return glide.Result.FailPermanent("Embedding API key is not set");
        }

        const embedding = await context.useCache(
            async () => {
                const result = await getEmbeddings(context, openaiApiKey, [text], "text-embedding-3-small");
                if (!result.ok) {
                    return result;
                }
                return Result.Ok(result.result[0]);
            },
            ["pinecone-embedding-cache", text],
            true
        );

        if (!embedding.ok) return embedding;

        const vector = embedding.result;

        const response = await context.fetch(`${host}/query`, {
            method: "POST",
            headers: getHeaders(apiKey),
            body: JSON.stringify({ namespace, top_k: numberOfResults ?? 1, include_metadata: true, vector }),
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => "Failed to read error response");
            return Result.FailFromHTTPStatus("Failed to query data", response.status, { errorText });
        }

        const data = await response.json();
        console.log(data); // eslint-disable-line no-console
        const results = resultSchema.decode(data);
        if (isLeft(results)) {
            return Result.Fail("Failed to decode query results", { data });
        }

        context.consumeBillable();
        return Result.Ok({
            text: results.right.matches.map(match => match.metadata.text),
            raw: results.right,
            ids: results.right.matches.map(match => match.id),
        });
    },
});

const textDeleteParam = glide.makeParameter({
    type: "string",
    name: "Text",
    description: "Text to delete. Should match the indexed value in the data.",
    required: true,
});

// Delete Data Action
plugin.addAction({
    id: "delete-data",
    name: "Delete Data",
    description: "Delete data from a Pinecone Database",
    billablesConsumed: 1,
    parameters: {
        host: hostParam,
        namespace: namespaceParam,
        text: textDeleteParam,
        id: idParam,
    },
    async execute(context, { apiKey, host, namespace, text, id }) {
        if (isUndefinedish(apiKey)) return Result.FailPermanent("API Key is required");
        if (isUndefinedish(host)) return Result.FailPermanent("Host is required");
        if (isUndefinedish(text)) return Result.FailPermanent("Text is required");

        const response = await context.fetch(`${host}/vectors/delete`, {
            method: "POST",
            headers: getHeaders(apiKey),
            body: JSON.stringify({ namespace, ids: [id ?? hashText(text)] }),
        });

        if (!response.ok) {
            const errorText = await response.text().catch(() => "Failed to read error response");
            return Result.FailFromHTTPStatus("Failed to delete data", response.status, { errorText });
        }

        context.consumeBillable();
        return Result.Ok();
    },
});
