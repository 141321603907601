import {
    type ActionDescription,
    type EditScreenDescription,
    type PropertyDescription,
    ActionKind,
    isFormScreen,
    makeEnumProperty,
    makeStringProperty,
} from "@glide/app-description";
import { definedMap } from "@glideapps/ts-necessities";

// FIXME: This really doesn't belong here,
export interface ShowToastActionDescription extends ActionDescription {
    readonly message: PropertyDescription | undefined;
    readonly icon: PropertyDescription | undefined;
}

export enum ToastIcon {
    Success = "success",
    Failure = "failure",
}

export function makeShowToastAction(message?: string, icon: ToastIcon = ToastIcon.Success): ShowToastActionDescription {
    return {
        kind: ActionKind.ShowToast,
        message: definedMap(message, makeStringProperty),
        icon: makeEnumProperty(icon),
    };
}

export function getEditOnSubmitActions(screen: EditScreenDescription): readonly ActionDescription[] {
    return screen.onSubmitActions ?? (isFormScreen(screen) ? [makeShowToastAction()] : []);
}
