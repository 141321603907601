/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { sleep, hasOwnProperty } from "@glideapps/ts-necessities";
import { isEmptyOrUndefinedish, maybeParseJSON } from "@glide/support";
import * as t from "io-ts";
import { isLeft } from "fp-ts/lib/Either";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

const apiKey = glide.makeParameter({
    type: "secret",
    name: "API key",
    required: true,
    description:
        "Learn how to get an API key [here](https://learn.microsoft.com/en-us/azure/cognitive-services/cognitive-services-apis-create-account?tabs=multiservice%2Canomaly-detector%2Clanguage-service%2Ccomputer-vision%2Clinux#create-a-new-azure-cognitive-services-resource).",
});

const endpointDomain = glide.makeParameter({
    type: "string",
    name: "Endpoint",
    placeholder: "e.g. https://abc123.cognitiveservices.azure.com",
    required: true,
    description:
        "This is the endpoint domain for your [Azure resource](https://learn.microsoft.com/en-us/azure/cognitive-services/cognitive-services-apis-create-account?tabs=multiservice%2Canomaly-detector%2Clanguage-service%2Ccomputer-vision%2Clinux#create-a-new-azure-cognitive-services-resource). This information can be found with your API key.",
});

export const plugin = glide.newPlugin({
    id: "azure-ml",
    name: "Azure",
    description: "Access machine learning models with Azure AI",
    tier: "starter",
    parameters: { apiKey, endpointDomain },
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/azure.png",
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/azure",
});

const image = glide.makeParameter({
    name: "Image",
    type: "string",
    description: "Choose the column that has the image to be described",
    required: true,
});

const description = glide.makeParameter({
    name: "Description",
    type: "string",
});

const denseCaptionResponseCodec = t.type({
    denseCaptionsResult: t.type({
        values: t.array(
            t.type({
                text: t.string,
            })
        ),
    }),
});

export async function getDenseCaptions(
    endpointDomain: string,
    context: Omit<glide.ServerExecutionContext, "uploadFile" | "rehostFile">,
    apiKey: string,
    image: string
) {
    const features = "denseCaptions";
    const url = `${endpointDomain}/computervision/imageanalysis:analyze?features=${features}&gender-neutral-caption=True&api-version=2023-04-01-preview`;
    const response = await context.fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": apiKey,
        },
        body: JSON.stringify({
            url: image,
        }),
    });

    let text: string = "";
    let json;
    try {
        text = await response.text();
        json = JSON.parse(text);
    } catch (e: unknown) {
        return glide.Result.Fail(`No json returned`, {
            status: response.status,
            data: text,
        });
    }

    if (!response.ok) {
        return glide.Result.Fail(`Could not get description | ${response.statusText}`, {
            status: response.status,
            data: json,
        });
    }

    if (hasOwnProperty(json, "error")) {
        return glide.Result.Fail(`Could not get description | ${json.error}`, {
            status: response.status,
            data: json,
        });
    }

    const decoded = denseCaptionResponseCodec.decode(json);
    if (isLeft(decoded)) {
        return glide.Result.Fail(`Could not parse json`, {
            data: json,
        });
    }
    const data = decoded.right.denseCaptionsResult.values.map(t => t.text).join("\n");

    return glide.Result.Ok(data);
}

const documentKVOCRResponseCodec = t.type({
    analyzeResult: t.intersection([
        t.type({
            keyValuePairs: t.array(
                t.union([
                    t.type({
                        key: t.type({ content: t.string }),
                    }),
                    t.type({
                        key: t.type({ content: t.string }),
                        value: t.type({ content: t.string }),
                    }),
                ])
            ),
        }),
        t.partial({
            tables: t.array(
                t.type({
                    cells: t.array(
                        t.intersection([
                            t.type({
                                rowIndex: t.number,
                                columnIndex: t.number,
                                content: t.string,
                            }),
                            t.partial({
                                kind: t.literal("columnHeader"),
                            }),
                        ])
                    ),
                })
            ),
        }),
    ]),
});

const documentOCRResponseCodec = t.type({
    analyzeResult: t.type({
        content: t.string,
    }),
});

export async function getDocumentKVOCR(
    endpointDomain: string,
    context: Omit<glide.ServerExecutionContext, "uploadFile" | "rehostFile">,
    apiKey: string,
    image: string
) {
    const url = `${endpointDomain}/formrecognizer/documentModels/prebuilt-document:analyze?api-version=2023-07-31`;
    const response = await context.fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": apiKey,
        },
        body: JSON.stringify({
            urlSource: image,
        }),
    });

    const text = await response.text(); // drain

    const url202 = response.headers.get("operation-location");
    if (response.status === 202 && url202 !== null) {
        for (let i = 0; i < 10; i++) {
            await sleep(i * 1000);
            const resp202 = await context.fetch(url202, {
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": apiKey,
                },
            });

            let resp202Text: string = "";
            let json;
            try {
                resp202Text = await resp202.text();
                json = JSON.parse(resp202Text);
            } catch (e: unknown) {
                return glide.Result.Fail(`No json returned`, {
                    status: resp202.status,
                    data: resp202Text,
                });
            }

            if (!resp202.ok) {
                return glide.Result.Fail(`Could not get description | ${resp202.statusText}`, {
                    status: resp202.status,
                    data: json,
                });
            }

            if (hasOwnProperty(json, "status")) {
                if (json.status === "running") {
                    continue;
                }
            }

            if (hasOwnProperty(json, "error")) {
                return glide.Result.Fail(`Could not get description | ${json.error}`, {
                    status: resp202.status,
                    data: json,
                });
            }
            const decoded = documentKVOCRResponseCodec.decode(json);
            if (isLeft(decoded)) {
                return glide.Result.Fail("Invalid json", { data: json });
            } else {
                const keyValuePairs = decoded.right.analyzeResult.keyValuePairs;

                const obj: Record<string, null | string | string[] | string[][][]> = {};
                for (const kv of keyValuePairs) {
                    const key = kv.key.content;
                    const content = hasOwnProperty(kv, "value") ? kv.value.content : null;
                    if (hasOwnProperty(obj, key)) {
                        const existing = obj[key];
                        if (Array.isArray(existing)) {
                            (obj[key] as any).push(content);
                        } else if (existing === null) {
                            obj[key] = content;
                        } else if (content !== null) {
                            obj[key] = [existing, content];
                        }
                    } else {
                        (obj[key] as any) = content;
                    }
                }
                const tables = decoded.right.analyzeResult.tables;
                if (tables !== undefined && tables.length > 0) {
                    obj["tables"] = tables.map(t => {
                        const rows = groupBy(
                            sortBy(t.cells, c => c.columnIndex),
                            c => c.rowIndex
                        );
                        return Object.values(rows).map(r => r.map(c => c.content));
                    });
                }
                const data = JSON.stringify(obj);

                return glide.Result.Ok(data);
            }
        }
    }

    return glide.Result.Fail(`Invalid response | ${response.statusText}`, { data: maybeParseJSON(text) });
}

export async function getDocumentOCR(
    endpointDomain: string,
    context: Omit<glide.ServerExecutionContext, "uploadFile" | "rehostFile">,
    apiKey: string,
    image: string
) {
    const url = `${endpointDomain}/formrecognizer/documentModels/prebuilt-read:analyze?api-version=2023-07-31`;
    const response = await context.fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": apiKey,
        },
        body: JSON.stringify({
            urlSource: image,
        }),
    });

    const text = await response.text(); // drain

    const url202 = response.headers.get("operation-location");
    if (response.status === 202 && url202 !== null) {
        for (let i = 0; i < 10; i++) {
            await sleep(i * 1000);
            const resp202 = await context.fetch(url202, {
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": apiKey,
                },
            });

            let resp202Text: string = "";
            let json;
            try {
                resp202Text = await resp202.text();
                json = JSON.parse(resp202Text);
            } catch (e: unknown) {
                return glide.Result.Fail(`No json returned`, {
                    status: resp202.status,
                    data: resp202Text,
                });
            }

            if (!resp202.ok) {
                return glide.Result.Fail(`Could not get description | ${resp202.statusText}`, {
                    status: resp202.status,
                    data: json,
                });
            }

            if (hasOwnProperty(json, "status")) {
                if (json.status === "running") {
                    continue;
                }
            }

            if (hasOwnProperty(json, "error")) {
                return glide.Result.Fail(`Could not get description | ${json.error}`, {
                    status: resp202.status,
                    data: json,
                });
            }

            const decoded = documentOCRResponseCodec.decode(json);
            if (isLeft(decoded)) {
                return glide.Result.Fail("Bad response", { data: json });
            } else {
                const data = decoded.right.analyzeResult.content;

                return glide.Result.Ok(data);
            }
        }
    }

    return glide.Result.FailFromHTTPStatus(`Invalid response | ${response.statusText}`, response.status, {
        data: maybeParseJSON(text),
    });
}

plugin.addComputation({
    id: "blip-2",
    name: "Describe image",
    description: "Use AI to describe an image",
    billablesConsumed: 1,
    parameters: {
        image,
    },
    results: { description },
    execute: async (context, { image = "", apiKey = "", endpointDomain }) => {
        if (isEmptyOrUndefinedish(endpointDomain)) {
            return glide.Result.Fail(`Please configure a domain in your integrations settings`, {
                isPluginError: false,
            });
        }
        const result = await context.useCache(async () => {
            const apiResult = await getDenseCaptions(endpointDomain, context, apiKey, image);

            if (apiResult.ok === true) {
                context.consumeBillable();
            }
            return apiResult;
        }, [image, apiKey]);

        if (!result.ok) return result;

        return glide.Result.Ok({ description: result.result });
    },
});
