/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

export const plugin = glide.newPlugin({
    id: "mixpanel", // DO NOT CHANGE
    name: "Mixpanel",
    description: "Track user behavior with Mixpanel",

    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/mixpanel.png",
    tier: "starter",
    parameters: {
        apiToken: glide.makeParameter({
            type: "string",
            name: "Project token",
            description:
                "Your project token is in your Mixpanel [project settings](https://mixpanel.com/settings/project/)",
            required: true,
        }),
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/mixpanel",
});

const event = glide.makeParameter({
    type: "string",
    name: "Event name",
    required: true,
    useTemplate: "withLabel",
});

const eventProperties = glide.makeParameter({
    type: "stringObject",
    name: "Properties",
    description: "Event properties",
});

plugin.addClientAction({
    id: "send-mixpanel-event",
    name: "Track custom event",
    description: "Send event to Mixpanel API",
    parameters: { event, eventProperties },
    needsClient: true,

    async execute(_context, { event, eventProperties }) {
        const { mixpanel } = window as any;

        if (mixpanel === undefined) {
            return glide.Result.Fail("Cannot track, mixpanel not initialized.", {
                isPluginError: false, // happens with ad blockers
            });
        }

        mixpanel.track(event, eventProperties);

        return glide.Result.Ok();
    },
});

plugin.setEventTracker((_params, event) => {
    const anyWindow = window as any;
    if (anyWindow.mixpanel === undefined) return;
    switch (event.kind) {
        case "load":
            anyWindow.mixpanel.track("load");
            break;
        case "identify":
            anyWindow.mixpanel.identify(event.userID);
            anyWindow.mixpanel.register({
                username: event.username,
                email: event.email,
            });
            break;
        case "navigate":
            anyWindow.mixpanel.track("navigate", {
                title: event.title,
            });
            break;
        case "action":
            anyWindow.mixpanel.track(event.name, event.data);
            break;
    }
});

plugin.addHeader(
    ({ apiToken }) => `<script type="text/javascript">
    (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
    for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
    MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
    
    // Enabling the debug mode flag is useful during implementation,
    // but it's recommended you remove it for production
    mixpanel.init('${apiToken}', {debug: false}); 
    </script>`
);
