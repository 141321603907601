import type { CoreTheme } from "@glide/theme";
import { TailwindThemeProvider as InnerTailwindThemeProvider } from "@glide/theme";
import type { PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";

interface Props extends PropsWithChildren {
    theme: CoreTheme;
    setPortal?: boolean;
    setModalRoot?: boolean;
    className?: string;
}

/**
 * We can't use the TailwindThemeProvider from @glideapps/theme directly because _some_ legacy components
 * still use styled-components.
 * We don't want to bring styled-components into the clean `components` repo, so we're forced to use this wrapper.
 *
 * TODO: Add a lint rule or something to prevent people from directly using the theme provider from @glideapps/theme
 */
export const TailwindThemeProvider: React.FC<React.PropsWithChildren<Props>> = p => {
    const { theme, setPortal, setModalRoot, className, children } = p;

    return (
        <InnerTailwindThemeProvider
            theme={theme}
            setPortal={setPortal}
            setModalRoot={setModalRoot}
            className={className}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </InnerTailwindThemeProvider>
    );
};
