import * as glide from "@glide/plugins";

export const plugin = glide.newPlugin({
    id: "intercom",
    name: "Intercom",
    description: "Add Intercom chat to your app",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/intercom.png",
    tier: "starter",
    parameters: {
        appID: glide.makeParameter({
            type: "string",
            name: "Workspace ID",
            description: "This is the app_id of your Intercom app",
            required: true,
            useTemplate: "withLabel",
        }),
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/intercom",
});

plugin.addHeader({
    getSnippet: ({ appID }) => `<script>
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "${appID}",
      vertical_padding: window.innerWidth <= 640 ? 85 : 20,
    };
  </script>
  
  <script>
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/${appID}'
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${appID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  </script>`,
});

plugin.setEventTracker((_params, event) => {
    const anyWindow = window as any;
    if (anyWindow.Intercom === undefined) return;
    switch (event.kind) {
        case "identify":
            anyWindow.Intercom("update", {
                user_id: event.userID,
                email: event.email,
                name: event.username,
            });
    }
});
