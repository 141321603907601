// This is the "old" barcode scanner, which at this point is always marked legacy

import { AppKind } from "@glide/location-common";
import { type LoadedRow, isLoadingValue } from "@glide/computation-model-types";
import { type ActionDescription, type PropertyDescription, ActionKind } from "@glide/app-description";
import { sheetNameForTable } from "@glide/type-schema";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import { CodeScannerStandard, type CodeScannerStandards } from "@glide/component-utils";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
    codeScannerScreenName,
    liveLinearBarcodeScannerScreenName,
    mustUseDynamsoftScanner,
    getPrimitiveNonHiddenColumnsSpec,
    type ActionAvailability,
} from "@glide/function-utils";
import {
    type WireActionHydrator,
    type WireActionInflationBackend,
    type WireActionResult,
    type WireActionResultBuilder,
    PageScreenTarget,
} from "@glide/wire";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { type GlideIconProps, type PluginTierList, makeTierList } from "@glide/plugins";
import type { StaticActionContext } from "../static-context";
import { ICON_PALE } from "../plugins/icon-colors";

interface ScanCodeConfigDescription {
    readonly useExtendedEAN: PropertyDescription;
    readonly scanForQrCode: PropertyDescription;
}

interface ScanCodeActionDescription extends ActionDescription, ScanCodeConfigDescription {
    readonly output: PropertyDescription;
}

const columnPropertyHandler = new ColumnPropertyHandler(
    "output",
    "Column",
    [
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    ["qrcode", "barcode", "sku"],
    getPrimitiveNonHiddenColumnsSpec,
    "string",
    PropertySection.Data
);

const extendedEANHandler = new SwitchPropertyHandler(
    { useExtendedEAN: false },
    "Scan for EAN extensions",
    PropertySection.Data
);

const qrScanHandler = new SwitchPropertyHandler({ scanForQrCode: false }, "Scan for QR Codes", PropertySection.Data);

const appFeatureHandlers = [extendedEANHandler, qrScanHandler];

function getStandardsForConfig(desc: ScanCodeActionDescription, appKind: AppKind): CodeScannerStandards | undefined {
    // This is stuff for the old barcode scanner which is only supported if
    // we're not forcing Dynamsoft.
    if (mustUseDynamsoftScanner(appKind)) return undefined;

    const standards = [CodeScannerStandard.EAN_13, CodeScannerStandard.EAN_8, CodeScannerStandard.UPC_E];
    if (extendedEANHandler.getSwitch(desc)) {
        standards.push(CodeScannerStandard.EAN_5, CodeScannerStandard.EAN_2);
    }
    standards.push(
        CodeScannerStandard.Code39,
        CodeScannerStandard.Code39_VIN,
        CodeScannerStandard.Code128,
        CodeScannerStandard.i2of5
    );
    if (qrScanHandler.getSwitch(desc)) {
        standards.push(CodeScannerStandard.QR);
    }
    return standards;
}

export function inflateScanBarcodeAction(
    ib: WireActionInflationBackend,
    columnProperty: PropertyDescription,
    standards: CodeScannerStandards | undefined,
    arb: WireActionResultBuilder
): WireActionHydrator | WireActionResult {
    arb = arb.addData({ tableName: sheetNameForTable(ib.tables.output), standards });

    const { tableAndColumn, setterMaker } = ib.getValueSetterForProperty(columnProperty, "set-barcode");
    if (tableAndColumn === undefined) return arb.inflationError("Invalid column");

    const isMutatingScreen = ib.mutatingScreenKind !== undefined;

    return (vp, skipLoading) => {
        const setter = setterMaker(vp);
        if (isLoadingValue(setter)) return arb.errorIfSkipLoading(skipLoading, "Destination column");
        if (setter === undefined) return arb.error(true, "Cannot write to column");

        const row: LoadedRow = {
            $rowID: makeRowID(),
            $isVisible: false,
            standards,
            acceptOnFirst: isMutatingScreen,
            // The scanner page will set this to the scanned value if the
            // scan was successful.
            value: undefined,
        };

        return ab => {
            return new Promise(resolve => {
                try {
                    ab.addSpecialScreenRow(row);
                    ab.pushFreeScreen(
                        standards !== undefined ? codeScannerScreenName : liveLinearBarcodeScannerScreenName,
                        [row],
                        "",
                        ib.adc.appKind === AppKind.App ? PageScreenTarget.LargeModal : PageScreenTarget.SmallModal,
                        undefined,
                        async () => {
                            try {
                                if (row.value === undefined) return resolve(arb.error(true, "No value for barcode"));
                                const result = await setter(ab, row.value);
                                return resolve(arb.fromResult(result));
                            } catch (e: unknown) {
                                return resolve(arb.fromException(true, e));
                            }
                        }
                    );
                } catch (e: unknown) {
                    return resolve(arb.fromException(true, e));
                }
            });
        };
    };
}

export class ScanCodeActionHandler extends BaseActionHandler<ScanCodeActionDescription> {
    public readonly kind = ActionKind.ScanCode;

    public readonly iconName: GlideIconProps = {
        icon: "st-scan-barcode",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Scan barcode or QR code";

    public get handlesSequencing(): boolean {
        return true;
    }

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getTier(appKind: AppKind): PluginTierList | undefined {
        if (mustUseDynamsoftScanner(appKind)) {
            return makeTierList("business");
        } else {
            return makeTierList("starter");
        }
    }

    public getDescriptor(
        _desc: ScanCodeActionDescription | undefined,
        { context: ccc }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const { appKind } = ccc;
        const mustUseDynamsoft = mustUseDynamsoftScanner(appKind);
        const properties: PropertyDescriptor[] = [columnPropertyHandler];
        if (!mustUseDynamsoft) {
            properties.push(...appFeatureHandlers);
        }

        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 16,
            needsScreenContext: true,
            isLegacy: true,
            properties,
        };
    }

    public getTokenizedDescription(
        desc: ScanCodeActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const token = tokenForProperty(desc.output, env);
        if (token === undefined) return undefined;
        return [{ kind: "string", value: "into " }, token];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: ScanCodeActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const standards = getStandardsForConfig(desc, ib.adc.appKind);
        return inflateScanBarcodeAction(ib, desc.output, standards, arb);
    }
}
