import { Img } from "@glide/wire-renderer";
import type { IconName } from "@glide/common";
import {
    AppIcon,
    getNamedIcon,
    GlideIcon,
    isMonotoneIcon,
    isStrokeIcon,
    maybeGetHeaderIconColorForMonotoneIcon,
} from "@glide/common";
import { ThemedIcon } from "../themed-icon/themed-icon";

interface IconRendererProps {
    readonly icon: string;
    readonly size?: number;
    readonly className?: string;
}

export const IconRenderer: React.FC<IconRendererProps> = p => {
    const { icon, size, className } = p;
    if (icon.startsWith("https://") || icon.startsWith("/")) {
        return <Img src={icon} tw="w-4 h-4 rounded" className={className} />;
    } else if (isMonotoneIcon(icon)) {
        const maybeColors = maybeGetHeaderIconColorForMonotoneIcon(icon);
        return <GlideIcon iconSize={size ?? 16} kind="monotone" icon={icon} className={className} {...maybeColors} />;
    } else if (isStrokeIcon(icon)) {
        return <GlideIcon iconSize={size ?? 16} kind="stroke" icon={icon} className={className} />;
    } else if (getNamedIcon(icon as IconName) !== undefined) {
        return <ThemedIcon icon={icon as IconName} className={className} size={size} />;
    } else {
        return <AppIcon size={size ?? 16} icon={icon} className={className} />;
    }
};
