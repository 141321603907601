import type { WireListCalendarCollection } from "@glide/fluent-components/dist/js/base-components";
import { UIStyleVariant } from "@glide/wire";
import * as React from "react";

import { extractActions } from "../../wire-lib";

import {
    WireListContainer,
    useDynamicFilter,
    useMultipleDynamicFilters,
    useSearchBar,
} from "../wire-list-container/wire-list-container";
import type { WireRenderer } from "../wire-renderer";

const DragAndDropCalendar = React.lazy(() => import("./lazy-calendar"));

type WireListCalendarCollectionRenderer = WireRenderer<WireListCalendarCollection, { isFirstComponent: boolean }>;

export const CalendarCollectionRenderer: WireListCalendarCollectionRenderer = React.memo(p => {
    const {
        backend,
        titleStyle,
        groups,
        newItemEnd,
        newItemStart,
        submitNewItem,
        componentTitle,
        titleActions,
        viewDate,
        viewMode,
        defaultGroup,
        searchBar,
        allowAdd,
        newItemTitle,
        isFirstComponent,
    } = p;
    const pageIndexToken = p.paging?.pageIndex.onChangeToken;

    const filterArgs = useDynamicFilter(p.dynamicFilter, backend);
    const multipleFilterProps = useMultipleDynamicFilters(p.multipleDynamicFilters, backend);
    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, pageIndexToken);

    return (
        <WireListContainer
            title={componentTitle ?? ""}
            titleStyle={titleStyle}
            titleActions={extractActions(titleActions, backend)}
            {...filterArgs}
            multipleFilterProps={multipleFilterProps}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            styleVariant={UIStyleVariant.Default}
            appKind={backend.appKind}
            isFirstComponent={isFirstComponent}>
            <React.Suspense
                fallback={
                    <div tw="h-full [min-height:420px] [max-height:640px] gp-md:([min-height:640px] [max-height:800px]) gp-2xl:(height[800px])" />
                }>
                <DragAndDropCalendar
                    newItemEnd={newItemEnd}
                    newItemStart={newItemStart}
                    submitNewItem={submitNewItem}
                    viewDate={viewDate}
                    viewMode={viewMode}
                    defaultGroup={defaultGroup}
                    allowAdd={allowAdd}
                    newItemTitle={newItemTitle}
                    backend={backend}
                    groups={groups}
                />
            </React.Suspense>
        </WireListContainer>
    );
});
