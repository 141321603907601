import { ConfirmModalStyle, showConfirmModal } from "@glide/common-core/dist/js/components/confirm-modal";
import { formatLocalizedString, getLocalizedString } from "@glide/localization";
import {
    type MinimalAppFacilities,
    type UploadFileResponse,
    type UploadProgressHandler,
    type UploadSession,
    isUploadFileResponseError,
} from "@glide/common-core/dist/js/components/types";
import {
    FileSizeLimitExceededError,
    uploadFileIntoGlideStorage,
} from "@glide/common-core/dist/js/components/upload-handlers";
import type { UploadComponentKind } from "@glide/common-core/dist/js/firebase-function-types";
import type { AppKind } from "@glide/location-common";
import { formatFileSize } from "@glide/support";

function getConfirmModalStringsForFailedUpload(
    component: UploadComponentKind,
    appKind: AppKind
): { title: string; description: string } {
    if (component === "signature") {
        return {
            title: getLocalizedString("cantAddNewSignatures", appKind),
            description: getLocalizedString("thisAppMustBeUpgradedToAllowMoreSignatures", appKind),
        };
    } else {
        return {
            title: getLocalizedString("thisActionCouldntBeTriggered", appKind),
            description: getLocalizedString("thisAppMustBeUpgraded", appKind),
        };
    }
}

export function uploadAppFile(
    appID: string,
    appKind: AppKind,
    appFacilities: MinimalAppFacilities,
    file: File,
    component: UploadComponentKind,
    onProgress: UploadProgressHandler | undefined,
    onFeedback: () => void,
    includeFilename: boolean
): UploadSession {
    appFacilities.trackEvent?.("upload_file", { app_id: appID, content_type: file.type, content_length: file.size });

    const onAttemptResponse = async (resp: UploadFileResponse) => {
        if (!isUploadFileResponseError(resp)) {
            appFacilities.trackEvent?.("upload success", { app_id: appID, label: component });
            return;
        }
        let pushFeedback = false;

        if (resp.quotaExceeded) {
            appFacilities.trackEvent?.("upload over quota", { app_id: appID, label: component });
            pushFeedback = !(
                (await showConfirmModal({
                    ...getConfirmModalStringsForFailedUpload(component, appKind),
                    accept: getLocalizedString("ok", appKind),
                    cancel: getLocalizedString("support", appKind),
                    style: ConfirmModalStyle.QuotaWarning,
                })) ?? true
            );
        } else if (resp.error instanceof FileSizeLimitExceededError) {
            const { givenSize, maxSize } = resp.error;
            pushFeedback = !(
                (await showConfirmModal({
                    title: getLocalizedString("fileTooLargeTitle", appKind),
                    description: formatLocalizedString(
                        "fileTooLargeDescription",
                        [givenSize, maxSize].map(x => formatFileSize(x)),
                        appKind
                    ),
                    accept: getLocalizedString("ok", appKind),
                    cancel: getLocalizedString("support", appKind),
                    style: ConfirmModalStyle.QuotaWarning,
                })) ?? true
            );
        }

        if (pushFeedback) {
            onFeedback();
            return;
        }

        appFacilities.trackEvent?.("upload failed", { app_id: appID, label: component });
    };
    return uploadFileIntoGlideStorage(
        appFacilities,
        appID,
        file,
        component,
        onProgress,
        onAttemptResponse,
        includeFilename
    );
}
