import * as glide from "@glide/plugins";

// Add type information for Koala's window object
declare global {
    interface Window {
        ko:
            | {
                  track: (event: string, properties?: Record<string, any>) => void;
                  identify: (userId: string, properties?: Record<string, any>) => void;
                  push?: (args: any[]) => void;
              }
            | undefined;
    }
}

export const plugin = glide.newPlugin({
    id: "koala",
    name: "Koala",
    description: "Track user behavior with Koala",
    icon: "https://getkoala.com/favicon.ico", // Using Koala's favicon as icon
    tier: "starter",
    parameters: {
        publicKey: glide.makeParameter({
            type: "secret",
            name: "Public Key",
            description: "Your Koala public key from your project settings",
            required: true,
        }),
    },
    documentationUrl: "https://www.glideapps.com/docs/koala",
});

// Add the Koala tracking snippet as a header
plugin.addHeader(
    ({ publicKey }) => `<script>
    !function(t){if(window.ko)return;window.ko=[],["identify","track","removeListeners","open","on","off","qualify","ready"].forEach(function(t){ko[t]=function(){var n=[].slice.call(arguments);return n.unshift(t),ko.push(n),ko}});var n=document.createElement("script");n.async=!0,n.setAttribute("src","https://cdn.getkoala.com/v1/${publicKey}/sdk.js"),(document.body||document.head).appendChild(n)}();
    </script>`
);

// Add event tracking similar to Mixpanel
plugin.setEventTracker((_params, event) => {
    const ko = window.ko;
    if (ko === undefined) return;

    switch (event.kind) {
        case "load":
            ko.track("load");
            break;
        case "identify":
            ko.identify(event.userID, {
                username: event.username,
                email: event.email,
            });
            break;
        case "navigate":
            ko.track("navigate", {
                title: event.title,
            });
            break;
        case "action":
            ko.track(event.name, event.data);
            break;
    }
});

// Add custom event tracking action
const event = glide.makeParameter({
    type: "string",
    name: "Event name",
    required: true,
    useTemplate: "withLabel",
});

const eventProperties = glide.makeParameter({
    type: "stringObject",
    name: "Properties",
    description: "Event properties",
});

plugin.addClientAction({
    id: "send-koala-event",
    name: "Track custom event",
    description: "Send event to Koala API",
    parameters: { event, eventProperties },
    needsClient: true,

    async execute(_context, { event: eventName, eventProperties: properties }) {
        const ko = window.ko;
        if (ko === undefined) {
            return glide.Result.FailPermanent("Cannot track, Koala not initialized.", {
                isPluginError: false,
            });
        }

        if (eventName === undefined) {
            return glide.Result.FailPermanent("Event name is required", {
                isPluginError: false,
            });
        }

        ko.track(eventName, properties ?? {});
        return glide.Result.Ok();
    },
});
