// The reason these icons are separate from GlideIcons is because they are special one-off icons that have gradients

export const BusinessTeamIcon = (
    <svg id="icon-import" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="test-icon">
        <path
            opacity="0.3"
            d="M40 12C42.2091 12 44 13.7909 44 16V36C44 38.2091 42.2091 40 40 40H8C5.79086 40 4 38.2091 4 36L4 16C4 13.7909 5.79086 12 8 12H40Z"
            fill="#12CCE6"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M44 35.9167V26H4V35.9167C4 38.1718 5.79086 40 8 40H40C42.2091 40 44 38.1718 44 35.9167Z"
            fill="url(#paint0_linear_3297_1045)"
            vectorEffect="non-scaling-stroke"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 8C18.8954 8 18 8.89543 18 10V12H14V10C14 6.68629 16.6863 4 20 4H28C31.3137 4 34 6.68629 34 10V12H30V10C30 8.89543 29.1046 8 28 8H20Z"
            fill="#12CCE5"
            vectorEffect="non-scaling-stroke"
        />
        <defs>
            <linearGradient
                id="paint0_linear_3297_1045"
                x1="40.5714"
                y1="40"
                x2="4.57143"
                y2="40"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#12CCE5" />
                <stop offset="1" stopColor="#12CCE5" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export const EnterpriseTeamIcon = (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="test-icon">
        <path
            d="M28 42V10.1937C28 8.01008 26.0223 6.35957 23.8739 6.7502L8.8739 9.47747C7.20968 9.78006 6 11.2295 6 12.921V38.5C6 40.433 7.567 42 9.5 42H28Z"
            fill="#015561"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M28 18H38C40.2091 18 42 19.7909 42 22V38.5C42 40.433 40.433 42 38.5 42H28V18Z"
            fill="url(#paint0_linear_3297_1046)"
            vectorEffect="non-scaling-stroke"
        />
        <defs>
            <linearGradient
                id="paint0_linear_3297_1046"
                x1="43"
                y1="16"
                x2="28"
                y2="42.5"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#007D8E" />
                <stop offset="1" stopColor="#007D8E" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export const FreeTeamIcon = (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="test-icon">
        <path
            d="M22 26C22 14.9543 30.9543 6 42 6V22C42 33.0457 33.0457 42 22 42V26Z"
            fill="#12CCE6"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M6 21C13.732 21 20 27.268 20 35V42C12.268 42 6 35.732 6 28V21Z"
            fill="url(#paint0_linear_3297_1056)"
            vectorEffect="non-scaling-stroke"
        />
        <defs>
            <linearGradient id="paint0_linear_3297_1056" x1="13" y1="21" x2="13" y2="42" gradientUnits="userSpaceOnUse">
                <stop stopColor="#12CCE6" />
                <stop offset="1" stopColor="#12CCE6" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export const ProTeamIcon = (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="test-icon">
        <path
            d="M20.1243 11.069C20.7686 9.32778 23.2314 9.32778 23.8757 11.069L27.0827 19.7357C27.2852 20.2831 27.7169 20.7148 28.2643 20.9173L36.931 24.1243C38.6722 24.7686 38.6722 27.2314 36.931 27.8757L28.2643 31.0827C27.7169 31.2852 27.2852 31.7169 27.0827 32.2643L23.8757 40.931C23.2314 42.6722 20.7686 42.6722 20.1243 40.931L16.9173 32.2643C16.7148 31.7169 16.2831 31.2852 15.7357 31.0827L7.06901 27.8757C5.32778 27.2314 5.32778 24.7686 7.06901 24.1243L15.7357 20.9173C16.2831 20.7148 16.7148 20.2831 16.9173 19.7357L20.1243 11.069Z"
            fill="#12CCE6"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M34.3588 6.43539C34.9225 4.91181 37.0775 4.9118 37.6412 6.43539L38.4217 8.54442C38.5989 9.02343 38.9766 9.4011 39.4556 9.57835L41.5646 10.3588C43.0882 10.9225 43.0882 13.0775 41.5646 13.6412L39.4556 14.4217C38.9766 14.5989 38.5989 14.9766 38.4217 15.4556L37.6412 17.5646C37.0775 19.0882 34.9225 19.0882 34.3588 17.5646L33.5783 15.4556C33.4011 14.9766 33.0234 14.5989 32.5444 14.4217L30.4354 13.6412C28.9118 13.0775 28.9118 10.9225 30.4354 10.3588L32.5444 9.57835C33.0234 9.4011 33.4011 9.02343 33.5783 8.54442L34.3588 6.43539Z"
            fill="url(#paint0_linear_3297_1044)"
            vectorEffect="non-scaling-stroke"
        />
        <defs>
            <linearGradient id="paint0_linear_3297_1044" x1="36" y1="2" x2="36" y2="22" gradientUnits="userSpaceOnUse">
                <stop stopColor="#12CCE5" />
                <stop offset="1" stopColor="#12CCE5" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export const StarterTeamIcon = (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="test-icon">
        <path
            opacity="0.3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42 10C42 7.79086 40.2091 6 38 6H34C31.7909 6 30 7.79086 30 10V14C30 16.2091 28.2091 18 26 18H22C19.7909 18 18 19.7909 18 22V26C18 28.2091 16.2091 30 14 30H10C7.79086 30 6 31.7909 6 34V38C6 40.2091 7.79086 42 10 42H38C40.2091 42 42 40.2091 42 38V30V18V10Z"
            fill="#12CCE6"
            vectorEffect="non-scaling-stroke"
        />
        <rect x="6" y="30" width="36" height="12" rx="3.5" fill="url(#paint0_linear_3297_1043)" />
        <defs>
            <linearGradient
                id="paint0_linear_3297_1043"
                x1="9.9375"
                y1="30"
                x2="40.3125"
                y2="30"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#12CCE6" />
                <stop offset="1" stopColor="#12CCE6" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export const FixedUpdatesIcon = (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="test-icon">
        <rect x="4.5" y="4" width="32" height="32" rx="9" fill="#E6F9FC" />
        <path
            opacity="0.5"
            d="M4 17L37 17L37 26.5C37 31.4706 32.9706 35.5 28 35.5H13C8.02944 35.5 4.00001 31.4706 4.00001 26.5L4 17Z"
            fill="url(#paint0_linear_740_15315)"
        />
        <path d="M5 18L36 18" stroke="#12CCE6" strokeWidth="3" strokeLinecap="round" />
        <defs>
            <linearGradient
                id="paint0_linear_740_15315"
                x1="35.4531"
                y1="36"
                x2="35.4531"
                y2="3.5"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#12CCE6" />
                <stop offset="1" stopColor="#12CCE6" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export const ScaledUpdatesIcon = (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="test-icon">
        <rect x="4.5" y="4" width="32" height="32" rx="9" fill="#E6F9FC" />
        <path
            opacity="0.3"
            d="M27.5 26C32.5 19.5 37 3.5 37 3.5L37 26.5C37 31.4706 32.9706 35.5 28 35.5L7.3053 35.5C5.47983 35.5 4 34.0202 4 32.1947C4 31.5212 4.50907 30.9617 5.18006 30.9038C9.62373 30.5201 25.2122 28.9742 27.5 26Z"
            fill="url(#paint0_linear_740_15339)"
        />
        <path
            d="M4.5 31.9753C4.5 31.9753 16.4231 32.5937 25.9615 27.646C35.5 22.6984 35.5 6 35.5 6"
            stroke="#12CCE6"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_740_15339"
                x1="35.4531"
                y1="36"
                x2="35.4531"
                y2="3.5"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#12CCE6" />
                <stop offset="1" stopColor="#12CCE6" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export const TrialIcon = (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="test-icon">
        <circle cx="19.9999" cy="19.9997" r="16.6667" fill="#12CCE6" fillOpacity="0.3" />
        <circle cx="19.9999" cy="19.9997" r="16.6667" fill="url(#paint0_linear_156_18354)" />
        <path
            d="M20.0002 8.33301V19.9997L25.0002 24.9997"
            stroke="#F5F6F7"
            strokeWidth="3.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_156_18354"
                x1="19.9999"
                y1="3.33301"
                x2="19.9999"
                y2="36.6663"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#12CCE6" />
                <stop offset="1" stopColor="#12CCE6" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);
