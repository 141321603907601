import {
    type ColumnType,
    type TableColumn,
    type TableGlideType,
    getTableColumn,
    isPrimitiveType,
} from "@glide/type-schema";
import {
    type LegacyPropertyDescription,
    type PropertyDescription,
    getColumnProperty,
    makeColumnProperty,
} from "@glide/app-description";

function columnCompatible(
    columnName: string,
    table: TableGlideType,
    isCompatible: (c: TableColumn) => boolean
): boolean {
    const column = getTableColumn(table, columnName);
    if (column === undefined) return false;
    return isCompatible(column);
}

export function rewriteColumn(
    pd: LegacyPropertyDescription,
    table: TableGlideType,
    isCorrectType: (t: ColumnType) => boolean
): PropertyDescription | undefined {
    const columnName = getColumnProperty(pd);
    if (columnName === undefined) return undefined;
    return columnCompatible(columnName, table, c => isCorrectType(c.type)) ? makeColumnProperty(columnName) : undefined;
}

// FIXME: This should be deprecated in favor of using `ColumnDescriptionHandler`
export function rewritePrimitiveColumn(
    columnName: LegacyPropertyDescription,
    table: TableGlideType
): PropertyDescription | undefined {
    return rewriteColumn(columnName, table, isPrimitiveType);
}
