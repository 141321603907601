import { createGlobalStyle } from "./styles";
// Only used by Story-utils
export const GlobalStyle = createGlobalStyle`
    body {
        padding: 0;
        margin: 0;
        /* TODO make this themable and move it into App */
        box-sizing: border-box;
        background-color: transparent;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        text-size-adjust: 100%;

        color: rgba(0, 0, 0, 0.9);

        font-size: 16px;
    }

    *, 
    *::after, 
    *::before {
        outline: none !important;
        margin: 0;
        padding: 0;
        box-sizing: inherit;

        /* this hack makes android not auto size because we do it ourselves */
        max-height: 9999999px;
    }

    a {
        text-decoration: none !important;
        color: inherit;
    }

    input {
        appearance: none;
        background: none;
    }

    button {
        appearance: none;
        border-top-width: 0;
        border-left-width: 0;
        border-bottom-width: 0;
        border-right-width: 0;
        background: none;
        font: inherit;
        color: inherit;
    }

    .scrollbar-hide {
      /* Firefox */
      scrollbar-width: none;

      /* Safari and Chrome */
      &::-webkit-scrollbar {
        display: none;
      }
    }
`;
