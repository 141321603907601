import { nonNull } from "@glide/support";
import * as React from "react";
import ReactDOM from "react-dom";
import type { Position, TooltipRect } from "./tooltip-style";
import { TooltipStyle } from "./tooltip-style";

interface Props<T extends HTMLElement> {
    show?: boolean;
    target: React.RefObject<T>;
    width?: number;
    position?: Position;
    longWord?: boolean;
    zIndex?: number;
    delayMS?: number | boolean;
}

export function Tooltip<T extends HTMLElement>(p: React.PropsWithChildren<Props<T>>) {
    const { target, show, width, position, longWord, zIndex, children, delayMS } = p;

    const [visible, setVisible] = React.useState(false);
    const [bounds, setBounds] = React.useState<TooltipRect>({
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
    });

    let delay = 50;
    if (delayMS === true) {
        delay = 500;
    } else if (typeof delayMS === "number") {
        delay = delayMS;
    }

    React.useEffect(() => {
        const updateBounds = () => {
            const node = target.current;
            if (node !== null && node instanceof Element) {
                const newBounds = node.getBoundingClientRect();
                setBounds(b => {
                    if (
                        newBounds?.left !== b?.left ||
                        newBounds?.right !== b?.right ||
                        newBounds?.top !== b?.top ||
                        newBounds?.bottom !== b?.bottom
                    ) {
                        return newBounds;
                    } else {
                        return b;
                    }
                });
            }
        };

        let enterTimeoutID: ReturnType<typeof setTimeout> | undefined;
        let leaveTimeoutID: ReturnType<typeof setTimeout> | undefined;

        const onMouseEnter = () => {
            if (leaveTimeoutID !== undefined) {
                clearTimeout(leaveTimeoutID);
            }

            enterTimeoutID = setTimeout(() => {
                setVisible(true);
                updateBounds();
            }, delay);
        };

        const onMouseLeave = () => {
            if (enterTimeoutID !== undefined) {
                clearTimeout(enterTimeoutID);
            }

            leaveTimeoutID = setTimeout(() => {
                setVisible(false);
            }, delay);
        };

        const node = target.current;
        if (node && node !== null && node instanceof Element) {
            node.addEventListener("mouseenter", onMouseEnter);
            node.addEventListener("mouseleave", onMouseLeave);
            node.addEventListener("focus", onMouseEnter);
            node.addEventListener("blur", onMouseLeave);
        }

        return () => {
            if (enterTimeoutID !== undefined) {
                clearTimeout(enterTimeoutID);
            }

            if (leaveTimeoutID !== undefined) {
                clearTimeout(leaveTimeoutID);
            }

            if (node !== null && node instanceof Element) {
                node.removeEventListener("mouseenter", onMouseEnter);
                node.removeEventListener("mouseleave", onMouseLeave);
            }
        };
    }, [delay, target]);

    const isLongWord = longWord !== undefined && longWord;

    return ReactDOM.createPortal(
        <TooltipStyle
            data-testid={`glideTooltip${visible ? "Visible" : "Hidden"}`}
            visible={visible && show !== false}
            bounds={bounds}
            width={width ?? 280}
            position={position ?? "right"}
            longWord={isLongWord}
            zIndex={zIndex}>
            {children}
        </TooltipStyle>,
        nonNull(document.getElementById("portal"))
    );
}
