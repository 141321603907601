import type { OAuthProvider, ParameterRecord, ServerExecutionContext, ValidationLog } from "@glide/plugins";
import { exceptionToString } from "@glideapps/ts-necessities";
import { base64URLEncodeNodeBuffer } from "@glide/support";

import type { OAuthProofKeyChallengeMethod } from "./oauth";

// client side only part of oauth plugin
export interface OAuthUserData {
    readonly uid: string;
    readonly email?: string;
}

export interface OAuthClientPlugin {
    /**
     * The unique name of the OAuth provider.
     */
    readonly canonicalName: OAuthProvider;
    /**
     * The display name of the OAuth provider.
     */
    readonly displayName: string;
    /**
     * Whether the plugin needs to use a special hack when redirecting to localhost via localhostr.
     * This is normally needed if the OAuth provider disallows unsecure http as a redirect uri
     */
    readonly needsRedirectHackForLocalhost?: boolean;
    /**
     * If true, forces the stage 2 redirect through a deployed instance.
     *
     * This is particularly necessary for OAuth implementations that only support one redirect URL.
     * Setting this to `true` causes the redirect to go through a deployed instance, which will then
     * redirect back to `localhost:3000` for development purposes.
     *
     * This flag is only respected if `stage1.proofKeyChallengeMethod` is set. The deployed instance
     * can only trust that the authorization code won't be stolen if the OAuth flow was using
     * Proof Key for Code Exchange mechanisms.
     */
    readonly forceStage2RedirectThroughDeployed?: boolean;

    /**
     * The authentication method used when attempting to refresh an access token
     * via the OAuth token endpoint.
     */
    readonly refreshAuthMethod?: "post-auth";

    /**
     * If set to `true`, the redirect URL will be suffixed with `for-owner`
     * whenever the auth scope is for an owner.
     */
    readonly suffixForOwner?: boolean;

    readonly stage1: {
        /**
         * The authorization endpoint of the OAuth provider.
         */
        readonly authorizationEndpoint: string;
        /**
         * Extra search parameters to include in the auth endpoint call.
         */
        readonly extraSearchParams: readonly (readonly [string, string])[];
        /**
         * These scopes will be inserted into the auth request no matter what.
         */
        readonly mandatoryScopes: readonly string[];
        /**
         * Whether the plugin includes the granted scopes from previous token invokations.
         */
        readonly includesGrantedScopes: boolean;
        /**
         * Overrides the name of the scope parameter sent to the endpoint. Useful for non-standard providers like slack.
         */
        readonly scopeNameOverride?: string;
        /**
         * If provided, instructs callers to use RFC 7636 Proof Key Code Exchange, with the challenge method
         * as specified.
         */
        readonly proofKeyChallengeMethod?: OAuthProofKeyChallengeMethod;
        /**
         * If provided, sets the explicit size of the proof key used in Proof Key Code Exchange.
         */
        readonly proofKeySize?: number;
    };
    readonly stage3: {
        /**
         * The token endpoint of the OAuth provider.
         */
        readonly tokenEndpoint: string;
        /**
         * Non-standard providers may embed their response inside another object. This lets you extract it.
         */
        readonly responseRootPath?: string;
        /**
         * This provides the path to a user ID if one is present in the response.
         */
        readonly responseUserIDPath?: string;
        /**
         * If not id_token is returned and no `responseUserIDPath` is set, this function must be set to fetch user data.
         */
        readonly fetchUserData?: (accessToken: string) => Promise<OAuthUserData | undefined>;
        /**
         * An optional number of seconds, after which we will immediately refresh the refresh token.
         * This exists to prevent us from losing access to accounts due to lack of activity.
         */
        readonly refreshAfterSeconds?: number;
        /**
         * If set to `true`, the scopes will be omitted from the refresh token request.
         */
        readonly omitScopesOnRefresh?: boolean;
        /**
         * Overrides the default client secret behavior. The default is to include client_secret as part of the POST
         * body, but some providers require the client_secret in another header.
         */
        readonly addAuthorizationToRequest?: (
            request: RequestInit,
            context: { clientID: string; clientSecret: string }
        ) => RequestInit;
    };
    readonly execution: {
        /**
         * The domains to which the authorization header will be inserted.
         */
        readonly authDomainRoot: string | ((url: string) => boolean);
        /**
         * Overrides the default authorization behavior. The default behavior is to insert a bearer token in the headers.
         */
        readonly addAuthorizationToRequest?: (
            accessToken: string,
            request: RequestInit,
            context: { additionalCredential?: string }
        ) => RequestInit;
        /**
         * Overrides the default check for token expiration. The default behavior looks for HTTP 401.
         * This function receives a clone response so it may consume the body if desired.
         */
        readonly checkResponseRequiresRefresh?: (response: Response) => Promise<boolean>;

        readonly validate?: (log: ValidationLog<ParameterRecord>, context: ServerExecutionContext) => Promise<void>;
    };
}

const googleOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "google",
    displayName: "Google",
    stage1: {
        authorizationEndpoint: "https://accounts.google.com/o/oauth2/v2/auth",
        extraSearchParams: [
            ["access_type", "offline"],
            ["include_granted_scopes", "true"],
            ["response_type", "code"],
        ],
        mandatoryScopes: ["email", "openid"],
        includesGrantedScopes: true,
    },
    stage3: {
        tokenEndpoint: "https://oauth2.googleapis.com/token",
        omitScopesOnRefresh: true,
    },
    execution: {
        authDomainRoot: url => url.includes("googleapis.com"),
        validate: async ({ error }, ctx) => {
            const r = await ctx.fetch(
                `https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${await ctx.getAccessToken()}`
            );
            if (!r.ok) {
                const refreshed = await ctx.refreshAccessToken();

                // Refreshing the access token can also fail, and the resulting access token will have the shape
                // `{ ok: false, message: 'Could not get access token', data: undefined }`
                if (!refreshed.ok) {
                    error("Could not validate access token");
                }
            }
        },
    },
    refreshAuthMethod: "post-auth",
};

const slackBotOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "slack-bot",
    displayName: "Slack Bot",
    stage1: {
        authorizationEndpoint: "https://slack.com/oauth/v2/authorize",
        mandatoryScopes: [],
        extraSearchParams: [["response_type", "code"]],
        includesGrantedScopes: false,
    },
    stage3: {
        tokenEndpoint: "https://slack.com/api/oauth.v2.access",
        responseUserIDPath: "bot_user_id",
    },
    execution: {
        authDomainRoot: url => url.includes("slack.com"),
        checkResponseRequiresRefresh: async res => {
            if (!res.ok) {
                void res.text();
                return false;
            }
            try {
                const json = await res.json();
                return json.error === "token_expired";
            } catch {
                return false;
            }
        },
    },
    needsRedirectHackForLocalhost: true,
};

const slackOAuthPlugin: OAuthClientPlugin = {
    ...slackBotOAuthPlugin,
    canonicalName: "slack",
    displayName: "Slack",
    stage1: {
        ...slackBotOAuthPlugin.stage1,
        mandatoryScopes: ["email", "openid", "profile"],
        scopeNameOverride: "user_scope",
    },
    stage3: {
        ...slackBotOAuthPlugin.stage3,
        responseRootPath: "authed_user",
        responseUserIDPath: "id",
    },
};

const discordOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "discord",
    displayName: "Discord",
    stage1: {
        authorizationEndpoint: "https://discord.com/oauth2/authorize",
        extraSearchParams: [
            ["response_type", "code"],
            ["permissions", "274945034304"],
        ],
        includesGrantedScopes: false,
        mandatoryScopes: ["identify", "email", "bot"],
    },
    stage3: {
        tokenEndpoint: "https://discord.com/api/oauth2/token",
        fetchUserData: async accessToken => {
            const response = await fetch("https://discord.com/api/users/@me", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: "application/json",
                },
            });

            if (!response.ok) {
                void response.text();
                return undefined;
            }

            try {
                const json = await response.json();

                return {
                    email: json.email,
                    uid: json.id,
                };
            } catch {
                return undefined;
            }
        },
    },
    execution: {
        authDomainRoot: "https://discord.com/api",
        addAuthorizationToRequest: (_token, req, { additionalCredential }) => {
            return {
                ...req,
                headers: {
                    ...req.headers,
                    Authorization: `Bot ${additionalCredential}`,
                },
            };
        },
    },
    refreshAuthMethod: "post-auth",
};

const githubOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "github",
    displayName: "GitHub",
    stage1: {
        authorizationEndpoint: "https://github.com/login/oauth/authorize",
        includesGrantedScopes: false,
        mandatoryScopes: ["user"],
        extraSearchParams: [["allow_signup", "false"]],
    },
    stage3: {
        tokenEndpoint: "https://github.com/login/oauth/access_token",
        fetchUserData: async (accessToken: string) => {
            /**
             * curl \
             *  -H "Accept: application/vnd.github+json" \
             *  -H "Authorization: Bearer <YOUR-TOKEN>"\
             *  -H "X-GitHub-Api-Version: 2022-11-28" \
             *  https://api.github.com/user
             */
            const response = await fetch("https://api.github.com/user", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: "application/vnd.github+json",
                    "X-GitHub-Api-Version": "2022-11-28",
                },
            });

            if (!response.ok) {
                void response.text();
                return undefined;
            }

            try {
                const json = await response.json();

                return {
                    email: json.email,
                    uid: json.id.toString(),
                };
            } catch {
                return undefined;
            }
        },
    },
    execution: {
        authDomainRoot: "https://api.github.com",
    },
};

const paypalOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "paypal",
    displayName: "PayPal",
    stage1: {
        authorizationEndpoint: "https://www.sandbox.paypal.com/signin/authorize",
        mandatoryScopes: ["openid"],
        extraSearchParams: [["response_type", "code"]],
        includesGrantedScopes: false,
    },
    stage3: {
        tokenEndpoint: "https://api-m.sandbox.paypal.com/v1/oauth2/token",
        fetchUserData: async (accessToken: string) => {
            const response = await fetch(
                "https://api-m.sandbox.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        Accept: "application/json",
                    },
                }
            );

            if (!response.ok) {
                void response.text();
                return undefined;
            }

            try {
                const json = await response.json();

                return {
                    email: json.emails[0].value,
                    uid: json.user_id,
                };
            } catch {
                return undefined;
            }
        },
    },
    execution: {
        authDomainRoot: url => url.includes("paypal.com"),
        // addAuthorizationToRequest: (accessToken, request) => {
        //     return {
        //         ...request,
        //         headers: {
        //             ...request.headers,
        //             Authorization: `Bearer ${accessToken}`,
        //         },
        //     };
        // },
        // checkResponseRequiresRefresh: async res => {
        //     if (!res.ok) {
        //         void res.text();
        //         return false;
        //     }
        //     try {
        //         const json = await res.json();
        //         return json.error === "invalid_token";
        //     } catch {
        //         return false;
        //     }
        // },
    },
    refreshAuthMethod: "post-auth",
    needsRedirectHackForLocalhost: true,
};

// This is a separate application from the Azure setup we use for Excel,
// which is why we call it msal-plugins instead of just msal.
const msalPluginOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "msal-plugins",
    displayName: "Microsoft",
    refreshAuthMethod: "post-auth",
    stage1: {
        authorizationEndpoint: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
        includesGrantedScopes: true,
        mandatoryScopes: ["openid", "profile", "offline_access"],
        extraSearchParams: [
            ["prompt", "select_account"],
            ["response_type", "code"],
        ],
    },
    stage3: {
        tokenEndpoint: "https://login.microsoftonline.com/common/oauth2/v2.0/token",
        refreshAfterSeconds: 14 * 24 * 60 * 60, // 14 days
    },
    execution: {
        authDomainRoot: url => url.includes("microsoftonline.com") || url.includes("graph.microsoft.com"),
    },
};

const zoomOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "zoom",
    displayName: "Zoom",
    stage1: {
        authorizationEndpoint: "https://zoom.us/oauth/authorize",
        includesGrantedScopes: false,
        mandatoryScopes: ["user:read"],
        extraSearchParams: [["response_type", "code"]],
    },
    stage3: {
        tokenEndpoint: "https://zoom.us/oauth/token",
        fetchUserData: async (accessToken: string) => {
            const response = await fetch("https://api.zoom.us/v2/users/me", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                void response.text();
                return undefined;
            }

            try {
                const json = await response.json();

                return {
                    email: json.email,
                    uid: json.id,
                };
            } catch {
                return undefined;
            }
        },
    },
    execution: {
        authDomainRoot: url => url.includes("zoom.us") || url.includes("api.zoom.us"),
    },
    needsRedirectHackForLocalhost: true,
};

const hubspotOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "hubspot",
    displayName: "HubSpot",
    stage1: {
        authorizationEndpoint: "https://app.hubspot.com/oauth/authorize",
        includesGrantedScopes: false,
        mandatoryScopes: [
            "oauth",
            // We are sillies and misinterpreted Hubspot's OAuth configuration UI.
            // What we thought were requests for optional access to scopes were actually
            // telling Hubspot that these scopes were completely mandatory! We deployed
            // like this, so we're kinda stuck with it.
            "crm.objects.companies.read",
            "crm.objects.companies.write",
            "crm.objects.contacts.read",
            "crm.objects.contacts.write",
            "crm.objects.deals.read",
            "crm.objects.deals.write",
        ],
        extraSearchParams: [["response_type", "code"]],
    },
    stage3: {
        tokenEndpoint: "https://api.hubapi.com/oauth/v1/token",
        responseUserIDPath: "user_id",
        fetchUserData: async (accessToken: string) => {
            const response = await fetch("https://api.hubapi.com/oauth/v1/access-tokens/" + accessToken, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: "application/json",
                },
            });

            if (!response.ok) {
                return undefined;
            }

            try {
                const json = await response.json();
                // Access tokens only apply to one portal at a time, which
                // for some reason is called a "hub_id" here.
                const uid = `${json.user_id}/${json.hub_id}`;

                return {
                    email: json.user,
                    uid,
                };
            } catch {
                return undefined;
            }
        },
    },
    execution: {
        authDomainRoot: url => url.includes("hubspot.com") || url.includes("hubapi.com"),
        checkResponseRequiresRefresh: async res => {
            // FIXME: A nasty bug in node-fetch 2.x will cause cloned
            // response bodies to completely drop all work from the event loop!
            // So to sidestep this bug, we have to bail early if the response
            // seems OK.
            if (res.ok) {
                // If we don't attempt to fail to drain the response body,
                // the cloned result also hangs.
                void res.text();
                return false;
            }
            try {
                // In the slow case, we still need to make sure we can make any
                // progress at all.
                let stallTimeout: NodeJS.Timeout | undefined;
                const stallPromise = new Promise<{}>((_, reject) => {
                    stallTimeout = setTimeout(() => reject(new Error("JSON parsing stalled")), 5_000);
                });
                const json = await Promise.race([res.json(), stallPromise]);
                if (stallTimeout !== undefined) {
                    clearTimeout(stallTimeout);
                }
                return json.status === "error" && json.category === "EXPIRED_AUTHENTICATION";
            } catch (e: unknown) {
                return exceptionToString(e).indexOf("JSON parsing stalled") >= 0;
            }
        },
    },
    refreshAuthMethod: "post-auth",
};

const asanaOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "asana",
    displayName: "Asana",
    needsRedirectHackForLocalhost: true,
    stage1: {
        authorizationEndpoint: "https://app.asana.com/-/oauth_authorize",
        mandatoryScopes: ["default"],
        extraSearchParams: [["response_type", "code"]],
        includesGrantedScopes: false,
    },
    stage3: {
        tokenEndpoint: "https://app.asana.com/-/oauth_token",
        fetchUserData: async (accessToken: string) => {
            try {
                // Define the API endpoint URL
                const url = "https://app.asana.com/api/1.0/users/me";

                // Send a request to the Asana API using the accessToken
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                // Check if the request was successful
                if (!response.ok) {
                    void response.text();
                    throw new Error(`Asana API error: ${response.statusText}`);
                }

                // Parse the JSON response
                const jsonResponse = await response.json();
                const user = jsonResponse.data;

                // Extract the user ID and email
                const asanaUser = {
                    uid: user.gid,
                    email: user.email,
                };

                return asanaUser;
            } catch (error: unknown) {
                // eslint-disable-next-line no-console
                console.error(`Error fetching Asana user: ${error}`);
                return undefined;
            }
        },
    },
    execution: {
        authDomainRoot: url => url.includes("asana.com"),
        addAuthorizationToRequest: (accessToken, request) => {
            return {
                ...request,
                headers: {
                    ...request.headers,
                    Authorization: `Bearer ${accessToken}`,
                },
            };
        },
    },
};

const docusignOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "docusign",
    displayName: "DocuSign",
    stage1: {
        authorizationEndpoint: "https://account.docusign.com/oauth/auth",
        includesGrantedScopes: true,
        mandatoryScopes: ["openid", "extended"],
        extraSearchParams: [["response_type", "code"]],
    },
    stage3: {
        tokenEndpoint: "https://account.docusign.com/oauth/token",
        refreshAfterSeconds: 14 * 24 * 60 * 60, // 14 days - extended pushes this to 30 days
        fetchUserData: async (accessToken: string) => {
            const response = await fetch("https://account.docusign.com/oauth/userinfo", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                void response.text();
                return undefined;
            }

            try {
                const json = await response.json();

                return {
                    email: json.email,
                    uid: json.sub,
                };
            } catch {
                return undefined;
            }
        },
    },
    execution: {
        authDomainRoot: url => url.includes("docusign.com") || url.includes("docusign.net"),
    },
};

const airtableOAuthPlugin: OAuthClientPlugin = {
    canonicalName: "airtable",
    displayName: "Airtable",
    forceStage2RedirectThroughDeployed: true,
    suffixForOwner: true,
    stage1: {
        authorizationEndpoint: "https://airtable.com/oauth2/v1/authorize",
        includesGrantedScopes: true,
        mandatoryScopes: ["data.records:read", "data.records:write", "schema.bases:read", "webhook:manage"],
        extraSearchParams: [["response_type", "code"]],
        proofKeyChallengeMethod: "S256",
        proofKeySize: 43,
    },
    stage3: {
        tokenEndpoint: "https://airtable.com/oauth2/v1/token",
        refreshAfterSeconds: 30 * 24 * 60 * 60, // 30 days - Airtable requires this after 60 days
        fetchUserData: async (accessToken: string) => {
            const response = await fetch("https://api.airtable.com/v0/meta/whoami", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                void response.text();
                return undefined;
            }

            try {
                const json = await response.json();
                // FIXME: How in the world do we get an Airtable email address?
                return {
                    uid: json.id,
                };
            } catch {
                return undefined;
            }
        },
        addAuthorizationToRequest: (request, context) => {
            return {
                ...request,
                headers: {
                    ...request.headers,
                    // https://airtable.com/developers/web/api/oauth-reference#token-creation-request says
                    // base64rul-encoding, but also within the context of the expectation of base64url-encoding
                    // according to IETF RFC 7636, which explicitly drops all padding. This interpretation is
                    // actually wrong; the padding is necessary or Airtable rejects the request.
                    Authorization: `Basic ${base64URLEncodeNodeBuffer(
                        Buffer.from(`${context.clientID}:${context.clientSecret}`, "utf-8")
                    )}`,
                },
            };
        },
        omitScopesOnRefresh: true,
    },
    execution: {
        authDomainRoot: "https://airtable.com/",
    },
};

export const authPlugins = [
    googleOAuthPlugin,
    githubOAuthPlugin,
    zoomOAuthPlugin,
    asanaOAuthPlugin,
    slackOAuthPlugin,
    hubspotOAuthPlugin,
    slackBotOAuthPlugin,
    discordOAuthPlugin,
    msalPluginOAuthPlugin,
    paypalOAuthPlugin,
    docusignOAuthPlugin,
    airtableOAuthPlugin,
];
