import type { GroundValue, LoadedGroundValue } from "./data";

export class LoadingValue {
    // ##resumableComputations:
    // When this flag is `true`, as created by `makeResumableLoadingValue`
    // and tested by `isResumableLoadingValue`, it indicates that a CPU-
    // bound computation was interrupted, this loading value is being
    // returned to yield control back to the UI thread, and the
    // Handler has scheduled a continuation for the computation.
    // Currently, this is only used for aggregates over columns with thunks.
    constructor(public readonly isResumableComputation: boolean) {}
}

class LoadingValueWithDisplayValue extends LoadingValue {
    constructor(public readonly displayValue: LoadedGroundValue) {
        super(false);
    }
}

export function isLoadingValue<T>(v: T | LoadingValue): v is LoadingValue {
    return v instanceof LoadingValue;
}

// See ##resumableComputations
export function isResumableLoadingValue<T>(v: T | LoadingValue): v is LoadingValue {
    return isLoadingValue(v) && v.isResumableComputation;
}

const loadingValueSingleton = new LoadingValue(false);
const resumableLoadingValueSingleton = new LoadingValue(true);

export function makeLoadingValue(): LoadingValue {
    return loadingValueSingleton;
}

// See ##resumableComputations
export function makeResumableLoadingValue(): LoadingValue {
    return resumableLoadingValueSingleton;
}

// It's safe to call this on a loading value.  It will just return it
// unchanged.
export function makeLoadingValueWithDisplayValue(v: GroundValue): LoadingValue {
    if (isLoadingValue(v)) {
        return v;
    } else {
        return new LoadingValueWithDisplayValue(v);
    }
}

// If this returns a `LoadingValue`, it will not have a display value.
export function unwrapLoadingValue(v: GroundValue): GroundValue {
    if (v instanceof LoadingValueWithDisplayValue) {
        return v.displayValue;
    } else {
        return v;
    }
}

export function mapLoadingValue<T extends LoadedGroundValue>(
    v: GroundValue,
    f: (v: LoadedGroundValue) => T
): T | LoadingValue {
    if (v instanceof LoadingValueWithDisplayValue) {
        return makeLoadingValueWithDisplayValue(f(v.displayValue));
    } else if (isLoadingValue(v)) {
        return v;
    } else {
        return f(v);
    }
}

/**
 * A computation's result must be wrapped in a `LoadingValue` if any of its
 * inputs are loading.  This interface is an easy way to make that happen by
 * unwrapping the input's display values on the way via the `unwrap` method.
 * Then, when the result it produced, calling `wrap` on it will make sure that
 * the result is wrapped in a `LoadingValue` in case any of the processed
 * values were loading.
 */
export interface LoadingValueWrapper {
    unwrap(v: GroundValue): GroundValue;
    wrap<T extends GroundValue>(v: T): T | LoadingValue;
}

export class LoadingValueWrapperImpl implements LoadingValueWrapper {
    private needsWrapping = false;

    public unwrap(v: GroundValue): GroundValue {
        if (isLoadingValue(v)) {
            this.needsWrapping = true;
            if (v instanceof LoadingValueWithDisplayValue) {
                return v.displayValue;
            }
        }
        return v;
    }

    public wrap<T extends GroundValue>(v: T): T | LoadingValue {
        if (this.needsWrapping) {
            return makeLoadingValueWithDisplayValue(v);
        } else {
            return v;
        }
    }
}
