import { setBuilderTheme } from "@glide/action-reducer-utils";
import { GlideIcon } from "@glide/common";
import { getUserFlags, type UserData } from "@glide/common-core/dist/js/Database";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import type { RootState } from "@glide/action-reducer-utils";
import { isOSThemeDark } from "@glide/theme";
import { Img } from "@glide/wire-renderer";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import { useBuilderSelector } from "../../hooks/use-builder-selector";
import { useOrgPageHeaderContext } from "./context";

const Item = styled(DropdownMenu.Item)`
    ${tw`px-2.5 py-1.5 hover:bg-n200A data-[highlighted]:bg-n200A select-none cursor-pointer`}
`;

const ThemeItem = styled(DropdownMenu.Item)`
    ${tw`px-3 py-1.5 hover:bg-n200A data-[highlighted]:bg-n200A select-none cursor-pointer flex items-center flex-nowrap gap-1.5`}
`;

const getIconForSelectedTheme = (isSelected: boolean) => {
    if (isSelected) return <GlideIcon tw="shrink-0 text-text-accent " kind="stroke" icon="st-check" iconSize={16} />;
    return <div tw="w-4 h-4"></div>;
};

interface Props extends React.PropsWithChildren {
    userData: UserData;
}

export const AccountMenu: React.FC<React.PropsWithChildren<Props>> = props => {
    const { userData } = props;
    const [portalContainer] = React.useState(() => document.getElementById("portal"));

    const { viewAccountSettings, signOut } = useOrgPageHeaderContext();
    const dispatch = useDispatch();
    const theme = useSelector((state: RootState) => getUserFlags(state.userData).builderTheme);
    const useSystemTheme = useBuilderSelector(state => getUserFlags(state.userData).useSystemTheme);

    const isOSDarkTheme = isOSThemeDark();

    const onToggleDarkMode = useCallback(
        (themeMode: "dark" | "light" | "system") => {
            let newTheme: "dark" | "light" = theme;
            if (themeMode === "system") {
                newTheme = isOSDarkTheme ? "dark" : "light";
            } else {
                newTheme = themeMode;
            }
            const isSystem = themeMode === "system";
            void dispatch(setBuilderTheme(newTheme, isSystem, true, getAppFacilities()));
        },
        [dispatch, isOSDarkTheme, theme]
    );

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger tw="rounded-full ring-3 ring-transparent transition duration-150 hover:ring-n400A focus-visible:ring-n400A data-[state=open]:ring-n400A">
                {userData.photoURL !== undefined ? (
                    <Img tw="rounded-full w-8 h-8 object-cover" src={userData.photoURL} alt={userData.displayName} />
                ) : (
                    <div tw="rounded-full flex justify-center items-center w-8 h-8 bg-n200A overflow-hidden">
                        <GlideIcon kind="monotone" icon="mt-user" iconSize={30} tw="-mb-0.5 text-icon-base" />
                    </div>
                )}
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal container={portalContainer}>
                <DropdownMenu.Content
                    tw="min-w-[256px] py-1 text-text-base shadow-md-dark bg-bg-front rounded-md text-builder-sm"
                    align="end">
                    <Item onClick={viewAccountSettings}>Account settings</Item>
                    <DropdownMenu.Sub>
                        <DropdownMenu.SubTrigger asChild>
                            <Item tw="flex justify-between items-center">
                                Theme
                                <div tw="transition-colors text-text-contextual-pale">
                                    <GlideIcon
                                        tw="shrink-0"
                                        kind="stroke"
                                        icon="st-caret"
                                        rotateDeg={-90}
                                        iconSize={16}
                                    />
                                </div>
                            </Item>
                        </DropdownMenu.SubTrigger>
                        <DropdownMenu.Portal container={portalContainer}>
                            <DropdownMenu.SubContent tw="min-w-[256px] py-1 text-text-base shadow-md-dark bg-bg-front rounded-md text-builder-sm -m-1">
                                <ThemeItem onClick={() => onToggleDarkMode("system")}>
                                    {getIconForSelectedTheme(useSystemTheme)} System default
                                </ThemeItem>
                                <ThemeItem onClick={() => onToggleDarkMode("light")}>
                                    {getIconForSelectedTheme(theme === "light" && !useSystemTheme)} Light
                                </ThemeItem>
                                <ThemeItem onClick={() => onToggleDarkMode("dark")}>
                                    {getIconForSelectedTheme(theme === "dark" && !useSystemTheme)} Dark
                                </ThemeItem>
                            </DropdownMenu.SubContent>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Sub>
                    <Item onClick={signOut}>Sign out</Item>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};
