import type { AppKind } from "@glide/location-common";
import type { AppPlanCode } from "../app-plans";
import type { EditedColumnKind } from "@glide/formula-specifications";
import type { PrimitiveGlideTypeKind } from "@glide/type-schema";
import type { Range, SyncedDataSources } from "../firebase-function-types";
import type {
    AppAnalyticsEvents,
    NewProjectEvents,
    PluginEvents,
    PricingV3Events,
    RevenueEvents,
    RichTooltipEvents,
} from "./app-events";
import type { ArrayFauxColumnKind } from "../faux-array-types";

type WithValue = { value: number };
type WithLabel = WithLabels<string>;
type WithLabels<T> = { label: T };

export interface OrgWithNumEmployees {
    readonly name: string;
    readonly numEmployees: Range;
}

type WithApp = { app_id: string };
export type WithProjectType = { project_kind: "app" | "page" };
type WithProgressStatus = { status: "started" | "loading" | "failed" | "invalid values" | "success" };
type WithOrgId = { org_id: string };

export type WithDataSource = {
    data_source: "gsheet" | "glide" | "glide-big" | "imported-native-tables" | SyncedDataSources;
};
type WithUserId = { user_id: string };
type WithColumnKind = {
    kind: EditedColumnKind | PrimitiveGlideTypeKind | "column-sh" | "specific-plugin-computation" | ArrayFauxColumnKind;
};

type WithAuthProvider = { provider: "email" | "google" };

// FIXME: Add app to most of these

export type AnalyticsEvents = AppAnalyticsEvents &
    NewProjectEvents &
    PricingV3Events &
    RevenueEvents & {
        "picked sheet": {};
        "replaced sheet": {};
        "removed sheet": {};
        "linked tables to app": WithDataSource;
        "first publish for app": WithApp;
        "create new table": WithDataSource;

        "upgrade start": WithApp & { intent: string; plan: AppPlanCode };

        upgrade: WithApp & {
            plan: "basic" | "pro";
            period: "monthly" | "annual";
            privacy: "public" | "private";
            "private-users"?: number;
        };

        "started free trial": { flow: string; url: string; owner_id: string };
        "org selected for standalone trial": { url: string; org: string };
        "upgrade modal plan selected": { url: string; owner_id: string; plan: string; is_free_trial: boolean };
        "upgrade modal legacy view": { plan: string; location: string };
        "upgrade modal view": { plan: string; feature: string; location?: string };
        "upgrade from modal": { plan: string; feature: string; location?: string };
        "start free trial": {};
        "view all plans from upgrade modal": { plan: string; feature: string; location?: string };
        "view pricing table": { orgID: string; location?: string };
        "upgrade modal go to billing": { plan: string };
        "dismiss business promo banner": { org: string };
        "dismiss SSO promo banner": { org: string };

        "upgrade failed": WithApp;

        downgrade: WithApp & {
            reason?: string;
            explanation?: string;
            isFreeTrial?: boolean;
        };

        "copy sample": WithApp & {
            new_app_id?: string;
            kind: AppKind;
        };

        "copy glide template": WithApp & {
            template_appid: string;
            template_name: string;
            template_price: number;
            price_currency: string;
        };
        "buy template": WithApp & { price: number };
        "submit template": WithApp & {
            kind: AppKind;
            firstSubmission: boolean;
        };

        "copy user template": WithApp;
        "sign up for copying user template": WithApp;

        "duplicate app": WithApp & { method: string; asPage?: boolean };
        "delete app": WithApp;

        "mobile open sample app": WithApp;

        config_group_changed: WithLabel;

        "help button": {};
        "help menu item": WithLabel & {
            query?: string;
            plan?: string;
            org_id?: string;
            user_id?: string;
        };

        "tried pay button": WithLabel & WithApp;
        "used pay button": WithLabel & WithApp;
        "pay button failed": WithLabel & WithApp;

        "video progress": {
            // Video URL
            label: string;
            // Progress 0-100
            value: number;
        };

        tos_update_dialog: { status: "accept" | "reject" };

        "share app": WithLabel & WithApp;

        "sign in start": WithAuthProvider;
        "sign in success": WithAuthProvider;
        "sign in failed": { reason: string };
        "sign in link sent": {};
        "nudge gmail sign up": WithAuthProvider;

        "sign up view": {};
        "sign up started": WithAuthProvider;
        "sign up failed": { reason: string };
        "sign up success": WithUserId & WithAuthProvider & { referrer?: string };
        "sign up link sent": {};

        "sign up user survey": { intent: string; companySize?: string };
        "sign up company survey": { name: string; industry: string; role: string };
        "sign up invite members": { count: number };
        "sign up accept tos": {};
        "completed sign up flow": { isForWork: boolean };

        "verified email": { provider: string };
        "verified via google sso": {};
        "verified via email": {};
        "verified via magic link": {};

        "sign in closed by user": {};
        "sign in blocked by popup blocker": {};
        "user modified app": WithApp & { app_rows?: number };

        builder_navigate: WithApp & WithLabel;
        builder_action:
            | (WithApp & WithLabels<"reload_sheet" | "edit_sheet" | "view_sheet">)
            | (WithApp & WithLabels<"undo" | "redo"> & { method: "keyboard" | "cursor" });

        // FIXME: Add `WithApp` to these, too
        set_platform_preview: WithLabel;
        set_preview_form_factor: WithLabel;
        set_preview_user: {};

        set_screen_layout: WithApp & WithLabel;

        component_new: WithApp & WithLabel;
        component_moved: WithApp;
        component_deleted: WithApp;

        special_value_new: WithApp & WithLabel;
        column_value_new: WithApp;

        set_theme: WithApp & WithLabel;
        set_theme_color: WithApp & WithLabel;

        set_icon: (WithApp & WithLabels<"emoji"> & { type: string }) | (WithApp & WithLabels<"file"> & WithValue);

        column_selected: WithApp & WithColumnKind;
        column_added: WithApp & WithColumnKind;
        column_changed: WithApp & WithColumnKind;
        column_canceled: WithApp & WithColumnKind;
        // FIXME: Add `WithColumnKind`
        column_deleted: WithApp;

        // FIXME: Add `WithApp` to these, too
        unsplash_image_picked: {};

        // `template` will either be an analytics name of a template or
        // * "easy"
        // * "custom"
        // * "form"
        tab_new: WithApp & { template?: string };
        tab_moved: WithApp;
        tab_deleted: WithApp;
        tab_icon_changed: WithApp;

        // Orgs
        org_created: WithOrgId & OrgWithNumEmployees;
        org_member_invited: WithOrgId & { source: string };
        org_member_invite_accept: WithUserId & WithOrgId;

        "app invite sent": WithApp & WithUserId;
        "app invite accepted": WithApp & WithUserId;
        "app user invited": WithApp & WithLabel;

        "billing learn more": WithApp;

        // Builder templates
        "browse templates from builder": WithUserId;
        "copy builder template": WithUserId & { isProTrial: boolean; new_app_id?: string };
        "preview builder template": WithApp & WithUserId;
        "copy own template": WithUserId & { template_id: string };
        "converted app into template": WithApp & WithUserId;
        "new appgpt generated app": {
            app_name: string;
            app_description: string;
        };
        // Pricing V3 Events
        "contact sales for enterprise": WithApp;

        "triggered enforcement": {
            limit_type: "users" | "apps" | "updates" | "rows";
            org_id: string;
            current_plan: string;
            current_users: number;
            current_apps: number;
            current_updates: number;
            current_rows: number;
            current_file_storage: number;
        };

        "action created": WithApp;
        "workflow created from template": WithApp & { template_id: string };

        // Templates
        "new template": {};

        // Import
        "import to existing app": {
            app_id: string;
            file_type: string;
            row_count: number;
            table_count: number;
        };

        "bulk user file import": WithProgressStatus & { user_id?: string };
        "skipped activation checklist": {};
        "open app in builder": WithApp;
        "past runs list opened": WithApp;

        "helpcenter searches": {
            query: string;
            plan?: string;
            org_id?: string;
            user_id?: string;
        };

        "preview feature toggled": WithUserId & { feature: string; enabled: boolean };

        "org logo changed": WithOrgId;

        "integration settings clicked": WithUserId & {
            integration_name: string;
            stripe_checkout_clicked: boolean;
            current_plan: string;
            required_plan: string;
            app_id: string;
            team_name: string;
            team_id: string;
            stripe_checkout_url: string;
            timestamp: number;
        };

        "in your face upgrade modal": WithApp & { org_id?: string; event_score: number };

        // Dashboard
        "dashboard folder created": WithOrgId;
        "dashboard folder renamed": WithOrgId;
        "dashboard folder deleted": WithOrgId;
        "dashboard folder added app": WithOrgId & { dragged: boolean };
        "dashboard filter changed": WithOrgId & { filter: string };
        "dashboard sorting changed": WithOrgId & { sort: string };
        "dashboard click starter video": WithOrgId & { index: number };
        "dashboard click front desk": { analyticsTag: string };

        // Custom component
        "custom component generated": WithOrgId;
        "custom component edit history": WithOrgId;
        "custom component imported": WithOrgId;
        "custom component shared": WithOrgId;
    } & RichTooltipEvents &
    PluginEvents;

export const MarketingEvents: Array<keyof AnalyticsEvents> = [
    "first publish for app",
    "create new table",

    "upgrade",

    "downgrade",
    "copy glide template",
    "copy sample",
    "buy template",
    "submit template",

    "triggered enforcement",

    "sign up for copying user template",

    "new app",
    "new app created",
    "new appgpt generated app",
    "new project name and kind select",
    "new project name",
    "new project layout select",
    "google sheet source select",
    "google sheet select",
    "excel source connect",
    "onedrive select",
    "excel source select",
    "airtable source connect",
    "airtable source select",
    "airtable base select",
    "glide data source select",
    "sign in start",
    "sign in success",
    "sign in failed",

    "sign up started",
    "sign up failed",
    "sign up success",
    "sign up link sent",
    "completed sign up flow",

    "verified email",
    "verified via google sso",
    "verified via email",
    "verified via magic link",

    "app invite sent",
    "app user invited",
    "copy builder template",
    "preview builder template",
    "contact sales for enterprise",
    "action created",

    "dashboard click front desk",
    "view pricing table",
    "integration settings clicked",
];

// Important: All custom hubspot events must be created through the hubspot UI
// https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#use-custom-behavioral-events-in-workflows
// If you need to pass additonal properties, please make sure you also create custom properties for each event located on the event.
export const HubspotCustomEvents: Array<keyof AnalyticsEvents> = [
    "new app created",
    "first publish for app",
    "app user invited",
    "triggered enforcement",
    "verified email",
    "copy glide template",
    "action created",
    "helpcenter searches",
    "view pricing table",
    "integration settings clicked",
];
