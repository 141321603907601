import { assert, definedMap, exceptionToString } from "@glideapps/ts-necessities";
import { logError } from "./debug-print";

export interface ChangeObservable<T> {
    readonly current: T;
    readonly isChangeObservable: true;

    subscribe(onChange: (newVal: T) => void): void;
    unsubscribe(onChange: (newVal: T) => void): void;
}

export function isChangeObservable<T>(v: unknown): v is ChangeObservable<T> {
    return typeof v === "object" && v !== null && "isChangeObservable" in v && (v as any).isChangeObservable === true;
}

// exported for testing
export class Subscribable<T> {
    protected subscribers: Set<(val: T) => void> = new Set();

    protected updateAllSubscribers(newVal: T): void {
        for (const cb of this.subscribers) {
            try {
                cb(newVal);
            } catch (e: unknown) {
                logError("Subscribable callback crashed", exceptionToString(e));
            }
        }
    }

    protected get hasSubscribers(): boolean {
        return this.subscribers.size > 0;
    }

    protected onFirstSubscribed?(): void;
    protected onLastUnsubscribed?(): void;

    public subscribe(onChange: (newVal: T) => void): void {
        const isFirst = this.subscribers.size === 0;
        this.subscribers.add(onChange);
        if (isFirst) {
            this.onFirstSubscribed?.();
        }
    }

    public unsubscribe(onChange: (newVal: T) => void) {
        if (this.subscribers.size === 0) return;
        this.subscribers.delete(onChange);
        if (this.subscribers.size === 0) {
            this.onLastUnsubscribed?.();
        }
    }
}

export class ConstantChangeObservable<T> implements ChangeObservable<T> {
    public readonly isChangeObservable = true;

    constructor(public readonly current: T) {}

    public subscribe(): void {
        // Do nothing
    }

    public unsubscribe(): void {
        // Do nothing
    }
}

export class MappingChangeObservable<T, U> extends Subscribable<U> implements ChangeObservable<U> {
    public readonly isChangeObservable = true;

    constructor(private readonly input: ChangeObservable<T>, private readonly map: (v: T) => U) {
        super();
    }

    public get current(): U {
        return this.map(this.input.current);
    }

    private readonly onChange = (v: T): void => {
        this.updateAllSubscribers(this.map(v));
    };

    protected onFirstSubscribed(): void {
        this.input.subscribe(this.onChange);
    }

    protected onLastUnsubscribed(): void {
        this.input.unsubscribe(this.onChange);
    }
}

export class Watchable<T> extends Subscribable<T> implements ChangeObservable<T> {
    public readonly isChangeObservable = true;

    constructor(private val: T, private readonly synchronousCallbacks = true) {
        super();
    }

    public get current(): T {
        return this.val;
    }

    public set current(newVal: T) {
        if (newVal === this.val) return;

        this.val = newVal;
        if (this.synchronousCallbacks) {
            this.updateAllSubscribers(newVal);
        } else {
            setTimeout(() => this.updateAllSubscribers(newVal), 0);
        }
    }
}

export class CombinedChangeObservable<T, U> extends Subscribable<T> implements ChangeObservable<T> {
    public readonly isChangeObservable = true;

    private readonly inputs = new Set<ChangeObservable<U>>();
    private val: T;

    constructor(private readonly combine: (inputs: readonly U[]) => T) {
        super();
        this.val = combine([]);
    }

    public get current(): T {
        if (!this.hasSubscribers) {
            // If we don't have subscribers, we are not subscribed to our
            // inputs, which means that `val` might be out of date, but we
            // still have to return the current value here.
            this.recompute();
        }
        return this.val;
    }

    protected onFirstSubscribed(): void {
        for (const co of this.inputs) {
            co.subscribe(this.recompute);
        }
        this.recompute();
    }

    protected onLastUnsubscribed(): void {
        for (const co of this.inputs) {
            co.unsubscribe(this.recompute);
        }
    }

    private readonly recompute = (): void => {
        const inputs = Array.from(this.inputs).map(co => co.current);
        const newVal = this.combine(inputs);
        if (newVal !== this.val) {
            this.val = newVal;
            this.updateAllSubscribers(newVal);
        }
    };

    public addInput(co: ChangeObservable<U>): void {
        this.inputs.add(co);
        if (this.hasSubscribers) {
            co.subscribe(this.recompute);
            // Even if nobody is subscribe, `current` should still return a value
            // based on all the inputs, even if it's not up-to-date.
            this.recompute();
        }
    }
}

export class AllTrueChangeObservable<U> extends Subscribable<boolean> implements ChangeObservable<boolean> {
    public readonly isChangeObservable = true;

    private readonly inputs = new Map<U, boolean>();

    public get current(): boolean {
        return Array.from(this.inputs.values()).every(v => v);
    }

    public setInput(sender: U, value: boolean): void {
        const oldValue = this.current;
        this.inputs.set(sender, value);
        const newValue = this.current;
        if (oldValue !== newValue) {
            this.updateAllSubscribers(newValue);
        }
    }
}

interface DebouncedValue<T> {
    readonly val: T;
    readonly timeout: ReturnType<typeof setTimeout>;
}

export class DebouncedChangeObservable<T> extends Subscribable<T> implements ChangeObservable<T> {
    public readonly isChangeObservable = true;

    private val: T;
    private debounced: DebouncedValue<T> | undefined;

    constructor(private readonly input: ChangeObservable<T>, private readonly debounceTime: (v: T) => number) {
        super();
        this.val = input.current;
        // FIXME: Only subscribe to the inputs when we have subscribers
        input.subscribe(this.onChange);
    }

    public get current(): T {
        return this.val;
    }

    private clearDebounced(): void {
        assert(this.debounced !== undefined);
        clearTimeout(this.debounced.timeout);
        this.debounced = undefined;
    }

    private readonly onChange = (v: T): void => {
        if (this.debounced !== undefined) {
            if (v === this.debounced.val) {
                return;
            }
            this.clearDebounced();
        }

        if (v === this.val) {
            return;
        }

        const time = this.debounceTime(v);
        if (time === 0) {
            this.val = v;
            this.updateAllSubscribers(v);
        } else {
            const timeout = setTimeout(() => {
                this.clearDebounced();
                assert(this.val !== v);
                this.val = v;
                this.updateAllSubscribers(v);
            }, time);
            this.debounced = {
                val: v,
                timeout,
            };
        }
    };

    public retire(): void {
        this.input.unsubscribe(this.onChange);
        if (this.debounced !== undefined) {
            this.clearDebounced();
        }
    }
}

// On the first subscriber, this will start fetching every `period`ms.  It
// will stop fetching when all the subscribers have gone away, but an
// outstanding fetch will still set the value if it succeeds.  There will
// never be more than one fetch in flight, nor will more than one be started
// every `period`.
//
// It is assumed that there's one source of truth, i.e. this won't prefer the
// `updateFn` to directly setting.
export class AutoWatchable<T> extends Watchable<T> {
    private inFlight: boolean = false;
    private lastUpdatedAt: number | undefined;
    // Defaulting to `undefined` will cause a fetch as soon as we get the
    // first subscriber.
    private timeout: ReturnType<typeof setTimeout> | undefined = undefined;

    constructor(val: T, private updateFn: () => Promise<T>, private period: number = 60 * 1000) {
        super(val);
    }

    // Yes, this override is necessary: https://stackoverflow.com/a/28951055
    public get current(): T {
        return super.current;
    }
    public set current(newVal: T) {
        super.current = newVal;
        this.lastUpdatedAt = Date.now();

        // If there's currently a timeout, this will reset it.
        this.stop();
        this.start();
    }

    private async update() {
        assert(!this.inFlight);
        this.inFlight = true;
        try {
            // This assignment will call `start`, but `inFlight` will be set,
            // so it won't do anything.
            this.current = await this.updateFn();
        } catch (e: unknown) {
            // If we don't do this then we immediately re-fetch, and if it
            // always fails, we end up in a loop.
            this.lastUpdatedAt = Date.now();
        } finally {
            this.inFlight = false;
            // Now we call `start` in earnest.
            this.start();
        }
    }

    public subscribe(onChange: (newVal: T) => void): void {
        const isFirst = this.subscribers.size === 0;
        super.subscribe(onChange);
        // If we've gained our first watcher, we want to update the current contents immediately, which also starts the
        // update timer.
        if (isFirst) {
            this.start();
        }
    }

    public unsubscribe(onChange: (newVal: T) => void) {
        if (this.subscribers.size === 0) return;

        super.unsubscribe(onChange);

        // No one is watching anymore, so let's stop the timer.
        if (this.subscribers.size === 0) {
            this.stop();
        }
    }

    public setPeriod(period: number) {
        this.period = period;
        this.stop();
        this.start();
    }

    // This is safe to call anytime
    private start(): void {
        if (this.inFlight) return;
        if (this.timeout !== undefined) return;
        if (this.subscribers.size === 0) return;

        const timeSinceLastUpdate = definedMap(this.lastUpdatedAt, t => Date.now() - t);
        if (timeSinceLastUpdate === undefined || timeSinceLastUpdate >= this.period) {
            void this.update();
        } else {
            this.timeout = setTimeout(() => {
                assert(this.timeout !== undefined);
                assert(this.subscribers.size > 0);
                this.timeout = undefined;
                void this.update();
            }, this.period - timeSinceLastUpdate);
        }
    }

    private stop(): void {
        if (this.timeout !== undefined) clearTimeout(this.timeout);
        this.timeout = undefined;
    }
}

export function waitForChangeObservableValue<T, U extends T>(
    co: ChangeObservable<T>,
    wantValue: (v: T) => v is U
): Promise<U> {
    return new Promise(resolve => {
        if (wantValue(co.current)) {
            resolve(co.current);
            return;
        }

        const onChange = (v: T) => {
            if (wantValue(v)) {
                co.unsubscribe(onChange);
                resolve(v);
            }
        };
        co.subscribe(onChange);
    });
}

export function makeChangeObservableForPromise<T>(promise: Promise<T>): ChangeObservable<T | undefined> {
    const watchable = new Watchable<T | undefined>(undefined);

    async function waitForValue() {
        const value = await promise;
        watchable.current = value;
    }

    void waitForValue();

    return watchable;
}
