import { nonNull } from "@glide/support";
import { useCallback, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ReactPlayer from "react-player";
import { GlideIcon } from "@glide/common";
import "twin.macro";
import { Img } from "@glide/wire-renderer";

// https://github.com/CookPete/react-player
interface OnProgressStatus {
    played: number; // goes from 0 to 1 where 1 means fully played
    playedSeconds: number;
    loaded: number; // goes from 0 to 1 where 1 means fully loaded
    loadedSeconds: number;
}

interface VideoPlayerProps {
    videoURL: string;
    thumbnailURL: string;
    onPlayVideo?: () => void;
    onCloseVideo?: (status: OnProgressStatus) => void;
}

export const VideoPlayer: React.VFC<VideoPlayerProps> = p => {
    const { thumbnailURL, videoURL, onPlayVideo, onCloseVideo } = p;
    const [showVideo, setShowVideo] = useState(false);
    const progressStatus = useRef({
        played: 0,
        playedSeconds: 0,
        loaded: 0,
        loadedSeconds: 0,
    });
    const playVideo = useCallback(() => {
        setShowVideo(true);

        onPlayVideo?.();
    }, [onPlayVideo]);

    const closeVideo = useCallback(() => {
        setShowVideo(false);

        onCloseVideo?.(progressStatus.current);
    }, [onCloseVideo]);

    const onProgress = useCallback((status: OnProgressStatus) => (progressStatus.current = status), []);

    let portal: React.ReactNode = <></>;

    if (showVideo) {
        portal = createPortal(
            <div
                onClick={closeVideo}
                tw="absolute animate-fade-in w-full h-full background-color[rgba(7, 13, 16, 0.3)] flex justify-center
                    items-center z-50 px-10 py-5 md:(px-20 py-10) lg:(px-40 py-20)">
                <div tw="relative shadow-2xl rounded-lg w-full aspect-ratio[16/9]">
                    <ReactPlayer
                        playing={true}
                        controls={true}
                        url={videoURL}
                        width="100%"
                        height="100%"
                        onProgress={onProgress}
                        tw="absolute rounded-lg overflow-hidden"
                    />
                    <button
                        onClick={closeVideo}
                        tw="p-2 text-n0 rounded-full bg-n800 hover:bg-n900 transition absolute top-0 -right-5 transform
                            translate-x-full">
                        <GlideIcon kind="stroke" icon="st-close" iconSize={24} />
                    </button>
                </div>
            </div>,
            nonNull(document.getElementById("portal"))
        );
    }
    return (
        <div tw="overflow-hidden relative">
            {portal}
            <Img tw="aspect-ratio[16/9] rounded-md" src={thumbnailURL} />
            <div tw="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex">
                <button tw="mt-4 hover:scale-110 transition transform" onClick={playVideo}>
                    <GlideIcon
                        kind="twotone"
                        icon="tt-large-play"
                        primaryColor="black"
                        iconSize={80}
                        accentColor="white"
                    />
                </button>
            </div>
        </div>
    );
};
