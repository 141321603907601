import * as iots from "io-ts";
import type { IconImage } from "./icon-image";
import { iconImageCodec } from "./icon-image";
import { experimentDefaultValues } from "./experiments";
import {
    propertyDescriptionCodec,
    screenDescriptionCodec,
    tabDescriptionCodec,
    userProfileDescriptionCodec,
} from "./description";
import { appKindCodec } from "@glide/location-common";
import { EmailClient } from "@glide/support";
import type { TableName } from "@glide/type-schema";
import type { SerializablePluginMetadata } from "@glide/plugins";
import { sourceMetadataCodec, tableNameCodec } from "@glide/type-schema";
import { sharedIntegration } from "./integration-types";
import type { BuilderAction } from "./builder-actions";
import { baseThemeCodec } from "@glide/base-theme";

type PrettyIOTS<T> = {
    [K in keyof T]: T[K] extends object ? PrettyIOTS<T[K]> : T[K];
} & {};

export type TypeOfIOTS<T extends iots.Any> = PrettyIOTS<iots.TypeOf<T>>;

export enum AppAuthenticationKind {
    Disabled = "disabled",
    Password = "password",
    EmailPin = "email-pin",
}

// This means don't allow authentication.  We use this in Pages.
const disabledAppAuthentication = iots.type({
    kind: iots.literal(AppAuthenticationKind.Disabled),
});

const passwordAppAuthentication = iots.intersection([
    iots.type({
        password: iots.string,
    }),
    iots.partial({
        kind: iots.literal(AppAuthenticationKind.Password),
    }),
]);
export type PasswordAppAuthentication = TypeOfIOTS<typeof passwordAppAuthentication>;

export enum AuthenticationEmailSource {
    Table = "table",
    OrgMembers = "org-members",
    JustMe = "just-me",
    UserProfiles = "user-profiles",
    AllowlistedDomains = "allowlisted-domains",
}

const emailWithPin = iots.type({
    email: iots.string,
    pin: iots.string,
});
export type EmailWithPin = TypeOfIOTS<typeof emailWithPin>;

const emailTableName = iots.union([iots.string, tableNameCodec]);

const emailPinAppAuthentication = iots.intersection([
    iots.type({
        kind: iots.literal(AppAuthenticationKind.EmailPin),
    }),
    iots.partial({
        // Default is `Table`.  In that case, if `emailTableName` is missing, the app is public.
        // For `OrgMembers` the `emailTableName` is ignored.
        source: iots.union([
            iots.literal(AuthenticationEmailSource.Table),
            iots.literal(AuthenticationEmailSource.OrgMembers),
            iots.literal(AuthenticationEmailSource.JustMe),
            iots.literal(AuthenticationEmailSource.UserProfiles),
            iots.literal(AuthenticationEmailSource.AllowlistedDomains),
        ]),
        emailTableName,
        // This is valid only if there's an `emailTableName`.
        additionalEmailsWithPins: iots.readonlyArray(emailWithPin),
        optional: iots.boolean, // defaults to `false`, currently only used in Pages
    }),
]);
export type EmailPinAppAuthentication = TypeOfIOTS<typeof emailPinAppAuthentication>;

export const appAuthenticationCodec = iots.union([
    disabledAppAuthentication,
    passwordAppAuthentication,
    emailPinAppAuthentication,
]);
const previousAuthenticationDetailsCodec = iots.partial({
    emailTableName,
    password: iots.string,
});

/**
 * This type is an unnormalized representation of the app authentication as it is stored in the database.
 * If you want a normalized version, see getAppAuthenticationData to provide a normalized AuthenticationData
 */
export type AppAuthentication = TypeOfIOTS<typeof appAuthenticationCodec>;

const userSettableAppFeatures = iots.partial({
    emailClient: iots.union([
        iots.literal(EmailClient.NativeMail),
        iots.literal(EmailClient.Gmail),
        iots.literal(EmailClient.MSOutlook),
    ]),
    requirePinForComments: iots.boolean,
    // undefined or <1 means don't refresh automatically
    automaticRefreshMinutes: iots.number,
    // Due to the location of the Google Analytics configuration,
    // we have to store it somewhere that the builder can always edit it.
    // Since the app description is always around when that side panel is displayed,
    // here's a good place to drop it.
    //
    // Since both the availability and the values are in the same features type,
    // here's what's up: You can always _have_ a measure ID, but you can't always
    // _use_ it. This makes it possible to downgrade your app, keep your measure ID,
    // then upgrade it again, and watch everything just start working again.
    googleAnalyticsMeasureIDs: iots.array(iots.string),
    showSignInWithEmailPin: iots.boolean, // defaults to `true`
    showSignInWithGoogle: iots.boolean,
    showSignInWithSSO: iots.boolean,
    useCustomSignInBackgroundImageInLoading: iots.boolean,
    enableShareScreen: iots.boolean,
    shareScreenTitle: iots.string,
    disableSharing: iots.boolean,
    defaultTabletMode: iots.boolean,
    virtualEmailAddresses: iots.boolean,
    askUserToSaveAuthCookie: iots.boolean,
    appKind: appKindCodec,
    useNCMOverWire: iots.boolean,
    useUnifiedApps: iots.boolean,
    canAppUserRequestAccess: iots.boolean,
    // We set this in `fixAppDescription` after we've converted legacy
    // actions so that we don't have to do it again because it's slow.
    noLegacyActions: iots.boolean,
    magicLinkInPinEmail: iots.boolean,
    compileCustomCss: iots.boolean,
    useGmailForSendEmails: sharedIntegration,
    actionsRecentRuns: iots.boolean,
    newAuthController: iots.boolean,
    shouldUsePlay2: iots.boolean,
    shouldUseVercelPlay: iots.boolean,
    // When copying a Classic App as a Page we need to support some legacy features.
    // In particular, the favorites column, which is special-cased in a lot of places.
    // The easiest way to make things work is to override that special case
    // in Pages that were copied from Classic Apps.
    // This flag is more general than just `showFavoritesInPage` cause I might need it for something else.
    pageCopiedFromClassic: iots.boolean,
});
export type UserSettableAppFeatures = TypeOfIOTS<typeof userSettableAppFeatures>;

export enum TemplateProvenance {
    None = "none",
    Free = "free",
    Paid = "paid",
}

const persistentAppFeatures = iots.partial({
    templateProvenance: iots.union([
        iots.literal(TemplateProvenance.None),
        iots.literal(TemplateProvenance.Free),
        iots.literal(TemplateProvenance.Paid),
    ]),
});
export type PersistentAppFeatures = TypeOfIOTS<typeof persistentAppFeatures>;

export const nonUserAppFeaturesCodec = iots.intersection([
    userSettableAppFeatures,
    iots.partial({
        isTrialLocked: iots.boolean,
    }),
    persistentAppFeatures,
]);
export type NonUserAppFeatures = TypeOfIOTS<typeof nonUserAppFeaturesCodec>;

const baseAppLoginDataCodec = iots.intersection([
    iots.type({
        title: iots.string,
        theme: baseThemeCodec,
    }),
    iots.partial({
        description: iots.string,
        author: iots.string,
        iconImage: iconImageCodec,
        features: nonUserAppFeaturesCodec,
        pagesCustomCss: iots.string,
        templateAuthorEmail: iots.string,
    }),
]);
type BaseAppLoginData = TypeOfIOTS<typeof baseAppLoginDataCodec>;

export const pluginConfigParametersCodec = iots.record(iots.string, propertyDescriptionCodec);

const enabledNotificationSenderCodec = iots.type({
    // This ID of the notification sender.  There usually won't be more than
    // one per plugin, but we want to make this general from the get-go.  We
    // might eventually want Twilio to be able to provide sending SMS as well
    // as sending WhatsApp messages, for example.
    notificationSenderID: iots.string,
});

export const pluginConfigCodec = iots.intersection([
    iots.type({
        // This is the ID of the plugin.  We can have more than one plugin config
        // for the same plugin.  They will have the same `pluginID`, but different
        // `configID`s.
        pluginID: iots.string,
        parameters: pluginConfigParametersCodec,
    }),
    iots.partial({
        // This is to identify which plugin is used by which action.  It is also
        // the (instance) key to the OAuth credentials table on the backend.
        configID: iots.string,
        // The notification senders that are enabled for this plugin config.
        notificationSenders: iots.readonlyArray(enabledNotificationSenderCodec),
    }),
]);
export type PluginConfig = TypeOfIOTS<typeof pluginConfigCodec>;

export const appDescriptionCodec = iots.intersection([
    baseAppLoginDataCodec,
    iots.type({
        screenDescriptions: iots.record(iots.string, screenDescriptionCodec),
    }),
    iots.partial({
        // Legacy
        // Do not access these directly; use getSourceMetadata instead.
        sourceMetadata: sourceMetadataCodec,
        sourceMetadataArray: iots.readonlyArray(sourceMetadataCodec),
        tabs: iots.readonlyArray(tabDescriptionCodec),
        authentication: appAuthenticationCodec,
        previousAuthenticationDetails: previousAuthenticationDetailsCodec,
        allowedEmailDomains: iots.readonlyArray(iots.string),
        userProfile: userProfileDescriptionCodec,
        pluginConfigs: iots.readonlyArray(pluginConfigCodec),
        pluginActionIDs: iots.readonlyArray(iots.string),
    }),
]);
export type AppDescription = TypeOfIOTS<typeof appDescriptionCodec>;

export interface AppManifest {
    readonly short_name?: string;
    readonly name: string;
    readonly description?: string;
    readonly author?: string;
    readonly icons: ReadonlyArray<AppManifestIcon>;
    readonly start_url: string;
    readonly background_color: string;
    readonly display: string;
    readonly scope?: string;
    readonly theme_color: string;
    glidePWAAddToHead?: string;
}

export interface AppManifestIcon {
    readonly src: string;
    readonly type: string;
    readonly sizes: string;
    readonly purpose?: string;
}

// NOTE: Some plugins also have a ##pluginUserFeature, but these are not
// enumerated here, and are thus not in the `UserFeatures` type.  The reason
// for that is that we can't depend on the plugins package here, and we don't
// want to keep this object in sync with plugins.
export const defaultUserFeatures = {
    ...experimentDefaultValues,
    // You might be tempted to check for this in the player. Don't. It gets copied to Eminence, where it should be.
    // Check there.
    removeBranding: false,
    primaryKeyProperties: false,
    addRowToSheet: false,
    emailClientOption: false,
    omitNotifications: false,
    copyToClipboardAction: false,
    advancedPro: false,
    adminPanel: false,
    appStoreReview: false,
    // This property will allow the user to ignore app eminence and bypass checks
    // for if an app is submittable as a template. If it is set to true, this will allow
    // apps copied from a template to be resubmitted as well as allowing org members to
    // submit any of the org's apps as templates
    forceAllowTemplateSubmit: false,
    forceSoftEnforcement: false,
    forceHardEnforcement: false,
    autoApproveTemplate: false,
    // Promo codes are typically only applicable to new customers.
    // Existing non-customer users are considered new customers.
    canApplyPromoCodes: false,
    alternateUniverseGlide: false,
    massScanBarcodes: false,
    unlimitedBarcodeScanning: false,
    offlineActionQueue: false,
    ncmPerformanceAnalysis: false,
    dbrScannerHandshake: undefined as string | undefined,
    nonBlockingButton: false,
    // If this is "true" then the user cannot even opt in to tracking,
    // it's just disabled. We primarily use this for Enterprise accounts.
    builderTrackingDisableAnalytics: false,
    billingVNoneCodePaths: false,
    onChangeAction: false,
    billingVnext: false,
    hasV3TeamBasedPricing: false,
    isV3FreePlan: false,
    payAsYouGoAddon: false,
    pagesCustomCss: false,
    allowNCMOverWire: false,
    hasMigratedTeamTemplates: false,
    unifiedApps: false,
    privateMagicLinks: false,
    authenticationSupportPassword: false,
    authenticationSupportEmailWhitelist: false,
    appAnalytics: false,
    yesCodeAction: false,
    queryableNativeTables: false,
    jdbcDataSources: false,
    usePgMirrorCheckpointing: false,
    overrideAllowClassicApps: false,
    showLockout: false,
    billingLockout: false,
};

export type UserFeatures = Readonly<typeof defaultUserFeatures>;
export type UserFeatureName = keyof UserFeatures;

export interface AppFeatures extends UserFeatures, NonUserAppFeatures {}

interface BaseSerializedAppLoginData extends BaseAppLoginData {
    readonly manifest?: AppManifest;
    readonly shortName?: string;
    readonly customDomain?: string;
    readonly features: AppFeatures | undefined;
}

export interface SerializedSearchableColumns {
    readonly columnsUsedByTable: readonly [TableName, readonly string[]][];
    readonly columnsUsedByInlineLists: readonly [string, readonly string[]][];
}

export interface SerializedApp extends AppDescription, BaseSerializedAppLoginData {
    readonly features: AppFeatures | undefined;

    readonly allowEmbed?: boolean;

    // This is a copy of the builder actions used by this app.  The wire app
    // uses it to actually run the actions, but even OCM uses it to verify
    // mutation actions.  If it's not available here we fall back to the
    // builder actions in Firestore.
    // https://github.com/quicktype/glide/issues/14228
    readonly builderActions: Record<string, BuilderAction> | undefined;

    readonly pluginMetadata: readonly SerializablePluginMetadata[] | undefined;

    readonly searchableColumns?: SerializedSearchableColumns;
}

// ##appLoginData:
// If we can we should unify this with `PublishedApp`.
export interface AppLoginData extends BaseSerializedAppLoginData {
    readonly manifest: AppManifest;
    readonly features: AppFeatures;
    readonly iconImage: IconImage;
    readonly lastPasswordID?: string;
}
