import { logError } from "@glide/support";
import { hasOwnProperty } from "collection-utils";
import styled from "styled-components";

import { massageImageUrl } from "../components/portable-renderers";
import type { IconImage } from "@glide/app-description";
import { IconWrapper, ImageStyle } from "./style";
import { systemFontFamily } from "@glide/base-theme";

export interface IconSize {
    filename: string;
    size: number;
}

interface IconProps {
    image?: IconImage;
    size: number;
    color: string;
    clipCorners: boolean;
    className?: string;
    loading?: boolean;
    pwaSafeArea?: boolean;
    onClick?(): void;
    pointerOnHover?: boolean;
    omitShadow?: boolean;
    lightGradientOverlay?: boolean;
    inset?: boolean;
    rounding?: number;
    fullscreen?: boolean;
    appID: string;
}

const LoadingLabel = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: ${systemFontFamily};
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: white;
`;

const bombURL = "https://cdn.jsdelivr.net/gh/mkabumattar/fluentui-emoji@latest/icons/modern/bomb.svg";

export function Icon({
    size,
    color,
    image,
    clipCorners,
    className,
    onClick,
    loading,
    pointerOnHover,
    pwaSafeArea,
    omitShadow,
    rounding,
    lightGradientOverlay = false,
    inset = false,
    fullscreen = false,
    appID,
}: IconProps): JSX.Element {
    const innerSizeRatio = pwaSafeArea ? 9 / 16 : 0.75;
    const svgSize = Math.round(size * innerSizeRatio);
    const roundingSize = rounding ? rounding : size / 4.363636364;

    if (loading === undefined) loading = false;

    const svgContainerStyle: React.CSSProperties = {
        position: "absolute",
        transform: "translate3d(-50%, -50%, 0)",
        width: svgSize,
        height: svgSize,
        top: "50%",
        left: "50%",
    };

    let withContainer: boolean = false;
    let content: JSX.Element | null = null;
    if (loading || image === undefined) {
        content = <LoadingLabel>{loading ? "Uploading…" : ""}</LoadingLabel>;
    } else {
        let url: string;

        if (hasOwnProperty(image, "url")) {
            url =
                massageImageUrl(image.url, { thumbnail: false, width: size * 2, height: size * 2 }, appID) ?? image.url;
        } else {
            if (hasOwnProperty(image, "emoji")) {
                url = "https://functions.prod.internal.glideapps.com/getEmoji/" + image.emoji;
            } else {
                logError("Icon image not recognized", image);
                url = bombURL;
            }
            withContainer = true;
        }

        content = (
            <ImageStyle
                roundingSize={roundingSize}
                clipped={!withContainer && clipCorners}
                src={url}
                padding={inset ? 14 : undefined}
                lightGradientOverlay={lightGradientOverlay}
                draggable={false}
            />
        );
        if (withContainer) {
            content = <div style={svgContainerStyle}>{content}</div>;
        }
    }

    return (
        <IconWrapper
            size={size}
            roundingSize={roundingSize}
            background={color}
            clipped={clipCorners}
            className={className}
            onClick={onClick}
            omitShadow={omitShadow === true}
            showPointer={pointerOnHover ?? false}
            fullscreen={fullscreen}>
            {content}
        </IconWrapper>
    );
}
