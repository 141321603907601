import type { ArrayScreenDescription, PropertyDescription } from "@glide/app-description";
import {
    ArrayScreenFormat,
    getActionProperty,
    getColumnProperty,
    getEnumProperty,
    getSourceColumnProperty,
    getSwitchProperty,
    makeActionProperty,
    makeArrayProperty,
    makeEnumProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import { GroupingSupport, ListItemFlags } from "@glide/component-utils";
import { WireImageFallback } from "@glide/fluent-components/dist/js/base-components";
import type {
    AppDescriptionContext,
    InlineListComponentDescription,
    PropertyDescriptor,
    PropertyTableGetter,
} from "@glide/function-utils";
import {
    EnumPropertyHandler,
    getTitlePropertyFromInlineListCaption,
    PropertySection,
    SwitchPropertyHandler,
} from "@glide/function-utils";
import {
    type WireTableComponentHydratorConstructor,
    type WireInflationBackend,
    CardStyle,
    UIImageStyle,
} from "@glide/wire";

import { getActionsForArrayContent } from "../components/component-utils";
import { makeCaptionPropertyDescriptor, useFallbackInitialsPropertyHandler } from "../components/descriptor-utils";
import { ListItemSize } from "../components/list-types";
import { type WireStringGetter, inflateActionsWithCanAutoRun, inflateStringProperty } from "../wire/utils";
import {
    type FallbackInitialsSummaryArrayContentDescription,
    type FallbackInitialsSummaryArrayScreenDescription,
    FallbackInitialsSummaryArrayScreenHandler,
    getNumberToTruncateTo,
    inflateSummary,
    makeListListHydrator,
} from "./summary-array-screen";
import { ComponentKindInlineList, makeEmptyComponentDescription } from "@glide/common-core";
import type { TableGlideType } from "@glide/type-schema";
import { getTableName } from "@glide/type-schema";
import type { CardCollectionComponentDescription } from "@glide/fluent-components/dist/js/fluent-components";
import { definedMap } from "collection-utils";
import type { ComponentScreenContextForConversion } from "./array-content";

const allowWrappingPropertyHandler = new SwitchPropertyHandler(
    { allowWrapping: false },
    "Allow text wrapping",
    PropertySection.TextStyle
);

const itemSizePropertyHandler = new EnumPropertyHandler(
    { itemSize: ListItemSize.Regular },
    "Item Size",
    "Item Size",
    [
        {
            value: ListItemSize.Regular,
            label: "Regular",
            icon: "listOrientationVertical",
        },
        {
            value: ListItemSize.Compact,
            label: "Compact",
            icon: "itemSizeCompact",
        },
    ],
    PropertySection.Design,
    "small-images"
);

export interface ListArrayContentDescription extends FallbackInitialsSummaryArrayContentDescription {
    readonly captionProperty?: PropertyDescription;
    readonly itemSize?: PropertyDescription;
}

export interface ListArrayScreenDescription
    extends FallbackInitialsSummaryArrayScreenDescription,
        ListArrayContentDescription {}

function getItemSize(desc: ListArrayContentDescription | undefined): ListItemSize {
    return getEnumProperty(desc?.itemSize) ?? ListItemSize.Regular;
}

export class ListArrayScreenHandler extends FallbackInitialsSummaryArrayScreenHandler<
    ListArrayContentDescription,
    ListArrayScreenDescription
> {
    protected readonly supportsNonURLImages = false;
    protected readonly supportsEmojiImages = true;
    protected readonly supportsTruncateList = true;

    constructor() {
        super(ArrayScreenFormat.List, "List", "list", false);
    }

    protected get groupingSupport(): GroupingSupport {
        return GroupingSupport.Regular;
    }

    protected getInterSummaryPropertyDescriptors<T extends ListArrayContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        _insideInlineList: boolean,
        desc: T | undefined
    ): readonly PropertyDescriptor[] {
        if (getItemSize(desc) === ListItemSize.Regular) {
            return [makeCaptionPropertyDescriptor(getPropertyTable, true, PropertySection.Content)];
        } else {
            return [];
        }
    }

    public getBasicSearchProperties(desc: ListArrayContentDescription): readonly string[] {
        const props = super.getBasicSearchProperties(desc);
        const captionColumn = getColumnProperty(desc.captionProperty);
        if (captionColumn === undefined) {
            return props;
        } else {
            return [...props, captionColumn];
        }
    }

    public getAdditionalPropertyDescriptors<T extends ListArrayContentDescription>(
        _getPropertyTable: PropertyTableGetter | undefined,
        _insideInlineList: boolean,
        _desc: T | undefined
    ): ReadonlyArray<PropertyDescriptor> {
        return [allowWrappingPropertyHandler, itemSizePropertyHandler];
    }

    public inflateContent<T extends ListArrayContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        captionGetter: WireStringGetter | undefined,
        containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        const itemSize = getItemSize(desc);

        const summaryGetters = inflateSummary(ib, desc);
        const [itemCaptionGetter, itemCaptionType] =
            itemSize === ListItemSize.Regular
                ? inflateStringProperty(ib, desc.captionProperty, true)
                : [undefined, undefined];

        const allowWrapping = allowWrappingPropertyHandler.getSwitch(desc);
        const useFallbackInitials = useFallbackInitialsPropertyHandler.getSwitch(desc);

        let flags: ListItemFlags = ListItemFlags.DrawSeparator | ListItemFlags.DisableLongPress;
        if (itemSize === ListItemSize.Compact) {
            flags |= ListItemFlags.SmallImage | ListItemFlags.HorizontalLayout;
        }
        if (allowWrapping) {
            flags |= ListItemFlags.WrapText;
        }

        let icon: string | undefined;
        if (itemSize === ListItemSize.Compact || itemCaptionType === undefined) {
            const iconAndFlags = this.makeIconAndFlagsForDefaultAction(desc, flags, ib.adc);
            flags = iconAndFlags.flags;
            icon = iconAndFlags.icon;
        } else {
            flags |= ListItemFlags.SwapImageSide + ListItemFlags.LargeImage + ListItemFlags.DisableChevron;
        }

        const numToTruncate = getNumberToTruncateTo(desc, containingRowIB !== undefined, 1, this.groupingSupport);

        const { actionHydrator, canAutoRunAction } = inflateActionsWithCanAutoRun(
            ib,
            getActionsForArrayContent(this, ib.tables, desc, ib.adc).actions
        );

        if (actionHydrator === undefined) {
            flags |= ListItemFlags.Inert + ListItemFlags.DisableChevron;
        }

        return makeListListHydrator(
            ib,
            itemSize === ListItemSize.Regular ? ArrayScreenFormat.List : ArrayScreenFormat.SmallList,
            componentID,
            flags,
            allowWrapping,
            useFallbackInitials
                ? this.fullTitleFallback
                    ? WireImageFallback.Title
                    : WireImageFallback.Initials
                : WireImageFallback.None,
            captionGetter,
            summaryGetters,
            this.makeGroupByGetters(ib, desc),
            numToTruncate,
            actionHydrator,
            canAutoRunAction,
            icon,
            itemCaptionGetter
        );
    }

    private convertContentToPage(desc: ListArrayContentDescription) {
        return {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            format: makeEnumProperty(ArrayScreenFormat.CardCollection),
            caption: undefined,
            cardStyle: makeEnumProperty(CardStyle.List),
            transforms: desc.transforms,
            action: definedMap(getActionProperty(desc.actions), a => makeActionProperty(a)),
            title: definedMap(getSourceColumnProperty(desc.titleProperty), sc => makeSourceColumnProperty(sc)),
            subtitle: definedMap(getSourceColumnProperty(desc.subtitleProperty), sc => makeSourceColumnProperty(sc)),
            image: definedMap(getSourceColumnProperty(desc.imageURLProperty), sc => makeSourceColumnProperty(sc)),
            emphasis: definedMap(getSourceColumnProperty(desc.captionProperty), e => makeSourceColumnProperty(e)),
            groupByColumn: desc.groupByColumn,
            imageStyle: makeEnumProperty(UIImageStyle.Circle),
        };
    }

    public convertInlineToPage(
        desc: InlineListComponentDescription & ListArrayContentDescription
    ): CardCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: desc.propertyName,
            allowSearch: desc.allowSearch,
            componentTitle: getTitlePropertyFromInlineListCaption(desc),
        };
    }

    public convertArrayScreenToPage(
        desc: ArrayScreenDescription & ListArrayContentDescription,
        table: TableGlideType,
        adc: AppDescriptionContext,
        screenContext: ComponentScreenContextForConversion
    ): CardCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: makeTableProperty(getTableName(table)),
            allowSearch: makeSwitchProperty(getSwitchProperty(desc.search)),
            titleActions: definedMap(screenContext.titleAction, a => makeArrayProperty([a])),
            componentTitle: definedMap(screenContext.screenTitle, t => makeStringProperty(t)),
            multipleDynamicFilters: this.getDynamicMultipleFiltersForPageConversion(
                adc,
                table,
                desc.dynamicFilterColumn,
                desc.pivots
            ),
        };
    }
}
