import {
    type Description,
    type TableName,
    areTableNamesEqual,
    isFavoritedColumnName,
    type ColumnType,
    type SourceColumn,
    type TableGlideType,
    makeArrayType,
    makePrimitiveType,
    getPrimitiveNonComputedNonHiddenColumns,
    getSourceColumnPath,
    getTableName,
    isColumnNonHidden,
    isSingleRelationType,
} from "@glide/type-schema";
import {
    type GroundValue,
    type LoadedGroundValue,
    type LoadingValue,
    type Row,
    Table,
    isLoadingValue,
    isPrimitiveValue,
    type RowIndex,
    isBound,
} from "@glide/computation-model-types";
import {
    asMaybeArrayOfStrings,
    asMaybeJSONValueForColumnType,
    asMaybeNumber,
    asMaybeString,
    asString,
    isRow,
    parseValueAsGlideDateTimeSync,
    asMaybeBoolean,
    asJSONValue,
} from "@glide/common-core/dist/js/computation-model/data";
import { getRowIndexForRow } from "@glide/common-core/dist/js/computation-model/row-index";
import { areRowIndexesConflicting } from "@glide/common-core/dist/js/Database";
import {
    type ActionDescription,
    type ActionKind,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    isPropertyDescription,
    getArrayProperty,
    getEnumProperty,
    getSourceColumnProperty,
    makeColumnProperty,
    type ActionOutputDescriptor,
    type PluginConfig,
} from "@glide/app-description";
import { makeInputOutputTables } from "@glide/common-core/dist/js/description";
import { reportBillable } from "@glide/backend-api";
import type {
    RunIntegrationsInstance,
    WebhookWriteBackTo,
    WebhookWriteBackToColumn,
    WritebackResponse,
} from "@glide/common-core/dist/js/firebase-function-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { frontendTrace } from "@glide/common-core/dist/js/tracing";
import { GlideDateTime, GlideJSON } from "@glide/data-types";
import {
    type AppDescriptionContext,
    type ColumnPropertyDescriptorFlags,
    type PropertyDescriptor,
    ArrayPropertyStyle,
    EnumPropertyHandler,
    PropertySection,
    RequiredKind,
    SwitchPropertyHandler,
    makeColumnCase,
    makeJSONPathPropertyDescriptor,
    makeNumberPropertyDescriptor,
    makeInlineTemplatePropertyDescriptor,
    makeTableViewPropertyDescriptor,
    type ActionAvailability,
} from "@glide/function-utils";
import pack from "@glide/glide-plugins/client";
import { AppKind } from "@glide/location-common";
import {
    type Computation,
    type Action,
    type ActionProps,
    type AppData,
    type JSONValue,
    type NativePlugin,
    type ParameterProps,
    type Plugin,
    type PluginProps,
    type PluginTable,
    type PluginTierList,
    type BillablesConsumed,
    type GlideIconProps as GlideIcon,
    ClientExecutionContextBase,
    Result,
    WaitForSignalResult,
    defaultFetchTimeout,
    isResult,
} from "@glide/plugins";
import { type ParameterPropsBase, isSyncEnumValues, valueRecordCodec } from "@glide/plugins-codecs";
import {
    findAction,
    makePluginActionKind,
    wrapFetchForRetries,
    pluginResultToGlideType,
    pluginResultToGlideTypeWithParameterProps,
} from "@glide/plugins-utils";
import {
    ArrayMap,
    DefaultArrayMap,
    allSettled,
    isDefined,
    isEmptyOrUndefined,
    isEmptyOrUndefinedish,
    logError,
    maybeParseJSON,
    removeUndefinedProperties,
    isResponseOK,
} from "@glide/support";
import {
    type WireActionResult,
    type WireRowHydrationValueProvider,
    type WireValueGetterGeneric,
    type WireActionResultBuilder,
    makeContextTableTypes,
    type WireActionBackend,
    type WireActionHydrator,
    type WireActionInflationBackend,
} from "@glide/wire";
import {
    assert,
    assertNever,
    DefaultMap,
    defined,
    exceptionToString,
    hasOwnProperty,
    mapFilterUndefined,
    mapRecord,
} from "@glideapps/ts-necessities";
// eslint-disable-next-line lodash/import-scope
import type { Dictionary } from "lodash";
import fromPairs from "lodash/fromPairs";
import { registerActionHandler } from "../actions";
import { type ActionDescriptor, ActionDisabledReason } from "../actions/action-descriptor";
import { BaseActionHandler, tokenForProperty } from "../actions/base";
import {
    inflateNamesAndValues,
    makeNamesAndJSONValuesPropertyDescriptor,
    makeNamesAndStringValuesPropertyDescriptor,
} from "../actions/kvp-in-out-action";
import { makeTextPropertyDescriptorWithSpecialValue } from "../components/descriptor-utils";
import { inflatePropertyWithLoading } from "../wire/utils";
import { type Parameter, EMPTY_WARNING_TEXT, isParameterActive, preProcessParameters } from "./parameters";
import { makeParameterSourceColumnType } from "@glide/common-core/dist/js/computation-model/make-parameter-source-column-type";
import { type DescriptionToken, actionAvailabilityApps, actionAvailabilityBoth } from "../actions/action-handler";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import type { StaticActionContext } from "../static-context";
import capitalize from "lodash/capitalize";
import last from "lodash/last";
import { pluginValueToCellValue } from "@glide/common-core/dist/js/serialization";
import { getFeatureSetting } from "@glide/common-core";
import { getPluginTypeForGlideType, getTypeKindForPrimitivePluginType } from "./convert-types";

interface ResultAndWriteTo {
    readonly result: PropertyDescription;
    readonly writeTo: PropertyDescription;
}

type PluginResult = ParameterProps & {
    // The internal result name
    readonly resultName: string;
    // The name of the property in the action description
    readonly propertyName: string;
};

enum PropertyTransformation {
    Capitalize = "capitalize",
    RemoveProtocol = "removeProtocol",
}

function transformProperty(value: string, transformation: PropertyTransformation | undefined): string {
    switch (transformation) {
        case PropertyTransformation.Capitalize:
            return capitalize(value);
        case PropertyTransformation.RemoveProtocol:
            return value.replace(/(^\w+:|^)\/\//, "");
        default:
            return value;
    }
}

function getDescriptionPropertyWithTransformation(text: string): {
    propName: string;
    transformation?: PropertyTransformation;
} {
    for (const t of [PropertyTransformation.Capitalize, PropertyTransformation.RemoveProtocol]) {
        if (text.startsWith(`${t}(`) && text.endsWith(")")) {
            return {
                propName: text.slice(t.length + 1, -1),
                transformation: t,
            };
        }
    }
    return {
        propName: text,
    };
}

export function makeParameterPropertyDescriptors(
    appID: string,
    pluginID: string,
    pluginConfig: PluginConfig | undefined,
    parameters: readonly Parameter[],
    mutatingScreenKind: MutatingScreenKind | undefined,
    desc: Description | undefined,
    actionOrColumn: "action" | "column",
    forComputation: boolean
): readonly PropertyDescriptor[] {
    return mapFilterUndefined(parameters, p => {
        if (!isParameterActive(p, parameters, desc, actionOrColumn)) return undefined;

        if (p.type === "stringObject") {
            const stringObjectCase = makeNamesAndStringValuesPropertyDescriptor(
                p.propertyName,
                mutatingScreenKind,
                p.required === true,
                p.withSecretConstants
            );
            return {
                ...stringObjectCase,
                section: p.propertySection ?? PropertySection.Data,
                helpText: p.description,
            };
        }

        if (p.type === "jsonObject") {
            const jsonObjectCase = makeNamesAndJSONValuesPropertyDescriptor(
                p.propertyName,
                mutatingScreenKind,
                p.required === true
            );
            return {
                ...jsonObjectCase,
                section: p.propertySection ?? PropertySection.Data,
                helpText: p.description,
            };
        }

        if (p.type === "table") {
            const tableCase = makeTableViewPropertyDescriptor(
                mutatingScreenKind,
                p.name,
                PropertySection.Data,
                {
                    name: p.propertyName,
                },
                p.required ?? false
            );
            return { ...tableCase, helpText: p.description };
        }
        if (p.type === "stringArray") {
            const columnCase = makeColumnCase(
                p.required === true,
                {
                    searchable: false,
                    isEditedInApp: false,
                    applyFormat: false,
                    emptyByDefault: true,
                    propertySection: p.propertySection ?? PropertySection.Data,
                    // Single strings are allowed as inputs to stringArray columns.
                    // We just lift the single string into an array before we send
                    // it off to the plugin.
                    columnFilter: {
                        getCandidateColumns: t => t.columns,
                        columnTypeIsAllowed: t => {
                            return (
                                (t.kind === "array" && getPluginTypeForGlideType(t.items) === "string") ||
                                getPluginTypeForGlideType(t) === "string"
                            );
                        },
                    },
                },
                "array"
            );
            const inputCase = {
                kind: PropertyKind.String,
                required: p.required === true,
                placeholder: p.placeholder,
                isMultiLine: false,
                isImageURL: false,
                menuLabel: "Custom",
            };

            return {
                property: { name: p.propertyName },
                label: p.name,
                section: p.propertySection ?? PropertySection.Data,
                emptyWarningText: p.required === true ? EMPTY_WARNING_TEXT : undefined,
                cases: [columnCase, inputCase],
                helpText: p.description,
            };
        }
        if (p.type === "generatedKeyPair") {
            // TODO: make property configurator for key pair if/when we need
            // it outside of plugin parameters
            return undefined;
        }
        const typeKind = getTypeKindForPrimitivePluginType(p.type);
        if (typeKind === undefined) return undefined;

        const flags: ColumnPropertyDescriptorFlags = {
            preferredNames: [p.name, ...(p.preferredNames ?? [])],
            preferredType: typeKind,
            searchable: false,
            applyFormat: p.withFormat,
            helpText: p.description,
            propertySection: p.propertySection,
            emptyWarningText: p.required === true ? EMPTY_WARNING_TEXT : undefined,
            emptyByDefault: p.emptyByDefault,
        };
        if (p.type === "number") {
            return makeNumberPropertyDescriptor(
                p.propertyName,
                p.name,
                p.placeholder ?? "",
                p.required === true ? RequiredKind.Required : RequiredKind.NotRequiredDefaultMissing,
                p.defaultValue ?? 0,
                mutatingScreenKind,
                { ...flags, columnFirst: p.defaultValue === undefined }
            );
        } else if (p.type === "enum") {
            if (isSyncEnumValues(p.values)) {
                return new EnumPropertyHandler(
                    { [p.propertyName]: p.defaultValue ?? p.values[0].value },
                    p.name,
                    p.name,
                    p.values,
                    p.propertySection ?? PropertySection.Data,
                    "small-images",
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    p.description
                );
            } else {
                const copy = p.values;
                return new EnumPropertyHandler(
                    { [p.propertyName]: p.defaultValue },
                    p.name,
                    p.name,
                    async () => {
                        const appFacilities = getAppFacilities();
                        const r = await appFacilities.callAuthCloudFunction("fetchAsyncEnum", {
                            appID,
                            key: copy.fetchKey,
                            pluginID,
                            pluginConfigID: pluginConfig?.configID,
                            pluginParams: pluginConfig?.parameters ?? {},
                        });
                        if (r === undefined || !r.ok) return [];
                        const json = await r.json();
                        return json ?? [];
                    },
                    p.propertySection ?? PropertySection.Data,
                    "dropdown",
                    undefined,
                    undefined,
                    undefined,
                    copy.defaultDisplayLabel,
                    p.description
                );
            }
        } else if (p.type === "boolean") {
            return new SwitchPropertyHandler(
                { [p.propertyName]: p.defaultValue ?? false },
                p.name,
                p.propertySection ?? PropertySection.Data
            );
        } else if (p.type === "string" && p.useTemplate !== undefined && p.useTemplate !== false && !forComputation) {
            return makeInlineTemplatePropertyDescriptor(
                p.propertyName,
                p.name,
                p.placeholder ?? "",
                p.required ?? false,
                p.useTemplate,
                mutatingScreenKind,
                {
                    ...flags,
                    defaultValue: p.defaultValue,
                    columnFirst: p.defaultValue === undefined,
                    helpText: p.description,
                },
                undefined,
                {
                    kind: PropertyKind.SpecialValue,
                    excludePrimitiveValues: false,
                    withActionSource: false,
                    withClearColumn: false,
                }
            );
        } else if (p.type === "string" || p.type === "url" || p.type === "secret") {
            return makeTextPropertyDescriptorWithSpecialValue(
                p.propertyName,
                p.name,
                p.placeholder ?? "",
                p.required === true,
                mutatingScreenKind,
                { ...flags, defaultValue: p.defaultValue, columnFirst: p.defaultValue === undefined }
            );
        } else if (p.type === "dateTime") {
            return makeTextPropertyDescriptorWithSpecialValue(
                p.propertyName,
                p.name,
                p.placeholder ?? "",
                p.required === true,
                mutatingScreenKind,
                { ...flags, defaultValue: p.defaultValue?.toString(), columnFirst: p.defaultValue === undefined }
            );
        } else if (p.type === "jsonPath") {
            return makeJSONPathPropertyDescriptor(
                p.propertyName,
                p.name,
                p.placeholder ?? "",
                p.required === true,
                mutatingScreenKind,
                flags,
                p.valueFromProperty,
                actionOrColumn
            );
        } else {
            return makeTextPropertyDescriptorWithSpecialValue(
                p.propertyName,
                p.name,
                p.placeholder ?? "",
                p.required === true,
                mutatingScreenKind,
                { ...flags, columnFirst: true }
            );
        }
    });
}

export function makeOutputDescriptorsForResults(
    results: Record<string, ParameterPropsBase>
): readonly ActionOutputDescriptor[] {
    return mapFilterUndefined(Object.entries(results), ([name, p]) => {
        let type: ColumnType;
        switch (p.type) {
            case "string":
            case "number":
            case "boolean":
                type = makePrimitiveType(p.type);
                break;
            case "url":
                type = makePrimitiveType("uri");
                break;
            case "dateTime":
                type = makePrimitiveType("date-time");
                break;
            case "json":
            case "jsonObject":
                type = makePrimitiveType("json");
                break;
            case "array":
            case "stringArray":
                type = makeArrayType(makePrimitiveType("string"));
                break;
            case "enum":
            case "object":
            case "stringObject":
            case "table":
            case "jsonPath":
            case "secret":
            case "generatedKeyPair":
                return undefined;
            default:
                return assertNever(p);
        }

        return {
            name,
            displayName: p.name,
            description: p.description,
            type,
        };
    });
}

async function transformTableToPluginTableType(
    vp: WireRowHydrationValueProvider,
    tableType: TableGlideType,
    gotten: Table,
    selectedColumns: Dictionary<WireValueGetterGeneric<GroundValue>>,
    nameOverrides: Record<string, string>
): Promise<PluginTable | undefined> {
    const columns = mapFilterUndefined(tableType.columns, c => {
        if (selectedColumns[c.name] === undefined) return undefined;
        // Favorites don't make a whole lot of sense in plugin output.
        // FIXME: We should have a better mechanism for handling this.
        if (!isColumnNonHidden(c, false) || c.name === isFavoritedColumnName) return undefined;
        const type = getPluginTypeForGlideType(c.type);
        if (type === undefined || type === "table" || type === "generatedKeyPair" || type === "jsonPath")
            return undefined;
        return {
            name: c.name,
            displayName: nameOverrides[c.name] ?? c.displayName,
            type,
        };
    });
    const columnSchedule = columns.map(c => c.name);

    const rows: PluginTable["rows"] = [];
    const allPromises = gotten.asArray().map(async (rowObject, index) => {
        if (rowObject.$isVisible === false) return;
        const rowVp = vp.makeHydrationBackendForRow(rowObject, undefined, undefined);
        await rowVp.listenForChanges(20_000, () => {
            let loading = false;
            const row = columnSchedule.map(col => {
                const colFromObj = selectedColumns[col]?.(rowVp);
                if (isLoadingValue(colFromObj)) {
                    loading = true;
                    return null;
                }

                // FIXME: Handle arrays and such
                if (!isPrimitiveValue(colFromObj)) return null;
                if (colFromObj instanceof GlideDateTime) return colFromObj;
                if (colFromObj instanceof GlideJSON) return colFromObj;
                if (typeof colFromObj === "boolean") return colFromObj ? "true" : "false";
                return colFromObj ?? null;
            });
            if (loading) return false;

            rows[index] = row;
            return true;
        });
    });

    await allSettled(allPromises);

    return { columns, rows: rows.filter(isDefined), name: tableType.sheetName ?? getTableName(tableType).name };
}

// If/when we support multiple instances of the same plugin in one project we
// will have to add the plugin config ID to the description here.  That will
// involve adding a new `PropertyKind.PluginConfig` that allows users to pick
// which one of their configs for a plugin they want to use for which action.
// The migration from here to there is probably that we will treat actions
// that don't have a plugin config ID as if they were configured to use
// whichever config there is in the app, so that it just works.  In the case
// where there is more that one plugin configured, i.e. where it's ambiguous,
// we might want to not run the action, because it might have bad consequences
// to do that on the wrong config.
interface DynamicActionDescription extends ActionDescription {}

// FIXME: it sucks that we duplicate this in `async-computations.ts`
class ClientExecutionContext extends ClientExecutionContextBase {
    constructor(
        private appFacilities: ActionAppFacilities,
        private appID: string,
        private readonly ab: WireActionBackend,
        app: AppData,
        action: Action | Computation,
        plugin: Plugin | NativePlugin
    ) {
        super(
            app,
            action,
            plugin,
            wrapFetchForRetries(appFacilities.fetch, plugin, action, (msg, params) => logError(msg, params)),
            getFeatureSetting("rehostFileTimeout") ? defaultFetchTimeout : undefined
        );
    }

    public async uploadFile(name: string, mimeType: string, contents: string | ArrayBuffer): Promise<Result<string>> {
        try {
            return await this.ab.uploadFile(name, mimeType, contents);
        } catch (e: unknown) {
            return Result.Fail("Could not upload file to Glide storage.", {
                isPluginError: true,
                data: maybeParseJSON(e),
            });
        }
    }

    public consumeBillable(count?: number) {
        if (count === undefined) {
            if (typeof this.action?.billablesConsumed === "number") count = this.action.billablesConsumed;
            else if (typeof this.action?.billablesConsumed === "object") count = this.action.billablesConsumed.number;
            else count = 1;
        }
        void reportBillable(
            {
                appID: this.appID,
                count,
                pluginID: this.plugin.fields.id,
                actionID: defined(this.action).name,
            },
            this.appFacilities
        );
    }

    public async sendPushNotification(
        title: string,
        body: string,
        link?: string,
        emails?: readonly string[]
    ): Promise<Result> {
        const response = await this.appFacilities.callAuthCloudFunction("sendFrontendPushNotification", {
            title,
            body,
            link,
            emails,
            appID: this.appID,
        });
        void response?.text();
        if (isResponseOK(response)) {
            return Result.Ok();
        } else {
            // Push notifications are dead. https://github.com/glideapps/glide/issues/31159
            // if we don't get a response, we are doing to default
            // to a 4xx error instead, 410 seemed appropriate in this case
            return Result.FailFromHTTPStatus("Failed to send push notification", response?.status ?? 410);
        }
    }
}

export class PluginActionHandler extends BaseActionHandler<DynamicActionDescription> {
    constructor(public plugin: PluginProps, public action: ActionProps) {
        super();
    }

    public get kind(): ActionKind {
        return makePluginActionKind(this.plugin, this.action) as ActionKind;
    }

    public get availability(): ActionAvailability {
        if (this.action.needsClient === true) {
            return actionAvailabilityApps;
        } else if (this.action.needsAutomation === true) {
            return { apps: false, automations: true };
        } else {
            return actionAvailabilityBoth;
        }
    }

    public get iconName(): GlideIcon | string {
        return this.action.icon ?? this.plugin.icon ?? "map-pin";
    }

    public get name(): string {
        return this.action.name;
    }

    public get appKinds(): AppKind | "both" {
        return AppKind.Page;
    }

    public getBillablesConsumed(): BillablesConsumed | undefined {
        return this.action.billablesConsumed;
    }

    public getTier(): PluginTierList | undefined {
        return this.action.tier ?? this.plugin.tier;
    }

    public getIsIdempotent(desc: DynamicActionDescription): boolean {
        if (this.action.isIdempotent !== true) return false;
        return this.getResultProperties(desc).length === 0;
    }

    private get parameters() {
        return preProcessParameters(this.action.parameters, "param_");
    }

    private get results(): PluginResult[] {
        return Object.entries(this.action.results).map(([resultName, props]) => ({
            ...props,
            resultName,
            propertyName: `result_${resultName}`,
        }));
    }

    private hasParameterErrors(description: DynamicActionDescription): boolean {
        return this.parameters.some(p => {
            const descriptionValue = (description as any)[p.propertyName];
            if (p.required !== true) return false;
            return isEmptyOrUndefinedish(descriptionValue?.value);
        });
    }

    public getDescriptor(
        desc: DynamicActionDescription | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        let disabledReason: ActionDisabledReason | undefined;
        const pluginConfig = ccc.appDescription?.pluginConfigs?.find(c => c.pluginID === this.plugin.id);
        if (pluginConfig === undefined && this.plugin.isNative !== true) {
            disabledReason = ActionDisabledReason.PluginNotConfigured;
        }

        if (desc !== undefined && this.hasParameterErrors(desc)) {
            disabledReason = ActionDisabledReason.PluginNotConfigured;
        }

        const parameterProperties = makeParameterPropertyDescriptors(
            ccc.appID,
            this.plugin.id,
            pluginConfig,
            this.parameters,
            mutatingScreenKind,
            desc,
            "action",
            false
        );

        let resultProperties: PropertyDescriptor[];
        const filteredResults = mapFilterUndefined(this.results, p => {
            assert(
                p.type !== "secret" &&
                    p.type !== "stringObject" &&
                    p.type !== "object" &&
                    p.type !== "generatedKeyPair" &&
                    p.type !== "jsonPath"
            );
            // FIXME: Eventually handle tables as results.
            if (p.type === "table") return undefined;
            // FIXME: Eventually write back actual arrays.
            if (p.type === "array" || p.type === "stringArray") return undefined;
            const typeKind = getTypeKindForPrimitivePluginType(p.type);
            if (typeKind === undefined) return undefined;
            return p;
        });
        if (filteredResults.length > 10) {
            const cases = filteredResults.map(r => ({ value: r.propertyName, label: r.name }));
            const columnCase = makeColumnCase(
                true,
                {
                    searchable: true,
                    isEditedInApp: true,
                    emptyByDefault: false,
                    allowUserProfileColumns: true,
                    applyFormat: false,
                },
                "string"
            );
            resultProperties = [
                {
                    kind: PropertyKind.Array,
                    label: "Results",
                    property: { name: "results" },
                    section: filteredResults[0].propertySection ?? PropertySection.Data,
                    properties: [
                        {
                            kind: PropertyKind.Enum,
                            property: { name: "result" },
                            label: "Result",
                            menuLabel: "result",
                            cases,
                            defaultCaseValue: cases[0].value,
                            section: filteredResults[0].propertySection ?? PropertySection.Data,
                            visual: "dropdown",
                            isSearchable: true,
                        },
                        {
                            property: { name: "writeTo" },
                            label: "Write to",
                            section: filteredResults[0].propertySection ?? PropertySection.Data,
                            ...columnCase,
                        },
                    ],
                    allowEmpty: false,
                    allowReorder: false,
                    addItemLabels: ["Add value"],
                    style: ArrayPropertyStyle.KeyValue,
                },
            ];
        } else {
            resultProperties = mapFilterUndefined(filteredResults, p => {
                assert(p.type !== "object");
                // FIXME: Eventually handle tables as results.
                if (p.type === "table") return undefined;
                // FIXME: Eventually write back actual arrays.
                if (p.type === "array" || p.type === "stringArray") return undefined;
                const typeKind = getTypeKindForPrimitivePluginType(p.type);
                if (typeKind === undefined) return undefined;

                const columnCase = makeColumnCase(
                    false,
                    {
                        searchable: false,
                        isEditedInApp: true,
                        applyFormat: false,
                        emptyByDefault: true,
                        allowUserProfileColumns: true,
                    },
                    typeKind
                );
                return {
                    property: { name: p.propertyName },
                    label: p.name,
                    section: p.propertySection ?? PropertySection.Data,
                    helpText: p.description,
                    ...columnCase,
                };
            });
        }

        return {
            name: this.action.name,
            group: this.action.group ?? this.plugin.name,
            needsScreenContext: true,
            properties: [...parameterProperties, ...resultProperties],
            outputs: makeOutputDescriptorsForResults(this.action.results),
            disabledReason,
        };
    }

    private inflateParameterGetters(ib: WireActionInflationBackend, desc: DynamicActionDescription) {
        return mapFilterUndefined(this.parameters, p => {
            const pd = (desc as any)[p.propertyName];
            if (p.type === "stringObject") {
                const getters = inflateNamesAndValues(ib, pd);
                const getter = (vp: WireRowHydrationValueProvider, skipLoading: boolean) => {
                    const obj: Record<string, string | { value: string; isSecret: boolean }> = {};
                    let loadingValue: LoadingValue | undefined;
                    for (const { nameGetter, valueGetter, valueIsStringConstant } of getters) {
                        const nameValue = nameGetter(vp);
                        const valueValue = valueGetter(vp);
                        if (!isBound(nameValue) || !isBound(valueValue)) continue;
                        if (isLoadingValue(nameValue)) {
                            loadingValue = nameValue;
                        } else if (isLoadingValue(valueValue)) {
                            loadingValue = valueValue;
                        } else {
                            const name = asMaybeString(nameValue);
                            if (isEmptyOrUndefined(name)) continue;
                            const value = asMaybeString(valueValue) ?? "";
                            obj[name] =
                                p.withSecretConstants === true ? { value, isSecret: valueIsStringConstant } : value;
                        }
                    }
                    if (loadingValue !== undefined && !skipLoading) return loadingValue;
                    return obj;
                };
                return [p, getter, getter, undefined, undefined, undefined] as const;
            } else if (p.type === "jsonObject") {
                const getters = inflateNamesAndValues(ib, pd);
                const getter = (vp: WireRowHydrationValueProvider, skipLoading: boolean) => {
                    const obj: Record<string, JSONValue> = {};
                    let loadingValue: LoadingValue | undefined;
                    for (const { nameGetter, valueGetter, valueType, valueIsStringConstant } of getters) {
                        const nameValue = nameGetter(vp);
                        const valueValue = valueGetter(vp);
                        if (!isBound(nameValue) || !isBound(valueValue)) continue;
                        if (isLoadingValue(nameValue)) {
                            loadingValue = nameValue;
                        } else if (isLoadingValue(valueValue)) {
                            loadingValue = valueValue;
                        } else {
                            const name = asMaybeString(nameValue);
                            if (isEmptyOrUndefined(name)) continue;
                            const value = asMaybeJSONValueForColumnType(
                                valueValue,
                                valueIsStringConstant ? undefined : makeParameterSourceColumnType(valueType)
                            );
                            if (value === undefined) continue;

                            obj[name] = value;
                        }
                    }
                    if (loadingValue !== undefined && !skipLoading) return loadingValue;
                    return obj;
                };
                return [p, getter, getter, undefined, undefined, undefined] as const;
            } else if (p.type === "number") {
                const [getter, type] = inflatePropertyWithLoading(ib, pd, false, undefined, asMaybeNumber);
                if (type === undefined) return undefined;
                const [formattedGetter] = inflatePropertyWithLoading(ib, pd, true, undefined, asMaybeNumber);
                return [p, getter, formattedGetter, undefined, undefined, undefined] as const;
            } else if (p.type === "table") {
                const maybe = ib.getTableGetter(pd, true);
                if (maybe === undefined) return undefined;
                const [getter, tableType, , selectedColumns, nameOverrides] = maybe;

                const tableIB = ib.makeInflationBackendForTables(
                    makeContextTableTypes(makeInputOutputTables(tableType)),
                    undefined
                );
                const colGetters = fromPairs(
                    mapFilterUndefined(
                        // If we haven't selected any columns explicitly, skip over the computed columns.
                        [
                            ...(selectedColumns?.values() ??
                                getPrimitiveNonComputedNonHiddenColumns(tableType).map(c => c.name)),
                        ],
                        colName => {
                            return [colName, tableIB.getValueGetterForProperty(makeColumnProperty(colName), false)[0]];
                        }
                    )
                );

                return [p, getter, undefined, tableType, colGetters, nameOverrides] as const;
            } else if (p.type === "dateTime") {
                const [getter, type] = inflatePropertyWithLoading(
                    ib,
                    pd,
                    false,
                    undefined,
                    parseValueAsGlideDateTimeSync
                );
                if (type === undefined) return undefined;
                const [formattedGetter] = inflatePropertyWithLoading(
                    ib,
                    pd,
                    true,
                    undefined,
                    parseValueAsGlideDateTimeSync
                );
                return [p, getter, formattedGetter, undefined, undefined, undefined] as const;
            } else if (p.type === "stringArray") {
                const convertArray = (i: any) =>
                    Array.isArray(i) ? i.map(e => asString(e)) : typeof i === "string" ? [i] : [];
                const [getter, type] = inflatePropertyWithLoading(ib, pd, false, undefined, convertArray);
                if (type === undefined) return undefined;
                const [formattedGetter] = inflatePropertyWithLoading(ib, pd, true, undefined, convertArray);
                return [p, getter, formattedGetter, undefined, undefined, undefined] as const;
            } else if (p.type === "jsonPath") {
                const [getter, type] = inflatePropertyWithLoading(
                    ib,
                    pd,
                    false,
                    undefined,
                    v => asMaybeArrayOfStrings(v) ?? asString(v)
                );
                if (type === undefined) return undefined;
                const [formattedGetter] = inflatePropertyWithLoading(
                    ib,
                    pd,
                    true,
                    undefined,
                    v => asMaybeArrayOfStrings(v) ?? asString(v)
                );
                return [p, getter, formattedGetter, undefined, undefined, undefined] as const;
            } else if (p.type === "boolean") {
                const [getter, type] = inflatePropertyWithLoading(ib, pd, false, undefined, asMaybeBoolean);
                if (type === undefined) return undefined;
                const [formattedGetter] = inflatePropertyWithLoading(ib, pd, true, undefined, asMaybeBoolean);
                return [p, getter, formattedGetter, undefined, undefined, undefined] as const;
            } else if (p.type === "json" && getFeatureSetting("passJSONAsJSONToPlugins")) {
                const [getter, type] = inflatePropertyWithLoading(ib, pd, p.withFormat, undefined, asJSONValue);
                if (type === undefined) return undefined;
                const [formattedGetter] = inflatePropertyWithLoading(ib, pd, true, undefined, asJSONValue);
                return [p, getter, formattedGetter, undefined, undefined, undefined] as const;
            } else {
                const [getter, type] = inflatePropertyWithLoading(ib, pd, p.withFormat, undefined, asString);
                if (type === undefined) return undefined;
                return [p, getter, undefined, undefined, undefined, undefined] as const;
            }
        });
    }

    private getResultProperties(desc: DynamicActionDescription) {
        const arrayResults = getArrayProperty<ResultAndWriteTo>((desc as any).results);
        return mapFilterUndefined(this.results, r => {
            let pd = (desc as any)[r.propertyName];
            if (pd === undefined && arrayResults !== undefined) {
                for (const arrayResult of arrayResults) {
                    const result = getEnumProperty<string>(arrayResult.result);
                    if (result !== undefined && result !== r.propertyName) continue;
                    pd = arrayResult.writeTo;
                    break;
                }
            }
            const sc = getSourceColumnProperty(pd);
            if (sc === undefined) return undefined;
            const path = getSourceColumnPath(sc);
            // If the path is empty then it specifies a row, but we can't
            // write to a row, only to a column in the row.
            const columnName = last(path);
            if (columnName === undefined) return undefined;
            const rowSC: SourceColumn = { kind: sc.kind, name: path.slice(0, path.length - 1) };
            return { result: r, rowSourceColumn: rowSC, columnName };
        });
    }

    private inflateResultGetters(ib: WireActionInflationBackend, desc: DynamicActionDescription) {
        return mapFilterUndefined(
            this.getResultProperties(desc),
            ({ result: r, rowSourceColumn: rowSC, columnName }) => {
                const [getter, type] = ib.getValueGetterForSourceColumn(rowSC, true, false);
                if (type === undefined || !isSingleRelationType(type)) return undefined;
                const table = ib.adc.findTable(type);
                if (table === undefined) return undefined;
                return [r, getter, table, columnName] as const;
            }
        );
    }

    private hydrateWriteBack(
        vp: WireRowHydrationValueProvider,
        resultGetters: ReturnType<typeof this.inflateResultGetters>
    ): [
        Record<string, WebhookWriteBackToColumn>,
        ArrayMap<TableName, ArrayMap<RowIndex, Row>>,
        LoadingValue | undefined
    ] {
        let loadingValue: LoadingValue | undefined;
        const writeBackRows = new DefaultArrayMap<TableName, ArrayMap<RowIndex, Row>>(
            areTableNamesEqual,
            () => new ArrayMap<RowIndex, Row>(areRowIndexesConflicting)
        );
        const writeBackToColumns: Record<string, WebhookWriteBackToColumn> = fromPairs(
            mapFilterUndefined(resultGetters, ([result, getter, table, columnName]) => {
                const row = getter(vp);
                if (isLoadingValue(row)) {
                    loadingValue = row;
                    return undefined;
                }
                if (!isBound(row) || !isRow(row)) return undefined;
                const rowIndex = getRowIndexForRow(table, undefined, row);
                if (rowIndex === undefined) return undefined;
                const tableName = getTableName(table);
                const writeBack: WebhookWriteBackToColumn = {
                    tableName: getTableName(table),
                    rowIndex,
                    columnName,
                };
                writeBackRows.get(tableName).set(rowIndex, row);
                const pair: [string, WebhookWriteBackToColumn] = [result.resultName, writeBack];
                return pair;
            })
        );
        return [writeBackToColumns, writeBackRows, loadingValue];
    }

    private hydrateParameterValues(
        vp: WireRowHydrationValueProvider,
        parameterGetters: ReturnType<typeof this.inflateParameterGetters>,
        skipLoading: boolean
    ) {
        let error: string | undefined;
        let loadingValue: LoadingValue | undefined;

        const parameterValues = mapFilterUndefined(
            parameterGetters,
            ([p, rawGetter, formattedGetter, maybeTableType, maybeSelectedColumns, maybeNameOverrides]) => {
                let value = rawGetter(vp, skipLoading);
                if (isLoadingValue(value)) {
                    loadingValue = value;
                    return undefined;
                }
                if (!isBound(value)) {
                    value = undefined;
                }
                if (maybeTableType !== undefined && !(value instanceof Table)) {
                    value = undefined;
                }

                let formattedValue = formattedGetter !== undefined ? formattedGetter(vp, skipLoading) : value;
                if (isLoadingValue(formattedValue)) {
                    loadingValue = formattedValue;
                    return undefined;
                }
                if (!isBound(formattedValue)) {
                    formattedValue = undefined;
                }

                // The value type might be `string`, in which case we do
                // actually want to send an empty string, but it does
                // count as missing if it's required.
                if (p.required === true && (value === undefined || value === "")) {
                    error = `The required parameter "${p.name}" is missing`;
                }

                if (value === undefined) {
                    return undefined;
                }

                return [p, value, formattedValue, maybeTableType, maybeSelectedColumns, maybeNameOverrides] as const;
            }
        );

        return [parameterValues, loadingValue, error] as const;
    }

    private async getClientPluginAndAction() {
        const realPlugin = await pack.getPlugin(this.plugin.id);
        const realAction = findAction([...realPlugin.actions, ...realPlugin.computations], this.action.id);
        if (realAction === undefined) return undefined;
        return [realPlugin, realAction] as const;
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: DynamicActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const { adc, appFacilities, forBuilder, forAutomation, writeSource } = ib;
        const { appID, appDescription } = adc;
        const pluginConfig = appDescription.pluginConfigs?.find(config => config.pluginID === this.plugin.id);
        if (pluginConfig === undefined && this.plugin.isNative !== true) {
            return arbBase.inflationError("Plugin not configured");
        }

        const parameterGetters = this.inflateParameterGetters(ib, desc);
        const resultGetters = this.inflateResultGetters(ib, desc);

        return (vp, skipLoading) => {
            const [writeBackToColumns, writeBackRows, writeBackLoadingValue] = this.hydrateWriteBack(vp, resultGetters);

            // We must get the values here in the hydrator, or we won't wait
            // for loading values.
            // https://github.com/quicktype/glide/issues/21129
            const [parameterValues, parameterLoadingValue, maybeError] = this.hydrateParameterValues(
                vp,
                parameterGetters,
                skipLoading
            );

            const loadingValue = writeBackLoadingValue ?? parameterLoadingValue;
            if (loadingValue !== undefined && !skipLoading) return arbBase.loading();

            if (maybeError !== undefined) return arbBase.error(true, maybeError);

            return async ab => {
                const liftParameters: (
                    withFormat: boolean
                ) => (p: (typeof parameterValues)[0]) => Promise<[string, any] | undefined> =
                    (withFormat: boolean) =>
                    async ([p, rawValue, formattedValue, maybeTableType, maybeSelectedColumns, maybeNameOverrides]) => {
                        if (maybeTableType !== undefined) {
                            assert(rawValue instanceof Table && maybeSelectedColumns !== undefined);
                            const subVp = ab.makeBackendForSubAction();
                            const asTableType = await transformTableToPluginTableType(
                                subVp,
                                maybeTableType,
                                rawValue,
                                maybeSelectedColumns,
                                maybeNameOverrides ?? {}
                            );
                            if (asTableType === undefined) return undefined;
                            return [p.parameterName, asTableType];
                        }
                        const v = !withFormat || formattedValue === undefined ? rawValue : formattedValue;
                        assert(isBound(v) && !isLoadingValue(v) && v !== undefined);
                        if (withFormat && typeof v !== "string") return undefined;
                        return [p.parameterName, v];
                    };
                const parametersObject = fromPairs(
                    (await allSettled(parameterValues.map(liftParameters(false)))).filter(isDefined)
                );
                const formattedParametersObject: Record<string, string> = fromPairs(
                    (await allSettled(parameterValues.map(liftParameters(true))))
                        .filter(isDefined)
                        .map(([k, v]) => [k, v.toString()])
                );

                const showErrorToast = (message: string) => {
                    ab.actionCallbacks.showToast(false, `${this.action.name}: ${message}`);
                };
                const getBodyError = (responseBody: any): string | undefined => {
                    if (hasOwnProperty(responseBody, "message") && typeof responseBody.message === "string") {
                        return responseBody.message;
                    }
                    return;
                };

                const writebackForBody = async (decoded: WritebackResponse): Promise<void> => {
                    // This excludes the `EmptyType` response
                    if (!hasOwnProperty(decoded, "actions")) return;
                    const { actions, error } = decoded;
                    if (error !== undefined) {
                        showErrorToast(error);
                    }
                    for (const enqueuedAction of actions) {
                        const row = writeBackRows.get(enqueuedAction.tableName)?.get(enqueuedAction.rowIndex);
                        if (row === undefined) continue;
                        await ab.setColumnsInRow(
                            enqueuedAction.tableName,
                            row,
                            // FIXME: Typescript thinks this is wrong, for mainly invalid reasons,
                            // but that's our fault that we haven't typed columnValues correctly here.
                            enqueuedAction.columnValues as any,
                            false,
                            enqueuedAction.jobID,
                            enqueuedAction.confirmedAtVersion
                        );
                    }
                };

                const writeBackTo: WebhookWriteBackTo = {
                    results: writeBackToColumns,
                    fromBuilder: forBuilder,
                    fromDataEditor: forAutomation,
                    appUserID: ab.getAppUserID(),
                    writeSource,
                };

                // FIXME: These writebacks need to be forecast into the data model,
                // because Firestore can have any amount of latency between us "completing"
                // and us seeing the data hit the data model. As it currently is, the roundabout
                // waiting for Firestore is what we have to deal with.
                if (this.action.type === "client") {
                    const maybeRealAction = await this.getClientPluginAndAction();
                    if (maybeRealAction === undefined) return arbBase.error(true, "Incorrect action configuration");
                    const [realPlugin, realAction] = maybeRealAction;

                    const context = new ClientExecutionContext(
                        appFacilities,
                        appID,
                        ab,
                        ab.appData,
                        realAction,
                        realPlugin
                    );
                    const pluginID = this.plugin.id;
                    const actionID = realAction.id;
                    let r = await frontendTrace("executePluginAction", { pluginID, action: actionID }, async () => {
                        return await realAction.execute(context, parametersObject, formattedParametersObject); // FIXME, add non-secret plugin params
                    });
                    if (!isResult(r)) {
                        r = r.current;
                    }

                    const errorMessage: string | undefined = getBodyError(r);
                    let adr = arbBase.addData(parametersObject);

                    if (!r.ok) {
                        if (errorMessage !== undefined) {
                            showErrorToast(errorMessage);
                        }
                        return adr.fromResult(r);
                    }
                    if (r.result === undefined) {
                        return adr.success();
                    }

                    // This can't be returned because it's only allowed in
                    // server actions, but Typescript doesn't pick up on that.
                    assert(!(r.result instanceof WaitForSignalResult));

                    const glideResults = pluginResultToGlideType(
                        realAction.results,
                        removeUndefinedProperties(r.result) as typeof r.result
                    );
                    adr = adr.withOutputs(glideResults);
                    // We're grouping together all the writebacks per row so
                    // that we only update each row once.  Otherwise we'd have
                    // to debounce to make sure we don't create multiple
                    // writes on the backend.
                    const updatesToWrite = new DefaultArrayMap<
                        TableName,
                        DefaultMap<Row, Record<string, LoadedGroundValue>>
                    >(areTableNamesEqual, () => new DefaultMap(() => ({})));
                    for (const [resultName, writeBackToColumn] of Object.entries(writeBackTo.results)) {
                        const row = writeBackRows.get(writeBackToColumn.tableName)?.get(writeBackToColumn.rowIndex);
                        if (row === undefined) continue;
                        const value = glideResults[resultName];
                        if (value === undefined) continue;
                        updatesToWrite.get(writeBackToColumn.tableName).get(row)[writeBackToColumn.columnName] = value;
                    }
                    const promises: Promise<Result>[] = [];
                    for (const [tableName, rowUpdates] of updatesToWrite.entries()) {
                        for (const [row, columnValues] of rowUpdates.entries()) {
                            promises.push(
                                ab.setColumnsInRow(tableName, row, columnValues, false, undefined, undefined)
                            );
                        }
                    }
                    await Promise.all(promises);
                    // FIXME: What if one of the set-columns fails?
                    return adr.success();
                } else {
                    const actionParams = valueRecordCodec.encode(parametersObject);

                    const pluginParams = pluginConfig?.parameters ?? {};

                    const integrationAggregator = appFacilities.getIntegrationsAggregator({
                        actionKind: this.kind,
                        appID,
                        deviceID: appFacilities.deviceID,
                        pluginConfigID: pluginConfig?.configID,
                        pluginParams: forBuilder ? pluginParams : undefined,
                    });

                    const instanceBody: RunIntegrationsInstance = {
                        actionParams,
                        instanceID: appFacilities.makeRowID(),
                        isAction: true,
                        writeBackTo,
                    };

                    const response = await integrationAggregator.runIntegration({
                        data: instanceBody,
                    });

                    let adr = arbBase.addData(actionParams);

                    if (response.ok) {
                        if (response.result instanceof WaitForSignalResult) {
                            return adr.continueWithSignal({
                                signalScope: response.result.signalScope,
                                signalID: response.result.signalID,
                                timeoutMS: response.result.timeoutMS,
                            });
                        }

                        try {
                            await writebackForBody(response.result);
                        } catch {
                            // nothing to do
                        }

                        // Right now only automations need action outputs.
                        // Note that we don't report an error if we couldn't
                        // decode the response.  Unfortunately the backend
                        // will send an incorrect response when it's supposed
                        // to send an empty one, so we just ignore it.
                        if (forAutomation) {
                            const results = hasOwnProperty(response.result, "actions") ? response.result.results : {};

                            const glideResults = pluginResultToGlideTypeWithParameterProps(
                                this.action.results,
                                mapRecord(results, pluginValueToCellValue)
                            );

                            adr = adr.withOutputs(glideResults);
                        }
                    } else {
                        if (forBuilder) {
                            try {
                                showErrorToast(response.message);
                            } catch {
                                // nothing to do
                            }
                        }
                    }

                    return adr.fromResult(response);
                }
            };
        };
    }

    public getTokenizedDescription(
        desc: DynamicActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const { configurationDescriptionPattern } = this.action;

        if (configurationDescriptionPattern === undefined) return undefined;

        const regex = /\$\{([A-Za-z\(\)]+)\}/g;
        const maches = [...configurationDescriptionPattern.matchAll(regex)];
        if (maches.length === 0) return undefined;

        let value = configurationDescriptionPattern;
        for (const match of maches) {
            const { propName, transformation } = getDescriptionPropertyWithTransformation(match[1]);
            const possibleKeys = [propName, `param_${propName}`, `result_${propName}`];
            const key = possibleKeys.find(k => isPropertyDescription((desc as any)[k]));
            if (key === undefined) {
                return undefined;
            }
            const prop = (desc as any)[key];
            const token = tokenForProperty(prop, env);
            value = value.replace(match[0], transformProperty(token?.value ?? "", transformation));
        }

        return [
            {
                kind: "string",
                value,
            },
        ];
    }

    public fixActionDescription(desc: DynamicActionDescription): DynamicActionDescription {
        if (desc.kind === this.kind) {
            return desc;
        } else {
            return {
                ...desc,
                kind: this.kind,
            };
        }
    }
}

export function registerPluginActionHandler(plugin: PluginProps, action: ActionProps): void {
    try {
        const handler = new PluginActionHandler(plugin, action);
        registerActionHandler(handler);
    } catch (e: unknown) {
        logError("Error registering plugin action handler", exceptionToString(e));
    }
}
