import "twin.macro";

type SettingsCardProps = {
    title: React.ReactNode | string;
    children: React.ReactNode;
    button?: React.ReactNode | undefined;
    titleBorder?: boolean;
    noPadding?: boolean;
};

export const SettingsCard: React.FC<React.PropsWithChildren<SettingsCardProps>> = props => {
    const { title, titleBorder, children, button, noPadding } = props;

    return (
        <div tw="flex justify-evenly flex-col rounded-lg border border-border-pale w-full bg-n50A">
            <div
                data-border={titleBorder !== false}
                tw="flex justify-between items-end px-5 py-4 text-lg border-border-pale 
                    data-[border=false]:(pb-0 pt-5)
                    data-[border=true]:(border-b min-h-[65px])">
                {typeof title === "string" ? <div tw="font-semibold">{title}</div> : <>{title}</>}
                {typeof button !== "undefined" ? button : null}
            </div>
            <div
                data-border={titleBorder !== false}
                data-nopad={noPadding === true}
                tw="p-4 data-[border=false]:(pt-0) data-[nopad=true]:(p-0)">
                {children}
            </div>
        </div>
    );
};
