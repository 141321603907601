import "twin.macro";

import { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { FeatureBox, withSubscriptionContext } from "@glide/designer-components";
import { useCallback } from "react";

export const BillingSettingsContemporaryFree = withSubscriptionContext(props => {
    const {
        subscriptionContext: { showUpgradeModal },
    } = props;
    const location = "billing_settings_free";

    const onComparePlans = useCallback(() => {
        void showUpgradeModal({ location, showPlanSelector: true });
    }, [location, showUpgradeModal]);

    return (
        <div tw="space-y-3">
            <FeatureBox
                icon="st-database-server"
                name="Business plan"
                description="30 users, Users with Businesss emails, Call API"
                targetPlan={PlanKind.V3Business}
                onClick={() => showUpgradeModal({ targetPlan: PlanKind.V3Business, location })}
                onComparePlans={onComparePlans}
            />
            <FeatureBox
                icon="st-star-4"
                name="Maker plan"
                description="Personal users, Google Sheets, Custom branding"
                targetPlan={PlanKind.V3Starter}
                onClick={() => showUpgradeModal({ targetPlan: PlanKind.V3Starter, location })}
                onComparePlans={onComparePlans}
            />
        </div>
    );
});
