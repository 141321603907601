import { ColorTheme } from "@glide/common-core/dist/js/components/color-types";
import { AppKind } from "@glide/location-common";
import type { ArrayContentDescription, MutatingScreenKind, PropertyDescription } from "@glide/app-description";
import {
    ArrayScreenFormat,
    PropertyKind,
    getActionProperty,
    getArrayProperty,
    getColumnProperty,
    getEnumProperty,
    makeActionProperty,
    makeArrayProperty,
    makeColumnProperty,
    makeEnumProperty,
} from "@glide/app-description";
import {
    ComponentKindInlineList,
    makeEmptyComponentDescription,
    type InputOutputTables,
} from "@glide/common-core/dist/js/description";
import { getTableColumn, getTableColumnDisplayName } from "@glide/type-schema";
import { ChartType } from "@glide/component-utils";
import type {
    WireAppBarChartComponent,
    WireAppChartComponent,
    WireAppDonutChartComponent,
    WireAppPieChartComponent,
} from "@glide/fluent-components/dist/js/base-components";
import type { InlineListComponentDescription } from "@glide/function-utils";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentSpecialCaseDescriptor,
    type PropertyDescriptor,
    type PropertyTableGetter,
    ArrayPropertyStyle,
    EnumPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
    makeStringyColumnPropertyDescriptor,
    makeTextPropertyDescriptor,
    getPrimitiveNonHiddenColumnsSpec,
} from "@glide/function-utils";
import { defined, mapFilterUndefined, assert, definedMap, assertNever } from "@glideapps/ts-necessities";
import {
    type InflatedProperty,
    type WireTableComponentHydratorConstructor,
    WireComponentKind,
    type WireInflationBackend,
    RadialChartWeights,
    BarChartType,
    BarChartWeights,
} from "@glide/wire";
import sum from "lodash/sum";
import zip from "lodash/zip";

import type { CaptionPropertyDescriptorFlags } from "../components/descriptor-utils";
import { formatValueWithSpecification } from "../format-value";
import type { ValueFormatSpecification } from "@glide/formula-specifications";
import {
    type WireStringGetter,
    getFormatForInputColumn,
    inflateNumberProperty,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import { type FixedArrayContent, ArrayContentHandlerBase } from "./array-content";
import type { ChartsComponentDescription } from "@glide/fluent-components/dist/js/fluent-components";

const showTotalInCenterPropertyHandler = new SwitchPropertyHandler(
    { showTotalInCenter: true },
    "Show total in center",
    PropertySection.Design
);

const showLegendByDefaultPropertyHandler = new SwitchPropertyHandler(
    { showLegendByDefault: true },
    "Show legend by default",
    PropertySection.Design
);

const showLabelsOnBarsPropertyHandler = new SwitchPropertyHandler(
    { showLabelsOnBars: true },
    "Show labels on bars",
    PropertySection.Design
);

const colorThemePropertyHandler = new EnumPropertyHandler(
    { colorTheme: ColorTheme.Barragan },
    "Color Theme",
    "Color Theme",
    [
        {
            value: ColorTheme.Ansel,
            label: "Ansel",
            icon: "ansel",
        },
        {
            value: ColorTheme.Barragan,
            label: "Barragán",
            icon: "barragan",
        },
        {
            value: ColorTheme.Hockney,
            label: "Hockney",
            icon: "hockney",
        },
        {
            value: ColorTheme.Kahlo,
            label: "Kahlo",
            icon: "kahlo",
        },
        {
            value: ColorTheme.Magritte,
            label: "Magritte",
            icon: "magritte",
        },
        {
            value: ColorTheme.Missy,
            label: "Missy",
            icon: "missy",
        },
        {
            value: ColorTheme.Pamplemousse,
            label: "Pamplemousse",
            icon: "pamplemousse",
        },
        {
            value: ColorTheme.Quinquela,
            label: "Quinquela",
            icon: "quinquela",
        },
        {
            value: ColorTheme.RuPaul,
            label: "RuPaul",
            icon: "rupaul",
        },
        {
            value: ColorTheme.Turrell,
            label: "Turrell",
            icon: "turrell",
        },
        {
            value: ColorTheme.Vermeer,
            label: "Vermeer",
            icon: "vermeer",
        },
    ],

    PropertySection.Design,
    "dropdown"
);

const stylePropertyHandler = new EnumPropertyHandler(
    { style: ChartType.DonutChart },
    "Style",
    "Style",
    [
        {
            value: ChartType.DonutChart,
            label: "Donut",
            icon: "donutChart",
        },
        {
            value: ChartType.PieChart,
            label: "Pie",
            icon: "pieChart",
        },
        { value: ChartType.BarChart, label: "Bar", icon: "barChart" },
        { value: ChartType.StackedBarChart, label: "Stack", icon: "stackedBarChart" },
    ],

    PropertySection.Style,
    "large-images"
);

interface SeriesDescription {
    readonly valueProperty: PropertyDescription;
}

interface PieChartArrayContentDescription extends ArrayContentDescription {
    readonly style: PropertyDescription;
    readonly titleString: PropertyDescription | undefined;
    readonly captionString: PropertyDescription | undefined;
    readonly unitsString: PropertyDescription | undefined;
    readonly labelProperty: PropertyDescription;
    readonly valueProperty: PropertyDescription | undefined; // only for donut/pie
    readonly series: PropertyDescription | undefined; // only for bar
    readonly showTotalInCenter: PropertyDescription;
    readonly showLegendByDefault: PropertyDescription;
}

function getChartType(desc: PieChartArrayContentDescription | undefined): ChartType {
    return definedMap(desc, d => stylePropertyHandler.getEnum(d)) ?? ChartType.DonutChart;
}

function getValueColumnNames(desc: PieChartArrayContentDescription): string[] | undefined {
    let valueColumnNames;
    if (getChartType(desc) === ChartType.BarChart) {
        const arr = getArrayProperty<SeriesDescription>(desc.series);
        if (arr === undefined) return undefined;
        valueColumnNames = mapFilterUndefined(arr, s => getColumnProperty(s.valueProperty));
    } else {
        const columnName = getColumnProperty(desc.valueProperty);
        if (columnName !== undefined) {
            valueColumnNames = [columnName];
        }
    }
    if (valueColumnNames !== undefined) {
        valueColumnNames = Array.from(new Set(valueColumnNames));
    }
    return valueColumnNames;
}

export class PieChartArrayContentHandler extends ArrayContentHandlerBase<PieChartArrayContentDescription> {
    constructor() {
        super(ArrayScreenFormat.PieChart);
    }

    public get allowChangePageSize(): boolean {
        return true;
    }

    public getSpecialCaseDescriptors(): readonly ComponentSpecialCaseDescriptor[] {
        return [
            {
                name: "Charts",
                analyticsName: "chart",
                description: "A colorful chart",
                img: "co-charts",
                group: "Charts",
                isNew: false,
                appKinds: AppKind.App,
            },
        ];
    }

    public getNeedsOrder(desc: PieChartArrayContentDescription | undefined): boolean {
        return getChartType(desc) === ChartType.BarChart;
    }

    public getContentPropertyDescriptors<T extends PieChartArrayContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined
    ): readonly PropertyDescriptor[] {
        const chartType = getChartType(desc);
        const isBar = chartType === ChartType.BarChart;
        const isDonut = chartType === ChartType.DonutChart;

        const valuePropertyDescriptor: PropertyDescriptor = {
            kind: PropertyKind.Column,
            property: { name: "valueProperty" },
            section: PropertySection.Content,
            label: "Quantity",
            required: isBar,
            editable: true,
            searchable: false,
            emptyByDefault: isDonut,
            getIndirectTable: getPropertyTable,
            columnFilter: getPrimitiveNonHiddenColumnsSpec,
            preferredNames: ["value", "quantity"],
            preferredType: "number",
        };
        const valueDescriptors: PropertyDescriptor[] = [];
        if (isBar) {
            valueDescriptors.push({
                kind: PropertyKind.Array,
                label: "Series",
                property: { name: "series" },
                section: PropertySection.Content,
                properties: [valuePropertyDescriptor],
                allowEmpty: true,
                allowReorder: true,
                addItemLabels: ["Add quantity"],
                style: ArrayPropertyStyle.Default,
            });
        } else {
            valueDescriptors.push(valuePropertyDescriptor);
        }

        const result: PropertyDescriptor[] = [
            ...super.getContentPropertyDescriptors(
                getPropertyTable,
                insideInlineList,
                containingScreenTables,
                desc,
                ccc,
                mutatingScreenKind,
                isDefaultArrayScreen,
                withTransforms,
                forEasyTabConfiguration,
                isFirstComponent,
                undefined,
                undefined
            ),
            makeStringyColumnPropertyDescriptor(
                "labelProperty",
                "Label",
                ["required", "editable"],
                PropertySection.Content,
                getPropertyTable,
                ["label", "name"]
            ),
            makeTextPropertyDescriptor("titleString", "Title", "Enter title", false, mutatingScreenKind, {
                propertySection: PropertySection.DataTop,
            }),
            makeTextPropertyDescriptor("captionString", "Caption", "Enter caption", false, mutatingScreenKind, {
                propertySection: PropertySection.DataTop,
            }),
            stylePropertyHandler,
            ...valueDescriptors,
            colorThemePropertyHandler,
            showLegendByDefaultPropertyHandler,
        ];

        if (chartType === ChartType.DonutChart) {
            result.push(
                makeTextPropertyDescriptor("unitsString", "Units", "Enter units", false, mutatingScreenKind, {
                    propertySection: PropertySection.Content,
                })
            );
        }
        if (chartType === ChartType.DonutChart) {
            result.push(showTotalInCenterPropertyHandler);
        }
        if (chartType === ChartType.BarChart) {
            result.push(showLabelsOnBarsPropertyHandler);
        }
        return result;
    }

    public getActionDescriptors(): readonly ActionPropertyDescriptor[] {
        return [];
    }

    public getBasicSearchProperties(): readonly string[] {
        return [];
    }

    public getCaptionFlags(): CaptionPropertyDescriptorFlags | undefined {
        return undefined;
    }

    public fixContentDescription(chartContent: PieChartArrayContentDescription): FixedArrayContent | undefined {
        const style = stylePropertyHandler.getEnum(chartContent);
        if (style !== ChartType.BarChart) return undefined;

        if (chartContent.series !== undefined) return undefined;

        const valueColumn = getColumnProperty(chartContent.valueProperty);
        if (valueColumn === undefined) return undefined;

        const series: SeriesDescription[] = [
            {
                valueProperty: makeColumnProperty(valueColumn),
            },
        ];

        return {
            properties: { ...chartContent, series: makeArrayProperty(series) } as PieChartArrayContentDescription,
            format: ArrayScreenFormat.PieChart,
        };
    }

    public inflateContent<T extends PieChartArrayContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        _captionGetter: WireStringGetter | undefined,
        containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        assert(containingRowIB !== undefined);
        const { forBuilder } = ib;

        const [labelGetter, labelGetterType] = inflateStringProperty(ib, desc.labelProperty, true);
        if (labelGetterType === undefined) return undefined;

        let legend: string[] | undefined;
        let valueColumns:
            | readonly {
                  inflated: InflatedProperty<number | undefined>;
                  format: ValueFormatSpecification | undefined;
                  // The column display name
                  label: string;
              }[]
            | undefined;

        const valueColumnNames = getValueColumnNames(desc);
        if (valueColumnNames !== undefined) {
            // the legend only uses column names if we have more than one
            // value column in the series otherwise, we leave it undefined and
            // the frontend will use the values from label
            if (valueColumnNames.length > 1) {
                legend = [];
                for (let i = 0; i < valueColumnNames.length; i++) {
                    const column = getTableColumn(ib.tables.input, valueColumnNames[i]);
                    if (column !== undefined) {
                        legend.push(getTableColumnDisplayName(column));
                    } else {
                        valueColumnNames.splice(i, 1);
                        i--;
                    }
                }
                if (valueColumnNames.length <= 1) legend = undefined;
            }

            if (valueColumnNames.length > 0) {
                valueColumns = mapFilterUndefined(valueColumnNames, n => {
                    const column = getTableColumn(ib.tables.input, n);
                    if (column === undefined) return undefined;

                    const format = getFormatForInputColumn(ib, n);

                    const inflated = inflateNumberProperty(ib, makeColumnProperty(n));
                    return { inflated, format, label: getTableColumnDisplayName(column) };
                });
            }
        }

        const firstFormat = valueColumns?.[0]?.format;

        const chartType = getChartType(desc);
        const showTotal = showTotalInCenterPropertyHandler.getSwitch(desc);
        const showLegendByDefault = showLegendByDefaultPropertyHandler.getSwitch(desc);
        const showLabelsOnBars = showLabelsOnBarsPropertyHandler.getSwitch(desc);
        const color = colorThemePropertyHandler.getEnum(desc);

        const [titleGetter] = inflateStringProperty(containingRowIB, desc.titleString, true);
        const [captionGetter] = inflateStringProperty(containingRowIB, desc.captionString, true);
        const [unitsGetter] = inflateStringProperty(containingRowIB, desc.unitsString, true);

        return makeSimpleWireTableComponentHydratorConstructor(ib, (thb, chb) => {
            const rows = thb.tableScreenContext.asArray();
            if (rows === undefined || rows.length === 0) return undefined;

            const groups = new Map<string, number[]>();
            for (const row of rows) {
                const rhb = thb.makeHydrationBackendForRow(row);
                const label = labelGetter(rhb);
                if (label === null || label === "") continue;

                // get the values for the current row
                const rowValues = valueColumns?.map(({ inflated: [getter] }) => getter(rhb) ?? 0) ?? [1];

                // sum them with the previous values, if any
                const values = groups.get(label);
                if (values !== undefined) {
                    assert(values.length === rowValues.length);
                    for (let i = 0; i < values.length; i++) {
                        values[i] += rowValues[i];
                    }
                } else {
                    groups.set(label, rowValues);
                }
            }

            let component: WireAppChartComponent = {
                ...spreadComponentID(componentID, forBuilder),
                kind: WireComponentKind.AppPieChart,
                showLegendByDefault,
                color,
                title: titleGetter(defined(chb)) ?? "",
                caption: captionGetter(defined(chb)) ?? "",
            };
            if (
                chartType === ChartType.PieChart ||
                chartType === ChartType.DonutChart ||
                chartType === ChartType.StackedBarChart
            ) {
                const pie: WireAppPieChartComponent = {
                    ...component,
                    kind: WireComponentKind.AppPieChart,
                    data: Array.from(groups).map(([t, v]) => {
                        const value = sum(v);
                        return {
                            value,
                            displayValue: formatValueWithSpecification(firstFormat, value),
                            title: t,
                        };
                    }),
                };
                if (chartType === ChartType.DonutChart) {
                    let total: string | undefined;
                    if (showTotal) {
                        total = formatValueWithSpecification(firstFormat, sum(pie.data.map(d => d.value)));
                    }
                    const donut: WireAppDonutChartComponent = {
                        ...pie,
                        kind: WireComponentKind.AppDonutChart,
                        total,
                        units: unitsGetter(defined(chb)) ?? "",
                    };
                    component = donut;
                } else if (chartType === ChartType.StackedBarChart) {
                    component = {
                        ...pie,
                        kind: WireComponentKind.AppStackedBarChart,
                    };
                } else {
                    component = pie;
                }
            } else {
                const bar: WireAppBarChartComponent = {
                    ...component,
                    kind: WireComponentKind.AppBarChart,
                    showLabelsOnBars,
                    data: Array.from(groups).map(([t, v]) => ({
                        title: t,
                        values: zip(v, valueColumns ?? []).map(([n, vc]) => {
                            assert(n !== undefined);
                            return { value: n, displayValue: formatValueWithSpecification(vc?.format, n) };
                        }),
                    })),
                    columnLabels: valueColumns?.map(v => v.label),
                };
                component = bar;
            }

            return {
                component,
                isValid: true,
            };
        });
    }

    private getPagesChartFormatFromLegacyStyle(
        legacyStyle: ChartType
    ): ArrayScreenFormat.RadialChart | ArrayScreenFormat.Charts {
        switch (legacyStyle) {
            case ChartType.PieChart:
            case ChartType.DonutChart:
                return ArrayScreenFormat.RadialChart;
            case ChartType.BarChart:
                return ArrayScreenFormat.Charts;
            case ChartType.StackedBarChart:
                return ArrayScreenFormat.Charts;
            default:
                assertNever(legacyStyle);
        }
    }

    private convertContentToPage(desc: PieChartArrayContentDescription) {
        const legacyStyle = getEnumProperty<ChartType>(desc.style);

        // there is no pie in pages, but if pie is selected
        // we can make the radial chart have a "chonk"-y width
        const lineWidthValue = legacyStyle === ChartType.PieChart ? RadialChartWeights.Chonk : RadialChartWeights.Small;

        // for bar charts, we have to map the "series" property to the "graph" property
        // they are the same simple properties, but one has "valueProperty" and the other has "value"
        // the graph property also allows a caption, but that doesn't exist in the legacy series
        const legacySeries = getArrayProperty<SeriesDescription>(desc.series);
        const graph =
            legacySeries !== undefined
                ? legacySeries.map(seriesPropertyValue => ({
                      ...seriesPropertyValue,
                      value: getColumnProperty(seriesPropertyValue.valueProperty),
                  }))
                : undefined;

        // stacked in legacy isn't really stacked in pages, but we use the selection here anyway.
        const barChartType = legacyStyle === ChartType.StackedBarChart ? BarChartType.Stack : BarChartType.Group;

        return {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            format: makeEnumProperty(this.getPagesChartFormatFromLegacyStyle(legacyStyle ?? ChartType.PieChart)),
            graph: makeArrayProperty(graph ?? []),
            transforms: desc.transforms,
            action: definedMap(getActionProperty(desc.actions), a => makeActionProperty(a)),
            title: desc.titleString,
            caption: desc.captionString,
            lineWeight: makeEnumProperty(lineWidthValue),
            labels: desc.labelProperty,
            values: desc.valueProperty,
            showLegend: desc.showLegendByDefault,
            // bar chart specifics but doesn't conflict with radial charts
            xAxis: desc.labelProperty,
            chartType: makeEnumProperty("BAR"), // ChartType is overloaded with the legacy type.
            barChartType: makeEnumProperty(barChartType),
            barChartWeight: makeEnumProperty(BarChartWeights.Block),
        };
    }

    public convertInlineToPage(
        desc: PieChartArrayContentDescription & InlineListComponentDescription
    ): ChartsComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: desc.propertyName,
            allowSearch: desc.allowSearch,
            reverse: desc.reverse,
        };
    }

    // classic Apps don't support chart screens
    public convertArrayScreenToPage(): ChartsComponentDescription | undefined {
        return undefined;
    }
}
