import { AppIcon, GlideIcon } from "@glide/common";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { HeaderSection, SuperPropertySection } from "@glide/function-utils";
import {
    PropertySection,
    getSectionKey,
    getSectionName,
    getSectionPriority,
    headerSectionForPropertySection,
    headerSectionOrder,
    sectionBelongsToHeader,
} from "@glide/function-utils";
import classNames from "classnames";
import deepEqual from "deep-equal";
import sortBy from "lodash/sortBy";
import * as React from "react";

import SectionTabBar from "../section-tab-bar/section-tab-bar";
import { RichTooltip } from "../tooltip/rich-tooltip";
import { GroupUIStyle } from "./group-ui-style";
import "twin.macro";
import InputLabel from "../input-label/input-label";

export interface CustomSectionItem {
    readonly section: SuperPropertySection;
    readonly position?: number; // defaults to `0`
    readonly ui: React.ReactNode;
}

interface ResultType {
    items: CustomSectionItem[];
    section: SuperPropertySection;
}

interface Props extends React.PropsWithChildren {
    readonly items: readonly CustomSectionItem[];
    readonly selectedHeader?: HeaderSection;
    readonly onHeaderSelected?: (header: HeaderSection) => void;
    readonly accessoryForSection?: (section: SuperPropertySection) => React.ReactNode;
    readonly canFold?: ((section: SuperPropertySection) => boolean) | boolean;
    readonly collapseAllWhenFirstFolded?: boolean;
    readonly drawSeparators?: boolean;

    readonly foldedItems?: readonly string[];
    readonly onFoldedItemsChange?: (newItems: readonly string[]) => void;

    readonly bananaButtonText?: string;
    readonly bananaContent?: React.ReactNode;
    readonly className?: string;
    readonly showBanana?: boolean;
    readonly onBananaShow?: (show: boolean) => void;

    readonly docURL?: string;
}

interface SectionTitleProps {
    folded: boolean;
    onSearch?: (search: string) => void;
    titleContent?: React.ReactNode;
}

const SectionTitle: React.FC<SectionTitleProps> = p => {
    const { onSearch, titleContent, folded } = p;
    const [isSearching, setIsSearching] = React.useState(false);
    const [query, setQuery] = React.useState("");

    const onQueryChange = React.useCallback(
        (str: string) => {
            setQuery(str);
            onSearch?.(str.trim().toLowerCase());
        },
        [onSearch]
    );

    const startSearching = React.useCallback(() => {
        setIsSearching(true);
        onQueryChange("");
    }, [onQueryChange]);

    const stopSearching = React.useCallback(() => {
        setIsSearching(false);
        onQueryChange("");
    }, [onQueryChange]);

    return (
        <div className="section-title-container">
            {isSearching ? (
                <>
                    <InputLabel
                        tw="w-full mr-1"
                        value={query}
                        onChange={e => {
                            onQueryChange(e.target.value);
                        }}
                        onKeyDown={e => {
                            if (e.key === "Escape") {
                                stopSearching();
                            }
                        }}
                        placeholder="Search"
                        autoFocus={true}
                    />
                    <SectionAccessoryButton onClick={stopSearching}>
                        <GlideIcon kind="stroke" icon="st-close" iconSize={20} />
                    </SectionAccessoryButton>
                </>
            ) : (
                <>
                    {!folded && onSearch !== undefined && (
                        <button
                            tw="p-1 mr-1 -ml-1 text-icon-base rounded-lg hover:(bg-n200A text-icon-dark)"
                            onClick={startSearching}>
                            <GlideIcon kind="stroke" icon="st-search" iconSize={20} />
                        </button>
                    )}
                    {titleContent}
                </>
            )}
        </div>
    );
};

export const GroupUI: React.VFC<Props> = p => {
    const {
        className,
        items,
        selectedHeader,
        onHeaderSelected,
        canFold,
        accessoryForSection,
        drawSeparators,
        collapseAllWhenFirstFolded,
        foldedItems,
        onFoldedItemsChange,
        bananaButtonText,
        bananaContent,
        showBanana: showBananaOuter,
        onBananaShow: setShowBananaOuter,
        docURL,
    } = p;
    const separators = drawSeparators === true;

    const headers = React.useMemo(() => {
        return Array.from(new Set(items.map(i => headerSectionForPropertySection(i.section))))
            .filter(h => h !== "Hidden")
            .sort((a, b) => headerSectionOrder.indexOf(a) - headerSectionOrder.indexOf(b));
    }, [items]);

    const needsBar = headers.length > 1;

    const [innerSelected, setInnerSelected] = React.useState(headers[0]);

    React.useEffect(() => {
        if (headers.length === 0) return;

        if (selectedHeader !== undefined && onHeaderSelected !== undefined && headers.indexOf(selectedHeader) === -1) {
            onHeaderSelected(headers[0]);
        } else if (headers.indexOf(innerSelected) === -1) {
            setInnerSelected(headers[0]);
        }
    }, [headers, innerSelected, onHeaderSelected, selectedHeader]);

    const selected = selectedHeader ?? innerSelected;

    const onHeaderChange = React.useCallback(
        (header: HeaderSection) => {
            (onHeaderSelected ?? setInnerSelected)(header);
        },
        [onHeaderSelected]
    );

    const toRender = React.useMemo(() => {
        const filtered = items.filter(i => sectionBelongsToHeader(i.section, selected));
        const sorted = sortBy(filtered, fi => getSectionPriority(fi.section));

        const result: ResultType[] = [];
        let last: ResultType | undefined;

        sorted.forEach(i => {
            if (last === undefined || !deepEqual(last.section, i.section)) {
                last = {
                    items: [],
                    section: i.section,
                };
                result.push(last);
            }
            last.items.push(i);
        });

        return result;
    }, [items, selected]);

    const [foldedSet, setFoldedSet] = React.useState<readonly string[]>(foldedItems ?? []);
    const effectiveFoldedSet = foldedSet;
    const effectedSetFoldedSet = React.useCallback(
        (newItems: readonly string[]) => {
            onFoldedItemsChange?.(newItems);
            setFoldedSet(newItems);
        },
        [onFoldedItemsChange]
    );

    const [showBananaInner, setShowBananaInner] = React.useState(false);

    const showBanana = showBananaOuter ?? showBananaInner;
    const setShowBanana = setShowBananaOuter ?? setShowBananaInner;

    const onBananaClick = React.useCallback(() => {
        setShowBanana(true);
    }, [setShowBanana]);

    const content = React.useMemo(
        () =>
            toRender.map((s, i) => {
                if (showBanana && i !== 0) return null;

                const name = getSectionName(s.section);
                const key = getSectionKey(s.section, i);
                const foldable =
                    !showBanana && (canFold === true || (typeof canFold === "function" && canFold(s.section)));
                const folded = effectiveFoldedSet.includes(key);

                const onSearch = typeof s.section !== "string" ? s.section.onSearch : undefined;

                if (
                    collapseAllWhenFirstFolded === true &&
                    i !== 0 &&
                    effectiveFoldedSet.includes(getSectionName(toRender[0].section))
                ) {
                    return null;
                }

                const accessory = accessoryForSection?.(s.section);

                const fold = (e: React.MouseEvent) => {
                    if (!foldable) return;
                    e.stopPropagation();
                    e.preventDefault();

                    if (e.altKey) {
                        if (
                            effectiveFoldedSet.length === toRender.filter(v => getSectionName(v.section) !== "").length
                        ) {
                            effectedSetFoldedSet([]);
                        } else {
                            effectedSetFoldedSet(toRender.map(v => getSectionName(v.section)).filter(n => n !== ""));
                        }
                    } else {
                        if (folded) {
                            effectedSetFoldedSet(effectiveFoldedSet.filter(n => n !== key));
                        } else {
                            effectedSetFoldedSet([...effectiveFoldedSet, key]);
                        }
                    }
                };

                const onSectionClick = showBanana ? () => setShowBanana(false) : foldable ? fold : undefined;

                let tooltipDocsUrl: string | undefined;
                switch (s.section) {
                    // case PropertySection.Style: // commented out because currently we don't have a good landing page for it
                    //     tooltipDocsUrl = getDocURL("style");
                    //     break;
                    case PropertySection.Action:
                    case PropertySection.Actions:
                        tooltipDocsUrl = getDocURL("actions");
                        break;
                    case PropertySection.Visibility:
                        tooltipDocsUrl = getDocURL("visibility");
                        break;
                    case PropertySection.FilterData:
                        tooltipDocsUrl = getDocURL("filters");
                        break;
                    case PropertySection.Design:
                        tooltipDocsUrl = getDocURL("design");
                        break;
                    case PropertySection.CollectionItems:
                    case PropertySection.Data:
                        tooltipDocsUrl = getDocURL("data");
                        break;
                    default:
                        if (docURL && i === 0) {
                            tooltipDocsUrl = docURL;
                        }
                }

                return (
                    <React.Fragment key={key}>
                        <div className="section-wrapper">
                            {name !== "" && (
                                <SectionTitle
                                    folded={folded}
                                    onSearch={onSearch}
                                    titleContent={
                                        <>
                                            <div
                                                className={classNames(
                                                    "section-title",
                                                    (canFold || showBanana) && "pointer",
                                                    showBanana && "secondary",
                                                    tooltipDocsUrl !== undefined && "with-tooltip"
                                                )}
                                                onClick={onSectionClick}>
                                                <span>{name}</span>
                                                {tooltipDocsUrl && <RichTooltip docURL={tooltipDocsUrl} />}
                                            </div>
                                            {bananaButtonText && i === 0 && (
                                                <div
                                                    className={classNames(
                                                        "section-title",
                                                        !showBanana && "pointer",
                                                        !showBanana && "secondary"
                                                    )}
                                                    onClick={onBananaClick}>
                                                    {bananaButtonText}
                                                </div>
                                            )}
                                            {foldable && (
                                                <AppIcon
                                                    icon="caret"
                                                    size={10}
                                                    className="caret pointer"
                                                    degreesOfRotation={folded ? -90 : 0}
                                                    onClick={fold}
                                                />
                                            )}
                                            {accessory && <div className="section-accessory">{accessory}</div>}
                                        </>
                                    }
                                />
                            )}
                            {showBanana && bananaContent}
                            {!folded &&
                                !showBanana &&
                                s.items.map((config, j) => (
                                    <div className="section-content" key={j}>
                                        {config.ui}
                                    </div>
                                ))}
                        </div>
                        {separators && i !== toRender.length - 1 && <div className="sep" />}
                    </React.Fragment>
                );
            }),
        [
            accessoryForSection,
            bananaButtonText,
            bananaContent,
            canFold,
            collapseAllWhenFirstFolded,
            effectedSetFoldedSet,
            effectiveFoldedSet,
            docURL,
            onBananaClick,
            separators,
            setShowBanana,
            showBanana,
            toRender,
        ]
    );

    return (
        <GroupUIStyle className={classNames(className, "group-ui-style")}>
            {needsBar && (
                <SectionTabBar
                    className={classNames("section-tab-bar")}
                    items={headers}
                    value={selected}
                    onChange={onHeaderChange}
                />
            )}
            <div className="content-container">{content}</div>
        </GroupUIStyle>
    );
};

interface BProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const SectionAccessoryButton: React.FC<React.PropsWithChildren<React.PropsWithChildren<BProps>>> = p => {
    const { className, children, ...rest } = p;

    return (
        <button {...rest} className={classNames(className, "accessory-button")}>
            {children}
        </button>
    );
};
