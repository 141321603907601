import { getSpinner } from "@glide/common-core/dist/js/components/activity-spinner/activity-spinner";
import type { IconImage } from "@glide/app-description";
import { assert } from "@glideapps/ts-necessities";
import classNames from "classnames";
import isString from "lodash/isString";
import { css } from "styled-components";
import tw from "twin.macro";

import type { BaseProps } from "../custom-sign-in-props";
import { WireEmailInput } from "./wire-email-input";
import { useAddToHomescreen } from "../../../chrome/add-to-homescreen/use-add-to-homescreen";
import { authThemeAccent, authThemeDark, authThemeHighlight } from "@glide/common-components";
import { AddToHomescreen } from "../../../chrome/add-to-homescreen/add-to-homescreen";
import type { PagesLoginSource } from "@glide/auth-controller-core";
import { useWireAppTheme } from "../../../utils/use-wireapp-theme";

interface Props extends BaseProps {
    readonly pagesSource?: PagesLoginSource;
    readonly onPageSourceChanged?: (newPageSource: PagesLoginSource) => void;
    readonly iconImage?: IconImage;
    readonly appID: string;
}

export const CustomWireSignIn: React.FC<React.PropsWithChildren<Props>> = p => {
    const theme = useWireAppTheme();

    const {
        loginType,
        currentState,
        previewMode,
        appTitle,
        pagesSource,
        appKind,
        iconImage,
        canCustomizeSignIn,
        appID,
    } = p;

    // These are not supported in pages
    assert(loginType !== "none" && loginType !== "password");
    const { showingAddToHomescreenDialog, closeAddToHomescreenDialog } = useAddToHomescreen();

    const hasBackgroundImage = isString(theme.signInBackground) && canCustomizeSignIn;

    const content =
        currentState === "sign-in" ? (
            <div
                tw="flex justify-center items-center self-center [margin-top: 80px] [min-height: 200px] [margin-bottom: 80px]"
                className="spinner"
            >
                {getSpinner(theme.n0, "loading-placeholder")}
            </div>
        ) : (
            <div tw="flex flex-col items-center">
                <div
                    className={classNames(pagesSource, hasBackgroundImage && "has-background-image")}
                    css={css`
                        ${tw`w-full [max-width: 390px] gp-sm:(rounded-[36px] px-10) rounded-[20px] px-4 pb-4`}

                        .${authThemeDark} & {
                            background-color: ${themeProps => themeProps.theme.darkAccent};
                        }

                        .${authThemeHighlight} & {
                            ${tw`bg-bg-front page-md:(shadow-[0_0_0_5px_rgb(0,0,0,0.03)] border border-[rgba(5, 26, 46, 0.1)])`}
                        }

                        .${authThemeAccent}.mobile & {
                            ${tw`bg-accent`}
                        }

                        .${authThemeAccent} & {
                            ${tw`bg-bg-front`}
                        }

                        .has-background-image &,
                        .has-background-image.mobile & {
                            backdrop-filter: blur(18px);
                            ${tw`bg-[rgba(0, 0, 0, 0.5)]`}
                        }

                        .has-background-image.mobile & {
                            width: calc(100% - 32px);
                            ${tw`mx-4`}
                        }
                    `}
                >
                    {loginType === "email" && <WireEmailInput theme={theme} {...p} />}
                </div>
            </div>
        );

    return (
        <div
            css={
                previewMode
                    ? css`
                          ${tw`pointer-events-none`}
                      `
                    : undefined
            }
            tw="text-base"
            role="dialog"
            aria-modal={true}
            aria-labelledby="sign-in-description"
        >
            {content}
            <AddToHomescreen
                isOpen={showingAddToHomescreenDialog}
                appKind={appKind}
                appTitle={appTitle ?? ""}
                closeDialog={closeAddToHomescreenDialog}
                iconImage={iconImage}
                appID={appID}
            />
        </div>
    );
};
