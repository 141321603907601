import React, { useState } from "react";
import { NodeViewWrapper } from "@tiptap/react";
import { useEditorContext, type ReactNodeViewProps } from "./editor-context";
import { Hovercard, HovercardAnchor, HovercardProvider } from "@ariakit/react/hovercard";
import { GlideIcon } from "@glide/common";
import { AnimatePresence, motion } from "framer-motion";
import { isDefined } from "@glide/support";

// For _some_ reason we're getting build errors when we don't cast the HovercardProvider
// we couldn't figure out why, and this still works, so I guess we'll find out eventually.
const TypedHovercardProvider = HovercardProvider as React.FC<{
    open?: boolean;
    children: React.ReactNode;
    showTimeout: number;
    hideTimeout: number;
}>;

function previewBase64Image(base64String: string) {
    // Ensure the base64 string has the correct data URI prefix
    const dataUri = base64String.startsWith("data:") ? base64String : `data:image/png;base64,${base64String}`;

    // Open a new window/tab with the image
    const newWindow = window.open();
    if (newWindow) {
        newWindow.document.write(`<img src="${dataUri}" alt="Base64 Image Preview" />`);
        newWindow.document.title = "Image Preview";
        newWindow.document.close();
    } else {
        // If popup is blocked, fallback to opening in the same tab
        window.location.href = dataUri;
    }
}

export interface ImageViewProps extends ReactNodeViewProps {
    onUpload: (file: File) => Promise<{
        url?: string;
    }>;
}

const useFilesControllerUpload = (
    onUpload: (file: File) => Promise<{
        url?: string;
    }>
) => {
    const [submittedAt, setSubmittedAt] = useState<Date | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const mutateAsync = async (file: File) => {
        const uploadWithRetry = async (): Promise<{ url?: string }> => {
            setError(null);
            try {
                const result = await onUpload(file);
                return result;
            } catch (e: unknown) {
                setError(e instanceof Error ? e : new Error("Unknown error occurred"));
                return {
                    url: undefined,
                };
            }
        };

        const response = await uploadWithRetry();
        if (response?.url) {
            setSubmittedAt(new Date());
        }

        return response;
    };

    return { mutateAsync, submittedAt, error, setError };
};

export const ImageView: React.FC<ImageViewProps> = ({ node, ...props }) => {
    const { mutateAsync: upload, submittedAt } = useFilesControllerUpload(props.onUpload);

    const { isDragging } = useEditorContext();
    const src = typeof node.attrs.src === "string" ? node.attrs.src : "";
    const alt = typeof node.attrs.alt === "string" ? node.attrs.alt : undefined;
    const title = typeof node.attrs.title === "string" ? node.attrs.title : undefined;

    const onClick = async (e: React.MouseEvent<HTMLDivElement>) => {
        if (node.attrs?.state === "error") {
            const defaultImageAttrs = {
                alt: alt ?? "Uploaded image",
                title: title ?? "Uploaded image",
            };

            props.updateAttributes({
                src: src,
                ...defaultImageAttrs,
                state: "loading",
            });

            const [, base64] = src.split(",");
            const binaryString = atob(base64);
            const fileType = src.split(";")[0].split(":")[1];
            const fileName = `image.${fileType.split("/")[1]}`;

            // Convert binary string to ArrayBuffer
            const arrayBuffer = new ArrayBuffer(binaryString.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryString.length; i++) {
                uint8Array[i] = binaryString.charCodeAt(i);
            }

            // Create a Blob object
            const blob = new Blob([arrayBuffer], { type: fileType });

            // Create a File object
            const file = new File([blob], fileName, { type: fileType });

            const uploadedFile = await upload(file);

            if (!isDefined(uploadedFile)) {
                props.updateAttributes({
                    src: src,
                    state: "error",
                    ...defaultImageAttrs,
                });
                return;
            }

            props.updateAttributes({
                src: uploadedFile.url ?? src,
                state: uploadedFile.url ? "loaded" : "error",
                ...defaultImageAttrs,
            });

            return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (src.startsWith("data:")) {
            previewBase64Image(src);
            return;
        }

        window.open(src, "_blank");
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Backspace") {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const isImageSelected = props.selected;
    const [isVisible, setIsVisible] = useState(true);

    return (
        <NodeViewWrapper tw="inline-flex max-w-full">
            <TypedHovercardProvider
                showTimeout={500}
                hideTimeout={100}
                open={
                    isDragging || node.attrs?.state === "error" || node.attrs?.state === "loading" ? false : undefined
                }>
                <HovercardAnchor
                    render={
                        <motion.div
                            data-drag-handle
                            initial={src.startsWith("data:") ? { opacity: 0, scale: 0.9 } : { opacity: 1, scale: 1 }}
                            animate={{ opacity: 1, scale: 1 }}
                            data-error={node.attrs?.state === "error" ? "true" : undefined}
                            transition={{ duration: 0.1, ease: [0.17, 0.67, 0.83, 0.67] }}
                            aria-label={title}
                            onKeyDown={onKeyDown}
                            tw="flex-nowrap flex-shrink mr-px inline-flex h-[22px] cursor-pointer  select-none items-center gap-0.5 rounded-md border py-1 pl-0.5 pr-1 font-medium outline-none transition
                                data-[selected=true]:border data-[selected=true]:border-aqua400 data-[selected=true]:ring-1 data-[selected=true]:ring-aqua300
                                text-builder-base border-border-dark leading-4 hover:border-n500A bg-bg-front
                                data-[error=true]:border data-[error=true]:border-r300 data-[error=true]:ring-1 data-[error=true]:ring-r200
                                w-full max-w-fit overflow-hidden
                                "
                            data-selected={isImageSelected}
                            tabIndex={-1}
                            onClick={onClick}>
                            <div tw="relative pl-[22px] pr-1 py-0.5 inline-flex truncate max-w-full">
                                {node.attrs?.state === "error" ? (
                                    <div tw="flex items-center">
                                        <div tw="absolute left-0.5 top-1/2 -translate-y-1/2 flex justify-center items-center w-4 h-4 rounded bg-r400">
                                            <GlideIcon
                                                icon="st-alert-warning"
                                                kind="stroke"
                                                iconSize={14}
                                                tw="text-white"
                                            />
                                        </div>
                                        <span tw="text-text-xpale">Retry Upload?</span>
                                    </div>
                                ) : (
                                    <motion.div
                                        tw="flex items-center w-full"
                                        initial={{ opacity: src.startsWith("data:") ? 0 : 1 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.3 }}>
                                        <div tw="absolute left-0.5 top-1/2 -translate-y-1/2 flex justify-center items-center w-4 h-4 rounded overflow-hidden">
                                            <img
                                                src={src}
                                                alt={alt ?? "Preview"}
                                                tw="object-cover w-full h-full rounded"
                                            />
                                            <AnimatePresence onExitComplete={() => setIsVisible(false)}>
                                                {isVisible && !submittedAt && node.attrs.state === "loading" && (
                                                    <motion.div
                                                        tw="flex absolute inset-0 justify-center items-center bg-black/50"
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{
                                                            opacity: { duration: 0.5 },
                                                        }}>
                                                        <motion.div
                                                            animate={{ rotate: 360 }}
                                                            transition={{
                                                                duration: 1,
                                                                repeat: Infinity,
                                                                ease: "linear",
                                                            }}
                                                            onAnimationComplete={() => {
                                                                if (submittedAt) {
                                                                    setIsVisible(false);
                                                                }
                                                            }}>
                                                            <GlideIcon
                                                                icon="st-spinner"
                                                                kind="stroke"
                                                                iconSize={12}
                                                                tw="text-white"
                                                            />
                                                        </motion.div>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                        <span tw="truncate">{title ?? "image"}</span>
                                    </motion.div>
                                )}
                            </div>
                        </motion.div>
                    }
                />
                {isDefined(title) ? (
                    <Hovercard
                        portal
                        portalElement={document.getElementById("portal")}
                        hideOnEscape
                        gutter={8}
                        tw="rounded border cursor-default z-[99999] isolate border-border-dark text-text-pale bg-bg-front shadow-lg-dark">
                        <div tw="inline-flex overflow-hidden items-center w-[100px] h-[100px]">
                            <div onClick={onClick}>
                                <img src={src} alt={title} tw="object-cover w-full h-full rounded cursor-pointer" />
                            </div>
                        </div>
                    </Hovercard>
                ) : null}
            </TypedHovercardProvider>
        </NodeViewWrapper>
    );
};
