import { getLocalizedString } from "@glide/localization";
import { launchNativeGoogleOauth2Flow } from "@glide/common-core/dist/js/native-hooks";
import { getCredentials, getHTTPGoogleOAuth2RedirectURL } from "@glide/auth-controller-core";
import type { AuthProviderCallbacks, InteractionProps } from "./types";
import { GoogleLogo, disabledProps, styled } from "@glide/common-components";

const signInWithGoogleClassName = "sign-in-with-google";

const Button = styled.button<{ isEnabled: boolean }>`
    height: 46px;
    margin-top: 20px;
    color: ${p => p.theme.button.filledTextColor};
    && {
        background-color: ${p => p.theme.primaryAccentColor};
    }
    border: none;
    outline: none;
    border-radius: 6px;

    font-weight: 600;
    font-size: 17px;

    ${p => !p.isEnabled && disabledProps}

    &.${signInWithGoogleClassName} {
        width: 100%;
        text-align: center;
    }
`;

const SignInWithGoogleLogoContainer = styled.div`
    height: 20px;
    width: 20px;

    margin: 4px 9px 4px 0px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const SignInWithGoogleContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    & label {
        line-height: 1em;
    }
`;

export function GoogleAuthProviderButton(props: AuthProviderCallbacks & InteractionProps): JSX.Element {
    const { userAgreed, appKind } = props;

    return (
        <Button
            className={signInWithGoogleClassName}
            id={"sign-in-with-google-button"}
            isEnabled={userAgreed}
            onClick={async e => {
                e.preventDefault();
                // FIXME: This will need to be set on a per-app basis in the future.
                const clientID = getCredentials().clientId;
                if (await launchNativeGoogleOauth2Flow(clientID)) return;

                props.createPopupForOAuth2Flow(getHTTPGoogleOAuth2RedirectURL);
            }}
        >
            <SignInWithGoogleContentWrapper>
                <SignInWithGoogleLogoContainer>
                    <GoogleLogo />
                </SignInWithGoogleLogoContainer>
                <label htmlFor={"sign-in-with-google-button"}>{getLocalizedString("signInWithGoogle", appKind)}</label>
            </SignInWithGoogleContentWrapper>
        </Button>
    );
}

export function GoogleSignInButton(props: InteractionProps): JSX.Element {
    const { userAgreed, appKind } = props;

    return (
        <Button className={signInWithGoogleClassName} id={"sign-in-with-google-button"} isEnabled={userAgreed}>
            <SignInWithGoogleContentWrapper>
                <SignInWithGoogleLogoContainer>
                    <GoogleLogo />
                </SignInWithGoogleLogoContainer>
                <label htmlFor={"sign-in-with-google-button"}>{getLocalizedString("signInWithGoogle", appKind)}</label>
            </SignInWithGoogleContentWrapper>
        </Button>
    );
}
