import { systemFontFamily } from "@glide/base-theme";
import { createGlobalStyle } from "styled-components";

// Add link underline, filter by css selector
// Filter out player
export const GlideGlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        overflow: hidden;
    }

    *,
    *::after,
    *::before {
        outline: none !important;
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    html body {
        font-weight: normal;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.9);
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-family: ${systemFontFamily};
        padding: 0 !important;
    }

    html, body {
        overscroll-behavior-x: none;
    }

    #root {
        position: relative;
    }

    a {
        text-decoration: none !important;
        color: inherit;
    }

    input {
        appearance: none;
        border: none;
        background: none;
        font: inherit;
    }

    button {
        appearance: none;
        background: none;
        font: inherit;
        color: inherit;
    }

    /* Override Google Picker modal styles */
    .picker-dialog-bg {
        background-color: transparent !important;
    }

    #portal {
        > * {
            pointer-events: auto;
        }
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: ${Math.pow(10, 7)}

    }

    .scrollbar-hide {
        /* Firefox */
            scrollbar-width: none;

        /* Safari and Chrome */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .glide-toast {
        background-color: #059669;
        line-height: 1rem;
        padding: 6px 10px;
        border-radius: 12px;
        box-shadow: 0px 0px 1px rgba(62, 65, 86, 0.25), 0px 20px 48px rgba(62, 65, 86, 0.2);
        color: white;
        font-size: 14px;
    }

    //this is used in unified-chrome.tsx, which only applies to the player
    .glide-toast-dark {
        padding: 6px 10px;
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.70);
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1), 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
        backdrop-filter: blur(8px);
        color: white;
        font-size: 14px;
        line-height: 1rem;
    }

    .glide-toast-dark > div:first-child > div {
        border-width: 1px;
        border-width: 1.5px;
        width: 16px;
        height: 16px;
    }

    body > .picker.picker-dialog {
        z-index: 10000000;
    }

    small {
        font-size: max(80%, 11px);
    }

    .rc-tooltip.rc-tooltip-fade-appear,
    .rc-tooltip.rc-tooltip-fade-enter {
        opacity: 0;
    }
    .rc-tooltip.rc-tooltip-fade-enter,
    .rc-tooltip.rc-tooltip-fade-leave {
        display: block;
    }
    .rc-tooltip-fade-enter,
    .rc-tooltip-fade-appear {
        opacity: 0;
        animation-fill-mode: both;
        transition-timing-function: ease-in;
        animation-play-state: paused;
    }
    .rc-tooltip-fade-leave {
        animation-fill-mode: both;
        transition-timing-function: ease-in;
        animation-play-state: paused;
    }
    .rc-tooltip-fade-enter.rc-tooltip-fade-enter-active,
    .rc-tooltip-fade-appear.rc-tooltip-fade-appear-active {
        animation-name: rcToolTipFadeIn;
        animation-play-state: running;
    }
    .rc-tooltip-fade-leave.rc-tooltip-fade-leave-active {
        animation-name: rcToolTipFadeOut;
        animation-play-state: running;
    }
    @keyframes rcToolTipFadeIn {
        0% {
        opacity: 0;
        }
        100% {
        opacity: 1;
        }
    }
    @keyframes rcToolTipFadeOut {
        0% {
        opacity: 1;
        }
        100% {
        opacity: 0;
        }
    }
    .rc-tooltip {
        position: absolute;
        z-index: 1070;
        display: block;
        visibility: visible;
        font-size: 12px;
        line-height: 1.5;
        animation-duration: ${p => (p.theme as any).hoverTransition};
    }
    .rc-tooltip-hidden {
        display: none;
    }
    .rc-tooltip-placement-top,
    .rc-tooltip-placement-topLeft,
    .rc-tooltip-placement-topRight {
        padding: 5px 0 9px 0;

        .rc-tooltip-inner {
            margin-bottom: 0;
        }
    }
    .rc-tooltip-placement-right,
    .rc-tooltip-placement-rightTop,
    .rc-tooltip-placement-rightBottom {
        padding: 0 5px 0 9px;

        .rc-tooltip-inner {
            margin-left: 0;
        }
    }
    .rc-tooltip-placement-bottom,
    .rc-tooltip-placement-bottomLeft,
    .rc-tooltip-placement-bottomRight {
        padding: 9px 0 5px 0;

        .rc-tooltip-inner {
            margin-top: 0;
        }
    }
    .rc-tooltip-placement-left,
    .rc-tooltip-placement-leftTop,
    .rc-tooltip-placement-leftBottom {
        padding: 0 9px 0 5px;

        .rc-tooltip-inner {
            margin-right: 0;
        }
    }
    .rc-tooltip-inner {
        padding: 8px 12px;
        color: ${p => (p.theme as any).bgFront};
        text-align: left;
        text-decoration: none;
        background-color: ${p => (p.theme as any).n800};
        border-radius: 9px;
        box-shadow: ${p => (p.theme as any).shadow3};
        min-height: 34px;
        margin: 10px;
    }
`;
