import { components as fluentComponents } from "@glide/fluent-components/dist/js/fluent-components";
import { registerComponentHandler } from "../handlers";
import { AudioPlayerComponentHandler } from "./audio-player";
import { ButtonComponentHandler } from "./button";
import { ButtonBarComponentHandler } from "./button-bar";
import { BuyButtonComponentHandler } from "./buy-button";
import { CheckboxComponentHandler } from "./checkbox";
import { ChoiceComponentHandler } from "./choice";
import { CommentsComponentHandler } from "./comments";
import { CompoundComponentHandler } from "./compound";
import { DateFieldComponentHandler } from "./date-fields";
import { DateTimeFieldComponentHandler } from "./date-time-field";
import { EmailComponentHandler } from "./email";
import { EmailFieldComponentHandler } from "./email-field";
import { FavoriteComponentHandler } from "./favorite";
import { FilePickerComponentHandler } from "./file-picker";
import { getInflatorForComponent } from "./fluent-component-inflators";
import { makeFluentComponentHandler } from "./fluent-components-handlers";
import { HintComponentHandler } from "./hint";
import { ImageComponentHandler } from "./image";
import { ImagePickerComponentHandler } from "./image-picker";
import { InlineListComponentHandler } from "./inline-list";
import { LikeComponentHandler } from "./like";
import { LinkComponentHandler } from "./link";
import { ListReferenceComponentHandler } from "./list-reference";
import { LocationEntryComponentHandler } from "./location-entry";
import { MapComponentHandler } from "./map";
import { MarkdownComponentHandler } from "./markdown";
import { NotesComponentHandler } from "./notes";
import { NumberFieldComponentHandler } from "./number-field";
import { PhoneFieldComponentHandler } from "./phone-field";
import { PhoneNumberComponentHandler } from "./phone-number";
import { PrimitiveComponentHandler } from "./primitive";
import { ProgressComponentHandler } from "./progress";
import { RatingComponentHandler } from "./rating";
import { ReactionComponentHandler } from "./reaction";
import { ReferenceComponentHandler } from "./reference";
import { SeparatorComponentHandler } from "./separator";
import { SignatureComponentHandler } from "./signature";
import { SimpleTableComponentHandler } from "./simple-table";
import { StopwatchComponentHandler } from "./stopwatch";
import { SwitchComponentHandler } from "./switch";
import { TextBoxComponentHandler } from "./text-box";
import { TextFieldComponentHandler } from "./text-field";
import { TitleComponentHandler } from "./title";
import { UserProfileComponentHandler } from "./user-profile";
import { VideoComponentHandler } from "./video-player";
import { WebviewComponentHandler } from "./webview";
import { YouTubeComponentHandler } from "./youtube";

let didInit = false;

export function registerComponentHandlers(): void {
    if (didInit) return;
    didInit = true;

    registerComponentHandler(new PrimitiveComponentHandler());
    registerComponentHandler(new CompoundComponentHandler());
    registerComponentHandler(new ReferenceComponentHandler());
    registerComponentHandler(new ListReferenceComponentHandler());
    registerComponentHandler(new LinkComponentHandler());
    registerComponentHandler(new InlineListComponentHandler());
    registerComponentHandler(new TitleComponentHandler());
    registerComponentHandler(new MarkdownComponentHandler());
    registerComponentHandler(new MapComponentHandler());
    registerComponentHandler(new ImageComponentHandler());
    registerComponentHandler(new AudioPlayerComponentHandler());
    registerComponentHandler(new TextBoxComponentHandler());
    registerComponentHandler(new YouTubeComponentHandler());
    registerComponentHandler(new CommentsComponentHandler());
    registerComponentHandler(new FavoriteComponentHandler());
    registerComponentHandler(new LikeComponentHandler());
    registerComponentHandler(new RatingComponentHandler());
    registerComponentHandler(new EmailComponentHandler());
    registerComponentHandler(new PhoneNumberComponentHandler());
    registerComponentHandler(new ButtonComponentHandler());
    registerComponentHandler(new ButtonBarComponentHandler());
    registerComponentHandler(new NotesComponentHandler());
    registerComponentHandler(new HintComponentHandler());
    registerComponentHandler(new TextFieldComponentHandler());
    registerComponentHandler(new DateTimeFieldComponentHandler());
    registerComponentHandler(new NumberFieldComponentHandler());
    registerComponentHandler(new PhoneFieldComponentHandler());
    registerComponentHandler(new EmailFieldComponentHandler());
    registerComponentHandler(new SwitchComponentHandler());
    registerComponentHandler(new CheckboxComponentHandler());
    registerComponentHandler(new ChoiceComponentHandler());
    registerComponentHandler(new VideoComponentHandler());
    registerComponentHandler(new SeparatorComponentHandler());
    registerComponentHandler(new ImagePickerComponentHandler());
    registerComponentHandler(new FilePickerComponentHandler());
    registerComponentHandler(new BuyButtonComponentHandler());
    registerComponentHandler(new ProgressComponentHandler());
    registerComponentHandler(new DateFieldComponentHandler());
    registerComponentHandler(new UserProfileComponentHandler());
    registerComponentHandler(new SimpleTableComponentHandler());
    registerComponentHandler(new WebviewComponentHandler());
    registerComponentHandler(new LocationEntryComponentHandler());
    registerComponentHandler(new StopwatchComponentHandler());
    registerComponentHandler(new SignatureComponentHandler());
    registerComponentHandler(new ReactionComponentHandler());

    Object.values(fluentComponents).forEach(component => {
        registerComponentHandler(makeFluentComponentHandler(component, getInflatorForComponent(component.spec.kind)));
    });
}
