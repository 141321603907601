import { load, save, unset } from "./storage";

const trackingConsentKey = "glide-cookie-consent";

let currentTrackingConsent: boolean | undefined;

const consentChangeHandlers = new Set<(consent: boolean) => void>();
export function onTrackingConsentChange(fn: (consent: boolean) => void) {
    if (consentChangeHandlers.has(fn)) return;

    consentChangeHandlers.add(fn);
    if (currentTrackingConsent !== undefined) {
        fn(currentTrackingConsent);
    }
}

export function getTrackingConsent(): boolean {
    if (currentTrackingConsent !== undefined) return currentTrackingConsent;

    const loaded = load<boolean>(trackingConsentKey);
    const ret = loaded ?? false;
    if (loaded !== undefined) {
        for (const fn of consentChangeHandlers) {
            fn(loaded);
        }
    }
    return ret;
}

export function setTrackingConsent(consent: boolean) {
    save<boolean>(trackingConsentKey, consent);
    currentTrackingConsent = consent;
    for (const fn of consentChangeHandlers) {
        fn(consent);
    }
}

// Note that this doesn't explicitly set it to "false", this just
// makes it indeterminate.
export function unsetTrackingConsent() {
    unset(trackingConsentKey);
    for (const fn of consentChangeHandlers) {
        fn(false);
    }
}

const withConsentQueue: (() => void)[] = [];

onTrackingConsentChange(runQueue => {
    if (!runQueue) return;
    for (const entry of withConsentQueue) {
        entry();
    }
    withConsentQueue.splice(0, withConsentQueue.length);
});

export function withTrackingConsent(fn: () => void) {
    if (getTrackingConsent()) {
        fn();
    } else {
        withConsentQueue.push(fn);
    }
}
