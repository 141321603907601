import type {
    WireActionWithTitle,
    WireSuperTableBooleanCell,
    WireSuperTableButtonCell,
    WireSuperTableChoiceCell,
    WireSuperTableExtraActionsCell,
    WireSuperTableListComponent,
    WireSuperTableRow,
    WireSuperTableRowHeaderCell,
    WireSuperTableTagsCell,
    WireTagOrChoiceItem,
} from "@glide/fluent-components/dist/js/base-components";
import type { WireRenderer } from "../wire-renderer";
import * as React from "react";

import { type WirePagedGroup, type WirePaging, APP_MODAL_ROOT, UIStyleVariant, ValueChangeSource } from "@glide/wire";
import { assertNever } from "@glideapps/ts-necessities";
import { ignore, isDefined } from "@glide/support";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { definedMap } from "collection-utils";
import { WireListContainer, useMultipleDynamicFilters, useSearchBar } from "../wire-list-container/wire-list-container";
import { extractActions, runActionAndHandleURL } from "../../wire-lib";
import { type UseLayerProps, useLayer } from "react-laag";
import { WireFloatingMenu } from "../wire-menu-button/wire-menu-button";
import { ClickOutsideContainer, getGenerativeColor, isDarkTheme } from "@glide/common";
import "twin.macro";
import { Pager } from "../card-collection/card-collection";
import type {
    SuperTableBooleanCell,
    SuperTableButtonCell,
    SuperTableChoiceCell,
    SuperTableColumn,
    SuperTableExtraActionsCell,
    SuperTableRow,
    SuperTableRowHeaderCell,
    SuperTableTagsCell,
    TagOrChoiceItem,
} from "@glide/component-utils";
import { NewTable } from "../../components/new-table/new-table";
import type { WireAppTheme } from "@glide/theme";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import * as Table from "../../components/new-table/new-table-lib";

function makeSuperTableRowFromWire(
    backend: WireBackendInterface,
    wireRow: WireSuperTableRow,
    setExtraActionsProps: React.Dispatch<React.SetStateAction<ExtraActionsProps | undefined>>,
    theme: WireAppTheme
): SuperTableRow {
    const row: SuperTableRow = wireRow.map(wireCell => {
        switch (wireCell.kind) {
            case "text":
                return wireCell;

            case "boolean":
                return makeSuperTableBooleanCellFromWire(backend, wireCell);

            case "image":
                return wireCell;

            case "link":
                return wireCell;

            case "button":
                return makeSuperTableButtonCellFromWire(backend, wireCell);

            case "row-header":
                return makeSuperTableRowHeaderCellFromWire(backend, wireCell);

            case "extra-actions":
                return makeSuperTableExtraActionsCellFromWire(backend, wireCell, setExtraActionsProps);

            case "choice":
                return makeSuperTableChoiceCellFromWire(backend, wireCell);

            case "tag":
                return makeSuperTableTagsCellFromWire(backend, wireCell, theme);

            default:
                assertNever(wireCell);
        }
    });

    return row;
}

function makeOnValueChange<T>(
    backend: WireBackendInterface,
    onChangeToken: string | undefined
): ((newValue: T) => void) | undefined {
    return definedMap(onChangeToken, t => {
        return (newValue: T) => {
            backend.valueChanged(t, newValue, ValueChangeSource.User);
        };
    });
}

function makeChoiceOptionFromWire(wireOption: WireTagOrChoiceItem, backend: WireBackendInterface): TagOrChoiceItem {
    const { onSelect, ...rest } = wireOption;

    return {
        ...rest,
        onSelect: definedMap(onSelect, action => () => {
            runActionAndHandleURL(action, backend, true);
        }),
    };
}

function getTagOptionsWithColor(cell: WireSuperTableTagsCell, theme: WireAppTheme): WireTagOrChoiceItem[] {
    const { options } = cell;
    if (cell.tagColorKind === "Manual") {
        return options;
    }

    const isDark = isDarkTheme(theme);
    const coloredOptions: WireTagOrChoiceItem[] = [];
    for (let i = 0; i < options.length; i++) {
        const option = options[i];
        const color = getGenerativeColor(theme.accent, i, isDark);

        coloredOptions.push({
            ...option,
            color,
        });
    }

    return coloredOptions;
}

function getChoiceOrTagItemsFromValue(value: string[], options: TagOrChoiceItem[]): TagOrChoiceItem[] {
    const items: TagOrChoiceItem[] = [];
    for (const v of value) {
        const valueNotInOptions: TagOrChoiceItem = {
            value: v,
            displayValue: v,
            color: undefined,
            onSelect: ignore,
        };

        const valueInOptions = options.find(o => o.value === v);

        items.push(valueInOptions ?? valueNotInOptions);
    }

    return items;
}

function makeSuperTableChoiceCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireSuperTableChoiceCell
): SuperTableChoiceCell {
    const { value, options: wireOptions, isEditable, isMulti } = wireCell;
    const options = wireOptions.map(o => makeChoiceOptionFromWire(o, backend));

    return {
        kind: "choice",
        value: getChoiceOrTagItemsFromValue(value, options),
        options,
        isEditable,
        isMulti,
    };
}

function makeSuperTableTagsCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireSuperTableTagsCell,
    theme: WireAppTheme
): SuperTableTagsCell {
    const options = getTagOptionsWithColor(wireCell, theme).map(o => makeChoiceOptionFromWire(o, backend));

    const { value, isEditable, isMulti } = wireCell;

    return {
        kind: "tag",
        value: getChoiceOrTagItemsFromValue(value, options),
        options,
        isEditable,
        isMulti,
    };
}

function makeSuperTableBooleanCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireSuperTableBooleanCell
): SuperTableBooleanCell {
    const { editableValue } = wireCell;
    const { value, onChangeToken } = editableValue;

    return {
        kind: "boolean",
        value: value,
        onValueChange: makeOnValueChange(backend, onChangeToken),
    };
}

function makeSuperTableButtonCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireSuperTableButtonCell
): SuperTableButtonCell {
    const { action, label, icon, style } = wireCell;

    const onClick = () => {
        runActionAndHandleURL(action, backend);
    };

    return {
        kind: "button",
        value: undefined,
        label,
        onClick: isDefined(action) ? onClick : undefined,
        icon,
        style,
    };
}

function makeSuperTableRowHeaderCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireSuperTableRowHeaderCell
): SuperTableRowHeaderCell {
    const { value, action, accessoryImage } = wireCell;

    const onClick = () => {
        runActionAndHandleURL(action, backend);
    };

    return {
        kind: "row-header",
        value: value,
        accessoryImage,
        onClick,
    };
}

function makeSuperTableExtraActionsCellFromWire(
    _backend: WireBackendInterface,
    wireCell: WireSuperTableExtraActionsCell,
    setExtraActionsProps: React.Dispatch<React.SetStateAction<ExtraActionsProps | undefined>>
): SuperTableExtraActionsCell {
    const { actions } = wireCell;

    return {
        kind: "extra-actions",
        value: undefined,
        onClick: (screenX: number, screenY: number) => {
            setExtraActionsProps({
                actions,
                screenX,
                screenY,
            });
        },
    };
}

interface ExtraActionsProps {
    readonly actions: readonly WireActionWithTitle[];
    readonly screenX: number;
    readonly screenY: number;
}

interface ExtraActionsMenuProps {
    readonly laagProps: UseLayerProps;
    readonly extraActionsProps: ExtraActionsProps | undefined;
    readonly setExtraActionsProps: React.Dispatch<React.SetStateAction<ExtraActionsProps | undefined>>;
}

function useExtraActionsMenu(): ExtraActionsMenuProps {
    const [extraActionsProps, setExtraActionsProps] = React.useState<ExtraActionsProps | undefined>();

    const getBounds = React.useCallback(() => {
        const screenX = extraActionsProps?.screenX ?? 0;
        const screenY = extraActionsProps?.screenY ?? 0;

        // eyeballed size to make it look similar to other menus
        return {
            top: screenY,
            left: screenX,
            right: screenX + 42,
            bottom: screenY + 36,
            width: 42,
            height: 36,
        };
    }, [extraActionsProps?.screenX, extraActionsProps?.screenY]);

    const laagProps = useLayer({
        isOpen: extraActionsProps !== undefined,
        auto: true,
        placement: "bottom-end",
        possiblePlacements: ["top-end", "top-start", "bottom-end", "bottom-start"],
        container: APP_MODAL_ROOT,
        triggerOffset: 5,
        trigger: {
            getBounds,
        },
    });

    return {
        laagProps,
        extraActionsProps,
        setExtraActionsProps,
    };
}

type WireSuperTableRenderer = WireRenderer<WireSuperTableListComponent, { isFirstComponent: boolean }>;

export const WireSuperTable: WireSuperTableRenderer = React.memo(p => {
    const {
        columns,
        groups,
        backend,
        isFirstComponent,
        multipleDynamicFilters,
        searchBar,
        title,
        titleActions,
        paging,
        activeSort,
        style,
    } = p;

    const containerRef = React.useRef<HTMLDivElement>(null);
    const { laagProps, extraActionsProps, setExtraActionsProps } = useExtraActionsMenu();
    const { renderLayer, layerProps } = laagProps;
    const theme = useWireAppTheme();
    const superTableGroups: WirePagedGroup<SuperTableRow>[] = React.useMemo(
        () =>
            groups.map(({ items, title: groupTitle, groupKey, paging: groupPaging }) => ({
                title: groupTitle,
                items: items.map(r => makeSuperTableRowFromWire(backend, r, setExtraActionsProps, theme)),
                groupKey,
                paging: groupPaging,
            })),
        [backend, groups, setExtraActionsProps, theme]
    );

    const closeMenu = React.useCallback(() => {
        setExtraActionsProps(undefined);
    }, [setExtraActionsProps]);

    const menuItems = React.useMemo(() => {
        const actions = extraActionsProps?.actions ?? [];

        return extractActions(actions, backend);
    }, [backend, extraActionsProps?.actions]);

    const multipleFilterProps = useMultipleDynamicFilters(multipleDynamicFilters, backend);
    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, undefined);
    const superTableColumns: SuperTableColumn[] = React.useMemo(
        () =>
            columns.map(c => ({
                ...c,
                headerAction: isDefined(c.headerAction)
                    ? () => runActionAndHandleURL(c.headerAction, backend)
                    : undefined,
            })),
        [backend, columns]
    );

    const scrollToTheTop = React.useCallback(() => {
        if (containerRef.current === null) {
            return;
        }

        containerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    const tdRef = React.useRef<HTMLTableCellElement>(null);
    const paginatorRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (containerRef.current === null || paginatorRef.current === null) {
            return;
        }

        paginatorRef.current.style.width = `${containerRef.current.clientWidth}px`;
    }, [containerRef.current?.clientWidth]);

    return (
        <>
            <WireListContainer
                ref={containerRef}
                title={title ?? undefined}
                titleActions={extractActions(titleActions, backend)}
                styleVariant={UIStyleVariant.Default}
                isFirstComponent={isFirstComponent}
                appKind={backend.appKind}
                searchValue={searchValue}
                onSearchChange={onSearchChange}
                multipleFilterProps={multipleFilterProps}
                // This is for legacy filters
                hasFilter={false}>
                <Table.Table>
                    {superTableGroups.length === 0 && (
                        <NewTable
                            columns={superTableColumns}
                            rows={[]}
                            activeSort={activeSort}
                            style={style}
                            title=""
                            appID={backend.appID}
                        />
                    )}
                    {superTableGroups.map(superTableGroup => (
                        <React.Fragment key={superTableGroup.groupKey}>
                            <NewTable
                                columns={superTableColumns}
                                rows={superTableGroup.items}
                                activeSort={activeSort}
                                style={style}
                                title={superTableGroup.title}
                                appID={backend.appID}
                            />

                            {superTableGroup.paging.numPages > 1 && (
                                <tr>
                                    <td ref={tdRef} colSpan={superTableColumns.length} tw={"h-12"}>
                                        {/* Centralizing the paginator inside the wire container */}
                                        <div tw={"relative mb-3 w-full h-12"}>
                                            <div ref={paginatorRef} tw={"flex sticky left-0 justify-center mb-3"}>
                                                <Paginator paging={superTableGroup.paging} backend={backend} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </Table.Table>

                {paging !== undefined && paging?.numPages > 1 && (
                    <Paginator backend={backend} paging={paging} scrollToTheTop={scrollToTheTop} />
                )}
            </WireListContainer>
            {extraActionsProps !== undefined &&
                menuItems.length > 0 &&
                renderLayer(
                    <div {...layerProps}>
                        <ClickOutsideContainer onClickOutside={closeMenu}>
                            <WireFloatingMenu menuItems={menuItems} closeMenu={closeMenu} />
                        </ClickOutsideContainer>
                    </div>
                )}
        </>
    );
});

interface PaginatorProps {
    readonly paging: WirePaging | undefined;
    readonly backend: WireBackendInterface;
    readonly scrollToTheTop?: () => void;
}

const Paginator: React.FC<PaginatorProps> = p => {
    const { paging, backend, scrollToTheTop } = p;

    if (paging === undefined) {
        return null;
    }

    const { numPages, pageIndex } = paging;

    const token = pageIndex.onChangeToken;

    if (numPages < 2 || token === undefined) {
        return null;
    }

    const onPageChange = (newPage: number) => {
        backend.valueChanged(token, newPage, ValueChangeSource.User);
        if (isDefined(scrollToTheTop)) {
            scrollToTheTop();
        }
    };

    return (
        <div tw={"self-center"}>
            <Pager numPages={numPages} page={pageIndex.value} onPageChange={onPageChange} />
        </div>
    );
};
