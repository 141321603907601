import { isLoadingValue } from "@glide/computation-model-types";
import {
    type ActionDescription,
    type PropertyDescription,
    ActionKind,
    makeColumnProperty,
} from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import type { AppDescriptionContext, ActionAvailability } from "@glide/function-utils";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import {
    type AdditionalEmailProperties,
    hydrateEmailURL,
    makeEmailGetters,
    makeEmailPropertyDescriptors,
} from "../components/email";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { ICON_BASE, PURPLE_500 } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins";

interface SendEmailActionDescription extends ActionDescription, AdditionalEmailProperties {
    readonly kind: ActionKind.SendEmailWithArgs;
    // This property name is also hardcoded in ##convertLegacyActions
    readonly email?: PropertyDescription;
}

export class SendEmailWithArgsHandler extends BaseActionHandler<SendEmailActionDescription> {
    public readonly kind = ActionKind.SendEmailWithArgs;
    public readonly iconName: GlideIconProps = {
        icon: "st-compose-email",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: PURPLE_500,
    };

    public readonly name: string = "Compose email";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: SendEmailActionDescription | undefined,
        { mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Communication,
            groupItemOrder: 1,
            needsScreenContext: true,
            properties: makeEmailPropertyDescriptors("email", true, mutatingScreenKind),
        };
    }

    public newDefaultActionDescription(c: TableColumn): SendEmailActionDescription | undefined {
        if (c.type.kind !== "email-address") return undefined;
        return { kind: ActionKind.SendEmailWithArgs, email: makeColumnProperty(c.name) };
    }

    public getTokenizedDescription(
        desc: SendEmailActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const columnToken = tokenForProperty(desc.email, env);
        if (columnToken === undefined) return undefined;

        return [
            {
                kind: "string",
                value: "to ",
            },
            columnToken,
        ];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: SendEmailActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const getters = makeEmailGetters(ib, desc.email, desc);
        if (getters === undefined) return arb.inflationError("Invalid email");

        return (vp, skipLoading) => {
            const url = hydrateEmailURL(vp, getters);
            if (isLoadingValue(url)) return arb.maybeSkipLoading(skipLoading, "Email address");
            if (url === undefined) return arb.error(true, "Invalid email");

            return async ab => {
                ab.actionCallbacks.openLink(url);
                return arb.addData({ url }).success();
            };
        };
    }
}
