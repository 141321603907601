import type {
    ActionComponentDescription,
    ComponentKind,
    LegacyPropertyDescription,
    MutatingScreenKind,
    PropertyDescription,
} from "@glide/app-description";
import {
    ActionKind,
    ArrayScreenFormat,
    getActionProperty,
    getSourceColumnProperty,
    makeActionProperty,
    makeEnumProperty,
    makeSourceColumnProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { isStringTypeKind, SourceColumnKind } from "@glide/type-schema";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireAppMapComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentDescriptor,
    EnumPropertyHandler,
    PropertySection,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { MapLocationKind } from "@glide/support";
import {
    type WireInflationBackend,
    type WireRowComponentHydratorConstructor,
    type WireAction,
    WireActionResult,
    WireComponentKind,
} from "@glide/wire";
import {
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { getActionsForComponent } from "./component-utils";
import { ComponentHandlerBase } from "./handler";
import { makeDefaultActionDescriptorWithKinds } from "./primitive";
import type { MapCollectionComponentDescription } from "@glide/fluent-components/dist/js/fluent-components";
import { ComponentKindInlineList, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { definedMap } from "collection-utils";

const ComponentKindMap: ComponentKind = "map";

enum LocationKind {
    Address = "address",
}

enum ZoomLevel {
    Near = "near",
    Medium = "medium",
    Far = "far",
}

const numberForZoomLevel: Record<ZoomLevel, number> = {
    [ZoomLevel.Near]: 15,
    [ZoomLevel.Medium]: 12,
    [ZoomLevel.Far]: 9,
};

const zoomLevelPropertyHandler = new EnumPropertyHandler(
    { zoomLevel: ZoomLevel.Near },
    "Zoom",
    "Zoom level",
    [
        {
            value: ZoomLevel.Near,
            label: "Near",
        },
        {
            value: ZoomLevel.Medium,
            label: "Medium",
        },
        {
            value: ZoomLevel.Far,
            label: "Far",
        },
    ],
    PropertySection.Design,
    "dropdown"
);

function getZoomLevelNumber(zoomLevel: ZoomLevel): number {
    // Just in case `zoomLevel` is actually not a `MapZoomLevel`
    return numberForZoomLevel[zoomLevel] ?? numberForZoomLevel[zoomLevelPropertyHandler.defaultCaseValue];
}

interface MapLocationAddress {
    readonly kind: LocationKind.Address;

    readonly addressPropertyName: LegacyPropertyDescription | undefined;
}

type MapLocation = MapLocationAddress;

interface MapComponentDescription extends ActionComponentDescription {
    readonly markerPropertyName: LegacyPropertyDescription | undefined;

    readonly location: MapLocation;
    readonly zoomLevel: PropertyDescription | undefined;
}

export class MapComponentHandler extends ComponentHandlerBase<MapComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindMap);
    }

    protected makeEmptyDescription(): MapComponentDescription {
        return {
            ...super.makeEmptyDescription(),
            location: { kind: LocationKind.Address },
        } as MapComponentDescription;
    }

    public getDescriptor(
        _desc: MapComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Map",
            description: "A map that opens full screen when tapped",
            img: "co-map",
            group: "Media",
            helpUrl: getDocURL("mapImage"),
            properties: [
                makeTextPropertyDescriptor(
                    {
                        id: "address",
                        get: d => (d as MapComponentDescription).location.addressPropertyName,
                        update: (d, newColumnName) => ({
                            location: {
                                ...(d as MapComponentDescription).location,
                                addressPropertyName: newColumnName,
                            },
                        }),
                    },
                    "Address",
                    "Enter address",
                    true,
                    mutatingScreenKind,
                    {
                        columnFilter: {
                            getCandidateColumns: t => t.columns,
                            columnTypeIsAllowed: t => isStringTypeKind(t.kind),
                        },
                        preferredNames: ["address", "location"],
                        columnFirst: true,
                    }
                ),
                zoomLevelPropertyHandler,
                ...this.getBasePropertyDescriptors(),
            ],
        };
    }

    public getActionDescriptors(): readonly ActionPropertyDescriptor[] {
        return [makeDefaultActionDescriptorWithKinds([ActionKind.OpenMap], true)];
    }

    public inflate(
        ib: WireInflationBackend,
        desc: MapComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder, appFacilities } = ib;

        const [addressGetter, addressType] = inflateStringProperty(ib, desc.location.addressPropertyName, false);
        if (addressType === undefined) return undefined;

        const { actions } = getActionsForComponent(this, desc, ib.tables, ib.adc, ib.mutatingScreenKind);
        const actionIsOpenMap = actions.length === 1 && actions[0].kind === ActionKind.OpenMap;

        const zoomLevel = getZoomLevelNumber(zoomLevelPropertyHandler.getEnum(desc));

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const address = addressGetter(hb) ?? "";
            if (address === "") return undefined;

            let onTap: WireAction | undefined;
            if (actionIsOpenMap) {
                const url = appFacilities.mapURL({ kind: MapLocationKind.Address, address });
                if (url !== undefined) {
                    onTap = registerActionRunner(hb, "", [
                        async (ab, handledByFrontend) => {
                            if (!handledByFrontend) {
                                ab.actionCallbacks.openLink(url);
                            }
                            return WireActionResult.nondescriptSuccess();
                        },
                        url,
                    ]);
                }
            }

            const component: WireAppMapComponent = {
                kind: WireComponentKind.AppMap,
                ...spreadComponentID(desc.componentID, forBuilder),
                address,
                zoomLevel,
                onTap,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(desc: MapComponentDescription): MapCollectionComponentDescription | undefined {
        return {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            caption: undefined,
            format: makeEnumProperty(ArrayScreenFormat.PagesSimpleMap),
            propertyName: makeSourceColumnProperty({ kind: SourceColumnKind.DefaultContext, name: [] }),
            allowSearch: makeSwitchProperty(false),
            reverse: makeSwitchProperty(false),
            action: definedMap(getActionProperty(desc.actions), a => makeActionProperty(a)),
            style: makeEnumProperty("standard"),
            mobileView: makeEnumProperty("fullWidth"),
            showNavigationControls: makeSwitchProperty(false),
            enableScrollWheelZoom: makeSwitchProperty(false),
            location: definedMap(getSourceColumnProperty(desc.location.addressPropertyName), sc =>
                makeSourceColumnProperty(sc)
            ),
        };
    }
}
