import { logError } from "@glide/support";
import type { OAuthAuthenticator } from "@glide/auth-controller-core";
import React from "react";
import { PlayerObservability } from "@glide/player-frontend-observability";

interface OAuthListenerProps {
    readonly authenticator: OAuthAuthenticator;
    readonly onLoggedIn: () => void;
    readonly requestExclusiveRender: () => void;
    readonly yieldExclusiveRender: () => void;
}

interface OAuthListenerResult {
    readonly isWaitingForAuth: boolean;
    readonly error: string | undefined;
    readonly clearError: () => void;
}

const signInWithOAuthMetric = PlayerObservability.makePlayerMetric("sign-in-with-oauth", ["status", "reason"]);

export function useOAuthListener(props: OAuthListenerProps): OAuthListenerResult {
    const { authenticator, onLoggedIn, requestExclusiveRender, yieldExclusiveRender } = props;

    const [isWaitingForAuth, setIsWaitingForAuth] = React.useState(false);

    const [error, setError] = React.useState<string | undefined>();

    const clearError = React.useCallback(() => {
        setError(undefined);
    }, []);

    React.useEffect(() => {
        const onMessage = async (message: MessageEvent) => {
            const isMessageValid = authenticator.isMessageValid(message);
            if (!isMessageValid || isWaitingForAuth) {
                return;
            }

            setIsWaitingForAuth(true);
            requestExclusiveRender();

            const stop = signInWithOAuthMetric.start();
            const result = await authenticator.signInFromOAuthMessage(message);

            if (!result.success) {
                setError(result.error);

                setIsWaitingForAuth(false);
                yieldExclusiveRender();

                stop({
                    status: "failed",
                    reason: result.error,
                });
                logError("Could not sign in from OAuth message");
                return;
            }

            stop({
                status: "success",
                reason: "",
            });

            onLoggedIn();
        };

        window.addEventListener("message", onMessage);

        return () => {
            window.removeEventListener("message", onMessage);
        };
    }, [authenticator, isWaitingForAuth, onLoggedIn, requestExclusiveRender, yieldExclusiveRender]);

    return { isWaitingForAuth, error, clearError };
}
