import * as React from "react";
import { ConditionContainerStyle, InnerContainer, CloseContainer } from "./condition-container-style";
import { X } from "@glide/common-core/dist/js/react-plucked";

interface Props extends React.PropsWithChildren {
    canClose?: boolean;
    onClose?: () => void;

    style?: React.CSSProperties;
}

export class ConditionContainer extends React.PureComponent<Props> {
    public render(): React.ReactNode {
        const { canClose, onClose, style } = this.props;

        return (
            <ConditionContainerStyle style={style}>
                <InnerContainer>{this.props.children}</InnerContainer>
                {canClose === true && (
                    <CloseContainer onClick={onClose}>
                        <X size={12} />
                    </CloseContainer>
                )}
            </ConditionContainerStyle>
        );
    }
}
