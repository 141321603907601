import type { ComputationModel } from "@glide/computation-model-types";
import {
    nativeTableRowIDColumnName,
    type Description,
    type SourceColumn,
    type TableColumn,
    type TableGlideType,
    type TableOrColumnsAndColumn,
    SourceColumnKind,
    getTableColumn,
    getTableName,
    isDataSourceColumn,
    makeSourceColumn,
    isBigTableOrExternal,
    type SchemaInspector,
    makeActionNodeOutputSourceColumn,
} from "@glide/type-schema";
import { MutatingScreenKind, PropertyKind } from "@glide/app-description";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import {
    type AppDescriptionContext,
    type ColumnPropertyDescriptorCase,
    type ResolvedSourceColumn,
    type TableViewPropertyDescriptorCase,
    applyColumnFilterSpec,
    resolveSourceColumn,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { definedMap } from "collection-utils";
import { getInputOrOutputTableForProperty } from "./components/descriptor-utils";
import { isQueryableColumn } from "./computed-columns";
import { allowProtectedColumns, filterEditableColumns } from "./description-utils";
import { getColumnsShownForApp } from "./favorites";
import type { PriorStep } from "./prior-step";
import type { StaticActionContext, StaticComputationContext } from "./static-context";

export function isColumnAllowedForFilteringRows(
    forFilteringRows: boolean,
    forSortingRows: boolean,
    // If this is not given, it will not allow queryable computed columns
    schema: SchemaInspector | undefined,
    table: TableGlideType,
    column: TableColumn,
    computationModel: Pick<ComputationModel, "getInfoForColumn"> | undefined,
    gbtComputedColumnsAlpha: boolean,
    gbtDeepLookups: boolean
): boolean {
    if (!forFilteringRows && !forSortingRows) return true;

    const isTableQueryable = isBigTableOrExternal(table);

    if (isTableQueryable) {
        // Queryable external tables have synthetic row ID columns which can't
        // be filtered by.
        if (column.name === nativeTableRowIDColumnName && table.sourceMetadata?.externalSource !== undefined) {
            return false;
        }

        // If the queryable table supports user specific columns, we allow
        // them.  Right now only GBT do this.
        if (isDataSourceColumn(column, true)) {
            return true;
        }

        if (gbtComputedColumnsAlpha) {
            if (
                schema !== undefined &&
                isQueryableColumn(schema, table, column, gbtComputedColumnsAlpha, gbtDeepLookups)
            ) {
                return true;
            }
        }

        return false;
    } else {
        const tableName = getTableName(table);

        // If we don't have a computation model, we allow the column.
        return computationModel?.getInfoForColumn(tableName, column.name)?.fromQuery !== true;
    }
}

// This will return all the columns that are allowed for searching in the table.
// If gbtComputedColumnsAlpha is true, it will also allow queryable computed columns.
export function isColumnAllowedForSearching(
    schema: SchemaInspector | undefined,
    table: TableGlideType,
    column: TableColumn,
    computationModel: Pick<ComputationModel, "getInfoForColumn"> | undefined,
    gbtComputedColumnsAlpha: boolean,
    gbtDeepLookups: boolean
) {
    return isColumnAllowedForFilteringRows(
        true,
        true,
        schema,
        table,
        column,
        computationModel,
        gbtComputedColumnsAlpha,
        gbtDeepLookups
    );
}

export function getAllowedColumnsFromTable(
    descr: ColumnPropertyDescriptorCase | TableViewPropertyDescriptorCase,
    desc: Description | undefined,
    { table, context: ccc }: StaticComputationContext<AppDescriptionContext>,
    mutatingScreenKind: MutatingScreenKind | undefined
): { allowed: readonly TableColumn[]; avoid: TableColumn | undefined } {
    if (table === undefined) return { allowed: [], avoid: undefined };

    const columnsShown = getColumnsShownForApp(ccc);
    const allowProtected = allowProtectedColumns(
        descr.kind === PropertyKind.Column && descr.isEditedInApp,
        mutatingScreenKind
    );
    const forFilteringRows = descr.kind === PropertyKind.Column && descr.forFilteringRows === true;
    const forSortingRows = descr.kind === PropertyKind.Column && descr.forSortingRows === true;
    const computationModel = ccc.builderComputationModel;
    const getCols = applyColumnFilterSpec(descr.columnFilter);
    const columns = getCols(table, desc, ccc).filter(c => {
        if (
            !isColumnAllowedForFilteringRows(
                forFilteringRows,
                forSortingRows,
                ccc,
                table,
                c,
                computationModel,
                isExperimentEnabled("gbtComputedColumnsAlpha", ccc.userFeatures),
                isExperimentEnabled("gbtDeepLookups", ccc.userFeatures)
            )
        ) {
            return false;
        }
        return columnsShown.isColumnShown(table, c, allowProtected);
    });
    // Why do we avoid the row ID column?
    const avoid = definedMap(table.rowIDColumn, n => getTableColumn(table, n));
    const allowed = { allowed: columns, avoid };
    if (descr.kind === PropertyKind.TableView || descr.isEditedInApp !== true) {
        return allowed;
    }
    return {
        allowed: filterEditableColumns(table, allowed.allowed, mutatingScreenKind, ccc),
        avoid: allowed.avoid,
    };
}

export function getAllowedColumnsForProperty(
    descr: ColumnPropertyDescriptorCase | TableViewPropertyDescriptorCase,
    rootDesc: Description,
    desc: Description,
    env: StaticActionContext<AppDescriptionContext>
): { table: TableGlideType | undefined; allowed: readonly TableColumn[]; avoid: TableColumn | undefined } {
    let propertyTable: TableGlideType | undefined;
    if (descr.getIndirectTable !== undefined) {
        const actionNodesInScope = env.priorSteps?.map(s => s.node) ?? [];
        propertyTable = descr.getIndirectTable(env.tables, rootDesc, desc, env.context, actionNodesInScope)?.table;
    }
    if (propertyTable === undefined && env.tables !== undefined) {
        propertyTable = getInputOrOutputTableForProperty(env.tables, descr, env.mutatingScreenKind);
    }
    return {
        ...getAllowedColumnsFromTable(descr, desc, { ...env, table: propertyTable }, env.mutatingScreenKind),
        table: propertyTable,
    };
}

export interface AllowedColumns {
    readonly contextTable: TableGlideType | undefined;
    readonly contextRow: boolean;
    readonly context: readonly TableColumn[];

    readonly userProfileTable: TableGlideType | undefined;
    readonly userProfileRow: boolean;
    readonly userProfile: readonly TableColumn[];

    readonly containingScreenTable?: TableGlideType;
    readonly containingScreenTableLabel?: string;
    readonly containingScreen?: readonly TableColumn[];

    readonly priorStepsOutputs: readonly PriorStep[] | undefined;
}

export function getDefaultSourceColumn(columns: AllowedColumns): SourceColumn | undefined {
    if (columns.context.length > 0) {
        return makeSourceColumn(columns.context[0].name, SourceColumnKind.DefaultContext);
    } else if (columns.userProfile.length > 0) {
        return makeSourceColumn(columns.userProfile[0].name, SourceColumnKind.UserProfile);
    } else if (columns.priorStepsOutputs !== undefined) {
        for (const priorStep of columns.priorStepsOutputs) {
            for (const output of priorStep.outputs) {
                const priorStepKey = priorStep.node.node.key;
                return makeActionNodeOutputSourceColumn(priorStepKey, output.name, output.columnName);
            }
        }
    }
    return undefined;
}

interface AllowedColumnsForProperty extends AllowedColumns {
    readonly contextAvoid: TableColumn | undefined;
    readonly userProfileAvoid: TableColumn | undefined;
}

export function getAllowedColumns(
    pdc: ColumnPropertyDescriptorCase | TableViewPropertyDescriptorCase,
    rootDesc: Description,
    desc: Description,
    env: StaticActionContext<AppDescriptionContext>
): AllowedColumnsForProperty | undefined {
    const { context: ccc, tables, mutatingScreenKind } = env;

    const columnCase = pdc.kind === PropertyKind.Column ? pdc : undefined;

    // We don't allow writing to user profile columns in classic apps
    let allowUserProfileColumns = false;
    // Automations don't allow referring to the user profile
    if (columnCase?.allowUserProfileColumns === true && !env.isAutomation) {
        if (ccc.appKind !== AppKind.App) {
            allowUserProfileColumns = true;
        } else if (columnCase.isEditedInApp !== true && columnCase.isAddedInApp !== true) {
            allowUserProfileColumns = true;
        }
    }
    const allowUserProfileViaTableView = pdc.kind === PropertyKind.TableView && !env.isAutomation;

    const fromContext =
        mutatingScreenKind === MutatingScreenKind.AddScreen &&
        (pdc.kind === PropertyKind.TableView ||
            columnCase?.needsExistingValue === true ||
            columnCase?.isEditedInApp === false)
            ? { table: undefined, allowed: [], avoid: undefined }
            : getAllowedColumnsForProperty(pdc, rootDesc, desc, env);

    let userProfileAllowed: readonly TableColumn[] = [];
    let userProfileAvoid: TableColumn | undefined;
    let userProfileTable: TableGlideType | undefined;
    if (allowUserProfileViaTableView || allowUserProfileColumns) {
        const userProfileTableName = ccc.userProfileTableInfo?.tableName;
        if (userProfileTableName !== undefined) {
            userProfileTable = ccc.findTable(userProfileTableName);
            if (userProfileTable !== undefined) {
                const fromUserProfile = getAllowedColumnsFromTable(
                    pdc,
                    desc,
                    {
                        ...env,
                        table: userProfileTable,
                    },
                    env.mutatingScreenKind
                );
                userProfileAllowed = fromUserProfile.allowed;
                userProfileAvoid = fromUserProfile.avoid;
            }
        }
    }

    const allowContextRow =
        tables !== undefined &&
        ((pdc.kind === PropertyKind.TableView || columnCase?.allowFullRow?.(tables.input, ccc)) ?? false) !== false;
    const allowUserProfileRow =
        userProfileTable !== undefined &&
        (pdc.kind === PropertyKind.TableView || (columnCase?.allowFullRow?.(userProfileTable, ccc) ?? false) !== false);

    // Here we get the allowed columns from prior steps... but what if your property has an indirect table?
    let priorStepsOutputs: PriorStep[] | undefined;
    if (columnCase === undefined || columnCase.disallowPriorSteps !== true) {
        const isReadOnly =
            columnCase === undefined || columnCase.isEditedInApp === false || columnCase.isEditedInApp === undefined;

        priorStepsOutputs = env.priorSteps
            ?.map(ps => ({
                ...ps,
                outputs: ps.outputs.filter(
                    // The prior step outputs include columns in
                    // outputs, if the output is a row.  For example,
                    // Loop nodes produce a row as their output, and we
                    // expand that into "extended" outputs for each
                    // column in that row, which can be writeable.
                    o => (isReadOnly || o.writeable) && pdc.columnFilter.columnTypeIsAllowed(o.type, ccc)
                ),
            }))
            .filter(ps => ps.outputs.length > 0);
    }

    if (
        !pdc.required &&
        fromContext.allowed.length === 0 &&
        userProfileAllowed.length === 0 &&
        (priorStepsOutputs ?? []).length === 0 &&
        !allowContextRow &&
        !allowUserProfileRow
    ) {
        return undefined;
    }

    return {
        contextTable: fromContext.table,
        contextRow: allowContextRow,
        context: fromContext.allowed,
        contextAvoid: fromContext.avoid,
        userProfile: userProfileAllowed,
        userProfileRow: allowUserProfileViaTableView || (allowUserProfileColumns && allowUserProfileRow),
        userProfileAvoid,
        userProfileTable,
        priorStepsOutputs,
    };
}

export interface AllowedAndSelectedColumns extends AllowedColumnsForProperty {
    readonly resolvedSelectedColumn: ResolvedSourceColumn<TableOrColumnsAndColumn> | undefined;
    // This is just `resolvedSelectedColumn?.tableAndColumn?.column`
    readonly selectedColumn: TableColumn | undefined;
}

export function getAllowedAndSelectedColumns(
    columnCase: ColumnPropertyDescriptorCase | TableViewPropertyDescriptorCase,
    selectedSourceColumn: SourceColumn | undefined,
    rootDesc: Description,
    desc: Description,
    env: StaticActionContext<AppDescriptionContext>
): AllowedAndSelectedColumns | undefined {
    const result = getAllowedColumns(columnCase, rootDesc, desc, env);
    if (result === undefined) return undefined;

    let resolvedSelectedColumn: ResolvedSourceColumn<TableOrColumnsAndColumn> | undefined;
    if (selectedSourceColumn !== undefined) {
        resolvedSelectedColumn = resolveSourceColumn(
            env.context,
            selectedSourceColumn,
            result.context,
            undefined,
            env.priorSteps?.map(ps => ps.node)
        );
    }

    return { ...result, resolvedSelectedColumn, selectedColumn: resolvedSelectedColumn?.tableAndColumn?.column };
}
