import styled, { css } from "styled-components";

import { SVG } from "../../utility/svg";
import type {
    ComponentIcons,
    MonotoneIcons,
    StrokeIcons,
    TwotoneIcons,
    ColorIcons,
    GlideIconProps as Props,
} from "@glide/plugins";
import { AppIcon } from "../../icons/named-icons";

/*
    Svg file location:
        app/public/svg/*
    Storybook location: 
        app/src/sharedUI/ui/views/glide-icon/glide-icon-stories.tsx
*/

export function maybeGetHeaderIconColorForMonotoneIcon(icon: string): { fgColor: string; bgColor: string } | undefined {
    switch (icon) {
        case "mt-column-generate-text":
        case "mt-column-choice":
        case "mt-column-text-to-date":
        case "mt-column-boolean":
        case "mt-column-number":
        case "mt-column-audio":
        case "mt-column-image":
        case "mt-column-document":
        case "mt-column-json":
            return {
                fgColor: "var(--gv-icon-base)",
                bgColor: "var(--gv-pink500)",
            };

        case "mt-header-reference":
        case "mt-header-if-then-else":
        case "mt-header-lookup":
        case "mt-header-single-value":
        case "mt-header-text-template":
        case "mt-header-math":
        case "mt-header-rollup":
        case "mt-header-join-strings":
        case "mt-header-split-string":
        case "mt-header-make-array":
        case "mt-header-geo-distance":
        case "mt-header-generate-image":
        case "mt-header-globe":
        case "mt-header-code":
        case "mt-header-filter-sort-limit":
            return {
                fgColor: "var(--gv-icon-base)",
                bgColor: "var(--gv-aqua500)",
            };
        default:
            return undefined;
    }
}

export function isStrokeIcon(icon: string | undefined): icon is StrokeIcons {
    return icon !== undefined && icon.startsWith("st-");
}

export function isTwotoneIcon(icon: string | undefined): icon is TwotoneIcons {
    return icon !== undefined && icon.startsWith("tt-");
}

export function isMonotoneIcon(icon: string | undefined): icon is MonotoneIcons {
    return icon !== undefined && icon.startsWith("mt-");
}

export function isComponentIcon(icon: string | undefined): icon is ComponentIcons {
    return icon !== undefined && icon.startsWith("co-");
}

export function isColorIcon(icon: string | undefined): icon is ColorIcons {
    return icon !== undefined && icon.startsWith("clr-");
}

export function backCompatIcon(icon: string, size: number) {
    if (isStrokeIcon(icon)) {
        return <GlideIcon kind="stroke" icon={icon} iconSize={size} />;
    } else if (isTwotoneIcon(icon)) {
        return <GlideIcon kind="twotone" icon={icon} iconSize={size} />;
    } else if (isMonotoneIcon(icon)) {
        return <GlideIcon kind="monotone" icon={icon} iconSize={size} />;
    } else if (isComponentIcon(icon)) {
        return <GlideIcon kind="component" icon={icon} iconSize={size} />;
    } else if (isColorIcon(icon)) {
        return <GlideIcon kind="color" icon={icon} iconSize={size} />;
    } else {
        return <AppIcon icon={icon} size={size} />;
    }
}

const GlideIconStyle = styled.div<Props>`
    width: ${p => p.iconSize ?? 24}px;
    height: ${p => p.iconSize ?? 24}px;

    position: relative;
    display: flex;

    & svg {
        ${p =>
            p.kind === "twotone"
                ? css`
                      ${p.primaryColor === undefined ? "" : `--primary-color: ${p.primaryColor}`};
                      ${p.accentColor === undefined ? "" : `--accent-color: ${p.accentColor}`};
                  `
                : ""};

        ${p =>
            p.kind === "monotone"
                ? css`
                      ${p.fgColor === undefined ? "" : `--fg-color: ${p.fgColor}`};
                      ${p.bgColor === undefined ? "" : `--bg-color: ${p.bgColor}`};
                  `
                : ""};

        ${p =>
            p.kind === "stroke"
                ? css`
                      ${p.strokeColor === undefined
                          ? "--stroke-color: currentColor"
                          : `--stroke-color: ${p.strokeColor}`};
                      ${p.strokeFgColor === undefined
                          ? "--stroke-fg-color: currentColor"
                          : `--stroke-fg-color: ${p.strokeFgColor}`};
                      ${p.strokeWidth !== undefined && `--stroke-width: ${p.strokeWidth}`};
                  `
                : ""};

        ${p =>
            p.kind === "component"
                ? css`
                      ${p.fgColor === undefined ? "" : `--fg-color: ${p.fgColor}`};
                      ${p.bgColor === undefined ? "" : `--bg-color: ${p.bgColor}`};
                  `
                : ""};

        ${p =>
            p.spin === true &&
            css`
                @keyframes spin {
                    100% {
                        -webkit-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }
                animation: spin 0.7s linear infinite;
            `};

        stroke-width: 1.5;
        fill: currentColor;
        stroke: ${p => (p.hideStroke ? "none" : "currentColor")};
        border-radius: ${p => (p.hideStroke && p.borderRadiusWithoutStroke !== true ? "10px" : 0)};

        width: 100%;
        height: 100%;

        transform: rotate(${p => p.rotateDeg ?? 0}deg);

        transition: transform 0.15s ease;
    }
`;

// FIXME: Eventually make this export just GlideIcon
export const GlideIcon: React.VFC<Props> = p => {
    const { icon, kind } = p;

    return (
        <GlideIconStyle {...p}>
            <SVG href={`/svg/${kind}/${icon}.svg`} />
        </GlideIconStyle>
    );
};
