import type { Organization, OrganizationFolder } from "@glide/common-core/dist/js/Database";
import { removeIntegration } from "@glide/common-core/dist/js/Database";
import type { Integration } from "@glide/common-core/dist/js/integration-types";
import { logError } from "@glide/support";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { castDraft } from "immer";
import orderBy from "lodash/orderBy";
import { defaultRootState } from "../store/default-root-state";

interface OrgAppPayload {
    readonly orgID: string;
    readonly appID: string;
}

interface OrgFoldersPayload {
    readonly orgID: string;
    readonly folders: OrganizationFolder[];
}

const slice = createSlice({
    name: "organizations",
    initialState: defaultRootState.organizations,
    reducers: {
        setOrganization: (state, action: PayloadAction<Organization>) => {
            const org = castDraft(action.payload);
            const idx = state.findIndex(o => o.id === org.id);
            if (idx >= 0) {
                state[idx] = { ...org, folders: org.folders ?? state[idx].folders ?? [] };
            } else {
                state.push(org);
            }
        },
        setOrganizationFolders: (state, action: PayloadAction<OrgFoldersPayload>) => {
            const { orgID, folders } = action.payload;
            const idx = state.findIndex(o => o.id === orgID);
            if (idx < 0) {
                logError("Organization not loaded", orgID);
                return;
            }
            state[idx].folders = folders;
        },
        addApp: (state, action: PayloadAction<OrgAppPayload>) => {
            const { orgID, appID } = action.payload;
            const idx = state.findIndex(o => o.id === orgID);
            if (idx < 0) {
                logError("Organization not loaded", orgID);
                return;
            }
            let org = state[idx];
            if (org.appIDs.indexOf(appID) >= 0) return;
            org = { ...org, appIDs: [...org.appIDs, appID] };
            state[idx] = org;
        },
        removeAppFromOrganization: (state, action: PayloadAction<OrgAppPayload>) => {
            const { orgID, appID } = action.payload;
            const idx = state.findIndex(o => o.id === orgID);
            if (idx >= 0) {
                let org = state[idx];
                org = { ...org, appIDs: org.appIDs.filter(id => id !== appID) };
                state[idx] = org;
            }
        },
        sortOrganizationsById: (state, action: PayloadAction<readonly string[]>) => {
            return orderBy(state, i => action.payload.indexOf(i.id));
        },
        unloadOrganizations: () => {
            return [];
        },
        removeOrganization: (state, action: PayloadAction<string>) => {
            const orgID = action.payload;
            const idx = state.findIndex(o => o.id === orgID);
            if (idx >= 0) {
                state.splice(idx, 1);
            }
        },
        removeIntegrationFromOrganization: (
            state,
            action: PayloadAction<{ orgID: string; integration: Integration }>
        ) => {
            const { orgID, integration } = action.payload;
            const idx = state.findIndex(o => o.id === orgID);
            if (idx >= 0) {
                const orgData = state[idx];
                state[idx] = {
                    ...orgData,
                    integrations: castDraft(removeIntegration(orgData.integrations, integration)),
                };
            }
        },
    },
});

export const orgActions = slice.reducer;
export const {
    addApp,
    removeAppFromOrganization,
    removeIntegrationFromOrganization,
    removeOrganization,
    setOrganization,
    setOrganizationFolders,
    unloadOrganizations,
    sortOrganizationsById,
} = slice.actions;

const loadedSlice = createSlice({
    name: "organizationsLoaded",
    initialState: defaultRootState.organizationsLoaded,
    reducers: {
        setOrganizationsLoaded: () => {
            return true;
        },
    },
    extraReducers: {
        [unloadOrganizations.type]: () => {
            return false;
        },
    },
});

export const organizationsLoadedReducer = loadedSlice.reducer;
export const { setOrganizationsLoaded } = loadedSlice.actions;
