import { type PrimitiveValue, type Row, isPrimitiveValue, isLoadingValue } from "@glide/computation-model-types";
import { asMaybeNumber, getRowColumn, isArrayValue } from "@glide/common-core/dist/js/computation-model/data";
import { assert } from "@glideapps/ts-necessities";

// Aggregate rows are sent as responses to aggregate queries, and don't
// conform to any table schema.  We put them in `Row`s and `Table`s because
// that's by far the easiest way to pass them around in the component model,
// but they don't participate in the computation model, so they'll never
// contain loading values, for example.  These functions help with dealing
// with them.

export function decomposeAggregateRow(row: Row): { group: PrimitiveValue; count: number } {
    const groupArray = getRowColumn(row, "group");
    assert(!isLoadingValue(groupArray));
    assert(isArrayValue(groupArray) && groupArray.length === 1);
    const [group] = groupArray;
    assert(isPrimitiveValue(group));

    const maybeCount = getRowColumn(row, "count");
    assert(!isLoadingValue(maybeCount));
    // The only aggregate where we might not produce a count is
    // `unique-array-elements`, and in that case we're not using it anyway.
    // We return a default value here to make it easier for consumers.
    const count = asMaybeNumber(maybeCount) ?? 1;

    return { group, count };
}

export function getAggregateFromRow<T>(
    row: Row,
    aggregateName: string,
    convert: (v: PrimitiveValue) => T
): T | undefined {
    const v = getRowColumn(row, aggregateName);
    assert(!isLoadingValue(v));
    if (!isPrimitiveValue(v)) return undefined;
    return convert(v);
}
