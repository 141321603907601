import styled, { css } from "styled-components";
import { AppIcon } from "../icons/named-icons";

export const RoundedRect = styled.div`
    border-radius: 12px;
    border: 1px solid ${p => p.theme.borderColorLight};
    overflow: hidden;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 2fr minmax(300px, 1fr);
    column-gap: 24px;
    margin-top: 16px;
    color: ${p => p.theme.textBase};
    font-size: 13px;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
`;

export const Card = styled.div`
    background-color: ${p => p.theme.n50A};
    border-radius: 12px;
    border: 1px solid ${p => p.theme.borderPale};
    margin-bottom: 24px;

    .card-header,
    .card-content {
        padding: 20px;
    }

    .card-content {
        padding-bottom: 20px;
    }

    .card-header + .card-content {
        padding-top: 0;
    }

    .card-title {
        color: ${p => p.theme.textDark};
        font-size: 19px;
        font-weight: 800;
    }

    p {
        margin-bottom: 0.5rem;
        color: ${p => p.theme.textPale};
    }

    .card-title + p {
        margin-top: 4px;
    }

    .card-grid {
        margin: -8px 0 12px 0;
    }
`;

export const CardSecondary = styled(Card)`
    background-color: ${p => p.theme.bgMiddle};
`;

export const LoadingIndicator = styled.div<{ color?: string }>`
    &,
    :before,
    :after {
        background: ${p => p.color ?? p.theme.fgColorMedium};
        animation: load1 1s infinite ease-in-out;
        width: 11px;
        height: 44px;
    }
    & {
        position: absolute;
        left: calc(50% - 5px);
        top: calc(50% - 22px);

        animation-delay: -0.16s;
    }
    &:before,
    &:after {
        position: absolute;
        top: 0;
        content: "";
    }
    &:before {
        left: -16px;
        animation-delay: -0.32s;
    }
    &:after {
        left: 16px;
    }
    @keyframes load1 {
        0%,
        80%,
        100% {
            box-shadow: 0 0 ${p => p.color ?? p.theme.fgColorMedium};
            height: 44px;
        }
        40% {
            box-shadow: 0 -14px ${p => p.color ?? p.theme.fgColorMedium};
            height: 58px;
        }
    }
`;

const CloseButtonStyle = styled.button<{ inline: boolean; variant: "big" | "small" }>`
    ${p =>
        !p.inline &&
        css`
            position: absolute;
            right: ${p.variant === "big" ? 20 : 8}px;
            top: ${p.variant === "big" ? 20 : 8}px;
        `}
    width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100px;
    cursor: pointer;
    color: ${p => p.theme.fgColorMedium};

    :hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    transition: ${p => p.theme.hoverTransition};
`;

export function CloseButton(props: { onClose: () => void; inline?: boolean; variant?: "small" | "big" }) {
    const variant = props.variant ?? "big";
    return (
        <CloseButtonStyle variant={variant} inline={props.inline ?? false} onClick={props.onClose}>
            <AppIcon icon="01-33-close" size={variant === "big" ? 18 : 14} />
        </CloseButtonStyle>
    );
}
