import { AppIcon } from "@glide/common";
import { Mood } from "@glide/component-utils";
import type { WireHintComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isSmallScreen, useRootResponsiveSizeClass, css } from "@glide/common-components";
import { isDefined } from "@glide/support";
import { UIBackgroundStyle, UIButtonAppearance, isAccentBackgroundStyle, isDarkBackgroundStyle } from "@glide/wire";
import chroma from "chroma-js";
import React from "react";
import tw from "twin.macro";

import { makeSingleActionSpreadProps } from "../../wire-lib";
import { WireButton } from "../wire-button/wire-button";
import { SectionStyleProvider, useSectionStyle } from "../wire-container/wire-container";
import type { WireRenderer } from "../wire-renderer";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

export const WireHint: WireRenderer<WireHintComponent> = React.memo(props => {
    const { backend, mood, action, actionTitle, description, icon, actionIcon } = props;

    const theme = useWireAppTheme();

    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    const parentSectionStyle = useSectionStyle();
    const sectionStyle =
        mood === Mood.Default || mood === Mood.Neutral ? UIBackgroundStyle.None : UIBackgroundStyle.Highlight;
    const transparentAccent = isAccentBackgroundStyle(parentSectionStyle)
        ? "rgba(255, 255, 255, 0.2)"
        : chroma(theme.primaryAccentColor).alpha(0.2).css();

    return (
        <SectionStyleProvider value={sectionStyle}>
            <div
                className={mood}
                css={css`
                    background-color: ${transparentAccent};
                    border-color: ${transparentAccent};

                    &.${Mood.Neutral} {
                        ${!isAccentBackgroundStyle(parentSectionStyle) && tw`bg-n200A border-w10A`}
                        ${isDarkBackgroundStyle(parentSectionStyle) && tw`border`}
                    }

                    &.${Mood.Warning} {
                        ${tw`bg-y100 border-y300 text-y500`}
                    }

                    &.${Mood.Danger} {
                        ${tw`bg-r100 border-r300 text-r500`}
                    }

                    &.${Mood.Success} {
                        ${tw`bg-g100 border-g300 text-g500`}
                    }
                `}
                tw="relative text-base text-text-contextual-dark px-4 py-3 flex items-stretch rounded-xl
                    whitespace-pre-wrap
                    gp-md:(px-4 py-3)">
                <div tw="flex-1 flex items-start">
                    {isDefined(icon) && (
                        <div
                            tw="flex self-stretch items-center shrink-0 mr-2 [max-height:30px]
                                gp-md:[max-height:36px]">
                            <AppIcon icon={icon} size={24} />
                        </div>
                    )}
                    <div tw="flex flex-shrink items-center self-stretch w-full [word-break: break-word] break-words leading-normal overflow-hidden">
                        <p>{description}</p>
                    </div>
                </div>
                {isDefined(action) && (
                    <div
                        tw="flex items-center ml-2 [max-height:30px]
                            gp-md:[max-height:36px]">
                        <WireButton
                            data-testid="wh-button"
                            {...makeSingleActionSpreadProps(action, backend)}
                            iconName={actionIcon}
                            size={isMobile ? "mini" : undefined}
                            appearance={UIButtonAppearance.Bordered}>
                            {actionTitle}
                        </WireButton>
                    </div>
                )}
            </div>
        </SectionStyleProvider>
    );
});
