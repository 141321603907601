// NOTE: This is part of the lower level of the New Computation Model, and
// should be kept isolated from non-trivial Glide dependencies.  In
// particular, it shouldn't have to know anything about Glide table/column
// types/schemas.

import { assert, assertNever, mapFilterUndefined } from "@glideapps/ts-necessities";
import { setIntersect } from "collection-utils";

import { isArray } from "@glide/support";
import { dirtyFromTheStart } from "./globals";
import {
    type Dirt,
    type DirtyState,
    type Handler,
    type Namespace,
    type KeyPath,
    type Path,
    isKeyPath,
    isRootPath,
} from "@glide/computation-model-types";

// This keeps track of the state of dirtiness of a table.  Dirtiness is
// tracked along two dimensions: rows and columns.  The dirty cells are all
// the ones in the intersection of the dirty rows and dirty columns.  For
// example, if the dirty columns are A and B, and the dirty rows are
// 3 and 5, then the dirty cells are A3, B3, A5, B5.
class UnionDirtyState implements DirtyState {
    // `false` means not dirty, `true` means all dirty
    private _rowIDs: Set<string> | boolean;
    private _columns: Set<string> | boolean;
    private _hasClone: boolean = false;

    constructor(
        // `true` means accept all dirt
        private readonly _columnFilter: ReadonlySet<string> | true,
        rowIDs: Set<string> | boolean = dirtyFromTheStart,
        columns: Set<string> | boolean = dirtyFromTheStart
    ) {
        if (_columnFilter !== true) {
            assert(_columnFilter.size > 0);
        }
        this._rowIDs = rowIDs;
        this._columns = columns;
    }

    // The caller must consume the returned set right away, because it might
    // change when more dirt is pushed.
    public get dirtyRowIDs(): ReadonlySet<string> | boolean {
        return this._rowIDs;
    }

    // The caller must consume the returned set right away, because it might
    // change when more dirt is pushed.
    public get dirtyColumns(): ReadonlySet<string> | boolean {
        return this._columns;
    }

    public add(d: Dirt): boolean {
        assert(!this._hasClone);

        let didAdd = false;

        let { columns } = d;
        if (this._columnFilter !== true) {
            if (columns === true) {
                columns = this._columnFilter;
            } else {
                columns = setIntersect(columns, this._columnFilter);
                if (columns.size === 0) return false;
            }
        }

        if (d.kind === "row") {
            if (this._rowIDs === false) {
                this._rowIDs = new Set([d.rowID]);
                didAdd = true;
            } else if (this._rowIDs !== true) {
                if (!this._rowIDs.has(d.rowID)) {
                    this._rowIDs.add(d.rowID);
                    didAdd = true;
                }
            }
        } else if (d.kind === "table") {
            if (this._rowIDs !== true) {
                this._rowIDs = true;
                didAdd = true;
            }
        } else {
            return assertNever(d);
        }

        if (columns === true) {
            if (this._columns !== true) {
                this._columns = true;
                didAdd = true;
            }
        } else {
            if (this._columns === false) {
                this._columns = new Set(columns);
                didAdd = true;
            } else if (this._columns !== true) {
                for (const c of columns) {
                    if (!this._columns.has(c)) {
                        this._columns.add(c);
                        didAdd = true;
                    }
                }
            }
        }

        return didAdd;
    }

    public addRowAndColumn(rowID: string, column: string | true): void {
        assert(!this._hasClone);

        if (column !== true && this._columnFilter !== true) {
            if (!this._columnFilter.has(column)) return;
        }

        if (this._rowIDs === false) {
            this._rowIDs = new Set([rowID]);
        } else if (this._rowIDs !== true) {
            this._rowIDs.add(rowID);
        }
        if (column === true) {
            if (this._columnFilter === true) {
                this._columns = true;
            } else {
                assert(this._columns !== true);
                this._columns = new Set(this._columnFilter);
            }
        } else if (this._columns === false) {
            this._columns = new Set([column]);
        } else if (this._columns !== true) {
            this._columns.add(column);
        }
    }

    public get isDirty(): boolean {
        const isDirty = this._rowIDs !== false;
        assert(isDirty === (this._columns !== false));
        return isDirty;
    }

    public setAllDirty(): boolean {
        this._hasClone = false;

        if (this._rowIDs === true && this._columns === true) return false;
        this._rowIDs = true;
        this._columns = true;
        return true;
    }

    public includesRow(rowID: string): boolean {
        if (this._rowIDs === false) return false;
        return this._rowIDs === true || this._rowIDs.has(rowID);
    }

    public includesColumn(column: string): boolean {
        if (this._columns === false) return false;
        return this._columns === true || this._columns.has(column);
    }

    public includesPaths(paths: readonly Path[]): boolean {
        return paths.some(p => {
            if (isRootPath(p)) return false;
            assert(isKeyPath(p));
            return this.includesColumn(p.key);
        });
    }

    public clear(): void {
        this._hasClone = false;

        this._rowIDs = false;
        this._columns = false;
    }

    public pushAndClear(ns: Namespace, handler: Handler): void {
        if (this._rowIDs === false || this._columns === false) {
            assert(this._rowIDs === false && this._columns === false);
            return;
        }

        if (this._rowIDs === true) {
            ns.pushDirt(handler, { kind: "table", columns: this._columns });
        } else {
            // FIXME: Combine this into a single dirt
            for (const rowID of this._rowIDs) {
                ns.pushDirt(handler, { kind: "row", rowID, columns: this._columns });
            }
        }

        this.clear();
    }

    public clone(): DirtyState {
        return new UnionDirtyState(this._columnFilter, this._rowIDs, this._columns);
    }
}

class NeverDirtyState implements DirtyState {
    public readonly dirtyRowIDs: ReadonlySet<string> | boolean = false;
    public readonly dirtyColumns: ReadonlySet<string> | boolean = false;
    public readonly isDirty: boolean = false;

    public add(): boolean {
        return false;
    }

    public addRowAndColumn(): void {
        return;
    }

    public setAllDirty(): boolean {
        return false;
    }

    public includesRow(): boolean {
        return false;
    }

    public includesColumn(): boolean {
        return false;
    }

    public includesPaths(): boolean {
        return false;
    }

    public clear(): void {
        return;
    }

    public pushAndClear(): void {
        return;
    }

    public clone(): DirtyState {
        return this;
    }
}

export function makeDirtyState(
    columnFilter: true | KeyPath | readonly Path[],
    forceDirtyState: boolean = false
): DirtyState {
    let cleanColumnFilter: ReadonlySet<string> | true;

    if (columnFilter === true) {
        cleanColumnFilter = true;
    } else {
        let columnNames: string[];
        if (isArray(columnFilter)) {
            columnNames = mapFilterUndefined(columnFilter, p => {
                if (isRootPath(p)) return undefined;
                assert(isKeyPath(p) && p.rest === undefined);
                return p.key;
            });
        } else {
            assert(columnFilter.rest === undefined);
            columnNames = [columnFilter.key];
        }
        cleanColumnFilter = new Set(columnNames);
    }

    if (cleanColumnFilter === true || cleanColumnFilter.size > 0) {
        return new UnionDirtyState(cleanColumnFilter);
    } else {
        if (forceDirtyState) {
            return new UnionDirtyState(new Set(["$rowID"]));
        } else {
            return new NeverDirtyState();
        }
    }
}
