import { browserMightBeOniOS, browserOnPhone, iOSVersion } from "@glide/common";
import { defined, hasOwnProperty } from "@glideapps/ts-necessities";
import { truthify } from "@glide/support";
import MobileDetect from "mobile-detect";

import type { AppFeatures, NonUserAppFeatures } from "@glide/app-description";
import { isCustomDomain } from "../customDomains";
import type { EminenceFlags } from "@glide/billing-types";
import { DeviceFormFactor } from "../render/form-factor";
import { isPlayer, isPlayerFullScreen } from "../routes";
import { standalone } from "./device";

const expectedWindowState = "Glide_Marker";

export function replaceLocation(url: string | null | undefined, titleOverride?: string) {
    window.history.replaceState(expectedWindowState, titleOverride === undefined ? document.title : titleOverride, url);
}

export function monitorSafariStateHack() {
    if (
        browserMightBeOniOS &&
        browserOnPhone &&
        // As of iOS 14, this hack is neither necessary, nor
        // is it benign when not necessary. In iOS 14, this just
        // immediately dumps you back to wherever you started.
        // In Safari, this looks fine, but in alt browsers like
        // Chrome or Firefox this renders your app unusable.
        //
        // In fact, it seems that this bug was fixed as of iOS 13.6.1,
        // which means this is a bug on anything newer than iOS 13.6.
        iOSVersion[0] < 14 &&
        !(iOSVersion[0] === 13 && (iOSVersion[1] ?? 0) >= 7) &&
        !(iOSVersion[0] === 13 && (iOSVersion[1] ?? 0) === 6 && iOSVersion[2] !== undefined) &&
        (isCustomDomain(window.location.hostname) || window.location.href.match(/\/play\//) !== null)
    ) {
        replaceLocation(undefined, "Glide");

        setInterval(() => {
            if (window.history.state !== expectedWindowState) {
                window.history.back();
            }
        }, 200);
    }
}

const bigEnoughForTablet = function () {
    const md = new MobileDetect(window.navigator.userAgent);

    const thresholdSize = 600;
    const windowDecorationSize = 40;

    //android keyboard open/closed can dramatically affect the innerHeight so we use outer instead
    if (md.is("AndroidOS")) {
        return Math.min(window.outerHeight - windowDecorationSize, window.innerWidth) >= thresholdSize;
    }

    return Math.min(window.innerHeight, window.innerWidth) >= thresholdSize;
};

export function getRenderMode(
    f: NonUserAppFeatures | undefined,
    eminenceFlags: EminenceFlags
): "frame" | "tablet" | "phone" {
    // Never in the builder
    if (!isPlayer()) return "frame";

    if (isPlayerFullScreen() || standalone || f?.defaultTabletMode === true) {
        return bigEnoughForTablet() && f !== undefined && eminenceFlags.tabletMode ? "tablet" : "phone";
    }

    if (browserOnPhone || window.innerWidth < 600) return "phone";

    return "frame";
}

export function needsFrame(f: AppFeatures | undefined) {
    if (
        !browserOnPhone &&
        window.innerWidth >= 600 &&
        !isPlayerFullScreen() &&
        !standalone &&
        f?.defaultTabletMode !== true
    )
        return true;
    return !isPlayer();
}

export function getDeviceFormFactor(f: AppFeatures | undefined, eminenceFlags: EminenceFlags): DeviceFormFactor {
    if (isPlayerFullScreen() || standalone || f?.defaultTabletMode === true) {
        return bigEnoughForTablet() && f !== undefined && eminenceFlags.tabletMode
            ? DeviceFormFactor.Tablet
            : DeviceFormFactor.Phone;
    }

    return DeviceFormFactor.Phone;
}

/**
 * Append some script source code to the script element, possibly executing a `mutate` fn over the latter.
 *
 * Also see:
 * - https://developer.mozilla.org/en-US/docs/Web/HTML/Element/script
 *
 * @param src - The script source code.
 * @param mutate - An optional function that mutates the script element before it is appended to the head.
 * @param callback - An optional callback that is called when the script is loaded.
 */
export function loadPageJavascript(src: string, mutate?: (e: HTMLScriptElement) => void, callback?: () => void): void {
    const scriptElem = document.createElement("script");
    scriptElem.async = true;
    scriptElem.onload = callback ?? null;
    scriptElem.src = src;
    if (mutate !== undefined) mutate(scriptElem);
    defined(document.getElementsByTagName("head")[0]).appendChild(scriptElem);
}

export function getQueryStrings(): URLSearchParams {
    return new URL(window.location.href).searchParams;
}

export function removeQueryStrings(first: string, ...rest: string[]): void {
    const url = new URL(window.location.href);
    [first, ...rest].forEach(param => url.searchParams.delete(param));
    replaceLocation(url.toString());
}

export function isDocumentHidden(skipLocalhost: boolean = true): boolean {
    if (skipLocalhost) {
        if (
            window.location.hostname === "localhost" ||
            window.location.hostname === "builder.local.internal.glideapps.com"
        ) {
            return false;
        }
    }
    return document.hidden ?? (hasOwnProperty(document, "webkitHidden") && truthify(document.webkitHidden));
}

const visibilityEventName = (function () {
    try {
        return document.hidden !== undefined
            ? "visibilitychange"
            : hasOwnProperty(document, "webkitHidden")
            ? "webkitvisibilitychange"
            : undefined;
    } catch {
        return undefined;
    }
})();

export function onVisibilityChange(callback: () => void) {
    if (visibilityEventName === undefined) return;

    document.addEventListener(visibilityEventName, callback);
}

export function removeOnVisibilityChangeHandler(callback: () => void) {
    if (visibilityEventName === undefined) return;

    document.removeEventListener(visibilityEventName, callback);
}

export async function waitForDocumentVisibility(): Promise<void> {
    return new Promise<void>(resolve => {
        if (!isDocumentHidden()) return resolve();

        let resolved = false;
        const handleUnhidden = () => {
            if (resolved) return;
            if (!isDocumentHidden()) {
                resolved = true;
                removeOnVisibilityChangeHandler(handleUnhidden);
                if (visibilityPollInterval !== undefined) {
                    clearInterval(visibilityPollInterval);
                }
                return resolve();
            }
        };

        onVisibilityChange(handleUnhidden);
        // We have to poll the document visibility here because of
        // recurrent bugs in the visibility event handler in certain
        // browsers. Chrome Android has historically been the worst
        // about this.
        const visibilityPollInterval = setInterval(handleUnhidden, 500);
    });
}
