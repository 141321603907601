import { convertGlideDurationToJS } from "@glide/data-types";

const minutesFactor = 60;
const hoursFactor = 60 * minutesFactor;
// const daysFactor = hoursFactor * 24;

function formatPart(n: number, twoDigits: boolean): string {
    const s = n.toString();
    if (twoDigits && s.length < 2) {
        return "0" + s;
    } else {
        return s;
    }
}

export function formatDuration(glideDuration: number): string | undefined {
    const totalSeconds = Math.round(convertGlideDurationToJS(glideDuration) / 1000);
    const isNegative = totalSeconds < 0;
    // all variables below should have integer values, unless the duration is
    // huge

    // const days = Math.floor(totalSeconds / daysFactor);
    // const withoutDays = totalSeconds - days * daysFactor;
    const withoutDays = Math.abs(totalSeconds);
    const hours = Math.floor(withoutDays / hoursFactor);
    const withoutHours = withoutDays - hours * hoursFactor;
    const minutes = Math.floor(withoutHours / minutesFactor);
    const withoutMinutes = withoutHours - minutes * minutesFactor;
    const seconds = Math.min(59, Math.floor(withoutMinutes));

    const parts: string[] = [];
    if (hours > 0) {
        parts.push(formatPart(hours, false));
    }
    parts.push(formatPart(minutes, parts.length > 0));
    parts.push(formatPart(seconds, true));

    let s = parts.join(":");
    if (isNegative) {
        s = "-" + s;
    }
    return s;
}
