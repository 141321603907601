import classNames from "classnames";
import React, { useCallback } from "react";
import { useBuilderTheme } from "../../hooks/use-builder-theme";

import type { SwitchToggleSize } from "./switch-toggle-styles";
import { SwitchToggleContainer } from "./switch-toggle-styles";

interface Props extends React.PropsWithChildren {
    readonly clickable?: boolean;
    readonly toggled: boolean;
    readonly size: SwitchToggleSize;
    readonly className?: string;
    readonly onChange?: (checked: boolean) => void;
    readonly acceptColorOverride?: string;
}

const SwitchToggle = (p: Props) => {
    const { toggled, onChange, size, clickable, className, acceptColorOverride } = p;

    const theme = useBuilderTheme();

    const onToggled = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            if (clickable === false) return;
            onChange?.(!toggled);
        },
        [onChange, toggled, clickable]
    );

    const c = classNames(className, toggled && "enabled", !(clickable ?? true) && "unclickable");

    return (
        <SwitchToggleContainer
            className={c}
            data-test={"switch-tgl"}
            onClick={onToggled}
            color={acceptColorOverride ?? theme.acceptColor}
            size={size}>
            <div className="check">
                <div className="thumb" />
            </div>
        </SwitchToggleContainer>
    );
};

export const LabeledSwitchToggle = (p: Props) => {
    const { toggled, onChange, size, clickable, className } = p;

    const theme = useBuilderTheme();

    const onToggled = useCallback(
        (e: React.ChangeEvent) => {
            e.stopPropagation();
            if (clickable === false) return;
            onChange?.(!toggled);
        },
        [onChange, toggled, clickable]
    );

    const c = classNames(className, toggled && "enabled", !(clickable ?? true) && "unclickable");

    return (
        <SwitchToggleContainer as="label" className={c} data-test={"switch-tgl"} color={theme.acceptColor} size={size}>
            <div className="check">
                <div className="thumb" />
            </div>
            <input tw="absolute left-0 top-0 w-0 h-0" type="checkbox" checked={toggled} onChange={onToggled} />
        </SwitchToggleContainer>
    );
};

export default SwitchToggle;
