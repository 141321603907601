import { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import type { PluginTier, PluginTierList } from "@glide/plugins";
import { assertNever } from "@glideapps/ts-necessities";
import type { EminenceFlags } from "@glide/billing-types";
import { BillingPlan } from "@glide/billing-types";

export const getRequiredPlan = (pluginTiers: PluginTierList | undefined, appTier: PluginTier): PlanKind | undefined => {
    if (pluginTiers === undefined) {
        return undefined;
    }
    const planKind = tierToPlanKind(pluginTiers[0]);
    return pluginTiers.includes(appTier) ? undefined : planKind;
};

export function hasRequiredEntitlementsForPlugin(
    pluginId: string,
    pluginTiers: PluginTierList | undefined,
    eminenceFlags: EminenceFlags
): boolean {
    // If the plugin tiers is undefined, then we don't need to check for plugin tiers
    // we just return true.
    if (pluginTiers === undefined) {
        return true;
    }

    // Check if the plugin is in deny list for entitlements.
    // If it is, then we short circuit and return false.
    if (eminenceFlags.pluginDenyList?.includes(pluginId)) {
        return false;
    }

    // Check if the plugin is in allow list for entitlements.
    // If it is, return true and short circuit.
    if (eminenceFlags.pluginAllowList?.includes(pluginId)) {
        return true;
    }

    return pluginTiers.includes(eminenceFlags.pluginTier);
}

export function hasRequiredPlan(requiredTiers: PluginTierList | undefined, tier: PluginTier): boolean {
    if (requiredTiers === undefined) return false;
    return requiredTiers.includes(tier);
}

export function tierToPlanKind(tier: PluginTier): PlanKind {
    switch (tier) {
        case "free":
            return PlanKind.Free;
        case "starter":
            return PlanKind.V3Starter;
        case "education":
            return PlanKind.V3NonProfit;
        case "pro":
            return PlanKind.V3Pro;
        case "business":
            return PlanKind.V3Business;
        case "enterprise":
            return PlanKind.Unlimited;
        default:
            assertNever(tier);
    }
}

export function planKindToBillingPlan(planKind: PlanKind): BillingPlan | undefined {
    switch (planKind) {
        case PlanKind.V3Starter:
            return BillingPlan.Starter;
        case PlanKind.V3Pro:
            return BillingPlan.Pro;
        case PlanKind.V3Business:
            return BillingPlan.Business;
        case PlanKind.Unlimited:
            return BillingPlan.Enterprise;
        case PlanKind.TestPro:
            return BillingPlan.Pro;
        case PlanKind.V3EnterpriseBaseNoUP:
            return BillingPlan.Enterprise;
        case PlanKind.Free:
        case PlanKind.V3NonProfit:
            return undefined;
        default:
            assertNever(planKind);
    }
}

export function tierToBillingPlan(tier: PluginTier): BillingPlan | undefined {
    return planKindToBillingPlan(tierToPlanKind(tier));
}
