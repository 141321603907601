import isString from "lodash/isString";

import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { getAnyLocalizedString } from "@glide/localization";
import type { AppFeatures, IconImage } from "@glide/app-description";
import type { Loading } from "@glide/common-core/dist/js/components/types";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

import { GreetingStyles } from "../custom-sign-in/greeting-styles";
import { MadeWithGlide } from "../made-with-glide";
import { SignInBranding } from "../custom-sign-in/sign-in-branding";
import type { PagesLoginSource } from "@glide/auth-controller-core";
import { SignUpFooter } from "./sign-up-footer";
import { SignInPrimaryButton, SignInSecondaryButton } from "../sign-in-v2/sign-in-buttons";

interface Props extends React.PropsWithChildren {
    readonly appFeatures: AppFeatures;
    readonly canCustomizeSignIn: boolean; // from EminenceFlags ideally
    readonly description?: string | Loading;
    readonly greeting?: string | Loading;
    readonly iconImage?: IconImage;
    readonly isSending: boolean;
    readonly onPageSourceChanged?: (newPageSource: PagesLoginSource) => void;
    readonly onPressedContinue?: () => void;
    readonly pagesSource?: PagesLoginSource;
    readonly removeBranding: boolean; // from EminenceFlags ideally
    readonly userEmail: string;
    readonly currentState: "request-access-prompt" | "request-access-complete";
    readonly onPressedBack?: () => void;
    readonly appID: string;
}

export const RequestAccessStage: React.VFC<Props> = p => {
    const {
        appFeatures,
        canCustomizeSignIn,
        description,
        greeting,
        iconImage,
        isSending,
        onPageSourceChanged,
        onPressedContinue,
        pagesSource,
        removeBranding,
        userEmail,
        currentState,
        onPressedBack,
        appID,
    } = p;

    const theme = useWireAppTheme();

    const logo = theme.pagesSignInLogo;

    const appKind = getAppKindFromFeatures(appFeatures);

    const hasBackgroundImage = isString(theme.signInBackground) && canCustomizeSignIn;

    const topContent = (
        <div>
            <SignInBranding
                theme={theme}
                iconImage={iconImage}
                appFeatures={appFeatures}
                logo={logo}
                canCustomizeSignIn={canCustomizeSignIn}
                appID={appID}
            />

            <GreetingStyles greeting={greeting} />
            <div tw="text-n600A">{description}</div>
            <div
                data-test="request-access-email"
                tw="w-full h-10 mt-2.5 text-base [line-height: 40px] select-text text-n800A font-semibold">
                {userEmail}
            </div>
        </div>
    );

    const buttonText = isSending
        ? getAnyLocalizedString("requestingAccess", appKind)
        : getAnyLocalizedString("requestAccess", appKind);

    const buttons = (
        <div>
            {currentState === "request-access-prompt" && (
                <SignInPrimaryButton onClick={onPressedContinue} disabled={isSending} label={buttonText} />
            )}
            {onPressedBack !== undefined && (
                <SignInSecondaryButton onClick={onPressedBack} label={getAnyLocalizedString("backToSignIn", appKind)} />
            )}
        </div>
    );

    return (
        <>
            {topContent}
            {buttons}
            <SignUpFooter pagesSource={pagesSource} onPageSourceChanged={onPageSourceChanged} appKind={appKind} />
            {!removeBranding ? (
                <MadeWithGlide position={hasBackgroundImage ? "relative" : "static"} background="none" />
            ) : (
                <div tw="gp-sm:h-8"></div>
            )}
        </>
    );
};
