import type { FlattenSimpleInterpolation } from "styled-components";
import { css } from "styled-components";
import { browserIsAndroidChrome, browserIsSafari } from "../utility/browser-detect";
import type { Config } from "webfontloader";

const systemFont = "-apple-system, BlinkMacSystemFont, Roboto, sans-serif";

type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

interface FontSubsystem {
    weights: FontWeight | [FontWeight, FontWeight, FontWeight, FontWeight, FontWeight, FontWeight, FontWeight];
    fontFamily?: string | [string, string, string, string, string, string, string];
    sizes?: [number, number, number, number, number, number, number];
    lineHeight?: [number, number, number, number, number, number, number];
    css?: FlattenSimpleInterpolation;
}

interface FontSystemClasses {
    Header: FontSubsystem | number;
    Subheader: FontSubsystem | number;
    Callout: FontSubsystem | number;
    Body: FontSubsystem | number;
    AccentHeader: FontSubsystem | number;
    AccentSubheader: FontSubsystem | number;
    AccentCallout: FontSubsystem | number;
    AccentBody: FontSubsystem | number;
    Tag: FontSubsystem | number;
    Caption: FontSubsystem | number;
}

interface FontSystem extends FontSystemClasses {
    defaultFontFamily: string;
    defaultFontSizes: [number, number, number, number, number, number, number];
    defaultLineHeights: [number, number, number, number, number, number, number];
    load: string[];
}

type FontSystemKeys = keyof FontSystemClasses;

export interface FontSystemResult {
    defaultFontFamily: string;
    Header1: FlattenSimpleInterpolation;
    Header1Fixed: FlattenSimpleInterpolation;
    Header1Height: number;
    Header2: FlattenSimpleInterpolation;
    Header2Fixed: FlattenSimpleInterpolation;
    Header2Height: number;
    Header3: FlattenSimpleInterpolation;
    Header3Fixed: FlattenSimpleInterpolation;
    Header3Height: number;
    Header4: FlattenSimpleInterpolation;
    Header4Fixed: FlattenSimpleInterpolation;
    Header4Height: number;
    Header5: FlattenSimpleInterpolation;
    Header5Fixed: FlattenSimpleInterpolation;
    Header5Height: number;
    Header6: FlattenSimpleInterpolation;
    Header6Fixed: FlattenSimpleInterpolation;
    Header6Height: number;
    Header7: FlattenSimpleInterpolation;
    Header7Fixed: FlattenSimpleInterpolation;
    Header7Height: number;
    Subheader1: FlattenSimpleInterpolation;
    Subheader1Fixed: FlattenSimpleInterpolation;
    Subheader1Height: number;
    Subheader2: FlattenSimpleInterpolation;
    Subheader2Fixed: FlattenSimpleInterpolation;
    Subheader2Height: number;
    Subheader3: FlattenSimpleInterpolation;
    Subheader3Fixed: FlattenSimpleInterpolation;
    Subheader3Height: number;
    Subheader4: FlattenSimpleInterpolation;
    Subheader4Fixed: FlattenSimpleInterpolation;
    Subheader4Height: number;
    Subheader5: FlattenSimpleInterpolation;
    Subheader5Fixed: FlattenSimpleInterpolation;
    Subheader5Height: number;
    Subheader6: FlattenSimpleInterpolation;
    Subheader6Fixed: FlattenSimpleInterpolation;
    Subheader6Height: number;
    Subheader7: FlattenSimpleInterpolation;
    Subheader7Fixed: FlattenSimpleInterpolation;
    Subheader7Height: number;
    Callout1: FlattenSimpleInterpolation;
    Callout1Fixed: FlattenSimpleInterpolation;
    Callout1Height: number;
    Callout2: FlattenSimpleInterpolation;
    Callout2Fixed: FlattenSimpleInterpolation;
    Callout2Height: number;
    Callout3: FlattenSimpleInterpolation;
    Callout3Fixed: FlattenSimpleInterpolation;
    Callout3Height: number;
    Callout4: FlattenSimpleInterpolation;
    Callout4Fixed: FlattenSimpleInterpolation;
    Callout4Height: number;
    Callout5: FlattenSimpleInterpolation;
    Callout5Fixed: FlattenSimpleInterpolation;
    Callout5Height: number;
    Callout6: FlattenSimpleInterpolation;
    Callout6Fixed: FlattenSimpleInterpolation;
    Callout6Height: number;
    Callout7: FlattenSimpleInterpolation;
    Callout7Fixed: FlattenSimpleInterpolation;
    Callout7Height: number;
    Body1: FlattenSimpleInterpolation;
    Body1Fixed: FlattenSimpleInterpolation;
    Body1Height: number;
    Body2: FlattenSimpleInterpolation;
    Body2Fixed: FlattenSimpleInterpolation;
    Body2Height: number;
    Body3: FlattenSimpleInterpolation;
    Body3Fixed: FlattenSimpleInterpolation;
    Body3Height: number;
    Body4: FlattenSimpleInterpolation;
    Body4Fixed: FlattenSimpleInterpolation;
    Body4Height: number;
    Body5: FlattenSimpleInterpolation;
    Body5Fixed: FlattenSimpleInterpolation;
    Body5Height: number;
    Body6: FlattenSimpleInterpolation;
    Body6Fixed: FlattenSimpleInterpolation;
    Body6Height: number;
    Body7: FlattenSimpleInterpolation;
    Body7Fixed: FlattenSimpleInterpolation;
    Body7Height: number;
    Tag1: FlattenSimpleInterpolation;
    Tag1Fixed: FlattenSimpleInterpolation;
    Tag1Height: number;
    Tag2: FlattenSimpleInterpolation;
    Tag2Fixed: FlattenSimpleInterpolation;
    Tag2Height: number;
    Tag3: FlattenSimpleInterpolation;
    Tag3Fixed: FlattenSimpleInterpolation;
    Tag3Height: number;
    Tag4: FlattenSimpleInterpolation;
    Tag4Fixed: FlattenSimpleInterpolation;
    Tag4Height: number;
    Tag5: FlattenSimpleInterpolation;
    Tag5Fixed: FlattenSimpleInterpolation;
    Tag5Height: number;
    Tag6: FlattenSimpleInterpolation;
    Tag6Fixed: FlattenSimpleInterpolation;
    Tag6Height: number;
    Tag7: FlattenSimpleInterpolation;
    Tag7Fixed: FlattenSimpleInterpolation;
    Tag7Height: number;
    Caption1: FlattenSimpleInterpolation;
    Caption1Fixed: FlattenSimpleInterpolation;
    Caption1Height: number;
    Caption2: FlattenSimpleInterpolation;
    Caption2Fixed: FlattenSimpleInterpolation;
    Caption2Height: number;
    Caption3: FlattenSimpleInterpolation;
    Caption3Fixed: FlattenSimpleInterpolation;
    Caption3Height: number;
    Caption4: FlattenSimpleInterpolation;
    Caption4Fixed: FlattenSimpleInterpolation;
    Caption4Height: number;
    Caption5: FlattenSimpleInterpolation;
    Caption5Fixed: FlattenSimpleInterpolation;
    Caption5Height: number;
    Caption6: FlattenSimpleInterpolation;
    Caption6Fixed: FlattenSimpleInterpolation;
    Caption6Height: number;
    Caption7: FlattenSimpleInterpolation;
    Caption7Fixed: FlattenSimpleInterpolation;
    Caption7Height: number;
    AccentHeader1: FlattenSimpleInterpolation;
    AccentHeader1Fixed: FlattenSimpleInterpolation;
    AccentHeader1Height: number;
    AccentHeader2: FlattenSimpleInterpolation;
    AccentHeader2Fixed: FlattenSimpleInterpolation;
    AccentHeader2Height: number;
    AccentHeader3: FlattenSimpleInterpolation;
    AccentHeader3Fixed: FlattenSimpleInterpolation;
    AccentHeader3Height: number;
    AccentHeader4: FlattenSimpleInterpolation;
    AccentHeader4Fixed: FlattenSimpleInterpolation;
    AccentHeader4Height: number;
    AccentHeader5: FlattenSimpleInterpolation;
    AccentHeader5Fixed: FlattenSimpleInterpolation;
    AccentHeader5Height: number;
    AccentHeader6: FlattenSimpleInterpolation;
    AccentHeader6Fixed: FlattenSimpleInterpolation;
    AccentHeader6Height: number;
    AccentHeader7: FlattenSimpleInterpolation;
    AccentHeader7Fixed: FlattenSimpleInterpolation;
    AccentHeader7Height: number;
    AccentSubheader1: FlattenSimpleInterpolation;
    AccentSubheader1Fixed: FlattenSimpleInterpolation;
    AccentSubheader1Height: number;
    AccentSubheader2: FlattenSimpleInterpolation;
    AccentSubheader2Fixed: FlattenSimpleInterpolation;
    AccentSubheader2Height: number;
    AccentSubheader3: FlattenSimpleInterpolation;
    AccentSubheader3Fixed: FlattenSimpleInterpolation;
    AccentSubheader3Height: number;
    AccentSubheader4: FlattenSimpleInterpolation;
    AccentSubheader4Fixed: FlattenSimpleInterpolation;
    AccentSubheader4Height: number;
    AccentSubheader5: FlattenSimpleInterpolation;
    AccentSubheader5Fixed: FlattenSimpleInterpolation;
    AccentSubheader5Height: number;
    AccentSubheader6: FlattenSimpleInterpolation;
    AccentSubheader6Fixed: FlattenSimpleInterpolation;
    AccentSubheader6Height: number;
    AccentSubheader7: FlattenSimpleInterpolation;
    AccentSubheader7Fixed: FlattenSimpleInterpolation;
    AccentSubheader7Height: number;
    AccentCallout1: FlattenSimpleInterpolation;
    AccentCallout1Fixed: FlattenSimpleInterpolation;
    AccentCallout1Height: number;
    AccentCallout2: FlattenSimpleInterpolation;
    AccentCallout2Fixed: FlattenSimpleInterpolation;
    AccentCallout2Height: number;
    AccentCallout3: FlattenSimpleInterpolation;
    AccentCallout3Fixed: FlattenSimpleInterpolation;
    AccentCallout3Height: number;
    AccentCallout4: FlattenSimpleInterpolation;
    AccentCallout4Fixed: FlattenSimpleInterpolation;
    AccentCallout4Height: number;
    AccentCallout5: FlattenSimpleInterpolation;
    AccentCallout5Fixed: FlattenSimpleInterpolation;
    AccentCallout5Height: number;
    AccentCallout6: FlattenSimpleInterpolation;
    AccentCallout6Fixed: FlattenSimpleInterpolation;
    AccentCallout6Height: number;
    AccentCallout7: FlattenSimpleInterpolation;
    AccentCallout7Fixed: FlattenSimpleInterpolation;
    AccentCallout7Height: number;
    AccentBody1: FlattenSimpleInterpolation;
    AccentBody1Fixed: FlattenSimpleInterpolation;
    AccentBody1Height: number;
    AccentBody2: FlattenSimpleInterpolation;
    AccentBody2Fixed: FlattenSimpleInterpolation;
    AccentBody2Height: number;
    AccentBody3: FlattenSimpleInterpolation;
    AccentBody3Fixed: FlattenSimpleInterpolation;
    AccentBody3Height: number;
    AccentBody4: FlattenSimpleInterpolation;
    AccentBody4Fixed: FlattenSimpleInterpolation;
    AccentBody4Height: number;
    AccentBody5: FlattenSimpleInterpolation;
    AccentBody5Fixed: FlattenSimpleInterpolation;
    AccentBody5Height: number;
    AccentBody6: FlattenSimpleInterpolation;
    AccentBody6Fixed: FlattenSimpleInterpolation;
    AccentBody6Height: number;
    AccentBody7: FlattenSimpleInterpolation;
    AccentBody7Fixed: FlattenSimpleInterpolation;
    AccentBody7Height: number;
}

let cachedFontScale: number | undefined;
export function getFontScale(forceRefresh?: boolean): number {
    if (!browserIsAndroidChrome) return 1;

    if (cachedFontScale !== undefined && forceRefresh !== true) return cachedFontScale;

    const outer = document.createElement("div");
    outer.className = "glide-measure-box";
    const measure = document.createElement("span");
    const spew = document.createElement("span");
    measure.innerText = "Kj";
    spew.innerText =
        "This is text string that never ends, yes it goes on and on my friend. Its going to be biiiiiig enough to trigger the auto sizer because chrome is duuuuuuuuuuuumb and we have to do insane things like this. Is this enough? I don't know! It's all magic!";
    outer.appendChild(measure);
    outer.appendChild(spew);
    document.body.appendChild(outer);

    let documentWidth = document.body.offsetWidth;
    outer.style.color = "red";
    documentWidth = document.body.offsetWidth;

    const outerSize = outer.offsetHeight;
    const size = measure.offsetHeight;
    document.body.removeChild(outer);
    if (size / 16 !== cachedFontScale) {
        cachedFontScale = size / 16;
    }

    if (3 === (2 as unknown)) {
        // we needed these for the side effects of getting them and for nothing else.
        // unfortunately typescript doesn't really understand that.
        // eslint-disable-next-line no-console
        console.info(outerSize, documentWidth);
    }

    return cachedFontScale;
}

function generateSingleStyle(
    weight: FontWeight,
    size: number,
    lineHeight: number,
    fontFamily: string,
    extra: FlattenSimpleInterpolation | undefined,
    includeRem: boolean
) {
    if (browserIsAndroidChrome && includeRem) {
        return css`
            font-weight: ${weight};
            font-size: calc(${size}px * var(--glide-font-scale));
            line-height: calc(${lineHeight}px * var(--glide-font-scale));
            font-family: ${fontFamily};
            ${extra}
        `;
    }
    return css`
        font-weight: ${weight};
        font-size: ${size}px;
        line-height: ${lineHeight}px;
        ${includeRem
            ? css`
                  font-size: ${size / 16}rem;
                  line-height: ${lineHeight / 16}rem;
              `
            : css``}
        font-family: ${fontFamily};
        ${extra}
    `;
}

async function loadWebFont(config: Config) {
    const WebFont = await import("webfontloader");
    WebFont.load(config);
}

const loaded: Set<string> = new Set();

export function generateFontStyleFromSystem(system: FontSystem): FontSystemResult {
    const result: Record<string, FlattenSimpleInterpolation | number | string> = {
        defaultFontFamily: system.defaultFontFamily,
    };

    let toLoad = system.load.filter(f => !loaded.has(f) && !f.startsWith("LOCAL-"));
    if (toLoad.length > 0) {
        void loadWebFont({
            google: {
                families: toLoad,
            },
        });
        toLoad.forEach(s => loaded.add(s));
    }

    toLoad = system.load.filter(f => !loaded.has(f) && f.startsWith("LOCAL-"));
    if (toLoad.length > 0) {
        const split = toLoad.map(f => f.split("-"));
        void loadWebFont({
            custom: {
                families: split.map(arr => arr[2]),
                urls: split.map(arr => arr[1]),
            },
        });
        toLoad.forEach(s => loaded.add(s));
    }

    const { defaultFontFamily, defaultFontSizes, defaultLineHeights } = system;
    const keys: FontSystemKeys[] = [
        "Header",
        "Subheader",
        "Callout",
        "Body",
        "AccentHeader",
        "AccentSubheader",
        "AccentCallout",
        "AccentBody",
        "Tag",
        "Caption",
    ];
    for (const k of keys) {
        const subsystem = system[k];

        for (let i = 0; i < 7; i++) {
            const key = k + (i + 1);
            if (typeof subsystem === "number") {
                result[key] = generateSingleStyle(
                    subsystem as FontWeight,
                    defaultFontSizes[i],
                    defaultLineHeights[i],
                    defaultFontFamily,
                    undefined,
                    true
                );
                result[key + "Fixed"] = generateSingleStyle(
                    subsystem as FontWeight,
                    defaultFontSizes[i],
                    defaultLineHeights[i],
                    defaultFontFamily,
                    undefined,
                    false
                );
                result[key + "Height"] = defaultLineHeights[i] * getFontScale();
            } else {
                const weight: FontWeight =
                    typeof subsystem.weights === "number" ? subsystem.weights : subsystem.weights[i];
                const fontFamily: string =
                    subsystem.fontFamily === undefined
                        ? defaultFontFamily
                        : typeof subsystem.fontFamily === "string"
                        ? subsystem.fontFamily
                        : subsystem.fontFamily[i];
                const fontSize: number =
                    subsystem.sizes === undefined
                        ? defaultFontSizes[i]
                        : typeof subsystem.sizes === "number"
                        ? subsystem.sizes
                        : subsystem.sizes[i];
                const lineHeight: number =
                    subsystem.lineHeight === undefined
                        ? defaultLineHeights[i]
                        : typeof subsystem.lineHeight === "number"
                        ? subsystem.lineHeight
                        : subsystem.lineHeight[i];
                result[key] = generateSingleStyle(weight, fontSize, lineHeight, fontFamily, subsystem.css, true);
                result[key + "Fixed"] = generateSingleStyle(
                    weight,
                    fontSize,
                    lineHeight,
                    fontFamily,
                    subsystem.css,
                    false
                );
                result[key + "Height"] = lineHeight * getFontScale();
            }
        }
    }

    // okay this is SUPER risky to do but I have failed typescript here
    return result as unknown as FontSystemResult;
}

const fontSystem: FontSystem = {
    AccentHeader: {
        weights: [700, 700, 700, 700, 700, 600, 600],
    },
    AccentSubheader: 600,
    AccentCallout: 500,
    AccentBody: 400,
    Header: {
        weights: [700, 700, 600, 500, 500, 500, 500],
    },
    Subheader: 600,
    Callout: 500,
    Body: 400,
    Caption: 400,
    Tag: {
        sizes: browserIsSafari ? undefined : [34, 24, 20, 16, 15, 13, 11],
        lineHeight: browserIsSafari ? [34, 24, 20, 16, 14, 12, 10] : [34, 24, 20, 16, 15, 13, 11],
        weights: browserIsSafari ? 600 : 500,
        css: css`
            text-transform: lowercase;
            font-variant: ${browserIsSafari ? "small-caps" : "all-small-caps"};
        `,
    },
    defaultFontFamily: systemFont,
    defaultFontSizes: [34, 24, 20, 16, 14, 12, 10],
    defaultLineHeights: [37, 29, 24, 19, 17, 17, 14],
    load: [], // default fonts are loaded by default
};

export const fontStyles = generateFontStyleFromSystem(fontSystem);
