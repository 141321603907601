import "twin.macro";
import { AccentPill, GreyPill } from "../pills";
import classNames from "classnames";
import { RadioTile } from "../tiles/radio-tile";
import { isDefined } from "@glide/support";

type ImportFromSourceProps = {
    onClick: () => void;
    icon: React.ReactNode;
    label: string;
    selected: boolean;
    labelDescription?: string;
    className?: string;

    accentPillLabel?: string;
    greyPillLabel?: string;
};

type ImportSourceTile = Omit<ImportFromSourceProps, "labelDescription"> & {
    pill?: React.ReactNode;
};

// NOTE: These are meant to match styles of the import from file, usually only paired with Google Sheets.
export const ImportFromSourceWideBox: React.VFC<ImportFromSourceProps> = p => {
    const { className, icon, label, labelDescription, onClick, selected, accentPillLabel, greyPillLabel } = p;
    return (
        <div
            onClick={onClick}
            className={classNames(className, selected && "selected")}
            tw="w-full relative pointer-events-auto cursor-pointer flex flex-col items-start justify-start border-border-base
                border-dashed border-2 rounded-xl text-center p-8 transition hover:bg-n100A
                [&.selected]:(border-solid border-accent)
            ">
            <ImportTitlePill greyPillLabel={greyPillLabel} accentPillLabel={accentPillLabel} />
            <div tw="flex pointer-events-none items-start gap-6">
                <div tw="w-8 h-8 pt-2">{icon}</div>
                <div tw="flex flex-col items-start">
                    <div tw="text-builder-xl text-text-dark font-semibold pointer-events-none">{label}</div>
                    <div tw="text-sm text-text-pale pointer-events-none">{labelDescription}</div>
                </div>
            </div>
        </div>
    );
};

type ImportTilePillProps = {
    greyPillLabel: string | undefined;
    accentPillLabel: string | undefined;
};

export const ImportTitlePill: React.VFC<ImportTilePillProps> = p => {
    const { greyPillLabel, accentPillLabel } = p;
    if (isDefined(accentPillLabel)) {
        return <AccentPill tw="absolute top-2 right-2 text-builder-2xs px-1.5">{accentPillLabel}</AccentPill>;
    }
    if (isDefined(greyPillLabel)) {
        return <GreyPill tw="absolute top-2 right-2 text-builder-2xs px-1.5">{greyPillLabel}</GreyPill>;
    }
    return null;
};

export const ImportSourceTile: React.VFC<ImportSourceTile> = p => {
    const { className, icon, label, pill, onClick, selected } = p;

    return (
        <RadioTile
            className={className}
            icon={icon}
            label={label}
            onSelect={onClick}
            checked={selected}
            value={`source-${label.toLowerCase()}`}
            name="new-app-source"
            pill={pill}
        />
    );
};
