import type { WireAppTheme } from "@glide/theme";
import { browserOnPhone, getGlideIcon } from "@glide/common";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { isDefined } from "@glide/support";
import type { UIImageFill } from "@glide/wire";
import {
    UIAlignment,
    UIAspect,
    UIBackgroundStyle,
    UIButtonAppearance,
    UIImageStyle,
    UIOrientation,
    UISize,
} from "@glide/wire";
import classNames from "classnames";
import type { HTMLAttributes } from "react";
import { css } from "styled-components";
import tw from "twin.macro";
import { Img } from "../../components/img/img";
import { useIsFlat } from "../../utils/use-is-flat";
import type { ExtractedActions } from "../../wire-lib";
import { breakoutActions, makeActionSpreadProps } from "../../wire-lib";
import { WireButton } from "../wire-button/wire-button";
import { SectionStyleProvider } from "../wire-container/wire-container";
import { WireMenuButton } from "../wire-menu-button/wire-menu-button";
import type { CardProps } from "./card-collection-types";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";

interface CardContainerProps {
    readonly size?: UISize;
    readonly orientation: UIOrientation;
    readonly onSeeAll: (() => void) | undefined;
    readonly showSeeAll?: boolean;
}

export const CardContainer: React.FC<React.PropsWithChildren<CardContainerProps>> = p => {
    const { children, size, orientation, onSeeAll, showSeeAll = false } = p;

    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    return (
        <>
            {showSeeAll && onSeeAll !== undefined && orientation === UIOrientation.Horizontal && (
                <div tw="flex justify-end w-full -mt-2 my-1">
                    <WireButton
                        data-testid="see-all-button"
                        onClick={onSeeAll}
                        appearance={UIButtonAppearance.MinimalPrimary}
                        iconName={getGlideIcon("st-chevron-right")}
                        iconPlacement="right"
                        size={isMobile ? "mini" : "sm"}>
                        {getLocalizedString("seeAll", AppKind.Page)}
                    </WireButton>
                </div>
            )}
            <div
                tw="w-full"
                data-testid={`${orientation}-collection-container`}
                className={classNames(size, orientation)}
                css={cardContainerStyles}>
                {children}
            </div>
        </>
    );
};

function getRingIdleCss(theme: WireAppTheme) {
    return css`
        box-shadow: inset 0px 0px 0px 1px ${theme.borderBase}, 0px 0px 0px 0px ${theme.borderBase};
    `;
}

export function getRingFocusCss(theme: WireAppTheme) {
    return css`
        --shadow-args: inset 0px 0px 0px 0px ${theme.borderBase}, 0px 0px 0px 4px ${theme.borderBase};
        --focus-shadow-args: inset 0px 0px 0px 0px ${theme.textContextualAccent},
            0px 0px 0px 4px ${theme.textContextualAccent};
        ${tw`page-hover:[box-shadow:var(--shadow-args)] active:[box-shadow:var(--shadow-args)]
          focus-visible:[box-shadow:var(--focus-shadow-args)]`};
    `;
}

export const Card: React.VFC<
    CardProps & {
        size: UISize;
        imageSize: UISize;
        imageFill: UIImageFill;
        alignment: UIAlignment;
        aspect: UIAspect;
        viewType: "card" | "grid";
        orientation: UIOrientation;
        appID: string;
    }
> = p => {
    const { desc, imageStyle, alignment, imageSize, aspect, viewType, imageFill, size, orientation, appID } = p;
    // eslint-disable-next-line prefer-const
    let [primary, secondary, ...maybeMenuActions] = breakoutActions(desc.menuItems, 2);

    let menuActions: ExtractedActions = maybeMenuActions;

    if (viewType === "grid" && desc.menuItems.length > 0) {
        // FIX(mauri): Somehow desc.menuItems is [undefined, undefined] when no actions are passed
        menuActions = [...desc.menuItems].filter(item => item !== undefined);
        primary = undefined;
        secondary = undefined;
    }

    const isFlat = useIsFlat();
    const theme = useWireAppTheme();
    const imageStyleSizeClass = `${imageStyle}-${imageSize}`;

    const hasButtons = isDefined(primary) || isDefined(secondary) || menuActions.length > 0;
    const hasMenuActions = menuActions.length > 0;

    const buttons = hasButtons ? (
        <div
            tw="hidden w-full gap-2"
            className={classNames(
                { mobile: browserOnPhone },
                (primary !== undefined || secondary !== undefined) && "needs-to-be-shown"
            )}
            css={css`
                .card &.needs-to-be-shown {
                    ${tw`flex`}
                }

                .card & {
                    ${tw`flex-col gap-1 px-2 pb-2`}
                }

                .card.${UISize.Large} &,
                .card.${UISize.XLarge} & {
                    ${tw`flex-row justify-between gap-2 px-2.5 pb-2.5`}
                }

                .card.${UISize.Medium} & {
                    ${tw`gp-xl:(flex-row justify-between gap-2 px-2.5 pb-2.5)`}
                }
            `}>
            {/* FIXME: Collapse styled button flow for small widths */}
            {primary !== undefined && (
                <WireButton
                    tw="flex-grow"
                    data-testid="card-primary-button"
                    appearance={UIButtonAppearance.Filled}
                    {...makeActionSpreadProps(primary)}
                />
            )}
            {secondary !== undefined && (
                <WireButton
                    tw="flex-grow"
                    appearance={UIButtonAppearance.Bordered}
                    {...makeActionSpreadProps(secondary)}
                />
            )}
        </div>
    ) : null;

    let a11yProps: HTMLAttributes<HTMLDivElement> = {};

    if (desc.onCardClick) {
        a11yProps = {
            "aria-pressed": false,
            role: "button",
            tabIndex: 0,
        };
    }
    return (
        <SectionStyleProvider value={UIBackgroundStyle.Highlight}>
            <div
                data-testid="cc-card"
                onKeyDown={e => {
                    if (e.key === "Enter") desc.onCardClick?.();
                }}
                onClick={e => {
                    desc.onCardClick?.();
                    e.stopPropagation();
                }}
                {...a11yProps}
                tw="relative text-text-dark grid [grid-template-columns:minmax(0, auto)] w-full rounded-lg
                    transition-colors duration-75 cursor-default"
                className={classNames(
                    viewType,
                    "group",
                    desc.onCardClick && "has-click-action",
                    isFlat && "flat",
                    size,
                    !desc.image && "no-image",
                    "collection-item",
                    orientation
                )}
                css={css`
                    --shadow-color: ${theme.borderBase};

                    &.grid.${UISize.Large}, &.grid.${UISize.XLarge} {
                        ${tw`rounded-xl`}
                    }

                    &.grid.${UISize.Medium} {
                        ${tw`gp-xl:rounded-xl`}
                    }

                    &.grid {
                        ${tw`overflow-hidden text-text-contextual-dark rounded-lg focus-visible:(ring-2
                          ring-text-contextual-accent)`}
                    }

                    &.card.${UISize.Large}, &.card.${UISize.XLarge} {
                        ${tw`rounded-2xl`}
                    }

                    &.card.${UISize.Medium} {
                        ${tw`gp-xl:rounded-2xl`}
                    }

                    &.card {
                        ${tw`overflow-hidden rounded-xl focus-visible:(ring-2 ring-text-contextual-accent)`}
                    }

                    &.card:not(.flat) {
                        ${tw`bg-bg-front`}
                        ${getRingIdleCss(theme)}
                    }

                    &.card.has-click-action:not(.flat) {
                        ${getRingFocusCss(theme)}
                    }

                    &.card.flat {
                        ${tw`bg-n100A`}

                        .style-accent-bg & {
                            ${tw`bg-bg-front`}
                        }
                    }

                    .style-dark &.card {
                        ${tw`bg-w10A ring-1 ring-inset ring-w05A text-text-contextual-base`}
                    }

                    &.has-click-action {
                        ${tw`cursor-pointer`}
                    }

                    &.${UIOrientation.Horizontal} {
                        ${tw`shrink-0 snap-start scroll-mx-[var(--container-x-pad)]`}
                    }
                `}>
                <div
                    tw="col-start-1 row-start-1 transition-colors duration-75"
                    className={classNames(viewType, desc.onCardClick && "has-click-action", isFlat && "flat")}
                    css={css`
                        &.grid.has-click-action,
                        &.card.has-click-action.flat {
                            ${tw`group-page-hover:bg-bg-hovered group-active:bg-bg-hovered`}
                        }
                    `}
                />
                <div
                    onKeyDown={e => e.key === "Enter" && e.stopPropagation()}
                    tw="flex flex-col col-start-1 row-start-1 gap-1"
                    css={css`
                        .card.${UISize.Large} &,
                        .card.${UISize.XLarge} & {
                            ${tw`gap-2 p-2`}
                        }

                        .card.${UISize.Medium} & {
                            ${tw`gp-xl:(p-2 gap-2)`}
                        }

                        .card & {
                            ${tw`p-1`}
                        }
                    `}>
                    {isDefined(desc.image) && (
                        <div
                            data-testid="card-image"
                            tw="relative w-full [min-height:72px] [padding-top:56.25%]"
                            className={classNames(alignment, aspect, imageStyleSizeClass)}
                            css={css`
                                &.${UIAspect.Auto}, &.${UIAspect.SixteenByNine} {
                                    ${tw`[padding-top:56.25%]`}
                                }

                                &.${UIAspect.Square} {
                                    ${tw`[padding-top:100%]`}
                                }

                                &.${UIAspect.FourByThree} {
                                    ${tw`[padding-top:75%]`}
                                }

                                &.${UIAspect.ThreeByOne} {
                                    ${tw`[padding-top:33.33%]`}
                                }

                                &.${UIAspect.ThreeByTwo} {
                                    ${tw`[padding-top:66.66%]`}
                                }

                                &.${UIImageStyle.Circle}-${UISize.XSmall} {
                                    ${tw`[padding-top:33.33%]`}
                                }

                                &.${UIImageStyle.Circle}-${UISize.Small} {
                                    ${tw`[padding-top:50%]`}
                                }

                                &.${UIImageStyle.Circle}-${UISize.Medium} {
                                    ${tw`[padding-top:66.66%]`}
                                }

                                &.${UIImageStyle.Circle}-${UISize.Large} {
                                    ${tw`[padding-top:100%]`}
                                }
                            `}>
                            <div
                                tw="absolute top-0 h-full w-full flex justify-center"
                                css={css`
                                    .${UIImageStyle.Circle}-${UISize.XSmall} & {
                                        ${tw`py-4 px-1
                                        gp-sm:(py-3)
                                        gp-md:(py-2)`}
                                    }

                                    .${UIImageStyle.Circle}-${UISize.Small} & {
                                        ${tw`py-3 px-1
                                        gp-sm:(py-2)
                                        gp-md:(py-1)`}
                                    }

                                    .${UIImageStyle.Circle}-${UISize.Medium} & {
                                        ${tw`py-2 px-0
                                        gp-md:(py-1)`}
                                    }

                                    .${UIImageStyle.Circle}-${UISize.Large} & {
                                        ${tw`py-1 px-0
                                        gp-sm:(py-2)
                                        gp-md:(py-3)`}
                                    }
                                `}>
                                {imageStyle === UIImageStyle.Circle && (
                                    <div
                                        tw="relative w-full flex justify-center"
                                        css={css`
                                            .${UIAlignment.Start} & {
                                                ${tw`justify-start ml-1`}
                                            }
                                        `}>
                                        <Img
                                            src={massageImageUrl(
                                                desc.image ?? undefined,
                                                {
                                                    thumbnail: false,
                                                },
                                                appID
                                            )}
                                            tw="relative inset-0 h-full object-cover bg-n50 transition-shadow text-2xl
                                                rounded-full aspect-ratio[1]"
                                            isPages={true}
                                        />
                                    </div>
                                )}
                                {imageStyle === UIImageStyle.Rectilinear && (
                                    <Img
                                        src={massageImageUrl(
                                            desc.image ?? undefined,
                                            {
                                                thumbnail: false,
                                            },
                                            appID
                                        )}
                                        isPages={true}
                                        tw="h-full w-full object-cover bg-n50A transition-shadow rounded-lg text-3xl"
                                        className={classNames(imageStyleSizeClass, viewType, imageFill)}
                                        css={css`
                                            &.image-circle {
                                                ${tw`object-cover rounded-full`}
                                            }

                                            &.image-fill-contain {
                                                ${tw`object-contain`}
                                            }
                                        `}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {desc.heroImage && (
                        <Img
                            src={massageImageUrl(desc.heroImage, { thumbnail: false, width: 96, height: 96 }, appID)}
                            isPages={true}
                            tw="w-24 h-24 self-center -mt-12 shrink-0 rounded-full object-cover border-2 z-10
                                border-solid border-n0"
                        />
                    )}
                    <div
                        tw="flex grow flex-col p-2"
                        className={classNames(imageStyle, alignment, viewType, hasMenuActions && "has-menu-actions")}
                        css={css`
                            .card.${UISize.Large} &,
                            .card.${UISize.XLarge} & {
                                ${tw`p-2.5`}
                            }

                            .card.${UISize.Medium} & {
                                ${tw`gp-xl:p-2.5`}
                            }

                            .grid & {
                                ${tw`pt-1 pb-3 text-text-contextual-dark`}
                            }

                            .grid.${UISize.Large} &,
                            .grid.${UISize.XLarge} & {
                                ${tw`pb-4`}
                            }

                            &.${UIImageStyle.Circle} {
                                ${tw`pt-0`}
                            }

                            &.${UIAlignment.Center} {
                                ${tw`items-center text-center`}
                            }

                            .no-image & button + h3,
                            .no-image & button + p {
                                ${tw`pr-7`}
                            }
                        `}>
                        {hasMenuActions && (
                            <WireMenuButton
                                className={classNames(
                                    viewType,
                                    (primary !== undefined || secondary !== undefined) && "could-hide"
                                )}
                                css={css`
                                    &.card {
                                        ${tw`top-2 right-2`}
                                    }

                                    .${UISize.Large} &.card,
                                    .${UISize.XLarge} &.card {
                                        ${tw`top-3 right-3
                                          gp-md:(top-4 right-4)`}
                                    }

                                    &.is-opened {
                                        ${tw`bg-n400A`}
                                    }

                                    .${UIImageStyle.Circle} & {
                                        ${tw`text-icon-base bg-transparent page-hover:(text-icon-dark bg-n100A)
                                          active:(text-icon-dark bg-n100A)`}
                                    }
                                `}
                                tw="absolute top-1 right-1 w-8 h-6 transform transition opacity-0 bg-n300A
                                    [backdrop-filter: blur(5px)] text-white group-page-hover:opacity-100
                                    no-hover:opacity-100 focus-visible:opacity-100 page-hover:(bg-n400A text-white)
                                    active:bg-n400A"
                                appearance={UIButtonAppearance.Floating}
                                menuItems={menuActions}
                            />
                        )}
                        {desc.emphasis && (
                            <p
                                css={css`
                                    .${UIBackgroundStyle.Accent} .grid & {
                                        ${tw`text-text-contextual-accent`}
                                    }
                                `}
                                tw="text-xs leading-4 uppercase font-semibold break-words text-accent">
                                {desc.emphasis}
                            </p>
                        )}
                        {desc.title && (
                            <h3 tw="font-semibold leading-snug mb-px text-base whitespace-pre-wrap break-words">
                                {desc.title}
                            </h3>
                        )}
                        {desc.subtitle && (
                            <p
                                css={css`
                                    .grid & {
                                        ${tw`text-text-contextual-pale`}
                                    }

                                    .${UIBackgroundStyle.Accent} .card.flat & {
                                        ${tw`text-text-pale`}
                                    }
                                `}
                                tw="text-base text-text-contextual-pale leading-5 whitespace-pre-wrap break-words">
                                {desc.subtitle}
                            </p>
                        )}
                        <div tw="grow" />
                    </div>
                    {viewType === "card" && buttons}
                </div>
            </div>
        </SectionStyleProvider>
    );
};

const cardContainerStyles = css`
    ${tw`grid [--grid-gap: 8px] gp-md:[--grid-gap: 10px] gap-[var(--grid-gap)]`}

    &.${UISize.XSmall} {
        ${tw`grid-cols-4
        gp-md:(grid-cols-5)
        gp-lg:(grid-cols-6)
        gp-xl:(grid-cols-7)`}
    }

    &.${UISize.Small} {
        ${tw`grid-cols-3
        gp-md:(grid-cols-4)
        gp-lg:(grid-cols-5)
        gp-xl:(grid-cols-6)`}
    }

    &.${UISize.Medium} {
        ${tw`text-lg grid-cols-2
        gp-md:(grid-cols-3)
        gp-lg:(grid-cols-4)
        gp-xl:(grid-cols-4 [--grid-gap: 16px])`}
    }

    &.${UISize.Large} {
        ${tw`text-lg grid-cols-1
        gp-sm:(grid-cols-2 [--grid-gap: 20px])
        gp-md:([--grid-gap: 24px])
        gp-lg:(grid-cols-3)
        gp-xl:(grid-cols-4)`}
    }

    &.${UISize.XLarge} {
        ${tw`text-lg grid-cols-1
        gp-sm:([--grid-gap: 20px])
        gp-md:(grid-cols-2 [--grid-gap: 24px])
        gp-lg:(grid-cols-2)
        gp-xl:(grid-cols-3)`}
    }

    &.${UIOrientation.Horizontal} {
        ${tw`flex overflow-auto snap-mandatory snap-x w-[calc(100% + var(--container-x-pad) * 2)] px-[var(--container-x-pad)] pb-3 gp-sm:px-0 gp-sm:w-full`}

        // I had to do maths...
        --two-cols-width: calc(50% - var(--grid-gap) * (1 / 2));
        --three-cols-width: calc(33.33333% - var(--grid-gap) * (2 / 3));
        --four-cols-width: calc(25% - var(--grid-gap) * (3 / 4));
        --five-cols-width: calc(20% - var(--grid-gap) * (4 / 5));
        --six-cols-width: calc(16.66666% - var(--grid-gap) * (5 / 6));

        &.${UISize.XSmall} {
            ${tw`all-child:(w-[var(--four-cols-width)])
            gp-md:(all-child:(w-[var(--five-cols-width)]))
            gp-lg:(all-child:(w-[var(--six-cols-width)]))`}
        }

        &.${UISize.Small} {
            ${tw`all-child:(w-[var(--three-cols-width)])
            gp-md:(all-child:(w-[var(--four-cols-width)]))
            gp-lg:(all-child:(w-[var(--five-cols-width)]))
            gp-xl:(all-child:(w-[var(--six-cols-width)]))`}
        }

        &.${UISize.Medium} {
            ${tw`all-child:(w-[var(--two-cols-width)])
            gp-md:(all-child:(w-[var(--three-cols-width)]))
            gp-lg:(all-child:(w-[var(--four-cols-width)]))`}
        }
        &.${UISize.Large} {
            ${tw`all-child:w-full
            gp-md:(all-child:(w-[var(--two-cols-width)]))
            gp-lg:(all-child:(w-[var(--three-cols-width)]))`}
        }

        &.${UISize.XLarge} {
            ${tw`all-child:w-full
            gp-lg:(all-child:(w-[var(--two-cols-width)]))`}
        }

        @supports selector(::-webkit-scrollbar) {
            .gp-sm &:not(.responsive-blocker),
            .responsive-blocker .gp-sm {
                ::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                    background-color: transparent;
                }

                ::-webkit-scrollbar-thumb {
                    ${tw`rounded-lg bg-n400 hover:bg-n500`}
                }
            }
        }
    }
`;
