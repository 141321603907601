import "twin.macro";

import { GlideIcon } from "@glide/common";
import { TextComponentStyle } from "@glide/component-utils";
import type { WireMultipleFilterComponent } from "@glide/fluent-components/dist/js/base-components";
import type { DynamicFilterEntriesWithCaption, StringMultipleDynamicFilterEntry, WireAction } from "@glide/wire";
import * as React from "react";

import { useScrollRef } from "../../chrome/content/content-common";
import { useSetSlideInHeight } from "../../chrome/content/lib/use-slide-in-size";
import { PagesCheckbox } from "../../components/pages-checkbox";
import { PagesSwitch } from "../../components/pages-switch";
import { Text } from "../../components/text/text";
import { runActionAndHandleURL } from "../../wire-lib";
import type { WireRenderer } from "../wire-renderer";
import { VerticallyExpandableContainer } from "./vertically-expandable-container";

export const WireMultipleFilters: WireRenderer<WireMultipleFilterComponent> = React.memo(p => {
    const { backend, multipleFilters } = p;

    // Make sure the slide in that contains this has a fixed height
    const isReady = useSetSlideInHeight(400);

    const filterValuesEntries = Object.values(multipleFilters.entries);

    const toggleFilterEntry = (onToggleAction: WireAction) => {
        runActionAndHandleURL(onToggleAction, backend);
    };

    const scrollingElementRef = useScrollRef();

    if (!isReady) {
        return null;
    }

    return (
        <WireMultipleFiltersImpl
            filterValuesEntries={filterValuesEntries}
            toggleFilterEntry={toggleFilterEntry}
            scrollingElementRef={scrollingElementRef}
        />
    );
});

interface WireMultipleFiltersImplProps {
    readonly toggleFilterEntry: (onToggleAction: WireAction) => void;
    readonly filterValuesEntries: readonly DynamicFilterEntriesWithCaption[];
    readonly scrollingElementRef: React.RefObject<HTMLElement> | null;
}

const FILTER_CATEGORY_CLASSNAME = "filter-category";

export const WireMultipleFiltersImpl: React.VFC<WireMultipleFiltersImplProps> = p => {
    const { filterValuesEntries, toggleFilterEntry, scrollingElementRef } = p;

    // This is technically a Set, but the ergonomics of using an Array are worth it
    const [toggledGroupsIndexes, setToggledGroupsIndexes] = React.useState<number[]>(() => {
        if (filterValuesEntries.length <= 2) {
            return filterValuesEntries.map((_, i) => i);
        } else {
            return [];
        }
    });

    const toggleGroup = (groupIndex: number) => {
        setToggledGroupsIndexes(prevGroups => {
            if (prevGroups.includes(groupIndex)) {
                return prevGroups.filter(item => item !== groupIndex);
            } else {
                return [...prevGroups, groupIndex];
            }
        });
    };

    React.useEffect(() => {
        if (scrollingElementRef === null || scrollingElementRef.current === null) {
            return;
        }

        const scrollingElement = scrollingElementRef.current;

        const onScroll = () => {
            const categories = document.querySelectorAll<HTMLElement>(`.${FILTER_CATEGORY_CLASSNAME}`);

            for (const category of categories) {
                if (isElementFloating(scrollingElement, category)) {
                    category.classList.add("floating");
                } else {
                    category.classList.remove("floating");
                }
            }
        };

        scrollingElement.addEventListener("scroll", onScroll);

        return () => {
            scrollingElement.removeEventListener("scroll", onScroll);
        };
    }, [scrollingElementRef]);

    return (
        <div tw="px-5">
            {filterValuesEntries.map((filterValues, idx) => {
                const isOpen = toggledGroupsIndexes.includes(idx);
                const toggleGroupOpenState = () => toggleGroup(idx);

                const { caption, kind } = filterValues;

                if (kind === "boolean") {
                    const { onToggle, selected } = filterValues.filterEntry;
                    return (
                        <BooleanFilterGroup
                            key={idx}
                            onToggle={() => toggleFilterEntry(onToggle)}
                            isSelected={selected}
                            caption={caption}
                        />
                    );
                } else {
                    return (
                        <StringFilterGroup
                            key={idx}
                            isOpen={isOpen}
                            toggleGroupOpenState={toggleGroupOpenState}
                            caption={caption}
                            filterEntries={filterValues.filterEntries}
                            toggleFilterEntry={toggleFilterEntry}
                        />
                    );
                }
            })}
        </div>
    );
};

interface BooleanFilterGroupProps {
    readonly isSelected: boolean;
    readonly onToggle: () => void;
    readonly caption: string;
}

const BooleanFilterGroup: React.VFC<BooleanFilterGroupProps> = p => {
    const { isSelected, caption, onToggle } = p;

    return (
        <div tw="not-last-of-type:(border-b border-border-pale)">
            <label tw="flex items-center justify-between h-12">
                <Text tw="text-text-base" element="span" variant={TextComponentStyle.regular}>
                    {caption}
                </Text>
                <PagesSwitch checked={isSelected} onChange={onToggle} />
            </label>
        </div>
    );
};

interface FilterGroupProps {
    readonly isOpen: boolean;
    readonly toggleGroupOpenState: () => void;
    readonly caption: string;
    readonly filterEntries: readonly StringMultipleDynamicFilterEntry[];
    readonly toggleFilterEntry: (onToggleAction: WireAction) => void;
}

const StringFilterGroup: React.VFC<FilterGroupProps> = p => {
    const { isOpen, toggleGroupOpenState, caption, filterEntries, toggleFilterEntry } = p;
    const selectedAmount = filterEntries.filter(v => v.selected).length;

    return (
        <div tw="not-last-of-type:(border-b border-border-pale)">
            <button
                onClick={toggleGroupOpenState}
                className={FILTER_CATEGORY_CLASSNAME}
                data-testid={`filter-group-header-${caption}`}
                tw="w-full h-12 flex justify-between items-center sticky top-0 bg-bg-front z-10 border-b border-transparent [&.floating]:border-border-xpale">
                <div tw="flex items-center">
                    <Text tw="text-text-base" element="span" variant={TextComponentStyle.headlineXXXSmall}>
                        {caption}
                    </Text>
                    <SelectedBadge selectedAmount={selectedAmount} />
                </div>

                <GlideIcon
                    kind="stroke"
                    icon="st-chevron-right"
                    rotateDeg={isOpen ? 90 : 0}
                    tw="text-text-xpale mr-px"
                    iconSize={20}
                />
            </button>

            <VerticallyExpandableContainer isOpen={isOpen} transitionSpeed="fast">
                <div tw="pb-2">
                    {filterEntries.map(entry => {
                        const onToggle = () => {
                            toggleFilterEntry(entry.onToggle);
                        };
                        return (
                            <div key={entry.displayValue}>
                                <label tw="flex items-center justify-between pb-3">
                                    <Text tw="text-text-base" element="span" variant={TextComponentStyle.regular}>
                                        {entry.displayValue}
                                    </Text>
                                    <PagesCheckbox checked={entry.selected} onChange={onToggle} tw="mr-px w-5 h-5" />
                                </label>
                            </div>
                        );
                    })}
                </div>
            </VerticallyExpandableContainer>
        </div>
    );
};

interface SelectedBadgeProps {
    readonly selectedAmount: number;
}

export const SelectedBadge: React.FC<SelectedBadgeProps> = p => {
    const { selectedAmount } = p;

    const isEmpty = selectedAmount === 0;

    return (
        <div
            className={isEmpty ? "empty" : undefined}
            tw="w-5 h-5 rounded-full bg-accent flex items-center justify-center ml-2 [&.empty]:(invisible) ">
            <Text element="span" variant={TextComponentStyle.small} tw="text-white font-medium">
                {selectedAmount}
            </Text>
        </div>
    );
};

function isElementFloating(container: HTMLElement, stickyElement: HTMLElement) {
    // Get the scroll position of the container
    const containerScrollTop = container.scrollTop;
    if (containerScrollTop === 0) {
        return false;
    }

    // Get the top position of the sticky element relative to its container
    const stickyTop = stickyElement.offsetTop;

    // containerScrollTop < stickyTop, the element is still not stuck to the top
    // containerScrollTop === stickyTop, the element is stuck to the top
    // containerScrollTop > stickyTop, the element is being pushed by the next sticky header
    return containerScrollTop === stickyTop;
}
