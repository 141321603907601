import * as React from "react";

import { ErrorBoundary } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { lazilyLoadedComponent } from "@glide/common-core/dist/js/support/react-lazy-loading";
import type { AppKind } from "@glide/location-common";
import { css } from "styled-components";
import { CodeWrapper, HelpLink, Title, Wrapper } from "./app-qr-code-style";

const QRCode = lazilyLoadedComponent("qrcode.react", () =>
    import("qrcode.react").then(mod => ({ default: mod.QRCodeCanvas }))
);

interface Props extends React.PropsWithChildren {
    size: number;
    url?: string;
    hideDocumentationLink?: boolean;
    appKind: AppKind;
    isDarkMode?: boolean;
}

class AppQRCode extends React.Component<Props, {}> {
    public render() {
        const { size, url, hideDocumentationLink, appKind, isDarkMode } = this.props;
        return (
            <Wrapper width={size}>
                <CodeWrapper>
                    <ErrorBoundary>
                        <React.Suspense fallback={<div />}>
                            <QRCode
                                //@ts-ignore
                                size={size - 42}
                                value={url}
                                fgColor={isDarkMode ? "#ffffff" : "#313139"}
                                bgColor={isDarkMode ? "#2C2C32" : "#ffffff"}
                                level="Q"
                            />
                        </React.Suspense>
                    </ErrorBoundary>
                </CodeWrapper>

                <QRCodeHelpText appKind={appKind} hideDocumentationLink={hideDocumentationLink} />
            </Wrapper>
        );
    }
}

export const QRCodeHelpText: React.VFC<{
    appKind: AppKind;
    hideDocumentationLink?: boolean;
    className?: string;
}> = p => {
    const { appKind, hideDocumentationLink = false, className } = p;
    const previewText = getLocalizedString("scanCodePreview", appKind);
    const [previewTextBeforeLink, previewTextRest] = previewText.split("<a>");
    const [previewLinkText, previewTextAfterLink] = previewTextRest.split("</a>");

    return (
        <Title className={className}>
            {previewTextBeforeLink}
            {hideDocumentationLink !== true && (
                <>
                    {" "}
                    <HelpLink href={getDocURL("scanInstall")} target="_blank" rel="noopener noreferrer">
                        {previewLinkText}
                    </HelpLink>
                </>
            )}
            {previewTextAfterLink}
        </Title>
    );
};

type GlideQRCodeProps = Pick<Props, "url" | "size" | "isDarkMode">;

export const GlideQRCode: React.VFC<GlideQRCodeProps> = p => {
    const { size, url, isDarkMode } = p;
    const logoSize = size / 4;

    return (
        <ErrorBoundary>
            <React.Suspense
                fallback={
                    <div
                        css={css`
                            width: ${size}px;
                            height: ${size}px;
                        `}
                    />
                }
            >
                <QRCode
                    //@ts-ignore
                    size={size}
                    value={url}
                    fgColor={isDarkMode ? "#ffffff" : "#313139"}
                    bgColor={isDarkMode ? "#2C2C32" : "#ffffff"}
                    level="Q"
                    includeMargin={false}
                    imageSettings={{
                        src: `/images/glide-logo${isDarkMode ? "-white" : ""}.svg`,
                        x: undefined,
                        y: undefined,
                        height: logoSize,
                        width: logoSize,
                        excavate: true,
                    }}
                />
            </React.Suspense>
        </ErrorBoundary>
    );
};

export default AppQRCode;
