import type { Organization } from "@glide/common-core/dist/js/Database";
import type { QuotaDocument } from "@glide/common-core/dist/js/Database/quotas";
import type { LoginLogsBody, QuotaStateForOrgResponse } from "@glide/common-core/dist/js/firebase-function-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";

export type OrgLoginLogData = {
    appID: string;
    authenticationMethod: string;
    appUserEmail: string;
    timestamp: number;
};

export const getOrgLoginLogs = async (org: Organization): Promise<OrgLoginLogData[]> => {
    const f = getAppFacilities();
    const body: LoginLogsBody = { organizationID: org.id };
    const res = await f.callAuthCloudFunction("getOrgLoginLogs", body);
    if (res === undefined) return [];
    return await res.json();
};

export const getQuotaStateForOrg = async (orgID: string): Promise<QuotaStateForOrgResponse | undefined> => {
    const f = getAppFacilities();
    const body: LoginLogsBody = { organizationID: orgID };
    const res = await f.callAuthCloudFunction("getQuotaStateForOrg", body);
    if (res === undefined) return undefined;
    const raw = await res.json();
    return raw;
};

export const getQuotaStateForApp = async (appID: string): Promise<QuotaDocument | undefined> => {
    const response = await getAppFacilities().callAuthCloudFunction("getQuotaStateForApp", { appID });
    if (response === undefined) {
        return undefined;
    }
    const rawJsonResponse = await response.json();
    return rawJsonResponse;
};

export enum UsageMetricsStatus {
    EMPTY = "EMPTY",
    LOADING = "LOADING",
    LOADED = "LOADED",
    ERROR = "ERROR",
}
