import {
    rowIndexColumnName,
    type TableGlideType,
    getTableColumnDisplayName,
    getTableName,
    isComputedColumn,
    isPrimitiveType,
    sheetNameForTable,
} from "@glide/type-schema";
import { ActionKind } from "@glide/common-core/dist/js/database-strings";
import { mapFilterUndefined } from "@glideapps/ts-necessities";
import camelCase from "lodash/camelCase";

import type { Examples, Props } from "../types";

const preamble = `import * as glide from "@glideapps/tables";`;

function makeColumnSchema(table: TableGlideType) {
    return mapFilterUndefined(table.columns, c => {
        if (c.hidden === true || c.isProtected === true || c.isUserSpecific === true || c.isReadOnly === true) {
            return undefined;
        }
        if (!isPrimitiveType(c.type)) return undefined;
        if (c.name === rowIndexColumnName || c.name === table.rowIDColumn) return undefined;
        if (isComputedColumn(c)) return undefined;

        const displayName = getTableColumnDisplayName(c);
        const internalName = c.name;
        const type = c.type.kind;

        return { displayName, internalName, type };
    });
}

function isValidIdentifier(str: string): boolean {
    // Check for empty string
    if (str === "") return false;

    // Check if the first character is a valid start character
    if (!/^[\p{L}_$]/u.test(str[0])) return false;

    // Check if the rest of the characters are valid identifier characters
    if (!/^[\p{L}\p{N}_$]*$/u.test(str.slice(1))) return false;

    // Check for reserved words
    const reservedWords = new Set([
        "await",
        "break",
        "case",
        "catch",
        "class",
        "const",
        "continue",
        "debugger",
        "default",
        "delete",
        "do",
        "else",
        "export",
        "extends",
        "finally",
        "for",
        "function",
        "if",
        "import",
        "in",
        "instanceof",
        "new",
        "return",
        "super",
        "switch",
        "this",
        "throw",
        "try",
        "typeof",
        "var",
        "void",
        "while",
        "with",
        "yield",
        "enum",
        "null",
        "true",
        "false",
        "NaN",
        "Infinity",
        "undefined",
    ]);

    return !reservedWords.has(str);
}

function columnNamesToObjectPropertyNames(columnNames: string[]): Record<string, string> {
    const seen = new Set<string>();
    const renamed: Record<string, string> = {};
    for (const name of columnNames) {
        let rename = name;

        // Make it a valid identifier
        rename = camelCase(rename.replace(/[^a-zA-Z0-9_$]/g, "_"));

        if (!isValidIdentifier(rename)) {
            rename = camelCase("the_" + rename);
        }

        // Avoid conflicts
        let n = 1;
        let unique = rename;
        while (seen.has(unique)) {
            unique = rename + n++;
        }
        seen.add(unique);
        renamed[name] = unique;
    }

    return renamed;
}

function tableToJavaScriptColumnSchema(table: TableGlideType) {
    const columns = makeColumnSchema(table);
    const displayNameToObjectProperty = columnNamesToObjectPropertyNames(columns.map(c => c.displayName));
    const lines = columns
        .map(({ displayName, internalName, type }, i) => {
            const safeProperty = displayNameToObjectProperty[displayName];
            const rhs = `{ type: ${JSON.stringify(type)}, name: ${JSON.stringify(internalName)} }`;
            const isLast = i === columns.length - 1;
            const suffix = isLast === true ? "" : ",";
            return `        ${safeProperty}: ${rhs}${suffix}`;
        })
        .join("\n");
    return `{\n${lines}\n    }`;
}

function rowIdentifier(table: TableGlideType): string {
    return camelCase(sheetNameForTable(table));
}

function rowsIdentifier(table: TableGlideType): string {
    let name = camelCase(sheetNameForTable(table));
    if (!name.endsWith("s")) {
        name += "s";
    }
    return name;
}

function tableIdentifier(table: TableGlideType): string {
    return rowIdentifier(table) + "Table";
}

function rowIDIdentifier(table: TableGlideType): string {
    return rowIdentifier(table) + "ID";
}

function tableDefinition(props: Props): string {
    return `const ${tableIdentifier(props.table)} = glide.table({
    token: ${JSON.stringify(props.apiKey)},
    app: ${JSON.stringify(props.appID)},
    table: ${JSON.stringify(getTableName(props.table).name)},
    columns: ${tableToJavaScriptColumnSchema(props.table)}
});`;
}

const examples: Examples = {
    ["Get rows"]: {
        title: "Get rows",
        render: props => {
            return `${preamble}

${tableDefinition(props)}

// getRows requires Business plan or above
const ${rowsIdentifier(props.table)} = await ${tableIdentifier(props.table)}.get();
`;
        },
    },
    [ActionKind.AddRowToTable]: {
        title: "Add rows",
        render: props => {
            return `${preamble}

${tableDefinition(props)}

const ${rowIDIdentifier(props.table)} = await ${tableIdentifier(props.table)}.add({
    // Add columns here
});
`;
        },
    },
    [ActionKind.SetColumnsInRow]: {
        title: "Edit rows",
        render: props => {
            return `${preamble}

${tableDefinition(props)}

await ${tableIdentifier(props.table)}.update(${rowIDIdentifier(props.table)}, {
    // Add columns here
});
`;
        },
    },
    [ActionKind.DeleteRow]: {
        title: "Delete rows",
        render: props => {
            return `${preamble}

${tableDefinition(props)}

await ${tableIdentifier(props.table)}.delete(rowID);
`;
        },
    },
};

export const javascript = { name: "JavaScript", syntax: "javascript", examples };
