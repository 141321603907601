import * as React from "react";
import { getAudioPlayer } from "./audio-player";
import { RecorderUI } from "./wire-voice-entry-recorder-ui";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { isResponseOK, logError } from "@glide/support";
import { transcribeAudioReponseBody } from "@glide/common-core/dist/js/firebase-function-types";
import { isLeft } from "fp-ts/lib/Either";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import "twin.macro";
import { useVoiceRecorderWithSilence } from "./use-voice-recorder-with-silence";

type FileTranscriptionResult = { error: true } | { error: false; text: string };

async function transcribeFile(appID: string, file: File): Promise<FileTranscriptionResult> {
    try {
        const formData = new FormData();
        formData.set("appID", appID);
        formData.set("audioFile", file);

        const appFacilities = getAppFacilities();
        const response = await appFacilities.callAuthCloudFunction("transcribeAudio", formData, undefined, false);

        if (!isResponseOK(response)) {
            logError("Couldn't transcribe audio");
            return { error: true };
        }

        const responseBody = await response.json();
        const decodedResponseBody = transcribeAudioReponseBody.decode(responseBody);

        if (isLeft(decodedResponseBody)) {
            logError("Couldn't decode transcription");
            return { error: true };
        }

        const { transcript } = decodedResponseBody.right;

        return { error: false, text: transcript };
    } catch (err: unknown) {
        logError(err);
        return { error: true };
    }
}

interface VoiceTranscriptProps {
    readonly onTranscribedText: (text: string) => void;
    readonly onTemporaryTranscription: (text: string) => void;
    readonly state: "idle" | "recording" | "processing" | "error";
    readonly setState: (newState: "idle" | "recording" | "processing" | "error") => void;
}

interface VoiceTranscriptControls {
    readonly onStartRecording: () => void;
    readonly onCancelRecording: () => void;
    readonly onFinishRecording: () => void;
    readonly isRecordingInProgress: boolean;
    readonly errorMessage: string;
    readonly getAudioData: () => Float32Array;
    readonly getFormattedTime: () => string;
}

export function useVoiceTranscriptControls(props: VoiceTranscriptProps): VoiceTranscriptControls {
    const { onTranscribedText, onTemporaryTranscription, state, setState } = props;

    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [isTranscribingPartial, setIsTranscribingPartial] = React.useState<boolean>(false);

    const startSound = React.useMemo(() => getAudioPlayer("/media/sound/start.mp3"), []);
    const endSound = React.useMemo(() => getAudioPlayer("/media/sound/end.mp3"), []);
    const lastTranscriptionFailedFile = React.useRef<File | null>(null);

    const appID = useAppID() ?? "";

    const transcribe = React.useCallback(
        async (file: File) => {
            setState("processing");
            const response = await transcribeFile(appID, file);

            const { error } = response;
            if (error) {
                setErrorMessage("Transcription failed");
                setState("error");
                lastTranscriptionFailedFile.current = file;
                return;
            }

            const { text } = response;
            onTranscribedText(text);

            setState("idle");
            lastTranscriptionFailedFile.current = null;
        },
        [appID, onTranscribedText, setState]
    );

    const transcribePartial = React.useCallback(
        async (file: File) => {
            if (isTranscribingPartial) return;

            setIsTranscribingPartial(true);
            const response = await transcribeFile(appID, file);

            if (!response.error) {
                const newText = response.text.trim();
                onTemporaryTranscription(newText);
            }

            setIsTranscribingPartial(false);
        },
        [appID, isTranscribingPartial, onTemporaryTranscription]
    );

    const onError = React.useCallback(
        (error: Error) => {
            setErrorMessage(error.message);
            setState("error");
        },
        [setState]
    );

    const recorderControls = useVoiceRecorderWithSilence({
        onRecordingComplete: transcribe,
        onError,
        onSilenceDetected: transcribePartial,
    });

    const { checkSupport, startRecording, stopRecording, getAudioData, getFormattedTime } = recorderControls;

    const onStartRecording = React.useCallback(async () => {
        setErrorMessage("");
        onTemporaryTranscription("");

        const supportCheck = checkSupport();
        if (!supportCheck.supported) {
            setErrorMessage(supportCheck.reason ?? "Your browser doesn't support audio recording");
            setState("error");
            return;
        }

        startSound.play();
        setState("recording");
        await startRecording();
    }, [onTemporaryTranscription, checkSupport, startSound, setState, startRecording]);

    const onCancelRecording = React.useCallback(async () => {
        endSound.play();
        stopRecording(true);
        setErrorMessage("");
        setState("idle");
    }, [endSound, setState, stopRecording]);

    const onSuccessRecording = React.useCallback(() => {
        endSound.play();

        if (state === "error") {
            setState("idle");
            setErrorMessage("");
            return;
        }

        stopRecording(false);
        setErrorMessage("");
    }, [endSound, state, stopRecording, setState]);

    const onErrorRecording = React.useCallback(() => {
        if (lastTranscriptionFailedFile.current) {
            setErrorMessage("");
            void transcribe(lastTranscriptionFailedFile.current);
        } else {
            setState("idle");
            setErrorMessage("");
        }
    }, [transcribe, setState]);

    const onFinishRecording = React.useCallback(() => {
        if (state === "error") {
            onErrorRecording();
        } else {
            onSuccessRecording();
        }
    }, [onErrorRecording, onSuccessRecording, state]);

    const isRecordingInProgress = recorderControls.state === "recording";

    const controls: VoiceTranscriptControls = React.useMemo(() => {
        return {
            onStartRecording,
            onCancelRecording,
            onFinishRecording,
            isRecordingInProgress,
            errorMessage,
            getAudioData,
            getFormattedTime,
        };
    }, [
        errorMessage,
        getAudioData,
        getFormattedTime,
        isRecordingInProgress,
        onCancelRecording,
        onFinishRecording,
        onStartRecording,
    ]);

    return controls;
}

interface RecordAndTranscribeComponentProps {
    readonly onTranscribedText: (text: string) => void;
    readonly startRecordingOnMount: boolean;
    readonly className?: string;
    readonly state: "idle" | "recording" | "processing" | "error";
    readonly setState: (newState: "idle" | "recording" | "processing" | "error") => void;
    readonly onTemporaryTranscription: (text: string) => void;
}

export const RecordAndTranscribeComponent: React.FC<RecordAndTranscribeComponentProps> = p => {
    const { onTranscribedText, startRecordingOnMount, className, state, setState, onTemporaryTranscription } = p;

    const {
        onStartRecording,
        onCancelRecording,
        onFinishRecording,
        isRecordingInProgress,
        errorMessage,
        getAudioData,
        getFormattedTime,
    } = useVoiceTranscriptControls({
        onTranscribedText,
        onTemporaryTranscription,
        state,
        setState,
    });

    return (
        <div tw="w-full p-1 px-3" className={className}>
            {errorMessage && state === "error" && <div tw="text-r500 text-sm mt-1 mb-2">{errorMessage}</div>}
            <RecorderUI
                state={state}
                onStartRecording={onStartRecording}
                onCancelRecording={onCancelRecording}
                onFinishRecording={onFinishRecording}
                startRecordingOnMount={startRecordingOnMount}
                isRecordingInProgress={isRecordingInProgress}
                getAudioData={getAudioData}
                getFormattedTime={getFormattedTime}
            />
        </div>
    );
};
