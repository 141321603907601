import { overlayYScrollbar } from "@glide/common-core/dist/js/shared-ui/style";
import { styled } from "@glide/common";

export const PushDropdownStyle = styled.div`
    position: relative;

    font-size: 12px;
    padding: 4px 0;
    border-radius: 6px;
    overflow: hidden;

    overflow-y: auto;
    ${overlayYScrollbar}

    box-shadow: ${p => p.theme.shadowDark1};

    min-width: 256px;

    --side-margin: 10px;

    background-color: ${p => p.theme.bgFront};

    max-height: calc(100vh - 40px);

    &.submenu {
        width: 256px;

        margin: -4px 0;
    }

    .previous {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    .spacer {
        flex-grow: 1;
    }

    .sep {
        height: 1px;
        margin: 1px 0px;
        background-color: ${p => p.theme.borderBase};
    }

    .search-input {
        height: 28px;
        border-radius: 8px;
        background-color: ${p => p.theme.n100A};
        color: ${p => p.theme.textDark};
        font-size: 13px;
        margin: 6px var(--side-margin) 4px;

        user-select: all;

        width: calc(100% - var(--side-margin) * 2);

        display: flex;
        align-items: center;
        padding-right: 8px;

        input {
            flex-grow: 1;
            padding: 0 0 0 12px;
            color: ${p => p.theme.textDark};

            ::placeholder {
                color: ${p => p.theme.textXpale};
            }
        }

        button {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;

            color: ${p => p.theme.n700};
            cursor: pointer;

            :hover {
                color: ${p => p.theme.n800};
            }
        }

        &.hide-search {
            position: absolute;
            opacity: 0;
            left: -9999px;
        }
    }

    .header {
        display: flex;

        margin-bottom: 4px;

        .back-button {
            color: ${p => p.theme.b400};
            font-weight: 600;

            margin-left: var(--side-margin);

            cursor: pointer;

            display: flex;
            align-items: center;

            > :first-child {
                margin-left: -2px;
                margin-right: 2px;
            }
        }

        .name {
            font-weight: 500;
        }

        .invisible {
            opacity: 0;
            font-weight: 600;

            flex-shrink: 1000000;
        }
    }

    @keyframes pushDropdownPrevOut {
        from {
            transform: translateX(0%);
        }
        to {
            transform: translateX(-100%);
        }
    }

    @keyframes pushDropdownNextIn {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0%);
        }
    }

    @keyframes pushDropdownPrevIn {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0%);
        }
    }

    @keyframes pushDropdownNextOut {
        from {
            transform: translateX(0%);
        }
        to {
            transform: translateX(100%);
        }
    }

    .prevOut {
        animation: pushDropdownPrevOut 0.2s ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .nextIn {
        animation: pushDropdownNextIn 0.2s ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .prevIn {
        animation: pushDropdownPrevIn 0.2s ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .nextOut {
        animation: pushDropdownNextOut 0.2s ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
`;
