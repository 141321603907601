import { type Row, Table, isLoadingValue, isBound } from "@glide/computation-model-types";
import { isRow } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ActionDescription,
    type PropertyDescription,
    ActionKind,
    MutatingScreenKind,
    PropertyKind,
    makeSourceColumnProperty,
} from "@glide/app-description";
import { isTableWritable, getTableName, sheetNameForTable } from "@glide/type-schema";
import {
    type AppDescriptionContext,
    type EditedColumnsAndTables,
    type PropertyDescriptor,
    thisRowSourceColumn,
    PropertySection,
    makeTableOrRelationPropertyDescriptor,
} from "@glide/function-utils";
import type {
    WireTableGetter,
    WireValueGetter,
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import { assert, filterUndefined, panic } from "@glideapps/ts-necessities";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import { getRelationForDescription } from "../description-utils";
import type { StaticActionContext } from "../static-context";
import { getCanEditFromNetworkStatus, makeRowGetter } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import type { DescriptionToken } from "./action-handler";
import { type AwaitSendDescription, getAwaitSendPropertyDescriptor, shouldAwaitSend } from "./await-send-property";
import { BaseActionHandler, getBillablesConsumedForCRUDActions } from "./base";
import { getSupportsUserProfileRowAccess, getTokenizedSourceColumn } from "./set-columns";
import type { BillablesConsumed } from "@glide/plugins-codecs";
import { ICON_BASE, LIME_500 } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins";

interface DeleteRowActionDescription extends ActionDescription, AwaitSendDescription {
    readonly kind: ActionKind.DeleteRow;
    readonly rowToDelete: PropertyDescription;
}

const getRelation = getRelationForDescription({
    inOutputRow: true,
    onlySingleRelations: false,
    defaultToThisRow: true,
    allowFullTable: true,
});

export class DeleteRowActionHandler extends BaseActionHandler<DeleteRowActionDescription> {
    public readonly kind = ActionKind.DeleteRow;
    public readonly iconName: GlideIconProps = {
        icon: "st-workflow-delete-row",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: LIME_500,
    };

    public readonly name = "Delete row";

    public getIsApplicable(screenHasInputContext: boolean, _appHasUserProfiles: boolean): boolean {
        return screenHasInputContext;
    }

    public getDescriptor(
        desc: DeleteRowActionDescription | undefined,
        env: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const { context: ccc, mutatingScreenKind } = env;

        const propertyDescriptors: PropertyDescriptor[] = [
            makeTableOrRelationPropertyDescriptor(
                mutatingScreenKind,
                "Rows",
                PropertySection.Data,
                {
                    // We don't allow full tables for the time being - it's
                    // too dangerous without further confirmation.
                    allowTables: false,
                    allowSingleRelations: true,
                    preferFullRow: true,
                    allowMultiRelations: true,
                    sourceIsDefaultCaption: false,
                    allowRewrite: false,
                    allowUserProfile: getSupportsUserProfileRowAccess(ccc),
                    allowQueryableTables: false,
                    allowUserProfileTableAndRow: false,
                    forWriting: true,
                },
                { name: "rowToDelete" },
                true
            ),
            ...getAwaitSendPropertyDescriptor(ccc),
        ];

        const tableAndColumn = getRelation(
            desc?.rowToDelete,
            env.context,
            env.tables,
            env.priorSteps?.map(s => s.node)
        );
        if (tableAndColumn !== undefined) {
            const { table: destTable, sourceColumn, isMulti } = tableAndColumn;
            if (isMulti) {
                const displayName = sheetNameForTable(destTable);
                const impact = sourceColumn !== undefined ? "can delete multiple rows" : "will delete all rows";
                propertyDescriptors.push({
                    kind: PropertyKind.Warning,
                    property: { name: "dummy" },
                    label: "Watch out!",
                    section: PropertySection.Data,
                    text: `This action ${impact} from table "${displayName}".  Deleting many rows at once can take long to process and may hold up other actions from being processed.`,
                });
            }
        }

        return {
            name: this.name,
            group: ActionGroup.Data,
            groupItemOrder: 2,
            needsScreenContext: true,
            properties: propertyDescriptors,
        };
    }

    public newActionDescription(_env: StaticActionContext<AppDescriptionContext>): DeleteRowActionDescription {
        return {
            kind: this.kind,
            rowToDelete: makeSourceColumnProperty(thisRowSourceColumn),
        };
    }

    public getEditedColumns(
        desc: DeleteRowActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): EditedColumnsAndTables | undefined {
        const tableAndColumn = getRelation(
            desc.rowToDelete,
            env.context,
            env.tables,
            env.priorSteps?.map(s => s.node)
        );
        if (tableAndColumn === undefined) return undefined;

        return {
            editedColumns: [],
            deletedTables: [getTableName(tableAndColumn.table)],
        };
    }

    public getTokenizedDescription(
        desc: DeleteRowActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        return getTokenizedSourceColumn(desc.rowToDelete, undefined, true, env);
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: DeleteRowActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { eminenceFlags },
        } = ib;

        const tableAndColumn = getRelation(desc.rowToDelete, ib.adc, ib.tables, ib.getActionNodesInScope());
        if (tableAndColumn === undefined) return arbBase.inflationError("Invalid row to delete");
        const { table: destTable, isMulti, isThisRow } = tableAndColumn;
        arbBase = arbBase.addData({ tableName: sheetNameForTable(destTable), isMulti });

        if (!isTableWritable(destTable)) return arbBase.inflationError("Table not writable");

        const deleteInEditScreen = isThisRow && ib.mutatingScreenKind === MutatingScreenKind.EditScreen;

        const awaitSend = shouldAwaitSend(ib.adc, desc);

        // Only one of these will be set, depending on whether we're multi or
        // not.
        let destRowGetter: WireValueGetter | undefined;
        let destTableGetter: WireTableGetter | undefined;

        if (deleteInEditScreen) {
            assert(!isMulti);

            destTableGetter = vp => {
                const destRows = vp.rowContext?.inputRows ?? [];
                assert(destRows.every(r => r.$isVisible));
                const destInvisibleRows = filterUndefined([vp.rowContext?.outputRow]);
                if (destInvisibleRows.length === 0) return new Table([]);
                return new Table([...destRows, ...destInvisibleRows]);
            };
        } else if (isMulti) {
            const maybeTableGetter = ib.getTableGetter(desc.rowToDelete, false);
            if (maybeTableGetter === undefined) return arbBase.inflationError("Invalid row to delete");

            const [tableGetter, table] = maybeTableGetter;
            assert(table === destTable);

            destTableGetter = tableGetter;
        } else {
            const destination = makeRowGetter(ib, desc.rowToDelete, { inOutputRow: true, defaultToThisRow: true });
            if (destination === undefined || destination === false)
                return arbBase.inflationError("Invalid row to delete");
            assert(destination.table === destTable);

            destRowGetter = destination.rowGetter;
        }

        return (vp, skipLoading) => {
            if (!getCanEditFromNetworkStatus(vp, eminenceFlags, MutatingScreenKind.EditScreen)) {
                return arbBase.offline();
            }

            let destRows: readonly Row[];

            if (destRowGetter !== undefined) {
                const destRow = destRowGetter(vp);
                if (isLoadingValue(destRow)) return arbBase.maybeSkipLoading(skipLoading, "Row to delete");
                if (!isBound(destRow) || !isRow(destRow)) return arbBase.error(true, "Invalid row to delete");
                destRows = [destRow];
            } else if (destTableGetter !== undefined) {
                const tableData = destTableGetter(vp);
                if (isLoadingValue(tableData)) return arbBase.maybeSkipLoading(skipLoading, "Table to delete");
                if (tableData === undefined) return arbBase.error(true, "Invalid table to delete");
                destRows = tableData.asArray();
            } else {
                return panic("Must have either row or table getter");
            }

            if (!deleteInEditScreen) {
                destRows = uniq(destRows.filter(r => r.$isVisible));
            }

            const arb = arbBase.addData({ rows: destRows.map(r => omit(r, ["$isVisible"])) });

            if (destRows.length === 0) return arb.nothingToDo("No rows to delete");

            return async ab => {
                const result = await ab.deleteRows(getTableName(destTable), destRows, awaitSend);
                return arb.fromResult(result);
            };
        };
    }

    public getBillablesConsumed(env: StaticActionContext<AppDescriptionContext>): BillablesConsumed | undefined {
        return getBillablesConsumedForCRUDActions(env);
    }
}
