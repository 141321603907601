import { ignore } from "@glide/support";
import React from "react";
import type { ComponentIndexes } from "@glide/common-core/dist/js/component-indexes";

interface SelectModeContext {
    isInSelectMode: boolean;
    setIsInSelectMode: React.Dispatch<React.SetStateAction<boolean>>;
    onComponentHovered: (id: string | undefined) => void;
    onComponentClicked: (id: ComponentIndexes) => void;
    selectedComponentIndexes: ComponentIndexes | undefined;
    isSelectedComponentInCurrentScreen: boolean;
}

const defaultContext: SelectModeContext = {
    isInSelectMode: false,
    setIsInSelectMode: ignore,
    onComponentHovered: ignore,
    onComponentClicked: ignore,
    selectedComponentIndexes: undefined,
    isSelectedComponentInCurrentScreen: false,
};

const SelectModeContextImpl = React.createContext<SelectModeContext>(defaultContext);

interface Props {
    onComponentHovered: (id: string | undefined) => void;
    onComponentClicked: (indexes: ComponentIndexes) => void;
    selectedComponentIndexes: ComponentIndexes | undefined;
    isSelectedComponentInCurrentScreen: boolean;
}

export const SelectModeProvider: React.FC<React.PropsWithChildren<Props>> = p => {
    const {
        children,
        onComponentHovered,
        onComponentClicked,
        selectedComponentIndexes,
        isSelectedComponentInCurrentScreen,
    } = p;

    const [isInSelectMode, setIsInSelectMode] = React.useState(false);

    const ctxValue: SelectModeContext = React.useMemo(() => {
        return {
            isInSelectMode,
            setIsInSelectMode,
            onComponentHovered,
            onComponentClicked,
            selectedComponentIndexes,
            isSelectedComponentInCurrentScreen,
        };
    }, [
        isInSelectMode,
        isSelectedComponentInCurrentScreen,
        onComponentClicked,
        onComponentHovered,
        selectedComponentIndexes,
    ]);

    return <SelectModeContextImpl.Provider value={ctxValue}>{children}</SelectModeContextImpl.Provider>;
};

export function useSelectMode(): SelectModeContext {
    return React.useContext(SelectModeContextImpl);
}
