import querystring from "querystring";

import type { ImageSource } from "@glide/support";
import { ImageSourceKind, MapLocationKind, getEmoji } from "@glide/support";

import { rewriteCloudStorage } from "../cloud-storage";
import { MAPBOX_KEY } from "../const";
import { geocodeAddress } from "./geocode";
import { imageURLHandlers } from "./image-handlers";
import type { ImageGravity } from "./image-types";
import type { ImageUrlOptions } from "./image-url-options";
import type { MinimalAppEnvironment } from "./types";
import { getFeatureSetting } from "../feature-settings";
import { generateFirestoreDocumentID } from "../id-generator";

export enum MapTheme {
    Light,
    Dark,
}

const googleDrivePrefix = "https://drive.google.com/uc?id=";

export function massageCloudURL(src: string, replaceHTTP: boolean = false): string {
    src = src.trim();

    if (replaceHTTP && src.toLowerCase().startsWith("http://")) {
        src = "https://" + src.substr(7);
    }

    return rewriteCloudStorage(googleSearchImageResultUrl(massageGoogleDriveURL(dropboxUrl(src))));
}

export function massageImageUrl(src: string | undefined, options: ImageUrlOptions, appID: string): string | undefined {
    if (src === undefined || options.width === 0 || options.height === 0) {
        return undefined;
    }
    const emoji = getEmoji(src);
    if (emoji !== undefined) {
        return emoji;
    }

    // Leave data: URIs alone; we can work with them directly.
    if (src.startsWith("data:") || src.startsWith("blob:") || src.startsWith("glide:")) return src;

    src = massageCloudURL(src, false);

    if (src.indexOf("://") === -1) return undefined;

    // ok look this is abusive but if we pass a random ID to the getFeatureSetting function we can get a random sampling
    // instead of having it be always on/off for users
    const randomId = generateFirestoreDocumentID();
    if (getFeatureSetting("checkImage", randomId)) {
        try {
            void fetch(`https://img-check.glideapps.com/check/${appID}/${encodeURIComponent(src)}`, {
                method: "GET",
                mode: "no-cors",
            });
        } catch {
            // ignore errors
        }
    }

    for (const h of imageURLHandlers) {
        const r = h.handle(src, options, appID);
        if (r !== undefined) return r;
    }

    return src;
}

export function firstImageURL(src: ImageSource, options: ImageUrlOptions, appID: string): string | undefined {
    if (src.kind === ImageSourceKind.URLs && src.urls.length > 0) {
        return massageImageUrl(src.urls[0], options, appID);
    }
    return undefined;
}

const DROPBOX_FILE_REGEX = /dropbox.com\/s\/([^/]+)/;

function dropboxUrl(url: string): string {
    const match = DROPBOX_FILE_REGEX.exec(url);
    if (match !== null) {
        return `https://www.dropbox.com/s/${match[1]}?raw=1`;
    }
    return url;
}

// This will match strings like these:
//     https://drive.google.com/file/d/16HxoCwKjDzO3xxo2IiiEkgY1PkzEDM0v/view
//     https://drive.google.com/a/kpf.co.za/file/d/16HxoCwKjDzO3xxo2IiiEkgY1PkzEDM0v/view?usp=sharing
const GDRIVE_FILE_REGEX = /drive\.google\.com(\/a\/[^/]+)?\/file\/d\/([^/]+)/;

// This will match strings like these:
//     https://drive.google.com/open?id=1ggFIp7pKfxWPgtO6e2m6cLdgYLE7KVga
//     http://drive.google.com/uc?export=view&id=1ggFIp7pKfxWPgtO6e2m6cLdgYLE7KVga
//     https://drive.google.com/open?id=1ggFIp7pKfxWPgtO6e2m6cLdgYLE7KVga
//     https://drive.google.com/uc?id=1ggFIp7pKfxWPgtO6e2m6cLdgYLE7KVga
const GDRIVE_OPEN_REGEX = /drive\.google\.com\/(open|uc)\?(.+&)?id=([^/]+)/;

function makeGoogleDriveURL(id: string): string {
    return `${googleDrivePrefix}${id}`;
}

export function massageGoogleDriveURL(url: string): string {
    let match = GDRIVE_FILE_REGEX.exec(url);
    if (match !== null) {
        return makeGoogleDriveURL(match[2]);
    }

    match = GDRIVE_OPEN_REGEX.exec(url);
    if (match !== null) {
        return makeGoogleDriveURL(match[3]);
    }

    return url;
}

const GSEARCH_IMAGE_RESULT = /www.google.(.+)\/imgres?(.+)/;

// Google Search image results have the image URL embedded as a query parameter
// that we need to extract before displaying the image, otherwise it doesn't work.
function googleSearchImageResultUrl(rawUrl: string): string {
    const match = GSEARCH_IMAGE_RESULT.exec(rawUrl);
    if (match === null) return rawUrl;

    const query = querystring.parse(match[2]);
    if (typeof query.imgurl !== "string") return rawUrl;

    return query.imgurl;
}

export async function mapUrl(
    appEnvironment: MinimalAppEnvironment,
    src: ImageSource,
    width: number,
    height: number,
    zoom: number,
    mapTheme: MapTheme
): Promise<string | undefined> {
    if (src.kind === ImageSourceKind.URLs || width === undefined || width <= 0 || height === undefined || height <= 0) {
        return undefined;
    }

    // FIXME: This is a little messy. Handle the case when selecting a map from address type in tiles
    // But the address url is a generated image. The fix. Short circuit and return the generated image url. A better
    // fix would be to do validation on the dropdown in the builder and only allow selecting Map from Address
    // if the source column is not a glide url
    if (src.location.kind === MapLocationKind.Address && src.location.address.startsWith("glide:")) {
        return src.location.address;
    }

    let lat: number;
    let lng: number;
    if (src.location.kind !== MapLocationKind.LatLong) {
        // Because this is a "static map" we're not counting it against the geocode quota.
        const r = await geocodeAddress(appEnvironment, src.location.address, undefined);
        if (r === undefined) return undefined;
        lat = r.lat;
        lng = r.lng;
    } else {
        lat = src.location.latitude;
        lng = src.location.longitude;
    }
    width = Math.round(width);
    height = Math.round(height);
    const base = "https://api.mapbox.com/styles/v1/";
    const theme =
        mapTheme === MapTheme.Light ? "jassmith87/cjyhqzqit14fs1cmjwogd33l2/" : "jassmith87/cjyjgyfao1jcy1cqv858a8ruc/";
    const method = "static/";
    const pinImageURL = "https://res.cloudinary.com/glide/image/upload/v1572388650/glidehq/glide-pin.png";
    const overlay = `url-${encodeURIComponent(pinImageURL)}(${lng},${lat})/`;
    const location = `${lng},${lat},`;
    const zoomBearingPitch = `${zoom},0,0/`;
    const size = `${width}x${height}@2x`;
    const query = `?access_token=${MAPBOX_KEY}`;

    return base + theme + method + overlay + location + zoomBearingPitch + size + query;
}

export function canMakeUrl(src: ImageSource | undefined) {
    if (src === undefined) return false;
    return src.kind !== ImageSourceKind.URLs || src.urls.length > 0;
}

export async function imageSourceUrl(
    appEnvironment: MinimalAppEnvironment,
    src: ImageSource,
    width: number,
    height: number,
    zoom: number,
    mapTheme: MapTheme,
    gravity?: ImageGravity
): Promise<string | undefined> {
    if (src.kind === ImageSourceKind.URLs) {
        return firstImageURL(
            src,
            { thumbnail: height <= 75 && width <= 75, width, height, gravity },
            appEnvironment.appID
        );
    }
    return mapUrl(appEnvironment, src, width, height, zoom, mapTheme);
}

export type DropImageHandler = (img: string) => void;
