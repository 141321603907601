import "@glideapps/glide-data-grid/dist/index.css";
import "twin.macro";

import { GlideIcon } from "@glide/common";
import type { GridCell, GridColumn, Item } from "@glideapps/glide-data-grid";
import { DataEditor, GridCellKind } from "@glideapps/glide-data-grid";
import { addDays, format } from "date-fns";
import orderBy from "lodash/orderBy";
import range from "lodash/range";
import React, { useState } from "react";

import Breadcrumb from "../breadcrumb/breadcrumb";
import { Callout } from "../callout";
import SectionTabBar from "../section-tab-bar/section-tab-bar";
import SwitchToggle from "../switch-toggle/switch-toggle";
import { UsageGraph } from "./usage-graph";

export type OrgLoginLogData = {
    appID: string;
    authenticationMethod: string;
    appUserEmail: string;
    timestamp: number;
};

type KindFilter = "All" | "Private" | "Public";

interface Props extends React.PropsWithChildren {
    orgLogins: readonly OrgLoginLogData[];
    loading: boolean;
    orgName: string;
    getTitleForAppID: (appID: string) => string;
}

const kindFilters = ["All", "Private", "Public"] as const;

export const AnalyticsView: React.FC<React.PropsWithChildren<Props>> = p => {
    const { orgLogins, loading, getTitleForAppID, orgName } = p;
    const [kindFilter, setKindFilter] = useState<KindFilter>("All");

    const effectiveLogins = React.useMemo(() => {
        if (kindFilter === "All") return orgLogins;
        else if (kindFilter === "Private") return orgLogins.filter(l => l.authenticationMethod === "private");
        return orgLogins.filter(l => l.authenticationMethod !== "private");
    }, [kindFilter, orgLogins]);

    const columns = React.useMemo<readonly GridColumn[]>(() => {
        return [
            {
                title: "App",
                id: "app",
                grow: 1,
            },
            {
                title: "Email",
                id: "email",
                grow: 2,
            },
            {
                title: "Kind",
                id: "authentication",
                grow: 1,
            },
            {
                title: "Session Start",
                id: "login",
                grow: 4,
            },
        ];
    }, []);

    const getCellContent = React.useCallback(
        (cell: Item): GridCell => {
            const [col, row] = cell;

            const rowData = effectiveLogins[row];
            if (rowData !== undefined) {
                let data = "";
                if (col === 0) {
                    data = getTitleForAppID(rowData.appID);
                } else if (col === 1) {
                    data = rowData.appUserEmail;
                } else if (col === 2) {
                    data = rowData.authenticationMethod;
                } else if (col === 3) {
                    data = new Date(rowData.timestamp).toLocaleString();
                }
                return {
                    kind: GridCellKind.Text,
                    allowOverlay: false,
                    data,
                    displayData: data,
                };
            }

            return {
                kind: GridCellKind.Loading,
                allowOverlay: false,
            };
        },
        [effectiveLogins, getTitleForAppID]
    );

    const dataGrid =
        effectiveLogins.length <= 0 ? null : (
            <DataEditor
                width="100%"
                height="100%"
                columns={columns}
                smoothScrollY={true}
                getCellContent={getCellContent}
                getCellsForSelection={true}
                maxColumnWidth={1000}
                keybindings={{
                    search: true,
                }}
                rowMarkers={"number"}
                rows={effectiveLogins.length}
            />
        );

    const [showTopFive, setShowTopFive] = React.useState(true);
    const graphData = React.useMemo(() => {
        let apps = orderBy(
            [...new Set<string>(effectiveLogins.map(x => x.appID))],
            x => effectiveLogins.filter(log => log.appID === x).length
        ).reverse();

        if (showTopFive) {
            apps = apps.slice(0, 5);
        }

        return range(30, -1).map(offset => {
            const day = addDays(new Date(), -offset);
            const data: any = {
                dateTime: format(day, "LLL d"),
            };
            for (const appID of apps) {
                const appTitle = getTitleForAppID(appID);
                data[appTitle] = effectiveLogins.filter(
                    l => l.appID === appID && new Date(l.timestamp).getDate() === day.getDate()
                ).length;
            }

            return data;
        });
    }, [effectiveLogins, getTitleForAppID, showTopFive]);

    return (
        <div tw="absolute left-0 right-0 top-0 h-full w-full [padding:24px 24px 64px] mb-8 flex flex-col [max-width:1230px]">
            <Breadcrumb items={[orgName, "Analytics"]} currentItem="Analytics" size={26} />

            <Callout tw="mt-4" mood="warning">
                This view is still experimental.
            </Callout>

            <div tw="mt-4 w-full rounded-lg shadow-md-dark px-6 pt-4 pb-6 bg-bg-front">
                <div tw="pb-3 font-extrabold text-builder-2xl text-n900 flex">
                    <p>30-day Session Overview</p>
                    <div tw="flex-grow" />
                    <label tw="flex items-center cursor-pointer" onClick={() => setShowTopFive(cv => !cv)}>
                        <span tw="mr-2 font-normal text-builder-base">Top apps only</span>
                        <SwitchToggle size="sm" toggled={showTopFive} onChange={checked => setShowTopFive(checked)} />
                    </label>
                </div>
                <UsageGraph loginLogs={graphData} />
            </div>

            <div tw="mt-4 h-full">
                {!loading ? (
                    <div tw="flex flex-col rounded-lg overflow-hidden bg-bg-front shadow-md-dark h-full">
                        <div
                            tw="px-6 pt-4 pb-3 font-extrabold text-builder-2xl text-n900 border-b border-border-base
                                flex items-center">
                            <p>Session Logs</p>
                            <div tw="flex-grow" />
                            <SectionTabBar tw="w-48" items={kindFilters} value={kindFilter} onChange={setKindFilter} />
                        </div>
                        <div tw="flex-grow">{dataGrid}</div>
                    </div>
                ) : (
                    <div tw="flex justify-center">
                        <GlideIcon tw="m-0.5 text-n900" kind="stroke" icon="st-half-spinner" iconSize={24} spin />
                    </div>
                )}
            </div>
        </div>
    );
};
