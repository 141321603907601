import type { WireContainerComponent } from "@glide/fluent-components/dist/js/fluent-components-containers";
import { isSmallScreen, useResponsiveSizeClass } from "@glide/common-components";
import type { WireComponent, WireScreen } from "@glide/wire";
import { WireComponentKind } from "@glide/wire";

import { getRenderableComponentIndexAtPosition } from "./get-renderable-component-for-possition";

export function nullifyUnnecesaryMobileBreadcrumb(
    components: readonly (WireComponent | null)[],
    isMobile: boolean
): readonly (WireComponent | null)[] {
    if (!isMobile) return components;

    const firstRenderableComponentIndex = getRenderableComponentIndexAtPosition(components, 0) ?? 0;
    const untypedComponent = components[firstRenderableComponentIndex];

    if (untypedComponent?.kind === WireComponentKind.Breadcrumbs) {
        const componentsWithFirstBreadcrumbNull = components.map((component, index) =>
            index === firstRenderableComponentIndex ? null : component
        );
        return componentsWithFirstBreadcrumbNull;
    }

    if (untypedComponent?.kind === WireComponentKind.Container) {
        const component = untypedComponent as WireContainerComponent;
        const componentChildren = [...component.components];
        const firstRenderableChildrenComponentIndex = getRenderableComponentIndexAtPosition(componentChildren, 0) ?? 0;
        if (componentChildren[firstRenderableChildrenComponentIndex]?.kind === WireComponentKind.Breadcrumbs) {
            const containerWithoutFirstBreadcrumb = {
                ...component,
                components: componentChildren.map((componentChild, index) =>
                    index === firstRenderableChildrenComponentIndex ? null : componentChild
                ),
            };

            const componentsWithContainerWithoutFirstBreadcrumb = components.map((comp, index) =>
                index === firstRenderableComponentIndex ? containerWithoutFirstBreadcrumb : comp
            );
            return componentsWithContainerWithoutFirstBreadcrumb;
        }
    }

    return components;
}

export const useScreenComponents = (screen: WireScreen) => {
    const sizeClass = useResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    return nullifyUnnecesaryMobileBreadcrumb(screen.components, isMobile);
};
