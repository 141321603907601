import { styled } from "@glide/common";
import * as React from "react";

import { SplitContainer, TwoByOneContainer } from "../builder/components/split-container/split-container";
import { BylineWrapper, Warning } from "../components/input-label/input-label-style";
import PropertyTitle from "../components/property-title/property-title";
import { MDTooltip } from "../components/tooltip/md-tooltip";

type WithLabelLayoutType = "split" | "stacked" | "2by1";

export interface WithLabelProps {
    label?: string;
    layout?: WithLabelLayoutType;
    byline?: React.ReactNode;
    warning?: React.ReactNode;
    className?: string;
    margin?: number;
    helpText?: string;
    align?: "baseline" | "center";
}

const Wrapper = styled.div<{ underline?: boolean; margin: number }>`
    margin: ${p => p.margin}px 0;

    ${p =>
        p.underline === true &&
        `
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);`}

    & > :last-child {
        margin: 0;
    }
`;

const Label = styled.div`
    color: ${p => p.theme.fgColorMedium};
    margin-bottom: 4px;
`;

const LabelSplitContainer = styled(SplitContainer)<{ align: WithLabelProps["align"] }>`
    margin: 8px 0;
    align-items: ${p => p.align};
`;

export const withLabelAndByline = <P extends object>(
    Component: React.ComponentType<P>,
    layout: WithLabelLayoutType | ((props: P) => WithLabelLayoutType),
    onClickLabel?: (props: P) => void,
    align?: WithLabelProps["align"]
) =>
    class WithLabel extends React.Component<WithLabelProps & P> {
        public render() {
            const { label, className, margin, byline, warning, helpText, ...rest } = this.props;

            // Layout prop should override layout argument
            let withLabelLayoutType = this.props.layout !== undefined ? this.props.layout : layout;

            if (typeof withLabelLayoutType === "function") {
                withLabelLayoutType = withLabelLayoutType(this.props);
            }

            let renderByline: React.ReactNode = byline;
            if (warning !== undefined) {
                renderByline = <Warning>{warning}</Warning>;
            }

            const onClick = onClickLabel ? () => onClickLabel(this.props) : undefined;

            if (withLabelLayoutType === "stacked" || label === undefined) {
                return (
                    <>
                        <Wrapper margin={(margin as number | undefined) ?? 16} className={className}>
                            {label !== undefined && <Label onClick={onClick}>{label}</Label>}
                            <Component {...(rest as P)} />
                        </Wrapper>
                        {renderByline !== undefined && <BylineWrapper>{renderByline}</BylineWrapper>}
                    </>
                );
            } else if (withLabelLayoutType === "split") {
                return (
                    <>
                        <LabelSplitContainer align={align ?? "baseline"}>
                            <div tw="flex items-center">
                                <PropertyTitle title={label as string} onClick={onClick} />
                                {helpText && <MDTooltip content={helpText} />}
                            </div>
                            <div>
                                <Component {...(rest as P)} />
                                {renderByline !== undefined && <BylineWrapper>{renderByline}</BylineWrapper>}
                            </div>
                        </LabelSplitContainer>
                    </>
                );
            } else {
                return (
                    <>
                        <TwoByOneContainer>
                            <PropertyTitle title={label as string} onClick={onClick} />
                            <Component {...(rest as P)} />
                        </TwoByOneContainer>
                        {renderByline !== undefined && <BylineWrapper>{renderByline}</BylineWrapper>}
                    </>
                );
            }
        }
    };
