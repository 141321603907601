import type { WireContainerComponent } from "@glide/fluent-components/dist/js/fluent-components-containers";
import type { WireComponent } from "@glide/wire";
import { WireComponentKind } from "@glide/wire";

import { getRenderableComponentIndexAtPosition } from "./get-renderable-component-for-possition";

// This is a helper function that is used to determine if the breadcrumbs should be rendered in a portal
// We need this for the new title component
export function shouldBreadcrumbsPortal(components: readonly (WireComponent | null)[]) {
    const firstRenderableComponentIndex = getRenderableComponentIndexAtPosition(components, 0);
    if (firstRenderableComponentIndex === null) {
        return false;
    }

    const untypedComponent = components[firstRenderableComponentIndex];

    if (untypedComponent?.kind === WireComponentKind.Breadcrumbs) {
        const secondRenderableComponentIndex = getRenderableComponentIndexAtPosition(components, 1);
        if (secondRenderableComponentIndex === null) {
            return false;
        }
        const untypedSecondComponent = components[secondRenderableComponentIndex];
        const isSecodComponentHero = untypedSecondComponent?.kind === WireComponentKind.Hero;

        if (isSecodComponentHero) {
            return true;
        }

        const isSecondComponentContiner = untypedSecondComponent?.kind === WireComponentKind.Container;

        if (isSecondComponentContiner) {
            const containerComponent = (untypedSecondComponent as WireContainerComponent).components;
            const firstRenderableChildrenIndex = getRenderableComponentIndexAtPosition(containerComponent, 0);

            if (firstRenderableChildrenIndex === null) {
                return false;
            }

            const isFirstChildHero =
                (untypedSecondComponent as WireContainerComponent).components[firstRenderableChildrenIndex]?.kind ===
                WireComponentKind.Hero;

            return isFirstChildHero;
        }
    }

    if (untypedComponent?.kind === WireComponentKind.Container) {
        const component = untypedComponent as WireContainerComponent;
        const componentChildren = component.components;
        const firstRenderableChildComponentIndex = getRenderableComponentIndexAtPosition(componentChildren, 0);
        const secondRenderableChildComponentIndex = getRenderableComponentIndexAtPosition(componentChildren, 1);

        if (firstRenderableChildComponentIndex === null || secondRenderableChildComponentIndex === null) {
            return false;
        }

        const firstChildComponentIsBreadcrumbs =
            componentChildren[firstRenderableChildComponentIndex]?.kind === WireComponentKind.Breadcrumbs;
        const secondChildComponentIsTitle =
            componentChildren[secondRenderableChildComponentIndex]?.kind === WireComponentKind.Hero;

        return firstChildComponentIsBreadcrumbs && secondChildComponentIsTitle;
    }

    return false;
}
