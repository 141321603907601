import { ActionKind } from "@glide/app-description";
import type { AppDescriptionContext, ActionAvailability } from "@glide/function-utils";
import type {
    WireActionHydrator,
    WireActionInflationBackend,
    WireActionResult,
    WireActionResultBuilder,
} from "@glide/wire";
import type { StaticActionContext } from "../static-context";
import { inflateStringProperty } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import {
    type OpenWebViewActionDescription,
    hydrateOpenWebView,
    makeLinkColumnPropertyDescriptor,
    webViewAppearancePropertyHandler,
} from "./open-link";

export class OpenWebViewActionHandler extends BaseActionHandler<OpenWebViewActionDescription> {
    public readonly kind = ActionKind.OpenWebView;

    // FIXME: Icon
    public readonly iconName = "link";

    public readonly name = "Open link in Web view";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: OpenWebViewActionDescription | undefined,
        { mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 10,
            needsScreenContext: true,
            isLegacy: true,
            properties: [makeLinkColumnPropertyDescriptor(mutatingScreenKind), webViewAppearancePropertyHandler],
        };
    }

    public getTokenizedDescription(
        desc: OpenWebViewActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const token = tokenForProperty(desc.link, env);
        if (token === undefined) return undefined;
        return [token];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: OpenWebViewActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const [urlGetter, urlType] = inflateStringProperty(ib, desc.link, false);
        if (urlType === undefined) return arb.inflationError("Invalid URL");

        const appearance = webViewAppearancePropertyHandler.getEnum(desc);

        return vp => {
            const url = urlGetter(vp) ?? "";
            return hydrateOpenWebView(arb.addData({ link: url, appearance }), url, appearance);
        };
    }
}
