import type {
    DataSnapshotLoader,
    NativeTableSnapshot,
    TableSnapshot,
} from "@glide/common-core/dist/js/components/types";
import {
    type DataSnapshot,
    type DocumentDataWithID,
    type Query,
    isDataSnapshot,
    makeAppUserIDQuery,
    makePrivateRowsQuery,
    makeRowVersionQuery,
    makeTableDataFromDocument,
} from "@glide/common-core/dist/js/Database";
import {
    documentIDForTableName,
    makePrivateRowsPath,
    makePublicRowsPath,
    makeTablesPath,
    makeUserRowsPath,
    rowVersionFieldName,
} from "@glide/common-core/dist/js/database-strings";
import type { PollingOptions } from "@glide/common-core/dist/js/Database/core";
import { type TableGlideType, getTableName, tableHasRowOwners, isTableNameForNativeTable } from "@glide/type-schema";
import { getFeatureSetting, getFeatureSettingProbability } from "@glide/common-core/dist/js/feature-settings";
import { frontendSendEvent } from "@glide/common-core/dist/js/tracing";
import { assert, defined, panic } from "@glideapps/ts-necessities";
import { logInfo } from "@glide/support";
import type { DurableStorageController } from "./durable-storage-controller";
import type { DocumentsFirestoreHandler, MakeFirestoreTableListener } from "./types";

const pollingThresholdNumRows = 15_000;

// Initial: The initial state.  The table isn't being listened to, so we're
//   waiting for the table being listened to.
//
// WaitingToFetchRows: We're waiting for the snapshot response, but the table
//   is now being listened to.
//
// GotSnapshotResponse: We have the snapshot response, but the table is not
//   being listened to.
//
// Listening: We have the snapshot response, and the table is being listened
//   to.

// Transitions:
//
// Initial:
//   fetch requested -> WaitingToFetchRows
// WaitingToFetchRows:
//   snapshot is loaded -> Listening
//   snapshot is unavailable -> Listening
//   fetch requested -> WaitingToFetchRows
// GotSnapshotResponse:
//   fetch requested -> Listening
// Listening:
//   fetch requested -> Listening
//   unlisten -> GotSnapshotResponse

enum State {
    Initial,
    WaitingToFetchRows,
    GotSnapshotResponse,
    Listening,
}

export abstract class FirestoreTableListener {
    protected state: State;

    private _unlistens: (() => void)[] = [];
    private _userSpecificUnlistens: (() => void)[] | undefined;

    constructor(
        protected readonly handler: DocumentsFirestoreHandler,
        protected readonly snapshotLoader: DataSnapshotLoader,
        private readonly _getTable: () => TableGlideType | undefined,
        protected readonly isBuilder: boolean,
        private readonly _getNumRowsUsedInApp: () => number | undefined,
        private readonly _durableStorageController: DurableStorageController
    ) {
        this.state = State.Initial;
    }

    protected get table(): TableGlideType | undefined {
        return this._getTable();
    }

    private get appUserID(): string | undefined {
        return this.handler.appUserDataObservable.current.appUserID;
    }

    protected abstract updateWithSnapshot(force: boolean): void;

    protected abstract getSnapshotRowCount(): number | undefined;

    protected abstract getSnapshotDataVersion(): number | undefined;

    // This is called the first time we transition to `WaitingToFetchRows`.
    protected async waitingToFetchRows(_table: TableGlideType): Promise<void> {
        return;
    }

    // This will go the `Listening` state if we have a database, otherwise go
    // to `WaitingForDatabase` and wait for the database.
    private switchToListening(): void {
        assert(this.state !== State.Listening);

        this.state = State.Listening;
        this.startListening(true);
    }

    private getPollingThresholdNumRows() {
        return pollingThresholdNumRows * getFeatureSettingProbability("firestorePollingListener");
    }

    public listenToTable(resendSnapshot: boolean): void {
        if (resendSnapshot) {
            this.updateWithSnapshot(true);
        }

        switch (this.state) {
            case State.Initial: {
                const { table } = this;
                // If we don't have a table yet because of initialization
                // order then we can't listen yet, so we wait to be called
                // again.
                if (table === undefined) return;

                this.state = State.WaitingToFetchRows;
                void this.waitingToFetchRows?.(table);
                break;
            }

            case State.GotSnapshotResponse:
                this.switchToListening();
                break;

            case State.WaitingToFetchRows:
                break;

            case State.Listening:
                this.startListening(false);
                break;

            default:
                return panic("Unexpected transition");
        }
    }

    protected gotSnapshotResponse(gotSnapshot: boolean): void {
        if (gotSnapshot) {
            this.updateWithSnapshot(false);
        }

        switch (this.state) {
            case State.WaitingToFetchRows:
                this.switchToListening();
                break;

            default:
                return panic("Unexpected transition");
        }
    }

    // Private rows don't use snapshots, so the row version is not included in
    // the query.
    private getQueriesForPrivateRows(): readonly Query[] {
        if (this.isBuilder) {
            const snapshotDataVersion = this.getSnapshotDataVersion();
            if (snapshotDataVersion !== undefined) {
                return [makeRowVersionQuery(snapshotDataVersion)];
            } else {
                return [];
            }
        } else {
            return [makePrivateRowsQuery(defined(this.appUserID), this.handler.appUserDataObservable.current.roles)];
        }
    }

    protected startListening(firstListen: boolean): void {
        assert(this.state === State.Listening);

        const {
            table,
            handler: { database },
        } = this;
        if (table === undefined || database === undefined) return;

        const tableName = getTableName(table);
        const documentID = documentIDForTableName(tableName);
        const tablesPath = makeTablesPath(this.handler.appID);

        // logInfo("listening to full", tableName.name, firstListen);

        // We don't sort by row index because that would leave out documents that don't
        // have a row index set yet.
        // FIXME: This should never be the case that documents don't have row indexes.
        // We can safely sort now.

        // Rows from tables may take a while to load on the first listen.
        // We want to establish a network connection lock, but only for the
        // initial listen.
        function withInitialTableNetworkLock(fn: (unlock: () => void) => () => void): () => void {
            let tableNetworkLock: Promise<void> | undefined = defined(database).enterNetworkConnectionLock();

            const exitLock = () => {
                if (tableNetworkLock !== undefined) {
                    void tableNetworkLock.then(() => defined(database).exitNetworkConnectionLock());
                    tableNetworkLock = undefined;
                }
            };

            const unlisten = fn(exitLock);
            return () => {
                exitLock();
                unlisten();
            };
        }

        // Firestore falls over if a listen request results in too many rows.
        //  Having snapshots mitigates this; we only need the rows since the last snapshot.
        //  However, we can still run into issues in a couple of cases:
        //    a) There are no snapshots. They weren't created yet, or snapshot creation failed,
        //       or it's a table with row owners.
        //    b) There are too many changes since the last snapshot.
        // We can't really know if some massive change will put us in this situation, so we use a
        // paging poll instead of listening.
        // For now, we'll poll
        //    - if the feature setting is set (which it is on staging), OR
        //    - if we're on backend NCM, OR
        //    - if the number of rows used in the app is above a certain threshold
        let polling: PollingOptions | undefined;
        const pollingRowsThreshold = this.getPollingThresholdNumRows();
        if ((this._getNumRowsUsedInApp() ?? 0) >= pollingRowsThreshold) {
            polling = {
                makeRowVersionQuery: v => {
                    return [makeRowVersionQuery(v), rowVersionFieldName];
                },
                getVersionFromData: data => data[rowVersionFieldName],
            };
        }

        if (firstListen) {
            const publicPath = makePublicRowsPath(tablesPath, documentID);

            const queries: Query[] = [];

            // If the table isn't included in the snapshot (that's the case
            // for native tables) then we don't query for row version.
            const snapshotDataVersion = this.getSnapshotDataVersion();
            if (polling !== undefined) {
                polling.initialVersion = snapshotDataVersion;
            } else if (snapshotDataVersion !== undefined) {
                queries.push(makeRowVersionQuery(snapshotDataVersion));
            }
            logInfo("querying", this.table, queries);

            // It's possible that this query fails due to missing permissions.
            // In that case we want to treat it as if no rows were loaded, hence
            // the `onError` handler.  If we didn't do that, the user profile
            // row might forever be in a loading state, which would lead to
            // ##tabVisibility issues.

            const publicUnlisten = withInitialTableNetworkLock(unlock =>
                database.listenDiffWhere(
                    publicPath,
                    queries,
                    undefined,
                    undefined,
                    (results, durationMs) => {
                        try {
                            this.handler.updateWithDiffResults(tableName, results, false);
                            if (polling !== undefined) {
                                frontendSendEvent("didListenWithPoll", 0, {
                                    app_id: this.handler.appID,
                                    num_rows_returned: results.length,
                                    num_rows_used_in_app: this._getNumRowsUsedInApp(),
                                    duration: durationMs,
                                });
                            }
                        } finally {
                            unlock();
                        }
                    },
                    () => {
                        try {
                            this.handler.updateWithDiffResults(tableName, [], false);
                        } finally {
                            unlock();
                        }
                    },
                    polling
                )
            );

            this._unlistens.push(publicUnlisten);

            // Table data is a single document so we don't need to establish a
            // network lock for it. It should be fast enough to pick up.
            const tableDataUnlisten = database.listenToDocument(tablesPath, documentID, doc => {
                if (doc === undefined) return;

                const data = makeTableDataFromDocument(doc);
                // Old table documents don't have version numbers.  For those
                // we assume that they're the latest ones vs any row versions,
                // therefore the max integer.
                const version = data.version ?? Number.MAX_SAFE_INTEGER;
                this.handler.setDeletedRowIndexes(tableName, data.deletedRowIndexes, version);
                for (const rowIndex of data.deletedRowIndexes) {
                    this._durableStorageController.handleDocumentDeletion(tableName, rowIndex, version);
                }
            });
            this._unlistens.push(tableDataUnlisten);
        }

        if (this._userSpecificUnlistens === undefined) {
            // When we're in the builder it's possible that we don't have an app
            // user ID (yet), but we must still load the private rows.  This is
            // particularly the case when we're support or admin, in which case
            // the backend might not give us an app user ID.
            if (tableHasRowOwners(table) && (this.appUserID !== undefined || this.isBuilder)) {
                const queries = this.getQueriesForPrivateRows();
                logInfo("listening to private rows", tableName.name, this.appUserID, queries);

                const privatePath = makePrivateRowsPath(tablesPath, documentID);
                const privateUnlisten = withInitialTableNetworkLock(unlock =>
                    database.listenDiffWhere(
                        privatePath,
                        queries,
                        undefined,
                        undefined,
                        (results, durationMs) => {
                            try {
                                this.handler.updateWithDiffResults(tableName, results, true);
                                if (polling !== undefined) {
                                    frontendSendEvent("didListenWithPoll", 0, {
                                        app_id: this.handler.appID,
                                        num_rows_returned: results.length,
                                        num_rows_used_in_app: this._getNumRowsUsedInApp(),
                                        duration: durationMs,
                                    });
                                }
                            } finally {
                                unlock();
                            }
                        },
                        () => {
                            try {
                                this.handler.updateWithDiffResults(tableName, [], true);
                            } finally {
                                unlock();
                            }
                        },
                        polling
                    )
                );

                this._userSpecificUnlistens = [privateUnlisten];
            } else if (tableHasRowOwners(table)) {
                // It's possible to have a row-owned table on an app where the
                // user is not signed in. When this happens, we have to tell
                // the table that there's nothing in it, so it leaves the
                // loading state.  It's fine if this is called multiple times.
                // https://github.com/quicktype/glide/issues/16039
                setTimeout(() => this.handler.updateWithDiffResults(tableName, [], true), 0);
            }

            logInfo("listening to user-specific columns", tableName.name, this.appUserID);

            if (this.appUserID !== undefined) {
                const userSpecificUnlisten = withInitialTableNetworkLock(unlock =>
                    database.listenWhere(
                        makeUserRowsPath(makeTablesPath(this.handler.appID), tableName),
                        [makeAppUserIDQuery(defined(this.appUserID))],
                        undefined,
                        undefined,
                        undefined,
                        results => {
                            try {
                                this.handler.updateWithUserSpecificColumns(table, results, defined(this.appUserID));
                            } finally {
                                unlock();
                            }
                        },
                        () => unlock()
                    )
                );

                if (this._userSpecificUnlistens === undefined) {
                    this._userSpecificUnlistens = [];
                }
                this._userSpecificUnlistens.push(userSpecificUnlisten);
            }
        }
    }

    public unlisten(): void {
        // `WaitingToFetchRows` will transition to `Listening` when a snapshot
        // arrives, which we don't want once we've called `unlisten`.
        if (this.state !== State.Listening && this.state !== State.WaitingToFetchRows) return;

        this.unlistenUserSpecifics();

        for (const unlisten of this._unlistens) {
            unlisten();
        }
        this._unlistens = [];

        this.state = State.GotSnapshotResponse;
    }

    public unlistenUserSpecifics(): void {
        if (this.state !== State.Listening) return;
        if (this._userSpecificUnlistens === undefined) return;

        for (const unlisten of this._userSpecificUnlistens) {
            unlisten();
        }
        this._userSpecificUnlistens = undefined;
    }

    public resetFromFirestore(): void {
        this.unlisten();
        this.listenToTable(true);
    }
}

class FirestoreGoogleSheetsTableListener extends FirestoreTableListener {
    // `false` means we tried to load but failed
    private _snapshot: { version: number; documents: readonly DocumentDataWithID[] } | false | undefined;

    protected getSnapshotRowCount(): number | undefined {
        if (this._snapshot === undefined || this._snapshot === false) return undefined;
        return this._snapshot.documents.length;
    }

    protected getSnapshotDataVersion(): number | undefined {
        if (this._snapshot === undefined || this._snapshot === false) return undefined;
        return this._snapshot.version;
    }

    protected updateWithSnapshot(force: boolean): void {
        if (this._snapshot === undefined || this._snapshot === false) return;

        const { table } = this;
        if (table === undefined) return;

        if (!this.isBuilder && tableHasRowOwners(table)) return;

        // We don't update for tables with owners because we might have an old snapshot
        // where the table didn't have owners.
        this.handler.updateWithSnapshotResults(
            table,
            1,
            this._snapshot.documents,
            this._snapshot.version,
            force,
            false
        );
    }

    // `privateSnapshot === undefined` means it's the "unused" snapshot
    // Returns whether the listener has "accepted" the snapshot.
    private snapshotLoaded(
        privateSnapshot: boolean | undefined,
        version: number,
        documents: readonly DocumentDataWithID[]
    ): boolean {
        if (this.table === undefined) return false;

        // If we got data from the unused snapshot, we always accept it.
        if (privateSnapshot !== undefined && tableHasRowOwners(this.table) !== privateSnapshot) return false;

        // We can get notified more than once because
        // `notifyListenerOfAllSnapshots` is invoked through more than one
        // code path.  If we do, we just report that we're accepting the
        // snapshot.
        if (this._snapshot !== undefined) return true;

        logInfo("snapshot loaded", version);

        this._snapshot = { version, documents };

        this.gotSnapshotResponse(true);

        return true;
    }

    private async loadAndNotify(
        privateSnapshot: boolean | undefined,
        load: () => Promise<TableSnapshot | DataSnapshot | undefined>
    ): Promise<void> {
        const snapshot = await load();
        if (this.table === undefined || snapshot === undefined) return;
        const data = isDataSnapshot(snapshot) ? snapshot.data[getTableName(this.table).name] : snapshot.rows;
        // We've observed public snapshots with row arrays for row-owned
        // tables, so we'll just ignore empty row arrays from snapshots.
        if (data !== undefined && data.length > 0) {
            this.snapshotLoaded(privateSnapshot, snapshot.version, data);
        }
    }

    protected async waitingToFetchRows(table: TableGlideType): Promise<void> {
        assert(this.state === State.WaitingToFetchRows);
        assert(this._snapshot === undefined);

        if (!this.isBuilder && tableHasRowOwners(table)) {
            this._snapshot = false;
            this.gotSnapshotResponse(false);
            return;
        }

        const tableName = getFeatureSetting("reduceSnapshotMemoryConsumption") ? getTableName(table).name : undefined;
        await Promise.all([
            this.loadAndNotify(false, () => this.snapshotLoader.loadPublicDataSnapshot(this.handler.appID, tableName)),
            this.loadAndNotify(true, () => this.snapshotLoader.loadPrivateDataSnapshot(this.handler.appID, tableName)),
            this.loadAndNotify(undefined, () =>
                this.snapshotLoader.loadUnusedDataSnapshot(this.handler.appID, tableName)
            ),
        ]);

        if (this.state !== State.WaitingToFetchRows) {
            // We might have changed state in the mean time, for example
            // because we've been retired, in which case we don't want to
            // update with the snapshot anymore.
            return;
        }

        if (this._snapshot === undefined) {
            this._snapshot = false;
            this.gotSnapshotResponse(false);
        }
    }
}

// This listener ignores the app data snapshot, and instead initiate loading
// the native table snapshot when the table is first accessed, which is the
// transition to `WaitingToFetchRows`.
class FirestoreNativeTableListener extends FirestoreTableListener {
    // We only accept snapshots that have a version for our app.  `false`
    // means the snapshot couldn't be loaded.
    private _snapshot: NativeTableSnapshot | false | undefined;

    protected getSnapshotRowCount(): number | undefined {
        if (this._snapshot === undefined || this._snapshot === false) return undefined;
        return this._snapshot.rows.length;
    }

    protected getSnapshotDataVersion(): number | undefined {
        if (this._snapshot === undefined || this._snapshot === false) return undefined;
        return defined(this._snapshot.appDataVersions[this.handler.appID]);
    }

    protected updateWithSnapshot(force: boolean): void {
        if (this._snapshot === undefined || this._snapshot === false) return;

        const { table } = this;
        if (table === undefined) return;

        if (!this.isBuilder && tableHasRowOwners(table)) return;

        // We don't update for tables with owners because we might have an old snapshot
        // where the table didn't have owners.
        this.handler.updateWithSnapshotResults(
            table,
            this._snapshot.formatVersion ?? 1,
            this._snapshot.rows,
            defined(this.getSnapshotDataVersion()),
            force,
            true
        );
    }

    protected async waitingToFetchRows(table: TableGlideType): Promise<void> {
        assert(this.state === State.WaitingToFetchRows);
        assert(this._snapshot === undefined);

        if (!this.isBuilder && tableHasRowOwners(table)) {
            this._snapshot = false;
            this.gotSnapshotResponse(false);
            return;
        }

        const snapshot = await this.snapshotLoader.loadNativeTableSnapshot(this.handler.appID, getTableName(table));

        if (this.state !== State.WaitingToFetchRows) {
            // We might have changed state in the mean time, for example
            // because we've been retired, in which case we don't want to
            // update with the snapshot anymore.
            return;
        }

        assert(this._snapshot === undefined);

        if (snapshot === undefined || snapshot.appDataVersions[this.handler.appID] === undefined) {
            this._snapshot = false;
            this.gotSnapshotResponse(false);
        } else {
            logInfo("native table snapshot", snapshot);

            this._snapshot = snapshot;
            this.gotSnapshotResponse(true);
        }
    }
}

export function makeMakeFirestoreTableListener(
    dataSnapshotLoader: DataSnapshotLoader,
    isBuilder: boolean,
    getNumRowsUsedInApp: () => number | undefined
): MakeFirestoreTableListener {
    const makeTableListener: MakeFirestoreTableListener = (
        tableName,
        getTable,
        documentsFirestoreHandler,
        durableStorageController
    ) => {
        // FIXME: We really shouldn't check the table name for whether
        // it's a valid table, but instead look into the signed snapshot
        // response for whether the table name is in there.
        if (isTableNameForNativeTable(tableName)) {
            return new FirestoreNativeTableListener(
                documentsFirestoreHandler,
                dataSnapshotLoader,
                getTable,
                isBuilder,
                getNumRowsUsedInApp,
                durableStorageController
            );
        } else {
            return new FirestoreGoogleSheetsTableListener(
                documentsFirestoreHandler,
                dataSnapshotLoader,
                getTable,
                isBuilder,
                getNumRowsUsedInApp,
                durableStorageController
            );
        }
    };
    return makeTableListener;
}
