import { shallowEqualArrays } from "@glide/support";
import * as React from "react";
import { type Owner, getUserFeatures } from "./Database";
import type { ExperimentName, FeatureSettingName } from "@glide/app-description";
import { featureSettingsObservable, getFeatureSetting } from "./feature-settings";

export function useFeatureSettings(names: readonly FeatureSettingName[], appOrUserID?: string): boolean[] {
    const [result, setResult] = React.useState(names.map(name => getFeatureSetting(name, appOrUserID)));
    const resultRef = React.useRef(result);
    const firstRun = React.useRef(false);
    resultRef.current = result;
    React.useEffect(() => {
        if (!firstRun.current) {
            setResult(names.map(name => getFeatureSetting(name, appOrUserID)));
        }
        firstRun.current = false;

        const cb = () => {
            const newValue = names.map(name => getFeatureSetting(name, appOrUserID));
            if (!shallowEqualArrays(resultRef.current, newValue)) {
                setResult(newValue);
            }
        };
        featureSettingsObservable.subscribe(cb);
        return () => featureSettingsObservable.unsubscribe(cb);
        // Unpack the names so changing the array wont cause us to re-subscribe for no reason
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...names, appOrUserID]);

    return result;
}

export function isExperimentEnabled(name: ExperimentName, userFeatures: Record<ExperimentName, boolean>): boolean {
    if (userFeatures[name]) return true;
    return getFeatureSetting(name);
}

export const useExperiment = (owner: Owner | undefined | null | "loading", experiment: ExperimentName): boolean => {
    const [isEnabledGlobally] = useFeatureSettings([experiment]);
    if (getUserFeatures(owner)[experiment]) return true;
    return isEnabledGlobally;
};
