import {
    SyntheticColumnKind,
    type ArraySourceSpecification,
    type ColumnOrValueSpecification,
    ValueFormatKind,
    ColumnOrValueKind,
    decomposeAll,
    isGetDisplayFormulaInput,
    makeFormatFormula,
} from "@glide/formula-specifications";
import {
    getDebugPrintTableName,
    type ColumnType,
    type ColumnTypeKind,
    type Formula,
    type PrimitiveGlideType,
    type PrimitiveGlideTypeKind,
    type TableColumn,
    type TableGlideType,
    type UniversalTableRefGlideType,
    isSourceColumn,
    FormulaKind,
    UnaryPredicateFormulaOperator,
    getTableColumn,
    getTableName,
    getTableRefTableName,
    isComputedColumn,
    isDateOrDateTimeTypeKind,
    isNumberTypeKind,
    isPrimitiveType,
    isSingleRelationType,
    makeArrayType,
    makePrimitiveType,
    makeUniversalTableRef,
    type AndOrFormula,
    type ApplyColumnFormatFormula,
    type ArrayContainsFormula,
    type ArraysOverlapFormula,
    type AssignVariablesFormula,
    type BinaryMathFormula,
    type CheckValueFormula,
    type CompareValuesFormula,
    type ConstantFormula,
    type ConstructURLFormula,
    type ConvertToTypeFormula,
    type FilterRowsFormula,
    type FilterSortLimitFormula,
    type FindRowFormula,
    type FormatDateTimeFormula,
    type FormatDurationFormula,
    type FormatNumberFixedFormula,
    type GenerateImageFormula,
    type GeoDistanceFormula,
    type GeocodeAddressFormula,
    type GetColumnFormula,
    type GetContextFormula,
    type GetNthFormula,
    type GetTableRowsFormula,
    type GetVariableFormula,
    type IfThenElseFormula,
    type IsInRangeFormula,
    type JoinStringsFormula,
    type MakeArrayFormula,
    type MapRowsFormula,
    type NotFormula,
    type PluginComputationFormula,
    type QueryParametersFormulas,
    type RandomPickFormula,
    type ReduceFormula,
    type ReduceToMemberByFormula,
    type SpecialValueFormula,
    type SplitStringFormula,
    type StartOrEndOfDayFormula,
    type TextTemplateFormula,
    type UnaryMathFormula,
    type UserAPIFetchFormula,
    type WithFormula,
    type WithUserEnteredTextFormula,
    type YesCodeFormula,
    BinaryMathFunction,
    reduceType,
    type SchemaInspector,
    type GetActionNodeOutputFormula,
    isActionNodeOutputSourceColumn,
    isPrimitiveArrayType,
    decomposeRelationType,
    unifyTypeKinds,
} from "@glide/type-schema";
import { RollupKind } from "@glide/computation-model-types";
import { resolveSourceColumn } from "@glide/function-utils";
import { assert, assertNever, definedMap, panic } from "@glideapps/ts-necessities";
import { isDefined, logError, logTrace } from "@glide/support";
import md5 from "blueimp-md5";
import deepEqual from "deep-equal";
import type { StaticComputationContext, TypeForActionNodeOutputGetter } from "../static-context";
import { getTypeForSpecialValue } from "../components/special-values";

type FormulaTypeKind = ColumnTypeKind | "lat-long";
type FormulaType = ColumnType | { readonly kind: "lat-long" };

function isPrimitiveFormulaType(t: FormulaType | undefined): t is PrimitiveGlideType {
    if (t === undefined) return false;
    return t.kind !== "lat-long" && isPrimitiveType(t);
}

function isNumberFormulaTypeKind(kind: FormulaTypeKind | undefined): kind is "number" | "duration" {
    return kind !== undefined && kind !== "lat-long" && isNumberTypeKind(kind);
}

function isDateOrDateTimeFormulaType(t: FormulaType) {
    if (t.kind === "lat-long") return false;
    return isDateOrDateTimeTypeKind(t.kind);
}

interface SuccessCompileResult {
    readonly success: true;

    readonly type: ColumnType | undefined;
    readonly typeIsPrecise: boolean;
    readonly geocodeQuotaKeys: ReadonlySet<string>;
}

interface ErrorCompileResult {
    readonly success: false;

    readonly issues: readonly string[];
    readonly errors: readonly string[];
}

export type CompileResult = SuccessCompileResult | ErrorCompileResult;

interface InternalCompileResult {
    readonly type: FormulaType | undefined;
    readonly typeIsPrecise: boolean;
}

// All over this file we ##preserveUniversalTableNames by always dealing with
// the universal table names and refs instead of with the `TableGlideType`s
// directly.
type Context = [name: string, type: UniversalTableRefGlideType | PrimitiveGlideTypeKind];

export function makeQuotaKeyForFormula(f: unknown): string {
    return `formula:${md5(JSON.stringify(f))}`;
}

// Will return the compiled formula, or a list of issues.
export function compileFormulaWithCodeGenerator(
    builder: SchemaInspector,
    outerTable: UniversalTableRefGlideType | undefined,
    rootFormula: Formula,
    getTypeForActionNodeOutput: TypeForActionNodeOutputGetter | undefined
): CompileResult {
    const issues: string[] = [];
    const errors: string[] = [];
    const geocodeQuotaKeys = new Set<string>();

    // FIXME: This can be simplified.  This array should never be empty.
    // The first item should be the outermost context.
    const contexts: Context[] = [];

    // Pushes and pops from the front
    const bindings: ReadonlyMap<string, InternalCompileResult>[] = [];

    function inContext(
        contextName: string,
        contextType: UniversalTableRefGlideType | PrimitiveGlideTypeKind,
        f: () => InternalCompileResult | undefined
    ): InternalCompileResult | undefined {
        assert(contexts.findIndex(([n]) => n === contextName) < 0);

        contexts.push([contextName, contextType]);
        const r = f();
        contexts.pop();

        if (r === undefined) return undefined;
        return { type: r.type, typeIsPrecise: r.typeIsPrecise };
    }

    function failWithIssue(issue: string): undefined {
        issues.push(issue);
        return undefined;
    }

    function failWithError(error: string): undefined {
        errors.push(error);
        return failWithIssue(error);
    }

    function getContext(
        contextName: string | undefined
    ): { type: UniversalTableRefGlideType | PrimitiveGlideTypeKind } | undefined {
        if (contextName === undefined) {
            if (outerTable === undefined) {
                return failWithIssue(`No table given`);
            }
            return {
                type: outerTable,
            };
        }

        const entry = contexts.find(([n]) => n === contextName);
        if (entry !== undefined) {
            return {
                type: entry[1],
            };
        }

        return failWithIssue(`Context ${contextName} is not defined`);
    }

    function getFormulaTableName(t: FormulaType): UniversalTableRefGlideType | undefined {
        if (t.kind === "lat-long" || !isSingleRelationType(t)) {
            return failWithIssue(`Expected table ref type but got ${t.kind}`);
        }
        return t;
    }

    function getArrayTableOrPrimitive(t: FormulaType): UniversalTableRefGlideType | PrimitiveGlideTypeKind | undefined {
        if (t.kind !== "array") {
            return failWithIssue(`Expected array type but got ${t.kind}`);
        }
        if (isPrimitiveType(t.items)) {
            return t.items.kind;
        } else {
            return t.items;
        }
    }

    function getTable(ref: UniversalTableRefGlideType): TableGlideType | undefined {
        const table = builder.findTable(ref);
        if (table === undefined) {
            return failWithIssue(`Could not find table ${getDebugPrintTableName(getTableRefTableName(ref))}`);
        }
        return table;
    }

    function getArrayTable(t: FormulaType): UniversalTableRefGlideType | undefined {
        const type = getArrayTableOrPrimitive(t);
        if (type === undefined) return undefined;
        if (typeof type === "string") {
            return failWithIssue(`Expected table ref type but got ${type}`);
        }
        return type;
    }

    function compilePredicate(f: Formula): InternalCompileResult | undefined {
        const r = compile(f);
        if (r === undefined) return undefined;
        if (r.type?.kind !== "boolean") {
            return failWithIssue(`Condition is not boolean but is ${r.type?.kind}`);
        }
        return r;
    }

    function resultWithType(type: FormulaType | undefined, typeIsPrecise: boolean): InternalCompileResult | undefined {
        return { type, typeIsPrecise };
    }

    function primitiveResult(
        typeKind: PrimitiveGlideTypeKind | "lat-long",
        typeIsPrecise: boolean
    ): InternalCompileResult | undefined {
        let type: FormulaType;
        if (typeKind === "lat-long") {
            type = { kind: typeKind };
        } else {
            type = makePrimitiveType(typeKind);
        }
        return resultWithType(type, typeIsPrecise);
    }

    function compileQueryParameters(paramsFormulas: QueryParametersFormulas): true | undefined {
        for (const q of paramsFormulas) {
            const name = compile(q.name);
            const value = compile(q.value);
            if (name === undefined || value === undefined) return undefined;
            if (!isPrimitiveFormulaType(name.type)) {
                return failWithIssue(`Name must be primitive, but is ${name.type?.kind}`);
            }
            if (!isPrimitiveFormulaType(value.type)) {
                return failWithIssue(`Value must be primitive, but is ${value.type?.kind}`);
            }
        }
        return true;
    }

    function compile(formula: Formula): InternalCompileResult | undefined {
        // Being defensive.  This can happen during development.
        if (formula === undefined) {
            logTrace("Formula is undefined");
            return failWithIssue("Internal error: formula is undefined");
        }

        switch (formula.kind) {
            case FormulaKind.GetTableRows: {
                const f = formula as GetTableRowsFormula;
                const table = builder.findTable(f.table);
                if (table === undefined) {
                    return failWithIssue(`Table ${getDebugPrintTableName(f.table)} does not exist`);
                }
                return resultWithType(makeArrayType(makeUniversalTableRef(f.table)), true);
            }
            case FormulaKind.FilterRows: {
                const f = formula as FilterRowsFormula;
                const rows = compile(f.rows);
                if (rows === undefined) return undefined;
                const rowsTable = definedMap(rows.type, getArrayTable);
                if (rowsTable === undefined) return undefined;
                return inContext(f.predicateContextName, rowsTable, () => {
                    const predicate = compilePredicate(f.predicate);
                    if (predicate === undefined) return undefined;
                    return resultWithType(rows.type, rows.typeIsPrecise);
                });
            }
            case FormulaKind.MapRows: {
                const f = formula as MapRowsFormula;
                const rows = compile(f.rows);
                if (rows === undefined) return undefined;
                const rowsTable = definedMap(rows.type, getArrayTable);
                if (rowsTable === undefined) return undefined;
                return inContext(f.functionContextName, rowsTable, () => {
                    const fn = compile(f.function);
                    if (fn?.type === undefined) return undefined;
                    if (fn.type.kind === "array") {
                        return failWithIssue("Cannot map to an array of arrays");
                    }
                    if (fn.type.kind === "lat-long") {
                        return failWithIssue("Cannot map to an array of lat-long");
                    }
                    return resultWithType(makeArrayType(fn.type), fn.typeIsPrecise);
                });
            }
            case FormulaKind.Reduce: {
                const f = formula as ReduceFormula;
                const rows = compile(f.rows);
                if (rows === undefined) return undefined;
                const itemsType = definedMap(rows.type, getArrayTableOrPrimitive);
                if (itemsType === undefined) return undefined;
                return inContext(f.valueContextName, itemsType, () => {
                    const valueForRow = compile(f.value);
                    if (valueForRow === undefined) return undefined;
                    return primitiveResult(reduceType[f.operator], true);
                });
            }
            case FormulaKind.ReduceToMemberBy: {
                const f = formula as ReduceToMemberByFormula;
                const rows = compile(f.rows);
                if (rows === undefined) return undefined;
                const itemsType = definedMap(rows.type, getArrayTableOrPrimitive);
                if (itemsType === undefined) return undefined;
                return inContext(f.valueContextName, itemsType, () => {
                    const value = compile(f.value);
                    const key = compile(f.key);
                    if (value === undefined || key === undefined) return undefined;
                    return resultWithType(value.type, value.typeIsPrecise);
                });
            }
            case FormulaKind.JoinStrings: {
                const f = formula as JoinStringsFormula;
                const rows = compile(f.rows);
                if (rows === undefined) return undefined;
                const itemsType = definedMap(rows.type, getArrayTableOrPrimitive);
                if (itemsType === undefined) return undefined;
                const separator = compile(f.separator);
                if (separator === undefined) return undefined;
                return inContext(f.valueContextName, itemsType, () => {
                    const value = compile(f.value);
                    if (value === undefined) return undefined;
                    return primitiveResult("string", true);
                });
            }
            case FormulaKind.SplitString: {
                const f = formula as SplitStringFormula;
                const string = compile(f.string);
                if (string === undefined) return undefined;
                const separator = compile(f.separator);
                if (separator === undefined) return undefined;
                return resultWithType({ kind: "array", items: makePrimitiveType("string") }, true);
            }
            case FormulaKind.FindRow: {
                const f = formula as FindRowFormula;
                const rows = compile(f.rows);
                if (rows === undefined) return undefined;
                const rowsTable = definedMap(rows.type, getArrayTable);
                if (rowsTable === undefined) return undefined;
                return inContext(f.predicateContextName, rowsTable, () => {
                    const predicate = compilePredicate(f.predicate);
                    if (predicate === undefined) return undefined;
                    return resultWithType(rows.type, true);
                });
            }
            case FormulaKind.IfThenElse: {
                const f = formula as IfThenElseFormula;
                const condition = compilePredicate(f.condition);
                const consequent = compile(f.consequent);
                if (condition === undefined || consequent === undefined) return undefined;
                const alternative = definedMap(f.alternative, compile);
                if (f.alternative !== undefined && alternative === undefined) return undefined;
                let type: FormulaType | undefined;
                let typeIsPrecise: boolean;
                if (alternative === undefined) {
                    type = consequent.type;
                    typeIsPrecise = consequent.typeIsPrecise;
                } else if (deepEqual(consequent.type, alternative.type, { strict: true })) {
                    type = consequent.type;
                    typeIsPrecise = consequent.typeIsPrecise && alternative.typeIsPrecise;
                } else if (consequent.type === undefined && alternative.type === undefined) {
                    typeIsPrecise = consequent.typeIsPrecise && alternative.typeIsPrecise;
                } else if (consequent.type === undefined) {
                    type = alternative.type;
                    typeIsPrecise = false;
                } else if (alternative.type === undefined) {
                    type = consequent.type;
                    typeIsPrecise = false;
                } else if (isPrimitiveFormulaType(consequent.type) && isPrimitiveFormulaType(alternative.type)) {
                    type = makePrimitiveType("string");
                    typeIsPrecise = false;
                } else {
                    return failWithIssue("Incompatible types for if-then-else");
                }
                return resultWithType(type, typeIsPrecise);
            }
            case FormulaKind.CheckValue: {
                const f = formula as CheckValueFormula;
                const value = compile(f.value);
                if (value === undefined) return undefined;
                switch (f.operator) {
                    case UnaryPredicateFormulaOperator.IsNotEmpty:
                    case UnaryPredicateFormulaOperator.IsTruthy: {
                        return {
                            type: makePrimitiveType("boolean"),
                            typeIsPrecise: true,
                        };
                    }
                    default:
                        return assertNever(f.operator);
                }
            }
            case FormulaKind.CompareValues: {
                const f = formula as CompareValuesFormula;
                const left = compile(f.left);
                const right = compile(f.right);
                if (left === undefined || right === undefined) return undefined;
                // FIXME: Check that `left` and `right` types can be compared
                return { type: makePrimitiveType("boolean"), typeIsPrecise: true };
            }
            case FormulaKind.Constant: {
                const f = formula as ConstantFormula;
                let type: ColumnType | undefined;
                let typeIsPrecise = true;
                if (typeof f.value === "string") {
                    const lower = f.value.toLowerCase();
                    if (lower === "true" || lower === "false") {
                        type = makePrimitiveType("boolean");
                        typeIsPrecise = false;
                    }
                }
                if (type === undefined) {
                    type = makePrimitiveType(typeof f.value as PrimitiveGlideTypeKind);
                }
                return { type, typeIsPrecise };
            }
            case FormulaKind.Empty: {
                return { type: undefined, typeIsPrecise: true };
            }
            case FormulaKind.GetContext: {
                const f = formula as GetContextFormula;
                const context = getContext(f.contextName);
                if (context === undefined) return undefined;

                if (typeof context.type === "string") {
                    return primitiveResult(context.type, false);
                } else {
                    return {
                        type: context.type,
                        typeIsPrecise: true,
                    };
                }
            }
            case FormulaKind.GetColumn: {
                const f = formula as GetColumnFormula;
                const context = getContext(f.contextName);
                if (context === undefined) return undefined;
                if (typeof context.type === "string") {
                    return failWithIssue("Trying to get a column from a primitive");
                }
                const contextTable = getTable(context.type);
                if (contextTable === undefined) return undefined;
                const column = getTableColumn(contextTable, f.column);
                if (column === undefined) {
                    return failWithIssue(`Column ${f.column} not found`);
                }

                return {
                    type: column.type,
                    typeIsPrecise: isComputedColumn(column) || !isPrimitiveType(column.type),
                };
            }
            case FormulaKind.GetUserProfileRow: {
                const userProfile = builder.userProfileTableInfo;
                if (userProfile === undefined) {
                    return failWithIssue(`No user profile table`);
                }
                return resultWithType(makeUniversalTableRef(getTableName(userProfile.tableName)), true);
            }
            case FormulaKind.GetActionNodeOutput: {
                const f = formula as GetActionNodeOutputFormula;
                const type = getTypeForActionNodeOutput?.(f.actionNodeKey, f.outputName, f.columnInRow);
                if (type === undefined) return failWithIssue("Could not get type for action node output");
                if (typeof type === "string") return failWithIssue(type);
                if (f.withFormat) {
                    if (isPrimitiveType(type)) {
                        return resultWithType(makePrimitiveType("string"), true);
                    } else if (isPrimitiveArrayType(type)) {
                        return resultWithType(makeArrayType(makePrimitiveType("string")), true);
                    } else {
                        return failWithIssue("Cannot apply format to non-primitive types");
                    }
                } else {
                    return resultWithType(type, true);
                }
            }
            case FormulaKind.ApplyColumnFormat: {
                const f = formula as ApplyColumnFormatFormula;
                const context = getContext(f.contextName);
                if (context === undefined) return undefined;
                if (typeof context.type === "string") {
                    return failWithIssue("Trying to get a column from a primitive");
                }
                const contextTable = getTable(context.type);
                if (contextTable === undefined) return undefined;
                const column = getTableColumn(contextTable, f.column);
                if (column === undefined) {
                    return failWithIssue(`Column ${f.column} not found`);
                }
                const value = compile(f.value);
                if (value === undefined) return undefined;

                return primitiveResult("string", false);
            }
            case FormulaKind.TextTemplate: {
                const f = formula as TextTemplateFormula;
                const template = compile(f.template);
                if (template === undefined) return undefined;
                if (!isPrimitiveFormulaType(template.type)) {
                    return failWithIssue(`Template must be a primitive, but is ${template.type?.kind}`);
                }
                for (const r of f.replacements) {
                    const pattern = compile(r.pattern);
                    const replacement = compile(r.replacement);
                    if (pattern === undefined || replacement === undefined) return undefined;
                    if (!isPrimitiveFormulaType(pattern.type)) {
                        return failWithIssue(`Pattern must be primitive, but is ${pattern.type?.kind}`);
                    }
                    if (!isPrimitiveFormulaType(replacement.type)) {
                        return failWithIssue(`Replacement must be primitive, but is ${replacement.type?.kind}`);
                    }
                }

                return primitiveResult("string", true);
            }
            case FormulaKind.ArrayContains: {
                const f = formula as ArrayContainsFormula;
                const array = compile(f.array);
                const item = compile(f.item);
                if (array === undefined || item === undefined) return undefined;
                if (array.type?.kind !== "array") {
                    return failWithIssue(`ArrayContains array argument is not an array, but is ${array.type?.kind}`);
                }
                return primitiveResult("boolean", true);
            }
            case FormulaKind.ArraysOverlap: {
                const f = formula as ArraysOverlapFormula;
                const left = compile(f.left);
                const right = compile(f.right);
                if (left === undefined || right === undefined) return undefined;
                if (left.type?.kind !== "array") {
                    return failWithIssue(`ArraysOverlap left argument is not an array, but is ${left.type?.kind}`);
                }
                if (right.type?.kind !== "array") {
                    return failWithIssue(`ArraysOverlap right argument is not an array, but is ${left.type?.kind}`);
                }
                return primitiveResult("boolean", true);
            }
            case FormulaKind.MakeArray: {
                const f = formula as MakeArrayFormula;
                const items = f.items.map(compile);
                if (!items.every(isDefined)) return undefined;

                const typeKinds = new Set<PrimitiveGlideTypeKind>();
                for (const i of items) {
                    if (isPrimitiveFormulaType(i.type)) {
                        typeKinds.add(i.type.kind);
                    } else if (i.type?.kind === "array" && isPrimitiveFormulaType(i.type.items)) {
                        typeKinds.add(i.type.items.kind);
                    } else {
                        return failWithIssue("MakeArray items must be primitives");
                    }
                }
                const unifiedTypeKind = unifyTypeKinds(typeKinds);

                return resultWithType(makeArrayType(makePrimitiveType(unifiedTypeKind)), false);
            }
            case FormulaKind.And:
            case FormulaKind.Or: {
                const f = formula as AndOrFormula;
                const left = compile(f.left);
                const right = compile(f.right);
                if (left === undefined || right === undefined) return undefined;
                if (left.type?.kind !== "boolean") {
                    return failWithIssue(`And/Or left argument is not an array, but is ${left.type?.kind}`);
                }
                if (right.type?.kind !== "boolean") {
                    return failWithIssue(`And/Or right argument is not an array, but is ${right.type?.kind}`);
                }
                return primitiveResult("boolean", true);
            }
            case FormulaKind.Not: {
                const f = formula as NotFormula;
                const value = compile(f.value);
                if (value === undefined) return undefined;
                if (value.type?.kind !== "boolean") {
                    return failWithIssue(`Not argument is not a boolean, but is ${value.type?.kind}`);
                }
                return {
                    type: makePrimitiveType("boolean"),
                    typeIsPrecise: true,
                };
            }
            case FormulaKind.SpecialValue: {
                const f = formula as SpecialValueFormula;
                const type = getTypeForSpecialValue(f.valueKind);
                return primitiveResult(type.kind, true);
            }
            case FormulaKind.ConvertToType: {
                const f = formula as ConvertToTypeFormula;
                const value = compile(f.value);
                if (value === undefined) return undefined;
                if (!isPrimitiveFormulaType(value.type)) {
                    return failWithIssue(`Argument to type conversion is not a primitive, but is ${value.type?.kind}`);
                }
                return primitiveResult(f.typeKind, true);
            }
            case FormulaKind.StartOfDay: {
                const f = formula as StartOrEndOfDayFormula;
                const dateTime = compile(f.dateTime);
                if (dateTime === undefined) return undefined;
                if (dateTime.type?.kind !== "date-time") {
                    return failWithIssue(`Argument to start of day is not a date-time, but is ${dateTime.type?.kind}`);
                }
                return primitiveResult("date-time", true);
            }
            case FormulaKind.EndOfDay: {
                const f = formula as StartOrEndOfDayFormula;
                const dateTime = compile(f.dateTime);
                if (dateTime === undefined) return undefined;
                if (dateTime.type?.kind !== "date-time") {
                    return failWithIssue(`Argument to end of day is not a date-time, but is ${dateTime.type?.kind}`);
                }
                return primitiveResult("date-time", true);
            }
            case FormulaKind.With: {
                const f = formula as WithFormula;
                const context = compile(f.context);
                if (context?.type === undefined) return undefined;
                const contextTable = getFormulaTableName(context.type);
                if (contextTable === undefined) return undefined;
                return inContext(f.contextName, contextTable, () => {
                    const value = compile(f.value);
                    if (value === undefined) return undefined;
                    return {
                        type: value.type,
                        typeIsPrecise: value.typeIsPrecise,
                    };
                });
            }
            case FormulaKind.GetNth:
            case FormulaKind.GetNthLast: {
                const f = formula as GetNthFormula;
                const array = compile(f.array);
                const index = compile(f.index);
                if (array === undefined || index === undefined) return undefined;
                if (array.type?.kind !== "array") {
                    return failWithIssue(`Value to get nth from must be array, but is ${array.type?.kind}`);
                }
                if (!isNumberFormulaTypeKind(index.type?.kind)) {
                    return failWithIssue(`Array index is not a number, but is ${index.type?.kind}`);
                }
                return resultWithType(array.type.items, array.typeIsPrecise);
            }
            case FormulaKind.RandomPick: {
                const f = formula as RandomPickFormula;
                const array = compile(f.array);
                if (array === undefined) return undefined;
                if (array.type?.kind !== "array") {
                    return failWithIssue(`Value to get random pick from must be array, but is ${array.type?.kind}`);
                }
                return resultWithType(array.type.items, array.typeIsPrecise);
            }
            case FormulaKind.IsInRange: {
                const f = formula as IsInRangeFormula;
                const value = compile(f.value);
                const start = compile(f.start);
                const end = compile(f.end);
                if (value === undefined || start === undefined || end === undefined) return undefined;
                // FIXME: Check that `value`, `start`, and `end` types can be compared

                return primitiveResult("boolean", true);
            }
            case FormulaKind.AssignVariables: {
                const f = formula as AssignVariablesFormula;
                const assignments = new Map<string, InternalCompileResult>();
                for (const [lhs, rhs] of f.assignments) {
                    const rhsResult = compile(rhs);
                    if (rhsResult === undefined) return undefined;
                    assignments.set(lhs, rhsResult);
                }
                bindings.unshift(assignments);
                const body = compile(f.body);
                bindings.shift();
                if (body === undefined) return undefined;
                return {
                    type: body.type,
                    typeIsPrecise: body.typeIsPrecise,
                };
            }
            case FormulaKind.GetVariable: {
                const f = formula as GetVariableFormula;
                for (const m of bindings) {
                    const binding = m.get(f.name);
                    if (binding === undefined) continue;
                    return {
                        type: binding.type,
                        typeIsPrecise: binding.typeIsPrecise,
                    };
                }
                return failWithIssue(`Variable ${f.name} not found`);
            }
            case FormulaKind.Random: {
                return primitiveResult("number", true);
            }
            case FormulaKind.UnaryMath: {
                const f = formula as UnaryMathFormula;
                const operand = compile(f.operand);
                if (operand === undefined) return undefined;
                if (!isPrimitiveFormulaType(operand.type)) {
                    return failWithIssue(`Math operand must be primitive, but is ${operand.type?.kind}`);
                }
                return primitiveResult("number", true);
            }
            case FormulaKind.BinaryMath: {
                const f = formula as BinaryMathFormula;
                const left = compile(f.left);
                const right = compile(f.right);
                if (left === undefined || right === undefined) return undefined;

                let resultKind: PrimitiveGlideTypeKind | undefined;
                if (left.type !== undefined && right.type !== undefined) {
                    const leftIsDate = isDateOrDateTimeFormulaType(left.type);
                    const rightIsDate = isDateOrDateTimeFormulaType(right.type);
                    const leftIsDuration = left.type.kind === "duration";
                    const rightIsDuration = right.type.kind === "duration";
                    const exactlyOneIsDuration = leftIsDuration !== rightIsDuration;
                    const isAddOrSubtract = f.fn === BinaryMathFunction.Add || f.fn === BinaryMathFunction.Subtract;
                    const isMultiplyOrDivide =
                        f.fn === BinaryMathFunction.Multiply || f.fn === BinaryMathFunction.Divide;

                    if (f.fn === BinaryMathFunction.Subtract && leftIsDate && rightIsDate) {
                        return primitiveResult("duration", true);
                    } else if (isAddOrSubtract && leftIsDate && !rightIsDate) {
                        // FIXME: this is where we need to figure out what the
                        // result time-zone is.
                        return primitiveResult("date-time", true);
                    } else if (leftIsDate || rightIsDate) {
                        return failWithError("Invalid operation on date/time");
                    } else if (isAddOrSubtract && (leftIsDuration || rightIsDuration)) {
                        resultKind = "duration";
                    } else if (isMultiplyOrDivide && exactlyOneIsDuration) {
                        resultKind = "duration";
                    }
                }

                if (!isPrimitiveFormulaType(left.type) || !isPrimitiveFormulaType(right.type)) {
                    return failWithIssue(
                        `Math operands must be primitives, but they are ${left.type?.kind} and ${right.type?.kind}`
                    );
                }
                return {
                    type: makePrimitiveType(resultKind ?? "number"),
                    typeIsPrecise: true,
                };
            }
            case FormulaKind.WithUserEnteredText: {
                const f = formula as WithUserEnteredTextFormula;
                return compile(f.formula);
            }
            case FormulaKind.FormatNumberFixed: {
                const f = formula as FormatNumberFixedFormula;
                const value = compile(f.value);
                if (value === undefined) return undefined;

                const decimalsAfterPoint = compile(f.decimalsAfterPoint);
                const groupSeparator = compile(f.groupSeparator);
                if (value === undefined || decimalsAfterPoint === undefined || groupSeparator === undefined) {
                    return undefined;
                }
                let currency: InternalCompileResult | undefined;
                if (f.currency !== undefined) {
                    currency = compile(f.currency);
                    if (currency === undefined) return undefined;
                }
                return primitiveResult("string", true);
            }
            case FormulaKind.FormatDateTime: {
                const f = formula as FormatDateTimeFormula;
                const value = compile(f.value);
                if (value === undefined) return undefined;
                const dateFormat = definedMap(f.dateFormat, compile);
                if (f.dateFormat !== undefined && dateFormat === undefined) return undefined;
                const timeFormat = definedMap(f.timeFormat, compile);
                if (f.timeFormat !== undefined && timeFormat === undefined) return undefined;

                return primitiveResult("string", true);
            }
            case FormulaKind.FormatDuration: {
                const f = formula as FormatDurationFormula;
                const value = compile(f.value);
                if (value === undefined) return undefined;

                return primitiveResult("string", true);
            }
            case FormulaKind.CurrentLocation: {
                return primitiveResult("lat-long", true);
            }
            case FormulaKind.GeocodeAddress: {
                const f = formula as GeocodeAddressFormula;
                const value = compile(f.address);
                if (value === undefined) return undefined;
                if (!isPrimitiveFormulaType(value.type)) {
                    return failWithIssue(`Address must be primitive, but is ${value.type?.kind}`);
                }
                const quotaKey = makeQuotaKeyForFormula(f.address);
                geocodeQuotaKeys.add(quotaKey);
                return primitiveResult("lat-long", true);
            }
            case FormulaKind.GeoDistance: {
                const f = formula as GeoDistanceFormula;
                const left = compile(f.left);
                const right = compile(f.right);
                if (left === undefined || right === undefined) return undefined;
                if (left.type?.kind !== "lat-long" || right.type?.kind !== "lat-long") {
                    return failWithIssue(`Geo distance operands must be lat/long`);
                }
                if (!left.typeIsPrecise || !right.typeIsPrecise) {
                    return failWithIssue(`Geo distance operands must have a precise type`);
                }
                return primitiveResult("number", true);
            }
            case FormulaKind.GenerateImage: {
                const f = formula as GenerateImageFormula;
                const input = compile(f.input);
                const imageKind = compile(f.imageKind);
                if (input === undefined || imageKind === undefined) return undefined;

                return primitiveResult("image-uri", true);
            }
            case FormulaKind.UserAPIFetch: {
                const f = formula as UserAPIFetchFormula;
                let webhookID: string | undefined;
                if (f.webhookID === undefined || f.webhookID.kind !== FormulaKind.Constant) {
                    const id = f.webhookID as ConstantFormula | undefined;
                    if (typeof id?.value === "string") {
                        webhookID = id.value;
                    }
                }
                if (webhookID === undefined) {
                    return failWithIssue("Webhook ID must be a constant string");
                }
                const params = compileQueryParameters(f.params);
                if (params === undefined) return undefined;

                return primitiveResult("string", false);
            }
            case FormulaKind.ConstructURL: {
                const f = formula as ConstructURLFormula;
                const scheme = compile(f.scheme);
                const host = compile(f.host);
                const path = compile(f.path);
                const params = compileQueryParameters(f.params);
                if (scheme === undefined || host === undefined || path === undefined || params === undefined) {
                    return undefined;
                }
                return primitiveResult("string", true);
            }
            case FormulaKind.YesCode: {
                const f = formula as YesCodeFormula;
                const url = compile(f.url);
                if (url === undefined) return undefined;
                for (const param of f.params) {
                    const name = compile(param.name);
                    const value = compile(param.value);
                    if (name === undefined || value === undefined) return undefined;
                }
                return resultWithType(f.type, true);
            }
            case FormulaKind.PluginComputation: {
                const f = formula as PluginComputationFormula;
                return resultWithType(f.resultType, f.resultTypeIsPrecise);
            }
            case FormulaKind.FilterSortLimit: {
                const f = formula as FilterSortLimitFormula;
                const rows = compile(f.rows);
                if (rows === undefined) return undefined;
                const rowsTable = definedMap(rows.type, getArrayTable);
                if (rowsTable === undefined) return undefined;
                return rows;
            }
            default:
                issues.push(`Unknown formula kind ${(formula as any).kind}`);
                return undefined;
        }
    }

    let result = compile(rootFormula);

    if (result?.type?.kind === "lat-long") {
        issues.push("Final formula result cannot be lat-long");
        result = undefined;
    }

    if (result === undefined) {
        // logError("Cannot compile formula", issues);
        // logError(JSON.stringify(formula, undefined, 4));
        if (issues.length === 0) {
            compile(rootFormula);
        }
        assert(issues.length > 0);
        return { success: false, issues, errors };
    }

    return {
        success: true,
        type: result.type as ColumnType | undefined,
        typeIsPrecise: result.typeIsPrecise,
        geocodeQuotaKeys,
    };
}

export function hasMathFormulaRandom(f: Formula): boolean {
    switch (f.kind) {
        case FormulaKind.Random:
            return true;
        case FormulaKind.UnaryMath:
            return hasMathFormulaRandom((f as UnaryMathFormula).operand);
        case FormulaKind.BinaryMath: {
            const m = f as BinaryMathFormula;
            return hasMathFormulaRandom(m.left) || hasMathFormulaRandom(m.right);
        }
        case FormulaKind.Constant:
        case FormulaKind.GetVariable:
        default:
            return false;
    }
}

const rollupThreadsThroughDisplayFormula: Record<RollupKind, boolean> = {
    [RollupKind.AllTrue]: true,
    [RollupKind.SomeTrue]: true,
    [RollupKind.Sum]: true,
    [RollupKind.CountNonEmpty]: false,
    [RollupKind.Average]: true,
    [RollupKind.Minimum]: true,
    [RollupKind.Maximum]: true,
    [RollupKind.Earliest]: true,
    [RollupKind.Latest]: true,
    [RollupKind.Range]: true,
    [RollupKind.CountNotTrue]: false,
    [RollupKind.CountTrue]: false,
    [RollupKind.CountUnique]: false,
};

export function getColumnForArraySource(
    source: ArraySourceSpecification,
    env: StaticComputationContext<SchemaInspector>
): [TableColumn | undefined, TableGlideType | undefined, ColumnType | undefined] | undefined {
    const { context: schema, table, priorSteps } = env;

    let columnTable: TableGlideType | undefined;
    let columnName: string;
    if (source.valueColumn !== undefined) {
        let tableType: ColumnType | undefined;

        if (typeof source.tableOrRelationColumn === "string") {
            if (table === undefined) return undefined;
            const column = getTableColumn(table, source.tableOrRelationColumn);
            tableType = column?.type;
        } else if (isSourceColumn(source.tableOrRelationColumn)) {
            const resolved = resolveSourceColumn(
                schema,
                source.tableOrRelationColumn,
                table,
                undefined,
                priorSteps?.map(ps => ps.node)
            );
            tableType = resolved?.type;
        } else {
            tableType = makeUniversalTableRef(source.tableOrRelationColumn);
        }

        if (tableType === undefined) return undefined;
        const relation = decomposeRelationType(tableType);
        if (relation === undefined) return undefined;
        columnTable = schema.findTable(relation.tableRef);
        columnName = source.valueColumn;
    } else if (typeof source.tableOrRelationColumn === "string") {
        columnTable = table;
        columnName = source.tableOrRelationColumn;
    } else if (
        isSourceColumn(source.tableOrRelationColumn) &&
        isActionNodeOutputSourceColumn(source.tableOrRelationColumn)
    ) {
        const resolved = resolveSourceColumn(
            schema,
            source.tableOrRelationColumn,
            table,
            undefined,
            priorSteps?.map(ps => ps.node)
        );
        if (resolved === undefined) return undefined;
        const { tableAndColumn } = resolved;
        if (tableAndColumn === undefined) return [undefined, undefined, resolved.type];
        columnTable = tableAndColumn.table;
        columnName = tableAndColumn.column.name;
    } else {
        return panic("Whole row from table not supported");
    }
    if (columnTable === undefined) return undefined;

    const column = getTableColumn(columnTable, columnName);
    if (column === undefined) return undefined;

    return [column, columnTable, column.type];
}

export function getDisplayFormulaForColumn(column: TableColumn): Formula | undefined {
    if (column.displayFormula !== undefined) {
        // This is the dummy display formula
        if (isGetDisplayFormulaInput(column.displayFormula)) return undefined;
        return column.displayFormula;
    }
    if (column.type.kind === "json") {
        return makeFormatFormula({ kind: ValueFormatKind.JSON });
    }
    return undefined;
}

export function getEffectiveDisplayFormulaForColumn(
    schemaInspector: SchemaInspector,
    table: TableGlideType,
    column: TableColumn,
    // FIXME: This should be internal, not an argument
    visited: Set<TableColumn> = new Set()
): Formula | undefined {
    function getForArraySource(arraySource: ArraySourceSpecification): Formula | undefined {
        if (arraySource.valueColumn === undefined) return undefined;
        const columnAndTable = getColumnForArraySource(arraySource, {
            context: schemaInspector,
            table,
            isAutomation: false,
        });
        if (columnAndTable === undefined) return undefined;

        const [valueColumn, columnTable] = columnAndTable;
        if (columnTable === undefined || valueColumn === undefined) return undefined;

        return getEffectiveDisplayFormulaForColumn(schemaInspector, columnTable, valueColumn, visited);
    }

    if (visited.has(column)) return undefined;
    visited.add(column);

    const displayFormula = getDisplayFormulaForColumn(column);
    if (displayFormula !== undefined) {
        return displayFormula;
    }

    if (column.formula === undefined) return undefined;
    const spec = decomposeAll(column.formula);
    if (spec === undefined) return undefined;

    switch (spec.kind) {
        case SyntheticColumnKind.Rollup:
            if (rollupThreadsThroughDisplayFormula[spec.rollupKind] !== true) return undefined;
            return getForArraySource(spec.arraySource);

        case SyntheticColumnKind.SingleValue:
            if (spec.arraySource.valueColumn === undefined) return undefined;
            return getForArraySource(spec.arraySource);

        case SyntheticColumnKind.Lookup:
            return getForArraySource(spec);

        case SyntheticColumnKind.IfThenElse: {
            let format: Formula | undefined;

            function processValue(v: ColumnOrValueSpecification<string | number>): boolean {
                if (v.kind === ColumnOrValueKind.Constant) {
                    return v.value === "";
                }
                if (v.kind === ColumnOrValueKind.Empty) {
                    return true;
                }
                if (v.kind !== ColumnOrValueKind.Column) {
                    logError("Unknown value kind for if-then-else", v.kind);
                    return false;
                }

                // Columns can't refer to action outputs, so we don't need to
                // pass `actionNodesInScope` here.
                const resolved = resolveSourceColumn(
                    schemaInspector,
                    v.column,
                    table,
                    undefined,
                    undefined
                )?.tableAndColumn;
                if (resolved === undefined) return true;

                const f = getEffectiveDisplayFormulaForColumn(
                    schemaInspector,
                    resolved.table,
                    resolved.column,
                    visited
                );
                if (f === undefined) return false;

                if (format === undefined) {
                    format = f;
                } else {
                    if (!deepEqual(format, f, { strict: true })) return false;
                }

                return true;
            }

            for (const clause of spec.clauses) {
                if (!processValue(clause.thenValue)) return undefined;
            }
            if (!processValue(spec.elseValue)) return undefined;
            return format;
        }

        default:
            return undefined;
    }
}
