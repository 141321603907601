import "firebase/compat/auth";
import firebase from "firebase/compat/app";

export function isFirebaseUserVerified() {
    return firebase.auth().currentUser?.emailVerified ?? false;
}

type IdTokenChangedCallback = (user: firebase.User | null) => void;

export function onFirebaseIdTokenChanged(callback: IdTokenChangedCallback): firebase.Unsubscribe {
    return firebase.auth().onIdTokenChanged(callback);
}
