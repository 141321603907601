import { PastAndOrFuture } from "@glide/common-core/dist/js/components/past-future";
import { parseValueAsGlideDateTimeSync } from "@glide/common-core/dist/js/computation-model/data";
import { type ComponentKind, type MutatingScreenKind, getEnumProperty, makeEnumProperty } from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { GlideDateTime } from "@glide/data-types";
import type { AppDescriptionContext, ComponentDescriptor } from "@glide/function-utils";
import {
    type WireRowComponentHydratorConstructor,
    WireComponentKind,
    type WireInflationBackend,
    type WireValueGetter,
} from "@glide/wire";

import { BaseFieldComponentHandler, defaultFieldComponent } from "./base-field";
import {
    type DateTimeFieldComponentDescription,
    pastAndOrFuturePropertyHandler,
    propertyNamePropertyHandler,
} from "./date-fields";

const ComponentKindDateTimeField: ComponentKind = "date-time-field";

export class DateTimeFieldComponentHandler extends BaseFieldComponentHandler<DateTimeFieldComponentDescription> {
    public readonly appKinds = "both";

    constructor() {
        super(ComponentKindDateTimeField, "date-time");
    }

    public getDescriptor(
        desc: DateTimeFieldComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return this.getFieldDescriptor(
            desc,
            ccc,
            "Date Time",
            "Display and edit a date time",
            "Pickers",
            "co-date-time",
            propertyNamePropertyHandler,
            false,
            getDocURL("dateTimeField"),
            mutatingScreenKind,
            "Time",
            [pastAndOrFuturePropertyHandler]
        );
    }

    protected getDefaultValueGetter(
        ib: WireInflationBackend,
        desc: DateTimeFieldComponentDescription
    ): WireValueGetter | undefined {
        const enumValue = getEnumProperty(desc.defaultValue);
        if (enumValue === "now") {
            return () => GlideDateTime.now();
        }
        return super.getDefaultValueGetter(ib, desc);
    }

    public inflate(
        ib: WireInflationBackend,
        desc: DateTimeFieldComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        return super.inflateComponent(ib, desc, (base, value, token) => ({
            ...base,
            kind: WireComponentKind.DateTimeField,
            value: { value: parseValueAsGlideDateTimeSync(value), onChangeToken: token },
            pastOrFuture: pastAndOrFuturePropertyHandler.getEnum(desc),
            withTime: true,
        }));
    }

    public static defaultComponent(column: TableColumn): DateTimeFieldComponentDescription {
        return {
            ...defaultFieldComponent(column, ComponentKindDateTimeField),
            pastAndOrFuture: makeEnumProperty(PastAndOrFuture.All),
        };
    }
}
