import type { WriteSourceType } from "@glide/common-core";
import {
    type DataStoreMutationMetadata,
    type ActionOperationsState,
    type ActionOutstandingOperationSubscribable,
    type ActionOutstandingOperationsHandler,
    type OfflineQueue,
    type ActionAppFacilities,
    type MutationResult,
    emptyActionOperations,
} from "@glide/common-core/dist/js/components/types";
import type { WritableValue, RowIndex } from "@glide/computation-model-types";
import type { ColumnValues } from "@glide/common-core/dist/js/firebase-function-types";
import type { Database } from "@glide/common-core/dist/js/Database/core";
import { type DocumentData, areRowIndexesConflicting } from "@glide/common-core/dist/js/Database";
import { type TableName, type TypeSchema, findTable } from "@glide/type-schema";
import {
    ActionKind,
    actionsMetadataCollectionForDevice,
    isSample,
    makeActionDocumentPath,
} from "@glide/common-core/dist/js/database-strings";
import { getDeviceID } from "@glide/common-core/dist/js/device-id";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { blockingWindowExit } from "@glide/common-core/dist/js/support/window-exit-blocking";
import { logError, logInfo } from "@glide/support";
import {
    type DeepWritable,
    DefaultMap,
    defined,
    definedMap,
    exceptionToError,
    exceptionToString,
} from "@glideapps/ts-necessities";
import { type DebounceContextFreeArguments, type DebounceKeySerializable, PostActionDebouncer } from "./debounce";
import { type EnqueueDataActionResult, isLikelyUnrecoverableEnqueueError } from "./enqueue-data-action";
import { convertColumnNamesToFieldNames } from "./field-names";
import { convertColumnValuesToDocumentData } from "./row-data";
import type {
    AddRowActionWithMetadataArguments,
    AddRowQueueItem,
    DeleteRowActionWithMetadataArguments,
    SetColumnsActionWithMetadataArguments,
} from "./types";

export const debounceSetColumnsTimeout = 3_000;
export const debounceDataEditorSetColumnsTimeout = 200;

export interface ActionPosterAccessors {
    appFacilities(): ActionAppFacilities;
}

export interface ActionManagerAcessors extends ActionPosterAccessors {
    database(): Database | undefined;
    appID(): string;
    appUserID(): string | undefined;
    schema(): TypeSchema;
    makeOfflineQueue(name: string, onOnline: () => void): OfflineQueue<AddRowQueueItem> | undefined;
    makeReloadResiliencyQueue<T extends { data: { jobID: string } }>(
        name: string,
        onOnline: () => void
    ): OfflineQueue<T> | undefined;
}

interface NonblockingQueueEntry {
    readonly key: DebounceKeySerializable;
    readonly data: DebounceContextFreeArguments;
    readonly withDebounce: boolean;
    readonly withSavingMessage: boolean;
}

export interface ActionPoster {
    addRowToTable(data: AddRowActionWithMetadataArguments): Promise<EnqueueDataActionResult>;
    setColumnsInRow(data: SetColumnsActionWithMetadataArguments): Promise<EnqueueDataActionResult>;
    deleteRow(data: DeleteRowActionWithMetadataArguments): Promise<EnqueueDataActionResult>;
}

export class ActionManager implements ActionOutstandingOperationSubscribable {
    // table name -> action path
    private readonly _lastAddRowActionPaths: Map<string, string> = new Map();
    private readonly _lastActionPaths: DefaultMap<string, [RowIndex, string][]> = new DefaultMap(() => []);
    private _offlineQueue: OfflineQueue<AddRowQueueItem> | undefined;
    private _addRowJobCallbacks = new Map<
        string,
        readonly [(result: EnqueueDataActionResult) => void, (e: Error) => void]
    >();
    private readonly _debouncer: PostActionDebouncer<MutationResult>;
    private _reloadResiliencyQueue: OfflineQueue<NonblockingQueueEntry> | undefined;

    private readonly outstandingOperations: DeepWritable<ActionOperationsState> = {
        started: { ...emptyActionOperations },
        performed: { ...emptyActionOperations },
        savingMessageStarted: { ...emptyActionOperations },
        savingMessagePerformed: { ...emptyActionOperations },
    };
    private readonly outstandingOperationSubscribers = new Set<ActionOutstandingOperationsHandler>();

    constructor(
        private readonly _accessors: ActionManagerAcessors,
        private readonly _deviceIDOverride: string | undefined,
        private readonly _debounceSetColumnsTimeout: number,
        private readonly _debounceDataEditorSetColumnsTimeout: number,
        private readonly _isBuilder: boolean,
        private readonly _writeSource: WriteSourceType,
        // This is the empty string for the "original" queues
        private readonly _nameInfix: string,
        private readonly _poster: ActionPoster,
        private readonly _confirmAction: (
            tableName: TableName,
            rowIndex: RowIndex,
            jobID: string,
            confirmedAtSerial: number
        ) => void,
        private readonly _rollbackAction: (tableName: TableName, rowIndex: RowIndex, jobID: string) => void,
        onJobObsolete: (tableName: TableName, jobIDA: string, jobIDB: string) => void
    ) {
        this._debouncer = new PostActionDebouncer(_isBuilder, onJobObsolete);
        this.updateOfflineQueue();
        this.resetReloadResiliencyQueue();
    }

    public getActionOutstandingOperations(): ActionOperationsState {
        return {
            started: { ...this.outstandingOperations.started },
            performed: { ...this.outstandingOperations.performed },
            savingMessageStarted: { ...this.outstandingOperations.savingMessageStarted },
            savingMessagePerformed: { ...this.outstandingOperations.savingMessagePerformed },
        };
    }

    public subscribeToOutstandingOperations(handler: ActionOutstandingOperationsHandler) {
        const had = this.outstandingOperationSubscribers.has(handler);
        if (!had) {
            this.outstandingOperationSubscribers.add(handler);
            handler(this.getActionOutstandingOperations());
        }
    }

    public unsubscribeFromOutstandingOperations(handler: ActionOutstandingOperationsHandler) {
        this.outstandingOperationSubscribers.delete(handler);
    }

    private notifyOutstandingOperationsSubscribers() {
        const outstandingOperations = this.getActionOutstandingOperations();
        for (const sub of [...this.outstandingOperationSubscribers]) {
            try {
                sub(outstandingOperations);
            } catch (e: unknown) {
                logError("Could not notify outstanding operation subscriber", e);
            }
        }
    }

    public updateOfflineQueue(): void {
        this._offlineQueue?.retire();

        this._offlineQueue = this._accessors.makeOfflineQueue(
            this._accessors.appID() + this._nameInfix + "-actions",
            this.processOfflineQueue
        );
        void this.processOfflineQueue();
    }

    public resetReloadResiliencyQueue() {
        this._reloadResiliencyQueue?.retire();
        this._reloadResiliencyQueue = this._accessors.makeReloadResiliencyQueue<NonblockingQueueEntry>(
            this._accessors.appID() + this._nameInfix + "-set-columns",
            this.processSetColumnsInRowResiliencyQueueWhenOnline
        );
        this.processSetColumnsInRowResiliencyQueueWhenOnline();
    }

    private async postAddRow(
        actionData: AddRowActionWithMetadataArguments,
        rowIndex: RowIndex | undefined
    ): Promise<EnqueueDataActionResult> {
        const response = await this._poster.addRowToTable(actionData);
        logInfo("addRowToTable job", actionData.deviceID, actionData.jobID);
        if (rowIndex !== undefined) {
            if (response.confirmedAtVersion !== undefined) {
                this._confirmAction(actionData.tableName, rowIndex, actionData.jobID, response.confirmedAtVersion);
            } else {
                this.listenForActionConfirmation(actionData.tableName, [rowIndex], actionData.jobID, undefined);
            }
        }
        return response;
    }

    private readonly processOfflineQueue = async (): Promise<void> => {
        const queue = this._offlineQueue;
        if (queue === undefined) return;

        const appUserID = this._accessors.appUserID();
        const authID = await this._accessors.appFacilities().getAuthUserID();

        await queue.processIfOnline(async item => {
            if (item.data.appUserID !== appUserID || item.data.authID !== authID) return false;
            const maybeCallbacks = this._addRowJobCallbacks.get(item.data.jobID);
            try {
                const result = await this.postAddRow(item.data, item.rowIndex);
                maybeCallbacks?.[0](result);
            } catch (e: unknown) {
                maybeCallbacks?.[1](exceptionToError(e));
            }
            return true;
        });
    };

    // Takes the path of the new action for a row and returns the path of the
    // last action that modified that row.
    private replaceLastActionPath(tableName: TableName, rowIndex: RowIndex, newActionPath: string): string | undefined {
        const entries = this._lastActionPaths.get(tableName.name);
        for (let i = 0; i < entries.length; i++) {
            const [ri, p] = entries[i];
            if (!areRowIndexesConflicting(rowIndex, ri)) continue;

            entries[i] = [ri, newActionPath];
            return p;
        }

        entries.push([rowIndex, newActionPath]);
        return undefined;
    }

    private get deviceID(): string {
        return this._deviceIDOverride ?? getDeviceID();
    }

    private makeJobIDAndDependsOn(
        tableName: TableName,
        rowIndex: RowIndex | undefined,
        actionKind: ActionKind,
        dependOnLastAddRow: boolean,
        jobID?: string
    ): { jobID: string; dependsOn: string | undefined } {
        const appID = this._accessors.appID();
        if (jobID === undefined) {
            jobID = this._accessors.appFacilities().makeRowID();
        }
        let dependsOn = definedMap(rowIndex, ri =>
            this.replaceLastActionPath(tableName, ri, `${makeActionDocumentPath(appID, actionKind)}/${jobID}`)
        );
        if (dependsOn === undefined && dependOnLastAddRow) {
            dependsOn = this._lastAddRowActionPaths.get(tableName.name);
        }
        return { jobID, dependsOn };
    }

    private listenForActionConfirmation(
        tableName: TableName,
        rowIndexes: readonly RowIndex[],
        jobID: string,
        // We call either `onConfirm` (if it's set) or `this.confirmAction`,
        // but not both.  No reason other than avoiding more special casing at
        // the moment.  We only need `onConfirm` for delete action, which
        // doesn't need `this.confirmAction`.
        onConfirm: ((serial: number) => void) | undefined
    ): void {
        const { deviceID } = this;
        const db = this._accessors.database();
        // If we don't have a database then we won't listen
        if (db === undefined) return;

        const unlisten = db.listenToDocument(actionsMetadataCollectionForDevice(deviceID), jobID, async currentDoc => {
            const confirmedAtSerial = currentDoc?.response?.actionWriteback?.confirmedAtSerial;
            if (typeof confirmedAtSerial !== "number") return;

            unlisten();

            if (onConfirm === undefined) {
                for (const rowIndex of rowIndexes) {
                    this._confirmAction(tableName, rowIndex, jobID, confirmedAtSerial);
                }
            } else {
                onConfirm(confirmedAtSerial);
            }
        });
    }

    private convertColumnValuesToFields(tableName: TableName, values: ColumnValues): DocumentData {
        const converted = convertColumnValuesToDocumentData(values);
        const table = findTable(this._accessors.schema(), tableName);
        if (table === undefined) return converted;
        return convertColumnNamesToFieldNames(new Map(), table, converted);
    }

    // `EnqueueDataActionResult` means it succeeded.
    // `true` means pretend it succeeded.
    public async addRowToTable(
        tableName: TableName,
        rowIndex: RowIndex | undefined,
        values: Record<string, WritableValue>,
        maybeJobID: string | undefined,
        metadata: DataStoreMutationMetadata,
        onError: ((e: Error) => void) | undefined,
        withSavingMessage: boolean
    ): Promise<(EnqueueDataActionResult & MutationResult) | boolean> {
        const appID = this._accessors.appID();
        const appUserID = this._accessors.appUserID();
        const appFacilities = this._accessors.appFacilities();
        const fields = this.convertColumnValuesToFields(tableName, values);
        const { jobID, dependsOn } = this.makeJobIDAndDependsOn(
            tableName,
            rowIndex,
            ActionKind.AddRowToTable,
            true,
            maybeJobID
        );
        this.outstandingOperations.started.addRowToTable += 1;
        if (withSavingMessage) {
            this.outstandingOperations.savingMessageStarted.addRowToTable += 1;
        }
        this.notifyOutstandingOperationsSubscribers();

        try {
            const authID = await appFacilities.getAuthUserID();
            const { deviceID } = this;

            // We have to set the dependency action path before we await
            // adding the document, because another Add Row action might be
            // run in between.
            this._lastAddRowActionPaths.set(
                tableName.name,
                `${makeActionDocumentPath(appID, ActionKind.AddRowToTable)}/${jobID}`
            );

            const actionData: AddRowActionWithMetadataArguments = {
                tableName,
                columnValues: fields,
                jobID,
                deviceID,
                dependsOn,
                appID,
                appUserID,
                authID,
                fromBuilder: this._isBuilder,
                fromDataEditor: metadata.fromDataEditor,
                screenPath: metadata.screenPath,
                writeSource: this._writeSource,
            };

            let result: EnqueueDataActionResult;
            if (this._offlineQueue !== undefined) {
                this._offlineQueue.enqueue({ kind: "add-row", data: actionData, rowIndex });
                result = await new Promise<EnqueueDataActionResult>(async (resolve, reject) => {
                    this._addRowJobCallbacks.set(jobID, [resolve, reject]);
                    void this.processOfflineQueue();
                });
            } else {
                result = await this.postAddRow(actionData, rowIndex);
            }
            return { ...result, jobID };
        } catch (e: unknown) {
            logError("Posting add row to table failed", e);
            onError?.(exceptionToError(e));
            if (rowIndex !== undefined) {
                this._rollbackAction(tableName, rowIndex, jobID);
            }
            // We don't report write errors in sample apps
            return isSample(appID);
        } finally {
            this.outstandingOperations.performed.addRowToTable += 1;
            if (withSavingMessage) {
                this.outstandingOperations.savingMessagePerformed.addRowToTable += 1;
            }
            this.notifyOutstandingOperationsSubscribers();
        }
    }

    public processSetColumnsInRowResiliencyQueueWhenOnline = () => {
        const queue = this._reloadResiliencyQueue;
        if (queue === undefined) return;

        const appID = this._accessors.appID();
        const appUserID = this._accessors.appUserID();

        for (const item of queue.previewQueueIfOnline()) {
            const { key, withSavingMessage } = item;
            if (key.appID !== appID) continue;
            if (key.appUserID !== appUserID) continue;

            if (!queue.claimItem(item)) continue;
            this.outstandingOperations.started.setColumnsInRow += 1;
            if (withSavingMessage) {
                this.outstandingOperations.savingMessageStarted.setColumnsInRow += 1;
            }
            this.notifyOutstandingOperationsSubscribers();
            this.processSetColumnsInRowDispatchByDebounce(item)
                .then(() => queue.confirmItem(item))
                .catch(e => {
                    logError(`Could not processSetColumnsInRowDispatchByDebounce`, exceptionToString(e));
                    queue.returnItem(item);
                })
                .finally(() => {
                    this.outstandingOperations.performed.setColumnsInRow += 1;
                    if (withSavingMessage) {
                        this.outstandingOperations.savingMessagePerformed.setColumnsInRow += 1;
                    }
                    this.notifyOutstandingOperationsSubscribers();
                });
        }
    };

    private processSetColumnsInRowDebounce = async (
        key: DebounceKeySerializable,
        mapValue: DebounceContextFreeArguments
    ): Promise<MutationResult> => {
        const { appID, appUserID, tableName, rowIndex } = key;
        const { values, fromDataEditor, screenPath } = mapValue;
        const { deviceID } = this;
        const fields = this.convertColumnValuesToFields(tableName, values);
        const authUserID = await this._accessors.appFacilities().getAuthUserID();
        const { jobID, dependsOn } = this.makeJobIDAndDependsOn(
            tableName,
            rowIndex,
            ActionKind.SetColumnsInRow,
            false,
            defined(mapValue.jobID)
        );

        const postingPayload = {
            appID,
            authID: authUserID,
            appUserID,
            fromBuilder: this._isBuilder,
            fromDataEditor,
            screenPath,
            tableName,
            rowIndex,
            columnValues: fields,
            deviceID,
            dependsOn,
            jobID,
            writeSource: this._writeSource,
        };
        const response = await this._poster.setColumnsInRow(postingPayload);

        if (response.confirmedAtVersion !== undefined) {
            void this._confirmAction(tableName, rowIndex, jobID, response.confirmedAtVersion);
        } else {
            this.listenForActionConfirmation(tableName, [rowIndex], jobID, undefined);
        }

        return { jobID, confirmedAtVersion: response.confirmedAtVersion };
    };

    private async processSetColumnsInRowDispatchByDebounce({
        key,
        data,
        withDebounce,
    }: NonblockingQueueEntry): Promise<MutationResult> {
        const { appID, tableName, rowIndex, appUserID } = key;
        const { jobID, fromBuilder, fromDataEditor, screenPath, values } = data;
        // We should be blocking exits all the way through the debounce period.
        return await blockingWindowExit(() =>
            this._debouncer.debounceOperation(
                {
                    appID,
                    appUserID,
                    tableName,
                    rowIndex,
                    values,
                    fromBuilder,
                    fromDataEditor,
                    screenPath,
                    executionTarget: this.processSetColumnsInRowDebounce,
                    debounceTimeout: fromDataEditor
                        ? this._debounceDataEditorSetColumnsTimeout
                        : this._debounceSetColumnsTimeout,
                    jobID,
                },
                !withDebounce
            )
        );
    }

    public async setColumnsInRow(
        tableName: TableName,
        rowIndex: RowIndex,
        values: Record<string, WritableValue>,
        // If this is set, we only want to listen for confirmation
        jobAlreadyExists: boolean,
        jobID: string,
        withDebounce: boolean,
        metadata: DataStoreMutationMetadata,
        onError: ((e: Error) => void) | undefined,
        withSavingMessage: boolean
    ): Promise<MutationResult> {
        if (jobAlreadyExists && getFeatureSetting("onlyListenToExistingActions")) {
            this.listenForActionConfirmation(tableName, [rowIndex], jobID, undefined);
            return { jobID, confirmedAtVersion: undefined };
        }

        const appID = this._accessors.appID();
        const appUserID = this._accessors.appUserID();

        const key = {
            appID,
            tableName,
            rowIndex,
            appUserID,
        };
        const data = {
            jobID,
            fromBuilder: this._isBuilder,
            fromDataEditor: metadata.fromDataEditor,
            screenPath: metadata.screenPath,
            values,
        };
        const item = { key, data, withDebounce, withSavingMessage };
        this._reloadResiliencyQueue?.enqueue(item);
        this._reloadResiliencyQueue?.claimItem(item);

        this.outstandingOperations.started.setColumnsInRow += 1;
        if (withSavingMessage) {
            this.outstandingOperations.savingMessageStarted.setColumnsInRow += 1;
        }
        this.notifyOutstandingOperationsSubscribers();

        // The lifecycle of `onConfirm` through _actionCompletionHandlers, and the use of onError,
        // mean we have to ensure that this action is processed in a synchronous-enough fashion
        // to clean up the entry in _actionCompletionHandlers and trigger onError in the event of a crash.
        // This prevents us from simply calling `processSetColumnsInRowResiliencyQueueWhenOnline`, as
        // we need to react to thrown exceptions in a way that can't be passed into the servicing
        // reload resiliency queue.
        try {
            const result = await this.processSetColumnsInRowDispatchByDebounce(item);
            this._reloadResiliencyQueue?.confirmItem(item);
            return result;
        } catch (e: unknown) {
            // If we can't recover from the error... we don't really have business
            // retrying it into oblivion.
            if (isLikelyUnrecoverableEnqueueError(e)) {
                this._reloadResiliencyQueue?.confirmItem(item);
            } else {
                this._reloadResiliencyQueue?.returnItem(item);
            }
            onError?.(exceptionToError(e));
            this._rollbackAction(tableName, rowIndex, jobID);
            return { jobID, confirmedAtVersion: false };
        } finally {
            this.outstandingOperations.performed.setColumnsInRow += 1;
            if (withSavingMessage) {
                this.outstandingOperations.savingMessagePerformed.setColumnsInRow += 1;
            }
            this.notifyOutstandingOperationsSubscribers();
        }
    }

    // FIXME: unify this with `deleteRows` below
    public async deleteRowAtIndex(
        tableName: TableName,
        rowIndex: RowIndex,
        metadata: DataStoreMutationMetadata,
        onError: ((e: Error) => void) | undefined,
        onConfirm: ((serial: number) => void) | undefined,
        withSavingMessage: boolean
    ): Promise<MutationResult> {
        const appFacilities = this._accessors.appFacilities();
        const appID = this._accessors.appID();
        const appUserID = this._accessors.appUserID();
        const { jobID, dependsOn } = this.makeJobIDAndDependsOn(tableName, rowIndex, ActionKind.DeleteRow, false);
        this.outstandingOperations.started.deleteRow += 1;
        if (withSavingMessage) {
            this.outstandingOperations.savingMessageStarted.deleteRow += 1;
        }
        this.notifyOutstandingOperationsSubscribers();

        try {
            const authID = await appFacilities.getAuthUserID();
            const { deviceID } = this;

            const actionData = {
                appID,
                appUserID,
                authID,
                tableName,
                rowIndex: [rowIndex],
                deviceID,
                dependsOn,
                jobID,
                fromBuilder: this._isBuilder,
                fromDataEditor: metadata.fromDataEditor,
                screenPath: metadata.screenPath,
                writeSource: this._writeSource,
            };
            const response = await this._poster.deleteRow(actionData);
            logInfo("deleteRowAtIndex job", deviceID, jobID);

            if (response.confirmedAtVersion !== undefined) {
                if (onConfirm !== undefined) {
                    onConfirm(response.confirmedAtVersion);
                } else {
                    void this._confirmAction(tableName, rowIndex, jobID, response.confirmedAtVersion);
                }
            } else if (onConfirm !== undefined) {
                this.listenForActionConfirmation(tableName, [rowIndex], jobID, onConfirm);
            }
            return { jobID, confirmedAtVersion: response.confirmedAtVersion };
        } catch (e: unknown) {
            onError?.(exceptionToError(e));
            this._rollbackAction(tableName, rowIndex, jobID);
            return { jobID: undefined, confirmedAtVersion: false };
        } finally {
            this.outstandingOperations.performed.deleteRow += 1;
            if (withSavingMessage) {
                this.outstandingOperations.savingMessagePerformed.deleteRow += 1;
            }
            this.notifyOutstandingOperationsSubscribers();
        }
    }

    public async deleteRows(
        tableName: TableName,
        rowIndexes: readonly RowIndex[],
        metadata: DataStoreMutationMetadata,
        jobID: string,
        onError: ((e: Error) => void) | undefined,
        withSavingMessage: boolean
    ): Promise<MutationResult> {
        const appFacilities = this._accessors.appFacilities();
        const appID = this._accessors.appID();
        const appUserID = this._accessors.appUserID();
        const { dependsOn } = this.makeJobIDAndDependsOn(tableName, jobID, ActionKind.DeleteRow, false);
        this.outstandingOperations.started.deleteRow += 1;
        if (withSavingMessage) {
            this.outstandingOperations.savingMessageStarted.deleteRow += 1;
        }
        this.notifyOutstandingOperationsSubscribers();

        try {
            const authID = await appFacilities.getAuthUserID();
            const { deviceID } = this;

            const actionData = {
                appID,
                appUserID,
                authID,
                tableName,
                rowIndex: rowIndexes,
                deviceID,
                dependsOn,
                jobID,
                fromBuilder: this._isBuilder,
                fromDataEditor: metadata.fromDataEditor,
                screenPath: metadata.screenPath,
                writeSource: this._writeSource,
            };
            const response = await this._poster.deleteRow(actionData);
            if (response.confirmedAtVersion !== undefined) {
                for (const rowIndex of rowIndexes) {
                    void this._confirmAction(tableName, rowIndex, jobID, response.confirmedAtVersion);
                }
            } else {
                this.listenForActionConfirmation(tableName, rowIndexes, jobID, undefined);
            }
            return { jobID, confirmedAtVersion: response.confirmedAtVersion };
        } catch (e: unknown) {
            onError?.(exceptionToError(e));
            for (const rowIndex of rowIndexes) {
                this._rollbackAction(tableName, rowIndex, jobID);
            }
            return { jobID: undefined, confirmedAtVersion: false };
        } finally {
            this.outstandingOperations.performed.deleteRow += 1;
            if (withSavingMessage) {
                this.outstandingOperations.savingMessagePerformed.deleteRow += 1;
            }
            this.notifyOutstandingOperationsSubscribers();
        }
    }

    public retire(): void {
        this._offlineQueue?.retire();

        // When we retire this ActionManager, we should notify all subscribers that
        // we've effectively abandoned the operations. This is not exactly true, but
        // for the purposes of monitoring, it may as well be.
        this.outstandingOperations.performed = { ...this.outstandingOperations.started };
        this.outstandingOperations.savingMessagePerformed = {
            ...this.outstandingOperations.savingMessageStarted,
        };
        this.notifyOutstandingOperationsSubscribers();
        this.outstandingOperationSubscribers.clear();
    }
}
