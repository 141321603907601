import deepEqual from "deep-equal";
import type { DocumentData, UserSpecificRow } from "@glide/common-core/dist/js/Database";
import type { TableGlideType } from "@glide/type-schema";
import { convertValueFromSerializable } from "@glide/data-types";
import { truthify } from "@glide/support";

export function compareUserSpecificRowsForApplication(left: UserSpecificRow, right: UserSpecificRow) {
    if (left.$rowVersion === undefined) {
        if (right.$rowVersion === undefined) return 0;
        return -1;
    } else if (right.$rowVersion === undefined) {
        return 1;
    } else {
        return left.$rowVersion - right.$rowVersion;
    }
}

export function liftUserSpecificData(
    table: TableGlideType,
    reverseSortedUserDatas: readonly UserSpecificRow[] | undefined
): DocumentData {
    const ret: DocumentData = {};
    if (reverseSortedUserDatas === undefined) return ret;

    for (const { name, isUserSpecific, formula } of table.columns) {
        // FIXME: use `isComputedColumn` instead of looking at `formula`
        if (!truthify(isUserSpecific) || formula !== undefined) continue;

        for (const userData of reverseSortedUserDatas) {
            const value = convertValueFromSerializable(userData.data[name]);
            if (value !== undefined) {
                ret[name] = userData.data[name];
                break;
            }
        }
    }
    return ret;
}

/**
 * Reports the difference between right and left, i.e. right - left.
 *
 * Each key-value pair for which the key is missing in left but present
 * in right is returned as part of the returned object.
 *
 * For each key in left:
 * 1. If right does not contain the same key, the value for the key
 *    in the returned object is explicitly "undefined" to signify
 *    that the value has been deleted.
 * 2. If right contains the same key but a different value for the key,
 *    the value for the key in the returned object is the value for the
 *    key in the right object.
 * 3. Otherwise, left and right contain the same value for the same key,
 *    and the key is removed entirely from the returned object.
 *
 * There are two ways in which this behavior can be modified:
 * 1. If a key is present in the alwaysInclude set, the value for the key
 *    in the right object will be set as the value for the key in the
 *    returned object, so long as said key also exists in the left object.
 * 2. If a key is present in the neverInclude set, even if the key were
 *    expected to be set by the usual process, it is not present in the
 *    returned object.
 */
export function diffDocumentData(
    left: DocumentData,
    right: DocumentData,
    alwaysInclude: ReadonlySet<string> | undefined,
    neverInclude: ReadonlySet<string> | undefined
): DocumentData {
    const ret = { ...right };
    const keysSet = new Set([...Object.keys(left), ...Object.keys(right)]);
    for (const key of keysSet) {
        if (alwaysInclude?.has(key) === true) {
            // This is right - left, hence we always return the value
            // of the right key.
            ret[key] = right[key];
            continue;
        }
        if (neverInclude?.has(key) === true) {
            delete ret[key];
            continue;
        }

        if (left[key] === undefined) {
            continue;
        } else if (right[key] === undefined) {
            // Explicitly set "undefined" is equivalent to "deleted".
            // We can still test for this with (key in ret).
            ret[key] = undefined;
        } else if (deepEqual(left[key], right[key], { strict: true })) {
            delete ret[key];
        }
    }
    return ret;
}

export function getOnlyColumns(doc: DocumentData, columnNames: Iterable<string>): DocumentData {
    const result: DocumentData = {};
    for (const n of columnNames) {
        result[n] = doc[n];
    }
    return result;
}
