import "twin.macro";

import { TextComponentStyle } from "@glide/component-utils";
import type { WireCommentsComponent } from "@glide/fluent-components/dist/js/base-components";
import { CommentsStyle } from "@glide/fluent-components/dist/js/base-components";
import classNames from "classnames";

import { useIsCommentScreen } from "../../../chrome/content/lib/screen-special-cases";
import { Img } from "../../../components/img/img";
import { Text } from "../../../components/text/text";
import { useIsInsideModal } from "../../../wire-lib";

interface EmptyCommentsProps {
    readonly emptyMessage: WireCommentsComponent["emptyMessage"];
    readonly componentStyle: CommentsStyle;
}

export const EmptyComments: React.VFC<EmptyCommentsProps> = p => {
    const { emptyMessage, componentStyle } = p;
    const isInsideOverlay = useIsInsideModal();
    const isCommentScreen = useIsCommentScreen();
    return (
        <div
            data-testid="empty-comments"
            className={classNames(isInsideOverlay && "inside-overlay", !isCommentScreen && "inline-comment")}
            tw="flex flex-col gap-y-10 [&.inline-comment]:(gap-y-6 mt-12 mb-8) mt-32 justify-center items-center [&.inside-overlay]:mb-10">
            <div>
                <ImgIllustration componentStyle={componentStyle} />
            </div>
            <Text tw="text-text-contextual-xpale" variant={TextComponentStyle.regular}>
                {emptyMessage}
            </Text>
        </div>
    );
};

interface ImgIllustrationProps {
    readonly componentStyle: CommentsStyle;
}

const ImgIllustration: React.VFC<ImgIllustrationProps> = p => {
    const src =
        p.componentStyle === CommentsStyle.Comments
            ? "/images/comments-illustration.png"
            : "/images/chat-illustration.png";
    const isCommentScreen = useIsCommentScreen();
    return (
        <Img className={classNames(!isCommentScreen && "inline-comment")} src={src} tw="w-64 [&.inline-comment]:w-48" />
    );
};
