import {
    type TableName,
    nativeTableRowIDColumnName,
    areTableNamesEqual,
    type TableColumn,
    type TableGlideType,
    type TypeSchema,
    getTableName,
    isPrimitiveType,
} from "@glide/type-schema";
import { doesTableAllowAddingBasicColumns } from "@glide/common-core/dist/js/schema-properties";
import { assertNever } from "@glideapps/ts-necessities";

export function renameTableInSchema(schema: TypeSchema, tableName: TableName, newName: string): TypeSchema {
    const { tables } = schema;
    const newTables = tables.map(t => {
        if (!areTableNamesEqual(getTableName(t), tableName)) return t;

        return { ...t, sheetName: newName };
    });
    return { ...schema, tables: newTables };
}

export function canAddRowIDColumn(table: TableGlideType): boolean {
    const externalSource = table.sourceMetadata?.externalSource;
    if (externalSource === undefined) {
        return doesTableAllowAddingBasicColumns(table);
    }
    switch (externalSource.type) {
        case "airtable":
            return doesTableAllowAddingBasicColumns(table);
        case "bigquery": // Not yet
        case "excel-online":
        case "mysql-gcp":
        case "data-plugin": // Not yet
        case "unknown":
            return false;
        case "queryable-plugin":
            return table.columns.some(c => c.name === nativeTableRowIDColumnName);
        default:
            return assertNever(externalSource);
    }
}

type TitleAndImageColumns = [title: TableColumn | undefined, image: TableColumn | undefined];

export function findTitleAndImageColumns(table: TableGlideType): TitleAndImageColumns {
    let titleColumn: TableColumn | undefined;
    let imageColumn: TableColumn | undefined;
    for (const c of table.columns) {
        if (titleColumn !== undefined && imageColumn !== undefined) break;

        if (c.hidden === true || c.isProtected === true) continue;
        if (c.name === table.rowIDColumn) continue;

        if (c.type.kind === "image-uri" || (c.type.kind === "array" && c.type.items.kind === "image-uri")) {
            if (imageColumn === undefined) {
                imageColumn = c;
            }
            continue;
        }

        if (c.type.kind === "string") {
            if (titleColumn?.type.kind !== "string") {
                titleColumn = c;
            }
            continue;
        }

        if (isPrimitiveType(c.type)) {
            if (titleColumn === undefined) {
                titleColumn = c;
            }
        }
    }

    return [titleColumn, imageColumn];
}

export function getDefaultTable(schema: TypeSchema): TableGlideType | undefined {
    return schema.tables.find(t => !getTableName(t).isSpecial);
}
