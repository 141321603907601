import { type ActionDescription, ActionKind } from "@glide/app-description";
import type {
    WireActionHydrator,
    WireActionInflationBackend,
    WireActionResult,
    WireActionResultBuilder,
} from "@glide/wire";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import type { ActionAvailability } from "@glide/function-utils";
import { actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";
import { ICON_BASE, LIME_500 } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins";

interface ReshuffleActionDescription extends ActionDescription {}

export class ReshuffleHandler extends BaseActionHandler<ReshuffleActionDescription> {
    public readonly kind = ActionKind.Reshuffle;
    public readonly iconName: GlideIconProps = {
        icon: "st-shuffle",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: LIME_500,
    };

    public readonly name: string = "Shuffle order";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Data,
            groupItemOrder: 5,
            needsScreenContext: false,
            properties: [],
        };
    }

    public inflate(
        _ib: WireActionInflationBackend,
        _desc: ReshuffleActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        return () => {
            return async ab => {
                ab.reshuffle();
                return arb.success();
            };
        };
    }
}
