import type { SerializedQuery } from "@glide/computation-model-types";
import { hasOwnProperty } from "@glideapps/ts-necessities";

export function getColumnsUsedByQuery(q: SerializedQuery): ReadonlySet<string> {
    const columnsNames = new Set<string>();

    for (const conjunction of q.conditions) {
        for (const cond of conjunction) {
            if (hasOwnProperty(cond, "column")) {
                columnsNames.add(cond.column.columnName);
            } else if (hasOwnProperty(cond, "lhs")) {
                columnsNames.add(cond.lhs.columnName);
                if (hasOwnProperty(cond.rhs, "columnName")) {
                    columnsNames.add(cond.rhs.columnName);
                }
            }
        }
    }

    for (const s of q.sort) {
        columnsNames.add(s.columnName);
    }

    if (q.search !== undefined) {
        for (const cn of q.search.columnNames) {
            columnsNames.add(cn);
        }
    }

    if (q.groupBy !== undefined) {
        for (const cn of q.groupBy.columns) {
            columnsNames.add(cn);
        }

        for (const agg of q.groupBy.aggregates) {
            columnsNames.add(agg.column);
        }

        for (const c of q.groupBy.sort) {
            columnsNames.add(c.columnName);
        }
    }

    return columnsNames;
}
