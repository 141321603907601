import { getLocalizedString } from "@glide/localization";
import { MenuItemPurpose } from "@glide/common-core/dist/js/components/types";
import { asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import { type ComponentDescription, type PropertyDescription, MutatingScreenKind } from "@glide/app-description";
import {
    type DefaultableComponentDescription,
    type InputOutputTables,
    makeEmptyComponentDescription,
} from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type {
    WireAppMenuItem,
    WireAppSignatureFieldComponent,
    WireAppSignaturePadComponent,
} from "@glide/fluent-components/dist/js/base-components";
import type { WireSignatureFieldDescription } from "@glide/fluent-components/dist/js/fluent-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    isRequiredPropertyHandler,
    makeTextPropertyDescriptor,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import {
    type WireActionBackend,
    type WireInflationBackend,
    type WireRowComponentHydratorConstructor,
    type WireScreen,
    type WireAction,
    WireActionResult,
    ValueChangeSource,
    WireComponentKind,
} from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";
import isBoolean from "lodash/isBoolean";
import {
    encodeScreenKey,
    inflateEditablePropertyWithDefault,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import { doesMutatingScreenSupportIsRequired, makeDefaultValuePropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

const ComponentKindSignature = "signature";

const imagePropertyHandler = new ColumnPropertyHandler(
    "imageURLProperty",
    "Column",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable, ColumnPropertyFlag.EditedInApp],
    undefined,
    ["signature"],
    getPrimitiveColumnsSpec,
    "image-uri",
    PropertySection.Data
);

interface SignatureDescription extends DefaultableComponentDescription {
    readonly titleProperty: PropertyDescription | undefined;
    readonly messageProperty: PropertyDescription | undefined;
    readonly placeholderProperty: PropertyDescription | undefined;
    readonly imageURLProperty: PropertyDescription;
    readonly isRequired: PropertyDescription | undefined;
}

export class SignatureComponentHandler extends ComponentHandlerBase<SignatureDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindSignature);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public needValidation(): boolean {
        return true;
    }

    public getDescriptor(
        desc: SignatureDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [
            makeTextPropertyDescriptor("titleProperty", "Title", "Signature", false, mutatingScreenKind, {
                searchable: false,
                emptyByDefault: true,
                propertySection: PropertySection.Design,
            }),
            makeTextPropertyDescriptor("placeholderProperty", "Hint text", "Add signature", false, mutatingScreenKind, {
                searchable: false,
                emptyByDefault: true,
                propertySection: PropertySection.Design,
            }),
            makeTextPropertyDescriptor(
                "messageProperty",
                "Message",
                "Sign using your finger",
                false,
                mutatingScreenKind,
                {
                    searchable: false,
                    emptyByDefault: true,
                    propertySection: PropertySection.Design,
                }
            ),
            imagePropertyHandler,
            ...this.getBasePropertyDescriptors(),
        ];

        if (doesMutatingScreenSupportIsRequired(mutatingScreenKind, desc?.propertyName)) {
            properties.push(isRequiredPropertyHandler);
        }

        const defaultDescr = makeDefaultValuePropertyDescriptor(
            ccc,
            desc?.propertyName,
            mutatingScreenKind,
            "image-uri",
            "Enter URL"
        );
        if (defaultDescr !== undefined) {
            properties.push(defaultDescr);
        }

        return {
            name: "Signature",
            description: "Lets the app user collect signatures",
            img: "co-signature",
            group: "Entry Fields",
            helpUrl: getDocURL("signature"),
            quotaWarning: ccc.eminenceFlags.proComponents ? undefined : "Enter 10 signatures per month on free apps.",
            properties,
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: SignatureDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const {
            forBuilder,
            mutatingScreenKind,
            adc: { appKind },
        } = ib;

        // Add and Form screens require that the submitted value is created by
        // the signature field.  Other screens allow submitting with existing
        // values.
        // https://github.com/quicktype/glide/issues/15472
        const allowExistingValue =
            mutatingScreenKind === undefined || mutatingScreenKind === MutatingScreenKind.EditScreen;

        const { getter: imageGetter, isInContext } = inflateEditablePropertyWithDefault(
            ib,
            "set",
            imagePropertyHandler.getProperty(desc),
            asMaybeString,
            ""
        );
        if (imageGetter === undefined) return undefined;

        const [titleGetter] = inflateStringProperty(ib, desc.titleProperty, true);
        const [messageGetter] = inflateStringProperty(ib, desc.messageProperty, true);
        const [placeholderGetter] = inflateStringProperty(ib, desc.placeholderProperty, true);

        const isRequired = isRequiredPropertyHandler.getSwitch(desc);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const image = imageGetter(hb);
            if (image?.onChangeToken === undefined) return undefined;

            const shouldWrite = hb.getState("shouldWrite", isBoolean, false, false);
            const didWrite =
                isRequired && !allowExistingValue ? hb.getState("didWrite", isBoolean, false, false) : undefined;
            const subsidiaryOpen = hb.getState("subsidiaryOpen", isBoolean, false, false);
            function setSubsidiaryOpen(ab: WireActionBackend, isOpen: boolean) {
                return ab.valueChanged(subsidiaryOpen.onChangeToken, isOpen, ValueChangeSource.User);
            }

            let onTap: WireAction | undefined;
            let subsidiaryScreen: WireScreen | undefined;
            if (subsidiaryOpen.value) {
                const hasPaths = hb.getState("hasPaths", isBoolean, false, false);

                function resetShouldWrite(ab: WireActionBackend) {
                    if (!shouldWrite.value) return;
                    ab.valueChanged(shouldWrite.onChangeToken, false, ValueChangeSource.User);
                }
                const cancelToken = shouldWrite.value
                    ? undefined
                    : hb.registerAction("cancel", async ab => {
                          setSubsidiaryOpen(ab, false);
                          resetShouldWrite(ab);
                          return WireActionResult.nondescriptSuccess();
                      });
                const cancelMenuItem: WireAppMenuItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title: getLocalizedString("cancel", appKind),
                    icon: "00-01-glide-close",
                    style: "platform-cancel",
                    purpose: undefined,
                    action: { token: cancelToken },
                };
                const doneToken =
                    !hasPaths.value || shouldWrite.value
                        ? undefined
                        : hb.registerAction("done", async ab => {
                              ab.valueChanged(shouldWrite.onChangeToken, true, ValueChangeSource.User);
                              return WireActionResult.nondescriptSuccess();
                          });
                const doneMenuItem: WireAppMenuItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title: getLocalizedString("done", appKind),
                    icon: "check",
                    style: "platform-accept",
                    purpose: MenuItemPurpose.SetValue,
                    action: definedMap(doneToken, t => ({ token: t })),
                };
                let message = messageGetter(hb) ?? "";
                if (message === "") {
                    message = getLocalizedString("signatureMessage", appKind);
                }
                const onWriteCompleteToken = !shouldWrite.value
                    ? undefined
                    : hb.registerAction("onWriteComplete", async ab => {
                          resetShouldWrite(ab);
                          setSubsidiaryOpen(ab, false);
                          if (didWrite !== undefined) {
                              ab.valueChanged(didWrite.onChangeToken, true, ValueChangeSource.User);
                          }
                          return WireActionResult.nondescriptSuccess();
                      });
                const signaturePad: WireAppSignaturePadComponent = {
                    kind: WireComponentKind.AppSignaturePad,
                    message,
                    hasPaths,
                    onWriteComplete: definedMap(onWriteCompleteToken, t => ({ token: t })),
                    image,
                };
                subsidiaryScreen = {
                    key: encodeScreenKey(`signature-${desc.componentID}`),
                    title: getLocalizedString("signature", appKind),
                    flags: [],
                    specialComponents: [cancelMenuItem, doneMenuItem],
                    components: [signaturePad],
                    isInModal: true,
                    tabIcon: "",
                };
            } else {
                const token = hb.registerAction("openSubsidiary", async ab => setSubsidiaryOpen(ab, true));
                onTap = { token };
            }

            let title = titleGetter(hb) ?? "";
            if (title === "") {
                title = getLocalizedString("signature", appKind);
            }

            let placeholder = placeholderGetter(hb) ?? "";
            if (placeholder === "") {
                placeholder = getLocalizedString("addSignature", appKind);
            }

            const component: WireAppSignatureFieldComponent = {
                kind: WireComponentKind.AppSignatureField,
                ...spreadComponentID(desc.componentID, forBuilder),
                image,
                title,
                placeholder,
                onTap,
            };
            const hasValue = image.value !== "";
            // We're valid if
            // * we're not currently uploading (when `shouldWrite` is set) and
            //   * we're not required or
            //     * we currently have a value and
            //     * if we're an Add/Form screen, we wrote the value
            const isValid =
                !shouldWrite.value && (!isRequired || (hasValue && (allowExistingValue || didWrite?.value === true)));
            return {
                component,
                isValid,
                editsInContext: isInContext,
                hasValue,
                subsidiaryScreen,
            };
        });
    }

    public convertToPage(desc: SignatureDescription, _ccc: AppDescriptionContext): ComponentDescription {
        const signature: WireSignatureFieldDescription = {
            ...makeEmptyComponentDescription(WireComponentKind.SignatureField),
            title: desc.titleProperty,
            signature: desc.imageURLProperty,
            isRequired: desc.isRequired,
        };
        return signature;
    }
}
