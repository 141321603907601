import { AppKind } from "@glide/location-common";
import { DeviceFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import type { RootState, UIState } from "./types";

const defaultUIState: UIState = {
    leftPanelState: { shouldExpand: true, copyingSample: false },
    componentSelector: { isOpen: false, targetIndexes: undefined },
    previewPlatform: "iOS",
    deviceFormFactor: DeviceFormFactor.Phone,
    selectedComponent: undefined,
    selectedColumn: undefined,
    selectMode: "play",
    maxPageWidth: undefined,
    desiredHeaderSection: undefined,
    highlightComponent: undefined,
    pagesCustomCssEnabled: false,
    pagesCustomCssHasErrors: false,
};

export const defaultRootState: RootState = {
    userData: null,
    organizations: [],
    organizationsLoaded: false,
    subscriptions: [],
    apps: {},
    tiles: {},
    uiState: defaultUIState,
    tables: {
        selectedTable: undefined,
        editedColumn: undefined,
        isValid: false,
        showAllData: true,
        columnFilter: "",
        gridSelection: undefined,
        triggerNCMPermanceAnalysis: {
            shouldTrigger: false,
            type: "strict",
        },
        ncmTimingStats: undefined,
    },
    newComponents: new Set(),
    templateSubmissions: {},
    templateStore: {},
    search: {
        showSearch: false,
        childColumnNames: undefined,
    },
    pasteboard: {
        components: [],
        appKind: AppKind.App,
    },
    newQueries: {},
    existingQueries: {},
};
