import { ActionKind } from "@glide/common-core/dist/js/database-strings";
import {
    type TableGlideType,
    getTableColumnDisplayName,
    getTableName,
    isColumnWritable,
    isPrimitiveType,
} from "@glide/type-schema";
import type {
    MutateTablesRequestBody,
    MutationRowIndex,
    QueryTablesRequestBody,
    TableMutation,
} from "@glide/common-core/dist/js/firebase-function-types";
import { mapFilterUndefined } from "@glideapps/ts-necessities";
import fromPairs from "lodash/fromPairs";

function makeAPIColumnValues(table: TableGlideType, forAddingRows: boolean): Record<string, unknown> {
    return fromPairs(
        mapFilterUndefined(table.columns, c => {
            if (c.isUserSpecific === true) return undefined;
            const opts = { allowProtected: false, allowHidden: false, allowArrays: true };
            if (!isColumnWritable(c, table, forAddingRows, opts)) return undefined;
            const displayName = getTableColumnDisplayName(c);
            return [c.name, isPrimitiveType(c.type) ? displayName : [displayName]];
        })
    );
}

function makeMutationRowIndex(table: TableGlideType): MutationRowIndex {
    if (table.rowIDColumn !== undefined) {
        return { rowID: "ROW-ID" };
    } else {
        return { rowIndex: 123 };
    }
}

export function makeSetColumnsMutation(table: TableGlideType): TableMutation {
    return {
        kind: ActionKind.SetColumnsInRow,
        tableName: getTableName(table).name,
        columnValues: makeAPIColumnValues(table, false),
        ...makeMutationRowIndex(table),
    };
}

export function makeDeleteRowMutation(table: TableGlideType): TableMutation {
    return {
        kind: ActionKind.DeleteRow,
        tableName: getTableName(table).name,
        ...makeMutationRowIndex(table),
    };
}

export function makeAddRowMutation(table: TableGlideType): TableMutation {
    return {
        kind: ActionKind.AddRowToTable,
        tableName: getTableName(table).name,
        columnValues: makeAPIColumnValues(table, true),
    };
}

export function makeTableMutationBody(appID: string, mutation: TableMutation): MutateTablesRequestBody {
    return {
        appID,
        mutations: [mutation],
    };
}

export function makeQueryTableBody(appID: string, table: TableGlideType): QueryTablesRequestBody {
    return {
        appID,
        queries: [{ tableName: getTableName(table).name, utc: true }],
    };
}
