import { type QuotaDocument, QuotaKind, quotaInfos } from "@glide/common-core/dist/js/Database/quotas";
import type { SerializedApp } from "@glide/app-description";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { usePlayerEminenceFlags } from "@glide/player-core";
import { isResponseOK } from "@glide/support";
import { defined, isEnumValue } from "@glideapps/ts-necessities";
import * as React from "react";
import "twin.macro";

const POLL_INTERVAL = 15 * 60 * 1000; // 15 minutes
const SOFT_ENFORCEMENT_DISMISS_TIMEOUT = 3000;

function useQuotaViolations(): QuotaKind[] {
    const [quotaViolations, setQuotaViolations] = React.useState<QuotaKind[]>([]);

    const appID = defined(useAppID());
    const eminenceFlags = usePlayerEminenceFlags(appID);

    React.useEffect(() => {
        const poll = async () => {
            const appFacilities = getAppFacilities();
            const res = await appFacilities.callCloudFunction("getQuotaStateForApp", { appID }, {});

            if (!isResponseOK(res)) {
                throw new Error(`Could not get quotas for app ${appID}: ${res?.statusText}`);
            }

            // There's no runtime validation for this type
            const quotaDoc = (await res.json()) as QuotaDocument;
            const quotaValues = quotaDoc.quotas;

            const reachedQuotas: QuotaKind[] = [];
            for (const [kind, values] of Object.entries(quotaValues)) {
                if (values === undefined) continue;
                if (!isEnumValue(QuotaKind, kind)) continue;

                const quotaValue = quotaValues[kind]?.current;
                if (quotaValue === undefined) continue;

                const { prepaidUsage, maxOverage } = eminenceFlags.quotas[kind] ?? {
                    prepaidUsage: Number.MAX_SAFE_INTEGER,
                    maxOverage: 0,
                };

                const limit = prepaidUsage + maxOverage;
                const reached = quotaValue > limit;
                if (reached) {
                    reachedQuotas.push(kind);
                }
            }

            const priorityOrder = [QuotaKind.Updates, QuotaKind.RowsUsed, QuotaKind.FileBytesUsed];
            const newViolations = reachedQuotas
                .filter(k => priorityOrder.includes(k))
                .sort((a, b) => {
                    return priorityOrder.indexOf(a) - priorityOrder.indexOf(b);
                });

            setQuotaViolations(newViolations);
        };

        void poll();
        const intervalID = setInterval(poll, POLL_INTERVAL);

        return () => {
            clearInterval(intervalID);
        };
    }, [appID, eminenceFlags.quotas]);

    return quotaViolations;
}

interface BillingEnforcementProps {
    readonly serializedApp: SerializedApp;
}

export const BillingEnforcement: React.FC<BillingEnforcementProps> = p => {
    const { serializedApp } = p;

    const appID = defined(useAppID());
    const eminenceFlags = usePlayerEminenceFlags(appID);

    const quotaViolations = useQuotaViolations();

    const softEnforcementEnabled =
        (getFeatureSetting("softEnforcementEnabled") && eminenceFlags.quotaEnforcementScheme === "free") ||
        (getFeatureSetting("paidSoftEnforcementEnabled") && eminenceFlags.quotaEnforcementScheme === "paid") ||
        serializedApp.features?.forceSoftEnforcement === true;

    const hardEnforcementEnabled =
        (getFeatureSetting("hardEnforcementEnabled") && eminenceFlags.quotaEnforcementScheme === "free") ||
        (getFeatureSetting("paidHardEnforcementEnabled") && eminenceFlags.quotaEnforcementScheme === "paid") ||
        serializedApp.features?.forceHardEnforcement === true;

    const shouldEnforce = (softEnforcementEnabled || hardEnforcementEnabled) && quotaViolations.length > 0;

    if (!shouldEnforce) {
        return null;
    }

    return <BlockingMessage quotaViolated={quotaViolations[0]} hardEnforcementEnabled={hardEnforcementEnabled} />;
};

interface BlockingMessageProps {
    readonly quotaViolated: QuotaKind;
    readonly hardEnforcementEnabled: boolean;
}

const BlockingMessage: React.FC<BlockingMessageProps> = p => {
    const { quotaViolated, hardEnforcementEnabled } = p;

    const [dismissedMessage, setDismissedMessage] = React.useState(false);
    const [canDismiss, setCanDismiss] = React.useState(false);

    const firstQuotaViolation = quotaInfos.find(q => {
        return q.kind === quotaViolated;
    });

    const quotaModalContent = firstQuotaViolation?.shortName ?? "Usage";
    const blockingMessageTitle = `This team has reached its ${quotaModalContent.toLowerCase()} limit`;

    const onDismiss = () => {
        if (canDismiss) {
            setDismissedMessage(true);
        }
    };

    const doSoftEnforcement = !hardEnforcementEnabled && quotaViolated !== QuotaKind.Updates;

    React.useEffect(() => {
        const timeoutID = setTimeout(() => {
            if (doSoftEnforcement) {
                setCanDismiss(true);
            }
        }, SOFT_ENFORCEMENT_DISMISS_TIMEOUT);

        return () => {
            clearTimeout(timeoutID);
        };
    }, [doSoftEnforcement]);

    if (dismissedMessage) {
        return null;
    }

    return (
        <div
            onClick={onDismiss}
            tw="absolute inset-0 p-10 [background-color: rgba(17, 24, 39, 0.33)] [z-index: 100000] text-text-dark flex flex-col justify-center items-center"
        >
            <div tw="w-full [max-width: 480px] rounded-xl shadow-2xl-dark">
                <div tw="rounded-xl overflow-hidden [background-color: #374664E0] backdrop-blur backdrop-filter">
                    <div tw="pt-10 pb-8 px-10 bg-bg-front" onClick={e => e.stopPropagation()}>
                        <h2 tw="font-bold text-xl mb-2">{blockingMessageTitle}</h2>
                        <div tw="text-sm whitespace-pre-wrap break-words mb-2">
                            Please contact the app owner to resolve this issue.
                        </div>

                        {doSoftEnforcement && (
                            <button
                                disabled={!canDismiss}
                                tw="transition cursor-pointer rounded-lg px-3 py-2 mr-2 text-white [font-size: 13px] font-semibold [background-color: #374664E0] mt-2 disabled:opacity-20"
                                onClick={onDismiss}
                            >
                                Continue
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
