import type { WireSpinnerComponent } from "@glide/fluent-components/dist/js/fluent-components";
import "twin.macro";
import type { WireRenderer } from "../wire-renderer";
import { GlideIcon } from "@glide/common";

export const Spinner: WireRenderer<WireSpinnerComponent> = p => {
    const { size } = p;

    return (
        <div
            className={size}
            tw="flex justify-center items-center pt-3 w-full text-icon-base [&.size-small]:py-2 [&.size-medium]:py-6 [&.size-large]:py-16 gp-md:([&.size-small]:py-4 [&.size-medium]:py-12 [&.size-large]:py-24)">
            <GlideIcon kind="stroke" icon="st-half-spinner" iconSize={24} spin={true} />
        </div>
    );
};
