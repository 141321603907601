import * as glide from "@glide/plugins";
import { isDefined } from "@glide/support";

// Configuration constants
const ZENROWS_API_BASE_URL = "https://api.zenrows.com/v1/";
const URL_REGEX = /^https?:\/\/.+/i;

// API Error status codes and messages
enum ZenRowsErrorCode {
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PAYMENT_REQUIRED = 402,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    TOO_MANY_REQUESTS = 429,
    INTERNAL_ERROR = 500,
    PARSE_ERROR = 502,
    TIMEOUT = 504,
}

const ERROR_MESSAGES: Record<ZenRowsErrorCode, string> = {
    [ZenRowsErrorCode.BAD_REQUEST]: "Bad Request. Check if URL and parameters are valid",
    [ZenRowsErrorCode.UNAUTHORIZED]: "Unauthorized. Check your API key",
    [ZenRowsErrorCode.PAYMENT_REQUIRED]: "Payment Required. Check your subscription status",
    [ZenRowsErrorCode.FORBIDDEN]: "Forbidden. Verify your account or check if IP is blocked",
    [ZenRowsErrorCode.NOT_FOUND]: "Page or Site Not Found",
    [ZenRowsErrorCode.TOO_MANY_REQUESTS]: "Too Many Requests. Rate limit or concurrency exceeded",
    [ZenRowsErrorCode.INTERNAL_ERROR]: "Internal Server Error",
    [ZenRowsErrorCode.PARSE_ERROR]: "Could not parse content",
    [ZenRowsErrorCode.TIMEOUT]: "Request timeout exceeded",
};

// Parameter for the API key
const apiKeyParam = glide.makeParameter({
    type: "secret",
    name: "API Key",
    description: "Your ZenRows API key",
    required: true,
    placeholder: "e.g. 1234567890abcdef...",
});

export const plugin = glide.newPlugin({
    id: "zenrows",
    name: "ZenRows",
    description: "AI-powered web scraping service with JavaScript rendering and anti-bot protection",
    icon: "https://res.cloudinary.com/glide/image/upload/v1741682976/glideapps.com/integrations/Zenrows.png",
    tier: "starter",
    parameters: {
        apiKey: apiKeyParam,
    },
    documentationUrl: "https://www.glideapps.com/docs/zenrows",
});

// Parameter for the target URL
const urlParam = glide.makeParameter({
    type: "string",
    name: "URL",
    description: "The webpage URL to scrape",
    required: true,
    placeholder: "https://example.com",
    useTemplate: "withLabel",
});

// Parameter for JavaScript rendering
const jsRenderParam = glide.makeParameter({
    type: "boolean",
    name: "JavaScript Render",
    description: "Enable JavaScript rendering for dynamic content",
    defaultValue: false,
});

const advancedSection = {
    name: "Advanced",
    order: 1,
    collapsed: true,
};

// Advanced parameters
const jsInstructionsParam = glide.makeParameter({
    type: "string",
    name: "JavaScript Instructions",
    description: "Custom JavaScript code to interact with the web page dynamically",
    propertySection: advancedSection,
});

const customHeadersParam = glide.makeParameter({
    type: "boolean",
    name: "Custom Headers",
    description: "Include custom headers in your request",
    defaultValue: false,
    propertySection: advancedSection,
});

const proxyCountryParam = glide.makeParameter({
    type: "string",
    name: "Proxy Country",
    description: "Geolocation of the IP used to make the request (only for Premium Proxies)",
    propertySection: advancedSection,
});

const sessionIdParam = glide.makeParameter({
    type: "number",
    name: "Session ID",
    description: "Maintain the same IP for multiple requests (up to 10 minutes)",
    propertySection: advancedSection,
});

const originalStatusParam = glide.makeParameter({
    type: "boolean",
    name: "Original Status",
    description: "Return the original HTTP status code from the target page",
    defaultValue: false,
    propertySection: advancedSection,
});

const allowedStatusCodesParam = glide.makeParameter({
    type: "string",
    name: "Allowed Status Codes",
    description: "Comma-separated list of status codes to return content for even on error",
    propertySection: advancedSection,
});

const waitForParam = glide.makeParameter({
    type: "string",
    name: "Wait For Selector",
    description: "CSS Selector to wait for before returning content",
    propertySection: advancedSection,
});

const waitParam = glide.makeParameter({
    type: "number",
    name: "Wait Time",
    description: "Fixed amount of time to wait before returning content (in milliseconds)",
    defaultValue: 0,
    propertySection: advancedSection,
});

const blockResourcesParam = glide.makeParameter({
    type: "string",
    name: "Block Resources",
    description: "Comma-separated list of resources to block from loading",
    propertySection: advancedSection,
});

const jsonResponseParam = glide.makeParameter({
    type: "boolean",
    name: "JSON Response",
    description: "Obtain the response in JSON format, including XHR/Fetch requests",
    defaultValue: false,
    propertySection: advancedSection,
});

const cssExtractorParam = glide.makeParameter({
    type: "string",
    name: "CSS Extractor",
    description: "JSON string defining CSS Selectors to extract data from HTML",
    propertySection: advancedSection,
});

const autoparseParam = glide.makeParameter({
    type: "boolean",
    name: "Auto Parse",
    description: "Use auto parser algorithm to automatically extract data",
    defaultValue: false,
    propertySection: advancedSection,
});

const screenshotParam = glide.makeParameter({
    type: "boolean",
    name: "Screenshot",
    description: "Take an above-the-fold screenshot of the page",
    defaultValue: false,
    propertySection: advancedSection,
});

const screenshotFullpageParam = glide.makeParameter({
    type: "boolean",
    name: "Screenshot Full Page",
    description: "Take a full-page screenshot",
    defaultValue: false,
    propertySection: advancedSection,
});

const screenshotSelectorParam = glide.makeParameter({
    type: "string",
    name: "Screenshot Selector",
    description: "CSS Selector for taking a screenshot of a specific element",
    propertySection: advancedSection,
});

const screenshotFormatParam = glide.makeParameter({
    type: "enum",
    name: "Screenshot Format",
    description: "Format for the screenshot",
    defaultValue: "png",
    values: [
        { value: "png", label: "PNG" },
        { value: "jpeg", label: "JPEG" },
    ],
    propertySection: advancedSection,
});

const screenshotQualityParam = glide.makeParameter({
    type: "number",
    name: "Screenshot Quality",
    description: "Quality setting for JPEG screenshots (1-100)",
    defaultValue: 90,
    propertySection: advancedSection,
});

const outputsParam = glide.makeParameter({
    type: "string",
    name: "Outputs",
    description: "Comma-separated list of data types to extract from HTML",
    propertySection: advancedSection,
});

// Parameter for premium proxies
const premiumParam = glide.makeParameter({
    type: "boolean",
    name: "Premium Proxies",
    description: "Use premium proxies for better success rates",
    defaultValue: false,
    propertySection: advancedSection,
});

// Parameter for anti-bot protection
const antibotParam = glide.makeParameter({
    type: "boolean",
    name: "Anti-bot",
    description: "Enable anti-bot protection bypass",
    defaultValue: false,
    propertySection: advancedSection,
});

// Parameter for response type
const responseTypeParam = glide.makeParameter({
    type: "enum",
    name: "Response Type",
    description: "Format of the response content",
    defaultValue: "HTML",
    values: [
        { value: "html", label: "HTML" },
        { value: "markdown", label: "Markdown" },
    ],
    propertySection: advancedSection,
});

// Result parameter for the content
const contentParam = glide.makeParameter({
    type: "string",
    name: "Content",
    description: "The scraped webpage content",
});

const ttlParam = glide.makeParameter({
    type: "number",
    name: "Refresh after",
    description: "Refresh the cached result after this many seconds. Default is 30 days.",
    required: false,
});

plugin.addComputation({
    id: "get-page-content",
    name: "Get Page Content",
    description: "Fetch and scrape content from a webpage",
    billablesConsumed: 1,
    parameters: {
        url: urlParam,
        jsRender: jsRenderParam,
        premium: premiumParam,
        antibot: antibotParam,
        responseType: responseTypeParam,
        jsInstructions: jsInstructionsParam,
        customHeaders: customHeadersParam,
        proxyCountry: proxyCountryParam,
        sessionId: sessionIdParam,
        originalStatus: originalStatusParam,
        allowedStatusCodes: allowedStatusCodesParam,
        waitFor: waitForParam,
        wait: waitParam,
        blockResources: blockResourcesParam,
        jsonResponse: jsonResponseParam,
        cssExtractor: cssExtractorParam,
        autoparse: autoparseParam,
        screenshot: screenshotParam,
        screenshotFullpage: screenshotFullpageParam,
        screenshotSelector: screenshotSelectorParam,
        screenshotFormat: screenshotFormatParam,
        screenshotQuality: screenshotQualityParam,
        outputs: outputsParam,
        ttl: ttlParam,
    },
    results: {
        content: contentParam,
    },
    async execute(context, params) {
        const {
            url,
            jsRender,
            premium,
            antibot,
            apiKey,
            responseType,
            jsInstructions,
            customHeaders,
            proxyCountry,
            sessionId,
            originalStatus,
            allowedStatusCodes,
            waitFor,
            wait,
            blockResources,
            jsonResponse,
            cssExtractor,
            autoparse,
            screenshot,
            screenshotFullpage,
            screenshotSelector,
            screenshotFormat,
            screenshotQuality,
            outputs,
            ttl = Number.MAX_SAFE_INTEGER,
        } = params;

        // Separate validation for better error messages
        if (!isDefined(apiKey)) {
            return glide.Result.Fail("API Key is required for ZenRows integration");
        }

        if (!isDefined(url)) {
            return glide.Result.Fail("URL is required for web scraping");
        }

        if (!URL_REGEX.test(url)) {
            return glide.Result.Fail("Invalid URL format. URL must start with http:// or https://");
        }

        const urlObject = new URL(ZENROWS_API_BASE_URL);
        const setParam = (key: string, value: string | number | boolean | undefined) => {
            if (isDefined(value)) {
                urlObject.searchParams.set(key, value.toString());
            }
        };

        setParam("url", url);
        setParam("apikey", apiKey);
        if (jsRender === true) setParam("js_render", "true");
        if (premium === true) setParam("premium_proxy", "true");
        if (antibot === true) setParam("antibot", "true");
        if (responseType === "markdown") setParam("response_type", "markdown");
        setParam("js_instructions", jsInstructions);
        if (customHeaders === true) setParam("custom_headers", "true");
        setParam("proxy_country", proxyCountry);
        setParam("session_id", sessionId?.toString());
        if (originalStatus === true) setParam("original_status", "true");
        setParam("allowed_status_codes", allowedStatusCodes);
        setParam("wait_for", waitFor);
        setParam("wait", wait?.toString());
        setParam("block_resources", blockResources);
        if (jsonResponse === true) setParam("json_response", "true");
        setParam("css_extractor", cssExtractor);
        if (autoparse === true) setParam("autoparse", "true");
        if (screenshot === true) setParam("screenshot", "true");
        if (screenshotFullpage === true) setParam("screenshot_fullpage", "true");
        setParam("screenshot_selector", screenshotSelector);
        setParam("screenshot_format", screenshotFormat);
        setParam("screenshot_quality", screenshotQuality?.toString());
        setParam("outputs", outputs);

        const urlWithParams = urlObject.toString();
        const ttlPeriodsSinceEpoch = Math.floor(Date.now() / 1000 / ttl);

        return await context.useCache(async () => {
            try {
                const response = await context.fetch(urlWithParams, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    return glide.Result.FailFromHTTPStatus(
                        getZenRowsErrorMessage(response.status, errorText),
                        response.status
                    );
                }

                const content = await response.text();
                context.consumeBillable();
                return glide.Result.Ok({ content });
            } catch (error: unknown) {
                return glide.Result.FailPermanent(
                    `Failed to fetch content: ${error instanceof Error ? error.message : String(error)}`
                );
            }
        }, [urlWithParams, ttlPeriodsSinceEpoch]);
    },
});

function getZenRowsErrorMessage(statusCode: number, errorText: string): string {
    const errorCode = statusCode as ZenRowsErrorCode;
    const defaultMessage = "Unknown error occurred";

    const errorMessage =
        ERROR_MESSAGES[errorCode] !== undefined
            ? `ZenRows API error: ${statusCode} - ${ERROR_MESSAGES[errorCode]}`
            : `ZenRows API error: ${statusCode} - ${defaultMessage}`;

    return `${errorMessage} - ${errorText}`;
}
