import type { AppFacilities } from "../components/types";
import { assert, defined } from "@glideapps/ts-necessities";

let materialAppFacilities: AppFacilities | undefined;

export const setAppFacilities = (appFacilities: AppFacilities): void => {
    assert(materialAppFacilities === undefined);
    materialAppFacilities = appFacilities;
};

export const setMockedAppFacilities = (mockedAppFacilities: AppFacilities | undefined): void => {
    materialAppFacilities = mockedAppFacilities;
};

export const getAppFacilitiesForMock = (): AppFacilities | undefined => {
    return materialAppFacilities;
};

export const withMockedAppFacilitiesAsync = async <T>(
    mockedAppFacilities: AppFacilities | undefined,
    fn: () => Promise<T>
): Promise<T> => {
    const OGAppFacilities = getAppFacilitiesForMock();
    setMockedAppFacilities(mockedAppFacilities);
    try {
        return await fn();
    } finally {
        setMockedAppFacilities(OGAppFacilities);
    }
};

export const getAppFacilities = (): AppFacilities => {
    return defined(materialAppFacilities);
};
