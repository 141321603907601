export const DefaultErrorMessage = (
    <div className="description">
        It appears something didn't go as planned. We understand this can be frustrating and we're here to help. Please
        visit our{" "}
        <a
            className="quicklink"
            href="https://www.glideapps.com/support?utm_source=errmodal"
            title="https://www.glideapps.com/support?utm_source=errmodal"
            target="_blank"
            rel="noopener noreferrer">
            Support Center
        </a>
        {" where you can find several resources to assist you."}
    </div>
);

export const ContactSupport = (
    <div className="description">
        It appears something didn't go as planned. We understand this can be frustrating and we're here to help.
        <div className="help-ul">
            <div className="help-options">
                1. Navigate to the app you're wanting to contact support about and click the circular button in the
                bottom right of Glide.
            </div>
            <div className="help-options">2. Click on "Send us a message".</div>
        </div>
    </div>
);

export const SupportLinkCreateError = (
    <div className="description">
        Please send an email to{" "}
        <a
            className="quicklink"
            href={`mailto:support@glideapps.com`}
            title="email support@glideapps.com"
            target="_blank"
            rel="noopener noreferrer">
            support@glideapps.com
        </a>{" "}
        if you encounter this issue.
    </div>
);

export const SignInError = (
    <>
        <div className="description">
            In order to preform this operation you need to be signed into Glide in the same browser instance.
        </div>
        <div className="description">
            1. Open a browser tab and go to{" "}
            <a
                className="quicklink"
                href={`https://go.glideapps.com/`}
                title="https://go.glideapps.com/"
                target="_blank"
                rel="noopener noreferrer">
                https://go.glideapps.com
            </a>
        </div>
        <div className="description">2. Log into your Glide account and try again</div>
        <br></br>
    </>
);

export const TransferAppError = (
    <div className="description">
        The team you are trying to transfer this app or page to does not have a payment method on file. Please go to the
        Billing folder of that team and select the link for either adding a credit card or the Customer Portal link and
        enter your credit card information. If you continue to experience this app/page transfer issue, please create a{" "}
        <div className="description">
            <a
                className="quicklink"
                href={`https://www.glideapps.com/support`}
                title="https://www.glideapps.com/support"
                target="_blank"
                rel="noopener noreferrer">
                support ticket here
            </a>
            . Select "Bug" from the dropdown for the ticket type. Providing all information requested will speed up the
            resolution of this support request.
        </div>
    </div>
);

export const SaveTaxDetailsError = (
    <>
        <div className="description">
            Please make sure that you have the a valid Tax Type and Tax ID for your Country by referring to{" "}
            <a
                className="quicklink"
                href={`https://www.notion.so/glideapps/TAX-IDs-62a468a6545a4c899eec9b42df885613`}
                title="Glide Tax IDS Support Documentation"
                target="_blank"
                rel="noopener noreferrer">
                our list of supported Tax ID types
            </a>{" "}
            If you continue to experience issues with adding Tax information to your app, please send an email to{" "}
            <a
                className="quicklink"
                href={`mailto:support@glideapps.com`}
                title="email support@glideapps.com"
                target="_blank"
                rel="noopener noreferrer">
                support@glideapps.com
            </a>{" "}
            that includes a screenshot of the error message you are receiving and relevant links.
        </div>
    </>
);
