import {
    isFavoritedColumnName,
    type TableColumn,
    type TableGlideType,
    isMultiRelationType,
    isPrimitiveType,
    makeTableRef,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type ClassScreenDescription,
    type ComponentDescription,
    type FormScreenDescription,
    type PropertyDescription,
    MutatingScreenKind,
    ScreenDescriptionKind,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { AppKind } from "@glide/location-common";
import { mapFilterUndefined } from "@glideapps/ts-necessities";

import { ChoiceArrayContentHandler } from "./array-screens/choice";
import { DateFieldComponentHandler } from "./components/date-fields";
import { DateTimeFieldComponentHandler } from "./components/date-time-field";
import { EmailFieldComponentHandler } from "./components/email-field";
import { ImagePickerComponentHandler } from "./components/image-picker";
import { NotesComponentHandler } from "./components/notes";
import { NumberFieldComponentHandler } from "./components/number-field";
import { PhoneFieldComponentHandler } from "./components/phone-field";
import { SwitchComponentHandler } from "./components/switch";
import { TextFieldComponentHandler } from "./components/text-field";
import { filterEditableColumns } from "./description-utils";
import { makeShowToastAction } from "./form-on-submit";

export function makeEditComponentForColumn(
    c: TableColumn,
    table: TableGlideType,
    appKind: AppKind,
    schema: SchemaInspector
): ComponentDescription | undefined {
    switch (c.type.kind) {
        case "image-uri":
            return ImagePickerComponentHandler.defaultComponent(c);

        case "date":
            return DateFieldComponentHandler.defaultComponent(c);

        case "date-time":
        case "time":
            return DateTimeFieldComponentHandler.defaultComponent(c);

        case "markdown":
            if (appKind === AppKind.App) {
                return NotesComponentHandler.defaultComponent(c);
            } else {
                return TextFieldComponentHandler.defaultComponent(c);
            }

        case "phone-number":
            return PhoneFieldComponentHandler.defaultComponent(c);

        case "email-address":
            return EmailFieldComponentHandler.defaultComponent(c);

        case "number":
            return NumberFieldComponentHandler.defaultComponent(c);

        case "boolean":
            return SwitchComponentHandler.defaultComponent(c);

        case "table-ref":
            return ChoiceArrayContentHandler.defaultComponentForLink(c, table, schema);

        case "array":
            if (!isMultiRelationType(c.type)) return undefined;
            return ChoiceArrayContentHandler.defaultComponentForLink(c, table, schema);

        default:
            if (!isPrimitiveType(c.type)) {
                // Not a primitive column, so we can't edit
                // FIXME: Support link columns
                return undefined;
            }
            return TextFieldComponentHandler.defaultComponent(c);
    }
}

export function editComponentsForTable(
    table: TableGlideType,
    appKind: AppKind,
    mutatingScreenKind: MutatingScreenKind | undefined,
    schema: SchemaInspector
): readonly ComponentDescription[] {
    // We're lazy and don't determine whether `table` supports adding
    // user-specific columns.  This is only for making new screens, so the app
    // creator can still add those components if they want them.
    // FIXME: Switch to ##getWritableColumnsForColumnAssignment
    const editableColumns = filterEditableColumns(table, undefined, mutatingScreenKind, schema).filter(
        c => c.name !== isFavoritedColumnName
    );
    return mapFilterUndefined(editableColumns, c => makeEditComponentForColumn(c, table, appKind, schema));
}

export function makeFormScreenDescription(
    tables: InputOutputTables,
    appKind: AppKind,
    title: PropertyDescription | undefined,
    schema: SchemaInspector
): FormScreenDescription {
    return {
        kind: ScreenDescriptionKind.Class,
        type: makeTableRef(tables.input),
        canEdit: false,
        canEditFilters: [],
        canDelete: false,
        canDeleteFilters: [],
        searchPlaceholder: undefined,
        isForm: true,
        fetchesData: false,
        formType: makeTableRef(tables.output),
        title,
        components: editComponentsForTable(tables.output, appKind, MutatingScreenKind.FormScreen, schema),
        transforms: undefined,
        onSubmitActions: [makeShowToastAction()],
    };
}

export function makeAddOrEditScreenDescriptionForTable(
    table: TableGlideType,
    appKind: AppKind,
    isEditScreen: boolean,
    forNewApp: boolean,
    title: PropertyDescription | undefined,
    schema: SchemaInspector
): ClassScreenDescription {
    return {
        kind: ScreenDescriptionKind.Class,
        type: makeTableRef(table),
        canEdit: undefined,
        canEditFilters: [],
        canDelete: isEditScreen && forNewApp,
        canDeleteFilters: [],
        searchPlaceholder: undefined,
        isForm: false,
        fetchesData: false,
        title,
        components: editComponentsForTable(
            table,
            appKind,
            isEditScreen ? MutatingScreenKind.EditScreen : MutatingScreenKind.AddScreen,
            schema
        ),
        transforms: undefined,
    };
}
