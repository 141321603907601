import "twin.macro";
import { withSubscriptionContext } from "./context";
import type { UpgradeModalConfig } from "./upgrade-utils";

interface Props
    extends UpgradeModalConfig,
        React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const UpgradeButton = withSubscriptionContext<Props>(props => {
    const {
        children,
        subscriptionContext: { showUpgradeModal },
        ...innerProps
    } = props;

    return (
        <button
            {...innerProps}
            onClick={async e => {
                e.stopPropagation();
                innerProps.onClick?.(e);
                await showUpgradeModal({
                    unsubscribeAppPlans: false,
                    upsell: true,
                    ...innerProps,
                });
            }}>
            {children}
        </button>
    );
});
