import { styled } from "@glide/common";

export const DropdownItemStyle = styled.li`
    display: flex;
    align-items: center;
    padding: 6px var(--side-margin);
    color: ${p => p.theme.textBase};

    .wrapping-area {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        min-height: 19px;

        flex-grow: 1;

        margin-right: 3px;
        overflow: hidden;
    }

    .emoji-container {
        width: 20px;
        height: 20px;
        font-size: 16px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.stack .wrapping-area {
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        overflow: hidden;
        margin-right: 0;
        > * {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            min-width: 0;
        }
    }

    .no-grow {
        flex-grow: 0;
    }

    .icon-wrapper {
        position: relative;
        margin-right: 8px;
        color: ${p => p.theme.iconBase};
        line-height: 0;

        width: 20px;
        min-width: 20px;
        height: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        .overlay-icon {
            position: absolute;
            right: -4px;
            bottom: -4px;
            width: 14px;
            height: 14px;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &:not(.header).hovered {
        background-color: ${p => p.theme.n200A};

        .action-icon {
            display: flex;
        }
        .action-icon + .checkmark {
            display: none;
        }
    }

    .name {
        display: flex;
        align-items: center;
        overflow: hidden;

        .name-part {
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .name-wrap.name-wrap {
        white-space: normal;
        word-wrap: break-word;
    }

    .hint {
        display: flex;
        align-items: center;
        color: ${p => p.theme.textPale};

        &.icon-pad {
            margin-left: 28px;
        }
    }

    .accelerator {
        margin-left: 4px;

        font-size: 11px;

        padding: 0 2px;
        border-radius: 4px;

        border: 1px solid ${p => p.theme.borderDark};

        background-color: ${p => p.theme.bgBack};

        .format-container {
            display: flex;
            align-items: center;
        }
        .command-icon {
            color: ${p => p.theme.iconBase};

            :not(:first-child) {
                margin-left: 2px;
            }

            :not(:last-child) {
                margin-right: 2px;
            }
        }
    }

    .disclosure {
        color: ${p => p.theme.textPale};
        flex-shrink: 0;
    }

    .lineage-marker {
        color: ${p => p.theme.textPale};
        flex-shrink: 0;
    }

    .checkmark {
        color: ${p => p.theme.b400};
        flex-shrink: 0;
    }

    .action-icon {
        color: ${p => p.theme.n600A};
        display: none;

        flex-shrink: 0;
        width: 20px;
        justify-content: flex-end;
        align-items: center;

        :hover {
            color: ${p => p.theme.textDark};
        }
    }

    &:not(.header) {
        cursor: pointer;
    }

    &.disabled {
        opacity: 0.5;
        &:not(.hasTooltip) {
            pointer-events: none;
        }
    }

    &.destructive {
        .name {
            color: ${p => p.theme.r500};
        }
    }

    &.callout {
        .name {
            color: ${p => p.theme.b500};
            font-weight: 500;
        }
    }

    &.header {
        margin-bottom: 0px;
        padding-bottom: 0px;
        cursor: default;

        .name {
            color: ${p => p.theme.n800A};
            font-weight: 600;
            font-size: 10px;
            text-transform: uppercase;
        }
    }

    &.warning {
        .name {
            color: ${p => p.theme.y500};
        }
    }

    &.delete {
        .name {
            color: ${p => p.theme.r500};
        }
    }
`;
