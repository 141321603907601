import { AppsStateContext } from "./app-state-context";
import type { MinimalAppEnvironment } from "./components/types";
import React, { useContext } from "react";
import { useAppID } from "./use-app-id";
import type { Subtract } from "utility-types";

export function useAppEnvironment(appID: string | undefined): MinimalAppEnvironment | undefined {
    const extra = useContext(AppsStateContext);
    if (appID === undefined) return undefined;
    return extra[appID]?.appEnvironment;
}

interface WithAppEnvironmentProps {
    appEnvironment: MinimalAppEnvironment | undefined;
}

export const withAppEnvironment =
    <P extends WithAppEnvironmentProps>(
        Component: React.ComponentType<P>
    ): React.FC<Subtract<P, WithAppEnvironmentProps>> =>
    p => {
        const appID = useAppID();
        const appEnvironment = useAppEnvironment(appID);
        const componentProps = { ...(p as P), appEnvironment } as P;

        return <Component {...componentProps} />;
    };
