import type { ActionNode } from "@glide/app-description";
import { ActionNodeKind } from "@glide/app-description";

export function isActionNodeEnabled(node: ActionNode): boolean {
    if (node.kind === ActionNodeKind.Primitive) {
        return node.actionDescription.enabled !== false;
    } else {
        return true;
    }
}
