import "twin.macro";

import { ErrorBoundary, makeValidHTMLId } from "@glide/common";
import { PortalObserver } from "@glide/common-core/dist/js/components/portal-observer/portal-observer";
import { TextComponentStyle } from "@glide/component-utils";
import { CommentsStyle, type WireCommentsComponent } from "@glide/fluent-components/dist/js/base-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { AppKind } from "@glide/location-common";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { isDefined } from "@glide/support";
import { UIStyleVariant } from "@glide/wire";
import classNames from "classnames";
import * as React from "react";
import { v4 as uuid } from "uuid";

import { useIsCommentScreen } from "../../chrome/content/lib/screen-special-cases";
import { Text } from "../../components/text/text";
import { WireContainer } from "../wire-container/wire-container";
import {
    MultipleFiltersButton,
    SearchBar,
    useMultipleDynamicFilters,
    useSearchBar,
} from "../wire-list-container/wire-list-container";
import type { WireRenderer } from "../wire-renderer";
import { useFloatingLayerPortalId, useIsInsideModal } from "../../wire-lib";
import { CommentsGroup } from "./lib/comment-group";
import { CommentInput } from "./lib/comment-input";

export const WireComments: WireRenderer<WireCommentsComponent> = p => {
    const {
        items,
        backend,
        newComment,
        postNewComment,
        loggedUser,
        emptyMessage,
        showMoreComments,
        title,
        searchBar,
        multipleDynamicFilters,
        buttonText,
        allowAdd,
        componentStyle,
    } = p;
    const isCommentScreen = useIsCommentScreen();
    const isInlineComment = !isCommentScreen && componentStyle === CommentsStyle.Comments;
    const isInlineChat = !isCommentScreen && componentStyle === CommentsStyle.Chat;
    const floatingPortalId = useFloatingLayerPortalId();
    const commentsListRef = React.useRef<HTMLUListElement>(null);
    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    const startsActive = newComment.value.length > 0 && componentStyle === CommentsStyle.Comments;
    const [textAreaActive, setTextAreaActive] = React.useState(startsActive);
    const isInsideModal = useIsInsideModal();

    // We don't want to have a floating input if we're in the bottom layer.
    const isBottomLayer = React.useMemo(() => {
        return floatingPortalId?.includes("bottom");
    }, [floatingPortalId]);

    const [id] = React.useState(makeValidHTMLId(uuid()));

    return (
        <div tw="flex flex-col max-w-full gap-y-2 gp-md:gap-y-4">
            <CommentCollectionHeader
                backend={backend}
                title={title}
                multipleDynamicFilters={multipleDynamicFilters}
                searchBar={searchBar}
            />
            {isInlineComment && (
                <CommentInput
                    id={id}
                    newComment={newComment}
                    postNewComment={postNewComment}
                    backend={backend}
                    loggedUser={loggedUser}
                    textAreaActive={textAreaActive}
                    setTextAreaActive={setTextAreaActive}
                    buttonText={buttonText}
                    allowAdd={allowAdd}
                    componentStyle={componentStyle}
                />
            )}

            <ErrorBoundary>
                <React.Suspense fallback={<div />}>
                    <CommentsGroup
                        items={items}
                        backend={backend}
                        showMoreComments={showMoreComments}
                        emptyMessage={emptyMessage}
                        commentsListRef={commentsListRef}
                        textAreaActive={textAreaActive}
                        componentStyle={componentStyle}
                    />
                </React.Suspense>
            </ErrorBoundary>

            {isInlineChat && (
                <CommentInput
                    id={id}
                    newComment={newComment}
                    postNewComment={postNewComment}
                    backend={backend}
                    loggedUser={loggedUser}
                    textAreaActive={textAreaActive}
                    setTextAreaActive={setTextAreaActive}
                    buttonText={buttonText}
                    allowAdd={allowAdd}
                    componentStyle={componentStyle}
                />
            )}
            {isCommentScreen && (
                <PortalObserver selector={`#${floatingPortalId}`}>
                    <WireContainer
                        className={classNames(isInsideModal && "inside-modal", isBottomLayer && "bottom-layer")}
                        tw="self-end [&.bottom-layer]:hidden -translate-y-[var(--tab-bar-height)] [&.inside-modal]:(-translate-y-[var(--safe-area-inset-bottom)]) page-md:-translate-y-6"
                        isInMultipleColumnLayout={isMobile}
                    >
                        <CommentInput
                            id={id}
                            testId="portaled-comment-input"
                            newComment={newComment}
                            postNewComment={postNewComment}
                            backend={backend}
                            loggedUser={loggedUser}
                            textAreaActive={textAreaActive}
                            setTextAreaActive={setTextAreaActive}
                            buttonText={buttonText}
                            allowAdd={allowAdd}
                            componentStyle={componentStyle}
                        />
                    </WireContainer>
                </PortalObserver>
            )}
        </div>
    );
};

interface CommentCollectionHeaderProps {
    readonly backend: WireBackendInterface;
    readonly searchBar?: WireCommentsComponent["searchBar"];
    readonly multipleDynamicFilters?: WireCommentsComponent["multipleDynamicFilters"];
    readonly title: WireCommentsComponent["title"];
}
const CommentCollectionHeader: React.VFC<CommentCollectionHeaderProps> = p => {
    const { title, searchBar, multipleDynamicFilters, backend } = p;
    const isCommentScreen = useIsCommentScreen();
    const { onSearchChange, searchValue } = useSearchBar(searchBar, backend, undefined);
    const multipleFilterProps = useMultipleDynamicFilters(multipleDynamicFilters, backend);
    const hasSearchBar = isDefined(onSearchChange);
    const hasFilter = isDefined(multipleFilterProps);
    const hasTitle = isDefined(title) && title.length > 0;

    return (
        <div className={classNames(hasTitle && "has-title")} tw="flex gap-y-3 items-center justify-end flex-wrap">
            {hasTitle && (
                <Text
                    element="h2"
                    className={classNames(isCommentScreen && "comments-screen", hasSearchBar && "has-search-bar")}
                    tw="grow font-semibold text-text-contextual-dark [&.has-search-bar]:(basis-full page-md:basis-auto) [&.comments-screen]:(page-md:(mb-5))"
                    variant={TextComponentStyle.large}
                >
                    {title}
                </Text>
            )}
            {hasSearchBar && (
                <SearchBar
                    tw="[grid-area: search]"
                    appKind={AppKind.Page}
                    onSearchChange={onSearchChange}
                    searchValue={searchValue}
                    styleVariant={UIStyleVariant.Default}
                />
            )}
            {hasFilter && (
                <MultipleFiltersButton
                    tw="[grid-area: filter]"
                    multipleFilterProps={multipleFilterProps}
                    styleVariant={UIStyleVariant.Default}
                />
            )}
        </div>
    );
};
