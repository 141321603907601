import type { IconName } from "@glide/common";
import {
    type ArrayContentDescription,
    type ArrayScreenDescription,
    type ArrayScreenFormat,
    type ComponentDescription,
    ActionKind,
    PropertyKind,
    ScreenDescriptionKind,
    makeStringProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { isTableWritable, makeTableRef } from "@glide/type-schema";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    ArrayPropertyStyle,
    StringPropertyHandler,
    SwitchPropertyHandler,
    dynamicFilterColumnPropertyHandler,
    PropertySection,
    getPrimitiveNonHiddenColumnsSpec,
} from "@glide/function-utils";
import { PopulationMode } from "../components/description-handlers";
import { populateDescription } from "../components/populate-description";
import { getCanAddRow } from "../description-utils";
import { ArrayContentHandlerBase } from "./array-content";
import type { ArrayScreenHandler } from ".";

// ##arrayScreenSearch: These are the two property handlers for search in
// array screens, but we're duplicating their functionality in the faux search
// component.
export const searchPropertyHandler = new SwitchPropertyHandler(
    { search: true },
    "Show search bar",
    PropertySection.Search
);

const searchPlaceholderPropertyName = "searchPlaceholder";

export const searchPlaceholderPropertyLabel = "Placeholder";
export const searchPlaceholderPropertyPlaceholder = `e.g. Search People`;
export const searchPlaceholderPropertyHandler = new StringPropertyHandler(
    searchPlaceholderPropertyName,
    searchPlaceholderPropertyLabel,
    searchPlaceholderPropertyPlaceholder,
    false,
    undefined,
    PropertySection.Search
);

const dynamicSortColumnsPropertyHandler: PropertyDescriptor = {
    kind: PropertyKind.Array,
    label: "In-app sort",
    property: { name: "dynamicSortColumns" },
    section: PropertySection.InAppSortData,
    properties: [
        {
            kind: PropertyKind.Column,
            property: { name: "column" },
            section: PropertySection.InAppSortData,
            label: "Sort by",
            required: true,
            editable: true,
            searchable: false,
            emptyByDefault: false,
            getIndirectTable: undefined,
            columnFilter: getPrimitiveNonHiddenColumnsSpec,
        },
    ],
    allowEmpty: true,
    allowReorder: true,
    addItemLabels: ["Add sort"],
    style: ArrayPropertyStyle.Default,
};

export abstract class ArrayScreenHandlerBase<
        TContentDesc extends ArrayContentDescription,
        TScreenDesc extends ArrayScreenDescription & TContentDesc
    >
    extends ArrayContentHandlerBase<TContentDesc>
    implements ArrayScreenHandler<TContentDesc, TScreenDesc>
{
    constructor(format: ArrayScreenFormat, public readonly label: string, public readonly icon: IconName) {
        super(format);
    }

    public get isLegacy(): boolean {
        return false;
    }

    public get supportsInlineList(): boolean {
        return true;
    }

    public defaultDescription(
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        components: readonly ComponentDescription[],
        forNewApp: boolean
    ): TScreenDesc | undefined {
        const type = makeTableRef(tables.input);
        const arrayDesc: ArrayScreenDescription = {
            kind: ScreenDescriptionKind.Array,
            type,
            format: this.format,
            fetchesData: false,
            canAddRow: undefined,
            canAddRowFilters: [],
            searchPlaceholder: makeStringProperty(""),
            reverse: makeSwitchProperty(false),
            groupByColumn: undefined,
            transforms: [],
            actions: [{ kind: ActionKind.PushDetailScreen }],
            components,
            dynamicFilterColumn: undefined,
            dynamicSortColumns: undefined,
        };
        const populated = populateDescription(
            d => this.getPropertyDescriptors(tables, d, true, false, ccc),
            PopulationMode.Default,
            arrayDesc as TScreenDesc,
            arrayDesc as TScreenDesc,
            tables,
            ccc,
            false,
            undefined
        );
        if (populated === undefined) return undefined;
        return {
            ...populated,
            // `populateDescription` will set this to `false` because that's
            // the default.
            canAddRow: makeSwitchProperty(forNewApp),
        };
    }

    public getPropertyDescriptors(
        tables: InputOutputTables | undefined,
        desc: TScreenDesc | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        ccc: AppDescriptionContext
    ): readonly PropertyDescriptor[] {
        const descriptors: PropertyDescriptor[] = [searchPropertyHandler];
        if (searchPropertyHandler.getSwitch(desc)) {
            descriptors.push(searchPlaceholderPropertyHandler);
        }

        // We don't allow configuring ##addEditButtonsInReadonlyTables.
        if (tables !== undefined && isTableWritable(tables.output)) {
            descriptors.push({
                kind: PropertyKind.Switch,
                property: { name: "canAddRow" },
                label: "Allow users to add items",
                section: PropertySection.Add,
            });

            if (getCanAddRow(desc) && ccc.userProfileTableInfo !== undefined) {
                descriptors.push({
                    kind: PropertyKind.Transforms,
                    property: { name: "canAddRowFilters" },
                    label: "Condition",
                    getIndirectTable: undefined,
                    addText: "Add condition",
                    description: "Set conditions for when adding is allowed.",
                    allowContextTable: false,
                    allowLHSUserProfileColumns: true,
                    withContainingScreen: false,
                    forFilteringRows: false,
                    section: PropertySection.Add,
                    allowSpecialValues: false,
                });
            }
        }

        descriptors.push(dynamicFilterColumnPropertyHandler);

        if (this.getNeedsOrder(desc)) {
            descriptors.push(dynamicSortColumnsPropertyHandler);
        }

        descriptors.push(
            ...this.getContentPropertyDescriptors<TScreenDesc>(
                undefined,
                false,
                // Array-screens don't have a content-table-getter, so we need
                // to pass the tables in here.
                tables,
                desc,
                ccc,
                undefined,
                isDefaultArrayScreen,
                withTransforms,
                false,
                true,
                undefined,
                undefined
            )
        );

        return descriptors;
    }

    public getPropertyAndActionDescriptors(
        tables: InputOutputTables | undefined,
        desc: TScreenDesc,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        ccc: AppDescriptionContext
    ): readonly PropertyDescriptor[] {
        const props = this.getPropertyDescriptors(tables, desc, isDefaultArrayScreen, withTransforms, ccc);
        const actions = this.getActionDescriptors(desc, tables, undefined, ccc);
        return [...props, ...actions];
    }

    // FIXME: Do this via the descriptor, where we have the searchable flag.
    public abstract getBasicSearchProperties(desc: TContentDesc): readonly string[];
}
