import { ActionKind } from "@glide/app-description";
import { isLoadingValue } from "@glide/computation-model-types";
import type { WirePageSignatureFieldComponent } from "@glide/fluent-components/dist/js/base-components";
import type { SignatureField } from "@glide/fluent-components/dist/js/fluent-components";
import { type WireAction, WireActionBusy, WireActionResult, WireComponentKind } from "@glide/wire";
import { definedMap } from "collection-utils";
import type { RequestSignatureActionDescription } from "../actions/request-signature";
import {
    hydrateAction,
    inflateActions,
    inflateComponentEnricher,
    inflateImageProperty,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerBusyActionRunner,
} from "../wire/utils";
import type { ComponentInflator } from "./fluent-components-handlers";
import { isRequiredPropertyHandler } from "@glide/function-utils";
import isEmpty from "lodash/isEmpty";
export const signatureFieldInflator: ComponentInflator<typeof SignatureField> = (ib, desc) => {
    const [imageGetter] = inflateImageProperty(ib, desc.signature, { inOutputRow: true });
    const { tableAndColumn, setterMaker, isInContext } = ib.getValueSetterForProperty(
        desc.signature,
        "clear-signature"
    );
    const [titleGetter] = inflateStringProperty(ib, desc.title, true);

    if (desc.signature === undefined || tableAndColumn === undefined) {
        return undefined;
    }

    const requestSignatureAction: RequestSignatureActionDescription = {
        kind: ActionKind.RequestSignature,
        saveTo: desc.signature,
    };
    const actionHydrator = inflateActions(ib, [requestSignatureAction]);

    const componentEnricher = inflateComponentEnricher<WirePageSignatureFieldComponent>(ib, desc);

    return makeSimpleWireRowComponentHydratorConstructor(hb => {
        const title = titleGetter(hb);
        const signature = imageGetter(hb);
        const triggerSignaturePadAction = definedMap(actionHydrator, ah =>
            registerBusyActionRunner(hb, "tap", () => hydrateAction(ah, hb, false, undefined))
        );

        let clearSignatureAction: WireAction | undefined;
        const setter = setterMaker(hb);
        if (isLoadingValue(setter)) {
            clearSignatureAction = { token: WireActionBusy };
        } else if (setter !== undefined) {
            clearSignatureAction = {
                token: hb.registerAction("clear-signature", async b =>
                    WireActionResult.fromResult(await setter(b, ""))
                ),
            };
        }

        const isRequired = isRequiredPropertyHandler.getSwitch(desc);
        const isValid = isRequired ? !isEmpty(signature) : true;

        return {
            component: componentEnricher({
                kind: WireComponentKind.SignatureField,
                title,
                signature,
                triggerSignaturePadAction,
                clearSignatureAction,
                isRequired,
            }),
            isValid,
            editsInContext: isInContext,
        };
    });
};
