import type { LocalizedStringKey } from "@glide/localization";
import { convertOAuth2RedirectTokenToState } from "@glide/common-core/dist/js/authorization/auth";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { logError } from "@glide/support";

type NativeAuthControllerCallback = Readonly<{
    onTokenExchangeStart: () => void;
    onTokenExchangeError: (errorKey: LocalizedStringKey) => void;
    onTokenExchangeSuccess: (token: string) => void;
}>;

export const nativeAuthControllerCallbacks = new Set<NativeAuthControllerCallback>();

// FIXME: Move this Google-specific code into a Google-specific module.
export async function handleGoogleAuthCodeFromNative(authCode: string): Promise<void> {
    const state = convertOAuth2RedirectTokenToState();
    if (state === undefined) return;

    for (const callbackGroup of nativeAuthControllerCallbacks) {
        callbackGroup.onTokenExchangeStart();
    }

    const reportError = () => {
        for (const callbackGroup of nativeAuthControllerCallbacks) {
            callbackGroup.onTokenExchangeError("tryAgain");
        }
    };

    try {
        const response = await getAppFacilities().callAuthIfAvailableCloudFunction(
            "signInWith/google",
            {
                state,
                code: authCode,
                respondDirectly: true,
            },
            {}
        );
        if (response?.ok !== true) {
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void response?.text();
            // Note that at this point, we aren't actually verifying whether the
            // user has access to the app or not. We're just converting a Google
            // auth code into a token we can possibly exchange for credentials.
            logError("Native Google sign-in response status was", response?.status);
            reportError();
        } else {
            const { oauth2Token } = await response.json();
            if (typeof oauth2Token !== "string") {
                return reportError();
            }

            for (const callbackGroup of nativeAuthControllerCallbacks) {
                callbackGroup.onTokenExchangeSuccess(oauth2Token);
            }
        }
    } catch (e: unknown) {
        logError("While converting native Google sign-in auth code:", e);
        reportError();
    }
}
