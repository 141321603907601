import type { NonUserAppFeatures } from "@glide/app-description";
import type { WireFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import { GlideDateTime, GlideJSON } from "@glide/data-types";
import type { AppKind } from "@glide/location-common";
import { isArray, logInfo } from "@glide/support";
import {
    type ValueChangeSource,
    type WireAlwaysEditableValue,
    type WireFrontendActionCallbacks,
    type WireNavigationModel,
    WireActionResult,
} from "@glide/wire";
import { definedMap, hasOwnProperty } from "@glideapps/ts-necessities";
import fromPairs from "lodash/fromPairs";

import type { AICustomComponentInstructionMetadata, WireBackend, WireBackendInterface } from "./types";

function isEditableValue(obj: unknown): obj is WireAlwaysEditableValue<unknown> {
    return hasOwnProperty(obj, "onChangeToken") && typeof obj.onChangeToken === "string";
}

interface UnconfirmedValueChange {
    readonly value: unknown;
    // must have either a `serial` or a `timeout`
    readonly serial: number | undefined;
    readonly timeout: ReturnType<typeof setTimeout> | undefined;
}

interface UnconfirmedAction {
    readonly token: string;
    readonly serial: number;
}

export class UnconfirmedChangeManager {
    private readonly unconfirmedChanges = new Map<string, UnconfirmedValueChange>();
    private unconfirmedActions: UnconfirmedAction[] = [];
    private lastSerial = 0;

    private makeSerial(): number {
        this.lastSerial += 1;
        return this.lastSerial;
    }

    public addUnconfirmedValueChange(token: string, value: unknown): number {
        const existing = this.unconfirmedChanges.get(token);
        if (existing?.timeout !== undefined) {
            clearTimeout(existing.timeout);
        }

        const serial = this.makeSerial();
        this.unconfirmedChanges.set(token, { value, serial, timeout: undefined });
        return serial;
    }

    public addDebouncedValueChange(
        token: string,
        value: unknown,
        debounceMS: number,
        setValue: (serial: number) => void
    ): void {
        const existing = this.unconfirmedChanges.get(token);
        if (existing?.timeout !== undefined) {
            clearTimeout(existing.timeout);
        }

        const timeout = setTimeout(() => {
            const serial = this.makeSerial();
            this.unconfirmedChanges.set(token, { value, serial, timeout: undefined });
            setValue(serial);
        }, debounceMS);

        this.unconfirmedChanges.set(token, { value, serial: undefined, timeout });
    }

    public addUnconfirmedAction(token: string): number {
        const serial = this.makeSerial();
        this.unconfirmedActions.push({ token, serial });
        return serial;
    }

    public overlayChanges<TNavModel extends WireNavigationModel>(navModel: TNavModel): TNavModel {
        const navModelSerial = navModel?.serial ?? 0;

        for (const [token, change] of Array.from(this.unconfirmedChanges)) {
            if (change.serial !== undefined && change.serial <= navModelSerial) {
                this.unconfirmedChanges.delete(token);
            }
        }

        const unconfirmedActionTokens = new Set<string>();
        this.unconfirmedActions = this.unconfirmedActions.filter(({ token, serial }) => {
            if (serial <= navModelSerial) return false;
            unconfirmedActionTokens.add(token);
            return true;
        });

        if (this.unconfirmedChanges.size === 0 && unconfirmedActionTokens.size === 0) {
            // No changes to overlay
            return navModel;
        }

        const apply = <T>(obj: T): T => {
            if (isEditableValue(obj) && this.unconfirmedChanges.has(obj.onChangeToken)) {
                const newValue = this.unconfirmedChanges.get(obj.onChangeToken)?.value;

                logInfo("replacing edited value", obj.onChangeToken, newValue);

                return {
                    ...obj,
                    value: newValue,
                    displayValue: undefined,
                    error: undefined,
                };
            } else if (
                hasOwnProperty(obj, "token") &&
                typeof obj.token === "string" &&
                unconfirmedActionTokens.has(obj.token)
            ) {
                logInfo("unsetting action", obj.token);

                return { ...obj, token: null };
            } else if (isArray(obj)) {
                let didChange = false;
                const newObj = obj.map(o => {
                    const n = apply(o);
                    if (n !== o) {
                        didChange = true;
                    }
                    return n;
                }) as unknown as T;
                return didChange ? newObj : obj;
            } else {
                // FIXME: We have to change it so we don't put these in the
                // nav model anymore - this doesn't work over JSON.
                if (obj instanceof GlideDateTime || obj instanceof Date || obj instanceof GlideJSON) return obj;
                if (obj === null || typeof obj !== "object") return obj;

                let didChange = false;
                const newObj = fromPairs(
                    Object.entries(obj).map(kvp => {
                        const [k, v] = kvp;
                        const n = apply(v);
                        if (n === v) {
                            return kvp;
                        } else {
                            didChange = true;
                            return [k, n];
                        }
                    })
                ) as T;
                return didChange ? newObj : obj;
            }
        };

        return apply(navModel);
    }
}

function findEditableValue(navModel: WireNavigationModel, token: string): WireAlwaysEditableValue<unknown> | undefined {
    function apply(obj: unknown): WireAlwaysEditableValue<unknown> | undefined {
        if (isEditableValue(obj) && obj.onChangeToken === token) {
            return obj;
        } else if (isArray(obj)) {
            for (const o of obj) {
                const v = apply(o);
                if (v !== undefined) return v;
            }
        } else {
            // FIXME: We have to change it so we don't put these in the
            // nav model anymore - this doesn't work over JSON.
            if (obj instanceof GlideDateTime || obj instanceof Date || obj instanceof GlideJSON) return undefined;
            if (obj === null || typeof obj !== "object") return undefined;

            for (const o of Object.values(obj)) {
                const v = apply(o);
                if (v !== undefined) return v;
            }
        }
        return undefined;
    }
    return apply(navModel);
}

export class BackendProxy implements WireBackendInterface {
    public readonly changeManager = new UnconfirmedChangeManager();

    constructor(private readonly backend: WireBackend, private readonly setForceUpdate: ({}) => void) {}

    public get appID(): string {
        return this.backend.appID;
    }

    public get appKind(): AppKind {
        return this.backend.appKind;
    }

    public get appFeatures(): NonUserAppFeatures {
        return this.backend.appFeatures;
    }

    public valueChanged(token: string, value: unknown, source: ValueChangeSource): WireActionResult {
        const editable = definedMap(this.backend.wireNavigationModelObservable.current, m =>
            findEditableValue(m, token)
        );
        if (editable?.debounceMS !== undefined) {
            this.changeManager.addDebouncedValueChange(token, value, editable.debounceMS, serial =>
                this.backend.valueChanged(token, value, source, serial)
            );
        } else {
            const serial = this.changeManager.addUnconfirmedValueChange(token, value);
            this.backend.valueChanged(token, value, source, serial);
        }
        this.setForceUpdate({});
        return WireActionResult.nondescriptSuccess();
    }

    public runAction(token: string | null, handled: boolean): void {
        if (token === null) return;

        const serial = this.changeManager.addUnconfirmedAction(token);
        this.backend.runAction(token, handled, serial);
        this.setForceUpdate({});
    }

    public editComponent(componentID: string | undefined, edit: any): void {
        return this.backend.editComponent(componentID, edit);
    }

    public setFrontendActionCallbacks(callbacks: WireFrontendActionCallbacks): void {
        return this.backend.setFrontendActionCallbacks(callbacks);
    }

    public setFormFactor(formFactor: WireFormFactor): void {
        this.backend.setFormFactor(formFactor);
    }

    public setUserName(_userName: string): void {
        return;
    }

    public getAIComponentInstructionsMetadata(): AICustomComponentInstructionMetadata {
        return this.backend.getAIComponentInstructionsMetadata();
    }
}
