import type { ChangeObservable } from "@glide/support";
import * as React from "react";
import { useForceUpdate } from "./use-force-update";

export function useChangeObservable<T>(observable: ChangeObservable<T>): T;
export function useChangeObservable<T>(observable: ChangeObservable<T> | undefined): T | undefined;
export function useChangeObservable<T>(observable: ChangeObservable<T> | undefined): T | undefined {
    const forceUpdate = useForceUpdate();

    // This `useEffect` handles the case where the observable changes, vs the
    // value in the observable.
    React.useEffect(() => {
        if (observable === undefined) return undefined;

        observable.subscribe(forceUpdate);
        return () => observable.unsubscribe(forceUpdate);
    }, [forceUpdate, observable]);

    return observable?.current;
}
