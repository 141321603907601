import type { PrimitiveActionNode } from "@glide/app-description";
import type { AppDescriptionContext } from "@glide/function-utils";
import { handlerForActionKind } from "@glide/generator/dist/js/actions";
import type { PriorStep } from "@glide/generator/dist/js/prior-step";
import type { TableName } from "@glide/type-schema";
import { makeTableName } from "@glide/type-schema";

const internalTableNamePrefix = "automations-scope-";

export function makeLoopScopeID(nodeKey: string): string {
    return `loop-${nodeKey}`;
}

export function makeActionOutputColumnName(nodeKey: string, name: string): string {
    return `output-${nodeKey}-${name}`;
}

export function makeInternalTableName(scopeID: string): TableName {
    return makeTableName(internalTableNamePrefix + scopeID, true);
}

export function isInternalTableName(tableName: TableName): boolean {
    return tableName.isSpecial && tableName.name.startsWith(internalTableNamePrefix);
}

export function getPrimitiveNodeName(
    node: PrimitiveActionNode,
    adc: AppDescriptionContext,
    priorSteps: readonly PriorStep[]
) {
    if (node.customTitle !== undefined) return node.customTitle;

    const handler = handlerForActionKind(node.actionDescription.kind);
    const env = {
        context: adc,
        tables: undefined,
        mutatingScreenKind: undefined,
        isAutomation: true,
        priorSteps,
    };
    const tokens = handler?.getTokenizedDescription(node.actionDescription, env);
    return tokens?.map(t => t.value).join(" ") ?? handler.getDescriptor(node.actionDescription, env).name;
}
