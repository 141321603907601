import type { Database, PollingOptions } from "@glide/common-core/dist/js/Database/core";
import {
    type Batch,
    type DataReader,
    type DiffResults,
    type DocumentData,
    type Query,
    type QueryResults,
    type SortDirection,
    type Transaction,
    unzipString,
    zipString,
} from "@glide/common-core/dist/js/Database";
import { getFeatureFlag } from "@glide/common-core/dist/js/feature-flags";
import type { FeatureSettings } from "@glide/app-description";
import {
    getFeatureSetting,
    listenFeatureSettings,
    setFeatureSettings,
} from "@glide/common-core/dist/js/feature-settings";
import { assert, exceptionToString, sleep } from "@glideapps/ts-necessities";
import {
    featureSettingsStorageKey,
    getCurrentTimestampInMilliseconds,
    hasUsableSessionStorage,
    isRunningOnEmulators,
    localStorageGetItem,
    localStorageSetItem,
    logError,
    logInfo,
    sessionStorageGetItem,
    sessionStorageSetItem,
    SyncJobQueue,
} from "@glide/support";
import { frontendSendEvent } from "@glide/common-core/dist/js/tracing";
import type { LocationSettings } from "@glide/location-common";
import { definedMap, hasOwnProperty } from "collection-utils";
import firebase from "firebase/compat/app";
import { deleteDB } from "idb";
import once from "lodash/once";
import { shortUserAgent } from "@glide/common";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import { isDocumentHidden, onVisibilityChange } from "@glide/common-core/dist/js/support/browser-hacks";
import { reloadBrowserWindow } from "@glide/common-core/dist/js/support/browser-reload";
import { standalone } from "@glide/common-core/dist/js/support/device";
import { initFirebase } from "@glide/firebase-stuff";
import { LazyLoadError, lazyLoading } from "@glide/common-core/dist/js/support/lazy-loading";
import {
    addIDBOpenHook,
    forceIndexedDBReplacementIfNecessary,
    getIndexedDBOpenDatabase,
    isMissingObjectStoreError,
} from "@glide/indexeddb-hacks";
import { ClientDatabase, isPermissionsError } from "./client-database";

let firestore: firebase.firestore.Firestore | undefined;
let firestoreLoad: Promise<any> | undefined;

let maybePersistenceDBName: string | undefined;

function getPersistenceDBName(): string {
    if (maybePersistenceDBName !== undefined) return maybePersistenceDBName;
    maybePersistenceDBName = `firestore/[DEFAULT]/${getLocationSettings().firebaseConfig.projectId}/main`;
    return maybePersistenceDBName;
}

const persistenceFailuresKey = "glide-firestore-persistence-failures";
const maxConsecutivePersistenceFailures = 2;

function injectPersistenceMutexFault() {
    const idb = getIndexedDBOpenDatabase(getPersistenceDBName());
    if (idb === undefined) {
        logError(`No open database at ${getPersistenceDBName()}`);
        return;
    }

    const txn = idb.transaction("owner", "readwrite");
    const txnStore = txn.objectStore("owner");
    const getReq = txnStore.get("owner");
    getReq.onsuccess = () => {
        const priorOwner = getReq.result?.ownerId;
        if (typeof priorOwner !== "string") {
            logError(`Unexpected transaction get result`, getReq.result);
            return;
        }

        txnStore.put(
            {
                ownerId: priorOwner.split("").reverse().join(""),
                allowTabSynchronization: false,
                leaseTimestampMs: Date.now(),
            },
            "owner"
        );
        txn.oncomplete = () => {
            logError("Injected Firestore Persistence mutex fault");
        };
    };
    txn.onerror = () => {
        logError(`Could not inject Persistence mutex fault`, txn.error);
    };
}

async function initFirestore(): Promise<firebase.firestore.Firestore> {
    firestoreLoad = lazyLoading("firestore", false, () => import("firebase/compat/firestore"));
    // This is a dummy use so we don't get a warning
    void firestoreLoad;
    forceIndexedDBReplacementIfNecessary(window);

    initFirebase();

    // Initialize Cloud Firestore through Firebase
    firestore = firebase.firestore();

    firestore.settings({
        // Versions of Kaspersky Endpoint Security do not function
        // properly with Firestore. This forces the Firestore SDK
        // to try and work around bad HTTP proxy behavior.
        //
        // See https://github.com/firebase/firebase-js-sdk/issues/1190#issuecomment-552860520
        experimentalForceLongPolling: true,
        // https://github.com/quicktype/glide/issues/8941
        ignoreUndefinedProperties: true,
        merge: true,
    });

    if (isRunningOnEmulators()) {
        // For debug purposes, we'll expose the firestore instance
        (window as any).firestore = firestore;
    }

    let canUsePersistence =
        ((getFeatureSetting("firestorePersistenceInPWA") && standalone) || getFeatureFlag("useFirestorePersistence")) &&
        hasUsableSessionStorage() &&
        !isRunningOnEmulators();

    if (canUsePersistence) {
        try {
            const priorPersistenceFailuresString = sessionStorageGetItem(persistenceFailuresKey);
            if (priorPersistenceFailuresString !== undefined) {
                const priorPersistenceFailures = Number.parseFloat(priorPersistenceFailuresString);
                if (priorPersistenceFailures >= maxConsecutivePersistenceFailures) {
                    frontendSendEvent("firestore persistence failure", 0, {
                        failures: priorPersistenceFailures,
                        permanent: true,
                        userAgent: shortUserAgent,
                    });
                    logError("Cannot use Firestore Persistence: too many consecutive failures");
                    canUsePersistence = false;
                }
            }
        } catch (e: unknown) {
            frontendSendEvent("firestore persistence failure", 0, {
                exception: exceptionToString(e),
                permanent: true,
                userAgent: shortUserAgent,
            });
            logError(`Could not read sessionStorage for Firestore Persistence: ${exceptionToString(e)}`);
            canUsePersistence = false;
        }
    }

    if (canUsePersistence) {
        // There's no reason to believe that Safari is polite.
        forceIndexedDBReplacementIfNecessary(window);

        await firestore.enablePersistence();
        // This ought to be a no-op but the Persistence Mutex failure is only visible
        // on the _next_ enqueued item.
        await firestore.enableNetwork();
        // If we did enable persistence, we should be able to inject a mutex
        // fault to test our recovery.
        (window as any)._injectPersistenceMutexFault = () => injectPersistenceMutexFault();
    }

    return firestore;
}

function isInternalAssertionError(e: unknown): e is { message: string } {
    return (
        hasOwnProperty(e, "message") &&
        typeof e.message === "string" &&
        e.message.startsWith("FIRESTORE") &&
        e.message.indexOf(" INTERNAL ASSERTION FAILED:") > 0
    );
}

function isPersistenceExclusivityError(e: unknown): e is { code: "failed-precondition" } {
    return (
        hasOwnProperty(e, "code") &&
        e.code === "failed-precondition" &&
        hasOwnProperty(e, "message") &&
        typeof e.message === "string" &&
        e.message.startsWith("Failed to obtain exclusive access to the persistence layer.")
    );
}

function isTerminatedClientError(e: unknown): e is { code: "failed-precondition" } {
    return (
        hasOwnProperty(e, "code") &&
        e.code === "failed-precondition" &&
        hasOwnProperty(e, "message") &&
        typeof e.message === "string" &&
        e.message.startsWith("The client has already been terminated.")
    );
}

function isPersistenceUnimplementedError(e: unknown): e is { code: "unimplemented" } {
    return (
        hasOwnProperty(e, "code") &&
        e.code === "unimplemented" &&
        hasOwnProperty(e, "message") &&
        typeof e.message === "string" &&
        e.message.indexOf("Offline persistence has been disabled.") >= 0
    );
}

const postDeletionReloadWaitFactor = 1.4;
const postDeletionReloadWaitBasis = 2000;

let handledPersistenceFailure = false;

// The Firestore Persistence mutex is super buggy and regularly
// falsely rejects lease grabbing. We're lucky: we only enable it
// in standalone mode, so we _know_ for a fact this is completely
// unnecessary. So what we'll do is just remove the record every
// time we start up.
async function trapIndexedDBMutex(db: IDBDatabase) {
    if (!standalone) return;
    if (db.name !== getPersistenceDBName()) return;

    try {
        const txn = db.transaction(["owner"], "readwrite");
        const store = txn.objectStore("owner");
        store.delete("owner");

        return await new Promise<void>((resolve, reject) => {
            txn.onerror = reject;
            txn.oncomplete = () => resolve();
        });
    } catch (e: unknown) {
        // Someone actually cares if we don't have an "owner" store.
        // But not this particular bit of code. It's not our job here
        // to handle this.
        //
        // In fact, the error traps elsewhere in this file handle this fun
        // little bit of surprise database corruption.
        if (isMissingObjectStoreError(e)) return;
        throw e;
    }
}

addIDBOpenHook(trapIndexedDBMutex);

async function performPersistenceErrorRecovery(e: unknown): Promise<never> {
    if (handledPersistenceFailure) {
        return new Promise(() => {
            // Do nothing; this is a permanently stalled Promise.
            // Anyone trying to handle the persistence failure needs to wait
            // for a full page reload now.
        });
    }

    if (window.indexedDB === undefined) throw e;

    let priorFailures: number = 0;
    try {
        const priorFailuresString = sessionStorageGetItem(persistenceFailuresKey);
        if (priorFailuresString === undefined) {
            sessionStorageSetItem(persistenceFailuresKey, "1");
        } else {
            try {
                priorFailures = Number.parseFloat(priorFailuresString);
                sessionStorageSetItem(persistenceFailuresKey, (priorFailures + 1).toString());
            } catch {
                sessionStorageSetItem(persistenceFailuresKey, maxConsecutivePersistenceFailures.toString());
            }
        }
    } catch {
        // This is unfortunate but there's nothing to be done here.
    }

    handledPersistenceFailure = true;
    frontendSendEvent("firestore persistence failure", 0, {
        exception: exceptionToString(e),
        permanent: false,
        userAgent: shortUserAgent,
    });
    const failureIsPermanent = priorFailures + 1 >= maxConsecutivePersistenceFailures;

    if (!isPersistenceExclusivityError(e) || failureIsPermanent) {
        try {
            await deleteDB(getPersistenceDBName());
        } catch (x: unknown) {
            logError("Exception occurred trying to delete Firestore database", exceptionToString(x));
            // Yep, we're throwing the original exception here.
            // If it weren't for this shim we would have thrown it anyway.
            throw e;
        }
    }

    // We don't want to sleep at all on non-permanent Persistence failures,
    // we can just reload and let the mutex deletion code recover quickly.
    let sleepTime = isPersistenceExclusivityError(e) && !failureIsPermanent ? 0 : postDeletionReloadWaitBasis;
    let reloadCount = 0;
    while (true) {
        // We sleep before reloading to attempt to send data to Honeycomb.
        // The attempt to Honeycomb should be allowed to fail, and shouldn't
        // interfere with the reload process; under normal circumstances the
        // network request should go through quickly.
        await sleep(sleepTime);
        if (sleepTime === 0) {
            // Except, if we want to retry a reload, we'll need to reset the sleep time
            // to something sane.
            sleepTime = postDeletionReloadWaitBasis;
        }
        sleepTime *= postDeletionReloadWaitFactor;

        if (!getFeatureFlag("debugBrowserReload") || reloadCount < 1) {
            reloadBrowserWindow("Attempting to recover from persistence failure", exceptionToString(e));
        }
        reloadCount++;
    }
}

function isTrappableIndirectFirestoreError(e: unknown): boolean {
    // We used to think that isTerminatedClientError was recoverable...
    // but it's not. Firestore gets hard-stuck on it.
    return (
        isInternalAssertionError(e) ||
        isPersistenceExclusivityError(e) ||
        isPersistenceUnimplementedError(e) ||
        isTerminatedClientError(e)
    );
}

async function trapDirectPersistenceFailure(e: unknown): Promise<never> {
    if (!isTrappableIndirectFirestoreError(e) && !isMissingObjectStoreError(e)) throw e;

    return await performPersistenceErrorRecovery(e);
}

window.addEventListener("error", ev => {
    // Firestore will sometimes leak uncaught INTERNAL ASSERTION FAILED exceptions;
    // when it does, we're dumping persistence and trying again.
    //
    // Firestore Persistence also ends up in unrecoverable deadlock far too often.
    // When it does, we're waiting 6 seconds and trying again. If it happens too
    // often then we shut persistence off.
    //
    // This one is for inline execution, but doesn't handle promises.
    if (isTrappableIndirectFirestoreError(ev.error)) {
        ev.preventDefault();
        ev.stopPropagation();
        void performPersistenceErrorRecovery(ev.error);
    }
});

window.addEventListener("unhandledrejection", ev => {
    // Firestore will sometimes leak uncaught INTERNAL ASSERTION FAILED exceptions;
    // when it does, we're dumping persistence and trying again.
    //
    // Firestore Persistence also ends up in unrecoverable deadlock far too often.
    // When it does, we're waiting 6 seconds and trying again. If it happens too
    // often then we shut persistence off.
    //
    // This one is for promises, but doesn't handle inline execution.
    if (isTrappableIndirectFirestoreError(ev.reason)) {
        ev.preventDefault();
        ev.stopPropagation();
        void performPersistenceErrorRecovery(ev.reason);
    }
});

let firestorePromise: Promise<firebase.firestore.Firestore> | undefined;

async function getFirestore(): Promise<firebase.firestore.Firestore> {
    if (firestore !== undefined) return firestore;
    if (firestorePromise === undefined) {
        firestorePromise = initFirestore();
    }
    return firestorePromise;
}

function trySaveFeatureSettings(featureSettings: FeatureSettings, db: DataReader) {
    setFeatureSettings(featureSettings, db);
    localStorageSetItem(featureSettingsStorageKey, zipString(JSON.stringify(featureSettings)));
}

// The feature settings are global to the entire running instance.
// We don't need to get them more than once per instance, and it really
// doesn't matter which analytics bucket they end up in. Hence the `once`.
const initFeatureSettings = once((db: Database) => {
    try {
        const storedFeatureSettings =
            definedMap(localStorageGetItem(featureSettingsStorageKey), s => JSON.parse(unzipString(s))) ??
            (hasOwnProperty(window, "glideFeatureSettings") ? window.glideFeatureSettings : undefined);
        if (storedFeatureSettings !== undefined) {
            trySaveFeatureSettings(storedFeatureSettings as FeatureSettings, db);
        }
    } catch (e: unknown) {
        logError(`Could not load saved feature settings: ${e}`);
    }
    listenFeatureSettings(db, x => trySaveFeatureSettings(x, db));
});

interface ShutdownHandler {
    enterNetworkConnectionLock(): Promise<void>;
    exitNetworkConnectionLock(): void;
}

class DummyShutdownHandler implements ShutdownHandler {
    public async enterNetworkConnectionLock(): Promise<void> {
        return;
    }

    public exitNetworkConnectionLock(): void {
        return;
    }
}

class WebDatabaseShutdownHandler implements ShutdownHandler {
    private outstandingLocks: number = 0;
    private networkShutdownResolver: (() => void) | undefined;

    private consumerWaitResolvers: (() => void)[] = [];

    private networkShutdown: boolean = false;
    private shutdownQueue: SyncJobQueue = new SyncJobQueue();

    private db = getFirestore();

    constructor() {
        // Chrome on Android had bugs where the visibility transition
        // events would not fire, locking up this shutdown handler until
        // the app was force restarted. Hence the incessant polling.

        setInterval(() => this.handleVisibilityTransition(false), 500);
        onVisibilityChange(this.handleVisibilityTransition);
    }

    // Note that this should be run via the shutdown queue, so that
    // all calls are serialized.
    private async shutdownNetworkWaitingForConsumers(): Promise<void> {
        if (this.networkShutdown) return;

        await new Promise<void>(resolve => {
            const setNetworkShutdownFlagAndResolve = () => {
                this.networkShutdown = true;
                resolve();
            };
            assert(this.networkShutdownResolver === undefined, "Pre-existing shutdown hard lock resolver");

            if (this.outstandingLocks > 0) {
                this.networkShutdownResolver = setNetworkShutdownFlagAndResolve;
            } else {
                setNetworkShutdownFlagAndResolve();
            }
        });

        await (await this.db).disableNetwork();
    }

    // Note that this should be run via the shutdown queue, so that
    // all calls are serialized.
    private async enableNetworkReleasingConsumers() {
        if (!this.networkShutdown) return;

        const networkEnablingTimeout = setTimeout(
            () => frontendSendEvent("Firestore enableNetwork timed out", 5_000, {}),
            5_000
        );
        const enablementStartTime = getCurrentTimestampInMilliseconds();
        try {
            await (await this.db).enableNetwork();
        } catch (e: unknown) {
            frontendSendEvent(
                "Firestore enableNetwork crashed",
                getCurrentTimestampInMilliseconds() - enablementStartTime,
                { exception: exceptionToString(e) }
            );
            return;
        } finally {
            clearTimeout(networkEnablingTimeout);
        }
        this.networkShutdown = false;
        const resolvers = this.consumerWaitResolvers.splice(0, this.consumerWaitResolvers.length);
        for (const resolve of resolvers) {
            try {
                resolve();
            } catch (e: unknown) {
                logError("Error calling resolver", e);
            }
        }
    }

    private handleVisibilityTransition = (logChange: boolean = true) => {
        if (logChange) {
            logInfo("Shutdown handler visibility change", isDocumentHidden(), Date.now());
        }
        this.shutdownQueue
            .run(async () => {
                try {
                    if (!this.networkShutdown && isDocumentHidden()) {
                        logInfo("Shutdown handler enter network shutdown", Date.now());
                        await this.shutdownNetworkWaitingForConsumers();
                        logInfo("Shutdown handler exit network shutdown", Date.now());
                    }
                    // This is two ifs instead of one if/else because the document
                    // might become unhidden while awaiting the prior call.
                    // Just breaking this up into two ifs keeps the expected behavior
                    // of one held transition, but also lets us quickly handle the
                    // not -> visible transition before we even get to the appropriate
                    // queue entry.
                    if (this.networkShutdown && !isDocumentHidden()) {
                        logInfo("Shutdown handler enter network restore", Date.now());
                        await this.enableNetworkReleasingConsumers();
                        logInfo("Shutdown handler exit network restore", Date.now());
                    }
                } catch (e: unknown) {
                    logError(`Could not handle visibility transition: ${e}`);
                    try {
                        await trapDirectPersistenceFailure(e);
                    } catch {
                        // If we're catching a thrown Error here, odds are
                        // the error we caught in the block above isn't a
                        // known Persistence crash. We definitely want to
                        // know about this, but can't do anything about it yet.

                        // If the document is hidden, it's very likely we're actually
                        // closing the page here. We don't need to report that.
                        if (e instanceof LazyLoadError && isDocumentHidden()) return;

                        frontendSendEvent("firestore network transition failure", 0, {
                            exception: exceptionToString(e),
                            userAgent: shortUserAgent,
                        });
                    }
                }
            })
            .catch(() => {
                // This empty block is only here to make ESLint happy
            });
    };

    public enterNetworkConnectionLock(): Promise<void> {
        return new Promise(resolve => {
            const incrementOutstandingAndResolve = () => {
                this.outstandingLocks++;
                resolve();
            };
            if (this.networkShutdown) {
                this.consumerWaitResolvers.push(incrementOutstandingAndResolve);
            } else {
                incrementOutstandingAndResolve();
            }
        });
    }

    public exitNetworkConnectionLock(): void {
        this.outstandingLocks--;
        assert(this.outstandingLocks >= 0, "Unbalanced network hard lock exit");

        if (this.outstandingLocks === 0) {
            const { networkShutdownResolver } = this;
            this.networkShutdownResolver = undefined;
            if (networkShutdownResolver !== undefined) {
                networkShutdownResolver();
            }
        }
    }
}

const useShutdownHandler = true;
let globalShutdownHandler: ShutdownHandler | undefined;

function getGlobalShutdownHandler(): ShutdownHandler {
    if (globalShutdownHandler === undefined) {
        if (useShutdownHandler) {
            globalShutdownHandler = new WebDatabaseShutdownHandler();
        } else {
            globalShutdownHandler = new DummyShutdownHandler();
        }
    }
    return globalShutdownHandler;
}

// The Firestore SDK regularly gets wedged in irrecoverable states due to "RESOURCE_EXHAUSTED".
// What's worse is that it doesn't signal those states to anything programmatic, but instead
// "silently" dumps an error log, and allows Glide to assume things are working when they aren't.
// This is especially egregious when attempts to save changes begin silently failing.
//
// Since the only way to trap these errors is to scrape the log outputs, we do so here.
const firestorePermanentFailureHandlers = new Set<() => void>();
export function addFirestorePermanentFailureHandler(f: () => void) {
    firestorePermanentFailureHandlers.add(f);
}
export function removeFirestorePermanentHandler(f: () => void) {
    firestorePermanentFailureHandlers.delete(f);
}

// eslint-disable-next-line no-console
const origConsoleError = console.error;
function consoleErrorTrappingFirestorePermanentFailure(...args: any[]) {
    origConsoleError(...args);
    // See https://github.com/firebase/firebase-js-sdk/blob/74b49d20537041ed4c0d722d47d21b0e7781f9bf/packages/firestore/src/remote/persistent_stream.ts#L352
    if (
        args.length > 1 &&
        typeof args[0] === "string" &&
        args[0].indexOf("@firebase/firestore") > -1 &&
        typeof args[1] === "string" &&
        args[1].indexOf("Using maximum backoff delay to prevent overloading the backend.") > -1
    ) {
        for (const handler of firestorePermanentFailureHandlers) {
            try {
                handler();
            } catch {
                // Well, that sucks, but we can't let that stop us.
            }
        }
    }
}

// Note that we are aggressive about running this, and do so frequently. We have to: Mobile Safari will often reset
// all monkey-patched methods to their native equivalents after a full page load, and we can't expect that just because
// we performed the monkey-patch once, we'll keep it when we need it.
function swapConsoleErrorForErrorTrapping() {
    // eslint-disable-next-line no-console
    console.error = consoleErrorTrappingFirestorePermanentFailure;
}

export class WebDatabase extends ClientDatabase {
    constructor() {
        super();

        getGlobalShutdownHandler(); // For side-effects: we want a shutdown handler as soon as we get a WebDatabase
        initFeatureSettings(this);
    }

    override async getDB(): Promise<firebase.firestore.Firestore> {
        return getFirestore();
    }

    public async enterNetworkConnectionLock(): Promise<void> {
        return await getGlobalShutdownHandler().enterNetworkConnectionLock();
    }

    public get deploymentLocationSettings(): LocationSettings {
        return getLocationSettings();
    }

    public exitNetworkConnectionLock(): void {
        getGlobalShutdownHandler().exitNetworkConnectionLock();
    }

    public override async getDocument(collectionName: string, id: string): Promise<DocumentData | undefined> {
        swapConsoleErrorForErrorTrapping();
        try {
            return await super.getDocument(collectionName, id);
        } catch (e: unknown) {
            return await trapDirectPersistenceFailure(e);
        }
    }

    public override async getDocumentsWhere(
        collectionName: string,
        queries: ReadonlyArray<Query>,
        sortFieldPath?: string,
        sortDirection?: SortDirection,
        limit?: number
    ): Promise<QueryResults> {
        swapConsoleErrorForErrorTrapping();
        try {
            return await super.getDocumentsWhere(collectionName, queries, sortFieldPath, sortDirection, limit);
        } catch (e: unknown) {
            return await trapDirectPersistenceFailure(e);
        }
    }

    public override async setDocument(
        collectionName: string,
        id: string | undefined,
        data: DocumentData
    ): Promise<string> {
        swapConsoleErrorForErrorTrapping();
        try {
            return await super.setDocument(collectionName, id, data);
        } catch (e: unknown) {
            return await trapDirectPersistenceFailure(e);
        }
    }

    public override async deleteDocument(collectionName: string, id: string): Promise<void> {
        swapConsoleErrorForErrorTrapping();
        try {
            return await super.deleteDocument(collectionName, id);
        } catch (e: unknown) {
            return await trapDirectPersistenceFailure(e);
        }
    }

    public override async updateDocument(
        collectionName: string,
        id: string | undefined,
        data: DocumentData
    ): Promise<string> {
        swapConsoleErrorForErrorTrapping();
        try {
            return await super.updateDocument(collectionName, id, data);
        } catch (e: unknown) {
            return await trapDirectPersistenceFailure(e);
        }
    }

    public override async updateDocumentWithNesting(
        collectionName: string,
        id: string,
        data: DocumentData
    ): Promise<void> {
        swapConsoleErrorForErrorTrapping();
        try {
            return await super.updateDocumentWithNesting(collectionName, id, data);
        } catch (e: unknown) {
            return await trapDirectPersistenceFailure(e);
        }
    }

    public override async runBatch<T>(name: string, f: (b: Batch) => Promise<T>): Promise<T> {
        swapConsoleErrorForErrorTrapping();
        return await super.runBatch<T>(name, async batch => {
            try {
                return await f(batch);
            } catch (e: unknown) {
                return await trapDirectPersistenceFailure(e);
            }
        });
    }

    public override async runTransaction<T>(name: string, f: (t: Transaction) => Promise<T>): Promise<T> {
        swapConsoleErrorForErrorTrapping();
        try {
            return await super.runTransaction<T>(name, f);
        } catch (e: unknown) {
            return await trapDirectPersistenceFailure(e);
        }
    }

    public override listenToDocument(
        collectionName: string,
        id: string,
        onUpdate: (data: DocumentData | undefined) => void,
        onError: ((e: Error) => void) | undefined
    ): () => void {
        swapConsoleErrorForErrorTrapping();
        if (onError === undefined) {
            onError = e => {
                const permError = isPermissionsError(e);
                const logFunc = permError ? logInfo : logError;
                logFunc("Error listening to document", collectionName, id, e);
                if (!permError) {
                    void trapDirectPersistenceFailure(e);
                }
            };
        }

        return super.listenToDocument(collectionName, id, onUpdate, onError);
    }

    public override listenWhere(
        collectionName: string,
        queries: Query[],
        sortFieldPath: string | undefined,
        sortDirection: SortDirection | undefined,
        limit: number | undefined,
        onUpdate: (results: QueryResults) => void,
        onError?: (e: Error) => void,
        polling?: PollingOptions
    ): () => void {
        swapConsoleErrorForErrorTrapping();
        if (onError === undefined) {
            onError = e => {
                logError("Error listening to query", collectionName, e);
                if (!isPermissionsError(e)) {
                    void trapDirectPersistenceFailure(e);
                }
            };
        }

        return super.listenWhere(
            collectionName,
            queries,
            sortFieldPath,
            sortDirection,
            limit,
            onUpdate,
            onError,
            polling
        );
    }

    public override listenDiffWhere(
        collectionName: string,
        queries: Query[],
        sortFieldPath: string | undefined,
        sortDirection: SortDirection | undefined,
        onUpdate: (results: DiffResults) => void,
        onError: (e?: Error) => void,
        polling?: PollingOptions
    ): () => void {
        swapConsoleErrorForErrorTrapping();

        // FIXME: Do we want to call trapDirectPersistenceFailure for the polling case as well?
        const wrappedOnError =
            polling !== undefined
                ? onError
                : (e?: Error) => {
                      onError(e);
                      // recoverFromPersistenceFailure will re-throw, but in here we don't care
                      // about the re-thrown error.
                      trapDirectPersistenceFailure(e).finally(() =>
                          logError("Attempted recovery from diff query error", collectionName, e)
                      );
                  };

        return super.listenDiffWhere(
            collectionName,
            queries,
            sortFieldPath,
            sortDirection,
            onUpdate,
            wrappedOnError,
            polling
        );
    }
}

let database: WebDatabase | undefined;

export function getWebDatabase(): WebDatabase {
    if (database === undefined) {
        database = new WebDatabase();
    }
    return database;
}
