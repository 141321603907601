import * as React from "react";
import {
    type MenuButtonProps,
    useMenuContext,
    MenuProvider,
    MenuButton,
    MenuItem as AriakitMenuItem,
    Menu as AriakitMenu,
    MenuGroup as AriakitMenuGroup,
    MenuGroupLabel as AriakitMenuGroupLabel,
    type MenuSeparatorProps as AriakitMenuSeparatorProps,
    type MenuGroupProps as AriakitMenuGroupProps,
    MenuSeparator as AriakitMenuSeparator,
} from "@ariakit/react/menu";
import type { ComboboxItemProps } from "@ariakit/react/combobox";

import { useEditorContext } from "./editor-context";
import type { Editor } from "@tiptap/react";
import type { TokenAttrs } from "./editor";
import { useIsDarkTheme } from "../../hooks/use-builder-theme";
import { isDefined, isEmptyOrUndefined } from "@glide/support";
import { IconRenderer } from "./icon-renderer";
import { ComboboxProvider } from "@ariakit/react/combobox";
import { GlideIcon } from "@glide/common";

// For _some_ reason we're getting build errors when we don't cast the MenuProvider
// we couldn't figure out why, and this still works, so I guess we'll find out eventually.
const TypedMenuProvider = MenuProvider as React.FC<{
    showTimeout: number;
    placement: string;
    focusLoop: boolean;
    children: React.ReactNode;
}>;
const TypedComboboxProvider = ComboboxProvider as React.FC<{
    resetValueOnHide: boolean;
    setValue?: (value: string) => void;
    includesBaseElement: boolean;
    children: React.ReactNode;
}>;

interface MenuProps extends MenuButtonProps<"div"> {
    label: string;
    trigger?: MenuButtonProps["render"];
    open?: boolean;
    anchorRef?: React.RefObject<HTMLDivElement | null> | React.RefObject<HTMLButtonElement | null>;
    icon?: string;
    editor: Editor;
    preview?: string;
    children: React.ReactNode;
    withDivider?: boolean;
    isItemSelected?: boolean;
    onSearchChange?: (value: string) => void;
}

export const Menu = React.forwardRef<HTMLDivElement, MenuProps>(function Menu(
    { label, icon, children, trigger, open, editor, anchorRef, preview, withDivider, onSearchChange, isItemSelected },
    ref
) {
    const innerRef = React.useRef<HTMLDivElement | null>(null);
    const parent = useMenuContext();
    const { setHideSubMenus, hideSubMenus, triggerActive } = useEditorContext();
    const scrollTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    // this is a workaround to force the parent menu to re-render when the input height changes
    React.useLayoutEffect(() => {
        parent?.render();
    });

    const isRootMenu = typeof open === "boolean";
    const isDarkTheme = useIsDarkTheme();

    const placement = parent ? "right" : triggerActive ? "bottom-end" : "bottom-start";

    return (
        <TypedComboboxProvider resetValueOnHide setValue={onSearchChange} includesBaseElement={false}>
            <TypedMenuProvider showTimeout={100} placement={placement} focusLoop>
                {isRootMenu ? null : (
                    <MenuButton
                        ref={ref}
                        tw="flex justify-between gap-1 items-center px-2 py-1.5 text-left data-[selected=true]:bg-n200A"
                        render={
                            parent !== undefined && icon !== undefined ? (
                                <MenuItem icon={icon} label={label ?? ""} withDivider={withDivider} render={trigger} />
                            ) : (
                                trigger
                            )
                        }>
                        <span
                            tw="inline-flex gap-2 items-center text-builder-base text-text-dark max-w-[80%] truncate"
                            className="label">
                            {icon && <IconRenderer icon={icon} size={16} tw="flex-none text-icon-base" />}
                            {label}
                        </span>
                        <span tw="flex gap-1 items-center">
                            {preview && !isItemSelected && (
                                <span tw="truncate text-icon-xpale text-builder-xs">{preview}</span>
                            )}
                            {isItemSelected && (
                                <span tw="truncate text-icon-xpale text-builder-xs">
                                    <GlideIcon
                                        kind="stroke"
                                        icon="st-check"
                                        iconSize={16}
                                        strokeWidth={1.25}
                                        tw="text-b400"
                                    />
                                </span>
                            )}
                            <IconRenderer icon="st-chevron-right" size={16} tw="text-icon-base" />
                        </span>
                    </MenuButton>
                )}

                <AriakitMenu
                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                        if (event.key === "Escape") {
                            if (editor) editor.commands.focus();
                            return;
                        }

                        if (event.key === "Tab") {
                            if (editor) editor.commands.blur();
                            return;
                        }
                    }}
                    focusable={false}
                    portal
                    portalElement={document.getElementById("portal")}
                    autoFocusOnHide={!isRootMenu}
                    id={isRootMenu ? undefined : "first-menu"}
                    ref={innerRef}
                    onScroll={() => {
                        if (isRootMenu) {
                            // When the root menu is scrolling, we close nested menus to avoid mispositioned floating menus
                            setHideSubMenus(true);

                            // Clear any existing timeout to debounce the scroll event
                            if (scrollTimeoutRef.current) {
                                clearTimeout(scrollTimeoutRef.current);
                            }

                            // Set a timeout to reset the scrolling state after scrolling stops
                            scrollTimeoutRef.current = setTimeout(() => {
                                setHideSubMenus(false);
                            }, 150);

                            // Cleanup function to clear the timeout if the component unmounts while scrolling
                            return () => {
                                if (scrollTimeoutRef.current) {
                                    clearTimeout(scrollTimeoutRef.current);
                                }
                            };
                        }
                        return;
                    }}
                    hideOnHoverOutside={false}
                    unmountOnHide
                    preventBodyScroll
                    open={isRootMenu ? open : hideSubMenus ? false : undefined}
                    getAnchorRect={
                        isRootMenu
                            ? () => {
                                  return anchorRef?.current?.getBoundingClientRect() ?? null;
                              }
                            : undefined
                    }
                    style={{
                        overflow: "auto",
                        overscrollBehavior: "contain",
                        colorScheme: isDarkTheme ? "dark" : "light",
                    }}
                    data-is-dark={isDarkTheme}
                    data-is-parent={isDefined(parent)}
                    tw="p-1 rounded-md shadow-lg-dark
                relative z-[9999] flex flex-col overflow-auto overscroll-contain bg-bg-middle
                data-[is-parent=true]:w-[254px] data-[is-parent=false]:min-w-[254px] max-h-[min(var(--popover-available-height,300px),300px)]
                w-[min(var(--popover-anchor-width,254px),254px)]
                data-[is-dark=false]:bg-n0
                text-builder-base text-text-dark
                ">
                    {children}
                </AriakitMenu>
            </TypedMenuProvider>
        </TypedComboboxProvider>
    );
});

interface MenuItemProps<T> extends Omit<ComboboxItemProps, "store" | "value"> {
    name?: string;
    editor?: Editor;
    preview?: string;
    label: string;
    icon: string | undefined;
    withDivider?: boolean;
    value?: T;
    singleValue?: boolean;
}

// we need to forward the ref to the MenuItem so that we can control the focus and keyboard navigation
export const MenuItem = React.forwardRef(function MenuItem<T>(
    { name, value, editor, preview, label, withDivider = undefined, singleValue, ...props }: MenuItemProps<T>,
    ref: React.Ref<HTMLDivElement>
) {
    const { selectedNodePosition } = useEditorContext();

    return (
        <>
            <AriakitMenuItem
                autoFocus={props.autoFocus}
                focusOnHover={true}
                blurOnHoverEnd={false}
                ref={ref}
                onClick={() => {
                    if (isDefined(selectedNodePosition) && isDefined(editor) && isDefined(value)) {
                        updateNodeByPosition<T>(editor, selectedNodePosition, {
                            label,
                            icon: props.icon,
                            preview,
                            value,
                            withDivider: withDivider,
                            invalid: false,
                            singleValue,
                        });
                        editor
                            ?.chain()
                            .setTextSelection(selectedNodePosition + 1)
                            .focus()
                            .run();
                    } else {
                        if (isDefined(editor) && editor.isEmpty) {
                            editor
                                .chain()
                                .focus()
                                .insertContentAt(
                                    {
                                        from: 0,
                                        to: editor.state.doc.content.size,
                                    },
                                    [
                                        {
                                            type: "binding",
                                            attrs: {
                                                label,
                                                icon: props.icon,
                                                preview,
                                                value,
                                                withDivider,
                                                singleValue,
                                            },
                                        },
                                    ]
                                )
                                .run();
                            return;
                        }

                        editor?.commands.insertContent({
                            type: "binding",
                            attrs: {
                                label,
                                icon: props.icon,
                                preview,
                                value,
                                withDivider,
                                invalid: false,
                                singleValue,
                            },
                        });
                    }
                }}
                {...props}
                data-divider={"false"}
                tw="rounded-md flex justify-between items-center data-[active-item=true]:(bg-n200A) text-builder-base cursor-pointer"
            />
            {withDivider && <div tw="border-b border-n300A my-0.5 px-0.5" />}
        </>
    );
});

function updateNodeByPosition<T>(editor: Editor, position: number, newAttrs: TokenAttrs<T>) {
    const { state, view } = editor;

    const { tr } = state;
    const node = state.doc.nodeAt(position);

    if (node) {
        // Create a new node with updated attributes
        const newNode = node.type.create({ ...node.attrs, ...newAttrs }, node.content, node.marks);

        // Replace the old node with the new one
        tr.setNodeMarkup(position, undefined, newNode.attrs);
        view.dispatch(tr);
    }
}

export interface MenuSeparatorProps extends AriakitMenuSeparatorProps {}

export const MenuSeparator = React.forwardRef<HTMLHRElement, MenuSeparatorProps>(function (props, ref) {
    return <AriakitMenuSeparator ref={ref} {...props} tw={"border-n300A my-0.5 px-0.5"} />;
});

export interface MenuGroupProps extends AriakitMenuGroupProps {
    label?: string;
    children: React.ReactNode;
}

export const MenuGroup = React.forwardRef<HTMLDivElement, MenuGroupProps>(function ({ label, ...props }, ref) {
    return (
        <AriakitMenuGroup ref={ref} {...props}>
            {!isEmptyOrUndefined(label?.trim()) && (
                <AriakitMenuGroupLabel tw="px-2.5 py-1 font-semibold truncate text-builder-xs text-text-xpale">
                    {label}
                </AriakitMenuGroupLabel>
            )}
            {props.children}
        </AriakitMenuGroup>
    );
});
