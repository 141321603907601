import { defined } from "@glideapps/ts-necessities";
import type { NodeViewProps } from "@tiptap/react";
import { type ReactNode, createContext, useState, useContext } from "react";
import type { TokenStyle } from "./utils";

// PoseMirror has this type for attrs, which it let us to break things when using node.attrs
// declare type Attrs = {
//     readonly [attr: string]: any;
// };

export interface ReactNodeViewProps extends NodeViewProps {
    tokenStyle: TokenStyle;
}

type EditorContextState = {
    selectedNodePosition: number | null;
    setSelectedNodePosition: (nodePosition: number | null) => void;
    activeMenu: "search" | "combobox" | "none";
    setActiveMenu: (menuOpen: "search" | "combobox" | "none") => void;
    hideSubMenus: boolean;
    setHideSubMenus: (hide: boolean) => void;
    triggerActive: boolean;
    setTriggerActive: (active: boolean) => void;
    isDragging: boolean;
    setIsDragging: (isDragging: boolean) => void;
};

const EditorContext = createContext<EditorContextState | undefined>(undefined);

export const EditorContextProvider = ({ children }: { children: ReactNode }) => {
    // This state represents the position of the currently selected node in the editor.
    // The value comes from the editor's node.getPos() method, which returns the position
    // of a node in the document. It's null when no node is selected.
    const [selectedNodePosition, setSelectedNodePosition] = useState<number | null>(null);
    const [activeMenu, setActiveMenu] = useState<"search" | "combobox" | "none">("none");
    const [hideSubMenus, setHideSubMenus] = useState<boolean>(false);
    const [triggerActive, setTriggerActive] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    return (
        <EditorContext.Provider
            value={{
                selectedNodePosition,
                setSelectedNodePosition,
                activeMenu,
                setActiveMenu,
                hideSubMenus,
                setHideSubMenus,
                triggerActive,
                setTriggerActive,
                isDragging,
                setIsDragging,
            }}>
            {children}
        </EditorContext.Provider>
    );
};

export const useEditorContext = (): EditorContextState => {
    return defined(useContext(EditorContext), "Can't use editor context outside of EditorContextProvider");
};
