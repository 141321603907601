import { exceptionToString, sleep } from "@glideapps/ts-necessities";
import {
    logError,
    maybe,
    optimalInitialWindowForHoldingTimeFunction,
    QuadraticBackoffController,
} from "@glide/support";
import { generateFirestoreDocumentID } from "./id-generator";
import { frontendTrace } from "./tracing";

const maxRetries = 3;
const defaultCallHeaderTimeout = 60_000;
const expectedConcurrency = 100;
const holdTime = 50;
const baseBackoffWindow = optimalInitialWindowForHoldingTimeFunction(holdTime, maxRetries)(expectedConcurrency);

function isFormData(body: any): body is FormData {
    // `FormData` is not available in Node.js, so we need to check for it.
    try {
        return body instanceof FormData;
    } catch {
        return false;
    }
}

function singleFetchNoRetry(
    url: string,
    headers: Record<string, string>,
    body: any,
    timeout: number,
    attempt: number,
    requestID: string,
    method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE"
): Promise<Response | undefined> {
    let callURL: string = url;
    try {
        const urlObj = new URL(url.startsWith("/") ? `${window.location.origin}${url}` : url);
        // Setting the query parameter on cross-origin requests will force a new
        // CORS OPTIONS every time, so we'll only do it in development and on
        // same-origin requests.
        if (urlObj.host === window.location.host || urlObj.hostname === "localhost") {
            urlObj.searchParams.set("reqid", requestID);
            callURL = urlObj.href;
        }
    } catch {
        // Oh well, we tried.
    }

    return frontendTrace(
        "service-gateway",
        {
            function: "service",
            url,
            attempt,
            request_id: requestID,
        },
        async fields => {
            // FIXME: We need a timeout for the body too!
            // This needs to be provided by callers, as well.
            const abortController = new AbortController();
            const timeoutHandle = setTimeout(() => abortController.abort(), timeout);

            try {
                const contentTypeHeader = isFormData(body)
                    ? undefined
                    : {
                          "Content-Type": "application/json",
                      };

                const response = await fetch(callURL, {
                    method,
                    body,
                    headers: {
                        ...contentTypeHeader,
                        "X-Glide-Attempt": attempt.toString(),
                        "Fly-Customer-Request-Id": requestID,
                        ...headers,
                    },
                    signal: abortController.signal,
                    mode: url.startsWith("/") ? "same-origin" : "cors",
                    // We use the Authorization header with a custom `Bearer` token
                    // as credentials, and don't need to send cookies, custom TLS certificates,
                    // or browser-based passwords. Keeping cookies out of Cloud Functions
                    // requests is important to work around adblockers that don't like Google Analytics,
                    // or any other marketing-based tracking cookies, to show up in `fetch` calls.
                    credentials: "omit",
                    cache: "no-store",
                });
                clearTimeout(timeoutHandle);

                fields.status = response.status;
                fields.success = response.ok;

                return response;
            } catch (e: unknown) {
                // FIXME: Appropriately handle exceptions here
                try {
                    // On the backend this will throw because `DOMException`
                    // doesn't exist.
                    if (!(e instanceof DOMException) || e.code !== e.ABORT_ERR) {
                        logError("In callServiceGateway", url, e);

                        fields.exception = exceptionToString(e);
                    }
                } catch {
                    logError("In callServiceGateway", url, e);
                    fields.exception = exceptionToString(e);
                }
                fields.success = false;
                fields.fetch_repr = maybe(() => window.fetch.toString().substring(0, 64), undefined);

                return undefined;
            }
        },
        "always",
        // Trace sending is retryable if the status is actually a number, or we have an exception.
        // This is to say, we don't retry sending timed out traces.
        fields => typeof fields.status === "number" || typeof fields.exception === "string"
    );
}

function statusNeedsRetry(s: number): boolean {
    if (s === 429 || s === 503) return true;
    return false;
}

export async function callServiceGateway(
    endpoint: string,
    body: any,
    headers: { [key: string]: string } = {},
    stringify: boolean = true,
    method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE" = "POST"
): Promise<Response | undefined> {
    const requestBody = stringify ? JSON.stringify(body) : body;
    const url = `/service/${endpoint}`;

    const backoff = new QuadraticBackoffController(baseBackoffWindow);
    const requestID = generateFirestoreDocumentID();

    for (let i = 1; i <= maxRetries; i++) {
        const response = await singleFetchNoRetry(
            url,
            headers,
            requestBody,
            defaultCallHeaderTimeout,
            i,
            requestID,
            method
        );
        if (response !== undefined) {
            // Will retry 429 and 503
            if (!statusNeedsRetry(response.status)) {
                return response;
            }
        }

        if (i === maxRetries) {
            return undefined;
        }
        if (response !== undefined) {
            // We need to retry the status, and we're about to do so. We need to "drain"
            // the response so that we don't leak a connection.
            response.text().catch(() => {
                // But we really don't care about the resulting error.
            });
        }

        const sleepTime = backoff.getWaitTime();
        await sleep(sleepTime);
    }

    return undefined;
}
