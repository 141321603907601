import type { TableName } from "@glide/type-schema";
import { commentsTableName } from "@glide/common-core/dist/js/database-strings";
import type { ComponentDescription, ComponentKind } from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import {
    type AllowedDataEdits,
    type AppDescriptionContext,
    type ComponentDescriptor,
    PropertySection,
    makeEmptyMutableAllowedDataEdits,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    EnumPropertyHandler,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type WireInflationBackend, type WireRowComponentHydratorConstructor, WireComponentKind } from "@glide/wire";
import { inflateStringProperty, makeSimpleWireRowComponentHydratorConstructor } from "../wire/utils";
import { hydrateComments, inflateCommentUserGetters } from "./comments-hydrator";
import { ComponentHandlerBase } from "./handler";

const ComponentKindComments: ComponentKind = "comments";

enum SortOrder {
    OldestFirst = "oldest-first",
    NewestFirst = "newest-first",
}

const topicPropertyHandler = new ColumnPropertyHandler(
    "topicProperty",
    "Topic",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable],
    undefined,
    ["topic", "subject", "comments"],
    getPrimitiveColumnsSpec,
    "string",
    PropertySection.Data
);
const sortOrderPropertyHandler = new EnumPropertyHandler(
    { sortOrder: SortOrder.OldestFirst },
    "Sort order",
    "Show",
    [
        {
            value: SortOrder.OldestFirst,
            label: "Oldest first",
        },
        {
            value: SortOrder.NewestFirst,
            label: "Newest first",
        },
    ],

    PropertySection.Design,
    "dropdown"
);

export class CommentsComponentHandler extends ComponentHandlerBase<ComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindComments);
    }

    public getAdditionalTablesUsed(): readonly TableName[] {
        return [commentsTableName];
    }

    public getDescriptor(): ComponentDescriptor {
        return {
            name: "Comments",
            description: "Allows users to post comments",
            img: "co-comments",
            group: "Entry Fields",
            helpUrl: getDocURL("comments"),
            properties: [topicPropertyHandler, sortOrderPropertyHandler, ...this.getBasePropertyDescriptors()],
        };
    }

    // ##userNameEdited:
    // If the user hasn't set a name yet, the Comments component will pop up
    // a modal asking for it, and will then save the name in their user
    // profile, if they have one, which means that the Comments component
    // edits the user profile name column.
    public getAdditionalDataEdits(
        _desc: ComponentDescription,
        _tables: InputOutputTables,
        ccc: AppDescriptionContext
    ): AllowedDataEdits | undefined {
        const userProfile = ccc.userProfileTableInfo;
        if (userProfile === undefined) return undefined;

        const edits = makeEmptyMutableAllowedDataEdits();
        edits.setColumnsInRow.get(userProfile.tableName).add(userProfile.nameColumnName);

        return edits;
    }

    public inflate(
        ib: WireInflationBackend,
        desc: ComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const {
            db,
            forBuilder,
            adc: { appKind, appID },
        } = ib;

        // We don't have a database when we're testing
        if (db === undefined) return undefined;

        const [topicGetter, topicType] = inflateStringProperty(ib, topicPropertyHandler.getProperty(desc), false);
        if (topicType === undefined) return undefined;

        const sortOrder = sortOrderPropertyHandler.getEnum(desc) === SortOrder.OldestFirst ? "asc" : "desc";

        const userGetters = inflateCommentUserGetters(ib);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const topic = topicGetter(hb) ?? "";
            if (topic === "") return undefined;

            return hydrateComments(
                hb,
                db,
                appID,
                appKind,
                desc.componentID,
                forBuilder,
                topic,
                sortOrder,
                WireComponentKind.AppComments,
                userGetters
            );
        });
    }

    public convertToPage(desc: ComponentDescription, ccc: AppDescriptionContext): ComponentDescription | undefined {
        return this.defaultConvertToPage(desc, ccc);
    }
}
