import type { ColumnFlags } from "@glide/computation-model-types";
import {
    type TableName,
    type ColumnType,
    type TableAndColumn,
    type TableColumn,
    getTableColumnDisplayName,
    getTableRefTableName,
    isComputedColumn,
    isMultiRelationType,
    isPrimitiveType,
    isSingleRelationType,
    sheetNameForTable,
} from "@glide/type-schema";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { logError } from "@glide/support";
import { assert, hasOwnProperty } from "@glideapps/ts-necessities";
import { type MutableColumnFlags, BuildResult, BuildResultKind } from "./column-builder-types";

export function prettyPrintTableAndColumn(tac: TableAndColumn): string {
    return `${sheetNameForTable(tac.table)} ➡ ${getTableColumnDisplayName(tac.column)}`;
}

export function isBuildResult(x: unknown): x is BuildResult {
    return x instanceof BuildResult;
}

export function makeBuildResultMissing(tac: TableAndColumn, message: string): BuildResult {
    const error = `Missing in ${prettyPrintTableAndColumn(tac)}: ${message}`;
    logError(error);
    return new BuildResult(BuildResultKind.NotImplemented, { message: error });
}

export function makeBuildResultFaulty(message: string, docURL?: string): BuildResult {
    logError(`Error building column: ${message}`);
    return new BuildResult(BuildResultKind.Faulty, { message, docURL });
}

export function makeBuildResultFaultyFromQuery(message?: string): BuildResult {
    return makeBuildResultFaulty(
        message ?? "This computed column is not supported on this data source.",
        getDocURL("bigTables")
    );
}

export function tryCatchBuildResult<T>(f: () => T): T | BuildResult {
    try {
        return f();
    } catch (e: unknown) {
        if (hasOwnProperty(e, "buildResult") && isBuildResult(e.buildResult)) {
            return e.buildResult;
        } else {
            throw e;
        }
    }
}

export function throwBuildResult(buildResult: BuildResult): never {
    throw { buildResult };
}

export const defaultColumnFlags: ColumnFlags = {
    usesThunks: false,
    makesQuery: false,
    fromQuery: false,
    isSlow: false,
    hasCustomOrder: false,
};

export function updateColumnFlags(columnFlags: MutableColumnFlags, ...incoming: ColumnFlags[]) {
    for (const i of incoming) {
        columnFlags.usesThunks ||= i.usesThunks;
        columnFlags.makesQuery ||= i.makesQuery;
        columnFlags.fromQuery ||= i.fromQuery;
        columnFlags.isSlow ||= i.isSlow;
        columnFlags.hasCustomOrder ||= i.hasCustomOrder;
    }
}

// Returns the item column names if its a primitive array type.
export function isSheetArrayType(t: ColumnType): readonly string[] | undefined {
    if (t.kind === "array" && t.itemColumnNames !== undefined) {
        assert(isPrimitiveType(t.items));
        return t.itemColumnNames;
    }
    return undefined;
}

export function isOldStyleRelation(
    c: TableColumn
): { tableName: TableName; columnName: string; isMultiple: boolean } | undefined {
    // We have at least one app that has a column that's apparently both
    // computed but is also an old-style relation.  I don't know how that can
    // happen, but in order to at least not crash we just treat it as a
    // computed column, vs an old-style relation.  In this particular case at
    // least there is no old-style relation data in the table data.
    //
    // https://glide-help.zendesk.com/agent/tickets/6397
    if (isComputedColumn(c)) return undefined;
    if (isSingleRelationType(c.type)) {
        if (c.type.refColumnName !== undefined) {
            return { tableName: getTableRefTableName(c.type), columnName: c.type.refColumnName, isMultiple: false };
        }
    } else if (isMultiRelationType(c.type)) {
        if (c.type.items.refColumnName !== undefined) {
            return {
                tableName: getTableRefTableName(c.type.items),
                columnName: c.type.items.refColumnName,
                isMultiple: true,
            };
        }
    }
    return undefined;
}
