import type { WireButtonsBlockComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isDefined, isEmptyOrUndefinedish } from "@glide/support";
import { ButtonBarPrimaryLocation, ButtonLabelsVisibility, UIButtonAppearance, UIBUttonStyle } from "@glide/wire";
import classNames from "classnames";
import * as React from "react";
import tw from "twin.macro";
import { css, styled } from "@glide/common-components";
import { makeSingleActionSpreadProps } from "../../wire-lib";
import { WireButton } from "../wire-button/wire-button";
import type { WireRenderer } from "../wire-renderer";

const ButtonBlockStyle = styled.div`
    ${tw`relative flex flex-col items-center p-0 text-center rounded-xl text-text-dark`}

    &.start {
        ${tw`items-start text-left`};

        .button-box {
            justify-content: flex-start;
        }
    }

    &.end {
        ${tw`items-end text-right`};

        .button-box {
            justify-content: flex-end;
        }
    }

    &.stretch {
        ${tw`items-stretch text-left`};

        .button-box {
            justify-content: stretch;
        }
    }

    &.wide {
        button,
        a {
            flex-grow: 1;
            flex-basis: 0;
            ${tw`gp-md:[max-width:50%]`}
        }
    }
`;

function getButtonIcon(
    configuredIcon: string | undefined,
    labelsVisibility: ButtonLabelsVisibility
): string | undefined {
    const defaultIcon =
        labelsVisibility === ButtonLabelsVisibility.Hide ? "path:/svg/stroke/st-action-bolt.svg" : undefined;
    return configuredIcon ?? defaultIcon;
}

export const WireButtonBlock: WireRenderer<WireButtonsBlockComponent, { overrideAppearance?: UIButtonAppearance }> =
    React.memo(p => {
        const {
            accentLocation = ButtonBarPrimaryLocation.Left,
            buttons,
            labelsVisibility = ButtonLabelsVisibility.Show,
        } = p;

        const filteredButtons = buttons.filter(b => {
            const { title, icon, action } = b;
            const actualIcon = getButtonIcon(icon, labelsVisibility);
            const hasVisuals = !isEmptyOrUndefinedish(title) || !isEmptyOrUndefinedish(actualIcon);
            return isDefined(action) && hasVisuals;
        });

        return (
            <ButtonBlockStyle className={classNames(p.alignment, p.size, p.buttonStyle)}>
                <div
                    tw="flex self-stretch justify-center flex-wrap"
                    className="button-box"
                    css={css`
                        ${tw`gap-3`}

                        .${UIBUttonStyle.Tiles} & {
                            ${tw`gap-2`}
                        }
                    `}>
                    {filteredButtons.map((b, i) => {
                        const { action, title, icon } = b;

                        const isPrimary = isPrimaryButton(i, filteredButtons.length, accentLocation);
                        const appearance = p.overrideAppearance ?? getAppearanceForStyle(isPrimary, p.buttonStyle);
                        const actualIcon = getButtonIcon(icon, labelsVisibility);

                        return (
                            <WireButton
                                key={i}
                                data-testid="wbb-button"
                                {...makeSingleActionSpreadProps(action, p.backend)}
                                size="md"
                                iconName={actualIcon}
                                iconOnly={labelsVisibility === ButtonLabelsVisibility.Hide}
                                appearance={appearance}>
                                {title}
                            </WireButton>
                        );
                    })}
                </div>
            </ButtonBlockStyle>
        );
    });

function isPrimaryButton(index: number, buttonsCount: number, accentLocation: ButtonBarPrimaryLocation): boolean {
    switch (accentLocation) {
        case ButtonBarPrimaryLocation.Left:
            return index === 0;

        case ButtonBarPrimaryLocation.None:
            return false;

        case ButtonBarPrimaryLocation.Right:
            return index === buttonsCount - 1;
    }
}

function getAppearanceForStyle(isPrimary: boolean, style: UIBUttonStyle) {
    // Tiles doesn't have primary/secondary
    if (style === UIBUttonStyle.Tiles) {
        return UIButtonAppearance.Tiles;
    }

    if (style === UIBUttonStyle.Minimal) {
        return isPrimary ? UIButtonAppearance.MinimalPrimary : UIButtonAppearance.MinimalSecondary;
    }

    return isPrimary ? UIButtonAppearance.Filled : UIButtonAppearance.Bordered;
}
