import "twin.macro";

import type { UpgradeComponentLocation } from "@glide/common-core";
import { getPlanInfoForPlanKind } from "@glide/common-core/dist/js/billing-vnext/org-plan-lib";
import { planKindIsUpgradeLocked, PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import React from "react";

import { OrgPlanIcon } from "../org-plan-icon/org-plan-icon";
import { SubscriptionContext, SubscriptionContextAlternateState, isSubscriptionContext } from "./context";
import { unlockPayloadPassthrough } from "./lib/unlock-payload-passthrough";
import { assertNever } from "@glideapps/ts-necessities";
import { trackEvent } from "@glide/common-core/dist/js/analytics";
import { useOrgInfo } from "../../hooks/use-org-info";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { useEminenceFlags } from "@glide/common-components";
import { defaultEminenceFlagsValues } from "@glide/billing-types";

interface Props extends React.PropsWithChildren {
    planKind: PlanKind;
    extended?: boolean;
    accentClassname?: string;
    location?: UpgradeComponentLocation;
}

export const UpgradePill: React.VFC<Props> = props => {
    const { planKind, extended, location } = props;
    const { short } = getPlanInfoForPlanKind(planKind);

    const subscriptionContext = React.useContext(SubscriptionContext);
    const canUpgrade = isSubscriptionContext(subscriptionContext);
    const subscription = canUpgrade ? subscriptionContext.subscription : undefined;
    const showUpgradeModal = canUpgrade ? subscriptionContext.showUpgradeModal : undefined;
    const { org } = useOrgInfo();
    const appID = useAppID();
    const { data: maybeEminenceFlags } = useEminenceFlags(org?.id);
    const eminenceFlags = maybeEminenceFlags ?? defaultEminenceFlagsValues;

    const onClick = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            trackEvent("integration settings clicked", {
                integration_name: location ?? "N/A",
                stripe_checkout_clicked: true,
                current_plan: eminenceFlags.displayName,
                required_plan: short,
                app_id: appID ?? "N/A",
                team_name: org?.displayName ?? "N/A",
                team_id: org?.id ?? "N/A",
                user_id: org?.adminUserIDs[0] ?? "N/A",
                stripe_checkout_url: "N/A",
                timestamp: Date.now(),
            });
            e.preventDefault();
            if (planKindIsUpgradeLocked(planKind)) {
                switch (planKind) {
                    case PlanKind.Unlimited:
                    // fallthrough
                    case PlanKind.V3NonProfit:
                        window.open("https://www.glideapps.com/pricing", "_blank");
                        break;
                    case PlanKind.V3EnterpriseBaseNoUP:
                        window.open("https://www.glideapps.com/enterprise", "_blank");
                        break;
                    default:
                        return assertNever(planKind);
                }
            }
            if (subscription !== undefined && showUpgradeModal !== undefined) {
                await unlockPayloadPassthrough(planKind, subscription, showUpgradeModal, location);
            }
            return;
        },
        [
            location,
            eminenceFlags.displayName,
            short,
            appID,
            org?.displayName,
            org?.id,
            org?.adminUserIDs,
            planKind,
            subscription,
            showUpgradeModal,
        ]
    );

    if (subscriptionContext === SubscriptionContextAlternateState.Loading) {
        return null;
    }

    return (
        <button
            tw="inline-flex items-center rounded-full"
            style={{ pointerEvents: canUpgrade ? undefined : "none" }}
            onClick={onClick}>
            <UpgradePillBadge extended={extended} planKind={planKind} />
        </button>
    );
};

export const UpgradePillBadge: React.FC<Pick<Props, "extended" | "planKind">> = p => {
    const { extended, planKind } = p;
    const { short, icon } = getPlanInfoForPlanKind(planKind);
    return (
        <div
            tw="inline-flex items-center text-builder-xs font-semibold rounded-full py-0.5 px-2 cursor-pointer
                bg-aqua100 text-aqua400 hover:bg-aqua200 whitespace-nowrap">
            {extended ? (
                <>
                    <OrgPlanIcon size={16} icon={icon} tw="mr-0.5" />
                    {`Unlock with ${short}`}
                </>
            ) : (
                short
            )}
        </div>
    );
};
