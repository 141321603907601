import { asMaybeBooleanStrict } from "@glide/common-core/dist/js/type-conversions";
import {
    asMaybeNumber,
    asString,
    type WritableValue,
    isPrimitiveValue,
    type RowIndex,
} from "@glide/computation-model-types";
import { isValueAllowedInAction } from "@glide/common-core/dist/js/components/computation-types";
import { parseValueAsGlideDateTimeSync } from "@glide/common-core/dist/js/computation-model/data";
import type { ColumnValues } from "@glide/common-core/dist/js/firebase-function-types";
import type { DocumentData } from "@glide/common-core/dist/js/Database";
import {
    type ColumnType,
    type Formula,
    type TableGlideType,
    isPrimitiveArrayType,
    getEmailOwnersColumnNames,
    getTableColumn,
    isComputedColumn,
    isDateOrDateTimeTypeKind,
    isNumberTypeKind,
    isPrimitiveType,
    isStringTypeKind,
    type SourceMetadata,
} from "@glide/type-schema";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import {
    ShouldAgnostifyDateTimes,
    doesTableRequireTZAgnosticDateTimes,
} from "@glide/common-core/dist/js/schema-properties";
import { GlideDateTime, GlideJSON, convertValueToSerializable, DateTimeParts } from "@glide/data-types";
import { ValueFormatKind, decomposeFormatFormula } from "@glide/formula-specifications";
import { getSourceMetadataForTable } from "@glide/common-core/dist/js/components/SerializedApp";
import { mapRecordFilterUndefined, assert, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import { isArray, isDefined } from "@glide/support";
import toPairs from "lodash/toPairs";

export function convertToWritableValues(values: Record<string, unknown>): Record<string, WritableValue> {
    return mapRecordFilterUndefined(values, v => {
        if (isArray(v)) {
            return v.filter(isPrimitiveValue).filter(isDefined);
        } else if (isPrimitiveValue(v)) {
            return v;
        }
        return undefined;
    });
}

interface AdaptValuesForWritingOptions {
    // Convert non-string values to strings if the column has a string type.
    readonly convertToString: boolean;

    // Remove values for columns that are not in the table.
    readonly removeUnknownColumns: boolean;

    // Allow setting the row ID column.
    readonly allowRowID: boolean;

    // Pass through the calling app user's email address to forcibly set
    // app owners if they are otherwise missing.
    //
    // You typically only want to do this when adding rows.
    readonly appUserEmail?: string;

    // ... except for when the write comes from the data editor,
    // then you don't want to do this.
    readonly fromDataEditor: boolean;
}

function installAppUserEmailIntoOwnersColumnsIfNecessary(
    table: TableGlideType | undefined,
    { appUserEmail, fromDataEditor }: AdaptValuesForWritingOptions,
    values: Record<string, WritableValue>
): Record<string, WritableValue> {
    if (
        table === undefined ||
        appUserEmail === undefined ||
        !getFeatureSetting("forceAppUserEmailWhenWritingOwnedRow") ||
        (fromDataEditor && getFeatureSetting("ignoreAppUserEmailInOwnedRowEnforcementInDataEditor"))
    ) {
        return values;
    }
    const ownersColumns = getEmailOwnersColumnNames(table);
    if (ownersColumns.length === 0) return values;
    let hasOwnersSet = false;
    for (const ownerColumn of ownersColumns) {
        const maybeValue = values[ownerColumn];
        if (typeof maybeValue === "string" && maybeValue.length > 0) {
            hasOwnersSet = true;
            break;
        }
    }
    if (hasOwnersSet) return values;
    const withOwners = { ...values };
    for (const ownerColumn of ownersColumns) {
        // This might seem severe, but this should not have happened
        // if the app were correctly configured anyway. Users who don't want this
        // should be setting the owners columns appropriately.
        withOwners[ownerColumn] = appUserEmail;
    }
    return withOwners;
}

// Returns a new object that the caller owns
function adaptValuesForWritingNew(
    // We allow calling this without a table, in which case we won't enforce
    // any types and can't remove columns for unknown values.
    table: TableGlideType | undefined,
    sourceMetadataOrMode: readonly SourceMetadata[] | ShouldAgnostifyDateTimes,
    opts: AdaptValuesForWritingOptions,
    values: Record<string, WritableValue>
): Record<string, WritableValue> {
    if (opts.removeUnknownColumns) {
        assert(table !== undefined);
    }

    let conversionMode: ShouldAgnostifyDateTimes;
    if (isArray(sourceMetadataOrMode)) {
        const sm = table !== undefined ? getSourceMetadataForTable(sourceMetadataOrMode, table) : undefined;

        conversionMode = doesTableRequireTZAgnosticDateTimes(sm);
    } else {
        conversionMode = sourceMetadataOrMode;
    }

    // If this is defined, exclude it.
    const rowIDColumnName = !opts.allowRowID ? table?.rowIDColumn : undefined;

    function adaptValue(
        original: WritableValue,
        type: ColumnType | undefined,
        displayFormula: Formula | undefined
    ): WritableValue {
        if (original === undefined) return undefined;

        let v = original;

        if (isArray(v)) {
            // Only allow arrays for primitive array columns, and also to
            // primitive columns, because we consider JSON values to be
            // primitive, and JSON values can be arrays.
            if (type !== undefined) {
                // It would be nicer to use
                // `!isPrimitiveOrPrimitiveArrayType(type)`, but TS doesn't
                // accept it as a type assertion.
                const isPrimitive = isPrimitiveType(type);
                const isPrimitiveArray = isPrimitiveArrayType(type);
                if (!isPrimitive && !isPrimitiveArray) return undefined;
            }
            // We need to convert the array items like top-level values, and
            // filter out undefineds.
            v = mapFilterUndefined(v, w =>
                adaptValue(
                    w,
                    definedMap(type, t => (t.kind === "array" ? t.items : t)),
                    displayFormula
                )
            );
        } else {
            if (type !== undefined && !isPrimitiveType(type)) return undefined;
        }

        if (type !== undefined) {
            if (isArray(v)) {
                // Nothing to do, the conversion already happened recursively above.
            } else if (isDateOrDateTimeTypeKind(type.kind)) {
                // We have to check for date-time first, because they are also
                // string types.  Parse permissively if we can.
                v = parseValueAsGlideDateTimeSync(v) ?? v;
            } else if (type.kind === "boolean") {
                v = asMaybeBooleanStrict(v) ?? v;
            } else if (isNumberTypeKind(type.kind)) {
                v = asMaybeNumber(v) ?? v;
            } else if (opts.convertToString && isStringTypeKind(type.kind)) {
                v = asString(v);
            }
        }

        if (v instanceof GlideDateTime) {
            let mode = conversionMode;

            const spec = definedMap(displayFormula, decomposeFormatFormula);
            if (
                spec?.kind === ValueFormatKind.DateTime &&
                spec.parts === DateTimeParts.DateOnly &&
                spec.timeZone === "agnostic" &&
                getFeatureSetting("writeDateOnlyAsAgnosticToAgnosticColumns")
            ) {
                mode = ShouldAgnostifyDateTimes.AlwaysAgnostic;
            }

            if (mode === ShouldAgnostifyDateTimes.AgnosticIfGMT) {
                mode = ShouldAgnostifyDateTimes.Keep;
                if (spec?.kind === ValueFormatKind.DateTime && spec.timeZone !== "local") {
                    mode = ShouldAgnostifyDateTimes.AlwaysAgnostic;
                }
            }

            if (mode === ShouldAgnostifyDateTimes.AlwaysAgnostic) {
                v = v.toOriginTimeZoneAgnostic();
            } else if (mode === ShouldAgnostifyDateTimes.AlwaysAware) {
                v = v.toTimeZoneAware();
            }
        }

        return v;
    }

    const result: Record<string, WritableValue> = {};
    for (const [k, original] of Object.entries(values)) {
        if (k === rowIDColumnName) continue;

        const c = definedMap(table, t => getTableColumn(t, k));

        if (c !== undefined) {
            if (isComputedColumn(c)) continue;
        } else {
            if (opts.removeUnknownColumns) continue;
        }

        const v = adaptValue(original, c?.type, c?.displayFormula);
        if (v === undefined) continue;

        result[k] = v;
    }
    return installAppUserEmailIntoOwnersColumnsIfNecessary(table, opts, result);
}

export function adaptValuesForWriting(
    // We allow calling this without a table, in which case we won't enforce
    // any types and can't remove columns for unknown values.
    table: TableGlideType | undefined,
    sourceMetadataOrMode: readonly SourceMetadata[] | ShouldAgnostifyDateTimes,
    opts: AdaptValuesForWritingOptions,
    values: Record<string, WritableValue>
): Record<string, WritableValue> {
    return adaptValuesForWritingNew(table, sourceMetadataOrMode, opts, values);
}

export function convertColumnValuesToDocumentData(values: ColumnValues): DocumentData {
    const ret: DocumentData = {};
    for (const [key, value] of Object.entries(values)) {
        let data: unknown;
        if (value instanceof GlideDateTime || value instanceof GlideJSON) {
            data = value.toDocumentData();
        } else if (isArray(value)) {
            data = value.map(convertValueToSerializable);
        } else {
            data = value;
        }
        ret[key] = data;
    }
    return ret;
}

// This mutates `values`
export function addRowIDToColumnValues(values: ColumnValues, table: TableGlideType | undefined): RowIndex | undefined {
    let rowIndex: RowIndex | undefined;
    const rowIDColumn = table?.rowIDColumn;
    if (rowIDColumn !== undefined) {
        let rowID: string;
        const maybeRowID = values[rowIDColumn];
        if (typeof maybeRowID === "string") {
            rowID = maybeRowID;
        } else {
            rowID = makeRowID();
        }
        values[rowIDColumn] = rowID;
        rowIndex = { keyColumnName: rowIDColumn, keyColumnValue: rowID };
    }
    return rowIndex;
}

export function cleanupColumnValues(columnValues: ColumnValues): ColumnValues {
    const cleaned: ColumnValues = {};
    for (const [k, v] of toPairs(columnValues)) {
        if (!isValueAllowedInAction(v)) continue;
        cleaned[k] = v;
    }
    return cleaned;
}
