import "twin.macro";

import { GlideIcon } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { TextComponentStyle } from "@glide/component-utils";
import { AppKind } from "@glide/location-common";
import * as React from "react";

import { Text } from "../../../components/text/text";
import type { Path } from "./signature-types";

const captureScale = 3;

interface SignatureViewProps {
    readonly shouldWrite: boolean;
    readonly message: string;
    readonly paths: Path[];
    readonly width: number;
    readonly height: number;
    readonly smallScreen: boolean;
    readonly onPadDown: () => void;
    readonly onPadUp: () => void;
    readonly onPadMove: (x: number, y: number) => void;
    readonly onImageData: (imgData: string) => void;
}

interface Bounds {
    left: number;
    right: number;
    top: number;
    bottom: number;
}

export class SignatureView extends React.Component<SignatureViewProps> {
    private canvasRef = React.createRef<HTMLCanvasElement>();

    public componentDidMount() {
        const canvas = this.canvasRef.current;
        if (canvas === null) return;

        // Oh safari, thanks. This is required to prevent native scroll on device
        canvas.addEventListener("touchmove", this.onTouchMove, { passive: false, capture: true });
    }

    componentWillUnmount() {
        const canvas = this.canvasRef.current;
        if (canvas === null) return;

        canvas.removeEventListener("touchmove", this.onTouchMove, { capture: true });
    }

    public shouldComponentUpdate(nextProps: SignatureViewProps) {
        const { paths } = this.props;
        const { paths: newPaths } = nextProps;

        if (paths !== newPaths) {
            this.draw(newPaths);
            return false;
        }

        return true;
    }

    componentDidUpdate(prevProps: SignatureViewProps) {
        const { shouldWrite } = this.props;
        const { shouldWrite: shouldWritePrev } = prevProps;

        if (!shouldWritePrev && shouldWrite) {
            this.getAndSendImageData();
        }
    }

    private getAndSendImageData = () => {
        const { onImageData, paths } = this.props;

        if (paths.length === 0) return;

        const canvas = this.canvasRef.current;
        if (canvas === null) return;

        const imageData = canvas.toDataURL("image/png");
        if (imageData === undefined) return;

        const cropCanvas = document.createElement("canvas");
        const cropCtx = cropCanvas.getContext("2d");
        if (cropCtx === null) return;
        const bounds = paths
            .map(path =>
                path.reduce<Bounds>(
                    (pv, next) => ({
                        left: Math.min(pv.left, next.x),
                        right: Math.max(pv.right, next.x),
                        top: Math.min(pv.top, next.y),
                        bottom: Math.max(pv.bottom, next.y),
                    }),
                    { left: Number.MAX_SAFE_INTEGER, right: 0, top: Number.MAX_SAFE_INTEGER, bottom: 0 }
                )
            )
            .reduce((pv, cv) => ({
                left: Math.min(pv.left, cv.left),
                right: Math.max(pv.right, cv.right),
                top: Math.min(pv.top, cv.top),
                bottom: Math.max(pv.bottom, cv.bottom),
            }));

        // 10 px of padding
        bounds.left = captureScale * Math.max(0, bounds.left - 10);
        bounds.top = captureScale * Math.max(0, bounds.top - 10);
        bounds.right = captureScale * (bounds.right + 10);
        bounds.bottom = captureScale * (bounds.bottom + 10);

        const width = Math.ceil(bounds.right - bounds.left);
        const height = Math.ceil(bounds.bottom - bounds.top);

        cropCanvas.width = width;
        cropCanvas.height = height;

        const img = new Image();
        img.onload = () => {
            cropCtx.drawImage(img, bounds.left, bounds.top, width, height, 0, 0, width, height);
            const croppedData = cropCanvas.toDataURL();

            onImageData(croppedData);
        };
        img.src = imageData;
    };

    private draw = (paths: Path[]) => {
        const canvas = this.canvasRef.current;
        if (canvas === null) return;

        const ctx = canvas.getContext("2d");
        if (ctx === null) return;

        ctx.save();
        ctx.scale(captureScale, captureScale);

        ctx.lineWidth = 3;
        ctx.lineCap = "round";
        ctx.strokeStyle = `#000`;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        for (const path of paths) {
            if (path.length === 0) {
                continue;
            }
            // Minimum of 4 points required for a single curve
            ctx.beginPath();
            ctx.moveTo(path[0].x, path[0].y);
            if (path.length >= 4) {
                let i = 1;
                for (i = 1; i < path.length - 2; i++) {
                    const { x, y } = path[i];
                    const { x: xNext, y: yNext } = path[i + 1];
                    const cx = (x + xNext) / 2;
                    const cy = (y + yNext) / 2;
                    ctx.quadraticCurveTo(x, y, cx, cy);
                }
                ctx.quadraticCurveTo(path[i].x, path[i].y, path[i + 1].x, path[i + 1].y);
            } else {
                const end = path[path.length - 1];
                ctx.lineTo(end.x, end.y);
            }
            ctx.stroke();
        }
        ctx.restore();
    };

    private setMove = (clientX: number, clientY: number) => {
        const { onPadMove } = this.props;

        const canvas = this.canvasRef.current;
        if (canvas === null) return;

        const rect = canvas.getBoundingClientRect();

        const naturalScaleX = canvas.width / rect.width;
        const naturalScaleY = canvas.height / rect.height;

        const x = ((clientX - rect.x) * naturalScaleX) / captureScale;
        const y = ((clientY - rect.y) * naturalScaleY) / captureScale;

        onPadMove(x, y);
    };

    private onMouseMove = (evt: React.MouseEvent) => {
        const { clientX, clientY } = evt;
        this.setMove(clientX, clientY);
    };

    private onTouchMove = (evt: TouchEvent) => {
        evt.stopPropagation();
        if (evt.cancelable) {
            evt.preventDefault();
        }
        if (evt.touches.length < 1) return;
        const { clientX, clientY } = evt.touches[0];
        this.setMove(clientX, clientY);
    };

    render() {
        const { onPadDown, onPadUp, width, height, smallScreen } = this.props;

        const minSize = Math.min(width, height);
        const canvasWidth = smallScreen ? minSize : width;
        const canvasHeight = smallScreen ? minSize : height;

        return (
            <div style={{ width, height }} tw="px-4 flex-grow flex flex-col gap-2 justify-center items-center">
                <div
                    tw="max-h-full max-w-full page-md:(h-full w-full mt-0 aspect-ratio[unset]) aspect-ratio[1/1]
                        bg-n100A rounded-2xl -mt-16 relative"
                >
                    <canvas
                        tw="w-full h-full"
                        ref={this.canvasRef}
                        className="signature-canvas"
                        width={canvasWidth * captureScale}
                        height={canvasHeight * captureScale}
                        onMouseDown={onPadDown}
                        onMouseUp={onPadUp}
                        onMouseLeave={onPadUp}
                        onMouseMove={this.onMouseMove}
                        onTouchStart={onPadDown}
                        onTouchEnd={onPadUp}
                        onTouchCancel={onPadUp}
                    />
                    <span
                        tw="absolute inset-x-4 bottom-1/3 border-b border-n200A pointer-events-none"
                        aria-hidden="true"
                    ></span>
                </div>
                <div tw="flex w-full items-center gap-2 page-md:(absolute bottom-3 left-7 w-auto)">
                    <GlideIcon kind="monotone" icon="mt-info-circle-filled" iconSize={16} tw="text-text-xpale" />
                    <Text element="p" variant={TextComponentStyle.footnote}>
                        {getLocalizedString("signatureMessage", AppKind.Page)}
                    </Text>
                </div>
            </div>
        );
    }
}
