import styled from "styled-components";
import * as Popover from "@radix-ui/react-popover";

export const RadixPopoverContent = styled(Popover.Content)`
    max-height: calc(var(--radix-popover-content-available-height) - 8px);
    transform-origin: var(--radix-popover-content-transform-origin);

    @keyframes rt-fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes rt-fade-out {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @keyframes rt-slide-from-top {
        from {
            transform: translateY(4px) scale(0.97);
        }
        to {
            transform: translateY(0) scale(1);
        }
    }

    @keyframes rt-slide-to-top {
        from {
            transform: translateY(0) scale(1);
        }
        to {
            transform: translateY(4px) scale(0.97);
        }
    }

    @keyframes rt-slide-from-bottom {
        from {
            transform: translateY(-4px) scale(0.97);
        }
        to {
            transform: translateY(0) scale(1);
        }
    }

    @keyframes rt-slide-to-bottom {
        from {
            transform: translateY(0) scale(1);
        }
        to {
            transform: translateY(-4px) scale(0.97);
        }
    }

    @keyframes rt-slide-from-left {
        from {
            transform: translateX(4px) scale(0.97);
        }
        to {
            transform: translateX(0) scale(1);
        }
    }

    @keyframes rt-slide-to-left {
        from {
            transform: translateX(0) scale(1);
        }
        to {
            transform: translateX(4px) scale(0.97);
        }
    }

    @keyframes rt-slide-from-right {
        from {
            transform: translateX(-4px) scale(0.97);
        }
        to {
            transform: translateX(0) scale(1);
        }
    }

    @keyframes rt-slide-to-right {
        from {
            transform: translateX(0) scale(1);
        }
        to {
            transform: translateX(-4px) scale(0.97);
        }
    }

    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

    &:where([data-state="open"]) {
        animation-duration: 300ms;

        &:where([data-side="top"]) {
            animation-name: rt-slide-from-top, rt-fade-in;
        }
        &:where([data-side="bottom"]) {
            animation-name: rt-slide-from-bottom, rt-fade-in;
        }
        &:where([data-side="left"]) {
            animation-name: rt-slide-from-left, rt-fade-in;
        }
        &:where([data-side="right"]) {
            animation-name: rt-slide-from-right, rt-fade-in;
        }
    }

    &:where([data-state="closed"]) {
        animation-duration: 150ms;

        &:where([data-side="top"]) {
            animation-name: rt-slide-to-top, rt-fade-out;
        }
        &:where([data-side="bottom"]) {
            animation-name: rt-slide-to-bottom, rt-fade-out;
        }
        &:where([data-side="left"]) {
            animation-name: rt-slide-to-left, rt-fade-out;
        }
        &:where([data-side="right"]) {
            animation-name: rt-slide-to-right, rt-fade-out;
        }
    }
`;
