// This is mostly similar to `Map`, except that it compares keys not with
// `===` but with a supplied equality function.
//
// Since it uses an array through which it searches linearly it’s not a good
// choice if there’s a large number or items, or the comparison function is
// slow.  We’re using it in a bunch of places where the keys are `TableName`s,
// which are fast to compare, and there aren’t too many tables in an app.

// TODO: Make it `implements Map<K, V>`
export class ArrayMap<K, V> implements ReadonlyMap<K, V> {
    // If `arr` is passed, `ArrayMap` will take possession of it.
    constructor(private readonly areKeysEqual: (a: K, b: K) => boolean, private arr: [K, V][] = []) {}

    public get size(): number {
        return this.arr.length;
    }

    private getEntry(k: K): [K, V] | undefined {
        for (const e of this.arr) {
            const [ak] = e;
            if (this.areKeysEqual(ak, k)) {
                return e;
            }
        }
        return undefined;
    }

    public get(k: K): V | undefined {
        return this.getEntry(k)?.[1];
    }

    public has(k: K): boolean {
        return this.getEntry(k) !== undefined;
    }

    public set(k: K, v: V): void {
        const e = this.getEntry(k);
        if (e !== undefined) {
            e[1] = v;
        } else {
            this.arr.push([k, v]);
        }
    }

    public delete(dk: K): void {
        const keyIndex = this.arr.findIndex(([ak]) => this.areKeysEqual(ak, dk));
        if (keyIndex < 0) return;
        this.arr.splice(keyIndex, 1);
    }

    public getOrSet(k: K, makeValue: () => V): V {
        const kv = this.getEntry(k);
        if (kv !== undefined) {
            return kv[1];
        }
        const v = makeValue();
        this.arr.push([k, v]);
        return v;
    }

    public clear(): void {
        this.arr.length = 0;
    }

    public entries(): IterableIterator<[K, V]> {
        return this.arr.values();
    }

    public keys(): IterableIterator<K> {
        return this.arr.map(([k]) => k).values();
    }

    public values(): IterableIterator<V> {
        return this.arr.map(([, v]) => v).values();
    }

    public forEach(callbackfn: (value: V, key: K, map: ReadonlyMap<K, V>) => void, thisArg?: any): void {
        for (const [k, v] of this.arr) {
            callbackfn.call(thisArg, v, k, this);
        }
    }

    public filterInPlace(predicate: (value: V, key: K) => boolean): void {
        this.arr = this.arr.filter(([k, v]) => predicate(v, k));
    }

    // eslint-disable-next-line require-yield
    public [Symbol.iterator](): IterableIterator<[K, V]> {
        return this.entries();
    }
}

export class DefaultArrayMap<K, V> extends ArrayMap<K, V> {
    constructor(areKeysEqual: (a: K, b: K) => boolean, private readonly makeDefault: (k: K) => V) {
        super(areKeysEqual);
    }

    public get(k: K): V {
        return super.getOrSet(k, () => this.makeDefault(k));
    }
}
