import {
    Corners,
    ImageAspectRatio,
    ImageFitStyle,
    ImageGravity,
    Sizing,
} from "@glide/common-core/dist/js/components/image-types";
import { isPrimitiveValue } from "@glide/computation-model-types";
import { asString } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ActionComponentDescription,
    type ComponentDescription,
    type ComponentKind,
    type LegacyPropertyDescription,
    type PropertyDescription,
    ActionKind,
    MutatingScreenKind,
    PropertyKind,
    getActionProperty,
    getEnumProperty,
    getSourceColumnProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeStringProperty,
} from "@glide/app-description";
import {
    type TableColumn,
    SourceColumnKind,
    getDefaultContextColumnName,
    getTableColumn,
    isPrimitiveArrayType,
    isPrimitiveType,
    isStringTypeOrStringTypeArray,
    type SchemaInspector,
} from "@glide/type-schema";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { TextSize } from "@glide/component-utils";
import type { WireAppImageComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentDescriptor,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    PropertySection,
    makeImageHeightPropertyHandler,
    makeImagePropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { assert, defined } from "@glideapps/ts-necessities";
import { isArray, isUrl } from "@glide/support";
import {
    type WireActionHydrator,
    type WireAlwaysEditableValue,
    type WireInflationBackend,
    type WireRowComponentHydratorConstructor,
    type WireAction,
    type WireEditableValue,
    type WireActionResult,
    WireComponentKind,
} from "@glide/wire";
import { definedMap } from "collection-utils";
import isBoolean from "lodash/isBoolean";
import {
    type OverlayContentDescription,
    defaultOverlayComponent,
    getOverlayPropertyHandlers,
    inflateOverlays,
} from "../overlay-utils";
import {
    hydrateAction,
    inflateActions,
    makeSimpleWireRowComponentHydratorConstructor,
    registerBusyActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { getActionsForComponent } from "./component-utils";
import { ComponentHandlerBase } from "./handler";
import { makeDefaultActionDescriptor } from "./primitive";

const ComponentKindImage: ComponentKind = "image";

// FIXME: It's possible to change the assigned column to a user profile
// column, while the action is set to "Upload Image", but
// ##editedColumnsInUserProfile are not supported, but it won't make the
// action unset.  This used to lead to a crash:
// https://github.com/quicktype/glide/issues/11091

// Papercut to fix the issue: https://github.com/quicktype/glide/issues/11094

interface ImageComponentDescription extends ActionComponentDescription, OverlayContentDescription {
    readonly propertyName: LegacyPropertyDescription;
    // This is optional because we added it later.
    readonly size: LegacyPropertyDescription | undefined;
    readonly sizing: LegacyPropertyDescription | undefined;
    readonly style: PropertyDescription | undefined;
    readonly gravity: PropertyDescription | undefined;
}

function getImageGravity(desc: ImageComponentDescription): ImageGravity {
    return getEnumProperty(desc.gravity) ?? ImageGravity.Center;
}

function getImageComponentSizing(desc: ImageComponentDescription): Sizing {
    return getEnumProperty(desc.sizing) ?? Sizing.Cover;
}

function getImageComponentSize(desc: ImageComponentDescription): ImageAspectRatio {
    return getEnumProperty(desc.size) ?? ImageAspectRatio.Square;
}

function getImageComponentStyle(desc: ImageComponentDescription): ImageFitStyle {
    return getEnumProperty(desc.style) ?? ImageFitStyle.FullWidth;
}

function hasUploadImageAction(desc: ImageComponentDescription | undefined): boolean {
    const action = getActionProperty(desc?.actions);
    return action?.kind === ActionKind.UploadImage;
}

export class ImageComponentHandler extends ComponentHandlerBase<ImageComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindImage);
    }

    public getIsEditor(desc: ImageComponentDescription | undefined): boolean {
        return desc === undefined || hasUploadImageAction(desc);
    }

    public needValidation(desc: ImageComponentDescription): boolean {
        return hasUploadImageAction(desc);
    }

    public getDescriptor(
        desc: ImageComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const imageHeightHandler = makeImageHeightPropertyHandler(ImageAspectRatio.Square, true, false, false);
        const uploadingImage = hasUploadImageAction(desc) || mutatingScreenKind === MutatingScreenKind.AddScreen;

        const properties: PropertyDescriptor[] = [
            makeImagePropertyDescriptor(
                "propertyName",
                "Image",
                "From URL",
                "Enter URL",
                true,
                false,
                uploadingImage,
                false,
                true,
                undefined,
                mutatingScreenKind,
                {}
            ),
            imageHeightHandler,
            ...this.getBasePropertyDescriptors(),
        ];

        const size = definedMap(desc, d => getEnumProperty(d.size));
        if (size !== undefined && size !== ImageAspectRatio.Fit) {
            properties.push({
                kind: PropertyKind.Enum,
                property: { name: "sizing" },
                label: "Fill",
                menuLabel: "Size to",
                cases: [
                    {
                        value: Sizing.Cover,
                        label: "Fill the area",
                    },
                    {
                        value: Sizing.Contain,
                        label: "Show the whole image",
                    },
                ],
                defaultCaseValue: Sizing.Cover,
                accessoryTo: imageHeightHandler,
                section: PropertySection.Design,
                visual: "dropdown",
            });
        }

        properties.push({
            kind: PropertyKind.Enum,
            property: { name: "style" },
            label: "Style",
            menuLabel: "Image styling",
            cases: [
                {
                    value: ImageFitStyle.FullWidth,
                    label: "Edge to edge",
                },
                {
                    value: ImageFitStyle.Content,
                    label: "Inset with content",
                },
            ],
            defaultCaseValue: ImageFitStyle.FullWidth,
            section: PropertySection.Design,
            visual: "dropdown",
        });

        properties.push({
            kind: PropertyKind.Enum,
            property: { name: "gravity" },
            label: "Crop Behavior",
            menuLabel: "Crop Behavior",
            cases: [
                {
                    value: ImageGravity.Faces,
                    label: "Faces",
                    icon: "faceGravity",
                },
                {
                    value: ImageGravity.Center,
                    label: "Center",
                    icon: "centerGravity",
                },
            ],
            defaultCaseValue: ImageGravity.Center,
            section: PropertySection.Design,
            visual: "small-images",
        });

        properties.push(...getOverlayPropertyHandlers(undefined, mutatingScreenKind));

        return {
            name: "Image",
            description: "Display an image from a link",
            img: "co-image",
            group: "Media",
            helpUrl: getDocURL("image"),
            properties,
        };
    }

    public getActionDescriptors(
        desc: ImageComponentDescription | undefined,
        tables: InputOutputTables | undefined,
        schema: SchemaInspector | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly ActionPropertyDescriptor[] {
        const extraKinds: ActionKind[] = [ActionKind.EnlargeImage];
        if (mutatingScreenKind !== MutatingScreenKind.FormScreen && desc !== undefined && tables !== undefined) {
            const columnName = definedMap(getSourceColumnProperty(desc.propertyName), getDefaultContextColumnName);
            if (columnName !== undefined) {
                const column = getTableColumn(tables.input, columnName);
                if (column !== undefined && isPrimitiveType(column.type)) {
                    extraKinds.push(ActionKind.UploadImage);
                }
            }
        }
        return [makeDefaultActionDescriptor(schema, mutatingScreenKind, false, extraKinds)];
    }

    public newComponent(
        tables: InputOutputTables,
        usedColumns: ReadonlySet<TableColumn>,
        editedColumns: ReadonlySet<TableColumn>,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ImageComponentDescription | undefined {
        const desc = super.newComponent(tables, usedColumns, editedColumns, iccc, mutatingScreenKind);
        if (desc === undefined || mutatingScreenKind !== MutatingScreenKind.AddScreen) return desc;
        return {
            ...desc,
            actions: [
                {
                    kind: ActionKind.UploadImage,
                },
            ],
        };
    }

    public updateComponent(
        desc: ImageComponentDescription,
        updates: Partial<ImageComponentDescription>,
        tables: InputOutputTables | undefined,
        ccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ImageComponentDescription {
        const newDesc = super.updateComponent(desc, updates, tables, ccc, mutatingScreenKind);
        // The Upload Image action is incompatible with anything other than
        // columns from the default context, so if the column changes to a
        // non-default context column, we unset the Upload Image action.  See
        // https://github.com/quicktype/glide/issues/11094
        if (hasUploadImageAction(newDesc)) {
            const sc = getSourceColumnProperty(newDesc.propertyName);
            if (sc?.kind !== SourceColumnKind.DefaultContext) {
                return {
                    ...newDesc,
                    actions: undefined,
                };
            }
        }
        return newDesc;
    }

    public inflate(
        ib: WireInflationBackend,
        desc: ImageComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const showUploadButton = hasUploadImageAction(desc);

        const { actions } = getActionsForComponent(this, desc, ib.tables, ib.adc, ib.mutatingScreenKind);
        const enlarge = actions[0]?.kind === ActionKind.EnlargeImage;

        const columnName = definedMap(getSourceColumnProperty(desc.propertyName), getDefaultContextColumnName);

        const [imagesGetter, imagesType] = ib.getValueGetterForProperty(desc.propertyName, false, {
            inOutputRow: showUploadButton,
        });
        if (imagesType === undefined) return undefined;
        // We only takes primitives or arrays of primitives.
        const isPrimitive = isPrimitiveType(imagesType);
        if (!isPrimitive && !isPrimitiveArrayType(imagesType)) {
            return undefined;
        }

        const overlaysHydrator = inflateOverlays(ib, desc, undefined, false, Corners.Square, TextSize.Medium);

        let actionHydrator: WireActionHydrator | WireActionResult;
        if (!enlarge && !showUploadButton) {
            actionHydrator = inflateActions(ib, actions);
        }

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const imagesValue = imagesGetter(hb);
            if (imagesValue === null) return undefined;

            let images: string[];
            if (isPrimitiveValue(imagesValue)) {
                images = [asString(imagesValue)];
            } else if (isArray(imagesValue)) {
                images = imagesValue.map(asString);
            } else {
                return undefined;
            }
            images = images.filter(isUrl);

            // An empty image component only shows if Upload Image is enabled.
            if (!showUploadButton && images.length === 0) return undefined;

            let image: WireEditableValue<string> | string[] | undefined;
            let isBusy: WireAlwaysEditableValue<boolean> | undefined;
            let hasValue: boolean | undefined;
            if ((showUploadButton || forBuilder) && isPrimitive && columnName !== undefined) {
                assert(images.length <= 1);
                const token = hb.registerOnValueChange("set", columnName);
                if (token !== false) {
                    image = { value: images[0] ?? "", onChangeToken: token };
                    isBusy = hb.getState("busy", isBoolean, false, false);
                    // We must only set `hasValue` when the upload button is
                    // enabled, otherwise it will block form submission.
                    // https://github.com/quicktype/glide/issues/15554
                    if (showUploadButton) {
                        hasValue = image.value !== "";
                    }
                }
            }
            if (image === undefined) {
                if (images.length === 0) return undefined;
                image = images;
            }
            if (showUploadButton && hasValue === undefined) {
                hasValue = false;
            }

            let onTap: WireAction | undefined;
            if (actionHydrator !== undefined) {
                onTap = registerBusyActionRunner(hb, "", () =>
                    hydrateAction(defined(actionHydrator), hb, false, undefined)
                );
            }

            const { overlays, subsidiaryScreen } = overlaysHydrator(hb);

            const component: WireAppImageComponent = {
                kind: WireComponentKind.AppImage,
                ...spreadComponentID(desc.componentID, forBuilder),
                image,
                showUploadButton,
                isBusy,
                size: getImageComponentSize(desc),
                sizing: getImageComponentSizing(desc),
                gravity: getImageGravity(desc),
                style: getImageComponentStyle(desc),
                withEnlarge: enlarge,
                overlays,
                onTap,
            };

            return {
                component,
                // If `isBusy` is undefined then there's no image upload, so
                // `undefined` and `false` both mean that we're valid.  This
                // component doesn't have a "required" option.
                isValid: isBusy?.value !== true,
                hasValue,
                subsidiaryScreen,
            };
        });
    }

    public static defaultComponent(column: TableColumn): ImageComponentDescription {
        assert(isStringTypeOrStringTypeArray(column.type));
        return {
            ...makeEmptyComponentDescription(ComponentKindImage),
            propertyName: makeColumnProperty(column.name),
            size: makeEnumProperty(ImageAspectRatio.Square),
            sizing: makeEnumProperty(Sizing.Cover),
            style: makeEnumProperty(ImageFitStyle.FullWidth),
            gravity: makeEnumProperty(ImageGravity.Center),
            actions: [],
            ...defaultOverlayComponent(),
        };
    }

    public static defaultComponentFromURL(url: string): ImageComponentDescription {
        return {
            ...makeEmptyComponentDescription(ComponentKindImage),
            propertyName: makeStringProperty(url),
            size: makeEnumProperty(ImageAspectRatio.Square),
            sizing: makeEnumProperty(Sizing.Cover),
            style: makeEnumProperty(ImageFitStyle.FullWidth),
            gravity: makeEnumProperty(ImageGravity.Center),
            actions: [],
            ...defaultOverlayComponent(),
        };
    }

    public convertToPage(desc: ImageComponentDescription, _ccc: AppDescriptionContext): ComponentDescription {
        return {
            ...makeEmptyComponentDescription(WireComponentKind.SimpleImage),
            image: desc.propertyName as any,
            alt: makeStringProperty(`${desc.avatarCaption?.value ?? ""} ${desc.tagOverlayText?.value ?? ""}`),
            actions: desc.actions,
            // aspect ratios sizing and alignment do not align well, so for now we'll just default
            // a possible future design review could result in some desirable changes
            aspectRatio: makeEnumProperty("aspect-auto"),
            size: makeEnumProperty("size-full"),
            align: makeEnumProperty("center"),
        } as ComponentDescription;
    }
}
