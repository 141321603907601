import "twin.macro";

import { AppIcon, getNamedIcon, styled } from "@glide/common";
import { defined } from "@glideapps/ts-necessities";
import { checkString, isEmptyOrUndefined, isResponseOK, nonNull, isEmpty } from "@glide/support";
import classNames from "classnames";
import * as React from "react";
import { useLayer } from "react-laag";
import type { PlacementType } from "react-laag/dist/PlacementType";
import {
    RemoveIconContainer,
    SplitContainer,
    SplitDropdownContainer,
} from "../../builder/components/split-container/split-container";
import { type BaseDropdownProps, type ItemPath, findPathInGroup, isGroup } from "../../lib/dropdown-types";
import { HighlightTextArea } from "../highlight-textarea/highlight-textarea";
import PropertyTitle from "../property-title/property-title";
import { PushDropdown } from "../push-dropdown/push-dropdown";
import { MDTooltip } from "../tooltip/md-tooltip";
import { DropdownLabel, GlideDropdownWrapper, IconWrapper } from "./glide-dropdown-style";
import { GlideDropdownButton } from "./private/glide-dropdown-button";
import type { HeightVariation } from "./private/glide-dropdown-button-style";
import { v4 as uuid } from "uuid";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import type { GetPluginSecretBody } from "@glide/common-core";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { AsyncDebouncedInput } from "../async-debounced-input/async-debounced-input";
import { setPluginSecret } from "@glide/backend-api";
import { isErrorInfo } from "@glide/error-info";
import { useModals } from "../modal-provider-v2/modal-provider-v2";

export interface GlideDropdownProps<T> extends Omit<BaseDropdownProps<T>, "width"> {
    readonly displayLabel?: string;
    readonly isEnabled?: boolean;
    readonly label?: string;
    readonly width?: string;
    readonly helpUrl?: string;
    readonly helpText?: string;

    readonly onRemove?: () => void;

    readonly slim?: boolean;
    readonly isInline?: boolean;
    readonly heightVariation?: HeightVariation;
    readonly showWarning?: boolean;
    readonly warnMessage?: string;
    readonly defaultDisplayLabel?: string;
    readonly drawBorder?: boolean;
    readonly highlight?: boolean;
    readonly highlightLabel?: boolean;
    readonly emptyWarningText?: string;
    readonly warningText?: string;
    readonly warningType?: "error" | "warn";
    readonly showInlineWarning?: boolean;
    readonly constantIsSecret?: boolean;
    readonly showSpinner?: boolean;

    readonly showBreadcrumb?: boolean;
    readonly customInputValue?: string;
    readonly customInputPlaceholder?: string;
    readonly onCustomInputChanged?: (v: string) => void;
    readonly validateCustomValue?: (v: string) => boolean;
    readonly possiblePlacements?: PlacementType[];

    readonly testId?: string;
}

const Wrapper = styled.div`
    &.prelight {
        box-shadow: 0 0 4px ${p => p.theme.b400};
        border-radius: 8px;
    }
`;

export function GlideDropdown<T>(p: GlideDropdownProps<T>): React.ReactElement | null {
    const {
        displayLabel,
        onRemove,
        isEnabled,
        helpUrl,
        helpText,
        label,
        width,
        slim,
        isInline,
        heightVariation,
        showWarning,
        emptyWarningText,
        warningText,
        warningType,
        showBreadcrumb,
        defaultDisplayLabel,
        highlight,
        highlightLabel,
        drawBorder,
        customInputPlaceholder,
        customInputValue,
        onCustomInputChanged,
        descriptionForItem,
        validateCustomValue,
        possiblePlacements,
        showInlineWarning,
        constantIsSecret,
        showSpinner,
        testId,
        ...rest
    } = p;
    let { warnMessage } = p;
    const { selected, items, className } = rest;

    const [menuOpen, setMenuOpen] = React.useState(false);

    let warnType: "error" | "warn" | undefined = warningType ?? (showWarning ? "error" : undefined);

    let displayValue: { name: string; icon: React.ReactNode }[] | undefined;

    if (selected !== undefined) {
        const path = findPathInGroup(items, selected);

        if (path === undefined || showBreadcrumb === false) {
            const description = descriptionForItem(selected, true);
            if (typeof description === "string") {
                displayValue = [{ name: description, icon: undefined }];
            } else {
                displayValue = [
                    {
                        name: description.selectedName ?? description.name,
                        icon: description.selectedIcon ?? description.icon,
                    },
                ];
            }
        } else {
            displayValue = path.map(partial => {
                if (isGroup(partial)) {
                    return { name: partial.name, icon: partial.icon };
                }
                const desc = descriptionForItem(partial, true);
                if (typeof desc === "string") {
                    return { name: desc, icon: undefined };
                } else {
                    return { name: desc.selectedName ?? desc.name, icon: desc.selectedIcon ?? desc.icon };
                }
            });
        }
    } else if (emptyWarningText !== undefined) {
        warnMessage = emptyWarningText;
        warnType = "warn";
    }

    if (warnMessage === undefined && warningText !== undefined) {
        warnMessage = warningText;
    }

    if (displayValue === undefined) {
        displayValue = [{ name: defaultDisplayLabel ?? "None", icon: undefined }];
    }

    const onClickOutside = React.useCallback(() => {
        setMenuOpen(false);
    }, []);

    const isOpen = items.length > 0 && menuOpen;
    const { layerProps, renderLayer, triggerProps, triggerBounds } = useLayer({
        isOpen,
        container: nonNull(document.getElementById("portal"), 'No element with id="portal" found'),
        placement: "bottom-end",
        possiblePlacements: possiblePlacements ?? ["bottom-start", "top-start", "bottom-end", "top-end"],
        auto: true,
        containerOffset: 2,
        overflowContainer: true,
        onOutsideClick: onClickOutside,
    });

    const onButtonClick = React.useCallback(() => {
        setMenuOpen(c => !c);
    }, []);

    const onItemSelect = React.useCallback(
        (item: T, path: ItemPath) => {
            rest.onItemSelect?.(item, path);
            setMenuOpen(false);
        },
        [rest]
    );

    const maxHeight = React.useMemo((): number | undefined => {
        if (triggerBounds === null) return undefined;
        const windowHeight = window.innerHeight;
        return Math.max(windowHeight - triggerBounds.bottom, triggerBounds.top) - 16;
    }, [triggerBounds]);

    const highlightAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const first = React.useRef(true);
    const hasInputValue = customInputValue !== undefined;
    React.useEffect(() => {
        if (first.current) {
            first.current = false;
            return;
        }

        if (hasInputValue && highlightAreaRef.current !== null) {
            highlightAreaRef.current.focus();
        }
    }, [hasInputValue]);

    let dropdown = (
        <>
            <Wrapper
                {...triggerProps}
                style={{ width: "100%" }}
                className={classNames(highlight === true && "prelight")}>
                {hasInputValue && (
                    // FIXME: Honor `drawBorder` here, too
                    <div className={classNames("highlight-container", drawBorder === false && "no-border")}>
                        {constantIsSecret ? (
                            <SecretInput
                                placeholder={customInputPlaceholder}
                                secretID={customInputValue}
                                setSecretID={defined(onCustomInputChanged)}
                                onMenuButtonClick={onButtonClick}
                            />
                        ) : (
                            <HighlightTextArea
                                inputRef={highlightAreaRef}
                                placeholder={customInputPlaceholder}
                                value={customInputValue}
                                validate={validateCustomValue}
                                onChange={e => {
                                    defined(onCustomInputChanged)(e.target.value);
                                }}
                                debounceUpdate={true}
                                onContextButton={onButtonClick}
                                error={showWarning === true ? warnMessage : undefined}
                                warningType={warningType}
                                showInlineWarning={showInlineWarning}
                            />
                        )}
                    </div>
                )}
                {!hasInputValue && (
                    <GlideDropdownButton
                        value={displayValue}
                        isOpen={isOpen}
                        warn={showWarning === true}
                        warnString={warnMessage}
                        warnType={warnType}
                        drawBorder={drawBorder}
                        // onKeyDown={this.onKeyDown}
                        onClick={onButtonClick}
                        isEnabled={items.length > 0}
                        slim={slim}
                        isInline={isInline}
                        heightVariation={heightVariation}
                        showSpinner={showSpinner}
                    />
                )}
            </Wrapper>
            {isOpen &&
                renderLayer(
                    <div {...layerProps}>
                        <PushDropdown
                            testId={testId}
                            height={maxHeight}
                            width={triggerBounds?.width}
                            descriptionForItem={descriptionForItem as any}
                            prefer={
                                triggerBounds === null
                                    ? "right"
                                    : window.innerWidth - triggerBounds.right < 200
                                    ? "left"
                                    : "right"
                            }
                            {...(rest as any)}
                            onItemSelect={onItemSelect as any}
                        />
                    </div>
                )}
        </>
    );

    const ref = React.useRef<any>();

    if (!isEmptyOrUndefined(displayLabel)) {
        const HelpIcon = getNamedIcon("helpIcon");
        const RemoveIcon = getNamedIcon("remove");
        dropdown = (
            <SplitContainer>
                <DropdownLabel heightVariation={heightVariation} ref={helpText !== undefined ? ref : undefined}>
                    <PropertyTitle title={displayLabel} highlight={highlightLabel} />
                    {(helpUrl !== undefined || helpText !== undefined) && (
                        <div tw="flex align-items[flex-start]">
                            {helpUrl !== undefined && (
                                <IconWrapper
                                    tw="m-0 ml-1 mb-1.5"
                                    href={helpUrl}
                                    target={"_blank"}
                                    rel="noopener noreferrer">
                                    <HelpIcon />
                                </IconWrapper>
                            )}
                            {helpText !== undefined && <MDTooltip content={helpText} />}
                        </div>
                    )}
                </DropdownLabel>
                <SplitDropdownContainer>
                    {dropdown}
                    {onRemove !== undefined && (
                        <RemoveIconContainer onClick={onRemove}>
                            <RemoveIcon />
                        </RemoveIconContainer>
                    )}
                </SplitDropdownContainer>
            </SplitContainer>
        );
    }

    return (
        <GlideDropdownWrapper
            className={classNames(slim && "slim", className)}
            isEnabled={isEnabled ?? true}
            data-testid={`glide-dropdown-${displayLabel ?? label ?? "unlabeled"}`}>
            {label !== undefined && <div className="dropdown-label">{label}</div>}
            {dropdown}
        </GlideDropdownWrapper>
    );
}

interface SecretInputProps {
    readonly secretID: string;
    readonly setSecretID: (newSecretID: string) => void;
    readonly placeholder?: string;
    readonly onMenuButtonClick?: () => void;
}

const SecretInput: React.VFC<SecretInputProps> = p => {
    const { secretID, placeholder, setSecretID, onMenuButtonClick } = p;

    const definedSecretID = React.useMemo(() => (isEmpty(secretID) ? uuid() : secretID), [secretID]);
    const appID = useAppID() ?? "";
    const modals = useModals();

    const getAsyncValue = React.useCallback(async () => {
        // If there's no value, that means there's no secret ID, so we're creating a new one...
        if (isEmpty(secretID)) {
            return "";
        }

        const body: GetPluginSecretBody = { appID, secretID, secretKind: "string" };
        const response = await getAppFacilities().callAuthCloudFunction("getPluginSecret", body, {});
        if (!isResponseOK(response)) return "";

        const json = await response.json();
        return checkString(json?.string);
    }, [appID, secretID]);

    const setAsyncValue = React.useCallback(
        async (newValue: string) => {
            setSecretID(definedSecretID);
            const maybeNewSecretID = await setPluginSecret(appID, definedSecretID, newValue, getAppFacilities());
            if (isErrorInfo(maybeNewSecretID)) {
                void modals.showErrorModal(1079, maybeNewSecretID.message);
                return;
            }
            if (maybeNewSecretID !== undefined) {
                setSecretID(maybeNewSecretID);
            }
        },
        [appID, definedSecretID, modals, setSecretID]
    );

    return (
        <div tw="flex items-center rounded-lg">
            <div tw="grow">
                <AsyncDebouncedInput
                    key={definedSecretID}
                    getAsyncValue={getAsyncValue}
                    setAsyncValue={setAsyncValue}
                    placeholder={placeholder}
                />
            </div>
            <button onClick={onMenuButtonClick} tw="flex pr-1 pl-3 shrink-0">
                <AppIcon icon="moreVertical" size={16} />
            </button>
        </div>
    );
};
