import MobileDetect from "mobile-detect";

const userAgent = typeof window !== "undefined" ? window.navigator.userAgent : "";
const md = new MobileDetect(userAgent);

export function appIsInstalled() {
    if (window.matchMedia === undefined) return false;
    return window.matchMedia("(display-mode: standalone)").matches;
}

export function isInIframe() {
    try {
        // If we can access window.top without error and it equals self,
        // we're definitely not in an iframe
        return typeof window !== "undefined" && window.top !== window.self;
    } catch {
        // If we get a security error, we must be in a cross-origin iframe
        return true;
    }
    // By default, assume we're not in an iframe
    return false;
}

export const browserOnPhone = md.phone() !== null;
// Since iPadOS 13.1.3 (yes, .1.3) PWAs report themselves as running in "Desktop Safari".
// So there's no such thing as a "safe" Safari anymore.
// Take note: Chrome always claims that it is Safari, even when it isn't.
export const browserIsSafari =
    md.userAgent() === "Safari" ||
    (userAgent.indexOf("Mac OS") > -1 && userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") < 0);

// isChrome returns true for Chrome on iOS and iPadOS
export const isChrome = md.userAgent() === "Chrome" || userAgent.includes("Chrome");
export const browserCertainlyIsOniOS = md.is("iOS") || md.is("iPadOS");
export const browserMightBeOniOS = browserCertainlyIsOniOS || browserIsSafari;

function extractiOSVersion(re: RegExp) {
    return ((re.exec(userAgent) ?? [])[1] ?? "")
        .split("_")
        .map(x => Number.parseInt(x, 10))
        .filter(x => !Object.is(x, NaN));
}

const iPhoneOSVersion = extractiOSVersion(/iPhone OS ([0-9_]+)/);
// Since iPadOS 13, Safari lies about being desktop MacOS.
// This only exist to catch really old versions of iOS, back before
// iPadOS was ever a thing.
const iPadOSVersion = extractiOSVersion(/CPU OS ([0-9_]+) like Mac OS X/);

export const iOSVersion = iPhoneOSVersion.length > 0 ? iPhoneOSVersion : iPadOSVersion;

export const browserIsOSX =
    typeof window !== "undefined" ? window.navigator.platform.toLowerCase().startsWith("mac") : false;
export const browserIsAndroidChrome = md.os() === "AndroidOS" && md.userAgent() === "Chrome";
export const isFirefox = userAgent.includes("Firefox");
export const isLinux = userAgent.includes("Linux");

export const shortUserAgent = md.userAgent();
// There's no nice way of detecting this... We're trying our best with this.
export const isMac = userAgent.toLowerCase().includes("macintosh");
