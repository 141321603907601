import { type ActionDescription, ActionKind } from "@glide/app-description";
import type { ActionDescriptor } from "./action-descriptor";
import type { ActionAvailability } from "@glide/function-utils";
import { actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";
import type { GlideIconProps } from "@glide/plugins";

abstract class LegacyActionHandler extends BaseActionHandler<ActionDescription> {
    public get needsData(): boolean {
        return true;
    }
    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }
}

export class OpenLinkActionHandler extends LegacyActionHandler {
    public readonly kind = ActionKind.OpenLink;
    public readonly iconName = "link";
    public readonly name: string = "Open link";

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            isLegacy: true,
            properties: [],
        };
    }
}

export class PhoneCallActionHandler extends LegacyActionHandler {
    public readonly kind = ActionKind.PhoneCall;
    public readonly iconName: GlideIconProps = { icon: "st-dial", kind: "stroke" };
    public readonly name: string = "Dial phone number";

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            isLegacy: true,
            properties: [],
        };
    }
}

export class TextMessageActionHandler extends LegacyActionHandler {
    public readonly kind = ActionKind.TextMessage;
    public readonly iconName: GlideIconProps = { icon: "st-message-square-chat", kind: "stroke" };
    public readonly name: string = "Compose SMS";

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            isLegacy: true,
            properties: [],
        };
    }
}

export class CopyToClipboardActionHandler extends LegacyActionHandler {
    public readonly kind = ActionKind.CopyToClipboard;
    public readonly iconName: GlideIconProps = { icon: "st-clipboard", kind: "stroke" };
    public readonly name: string = "Copy to clipboard";

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            isLegacy: true,
            properties: [],
        };
    }
}

export class OpenMapActionHandler extends LegacyActionHandler {
    public readonly kind = ActionKind.OpenMap;
    public readonly iconName = "map-pin";
    public readonly name: string = "Show address on map";

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            isLegacy: true,
            properties: [],
        };
    }
}

export class SendEmailActionHandler extends LegacyActionHandler {
    public readonly kind = ActionKind.SendEmail;
    public readonly iconName: GlideIconProps = { icon: "st-send", kind: "stroke" };
    public readonly name: string = "Compose email";

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            isLegacy: true,
            properties: [],
        };
    }
}

export class ShowShareScreenHandler extends LegacyActionHandler {
    public readonly kind = ActionKind.ShowShareSheet;
    public readonly iconName: GlideIconProps = { icon: "st-share", kind: "stroke" };
    public readonly name: string = "Share link";

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            isLegacy: true,
            properties: [],
        };
    }
}
