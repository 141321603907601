import { isUrl } from "@glide/support";
import styled from "styled-components";

export const GlideBar = styled.div`
    display: flex;
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    justify-content: flex-end;

    pointer-events: none;
    & > * {
        pointer-events: auto;
    }
`;

export const Scroller = styled.div`
    overflow-x: auto;
    /* overflow-y: hidden; */

    height: 100%;
`;

export const PageContainer = styled.div`
    position: absolute;

    background-color: ${p => p.theme.n200};

    height: 100%;
    width: 100%;

    display: flex;

    justify-content: center;
    align-items: center;
`;

export const AppInfoWrapper = styled.div`
    width: 340px;
    margin-left: 40px;

    flex-shrink: 1;

    @media only screen and (max-width: 800px) {
        display: none;
    }
`;

export const QRCodeWrapper = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    @media only screen and (max-width: 800px) {
        display: none;
    }
`;

export const FrameWrapper = styled.div`
    display: grid;

    align-items: center;
    height: 100%;
`;

export const OutputWrapper = styled.div`
    grid-row: 1;
    grid-column: 1;

    flex-grow: 1;

    height: 100%;
    width: 100%;
    min-width: 393px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FrameInner = styled.div`
    grid-row: 1;
    grid-column: 1;
    height: 100%;
    display: grid;
    place-items: center;
`;

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: 30px 0;

    align-self: stretch;

    align-items: center;
`;

export const ConnectionIssueWrapper = styled.div`
    box-sizing: border-box;

    font-family: sans-serif;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;

    padding: 30px;

    font-size: 18px;
    text-align: center;
    letter-spacing: 0.01em;

    color: ${p => p.theme.fgColorLight};

    & div {
        margin-top: 20px;
    }
`;

export const PlayerContentPlaceholder = styled.div<{ backgroundImage?: string }>`
    background-color: ${p => (p.theme as any).baseColor ?? "#FFFFFF"};
    background-image: ${p =>
        p.backgroundImage !== undefined && isUrl(p.backgroundImage) ? `url(${p.backgroundImage})` : "none"};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .spinner {
        margin-top: 12px;
    }
`;
