export enum ChartType {
    PieChart = "pie-chart",
    DonutChart = "donut-chart",
    BarChart = "bar-chart",
    StackedBarChart = "stacked-bar-chart",
}

// We copied all these ##chartData types from our internal fork of
// minimal-chart.
export interface ChartDatum {
    value: number;
    displayValue: string | undefined;
}

export interface ChartDatumWithTitle extends ChartDatum {
    title: string;
}

export interface BarChartData {
    readonly title: string;
    readonly values: readonly ChartDatum[];
}
