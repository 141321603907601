import { AppAuthenticationKind, type ActionDescription, ActionKind } from "@glide/app-description";
import { getAppAuthenticationKind } from "@glide/common-core/dist/js/components/SerializedApp";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import type { ActionAvailability } from "@glide/function-utils";
import { actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

export class SignInActionHandler extends BaseActionHandler<ActionDescription> {
    public readonly kind = ActionKind.SignIn;
    public readonly iconName: GlideIconProps = {
        icon: "st-sign-in",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Sign in";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 6,
            needsScreenContext: false,
            properties: [],
        };
    }

    public inflate(
        ib: WireActionInflationBackend,
        _desc: ActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        if (
            definedMap(ib.adc.appDescription.authentication, getAppAuthenticationKind) ===
            AppAuthenticationKind.Disabled
        ) {
            return arb.inflationError("Sign-in is disabled");
        }
        return vp => {
            if (vp.getIsUserSignedIn()) return arb.nothingToDo("User is already signed in");

            return async ab => {
                ab.signIn("sign-in", undefined);
                return arb.success();
            };
        };
    }
}
