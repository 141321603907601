import type { AppKind } from "@glide/location-common";
import type { PagesLoginSource } from "../types";
import { formatLocalizedString } from "@glide/localization";

export function getDefaultPagesGreeting(
    pagesSource: PagesLoginSource | undefined,
    appTitle: string | undefined,
    appKind: AppKind
): string {
    const postFix = appTitle !== undefined ? formatLocalizedString("appTitleOptions", [appTitle], appKind) : "";
    if (pagesSource === "modal-sign-up") {
        return formatLocalizedString("signUpWithOptions", [postFix], appKind);
    }
    return formatLocalizedString("logInWithOptions", [postFix], appKind);
}
