import { useNetworkStatus } from "@glide/common-core/dist/js/hooks/use-network-status";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { TextComponentStyle } from "@glide/component-utils";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { localStorageGetItem, localStorageSetItem } from "@glide/support";
import { AnimatePresence, m } from "framer-motion";
import * as React from "react";
import tw from "twin.macro";

import { Text } from "../../components/text/text";

const DONT_SHOW_AGAIN_KEY_PREFIX = "dont-show-again";
interface DontShowAgain {
    readonly dontShowAgain: boolean;
    readonly setDontShowAgain: () => void;
}
const useDontShowAgain = (): DontShowAgain => {
    const appID = useAppID();
    const dontShowAgainAppKey = `${DONT_SHOW_AGAIN_KEY_PREFIX}-${appID}`;
    const dontShowAgain = localStorageGetItem(dontShowAgainAppKey) === "true";
    const setDontShowAgain = () => localStorageSetItem(dontShowAgainAppKey, "true");

    return { dontShowAgain, setDontShowAgain };
};

interface OfflineBannerProps {
    readonly isTabBarVisible: boolean;
}
const MOBILE_TAB_BAR_HEIGHT_PX = 56;

const OfflineIcon = () => (
    <svg tw="h-5 w-5" id="icon-import" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            tw="text-w60A"
            stroke="currentColor"
            strokeWidth="1.5"
            vectorEffect="non-scaling-stroke"
            d="M6 10C3.79086 10 2 11.7909 2 14C2 16.2091 3.79086 18 6 18H13.5M9.43006 5.17713C10.4277 4.43748 11.6627 4 13 4C16.3137 4 19 6.68629 19 10C19 10.0736 19.0493 10.1383 19.12 10.1589C20.784 10.6433 22 12.1797 22 14C22 15.0419 21.6017 15.9907 20.949 16.7026M4 4L20 20"
        />
    </svg>
);

export const OfflineBanner: React.VFC<OfflineBannerProps> = ({ isTabBarVisible }) => {
    const { dontShowAgain } = useDontShowAgain();
    const [showingDialog, setShowDialog] = React.useState(!dontShowAgain);
    const isOnline = useNetworkStatus();
    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    const openDialog = React.useCallback(() => {
        if (showingDialog) return;
        setShowDialog(true);
    }, [showingDialog]);

    const closeDialog = React.useCallback(() => {
        if (!showingDialog) return;
        setShowDialog(false);
    }, [showingDialog]);

    if (!isPlayer()) return null;

    const bottomPosition = isTabBarVisible && isMobile ? MOBILE_TAB_BAR_HEIGHT_PX + 16 : 8;
    return (
        <AnimatePresence>
            {!isOnline && (
                <m.button
                    initial={{ y: "100%" }}
                    animate={{
                        y: `-${bottomPosition}px`,
                        transition: {
                            type: "spring",
                            mass: 1,
                            stiffness: 850,
                            damping: 55,
                        },
                    }}
                    exit={{ y: "100%", transition: { duration: 0.2 } }}
                    id="offline-banner"
                    tw="absolute bottom-[var(--safe-area-inset-bottom)] left-2 rounded-[10px] [background-color: #333] flex justify-center"
                    onClick={openDialog}
                    css={[showingDialog && tw`pointer-events-none right-2 page-md:right-[unset]`]}>
                    {showingDialog ? <DialogOfflineBanner closeDialog={closeDialog} /> : <CompactOfflineBanner />}
                </m.button>
            )}
        </AnimatePresence>
    );
};

const CompactOfflineBanner: React.VFC<{ className?: string }> = ({ className }) => {
    return (
        <div tw="flex gap-x-1.5  px-2 py-1 items-center" className={className}>
            <OfflineIcon />
            <Text tw="text-w90A" variant={TextComponentStyle.small}>
                Offline
            </Text>
        </div>
    );
};

interface DialogOfflineBannerProps {
    readonly className?: string;
    readonly closeDialog: () => void;
}

const DialogOfflineBanner: React.VFC<DialogOfflineBannerProps> = ({ className, closeDialog }) => {
    const { dontShowAgain, setDontShowAgain } = useDontShowAgain();
    return (
        <div
            tw="flex flex-col gap-y-[22px] px-2 py-4 w-full items-center page-sm:(max-w-[377px])"
            className={className}>
            <div tw="flex flex-col items-center">
                <OfflineIcon />
                <Text tw="text-w90A" variant={TextComponentStyle.regular}>
                    You are offline. Your changes will be saved when you are back online.
                </Text>
            </div>
            <div tw="flex gap-x-2 items-center justify-center pointer-events-auto">
                {!dontShowAgain && (
                    <button
                        onClick={() => {
                            setDontShowAgain();
                            closeDialog();
                        }}
                        tw="rounded-full bg-w10A py-[5px] px-[13px]">
                        <Text variant={TextComponentStyle.headlineXXXSmall} tw="text-w100A">
                            Don't show again
                        </Text>
                    </button>
                )}
                <button onClick={closeDialog} tw="rounded-full bg-w100A py-[5px] px-[13px]">
                    <Text variant={TextComponentStyle.headlineXXXSmall} tw="text-n700">
                        Ok, Got it
                    </Text>
                </button>
            </div>
        </div>
    );
};
