import type { LanguageStrings } from "../types";
import { englishLocalizedStrings } from "../english";

export const englishUSLocalizedStrings: LanguageStrings = {
    ...englishLocalizedStrings,
    favorites: "Favorites",
    favorite: "Favorite",
    zip: "Zip",
    uploadNImages: "Upload {0} Images",
    dragImagesHereOr: "Drag images here or",
    addNImages: "Add {0} images",
};
