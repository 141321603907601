import {
    Corners,
    ImageAspectRatio,
    OverlayTextAlignment,
    TextPosition,
    TilesPadding,
} from "@glide/common-core/dist/js/components/image-types";
import type { PropertyDescription, ComponentDescription } from "@glide/app-description";
import {
    type ScreenDescriptionKind,
    ArrayScreenFormat,
    PropertyKind,
    getEnumProperty,
    makeEnumProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import { TextSize } from "@glide/component-utils";
import { type PropertyDescriptor, PropertySection } from "@glide/function-utils";

import type { FixedArrayContent } from "./array-content";
import { NumberOfColumns } from "./number-of-columns";
import {
    type FallbackInitialsSummaryArrayContentDescription,
    type FallbackInitialsSummaryArrayScreenDescription,
    FallbackInitialsSummaryArrayScreenHandler,
    isolateFallbackInitialsSummaryArrayContentDescription,
} from "./summary-array-screen";
import type { TilesArrayContentDescription } from "./tiles-array-screen";

interface GalleryContentDescription extends FallbackInitialsSummaryArrayContentDescription {
    readonly numColumns: PropertyDescription | undefined;
}

interface GalleryScreenDescription extends FallbackInitialsSummaryArrayScreenDescription, GalleryContentDescription {
    readonly kind: ScreenDescriptionKind.Array;
    readonly format: ArrayScreenFormat.Gallery;
}

export class GalleryScreenHandler extends FallbackInitialsSummaryArrayScreenHandler<
    GalleryContentDescription,
    GalleryScreenDescription
> {
    protected readonly supportsNonURLImages = false;
    protected readonly supportsEmojiImages = false;
    protected readonly supportsTruncateList = false;

    constructor() {
        super(ArrayScreenFormat.Gallery, "Gallery", "gallery", true);
    }

    public get isLegacy(): boolean {
        return true;
    }

    protected includeDescription() {
        return false;
    }

    public getAdditionalPropertyDescriptors(): readonly PropertyDescriptor[] {
        return [
            {
                kind: PropertyKind.Enum,
                property: { name: "numColumns" },
                label: "Columns",
                menuLabel: "Number of columns",
                cases: [
                    {
                        value: NumberOfColumns.Two,
                        label: "Two",
                    },
                    {
                        value: NumberOfColumns.Three,
                        label: "Three",
                    },
                    {
                        value: NumberOfColumns.Four,
                        label: "Four",
                    },
                ],
                defaultCaseValue: NumberOfColumns.Three,
                section: PropertySection.Design,
                visual: "dropdown",
            },
        ];
    }

    public fixContentDescription(galleryContent: GalleryContentDescription): FixedArrayContent | undefined {
        const numColumnsValue = getEnumProperty(galleryContent.numColumns) ?? NumberOfColumns.Three;
        const numColumns =
            numColumnsValue === NumberOfColumns.Two ? 2 : numColumnsValue === NumberOfColumns.Three ? 3 : 4;
        return {
            properties: {
                ...isolateFallbackInitialsSummaryArrayContentDescription(galleryContent),
                titleProperty: undefined,
                subtitleProperty: undefined,
                size: makeEnumProperty(ImageAspectRatio.Square),
                numColumns: makeEnumProperty(numColumns),
                padding: makeEnumProperty(TilesPadding.Tight),
                isHorizontal: makeEnumProperty(false),
                allowWrapping: makeSwitchProperty(false),
                corners: makeEnumProperty(Corners.Square),
                textPosition: makeEnumProperty(TextPosition.Below),
                overlayTextAlignment: makeEnumProperty(OverlayTextAlignment.Center),
                textSize: makeEnumProperty(TextSize.Medium),
                allCaps: makeSwitchProperty(false),
            } as TilesArrayContentDescription,
            format: ArrayScreenFormat.Tiles,
        };
    }

    public convertArrayScreenToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }

    public convertInlineToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }
}
