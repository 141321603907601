import { useEventListener, useIsWireThemeDark } from "@glide/common";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import type { IconImage } from "@glide/app-description";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import {
    ResponsiveProvider,
    isSmallScreen,
    useResponsiveSizeClass,
    useResponsiveWidth,
} from "@glide/common-components";
import { ignore, isDefined } from "@glide/support";
import {
    type ResponsiveSizeClass,
    type WireNavigation,
    type WireScreen,
    UIBackgroundStyle,
    WireComponentKind,
    WireNavigationAction,
} from "@glide/wire";
import classNames from "classnames";
import { type MotionValue, type PanInfo, m, useAnimation, useMotionValue } from "framer-motion";
import * as React from "react";
import { css } from "styled-components";
import tw from "twin.macro";

import {
    type LayerInfo,
    type LayerTransition,
    type PageTransitionInfo,
    LayerPositionProvider,
    PageTransitions,
    TransitionProvider,
    getFullLayerTransition,
} from "../../chrome-common";
import { Img } from "../../components/img/img";
import {
    type PagesRendererComponentDependencies,
    PagesComponentRenderer,
} from "../../renderers/pages-component-renderer";
import { WireContainer, WireContainerSpacing } from "../../renderers/wire-container/wire-container";
import { getComponentTitleImage } from "../../utils/get-component-title-image";
import { PortalIdContext, createNavBarPortalIdFromKey, usePortalBaseName } from "../../wire-lib";
import { pushedContentCSS } from "../nav-bar/mobile-sidemenu";
import { OfflineBanner } from "../offline-banner/offline-banner";
import { CaptureBlockedPopup } from "../capture-blocked-popup/capture-blocked-popup";
import { FloatingPortalLayer, ScrollRefProvider, SpecialGroupComponents } from "./content-common";
import { PreventDragBackProvider, usePreventDragBack } from "./lib/prevent-drag-back";
import { ScreenSpecialCasesProvider } from "./lib/screen-special-cases";
import { useScreenComponents } from "./lib/use-layer-compoents";
import { getRenderableComponentIndexAtPosition } from "./lib/get-renderable-component-for-possition";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { flushSync } from "react-dom";
import { UpdateVersionBanner } from "../update-banner/update-banner";

interface MainContentProps {
    readonly currentScreen: WireScreen;
    readonly belowScreen: WireScreen | undefined;
    readonly lastNavigation: WireNavigation | undefined;
    readonly hasModal: boolean;
    readonly backend: WireBackendInterface;
    readonly navBarHeight: number;
    readonly maxSize: ResponsiveSizeClass;
    readonly onScroll: React.UIEventHandler<HTMLDivElement>;
    readonly onDragBack: (() => void) | undefined;
    readonly canGoBack: boolean;
    readonly navBarCrossfadeMotionValue: MotionValue<number>;
    readonly componentDependencies: PagesRendererComponentDependencies;
    readonly iconImage?: IconImage;
    readonly isTabBarVisible: boolean;
}

export interface MainContentRef {
    scrollToTop: () => void;
}

export const ChromeMainContent = React.forwardRef<MainContentRef, MainContentProps>((p, ref) => {
    const {
        currentScreen,
        lastNavigation,
        hasModal,
        backend,
        navBarHeight,
        maxSize,
        onScroll,
        belowScreen,
        onDragBack,
        canGoBack,
        navBarCrossfadeMotionValue,
        componentDependencies,
        iconImage,
        isTabBarVisible,
    } = p;

    const { layers, onAnimationComplete, onDragAnimationComplete } = usePageTransitions(
        currentScreen,
        lastNavigation,
        hasModal
    );

    const noAnimationTopLayer = React.useMemo<LayerInfo>(() => {
        return {
            screen: currentScreen,
            transition: undefined,
            persist: true,
        };
    }, [currentScreen]);

    const canDragBack = belowScreen !== undefined && onDragBack !== undefined;

    const noAnimationBottomLayer = React.useMemo<LayerInfo | undefined>(() => {
        if (!canDragBack || hasModal) {
            return undefined;
        }

        return {
            screen: belowScreen,
            transition: undefined,
            persist: true,
        };
    }, [belowScreen, canDragBack, hasModal]);

    const bottomLayerRef = React.useRef<ContentLayerRef | null>(null);
    const setTopLayerDragRatio = React.useCallback((ratio: number) => {
        bottomLayerRef.current?.updateDragRatio(ratio);
    }, []);

    const topLayerRef = React.useRef<ContentLayerRef | null>(null);
    React.useImperativeHandle(
        ref,
        () => ({
            scrollToTop: () => {
                topLayerRef.current?.scrollToTop();
            },
        }),
        []
    );

    const mainElementRef = React.useRef<HTMLDivElement>(null);

    const onTouchStart = React.useCallback((ev: TouchEvent) => {
        const touch = ev.changedTouches.item(0);
        if (ev.changedTouches.length !== 1 || touch === null) {
            return;
        }

        if (touch.pageX < 15) {
            ev.preventDefault();
        }
    }, []);

    useEventListener("touchstart", onTouchStart, mainElementRef.current, false);

    return (
        <PreventDragBackProvider>
            <main
                ref={mainElementRef}
                css={css`
                    ${pushedContentCSS}
                    @supports (-webkit-touch-callout: none) {
                        @media (hover: none) and (pointer: coarse) and (display-mode: standalone) {
                            .with-sidebar & {
                                padding-top: var(--safe-area-inset-top, 0);
                            }
                        }
                    }
                `}
                tw="relative flex-grow transition-transform duration-200">
                {layers === undefined ? (
                    <>
                        {noAnimationBottomLayer !== undefined && (
                            <ContentLayer
                                layerPosition="bottom"
                                key={noAnimationBottomLayer.screen.key}
                                maxSize={maxSize}
                                navBarHeight={navBarHeight}
                                backend={backend}
                                onAnimationComplete={ignore}
                                layer={noAnimationBottomLayer}
                                ref={bottomLayerRef}
                                currentScreenKey={currentScreen.key}
                                isAnimating={false}
                                componentDependencies={componentDependencies}
                                iconImage={iconImage}
                            />
                        )}

                        <ContentLayer
                            layerPosition="top"
                            key={noAnimationTopLayer.screen.key}
                            maxSize={maxSize}
                            navBarHeight={navBarHeight}
                            backend={backend}
                            onAnimationComplete={ignore}
                            layer={noAnimationTopLayer}
                            onScroll={onScroll}
                            onDragBack={onDragBack}
                            canGoBack={canGoBack}
                            setTopLayerDragRatio={setTopLayerDragRatio}
                            navBarCrossfadeMotionValue={navBarCrossfadeMotionValue}
                            onDragAnimationComplete={onDragAnimationComplete}
                            ref={topLayerRef}
                            isAnimating={false}
                            componentDependencies={componentDependencies}
                            iconImage={iconImage}
                        />
                    </>
                ) : (
                    <>
                        <ContentLayer
                            layerPosition="bottom"
                            key={layers.bottom.screen.key}
                            maxSize={maxSize}
                            navBarHeight={navBarHeight}
                            backend={backend}
                            onAnimationComplete={onAnimationComplete}
                            layer={layers.bottom}
                            currentScreenKey={undefined}
                            isAnimating={true}
                            componentDependencies={componentDependencies}
                            iconImage={iconImage}
                        />

                        <ContentLayer
                            layerPosition="top"
                            key={layers.top.screen.key}
                            maxSize={maxSize}
                            navBarHeight={navBarHeight}
                            backend={backend}
                            onAnimationComplete={onAnimationComplete}
                            layer={layers.top}
                            onScroll={onScroll}
                            onDragBack={onDragBack}
                            canGoBack={canGoBack}
                            setTopLayerDragRatio={setTopLayerDragRatio}
                            navBarCrossfadeMotionValue={navBarCrossfadeMotionValue}
                            onDragAnimationComplete={onDragAnimationComplete}
                            isAnimating={true}
                            componentDependencies={componentDependencies}
                            iconImage={iconImage}
                        />
                    </>
                )}
                <OfflineBanner isTabBarVisible={isTabBarVisible} />
                <CaptureBlockedPopup isTabBarVisible={isTabBarVisible} />
                <UpdateVersionBanner isTabBarVisible={isTabBarVisible} />
            </main>
        </PreventDragBackProvider>
    );
});

const ANIMATED_ACTIONS: (WireNavigationAction | undefined)[] = [
    WireNavigationAction.Pop,
    WireNavigationAction.Push,
    WireNavigationAction.SwitchTab,
];

interface PageTransitionInfoWithDragBack extends PageTransitionInfo {
    readonly onDragAnimationComplete: () => void;
}
/**
 * You'll probably ask yourself why the bottom layer never has a transition.
 * The answer is, because the current animations don't require them.
 *
 * If we want to do a push transition where the back layer yeets to the left, we can!
 */
function usePageTransitions(
    currentScreen: WireScreen,
    lastNavigation: WireNavigation | undefined,
    hasModal: boolean
): PageTransitionInfoWithDragBack {
    const [lastNavigationKey, setLastAnimationKey] = React.useState<string | undefined>();
    const [didDrag, setDidDrag] = React.useState(false);

    const onAnimationComplete = React.useCallback(() => {
        setLastAnimationKey(lastNavigation?.priorScreen.key);
    }, [lastNavigation?.priorScreen.key]);

    const onDragAnimationComplete = React.useCallback(() => {
        setLastAnimationKey(undefined);
        setDidDrag(true);
    }, []);

    // When a new screen comes, we don't care if we dragged or not
    // We might need an animation any ways.
    React.useEffect(() => {
        // Consuming this as a side effect.
        ignore(currentScreen.key);
        setDidDrag(false);
    }, [currentScreen.key]);

    const animationCompleted =
        didDrag ||
        lastNavigationKey === lastNavigation?.priorScreen.key ||
        !ANIMATED_ACTIONS.includes(lastNavigation?.navigationAction) ||
        hasModal;

    const layers = React.useMemo<LayerTransition | undefined>(() => {
        if (!animationCompleted) {
            switch (lastNavigation?.navigationAction) {
                case WireNavigationAction.Push: {
                    return {
                        bottom: {
                            screen: lastNavigation.priorScreen,
                            transition: undefined,
                            persist: false,
                        },
                        top: {
                            screen: currentScreen,
                            transition: PageTransitions.pushIn,
                            persist: true,
                        },
                    };
                }

                case WireNavigationAction.Pop: {
                    return {
                        bottom: {
                            screen: currentScreen,
                            transition: undefined,
                            persist: true,
                        },
                        top: {
                            screen: lastNavigation.priorScreen,
                            transition: PageTransitions.popOut,
                            persist: false,
                        },
                    };
                }

                case WireNavigationAction.SwitchTab: {
                    return {
                        bottom: {
                            screen: lastNavigation.priorScreen,
                            transition: undefined,
                            persist: false,
                        },
                        top: {
                            screen: currentScreen,
                            transition: PageTransitions.fadeIn,
                            persist: true,
                        },
                    };
                }
            }
        }

        return undefined;
    }, [animationCompleted, currentScreen, lastNavigation?.navigationAction, lastNavigation?.priorScreen]);

    return {
        layers,
        onAnimationComplete,
        onDragAnimationComplete,
    };
}

interface BaseContentLayerProps {
    readonly layer: LayerInfo;
    readonly maxSize: ResponsiveSizeClass;
    readonly navBarHeight: number | undefined;
    readonly backend: WireBackendInterface;
    readonly onAnimationComplete: () => void;
    readonly isAnimating: boolean;
    readonly componentDependencies: PagesRendererComponentDependencies;
    readonly iconImage?: IconImage;
}

interface TopContentLayerProps extends BaseContentLayerProps {
    readonly layerPosition: "top";
    readonly onScroll: React.UIEventHandler<HTMLDivElement>;
    readonly onDragBack: (() => void) | undefined;
    readonly onDragAnimationComplete: () => void;
    readonly setTopLayerDragRatio: (ratio: number) => void;
    readonly canGoBack: boolean;
    readonly navBarCrossfadeMotionValue: MotionValue<number>;
}

interface BottomContentLayerProps extends BaseContentLayerProps {
    readonly layerPosition: "bottom";
    readonly currentScreenKey: string | undefined;
}

type ContentLayerProps = TopContentLayerProps | BottomContentLayerProps;

interface ContentLayerRef {
    updateDragRatio: (ratio: number) => void;
    scrollToTop: () => void;
}

const ContentLayer = React.forwardRef<ContentLayerRef, ContentLayerProps>((p, ref) => {
    const {
        maxSize,
        navBarHeight,
        backend,
        layer,
        onAnimationComplete,
        layerPosition,
        isAnimating,
        componentDependencies,
        iconImage,
    } = p;

    const portalContainerImage = getComponentTitleImage(layer.screen.components[0]);

    const theme = useWireAppTheme();

    const [finished, setFinished] = React.useState(false);

    const onAnimationCompleteImpl = React.useCallback(() => {
        onAnimationComplete();
        if (!layer.persist) {
            // Currently, our navigation animation expects layers to not render when they finish animating.
            // So we need to flushSync to make sure the layer won't render after the state changes.
            flushSync(() => setFinished(true));
        }
    }, [layer.persist, onAnimationComplete]);

    const sizeClass = useResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    const pageWidth = useResponsiveWidth();

    const specialComponents = layer.screen.specialComponents.filter(isDefined) ?? [];

    const fullLayerTransition = getFullLayerTransition(layer.transition);

    const portalBaseName = usePortalBaseName();
    const portalId = createNavBarPortalIdFromKey(portalBaseName, layer.screen.key, layerPosition);

    const preventDragBackContext = usePreventDragBack();
    const isPrevented = preventDragBackContext?.isPrevented ?? false;

    const canDrag = layerPosition === "top" && p.canGoBack && p.onDragBack !== undefined && isMobile && !isPrevented;

    const x = useMotionValue(0);
    const animate = useAnimation();

    const onDragEnd = async (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        if (layerPosition === "top") {
            const draggedTooMuchY = Math.abs(info.offset.y) > 50;
            const draggedEnoughX = info.offset.x > pageWidth / 2 || info.velocity.x > 10;
            const gestureShouldGoBack = draggedEnoughX && !draggedTooMuchY;

            if (canDrag && gestureShouldGoBack) {
                await animate.start({ x: pageWidth });
                p.onDragBack();
                p.onDragAnimationComplete();
                p.navBarCrossfadeMotionValue.set(0);
            } else {
                await animate.start({ x: 0 });
                p.setTopLayerDragRatio(1);
            }
        }
    };

    const dragRatioRef = React.useRef<number | undefined>(undefined);
    const darkOverlayRef = React.useRef<HTMLDivElement>(null);
    const layerRef = React.useRef<HTMLDivElement>(null);

    const [isStaticLayerVisible, setIsStaticVisible] = React.useState(true);

    const currentScreenKey = layerPosition === "bottom" ? p.currentScreenKey : undefined;
    React.useEffect(() => {
        // When the current screen finally changes hide the bottom layer until we drag or go back.
        // That way we won't keep focus on the bottom screen.
        if (currentScreenKey !== undefined) {
            setIsStaticVisible(false);
        }
    }, [currentScreenKey]);

    const updateDivsFromDragRatio = React.useCallback(() => {
        if (layerPosition === "bottom" && dragRatioRef.current !== undefined) {
            const tx = 100 * (dragRatioRef.current - 1);
            const darkOverlayOpacity = 0.2 * (1 - dragRatioRef.current);

            darkOverlayRef.current?.style.setProperty("opacity", darkOverlayOpacity.toString());

            layerRef.current?.style.setProperty("transform", `translateX(${tx}px)`);
            if (dragRatioRef.current > 0) {
                setIsStaticVisible(true);
            }
        }
    }, [layerPosition]);

    React.useImperativeHandle(
        ref,
        () => ({
            updateDragRatio: (ratio: number) => {
                if (layerPosition === "bottom") {
                    dragRatioRef.current = ratio;
                    updateDivsFromDragRatio();
                }
            },
            scrollToTop: () => {
                layerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
            },
        }),
        [layerPosition, updateDivsFromDragRatio]
    );

    // On every render we need to make sure that the translated div and the dark overlay
    // are in sync with the drag ratio.
    // Sometimes a re-render can reset the div's translate for unknown reasons.
    React.useLayoutEffect(() => {
        updateDivsFromDragRatio();
    });

    React.useEffect(() => {
        if (layerPosition === "top") {
            return x.onChange(v => {
                // So this one is fun:
                // If you tap the layer WHILE it's snapping back to 0 (or 100%) it freezes
                // until you interact with it again.
                // This makes it so you can't really interact with it whenever a drag is happening.
                if (v > 0) {
                    darkOverlayRef.current?.style.setProperty("pointer-events", "auto");
                } else {
                    darkOverlayRef.current?.style.setProperty("pointer-events", "none");
                }

                // Clamp it to [0, 1] to avoid visual artifacts
                const ratio = Math.max(0, Math.min(v / pageWidth, 1));
                p.navBarCrossfadeMotionValue.set(ratio);
                p.setTopLayerDragRatio(ratio);
            });
        }

        return;
    }, [layerPosition, p, pageWidth, x]);

    const onScroll = layerPosition === "top" ? p.onScroll : undefined;

    const isVisible = layerPosition === "top" || isStaticLayerVisible || isAnimating;
    const components = useScreenComponents(layer.screen);
    const isWireThemeDark = useIsWireThemeDark(theme.pageEnvironment);
    const firstRenderableIndex = getRenderableComponentIndexAtPosition(components, 0) ?? 0;
    const isFirstCollection = components[firstRenderableIndex]?.kind === WireComponentKind.List;

    if (finished || !isVisible) {
        return null;
    }

    return (
        <LayerPositionProvider position={layerPosition}>
            <TransitionProvider transition={layer.transition}>
                <PortalIdContext.Provider value={portalId}>
                    <ScreenSpecialCasesProvider components={components}>
                        <ScrollRefProvider scrollRef={layerRef}>
                            <m.div
                                drag="x"
                                dragDirectionLock={true}
                                dragMomentum={false}
                                dragListener={canDrag}
                                dragSnapToOrigin={false}
                                dragElastic={0}
                                dragConstraints={{ left: 0, right: canDrag ? pageWidth : 0 }}
                                transition={{ type: "spring", duration: 0.2, bounce: 0, velocity: 0 }}
                                animate={animate}
                                onDragEnd={onDragEnd}
                                className={classNames(fullLayerTransition, isMobile && "mobile-layer")}
                                onAnimationEnd={onAnimationCompleteImpl}
                                ref={layerRef}
                                tw="flex absolute inset-0 bg-bg-container-base overflow-y-auto overflow-x-hidden"
                                onScroll={onScroll}
                                style={{ x, willChange: "transform" }}
                                css={css`
                                    ${getCssHackForTransparentNavBar(navBarHeight)}

                                    &.mobile-layer {
                                        ::-webkit-scrollbar {
                                            display: none;
                                        }
                                        scrollbar-width: none;
                                    }
                                `}>
                                <ResponsiveProvider
                                    maxSize={maxSize}
                                    id="main-root"
                                    tw="relative flex flex-col items-center flex-1 min-w-0">
                                    <WireContainer
                                        dontAnimate={true}
                                        spacing={WireContainerSpacing.collapsed}
                                        ignoreHighlight={true}
                                        className={classNames(
                                            (theme.pageTheme ?? "").toLowerCase(),
                                            theme.pageBackground === "Neutral" && "neutral",
                                            isFirstCollection && "first-collection",
                                            isMobile && "is-mobile",
                                            isWireThemeDark ? "dark-system-theme" : "light-system-theme"
                                        )}
                                        backgroundElement={
                                            portalContainerImage && (
                                                <Img
                                                    className={(theme.pageTheme ?? "").toLowerCase()}
                                                    css={css`
                                                        mix-blend-mode: luminosity;
                                                        .accent & {
                                                            filter: contrast(0.3);
                                                        }

                                                        .dark & {
                                                            filter: contrast(0.3) brightness(0.4);
                                                        }

                                                        .highlight & {
                                                            filter: contrast(0.2) brightness(2);
                                                        }
                                                    `}
                                                    tw="absolute inset-0 object-cover w-full h-full pointer-events-none"
                                                    data-test="hero-background-image"
                                                    src={massageImageUrl(
                                                        portalContainerImage ?? undefined,
                                                        {
                                                            width: window.innerWidth,
                                                            thumbnail: false,
                                                        },
                                                        backend.appID
                                                    )}
                                                    width={"100%"}
                                                    height={400}
                                                    draggable={false}
                                                    isPages={true}
                                                />
                                            )
                                        }
                                        tw="gp-md:mb-0"
                                        css={css`
                                            ${getCssHackForTransparentNavBar(navBarHeight)}

                                            &.first-collection {
                                                ${tw`-mb-2 page-md:mb-0`}
                                            }
                                        `}
                                        background={
                                            theme.pageTheme === "Highlight"
                                                ? UIBackgroundStyle.White
                                                : theme.pageTheme === "Dark"
                                                ? UIBackgroundStyle.Dark
                                                : UIBackgroundStyle.Accent
                                        }>
                                        <div id={portalId} />
                                    </WireContainer>
                                    <div tw="relative all-child:shrink-0 flex flex-col w-full shrink-0 grow all-child:last:pb-28 gp-md:all-child:last:pb-14">
                                        <PagesComponentRenderer
                                            hasPrimary={true}
                                            components={components}
                                            backend={backend}
                                            componentDependencies={componentDependencies}
                                            iconImage={iconImage}
                                        />
                                    </div>
                                    <SpecialGroupComponents specialComponents={specialComponents} backend={backend} />
                                </ResponsiveProvider>
                            </m.div>
                            <div ref={darkOverlayRef} tw="absolute inset-0 bg-black opacity-0 pointer-events-none" />
                            <FloatingPortalLayer maxSize={maxSize} />
                        </ScrollRefProvider>
                    </ScreenSpecialCasesProvider>
                </PortalIdContext.Provider>
            </TransitionProvider>
        </LayerPositionProvider>
    );
});

function getCssHackForTransparentNavBar(navBarHeight: number | undefined) {
    return css`
        margin-top: ${-(navBarHeight ?? 0)}px !important;
        padding-top: ${navBarHeight ?? 0}px;
    `;
}
