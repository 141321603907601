export function insertAtCursor(element: HTMLTextAreaElement, textToInsert: string): string {
    const { selectionStart, selectionEnd, value } = element;
    const beforeText = value.substring(0, selectionStart);
    const afterText = value.substring(selectionEnd);

    // Add a space before if there's text before and it doesn't end with a space
    const spacePrefix = beforeText.length > 0 && !beforeText.endsWith(" ") ? " " : "";
    // Add a space after if there's text after and it doesn't start with a space
    const spaceSuffix = afterText.length > 0 && !afterText.startsWith(" ") ? " " : "";

    return beforeText + spacePrefix + textToInsert + spaceSuffix + afterText;
}

export function setCaretPosition(element: HTMLTextAreaElement, position: number) {
    element.focus();
    element.setSelectionRange(position, position);
}
