import "twin.macro";

import {
    type PropertyDescription,
    getGeneratedKeyPairProperty,
    makeGeneratedKeyPairProperty,
} from "@glide/app-description";
import type { GetPluginSecretBody } from "@glide/common-core/dist/js/firebase-function-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { copyToClipboard } from "@glide/common";
import { checkString, isResponseOK } from "@glide/support";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import toast from "react-hot-toast";
import uuid from "uuid";

import { Button } from "../button/button";

interface Props extends React.PropsWithChildren {
    readonly appID: string;
    readonly desc: PropertyDescription | undefined;
    readonly setDesc: (desc: PropertyDescription) => void;
}

export const GeneratedKeyPairConfigurator: React.VFC<Props> = props => {
    const { appID, desc, setDesc } = props;

    const secretID = React.useMemo(() => {
        let id = getGeneratedKeyPairProperty(desc);
        if (id === undefined) {
            id = uuid();
            setDesc(makeGeneratedKeyPairProperty(id));
        }
        return id;
    }, [desc, setDesc]);

    const { data: publicKey } = useQuery(
        ["getPluginSecret", appID, secretID],
        async ({ queryKey: [, theAppID, theSecretID] }) => {
            const body: GetPluginSecretBody = { appID: theAppID, secretKind: "public-key", secretID: theSecretID };
            const response = await getAppFacilities().callAuthCloudFunction("getPluginSecret", body);
            if (!isResponseOK(response)) return undefined;

            const json = await response.json();
            return checkString(json.publicKey);
        }
    );

    if (publicKey === undefined) {
        return <div tw="text-builder-sm">Loading public key...</div>;
    } else {
        return (
            <div>
                <Button
                    buttonType="secondary"
                    variant="default"
                    size="sm"
                    label={"Copy public key"}
                    isFullWidth={true}
                    onClick={() => {
                        copyToClipboard(publicKey);
                        toast.success("Public key copied to clipboard");
                    }}
                />
            </div>
        );
    }
};
