// Ultimately, these *should probably* be driven by plugins config, And
// probably can be, except that this controls display order. the keys match the
// plugin ids and the respective clr-{pluginkey} icons.
export const pluginBasedDataSources = {
    postgresql: "PostgreSQL",
    mysql: "MySQL",
    "google-cloud-sql": "Cloud SQL",
    sqlserver: "SQL Server",
};

export const addableDataSources = {
    gsheet: "Google Sheets",
    airtable: "Airtable",
    excel: "Excel Online",

    // GBT is not a plugin
    "glide-big-tables": "Glide Big Tables",

    // this is separate because we are using it to drive the data editor
    // for v4 pricing work via data-source-capabilities-provider
    ...pluginBasedDataSources,

    // BQ is not a plugin yet, but maybe it should be?
    bigquery: "BigQuery",

    // These are future possibilities. In Figma but not yet Gliding.
    // oracle: "Oracle",
    // google: "Google",
    // hubspot: "Hubspot",
    // microsoft: "Microsoft",
    // sap: "SAP",
    // csv: "CSV",

    // Figma exports glide tables (default), but create new app flow
    // doesn't surface it and we aren't yet using this for the data
    // editor
    /* glide: "Glide Tables", */
} as const;

export const isAddableDataSource = (item: string): item is keyof typeof addableDataSources => {
    return Object.keys(addableDataSources).includes(item);
};
