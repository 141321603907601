/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

const pixelID = glide.makeParameter({
    type: "string",
    name: "Pixel ID",
    description: "Unique tracking identifier for your Meta Pixel. It should be a 16-digit number.",
    placeholder: "0123456789",
    required: true,
});

export const plugin = glide.newPlugin({
    name: "Meta Pixel",
    id: "meta-pixel",
    description: "Passively track user activity in Facebook Ads Manager.",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/meta.png",
    tier: "starter",
    parameters: { pixelID },
    documentationUrl: "https://www.glideapps.com/docs/meta-pixel",
});

plugin.addHeader(({ pixelID }) => {
    const isDigits = /^\d+$/.test(pixelID);
    if (!isDigits) {
        return `<script>console.log("Invalid Meta Pixel ID")</script>`;
    }
    return `
        <!-- Meta Pixel Code -->
        <script>
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixelID}');
        fbq('track', 'PageView');
        </script>
        <noscript><img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${pixelID}&ev=PageView&noscript=1"
        /></noscript>
        <!-- End Meta Pixel Code -->
        `;
});
