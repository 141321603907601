import { assert, DefaultMap, definedMap } from "@glideapps/ts-necessities";
import { isLeft } from "fp-ts/lib/Either";
import * as iots from "io-ts";
import { GlideDateTime, convertDateFromTimeZoneAgnostic } from "@glide/data-types";

import { asDate, asMaybeNumber, asMaybeString, isPrimitiveValue } from "@glide/computation-model-types";
import { asMaybeBoolean } from "@glide/common-core/dist/js/type-conversions";
import { logError, isArray } from "@glide/support";
import {
    type ColumnTypeKind,
    type StringGlideTypeKind,
    isDateTimeTypeKind,
    isStringTypeKind,
} from "@glide/type-schema";

import {
    type YesCodeColumnManifest,
    type YesCodeParameter,
    type YesCodeType,
    type YesCodeValue,
    yesCodeColumnManifest,
} from "@glide/common-core/dist/js/yes-code-types";
import type { YesCodeParameterMap } from "@glide/common-core/dist/js/components/types";

const yesCodeManifest = iots.union([yesCodeColumnManifest, iots.readonlyArray(yesCodeColumnManifest)]);

const manifestPromises = new DefaultMap<string, Promise<YesCodeColumnManifest | string>>(fetchManifest);
const manifestResults = new Map<string, YesCodeColumnManifest | string>();

export function normalizeYesCodeURL(url: string): string {
    if (!url.includes("://")) {
        url = "https://" + url;
    }
    if (!url.endsWith("/")) {
        url = url + "/";
    }
    return url;
}

async function fetchManifest(url: string): Promise<YesCodeColumnManifest | string> {
    url = normalizeYesCodeURL(url);

    let manifestURL = url + "glide.json";
    let result: Response;
    try {
        result = await fetch(manifestURL);
        if (!result.ok) {
            void result.text();
            throw new Error("fail");
        }
    } catch {
        manifestURL = url + "manifest.json";
        try {
            result = await fetch(manifestURL);
            if (!result.ok) {
                void result.text();
                throw new Error("fail");
            }
        } catch (e: unknown) {
            logError("Failed to load YesCode manifest", manifestURL, e);
            return 'Loading the manifest "glide.json" failed. Is the URL correct, and the hosting set up correctly?';
        }
    }

    let json: unknown;
    try {
        json = await result.json();
    } catch {
        return "The manifest is not correct JSON.";
    }

    const parsed = yesCodeManifest.decode(json);
    if (isLeft(parsed)) return "The manifest does not have the correct format.";

    if (isArray(parsed.right)) {
        const manifest = parsed.right[0];
        if (manifest === undefined) {
            return "The manifest must contain at least one column.";
        }
        return manifest;
    } else {
        return parsed.right;
    }
}

async function getYesCodeManifestAsync(url: string): Promise<YesCodeColumnManifest | string> {
    try {
        const result = await manifestPromises.get(url);
        manifestResults.set(url, result);
        if (typeof result === "string") {
            manifestPromises.delete(url);
        }
        return result;
    } catch (e: unknown) {
        logError("Could not fetch manifest", e);
        manifestPromises.delete(url);
        return "An unexpected error happened when loading the manifest.";
    }
}

export function getYesCodeManifest(
    url: string
): YesCodeColumnManifest | string | Promise<YesCodeColumnManifest | string> {
    const maybeResult = manifestResults.get(url);
    if (maybeResult !== undefined) {
        return maybeResult;
    }

    return getYesCodeManifestAsync(url);
}

export function unloadYesCodeManifest(url: string): void {
    manifestPromises.delete(url);
}

function isStringType(type: YesCodeType): type is StringGlideTypeKind {
    return isStringTypeKind(type as ColumnTypeKind);
}

export interface YesCodeValueWithType {
    readonly value: YesCodeValue | undefined;
    readonly type: YesCodeType;
}

export function convertYesCodeValue(
    maybeValue: readonly [unknown, unknown] | undefined,
    type: YesCodeType
): YesCodeValueWithType {
    function convertString(v: unknown): string | undefined {
        if (v instanceof GlideDateTime) {
            return v.asTimeZoneAwareDate().toISOString();
        }
        if (v instanceof Date) {
            return convertDateFromTimeZoneAgnostic(v).toISOString();
        }
        const s = asMaybeString(v);
        if (s === "") return undefined;
        return s;
    }

    if (maybeValue === undefined) {
        return { value: undefined, type };
    }

    const [value, formatted] = maybeValue;

    if (value === undefined) {
        return { value: undefined, type };
    } else if (isDateTimeTypeKind(type as ColumnTypeKind)) {
        if (value instanceof GlideDateTime) {
            return { value: value.asTimeZoneAwareDate().toISOString(), type };
        } else {
            // TODO: Consider parsing with `parseValueAsDateSync` as opposed to
            // `asDate` here.
            return { value: definedMap(asDate(value), d => convertDateFromTimeZoneAgnostic(d).toISOString()), type };
        }
    } else if (type === "string") {
        return { value: convertString(formatted), type: "string" };
    } else if (isStringType(type)) {
        return { value: convertString(value), type: "string" };
    } else if (type === "number") {
        return { value: asMaybeNumber(value), type: "number" };
    } else if (type === "boolean") {
        return { value: asMaybeBoolean(value), type: "boolean" };
    } else if (type === "primitive") {
        if (typeof value === "string") {
            return { value, type: "string" };
        } else if (typeof value === "number") {
            return { value, type: "number" };
        } else if (typeof value === "boolean") {
            return { value, type: "boolean" };
        } else if (value instanceof GlideDateTime) {
            return { value: value.asTimeZoneAwareDate().toISOString(), type: "date-time" };
        } else if (value instanceof Date) {
            return { value: value.toISOString(), type: "date-time" };
        } else if (isPrimitiveValue(value)) {
            return { value: convertString(value), type: "string" };
        } else {
            return { value: undefined, type: "primitive" };
        }
    } else {
        if (!isArray(value)) return { value: undefined, type };

        return {
            value: value.map(v => {
                const r = convertYesCodeValue([v, v], type.items).value;
                assert(!isArray(r));
                return r;
            }),
            type,
        };
    }
}

export function makeParams(manifest: YesCodeColumnManifest, paramsMap: YesCodeParameterMap): YesCodeParameter[] {
    const params: YesCodeParameter[] = [];
    for (const ps of manifest.params) {
        params.push({ name: ps.name, ...convertYesCodeValue(paramsMap.get(ps.name), ps.type) });
    }
    return params;
}
