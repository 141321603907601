import "twin.macro";

import {
    appIsInstalled,
    browserIsAndroidChrome,
    browserMightBeOniOS,
    browserOnPhone,
    madeWithGlide,
    TailwindThemeProvider,
    isInIframe,
} from "@glide/common";
import { getSpinner } from "@glide/common-core/dist/js/components/activity-spinner/activity-spinner";
import type { IconImage } from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import { glideViralReferralLink, isEmptyOrUndefinedish } from "@glide/support";
import classNames from "classnames";
import { useEffect, useState } from "react";

import type { BaseProps } from "./custom-sign-in-props";
import { AppTitle, CustomSignInStyle, IconContainer } from "./custom-sign-in-style";
import EmailInput from "./email-input";
import { canShowExternalAuthButtons } from "./google-sso";
import NameInput from "./name-input";
import PasswordInput from "./password-input";
import { AddToHomescreen } from "../../chrome/add-to-homescreen/add-to-homescreen";
import { Img } from "../img/img";
import { PlayerContentPlaceholder } from "@glide/common-components";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

const MadeWithGlideIcon = madeWithGlide;

interface Props extends BaseProps {
    // For add to homescreen we need the icon
    readonly iconImage?: IconImage;
    readonly appID: string;
}

export const CustomSignIn: React.FC<React.PropsWithChildren<Props>> = p => {
    const [visualViewportHeight, setVisualViewportHeight] = useState<number | undefined>(undefined);
    const [isPopoverOpen, setIsPopoverOpen] = useState(true);
    const theme = useWireAppTheme();

    useEffect(() => {
        // onMount
        if (window.visualViewport !== undefined && browserMightBeOniOS) {
            (window.visualViewport as any).addEventListener("scroll", viewportHandler);
            (window.visualViewport as any).addEventListener("resize", viewportHandler);
        }
        return () => {
            //unmount
            if (window.visualViewport !== undefined && browserMightBeOniOS) {
                (window.visualViewport as any).removeEventListener("scroll", viewportHandler);
                (window.visualViewport as any).removeEventListener("resize", viewportHandler);
            }
        };
    }, []);

    const viewportHandler = (e: any) => {
        setVisualViewportHeight(e.target.height);
    };

    const {
        loginType,
        colorTheme,
        currentState,
        background,
        icon,
        logoSize,
        backgroundOverlay,
        isPro,
        useParallax,
        appTitle,
        appFeatures,
        previewMode,
        appKind,
        iconImage,
        appID,
    } = p;
    let buttonOffset = 0;

    if (visualViewportHeight !== undefined && window.innerHeight !== visualViewportHeight && window.innerHeight > 500) {
        buttonOffset = window.innerHeight - visualViewportHeight;
        // FIXME: This is a hack to make the virtual keyboard overlap the Sign in with Google
        // button. This only works when there's one external auth button, too.
        // Either make this method report the number of auth buttons we can show or
        // figure out a better way to handle keyboard overlap.
        if (canShowExternalAuthButtons(appFeatures)) {
            buttonOffset -= 66;
        }
    }

    const hasIcon = !isEmptyOrUndefinedish(icon);
    let size = 120;

    if (logoSize !== undefined) {
        if (logoSize === "small") {
            size = 80;
        } else if (logoSize === "medium") {
            size = 100;
        }
    }

    if (currentState === "sign-in") {
        const showBackgroundImage =
            appFeatures?.useCustomSignInBackgroundImageInLoading === true && background !== undefined;
        return (
            <TailwindThemeProvider theme={theme}>
                <PlayerContentPlaceholder
                    data-test="app-signing-in"
                    backgroundImage={showBackgroundImage ? background : undefined}>
                    {!isPro && <MadeWithGlideIcon fgColor={theme.fgColorDark} />}
                    <div className="spinner">{getSpinner(theme.primaryAccentColor, "loading-placeholder")}</div>
                </PlayerContentPlaceholder>
            </TailwindThemeProvider>
        );
    }

    // there was a bug where "none" got put in as "no" so treat them the same
    return (
        <CustomSignInStyle
            className={classNames(
                `${
                    backgroundOverlay === undefined ||
                    backgroundOverlay === "none" ||
                    backgroundOverlay === ("no" as any) ||
                    background === undefined
                        ? "accent"
                        : "black"
                }`,
                appKind
            )}
            colorTheme={colorTheme}
            useParallax={useParallax === true && currentState === "challenge"}
            isBuilder={true}
            buttonOffset={buttonOffset}
            previewMode={previewMode}
            role="dialog"
            aria-modal={true}
            aria-labelledby="sign-in-description">
            {background && appKind === AppKind.App && (
                <Img className={`bc-background-image ${backgroundOverlay}`} src={background} altBehavior="loading" />
            )}
            <div
                className="bc-main-content"
                onClick={() => {
                    if (!browserIsAndroidChrome) {
                        setIsPopoverOpen(false);
                    }
                }}>
                <div className="bc-content-container">
                    {appKind === AppKind.App && (
                        <IconContainer data-test="app-sign-in-icon" size={size}>
                            {hasIcon && <Img src={icon} altBehavior="loading" draggable={false} />}
                            {appTitle !== undefined && !hasIcon && <AppTitle> {appTitle} </AppTitle>}
                        </IconContainer>
                    )}
                    {loginType === "none" && currentState !== "request-access-complete" && <NameInput {...p} />}
                    {loginType === "password" && <PasswordInput theme={theme} {...p} />}
                    {loginType === "email" && <EmailInput theme={theme} {...p} />}
                    {isPro === false && appKind === AppKind.App && (
                        <a
                            className="bc-footer-wrap"
                            href={glideViralReferralLink("sign-in")}
                            rel="noopener noreferrer"
                            target="_blank">
                            <MadeWithGlideIcon />
                        </a>
                    )}
                </div>
                {appKind === AppKind.App && isPopoverOpen && browserOnPhone && !appIsInstalled() && !isInIframe() ? (
                    <AddToHomescreen
                        isOpen={isPopoverOpen}
                        closeDialog={() => setIsPopoverOpen(false)}
                        iconImage={iconImage}
                        appTitle={appTitle === undefined ? "App" : appTitle}
                        appKind={appKind}
                        theme={theme}
                        appID={appID}
                    />
                ) : null}
            </div>
        </CustomSignInStyle>
    );
};
