import type { ButtonSizes } from "../button/button-lib";
import { Button } from "../button/button";

interface Props<T extends string> {
    values: T[];
    value: T;
    size?: ButtonSizes;
    selectedStyle?: "secondary" | "primary" | "tertiary";
    setValue: (value: T) => void;
    displayName?: (value: T) => string;
}

export function SegmentedButton<T extends string = string>(props: Props<T>) {
    const { values, value, setValue, displayName = x => x, size = "sm", selectedStyle = "primary" } = props;

    return (
        <div tw="space-y-4">
            {values.map((v, i) => {
                return (
                    <Button
                        variant={"default"}
                        buttonType={value === v ? selectedStyle : "minimal"}
                        size={size}
                        label={displayName(v)}
                        onClick={() => setValue(v)}
                        key={i}
                    />
                );
            })}
        </div>
    );
}
