import { styled } from "@glide/common";

export const MoreMenuStyle = styled.button<{ size: number }>`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    border-radius: ${p => p.size / 4}px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color ${p => p.theme.transitionBase};

    cursor: pointer;

    &:hover,
    &:focus {
        background-color: ${p => p.theme.n300A};
    }
`;
