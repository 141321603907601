import {
    Corners,
    ImageAspectRatio,
    OverlayTextAlignment,
    TextPosition,
    TilesPadding,
} from "@glide/common-core/dist/js/components/image-types";
import type { PropertyDescription, ScreenDescriptionKind, ComponentDescription } from "@glide/app-description";
import { ArrayScreenFormat, getEnumProperty, makeEnumProperty, makeSwitchProperty } from "@glide/app-description";
import { TextSize } from "@glide/component-utils";
import { type PropertyDescriptor, makeImageHeightPropertyHandler } from "@glide/function-utils";

import type { FixedArrayContent } from "./array-content";
import {
    type FallbackInitialsSummaryArrayContentDescription,
    type SummaryArrayScreenDescription,
    FallbackInitialsSummaryArrayScreenHandler,
    isolateFallbackInitialsSummaryArrayContentDescription,
} from "./summary-array-screen";
import type { TilesArrayContentDescription } from "./tiles-array-screen";

interface GridArrayContentDescription extends FallbackInitialsSummaryArrayContentDescription {
    readonly size: PropertyDescription | undefined;
}

interface GridArrayScreenDescription extends SummaryArrayScreenDescription, GridArrayContentDescription {
    readonly kind: ScreenDescriptionKind.Array;
    readonly format: ArrayScreenFormat.Grid;
}

export class GridArrayScreenHandler extends FallbackInitialsSummaryArrayScreenHandler<
    GridArrayContentDescription,
    GridArrayScreenDescription
> {
    protected readonly supportsNonURLImages = true;
    protected readonly supportsEmojiImages = false;
    protected readonly supportsTruncateList = false;
    protected readonly fullTitleFallback = true;

    constructor() {
        super(ArrayScreenFormat.Grid, "Old Tiles", "grid", true);
    }

    public get isLegacy(): boolean {
        return true;
    }

    public getAdditionalPropertyDescriptors(): ReadonlyArray<PropertyDescriptor> {
        return [makeImageHeightPropertyHandler(ImageAspectRatio.ThreeByOne, false, false, false)];
    }

    public fixContentDescription(gridContent: GridArrayContentDescription): FixedArrayContent | undefined {
        return {
            properties: {
                ...isolateFallbackInitialsSummaryArrayContentDescription(gridContent),
                size: makeEnumProperty(getEnumProperty(gridContent.size) ?? ImageAspectRatio.ThreeByOne),
                numColumns: makeEnumProperty(1),
                padding: makeEnumProperty(TilesPadding.Loose),
                isHorizontal: makeEnumProperty(false),
                allowWrapping: makeSwitchProperty(true),
                corners: makeEnumProperty(Corners.Rounded),
                textPosition: makeEnumProperty(TextPosition.Below),
                overlayTextAlignment: makeEnumProperty(OverlayTextAlignment.Center),
                textSize: makeEnumProperty(TextSize.Medium),
                allCaps: makeSwitchProperty(false),
            } as TilesArrayContentDescription,
            format: ArrayScreenFormat.Tiles,
        };
    }

    public convertArrayScreenToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }

    public convertInlineToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }
}
