/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { Result } from "@glide/plugins";
import { maybeParseJSON } from "@glide/support";

const ICON_BASE = "var(--gv-icon-base)";
const LIME_500 = "var(--gv-lime500)";

export const plugin = glide.newPlugin({
    id: "data-structures",
    name: "Data Structures",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/data-structure.png",
    description: `Experimental data structures. These are not guaranteed to be stable, and they are not atomic, meaning that if multiple users change them at the same time, the result may not be what you expect.`,
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/data-structures",
});

const stringSet = glide.makeParameter({
    type: "string",
    name: "String Set",
    description:
        "A set of strings, represented as JSON. Operations are not atomic–if you have multiple actions in a row, they may not see the same state.",
    placeholder: `e.g. ["x", "y", "z"]`,
    required: true,
});

const stringElement = glide.makeParameter({
    type: "string",
    name: "Element",
    placeholder: "e.g. x",
    required: true,
});

const stringElements = glide.makeParameter({
    type: "stringArray",
    name: "Element",
    description: "One or more elements.",
    placeholder: `e.g. x or ["x", "y"]`,
    required: true,
});

export function jsonToStringSet(stringSet: string | undefined | null): Result<Set<string>> {
    if (stringSet === undefined || stringSet === null) return Result.Ok(new Set());
    if (stringSet === "") return Result.Ok(new Set());

    try {
        const asArray = JSON.parse(stringSet);
        if (!Array.isArray(asArray))
            return Result.Fail("Invalid set value", {
                isPluginError: false,
                data: asArray,
            });

        const asArrayOfStrings = asArray.map(x => (typeof x === "string" ? x : `${x}`));
        const asSet = new Set(asArrayOfStrings);

        return Result.Ok(asSet);
    } catch (e: unknown) {
        return Result.Fail("Invalid set value", {
            isPluginError: false,
            data: maybeParseJSON(e),
        });
    }
}

export function stringSetToJSON(stringSet: Set<string>): string {
    return JSON.stringify(Array.from(stringSet));
}

const emptySet = "[]";

function mapStringSet<T>(stringSet: string, fn: (x: Set<string>) => T): Result<T> {
    return Result.map(jsonToStringSet(stringSet), fn);
}

export function stringSetAdd(
    stringSet: string,
    stringElement: string | readonly string[] | undefined | null
): Result<string> {
    if (stringElement === undefined || stringElement === null) return Result.Ok(stringSet);

    return mapStringSet(stringSet, set => {
        const elements = Array.isArray(stringElement) ? stringElement : [stringElement];
        elements.forEach(x => set.add(x));
        return stringSetToJSON(set);
    });
}

export function stringSetRemove(stringSet: string, stringElement: string | readonly string[]): Result<string> {
    return mapStringSet(stringSet, set => {
        const elements = Array.isArray(stringElement) ? stringElement : [stringElement];
        elements.forEach(x => set.delete(x));
        return stringSetToJSON(set);
    });
}

plugin.addClientComputation({
    id: "string-set-add",
    name: "Add element to set",
    description: "Add an element to a set",
    group: "Data",
    icon: { icon: "st-add-set", kind: "stroke", strokeFgColor: ICON_BASE, strokeColor: LIME_500 },
    parameters: { stringSet, stringElements },
    results: { stringSet },

    execute: async (_context, { stringSet = "", stringElements = [] }) =>
        Result.map(stringSetAdd(stringSet, stringElements), stringSet => ({ stringSet })),
});

plugin.addClientComputation({
    id: "string-set-from-array",
    name: "Array to set",
    description: "Create a set from an array",
    group: "Data",
    icon: { icon: "st-array-set", kind: "stroke", strokeFgColor: ICON_BASE, strokeColor: LIME_500 },
    parameters: { stringElements },
    results: { stringSet },

    execute: async (_context, { stringElements }) =>
        Result.map(stringSetAdd(emptySet, stringElements), stringSet => ({ stringSet })),
});

plugin.addClientComputation({
    id: "string-set-remove",
    name: "Remove element from set",
    description: "Remove an element from a set",
    group: "Data",
    icon: { icon: "st-empty-set", kind: "stroke", strokeFgColor: ICON_BASE, strokeColor: LIME_500 },
    parameters: { stringSet, stringElements },
    results: { stringSet },

    execute: async (_context, { stringSet = "", stringElements = [] }) =>
        Result.map(stringSetRemove(stringSet, stringElements), stringSet => ({ stringSet })),
});

plugin.addClientComputation({
    id: "string-set-remove-all",
    name: "Remove all elements",
    description: "Remove all elements from a set",
    group: "Data",
    icon: { icon: "st-empty-set", kind: "stroke", strokeFgColor: ICON_BASE, strokeColor: LIME_500 },
    parameters: { stringSet },
    results: { stringSet },

    execute: async (_context, { stringSet = "" }) =>
        mapStringSet(stringSet, set => {
            set.clear();
            return { stringSet: stringSetToJSON(set) };
        }),
});

export function stringSetContains(stringSet: string, stringElement: string): Result<boolean> {
    return mapStringSet(stringSet, set => set.has(stringElement));
}

plugin.addClientColumn({
    id: "string-set-contains",
    name: "Set contains element",
    description: "Whether set contains an element",
    group: "Data",
    icon: {
        icon: "st-set-contains",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: LIME_500,
    },
    parameters: { stringSet, stringElement },

    result: "boolean",
    execute: async (_context, { stringSet = "", stringElement = "" }) => stringSetContains(stringSet, stringElement),
});

plugin.addClientColumn({
    id: "string-empty-set",
    name: "Empty set",
    description: "An empty set",
    group: "Data",
    icon: { icon: "st-empty-set", kind: "stroke", strokeFgColor: ICON_BASE, strokeColor: LIME_500 },
    parameters: {},
    result: "string",
    execute: async () => Result.Ok(emptySet),
});

export function stringSetSize(stringSet: string): Result<number> {
    return Result.map(jsonToStringSet(stringSet), x => x.size);
}

plugin.addClientColumn({
    id: "string-set-size",
    name: "Set size",
    description: "Number of elements in set",
    group: "Data",
    icon: { icon: "st-set-size", kind: "stroke", strokeFgColor: ICON_BASE, strokeColor: LIME_500 },
    parameters: { stringSet },
    result: "number",
    execute: async (_context, { stringSet = "" }) => stringSetSize(stringSet),
});
