import {
    type ActionComputationModel,
    type GroundValue,
    isLoadingValue,
    isQuery,
    UnboundVal,
} from "@glide/computation-model-types";
import { assert, defined } from "@glideapps/ts-necessities";
import type { InflatedProperty, WireValueGetterGeneric, ContextTableTypes } from "@glide/wire";
import type { ExistingAppDescriptionContext, ActionNodeInScope } from "@glide/function-utils";

import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import { getValueAtPath } from "@glide/computation-model";
import { isSingleRelationType } from "@glide/type-schema";
import { ActionInflationBackend, unboundColumnGetter } from "./action-inflation-backend";
import {
    makeActionOutputColumnName,
    makeTypeForActionNodeOutputGetterFromNodeForKey,
} from "@glide/automations-support";

function getActionNodeOutput(nodeForKey: ReadonlyMap<string, ActionNodeInScope>, nodeKey: string, outputName: string) {
    const nodeInScope = nodeForKey.get(nodeKey);
    if (nodeInScope === undefined) {
        return undefined;
    }

    const output = nodeInScope.outputs.find(o => o.name === outputName);
    if (output === undefined) {
        return undefined;
    }

    return { nodeInScope, output };
}

export class AutomationInflationBackend extends ActionInflationBackend {
    constructor(
        appFacilities: ActionAppFacilities,
        adc: ExistingAppDescriptionContext,
        tables: ContextTableTypes,
        acm: ActionComputationModel,
        forBuilder: boolean,
        private readonly actionNodeForKey: ReadonlyMap<string, ActionNodeInScope>
    ) {
        super(
            appFacilities,
            adc,
            tables,
            acm,
            undefined,
            forBuilder,
            true,
            makeTypeForActionNodeOutputGetterFromNodeForKey(adc, actionNodeForKey),
            "automation"
        );
    }

    public getActionNodesInScope(): ActionNodeInScope[] {
        return Array.from(this.actionNodeForKey.values());
    }

    public makeInflationBackendForTables(tables: ContextTableTypes): AutomationInflationBackend {
        return new AutomationInflationBackend(
            this.appFacilities,
            this.adc,
            tables,
            this.acm,
            this.forBuilder,
            this.actionNodeForKey
        );
    }

    protected getValueGetterForActionNodeOutput(
        nodeKey: string,
        outputName: string,
        withFormat: boolean
    ): InflatedProperty<GroundValue> {
        const maybeOutput = getActionNodeOutput(this.actionNodeForKey, nodeKey, outputName);
        if (maybeOutput === undefined) {
            return unboundColumnGetter;
        }

        const { nodeInScope, output } = maybeOutput;

        const getter: WireValueGetterGeneric<GroundValue> = vp => {
            const scope = vp.activeScopes.find(s => s.scopeID === nodeInScope.scopeID);
            if (scope === undefined) return UnboundVal;
            const row = scope.internalTableKeeper.getRow(scope.rowID);
            assert(row !== undefined, "The scope doesn't have the row");
            assert(!isLoadingValue(row), "The scope row must not be loading");

            const [, path, rootPath] = defined(
                this.getPathForColumn(
                    scope.staticScope.internalTableType,
                    makeActionOutputColumnName(nodeKey, outputName),
                    withFormat
                )
            );
            if (rootPath !== undefined) {
                this.acm.ns.get(rootPath);
            }

            const value = getValueAtPath(this.acm.ns, row, path);
            // Users of the getter know how to deal with queries when they
            // represent tables, but they expect rows to come back as rows, so
            // we have to handle this case here.  Queries for tables are not
            // special-cased because the users might want to modify the
            // queries, for example to change the limit.
            if (isQuery(value) && isSingleRelationType(output.type)) {
                const table = vp.resolveQueryAsTable(value);
                if (table === undefined || isLoadingValue(table)) return table;
                return table.asMutatingArray()[0];
            }
            return value;
        };

        return [getter, output.type, output.displayFormula !== undefined];
    }
}
