import { AddRowActionHandler } from "./add-row";
import { CloseModalHandler } from "./close-modal";
import { CompoundActionHandler } from "./compound-handler";
import { CopyToClipboardWithArgsHandler } from "./copy-to-clipboard";
import { DeleteRowActionHandler } from "./delete-row";
import { DeliverEmailHandler } from "./deliver-email";
import { IncrementActionHandler } from "./increment";
import {
    CopyToClipboardActionHandler,
    OpenLinkActionHandler,
    OpenMapActionHandler,
    PhoneCallActionHandler,
    SendEmailActionHandler,
    ShowShareScreenHandler,
    TextMessageActionHandler,
} from "./legacy";
import { PushFreeScreenActionHandler } from "./link-to-screen";
import { LiveScanLinearBarcodeActionHandler } from "./live-scan-linear-barcode";
import { EnlargeImageActionHandler, UploadImageActionHandler } from "./misc";
import { NavigateToTabHandler } from "./navigate-to-tab";
import { OpenLinkWithArgsHandler } from "./open-link";
import { OpenMapWithArgsHandler } from "./open-map";
import { OpenWebViewActionHandler } from "./open-web-view";
import { PhoneCallWithArgsHandler } from "./phone-call";
import { PlaySoundHandler } from "./play-sound";
import { PopScreenHandler } from "./pop-screen";
import { PushDetailScreenHandler } from "./push-detail-screen";
import { PushEditScreenHandler } from "./push-edit-screen";
import { FormScreenActionHandler } from "./push-form-screen";
import { PushUserProfileScreenActionHandler } from "./push-user-profile-screen";
import { ReloadQueryActionHandler } from "./reload-query";
import { RequestSignatureActionHandler } from "./request-signature";
import { ReshuffleHandler } from "./reshuffle";
import { ScanCodeActionHandler } from "./scan-code";
import { SendEmailWithArgsHandler } from "./send-email";
import { SetColumnsActionHandler } from "./set-columns";
import { ShowShareScreenWithArgsHandler } from "./show-share-screen";
import { ShowToastHandler } from "./show-toast";
import { SignInActionHandler } from "./sign-in";
import { TextMessageWithArgsHandler } from "./text-message";
import { WaitActionHandler, WaitForConditionActionHandler } from "./wait";
import { WebhookActionHandler } from "./webhook";
import { YesCodeActionHandler } from "./yes-code-action";
import { ZapierWithArgsHandler } from "./zapier";
import { registerActionHandler } from ".";
import { ComputationActionHandler } from "./computed-columns";
import { SyntheticColumnKind } from "@glide/formula-specifications";
import { VoiceEntryActionHandler } from "./voice-entry";
import { TriggerAutomationActionHandler } from "./trigger-automation";

let didInit = false;

export function registerActionHandlers(): void {
    if (didInit) return;
    didInit = true;

    // NOTE: The first item here is the "default" action, so don't move it.
    registerActionHandler(new PushDetailScreenHandler());
    registerActionHandler(new PushEditScreenHandler());
    registerActionHandler(new FormScreenActionHandler());
    registerActionHandler(new PushFreeScreenActionHandler());
    registerActionHandler(new CopyToClipboardWithArgsHandler());
    registerActionHandler(new OpenLinkWithArgsHandler());
    registerActionHandler(new OpenMapWithArgsHandler());
    registerActionHandler(new PhoneCallWithArgsHandler());
    registerActionHandler(new TextMessageWithArgsHandler());
    registerActionHandler(new ShowShareScreenWithArgsHandler());
    registerActionHandler(new SendEmailWithArgsHandler());
    registerActionHandler(new AddRowActionHandler());
    registerActionHandler(new SetColumnsActionHandler());
    registerActionHandler(new DeleteRowActionHandler());
    registerActionHandler(new ZapierWithArgsHandler());
    registerActionHandler(new ReshuffleHandler());
    registerActionHandler(new IncrementActionHandler());
    registerActionHandler(new SignInActionHandler());
    registerActionHandler(new PopScreenHandler());
    registerActionHandler(new CloseModalHandler());
    registerActionHandler(new NavigateToTabHandler());
    registerActionHandler(new ShowToastHandler());
    registerActionHandler(new DeliverEmailHandler());
    registerActionHandler(new ScanCodeActionHandler());
    registerActionHandler(new LiveScanLinearBarcodeActionHandler());
    registerActionHandler(new RequestSignatureActionHandler());
    registerActionHandler(new PlaySoundHandler());
    registerActionHandler(new WebhookActionHandler());
    registerActionHandler(new YesCodeActionHandler());
    registerActionHandler(new ReloadQueryActionHandler());
    registerActionHandler(new OpenLinkActionHandler());
    registerActionHandler(new OpenWebViewActionHandler());
    registerActionHandler(new PhoneCallActionHandler());
    registerActionHandler(new TextMessageActionHandler());
    registerActionHandler(new SendEmailActionHandler());
    registerActionHandler(new CopyToClipboardActionHandler());
    registerActionHandler(new OpenMapActionHandler());
    registerActionHandler(new EnlargeImageActionHandler());
    registerActionHandler(new ShowShareScreenHandler());
    registerActionHandler(new UploadImageActionHandler());
    registerActionHandler(new CompoundActionHandler());
    registerActionHandler(new PushUserProfileScreenActionHandler());
    registerActionHandler(new WaitActionHandler());
    registerActionHandler(new WaitForConditionActionHandler());
    registerActionHandler(new VoiceEntryActionHandler());
    registerActionHandler(new TriggerAutomationActionHandler());

    // computed columns

    // registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.FilterReference));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.IfThenElse));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.Lookup));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.SingleValue));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.TextTemplate));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.Math));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.Rollup));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.JoinStrings));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.SplitString));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.MakeArray));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.GenerateImage));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.ConstructURL));
    registerActionHandler(new ComputationActionHandler(SyntheticColumnKind.FilterSortLimit));
}
