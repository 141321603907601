import {
    type ColumnFlags,
    type ComputationError,
    type ComputationErrorAccumulator,
    type ComputationErrorAccumulatorPerTableColumn,
    type PathForColumn,
    type LoadingValue,
    isLoadingValue,
    type KeyPath,
    type Path,
    type RootPath,
    amendPath,
    isRootPath,
    makeKeyPath,
    makePath,
    type AsyncComputation,
    type Computation,
    type Handler,
    type TableAggregateComputation,
    type TableAggregateDataProvider,
    type ConditionValuePath,
    type Query,
    type RelativePath,
    RollupKind,
} from "@glide/computation-model-types";
import {
    asMaybeArrayOfStrings,
    asMaybeArrayOfStringsCoercedString,
    asMaybeBoolean,
    asMaybeDate,
    asMaybeJSONValueForColumnType,
    asMaybeNumber,
    asMaybeString,
    asString,
} from "@glide/common-core/dist/js/computation-model/data";
import type { SpecialValueSpecification } from "@glide/formula-specifications";
import {
    SyntheticColumnKind,
    type ArraySourceSpecification,
    type ColumnOrValueSpecification,
    type ConstructURLSpecification,
    type FilterReferenceSpecification,
    type FilterSortLimitSpecification,
    type GenerateImageSpecification,
    type GeoDistanceSpecification,
    type IfThenElseSpecification,
    type JoinStringsSpecification,
    type LookupSpecification,
    type MakeArraySpecification,
    type MathSpecification,
    type PluginComputationSpecification,
    type PredicateSpecification,
    type RollupSpecification,
    type SingleValuePosition,
    type SingleValueSpecification,
    type SplitStringSpecification,
    type TextTemplateSpecification,
    type YesCodeSpecification,
    ColumnOrValueKind,
    decomposeAll,
    decomposeSortKey,
    SingleValuePositionKind,
    parseMath,
    type QueryValueInflator,
    inflateQueryConditions,
} from "@glide/formula-specifications";
import {
    type TableName,
    rowIndexColumnName,
    type PrimitiveGlideTypeKind,
    type TableAndColumn,
    type TableColumn,
    type TableGlideType,
    isSourceColumn,
    FormulaKind,
    getTableColumn,
    getTableName,
    isComputedColumn,
    isMultiRelationType,
    isPrimitiveArrayType,
    isPrimitiveType,
    isRelationType,
    isSingleRelationType,
    makeSourceColumn,
    SourceColumnKind,
    SpecialValueKind,
    type WithUserEnteredTextFormula,
    GeneratedImageKind,
    isBigTableOrExternal,
    isUniversalTableName,
} from "@glide/type-schema";
import {
    ArrayTransformKind,
    SortOrder,
    getArrayProperty,
    getEnumProperty,
    getJSONPathProperty,
    getNumberProperty,
    getSecretProperty,
    getSourceColumnProperty,
    getSpecialValueProperty,
    getStringProperty,
    getSwitchProperty,
    isPropertyDescription,
} from "@glide/app-description";
import { doesTableSupportReverseSheetOrder } from "@glide/common-core/dist/js/schema-properties";
import { makeParameterSourceColumnType } from "@glide/common-core/dist/js/computation-model/make-parameter-source-column-type";
import { hasMathFormulaRandom, makeQuotaKeyForFormula } from "@glide/generator/dist/js/formulas/compiler";
import { doesColumnSupportRollups, isQueryableColumn } from "@glide/generator/dist/js/computed-columns";
import type { NameAndValueDescription } from "@glide/generator/dist/js/actions/kvp-in-out-action";
import {
    type JSONObject,
    type TextTemplateToken,
    isArray,
    isEmptyOrUndefined,
    logError,
    tokenizeTemplateString,
} from "@glide/support";
import {
    assert,
    assertNever,
    defined,
    filterUndefined,
    mapFilterUndefined,
    DefaultMap,
} from "@glideapps/ts-necessities";
import { iterableEnumerate, mapMap } from "collection-utils";
import {
    type FilterConditions,
    AllTrueComputation,
    AverageComputation,
    CountNonEmptyComputation,
    CountNotTrueComputation,
    CountTrueComputation,
    CountUniqueComputation,
    EarliestComputation,
    ExtractNthItemComputation,
    ExtractRandomItemComputation,
    FilterSortLimitComputation,
    FirstOrLast,
    JoinStringsComputation,
    LatestComputation,
    MaximumComputation,
    MinimumComputation,
    MultiPrimitiveLookupComputation,
    MultiRelationLookupComputation,
    RangeComputation,
    SingleRelationComputation,
    SomeTrueComputation,
    SumComputation,
} from "./aggregates";
import {
    type PluginParameterSetter,
    type PluginParameterSource,
    GeoDistanceAsyncComputation,
    PluginComputationComputation,
    YesCodeComputation,
} from "./async-computations";
import type {
    BuildResult,
    ColumnBuilderHelper,
    MutableColumnFlags,
    SourceColumnResult,
    TableAndColumnInfo,
} from "./column-builder-types";
import {
    defaultColumnFlags,
    isBuildResult,
    isOldStyleRelation,
    isSheetArrayType,
    makeBuildResultFaulty,
    makeBuildResultFaultyFromQuery,
    makeBuildResultMissing,
    prettyPrintTableAndColumn,
    throwBuildResult,
    tryCatchBuildResult,
    updateColumnFlags,
} from "./column-builder-utils";
import {
    type IfThenElseClause,
    ConstructURLComputation,
    DynamicTextTemplateComputation,
    ForwardingKind,
    GenerateImageComputation,
    GlobalGuardedForwardComputation,
    IfThenElseComputation,
    MakeArrayComputation,
    MathComputation,
    QueryRelationComputation,
    SingleLookupComputation,
    SplitStringComputation,
    StaticTextTemplateComputation,
} from "./computations";
import type { Condition, PathOrGroundValue } from "./conditions";
import {
    AsyncComputationComputedColumnHandler,
    AsyncComputationHandler,
    CombineHandler,
    ComputationComputedColumnHandler,
    ComputationHandler,
    localQueryableRollupLimit,
    makeTableAggregateHandler,
    MultiRelationToColumnComputedColumnHandler,
    MultiRelationToColumnHandler,
    MultiRelationToGlobalComputedColumnHandler,
    MultiRelationToGlobalHandler,
    ResolveQueryHandler,
    ResumableTableAggregateHandler,
    SingleRelationToColumnComputedColumnHandler,
    SingleRelationToGlobalComputedColumnHandler,
    TableAggregateComputedColumnHandler,
    ThunkifyColumnHandler,
} from "./handlers";
import { prepareCondition } from "./prepare-condition";
import { GlideDateTime, GlideJSON } from "@glide/data-types";
import { getValueAt } from "./getters";
import { getRootPathForPathForColumn } from "./support";

function isRelationKeyArray(column: TableColumn): boolean {
    return column.type.kind === "array" || isOldStyleRelation(column) !== undefined;
}

const rowIndexPath = makeKeyPath(rowIndexColumnName);

interface RollupResult {
    readonly handler: Handler;
    // The name of the computed column the `handler` produces, for the
    // case where we're doing an aggregate query, which require a
    // follow-up handler.  `undefined` means that this isn't a query.
    // `true` means it is a query, but it's global, so there's no
    // column.
    readonly queryColumnName: string | true | undefined;
}

const createComputationErrorAccumulator = (): ComputationErrorAccumulator => ({
    byRowID: new Map<string, ComputationError>(),
    global: undefined,
});

export const createComputationErrorAccumulatorPerTableColumn = (): ComputationErrorAccumulatorPerTableColumn =>
    new DefaultMap<TableAndColumn, ComputationErrorAccumulator>(createComputationErrorAccumulator);

interface MakeRollupOptions {
    readonly withFormat: boolean;
    // If `true`, the computation operates on rows, in which case
    // `valueColumn` must be `undefined`.  Otherwise it operates on
    // primitives.  Most rollups, such as "sum", operate on
    // primitives, but some rollup-ish computation, such as the Query
    // column, operate on full rows.
    readonly fullRow: boolean;
    readonly canHaveCustomOrder: boolean;
    // Is the rollup supported via aggregate queries?  Lookups are not (yet).
    readonly supportsQuery: boolean;
}

export type ColumnBuildSuccess = PathForColumn & { readonly warningMessage: string | undefined };

interface Options {
    readonly gbtComputedColumnsAlpha: boolean;
    readonly gbtDeepLookups: boolean;
    readonly throwComputationErrors: boolean;
}

export class ColumnBuilder {
    private isGlobal = false;
    private columnFlags: MutableColumnFlags;
    private warningMessage: string | undefined;

    constructor(
        // `tac` is the table and column we're building the handler(s) for
        private readonly tac: TableAndColumn,
        private readonly columnNameOverride: string | undefined,
        private readonly helper: ColumnBuilderHelper,
        private readonly errorAccumulators: ComputationErrorAccumulatorPerTableColumn,
        private readonly opts: Options
    ) {
        this.columnFlags = { ...defaultColumnFlags, fromQuery: isBigTableOrExternal(this.tac.table) };
    }

    private get columnName(): string {
        return this.columnNameOverride ?? this.tac.column.name;
    }

    private get tableName(): TableName {
        return getTableName(this.tac.table);
    }

    private updateFlags(...incoming: ColumnFlags[]) {
        updateColumnFlags(this.columnFlags, ...incoming);
    }

    // `tablePath !== undefined` means uses default context
    private makeComputationHandlerAndSetIsGlobal(
        computation: Computation,
        tablePaths: RootPath | readonly RootPath[] | undefined,
        computedColumnName: string = this.columnName
    ): Handler {
        if (tablePaths === undefined || (isArray(tablePaths) && tablePaths.length === 0)) {
            this.isGlobal = true;
            return new ComputationHandler(computation);
        } else {
            return new ComputationComputedColumnHandler(
                this.helper.combineTablePaths(isArray(tablePaths) ? tablePaths : [tablePaths], true),
                computedColumnName,
                computation,
                this.columnFlags.usesThunks
            );
        }
    }

    private makeAsyncComputationHandlerAndSetIsGlobal(
        computation: AsyncComputation,
        tablePaths: readonly RootPath[]
    ): Handler {
        this.columnFlags.usesThunks = true;
        if (tablePaths.length === 0) {
            this.isGlobal = true;
            return new AsyncComputationHandler(computation, (error: ComputationError | undefined) => {
                this.errorAccumulators.get(this.tac).global = error;
            });
        } else {
            return new AsyncComputationComputedColumnHandler(
                this.helper.combineTablePaths(tablePaths, true),
                this.columnName,
                computation,
                (rowID: string, error: ComputationError | undefined) => {
                    if (error === undefined) {
                        this.errorAccumulators.get(this.tac).byRowID.delete(rowID);
                    } else {
                        this.errorAccumulators.get(this.tac).byRowID.set(rowID, error);
                    }
                }
            );
        }
    }

    private finishWithHandler(handler: Handler, n?: string): ColumnBuildSuccess {
        const newPath = this.helper.addEntity(prettyPrintTableAndColumn(this.tac), handler, true);
        if (this.isGlobal) {
            assert(n === undefined);
        }
        if (this.isGlobal) {
            return {
                isGlobal: true,
                valuePath: newPath,
                tablePath: undefined,
                canBeDeleted: true,
                ...this.columnFlags,
                usesThunks: false,
                warningMessage: this.warningMessage,
            };
        } else {
            const name = n ?? this.columnName;
            return {
                isGlobal: false,
                tablePath: newPath,
                valuePath: makeKeyPath(name),
                tableColumnPath: amendPath(newPath, { c: name }),
                warningMessage: this.warningMessage,
                ...this.columnFlags,
            };
        }
    }

    private makeTableAggregateComputedColumnHandler(
        tableOrArrayInfo: TableAndColumnInfo,
        additionalTablePath: RootPath | undefined,
        aggregatedTablePath: RootPath | undefined,
        computation: TableAggregateComputation<unknown>,
        canHaveCustomOrder: boolean,
        columnNameOverrideOverride: string | undefined,
        aggregateLocally: boolean
    ): Handler {
        let contextTablePath: RootPath;
        if (tableOrArrayInfo.isGlobal) {
            if (additionalTablePath !== undefined) {
                contextTablePath = additionalTablePath;
            } else {
                contextTablePath = defined(this.helper.tableBasePaths.get(this.tableName));
            }
        } else {
            if (additionalTablePath === undefined) {
                contextTablePath = tableOrArrayInfo.tablePath;
            } else {
                contextTablePath = this.helper.combineTablePaths(
                    [tableOrArrayInfo.tablePath, additionalTablePath],
                    true
                );
            }
        }

        this.updateFlags({ ...tableOrArrayInfo, usesThunks: true });
        if (!canHaveCustomOrder) {
            // Most aggregates never have custom order, or rather they can't
            // because they don't produce relations.
            this.columnFlags.hasCustomOrder = false;
        }

        return new TableAggregateComputedColumnHandler(
            contextTablePath,
            tableOrArrayInfo.valuePath,
            aggregatedTablePath,
            computation,
            columnNameOverrideOverride ?? this.columnName,
            undefined,
            aggregateLocally
        );
    }

    private makeArrayRollup(
        arrayInfo: TableAndColumnInfo,
        // See comment below in `makeRollup`
        additionalTablePath: RootPath | undefined,
        computation: TableAggregateComputation<unknown>,
        canBeGlobal: boolean,
        canHaveCustomOrder: boolean
    ): Handler | BuildResult {
        const arrayType = arrayInfo.column.type;
        if (arrayType.kind !== "array" || !isPrimitiveType(arrayType.items)) {
            return makeBuildResultFaulty("Rollup target is not a primitive array");
        }

        if (canBeGlobal && arrayInfo.isGlobal && additionalTablePath === undefined) {
            this.isGlobal = true;
            return makeTableAggregateHandler(arrayInfo.valuePath, computation, undefined, false);
        } else {
            return this.makeTableAggregateComputedColumnHandler(
                arrayInfo,
                additionalTablePath,
                undefined,
                computation,
                canHaveCustomOrder,
                undefined,
                false
            );
        }
    }

    private makeLookup(relationInfo: TableAndColumnInfo, columnInRelationInfo: TableAndColumnInfo): Handler {
        if (columnInRelationInfo.isGlobal) {
            return this.makeGuardedForwardHandler(
                columnInRelationInfo.valuePath,
                relationInfo,
                isRelationType(this.tac.column.type) ? ForwardingKind.LookupRow : ForwardingKind.LookupPrimitive
            );
        }

        if (relationInfo.isGlobal) {
            this.isGlobal = true;
            return new ComputationHandler(
                new SingleLookupComputation(
                    relationInfo.valuePath,
                    columnInRelationInfo.valuePath,
                    columnInRelationInfo.tablePath
                )
            );
        } else {
            return new ComputationComputedColumnHandler(
                relationInfo.tablePath,
                this.columnName,
                new SingleLookupComputation(
                    relationInfo.valuePath,
                    columnInRelationInfo.valuePath,
                    columnInRelationInfo.tablePath
                ),
                this.columnFlags.usesThunks
            );
        }
    }

    private makeSingleValueFromArray(
        arrayInfo: TableAndColumnInfo,
        position: SingleValuePosition
    ): Handler | BuildResult {
        let additionalTablePath: RootPath | undefined;
        let canBeGlobal = true;

        let computation: TableAggregateComputation<unknown>;
        switch (position.kind) {
            case SingleValuePositionKind.First:
            case SingleValuePositionKind.Last:
                computation = new ExtractNthItemComputation(
                    undefined,
                    position.kind === SingleValuePositionKind.First ? FirstOrLast.First : FirstOrLast.Last,
                    this.helper.makePathForConstant(0)
                );
                break;
            case SingleValuePositionKind.FromStart:
            case SingleValuePositionKind.FromEnd:
                const positionPaths = this.helper.getPathsForSingleValueOffset(this.tac.table, position.offset);
                if (isBuildResult(positionPaths)) return positionPaths;
                computation = new ExtractNthItemComputation(
                    undefined,
                    position.kind === SingleValuePositionKind.FromStart ? FirstOrLast.First : FirstOrLast.Last,
                    positionPaths.valuePath
                );
                if (positionPaths.tablePath !== undefined) {
                    additionalTablePath = positionPaths.tablePath;
                }
                break;
            case SingleValuePositionKind.Random:
                computation = new ExtractRandomItemComputation(this.helper.getRandomOrderSerialPath());
                canBeGlobal = false;
                break;
            default:
                return assertNever(position);
        }

        return this.makeArrayRollup(arrayInfo, additionalTablePath, computation, canBeGlobal, false);
    }

    private makeRollup(
        arraySource: ArraySourceSpecification,
        {
            withFormat = false,
            fullRow = false,
            canHaveCustomOrder = false,
            supportsQuery = true,
        }: Partial<MakeRollupOptions>,
        makeComputation: (
            valuePath: Path | undefined,
            columnName: string | undefined,
            targetTable: TableGlideType | undefined
        ) =>
            | [
                  computation: TableAggregateComputation<unknown>,
                  // If the rollup computation requires additional input
                  // from the context table, `contextTablePath` is the
                  // path to it.  The only case where we currently need it
                  // is for the separator in join. If the relation itself
                  // is global, but the separator is in the context table,
                  // the result is still non-global.  We must also combine
                  // with this table.
                  contextTablePath: RootPath | undefined
              ]
            | BuildResult
    ): RollupResult | BuildResult {
        const { tableOrRelationColumn, valueColumn } = arraySource;

        if (fullRow) {
            assert(valueColumn === undefined);
        }

        const rollupPrimitiveArray = (arrayInfo: TableAndColumnInfo): RollupResult | BuildResult => {
            if (fullRow) {
                return makeBuildResultFaulty("Aggregate target is not a relation");
            }

            const maybeComputation = makeComputation(undefined, undefined, undefined);
            if (isBuildResult(maybeComputation)) return maybeComputation;
            const [computation, contextTablePath] = maybeComputation;

            const arrayRollup = this.makeArrayRollup(
                arrayInfo,
                contextTablePath,
                computation,
                true,
                canHaveCustomOrder
            );
            if (isBuildResult(arrayRollup)) return arrayRollup;

            return { handler: arrayRollup, queryColumnName: undefined };
        };

        let targetTable: TableGlideType | undefined;
        if (typeof tableOrRelationColumn === "string") {
            // FIXME: can't we handle this as a source column below and remove
            // this whole case?

            const relationColumn = getTableColumn(this.tac.table, tableOrRelationColumn);
            if (relationColumn === undefined) {
                return makeBuildResultFaulty("Rollup target column not found");
            }

            if (isPrimitiveArrayType(relationColumn.type)) {
                const arrayInfo = this.helper.lookupTableAndColumn(this.tac.table, tableOrRelationColumn, {
                    withFormat,
                });
                if (isBuildResult(arrayInfo)) return arrayInfo;

                return rollupPrimitiveArray(arrayInfo);
            }

            if (!isMultiRelationType(relationColumn.type)) {
                return makeBuildResultFaulty("Rollup target is not a multi-relation");
            }

            targetTable = this.helper.inspector.findTable(relationColumn.type.items);
        } else if (isSourceColumn(tableOrRelationColumn)) {
            const result = this.helper.makePathForSourceColumn(this.tac.table, tableOrRelationColumn, false, undefined);
            if (isBuildResult(result)) return result;

            const relationColumn = result.column;

            if (isPrimitiveArrayType(relationColumn.type)) {
                const arrayInfo = this.helper.makePathForSourceColumn(
                    this.tac.table,
                    tableOrRelationColumn,
                    withFormat,
                    undefined
                );
                if (isBuildResult(arrayInfo)) return arrayInfo;

                return rollupPrimitiveArray(arrayInfo);
            }

            if (!isMultiRelationType(relationColumn.type)) {
                return makeBuildResultFaulty("Rollup target is not a multi-relation");
            }

            targetTable = this.helper.inspector.findTable(relationColumn.type.items);
        } else {
            targetTable = this.helper.inspector.findTable(tableOrRelationColumn);
        }
        if (targetTable === undefined) {
            return makeBuildResultFaulty("Rollup target table not found");
        }

        if (valueColumn === undefined) {
            // We've handled the only other case where `valueColumn` can
            // be `undefined` above, with `makeArrayRollup`.  This can
            // still happen with a misconfigured schema, though.
            // https://github.com/quicktype/glide/issues/20012
            if (!fullRow) {
                return makeBuildResultFaulty("A column has to be specified");
            }
        }

        let valueInfo: TableAndColumnInfo | undefined;
        let queryColumnName: string | true | undefined;
        let computeLocally = false;
        if (valueColumn !== undefined) {
            const maybeValueInfo = this.helper.lookupTableAndColumn(targetTable, valueColumn, { withFormat });
            if (isBuildResult(maybeValueInfo)) return maybeValueInfo;
            valueInfo = maybeValueInfo;

            if (valueInfo.makesQuery && !isBigTableOrExternal(valueInfo.table)) {
                // This some column in a non-queryable table that makes a
                // query, most likely a Rollup.  We don't support rolling up
                // over those.  The case where `valueInfo.table` is not
                // queryable is handled below by `doesColumnSupportRollups`.
                return makeBuildResultFaultyFromQuery();
            }

            if (valueInfo.fromQuery) {
                if (
                    supportsQuery &&
                    doesColumnSupportRollups(
                        this.helper.inspector,
                        valueInfo.table,
                        valueInfo.column,
                        this.opts.gbtComputedColumnsAlpha,
                        this.opts.gbtDeepLookups
                    )
                ) {
                    queryColumnName = this.helper.makeRandomID();
                } else if (
                    this.opts.gbtComputedColumnsAlpha &&
                    // This disallows rollups over rollups
                    !valueInfo.makesQuery
                ) {
                    computeLocally = true;
                    this.warningMessage = `This rollup is limited to ${localQueryableRollupLimit} rows and will give incorrect results if there are more rows than that.`;
                } else {
                    return makeBuildResultFaultyFromQuery();
                }
                // We always use thunks for non-global rollups from
                // queries.
                this.columnFlags.usesThunks ||= !valueInfo.isGlobal;
                this.columnFlags.makesQuery = true;
            }
            this.updateFlags(valueInfo);
        }

        let valuePath: Path | undefined;
        let tablePath: RootPath;
        if (valueInfo === undefined) {
            valuePath = undefined;
            tablePath = defined(this.helper.tableBasePaths.get(getTableName(targetTable)));
        } else if (valueInfo.isGlobal === true) {
            valuePath = valueInfo.valuePath;
            tablePath = defined(this.helper.tableBasePaths.get(getTableName(targetTable)));
        } else {
            valuePath = valueInfo.valuePath;
            tablePath = valueInfo.tablePath;
        }

        const maybeComputation = makeComputation(valuePath, valueInfo?.column.name, targetTable);
        if (isBuildResult(maybeComputation)) return maybeComputation;
        const [computation, contextTablePath] = maybeComputation;

        const makeRollupFromGlobalPath = (rootPath: RootPath): RollupResult | BuildResult => {
            let aggregatedTablePath: RootPath;
            if (valueInfo === undefined || valueInfo.isGlobal) {
                aggregatedTablePath = rootPath;
            } else {
                aggregatedTablePath = this.helper.addEntity(
                    `combine for rollup ${prettyPrintTableAndColumn(this.tac)}`,
                    new CombineHandler(rootPath, [
                        {
                            kind: "pass-through",
                            path: rootPath,
                        },
                        {
                            kind: "full-table",
                            tablePath: valueInfo.tablePath,
                        },
                    ]),
                    true
                );
            }
            this.isGlobal = true;
            if (queryColumnName !== undefined) {
                queryColumnName = true;
            }
            return {
                handler: makeTableAggregateHandler(aggregatedTablePath, computation, undefined, computeLocally),
                queryColumnName,
            };
        };

        if (typeof tableOrRelationColumn === "string") {
            const tableInfo = this.helper.lookupTableAndColumn(this.tac.table, tableOrRelationColumn, {
                withFormat: false,
            });
            if (isBuildResult(tableInfo)) return tableInfo;
            this.updateFlags(tableInfo);

            if (tableInfo.isGlobal && contextTablePath === undefined) {
                return makeRollupFromGlobalPath(tableInfo.valuePath);
            } else {
                assert(queryColumnName !== true);
                return {
                    handler: this.makeTableAggregateComputedColumnHandler(
                        tableInfo,
                        contextTablePath,
                        tablePath,
                        computation,
                        canHaveCustomOrder,
                        queryColumnName,
                        computeLocally
                    ),
                    queryColumnName,
                };
            }
        } else if (isSourceColumn(tableOrRelationColumn)) {
            const result = this.helper.makePathForSourceColumn(this.tac.table, tableOrRelationColumn, false, undefined);
            if (isBuildResult(result)) return result;

            this.updateFlags(result);

            assert(isRootPath(result.valuePath) && result.tablePath === undefined);
            return makeRollupFromGlobalPath(result.valuePath);
        } else {
            let tableNameForQuery: TableName | undefined;
            if (isBigTableOrExternal(targetTable)) {
                tableNameForQuery = getTableName(targetTable);
                this.columnFlags.fromQuery = true;
            }
            if (contextTablePath === undefined) {
                this.isGlobal = true;
                if (queryColumnName !== undefined) {
                    queryColumnName = true;
                }
                const aggregateHandler = new ResumableTableAggregateHandler(
                    tablePath,
                    computation,
                    tableNameForQuery,
                    !this.columnFlags.fromQuery && valueInfo?.usesThunks === true,
                    computeLocally
                );
                return { handler: aggregateHandler, queryColumnName };
            } else {
                this.columnFlags.usesThunks = true;
                assert(queryColumnName !== true);
                return {
                    handler: new TableAggregateComputedColumnHandler(
                        contextTablePath,
                        tablePath,
                        tablePath,
                        computation,
                        queryColumnName ?? this.columnName,
                        tableNameForQuery,
                        computeLocally
                    ),
                    queryColumnName,
                };
            }
        }
    }

    private finishWithRollupResult(rollupResult: RollupResult) {
        let handler: Handler;
        const { handler: rollupHandler, queryColumnName } = rollupResult;
        if (queryColumnName !== undefined) {
            const pretty = prettyPrintTableAndColumn(this.tac);
            const queryPath = this.helper.addEntity(`query(${pretty})`, rollupHandler, true);
            if (this.isGlobal) {
                assert(queryColumnName === true);
                handler = new ResolveQueryHandler(queryPath);
            } else {
                assert(queryColumnName !== true);
                handler = new ThunkifyColumnHandler(amendPath(queryPath, { c: queryColumnName }), this.columnName);
            }
        } else {
            handler = rollupHandler;
        }
        return this.finishWithHandler(handler);
    }

    private makeGuardedForwardHandler(
        forwardee: RootPath,
        relationInfo: TableAndColumnInfo,
        kind: ForwardingKind
    ): Handler {
        // In this case we're getting a global value, but we're
        // getting it through a relation, and that relation can be
        // empty, in which case the result would also be empty.
        const computation = new GlobalGuardedForwardComputation(forwardee, relationInfo.valuePath, kind);
        if (relationInfo.isGlobal) {
            this.isGlobal = true;
            return new ComputationHandler(computation);
        } else {
            return new ComputationComputedColumnHandler(
                relationInfo.tablePath,
                this.columnName,
                computation,
                this.columnFlags.usesThunks
            );
        }
    }

    private makeGuardedForward(
        forwardee: RootPath,
        relationInfo: TableAndColumnInfo,
        kind: ForwardingKind
    ): ColumnBuildSuccess {
        const handler = this.makeGuardedForwardHandler(forwardee, relationInfo, kind);
        return this.finishWithHandler(handler);
    }

    private makeCondition(
        predicate: PredicateSpecification,
        contextTable: TableGlideType,
        hostTable: TableGlideType | undefined,
        allowQueriesAndComputedInContextTable: boolean
    ) {
        const contextTablePaths: RootPath[] = [];
        const hostTablePaths: RootPath[] = [];

        return tryCatchBuildResult(() => {
            const condition = prepareCondition<ConditionValuePath>(predicate, {
                makePathForSourceColumn: sc => {
                    const maybePath = this.helper.makePathForSourceColumn(contextTable, sc, false, hostTable);
                    if (isBuildResult(maybePath)) return throwBuildResult(maybePath);

                    // `allowQueriesAndComputedInContextTable` disallows two
                    // types of columns in the context row:
                    // 1. If the context table is a queryable table, we don't
                    //    allow computed columns, because they can't be
                    //    queried.
                    // 2. Even if the context table is not queryable, we don't
                    //    allow columns that "make a query", because that
                    //    would mean we'd have to potentially make thousands
                    //    of queries to find all matching rows in the context
                    //    table.
                    const isInContextRow = !maybePath.inHostRow && !isRootPath(maybePath.valuePath);
                    if (!allowQueriesAndComputedInContextTable && isInContextRow) {
                        const isContextQueryable = isBigTableOrExternal(contextTable);
                        const isQuery = !isContextQueryable && maybePath.makesQuery;
                        const isDisallowedComputed =
                            isContextQueryable &&
                            !this.canFilterByColumnInQueryableTable(contextTable, maybePath.column);
                        if (isQuery || isDisallowedComputed) {
                            throwBuildResult(makeBuildResultFaultyFromQuery());
                        }
                    }

                    if (maybePath.tablePath !== undefined) {
                        (maybePath.inHostRow ? hostTablePaths : contextTablePaths).push(maybePath.tablePath);
                    }
                    this.updateFlags(maybePath);

                    return { path: maybePath.valuePath, inHostRow: maybePath.inHostRow };
                },
                getVerifiedEmailAddressPath: () => ({
                    path: this.helper.getVerifiedEmailAddressPath(),
                    inHostRow: false,
                }),
                getTimestampPath: () => ({ path: this.helper.getTimestampPath(), inHostRow: false }),
                getStartOrEndOfTodayPath: startOrEnd => ({
                    path: this.helper.getStartOrEndOfTodayPath(startOrEnd),
                    inHostRow: false,
                }),
                makePathForSpecialValue: (sv: SpecialValueSpecification) => {
                    const maybePath = this.helper.makePathForSpecialValue(this.tac, sv.specialValue);
                    if (isBuildResult(maybePath)) return throwBuildResult(maybePath);
                    return {
                        path: maybePath.valuePath,
                        inHostRow: false,
                    };
                },
            });
            return [condition, contextTablePaths, hostTablePaths] as const;
        });
    }

    // Returns `undefined` if it's a regular computed column.
    public buildIrregularColumn(): ColumnBuildSuccess | BuildResult | undefined {
        const itemColumnNames = isSheetArrayType(this.tac.column.type);
        // Lookup and single-value columns take their types from the columns
        // they look up, so if those columns have item column names set, the
        // computed columns' types will, too, but we must handle them like
        // regular computed columns.
        if (!isComputedColumn(this.tac.column) && itemColumnNames !== undefined) {
            const tablePaths: RootPath[] = [];
            let buildResult: BuildResult | undefined;
            const paths = mapFilterUndefined(itemColumnNames, c => {
                const p = this.helper.makePathForSourceColumn(this.tac.table, makeSourceColumn(c), false, undefined);
                if (isBuildResult(p)) {
                    buildResult = p;
                    return undefined;
                }
                if (p.tablePath !== undefined) {
                    tablePaths.push(p.tablePath);
                }
                this.columnFlags.usesThunks ||= p.usesThunks;
                return p.valuePath;
            });
            if (buildResult !== undefined) return buildResult;

            // We will get updates from Google Sheets via Firestore that
            // include the "array ref" object for this column.  We don't want
            // to compete with those updates, and for simplicity we'd also
            // rather not filter them out, so we're giving this computed
            // column a different name.  Previously we didn't do this, which
            // would lead to bugs under some circumstances:
            // https://github.com/quicktype/glide/issues/18328
            const name = this.columnNameOverride ?? this.helper.makeRandomID();

            const handler = this.makeComputationHandlerAndSetIsGlobal(
                new MakeArrayComputation(paths),
                tablePaths,
                name
            );
            // This should never be global, but we're being careful.
            return this.finishWithHandler(handler, this.isGlobal ? undefined : name);
        }

        const oldStyleRelation = isOldStyleRelation(this.tac.column);
        if (oldStyleRelation !== undefined) {
            const targetInfo = this.helper.lookupTableAndColumn(
                oldStyleRelation.tableName,
                oldStyleRelation.columnName,
                { oldStyleRelation: true }
            );
            if (isBuildResult(targetInfo)) return targetInfo;

            // Old-style relations can only refer to sheet columns which can't
            // be global.
            if (targetInfo.isGlobal) return makeBuildResultFaulty("Internal error with old-style relations");

            const hostTableColumnPath = amendPath(defined(this.helper.tableBasePaths.get(this.tableName)), {
                c: this.tac.column.name,
            });
            const name = this.columnNameOverride ?? this.helper.makeRandomID();

            let handler: Handler;
            if (oldStyleRelation.isMultiple) {
                handler = new MultiRelationToColumnComputedColumnHandler(
                    hostTableColumnPath,
                    targetInfo.tableColumnPath,
                    name,
                    rowIndexPath,
                    true,
                    targetInfo.column.type.kind === "array"
                );
                this.columnFlags.usesThunks = true;
            } else {
                handler = new SingleRelationToColumnComputedColumnHandler(
                    hostTableColumnPath,
                    targetInfo.tableColumnPath,
                    name,
                    true,
                    isRelationKeyArray(targetInfo.column)
                );
                this.columnFlags.usesThunks = true;
            }

            return this.finishWithHandler(handler, name);
        }

        return undefined;
    }

    private canFilterByColumnInQueryableTable(table: TableGlideType, column: TableColumn): boolean {
        const canUse = this.opts.gbtComputedColumnsAlpha
            ? isQueryableColumn(this.helper.inspector, table, column, true, this.opts.gbtDeepLookups)
            : !isComputedColumn(column);
        return canUse;
    }

    private buildFilterReference(spec: FilterReferenceSpecification): ColumnBuildSuccess | BuildResult {
        const hostInfo = this.helper.lookupTableAndColumn(this.tac.table, spec.hostColumn);
        if (isBuildResult(hostInfo)) return hostInfo;
        this.updateFlags(hostInfo);
        const { column: hostColumn } = hostInfo;

        if (!isPrimitiveType(hostColumn.type) && !isPrimitiveArrayType(hostColumn.type)) {
            return makeBuildResultFaulty("Relation host column type is not supported");
        }

        const targetInfo = this.helper.lookupTableAndColumn(spec.targetTable, spec.targetColumn);
        if (isBuildResult(targetInfo)) return targetInfo;
        this.updateFlags(targetInfo);
        const { column: targetColumn } = targetInfo;

        if (!isPrimitiveType(targetColumn.type) && !isPrimitiveArrayType(targetColumn.type)) {
            return makeBuildResultFaulty("Relation target column type is not supported");
        }

        const sourceKeyIsArray = isRelationKeyArray(hostInfo.column);
        const targetKeyIsArray = isRelationKeyArray(targetColumn);

        const targetTableName = getTableName(targetInfo.table);
        if (isBigTableOrExternal(targetInfo.table)) {
            if (!this.canFilterByColumnInQueryableTable(targetInfo.table, targetColumn)) {
                return makeBuildResultFaultyFromQuery("Cannot use computed columns in relations to queryable tables");
            }

            this.updateFlags({
                usesThunks: true,
                makesQuery: true,
                fromQuery: true,
                isSlow: false,
                hasCustomOrder: false,
            });

            const computation = new QueryRelationComputation(
                hostInfo.valuePath,
                targetTableName,
                targetColumn.name,
                spec.multiple
            );
            const handler = this.makeComputationHandlerAndSetIsGlobal(
                computation,
                hostInfo.isGlobal ? undefined : hostInfo.tablePath
            );
            return this.finishWithHandler(handler);
        }

        const sortKey: RelativePath | undefined = spec.omitSort === true ? undefined : rowIndexPath;

        let handler: Handler;
        if (targetInfo.isGlobal) {
            const { valuePath: targetPath, table: targetTable } = targetInfo;
            const targetTablePath = defined(this.helper.tableBasePaths.get(getTableName(targetTable)));
            if (spec.multiple) {
                if (hostInfo.isGlobal) {
                    handler = new MultiRelationToGlobalHandler(
                        hostInfo.valuePath,
                        targetTablePath,
                        targetPath,
                        sortKey,
                        sourceKeyIsArray,
                        targetKeyIsArray
                    );
                    this.isGlobal = true;
                } else {
                    handler = new MultiRelationToGlobalComputedColumnHandler(
                        hostInfo.tableColumnPath,
                        targetTablePath,
                        targetPath,
                        this.columnName,
                        sortKey,
                        sourceKeyIsArray,
                        targetKeyIsArray
                    );
                    this.columnFlags.usesThunks = true;
                }
            } else {
                if (hostInfo.isGlobal) {
                    handler = makeTableAggregateHandler(
                        targetTablePath,
                        new SingleRelationComputation(
                            hostInfo.valuePath,
                            targetPath,
                            makePath(rowIndexColumnName),
                            sourceKeyIsArray,
                            targetKeyIsArray
                        ),
                        undefined,
                        false
                    );
                    this.isGlobal = true;
                } else {
                    handler = new SingleRelationToGlobalComputedColumnHandler(
                        hostInfo.tableColumnPath,
                        targetTablePath,
                        targetPath,
                        this.columnName,
                        sourceKeyIsArray,
                        targetKeyIsArray
                    );
                    this.columnFlags.usesThunks = true;
                }
            }
        } else {
            const {
                tablePath: targetTablePath,
                valuePath: targetColumnPath,
                tableColumnPath: targetTableColumnPath,
            } = targetInfo;

            if (spec.multiple) {
                if (hostInfo.isGlobal) {
                    handler = new MultiRelationToColumnHandler(
                        hostInfo.valuePath,
                        targetTableColumnPath,
                        sortKey,
                        sourceKeyIsArray,
                        targetKeyIsArray
                    );
                    this.isGlobal = true;
                } else {
                    handler = new MultiRelationToColumnComputedColumnHandler(
                        hostInfo.tableColumnPath,
                        targetTableColumnPath,
                        this.columnName,
                        sortKey,
                        sourceKeyIsArray,
                        targetKeyIsArray
                    );
                    this.columnFlags.usesThunks = true;
                }
            } else {
                if (hostInfo.isGlobal) {
                    handler = makeTableAggregateHandler(
                        targetTablePath,
                        new SingleRelationComputation(
                            hostInfo.valuePath,
                            targetColumnPath,
                            makePath(rowIndexColumnName),
                            sourceKeyIsArray,
                            targetKeyIsArray
                        ),
                        undefined,
                        false
                    );
                    this.isGlobal = true;
                } else {
                    handler = new SingleRelationToColumnComputedColumnHandler(
                        hostInfo.tableColumnPath,
                        targetTableColumnPath,
                        this.columnName,
                        sourceKeyIsArray,
                        targetKeyIsArray
                    );
                    this.columnFlags.usesThunks = true;
                }
            }
        }
        return this.finishWithHandler(handler);
    }

    private buildIfThenElse(spec: IfThenElseSpecification): ColumnBuildSuccess | BuildResult {
        const clauses: IfThenElseClause[] = [];
        const tablePaths: RootPath[] = [];
        const convertColumnOrValueSpecification = (
            cov: ColumnOrValueSpecification<string | number>,
            hostTable: TableGlideType | undefined
        ): PathOrGroundValue<ConditionValuePath> | BuildResult => {
            if (cov.kind === ColumnOrValueKind.Constant) {
                return { isPath: false, value: cov.value };
            } else if (cov.kind === ColumnOrValueKind.Empty) {
                return { isPath: false, value: undefined };
            } else {
                const result = this.helper.makePathForColumnOrValue(this.tac, cov, false, hostTable);
                if (isBuildResult(result)) return result;
                // We only allow primitive values as results of if-then-else
                if (result.column !== undefined && !isPrimitiveType(result.column.type)) {
                    return makeBuildResultFaulty("If-then-else only supports basic column types");
                }
                if (result.tablePath !== undefined) {
                    tablePaths.push(result.tablePath);
                }
                this.updateFlags(result);
                return { isPath: true, path: { path: result.valuePath, inHostRow: false } };
            }
        };

        for (const c of spec.clauses) {
            const maybeCondition = this.makeCondition(c.condition, this.tac.table, undefined, true);
            if (isBuildResult(maybeCondition)) return maybeCondition;

            const [condition, conditionTablePaths] = maybeCondition;
            tablePaths.push(...conditionTablePaths);

            const then = convertColumnOrValueSpecification(c.thenValue, undefined);
            if (isBuildResult(then)) return then;

            clauses.push({ condition, then });
        }
        const elseValue = convertColumnOrValueSpecification(spec.elseValue, undefined);
        if (isBuildResult(elseValue)) return elseValue;
        const handler = this.makeComputationHandlerAndSetIsGlobal(
            new IfThenElseComputation(clauses, elseValue),
            tablePaths
        );
        return this.finishWithHandler(handler);
    }

    private buildLookup(spec: LookupSpecification): ColumnBuildSuccess | BuildResult {
        let handler: Handler;
        if (spec.isMultiRelation) {
            const rollup = this.makeRollup(
                {
                    tableOrRelationColumn: spec.tableOrRelationColumn,
                    valueColumn: spec.valueColumn,
                },
                { supportsQuery: false },
                columnPath => {
                    if (columnPath === undefined) {
                        return makeBuildResultFaulty("Lookup doesn't work over arrays");
                    }
                    if (isMultiRelationType(this.tac.column.type)) {
                        return [new MultiRelationLookupComputation(columnPath, undefined), undefined];
                    } else {
                        return [new MultiPrimitiveLookupComputation(columnPath, undefined), undefined];
                    }
                }
            );
            if (isBuildResult(rollup)) return rollup;
            // We can't yet do multi-lookups on queryables via the database
            if (rollup.queryColumnName !== undefined) return makeBuildResultFaultyFromQuery();

            handler = rollup.handler;
        } else {
            if (isUniversalTableName(spec.tableOrRelationColumn)) {
                return makeBuildResultFaulty("Single-Lookup target can't be a table.");
            }

            const sc =
                typeof spec.tableOrRelationColumn === "string"
                    ? makeSourceColumn(spec.tableOrRelationColumn)
                    : spec.tableOrRelationColumn;
            const relationInfo = this.helper.makePathForSourceColumn(this.tac.table, sc, false, undefined);
            if (isBuildResult(relationInfo)) return relationInfo;
            this.updateFlags(relationInfo);

            const relationColumn = relationInfo.column;

            if (!isSingleRelationType(relationColumn.type)) {
                return makeBuildResultFaulty("Lookup is single, but relation isn't.");
            }
            const relationTable = this.helper.inspector.findTable(relationColumn.type);
            if (relationTable === undefined) return makeBuildResultFaulty("Table not found");

            const columnInRelationInfo = this.helper.lookupTableAndColumn(relationTable, spec.valueColumn);
            if (isBuildResult(columnInRelationInfo)) return columnInRelationInfo;
            this.updateFlags(columnInRelationInfo);

            if (spec.isMultiRelation && columnInRelationInfo.column.type.kind === "array") {
                return makeBuildResultFaulty("Cannot lookup array/table through multi-relation");
            }

            handler = this.makeLookup(relationInfo, columnInRelationInfo);
        }
        return this.finishWithHandler(handler);
    }

    private buildSingleValue(spec: SingleValueSpecification): ColumnBuildSuccess | BuildResult {
        // FIXME: Don't first/last/nth have to combine with the row index column path?

        const src = spec.arraySource;

        // Special case for extracting from a table where we don't
        // need the context.
        if (
            typeof src.tableOrRelationColumn !== "string" &&
            !isSourceColumn(src.tableOrRelationColumn) &&
            spec.position.kind !== SingleValuePositionKind.Random
        ) {
            const table = this.helper.inspector.findTable(src.tableOrRelationColumn);
            if (table === undefined) return makeBuildResultFaulty("Table not found");
            if (isBigTableOrExternal(table)) {
                return makeBuildResultFaultyFromQuery();
            }

            // `undefined` means not handled
            const getRow = (): { path: RootPath; canBeDeleted: boolean } | BuildResult | undefined => {
                assert(table !== undefined);
                if (spec.position.kind === SingleValuePositionKind.First) {
                    return { path: this.helper.getFirstRowOfTable(table), canBeDeleted: false };
                } else if (spec.position.kind === SingleValuePositionKind.Last) {
                    return { path: this.helper.getLastRowOfTable(table), canBeDeleted: false };
                } else {
                    const computation = this.helper.getAggregateComputationForSingleValuePosition(
                        spec.position,
                        undefined,
                        this.tac.table,
                        // We're getting the row from a full table, which
                        // always has sheet order.
                        false
                    );
                    if (isBuildResult(computation)) return computation;
                    if (computation.tablePath !== undefined) return undefined;
                    assert(computation.canBeGlobal);
                    return {
                        path: this.helper.extractRow(table, computation.computation, true),
                        canBeDeleted: true,
                    };
                }
            };

            if (src.valueColumn === undefined) {
                const row = getRow();
                if (isBuildResult(row)) return row;
                if (row !== undefined) {
                    return {
                        isGlobal: true,
                        valuePath: row.path,
                        tablePath: undefined,
                        canBeDeleted: row.canBeDeleted,
                        usesThunks: false,
                        makesQuery: false,
                        fromQuery: false,
                        isSlow: false,
                        hasCustomOrder: false,
                        warningMessage: this.warningMessage,
                    };
                }
            } else {
                const targetInfo = this.helper.lookupTableAndColumn(src.tableOrRelationColumn, src.valueColumn);
                if (isBuildResult(targetInfo)) return targetInfo;
                this.updateFlags(targetInfo);

                if (targetInfo.isGlobal) {
                    return {
                        isGlobal: true,
                        valuePath: targetInfo.valuePath,
                        tablePath: undefined,
                        canBeDeleted: false,
                        ...this.columnFlags,
                        usesThunks: false,
                        hasCustomOrder: false,
                        warningMessage: this.warningMessage,
                    };
                } else {
                    const row = getRow();
                    if (isBuildResult(row)) return row;
                    if (row !== undefined) {
                        const handler = new ComputationHandler(
                            new SingleLookupComputation(
                                row.path,
                                // In the case where we have a column with an
                                // overlaid computation (we do that for
                                // time-zone conversion), we want the value
                                // from the computation, not the raw value, so
                                // `src.valueColumn` would be wrong here.  It
                                // would also be wrong because in that case
                                // `targetInfo.rootPath` wouldn't even give us
                                // the dirt for the raw column, which would
                                // lead to a failure to recompute.  We could
                                // ##forceBaseColumn but then we wouldn't get
                                // the computed value, but we compute that for
                                // a reason.
                                // https://github.com/glideapps/glide/issues/27232
                                targetInfo.valuePath,
                                targetInfo.tablePath
                            )
                        );
                        this.isGlobal = true;
                        this.columnFlags.hasCustomOrder = false;
                        return this.finishWithHandler(handler);
                    }
                }
            }
        }

        let relationTable: TableGlideType | undefined;
        let relationTablePath: RootPath;
        // If we're looking up a value inside the row, `valuePath`
        // will be the relative path to the column in the row and
        // `valueTablePath` will be the root path to the entity that
        // produces that column.
        let valuePath: KeyPath | undefined;
        let valueTablePath: RootPath | undefined;

        const tablePaths: RootPath[] = [];
        let relationPath: Path | undefined;
        let hasCustomOrder: boolean;

        const handleFromPrimitiveArray = (relationInfo: TableAndColumnInfo) => {
            // If the input is an array then we do it via a rollup.
            const handler = this.makeSingleValueFromArray(relationInfo, spec.position);
            if (isBuildResult(handler)) return handler;
            this.columnFlags.hasCustomOrder = false;
            return this.finishWithHandler(handler);
        };

        let relationInfo: TableAndColumnInfo | undefined;
        let sourceColumnResult: SourceColumnResult | undefined;
        if (typeof src.tableOrRelationColumn === "string") {
            // FIXME: can't we handle this as a source column below and remove
            // this whole case?

            const maybeRelationInfo = this.helper.lookupTableAndColumn(this.tac.table, src.tableOrRelationColumn);
            if (isBuildResult(maybeRelationInfo)) return maybeRelationInfo;
            relationInfo = maybeRelationInfo;

            this.updateFlags(relationInfo);

            const relationColumn = relationInfo.column;

            if (isPrimitiveArrayType(relationColumn.type)) {
                return handleFromPrimitiveArray(relationInfo);
            }

            if (!isMultiRelationType(relationColumn.type)) {
                return makeBuildResultFaulty("Single Value doesn't support single relations");
            }

            relationTable = this.helper.inspector.findTable(relationColumn.type.items);

            if (relationInfo.isGlobal) {
                relationPath = relationInfo.valuePath;
            } else {
                tablePaths.push(relationInfo.tablePath);
                relationPath = relationInfo.valuePath;
            }
            hasCustomOrder = relationInfo.hasCustomOrder;
        } else if (isSourceColumn(src.tableOrRelationColumn)) {
            const result = this.helper.makePathForSourceColumn(
                this.tac.table,
                src.tableOrRelationColumn,
                false,
                undefined
            );
            if (isBuildResult(result)) return result;

            this.updateFlags(result);

            if (isPrimitiveArrayType(result.column.type)) {
                return handleFromPrimitiveArray(result);
            }

            if (!isMultiRelationType(result.column.type)) {
                return makeBuildResultFaulty("Single Value doesn't support single relations");
            }

            relationTable = this.helper.inspector.findTable(result.column.type.items);

            assert(isRootPath(result.valuePath) && result.tablePath === undefined);
            sourceColumnResult = result;
            relationPath = result.valuePath;
            hasCustomOrder = result.hasCustomOrder;
        } else {
            relationTable = this.helper.inspector.findTable(src.tableOrRelationColumn);
            hasCustomOrder = false;
        }

        if (relationTable === undefined) return makeBuildResultFaulty("Table not found");

        let columnInRelationInfo: TableAndColumnInfo | undefined;
        if (src.valueColumn !== undefined) {
            const maybeColumnInRelationInfo = this.helper.lookupTableAndColumn(relationTable, src.valueColumn);
            if (isBuildResult(maybeColumnInRelationInfo)) return maybeColumnInRelationInfo;
            columnInRelationInfo = maybeColumnInRelationInfo;
        }

        if (isBigTableOrExternal(relationTable)) {
            if (
                (relationInfo !== undefined || sourceColumnResult !== undefined) &&
                columnInRelationInfo !== undefined &&
                spec.position.kind === SingleValuePositionKind.First
            ) {
                // We special-case this to be handled like a
                // lookup instead of not supporting it at all.
                // Users often make multi-relations instead of
                // single-relations so they can use an Inline
                // List, and then they use this instead of a
                // lookup.
                const handler = this.makeLookup(defined(relationInfo ?? sourceColumnResult), columnInRelationInfo);
                this.columnFlags.hasCustomOrder = false;
                return this.finishWithHandler(handler);
            }
            return makeBuildResultFaultyFromQuery(
                'Single value from queryable table only supports values from the "First" row'
            );
        }

        if (columnInRelationInfo === undefined) {
            relationTablePath = defined(this.helper.tableBasePaths.get(getTableName(relationTable)));
            valuePath = undefined;
        } else {
            this.updateFlags(columnInRelationInfo);

            if (columnInRelationInfo.isGlobal) {
                if (relationInfo === undefined) {
                    return {
                        isGlobal: true,
                        valuePath: columnInRelationInfo.valuePath,
                        tablePath: undefined,
                        canBeDeleted: false,
                        ...this.columnFlags,
                        makesQuery: false,
                        usesThunks: false,
                        hasCustomOrder: false,
                        warningMessage: this.warningMessage,
                    };
                } else {
                    this.columnFlags.hasCustomOrder = false;
                    return this.makeGuardedForward(
                        columnInRelationInfo.valuePath,
                        relationInfo,
                        ForwardingKind.SingleValue
                    );
                }
            }

            relationTablePath = columnInRelationInfo.tablePath;
            valuePath = columnInRelationInfo.valuePath;
            valueTablePath = columnInRelationInfo.tablePath;
        }

        if (relationPath === undefined) {
            assert(typeof src.tableOrRelationColumn !== "string");
            relationPath = relationTablePath;
        }

        const computation = this.helper.getAggregateComputationForSingleValuePosition(
            spec.position,
            valuePath,
            this.tac.table,
            hasCustomOrder
        );
        if (isBuildResult(computation)) return computation;

        if (computation.tablePath !== undefined) {
            tablePaths.push(computation.tablePath);
        }

        let handler: Handler;
        if (tablePaths.length === 0 && computation.canBeGlobal) {
            assert(isRootPath(relationPath));
            let aggregatedTablePath: RootPath;
            if (valueTablePath === undefined) {
                aggregatedTablePath = relationPath;
            } else {
                aggregatedTablePath = this.helper.addEntity(
                    `combine for global multi-relation ${prettyPrintTableAndColumn(this.tac)}`,
                    new CombineHandler(relationPath, [
                        {
                            kind: "pass-through",
                            path: relationPath,
                        },
                        {
                            kind: "full-table-from-column",
                            tablePath: valueTablePath,
                            columnName: defined(valuePath).key,
                        },
                    ]),
                    true
                );
            }
            handler = makeTableAggregateHandler(aggregatedTablePath, computation.computation, undefined, false);
            this.isGlobal = true;
        } else {
            let combined: RootPath;
            if (tablePaths.length === 0) {
                combined = defined(this.helper.tableBasePaths.get(this.tableName));
            } else {
                combined = this.helper.combineTablePaths(tablePaths, true);
            }

            this.columnFlags.usesThunks = true;
            handler = new TableAggregateComputedColumnHandler(
                combined,
                relationPath,
                relationTablePath,
                computation.computation,
                this.columnName,
                undefined,
                false
            );
        }

        this.columnFlags.hasCustomOrder = false;
        return this.finishWithHandler(handler);
    }

    private buildTextTemplate(spec: TextTemplateSpecification): ColumnBuildSuccess | BuildResult {
        let buildResult: BuildResult | undefined;
        const tablePaths: RootPath[] = [];
        const replacementColumns = new Map<string, Path>(
            mapFilterUndefined(spec.params, ([p, sc]) => {
                if (sc === undefined) return undefined;
                const maybePath = this.helper.makePathForColumnOrValue(this.tac, sc, true, undefined);
                if (isBuildResult(maybePath)) {
                    buildResult = maybePath;
                    return undefined;
                }
                if (maybePath.tablePath !== undefined) {
                    tablePaths.push(maybePath.tablePath);
                }
                if (maybePath.column !== undefined && !isPrimitiveType(maybePath.column.type)) {
                    logError("Non-primitive replacement for template");
                    return undefined;
                }
                this.updateFlags(maybePath);
                return [p, maybePath.valuePath];
            })
        );
        if (buildResult !== undefined) return buildResult;
        let computation: Computation;
        if (spec.template.kind === ColumnOrValueKind.Constant) {
            const tokens = tokenizeTemplateString(spec.template.value, Array.from(replacementColumns.keys()));
            const columnTokens: TextTemplateToken<Path>[] = tokens.map(t => {
                if (t.isText) {
                    return t;
                } else {
                    return { isText: false, value: defined(replacementColumns.get(t.value)) };
                }
            });
            computation = new StaticTextTemplateComputation(columnTokens);
        } else {
            const templatePath = this.helper.makePathForColumnOrValue(this.tac, spec.template, false, undefined);
            if (isBuildResult(templatePath)) return templatePath;
            if (templatePath.column !== undefined && !isPrimitiveType(templatePath.column.type)) {
                return makeBuildResultFaulty("Cannot make template with non-primitive");
            }
            if (templatePath.tablePath !== undefined) {
                tablePaths.push(templatePath.tablePath);
            }
            this.updateFlags(templatePath);
            computation = new DynamicTextTemplateComputation(templatePath.valuePath, replacementColumns);
        }
        const handler = this.makeComputationHandlerAndSetIsGlobal(computation, tablePaths);
        return this.finishWithHandler(handler);
    }

    private buildMath(spec: MathSpecification): ColumnBuildSuccess | BuildResult {
        const result = parseMath(spec.expr, false);
        if (!result.success) return makeBuildResultFaulty("Math formula is incorrect");
        assert(result.formula.kind === FormulaKind.WithUserEnteredText);
        const formula = (result.formula as WithUserEnteredTextFormula).formula;
        let buildResult: BuildResult | undefined;
        const tablePaths: RootPath[] = [];
        if (hasMathFormulaRandom(formula)) {
            tablePaths.push(defined(this.helper.tableBasePaths.get(this.tableName)));
        }
        const variablePaths = new Map<string, [Path, PrimitiveGlideTypeKind]>(
            mapFilterUndefined(spec.variables, ([n, va]) => {
                if (va === SpecialValueKind.Timestamp) {
                    return [n, [this.helper.getTimestampPath(), "date-time"]];
                } else {
                    const p = this.helper.makePathForSourceColumn(this.tac.table, va, false, undefined);

                    if (isBuildResult(p)) {
                        buildResult = p;
                        return undefined;
                    }
                    if (p.tablePath !== undefined) {
                        tablePaths.push(p.tablePath);
                    }
                    this.updateFlags(p);
                    if (!isPrimitiveType(p.column.type)) {
                        buildResult = makeBuildResultFaulty("Math column input is not a primitive");
                        return undefined;
                    }
                    return [n, [p.valuePath, p.column.type.kind]];
                }
            })
        );
        if (buildResult !== undefined) return buildResult;
        const handler = this.makeComputationHandlerAndSetIsGlobal(
            new MathComputation(formula, variablePaths),
            tablePaths
        );
        return this.finishWithHandler(handler);
    }

    private buildRollup(spec: RollupSpecification): ColumnBuildSuccess | BuildResult {
        const rollupResult = this.makeRollup(spec.arraySource, {}, (valuePath, columnName) => {
            switch (spec.rollupKind) {
                case RollupKind.AllTrue:
                    return [new AllTrueComputation(valuePath, columnName), undefined];
                case RollupKind.SomeTrue:
                    return [new SomeTrueComputation(valuePath, columnName), undefined];
                case RollupKind.Sum:
                    return [new SumComputation(valuePath, columnName), undefined];
                case RollupKind.CountNonEmpty:
                    return [new CountNonEmptyComputation(valuePath, columnName), undefined];
                case RollupKind.Average:
                    return [new AverageComputation(valuePath, columnName), undefined];
                case RollupKind.Minimum:
                    return [new MinimumComputation(valuePath, columnName), undefined];
                case RollupKind.Maximum:
                    return [new MaximumComputation(valuePath, columnName), undefined];
                case RollupKind.Earliest:
                    return [new EarliestComputation(valuePath, columnName), undefined];
                case RollupKind.Latest:
                    return [new LatestComputation(valuePath, columnName), undefined];
                case RollupKind.Range:
                    return [new RangeComputation(valuePath, columnName), undefined];
                case RollupKind.CountTrue:
                    return [new CountTrueComputation(valuePath, columnName), undefined];
                case RollupKind.CountNotTrue:
                    return [new CountNotTrueComputation(valuePath, columnName), undefined];
                case RollupKind.CountUnique:
                    return [new CountUniqueComputation(valuePath, columnName), undefined];
                default:
                    return assertNever(spec.rollupKind);
            }
        });
        if (isBuildResult(rollupResult)) return rollupResult;
        return this.finishWithRollupResult(rollupResult);
    }

    private buildJoinStrings(spec: JoinStringsSpecification): ColumnBuildSuccess | BuildResult {
        const separatorPath = this.helper.makePathForColumnOrValue(this.tac, spec.separator, true, undefined);
        if (isBuildResult(separatorPath)) return separatorPath;
        this.updateFlags(separatorPath);
        const rollupResult = this.makeRollup(spec.arraySource, { withFormat: true }, (valuePath, columnName) => [
            new JoinStringsComputation(valuePath, columnName, separatorPath.valuePath),
            separatorPath.tablePath,
        ]);
        if (isBuildResult(rollupResult)) return rollupResult;
        return this.finishWithRollupResult(rollupResult);
    }

    private buildSplitString(spec: SplitStringSpecification): ColumnBuildSuccess | BuildResult {
        const tablePaths: RootPath[] = [];
        const inputPath = this.helper.makePathForSourceColumn(this.tac.table, spec.stringColumn, true, undefined);
        if (isBuildResult(inputPath)) return inputPath;
        if (inputPath.tablePath !== undefined) {
            tablePaths.push(inputPath.tablePath);
        }
        const separatorPath = this.helper.makePathForColumnOrValue(this.tac, spec.separator, true, undefined);
        if (isBuildResult(separatorPath)) return separatorPath;
        if (
            (inputPath.column !== undefined && !isPrimitiveType(inputPath.column.type)) ||
            (separatorPath.column !== undefined && !isPrimitiveType(separatorPath.column.type))
        ) {
            // To be compatible with the old model.  Technically this
            // should be `BuildResult.Faulty`.
            return {
                isGlobal: true,
                valuePath: this.helper.getEmptyArrayPath(),
                tablePath: undefined,
                canBeDeleted: false,
                usesThunks: false,
                makesQuery: false,
                fromQuery: false,
                isSlow: false,
                hasCustomOrder: false,
                warningMessage: this.warningMessage,
            };
        }
        if (separatorPath.tablePath !== undefined) {
            tablePaths.push(separatorPath.tablePath);
        }

        this.updateFlags(inputPath, separatorPath);
        const handler = this.makeComputationHandlerAndSetIsGlobal(
            new SplitStringComputation(inputPath.valuePath, separatorPath.valuePath),
            tablePaths
        );
        return this.finishWithHandler(handler);
    }

    private buildMakeArray(spec: MakeArraySpecification): ColumnBuildSuccess | BuildResult {
        let buildResult: BuildResult | undefined;
        this.columnFlags.usesThunks = true;
        const tablePaths: RootPath[] = [];
        const paths = mapFilterUndefined(spec.items, sc => {
            const p = this.helper.makePathForColumnOrValue(this.tac, sc, false, undefined);
            if (isBuildResult(p)) {
                buildResult = p;
                return undefined;
            }
            if (p.tablePath !== undefined) {
                tablePaths.push(p.tablePath);
            }
            this.updateFlags(p);
            return p.valuePath;
        });
        if (buildResult !== undefined) return buildResult;
        const handler = this.makeComputationHandlerAndSetIsGlobal(new MakeArrayComputation(paths), tablePaths);
        return this.finishWithHandler(handler);
    }

    private buildGenerateImage(spec: GenerateImageSpecification): ColumnBuildSuccess | BuildResult {
        const seedPath = this.helper.makePathForColumnOrValue(this.tac, spec.input, false, undefined);
        if (isBuildResult(seedPath)) return seedPath;
        this.updateFlags(seedPath);
        const handler = this.makeComputationHandlerAndSetIsGlobal(
            new GenerateImageComputation(seedPath.valuePath, spec.imageKind === GeneratedImageKind.Mesh),
            seedPath.tablePath
        );
        return this.finishWithHandler(handler);
    }

    private buildGeoDistance(spec: GeoDistanceSpecification): ColumnBuildSuccess | BuildResult {
        if (this.helper.appEnvironment === undefined) return makeBuildResultFaulty("Internal error");

        const firstInfo = this.helper.makePathForSourceColumn(this.tac.table, spec.locationColumn, false, undefined);
        if (isBuildResult(firstInfo)) return firstInfo;
        // It's fine to update `usesThunks` here - it'll be set to
        // `true` by `makeAsyncComputationHandlerAndSetIsGlobal`
        // later.
        this.updateFlags(firstInfo);

        let secondPath: Path;
        let secondTablePath: RootPath | undefined;
        if (spec.otherLocationColumn === "here") {
            secondPath = this.helper.getCurrentLocationPath();
        } else {
            const secondInfo = this.helper.makePathForSourceColumn(
                this.tac.table,
                spec.otherLocationColumn,
                false,
                undefined
            );
            if (isBuildResult(secondInfo)) return secondInfo;
            this.updateFlags(secondInfo);

            secondPath = secondInfo.valuePath;
            secondTablePath = secondInfo.tablePath;
        }

        const computation = new GeoDistanceAsyncComputation(
            firstInfo.valuePath,
            secondPath,
            spec.unit,
            this.helper.appEnvironment,
            makeQuotaKeyForFormula(spec)
        );

        const tablePaths: RootPath[] = [];
        if (firstInfo.tablePath !== undefined) {
            tablePaths.push(firstInfo.tablePath);
        }
        if (secondTablePath !== undefined) {
            tablePaths.push(secondTablePath);
        }

        const handler = this.makeAsyncComputationHandlerAndSetIsGlobal(computation, tablePaths);
        this.columnFlags.isSlow = !this.isGlobal;
        return this.finishWithHandler(handler);
    }

    private buildConstructURL(spec: ConstructURLSpecification): ColumnBuildSuccess | BuildResult {
        const schemePath = this.helper.makePathForColumnOrValue(this.tac, spec.scheme, false, undefined);
        const hostPath = this.helper.makePathForColumnOrValue(this.tac, spec.host, false, undefined);
        const pathPath = this.helper.makePathForColumnOrValue(this.tac, spec.path, false, undefined);
        if (isBuildResult(schemePath)) return schemePath;
        if (isBuildResult(hostPath)) return hostPath;
        if (isBuildResult(pathPath)) return pathPath;

        const tablePaths = [schemePath.tablePath, hostPath.tablePath, pathPath.tablePath];
        this.updateFlags(schemePath, hostPath, pathPath);

        const paramPaths = this.helper.makePathsForQueryParameters(this.tac, spec.params, false);
        if (isBuildResult(paramPaths)) return paramPaths;

        tablePaths.push(...paramPaths.tablePaths);
        this.updateFlags(paramPaths);

        const handler = this.makeComputationHandlerAndSetIsGlobal(
            new ConstructURLComputation(
                schemePath.valuePath,
                hostPath.valuePath,
                pathPath.valuePath,
                mapMap(paramPaths.paramPaths, ([v]) => v)
            ),
            filterUndefined(tablePaths)
        );
        return this.finishWithHandler(handler);
    }

    private buildYesCode(spec: YesCodeSpecification): ColumnBuildSuccess | BuildResult {
        if (this.helper.appEnvironment === undefined) return makeBuildResultFaulty("Internal error");

        const urlPath = this.helper.makePathForConstant(spec.url);

        const paramPaths = this.helper.makePathsForQueryParameters(this.tac, spec.params, true);
        if (isBuildResult(paramPaths)) return paramPaths;
        this.updateFlags(paramPaths);

        const handler = this.makeAsyncComputationHandlerAndSetIsGlobal(
            new YesCodeComputation(urlPath, paramPaths.paramPaths, this.helper.appEnvironment),
            paramPaths.tablePaths
        );
        this.columnFlags.isSlow = !this.isGlobal;

        this.helper.appEnvironment.preloadYesCodeModule?.(spec.url);

        return this.finishWithHandler(handler);
    }

    private buildPluginComputation(spec: PluginComputationSpecification): ColumnBuildSuccess | BuildResult {
        if (this.helper.appEnvironment === undefined) return makeBuildResultFaulty("Internal error");

        const paramSources: PluginParameterSource[] = [];
        const tablePaths: RootPath[] = [];

        const processProperty = (
            p: unknown,
            parameterName: string,
            setter: PluginParameterSetter
        ): boolean | BuildResult => {
            // If we don't do this then `getSourceColumnProperty` and
            // `getStringProperty` will both fire on raw strings, i.e. super
            // old legacy properties.  This way we just weed them out right
            // away.
            if (!isPropertyDescription(p)) return false;

            const constant =
                getStringProperty(p) ??
                getNumberProperty(p) ??
                getSwitchProperty(p) ??
                getEnumProperty(p) ??
                getSecretProperty(p) ??
                getJSONPathProperty(p);

            // We handle the empty string like `undefined`.
            if (constant === "") return false;

            const sourceColumn = getSourceColumnProperty(p);
            const specialValue = getSpecialValueProperty(p);

            if (constant !== undefined) {
                paramSources.push({
                    name: parameterName,
                    setter,
                    value: constant,
                });
            } else if (sourceColumn !== undefined) {
                const path = this.helper.makePathForSourceColumn(this.tac.table, sourceColumn, false, undefined);
                if (isBuildResult(path)) return path;
                if (path.tablePath !== undefined) {
                    tablePaths.push(path.tablePath);
                }
                this.updateFlags(path);

                let formatPath = path;
                const maybeFormatPath = this.helper.makePathForSourceColumn(
                    this.tac.table,
                    sourceColumn,
                    true,
                    undefined
                );
                if (!isBuildResult(maybeFormatPath)) {
                    formatPath = maybeFormatPath;
                    if (formatPath.tablePath !== undefined) {
                        tablePaths.push(formatPath.tablePath);
                    }
                    this.updateFlags(formatPath);
                }

                paramSources.push({
                    name: parameterName,
                    setter,
                    paths: [path.valuePath, formatPath.valuePath],
                    kind: makeParameterSourceColumnType(path.column.type),
                });
            } else if (specialValue !== undefined) {
                const path = this.helper.makePathForSpecialValue(this.tac, specialValue);
                if (isBuildResult(path)) return path;

                if (path.tablePath !== undefined) {
                    tablePaths.push(path.tablePath);
                }
                this.updateFlags(path);

                paramSources.push({
                    name: parameterName,
                    setter,
                    paths: [path.valuePath, path.valuePath],
                    kind: [path.type.kind],
                });
            } else {
                return false;
            }
            return true;
        };

        function processArrayElement(
            parameterName: string,
            index: number,
            kvp: NameAndValueDescription,
            withSecretConstants: boolean | undefined
        ): BuildResult | undefined {
            let name: string | undefined;

            const nameResult = processProperty(kvp.name, `${parameterName}[${index}].name`, (_obj, value) => {
                const v = asMaybeString(value);
                if (isEmptyOrUndefined(v)) return;
                name = v;
            });

            if (isBuildResult(nameResult)) return nameResult;

            if (!nameResult) return;

            const valueResult = processProperty(
                kvp.value,
                `${parameterName}[${index}].value`,
                (obj, value, _columnType, isConstant) => {
                    if (name === undefined) return;

                    // for dates, we want to pass the formatted value
                    let stringValue: string | undefined;
                    if (value instanceof GlideDateTime) {
                        stringValue = value.asUTCDate().toISOString();
                    } else if (value instanceof GlideJSON) {
                        stringValue = value.jsonString;
                    } else {
                        stringValue = asMaybeString(value);
                    }

                    if (stringValue === undefined) {
                        return;
                    }

                    if (obj[parameterName] === undefined) {
                        obj[parameterName] = {};
                    }
                    if (withSecretConstants === true) {
                        (obj[parameterName] as JSONObject)[name] = {
                            value: stringValue,
                            isSecret: isConstant,
                        };
                    } else {
                        (obj[parameterName] as JSONObject)[name] = stringValue;
                    }
                }
            );
            if (isBuildResult(valueResult)) return valueResult;

            return;
        }

        function processJSONElement(
            parameterName: string,
            index: number,
            kvp: NameAndValueDescription
        ): BuildResult | undefined {
            let name: string | undefined;

            const nameResult = processProperty(kvp.name, `${parameterName}[${index}].name`, (_obj, value) => {
                const v = asMaybeString(value);
                if (isEmptyOrUndefined(v)) return;
                name = v;
            });

            if (isBuildResult(nameResult)) return nameResult;

            if (!nameResult) return;

            const valueResult = processProperty(
                kvp.value,
                `${parameterName}[${index}].value`,
                (obj, value, columnType) => {
                    if (name === undefined) return;

                    const jsonValue = asMaybeJSONValueForColumnType(value, columnType);

                    if (jsonValue === undefined) {
                        return;
                    }

                    if (obj[parameterName] === undefined) {
                        obj[parameterName] = {};
                    }
                    (obj[parameterName] as JSONObject)[name] = jsonValue;
                }
            );
            if (isBuildResult(valueResult)) return valueResult;

            return;
        }

        const pluginData = this.helper.getPluginComputation(spec.pluginID, spec.computationID);
        if (isBuildResult(pluginData)) return pluginData;
        const { pluginConfig, computation } = pluginData;

        for (const [parameterName, parameter] of Object.entries(computation.parameters)) {
            const p = (spec.parameters as any)[parameterName];
            if (parameter.type === "jsonPath") {
                const jsonPathResult = processProperty(p, parameterName, (obj, value) => {
                    value = asMaybeArrayOfStrings(value) ?? asMaybeString(value);
                    obj[parameterName] = value;
                });
                if (isBuildResult(jsonPathResult)) return jsonPathResult;
                continue;
            }

            if (parameter.type === "jsonObject") {
                const arr = getArrayProperty<NameAndValueDescription>(p);
                if (arr === undefined) continue;
                for (const [i, kvp] of iterableEnumerate(arr)) {
                    const maybeResult = processJSONElement(parameterName, i, kvp);

                    if (isBuildResult(maybeResult)) {
                        return maybeResult;
                    }
                }
                continue;
            }

            if (parameter.type === "stringObject") {
                const arr = getArrayProperty<NameAndValueDescription>(p);
                if (arr === undefined) continue;

                for (const [i, kvp] of iterableEnumerate(arr)) {
                    const maybeResult = processArrayElement(parameterName, i, kvp, parameter.withSecretConstants);

                    if (isBuildResult(maybeResult)) {
                        return maybeResult;
                    }
                }

                continue;
            }

            const setter: PluginParameterSetter = (obj, value, columnType) => {
                let pluginValue: unknown;
                switch (parameter.type) {
                    case "string":
                    case "enum":
                    case "url":
                        pluginValue = asMaybeString(value);
                        break;
                    case "number":
                        pluginValue = asMaybeNumber(value);
                        break;
                    case "boolean":
                        pluginValue = asMaybeBoolean(value);
                        break;
                    case "stringArray":
                        pluginValue = asMaybeArrayOfStringsCoercedString(value);
                        break;
                    case "dateTime":
                        pluginValue = asMaybeDate(value);
                        break;
                    case "json":
                        pluginValue = asMaybeJSONValueForColumnType(value, columnType);
                        break;
                    default:
                        logError("Parameter type not supported", parameter.type);
                        return;
                }
                obj[parameterName] = pluginValue;
            };

            const result = processProperty(p, parameterName, setter);
            if (isBuildResult(result)) return result;
        }

        const handler = this.makeAsyncComputationHandlerAndSetIsGlobal(
            new PluginComputationComputation(
                pluginConfig ?? {
                    pluginID: spec.pluginID,
                    configID: undefined,
                    parameters: {},
                },
                spec.computationID,
                paramSources,
                spec.resultName,
                this.helper.appEnvironment,
                this.helper.pluginMetadata,
                {
                    prettyName: prettyPrintTableAndColumn(this.tac),
                    throwError: this.opts.throwComputationErrors,
                }
            ),
            tablePaths
        );
        this.columnFlags.isSlow = !this.isGlobal;

        return this.finishWithHandler(handler);
    }

    private buildFilterSortLimit(spec: FilterSortLimitSpecification): ColumnBuildSuccess | BuildResult {
        let filterConditions: FilterConditions;
        const tablePaths: RootPath[] = [];

        const result = this.makeRollup(
            {
                tableOrRelationColumn: spec.tableOrRelationColumn,
                valueColumn: undefined,
            },
            { fullRow: true, canHaveCustomOrder: true },
            (_valuePath, _columnName, targetTable) => {
                if (targetTable === undefined) {
                    return makeBuildResultFaulty("No target table for Query column");
                }

                const isQueryable = isBigTableOrExternal(targetTable);
                let queryConditionsTransformer:
                    | ((proc: TableAggregateDataProvider<unknown>, q: Query) => Query | LoadingValue | undefined)
                    | undefined;
                const additionalAggregatePaths: RootPath[] = [];

                if (spec.filter === undefined) {
                    // This is `true`
                    filterConditions = { combinator: "and", conditions: [] };
                    if (isQueryable) {
                        queryConditionsTransformer = (_proc, q) => q;
                    }
                } else {
                    const conditions: Condition<ConditionValuePath>[] = [];
                    for (const c of spec.filter.predicates ?? []) {
                        const maybeCondition = this.makeCondition(c, targetTable, this.tac.table, false);
                        if (isBuildResult(maybeCondition)) return maybeCondition;

                        const [condition, conditionTablePaths, hostTablePaths] = maybeCondition;
                        conditions.push(condition);
                        additionalAggregatePaths.push(...conditionTablePaths);
                        tablePaths.push(...hostTablePaths);
                    }

                    filterConditions = {
                        combinator: spec.filter.combinator,
                        conditions,
                    };

                    if (isQueryable) {
                        const maybeTransformer = tryCatchBuildResult(() => {
                            const { tac, helper } = this;
                            const valueInflator: QueryValueInflator<TableAggregateDataProvider<unknown>> = {
                                makeNonDefaultSourceColumnGetter(sc) {
                                    assert(sc.kind !== SourceColumnKind.DefaultContext);

                                    const maybePath = helper.makePathForSourceColumn(targetTable, sc, false, tac.table);
                                    if (isBuildResult(maybePath)) return throwBuildResult(maybePath);

                                    const { valuePath: path } = maybePath;

                                    return proc =>
                                        proc.loadingValueWrapper.unwrap(
                                            getValueAt(proc.rootPathResolver, proc.getContextRow(), path)
                                        );
                                },
                                makeVerifiedEmailAddressGetter() {
                                    const p = helper.getVerifiedEmailAddressPath();
                                    return proc => {
                                        const v = proc.loadingValueWrapper.unwrap(proc.rootPathResolver.get(p));
                                        if (v === undefined || isLoadingValue(v)) return v;
                                        return asString(v);
                                    };
                                },
                            };

                            return inflateQueryConditions(valueInflator, targetTable, defined(spec.filter));
                        });
                        if (isBuildResult(maybeTransformer)) return maybeTransformer;
                        queryConditionsTransformer = maybeTransformer;
                    }
                }

                let contextTablePath: RootPath | undefined;
                if (tablePaths.length > 0) {
                    contextTablePath = this.helper.combineTablePaths(tablePaths, false);
                }

                let sortTableColumnPath: RootPath | undefined;
                let reverse = false;

                if (spec.ordering !== undefined) {
                    if (spec.ordering.kind === ArrayTransformKind.Sort) {
                        const sortColumnName = decomposeSortKey(spec.ordering.keys[0]?.key);
                        if (sortColumnName !== undefined) {
                            const sortInfo = this.helper.lookupTableAndColumn(targetTable, sortColumnName);
                            if (isBuildResult(sortInfo)) return sortInfo;

                            if (isQueryable) {
                                if (
                                    !isQueryableColumn(
                                        this.helper.inspector,
                                        sortInfo.table,
                                        sortInfo.column,
                                        this.opts.gbtComputedColumnsAlpha,
                                        this.opts.gbtDeepLookups
                                    )
                                ) {
                                    return makeBuildResultFaulty("Cannot sort by computed column");
                                }

                                sortTableColumnPath = amendPath(getRootPathForPathForColumn(sortInfo), {
                                    c: sortInfo.column.name,
                                });
                            } else if (!sortInfo.isGlobal) {
                                sortTableColumnPath = sortInfo.tableColumnPath;
                            }
                            reverse = spec.ordering?.keys[0].order !== SortOrder.Ascending;
                            this.columnFlags.hasCustomOrder = true;
                        }
                    } else if (spec.ordering.kind === ArrayTransformKind.TableOrder) {
                        if (doesTableSupportReverseSheetOrder(targetTable)) {
                            reverse = spec.ordering.reverse;
                            this.columnFlags.hasCustomOrder = true;
                        }
                    } else {
                        return assertNever(spec.ordering);
                    }
                }

                return [
                    new FilterSortLimitComputation(
                        filterConditions,
                        queryConditionsTransformer,
                        sortTableColumnPath,
                        additionalAggregatePaths,
                        reverse,
                        spec.limit,
                        spec.multiple
                    ),
                    contextTablePath,
                ];
            }
        );
        if (isBuildResult(result)) return result;

        assert(result.queryColumnName === undefined);
        const handler = result.handler;
        return this.finishWithHandler(handler);
    }

    public buildRegularColumn(): ColumnBuildSuccess | BuildResult {
        const spec = decomposeAll(defined(this.tac.column.formula));
        if (spec === undefined) return makeBuildResultFaulty("Computed column not supported");

        switch (spec.kind) {
            case SyntheticColumnKind.FilterReference:
                return this.buildFilterReference(spec);
            case SyntheticColumnKind.IfThenElse:
                return this.buildIfThenElse(spec);
            case SyntheticColumnKind.Lookup:
                return this.buildLookup(spec);
            case SyntheticColumnKind.SingleValue:
                return this.buildSingleValue(spec);
            case SyntheticColumnKind.TextTemplate:
                return this.buildTextTemplate(spec);
            case SyntheticColumnKind.Math:
                return this.buildMath(spec);
            case SyntheticColumnKind.Rollup:
                return this.buildRollup(spec);
            case SyntheticColumnKind.JoinStrings:
                return this.buildJoinStrings(spec);
            case SyntheticColumnKind.SplitString:
                return this.buildSplitString(spec);
            case SyntheticColumnKind.MakeArray:
                return this.buildMakeArray(spec);
            case SyntheticColumnKind.GenerateImage:
                return this.buildGenerateImage(spec);
            case SyntheticColumnKind.GeoDistance:
                return this.buildGeoDistance(spec);
            case SyntheticColumnKind.UserAPIFetch:
                return makeBuildResultMissing(this.tac, "user API fetch");
            case SyntheticColumnKind.ConstructURL:
                return this.buildConstructURL(spec);
            case SyntheticColumnKind.YesCode:
                return this.buildYesCode(spec);
            case SyntheticColumnKind.PluginComputation:
                return this.buildPluginComputation(spec);
            case SyntheticColumnKind.FilterSortLimit:
                return this.buildFilterSortLimit(spec);
            default:
                assertNever(spec);
        }
    }
}
