import { getFirstElementFromArrayOrSingleElement } from "@glide/common-core/dist/js/components/component-helpers";
import { ArrayScreenFormat } from "@glide/app-description";
import type {
    WireDataGridListComponent,
    WireKanbanComponent,
    WireListCalendarCollection,
} from "@glide/fluent-components/dist/js/base-components";
import type { WireListCardCollection } from "@glide/fluent-components/dist/js/fluent-components";
import type { WireListComponentGeneric } from "@glide/fluent-components/dist/js/fluent-components-spec";
import { type WireComponent, WireComponentKind } from "@glide/wire";

export function getComponentTitleImage(component: WireComponent | null | undefined): string | null | undefined {
    if (component === undefined || component === null) return undefined;
    if (component.kind === WireComponentKind.List) {
        const coerced = component as WireListComponentGeneric<ArrayScreenFormat>;
        if (coerced.format === ArrayScreenFormat.CardCollection) {
            const singleOrMultiImages = (coerced as WireListCardCollection).titleImage;
            return getFirstElementFromArrayOrSingleElement(singleOrMultiImages);
        } else if (coerced.format === ArrayScreenFormat.Kanban) {
            return (coerced as WireKanbanComponent).titleImage;
        } else if (coerced.format === ArrayScreenFormat.DataGrid) {
            return (coerced as WireDataGridListComponent).titleImage;
        } else if (coerced.format === ArrayScreenFormat.CalendarCollection) {
            return (coerced as WireListCalendarCollection).titleImage;
        } else if (coerced.format === ArrayScreenFormat.ForEachContainer) {
            return (coerced as WireListCalendarCollection).titleImage;
        }
    }
    return undefined;
}
