import { GlideIcon } from "@glide/common";
import * as React from "react";
import { css } from "styled-components";
import tw from "twin.macro";

import { Button } from "../button/button";
import type { Size } from "./breadcrumb-style";
import { BreadcrumbStyle } from "./breadcrumb-style";
import classNames from "classnames";

interface Props<T> {
    readonly items: readonly T[];
    readonly currentItem: T;
    readonly size: Size;
    readonly minimal?: boolean;
    readonly nameForItem?: (item: T) => string;
    readonly onItemClicked?: (item: T) => void;
    readonly isLoading?: boolean;

    readonly onItemRename?: (item: T, newName: string) => void;
}

interface RenameableBreadcrumbProps<T> {
    readonly currentItem: T;
    readonly nameForItem?: (item: T) => string;
    readonly onItemRename: (item: T, newName: string) => void;
    readonly size: Size;
    readonly minimal?: boolean;
}

function itemName<T>(item: T, nameForItem?: (item: T) => string): string {
    return nameForItem?.(item) ?? `${item}`;
}

function RenameableBreadcrumb<T>(
    props: React.PropsWithChildren<RenameableBreadcrumbProps<T>>
): React.ReactElement {
    const { currentItem, nameForItem, onItemRename, size, minimal } = props;
    const [name, setName] = React.useState(itemName(currentItem, nameForItem));
    const [editing, setEditing] = React.useState(false);

    React.useEffect(() => {
        setName(itemName(currentItem, nameForItem));
    }, [currentItem, nameForItem]);

    if (editing) {
        return (
            <BreadcrumbStyle size={size} className={classNames(minimal && "is-minimal")}>
                <input
                    className="brcmb-current brcmb-item brcmb-editable brcmb-is-editing"
                    type="text"
                    autoFocus={true}
                    value={name}
                    onFocus={e => e.target.select()}
                    onChange={e => setName(e.target.value)}
                    onBlur={() => {
                        onItemRename(currentItem, name);
                        setName(itemName(currentItem, nameForItem));
                        setEditing(false);
                    }}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            onItemRename(currentItem, name);
                            setEditing(false);
                        } else if (e.key === "Escape") {
                            setName(itemName(currentItem, nameForItem));
                            setEditing(false);
                        }
                    }}
                />
                <Button
                    variant={"accent"}
                    buttonType={"secondary"}
                    size={size === 26 ? "sm" : "xsm"}
                    label={"Save"}
                    iconType="iconOnly"
                    icon="st-check"
                    onClick={() => setEditing(false)}
                />
            </BreadcrumbStyle>
        );
    } else {
        return (
            <BreadcrumbStyle size={size} className={classNames(minimal && "is-minimal")}>
                <div tw="flex items-center h-full max-w-full" className="brcmb-wrapper">
                    <div
                        className="brcmb-current brcmb-item brcmb-editable brcmb-truncate"
                        onClick={() => setEditing(true)}
                        css={css`
                            ${size === 26 ? tw`mr-1` : tw`mr-0.5`}
                        `}>
                        <div tw="truncate">{name}</div>
                    </div>
                    <Button
                        variant={"default"}
                        buttonType={"minimal"}
                        size={size === 26 ? "sm" : "xsm"}
                        label={"Edit"}
                        iconType="iconOnly"
                        icon="st-edit-pencil"
                        onClick={() => setEditing(true)}
                    />
                </div>
            </BreadcrumbStyle>
        );
    }
}

export default function Breadcrumb<T>(props: React.PropsWithChildren<Props<T>>): React.ReactElement {
    const { items, currentItem, nameForItem, onItemClicked, onItemRename, size, minimal, isLoading = false } = props;

    // When there is only one item, we can support renaming
    if (items.length === 1 && onItemRename !== undefined) {
        return <RenameableBreadcrumb {...{ currentItem, onItemRename, nameForItem, size, minimal }} />;
    }

    return (
        <BreadcrumbStyle size={size}>
            {items.map((item, index) => {
                const isCurrent = item === currentItem;
                return (
                    <React.Fragment key={index}>
                        <button
                            onClick={onItemClicked !== undefined ? () => onItemClicked(item) : undefined}
                            className={isCurrent ? "brcmb-current brcmb-item" : "brcmb-item"}>
                            <div tw="truncate">{itemName(item, nameForItem)}</div>
                            {index < items.length - 1 && (
                                <GlideIcon
                                    tw="inline-flex flex-none text-icon-pale ml-1"
                                    kind="stroke"
                                    icon="st-caret"
                                    rotateDeg={270}
                                    iconSize={20}
                                />
                            )}
                        </button>
                    </React.Fragment>
                );
            })}
            {isLoading && (
                <GlideIcon
                    icon="st-half-spinner"
                    kind="stroke"
                    tw="animate-spin text-icon-base transition opacity-0 w-5 h-5 ml-3 ease-in-out"
                    css={[isLoading && tw`opacity-100`]}
                />
            )}
        </BreadcrumbStyle>
    );
}
