import { GlideDateTime, GlideJSON } from "@glide/data-types";
import type { ValueTypeType } from "@glide/plugins";

// FIXME: this function doesn't belong here, and should use the regular
// computation model types, not these impromptu ones.  Maybe `generator`?  Or
// `computation-model`?
type PrimitiveType = string | number | boolean | GlideJSON;
type ArrayType = readonly (PrimitiveType | ArrayType)[];

export function convertValueToJSONValue(maybeJSONValue: ValueTypeType): PrimitiveType | ArrayType {
    if (
        typeof maybeJSONValue === "string" ||
        typeof maybeJSONValue === "number" ||
        typeof maybeJSONValue === "boolean"
    ) {
        return maybeJSONValue;
    }
    // The `instanceof` check here makes TS happy, and the `Array.isArray`
    // check is to make super duper sure that it's really an array, even if
    // it's not from this realm.
    if (maybeJSONValue instanceof Array || Array.isArray(maybeJSONValue)) {
        return maybeJSONValue.map(convertValueToJSONValue);
    }

    if (maybeJSONValue instanceof GlideDateTime) {
        return maybeJSONValue.asUTCDate().toISOString();
    }

    if (maybeJSONValue instanceof GlideJSON) return maybeJSONValue;

    return GlideJSON.fromJSON(maybeJSONValue);
}
