import { localStorageGetItem, localStorageSetItem } from "@glide/support";
import { generateFirestoreDocumentID } from "./id-generator";

/** @deprecated This is exported only for testing */
export const deviceIDStorageKey = "glide-device-id";

let deviceID: string | undefined;

/** @deprecated This is to be used only in tests */
export function resetDeviceIDForTest(): void {
    deviceID = undefined;
}

export function getDeviceID(): string {
    if (deviceID === undefined) {
        deviceID = localStorageGetItem(deviceIDStorageKey);
    }
    if (deviceID === undefined) {
        deviceID = generateFirestoreDocumentID();
        localStorageSetItem(deviceIDStorageKey, deviceID);
    }
    return deviceID;
}
