import {
    type ColumnType,
    type TableGlideType,
    areSourceColumnsEqual,
    decomposeRelationType,
    isRelationType,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type ActionComponentDescription,
    type ArrayContentDescription,
    type LegacyPropertyDescription,
    type PropertyDescription,
    getSourceColumnProperty,
    getTableProperty,
    isPropertyDescription,
    makeStringProperty,
} from "@glide/app-description";
import { definedMap } from "@glideapps/ts-necessities";
import type { PropertyTable } from "./property/lib";
import { resolveSourceColumn, thisRowSourceColumn } from "./source-column";

export type ListSourcePropertyDescription = LegacyPropertyDescription | PropertyDescription;

export interface InlineListComponentDescription extends ActionComponentDescription, ArrayContentDescription {
    readonly propertyName: ListSourcePropertyDescription | undefined;
    // This weird type is just so we can use `getStringProperty` without having to cast.
    readonly caption: LegacyPropertyDescription | PropertyDescription | undefined;
    readonly format: PropertyDescription | undefined;
    readonly allowSearch?: PropertyDescription;
    readonly pageSize?: PropertyDescription;
    readonly forEasyTabConfiguration?: PropertyDescription;
    readonly multipleDynamicFilters?: PropertyDescription;
}

export function getTitlePropertyFromInlineListCaption(
    desc: InlineListComponentDescription
): PropertyDescription | undefined {
    if (isPropertyDescription(desc.caption)) {
        return desc.caption;
    }

    if (typeof desc.caption === "string") {
        return makeStringProperty(desc.caption);
    }

    return undefined;
}

export function getTableForRelationType(schema: SchemaInspector, columnType: ColumnType): TableGlideType | undefined {
    return definedMap(decomposeRelationType(columnType), r => schema.findTable(r.tableRef));
}

export function getInlineListPropertyTable(
    t: TableGlideType | undefined,
    p: ListSourcePropertyDescription | undefined,
    s: SchemaInspector
): PropertyTable | undefined {
    const sourceColumn = getSourceColumnProperty(p);
    if (sourceColumn !== undefined) {
        const resolved = resolveSourceColumn(s, sourceColumn, t, undefined, undefined);
        if (resolved === undefined) return undefined;
        if (resolved.type === undefined || !isRelationType(resolved.type)) {
            // logInfo("Inline List source is not a relation", sourceColumn, definedMap(t, getTableName));
            return undefined;
        }
        return definedMap(getTableForRelationType(s, resolved.type), tbl => ({
            table: tbl,
            inScreenContext: areSourceColumnsEqual(sourceColumn, thisRowSourceColumn),
        }));
    }

    const tableName = getTableProperty(p);
    if (tableName !== undefined) {
        return definedMap(s.findTable(tableName), tble => ({ table: tble, inScreenContext: false }));
    }

    return undefined;
}
