import { panic } from "@glideapps/ts-necessities";
import { logError } from "@glide/support";
import * as t from "io-ts";

export const baseRowIndexCodec = t.union([t.number, t.string]);
export type BaseRowIndex = t.TypeOf<typeof baseRowIndexCodec>;

export function isBaseRowIndex(x: unknown): x is BaseRowIndex {
    return typeof x === "number" || typeof x === "string";
}

export function checkBaseRowIndex(x: unknown): BaseRowIndex {
    if (!isBaseRowIndex(x)) {
        logError("Invalid row index", x);
        return panic("Row index must be a string or a number");
    }
    return x;
}

// FIXME: rename to something like `keyValueRowIndex`?
export const userSpecificRowIndexCodec = t.type({
    keyColumnName: t.string,
    // FIXME: Be more precise with this
    keyColumnValue: t.unknown,
});

export type UserSpecificRowIndex = t.TypeOf<typeof userSpecificRowIndexCodec>;

export const rowIndexCodec = t.union([
    baseRowIndexCodec,
    t.intersection([
        userSpecificRowIndexCodec,
        t.partial({
            rowIndexHint: baseRowIndexCodec,
        }),
    ]),
]);
export type RowIndex = t.TypeOf<typeof rowIndexCodec>;
