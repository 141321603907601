import type { Description, TableName } from "@glide/type-schema";
import { type ActionDescription, ActionKind } from "@glide/app-description";
import type { ActionsRecord } from "@glide/function-utils";
import type { ActionDescriptor } from "./action-descriptor";
import { type ActionHandler, actionAvailabilityBoth } from "./action-handler";

export class DummyActionHandler implements ActionHandler<ActionDescription> {
    public readonly kind = ActionKind.Dummy;
    public readonly needsData = false;
    public readonly availability = actionAvailabilityBoth;

    public readonly iconName = undefined;
    public readonly handlesSequencing = false;
    public readonly canAutoRun = false;
    public readonly appKinds = "both";
    public readonly name = "No action";

    public getTier(): undefined {
        return undefined;
    }

    public getBillablesConsumed(): undefined {
        return undefined;
    }

    public getIsApplicable(): boolean {
        return false;
    }

    public getIsIdempotent(): boolean {
        return true;
    }

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            properties: [],
            isLegacy: true,
        };
    }

    public getActions(): ActionsRecord {
        return { actions: [] };
    }

    public getDescriptionToWalk(desc: Description): ActionDescription {
        return desc as ActionDescription;
    }

    public getScreensUsed(): readonly string[] {
        return [];
    }

    public newActionDescription(): undefined {
        return undefined;
    }

    public rewriteAfterReload(): undefined {
        return undefined;
    }

    public getEditedColumns(): undefined {
        return undefined;
    }
    public getAdditionalTablesUsed(): readonly TableName[] {
        return [];
    }

    public getColumnAssignments(): undefined {
        return undefined;
    }

    public async duplicateAction(): Promise<undefined> {
        return undefined;
    }

    public async reuseOrDuplicateAction(): Promise<undefined> {
        return undefined;
    }

    public newDefaultActionDescription(): undefined {
        return undefined;
    }

    public fixActionDescription(desc: ActionDescription): ActionDescription {
        return desc;
    }

    public getTokenizedDescription(): undefined {
        return undefined;
    }
}
