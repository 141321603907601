import { AuthenticationMethod, signInAccessControlledEminence, type AuthenticationData } from "./Database";
import type { UserSettableAppFeatures } from "@glide/app-description";
import type { EminenceFlags } from "@glide/billing-types";
import type { GCPGmailIntegration } from "./integration-types";

export function getUsesVirtualEmailAddresses(
    appFeatures: UserSettableAppFeatures | undefined,
    eminenceFlags: EminenceFlags,
    authData: AuthenticationData
): boolean {
    // All plans allow virtual email addresses, so if they're turned on, we're
    // using them.
    if (appFeatures?.virtualEmailAddresses === true) return true;

    // The app doesn't want virtual emails, but its plan might not support
    // real emails.
    if (signInAccessControlledEminence(eminenceFlags.allowRealEmails, authData)) return false;
    return true;
}

export function getCanAppUserRequestAccess(
    appFeatures: UserSettableAppFeatures | undefined,
    eminenceFlags: EminenceFlags,
    authData: AuthenticationData
): boolean {
    if (appFeatures?.canAppUserRequestAccess === true && authData.kind !== AuthenticationMethod.UserProfileEmailPin) {
        return false;
    }
    return (
        signInAccessControlledEminence(eminenceFlags.canAppUserRequestAccess, authData) &&
        appFeatures?.canAppUserRequestAccess === true
    );
}

export async function canUseGmailForSendEmails(
    appFeatures: UserSettableAppFeatures | undefined,
    memberIntegrations: GCPGmailIntegration[]
): Promise<boolean> {
    return appFeatures?.useGmailForSendEmails !== undefined && memberIntegrations.length > 0;
}
