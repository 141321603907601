import { assertNever, mapFilterUndefined } from "@glideapps/ts-necessities";
import type { AppUserData } from "@glide/common-core/dist/js/components/datastore/data-row-store";
import { type Row, isLoadingValue } from "@glide/computation-model-types";
import { getRowColumn, isArrayValue } from "@glide/common-core/dist/js/computation-model/data";
import { type TableGlideType, getEmailOwnersColumnNames } from "@glide/type-schema";
import { makeRoleHash } from "@glide/common-core/dist/js/database-strings";
import { isArray, normalizeEmailAddress } from "@glide/support";
import { GlideDateTime } from "@glide/data-types";

function isRowOwnedByUserGeneral(
    appUserData: AppUserData | undefined,
    table: TableGlideType | undefined,
    getColumnValue: (columnName: string) => string | readonly string[] | undefined
): boolean {
    if (table === undefined) return true;

    const emailOwnersColumns = getEmailOwnersColumnNames(table, true);
    if (emailOwnersColumns.length === 0) return true;

    if (appUserData === undefined) return false;

    const { email, roles } = appUserData;

    function matches(s: string): boolean {
        if (normalizeEmailAddress(s) === email) return true;
        if (roles.size === 0) return false;
        const role = makeRoleHash(s);
        return role !== undefined && roles.has(role);
    }

    for (const columnName of emailOwnersColumns) {
        const emailOwners = getColumnValue(columnName);
        if (emailOwners === undefined) continue;
        if (typeof emailOwners === "string") {
            if (matches(emailOwners)) {
                return true;
            }
        } else if (isArray(emailOwners)) {
            for (const v of emailOwners) {
                if (matches(v)) {
                    return true;
                }
            }
        } else {
            assertNever(emailOwners);
        }
    }
    return false;
}

export function isRowOwnedByUser(
    row: Row,
    appUserData: AppUserData | undefined,
    table: TableGlideType | undefined
): boolean {
    return isRowOwnedByUserGeneral(appUserData, table, columnName => {
        const v = getRowColumn(row, columnName);
        if (typeof v === "string") {
            return v;
        }
        if (isLoadingValue(v)) return undefined;
        if (isArrayValue(v)) {
            return mapFilterUndefined(v, c => {
                if (typeof c !== "string") return undefined;
                return c;
            });
        }

        if (v instanceof GlideDateTime) {
            return v.getRepr();
        }

        return undefined;
    });
}
