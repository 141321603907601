import { getLocalizedString } from "@glide/localization";
import { launchNativeGoogleOauth2Flow } from "@glide/common-core/dist/js/native-hooks";
import { UIButtonAppearance } from "@glide/wire";
import type { MouseEventHandler } from "react";
import { css } from "styled-components";
import tw from "twin.macro";

import { getCredentials, getHTTPGoogleOAuth2RedirectURL } from "@glide/auth-controller-core";
import type { AuthProviderCallbacks, InteractionProps } from "./types";
import { GoogleLogo, authThemeAccent, authThemeDark, authThemeHighlight } from "@glide/common-components";
import { WireButton } from "../../../renderers/wire-button/wire-button";

interface WireGoogleAuthProviderButtonProps {
    userAgreed: InteractionProps["userAgreed"];
    appKind: InteractionProps["appKind"];
    createPopupForOAuth2Flow: AuthProviderCallbacks["createPopupForOAuth2Flow"];
}

export function WireGoogleAuthProviderButton(props: WireGoogleAuthProviderButtonProps): JSX.Element {
    const { userAgreed, appKind } = props;

    return (
        <GoogleSignInButton
            tw="mt-3 page-md:mt-4"
            userAgreed={userAgreed}
            appKind={appKind}
            onClick={async e => {
                e.preventDefault();
                // FIXME: This will need to be set on a per-app basis in the future.
                const clientID = getCredentials().clientId;
                if (await launchNativeGoogleOauth2Flow(clientID)) return;

                props.createPopupForOAuth2Flow(getHTTPGoogleOAuth2RedirectURL);
            }}
        />
    );
}

export function WireGoogleSignInButton(props: InteractionProps): JSX.Element {
    const { userAgreed, appKind } = props;

    return <GoogleSignInButton userAgreed={userAgreed} appKind={appKind} />;
}

interface GoogleSignInButtonProps extends InteractionProps {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className?: string;
}

function GoogleSignInButton(props: GoogleSignInButtonProps): JSX.Element {
    const { userAgreed, appKind, onClick, className } = props;

    return (
        <>
            <div
                css={css`
                    ${tw`h-px mx-3 my-3 bg-n100 page-md:my-4`}

                    .${authThemeHighlight} &,
                    .${authThemeAccent}.mobile & {
                        ${tw`bg-n200A`}
                    }
                    .has-background-image &,
                    .${authThemeDark} & {
                        ${tw`bg-w10A`}
                    }
                `}></div>
            <WireButton
                className={className}
                tw="w-full h-12 rounded-[10px] hover:after:([background: rgba(0,0,0,0.02)])"
                id="sign-in-with-google-button"
                appearance={UIButtonAppearance.Bordered}
                onClick={onClick}
                disabled={!userAgreed}
                css={css`
                    .has-background-image &,
                    .${authThemeDark} &,
                    .${authThemeAccent}.mobile & {
                        box-shadow: none;
                        ${tw`bg-w20A`}
                    }
                    .${authThemeHighlight} &,
                    .${authThemeAccent} & {
                        box-shadow: none;
                        ${tw`bg-n100`}
                    }
                    .${authThemeAccent}.mobile & {
                        ${tw`text-white bg-w20A`}
                    }
                `}>
                <div tw="flex justify-center items-center cursor-pointer">
                    <GoogleLogo fill={undefined} />
                    <label
                        css={css`
                            .${authThemeDark} & {
                                ${tw`text-white`}
                            }
                        `}
                        tw="ml-2.5 pointer-events-none">
                        {getLocalizedString("continueWithGoogle", appKind)}
                    </label>
                </div>
            </WireButton>
        </>
    );
}
