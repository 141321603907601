import { definedMap } from "@glideapps/ts-necessities";
import { type LoadingValue, isLoadingValue } from "./loading-value";

export type Unbound = null;
export const UnboundVal = null;

export function isBound<T>(x: T | Unbound): x is T {
    return x !== UnboundVal;
}

export function boundMap<T, U>(x: T | Unbound | LoadingValue | undefined, f: (x: T) => U): U | Unbound | undefined {
    if (isLoadingValue(x)) {
        return undefined;
    } else if (isBound(x)) {
        return definedMap(x, f);
    } else {
        return x;
    }
}
