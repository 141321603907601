import { type Row, type GroundValue, isLoadingValue, type QuerySort } from "@glide/computation-model-types";
import {
    asString,
    nullLoadingToUndefined,
    asMaybeNumber,
    asBoolean,
    asMaybeArrayOfStrings,
} from "@glide/common-core/dist/js/computation-model/data";
import {
    type TableGlideType,
    getTableColumn,
    getTableColumnDisplayName,
    getTableName,
    isPrimitiveArrayType,
    isPrimitiveType,
} from "@glide/type-schema";
import {
    ArrayScreenFormat,
    getActionProperty,
    getArrayProperty,
    getColumnProperty,
    getEnumProperty,
    getNumberProperty,
    getSourceColumnProperty,
    getSwitchProperty,
} from "@glide/app-description";
import {
    type WireNewDataGridRow,
    type WireNewDataGridRowHeaderCell,
    type WireNewDataGridColumn,
    type WireNewDataGridChoiceCell,
    type WireNewDataGridTagsCell,
    type WireNewDataGridCell,
    type WireNewDataGridTextCell,
    type WireNewDataGridNumberCell,
    type WireNewDataGridBooleanCell,
    type WireNewDataGridButtonCell,
    type WireActionWithTitle,
    type WireNewDataGridExtraActionsCell,
    type SuperTableViewWindow,
    type WireNewDataGridListComponent,
    isSuperTableViewWindow,
} from "@glide/fluent-components/dist/js/base-components";
import {
    type ConfigurableNewDataGridColumnKind,
    type ConfigurableNewDataGridColumnKindWithAuto,
    type NewDataGridColumnDescription,
    NewDataGrid,
    glideTypeToNewDataGridColumnMapping,
} from "@glide/fluent-components/dist/js/fluent-components";
import {
    type InlineListComponentDescription,
    emptyEditedColumnsAndTables,
    getTableAndColumnForSourceColumn,
} from "@glide/function-utils";
import {
    type WireActionHydrator,
    type WireRowComponentHydrationBackend,
    type WireTableComponentHydrationBackend,
    type WireTableComponentHydrationResult,
    type WireValueGetterGeneric,
    type WireAction,
    type WireEditableValue,
    type WireAlwaysEditableValue,
    type WirePaging,
    type WireTableGetter,
    type WirePredicate,
    WireActionResult,
    WireComponentKind,
} from "@glide/wire";
import {
    applyPaging,
    doTitleActionsForceComponentToShow,
    getItemsPageIndex,
    getPageSizeForPaging,
    hydrateAndRegisterAction,
    inflateActions,
    inflateActionsWithTitles,
    inflateComponentEnricher,
    inflateStringProperty,
    inflateSwitchWithCondition,
    makeSimpleWireTableComponentHydratorConstructor,
    registerActionRunner,
} from "../wire/utils";
import { makeFluentArrayContentHandler } from "./fluent-array-handler";
import { assertNever, defined, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import type {
    NewDataGridBlankCell,
    NewDataGridColumn,
    NewDataGridImageCell,
    NewDataGridLinkCell,
} from "@glide/component-utils";
import { iterableEnumerate } from "collection-utils";
import { sortAndLimitQuery, makeSortByKeyAction } from "./super-table-array-content";
import { makeGroupByGetters } from "../array-screens/array-content";
import {
    type BaseTagOrChoiceItem,
    getBaseChoiceItems,
    inflateBaseChoiceColumn,
    makeWireTagOrChoiceItemFromBase,
} from "./new-table-shared";
import { isArray, isEmptyOrUndefined, isUndefinedish } from "@glide/support";

interface InflatedColumn {
    readonly index: number;
    readonly isVisibleGetter: WirePredicate;
    readonly headerGetter: WireValueGetterGeneric<string>;
    readonly defaultHeader: string;
    readonly kind: ConfigurableNewDataGridColumnKind;
    // We're tracking the first text column so we can make it a row-header
    readonly isFirstText: boolean;
    readonly sizeOptions: NewDataGridColumn["sizeOptions"];
    readonly columnName: string | undefined;
    readonly isEditable: boolean;
    readonly valueGetter: WireValueGetterGeneric<GroundValue>;
    readonly formattedValueGetter: WireValueGetterGeneric<string>;
    readonly displayValueGetter: WireValueGetterGeneric<string>;
    readonly onClickActionGetter: WireActionHydrator | WireActionResult;
    readonly choicesAreConstant: boolean; // Same choices for all rows?
    readonly choiceSourceGetter: WireTableGetter | undefined;
    readonly choiceSourceTableType: TableGlideType | undefined;
    readonly choiceValuesGetter: WireValueGetterGeneric<string> | undefined;
    readonly choiceColorGetter: WireValueGetterGeneric<string> | undefined;
    readonly tagColorKind: "Auto" | "Manual";
    readonly choiceDisplayGetter: WireValueGetterGeneric<string> | undefined;
    readonly choiceTokenMaker: (hb: WireRowComponentHydrationBackend) => string | false | undefined;
    readonly isMultiChoice: boolean;
    // We don't support sorting by computed columns on GBT. We use this to prevent sorting on them.
    readonly canSort: boolean;
}

interface ColumnWithChoiceOptions extends InflatedColumn {
    readonly options: BaseTagOrChoiceItem[];
}

function hydrateWireNewDataGridCell(
    rhb: WireRowComponentHydrationBackend,
    chb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number,
    hasItemAction: boolean,
    itemClickAction: WireAction | undefined
): WireNewDataGridCell {
    const { kind } = c;
    if (c.isFirstText && hasItemAction) {
        return hydrateWireNewDataGridRowHeaderCell(rhb, c, rowIdx, itemClickAction);
    }

    switch (kind) {
        case "text":
            return hydrateWireNewDataGridTextCell(rhb, c, rowIdx);

        case "number": {
            return hydrateWireNewDataGridNumberCell(rhb, c, rowIdx);
        }

        case "boolean": {
            return hydrateWireNewDataGridBooleanCell(rhb, c, rowIdx);
        }

        case "image": {
            return hydrateWireNewDataGridImageCell(rhb, c);
        }

        case "link": {
            return hydrateWireNewDataGridLinkCell(rhb, c);
        }

        case "button": {
            return hydrateWireNewDataGridButtonCell(rhb, c, rowIdx);
        }

        case "choice": {
            return hydrateWireNewDataGridChoiceCell(rhb, chb, c, rowIdx);
        }

        case "tag": {
            return hydrateWireNewDataGridTagsCell(rhb, c, rowIdx);
        }

        default: {
            assertNever(kind);
        }
    }
}

function makeOnChangeToken(
    rhb: WireRowComponentHydrationBackend,
    columnName: string | undefined,
    rowIdx: number
): string | undefined {
    const maybeFalseyOnChangeToken = definedMap(columnName, n =>
        rhb.registerOnValueChange(`set-${columnName}-${rowIdx}`, n)
    );

    const onChangeToken = maybeFalseyOnChangeToken === false ? undefined : maybeFalseyOnChangeToken;

    return onChangeToken;
}

function hydrateWireNewDataGridRowHeaderCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number,
    primaryAction: WireAction | undefined
): WireNewDataGridRowHeaderCell {
    const value = asString(nullLoadingToUndefined(c.valueGetter(rhb)));
    const formattedValue = c.formattedValueGetter(rhb);
    const displayValue = c.displayValueGetter(rhb);

    const { columnName, isEditable } = c;

    const editableValue: WireEditableValue<string> = {
        value,
        displayValue: displayValue ?? formattedValue ?? value,
        onChangeToken: isEditable ? makeOnChangeToken(rhb, columnName, rowIdx) : undefined,
    };

    return {
        kind: "row-header",
        editableValue,
        action: primaryAction,
    };
}

function hydrateWireNewDataGridTextCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number
): WireNewDataGridTextCell {
    const value = asString(nullLoadingToUndefined(c.valueGetter(rhb)));
    const formattedValue = c.formattedValueGetter(rhb);
    const displayValue = c.displayValueGetter(rhb);

    const { columnName, isEditable } = c;

    const editableValue: WireEditableValue<string> = {
        value,
        displayValue: displayValue ?? formattedValue ?? value,
        onChangeToken: isEditable ? makeOnChangeToken(rhb, columnName, rowIdx) : undefined,
    };

    return {
        kind: "text",
        editableValue,
    };
}

function hydrateWireNewDataGridNumberCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number
): WireNewDataGridNumberCell {
    const value = asMaybeNumber(nullLoadingToUndefined(c.valueGetter(rhb))) ?? 0;
    const formattedValue = c.formattedValueGetter(rhb);
    const displayValue = c.displayValueGetter(rhb);

    const { columnName, isEditable } = c;

    const editableValue: WireEditableValue<number> = {
        value: value,
        displayValue: displayValue ?? formattedValue ?? value.toString(),
        onChangeToken: isEditable ? makeOnChangeToken(rhb, columnName, rowIdx) : undefined,
    };

    return {
        kind: "number",
        editableValue,
    };
}

function hydrateWireNewDataGridBooleanCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number
): WireNewDataGridBooleanCell {
    const value = asBoolean(nullLoadingToUndefined(c.valueGetter(rhb)));

    const { columnName, isEditable } = c;

    const editableValue: WireEditableValue<boolean> = {
        value: value,
        onChangeToken: isEditable ? makeOnChangeToken(rhb, columnName, rowIdx) : undefined,
    };

    return {
        kind: "boolean",
        editableValue,
    };
}

function hydrateWireNewDataGridImageCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions
): NewDataGridImageCell {
    const loaded = nullLoadingToUndefined(c.valueGetter(rhb));
    const value: string | string[] = asMaybeArrayOfStrings(loaded) ?? asString(loaded);

    return {
        kind: "image",
        value,
    };
}

function hydrateWireNewDataGridLinkCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions
): NewDataGridLinkCell {
    const value = asString(nullLoadingToUndefined(c.valueGetter(rhb)));
    const formattedValue = c.formattedValueGetter(rhb);
    const displayValue = c.formattedValueGetter(rhb);

    return {
        kind: "link",
        value,
        displayValue: displayValue ?? formattedValue ?? value,
    };
}

function hydrateWireNewDataGridButtonCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number
): WireNewDataGridButtonCell {
    const value = asString(nullLoadingToUndefined(c.valueGetter(rhb)));
    const formattedValue = c.formattedValueGetter(rhb);
    const action = hydrateAndRegisterAction(
        `onClick-${c.index}-${rowIdx}`,
        c.onClickActionGetter,
        rhb,
        false,
        undefined
    );

    return {
        kind: "button",
        label: formattedValue ?? value,
        action,
    };
}

function hydrateWireNewDataGridExtraActionsCell(
    extraActions: readonly WireActionWithTitle[]
): WireNewDataGridExtraActionsCell {
    return {
        kind: "extra-actions",
        value: undefined,
        actions: extraActions,
    };
}

const wireNewDataGridBlankCell: NewDataGridBlankCell = { kind: "blank", value: undefined };

function hydrateWireNewDataGridChoiceCell(
    rhb: WireRowComponentHydrationBackend,
    chb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number
): WireNewDataGridChoiceCell {
    let items: BaseTagOrChoiceItem[];
    if (c.choicesAreConstant) {
        items = c.options;
    } else {
        items = getBaseChoiceItems(rhb, chb, c);
    }

    const rawValue = nullLoadingToUndefined(c.valueGetter(rhb));
    const value = isArray(rawValue)
        ? rawValue.map(v => asString(v))
        : asString(rawValue)
              .split(",")
              .filter(v => !isEmptyOrUndefined(v));

    const options = makeWireTagOrChoiceItemFromBase(rhb, c, items, value, c.isMultiChoice, rowIdx);

    return {
        kind: "choice",
        value,
        isEditable: c.isEditable,
        options,
        isMulti: c.isMultiChoice,
    };
}

function hydrateWireNewDataGridTagsCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number
): WireNewDataGridTagsCell {
    const rawValue = nullLoadingToUndefined(c.valueGetter(rhb));
    const value = isArray(rawValue)
        ? rawValue.map(v => asString(v))
        : asString(rawValue)
              .split(",")
              .filter(v => !isEmptyOrUndefined(v));

    const options = makeWireTagOrChoiceItemFromBase(rhb, c, c.options, value, c.isMultiChoice, rowIdx);

    return {
        kind: "tag",
        value,
        tagColorKind: c.tagColorKind,
        isEditable: c.isEditable,
        options,
        isMulti: c.isMultiChoice,
    };
}

export const dataGridFluentArrayContentHandler = makeFluentArrayContentHandler(
    NewDataGrid,
    (ib, desc, containingRowIB) => {
        if (containingRowIB === undefined) return undefined;

        const tableName = getTableName(ib.tables.input);
        const allowDeletingRows = getSwitchProperty(desc.allowDeletingRows) ?? false;

        const columnDescs = getArrayProperty<NewDataGridColumnDescription>(desc.columns);
        const [componentTitleGetter] = inflateStringProperty(containingRowIB, desc.title, true);
        const [titleActionsHydrator] = inflateActionsWithTitles(containingRowIB, desc.titleActions, "title");

        const itemAction = getActionProperty(desc.action);
        const itemActionGetter = definedMap(itemAction, x => inflateActions(ib, [x])) ?? WireActionResult.nothingToDo();
        const hasItemAction = itemAction !== undefined;

        const [itemActionsHydrator, configuredItemActions] = inflateActionsWithTitles(ib, desc.itemActions, "item");

        if (columnDescs === undefined || columnDescs.length === 0) {
            return undefined;
        }

        const groupByGetters = makeGroupByGetters(ib, desc, NewDataGrid.spec.groupingSupport);

        // Everything needed to register the actions and hydrate values
        const inflatedColumns: InflatedColumn[] = [];

        // We want to mark the first text column so we can make it a row-header column
        let alreadyInflatedATextColumn = false;
        for (const [key, c] of iterableEnumerate(columnDescs)) {
            const viewOrEdit = getEnumProperty<"View" | "Edit">(c.editOrView) ?? "Edit";

            const {
                valueGetter,
                formattedValueGetter,
                type,
                choicesAreConstant,
                choiceSourceGetter,
                choiceSourceTableType,
                choiceValuesGetter,
                choiceColorGetter,
                choiceDisplayGetter,
                columnName,
                canSort,
                choiceTokenMaker,
                isEditable,
            } = inflateBaseChoiceColumn(ib, containingRowIB.tables, {
                value: c.value,
                choiceSource: c.choiceSource,
                choiceValues: c.choiceValues,
                choiceColor: c.choiceColor,
                choiceDisplay: c.choiceDisplay,
                viewOrEdit,
            });

            const [displayValueGetter] = inflateStringProperty(ib, c.displayValue, true);
            const tagColorKind = getEnumProperty<"Auto" | "Manual">(c.tagColor) ?? "Auto";

            const isMultiChoice = getSwitchProperty(c.isMultiChoice) ?? false;

            const onClickAction = getActionProperty(c.onClick);
            const onClickActionGetter =
                definedMap(onClickAction, x => inflateActions(ib, [x])) ?? WireActionResult.nothingToDo();

            if (!(isPrimitiveType(type) || (type !== undefined && isPrimitiveArrayType(type)))) continue;

            const isVisibleGetter = inflateSwitchWithCondition(containingRowIB, c.isVisible, true);

            // This is how we evaluate in the scope of the containing row
            const [headerGetter] = inflateStringProperty(containingRowIB, c.title, true);
            let defaultHeader = "";
            let maybeColumn = definedMap(getSourceColumnProperty(c.value), sc =>
                getTableAndColumnForSourceColumn(ib.adc, sc, ib.tables.input, undefined)
            )?.column;
            if (maybeColumn === undefined) {
                maybeColumn = definedMap(getSourceColumnProperty(c.displayValue), sc =>
                    getTableAndColumnForSourceColumn(ib.adc, sc, ib.tables.input, undefined)
                )?.column;
            }
            if (maybeColumn !== undefined) {
                defaultHeader = getTableColumnDisplayName(maybeColumn);
            }

            const choosenKind = getEnumProperty<ConfigurableNewDataGridColumnKindWithAuto>(c.kind) ?? "auto";
            const kind = glideTypeToNewDataGridColumnMapping(type, choosenKind);
            const sizeKind = getEnumProperty<NewDataGridColumn["sizeOptions"]["sizeKind"]>(c.sizeKind) ?? "auto";
            const fixedWidthColumnSize = getNumberProperty(c.widthSize);
            const autoWidthColumnRatio = getNumberProperty(c.widthRatio);

            if (kind === undefined || sizeKind === undefined) {
                // This will never happen. This is just a TS and fluent component thing.
                continue;
            }

            const sizeOptions: NewDataGridColumn["sizeOptions"] =
                sizeKind === "auto"
                    ? {
                          sizeKind,
                          widthRatio: autoWidthColumnRatio ?? 1.0,
                      }
                    : {
                          sizeKind,
                          size: fixedWidthColumnSize ?? 200,
                      };

            inflatedColumns.push({
                index: key,
                isVisibleGetter,
                headerGetter,
                defaultHeader,
                kind,
                isFirstText: kind === "text" && !alreadyInflatedATextColumn,
                sizeOptions,
                columnName,
                isEditable,
                valueGetter,
                formattedValueGetter,
                displayValueGetter,
                onClickActionGetter,
                canSort,
                choicesAreConstant,
                choiceSourceGetter,
                choiceSourceTableType,
                choiceValuesGetter,
                choiceColorGetter,
                tagColorKind,
                choiceDisplayGetter,
                choiceTokenMaker,
                isMultiChoice,
            });

            if (kind === "text") {
                alreadyInflatedATextColumn = true;
            }
        }

        if (inflatedColumns.length === 0) {
            return undefined;
        }

        const selectedPageSize = defined(
            getPageSizeForPaging(
                getNumberProperty((desc as unknown as InlineListComponentDescription).pageSize),
                24,
                false
            )
        );

        const componentEnricher = inflateComponentEnricher<WireNewDataGridListComponent>(
            ib,
            desc as unknown as InlineListComponentDescription
        );

        function makeComponent(
            rows: readonly Row[],
            viewWindow: WireAlwaysEditableValue<SuperTableViewWindow>,
            paging: WirePaging,
            thb: WireTableComponentHydrationBackend,
            chb: WireRowComponentHydrationBackend,
            shouldSort: boolean
        ): WireTableComponentHydrationResult {
            const title = componentTitleGetter(chb);

            const columnsWithOptions = mapFilterUndefined(inflatedColumns, c => {
                const isVisible = c.isVisibleGetter(chb);
                if (isLoadingValue(isVisible) || !isVisible) return undefined;

                const col: ColumnWithChoiceOptions = {
                    ...c,
                    options: c.choicesAreConstant ? getBaseChoiceItems(chb, chb, c) : [],
                };

                return col;
            });

            const gridRows = rows.map((row, rowIdx) => {
                const rhb = thb.makeHydrationBackendForRow(row);
                const itemActions = itemActionsHydrator?.(rhb, row.$rowID) ?? [];
                const activeItemActions = itemActions.filter(i => !isUndefinedish(i.action));
                const itemClickAction = hydrateAndRegisterAction("itemAction", itemActionGetter, rhb, false, undefined);

                const wireRow: WireNewDataGridRow = {
                    cells: [],
                    deleteAction: allowDeletingRows
                        ? registerActionRunner(rhb, `delete-${row.$rowID}`, async ab =>
                              WireActionResult.fromResult(await ab.deleteRows(tableName, [row], true))
                          )
                        : undefined,
                };
                for (const c of columnsWithOptions) {
                    wireRow.cells.push(hydrateWireNewDataGridCell(rhb, chb, c, rowIdx, hasItemAction, itemClickAction));
                }

                // Extra actions go in the last column's menu, make cells for it
                if (itemActions.length > 0) {
                    wireRow.cells.push(
                        activeItemActions.length > 0
                            ? hydrateWireNewDataGridExtraActionsCell(activeItemActions)
                            : wireNewDataGridBlankCell
                    );
                }

                return wireRow;
            });

            const sortByKey = thb.getState<QuerySort | undefined>("sortByKey", undefined, undefined, false);
            const columns: WireNewDataGridColumn[] = columnsWithOptions.map(c => {
                const { kind, headerGetter, defaultHeader, sizeOptions, columnName, canSort, isEditable, isFirstText } =
                    c;

                const headerAction =
                    shouldSort && canSort ? makeSortByKeyAction(thb, columnName ?? "", sortByKey) : undefined;

                const kindWithFirstText: WireNewDataGridColumn["kind"] =
                    isFirstText && hasItemAction ? "row-header" : kind;

                let header = headerGetter(chb) ?? "";
                if (header === "") {
                    header = defaultHeader;
                }

                return {
                    kind: kindWithFirstText,
                    header,
                    sizeOptions,
                    headerAction,
                    columnName,
                    canSort,
                    isEditable,
                };
            });

            // Don't forget to add the column for the extra actions menu
            if (configuredItemActions > 0) {
                columns.push({
                    kind: "extra-actions",
                    header: "",
                    sizeOptions: {
                        sizeKind: "fixed",
                        size: 50,
                    },
                    columnName: undefined,
                    headerAction: undefined,
                    isEditable: false,
                });
            }

            const titleActions = titleActionsHydrator?.(chb, "") ?? [];

            const component: WireNewDataGridListComponent = componentEnricher({
                kind: WireComponentKind.List,
                format: ArrayScreenFormat.NewDataGrid,
                columns,
                rows: gridRows,
                title,
                titleActions,
                hasNoMoreRows: true,
                viewWindow,
                paging,
            });

            return {
                component,
                isValid: true,
            };
        }

        function getViewWindow(chb: WireRowComponentHydrationBackend): WireAlwaysEditableValue<SuperTableViewWindow> {
            return chb.getState<SuperTableViewWindow>(
                "super-table-window",
                isSuperTableViewWindow,
                { start: 0, end: 500, maxRequested: 500 },
                false
            );
        }

        return makeSimpleWireTableComponentHydratorConstructor(
            ib,
            (thb, chb, searchActive) => {
                if (chb === undefined) {
                    return undefined;
                }

                const rows = thb.tableScreenContext.asArray();
                const titleActions = titleActionsHydrator?.(chb, "") ?? [];
                const showIfEmpty = doTitleActionsForceComponentToShow(titleActions);

                if (rows.length === 0 && !searchActive && !showIfEmpty) {
                    return undefined;
                }

                const viewWindow = getViewWindow(chb);

                // If you want infinite scrolling, add these guys:
                // const { maxRequested } = viewWindow.value;
                // const windowedRows = rows.slice(0, maxRequested);
                // const hasNoMoreRows = rows.length < maxRequested;
                const pageIndexState = getItemsPageIndex(chb, false, "");
                const [paging, pagedRows] = applyPaging(rows, pageIndexState, selectedPageSize);

                return makeComponent(pagedRows, viewWindow, paging, thb, chb, false);
            },
            (chb, query, thb, searchActive) => {
                if (thb === undefined) {
                    return undefined;
                }

                const pageIndexState = getItemsPageIndex(chb, false, "");
                const { maybeGroupAggregateQuery } = sortAndLimitQuery(
                    ib,
                    query,
                    thb,
                    selectedPageSize,
                    pageIndexState,
                    groupByGetters,
                    desc.transforms,
                    NewDataGrid
                );
                query = maybeGroupAggregateQuery;

                const table = chb.resolveQueryAsTable(query);
                const titleActions = titleActionsHydrator?.(chb, "") ?? [];
                const showIfEmpty = doTitleActionsForceComponentToShow(titleActions);

                if (table === undefined && !searchActive && !showIfEmpty) {
                    return undefined;
                }

                const isEmpty = isLoadingValue(table) || table === undefined;
                const rows = isEmpty ? [] : table.asMutatingArray();
                const viewWindow = getViewWindow(chb);
                const [paging, pagedRows] = applyPaging(rows, pageIndexState, selectedPageSize);

                return makeComponent(pagedRows, viewWindow, paging, thb, chb, true);
            }
        );
    },
    (desc, _tables, itemTable, _adc) => {
        let edited = emptyEditedColumnsAndTables;

        if (itemTable === undefined) return edited;

        const columnDescs = getArrayProperty<NewDataGridColumnDescription>(desc.columns) ?? [];
        const allowDeletingRows = getSwitchProperty(desc.allowDeletingRows) ?? false;

        if (allowDeletingRows) {
            edited = {
                ...edited,
                deletedTables: [getTableName(itemTable)],
            };
        }

        edited = {
            ...edited,
            editedColumns: mapFilterUndefined(columnDescs, c => {
                const viewOrEdit = getEnumProperty<"View" | "Edit">(c.editOrView) ?? "Edit";
                const isEditable = viewOrEdit === "Edit";
                if (!isEditable) return undefined;

                const columnName = getColumnProperty(c.value);
                if (columnName === undefined) return undefined;

                const column = getTableColumn(itemTable, columnName);
                if (column === undefined) return undefined;

                return [columnName, false, false, getTableName(itemTable)];
            }),
        };

        return edited;
    }
);
