import type { GlideIconProps } from "@glide/plugins";
import { type EditedColumnKind, SyntheticColumnKind, DataColumnKind } from "@glide/formula-specifications";
import { assertNever } from "@glideapps/ts-necessities";

export const nameForSyntheticColumnKind: Record<SyntheticColumnKind, string> = {
    [SyntheticColumnKind.FilterReference]: "Relation",
    [SyntheticColumnKind.IfThenElse]: "If → Then → Else",
    [SyntheticColumnKind.Lookup]: "Lookup",
    [SyntheticColumnKind.SingleValue]: "Single Value",
    [SyntheticColumnKind.TextTemplate]: "Template",
    [SyntheticColumnKind.Math]: "Math",
    [SyntheticColumnKind.Rollup]: "Rollup",
    [SyntheticColumnKind.JoinStrings]: "Joined List",
    [SyntheticColumnKind.SplitString]: "Split Text",
    [SyntheticColumnKind.MakeArray]: "Make Array",
    [SyntheticColumnKind.GeoDistance]: "Distance",
    [SyntheticColumnKind.GenerateImage]: "Generate Image",
    [SyntheticColumnKind.UserAPIFetch]: "API",
    [SyntheticColumnKind.ConstructURL]: "Construct URL",
    [SyntheticColumnKind.YesCode]: "Experimental Code",
    [SyntheticColumnKind.FilterSortLimit]: "Query",
    // This one should never show up in the UI
    [SyntheticColumnKind.PluginComputation]: "Computation from Integration",
};

function getHeaderIconColorForMonotoneIcon(icon: string): { fgColor: string; bgColor: string } | undefined {
    switch (icon) {
        case "mt-column-generate-text":
        case "mt-column-choice":
        case "mt-column-text-to-date":
        case "mt-column-boolean":
        case "mt-column-number":
        case "mt-column-audio":
        case "mt-column-image":
        case "mt-column-document":
        case "mt-column-json":
            return {
                fgColor: "var(--gv-icon-base)",
                bgColor: "var(--gv-pink500)",
            };

        case "mt-header-reference":
        case "mt-header-if-then-else":
        case "mt-header-lookup":
        case "mt-header-single-value":
        case "mt-header-text-template":
        case "mt-header-math":
        case "mt-header-rollup":
        case "mt-header-join-strings":
        case "mt-header-split-string":
        case "mt-header-make-array":
        case "mt-header-geo-distance":
        case "mt-header-generate-image":
        case "mt-header-globe":
        case "mt-header-code":
        case "mt-header-filter-sort-limit":
        case "mt-header-uri":
            return {
                fgColor: "var(--gv-icon-base)",
                bgColor: "var(--gv-aqua500)",
            };
        default:
            return undefined;
    }
}

export function getHeaderIconForEditedColumn(kind: EditedColumnKind): GlideIconProps {
    switch (kind) {
        case SyntheticColumnKind.FilterReference:
            return {
                kind: "monotone",
                icon: "mt-header-reference",
                ...getHeaderIconColorForMonotoneIcon("mt-header-reference"),
            };
        case SyntheticColumnKind.IfThenElse:
            return {
                kind: "monotone",
                icon: "mt-header-if-then-else",
                ...getHeaderIconColorForMonotoneIcon("mt-header-if-then-else"),
            };
        case SyntheticColumnKind.Lookup:
            return {
                kind: "monotone",
                icon: "mt-header-lookup",
                ...getHeaderIconColorForMonotoneIcon("mt-header-lookup"),
            };
        case SyntheticColumnKind.Math:
            return {
                kind: "monotone",
                icon: "mt-header-math",
                ...getHeaderIconColorForMonotoneIcon("mt-header-math"),
            };
        case SyntheticColumnKind.SingleValue:
            return {
                kind: "monotone",
                icon: "mt-header-single-value",
                ...getHeaderIconColorForMonotoneIcon("mt-header-single-value"),
            };
        case SyntheticColumnKind.TextTemplate:
            return {
                kind: "monotone",
                icon: "mt-header-text-template",
                ...getHeaderIconColorForMonotoneIcon("mt-header-text-template"),
            };
        case SyntheticColumnKind.Rollup:
            return {
                kind: "monotone",
                icon: "mt-header-rollup",
                ...getHeaderIconColorForMonotoneIcon("mt-header-rollup"),
            };
        case SyntheticColumnKind.JoinStrings:
            return {
                kind: "monotone",
                icon: "mt-header-join-strings",
                ...getHeaderIconColorForMonotoneIcon("mt-header-join-strings"),
            };
        case SyntheticColumnKind.SplitString:
            return {
                kind: "monotone",
                icon: "mt-header-split-string",
                ...getHeaderIconColorForMonotoneIcon("mt-header-split-string"),
            };
        case SyntheticColumnKind.MakeArray:
            return {
                kind: "monotone",
                icon: "mt-header-make-array",
                ...getHeaderIconColorForMonotoneIcon("mt-header-make-array"),
            };
        case SyntheticColumnKind.GeoDistance:
            return {
                kind: "monotone",
                icon: "mt-header-geo-distance",
                ...getHeaderIconColorForMonotoneIcon("mt-header-geo-distance"),
            };
        case SyntheticColumnKind.GenerateImage:
            return {
                kind: "monotone",
                icon: "mt-header-generate-image",
                ...getHeaderIconColorForMonotoneIcon("mt-header-generate-image"),
            };
        case SyntheticColumnKind.UserAPIFetch:
            return {
                kind: "monotone",
                icon: "mt-header-globe",
                ...getHeaderIconColorForMonotoneIcon("mt-header-globe"),
            };
        case SyntheticColumnKind.ConstructURL:
            return {
                kind: "monotone",
                icon: "mt-header-uri",
                ...getHeaderIconColorForMonotoneIcon("mt-header-uri"),
            };
        case SyntheticColumnKind.YesCode:
            return {
                kind: "monotone",
                icon: "mt-header-code",
                ...getHeaderIconColorForMonotoneIcon("mt-header-code"),
            };
        case SyntheticColumnKind.PluginComputation:
            return {
                kind: "monotone",
                icon: "mt-header-code",
                ...getHeaderIconColorForMonotoneIcon("mt-header-code"),
            };
        case DataColumnKind.Regular:
            return {
                kind: "monotone",
                icon: "mt-header-string",
                ...getHeaderIconColorForMonotoneIcon("mt-header-string"),
            };
        case DataColumnKind.UserSpecific:
            return {
                kind: "monotone",
                icon: "mt-header-string",
                ...getHeaderIconColorForMonotoneIcon("mt-header-string"),
            };
        case DataColumnKind.RowID:
            return {
                kind: "monotone",
                icon: "mt-header-row-id",
                ...getHeaderIconColorForMonotoneIcon("mt-header-row-id"),
            };
        case SyntheticColumnKind.FilterSortLimit:
            return {
                kind: "monotone",
                icon: "mt-header-filter-sort-limit",
                ...getHeaderIconColorForMonotoneIcon("mt-header-filter-sort-limit"),
            };
        default:
            assertNever(kind);
    }
}

export function getHeaderIconNameForEditedColumn(kind: EditedColumnKind): string {
    switch (kind) {
        case SyntheticColumnKind.FilterReference:
            return "headerReference";
        case SyntheticColumnKind.IfThenElse:
            return "headerIfThenElse";
        case SyntheticColumnKind.Lookup:
            return "headerLookup";
        case SyntheticColumnKind.Math:
            return "headerMath";
        case SyntheticColumnKind.SingleValue:
            return "headerSingleValue";
        case SyntheticColumnKind.TextTemplate:
            return "headerTextTemplate";
        case SyntheticColumnKind.Rollup:
            return "headerRollup";
        case SyntheticColumnKind.JoinStrings:
            return "headerJoinStrings";
        case SyntheticColumnKind.SplitString:
            return "headerSplitString";
        case SyntheticColumnKind.MakeArray:
            return "headerMakeArray";
        case SyntheticColumnKind.GeoDistance:
            return "headerGeoDistance";
        case SyntheticColumnKind.GenerateImage:
            return "headerImage";
        case SyntheticColumnKind.UserAPIFetch:
            return "headerGlobe";
        case SyntheticColumnKind.ConstructURL:
            return "headerUri";
        case SyntheticColumnKind.YesCode:
            return "headerCode";
        case SyntheticColumnKind.PluginComputation:
            // FIXME: proper icon
            return "headerCode";
        case DataColumnKind.Regular:
            return "headerString";
        case DataColumnKind.UserSpecific:
            return "headerString";
        case DataColumnKind.RowID:
            return "headerRowID";
        case SyntheticColumnKind.FilterSortLimit:
            return "headerFilterSortLimit";
        default:
            assertNever(kind);
    }
}
