import { DateFormat, TimeFormat, formatDateTime } from "@glide/data-types";
import type { AppKind } from "@glide/location-common";
import { checkString } from "@glide/support";
import { hasOwnProperty } from "@glideapps/ts-necessities";
import { localizedStringsEnglishGlobalInstance, localizedStringsGlobalInstance } from "./base";
import {
    type DevStringKey as InternalDevStringKey,
    getDevLocalizedString,
    intentionallyUntranslatedLocalizedDevStrings,
    localizedDevStrings,
} from "./dev";
import { englishLocalizedStrings } from "./english";
import type { LocalizedStringKey as InternalLocalizedStringKey } from "./types";

type LocalizationDomain = AppKind | "builder";

function shouldTranslate(domain: LocalizationDomain): boolean {
    return domain !== "builder";
}

// We have "properly" configured our TypeScript compiler to not re-export types.
// That's too bad TypeScript, I'm re-exporting these types anyway.
export type LocalizedStringKey = InternalLocalizedStringKey;
export type DevStringKey = InternalDevStringKey;

export function getLocalizedString(name: LocalizedStringKey, domain: LocalizationDomain): string {
    if (!shouldTranslate(domain)) {
        return englishLocalizedStrings[name];
    }

    const localizedStrings = localizedStringsGlobalInstance();
    return localizedStrings.getString(name, localizedStrings.getLanguage(), false);
}

export function getAnyLocalizedString(name: LocalizedStringKey | DevStringKey, domain: LocalizationDomain): string {
    if (englishLocalizedStrings[name as LocalizedStringKey] !== undefined) {
        return getLocalizedString(name as LocalizedStringKey, domain);
    }
    if (
        hasOwnProperty(localizedDevStrings, name) ||
        hasOwnProperty(intentionallyUntranslatedLocalizedDevStrings, name)
    ) {
        return getDevLocalizedString(name as DevStringKey);
    }
    return name;
}

export function formatLocalizedString(
    name: LocalizedStringKey,
    strings: ReadonlyArray<string>,
    domain: LocalizationDomain
): string {
    const instance = shouldTranslate(domain)
        ? localizedStringsGlobalInstance()
        : localizedStringsEnglishGlobalInstance();
    return checkString(instance.formatString(name, ...strings));
}

// e.g. 8:30 AM
export function formatShortTime(d: Date): string | undefined {
    return formatDateTime(d, undefined, TimeFormat.WithoutSeconds);
}

// e.g. Wed, Jul 17 2019
export function formatShortDate(d: Date, showDayOfWeek: boolean = true): string | undefined {
    return formatDateTime(d, showDayOfWeek ? DateFormat.Long : DateFormat.Medium, undefined);
}

export function getDateString(
    domain: LocalizationDomain,
    date: Date,
    now: Date,
    startOfDay: Date,
    forBuilder: boolean,
    nowMintues?: number
): string | undefined {
    let difference = now.getTime() - date.getTime();
    difference = Math.floor(difference / (1000 * 60)); // move to minutes

    if (difference < (nowMintues ?? 3)) {
        return forBuilder ? "Just now" : getLocalizedString("justNow", domain);
    } else if (difference < 60) {
        return forBuilder
            ? `${difference.toString()} minutes ago`
            : formatLocalizedString("nMinutes", [difference.toString()], domain);
    }

    const startDay = startOfDay.getTime();
    const isToday = date.getTime() > startDay;
    const isYesterday = !isToday && date.getTime() > startDay - 1000 * 60 * 60 * 24;

    difference = Math.floor(difference / 60); // move to hours
    if (difference === 1) {
        return forBuilder ? `One hour ago` : getLocalizedString("oneHour", domain);
    } else if (difference < 12) {
        return forBuilder
            ? `${difference.toString()} hours ago`
            : formatLocalizedString("nHours", [difference.toString()], domain);
    } else if (isToday) {
        return forBuilder ? `Today` : getLocalizedString("today", domain);
    } else if (isYesterday) {
        return forBuilder ? `Yesterday` : getLocalizedString("yesterday", domain);
    } else if (difference < 168) {
        return formatDateTime(date, DateFormat.WeekdayOnly, undefined);
    } else {
        return formatShortDate(date, false);
    }
}

// Will return a string when n >= 1
export function makeLocalizedNumberOfItems(n: number, domain: LocalizationDomain): string | undefined {
    if (n < 1) {
        return undefined;
    }
    if (n === 1) {
        return getLocalizedString("oneItem", domain);
    }
    if (n < 5) {
        return formatLocalizedString("twoToFourItems", [n.toLocaleString()], domain);
    } else {
        return formatLocalizedString("numberOfItems", [n.toLocaleString()], domain);
    }
}
