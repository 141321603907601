import styled from "styled-components";

export const ConditionContainerStyle = styled.div`
    display: flex;
    align-items: center;
`;

export const InnerContainer = styled.div`
    padding: 4px;

    background-color: ${p => p.theme.conditionContainer.bgInner};
    border: 1px solid ${p => p.theme.borderColor};
    box-sizing: border-box;
    border-radius: 4px;

    flex-grow: 1;
    flex-shrink: 1;
`;

export const CloseContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;

    margin-left: 8px;

    cursor: pointer;

    color: ${p => p.theme.fgColorMedium};
    :hover {
        color: ${p => p.theme.fgColorDark};
    }

    flex-shrink: 0;
`;
