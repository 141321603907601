import { ResponsiveProvider } from "@glide/common-components";
import { DeviceFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import { standalone } from "@glide/common-core/dist/js/support/device";
import { APP_MODAL_ROOT, APP_OVERLAY_ROOT, APP_ROOT, PUSH_MODAL_ROOT } from "@glide/wire";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import classNames from "classnames";
import * as React from "react";
import styled from "styled-components";
import "twin.macro";
import { LockoutMessage } from "./lockout-message";
import type { AppFeatures } from "@glide/app-description";

const AppStylesThatDontEasilyFitInTailwind = styled.div`
    /** It's very likely we don't need these */
    button:focus,
    input:focus,
    textarea:focus {
        outline: none;
    }

    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        --safe-area-inset-bottom: constant(safe-area-inset-bottom);
        --safe-area-inset-top: constant(safe-area-inset-top);
        --safe-area-inset-left: constant(safe-area-inset-left);
        --safe-area-inset-right: constant(safe-area-inset-right);
    }

    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        --safe-area-inset-bottom: env(safe-area-inset-bottom);
        --safe-area-inset-top: env(safe-area-inset-top);
        --safe-area-inset-left: env(safe-area-inset-left);
        --safe-area-inset-right: env(safe-area-inset-right);
    }
`;

const queryClient = new QueryClient();

interface AppContainerProps extends React.PropsWithChildren {
    appFeatures?: AppFeatures;
}

export const AppContainer: React.FC<AppContainerProps> = p => {
    const { children } = p;
    const showLockout = p.appFeatures?.showLockout ?? false;

    return (
        <React.Fragment>
            <ResponsiveProvider isRoot tw="overflow-x-auto h-full">
                <QueryClientProvider client={queryClient}>
                    <AppStylesThatDontEasilyFitInTailwind tw="flex [font-family: Inter,-apple-system,BlinkMacSystemFont,Roboto,sans-serif] bg-bg-back absolute inset-0">
                        <div
                            id={APP_ROOT}
                            className={classNames("player", DeviceFormFactor.Phone, { standalone })}
                            tw="absolute inset-0 flex justify-center bg-transparent [text-rendering: optimizeLegibility] [-webkit-font-smoothing: antialiased] [-webkit-tap-highlight-color: rgba(0, 0, 0, 0)]">
                            {showLockout ? <LockoutMessage /> : children}
                            <div tw="absolute inset-0 pointer-events-none z-[15]">
                                <div tw="pointer-events-auto" id={PUSH_MODAL_ROOT} />
                            </div>

                            <ModalContainer>
                                <div tw="[--safe-area-inset-top: 0px] pointer-events-auto" id={APP_MODAL_ROOT} />
                            </ModalContainer>

                            <ModalContainer>
                                <div tw="[--safe-area-inset-top: 0px] pointer-events-auto" id={APP_OVERLAY_ROOT} />
                            </ModalContainer>
                        </div>
                    </AppStylesThatDontEasilyFitInTailwind>
                </QueryClientProvider>
            </ResponsiveProvider>
        </React.Fragment>
    );
};

const ModalContainer: React.FC<React.PropsWithChildren> = p => {
    return (
        <div tw="absolute bottom-0 [top: var(--safe-area-inset-top)] inset-x-0 pointer-events-none z-app-modal-root">
            {p.children}
        </div>
    );
};
