import { assert } from "@glideapps/ts-necessities";

type UnanchoredBox = { width: number; height: number };
type AnchoredBoxToScale = UnanchoredBox & {
    x: number;
    y: number;
    scale: number;
};

export function fitBoxIntoBox(givenBox: UnanchoredBox, containerBox: UnanchoredBox): AnchoredBoxToScale {
    const givenRatio = givenBox.width / givenBox.height;
    const containerRatio = containerBox.width / containerBox.height;

    if (givenRatio > containerRatio) {
        const scale = containerBox.width / givenBox.width;
        const width = containerBox.width;
        const height = givenBox.height * scale;
        return {
            x: 0,
            y: (containerBox.height - height) / 2,
            width,
            height,
            scale,
        };
    } else {
        const scale = containerBox.height / givenBox.height;
        const width = givenBox.width * scale;
        const height = containerBox.height;
        return {
            x: (containerBox.width - width) / 2,
            y: 0,
            width,
            height,
            scale,
        };
    }
}

export function xAtYIntercept(x0: number, y0: number, x1: number, y1: number): number {
    const dx = x1 - x0;
    const dy = y1 - y0;
    return x0 - (dx * y0) / dy;
}

export function rotatePointAbout(xP: number, yP: number, theta: number, xO: number, yO: number): [number, number] {
    const c = Math.cos(theta);
    const s = Math.sin(theta);

    const xD = xP - xO;
    const yD = yP - yO;

    return [c * xD - s * yD + xO, s * xD + c * yD + yO];
}

function pointDistance(x1: number, y1: number, x2: number, y2: number): number {
    const xDelta = x2 - x1;
    const yDelta = y2 - y1;
    return Math.sqrt(xDelta * xDelta + yDelta * yDelta);
}

// The general equation for area of a triangle is 1/2 width * height,
// and interestingly enough, if you take any edge of a triangle as the
// "origin edge", rotate the entire triangle about that edge, and consider
// the "width" of the triangle the length of that edge, and the Y offset of
// the point in the triangle not in that edge from the origin axis, even if
// the X offset of that "height" point isn't within the boundaries of the
// "width" edge, this relation still holds.

// This particular edge case can be thought of as computing the area of two
// right triangles, where the "height" point is shared between them, a new
// "virtual" point at X = 0, Y is the point of the right angle, and the only
// difference between the two angles is which point of the "width" edge is the
// extreme point. Let's suppose that the "width" edge has points X1 and X2,
// both with Y = 0. Let's also suppose that the X offset of the "height" point
// is XP. We take X1 < X2 as an assumption. Now, assume that XP < X1 (this
// still holds if XP > X2), then the width of the "larger" triangle is X2 - XP
// and the width of the smaller triangle is X1 - XP. In order to determine the
// area of the actual triangle, we compute the area of the larger "virtual"
// triangle minus the area of the smaller "virtual" triangle. This area is:
//
//   Y * (X2 - XP) - Y * (X1 - XP)
// = Y * (X2 - XP - X1 + XP)
// = Y * (X2 - X1)
//
// Which is the same as if the "height" point had an X offset between X2 and
// X1.
function areaOfTriangle(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number): number {
    const rise = y2 - y1;
    const run = x2 - x1;
    const theta = -Math.atan(rise / run);
    const width = Math.sqrt(rise * rise + run * run);
    // The tricky thing about rotatePointAbout is that it translates the point
    // back into the vector space of the original image; in order to get the
    // height, we explicitly don't want the translation back into the original
    // image vector space. Since we're not caring about x offsets here, we don't
    // correct for those.
    const height = Math.abs(rotatePointAbout(x3, y3, theta, x1, y1)[1] - y1);
    return (width * height) / 2;
}

function pointsOnSameSideOfLine(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    xp1: number,
    yp1: number,
    xp2: number,
    yp2: number
): boolean {
    const rise = y2 - y1;
    const run = x2 - x1;
    const theta = -Math.atan(rise / run);
    if (!isNaN(theta)) {
        // See above: rotatePointAbout puts the rotated point in the vector space
        // of the original image, so if we're trying to get distances from the
        // basis line after rotation, we should be computing based on the offset
        // of the basis line itself.
        const p1Above = rotatePointAbout(xp1, yp1, theta, x1, y1)[1] >= y1;
        const p2Above = rotatePointAbout(xp2, yp2, theta, x1, y1)[1] >= y1;
        return p1Above === p2Above;
    } else {
        // This is a 90 degree line (the run is assumed to be 0)
        // So we just need to determine if both points are on the same side
        // of the x division.
        // eslint-disable-next-line no-mixed-operators
        return xp1 <= x1 === xp2 <= x1;
    }
}

// That's x, y
export type Point = readonly [number, number];

// If a polygon is convex everywhere, it's trivial to decompose the polygon
// into several triangles, all of which share a point. With this, you can
// compute the area of the polygon.
function areaOfConvexPolygon(points: readonly Point[]): number {
    let area = 0;
    const xy0 = points[0];
    const x0 = xy0[0];
    const y0 = xy0[1];
    for (let i = 2; i < points.length; i++) {
        const xy1 = points[i - 1];
        const xy2 = points[i];
        area += areaOfTriangle(x0, y0, xy1[0], xy1[1], xy2[0], xy2[1]);
    }
    return isNaN(area) ? 0 : area;
}

type Triangle = readonly [Point, Point, Point];

function pointWithinTriangle(t: Triangle, xp: number, yp: number): boolean {
    const tp1 = t[0];
    const tp2 = t[1];
    const tp3 = t[2];
    return (
        pointsOnSameSideOfLine(tp1[0], tp1[1], tp2[0], tp2[1], tp3[0], tp3[1], xp, yp) &&
        pointsOnSameSideOfLine(tp2[0], tp2[1], tp3[0], tp3[1], tp1[0], tp1[1], xp, yp) &&
        pointsOnSameSideOfLine(tp3[0], tp3[1], tp1[0], tp1[1], tp2[0], tp2[1], xp, yp)
    );
}

type Line = [number, number, number, number];

// Thats [m, b] so it's not semantically a Point.
function lineToInterceptForm(l: Line): [number, number] {
    const rise = l[3] - l[1];
    const run = l[2] - l[0];
    const m = rise / run;
    const b = l[1] - m * l[0];
    return [m, b];
}

function pointWithinLineBox(l: Line, p: Point): boolean {
    const xMin = Math.min(l[0], l[2]);
    const xMax = Math.max(l[0], l[2]);
    if (p[0] < xMin || xMax < p[0]) return false;

    const yMin = Math.min(l[1], l[3]);
    const yMax = Math.max(l[1], l[3]);
    return yMin <= p[1] && p[1] <= yMax;
}

export const effectiveIntersectionZero = 0.00005;

function intersectionRegionOnParallelLines(l1: Line, l2: Line): Point | undefined {
    const nearPoint: Point = [l2[0], l2[1]];
    const farPoint: Point = [l2[2], l2[3]];
    if (!pointWithinLineBox(l1, nearPoint)) {
        if (pointWithinLineBox(l1, farPoint)) return farPoint;

        // If [l1[2], l1[3]] were in l2, but [l1[0], l1[1]] weren't, then
        // one of the points from l2 would also be in l1, so we only need
        // to check the first point.
        const firstPoint: Point = [l1[0], l1[1]];
        return pointWithinLineBox(l2, firstPoint) ? firstPoint : undefined;
    } else if (!pointWithinLineBox(l1, farPoint)) {
        return nearPoint;
    }

    const nearDelta = pointDistance(l1[0], l1[1], l2[0], l2[1]);
    const farDelta = pointDistance(l1[0], l1[1], l2[2], l2[3]);

    return nearDelta <= farDelta ? nearPoint : farPoint;
}

function intersectionBetweenLines(l1: Line, l2: Line): Point | undefined {
    const intercept1 = lineToInterceptForm(l1);
    const m1 = intercept1[0];
    const b1 = intercept1[1];

    const intercept2 = lineToInterceptForm(l2);
    const m2 = intercept2[0];
    const b2 = intercept2[1];

    const mDelta = m1 - m2;
    const bDelta = b2 - b1;
    let p: Point;
    if (isNaN(mDelta)) {
        // One of the two lines (or maybe both) has a zero-run.
        if (isNaN(m1) && isNaN(m2)) {
            return intersectionRegionOnParallelLines(l1, l2);
        } else if (isNaN(m1)) {
            return [l1[0], m2 * l1[0] + b2];
        } else {
            return [l2[0], m1 * l2[0] + b1];
        }
    } else if (Math.abs(mDelta) < effectiveIntersectionZero) {
        // mDelta is zero, so these lines are parallel.
        if (Math.abs(bDelta) >= effectiveIntersectionZero) return undefined;

        // In order for parallel lines to "intersect" they have to have the same
        // origin intercept, otherwise there's no intersection.

        // The "intersecting" point does need to be within both lines, though.
        return intersectionRegionOnParallelLines(l1, l2);
    } else {
        const x = bDelta / mDelta;
        const y = m1 * x + b1;
        p = [x, y];

        // The lines in question are bounded, and in order for the intersection
        // to be considered "valid", it has to land within the bounds of both lines.
        return pointWithinLineBox(l1, p) && pointWithinLineBox(l2, p) ? p : undefined;
    }
}

function pointOnLine(l: Line, p: Point): boolean {
    if (!pointWithinLineBox(l, p)) return false;

    const intercept = lineToInterceptForm(l);
    const theta = -Math.atan(intercept[0]);
    if (isNaN(theta)) {
        return Math.abs(p[0] - l[0]) <= effectiveIntersectionZero;
    }

    const offsets = rotatePointAbout(p[0], p[1], theta, l[0], l[1]);
    // See above: rotatePointAbout keeps the rotated point in the image space,
    // and we're trying to figure out the offset from "zero" at l[0], l[1].
    // So we just subtract l[1] from offsets[1] to get the height.
    return Math.abs(offsets[1] - l[1]) <= effectiveIntersectionZero;
}

interface GraphPoint {
    readonly point: Point;
    memberOfFirst: boolean | undefined;
    memberOfSecond: boolean | undefined;

    // GraphPoints are intentionally cyclical.
    prev: GraphPoint;
    next: GraphPoint;
}

function cloneGraphPoint(g: GraphPoint): GraphPoint {
    return {
        point: g.point,
        memberOfFirst: g.memberOfFirst,
        memberOfSecond: g.memberOfSecond,
        prev: g.prev,
        next: g.next,
    };
}

function triangleIntoGraph(t: Triangle, first: boolean): GraphPoint {
    let anchor: GraphPoint | undefined;
    let prevPoint: GraphPoint | undefined;
    for (let i = 0; i < t.length; i++) {
        const point = t[i];
        const protoPoint: any = {
            point,
            memberOfFirst: first ? true : undefined,
            memberOfSecond: !first ? true : undefined,
            prev: undefined,
            next: undefined,
        };
        protoPoint.next = protoPoint;
        if (prevPoint === undefined) {
            protoPoint.prev = protoPoint;
            anchor = protoPoint;
        } else {
            prevPoint.next = protoPoint;
            protoPoint.prev = prevPoint;
        }
        prevPoint = protoPoint;
    }
    assert(anchor !== undefined);
    assert(prevPoint !== undefined);
    anchor.prev = prevPoint;
    prevPoint.next = anchor;
    return anchor;
}

function updateGraphForTriangleInclusion(g: GraphPoint, t: Triangle, triangleIsFirst: boolean): GraphPoint {
    let cur = g;
    do {
        if (triangleIsFirst) {
            cur.memberOfFirst = pointWithinTriangle(t, cur.point[0], cur.point[1]);
        } else {
            cur.memberOfSecond = pointWithinTriangle(t, cur.point[0], cur.point[1]);
        }
        if (cur.next === cur) break;
        cur = cur.next;
    } while (cur !== g);
    return g;
}

function isInteriorPoint(g: GraphPoint): boolean {
    return g.memberOfFirst === true && g.memberOfSecond === true;
}

// If we find a valid GraphPoint, the line in question is between the returned
// GraphPoint and its .next property.
function findGraphPointWithIntersection(g: GraphPoint, p: Point): GraphPoint | undefined {
    let cur = g;
    do {
        const next = cur.next;
        const line: Line = [cur.point[0], cur.point[1], next.point[0], next.point[1]];
        if (pointOnLine(line, p)) return cur;
        if (next === cur) break;
        cur = next;
    } while (cur !== g);
    return undefined;
}

function updateGraphForNextPoint(
    g: GraphPoint,
    t1: Triangle,
    t2: Triangle,
    tg1: GraphPoint,
    tg2: GraphPoint
): GraphPoint | undefined {
    if (!isInteriorPoint(g)) {
        // This is the first attempt to create a graph of the intersecting polygon,
        // so we'll need to perform some special handling here.
        let cur = g;
        const other = g.memberOfFirst === true ? t2 : t1;
        do {
            for (let i = 0; i < other.length; i++) {
                const xy1 = other[i];
                const xy2 = other[(i + 1) % other.length];

                const next = cur.next;
                const curLine: Line = [cur.point[0], cur.point[1], next.point[0], next.point[1]];
                const otherLine: Line = [xy1[0], xy1[1], xy2[0], xy2[1]];

                const maybeIntersecting = intersectionBetweenLines(curLine, otherLine);
                if (maybeIntersecting === undefined) continue;

                const ret: GraphPoint = {
                    point: maybeIntersecting,
                    prev: cur,
                    next,
                    memberOfFirst: true,
                    memberOfSecond: true,
                };
                ret.prev = ret;
                return ret;
            }
            if (cur.next === cur) break;
            cur = cur.next;
        } while (cur !== g);
        return undefined;
    } else if (isInteriorPoint(g.next)) {
        const ret = cloneGraphPoint(g.next);
        // Note that ret.prev isn't necessarily g here! We generally don't alter
        // the "next" to be strictly part of the polygon graph, so its "prev" is
        // typically whatever it was when we originally constructed the graph it
        // is actually a member of. This lets us figure out whether g is part
        // of the line that g.next actually represents in its original graph.
        const prev = ret.prev;
        const sourceLine: Line = [ret.point[0], ret.point[1], prev.point[0], prev.point[1]];
        // If we're not on our next's "prev" line, we were on its "next" line
        // and the resulting "next" is actually its "prev". That is to say, we're
        // "swimming against the current" of the other triangle's graph direction.
        if (!pointOnLine(sourceLine, g.point)) {
            ret.next = ret.prev;
        }
        ret.prev = g;
        g.next = ret;
        return ret;
    } else {
        const first = g.next.memberOfFirst === true;
        const graphNext = g.next;
        const graphLine: Line = [g.point[0], g.point[1], graphNext.point[0], graphNext.point[1]];
        const other = first ? t2 : t1;
        let intersecting: Point | undefined;
        let bestDistance = Number.MAX_SAFE_INTEGER;

        for (let i = 0; i < other.length; i++) {
            const xy1 = other[i];
            const xy2 = other[(i + 1) % other.length];
            const xyLine: Line = [xy1[0], xy1[1], xy2[0], xy2[1]];
            const maybeIntersecting = intersectionBetweenLines(graphLine, xyLine);
            if (maybeIntersecting === undefined) continue;

            // We want the intersection point closest to the "far" end of our line,
            // not closest to us, because it's possible _we_ are already
            // intersecting.
            const intersectingDistance = pointDistance(
                maybeIntersecting[0],
                maybeIntersecting[1],
                graphLine[2],
                graphLine[3]
            );
            if (intersecting === undefined || intersectingDistance < bestDistance) {
                intersecting = maybeIntersecting;
                bestDistance = intersectingDistance;
            }
        }

        if (intersecting === undefined) return undefined;

        const otherGraph = findGraphPointWithIntersection(first ? tg2 : tg1, intersecting);
        if (otherGraph === undefined) return undefined;

        let farPoint: Point;
        // We are considering the direction to move on the basis of being
        // part of a two-edge, three-vertex component of the existing graph.
        // Our current point is one vertex of an edge connected to the "next"
        // vertex, and that "next" vertex is connected to another edge entirely,
        // of which we are not a member. This same edge, not connected to the
        // current vertex at all, is connected to a "far" vertex.
        //
        // We're trying to "move" in the direction of the "far" vertex, so that
        // we can build out an enclosing graph. But figuring out the "far" vertex
        // is not as simple as just going next -> next; the "next" point might be
        // part of a graph that "flows" opposite to the graph we're currently
        // building.

        // Note that, like above, next -> prev is not necessarily "us", it's
        // possible that "we" are just a point on the line segment between
        // next->prev and next.
        const nextPrev = g.next.prev;
        const nextLine: Line = [nextPrev.point[0], nextPrev.point[1], graphNext.point[0], graphNext.point[1]];
        // However, in the two-edge component we're investigating, we might
        // not be on the next -> prev edge, but actually be on the next -> next
        // edge! What we do know is that we're on _some_ edge connected to "next"
        // because it _is_ our next.
        //
        // If we are on next -> prev, then next -> next is the "far" point.
        // Otherwise, next -> prev is the "far point".
        if (pointOnLine(nextLine, intersecting)) {
            farPoint = g.next.next.point;
        } else {
            farPoint = g.next.prev.point;
        }

        // We now have enough information to determine which way on the
        // intersecting line to move: we move in the same direction as the "far"
        // point.
        if (
            pointsOnSameSideOfLine(
                graphLine[0],
                graphLine[1],
                graphLine[2],
                graphLine[3],
                farPoint[0],
                farPoint[1],
                otherGraph.point[0],
                otherGraph.point[1]
            )
        ) {
            const ret: GraphPoint = {
                point: intersecting,
                prev: g,
                next: otherGraph,
                memberOfFirst: true,
                memberOfSecond: true,
            };
            g.next = ret;
            return ret;
        } else if (
            pointsOnSameSideOfLine(
                graphLine[0],
                graphLine[1],
                graphLine[2],
                graphLine[3],
                farPoint[0],
                farPoint[1],
                otherGraph.next.point[0],
                otherGraph.next.point[1]
            )
        ) {
            const ret: GraphPoint = {
                point: intersecting,
                prev: g,
                next: otherGraph.next,
                memberOfFirst: true,
                memberOfSecond: true,
            };
            g.next = ret;
            return ret;
        } else {
            return undefined;
        }
    }
}

function graphIntoPointArray(g: GraphPoint): Point[] {
    const ret: Point[] = [];
    let cur = g;
    do {
        ret.push(cur.point);
        if (cur.next === cur) break;
        cur = cur.next;
    } while (cur !== g);
    return ret;
}

function areaOfConvexGraph(g: GraphPoint): number {
    const points = graphIntoPointArray(g);
    return areaOfConvexPolygon(points);
}

function graphAll(g: GraphPoint, f: (gp: GraphPoint) => boolean): boolean {
    let cur = g;
    do {
        if (!f(cur)) return false;
        if (cur.next === cur) break;
        cur = cur.next;
    } while (cur !== g);
    return true;
}

function graphPointsAreSame(g1: GraphPoint, g2: GraphPoint): boolean {
    const xDelta = g2.point[0] - g1.point[0];
    const yDelta = g2.point[1] - g1.point[1];
    return Math.abs(xDelta) <= effectiveIntersectionZero && Math.abs(yDelta) <= effectiveIntersectionZero;
}

function areaOfTriangleOverlap(t1: Triangle, t2: Triangle): number {
    const g1 = updateGraphForTriangleInclusion(triangleIntoGraph(t1, true), t2, false);
    if (graphAll(g1, isInteriorPoint)) return areaOfConvexGraph(g1);

    const g2 = updateGraphForTriangleInclusion(triangleIntoGraph(t2, false), t1, true);
    if (graphAll(g2, isInteriorPoint)) return areaOfConvexGraph(g2);

    const anchor = updateGraphForNextPoint(g1, t1, t2, g1, g2);
    if (anchor === undefined) return 0;

    let next: GraphPoint | undefined = anchor;
    do {
        next = updateGraphForNextPoint(next, t1, t2, g1, g2);
        if (next === undefined) return 0;
    } while (next !== undefined && !graphPointsAreSame(next, anchor));

    anchor.prev = next.prev;
    next.prev.next = anchor;

    return areaOfConvexGraph(anchor);
}

export type Quadrilateral = readonly [Point, Point, Point, Point];

export function areaOfConvexQuadrilateral(q: Quadrilateral): number {
    return areaOfConvexPolygon(q);
}

export function areaOfConvexQuadrilateralOverlap(q1: Quadrilateral, q2: Quadrilateral): number {
    const t11: Triangle = [q1[0], q1[1], q1[2]];
    const t12: Triangle = [q1[2], q1[3], q1[0]];
    const t21: Triangle = [q2[0], q2[1], q2[2]];
    const t22: Triangle = [q2[2], q2[3], q2[0]];

    return (
        areaOfTriangleOverlap(t11, t21) +
        areaOfTriangleOverlap(t11, t22) +
        areaOfTriangleOverlap(t12, t21) +
        areaOfTriangleOverlap(t12, t22)
    );
}

// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
    function deg2rad(deg: number) {
        return deg * (Math.PI / 180);
    }

    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}
