import type { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { PLAN_WEIGHTS } from "./types";

export function canCurrentPlanPassGate(current: PlanKind, minimumPlanForGate: PlanKind) {
    const currentPlan = PLAN_WEIGHTS.find(x => {
        return x.planKind === current;
    });
    const targetPlan = PLAN_WEIGHTS.find(x => {
        return x.planKind === minimumPlanForGate;
    });

    if (currentPlan === undefined || targetPlan === undefined) {
        return true;
    }

    return currentPlan.weight >= targetPlan.weight;
}
