interface AndroidHooks {
    passFunctionResponse(functionName: string, url: string, headers: string, body: string): void;

    launchGoogleOauth2Flow(webClientId: string): void;
}

export async function launchNativeGoogleOauth2Flow(clientID: string): Promise<boolean> {
    const androidHooks = (window as any).Android as AndroidHooks;

    if (androidHooks !== undefined) {
        androidHooks.launchGoogleOauth2Flow(clientID);
        return true;
    }

    return false;
}

export function isRunningInNativeIos(): boolean {
    /*
     * In the event we ever actually implement "native" iOS builds, we
     * cannot use this implementation.
     *
     * Chrome for iOS and Firefox for iOS, being nothing more than shells
     * for Mobile Safari, also use the WKWebView class and consequently expose
     * this same window.webkit.messageHandlers. This breaks Sign in with Google
     * on those browsers.
     *
     * If we ever have to really implement this, we're going to need to be
     * smarter about communicating our "nativeness" into the JavaScript
     * runtime.
     */
    /*
    let iOsHooks = undefined;

    if ((window as any).webkit !== undefined) {
        iOsHooks = (window as any).webkit.messageHandlers as IOsMessageHandlers;
    }

    return iOsHooks !== undefined;
    */
    return false;
}
