import {
    type AppAuthentication,
    type AppDescription,
    AppAuthenticationKind,
    AuthenticationEmailSource,
} from "@glide/app-description";
import {
    getAuthenticationEmailSource,
    isPrivateAuthentication,
} from "@glide/common-core/dist/js/components/SerializedApp";
import {
    type AppLoginAuthenticationMethod,
    type AuthenticationData,
    AuthenticationMethod,
} from "@glide/common-core/dist/js/Database";
import { type TableName, makeTableName } from "@glide/type-schema";
import type { AuthenticationSupport } from "@glide/common-core/dist/js/Database/eminence";
import { digitsAndLetters, makeRandomString } from "@glide/support";
import { assertNever, hasOwnProperty, panic } from "@glideapps/ts-necessities";
import { getUserProfileTableInfo } from "./user-profile-info";

function isAuthenticationRequiredInternal(kind: AuthenticationMethod, optional: boolean): boolean {
    switch (kind) {
        case AuthenticationMethod.None:
        case AuthenticationMethod.Disabled:
            return false;
        case AuthenticationMethod.PublicEmailPin:
        case AuthenticationMethod.OrgMembers:
        case AuthenticationMethod.JustMe:
        case AuthenticationMethod.UserProfileEmailPin:
        case AuthenticationMethod.AllowedEmailDomains:
            return !optional;
        case AuthenticationMethod.WhitelistEmailPin:
        case AuthenticationMethod.Password:
            return true;
        default:
            return assertNever(kind);
    }
}

export function isAuthenticationRequired(auth: AuthenticationData): boolean {
    return isAuthenticationRequiredInternal(auth.kind, hasOwnProperty(auth, "optional") ? auth.optional : false);
}

export function isAuthenticationRequiredForAppLogin(appLogin: AppLoginAuthenticationMethod): boolean {
    return isAuthenticationRequiredInternal(appLogin.authenticationMethod, appLogin.authenticationOptional);
}

const emailBasedAuthenticationMethods = [
    AuthenticationMethod.PublicEmailPin,
    AuthenticationMethod.WhitelistEmailPin,
    AuthenticationMethod.UserProfileEmailPin,
    AuthenticationMethod.OrgMembers,
    AuthenticationMethod.JustMe,
];

export function isEmailBasedAuthentication(auth: AuthenticationData): boolean {
    return emailBasedAuthenticationMethods.includes(auth.kind);
}

export function getAppAuthenticationData(app: AppDescription): AuthenticationData {
    const { authentication } = app;
    const userProfile = getUserProfileTableInfo(app);
    return getAppAuthenticationDataCore(authentication, userProfile?.tableName, userProfile?.emailColumnName);
}

export function getAppAuthenticationDataCore(
    authentication: AppAuthentication | undefined,
    tableName?: TableName,
    columnName?: string
): AuthenticationData {
    if (authentication === undefined) {
        // `undefined` always means no authentication required.
        return { kind: AuthenticationMethod.PublicEmailPin, optional: true };
    }

    if (authentication.kind === AppAuthenticationKind.Disabled) {
        return { kind: AuthenticationMethod.Disabled };
    }

    if (authentication.kind === undefined || authentication.kind === AppAuthenticationKind.Password) {
        return { kind: AuthenticationMethod.Password, password: authentication.password };
    }

    if (authentication.kind === AppAuthenticationKind.EmailPin) {
        const source = getAuthenticationEmailSource(authentication);
        const optional = authentication.optional ?? false;
        if (source === AuthenticationEmailSource.Table) {
            const { emailTableName, additionalEmailsWithPins } = authentication;
            if (emailTableName === undefined) {
                return { kind: AuthenticationMethod.PublicEmailPin, optional };
            } else {
                return {
                    kind: AuthenticationMethod.WhitelistEmailPin,
                    emailTableName: makeTableName(emailTableName),
                    additionalEmailsWithPins: additionalEmailsWithPins ?? [],
                };
            }
        } else if (source === AuthenticationEmailSource.AllowlistedDomains) {
            return {
                kind: AuthenticationMethod.AllowedEmailDomains,
                tableName,
                columnName,
                optional,
            };
        } else if (source === AuthenticationEmailSource.OrgMembers) {
            return { kind: AuthenticationMethod.OrgMembers, optional };
        } else if (source === AuthenticationEmailSource.JustMe) {
            return { kind: AuthenticationMethod.JustMe, optional };
        } else if (source === AuthenticationEmailSource.UserProfiles) {
            return {
                kind: AuthenticationMethod.UserProfileEmailPin,
                tableName,
                columnName,
                optional,
            };
        } else {
            assertNever(source);
        }
    }

    assertNever(authentication.kind);
}

export function enforceEminenceInAuthentication(
    authentication: AppAuthentication | undefined,
    support: AuthenticationSupport
): readonly [auth: AppAuthentication | undefined, didChange: boolean] {
    const info = getAppAuthenticationDataCore(authentication);

    let mustChange = false;

    switch (info.kind) {
        case AuthenticationMethod.None:
            mustChange = true;
            break;
        case AuthenticationMethod.Disabled:
            mustChange = support.publicDisabled !== true;
            break;
        case AuthenticationMethod.PublicEmailPin:
            mustChange =
                support.publicWithEmail !== true ||
                (info.optional === true && support.optionalPublicWithEmail !== true);
            break;
        case AuthenticationMethod.OrgMembers:
            mustChange =
                support.organizationMembers !== true || (info.optional === true && support.optionalOtherEmail !== true);
            break;
        case AuthenticationMethod.JustMe:
            mustChange = support.justMe !== true || (info.optional === true && support.optionalOtherEmail !== true);
            break;
        case AuthenticationMethod.Password:
            mustChange = support.password !== true;
            break;
        case AuthenticationMethod.WhitelistEmailPin:
            mustChange = support.emailWhitelist !== true;
            break;
        case AuthenticationMethod.UserProfileEmailPin:
            mustChange =
                support.userProfiles !== true || (info.optional === true && support.optionalOtherEmail !== true);
            break;
        case AuthenticationMethod.AllowedEmailDomains:
            mustChange =
                support.allowedEmailDomains !== true || (info.optional === true && support.optionalOtherEmail !== true);
            break;
        default:
            return assertNever(info);
    }

    if (!mustChange) {
        return [authentication, false];
    }

    let newAuth: AppAuthentication | undefined;
    if (support.organizationMembers === true) {
        newAuth = {
            ...authentication,
            kind: AppAuthenticationKind.EmailPin,
            source: AuthenticationEmailSource.OrgMembers,
            emailTableName: undefined,
            optional: false,
        };
    } else if (support.justMe === true) {
        newAuth = {
            ...authentication,
            kind: AppAuthenticationKind.EmailPin,
            source: AuthenticationEmailSource.JustMe,
            emailTableName: undefined,
            optional: false,
        };
    } else if (support.password === true) {
        newAuth = {
            ...authentication,
            kind: AppAuthenticationKind.Password,
            password: makeRandomString(8, digitsAndLetters),
        };
    } else if (authentication !== undefined && isPrivateAuthentication(authentication)) {
        // We intentionally refuse to enforce eminence in such a way
        // that apps stop being private without user intervention,
        // including when fixing bugs with eminence.
        return [authentication, false];
    } else if (support.publicWithEmail === true) {
        newAuth = {
            ...authentication,
            kind: AppAuthenticationKind.EmailPin,
            source: AuthenticationEmailSource.Table,
            emailTableName: undefined,
            optional: false,
        };
    } else {
        return panic("Eminence doesn't support any suitable authentication method");
    }

    return [newAuth, true];
}

export function doesAuthenticationMethodAllowSignUp(method: AuthenticationMethod): boolean {
    switch (method) {
        case AuthenticationMethod.None:
        case AuthenticationMethod.PublicEmailPin:
        case AuthenticationMethod.AllowedEmailDomains:
            return true;

        case AuthenticationMethod.JustMe:
        case AuthenticationMethod.Disabled:
        case AuthenticationMethod.Password:
        case AuthenticationMethod.OrgMembers:
        case AuthenticationMethod.WhitelistEmailPin:
        case AuthenticationMethod.UserProfileEmailPin:
            return false;

        default:
            return assertNever(method);
    }
}
