import { logError, logInfo } from "@glide/support";
import { DefaultMap } from "@glideapps/ts-necessities";
import { getLocationSettings } from "@glide/common-core/dist/js/location";

async function getFirebaseMessaging() {
    return await import("firebase/messaging");
}

async function canRunWebPushNotifications(): Promise<boolean> {
    const messaging = await getFirebaseMessaging();
    return (
        (window as any).Notification !== undefined &&
        navigator.serviceWorker !== undefined &&
        messaging !== undefined &&
        (await messaging.isSupported())
    );
}

async function getMessagingToken(): Promise<string> {
    const messaging = await getFirebaseMessaging();
    const msg = messaging.getMessaging();
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;

    return await messaging.getToken(msg, { vapidKey: getLocationSettings().fcmKey, serviceWorkerRegistration });
}

let pushNotificationInitPromise: Promise<void> | undefined;

async function initPushNotifications(): Promise<void> {
    if (!(await canRunWebPushNotifications())) return;
    if (pushNotificationInitPromise !== undefined) return await pushNotificationInitPromise;

    pushNotificationInitPromise = (async () => {
        const { initFirebase } = await import("./firebase");

        initFirebase();

        if (Notification.permission === "granted") {
            await getMessagingToken();
        }
    })();
    return await pushNotificationInitPromise;
}

let latestToken: string | null = null;
const onTokenLoadCallbacks = new Set<(token: string) => Promise<void>>();

async function runTokenLoadCallback(targetToken: string | null, f: (token: string) => Promise<void>): Promise<void> {
    if (targetToken !== null) {
        try {
            await f(targetToken);
        } catch (e: unknown) {
            logError(e);
        }
    }
}

export async function registerOnPushTokenLoadCallback(f: (token: string) => Promise<void>): Promise<void> {
    if (onTokenLoadCallbacks.has(f)) return;
    onTokenLoadCallbacks.add(f);

    // We only want to run the push notification token load callbacks
    // when we've actually tried to initialize them.
    if (pushNotificationInitPromise !== undefined) {
        await initPushNotifications();
        await runTokenLoadCallback(latestToken, f);
    }
}

export function unregisterOnPushTokenLoadCallback(f: (token: string) => Promise<void>): void {
    onTokenLoadCallbacks.delete(f);
}

// { [appID: string]: Set<string> }
const suppressedPushNotificationTopics: DefaultMap<string, Set<string>> = new DefaultMap(() => new Set());

function isTopicSuppressed(appID: string, topic: string): boolean {
    return suppressedPushNotificationTopics.get(appID).has(topic);
}

export function suppressPushNotificationTopic(appID: string, topic: string): void {
    suppressedPushNotificationTopics.get(appID).add(topic);
}

export function unsuppressPushNotificationTopic(appID: string, topic: string): void {
    suppressedPushNotificationTopics.get(appID).delete(topic);
}

let didPrepareActivePushNotifications = false;

export async function prepareActivePushNotifications(): Promise<void> {
    (window as any).glideNotifications = {
        hasRequestPermission,
        requestPermission: requestPushNotificationPermissionIfNecessary,
    };

    await initPushNotifications();

    if (!(await canRunWebPushNotifications())) return;
    if (Notification.permission !== "granted") return;

    const messaging = await getFirebaseMessaging();
    const msg = messaging.getMessaging();
    const firstToken = await getMessagingToken();
    latestToken = firstToken;

    if (didPrepareActivePushNotifications) return;
    didPrepareActivePushNotifications = true;

    const runAllTokenLoadCallbacks = async (targetToken: string | null) => {
        for (const callback of onTokenLoadCallbacks) {
            await runTokenLoadCallback(targetToken, callback);
        }
    };

    // This apparently was never implemented, and deprecated in Firebase 7.18.0.
    // The official reference documentation still tells you to do this, but
    // you shouldn't be doing this, because it will be removed in a near version
    // of Firebase.
    /*
    firebase.messaging().onTokenRefresh(async () => {
        const nextToken = await messaging.getToken();
        latestToken = nextToken;
        await runAllTokenLoadCallbacks(nextToken);
    });
    */

    messaging.onMessage(msg, async message => {
        const { data, notification, fcmOptions } = message;
        if (notification === undefined || data === undefined) return;
        const { title, body, icon } = notification;
        const { topic, appID } = data;
        const link = fcmOptions?.link;

        if (isTopicSuppressed(appID, topic)) return;

        // navigator.serviceWorker.controller will be null because we explicitly do not cache
        // builder requests, which means that all builder documents will not be controlled by it.
        const registration = await navigator.serviceWorker.ready;

        // FIXME: Do we want to have the action be opening the app in the player?
        if (registration.active !== null) {
            await registration.showNotification(title ?? "", {
                body,
                icon,
                data: { type: "open-link-in-window", link },
            });
        } else {
            logInfo(`active service worker is null`);
        }
    });

    await runAllTokenLoadCallbacks(firstToken);
}

export async function requestPushNotificationPermissionIfNecessary(): Promise<void> {
    if (!(await canRunWebPushNotifications())) return;
    let { permission } = Notification;

    // We assume that an explicit "denied" state arises from the user having
    // explicitly denied the request for push notifications. We won't bug
    // them again. Ever.
    if (permission === "default") {
        permission = await Notification.requestPermission();
    }
    if (permission !== "granted") return;

    await prepareActivePushNotifications();
}

async function hasRequestPermission(): Promise<boolean> {
    if (!(await canRunWebPushNotifications())) return false;
    return Notification.permission === "granted";
}
