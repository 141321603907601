import {
    isLoadingValue,
    makeLoadingValueWithDisplayValue,
    type QueryTableVersions,
} from "@glide/computation-model-types";
import { type ActiveQueryBase, QueryState } from "./active-query";
import type { QueryIDsWithTableVersions } from "@glide/common-core";
import { checkQueryTableVersions } from "@glide/backend-api";
import { mapRecordFilterUndefined } from "@glideapps/ts-necessities";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import { Periodically } from "@glide/support";

export type ListQueries = () => IterableIterator<ActiveQueryBase>;
type QueryChangedHandler = () => void;

export interface QueryRefresher {
    tableVersions(): QueryIDsWithTableVersions;
    requery(queryIDs: Set<string>): void;
    onAfterQuery(query: ActiveQueryBase): void;
}

export class NoOpQueryRefresher implements QueryRefresher {
    static build(_queries: ListQueries, _handler: QueryChangedHandler): QueryRefresher {
        return new NoOpQueryRefresher();
    }

    tableVersions(): QueryIDsWithTableVersions {
        return {};
    }

    requery(_queryIDs: Set<string>) {
        return;
    }

    onAfterQuery(_query: ActiveQueryBase) {
        return;
    }
}

export class GbtQueryRefresher implements QueryRefresher {
    static build(queries: ListQueries, handler: QueryChangedHandler): QueryRefresher {
        return new GbtQueryRefresher(queries, handler);
    }

    constructor(readonly queries: ListQueries, readonly handler: QueryChangedHandler) {}

    tableVersions(): QueryIDsWithTableVersions {
        const tableVersions: QueryIDsWithTableVersions = {};
        for (const active of this.queries()) {
            if (!isRelevantQuery(active)) continue;
            if (!hasTableVersions(active)) continue;
            tableVersions[active.subQueryID] = active.tableVersions;
        }

        return tableVersions;
    }

    requery(queryIDs: Set<string>) {
        let requeried = false;
        for (const active of this.queries()) {
            if (!isRelevantQuery(active)) continue;
            if (!queryIDs.has(active.subQueryID)) continue;

            requery(active);
            requeried = true;
        }

        if (!requeried) return;
        this.handler();
    }

    onAfterQuery(query: ActiveQueryBase) {
        const { cause, ...subQuery } = query.subQuery;
        query.subQuery = subQuery;
    }
}

function isRelevantQuery(query: ActiveQueryBase): boolean {
    if (query.state !== QueryState.Done) return false;
    if (query.callbacks.size === 0) return false;
    return true;
}

function hasTableVersions(query: ActiveQueryBase): query is ActiveQueryBase & { tableVersions: QueryTableVersions } {
    return query.tableVersions !== undefined;
}

function requery(query: ActiveQueryBase) {
    query.state = QueryState.Execute;
    query.subQuery = { ...query.subQuery, cause: "requery" };
    if (!isLoadingValue(query.currentValue)) {
        query.lastValue = query.currentValue;
        query.currentValue = makeLoadingValueWithDisplayValue(query.currentValue);
    }
}

export type BuildQueryRefresher = (queries: ListQueries, handler: QueryChangedHandler) => QueryRefresher;

export class RunningQueryFreshener {
    constructor(readonly buildQueryRefresher: BuildQueryRefresher, readonly stop: () => void) {}
}

export interface AppDetails {
    readonly appID: string;
    readonly appFacilities: ActionAppFacilities;
}

export type GetQueries = () => IterableIterator<QueryRefresher | undefined>;
export type GetAppDetails = () => AppDetails;
export type StartQueryFreshener = (
    interval: number,
    queries: GetQueries,
    getAppDetails: GetAppDetails
) => RunningQueryFreshener;

export class NoOpQueryFreshener {
    static start(_interval: number, _queries: GetQueries, _getAppDetails: GetAppDetails): RunningQueryFreshener {
        return new RunningQueryFreshener(NoOpQueryRefresher.build, () => {
            return;
        });
    }
}

interface GbtQueryDependencies {
    readonly check: typeof checkQueryTableVersions;
}

export class GbtQueryFreshener {
    static start(interval: number, queries: GetQueries, getAppDetails: GetAppDetails): RunningQueryFreshener {
        const freshener = new GbtQueryFreshener(queries, getAppDetails);
        const stop = Periodically.run(interval, () => freshener.reload());
        return new RunningQueryFreshener(GbtQueryRefresher.build, stop);
    }

    check: GbtQueryDependencies["check"];

    constructor(
        readonly queries: GetQueries,
        readonly getAppDetails: GetAppDetails,
        { check = checkQueryTableVersions }: Partial<GbtQueryDependencies> = {}
    ) {
        this.check = check;
    }

    async reload() {
        const env = this.getAppDetails();
        if (env === undefined) return;

        const allTableVersions: QueryIDsWithTableVersions = {};

        for (const refresher of this.queries()) {
            if (refresher === undefined) continue;
            Object.assign(allTableVersions, refresher.tableVersions());
        }

        if (Object.keys(allTableVersions).length === 0) return;

        const response = await this.check(env.appID, allTableVersions, env.appFacilities);
        const changedQueryResults: Record<string, true> = mapRecordFilterUndefined(
            response.queryResults,
            r => r.didChange || undefined
        );

        const changedQueryIDs = new Set(Object.keys(changedQueryResults));
        if (changedQueryIDs.size === 0) return;

        for (const refresher of this.queries()) {
            if (refresher === undefined) continue;
            refresher.requery(changedQueryIDs);
        }
    }
}
