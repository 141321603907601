/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import type { JSONObject } from "@glide/support";
import {
    ConcurrencyLimiterWithBackpressure,
    audioProperties,
    isEmptyOrUndefined,
    isUndefinedish,
    maybeParseJSON,
    removeUndefinedProperties,
} from "@glide/support";
import { default as NodeFormData } from "form-data";
import orderBy from "lodash/orderBy";
import * as t from "io-ts";
import { isLeft } from "fp-ts/lib/Either";
import { isPluginTable } from "@glide/plugins-codecs";

import * as prompts from "./prompts";

const { Result } = glide;

// This is a legacy api that is still supported by the API
const defaultModelParams: Pick<ModelParams, "model" | "max_tokens"> = { model: "gpt-3.5-turbo", max_tokens: 255 };

export const defaultChatModelParams: Pick<ModelParams, "model" | "max_tokens" | "seed"> = {
    model: "gpt-3.5-turbo",
    max_tokens: 255,
    seed: 42,
};

// https://platform.openai.com/docs/api-reference/chat/create#chat/create-max_tokens
const chatGPTTokenLimit = 4096;
const charsPerToken = 4;

const apiKey = glide.makeParameter({
    type: "secret",
    name: "API key",
    required: true,
});

export const plugin = glide.newPlugin({
    name: "OpenAI (Legacy)",
    id: "open-ai",
    description:
        "This is the legacy OpenAI Integration. You are seeing this because your app added this integration prior to the update. Re-add the OpenAI integration to use the latest features.",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/openai.png",
    parameters: {
        apiKey,
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/openai-and-glide",
    deprecated: true,
});

plugin.useSecret({
    kind: "authorization-bearer",
    baseUrl: "https://api.openai.com",
    value: apiKey,
});

export const pluginV2 = glide.newPlugin({
    name: "OpenAI",
    id: "open-ai-v2",
    description: "Complete prompts, generate images, and analyze text with OpenAI",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/openai.png",
    experimentFlag: "pricingv4-openAIv2Plugin",
    tier: "starter",
    parameters: {
        apiKey,
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/openai-and-glide",
});

pluginV2.useSecret({
    kind: "authorization-bearer",
    baseUrl: "https://api.openai.com",
    value: apiKey,
});

export interface ModelParams {
    model: string;
    temperature: number;
    max_tokens: number;
    frequency_penalty: number;
    seed?: number;
}

// https://platform.openai.com/docs/models/model-endpoint-compatibility
const completionGPTRegex = /^(gpt-3\.5-turbo-instruct|babbage-002|davinci-002)$/;

async function completePrompt(
    prompt: string,
    context: Omit<glide.ServerExecutionContext, "uploadFile" | "rehostFile">,
    modelParams: Partial<ModelParams> = {}
): Promise<glide.Result<string>> {
    if (modelParams.model !== undefined && completionGPTRegex.test(modelParams.model)) {
        const response = await context.fetch(`https://api.openai.com/v1/completions`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            body: JSON.stringify({
                prompt,
                ...defaultModelParams,
                ...removeUndefinedProperties(modelParams),
            }),
        });

        const asText = await response.text();
        const asJSON = JSON.parse(asText);

        if (response.ok !== true) {
            try {
                if (asJSON.error.message !== null) {
                    return Result.FailFromHTTPStatus(asJSON.error.message, response.status, {
                        data: maybeParseJSON(asText),
                    });
                }
            } catch {
                return Result.FailFromHTTPStatus(`Unknown error: ${asText}`, response.status, {
                    data: maybeParseJSON(asText),
                });
            }
            return Result.FailFromHTTPStatus("Could not complete chat", response.status, {
                data: maybeParseJSON(asText),
            });
        }

        let resultText = (asJSON.choices[0].text as string) ?? "";
        resultText = resultText.trim();
        return Result.Ok(resultText);
    } else {
        const res = await chatPrompt(prompt, undefined, context, [], modelParams);
        if (res.ok === false) {
            return res;
        }
        return Result.Ok(res.result.message);
    }
}

function addPromptCompletionComputation(props: {
    id: string;
    name: string;
    description: string;
    input?: {
        name: string;
        description: string;
    };
    result?: {
        name: string;
        description: string;
    };
    temperature?: number;
    maxTokens?: number;
    frequencyPenalty?: number;
    transformResult?: (result: string) => string;
    prompt(input: string): string;
    deprecated?: boolean;
}) {
    const {
        id,
        name,
        description,
        input = {
            name: "Prompt",
            description: "The prompt sent to OpenAI",
        },
        result = {
            name: "Result",
            description: "Choose the column where the result from OpenAI will be saved",
        },
        temperature,
        maxTokens,
        frequencyPenalty,
        transformResult = x => x,
        deprecated,
    } = props;

    plugin.addComputation({
        deprecated,
        id,
        name,
        description,
        configurationDescriptionPattern: "To ${result}",
        billablesConsumed: 1,
        parameters: {
            input: glide.makeParameter({ type: "string", ...input, multiLine: true }),
        },
        results: {
            result: glide.makeParameter({ type: "string", ...result }),
        },
        execute: async (context, { input: inputText = "" }) => {
            if (inputText.length === 0) {
                return Result.FailPermanent(`Please provide ${input.name}`, {
                    isPluginError: false,
                });
            }

            const prompt = props.prompt(inputText);

            const cachedCompletion = await context.useCache(async () => {
                const completion = await completePrompt(prompt, context, {
                    temperature,
                    max_tokens: maxTokens,
                    frequency_penalty: frequencyPenalty,
                });
                if (completion.ok === true) {
                    context.consumeBillable();
                }
                return completion;
            }, [prompt]);

            if (cachedCompletion.ok === false) {
                return cachedCompletion;
            }

            let { result } = cachedCompletion;
            if (result !== undefined) {
                result = transformResult(result);
            }
            return Result.Ok({ result });
        },
    });
}

addPromptCompletionComputation({
    deprecated: true,
    id: "summarize-text",
    name: "Summarize",
    description: "Summarize text with OpenAI",
    temperature: 0.7,
    prompt: x => `Summarize this text:\n\n${x}`,
});

addPromptCompletionComputation({
    deprecated: true,
    id: "suggest-a-color",
    name: "Suggest a color",
    description: "Suggests a color based on a prompt",
    result: {
        name: "Color",
        description: "The color suggested by OpenAI",
    },
    temperature: 0,
    maxTokens: 64,
    prompt: x => `Provide the CSS code for a color that reminds me of ${x}:\n\nbackground-color: #`,
    transformResult: x => `#${x}`.replace(";", ""),
});

addPromptCompletionComputation({
    deprecated: true,
    id: "extract-keywords",
    name: "Extract keywords",
    description: "Extract keywords from text using OpenAI",
    temperature: 0.5,
    maxTokens: 64,
    frequencyPenalty: 0.8,
    prompt: x => `Extract keywords for this text:\n\nText: ${x}\nKeywords: `,
});

addPromptCompletionComputation({
    deprecated: true,
    id: "analyze-sentiment",
    name: "Analyze sentiment",
    description: "Analyzes a prompt as positive, negative, or neutral",
    prompt: prompts.sentimentAnalysis,
});

addPromptCompletionComputation({
    deprecated: true,
    id: "suggest-emoji",
    name: "Suggest an emoji",
    description: "Suggests an emoji based on a prompt",
    prompt: prompts.suggestEmoji,
});

addPromptCompletionComputation({
    deprecated: true,
    id: "correct-grammer",
    name: "Correct grammar",
    description: "Corrects sentences into standard English with OpenAI",
    input: {
        name: "Phrase",
        description: "The phrase to correct for grammar",
    },
    result: {
        name: "Corrected Phrase",
        description: "The phrase corrected for grammar and spelling",
    },
    prompt: input => `Correct this to standard English: \n\n${input}`,
});

addPromptCompletionComputation({
    deprecated: true,
    id: "answer-a-question",
    name: "Answer question",
    description: "Answers a question based on a prompt",
    input: {
        name: "Question",
        description: "Question to answer",
    },
    result: {
        name: "Answer",
        description: "Answer generated by OpenAI that may or may not be incorrect",
    },
    prompt: prompts.answerQuestion,
});

const defaultDalleModel = "dall-e-3";
const defaultDalleImageSize = "1024x1024";

const generateImageParameters = {
    description: glide.makeParameter({
        type: "string",
        name: "Prompt",
        required: true,
        description:
            "A text description of the image you want Dall-E to generate. The maximum length is 1000 characters for dall-e-2 and 4000 characters for dall-e-3.",
    }),
    model: glide.makeParameter({
        type: "string",
        name: "Model",
        defaultValue: defaultDalleModel,
        description: "The model to use for image generation. Must be either dall-e-2 or dall-e-3.",
        emptyByDefault: true,
    }),
    size: glide.makeParameter({
        type: "string",
        name: "Size",
        description:
            "The size of the generated image. Must be one of 256x256, 512x512, or 1024x1024 for dall-e-2. Must be one of 1024x1024, 1792x1024, or 1024x1792 for dall-e-3 models.",
        defaultValue: defaultDalleImageSize,
        emptyByDefault: true,
    }),
    style: glide.makeParameter({
        type: "string",
        name: "Style",
        description:
            "The style of the generated images. Must be one of vivid or natural. Vivid causes the model to lean towards generating hyper-real and dramatic images. Natural causes the model to produce more natural, less hyper-real looking images. Only supported for dall-e-3.",
        defaultValue: "vivid",
        emptyByDefault: true,
    }),
    hd: glide.makeParameter({
        type: "boolean",
        name: "HD",
        description: "Generate a high-definition image. Only supported for dall-e-3.",
        defaultValue: false,
        emptyByDefault: true,
    }),
};

const generateImageResult = {
    imageUrl: glide.makeParameter({
        type: "string",
        name: "Image output",
        description: "Choose the column where the URL to the generated image will be saved",
    }),
};

type PluginParameters = NonNullable<typeof plugin.fields.parameters>;

type GenerateImageParameters = typeof generateImageParameters;
type GenerateImageResult = typeof generateImageResult;

const generateImageAction: Omit<
    glide.Action<GenerateImageResult, GenerateImageParameters, PluginParameters, "server">,
    "type"
> = {
    id: "generate-image",
    name: "Generate image",
    description: "Use Dall-E to generate an image from a prompt",
    configurationDescriptionPattern: "To ${imageUrl}",
    billablesConsumed: 1,
    parameters: generateImageParameters,
    results: generateImageResult,
    execute: async (context, { description, apiKey, model, hd, size, style }) => {
        // Use the `fetch` API to make a POST request to the Dall-E endpoint
        // Pass the text description in the request body

        const hdParams = hd === true ? { quality: "hd" } : {};
        const styleParams = style === undefined ? {} : { style };
        const imageUrl = await context.useCache(async () => {
            const response = await context.fetch("https://api.openai.com/v1/images/generations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${apiKey}`,
                },
                body: JSON.stringify({
                    model: model ?? defaultDalleModel,
                    prompt: description,
                    n: 1,
                    size: size ?? defaultDalleImageSize,
                    response_format: "url",
                    ...styleParams,
                    ...hdParams,
                }),
            });

            if (!response.ok)
                return Result.FailFromHTTPStatus("Could not fetch", response.status, {
                    data: maybeParseJSON(await response.text()),
                });

            try {
                const json = await response.json();
                const imageUrl = json.data[0].url as string;
                const rehostedImageUrl = await context.rehostFile("generated.png", imageUrl);

                if (rehostedImageUrl.ok === false)
                    return Result.Fail(`Could not rehost image`, {
                        ...rehostedImageUrl.data,
                        status: response.status,
                        data: maybeParseJSON(json),
                    });

                context.consumeBillable();
                return Result.Ok(rehostedImageUrl.result);
            } catch (e: unknown) {
                return Result.Fail(`Could not get image`, {
                    data: maybeParseJSON(e),
                });
            }
        }, [description]);

        if (imageUrl.ok === false) return imageUrl;

        return Result.Ok({ imageUrl: imageUrl.result });
    },
};

// Define the Dall-E action
plugin.addAction({ ...generateImageAction, deprecated: true });
pluginV2.addAction(generateImageAction);

const otherModelParams = {
    temperature: glide.makeParameter({
        type: "number",
        name: "Temperature",
        description:
            "What sampling temperature to use between 0 and 2. Higher values like 0.8 will make the output more random while lower values like 0.2 will make it more focused and deterministic. [Learn more](https://platform.openai.com/docs/quickstart/adjust-your-settings) about temperature",
        propertySection: {
            name: "Model Tweaks",
            order: 1,
            collapsed: true,
        },
        emptyByDefault: true,
    }),

    max_tokens: glide.makeParameter({
        type: "number",
        name: "Maximum Length",
        description:
            "The maximum number of tokens to generate in the completion. The token count of your prompt plus max_tokens cannot exceed the model's context length. Most models have a context length of 2048 tokens (except for the newest models, which support 4096).",
        placeholder: `e.g. ${defaultModelParams.max_tokens?.toString()}`,
        defaultValue: defaultModelParams.max_tokens,
        propertySection: {
            name: "Model Tweaks",
            order: 1,
            collapsed: true,
        },
    }),

    frequency_penalty: glide.makeParameter({
        type: "number",
        name: "Frequency Penalty",
        description:
            "The frequency penalty to use between -2 and 2. Higher values like 1.2 will make the output more random while lower values like 0.8 will make it more focused and deterministic. [Learn more](https://platform.openai.com/docs/api-reference/parameter-details) about frequency penalties",
        propertySection: {
            name: "Model Tweaks",
            order: 1,
            collapsed: true,
        },
        emptyByDefault: true,
    }),
};

plugin.addComputation({
    deprecated: true,
    id: "complete-prompt",
    name: "Complete prompt",
    description: "Complete a prompt using OpenAI's GPT-3 API",
    configurationDescriptionPattern: "To ${result}",
    billablesConsumed: 1,
    parameters: {
        prompt: glide.makeParameter({ type: "string", name: "Prompt" }),

        model: glide.makeParameter({
            type: "string",
            name: "Model",
            description:
                "The OpenAI model used to complete the prompt. If not set, Glide will preselect a model. This action is compatible with [`/v1/completions` models](https://platform.openai.com/docs/models/model-endpoint-compatibility)",
            placeholder: `e.g. ${defaultModelParams.model.toString()}`,
            defaultValue: defaultModelParams.model,
        }),

        ...otherModelParams,
    },
    results: {
        result: glide.makeParameter({ type: "string", name: "Result" }),
    },
    execute: async (context, { prompt, model, temperature, max_tokens, frequency_penalty }) => {
        if (prompt === undefined || prompt.length === 0) {
            return Result.FailPermanent(`Please provide prompt`, {
                isPluginError: false,
            });
        }

        const completionParams: Partial<ModelParams> = { model, temperature, max_tokens, frequency_penalty };
        const cachedCompletion = await context.useCache(async () => {
            const completion = await completePrompt(prompt, context, completionParams);
            if (completion.ok === true) {
                context.consumeBillable();
            }
            return completion;
        }, [prompt, completionParams]);

        if (cachedCompletion.ok === false) {
            return cachedCompletion;
        }

        const { result } = cachedCompletion;
        return Result.Ok({ result });
    },
});

const chatRoles = ["system", "user", "assistant"] as const;

const columnToValue = (c: { displayName?: string; name: string }): string => c.displayName ?? c.name;
type ChatContentItem = { type: "text"; text: string } | { type: "image_url"; image_url: { url: string } };
type ChatContent = string | ChatContentItem[];
type ChatMessage = { role: string; content: ChatContent };
export type ChatMessages = ChatMessage[];
type Functions = { name: string; parameters: JSONObject; description?: string }[];

const chatPromptResponseCodec = t.type({
    choices: t.array(
        t.type({
            message: t.intersection([
                t.type({
                    content: t.union([t.string, t.null]),
                }),
                t.partial({
                    function_call: t.type({
                        arguments: t.string,
                    }),
                }),
            ]),
        })
    ),
    usage: t.type({
        total_tokens: t.number,
    }),
});

export function rowsToMessages(
    columns: glide.PluginTable["columns"],
    rows: glide.PluginTable["rows"],
    sessionID: string,
    charsLeft: number
): glide.Result<ChatMessages> {
    const messages = [];
    const roleIndex = columns.findIndex(c => columnToValue(c).toLowerCase() === "role");
    const contentIndex = columns.findIndex(c => columnToValue(c).toLowerCase() === "content");
    const tsIndex = columns.findIndex(
        c =>
            ["time", "date", "timestamp", "time stamp"].includes(columnToValue(c).toLowerCase()) &&
            c.type === "dateTime"
    );
    const sessionIdIndex = columns.findIndex(
        c => ["sessionid", "session id"].includes(columnToValue(c).toLowerCase()) && c.type === "string"
    );

    if (roleIndex === -1 || contentIndex === -1 || tsIndex === -1 || sessionIdIndex === -1) {
        return Result.FailPermanent(
            `Your message history table must have "Role", "Content", "Session ID", and "Timestamp" columns`,
            {
                isPluginError: false,
            }
        );
    }

    let currentChars = charsLeft;

    const reverseChronologicalMessages = orderBy(
        rows.filter(r => r[tsIndex] !== null && r[sessionIdIndex] === sessionID),
        r => (r[tsIndex] !== null ? r[tsIndex] : -1),
        "desc"
    );

    for (const row of reverseChronologicalMessages) {
        const role = row[roleIndex];
        const content = row[contentIndex];

        if (typeof role !== "string") {
            return Result.FailPermanent(
                "Invalid message history row | Ensure each row has a role column string value",
                {
                    isPluginError: false,
                }
            );
        }
        if (typeof content !== "string") {
            return Result.FailPermanent(
                "Invalid message history row | Ensure each row has a content column string value",
                {
                    isPluginError: false,
                }
            );
        }

        const cleanedRole = role.trim().toLowerCase();
        const cleanedContent = content.trim();
        if (chatRoles.includes(cleanedRole as any) && content !== undefined) {
            if (currentChars - cleanedContent.length < 0) {
                break;
            }
            messages.push({ role: cleanedRole, content: cleanedContent });
            currentChars -= cleanedContent.length;
        } else {
            return Result.FailPermanent(`Ensure role is one of ${chatRoles.join(", ")} and there is content`, {
                isPluginError: false,
            });
        }
    }
    return Result.Ok(messages.reverse()); // reverse to get chronological order for api
}

type TextContent = { type: "text"; text: string };
type ImageContent = { type: "image_url"; image_url: { url: string } };
type ChatInput = string | (TextContent | ImageContent)[];

const makeJsonPrompt = "Ensure JSON output format.";

export function chatPromptOptions(
    input: ChatInput,
    prompt: string | undefined,
    context: Omit<glide.ServerExecutionContext, "uploadFile" | "rehostFile">,
    options: Partial<{
        otherMessages: ChatMessages;
        modelParams: Partial<ModelParams>;
        headers: HeadersInit;
        functions: Functions;
        functionCall: string | { name: string };
        jsonOutput: boolean;
    }> = {}
): Promise<glide.Result<{ message: string; tokensUsed: number }>> {
    return chatPrompt(
        input,
        prompt,
        context,
        options.otherMessages,
        { ...defaultChatModelParams, ...options.modelParams },
        options.headers,
        options.functions,
        options.functionCall,
        options.jsonOutput
    );
}

export async function chatPrompt(
    input: ChatInput,
    prompt: string | undefined,
    context: Pick<glide.ServerExecutionContext, "fetch">,
    otherMessages: ChatMessages = [],
    modelParams: Partial<ModelParams> = {},
    headers?: HeadersInit,
    functions?: Functions,
    functionCall?: string | { name: string },
    jsonOutput?: boolean
): Promise<glide.Result<{ message: string; tokensUsed: number }>> {
    const newPrompt = jsonOutput === true ? `${makeJsonPrompt} ${prompt ?? ""}` : prompt;
    const messages = [
        ...(newPrompt !== undefined ? [{ role: "system", content: newPrompt.trim() }] : []),
        ...otherMessages,
        { role: "user", content: input },
    ];

    const responseFormat = jsonOutput === true ? { response_format: { type: "json_object" } } : {};

    const response = await context.fetch(`https://api.openai.com/v1/chat/completions`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
        redirect: "follow",
        body: JSON.stringify({
            messages,
            ...defaultChatModelParams,
            ...removeUndefinedProperties(modelParams),
            functions,
            function_call: functionCall,
            ...responseFormat,
        }),
    });

    const asText = await response.text();
    const asJSON = JSON.parse(asText);

    if (response.ok !== true) {
        try {
            if (asJSON.error.message !== null) {
                return Result.FailFromHTTPStatus(asJSON.error.message, response.status, {
                    data: maybeParseJSON(asText),
                });
            }
        } catch {
            return Result.FailFromHTTPStatus(`Unknown error: ${asText}`, response.status, {
                data: maybeParseJSON(asText),
            });
        }
        return Result.FailFromHTTPStatus("Could not complete chat", response.status, {
            data: maybeParseJSON(asText),
        });
    }

    const decoded = chatPromptResponseCodec.decode(asJSON);
    if (isLeft(decoded)) {
        return Result.Fail(`Could not parse output`, {
            data: asJSON,
        });
    }
    const tokensUsed = decoded.right.usage.total_tokens;
    const message = decoded.right.choices[0].message;
    const messageContent = message.function_call?.arguments ?? message.content ?? "";

    return Result.Ok({ message: messageContent.trim(), tokensUsed });
}

const sendMessageToBotParameters = {
    table: glide.makeParameter({
        type: "table",
        name: "Message History",
        required: true,
        description: `Each row must have 4 columns: Role(${chatRoles.join(
            ", "
        )}), Content (text content), Session ID (unique value for each session), Timestamp (datetime). [Learn more](https://platform.openai.com/docs/guides/chat/introduction)`,
    }),
    prompt: glide.makeParameter({
        type: "string",
        name: "Prompt (system)",
        description:
            "A short description of what the bot should do (or not do). Treat it like a mission statement or constitution for your bot.",
        required: true,
        emptyByDefault: true,
    }),
    input: glide.makeParameter({
        type: "string",
        name: "Message",
        required: true,
        useTemplate: "withLabel",
    }),

    sessionID: glide.makeParameter({
        type: "string",
        name: "Session ID",
        description:
            "A uniq value for each session (e.g. user ID, conversation ID, etc.) this must match the column name `Session ID` in your table",
        required: true,
        emptyByDefault: true,
    }),

    model: glide.makeParameter({
        type: "string",
        name: "Model",
        description:
            "The OpenAI model used to complete the prompt. If not set, Glide will preselect a model. This action is compatible with [`/v1/chat/completions` models](https://platform.openai.com/docs/models/model-endpoint-compatibility)",
        placeholder: `e.g. ${defaultChatModelParams.model.toString()}`,
        defaultValue: defaultChatModelParams.model,
    }),

    ...otherModelParams,
};

const sendMessageToBotResult = {
    result: glide.makeParameter({ type: "string", name: "Result" }),
};

type SendMessageToBotParameters = typeof sendMessageToBotParameters;
type SendMessageToBotResult = typeof sendMessageToBotResult;

const sendMessageToBotAction: Omit<
    glide.Action<SendMessageToBotResult, SendMessageToBotParameters, PluginParameters, "server">,
    "type"
> = {
    id: "send-message-to-bot",
    name: "Complete chat (with history)",
    description: "Make your own chatbot using the ChatGPT API",
    configurationDescriptionPattern: "To ${result}",
    billablesConsumed: 1,
    parameters: sendMessageToBotParameters,
    results: sendMessageToBotResult,

    execute: async (context, { table, prompt, input, apiKey, sessionID, ...completionParams }) => {
        if (input === undefined || input.trim().length === 0) {
            return Result.FailPermanent(`Please provide a message`);
        }
        if (prompt === undefined) {
            return Result.FailPermanent(`Please provide a prompt`);
        }
        if (sessionID === undefined)
            return Result.FailPermanent("Please provide a session ID", {
                isPluginError: false,
            });
        if (!isPluginTable(table))
            return glide.Result.FailPermanent("Invalid message history table", {
                isPluginError: false,
            });
        const { columns, rows } = table;
        const charsLeft =
            chatGPTTokenLimit * charsPerToken -
            input.trim().length -
            prompt.trim().length -
            (completionParams.max_tokens ?? defaultChatModelParams.max_tokens);
        const cleanedRows = rowsToMessages(columns, rows, sessionID, charsLeft);

        if (cleanedRows.ok === false) {
            return cleanedRows;
        }

        const modelParams: Partial<ModelParams> = completionParams;

        const cachedCompletion = await context.useCache(async () => {
            const result = await chatPrompt(input, prompt, context, cleanedRows.result, modelParams);

            if (!result.ok) {
                return result;
            }
            context.consumeBillable();

            return Result.Ok({ result: result.result.message });
        }, [prompt, input, apiKey, modelParams]);

        return cachedCompletion;
    },
};

plugin.addAction({ ...sendMessageToBotAction, deprecated: true });
pluginV2.addAction(sendMessageToBotAction);

const sendMessageToBotNoHistoryParameters = {
    prompt: glide.makeParameter({
        type: "string",
        name: "Prompt (system)",
        description:
            "A short description of what the bot should do (or not do). Treat it like a mission statement or constitution for your bot.",
        emptyByDefault: true,
    }),
    input: glide.makeParameter({
        type: "string",
        name: "Message",
        required: true,
        useTemplate: "withLabel",
    }),

    model: glide.makeParameter({
        type: "string",
        name: "Model",
        description:
            "The OpenAI model used to complete the prompt. If not set, Glide will preselect a model. This action is compatible with [`/v1/chat/completions` models](https://platform.openai.com/docs/models/model-endpoint-compatibility)",
        placeholder: `e.g. ${defaultChatModelParams.model.toString()}`,
        defaultValue: defaultChatModelParams.model,
    }),
    image: glide.makeParameter({
        type: "string",
        name: "Image",
        description: "Add image url to content list",
        emptyByDefault: true,
    }),
    ...otherModelParams,
    jsonOutput: glide.makeParameter({
        type: "boolean",
        name: "JSON Output",
        description: "Format in JSON instead of plain text",
        defaultValue: false,
        propertySection: {
            name: "Model Tweaks",
            order: 1,
            collapsed: true,
        },
    }),
};

type SendMessageToBotNoHistoryParameters = typeof sendMessageToBotNoHistoryParameters;

const sendMessageToBotNoHistoryColumn: Omit<
    glide.SimpleComputation<"string", SendMessageToBotNoHistoryParameters, PluginParameters, "server">,
    "type"
> = {
    id: "send-message-to-bot-no-history",
    name: "Complete chat",
    description: "Make your own chatbot using the ChatGPT API",
    configurationDescriptionPattern: "To ${result}",
    billablesConsumed: 1,
    parameters: sendMessageToBotNoHistoryParameters,
    result: "string",
    execute: async (context, { prompt, input, apiKey, jsonOutput, image, ...completionParams }) => {
        if (input === undefined || input.trim().length === 0) {
            return Result.FailPermanent(`Please provide a message`);
        }

        const formatedInput =
            image !== undefined
                ? [
                      { type: "text" as const, text: input },
                      { type: "image_url" as const, image_url: { url: image } },
                  ]
                : input;

        const modelParams: Partial<ModelParams> = completionParams;

        const cachedCompletion = await context.useCache(async () => {
            const result = await chatPrompt(
                formatedInput,
                prompt,
                context,
                [],
                modelParams,
                undefined,
                undefined,
                undefined,
                jsonOutput
            );

            if (!result.ok) {
                return result;
            }
            context.consumeBillable();

            return Result.Ok(result.result.message);
        }, [formatedInput, prompt, apiKey, modelParams, jsonOutput]);

        return cachedCompletion;
    },
};

plugin.addColumn({ ...sendMessageToBotNoHistoryColumn, deprecated: true });
pluginV2.addColumn(sendMessageToBotNoHistoryColumn);

function cosineSimilarity(a: number[], b: number[]): number {
    if (a.length !== b.length) {
        throw new Error("Arrays must have the same length");
    }
    const dotProduct = a.reduce((acc, curr, i) => acc + curr * b[i], 0);
    const magnitudeA = Math.sqrt(a.reduce((acc, curr) => acc + curr ** 2, 0));
    const magnitudeB = Math.sqrt(b.reduce((acc, curr) => acc + curr ** 2, 0));
    return dotProduct / (magnitudeA * magnitudeB);
}

export async function getEmbeddings(
    context: glide.ServerExecutionContext,
    apiKey: string,
    rowStrings: string[],
    model: string
): Promise<glide.Result<number[][]>> {
    const response = await context.fetch("https://api.openai.com/v1/embeddings", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
            model,
            input: rowStrings,
        }),
    });

    if (!response.ok)
        return Result.FailFromHTTPStatus(`Could not get embeddings | ${response.statusText}`, response.status, {
            data: maybeParseJSON(await response.text()),
        });

    const json = await response.json();
    return Result.Ok(json.data.map((x: { embedding: number[] }) => x.embedding) as number[][]);
}

const tableQuestionParameters = {
    question: glide.makeParameter({
        type: "string",
        name: "Question",
        description: "The question you would like to ask about your table",
        required: true,
        emptyByDefault: true,
    }),
    table: glide.makeParameter({
        type: "table",
        name: "Source table",
        required: true,
    }),
    query: glide.makeParameter({
        type: "string",
        name: "Row specifier",
        description: "An optional prompt to direct the AI to a specific row",
        emptyByDefault: true,
    }),
    questionPrompt: glide.makeParameter({
        type: "string",
        name: "Additional context",
        description: "An additional prompt can be added to direct the AI in answering the question",
        emptyByDefault: true,
    }),
    ...otherModelParams,
};

const tableQuestionResults = {
    answer: glide.makeParameter({
        type: "string",
        name: "Answer",
    }),
};

type TableQuestionParameters = typeof tableQuestionParameters;
type TableQuestionResults = typeof tableQuestionResults;

const tableQuestionAction: Omit<
    glide.Action<TableQuestionResults, TableQuestionParameters, PluginParameters, "server">,
    "type"
> = {
    id: "table-question",
    name: "Answer question about a table",
    description: "Finds the most relevant row in your table to answer the prompt",
    configurationDescriptionPattern: "To ${answer}",
    billablesConsumed: 1,
    parameters: tableQuestionParameters,
    results: tableQuestionResults,
    // Define the function to execute when the action is called
    execute: async (
        context,
        { table, query, question, questionPrompt, apiKey = "", temperature, max_tokens, frequency_penalty }
    ) => {
        if (!isPluginTable(table)) return glide.Result.FailPermanent("Invalid input table");
        if (question === undefined || question.trim().length === 0)
            return glide.Result.FailPermanent("Please provide a question", {
                isPluginError: false,
            });
        const { columns, rows } = table;
        const headerRow = columns.map(c => c.displayName ?? c.name);

        const rowsStrings: string[] = rows
            .map(row => row.map((field, i) => (isUndefinedish(field) ? "" : `${headerRow[i]}: ${field}`)).join(" | "))
            .filter(x => !isEmptyOrUndefined(x));
        if (headerRow.length === 0) {
            return Result.FailPermanent("Table must have at least one column - use Select Columns to add a column", {
                isPluginError: false,
            });
        }

        const trimmedQuery = query?.trim();
        const input: string[] = [isEmptyOrUndefined(trimmedQuery) ? question : trimmedQuery, ...rowsStrings];

        const result = await context.useCache(async () => {
            const limiter = new ConcurrencyLimiterWithBackpressure(50);
            const embeddings: number[][] = [];

            // Set up a timeout to abort the request if it takes too long
            const controller = new AbortController();
            const timeoutMS = 1000 * 20;
            setTimeout(() => controller.abort(), timeoutMS);
            let error: string | undefined;
            for (const [index, rowString] of input.entries()) {
                await limiter.run(async () => {
                    const embedding = await context.useCache(
                        async () => {
                            const result = await getEmbeddings(context, apiKey, [rowString], "text-embedding-ada-002");
                            if (!result.ok) {
                                return result;
                            }
                            return Result.Ok(result.result[0]);
                        },
                        ["embedding-cache", rowString],
                        true
                    );

                    if (embedding.ok) {
                        embeddings[index] = embedding.result;
                    } else {
                        if (error === undefined) {
                            error = embedding.message;
                        }
                    }
                    return;
                }, controller.signal);
            }
            await limiter.finish();

            if (error !== undefined) {
                return Result.Fail(error, {
                    isPluginError: false,
                });
            }

            const firstEmbedding = embeddings[0];
            const otherEmbeddings = embeddings.slice(1);
            const rowsWithSimilarity = rowsStrings.slice(0, otherEmbeddings.length).map((row, i) => ({
                row,
                similarity: cosineSimilarity(firstEmbedding, otherEmbeddings[i]),
            }));
            rowsWithSimilarity.sort((a, b) => b.similarity - a.similarity);

            const promptContext = rowsWithSimilarity
                // TODO pack more context if we can
                .slice(0, 5)
                .map(({ row }) => row)
                .join("\n");

            const prompt = prompts.tableQuestion(questionPrompt ?? "", promptContext, question);

            const completionParams: Partial<ModelParams> = { temperature, max_tokens, frequency_penalty };

            const comp = await completePrompt(prompt, context, completionParams);

            if (!comp.ok) return comp;
            context.consumeBillable();

            return Result.Ok(comp.result?.trim());
        }, [questionPrompt, input, otherModelParams]);

        if (!result.ok) return result;

        return Result.Ok({ answer: result.result });
    },
};

plugin.addAction({ ...tableQuestionAction, deprecated: true });
pluginV2.addAction(tableQuestionAction);

const DEFAULT_WHISPER_MODEL = "whisper-1";

export const speechToText = async (
    context: Omit<glide.ServerExecutionContext, "uploadFile" | "rehostFile">,
    url: string | undefined,
    apiKey: string | undefined
) => {
    if (url === undefined)
        return Result.FailPermanent("Please provide a url", { isPluginError: false, showInBuilder: false });
    if (apiKey === undefined) return Result.FailPermanent("API key is not set");

    const blob = await context.fetch(url).then(r => r.blob());
    const extension = url.split(".").pop();

    const formData = new NodeFormData();
    formData.append("file", Buffer.from(await blob.arrayBuffer()), `f.${extension}`);
    formData.append("model", DEFAULT_WHISPER_MODEL);

    const response = await context.fetch("https://api.openai.com/v1/audio/transcriptions", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${apiKey}`,
            ...formData.getHeaders(),
        },
        body: formData as any,
    });

    if (!response.ok) {
        return Result.FailFromHTTPStatus(
            `Error ${response.status}: ${response.statusText} - ${await response.text()}`,
            response.status
        );
    }

    const { text } = await response.json();
    context.consumeBillable();

    return Result.Ok({ text });
};

const whisperSpeechToTextParameters = {
    url: glide.makeParameter({
        type: "url",
        name: "Audio File",
        description: "The audio to transcribe",
        required: true,
        preferredNames: [...audioProperties],
    }),
};

const whisperSpeechToTextResults = {
    text: glide.makeParameter({
        type: "string",
        name: "Text",
    }),
};

type WhisperSpeechToTextParameters = typeof whisperSpeechToTextParameters;
type WhisperSpeechToTextResults = typeof whisperSpeechToTextResults;
const whisperSpeechToTextComputation: Omit<
    glide.Computation<WhisperSpeechToTextResults, WhisperSpeechToTextParameters, PluginParameters, "server">,
    "type"
> = {
    id: "whisper-speech-to-text",
    name: "Speech to text",
    description: "Use Whisper to transcribe speech to text",
    configurationDescriptionPattern: "To ${text}",
    billablesConsumed: 5,
    parameters: whisperSpeechToTextParameters,
    results: whisperSpeechToTextResults,

    execute: async (context, { url, apiKey }) => {
        const result = await context.useCache(async () => {
            return await speechToText(context, url, apiKey);
        }, [url, apiKey]);

        return result;
    },
};

plugin.addComputation({ ...whisperSpeechToTextComputation, deprecated: true });
pluginV2.addComputation(whisperSpeechToTextComputation);

const defaultTSSVoice = "shimmer";
const defaultTTSModel = "tts-1-hd";
const defaultTTSSpeed = 1;
const defaultTTSResponseFormat = "mp3";

export const textToSpeech = async (
    context: glide.ServerExecutionContext,
    prompt: string,
    apiKey: string | undefined,
    model: string = defaultTTSModel,
    voice: string = defaultTSSVoice,
    speed: number = defaultTTSSpeed,
    responseFormat: string = defaultTTSResponseFormat
) => {
    if (apiKey === undefined) return Result.FailPermanent("API key is not set");

    const response = await context.fetch("https://api.openai.com/v1/audio/speech", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            model,
            input: prompt,
            voice,
            speed,
            responseFormat,
        }),
    });

    if (!response.ok) {
        return Result.FailFromHTTPStatus(
            `Error ${response.status}: ${response.statusText} - ${await response.text()}`,
            response.status
        );
    }
    context.consumeBillable();

    const buffer = await response.arrayBuffer();
    const contentType = response.headers.get("content-type") ?? "";

    const uploadFile = await context.uploadFile("audio.mp3", contentType, buffer);

    if (uploadFile.ok === false)
        return Result.Fail(`Could not upload image`, {
            ...uploadFile.data,
            status: response.status,
        });

    return Result.Ok({ audioUrl: uploadFile.result });
};

const whisperTextToSpeechParameters = {
    input: glide.makeParameter({
        type: "string",
        name: "Input",
        description:
            "A short description of what the bot should do (or not do). Treat it like a mission statement or constitution for your bot.",
        emptyByDefault: true,
    }),
    model: glide.makeParameter({
        type: "string",
        name: "Model",
        defaultValue: defaultTTSModel,
        description:
            "Audio model to use. [Learn more](https://platform.openai.com/docs/api-reference/audio/createSpeech#audio-createspeech-voice) about audio models",
        emptyByDefault: true,
        propertySection: {
            name: "Options",
            order: 1,
            collapsed: true,
        },
    }),
    voice: glide.makeParameter({
        type: "string",
        name: "Voice",
        defaultValue: defaultTSSVoice,
        description:
            "The voice to use for the audio. [Learn more](https://platform.openai.com/docs/guides/text-to-speech/voice-options) about voices",
        emptyByDefault: true,
        propertySection: {
            name: "Options",
            order: 1,
            collapsed: true,
        },
    }),
    speed: glide.makeParameter({
        type: "number",
        name: "Speed",
        defaultValue: defaultTTSSpeed,
        description: "The speed of the audio: 0.5 is half speed, 2 is double speed",
        emptyByDefault: true,
        propertySection: {
            name: "Options",
            order: 1,
            collapsed: true,
        },
    }),
    responseFormat: glide.makeParameter({
        type: "string",
        name: "Response Format",
        defaultValue: defaultTTSResponseFormat,
        description:
            "mp3, opus, aac, and flac are supported. [Learn more](https://platform.openai.com/docs/api-reference/audio/createSpeech#audio-createspeech-response_format)",
        emptyByDefault: true,
        propertySection: {
            name: "Options",
            order: 1,
            collapsed: true,
        },
    }),
};

const whisperTextToSpeechResults = {
    audioUrl: glide.makeParameter({
        type: "url",
        name: "Audio URL",
    }),
};

type WhisperTextToSpeechParameters = typeof whisperTextToSpeechParameters;
type WhisperTextToSpeechResults = typeof whisperTextToSpeechResults;

const whisperTextToSpeechAction: Omit<
    glide.Action<WhisperTextToSpeechResults, WhisperTextToSpeechParameters, PluginParameters, "server">,
    "type"
> = {
    id: "whisper-text-to-speech",
    name: "Text to Speech",
    description: "Use Whisper to transcribe speech to text",
    configurationDescriptionPattern: "To ${audioUrl}",
    billablesConsumed: 5,
    parameters: whisperTextToSpeechParameters,
    results: whisperTextToSpeechResults,
    execute: async (context, { input, apiKey, model, voice, speed, responseFormat }) => {
        if (input === undefined || input.trim().length === 0) {
            return Result.FailPermanent(`Please provide input`);
        }
        const result = await context.useCache(async () => {
            return await textToSpeech(context, input, apiKey, model, voice, speed, responseFormat);
        }, [input, model, voice, speed, responseFormat, apiKey]);

        return result;
    },
};

plugin.addAction({ ...whisperTextToSpeechAction, deprecated: true });
pluginV2.addAction(whisperTextToSpeechAction);
