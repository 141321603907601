import type { ArrayScreenDescription, ArrayContentDescription } from "@glide/app-description";
import { ListArrayScreenHandler } from "./list-array-screen";
import { GridArrayScreenHandler } from "./grid-array-screen";
import { CalendarListArrayScreenHandler } from "./calendar-screen";
import { ColumnScreenHandler } from "./column-screen";
import { GalleryScreenHandler } from "./gallery-screen";
import { MapScreenHandler } from "./map-screen";
import { SmallListArrayScreenHandler } from "./small-list-array-screen";
import { CheckListArrayScreenHandler } from "./checklist-array-screen";
import { TilesArrayScreenHandler } from "./tiles-array-screen";
import { CardsArrayScreenHandler } from "./cards-array-screen";
import { TinderArrayScreenHandler } from "./tinder-array-screen";
import { type ArrayScreenHandler, registerArrayScreenHandler } from ".";

let didInit = false;

export function registerArrayScreenHandlers(): void {
    if (didInit) return;
    didInit = true;

    // FIXME: This typecasting is not nice, but I was too lazy to figure out what the issue is.
    registerArrayScreenHandler(new ListArrayScreenHandler());
    registerArrayScreenHandler(new SmallListArrayScreenHandler());
    registerArrayScreenHandler(
        new TilesArrayScreenHandler() as ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>
    );
    registerArrayScreenHandler(
        new GridArrayScreenHandler() as ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>
    );
    registerArrayScreenHandler(
        new CalendarListArrayScreenHandler() as ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>
    );
    registerArrayScreenHandler(new ColumnScreenHandler());
    registerArrayScreenHandler(
        new GalleryScreenHandler() as ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>
    );
    registerArrayScreenHandler(
        new MapScreenHandler() as ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>
    );
    registerArrayScreenHandler(
        new CheckListArrayScreenHandler() as ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>
    );
    registerArrayScreenHandler(
        new CardsArrayScreenHandler() as ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>
    );
    registerArrayScreenHandler(
        new TinderArrayScreenHandler() as ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>
    );
}
