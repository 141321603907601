import { getCanAppUserRequestAccess, getUsesVirtualEmailAddresses } from "../app-features";
import type { AppFeatures } from "@glide/app-description";
import { noAPICapabilities } from "./api-key-capabilities";
import type { EminenceFlags } from "@glide/billing-types";
import { QuotaKind, defaultQuotaLimits } from "./quotas";
import type { AuthenticationData } from ".";

// `true` means supported.
// `"upgrade"` means not supported, but upgrading adds support.
// `false` means not supported, and no way to get support.
export type SupportLevel = boolean | "upgrade";

export interface AuthenticationSupport {
    readonly public: SupportLevel;
    readonly publicWithEmail: SupportLevel;
    // What this option means is "Sign-In = None" i.e: fully public
    readonly publicDisabled: SupportLevel;
    readonly password: SupportLevel;
    readonly emailWhitelist: SupportLevel;
    readonly organizationMembers: SupportLevel;
    readonly justMe: SupportLevel;
    readonly userProfiles: SupportLevel;
    readonly allowedEmailDomains: SupportLevel;

    // Can Public with Email be made optional?
    readonly optionalPublicWithEmail: SupportLevel;
    // Can the other Email Pin auth methods be made optional?
    readonly optionalOtherEmail: SupportLevel;
}

export type TransferToOrgAs = "internal" | "public" | "free" | undefined;

export type PrivateOrPublicOnly = "private" | "public";

// EminenceFlagsBase contains a ton of information.
// Some if it is legacy, and some relates to contemporary plans.
// Some of it is small switches for legacy logic, that's not part of what
// we sell to customers. This interface only describes things that people
// upgrade for, to create more focused types around upgrade UI and logic.
// It doesn't need to be comprehensive; as we use @glide/paywall to handle more and more
// upgrade flows, we can simply move properties from EminenceFlagsBase to here.
export interface EminenceFlagsThatUsersCanUpgradeFor {
    readonly signInAgreements: boolean;
    readonly canDisableShare: boolean;
    readonly automaticRefresh: boolean;
    readonly customSignInAppearance: boolean;
    readonly bigQuery: boolean;
    readonly canAccessAnalytics: boolean;
    readonly canCustomizeSignIn: boolean;
}

export type EminencesByAppAndOwner = Record<string, EminenceFlags>;

// NOTE: If you, like I, are tempted to replace this with an elegant `Infinity`, please don't.
// `Infinity` is not part of the JSON spec (despite being valid in IEEE!), and will serialize weirdly.
// For most math-related purposes, MAX_SAFE_INTEGER behaves like infinity, which is nice for quotas.
export const maxQuotaNumber = Number.MAX_SAFE_INTEGER;

const proEminenceFlags = {
    allowedPlugins: [],
    isFreeEminence: false,
    isAgency: false,
    isV1Pro: true,
    confirmChangeAuthOnUpgrade: true,
    needsBuilderModifyAppFeatures: false,
    removeBranding: true,
    lowTransactionFees: true,
    googleSharedDrive: true,
    customDomains: true,
    withGrandfathering: false,
    automaticRefresh: true,
    googleAnalytics: true,
    canAccessAnalytics: false,
    isUserMetered: false,
    unlimitedZapier: true,
    signInAgreements: true,
    customSignInAppearance: true,
    proComponents: true,
    proIcons: true,
    canDisableShare: true,
    canTransferBetweenUsers: false,
    canPausePublish: true,
    canUseSignInWithGoogle: false,
    downgradeBeforeDeleteStripeCancellation: undefined,
    buyButton: true,
    showQuotaBanners: false,
    unlimitedPasswordAndTeamMembersOnlyUsers: true,
    proDisplayQuotas: true,
    tabletMode: true,
    showReportApp: false,
    protectedColumns: true,
    allowRealEmails: true,
    stripeTransactionFeePercentage: 2,
    base64EncodePublicUserProfiles: true,
    canChangeAuthType: true,
    isPrivateProTrial: false,
    appLoginsSheet: true,
    supportsQuotaUpgrades: true,
    maxSingleUploadBytes: 1024 * 1024 * 1024,
    quotas: {
        ...defaultQuotaLimits,
        [QuotaKind.BarcodesScanned]: { prepaidUsage: 1000, maxOverage: maxQuotaNumber },
        [QuotaKind.FileBytesUsed]: { prepaidUsage: 10 * 1000 * 1000 * 1000, maxOverage: maxQuotaNumber },
        [QuotaKind.Geocodes]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.PrivateUsers]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.Reloads]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.RowsUsed]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.Signatures]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.ZapsAndWebhook]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
    },
    apiCapabilities: {
        requestReload: false,
        mutateTables: true,
        queryTables: false,
        createAnonymousUser: false,
    },
    quotaContext: "app",
    offlineActionQueue: false,
    pagesCustomCss: false,
    shouldUseNewPrivacy: false,
    quotaEnforcementScheme: undefined,
    canAppUserRequestAccess: "private" as const,
    appUserRequestAccessInPrivateProjectsDefault: true,
    virtualEmailAddressesInPublicProjectsDefault: false,
    bigQuery: false,
    bigTables: false,
    gmailForSendEmails: false,
    pluginTier: "pro" as const,
    canCreateSupportTicket: true,
    canCustomizeSignIn: true,
    queryTablesAPICountsTowardUpdatesQuota: false,
    mutateTablesAPICountsTowardUpdatesQuota: true,
    glideAPIv2CountsTowardUpdatesQuota: false,
    signaturesCountTowardUpdatesQuota: false,
    userCRUDActionsOnInternalDataSourcesCountTowardUpdatesQuota: true,
    userCRUDActionsOnExternalDataSourcesCountTowardUpdatesQuota: true,
    deletingOrgRequiresSupport: false,
    pluginDenyList: [],
    pluginAllowList: [],
    actionLogsHistoryLength: 30,
    automationTriggerScheduleBillableConsumed: 1,
    pgMirrorSyncsCountTowardUpdatesQuota: false,
};

export const freeFlags: EminenceFlags = {
    integrationsCountTowardUpdatesQuota: false,
    allowedPlugins: [],
    isFreeEminence: true,
    isV1Pro: false,
    includedWhitelabels: 0,
    isAgency: false,
    displayName: "Free",
    needsBuilderModifyAppFeatures: true,
    removeBranding: false,
    lowTransactionFees: false,
    googleSharedDrive: false,
    confirmChangeAuthOnUpgrade: true,
    customDomains: false,
    badge: undefined,
    isUserMetered: false,
    personalUsersOnly: false,
    newNewAppFlow: false,
    withGrandfathering: false,
    automaticRefresh: false,
    googleAnalytics: false,
    canAccessAnalytics: false,
    unlimitedZapier: false,
    signInAgreements: false,
    customSignInAppearance: false,
    authenticationSupport: {
        public: true,
        publicWithEmail: true,
        publicDisabled: false,
        password: true,
        emailWhitelist: "upgrade",
        userProfiles: "upgrade",
        organizationMembers: false,
        justMe: true,
        optionalPublicWithEmail: true,
        optionalOtherEmail: false,
        allowedEmailDomains: true,
    },
    upgradeButton: true,
    proComponents: false,
    proIcons: false,
    canDisableShare: false,
    buyButton: true,
    canUpgrade: true,
    canDowngrade: false,
    canTransferBetweenUsers: true,
    canTransferToOrg: true,
    canPausePublish: false,
    canUseSignInWithGoogle: true,
    downgradeBeforeDelete: false,
    downgradeBeforeDeleteStripeCancellation: undefined,
    logOrgLogin: false,
    canSubmitTemplate: true,
    needsPublishableOrgToTransfer: false,
    transferToOrgAs: "free",
    showQuotaBanners: true,
    unlimitedPasswordAndTeamMembersOnlyUsers: false,
    proDisplayQuotas: false,
    tabletMode: false,
    showReportApp: true,
    roles: false,
    rowQuotasIncludeBigTables: false,
    protectedColumns: false,
    allowRealEmails: "private",
    stripeTransactionFeePercentage: 10,
    base64EncodePublicUserProfiles: true,
    canChangeAuthType: true,
    isPrivateProTrial: false,
    appLoginsSheet: true,
    supportsQuotaUpgrades: true,
    maxSingleUploadBytes: 10 * 1024 * 1024,
    quotas: defaultQuotaLimits,
    apiCapabilities: noAPICapabilities,
    quotaContext: "app",
    offlineActionQueue: false,
    pagesCustomCss: false,
    shouldUseNewPrivacy: false,
    quotaEnforcementScheme: undefined,
    canAppUserRequestAccess: "private",
    appUserRequestAccessInPrivateProjectsDefault: true,
    virtualEmailAddressesInPublicProjectsDefault: true,
    bigQuery: false,
    bigTables: false,
    gmailForSendEmails: false,
    pluginTier: "free",
    canCreateSupportTicket: false,
    canCustomizeSignIn: false,
    queryTablesAPICountsTowardUpdatesQuota: false,
    mutateTablesAPICountsTowardUpdatesQuota: true,
    glideAPIv2CountsTowardUpdatesQuota: false,
    signaturesCountTowardUpdatesQuota: false,
    userCRUDActionsOnInternalDataSourcesCountTowardUpdatesQuota: true,
    userCRUDActionsOnExternalDataSourcesCountTowardUpdatesQuota: true,
    deletingOrgRequiresSupport: false,
    pluginDenyList: [],
    pluginAllowList: [],
    actionLogsHistoryLength: 7,
    automationTriggerScheduleBillableConsumed: 1,
    automationScheduleKind: [],
    automationTriggerKind: ["app"],
    automaionTriggerKind: ["app"],
    automationMaxStepsLimit: 2000,
    pgMirrorSyncsCountTowardUpdatesQuota: false,
};

const orgFreeFlags = {
    ...freeFlags,
    authenticationSupport: {
        ...freeFlags.authenticationSupport,
        justMe: false,
        organizationMembers: true,
    },
    supportsQuotaUpgrades: true,
    quotaContext: "app",
};

export const freePageFlags: EminenceFlags = {
    ...orgFreeFlags,
    displayName: "Free",
    unlimitedPasswordAndTeamMembersOnlyUsers: true,
    authenticationSupport: {
        public: false,
        publicWithEmail: "upgrade",
        publicDisabled: true,
        password: false,
        emailWhitelist: false,
        userProfiles: "upgrade",
        organizationMembers: true,
        justMe: false,
        optionalPublicWithEmail: "upgrade",
        optionalOtherEmail: "upgrade",
        allowedEmailDomains: true,
    },
    appLoginsSheet: false,
    supportsQuotaUpgrades: false,
    customSignInAppearance: true,
    quotas: {
        ...orgFreeFlags.quotas,
        [QuotaKind.RowsUsed]: { prepaidUsage: 100, maxOverage: 0 },
    },
    quotaContext: "app",
};

export const eminencePro: EminenceFlags = {
    ...proEminenceFlags,
    displayName: "Pro App",
    badge: "Pro",
    newNewAppFlow: false,
    includedWhitelabels: 0,
    integrationsCountTowardUpdatesQuota: false,
    personalUsersOnly: false,
    authenticationSupport: {
        public: true,
        publicWithEmail: true,
        publicDisabled: false,
        password: true,
        emailWhitelist: true,
        userProfiles: true,
        organizationMembers: false,
        justMe: true,
        optionalPublicWithEmail: true,
        optionalOtherEmail: false,
        allowedEmailDomains: true,
    },
    upgradeButton: false,
    canUpgrade: false,
    canDowngrade: true,
    canTransferToOrg: true,
    canUseSignInWithGoogle: false,
    downgradeBeforeDelete: true,
    downgradeBeforeDeleteStripeCancellation: "immediately",
    logOrgLogin: false,
    canSubmitTemplate: true,
    needsPublishableOrgToTransfer: true,
    transferToOrgAs: "public",
    rowQuotasIncludeBigTables: false,
    roles: true,
    quotaContext: "app",
    shouldUseNewPrivacy: false,
    quotaEnforcementScheme: undefined,
    automationScheduleKind: [],
    automationTriggerKind: ["app"],
    automaionTriggerKind: ["app"],
    automationMaxStepsLimit: 2000,
};

// In lots of cases where we don’t have eminence flags,
// and we just want “eminence flags with everything turned on”.
// This use to be eminencePro, but it was missing some settings
// for app bootstraping like excel row quotas
export const eminenceFull: EminenceFlags = {
    ...eminencePro,
    authenticationSupport: {
        public: true,
        publicWithEmail: true,
        publicDisabled: true,
        password: true,
        emailWhitelist: true,
        userProfiles: true,
        organizationMembers: true,
        justMe: true,
        optionalPublicWithEmail: true,
        optionalOtherEmail: true,
        allowedEmailDomains: true,
    },
    quotaContext: "org",
    offlineActionQueue: true,
};

export const eminenceOrgInternal: EminenceFlags = {
    ...proEminenceFlags,
    displayName: "Pro App (Legacy Organization Internal)",
    badge: undefined,
    newNewAppFlow: false,
    includedWhitelabels: 0,
    integrationsCountTowardUpdatesQuota: false,
    personalUsersOnly: false,
    authenticationSupport: {
        public: false,
        publicWithEmail: false,
        publicDisabled: false,
        password: false,
        emailWhitelist: true,
        userProfiles: true,
        organizationMembers: true,
        justMe: false,
        optionalPublicWithEmail: false,
        optionalOtherEmail: false,
        allowedEmailDomains: true,
    },
    upgradeButton: false,
    canUpgrade: false,
    canDowngrade: false,
    canTransferToOrg: false,
    downgradeBeforeDelete: true,
    logOrgLogin: true,
    canSubmitTemplate: false,
    needsPublishableOrgToTransfer: false,
    transferToOrgAs: undefined,
    rowQuotasIncludeBigTables: false,
    roles: true,
    quotaContext: "app",
    automationScheduleKind: [],
    automationTriggerKind: ["app"],
    automaionTriggerKind: ["app"],
    automationMaxStepsLimit: 2000,
};

export const eminenceOrgPublic: EminenceFlags = {
    ...proEminenceFlags,
    isFreeEminence: false,
    isV1Pro: true,
    newNewAppFlow: false,
    displayName: "Pro App (Legacy Organization)",
    badge: "Pro",
    includedWhitelabels: 0,
    integrationsCountTowardUpdatesQuota: false,
    personalUsersOnly: false,
    authenticationSupport: {
        public: true,
        publicWithEmail: true,
        publicDisabled: false,
        password: true,
        emailWhitelist: true,
        userProfiles: true,
        organizationMembers: false,
        justMe: false,
        optionalPublicWithEmail: true,
        optionalOtherEmail: false,
        allowedEmailDomains: true,
    },
    upgradeButton: false,
    canUpgrade: false,
    canDowngrade: false,
    canTransferToOrg: false,
    downgradeBeforeDelete: true,
    logOrgLogin: false,
    canSubmitTemplate: false,
    needsPublishableOrgToTransfer: false,
    transferToOrgAs: undefined,
    rowQuotasIncludeBigTables: false,
    roles: false,
    quotas: {
        ...proEminenceFlags.quotas,
        [QuotaKind.DeliverEmail]: { prepaidUsage: 0, maxOverage: 0 },
    },
    quotaContext: "app",
    automationScheduleKind: [],
    automationTriggerKind: ["app"],
    automaionTriggerKind: ["app"],
    automationMaxStepsLimit: 2000,
};

export const unlimitedEminenceFlags: EminenceFlags = {
    badge: "Enterprise",
    displayName: "Enterprise",
    includedWhitelabels: 1000,
    allowedPlugins: [],
    isFreeEminence: false,
    integrationsCountTowardUpdatesQuota: false,
    isV1Pro: false,
    isAgency: false,
    upgradeButton: false,
    canUpgrade: false,
    newNewAppFlow: false,
    canDowngrade: false,
    personalUsersOnly: false,
    canTransferToOrg: true,
    logOrgLogin: false,
    canSubmitTemplate: true,
    needsPublishableOrgToTransfer: true,
    transferToOrgAs: undefined,
    rowQuotasIncludeBigTables: false,
    roles: true,
    confirmChangeAuthOnUpgrade: false,
    needsBuilderModifyAppFeatures: false,
    removeBranding: true,
    lowTransactionFees: true,
    googleSharedDrive: true,
    customDomains: true,
    withGrandfathering: false,
    automaticRefresh: true,
    googleAnalytics: true,
    canAccessAnalytics: true,
    isUserMetered: false,
    unlimitedZapier: true,
    signInAgreements: true,
    customSignInAppearance: true,
    proComponents: true,
    proIcons: true,
    canDisableShare: true,
    canTransferBetweenUsers: false,
    canPausePublish: true,
    canUseSignInWithGoogle: false,
    downgradeBeforeDelete: false,
    downgradeBeforeDeleteStripeCancellation: undefined,
    buyButton: true,
    showQuotaBanners: false,
    unlimitedPasswordAndTeamMembersOnlyUsers: true,
    proDisplayQuotas: false,
    tabletMode: true,
    showReportApp: false,
    protectedColumns: true,
    allowRealEmails: true,
    stripeTransactionFeePercentage: 2,
    base64EncodePublicUserProfiles: true,
    canChangeAuthType: true,
    isPrivateProTrial: false,
    appLoginsSheet: true,
    supportsQuotaUpgrades: true,
    maxSingleUploadBytes: 1024 * 1024 * 1024,
    authenticationSupport: {
        public: true,
        publicWithEmail: true,
        publicDisabled: true,
        password: true,
        emailWhitelist: true,
        userProfiles: true,
        organizationMembers: true,
        justMe: false,
        optionalPublicWithEmail: true,
        optionalOtherEmail: true,
        allowedEmailDomains: true,
    },
    quotas: {
        [QuotaKind.AirtableRowsUsed]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.BarcodesScanned]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.DeliverEmail]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.FileBytesUsed]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.Geocodes]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.MapPins]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.PrivateUsers]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.Reloads]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.RowsUsed]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.Signatures]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.ZapsAndWebhook]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.PublicUsers]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.AppEditors]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.Updates]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.PublishedApps]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.BigTableRows]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.SQLRows]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
        [QuotaKind.Users]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
    },
    apiCapabilities: {
        mutateTables: true,
        queryTables: true,
        requestReload: false,
        createAnonymousUser: false,
    },
    quotaContext: "org",
    offlineActionQueue: true,
    pagesCustomCss: true,
    shouldUseNewPrivacy: true,
    quotaEnforcementScheme: undefined,
    canAppUserRequestAccess: "private",
    appUserRequestAccessInPrivateProjectsDefault: true,
    virtualEmailAddressesInPublicProjectsDefault: false,
    bigQuery: true,
    bigTables: true,
    gmailForSendEmails: true,
    pluginTier: "enterprise",
    canCreateSupportTicket: true,
    canCustomizeSignIn: true,
    queryTablesAPICountsTowardUpdatesQuota: false,
    mutateTablesAPICountsTowardUpdatesQuota: true,
    glideAPIv2CountsTowardUpdatesQuota: false,
    signaturesCountTowardUpdatesQuota: false,
    userCRUDActionsOnInternalDataSourcesCountTowardUpdatesQuota: true,
    userCRUDActionsOnExternalDataSourcesCountTowardUpdatesQuota: true,
    deletingOrgRequiresSupport: true,
    pluginDenyList: [],
    pluginAllowList: [],
    actionLogsHistoryLength: 90,
    automationTriggerScheduleBillableConsumed: 1,
    automationScheduleKind: [
        "every-5-mins",
        "every-15-mins",
        "every-30-mins",
        "every-hour",
        "every-day",
        "every-week",
        "every-month",
        "custom",
    ],
    automationTriggerKind: ["app", "schedule", "plugin"],
    automaionTriggerKind: ["app", "schedule", "plugin"],
    automationMaxStepsLimit: 2000,
    pgMirrorSyncsCountTowardUpdatesQuota: false,
};

// This enforces app features based on eminence.  There's a somewhat similar
// function `adaptAppToEminence` which is used to set features to new defaults
// when an app's eminence changes.  We're using this in
// `AppInfoFetcher.getAppFeatures`, so it's used on the backend when getting
// app features from the builder app, too.
export function enforceEminenceInAppFeatures(
    eminenceFlags: EminenceFlags,
    features: AppFeatures,
    daysLeftInTrial: number | undefined,
    authData: AuthenticationData
): AppFeatures {
    const {
        removeBranding,
        googleAnalytics,
        canDisableShare,
        automaticRefresh,
        tabletMode,
        customSignInAppearance,
        isPrivateProTrial,
    } = eminenceFlags;
    const trialExpired = daysLeftInTrial !== undefined && daysLeftInTrial <= 0;
    features = {
        ...features,
        removeBranding,
        useCustomSignInBackgroundImageInLoading: customSignInAppearance
            ? features.useCustomSignInBackgroundImageInLoading ?? false
            : false,
        googleAnalyticsMeasureIDs: googleAnalytics ? features.googleAnalyticsMeasureIDs : [],
        enableShareScreen: canDisableShare ? features.enableShareScreen === true : true,
        disableSharing: canDisableShare ? features.disableSharing : false,
        automaticRefreshMinutes: automaticRefresh ? features.automaticRefreshMinutes : undefined,
        defaultTabletMode: tabletMode ? features.defaultTabletMode : false,
        isTrialLocked: isPrivateProTrial && trialExpired,
        virtualEmailAddresses: getUsesVirtualEmailAddresses(features, eminenceFlags, authData),
        canAppUserRequestAccess: getCanAppUserRequestAccess(features, eminenceFlags, authData),
    };
    return features;
}
