import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import type { TableName } from "@glide/type-schema";
import type { ScreenDescription } from "@glide/app-description";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import type {
    WirePageSignaturePadComponent,
    WirePageVoiceEntryComponent,
} from "@glide/fluent-components/dist/js/base-components";
import type { WireButtonComponent } from "@glide/fluent-components/dist/js/fluent-components";

import {
    codeScannerScreenName,
    liveLinearBarcodeScannerScreenName,
    requestSignatureScreenName,
    signInScreenName,
    signUpScreenName,
    userProfileScreenName,
    voiceEntryScreenName,
} from "@glide/function-utils";
import {
    type HydratedScreenContext,
    type WireActionBackend,
    type WireActionRunner,
    type WireHydrationFollowUp,
    ValueChangeSource,
    WireComponentKind,
    WireNavigationAction,
    WireScreenFlag,
    UIButtonAppearance,
    WireScreenPosition,
    WireActionResult,
    type WireModalScreen,
    type WireNavigation,
    type WireNavigationModelBase,
    type WirePageNavigationModel,
    type WireScreen,
    type WireSubsidiaryScreen,
    type NavigationPath,
    type ParsedModalScreen,
    type ParsedScreen,
    type WithModalFlag,
    type WireAction,
    type WireModalSize,
} from "@glide/wire";
import { encodeScreenKey } from "@glide/generator/dist/js/wire/utils";
import { type ArraySet, isDefined } from "@glide/support";
import { assert, defined, definedMap, panic } from "@glideapps/ts-necessities";
import isBoolean from "lodash/isBoolean";

import {
    type AuxHydrationData,
    type InternalNavigationModelBase,
    type ScreenHydrationResult,
    WireBackendBase,
    emptyScreen,
    getSubsidiaryScreen,
    makeScreenKey,
} from "./backend";
import { emptyHydratedScreenContext } from "./internal";
import type {
    ActionRunnerWithContext,
    HydratedScreen,
    InternalScreen,
    InternalTab,
    ScreenBuilderTableData,
    ScreenHydrationContext,
    SubscriptionNeeds,
} from "./internal-types";
import { Result } from "@glide/plugins";
import isString from "lodash/isString";
import { asMaybeString } from "@glide/computation-model-types";

interface HydratedModalScreen extends HydratedScreen {
    readonly wireScreen: WireModalScreen;
}

interface InternalModalScreen extends InternalScreen {
    readonly size: WireModalSize;
    readonly hydratedScreen: HydratedModalScreen | undefined;
}

interface InternalPageNavigationModel extends InternalNavigationModelBase {
    readonly screen: InternalScreen | undefined;
    readonly belowScreen: InternalScreen | undefined;
    readonly modal: InternalModalScreen | undefined;
}

function isSubsidiaryInMainScreen(screen: WireSubsidiaryScreen, isCurrentScreenModal: boolean): boolean {
    const isCurrentSize = screen.size === "current" || screen.size === undefined;
    return isCurrentSize && !isCurrentScreenModal;
}

export class WirePageBackend extends WireBackendBase<InternalPageNavigationModel, WirePageNavigationModel> {
    private lastNavigation: WireNavigation | undefined;
    private lastSubsidiary: WireSubsidiaryScreen | undefined;

    private readonly closeSubsidiaryAction = { token: makeRowID() };
    private readonly backSubsidiaryAction = { token: makeRowID() };
    private readonly dragBackSubsidiaryAction = { token: makeRowID() };

    private makeScreenKeys(): [string, string, string] {
        const { parsedPath: path } = this;
        if (!path.isVisible()) return ["", "", ""];

        const { main, modal, below } = path.getParsedScreens();

        return [
            makeScreenKey(path, main?.screen),
            makeScreenKey(path, modal?.screen),
            makeScreenKey(path, below?.screen),
        ];
    }

    protected getHasUnconfigurableModal(aux: AuxHydrationData): boolean {
        return aux.hasSubsidiaryScreen;
    }

    public navigateToUserProfile(force: boolean): Result {
        return this.runNavigateToTab(userProfileScreenName, force);
    }

    public runNavigateUp(): Result {
        const stack = this.parsedPath.getScreenStack();

        // Current screen is the last in the stack.
        // If we go from a modal to a non-modal, the action is PopModal.
        const currentScreen = stack[stack.length - 1];
        const prevScreen = stack[stack.length - 2];
        let navigateAction = WireNavigationAction.Pop;
        if (currentScreen?.kind === WireScreenPosition.Modal && prevScreen?.kind !== WireScreenPosition.Modal) {
            navigateAction = WireNavigationAction.PopModal;
        }

        const popped = this.parsedPath.pop();
        if (popped === undefined) return Result.FailPermanent("Cannot navigate up from the root screen");

        popped[1]?.();
        this.setParsedPath(popped[0], navigateAction);

        return Result.Ok();
    }

    protected getCurrentBuilderTableData(navModel: InternalPageNavigationModel): ScreenBuilderTableData | undefined {
        if (navModel.modal !== undefined) {
            return navModel.modal.hydratedScreen?.builderTableData;
        } else {
            return navModel.screen?.hydratedScreen?.builderTableData;
        }
    }

    protected makeEmptyNavigationModel(base: WireNavigationModelBase): WirePageNavigationModel {
        const navModel: WirePageNavigationModel = {
            ...base,
            kind: AppKind.Page,
            screen: emptyScreen,
            belowScreen: undefined,
            isTabRootScreen: true,
        };
        return navModel;
    }

    protected setParsedPath(parsedPath: NavigationPath, navigationAction: WireNavigationAction): boolean {
        const didChange = super.setParsedPath(parsedPath, navigationAction);
        if (!didChange) return false;

        const { screen, modal } = this.navigationModelWatchable.current;

        this.lastNavigation = {
            priorScreen: modal ?? screen,
            navigationAction,
        };

        return true;
    }

    private getRunnerForClosingSubsidiary(
        subsidiary: WireSubsidiaryScreen,
        navigationAction: WireNavigationAction
    ): WireActionRunner {
        const closeSubsidiaryToken = subsidiary.closeAction?.token;

        if (!isDefined(closeSubsidiaryToken)) {
            panic("Subsidiaries should always have a closeAction");
        }

        const runner: WireActionRunner = async ab => {
            this.lastNavigation = {
                priorScreen: subsidiary,
                navigationAction,
            };

            ab.runAction(closeSubsidiaryToken);

            return WireActionResult.nondescriptSuccess();
        };

        return runner;
    }

    private getSubsidiaryNavControls(
        subsidiary: WireSubsidiaryScreen | undefined,
        isCurrentScreenModal: boolean
    ): Pick<WireScreen, "backAction" | "closeAction" | "dragBackAction"> {
        const isSubsidiaryScreenModal =
            subsidiary !== undefined && !isSubsidiaryInMainScreen(subsidiary, isCurrentScreenModal);

        const bothAreModal = isCurrentScreenModal && isSubsidiaryScreenModal;
        const noneAreModal = !isCurrentScreenModal && !isSubsidiaryScreenModal;

        let closeAction: WireAction | undefined;
        let backAction: WireAction | undefined;
        let dragBackAction: WireAction | undefined;

        if (subsidiary !== undefined) {
            const closeSubsidiaryRunner = this.getRunnerForClosingSubsidiary(subsidiary, WireNavigationAction.PopModal);
            this.globalActions.set(this.closeSubsidiaryAction.token, [
                closeSubsidiaryRunner,
                emptyHydratedScreenContext,
            ]);
            closeAction = this.closeSubsidiaryAction;

            const dragBackSubsidiaryRunner = this.getRunnerForClosingSubsidiary(
                subsidiary,
                WireNavigationAction.DragBack
            );
            this.globalActions.set(this.dragBackSubsidiaryAction.token, [
                dragBackSubsidiaryRunner,
                emptyHydratedScreenContext,
            ]);
            dragBackAction = this.dragBackSubsidiaryAction;

            const backSubsidiaryRunner = this.getRunnerForClosingSubsidiary(subsidiary, WireNavigationAction.Pop);
            this.globalActions.set(this.backSubsidiaryAction.token, [backSubsidiaryRunner, emptyHydratedScreenContext]);
            backAction = this.backSubsidiaryAction;
        }

        return {
            closeAction: isCurrentScreenModal ? this.closeModalAction : closeAction,
            backAction: noneAreModal || bothAreModal ? backAction : undefined,
            dragBackAction: noneAreModal ? dragBackAction : undefined,
        };
    }

    private updateLastNavigationBasedOnSubsidiary(
        subsidiary: WireSubsidiaryScreen | undefined,
        newPriorScreen: WireScreen,
        isCurrentScreenModal: boolean
    ) {
        const previousSubsidiary = this.lastSubsidiary;
        this.lastSubsidiary = subsidiary;

        /**
         * If a subsidiary was opened, we need to specify the last navigation.
         */
        if (previousSubsidiary === undefined && subsidiary !== undefined) {
            const isSubsidiaryInMain = isSubsidiaryInMainScreen(subsidiary, isCurrentScreenModal);

            // If we go from a Main screen to a Modal screen, we PushModal. Else we Push.
            const pushedModal = !isCurrentScreenModal && !isSubsidiaryInMain;
            const navigationAction = pushedModal ? WireNavigationAction.PushModal : WireNavigationAction.Push;

            this.lastNavigation = {
                priorScreen: newPriorScreen,
                navigationAction,
            };
            return;
        }
    }

    private getSubsidiaryInMainScreen(internalModel: InternalPageNavigationModel): WireSubsidiaryScreen | undefined {
        const mainScreen = internalModel.screen?.hydratedScreen;

        const subsidiaryFromMain = getSubsidiaryScreen(mainScreen);

        // A subsidiary can be in the main screen _only_ if it comes from the main screen, with "current" size
        return subsidiaryFromMain !== undefined && isSubsidiaryInMainScreen(subsidiaryFromMain, false)
            ? subsidiaryFromMain
            : undefined;
    }

    private getSubsidiaryInModalScreen(internalModel: InternalPageNavigationModel): WireSubsidiaryScreen | undefined {
        const mainScreen = internalModel.screen?.hydratedScreen;
        const modalScreen = internalModel.modal?.hydratedScreen;

        const subsidiaryFromMain = getSubsidiaryScreen(mainScreen);
        const subsidiaryFromModal = getSubsidiaryScreen(modalScreen);

        // A subsidiary can be in a modal if the main screen has a modal subsidiary, or if the modal screen has any subsidiary
        const isSubsidiaryFromMainAModal =
            subsidiaryFromMain !== undefined && !isSubsidiaryInMainScreen(subsidiaryFromMain, false);

        const modalSubsidiaryFromMain = isSubsidiaryFromMainAModal ? subsidiaryFromMain : undefined;

        return subsidiaryFromModal ?? modalSubsidiaryFromMain;
    }

    protected makeNavigationModel(
        internalModel: InternalPageNavigationModel,
        base: WireNavigationModelBase
    ): [WirePageNavigationModel, AuxHydrationData] {
        const screen = internalModel.screen?.hydratedScreen?.wireScreen ?? emptyScreen;
        const modalScreen = internalModel.modal?.hydratedScreen?.wireScreen;

        const isCurrentScreenModal = modalScreen !== undefined;

        const subsidiaryInMain = this.getSubsidiaryInMainScreen(internalModel);
        const subsidiaryInModal = this.getSubsidiaryInModalScreen(internalModel);

        const primarySubsidiary = subsidiaryInModal ?? subsidiaryInMain;

        const subsidiaryNavControls = this.getSubsidiaryNavControls(primarySubsidiary, isCurrentScreenModal);

        const mainSubsidiary: WireScreen | undefined =
            subsidiaryInMain !== undefined
                ? {
                      ...subsidiaryInMain,
                      ...subsidiaryNavControls,
                  }
                : undefined;

        const modalSubsidiarySize = subsidiaryInModal?.size === "current" ? modalScreen?.size : subsidiaryInModal?.size;
        const modalSubsidiary =
            subsidiaryInModal !== undefined && modalSubsidiarySize !== undefined
                ? {
                      ...subsidiaryInModal,
                      ...subsidiaryNavControls,
                      size: modalSubsidiarySize,
                  }
                : undefined;

        const belowScreen =
            subsidiaryInMain !== undefined
                ? internalModel.screen?.hydratedScreen?.wireScreen
                : internalModel.belowScreen?.hydratedScreen?.wireScreen;

        const isTabRootScreen =
            subsidiaryInMain === undefined && internalModel.screen?.screenName === this.parsedPath.getTabScreenName();

        this.updateLastNavigationBasedOnSubsidiary(primarySubsidiary, modalScreen ?? screen, isCurrentScreenModal);

        const navModel: WirePageNavigationModel = {
            kind: AppKind.Page,
            ...base,
            screen: mainSubsidiary ?? screen,
            belowScreen,
            modal: modalSubsidiary ?? modalScreen,
            lastNavigation: this.lastNavigation,
            isTabRootScreen,
        };
        return [navModel, { hasSubsidiaryScreen: primarySubsidiary !== undefined }];
    }

    protected postProcessNavigationModel(model: WirePageNavigationModel): WirePageNavigationModel {
        return model;
    }

    protected getNeedsBackAction({ screen, isInModal }: WithModalFlag<ParsedScreen>): boolean {
        if (isInModal) {
            const screenStack = this.parsedPath.getScreenStack();
            // Current modal is the last in the stack.
            // If the previous was screen exists, and is a Modal, you can go back.
            return screenStack[screenStack.length - 2]?.kind === WireScreenPosition.Modal;
        }

        return screen.screenName !== this.parsedPath.getTabScreenName();
    }

    private makeInternalModalScreen(parsedScreen: WithModalFlag<ParsedModalScreen>): InternalModalScreen {
        const internalScreen = this.makeInternalScreen(parsedScreen, undefined);
        assert(internalScreen.hydratedScreen === undefined);
        return {
            ...internalScreen,
            hydratedScreen: undefined,
            size: parsedScreen.screen.size,
        };
    }

    private updateInternalModalScreen(
        parsedScreen: WithModalFlag<ParsedScreen> | undefined,
        internalScreen: InternalModalScreen | undefined
    ): InternalModalScreen | undefined {
        assert(parsedScreen?.screen.kind !== WireScreenPosition.Main);
        let screen: WithModalFlag<ParsedModalScreen> | undefined;
        if (parsedScreen !== undefined) {
            // Typescript doesn't let us assign these straight up
            screen = {
                screen: parsedScreen.screen,
                isInModal: parsedScreen.isInModal,
                depth: parsedScreen.depth,
            };
        }
        // We may now have a back action, if we navigate to the exact same modal screen, for example.
        const updatedInternalScreen = definedMap(internalScreen, s => ({
            ...s,
            needsBackAction: screen !== undefined && this.getNeedsBackAction(screen),
        }));

        return this.updateInternalScreenGeneric(screen, updatedInternalScreen, p => this.makeInternalModalScreen(p));
    }

    protected updateInternalNavigationModel(
        oldModel: InternalPageNavigationModel | undefined
    ): InternalPageNavigationModel {
        const { main, modal, below } = this.parsedPath.getParsedScreens();

        const belowScreenTab = this.parsedPath.pop()?.[0].getTabOfCurrentScreen(this.adc.appDescription);
        const internalBelowScreen = this.updateInternalScreen(below, oldModel?.belowScreen, belowScreenTab);

        const internalModalScreen = this.updateInternalModalScreen(modal, oldModel?.modal);

        return {
            // We pass `undefined` for the screen tab because it's not
            // used in pages.
            screen: this.updateInternalScreen(main, oldModel?.screen, undefined),
            belowScreen: internalBelowScreen,
            modal: internalModalScreen,
            tabs: oldModel?.tabs,
            userProfile: oldModel?.userProfile ?? {
                userProfileButton: undefined,
                signInAction: undefined,
            },
            userProfileSubscriptionInfo: oldModel?.userProfileSubscriptionInfo,
        };
    }

    private rebuildModalScreen(
        internalScreen: InternalModalScreen,
        position: WireScreenPosition,
        tablesToFetch: ArraySet<TableName>,
        internalTabs: readonly InternalTab[] | undefined,
        screenKey: string,
        needsChanged: SubscriptionNeeds
    ): [InternalModalScreen, WireHydrationFollowUp | undefined] {
        const [screen, followUp] = this.rebuildScreen(
            internalScreen,
            position,
            internalScreen.size,
            tablesToFetch,
            internalTabs,
            screenKey,
            needsChanged
        );
        return [
            {
                ...screen,
                hydratedScreen: definedMap(
                    screen.hydratedScreen,
                    (h: HydratedScreen): HydratedModalScreen => ({
                        ...h,
                        wireScreen: { ...h.wireScreen, size: internalScreen.size },
                    })
                ),
                size: internalScreen.size,
            },
            followUp,
        ];
    }

    protected rebuildScreens(
        oldModel: InternalPageNavigationModel,
        newBase: InternalNavigationModelBase,
        tablesToFetch: ArraySet<TableName>,
        needsChanged: SubscriptionNeeds
    ): [InternalPageNavigationModel, readonly WireHydrationFollowUp[]] {
        const [mainKey, modalKey, belowKey] = this.makeScreenKeys();
        const [newScreen, screenFollowUp] =
            definedMap(oldModel.screen, m =>
                this.rebuildScreen(
                    m,
                    WireScreenPosition.Main,
                    undefined,
                    tablesToFetch,
                    newBase.tabs,
                    mainKey,
                    needsChanged
                )
            ) ?? [];

        const [newBelowScreen, belowScreenFollowUp] =
            definedMap(oldModel.belowScreen, m =>
                this.rebuildScreen(
                    m,
                    WireScreenPosition.Main,
                    undefined,
                    tablesToFetch,
                    newBase.tabs,
                    belowKey,
                    needsChanged
                )
            ) ?? [];
        const [newModal, modalFollowUp] =
            definedMap(oldModel.modal, m =>
                this.rebuildModalScreen(
                    m,
                    WireScreenPosition.Modal,
                    tablesToFetch,
                    newBase.tabs,
                    modalKey,
                    needsChanged
                )
            ) ?? [];

        return [
            {
                ...newBase,
                screen: newScreen,
                belowScreen: newBelowScreen,
                modal: newModal,
            },
            [screenFollowUp, modalFollowUp, belowScreenFollowUp].filter(isDefined),
        ];
    }

    protected getActionFromScreens(
        navModel: InternalPageNavigationModel,
        token: string
    ):
        | { action: ActionRunnerWithContext; source: WireScreenPosition; sourceModalSize: WireModalSize | undefined }
        | undefined {
        let action = navModel.modal?.hydratedScreen?.actions.get(token);
        let source: WireScreenPosition = WireScreenPosition.Modal;
        let sourceModalSize = navModel.modal?.size;
        if (action === undefined) {
            action = navModel.screen?.hydratedScreen?.actions.get(token);
            source = WireScreenPosition.Main;
            sourceModalSize = undefined;
        }
        if (action === undefined) return undefined;
        return { action, source, sourceModalSize };
    }

    protected processInHydratedScreens<T>(
        navModel: InternalPageNavigationModel,
        processScreen: (hydratedScreen: HydratedScreen, internalScreen: InternalScreen) => T | undefined
    ): T | undefined {
        if (navModel.screen?.hydratedScreen !== undefined) {
            const fromScreen = processScreen(navModel.screen.hydratedScreen, navModel.screen);
            if (fromScreen !== undefined) {
                return fromScreen;
            }
        }
        if (navModel.modal?.hydratedScreen !== undefined) {
            const fromModal = processScreen(navModel.modal.hydratedScreen, navModel.modal);
            if (fromModal !== undefined) {
                return fromModal;
            }
        }
        return undefined;
    }

    protected forEachInternalScreen(navModel: InternalPageNavigationModel, f: (screen: InternalScreen) => void): void {
        if (navModel.screen !== undefined) {
            f(navModel.screen);
        }
        if (navModel.modal !== undefined) {
            f(navModel.modal);
        }
    }

    protected getCurrentScreen(
        navModel: InternalPageNavigationModel | undefined,
        parsedPath: NavigationPath
    ): [screenName: string, screen: InternalScreen | undefined] {
        assert(parsedPath.isVisible());

        let internalScreen: InternalScreen | undefined;
        let screenName: string;
        const { main, modal } = parsedPath.getParsedScreens();
        if (modal !== undefined) {
            screenName = modal.screen.screenName;
            internalScreen = navModel?.modal;
        } else {
            screenName = defined(main).screen.screenName;
            internalScreen = navModel?.screen;
        }
        return [screenName, internalScreen];
    }

    private hydrateRequestSignatureSpecialScreen(
        hydrationContext: ScreenHydrationContext,
        screenContext: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        const [row] = screenContext.inputRows;
        if (row === undefined) return undefined;

        const title = getLocalizedString("signature", AppKind.Page);

        return this.hydrateSubscribingRowSpecialScreen(hydrationContext, title, screenContext, hb => {
            const shouldWrite = hb.getState("shouldWrite", isBoolean, false, false);
            const hasPaths = hb.getState("hasPaths", isBoolean, false, false);
            const didWrite = hb.getState("didWrite", isBoolean, false, false);

            function resetShouldWrite(ab: WireActionBackend) {
                if (!shouldWrite.value) return;
                ab.valueChanged(shouldWrite.onChangeToken, false, ValueChangeSource.User);
            }

            const cancelToken = shouldWrite.value
                ? undefined
                : hb.registerAction("cancel", async ab => {
                      ab.navigateUp();
                      resetShouldWrite(ab);
                      return WireActionResult.nondescriptSuccess();
                  });

            const cancelComponent: WireButtonComponent = {
                kind: WireComponentKind.Button,
                appearance: UIButtonAppearance.Bordered,
                action: definedMap(cancelToken, t => ({ token: t })),
                title: getLocalizedString("cancel", AppKind.Page),
            };

            const doneToken =
                !hasPaths.value || shouldWrite.value
                    ? undefined
                    : hb.registerAction("done", async ab =>
                          ab.valueChanged(shouldWrite.onChangeToken, true, ValueChangeSource.User)
                      );

            const doneComponent: WireButtonComponent = {
                kind: WireComponentKind.Button,
                appearance: UIButtonAppearance.Filled,
                action: definedMap(doneToken, t => ({ token: t })),
                title: getLocalizedString("done", AppKind.Page),
            };

            const onChangeToken = hb.registerOnSpecialScreenRowValueChange("value", row, "value");

            const onWriteCompleteToken = !shouldWrite.value
                ? undefined
                : hb.registerAction("onWriteComplete", async ab => {
                      resetShouldWrite(ab);
                      ab.navigateUp();
                      if (didWrite !== undefined) {
                          ab.valueChanged(didWrite.onChangeToken, true, ValueChangeSource.User);
                      }
                      return WireActionResult.nondescriptSuccess();
                  });

            const component: WirePageSignaturePadComponent = {
                kind: WireComponentKind.SignaturePad,
                image: {
                    value: "",
                    onChangeToken,
                },
                hasPaths,
                isWriting: shouldWrite.value,
                onWriteComplete: {
                    token: onWriteCompleteToken,
                },
            };

            return [
                {
                    key: encodeScreenKey(hydrationContext.screenKey),
                    title,
                    components: [component],
                    specialComponents: [doneComponent, cancelComponent],
                    flags: [WireScreenFlag.IsSignaturePad],
                    isInModal: true,
                    tabIcon: hydrationContext.internalScreen.tabIcon,
                    closeAction: this.appKind === AppKind.Page ? this.navigateUpAction : undefined,
                },
                undefined,
            ];
        });
    }

    private hydrateVoiceEntrySpecialScreen(
        hydrationContext: ScreenHydrationContext,
        screenContext: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        const [inputRow] = screenContext.inputRows;
        if (inputRow === undefined) return undefined;

        const title = asMaybeString(inputRow.drawerTitle) ?? "Add notes";
        return this.hydrateSubscribingRowSpecialScreen(hydrationContext, title, screenContext, hb => {
            const initialTranscript = asMaybeString(inputRow.transcript) ?? "";
            const textareaPlaceholder = asMaybeString(inputRow.textareaPlaceholder) ?? "Record or type";
            const shouldAutoRecord = inputRow.autoRecord === true;
            const localTranscript = hb.getState("localTranscript", isString, initialTranscript, false);
            const transcriptionState = hb.getState(
                "transcriptionState",
                (v: unknown): v is "idle" | "recording" | "processing" | "error" => {
                    return ["idle", "recording", "processing", "error"].includes(v as any);
                },
                "idle",
                false
            );

            const isBusy = transcriptionState.value === "recording" || transcriptionState.value === "processing";

            const shouldSaveChangeToken = hb.registerOnSpecialScreenRowValueChange(
                "shouldSave",
                inputRow,
                "shouldSave"
            );

            const transcriptChangeToken = hb.registerOnSpecialScreenRowValueChange(
                "transcript",
                inputRow,
                "transcript"
            );

            const cancelComponent: WireButtonComponent = {
                kind: WireComponentKind.Button,
                appearance: UIButtonAppearance.Bordered,
                action: this.navigateUpAction,
                title: getLocalizedString("cancel", AppKind.Page),
            };

            const saveToken =
                isBusy === false
                    ? hb.registerAction("save", async ab => {
                          ab.valueChanged(shouldSaveChangeToken, true, ValueChangeSource.User);
                          ab.valueChanged(transcriptChangeToken, localTranscript.value, ValueChangeSource.User);
                          ab.navigateUp();
                          return WireActionResult.nondescriptSuccess();
                      })
                    : undefined;

            const saveComponent: WireButtonComponent = {
                kind: WireComponentKind.Button,
                appearance: UIButtonAppearance.Filled,
                action: definedMap(saveToken, t => ({ token: t })),
                title: getLocalizedString("save", AppKind.Page),
            };

            const component: WirePageVoiceEntryComponent = {
                kind: WireComponentKind.VoiceEntry,
                transcript: localTranscript,
                transcriptionState,
                autoRecord: shouldAutoRecord,
                textareaPlaceholder,
            };

            return [
                {
                    key: encodeScreenKey(hydrationContext.screenKey),
                    title,
                    components: [component],
                    specialComponents: [saveComponent, cancelComponent],
                    flags: [WireScreenFlag.IsVoiceEntry],
                    isInModal: true,
                    tabIcon: hydrationContext.internalScreen.tabIcon,
                    closeAction: this.navigateUpAction,
                },
                undefined,
            ];
        });
    }

    protected hydrateSpecialScreen(
        _screen: ScreenDescription | undefined,
        hydrationContext: ScreenHydrationContext,
        _titleOverride: string | undefined,
        context: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        const {
            internalScreen: { screenName },
        } = hydrationContext;
        if (screenName === signInScreenName || screenName === signUpScreenName) {
            return this.hydrateSignInOutSpecialScreen(screenName);
        } else if (screenName === codeScannerScreenName || screenName === liveLinearBarcodeScannerScreenName) {
            return this.hydrateCodeScannerSpecialScreen(hydrationContext, context, []);
        } else if (screenName === requestSignatureScreenName) {
            return this.hydrateRequestSignatureSpecialScreen(hydrationContext, context);
        } else if (screenName === voiceEntryScreenName) {
            return this.hydrateVoiceEntrySpecialScreen(hydrationContext, context);
        } else {
            return undefined;
        }
    }
}
