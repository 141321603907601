import { panic, hasOwnProperty, sleep } from "@glideapps/ts-necessities";
import { QuadraticBackoffController, optimalInitialWindowForHoldingTimeFunction } from "@glide/support";

interface LowLevelFetchRetry {
    retries: number;
    clients: number;
}

const excelFetchTotalTimeout = 180_000;
const excelFetchSingleTimeout = 30_000;

// FIXME: This is a duplicate function; we copied it from functions/src/support.ts.
export function isAbortError(x: unknown): x is Error & { type: "aborted" } {
    if (!(x instanceof Error)) return false;
    return hasOwnProperty(x, "type") && x.type === "aborted";
}

const microsoftRetryAttempts: number = 5;
const microsoftWindowForQuadBackoff: (clients: number) => number = optimalInitialWindowForHoldingTimeFunction(
    6.6667, // 1500 requests per 10 seconds, per second window, in milliseconds
    microsoftRetryAttempts
);

async function fetchRetryOnLowlevelErrors<T>(
    fetchImpl: typeof fetch,
    requestInfo: RequestInfo,
    requestInit: RequestInit | undefined,
    { retries, clients }: LowLevelFetchRetry,
    bodyHandler: (response: Response) => Promise<T>
): Promise<T> {
    const backoff = new QuadraticBackoffController(microsoftWindowForQuadBackoff(clients));
    const timeoutController = new AbortController();
    const timeoutAbort = () => timeoutController.abort();
    const fullTimeoutHandle = setTimeout(timeoutAbort, excelFetchTotalTimeout);
    requestInit?.signal?.addEventListener("abort", timeoutAbort);

    try {
        for (let i = 0; i < retries; i++) {
            const requestController = new AbortController();
            const requestAbort = () => requestController.abort();
            timeoutController.signal.addEventListener("abort", requestAbort);
            const localTimeoutHandle = setTimeout(requestAbort, excelFetchSingleTimeout);
            if (i > 0) {
                await sleep(backoff.getWaitTime());
            }
            try {
                const response = await fetchImpl(requestInfo, { ...requestInit, signal: requestController.signal });
                if (Math.floor(response.status / 100) === 5 && i < retries - 1) {
                    // This was an HTTP 5xx error; we'll attempt a retry here.
                    // We don't need to read the contents of the text.
                    continue;
                }
                return await bodyHandler(response);
            } catch (e: unknown) {
                if (isAbortError(e)) {
                    if (timeoutController.signal.aborted) throw e;
                    continue;
                }
                if (!(hasOwnProperty(e, "code") && typeof e.code === "string")) throw e;
                // We receive a few low-level errors every so often:
                // EAI_AGAIN if name resolution temporarily fails
                // ETIMEDOUT if we hit a TCP timeout
                // ECONNRESET just because the network can
                // ECONNREFUSED when the network is really down for a bit
                if (
                    (e.code === "EAI_AGAIN" ||
                        e.code === "ETIMEDOUT" ||
                        e.code === "ECONNRESET" ||
                        e.code === "ECONNREFUSED") &&
                    i < retries - 1
                ) {
                    continue;
                }
                throw e; // If we have more Fetch errors we'll have to explicity trap them
            } finally {
                timeoutController.signal.removeEventListener("abort", requestAbort);
                clearTimeout(localTimeoutHandle);
                // If we haven't actually awaited the body, aborting will at least
                // close the network connection.
                requestAbort();
            }
        }
    } finally {
        requestInit?.signal?.removeEventListener("abort", timeoutAbort);
        clearTimeout(fullTimeoutHandle);
    }
    return panic("fetchRetryOnLowlevelErrors could not return normally");
}

export class MicrosoftGraphError extends Error {
    constructor(public readonly statusCode: number, public readonly body: string) {
        super(`Microsoft Graph returned with HTTP status ${statusCode}: ${body}`);
    }
}

// The Microsoft Graph API is a comparatively unreliable one, and regularly faults with
// HTTP 404 that really should be HTTP 500. We have to intervene in between our application
// code and the network code to work around this bad behavior.
//
// Luckily for us, the "expected response" for a missing entity is specifically
//
// {
//   "error": {
//     "code": "itemNotFound"
//   }
// }
//
// In some cases, the code might be "ResourceNotFound" or "Request_ResourceNotFound". Any other 404 that doesn't
// have this shape is actually not a 404.
// FIXME: This is a duplicate of the same code in
function isReallyMsGraph404(body: string): boolean {
    try {
        const bodyAsJSON = JSON.parse(body);
        if (!hasOwnProperty(bodyAsJSON, "error") || typeof bodyAsJSON.error !== "object") return false;
        if (!hasOwnProperty(bodyAsJSON.error, "code")) return false;
        switch (bodyAsJSON.error.code) {
            case "ItemNotFound":
            // fallthrough
            case "itemNotFound":
                return true;
            case "resourceNotFound":
            // fallthrough
            case "ResourceNotFound":
                return true;
            case "Request_ResourceNotFound":
                return true;
            default:
                return false;
        }
    } catch {
        return false;
    }
}

const expectedConcurrentClients = 100;

export async function microsoftGraphFetch(
    fetchImpl: typeof fetch,
    refreshToken: () => Promise<any>,
    requestInfo: RequestInfo,
    requestInit: RequestInit | undefined
): Promise<any> {
    const backoff = new QuadraticBackoffController(microsoftWindowForQuadBackoff(expectedConcurrentClients));
    const didRefresh = false;
    let lastStatus: number | undefined, lastBody: string | undefined;
    for (let i = 0; i < microsoftRetryAttempts; i++) {
        if (i > 0) {
            await sleep(backoff.getWaitTime());
        }
        const response = await fetchRetryOnLowlevelErrors<{ kind: "ok"; result: any } | { kind: "retry" }>(
            fetchImpl,
            requestInfo,
            requestInit,
            { retries: microsoftRetryAttempts, clients: expectedConcurrentClients },
            async resp => {
                if (resp.status === 204) return { kind: "ok" as const, result: {} };

                const bodyText = await resp.text();
                if (resp.ok) return { kind: "ok", result: bodyText.length === 0 ? {} : JSON.parse(bodyText) };

                lastStatus = resp.status;
                lastBody = bodyText;
                if (resp.status / 100 === 5) return { kind: "retry" as const };

                if ((resp.status === 401 || resp.status === 403) && !didRefresh) {
                    await refreshToken();
                    return { kind: "retry" as const };
                }
                if (resp.status !== 404 || isReallyMsGraph404(bodyText)) {
                    throw new MicrosoftGraphError(resp.status, bodyText);
                }
                return { kind: "retry" as const };
            }
        );
        if (response.kind === "ok") return response.result;
    }
    if (lastStatus !== undefined && lastBody !== undefined) {
        throw new MicrosoftGraphError(lastStatus, lastBody);
    }
    return panic("microsoftGraphFetch could not return normally");
}

const msGraphDNS = `graph.microsoft.com`;
const msGraphVersion = `v1.0`;
export function microsoftGraphURL(path: string, searchParams: readonly (readonly [string, string])[] = []): string {
    const basePath = `https://${msGraphDNS}/${msGraphVersion}/${path}`;
    if (searchParams.length === 0) return basePath;
    const baseURL = new URL(basePath);
    for (const [key, value] of searchParams) {
        baseURL.searchParams.set(key, value);
    }
    return baseURL.href;
}

export function dateToGraphDateTimeZone(d: Date): { dateTime: string; timeZone: string } {
    const dateTime = d.toISOString().replace(/[zZ]$/, "");
    return {
        dateTime,
        timeZone: "Etc/GMT",
    };
}
