import { styled } from "@glide/common";
import { useBuilderTheme } from "../hooks/use-builder-theme";

interface Props extends React.PropsWithChildren {
    size?: "normal" | "large" | "larger" | "xlarge" | "2xlarge";
    label: string;
    colorSeed?: string;
    backgroundColor?: string;
}

const sizes: Record<
    NonNullable<Props["size"]>,
    { size: number; radius: number; text: number; containerSize: number }
> = {
    normal: { size: 22, radius: 6, text: 10, containerSize: 28 },
    large: { size: 28, radius: 7, text: 12, containerSize: 28 },
    larger: { size: 36, radius: 9, text: 16, containerSize: 36 },
    xlarge: { size: 48, radius: 16, text: 25, containerSize: 48 },
    "2xlarge": { size: 64, radius: 8, text: 32, containerSize: 64 },
};

interface StyleProps {
    containerSize: number;
    size: number;
    radius: number;
    text: number;
    background: string;
    greyedOut: boolean;
}

const OrgIconStyle = styled.div<StyleProps>`
    height: ${p => p.containerSize}px;
    width: ${p => p.containerSize}px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1px rgb(128 128 128 / 8%);

    div {
        width: ${p => p.size}px;
        height: ${p => p.size}px;
        line-height: ${p => p.size}px;
        text-align: center;
        background: ${p => p.background};
        border-radius: ${p => p.radius}px;
        font-size: ${p => p.text}px;
        color: white;
        font-weight: 600;
        transition: background-color ${p => p.theme.transitionBase};
        user-select: none;
    }
`;

export const OrgIcon = (props: Props) => {
    const { size = "normal", label, backgroundColor } = props;
    const initial = (label || "T").substring(0, 1).toUpperCase();
    const greyedOut = size === "normal" || label === "";

    const theme = useBuilderTheme();

    const background = backgroundColor ?? (greyedOut ? theme.n500 : theme.aqua);
    return (
        <OrgIconStyle {...sizes[size]} background={background} greyedOut={greyedOut}>
            <div>{initial}</div>
        </OrgIconStyle>
    );
};
