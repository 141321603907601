import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import { getFeatureFlag } from "@glide/common-core/dist/js/feature-flags";
import {
    type ColumnValues,
    type EnqueueActionRequestBody,
    type EnqueueSingleActionRequest,
    enqueueActionResponseBodyCodec,
} from "@glide/common-core/dist/js/firebase-function-types";
import { sleep, hasOwnProperty } from "@glideapps/ts-necessities";
import { isResponseOK } from "@glide/support";
import { EnqueueActionException } from "./types";

class InvalidClientRequestException extends EnqueueActionException {
    constructor(appID: string, kind: string, jobID: string) {
        super(appID, kind, jobID, "Invalid request body for", undefined);
    }
}

class InvalidServerResponseException extends EnqueueActionException {
    constructor(appID: string, kind: string, jobID: string) {
        super(appID, kind, jobID, "Invalid response body for", undefined);
    }
}

class UnauthorizedActionException extends EnqueueActionException {
    constructor(appID: string, kind: string, jobID: string) {
        super(appID, kind, jobID, "Action was not authorized", undefined);
    }
}

class ActionTooLargeException extends EnqueueActionException {
    constructor(appID: string, kind: string, jobID: string) {
        super(appID, kind, jobID, "Invalid request body for", undefined);
    }
}

export class PermanentActionFailureException extends EnqueueActionException {
    constructor(appID: string, kind: string, jobID: string, originalMessage: string | undefined) {
        super(appID, kind, jobID, "Permanent failure", originalMessage);
    }
}

/**
 * This is a transient failure, but we need to pass it through and not retry
 * locally.  We use this in Automations to avoid blocking indefinitely.
 */
export class TransientPassThroughFailureException extends EnqueueActionException {
    constructor(appID: string, kind: string, jobID: string, originalMessage: string | undefined) {
        super(appID, kind, jobID, "Transient failure", originalMessage);
    }
}

export function isPassThroughEnqueueError(
    x: unknown
): x is PermanentActionFailureException | TransientPassThroughFailureException {
    return x instanceof PermanentActionFailureException || x instanceof TransientPassThroughFailureException;
}

// InvalidServerResponseException is likely recoverable. Consider the all too
// common case of invasive HTTP middleware that injects an HTTP 200 that just
// so happens to be blocking the request. If users switch networks such that
// this invasive HTTP middleware is removed, retrying will work.
export function isLikelyUnrecoverableEnqueueError(
    x: unknown
): x is
    | InvalidClientRequestException
    | UnauthorizedActionException
    | ActionTooLargeException
    | PermanentActionFailureException {
    return (
        x instanceof InvalidClientRequestException ||
        x instanceof UnauthorizedActionException ||
        x instanceof ActionTooLargeException ||
        isPassThroughEnqueueError(x)
    );
}

export interface EnqueueDataActionResult {
    // If this is `undefined` it means it's not confirmed yet, and the
    // frontend has to listen to the action metadata document for
    // confirmation.
    readonly confirmedAtVersion: number | undefined;
    readonly newRow: ColumnValues | undefined;
}

// Exported for use in this package and for testing
export async function callEnqueueDataAction(
    appFacilities: ActionAppFacilities,
    actionRequest: EnqueueSingleActionRequest
): Promise<EnqueueDataActionResult> {
    const { kind, actionMetadata } = actionRequest;
    const body: EnqueueActionRequestBody = {
        appID: actionMetadata.appID,
        ...actionRequest,
    };
    if (getFeatureFlag("stallActionPosting")) {
        await sleep(10_000);
    }
    const response = await appFacilities.callAuthIfAvailableCloudFunction("enqueueDataAction", body, {});
    let json: any;

    try {
        json = await response?.json();
    } catch {
        // nothing to do
    }

    // FIXME: Establish better exceptions here
    if (!isResponseOK(response)) {
        if (hasOwnProperty(json, "isPermanentFailure") && json.isPermanentFailure === true) {
            const message =
                hasOwnProperty(json, "message") && typeof json.message === "string" ? json.message : undefined;
            throw new PermanentActionFailureException(actionMetadata.appID, kind, actionMetadata.jobID, message);
        }
        if (response?.status === 400) {
            throw new InvalidClientRequestException(actionMetadata.appID, kind, actionMetadata.jobID);
        }
        if (response?.status === 401 || response?.status === 403) {
            throw new UnauthorizedActionException(actionMetadata.appID, kind, actionMetadata.jobID);
        }
        if (response?.status === 413) {
            throw new ActionTooLargeException(actionMetadata.appID, kind, actionMetadata.jobID);
        }
        throw new Error(`Could not enqueueDataAction ${kind}: ${response?.status}`);
    }

    if (enqueueActionResponseBodyCodec.is(json)) {
        return { confirmedAtVersion: json.confirmedAtVersion, newRow: json.newRow };
    } else {
        // This should not happen, but we're being careful here not to crash,
        // which would potentially mean losing data.
        return { confirmedAtVersion: undefined, newRow: undefined };
    }
}

export function handleFaultInjection(appID: string, kind: string, jobID: string) {
    if (getFeatureFlag("simulateUnauthorizedActionPosting")) {
        throw new UnauthorizedActionException(appID, kind, jobID);
    }
    if (getFeatureFlag("injectActionPostingFaults")) {
        if (Math.random() >= 0.333) return;
        const exceptionRoll = Math.random();
        if (exceptionRoll <= 0.333) {
            throw new InvalidClientRequestException(appID, kind, jobID);
        }
        if (exceptionRoll <= 0.666) {
            throw new InvalidServerResponseException(appID, kind, jobID);
        }
        throw new UnauthorizedActionException(appID, kind, jobID);
    }
}
