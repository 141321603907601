import type { SignInTheme } from "../custom-sign-in-types";
import { disabledProps, styled } from "../glide-styled-components";

interface CheckboxIconProps {
    signInTheme?: SignInTheme;
    defaultColor: string;
}

export const CheckboxIcon = (props: CheckboxIconProps) => (
    <svg className="box" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect className="checked-box" width="18" height="18" rx="2" fill="currentColor" />
        <path
            className="unchecked-box"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z"
            fill="currentColor"
        />
        <path
            id="checkmark"
            style={{ strokeDasharray: "30px", strokeDashoffset: "30px" }}
            transform="translate(2, 3)"
            d="M0.699951 5.51922L4.99995 9.49999L13.3 1.67307"
            stroke={props.signInTheme ?? props.defaultColor}
            strokeWidth="2"
        />
        <path
            id="default-checkmark"
            transform="translate(2, 3)"
            d="M0.699951 5.51922L4.99995 9.49999L13.3 1.67307"
            stroke={props.signInTheme ?? props.defaultColor}
            strokeWidth="2"
        />
    </svg>
);

export const CheckboxWrapper = styled.label<{ isEnabled: boolean; disablePadding: boolean }>`
    cursor: pointer;

    & > input {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
    }

    line-height: 0px;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin: ${p => (p.disablePadding ? 0 : -12)}px 0;

    ${p => !p.isEnabled && disabledProps}
`;

export const CheckboxContainer = styled.div<{ disablePadding: boolean; color: string }>`
    padding: 0px ${p => (p.disablePadding ? "0" : p.theme.pageMarginMedium)}px;
    color: ${p => p.color};

    .default.checked & svg.box {
        .unchecked-box,
        #checkmark {
            display: none;
        }
    }

    .default.unchecked & svg.box {
        .checked-box,
        #checkmark,
        #default-checkmark {
            display: none;
        }
    }

    .changed.checked & svg.box {
        .unchecked-box,
        #default-checkmark {
            display: none;
        }
        #checkmark {
            animation: draw 0.2s ease-in forwards;
        }
    }

    .changed.unchecked & .box {
        .checked-box,
        #checkmark,
        #default-checkmark {
            display: none;
        }
    }

    @keyframes draw {
        to {
            stroke-dashoffset: 0;
        }
    }
`;
