import type {
    ArrayContentDescription,
    ArrayScreenFormat,
    ComponentDescription,
    MutatingScreenKind,
    PropertyDescription,
    ExperimentName,
    FeatureSettingName,
    UserFeatures,
} from "@glide/app-description";
import type { Description, TableGlideType } from "@glide/type-schema";
import { type InputOutputTables, ContainerLayout } from "@glide/common-core/dist/js/description";
import type { Doc } from "@glide/common-core/dist/js/docUrl";
import { GroupingSupport } from "@glide/component-utils";
import { type BillablesConsumed, type PluginTierList, type PluginTier, makeTierList } from "@glide/plugins";
import {
    type AppDescriptionContext,
    type ComponentSpecialCaseDescriptor,
    type InlineListComponentDescription,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    type PropertyTableGetter,
    type PropertyConfiguratorKind,
    type SuperPropertySection,
    PropertySection,
    QueryableTableSupport,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import type {
    WireDynamicFilter,
    WireMultipleDynamicFilters,
    WireComponentKind,
    WireAction,
    WireComponent,
    WireEditableValue,
    WirePagedGroup,
    WirePaging,
} from "@glide/wire";
import type { EasyCRUDFlags } from "./easy-crud";
import type {
    ActionSpec,
    FluentInternalStateSpec,
    FluentPropertiesSpec,
    InternalStateSpec,
} from "./fluent-properties-spec";
import { FluentProperties } from "./fluent-properties-spec";
import type { MaybeNegated } from "./negated";

type PropertiesOfComponent<T> = T extends FluentComponent<infer U, infer V, unknown, any> ? U & V : never;
type AsFormProperties<T> = { readonly [K in keyof T]?: WireEditableValue<NonNullable<T[K]>> };

// Only three levels of pretty so we don't affect TS too much.
// For this cases this is fine, since we don't build deeper than this.
type PrettifyIntersectionDeep2<T> = {
    [K in keyof T]: T[K];
} & {};

type PrettifyIntersectionDeep1<T> = {
    [K in keyof T]: T[K] extends object ? PrettifyIntersectionDeep2<T[K]> : T[K];
} & {};

export type PrettifyIntersection<T> = {
    [K in keyof T]: T[K] extends object ? PrettifyIntersectionDeep1<T[K]> : T[K];
} & {};

type InternalStateType<T> = T extends InternalStateSpec<infer U> ? U : never;
export type InternalStateProperties<T> = {
    [K in keyof T]: WireEditableValue<InternalStateType<T[K]>>;
};

type UglyWirePropertiesOfComponent<T> = T extends FluentComponent<infer U, infer V, infer W, infer X>
    ? WireComponent & W & U & AsFormProperties<V> & InternalStateProperties<X>
    : never;

export type WirePropertiesOfComponent<T> = PrettifyIntersection<UglyWirePropertiesOfComponent<T>>;

type PropertiesOfArrayContent<T> = T extends FluentArrayContent<infer U, infer V, unknown> ? U & V : never;

type UglyWirePropertiesOfArrayContent<T> = T extends FluentArrayContent<infer U, infer V, infer W>
    ? W & V & WireComponent & { readonly groups: readonly WirePagedGroup<U>[] }
    : never;

export type WirePropertiesOfArrayContent<T> = PrettifyIntersection<UglyWirePropertiesOfArrayContent<T>>;

// TODO: We're not forcing the correct `kind` here.  We'd have to add another
// generic argument to `FluentComponent` to be the component kind, then we
// could use that here as the `kind` type.
type UglyDescriptionOfComponent<T> = ComponentDescription & {
    readonly [K in keyof PropertiesOfComponent<T>]?: PropertyDescription;
};

export type DescriptionOfComponent<T> = PrettifyIntersection<UglyDescriptionOfComponent<T>>;

export type ArrayContentDescriptionForProperties<T> = ArrayContentDescription & {
    [K in keyof T]: PropertyDescription | undefined;
};

export type DescriptionOfArrayContent<T> = {
    readonly [K in keyof PropertiesOfArrayContent<T>]?: PropertyDescription;
};
type UglyDescriptionOfArrayContentInlineList<T> = InlineListComponentDescription & DescriptionOfArrayContent<T>;
export type DescriptionOfArrayContentInlineList<T> = PrettifyIntersection<UglyDescriptionOfArrayContentInlineList<T>>;

export enum ContainerKind {
    // The subcomponents are all part of the same context as the containing
    // screen.
    Regular = "regular",
    // The container has an output row from a potentially different table.
    Form = "form",
}

type FluentComponentSpecializer = (
    desc: ComponentDescription,
    tables: InputOutputTables,
    iccc: InteractiveComponentConfiguratorContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    insideContainer: boolean
) => ComponentDescription;

interface ComponentSpecialCaseSpec extends Omit<ComponentSpecialCaseDescriptor, "isLegacy"> {
    readonly getIsHidden?: (userFeatures: UserFeatures) => boolean;
}

export function getSpecialCaseDescriptorFromSpec(
    spec: ComponentSpecialCaseSpec,
    userFeatures: UserFeatures
): ComponentSpecialCaseDescriptor {
    const { getIsHidden, ...commonSpec } = spec;

    const isCaseLegacy = getIsHidden?.(userFeatures) === true;

    return {
        ...commonSpec,
        isLegacy: isCaseLegacy,
    };
}

type FluentComponentSpecialCases = readonly [ComponentSpecialCaseSpec, FluentComponentSpecializer][];

interface CustomConfigurator {
    readonly kind: PropertyConfiguratorKind;
    readonly section: SuperPropertySection;
}

export interface FluentComponentSpec {
    readonly kind: WireComponentKind;

    readonly displayName?: string;
    readonly description?: string;
    readonly group?: string;
    readonly icon?: string;
    readonly tier?: PluginTierList;
    readonly isBeta?: boolean;
    readonly billablesConsumed?: (adc: AppDescriptionContext) => BillablesConsumed | undefined;
    readonly keywords?: readonly string[];
    readonly docURL?: Doc;
    readonly containerKind?: ContainerKind;
    readonly properties: FluentPropertiesSpec;
    readonly internalState: FluentInternalStateSpec;
    readonly formProperties: FluentPropertiesSpec;
    readonly actions?: readonly ActionSpec[];
    readonly configuresScreenTitle?: boolean;
    readonly featureSetting?: MaybeNegated<FeatureSettingName>;
    readonly experimentFlag?: MaybeNegated<ExperimentName>;
    // `true` means show the component, `false` means hide the component.
    readonly userFeatureChecker?: (userFeatures: UserFeatures) => boolean;
    readonly specialCases?: FluentComponentSpecialCases;
    readonly includeRegularComponent: boolean;
    // There's this "feature" in Glide where components don't show if they don't have any data
    // but there are exceptions to that rule. For example: Separator and Location.
    readonly showRegardlessOfEmptyProperties?: boolean;
    // FIXME: This doesn't allow specifying where that configurator will show
    // up.  The way to do that would be for it to be part of the `properties`,
    // but `PropertySpec` assumes that there's a real property being
    // configured, so it has lots of stuff that's not relevant to this.  Not
    // clear what the best apprach is.  Maybe for the time being it's enough
    // that it has a property section?
    readonly customConfigurator?: CustomConfigurator;
    readonly prompt?: string;
}

export class FluentComponent<TProps, TForm, TWire, TInternalState> {
    constructor(public readonly spec: FluentComponentSpec) {}

    public withBeta(beta: boolean): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, isBeta: beta });
    }

    public withPrompt(prompt: string): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, prompt });
    }

    public withProperties<T>(
        props: FluentProperties<T, unknown>
    ): FluentComponent<TProps & T, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, properties: props.spec });
    }

    public withInternalState<TName extends string, StateType>(
        name: TName,
        internalState: InternalStateSpec<StateType>
    ): FluentComponent<TProps, TForm, TWire, TInternalState & Record<TName, InternalStateSpec<StateType>>> {
        return new FluentComponent({
            ...this.spec,
            internalState: { ...this.spec.internalState, [name]: internalState },
        });
    }

    public withFormProperties<T>(
        props: FluentProperties<T, unknown>
    ): FluentComponent<TProps, TForm & T, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, formProperties: props.spec });
    }

    public withDisplayName(displayName: string): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, displayName });
    }

    public withPluginTier(lowestSupported: PluginTier): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, tier: makeTierList(lowestSupported) });
    }

    public withBillablesConsumed(
        billablesConsumed: (adc: AppDescriptionContext) => BillablesConsumed | undefined
    ): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, billablesConsumed });
    }

    public withIcon(icon: string): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, icon });
    }

    public withDocURL(docURL: Doc): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, docURL });
    }

    public withinGroup(group: string): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, group });
    }

    public withDescription(description: string): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, description });
    }

    public withKeywords(...keywords: readonly string[]): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, keywords: [...(this.spec.keywords ?? []), ...keywords] });
    }

    public withFeatureSetting(
        featureSetting: FeatureSettingName,
        negate?: boolean
    ): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({
            ...this.spec,
            featureSetting: { value: featureSetting, isNegated: negate ?? false },
        });
    }

    public withExperimentFlag(
        experimentFlag: ExperimentName,
        negate?: boolean
    ): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({
            ...this.spec,
            experimentFlag: { value: experimentFlag, isNegated: negate ?? false },
        });
    }

    public withUserFeature(
        userFeatureChecker: (userFeatures: UserFeatures) => boolean
    ): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, userFeatureChecker });
    }

    public withScreenTitle(): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, configuresScreenTitle: true });
    }

    public withLegacy(): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, includeRegularComponent: false });
    }

    public withSpecialCases(
        includeRegularComponent: boolean,
        specialCases: FluentComponentSpecialCases
    ): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, specialCases, includeRegularComponent });
    }

    public withShowRegardlessOfEmptyProperties(show: boolean): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, showRegardlessOfEmptyProperties: show });
    }

    public withCustomConfigurator(kind: CustomConfigurator): FluentComponent<TProps, TForm, TWire, TInternalState> {
        return new FluentComponent({ ...this.spec, customConfigurator: kind });
    }
}

interface EasyCRUDHandler<TDesc> {
    readonly withDelete: boolean;
    readonly withConditions: boolean;

    // These all default to `true`
    readonly withEdit?: boolean;
    readonly withAddTitle?: boolean;
    readonly withEditTitle?: boolean;
    readonly withDeleteTitle?: boolean;

    // This will be called when CRUD is turned on, as well as when the source
    // changes.
    updateSource(
        desc: TDesc,
        screenTables: InputOutputTables,
        itemTable: TableGlideType,
        iccc: InteractiveComponentConfiguratorContext
    ): Partial<TDesc>;

    // `forGC` means that anything that's not included in the updated
    // description will be removed.
    lower(desc: TDesc, flags: EasyCRUDFlags, forGC: boolean): Partial<TDesc & InlineListComponentDescription>;
}

type StringKeys<T> = Extract<keyof T, string>;
interface FluentArrayContentAdditionalColumns {
    excludedProperties: string[];
}

type SubComponentMaker = (tables: InputOutputTables) => readonly ComponentDescription[];
interface FluentArrayContentSpec {
    readonly displayName?: string;
    readonly icon?: string;
    readonly withQuotaKey?: boolean;
    readonly format?: ArrayScreenFormat;
    readonly itemProperties: FluentPropertiesSpec;
    readonly componentProperties: FluentPropertiesSpec;
    readonly docURL?: Doc;
    readonly specialCases: readonly ComponentSpecialCaseSpec[];
    readonly featureSetting?: MaybeNegated<FeatureSettingName>;
    readonly experimentFlag?: MaybeNegated<ExperimentName>;
    readonly experimentFlagAndFeatureSetting?: boolean;
    readonly groupingSupport: GroupingSupport;
    readonly paging: false | "default" | "custom" | number;
    readonly maxPageSize: number | undefined;
    readonly handlesSorting: boolean;
    // This will set `needsFilterValues` and turn off dynamic filter support
    // for queryable tables.
    readonly customFiltering: boolean;
    readonly searchDefault: boolean;
    readonly canDeleteRows: boolean;
    readonly canShowIfEmpty: boolean;
    readonly queryableTableSupport: QueryableTableSupport;
    readonly allowsQueryableSort: boolean;
    readonly easyCRUDHandler: EasyCRUDHandler<Description> | undefined;
    // This is set when we're a container
    readonly makeSubComponents: SubComponentMaker | undefined;

    readonly extraPropertyDescriptors?: (
        getPropertyTable: PropertyTableGetter | undefined
    ) => readonly PropertyDescriptor[];
    readonly columnAssignments?: FluentArrayContentAdditionalColumns;
    readonly handlesLimit: boolean;
    readonly onlyQueries: boolean;
}

export class FluentArrayContent<TItemProps, TComponentProps, TWire> {
    constructor(public readonly spec: FluentArrayContentSpec) {}

    public withDisplayName(displayName: string): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, displayName });
    }

    public withIcon(icon: string): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, icon });
    }

    public withItemProperties<T>(
        makeProps: (p: FluentProperties<unknown, TComponentProps>) => FluentProperties<T, TComponentProps>
    ): FluentArrayContent<T, TComponentProps, TWire> {
        const props = makeProps(new FluentProperties(emptyPropertiesSpec));
        return new FluentArrayContent({ ...this.spec, itemProperties: props.spec });
    }

    public withComponentProperties<T>(props: FluentProperties<T, unknown>): FluentArrayContent<TItemProps, T, TWire> {
        return new FluentArrayContent({ ...this.spec, componentProperties: props.spec });
    }

    public withSpecialCases(
        specialCases: readonly ComponentSpecialCaseSpec[]
    ): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, specialCases });
    }

    public withDocURL(docURL: Doc): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, docURL });
    }

    public withGrouping(groupingSupport: GroupingSupport): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, groupingSupport });
    }

    public withPaging(
        // A number means the page size is fixed to that value.
        paging: false | "default" | "custom" | number,
        maxPageSize?: number
    ): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, paging, maxPageSize });
    }

    public withQuotaKey(): FluentArrayContent<
        TItemProps,
        TComponentProps,
        TWire & { readonly quotaKey: string | undefined }
    > {
        return new FluentArrayContent({ ...this.spec, withQuotaKey: true });
    }

    public withHandlesSorting(): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, handlesSorting: true });
    }

    public withCustomFiltering(): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, customFiltering: true });
    }

    public withSearchDefault(defaultState: boolean): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, searchDefault: defaultState });
    }

    public withCanDeleteRows(): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, canDeleteRows: true });
    }

    // Collections with title actions can show even when they're empty.  This
    // usually only needs to be set to `false` on components that have a
    // custom hydrator and that don't have title actions.
    public withCanShowIfEmpty(defaultState: boolean): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, canShowIfEmpty: defaultState });
    }

    // If `support` is `Disable` then it's disabled for all queryable tables,
    // including Big Tables.
    public withQueryableTables(
        support: QueryableTableSupport,
        allowSort: boolean
    ): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, queryableTableSupport: support, allowsQueryableSort: allowSort });
    }

    public withEasyCRUD(
        handler: EasyCRUDHandler<{ readonly [K in keyof (TItemProps & TComponentProps)]?: PropertyDescription }>
    ): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, easyCRUDHandler: handler });
    }

    // We don't show any of these for easy tab configuration.
    public withExtraPropertyDescriptors(
        extraPropertyDescriptors: (getPropertyTable: PropertyTableGetter | undefined) => readonly PropertyDescriptor[]
    ): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, extraPropertyDescriptors });
    }

    public withIsContainer(
        makeSubComponents: SubComponentMaker = () => []
    ): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, makeSubComponents });
    }

    public withFeatureSetting(
        featureSetting: FeatureSettingName,
        negate?: boolean
    ): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({
            ...this.spec,
            featureSetting: { value: featureSetting, isNegated: negate ?? false },
        });
    }

    public withExperimentFlag(
        experimentFlag: ExperimentName,
        negate?: boolean
    ): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({
            ...this.spec,
            experimentFlag: { value: experimentFlag, isNegated: negate ?? false },
        });
    }

    public withExperimentFlagAndFeatureSetting(
        experimentFlagAndFeatureSetting: boolean
    ): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({ ...this.spec, experimentFlagAndFeatureSetting });
    }

    public withColumnAssignments(
        excludedProperties: (StringKeys<TItemProps> | StringKeys<TComponentProps>)[]
    ): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({
            ...this.spec,
            columnAssignments: { excludedProperties },
        });
    }

    public withHandlesLimit(): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({
            ...this.spec,
            handlesLimit: true,
        });
    }

    public withOnlyQueries(): FluentArrayContent<TItemProps, TComponentProps, TWire> {
        return new FluentArrayContent({
            ...this.spec,
            onlyQueries: true,
        });
    }
}

export const emptyPropertiesSpec: FluentPropertiesSpec = {
    propertySpecs: [],
    arraySpecs: [],
    actionSpecs: [],
    hasDisplayedProperties: false,
};

export const properties = new FluentProperties(emptyPropertiesSpec);

export const propertiesWithCSS = properties.withPrimitive("customCssClassName", {
    displayName: "CSS class",
    propertySection: PropertySection.CustomCSS,
    allowColumn: false,
    isDisplayed: false,
    when: (_desc, _parentDesc, _tables, adc) => {
        // TS is crazy, it says this is any.
        const isPage: boolean = adc?.appKind === AppKind.Page;
        const hasCustomCss = adc?.eminenceFlags.pagesCustomCss === true;
        return isPage && hasCustomCss;
    },
});

// The layout property for ##containerComponents.
export const containerPropertiesWithCSS = propertiesWithCSS.withEnum(
    "layout",
    [
        { value: ContainerLayout.Full, label: "Full", icon: "mt-container-columns-1" },
        { value: ContainerLayout.OneToOne, label: "1:1", icon: "mt-container-columns-2" },
        { value: ContainerLayout.OneToTwo, label: "1:2", icon: "mt-container-columns-1-2" },
        { value: ContainerLayout.TwoToOne, label: "2:1", icon: "mt-container-columns-2-1" },
        { value: ContainerLayout.ThreeColumns, label: "3 columns", icon: "mt-container-columns-3" },
        { value: ContainerLayout.FourColumns, label: "4 columns", icon: "mt-container-columns-4" },
    ],
    {
        propertySection: PropertySection.Layout,
        enumVisual: "large-images",
    }
);

export function component<TKind extends WireComponentKind>(kind: TKind) {
    return new FluentComponent<unknown, unknown, { readonly kind: TKind }, {}>({
        kind,
        properties: emptyPropertiesSpec,
        formProperties: emptyPropertiesSpec,
        includeRegularComponent: true,
        internalState: {},
    });
}

export function form<TKind extends WireComponentKind>(kind: TKind) {
    return new FluentComponent<unknown, unknown, { readonly kind: TKind; readonly submitAction?: WireAction }, {}>({
        kind,
        properties: emptyPropertiesSpec,
        formProperties: emptyPropertiesSpec,
        includeRegularComponent: true,
        internalState: {},
    });
}

export interface WireListComponentGeneric<TFormat extends ArrayScreenFormat> extends WireComponent {
    readonly kind: WireComponentKind.List;
    readonly format: TFormat;

    // Only used in Pages.  Some components use this to page list items,
    // others (Collection) to page groups.
    readonly paging?: WirePaging;

    readonly searchBar?: WireEditableValue<string>;

    readonly dynamicFilter?: WireDynamicFilter;

    readonly multipleDynamicFilters?: WireMultipleDynamicFilters;
}

export function arrayContent<TFormat extends ArrayScreenFormat>(format: TFormat) {
    return new FluentArrayContent<unknown, unknown, WireListComponentGeneric<TFormat>>({
        format,
        itemProperties: emptyPropertiesSpec,
        componentProperties: emptyPropertiesSpec,
        specialCases: [],
        groupingSupport: GroupingSupport.None,
        paging: false,
        maxPageSize: undefined,
        handlesSorting: false,
        customFiltering: false,
        canDeleteRows: false,
        canShowIfEmpty: true,
        queryableTableSupport: QueryableTableSupport.LoadOnDemand,
        allowsQueryableSort: true,
        easyCRUDHandler: undefined,
        makeSubComponents: undefined,
        searchDefault: true,
        handlesLimit: false,
        onlyQueries: false,
    });
}
