import { styled } from "@glide/common";
import { css } from "styled-components";

export type SwitchToggleSize = "default" | "sm" | "xsm";

const defaultStyles = css`
    .check {
        width: 34px;
        height: 20px;

        .thumb {
            left: 2px;
            top: 2px;
            height: 16px;
            width: 16px;
        }
    }

    &.enabled {
        .thumb {
            transform: translateX(14px);
        }
    }
`;

const smallStyles = css`
    .check {
        width: 26px;
        height: 16px;
        .thumb {
            left: 2px;
            top: 2px;
            height: 12px;
            width: 12px;
        }
    }

    &.enabled {
        .thumb {
            transform: translateX(10px);
        }
    }
`;

const xSmallStyles = css`
    .check {
        width: 21px;
        height: 12px;
        .thumb {
            left: 1px;
            top: 1px;
            height: 10px;
            width: 10px;
        }
    }

    &.enabled {
        .thumb {
            transform: translateX(9px);
        }
    }
`;

export const SwitchToggleContainer = styled.div<{ size: SwitchToggleSize }>`
    cursor: pointer;

    position: relative;

    .check {
        position: relative;
        background-color: ${p => p.theme.switchToggle.background};
        border-radius: 100px;
        transition: background-color 0.2s;
        .thumb {
            position: absolute;
            transition: transform 0.2s;
            border-radius: 100px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            background-color: ${p => p.theme.white};
        }
    }
    &.enabled {
        border-color: ${p => p.color};
        .thumb {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        }
        .check {
            background-color: ${p => p.color};
        }
    }
    &.unclickable {
        pointer-events: none;
        opacity: 0.4;
    }

    ${p => p.size === "default" && defaultStyles}
    ${p => p.size === "sm" && smallStyles}
    ${p => p.size === "xsm" && xSmallStyles}
`;
