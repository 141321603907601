import type { Description } from "@glide/type-schema";
import type { LegacyPropertyDescription, PropertyDescription } from "@glide/app-description";
import { hasOwnProperty } from "@glideapps/ts-necessities";

import type { InteractiveComponentConfiguratorContext } from "./lib";

export type PropertySource =
    | {
          readonly name: string;
          readonly defaultValue?: PropertyDescription;
      }
    | {
          readonly id: string;
          // NOTE: This is almost certainly not what you want - use the `name`
          // case above.
          readonly get: (desc: Description) => LegacyPropertyDescription | undefined;
          readonly update: (
              desc: Description,
              value: PropertyDescription | undefined,
              ccc: InteractiveComponentConfiguratorContext | undefined
          ) => Partial<Description>;
      };

export function arePropertySourcesEqual(s1: PropertySource, s2: PropertySource): boolean {
    if (hasOwnProperty(s1, "name")) {
        if (hasOwnProperty(s2, "name")) {
            return s1.name === s2.name;
        } else {
            return false;
        }
    } else {
        if (hasOwnProperty(s2, "name")) {
            return false;
        } else {
            return s1.id === s2.id;
        }
    }
}
