import classNames from "classnames";
import * as React from "react";
import { SectionTabBarWrapper } from "./section-tab-bar-style";

interface Props<T> {
    readonly items: readonly T[];
    readonly value: T;
    readonly onChange: (newValue: T) => void;
    readonly sizeMode?: "natural" | "stretch" | "wide";
    itemRenderer?: (item: T) => React.ReactNode;
    readonly className?: string;
}

export default class SectionTabBar<T extends {}> extends React.PureComponent<Props<T>> {
    public render(): React.ReactNode {
        const { items, value, onChange, itemRenderer, sizeMode = "stretch", className } = this.props;
        return (
            <SectionTabBarWrapper className={classNames(sizeMode, className)}>
                {items.map((item, index) => (
                    <div
                        data-testid="stbItem"
                        className={classNames(
                            value === item ? "selected stb-item" : "stb-item",
                            `section-bar-${item.toString().toLowerCase()}`,
                            className
                        )}
                        key={index}
                        onClick={() => onChange(item)}>
                        {itemRenderer ? itemRenderer(item) : item.toString()}
                    </div>
                ))}
            </SectionTabBarWrapper>
        );
    }
}
