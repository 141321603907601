import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import { NetworkOfflineError } from "@glide/common-core/dist/js/components/upload-handlers";
import type { TableName, TableColumn, TableGlideType } from "@glide/type-schema";
import type { QueryTableVersions } from "@glide/computation-model-types";
import type {
    ColumnValues,
    DuplicatePluginSecretBody,
    SetPluginSecretBody,
    TriggerAutomationBody,
} from "@glide/common-core/dist/js/firebase-function-types";
import {
    type CheckReverseFreeTrialEligibilityBody,
    type CheckQueryTableVersionsRequestBody,
    type CheckQueryTableVersionsResponseBody,
    type CompletePluginRequestBody,
    type CompletePluginResponseBody,
    type ContinuePreviewQueryRequestBody,
    type CreateNativeTableForImportResponse,
    type DuplicateAppResponseBody,
    type EndReverseFreeTrialBody,
    type EnqueueActionBatchRequestBody,
    type EnqueueActionBatchResponseBody,
    type EnqueueSingleActionRequest,
    type FinishOnboardingBody,
    type GenerateAppFromDescriptionResponse,
    type GeneratePublishedAppDataFromSheetBody,
    type GeneratePublishedAppDataFromSheetResult,
    type GetLongHTTPFunctionStatusRequestBody,
    type GetSimpleValueBody,
    type GetUnsplashImagesBody,
    type GetV4StripeCheckoutSessionBody,
    type GetV4StripeCustomerPortalBody,
    type IntegrateWithAirtableRequestBody,
    type LinkTablesBody,
    type LinkTablesResponseBody,
    type MaybeCreateReverseFreeTrialBody,
    type ModifySyntheticColumnsBody,
    type MoveTableInSchemaBody,
    type NewAppSurveyData,
    type PreviewQueryRequestBody,
    type QueryContinuation,
    type QueryResponseEntry,
    type QueryResponseSuccessEntry,
    type ReloadPublishedAppDataFromSheetBody,
    type ReloadPublishedAppDataFromSheetResponse,
    type ReportBillableBody,
    type RequestTrialExtensionBody,
    type SaveQueryRequestBody,
    type SaveQuerySuccessResponseBody,
    type SetOrgOnboardingBody,
    type SetOrUpdateUserBody,
    type SQLQueryBase,
    type StoreAppDevicePreferenceBody,
    type SubscribeToAddonsBody,
    type SubscribeToPlanBody,
    type TransferAppToOrganizationBody,
    type TriggerRemovePluginRequestBody,
    type TriggerRemovePluginResponseBody,
    type UnsplashPingDownloadBody,
    type UserDataRemovals,
    type UserDataUpdates,
    type ValidatePluginRequestBody,
    type ValidatePluginResponseBody,
    type ValidatePluginResult,
    createNativeTableForImportResponse,
    enqueueActionBatchResponseBodyCodec,
    executeQueryResponseBodyCodec,
    linkTablesResponseBody,
    previewQueryResponseBodyCodec,
    saveQueryResponseBodyCodec,
    TransferAppToOrganizationResponse,
} from "@glide/common-core/dist/js/firebase-function-types";
import type { AeadKeyedData } from "@glide/common-core/dist/js/integration-types";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import type { PagePreviewDevice } from "@glide/common-core/dist/js/render/form-factor";
import type { AppKind } from "@glide/location-common";
import { panic, definedMap, exceptionToString, hasOwnProperty, sleep } from "@glideapps/ts-necessities";
import { checkString, getResponseErrorMessage, isResponseOK, logError, logInfo } from "@glide/support";
import { isLeft } from "fp-ts/lib/Either";
import { v4 as uuid } from "uuid";
import type { ErrorOr } from "@glide/error-info";
import { ErrorInfo } from "@glide/error-info";

export async function storeDevicePreference(
    appID: string,
    device: PagePreviewDevice,
    appFacilities: ActionAppFacilities
): Promise<void> {
    const body: StoreAppDevicePreferenceBody = {
        appID,
        device,
    };

    await appFacilities.callAuthCloudFunction("storeAppDevicePreference", body);

    return;
}

export async function saveUserUpdates(
    updates: UserDataUpdates,
    removals: Partial<UserDataRemovals> = {},
    emailAppendAuthorization: AeadKeyedData | undefined,
    appFacilities: ActionAppFacilities
): Promise<boolean> {
    logInfo("updating user", updates);
    const body: SetOrUpdateUserBody = {
        updates,
        removals,
        emailAppendAuthorization,
    };
    const response = await appFacilities.callAuthCloudFunction("setOrUpdateUser", body);
    // FIXME: We should handle this case in a much more graceful fashion.
    if (!isResponseOK(response)) {
        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        void response?.text();
        return panic("Could not save user updates");
    }
    const { created } = await response.json();
    return created;
}

export async function generatePublishedAppDataFromSheet(
    sheetsFileID: string,
    organizationID: string | undefined,
    appKind: AppKind,
    importIntoNativeTables: boolean,
    newAppSurveyData: NewAppSurveyData,
    appFacilities: ActionAppFacilities
): Promise<GeneratePublishedAppDataFromSheetResult> {
    const body: GeneratePublishedAppDataFromSheetBody = {
        sheetsFileID,
        organizationID,
        appKind,
        importIntoNativeTables,
        ...newAppSurveyData,
    };
    const response = await appFacilities.callAuthCloudFunction("generatePublishedAppDataFromSheet", body);
    if (response === undefined) return "Network error";
    const json = await response.json();
    if (!response.ok) {
        logError("Could not generate published app data", response);
        if (typeof json.error === "string") {
            return json.error;
        }
        return "An error occurred.";
    }

    return json;
}

export async function callModifySyntheticColumns(
    body: ModifySyntheticColumnsBody,
    appFacilities: ActionAppFacilities
): Promise<boolean> {
    const response = await appFacilities.callAuthCloudFunction("modifySyntheticColumns", body);
    void response?.text();
    return isResponseOK(response);
}

export async function setSyntheticColumn(
    appID: string,
    hostTableName: TableName,
    column: TableColumn,
    isRowID: boolean,
    confirmDestructive: boolean,
    index: number | undefined,
    appFacilities: ActionAppFacilities
): Promise<boolean> {
    const body: ModifySyntheticColumnsBody = { appID, set: { [hostTableName.name]: [column] }, confirmDestructive };
    if (index !== undefined) {
        body.move = { [hostTableName.name]: [[column.name, index]] };
    }
    if (isRowID) {
        body.rowIDColumns = [[hostTableName.name, column.name]];
    }
    return await callModifySyntheticColumns(body, appFacilities);
}

export class TableDeletedError extends Error {
    constructor(public readonly tableName: string) {
        super(`Table ${tableName} was deleted`);
    }
}

export class TableUnauthorizedError extends Error {
    constructor(public readonly tableName: string) {
        super(`Not authorized to import to table ${tableName}`);
    }
}

export async function createNativeTableForImport(
    name: string,
    columns: readonly TableColumn[],
    owner: string | undefined,
    appID: string | undefined,
    appFacilities: ActionAppFacilities
): Promise<CreateNativeTableForImportResponse> {
    const createResponse = await appFacilities.callAuthCloudFunction("createNativeTableForImport", {
        name,
        columns,
        onBehalfOf: owner,
        appID,
    });

    if (!isResponseOK(createResponse)) {
        if (createResponse === undefined) throw new NetworkOfflineError();
        const responseBody = await createResponse.text();

        if (createResponse.status === 403) throw new TableUnauthorizedError(name);
        if (createResponse.status === 404) throw new TableDeletedError(name);
        throw new Error(responseBody);
    }

    const blob = await createResponse.json();

    if (!createNativeTableForImportResponse.is(blob)) throw new Error("Unexpected response from server");

    return blob;
}

export async function setRowIDColumn(
    appID: string,
    hostTableName: TableName,
    rowIDColumnName: string,
    appFacilities: ActionAppFacilities
): Promise<boolean> {
    return await callModifySyntheticColumns(
        { appID, rowIDColumns: [[hostTableName.name, rowIDColumnName]] },
        appFacilities
    );
}

export async function removeSyntheticColumn(
    appID: string,
    tableName: TableName,
    column: string,
    allowDeletingSheetColumns: boolean,
    appFacilities: ActionAppFacilities
): Promise<boolean> {
    return await callModifySyntheticColumns(
        {
            appID,
            remove: {
                [tableName.name]: [column],
            },
            confirmDestructive: allowDeletingSheetColumns,
        },
        appFacilities
    );
}

export async function moveSyntheticColumn(
    appID: string,
    tableName: TableName,
    column: string,
    index: number,
    appFacilities: ActionAppFacilities
): Promise<boolean> {
    return await callModifySyntheticColumns(
        {
            appID,
            move: {
                [tableName.name]: [[column, index]],
            },
        },
        appFacilities
    );
}

export async function setColumnProtected(
    appID: string,
    tableName: TableName,
    column: string,
    setProtected: boolean,
    appFacilities: ActionAppFacilities
): Promise<boolean> {
    return await callModifySyntheticColumns(
        {
            appID,
            setProtected: {
                [tableName.name]: [[column, setProtected]],
            },
        },
        appFacilities
    );
}

export async function moveTableInSchema(
    appID: string,
    tableName: TableName,
    afterTableName: TableName | undefined,
    appFacilities: ActionAppFacilities
): Promise<boolean> {
    const body: MoveTableInSchemaBody = { appID, tableName, afterTableName };
    const response = await appFacilities.callAuthCloudFunction("moveTableInSchema", body);
    // We have to drain out the body, otherwise we'll just leave the connection
    // around forever.
    void response?.text();
    return isResponseOK(response);
}

export interface UnsplashResult {
    id: string;
    urls: {
        raw: string;
        thumb: string;
        regular: string;
    };
    user: {
        name: string;
        links: {
            html: string;
        };
    };
}
interface UnsplashResults {
    error: string | undefined;
    results: UnsplashResult[] | undefined;
}

export async function getUnsplashImages(
    appID: string,
    query: string,
    appFacilities: ActionAppFacilities
): Promise<UnsplashResults> {
    const body: GetUnsplashImagesBody = { appID, query };
    const response = await appFacilities.callAuthCloudFunction("getUnsplashImages", body);

    // Handle function error
    if (!isResponseOK(response)) {
        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        void response?.text();
        return { results: undefined, error: "Error - Unable to get unsplash images" };
    }
    const result = await response.json();
    if (hasOwnProperty(result, "errors")) {
        // If API request to unsplash fails, the response will have an errors prop
        return { results: undefined, error: "Error - Please try again!" };
    }
    if (!hasOwnProperty(result, "results")) {
        // Handle if results does not exist on payload
        return { results: undefined, error: "Error - Please try again!" };
    }
    return { results: result.results as any, error: undefined };
}

export async function pingUnsplashDownload(
    appID: string,
    imageId: string,
    appFacilities: ActionAppFacilities
): Promise<void> {
    const body: UnsplashPingDownloadBody = { appID, imageId };

    // We have to drain out the body, otherwise we'll just leave the connection
    // around forever.
    await appFacilities.callAuthCloudFunction("pingUnsplashDownload", body).then(r => r?.text());
}

// FIXME: These functions really shouldn't just be raw Response object because the consumers sometimes fail to drain them.
// They should be Promise<WhateverTheBackendReturnsType>

export async function subscribeToAddons(
    requestBody: SubscribeToAddonsBody,
    appFacilities: ActionAppFacilities
): Promise<Response> {
    return (
        (await appFacilities.callAuthCloudFunction("subscribeToAddons", requestBody)) ??
        new Response(null, {
            status: 400,
        })
    );
}

export async function subscribeToPlan(
    requestBody: SubscribeToPlanBody,
    appFacilities: ActionAppFacilities
): Promise<Response> {
    return (
        (await appFacilities.callAuthCloudFunction("subscribeToPlan", requestBody)) ??
        new Response(null, {
            status: 400,
        })
    );
}

export async function transferAppToOrganization(
    appID: string,
    organizationID: string,
    confirmed: boolean,
    asPublicApp: boolean,
    appFacilities: ActionAppFacilities
): Promise<TransferAppToOrganizationResponse | undefined> {
    const body: TransferAppToOrganizationBody = { appID, organizationID, confirmed, asPublicApp };
    const response = await appFacilities.callAuthCloudFunction("transferAppToOrganization", body);
    if (response === undefined) return TransferAppToOrganizationResponse.ErrorOther;
    if (response.ok) {
        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        void response.text();
        return undefined;
    }

    const result = await response.json();
    if (hasOwnProperty(result, "response") && typeof result.response === "string") {
        return result.response as TransferAppToOrganizationResponse;
    }
    return TransferAppToOrganizationResponse.ErrorOther;
}

export async function reloadPublishedAppDataFromSheet(
    appID: string,
    checkCompatibilityIssues: boolean,
    didReconnectSpreadsheet: boolean,
    appFacilities: ActionAppFacilities
): Promise<ReloadPublishedAppDataFromSheetResponse | undefined> {
    const requestBody: ReloadPublishedAppDataFromSheetBody = {
        appID,
        checkCompatibilityIssues,
        didReconnectSpreadsheet,
    };
    return await callLongHTTPFunction<ReloadPublishedAppDataFromSheetResponse>(
        await appFacilities.callAuthCloudFunction("reloadPublishedAppDataFromSheet", requestBody),
        appFacilities
    );
}

export async function setOrgOnboarding(
    requestBody: SetOrgOnboardingBody,
    appFacilities: ActionAppFacilities
): Promise<Response | undefined> {
    return await appFacilities.callAuthCloudFunction("setOrgOnboarding", requestBody);
}

export async function finishOnboarding(
    requestBody: FinishOnboardingBody,
    appFacilities: ActionAppFacilities
): Promise<Response | undefined> {
    return await appFacilities.callAuthCloudFunction("finishOnboarding", requestBody);
}

export async function checkReverseFreeTrialEligibility(
    requestBody: CheckReverseFreeTrialEligibilityBody,
    appFacilities: ActionAppFacilities
): Promise<Response | undefined> {
    return await appFacilities.callAuthCloudFunction("checkReverseFreeTrialEligibility", requestBody);
}

export async function maybeCreateReverseFreeTrial(
    requestBody: MaybeCreateReverseFreeTrialBody,
    appFacilities: ActionAppFacilities
): Promise<Response | undefined> {
    return await appFacilities.callAuthCloudFunction("maybeCreateReverseFreeTrial", requestBody);
}

export async function endReverseFreeTrial(
    requestBody: EndReverseFreeTrialBody,
    appFacilities: ActionAppFacilities
): Promise<Response | undefined> {
    return await appFacilities.callAuthCloudFunction("endReverseFreeTrial", requestBody);
}

export async function requestTrialExtension(
    requestBody: RequestTrialExtensionBody,
    appFacilities: ActionAppFacilities
): Promise<Response | undefined> {
    return await appFacilities.callAuthCloudFunction("requestTrialExtension", requestBody);
}

export async function getV4StripeCustomerPortal(
    requestBody: GetV4StripeCustomerPortalBody,
    appFacilities: ActionAppFacilities
): Promise<Response | undefined> {
    return await appFacilities.callAuthCloudFunction("getV4StripeCustomerPortal", requestBody);
}

export async function getV4StripeCheckoutSession(
    requestBody: GetV4StripeCheckoutSessionBody,
    appFacilities: ActionAppFacilities
): Promise<Response | undefined> {
    return await appFacilities.callAuthCloudFunction("getV4StripeCheckoutSession", requestBody);
}

export async function integrateWithAirtable(
    body: IntegrateWithAirtableRequestBody,
    appFacilities: ActionAppFacilities
): Promise<boolean> {
    const response = await appFacilities.callAuthCloudFunction("integrateWithAirtable", body);

    // We have to drain out the body, otherwise we'll just leave the connection
    // around forever.
    void response?.text();
    return isResponseOK(response);
}

export async function linkTablesToApp(
    body: LinkTablesBody,
    appFacilities: ActionAppFacilities
): Promise<LinkTablesResponseBody & { success: boolean }> {
    if (body.target === "native" && body.nativeTableIDs.length === 0) return { sourceMetadatas: [], success: true };
    try {
        const response = await appFacilities.callAuthCloudFunction("linkTablesToApp", body);
        const result = await response?.json();
        const decoded = linkTablesResponseBody.decode(result);
        if (isLeft(decoded)) {
            logError("Could not decode linkTablesToApp response", result);
            return { sourceMetadatas: [], success: false };
        }

        return { ...decoded.right, success: isResponseOK(response) };
    } catch (e: unknown) {
        logError("Error calling linkTablesToApp", exceptionToString(e));
        return { sourceMetadatas: [], success: false };
    }
}

type LongHTTPFunctionResponse =
    | GenerateAppFromDescriptionResponse
    | ReloadPublishedAppDataFromSheetResponse
    | DuplicateAppResponseBody;

export async function callLongHTTPFunction<T extends LongHTTPFunctionResponse>(
    callResponse: Response | undefined,
    appFacilities: ActionAppFacilities
): Promise<T | undefined> {
    if (!isResponseOK(callResponse)) {
        // drain
        await callResponse?.text();
        return undefined;
    }

    const responseBody = await callResponse.json();
    if (!hasOwnProperty(responseBody, "jobID") || typeof responseBody.jobID !== "string") {
        if (getLocationSettings().isDevelopment) {
            return responseBody;
        }
        return undefined;
    }

    const { jobID } = responseBody;

    await sleep(500);

    for (;;) {
        const pollBody: GetLongHTTPFunctionStatusRequestBody = { jobID };

        const pollResponse = await appFacilities.callAuthIfAvailableCloudFunction(
            "getLongHTTPFunctionStatus",
            pollBody,
            {}
        );
        if (isResponseOK(pollResponse)) {
            const { response } = await pollResponse.json();

            // `false` means the job failed due to some internal error, which
            // means our long HTTP call failed.
            if (response === false) {
                return undefined;
            } else if (response !== undefined) {
                return response;
            }

            // `undefined` falls through - it means the job hasn't finished
            // yet.
        } else {
            // drain
            await pollResponse?.text();
        }

        await sleep(2000);
    }
}

export async function validatePlugin(
    id: string,
    pluginID: string,
    pluginConfigID: string,
    pluginParams: ValidatePluginRequestBody["pluginParams"],
    appFacilities: ActionAppFacilities
): Promise<ValidatePluginResult> {
    const body: ValidatePluginRequestBody = {
        appID: id,
        pluginConfigID,
        pluginID,
        pluginParams,
    };

    const r = await appFacilities.callAuthCloudFunction("validatePlugin", body);
    if (!isResponseOK(r)) {
        return {
            ok: false,
            errors: [],
            warnings: [],
            externalConfigurationStep: undefined,
        };
    }
    const serialized: ValidatePluginResponseBody = await r.json();
    const result: ValidatePluginResult = {
        ...serialized,
        externalConfigurationStep: definedMap(serialized.externalConfigurationStep, s => ({
            ...s,
            expiresAt: definedMap(s.expiresAt, e => new Date(e)),
        })),
    };
    return result;
}

export async function completePlugin(
    id: string,
    pluginID: string,
    pluginConfigID: string,
    pluginParams: CompletePluginRequestBody["pluginParams"],
    appFacilities: ActionAppFacilities
): Promise<CompletePluginResponseBody> {
    const body: CompletePluginRequestBody = {
        appID: id,
        pluginConfigID,
        pluginID,
        pluginParams,
    };

    const r = await appFacilities.callAuthCloudFunction("completePlugin", body);
    if (!isResponseOK(r)) {
        return { updates: {} };
    }
    return await r.json();
}

export async function previewQueryDataSource(
    savedIdentity: PreviewQueryRequestBody["savedIdentity"],
    orgID: string | undefined,
    queryName: string,
    queryID: string,
    queryBase: SQLQueryBase,
    limit: number,
    appFacilities: ActionAppFacilities
): Promise<QueryResponseSuccessEntry | string> {
    const body: PreviewQueryRequestBody = {
        savedIdentity,
        onBehalfOf: orgID,
        queryName,
        queryID,
        queryBase,
        limit,
    };

    const resp = await appFacilities.callAuthCloudFunction("previewQueryDataSource", body);
    if (!isResponseOK(resp)) {
        const whyNot = await resp?.text();
        logError("Error from previewQueryDataSource", whyNot);
        return `Something went wrong: ${resp?.statusText} ${whyNot ?? "Unknown error previewing query"}`;
    }

    const json = await resp.json();
    if (!previewQueryResponseBodyCodec.is(json)) {
        return "Unexpected response from the server";
    }

    if (json.response.kind === "error") {
        return json.response.message;
    }

    return json.response;
}

export async function continuePreviewQueryDataSource(
    appFacilities: ActionAppFacilities,
    savedIdentity: ContinuePreviewQueryRequestBody["savedIdentity"],
    orgID: string | undefined,
    queryID: string,
    continuation: QueryContinuation
): Promise<readonly QueryResponseEntry[] | string> {
    const body: ContinuePreviewQueryRequestBody = {
        savedIdentity,
        onBehalfOf: orgID,
        requests: [
            {
                queryID,
                continuation,
            },
        ],
    };
    const resp = await appFacilities.callAuthIfAvailableCloudFunction("continuePreviewQueryDataSource", body, {}, true);
    if (resp === undefined) {
        logError("Continuation didn't work.");
        return "Could not contact Glide Servers to continue query";
    }
    if (!resp.ok) {
        const whyNot = await resp.text();
        logError(`Continuation didn't work because ${resp.statusText}, ${whyNot}`);
        return `Continuing query resulted in error: ${whyNot}`;
    }
    const nextQueryBody = await resp.json();
    if (!executeQueryResponseBodyCodec.is(nextQueryBody)) {
        logError("Continuation attempt body's bad", nextQueryBody);
        return "Unexpected response from the server when continuing query";
    }
    return nextQueryBody.responses;
}

export async function saveQuery(
    savedIdentity: SaveQueryRequestBody["savedIdentity"],
    appID: string,
    orgID: string,
    queryName: string,
    queryBase: SQLQueryBase,
    persistTable: TableGlideType,
    appFacilities: ActionAppFacilities
): Promise<SaveQuerySuccessResponseBody | string> {
    const body: SaveQueryRequestBody = {
        onBehalfOf: orgID,
        queryName,
        queryBase,
        savedIdentity,
        appChanges: {
            add: [appID],
        },
        persistTable,
    };

    const resp = await appFacilities.callAuthCloudFunction("saveQuery", body);
    if (resp === undefined) {
        logError("Could not save query due to network issue");
        return "Could not contact Glide servers to save the query.";
    }

    const respBody = await resp.json();

    if (!isResponseOK(resp)) {
        logError("Saving query returned status", resp.status);

        const message = await getResponseErrorMessage(resp);
        return message ?? "Unexpected response from the server";
    }

    if (!saveQueryResponseBodyCodec.is(respBody)) {
        logError("Response body was unexpected", respBody);
        return "Unexpected response from the server";
    }

    if (hasOwnProperty(respBody, "errors")) {
        const errorMessage = respBody.errors[0]?.message ?? "Unexpected error";
        return errorMessage;
    }

    return respBody;
}

export async function checkQueryTableVersions(
    appID: string,
    queryTableVersions: Record<string, QueryTableVersions>,
    appFacilities: ActionAppFacilities
): Promise<CheckQueryTableVersionsResponseBody> {
    const body: CheckQueryTableVersionsRequestBody = { appID, queryTableVersions };
    const r = await appFacilities.callAuthIfAvailableCloudFunction("checkQueryTableVersions", body, {});
    if (!isResponseOK(r)) return { queryResults: {} };
    return await r.json();
}

export async function triggerRemovePlugin(
    appID: string,
    pluginID: string,
    pluginConfigID: string,
    pluginParams: CompletePluginRequestBody["pluginParams"],
    appFacilities: ActionAppFacilities
): Promise<TriggerRemovePluginResponseBody> {
    const body: TriggerRemovePluginRequestBody = {
        appID,
        pluginConfigID,
        pluginID,
        pluginParams,
    };

    const r = await appFacilities.callAuthCloudFunction("triggerRemovePlugin", body);
    if (r === undefined) return { ok: false, errors: [] };
    return r.json();
}

// Returns `undefined` if there was an error
export async function enqueueDataActionBatch(
    appID: string,
    actions: readonly EnqueueSingleActionRequest[],
    appFacilities: ActionAppFacilities
): Promise<EnqueueActionBatchResponseBody | undefined> {
    try {
        const body: EnqueueActionBatchRequestBody = { appID, actions };

        const response = await appFacilities.callAuthIfAvailableCloudFunction("enqueueDataActionBatch", body, {});
        const json = await response?.json();
        if (!enqueueActionBatchResponseBodyCodec.is(json)) {
            logError("Unexpected response from enqueueDataActionBatch", json);
            return undefined;
        }

        return json;
    } catch (e: unknown) {
        logError("Error enqueuing batch", exceptionToString(e));
        return undefined;
    }
}

export async function reportBillable(body: ReportBillableBody, appFacilities: ActionAppFacilities): Promise<void> {
    const r = await appFacilities.callAuthIfAvailableCloudFunction("reportBillable", body, {});
    void r?.text(); // drain body.
}

export async function getSimpleValue(
    appID: string,
    value: string,
    appFacilities: ActionAppFacilities
): Promise<string | undefined> {
    const body: GetSimpleValueBody = { appID, value };
    const response = await appFacilities.callAuthIfAvailableCloudFunction("getSimpleValue", body, {});
    if (!isResponseOK(response)) {
        return undefined;
    }
    const json = await response.json();
    if (typeof json?.result === "string") {
        return json.result;
    }
    return undefined;
}

export async function setPluginSecret(
    appID: string,
    secretID: string,
    secretValue: string,
    appFacilities: ActionAppFacilities
): Promise<ErrorOr<string | undefined>> {
    async function call() {
        // We mutate the `secretID`.
        const body: SetPluginSecretBody = { appID, secretID, secretValue };
        return await appFacilities.callAuthCloudFunction("setPluginSecret", body, {}, true);
    }

    let response = await call();
    if (isResponseOK(response)) return;

    // If we get a 409 conflict, we need to generate a new secret ID
    if (response?.status === 409) {
        secretID = uuid();
        response = await call();
        if (response?.ok === true) {
            return secretID;
        }
    }

    return new ErrorInfo(response?.status ?? 500, response?.statusText ?? "Unknown error");
}

/** Returns the new secret ID */
export async function duplicatePluginSecret(
    appID: string,
    originalSecretID: string,
    appFacilities: ActionAppFacilities
): Promise<ErrorOr<string>> {
    const newSecretID = uuid();
    const body: DuplicatePluginSecretBody = { appID, originalSecretID, newSecretID };

    const response = await appFacilities.callAuthCloudFunction("duplicatePluginSecret", body, {}, true);
    if (isResponseOK(response)) return newSecretID;

    return new ErrorInfo(response?.status ?? 500, response?.statusText ?? "Unknown error");
}

/** Returns the run ID. */
export async function triggerAutomation(
    appID: string,
    actionID: string,
    triggerData: ColumnValues,
    appFacilities: ActionAppFacilities
): Promise<ErrorOr<string>> {
    const body: TriggerAutomationBody = { appID, actionID, triggerData };

    const response = await appFacilities.callAuthCloudFunction("triggerAutomation", body);
    if (!isResponseOK(response)) {
        const message = await getResponseErrorMessage(response);
        return new ErrorInfo(response?.status ?? 500, message ?? response?.statusText ?? "Unknown error");
    }

    const json = await response.json();
    return checkString(json.runID);
}
