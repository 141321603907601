import { DefaultMap } from "@glideapps/ts-necessities";
import { getBrowserLanguage } from ".";

let defaultFormat: Intl.NumberFormat | undefined;

export function formatNumber(x: number): string {
    if (defaultFormat === undefined) {
        defaultFormat = new Intl.NumberFormat(getBrowserLanguage(), { useGrouping: false, maximumFractionDigits: 10 });
    }
    return defaultFormat.format(x);
}

// currency -> decimal/grouping cache
const cache = new DefaultMap<string | undefined, Intl.NumberFormat[]>(() => []);

// We store without grouping on even indexes, with grouping on odd.
function getCacheIndex(decimalsAfterPoint: number, useGrouping: boolean): number {
    return decimalsAfterPoint * 2 + (useGrouping ? 1 : 0);
}

function makeFixedNumberFormatter(
    decimalsAfterPoint: number,
    useGrouping: boolean,
    currency: string | undefined
): Intl.NumberFormat {
    // ##cacheFormatting:
    // This cache can make number formatting 50x faster.
    const cacheIndex = getCacheIndex(decimalsAfterPoint, useGrouping);
    const existing = cache.get(currency)[cacheIndex];
    if (existing !== undefined) {
        return existing;
    }

    const options: Intl.NumberFormatOptions = {
        useGrouping,
        minimumFractionDigits: decimalsAfterPoint,
        maximumFractionDigits: decimalsAfterPoint,
    };
    if (currency !== undefined && currency !== "USD") {
        options.style = "currency";
        options.currency = currency;
    }
    const format = new Intl.NumberFormat(getBrowserLanguage(), options);
    cache.get(currency)[cacheIndex] = format;
    return format;
}

function addUSD(formattedNumber: string): string {
    if (formattedNumber.startsWith("-")) {
        return "-$" + formattedNumber.substring(1);
    } else {
        return "$" + formattedNumber;
    }
}

export function formatNumberFixed(
    x: number,
    decimalsAfterPoint: number,
    useGrouping: boolean,
    currency: string | undefined
): string {
    const format = makeFixedNumberFormatter(decimalsAfterPoint, useGrouping, currency);
    let result = format.format(x);
    // FIXME: Don't do this anymore once we have proper currency formatting.
    // https://github.com/quicktype/glide/issues/5450
    if (currency === "USD") {
        result = addUSD(result);
    }
    return result;
}

// for (const x of [
//     123,
//     1.23,
//     0.001,
//     1e26,
//     132323.23423,
//     123456789123456789,
//     -342.1324,
//     1 / 3,
//     -Math.PI * 1e30,
//     Math.pow(Math.pow(2, 1 / 2), 2),
//     Math.sin((Math.PI / 2) * 100),
//     1,
//     0,
//     1.1,
//     0.9,
//     1.234,
//     114.999999999999
// ]) {
// }
