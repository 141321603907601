import {
    type Location,
    type LocationSettings,
    type LocationSettingsWithURL,
    getLocationSettingsUnsafe,
    type AppKind,
} from "@glide/location-common";
import { logError } from "@glide/support";

let explicitLocation: Location | undefined;

export function setExplicitLocation(loc: Location | undefined) {
    explicitLocation = loc;
}

export function getLocation(): Location {
    if (explicitLocation !== undefined) return explicitLocation;

    const glideEnv: unknown = (window as any).glideEnv;
    if (glideEnv === "prod") {
        return "production";
    }
    if (glideEnv === "staging") return "staging";
    if (glideEnv === "sandbox") return "sandbox";
    if (glideEnv === "local") return "local";
    if (glideEnv === "dev") return "dev";
    if (typeof glideEnv === "string" && glideEnv.startsWith("preview-")) return glideEnv;
    // glideEnv should never be undefined, but it happens in tests.
    // For now let's continue the historic behaviour of using dev as a fallback.
    // TODO: This should really panic, but who knows where we depend on it?
    if (typeof jest === "undefined") {
        logError(`Unknown glideEnv value ${glideEnv}, assuming 'dev'`);
    }
    return "dev";
}

export function getLocationSettings(): LocationSettings;
export function getLocationSettings(appKind: AppKind): LocationSettingsWithURL;
export function getLocationSettings(appKind?: AppKind): LocationSettings {
    const location = getLocation();
    let settings = getLocationSettingsUnsafe(location, appKind);

    let hostname: string = "localhost";
    if (typeof window !== "undefined") {
        if ((window as any).overrideCloudFunctionLocation !== undefined) {
            settings = {
                ...settings,
                containerURLPrefix: (window as any).overrideCloudFunctionLocation,
            };
        }
        hostname = window.location.hostname;
    }

    if (location === "dev" && hostname !== "localhost") {
        // If we're on a development platform but aren't exactly on "localhost",
        // we need to redirect through staging proper to make Sign in with Google
        // function correctly.
        const stagingSettings = getLocationSettingsUnsafe("staging", appKind);
        settings = {
            ...settings,
            oAuthCallbackURL: stagingSettings.oAuthCallbackURL,
        };
    }

    return settings;
}
