import { appIsInstalled, browserOnPhone, type RuntimeTheme } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import * as React from "react";

import type { BaseProps } from "./custom-sign-in-props";
import {
    Button,
    ButtonWrapper,
    ContentContainer,
    ErrorContainer,
    Input,
    Subtitle,
    Title,
} from "./custom-sign-in-style";
import { signInNormalTextColors } from "./custom-sign-in-styles";
import type { WireAppTheme } from "@glide/theme";

interface Props extends BaseProps {
    readonly theme: RuntimeTheme | WireAppTheme;
}
const PasswordInputImpl: React.FC<React.PropsWithChildren<Props>> = p => {
    const {
        greeting,
        description,
        colorTheme,
        isPro,
        passcode,
        canContinue,
        error,
        appFeatures,
        onPressedContinue,
        onPasscodeChange,
    } = p;

    const appKind = getAppKindFromFeatures(appFeatures);

    const onChange = React.useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            onPasscodeChange(ev.target.value);
        },
        [onPasscodeChange]
    );

    const onFormSubmit = React.useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();
            if (onPressedContinue !== undefined) onPressedContinue();
        },
        [onPressedContinue]
    );

    return (
        <>
            <ContentContainer>
                <div>
                    <Title data-test="app-sign-in-title" css={signInNormalTextColors}>
                        {greeting}
                    </Title>
                    <Subtitle id="sign-in-description" data-test="app-sign-in-subtitle">
                        {description}
                    </Subtitle>
                    <form action="" onSubmit={onFormSubmit}>
                        <Input
                            data-test="app-password-input"
                            className={error !== undefined ? "error" : ""}
                            type="password"
                            inputMode="text"
                            placeholder={getLocalizedString("enterPassword", appKind)}
                            value={passcode}
                            onChange={onChange}
                            colorTheme={colorTheme}
                            autoFocus={!browserOnPhone || appIsInstalled()}
                        />
                    </form>
                    {error !== undefined && <ErrorContainer>{error}</ErrorContainer>}
                </div>
            </ContentContainer>{" "}
            <ButtonWrapper colorTheme={colorTheme} showFooter={isPro ?? false}>
                <Button onClick={onPressedContinue} disabled={canContinue === false}>
                    {getLocalizedString("continue", appKind)}
                </Button>
            </ButtonWrapper>
        </>
    );
};

const PasswordInput = PasswordInputImpl;
export default PasswordInput;
