import type { MinimalAppFacilities } from "@glide/common-core/dist/js/components/types";
import { type ActionDescription, ActionKind } from "@glide/app-description";
import type {
    SendActionErrorEmailBody,
    WriteActionLog,
    WriteActionLogBody,
} from "@glide/common-core/dist/js/firebase-function-types";
import { type JSONObject, truncateStringProperties } from "@glide/support";
import { assert, defined } from "@glideapps/ts-necessities";
import { type ActionConditionLog, WireActionResult } from "@glide/wire";

interface ActionLog {
    readonly actionInvocationID: string;
    readonly customActionID: string | undefined;
    readonly startedAt: Date;
    readonly finishedAt: Date;
    readonly desc: ActionDescription | ActionConditionLog;
    readonly data: JSONObject;
    readonly errorMessage?: string;
    readonly result?: JSONObject;
}

export class ActionLogger {
    private readonly logsStack: ActionLog[][] = [];
    private logSendQueue: WriteActionLog[] = [];

    private sendFailureEmails: boolean;

    public constructor(options: { sendFailureEmails: boolean }) {
        this.sendFailureEmails = options.sendFailureEmails;
    }

    public logActionRun(
        actionInvocationID: string,
        customActionID: string | undefined,
        startedAt: Date,
        finishedAt: Date,
        runResult: WireActionResult
    ): void {
        const { desc } = runResult;
        if (desc === undefined) {
            return;
        }
        assert(this.logsStack.length > 0);
        const logs = this.logsStack[this.logsStack.length - 1];
        logs.push({
            actionInvocationID,
            customActionID,
            startedAt,
            finishedAt,
            desc,
            data: runResult.data,
            errorMessage: runResult.isSuccessInApp ? undefined : runResult.message,
        });
    }

    public async invoke(
        appID: string,
        actionToRun: () => Promise<WireActionResult>,
        appFacilities: MinimalAppFacilities
    ): Promise<WireActionResult> {
        let result: WireActionResult;
        let logs: ActionLog[];
        try {
            this.logsStack.push([]);
            result = await actionToRun();
        } catch (e: unknown) {
            result = WireActionResult.fromException(false, e);
        } finally {
            logs = defined(this.logsStack.pop());
        }

        for (const log of logs) {
            if (log !== undefined) {
                const shippableActionLog: WriteActionLog = {
                    actionID: log.actionInvocationID,
                    customActionID: log.customActionID,
                    actionName: log.desc.kind,
                    actionTitle: log.desc.kind !== "conditional" ? log.desc.customTitle : undefined,
                    timestamp: log.startedAt.getTime(),
                    ended: log.finishedAt.getTime(),
                    error: log.errorMessage,
                    actionParameters: truncateStringProperties(log.data),
                    actionResult: truncateStringProperties(log.result),
                };
                this.logSendQueue.push(shippableActionLog);
            }

            if (log !== undefined && this.logSendQueue.length > 1 && log.desc.kind === ActionKind.Compound) {
                const body: WriteActionLogBody = {
                    appID,
                    logs: this.logSendQueue,
                    pageURL: window.location.href,
                };
                const writeLogResp = await appFacilities.callAuthIfAvailableCloudFunction("writeActionLog", body, {});
                // Empty the response so we don't leave a channel open.
                // This should have been a 204, so this isn't supposed to matter.
                // but it can also be 403 if the user does not have access.
                await writeLogResp?.text();

                if (this.sendFailureEmails) {
                    const actionWithError = this.logSendQueue.find(l => l.error !== undefined);
                    if (actionWithError !== undefined && writeLogResp?.status === 204) {
                        const errorBody: SendActionErrorEmailBody = {
                            appID,
                            actionID: log.data.actionID as string,
                            failedActionName: actionWithError.actionName,
                            errorMessage: actionWithError.error ?? "Unknown",
                            pageURL: window.location.href,
                        };
                        void appFacilities.callAuthIfAvailableCloudFunction("sendActionErrorEmail", errorBody, {});
                    }
                }
            }
        }

        return result;
    }
}
