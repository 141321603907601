import { assertNever } from "@glideapps/ts-necessities";
import * as Elements from "@glide/common-core/dist/js/shared-ui/style";
import { styled } from "@glide/common";
import { css } from "styled-components";

export type Position = "right" | "left" | "top" | "bottom" | "bottom-center" | "bottom-right" | "bottom-left";

export interface TooltipRect {
    readonly bottom: number;
    readonly height: number;
    readonly left: number;
    readonly right: number;
    readonly top: number;
    readonly width: number;
    readonly x: number;
    readonly y: number;
}

const topProps = css<{ bounds: TooltipRect }>`
    left: ${p => (p.bounds.left + p.bounds.right) / 2}px;
    top: ${p => p.bounds.top - 10}px;
    transform: translateX(-50%) translateY(-100%);
`;

const bottomProps = css<{ bounds: TooltipRect }>`
    left: ${p => p.bounds.right}px;
    top: ${p => p.bounds.bottom + 10}px;
    transform: translateX(-50%);
`;

const bottomCenterProps = css<{ bounds: TooltipRect }>`
    left: ${p => (p.bounds.left + p.bounds.right) / 2}px;
    top: ${p => p.bounds.bottom + 10}px;
    transform: translateX(-50%);
`;

const bottomLeftProps = css<{ bounds: TooltipRect; width: number }>`
    left: ${p => p.bounds.left + p.bounds.width - p.width}px;
    top: ${p => p.bounds.bottom + 10}px;
`;

const bottomRightProps = css<{ bounds: TooltipRect }>`
    left: ${p => p.bounds.right - p.bounds.width}px;
    top: ${p => p.bounds.bottom + 10}px;
`;

const leftProps = css<{ bounds: TooltipRect }>`
    left: ${p => p.bounds.left - 16}px;
    top: ${p => p.bounds.top + p.bounds.height / 2 - 16}px;
    transform: translateX(-100%);
`;

const rightProps = css<{ bounds: TooltipRect }>`
    left: ${p => p.bounds.right + 16}px;
    top: ${p => p.bounds.top + p.bounds.height / 2}px;
    transform: translateY(-50%);
`;

interface TooltipProps {
    visible: boolean;
    bounds: TooltipRect;
    position: Position;
    width: number;
    longWord: boolean;
    zIndex: number | undefined;
}
export const TooltipStyle = styled(Elements.Label)<TooltipProps>`
    max-width: ${p => p.width}px;
    visibility: ${p => (p.visible ? "visible" : "hidden")};
    background: ${p => p.theme.n800};
    border-radius: 8px;
    font-size: 12px;
    color: ${p => p.theme.bgFront};
    position: absolute;
    display: flex;
    align-items: center;
    gap: 4px;
    z-index: 1000000;
    ${p => {
        switch (p.position) {
            case "top":
                return topProps;
            case "bottom":
                return bottomProps;
            case "bottom-center":
                return bottomCenterProps;
            case "bottom-right":
                return bottomRightProps;
            case "bottom-left":
                return bottomLeftProps;
            case "left":
                return leftProps;
            case "right":
                return rightProps;
            default:
                return assertNever(p.position);
        }
    }}
    padding: 6px 10px;
    white-space: normal;
    opacity: ${p => (p.visible ? 1 : 0)};
    transition-duration: ${p => p.theme.hoverTransition};
    transition-property: visibility, opacity;
    transition-timing-function: ease-in;
    word-break: ${p => (p.longWord ? "break-all" : "normal")};

    box-shadow: ${p => p.theme.shadow3};
`;
