export enum TextComponentStyle {
    large = "large",
    regular = "regular",
    small = "small",
    footnote = "footnote",
    metaText = "metaText",
    headlineXSmall = "headlineXSmall",
    headlineXXSmall = "headlineXXSmall",
    headlineXXXSmall = "headlineXXXSmall",
    headlineSmall = "headlineSmall",
    headlineMedium = "headlineMedium",
    headlineLarge = "headlineLarge",
    headlineXLarge = "headlineXLarge",
}
