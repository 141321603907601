import { css, styled } from "@glide/common-components";

const inputProps = css`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: ${p => p.theme.fgColorDark};
    padding: 0;
    margin: 0;
`;

export const Wrapper = styled.div<{ iOSHack: boolean }>`
    position: relative;

    .shadow-box {
        visibility: hidden;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: anywhere;

        width: 100%;

        ${inputProps}

        ${p => p.iOSHack && "padding: 0 3px;"}
    }

    .placeholder {
        color: ${p => p.theme.fgColorLight};
    }

    .ta-inputbox {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 0px;
        resize: none;
        white-space: pre-wrap;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border: 0;
        background-color: transparent;
        ${inputProps}
    }
`;
