import type { WireAppTheme } from "@glide/theme";
import { AppOrPageIcon, type SignInState } from "@glide/common-components";
import type { NonUserAppFeatures, IconImage } from "@glide/app-description";
import { isUndefinedish } from "@glide/support";
import { Img } from "../img/img";
import type { NotificationTarget } from "@glide/plugins";

interface BrandingProps {
    readonly theme: WireAppTheme;
    readonly iconImage?: IconImage;
    readonly logo?: string;
    readonly appFeatures: NonUserAppFeatures;
    readonly canCustomizeSignIn: boolean;
    readonly appID: string;
}

export const SignInBranding: React.FC<BrandingProps> = p => {
    const { theme, iconImage, logo, appFeatures, canCustomizeSignIn, appID } = p;

    if (canCustomizeSignIn && !isUndefinedish(logo)) {
        return (
            <div tw="flex justify-center">
                <Img src={logo} tw="max-w-[192px] max-h-[64px] gp-sm:(mt-10) mt-8" />
            </div>
        );
    }

    return (
        <div className="icon-container" tw="grid justify-center mt-10 gp-md:(mb-10)">
            <AppOrPageIcon
                theme={theme}
                iconImage={iconImage}
                features={appFeatures}
                hideClassicIndicator={true}
                iconSize={50}
                appID={appID}
            />
        </div>
    );
};

export const SignInBrandingWithIcons: React.FC<
    BrandingProps & { state: SignInState; pinMethod: NotificationTarget["method"] | undefined }
> = p => {
    const { state, pinMethod, theme, iconImage, logo, appFeatures, canCustomizeSignIn, appID } = p;

    let icon: JSX.Element | null = null;

    switch (state) {
        case "insert-pin":
            icon =
                pinMethod === "sms" ? (
                    <svg width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6 12C6 9.79086 7.79086 8 10 8H39C41.2092 8 43 9.79086 43 12V33C43 35.2092 41.2092 37 39 37H31.2518C30.786 37 30.3346 37.1626 29.976 37.4598L25.7794 40.9366C25.0358 41.5526 23.9584 41.5496 23.2186 40.9294L19.0884 37.4672C18.7284 37.1654 18.2735 37 17.8036 37H10C7.79086 37 6 35.2092 6 33V12Z"
                            stroke={theme.primaryAccentColor}
                            strokeWidth="3"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M13.75 22.5C13.75 23.4664 14.5335 24.25 15.5 24.25C16.4665 24.25 17.25 23.4664 17.25 22.5C17.25 21.5336 16.4665 20.75 15.5 20.75C14.5335 20.75 13.75 21.5336 13.75 22.5ZM22.75 22.5C22.75 23.4664 23.5336 24.25 24.5 24.25C25.4664 24.25 26.25 23.4664 26.25 22.5C26.25 21.5336 25.4664 20.75 24.5 20.75C23.5336 20.75 22.75 21.5336 22.75 22.5ZM31.75 22.5C31.75 23.4664 32.5336 24.25 33.5 24.25C34.4664 24.25 35.25 23.4664 35.25 22.5C35.25 21.5336 34.4664 20.75 33.5 20.75C32.5336 20.75 31.75 21.5336 31.75 22.5Z"
                            fill={theme.primaryAccentColor}
                            stroke={theme.primaryAccentColor}
                            strokeWidth="3"
                            strokeLinecap="square"
                        />
                    </svg>
                ) : (
                    <svg width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24.5 33.5L4.5 20.5V40.5C4.5 42.7091 6.29086 44.5 8.5 44.5H40.5C42.7091 44.5 44.5 42.7091 44.5 40.5V20.5L24.5 33.5Z"
                            stroke={theme.primaryAccentColor}
                            strokeWidth="3"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M38.5 18.5V6.5C38.5 5.39543 37.6046 4.5 36.5 4.5H12.5C11.3954 4.5 10.5 5.39543 10.5 6.5V18.5"
                            stroke={theme.primaryAccentColor}
                            strokeWidth="3"
                            strokeLinejoin="round"
                        />
                        <path d="M18.5 12.5H30.5" stroke={theme.primaryAccentColor} strokeWidth="3" />
                        <path d="M18.5 20.5H30.5" stroke={theme.primaryAccentColor} strokeWidth="3" />
                    </svg>
                );
            break;
        case "check-spam":
            icon = (
                <svg width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M24.5 33.5L4.5 20.5V40.5C4.5 42.7091 6.29086 44.5 8.5 44.5H40.5C42.7091 44.5 44.5 42.7091 44.5 40.5V20.5L24.5 33.5Z"
                        stroke={theme.primaryAccentColor}
                        strokeWidth="3"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M38.5 18.5V6.5C38.5 5.39543 37.6046 4.5 36.5 4.5H12.5C11.3954 4.5 10.5 5.39543 10.5 6.5V18.5"
                        stroke={theme.primaryAccentColor}
                        strokeWidth="3"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M24.875 14.1875V17.1875M24.875 19.25V19.2425M25.0625 19.25C25.0625 19.3536 24.9786 19.4375 24.875 19.4375C24.7714 19.4375 24.6875 19.3536 24.6875 19.25C24.6875 19.1464 24.7714 19.0625 24.875 19.0625C24.9786 19.0625 25.0625 19.1464 25.0625 19.25Z"
                        stroke={theme.primaryAccentColor}
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                    <path
                        d="M17.9728 19.7957L23.5857 10.3557C24.1671 9.37785 25.5829 9.37785 26.1643 10.3557L31.7773 19.7957C32.3718 20.7956 31.6513 22.0623 30.4881 22.0623H19.2621C18.0988 22.0623 17.3782 20.7956 17.9728 19.7957Z"
                        stroke={theme.primaryAccentColor}
                        strokeWidth="2.5"
                        strokeLinecap="square"
                    />
                </svg>
            );
            break;
        case "search-email":
            icon = (
                <svg width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M41 41L32.753 32.753M32.753 32.753C35.377 30.129 37 26.504 37 22.5C37 14.4919 30.5082 8 22.5 8C14.4919 8 8 14.4919 8 22.5C8 30.5082 14.4919 37 22.5 37C26.504 37 30.129 35.377 32.753 32.753Z"
                        stroke={theme.primaryAccentColor}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
            break;
        case "contact-it":
            icon = (
                <svg width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 20V19.5C10 12.0442 16.4919 6 24.5 6C32.5082 6 39 12.0442 39 19.5V20M24.5 39.7858V41C24.5 42.1046 25.3954 43 26.5 43H30.5C35.4706 43 39.5 38.9706 39.5 34M9 20H12V33H9C7.34314 33 6 31.6568 6 30V23C6 21.3432 7.34314 20 9 20ZM37 20H40C41.6568 20 43 21.3432 43 23V30C43 31.6568 41.6568 33 40 33H37V20Z"
                        stroke={theme.primaryAccentColor}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
            break;
        default:
            break;
    }
    if (icon !== null) {
        return <div tw="flex justify-center mt-10">{icon}</div>;
    }

    return (
        <SignInBranding
            theme={theme}
            iconImage={iconImage}
            logo={logo}
            appFeatures={appFeatures}
            canCustomizeSignIn={canCustomizeSignIn}
            appID={appID}
        />
    );
};
