import isString from "lodash/isString";
import React from "react";
import "twin.macro";

import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { getLocalizedString } from "@glide/localization";
import { isEmailPinEnabled } from "@glide/common-core/dist/js/utility/pins";
import { isValidEmailAddress } from "@glide/support";
import type { AppFeatures, IconImage } from "@glide/app-description";
import type { AuthenticationMethod } from "@glide/common-core/dist/js/Database";
import type { Loading } from "@glide/common-core/dist/js/components/types";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

import { GreetingStyles } from "../custom-sign-in/greeting-styles";
import { MadeWithGlide } from "../made-with-glide";
import { SignInBranding } from "../custom-sign-in/sign-in-branding";
import { UserAgreementButtons } from "../custom-sign-in/custom-wire-sign-in/user-agreements";
import { WireField } from "../../renderers/wire-field/wire-field";
import { getDefaultPagesGreeting, type PagesLoginSource } from "@glide/auth-controller-core";
import { SignUpFooter } from "./sign-up-footer";
import { SignInPrimaryButton } from "../sign-in-v2/sign-in-buttons";

interface Props extends React.PropsWithChildren {
    readonly allowSaveLogin: boolean;
    readonly appFeatures: AppFeatures;
    readonly appTitle?: string;
    readonly authMethod?: AuthenticationMethod;
    readonly canContinue?: boolean;
    readonly canCustomizeSignIn: boolean; // from EminenceFlags ideally
    readonly description?: string | Loading;
    readonly error?: string;
    readonly greeting?: string | Loading;
    readonly iconImage?: IconImage;
    readonly isSending: boolean;
    readonly onEmailChange: (newEmail: string) => void;
    readonly onPageSourceChanged?: (newPageSource: PagesLoginSource) => void;
    readonly onPressedContinue?: () => void;
    readonly onUserAgreedChange: (userAgreed: boolean) => void;
    readonly onUserUpdateSaveLogin: (saveLogin: boolean) => void;
    readonly pagesSource?: PagesLoginSource;
    readonly removeBranding: boolean; // from EminenceFlags ideally
    readonly userAgreed: boolean;
    readonly userEmail: string;
    readonly appID: string;
}

export const UsernameStage: React.VFC<Props> = p => {
    const {
        allowSaveLogin,
        appFeatures,
        appTitle,
        canContinue,
        canCustomizeSignIn,
        description,
        error,
        greeting,
        iconImage,
        isSending,
        onEmailChange,
        onPageSourceChanged,
        onPressedContinue,
        onUserAgreedChange,
        onUserUpdateSaveLogin,
        pagesSource,
        removeBranding,
        userAgreed,
        userEmail,
        appID,
    } = p;

    const theme = useWireAppTheme();

    const showSignInWithEmailPin = isEmailPinEnabled(appFeatures);

    const logo = theme.pagesSignInLogo;

    const onFormSubmit = React.useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();

            const userAgreementRequired = theme.requiresAgreement === true;
            const userAgreedValidated = !userAgreementRequired || (userAgreementRequired && userAgreed);

            if (onPressedContinue && userAgreedValidated) onPressedContinue();
        },
        [onPressedContinue, theme.requiresAgreement, userAgreed]
    );

    const appKind = getAppKindFromFeatures(appFeatures);
    const { requiresAgreement } = theme;

    const userAgreementRequired = requiresAgreement === true;
    const userAgreedValidated = !userAgreementRequired || (userAgreementRequired && userAgreed);

    const canProgressToPin = canContinue === true && isValidEmailAddress(userEmail) && userAgreedValidated;

    const isGreetingDefault = getDefaultPagesGreeting(pagesSource, appTitle, appKind) === greeting;

    const hasBackgroundImage = isString(theme.signInBackground) && canCustomizeSignIn;

    const topContent = (
        <div>
            <SignInBranding
                theme={theme}
                iconImage={iconImage}
                appFeatures={appFeatures}
                logo={logo}
                canCustomizeSignIn={canCustomizeSignIn}
                appID={appID}
            />

            <GreetingStyles greeting={greeting} />
            {showSignInWithEmailPin && (
                <form action="" onSubmit={onFormSubmit}>
                    <WireField
                        isSignInEmail={true}
                        title={description ?? undefined}
                        data-test="app-email-input"
                        isEnabled={!isSending}
                        placeholder={getLocalizedString("enterYourEmail", appKind)}
                        value={userEmail}
                        onChange={onEmailChange}
                        dataType="email"
                        error={error}
                        autoFocus={true}
                    />
                </form>
            )}
            <UserAgreementButtons
                appFeatures={appFeatures}
                theme={theme}
                userAgreed={userAgreed}
                allowSaveLogin={allowSaveLogin}
                onUserAgreedChange={onUserAgreedChange}
                onUserUpdateSaveLogin={onUserUpdateSaveLogin}
            />
        </div>
    );

    let buttonText = getLocalizedString("continue", appKind);
    if (isSending) {
        buttonText = getLocalizedString("sendingPin", appKind);
    } else if (!isGreetingDefault) {
        buttonText =
            pagesSource === "modal-sign-up"
                ? getLocalizedString("signUp", appKind)
                : getLocalizedString("signIn", appKind);
    }

    const buttons = (
        <div>
            {showSignInWithEmailPin && (
                <SignInPrimaryButton onClick={onPressedContinue} disabled={!canProgressToPin} label={buttonText} />
            )}

            {/* TODO need to bring this back somehow without importing the world - need to refactor this element */}
            {/* {!isRunningInNativeIos() && (
                <ExternalAuthProviderButtons
                    authMethod={authMethod}
                    {...p}
                    userAgreed={userAgreedValidated}
                    appKind={appKind}
                />
            )} */}
            {/*
             * The email input shows the error if it's enabled. If the email input isn't enabled
             * we have to show it here instead.
             */}
            {!showSignInWithEmailPin && error !== undefined && (
                <div tw="flex justify-center mt-2 text-text-danger text-center">{error}</div>
            )}
        </div>
    );

    return (
        <>
            {topContent}
            {buttons}
            <SignUpFooter pagesSource={pagesSource} onPageSourceChanged={onPageSourceChanged} appKind={appKind} />
            {!removeBranding ? (
                <MadeWithGlide position={hasBackgroundImage ? "relative" : "static"} background="none" />
            ) : (
                <div tw="gp-sm:h-8"></div>
            )}
        </>
    );
};
