import type { WireLocationComponent } from "@glide/fluent-components/dist/js/fluent-components";
import type { WireRenderer } from "../wire-renderer";
import * as React from "react";
import type { WireEditableValue } from "@glide/wire";
import { ValueChangeSource } from "@glide/wire";
import { isDefined, isEmptyOrUndefinedish } from "@glide/support";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import type { CurrentLocationListener } from "@glide/common-core/dist/js/components/current-location";
import {
    listenToCurrentLocation,
    stopListeningToCurrentLocation,
} from "@glide/common-core/dist/js/components/current-location";
import { PagesSwitch } from "../../components/pages-switch";
import "twin.macro";
import { GlideIcon } from "@glide/common";
import { css } from "styled-components";
import chroma from "chroma-js";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

export const WireLocation: WireRenderer<WireLocationComponent> = React.memo(p => {
    const { backend, value, label } = p;

    if (!isDefined(value)) {
        return null;
    }

    const defaultedLabel = isEmptyOrUndefinedish(label) ? "Use current location" : label;

    return <WireLocationImpl backend={backend} value={value} label={defaultedLabel} />;
});

interface WireLocationImplProps {
    backend: WireBackendInterface;
    value: WireEditableValue<string>;
    label: string;
}
const WireLocationImpl: React.VFC<WireLocationImplProps> = p => {
    const { backend, value, label } = p;

    const [listening, setListening] = React.useState(false);

    const setValue = React.useCallback(
        (newVal: string) => {
            if (value.onChangeToken === undefined) return;
            backend.valueChanged(value.onChangeToken, newVal, ValueChangeSource.User);
        },

        [backend, value.onChangeToken]
    );

    React.useEffect(() => {
        const l: CurrentLocationListener = {
            updateLocation: (lat, long) => {
                if (listening) {
                    const v = `${lat},${long}`;
                    setValue(v);
                }
            },
            locationError: () => {
                if (listening) {
                    setListening(false);
                }
                stopListeningToCurrentLocation(l);
            },
        };

        if (listening) {
            listenToCurrentLocation(l);
        }

        return () => {
            stopListeningToCurrentLocation(l);
        };
    }, [setValue, listening]);

    const onChange = (checked: boolean) => {
        setListening(checked);
    };

    const theme = useWireAppTheme();

    const paleAccent = chroma(theme.textContextualAccent).alpha(0.1).css();
    const paleBase = chroma(theme.textContextualBase).alpha(0.1).css();

    return (
        <label tw="flex justify-between items-center">
            <div
                tw="flex flex-1 gap-2 items-center"
                css={css`
                    .on-icon {
                        display: ${listening ? "block" : "none"};
                    }
                    .off-icon {
                        display: ${listening ? "none" : "block"};
                    }
                `}>
                <div
                    tw="rounded-md"
                    css={css`
                        background-color: ${listening ? paleAccent : paleBase};
                        color: ${listening ? theme.textContextualAccent : theme.textContextualBase};
                    `}>
                    <GlideIcon className="on-icon" kind="monotone" icon="mt-location-on" />
                    <GlideIcon className="off-icon" kind="monotone" icon="mt-location-off" />
                </div>
                <p tw="text-base text-text-contextual-base">{label}</p>
            </div>
            <PagesSwitch checked={listening} onChange={event => onChange(event.currentTarget.checked)} />
        </label>
    );
};
