import { AuthWrapper, EmailPinAuthController, GoogleAuthController, SSOAuthController } from "@glide/auth-controller";
import {
    type RootAuthenticator,
    EmailPinAuthenticator,
    getDefaultPagesGreeting,
    OAuthAuthenticator,
} from "@glide/auth-controller-core";
import { extractPrivateInviteLinkTokenFromCurrentURL } from "@glide/common-core/dist/js/authorization/auth";
import { AppKind } from "@glide/location-common";
import type { IconImage, NonUserAppFeatures } from "@glide/app-description";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { usePlayerEminenceFlags } from "@glide/player-core";
import { isDefined } from "@glide/support";
import {
    AddToHomescreen,
    AuthAgreementProvider,
    GreetingStyles,
    MadeWithGlide,
    SignInBrandingWithIcons,
    useAddToHomescreen,
    useAuthAgreement,
    UserAgreementButtons,
    useWireAppTheme,
} from "@glide/wire-renderer";
import { defined } from "@glideapps/ts-necessities";
import React, { useCallback } from "react";
import "twin.macro";
import type { SignInState } from "@glide/common-components";
import type { NotificationTarget } from "@glide/plugins";

interface PlayerAuthScreenProps {
    readonly onLoggedIn: () => void;
    readonly appFeatures: NonUserAppFeatures;
    readonly iconImage: IconImage | undefined;
    readonly appTitle: string;
    readonly authenticator: RootAuthenticator;
}

type ExclusiveController = "email-pin" | "google-auth" | "sso" | undefined;

export const PlayerAuthScreen: React.FC<PlayerAuthScreenProps> = p => {
    const { onLoggedIn, appFeatures, iconImage, appTitle, authenticator } = p;
    const [signinState, setSigninState] = React.useState<SignInState>("sign-in");
    const [pinMethod, setPinMethod] = React.useState<NotificationTarget["method"] | undefined>(undefined);

    const appID = defined(useAppID());

    const eminenceFlags = usePlayerEminenceFlags(appID);

    const theme = useWireAppTheme();
    const canCustomizeSignIn = eminenceFlags.canCustomizeSignIn;
    const hasBackgroundImage = isDefined(theme.signInBackground) && canCustomizeSignIn;

    const { showingAddToHomescreenDialog, closeAddToHomescreenDialog } = useAddToHomescreen();

    const onSigninStateChange = useCallback((state: SignInState, method?: NotificationTarget["method"]) => {
        setSigninState(state);
        setPinMethod(method);
    }, []);

    return (
        <AuthWrapper>
            <AuthAgreementProvider theme={theme}>
                <div tw="flex flex-col items-center">
                    <AuthStyleContainer
                        hasBackgroundImage={hasBackgroundImage}
                        tw="w-full [max-width: 390px] rounded-[20px] px-4 pb-4">
                        <SignInBrandingWithIcons
                            theme={theme}
                            iconImage={iconImage}
                            appFeatures={appFeatures}
                            logo={theme.pagesSignInLogo}
                            canCustomizeSignIn={canCustomizeSignIn}
                            appID={appID}
                            state={signinState}
                            pinMethod={pinMethod}
                        />
                        <AuthOptionControllersStack
                            appTitle={appTitle}
                            appFeatures={appFeatures}
                            onLoggedIn={onLoggedIn}
                            authenticator={authenticator}
                            signinState={signinState}
                            onSigninStateChange={onSigninStateChange}
                        />
                        <MaybeMadeWithGlide />
                    </AuthStyleContainer>
                </div>
                <AddToHomescreen
                    isOpen={showingAddToHomescreenDialog}
                    appKind={AppKind.Page}
                    appTitle={appTitle ?? ""}
                    closeDialog={closeAddToHomescreenDialog}
                    iconImage={iconImage}
                    appID={appID}
                />
            </AuthAgreementProvider>
        </AuthWrapper>
    );
};

type NoEmailAgreementsRenderingPosition = "google" | "sso" | undefined;
type AuthScreenStackProps = Omit<PlayerAuthScreenProps, "iconImage"> & {
    signinState: SignInState;
    onSigninStateChange: (state: SignInState, pinMethod?: NotificationTarget["method"]) => void;
};

const AuthOptionControllersStack: React.FC<AuthScreenStackProps> = p => {
    const { onLoggedIn, appFeatures, appTitle, authenticator, signinState, onSigninStateChange } = p;

    const appID = defined(useAppID());
    const theme = useWireAppTheme();
    const privateMagicLinkToken = React.useMemo(() => extractPrivateInviteLinkTokenFromCurrentURL(), []);
    const emailPinAuthenticator = React.useMemo(
        () => new EmailPinAuthenticator(appID, authenticator, privateMagicLinkToken),
        [appID, authenticator, privateMagicLinkToken]
    );

    const googleAuthenticator = React.useMemo(
        () => new OAuthAuthenticator(appID, authenticator),
        [appID, authenticator]
    );

    const ssoAuthenticator = React.useMemo(() => new OAuthAuthenticator(appID, authenticator), [appID, authenticator]);
    const [exclusiveController, setExclusiveController] = React.useState<ExclusiveController>();
    const { userAgreed, allowSaveLogin, setUserAgreed, setAllowSaveLogin } = useAuthAgreement();

    // for cases where email authentication is disabled, we may still need to show the terms/agreements component.
    const noEmailAgreementsRenderingPosition: NoEmailAgreementsRenderingPosition = React.useMemo(() => {
        const doesNotHaveEmailSignIn = appFeatures.showSignInWithEmailPin === false;
        const doesNotHaveGoogleSignIn = appFeatures.showSignInWithGoogle === false;
        if (doesNotHaveEmailSignIn && doesNotHaveGoogleSignIn) {
            return "sso";
        }
        if (doesNotHaveEmailSignIn) {
            return "google";
        }
        return undefined;
    }, [appFeatures.showSignInWithEmailPin, appFeatures.showSignInWithGoogle]);
    const greeting = theme.signInGreetingText ?? getDefaultPagesGreeting(undefined, appTitle, AppKind.Page);

    return (
        <>
            <ControllerVisibilityWrapper controller="email-pin" currentExclusiveController={exclusiveController}>
                <EmailPinAuthController
                    appFeatures={appFeatures}
                    appTitle={appTitle}
                    onLoggedIn={onLoggedIn}
                    authenticator={emailPinAuthenticator}
                    requestExclusiveRender={() => setExclusiveController("email-pin")}
                    yieldExclusiveRender={() => setExclusiveController(undefined)}
                    signinState={signinState}
                    onSigninStateChange={onSigninStateChange}
                />
            </ControllerVisibilityWrapper>

            <ControllerVisibilityWrapper controller="google-auth" currentExclusiveController={exclusiveController}>
                {noEmailAgreementsRenderingPosition === "google" && (
                    <>
                        <GreetingStyles greeting={greeting} />
                        <UserAgreementButtons
                            appFeatures={appFeatures}
                            theme={theme}
                            userAgreed={userAgreed}
                            allowSaveLogin={allowSaveLogin}
                            onUserAgreedChange={setUserAgreed}
                            onUserUpdateSaveLogin={setAllowSaveLogin}
                        />
                    </>
                )}
                <GoogleAuthController
                    appTitle={appTitle}
                    appFeatures={appFeatures}
                    authenticator={googleAuthenticator}
                    onLoggedIn={onLoggedIn}
                    requestExclusiveRender={() => setExclusiveController("google-auth")}
                    yieldExclusiveRender={() => setExclusiveController(undefined)}
                />
            </ControllerVisibilityWrapper>

            <ControllerVisibilityWrapper controller="sso" currentExclusiveController={exclusiveController}>
                {noEmailAgreementsRenderingPosition === "sso" && (
                    <>
                        <GreetingStyles greeting={greeting} />
                        <UserAgreementButtons
                            appFeatures={appFeatures}
                            theme={theme}
                            userAgreed={userAgreed}
                            allowSaveLogin={allowSaveLogin}
                            onUserAgreedChange={setUserAgreed}
                            onUserUpdateSaveLogin={setAllowSaveLogin}
                        />
                    </>
                )}
                <SSOAuthController
                    authenticator={ssoAuthenticator}
                    onLoggedIn={onLoggedIn}
                    requestExclusiveRender={() => setExclusiveController("sso")}
                    yieldExclusiveRender={() => setExclusiveController(undefined)}
                />
            </ControllerVisibilityWrapper>
        </>
    );
};

interface ControllerVisibilityWrapperProps {
    controller: ExclusiveController;
    currentExclusiveController: ExclusiveController;
}

const ControllerVisibilityWrapper: React.FC<React.PropsWithChildren<ControllerVisibilityWrapperProps>> = p => {
    const { controller, currentExclusiveController } = p;

    const isVisible = currentExclusiveController === undefined || currentExclusiveController === controller;

    return (
        <div data-visible={isVisible} tw="data-[visible=false]:hidden">
            {p.children}
        </div>
    );
};

const MaybeMadeWithGlide: React.VFC = () => {
    const appID = defined(useAppID());

    const { canCustomizeSignIn, removeBranding } = usePlayerEminenceFlags(appID);

    const theme = useWireAppTheme();
    const hasBackgroundImage = isDefined(theme.signInBackground) && canCustomizeSignIn;

    if (removeBranding) {
        return <div tw="gp-sm:h-8" />;
    }

    return <MadeWithGlide position={hasBackgroundImage ? "relative" : "static"} background="none" />;
};

interface AuthStyleContainerProps {
    readonly hasBackgroundImage: boolean;
    readonly className?: string;
}

const AuthStyleContainer: React.FC<React.PropsWithChildren<AuthStyleContainerProps>> = p => {
    const { hasBackgroundImage, className, children } = p;

    const { pageTheme } = useWireAppTheme();

    if (hasBackgroundImage) {
        return <BackgroundImageWrapper className={className}>{children}</BackgroundImageWrapper>;
    }

    if (pageTheme === "Dark") {
        return <DarkWrapper className={className}>{children}</DarkWrapper>;
    }

    if (pageTheme === "Highlight") {
        return <HighlightWrapper className={className}>{children}</HighlightWrapper>;
    }

    return <AccentWrapper className={className}>{children}</AccentWrapper>;
};

interface SpecificWrapperProps {
    className?: string;
}

const BackgroundImageWrapper: React.FC<React.PropsWithChildren<SpecificWrapperProps>> = p => {
    const { children, className } = p;

    return (
        <div
            tw="m-8 bg-[rgba(0,0,0,0.9)] supports-[backdrop-filter]:(bg-[rgba(0,0,0,0.5)] [backdrop-filter:blur(18px)]) w-full max-w-[390px]"
            className={className}>
            {children}
        </div>
    );
};

const AccentWrapper: React.FC<React.PropsWithChildren<SpecificWrapperProps>> = p => {
    const { children, className } = p;

    return (
        <div tw="bg-accent page-md:bg-bg-front" className={className}>
            {children}
        </div>
    );
};

const HighlightWrapper: React.FC<React.PropsWithChildren<SpecificWrapperProps>> = p => {
    const { children, className } = p;

    return (
        <div
            tw="bg-w100A page-md:(shadow-[0_0_0_5px_rgb(0,0,0,0.03)] border border-[rgba(5, 26, 46, 0.1)])"
            className={className}>
            {children}
        </div>
    );
};

const DarkWrapper: React.FC<React.PropsWithChildren<SpecificWrapperProps>> = p => {
    const { children, className } = p;

    return (
        <div tw="bg-darkerAccent" className={className}>
            {children}
        </div>
    );
};
