/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { hasOwnProperty, sleep } from "@glideapps/ts-necessities";

const { Result } = glide;

const apiToken = glide.makeParameter({
    type: "secret",
    name: "API Token",
    placeholder: "Enter your Replicate API Key",
    description: "[Learn more](https://replicate.com/account/api-tokens) about getting an API token",
});

export const plugin = glide.newPlugin({
    id: "replicate",
    name: "Replicate",
    description: "Choose from thousands of AI models and run them in your app",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/replicate.png",
    tier: "starter",
    parameters: {
        apiToken,
    },
});

const output = glide.makeParameter({
    type: "json",
    name: "Output",
    description: "Model output value",
});

const createUrl = "https://api.replicate.com/v1/predictions";

export async function replicateRun(
    context: Omit<glide.ServerExecutionContext, "uploadFile" | "rehostFile">,
    {
        apiToken,
        identifier,
        input,
    }: {
        apiToken?: string;
        identifier?: string;
        input?: object;
    }
) {
    if (apiToken === undefined) return Result.Fail("API Token is required");
    if (identifier === undefined) return Result.Fail("Identifier required");

    const cacheKey = [1, identifier, input];

    const version = identifier.includes(":") ? identifier.split(":")[1] : identifier;

    const predictionResult = await context.useCache(async () => {
        const createResults = await context.fetch(createUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiToken}`,
            },
            body: JSON.stringify({
                input,
                version,
            }),
        });

        if (createResults.ok === false) {
            const text = await createResults.text();
            return Result.FailFromHTTPStatus(`Failed to create prediction: ${text}`, createResults.status);
        }

        let finalResults = await createResults.json();
        let timeout = 500;

        while (
            finalResults.status === "starting" ||
            finalResults.status === "pending" ||
            finalResults.status === "processing"
        ) {
            await sleep(timeout);
            timeout *= 2;

            const r2 = await context.fetch(finalResults.urls.get, {
                headers: {
                    Authorization: `Bearer ${apiToken}`,
                },
            });

            if (!r2.ok) return Result.FailFromHTTPStatus(r2.statusText, r2.status);

            finalResults = await r2.json();
        }

        if (hasOwnProperty(finalResults, "output")) {
            return Result.Ok(finalResults.output as any);
        }

        return Result.Fail("Failed Prediction", finalResults);
    }, cacheKey);

    if (predictionResult.ok === false) return predictionResult;

    const output = predictionResult.result;
    return Result.Ok({ output });
}

plugin.addComputation({
    id: "run",
    name: "Run Prediction",
    description: "Create a prediction using a Replicate model and wait for the result.",
    billablesConsumed: 1,
    parameters: {
        identifier: glide.makeParameter({
            type: "string",
            name: "Identifier",
            required: true,
        }),
        input: glide.makeParameter({
            type: "jsonObject",
            name: "Input",
            required: true,
        }),
    },
    results: { output },
    execute: replicateRun,
});
