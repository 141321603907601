import type { BasePrimitiveValue } from "@glide/data-types";
import {
    isFavoritedColumnName,
    type ColumnType,
    type ColumnTypeKind,
    type Description,
    type SourceColumn,
    type TableAndColumn,
    type TableColumn,
    type TableGlideType,
    getNonHiddenColumns,
    SourceColumnKind,
    getBackendCompatiblePrimitiveColumns,
    getPrimitiveNonHiddenColumns,
    getSourceColumnSinglePath,
    getTableColumn,
    getTableColumnDisplayName,
    isColumnWritable,
    isDateTimeTypeKind,
    isMultiRelationType,
    isNumberTypeKind,
    isPrimitiveArrayType,
    isPrimitiveType,
    isSingleRelationType,
    sheetNameForTable,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type ActionDescription,
    type ArrayFilter,
    type ColumnAssignment,
    type FormScreenDescription,
    type PropertyDescription,
    MutatingScreenKind,
    PropertyKind,
    getArrayProperty,
    getSourceColumnProperty,
    getTableProperty,
} from "@glide/app-description";
import { type InputOutputTables, ImageKind, getInputOrOutputTable } from "@glide/common-core/dist/js/description";
import {
    type ActionNodeInScope,
    type ActionPropertyDescriptorCase,
    type AppDescriptionContext,
    type ArrayPropertyDescriptorCase,
    type ColumnFilterSpec,
    type ColumnPropertyDescriptorCase,
    type EditedColumnsAndTables,
    type EnumPropertyCase,
    type EnumPropertyDescriptorCase,
    type GetAllowedColumnsFunction,
    type InlineComputationPropertyDescriptorCase,
    type MultiCasePropertyDescriptor,
    type NumberPropertyDescriptorCase,
    type PropertyDescriptor,
    type PropertyDescriptorCase,
    type PropertyLabel,
    type PropertySource,
    type PropertyTableGetter,
    type SpecialValuePropertyDescriptorCase,
    type StringPropertyDescriptorCase,
    type StringyColumnPropertyFlag,
    type SuperPropertySection,
    type TableViewPropertyDescriptorCase,
    type TextPropertyDescriptorFlags,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    RequiredKind,
    SwitchPropertyHandler,
    combineEditedColumnsAndTables,
    doesMutatingScreenKindHaveInputRow,
    emptyEditedColumnsAndTables,
    getPropertyDescription,
    imageProperties,
    isImageType,
    makeColumnCase,
    makeColumnOrLiteralPropertyDescriptor,
    makeImagePropertyDescriptor,
    makeStringyColumnPropertyDescriptor,
    makeTextPropertyDescriptor,
    resolveSourceColumn,
    titleProperties,
    getStringTypeOrStringTypeArrayColumnsSpec,
    getPrimitiveColumnsSpec,
    makeInlineTemplatePropertyDescriptor,
    InlineComputationPropertyHandler,
    applyColumnFilterSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { assert } from "@glideapps/ts-necessities";
import { removeArrayItem, replaceArrayItem } from "@glide/support";
import { definedMap, setUnionInto } from "collection-utils";
import {
    doFilterColumnsExist,
    getColumnAssignments,
    getColumnNameAndGroup,
    getPropertyAndDescriptorFromDescription,
    getPropertyDescriptorCaseForDescription,
    getPropertyDescriptorCaseForKind,
    isPropertyInOtherContext,
} from "../description-utils";
import { type LinkTargetType, getTargetForLink } from "../link-columns";
import type { StaticActionContext } from "../static-context";
import { getUserProfileTableInfo } from "../user-profile-info";
import { handlerForPropertyKind } from "./description-handlers";
import { getAppFeatures } from "@glide/common-core/dist/js/components/SerializedApp";

function getColumnPropertyDescription<T extends Description>(
    pd: PropertyDescriptor,
    desc: T
): { sourceColumn: SourceColumn; columnDescr: ColumnPropertyDescriptorCase } | undefined {
    const propertyDesc = getPropertyDescription(desc, pd);
    if (propertyDesc === undefined) return undefined;
    const sourceColumn = getSourceColumnProperty(propertyDesc);
    if (sourceColumn === undefined) return undefined;
    const columnDescr = getPropertyDescriptorCaseForDescription(propertyDesc, pd) as ColumnPropertyDescriptorCase;
    if (columnDescr === undefined) return undefined;

    return { sourceColumn, columnDescr };
}

interface ColumnWithDescriptorCase extends TableAndColumn {
    readonly columnDescr: ColumnPropertyDescriptorCase;
    readonly sourceColumn: SourceColumn;
}

export function getColumnForProperty(
    pd: PropertyDescriptor,
    desc: Description,
    rootDesc: Description,
    tables: InputOutputTables,
    ccc: AppDescriptionContext,
    actionNodesInScope: readonly ActionNodeInScope[]
): ColumnWithDescriptorCase | undefined {
    const propertyDesc = getColumnPropertyDescription(pd, desc);
    if (propertyDesc === undefined) return undefined;
    const { columnDescr, sourceColumn } = propertyDesc;

    // FIXME: Pass a `MutatingScreenKind` into `getInputOrOutputTableForProperty`
    const table = getInputOrOutputTableForProperty(tables, columnDescr, undefined);
    let propertyTable: TableGlideType | undefined;
    if (columnDescr.getIndirectTable === undefined) {
        propertyTable = table;
    } else {
        if (ccc === undefined) return undefined;
        propertyTable = columnDescr.getIndirectTable(tables, rootDesc, desc, ccc, actionNodesInScope)?.table;
    }

    // We know that `sourceColumn` refers to a column, so we don't need to
    // pass `actionNodesInScope` here.
    const resolved = resolveSourceColumn(ccc, sourceColumn, propertyTable, undefined, undefined)?.tableAndColumn;
    if (resolved === undefined) return undefined;

    return { ...resolved, columnDescr, sourceColumn };
}

interface ColumnsUsedInDescription {
    readonly direct: Set<TableColumn>;
    readonly indirect: Set<TableColumn>;
}

export function makeEmptyColumnsUsedInDescription(): ColumnsUsedInDescription {
    return {
        direct: new Set(),
        indirect: new Set(),
    };
}

export function mergeIntoColumnsUsedInDescription(dest: ColumnsUsedInDescription, src: ColumnsUsedInDescription): void {
    setUnionInto(dest.direct, src.direct);
    setUnionInto(dest.indirect, src.indirect);
}

// This will only return columns used from the context, not, for example,
// columns used from the user profile row.
export function getColumnsUsedInDescription(
    propertyDescriptors: ReadonlyArray<PropertyDescriptor>,
    desc: Description,
    rootDesc: Description,
    tables: InputOutputTables,
    ccc: AppDescriptionContext,
    actionNodesInScope: readonly ActionNodeInScope[],
    predicate?: (column: TableColumn, columnDescr: ColumnPropertyDescriptorCase) => boolean
): ColumnsUsedInDescription {
    const columnsUsed = makeEmptyColumnsUsedInDescription();
    for (const pd of propertyDescriptors) {
        if (pd.when?.(desc, rootDesc, tables, ccc, undefined) === false) {
            continue;
        }

        const column = getColumnForProperty(pd, desc, rootDesc, tables, ccc, actionNodesInScope);
        if (column !== undefined) {
            if (predicate !== undefined && !predicate(column.column, column.columnDescr)) continue;
            if (column.columnDescr.getIndirectTable !== undefined) {
                columnsUsed.indirect.add(column.column);
            } else {
                // The column might still be from the user profile table, or
                // the "containing screen".
                if (column.sourceColumn.kind === SourceColumnKind.DefaultContext) {
                    assert(column.table === tables.input || column.table === tables.output);
                    columnsUsed.direct.add(column.column);
                } else {
                    columnsUsed.indirect.add(column.column);
                }
            }
            continue;
        }

        const propertyDesc = getPropertyDescription(desc, pd);
        if (propertyDesc === undefined) continue;

        const descrCase = getPropertyDescriptorCaseForDescription(propertyDesc, pd);

        if (descrCase?.kind === PropertyKind.Array) {
            const arr = getArrayProperty<Description[]>(propertyDesc);
            if (arr === undefined) continue;
            for (const item of arr) {
                mergeIntoColumnsUsedInDescription(
                    columnsUsed,
                    getColumnsUsedInDescription(
                        (descrCase as ArrayPropertyDescriptorCase).properties,
                        item,
                        rootDesc,
                        tables,
                        ccc,
                        actionNodesInScope,
                        predicate
                    )
                );
            }
        }
    }
    return columnsUsed;
}

// FIXME: This should ideally also respect `pdc.getIndirectTable`, but that
// also requires the root description and a schema inspector.  A few callers
// have to do this manually now.
export function getInputOrOutputTableForProperty(
    tables: InputOutputTables,
    pdc:
        | ColumnPropertyDescriptorCase
        | TableViewPropertyDescriptorCase
        | InlineComputationPropertyDescriptorCase
        | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined
): TableGlideType | undefined {
    const isEdit =
        pdc?.kind === PropertyKind.Column &&
        (pdc.isEditedInApp === true || pdc.isEditedInApp === "even-if-not-writable");
    if (!doesMutatingScreenKindHaveInputRow(mutatingScreenKind) && !isEdit) return undefined;
    return getInputOrOutputTable(tables, isEdit);
}

export function rewriteFilter(filter: ArrayFilter, table: TableGlideType): ArrayFilter | undefined {
    if (doFilterColumnsExist(filter, table) === true) {
        return filter;
    }
    return undefined;
}

export function getDefaultCaptionColumnDescriptor(
    propertyDescriptors: ReadonlyArray<PropertyDescriptor>
): { descr: PropertyDescriptor; pdc: ColumnPropertyDescriptorCase } | undefined {
    for (const descr of propertyDescriptors) {
        const pdc = getPropertyDescriptorCaseForKind(PropertyKind.Column, descr);

        if (pdc?.kind !== PropertyKind.Column) continue;
        const cpdc = pdc as ColumnPropertyDescriptorCase;
        if (cpdc.isDefaultCaption !== true) continue;

        return { descr, pdc: cpdc };
    }
    return undefined;
}

export function getDefaultCaption(
    propertyDescriptors: ReadonlyArray<PropertyDescriptor>,
    desc: Description,
    tables: InputOutputTables | undefined,
    schema: SchemaInspector
): string | undefined {
    const defaultCaptionDescriptor = getDefaultCaptionColumnDescriptor(propertyDescriptors);
    if (defaultCaptionDescriptor === undefined) return undefined;
    const { descr, pdc } = defaultCaptionDescriptor;

    const pd = getPropertyDescription(desc, descr);
    if (pd?.kind === PropertyKind.Column && pdc.kind === PropertyKind.Column) {
        assert(pdc.kind === PropertyKind.Column);
        if (tables === undefined) return undefined;
        const sourceColumn = getSourceColumnProperty(pd);
        if (sourceColumn === undefined) return undefined;
        const columnName = getSourceColumnSinglePath(sourceColumn);
        if (columnName === undefined) return undefined;
        // FIXME: Pass a `MutatingScreenKind` into `getInputOrOutputTableForProperty`
        const table = getInputOrOutputTableForProperty(tables, pdc, undefined);
        if (table === undefined) return columnName;
        const column = getTableColumn(table, columnName);
        if (column === undefined) return columnName;
        return getColumnNameAndGroup(column)[1];
    } else if (pd?.kind === PropertyKind.Table) {
        const tableName = getTableProperty(pd);
        if (tableName === undefined) return undefined;
        const table = schema.findTable(tableName);
        if (table === undefined) return undefined;
        return sheetNameForTable(table);
    } else {
        return undefined;
    }
}

const imageKindCases: ReadonlyArray<EnumPropertyCase<ImageKind>> = [
    { value: ImageKind.URL, label: "URL" },
    { value: ImageKind.MapFromAddress, label: "Map from address" },
];

export function makeTitlePropertyDescriptor(
    getPropertyTable: PropertyTableGetter | undefined,
    required: boolean,
    isEditedInApp: boolean,
    propertySection: SuperPropertySection
): PropertyDescriptor {
    return makeStringyColumnPropertyDescriptor(
        "titleProperty",
        "Title",
        [
            required ? "required" : "optional",
            "editable",
            "searchable",
            ...(isEditedInApp ? ["edited-in-app" as const] : []),
        ],
        propertySection,
        getPropertyTable,
        titleProperties
    );
}

export function makeSubtitlePropertyDescriptor(
    getPropertyTable: PropertyTableGetter | undefined,
    propertySection: SuperPropertySection
): PropertyDescriptor {
    return makeStringyColumnPropertyDescriptor(
        "subtitleProperty",
        "Details",
        ["editable", "searchable"],
        propertySection,
        getPropertyTable,
        titleProperties,
        ["titleProperty"]
    );
}

export function makeSummaryImagePropertyDescriptor(
    getPropertyTable: PropertyTableGetter | undefined,
    imageRequired: boolean,
    isEditedInApp: boolean,
    withEmoji: boolean,
    propertySection: SuperPropertySection
): PropertyDescriptor {
    return {
        kind: PropertyKind.Column,
        property: { name: "imageURLProperty" },
        label: "Image",
        required: imageRequired,
        editable: true,
        searchable: false,
        isEditedInApp,
        getIndirectTable: getPropertyTable,
        columnFilter: getStringTypeOrStringTypeArrayColumnsSpec,
        preferredNames: imageProperties,
        preferredType: t => isImageType(t, withEmoji, true),
        section: propertySection,
    };
}

export function makeSummaryImageKindPropertyDescriptor(imageKind: ImageKind | undefined): PropertyDescriptor {
    if (imageKind === undefined) {
        return {
            kind: PropertyKind.Enum,
            property: { name: "imageKind" },
            label: "Image is",
            menuLabel: "Image source",
            cases: imageKindCases,
            defaultCaseValue: ImageKind.URL,
            section: PropertySection.Design,
            visual: "dropdown",
            isSearchable: false,
        };
    } else {
        return {
            kind: PropertyKind.Constant,
            property: { name: "imageKind" },
            // This isn't used
            label: "Image is",
            value: imageKind,
            doNotRewrite: true,
            section: PropertySection.Hidden,
        };
    }
}

export function makeCaptionPropertyDescriptor(
    getPropertyTable: PropertyTableGetter | undefined,
    emptyByDefault: boolean,
    propertySection: SuperPropertySection
): PropertyDescriptor {
    const flags: StringyColumnPropertyFlag[] = ["editable", "searchable"];
    if (emptyByDefault) {
        flags.push("empty-by-default");
    }

    return makeStringyColumnPropertyDescriptor(
        "captionProperty",
        "Caption",
        flags,
        propertySection,
        getPropertyTable,
        ["caption", ...titleProperties],
        ["titleProperty", "subtitleProperty"]
    );
}

const primaryKeyPropertyName = "primaryKeyProperty";

export function makePrimaryKeyPropertyHandler(
    ccc: AppDescriptionContext,
    table: TableGlideType | undefined,
    isLegacy: boolean = true,
    // This is for ##nonEditableColumnProperty
    showAsDisabledIfRowIDPresent: boolean = false
): readonly ColumnPropertyHandler[] {
    const flags = new Set([ColumnPropertyFlag.Editable, ColumnPropertyFlag.EmptyByDefault]);
    let getAllowedColumns: GetAllowedColumnsFunction = getBackendCompatiblePrimitiveColumns;

    const rowIDColumn = table?.rowIDColumn !== undefined ? getTableColumn(table, table.rowIDColumn) : undefined;
    if (rowIDColumn !== undefined) {
        if (showAsDisabledIfRowIDPresent) {
            flags.delete(ColumnPropertyFlag.Editable);
            getAllowedColumns = () => [rowIDColumn];
        } else {
            return [];
        }
    }

    if (isLegacy && !ccc.userFeatures.primaryKeyProperties) {
        flags.add(ColumnPropertyFlag.Legacy);
    }

    return [
        new ColumnPropertyHandler(
            primaryKeyPropertyName,
            "Key",
            Array.from(flags),
            undefined,
            ["key", "id", "primary key", "identifier"],
            { getCandidateColumns: getAllowedColumns, columnTypeIsAllowed: isPrimitiveType },
            "string",
            PropertySection.Data
        ),
    ];
}

export const useFallbackInitialsPropertyHandler = new SwitchPropertyHandler(
    {
        useFallbackInitials: false,
    },
    "Generate image when data is not available",
    PropertySection.Options
);

export type PropertyDescriptorsWithColumns = [readonly PropertyDescriptor[], readonly TableColumn[]];

export function isTypeAssignableToLink(linkTarget: LinkTargetType, type: ColumnType, schema: SchemaInspector): boolean {
    if (isSingleRelationType(type)) {
        return linkTarget.targetTable === schema.findTable(type);
    } else if (isMultiRelationType(type)) {
        if (!linkTarget.isMulti) return false;
        return linkTarget.targetTable === schema.findTable(type.items);
    }
    return false;
}

function makeLinkTargetType(type: ColumnType, schema: SchemaInspector): LinkTargetType | undefined {
    if (isSingleRelationType(type)) {
        const table = schema.findTable(type);
        if (table === undefined) return undefined;
        return { targetTable: table, isMulti: false };
    } else if (isMultiRelationType(type)) {
        const table = schema.findTable(type.items);
        if (table === undefined) return undefined;
        return { targetTable: table, isMulti: true };
    } else {
        return undefined;
    }
}

export function isTypeAssignableToType(destType: ColumnType, valueType: ColumnType, schema: SchemaInspector): boolean {
    if (isPrimitiveType(destType)) {
        return isPrimitiveType(valueType);
    }
    if (isPrimitiveArrayType(destType)) {
        return isPrimitiveArrayType(valueType);
    }

    const linkTarget = makeLinkTargetType(destType, schema);
    if (linkTarget === undefined) return false;
    return isTypeAssignableToLink(linkTarget, valueType, schema);
}

interface GetWritableColumnsOptions {
    readonly withLinkColumns: boolean;
    readonly withArrays: boolean;
    readonly forAddingRow: boolean;
    readonly excludeOutputColumns?: ReadonlySet<string>;
}

// ##getWritableColumnsForColumnAssignment:
// This is better than `getEditableColumnsForTable` and allows link columns.
// The latter does have special handling for protected columns via
// `filterEditableColumns`, though, which might have to be added here.
export function getWritableColumnsForColumnAssignment(
    adc: AppDescriptionContext,
    destTable: TableGlideType,
    { withLinkColumns, withArrays, forAddingRow, excludeOutputColumns }: GetWritableColumnsOptions
): readonly TableColumn[] {
    const appFeatures = definedMap(adc.appDescription, appDesc => getAppFeatures(appDesc));
    const wasCopiedFromClassic = appFeatures?.pageCopiedFromClassic ?? false;

    return destTable.columns.filter(c => {
        if (excludeOutputColumns?.has(c.name) === true) return false;
        if (withLinkColumns) {
            const linkTarget = getTargetForLink(destTable, c, adc, false);
            if (linkTarget !== undefined) return true;
        }
        return (
            isColumnWritable(c, destTable, forAddingRow, { allowArrays: withArrays }) &&
            // ##excludeIsFavoritedColumn:
            // FIXME: In Apps, we should only show the favorite column if
            // it's used in the app.
            (adc.appKind === AppKind.App || wasCopiedFromClassic || c.name !== isFavoritedColumnName)
        );
    });
}

export function getWritableColumnAssignments(
    adc: AppDescriptionContext,
    destTable: TableGlideType,
    assignments: readonly ColumnAssignment[],
    forAddingRow: boolean
): readonly ColumnAssignment[] {
    // We only allow ##writableColumnsForColumnAssignments when reporting
    // which columns are edited.
    const allowedColumnNames = new Set(
        getWritableColumnsForColumnAssignment(adc, destTable, {
            withLinkColumns: true,
            withArrays: true,
            forAddingRow,
        }).map(c => c.name)
    );
    return assignments.filter(assignment => allowedColumnNames.has(assignment.destColumn));
}

/**
 * `inputTable` can/will be `undefined` for Automations, in which case we
 * still create a case for columns because they can refer to previous action
 * outputs which are represented as `SourceColumn`s.
 */
export function makePropertyDescriptorsForColumns<TDesc extends Description>(
    adc: AppDescriptionContext,
    inputTable: TableGlideType | undefined,
    destTable: TableGlideType,
    getColumns: (desc: Description) => readonly ColumnAssignment[],
    makeUpdate: (columns: readonly ColumnAssignment[]) => Partial<TDesc>,
    {
        withActionSource,
        withClearColumn,
        withLinkColumns,
        withArrays,
        forAddingRow,
        allowCustomAndUserProfile,
        emptyByDefault,
        propertySection = PropertySection.Data,
        excludeOutputColumns,
    }: GetWritableColumnsOptions & {
        withActionSource: boolean;
        withClearColumn: boolean;
        allowCustomAndUserProfile: boolean;
        emptyByDefault: boolean;
        propertySection?: SuperPropertySection;
    }
): PropertyDescriptorsWithColumns {
    // ##writableColumnsForColumnAssignments:
    // Here's where we determine which columns can be assigned to in
    // component/action configurations.  This better be the same as the
    // columns we report as written, and the columns we actually write.
    const writeableColumns =
        definedMap(destTable, t =>
            getWritableColumnsForColumnAssignment(adc, t, {
                withLinkColumns,
                withArrays,
                forAddingRow,
                excludeOutputColumns,
            })
        ) ?? [];
    const withColumnCase = inputTable === undefined || getPrimitiveNonHiddenColumns(inputTable).length > 0;
    const descrs = writeableColumns.map(destColumn => {
        const cases: PropertyDescriptorCase[] = [];

        const linkTarget =
            withLinkColumns !== undefined ? getTargetForLink(destTable, destColumn, adc, false) : undefined;
        function columnMatchesLink(t: ColumnType, s: SchemaInspector) {
            if (linkTarget === undefined) return false;
            return isTypeAssignableToLink(linkTarget, t, s);
        }

        const isArray = withArrays && isPrimitiveArrayType(destColumn.type);

        const columnFilter: ColumnFilterSpec = {
            getCandidateColumns: t => getNonHiddenColumns(t, true),
            columnTypeIsAllowed: (t, s) => {
                if (isArray) {
                    return isPrimitiveArrayType(t);
                } else if (linkTarget === undefined) {
                    return isPrimitiveType(t);
                } else {
                    return columnMatchesLink(t, s);
                }
            },
        };

        if (withColumnCase) {
            const columnCase: ColumnPropertyDescriptorCase = {
                kind: PropertyKind.Column,
                required: false,
                editable: true,
                searchable: false,
                emptyByDefault,
                allowUserProfileColumns: allowCustomAndUserProfile,
                allowFullRow: t => (t === linkTarget?.targetTable ? "preferred" : false),
                columnFilter,
                preferredNames: [destColumn.name],
                preferredType: destColumn.type.kind,
            };
            cases.push(columnCase);
        }

        const specialValueCase: SpecialValuePropertyDescriptorCase = {
            kind: PropertyKind.SpecialValue,
            excludePrimitiveValues: linkTarget !== undefined || isArray,
            withActionSource,
            withClearColumn,
        };
        cases.push(specialValueCase);

        const withStringCase = allowCustomAndUserProfile && linkTarget === undefined && !isArray;
        if (withStringCase) {
            const stringCase: StringPropertyDescriptorCase = {
                kind: PropertyKind.String,
                required: false,
                placeholder: "Custom",
            };
            cases.push(stringCase);
        }

        const label = getTableColumnDisplayName(destColumn);

        if (withColumnCase && withStringCase) {
            const templateCase = new InlineComputationPropertyHandler(
                destColumn.name,
                label,
                propertySection,
                applyColumnFilterSpec(columnFilter),
                undefined,
                undefined,
                {
                    withLabel: true,
                    defaultValue: undefined,
                    fullWidth: true,
                    allowUpload: false,
                }
            );
            cases.push(templateCase);
        }

        function findAssignment(d: Description): [ColumnAssignment, number] | undefined {
            const columns = getColumns(d);
            const index = columns.findIndex(a => a.destColumn === destColumn.name);
            if (index < 0) return undefined;
            return [columns[index], index];
        }

        return {
            label,
            property: {
                id: destColumn.name,
                get: d => definedMap(findAssignment(d), ([a]) => a.value),
                update: (d, v) => {
                    let columns = getColumns(d);
                    const index = definedMap(findAssignment(d), ([, i]) => i);
                    if (v !== undefined) {
                        const newAssignment = { destColumn: destColumn.name, value: v };
                        if (index !== undefined) {
                            columns = replaceArrayItem(columns, index, newAssignment);
                        } else {
                            columns = [...columns, newAssignment];
                        }
                    } else if (index !== undefined) {
                        columns = removeArrayItem(columns, index);
                    }
                    return makeUpdate(columns);
                },
            },
            cases,
            section: propertySection,
            writesToColumn: { table: destTable, column: destColumn },
        } as MultiCasePropertyDescriptor;
    });
    return [descrs, writeableColumns];
}

// This is used to find ##columnsAlreadyAssignedInScreen.
export function makePropertyDescriptorsForFormScreen(
    adc: AppDescriptionContext,
    tables: InputOutputTables,
    withActionSource: boolean
): readonly PropertyDescriptor[] {
    return makePropertyDescriptorsForColumns<FormScreenDescription>(
        adc,
        tables.input,
        tables.output,
        getColumnAssignments,
        cs => ({
            columnAssignments: cs,
        }),
        {
            withActionSource: withActionSource,
            withClearColumn: false,
            withLinkColumns: true,
            withArrays: true,
            forAddingRow: true,
            allowCustomAndUserProfile: false,
            emptyByDefault: false,
        }
    )[0];
}

export const screenTitlePropertyDescriptor: PropertyDescriptor = {
    property: { name: "title" },
    label: "Title",
    section: PropertySection.Navigation,
    cases: [
        {
            kind: PropertyKind.Column,
            required: false,
            editable: true,
            searchable: false,
            allowUserProfileColumns: true,
            columnFilter: getPrimitiveColumnsSpec,
            preferredNames: ["title", "name"],
            preferredType: "string",
        } as ColumnPropertyDescriptorCase,
        {
            kind: PropertyKind.String,
            required: false,
            placeholder: "Screen title",
        } as StringPropertyDescriptorCase,
    ],
};

export function makeTextPropertyDescriptorWithSpecialValue(
    property: string | PropertySource,
    label: PropertyLabel,
    placeholder: string,
    required: boolean,
    mutatingScreenKind: MutatingScreenKind | undefined,
    flags: TextPropertyDescriptorFlags
): PropertyDescriptor {
    return makeTextPropertyDescriptor(property, label, placeholder, required, mutatingScreenKind, flags, [
        {
            kind: PropertyKind.SpecialValue,
            excludePrimitiveValues: false,
            withActionSource: false,
            withClearColumn: false,
        },
    ]);
}

// FIXME: rename?
export function doesMutatingScreenKindSupportDefaultValues(
    mutatingScreenKind: MutatingScreenKind | undefined,
    forSavingTo: PropertyDescription | undefined
): boolean {
    if (isPropertyInOtherContext(forSavingTo)) return false;
    return mutatingScreenKind === MutatingScreenKind.AddScreen || mutatingScreenKind === MutatingScreenKind.FormScreen;
}

// FIXME: rename?
export function doesMutatingScreenSupportIsRequired(
    mutatingScreenKind: MutatingScreenKind | undefined,
    forSavingTo: PropertyDescription | undefined
): boolean {
    return mutatingScreenKind !== undefined && !isPropertyInOtherContext(forSavingTo);
}

// This makes a property descriptor for default values, which are allowed in
// add and form screens.
export function makeDefaultValuePropertyDescriptor(
    ccc: AppDescriptionContext,
    forSavingTo: PropertyDescription | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined,
    defaultPreferredType: ColumnTypeKind,
    placeholder: string = "Enter the default value",
    withCustomCase: boolean = true,
    enumOptions?: readonly BasePrimitiveValue[]
): PropertyDescriptor | undefined {
    if (!doesMutatingScreenKindSupportDefaultValues(mutatingScreenKind, forSavingTo)) return undefined;

    let columnCase: ColumnPropertyDescriptorCase | undefined;
    if (
        mutatingScreenKind === MutatingScreenKind.FormScreen ||
        definedMap(ccc.appDescription, getUserProfileTableInfo) !== undefined
    ) {
        columnCase = makeColumnCase(
            defaultPreferredType === "boolean",
            {
                searchable: false,
                applyFormat: false,
                emptyByDefault: true,
                needsExistingValue: true,
            },
            defaultPreferredType
        );
    }

    const otherCases: (
        | StringPropertyDescriptorCase
        | NumberPropertyDescriptorCase
        | EnumPropertyDescriptorCase<any>
    )[] = [];
    if (enumOptions !== undefined) {
        const enumCases: EnumPropertyCase<any>[] = enumOptions.map(o => ({ label: o.toString(), value: o }));
        if (columnCase === undefined) {
            enumCases.unshift({ label: "—", value: "" });
        }
        otherCases.push({
            kind: PropertyKind.Enum,
            menuLabel: "Default value",
            cases: enumCases,
            defaultCaseValue: enumCases[0]?.value,
            visual: "dropdown",
        });
    } else if (!withCustomCase) {
        // no custom case
    } else if (isNumberTypeKind(defaultPreferredType)) {
        otherCases.push({
            kind: PropertyKind.Number,
            required: RequiredKind.NotRequiredDefaultMissing,
            placeholder,
            defaultValue: 0,
        });
    } else if (defaultPreferredType === "boolean") {
        otherCases.push({
            kind: PropertyKind.Enum,
            menuLabel: "Default value",
            cases: [
                {
                    label: "On",
                    value: true,
                },
                {
                    label: "Off",
                    value: false,
                },
            ],
            defaultCaseValue: false,
            visual: "dropdown",
        });
    } else {
        if (isDateTimeTypeKind(defaultPreferredType)) {
            const enumCase: EnumPropertyDescriptorCase<"now"> = {
                kind: PropertyKind.Enum,
                menuLabel: "Default value",
                cases: [
                    {
                        label: defaultPreferredType === "date" ? "Today" : "Now",
                        value: "now",
                    },
                ],
                defaultCaseValue: undefined,
                visual: "dropdown",
            };
            otherCases.push(enumCase);
        }

        otherCases.push({
            kind: PropertyKind.String,
            required: false,
            isCaption: undefined,
            placeholder,
            isMultiLine: false,
            menuLabel: "Custom",
        });
    }

    return makeColumnOrLiteralPropertyDescriptor(
        "defaultValue",
        "Default value",
        mutatingScreenKind,
        PropertySection.Data,
        undefined,
        undefined,
        undefined,
        enumOptions !== undefined,
        columnCase,
        otherCases
    );
}

export interface CaptionPropertyDescriptorFlags {
    readonly label?: string; // default: `"Title"`
    readonly placeholder?: string; // default: `"Enter title"`
    readonly propertySection?: SuperPropertySection; // default: `PropertySection.Design`
}

export const labelCaptionStringOptions: CaptionPropertyDescriptorFlags = {
    label: "Label",
    placeholder: "Enter label",
};

export function makeCaptionStringPropertyDescriptor(
    defaultCaption: string,
    required: boolean,
    mutatingScreenKind: MutatingScreenKind | undefined,
    flags?: CaptionPropertyDescriptorFlags,
    propertyNameOverride?: string,
    useTemplate?: boolean
): PropertyDescriptor {
    const commonFlags = {
        isCaption: defaultCaption,
        preferredNames: ["caption", "title", "name", "description"],
        propertySection: flags?.propertySection ?? PropertySection.Design,
        searchable: false,
    };

    if (useTemplate === true) {
        return makeInlineTemplatePropertyDescriptor(
            propertyNameOverride ?? "caption",
            flags?.label ?? "Title",
            flags?.placeholder ?? "Enter title",
            required,
            "withLabel",
            mutatingScreenKind,
            commonFlags
        );
    }
    return makeTextPropertyDescriptor(
        propertyNameOverride ?? "caption",
        flags?.label ?? "Title",
        flags?.placeholder ?? "Enter title",
        required,
        mutatingScreenKind,
        commonFlags
    );
}

export function makeTitleSubtitleImagePropertyDescriptors(
    mutatingScreenKind: MutatingScreenKind | undefined,
    titleRequired: boolean,
    withEmoji: boolean = false,
    getIndirectTable?: PropertyTableGetter | undefined,
    useTemplate?: boolean | undefined
): readonly PropertyDescriptor[] {
    const titleFlags = {
        preferredNames: titleProperties,
        columnFirst: true,
        getIndirectTable,
    };

    const titleDescriptor =
        useTemplate === true
            ? makeInlineTemplatePropertyDescriptor(
                  "titleProperty",
                  "Title",
                  "Enter title",
                  titleRequired,
                  "withLabel",
                  mutatingScreenKind,
                  titleFlags
              )
            : makeTextPropertyDescriptor(
                  "titleProperty",
                  "Title",
                  "Enter title",
                  titleRequired,
                  mutatingScreenKind,
                  titleFlags
              );

    const subtitleFlags = {
        preferredNames: titleProperties,
        columnFirst: true,
        ignoreColumnsFromProperties: ["titleProperty"],
        getIndirectTable,
    };

    const subtitleDescriptor =
        useTemplate === true
            ? makeInlineTemplatePropertyDescriptor(
                  "subtitleProperty",
                  "Details",
                  "Enter details",
                  false,
                  "withLabel",
                  mutatingScreenKind,
                  subtitleFlags
              )
            : makeTextPropertyDescriptor(
                  "subtitleProperty",
                  "Details",
                  "Enter details",
                  false,
                  mutatingScreenKind,
                  subtitleFlags
              );

    const imageDescriptor = makeImagePropertyDescriptor(
        "imageURLProperty",
        "Image",
        "From URL or address",
        "Enter URL or address",
        false,
        false,
        false,
        withEmoji,
        true,
        getIndirectTable,
        mutatingScreenKind,
        {}
    );
    return [titleDescriptor, subtitleDescriptor, imageDescriptor];
}

export function getEditedColumnsForProperties(
    properties: readonly PropertyDescriptor[],
    desc: Description,
    rootDesc: Description,
    withActions: boolean,
    env: StaticActionContext<AppDescriptionContext>
): EditedColumnsAndTables {
    let results: EditedColumnsAndTables = emptyEditedColumnsAndTables;
    for (const propertyDescr of properties) {
        if (propertyDescr.when?.(desc, rootDesc, env.tables, env.context, undefined) === false) {
            continue;
        }

        const propertyAndDescriptor = getPropertyAndDescriptorFromDescription(desc, propertyDescr);
        if (propertyAndDescriptor === undefined) continue;

        const { description: pd, descriptorCase: pdc } = propertyAndDescriptor;

        const handler = handlerForPropertyKind(pdc.kind);
        results = combineEditedColumnsAndTables(results, handler.getEditedColumns(pdc, pd, rootDesc, env, withActions));
    }
    return results;
}

export function makePlaceholderPropertyDescriptor(
    mutatingScreenKind: MutatingScreenKind | undefined,
    propertySection: SuperPropertySection,
    placeholderPlaceholder: string = "Enter placeholder",
    useTemplate?: boolean
): PropertyDescriptor {
    const commonFlags = {
        propertySection,
        searchable: false,
    };
    if (useTemplate === true) {
        return makeInlineTemplatePropertyDescriptor(
            "placeholder",
            "Placeholder",
            placeholderPlaceholder,
            true,
            "withLabel",
            mutatingScreenKind,
            commonFlags
        );
    }
    return makeTextPropertyDescriptor(
        "placeholder",
        "Placeholder",
        placeholderPlaceholder,
        true,
        mutatingScreenKind,
        commonFlags
    );
}

export function makeDefaultAction<T extends Description>(
    descriptor: ActionPropertyDescriptorCase,
    tables: InputOutputTables,
    desc: T
): ActionDescription | undefined {
    const { kinds, defaultAction } = descriptor;
    if (defaultAction === true) {
        assert(kinds.length > 0);
        return { kind: kinds[0] };
    } else if (typeof defaultAction === "function") {
        return defaultAction(tables.input, desc);
    }
    return undefined;
}

export function makeScreenTitlePropertyDescriptor(
    mutatingScreenKind: MutatingScreenKind | undefined,
    placeholder: string = "Screen title"
): PropertyDescriptor {
    return makeTextPropertyDescriptor("title", "Title", placeholder, true, mutatingScreenKind, {
        preferredNames: titleProperties,
        propertySection: PropertySection.Design,
        searchable: false,
    });
}
