import type { LocalizedStrings as LocalizedStringsType } from "localized-strings";
import LocalizedStrings from "localized-strings";
import { getLanguagePart } from "./locale";
import { englishLocalizedStrings } from "./english";
import { englishUSLocalizedStrings } from "./lang/en-US";
import type { LanguageStrings } from "./types";

async function britishEnglishConstructor() {
    return englishLocalizedStrings;
}

// These language strings are starting to accumulate and bloat the chunks
// for _everyone_. We can lazy-load all of them by being clever about
// when we do it.
const languageConstructors = {
    // We used to default to en-GB for English, but recent versions of iOS
    // have stopped sending en-US and just send "en" instead.
    en: async () => {
        return englishUSLocalizedStrings;
    },
    "en-GB": britishEnglishConstructor, // UK
    "en-IN": britishEnglishConstructor, // India
    "en-SG": britishEnglishConstructor, // Singapore
    "en-IE": britishEnglishConstructor, // Ireland
    "en-ZA": britishEnglishConstructor, // Zambia
    "en-MU": britishEnglishConstructor, // Mauritius
    "en-CN": britishEnglishConstructor, // China
    "en-AE": britishEnglishConstructor, // United Arab Emirates
    "en-JM": britishEnglishConstructor, // Jamaica
    "en-KE": britishEnglishConstructor, // Kenya
    "en-NG": britishEnglishConstructor, // Nigeria
    "en-NZ": async () => {
        const { englishNewZealandLocalizedStrings } = await import("./lang/en-NZ");
        return englishNewZealandLocalizedStrings;
    },
    "en-AU": async () => {
        // Sorry Australia. You're so close to NZ in terms of locale we don't
        // even treat you differently.
        const { englishNewZealandLocalizedStrings: englishAustralianLocalizedStrings } = await import("./lang/en-NZ");
        return englishAustralianLocalizedStrings;
    },
    "en-CA": async () => {
        const { englishCanadianLocalizedStrings } = await import("./lang/en-CA");
        return englishCanadianLocalizedStrings;
    },
    es: async () => {
        const { spanishLocalizedStrings } = await import("./lang/es");
        return spanishLocalizedStrings;
    },
    it: async () => {
        const { italianLocalizedStrings } = await import("./lang/it");
        return italianLocalizedStrings;
    },
    fr: async () => {
        const { frenchLocalizedStrings } = await import("./lang/fr");
        return frenchLocalizedStrings;
    },
    zh: async () => {
        const { simplifiedChineseLocalizedStrings } = await import("./lang/zh");
        return simplifiedChineseLocalizedStrings;
    },
    nl: async () => {
        const { dutchLocalizedStrings } = await import("./lang/nl");
        return dutchLocalizedStrings;
    },
    ja: async () => {
        const { japaneseLocalizedStrings } = await import("./lang/ja");
        return japaneseLocalizedStrings;
    },
    id: async () => {
        const { indonesianLocalizedStrings } = await import("./lang/id");
        return indonesianLocalizedStrings;
    },
    yo: async () => {
        // not supported by Lang
        const { yorubaLocalizedStrings } = await import("./lang/yo");
        return yorubaLocalizedStrings;
    },
    ru: async () => {
        const { russianLocalizedStrings } = await import("./lang/ru");
        return russianLocalizedStrings;
    },
    da: async () => {
        const { danishLocalizedStrings } = await import("./lang/da");
        return danishLocalizedStrings;
    },
    pl: async () => {
        const { polishLocalizedStrings } = await import("./lang/pl");
        return polishLocalizedStrings;
    },
    "zh-TW": async () => {
        const { traditionalChineseTaiwanLocalizedStrings } = await import("./lang/zh-TW");
        return traditionalChineseTaiwanLocalizedStrings;
    },
    "zh-HK": async () => {
        const { traditionalChineseTaiwanLocalizedStrings } = await import("./lang/zh-TW");
        return traditionalChineseTaiwanLocalizedStrings;
    },
    ca: async () => {
        const { catalanLocalizedStrings } = await import("./lang/ca");
        return catalanLocalizedStrings;
    },
    "pt-BR": async () => {
        const { brazilianPortugueseLocalizedStrings } = await import("./lang/pt-BR");
        return brazilianPortugueseLocalizedStrings;
    },
    ka: async () => {
        const { georgianLocalizedStrings } = await import("./lang/ka");
        return georgianLocalizedStrings;
    },
    tr: async () => {
        const { turkishLocalizedStrings } = await import("./lang/tr");
        return turkishLocalizedStrings;
    },
    de: async () => {
        const { germanLocalizedStrings } = await import("./lang/de");
        return germanLocalizedStrings;
    },
    pt: async () => {
        const { portugueseLocalizedStrings } = await import("./lang/pt");
        return portugueseLocalizedStrings;
    },
    hr: async () => {
        const { croatianLocalizedStrings } = await import("./lang/hr");
        return croatianLocalizedStrings;
    },
    hu: async () => {
        const { hungarianLocalizedStrings } = await import("./lang/hu");
        return hungarianLocalizedStrings;
    },
    ro: async () => {
        const { romanianLocalizedStrings } = await import("./lang/ro");
        return romanianLocalizedStrings;
    },
    ko: async () => {
        const { koreanLocalizedStrings } = await import("./lang/ko");
        return koreanLocalizedStrings;
    },
    sv: async () => {
        const { swedishLocalizedStrings } = await import("./lang/sv");
        return swedishLocalizedStrings;
    },
    fi: async () => {
        const { finnishLocalizedStrings } = await import("./lang/fi");
        return finnishLocalizedStrings;
    },
    nb: async () => {
        const { norwegianLocalizedStrings } = await import("./lang/nb");
        return norwegianLocalizedStrings;
    },
    sk: async () => {
        const { slovakLocalizedStrings } = await import("./lang/sk");
        return slovakLocalizedStrings;
    },
    cs: async () => {
        const { czechLocalizedStrings } = await import("./lang/cs");
        return czechLocalizedStrings;
    },
    ar: async () => {
        const { arabicLocalizedStrings } = await import("./lang/ar");
        return arabicLocalizedStrings;
    },
    he: async () => {
        const { hebrewLocalizedStrings } = await import("./lang/he");
        return hebrewLocalizedStrings;
    },
    sl: async () => {
        const { slovenianLocalizedStrings } = await import("./lang/sl");
        return slovenianLocalizedStrings;
    },
    hi: async () => {
        const { hindiLocalizedStrings } = await import("./lang/hi");
        return hindiLocalizedStrings;
    },
    kn: async () => {
        const { kannadaLocalizedStrings } = await import("./lang/kn");
        return kannadaLocalizedStrings;
    },
    bn: async () => {
        const { bengaliLocalizedStrings } = await import("./lang/bn");
        return bengaliLocalizedStrings;
    },
    cy: async () => {
        const { welshLocalizedStrings } = await import("./lang/cy");
        return welshLocalizedStrings;
    },
    et: async () => {
        const { estonianLocalizedStrings } = await import("./lang/et");
        return estonianLocalizedStrings;
    },
    lv: async () => {
        const { latvianLocalizedStrings } = await import("./lang/lv");
        return latvianLocalizedStrings;
    },
    gl: async () => {
        const { galicianLocalizedStrings } = await import("./lang/gl");
        return galicianLocalizedStrings;
    },
    lo: async () => {
        const { laoLocalizedStrings } = await import("./lang/lo");
        return laoLocalizedStrings;
    },
    uk: async () => {
        const { ukrainianLocalizedStrings } = await import("./lang/uk");
        return ukrainianLocalizedStrings;
    },
    az: async () => {
        const { azerbaijaniLocalizedStrings } = await import("./lang/az");
        return azerbaijaniLocalizedStrings;
    },
    bg: async () => {
        const { bulgarianLocalizedStrings } = await import("./lang/bg");
        return bulgarianLocalizedStrings;
    },
    th: async () => {
        const { thaiLocalizedStrings } = await import("./lang/th");
        return thaiLocalizedStrings;
    },
    vi: async () => {
        const { vietnameseLocalizedStrings } = await import("./lang/vi");
        return vietnameseLocalizedStrings;
    },
};

export type LanguageCode = keyof typeof languageConstructors;

export type LocaleLoader<T> = { [P in LanguageCode]: () => Promise<T> };

let globalInstance: LocalizedStringsType<LanguageStrings> | undefined;
let englishGlobalInstance: LocalizedStringsType<LanguageStrings> | undefined;

export function localizedStringsEnglishGlobalInstance(): LocalizedStringsType<LanguageStrings> {
    if (englishGlobalInstance === undefined) {
        englishGlobalInstance = new LocalizedStrings({ en: englishLocalizedStrings });
    }
    return englishGlobalInstance;
}

export function localizedStringsGlobalInstance(): LocalizedStringsType<LanguageStrings> {
    // English is the fallback for the potential case where we didn't
    // initialize.
    return globalInstance ?? localizedStringsEnglishGlobalInstance();
}

export function getBestMatchingLanguage(language: string): LanguageCode {
    language = language.toLowerCase();

    for (const l of Object.keys(languageConstructors)) {
        if (language === l.toLowerCase()) return l as LanguageCode;
    }

    const nextLang = getLanguagePart(language);
    if (nextLang === language) return "en";

    return getBestMatchingLanguage(nextLang);
}

// index.tsx _must_ call this before doing anything else with localized strings.
// That's how we're able to continue to use localization synchronously while
// still lazy-loading the translations.
export async function initializeLocalizationCrossPlatform(
    language: string
): Promise<LocalizedStringsType<LanguageStrings>> {
    const langKey = getBestMatchingLanguage(language);

    const langStrings = (await languageConstructors[langKey]()) as LanguageStrings;

    globalInstance = new LocalizedStrings({ [langKey]: langStrings });
    return globalInstance;
}
