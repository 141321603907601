import classNames from "classnames";
import { nonNull } from "@glide/support";
import * as React from "react";
import { createPortal } from "react-dom";
import { ClickOutsideContainer } from "@glide/common";
import { css } from "styled-components";
import tw from "twin.macro";

interface BaseModalV2Props extends React.PropsWithChildren {
    readonly canClickOff: boolean;
    readonly onClickOff?: () => void;

    readonly canScroll?: boolean;
    readonly zIndex?: number;
    readonly className?: string;
    readonly noBackDrop?: boolean;
    readonly noPortal?: boolean;
}

const BaseModalV2: React.FC<React.PropsWithChildren<BaseModalV2Props>> = p => {
    const { children, canClickOff, onClickOff, className, canScroll, zIndex, noBackDrop, noPortal } = p;

    const onClickOffImpl = React.useCallback(() => {
        if (canClickOff && onClickOff !== undefined) {
            onClickOff();
        }
        return;
    }, [canClickOff, onClickOff]);

    const content = (
        <div
            tw="absolute grid place-items-center left-0 top-0 w-full h-full"
            css={css`
                background-color: ${noBackDrop ? "rgba(0,0,0,0)" : "rgba(0,0,0, 0.5)"};
                z-index: ${zIndex ?? "auto"};
                &.canScroll {
                    ${tw`overflow-y-scroll`}
                }
            `}
            className={classNames(className, canScroll && "canScroll")}>
            <ClickOutsideContainer className={"bm-content-area"} onClickOutside={onClickOffImpl}>
                {children}
            </ClickOutsideContainer>
        </div>
    );

    return noPortal === true ? content : createPortal(content, nonNull(document.getElementById("portal")));
};
export default BaseModalV2;
