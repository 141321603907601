import { generateFirestoreDocumentID } from "./id-generator";
import { load, save } from "./storage";
import { getTrackingConsent, onTrackingConsentChange } from "./tracking-consent";

let currentDeviceID: string | undefined;
const persistentKeyPrefix = "glide-persistent-id-";

function clearPersistentDeviceID(appID?: string): void {
    const persistentKey = persistentKeyPrefix + appID ?? "builder";
    save(persistentKey, undefined);
}

export function getPersistentDeviceID(appID?: string): string {
    if (currentDeviceID !== undefined) return currentDeviceID;

    const persistentKey = persistentKeyPrefix + appID ?? "builder";

    const loaded = load<string>(persistentKey);
    if (loaded !== undefined) {
        currentDeviceID = loaded;
        return currentDeviceID;
    }

    const persistentID = generateFirestoreDocumentID();
    currentDeviceID = persistentID;
    onTrackingConsentChange(trackingConsent => {
        if (trackingConsent) {
            save(persistentKey, currentDeviceID);
        } else {
            clearPersistentDeviceID(appID);
        }
    });

    // We only do this for the side effect of triggering the
    // tracking consent change handler.
    getTrackingConsent();
    return currentDeviceID;
}
