import { nullToUndefined } from ".";

export const featureSettingsStorageKey = `glide-feature-settings`;

export function getQuotasKey(quotaID: string): string {
    return `glide-quotas-${quotaID}`;
}

export function getTableSizeMapKey(appID: string): string {
    return `${appID}.table-size-map`;
}

export function getPreviewAsKey(appID: string): string {
    return appID + ".preview-as";
}

export function makeAppLoginKey(appID: string): string {
    return `app-login-${appID}`;
}

export function publishedAppKeyForID(appID: string): string {
    return `published-apps-${appID}`;
}

export function getAddToHomeKey(appID: string | undefined): string {
    // FIXME: This can be undefined, which could result in some issues
    return appID + "ADDTOHOME";
}

// FIXME: These two suffixes are deprecated as of the transition to
// app login tokens. We're only keeping them around for the transition.
const PASSWORD_SUFFIX = ".stored-pwd";
const PASSWORD_FOR_EMAIL_SUFFIX = ".stored-password";

const USER_SUFFIX = ".stored-username";
const EMAIL_SUFFIX = ".stored-email";
const PIN_SUFFIX = ".pin-sent";
const LOGIN_TOKEN_SUFFIX = ".login-token";

export function getUsernameKey(appID: string): string {
    return appID + USER_SUFFIX;
}

export function getPasswordKey(appID: string): string {
    return appID + PASSWORD_SUFFIX;
}

export function getEmailKey(appID: string): string {
    return appID + EMAIL_SUFFIX;
}

export function getPasswordForEmailKey(appID: string): string {
    return appID + PASSWORD_FOR_EMAIL_SUFFIX;
}

export function getPinKey(appID: string): string {
    return appID + PIN_SUFFIX;
}

export function getAllowLoginSaveKey(appID: string): string {
    return `${appID}.allow-login-save`;
}

export function getLoginTokenKey(appID: string): string {
    return appID + LOGIN_TOKEN_SUFFIX;
}

export function getComputationModelWatchKey(appID: string): string {
    return `${appID}.ncm-watch`;
}

function getLocalStorage(): Storage | undefined {
    try {
        if (localStorage !== null && localStorage !== undefined) {
            return localStorage;
        }
    } catch {
        // node doesn't have `localStorage`
    }
    return undefined;
}

export function localStorageGetItem(key: string): string | undefined {
    const storage = getLocalStorage();
    if (storage === undefined) return undefined;

    return nullToUndefined(storage.getItem(key));
}

export function localStorageSetItem(key: string, value: string): void {
    try {
        const storage = getLocalStorage();
        if (storage === undefined) return;

        storage.setItem(key, value);
    } catch {
        // This can happen due to quota, for example
    }
}

export function localStorageRemoveItem(key: string): void {
    try {
        const storage = getLocalStorage();
        if (storage === undefined) return;

        storage.removeItem(key);
    } catch {
        // Who knows if this can happen?
    }
}
