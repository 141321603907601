import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import React from "react";

export function useAuthConfiguredPlugins(appID: string): readonly string[] {
    const [result, setResult] = React.useState<readonly string[]>([]);

    React.useEffect(() => {
        let canceled = false;
        const fn = async () => {
            const r = await getAppFacilities().callAuthCloudFunction("getConfiguredPluginsForApp", { appID });
            if (canceled || r === undefined) return;
            const json = await r.json();
            if (canceled) return;
            setResult(json.ids);

            window.setTimeout(async () => {
                if (!canceled) await fn();
            }, 30 * 1000);
        };

        void fn();
        return () => {
            canceled = true;
        };
    }, [appID]);

    return result;
}
