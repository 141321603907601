import tw from "twin.macro";
import React from "react";
import { TextComponentStyle } from "@glide/component-utils";
import { Text } from "../../components/text/text";
import { AnimatePresence, m } from "framer-motion";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { CallbackURLOpenerContext } from "../../utils/use-wire-frontend-action-callbacks";

interface CaptureProps {
    readonly dismiss?: () => void;
    readonly open: () => void;
    readonly url: string;
}

const MOBILE_TAB_BAR_HEIGHT_PX = 56;

export const CaptureBlockedPopup: React.FC<{ isTabBarVisible: boolean }> = ({ isTabBarVisible }) => {
    const { blockedWindowOpenUrl, resetBlockedWindowUrl } = React.useContext(CallbackURLOpenerContext) ?? {};
    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    const open = React.useCallback(() => {
        window.open(blockedWindowOpenUrl);
        if (resetBlockedWindowUrl !== undefined) {
            resetBlockedWindowUrl();
        }
    }, [blockedWindowOpenUrl, resetBlockedWindowUrl]);
    const bottomPosition = isTabBarVisible && isMobile ? MOBILE_TAB_BAR_HEIGHT_PX + 16 : 8;
    const show = blockedWindowOpenUrl !== undefined;
    return (
        <AnimatePresence>
            {show ? (
                <m.div
                    initial={{ y: "100%" }}
                    animate={{
                        y: `-${bottomPosition}px`,
                        transition: {
                            type: "spring",
                            mass: 1,
                            stiffness: 850,
                            damping: 55,
                        },
                    }}
                    exit={{ y: "100%", transition: { duration: 0.2 } }}
                    tw="absolute bottom-[var(--safe-area-inset-bottom)] left-2 rounded-[10px] [background-color: #333] flex justify-center"
                    css={[show && tw`pointer-events-none right-2 page-md:right-[unset]`]}>
                    <CaptureContent dismiss={resetBlockedWindowUrl} open={open} url={blockedWindowOpenUrl} />
                </m.div>
            ) : null}
        </AnimatePresence>
    );
};

const CaptureContent: React.FC<CaptureProps> = ({ dismiss, open, url }) => {
    return (
        <div tw="flex flex-col gap-y-[22px] px-2 py-4 w-full items-center page-sm:(max-w-[377px])">
            <div tw="flex flex-col items-center">
                <Text tw="text-w90A" variant={TextComponentStyle.regular}>
                    Your browser prevented this app from opening: {url}.
                </Text>
            </div>
            <div tw="flex gap-x-2 items-center justify-center pointer-events-auto">
                <button onClick={open} tw="rounded-full bg-w100A py-[5px] px-[13px]">
                    <Text variant={TextComponentStyle.headlineXXXSmall} tw="text-n700">
                        Open
                    </Text>
                </button>
                {dismiss !== undefined ? (
                    <button onClick={() => dismiss()} tw="rounded-full bg-w10A py-[5px] px-[13px]">
                        <Text variant={TextComponentStyle.headlineXXXSmall} tw="text-w100A">
                            Cancel
                        </Text>
                    </button>
                ) : null}
            </div>
        </div>
    );
};
