import type { WireCommentsComponent } from "@glide/fluent-components/dist/js/base-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { useIsCommentScreen } from "../../../chrome/content/lib/screen-special-cases";
import { useScrollRef } from "../../../chrome/content/content-common";
import * as React from "react";
import { ValueChangeSource } from "@glide/wire";
import { runActionAndHandleURL } from "../../../wire-lib";
import { isDefined } from "@glide/support";

interface UseComments {
    readonly sendNewComment: (e: React.FormEvent<HTMLFormElement>) => void;
    readonly submitOnEnter: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export function useComments(
    backend: WireBackendInterface,
    postNewComment: WireCommentsComponent["postNewComment"],
    formRef: React.RefObject<HTMLFormElement>,
    newComment: WireCommentsComponent["newComment"]
): UseComments {
    const isCommentScreen = useIsCommentScreen();
    const scrollRef = useScrollRef();
    const sendNewComment = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const content = formData.get("comment-input")?.toString() ?? "";
            const hasText = content.length > 0;
            backend.valueChanged(newComment.onChangeToken, content, ValueChangeSource.User);

            if (hasText) {
                setTimeout(() => {
                    runActionAndHandleURL(postNewComment, backend, false);

                    // Nested timeouts FTW
                    setTimeout(() => {
                        if (isCommentScreen && isDefined(scrollRef) && isDefined(scrollRef.current)) {
                            scrollRef.current.scrollBy({ top: 9999, behavior: "smooth" });
                        }
                    }, 50);
                }, 0);
                formRef.current?.reset();
            }
        },
        [backend, formRef, isCommentScreen, newComment.onChangeToken, postNewComment, scrollRef]
    );

    const submitOnEnter = React.useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (isDefined(formRef.current)) {
                if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    formRef.current.requestSubmit();
                }
            }
        },
        [formRef]
    );

    return {
        sendNewComment,
        submitOnEnter,
    };
}
