import * as React from "react";
import debounce from "lodash/debounce";

import { withTheme } from "styled-components";
import type { RuntimeTheme } from "@glide/common";
import type { SignInTheme } from "../custom-sign-in-types";
import { CheckboxWrapper, CheckboxContainer, CheckboxIcon } from "./checkbox-style";

interface Props extends React.PropsWithChildren {
    checked: boolean;
    onChanged?: (newValue: boolean) => void;
    theme: RuntimeTheme;
    isEnabled: boolean;
    disablePadding?: boolean;
    colorTheme?: SignInTheme;
}
interface State {
    checkedValue: boolean;
}

class CheckboxImpl extends React.PureComponent<Props, State> {
    public state: State = {
        checkedValue: false,
    };

    private fromState = false;

    constructor(props: Props) {
        super(props);
        this.state.checkedValue = props.checked;
    }

    public componentDidUpdate(_prevProps: Props, _prevState: State) {
        if (!this.fromState && this.props.checked !== this.state.checkedValue) {
            this.setState({ checkedValue: this.props.checked });
        }
        this.fromState = false;
    }

    private onUpdate = (val: boolean) => {
        if (val !== this.props.checked && this.props.onChanged) {
            this.props.onChanged(val);
        }
    };

    private debouncedOnChange = debounce(this.onUpdate, 200);

    private handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const newVal = !this.state.checkedValue;
        this.fromState = true;
        this.setState({ checkedValue: newVal });
        this.debouncedOnChange(newVal);
    };

    private handleWrapperClick = (event: React.MouseEvent<HTMLLabelElement>) => {
        event.stopPropagation();
    };

    public render(): React.ReactNode {
        const { isEnabled, theme, disablePadding, colorTheme } = this.props;
        const { checkedValue } = this.state;

        const checkboxSelectedColor = colorTheme === undefined ? theme.primaryAccentColor : "transparent";
        const checkboxUnselectedColor = colorTheme === undefined ? theme.fgColorLight : colorTheme;
        const color = checkedValue ? checkboxSelectedColor : checkboxUnselectedColor;

        return (
            <CheckboxWrapper
                data-test="app-check-accessory"
                onClick={this.handleWrapperClick}
                disablePadding={disablePadding ?? false}
                isEnabled={isEnabled}
                className={`${checkedValue ? "changed" : "default"} ${checkedValue ? "checked" : "unchecked"} `}>
                <input type="checkbox" checked={checkedValue} onChange={this.handleCheckboxClick} />
                <CheckboxContainer className="check-box" disablePadding={disablePadding ?? false} color={color}>
                    <CheckboxIcon signInTheme={colorTheme} defaultColor={theme.baseColor} />
                </CheckboxContainer>
            </CheckboxWrapper>
        );
    }
}

export const Checkbox = withTheme(CheckboxImpl);
