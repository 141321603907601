import LRU from "lru-cache";

const pool: HTMLCanvasElement[] = [];

const moduleCache = new LRU<string, HTMLCanvasElement>({
    max: 500,
    updateAgeOnGet: true,
    dispose: (_key: string, canvas: HTMLCanvasElement) => {
        pool.push(canvas);
    },
});

export function drawGenImageToCanvas(ctx: CanvasRenderingContext2D, uri: string, x: number, y: number, size: number) {
    if (!moduleCache.has(uri)) {
        let targetCanv = pool.pop();
        if (targetCanv === undefined) {
            targetCanv = document.createElement("canvas");
            targetCanv.width = 64;
            targetCanv.height = 64;
        }
        const targetCtx = targetCanv.getContext("2d");
        if (targetCtx !== null) {
            ctx.clearRect(0, 0, 64, 64);
            drawStaticGenImage(targetCtx, uri, 64, 64);
            moduleCache.set(uri, targetCanv);
        }
    }

    const canvas = moduleCache.get(uri);

    if (canvas === undefined) return;

    ctx.drawImage(canvas, x, y, size, size);
}

export function roundedRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number
) {
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + radius, radius);
    ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
    ctx.arcTo(x, y + height, x, y + height - radius, radius);
    ctx.arcTo(x, y, x + radius, y, radius);
}

import * as React from "react";

import chroma from "chroma-js";
import Delaunator from "delaunator";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement> {
    mode: string;
    hash: string;

    className?: string;
}

interface Point {
    x: number;
    y: number;
    jitterX: number;
    jitterY: number;
    momentumX: number;
    momentumY: number;
}

function getVerticiesPerturbed(rows: number, cols: number) {
    const rowStride = rows + 1;
    const points: Point[] = Array.from({
        length: (rows + 1) * (cols + 1),
    }).map((_, i) => {
        const x = i % rowStride;
        const y = Math.floor(i / rowStride);
        const jitterX = (Math.random() - 0.5) * 0.9;
        const jitterY = (Math.random() - 0.5) * 0.9;
        const momentumX = (Math.random() - 0.5) * 0.01;
        const momentumY = (Math.random() - 0.5) * 0.01;

        return { x, y, jitterX, jitterY, momentumX, momentumY };
    });
    return points;
}

function updateJitter(points: Point[]): Point[] {
    const centeringStrength = 1;
    return points.map(p => {
        const newJitterX = Math.max(-0.5, Math.min(0.5, p.jitterX + p.momentumX));
        const newJitterY = Math.max(-0.5, Math.min(0.5, p.jitterY + p.momentumY));
        const newMomentumX = Math.max(
            -0.02,
            Math.min(0.02, p.momentumX + (Math.random() - 0.5 - p.jitterX * centeringStrength) * 0.0005)
        );
        const newMomentumY = Math.max(
            -0.02,
            Math.min(0.02, p.momentumY + (Math.random() - 0.5 - p.jitterY * centeringStrength) * 0.0005)
        );

        return {
            x: p.x,
            y: p.y,
            jitterX: newJitterX,
            jitterY: newJitterY,
            momentumX: newMomentumX,
            momentumY: newMomentumY,
        };
    });
}

function strToHex(str: string): string {
    let hash = 0;
    if (str.length === 0) return "#000000";
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 255;
        color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
}

function drawStaticGenImage(ctx: CanvasRenderingContext2D, uri: string, width: number, height: number) {
    uri = uri.substring("glide:".length);
    const [mode, seed] = uri.split(",");

    const points = getVerticiesPerturbed(10, 10);

    const start = strToHex(seed);
    let end = strToHex(seed + "end");

    let count = 0;
    while (chroma.contrast(start, end) < 2) {
        end = strToHex(count + seed + "end");
        count++;
    }
    const scale = chroma.scale([start, end]).mode("lab");

    drawGenImage(ctx, mode, scale, points, {}, width, height);
}

function drawGenImage(
    ctx: CanvasRenderingContext2D,
    mode: string,
    scale: chroma.Scale<chroma.Color>,
    points: Point[],
    colorCache: Record<number, string>,
    width: number,
    height: number
): void {
    const d = Delaunator.from(
        points,
        pnt => pnt.x + pnt.jitterX,
        pnt => pnt.y + pnt.jitterY
    );

    if (mode === "triangles") {
        ctx.fillStyle = scale(0.5).css();
        ctx.fillRect(0, 0, width, height);
    } else {
        ctx.clearRect(0, 0, width, height);
    }

    const inset = width / 10;
    ctx.save();
    ctx.translate(-inset, -inset);

    const scaleX = width / 8;
    const scaleY = height / 8;
    const triangles = d.triangles;
    for (let i = 0; i < triangles.length; i += 3) {
        const first = points[triangles[i]];
        const second = points[triangles[i + 1]];
        const third = points[triangles[i + 2]];

        const x1 = (first.x + first.jitterX) * scaleX - inset;
        const y1 = (first.y + first.jitterY) * scaleY - inset;
        const x2 = (second.x + second.jitterX) * scaleX - inset;
        const y2 = (second.y + second.jitterY) * scaleY - inset;
        const x3 = (third.x + third.jitterX) * scaleX - inset;
        const y3 = (third.y + third.jitterY) * scaleY - inset;

        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.lineTo(x3, y3);

        const xAvg = (x1 + x2 + x3) / 3;
        const yAvg = (y1 + y2 + y3) / 3;

        const tHeight = Math.max(y1, y2, y3) - Math.min(y1, y2, y3);
        const tWidth = Math.max(x1, x2, x3) - Math.min(x1, x2, x3);

        let distance = (xAvg - inset + yAvg - inset) / (width * 2);

        distance += (tHeight - tWidth) / (width * 1.5);

        const rounded = Math.round(distance * 100) / 100;
        let cached = colorCache[rounded];
        if (cached === undefined) {
            cached = scale(rounded).css();
            colorCache[rounded] = cached;
        }

        if (mode === "triangles") {
            ctx.fillStyle = cached;
            ctx.fill();
        } else {
            ctx.closePath();
            ctx.lineWidth = width / 125;
            ctx.strokeStyle = cached;
            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.stroke();
        }
    }

    ctx.restore();
}

export const GeneratedImage: React.FC<React.PropsWithChildren<Props>> = p => {
    const ref = React.useRef<HTMLCanvasElement | null>(null);
    const cache = React.useRef<Record<number, string>>({});
    const lastDraw = React.useRef<number>(0);

    const pointsRef = React.useRef<Point[]>(getVerticiesPerturbed(10, 10));

    const [startColor, setStartColor] = React.useState<string>();
    const [endColor, setEndColor] = React.useState<string>();
    const chromaScale = React.useRef<chroma.Scale<chroma.Color>>();

    React.useEffect(() => {
        const start = strToHex(p.hash);
        let end = strToHex(p.hash + "end");

        let count = 0;
        while (chroma.contrast(start, end) < 2) {
            end = strToHex(count + p.hash + "end");
            count++;
        }

        setStartColor(start);
        setEndColor(end);
        const scale = chroma.scale([start, end]).mode("lab");
        chromaScale.current = scale;

        cache.current = {};
        lastDraw.current = 0;
    }, [p.hash]);

    const draw = React.useCallback(() => {
        const canvas = ref.current;
        const scale = chromaScale.current;
        if (canvas === null || startColor === undefined || endColor === undefined || scale === undefined) return;

        pointsRef.current = updateJitter(pointsRef.current);
        const points = pointsRef.current;

        const ctx = canvas.getContext("2d");
        if (ctx === null) return;

        drawGenImage(ctx, p.mode, scale, points, cache.current, canvas.width, canvas.height);
    }, [p.mode, startColor, endColor]);

    React.useEffect(() => {
        let animationFrameId: number = 0;
        const render = (time: number) => {
            if (time - lastDraw.current > 200) {
                draw();
                lastDraw.current = time;
            }
            animationFrameId = window.requestAnimationFrame(render);
        };
        render(window.performance.now());

        return () => window.cancelAnimationFrame(animationFrameId);
    }, [draw]);

    let size = 500;
    if (ref.current !== null) {
        const c = ref.current;
        if (c.clientWidth > 0 && c.clientHeight > 0) {
            const dpr = window.devicePixelRatio;
            size = Math.ceil(Math.max(c.clientWidth * dpr, c.clientHeight * dpr) / 25) * 25;
        }
    }

    return <canvas {...p} className={p.className} ref={ref} width={size} height={size} />;
};
