// IMPORTANT:
// The string values of the enum must match the
// name of the audio file in public/media/sound
export enum Sound {
    Alert = "alert",
    Blip = "blip",
    CalmThought = "calm-thought",
    CloseEncounters = "close-encounters",
    Hint = "hint",
    NewThought = "new-thought",
    Ping = "ping",
    Sonar = "sonar",
    Pop = "pop",
}
