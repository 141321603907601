import {
    type GroundValue,
    makeRootPath,
    type Dirt,
    type Handler,
    type IncomingSlot,
    type Namespace,
} from "@glide/computation-model-types";
import { areSetsOverlapping } from "@glide/support";
import { defined } from "@glideapps/ts-necessities";
import { setUnionInto } from "collection-utils";
import type { SubscriptionSources } from "./internal-types";

export interface SubscriptionHandler extends Handler {
    readonly needsRedraw: boolean;
}

class SubscriptionHandlerImpl implements SubscriptionHandler {
    private readonly slots: IncomingSlot[];
    public readonly isDirty = false;
    public needsRedraw = false;
    // `true` means all rows, and it implies `needsRedraw`. We might want to
    // use this at some point to only redraw the affected rows.
    private rowsNeedRedraw: Set<string> | true = new Set();

    public readonly symbolicRepresentation: string = "subscription";

    constructor(sources: SubscriptionSources, private readonly onChange: () => void) {
        this.slots = [
            ...Array.from(sources.globalKeys).map(([k, c]) => ({
                sourcePath: makeRootPath(k),
                process: (d: Dirt) => {
                    if (this.needsRedraw && this.rowsNeedRedraw === true) return false;
                    if (c !== true && d.columns !== true) {
                        if (!areSetsOverlapping(c, d.columns)) return false;
                    }
                    this.needsRedraw = true;
                    this.rowsNeedRedraw = true;
                    onChange();
                    return true;
                },
            })),
            ...Array.from(sources.columnsInRows ?? []).map(([k, m]) => {
                let allColumns: Set<string> | undefined;
                return {
                    sourcePath: makeRootPath(k),
                    process: (d: Dirt) => {
                        if (this.rowsNeedRedraw === true) return false;
                        let rowColumns: ReadonlySet<string>;
                        let rowID: string | true;
                        if (d.kind === "row") {
                            if (m.has(d.rowID)) {
                                rowColumns = defined(m.get(d.rowID));
                                rowID = d.rowID;
                            } else {
                                return false;
                            }
                        } else {
                            if (allColumns === undefined) {
                                allColumns = new Set();
                                for (const columns of m.values()) {
                                    setUnionInto(allColumns, columns);
                                }
                            }
                            rowColumns = allColumns;
                            rowID = true;
                        }
                        if (d.columns === true || areSetsOverlapping(d.columns, rowColumns)) {
                            this.needsRedraw = true;
                            if (rowID === true) {
                                this.rowsNeedRedraw = true;
                            } else {
                                this.rowsNeedRedraw.add(rowID);
                            }
                            onChange();
                            return true;
                        }
                        return false;
                    },
                };
            }),
            ...Array.from(sources.columnsInAllDirectRows ?? []).map(([k, cs]) => ({
                sourcePath: makeRootPath(k),
                process: (d: Dirt) => {
                    if (this.rowsNeedRedraw === true) return false;
                    if (d.columns === true || areSetsOverlapping(d.columns, cs)) {
                        if (d.kind === "row") {
                            this.rowsNeedRedraw.add(d.rowID);
                        } else {
                            this.rowsNeedRedraw = true;
                        }
                        this.needsRedraw = true;
                        onChange();
                        return true;
                    }
                    return false;
                },
            })),
            ...Array.from(sources.columnsInAllIndirectRows ?? []).map(([k, cs]) => ({
                sourcePath: makeRootPath(k),
                process: (d: Dirt) => {
                    if (this.rowsNeedRedraw === true) return false;
                    if (d.columns === true || areSetsOverlapping(d.columns, cs)) {
                        this.rowsNeedRedraw = true;
                        this.needsRedraw = true;
                        onChange();
                        return true;
                    }
                    return false;
                },
            })),
        ];
    }

    public dependenciesAreDirty(): void {
        // We don't set ourselves dirty here.  Instead, this will trigger a
        // rehydration, which in turn will run ##recomputeSubscriptionHandlers
        // which will recompute our dependencies, which will then potentially
        // push dirt, and that will make us set ourselved dirty.
        this.onChange();
    }

    public getSlots(): readonly IncomingSlot[] {
        return this.slots;
    }

    public recompute(_ns: Namespace): GroundValue {
        return undefined;
    }

    public setDirty(): void {
        return;
    }
}

export function makeSubscriptionHandler(
    sources: SubscriptionSources,
    onChange: () => void
): SubscriptionHandler | undefined {
    if (
        sources.globalKeys.size === 0 &&
        sources.columnsInRows.size === 0 &&
        sources.columnsInAllDirectRows.size === 0 &&
        sources.columnsInAllIndirectRows.size === 0
    )
        return undefined;

    return new SubscriptionHandlerImpl(sources, onChange);
}
