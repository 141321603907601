import copyToClipboardLegacy from "copy-to-clipboard";

export function copyToClipboard(text: string): void {
    if (navigator.clipboard !== undefined) {
        try {
            void navigator.clipboard.writeText(text);
        } catch {
            // meh
        }
        return;
    }
    copyToClipboardLegacy(text);
}

export async function readClipboardText(): Promise<string | undefined> {
    try {
        return await navigator.clipboard.readText();
    } catch {
        // Ignore
    }
    return undefined;
}
