import { setSubtract } from "collection-utils";

import type { UserData, UserDataAndState } from "@glide/common-core/dist/js/Database";
import { getUserFlags, isUserData } from "@glide/common-core/dist/js/Database";
import type { UserFlags } from "@glide/common-core/dist/js/firebase-function-types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { defaultRootState } from "../store/default-root-state";

const deletedAppIDs = new Set<string>();

function removeDeletedApps(user: UserData): UserData {
    return {
        ...user,
        appIDs: Array.from(setSubtract(user.appIDs, deletedAppIDs)),
        planAppIDs: {
            ...user.planAppIDs,
            pro: Array.from(setSubtract(user.planAppIDs.pro, deletedAppIDs)),
        },
    };
}

const slice = createSlice({
    name: "userData",
    initialState: defaultRootState.userData,
    reducers: {
        setUser: (_, action: PayloadAction<UserDataAndState>) => {
            const newState = action.payload;
            if (newState === undefined || !isUserData(newState)) return newState;
            return removeDeletedApps(newState);
        },
        updateUser: (state, action: PayloadAction<UserData>) => {
            if (!isUserData(state)) return state;
            const newState = action.payload;
            if (state.id !== newState.id) return state;
            return removeDeletedApps(newState);
        },
        upgradeToPro: (state, action: PayloadAction<string>) => {
            if (!isUserData(state)) return;
            const appID = action.payload;
            state.planAppIDs.pro.push(appID);
        },
        downgradeToFree: (state, action: PayloadAction<string>) => {
            if (!isUserData(state)) return;
            const appID = action.payload;
            state.planAppIDs.pro = state.planAppIDs.pro.filter(a => a !== appID);
        },
        deleteApp: (state, action: PayloadAction<string>) => {
            if (!isUserData(state)) return state;
            const appID = action.payload;
            deletedAppIDs.add(appID);
            return removeDeletedApps(state);
        },
        updateUserFlags: (state, action: PayloadAction<Partial<UserFlags>>) => {
            if (!isUserData(state)) return state;
            const updates = action.payload as Partial<UserFlags>;
            return {
                ...state,
                flags: { ...getUserFlags(state), ...updates },
            };
        },
        deleteOrg: (state, action: PayloadAction<string>) => {
            if (!isUserData(state)) return state;
            const orgID = action.payload;
            return {
                ...state,
                orgUserIDs: state.orgUserIDs.filter(id => id !== orgID),
            };
        },
    },
});

export const { deleteApp, downgradeToFree, setUser, updateUser, updateUserFlags, upgradeToPro, deleteOrg } =
    slice.actions;
export const userDataReducer = slice.reducer;
