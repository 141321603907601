import { geocodeAddress } from "@glide/common-core/dist/js/components/geocode";
import type { PluginConfig } from "@glide/app-description";
import type { ActionAppEnvironment } from "@glide/common-core/dist/js/components/types";
import { uploadFileIntoGlideStorage } from "@glide/common-core/dist/js/components/upload-handlers";
import {
    type GroundValue,
    type LoadedGroundValue,
    type LoadingValue,
    type PrimitiveValue,
    isPrimitiveValue,
    isLoadingValue,
    isPrimitive,
    type Path,
    getSymbolicRepresentationForPath,
    type AsyncComputation,
    type RootPathResolver,
    type ComputationValueGetters,
} from "@glide/computation-model-types";
import {
    getSymbolicRepresentationForGroundValue,
    asMaybeDate,
    asMaybeNumber,
    asMaybeString,
    asPrimitive,
    isArrayValue,
    isRow,
} from "@glide/common-core/dist/js/computation-model/data";
import type { LatLng } from "@glide/common-core/dist/js/Database";
import type { RunIntegrationsInstance } from "@glide/common-core/dist/js/firebase-function-types";
import { getDistanceFromLatLonInKm } from "@glide/common-core/dist/js/geometry";
import { frontendTrace } from "@glide/common-core/dist/js/tracing";
import type { ParameterSourceColumnType } from "@glide/common-core/dist/js/computation-model/make-parameter-source-column-type";
import { type GlideDateTime, parseUserDateTimeZoneAgnostic, parseUserDateTimeZoneAware } from "@glide/data-types";
import { type FormatDateTimeSpecification, DistanceUnit } from "@glide/formula-specifications";
import pack from "@glide/glide-plugins/client";
import type { Computation, ParameterRecord, UnwrapGeneric } from "@glide/plugins";
import {
    type Action,
    type ErrorResult,
    type NativePlugin,
    type Plugin,
    type SerializablePluginMetadata,
    ClientExecutionContextBase,
    Result,
    defaultFetchTimeout,
    isResult,
} from "@glide/plugins";
import {
    findAction,
    makePluginComputationKindFromIDs,
    wrapFetchForRetries,
    pluginResultToGlideType,
} from "@glide/plugins-utils";
import type { ChangeObservable, JSONObject } from "@glide/support";
import { isArray, logError, isResponseOK, milesPerKM, MappingChangeObservable } from "@glide/support";
import { assertNever, defined, definedMap, mapFilterUndefined, panic } from "@glideapps/ts-necessities";
import { arrayMapSync, hasOwnProperty, mapMap } from "collection-utils";
import flatten from "lodash/flatten";
import { reportBillable } from "@glide/backend-api";
import { PluginError } from "./support";
import { pluginValueToCellValue } from "@glide/common-core/dist/js/serialization";
import { getFeatureSetting } from "@glide/common-core";

export class GeoDistanceAsyncComputation implements AsyncComputation {
    constructor(
        private readonly _firstLocationPath: Path,
        private readonly _secondLocationPath: Path,
        private readonly _unit: DistanceUnit,
        private readonly _appEnvironment: ActionAppEnvironment,
        private readonly _quotaKey: string
    ) {}

    public getPaths(): readonly Path[] {
        return [this._firstLocationPath, this._secondLocationPath];
    }

    private lookupLocation(
        value: LoadedGroundValue,
        valueGetters: ComputationValueGetters
    ): Promise<LatLng | undefined> | LoadingValue | LatLng | undefined {
        if (isRow(value)) {
            const latValue = valueGetters.getRowColumn(value, "latitude");
            if (isLoadingValue(latValue)) return latValue;
            const lat = asMaybeNumber(latValue);
            const lngValue = valueGetters.getRowColumn(value, "longitude");
            if (isLoadingValue(lngValue)) return lngValue;
            const lng = asMaybeNumber(lngValue);
            if (lat === undefined || lng === undefined) return undefined;
            return { lat, lng };
        }

        const str = asMaybeString(value);
        if (str === undefined) return undefined;

        return geocodeAddress(this._appEnvironment, str, this._quotaKey);
    }

    private computeDistance(firstLocation: LatLng | undefined, secondLocation: LatLng | undefined): number | undefined {
        if (firstLocation === undefined || secondLocation === undefined) return undefined;

        const km = getDistanceFromLatLonInKm(
            firstLocation.lat,
            firstLocation.lng,
            secondLocation.lat,
            secondLocation.lng
        );
        switch (this._unit) {
            case DistanceUnit.KM:
                return km;
            case DistanceUnit.Miles:
                return km * milesPerKM;
            default:
                return assertNever(this._unit);
        }
    }

    private async computeFromPromises(
        firstPromise: Promise<LatLng | undefined> | LatLng | undefined,
        secondPromise: Promise<LatLng | undefined> | LatLng | undefined
    ): Promise<number | undefined> {
        const [firstLocation, secondLocation] = await Promise.all([firstPromise, secondPromise]);
        return this.computeDistance(firstLocation, secondLocation);
    }

    public compute(
        resolver: RootPathResolver,
        context: GroundValue,
        valueGetters: ComputationValueGetters
    ): Promise<GroundValue> | GroundValue {
        const firstValue = valueGetters.getValueAt(resolver, context, this._firstLocationPath);
        if (isLoadingValue(firstValue)) return firstValue;
        const secondValue = valueGetters.getValueAt(resolver, context, this._secondLocationPath);
        if (isLoadingValue(secondValue)) return secondValue;

        const maybeFirstLocation = this.lookupLocation(firstValue, valueGetters);
        if (isLoadingValue(maybeFirstLocation)) return maybeFirstLocation;
        const maybeSecondLocation = this.lookupLocation(secondValue, valueGetters);
        if (isLoadingValue(maybeSecondLocation)) return maybeSecondLocation;

        if (maybeFirstLocation instanceof Promise || maybeSecondLocation instanceof Promise) {
            return this.computeFromPromises(maybeFirstLocation, maybeSecondLocation);
        } else {
            return this.computeDistance(maybeFirstLocation, maybeSecondLocation);
        }
    }

    public get symbolicRepresentation(): string {
        return `(geo-distance from: ${getSymbolicRepresentationForPath(
            this._firstLocationPath
        )} to: ${getSymbolicRepresentationForPath(this._secondLocationPath)})`;
    }
}

export class FormatDateTimeComputation implements AsyncComputation {
    constructor(private readonly _dateTimePath: Path, private readonly _spec: FormatDateTimeSpecification) {}

    public getPaths(): readonly Path[] {
        return [this._dateTimePath];
    }

    private format(dateTime: GlideDateTime): string | undefined {
        // We ignore the time zone in the spec because the computation model
        // will already have converted to time-zone agnostic for UTC columns,
        // so "local" will produce the correct result.
        return dateTime.formatWithSpecification(this._spec, "local");
    }

    private async awaitAndFormat(p: Promise<GlideDateTime | undefined>): Promise<PrimitiveValue> {
        const d = await p;
        if (d === undefined) return undefined;
        return this.format(d);
    }

    private formatPrimitive(p: PrimitiveValue): Promise<PrimitiveValue> | PrimitiveValue {
        if (typeof p === "string") {
            const maybeDate = parseUserDateTimeZoneAgnostic(p, true);
            if (maybeDate instanceof Promise) {
                return this.awaitAndFormat(maybeDate);
            } else if (maybeDate === undefined) {
                return undefined;
            } else {
                return this.format(maybeDate);
            }
        } else {
            const d = asMaybeDate(p);
            if (d === undefined) return undefined;
            return this.format(d);
        }
    }

    public compute(
        ns: RootPathResolver,
        context: GroundValue,
        valueGetters: ComputationValueGetters
    ): Promise<GroundValue> | GroundValue {
        const p = valueGetters.getValueAt(ns, context, this._dateTimePath);
        if (isLoadingValue(p)) {
            return p;
        } else if (isPrimitive(p)) {
            return this.formatPrimitive(p);
        } else if (isArray(p)) {
            const a = p.map(v => this.formatPrimitive(asPrimitive(v)));
            if (a.some(v => v instanceof Promise)) {
                return arrayMapSync(a, async v => await v);
            } else {
                return a.map(v => {
                    if (v instanceof Promise) {
                        return panic("We already asserted this above");
                    }
                    return v;
                });
            }
        } else {
            return undefined;
        }
    }

    public get symbolicRepresentation(): string {
        return `(format-date-time ${getSymbolicRepresentationForPath(this._dateTimePath)})`;
    }
}

export class ParseTimeZoneAwareDateTimeComputation implements AsyncComputation {
    constructor(private readonly _valuePath: Path) {}

    public getPaths(): readonly Path[] {
        return [this._valuePath];
    }

    public compute(
        ns: RootPathResolver,
        context: GroundValue,
        valueGetters: ComputationValueGetters
    ): Promise<GroundValue> | GroundValue {
        const p = valueGetters.getValueAt(ns, context, this._valuePath);
        if (typeof p !== "string") {
            return p;
        }
        const parsed = parseUserDateTimeZoneAware(p);
        if (parsed instanceof Promise) {
            return parsed.then(d => d ?? p);
        } else {
            return parsed ?? p;
        }
    }

    public get symbolicRepresentation(): string {
        return `(parse-time-zone-agnostic-date-time ${getSymbolicRepresentationForPath(this._valuePath)})`;
    }
}

export class YesCodeComputation implements AsyncComputation {
    constructor(
        private readonly _urlPath: Path,
        private readonly _paramPaths: ReadonlyMap<string, [valuePath: Path, formattedPath: Path]>,
        private readonly _appEnvironment: ActionAppEnvironment
    ) {}

    public getPaths(): readonly Path[] {
        return [this._urlPath, ...flatten(Array.from(this._paramPaths.values()))];
    }

    public compute(
        resolver: RootPathResolver,
        context: GroundValue,
        valueGetters: ComputationValueGetters
    ): GroundValue | Promise<GroundValue> {
        const url = valueGetters.getValueAt(resolver, context, this._urlPath);
        if (isLoadingValue(url)) return url;
        if (typeof url !== "string") return undefined;

        let loadingValue: LoadingValue | undefined;
        const params = mapMap(this._paramPaths, ([v, f]) => {
            const value = valueGetters.getValueAt(resolver, context, v);
            if (isLoadingValue(value)) {
                loadingValue = value;
            }
            const formatted = valueGetters.getValueAt(resolver, context, f);
            if (isLoadingValue(formatted)) {
                loadingValue = formatted;
            }
            // Note that if either of those is the loading value we won't
            // do the call below.
            return [value, formatted] as const;
        });
        if (loadingValue !== undefined) return loadingValue;

        return this._appEnvironment.callYesCode?.(url, params);
    }

    public get symbolicRepresentation(): string {
        return `(yes-code ${getSymbolicRepresentationForPath(this._urlPath)} ${Array.from(this._paramPaths)
            .map(
                ([k, [v, f]]) =>
                    `${k}: (value: ${getSymbolicRepresentationForPath(v)} formatted: ${getSymbolicRepresentationForPath(
                        f
                    )})`
            )
            .join(" ")})`;
    }
}

// FIXME: it sucks that we duplicate this in `generate.ts`
class ClientExecutionContext extends ClientExecutionContextBase {
    constructor(private appEnv: ActionAppEnvironment, action: Action | Computation, plugin: Plugin | NativePlugin) {
        super(
            appEnv.appData,
            action,
            plugin,
            wrapFetchForRetries(appEnv.appFacilities.fetch, plugin, action, (msg, params) => logError(msg, params)),
            getFeatureSetting("rehostFileTimeout") ? defaultFetchTimeout : undefined
        );
    }

    public async uploadFile(name: string, mimeType: string, contents: string | ArrayBuffer): Promise<Result<string>> {
        const session = uploadFileIntoGlideStorage(
            this.appEnv.appFacilities,
            this.appEnv.appID,
            {
                name,
                type: mimeType,
                contents,
            },
            "plugin",
            undefined
        );

        const r = await session.attempt();

        if (hasOwnProperty(r, "error")) {
            return Result.Fail("Could not upload file: " + r.error);
        }

        return Result.Ok(r.path);
    }

    public consumeBillable(count?: number) {
        if (count === undefined) {
            if (typeof this.action?.billablesConsumed === "number") count = this.action.billablesConsumed;
            else if (typeof this.action?.billablesConsumed === "object") count = this.action.billablesConsumed.number;
            else count = 1;
        }
        void reportBillable(
            {
                appID: this.appEnv.appID,
                count,
                pluginID: this.plugin.fields.id,
                actionID: defined(this.action).name,
            },
            this.appEnv.appFacilities
        );
    }

    public async sendPushNotification(
        title: string,
        body: string,
        link?: string,
        emails?: readonly string[]
    ): Promise<Result> {
        const response = await this.appEnv.appFacilities.callAuthCloudFunction("sendFrontendPushNotification", {
            title,
            body,
            link,
            emails,
            appID: this.appEnv.appID,
        });
        void response?.text();
        if (isResponseOK(response)) {
            return Result.Ok();
        } else {
            // Push notifications are dead. https://github.com/glideapps/glide/issues/31159
            // if we don't get a response, we are doing to default
            // to a 4xx error instead, 410 seemed appropriate in this case
            return Result.FailFromHTTPStatus("Failed to send push notification", response?.status ?? 410);
        }
    }
}

export type PluginParameterSetter = (
    paramsObjectToMutate: JSONObject,
    value: LoadedGroundValue,
    columnType: ParameterSourceColumnType | undefined,
    isConstant: boolean
) => void;

interface ConstantPluginParameterSource {
    // This is just for debugging
    readonly name: string;
    readonly setter: PluginParameterSetter;
    readonly value: LoadedGroundValue;
}

interface BoundPluginParameterSource {
    // This is just for debugging
    readonly name: string;
    readonly setter: PluginParameterSetter;
    readonly paths: [valuePath: Path, formattedPath: Path];
    readonly kind: ParameterSourceColumnType;
}

export type PluginParameterSource = ConstantPluginParameterSource | BoundPluginParameterSource;

interface ErrorHandling {
    readonly prettyName: string;
    // Automations never throw errors, but in the frontend we want this
    // because the Data Editor will show it in the cell.  FIXME: This is
    // not the correct way to handle errors.  The Data Editor should use
    // the same mechanism as Automations.
    readonly throwError: boolean;
}

function isConstantParameterSource(s: PluginParameterSource): s is ConstantPluginParameterSource {
    return hasOwnProperty(s, "value");
}
export class PluginComputationComputation implements AsyncComputation {
    private _isClient = false;

    constructor(
        private readonly _pluginConfig: PluginConfig,
        private readonly _computationID: string,
        private readonly _paramSources: readonly PluginParameterSource[],
        private readonly _resultName: string,
        private readonly _appEnvironment: ActionAppEnvironment,
        private readonly _pluginMetadata: readonly SerializablePluginMetadata[],
        private readonly _errorHandling: ErrorHandling
    ) {
        const pm = this._pluginMetadata.find(p => p.id === this._pluginConfig.pluginID);
        const compMetadata = pm === undefined ? undefined : findAction(pm.computations, this._computationID);
        this._isClient = compMetadata?.type === "client";
    }

    public getPaths(): readonly Path[] {
        return flatten(
            mapFilterUndefined(this._paramSources, s => (isConstantParameterSource(s) ? undefined : s.paths))
        );
    }

    private makeErrorResult(r: ErrorResult, context: GroundValue): ErrorResult {
        let message = `Error in column ${this._errorHandling.prettyName}`;
        if (!isLoadingValue(context) && isRow(context)) {
            message += ` in row with ID ${context.$rowID}`;
        }
        message += `: ${r.message}`;
        return Result.Fail(message, r.data);
    }

    private convertResult(
        resolver: RootPathResolver,
        context: GroundValue,
        computation: Computation,
        r: Result<Partial<UnwrapGeneric<ParameterRecord>>>
    ) {
        if (r.ok) {
            return pluginResultToGlideType(computation.results, r.result)[this._resultName];
        } else if (this._errorHandling.throwError) {
            throw new PluginError(r.message, r.data?.showInBuilder);
        } else {
            resolver.setErrorFromComputation(this.makeErrorResult(r, context));
            return undefined;
        }
    }

    private async computeClient(
        resolver: RootPathResolver,
        context: GroundValue,
        params: JSONObject,
        formattedParams: JSONObject
    ): Promise<GroundValue | ChangeObservable<GroundValue>> {
        const plugin = await pack.getPlugin(this._pluginConfig.pluginID);
        const computation = findAction(plugin.computations, this._computationID);

        if (computation === undefined) return undefined;

        const actionID = computation.id;
        const r = await frontendTrace(
            "executePluginAction",
            { pluginID: plugin.fields.id, action: actionID },
            async () => {
                return await computation.execute(
                    new ClientExecutionContext(this._appEnvironment, computation, plugin),
                    { ...params, ...this._pluginConfig.parameters } as Record<string, any>,
                    { ...formattedParams, ...this._pluginConfig.parameters } as Record<string, string>
                );
            }
        );

        if (isResult(r)) {
            return this.convertResult(resolver, context, computation, r);
        } else {
            return new MappingChangeObservable(r, v => this.convertResult(resolver, context, computation, v));
        }
    }

    private async computeServer(
        resolver: RootPathResolver,
        context: GroundValue,
        params: JSONObject
    ): Promise<GroundValue> {
        const fromBuilder = this._appEnvironment.isBuilder;

        const actionKind = makePluginComputationKindFromIDs(this._pluginConfig.pluginID, this._computationID);

        const integrationAggregator = this._appEnvironment.appFacilities.getIntegrationsAggregator({
            actionKind,
            appID: this._appEnvironment.appID,
            deviceID: this._appEnvironment.appFacilities.deviceID,
            pluginConfigID: this._pluginConfig.configID,
            pluginParams: fromBuilder ? this._pluginConfig.parameters : undefined,
        });

        const instanceBody: RunIntegrationsInstance = {
            actionParams: params,
            instanceID: this._appEnvironment.appFacilities.makeRowID(),
            isAction: false,
            writeBackTo: {
                results: {},
                fromBuilder,
                // This is not necessarily true, but we don't need write-back
                // anyway.
                fromDataEditor: false,
                appUserID: this._appEnvironment.authenticator.appUserID,
                writeSource: this._appEnvironment.writeSource,
            },
        };

        const r = await integrationAggregator.runIntegration({ data: instanceBody });
        if (r.ok) {
            if (!hasOwnProperty(r.result, "actions")) return undefined;
            return definedMap(r.result.results?.[this._resultName], pluginValueToCellValue);
        } else if (this._errorHandling.throwError) {
            throw new PluginError(r.message, r.data?.showInBuilder);
        } else {
            resolver.setErrorFromComputation(this.makeErrorResult(r, context));
            return undefined;
        }
    }

    public compute(
        resolver: RootPathResolver,
        context: GroundValue,
        valueGetters: ComputationValueGetters
    ): GroundValue | Promise<GroundValue | ChangeObservable<GroundValue>> {
        let loadingValue: LoadingValue | undefined;

        function setParam(obj: JSONObject, s: PluginParameterSource, formatted: boolean) {
            if (isConstantParameterSource(s)) {
                s.setter(obj, s.value, undefined, true);
            } else if (s.paths !== undefined) {
                const value = valueGetters.getValueAt(resolver, context, s.paths[formatted ? 1 : 0]);
                if (isLoadingValue(value)) {
                    loadingValue = value;
                    return;
                }
                if (!isPrimitiveValue(value) && !isArrayValue(value)) return;
                s.setter(obj, value, s.kind, false);
            }
        }

        // FIXME: either only get the value we actually need (that will
        // require having the computation props), or send both

        const params: JSONObject = {};
        const formattedParams: JSONObject = {};
        for (const s of this._paramSources) {
            setParam(params, s, false);
            setParam(formattedParams, s, true);
        }

        if (loadingValue !== undefined) return loadingValue;

        if (this._isClient) {
            return this.computeClient(resolver, context, params, formattedParams);
        } else {
            return this.computeServer(resolver, context, params);
        }
    }

    public get symbolicRepresentation(): string {
        return `(plugin-computation ${this._pluginConfig.pluginID} ${this._computationID} ${
            this._resultName
        } ${mapFilterUndefined(this._paramSources, s => {
            let rhs: string;
            if (isConstantParameterSource(s)) {
                rhs = getSymbolicRepresentationForGroundValue(s.value);
            } else if (s.paths !== undefined) {
                const [v, f] = s.paths;
                rhs = `(value: ${getSymbolicRepresentationForPath(v)} formatted: ${getSymbolicRepresentationForPath(
                    f
                )})`;
            } else {
                return undefined;
            }

            return `${s.name}: ${rhs}`;
        }).join(" ")})`;
    }
}
