import type { SerializedApp } from "@glide/app-description";

import type { PluginTier, SerializablePluginMetadata } from "@glide/plugins";

import { getRequiredPlan } from "@glide/component-utils";
import { PluginPinAuthListItem } from "./plugin-pin-auth-list-item";
import React from "react";
import sortBy from "lodash/sortBy";

export interface Props extends React.PropsWithChildren {
    plugins: readonly SerializablePluginMetadata[];
    pluginConfigs: SerializedApp["pluginConfigs"];
    onConfigurePlugin: (plugin: SerializablePluginMetadata) => Promise<void>;
    currentPluginTier: PluginTier;
    onToggleClick: (
        plugin: SerializablePluginMetadata,
        sender: NonNullable<SerializablePluginMetadata["notificationSenders"]>[number],
        newValue: boolean
    ) => void;
}

export const PinAuthList: React.VFC<Props> = props => {
    const { plugins, currentPluginTier, onToggleClick, pluginConfigs, onConfigurePlugin } = props;

    const pluginsToDisplay = plugins.filter(plugin => (plugin.notificationSenders ?? []).length > 0);
    const notificationSendersMap = sortBy(
        pluginsToDisplay.flatMap(plugin => (plugin.notificationSenders ?? [])?.map(ns => ({ sender: ns, plugin }))),
        e => e.sender.priority
    );

    return (
        <>
            {notificationSendersMap.map(val => {
                const { plugin, sender } = val;
                const { icon, tier } = plugin;
                const requiredPlan = getRequiredPlan(tier, currentPluginTier);

                const config = pluginConfigs?.find(pc => pc.pluginID === plugin.id);

                const enabled =
                    config?.notificationSenders?.find(ns => ns.notificationSenderID === sender.id) !== undefined;

                return (
                    <React.Fragment key={sender.id + plugin.id}>
                        <PluginPinAuthListItem
                            onToggle={() => onToggleClick(plugin, sender, !enabled)}
                            icon={icon}
                            name={sender.name}
                            description={sender.description}
                            requiredPlan={requiredPlan}
                            onConfigureClick={
                                config !== undefined ? undefined : async () => await onConfigurePlugin(plugin)
                            }
                            enabled={enabled}
                        />
                        <div tw="border-b border-border-pale my-1"></div>
                    </React.Fragment>
                );
            })}
        </>
    );
};
