import type { GlideDateTime, GlideDateTimeDocumentData } from "./glide-date-time";
import type { GlideJSON, GlideJSONDocumentData } from "./glide-json";

export type BasePrimitiveValue = string | number | boolean;

export type PrimitiveCellValue = BasePrimitiveValue | GlideDateTime | GlideJSON;
export type CellValue = PrimitiveCellValue | readonly CellValue[];

export type SerializableCellValue =
    | BasePrimitiveValue
    | GlideDateTimeDocumentData
    | GlideJSONDocumentData
    | readonly SerializableCellValue[];

export interface ColumnCellValues {
    [column: string]: CellValue;
}

// FIXME: Comments and purchase write-backs still post a `Date` - get rid of
// this once we get rid of Classic Apps.
type LegacyPrimitiveCellValue = BasePrimitiveValue | Date | GlideDateTime | GlideJSON;
export type LegacyCellValue = LegacyPrimitiveCellValue | readonly LegacyCellValue[];

export interface LegacyColumnCellValues {
    [column: string]: LegacyCellValue;
}

export function isBasePrimitiveValue(v: unknown): v is BasePrimitiveValue {
    return typeof v === "string" || typeof v === "number" || typeof v === "boolean";
}
