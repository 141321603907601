import { type Row, type GroundValue, isLoadingValue, type QuerySort } from "@glide/computation-model-types";
import {
    asString,
    nullLoadingToUndefined,
    asBoolean,
    asMaybeArrayOfStrings,
} from "@glide/common-core/dist/js/computation-model/data";
import {
    ArrayScreenFormat,
    getActionProperty,
    getArrayProperty,
    getColumnProperty,
    getEnumProperty,
    getIconProperty,
    getNumberProperty,
    getSwitchProperty,
} from "@glide/app-description";
import {
    getTableColumn,
    getTableName,
    isPrimitiveArrayType,
    isPrimitiveType,
    type TableGlideType,
} from "@glide/type-schema";
import type {
    WireSuperTableListComponent,
    WireSuperTableRow,
    WireSuperTableCell,
    WireSuperTableBooleanCell,
    WireSuperTableButtonCell,
    WireSuperTableRowHeaderCell,
    WireActionWithTitle,
    WireSuperTableExtraActionsCell,
    WireSuperTableChoiceCell,
    WireSuperTableColumn,
    WireSuperTableTagsCell,
} from "@glide/fluent-components/dist/js/base-components";
import {
    type ConfigurableSuperTableColumnKind,
    type ConfigurableSuperTableColumnKindWithAuto,
    type SuperTableColumnDescription,
    SuperTable,
    glideTypeToSuperTableColumnMapping,
} from "@glide/fluent-components/dist/js/fluent-components";
import { type InlineListComponentDescription, emptyEditedColumnsAndTables } from "@glide/function-utils";
import {
    type WireActionHydrator,
    type WireRowComponentHydrationBackend,
    type WireTableComponentHydrationBackend,
    type WireTableComponentHydrationResult,
    type WireTableGetter,
    type WireValueGetterGeneric,
    type WireAction,
    type WireEditableValue,
    type WireAlwaysEditableValue,
    type WirePaging,
    type WirePagedGroup,
    UISize,
    ValueChangeSource,
    WireComponentKind,
    WireActionResult,
} from "@glide/wire";
import {
    applyGroupPaging,
    applyPaging,
    doTitleActionsForceComponentToShow,
    getItemsPageIndex,
    getPageSizeForPaging,
    hydrateAndRegisterAction,
    inflateActions,
    inflateActionsWithTitles,
    inflateComponentEnricher,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
} from "../wire/utils";
import { makeFluentArrayContentHandler, makeQueryGroup } from "./fluent-array-handler";
import { assertNever, defined, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import type {
    SuperTableColumn,
    SuperTableImageCell,
    SuperTableLinkCell,
    SuperTableTextCell,
} from "@glide/component-utils";
import { iterableEnumerate } from "collection-utils";
import { makeGroupByGetters } from "../array-screens/array-content";
import {
    type BaseTagOrChoiceItem,
    getBaseChoiceItems,
    inflateBaseChoiceColumn,
    makeWireTagOrChoiceItemFromBase,
    sortAndLimitQuery,
} from "./new-table-shared";
import { isArray, isEmptyOrUndefined, isUndefinedish } from "@glide/support";

interface InflatedColumn {
    readonly index: number;
    readonly headerGetter: WireValueGetterGeneric<string>;
    readonly kind: ConfigurableSuperTableColumnKind;
    // We're tracking the first text column so we can make it a row-header
    readonly isFirstText: boolean;
    readonly sizeOptions: SuperTableColumn["sizeOptions"];
    readonly columnName: string | undefined;
    readonly isEditable: boolean;
    readonly valueGetter: WireValueGetterGeneric<GroundValue>;
    readonly formattedValueGetter: WireValueGetterGeneric<string>;
    readonly displayValueGetter: WireValueGetterGeneric<string>;
    readonly onClickActionGetter: WireActionHydrator | WireActionResult;
    readonly buttonIcon: string | undefined;
    readonly buttonStyle: "Default" | "Accent";
    readonly choiceSourceGetter: WireTableGetter | undefined;
    readonly choiceSourceTableType: TableGlideType | undefined;
    readonly choiceValuesGetter: WireValueGetterGeneric<string> | undefined;
    readonly choiceColorGetter: WireValueGetterGeneric<string> | undefined;
    readonly tagColorKind: "Auto" | "Manual";
    readonly choiceDisplayGetter: WireValueGetterGeneric<string> | undefined;
    readonly choiceTokenMaker: (hb: WireRowComponentHydrationBackend) => string | false | undefined;
    readonly isMultiChoice: boolean;
    readonly accessoryImageGetter: WireValueGetterGeneric<string>;
    // We don't support sorting by computed columns on GBT. We use this to prevent sorting on them.
    readonly canSort: boolean;
}

interface ColumnWithChoiceOptions extends InflatedColumn {
    readonly options: BaseTagOrChoiceItem[];
}

function hydrateWireSuperTableCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number,
    itemClickAction: WireAction | undefined,
    appID: string
): WireSuperTableCell {
    // Old configurations can still have the `number` type. We'll just hydrate a text cell.
    const kind = c.kind as ConfigurableSuperTableColumnKind | "number";
    if (c.isFirstText && itemClickAction !== undefined) {
        return hydrateWireSuperTableRowHeaderCell(rhb, c, itemClickAction);
    }

    switch (kind) {
        case "number":
        case "text":
            return hydrateWireSuperTableTextCell(rhb, c, appID);

        case "boolean": {
            return hydrateWireSuperTableBooleanCell(rhb, c, rowIdx);
        }

        case "image": {
            return hydrateWireSuperTableImageCell(rhb, c);
        }

        case "link": {
            return hydrateWireSuperTableLinkCell(rhb, c);
        }

        case "button": {
            return hydrateWireSuperTableButtonCell(rhb, c, rowIdx);
        }

        case "choice": {
            return hydrateWireSuperTableChoiceCell(rhb, c, rowIdx);
        }

        case "tag": {
            return hydrateWireSuperTableTagsCell(rhb, c, rowIdx);
        }

        default: {
            return assertNever(kind);
        }
    }
}

function makeOnChangeToken(
    rhb: WireRowComponentHydrationBackend,
    columnName: string | undefined,
    rowIdx: number
): string | undefined {
    const maybeFalseyOnChangeToken = definedMap(columnName, n =>
        rhb.registerOnValueChange(`set-${columnName}-${rowIdx}`, n)
    );

    const onChangeToken = maybeFalseyOnChangeToken === false ? undefined : maybeFalseyOnChangeToken;

    return onChangeToken;
}

function hydrateWireSuperTableRowHeaderCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    primaryAction: WireAction
): WireSuperTableRowHeaderCell {
    const value = c.formattedValueGetter(rhb) ?? "";
    const accessoryImage = c.accessoryImageGetter(rhb) ?? undefined;

    return {
        kind: "row-header",
        value,
        action: primaryAction,
        accessoryImage,
    };
}

function hydrateWireSuperTableTextCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    appID: string
): SuperTableTextCell {
    const value = c.formattedValueGetter(rhb) ?? "";
    const accessoryImage = c.accessoryImageGetter(rhb) ?? undefined;

    return {
        kind: "text",
        value: value,
        accessoryImage,
        appID,
    };
}

function hydrateWireSuperTableBooleanCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number
): WireSuperTableBooleanCell {
    const value = asBoolean(nullLoadingToUndefined(c.valueGetter(rhb)));

    const { columnName, isEditable } = c;

    const editableValue: WireEditableValue<boolean> = {
        value: value,
        onChangeToken: isEditable ? makeOnChangeToken(rhb, columnName, rowIdx) : undefined,
    };

    return {
        kind: "boolean",
        editableValue,
    };
}

function hydrateWireSuperTableImageCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions
): SuperTableImageCell {
    const loaded = nullLoadingToUndefined(c.valueGetter(rhb));
    const value: string | string[] = asMaybeArrayOfStrings(loaded) ?? asString(loaded);

    return {
        kind: "image",
        value,
    };
}

function hydrateWireSuperTableLinkCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions
): SuperTableLinkCell {
    const value = asString(nullLoadingToUndefined(c.valueGetter(rhb)));
    const formattedValue = c.formattedValueGetter(rhb);
    const displayValue = c.displayValueGetter(rhb);

    return {
        kind: "link",
        value,
        displayValue: displayValue ?? formattedValue ?? value,
    };
}

function hydrateWireSuperTableButtonCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number
): WireSuperTableButtonCell {
    const value = c.formattedValueGetter(rhb) ?? "";
    const action = hydrateAndRegisterAction(
        `onClick-${c.index}-${rowIdx}`,
        c.onClickActionGetter,
        rhb,
        false,
        undefined
    );

    return {
        kind: "button",
        label: value,
        action,
        icon: c.buttonIcon,
        style: c.buttonStyle,
    };
}

function hydrateWireSuperTableChoiceCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number
): WireSuperTableChoiceCell {
    const rawValue = nullLoadingToUndefined(c.valueGetter(rhb));
    const value = isArray(rawValue)
        ? rawValue.map(v => asString(v))
        : asString(rawValue)
              .split(",")
              .filter(v => !isEmptyOrUndefined(v));

    const options = makeWireTagOrChoiceItemFromBase(rhb, c, c.options, value, c.isMultiChoice, rowIdx);

    return {
        kind: "choice",
        value,
        isEditable: c.isEditable,
        options,
        isMulti: c.isMultiChoice,
    };
}

function hydrateWireSuperTableTagsCell(
    rhb: WireRowComponentHydrationBackend,
    c: ColumnWithChoiceOptions,
    rowIdx: number
): WireSuperTableTagsCell {
    const rawValue = nullLoadingToUndefined(c.valueGetter(rhb));
    const value = isArray(rawValue)
        ? rawValue.map(v => asString(v))
        : asString(rawValue)
              .split(",")
              .filter(v => !isEmptyOrUndefined(v));

    const options = makeWireTagOrChoiceItemFromBase(rhb, c, c.options, value, c.isMultiChoice, rowIdx);

    return {
        kind: "tag",
        value,
        options,
        tagColorKind: c.tagColorKind,
        isEditable: c.isEditable,
        isMulti: c.isMultiChoice,
    };
}

function hydrateWireSuperTableExtraActionsCell(
    extraActions: readonly WireActionWithTitle[]
): WireSuperTableExtraActionsCell {
    return {
        kind: "extra-actions",
        value: undefined,
        actions: extraActions,
    };
}

function getNextSortByKeyState(currentState: WireAlwaysEditableValue<QuerySort | undefined>, wantedColumnName: string) {
    if (currentState.value === undefined) {
        return { columnName: wantedColumnName, order: "asc" };
    }

    if (currentState.value.columnName === wantedColumnName && currentState.value.order === "asc") {
        return { columnName: wantedColumnName, order: "desc" };
    }

    if (currentState.value.columnName !== wantedColumnName) {
        return { columnName: wantedColumnName, order: "asc" };
    }

    return undefined;
}

export const makeSortByKeyAction = (
    thb: WireTableComponentHydrationBackend,
    columnName: string,
    sortByKeyState: WireAlwaysEditableValue<QuerySort | undefined>
): WireAction => {
    const token = thb.registerAction(`sort-by-${columnName}`, async ab => {
        const nextState = getNextSortByKeyState(sortByKeyState, columnName);
        return ab.valueChanged(sortByKeyState.onChangeToken, nextState, ValueChangeSource.User);
    });

    return { token };
};

const emptyPaging: WirePaging = {
    pageSize: 0,
    numPages: 0,
    itemsCount: 0,
    pageIndex: {
        value: 0,
        onChangeToken: undefined,
    },
};

export const superTableFluentArrayContentHandler = makeFluentArrayContentHandler(
    SuperTable,
    (ib, desc, containingRowIB) => {
        if (containingRowIB === undefined) return undefined;
        const columnDescs = getArrayProperty<SuperTableColumnDescription>(desc.columns);
        const [componentTitleGetter] = inflateStringProperty(containingRowIB, desc.title, true);
        const [titleActionsHydrator] = inflateActionsWithTitles(containingRowIB, desc.titleActions, "title");

        const style = getEnumProperty<"minimal" | "striped">(desc.style) ?? "minimal";

        const itemAction = getActionProperty(desc.action);
        const itemActionGetter = definedMap(itemAction, x => inflateActions(ib, [x])) ?? WireActionResult.nothingToDo();

        const [itemActionsHydrator, configuredItemActions] = inflateActionsWithTitles(ib, desc.itemActions, "item");

        if (columnDescs === undefined || columnDescs.length === 0) {
            return undefined;
        }

        const groupByGetters = makeGroupByGetters(ib, desc, SuperTable.spec.groupingSupport);

        // Everything needed to register the actions and hydrate values
        const inflatedColumns: InflatedColumn[] = [];

        // We want to mark the first text column so we can make it a row-header column
        let alreadyInflatedATextColumn = false;

        for (const [key, c] of iterableEnumerate(columnDescs)) {
            const viewOrEdit = getEnumProperty<"View" | "Edit">(c.viewOrEdit) ?? "View";

            const {
                valueGetter,
                formattedValueGetter,
                type,
                choiceSourceGetter,
                choiceSourceTableType,
                choiceValuesGetter,
                choiceColorGetter,
                choiceDisplayGetter,
                columnName,
                canSort,
                choiceTokenMaker,
                isEditable,
            } = inflateBaseChoiceColumn(ib, containingRowIB.tables, {
                value: c.value,
                choiceSource: c.choiceSource,
                choiceValues: c.choiceValues,
                choiceColor: c.choiceColor,
                choiceDisplay: c.choiceDisplay,
                viewOrEdit,
            });

            const [displayValueGetter] = inflateStringProperty(ib, c.displayValue, true);
            const tagColorKind = getEnumProperty<"Auto" | "Manual">(c.tagColor) ?? "Auto";

            const isMultiChoice = getSwitchProperty(c.isMultiChoice) ?? false;
            const [accessoryImageGetter] = inflateStringProperty(ib, c.accessoryImage, true);

            const onClickAction = getActionProperty(c.onClick);
            const onClickActionGetter =
                definedMap(onClickAction, x => inflateActions(ib, [x])) ?? WireActionResult.nothingToDo();
            const buttonIcon = getIconProperty(c.buttonIcon);
            const buttonStyle = getEnumProperty<"Default" | "Accent">(c.buttonStyle) ?? "Default";

            const choosenKind = getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(c.kind);
            // We allow button columns with only icons. Empty values have undefined type.
            const isAllowedEmptyType = choosenKind === "button" && type === undefined && buttonIcon !== undefined;

            const typeIsValid =
                isAllowedEmptyType || isPrimitiveType(type) || (type !== undefined && isPrimitiveArrayType(type));

            if (!typeIsValid) continue;

            const [headerGetter] = inflateStringProperty(containingRowIB, c.header, true);

            const kind = glideTypeToSuperTableColumnMapping(type, choosenKind);
            const sizeKind = getEnumProperty<SuperTableColumn["sizeOptions"]["sizeKind"]>(c.sizeKind);
            const fixedWidthColumnSize = getEnumProperty<UISize>(c.widthSize);

            if (kind === undefined || sizeKind === undefined) {
                // This will never happen. This is just a TS and fluent component thing.
                continue;
            }

            const sizeOptions: SuperTableColumn["sizeOptions"] =
                sizeKind === "auto"
                    ? {
                          sizeKind,
                      }
                    : {
                          sizeKind,
                          size: fixedWidthColumnSize ?? UISize.Medium,
                      };

            inflatedColumns.push({
                index: key,
                headerGetter,
                kind,
                isFirstText: kind === "text" && !alreadyInflatedATextColumn,
                sizeOptions,
                columnName,
                isEditable,
                valueGetter,
                formattedValueGetter,
                displayValueGetter,
                onClickActionGetter,
                buttonIcon,
                buttonStyle,
                choiceSourceGetter,
                choiceSourceTableType,
                choiceValuesGetter,
                tagColorKind,
                choiceColorGetter,
                choiceDisplayGetter,
                canSort,
                choiceTokenMaker,
                isMultiChoice,
                accessoryImageGetter,
            });

            if (kind === "text") {
                alreadyInflatedATextColumn = true;
            }
        }

        if (inflatedColumns.length === 0) {
            return undefined;
        }

        const componentEnricher = inflateComponentEnricher<WireSuperTableListComponent>(
            ib,
            desc as unknown as InlineListComponentDescription
        );

        function makeComponent(
            groups: WirePagedGroup<Row>[],
            thb: WireTableComponentHydrationBackend,
            chb: WireRowComponentHydrationBackend,
            paging: WirePaging,
            shouldSort: boolean
        ): WireTableComponentHydrationResult {
            const title = componentTitleGetter(chb);

            const columnsWithOptions = inflatedColumns.map(c => {
                const col: ColumnWithChoiceOptions = {
                    ...c,
                    options: getBaseChoiceItems(chb, chb, c),
                };

                return col;
            });

            const gridRows: WirePagedGroup<WireSuperTableRow>[] = groups.map(group => {
                return {
                    groupKey: group.groupKey,
                    title: group.title,
                    paging: group.paging,
                    items: group.items.map((row, rowIdx) => {
                        const rhb = thb.makeHydrationBackendForRow(row);
                        const itemActions = itemActionsHydrator?.(rhb, row.$rowID) ?? [];
                        const activeItemActions = itemActions.filter(i => !isUndefinedish(i.action));
                        const itemClickAction = hydrateAndRegisterAction(
                            "itemAction",
                            itemActionGetter,
                            rhb,
                            false,
                            undefined
                        );

                        const wireRow: WireSuperTableRow = [];
                        for (const c of columnsWithOptions) {
                            wireRow.push(hydrateWireSuperTableCell(rhb, c, rowIdx, itemClickAction, ib.adc.appID));
                        }

                        // Extra actions go in the last column's menu, make cells for it
                        if (activeItemActions.length > 0) {
                            wireRow.push(hydrateWireSuperTableExtraActionsCell(activeItemActions));
                        }

                        return wireRow;
                    }),
                };
            });
            const sortByKey = thb.getState<QuerySort | undefined>("sortByKey", undefined, undefined, false);
            const columns: WireSuperTableColumn[] = columnsWithOptions.map(c => {
                const { kind, headerGetter, sizeOptions, columnName, canSort } = c;

                const header = headerGetter(chb) ?? "";

                const headerAction =
                    shouldSort && canSort ? makeSortByKeyAction(thb, columnName ?? "", sortByKey) : undefined;

                return {
                    kind,
                    header,
                    sizeOptions,
                    headerAction,
                    columnName,
                    canSort,
                };
            });

            // Don't forget to add the column for the extra actions menu
            if (configuredItemActions > 0) {
                columns.push({
                    kind: "extra-actions",
                    header: "",
                    sizeOptions: {
                        sizeKind: "fixed",
                        size: UISize.XSmall,
                    },
                    headerAction: undefined,
                    columnName: undefined,
                });
            }

            const titleActions = titleActionsHydrator?.(chb, "") ?? [];

            const component: WireSuperTableListComponent = componentEnricher({
                kind: WireComponentKind.List,
                format: ArrayScreenFormat.SuperTable,
                columns,
                groups: gridRows,
                title,
                titleActions,
                paging,
                activeSort: sortByKey.value,
                style,
            });

            return {
                component,
                isValid: true,
            };
        }

        return makeSimpleWireTableComponentHydratorConstructor(
            ib,
            (thb, chb, searchActive) => {
                if (chb === undefined) {
                    return undefined;
                }

                const rows = thb.tableScreenContext.asArray();

                const titleActions = titleActionsHydrator?.(chb, "") ?? [];
                const showIfEmpty = doTitleActionsForceComponentToShow(titleActions);

                if (rows.length === 0 && !searchActive && !showIfEmpty) {
                    return undefined;
                }
                const pageSize = getNumberProperty((desc as unknown as InlineListComponentDescription).pageSize);
                const selectedPageSize = defined(getPageSizeForPaging(pageSize, 24, false));
                const pageIndexState = getItemsPageIndex(chb, false, "");
                const [paging, pagedRows] = applyPaging(rows, pageIndexState, selectedPageSize);
                const group: WirePagedGroup<Row> = {
                    title: "",
                    items: pagedRows,
                    paging,
                    groupKey: "",
                };
                return makeComponent([group], thb, chb, emptyPaging, false);
            },
            (chb, query, thb, searchActive, _pageIndexState, selectedPageSize) => {
                if (thb === undefined) {
                    return undefined;
                }
                const pageIndexState = getItemsPageIndex(chb, false, "");
                const { maybeGroupAggregateQuery, newTableSort } = sortAndLimitQuery(
                    ib,
                    query,
                    thb,
                    selectedPageSize,
                    pageIndexState,
                    groupByGetters,
                    desc.transforms,
                    SuperTable
                );

                const rowGroups = chb.resolveQueryAsTable(maybeGroupAggregateQuery);

                const hasNoRowGroups = rowGroups === undefined || isLoadingValue(rowGroups);

                const titleActions = titleActionsHydrator?.(chb, "") ?? [];
                const showIfEmpty = doTitleActionsForceComponentToShow(titleActions);

                if (hasNoRowGroups && !searchActive && !showIfEmpty) {
                    return undefined;
                }

                if (hasNoRowGroups) {
                    return makeComponent([], thb, chb, emptyPaging, true);
                }

                const rows = rowGroups.asArray();

                let groups: WirePagedGroup<Row>[];

                const groupings = applyGroupPaging(thb, rows);
                let groupPaging = groupings[0];
                const visibleRowGroups = groupings[1];
                if (groupByGetters !== undefined) {
                    groups = makeQueryGroup({
                        chb,
                        groupByGetters,
                        query,
                        queryableTableSupport: SuperTable.spec.queryableTableSupport,
                        selectedPageSize,
                        thb,
                        visibleRowGroups,
                        sort: newTableSort.length > 0 ? newTableSort : undefined,
                    });
                } else {
                    const [paging, visibleRows] = applyPaging(rows, pageIndexState, selectedPageSize);
                    groups = [
                        {
                            title: "",
                            items: visibleRows,
                            paging,
                            groupKey: "",
                        },
                    ];
                    groupPaging = emptyPaging;
                }

                if (groups === undefined) return undefined;

                return makeComponent(groups, thb, chb, groupPaging, true);
            }
        );
    },
    (desc, _tables, itemTable, _adc) => {
        let edited = emptyEditedColumnsAndTables;

        if (itemTable === undefined) return edited;

        const columnDescs = getArrayProperty<SuperTableColumnDescription>(desc.columns) ?? [];

        edited = {
            ...edited,
            editedColumns: mapFilterUndefined(columnDescs, c => {
                const viewOrEdit = getEnumProperty<"View" | "Edit">(c.viewOrEdit) ?? "View";
                const isEditable =
                    viewOrEdit === "Edit" ||
                    // choice is always editable
                    getEnumProperty<ConfigurableSuperTableColumnKindWithAuto>(c.kind) === "choice";

                if (!isEditable) return undefined;

                const columnName = getColumnProperty(c.value);

                if (columnName === undefined) return undefined;

                const column = getTableColumn(itemTable, columnName);
                if (column === undefined) return undefined;

                return [columnName, false, false, getTableName(itemTable)];
            }),
        };

        return edited;
    }
);
export { sortAndLimitQuery };
