import type { APICapabilities } from "@glide/plugins";
import { reduceTwo } from "@glideapps/ts-necessities";

export const noAPICapabilities: APICapabilities = {
    requestReload: false,
    mutateTables: false,
    queryTables: false,
    createAnonymousUser: false,
};

export function combineAPICapabilities(
    caps1: APICapabilities | undefined,
    caps2: APICapabilities | undefined
): APICapabilities {
    return (
        reduceTwo(caps1, caps2, (a, b) => ({
            mutateTables: a.mutateTables && b.mutateTables,
            queryTables: a.queryTables && b.queryTables,
            requestReload: a.requestReload && b.requestReload,
            createAnonymousUser: a.createAnonymousUser && b.createAnonymousUser,
        })) ?? noAPICapabilities
    );
}
