import type { AppFeatures } from "@glide/app-description";
import { asAppLoginTokenContainer } from "@glide/common-core/dist/js/firebase-function-types";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import { defined } from "@glideapps/ts-necessities";
import {
    getAllowLoginSaveKey,
    getEmailKey,
    getLoginTokenKey,
    getPasswordForEmailKey,
    getPasswordKey,
    getPinKey,
    getUsernameKey,
    localStorageGetItem,
    localStorageRemoveItem,
} from "@glide/support";
import * as b64 from "base64-arraybuffer";
import { createContext, useContext } from "react";

import type { AppCredentials } from "./app-container-types";

export function getLocalEmailLoginCredentials(
    appID: string
): { email: string; emailPassword: string | undefined } | undefined {
    const email = localStorageGetItem(getEmailKey(appID));
    const emailPassword = localStorageGetItem(getPasswordForEmailKey(appID));
    if (email === undefined) {
        return undefined;
    }
    return { email, emailPassword };
}

export function getLocalAppLoginToken(appID: string): AppLoginTokenContainer | undefined {
    const maybeToken = localStorageGetItem(getLoginTokenKey(appID));
    if (maybeToken === undefined) return undefined;

    try {
        return asAppLoginTokenContainer(JSON.parse(maybeToken));
    } catch {
        return undefined;
    }
}

export function getAppLoginTokenExpirationTimestamp(token: AppLoginTokenContainer): number {
    if (token.authOnlyData === undefined) return 0;
    try {
        // This is where the frontend decodes the ##authOnlyData.
        const arrayBuf = b64.decode(token.authOnlyData);
        return new DataView(arrayBuf).getFloat64(0, false);
    } catch {
        return 0;
    }
}

export function clearAppPasswordLocalStorage(appID: string): void {
    // Theoretically a user could be blacklisted for a password-authed
    // app to which they're logged in via email.  I don't think the
    // backend supports blacklists in those cases, but who knows
    // what happens?  We just delete all passwords.
    localStorageRemoveItem(getPasswordKey(appID));
    localStorageRemoveItem(getPasswordForEmailKey(appID));
    localStorageRemoveItem(getEmailKey(appID));
    localStorageRemoveItem(getPinKey(appID));
    localStorageRemoveItem(getUsernameKey(appID));
    localStorageRemoveItem(getLoginTokenKey(appID));
}

export function shouldSaveLogin(appID: string, appFeatures: AppFeatures): boolean {
    // 3 possible states where we should save login

    // 1. The ask user feature is not set. We should save
    const featureSet = appFeatures.askUserToSaveAuthCookie === true;
    if (!featureSet) return true;

    const notOpted = localStorageGetItem(getAllowLoginSaveKey(appID)) === undefined;
    const optedIn = localStorageGetItem(getAllowLoginSaveKey(appID)) === "true";
    // 2. The user has never opted but the feature is set. We should save and pop the modal to allow clearing
    //  stored creds after the fact.
    // 3. The user has opted in.
    return notOpted || optedIn;
}

// We don't care so much if these fail to load, so we won't
// be using the automatic page-reloading lazyLoading function.
export async function tryRegisterOnPushTokenLoadCallback(handler: (token: string) => Promise<void>): Promise<void> {
    const { registerOnPushTokenLoadCallback } = await import("@glide/firebase-stuff");
    return registerOnPushTokenLoadCallback(handler);
}

export async function tryUnregisterOnPushTokenLoadCallback(handler: (token: string) => Promise<void>): Promise<void> {
    const { unregisterOnPushTokenLoadCallback } = await import("@glide/firebase-stuff");
    return unregisterOnPushTokenLoadCallback(handler);
}

const CredentialsContext = createContext<AppCredentials | undefined>(undefined);

export const CredentialsProvider = CredentialsContext.Provider;
export const CredentialsConsumer = CredentialsContext.Consumer;

export function useCredentials(): AppCredentials {
    return defined(useContext(CredentialsContext), "No credentials context found.");
}
