import type { AppLoginAuthData } from "@glide/common-core/dist/js/Database";
import { defaultAppDescriptionText } from "@glide/common-core/dist/js/components/SerializedApp";

// Copy-pasted from the original GlideAppPlayer
export function setAppMetadata(login: AppLoginAuthData): void {
    const serializedTitle = login.title;
    const { primaryAccentColor } = login.theme;
    const appName = serializedTitle !== undefined ? serializedTitle : "Glide";
    let data = login.manifest;
    if (data === undefined) {
        const iconUrl = `https://via.placeholder.com/256/${primaryAccentColor.replace(
            "#",
            ""
        )}/ffffff?text=${appName.substr(0, 1)}`;
        data = {
            name: appName,
            short_name: appName,
            display: "standalone",
            description: defaultAppDescriptionText,
            start_url: window.location.href,
            background_color: "#FFFFFF",
            theme_color: primaryAccentColor,
            icons: [
                {
                    src: iconUrl,
                    sizes: "256x256",
                    type: "image/png",
                },
            ],
        };
    }

    const appleTouchIcon = window.document.querySelector("#apple-touch-icon-placeholder");
    if (appleTouchIcon !== null && data.icons.length > 0) {
        // Icons must be specified smallest to biggest. Here we want the biggest icon possible because ipads.
        appleTouchIcon.setAttribute("href", data.icons[data.icons.length - 1].src);
    }

    const { glidePWAAddToHead } = data;
    if (glidePWAAddToHead !== undefined) {
        data = { ...data };
        delete data.glidePWAAddToHead;

        const head = document.getElementsByTagName("head")[0];
        if (head !== undefined) {
            // TODO: This unfortunately needs to be tested in staging since it's PWA related.
            // Hopefully, Apple can fix this bug and frankly I'm not sure this hack is even going to work.
            // https://github.com/elegantapp/pwa-asset-generator/issues/93
            if (window.innerWidth > window.innerHeight) {
                // if we are in landscape mode, filter out all the <link tags> that contain "portrait"
                const linkTags = glidePWAAddToHead.split("<link");
                const onlyLandscapeLinkTags = linkTags.filter(tag => !tag.includes("portrait"));
                head.insertAdjacentHTML("beforeend", onlyLandscapeLinkTags.join("<link"));
            } else {
                // if we are portrait then it's fine to include landscape since portrait comes first.
                head.insertAdjacentHTML("beforeend", glidePWAAddToHead);
            }
        }
    }
}
