import type { CoreTheme } from "@glide/theme";
import type { OverlayTheme } from "../overlay-theme";
import { darkAppCoreTheme } from "./dark-theme-overlays/dark-app-theme";

const pureBlackAppCoreTheme: CoreTheme = {
    ...darkAppCoreTheme,

    n0: "#000000",
    n0A: "rgba(0, 0, 0, 0)",
    n100: "#171A21",
    n100A: "rgba(225,225,255,0.08)",
    n200: "#1E222B",
    n200A: "rgba(255,255,255,0.12)",
    n300: "#2C2C30",
    n300A: "rgba(225,225,225,0.2)",
    n400: "#333333",
    n400A: "rgba(225,225,225,0.2)",
    n500: "#666666",
    n500A: "rgba(255,255,255,0.4)",
    n600: "#999999",
    n600A: "rgba(255,255,255,0.6)",
    n700: "#BEBEBE",
    n700A: "rgba(255,255,255,0.75)",
    n800: "#F1F1F1",
    n800A: "rgba(255,255,255,0.9)",
    n900: "#FFFFFF",

    OSstatusBar: "#000",

    textDark: "_n800A",
    textBase: "_n700",
};

export const pureBlackOverlay: OverlayTheme = {
    overlayName: "PureBlackTheme",

    ...pureBlackAppCoreTheme,

    fgColorLight: "_n500A",
    fgColorMedium: "_n600",
    fgColorDark: "_n800A",
    fgColorOpaque: "_n900",
    baseColor: "_n0",
    offBaseColor: "_n400",
    skeletonColor: "_n100",
    linkColor: "#5db7ef",
    separatorColor: "_n200A",
    primaryAccentColor: "#FFFFFF",
    accentForegroundColor: "#000000",

    primaryOverlayColor: "#000000F9",
    transparentOverlayColor: "#000000C9",

    mapTheme: "dark",

    avoidColors: [],

    statusBar: {
        color: "#fff",
    },

    cards: {
        bgColor: "_offBaseColor",
    },

    calendar: {
        pastEventColor: "_n200A",
        pastEventAccent: "#6c6c6c",
    },

    homeBar: {
        color: "#fff",
    },

    textField: {
        underlineColor: "rgba(255, 255, 255, 0.15)",
    },

    list: {
        topMargin: 0,
    },

    listItem: {
        selectedColor: "_n200A",
        selectedColorOpaque: "rgba(32, 32, 32, 1)",
        textDarkenAmount: -25,

        actionImage: {
            color: "black",
        },
    },

    picker: {
        backgroundColor: "_n200A",
        hoverBgColor: "rgba(255, 255, 255, 0.14)",
        activeBgColor: "rgba(255, 255, 255, 0.16)",
    },

    navBar: {
        titleColor: "#ffffff",
        fgColor: "#ffffff",
        bgColor: "#000",
        transparentBgColor: "#000",
        fadeTo: "black",
        cancelColor: "#ffffff",

        largeTitleHeight: 56,
    },

    button: {
        filledTextColor: "black",
    },

    map: {
        locationButtonForegroundColor: "#000000",
    },

    searchBar: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
    },

    signature: {
        invert: true,
    },

    switch: {
        compact: {
            backgroundColor: "#FFFFFF",
        },
        big: {
            selectedBackgroundColor: "rgba(102, 102, 102, 1)",
            borderColor: "rgba(255, 255, 255, 0.24)",
        },
    },

    formSubmissionSuccessToast: {
        background: "rgba(25, 25, 25, 0.96)",
        translucentBackground: "rgba(18, 18, 18, 0.6)",
        checkmark: "white",
        labelColor: "#FFFFFF",
    },

    flyout: {
        overlayColor: "#00000099",
        bgColor: "black",
        headerFg: "white",
        headerBg: "black",
        headerBorderColor: "#E0E0E033",
        rightBorderColor: "#E0E0E033",
        headerImgColor: "rgba(255, 255, 255, 0.3)",
    },

    expandableOptions: {
        modal: {
            bgColor: "#14171E",
        },
        options: {
            bgColorUnselected: "#22252C",
            fgColorSelected: "#000000",
        },
        button: {
            fgColorUnselected: "#999999",
            bgColorUnselected: "#22252C",
            fgColorSelected: "#000000",
        },
    },

    materialDateTimePicker: {
        borderRadius: "12",
        borderWidth: "2",
        borderColor: "#1F222B",
        borderStyle: "solid",

        cancelConfirm: {
            fg: "#FFFFFF",
            bg: "#000000",
        },
    },
};
