import {
    type GlideDateTimeDocumentData,
    type GlideJSONDocumentData,
    convertValueFromSerializable,
    GlideDateTime,
    GlideJSON,
    isGlideDateTimeDocumentData,
    isGlideJSONDocumentData,
} from "@glide/data-types";
import { asMaybeString, asMaybeDate, asString } from "@glide/computation-model-types";
import { asMaybeNumber, asMaybeBoolean, asBoolean } from "@glide/common-core/dist/js/type-conversions";
import type { PluginTableSchema, PluginPrimitiveValue } from "@glide/plugins";
import { pluginColumnDefinitionCodec } from "@glide/plugins-codecs";
import {
    type FilterCondition,
    type SpecialDateTimeValue,
    UnaryPredicateFormulaOperator,
    BinaryPredicateFormulaOperator,
    BinaryPredicateCompositeOperator,
} from "@glide/type-schema";
import { isArray, isEmpty, isEmptyOrUndefined, isUndefinedOrNaN, normalizeEmailAddress } from "@glide/support";
import { assert, assertNever, hasOwnProperty, mapFilterUndefined } from "@glideapps/ts-necessities";

export type PluginTableColumn = PluginTableSchema["columns"][0];

function numberToString(x: number): string {
    return x.toString();
}

function getCanonicalDateISOString(d: Date): string {
    // This is how `as_string_canonical` implements it
    return d.toISOString().substring(0, 19) + "Z";
}

// This is the equivalent of `as_string_canonical` in the GBT database (or `as_string_diligent` if diligent is true)
function asStringForArrayOverlap(x: unknown, diligent: boolean = false): string | undefined {
    x = convertValueFromSerializable(x);
    if (x instanceof Date) return getCanonicalDateISOString(x);
    if (x instanceof GlideDateTime) {
        const repr = diligent ? x.getRepr() : undefined;
        return repr ?? getCanonicalDateISOString(x.asTimeZoneAwareDate());
    }
    return asMaybeString(x, numberToString);
}

function getDateValue(gdt: GlideDateTime, c: PluginTableColumn): GlideDateTime {
    return c.type === "dateTime" && c.timezoneAware === true
        ? GlideDateTime.fromDocumentData({ ...gdt.toDocumentData(), tzOffset: 0 })
        : gdt.toOriginTimeZoneAgnostic();
}

// NOTE: This isn't (meant to be) fast.
export function evaluateBinaryForType<T>(
    lhsValue: PluginPrimitiveValue,
    rhsValue: PluginPrimitiveValue,
    lhsColumn: PluginTableColumn | PluginTableColumn["type"],
    rhsColumn: PluginTableColumn | undefined,
    forString: (l: string, r: string) => T,
    forNumber: (l: number, r: number) => T,
    forBoolean: (l: boolean, r: boolean) => T,
    forDateTime: (l: GlideDateTime, r: GlideDateTime) => T,
    defaultValue: T, // this is used for duration, array, and table-ref types, and if the applicable default is not specified in the next arg
    specificDefaults: {
        lhsDefault: T; // default for when lhsValue is undefined or the wrong type
        rhsDefault: T; // default for when rhsValue is undefined or the wrong type
        bothDefault: T; // default for when both lhsValue and rhsValue are undefined or the wrong type
    } = {
        lhsDefault: defaultValue,
        rhsDefault: defaultValue,
        bothDefault: defaultValue,
    }
): T {
    const typeKind = pluginColumnDefinitionCodec.is(lhsColumn) ? lhsColumn.type : lhsColumn;
    if (typeKind === "dateTime") {
        assert(pluginColumnDefinitionCodec.is(lhsColumn));
        // FIXME: We won't be able to do everything this conversion is
        // doing in SQL.
        const l = asMaybeDate(lhsValue);
        const r = asMaybeDate(rhsValue);
        if (l === undefined && r === undefined) return specificDefaults.bothDefault;
        if (l === undefined) return specificDefaults.lhsDefault;
        if (r === undefined) return specificDefaults.rhsDefault;
        return forDateTime(getDateValue(l, lhsColumn), getDateValue(r, rhsColumn ?? lhsColumn));
    }
    if (typeKind === "string" || typeKind === "url" || typeKind === "enum") {
        const l = asMaybeString(lhsValue, numberToString);
        const r = asMaybeString(rhsValue, numberToString);
        // Glide treats empty and undefined strings the same way
        if (isEmptyOrUndefined(l) && isEmptyOrUndefined(r)) return specificDefaults.bothDefault;
        if (isEmptyOrUndefined(l)) return specificDefaults.lhsDefault;
        if (isEmptyOrUndefined(r)) return specificDefaults.rhsDefault;
        return forString(l, r);
    }
    if (typeKind === "number") {
        const l = asMaybeNumber(lhsValue);
        const r = asMaybeNumber(rhsValue);
        // Glide treats NaN and undefined the same way
        if (isUndefinedOrNaN(l) && isUndefinedOrNaN(r)) return specificDefaults.bothDefault;
        if (isUndefinedOrNaN(l)) return specificDefaults.lhsDefault;
        if (isUndefinedOrNaN(r)) return specificDefaults.rhsDefault;
        return forNumber(l, r);
    }
    switch (typeKind) {
        case "boolean": {
            const l = asMaybeBoolean(lhsValue);
            const r = asMaybeBoolean(rhsValue);
            if (l === undefined && r === undefined) return specificDefaults.bothDefault;
            if (l === undefined) return specificDefaults.lhsDefault;
            if (r === undefined) return specificDefaults.rhsDefault;
            return forBoolean(l, r);
        }
        case "array":
        case "stringArray":
        case "secret":
        case "object":
        case "stringObject":
        case "json":
        case "jsonObject":
            return defaultValue;
        default:
            return assertNever(typeKind);
    }
}

interface FilterColumn {
    columnName: string;
}

function isFilterColumn(x: unknown): x is FilterColumn {
    return hasOwnProperty(x, "columnName") && typeof x.columnName === "string";
}

type QueryDataValue = null | boolean | string | number | GlideDateTimeDocumentData | GlideJSONDocumentData;

export function asPrimitive(v: unknown): PluginPrimitiveValue {
    if (
        typeof v === "boolean" ||
        typeof v === "number" ||
        typeof v === "string" ||
        v instanceof GlideDateTime ||
        v instanceof GlideJSON
    ) {
        return v;
    }
    if (isGlideDateTimeDocumentData(v)) {
        return GlideDateTime.fromDocumentData(v);
    }
    if (isGlideJSONDocumentData(v)) {
        return GlideJSON.fromDocumentData(v);
    }
    return null;
}

export type ColumnValueGetter = (columnName: string) => unknown;

export function isConditionTrueForRow(
    condition: FilterCondition,
    columnValueGetter: ColumnValueGetter,
    columns: readonly PluginTableColumn[],
    currentTimestamp: GlideDateTime,
    makeRoleHash: (role: string) => string | undefined,
    deviceTzMinutesOffset: number | undefined
): boolean {
    function getColumn(c: FilterColumn): unknown {
        return columnValueGetter(c.columnName);
    }

    function getPrimitive(c: FilterColumn | QueryDataValue | SpecialDateTimeValue): PluginPrimitiveValue {
        if (isFilterColumn(c)) {
            return asPrimitive(getColumn(c));
        } else {
            switch (c) {
                case "now":
                    return currentTimestamp;
                case "start-of-today":
                    return currentTimestamp.localStartOfDay(deviceTzMinutesOffset);
                case "end-of-today":
                    return currentTimestamp.localEndOfDay(deviceTzMinutesOffset);
            }
            return asPrimitive(c);
        }
    }

    function findColumn(fc: FilterColumn): PluginTableColumn | undefined {
        return columns.find(c => c.name === fc.columnName);
    }

    function evaluateBinary(
        lhs: FilterColumn,
        rhs: FilterColumn | QueryDataValue,
        forString: (l: string, r: string) => boolean,
        forNumber: (l: number, r: number) => boolean,
        forBoolean: (l: boolean, r: boolean) => boolean,
        forDateTime: (l: GlideDateTime, r: GlideDateTime) => boolean,
        defaultValue: boolean = false, // this is used for duration, array, and table-ref types, and if the applicable default is not specified in the next arg
        specificDefaults: {
            lhsDefault: boolean; // default for when lhsValue is undefined or the wrong type
            rhsDefault: boolean; // default for when rhsValue is undefined or the wrong type
            bothDefault: boolean; // default for when both lhsValue and rhsValue are undefined or the wrong type
        } = {
            lhsDefault: defaultValue,
            rhsDefault: defaultValue,
            bothDefault: defaultValue,
        }
    ): boolean {
        const lhsColumn = findColumn(lhs);
        const rhsColumn = isFilterColumn(rhs) ? findColumn(rhs) : undefined;
        if (lhsColumn === undefined) return false;

        const lhsValue = getPrimitive(lhs);
        const rhsValue = getPrimitive(rhs);

        return evaluateBinaryForType(
            lhsValue,
            rhsValue,
            lhsColumn,
            rhsColumn,
            forString,
            forNumber,
            forBoolean,
            forDateTime,
            defaultValue,
            specificDefaults
        );
    }

    function getCanonicalStringArray(
        c: FilterColumn | readonly QueryDataValue[],
        diligent: boolean = false
    ): readonly string[] {
        const result: string[] = [];

        function process(v: unknown) {
            if (isArray(v)) {
                for (const e of v) {
                    process(e);
                }
            } else {
                const s = asStringForArrayOverlap(v, diligent);
                if (s !== undefined) {
                    result.push(s);
                }
            }
        }

        if (isArray(c)) {
            process(c);
        } else {
            process(getColumn(c));
        }

        return result;
    }

    // See ##arrayIncludesInQueries below.
    //
    // function areEqual(v: PrimitiveValue, u: PrimitiveValue): boolean {
    //     if (v === u) {
    //         return true;
    //     }
    //     if (v instanceof GlideDateTime && u instanceof GlideDateTime) {
    //         return v.compareTo(u, deviceTzMinutesOffset) === 0;
    //     }
    //     return false;
    // }

    let result: boolean;

    switch (condition.kind) {
        case "array-overlap": {
            const array = getCanonicalStringArray(condition.array);
            const rawColumn = getCanonicalStringArray(condition.column, condition.roleHashLHS);
            const column = condition.roleHashLHS === true ? mapFilterUndefined(rawColumn, makeRoleHash) : rawColumn;
            result = column.some(a => array.includes(a));
            break;
        }

        // We don't yet support ##arrayIncludesInQueries.

        // case BinaryPredicateFormulaOperator.ArrayIncludes: {
        //     const lhs = getArray(condition.lhs);
        //     const rhs = getArray(condition.rhs);
        //     result = rhs.every(r => lhs.some(l => areEqual(r, l)));
        //     break;
        // }

        case UnaryPredicateFormulaOperator.IsTruthy: {
            const column = getPrimitive(condition.column);
            result = asBoolean(column);
            break;
        }

        case "is-empty": {
            const column = getColumn(condition.column);
            result = isEmpty(column);
            break;
        }

        case BinaryPredicateFormulaOperator.Equals: {
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                (l, r) => l === r,
                (l, r) => l === r,
                (l, r) => l === r,
                (l, r) => l.compareTo(r, { deviceTzMinutesOffset: 0 }) === 0,
                false,
                {
                    lhsDefault: false,
                    rhsDefault: false,
                    bothDefault: true, // they're equal if they're both empty (or LHS is empty and RHS doesn't convert to LHS column type)
                }
            );
            break;
        }
        case BinaryPredicateFormulaOperator.IsLessThan:
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                (l, r) => l < r,
                (l, r) => l < r,
                () => false,
                () => false
            );
            break;

        case BinaryPredicateFormulaOperator.IsGreaterThan:
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                (l, r) => l > r,
                (l, r) => l > r,
                () => false,
                () => false
            );
            break;

        case BinaryPredicateFormulaOperator.IsLessOrEqualTo:
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                (l, r) => l <= r,
                (l, r) => l <= r,
                () => false,
                () => false
            );
            break;

        case BinaryPredicateFormulaOperator.IsGreaterOrEqualTo:
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                (l, r) => l >= r,
                (l, r) => l >= r,
                () => false,
                () => false
            );
            break;

        case BinaryPredicateCompositeOperator.IsBefore:
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                () => false,
                () => false,
                () => false,
                (l, r) => l.compareTo(r, { deviceTzMinutesOffset: 0 }) < 0
            );
            break;

        case BinaryPredicateCompositeOperator.IsAfter:
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                () => false,
                () => false,
                () => false,
                (l, r) => l.compareTo(r, { deviceTzMinutesOffset: 0 }) > 0
            );
            break;

        case BinaryPredicateCompositeOperator.IsOnOrBefore:
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                () => false,
                () => false,
                () => false,
                (l, r) => l.compareTo(r.localEndOfDay(0), { deviceTzMinutesOffset: 0 }) <= 0
            );
            break;

        case BinaryPredicateCompositeOperator.IsOnOrAfter:
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                () => false,
                () => false,
                () => false,
                (l, r) => l.compareTo(r.localStartOfDay(0), { deviceTzMinutesOffset: 0 }) >= 0
            );
            break;

        case BinaryPredicateCompositeOperator.IsWithin:
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                () => false,
                () => false,
                () => false,
                (l, r) =>
                    l.compareTo(r.localStartOfDay(0), { deviceTzMinutesOffset: 0 }) >= 0 &&
                    l.compareTo(r.localEndOfDay(0), { deviceTzMinutesOffset: 0 }) <= 0
            );
            break;

        case BinaryPredicateFormulaOperator.ContainsString: {
            // FIXME: `numberToString` is not technically correct.  It should
            // be `formatNumber`, but we'd have to move that out of
            // `common-core`.  But really this whole thing should be removed
            // and we should use `conditions.ts` instead.  The two are
            // supposed to behave exactly the same.
            const lhs = asString(getPrimitive(condition.lhs), numberToString).toLowerCase();
            const rhs = asString(getPrimitive(condition.rhs), numberToString).toLowerCase();
            result = lhs.includes(rhs);
            break;
        }

        case BinaryPredicateFormulaOperator.IsContainedInString: {
            // FIXME: `numberToString` is not technically correct.  It should
            // be `formatNumber`, but we'd have to move that out of
            // `common-core`.  But really this whole thing should be removed
            // and we should use `conditions.ts` instead.  The two are
            // supposed to behave exactly the same.
            const lhs = asString(getPrimitive(condition.lhs), numberToString).toLowerCase();
            const rhs = asString(getPrimitive(condition.rhs), numberToString).toLowerCase();
            result = rhs.includes(lhs);
            break;
        }

        case BinaryPredicateFormulaOperator.MatchesEmailAddress:
            result = evaluateBinary(
                condition.lhs,
                condition.rhs,
                (l, r) => normalizeEmailAddress(l) === normalizeEmailAddress(r),
                () => false,
                () => false,
                () => false
            );
            break;
        default:
            return assertNever(condition);
    }

    if (condition.negated) {
        result = !result;
    }
    return result;
}
