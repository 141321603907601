import type { WireAppTheme } from "@glide/theme";
import { AppIcon } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import type { WireButtonComponent } from "@glide/fluent-components/dist/js/fluent-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { AppKind } from "@glide/location-common";
import { type WireComponent, type WireTab, UIBackgroundStyle, WireComponentKind } from "@glide/wire";
import type { MotionValue } from "framer-motion";
import { css } from "styled-components";

import type { WireButtonProps } from "../../renderers/wire-button/wire-button";
import { makeSingleActionSpreadProps } from "../../wire-lib";
import type { IconImage } from "@glide/app-description";
import { hasOwnProperty } from "collection-utils";

interface UserProfileMenuProps {
    layerProps: any;
    onClose: () => void;
    onUserButtonPressed: () => void;
    onSignOutPressed?: () => void;
}

export const UserProfileMenu: React.VFC<UserProfileMenuProps> = p => {
    return (
        <div {...p.layerProps} tw="rounded-lg shadow-md [width:192px] text-text-dark text-base bg-bg-front p-1">
            <ul
                onClick={p.onClose}
                tw="all-child:(px-3 py-2 space-y-px leading-4 rounded [font-size: 14px] cursor-pointer transition hover:bg-n200A)"
            >
                <li onClick={p.onUserButtonPressed}>{getLocalizedString("viewProfile", AppKind.Page)}</li>
                {p.onSignOutPressed && (
                    <li onClick={p.onSignOutPressed}>{getLocalizedString("signOut", AppKind.Page)}</li>
                )}
            </ul>
        </div>
    );
};

export function getLogoOrIcon(
    showLogo: boolean,
    showIcon: boolean,
    logo: string | undefined,
    iconImage: IconImage | undefined
) {
    if (showLogo) {
        return logo;
    }

    if (showIcon) {
        if (iconImage !== undefined) {
            if (hasOwnProperty(iconImage, "emoji")) {
                return "https://functions.prod.internal.glideapps.com/getEmoji/" + iconImage.emoji;
            } else if (hasOwnProperty(iconImage, "url")) {
                return iconImage.url;
            }
        }
    }

    return undefined;
}

export function useLogoAndTitleDisplayProps(
    logo: string | undefined,
    theme: WireAppTheme,
    iconImage: IconImage | undefined
): { showLogo: boolean; showIcon: boolean; showTitle: boolean } {
    const shouldShowLogo =
        logo !== undefined &&
        (theme.logoDisplay === undefined || theme.logoDisplay === "logoOnly" || theme.logoDisplay === "logoAndTitle");

    const shouldShowIcon =
        iconImage !== undefined && (theme.logoDisplay === undefined || theme.logoDisplay) === "iconAndTitle";

    const shouldShowTitle =
        theme.logoDisplay === undefined ||
        theme.logoDisplay === "titleOnly" ||
        theme.logoDisplay === "logoAndTitle" ||
        theme.logoDisplay === "iconAndTitle";

    return { showLogo: shouldShowLogo, showIcon: shouldShowIcon, showTitle: shouldShowTitle };
}

export interface UnifiedSideBarProps {
    readonly appID: string;
    readonly tabs: { primary: readonly WireTab[]; secondary: readonly WireTab[] };
    readonly selected?: WireTab;
    readonly onSelectionChange: (tab: WireTab) => void;
    readonly title: string;
    readonly isUserProfileActive: boolean;
    readonly logo?: string;
    readonly iconImage?: IconImage;
    readonly className?: string;
    readonly signedInUserEmail?: string;
    readonly signedInUserName?: string;
    readonly signedInUserImage?: string;
    readonly onUserButtonPressed?: () => void;
    readonly onSignInPressed?: () => void;
    readonly onSignOutPressed?: () => void;
    readonly onSignUpPressed?: () => void;
    readonly navigateToRoot?: () => void;
}

export interface UnifiedNavBarProps extends UnifiedSideBarProps {
    // Callback ref over here, got from useResizeObserver.
    readonly containerRef: React.Ref<HTMLDivElement>;
    readonly onBack: (isInModal: boolean) => void;
    readonly canGoBack: boolean;
    readonly scrollToTop: () => void;
    readonly isInDetailsScreen: boolean;
    readonly detailsTitle: string;
    readonly setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
    readonly isInModal: boolean;
    readonly isInFormOrEditModal: boolean;
    readonly onModalClose: () => void;
    readonly modalFormControls: ChromeFormControls | undefined;
    readonly belowScreenCanGoBack: boolean;
    readonly belowScreenTitle: string;
    readonly navBarCrossfadeMotionValue: MotionValue<number>;
    readonly topFirstComponentHasTitle: boolean;
    readonly belowFirstComponentHasTitle: boolean;
    readonly mustShowTitleInNavBar: boolean;
    readonly showMenuButton: boolean;
    readonly showTransparentNavBar: boolean;
    readonly iconImage: IconImage | undefined;
}

interface SingleChromeFormControl extends Pick<WireButtonProps, "target" | "href" | "onClick" | "disabled"> {
    label: string;
}

export interface ChromeFormControls {
    submit: SingleChromeFormControl;
    cancel: SingleChromeFormControl;
}

// This is terrible. We _know_ we have two specialComponents.
// But the API is more generic, so this is very prompt to breaking.
// Does it make sense to have a different structure for form controls?
// It would be much simpler than a WireComponent, like { label, action }[]
export function getFormOrEditControls(
    specialComponents: WireComponent[],
    backend: WireBackendInterface
): ChromeFormControls | undefined {
    const [submit, cancel] = specialComponents as WireButtonComponent[];
    if (
        submit === undefined ||
        cancel === undefined ||
        submit.kind !== WireComponentKind.Button ||
        cancel.kind !== WireComponentKind.Button
    ) {
        return undefined;
    }

    return {
        submit: {
            label: submit.title ?? getLocalizedString("submit", AppKind.Page),
            ...makeSingleActionSpreadProps(submit.action, backend),
        },
        cancel: {
            label: cancel.title ?? getLocalizedString("cancel", AppKind.Page),
            ...makeSingleActionSpreadProps(cancel.action, backend),
        },
    };
}

export function getContainerTheme(theme: WireAppTheme["pageTheme"], isTranslucent: boolean) {
    if (theme === "Highlight") {
        return isTranslucent ? UIBackgroundStyle.HighlightTranslucent : UIBackgroundStyle.White;
    } else if (theme === "Dark") {
        return isTranslucent ? UIBackgroundStyle.DarkTranslucent : UIBackgroundStyle.Dark;
    } else if (theme === "Accent") {
        return isTranslucent ? UIBackgroundStyle.AccentTranslucent : UIBackgroundStyle.Accent;
    }
    return UIBackgroundStyle.Transparent;
}

interface TabIconProps {
    icon: string;
    size?: number;
    strokeWidth?: number;
    className?: string;
}

export const TabIcon: React.VFC<TabIconProps> = p => {
    const { size = 20, className, icon, strokeWidth = 1.5 } = p;
    return (
        <AppIcon
            icon={icon}
            size={size}
            className={className}
            css={css`
                --stroke-width: ${strokeWidth};
            `}
        />
    );
};
