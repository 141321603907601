import { isDefined } from "@glide/support";
import * as React from "react";

interface UseMouseHoverProps {
    readonly delayEnter?: number;
}

interface UseMouseHoverMethods {
    readonly onMouseEnter: (event: React.MouseEvent) => void;
    readonly onMouseLeave: (event: React.MouseEvent) => void;
}

interface UseMouseHover {
    isHovering: boolean;
    hoverProps: UseMouseHoverMethods;
}

type Timeout = ReturnType<typeof setTimeout>;

export function useMouseHover(props: UseMouseHoverProps): UseMouseHover {
    const { delayEnter } = props;
    const [isHovering, setIsHovering] = React.useState(false);
    const setTimeoutIdRef = React.useRef<Timeout | null>(null);

    const timeoutCleanUp = () => {
        if (setTimeoutIdRef.current) {
            clearTimeout(setTimeoutIdRef.current);
        }
    };

    const runWithTimeout = React.useCallback((fn: Function, timeout: number) => {
        timeoutCleanUp();
        setTimeoutIdRef.current = setTimeout(() => fn(), timeout);
    }, []);

    const onMouseEnter = React.useCallback(() => {
        if (isDefined(delayEnter) && delayEnter > 0) {
            runWithTimeout(() => setIsHovering(true), delayEnter);
        } else {
            setIsHovering(true);
        }
    }, [delayEnter, runWithTimeout]);

    const onMouseLeave = React.useCallback(() => {
        timeoutCleanUp();
        setIsHovering(false);
    }, []);

    const hoverProps: UseMouseHoverMethods = React.useMemo(
        () => ({
            onMouseEnter,
            onMouseLeave,
        }),
        [onMouseEnter, onMouseLeave]
    );

    return { isHovering, hoverProps };
}
