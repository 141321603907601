import { isLoadingValue } from "@glide/computation-model-types";
import { asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ActionDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
} from "@glide/app-description";
import {
    makeInlineTemplatePropertyDescriptor,
    type AppDescriptionContext,
    type PropertyDescriptor,
    type ActionAvailability,
} from "@glide/function-utils";
import { nullToUndefined } from "@glide/support";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { ICON_BASE, LIME_500 } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins";

interface CopyToClipboardActionDescription extends ActionDescription {
    // This property name is also hardcoded in ##convertLegacyActions
    readonly data?: PropertyDescription;
}

export function makeDataPropertyDescriptor(
    placeholder: string,
    mutatingScreenKind: MutatingScreenKind | undefined
): PropertyDescriptor {
    return makeInlineTemplatePropertyDescriptor("data", "Data", placeholder, true, "withLabel", mutatingScreenKind, {
        columnFirst: true,
        searchable: false,
    });
}

export class CopyToClipboardWithArgsHandler extends BaseActionHandler<CopyToClipboardActionDescription> {
    public readonly kind = ActionKind.CopyToClipboardWithArgs;

    public readonly iconName: GlideIconProps = {
        icon: "st-clipboard",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: LIME_500,
    };

    public readonly name = "Copy to clipboard";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: CopyToClipboardActionDescription | undefined,
        { mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Data,
            groupItemOrder: 7,
            needsScreenContext: true,
            properties: [makeDataPropertyDescriptor("Enter text to copy", mutatingScreenKind)],
        };
    }

    public getTokenizedDescription(
        desc: CopyToClipboardActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const columnToken = tokenForProperty(desc.data, env);
        if (columnToken === undefined) return undefined;

        return [columnToken];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: CopyToClipboardActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const [valueGetter] = ib.getValueGetterForProperty(desc.data, false);

        return (vp, skipLoading) => {
            const value = nullToUndefined(valueGetter(vp));
            if (isLoadingValue(value)) return arbBase.maybeSkipLoading(skipLoading, "Value to copy");

            const str = asMaybeString(value);
            if (str === undefined) return arbBase.error(true, "Data cannot be copied");
            const arb = arbBase.addData({ copied: str });

            return async ab => {
                ab.actionCallbacks.copyToClipboard(str);
                return arb.success();
            };
        };
    }
}
