export function* generateAllSequencesOfLength<T>(items: readonly T[], length: number): Generator<readonly T[]> {
    if (length === 0) {
        yield [];
        return;
    }

    for (const item of items) {
        for (const sequence of generateAllSequencesOfLength(items, length - 1)) {
            yield [item, ...sequence];
        }
    }
}
