import { AppKind } from "@glide/location-common";
import { type ActionDescription, type PropertyDescription, ActionKind } from "@glide/app-description";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    userProfileScreenName,
    type ActionAvailability,
} from "@glide/function-utils";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import { makeScreenTitlePropertyDescriptor } from "../components/descriptor-utils";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

interface PushUserProfileScreenActionDescription extends ActionDescription {
    readonly kind: ActionKind.PushUserProfileScreen;
    readonly title: PropertyDescription | undefined;
}

export class PushUserProfileScreenActionHandler extends BaseActionHandler<PushUserProfileScreenActionDescription> {
    public readonly kind = ActionKind.PushUserProfileScreen;
    public readonly iconName: GlideIconProps = {
        icon: "st-user-profile",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Show user profile screen";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getIsApplicable(_screenHasInputContext: boolean, appHasUserProfiles: boolean): boolean {
        return appHasUserProfiles;
    }

    public getDescriptor(
        _desc: PushUserProfileScreenActionDescription | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const properties: PropertyDescriptor[] = [];
        if (ccc.appKind === AppKind.App) {
            properties.push(makeScreenTitlePropertyDescriptor(mutatingScreenKind));
        }

        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 7,
            needsScreenContext: false,
            properties,
        };
    }

    public getScreensUsed(
        _desc: PushUserProfileScreenActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly string[] {
        if (env.context.userProfileTableInfo === undefined) return [];

        return [userProfileScreenName];
    }

    public getTokenizedDescription(): readonly DescriptionToken[] | undefined {
        return [];
    }

    public inflate(
        _ib: WireActionInflationBackend,
        _desc: PushUserProfileScreenActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        return () => {
            return async ab => {
                const result = ab.navigateToUserProfile();
                return arb.fromResult(result);
            };
        };
    }
}
