// Figma Spec : https://www.figma.com/file/QLE51iLgZEcHTwODa6m10h/Untitled?type=design&node-id=1-1413&t=og6PAcWHw2r5Aec8-0
import { copyToClipboard as copy, getNamedIcon } from "@glide/common";
import { marked } from "marked";

import { DefaultErrorMessage } from "./error-components";
import { errorBodies } from "./error-snippets";
import { ModalBody } from "./modal-v2-common-styles";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";

export interface ErrorModalBody {
    code: number;
    title: string;
    description?: string;
    htmlBodyContent?: React.ReactNode;
    onClick?: () => void;
    buttonText: string;
}

interface ModalSnippet {
    title: string;
    bodyContent: React.ReactNode;
    code?: number;
    buttonText?: string;
    description?: string;
    onClick?: () => void;
}

// TODO: Add logic where we conditionally different error modals after checking eminence to see if they are
// on a plan higher than starter.

const createModalSnippet = (
    { code, title, description, buttonText, onClick, htmlBodyContent = <></> }: ErrorModalBody,
    errorMessage?: string
): ModalSnippet => {
    return {
        title,
        bodyContent: (
            <ModalBody>
                {description && (
                    <div className="description" dangerouslySetInnerHTML={{ __html: marked(description) }} />
                )}
                {htmlBodyContent}
                {errorMessage ?? <div className="description">{errorMessage}</div>}
            </ModalBody>
        ),
        onClick: onClick,
        buttonText,
        description,
        code,
    };
};

export const getErrorModalSnippet = (errorCode: number, errorMessage?: string): ModalSnippet => {
    const modalSnippet: ErrorModalBody | undefined = errorBodies.find(item => item.code === errorCode);

    if (modalSnippet === undefined) {
        // eslint-disable-next-line no-console
        console.error("Error code not found:", errorCode);
        return createModalSnippet(errorBodies[0], errorMessage);
    }
    return createModalSnippet(modalSnippet, errorMessage);
};

export const GenericOhSheetError = (errorMessage?: string | undefined): ModalSnippet => {
    return {
        title: "An error has occurred",
        bodyContent: (
            <ModalBody>
                {DefaultErrorMessage}
                <br></br>
                {errorMessage ?? ""}
            </ModalBody>
        ),
    };
};

const CopyLinkIcon = getNamedIcon("copyLink");

export const CreateSupportLink = (url: string): ModalSnippet => {
    return {
        title: "Copy Support Link",
        bodyContent: (
            <ModalBody>
                <div className="description">
                    Please share this link with Glide
                    <a
                        className="quicklink learnmore"
                        href={getDocURL("supportCodes")}
                        title="learn more"
                        target="_blank"
                        rel="noopener noreferrer">
                        {" "}
                        Support{" "}
                    </a>
                    to grant temporary access to your app:
                </div>
                <div
                    className="applink"
                    onClick={() => {
                        copy(url);
                    }}>
                    <p>{url}</p>
                    <CopyLinkIcon />
                </div>
            </ModalBody>
        ),
    };
};
