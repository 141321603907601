import crypto from "crypto";

/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

export const plugin = glide.newPlugin({
    name: "Urlbox",
    id: "urlbox",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/urlbox.png",
    description: "Generate Templated PDF/Images",
    tier: "starter",
    parameters: {
        publishableKey: glide.makeParameter({
            type: "string",
            name: "Publishable Key",
            description: "You can find this key in your [Urlbox dashboard](https://www.urlbox.io/dashboard)",
            required: true,
        }),
        secretKey: glide.makeParameter({
            type: "secret",
            name: "Secret Key",
            description: "You can find this key in your [Urlbox dashboard](https://www.urlbox.io/dashboard)",
            required: true,
        }),
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/urlbox",
});

// https://urlbox.io/docs/options
type Options = Record<string, any>;

type APIType = "pdf" | "png";

function makeURL(options: Options, publishableKey: string, secretKey: string, apiType: APIType) {
    const query = new URLSearchParams(options).toString();

    // https://urlbox.io/docs/authenticated-requests
    const hmacToken = crypto.createHmac("sha1", secretKey).update(query).digest("hex");

    return `https://api.urlbox.io/v1/${publishableKey}/${hmacToken}/${apiType}?${query}`;
}

const html = glide.makeParameter({
    // TODO support syntax-highligted HTML
    type: "string",
    name: "HTML",
    description: "HTML to render",
    placeholder: "e.g. <p>hello!</p>",
    required: true,
});

const css = glide.makeParameter({
    // TODO support syntax-highlighted CSS
    type: "string",
    name: "CSS",
    description: "CSS to style",
    placeholder: "e.g. p { color: blue; }",
});

const filePathName = glide.makeParameter({
    type: "string",
    name: "File Name",
    description: "The File path for created url",
    placeholder: "e.g. report",
});

plugin.addAction({
    id: "generate-pdf",
    name: "Generate PDF",
    description: "Generate a PDF from HTML",
    billablesConsumed: 5,
    parameters: { html, css, filePathName },
    results: {
        fileName: glide.makeParameter({
            type: "string",
            name: "Uploaded PDF",
            description: "A link to the uploaded PDF",
            required: true,
        }),
    },

    execute: async (context, { html = "", css = "", publishableKey = "", secretKey = "", filePathName }) => {
        const options: Record<string, string> = { html, css, pdf_page_size: "A4" };

        const cachedResult = await context.useCache(async () => {
            const url = makeURL(options, publishableKey, secretKey, "pdf");
            const uploaded = await context.rehostFile(`${filePathName}.pdf`, url);

            if (!uploaded.ok) return uploaded;
            context.consumeBillable();

            return glide.Result.Ok({ fileName: uploaded.result });
        }, ["pdf", options, publishableKey, secretKey]);

        return cachedResult;
    },
});

const width = glide.makeParameter({
    type: "number",
    name: "Width",
    description: "Image width",
});

const height = glide.makeParameter({
    type: "number",
    name: "Height",
    description: "Image height",
});

plugin.addAction({
    id: "generate-image",
    name: "Generate image",
    description: "Generate an image from HTML",
    billablesConsumed: 3,
    parameters: { html, css, width, height, filePathName },
    results: {
        fileName: glide.makeParameter({
            type: "string",
            name: "Uploaded Image",
            description: "A link to the uploaded PNG",
        }),
    },

    execute: async (
        context,
        { html = "", css = "", width = 0, height = 0, publishableKey = "", secretKey = "", filePathName }
    ) => {
        const options = { html, css, width: `${width}`, height: `${height}`, retina: "true" };

        const cachedResult = await context.useCache(async () => {
            const url = makeURL(options, publishableKey, secretKey, "png");
            const uploaded = await context.rehostFile(`${filePathName}.png`, url);

            if (!uploaded.ok) return uploaded;

            context.consumeBillable();
            return glide.Result.Ok({ fileName: uploaded.result });
        }, ["png", options, publishableKey, secretKey]);
        return cachedResult;
    },
});
