import { exceptionToString } from "@glideapps/ts-necessities";
import { isRight } from "fp-ts/lib/Either";
import * as t from "io-ts";

const dbCleanupMessageCodec = t.type({
    kind: t.literal("glide-sw-db-cleanup"),
});
type DbCleanupMessage = t.TypeOf<typeof dbCleanupMessageCodec>;
export function isDbCleanupMessage(m: unknown): m is DbCleanupMessage {
    return isRight(dbCleanupMessageCodec.decode(m));
}
export function makeDbCleanupMessage(): DbCleanupMessage {
    return {
        kind: "glide-sw-db-cleanup",
    };
}

const cacheCleanupMessageCodec = t.type({
    kind: t.literal("glide-sw-cache-cleanup"),
});
type CacheCleanupMessage = t.TypeOf<typeof cacheCleanupMessageCodec>;
export function isCacheCleanupMessage(m: unknown): m is CacheCleanupMessage {
    return isRight(cacheCleanupMessageCodec.decode(m));
}
export function makeCacheCleanupMessage(): CacheCleanupMessage {
    return {
        kind: "glide-sw-cache-cleanup",
    };
}

const swErrorMessageCodec = t.type({
    kind: t.literal("glide-sw-error"),
    action: t.string,
    exceptionStr: t.string,
    exception: t.unknown,
});
type ServiceWorkerErrorMessage = t.TypeOf<typeof swErrorMessageCodec>;
export function isServiceWorkerErrorMessage(m: unknown): m is ServiceWorkerErrorMessage {
    return isRight(swErrorMessageCodec.decode(m));
}
export function makeServiceWorkerErrorMessage(action: string, e: unknown): ServiceWorkerErrorMessage {
    return {
        kind: "glide-sw-error",
        action,
        exceptionStr: exceptionToString(e),
        exception: e,
    };
}

export const swInstallTimingRequestCodec = t.type({
    kind: t.literal("glide-sw-install-timing-request"),
});
type ServiceWorkerInstallTimingRequest = t.TypeOf<typeof swInstallTimingRequestCodec>;
export function makeServiceWorkerInstallTimingRequest(): ServiceWorkerInstallTimingRequest {
    return {
        kind: "glide-sw-install-timing-request",
    };
}

const cacheInstallTimings = t.type({
    startTime: t.number,
    durationMS: t.number,
    href: t.string,
});
export type ServiceWorkerCacheInstallTimings = t.TypeOf<typeof cacheInstallTimings>;

export const swInstallTimingResponseMessageCodec = t.intersection([
    t.type({
        kind: t.literal("glide-sw-install-timing-response"),
        cacheCheckStartTime: t.number,
        cacheCheckDurationMS: t.number,
        cacheCheckedEntries: t.number,
        cacheFetchStartTime: t.number,
        cacheFetchDurationMS: t.number,
        cacheFetchedEntries: t.number,
    }),
    t.partial({
        rootFetchStartTime: t.number,
        rootFetchHeaderDurationMS: t.number,
        rootFetchBodyDurationMS: t.number,
        cacheFetchTimings: t.array(cacheInstallTimings),
    }),
]);
export type ServiceWorkerInstallTimingResponse = t.TypeOf<typeof swInstallTimingResponseMessageCodec>;
