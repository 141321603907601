import { AppKind } from "@glide/location-common";
import type { ComponentKind, MutatingScreenKind } from "@glide/app-description";
import { makeEnumProperty, PropertyKind } from "@glide/app-description";
import { makeEmptyComponentDescription, type InputOutputTables } from "@glide/common-core/dist/js/description";
import { WireToggleKind } from "@glide/fluent-components/dist/js/base-components";
import type { AppDescriptionContext, ComponentDescriptor, PropertyDescriptor } from "@glide/function-utils";
import { PropertySection } from "@glide/function-utils";

import { type ToggleComponentDescription, ToggleComponentHandler } from "./toggle";

const ComponentKindCheckbox: ComponentKind = "checkbox";

export class CheckboxComponentHandler extends ToggleComponentHandler {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindCheckbox, WireToggleKind.Checkbox, ["flag", "checkbox"]);
    }

    protected readonly name = "Checkbox";
    protected readonly description = "A box you can check";
    protected readonly img = "co-checkbox";
    protected readonly docPath = "toggle";

    protected readonly uiName = "Checkbox";

    public getDescriptor(
        desc: ToggleComponentDescription,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const baseDescriptor = super.getDescriptor(desc, tables, ccc, mutatingScreenKind);
        const checkboxProperties: readonly PropertyDescriptor[] =
            mutatingScreenKind === undefined
                ? baseDescriptor.properties
                : [
                      ...baseDescriptor.properties,
                      // FIXME: only when editing in screen context
                      {
                          kind: PropertyKind.Switch,
                          property: { name: "mustBeActive" },
                          label: "Required",
                          section: PropertySection.Options,
                      },
                  ];
        return {
            ...baseDescriptor,
            properties: checkboxProperties,
        };
    }

    public convertToPage(
        desc: ToggleComponentDescription,
        _ccc: AppDescriptionContext
    ): ToggleComponentDescription | undefined {
        return {
            ...makeEmptyComponentDescription("switch"),
            propertyName: desc.propertyName,
            toggleKind: makeEnumProperty(WireToggleKind.Checkbox),
            description: desc.description,
            caption: desc.caption,
            primaryKeyProperty: desc.primaryKeyProperty,
            mustBeActive: desc.mustBeActive,
            allowWrapping: desc.allowWrapping,
            defaultValue: desc.defaultValue,
        };
    }
}
