import "twin.macro";

import { formatLocalizedString, getLocalizedString } from "@glide/localization";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { isUploadFileResponseError } from "@glide/common-core/dist/js/components/types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { getGlideIcon, getMonotoneGlideIcon, GlideIcon } from "@glide/common";
import { ImagePickerSourceHint, TextComponentStyle } from "@glide/component-utils";

import type { WireBackendInterface } from "@glide/hydrated-ui";
import { ConcurrencyLimiterWithBackpressure, Watchable, ignore, isDefined, parseURL } from "@glide/support";
import { UIButtonAppearance, ValueChangeSource, type WireEditableValue } from "@glide/wire";
import { definedMap } from "collection-utils";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import * as React from "react";

import { Img } from "../../components/img/img";
import { Text } from "../../components/text/text";
import { WireButton } from "../wire-button/wire-button";
import { useFileUploader } from "../../utils/use-file-uploader";
import { AppKind } from "@glide/location-common";
import { useIsHoverable } from "@glide/common-components";

interface MultipleImagePickerProps {
    readonly title: string | undefined;
    readonly editableValue: WireEditableValue<readonly string[]>;
    readonly isRequired: boolean;
    readonly backend: WireBackendInterface;
    readonly sourceHint: ImagePickerSourceHint;
}

export const MultipleImagePicker: React.VFC<MultipleImagePickerProps> = p => {
    const { title, editableValue, backend, isRequired, sourceHint } = p;

    const { value, onChangeToken } = editableValue;

    const { images, uploadImages } = usePickerImages(value, onChangeToken, backend);
    const hasImages = !isEmpty(images);

    const hasTitleElement = isDefined(title) || isRequired;

    return (
        <div onClick={e => e.stopPropagation()}>
            {hasTitleElement && (
                <div tw="flex mb-2 justify-between">
                    {isDefined(title) ? (
                        <Text
                            element="h3"
                            variant={TextComponentStyle.small}
                            tw="font-semibold text-text-contextual-dark">
                            {title}
                        </Text>
                    ) : (
                        <div />
                    )}
                    {isRequired && (
                        <Text element="span" variant={TextComponentStyle.footnote}>
                            {getLocalizedString("required", backend.appKind)}
                        </Text>
                    )}
                </div>
            )}
            {hasImages ? (
                <ImageList images={images} uploadImages={uploadImages} appID={backend.appID} sourceHint={sourceHint} />
            ) : (
                <EmptyState uploadImages={uploadImages} sourceHint={sourceHint} />
            )}
        </div>
    );
};

function getImagesCountFromDataTransferItems(items: DataTransferItemList): number {
    let images = 0;
    for (const item of items) {
        if (item.kind === "file" && item.type.startsWith("image/")) {
            images++;
        }
    }

    return images;
}

function getImagesFromFileList(files: FileList | null): File[] {
    if (files === null) {
        return [];
    }

    const images = [];
    for (const file of files) {
        if (file.type.startsWith("image/")) {
            images.push(file);
        }
    }
    return images;
}

interface UploadInputProps {
    readonly uploadImages: (files: File[] | null) => Promise<void>;
    readonly inputRef?: React.RefObject<HTMLInputElement>;
    readonly sourceHint: ImagePickerSourceHint;
}

const UploadInput: React.VFC<UploadInputProps> = p => {
    const { uploadImages, inputRef, sourceHint } = p;
    const capture = sourceHint === ImagePickerSourceHint.CameraOnly ? "environment" : undefined;

    return (
        <input
            ref={inputRef}
            data-testid="upload-images-input"
            tw="absolute w-0 h-0 invisible"
            type="file"
            multiple={true}
            accept="image/*"
            onChange={ev => uploadImages(getImagesFromFileList(ev.target.files))}
            capture={capture}
        />
    );
};

interface DragAndDropHandlers {
    handlers: {
        onDragOver: (e: React.DragEvent) => void;
        onDrop: (e: React.DragEvent) => void;
        onDragLeave: () => void;
    };
    isDraggingOver: boolean;
    draggedImages: number;
}

function useDragAndDropImages(uploadImages: (files: File[] | null) => Promise<void>): DragAndDropHandlers {
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const [draggedImages, setDraggedImages] = React.useState(0);

    const onDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";

        const imagesCount = getImagesCountFromDataTransferItems(e.dataTransfer.items);
        setDraggedImages(imagesCount);
        setIsDraggingOver(imagesCount > 0);
    };

    const onDrop = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();

        const files = e.dataTransfer.files;
        const images = getImagesFromFileList(files);

        void uploadImages(images);
        setIsDraggingOver(false);
    };

    const onDragLeave = () => {
        setIsDraggingOver(false);
    };

    return {
        handlers: {
            onDragOver,
            onDrop,
            onDragLeave,
        },
        isDraggingOver,
        draggedImages,
    };
}

interface EmptyStateProps {
    readonly uploadImages: (files: File[] | null) => Promise<void>;
    readonly sourceHint: ImagePickerSourceHint;
}

const EmptyState: React.VFC<EmptyStateProps> = p => {
    const { uploadImages, sourceHint } = p;

    const { handlers, isDraggingOver, draggedImages } = useDragAndDropImages(uploadImages);

    const isCameraOnly = sourceHint === ImagePickerSourceHint.CameraOnly;

    const isTouchDevice = !useIsHoverable();

    const labelForMainUploadButton =
        isCameraOnly && isTouchDevice
            ? getLocalizedString("takeAPicture", AppKind.Page)
            : formatLocalizedString("uploadNImages", [""], AppKind.Page);

    const iconForMainUploadButton =
        isCameraOnly && isTouchDevice
            ? getMonotoneGlideIcon("mt-page-camera-upload")
            : getMonotoneGlideIcon("mt-page-image-upload");

    return (
        <div {...handlers}>
            <label
                className={isDraggingOver ? "dragging-over" : undefined}
                tw="w-full flex rounded-lg border border-border-dark py-4 px-6 relative cursor-pointer transition-colors [&.dragging-over]:bg-n50 page-hover:bg-n50">
                <UploadInput uploadImages={uploadImages} sourceHint={sourceHint} />
                <div tw="absolute inset-0 pointer-events-none flex justify-center items-center opacity-0 [.dragging-over &]:opacity-100">
                    <Text element="p" variant={TextComponentStyle.regular}>
                        {formatLocalizedString("uploadNImages", [draggedImages.toString()], AppKind.Page)}
                    </Text>
                </div>
                <div tw="w-full flex flex-col items-center pointer-events-none transition-opacity [.dragging-over &]:opacity-0">
                    <Text element="p" variant={TextComponentStyle.regular} tw="mb-2 mt-1 hidden page-md:block">
                        {getLocalizedString("dragImagesHereOr", AppKind.Page)}
                    </Text>
                    <WireButton appearance={UIButtonAppearance.Transparent} iconName={iconForMainUploadButton}>
                        {labelForMainUploadButton}
                    </WireButton>
                </div>
            </label>
        </div>
    );
};

interface PickerImage {
    readonly key: string;
    readonly isLoading: boolean;
    readonly src: string;
    readonly onRemove: (() => void) | undefined;
    readonly loadingPercentage: Watchable<number>;
}

interface UsePickerImages {
    readonly images: readonly PickerImage[];
    readonly uploadImages: (files: File[] | null) => Promise<void>;
}

function usePickerImages(
    backendImages: readonly string[],
    onChangeToken: string | undefined,
    backend: WireBackendInterface
): UsePickerImages {
    const uploadAppFile = useFileUploader();

    const pickerImagesFromBackend = backendImages.map((i): PickerImage => {
        const onRemove = definedMap(onChangeToken, t => () => {
            const newImages = backendImages.filter(image => image !== i);
            backend.valueChanged(t, newImages, ValueChangeSource.User);
        });

        return {
            key: i,
            isLoading: false,
            loadingPercentage: new Watchable<number>(1),
            src: i,
            onRemove,
        };
    });

    const [pickerImagesLoading, setPickerImagesLoading] = React.useState<PickerImage[]>([]);

    const images = React.useMemo(() => {
        return [...pickerImagesFromBackend, ...pickerImagesLoading];
    }, [pickerImagesFromBackend, pickerImagesLoading]);

    const imagesRef = React.useRef(backendImages);
    imagesRef.current = backendImages;

    const uploadImages = React.useCallback(
        async (fileList: File[] | null) => {
            if (fileList === null || isEmpty(fileList) || onChangeToken === undefined) {
                return;
            }

            const uploadSessions = [...fileList].map((file, i) => {
                const loadingPercentage = new Watchable(0);
                const session = uploadAppFile(
                    backend.appID,
                    backend.appKind,
                    getAppFacilities(),
                    file,
                    "image-picker",
                    (totalBytes, bytesSent) => {
                        if (totalBytes === 0) {
                            loadingPercentage.current = 0;
                            return;
                        }
                        loadingPercentage.current = bytesSent / totalBytes;
                    },
                    ignore,
                    false
                );

                const key = i + file.name;
                const src = URL.createObjectURL(file);

                const onRemove = () => {
                    session.cancel();
                    setPickerImagesLoading(removingCurrent => removingCurrent.filter(c => c.key !== key));
                    URL.revokeObjectURL(src);
                };

                const pickerImage: PickerImage = {
                    key,
                    src,
                    isLoading: true,
                    loadingPercentage,
                    onRemove,
                };

                return {
                    session,
                    pickerImage,
                };
            });

            setPickerImagesLoading(current => {
                const newImages = uploadSessions.map(s => s.pickerImage);
                return [...current, ...newImages];
            });

            const limiter = new ConcurrencyLimiterWithBackpressure(5);
            void limiter.forEach(uploadSessions, async s => {
                const { session, pickerImage } = s;

                const response = await session.attempt();

                if (!isUploadFileResponseError(response)) {
                    backend.valueChanged(onChangeToken, [...imagesRef.current, response.path], ValueChangeSource.User);
                }

                pickerImage.onRemove?.();
            });
        },
        [backend, onChangeToken, uploadAppFile]
    );

    return {
        images,
        uploadImages,
    };
}

interface ImageListProps {
    readonly images: readonly PickerImage[];
    readonly uploadImages: (files: File[] | null) => Promise<void>;
    readonly appID: string;
    readonly sourceHint: ImagePickerSourceHint;
}

const ImageList: React.VFC<ImageListProps> = p => {
    const { images, uploadImages, appID, sourceHint } = p;

    const { handlers, isDraggingOver, draggedImages } = useDragAndDropImages(uploadImages);

    const refs = React.useMemo(() => {
        return images.map(() => {
            return React.createRef<HTMLDivElement>();
        });
    }, [images]);

    React.useEffect(() => {
        const removalCallbacks: (() => void)[] = [];

        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const imageRef = refs[i];

            const updateHandler = (newValue: number) => {
                if (imageRef.current === null) {
                    return;
                }

                imageRef.current.style.transform = `translateX(${newValue * 100}%)`;
            };
            image.loadingPercentage.subscribe(updateHandler);
            updateHandler(image.loadingPercentage.current);

            removalCallbacks.push(() => {
                image.loadingPercentage.unsubscribe(updateHandler);
            });
        }

        return () => {
            for (const removal of removalCallbacks) {
                removal();
            }
        };
    }, [images, refs]);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const triggerUpload = () => {
        inputRef.current?.click();
    };

    return (
        <>
            <div
                {...handlers}
                className={isDraggingOver ? "dragging-over" : undefined}
                tw="w-full relative rounded-lg border border-border-base py-4 px-6 cursor-pointer transition-all page-hover:bg-n50"
                onClick={triggerUpload}>
                <div tw="absolute inset-0 pointer-events-none flex justify-center items-center opacity-0 rounded-lg transition-all [.dragging-over &]:opacity-100 backdrop-blur-2xl z-10">
                    <Text element="p" variant={TextComponentStyle.regular}>
                        {formatLocalizedString("addNImages", [draggedImages.toString()], AppKind.Page)}
                    </Text>
                </div>
                <div
                    tw="grid gap-x-6 gap-y-4 grid-cols-4
                        gp-md:(grid-cols-5 gap-y-6)
                        gp-lg:(grid-cols-6)
                        gp-xl:(grid-cols-7)">
                    <>
                        {images.map((image, idx) => {
                            let label = image.src;

                            const uri = parseURL(image.src);
                            if (uri !== undefined) {
                                const fileName = last(uri.pathname.split("/"));
                                if (fileName !== undefined) {
                                    label = fileName;
                                }
                            }

                            const isLocalImage = image.src.startsWith("blob:");
                            const src =
                                image.isLoading || isLocalImage
                                    ? image.src
                                    : massageImageUrl(image.src, { thumbnail: false, width: 150 }, appID);

                            return (
                                <div
                                    key={image.key}
                                    data-testid="picker-image"
                                    tw="cursor-auto"
                                    onClick={e => e.stopPropagation()}>
                                    <div tw="relative w-full [aspect-ratio: 1/1]">
                                        <div tw="w-full h-full relative rounded-lg overflow-hidden">
                                            <Img
                                                className={image.isLoading ? "uploading" : "uploaded"}
                                                src={src}
                                                tw="rounded-lg object-cover w-full h-full [&.uploading]:opacity-40"
                                                data-testid={`image-from-picker-${idx}`}
                                            />
                                            {image.isLoading && (
                                                <div
                                                    tw="w-full h-1 bg-accent bottom-0 -left-full absolute transition ease-in"
                                                    ref={refs[idx]}
                                                />
                                            )}
                                        </div>
                                        {isDefined(image.onRemove) && (
                                            <RemoveButton onClick={image.onRemove} testIdIndex={idx} />
                                        )}
                                    </div>
                                    <Text
                                        element="p"
                                        variant={TextComponentStyle.footnote}
                                        tw="truncate w-full gp-md:(mt-2)">
                                        {label}
                                    </Text>
                                </div>
                            );
                        })}
                    </>
                </div>
            </div>
            <WireButton
                onClick={triggerUpload}
                appearance={UIButtonAppearance.MinimalPrimary}
                iconName={getGlideIcon("st-plus-add")}
                tw="py-1! my-0.5"
                iconPlacement="right">
                {getLocalizedString("add", AppKind.Page)}
            </WireButton>
            <UploadInput uploadImages={uploadImages} inputRef={inputRef} sourceHint={sourceHint} />
        </>
    );
};

interface RemoveButtonProps {
    readonly onClick: () => void;
    readonly testIdIndex: number;
}

const RemoveButton: React.VFC<RemoveButtonProps> = p => {
    const { onClick, testIdIndex } = p;

    return (
        <button
            data-testid={`remove-image-${testIdIndex}`}
            onClick={onClick}
            className="group"
            tw="rounded-full absolute top-0 right-0 w-5 h-5 p-0.5 bg-n50 translate-x-1/2 -translate-y-1/2 text-white page-hover:scale-105">
            <div tw="rounded-full bg-n600 w-full h-full flex items-center justify-center group-page-hover:bg-text-base">
                <GlideIcon kind="stroke" icon="st-close" iconSize={12} />
            </div>
        </button>
    );
};
