import { showConfirmModal, ConfirmModalStyle } from "./confirm-modal";
import { getLocalizedString } from "@glide/localization";
import { CancelledError, NetworkOfflineError, FileReadError, FileSizeLimitExceededError } from "./upload-handlers";
import { type UploadFileResponse, isUploadFileResponseError } from "./types";
import type { AppKind } from "@glide/location-common";

export async function showCancelUploadModal(appKind: AppKind): Promise<boolean> {
    return (
        (await showConfirmModal({
            title: getLocalizedString("cancelUpload", appKind),
            accept: getLocalizedString("cancel", appKind),
            cancel: getLocalizedString("continue", appKind),
            description: getLocalizedString("uploadProgressWillBeLost", appKind),
            style: ConfirmModalStyle.Delete,
        })) ?? false
    );
}

// Returns "true" if you should retry the operation.
// "Cancel" is considered "accept" here because the proper Cancel
// modal uses "Continue" as "cancel" and "Cancel" as "accept".
// If these two modals start conflicting over each other,
// you end up with a user trying to continue a near-cancelled
// upload just in time to cancel a failed upload.
export async function showUploadFailedRetryModal(resp: UploadFileResponse, appKind: AppKind): Promise<boolean> {
    if (
        !isUploadFileResponseError(resp) ||
        resp.error instanceof CancelledError ||
        resp.error instanceof FileSizeLimitExceededError
    ) {
        return false;
    }

    let title = getLocalizedString("uploadFailedTitle", appKind);
    let description: string;
    if (resp.quotaExceeded) {
        description = getLocalizedString("uploadQuotaReached", appKind);
    } else {
        description = getLocalizedString("uploadFailedDetails", appKind);
    }
    const { error } = resp;
    if (error instanceof NetworkOfflineError) {
        title = getLocalizedString("networkDownTitle", appKind);
        description = getLocalizedString("uploadNetworkDownDetails", appKind);
    } else if (error instanceof FileReadError) {
        title = getLocalizedString("fileReadErrorTitle", appKind);
        description = getLocalizedString("fileReadErrorDetails", appKind);
    }

    return !(
        (await showConfirmModal({
            title,
            description,
            cancel: resp.quotaExceeded ? undefined : getLocalizedString("retry", appKind),
            accept: getLocalizedString("cancel", appKind),
            style: ConfirmModalStyle.Delete,
        })) ?? false
    );
}
