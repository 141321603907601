// FIXME: webapp incurs dependencies on sharedUI incurs dependencies inside webapp
// This complicates the dependency layout.
import { parseSendPinForEmailResponse } from "@glide/auth-controller-core";
import { hookFirebaseSignOutTransitionEvent } from "@glide/common-core/dist/js/authorization/auth";
import type {
    AppFacilities,
    AppUserAuthenticator,
    AppUserChangedCallback,
    OAuth2TokenAuth,
    ResponseStatus,
} from "@glide/common-core/dist/js/components/types";
import { decodeTypeSchema } from "@glide/type-schema";
import { getDeviceID } from "@glide/common-core/dist/js/device-id";
import {
    type AuthorizeUserForAppBody,
    type GetAppUserForAuthenticatedUserBody,
    type GetCustomTokenForAppBody,
    type GetPasswordForEmailPinBody,
    type GetPasswordForOAuth2TokenBody,
    type GetPreviewAsUserBody,
    type SendPinForEmailBody,
    asAppLoginTokenContainer,
} from "@glide/common-core/dist/js/firebase-function-types";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import { reloadBrowserWindow } from "@glide/common-core/dist/js/support/browser-reload";
import { callCloudFunctionWeb } from "@glide/common-core/dist/js/utility/function-utils";
import type { NotificationTarget } from "@glide/plugins";
import {
    type JSONObject,
    checkString,
    getPreviewAsKey,
    isResponseOK,
    isValidEmailAddress,
    localStorageSetItem,
    logError,
    logInfo,
    normalizeEmailAddress,
    parseAnonymousUserID,
    undefinedIfEmptyString,
} from "@glide/support";
import { definedMap, hasOwnProperty } from "collection-utils";
import deepEqual from "deep-equal";

// FIXME: We're caching the app user ID we get here because the way we load apps
// in the dashboard is stupid.  Right now we load and evaluate all the apps, which
// makes their data store want an app user ID.  Instead we should do one of the
// following two:
// 1. Only load but don't evaluate the app.  The app description should have all
//    the data we need in the dashboard.  This should be fairly easy to implement.
// 2. Add a call to the backend to retrieve a user's list of apps with only the
//    data that's required to render the dashboard.  This is harder, but would
//    use less bandwidth and might potentially be faster.
const appUserIDForAutenticatedUser = new Map<string, string>();

interface AppUserInfo {
    readonly appUserID?: string;
    readonly realEmail?: string;
    readonly virtualEmail?: string;
    readonly userProfileRow?: JSONObject;
    readonly loginToken: AppLoginTokenContainer | undefined;
}

// To not get confused about which ##previewAsUser is the right one, we keep
// track of what the source was, and prioritize them.  This is sorted by
// increasing priority, i.e. setting a user as the real app user always wins,
// and the Glide user has lowest priority.
enum UserSource {
    GlideUser,
    DefaultPreviewAs,
    SelectedPreviewAs,
    RealUser,
}

export class FirebaseAppUserAuthenticator implements AppUserAuthenticator {
    private _appUserInfo: AppUserInfo | undefined;
    // `undefined` means we didn't run the callbacks yet
    private _lastCallbackAppUserInfo: AppUserInfo | undefined;
    private _callbacks: Set<AppUserChangedCallback> = new Set();
    private _failedSignIns: number = 0;
    private _passwordID: string | undefined;
    private _passwordIDArmed: boolean = false;
    private _userSource: UserSource | undefined;

    constructor(private readonly _appFacilities: AppFacilities, public readonly appID: string) {}

    public get appUserID(): string | undefined {
        return this._appUserInfo?.appUserID;
    }

    public get realEmail(): string | undefined {
        return undefinedIfEmptyString(definedMap(this._appUserInfo?.realEmail, normalizeEmailAddress));
    }

    public get virtualEmail(): string | undefined {
        return undefinedIfEmptyString(definedMap(this._appUserInfo?.virtualEmail, normalizeEmailAddress));
    }

    public get unnormalizedVirtualEmail(): string | undefined {
        return this._appUserInfo?.virtualEmail;
    }

    public get userProfileRow(): JSONObject | undefined {
        return this._appUserInfo?.userProfileRow;
    }

    public get failedSignIns(): number {
        return this._failedSignIns;
    }

    private incrementFailedSignIns() {
        this._failedSignIns++;
    }

    private clearFailedSignIns() {
        this._failedSignIns = 0;
    }

    public addCallback(callback: AppUserChangedCallback): void {
        this._callbacks.add(callback);
    }

    public removeCallback(callback: AppUserChangedCallback): void {
        this._callbacks.delete(callback);
    }

    private async fetchAppUserID(): Promise<string | undefined> {
        const uid = await this._appFacilities.getAuthUserID();
        if (uid === undefined) return undefined;
        const anonymousUser = parseAnonymousUserID(uid);
        if (anonymousUser !== undefined) {
            const { appUserID } = anonymousUser;
            if (appUserID !== undefined) {
                return appUserID;
            }
            return undefined;
        }

        const idToken = await this._appFacilities.getIDToken();
        if (idToken === undefined) return undefined;

        const maybeAppUserID = appUserIDForAutenticatedUser.get(idToken);
        if (maybeAppUserID !== undefined) {
            return maybeAppUserID;
        }

        const body: GetAppUserForAuthenticatedUserBody = { appID: this.appID };
        const response = await this._appFacilities.callAuthCloudFunction("getAppUserForAuthenticatedUser", body, {});
        if (!isResponseOK(response)) {
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void response?.text();
            return undefined;
        }

        let responseBody: any;
        try {
            responseBody = await response.json();
            if (!hasOwnProperty(responseBody, "appUserID")) return undefined;
        } catch (e: unknown) {
            logError("Reading getAppUserForAuthenticatedUser", e);
            return undefined;
        }

        const resultAppUserID = checkString(responseBody.appUserID);
        appUserIDForAutenticatedUser.set(idToken, resultAppUserID);
        return resultAppUserID;
    }

    private runCallbacks(
        appUserID: string | undefined,
        realEmail: string | undefined,
        virtualEmail: string | undefined,
        loginToken: AppLoginTokenContainer | undefined,
        fromMagicLink: boolean
    ): void {
        realEmail = definedMap(realEmail, normalizeEmailAddress);
        virtualEmail = definedMap(virtualEmail, normalizeEmailAddress);
        for (const callback of this._callbacks) {
            callback(appUserID, realEmail, virtualEmail, loginToken, fromMagicLink);
        }
    }

    private doesSourceOverrideCurrent(source: UserSource): boolean {
        if (this._userSource === undefined) return true;
        return source >= this._userSource;
    }

    // NOTE: This will not try to fetch the app user ID or the real email.
    // You probably want to use `setAppUser` instead.
    private setAppUserWithoutFetching(
        appUserID: string | undefined,
        realEmail: string | undefined,
        userProfileRow: JSONObject | undefined,
        emailColumnName: string | undefined,
        source: UserSource,
        // FIXME: This probably doesn't belong here
        loginToken: AppLoginTokenContainer | undefined,
        fromMagicLink: boolean
    ): void {
        if (!this.doesSourceOverrideCurrent(source)) return;

        // Prevent looping when being set to the same value we already have.
        if (appUserID !== undefined) {
            this._appUserInfo = { loginToken, ...this._appUserInfo, appUserID };
        }
        if (realEmail !== undefined) {
            // `realEmail` is the default for `virtualEmail`
            this._appUserInfo = { loginToken, ...this._appUserInfo, realEmail, virtualEmail: realEmail };
        }
        let virtualEmail: string | undefined;
        if (userProfileRow !== undefined) {
            this._appUserInfo = { loginToken, ...this._appUserInfo, userProfileRow };
            virtualEmail = definedMap(emailColumnName, n => definedMap(userProfileRow[n], checkString));
            if (virtualEmail !== undefined) {
                this._appUserInfo = { ...this._appUserInfo, virtualEmail };
            }
        }

        if (
            this._lastCallbackAppUserInfo === undefined ||
            this._lastCallbackAppUserInfo.appUserID !== appUserID ||
            this._lastCallbackAppUserInfo.realEmail !== realEmail ||
            this._lastCallbackAppUserInfo.virtualEmail !== virtualEmail ||
            !deepEqual(this._lastCallbackAppUserInfo.userProfileRow, userProfileRow, { strict: true }) ||
            !deepEqual(this._lastCallbackAppUserInfo.loginToken, loginToken, { strict: true })
        ) {
            this._userSource = source;

            this.runCallbacks(appUserID, realEmail, virtualEmail, loginToken, fromMagicLink);
            this._lastCallbackAppUserInfo = { appUserID, realEmail, virtualEmail, userProfileRow, loginToken };
        }
    }

    // If `appUserID` is `undefined` then `email` is ignored, and we try
    // to get the app user for the authenticated Glide user.
    private async setAppUser(
        appUserID: string | undefined,
        realEmail: string | undefined,
        userProfileRow: JSONObject | undefined,
        emailColumnName: string | undefined,
        source: UserSource,
        // FIXME: This probably doesn't belong here
        loginToken: AppLoginTokenContainer | undefined,
        fromMagicLink: boolean
    ): Promise<void> {
        if (appUserID === undefined) {
            appUserID = await this.fetchAppUserID();
            if (realEmail === undefined) {
                realEmail = await this._appFacilities.getAppUserEmail();
            }
        }

        this.setAppUserWithoutFetching(
            appUserID,
            realEmail,
            userProfileRow,
            emailColumnName,
            source,
            loginToken,
            fromMagicLink
        );
    }

    // This tries to get the app user ID via the authenticated Glide
    // user.
    public async tryGetAppUserID(): Promise<void> {
        if (this.appUserID !== undefined) return;
        await this.setAppUser(undefined, undefined, undefined, undefined, UserSource.GlideUser, undefined, false);
    }

    private getCommonRequestBody(userProfileRow: JSONObject | undefined): {
        appID: string;
        addUserProfileRow: boolean;
        deviceID: string;
    } {
        return {
            appID: this.appID,
            addUserProfileRow: userProfileRow === undefined && this.userProfileRow === undefined,
            deviceID: getDeviceID(),
        };
    }

    private possiblyInstallInitialSchema(possiblySchema: unknown): void {
        const schema = decodeTypeSchema(possiblySchema);
        if (schema === undefined) return;
        this._appFacilities.setInitialSchemaForAppID(this.appID, schema);
    }

    private async authorizeUserForApp(
        password: string | undefined,
        email: string | undefined,
        userProfileRow: JSONObject | undefined,
        emailColumnName: string | undefined,
        token: AppLoginTokenContainer | undefined,
        fromMagicLink: boolean,
        privateMagicLinkToken: string | undefined
    ): Promise<boolean> {
        const body: AuthorizeUserForAppBody = {
            ...this.getCommonRequestBody(userProfileRow),
            password,
            email,
            token,
            fromMagicLink,
            privateMagicLinkToken,
        };
        const response = await this._appFacilities.callAuthCloudFunction("authorizeUserForApp", body, {});
        if (!isResponseOK(response)) {
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void response?.text();
            this.incrementFailedSignIns();
            return false;
        }

        let responseBody: any;
        try {
            responseBody = await response.json();
        } catch (e: unknown) {
            logError("Reading authorizeUserForAppBody", e);
            this.incrementFailedSignIns();
            return false;
        }

        const {
            appUserID,
            userProfileRow: userProfileRowForRequest,
            emailColumnName: emailColumnNameForRequest,
            schema: possibleSchema,
            passwordID,
            newLoginToken,
        } = responseBody;
        if (userProfileRow === undefined) {
            userProfileRow = userProfileRowForRequest;
        }
        if (emailColumnName === undefined) {
            emailColumnName = emailColumnNameForRequest;
        }
        logInfo("authorized user", appUserID, userProfileRow);
        this.updatePasswordID(passwordID);
        this._appFacilities.setSnapshotLocationsForAppID(this.appID, responseBody);
        this.possiblyInstallInitialSchema(possibleSchema);
        if (appUserID !== undefined) {
            await this.setAppUser(
                appUserID,
                email,
                userProfileRow,
                definedMap(emailColumnName, checkString),
                UserSource.RealUser,
                asAppLoginTokenContainer(newLoginToken),
                false
            );
        } else {
            // If we don't get back an app user ID it means the app doesn't use app users,
            // i.e. it's just password-authenticated.
            this.runCallbacks(undefined, email, email, asAppLoginTokenContainer(newLoginToken), fromMagicLink);
        }
        await hookFirebaseSignOutTransitionEvent(() => reloadBrowserWindow("Firebase signed us out of shared account"));
        this.clearFailedSignIns();
        return true;
    }

    private async signInWithCustomToken(
        customToken: string,
        appUserID: string | undefined,
        email: string | undefined,
        userProfileRow: JSONObject | undefined,
        emailColumnName: string | undefined,
        loginToken: AppLoginTokenContainer | undefined,
        fromMagicLink: boolean
    ): Promise<boolean> {
        logInfo("signing in with custom token");
        const success = await this._appFacilities.signInWithCustomToken(customToken);
        logInfo("signed in with custom token", success);
        if (success) {
            this.clearFailedSignIns();
            await this.setAppUser(
                appUserID,
                email,
                userProfileRow,
                emailColumnName,
                UserSource.RealUser,
                loginToken,
                fromMagicLink
            );
        } else {
            this.incrementFailedSignIns();
        }
        return success;
    }

    public async sendPinForEmail(
        email: string,
        glideCommit: string,
        privateMagicLinkToken: string | undefined
    ): Promise<{ status: ResponseStatus; link?: string; target?: string; method?: NotificationTarget["method"] }> {
        const body: SendPinForEmailBody = { appID: this.appID, email, glideCommit, privateMagicLinkToken };
        const response = await this._appFacilities.callAuthIfAvailableCloudFunction("sendPinForEmail", body, {});

        // The body is only actually returned if we are in a local environment, but either way
        //  we need to drain the response to free up the network connection.
        // FIXME: The call*CloudFunction methods need to take an AbortController!

        const responseText = await response?.text();
        const { link, sendTarget, sendMethod } = parseSendPinForEmailResponse(responseText);

        if (response?.ok === true) return { status: "Success", link, target: sendTarget, method: sendMethod };
        if (response === undefined || Math.floor(response.status / 100) !== 4) {
            const responseKind = response === undefined ? "no" : "bad";
            logError(`Could not get send pin for email - ${responseKind} response, check network status`, response);
            return { status: "Offline" };
        }
        if (response.status === 402) {
            return { status: "PaymentRequired" };
        }
        logError("Could not get send pin for email - email doesn't have access", response);
        return { status: "Forbidden" };
    }

    private async authorizeWithPasswordOrCustomToken(
        appUserID: string | undefined,
        email: string,
        password: string | undefined,
        userProfileRow: JSONObject | undefined,
        emailColumnName: string | undefined,
        customToken: string,
        loginToken: AppLoginTokenContainer | undefined
    ): Promise<{ password: string | undefined; loginToken: AppLoginTokenContainer | undefined } | undefined> {
        let success: boolean;
        logInfo("authorizing with password or custom token");
        if (await this._appFacilities.isSignedInToFirebase()) {
            logInfo("already signed in");
            success = await this.authorizeUserForApp(
                password,
                email,
                userProfileRow,
                emailColumnName,
                loginToken,
                false,
                undefined
            );
        } else {
            logInfo("not signed in yet");
            success = await this.signInWithCustomToken(
                customToken,
                appUserID,
                email,
                userProfileRow,
                emailColumnName,
                loginToken,
                false
            );
        }
        if (!success) {
            logError("Could not authorize or sign in with custom token");
            return undefined;
        }
        return { password, loginToken };
    }

    public async getPasswordForEmailPin(
        email: string,
        pin: string,
        userAgreed: boolean
    ): Promise<{ password: string | undefined; loginToken: AppLoginTokenContainer | undefined } | undefined> {
        const body: GetPasswordForEmailPinBody = { ...this.getCommonRequestBody(undefined), email, pin, userAgreed };
        const response = await callCloudFunctionWeb("getPasswordForEmailPin", body, {});
        if (!isResponseOK(response)) {
            logError("Could not get password for pin", response);
            this.incrementFailedSignIns();
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void response?.text();
            return undefined;
        }

        let responseBody: any;
        try {
            responseBody = await response.json();
        } catch (e: unknown) {
            logError("Reading getPasswordForEmailPin body", e);
            this.incrementFailedSignIns();
            return undefined;
        }

        const {
            password,
            customToken,
            userProfileRow,
            emailColumnName,
            schema: possibleSchema,
            passwordID,
            newLoginToken,
        } = responseBody;
        this.updatePasswordID(passwordID);
        this._appFacilities.setSnapshotLocationsForAppID(this.appID, responseBody);
        this.possiblyInstallInitialSchema(possibleSchema);
        return await this.authorizeWithPasswordOrCustomToken(
            undefined,
            email,
            password,
            userProfileRow,
            definedMap(emailColumnName, checkString),
            customToken,
            asAppLoginTokenContainer(newLoginToken)
        );
    }

    public async getPasswordForOAuth2Token(
        authToken: string,
        userAgreed: boolean
    ): Promise<OAuth2TokenAuth | undefined> {
        const body: GetPasswordForOAuth2TokenBody = { ...this.getCommonRequestBody(undefined), authToken, userAgreed };
        const response = await callCloudFunctionWeb("getPasswordForOAuth2Token", body, {});
        if (response === undefined || (response.status !== 200 && response.status !== 403)) {
            logError("Could not get password for OAuth2 token", response);
            this.incrementFailedSignIns();
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void response?.text();
            return undefined;
        }

        let responseBody: any;
        try {
            responseBody = await response.json();
        } catch (e: unknown) {
            logError("Reading getPasswordForOAuth2Token body", e);
            this.incrementFailedSignIns();
            return undefined;
        }

        const {
            email,
            displayName,
            password,
            customToken,
            userProfileRow,
            emailColumnName,
            schema: possibleSchema,
            passwordID,
            newLoginToken,
        } = responseBody;
        if (typeof email !== "string") {
            this.incrementFailedSignIns();
            return undefined;
        }

        if (response.status === 403) {
            this.incrementFailedSignIns();
            return { email, displayName, password: undefined, userProfileRow: undefined, appLoginToken: undefined };
        }

        this.updatePasswordID(passwordID);
        this._appFacilities.setSnapshotLocationsForAppID(this.appID, responseBody);
        this.possiblyInstallInitialSchema(possibleSchema);
        const appLoginToken = asAppLoginTokenContainer(newLoginToken);
        const validPassword = await this.authorizeWithPasswordOrCustomToken(
            undefined,
            email,
            password,
            userProfileRow,
            definedMap(emailColumnName, checkString),
            customToken,
            appLoginToken
        );
        if (validPassword === undefined) {
            this.incrementFailedSignIns();
            return undefined;
        }

        return { email, displayName, password, userProfileRow, appLoginToken };
    }

    private updatePasswordID(passwordID: unknown): void {
        if (passwordID === undefined) return;

        this._passwordID = checkString(passwordID);
        this._passwordIDArmed = false;
    }

    public mustSignOutForPasswordID(passwordID: string): boolean {
        if (this._passwordID === undefined) {
            return false;
        }
        if (passwordID === this._passwordID) {
            this._passwordIDArmed = true;
            return false;
        }
        return this._passwordIDArmed;
    }

    private async authorizeForApp(
        password: string | undefined,
        email: string | undefined,
        token: AppLoginTokenContainer | undefined,
        fromMagicLink: boolean,
        privateMagicLinkToken: string | undefined
    ): Promise<boolean> {
        if (password === undefined && token === undefined && privateMagicLinkToken === undefined) {
            logError("No credentials");
            return false;
        }

        let success: boolean;
        // See comment in GlideAppPlayer.ts about detecting the
        // signed-in user early.
        logInfo("authorizing for app");
        if (this.appUserID !== undefined && this.realEmail === email) {
            logInfo("already signed in");
            return true;
        } else if (await this._appFacilities.isSignedInToFirebase()) {
            logInfo("signed in - authorizing");
            success = await this.authorizeUserForApp(
                password,
                email,
                undefined,
                undefined,
                token,
                fromMagicLink,
                privateMagicLinkToken
            );
        } else {
            logInfo("not signed in - getting custom token");
            const body: GetCustomTokenForAppBody = {
                ...this.getCommonRequestBody(undefined),
                password,
                email,
                token,
                fromMagicLink,
                privateMagicLinkToken,
            };

            const response = await this._appFacilities.callAuthIfAvailableCloudFunction(
                "getCustomTokenForApp",
                body,
                {}
            );
            if (!isResponseOK(response)) {
                // We have to drain out the body, otherwise we'll just leave the connection
                // around forever.
                void response?.text();
                logError("Could not get custom token", response);
                this.incrementFailedSignIns();
                return false;
            }

            let responseBody: any;
            try {
                responseBody = await response.json();
            } catch (e: unknown) {
                logError("Reading getCustomTokenForApp body", e);
                this.incrementFailedSignIns();
                return false;
            }

            const {
                customToken,
                userProfileRow,
                emailColumnName,
                passwordID,
                schema: possibleSchema,
                newLoginToken,
            } = responseBody;

            this.updatePasswordID(passwordID);

            this._appFacilities.setSnapshotLocationsForAppID(this.appID, responseBody);
            this.possiblyInstallInitialSchema(possibleSchema);
            success = await this.signInWithCustomToken(
                customToken,
                undefined,
                email,
                userProfileRow,
                definedMap(emailColumnName, checkString),
                asAppLoginTokenContainer(newLoginToken),
                fromMagicLink
            );
        }
        if (!success) {
            logError("Could not authorize user");
            return false;
        }
        return true;
    }

    public async authorizeForAppWithEmail(email: string, passwordForEmail: string): Promise<boolean> {
        return await this.authorizeForApp(passwordForEmail, email, undefined, false, undefined);
    }

    public async authorizeForAppWithPassword(password: string): Promise<boolean> {
        return await this.authorizeForApp(password, undefined, undefined, false, undefined);
    }

    public async authorizeForAppWithInviteMagicLink(token: string, email: string): Promise<boolean> {
        return await this.authorizeForApp(undefined, email, undefined, false, token);
    }

    // All magic links have to come through this entry point
    public async authorizeForAppWithLoginToken(
        token: AppLoginTokenContainer,
        email: string | undefined,
        password: string | undefined
    ): Promise<boolean> {
        return await this.authorizeForApp(password, email, token, true, undefined);
    }

    public async setEmailAddressFromBuilder(unnormalizedEmail: string, isSelectedByUser: boolean): Promise<void> {
        const source = isSelectedByUser ? UserSource.SelectedPreviewAs : UserSource.DefaultPreviewAs;
        if (!this.doesSourceOverrideCurrent(source)) return;

        this._appUserInfo = {
            loginToken: undefined,
            ...this._appUserInfo,
            realEmail: unnormalizedEmail,
            virtualEmail: unnormalizedEmail,
            userProfileRow: undefined,
        };
        this._userSource = source;

        localStorageSetItem(getPreviewAsKey(this.appID), unnormalizedEmail);

        // We're just using the getters to get the normalized email addresses
        const { realEmail, virtualEmail } = this;

        const finish = () => {
            this.runCallbacks(this._appUserInfo?.appUserID, realEmail, virtualEmail, undefined, false);
        };

        if (realEmail === undefined) return finish();

        if (!isValidEmailAddress(realEmail)) return finish();

        let appUserID: string | undefined;
        const body: GetPreviewAsUserBody = { appID: this.appID, appUserEmail: realEmail };
        const response = await this._appFacilities.callAuthCloudFunction("getPreviewAsUser", body);

        // Somebody changed the email in the mean time
        if (this._appUserInfo?.realEmail !== unnormalizedEmail) return finish();

        if (response !== undefined && response.ok) {
            try {
                appUserID = checkString((await response.json()).appUserID);
            } catch (e: unknown) {
                logError("Could not get preview as response", e);
            }
        } else if (response !== undefined) {
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void response.text();
        }

        if (this._appUserInfo.realEmail !== unnormalizedEmail) return finish();

        if (appUserID !== undefined) {
            this._appUserInfo = { ...this._appUserInfo, appUserID };
        }

        return finish();
    }
}
