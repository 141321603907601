import { ClickOutsideContainer, copyToClipboard, GlideIcon } from "@glide/common";
import type { ActionStepLogData } from "@glide/common-core";
import { useCallback, useEffect, useMemo, useState } from "react";
import "twin.macro";
import { Button } from "../button/button";
import { JSONViewer } from "../json-viewer/json-viewer";
import { SimpleTooltip } from "../tooltip/simple-tooltip";
import { WireActionResultKind } from "@glide/wire";
import classNames from "classnames";
import { formatDuration } from "./lib";
import { useLayer } from "react-laag";

interface Props {
    readonly logs: readonly ActionStepLogData[];
}

const CopyButton: React.FC<{ onClick: () => void; justCopied: boolean }> = p => {
    const { justCopied, onClick } = p;
    return (
        <div tw="h-5 flex items-center">
            <SimpleTooltip text={justCopied ? "Copied!" : "Copy to clipboard"} instant forceOneLine>
                <Button
                    iconType="iconOnly"
                    icon={justCopied ? "st-check" : "st-clipboard"}
                    size="xsm"
                    label="Copy data"
                    buttonType="minimal"
                    onClick={onClick}
                    tw="group-hover:opacity-100 opacity-0 -mt-1"
                />
            </SimpleTooltip>
        </div>
    );
};

const DataRow: React.FC<{ label: string; value: unknown }> = ({ label, value }) => {
    // show JSON-like objects as JSON
    const parsedValue = useMemo(() => {
        if (typeof value === "string") {
            try {
                return JSON.parse(value);
            } catch {
                // do nothing;
            }
        }
        return value;
    }, [value]);
    const stringValue = useMemo(() => JSON.stringify(parsedValue), [parsedValue]);

    const [justCopied, setJustCopied] = useState(false);

    const copyDataToClipboard = useCallback(async () => {
        copyToClipboard(JSON.stringify(value, null, 2));
        setJustCopied(true);
    }, [value]);

    useEffect(() => {
        if (justCopied) {
            const timeout = window.setTimeout(() => {
                setJustCopied(false);
            }, 1000);
            return () => window.clearTimeout(timeout);
        }
        return undefined;
    }, [justCopied]);

    const isJSON = typeof parsedValue === "object" && parsedValue !== null;

    return (
        <div tw="text-builder-base relative [.w-rjv-line]:whitespace-nowrap" className="group">
            <div tw="flex items-start gap-0.5 overflow-x-auto">
                <span tw="whitespace-nowrap font-medium mr-1">{label}:</span>
                {isJSON ? (
                    <JSONViewer json={parsedValue} />
                ) : (
                    <>
                        <span tw="font-mono">{stringValue}</span>
                        <CopyButton onClick={copyDataToClipboard} justCopied={justCopied} />
                    </>
                )}
            </div>
            {isJSON && (
                <div tw="absolute right-0 top-0">
                    <CopyButton onClick={copyDataToClipboard} justCopied={justCopied} />
                </div>
            )}
        </div>
    );
};

const DataBlock: React.FC<React.PropsWithChildren<{ title: string; data: Record<string, unknown> }>> = ({
    title,
    data,
}) => {
    const keys = useMemo(() => Object.keys(data), [data]);
    if (keys.length === 0) return null;
    return (
        <div>
            <div tw="text-builder-lg font-medium mb-1">{title}</div>
            <div tw="text-builder-base flex flex-col gap-0.5">
                {keys.map(key => (
                    <DataRow label={key} key={key} value={data[key]} />
                ))}
            </div>
        </div>
    );
};

const MessageBlock: React.FC<{ message: string; success: boolean; errorData?: Record<string, unknown> }> = p => {
    const { message, success, errorData } = p;
    const [showErrorData, setShowErrorData] = useState(false);

    const { layerProps, renderLayer, triggerProps } = useLayer({
        isOpen: showErrorData,
        container: "portal",
        auto: true,
        containerOffset: 14,
        triggerOffset: 4,
        placement: "top-end",
    });

    const hasErrorData = success === false && errorData !== undefined;

    return (
        <div
            className={classNames({ error: success === false })}
            tw="text-text-base -mx-2 [&.error]:(bg-r100 text-text-danger mx-0) py-2 px-2 text-builder-base rounded-lg select-text flex gap-2">
            <div tw="grow">{message}</div>
            {hasErrorData && (
                <div tw="shrink-0">
                    <SimpleTooltip text="View error data" instant forceOneLine hide={showErrorData}>
                        <button
                            {...triggerProps}
                            className={classNames({ open: showErrorData })}
                            tw="p-1 rounded-md [&.open]:bg-r100 hover:bg-r200"
                            onClick={() => setShowErrorData(true)}>
                            <GlideIcon tw="text-icon-danger" icon="st-json" iconSize={12} kind="stroke" />
                        </button>
                    </SimpleTooltip>
                </div>
            )}
            {hasErrorData &&
                showErrorData &&
                renderLayer(
                    <ClickOutsideContainer onClickOutside={() => setShowErrorData(false)} stopPropagation={true}>
                        <div
                            className="click-outside-ignore"
                            {...layerProps}
                            tw="bg-bg-front px-4 py-3 rounded-xl shadow-xl max-w-[90%]">
                            <DataBlock title="Error data" data={errorData ?? {}} />
                        </div>
                    </ClickOutsideContainer>
                )}
        </div>
    );
};

const LogView: React.FC<{ index: number; data: ActionStepLogData; isFirst: boolean }> = p => {
    const { index, isFirst } = p;
    const { message, data, outputs, resultKind, startedAt, finishedAt, errorData } = p.data;
    const [isOpen, setIsOpen] = useState(isFirst);
    const success = [WireActionResultKind.Success, WireActionResultKind.Offline].includes(
        resultKind as WireActionResultKind
    );

    const duration = useMemo(() => {
        if (finishedAt === undefined) return undefined;
        const durationInSeconds = (new Date(finishedAt).getTime() - new Date(startedAt).getTime()) / 1000;
        return formatDuration(durationInSeconds);
    }, [finishedAt, startedAt]);

    return (
        <div className={classNames({ open: isOpen })} tw="flex flex-col gap-1 rounded-md [&.open]:bg-n100A">
            <button tw="h-6 px-2 hover:bg-n200A flex items-center gap-2 rounded-md" onClick={() => setIsOpen(!isOpen)}>
                {success ? (
                    <GlideIcon tw="text-icon-success" icon="st-check" iconSize={16} kind="stroke" />
                ) : (
                    <GlideIcon tw="text-icon-danger" icon="st-alert-warning" iconSize={16} kind="stroke" />
                )}
                <div tw="text-builder-base text-text-base grow text-left">#{index + 1}</div>
                {duration !== undefined && <div tw="text-builder-sm text-text-pale">{duration}</div>}
                <GlideIcon
                    tw="text-icon-base"
                    icon="st-chevron-right"
                    iconSize={16}
                    kind="stroke"
                    rotateDeg={isOpen ? 90 : 0}
                />
            </button>
            {isOpen && (
                <div tw="px-2 flex flex-col gap-2 mb-2 overflow-x-hidden">
                    {message !== undefined && (
                        <MessageBlock message={message} success={success} errorData={errorData} />
                    )}
                    {data !== undefined && <DataBlock title="Data" data={data} />}
                    {outputs !== undefined && <DataBlock title="Outputs" data={outputs} />}
                </div>
            )}
        </div>
    );
};

export const StepLogs: React.FC<Props> = p => {
    const { logs } = p;
    const [scrolled, setScrolled] = useState(false);

    const onScroll = useCallback((e: React.UIEvent) => {
        setScrolled(e.currentTarget.scrollTop > 0);
    }, []);

    const setRef = useCallback((node: HTMLDivElement | null) => {
        if (node) {
            node.scrollTop = node.scrollHeight;
        }
    }, []);

    return (
        <div
            className={classNames({ scrolled })}
            tw="flex flex-col gap-1 max-h-full overflow-y-auto [&.scrolled]:shadow-[inset 0 2px 4px rgba(0,0,0,0.1)]"
            onScroll={onScroll}
            ref={setRef}>
            {logs.map((log, index) => (
                <LogView key={index} index={index} data={log} isFirst={index === 0} />
            ))}
        </div>
    );
};
