/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { maybeParseJSON } from "@glide/support";
import md5 from "blueimp-md5";

const apiKey = glide.makeParameter({
    name: "API Key",
    type: "secret",
    description: "You can find your API key in your DcosAutomator account's settings",
    required: true,
});

const email = glide.makeParameter({
    name: "Email",
    type: "string",
    description: "The email address you registered with",
    required: true,
});

export const plugin = glide.newPlugin({
    id: "docsautomator",
    name: "DocsAutomator",
    description: "Create PDFs and Google Docs from your data",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/docsautomator.png",
    tier: "starter",
    parameters: { apiKey, email },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/docsautomator",
});

const data = glide.makeParameter({
    name: "Data",
    type: "stringObject",
    description: "Data to pass to the PDF template",
});

const templateID = glide.makeParameter({
    name: "Template ID",
    type: "string",
    description: "The PDF template you would like to use",
    required: true,
});

const fileName = glide.makeParameter({
    name: "Filename",
    type: "string",
    description: "The name you want to give to the PDF file",
    placeholder: "e.g. receipt",
});

const url = glide.makeParameter({
    name: "File Output",
    type: "url",
    description: "Choose the column where the URL to the PDF file will be saved",
    required: true,
});

plugin.addAction({
    id: "generate-document",
    name: "Generate document",
    description: "Generate a document file using DocsAutomator",
    billablesConsumed: 5,
    parameters: { templateID, fileName, data },
    results: { url },
    deprecated: true,
    execute: async (ctx, { data, templateID, fileName, email, apiKey }) => {
        return await ctx.useCache(async () => {
            const result = await ctx.fetch("https://api.docsautomator.co/api/createDocument", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "x-api-key": apiKey ?? "",
                },
                body: JSON.stringify({
                    docId: templateID,
                    email,
                    documentName: fileName,
                    ...data,
                }),
            });

            if (!result.ok) {
                const text = await result.text();
                const data = maybeParseJSON(text) ?? text;
                const message =
                    typeof data === "object" && "message" in data && typeof data.message === "string"
                        ? data.message
                        : undefined;
                return glide.Result.FailFromHTTPStatus(message ?? `Could not generate PDF`, result.status, {
                    data,
                    showInBuilder: true,
                });
            }

            const json = await result.json();

            if (json.pdfUrl === undefined) return glide.Result.Fail("Could not find document URL");

            // for backwards compatibility, we didn't have this flag, so we always rehosted
            const rehosted = await ctx.rehostFile(fileName ?? md5(JSON.stringify({ data, templateID })), json.pdfUrl);

            if (!rehosted.ok) {
                return rehosted;
            }

            ctx.consumeBillable();
            return glide.Result.Ok({ url: rehosted.result });
        }, [templateID, fileName, data, rehostFile, apiKey, email]);
    },
});

const dataV2 = glide.makeParameter({
    name: "Data",
    type: "jsonObject",
    description: "Data to pass to the PDF template",
});

const rehostFile = glide.makeParameter({
    name: "Rehost File",
    type: "boolean",
    description: "Rehost the file in Glide",
    defaultValue: true,
});

plugin.addAction({
    id: "generate-document-v2",
    name: "Generate document",
    description: "Generate a document file using DocsAutomator",
    billablesConsumed: 3,
    parameters: { templateID, fileName, data: dataV2, rehostFile },
    results: { url },
    execute: async (ctx, { data, templateID, fileName, email, rehostFile, apiKey }) => {
        return await ctx.useCache(async () => {
            const result = await ctx.fetch("https://api.docsautomator.co/api/createDocument", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "x-api-key": apiKey ?? "",
                },
                body: JSON.stringify({
                    docId: templateID,
                    email,
                    documentName: fileName,
                    ...data,
                }),
            });

            if (!result.ok) {
                const text = await result.text();
                const data = maybeParseJSON(text) ?? text;
                const message =
                    typeof data === "object" && "message" in data && typeof data.message === "string"
                        ? data.message
                        : undefined;
                return glide.Result.FailFromHTTPStatus(message ?? `Could not generate PDF`, result.status, {
                    data,
                    showInBuilder: true,
                });
            }

            const json = await result.json();

            if (json.pdfUrl === undefined) return glide.Result.Fail("Could not find document URL");

            if (rehostFile === true) {
                const rehosted = await ctx.rehostFile(
                    fileName ?? md5(JSON.stringify({ data, templateID })),
                    json.pdfUrl
                );

                if (!rehosted.ok) {
                    return rehosted;
                }

                ctx.consumeBillable();
                return glide.Result.Ok({ url: rehosted.result });
            } else {
                ctx.consumeBillable();
                return glide.Result.Ok({ url: json.pdfUrl });
            }
        }, [templateID, fileName, data, rehostFile, apiKey, email]);
    },
});
