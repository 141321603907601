import { GlideIcon } from "@glide/common";
import { TextComponentStyle } from "@glide/component-utils";
import type { WirePageSignatureFieldComponent } from "@glide/fluent-components/dist/js/base-components";
import { isDefined, isEmptyOrUndefinedish } from "@glide/support";
import { isAccentBackgroundStyle, isDarkBackgroundStyle } from "@glide/wire";
import classNames from "classnames";
import { definedMap } from "collection-utils";
import * as React from "react";
import type { WireRenderer } from "../wire-renderer";
import { css } from "styled-components";
import tw from "twin.macro";

import { Img } from "../../components/img/img";
import { Text } from "../../components/text/text";
import { useSectionStyle } from "../wire-container/wire-container";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";

export const WireSignatureField: WireRenderer<WirePageSignatureFieldComponent> = React.memo(p => {
    const { title, signature, triggerSignaturePadAction, clearSignatureAction, backend, isRequired } = p;

    const sectionStyle = useSectionStyle();

    if (!isDefined(signature) || !isDefined(triggerSignaturePadAction)) {
        return null;
    }

    const triggerSignaturePad = () => {
        backend.runAction(triggerSignaturePadAction.token, true);
    };

    const clearSignature = definedMap(clearSignatureAction, a => () => {
        backend.runAction(a.token, true);
    });

    const hasSignature = signature !== "";

    const isAccent = isAccentBackgroundStyle(sectionStyle);
    const isDark = isDarkBackgroundStyle(sectionStyle);
    return (
        <>
            <div tw="flex justify-between">
                {!isEmptyOrUndefinedish(title) && (
                    <Text element="h3" variant={TextComponentStyle.headlineXXXSmall} tw="mb-2">
                        {title}
                    </Text>
                )}
                {isRequired && (
                    <div
                        data-testid="wf-required"
                        tw="shrink-0 ml-2 text-xs text-text-contextual-pale font-normal justify-end">
                        {getLocalizedString("required", AppKind.Page)}
                    </div>
                )}
            </div>
            <div
                tw="bg-n100A rounded-lg [min-height:100px] overflow-hidden relative"
                className={classNames({
                    accent: isAccent,
                    dark: isDark,
                })}
                css={css`
                    ${tw`transition-colors bg-n100A page-hover:bg-n200A`}

                    &.accent, &.dark {
                        ${tw`transition-colors bg-w10A page-hover:bg-w20A`}
                    }
                `}>
                <button tw="flex justify-center items-center w-full h-full relative" onClick={triggerSignaturePad}>
                    <span tw="absolute inset-x-4 bottom-4 border-b border-n200A" aria-hidden="true"></span>
                    {!hasSignature ? (
                        <>
                            <GlideIcon
                                kind="component"
                                icon="co-signature"
                                tw="text-text-contextual-accent mr-2"
                                iconSize={20}
                            />
                            <Text
                                element="p"
                                variant={TextComponentStyle.headlineXXXSmall}
                                tw="text-text-contextual-accent">
                                {getLocalizedString("addSignature", AppKind.Page)}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Img
                                alt={title ?? undefined}
                                src={signature}
                                tw="relative [max-height:360px]"
                                css={css`
                                    .accent &,
                                    .dark & {
                                        ${tw`invert filter`}
                                    }
                                `}
                            />
                        </>
                    )}
                </button>
                {hasSignature && isDefined(clearSignature) && (
                    <button
                        tw="absolute top-0 right-0 p-3 transition-colors text-text-contextual-pale
                            page-hover:text-text-contextual-base active:text-text-contextual-base"
                        onClick={clearSignature}>
                        <GlideIcon kind="monotone" icon="mt-circular-cross" iconSize={20} />
                    </button>
                )}
            </div>
        </>
    );
});
