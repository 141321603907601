/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { maybeParseJSON } from "@glide/support";
import { sleep } from "@glideapps/ts-necessities";
import md5 from "blueimp-md5";

const apiKey = glide.makeParameter({
    name: "API Key",
    type: "secret",
    description: "You can find your API key in your PDFMonkey account's settings",
    required: true,
});

export const plugin = glide.newPlugin({
    id: "pdf-moneky", // Yes this is misspelled - we need to keep this scar forever now
    name: "PDFMonkey",
    description: "Create PDFs using PDFMonkey",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/pdfmonkey.png",
    tier: "starter",
    parameters: { apiKey },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/pdf-monkey",
});

plugin.useSecret({
    kind: "authorization-bearer",
    value: apiKey,
    baseUrl: "https://api.pdfmonkey.io/api/",
});

const data = glide.makeParameter({
    name: "Data",
    type: "stringObject",
    description: "Data to pass to the PDF template",
});

const templateID = glide.makeParameter({
    name: "Template ID",
    type: "string",
    description: "The PDF template you would like to use",
    required: true,
});

const fileName = glide.makeParameter({
    name: "Filename",
    type: "string",
    description: "The name you want to give to the PDF file",
    placeholder: "e.g. receipt",
});

const url = glide.makeParameter({
    name: "File Output",
    type: "url",
    description: "Choose the column where the URL to the PDF file will be saved",
    required: true,
});

plugin.addAction({
    id: "generate-pdf",
    name: "Generate PDF",
    description: "Generate a PDF file using PDFMonkey",
    billablesConsumed: 5,
    parameters: { templateID, fileName, data },
    results: { url },
    execute: async (ctx, { data, templateID, fileName }) => {
        return await ctx.useCache(async () => {
            const result = await ctx.fetch("https://api.pdfmonkey.io/api/v1/documents", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    document: {
                        document_template_id: templateID,
                        payload: data,
                        meta: {
                            _filename: fileName,
                        },
                        status: "pending",
                    },
                }),
            });

            if (!result.ok) {
                return glide.Result.FailFromHTTPStatus("Could not generate PDF", result.status, {
                    step: "generate",
                    data: maybeParseJSON(await result.text()),
                });
            }

            const documentCard = await result.json();
            let downloadUrl: string | undefined;

            for (let i = 0; i < 20; i++) {
                const documentDataResult = await ctx.fetch(
                    "https://api.pdfmonkey.io/api/v1/document_cards/" + documentCard.document.id
                );

                if (!documentDataResult.ok) {
                    return glide.Result.FailFromHTTPStatus(
                        "Could not fetch document status",
                        documentDataResult.status,
                        {
                            step: "poll",
                            data: maybeParseJSON(await documentDataResult.text()),
                        }
                    );
                }

                const json = await documentDataResult.json();
                const status = json?.document_card?.status;
                if (status === "pending" || status === "generating") {
                    await sleep(2000);
                    continue;
                }
                if (status === "success") {
                    downloadUrl = json.document_card.download_url;
                    if (typeof downloadUrl !== "string") {
                        return glide.Result.Fail("Download URL not found", {
                            isPluginError: false,
                        });
                    }
                } else {
                    return glide.Result.Fail("Failed to generate", {
                        data: json,
                    });
                }

                break;
            }

            if (downloadUrl === undefined) {
                return glide.Result.Fail("Download URL not found", {
                    isPluginError: false,
                });
            }

            const rehosted = await ctx.rehostFile(fileName ?? md5(JSON.stringify({ data, templateID })), downloadUrl);

            if (!rehosted.ok) {
                return rehosted;
            }

            ctx.consumeBillable();
            return glide.Result.Ok({ url: rehosted.result });
        }, [templateID, fileName, data]);
    },
});
