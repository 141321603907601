import type { JSONObject } from "@glide/support";
import { isArray } from "@glide/support";
import { hasOwnProperty, mapFilterUndefined } from "@glideapps/ts-necessities";
import { isGlideDateTimeDocumentData } from "@glide/data-types";

// These "ref objects" implement old-school relations that are specified
// manually in Google Sheets: https://www.youtube.com/watch?v=TH5jk855JrM

export enum RefPredicate {
    KeyContainsValue = "key-contains-value",
}

type RefKey = string | ReadonlyArray<string>;

interface KeyRefObject {
    readonly $ref: string;
    readonly $key: RefKey;
    readonly $value?: unknown;
    readonly $multiple: boolean;
    readonly $predicate?: RefPredicate;
}

interface ArrayRefObject {
    readonly $ref: ".";
    readonly $arrayItems: ReadonlyArray<string>;
}

export function makeArrayRefObject(itemColumnNames: readonly string[]): ArrayRefObject {
    return {
        $ref: ".",
        $arrayItems: itemColumnNames,
    };
}

export type RefObject = KeyRefObject | ArrayRefObject;

function isRefObject(obj: unknown): obj is RefObject {
    return typeof obj === "object" && hasOwnProperty(obj, "$ref") && typeof obj.$ref === "string";
}

export function isKeyRefObject(obj: unknown): obj is KeyRefObject {
    return isRefObject(obj) && hasOwnProperty(obj, "$key");
}

function isArrayRefObject(obj: unknown): obj is ArrayRefObject {
    return isRefObject(obj) && !isKeyRefObject(obj);
}

function arrayValuesAsStrings(value: unknown): string | undefined {
    if (typeof value === "string") {
        return value;
    } else if (typeof value === "number") {
        return String(value);
    } else {
        return undefined;
    }
}

// Return the value of `columnName` in `parentObject` converting to a string as necessary.
export function getStringOrStringArrayColumn(
    parentObject: JSONObject,
    columnName: string,
    isPartial: boolean
): readonly string[] | undefined {
    const columnValue = parentObject[columnName];
    if (isArray(columnValue)) {
        return mapFilterUndefined(columnValue, arrayValuesAsStrings);
    } else if (isArrayRefObject(columnValue)) {
        return mapFilterUndefined(resolveArrayRef(columnValue.$arrayItems, parentObject), arrayValuesAsStrings);
    } else if (typeof columnValue === "string") {
        return [columnValue];
    } else if (typeof columnValue === "number") {
        return [String(columnValue)];
    } else if (isGlideDateTimeDocumentData(columnValue)) {
        if (columnValue.repr === undefined) {
            return undefined;
        }
        return [columnValue.repr];
    } else if (columnValue === undefined && isPartial) {
        return undefined;
    } else {
        return [];
    }
}

function isValueNotEmpty(v: unknown): boolean {
    if (v === undefined || v === null) return false;
    if (v === "") return false;
    if (typeof v === "number" && isNaN(v)) return false;
    if (isArray(v)) return v.length > 0;
    return true;
}

function resolveArrayRef<T>(arrayItems: readonly string[], parentObject: Record<string, T> | undefined): T[] {
    const values: T[] = [];
    for (const key of arrayItems) {
        const v = parentObject?.[key];
        if (v !== undefined && isValueNotEmpty(v)) {
            values.push(v);
        }
    }
    return values;
}
