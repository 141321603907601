import Color from "color";
import type { PalletteType } from "./color-types";
import { Pallettes } from "./color-types";

export function getColorsFromPallette(pallette: PalletteType, primaryAccentColor: string): string[] {
    if (pallette === "Turrell") {
        return getColorsGivenPrimary(primaryAccentColor, 10);
    } else {
        return Pallettes[pallette];
    }
}

function getLuminosity(startColor: string): number {
    const primary = Color(startColor, "hex");
    return primary.luminosity();
}

export function getContrastColor(startColor: string): string {
    if (getLuminosity(startColor) > 0.5) {
        return "black";
    } else {
        return "white";
    }
}

export function getColorsGivenPrimary(primaryColor: string, numberOfColors: number): string[] {
    const colors: string[] = [];
    const primary = Color(primaryColor, "hex");
    let h = primary.hue();
    let s = primary.saturationl();
    let l = primary.luminosity();

    for (let i = 0; i < Math.ceil(numberOfColors / 10); i++) {
        for (let j = 0; j < 10; j++) {
            if (10 * i + j >= numberOfColors) {
                break;
            }
            const newl = Math.round(20 + ((j + 1) / 10) * 70);
            const c = Color([h, s, newl], "hsl");
            colors.push(c.hex());
        }
        const next = Color([(h + 137) % 360, s, l], "hsl");
        h = next.hue();
        s = next.saturationl();
        l = next.luminosity();
    }

    return colors;
}
