import { formatNumber, formatNumberFixed } from "@glide/support";
import type { FormatNumberSpecification } from "@glide/formula-specifications";

// ##formatCurrency:
// FIXME: Unify this code.  Until then, keep it in sync.
export function formatNumberWithSpecification(
    spec: FormatNumberSpecification | undefined,
    n: number
): string | undefined {
    if (isNaN(n)) return undefined;

    if (spec === undefined) {
        return formatNumber(n);
    } else if (spec.unit === "$" && !spec.unitAfterNumber) {
        return formatNumberFixed(n, spec.decimalsAfterPoint, spec.groupSeparator, "USD");
    } else {
        const s = formatNumberFixed(n, spec.decimalsAfterPoint, spec.groupSeparator, undefined);
        if (spec.unit === undefined) {
            return s;
        }
        if (spec.unitAfterNumber) {
            return s + spec.unit;
        } else {
            return spec.unit + s;
        }
    }
}
