import { getLocalizedString } from "@glide/localization";
import { MenuItemPurpose } from "@glide/common-core/dist/js/components/types";
import { asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    makeColumnProperty,
    makeStringProperty,
} from "@glide/app-description";
import { type TableColumn, getTableColumnDisplayName, isPrimitiveType } from "@glide/type-schema";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireAppNotesComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    isRequiredPropertyHandler,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { assert, defined } from "@glideapps/ts-necessities";
import {
    type WireInflationBackend,
    type WireRowComponentHydratorConstructor,
    type WireScreen,
    type WireAction,
    WireActionResult,
    ValueChangeSource,
    WireComponentKind,
} from "@glide/wire";
import isString from "lodash/isString";
import {
    hydrateSubsidiaryScreenFlag,
    inflateEditablePropertyWithDefault,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerActionRunner,
    spreadComponentID,
} from "../wire/utils";
import {
    doesMutatingScreenSupportIsRequired,
    labelCaptionStringOptions,
    makeCaptionStringPropertyDescriptor,
    makePlaceholderPropertyDescriptor,
    makePrimaryKeyPropertyHandler,
} from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";
import { makeOverlayEditorSubsidiaryScreen } from "./overlay-editor";

const ComponentKindNotes: ComponentKind = "notes";

const propertyNamePropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.Searchable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
    ],
    undefined,
    ["notes", "note"],
    getPrimitiveColumnsSpec,
    "string",
    PropertySection.Data
);

interface NotesComponentDescription extends ComponentDescription {
    readonly propertyName: PropertyDescription;
    readonly primaryKeyProperty: PropertyDescription | undefined;
    readonly placeholder: PropertyDescription;
    readonly title: PropertyDescription;
}

export class NotesComponentHandler extends ComponentHandlerBase<NotesComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindNotes);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public needValidation(desc: NotesComponentDescription): boolean {
        return isRequiredPropertyHandler.getSwitch(desc);
    }

    public getDescriptor(
        desc: NotesComponentDescription | undefined,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [
            propertyNamePropertyHandler,
            ...makePrimaryKeyPropertyHandler(ccc, tables?.input),
            makeCaptionStringPropertyDescriptor("Notes", false, mutatingScreenKind, labelCaptionStringOptions, "title"),
            makePlaceholderPropertyDescriptor(mutatingScreenKind, PropertySection.Data),
            ...this.getBasePropertyDescriptors(),
        ];
        if (doesMutatingScreenSupportIsRequired(mutatingScreenKind, desc?.propertyName)) {
            properties.push(isRequiredPropertyHandler);
        }
        return {
            name: "Notes",
            description: "Text that you can read and edit",
            img: "co-notes",
            group: "Entry Fields",
            helpUrl: getDocURL("notes"),
            properties,
        };
    }

    public static defaultComponent(column: TableColumn): NotesComponentDescription {
        assert(isPrimitiveType(column.type));
        return {
            ...makeEmptyComponentDescription(ComponentKindNotes),
            kind: ComponentKindNotes,
            propertyName: makeColumnProperty(column.name),
            primaryKeyProperty: undefined,
            placeholder: makeStringProperty(""),
            title: makeStringProperty(getTableColumnDisplayName(column)),
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: NotesComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const {
            forBuilder,
            adc: { appKind },
        } = ib;

        const { getter: valueGetter, isInContext } = inflateEditablePropertyWithDefault(
            ib,
            "set",
            desc.propertyName,
            asMaybeString,
            "",
            desc.primaryKeyProperty
        );
        if (valueGetter === undefined) return undefined;

        const isRequired = isRequiredPropertyHandler.getSwitch(desc);

        const [titleGetter] = inflateStringProperty(ib, desc.title, true);
        const [placeholderGetter] = inflateStringProperty(ib, desc.placeholder, true);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const value = valueGetter(hb);
            if (value?.onChangeToken === undefined) return undefined;

            const title = titleGetter(hb);
            let placeholder = placeholderGetter(hb) ?? "";
            if (placeholder === "") {
                placeholder = getLocalizedString("writeANote", appKind);
            }

            const valueCopy = hb.getState("valueCopy", isString, "", true);
            const [subsidiaryOpen, toggleSubsidiaryRunner] = hydrateSubsidiaryScreenFlag(
                hb,
                "editorSubsidiaryOpen",
                (ab, shouldOpen) => {
                    if (shouldOpen) {
                        ab.valueChanged(valueCopy.onChangeToken, value.value, ValueChangeSource.Internal);
                    }
                }
            );
            const toggleSubsidiaryAction = defined(registerActionRunner(hb, "toggleEditor", toggleSubsidiaryRunner));

            let onTap: WireAction | undefined;
            let subsidiaryScreen: WireScreen | undefined;
            if (subsidiaryOpen.value) {
                const doneToken = hb.registerAction("done", async ab => {
                    ab.valueChanged(defined(value.onChangeToken), valueCopy.value, ValueChangeSource.User);
                    ab.valueChanged(subsidiaryOpen.onChangeToken, false, ValueChangeSource.User);
                    return WireActionResult.nondescriptSuccess();
                });
                subsidiaryScreen = makeOverlayEditorSubsidiaryScreen(
                    appKind,
                    "",
                    valueCopy,
                    placeholder,
                    getLocalizedString("done", appKind),
                    "check",
                    MenuItemPurpose.SetValue,
                    doneToken,
                    toggleSubsidiaryAction,
                    `notes-${desc.componentID}`
                );
            } else {
                onTap = toggleSubsidiaryAction;
            }

            const component: WireAppNotesComponent = {
                kind: WireComponentKind.AppNotes,
                ...spreadComponentID(desc.componentID, forBuilder),
                title,
                value: value.value,
                onTap,
                isRequired,
            };

            const hasValue = value.value !== "";
            return {
                component,
                isValid: !isRequired || hasValue,
                editsInContext: isInContext,
                hasValue,
                subsidiaryScreen,
            };
        });
    }

    public convertToPage(
        desc: NotesComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return {
            ...makeEmptyComponentDescription(WireComponentKind.Note),
            label: desc.title,
            text: desc.propertyName,
            // classic app notes have no actions,
            // though perhaps worth noting(ha, ha)
            // they do behave very differently.
        } as ComponentDescription;
    }
}
