import type { OrgPlanIconNames } from "@glide/common-core/dist/js/billing-vnext/org-plan-lib";
import { assertNever } from "@glideapps/ts-necessities";
import classNames from "classnames";
import styled, { css } from "styled-components";
import tw from "twin.macro";

import {
    BusinessTeamIcon,
    EnterpriseTeamIcon,
    FixedUpdatesIcon,
    FreeTeamIcon,
    ProTeamIcon,
    ScaledUpdatesIcon,
    StarterTeamIcon,
    TrialIcon,
} from "./org-svgs";

interface Props extends React.PropsWithChildren {
    size: number | undefined;
    icon: OrgPlanIconNames;
    hasBackground?: boolean;
    verticalAlignment?: "items-start" | "items-center" | "items-end";
}

const getIconForTeam = (iconName: OrgPlanIconNames) => {
    switch (iconName) {
        case "st-test-team":
        case "st-personal-team":
            return FreeTeamIcon;
        case "st-starter-team":
            return StarterTeamIcon;
        case "st-pro-team":
            return ProTeamIcon;
        case "st-business-team":
            return BusinessTeamIcon;
        case "st-enterprise-team":
            return EnterpriseTeamIcon;
        case "st-scaled-updates":
            return ScaledUpdatesIcon;
        case "st-fixed-updates":
            return FixedUpdatesIcon;
        case "st-trial":
            return TrialIcon;
        default:
            assertNever(iconName);
    }
};
const OrgIcon = styled.div<Props>`
    & svg {
        stroke-width: 1.5;
        width: ${p => p.size ?? 24}px;
        height: ${p => p.size ?? 24}px;

        transition: transform 0.15s ease;
    }
`;

export const OrgPlanIcon: React.FC<React.PropsWithChildren<Props>> = p => {
    const { icon, hasBackground = true, verticalAlignment = "items-center" } = p;
    const PlanIcon = getIconForTeam(icon);
    return (
        <OrgIcon
            className={classNames(hasBackground && "has-background", verticalAlignment)}
            {...p}
            css={css`
                &.has-background {
                    ${tw`p-3 rounded-lg bg-bg-front`}
                }
                &.items-center {
                    ${tw`items-center`}
                }

                &.items-end {
                    ${tw`items-end`}
                }

                &.items-start {
                    ${tw`items-start`}
                }
            `}
            tw="flex justify-center">
            {PlanIcon}
        </OrgIcon>
    );
};
export { ProTeamIcon };
