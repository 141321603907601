import type { ComponentIndexes } from "@glide/common-core/dist/js/component-indexes";
import {
    type AppDescription,
    type ActionDescription,
    type ArrayFilter,
    type ArrayPivot,
    type ArrayScreenDescription,
    type ArrayTransform,
    type ClassOrArrayScreenDescription,
    type ColumnAssignment,
    type ColumnAssignmentsDescription,
    type ColumnAssignmentsWithLegacyDescription,
    type LegacyPropertyDescription,
    type LimitArrayTransform,
    type PropertyDescription,
    type SortArrayTransform,
    type SortOrder,
    type TabDescription,
    type TransformableContentDescription,
    type PropertyKind,
    ActionKind,
    ArrayTransformKind,
    MutatingScreenKind,
    isFormScreen,
    getActionProperty,
    getArrayProperty,
    getColumnProperty,
    getEnumProperty,
    getFilterProperty,
    getNumberProperty,
    getScreenProperty,
    getSourceColumnProperty,
    getStringProperty,
    getSwitchProperty,
    getTableProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeSpecialValueProperty,
} from "@glide/app-description";
import { getAppTabs } from "@glide/common-core/dist/js/components/SerializedApp";
import type { MissingTablesAndColumns } from "@glide/common-core/dist/js/Database";
import {
    type TableName,
    type UniversalTableName,
    areTableNamesEqual,
    getDebugPrintTableName,
    isFavoritedColumnName,
    type ColumnType,
    type Description,
    type SourceColumn,
    type TableColumn,
    type TableGlideType,
    type TableRefGlideType,
    type TypeSchema,
    type UniversalTableRefGlideType,
    BinaryPredicateFormulaOperator,
    FormulaKind,
    SourceColumnKind,
    SpecialValueKind,
    areSourceColumnsEqual,
    findTable,
    getEmailOwnersColumnNames,
    getSourceColumnPath,
    getTableColumn,
    getTableColumnDisplayName,
    getTableName,
    getTableRefTableName,
    isColumnWritable,
    isComputedColumn,
    isDataSourceColumn,
    isMultiRelationType,
    isPrimitiveType,
    isSingleRelationType,
    isSourceColumn,
    isStringTypeKind,
    isStringTypeOrStringTypeArray,
    type ConstantFormula,
    type SchemaInspector,
    type TableColumnLike,
    isQueryableExternalSourceMetadata,
} from "@glide/type-schema";
import {
    type ActionWithOutputRowDescription,
    type DynamicSortColumn,
    type InputOutputTables,
    type TitleDescription,
    ImageKind,
    doesMutatingScreenAddRows,
    getInputOrOutputTable,
    isEmailArrayFilter,
    makeInputOutputTables,
} from "@glide/common-core/dist/js/description";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import {
    type ActionNodeInScope,
    type ActionPropertyDescriptor,
    type ActionsRecord,
    type IsEditedInApp,
    type PropertyDescriptor,
    type PropertyDescriptorCase,
    type PropertyTable,
    type PropertyTableGetter,
    resolveSourceColumn,
    dynamicFilterColumnPropertyHandler,
    findFittingColumnInTable,
    findTitleColumnForTable,
    getMenuScreenName,
    getPropertyDescription,
    getPropertyDescriptionValue,
    imageProperties,
    isImageType,
    isMultiCasePropertyDescriptor,
    isNamedPropertySource,
    thisRowSourceColumn,
} from "@glide/function-utils";
import { assertNever, mapFilterUndefined, type Writable, assert, defined, definedMap } from "@glideapps/ts-necessities";
import { areSetsEqual, findType, shallowEqualArrays, truthify } from "@glide/support";
import deepEqual from "deep-equal";
import last from "lodash/last";
import toPairs from "lodash/toPairs";
import { decomposeSortKey } from "@glide/formula-specifications";
import { getTargetForLink } from "./link-columns";
import { MissingTablesAndColumnsAccumulator } from "./missing-tables-and-columns";

function findImageColumn(
    table: TableGlideType,
    mustBeImageURI: boolean,
    forListItem: boolean,
    allowArrayColumn: boolean,
    excludeColumns: ReadonlySet<string> | undefined
): TableColumn | undefined {
    const isImage = (t: ColumnType) => isImageType(t, forListItem, allowArrayColumn);
    return findFittingColumnInTable(
        table,
        imageProperties,
        excludeColumns,
        isImage,
        mustBeImageURI ? isImage : allowArrayColumn ? isStringTypeOrStringTypeArray : t => isStringTypeKind(t.kind),
        true
    );
}

// FIXME: Implement this via the summary property descriptors
export function propertiesInSummary(desc: TitleDescription): string[] {
    return mapFilterUndefined([desc.titleProperty, desc.subtitleProperty, desc.imageURLProperty], getColumnProperty);
}

export function summaryColumnsForTable(
    table: TableGlideType,
    titleRequired: boolean,
    makeSummaryIfPossible: boolean,
    forListItem: boolean = false,
    allowArrayColumn: boolean = true,
    excludeColumns: ReadonlySet<string> | undefined = undefined
): { [K in keyof TitleDescription]: PropertyDescription | undefined } | undefined {
    const titleProperty = findTitleColumnForTable(table, !makeSummaryIfPossible, excludeColumns);
    if (titleRequired && titleProperty === undefined) return undefined;

    const exclude = new Set(excludeColumns);

    let subtitleProperty: string | undefined;
    if (titleProperty !== undefined) {
        exclude.add(titleProperty);
        subtitleProperty = findTitleColumnForTable(table, !makeSummaryIfPossible, exclude);
    } else {
        subtitleProperty = undefined;
    }

    if (subtitleProperty !== undefined) {
        exclude.add(subtitleProperty);
    }

    const imageURLProperty = findImageColumn(table, true, forListItem, allowArrayColumn, exclude)?.name;

    if (titleProperty === undefined && subtitleProperty === undefined && imageURLProperty === undefined) {
        return undefined;
    }

    if (imageURLProperty !== undefined) {
        exclude.add(imageURLProperty);
    }

    return {
        titleProperty: makeColumnProperty(titleProperty),
        subtitleProperty: makeColumnProperty(subtitleProperty),
        imageURLProperty: makeColumnProperty(imageURLProperty),
        imageKind: makeEnumProperty(imageURLProperty !== undefined ? ImageKind.URL : undefined),
    };
}

// Returns `true` if the filter columns exist, otherwise a set of the ones that
// are missing
export function doFilterColumnsExist(filter: ArrayFilter, table: TableGlideType): true | Set<string> {
    const nonExisting = new Set<string>();
    const valueColumn = getColumnProperty(filter.value as PropertyDescription);
    if (valueColumn !== undefined) {
        const column = getTableColumn(table, valueColumn);
        if (column === undefined) {
            nonExisting.add(valueColumn);
        }
    }

    const keyColumn = getColumnProperty(filter.key as PropertyDescription);
    if (keyColumn !== undefined) {
        const column = getTableColumn(table, keyColumn);
        if (column === undefined) {
            nonExisting.add(keyColumn);
        }
    }

    if (nonExisting.size === 0) {
        return true;
    }
    return nonExisting;
}

export function makeInputOutputTablesForClassOrArrayScreen(
    desc: ClassOrArrayScreenDescription,
    getTable: (tableRef: TableRefGlideType) => TableGlideType | undefined
): InputOutputTables | undefined {
    if (!isSingleRelationType(desc.type)) return undefined;
    const table = getTable(desc.type);
    if (table === undefined) return undefined;

    let outputTable: TableGlideType;
    if (isFormScreen(desc)) {
        const t = getTable(desc.formType);
        if (t === undefined) return undefined;
        outputTable = t;
    } else {
        outputTable = table;
    }

    return makeInputOutputTables(table, outputTable);
}

export function inputOutputTablesForClassOrArrayScreen(
    desc: ClassOrArrayScreenDescription,
    schema: TypeSchema
): InputOutputTables | undefined {
    return makeInputOutputTablesForClassOrArrayScreen(desc, r => findTable(schema, r));
}

export function getPropertyDescriptorCaseForKind(
    kind: PropertyKind,
    descr: PropertyDescriptor
): PropertyDescriptorCase | undefined {
    if (isMultiCasePropertyDescriptor(descr)) {
        return descr.cases.find(c => c.kind === kind);
    } else if (descr.kind === kind) {
        return descr;
    } else {
        return undefined;
    }
}

export function getPropertyDescriptorCaseForDescription(
    desc: PropertyDescription,
    descr: PropertyDescriptor
): PropertyDescriptorCase | undefined {
    return getPropertyDescriptorCaseForKind(desc.kind, descr);
}

export function getSummarySearchProperties(desc: TitleDescription): readonly string[] {
    const searchProperties: string[] = [];
    const titleProperty = definedMap(desc.titleProperty, getColumnProperty);
    const subtitleProperty = definedMap(desc.subtitleProperty, getColumnProperty);
    if (titleProperty !== undefined) {
        searchProperties.push(titleProperty);
    }
    if (subtitleProperty !== undefined) {
        searchProperties.push(subtitleProperty);
    }
    return searchProperties;
}

export function getPropertyAndDescriptorFromDescription<T>(
    desc: Description,
    pdescr: PropertyDescriptor
):
    | {
          description: PropertyDescription;
          descriptorCase: PropertyDescriptorCase;
      }
    | undefined {
    const pd = getPropertyDescription<T>(desc, pdescr);
    if (pd === undefined) {
        return undefined;
    }

    const pdc = getPropertyDescriptorCaseForDescription(pd, pdescr);
    if (pdc === undefined) {
        return undefined;
    }

    return { description: pd, descriptorCase: pdc };
}

export function areColumnTypesEqualExceptPrimitives(t1: ColumnType, t2: ColumnType): boolean {
    if (isPrimitiveType(t1)) {
        return isPrimitiveType(t2);
    } else if (t1.kind === "array") {
        if (t2.kind !== "array") return false;
        if (isPrimitiveType(t1.items)) {
            return isPrimitiveType(t2.items);
        } else if (isSingleRelationType(t1.items)) {
            return (
                isSingleRelationType(t2.items) &&
                areTableNamesEqual(getTableRefTableName(t1.items), getTableRefTableName(t2.items))
            );
        } else {
            assertNever(t1.items);
        }
    } else if (isSingleRelationType(t1)) {
        return isSingleRelationType(t2) && areTableNamesEqual(getTableRefTableName(t1), getTableRefTableName(t2));
    } else {
        assertNever(t1);
    }
}

const defaultMetadataDifferences = {
    oneIsMissing: false,
    numRows: false,
    sheetMetadata: false,
    rowIDColumn: false,
    tableOrder: false,
    columnDisplayName: false,
    columnReadonly: false,
    columnFromNativeTable: false,
    columnFromExternalSource: false,
};

// We don't want to redraw the builder if only the number of rows changed.
export function doMetadataDifferencesRequireBuilderUpdate(d: MetadataDifferences): boolean {
    return (
        d.oneIsMissing || d.sheetMetadata || d.rowIDColumn || d.tableOrder || d.columnDisplayName || d.columnReadonly
    );
}

type MetadataDifferences = Record<keyof typeof defaultMetadataDifferences, boolean>;

export interface SchemaDifferences {
    readonly metadata: MetadataDifferences | undefined;
    readonly tablesOrColumns: boolean;
    readonly tablesAdded: boolean;
    // Not an exhaustive list
    readonly issues: readonly string[];
    readonly missing: MissingTablesAndColumns | undefined;
}

// Unfortunately we can't compare schemas with `deepEqual` because they
// contain ancillary information, in particular row counts. "metadata" means
// only metadata changed.  NOTE: The Row ID column counts as only metadata.
export function compareSchemas(
    schema1: TypeSchema | undefined,
    schema2: TypeSchema | undefined,
    accumulateMissing: boolean,
    areColumnTypesEqual?: (t1: ColumnType, t2: ColumnType) => boolean
): SchemaDifferences | undefined {
    let metadataDifferences: MetadataDifferences | undefined;

    function addMetadataDifference(diff: Partial<MetadataDifferences>) {
        if (metadataDifferences === undefined) {
            metadataDifferences = defaultMetadataDifferences;
        }
        metadataDifferences = { ...metadataDifferences, ...diff };
    }

    if (schema1 === undefined || schema2 === undefined) {
        addMetadataDifference({ oneIsMissing: true });
        return schema1 === schema2
            ? undefined
            : {
                  metadata: metadataDifferences,
                  tablesOrColumns: true,
                  tablesAdded: true,
                  issues: ["One schema is undefined"],
                  missing: undefined,
              };
    }

    if (schema1 === schema2) return undefined;

    if (areColumnTypesEqual === undefined) {
        areColumnTypesEqual = (t1, t2) => deepEqual(t1, t2, { strict: true });
    }

    const missing = accumulateMissing ? new MissingTablesAndColumnsAccumulator() : undefined;

    const tablesAdded = schema2.tables.some(t => findTable(schema1, getTableName(t)) === undefined);
    let tablesOrColumns = tablesAdded;
    const issues: string[] = [];

    for (const table1 of schema1.tables) {
        const tableName = getTableName(table1);
        const table2 = findTable(schema2, tableName);
        if (table2 === undefined) {
            tablesOrColumns = true;
            issues.push(`Table ${tableName.name} not found in new schema`);
            missing?.addTable(table1);
            continue;
        }

        if (table1.numDataRows !== table2.numDataRows) {
            addMetadataDifference({ numRows: true });
            issues.push(`Numbers of data rows differ in ${tableName.name}`);
        }

        if (table1.sheetID !== table2.sheetID || table1.sheetName !== table2.sheetName) {
            addMetadataDifference({ sheetMetadata: true });
            issues.push(`Sheet metadata differs in ${tableName.name}`);
        }

        if (table1.rowIDColumn !== table2.rowIDColumn) {
            addMetadataDifference({ rowIDColumn: true });
            issues.push(`Row ID columns differ in ${tableName.name}`);
        }

        if (schema1.tables.indexOf(table1) !== schema2.tables.indexOf(table2)) {
            addMetadataDifference({ tableOrder: true });
            issues.push(`Index of table ${tableName.name} differs`);
        }

        if (!areSetsEqual(getEmailOwnersColumnNames(table1), getEmailOwnersColumnNames(table2))) {
            tablesOrColumns = true;
            issues.push(`Email owners columns differ in ${tableName.name}`);
        }

        if (table1.columns.length !== table2.columns.length) {
            tablesOrColumns = true;
            issues.push(`Number of columns differ in ${tableName.name}`);
        }

        if (table1.columns.length === table2.columns.length || accumulateMissing) {
            for (const column1 of table1.columns) {
                const column2 = getTableColumn(table2, column1.name);
                if (column2 === undefined) {
                    tablesOrColumns = true;
                    issues.push(`Column ${column1.name} not found in ${tableName.name}`);
                    missing?.addColumn(table1, column1.name);
                    continue;
                }

                if (column1.displayName !== column2.displayName) {
                    addMetadataDifference({ columnDisplayName: true });
                    // if (column1.name !== isFavoritedColumnName) {
                    issues.push(`Column display names differ for ${column1.name} in ${tableName.name}`);
                    // }
                }

                if (!areColumnTypesEqual(column1.type, column2.type)) {
                    tablesOrColumns = true;
                    issues.push(
                        `Column types of ${column1.name} differ in ${tableName.name}.  Kind was ${JSON.stringify(
                            column1.type
                        )}, is ${JSON.stringify(column2.type)}.`
                    );
                    // }
                    continue;
                }

                if ((column1.hidden === true) !== (column2.hidden === true)) {
                    tablesOrColumns = true;
                    if (column1.name !== isFavoritedColumnName) {
                        issues.push(`Column ${column1.name} hidden is different in ${tableName.name}`);
                    }
                }

                if ((column1.isProtected === true) !== (column2.isProtected === true)) {
                    tablesOrColumns = true;
                    if (column1.name !== isFavoritedColumnName) {
                        issues.push(`Column ${column1.name} protected is different in ${tableName.name}`);
                    }
                }

                if (
                    (column1.isUserSpecific === true) !== (column2.isUserSpecific === true) ||
                    !deepEqual(column1.formula, column2.formula, { strict: true }) ||
                    !deepEqual(column1.displayFormula, column2.displayFormula, { strict: true })
                ) {
                    tablesOrColumns = true;
                    issues.push(`Column ${column1.name} differs in ${tableName.name}`);
                    continue;
                }

                if ((column1.isReadOnly === true) !== (column2.isReadOnly === true)) {
                    addMetadataDifference({ columnReadonly: true });
                    issues.push(`Column ${column1.name} read-only is different in ${tableName.name}`);
                }

                if ((column1.fromNativeTable === true) !== (column2.fromNativeTable === true)) {
                    addMetadataDifference({ columnFromNativeTable: true });
                    issues.push(`Column ${column1.name} from native table is different in ${tableName.name}`);
                }

                if ((column1.fromExternalSource === true) !== (column2.fromExternalSource === true)) {
                    addMetadataDifference({ columnFromExternalSource: true });
                    issues.push(`Column ${column1.name} from external source is different in ${tableName.name}`);
                }
            }
        }
    }

    if (metadataDifferences === undefined && !tablesOrColumns && !tablesAdded) return undefined;

    return { metadata: metadataDifferences, tablesOrColumns, tablesAdded, issues, missing: missing?.getMissing() };
}

export function setEmailOwnersColumnsInSchema(
    schema: TypeSchema,
    updates: readonly (readonly [TableName, readonly string[]])[]
): [TypeSchema, readonly TableName[]] | number {
    const tablesToUpdate: TableName[] = [];

    const newTables: TableGlideType[] = [];
    for (const table of schema.tables) {
        const tableName = getTableName(table);
        if (tableName.isSpecial) {
            newTables.push(table);
            continue;
        }

        const update = updates.find(u => areTableNamesEqual(tableName, u[0]));
        if (update === undefined) {
            newTables.push(table);
            continue;
        }

        const columnNames = update[1];

        let newTable = { ...table };

        for (const columnName of columnNames) {
            const column = getTableColumn(table, columnName);
            if (column === undefined) return 400;
            if (!isStringTypeOrStringTypeArray(column.type)) return 400;
        }

        newTable = { ...newTable, emailOwnersColumn: columnNames };

        newTables.push(newTable);
        tablesToUpdate.push(tableName);
    }

    return [{ ...schema, tables: newTables }, tablesToUpdate];
}

export function makeMissingColumnMessage(column: string | SourceColumn): string | undefined {
    if (!getFeatureSetting("showMissingEntities")) return undefined;
    if (isSourceColumn(column)) {
        const name = last(getSourceColumnPath(column));
        if (name === undefined) return undefined;
        column = name;
    }
    return `The column "${column}" doesn't exist anymore`;
}

export function makeMissingTableMessage(tableName: UniversalTableName): string | undefined {
    if (!getFeatureSetting("showMissingEntities")) return undefined;
    return `The sheet "${getDebugPrintTableName(tableName)}" doesn't exist anymore`;
}

export function makeEmailArrayFilter(columnName: string): ArrayFilter {
    return {
        key: makeSpecialValueProperty(SpecialValueKind.VerifiedEmailAddress),
        operator: BinaryPredicateFormulaOperator.Equals,
        value: makeColumnProperty(columnName),
    };
}

export function getEmailArrayFilter(desc: TransformableContentDescription): ArrayFilter | undefined {
    const filter = getFilterProperty(desc.filter);
    if (filter === undefined || !isEmailArrayFilter(filter)) return undefined;
    return filter;
}

export function isRoleOrOwnersColumnType(table: TableGlideType | undefined, type: ColumnType): boolean {
    if (!isStringTypeOrStringTypeArray(type)) return false;
    if (type.kind === "array") {
        // SQL data sources don't support arrays as row owners
        if (table !== undefined && isQueryableExternalSourceMetadata(table.sourceMetadata)) return false;

        const itemsKind = type.items.kind;
        if (itemsKind !== "string" && itemsKind !== "email-address") return false;
    }
    return true;
}

export function canBeRoleOrOwnersColumn(table: TableGlideType | undefined, column: TableColumn): boolean {
    if (isComputedColumn(column) || column.isUserSpecific === true) return false;
    return isRoleOrOwnersColumnType(table, column.type);
}

export type ColumnProtectionKind = "legacy-sheets" | "pure-native" | "external-native" | "queryable";

export function canColumnBeProtected(column: TableColumn, protectionKind: ColumnProtectionKind): boolean {
    if (protectionKind === "pure-native" || protectionKind === "queryable") return false;
    return isDataSourceColumn(column, protectionKind !== "legacy-sheets");
}

export function makeInputOutputTablesForFormOnSubmitAction(screenTables: InputOutputTables): InputOutputTables {
    // This is one readonly why there is no ##onSubmitMutatingScreenKind -
    // there's only the output row.
    return makeInputOutputTables(screenTables.output);
}

export function getColumnNameAndGroup(c: TableColumn | string): [string | undefined, string] {
    const name = typeof c === "string" ? c : getTableColumnDisplayName(c);

    const idx = name.indexOf("/");
    if (idx < 0) {
        return [undefined, name];
    } else {
        return [name.substring(0, idx).trimRight(), name.substring(idx + 1).trimLeft()];
    }
}

function removeEmptyColumnAssignments(assignments: readonly ColumnAssignment[]): readonly ColumnAssignment[] {
    // ##emptyColumnAssignments:
    // An empty "Custom" assignment will write a string property with an empty
    // string. Those count as if they weren't present for set/add row actions.
    return assignments.filter(a => getStringProperty(a.value)?.trim() !== "");
}

export function getColumnAssignments(screen: ColumnAssignmentsDescription): readonly ColumnAssignment[] {
    return removeEmptyColumnAssignments(screen.columnAssignments ?? []);
}

export function getColumnAssignmentsWithLegacy(
    desc: ColumnAssignmentsWithLegacyDescription
): readonly ColumnAssignment[] {
    const columnAssignments = Array.from(getColumnAssignments(desc));
    for (const [destColumn, value] of toPairs(desc.columns)) {
        // Don't generate duplicates. We had a bug where we would keep the old
        // ones, but ignore them, and let users add new ones, so the new-style
        // assignments have priority.
        if (columnAssignments.some(ca => ca.destColumn === destColumn)) continue;
        columnAssignments.push({ destColumn, value });
    }
    return removeEmptyColumnAssignments(columnAssignments);
}

export function getActionPropertyWithDescriptor(
    desc: Description,
    descr: ActionPropertyDescriptor
): ActionDescription | undefined {
    // This is where we handle the special case for ##inlineListDefaultAction
    // where we treat `undefined` as the default action.
    const { defaultActionForUndefined } = descr;
    let hadValue = false;

    let action = getPropertyDescriptionValue(desc, descr, pd => {
        hadValue = true;
        return getActionProperty(pd);
    });

    if (action !== undefined && action.kind !== ActionKind.Compound && !descr.kinds.includes(action.kind)) {
        action = undefined;
    }

    if ((action === undefined && descr.required === true) || (defaultActionForUndefined === true && !hadValue)) {
        action = definedMap(descr.kinds[0], k => ({ kind: k }));
    }

    return action;
}

export type ActionsWithDescriptors = readonly [
    descriptor: ActionPropertyDescriptor,
    action: ActionDescription | undefined,
    name: string
][];

export function getActionsForDescriptors(
    desc: Description,
    descriptors: readonly ActionPropertyDescriptor[]
): ActionsWithDescriptors {
    const pairs: [ActionPropertyDescriptor, ActionDescription | undefined, string][] = [];
    for (const descr of descriptors) {
        assert(isNamedPropertySource(descr.property));
        const action = getActionPropertyWithDescriptor(desc, descr);
        pairs.push([descr, action, descr.property.name]);
    }
    return pairs;
}

export function getActionsRecord(desc: Description, descriptors: readonly ActionPropertyDescriptor[]): ActionsRecord {
    const record: Writable<ActionsRecord> = { actions: [] };

    for (const [, action, name] of getActionsForDescriptors(desc, descriptors)) {
        if (action !== undefined) {
            record[name] = [action];
        } else {
            record[name] = [];
        }
    }

    return record;
}

export function areComponentIndexesEqual(a?: ComponentIndexes, b?: ComponentIndexes): boolean {
    if (a === undefined && b === undefined) return true;
    if (a === undefined || b === undefined) return false;
    return shallowEqualArrays(a, b);
}

export function previousComponentIndex(indexes: ComponentIndexes): ComponentIndexes | undefined {
    if (indexes.length === 1) {
        if (indexes[0] === 0) return undefined;
        return [indexes[0] - 1];
    } else if (indexes.length === 2) {
        if (indexes[1] === 0) {
            return [indexes[0]];
        } else {
            return [indexes[0], indexes[1] - 1];
        }
    } else {
        return assertNever(indexes);
    }
}

export function getMenuScreenNames(appDescription: AppDescription, menuID: string): readonly string[] {
    const prefix = getMenuScreenName(menuID, undefined);
    const screenNames: string[] = [];
    for (const screenName of Object.keys(appDescription.screenDescriptions)) {
        if (screenName.startsWith(prefix)) {
            screenNames.push(screenName);
        }
    }
    return screenNames;
}

export function findTabInAppDescription(
    tabScreenName: string,
    appDescription: AppDescription
): [TabDescription, number] | undefined {
    const tabs = getAppTabs(appDescription);
    const index = tabs.findIndex(t => getScreenProperty(t.screenName) === tabScreenName);
    if (index < 0) return undefined;
    return [tabs[index], index];
}

interface GetRelationForDescriptionOptions {
    readonly inOutputRow: boolean;
    readonly onlySingleRelations: boolean;
    readonly defaultToThisRow: boolean;
    readonly allowFullTable: boolean;
}

// This will not default to "this row"
export function getSourceColumnOrThis(pd: LegacyPropertyDescription | undefined): SourceColumn | undefined {
    const sourceColumn = getSourceColumnProperty(pd);
    if (sourceColumn !== undefined) {
        return sourceColumn;
    }
    // `true` is legacy
    if (getEnumProperty(pd) === true) {
        return thisRowSourceColumn;
    }
    return undefined;
}

type RelationGetter = (
    desc: PropertyDescription | undefined,
    // This doesn't take a `StaticActionContext` because we it only needs
    // these, and some callers don't have `priorSteps`.
    schema: SchemaInspector,
    tables: InputOutputTables | undefined,
    actionNodesInScope: readonly ActionNodeInScope[] | undefined
) =>
    | {
          sourceColumn: SourceColumn | undefined;
          column: TableColumnLike | undefined;
          table: TableGlideType;
          isMulti: boolean;
          isThisRow: boolean;
      }
    | undefined;

// This is curried, because we always use the same options for a particular
// component/action.
//
// `undefined` means error
// `sourceColumn === undefined` means the whole table
// `sourceColumn !== undefined && column === undefined` means the whole row
// `table` is the type of the column or row, not the table that contains the
//         column!
export function getRelationForDescription({
    inOutputRow,
    onlySingleRelations,
    defaultToThisRow,
    allowFullTable,
}: GetRelationForDescriptionOptions): RelationGetter {
    if (allowFullTable) {
        assert(!onlySingleRelations);
    }

    return (desc, schema, tables, actionNodesInScope) => {
        const contextTable = definedMap(tables, ts => getInputOrOutputTable(ts, inOutputRow));

        const tableName = getTableProperty(desc);
        if (tableName !== undefined) {
            if (!allowFullTable) return undefined;
            const table = schema.findTable(tableName);
            if (table === undefined) return undefined;

            return {
                sourceColumn: undefined,
                column: undefined,
                table,
                isMulti: true,
                isThisRow: false,
            };
        }

        const sourceColumn = getSourceColumnOrThis(desc);
        if (sourceColumn === undefined) {
            // We used to use the enum value `true` for this, and before that
            // we didn't even have this property and "this row" was all you
            // got, so it's the default if no other option works.
            if (defaultToThisRow) {
                if (contextTable === undefined) return undefined;
                return {
                    sourceColumn: thisRowSourceColumn,
                    column: undefined,
                    table: contextTable,
                    isMulti: false,
                    isThisRow: true,
                };
            } else {
                return undefined;
            }
        }

        const resolved = resolveSourceColumn(schema, sourceColumn, contextTable, undefined, actionNodesInScope);
        if (resolved?.type === undefined) return undefined;

        let type: UniversalTableRefGlideType;
        let isMulti: boolean;
        if (isSingleRelationType(resolved.type)) {
            type = resolved.type;
            isMulti = false;
        } else if (!onlySingleRelations && isMultiRelationType(resolved.type)) {
            type = resolved.type.items;
            isMulti = true;
        } else {
            return undefined;
        }

        const table = schema.findTable(type);
        if (table === undefined) return undefined;

        return {
            sourceColumn,
            column: resolved.tableAndColumn?.column,
            table,
            isMulti,
            isThisRow: resolved.path.length === 0 && sourceColumn.kind === SourceColumnKind.DefaultContext,
        };
    };
}

export function makeGetIndirectTableForActionWithOutputRow(relationGetter: RelationGetter): PropertyTableGetter {
    return function (
        tables: InputOutputTables | undefined,
        _rootDesc: Description,
        desc: Description,
        schema: SchemaInspector,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): PropertyTable | undefined {
        const tableAndColumn = relationGetter(
            (desc as ActionWithOutputRowDescription).outputRow,
            schema,
            tables,
            actionNodesInScope
        );
        if (tableAndColumn === undefined) return undefined;
        const { table, sourceColumn } = tableAndColumn;
        const inScreenContext = areSourceColumnsEqual(defined(sourceColumn), thisRowSourceColumn);
        if (inScreenContext) {
            assert(table === tables?.output);
        }
        return { table, inScreenContext };
    };
}

export function getCanAddRow(desc: ArrayScreenDescription | undefined): boolean {
    return truthify(getSwitchProperty(desc?.canAddRow));
}

export function allowProtectedColumns(
    isEditedInApp: IsEditedInApp | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined
): boolean {
    if (isEditedInApp !== true) return false;
    return mutatingScreenKind === MutatingScreenKind.AddScreen || mutatingScreenKind === MutatingScreenKind.FormScreen;
}

// Return all columns for which we can make edit components.  If `columns` are
// undefined then it uses `table.columns`.
// This does handling of protected columns which
// ##getWritableColumnsForColumnAssignment doesn't do.
// NOTE: `table` must be the table that `columns` are from, if the latter is
// given.
export function filterEditableColumns(
    table: TableGlideType,
    columns: readonly TableColumn[] | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined,
    schema: SchemaInspector
): readonly TableColumn[] {
    const forAddingRow = doesMutatingScreenAddRows(mutatingScreenKind);
    const allowProtected = allowProtectedColumns(true, mutatingScreenKind);
    return (columns ?? table.columns).filter(
        c =>
            isColumnWritable(c, table, forAddingRow, { allowProtected, allowArrays: true }) ||
            getTargetForLink(table, c, schema, true) !== undefined
    );
}

// This is how we decode ##favoritesPivots.
export function decomposeFavoritesPivot(
    pivots: readonly ArrayPivot[] | undefined
): { readonly favoritesLabel: string | undefined } | undefined {
    if (pivots === undefined) return undefined;
    assert(pivots.length === 2);
    return { favoritesLabel: pivots[1].title };
}

export function getArrayScreenDynamicFilterAndSorts(
    desc: ArrayScreenDescription,
    schema: SchemaInspector,
    withSort: boolean
):
    | {
          table: TableGlideType;
          filterColumn: TableColumn | undefined;
          sortColumns: readonly TableColumn[];
      }
    | undefined {
    const table = isSingleRelationType(desc.type) ? schema.findTable(desc.type) : undefined;
    if (table === undefined) return undefined;

    const filterColumnName = dynamicFilterColumnPropertyHandler.getColumnName(desc);
    const filterColumn = filterColumnName !== undefined ? getTableColumn(table, filterColumnName) : undefined;

    let sortColumns: TableColumn[];
    if (withSort) {
        sortColumns = Array.from(
            new Set(
                (getArrayProperty<DynamicSortColumn>(desc.dynamicSortColumns)
                    ?.map(dsc => definedMap(getColumnProperty(dsc.column), c => getTableColumn(table, c)))
                    ?.filter(c => c !== undefined) as TableColumn[]) ?? []
            )
        );
    } else {
        sortColumns = [];
    }

    return {
        table,
        filterColumn,
        sortColumns,
    };
}

export function areTransformsEmpty(transforms: readonly ArrayTransform[] | undefined): boolean {
    if (transforms === undefined || transforms.length === 0) {
        return true;
    }

    for (const t of transforms) {
        switch (t.kind) {
            case ArrayTransformKind.Filter:
                if (t.isActive !== true) continue;

                if (t.predicate.kind !== FormulaKind.Constant) return false;
                const constant = t.predicate as ConstantFormula;
                if (constant.value !== true) return false;
                break;

            case ArrayTransformKind.Limit:
            case ArrayTransformKind.Sort:
            case ArrayTransformKind.Shuffle:
                return false;

            case ArrayTransformKind.TableOrder:
                if (t.reverse) return false;
                break;

            default:
                return assertNever(t);
        }
    }

    return true;
}

export function isTabScreenName(appDescription: AppDescription, screenName: string): boolean {
    return getAppTabs(appDescription).some(t => getScreenProperty(t.screenName) === screenName);
}

// The "required" and "default value" options only make sense for components
// that edit in the screen's context, because edits targeting the user profile
// row (for example) already have a value, and are not submitted either.
export function isPropertyInOtherContext(desc: PropertyDescription | undefined): boolean {
    const sourceColumnKind = getSourceColumnProperty(desc)?.kind;
    return sourceColumnKind !== undefined && sourceColumnKind !== SourceColumnKind.DefaultContext;
}

export function getSortFromArrayTransforms(
    transforms: readonly ArrayTransform[]
): { columnName: string; order: SortOrder } | undefined {
    const sortTransform = findType(transforms, (t): t is SortArrayTransform => t.kind === ArrayTransformKind.Sort);
    if (sortTransform === undefined || sortTransform.keys.length === 0) return undefined;

    assert(sortTransform.keys.length === 1);
    const { key, order } = sortTransform.keys[0];

    const columnName = decomposeSortKey(key);
    if (columnName === undefined) return undefined;

    return { columnName, order };
}

export function getLimitFromArrayTransforms(transforms: readonly ArrayTransform[]): number | undefined {
    const limitTransform = findType(transforms, (t): t is LimitArrayTransform => t.kind === ArrayTransformKind.Limit);
    if (limitTransform === undefined) return undefined;

    const limit = getNumberProperty(limitTransform.numRows);
    if (limit === undefined) return undefined;

    return limit;
}
