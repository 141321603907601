import * as React from "react";
import type { Subtract } from "utility-types";
import { CredentialsConsumer } from "@glide/auth-controller-core";
import type { AppCredentials } from "@glide/auth-controller-core";

interface WithCredentialsProps {
    credentials: AppCredentials;
}

export const withCredentials = <P extends WithCredentialsProps>(Component: React.ComponentType<P>) =>
    class WithAppID extends React.Component<Subtract<P, WithCredentialsProps>> {
        public render() {
            return (
                <CredentialsConsumer>
                    {creds => <Component {...(this.props as P)} credentials={creds} />}
                </CredentialsConsumer>
            );
        }
    };
