import { definedMap } from "@glideapps/ts-necessities";
import { isEmptyOrUndefined } from "@glide/support";

function getPhoneNumberDigits(s: string): string {
    const digits: string[] = [];
    for (const d of s) {
        if ("0123456789".includes(d)) {
            digits.push(d);
        } else if ("+*#,".includes(d)) {
            digits.push(encodeURIComponent(d));
        }
    }
    return digits.join("");
}

export function makeSMSURL(number: string | undefined, body: string | undefined): string | undefined {
    const numbers = getPhoneNumberDigits(number ?? "");

    body = definedMap(body, s => s.trim());

    if (numbers === "" && isEmptyOrUndefined(body)) return undefined;

    let url = "sms://" + numbers;
    if (!isEmptyOrUndefined(body)) {
        // The ampersand doesn't work on Android.  In some circumstances,
        // dual-encoding the body makes it work, but then it completely
        // breaks in other cases and the body will show up encoded.
        // body = body.replace(/&/g, encodeURIComponent("&"));
        url = url + "?&body=" + encodeURIComponent(body);
    }
    return url;
}

export function makeTelephoneURL(number: string): string | undefined {
    const numbers = getPhoneNumberDigits(number);
    if (numbers.length === 0) return undefined;

    return "tel:" + number;
}
