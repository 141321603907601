import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { type SerializablePluginMetadata, isSerializablePluginsMetadata } from "@glide/plugins-codecs";
import { type ChangeObservable, makeChangeObservableForPromise } from "@glide/support";
import { sleep } from "@glideapps/ts-necessities";
import { useChangeObservable } from "@glide/common";
import type { Subtract } from "utility-types";

async function fetchPluginsMetadata(): Promise<readonly SerializablePluginMetadata[]> {
    let res: Response | undefined;
    let count = 0;

    while (count < 3) {
        count++;
        res = await getAppFacilities().callCloudFunction("getPluginsMetadata", {}, {});
        if (res === undefined || !res.ok) {
            await sleep(500);
            continue;
        }
        try {
            const json = await res.json();
            // Doing this becuase TS was mad because of strict-boolean-expressions rule
            if (isSerializablePluginsMetadata(json) === true) {
                return json;
            }
        } catch {
            return []; // invalid response is not worth retrying
        }
        await sleep(500);
    }
    return [];
}

let promise: Promise<readonly SerializablePluginMetadata[]> | undefined;

export function getPluginsMetadata(): Promise<readonly SerializablePluginMetadata[]> {
    if (promise === undefined) {
        promise = fetchPluginsMetadata();
    }
    return promise;
}

let changeObservable: ChangeObservable<readonly SerializablePluginMetadata[] | undefined> | undefined;

function getPluginsMetadataChangeObservable(): ChangeObservable<readonly SerializablePluginMetadata[] | undefined> {
    if (changeObservable === undefined) {
        changeObservable = makeChangeObservableForPromise(getPluginsMetadata());
    }
    return changeObservable;
}

export function usePluginsMetadata(): readonly SerializablePluginMetadata[] {
    const maybePluginsMetadata = useChangeObservable(getPluginsMetadataChangeObservable());
    return maybePluginsMetadata ?? [];
}

export interface WithPluginsMetadataProps {
    pluginsMetadata: readonly SerializablePluginMetadata[];
}

export const withPluginsMetadata =
    <P extends WithPluginsMetadataProps>(
        Component: React.ComponentType<P>
    ): React.FC<Subtract<P, WithPluginsMetadataProps>> =>
    p => {
        const pluginsMetadata = usePluginsMetadata();
        return <Component {...(p as P)} pluginsMetadata={pluginsMetadata} />;
    };
