import { getPlanInfoForPlanKind } from "@glide/common-core/dist/js/billing-vnext/org-plan-lib";
import type { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import type { CardPreview } from "@glide/common-core/dist/js/firebase-function-types";

type GetEstimateResult = {
    newEstimate: number;
    newCardPreview: CardPreview;
};

export enum CardState {
    Loading = "loading",
    NoCard = "no-card",
    CardPresent = "card-present",
}

export function getCardState(cardPreview: CardPreview | undefined): CardState {
    if (cardPreview === undefined) return CardState.Loading;
    if (cardPreview !== undefined && cardPreview.last4 === "") return CardState.NoCard;
    if (cardPreview !== undefined && cardPreview.last4.length === 4) return CardState.CardPresent;

    // This should never happen
    return CardState.Loading;
}

const emptyCard = {
    last4: "",
    address: {
        line1: "",
        line2: "",
        country: "",
        state: "",
        city: "",
        zip: "",
    },
    email: "",
    name: "",
};

export async function getEstimateAndCardPreviewForOrgPlans(args: {
    planKind: PlanKind;
    cardInfo: CardPreview | undefined;
}): Promise<GetEstimateResult> {
    const { planKind, cardInfo } = args;
    // Undefined if the user has no payment info
    const planInfo = getPlanInfoForPlanKind(planKind);
    return { newEstimate: planInfo.price, newCardPreview: cardInfo ?? emptyCard };
}
