import { type ActionDescription, ActionKind } from "@glide/app-description";
import type { ActionDescriptor } from "./action-descriptor";
import type { ActionAvailability } from "@glide/function-utils";
import { actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";

export class EnlargeImageActionHandler extends BaseActionHandler<ActionDescription> {
    public readonly kind = ActionKind.EnlargeImage;
    public readonly iconName = "maximize-2";
    public readonly name = "Enlarge image";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getIsApplicable(): boolean {
        return false;
    }

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            properties: [],
        };
    }
}

export class UploadImageActionHandler extends BaseActionHandler<ActionDescription> {
    public readonly kind = ActionKind.UploadImage;
    public readonly iconName = "upload";
    public readonly name = "Upload image";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getIsApplicable(): boolean {
        return false;
    }

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            properties: [],
        };
    }
}
