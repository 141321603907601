/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

const { Result } = glide;

export const plugin = glide.newPlugin({
    id: "google-maps",
    name: "Google Maps",
    description: "Geocoding and address completion",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/googlemaps.png",
    tier: "starter",
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/google-maps",
    parameters: {
        apiKey: glide.makeParameter({
            type: "secret",
            name: "API key",
            placeholder: "e.g. abc123...",
            description:
                "[Learn more](https://console.cloud.google.com/google/maps-apis/credentials) about getting an API key",
            required: true,
        }),
    },
});

const address = glide.makeParameter({
    type: "string",
    name: "Address",
    description: "Physical location",
    placeholder: "e.g. 1600 Pennsylvanie Ave",
    useTemplate: "withLabel",
});

const lat = glide.makeParameter({
    type: "number",
    name: "Latitude",
    description: "Latitude",
    placeholder: "e.g. 38.8976633",
});

const lng = glide.makeParameter({
    type: "number",
    name: "Longitude",
    description: "Longitude",
    placeholder: "e.g. -77.0365739",
});

const latLng = glide.makeParameter({
    type: "string",
    name: "Latitude, Longitude",
    description: "Lattitude and Longitude as a string",
    placeholder: "e.g. 38.8976633,-77.0365739",
    useTemplate: "withLabel",
});

interface Result {
    address_components: AddressComponent[];
    formatted_address: string;
    geometry: Geometry;
    place_id: string;
    types: string[];
}

interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

interface Geometry {
    location: Location;
    location_type: string;
    viewport: Viewport;
}

interface Location {
    lat: number;
    lng: number;
}

interface Viewport {
    northeast: Location;
    southwest: Location;
}

type ErrorStatus =
    | "INVALID_REQUEST"
    | "ZERO_RESULTS"
    | "OVER_QUERY_LIMIT"
    | "REQUEST_DENIED"
    | "UNKNOWN_ERROR"
    | "ERROR";

type ErrorResponse = {
    status: ErrorStatus;
    error_message: string;
};

type Results = { status: "OK"; results: Result[] } | ErrorResponse;

function glideResultFromGeocodeAPIResults(response: Results, params: (results: Result) => any) {
    if (response.status === "OK") {
        return Result.Ok(params(response.results[0]));
    } else if (response.status === "ZERO_RESULTS") {
        return Result.Ok({});
    } else {
        return Result.Fail(response.error_message, {
            error: response.error_message,
            status: response.status,
        });
    }
}

plugin.addComputation({
    id: "address-to-coordinates",
    name: "Get coordinates for address",
    description: "Get geocoordinates for an address",
    billablesConsumed: 1,
    parameters: { address },
    results: { lat, lng, latLng },

    async execute(context, { address, apiKey = "" }) {
        if (address === undefined) return Result.Ok();

        const url = new URL("https://maps.googleapis.com/maps/api/geocode/json");
        url.searchParams.append("key", apiKey);
        url.searchParams.append("address", address);
        const urlText = url.toString();

        const cachedResult = await context.useCache(async () => {
            const response = await context.fetch(urlText);
            if (!response.ok) {
                return Result.FailFromHTTPStatus("Failed to fetch data", response.status);
            }
            const results: Results = await response.json();
            const r = glideResultFromGeocodeAPIResults(results, result => ({
                lat: result.geometry.location.lat,
                lng: result.geometry.location.lng,
                latLng: `${result.geometry.location.lat},${result.geometry.location.lng}`,
            }));
            if (r.ok) context.consumeBillable();
            return r;
        }, [urlText, apiKey]);

        if (cachedResult.ok === false) {
            return cachedResult;
        }

        return cachedResult;
    },
});

plugin.addComputation({
    id: "coordinates-to-address",
    name: "Get address from coordinates",
    description: "Get an address from geocoordinates",
    billablesConsumed: 1,
    parameters: { lat, lng },
    results: { address },

    async execute(context, { lat, lng, apiKey = "" }) {
        if (lat === undefined || lng === undefined) return Result.Ok();

        const url = new URL("https://maps.googleapis.com/maps/api/geocode/json");
        url.searchParams.append("key", apiKey);
        url.searchParams.append("latlng", `${lat},${lng}`);
        const urlText = url.toString();

        const cachedResult = await context.useCache(async () => {
            const response = await context.fetch(urlText);
            if (!response.ok) {
                return Result.FailFromHTTPStatus("Failed to fetch data", response.status);
            }
            const results: Results = await response.json();
            const r = glideResultFromGeocodeAPIResults(results, result => ({ address: result.formatted_address }));
            if (r.ok) context.consumeBillable();
            return r;
        }, [urlText, apiKey]);

        if (cachedResult.ok === false) {
            return cachedResult;
        }

        return cachedResult;
    },
});

plugin.addComputation({
    id: "complete-address",
    name: "Complete address",
    description: "Complete a partial or unformatted address",
    billablesConsumed: 1,
    parameters: { address },
    results: { address },

    async execute(context, { address, apiKey = "" }) {
        if (address === undefined) return Result.Ok();

        const url = new URL("https://maps.googleapis.com/maps/api/geocode/json");
        url.searchParams.append("key", apiKey);
        url.searchParams.append("address", address);
        const urlText = url.toString();

        const cachedResult = await context.useCache(async () => {
            const response = await context.fetch(urlText);
            if (!response.ok) {
                return Result.FailFromHTTPStatus("Failed to fetch data", response.status);
            }
            const results: Results = await response.json();
            const r = glideResultFromGeocodeAPIResults(results, result => ({ address: result.formatted_address }));
            if (r.ok) context.consumeBillable();
            return r;
        }, [urlText, apiKey]);

        if (cachedResult.ok === false) {
            return cachedResult;
        }

        return cachedResult;
    },
});

const distanceText = glide.makeParameter({
    type: "string",
    name: "Distance Text",
    description: "Human-readable distance",
    placeholder: "e.g. 100 km",
});

const durationText = glide.makeParameter({
    type: "string",
    name: "Duration Text",
    description: "Human-readable duration",
    placeholder: "e.g. 2 hours 30 minutes",
});

const distanceMeters = glide.makeParameter({
    type: "number",
    name: "Distance in Meters",
    description: "Distance in meters",
    placeholder: "e.g. 100000",
});

const durationSeconds = glide.makeParameter({
    type: "number",
    name: "Duration in Seconds",
    description: "Duration in seconds",
    placeholder: "e.g. 7200",
});

type DistanceMatrixAPIResponse =
    | {
          destination_addresses: string[];
          origin_addresses: string[];
          rows: {
              elements: {
                  distance: {
                      text: string;
                      value: number;
                  };
                  duration: {
                      text: string;
                      value: number;
                  };
                  status: string;
              }[];
          }[];
          status: "OK";
      }
    | ErrorResponse;

plugin.addComputation({
    id: "distance-between-locations",
    name: "Calculate distance",
    description: "Calculate the distance and duration between two addresses",
    billablesConsumed: 1,
    parameters: { origin: address, destination: address },
    results: { distanceText, distanceMeters, durationText, durationSeconds },

    async execute(context, { origin, destination, apiKey = "" }) {
        if (origin === undefined || destination === undefined) return Result.Ok({});

        const url = new URL("https://maps.googleapis.com/maps/api/distancematrix/json");
        url.searchParams.append("key", apiKey);
        url.searchParams.append("origins", origin);
        url.searchParams.append("destinations", destination);
        const urlText = url.toString();

        const cachedResult = await context.useCache(async () => {
            const response = await context.fetch(urlText);
            if (!response.ok) {
                return Result.FailFromHTTPStatus("Failed to fetch data", response.status);
            }
            const results: DistanceMatrixAPIResponse = await response.json();
            if (results.status === "OK") {
                const { distance, duration } = results.rows[0].elements[0];
                // we observed several errors daily across multiple apps
                // that threw Cannot read properties of undefined (reading 'text') below.
                // this guards against these cases, but more investigation
                // into the response is needed to understand what about config
                // is causing the error and if we should be billing for it or not.
                // so, for now we will log these cases until that can be done
                context.consumeBillable();
                if (distance === undefined) {
                    context.log("Distance is undefined in first row-element matrix position", {
                        origin,
                        destination,
                        results,
                    });
                    return Result.Ok({});
                }
                return Result.Ok({
                    distanceText: distance.text,
                    distanceMeters: distance.value,
                    durationText: duration.text,
                    durationSeconds: duration.value,
                });
            } else if (results.status === "ZERO_RESULTS") {
                context.consumeBillable();
                return Result.Ok({});
            } else {
                return Result.Fail(results.error_message, {
                    error: results.error_message,
                    status: results.status,
                });
            }
        }, [urlText, apiKey]);

        if (cachedResult.ok === false) {
            return cachedResult;
        }

        return cachedResult;
    },
});
