import { defaultAppFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import type { FluentComponent } from "@glide/fluent-components/dist/js/fluent-components-spec";
import { encodeScreenKey } from "@glide/generator/dist/js/wire/utils";
import type { WireBackend } from "@glide/hydrated-ui";
import { AppKind } from "@glide/location-common";
import { Result } from "@glide/plugins";
import { ignore } from "@glide/support";
import { WireActionResult } from "@glide/wire";

import type { ExtractedActions } from "../../wire-lib";

export function TestWireBackend({
    runAction,
    urlPathChanged,
    valueChanged,
}: Partial<Pick<WireBackend, "runAction" | "urlPathChanged" | "valueChanged">>): WireBackend {
    return {
        appID: "APP_ID",
        appKind: AppKind.Page,
        appFeatures: defaultAppFeatures,
        isBuilder: false,
        wireNavigationModelObservable: {
            isChangeObservable: true,
            current: {
                kind: AppKind.Page,
                urlPath: "/",
                appTitle: "",
                removeBranding: false,
                screen: {
                    key: encodeScreenKey("empty"),
                    title: "",
                    components: [],
                    specialComponents: [],
                    closeAction: undefined,
                    flags: [],
                    isInModal: false,
                    tabIcon: "",
                },
                belowScreen: undefined,
                tabs: [],
                flyoutTabs: undefined,
                isTabRootScreen: true,
            },
            subscribe: ignore,
            unsubscribe: ignore,
        },
        metadataObservable: {
            isChangeObservable: true,
            current: undefined,
            subscribe: ignore,
            unsubscribe: ignore,
        },
        setFrontendActionCallbacks: ignore,
        setHeartbeatManager: ignore,
        runAction: runAction ?? ignore,
        urlPathChanged: urlPathChanged ?? ignore,
        valueChanged: valueChanged ?? (() => WireActionResult.nondescriptSuccess()),
        navigateToVisibleTab: ignore,
        navigateToUserProfile: () => Result.Ok(),
        navigateToTabRoot: ignore,
        pushFakeScreen: ignore,
        popToLogicalTab: ignore,
        editComponent: ignore,
        setFormFactor: ignore,
        setUserName: ignore,
        retire: ignore,
        inflateAndHydrateCompoundAction: () => undefined,
        makeActionBackend: () => undefined,
        getAIComponentInstructionsMetadata: () => ({
            primaryAccentColor: "#000000",
            appDescription: undefined,
            appName: "Test App",
            tabName: undefined,
        }),
    };
}

export function generateWireMenuItems(names: string[], spy?: () => void): ExtractedActions {
    return names.map(n => ({
        enabled: true,
        title: n,
        run: spy ?? ignore,
    }));
}

export function enumControlsFromComponent(component: FluentComponent<any, any, any, any>) {
    return component.spec.properties.propertySpecs
        .map(prop => prop.descriptorConstructor({} as any))
        .filter((prop: any) => prop.kind === "enum")
        .reduce((builder: Record<string, any>, current: any) => {
            if (!current.hasOwnProperty("cases")) {
                return builder;
            }
            const key = current.name || current.property.name;
            builder[key] = {
                options: current.cases.map((c: any) => c.value),
                control: { type: "radio" },
            };
            return builder;
        }, {});
}
