import { reloadBrowserWindow } from "@glide/common-core/dist/js/support/browser-reload";
import { getAllowLoginSaveKey, localStorageRemoveItem } from "@glide/support";
import { flagExplicitSignOut, clearAppPasswordLocalStorage } from "@glide/auth-controller-core";

export function signOutAppUser(appID: string): void {
    clearAppPasswordLocalStorage(appID);
    localStorageRemoveItem(getAllowLoginSaveKey(appID));
    flagExplicitSignOut(appID);
    reloadBrowserWindow("user explicitly signed out");
}
