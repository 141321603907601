import { useEminenceFlags } from "@glide/common-components";
import { useOrgInfo } from "./use-org-info";
import type { AutomationTriggerKind } from "@glide/automations-codecs";

type Charges = Record<AutomationTriggerKind, number>;

export function useWorkflowCharges(): Charges {
    const orgInfo = useOrgInfo();
    const callingOrgID = orgInfo.orgID;
    const { data: maybeEminenceFlags } = useEminenceFlags(callingOrgID);
    const schedule = maybeEminenceFlags?.automationTriggerScheduleBillableConsumed ?? 0;

    return {
        app: 0,
        manual: schedule,
        schedule,
        // FIXME: This is probably wrong - we might want to allow triggers to
        // determine their own charges
        plugin: schedule,
    };
}
