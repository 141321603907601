import * as glide from "@glide/plugins";

import * as v1 from "./glide-ai-v1";
import * as v2 from "./glide-ai-v2";
import * as v3 from "./glide-ai-v3";

export const plugin = glide.newNativePlugin({
    id: "glide-ai",
    name: "Glide AI",
    description: "The easiest way to build with AI. Add powerful new AI columns to get more from your data.",
    icon: { kind: "monotone", icon: "mt-column-ai" },
    tier: "starter",
    documentationUrl: "https://www.glideapps.com/docs/automation/ai",
});

v1.init(plugin);
v2.init(plugin);
v3.init(plugin);
