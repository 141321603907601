import { isBasePrimitiveValue, GlideDateTime } from "@glide/data-types";
import { getLocalizedString } from "@glide/localization";
import { type LoadingValue, isLoadingValue } from "@glide/computation-model-types";
import { asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ActionDescription,
    type PropertyDescription,
    ActionKind,
    PropertyKind,
    getArrayProperty,
    getColumnProperty,
    makeArrayProperty,
    makeColumnProperty,
} from "@glide/app-description";
import type { TriggerZapBody } from "@glide/common-core/dist/js/firebase-function-types";
import {
    type AppDescriptionContext,
    ArrayPropertyStyle,
    PropertySection,
    StringPropertyHandler,
    ZapPropertyHandler,
} from "@glide/function-utils";
import { defined, definedMap } from "@glideapps/ts-necessities";
import { nullToUndefined, removeNullFromObject, removeUndefinedProperties } from "@glide/support";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import { makeTextPropertyDescriptorWithSpecialValue } from "../components/descriptor-utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type AwaitSendDescription, getAwaitSendPropertyDescriptor, shouldAwaitSend } from "./await-send-property";
import { BaseActionHandler } from "./base";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { AppKind } from "@glide/location-common";
import type { StaticActionContext } from "../static-context";

interface ValueDescription {
    value: PropertyDescription | undefined;
}

interface ZapierActionDescription extends ActionDescription, AwaitSendDescription {
    zapID: PropertyDescription;
    values: PropertyDescription | undefined;
    confirmMessage: PropertyDescription | undefined;

    val1: PropertyDescription | undefined;
    val2: PropertyDescription | undefined;
    val3: PropertyDescription | undefined;
}

export class ZapierWithArgsHandler extends BaseActionHandler<ZapierActionDescription> {
    private static zapIDPropertyHandler = new ZapPropertyHandler("zapID", "Zap", PropertySection.Data);

    private static confirmMessagePropertyHandler = new StringPropertyHandler(
        "confirmMessage",
        "Confirm Message",
        "Sent",
        false,
        undefined,
        PropertySection.Data
    );

    public readonly kind = ActionKind.Zapier;
    public readonly iconName = { icon: "st-connect" as const, kind: "stroke" as const };
    public readonly name: string = "Trigger Zap";

    public getDescriptor(
        _desc: ZapierActionDescription | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Communication,
            groupItemOrder: 7,
            needsScreenContext: true,
            properties: [
                ZapierWithArgsHandler.zapIDPropertyHandler,
                {
                    kind: PropertyKind.Array,
                    label: "Values",
                    property: { name: "values" },
                    section: PropertySection.Data,
                    properties: [
                        makeTextPropertyDescriptorWithSpecialValue(
                            "value",
                            i => `Value ${defined(i) + 1}`,
                            "Enter value",
                            true,
                            mutatingScreenKind,
                            {
                                columnFirst: true,
                                isDefaultCaption: true,
                            }
                        ),
                    ],
                    allowEmpty: true,
                    maxItems: 12,
                    allowReorder: true,
                    addItemLabels: ["Add value"],
                    style: ArrayPropertyStyle.Default,
                },
                ZapierWithArgsHandler.confirmMessagePropertyHandler,
                ...getAwaitSendPropertyDescriptor(ccc),
            ],
            isLegacy: isExperimentEnabled("plugin-zapier", ccc.userFeatures) && ccc.appKind === AppKind.Page,
        };
    }

    public fixActionDescription(desc: ZapierActionDescription): ZapierActionDescription {
        if (desc.values !== undefined) return desc;

        const columnName1 = getColumnProperty(desc.val1);
        const columnName2 = getColumnProperty(desc.val2);
        const columnName3 = getColumnProperty(desc.val3);

        const values = [columnName1, columnName2, columnName3].map(n => ({ value: definedMap(n, makeColumnProperty) }));

        return {
            ...desc,
            values: makeArrayProperty(values),
            val1: undefined,
            val2: undefined,
            val3: undefined,
        };
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: ZapierActionDescription,
        arbInflated: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { appID, appKind },
            appFacilities,
        } = ib;

        const zapID = ZapierWithArgsHandler.zapIDPropertyHandler.getZap(desc);
        if (zapID === undefined) return arbInflated.inflationError("Invalid Zap");

        const arr = getArrayProperty<ValueDescription>(desc.values) ?? [];
        const getters = arr.map(vd => ib.getValueGetterForProperty(vd.value, false)[0]);

        const [confirmMessageGetter] = ib.getValueGetterForProperty(desc.confirmMessage, true);

        const awaitSend = shouldAwaitSend(ib.adc, desc);

        return (vp, skipLoading) => {
            if (!vp.getIsOnline()) return arbInflated.offline();

            // We always ##runAllGetters.
            let loadingValue: LoadingValue | undefined;
            const values = getters.map(g => {
                const v = nullToUndefined(g(vp));
                if (isLoadingValue(v)) {
                    loadingValue = v;
                    return undefined;
                }
                if (isBasePrimitiveValue(v)) {
                    return v;
                } else if (v instanceof GlideDateTime) {
                    return v.asTimeZoneAwareDate().toISOString();
                } else {
                    return undefined;
                }
            });

            const body: TriggerZapBody = {
                zapID,
                appID,
                val1: values[0],
                val2: values[1],
                val3: values[2],
                val4: values[3],
                val5: values[4],
                val6: values[5],
                val7: values[6],
                val8: values[7],
                val9: values[8],
                val10: values[9],
                val11: values[10],
                val12: values[11],
            };
            const bodyForLogging = removeUndefinedProperties(removeNullFromObject(body));
            let arbHydrated = arbInflated.addData({ body: bodyForLogging });

            if (loadingValue !== undefined) {
                if (!skipLoading) return arbHydrated.loading();
            }

            let confirmMessageValue = nullToUndefined(confirmMessageGetter(vp));
            if (isLoadingValue(confirmMessageValue)) {
                if (skipLoading) {
                    confirmMessageValue = undefined;
                } else {
                    return arbHydrated.loading("Confirm message");
                }
            }
            const confirmMessage =
                asMaybeString(confirmMessageValue) ?? getLocalizedString("formSubmissionSuccess", appKind);
            arbHydrated = arbHydrated.addData({ confirmMessage });

            return async ab => {
                const promise = appFacilities.callAuthIfAvailableCloudFunction("triggerZap", body, {});

                // We have to drain out the body, otherwise we'll just leave the connection
                // around forever.
                if (!awaitSend) {
                    void promise.then(r => r?.text());
                    return arbHydrated.success();
                }

                const resp = await promise;
                void resp?.text();

                const arb = arbHydrated.addData({ status: resp?.status });

                if (resp?.ok === true) {
                    ab.actionCallbacks.showToast(true, confirmMessage);
                    return arb.success();
                } else if (resp?.status === 402) {
                    ab.actionCallbacks.showToast(
                        false,
                        getLocalizedString("thisActionCouldntBeTriggered", appKind) +
                            "\n" +
                            getLocalizedString("thisAppMustBeUpgraded", appKind)
                    );
                    return arb.error(true, "The team must be upgraded to use this feature");
                } else {
                    ab.actionCallbacks.showToast(false, getLocalizedString("error", appKind));
                    return arb.errorFromHTTPStatus(resp?.status ?? 500, resp?.statusText ?? "Could not trigger Zap");
                }
            };
        };
    }
}
