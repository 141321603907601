import type { Owner } from "@glide/common-core/dist/js/Database";
import type { DeleteTaxBody, SetTaxBody } from "@glide/common-core/dist/js/firebase-function-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import type { SimpleModalContextProps } from "@glide/designer-components";
import { isResponseOK, logError } from "@glide/support";

export const deleteTaxInfo = async (owner: Owner | undefined, taxID: string, modals: SimpleModalContextProps) => {
    if (owner !== undefined && owner.id !== undefined) {
        const body: DeleteTaxBody = {
            ownerID: owner.id,
            taxID: taxID,
        };
        const confirmation = await modals.showAcceptCancelModal(
            "Delete Tax Selection",
            "Are you sure you want to delete this VAT tax selection?",
            "Delete",
            { acceptButtonVariance: "danger" }
        );

        if (confirmation) {
            const response = await getAppFacilities().callAuthCloudFunction("deleteTaxInfo", body);

            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void response?.text();
            if (response === undefined || !response.ok) {
                logError("Unable to delete tax selection");
            } else {
                await modals.showSimpleInfoModal("Success", "Deleted VAT tax selection successfully!");
            }
        }
    } else {
        logError("No owner found");
    }
};

export const setTaxInfo = async (
    owner: Owner,
    modals: SimpleModalContextProps,
    taxType: string,
    taxValue: string
): Promise<void> => {
    if (owner.id !== undefined) {
        const body: SetTaxBody = {
            ownerID: owner.id,
            type: taxType,
            value: taxValue,
        };
        const response = await getAppFacilities().callAuthCloudFunction("setTaxInfo", body);

        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        void response?.text();
        if (!isResponseOK(response)) {
            await modals.showErrorModal(1054);
        } else {
            await modals.showSimpleInfoModal("Success", "Saved tax details successfully!");
        }
    } else {
        await modals.showErrorModal(1054);
    }
};
