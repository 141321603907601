import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import {
    ContactSupport,
    DefaultErrorMessage,
    SaveTaxDetailsError,
    SignInError,
    SupportLinkCreateError,
    TransferAppError,
} from "./error-components";
import type { ErrorModalBody } from "./modal-snippet-library";

export const errorBodies: ErrorModalBody[] = [
    {
        code: 1001,
        title: "An error has occurred",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1002,
        title: "You aren't signed in",
        htmlBodyContent: SignInError,
        buttonText: "Close",
    },
    {
        code: 1003,
        title: "An error occurred while duplicating your app",
        description:
            "Duplicating an app can fail when it uses a large or complex Google Sheet. Please delete all rows and columns that your app is not using or where there is no data. Glide will append rows as needed, so you can remove all empty rows below the existing data on all of your sheets. After completing these steps, try duplicating your app again.",
        buttonText: "Close",
    },
    {
        code: 1004,
        title: "An error occurred while unpublishing your app",
        description:
            "Include a detailed explanation or a screen recording of the events that lead up to the error message you have received. You can use [Loom](https://www.loom.com) or any other screen recording software. Also include a [Support Link](https://community.glideapps.com/t/support-links/17192?u=george_b) in your email.",
        buttonText: "Close",
    },
    {
        code: 1005,
        title: "Could not invite the user",
        description: "Make sure the user you are trying to invite to become a member of your Team has a Glide account",
        buttonText: "Close",
    },
    {
        code: 1006,
        title: "Could not make support link",
        htmlBodyContent: SupportLinkCreateError,
        buttonText: "Close",
    },
    {
        code: 1007,
        title: "Unable to load the app",
        description:
            "Try loading a different app or creating a new app. If you still encounter loading errors, please log out of Glide and close your browser completely. Then, reopen Glide in one of our [supported browsers](https://docs.glideapps.com/all/etc/faq/system-requirements) and try again.",
        buttonText: "Close",
    },
    {
        code: 1008,
        title: "There's no Google Sheet connected to this app",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1009,
        title: "That's the wrong spreadsheet (when a spreadsheet needs to be reconnected, but the user picked the wrong one)",
        description:
            "Make sure you are selecting the original Google Sheet of a Google Sheet that is a copy of the original sheet.",
        buttonText: "Close",
    },
    {
        code: 1010,
        title: "Unable to perform action. Contact Organization Admin to delete Tax data",
        description: "Only the owner of a Team is allowed to delete tax data.",
        buttonText: "Close",
    },
    {
        code: 1011,
        title: "Could not set additional Billing info",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1012,
        title: "Could not set additional Billing info. ",
        description:
            "Please make sure your taxID format matches the appropriate type. Tax ID's for the various countries have specific formats. Please see this [Stripe link](https://stripe.com/docs/invoicing/customer/tax-ids) for further information about how Tax ID's are formatted for your country.",
        buttonText: "Close",
    },
    {
        code: 1013,
        htmlBodyContent: DefaultErrorMessage,
        title: "Could not transfer subscription. -or- There was an error transferring your app.",
        buttonText: "Close",
    },
    {
        code: 1014,
        title: "Could not cancel subscription.",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1015,
        title: "Something went wrong renewing your subscription",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1016,
        title: "Something went wrong upgrading your subscription",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1017,
        title: "Something went wrong updating payment information",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1018,
        title: "Something went wrong updating Boosts",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1019,
        title: "Could not connect to Glide to request export file",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1020,
        title: "Export file is no longer available",
        description:
            "Data export files are only held for a short period of time. Re-export the data again and make sure to check for the email in a timely manner and click the link to download.",
        buttonText: "Close",
    },
    {
        code: 1021,
        title: "You do not have permission to download this export file",
        description: "You must be logged into the same account as the Glide account that exported the data.",
        buttonText: "Close",
    },
    {
        code: 1022,
        title: "Unexpected download response from the server",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1023,
        title: "Could not access support code",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1024,
        title: "Could not create Glide table",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1025,
        title: "Could not remove Glide table",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1026,
        title: "Something went wrong replacing the spreadsheet",
        description:
            "Make sure the Google Sheet you are selecting has the same structure with regard to sheet tab names and column titles (row 1 titles) as the currently attached sheet. Depending on the reasons for replacing the current attached sheet, its best to make a copy of the original sheet and move or alter the data instead of trying to duplicate the sheet from scratch.",
        buttonText: "Close",
    },
    {
        code: 1027,
        title: "That user does not exist for this app",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1028,
        title: "Something went wrong trying to delete that user's data",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1029,
        title: "Something went wrong updating your payment information",
        description:
            "Make sure you are entering in a valid credit card. Check here for what credit cards are [accepted by Stripe](https://stripe.com/payments/payment-methods-guide) our credit card processing vendor. Glide may not accept every type of CC listed but this will give you an idea of which types to try,",
        buttonText: "Close",
    },
    {
        code: 1030,
        title: "Something went wrong adding the webhook",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1031,
        title: "Something went wrong getting the Google Sheet",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1032,
        title: "An error occurred copying the sample",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1033,
        title: "An error occurred deleting the app",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1034,
        title: "Please try again in a bit. - Unsplash call fail",
        description:
            "Try again in a little while. For reasons beyond Glides control the connection to Unsplash is not working.",
        buttonText: "Close",
    },
    {
        code: 1035,
        title: "Glide could not reload the sheet",
        description:
            "Make sure the sheet exists and has not been moved to a different location that causes the Glide login email account to not be able to access the sheet.",
        buttonText: "Close",
    },
    {
        code: 1036,
        title: "Could not complete transfer because the destination Team does not have a payment method set up.",
        htmlBodyContent: TransferAppError,
        buttonText: "Close",
    },
    {
        code: 1037,
        title: "Authentication Failed",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1038,
        title: "Your app is too large to publish",
        description: "Please remove components or screens to make it publishable.",
        buttonText: "Close",
    },
    {
        code: 1039,
        title: "You reached your plan's limit for published apps",
        description: "Please upgrade or unpublish another app first.",
        buttonText: "Close",
    },
    {
        code: 1040,
        title: "There was an error while publishing your app.",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1041,
        title: "Could not add row",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1043,
        title: "Glide has encountered an irrecoverable database error and needs to be reloaded.",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1044,
        title: "Could not edit Airtable Integration",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1045,
        title: "Unable to reload your spreadsheet",
        description: "Glide did not reload your spreadsheet because it has become incompatible with the app.",
        buttonText: "Close",
    },
    {
        code: 1046,
        title: "Quota limit has been reached",
        description:
            "Glide did not reload your spreadsheet because you've reached the Sheet Edits quota. Please upgrade your plan.",
        buttonText: "Close",
    },
    {
        code: 1047,
        title: "Reloading is disabled for this app",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1048,
        title: "Reloading your spreadsheet has timed out",
        description:
            "Apologies, the process of refreshing your spreadsheet exceeded our expected time limit, so Glide had to halt it. Please try again, or if the issue persists, consider checking your network connection.",
        buttonText: "Close",
    },
    {
        code: 1049,
        title: "An error occurred grabbing screenshot",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1050,
        title: "An error occurred while publishing your template's screenshot",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1051,
        title: "An error occurred submitting the template",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1052,
        title: "An error occurred connecting to Google.",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1053,
        title: "Authentication Link Expired",
        description: "The given authentication link has expired.",
        buttonText: "Close",
    },
    {
        code: 1054,
        title: "An error occurred when saving Tax Details",
        htmlBodyContent: SaveTaxDetailsError,
        buttonText: "Close",
    },
    {
        code: 1055,
        title: "An error occurred when modifying Tax Details",
        description: "Error - Can't modify tax details because owner, tax value, or tax type is not defined",
        buttonText: "Close",
    },
    {
        code: 1056,
        title: "Glide is currently unable to process your request",
        description:
            "An error has occurred attempting to create a payment session for your purchase. Please try again later.",
        buttonText: "Close",
    },
    {
        code: 1057,
        title: "App cannot be transferred",
        description: `This app uses a high-scale data source (Big Tables, SQL, or BigQuery) that cannot be transferred. [Learn More](${getDocURL(
            "dataSources"
        )}) about data sources.`,
        buttonText: "Close",
    },
    {
        code: 1058,
        title: "Table cannot be renamed",
        buttonText: "Close",
    },
    {
        code: 1059,
        title: "Table cannot be reloaded",
        buttonText: "Close",
    },
    {
        code: 1061,
        title: "Contact an admin to upgrade your team",
        description: "Upgrade your team to use this feature. Please contact a team admin to perform the upgrade.",
        buttonText: "Close",
    },
    {
        code: 1062,
        title: "Unable to create new app",
        description: "We were unable to create a new app. Please try again later.",
        buttonText: "Close",
    },
    {
        code: 1063,
        title: "Could not delete team",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1064,
        title: "We weren't able to read the file you uploaded",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1065,
        title: "File too large",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1066,
        title: "Device offline",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1067,
        title: "Permission denied",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1068,
        title: "Table deleted",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1069,
        title: "Unable to create table from google sheet",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1070,
        title: "Improper credentials have been returned",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1071,
        title: "Network connection was interrupted",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1072,
        title: "Server sent an unexpected response type",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1073,
        title: "An error occurred connecting to GCP",
        htmlBodyContent: DefaultErrorMessage,
        buttonText: "Close",
    },
    {
        code: 1074,
        title: "An error occurred unpublishing/deleting the app",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1075,
        title: "Something went wrong processing your request",
        description: "Please try again to update your payment method.",
        buttonText: "Close",
    },
    {
        code: 1076,
        title: "Something went wrong processing your request",
        description: "Please try again to request an extension.",
        buttonText: "Close",
    },
    {
        code: 1077,
        title: "Unable to delete action",
        description:
            "This is likely due to a permissions issue. Make sure you are logged into the builder as the same user who created the action originally. If this is not possible, please contact support.",
        buttonText: "Close",
    },
    {
        code: 1078,
        title: "Workflow failed to run",
        description:
            "Unable to trigger a workflow run manually at this time. Try again after a while or contact support if the issue persists.",
        buttonText: "Close",
    },
    {
        code: 1079,
        title: "Unable to save secret",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1080,
        title: "An error occurred while creating a new app from your classic app",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1081,
        title: "An error occurred while duplicating your app",
        htmlBodyContent: ContactSupport,
        buttonText: "Close",
    },
    {
        code: 1082,
        title: "A client-side error occurred while handling a payment session for your purchase",
        description: "Please contact support for further assistance.",
        buttonText: "Close",
    },
];
