import { ReactRenderer } from "@tiptap/react";
import type { GetReferenceClientRect } from "tippy.js";
import tippy, { type Instance } from "tippy.js";
import { type TokenListDropdownRefMethods, TokenListDropdown } from "./token-list";
import type { SuggestionOptions } from "@tiptap/suggestion";

import { filterActions } from "./utils";
import { isDefined } from "@glide/support";

interface SuggestionProps {
    onToggle: (show: boolean) => void;
    portalRef: React.RefObject<HTMLDivElement>;
}

export const buildSuggestions = <T>({ onToggle, portalRef }: SuggestionProps): Omit<SuggestionOptions, "editor"> => ({
    allowSpaces: true,
    items: ({ query, editor }) => {
        const options = editor.storage.BindingStorage.suggestions;
        const contextualLabel = editor.storage.BindingStorage.contextualLabel;

        const matches = filterActions<T>(Object.values(options), query, contextualLabel);
        if (matches !== null && matches.length > 0) {
            return matches;
        }

        return [];
    },
    // https://github.com/ueberdosis/tiptap/blob/main/packages/extension-mention/src/mention.ts, we're just removing the space insertion
    command: ({ editor, range, props }) => {
        // increase range.to by one when the next node is of type "text"
        // and starts with a space character
        const nodeAfter = editor.view.state.selection.$to.nodeAfter;
        const overrideSpace = nodeAfter?.text?.startsWith(" ");

        if (isDefined(overrideSpace)) {
            range.to += 1;
        }

        editor
            .chain()
            .focus()
            .insertContentAt(range, [
                {
                    type: "binding",
                    attrs: props,
                },
            ])
            .run();

        window.getSelection()?.collapseToEnd();
    },

    render: () => {
        let component: ReactRenderer<TokenListDropdownRefMethods>;
        let popup: Instance;

        return {
            onStart: props => {
                component = new ReactRenderer(TokenListDropdown, {
                    props: { ...props },
                    editor: props.editor,
                });

                if (props.clientRect === null) {
                    return;
                }

                if (!isDefined(portalRef.current)) {
                    return;
                }

                if (isDefined(popup)) {
                    popup.destroy();
                }

                popup = tippy(portalRef.current, {
                    onShow: () => onToggle(true),
                    onHide: () => onToggle(false),
                    appendTo: () => document.getElementById("portal") as HTMLElement,
                    content: component.element,
                    getReferenceClientRect: props.clientRect as unknown as GetReferenceClientRect,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                    offset: [0, 10],
                    animation: "none",
                });
            },

            onUpdate(props) {
                component.updateProps(props);

                // This can't happen. We're setting this in `onStart`
                if (!isDefined(popup)) return;

                popup.setProps({
                    getReferenceClientRect: props.clientRect as unknown as GetReferenceClientRect,
                });
            },

            onKeyDown(props) {
                if (props.event.key === "Escape" || props.event.key === "Tab") {
                    popup.hide();

                    return true;
                }

                // This can't happen. We're setting this in `onStart`
                if (!isDefined(component.ref)) {
                    return true;
                }

                return component.ref.onKeyDown(props);
            },

            onExit() {
                popup?.destroy();
                component?.destroy();
            },
        };
    },
});
