import type { ComponentDescription, ComponentKind, MutatingScreenKind } from "@glide/app-description";
import { getEnumProperty, makeColumnProperty, makeEnumProperty, makeStringProperty } from "@glide/app-description";
import { type TableColumn, getTableColumnDisplayName, isPrimitiveType } from "@glide/type-schema";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { WireToggleKind } from "@glide/fluent-components/dist/js/base-components";
import type {
    AppDescriptionContext,
    ComponentSpecialCaseDescriptor,
    InteractiveComponentConfiguratorContext,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { assert } from "@glideapps/ts-necessities";
import { definedMap } from "collection-utils";

import { type ToggleComponentDescription, ToggleComponentHandler } from "./toggle";

const checkboxSpecialCase: ComponentSpecialCaseDescriptor = {
    name: "Checkbox",
    description: "A checkbox",
    group: "Form Elements",
    appKinds: AppKind.Page,
    img: "co-checkbox",
    isNew: false,
};

const ComponentKindSwitch: ComponentKind = "switch";

export class SwitchComponentHandler extends ToggleComponentHandler {
    public readonly appKinds = "both";

    constructor() {
        super(ComponentKindSwitch, WireToggleKind.Switch, ["flag", "switch"]);
    }

    protected readonly name = "Switch";
    protected readonly description = "An on/off switch";
    protected readonly img = "co-switch";
    protected readonly docPath = "toggle";

    protected readonly uiName = "ToggleSwitch";

    public newSpecialCaseComponent(
        specialCaseDescriptor: ComponentSpecialCaseDescriptor,
        tables: InputOutputTables,
        usedColumns: ReadonlySet<TableColumn>,
        editedColumns: ReadonlySet<TableColumn>,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ToggleComponentDescription | undefined {
        assert(specialCaseDescriptor === checkboxSpecialCase);
        const baseComponent = this.newComponent(tables, usedColumns, editedColumns, iccc, mutatingScreenKind);

        return definedMap(baseComponent, c => ({
            ...c,
            toggleKind: makeEnumProperty(WireToggleKind.Checkbox),
        }));
    }

    public getSpecialCaseDescriptors() {
        return [checkboxSpecialCase];
    }

    public getDescriptiveName(
        desc: ToggleComponentDescription,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext
    ): [string, string] {
        const [, secondary] = super.getDescriptiveName(desc, tables, ccc);
        let name: string;
        if (getEnumProperty(desc.toggleKind) === WireToggleKind.Checkbox) {
            name = "Checkbox";
        } else {
            name = "Switch";
        }
        return [name, secondary];
    }

    public static defaultComponent(column: TableColumn): ToggleComponentDescription {
        assert(isPrimitiveType(column.type));
        return {
            ...makeEmptyComponentDescription(ComponentKindSwitch),
            propertyName: makeColumnProperty(column.name),
            defaultValue: undefined,
            primaryKeyProperty: undefined,
            caption: makeStringProperty(getTableColumnDisplayName(column)),
            description: undefined,
            mustBeActive: undefined,
            allowWrapping: undefined,
            toggleKind: makeEnumProperty(WireToggleKind.Switch),
        };
    }

    public convertToPage(
        desc: ToggleComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return {
            ...desc,
            toggleKind: makeEnumProperty(WireToggleKind.Switch),
        } as ComponentDescription;
    }
}
