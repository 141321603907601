import * as React from "react";

import { withTheme } from "styled-components";
import type { IconName } from "@glide/common";
import { getNamedIcon } from "@glide/common";
import { logError } from "@glide/support";
import type { BuilderTheme } from "@glide/theme";

class ThemedIconInner extends React.PureComponent<{
    icon: IconName;
    theme: BuilderTheme;
    className?: string;
    size?: number;
}> {
    public render(): React.ReactNode {
        const { theme, icon, className, size } = this.props;
        const Icon = getNamedIcon(icon);
        if (Icon === undefined) {
            logError("Could not find icon", icon);
            return null;
        }
        return <Icon fgColor={theme.dropdown.iconFg} className={className} size={size} />;
    }
}

export const ThemedIcon = withTheme(ThemedIconInner);
