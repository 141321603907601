import { v4 as uuid } from "uuid";
import { type LocationSettings, AppKind, getLocationSettingsUnsafe } from "@glide/location-common";
import {
    type ChangeObservable,
    type JSONObject,
    normalizeEmailAddress,
    sessionStorageGetItem,
    sessionStorageSetItem,
    Watchable,
} from "@glide/support";
import { defined, definedMap, panic } from "@glideapps/ts-necessities";
import type { AppFeatures, PluginConfig } from "@glide/app-description";
import { defaultAppFeatures } from "../components/SerializedApp";
import {
    type IntegrationsAggregator,
    emptyAppEnvironmentActionsState,
    type AppEnvironmentActionStateHandler,
    type AppEnvironmentActionsState,
    type AppFacilities,
    type AppPublishInfo,
    type AppUserAuthenticator,
    type AppUserChangedCallback,
    type DataStore,
    type MinimalAppEnvironment,
    type MinimalAppFacilities,
    type NativeTableSnapshot,
    type OAuth2TokenAuth,
    type OfflineQueue,
    type QueryableDataStore,
    type ResponseStatus,
    type TableSnapshot,
} from "../components/types";
import type { Database } from "../Database/core";
import type { DataSnapshot, PublishedAppSnapshot } from "../Database";
import type { EminenceFlags } from "@glide/billing-types";
import { eminenceFull } from "../Database/eminence";
import type { TypeSchema, SourceMetadata } from "@glide/type-schema";
import type { AppLoginTokenContainer } from "../integration-types";
import { OfflineQueueImplWithoutOnlineHooks } from "../offline-action-queue";
import type { IntegrationsAggregatorFactory, MockedCloudFunctions } from "../utility/material-app-facilities";
import type { YesCodeValue } from "../yes-code-types";
import { Response as ResponseImpl } from "node-fetch";
import type { RunIntegrationsBody } from "../firebase-function-types";
import { generateFirestoreDocumentID } from "../id-generator";
import { getDeviceID } from "../device-id";
import type { AppData } from "@glide/plugins";

export class DummyAppFacilitiesBase implements AppFacilities {
    public readonly fetch = globalThis.fetch;

    constructor(
        private readonly integrationsAggregatorFactory: IntegrationsAggregatorFactory,
        private readonly mockedFunctions?: MockedCloudFunctions
    ) {}

    public get deviceID(): string {
        return getDeviceID();
    }

    public makeRowID(): string {
        return generateFirestoreDocumentID();
    }

    public makeUUID(): string {
        return uuid();
    }

    public get locationSettings(): LocationSettings {
        return getLocationSettingsUnsafe("dev", AppKind.App);
    }

    public mapURL(): string | undefined {
        return panic("Not implemented: mapURL");
    }

    public mailtoURL(): string {
        return panic("Not implemented: mailtoURL");
    }

    public isSignedInToFirebase(): Promise<boolean> {
        return panic("Not implemented: isSignedInToFirebase");
    }

    public async getAuthUserID(): Promise<string | undefined> {
        return undefined;
    }

    public getAppUserEmail(): Promise<string | undefined> {
        return panic("Not implemented: getAppUserEmail");
    }

    public getIDToken(): Promise<string | undefined> {
        return panic("Not implemented: getIDToken");
    }

    public uploadAppImage(): Promise<string> {
        return panic("Not implemented: uploadAppImage");
    }

    public uploadAppFile(): Promise<string> {
        return panic("Not implemented: uploadAppFile");
    }

    public async callAuthCloudFunction(
        functionName: string,
        body: any,
        headers: { [key: string]: string } = {},
        stringify: boolean = true
    ): Promise<Response | undefined> {
        return await this.callCloudFunction(functionName, body, headers, stringify);
    }

    public async callAuthIfAvailableCloudFunction(
        functionName: string,
        body: any,
        headers: { [key: string]: string },
        stringify?: boolean
    ): Promise<Response | undefined> {
        return await this.callCloudFunction(functionName, body, headers, stringify);
    }

    public async callCloudFunction(
        functionName: string,
        body: any,
        _headers: { [key: string]: string } = {},
        _stringify: boolean = true
    ): Promise<Response | undefined> {
        if (this.mockedFunctions === undefined || !Object.keys(this.mockedFunctions).includes(functionName)) {
            throw new Error(`Function ${functionName} is not mocked. I won't call an actual endpoint`);
        }

        const resolver = this.mockedFunctions[functionName];
        const response = await resolver(body);

        return new ResponseImpl(JSON.stringify(response, null, 4), {
            headers: {
                "Content-type": "application/json",
            },
            status: 200,
        }) as unknown as Response;
    }

    public async callAuthServiceGateway(
        endpoint: string,
        body: any,
        headers: { [key: string]: string },
        stringify?: boolean,
        method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE"
    ): Promise<Response | undefined> {
        return await this.callServiceGateway(endpoint, body, headers, stringify, method);
    }

    public async callAuthIfAvailableServiceGateway(
        endpoint: string,
        body: any,
        headers: { [key: string]: string },
        stringify?: boolean,
        method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE"
    ): Promise<Response | undefined> {
        return await this.callServiceGateway(endpoint, body, headers, stringify, method);
    }

    public async callServiceGateway(
        endpoint: string,
        _body: any,
        _headers: { [key: string]: string } = {},
        _stringify: boolean = true,
        _method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE" = "POST"
    ): Promise<Response | undefined> {
        return new ResponseImpl(JSON.stringify(`${endpoint}`, null, 4), {
            headers: {
                "Content-type": "application/json",
            },
            status: 200,
        }) as unknown as Response;
    }

    public getIntegrationsAggregator(base: Omit<RunIntegrationsBody, "instances">): IntegrationsAggregator {
        return this.integrationsAggregatorFactory.get(this, base);
    }

    public signInWithCustomToken(): Promise<boolean> {
        return panic("Not implemented: signInWithCustomToken");
    }

    public setSnapshotLocationsForAppID(): void {
        return panic("Not implemented: setSnapshotLocationsForAppID");
    }

    public async loadPublicDataSnapshot(): Promise<TableSnapshot | DataSnapshot | undefined> {
        return undefined;
    }

    public async loadPrivateDataSnapshot(): Promise<TableSnapshot | DataSnapshot | undefined> {
        return undefined;
    }

    public async loadUnusedDataSnapshot(): Promise<TableSnapshot | DataSnapshot | undefined> {
        return undefined;
    }

    public loadPublishedAppFromLocalStorage(): PublishedAppSnapshot | undefined {
        return panic("Not implemented: loadPublishedAppFromLocalStorage");
    }

    public loadPublishedAppSnapshot(): Promise<PublishedAppSnapshot | undefined> {
        return panic("Not implemented: loadPublishedAppSnapshot");
    }

    public loadNativeTableSnapshot(): Promise<NativeTableSnapshot | undefined> {
        return panic("Not implemented: loadNativeTableSnapshot");
    }

    public setInitialSchemaForAppID(_appID: string, _schema: TypeSchema): void {
        return;
    }

    public getInitialSchemaForAppID(_appID: string): TypeSchema | undefined {
        return undefined;
    }

    public getNumRowsUsedByAppID(_appID: string): number | undefined {
        return undefined;
    }

    public async eagerLoadAppSnapshot(_appID: string): Promise<void> {
        return;
    }

    public trackEvent(): void {
        return;
    }

    public async callYesCode(_url: string, _params: ReadonlyMap<string, unknown>): Promise<YesCodeValue | undefined> {
        return undefined;
    }
}

export class DummyAppUserAuthenticator implements AppUserAuthenticator {
    private _appUserID: string | undefined;
    private _email: string | undefined;
    public readonly userProfileRow: JSONObject | undefined;
    public readonly failedSignIns: number = 0;
    private readonly callbacks: Set<AppUserChangedCallback> = new Set();

    constructor(public readonly appFacilities: MinimalAppFacilities, public readonly appID: string) {}
    public get appUserID(): string | undefined {
        return this._appUserID;
    }

    public get realEmail(): string | undefined {
        return this._email;
    }

    public get virtualEmail(): string | undefined {
        return this._email;
    }

    public get unnormalizedVirtualEmail(): string | undefined {
        return definedMap(this._email, normalizeEmailAddress);
    }

    public addCallback(callback: AppUserChangedCallback): void {
        this.callbacks.add(callback);
        callback(this.appUserID, this.realEmail, this.virtualEmail, undefined, false);
    }

    public removeCallback(callback: AppUserChangedCallback): void {
        this.callbacks.delete(callback);
    }

    private runCallbacks() {
        for (const callback of this.callbacks) {
            callback(this.appUserID, this.realEmail, this.virtualEmail, undefined, false);
        }
    }

    // Not part of the interface: for testing purposes.
    public setAppUser(appUserID: string | undefined, email: string | undefined) {
        this._appUserID = appUserID;
        this._email = email;
        this.runCallbacks();
    }

    public sendPinForEmail(): Promise<{ status: ResponseStatus; link?: string }> {
        return panic("Not implemented: sendPinForEmail");
    }

    public getPasswordForOAuth2Token(): Promise<OAuth2TokenAuth | undefined> {
        return panic("Not implemented: getPasswordForOAuth2Token");
    }

    public getPasswordForEmailPin(): Promise<{ password: string; loginToken: AppLoginTokenContainer } | undefined> {
        return panic("Not implemented: getPasswordForEmailPin");
    }

    public getTokenForInviteLink(): Promise<{ loginToken: AppLoginTokenContainer | undefined } | undefined> {
        return panic("Not implemented: getTokenForInviteLink");
    }

    public authorizeForAppWithEmail(): Promise<boolean> {
        return panic("Not implemented: authorizeForAppWithEmail");
    }

    public authorizeForAppWithPassword(): Promise<boolean> {
        return panic("Not implemented: authorizeForAppWithPassword");
    }

    public async tryGetAppUserID(): Promise<void> {
        return;
    }

    public setEmailAddressFromBuilder(): void {
        return panic("Not implemented: setEmailAddressFromBuilder");
    }

    public async authorizeForAppWithLoginToken(): Promise<boolean> {
        throw new Error("Method not implemented: authorizeForAppWithLoginToken");
    }

    public async authorizeForAppWithInviteMagicLink(_token: string): Promise<boolean> {
        throw new Error("Method not implemented: authorizeForAppWithInviteMagicLink");
    }
}

interface DummyAppEnvironmentOptions {
    readonly appFacilities: MinimalAppFacilities;
    readonly authenticator: AppUserAuthenticator;
    readonly database: Database;
    readonly dataStore: DataStore;
    readonly queryableDataStore: QueryableDataStore;
    readonly pluginConfigs: readonly PluginConfig[];
    readonly sourceMetadata: readonly SourceMetadata[];
    readonly actionsStateObservable: ChangeObservable<AppEnvironmentActionsState>;
}

export class DummyAppEnvironment implements MinimalAppEnvironment {
    public readonly isBuilder = false;

    public readonly writeSource = "player" as const;

    constructor(
        public readonly appID: string,
        public readonly appKind: AppKind,
        private readonly opts?: Partial<DummyAppEnvironmentOptions>
    ) {}

    public get appFacilities(): MinimalAppFacilities {
        return defined(this.opts?.appFacilities);
    }

    public get authenticator(): AppUserAuthenticator {
        return defined(this.opts?.authenticator);
    }

    public get database(): Database {
        return defined(this.opts?.database);
    }

    public get dataStore(): DataStore {
        return defined(this.opts?.dataStore);
    }

    public get appFeatures(): AppFeatures {
        return defaultAppFeatures;
    }

    public get sourceMetadata(): readonly SourceMetadata[] | undefined {
        return this.opts?.sourceMetadata;
    }

    public get pluginConfigs(): readonly PluginConfig[] {
        return this.opts?.pluginConfigs ?? [];
    }

    public get appData(): AppData {
        return {
            id: this.appID,
            name: "Dummy App",
            appIconSubtitle: "by the app team",
            isFreeEminence: true,
            appPrimaryAccentColor: "pink",
            getIcon: async () => undefined,
            getPlayURL: async () => undefined,
            screenSize: new Watchable(undefined),
        };
    }

    public get userProfileTableInfo(): undefined {
        return undefined;
    }

    public get queryableDataStore(): QueryableDataStore | undefined {
        return this.opts?.queryableDataStore;
    }

    public get localDataStore(): undefined {
        return undefined;
    }

    public get eminenceFlags(): EminenceFlags {
        return eminenceFull;
    }

    public get locationSettings(): undefined {
        return undefined;
    }

    public getPublishInfo(): AppPublishInfo {
        return {
            isPublished: true,
            shortName: undefined,
            customDomain: undefined,
        };
    }

    public isGeocodeWithinQuota(_key: string): boolean {
        return true;
    }

    public countGeocodeForQuota(_key: string): void {
        // nothing to do
    }

    public reportGeocodes(_count: number): void {
        // nothing to do
    }

    public makeReloadResiliencyQueue<T>(
        name: string,
        onOnline: () => void,
        idForItem: (item: T) => string
    ): OfflineQueue<T> | undefined {
        // We want to make sure the OfflineQueueImpl gets tested at all, so we use it
        // as the "reload resilience" queue here.
        return new OfflineQueueImplWithoutOnlineHooks(name, onOnline, idForItem, {
            getItem: sessionStorageGetItem,
            setItem: sessionStorageSetItem,
        });
    }

    public makeOfflineQueue<T>(
        name: string,
        onOnline: () => void,
        idForItem: (item: T) => string
    ): OfflineQueue<T> | undefined {
        return this.makeReloadResiliencyQueue(name, onOnline, idForItem);
    }

    public getActionsState(): AppEnvironmentActionsState {
        return this.opts?.actionsStateObservable?.current ?? emptyAppEnvironmentActionsState;
    }

    public subscribeToActionsState(handler: AppEnvironmentActionStateHandler): void {
        if (this.opts?.actionsStateObservable !== undefined) {
            this.opts.actionsStateObservable.subscribe(handler);
        }

        setTimeout(() => handler(this.getActionsState()), 0);
    }

    public unsubscribeFromActionState(handler: AppEnvironmentActionStateHandler): void {
        if (this.opts?.actionsStateObservable !== undefined) {
            this.opts.actionsStateObservable.unsubscribe(handler);
        }
    }
}
