import type { TableColumn } from "@glide/type-schema";
import { DateTimeParts } from "@glide/data-types";
import { decomposeFormatFormula, ValueFormatKind } from "./formula-specifications";

export function isTzAwareColumn(c: TableColumn): boolean {
    let isTzAware = false;
    if (c?.displayFormula !== undefined) {
        const spec = decomposeFormatFormula(c.displayFormula);
        if (spec?.kind === ValueFormatKind.DateTime) {
            isTzAware = spec.timeZone === "local";
        }
    }
    return isTzAware;
}

export function isTimeOnlyColumn(c: TableColumn): boolean {
    if (c?.displayFormula === undefined) return false;

    const spec = decomposeFormatFormula(c.displayFormula);
    return spec?.kind === ValueFormatKind.DateTime && spec.parts === DateTimeParts.TimeOnly;
}
