import { hasOwnProperty } from "@glideapps/ts-necessities";
import type { BasePrimitiveValue } from "@glide/data-types";
import type { NativeTableName, UniversalTableName } from "./table-name";
import { isTableName } from "./table-name";
import type {
    ColumnType,
    Description,
    Formula,
    FormulaKind,
    PrimitiveGlideTypeKind,
    SpecialValueDescription,
} from "./description";
import type { UnaryPredicateFormulaOperator, BinaryPredicateFormulaOperator } from "./data-filters";

function isNativeTableName(tn: unknown): tn is NativeTableName {
    if (!hasOwnProperty(tn, "isNativeTableName")) return false;
    if (tn.isNativeTableName !== true) return false;
    if (!hasOwnProperty(tn, "nativeTableID")) return false;
    if (typeof tn.nativeTableID !== "string") return false;
    return true;
}

export function isUniversalTableName(tn: unknown): tn is UniversalTableName {
    return isTableName(tn) || isNativeTableName(tn);
}

export interface GetTableRowsFormula extends Formula {
    readonly kind: FormulaKind.GetTableRows;
    readonly table: UniversalTableName;
}

export interface FilterRowsFormula extends Formula {
    readonly kind: FormulaKind.FilterRows;
    readonly rows: Formula;
    readonly predicateContextName: string;
    readonly predicate: Formula;
    readonly omitSort?: true;
}

export interface MapRowsFormula extends Formula {
    readonly kind: FormulaKind.MapRows;
    readonly rows: Formula;
    readonly functionContextName: string;
    readonly function: Formula;
}

export enum ReduceOperator {
    AllTrue = "all-true",
    SomeTrue = "some-true",
    Sum = "sum",
    CountNonEmpty = "count-non-empty",
    CountUnique = "count-unique",
    CountTrue = "count-true",
    CountNotTrue = "count-not-true",
    Average = "average",
    Range = "range",
}

export const reduceType: Record<ReduceOperator, PrimitiveGlideTypeKind> = {
    [ReduceOperator.AllTrue]: "boolean",
    [ReduceOperator.SomeTrue]: "boolean",
    [ReduceOperator.Sum]: "number",
    [ReduceOperator.CountNonEmpty]: "number",
    [ReduceOperator.CountUnique]: "number",
    [ReduceOperator.CountTrue]: "number",
    [ReduceOperator.CountNotTrue]: "number",
    [ReduceOperator.Average]: "number",
    [ReduceOperator.Range]: "number",
};

export enum ReduceToMemberByOperator {
    Minimum = "minimum",
    Maximum = "maximum",
}

export interface ReduceFormula extends Formula {
    readonly kind: FormulaKind.Reduce;
    readonly operator: ReduceOperator;
    readonly rows: Formula;
    readonly valueContextName: string;
    readonly value: Formula;
}

export interface ReduceToMemberByFormula extends Formula {
    readonly kind: FormulaKind.ReduceToMemberBy;
    readonly operator: ReduceToMemberByOperator;
    readonly rows: Formula;
    readonly valueContextName: string;
    readonly key: Formula; // This is what we compare by
    readonly value: Formula; // This is the result we return
}

export interface JoinStringsFormula extends Formula {
    readonly kind: FormulaKind.JoinStrings;
    readonly rows: Formula;
    readonly valueContextName: string;
    readonly value: Formula;

    readonly separator: Formula;
}

export interface SplitStringFormula extends Formula {
    readonly kind: FormulaKind.SplitString;
    readonly string: Formula;
    readonly separator: Formula;
}

export interface FindRowFormula extends Formula {
    readonly kind: FormulaKind.FindRow;
    readonly rows: Formula;
    readonly predicateContextName: string;
    readonly predicate: Formula;
    readonly omitSort?: true;
}

export interface IfThenElseFormula extends Formula {
    readonly kind: FormulaKind.IfThenElse;
    readonly condition: Formula;
    readonly consequent: Formula;
    readonly alternative?: Formula;
}

export interface ConstantFormula extends Formula {
    readonly kind: FormulaKind.Constant;
    readonly value: BasePrimitiveValue;
}

export interface EmptyFormula extends Formula {
    readonly kind: FormulaKind.Empty;
}

export interface CheckValueFormula extends Formula {
    readonly kind: FormulaKind.CheckValue;
    readonly operator: UnaryPredicateFormulaOperator;
    readonly value: Formula;
}

export interface LeftRightFormula extends Formula {
    readonly left: Formula;
    readonly right: Formula;
}

export interface CompareValuesFormula extends LeftRightFormula {
    readonly kind: FormulaKind.CompareValues;
    readonly operator: BinaryPredicateFormulaOperator;
}

export interface GetContextFormula extends Formula {
    readonly kind: FormulaKind.GetContext;
    readonly contextName: string;
}

export interface GetColumnFormula extends Formula {
    readonly kind: FormulaKind.GetColumn;
    readonly column: string;
    // `undefined` always means outermost
    readonly contextName?: string;
}

export interface GetUserProfileRowFormula extends Formula {
    readonly kind: FormulaKind.GetUserProfileRow;
}

export interface GetActionNodeOutputFormula extends Formula {
    readonly kind: FormulaKind.GetActionNodeOutput;
    readonly actionNodeKey: string;
    readonly outputName: string;
    readonly columnInRow: string | undefined;
    readonly withFormat: boolean;
}

export interface ApplyColumnFormatFormula extends Formula {
    readonly kind: FormulaKind.ApplyColumnFormat;
    readonly column: string;
    readonly value: Formula;
    // `undefined` always means outermost
    readonly contextName?: string;
}

interface TextTemplateReplacement {
    readonly pattern: Formula;
    readonly replacement: Formula;
}

export interface TextTemplateFormula extends Formula {
    readonly kind: FormulaKind.TextTemplate;
    readonly template: Formula;
    readonly replacements: readonly TextTemplateReplacement[];
}

export interface ArrayContainsFormula extends Formula {
    readonly kind: FormulaKind.ArrayContains;
    readonly array: Formula;
    readonly item: Formula;
}

export interface ArraysOverlapFormula extends LeftRightFormula {
    readonly kind: FormulaKind.ArraysOverlap;
}

export interface MakeArrayFormula extends Formula {
    readonly kind: FormulaKind.MakeArray;
    readonly items: readonly Formula[];
}

export interface NotFormula extends Formula {
    readonly kind: FormulaKind.Not;
    readonly value: Formula;
}

export interface AndOrFormula extends LeftRightFormula {
    readonly kind: FormulaKind.And | FormulaKind.Or;
}

export interface SpecialValueFormula extends Formula {
    readonly kind: FormulaKind.SpecialValue;
    readonly valueKind: SpecialValueDescription;
}

export interface ConvertToTypeFormula extends Formula {
    readonly kind: FormulaKind.ConvertToType;
    readonly typeKind: PrimitiveGlideTypeKind;
    readonly value: Formula;
}

export interface StartOrEndOfDayFormula extends Formula {
    readonly kind: FormulaKind.StartOfDay | FormulaKind.EndOfDay;
    readonly dateTime: Formula;
}

export interface WithFormula extends Formula {
    readonly kind: FormulaKind.With;
    readonly contextName: string;
    readonly context: Formula;
    readonly value: Formula;
}

export interface GetNthFormula extends Formula {
    readonly kind: FormulaKind.GetNth | FormulaKind.GetNthLast;
    readonly array: Formula;
    readonly index: Formula;
}

export interface RandomPickFormula extends Formula {
    readonly kind: FormulaKind.RandomPick;
    readonly array: Formula;
}

export interface IsInRangeFormula extends Formula {
    readonly kind: FormulaKind.IsInRange;
    readonly value: Formula;
    readonly start: Formula;
    readonly end: Formula;
}

export interface AssignVariablesFormula extends Formula {
    readonly kind: FormulaKind.AssignVariables;
    readonly assignments: readonly [string, Formula][];
    readonly body: Formula;
}

export interface GetVariableFormula extends Formula {
    readonly kind: FormulaKind.GetVariable;
    readonly name: string;
}

export interface RandomFormula extends Formula {
    readonly kind: FormulaKind.Random;
}

export enum UnaryMathFunction {
    Negate = "negate",
    Absolute = "absolute",
    Floor = "floor",
    Ceiling = "ceiling",
    Round = "round",
    Sign = "sign",
    Truncate = "truncate",
    SquareRoot = "square-root",
    Sine = "sine",
    Cosine = "cosine",
    Tangent = "tangent",
    ArcSine = "arcsine",
    ArcCosine = "arccosine",
    ArcTangent = "arctangent",
    Logarithm = "logarithm",
    Year = "year",
    Month = "month",
    Day = "day",
    Hour = "hour",
    Minute = "minute",
    Second = "second",
    Weekday = "weekday",
    WeekNumber = "week-number",
}

export interface UnaryMathFormula extends Formula {
    readonly kind: FormulaKind.UnaryMath;
    readonly fn: UnaryMathFunction;
    readonly operand: Formula;
}

export enum BinaryMathFunction {
    Add = "add",
    Subtract = "subtract",
    Multiply = "multiply",
    Divide = "divide",
    Modulo = "modulo",
    Minimum = "minimum",
    Maximum = "maximum",
    Power = "power",
    Logarithm = "logarithm",
    ArcTangent2 = "arctangent2",
    Round = "round",
    Truncate = "truncate",
}

export interface BinaryMathFormula extends LeftRightFormula {
    readonly kind: FormulaKind.BinaryMath;
    readonly fn: BinaryMathFunction;
}

export interface WithUserEnteredTextFormula extends Formula {
    readonly kind: FormulaKind.WithUserEnteredText;
    readonly text: string;
    readonly formula: Formula;
}

export interface FormatNumberFixedFormula extends Formula {
    readonly kind: FormulaKind.FormatNumberFixed;
    readonly value: Formula;
    readonly decimalsAfterPoint: Formula;
    readonly groupSeparator: Formula;
    readonly currency: Formula | undefined;
}

export interface FormatDateTimeFormula extends Formula {
    readonly kind: FormulaKind.FormatDateTime;
    readonly value: Formula;
    readonly dateFormat: Formula | undefined;
    readonly timeFormat: Formula | undefined;
    readonly timeZone: Formula | undefined;
}

export interface FormatDurationFormula extends Formula {
    readonly kind: FormulaKind.FormatDuration;
    readonly value: Formula;
}

export interface FormatJSONFormula extends Formula {
    readonly kind: FormulaKind.FormatJSON;
    readonly value: Formula;
}

export interface CurrentLocationFormula extends Formula {
    readonly kind: FormulaKind.CurrentLocation;
}

export interface GeoDistanceFormula extends LeftRightFormula {
    readonly kind: FormulaKind.GeoDistance;
}

export interface GeocodeAddressFormula extends Formula {
    readonly kind: FormulaKind.GeocodeAddress;
    readonly address: Formula;
}

export enum GeneratedImageKind {
    Triangles = "triangles",
    Mesh = "mesh",
}

export interface GenerateImageFormula extends Formula {
    readonly kind: FormulaKind.GenerateImage;
    readonly input: Formula;
    readonly imageKind: Formula;
}

interface QueryParameter {
    readonly name: Formula;
    readonly value: Formula;
}

export type QueryParametersFormulas = readonly QueryParameter[];

export interface UserAPIFetchFormula extends Formula {
    readonly kind: FormulaKind.UserAPIFetch;
    readonly webhookID: Formula;
    readonly params: QueryParametersFormulas;
}

export interface ConstructURLFormula extends Formula {
    readonly kind: FormulaKind.ConstructURL;
    readonly scheme: Formula;
    readonly host: Formula;
    readonly path: Formula;
    readonly params: QueryParametersFormulas;
}

export interface YesCodeFormula extends Formula {
    readonly kind: FormulaKind.YesCode;
    readonly url: Formula;
    readonly params: QueryParametersFormulas;
    readonly type: ColumnType;
    readonly typeIsPrecise: boolean;
}

export interface PluginComputationFormula extends Formula {
    readonly kind: FormulaKind.PluginComputation;
    readonly pluginID: Formula;
    readonly computationID: Formula;
    // We allow this to be `undefined` so that we don't crash apps that use
    // the old formula-based `params`.
    readonly parameters: Description | undefined;
    readonly resultName: Formula;
    readonly resultType: ColumnType;
    readonly resultTypeIsPrecise: boolean;
}

export interface Ordering {
    // If no sort keys is given, no sort is performed, but reversal will if
    // specified.
    readonly sortKey: Formula | undefined;
    readonly reverse: boolean;
}

export interface FilterSortLimitFormula extends Formula {
    readonly kind: FormulaKind.FilterSortLimit;
    readonly rows: Formula;
    readonly predicate: Formula | undefined;
    readonly orderings: readonly Ordering[];
    // number
    readonly limit: Formula | undefined;
}
