import {
    type ComputationModel,
    type GroundValue,
    type LoadedGroundValue,
    type LoadedRow,
    type LoadingValue,
    type Row,
    MutableTable,
    Table,
    isLoadingValue,
    isPrimitiveValue,
    isThunk,
    type RootPath,
    type RowIndex,
} from "@glide/computation-model-types";
import {
    type WireBackendAppEnvironment,
    type DataStore,
    type MinimalAppFacilities,
    type QueryableDataStore,
    type QueryableRowsRootFinder,
    type AppEnvironmentActionsState,
    isUploadFileResponseError,
} from "@glide/common-core/dist/js/components/types";
import { actionRunTimeout } from "@glide/common-core/dist/js/action-timeout";
import { getLocalizedString } from "@glide/localization";
import {
    type AppDescription,
    type NonUserAppFeatures,
    type ArrayScreenDescription,
    type ClassOrArrayScreenDescription,
    type ClassScreenDescription,
    type ScreenDescription,
    type TabDescription,
    MutatingScreenKind,
    ScreenDescriptionKind,
    type ConditionalActionNode,
    getScreenProperty,
} from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import {
    ScreenFlag,
    getAppFeatures,
    getAppKind,
    getAppTabs,
} from "@glide/common-core/dist/js/components/SerializedApp";
import { uploadFileIntoGlideStorage } from "@glide/common-core/dist/js/components/upload-handlers";
import {
    asMaybeString,
    asString,
    asTable,
    getRowColumn,
    isNotEmpty,
    loadedDefinedMap,
    nullLoadingToUndefined,
} from "@glide/common-core/dist/js/computation-model/data";
import { getRowIndexForRow } from "@glide/common-core/dist/js/computation-model/row-index";
import { SimpleTableKeeper } from "@glide/computation-model";
import type { Database } from "@glide/common-core/dist/js/Database/core";
import { AuthenticationMethod } from "@glide/common-core/dist/js/Database";
import {
    type TableName,
    areTableNamesEqual,
    nativeTableRowIDColumnName,
    type TableGlideType,
    type TableRefGlideType,
    getTableName,
    getTableRefTableName,
    isColumnWritable,
    isComputedColumn,
    isSingleRelationType,
    makeTableRef,
    isQueryableTable,
} from "@glide/type-schema";
import { isTemplate, localDataStoreName } from "@glide/common-core/dist/js/database-strings";
import { frontendCheckQuotaKinds } from "@glide/common-core/dist/js/Database/quotas";
import {
    type InputOutputTables,
    isClassOrArrayScreenDescription,
    makeInputOutputTables,
} from "@glide/common-core/dist/js/description";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import type { HeartbeatAndQuotaManager } from "@glide/common-core/dist/js/heartbeat-and-quota";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import {
    memoizeFunction,
    ArraySet,
    DebouncedChangeObservable,
    Watchable,
    checkArray,
    checkBoolean,
    checkString,
    isArray,
    logError,
    logInfo,
    nullToUndefined,
    shallowEqualArrays,
    type ChangeObservable,
} from "@glide/support";
import { NetworkStatus } from "@glide/common-core/dist/js/network-status";
import { type WireFormFactor, DeviceFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import type { CodeScannerStandards } from "@glide/component-utils";
import { GlideDateTime, GlideJSON, convertValueFromSerializable, isChronoLoadedObservable } from "@glide/data-types";
import type {
    WireAppCodeScannerScreenComponent,
    WireSignInComponent,
} from "@glide/fluent-components/dist/js/base-components";
import { activitySpinnerComponent } from "@glide/fluent-components/dist/js/component";
import {
    type ExistingAppDescriptionContext,
    type SignInUpScreenName,
    addClassScreenName,
    arrayScreenName,
    classScreenName,
    editClassScreenName,
    getMenuScreenName,
    getMutatingKindForScreen,
    isAddClassScreenName,
    signInScreenName,
    userProfileScreenName,
} from "@glide/function-utils";
import type { ActionDebugger } from "@glide/generator/dist/js/actions/compound-handler";
import {
    doesAuthenticationMethodAllowSignUp,
    getAppAuthenticationData,
} from "@glide/generator/dist/js/authentication-method";
import {
    type HydratedRowContext,
    type HydratedScreenContext,
    type WireActionBackend,
    type WireFrontendActionCallbacks as WireActionCallbacks,
    type WireActionRunner,
    type WireAlwaysEditableValue,
    type WireComponentHydrationResult,
    type WireHydrationFollowUp,
    type WirePredicate,
    type WireRowActionHydrationValueProvider,
    type WireRowComponentHydrationBackend,
    type BuilderCallbacks,
    type SearchableColumns,
    type ValueChangeSource,
    type WireBackendCallbacks,
    type WireFrontendActionCallbacks,
    type WireTableComponentHydratorConstructor,
    type WireTableTransformer,
    type WireValueGetter,
    type WireNavigationModel,
    type WireNavigationModelBase,
    type WireScreen,
    type WireSubsidiaryScreen,
    type WireTab,
    type WireUserProfile,
    type NavigationPath,
    type NavigationPathTransformer,
    type ParsedScreen,
    type ParsedScreenBase,
    type WithModalFlag,
    type WireAction,
    type WireComponent,
    type WireMessage,
    WireActionResult,
    PageScreenTarget,
    WireComponentKind,
    WireNavigationAction,
    WireScreenFlag,
    WireModalSize,
    WireScreenPosition,
} from "@glide/wire";
import {
    findTabInAppDescription,
    makeInputOutputTablesForClassOrArrayScreen,
} from "@glide/generator/dist/js/description-utils";
import {
    arePrimitiveValuesStrictlyEqual,
    encodeScreenKey,
    invokeRowComponentHydrator,
} from "@glide/generator/dist/js/wire/utils";
import {
    type Writable,
    DefaultMap,
    assert,
    assertNever,
    defined,
    definedMap,
    filterUndefined,
    mapFilterUndefined,
    panic,
    proveNever,
} from "@glideapps/ts-necessities";
import md5 from "blueimp-md5";
import deepEqual from "deep-equal";
import fromPairs from "lodash/fromPairs";
import last from "lodash/last";
import sortBy from "lodash/sortBy";
import zip from "lodash/zip";
import { ActionLogger } from "./action-logger";
import {
    type HydrationHelper,
    type HydrationResult,
    type InternalTabHydrator,
    RowActionHydrationBackend,
    RowComponentHydrationBackend,
    RowHydrationValueProvider,
    StateManager,
    TableComponentHydrationBackend,
} from "./hydration";
import {
    ObjectRetirer,
    emptyHydratedScreenContext,
    emptySubscriptionInfo,
    makeHydratedScreenContext,
    getShouldShowOriginalErrorMessageForTable,
} from "./internal";
import {
    type ActionHydrationContext,
    type ActionRunnerWithContext,
    type BuilderTableData,
    type ComponentState,
    type HydratedScreen,
    type InternalScreen,
    type InternalScreenContext,
    type InternalTab,
    type OnChangeData,
    type ScreenBuilderTableData,
    type ScreenHydrationContext,
    type StateValuesMap,
    type SubscriptionInfo,
    type SubscriptionNeeds,
    type ValueProviderActionContext,
    ActionTokenMap,
    isComponentState,
} from "./internal-types";
import { type SpecialScreenName, isSpecialScreenName, unparseParsedScreen } from "./parsed-path";
import {
    type DynamicTransformsApplicator,
    type RowToCopyMaker,
    type ScreenInflator,
    type ScreenInflatorCallbacks,
    type SpecialComponentHydrator,
    makeScreenInflator,
} from "./screen-inflator";
import type { BuilderNavigationModel, NavigationModelData, WireBackend, WireBuilderBackend } from "./types";
import { shouldGenerateRowIDForTable } from "@glide/common-core/dist/js/schema-properties";
import { EnqueueActionException } from "@glide/post-action";
import { resolveQueryFromRows } from "./resolve-query";
import type { AppData } from "@glide/plugins";
import { Result } from "@glide/plugins";
import type { WriteSourceType } from "@glide/common-core";

const quotaError: WireMessage = {
    title: "Oops! This page is over quota",
    message: "Please upgrade for more resources.",
    link: {
        text: "Contact the Page Maker",
    },
};

function shallowEqual<T>(obj1: T, obj2: T): boolean {
    assert(obj1 !== null && obj2 !== null);
    if (obj1 === undefined || obj2 === undefined) {
        return (obj1 === undefined) === (obj2 === undefined);
    }

    // https://stackoverflow.com/questions/22266826/how-can-i-do-a-shallow-comparison-of-the-properties-of-two-objects-with-javascri
    return (
        Object.keys(obj1).length === Object.keys(obj2).length &&
        Object.keys(obj1).every(key => (obj1 as any)[key] === (obj2 as any)[key])
    );
}

function maybeShallowOldest<T>(older: T, newer: T): T {
    if (shallowEqual(older, newer)) {
        return older;
    }
    return newer;
}

export function makeStateSaveKey(
    subComponentsName: string | undefined,
    componentID: string | undefined,
    ctx: HydratedScreenContext | undefined
): string | undefined {
    if (componentID === undefined) return undefined;
    return `${componentID}-${subComponentsName ?? ""}-${ctx?.inputRows[0]?.$rowID}-${ctx?.outputRow?.$rowID}`;
}

export function makeScreenKey(path: NavigationPath, s: ParsedScreen | undefined): string {
    if (s === undefined) return "";
    const depth = path.getDepthOfScreen(s);
    return `${unparseParsedScreen(s).join("/")}-${depth}`;
}

function isSubscriptionDirty(si: SubscriptionInfo, needsChanged: SubscriptionNeeds): boolean {
    if (needsChanged.override) return true;

    if (si.handlerAndPath?.handler.needsRedraw === true) return true;

    // This is where we check for changes in ##subscriptionNeeds.
    if (si.needs.parsedPath && needsChanged.parsedPath) return true;
    if (si.needs.shuffleOrder && needsChanged.shuffleOrder) return true;
    if (si.needs.online && needsChanged.online) return true;

    return si.subsidiaries.some(s => isSubscriptionDirty(s, needsChanged));
}

function isComponentStateDirty(
    cs: ComponentState,
    additionalInputs: readonly unknown[],
    needsChanged: SubscriptionNeeds
): boolean {
    return (
        isSubscriptionDirty(cs, needsChanged) ||
        cs.stateChanged ||
        !shallowEqualArrays(cs.additionalInputs, additionalInputs) ||
        Object.values(cs.subComponentStates).some(([, states]) =>
            states.some(scs => isComponentStateDirty(scs, [], needsChanged))
        ) ||
        cs.subsidiaries.some(si => isComponentState(si) && isComponentStateDirty(si, [], needsChanged))
    );
}

export interface InternalNavigationModelBase {
    readonly tabs: readonly InternalTab[] | undefined;

    readonly userProfile: WireUserProfile | undefined;
    readonly userProfileSubscriptionInfo: SubscriptionInfo | undefined;
}

export const emptyScreen: WireScreen = {
    key: encodeScreenKey("empty"),
    title: "",
    components: [],
    specialComponents: [],
    closeAction: undefined,
    flags: [],
    isInModal: false,
    tabIcon: "",
};

function areScreenContextsEqual(a: HydratedScreenContext | undefined, b: HydratedScreenContext): boolean {
    if (a === undefined) return false;
    if (!shallowEqualArrays(a.inputRows, b.inputRows)) return false;
    if (a.outputRow !== b.outputRow) return false;
    return true;
}

function keepSubscriptionInfo<T extends SubscriptionInfo>(
    hydrationContext: ScreenHydrationContext,
    hydratedScreen: HydratedScreen,
    si: T
): T {
    assert(hydratedScreen !== undefined);

    for (const token of si.tokens) {
        if (hydratedScreen.actions.has(token)) {
            hydrationContext.actions.set(token, defined(hydratedScreen.actions.get(token)));
        } else if (hydratedScreen.onChangeData.has(token)) {
            hydrationContext.onChangeValueRows.set(token, defined(hydratedScreen.onChangeData.get(token)));
        } else {
            return panic("Token not registered");
        }
    }

    for (const subsidiary of si.subsidiaries) {
        keepSubscriptionInfo(hydrationContext, hydratedScreen, subsidiary);
    }

    if (isComponentState(si)) {
        for (const [, states] of Object.values(si.subComponentStates)) {
            for (const scs of states) {
                keepSubscriptionInfo(hydrationContext, hydratedScreen, scs);
            }
        }
    }

    return si;
}

export interface ScreenHydrationResult {
    readonly hydratedScreen: HydratedScreen;
    readonly context: HydratedScreenContext | LoadingValue;
    readonly followUp: WireHydrationFollowUp;
}

enum State {
    Clean,
    Requested,
    Recomputing,
    FollowUpRequested,
    FollowUpWithCleanupRequested,
    Retired,
}

function isFollowUpRequestedState(s: State): s is State.FollowUpRequested | State.FollowUpWithCleanupRequested {
    return s === State.FollowUpRequested || s === State.FollowUpWithCleanupRequested;
}

function isRecomputingState(
    s: State
): s is State.Recomputing | State.FollowUpRequested | State.FollowUpWithCleanupRequested {
    return s === State.Recomputing || isFollowUpRequestedState(s);
}

type ActionBackendWithHydrationContext = WireActionBackend & { readonly hydrationContext: ValueProviderActionContext };

function makeRowKeyForScreen(screenName: string, rowID: string | undefined): string {
    return `${screenName}-${rowID ?? "default"}`;
}

const makeBuilderNavigationModel = memoizeFunction(
    "makeBuilderNavigationModel",
    (
        currentLogicalTabIndex: number | "user-profile" | undefined,
        isCurrentTabVisible: boolean,
        screenName: string,
        screen: ScreenDescription | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        tabScreenName: string
    ): BuilderNavigationModel => {
        return {
            currentLogicalTabIndex,
            isCurrentTabVisible,
            currentScreenHasFlag: f => {
                if (f === ScreenFlag.IsUserProfileScreen) {
                    return screenName === userProfileScreenName;
                }
                if (screen === undefined) return f === ScreenFlag.IsErrorScreen || f === ScreenFlag.IsUnconfigurable;
                switch (f) {
                    case ScreenFlag.IsEditScreen:
                        return mutatingScreenKind === MutatingScreenKind.EditScreen;
                    case ScreenFlag.IsAddItemScreen:
                        return mutatingScreenKind === MutatingScreenKind.AddScreen;
                    case ScreenFlag.IsFormScreen:
                        return mutatingScreenKind === MutatingScreenKind.FormScreen;
                    case ScreenFlag.IsTopScreen:
                        return screenName === tabScreenName;
                    case ScreenFlag.IsArrayScreen:
                        return screen.kind === ScreenDescriptionKind.Array;
                    case ScreenFlag.IsMapScreen:
                    case ScreenFlag.IsUnconfigurable:
                    case ScreenFlag.IsSignaturePad:
                    case ScreenFlag.IsShareScreen:
                    case ScreenFlag.IsFinalTransient:
                    case ScreenFlag.IsErrorScreen:
                        return false;
                    default:
                        return assertNever(f);
                }
            },
        };
    }
);

// We memoize this so as not to trigger the observable whenever we set it.
const makeNavigationModelData = memoizeFunction(
    "makeNavigationModelData",
    (
        screenName: string,
        mutatingScreenKind: MutatingScreenKind | undefined,
        hasUnconfigurableModal: boolean,
        tableData: ScreenBuilderTableData | undefined,
        context: HydratedScreenContext | LoadingValue | undefined,
        tables: InputOutputTables | undefined,
        navigationModel: BuilderNavigationModel
    ): NavigationModelData => {
        return {
            screenName,
            mutatingScreenKind,
            hasUnconfigurableModal,
            tableData,
            navigationModel,
            context,
            tables,
        };
    }
);

function makeTabScreenVisibilityPredicates(
    internalTabs: readonly InternalTab[] | undefined
): ReadonlyMap<string, WirePredicate> {
    return new Map(internalTabs?.map(t => [t.tabScreenName, t.visibilityPredicate]));
}

interface ScreenHydrator {
    readonly screenName: string;

    addFollowUp(followUp: WireHydrationFollowUp): void;

    hydrateRowComponents<THydrator>(
        // If this is hydrating sub-components, this is the name for this
        // particular set of sub-components.  We don't support nesting of this
        // yet.
        subComponentsName: string | undefined,
        hydrators: readonly [hydrator: THydrator, componentID: string | undefined][],
        additionalInputs: readonly unknown[],
        hydrate: (
            hb: WireRowComponentHydrationBackend,
            h: THydrator,
            bc: BuilderCallbacks | undefined
        ) => WireComponentHydrationResult | undefined,
        previousHydrated:
            | [
                  context: HydratedScreenContext | LoadingValue | undefined,
                  states: readonly ComponentState[],
                  components: readonly (WireComponent | null)[]
              ]
            | undefined,
        hydrationTables: InputOutputTables,
        hydrationRowContext: HydratedRowContext,
        forceRehydrate: boolean
    ): readonly HydrationResult[];

    hydrateArrayScreenComponent(
        hydrator: WireTableComponentHydratorConstructor,
        oldComponentState: ComponentState | undefined,
        oldScreenContextSubscriptionInfo: SubscriptionInfo | undefined,
        table: Table,
        applyDynamicTransforms: DynamicTransformsApplicator,
        sortTransform: WireTableTransformer,
        limitTransform: WireTableTransformer
    ): { readonly hydrationResult: HydrationResult; readonly firstListItemActionToRun: WireAction | undefined };

    // We separate out the subscribing so that we can do it for
    // subcomponents in one go after hydrating.
    subscribeComponents(
        results: readonly HydrationResult[],
        additionalInputs: readonly unknown[],
        componentNamePrefix: string
    ): readonly ComponentState[];
}

function hydrateAndSubscribeSpecialComponents(
    screenHydrator: ScreenHydrator,
    hydrators: readonly SpecialComponentHydrator[],
    isEditValid: boolean,
    haveSearchableComponent: boolean,
    additionalInputs: readonly unknown[],
    oldHydratedScreen: HydratedScreen | undefined,
    screenTables: InputOutputTables,
    rowScreenContext: HydratedRowContext
): {
    readonly specialComponents: readonly (WireComponent | null)[];
    readonly specialComponentStates: readonly ComponentState[];
} {
    // We don't keep track of which components need to know whether a search
    // bar is needed, so in the case of that changing we just rehydrate all
    // special components.  This only affects apps.
    const forceRehydrate =
        oldHydratedScreen !== undefined && oldHydratedScreen.hasSearchableComponent !== haveSearchableComponent;

    const specialComponentResults = screenHydrator.hydrateRowComponents(
        undefined,
        hydrators.map(h => [h, undefined]),
        additionalInputs,
        (hb, h) =>
            definedMap(h(hb, isEditValid, haveSearchableComponent), c => ({
                component: c.component,
                subsidiaryScreen: c.subsidiaryScreen,
                isValid: true,
            })),
        definedMap(oldHydratedScreen, hs => [
            hs.screenContext,
            hs.specialComponentStates,
            hs.wireScreen.specialComponents,
        ]),
        screenTables,
        rowScreenContext,
        forceRehydrate
    );
    const specialComponentStates = screenHydrator.subscribeComponents(
        specialComponentResults,
        additionalInputs,
        "special-"
    );
    const specialComponents = specialComponentResults.map(({ component: c }) => c);

    return { specialComponents, specialComponentStates };
}

const emptyComponentState: ComponentState = {
    ...emptySubscriptionInfo,
    isComponentState: true,
    stateValues: new Map(),
    stateSaveKey: undefined,
    stateChanged: false,
    isValid: true,
    canBeSearched: false,
    subComponentStates: {},
    additionalInputs: [],
    builderTableData: undefined,
    subsidiaryScreen: undefined,
    editor: undefined,
};

export const dummyFrontendActionCallbacks: WireFrontendActionCallbacks = {
    openLink: () => undefined,
    showToast: () => undefined,
    copyToClipboard: () => undefined,
    playSound: () => undefined,
    showShareSheet: () => undefined,
    signOut: () => undefined,
};

// FIXME: This is a hack that should go away when array screens go away.
const arrayScreenComponentID = "array-screen";

export interface AuxHydrationData {
    readonly hasSubsidiaryScreen: boolean;
}

export function getSubsidiaryScreen(screen: HydratedScreen | undefined): WireSubsidiaryScreen | undefined {
    if (screen === undefined) return undefined;

    for (const cs of [...screen.componentStates, ...screen.specialComponentStates]) {
        if (cs.subsidiaryScreen !== undefined) {
            return cs.subsidiaryScreen;
        }
        for (const [, scss] of Object.values(cs.subComponentStates)) {
            for (const scs of scss) {
                if (scs.subsidiaryScreen !== undefined) {
                    return scs.subsidiaryScreen;
                }
            }
        }
    }
    return undefined;
}

export abstract class WireBackendBase<
    TInternalNavigationModel extends InternalNavigationModelBase,
    TWireNavigationModel extends WireNavigationModel
> implements WireBackend, WireBuilderBackend, QueryableRowsRootFinder
{
    protected readonly navigationModelWatchable: Watchable<TWireNavigationModel>;
    private lastNavigationModel: TWireNavigationModel;
    private metadataWatchable: Watchable<NavigationModelData | undefined> | undefined;
    protected parsedPath: NavigationPath;
    private lastParsedPath: NavigationPath;
    // This is our "aspirational" path.  We'd like to be there because that's
    // the link we started out as, but maybe we can't yet because of some
    // visibility condition.  We keep trying until the user does a navigation
    // action, which overrides it.  We will also report this to the outside as
    // our "current" path, even though `parsedPath` is the real path we're on,
    // and might be different.
    // https://github.com/quicktype/glide/issues/16256
    private preferredParsedPath: NavigationPath | undefined;
    private lastIsOnline: boolean;
    private lastAuxHydrationData: AuxHydrationData | undefined;
    // This has to be brought in sync with `parsedPath` when recomputing.
    private internalNavigationModel: TInternalNavigationModel | undefined;

    private actionCallbacks: WireFrontendActionCallbacks | undefined;

    private maybeScreenInflator: ScreenInflator | undefined;

    private readonly invisibleRowIDs = new Map<string, string>();
    private readonly deletedRowIDs = new Set<string>();
    private screenStateKeeper: [SimpleTableKeeper, RootPath] | undefined;
    private readonly specialScreenRows = new MutableTable();
    private shuffleOrder: DefaultMap<string, number> | undefined = new DefaultMap(() => Math.random());

    protected readonly globalActions: ActionTokenMap = new ActionTokenMap();
    private readonly navigateToTabActions = new DefaultMap<string, WireAction>(tabScreenName => {
        const token = makeRowID();
        const tab = this.findTab(tabScreenName)?.[0];
        const isAppFlyout =
            this.appKind === AppKind.App && this.formFactor === DeviceFormFactor.Phone && tab?.inFlyout === true;
        this.globalActions.set(token, [
            async ab => {
                if (isAppFlyout) {
                    ab.pushFreeScreen(tabScreenName, [], tab.title ?? "", PageScreenTarget.LargeModal, undefined);
                    return WireActionResult.nondescriptSuccess();
                } else {
                    return WireActionResult.fromResult(ab.navigateToTab(tabScreenName, false));
                }
            },
            emptyHydratedScreenContext,
        ]);
        return { token };
    });
    private readonly showUserProfileAction: WireAction | undefined;
    private readonly signInAction: WireAction | undefined;
    private readonly signUpAction: WireAction | undefined;
    private readonly signOutAction: WireAction | undefined;
    private readonly dragBackAction: WireAction;
    protected readonly closeModalAction: WireAction;
    protected readonly navigateUpAction: WireAction;

    private serial: number | undefined;

    private currentURLQuery: string;

    // We set this when chrono loads because we don't want to bookkeep all
    // places where it's needed.
    private rehydrateEverything = false;

    private state = State.Clean;
    // TODO: This is only for debugging - remove
    private readonly id = makeRowID();

    private _isReadyForGC = false;
    private readonly _areQueriesLoadingObservable: DebouncedChangeObservable<boolean> | undefined;
    // TODO: We're using this to trigger `listenForChanges` to re-check its
    // condition when the condition involves loading a query.  This is not a
    // good way to do this, since it triggers indiscriminately.  Instead the
    // subscription should take care of subscribing only itself to the query.
    // FIXME: remove once we're committed to `queriesInComputationModel`
    private readonly _queryChangedCallbacks = new Set<() => void>();

    // The last one is the one that we display.  We use objects instead of the
    // strings to that we can remove them by identity.
    private readonly _busyMessages: { message: string }[] = [];

    private actionsState: AppEnvironmentActionsState | undefined;

    constructor(
        private readonly appEnvironment: WireBackendAppEnvironment,
        protected readonly adc: ExistingAppDescriptionContext,
        protected readonly precomputedSearchableColumns: SearchableColumns | undefined,
        private readonly networkStatusWatchable: ChangeObservable<NetworkStatus>,
        private readonly backendCallbacks: WireBackendCallbacks,
        public readonly isBuilder: boolean,
        private readonly pathTransformer: NavigationPathTransformer,
        // This is only used for cleaning up the parsed path initially.
        userIsLoggedIn: boolean,
        private readonly appHost: string,
        // Only used in apps
        protected readonly fallbackAuthorName: string,
        protected formFactor: WireFormFactor,
        currentURL: string,
        urlPathOrPrevious: string | WireBackendBase<TInternalNavigationModel, TWireNavigationModel> | undefined,
        public readonly writeSource: WriteSourceType
    ) {
        document.dispatchEvent(
            new CustomEvent("GlideExtension", {
                detail: {
                    kind: "appDescription",
                    value: adc.appDescription,
                },
            })
        );
        document.dispatchEvent(
            new CustomEvent("GlideExtension", {
                detail: {
                    kind: "schema",
                    value: adc.schema,
                },
            })
        );

        logInfo("constructing", this.id);

        this.lastNavigationModel = this.makeEmptyNavigationModel({
            urlPath: "/",
            appTitle: "",
            tabs: [],
            flyoutTabs: undefined,
            removeBranding: true,
        });
        this.navigationModelWatchable = new Watchable<TWireNavigationModel>(
            this.postProcessNavigationModel(this.lastNavigationModel)
        );

        this.lastParsedPath = pathTransformer.invisiblePath;
        this.lastIsOnline = this.isOnline;

        if (isBuilder) {
            this.metadataWatchable = new Watchable<NavigationModelData | undefined>(undefined);
        }

        let parsedPath: NavigationPath | undefined;
        if (typeof urlPathOrPrevious === "string") {
            parsedPath = this.parseURLPath(urlPathOrPrevious);
        } else if (urlPathOrPrevious !== undefined) {
            parsedPath = urlPathOrPrevious.parsedPath;
            this.invisibleRowIDs = urlPathOrPrevious.invisibleRowIDs;
            this.screenStateKeeper = urlPathOrPrevious.screenStateKeeper;
        }
        if (parsedPath === undefined || !this.isParsedPathValid(parsedPath, isBuilder)) {
            parsedPath = this.makeRootParsedPath();
        }

        this.parsedPath = parsedPath ?? pathTransformer.invisiblePath;
        this.preferredParsedPath = this.parsedPath;

        if (!isBuilder && (adc.userProfileTableInfo === undefined || !userIsLoggedIn)) {
            this.parsedPath = this.parsedPath.removeScreen(userProfileScreenName);
        }
        if (!isBuilder && userIsLoggedIn) {
            this.parsedPath = this.parsedPath.removeScreen(signInScreenName);
        }

        try {
            const url = new URL(currentURL);
            this.currentURLQuery = url.search;
        } catch {
            this.currentURLQuery = "";
        }

        this.updateAppURL();

        const authData = getAppAuthenticationData(adc.appDescription);
        if (authData.kind !== AuthenticationMethod.Disabled) {
            const showUserProfileActionToken = makeRowID();
            this.showUserProfileAction = { token: showUserProfileActionToken };
            this.globalActions.set(showUserProfileActionToken, [
                async ab => WireActionResult.fromResult(ab.navigateToUserProfile()),
                emptyHydratedScreenContext,
            ]);

            const signInActionToken = makeRowID();
            this.signInAction = { token: signInActionToken };
            this.globalActions.set(signInActionToken, [
                async ab => {
                    ab.signIn("sign-in", undefined);
                    return WireActionResult.nondescriptSuccess();
                },
                emptyHydratedScreenContext,
            ]);

            if (doesAuthenticationMethodAllowSignUp(authData.kind)) {
                const signUpActionToken = makeRowID();
                this.signUpAction = { token: signUpActionToken };
                this.globalActions.set(signUpActionToken, [
                    async ab => {
                        ab.signIn("sign-up", undefined);
                        return WireActionResult.nondescriptSuccess();
                    },
                    emptyHydratedScreenContext,
                ]);
            }

            const signOutActionToken = makeRowID();
            this.signOutAction = { token: signOutActionToken };
            this.globalActions.set(signOutActionToken, [
                async ab => {
                    ab.actionCallbacks.signOut();
                    return WireActionResult.nondescriptSuccess();
                },
                emptyHydratedScreenContext,
            ]);
        }

        const closeModalActionToken = makeRowID();
        this.closeModalAction = { token: closeModalActionToken };
        this.globalActions.set(closeModalActionToken, [
            async ab => {
                ab.closeScreen(WireScreenPosition.Modal);
                return WireActionResult.nondescriptSuccess();
            },
            emptyHydratedScreenContext,
        ]);

        const navigateUpActionToken = makeRowID();
        this.navigateUpAction = { token: navigateUpActionToken };
        this.globalActions.set(navigateUpActionToken, [
            async ab => WireActionResult.fromResult(ab.navigateUp()),
            emptyHydratedScreenContext,
        ]);

        const dragBackActionToken = makeRowID();
        this.dragBackAction = { token: dragBackActionToken };
        this.globalActions.set(dragBackActionToken, [
            async ab => WireActionResult.fromResult(ab.dragBack()),
            emptyHydratedScreenContext,
        ]);

        networkStatusWatchable.subscribe(this.networkStatusChanged);

        const { dataStore, queryableDataStore } = appEnvironment;

        dataStore.getComputationModelObservable(false).subscribe(this.onComputationModelChange);

        if (queryableDataStore !== undefined) {
            queryableDataStore?.addQueryableRowsRootFinder(this);

            // We're debouncing the "loading" status switching to `false`
            // because it's easy to get "pauses" where we don't have
            // subscriptions, and debouncing is way easier that arranging
            // those to not happen.
            this._areQueriesLoadingObservable = new DebouncedChangeObservable(
                queryableDataStore.getAreSubscribedQueriesRunning(false),
                v => (v ? 0 : 500)
            );
            this._areQueriesLoadingObservable.subscribe(this.onDataChange);
        }

        this.appEnvironment.subscribeToActionsState(this.actionsStateChanged);

        isChronoLoadedObservable.subscribe(this.requestFullRehydration);

        this.requestRecompute(true);
    }

    public get appID(): string {
        return this.appEnvironment.appID;
    }

    protected get appFacilities(): MinimalAppFacilities {
        return this.appEnvironment.appFacilities;
    }

    private get dataStore(): DataStore {
        return this.appEnvironment.dataStore;
    }

    protected get localDataStore(): DataStore | undefined {
        return this.appEnvironment.localDataStore;
    }

    protected get queryableDataStore(): QueryableDataStore | undefined {
        return this.appEnvironment.queryableDataStore;
    }

    protected get db(): Database | undefined {
        return this.appEnvironment.database;
    }

    public setFrontendActionCallbacks(callbacks: WireFrontendActionCallbacks): void {
        this.actionCallbacks = callbacks;
    }

    public setFormFactor(formFactor: WireFormFactor): void {
        if (formFactor === this.formFactor) return;

        this.formFactor = formFactor;
        this.requestRecompute(false);
    }

    protected abstract makeEmptyNavigationModel(base: WireNavigationModelBase): TWireNavigationModel;
    protected abstract makeNavigationModel(
        internalModel: TInternalNavigationModel,
        base: WireNavigationModelBase,
        previous: { navModel: TWireNavigationModel; aux: AuxHydrationData | undefined } | undefined
    ): [TWireNavigationModel, AuxHydrationData];
    protected abstract postProcessNavigationModel(model: TWireNavigationModel): TWireNavigationModel;
    protected abstract updateInternalNavigationModel(
        oldModel: TInternalNavigationModel | undefined
    ): TInternalNavigationModel;
    protected abstract rebuildScreens(
        oldModel: TInternalNavigationModel,
        newBase: InternalNavigationModelBase,
        tablesToFetch: ArraySet<TableName>,
        needsChanged: SubscriptionNeeds
    ): [TInternalNavigationModel, readonly WireHydrationFollowUp[]];
    protected abstract getActionFromScreens(
        navModel: TInternalNavigationModel,
        token: string
    ):
        | { action: ActionRunnerWithContext; source: WireScreenPosition; sourceModalSize: WireModalSize | undefined }
        | undefined;
    protected abstract processInHydratedScreens<T>(
        navModel: TInternalNavigationModel,
        processScreen: (hydratedScreen: HydratedScreen, internalScreen: InternalScreen) => T | undefined
    ): T | undefined;
    protected abstract forEachInternalScreen(
        navModel: TInternalNavigationModel,
        f: (screen: InternalScreen) => void
    ): void;
    // This function returns both the screen name as well as the internal
    // screen, which should have the screen name, because the internal screen
    // might not exist for some reason, since the `navModel` is optional.
    // FIXME: Is this really necessary?
    protected abstract getCurrentScreen(
        navModel: TInternalNavigationModel | undefined,
        parsedPath: NavigationPath
    ): [screenName: string, screen: InternalScreen | undefined];

    private heartbeatManager: HeartbeatAndQuotaManager | undefined;
    public setHeartbeatManager(manager: HeartbeatAndQuotaManager): void {
        this.heartbeatManager = manager;
    }

    private getScreenStateKeeper(): [SimpleTableKeeper, RootPath] | undefined {
        if (this.screenStateKeeper === undefined && this.computationModel !== undefined) {
            const keeper = new SimpleTableKeeper();
            const path = this.computationModel.ns.addEntity("screen-state", keeper, undefined);
            this.screenStateKeeper = [keeper, path];
        }
        return this.screenStateKeeper;
    }

    public get appKind(): AppKind {
        return getAppKind(this.appDescription);
    }

    public get appFeatures(): NonUserAppFeatures {
        return getAppFeatures(this.appDescription);
    }

    protected get appDescription(): AppDescription {
        return this.adc.appDescription;
    }

    public get wireNavigationModelObservable(): ChangeObservable<TWireNavigationModel> {
        return this.navigationModelWatchable;
    }

    public get metadataObservable(): ChangeObservable<NavigationModelData | undefined> {
        return defined(this.metadataWatchable);
    }

    protected get computationModel(): ComputationModel | undefined {
        return this.dataStore.getComputationModelObservable(false).current;
    }

    private networkStatusChanged = () => this.onDataChange();

    private actionsStateChanged = (state: AppEnvironmentActionsState) => {
        this.actionsState = state;
        this.onDataChange();
    };

    private requestFullRehydration = () => {
        this.rehydrateEverything = true;
        this.onDataChange();
    };

    // FIXME: remove once we're committed to `queriesInComputationModel`
    private requestFullRehydrationFromQuery = () => {
        // When we get this callback it means that we've "lost" our registered
        // callbacks with the queryable data store, so if a GC happened now it
        // would remove all the queries we care about.  We'll enable GC again
        // once we've rehydrated.
        this.resetReadyForGC();
        for (const cb of this._queryChangedCallbacks) {
            cb();
        }
        this.requestFullRehydration();
    };

    public getAIComponentInstructionsMetadata() {
        const internalTabName = this.parsedPath.getTabScreenName();
        const tabName = definedMap(internalTabName, t => findTabInAppDescription(t, this.appDescription)?.[0].title);

        const {
            theme: { primaryAccentColor, pageEnvironment },
            description: appDescription,
            title: appName,
        } = this.adc.appDescription;
        return {
            pageEnvironment,
            primaryAccentColor,
            appDescription,
            appName,
            tabName,
        };
    }

    private get isOnline(): boolean {
        return this.networkStatusWatchable.current !== NetworkStatus.Offline;
    }

    public setUserName(userName: string): void {
        this.computationModel?.setFallbackUserName(userName);
    }

    private get screenInflator(): ScreenInflator | undefined {
        if (this.maybeScreenInflator === undefined) {
            const callbacks: ScreenInflatorCallbacks = {
                navigateUpAction: this.navigateUpAction,
                signOutAction: this.signOutAction,
                onDataChange: this.onDataChange,
                fetchTableRows: tn => this.fetchTableRows(tn),
                setColumnsInInvisibleRow: (tn, r, u) => this.setColumnsInInvisibleRow(tn, r, u),
                retireSubscriptionInfo: si => this.retireSubscriptionInfo(si, false),
                getOrAddEmptyRow: (tn, k, mc) => this.getOrAddEmptyRow(tn, k, mc),
                getOrCopyRowWithKey: (tn, r, k) => this.getOrCopyRowWithKey(tn, r, k),
                makeRowKeyForScreen,
            };
            this.maybeScreenInflator = makeScreenInflator(
                this.appID,
                this.appFacilities,
                this.adc,
                this.isBuilder,
                this.computationModel,
                this.db,
                callbacks,
                this.precomputedSearchableColumns,
                this.writeSource
            );
        }
        return this.maybeScreenInflator;
    }

    private updateAppURL(): void {
        const unparsed = this.unparseParsedPath(this.parsedPath);
        // Setting '#' as the appHost will cause consuming 'URIs' to just be a hash fragment.
        const newURL = this.appHost === "" ? "#" : `${this.appHost}/dl/${unparsed}${this.currentURLQuery}`;
        this.computationModel?.updateAppURL(newURL);
        if (this.isBuilder) {
            this.dataStore.getComputationModelObservable(true).current?.updateAppURL(newURL);
        }
    }

    protected getHasUnconfigurableModal(_aux: AuxHydrationData): boolean {
        return false;
    }

    protected getCurrentBuilderTableData(_navModel: TInternalNavigationModel): ScreenBuilderTableData | undefined {
        return undefined;
    }

    protected setParsedPath(parsedPath: NavigationPath, navigationAction: WireNavigationAction): boolean {
        // When the URL is reported to have changed we must only compare the
        // first row IDs because we only ever encode one row ID in the URL, so
        // if the two are equal in that regard then the URL hasn't actually
        // changed.  Not doing this can result in only one item displaying in
        // an array screen because the player will report that the URL has
        // changed even when the backend initiated the change.
        // https://github.com/quicktype/glide/issues/15192
        if (this.parsedPath.isEqualTo(parsedPath, navigationAction === WireNavigationAction.URLChanged)) return false;

        // We unset the preferred path when the user makes a navigation
        // action.  To catch those we have to exclude a navigation action
        // that doesn't originate with the user:
        //
        // * The frontend might notify us of a URL change where the URL
        //   doesn't actually change.
        if (
            !(
                navigationAction === WireNavigationAction.URLChanged &&
                this.preferredParsedPath?.isEqualTo(parsedPath, true) === true
            )
        ) {
            this.preferredParsedPath = undefined;
        }

        this.parsedPath = parsedPath;
        this.currentURLQuery = "";

        if (this.preferredParsedPath === undefined) {
            this.updateAppURL();
        }

        this.requestRecompute(false);
        return true;
    }

    public getRowIDsForTable(tableName: TableName): ReadonlySet<string> {
        return this.parsedPath.getRowIDsForTable(this.adc, tableName);
    }

    public get isReadyForGC(): boolean {
        return this._isReadyForGC;
    }

    public resetReadyForGC(): void {
        this._isReadyForGC = false;
    }

    // Will return `undefined` for the user profile faux-tab
    private findTab(tabScreenName: string): [TabDescription, number] | undefined {
        return findTabInAppDescription(tabScreenName, this.appDescription);
    }

    private makeMetadata(
        internalNavModel: TInternalNavigationModel,
        auxHydrationData: AuxHydrationData
    ): NavigationModelData | undefined {
        const { parsedPath } = this;
        if (!parsedPath.isVisible()) return undefined;

        const [screenName, internalScreen] = this.getCurrentScreen(this.internalNavigationModel, parsedPath);
        const tabScreenName = defined(parsedPath.getTabScreenName());

        const maybeTab = this.findTab(tabScreenName);
        let currentLogicalTabIndex: number | undefined;
        let tabIsVisible: boolean;
        if (maybeTab !== undefined) {
            currentLogicalTabIndex = maybeTab[1];
            const internalTab = this.internalNavigationModel?.tabs?.[currentLogicalTabIndex];
            tabIsVisible = internalTab?.isVisible !== false;
        } else {
            tabIsVisible = true;
        }

        const screen: ScreenDescription | undefined = this.appDescription.screenDescriptions[screenName];
        const mutatingScreenKind = getMutatingKindForScreen(screenName, screen);
        const navigationModel = makeBuilderNavigationModel(
            currentLogicalTabIndex ?? (tabScreenName === userProfileScreenName ? "user-profile" : undefined),
            tabIsVisible,
            screenName,
            screen,
            mutatingScreenKind,
            tabScreenName
        );
        let tables: InputOutputTables | undefined;
        if (isClassOrArrayScreenDescription(screen)) {
            tables = makeInputOutputTablesForClassOrArrayScreen(screen, t => this.adc.findTable(t));
        }

        const hasUnconfigurableModal = this.getHasUnconfigurableModal(auxHydrationData);
        const tableData = this.getCurrentBuilderTableData(internalNavModel);

        return makeNavigationModelData(
            screenName,
            mutatingScreenKind,
            hasUnconfigurableModal,
            tableData,
            internalScreen?.hydratedScreen?.screenContext,
            tables,
            navigationModel
        );
    }

    private unparseParsedPath(parsed: NavigationPath): string {
        return parsed.unparseForApp(this.appDescription);
    }

    private parseURLPath(urlPath: string): NavigationPath | undefined {
        return this.pathTransformer.parse(urlPath, this.appDescription);
    }

    private isParsedPathValid(path: NavigationPath, allowHidden: boolean): boolean {
        return path.isValidForApp(this.appDescription, allowHidden);
    }

    private makeRootParsedPath(): NavigationPath | undefined {
        return this.pathTransformer.makeRootForApp(this.appDescription);
    }

    // `forScreenRetire` must be set if the components will not be hydrated
    // again.
    protected retireSubscriptionInfo(si: SubscriptionInfo, forScreenRetire: boolean): void {
        if (si.handlerAndPath !== undefined) {
            try {
                this.computationModel?.ns.deleteEntity(si.handlerAndPath.path);
            } catch (e: unknown) {
                logError("Failed to delete computation model entity", e);
            }
        }
        if (forScreenRetire) {
            si.effect?.unsubscribe?.();
        }
        for (const subsidiary of si.subsidiaries) {
            this.retireSubscriptionInfo(subsidiary, forScreenRetire);
        }
    }

    private retireNonScreenContextSubscriptions(hs: HydratedScreen): void {
        for (const si of hs.componentStates) {
            this.retireSubscriptionInfo(si, true);
            for (const [, scss] of Object.values(si.subComponentStates)) {
                for (const scs of scss) {
                    this.retireSubscriptionInfo(scs, true);
                }
            }
        }

        if (hs.titleSubscriptionInfo !== undefined) {
            this.retireSubscriptionInfo(hs.titleSubscriptionInfo, true);
        }
    }

    private retireHydratedScreen(hs: HydratedScreen): void {
        if (hs.screenContextSubscriptionInfo !== undefined) {
            this.retireSubscriptionInfo(hs.screenContextSubscriptionInfo, true);
        }

        this.retireNonScreenContextSubscriptions(hs);
    }

    private retireScreen(screen: InternalScreen): void {
        const { hydratedScreen } = screen;
        if (hydratedScreen !== undefined) {
            this.retireHydratedScreen(hydratedScreen);
        }

        if (isLoadingValue(hydratedScreen?.screenContext)) return;

        const outputRow = hydratedScreen?.screenContext?.outputRow;
        if (outputRow !== undefined) {
            assert(!outputRow.$isVisible);

            const { computationModel } = this;
            if (computationModel === undefined) return;

            // We don't delete the invisible row because the user might come
            // back to the URL and continue editing.
        }
    }

    public retire(): void {
        logInfo("retiring", this.id);

        assert(!isRecomputingState(this.state));
        assert(this.state !== State.Retired);
        this.state = State.Retired;

        this.networkStatusWatchable.unsubscribe(this.networkStatusChanged);

        this.dataStore.getComputationModelObservable(false, true).unsubscribe(this.onComputationModelChange);

        this.queryableDataStore?.removeQueryableRowsRootFinder(this);
        this._areQueriesLoadingObservable?.retire();

        this.appEnvironment.unsubscribeFromActionState(this.actionsStateChanged);

        if (this.internalNavigationModel !== undefined) {
            this.forEachInternalScreen(this.internalNavigationModel, s => this.retireScreen(s));
        }

        isChronoLoadedObservable.unsubscribe(this.requestFullRehydration);
    }

    private get savingOrLoadingMessage(): string | undefined {
        if (this.actionsState !== undefined) {
            if (this.actionsState.savingMessageStarted > this.actionsState.savingMessagePerformed) {
                return getLocalizedString("saving", this.appKind);
            }
        }

        const queriesInProgress = this._areQueriesLoadingObservable?.current === true;
        if (queriesInProgress) {
            return getLocalizedString("loading", this.appKind);
        }
        return undefined;
    }

    private loadStateValues(key: string | undefined): StateValuesMap | undefined {
        if (key === undefined) return undefined;
        const values = this.backendCallbacks.loadStateValues(key);

        return new Map(Object.entries(values).map(([k, v]) => [k, [convertValueFromSerializable(v), "DUMMY", true]]));
    }

    private saveStateValues(key: string | undefined, values: StateValuesMap): void {
        if (key === undefined) return;

        this.backendCallbacks.saveStateValues(
            key,
            fromPairs(
                mapFilterUndefined(Array.from(values.entries()), ([k, [v, , shouldSave]]) => {
                    if (!shouldSave) return undefined;
                    if (v instanceof GlideDateTime || v instanceof GlideJSON) {
                        v = v.toDocumentData();
                    }
                    return [k, v];
                })
            )
        );
    }

    private makeBuilderCallbacks(): [BuilderCallbacks | undefined, Record<string, BuilderTableData> | undefined] {
        if (!this.isBuilder) return [undefined, undefined];

        const builderTableData: Record<string, BuilderTableData> = {};
        const builderCallbacks: BuilderCallbacks = {
            overrideTableData(componentID, table, tableType) {
                defined(builderTableData)[componentID ?? arrayScreenComponentID] = { tableType, table };
            },
        };

        return [builderCallbacks, builderTableData];
    }

    protected hydrateScreenInternal<
        TInflated extends {
            readonly tables: InputOutputTables;
            readonly specialComponentHydrators: readonly SpecialComponentHydrator[];
            readonly titleGetter: WireValueGetter;
            readonly screenContext: HydratedRowContext | LoadingValue;
            readonly screenContextSubscriptionInfo: SubscriptionInfo | undefined;
        }
    >(
        hydrationContext: ScreenHydrationContext,
        inflateScreenAndGetContext: (
            screenName: string,
            previousHydratedScreen: HydratedScreen | undefined
        ) => TInflated | undefined,
        hydrateScreen: (
            inflated: TInflated & { readonly screenContext: HydratedRowContext },
            hydratedScreen: HydratedScreen | undefined,
            screenHydrator: ScreenHydrator
        ) => {
            readonly components: readonly (WireComponent | null)[];
            readonly componentStates: readonly ComponentState[];
            readonly specialComponents: readonly (WireComponent | null)[];
            readonly specialComponentStates: readonly ComponentState[];
            readonly firstListItemActionToRun?: WireAction;
            // `undefined` means keep the old one from the hydrated
            // screen.
            readonly builderTableData: ScreenBuilderTableData | undefined;
            readonly hasSearchableComponent: boolean;
        }
    ): ScreenHydrationResult | undefined {
        const { internalScreen } = hydrationContext;
        const { hydratedScreen, screenName, defaultTitle, titleOverride } = internalScreen;

        const inflatedScreen = inflateScreenAndGetContext(screenName, hydratedScreen);
        if (inflatedScreen === undefined) return undefined;

        // We don't seem to check anywhere whether the
        // `screenContextSubscriptionInfo` is dirty for class screens, but
        // they do work correctly if they have a filter set.  🤷‍♂️
        const {
            tables: screenTables,
            titleGetter,
            screenContext: maybeScreenContext,
            screenContextSubscriptionInfo,
        } = inflatedScreen;

        if (isLoadingValue(maybeScreenContext)) {
            if (hydratedScreen !== undefined) {
                this.retireNonScreenContextSubscriptions(hydratedScreen);
            }

            return {
                hydratedScreen: {
                    actions: new ActionTokenMap(),
                    onChangeData: new Map(),
                    wireScreen: {
                        ...emptyScreen,
                        components: [activitySpinnerComponent],
                    },
                    screenContext: maybeScreenContext,
                    screenContextSubscriptionInfo,
                    builderTableData: hydratedScreen?.builderTableData,
                    componentStates: [],
                    specialComponentStates: [],
                    titleSubscriptionInfo: emptySubscriptionInfo,
                    hasSearchableComponent: false,
                },
                context: maybeScreenContext,
                followUp: () => undefined,
            };
        }

        const screenContext = maybeScreenContext;

        let currentScreenTitle = titleOverride;
        const followUps: WireHydrationFollowUp[] = [];

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const backend = this;

        class Hydrator implements ScreenHydrator {
            public get screenName(): string {
                return screenName;
            }

            public addFollowUp(followUp: WireHydrationFollowUp): void {
                followUps.push(followUp);
            }

            public makeHydrationBackend(
                subComponentsName: string | undefined,
                cs: ComponentState | undefined,
                componentID: string | undefined,
                helper: HydrationHelper,
                hydrationTables: InputOutputTables | undefined,
                hydrationRowContext: HydratedRowContext
            ): RowComponentHydrationBackend {
                const stateSaveKey = makeStateSaveKey(subComponentsName, componentID, screenContext);
                const stateValues =
                    cs !== undefined && cs?.stateSaveKey === stateSaveKey
                        ? cs.stateValues
                        : backend.loadStateValues(stateSaveKey);
                return new RowComponentHydrationBackend(
                    hydrationContext,
                    hydrationRowContext,
                    stateValues,
                    stateSaveKey,
                    componentID,
                    true,
                    hydrationTables,
                    currentScreenTitle,
                    helper,
                    () => backend.formFactor,
                    []
                );
            }

            public makeHelper(
                cs: ComponentState | undefined,
                componentID: string | undefined,
                wc: WireComponent | null
            ): HydrationHelper {
                return {
                    hydrateSubComponents: (name, hydrators, tables, context, getSubComponents) => {
                        let previousHydrated:
                            | [
                                  HydratedScreenContext | undefined,
                                  readonly ComponentState[],
                                  readonly (WireComponent | null)[]
                              ]
                            | undefined;
                        if (wc !== null && cs !== undefined) {
                            let previousStates = cs.subComponentStates[name];
                            if (previousStates === undefined) {
                                // This is a hack to fix our bad state
                                // management.  We have this concept of
                                // "subsidiaries", but they are not keyed, and
                                // when rehydrating we don't associate the
                                // state of previous subsidiaries with current
                                // subsidiaries.  This works around that
                                // somewhat because at least subcomponents
                                // have names, so if a subcomponent is
                                // hydrated within a subsidiary, we can find
                                // its previous state by looking through the
                                // previous subsidiaries.  What we'd ideally
                                // do is to have the subsidiaries also have
                                // names, and then associate the states
                                // properly.  Or rewrite the whole damn state
                                // management to be more sane.
                                // https://github.com/quicktype/glide/issues/16208
                                for (const subsidiary of cs.subsidiaries) {
                                    if (!isComponentState(subsidiary)) continue;

                                    previousStates = subsidiary.subComponentStates[name];
                                    if (previousStates !== undefined) {
                                        break;
                                    }
                                }
                            }
                            if (previousStates !== undefined) {
                                const previousComponents = getSubComponents(wc);
                                assert(previousStates[1].length === previousComponents.length);
                                previousHydrated = [...previousStates, previousComponents];
                            }
                        } else if (wc !== null) {
                            // Another case of ##activitySpinnerWithoutComponentState.
                            assert(wc.kind === WireComponentKind.ActivitySpinner);
                        }
                        return this.hydrateRowComponents(
                            name,
                            hydrators,
                            [],
                            (hb, h, bc) => invokeRowComponentHydrator(new h(hb, bc)),
                            previousHydrated,
                            tables,
                            context,
                            false
                        );
                    },
                    subscribeSubComponents: (results, superComponentName) =>
                        this.subscribeComponents(results, [], `${superComponentName}-`),
                    getEditedRow: tn => {
                        assert(componentID !== undefined);
                        const rowIDKey = `${componentID}-${tn.name}-${screenContext.inputRows[0]?.$rowID}`;
                        const maybeRowID = backend.invisibleRowIDs.get(rowIDKey);
                        const row = backend.getOrCopyRowWithNewRowID(
                            tn,
                            () => [{ $rowID: "DUMMY", $isVisible: false }, undefined],
                            maybeRowID
                        );
                        if (row !== undefined) {
                            backend.invisibleRowIDs.set(rowIDKey, row.$rowID);
                        }
                        return row;
                    },
                };
            }

            public hydrateRowComponents<THydrator>(
                subComponentsName: string | undefined,
                hydrators: readonly [hydrator: THydrator, componentID: string | undefined][],
                additionalInputs: readonly unknown[],
                hydrate: (
                    hb: WireRowComponentHydrationBackend,
                    h: THydrator,
                    bc: BuilderCallbacks | undefined
                ) => WireComponentHydrationResult | undefined,
                previousHydrated:
                    | [
                          context: HydratedScreenContext | LoadingValue | undefined,
                          states: readonly ComponentState[],
                          components: readonly (WireComponent | null)[]
                      ]
                    | undefined,
                hydrationTables: InputOutputTables | undefined,
                hydrationRowContext: HydratedRowContext,
                forceRehydrate: boolean
            ): readonly HydrationResult[] {
                const results: HydrationResult[] = [];

                function push(
                    componentWithFlags: WireComponentHydrationResult | undefined,
                    hb: RowComponentHydrationBackend | undefined,
                    oldComponentState: ComponentState | undefined,
                    builderTableData: Record<string, BuilderTableData> | undefined
                ) {
                    const component = componentWithFlags?.component ?? null;
                    results.push({
                        componentWithFlags,
                        subsidiaryScreen: componentWithFlags?.subsidiaryScreen,
                        component,
                        hb,
                        oldComponentState,
                        builderTableData,
                    });
                }

                if (previousHydrated !== undefined) {
                    const [previousContext, componentStates] = previousHydrated;
                    let [, , wireComponents] = previousHydrated;

                    // ##activitySpinnerWithoutComponentState:
                    // This is a little precarious.  We depend here on the
                    // fact that if the `previousContext` was `LoadingValue`,
                    // we will have hydrated a single component, namely an
                    // activity spinner, for which we hydrated no component
                    // state, or no components, in the case of special
                    // components.  Otherwise, we must have hydrated the same
                    // number of components as we hydrated now.
                    let contextChanged: boolean;
                    if (isLoadingValue(previousContext)) {
                        assert(componentStates.length === 0);
                        assert(
                            wireComponents.length === 0 ||
                                (wireComponents.length === 1 &&
                                    wireComponents[0]?.kind === WireComponentKind.ActivitySpinner)
                        );
                        wireComponents = [];
                        contextChanged = true;
                    } else {
                        assert(componentStates.length === hydrators.length);
                        assert(wireComponents.length === hydrators.length);
                        contextChanged = !areScreenContextsEqual(previousContext, hydrationRowContext);
                    }

                    for (const [hydratorWithID, cs, wc] of zip(hydrators, componentStates, wireComponents)) {
                        const [hydrator, componentID] = defined(hydratorWithID);
                        if (
                            contextChanged ||
                            forceRehydrate ||
                            (cs !== undefined &&
                                isComponentStateDirty(cs, additionalInputs, hydrationContext.needsChanged))
                        ) {
                            const [builderCallbacks, builderTableData] = backend.makeBuilderCallbacks();
                            const hb = this.makeHydrationBackend(
                                subComponentsName,
                                cs,
                                componentID,
                                this.makeHelper(cs, componentID, wc ?? null),
                                hydrationTables,
                                hydrationRowContext
                            );
                            const component = hydrate(hb, hydrator, builderCallbacks);
                            push(component, hb, cs, builderTableData);
                        } else {
                            assert(cs !== undefined);
                            if (wc === null) {
                                push(undefined, undefined, cs, cs.builderTableData);
                            } else {
                                // We need to put in the validation flags for
                                // components we haven't re-hydrated so that
                                // validation still works.
                                push(
                                    {
                                        component: wc,
                                        isValid: cs.isValid,
                                        editsInContext: cs.editsInContext,
                                        hasValue: cs.hasValue,
                                        followUp: undefined,
                                    },
                                    undefined,
                                    cs,
                                    cs.builderTableData
                                );
                            }
                        }
                    }
                } else {
                    for (const [hydrator, componentID] of hydrators) {
                        const [builderCallbacks, builderTableData] = backend.makeBuilderCallbacks();
                        const hb = this.makeHydrationBackend(
                            subComponentsName,
                            undefined,
                            componentID,
                            this.makeHelper(undefined, componentID, null),
                            hydrationTables,
                            hydrationRowContext
                        );
                        const component = hydrate(hb, hydrator, builderCallbacks);
                        push(component, hb, undefined, builderTableData);
                    }
                }

                assert(results.length === hydrators.length);

                return results;
            }

            public hydrateArrayScreenComponent(
                hydratorConstructor: WireTableComponentHydratorConstructor,
                oldComponentState: ComponentState | undefined,
                oldScreenContextSubscriptionInfo: SubscriptionInfo | undefined,
                table: Table,
                applyDynamicTransforms: DynamicTransformsApplicator,
                sortTransform: WireTableTransformer,
                limitTransform: WireTableTransformer
            ): {
                readonly hydrationResult: HydrationResult;
                readonly firstListItemActionToRun: WireAction | undefined;
            } {
                const oldComponent = hydratedScreen?.wireScreen.components[0] ?? null;

                let contentHydrationBackend: TableComponentHydrationBackend | undefined;
                let component: WireComponent | undefined;
                let componentWithFlags: WireComponentHydrationResult | undefined;
                let firstListItemActionToRun: WireAction | undefined;
                let builderTableData: Record<string, BuilderTableData> | undefined;

                if (
                    oldComponentState === undefined ||
                    isComponentStateDirty(oldComponentState, [], hydrationContext.needsChanged) ||
                    oldScreenContextSubscriptionInfo === undefined ||
                    isSubscriptionDirty(oldScreenContextSubscriptionInfo, hydrationContext.needsChanged)
                ) {
                    contentHydrationBackend = new TableComponentHydrationBackend(
                        hydrationContext,
                        table,
                        undefined,
                        screenTables,
                        true,
                        undefined,
                        new StateManager(oldComponentState?.stateValues),
                        makeStateSaveKey(undefined, `array-${screenName}`, undefined),
                        "",
                        this.makeHelper(oldComponentState, "array", oldComponent),
                        undefined,
                        true,
                        () => backend.formFactor,
                        []
                    );

                    const rowBackends = new DefaultMap((r: Row) =>
                        defined(contentHydrationBackend).makeHydrationBackendForRow(r)
                    );

                    const afterDynamicTransforms = applyDynamicTransforms(
                        contentHydrationBackend,
                        table,
                        sortTransform,
                        limitTransform
                    );
                    contentHydrationBackend = afterDynamicTransforms[0];
                    const [, searchActive, dynamicFilterResult] = afterDynamicTransforms;

                    const [builderCallbacks, componentTableData] = backend.makeBuilderCallbacks();

                    const hydrator = hydratorConstructor.makeHydrator(
                        undefined,
                        rowBackends,
                        searchActive,
                        builderCallbacks
                    );
                    // Only Choice cares about `limit` for now, so we don't
                    // get and pass it here.
                    const maybeResult = hydrator.hydrate(contentHydrationBackend, dynamicFilterResult, undefined);
                    componentWithFlags = maybeResult;
                    component = componentWithFlags?.component;
                    if (componentWithFlags?.followUp !== undefined) {
                        screenHydrator.addFollowUp(componentWithFlags.followUp);
                    }
                    firstListItemActionToRun = maybeResult?.firstListItemActionToRun;
                    builderTableData = componentTableData;
                } else {
                    assert(hydratedScreen !== undefined);
                    component = nullToUndefined(oldComponent);
                    builderTableData = oldComponentState.builderTableData;
                }

                return {
                    hydrationResult: {
                        component: component ?? null,
                        subsidiaryScreen: componentWithFlags?.subsidiaryScreen,
                        componentWithFlags,
                        hb: contentHydrationBackend,
                        oldComponentState,
                        builderTableData,
                    },
                    firstListItemActionToRun,
                };
            }

            // We separate out the subscribing so that we can do it for
            // subcomponents in one go after hydrating.
            public subscribeComponents(
                results: readonly HydrationResult[],
                additionalInputs: readonly unknown[],
                componentNamePrefix: string
            ): readonly ComponentState[] {
                const newComponentStates: ComponentState[] = [];

                for (const [
                    i,
                    { subsidiaryScreen, componentWithFlags: component, hb, oldComponentState, builderTableData },
                ] of results.entries()) {
                    const subscriptionName = `component ${componentNamePrefix}${i}-${component?.component?.kind}`;
                    if (oldComponentState !== undefined) {
                        if (hb !== undefined) {
                            newComponentStates.push(
                                hb.subscribeComponent(
                                    component,
                                    subsidiaryScreen,
                                    component?.editor,
                                    additionalInputs,
                                    builderTableData,
                                    subscriptionName,
                                    oldComponentState,
                                    backend.onDataChange
                                )
                            );

                            backend.retireSubscriptionInfo(oldComponentState, false);
                        } else {
                            // not changed
                            newComponentStates.push(
                                keepSubscriptionInfo(hydrationContext, defined(hydratedScreen), oldComponentState)
                            );
                        }
                    } else {
                        // no old state
                        assert(hb !== undefined);
                        newComponentStates.push(
                            hb.subscribeComponent(
                                component,
                                subsidiaryScreen,
                                component?.editor,
                                additionalInputs,
                                builderTableData,
                                subscriptionName,
                                undefined,
                                backend.onDataChange
                            )
                        );
                    }

                    if (component?.followUp !== undefined) {
                        this.addFollowUp(component.followUp);
                    }
                }

                assert(newComponentStates.length === results.length);
                return newComponentStates;
            }
        }

        const screenHydrator = new Hydrator();

        const screenContextChanged =
            isLoadingValue(hydratedScreen?.screenContext) ||
            !areScreenContextsEqual(hydratedScreen?.screenContext, screenContext);

        let titleSubscriptionInfo: SubscriptionInfo;
        // TODO: Most of this is not necessary if we already have a title from
        // the override.  We do need to potentially retire the old
        // subscription info, but that should be it.
        if (
            hydratedScreen === undefined ||
            screenContextChanged ||
            isSubscriptionDirty(hydratedScreen.titleSubscriptionInfo, hydrationContext.needsChanged)
        ) {
            const titleHB = screenHydrator.makeHydrationBackend(
                undefined,
                undefined,
                undefined,
                screenHydrator.makeHelper(undefined, undefined, null),
                screenTables,
                screenContext
            );
            if (currentScreenTitle === undefined) {
                currentScreenTitle = asMaybeString(nullLoadingToUndefined(titleGetter(titleHB))) ?? defaultTitle ?? "";
            }
            titleSubscriptionInfo = titleHB.subscribeComponent(
                undefined,
                undefined,
                undefined,
                [],
                undefined,
                `screen-title ${screenName}`,
                hydratedScreen?.titleSubscriptionInfo,
                this.onDataChange
            );
            if (hydratedScreen !== undefined) {
                this.retireSubscriptionInfo(hydratedScreen.titleSubscriptionInfo, false);
            }
        } else {
            currentScreenTitle = hydratedScreen.wireScreen.title;
            titleSubscriptionInfo = keepSubscriptionInfo(
                hydrationContext,
                hydratedScreen,
                hydratedScreen.titleSubscriptionInfo
            );
        }

        let closeAction: WireAction | undefined;
        if (this.appKind === AppKind.Page && hydrationContext.position !== WireScreenPosition.Main) {
            assert(hydrationContext.position === WireScreenPosition.Modal);
            closeAction = this.closeModalAction;
        }

        let backAction: WireAction | undefined;
        if (internalScreen.needsBackAction) {
            backAction = this.navigateUpAction;
        }

        let dragBackAction: WireAction | undefined;
        if (internalScreen.needsBackAction) {
            dragBackAction = this.dragBackAction;
        }

        assert(screenContext === inflatedScreen.screenContext);
        const {
            components,
            componentStates,
            specialComponents,
            specialComponentStates,
            firstListItemActionToRun,
            builderTableData,
            hasSearchableComponent,
        } = hydrateScreen({ ...inflatedScreen, screenContext }, hydratedScreen, screenHydrator);

        const isClassScreen = this.appDescription.screenDescriptions[screenName]?.kind === ScreenDescriptionKind.Class;

        return {
            hydratedScreen: {
                actions: hydrationContext.actions,
                onChangeData: hydrationContext.onChangeValueRows,
                wireScreen: {
                    key: encodeScreenKey(hydrationContext.screenKey),
                    // If `screenName` is set then we'll put a highlight
                    // wrapper around the component, which we only want in the
                    // builder for class screens.
                    screenName: this.isBuilder && isClassScreen ? screenName : undefined,
                    title: currentScreenTitle,
                    components,
                    specialComponents,
                    backAction,
                    dragBackAction,
                    closeAction,
                    flags: [],
                    isInModal: internalScreen.isInModal,
                    tabIcon: internalScreen.tabIcon,
                },
                screenContext,
                screenContextSubscriptionInfo,
                builderTableData: builderTableData ?? hydratedScreen?.builderTableData,
                componentStates,
                specialComponentStates,
                titleSubscriptionInfo,
                firstListItemActionToRun,
                hasSearchableComponent,
            },
            context: screenContext,
            followUp: ab => {
                for (const f of followUps) {
                    f(ab);
                }
            },
        };
    }

    // It's ok for `screen` to be `undefined`.  One case where we need that is
    // for the unconfigured user-profile screen, which we do inflate.
    private hydrateClassScreen(
        screen: ClassScreenDescription | undefined,
        hydrationContext: ScreenHydrationContext
    ): ScreenHydrationResult | undefined {
        return this.hydrateScreenInternal(
            hydrationContext,
            (screenName, hydratedScreen) => {
                const inflatedScreen = this.screenInflator?.inflateClassScreen(screenName, screen);
                if (inflatedScreen === undefined) return undefined;

                const {
                    tables: screenTables,
                    componentHydrators,
                    specialComponentHydrators,
                    titleGetter,
                    getScreenContext,
                } = inflatedScreen;

                const maybeScreenContext = getScreenContext(
                    hydrationContext,
                    hydratedScreen?.screenContextSubscriptionInfo
                );
                if (maybeScreenContext === undefined) return undefined;
                const [screenContext, screenContextSubscriptionInfo] = maybeScreenContext;

                const rowScreenContext = isLoadingValue(screenContext)
                    ? screenContext
                    : makeHydratedScreenContext(screenContext.inputRows, screenContext.outputRow, undefined);

                const writableColumns = screenTables.output.columns.filter(c =>
                    isColumnWritable(c, screenTables.output, false, { allowProtected: false, allowArrays: true })
                );

                return {
                    tables: screenTables,
                    screenContext: rowScreenContext,
                    screenContextSubscriptionInfo,
                    specialComponentHydrators,
                    titleGetter,
                    componentHydrators,
                    mutatingScreenKind: getMutatingKindForScreen(screenName, screen),
                    writableColumns,
                };
            },
            (inflated, hydratedScreen, screenHydrator) => {
                const {
                    tables: screenTables,
                    screenContext: rowScreenContext,
                    specialComponentHydrators,
                    mutatingScreenKind,
                    writableColumns,
                } = inflated;
                const componentResults = screenHydrator.hydrateRowComponents(
                    undefined,
                    inflated.componentHydrators,
                    [],
                    (hb, h, bc) => invokeRowComponentHydrator(new h(hb, bc)),
                    definedMap(hydratedScreen, hs => [hs.screenContext, hs.componentStates, hs.wireScreen.components]),
                    screenTables,
                    rowScreenContext,
                    false
                );
                const components = componentResults.map(r => r.component);
                const componentStates = screenHydrator.subscribeComponents(componentResults, [], "");

                // We don't care about components that don't edit in the
                // screen's context.
                const allComponentsValid = componentStates.every(s => s.editsInContext !== true || s.isValid);
                let haveValue: boolean;
                if (mutatingScreenKind === MutatingScreenKind.EditScreen) {
                    // In Edit screens, the condition is that some editable
                    // column needs a value, whether it has an edit component
                    // or not.
                    // https://github.com/quicktype/glide/issues/15584
                    if (writableColumns.length === 0) {
                        haveValue = true;
                    } else {
                        haveValue = writableColumns.some(c => {
                            const v = rowScreenContext.outputRow?.[c.name];
                            // Technically these should never be loading or be
                            // thunks.
                            if (isLoadingValue(v) || isThunk(v)) return false;
                            return isNotEmpty(v);
                        });
                    }
                } else {
                    haveValue = componentStates.some(s => s.editsInContext === true && s.hasValue === true);
                }
                // Edit screens are valid if
                // * every component is valid and
                // * we have at least one component with a value or
                //   * we're an app and there are no edit components at all
                // https://github.com/quicktype/glide/issues/15446
                const isEditValid =
                    allComponentsValid &&
                    (haveValue ||
                        (this.appKind === AppKind.App &&
                            componentStates.every(s => s.editsInContext !== true || s.hasValue === undefined)));
                const hasSearchableComponent = componentStates.some(s => s.canBeSearched);
                const specialComponentAdditionalInputs = [isEditValid];

                const { specialComponents, specialComponentStates } = hydrateAndSubscribeSpecialComponents(
                    screenHydrator,
                    specialComponentHydrators,
                    isEditValid,
                    hasSearchableComponent,
                    specialComponentAdditionalInputs,
                    hydratedScreen,
                    screenTables,
                    rowScreenContext
                );

                const componentTableData: Record<string, BuilderTableData> = {};
                for (const cs of componentStates) {
                    Object.assign(componentTableData, cs.builderTableData);
                    for (const [, scss] of Object.values(cs.subComponentStates)) {
                        for (const scs of scss) {
                            Object.assign(componentTableData, scs.builderTableData);
                        }
                    }
                }
                const builderTableData: ScreenBuilderTableData = {
                    screen: {
                        tableType: screenTables.input,
                        table: new Table(rowScreenContext.inputRows),
                    },
                    components: componentTableData,
                };

                return {
                    components,
                    componentStates,
                    specialComponents,
                    specialComponentStates,
                    builderTableData,
                    hasSearchableComponent,
                };
            }
        );
    }

    // Only used in apps
    private hydrateArrayScreen(
        screen: ArrayScreenDescription,
        hydrationContext: ScreenHydrationContext
    ): ScreenHydrationResult | undefined {
        return this.hydrateScreenInternal(
            hydrationContext,
            (screenName, hydratedScreen) => {
                const inflatedScreen = this.screenInflator?.inflateArrayScreen(screenName, screen);
                if (inflatedScreen === undefined) return undefined;

                const {
                    table,
                    contentHydratorConstructor,
                    specialComponentHydrators,
                    getContextTable,
                    applyDynamicTransforms,
                } = inflatedScreen;

                const oldScreenContextSubscriptionInfo = hydratedScreen?.screenContextSubscriptionInfo;
                const maybeScreenContext = getContextTable(hydrationContext, oldScreenContextSubscriptionInfo);
                if (maybeScreenContext === undefined) return undefined;
                const [rows, screenContextSubscriptionInfo, sortTransform, limitTransform, screenContext] =
                    maybeScreenContext;

                return {
                    tables: makeInputOutputTables(table),
                    screenContext,
                    screenContextSubscriptionInfo,
                    specialComponentHydrators,
                    applyDynamicTransforms,
                    // TODO: support title, once we support array screens
                    titleGetter: () => hydrationContext.internalScreen.defaultTitle ?? "",
                    oldScreenContextSubscriptionInfo,
                    contentHydratorConstructor,
                    rows,
                    sortTransform,
                    limitTransform,
                };
            },
            (inflated, hydratedScreen, screenHydrator) => {
                const { screenName } = screenHydrator;
                const {
                    contentHydratorConstructor,
                    rows,
                    oldScreenContextSubscriptionInfo,
                    tables,
                    specialComponentHydrators,
                    applyDynamicTransforms,
                    sortTransform,
                    limitTransform,
                } = inflated;

                const oldContentState = hydratedScreen?.componentStates[0];
                const { hydrationResult, firstListItemActionToRun } = screenHydrator.hydrateArrayScreenComponent(
                    contentHydratorConstructor,
                    oldContentState,
                    oldScreenContextSubscriptionInfo,
                    rows,
                    applyDynamicTransforms,
                    sortTransform,
                    limitTransform
                );
                const componentStates = screenHydrator.subscribeComponents(
                    [hydrationResult],
                    [],
                    `array-screen-content ${screenName}`
                );

                const { specialComponents, specialComponentStates } = hydrateAndSubscribeSpecialComponents(
                    screenHydrator,
                    specialComponentHydrators,
                    false,
                    true,
                    [],
                    hydratedScreen,
                    tables,
                    {
                        inputRows: [],
                        outputRow: undefined,
                        containingScreenRow: undefined,
                    }
                );

                let builderTableData: BuilderTableData | undefined;
                if (this.isBuilder) {
                    builderTableData = hydrationResult.builderTableData?.[arrayScreenComponentID];
                    if (builderTableData === undefined) {
                        // This is the default, but the content hydrator can
                        // override it.  FIXME: This is before sorting, so the
                        // resulting table will be filtered, but not sorted.  This
                        // is only Apps, so we don't care at the moment.
                        builderTableData = {
                            tableType: tables.input,
                            table: rows,
                        };
                    }
                }

                return {
                    components: [hydrationResult.component ?? null],
                    componentStates,
                    specialComponents,
                    specialComponentStates,
                    firstListItemActionToRun,
                    builderTableData: {
                        screen: builderTableData,
                        components: {},
                    },
                    hasSearchableComponent: false,
                };
            }
        );
    }

    protected hydrateSpecialScreen(
        _screen: ScreenDescription | undefined,
        _hydrationContext: ScreenHydrationContext,
        _titleOverride: string | undefined,
        _context: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        return panic("Must be implemented in the subclass");
    }

    protected makeHydratedSpecialScreen(
        screenName: string,
        title: string,
        components: readonly WireComponent[],
        specialComponents: readonly WireComponent[],
        flags: readonly WireScreenFlag[],
        isInModal: boolean,
        tabIcon: string,
        screenContext: HydratedScreenContext | undefined
    ): ScreenHydrationResult {
        return {
            hydratedScreen: {
                wireScreen: {
                    key: encodeScreenKey(screenName),
                    title,
                    components,
                    specialComponents,
                    closeAction: this.appKind === AppKind.Page ? this.closeModalAction : this.navigateUpAction,
                    flags,
                    isInModal,
                    tabIcon,
                },
                screenContext,
                screenContextSubscriptionInfo: undefined,
                componentStates: components.map(() => emptyComponentState),
                specialComponentStates: [],
                titleSubscriptionInfo: emptySubscriptionInfo,
                actions: new ActionTokenMap(),
                onChangeData: new Map(),
                builderTableData: undefined,
                hasSearchableComponent: false,
            },
            context: emptyHydratedScreenContext,
            followUp: () => undefined,
        };
    }

    protected hydrateSubscribingSpecialScreen<T extends RowComponentHydrationBackend | TableComponentHydrationBackend>(
        hydrationContext: ScreenHydrationContext,
        screenContext: HydratedScreenContext,
        makeHydrationBackend: (oldComponentState: ComponentState | undefined) => T,
        hydrate: (hb: T) => [screen: WireScreen, subsidiary: WireScreen | undefined] | undefined
    ): ScreenHydrationResult | undefined {
        const oldComponentStates = hydrationContext.internalScreen.hydratedScreen?.componentStates ?? [];
        const oldComponentState = oldComponentStates[0];
        const hb = makeHydrationBackend(oldComponentState);

        // We hydrate all components with the same hydration backend, so we
        // only have to subscribe to one of the components, at least when it
        // comes to the computation model.  There might be more subtle
        // problems with this, but we only do this for the special screens.
        const [maybeScreen, subsidiary] = hydrate(hb) ?? [];

        const componentState = hb.subscribeComponent(
            definedMap(maybeScreen?.components[0] ?? undefined, c => ({ component: c, isValid: true, hasValue: true })),
            subsidiary,
            undefined,
            [],
            undefined,
            "singleComponent",
            oldComponentState,
            this.onDataChange
        );
        for (const cs of oldComponentStates) {
            this.retireSubscriptionInfo(cs, false);
        }

        if (maybeScreen === undefined) {
            this.retireSubscriptionInfo(componentState, true);
            return undefined;
        }

        return {
            hydratedScreen: {
                wireScreen: maybeScreen,
                screenContext,
                screenContextSubscriptionInfo: undefined,
                componentStates: [componentState],
                specialComponentStates: [],
                titleSubscriptionInfo: emptySubscriptionInfo,
                actions: hydrationContext.actions,
                onChangeData: hydrationContext.onChangeValueRows,
                builderTableData: undefined,
                hasSearchableComponent: false,
            },
            context: emptyHydratedScreenContext,
            followUp: () => undefined,
        };
    }

    protected hydrateSubscribingRowSpecialScreen(
        hydrationContext: ScreenHydrationContext,
        title: string,
        screenContext: HydratedScreenContext,
        hydrate: (thb: WireRowComponentHydrationBackend) => [WireScreen, WireScreen | undefined] | undefined
    ): ScreenHydrationResult | undefined {
        const {
            internalScreen: { screenName },
        } = hydrationContext;
        return this.hydrateSubscribingSpecialScreen<RowComponentHydrationBackend>(
            hydrationContext,
            screenContext,
            oldComponentState =>
                new RowComponentHydrationBackend(
                    hydrationContext,
                    undefined,
                    oldComponentState?.stateValues,
                    makeStateSaveKey(undefined, `class-${screenName}`, undefined),
                    screenName,
                    true,
                    undefined,
                    title,
                    undefined,
                    () => this.formFactor,
                    []
                ),
            hydrate
        );
    }

    protected hydrateSignInOutSpecialScreen(screenName: SignInUpScreenName): ScreenHydrationResult {
        const component: WireSignInComponent = {
            kind: WireComponentKind.SignIn,
            onSuccess: this.navigateUpAction,
            onFailure: this.navigateUpAction,
            isSignUp: screenName === "sign-up",
            withUserName: false,
        };
        return this.makeHydratedSpecialScreen(
            screenName,
            "",
            [component],
            [],
            [WireScreenFlag.IsSignIn],
            true,
            "",
            undefined
        );
    }

    protected hydrateCodeScannerSpecialScreen(
        hydrationContext: ScreenHydrationContext,
        screenContext: HydratedScreenContext,
        specialComponents: (WireComponent | null)[]
    ): ScreenHydrationResult | undefined {
        const [row] = screenContext.inputRows;
        if (row === undefined) return undefined;

        const standards = definedMap(
            getRowColumn(row, "standards"),
            v => checkArray<string>(v, checkString) as CodeScannerStandards
        );
        const acceptOnFirst = checkBoolean(getRowColumn(row, "acceptOnFirst"));

        const title = getLocalizedString("scanCode", this.appKind);

        return this.hydrateSubscribingRowSpecialScreen(hydrationContext, title, screenContext, hb => {
            const valueToken = hb.registerOnSpecialScreenRowValueChange("value", row, "value", async ab => {
                ab.navigateUp();
            });

            const component: WireAppCodeScannerScreenComponent = {
                kind: WireComponentKind.AppCodeScannerScreen,
                standards,
                acceptOnFirst,
                value: {
                    value: "",
                    onChangeToken: valueToken,
                },
                onSuccess: this.navigateUpAction,
            };

            return [
                {
                    key: encodeScreenKey(hydrationContext.screenKey),
                    title,
                    components: [component],
                    specialComponents: specialComponents,
                    flags: [WireScreenFlag.IsCodeScanner],
                    isInModal: true,
                    tabIcon: hydrationContext.internalScreen.tabIcon,
                    closeAction: this.appKind === AppKind.Page ? this.navigateUpAction : undefined,
                },
                undefined,
            ];
        });
    }

    private hydrateScreen(hydrationContext: ScreenHydrationContext): ScreenHydrationResult | undefined {
        const { screenName, defaultTitle, titleOverride, context } = hydrationContext.internalScreen;
        const screen = this.appDescription.screenDescriptions[screenName];

        if (
            isSpecialScreenName(screenName) ||
            screen?.kind === ScreenDescriptionKind.ShoppingCart ||
            screen?.kind === ScreenDescriptionKind.Chat
        ) {
            const rows = mapFilterUndefined(context.inputRowIDs, rowID => this.specialScreenRows.get(rowID));
            return this.hydrateSpecialScreen(
                screen,
                hydrationContext,
                titleOverride ?? defaultTitle,
                makeHydratedScreenContext(rows, undefined, undefined)
            );
        }

        if (
            screen?.kind === ScreenDescriptionKind.Class ||
            (screen === undefined && screenName === userProfileScreenName)
        ) {
            return this.hydrateClassScreen(screen, hydrationContext);
        } else if (screen?.kind === ScreenDescriptionKind.Array) {
            return this.hydrateArrayScreen(screen, hydrationContext);
        } else if (screen === undefined) {
            return undefined;
        } else {
            return assertNever(screen);
        }
    }

    private recomputePathsSubscribedTo(context: ValueProviderActionContext): void {
        for (const path of context.pathsSubscribedTo) {
            this.computationModel?.ns.get(path);
        }
    }

    private makePartialValueProviderActionContext(): Omit<
        ValueProviderActionContext,
        "fetchTableData" | "registerObjectToRetire" | "parsedPath"
    > {
        return {
            namespace: this.computationModel?.ns,
            getPathForQuery: q => this.computationModel?.getPathForQuery(q),
            isOnline: this.isOnline,
            actions: new ActionTokenMap(),
            pathsSubscribedTo: [],
            retireSubscriptionInfo: si => this.retireSubscriptionInfo(si, false),
            // FIXME: remove once we're committed to `queriesInComputationModel`
            resolveQuery: q => this.queryableDataStore?.fetchQuery(q, this.requestFullRehydrationFromQuery, false),
            resolveQueryFromRows: (q, s) => definedMap(this.computationModel, cm => resolveQueryFromRows(q, cm, s)),
            addQueryChangedCallback: cb => {
                if (getFeatureSetting("queriesInComputationModel")) return;
                this._queryChangedCallbacks.add(cb);
            },
            removeQueryChangedCallback: cb => {
                if (getFeatureSetting("queriesInComputationModel")) return;
                this._queryChangedCallbacks.delete(cb);
            },
        };
    }

    private hydrateUserProfile(
        tablesToFetch: ArraySet<TableName>
    ): [WireUserProfile | undefined, SubscriptionInfo | undefined] {
        const inflated = this.screenInflator?.inflateUserProfile();
        if (inflated === undefined) return [undefined, undefined];

        const retirer = new ObjectRetirer();
        const context: ValueProviderActionContext = {
            ...this.makePartialValueProviderActionContext(),
            fetchTableData: tn => tablesToFetch.add(tn),
            registerObjectToRetire: retirer.registerObjectToRetire,
            parsedPath: this.parsedPath,
        };
        const vp = new RowHydrationValueProvider(context, undefined, undefined, undefined, "user-profile", true, []);

        let userProfile: WireUserProfile | undefined;
        const email = asMaybeString(nullLoadingToUndefined(inflated.emailValueGetter(vp)));
        if (email !== undefined) {
            const name = asMaybeString(nullLoadingToUndefined(inflated.nameValueGetter?.(vp)));
            const image = asMaybeString(nullLoadingToUndefined(inflated.imageValueGetter?.(vp)));
            const parsedPath = vp.getParsedPath();
            let userProfileScreenIsOpen: boolean;
            if (parsedPath === undefined) {
                userProfileScreenIsOpen = false;
            } else {
                userProfileScreenIsOpen =
                    parsedPath.isVisible() && parsedPath.getTabScreenName() === userProfileScreenName;
            }
            userProfile = {
                userProfileButton: {
                    signedInUserEmail: email,
                    signedInUserName: name,
                    signedInUserImage: image,
                    userProfileAction: this.showUserProfileAction,
                    userProfileScreenIsOpen,
                },
                signOutAction: this.signOutAction,
            };
        } else {
            userProfile = {
                signInAction: this.signInAction,
                signUpAction: this.signUpAction,
            };
        }

        const si = vp.subscribe("user-profile", this.onDataChange);
        this.recomputePathsSubscribedTo(context);

        retirer.retire();

        return [userProfile, si];
    }

    // ##recomputeSubscriptionHandlers:
    // This makes sure that all the dirt makes its way up to the subscription
    // handlers.
    private recomputeSubscriptionHandlers(): void {
        const process = (si: SubscriptionInfo | undefined) => {
            if (si?.handlerAndPath !== undefined) {
                this.computationModel?.ns.get(si.handlerAndPath.path);
            }
            for (const subsidiary of si?.subsidiaries ?? []) {
                process(subsidiary);
            }
            if (si !== undefined && isComponentState(si)) {
                for (const [, states] of Object.values(si.subComponentStates)) {
                    for (const scs of states) {
                        process(scs);
                    }
                }
            }
        };

        const processScreen = (screen: InternalScreen | undefined) => {
            if (screen?.hydratedScreen === undefined) return;

            process(screen.hydratedScreen.screenContextSubscriptionInfo);
            for (const cs of screen.hydratedScreen.componentStates) {
                process(cs);
            }
            return undefined;
        };

        if (this.internalNavigationModel !== undefined) {
            this.forEachInternalScreen(this.internalNavigationModel, processScreen);
        }
        for (const tab of this.internalNavigationModel?.tabs ?? []) {
            process(tab.visibilitySubscriptionInfo);
        }

        process(this.internalNavigationModel?.userProfileSubscriptionInfo);
    }

    private getParsedScreenDescription(
        parsedScreen: ParsedScreen
    ): (ClassOrArrayScreenDescription & { readonly type: TableRefGlideType }) | undefined {
        const screen = this.appDescription.screenDescriptions[parsedScreen.screenName];
        if (!isClassOrArrayScreenDescription(screen)) return undefined;
        if (screen.kind === ScreenDescriptionKind.Class) {
            return screen;
        } else {
            if (!isSingleRelationType(screen.type)) return undefined;
            return screen as ArrayScreenDescription & { readonly type: TableRefGlideType };
        }
    }

    // `undefined` means the screen fetches its own row
    private getRowIDsForParsedScreen(parsedScreen: ParsedScreen): readonly string[] | undefined {
        if (isSpecialScreenName(parsedScreen.screenName)) {
            return parsedScreen.rowIDs;
        }

        const screen = this.getParsedScreenDescription(parsedScreen);
        if (screen === undefined) return undefined;

        const { rowIDs } = parsedScreen;
        if (rowIDs.length === 0) {
            // Why don't we always return `undefined` for screens that fetch
            // their own data?  Does it make sense to have a screen that
            // fetches its own data, but still gets input rows from the parsed
            // path?
            if (screen.fetchesData === true) {
                return undefined;
            } else {
                return [];
            }
        } else {
            return rowIDs;
        }
    }

    protected getNeedsBackAction(_parsedScreen: WithModalFlag<ParsedScreen>): boolean {
        return false;
    }

    protected makeInternalScreen(
        parsedScreenWithFlag: WithModalFlag<ParsedScreen>,
        screenTab: TabDescription | undefined
    ): InternalScreen {
        const { screen: parsedScreen, isInModal } = parsedScreenWithFlag;
        const { screenName } = parsedScreen;
        const screen = this.getParsedScreenDescription(parsedScreen);

        const tab = this.findTab(screenName)?.[0];

        let context: InternalScreenContext | undefined;
        if (screen !== undefined && isAddClassScreenName(screenName)) {
            context = { inputRowIDs: [] };
        } else if (parsedScreen.rowIDs.length > 0) {
            const rowIDs = this.getRowIDsForParsedScreen(parsedScreen);
            context = { inputRowIDs: rowIDs ?? [] };
        } else {
            context = { inputRowIDs: [] };
        }

        return {
            screenName,
            defaultTitle: parsedScreen.screenTitleFallback ?? tab?.title,
            titleOverride: parsedScreen.screenTitleOverride,
            context,
            isInModal,
            needsBackAction: this.getNeedsBackAction(parsedScreenWithFlag),
            tabIcon: screenTab?.icon ?? "",
            hydratedScreen: undefined,
        };
    }

    protected updateInternalScreenGeneric<P extends ParsedScreen, I extends InternalScreen>(
        parsedScreen: WithModalFlag<P> | undefined,
        internalScreen: I | undefined,
        makeScreen: (p: WithModalFlag<P>) => I
    ): I | undefined {
        if (parsedScreen === undefined) {
            if (internalScreen !== undefined) {
                this.retireScreen(internalScreen);
            }
            return undefined;
        }

        const retireAndMakeDefault = () => {
            if (internalScreen !== undefined) {
                this.retireScreen(internalScreen);
            }
            return makeScreen(parsedScreen);
        };

        if (
            internalScreen === undefined ||
            parsedScreen.screen.screenName !== internalScreen.screenName ||
            // As we add more pieces of data to the screen, we'll either have
            // to compare them here, or live with the fact that pushing the
            // same screen again will sometimes not update everything.  For
            // example, if we didn't check for the screen title here, and we
            // pushed a detail screen for a row on top of the detail screen
            // for the same row, but with a different title, we wouldn't get
            // the new title.
            parsedScreen.screen.screenTitleOverride !== internalScreen.titleOverride ||
            parsedScreen.isInModal !== internalScreen.isInModal
        ) {
            return retireAndMakeDefault();
        }

        const rowIDs = this.getRowIDsForParsedScreen(parsedScreen.screen);
        if (rowIDs !== undefined && !shallowEqualArrays(rowIDs, internalScreen.context.inputRowIDs)) {
            return retireAndMakeDefault();
        }

        return internalScreen;
    }

    protected updateInternalScreen(
        parsedScreen: WithModalFlag<ParsedScreen> | undefined,
        // Only one of these two needs to be defined
        internalScreen: InternalScreen | undefined,
        screenTab: TabDescription | undefined
    ): InternalScreen | undefined {
        return this.updateInternalScreenGeneric(parsedScreen, internalScreen, p =>
            this.makeInternalScreen(p, screenTab)
        );
    }

    private getScreenTitle(screen: ParsedScreen, hb: WireRowComponentHydrationBackend): string | null | undefined {
        if (screen.screenTitleOverride !== undefined) {
            return screen.screenTitleOverride;
        }

        const { screenInflator } = this;
        if (screenInflator === undefined) return undefined;

        const [getter, tables] = screenInflator.inflateScreenTitle(screen.screenName);
        const rowID = this.getRowIDsForParsedScreen(screen)?.[0];
        let row: Row | undefined;
        if (rowID === undefined) {
            // The screen fetches its own data, so we have to ask it to do
            // that.
            const screenDesc = this.adc.appDescription.screenDescriptions[screen.screenName];
            if (screenDesc?.kind === ScreenDescriptionKind.Class) {
                const common = screenInflator.inflateScreenCommon(screen.screenName, screenDesc);
                if (common !== undefined) {
                    assert(hb instanceof RowHydrationValueProvider);
                    const screenContext = screenInflator.fetchScreenData(
                        screen.screenName,
                        screenDesc,
                        hb.context,
                        true
                    );
                    if (screenContext !== undefined) {
                        const [rowContext, si] = screenContext;
                        if (!isLoadingValue(rowContext)) {
                            row = rowContext.inputRows[0];
                        }
                        hb.addSubscriptionInfo(si);
                    }
                }
            }
        } else if (tables !== undefined) {
            // Just get the row from the table.
            const tableData = this.computationModel?.getBaseDataForTable(getTableName(tables?.input));
            if (!isLoadingValue(tableData)) {
                row = tableData?.get(rowID);
            }
        }

        // ##hydrationBackendWithUndefinedTables:
        // `tables` here can theoretically be `undefined`, so we kinda support
        // it.
        const rowHB = hb.makeHydrationBackendForRow(row, undefined, tables);

        const v = getter(rowHB);
        if (v === null || v === undefined) return v;
        if (isLoadingValue(v)) return undefined;
        return asString(v);
    }

    protected rebuildScreen(
        internalScreen: InternalScreen,
        position: WireScreenPosition,
        size: WireModalSize | undefined,
        tablesToFetch: ArraySet<TableName>,
        internalTabs: readonly InternalTab[] | undefined,
        screenKey: string,
        needsChanged: SubscriptionNeeds
    ): [InternalScreen, WireHydrationFollowUp] {
        let parsedPath = this.parsedPath;
        if (position !== WireScreenPosition.Modal) {
            const [removed] = parsedPath.removeModals();
            parsedPath = removed;
        }
        assert(parsedPath.isVisible());

        const followUps: WireHydrationFollowUp[] = [];
        const retirer = new ObjectRetirer();
        const hydrationContext: ScreenHydrationContext = {
            ...this.makePartialValueProviderActionContext(),
            appKind: this.appKind,
            internalScreen,
            fetchTableData: tn => tablesToFetch.add(tn),
            registerObjectToRetire: retirer.registerObjectToRetire,
            onChangeValueRows: new Map(),
            position,
            size,
            tabScreenVisibilityPredicates: makeTabScreenVisibilityPredicates(internalTabs),
            verifiedEmailAddressPath: this.computationModel?.getVerifiedEmailAddressPath(),
            parsedPath,
            screenKey,
            needsChanged,
            shuffleOrder: defined(this.shuffleOrder),
            screenStateKeeper: this.getScreenStateKeeper(),
            getScreenTitle: (s, hb) => this.getScreenTitle(s, hb),
            getPaymentInformationForBuyButton: id => this.backendCallbacks.getPaymentInformationForBuyButton(id),
            followUpWith: f => followUps.push(f),
            getBaseRootPathForTable: tn => this.computationModel?.getBasePathForTable(tn),
        };

        // `hydrateScreen` is responsible for retiring everything that needs
        // to be retired, unless it fails, in which case it really should
        // never have succeeded.
        const result = this.hydrateScreen(hydrationContext);

        function followUp(ab: WireActionBackend) {
            result?.followUp(ab);
            for (const f of followUps) {
                f(ab);
            }
        }

        if (result === undefined) {
            if (internalScreen.hydratedScreen !== undefined) {
                this.retireHydratedScreen(internalScreen.hydratedScreen);
            }
            return [internalScreen, followUp];
        }

        this.recomputePathsSubscribedTo(hydrationContext);

        retirer.retire();

        return [{ ...internalScreen, hydratedScreen: result.hydratedScreen }, followUp];
    }

    private fetchTableRows(tableName: TableName): void {
        // `dataStore.fetchTableRows` can have the side effect of retiring the
        // computation model and making a new one, but we're calling this
        // while inflating or hydrating components, which pulls the rug under
        // our feet.  It's safe to call it from a timeout, though.
        setTimeout(() => this.dataStore.fetchTableRows(tableName, false), 0);
    }

    private fetchUserProfileTable(): void {
        const tableName = this.adc.userProfileTableInfo?.tableName;
        if (tableName === undefined) return;
        this.fetchTableRows(tableName);
    }

    private computeTabVisibility(
        tablesToFetch: ArraySet<TableName>,
        needsChanged: SubscriptionNeeds
    ): readonly InternalTab[] | undefined {
        // This will force instantiating the computation model, which needs to
        // happen to evaluate tab visibility conditions.
        this.fetchUserProfileTable();

        const { screenInflator } = this;
        if (screenInflator === undefined) return undefined;

        const retirer = new ObjectRetirer();
        const hydrationContext: ValueProviderActionContext = {
            ...this.makePartialValueProviderActionContext(),
            fetchTableData: tn => tablesToFetch.add(tn),
            registerObjectToRetire: retirer.registerObjectToRetire,
            parsedPath: this.parsedPath,
        };

        function makeInternalTab(hydrator: InternalTabHydrator, tabIndex: number): InternalTab {
            const hb = new RowHydrationValueProvider(
                hydrationContext,
                undefined,
                undefined,
                undefined,
                // TODO: It would be better here to use the tab screen name,
                // instead of the index, to get a stable ID.
                `tab-${tabIndex}`,
                true,
                []
            );
            return hydrator(hb);
        }

        const inflatedTabs = screenInflator.inflateInternalTabs();
        let result: readonly InternalTab[] | undefined;
        if (this.internalNavigationModel?.tabs === undefined) {
            result = inflatedTabs.map(makeInternalTab);
        } else {
            result = this.internalNavigationModel.tabs.map((it, i) => {
                if (!isSubscriptionDirty(it.visibilitySubscriptionInfo, needsChanged)) {
                    return it;
                }
                this.retireSubscriptionInfo(it.visibilitySubscriptionInfo, false);

                const maybeTab = this.findTab(it.tabScreenName);
                if (maybeTab === undefined) {
                    // There's nothing we can do here, so we just don't
                    // re-hydrate.
                    return it;
                }

                const [, tabIndex] = maybeTab;
                const hydrator = defined(inflatedTabs[tabIndex]);

                return makeInternalTab(hydrator, i);
            });
        }

        this.recomputePathsSubscribedTo(hydrationContext);

        retirer.retire();

        return result;
    }

    private updateParsedPathWithTabVisibilityAndDeletedRows(internalTabs: readonly InternalTab[] | undefined): void {
        if (this.isBuilder) {
            this.setParsedPath(this.parsedPath.updateWithDeletedRows(this.deletedRowIDs), WireNavigationAction.Pop);
            return;
        }

        // If we couldn't evaluate internal tabs that means the app doesn't
        // have a user profile table or something else missing, so we just
        // ignore visibility.
        if (internalTabs === undefined) return;

        const appTabs = getAppTabs(this.appDescription);
        assert(internalTabs.length === appTabs.length);

        const visibleRootScreens = new Set<string>();
        const invisibleRootScreens = new Set<string>();
        let defaultTabScreenName: string | undefined;
        // Regular tabs come first, ##flyoutTabsComeSecond.
        for (const [tab, internalTab] of sortBy(zip(appTabs, internalTabs), ([t]) => (t?.inFlyout === true ? 1 : 0))) {
            assert(tab !== undefined && internalTab !== undefined);

            const screenName = getScreenProperty(tab.screenName);
            if (screenName === undefined) continue;

            if (internalTab.isVisible) {
                if (defaultTabScreenName === undefined) {
                    defaultTabScreenName = screenName;
                }
                visibleRootScreens.add(screenName);
            } else {
                invisibleRootScreens.add(screenName);
            }
        }

        // We don't use `setPath` here because we're already recomputing and
        // we don't want to trigger a follow-up recomputation.  This also
        // doesn't count as a "navigation action".
        this.parsedPath = this.parsedPath
            .updateWithTabVisibility(visibleRootScreens, invisibleRootScreens, defaultTabScreenName)
            .updateWithDeletedRows(this.deletedRowIDs);
    }

    // TODO: We hydrate twice if the user changes the data in an entry
    // component. The first render is caused by a push of some dirt, and then
    // the second render is triggered during the first render when we `get`
    // all the computation model entities we're subscribed to.  The latter is
    // necessary because without it the computation model doesn't even know
    // yet which rows/columns will get dirtied (because it hasn't computed
    // them yet).  It is a bit wasteful though, since the first render will be
    // superseded right away.  It would be nice if instead of the first render
    // we'd just go over all the subscription infos and `get` them, at which
    // point we will know exactly what has changed and we can rehydrate that
    // just once.  Right now this is not a big issue, but it will be when/if
    // we separate the display layer from the component model via an actual
    // wire.
    private rebuildNavigationModel(): WireHydrationFollowUp | undefined {
        this.recomputeSubscriptionHandlers();

        const { isOnline } = this;
        const needsChanged: SubscriptionNeeds = {
            parsedPath: !deepEqual(this.parsedPath, this.lastParsedPath, { strict: true }),
            shuffleOrder: this.shuffleOrder === undefined,
            online: isOnline !== this.lastIsOnline,
            override: this.rehydrateEverything,
        };
        this.rehydrateEverything = false;

        if (needsChanged.shuffleOrder) {
            this.shuffleOrder = new DefaultMap(() => Math.random());
        }

        // 1. Compute tab visibility.
        const tablesToFetch = new ArraySet<TableName>(areTableNamesEqual);
        const internalTabs = this.computeTabVisibility(tablesToFetch, needsChanged);

        // 2. Update the parsed path to not show invisible tab root screens,
        //    and to not have an invisible tab selected.
        if (this.preferredParsedPath !== undefined) {
            this.parsedPath = this.preferredParsedPath;
        }
        this.updateParsedPathWithTabVisibilityAndDeletedRows(internalTabs);

        // 3. Update the internal navigation model to reflect the parsed path.
        this.internalNavigationModel = this.updateInternalNavigationModel(this.internalNavigationModel);

        const wireTabs: WireTab[] = [];
        const wireFlyoutTabs: WireTab[] = [];

        const appTabs = getAppTabs(this.appDescription);

        const zippedTabs = zip(appTabs, internalTabs ?? []);

        for (const [appTab, internalTab] of zippedTabs) {
            assert(appTab !== undefined);

            if (appTab.showInMenu === false || internalTab?.isVisible === false) continue;

            const tabScreenName = getScreenProperty(appTab.screenName);
            if (tabScreenName === undefined) continue;

            const wireTab: WireTab = {
                title: appTab.title ?? "",
                isActive: this.parsedPath.isVisible() && tabScreenName === this.parsedPath.getTabScreenName(),
                action: this.navigateToTabActions.get(tabScreenName),
                icon: appTab.icon,
            };

            if (appTab.inFlyout === true) {
                wireFlyoutTabs.push(wireTab);
            } else {
                wireTabs.push(wireTab);
            }
        }

        // 4. Recompute screens.
        let userProfile: WireUserProfile | undefined;
        let userProfileSubscriptionInfo: SubscriptionInfo | undefined;
        if (
            this.internalNavigationModel.userProfileSubscriptionInfo === undefined ||
            isSubscriptionDirty(this.internalNavigationModel.userProfileSubscriptionInfo, needsChanged)
        ) {
            if (this.internalNavigationModel.userProfileSubscriptionInfo !== undefined) {
                this.retireSubscriptionInfo(this.internalNavigationModel.userProfileSubscriptionInfo, false);
            }

            const hydrated = this.hydrateUserProfile(tablesToFetch);
            userProfile = hydrated[0];
            userProfileSubscriptionInfo = hydrated[1];
        } else {
            userProfile = this.internalNavigationModel.userProfile;
            userProfileSubscriptionInfo = this.internalNavigationModel.userProfileSubscriptionInfo;
        }

        const [newInternalNavigationModel, followUps] = this.rebuildScreens(
            this.internalNavigationModel,
            {
                tabs: internalTabs,
                userProfile,
                userProfileSubscriptionInfo,
            },
            tablesToFetch,
            needsChanged
        );
        this.internalNavigationModel = newInternalNavigationModel;

        let error: WireMessage | undefined;
        if (
            !this.isBuilder &&
            this.heartbeatManager?.getQuotaValues !== undefined &&
            frontendCheckQuotaKinds.some(qc => this.heartbeatManager?.getQuotaReached?.(qc))
        ) {
            error = quotaError;
        }

        // The home button should link to the first visible tab.
        const firstVisibleAppTab = zippedTabs.find(([appTab, internalTab]) => {
            assert(appTab !== undefined);

            return !appTab.hidden && internalTab?.isVisible !== false;
        })?.[0];

        const firstVisibleTabScreenName = getScreenProperty(
            definedMap(firstVisibleAppTab, appTab => appTab?.screenName)
        );
        const firstTabAction = definedMap(firstVisibleTabScreenName, n => this.navigateToTabActions.get(n));

        const tabs = wireTabs;

        // If we have an explicit busy message, we'll use that.  If not, we'll
        // show a busy message if we're saving or queries are in progress.
        const busyMessage = last(this._busyMessages)?.message ?? this.savingOrLoadingMessage;

        const [navModel, auxHydrationData] = this.makeNavigationModel(
            this.internalNavigationModel,
            {
                urlPath: this.unparseParsedPath(this.preferredParsedPath ?? this.parsedPath),
                appTitle: this.appDescription.title,
                appTitleAction: firstTabAction,
                tabs,
                userProfileButton: this.internalNavigationModel.userProfile?.userProfileButton,
                signInAction: this.internalNavigationModel.userProfile?.signInAction,
                signUpAction: this.internalNavigationModel.userProfile?.signUpAction,
                signOutAction: this.internalNavigationModel.userProfile?.signOutAction,
                removeBranding: this.adc.eminenceFlags.removeBranding,
                iconImage: this.appDescription.iconImage,
                blockingMessage: error,
                serial: this.serial,
                flyoutTabs: wireFlyoutTabs,
                busyMessage,
            },
            {
                navModel: this.navigationModelWatchable.current,
                aux: this.lastAuxHydrationData,
            }
        );

        this.lastNavigationModel = navModel;
        this.navigationModelWatchable.current = maybeShallowOldest(
            this.navigationModelWatchable.current,
            this.postProcessNavigationModel(navModel)
        );
        if (this.isBuilder) {
            assert(this.metadataWatchable !== undefined);
            this.metadataWatchable.current = maybeShallowOldest(
                this.metadataWatchable.current,
                this.makeMetadata(this.internalNavigationModel, auxHydrationData)
            );
        }
        this.lastParsedPath = this.parsedPath;
        this.lastIsOnline = isOnline;
        this.lastAuxHydrationData = auxHydrationData;

        document.dispatchEvent(
            new CustomEvent("GlideExtension", {
                detail: {
                    kind: "navModel",
                    value: navModel,
                },
            })
        );

        for (const tableName of tablesToFetch.values()) {
            this.fetchTableRows(tableName);
        }

        this.computationModel?.cleanUpUnusedQueryHandlers();

        const computationModelCounters = this.computationModel?.ns.getAndResetPerformanceCounters();
        // It would be nice if we could get these into the Glide Chrome
        // extension
        void computationModelCounters;

        this._isReadyForGC = true;

        if (followUps.length > 0) {
            return ab => {
                for (const followUp of followUps) {
                    followUp(ab);
                }
            };
        } else {
            return undefined;
        }
    }

    private recompute(): void {
        if (this.state === State.Retired) return;

        logInfo("recomputing", this.id, this.computationModel?.ns.numEntities);
        // this.computationModel?.ns.debugPrintGraph();

        assert(this.state === State.Requested);
        this.state = State.Recomputing;
        try {
            const followUp = this.rebuildNavigationModel();
            if (followUp !== undefined) {
                const ab = this.makeActionBackend(
                    undefined,
                    true,
                    undefined,
                    undefined,
                    this.internalNavigationModel?.tabs
                );
                if (ab !== undefined) {
                    // We're calling ##followUpsWhileRecomputing.
                    followUp(ab);
                }
            }
        } finally {
            if ((this.state as State) === State.FollowUpRequested) {
                this.state = State.Clean;
                this.onDataChange();
                return;
            } else if ((this.state as State) === State.FollowUpWithCleanupRequested) {
                this.state = State.Clean;
                this.cleanUpInflated();
                this.onDataChange();
                return;
            } else {
                assert(this.state === State.Recomputing);
                this.state = State.Clean;
            }
        }
    }

    // Flyout screens in Apps look like modals, i.e. they obscure the tab bar,
    // but they animate like regular screens.  This is how we override the
    // default push/pop animations for them.
    protected getNavigationActionOverride(
        _screenName: string
    ): { push: WireNavigationAction.Push; pop: WireNavigationAction.Pop } | undefined {
        return undefined;
    }

    protected pushScreen(
        screenBase: ParsedScreenBase,
        target: PageScreenTarget,
        // `source === undefined` means go to the tab root.  In that case
        // `target` must be `WireScreenPosition.Main`.
        source: WireScreenPosition | undefined,
        sourceModalSize: WireModalSize | undefined,
        sourceItemRowID: string | undefined,
        onPop: (() => void) | undefined
    ): Result {
        const { parsedPath } = this;
        if (parsedPath === undefined) return Result.FailPermanent("No current screen");

        const [currentPosition, currentSize] = this.getCurrentScreenPosition();

        let targetIsNewModal: boolean;
        let screen: ParsedScreen;
        if (this.appKind === AppKind.App) {
            targetIsNewModal =
                target === PageScreenTarget.XSmallModal ||
                target === PageScreenTarget.SmallModal ||
                target === PageScreenTarget.LargeModal;
            if (targetIsNewModal) {
                screen = { kind: WireScreenPosition.Modal, size: WireModalSize.Large, ...screenBase };
            } else {
                screen = { kind: WireScreenPosition.Main, ...screenBase, sourceItemRowID };
            }
        } else {
            let isMain: boolean;
            let modalSize = WireModalSize.Small;
            if (target === PageScreenTarget.Current) {
                isMain = source !== WireScreenPosition.Modal;
                modalSize = sourceModalSize ?? modalSize;
            } else if (target === PageScreenTarget.Main) {
                isMain = true;
            } else if (target === PageScreenTarget.XSmallModal) {
                isMain = false;
                modalSize = WireModalSize.XSmall;
            } else if (target === PageScreenTarget.SmallModal) {
                isMain = false;
            } else if (target === PageScreenTarget.LargeModal) {
                isMain = false;
                modalSize = WireModalSize.Large;
            } else if (target === PageScreenTarget.SlideIn) {
                isMain = false;
                modalSize = WireModalSize.SlideIn;
            } else {
                isMain = proveNever(target, "Probably legacy", true);
            }

            if (isMain) {
                screen = { kind: WireScreenPosition.Main, ...screenBase, sourceItemRowID };
            } else {
                screen = { kind: WireScreenPosition.Modal, size: modalSize, ...screenBase };
            }

            if (currentPosition === WireScreenPosition.Modal) {
                targetIsNewModal = target !== PageScreenTarget.Current && modalSize !== currentSize;
            } else {
                targetIsNewModal = target !== PageScreenTarget.Current && target !== PageScreenTarget.Main;
            }
        }

        screen = { ...screen, onPop };

        const defaultAction = targetIsNewModal ? WireNavigationAction.PushModal : WireNavigationAction.Push;
        const override = this.getNavigationActionOverride(screenBase.screenName)?.push;
        const navigationAction = override ?? defaultAction;

        let newPath = parsedPath;
        if (
            this.appKind === AppKind.App &&
            this.formFactor === DeviceFormFactor.Tablet &&
            source === WireScreenPosition.Master &&
            !targetIsNewModal
        ) {
            // Pushing from the master in tablet mode pops everything first.
            const [poppedPath, popFollowUp] = newPath.popToTab();
            newPath = poppedPath;
            popFollowUp?.();
        }
        newPath = newPath.push(screen);

        this.setParsedPath(newPath, navigationAction);

        return Result.Ok();
    }

    private runPushFreeScreen(
        screenName: string,
        target: PageScreenTarget,
        screenTitle: string | undefined,
        rows: readonly Row[],
        source: WireScreenPosition | undefined,
        sourceModalSize: WireModalSize | undefined,
        sourceItemRowID: string | undefined,
        onPop: (() => void) | undefined
    ): void {
        this.pushScreen(
            { screenName, rowIDs: rows.map(r => r.$rowID), screenTitleOverride: screenTitle },
            target,
            source,
            sourceModalSize,
            sourceItemRowID,
            onPop
        );
    }

    private runPushDefaultClassScreen(
        tableName: TableName,
        row: Row,
        screenTitle: string | undefined,
        target: PageScreenTarget,
        source: WireScreenPosition | undefined,
        sourceModalSize: WireModalSize | undefined,
        sourceItemRowID: string | undefined
    ): void {
        this.pushScreen(
            {
                screenName: classScreenName(tableName),
                rowIDs: definedMap(row.$rowID, id => [id]) ?? [],
                screenTitleFallback: screenTitle,
            },
            target,
            source,
            sourceModalSize,
            sourceItemRowID,
            undefined
        );
    }

    private runPushFormScreen(
        screenName: string,
        row: Row | undefined,
        screenTitle: string | undefined,
        target: PageScreenTarget,
        source: WireScreenPosition | undefined,
        sourceModalSize: WireModalSize | undefined
    ): void {
        const key = makeRowKeyForScreen(screenName, row?.$rowID);
        this.invisibleRowIDs.delete(key);
        this.pushScreen(
            { screenName, rowIDs: filterUndefined([row?.$rowID]), screenTitleOverride: screenTitle },
            target,
            source,
            sourceModalSize,
            undefined,
            undefined
        );
    }

    private runPushDefaultAddScreen(
        tableName: TableName,
        target: PageScreenTarget,
        source: WireScreenPosition | undefined,
        sourceModalSize: WireModalSize | undefined
    ): void {
        const screenName = addClassScreenName(tableName);
        const key = makeRowKeyForScreen(screenName, undefined);
        this.invisibleRowIDs.delete(key);
        this.pushScreen({ screenName, rowIDs: [] }, target, source, sourceModalSize, undefined, undefined);
    }

    private runPushDefaultEditScreen(
        tableName: TableName,
        row: Row,
        target: PageScreenTarget,
        source: WireScreenPosition | undefined,
        sourceModalSize: WireModalSize | undefined
    ): void {
        const screenName = editClassScreenName(tableName);
        const key = makeRowKeyForScreen(screenName, row.$rowID);
        this.invisibleRowIDs.delete(key);
        this.pushScreen({ screenName, rowIDs: [row.$rowID] }, target, source, sourceModalSize, undefined, undefined);
    }

    private runPushSpecialScreen(
        screenName: SpecialScreenName,
        target: PageScreenTarget,
        source: WireScreenPosition | undefined,
        sourceModalSize: WireModalSize | undefined,
        onPop: (() => void) | undefined
    ): void {
        this.pushScreen({ screenName, rowIDs: [] }, target, source, sourceModalSize, undefined, onPop);
    }

    public runPushDefaultArrayScreen(
        tableName: TableName,
        table: Table,
        screenTitle: string,
        target: PageScreenTarget,
        source: WireScreenPosition | undefined,
        sourceModalSize: WireModalSize | undefined
    ): void {
        const rowIDs = Array.from(table.keys());

        this.pushScreen(
            {
                screenName: arrayScreenName(this.adc, makeTableRef(tableName)),
                rowIDs,
                screenTitleOverride: screenTitle,
            },
            target,
            source,
            sourceModalSize,
            undefined,
            undefined
        );
    }

    public runPushMenuScreen(
        defaultScreenName: string,
        menuID: string,
        primaryKey: string,
        row: Row,
        screenTitle: string | undefined,
        target: PageScreenTarget,
        source: WireScreenPosition | undefined,
        sourceModalSize: WireModalSize | undefined,
        sourceItemRowID: string | undefined
    ): void {
        const primaryKeyHash = md5(primaryKey);

        let screenName = getMenuScreenName(menuID, primaryKeyHash);
        let screen = this.appDescription.screenDescriptions[screenName];

        if (screen === undefined && !this.isBuilder) {
            screenName = defaultScreenName;
            screen = this.appDescription.screenDescriptions[screenName];
        }

        if (screen === undefined) {
            if (!this.isBuilder) {
                logError("Default screen for menu doesn't exist", screenName);
                return;
            }

            // We're in the builder and we will now push a screen that doesn't
            // exist.  That's fine because we will modified the app
            // description which means that this backend instance will be
            // retired and replaced by a new one with the new app description
            // that does contain the screen.  The reason we're pushing the
            // screen before adding it to the app description is because the
            // latter might cause a sync redraw which will replace this
            // instance before it updated its parsed path, which means the new
            // instance will also not have the new parsed path, i.e. the
            // screen will not have been pushed.
        }

        this.pushScreen(
            {
                screenName,
                rowIDs: [row.$rowID],
                screenTitleOverride: screenTitle,
            },
            target,
            source,
            sourceModalSize,
            sourceItemRowID,
            undefined
        );

        if (screen === undefined) {
            assert(this.isBuilder);

            // Add the missing screen by copying the default screen
            this.backendCallbacks.addMenuScreen?.(menuID, primaryKeyHash, defaultScreenName);
        }
    }

    private runCloseScreen(position: WireScreenPosition.Modal): void {
        if (this.parsedPath === undefined) return;

        if (position === WireScreenPosition.Modal) {
            const [parsedPath, onPop] = this.parsedPath.closeModal();
            onPop?.();
            this.setParsedPath(parsedPath, WireNavigationAction.PopModal);
        } else {
            return assertNever(position);
        }
    }

    private runNavigateToPath(path: NavigationPath, navigationAction: WireNavigationAction): Result {
        if (!this.isParsedPathValid(path, false)) return Result.FailPermanent("Path to navigate to is invalid");

        this.setParsedPath(path, navigationAction);
        return Result.Ok();
    }

    protected runNavigateToTab(tabScreenNameOrLogicalTabIndex: string | number, force: boolean): Result {
        if (tabScreenNameOrLogicalTabIndex === userProfileScreenName) {
            const [parsedPath, onPop] = this.parsedPath.navigateToTab(userProfileScreenName).popToTab();
            onPop?.();
            this.setParsedPath(parsedPath, WireNavigationAction.SwitchTab);
        } else {
            if (typeof tabScreenNameOrLogicalTabIndex === "string") {
                const result = this.findTab(tabScreenNameOrLogicalTabIndex);
                if (result === undefined) return Result.FailPermanent("Tab does not exist");
                tabScreenNameOrLogicalTabIndex = result[1];
            }

            const internalTab = this.internalNavigationModel?.tabs?.[tabScreenNameOrLogicalTabIndex];
            if (internalTab === undefined) return Result.FailPermanent("Tab does not exist");
            if (!internalTab.isVisible && !force) return Result.FailPermanent("Tab is not visible");

            const { tabScreenName } = internalTab;

            if (this.parsedPath.getTabScreenName() === tabScreenName) {
                const [parsedPath, onPop] = this.parsedPath.popToTab();
                onPop?.();
                this.setParsedPath(parsedPath, WireNavigationAction.Pop);
            } else {
                this.setParsedPath(this.parsedPath.navigateToTab(tabScreenName), WireNavigationAction.SwitchTab);
            }
        }
        return Result.Ok();
    }

    public navigateToVisibleTab(): void {
        const tabs = this.internalNavigationModel?.tabs;
        if (tabs === undefined) return;

        // Find the first tab whose visibility condition is true.  Regular
        // tabs come first, ##flyoutTabsComeSecond.
        for (const inFlyout of [false, true]) {
            for (const it of tabs) {
                if (!it.isVisible) continue;
                const maybeTab = this.findTab(it.tabScreenName);
                if (maybeTab === undefined) continue;
                const [tab, index] = maybeTab;
                if ((tab.inFlyout === true) !== inFlyout) continue;
                this.navigateToTabRoot(index);
                return;
            }
        }
    }

    public navigateToTabRoot(tabScreenNameOrLogicalTabIndex: string | number): void {
        this.runNavigateToTab(tabScreenNameOrLogicalTabIndex, true);
    }

    protected getCurrentScreenPosition(): [WireScreenPosition, WireModalSize | undefined] {
        if (!this.parsedPath.isVisible()) return [WireScreenPosition.Main, undefined];
        const { realModal } = this.parsedPath.getParsedScreens();

        let source: WireScreenPosition;
        let sourceModalSize: WireModalSize | undefined;
        if (realModal !== undefined) {
            source = WireScreenPosition.Modal;
            sourceModalSize = realModal.screen.size;
        } else {
            source = WireScreenPosition.Main;
        }
        return [source, sourceModalSize];
    }

    public pushFakeScreen(screenName: string): void {
        const screen = this.appDescription.screenDescriptions[screenName];
        if (screen === undefined) return;

        const [source, sourceModalSize] = this.getCurrentScreenPosition();

        if (screen.kind === ScreenDescriptionKind.Class) {
            if (!isSingleRelationType(screen.type)) return;
            const path = this.computationModel?.getBasePathForTable(getTableRefTableName(screen.type));
            if (path === undefined) return;
            const table = loadedDefinedMap(this.computationModel?.ns.get(path), asTable);
            if (table === undefined || isLoadingValue(table)) return;
            const rowIDs = Array.from(table.keys()).slice(0, 1);
            this.pushScreen(
                { screenName, rowIDs },
                PageScreenTarget.Current,
                source,
                sourceModalSize,
                undefined,
                undefined
            );
        } else if (screen.kind === ScreenDescriptionKind.Array) {
            this.pushScreen(
                { screenName, rowIDs: [] },
                PageScreenTarget.Current,
                source,
                sourceModalSize,
                undefined,
                undefined
            );
        }
    }

    public abstract navigateToUserProfile(force: boolean): Result;

    public abstract runNavigateUp(): Result;

    public popToLogicalTab(): void {
        if (!this.parsedPath.isVisible()) return;

        const [parsedPath, onPop] = this.parsedPath.popToTab();
        onPop?.();
        this.setParsedPath(parsedPath, WireNavigationAction.Pop);
    }

    private reshuffle(): void {
        this.shuffleOrder = undefined;
        this.computationModel?.reshuffle();
    }

    private handleDataActionError(table: TableGlideType | undefined, e: Error) {
        const originalMessage = e instanceof EnqueueActionException ? e.originalMessage : undefined;

        let showOriginalMessage = true;
        if (table === undefined || !getShouldShowOriginalErrorMessageForTable(table)) {
            // We only show the original message for SQL data sources.
            showOriginalMessage = false;
        }
        if (!this.isBuilder && !getFeatureSetting("sqlShowDetailedErrorMessageInPlayer")) {
            // If we're in the player, we only want to show the original
            // message if the feature setting is on.
            showOriginalMessage = false;
        }

        let message = showOriginalMessage ? originalMessage : undefined;
        if (message === undefined) {
            message = getLocalizedString("error", this.appKind);
        }

        this.actionCallbacks?.showToast(false, message);
    }

    private getDataStoreForTable(table: TableGlideType | undefined): DataStore {
        if (table !== undefined && isQueryableTable(table) && this.queryableDataStore !== undefined) {
            return this.queryableDataStore;
        } else {
            return this.dataStore;
        }
    }

    private async runSetColumnsInRow(
        tableName: TableName,
        primaryKeyColumnName: string | undefined,
        row: Row,
        updates: Record<string, LoadedGroundValue>,
        withDebounce: boolean,
        screenPath: string,
        existingJobID: string | undefined,
        confirmedAtVersion: number | undefined
    ): Promise<Result> {
        if (!row.$isVisible) {
            this.setColumnsInInvisibleRow(tableName, row, updates);
            // For invisible rows with a backend row ID, we set the data both
            // in the invisible row, as well as through the backend to the
            // actual row.
            if (row.$backendRowID === undefined) return Result.Ok();
        }

        const table = this.adc.findTable(tableName);
        if (table === undefined) return Result.FailPermanent("Table does not exist");

        const rowIndex = getRowIndexForRow(table, primaryKeyColumnName, row);
        if (rowIndex === undefined) return Result.FailPermanent("Row does not exist");

        const dataStore = this.getDataStoreForTable(table);
        await dataStore.setColumnsInRow(
            {
                fromDataEditor: false,
                screenPath,
                tableName: getTableName(table),
                sendToBackend: true,
                setUnderlyingData: true,
                awaitSend: false,
                onError: e => this.handleDataActionError(table, e),
            },
            rowIndex,
            updates,
            withDebounce,
            undefined,
            existingJobID,
            confirmedAtVersion
        );

        return Result.Ok();
    }

    private async runDeleteRowsAtIndexes(
        tableName: TableName,
        rowIndexes: readonly RowIndex[],
        awaitSend: boolean,
        screenPath: string
    ): Promise<Result> {
        const table = this.adc.findTable(tableName);
        const dataStore = this.getDataStoreForTable(table);
        await dataStore.deleteRowsAtIndexes(
            {
                fromDataEditor: false,
                screenPath,
                tableName,
                sendToBackend: true,
                setUnderlyingData: true,
                awaitSend,
                onError: e => this.handleDataActionError(table, e),
            },
            rowIndexes
        );
        return Result.Ok();
    }

    private async runDeleteRows(
        tableName: TableName,
        rows: readonly Row[],
        awaitSend: boolean,
        screenPath: string
    ): Promise<Result> {
        const table = this.adc.findTable(tableName);
        if (table === undefined) return Result.FailPermanent("Table does not exist");

        // We delete invisible rows when running Delete Row in Edit screens.
        const invisibleRowsToDelete: Row[] = [];

        const rowIndexes = mapFilterUndefined(rows, r => {
            this.deletedRowIDs.add(r.$rowID);
            if (r.$isVisible) {
                const rowIndex = getRowIndexForRow(table, undefined, r);
                if (rowIndex === undefined) return undefined;
                return rowIndex;
            } else {
                invisibleRowsToDelete.push(r);
                return undefined;
            }
        });

        if (invisibleRowsToDelete.length > 0) {
            const keeper = this.computationModel?.tableKeeperStore.getTableKeeperForTable(tableName);
            for (const r of invisibleRowsToDelete) {
                this.deletedRowIDs.add(r.$rowID);
                keeper?.deleteInvisibleRow(r.$rowID);
            }
        }

        if (rowIndexes.length === 0) return Result.FailPermanent("No rows to delete");
        return await this.runDeleteRowsAtIndexes(tableName, rowIndexes, awaitSend, screenPath);
    }

    private async runAddRow(
        tableName: TableName,
        r: Row,
        dataStoreName: string | undefined,
        awaitSend: boolean,
        screenPath: string
    ): Promise<Result<Row | undefined>> {
        assert(!r.$isVisible);

        const newRow: Record<string, LoadedGroundValue> = {};

        const table = this.adc.findTable(tableName);
        if (dataStoreName === localDataStoreName) {
            for (const [k, v] of Object.entries(r)) {
                if (k === "$rowID" || k === "$isVisible") continue;
                if (!isPrimitiveValue(v)) continue;
                newRow[k] = v;
            }
            const result = await this.localDataStore?.addRowToTable(
                {
                    fromDataEditor: false,
                    screenPath,
                    tableName,
                    sendToBackend: true,
                    setUnderlyingData: true,
                    awaitSend,
                    onError: e => this.handleDataActionError(table, e),
                },
                newRow,
                undefined
            );
            if (result?.playerRow === undefined) return Result.Fail("Failed to add row");
            return Result.Ok(result.playerRow);
        }

        const outputTable = this.adc.findTable(tableName);
        if (outputTable === undefined) return Result.FailPermanent("Table does not exist");

        const keeper = this.computationModel?.tableKeeperStore.getTableKeeperForTable(tableName);
        keeper?.deleteInvisibleRow(r.$rowID);

        const columnNames = new Set<string>();
        for (const c of outputTable.columns) {
            if (!isColumnWritable(c, outputTable, true, { allowArrays: true, allowHidden: true })) continue;

            const v = getRowColumn(r, c.name);
            if (v === undefined || isLoadingValue(v)) continue;

            newRow[c.name] = v;
            columnNames.add(c.name);
        }
        if (outputTable.rowIDColumn !== undefined && shouldGenerateRowIDForTable(outputTable)) {
            newRow[outputTable.rowIDColumn] = r.$rowID;
            columnNames.add(outputTable.rowIDColumn);
        }

        const dataStore = this.getDataStoreForTable(outputTable);
        const result = await dataStore.addRowToTable(
            {
                fromDataEditor: false,
                screenPath,
                tableName,
                sendToBackend: true,
                setUnderlyingData: true,
                awaitSend,
                onError: e => this.handleDataActionError(table, e),
            },
            newRow,
            columnNames
        );
        // If we didn't actually add the row, we shouldn't trigger any
        // follow-on actions that require it.
        if (result.didAdd) {
            return Result.Ok(result.playerRow);
        } else {
            return Result.Fail("Failed to add row");
        }
    }

    private async runSaveEditScreenChanges(
        tableName: TableName,
        sc: HydratedScreenContext,
        screenPath: string
    ): Promise<Result> {
        const { outputRow } = sc;
        assert(sc.inputRows.length === 1 && outputRow !== undefined);
        assert(outputRow.$referenceCopy !== undefined);

        const table = this.adc.findTable(tableName);
        if (table === undefined) return Result.FailPermanent("Table does not exist");

        const updates: Record<string, LoadedGroundValue> = {};

        for (const column of table.columns) {
            if (isComputedColumn(column)) continue;
            if (!isColumnWritable(column, table, false, { allowArrays: true, allowHidden: true })) continue;
            if (column.name === table.rowIDColumn) continue;
            if (column.name === "$rowID") continue;

            const inputValue = getRowColumn(outputRow.$referenceCopy, column.name);
            const outputValue = getRowColumn(outputRow, column.name);
            if (isArray(inputValue)) {
                if (!isArray(outputValue)) continue;
                if (shallowEqualArrays(inputValue, outputValue)) continue;
            } else {
                if (inputValue === outputValue) continue;
                // They could be `GlideDateTimes` which would not be
                // identical, but still equal.  This guards against the case
                // that somebody changed a date/time in the Edit screen, and
                // then changed it back to what it was, in which case we don't
                // want to write it, just like we wouldn't write in the same
                // case for other primitive values.
                if (
                    isPrimitiveValue(inputValue) &&
                    isPrimitiveValue(outputValue) &&
                    arePrimitiveValuesStrictlyEqual(inputValue, outputValue)
                ) {
                    continue;
                }
            }
            assert(!isLoadingValue(outputValue));

            updates[column.name] = outputValue;
        }

        const keeper = this.computationModel?.tableKeeperStore.getTableKeeperForTable(tableName);
        this.deletedRowIDs.add(outputRow.$rowID);
        keeper?.deleteInvisibleRow(outputRow.$rowID);

        return await this.runSetColumnsInRow(
            tableName,
            undefined,
            sc.inputRows[0],
            updates,
            false,
            screenPath,
            undefined,
            undefined
        );
    }

    private clearSubComponentStates(stateSaveKey: string): void {
        this.processScreensAndComponents(cs => {
            if (cs.stateSaveKey !== stateSaveKey) return undefined;

            for (const [, states] of Object.values(cs.subComponentStates)) {
                for (const scs of states) {
                    scs.stateValues.clear();
                }
            }
            return true;
        });
    }

    // There are three cases:
    // 1. We don't have an edited row ID yet.  Make a row ID, and make the row
    //    for it.
    // 2. We have an edited row ID, but the row is either gone or already
    //    submitted.  Make a new row ID, and make the row for it.
    // 3. We have an edited row ID, and the row exists and is invisible. Use
    //    that row.
    private getOrCopyRowWithNewRowID(
        tableName: TableName,
        makeRowToCopy: RowToCopyMaker,
        existingRowID: string | undefined
    ): Row | undefined {
        const outputTable = this.adc.findTable(tableName);
        if (outputTable === undefined) return undefined;

        const keeper = this.computationModel?.tableKeeperStore.getTableKeeperForTable(tableName);
        if (keeper === undefined) return undefined;

        if (existingRowID !== undefined) {
            const tableData = this.computationModel?.getBaseDataForTable(tableName);
            // ##outputTableIsntLoadedYet:
            // Maybe we should propagate the loading value here?  It doesn't
            // look necessary.
            if (isLoadingValue(tableData)) return undefined;
            const existingRow = tableData?.get(existingRowID);
            if (existingRow?.$isVisible === false) {
                return existingRow;
            }
        }

        const [rowToCopy, referenceRow] = makeRowToCopy();
        assert(!rowToCopy.$isVisible);

        // We must not copy thunks.
        // https://github.com/quicktype/glide/issues/16051
        function makeCopy(r: Row, rowID: string): Writable<Row> {
            const c: Writable<Row> = fromPairs(
                Object.entries(r).filter(([_k, v]) => !isLoadingValue(v) && !isThunk(v))
            ) as Row;
            c.$rowID = rowID;
            if (outputTable?.rowIDColumn !== undefined) {
                c[outputTable.rowIDColumn] = rowID;
            }
            return c;
        }

        const copy = makeCopy(rowToCopy, makeRowID());
        if (referenceRow !== undefined) {
            copy.$referenceCopy = makeCopy(referenceRow, copy.$rowID);
        }

        keeper.addInvisibleRow(copy);

        return copy;
    }

    private getOrCopyRowWithKey(tableName: TableName, makeRow: RowToCopyMaker, key: string): Row | undefined {
        const rowID = this.invisibleRowIDs.get(key);

        const copy = this.getOrCopyRowWithNewRowID(tableName, makeRow, rowID);
        if (copy === undefined) return undefined;

        this.invisibleRowIDs.set(key, copy.$rowID);
        return copy;
    }

    private getOrAddEmptyRow(
        tableName: TableName,
        key: string,
        makeColumnsToSet: () => Record<string, GroundValue>
    ): Row | undefined {
        return this.getOrCopyRowWithKey(
            tableName,
            () => [{ ...makeColumnsToSet(), $rowID: "", $isVisible: false }, undefined],
            key
        );
    }

    private setColumnsInInvisibleRow(tableName: TableName, row: Row, update: Record<string, GroundValue>): void {
        const keeper = this.computationModel?.tableKeeperStore.getTableKeeperForTable(tableName);
        if (keeper === undefined) return;

        // TODO: We're kinda abusing this API.  I'm not sure it's meant to let
        // you update invisible rows without bothering the data store.  Also,
        // this method assumes that `updates` are serialized values, and the
        // only reason this works at all is because
        // `convertSerializableValueToCellValue` special-cases those.
        // ##weAreDeserializingGroundValues
        keeper.updateRowWithPartialData(
            { keyColumnName: nativeTableRowIDColumnName, keyColumnValue: row.$rowID },
            update
        );
    }

    private requestRecompute(sync: boolean): void {
        if (isRecomputingState(this.state)) {
            assert(!sync);
            // ##followUpsWhileRecomputing:
            // Follow-ups can state change and thus request recomputes while
            // we're recomputing.  Another way to handle this would be to call
            // the follow-ups in a non-recomputing state.
            if (this.state === State.Recomputing) {
                this.state = State.FollowUpRequested;
            }
            return;
        }

        assert(this.state !== State.Retired);
        if (this.state === State.Requested) return;
        this.state = State.Requested;

        if (sync) {
            this.recompute();
        } else {
            setTimeout(() => this.recompute(), 0);
        }
    }

    private makeActionHydrationContext(
        internalTabs: readonly InternalTab[] | undefined
    ): [ValueProviderActionContext, ActionHydrationContext] {
        const context: ValueProviderActionContext = {
            ...this.makePartialValueProviderActionContext(),
            fetchTableData: () => undefined,
            // action backends don't hold on to anything big yet, so we don't
            // bother retiring them
            registerObjectToRetire: () => undefined,
            parsedPath: this.parsedPath,
        };

        const actionHydrationContext: ActionHydrationContext = {
            ...context,
            tabScreenVisibilityPredicates: makeTabScreenVisibilityPredicates(internalTabs),
            verifiedEmailAddressPath: this.computationModel?.getVerifiedEmailAddressPath(),
        };

        return [context, actionHydrationContext];
    }

    public makeActionBackend(
        rowScreenContext: HydratedRowContext | undefined,
        enableNavigation: boolean,
        source?: WireScreenPosition,
        sourceModalSize?: WireModalSize,
        internalTabs?: readonly InternalTab[]
    ): ActionBackendWithHydrationContext | undefined {
        if (!this.parsedPath.isVisible()) return undefined;

        const currentScreenPath = this.unparseParsedPath(this.parsedPath);

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const backend = this;

        const [context, actionHydrationContext] = this.makeActionHydrationContext(internalTabs);

        const actionInvocationID = makeRowID();
        const componentID = `action-${actionInvocationID}`; // this is a dummy component ID for the `HydrationValueProvider`
        const { queryableDataStore, appFacilities, appID } = this;

        class ActionBackend extends RowHydrationValueProvider<ValueProviderActionContext> implements WireActionBackend {
            private actionLogger: ActionLogger | undefined;

            constructor(
                rowContext: HydratedRowContext | undefined,
                public readonly timesOutAt: Date,
                private readonly appUserID: string | undefined,
                private readonly screenPath: string,
                public readonly appData: AppData
            ) {
                super(context, rowContext, undefined, undefined, componentID, false, []);
                if (!isTemplate(appID)) {
                    const sendFailureEmails = getFeatureSetting("notifyActionErrors");
                    this.actionLogger = new ActionLogger({
                        sendFailureEmails,
                    });
                }
            }

            public logActionResult(
                actionID: string | undefined,
                startedAt: Date,
                finishedAt: Date,
                result: WireActionResult
            ): void {
                this.actionLogger?.logActionRun(actionInvocationID, actionID, startedAt, finishedAt, result);
            }

            public get hydrationContext(): ValueProviderActionContext {
                return context;
            }

            public get appID(): string {
                return backend.appID;
            }

            public get actionCallbacks(): WireActionCallbacks {
                return backend.actionCallbacks ?? dummyFrontendActionCallbacks;
            }

            public getAppUserID(): string | undefined {
                return this.appUserID;
            }

            // The only reason for this method vs invoking the runner by hand
            // is to ease debugging.  Debugging actions is very hard because
            // they're all async, which is a pain.  This is the one entry
            // point for all runner, so we can just break here.
            // FIXME: make this log the action
            public async invoke(
                reason: string,
                runner: WireActionRunner,
                handledByFrontend: boolean
            ): Promise<WireActionResult> {
                logInfo("Running action", reason);
                let result: WireActionResult;
                const runnerPromise = async () => {
                    return await runner(this, handledByFrontend, 123);
                };
                if (this.actionLogger !== undefined) {
                    result = await this.actionLogger.invoke(this.appID, runnerPromise, backend.appFacilities);
                } else {
                    result = await runnerPromise();
                }
                return result;
            }

            public makeBackendForSubAction(): WireRowActionHydrationValueProvider {
                return new RowActionHydrationBackend(
                    actionHydrationContext,
                    this.rowContext,
                    undefined,
                    undefined,
                    `sub-action-${makeRowID()}`,
                    false,
                    []
                );
            }

            public getState<T>(
                _name: string,
                _validate: (v: unknown) => v is T,
                _defaultValue: T
            ): WireAlwaysEditableValue<T> {
                return panic("State is not supported in actions");
            }

            public pushFreeScreen(
                screenName: string,
                rows: readonly Row[],
                screenTitle: string | undefined,
                target: PageScreenTarget,
                sourceItemRowID: string | undefined,
                onPop: (() => void) | undefined
            ): void {
                backend.runPushFreeScreen(
                    screenName,
                    target,
                    screenTitle,
                    rows,
                    source,
                    sourceModalSize,
                    sourceItemRowID,
                    onPop
                );
            }

            public pushDefaultClassScreen(
                tableName: TableName,
                r: Row,
                screenTitle: string | undefined,
                target: PageScreenTarget,
                sourceItemRowID: string | undefined
            ): void {
                if (!enableNavigation) return;

                backend.runPushDefaultClassScreen(
                    tableName,
                    r,
                    screenTitle,
                    target,
                    source,
                    sourceModalSize,
                    sourceItemRowID
                );
            }

            public pushDefaultAddScreen(tableName: TableName, target: PageScreenTarget): void {
                if (!enableNavigation) return;

                backend.runPushDefaultAddScreen(tableName, target, source, sourceModalSize);
            }

            public pushDefaultEditScreen(tableName: TableName, r: Row, target: PageScreenTarget): void {
                if (!enableNavigation) return;

                backend.runPushDefaultEditScreen(tableName, r, target, source, sourceModalSize);
            }

            public pushFormScreen(
                screenName: string,
                r: Row | undefined,
                screenTitle: string | undefined,
                target: PageScreenTarget
            ): void {
                if (!enableNavigation) return;

                backend.runPushFormScreen(screenName, r, screenTitle, target, source, sourceModalSize);
            }

            public pushDefaultArrayScreen(
                tableName: TableName,
                table: Table,
                screenTitle: string,
                target: PageScreenTarget
            ): void {
                if (!enableNavigation) return;

                backend.runPushDefaultArrayScreen(tableName, table, screenTitle, target, source, sourceModalSize);
            }

            public pushMenuScreen(
                defaultScreenName: string,
                menuID: string,
                primaryKey: string,
                row: Row,
                screenTitle: string | undefined,
                sourceItemRowID: string | undefined
            ): void {
                if (!enableNavigation) return;

                backend.runPushMenuScreen(
                    defaultScreenName,
                    menuID,
                    primaryKey,
                    row,
                    screenTitle,
                    PageScreenTarget.Main,
                    source,
                    sourceModalSize,
                    sourceItemRowID
                );
            }

            public reshuffle() {
                backend.reshuffle();
            }

            public navigateToUserProfile(): Result {
                if (!enableNavigation) return Result.Ok();

                return backend.navigateToUserProfile(false);
            }

            public closeScreen(position: WireScreenPosition.Modal): void {
                if (!enableNavigation) return;

                backend.runCloseScreen(position);
            }

            public navigateToPath(path: NavigationPath, navigationAction: WireNavigationAction): Result {
                if (!enableNavigation) return Result.Ok();

                return backend.runNavigateToPath(path, navigationAction);
            }

            public navigateToTab(tabScreenName: string, handleFlyouts: boolean): Result {
                if (!enableNavigation) return Result.Ok();

                if (handleFlyouts) {
                    const token = backend.navigateToTabActions.get(tabScreenName).token;
                    if (token === null || token === undefined) return Result.FailPermanent("Tab does not exist");
                    this.runAction(token);
                    return Result.Ok();
                }
                return backend.runNavigateToTab(tabScreenName, false);
            }

            public dragBack(): Result {
                if (!enableNavigation) return Result.Ok();

                const popped = backend.parsedPath.pop();
                if (popped === undefined) return Result.FailPermanent("Already at the root screen");

                popped[1]?.();
                backend.setParsedPath(popped[0], WireNavigationAction.DragBack);

                return Result.Ok();
            }

            public navigateUp(): Result {
                if (!enableNavigation) return Result.Ok();

                return backend.runNavigateUp();
            }

            public async addRow(
                tableName: TableName,
                r: Row,
                dataStoreName: string | undefined,
                awaitSend: boolean
            ): Promise<Result<Row | undefined>> {
                return await backend.runAddRow(tableName, r, dataStoreName, awaitSend, this.screenPath);
            }

            public async setColumnsInRow(
                tableName: TableName,
                r: Row,
                updates: Record<string, LoadedGroundValue>,
                withDebounce: boolean,
                existingJobID: string | undefined,
                confirmedAtVersion: number | undefined
            ): Promise<Result> {
                return await backend.runSetColumnsInRow(
                    tableName,
                    undefined,
                    r,
                    updates,
                    withDebounce,
                    this.screenPath,
                    existingJobID,
                    confirmedAtVersion
                );
            }

            public async deleteRows(tableName: TableName, rows: readonly Row[], awaitSend: boolean): Promise<Result> {
                return await backend.runDeleteRows(tableName, rows, awaitSend, this.screenPath);
            }

            // We use this for comments
            public async deleteRowAtIndex(
                tableName: TableName,
                rowIndex: RowIndex,
                awaitSend: boolean
            ): Promise<Result> {
                return await backend.runDeleteRowsAtIndexes(tableName, [rowIndex], awaitSend, this.screenPath);
            }

            public addSpecialScreenRow(row: LoadedRow): void {
                backend.specialScreenRows.set(row.$rowID, row);
            }

            public async saveEditScreenChanges(tableName: TableName, sc: HydratedScreenContext): Promise<Result> {
                return await backend.runSaveEditScreenChanges(tableName, sc, this.screenPath);
            }

            public clearSubComponentStates(stateSaveKey: string): void {
                backend.clearSubComponentStates(stateSaveKey);
            }

            public signIn(type: "sign-in" | "sign-up", onPop: (() => void) | undefined): void {
                if (!enableNavigation) return;

                if (backend.isBuilder) {
                    // We do pretend sign-in in the builder.  We allow the
                    // action to happen, but we open the user profile screen
                    // and set a preview-as user if possible.
                    this.navigateToUserProfile();
                    backend.backendCallbacks.setPreviewAsUser();
                } else {
                    backend.runPushSpecialScreen(type, PageScreenTarget.Main, source, sourceModalSize, onPop);
                }
            }

            public valueChanged(token: string, value: unknown, valueChangeSource: ValueChangeSource): WireActionResult {
                backend.valueChanged(token, value, valueChangeSource, undefined);
                return WireActionResult.nondescriptSuccess();
            }

            public makeActionBackendForOnSubmit(newRow: Row | undefined): WireActionBackend {
                const { rowContext } = this;
                return new ActionBackend(
                    makeHydratedScreenContext(
                        newRow ?? rowContext?.inputRows[0],
                        undefined,
                        rowContext?.containingScreenRow
                    ),
                    this.timesOutAt,
                    this.appUserID,
                    this.screenPath,
                    this.appData
                );
            }

            public runAction(token: string): void {
                backend.runAction(token, false, undefined, true);
            }

            public resetQuery(tableName: TableName): Result {
                queryableDataStore?.resetQueryByTableName(tableName, undefined);
                return Result.Ok();
            }

            public async uploadFile(
                name: string,
                type: string,
                contents: string | ArrayBuffer
            ): Promise<Result<string>> {
                const session = uploadFileIntoGlideStorage(
                    appFacilities,
                    appID,
                    { name, type, contents },
                    "plugin",
                    undefined,
                    undefined,
                    true
                );
                const result = await session.attempt();
                if (isUploadFileResponseError(result))
                    return Result.Fail(result.error.message, { isPluginError: true });
                return Result.Ok(result.path);
            }

            public async withBusy<T>(message: string, f: () => Promise<T>): Promise<T> {
                const obj = { message };
                try {
                    backend._busyMessages.push(obj);
                    backend.onDataChange();
                    return await f();
                } finally {
                    const index = backend._busyMessages.indexOf(obj);
                    assert(index >= 0);
                    backend._busyMessages.splice(index, 1);
                    backend.onDataChange();
                }
            }

            public getFormFactor(): WireFormFactor {
                return backend.formFactor;
            }
        }

        return new ActionBackend(
            rowScreenContext,
            new Date(Date.now() + actionRunTimeout),
            this.dataStore.getAppUserID(),
            currentScreenPath,
            this.appEnvironment.appData
        );
    }

    private async runActionWithRunnerAndRequestRecompute(
        reason: string,
        ab: ActionBackendWithHydrationContext,
        runner: WireActionRunner,
        handledByFrontend: boolean
    ): Promise<void> {
        await ab.invoke(reason, runner, handledByFrontend);
        assert(ab.hydrationContext.actions.size === 0);
        // FIXME: This shouldn't be necessary.  The actions themselves should
        // cause recomputation to be requested if they actually changed
        // anything.  We're just too lazy to verify that that's actually the
        // case right now.
        this.requestRecompute(false);
    }

    public runAction(
        token: string | null | undefined,
        handled: boolean,
        serial: number | undefined,
        internal: boolean = false
    ): void {
        if (!internal) {
            assert(!isRecomputingState(this.state) && this.state !== State.Retired);
        }

        if (token === null || token === undefined) {
            logError("Action called with null or undefined token");
            return;
        }

        if (this.internalNavigationModel === undefined) return;

        let action: ActionRunnerWithContext | undefined;
        let source: WireScreenPosition = WireScreenPosition.Main;
        let sourceModalSize: WireModalSize | undefined;

        const maybeAction = this.getActionFromScreens(this.internalNavigationModel, token);
        if (maybeAction !== undefined) {
            action = maybeAction.action;
            source = maybeAction.source;
            sourceModalSize = maybeAction.sourceModalSize;
        }
        if (action === undefined) {
            action = this.globalActions.get(token);
            source = this.getCurrentScreenPosition()[0];
        }
        if (action === undefined) return;

        if (serial !== undefined) {
            this.serial = serial;
        }

        const [runner, row] = action;

        const ab = this.makeActionBackend(row, true, source, sourceModalSize, this.internalNavigationModel.tabs);
        if (ab === undefined) return;

        void this.runActionWithRunnerAndRequestRecompute(`token ${token}`, ab, runner, handled);
    }

    private async runValueChanged(
        entry: OnChangeData,
        value: unknown,
        valueChangeSource: ValueChangeSource,
        serial: number | undefined,
        screenPath: string
    ): Promise<void> {
        // We do tolerate a recomputing state here.  Component follow-ups can
        // run value-change actions, and they run while we're in a recomputing
        // state.  What happens then is that we're put into the follow-up
        // requested state, and the recomputation runs again.
        assert(this.state !== State.Retired);

        if (entry.kind === "column") {
            const { source, columnName, row, followUp } = entry;

            assert(isPrimitiveValue(value) || (isArray(value) && value.every(isPrimitiveValue)));

            const update = { [columnName]: value };

            if (source.kind === "table") {
                const { tableName, primaryKeyColumnName } = source;
                await this.runSetColumnsInRow(
                    tableName,
                    primaryKeyColumnName,
                    row,
                    update,
                    true,
                    screenPath,
                    undefined,
                    undefined
                );
            } else if (source.kind === "keeper") {
                source.keeper.updateRowWithPartialData({ keyColumnName: "$rowID", keyColumnValue: row.$rowID }, update);
            } else if (source.kind === "special-row") {
                row[columnName] = value;
            }

            if (serial !== undefined) {
                this.serial = serial;
            }

            if (followUp !== undefined) {
                const ab = this.makeActionBackend(
                    followUp.rowScreenContext,
                    true,
                    followUp.screenPosition,
                    followUp.modalSize,
                    this.internalNavigationModel?.tabs
                );
                if (ab !== undefined) {
                    await followUp.followUp(ab, valueChangeSource);
                }
            }
        } else {
            return assertNever(entry.kind);
        }
    }

    // This will stop at the first screen or component for which the callback
    // returns a boolean.  If it returns `true`, it will set that component's
    // state as updated, as well as all its parent components states.
    // FIXME: Actually set the parents to updated.
    private processScreensAndComponents(
        forComponent: (cs: ComponentState) => boolean | undefined,
        forScreen?: (hs: HydratedScreen) => boolean | undefined
    ): void {
        if (this.internalNavigationModel === undefined) return;

        const processComponentState = (cs: ComponentState): boolean | undefined => {
            const result = forComponent(cs);
            if (result !== undefined) {
                cs.stateChanged = true;
            }
            return result;
        };

        const processScreen = (hydratedScreen: HydratedScreen): boolean | undefined => {
            const fromScreen = forScreen?.(hydratedScreen);
            if (fromScreen !== undefined) {
                return fromScreen;
            }

            function processWithSubComponentStates(cs: ComponentState): boolean | undefined {
                const fromState = processComponentState(cs);
                if (fromState !== undefined) {
                    return fromState;
                }
                for (const [, states] of Object.values(cs.subComponentStates)) {
                    for (const scs of states) {
                        const fromSubState = processComponentState(scs);
                        if (fromSubState !== undefined) {
                            return fromSubState;
                        }
                    }
                }
                for (const si of cs.subsidiaries) {
                    if (isComponentState(si)) {
                        const fromSubsidiary = processWithSubComponentStates(si);
                        if (fromSubsidiary !== undefined) {
                            if (fromSubsidiary === true) {
                                si.stateChanged = true;
                            }
                            return fromSubsidiary;
                        }
                    }
                }
                return undefined;
            }

            for (const cs of hydratedScreen.componentStates) {
                const result = processWithSubComponentStates(cs);
                if (result !== undefined) {
                    if (result === true) {
                        cs.stateChanged = true;
                    }
                    return result;
                }
            }

            for (const cs of hydratedScreen.specialComponentStates) {
                const result = processWithSubComponentStates(cs);
                if (result !== undefined) {
                    if (result === true) {
                        cs.stateChanged = true;
                    }
                    return result;
                }
            }

            return undefined;
        };

        const fromScreens = this.processInHydratedScreens(this.internalNavigationModel, processScreen);
        if (fromScreens === true) {
            this.onDataChange();
        }
    }

    // This will trigger a recomputation if it needs to happen, either
    // directly in the case of internal state, or if we change data in the
    // computation model, it'll update our subscriptions, so in that case we
    // don't have to worry.
    public valueChanged(
        token: string,
        value: unknown,
        source: ValueChangeSource,
        serial: number | undefined
    ): WireActionResult {
        this.processScreensAndComponents(
            cs => {
                for (const [k, [, t, s]] of cs.stateValues) {
                    if (token === t) {
                        cs.stateValues.set(k, [value, t, s]);
                        this.saveStateValues(cs.stateSaveKey, cs.stateValues);
                        if (serial !== undefined) {
                            this.serial = serial;
                        }
                        return true;
                    }
                }
                return undefined;
            },
            hs => {
                const entry = hs.onChangeData.get(token);
                if (entry !== undefined) {
                    assert(isPrimitiveValue(value) || (isArray(value) && value.every(isPrimitiveValue)));
                    void this.runValueChanged(entry, value, source, serial, this.unparseParsedPath(this.parsedPath));
                    // The reason we're returning `false` here is we modified a
                    // row in the computation model, which will push dirt, which
                    // will independently lead to a redraw.
                    return false;
                }
                return undefined;
            }
        );
        // We purposely always return a success, because we used to never
        // check whether this succeeds, but now we pass this result up for
        // convenience, so we can't make it an error.
        return WireActionResult.nondescriptSuccess();
    }

    public editComponent(componentID: string | undefined, edit: any): void {
        if (this.internalNavigationModel === undefined) return;
        if (componentID === undefined) return;

        const processScreen = (hydratedScreen: HydratedScreen, internalScreen: InternalScreen) => {
            const processComponents = (
                components: readonly (WireComponent | null)[],
                componentStates: readonly ComponentState[]
            ): true | undefined => {
                if (components.length !== componentStates.length) return;

                for (const [c, cs] of zip(components, componentStates)) {
                    assert(c !== undefined && cs !== undefined);
                    if (c === null) continue;

                    if (c.id === componentID) {
                        // We know we have the right component now.  It might not
                        // support editing.
                        if (cs.editor === undefined) return undefined;
                        this.backendCallbacks.editComponent?.(internalScreen.screenName, componentID, cs.editor, edit);
                        return true;
                    }

                    for (const [name, [, subComponentStates]] of Object.entries(cs.subComponentStates)) {
                        const subComponents = (c as any)[name];
                        if (!isArray(subComponents) || subComponents.length !== subComponentStates.length) continue;

                        const result = processComponents(
                            subComponents as readonly (WireComponent | null)[],
                            subComponentStates
                        );
                        if (result !== undefined) {
                            return result;
                        }
                    }
                }
                return undefined;
            };

            return processComponents(hydratedScreen.wireScreen.components, hydratedScreen.componentStates);
        };

        this.processInHydratedScreens(this.internalNavigationModel, processScreen);
    }

    public urlPathChanged(urlPath: string): void {
        const parsed = this.parseURLPath(urlPath);
        if (parsed === undefined) return;

        this.setParsedPath(parsed, WireNavigationAction.URLChanged);
    }

    public inflateAndHydrateCompoundAction(
        action: ConditionalActionNode,
        tables: InputOutputTables,
        rowScreenContext: HydratedRowContext,
        actionDebugger: ActionDebugger
    ): WireActionRunner | WireActionResult | undefined {
        if (this.computationModel === undefined) return undefined;

        const hydrator = this.screenInflator?.inflateCompoundAction(action, tables, actionDebugger);
        if (hydrator === undefined || hydrator instanceof WireActionResult) return hydrator;

        const [, actionHydrationContext] = this.makeActionHydrationContext(undefined);

        const vp = new RowActionHydrationBackend(
            actionHydrationContext,
            rowScreenContext,
            undefined,
            undefined,
            undefined,
            false,
            []
        );

        let runner = hydrator(vp, true, undefined);
        if (isArray(runner)) {
            runner = runner[0];
        }
        return runner;
    }

    // This is where we ##cleanUpInflatedStuff.
    private cleanUpInflated(): void {
        assert(this.state === State.Clean || this.state === State.Requested);
        this.internalNavigationModel = undefined;
        this.maybeScreenInflator = undefined;
    }

    private readonly onComputationModelChange = (): void => {
        // We're being defensive here to not depend on cleanup order too much.
        if (this.state === State.Retired) return;

        this.screenStateKeeper = undefined;

        this.updateAppURL();

        if (this.state === State.FollowUpWithCleanupRequested) {
            return;
        }
        if (this.state === State.Recomputing || this.state === State.FollowUpRequested) {
            this.state = State.FollowUpWithCleanupRequested;
            return;
        }

        this.cleanUpInflated();

        this.onDataChange();
    };

    protected readonly onDataChange = (): void => {
        if (isFollowUpRequestedState(this.state)) return;

        if (this.state === State.Recomputing) {
            this.state = State.FollowUpRequested;
            return;
        }

        if (this.state !== State.Clean) return;
        this.state = State.Requested;

        logInfo("onDataChange", this.id);

        // It would be nice if we didn't have to do this async.  Also, make
        // sure we're only doing this once per change.  Might have to modify
        // the namespace to only call it when it has finished recomputing.
        setTimeout(() => this.recompute(), 0);
    };
}
