import { getFeatureSetting } from "@glide/common-core";
import type { RootPathWithType } from "@glide/computation-model-types";
import {
    type Path,
    type RelativePath,
    type RootPath,
    type ValueAndFormatPaths,
    amendPath,
    getSymbolicRepresentationForPath,
    isLoadingValue,
    isTopLevelPath,
    makeKeyPath,
    makePath,
    makeRootPath,
    type TableKeeper,
    type TableKeeperStore,
    type ColumnBuildResult,
    type ActionTableKeeper,
    type LoadingValueWrapper,
    type Handler,
    type IncomingSlot,
    type Namespace,
    type RootPathResolver,
    type TableAggregateComputation,
    fullDirt,
    type RowIndex,
    Query,
    ComputationTimeoutException,
    type Computation,
    type ColumnBuildMessage,
    type ColumnInfo,
    type ComputationError,
    type ComputationErrorAccumulatorPerTableColumn,
    type ComputationModel,
    type GroundValue,
    type LoadingValue,
    type NamespaceGraph,
    type NamespaceGraphNode,
    type PathForColumn,
    type PathWithColumnFlags,
    type RecomputeResult,
    type RecomputeTiming,
    type Row,
    Table,
    type TemporaryComputedColumn,
    makeLoadingValue,
} from "@glide/computation-model-types";
import type { ActionAppEnvironment, AppUserProvider } from "@glide/common-core/dist/js/components/types";
import {
    type BasePrimitiveValue,
    type GlideDateTime,
    type GlideDateTimeZone,
    convertValueFromSerializable,
} from "@glide/data-types";
import {
    asMaybeString,
    asString,
    asTable,
    getRowColumn,
    loadedDefinedMap,
    tableForEach,
} from "@glide/common-core/dist/js/computation-model/data";
import {
    type TableName,
    type UniversalTableName,
    areTableNamesEqual,
    rowIndexColumnName,
    type Formula,
    type SourceColumn,
    type TableAndColumn,
    type TableColumn,
    type TableGlideType,
    getTableRefTableName,
    isSingleRelationType,
    SourceColumnKind,
    SpecialValueKind,
    getSourceColumnPath,
    getTableColumn,
    getTableColumnDisplayName,
    getTableName,
    isComputedColumn,
    isDateTimeTypeKind,
    sheetNameForTable,
    type UserProfileTableInfo,
    isUniversalTableName,
    isBigTableOrExternal,
    type SchemaInspector,
    isQueryableTable,
    specialValueTypeKinds,
    makePrimitiveType,
    areSpecialValuesEqual,
} from "@glide/type-schema";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import {
    getEffectiveDisplayFormulaForColumn,
    getDisplayFormulaForColumn,
} from "@glide/generator/dist/js/formulas/compiler";
import { resolvePluginSpecialValue } from "@glide/generator/dist/js/components/special-values";
import {
    type ColumnOrValueSpecification,
    type QueryParametersSpecification,
    type SingleValuePosition,
    ColumnOrValueKind,
    SingleValuePositionKind,
    ValueFormatKind,
    decomposeFormatFormula,
} from "@glide/formula-specifications";
import type { SerializablePluginMetadata } from "@glide/plugins";
import { modifyQueryColumns } from "@glide/query-conditions";
import {
    assert,
    assertNever,
    defined,
    mapFilterUndefined,
    DefaultMap,
    definedMap,
    filterUndefined,
    panic,
    hasOwnProperty,
} from "@glideapps/ts-necessities";
import {
    ArrayMap,
    DefaultArrayMap,
    checkNumber,
    logError,
    logInfo,
    mapFilter,
    normalizeEmailAddress,
    withStopwatch,
    shallowEqualArrays,
} from "@glide/support";
import { getCycleNodesInGraph, getCyclesInGraph, makeGraphFromEdges } from "@glideapps/graphs";
import { areEqual } from "collection-utils";
import fromPairs from "lodash/fromPairs";
import {
    ExtractNthRowComputation,
    ExtractRandomRowComputation,
    ExtractRowByMaximumComputation,
    ExtractRowByMinimumComputation,
    FirstOrLast,
} from "./aggregates";
import {
    FormatDateTimeComputation,
    ParseTimeZoneAwareDateTimeComputation,
    PluginComputationComputation,
} from "./async-computations";
import {
    type ColumnBuildSuccess,
    ColumnBuilder,
    createComputationErrorAccumulatorPerTableColumn,
} from "./column-builder";
import type { ColumnOrValuesResultWithType, PluginComputationData } from "./column-builder-types";
import {
    type AggregateComputationForSingleValuePositionResult,
    type ColumnBuilderHelper,
    type ColumnOrValueResult,
    type ColumnPathsWithThunksFlag,
    type LookupTableAndColumnOptions,
    type ParametersResult,
    type SourceColumnResult,
    type TableAndColumnInfo,
    BuildResult,
    BuildResultKind,
} from "./column-builder-types";
import {
    defaultColumnFlags,
    isBuildResult,
    isOldStyleRelation,
    isSheetArrayType,
    makeBuildResultFaulty,
    prettyPrintTableAndColumn,
    updateColumnFlags,
} from "./column-builder-utils";
import {
    FormatArrayComputation,
    FormatDurationComputation,
    FormatJSONComputation,
    FormatNumberComputation,
    MakeTimeZoneAgnosticComputation,
    ModifyDateComputation,
    ModifyDateKind,
    SingleLookupComputation,
    WithDefaultComputation,
} from "./computations";
import {
    type FilterPredicate,
    GetRowHandler,
    ResolveQueryHandler,
    RunQueryLocallyHandler,
    FixupQueryHandler,
    AsyncComputationComputedColumnHandler,
    AsyncComputationHandler,
    CombineHandler,
    ComputationComputedColumnHandler,
    ComputationHandler,
    ConstantValueHandler,
    CurrentLocationHandler,
    FilterHandler,
    MutableLocalValueHandler,
    SortByHandler,
    TimestampHandler,
    UUIDColumnHandler,
    makeTableAggregateHandler,
} from "./handlers";
import { type QueryFetcher, NamespaceImpl } from "./namespace";
import { QueriesInNamespaceManager } from "./queries-manager";
import { getRootPathForPathForColumn, makeValueAndFormatPaths } from "./support";
import { SimpleTableKeeper } from "./simple-table-keeper";
import { follow, getValueAt, getValueAtPath } from "./getters";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { makeQueryForUserProfileRow } from "./use-user-profiles";
import type { PluginSpecialValueDescription, SpecialValueDescription } from "@glide/type-schema";

const printTables = false;

/**
 * **Don't use this if you just want the row ID column column name.  Instead,
 * get it directly from the `TableGlideType` via `.rowIDColumn`.**
 *
 * We use this in the computation model to get a column that we can use like a
 * row ID.  For that purpose the row index is next best thing if there's no
 * row ID.
 *
 * @returns The row ID column name or, in its absence, the row index column
 * name.
 */
export function getRowIDColumnNameOrProxy(table: TableGlideType): string {
    return table.rowIDColumn ?? rowIndexColumnName;
}

const buildResultWaitingOnDependency = new BuildResult(BuildResultKind.WaitingOnDependency, {
    message: "Waiting on a dependency",
});

class AppUserEmailAddressHandler implements Handler {
    private _email: string | undefined | false;
    private _ns: Namespace | undefined;
    public readonly symbolicRepresentation = "(app-user-email-address)";

    constructor(private readonly _authenticator: AppUserProvider | undefined, private readonly _real: boolean) {}

    private readonly appUserChanged = (): void => {
        this._email = undefined;

        this._ns?.pushDirt(this, fullDirt);
    };

    public getSlots(): readonly IncomingSlot[] {
        return [];
    }

    public get isDirty(): boolean {
        return false;
    }

    public recompute(): GroundValue {
        if (this._email === undefined) {
            if (this._real) {
                this._email = this._authenticator?.realEmail ?? false;
            } else {
                this._email = this._authenticator?.virtualEmail ?? false;
            }
        }
        if (this._email === false) return undefined;
        return this._email;
    }

    public setDirty(): void {
        return;
    }

    public connect(ns: Namespace): void {
        assert(this._ns === undefined);
        this._ns = ns;
        this._authenticator?.addCallback(this.appUserChanged);
    }

    public disconnect(ns: Namespace): void {
        assert(this._ns === ns);
        this._ns = undefined;
        this._authenticator?.removeCallback(this.appUserChanged);
    }
}

class UserProfileFilterPredicate implements FilterPredicate {
    constructor(private readonly _virtualEmailPath: RootPath, private readonly _emailColumnPath: RelativePath) {}

    public getFilteredPaths(): readonly Path[] {
        return [this._virtualEmailPath, this._emailColumnPath];
    }

    public getHostPaths(): readonly RelativePath[] {
        return [];
    }

    public isRowIncluded(ns: RootPathResolver, row: Row, wrapper: LoadingValueWrapper): boolean {
        // The filter handler doesn't include invisible rows, apart from the
        // fallback row, so we don't have to check `$isVisible` here.
        const emailValue = wrapper.unwrap(ns.get(this._virtualEmailPath, true));
        if (isLoadingValue(emailValue)) return false;
        const email = definedMap(emailValue, asString);
        if (email === undefined) return false;

        const v = wrapper.unwrap(follow(row, this._emailColumnPath));
        if (typeof v !== "string") return false;
        return normalizeEmailAddress(v) === email;
    }

    public get symbolicRepresentation(): string {
        return `(user-profile-filter virtual: ${getSymbolicRepresentationForPath(
            this._virtualEmailPath
        )} real: ${getSymbolicRepresentationForPath(this._emailColumnPath)})`;
    }
}

class MakeUserProfileRowQueryComputation implements Computation {
    constructor(
        private readonly _userProfileTableName: TableName,
        private readonly _emailColumnName: string,
        private readonly _emailPath: RootPath
    ) {}

    public getPaths(): readonly Path[] {
        return [this._emailPath];
    }

    public compute(resolver: RootPathResolver, context: GroundValue): GroundValue {
        // We don't want to do any implicit unwrapping of loading values here, since
        //  there's nothing we could do with the out-of-date email that would make sense.
        //  The consumer of this Computation is also looking for a row and not an email,
        //  so we make a new loading value without a wrapped value instad of returning the
        //  email's loading value.
        const emailValue = getValueAt(resolver, context, this._emailPath);
        if (isLoadingValue(emailValue)) return makeLoadingValue();
        const email = asString(emailValue);
        if (email === "") return undefined;
        return makeQueryForUserProfileRow(this._userProfileTableName, this._emailColumnName, [email]);
    }

    public get symbolicRepresentation(): string {
        return `(make-user-profile-row-query ${getSymbolicRepresentationForPath(this._emailPath)})`;
    }
}

function makeTableAndColumnInfo(
    table: TableGlideType,
    column: TableColumn,
    pathForColumn: PathForColumn
): TableAndColumnInfo {
    return { table, column, ...pathForColumn };
}

interface Metadata {
    readonly tableAndColumn: TableAndColumn | undefined;
}

interface CurrentTableAndColumn {
    readonly tac: TableAndColumn;
    readonly columnDependencies: Set<TableAndColumn>;
    readonly entitiesAdded: RootPath[];
}

interface ActionScopeInfo {
    readonly tableName: TableName;
    readonly rowIDHandler: MutableLocalValueHandler;
    readonly rowPath: RootPath;
    // node key -> output name -> info
    readonly lookupInfos: DefaultMap<string, Map<string, TableAndColumnInfo>>;
    // node key -> [output name, column name?] -> path
    readonly columnPaths: DefaultMap<string, ArrayMap<readonly string[], SourceColumnResult>>;
}

interface ActionNotOutputInfo {
    readonly scopeID: string;
    readonly columnName: string;
}

export interface ComputationModelOptions {
    readonly staticTimestamp: GlideDateTime | undefined;
    readonly throwComputationErrors: boolean; // defaults to `true`
    readonly getActionNodeOutputInfo: (nodeKey: string, outputName: string) => ActionNotOutputInfo | undefined;
    readonly makeRandomID: () => string;
}

export class ComputationModelImpl implements ComputationModel {
    public readonly ns: NamespaceImpl<Metadata>;

    private readonly _queriesManager: QueriesInNamespaceManager;

    // This being `undefined` means that computed columns have not being built
    // yet.
    private _columnBuildResult: ColumnBuildResult | undefined;

    // The path of the table keeper for a table.
    private readonly _tableBasePaths = new ArrayMap<TableName, RootPath>(areTableNamesEqual);
    // The path of the node that also has the global columns.  If a table
    // doesn't have global columns, this will be the same as the one in
    // `_tableBasePaths`.
    private readonly _tableFullPaths = new ArrayMap<TableName, RootPath>(areTableNamesEqual);
    // Path of the node that produces a given column.
    private readonly _columnPaths = new Map<TableAndColumn, PathForColumn | BuildResult>();
    // Path of the node that produces the formatted version of a given column
    private readonly _formatPaths = new Map<TableAndColumn, PathForColumn>();
    // Path of the first row of a given table.
    private readonly _firstTableRows = new ArrayMap<TableName, RootPath>(areTableNamesEqual);
    // Path of the last row of a given table.
    private readonly _lastTableRows = new ArrayMap<TableName, RootPath>(areTableNamesEqual);
    // Path of the user profile row.
    private _userProfileRowPath: RootPath | undefined;
    // Path of the "current time" value.
    private _timestampPath: RootPath | undefined;
    private _startOfTodayPath: RootPath | undefined;
    private _endOfTodayPath: RootPath | undefined;
    // Path of the constant `undefined`.
    private _undefinedPath: RootPath | undefined;
    private _emptyArrayPath: RootPath | undefined;
    private _verifiedEmailAddressPath: RootPath | undefined;
    private _realEmailAddressPath: RootPath | undefined;
    private _fallbackUserNameHandler: MutableLocalValueHandler | undefined;
    // The path for the current user name.  If user profile rows are
    // configured, this will get the name column from the user profile row.
    // If not, this will get the fallback name.
    private _userNamePath: RootPath | undefined;
    private _appUrlPath: RootPath | undefined;
    private _currentLocationPath: RootPath | undefined;
    // This is mutated directly by an interface shim, so we need to keep it
    // separately.
    private _appUrlVariable: MutableLocalValueHandler | undefined;
    // We increment this every time a reshuffle happens.
    private _randomOrderSerial: { handler: MutableLocalValueHandler; path: RootPath } | undefined;
    // Path of the node that produces a specific column in the user profile
    // row.  This will usually be a combination node, and have the full
    // dirtiness for that value.
    private readonly _userProfilePaths = new Map<string, TableAndColumn & PathWithColumnFlags>();
    private readonly _userProfileFormattedPaths = new Map<string, TableAndColumn & PathWithColumnFlags>();
    private readonly _pluginSpecialValuePaths = new ArrayMap<PluginSpecialValueDescription, RootPathWithType>(
        areSpecialValuesEqual
    );
    // Paths for constants.  NOTE: This won't work for `GlideDateTime`s, but
    // we shouldn't have those as constants.
    private readonly _constantPaths = new Map<BasePrimitiveValue, RootPath>();
    // This is a map of combination nodes, so we don't generate
    // duplicates.  The map key is the key of the node that provides
    // the value/result for the combination node.
    // key -> [key[], path][]
    private readonly _combinedPaths = new DefaultMap<string, [ReadonlySet<string>, RootPath][]>(() => []);
    // ##tablesAndColumnsBeingBuilt:
    // Both `TableGlideType`s as well as `TableColumn`s are unique, so we can
    // use them as keys here.  This map makes `TableAndColumn`s unique.  It's
    // important that we only add items here when we attempt to build them
    // because we check their presence here to know whether they've failed to
    // build, or we haven't tried building them yet.
    // table -> column -> tac
    private readonly _tableAndColumns = new DefaultArrayMap<TableName, Map<TableColumn, TableAndColumn>>(
        areTableNamesEqual,
        () => new Map()
    );
    private readonly _fallbackUserProfileRowID: string;
    private readonly _dependenciesForColumn: Map<TableAndColumn, Set<TableAndColumn>> = new Map();
    // Must be invalidated whenever `_columnPaths` or `_formatPaths` or any
    // table base paths change.  In practice this shouldn't happen after the
    // model has been built.
    private readonly _cachedColumnPaths = new ArrayMap<TableName, ReadonlyMap<string, ValueAndFormatPaths>>(
        areTableNamesEqual
    );
    private readonly _localTableKeeperPaths = new ArrayMap<TableName, RootPath>(areTableNamesEqual);
    // scope ID -> info
    private readonly _actionScopeInfo = new Map<string, ActionScopeInfo>();
    private readonly _temporaryTables = new ArrayMap<UniversalTableName, TableGlideType>(areTableNamesEqual);

    // The table and column currently being built.
    private _currentTableAndColumn: CurrentTableAndColumn | undefined;

    // This is only used for timing
    private _numRowsAfterAdding = 0;

    private readonly _errorAccumulators: ComputationErrorAccumulatorPerTableColumn =
        createComputationErrorAccumulatorPerTableColumn();

    constructor(
        private readonly _inspector: SchemaInspector,
        public readonly tableKeeperStore: TableKeeperStore<TableKeeper>,
        // The app environment is not strictly necessary.  Some things won't
        // work, such as geo distance, yes-code, and user profiles.
        private readonly _appEnvironment: ActionAppEnvironment | undefined,
        private readonly _queryFetcher: QueryFetcher | undefined,
        private readonly _pluginMetadata: readonly SerializablePluginMetadata[],
        private readonly _options: Partial<ComputationModelOptions>,
        debugPrint: boolean
    ) {
        this.ns = new NamespaceImpl(_appEnvironment?.appID, _queryFetcher, this, debugPrint);
        this._queriesManager = new QueriesInNamespaceManager(this.ns);

        for (const table of this.getTables()) {
            const tableName = getTableName(table);
            const displayName = sheetNameForTable(table);
            const keeper = tableKeeperStore.getTableKeeperForTable(tableName);
            const keeperPath = this.addEntity(displayName, keeper, false);
            const sortByPath = this.addEntity(
                `sort(${displayName})`,
                new SortByHandler(amendPath(keeperPath, { c: rowIndexColumnName })),
                false
            );

            this._tableBasePaths.set(tableName, sortByPath);
        }

        this.updateFallbackUserProfileRow();
        this._appEnvironment?.authenticator.addCallback(this.updateFallbackUserProfileRow);

        this._fallbackUserProfileRowID = this.makeRandomID();
    }

    private makeRandomID(): string {
        return this._options.makeRandomID?.() ?? makeRowID();
    }

    private get gbtComputedColumnsAlpha(): boolean {
        const features = this._appEnvironment?.appFeatures;
        // If we don't have app/user features then we consider the
        // experiment enabled, just to be on the safe side and not break
        // an app.
        return features === undefined || isExperimentEnabled("gbtComputedColumnsAlpha", features);
    }

    private get gbtDeepLookups(): boolean {
        const features = this._appEnvironment?.appFeatures;
        return features === undefined || isExperimentEnabled("gbtDeepLookups", features);
    }

    private get appURLVariable(): MutableLocalValueHandler {
        if (this._appUrlVariable === undefined) {
            this._appUrlVariable = new MutableLocalValueHandler();
        }
        return this._appUrlVariable;
    }

    private findTable(tableName: UniversalTableName): TableGlideType | undefined {
        return this._inspector.findTable(tableName) ?? this._temporaryTables.get(tableName);
    }

    private getUniqueTableAndColumn(tac: TableAndColumn): TableAndColumn | undefined {
        const table = this.findTable(getTableName(tac.table));
        if (table === undefined) return undefined;
        const column = getTableColumn(table, tac.column.name);
        if (column === undefined) return undefined;
        return this._tableAndColumns.get(getTableName(table)).get(column);
    }

    public getRowErrorPerTableColumn(tac: TableAndColumn, rowID: string): ComputationError | undefined {
        this.buildComputedColumns();
        const uniqueTac = this.getUniqueTableAndColumn(tac);
        if (uniqueTac === undefined) return;
        const table = this._errorAccumulators.get(uniqueTac);
        const v = table?.byRowID.get(rowID) ?? table?.global;
        return v;
    }

    public updateAppURL(urlPath: string): void {
        this.appURLVariable.setValue(urlPath);
    }

    public getAppURLPath(): RootPath {
        if (this._appUrlPath === undefined) {
            this._appUrlPath = this.addEntity("appUrl", this.appURLVariable, false);
        }
        return this._appUrlPath;
    }

    public retire(): void {
        if (this.ns.isRetired) return;

        const info = this.getUserProfileKeeper();
        if (info !== undefined) {
            info[1].deleteInvisibleRow(this._fallbackUserProfileRowID);
        }

        this.ns.retire();
        this._appEnvironment?.authenticator.removeCallback(this.updateFallbackUserProfileRow);
    }

    // `f` must return `undefined` if building the column was successful, otherwise the build result.  If it
    // wasn't successful, this will remove all entities that have been added to the
    // namespace for this column.
    private withCurrentTableAndColumn(tac: TableAndColumn, f: () => BuildResult | undefined): BuildResult | undefined {
        assert(this._currentTableAndColumn === undefined);
        this._currentTableAndColumn = {
            tac,
            columnDependencies: new Set(),
            entitiesAdded: [],
        };

        let buildResult: BuildResult | undefined;
        try {
            buildResult = f();
            return buildResult;
        } finally {
            if (buildResult !== undefined) {
                for (;;) {
                    // We have to go in reverse order to respect dependencies.
                    const path = this._currentTableAndColumn.entitiesAdded.pop();
                    if (path === undefined) break;
                    this.ns.deleteEntity(path);
                }
            }

            this._dependenciesForColumn.set(tac, this._currentTableAndColumn.columnDependencies);
            this._currentTableAndColumn = undefined;
        }
    }

    public makeGraph(): NamespaceGraph {
        this.buildComputedColumns();

        return this.ns.makeGraph();
    }

    public debugPrintNode(node: NamespaceGraphNode): void {
        this.ns.debugPrintNode(node);
    }

    private getUserProfileKeeper(): [UserProfileTableInfo, TableKeeper] | undefined {
        const { userProfileTableInfo } = this._inspector;
        if (userProfileTableInfo === undefined) return undefined;

        const keeper = this.tableKeeperStore.getTableKeeperForTable(userProfileTableInfo.tableName);

        return [userProfileTableInfo, keeper];
    }

    private readonly updateFallbackUserProfileRow = (): void => {
        const info = this.getUserProfileKeeper();
        if (info === undefined) return;
        const [userProfileTableInfo, keeper] = info;

        keeper.deleteInvisibleRow(this._fallbackUserProfileRowID);

        const email = this._appEnvironment?.authenticator.virtualEmail;

        if (email !== undefined || getFeatureSetting("alwaysAddFallbackUserProfileRow")) {
            const userProfileRow = this._appEnvironment?.authenticator.userProfileRow;

            const nameValue = this._fallbackUserNameHandler?.recompute();
            let name: string | undefined;
            if (!isLoadingValue(nameValue)) {
                name = asMaybeString(nameValue);
            }

            const userProfileTable = this.findTable(userProfileTableInfo.tableName);
            const rowIDColumnName = userProfileTable?.rowIDColumn;
            const maybeBackendRowID = rowIDColumnName !== undefined ? userProfileRow?.[rowIDColumnName] : undefined;
            const backendRowID = typeof maybeBackendRowID === "string" ? maybeBackendRowID : undefined;
            const row: Row = {
                [userProfileTableInfo.emailColumnName]: email,
                [userProfileTableInfo.nameColumnName]: name,
                ...fromPairs(
                    Object.entries(userProfileRow ?? {}).map(([k, v]) => [k, convertValueFromSerializable(v)])
                ),
                $rowID: this._fallbackUserProfileRowID,
                $backendRowID: backendRowID,
                $isVisible: false,
            };
            keeper.addInvisibleRow(row);
        }
    };

    private getTables(): readonly TableGlideType[] {
        return this._inspector.schema.tables.filter(t => !getTableName(t).isSpecial);
    }

    public getTimestampPath(): RootPath {
        if (this._timestampPath === undefined) {
            let handler: Handler;
            if (this._options.staticTimestamp !== undefined) {
                handler = new ConstantValueHandler(this._options.staticTimestamp);
            } else {
                handler = new TimestampHandler();
            }

            this._timestampPath = this.addEntity("timestamp", handler, false);
        }
        return this._timestampPath;
    }

    public getStartOrEndOfTodayPath(startOrEnd: "start" | "end"): RootPath {
        if (startOrEnd === "start") {
            if (this._startOfTodayPath === undefined) {
                const now = this.getTimestampPath();
                this._startOfTodayPath = this.addEntity(
                    "startOfToday",
                    new ComputationHandler(new ModifyDateComputation(now, ModifyDateKind.StartOfDay)),
                    false
                );
            }
            return this._startOfTodayPath;
        } else if (startOrEnd === "end") {
            if (this._endOfTodayPath === undefined) {
                const now = this.getTimestampPath();
                this._endOfTodayPath = this.addEntity(
                    "endOfToday",
                    new ComputationHandler(new ModifyDateComputation(now, ModifyDateKind.EndOfDay)),
                    false
                );
            }
            return this._endOfTodayPath;
        } else {
            return assertNever(startOrEnd);
        }
    }

    private getUndefinedPath(): RootPath {
        if (this._undefinedPath === undefined) {
            this._undefinedPath = this.addEntity("undefined", new ConstantValueHandler(undefined), false);
        }
        return this._undefinedPath;
    }

    private getEmptyArrayPath(): RootPath {
        if (this._emptyArrayPath === undefined) {
            this._emptyArrayPath = this.addEntity("empty array", new ConstantValueHandler([]), false);
        }
        return this._emptyArrayPath;
    }

    public getVerifiedEmailAddressPath(): RootPath {
        if (this._verifiedEmailAddressPath === undefined) {
            this._verifiedEmailAddressPath = this.addEntity(
                "verifiedEmailAddress",
                new AppUserEmailAddressHandler(this._appEnvironment?.authenticator, false),
                false
            );
        }
        return this._verifiedEmailAddressPath;
    }

    public getRealEmailAddressPath(): RootPath {
        if (this._realEmailAddressPath === undefined) {
            this._realEmailAddressPath = this.addEntity(
                "realEmailAddress",
                new AppUserEmailAddressHandler(this._appEnvironment?.authenticator, true),
                false
            );
        }
        return this._realEmailAddressPath;
    }

    public getUserNamePath(): RootPath {
        if (this._userNamePath === undefined) {
            assert(this._fallbackUserNameHandler === undefined);
            const fallbackHandler = new MutableLocalValueHandler();
            const fallbackPath = this.addEntity("fallbackUserName", fallbackHandler, false);
            this._fallbackUserNameHandler = fallbackHandler;

            const { userProfileTableInfo } = this._inspector;
            if (userProfileTableInfo !== undefined) {
                const result = this.getUserProfileColumn(userProfileTableInfo.nameColumnName, true);
                if (isBuildResult(result)) {
                    this._userNamePath = fallbackPath;
                } else {
                    const handler = new ComputationHandler(new WithDefaultComputation(result.tablePath, fallbackPath));
                    this._userNamePath = this.addEntity("userName", handler, false);
                }
            } else {
                this._userNamePath = fallbackPath;
            }
        }
        return this._userNamePath;
    }

    public setFallbackUserName(userName: string): void {
        // To create the handler
        this.getUserNamePath();
        assert(this._fallbackUserNameHandler !== undefined);

        if (this._fallbackUserNameHandler.setValue(userName)) {
            this.updateFallbackUserProfileRow();
        }
    }

    public reshuffle(): void {
        if (this._randomOrderSerial === undefined) return;

        const serial = checkNumber(this.ns.get(this._randomOrderSerial.path));
        this._randomOrderSerial.handler.setValue(serial + 1);
    }

    private getCurrentLocationPath(): RootPath {
        if (this._currentLocationPath === undefined) {
            this._currentLocationPath = this.addEntity("currentLocation", new CurrentLocationHandler(), false);
        }
        return this._currentLocationPath;
    }

    private getRandomOrderSerialPath(): RootPath {
        if (this._randomOrderSerial === undefined) {
            const handler = new MutableLocalValueHandler(0);
            const path = this.addEntity("randomOrderSerial", handler, false);
            this._randomOrderSerial = { handler, path };
        }
        return this._randomOrderSerial.path;
    }

    private addEntity(name: string, handler: Handler, forCurrentColumnOnly: boolean): RootPath {
        let metadata: Metadata | undefined;
        if (forCurrentColumnOnly) {
            assert(this._currentTableAndColumn !== undefined);
            metadata = { tableAndColumn: this._currentTableAndColumn.tac };
        }
        const path = this.ns.addEntity(name, handler, metadata);
        if (forCurrentColumnOnly) {
            defined(this._currentTableAndColumn).entitiesAdded.push(path);
        }
        return path;
    }

    private addExtractFirstRowEntity(entityName: string, tablePath: RootPath, includeInvisible: boolean): RootPath {
        return this.addEntity(
            entityName,
            makeTableAggregateHandler(
                tablePath,
                new ExtractRowByMinimumComputation(makePath(rowIndexColumnName), undefined, includeInvisible),
                undefined,
                false
            ),
            false
        );
    }

    private getFirstRowOfTable(table: TableGlideType): RootPath {
        const tableName = getTableName(table);
        let firstRow = this._firstTableRows.get(tableName);
        if (firstRow === undefined) {
            firstRow = this.addExtractFirstRowEntity(
                `first(${sheetNameForTable(table)})`,
                defined(this._tableBasePaths.get(tableName)),
                false
            );
            this._firstTableRows.set(tableName, firstRow);
        }
        return firstRow;
    }

    private getPathsForSingleValueOffset(
        contextTable: TableGlideType | undefined,
        offset: ColumnOrValueSpecification<number>
    ): ColumnPathsWithThunksFlag | BuildResult {
        // If we need an offset and it's in the context table, we have to return its table path.
        if (offset.kind === ColumnOrValueKind.Column) {
            const positionInfo = this.makePathForSourceColumn(defined(contextTable), offset.column, false, undefined);
            if (isBuildResult(positionInfo)) return positionInfo;

            return {
                valuePath: positionInfo.valuePath,
                tablePath: positionInfo.tablePath,
                usesThunks: positionInfo.usesThunks,
            };
        } else if (offset.kind === ColumnOrValueKind.Constant) {
            return { valuePath: this.makePathForConstant(offset.value), tablePath: undefined, usesThunks: false };
        } else if (offset.kind === ColumnOrValueKind.Empty || offset.kind === ColumnOrValueKind.SpecialValue) {
            return makeBuildResultFaulty("Single Value index is invalid");
        } else {
            return assertNever(offset);
        }
    }

    private getAggregateComputationForSingleValuePosition(
        position: SingleValuePosition,
        valuePath: RelativePath | undefined,
        contextTable: TableGlideType | undefined,
        hasCustomOrder: boolean
    ): AggregateComputationForSingleValuePositionResult | BuildResult {
        // If we need the row index, we have to return its table path in the aggregated table.

        if (hasCustomOrder) {
            if (position.kind === SingleValuePositionKind.First) {
                position = {
                    kind: SingleValuePositionKind.FromStart,
                    offset: { kind: ColumnOrValueKind.Constant, value: 0 },
                };
            } else if (position.kind === SingleValuePositionKind.Last) {
                position = {
                    kind: SingleValuePositionKind.FromEnd,
                    offset: { kind: ColumnOrValueKind.Constant, value: 0 },
                };
            }
        }

        switch (position.kind) {
            case SingleValuePositionKind.First:
                return {
                    computation: new ExtractRowByMinimumComputation(makePath(rowIndexColumnName), valuePath, false),
                    tablePath: undefined,
                    canBeGlobal: true,
                    usesThunks: false,
                };
            case SingleValuePositionKind.Last:
                return {
                    computation: new ExtractRowByMaximumComputation(makePath(rowIndexColumnName), valuePath),
                    tablePath: undefined,
                    canBeGlobal: true,
                    usesThunks: false,
                };
            case SingleValuePositionKind.FromStart:
            case SingleValuePositionKind.FromEnd:
                const positionPaths = this.getPathsForSingleValueOffset(contextTable, position.offset);
                if (isBuildResult(positionPaths)) return positionPaths;

                return {
                    computation: new ExtractNthRowComputation(
                        valuePath,
                        position.kind === SingleValuePositionKind.FromStart ? FirstOrLast.First : FirstOrLast.Last,
                        positionPaths.valuePath
                    ),
                    tablePath: positionPaths.tablePath,
                    canBeGlobal: true,
                    usesThunks: positionPaths.usesThunks,
                };
            case SingleValuePositionKind.Random:
                return {
                    computation: new ExtractRandomRowComputation(valuePath, this.getRandomOrderSerialPath()),
                    tablePath: undefined,
                    canBeGlobal: false,
                    usesThunks: false,
                };
            default:
                return assertNever(position);
        }
    }

    private extractRow(
        table: TableGlideType,
        aggregateComputation: TableAggregateComputation<unknown>,
        forCurrentColumnOnly: boolean
    ): RootPath {
        return this.addEntity(
            `${aggregateComputation.displayName}(${sheetNameForTable(table)})`,
            makeTableAggregateHandler(
                defined(this._tableBasePaths.get(getTableName(table))),
                aggregateComputation,
                undefined,
                false
            ),
            forCurrentColumnOnly
        );
    }

    private getLastRowOfTable(table: TableGlideType): RootPath {
        let lastRow = this._lastTableRows.get(getTableName(table));
        if (lastRow === undefined) {
            const computation = this.getAggregateComputationForSingleValuePosition(
                { kind: SingleValuePositionKind.Last },
                undefined,
                undefined,
                false
            );
            assert(!isBuildResult(computation) && computation.tablePath === undefined && computation.canBeGlobal);
            lastRow = this.extractRow(table, computation.computation, false);
            this._lastTableRows.set(getTableName(table), lastRow);
        }
        return lastRow;
    }

    private buildUserProfileRowPath(): RootPath | BuildResult {
        if (this._userProfileRowPath === undefined) {
            const { userProfileTableInfo } = this._inspector;
            if (userProfileTableInfo === undefined) {
                return makeBuildResultFaulty("User Profile is not set up correctly");
            }
            const userProfileTable = this._inspector.findTable(userProfileTableInfo.tableName);
            if (userProfileTable === undefined) {
                return makeBuildResultFaulty("User Profile table not found");
            }

            const emailPath = this.getVerifiedEmailAddressPath();

            if (isQueryableTable(userProfileTable)) {
                const queryHandler = new ComputationHandler(
                    new MakeUserProfileRowQueryComputation(
                        userProfileTableInfo.tableName,
                        userProfileTableInfo.emailColumnName,
                        emailPath
                    )
                );
                const queryPath = this.addEntity("userProfileQuery", queryHandler, false);

                const resolveQueryPath = this.addEntity(
                    "resolve(userProfileQuery)",
                    new ResolveQueryHandler(queryPath),
                    false
                );

                // There's no point including invisible rows because the query
                // won't return any.
                this._userProfileRowPath = this.addExtractFirstRowEntity(
                    "first(resolve(userProfileQuery))",
                    resolveQueryPath,
                    false
                );
            } else {
                const tablePath = this._tableBasePaths.get(userProfileTableInfo.tableName);
                if (tablePath === undefined) return makeBuildResultFaulty("User Profile table not found");

                // FIXME: It's possible we add this way after all the data is
                // loaded and everything is computed, but we still initialize it
                // as not dirty, which means it'll return `undefined` as its
                // result.
                const filtered = this.addEntity(
                    "filter(userProfiles)",
                    new FilterHandler(
                        tablePath,
                        new UserProfileFilterPredicate(emailPath, makePath(userProfileTableInfo.emailColumnName)),
                        undefined,
                        this._fallbackUserProfileRowID
                    ),
                    false
                );
                this._userProfileRowPath = this.addExtractFirstRowEntity("first(filter(userProfiles))", filtered, true);
            }
        }

        return this._userProfileRowPath;
    }

    private buildGlobalSingleLookup(
        name: string,
        tacInfo: TableAndColumnInfo,
        makeRowPath: () => RootPath | BuildResult
    ): RootPath | BuildResult {
        if (tacInfo.isGlobal) {
            return tacInfo.valuePath;
        } else {
            const rowPath = makeRowPath();
            if (isBuildResult(rowPath)) return rowPath;

            const handler = new ComputationHandler(
                new SingleLookupComputation(rowPath, tacInfo.valuePath, tacInfo.tablePath)
            );

            return this.addEntity(name, handler, false);
        }
    }

    private getUserProfileColumn(
        columnName: string,
        withFormat: boolean
    ): (TableAndColumn & PathWithColumnFlags) | BuildResult {
        const paths = withFormat ? this._userProfileFormattedPaths : this._userProfilePaths;

        const path = paths.get(columnName);
        if (path !== undefined) return path;

        const { userProfileTableInfo } = this._inspector;
        if (userProfileTableInfo === undefined) return makeBuildResultFaulty("User Profile is not set up correctly");

        const userProfileTable = this.findTable(userProfileTableInfo.tableName);
        if (userProfileTable === undefined) return makeBuildResultFaulty("User Profile table not found");

        const tacInfo = this.lookupTableAndColumn(userProfileTable, columnName, { withFormat });
        if (isBuildResult(tacInfo)) return tacInfo;

        const columnPath = this.buildGlobalSingleLookup(
            `${prettyPrintTableAndColumn(tacInfo)}(userProfileRow)`,
            tacInfo,
            () => this.buildUserProfileRowPath()
        );
        if (isBuildResult(columnPath)) return columnPath;

        const result = {
            table: tacInfo.table,
            column: tacInfo.column,
            tablePath: columnPath,
            usesThunks: false,
            makesQuery: tacInfo.makesQuery,
            fromQuery: tacInfo.fromQuery,
            isSlow: tacInfo.isSlow,
            hasCustomOrder: tacInfo.hasCustomOrder,
        };
        paths.set(columnName, result);
        return result;
    }

    private makePathForActionNodeOutput(
        nodeKey: string,
        outputName: string,
        outputColumnName: string | undefined,
        withFormat: boolean
    ): SourceColumnResult | BuildResult {
        const outputAndColumnName = filterUndefined([outputName, outputColumnName]);

        const outputInfo = this._options.getActionNodeOutputInfo?.(nodeKey, outputName);
        if (outputInfo === undefined) {
            return makeBuildResultFaulty(`Action node ${nodeKey} output ${outputName} not found`);
        }
        const { scopeID, columnName } = outputInfo;

        const scopeInfo = this._actionScopeInfo.get(scopeID);
        if (scopeInfo === undefined) return makeBuildResultFaulty(`Action scope ID ${scopeID} not found`);

        let tacInfo: TableAndColumnInfo | BuildResult | undefined = scopeInfo.lookupInfos.get(nodeKey).get(outputName);
        if (tacInfo === undefined) {
            tacInfo = this.lookupTableAndColumn(scopeInfo.tableName, columnName, { withFormat });
            if (isBuildResult(tacInfo)) return tacInfo;

            scopeInfo.lookupInfos.get(nodeKey).set(outputName, tacInfo);
        }

        let columnResult = scopeInfo.columnPaths.get(nodeKey).get(outputAndColumnName);
        if (columnResult === undefined) {
            assert(!tacInfo.isGlobal);

            const tablePath = this._tableBasePaths.get(scopeInfo.tableName);
            if (tablePath === undefined) {
                return makeBuildResultFaulty(`Action scope ${scopeID} is misconfigured`);
            }

            const lookupName = `${prettyPrintTableAndColumn(tacInfo)}(actionScope(${scopeID}))`;
            let maybeColumnPath = this.buildGlobalSingleLookup(lookupName, tacInfo, () => scopeInfo.rowPath);
            if (isBuildResult(maybeColumnPath)) return maybeColumnPath;

            let columnPath = maybeColumnPath;

            if (outputColumnName !== undefined) {
                if (!isSingleRelationType(tacInfo.column.type)) {
                    return makeBuildResultFaulty(
                        `Column for action node ${nodeKey} output ${outputName} is not a single relation`
                    );
                }
                const outputTable = this.findTable(getTableRefTableName(tacInfo.column.type));
                if (outputTable === undefined) {
                    return makeBuildResultFaulty(`Table for action node ${nodeKey} output ${outputName} not found`);
                }

                tacInfo = this.lookupTableAndColumn(getTableRefTableName(tacInfo.column.type), outputColumnName, {
                    withFormat,
                });
                if (isBuildResult(tacInfo)) return tacInfo;

                maybeColumnPath = this.buildGlobalSingleLookup(
                    `${prettyPrintTableAndColumn(tacInfo)}(${lookupName})`,
                    tacInfo,
                    () => defined(columnPath)
                );
                if (isBuildResult(maybeColumnPath)) return maybeColumnPath;

                columnPath = maybeColumnPath;
            }

            columnResult = {
                isGlobal: true,
                table: tacInfo.table,
                column: tacInfo.column,
                valuePath: columnPath,
                tablePath: undefined,
                usesThunks: tacInfo.usesThunks,
                makesQuery: tacInfo.makesQuery,
                fromQuery: tacInfo.fromQuery,
                isSlow: tacInfo.isSlow,
                hasCustomOrder: tacInfo.hasCustomOrder,
                inHostRow: false,
                canBeDeleted: false,
            };

            scopeInfo.columnPaths.get(nodeKey).set(outputAndColumnName, columnResult);
        }

        return columnResult;
    }

    // The returned `tablePath` is the table path to the table or computation
    // for this column, if it's in the default context.
    //
    // `undefined` means a dependency is missing or something's wrong
    private makePathForSourceColumn(
        // This is the table of the "default context".  In a filter this would
        // be the table of the rows that are filtered.
        contextTable: TableGlideType,
        sc: SourceColumn,
        withFormat: boolean,
        // This is the table for the "containing screen" context.  In a filter
        // over a relation this would be table that the relation is embedded
        // in, so that the filter can refer to the row that contains the
        // relation.
        hostTable: TableGlideType | undefined
    ): SourceColumnResult | BuildResult {
        const path = getSourceColumnPath(sc);

        if (sc.kind === SourceColumnKind.ActionNodeOutput) {
            assert(path.length === 2 || path.length === 3);
            const [nodeKey, outputName, outputColumnName] = path;
            return this.makePathForActionNodeOutput(nodeKey, outputName, outputColumnName, withFormat);
        }

        if (path.length !== 1) return makeBuildResultFaulty("Column is configured incorrectly");
        if (sc.kind === SourceColumnKind.DefaultContext || sc.kind === SourceColumnKind.ContainingScreen) {
            const inHostRow = sc.kind === SourceColumnKind.ContainingScreen;
            const table = inHostRow ? hostTable : contextTable;
            assert(table !== undefined);

            const tacInfo = this.lookupTableAndColumn(table, path[0], { withFormat });
            if (isBuildResult(tacInfo)) return tacInfo;
            if (tacInfo.isGlobal) {
                // To use non-primitive global columns, the computation needs
                // to be combined with that table column's path that is
                // accessed in the non-primitive.
                return {
                    isGlobal: true,
                    table: tacInfo.table,
                    column: tacInfo.column,
                    valuePath: tacInfo.valuePath,
                    tablePath: undefined,
                    usesThunks: false,
                    makesQuery: tacInfo.makesQuery,
                    fromQuery: tacInfo.fromQuery,
                    isSlow: tacInfo.isSlow,
                    hasCustomOrder: tacInfo.hasCustomOrder,
                    inHostRow: false,
                    canBeDeleted: false,
                };
            } else {
                return {
                    isGlobal: false,
                    table: tacInfo.table,
                    column: tacInfo.column,
                    valuePath: tacInfo.valuePath,
                    tablePath: tacInfo.tablePath,
                    tableColumnPath: tacInfo.tableColumnPath,
                    usesThunks: tacInfo.usesThunks,
                    makesQuery: tacInfo.makesQuery,
                    fromQuery: tacInfo.fromQuery,
                    isSlow: tacInfo.isSlow,
                    hasCustomOrder: tacInfo.hasCustomOrder,
                    inHostRow,
                };
            }
        } else if (sc.kind === SourceColumnKind.UserProfile) {
            const columnPath = this.getUserProfileColumn(path[0], withFormat);
            if (isBuildResult(columnPath)) return columnPath;
            return {
                isGlobal: true,
                table: columnPath.table,
                column: columnPath.column,
                valuePath: columnPath.tablePath,
                tablePath: undefined,
                usesThunks: columnPath.usesThunks,
                makesQuery: columnPath.makesQuery,
                fromQuery: columnPath.fromQuery,
                isSlow: columnPath.isSlow,
                hasCustomOrder: columnPath.hasCustomOrder,
                inHostRow: false,
                canBeDeleted: false,
            };
        } else {
            return assertNever(sc.kind);
        }
    }

    private makePathForConstant(value: BasePrimitiveValue): RootPath {
        let path = this._constantPaths.get(value);
        if (path === undefined) {
            path = this.addEntity(`Constant: ${value}`, new ConstantValueHandler(value), false);
            this._constantPaths.set(value, path);
        }
        return path;
    }

    private makeUndefinedResult(): ColumnOrValueResult {
        return {
            valuePath: this.getUndefinedPath(),
            tablePath: undefined,
            usesThunks: false,
            makesQuery: false,
            fromQuery: false,
            isSlow: false,
            hasCustomOrder: false,
            table: undefined,
            column: undefined,
        };
    }

    private makeConstantResult(c: BasePrimitiveValue): ColumnOrValueResult {
        const path = this.makePathForConstant(c);
        return {
            tablePath: undefined,
            valuePath: path,
            usesThunks: false,
            makesQuery: false,
            fromQuery: false,
            isSlow: false,
            hasCustomOrder: false,
            table: undefined,
            column: undefined,
        };
    }

    public getPluginComputation(pluginID: string, computationID: string): PluginComputationData | BuildResult {
        if (this._appEnvironment === undefined) return makeBuildResultFaulty("Internal error");

        const pluginMetadata = this._pluginMetadata.find(p => p.id === pluginID);
        if (pluginMetadata === undefined) {
            return makeBuildResultFaulty("Cannot find plugin");
        }

        const pluginConfig = this._appEnvironment.pluginConfigs.find(c => c.pluginID === pluginID);
        if (pluginConfig === undefined && pluginMetadata.isNative !== true) {
            return makeBuildResultFaulty("Cannot find plugin config");
        }

        const computation = pluginMetadata.computations.find(c => c.id === computationID);
        if (computation === undefined) {
            return makeBuildResultFaulty("Cannot find computation");
        }

        return { pluginConfig, computation };
    }

    public getPathForPluginSpecialValue(sv: PluginSpecialValueDescription): RootPathWithType | ColumnBuildMessage {
        const existing = this._pluginSpecialValuePaths.get(sv);
        if (existing !== undefined) {
            return existing;
        }

        if (this._appEnvironment === undefined) return { message: "Internal error" };

        const resolved = resolvePluginSpecialValue(sv, this._pluginMetadata);
        if (typeof resolved === "string") return { message: resolved };

        const prettyName = `specialValue(${sv.pluginID}, ${sv.computationID})`;
        const handler = new AsyncComputationHandler(
            new PluginComputationComputation(
                { pluginID: sv.pluginID, parameters: {} },
                sv.computationID,
                [],
                sv.resultName,
                this._appEnvironment,
                this._pluginMetadata,
                {
                    prettyName,
                    throwError: this._options.throwComputationErrors !== false,
                }
            ),
            () => undefined
        );
        const rootPath = this.addEntity(prettyName, handler, false);

        const result: RootPathWithType = {
            path: rootPath,
            type: resolved.type,
        };
        this._pluginSpecialValuePaths.set(sv, result);
        return result;
    }

    public makePathForSpecialValue(
        tac: TableAndColumn,
        specialValue: SpecialValueDescription
    ): ColumnOrValuesResultWithType | BuildResult {
        switch (specialValue) {
            case SpecialValueKind.VerifiedEmailAddress:
                return {
                    valuePath: this.getVerifiedEmailAddressPath(),
                    tablePath: undefined,
                    usesThunks: false,
                    makesQuery: false,
                    fromQuery: false,
                    isSlow: false,
                    hasCustomOrder: false,
                    table: undefined,
                    column: undefined,
                    type: makePrimitiveType(specialValueTypeKinds[specialValue]),
                };
            case SpecialValueKind.Timestamp:
                return {
                    valuePath: this.getTimestampPath(),
                    tablePath: undefined,
                    usesThunks: false,
                    makesQuery: false,
                    fromQuery: false,
                    // We mark this as slow because it causes periodic
                    // recomputation.
                    isSlow: true,
                    hasCustomOrder: false,
                    table: undefined,
                    column: undefined,
                    type: makePrimitiveType(specialValueTypeKinds[specialValue]),
                };
            case SpecialValueKind.UniqueIdentifier:
                const basePath = defined(this._tableBasePaths.get(getTableName(tac.table)));
                const columnName = this.makeRandomID();
                const handler = new UUIDColumnHandler(basePath, columnName);
                const tablePath = this.addEntity(`${prettyPrintTableAndColumn(tac)}.uuid`, handler, true);
                return {
                    tablePath: tablePath,
                    valuePath: makePath(columnName),
                    usesThunks: false,
                    makesQuery: false,
                    fromQuery: false,
                    isSlow: false,
                    hasCustomOrder: false,
                    table: undefined,
                    column: undefined,
                    type: makePrimitiveType(specialValueTypeKinds[specialValue]),
                };
            case SpecialValueKind.CurrentURL:
                return {
                    valuePath: this.getAppURLPath(),
                    tablePath: undefined,
                    usesThunks: false,
                    makesQuery: false,
                    fromQuery: false,
                    isSlow: false,
                    hasCustomOrder: false,
                    table: undefined,
                    column: undefined,
                    type: makePrimitiveType(specialValueTypeKinds[specialValue]),
                };
            default:
                if (typeof specialValue === "string") {
                    return panic(`Special value ${specialValue} not supported in computed columns`);
                }
                const result = this.getPathForPluginSpecialValue(specialValue);
                if (hasOwnProperty(result, "message")) return makeBuildResultFaulty(result.message, result.docURL);

                return {
                    valuePath: result.path,
                    tablePath: undefined,
                    usesThunks: false,
                    makesQuery: false,
                    fromQuery: false,
                    isSlow: false,
                    hasCustomOrder: false,
                    table: undefined,
                    column: undefined,
                    type: result.type,
                };
        }
    }

    private makePathForColumnOrValue(
        tac: TableAndColumn,
        spec: ColumnOrValueSpecification<BasePrimitiveValue>,
        withFormat: boolean,
        hostTable: TableGlideType | undefined
    ): ColumnOrValueResult | BuildResult {
        if (spec.kind === ColumnOrValueKind.Column) {
            return this.makePathForSourceColumn(tac.table, spec.column, withFormat, hostTable);
        } else if (spec.kind === ColumnOrValueKind.Constant) {
            return this.makeConstantResult(spec.value);
        } else if (spec.kind === ColumnOrValueKind.Empty) {
            return this.makeUndefinedResult();
        } else if (spec.kind === ColumnOrValueKind.SpecialValue) {
            return this.makePathForSpecialValue(tac, spec.specialValue);
        } else {
            return assertNever(spec);
        }
    }

    private makePathsForKeyValuePairs<T>(
        kvps: readonly [string, T][],
        withFormat: boolean,
        makePathForValue: (v: T, withFormat: boolean) => ColumnOrValueResult | BuildResult
    ): ParametersResult | BuildResult {
        const paramPaths = new Map<string, [Path, Path]>();
        const tablePaths: RootPath[] = [];
        const columnFlags = { ...defaultColumnFlags };

        for (const [n, s] of kvps) {
            if (n === "") continue;

            const paramPath = makePathForValue(s, false);
            if (isBuildResult(paramPath)) return paramPath;

            if (paramPath.tablePath !== undefined) {
                tablePaths.push(paramPath.tablePath);
            }
            updateColumnFlags(columnFlags, paramPath);

            let formatPath = paramPath.valuePath;
            if (withFormat) {
                const pathWithFormat = makePathForValue(s, true);
                if (!isBuildResult(pathWithFormat)) {
                    if (pathWithFormat.tablePath !== undefined) {
                        tablePaths.push(pathWithFormat.tablePath);
                    }
                    updateColumnFlags(columnFlags, pathWithFormat);

                    formatPath = pathWithFormat.valuePath;
                }
            }

            paramPaths.set(n, [paramPath.valuePath, formatPath]);
        }

        return { paramPaths, tablePaths, ...columnFlags };
    }

    // If `withFormat` is set then we also compute the formatted values of all
    // parameters.  We use this in Yes-Code when passing values to string
    // parameters, but it's wasteful because in many cases those values won't
    // even be used.
    private makePathsForQueryParameters(
        tac: TableAndColumn,
        params: QueryParametersSpecification,
        withFormat: boolean
    ): ParametersResult | BuildResult {
        return this.makePathsForKeyValuePairs(params, withFormat, (s, f) =>
            this.makePathForColumnOrValue(tac, s, f, undefined)
        );
    }

    private lookupTableAndColumn(
        tableOrTableName: TableGlideType | UniversalTableName,
        columnName: string,
        opts?: LookupTableAndColumnOptions
    ): TableAndColumnInfo | BuildResult {
        const {
            withFormat = false,
            oldStyleRelation = false,
            countAsDependency = true,
            forceBaseColumn = false,
        } = opts ?? {};

        const table = isUniversalTableName(tableOrTableName) ? this.findTable(tableOrTableName) : tableOrTableName;
        if (table === undefined) return makeBuildResultFaulty("Table not found");
        const tableName = getTableName(table);

        const column = getTableColumn(table, columnName);
        if (column === undefined) return makeBuildResultFaulty(`Column ${columnName} not found`);

        let tac: TableAndColumn | undefined;
        if (!oldStyleRelation) {
            tac = this._tableAndColumns.get(tableName).get(column);
            if (withFormat && tac !== undefined) {
                const formattedPath = this._formatPaths.get(tac);
                if (formattedPath !== undefined) {
                    return makeTableAndColumnInfo(table, column, formattedPath);
                }
            }

            if (countAsDependency && tac !== undefined && this._currentTableAndColumn !== undefined) {
                this._currentTableAndColumn.columnDependencies.add(tac);
            }
        }

        const columnIsOldStyleRelation = isOldStyleRelation(column) !== undefined;

        if (tac !== undefined && !forceBaseColumn) {
            const pathForColumn = this._columnPaths.get(tac);
            if (pathForColumn !== undefined) {
                // We tried building the computed column, but couldn't, or there's
                // an infinite recursion.
                if (isBuildResult(pathForColumn)) return pathForColumn;

                return makeTableAndColumnInfo(table, column, pathForColumn);
            }
        }

        if (!isComputedColumn(column) && isSheetArrayType(column.type) === undefined) {
            if (columnIsOldStyleRelation) {
                if (!oldStyleRelation) return makeBuildResultFaulty("Internal error with old-style relations");
            }

            // This is the code path for columns that come directly from the
            // table, without any modifications.
            const tablePath = defined(this._tableBasePaths.get(tableName));
            return {
                table,
                column,
                isGlobal: false,
                tablePath: tablePath,
                valuePath: makeKeyPath(column.name),
                tableColumnPath: amendPath(tablePath, { c: column.name }),
                usesThunks: false,
                makesQuery: false,
                isSlow: false,
                fromQuery: isBigTableOrExternal(table),
                hasCustomOrder: false,
            };
        }

        if (columnIsOldStyleRelation) return makeBuildResultFaulty("Internal error with old-style relations");

        if (tac === undefined) {
            // We haven't tried building the computed column yet - see
            // ##tablesAndColumnsBeingBuilt.
            return buildResultWaitingOnDependency;
        }

        // This can happen when a column refers directly to itself.
        return makeBuildResultFaulty("Column refers to itself");
    }

    // FIXME: Be smarter here.  Some of these paths can include others as
    // dependencies, in which case we only have to include the former.  We
    // also have to only make at most one combination per set of paths.
    private combineTablePaths(paths: readonly RootPath[], forCurrentColumnOnly: boolean): RootPath {
        assert(paths.every(isTopLevelPath));
        const keys = new Set(paths.map(p => p.rest.key));
        assert(keys.size > 0);
        const uniquePaths = Array.from(keys).map(k => makeRootPath(k));
        const mainKey = paths[0].rest.key;
        assert(mainKey === uniquePaths[0].rest.key);
        if (uniquePaths.length === 1) return uniquePaths[0];

        const entries = this._combinedPaths.get(mainKey);
        let entry = entries.find(([k]) => areEqual(k, keys));
        if (entry === undefined) {
            entry = [
                keys,
                this.addEntity(
                    "combine",
                    new CombineHandler(
                        paths[0],
                        uniquePaths.map(p => ({
                            kind: "pass-through",
                            path: p,
                        }))
                    ),
                    forCurrentColumnOnly
                ),
            ];
            entries.push(entry);
        }

        return entry[1];
    }

    public setActionScopeRow(scopeID: string, tableName: TableName, rowID: string): string | undefined {
        let scopeInfo = this._actionScopeInfo.get(scopeID);
        if (scopeInfo === undefined) {
            const tablePath = this._tableBasePaths.get(tableName);
            if (tablePath === undefined) return "Table for scope not found";

            const rowIDHandler = new MutableLocalValueHandler();
            const rowIDPath = this.addEntity(`actionScopeRowID(${scopeID})`, rowIDHandler, false);
            const rowHandler = new GetRowHandler(tablePath, rowIDPath);
            const rowPath = this.addEntity(`actionScope(${scopeID})`, rowHandler, false);
            scopeInfo = {
                tableName,
                rowIDHandler,
                rowPath,
                lookupInfos: new DefaultMap(() => new Map()),
                columnPaths: new DefaultMap(() => new ArrayMap(shallowEqualArrays)),
            };
            this._actionScopeInfo.set(scopeID, scopeInfo);
        }
        scopeInfo.rowIDHandler.setValue(rowID);
        return undefined;
    }

    private buildComputedColumn(
        tac: TableAndColumn,
        columnNameOverride: string | undefined,
        gbtComputedColumnsAlpha: boolean,
        gbtDeepLookups: boolean
    ): ColumnBuildSuccess | BuildResult {
        const helper: ColumnBuilderHelper = {
            inspector: this._inspector,
            tableBasePaths: this._tableBasePaths,
            appEnvironment: this._appEnvironment,
            pluginMetadata: this._pluginMetadata,
            makeRandomID: () => this.makeRandomID(),
            lookupTableAndColumn: (...args) => this.lookupTableAndColumn(...args),
            makePathForConstant: (...args) => this.makePathForConstant(...args),
            makePathForSourceColumn: (...args) => this.makePathForSourceColumn(...args),
            makePathForColumnOrValue: (...args) => this.makePathForColumnOrValue(...args),
            makePathForSpecialValue: (...args) => this.makePathForSpecialValue(...args),
            makePathsForQueryParameters: (...args) => this.makePathsForQueryParameters(...args),
            getPluginComputation: (...args) => this.getPluginComputation(...args),
            combineTablePaths: (...args) => this.combineTablePaths(...args),
            getVerifiedEmailAddressPath: (...args) => this.getVerifiedEmailAddressPath(...args),
            getTimestampPath: (...args) => this.getTimestampPath(...args),
            getEmptyArrayPath: (...args) => this.getEmptyArrayPath(...args),
            getCurrentLocationPath: (...args) => this.getCurrentLocationPath(...args),
            getRandomOrderSerialPath: (...args) => this.getRandomOrderSerialPath(...args),
            getPathsForSingleValueOffset: (...args) => this.getPathsForSingleValueOffset(...args),
            getStartOrEndOfTodayPath: (...args) => this.getStartOrEndOfTodayPath(...args),
            getFirstRowOfTable: (...args) => this.getFirstRowOfTable(...args),
            getLastRowOfTable: (...args) => this.getLastRowOfTable(...args),
            getAggregateComputationForSingleValuePosition: (...args) =>
                this.getAggregateComputationForSingleValuePosition(...args),
            extractRow: (...args) => this.extractRow(...args),
            addEntity: (...args) => this.addEntity(...args),
        };

        const builder = new ColumnBuilder(tac, columnNameOverride, helper, this._errorAccumulators, {
            gbtComputedColumnsAlpha,
            gbtDeepLookups,
            throwComputationErrors: this._options.throwComputationErrors !== false,
        });

        const irregularColumn = builder.buildIrregularColumn();
        if (irregularColumn !== undefined) {
            return irregularColumn;
        }

        return builder.buildRegularColumn();
    }

    private buildFormatForPathAndColumn(
        tac: TableAndColumn,
        info: PathForColumn,
        displayFormula: Formula,
        entityName: string
    ): PathForColumn | undefined {
        const spec = decomposeFormatFormula(displayFormula);
        if (spec === undefined) return undefined;

        let inputPath: Path;
        let inputTablePath: RootPath | undefined;
        if (info.isGlobal) {
            inputPath = info.valuePath;
        } else {
            inputPath = info.valuePath;
            inputTablePath = info.tablePath;
        }

        let handler: Handler;
        let columnName: string | undefined;
        let usesThunks: boolean;

        if (spec.kind === ValueFormatKind.Number || spec.kind === ValueFormatKind.Duration) {
            const formatter =
                spec.kind === ValueFormatKind.Number
                    ? new FormatNumberComputation(inputPath, spec)
                    : new FormatDurationComputation(inputPath, spec);

            const computation =
                tac.column.type.kind === "array" ? new FormatArrayComputation(inputPath, formatter) : formatter;

            if (inputTablePath !== undefined) {
                columnName = this.makeRandomID();
                handler = new ComputationComputedColumnHandler(
                    inputTablePath,
                    columnName,
                    computation,
                    info.usesThunks
                );
                usesThunks = info.usesThunks;
            } else {
                handler = new ComputationHandler(computation);
                usesThunks = false;
            }
        } else if (spec.kind === ValueFormatKind.DateTime) {
            const computation = new FormatDateTimeComputation(inputPath, spec);
            if (inputTablePath !== undefined) {
                columnName = this.makeRandomID();
                handler = new AsyncComputationComputedColumnHandler(
                    inputTablePath,
                    columnName,
                    computation,
                    (rowID: string, error: ComputationError | undefined) => {
                        const uniqueTac = this.getUniqueTableAndColumn(tac);
                        if (uniqueTac === undefined) return;

                        const errorAccumulator = this._errorAccumulators.get(uniqueTac);

                        if (error === undefined) {
                            errorAccumulator.byRowID.delete(rowID);
                        } else {
                            errorAccumulator.byRowID.set(rowID, error);
                        }
                    }
                );
                usesThunks = true;
            } else {
                handler = new AsyncComputationHandler(computation, (error: ComputationError | undefined) => {
                    const uniqueTac = this.getUniqueTableAndColumn(tac);
                    if (uniqueTac === undefined) return;

                    const errorAccumulator = this._errorAccumulators.get(uniqueTac);

                    errorAccumulator.global = error;
                });
                usesThunks = false;
            }
        } else if (spec.kind === ValueFormatKind.JSON) {
            const elementFormatter = new FormatJSONComputation(inputPath);
            const computation =
                tac.column.type.kind === "array"
                    ? new FormatArrayComputation(inputPath, elementFormatter)
                    : elementFormatter;
            if (inputTablePath !== undefined) {
                columnName = this.makeRandomID();
                handler = new ComputationComputedColumnHandler(
                    inputTablePath,
                    columnName,
                    computation,
                    info.usesThunks
                );
                usesThunks = info.usesThunks;
            } else {
                handler = new ComputationHandler(computation);
                usesThunks = false;
            }
        } else {
            return assertNever(spec);
        }

        const path = this.addEntity(entityName, handler, true);

        if (inputTablePath === undefined) {
            return {
                isGlobal: true,
                valuePath: path,
                tablePath: undefined,
                canBeDeleted: true,
                usesThunks: false,
                makesQuery: info.makesQuery,
                fromQuery: info.fromQuery,
                isSlow: info.isSlow,
                hasCustomOrder: info.hasCustomOrder,
            };
        } else {
            return {
                isGlobal: false,
                tablePath: path,
                valuePath: makeKeyPath(defined(columnName)),
                tableColumnPath: amendPath(path, { c: defined(columnName) }),
                usesThunks,
                makesQuery: info.makesQuery,
                fromQuery: info.fromQuery,
                isSlow: info.isSlow,
                hasCustomOrder: info.hasCustomOrder,
            };
        }
    }

    private buildFormat(
        tac: TableAndColumn,
        displayFormula: Formula,
        allowMissingColumn: boolean
    ): PathForColumn | undefined {
        const info = this.lookupTableAndColumn(tac.table, tac.column.name, {
            countAsDependency: false,
        });
        if (isBuildResult(info)) {
            assert(allowMissingColumn && info.kind === BuildResultKind.Faulty);
            return undefined;
        }

        const entityName = `format(${prettyPrintTableAndColumn(tac)})`;

        return this.buildFormatForPathAndColumn(tac, info, displayFormula, entityName);
    }

    private buildTimeZoneConvert(tac: TableAndColumn, timeZone: GlideDateTimeZone): PathForColumn | undefined {
        assert(!isComputedColumn(tac.column));

        const info = this.lookupTableAndColumn(tac.table, tac.column.name, {
            countAsDependency: false,
            forceBaseColumn: true,
        });
        // This can happen when we're configuring a new column
        if (isBuildResult(info)) return undefined;

        // We only do this for data columns, which can't be global or thunks
        // (they can be from a query).
        assert(!info.isGlobal && !info.usesThunks);

        let handler: Handler;
        let entityName: string;

        const columnName = this.makeRandomID();
        if (timeZone === "agnostic") {
            const computation = new MakeTimeZoneAgnosticComputation(info.valuePath);
            handler = new ComputationComputedColumnHandler(info.tablePath, columnName, computation, false);
            entityName = `makeTimeZoneAgnostic(${prettyPrintTableAndColumn(tac)})`;
        } else if (timeZone === "local") {
            const computation = new ParseTimeZoneAwareDateTimeComputation(info.valuePath);

            handler = new AsyncComputationComputedColumnHandler(
                info.tablePath,
                columnName,
                computation,
                (rowID: string, error: ComputationError | undefined) => {
                    const uniqueTac = this.getUniqueTableAndColumn(tac);

                    if (uniqueTac === undefined) return;

                    if (error === undefined) {
                        this._errorAccumulators.get(uniqueTac).byRowID.delete(rowID);
                    } else {
                        this._errorAccumulators.get(uniqueTac).byRowID.set(rowID, error);
                    }
                }
            );
            entityName = `parseTimeZoneAwareDateTime(${prettyPrintTableAndColumn(tac)})`;
        } else {
            return assertNever(timeZone);
        }
        const path = this.addEntity(entityName, handler, true);
        return {
            isGlobal: false,
            tablePath: path,
            valuePath: makeKeyPath(columnName),
            tableColumnPath: amendPath(path, { c: columnName }),
            // We only do this from base column, which never use thunks
            usesThunks: false,
            makesQuery: false,
            fromQuery: isBigTableOrExternal(tac.table),
            isSlow: false,
            hasCustomOrder: false,
        };
    }

    // `tables` is all the tables we're building columns for.  That will
    // usually be `this.getTables()`, but when we're adding a new temporary
    // table, we're only building columns for that one table.
    private buildSpecificComputedColumns(
        tablesAndColumns: readonly TableAndColumn[],
        tables: readonly TableGlideType[]
    ): ColumnBuildResult {
        const toDo = new Set(tablesAndColumns);

        let haveMissing = false;
        let haveErrors = false;
        for (;;) {
            const numToDo = toDo.size;
            if (numToDo === 0) break;
            for (const tac of Array.from(toDo)) {
                this.withCurrentTableAndColumn(tac, () => {
                    this._tableAndColumns.get(getTableName(tac.table)).set(tac.column, tac);
                    // `gbtComputedColumnsAlpha` and `gbtDeepLookups` are
                    // always enabled here because it's building columns that
                    // already exist.
                    const result = this.buildComputedColumn(tac, undefined, true, true);

                    if (isBuildResult(result) && result.kind === BuildResultKind.WaitingOnDependency) {
                        // logInfo("   ", prettyPrintTableAndColumn(tac));

                        this._columnPaths.set(tac, result);
                    } else {
                        if (!isBuildResult(result)) {
                            // We delete first so that `_columnPaths` is the
                            // correct order of computation.
                            this._columnPaths.delete(tac);
                            this._columnPaths.set(tac, result);

                            // logInfo("***", prettyPrintTableAndColumn(tac));

                            const displayFormula = getEffectiveDisplayFormulaForColumn(
                                this._inspector,
                                tac.table,
                                tac.column,
                                new Set()
                            );
                            if (displayFormula !== undefined) {
                                const formatPath = this.buildFormat(tac, displayFormula, false);
                                if (formatPath !== undefined) {
                                    this._formatPaths.set(tac, formatPath);
                                }
                            }
                        } else if (
                            result.kind === BuildResultKind.Faulty ||
                            result.kind === BuildResultKind.NotImplemented
                        ) {
                            // logInfo("---", prettyPrintTableAndColumn(tac));

                            this._columnPaths.delete(tac);
                            this._columnPaths.set(tac, {
                                isGlobal: true,
                                valuePath: this.getUndefinedPath(),
                                tablePath: undefined,
                                canBeDeleted: false,
                                usesThunks: false,
                                makesQuery: false,
                                fromQuery: false,
                                isSlow: false,
                                hasCustomOrder: false,
                            });

                            if (result.kind === BuildResultKind.NotImplemented) {
                                haveMissing = true;
                            } else {
                                haveErrors = true;
                            }
                        } else {
                            return panic("We should have handled all the cases above");
                        }
                        toDo.delete(tac);
                    }

                    return isBuildResult(result) ? result : undefined;
                });
            }
            if (toDo.size === numToDo) {
                if (numToDo > 0) {
                    haveMissing = true;
                }
                break;
            }
        }

        for (const table of tables) {
            let tablePath = defined(this._tableBasePaths.get(getTableName(table)));
            const columnPaths: RootPath[] = [];
            for (const [tac, entry] of this._columnPaths.entries()) {
                if (tac.table !== table) continue;
                // This can happen if we have a dependency cycle.
                if (isBuildResult(entry)) continue;
                if (entry.isGlobal) continue;

                columnPaths.push(entry.tablePath);
            }

            for (const [tac, entry] of this._formatPaths.entries()) {
                if (tac.table !== table) continue;

                if (!entry.isGlobal) {
                    columnPaths.push(entry.tablePath);
                }
            }

            tablePath = this.combineTablePaths([tablePath, ...columnPaths], false);
            this._tableFullPaths.set(getTableName(table), tablePath);
        }

        const dependencyGraph = makeGraphFromEdges(this._dependenciesForColumn);
        const cycleNodes = getCycleNodesInGraph(dependencyGraph);
        if (cycleNodes !== undefined) {
            const cycles = getCyclesInGraph(dependencyGraph, cycleNodes);
            assert(cycles.length > 0);
            logError("Computed columns contain cycles");
            for (const cycle of cycles) {
                logError(cycle.map(n => `"${prettyPrintTableAndColumn(n)}"`).join(" -> "));
            }
        }

        const symbolicRepresentation = this.ns.getSymbolicRepresentation(m =>
            definedMap(m.tableAndColumn, prettyPrintTableAndColumn)
        );
        for (const line of symbolicRepresentation) {
            logInfo(line);
        }

        return {
            allColumnsBuilt: !haveMissing,
            errors: haveErrors,
            dependencyCycles: cycleNodes !== undefined,
            symbolicRepresentation,
        };
    }

    private buildFormatForBasicColumn(tac: TableAndColumn): boolean {
        const { table, column } = tac;

        if (isSheetArrayType(column.type) !== undefined) return false;
        if (isComputedColumn(column)) return false;
        if (isOldStyleRelation(column) !== undefined) return false;

        const displayFormula = getDisplayFormulaForColumn(column);

        // We're potentially double-setting it here, but `tac`
        // doesn't change, so it's fine.
        this._tableAndColumns.get(getTableName(table)).set(column, tac);
        this.withCurrentTableAndColumn(tac, () => {
            if (displayFormula !== undefined) {
                const formatPath = this.buildFormat(tac, displayFormula, false);
                if (formatPath !== undefined) {
                    this._formatPaths.set(tac, formatPath);
                }
            }
            return undefined;
        });
        return true;
    }

    // Returns whether all computed columns have been built and whether there were dependency cycles
    public buildComputedColumns(): ColumnBuildResult {
        if (this._columnBuildResult !== undefined) {
            return this._columnBuildResult;
        }

        // It seems weird that we need this, but it also can't hurt.
        this._cachedColumnPaths.clear();

        const tablesAndColumns: TableAndColumn[] = [];

        for (const table of this.getTables()) {
            for (const column of table.columns) {
                const tac: TableAndColumn = { table, column };
                const displayFormula = getDisplayFormulaForColumn(column);

                const formatSpec = definedMap(displayFormula, decomposeFormatFormula);
                if (
                    !isComputedColumn(column) &&
                    isDateTimeTypeKind(column.type.kind) &&
                    formatSpec?.kind === ValueFormatKind.DateTime
                ) {
                    this._tableAndColumns.get(getTableName(table)).set(column, tac);
                    this.withCurrentTableAndColumn(tac, () => {
                        const columnPath = this.buildTimeZoneConvert(tac, formatSpec.timeZone);
                        assert(columnPath !== undefined);
                        this._columnPaths.set(tac, columnPath);
                        return undefined;
                    });
                    // we fall through here because we probably still have to
                    // add the format below
                }

                const didBuildFormat = this.buildFormatForBasicColumn(tac);
                if (didBuildFormat) {
                    continue;
                }

                tablesAndColumns.push(tac);
            }
        }

        this._columnBuildResult = this.buildSpecificComputedColumns(tablesAndColumns, this.getTables());

        return this._columnBuildResult;
    }

    // `column` must not be included in the table.  It can have the same name
    // as an existing one, but it must not be the same object.
    public buildTemporaryComputedColumn(
        tableName: TableName,
        column: TableColumn,
        columnIsNew: boolean
    ): TemporaryComputedColumn | ColumnBuildMessage {
        assert(this._tableBasePaths.has(tableName));
        const table = defined(this.findTable(tableName));
        assert(!table.columns.includes(column));

        const tac: TableAndColumn = { table, column };

        let pathForValue: PathForColumn | undefined;
        let pathForFormat: PathForColumn | undefined;
        let info: ColumnInfo | undefined;
        let warningMessage: ColumnBuildMessage | undefined;

        const buildResult = this.withCurrentTableAndColumn(tac, () => {
            if (isComputedColumn(column)) {
                const maybePathForValue = this.buildComputedColumn(
                    tac,
                    this.makeRandomID(),
                    this.gbtComputedColumnsAlpha,
                    this.gbtDeepLookups
                );
                if (isBuildResult(maybePathForValue)) return maybePathForValue;

                pathForValue = maybePathForValue;
                info = {
                    makesQuery: maybePathForValue.makesQuery,
                    fromQuery: maybePathForValue.fromQuery,
                    isSlow: maybePathForValue.isSlow,
                    isGlobal: maybePathForValue.isGlobal,
                    hasCustomOrder: maybePathForValue.hasCustomOrder,
                };
                if (maybePathForValue.warningMessage !== undefined) {
                    warningMessage = { message: maybePathForValue.warningMessage, docURL: getDocURL("bigTables") };
                }
            } else {
                info = {
                    makesQuery: false,
                    fromQuery: isBigTableOrExternal(table),
                    isSlow: false,
                    isGlobal: false,
                    hasCustomOrder: false,
                };
            }

            const displayFormula = getEffectiveDisplayFormulaForColumn(this._inspector, table, column);
            if (displayFormula !== undefined) {
                const formatSpec = decomposeFormatFormula(displayFormula);

                // `pathForValue` is defined for computed columns, for which
                // we don't have to do time zone conversion.
                if (
                    pathForValue === undefined &&
                    isDateTimeTypeKind(column.type.kind) &&
                    formatSpec?.kind === ValueFormatKind.DateTime
                ) {
                    pathForValue = this.buildTimeZoneConvert(tac, formatSpec.timeZone);
                }

                if (pathForValue === undefined) {
                    pathForFormat = this.buildFormat(tac, displayFormula, columnIsNew);
                } else {
                    pathForFormat = this.buildFormatForPathAndColumn(
                        tac,
                        pathForValue,
                        displayFormula,
                        `format(${prettyPrintTableAndColumn(tac)})`
                    );
                }
            }

            return undefined;
        });

        if (buildResult !== undefined) {
            assert(pathForValue === undefined && pathForFormat === undefined && info === undefined);
            return buildResult.message;
        } else {
            assert(info !== undefined);
        }

        return { pathForValue, pathForFormat, info, warningMessage };
    }

    public deleteTemporaryComputedColumn(t: TemporaryComputedColumn): void {
        const deleteIfNecessary = (p: PathForColumn) => {
            if ((p.isGlobal && p.canBeDeleted) || !p.isGlobal) {
                const rootPath = getRootPathForPathForColumn(p);
                // We check for this because ##computationModelKeepsChanging
                // and we're too lazy to fix that properly.
                if (!this.ns.hasPath(rootPath)) return;
                this.ns.deleteEntity(rootPath);
            }
        };

        if (t.pathForFormat !== undefined) {
            deleteIfNecessary(t.pathForFormat);
        }
        if (t.pathForValue !== undefined) {
            deleteIfNecessary(t.pathForValue);
        }
    }

    public addTemporaryTable(table: TableGlideType): [ColumnBuildResult, ActionTableKeeper] {
        const tableName = getTableName(table);
        assert(this.findTable(tableName) === undefined);

        this.buildComputedColumns();

        // Add the keeper to the namespace
        const keeper = new SimpleTableKeeper();
        const displayName = sheetNameForTable(table);
        const keeperPath = this.addEntity(displayName, keeper, false);
        this._tableBasePaths.set(tableName, keeperPath);

        // Add the non-computed columns, and gather the computed ones
        const tablesAndColumns: TableAndColumn[] = [];
        for (const c of table.columns) {
            const tac: TableAndColumn = { table, column: c };
            if (!isComputedColumn(c)) {
                this._tableAndColumns.get(tableName).set(c, tac);
                this.buildFormatForBasicColumn(tac);
                continue;
            }
            tablesAndColumns.push({ table, column: c });
        }

        // Build the computed columns
        const result = this.buildSpecificComputedColumns(tablesAndColumns, [table]);

        const columnPaths = this.buildColumnPathsForTable(table);
        assert(columnPaths !== undefined);

        this._temporaryTables.set(tableName, table);

        return [result, keeper];
    }

    public addTemporaryTableKeeper(name: string, keeper: Handler): RootPath {
        return this.ns.addEntity(name, keeper, undefined);
    }

    public deleteTemporaryTableKeeper(path: RootPath): void {
        this.ns.deleteEntity(path);
    }

    public setColumnInRow(table: TableGlideType, rowIndex: RowIndex, columnName: string, value: GroundValue): void {
        this.buildComputedColumns();

        const keeper = this.tableKeeperStore.getTableKeeperForTable(getTableName(table));
        keeper.updateRowWithPartialData(rowIndex, { [columnName]: value });
    }

    public getColumnsInComputationOrder(): readonly TableAndColumn[] {
        this.buildComputedColumns();

        return mapFilterUndefined(this._columnPaths, ([k, v]) => (isBuildResult(v) ? undefined : k));
    }

    private getAllColumnPaths(): void {
        for (const v of this._columnPaths.values()) {
            if (isBuildResult(v)) continue;
            this.ns.get(getRootPathForPathForColumn(v));
        }
        for (const path of this._tableFullPaths.values()) {
            this.ns.get(path);
        }
    }

    public getPathForTable(tableName: TableName): RootPath | undefined {
        this.buildComputedColumns();
        for (const [table, path] of this._tableFullPaths.entries()) {
            if (areTableNamesEqual(tableName, getTableName(table))) {
                return path;
            }
        }
        return undefined;
    }

    public getBasePathForTable(tableName: TableName): RootPath | undefined {
        this.buildComputedColumns();
        return this._tableBasePaths.get(tableName);
    }

    public getPathsForTableOrQuery(
        tableName: TableName,
        queryLimit: number | undefined
    ): [RootPath, RootPath] | undefined {
        const table = this.findTable(tableName);
        if (table === undefined) return undefined;

        // This is the path for the "full table", which includes all computed
        // columns as dependencies, so whenever anything in the table changes,
        // this path will be dirtied.
        const pathForTable = this.getPathForTable(tableName);
        if (pathForTable === undefined) return undefined;

        if (isBigTableOrExternal(table) && getFeatureSetting("queriesInComputationModel")) {
            // The path for the query points directly to the query handler,
            // which doesn't depend on any computed column handlers, so it
            // won't get any dirt from computed columns.  That's why we also
            // return the full table path, so the caller can get notified
            // whenever anything changes.
            const dataPath = this.getPathForQuery(new Query(tableName).withLimit(queryLimit ?? 1000));
            if (dataPath === undefined) return undefined;

            return [dataPath, pathForTable];
        } else {
            return [pathForTable, pathForTable];
        }
    }

    // This is mainly used in the Data Editor to get table data.  We also use
    // it when getting user profiles.
    public getDataForTable(
        tableName: TableName,
        queryLimit?: number,
        onChange?: () => void
    ): Table | LoadingValue | undefined {
        const table = this.findTable(tableName);
        if (table === undefined) return undefined;

        const path = this.getPathForTable(tableName);
        if (path === undefined) return undefined;

        if (isBigTableOrExternal(table)) {
            if (this._queryFetcher === undefined || onChange === undefined) return undefined;

            const query = new Query(tableName).withLimit(queryLimit ?? 1000);
            let result: ReturnType<typeof this.ns.get>;
            if (getFeatureSetting("queriesInComputationModel")) {
                const queryPath = this.getPathForQuery(query);
                if (queryPath === undefined) return undefined;

                result = this.ns.get(queryPath);
            } else {
                result = this._queryFetcher.fetchQuery(query, onChange);
            }

            // Run all computations
            this.ns.get(path);
            return loadedDefinedMap(result, asTable);
        }

        const tableValue = this.ns.get(path);
        if (tableValue === undefined || isLoadingValue(tableValue)) return tableValue;
        return asTable(tableValue);
    }

    public getBaseDataForTable(tableName: TableName): Table | LoadingValue | undefined {
        const path = this.getBasePathForTable(tableName);
        if (path === undefined) return undefined;

        const tableValue = this.ns.get(path);
        if (tableValue === undefined) {
            // This can happen when callbacks are called in the "wrong" order.
            assert(this.ns.isRetired);
            return undefined;
        }

        if (isLoadingValue(tableValue)) return tableValue;
        return asTable(tableValue);
    }

    public getInfoForColumn(tableName: TableName, columnName: string): ColumnInfo | undefined {
        // The computation model doesn't handle special tables (i.e. comments).
        if (tableName.isSpecial) return undefined;

        const info = this.lookupTableAndColumn(tableName, columnName);
        if (isBuildResult(info)) return undefined;
        return {
            makesQuery: info.makesQuery,
            fromQuery: info.fromQuery,
            isSlow: info.isSlow,
            isGlobal: info.isGlobal,
            hasCustomOrder: info.hasCustomOrder,
        };
    }

    public getPathForLocalTable(tableName: TableName): RootPath | undefined {
        const tableKeeperStore = this._appEnvironment?.localDataStore?.tableKeeperStore;
        if (tableKeeperStore === undefined) return undefined;

        let path = this._localTableKeeperPaths.get(tableName);
        if (path === undefined) {
            const keeper = tableKeeperStore.getTableKeeperForTable(tableName);
            path = this.ns.addEntity(`local table ${tableName.name}`, keeper, undefined);
            this._localTableKeeperPaths.set(tableName, path);
        }
        return path;
    }

    // Returns map from column name to the column's paths
    public getColumnPaths(tableName: TableName): ReadonlyMap<string, ValueAndFormatPaths> | undefined {
        const existing = this._cachedColumnPaths.get(tableName);
        if (existing !== undefined) {
            return existing;
        }

        const table = this.findTable(tableName);
        if (table === undefined) return undefined;

        return this.buildColumnPathsForTable(table);
    }

    private buildColumnPathsForTable(table: TableGlideType): ReadonlyMap<string, ValueAndFormatPaths> | undefined {
        const tableName = getTableName(table);

        const baseRootPath = this.getBasePathForTable(tableName);
        if (baseRootPath === undefined) return undefined;

        const result = new Map<string, ValueAndFormatPaths>();

        const entries = this._tableAndColumns.get(tableName);
        if (entries !== undefined) {
            for (const tac of entries.values()) {
                const valueInfo = this._columnPaths.get(tac);
                if (isBuildResult(valueInfo)) continue;

                const formatInfo = this._formatPaths.get(tac);

                result.set(
                    tac.column.name,
                    makeValueAndFormatPaths(tac.column.name, valueInfo, formatInfo, baseRootPath)
                );
            }
        }

        for (const { name } of table.columns) {
            if (result.has(name)) continue;

            result.set(name, [
                [makePath(name), baseRootPath],
                [undefined, undefined],
            ]);
        }

        this._cachedColumnPaths.set(tableName, result);

        return result;
    }

    public getValueAtPath(context: GroundValue, path: Path): GroundValue {
        return getValueAtPath(this.ns, context, path);
    }

    private getAllTableData(): Map<TableGlideType, Table> {
        this.buildComputedColumns();

        const tableData = new Map<TableGlideType, Table>();
        for (const [tableName, path] of this._tableFullPaths.entries()) {
            const table = this.findTable(tableName);
            if (table === undefined) continue;
            let tableValue = this.ns.get(path);
            if (isLoadingValue(tableValue)) continue;
            tableValue = asTable(tableValue);
            tableData.set(
                table,
                new Table(
                    mapFilter(tableValue, r => r.$isVisible),
                    tableValue
                )
            );
        }
        return tableData;
    }

    public getUserProfileRowPath(): RootPath | undefined {
        this.buildComputedColumns();

        const path = this.buildUserProfileRowPath();
        if (isBuildResult(path)) return undefined;

        return path;
    }

    public getPathForQuery(query: Query): RootPath | undefined {
        const table = this.findTable(query.tableName);
        if (table === undefined) return undefined;

        const isQueryable = isBigTableOrExternal(table);
        if (isQueryable) {
            if (!getFeatureSetting("queriesInComputationModel")) return undefined;

            // We don't handle aggregate queries in the computation model for
            // queryables.
            if (query.serialize().groupBy !== undefined) return undefined;
        } else {
            // If we need to run a query for non-queryables, we do it locally
            // via `RunQueryLocallyHandler`.
        }

        const tablePath = this.getBasePathForTable(query.tableName);
        if (tablePath === undefined) return undefined;

        return this._queriesManager.getPathForQuery(query, () => {
            const columnPaths = new Map<string, Path>();
            const rootPaths: RootPath[] = [tablePath];
            let error: BuildResult | undefined;
            let didDeleteColumns = false;
            const fixupQuery = modifyQueryColumns(
                query.serialize(),
                (cn: string, _sn: (n: string) => void, del: () => void) => {
                    const info = this.lookupTableAndColumn(query.tableName, cn, {
                        countAsDependency: false,
                    });
                    if (isBuildResult(info)) {
                        error = info;
                        return;
                    }
                    if (info.makesQuery) {
                        // This column makes a query, so we don't want to use
                        // it while fixing up the containing query locally.
                        del();
                        didDeleteColumns = true;
                        return;
                    }
                    if (info.isGlobal) {
                        columnPaths.set(cn, info.valuePath);
                    } else {
                        rootPaths.push(info.tablePath);
                        columnPaths.set(cn, info.valuePath);
                    }
                }
            );
            if (isBuildResult(error)) return undefined;
            const combine = this.combineTablePaths(rootPaths, false);
            if (isBigTableOrExternal(table)) {
                return new FixupQueryHandler(
                    query,
                    didDeleteColumns ? new Query(query.tableName, fixupQuery) : undefined,
                    combine,
                    columnPaths,
                    table.columns,
                    () => this._queryFetcher?.getLocallyModifiedRowIDs(query.tableName) ?? new Set()
                );
            } else {
                return new RunQueryLocallyHandler(query, combine, columnPaths, table.columns);
            }
        });
    }

    public cleanUpUnusedQueryHandlers(): void {
        this._queriesManager.cleanUpUnusedQueryHandlers();
    }

    public timeRecompute(writeFile?: (s: string) => void): Map<TableGlideType, Table> {
        this.buildComputedColumns();

        const recompute = (name: string, count: number = 1, before?: () => void) => {
            this.ns.resetTiming();
            const [time] = withStopwatch(() => {
                for (let i = 0; i < count; i++) {
                    before?.();
                    this.getAllColumnPaths();
                }
            });
            logInfo(name, "speed in row/s", (this._numRowsAfterAdding * count) / (time / 1000));
        };

        recompute("after adding", undefined);

        recompute("full recompute", undefined, () => this.ns.setAllDirty());

        if (writeFile !== undefined) {
            recompute("no changes");
            recompute("from scratch", 10, () => this.ns.setAllDirty());

            writeFile(this.ns.makeGraphviz());

            if (printTables) {
                // FIXME: We should really get it via its path, not via the
                // keeper.
                for (const [tableName, path] of this._tableBasePaths.entries()) {
                    const tableValue = this.ns.get(path);
                    if (isLoadingValue(tableValue)) continue;

                    tableForEach(asTable(tableValue), false, r => {
                        const table = this.findTable(tableName);
                        if (table === undefined) return;

                        logInfo("===", sheetNameForTable(table), r.$rowID);
                        for (const c of table.columns) {
                            const v = getRowColumn(r, c.name);
                            if (v === undefined) continue;
                            logInfo(getTableColumnDisplayName(c), ":", v);
                        }
                        for (const [tac, globalPath] of this._columnPaths.entries()) {
                            if (isBuildResult(globalPath)) continue;
                            if (!globalPath.isGlobal || !areTableNamesEqual(tableName, getTableName(tac.table)))
                                continue;
                            const v = this.ns.get(globalPath.valuePath);
                            logInfo("*", getTableColumnDisplayName(tac.column), ":", v);
                        }
                    });
                }
            }
        }

        return this.getAllTableData();
    }

    public recompute(
        setAllDirty: boolean,
        printTiming: boolean,
        useStrictMode: boolean,
        timeout: number | undefined
    ): RecomputeResult {
        if (useStrictMode) {
            this.ns.setStrictMode(true);
        } else {
            this.ns.setStrictMode(false);
        }

        this.buildComputedColumns();

        if (setAllDirty) {
            this.ns.setAllDirty();
        }

        this.ns.resetTiming(printTiming, true);

        let tableData: Map<TableGlideType, Table> | undefined;
        let timedOut = false;

        if (timeout !== undefined) {
            this.ns.setTimeOutAt(Date.now() + timeout);
        }

        try {
            this.getAllColumnPaths();
            tableData = this.getAllTableData();
        } catch (e: unknown) {
            if (e instanceof ComputationTimeoutException) {
                timedOut = true;
            } else {
                throw e;
            }
        } finally {
            this.ns.setTimeOutAt(undefined);
        }

        const stats: RecomputeTiming = new Map();
        if (printTiming) {
            logInfo("*** TIMING ***");

            for (const t of this.ns.getTiming()) {
                logInfo(t);

                const tableAndColumn = t.metadata?.tableAndColumn;

                // NOTE: If a table and column is not in the metadata, store the timing info in a table "unknown" with
                // the column name as the debug name provided when creating the entity
                const tableName = tableAndColumn !== undefined ? getTableName(tableAndColumn.table).name : "unknown";
                const columnName = tableAndColumn !== undefined ? tableAndColumn.column.name : t.name;

                const tableEntry = stats.get(tableName) ?? new Map();
                tableEntry.set(columnName, { time: t.totalTime });
                stats.set(tableName, tableEntry);
            }
        }

        // Reset strict mode
        this.ns.setStrictMode(false);

        return { tableData, stats, timedOut };
    }
}
