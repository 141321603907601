import { asEnum } from "@glide/common-core/dist/js/type-conversions";
import { formatLocalizedString } from "@glide/localization";
import { asString } from "@glide/common-core/dist/js/computation-model/data";
import { getSwitchProperty, type ComponentKind, type MutatingScreenKind } from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { TextEntrySize } from "@glide/component-utils";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    EnumPropertyHandler,
    PropertySection,
    RequiredKind,
    makeNumberPropertyDescriptor,
    getPrimitiveColumnsSpec,
    SwitchPropertyHandler,
} from "@glide/function-utils";
import { checkNumber } from "@glide/support";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import { boundMap } from "@glide/computation-model-types";

import { inflateNumberProperty } from "../wire/utils";
import { type BaseFieldComponentDescription, BaseFieldComponentHandler, defaultFieldComponent } from "./base-field";
import { autofocusPropertyHandler } from "./autofocus-field";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";

const ComponentKindTextField: ComponentKind = "text-field";

const propertyNamePropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
        ColumnPropertyFlag.Searchable,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    undefined,
    getPrimitiveColumnsSpec,
    "string",
    PropertySection.Data
);

interface TextFieldComponentDescription extends BaseFieldComponentDescription {
    size?: TextEntrySize;
    autofocus?: boolean;
    inlineVoiceTranscription?: boolean;
}

const sizePropertyHandler = new EnumPropertyHandler(
    { size: TextEntrySize.Normal },
    "Size",
    "Size",
    [
        {
            value: TextEntrySize.Normal,
            label: "Normal",
            icon: "border3by1",
        },
        {
            value: TextEntrySize.Medium,
            label: "Medium",
            icon: "border3by2",
        },
        {
            value: TextEntrySize.Large,
            label: "Large",
            icon: "borderSquare",
        },
    ],
    PropertySection.Design,
    "small-images"
);

export class TextFieldComponentHandler extends BaseFieldComponentHandler<TextFieldComponentDescription> {
    public readonly appKinds = "both";

    constructor() {
        super(ComponentKindTextField, "string");
    }

    public needValidation(desc: TextFieldComponentDescription): boolean {
        return super.needValidation(desc) || desc.minLength !== undefined || desc.maxLength !== undefined;
    }

    public getDescriptor(
        desc: TextFieldComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [];
        properties.push(
            makeNumberPropertyDescriptor(
                "minLength",
                "Min Length",
                "Number of characters",
                RequiredKind.NotRequiredDefaultMissing,
                0,
                mutatingScreenKind,
                {
                    searchable: false,
                    emptyByDefault: true,
                }
            ),
            makeNumberPropertyDescriptor(
                "maxLength",
                "Max Length",
                "Number of characters",
                RequiredKind.NotRequiredDefaultMissing,
                100,
                mutatingScreenKind,
                {
                    searchable: false,
                    emptyByDefault: true,
                }
            ),
            sizePropertyHandler,
            autofocusPropertyHandler,
            new SwitchPropertyHandler(
                { inlineVoiceTranscription: false },
                "Voice Transcription",
                PropertySection.Options,
                undefined,
                (_d, _r, _t, adc) => {
                    return isExperimentEnabled("inlineAudioTranscript", adc.userFeatures);
                }
            )
        );
        return this.getFieldDescriptor(
            desc,
            ccc,
            "Text Entry",
            "Edit a short piece of text",
            "Entry Fields",
            "co-text-entry",
            propertyNamePropertyHandler,
            false,
            getDocURL("textField"),
            mutatingScreenKind,
            "Text",
            properties
        );
    }

    public inflate(
        ib: WireInflationBackend,
        desc: TextFieldComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const [minGetter] = inflateNumberProperty(ib, desc.minLength);
        const [maxGetter] = inflateNumberProperty(ib, desc.maxLength);
        const [sizeGetter] = ib.getValueGetterForProperty(desc.size, false);
        const autofocus = getSwitchProperty(desc.autofocus);
        const voiceTranscription = getSwitchProperty(desc.inlineVoiceTranscription);
        // Returns 0 if valid, <0 if too short, >0 if too long
        const isValid = (str: string, min: number = 0, max: number = 0): number => {
            if (str === "") return 0;

            if (min > 0 && str.length < min) return -1;
            if (max > 0 && str.length > max) return 1;

            return 0;
        };

        return super.inflateComponent(
            ib,
            desc,
            (base, value, token, hb) => {
                const s = asString(value);
                const minLength = minGetter(hb);
                const maxLength = maxGetter(hb);
                const size = boundMap(sizeGetter(hb), x => asEnum<TextEntrySize>(x)) ?? undefined;
                return {
                    ...base,
                    kind: WireComponentKind.TextField,
                    value: {
                        value: s,
                        onChangeToken: token,
                        error:
                            isValid(s, minLength ?? undefined, maxLength ?? undefined) >= 0
                                ? undefined
                                : formatLocalizedString(
                                      "charactersRequired",
                                      [checkNumber(minLength).toString()],
                                      ib.adc.appKind
                                  ),
                    },
                    size,
                    minChars: minLength,
                    maxChars: maxLength,
                    autofocus,
                    voiceTranscription,
                };
            },
            (value, hb) => {
                const s = asString(value);
                if (s === "") return true;

                const minLength = minGetter(hb) ?? undefined;
                const maxLength = maxGetter(hb) ?? undefined;

                return isValid(s, minLength, maxLength) === 0;
            }
        );
    }

    public static defaultComponent(column: TableColumn): TextFieldComponentDescription {
        return defaultFieldComponent(column, ComponentKindTextField);
    }
}
