import { Result } from "@glide/plugins";
import { maybeParseJSON, type JSONObject, removeUndefinedProperties } from "@glide/support";
import { isLeft } from "fp-ts/lib/Either";
import * as t from "io-ts";
import type { ChatMessages } from "../openai";

type Functions = { name: string; parameters: JSONObject; description?: string }[];

const defaultModel = "gpt-4o";

const chatPromptResponseCodec = t.type({
    choices: t.array(
        t.type({
            message: t.intersection([
                t.type({
                    content: t.union([t.string, t.null]),
                }),
                t.partial({
                    function_call: t.type({
                        arguments: t.string,
                    }),
                }),
            ]),
        })
    ),
    usage: t.type({
        prompt_tokens: t.number,
        completion_tokens: t.number,
        total_tokens: t.number,
    }),
});

type ChatResponse = t.TypeOf<typeof chatPromptResponseCodec>;

type TokenUsage = ChatResponse["usage"];

export class GlideAI {
    constructor(private openAIKey: string, private fetch: typeof globalThis.fetch) {}

    public async completeText(
        prompt: string | undefined,
        message: string | ChatMessages,
        options: Partial<{
            // Model params
            model: string;
            temperature: number;
            max_tokens: number;
            frequency_penalty: number;
            seed: number;

            headers: Record<string, string>;
            functions: Functions;
            functionCall: string;
            jsonMode: boolean;
        }> = {}
    ): Promise<Result<{ value: string } & TokenUsage>> {
        const {
            model = defaultModel,
            temperature,
            max_tokens,
            frequency_penalty,
            seed,
            jsonMode = false,
            headers = {},
            functions,
            functionCall,
        } = options;

        const messages = [];
        if (prompt !== undefined) {
            messages.push({ role: "system", content: prompt.trim() });
        }
        if (typeof message === "string") {
            messages.push({ role: "user", content: message.trim() });
        } else {
            messages.push(...message);
        }

        const responseFormat = jsonMode === true ? { response_format: { type: "json_object" } } : {};

        const body: any = removeUndefinedProperties({
            model,
            temperature,
            max_tokens,
            frequency_penalty,
            seed,

            messages,
            functions,
            ...responseFormat,
        });

        if (functionCall !== undefined) {
            body.function_call = { name: functionCall };
        }

        const response = await this.fetch(`https://api.openai.com/v1/chat/completions`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${this.openAIKey}`,
                "Content-Type": "application/json",
                ...headers,
            },
            redirect: "follow",
            body: JSON.stringify(body),
        });

        const asText = await response.text();
        const asJSON = JSON.parse(asText);

        if (response.ok !== true) {
            try {
                if (asJSON.error.message !== null) {
                    return Result.FailFromHTTPStatus(asJSON.error.message, response.status, {
                        data: maybeParseJSON(asText),
                    });
                }
            } catch {
                return Result.FailFromHTTPStatus(`Unknown error: ${asText}`, response.status, {
                    data: maybeParseJSON(asText),
                });
            }
            return Result.FailFromHTTPStatus("Could not complete chat", response.status, {
                data: maybeParseJSON(asText),
            });
        }

        const decoded = chatPromptResponseCodec.decode(asJSON);
        if (isLeft(decoded)) {
            return Result.Fail(`Could not parse output`, {
                data: asJSON,
            });
        }

        const completion = decoded.right.choices[0].message;
        const messageContent = completion.function_call?.arguments ?? completion.content ?? "";

        return Result.Ok({ value: messageContent.trim(), ...decoded.right.usage });
    }

    public async generateText(
        instructions: string | undefined,
        message: string,
        model = defaultModel
    ): Promise<Result<{ value: string } & TokenUsage>> {
        return this.completeText(instructions, message, {
            model,
            seed: 42,
        });
    }

    public async textToJSON(
        instructions: string | undefined,
        message: string
    ): Promise<Result<{ data: any } & TokenUsage>> {
        const instructionsMustIncludeJSON = `${instructions ?? ""} reply with JSON`;
        const completionResult = await this.completeText(instructionsMustIncludeJSON, message, {
            jsonMode: true,
            seed: 42,
        });

        if (!completionResult.ok) return completionResult;

        try {
            const { value: completion, ...usage } = completionResult.result;
            const data = JSON.parse(completion);
            return Result.Ok({ data, ...usage });
        } catch {
            return Result.Fail(`Could not parse JSON`);
        }
    }

    public async textToTexts(
        instructions: string,
        message: string
    ): Promise<Result<{ values: string[] } & TokenUsage>> {
        const instructionsMustIncludeJSON = [
            `You are extracting text values from text`,
            instructions,
            `Reply with JSON with this type: { values: string[] }`,
        ].join("\n\n");

        const completionResult = await this.textToJSON(instructionsMustIncludeJSON, message);

        if (!completionResult.ok) return completionResult;
        const {
            data: { values },
            ...usage
        } = completionResult.result;

        const isStringArray =
            values !== undefined && Array.isArray(values) && values.every(value => typeof value === "string");
        if (!isStringArray) {
            return Result.FailPermanent(`Could not extract text values from the input text`, {
                isPluginError: true,
            });
        }

        return Result.Ok({ values, ...usage });
    }

    public async textToNumber(
        instructions: string | undefined,
        message: string
    ): Promise<Result<{ number: number } & TokenUsage>> {
        const functionCall = "result";
        const argumentName = "number";
        const functions = [
            {
                name: functionCall,
                parameters: {
                    type: "object",
                    properties: { [argumentName]: { type: "number" } },
                    required: [argumentName],
                },
            },
        ];

        const completionResult = await this.completeText(instructions, message, {
            functions,
            functionCall,
            seed: 42,
        });

        if (!completionResult.ok) return completionResult;

        const {
            result: { value: completion, ...usage },
        } = completionResult;

        let number: number;
        try {
            number = JSON.parse(completion)[argumentName];
        } catch {
            return Result.Fail(`Could not parse JSON`);
        }

        if (typeof number !== "number") {
            return Result.Fail(`Could not parse number`);
        }

        return Result.Ok({ number, ...usage });
    }

    public async textToChoice(
        instructions: string | undefined,
        message: string,
        choices: string[]
    ): Promise<Result<{ value: string } & TokenUsage>> {
        // If the instructions are not provided, we will provide a default message
        instructions ??= "Categorize the message into one of the following categories:";
        // Always include the choices in the instructions
        instructions += `\nReply with exactly one of: ${JSON.stringify(choices)}`;

        const completionResult = await this.completeText(instructions, message);
        if (!completionResult.ok) return completionResult;

        const {
            result: { value: completion, ...usage },
        } = completionResult;

        const longestMatch = choices.filter(c => completion.includes(c)).sort((a, b) => a.length - b.length)[0];

        return Result.Ok({ value: longestMatch ?? "", ...usage });
    }

    public async textToBoolean(
        instructions: string | undefined,
        message: string
    ): Promise<Result<{ value: boolean } & TokenUsage>> {
        instructions ??= "Decide if the message you receive is true or false.";
        const completionResult = await this.textToChoice(instructions, message, ["true", "false"]);

        if (!completionResult.ok) return completionResult;

        const {
            result: { value: completion, ...usage },
        } = completionResult;

        const value = completion === "true";

        return Result.Ok({ value, ...usage });
    }

    // This is promising but not yet used in production, since Dates are tricky
    public async textToDate(
        instructions: string | undefined,
        message: string,
        { now = new Date() }: { now?: Date } = {}
    ): Promise<Result<{ date: Date } & TokenUsage>> {
        const currentDate = now.toUTCString();
        instructions = `
The current date and time is ${currentDate}

Months are represented as numbers from 0-11, where 0 is January and 11 is December.

${instructions ?? ""}
`.trim();

        const functionCall = "answer";
        const functions = [
            {
                name: functionCall,
                parameters: {
                    type: "object",
                    properties: {
                        year: { type: "number" },
                        month: { type: "number" },
                        day: { type: "number" },
                        hours: { type: "number", description: "0-23 (military time)" },
                        minutes: { type: "number" },
                        seconds: { type: "number" },
                    },
                    required: ["year", "month", "day", "hours", "minutes", "seconds"],
                },
            },
        ];

        const completionResult = await this.completeText(instructions, message, {
            functions,
            functionCall,
            seed: 42,
        });

        if (!completionResult.ok) return completionResult;

        const {
            result: { value: completion, ...usage },
        } = completionResult;

        let date: Date | undefined;
        try {
            const { year, month, day, hours, minutes, seconds } = JSON.parse(completion);
            date = new Date(Date.UTC(year, month, day, hours, minutes, seconds));
        } catch {
            return Result.Fail(`Could not parse JSON`);
        }

        if (date === undefined) {
            return Result.Fail(`Could not parse date`);
        }

        return Result.Ok({ date, ...usage });
    }

    public async imageReasoning(
        instructions: string | undefined,
        images: readonly string[]
    ): Promise<Result<{ value: string } & TokenUsage>> {
        const messages = [
            {
                role: "user" as const,
                content: [...images.map(url => ({ type: "image_url" as const, image_url: { url } }))],
            },
        ];

        return await this.completeText(instructions, messages, {
            seed: 42,
        });
    }
}
