import { getBrowserLanguage } from "@glide/support";

export enum DateFormat {
    Short = "short",
    Medium = "medium",
    Long = "long",
    // This is not used by display formulas (yet?)
    WeekdayOnly = "weekday-only",
}

export enum TimeFormat {
    WithoutSeconds = "without-seconds",
    WithSeconds = "with-seconds",
}

export enum DateTimeParts {
    DateOnly = "date-only",
    TimeOnly = "time-only",
    DateTime = "date-time",
}

export interface GlideDateTimeFormatSpecification {
    readonly parts: DateTimeParts;
    readonly dateFormat: DateFormat;
    readonly timeFormat: TimeFormat;
}

// We ##cacheFormatting.
let lastDateFormat: DateFormat | undefined;
let lastTimeFormat: TimeFormat | undefined;
let lastFormatter: Intl.DateTimeFormat | undefined;
let lastTimeZone: string | undefined;
let lastSkipYear: boolean | undefined;

export function makeDateTimeFormat(
    dateFormat: DateFormat | undefined,
    timeFormat: TimeFormat | undefined,
    timeZone: string = "UTC",
    skipYear: boolean = false
): Intl.DateTimeFormat {
    if (
        lastFormatter !== undefined &&
        dateFormat === lastDateFormat &&
        timeFormat === lastTimeFormat &&
        timeZone === lastTimeZone &&
        skipYear === lastSkipYear
    ) {
        return lastFormatter;
    }

    let options: Intl.DateTimeFormatOptions;
    if (dateFormat === DateFormat.Short) {
        options = { day: "numeric", month: "numeric", year: "numeric" };
    } else if (dateFormat === DateFormat.Medium) {
        options = { day: "numeric", month: "long", year: "numeric" };
    } else if (dateFormat === DateFormat.Long) {
        options = { weekday: "long", day: "numeric", month: "long", year: "numeric" };
    } else if (dateFormat === DateFormat.WeekdayOnly) {
        options = { weekday: "long" };
    } else {
        options = {};
    }

    if (skipYear) {
        options = { ...options, year: undefined };
    }

    if (timeFormat === TimeFormat.WithoutSeconds) {
        options = { ...options, hour: "numeric", minute: "2-digit" };
    } else if (timeFormat === TimeFormat.WithSeconds) {
        options = { ...options, hour: "numeric", minute: "2-digit", second: "2-digit" };
    }

    // Using the local time zone to format results in inconsistencies.  The
    // way we convert to time-zone aware date/times is to use the current
    // local time zone offset, but formatting uses the time zone at the
    // current location at the time of the date/time to be formatted.  Because
    // of daylight savings that could be a different time zone than the
    // current one.
    options.timeZone = timeZone;

    lastDateFormat = dateFormat;
    lastTimeFormat = timeFormat;
    lastTimeZone = timeZone;
    lastSkipYear = skipYear;
    lastFormatter = new Intl.DateTimeFormat(getBrowserLanguage(), options);

    return lastFormatter;
}

export function formatDateTime(
    dateTZA: Date,
    dateFormat: DateFormat | undefined,
    timeFormat: TimeFormat | undefined,
    timeZone: string = "UTC",
    skipThisYear: boolean = false
): string | undefined {
    if (isNaN(dateTZA as any)) return undefined;
    const skipYear = skipThisYear && dateTZA.getFullYear() === new Date().getFullYear();
    return makeDateTimeFormat(dateFormat, timeFormat, timeZone, skipYear).format(dateTZA);
}

export function browserTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY; // Approximate month duration
const YEAR = 365 * DAY; // Approximate year duration

export function getFormattedTimeDifference(futureOrPastDate: Date | undefined, now: Date = new Date()): string {
    if (futureOrPastDate === undefined) return "n/a";

    const diffInMs = futureOrPastDate.getTime() - now.getTime();

    const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

    const absDiffInMs = Math.abs(diffInMs);

    if (absDiffInMs < MINUTE) {
        return diffInMs < 0 ? "just now" : "soon";
    } else if (absDiffInMs < HOUR) {
        const minutes = Math.round(diffInMs / MINUTE);
        return rtf.format(minutes, "minute");
    } else if (absDiffInMs < DAY) {
        const hours = Math.round(diffInMs / HOUR);
        return rtf.format(hours, "hour");
    } else if (absDiffInMs < WEEK) {
        const days = Math.round(diffInMs / DAY);
        return rtf.format(days, "day");
    } else if (absDiffInMs < MONTH) {
        const weeks = Math.round(diffInMs / WEEK);
        return rtf.format(weeks, "week");
    } else if (absDiffInMs < YEAR) {
        const months = Math.round(diffInMs / MONTH);
        return rtf.format(months, "month");
    } else {
        const years = Math.round(diffInMs / YEAR);
        return rtf.format(years, "year");
    }
}
