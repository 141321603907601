export function shareOnFacebook(appURL: string) {
    open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(appURL)}`);
}

export function shareOnTwitter(appURL: string, title: string, showBranding: boolean) {
    let titleRaw = `Check out ${title}!`;
    if (showBranding) {
        titleRaw += ` An app made with @glideapps from a Google Sheet, without writing any code.`;
    }
    const titlecomponent = encodeURIComponent(titleRaw);
    open(`https://twitter.com/intent/tweet?text=${titlecomponent}%20${encodeURIComponent(appURL)}`);
}
