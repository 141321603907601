import { CalendarListMode } from "@glide/common-core/dist/js/components/calendar-list-mode";
import { CalendarListOrder } from "@glide/common-core/dist/js/components/calendar-list-order";
import type { MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import type {
    ArrayContentDescription,
    ArrayScreenDescription,
    LegacyPropertyDescription,
    MutatingScreenKind,
    PropertyDescription,
    ScreenDescriptionKind,
} from "@glide/app-description";
import {
    ArrayScreenFormat,
    makeActionProperty,
    PropertyKind,
    getColumnProperty,
    getSourceColumnProperty,
    getSwitchProperty,
    makeArrayProperty,
    makeEnumProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
    getActionProperty,
} from "@glide/app-description";
import { type TableGlideType, getTableColumn, getTableName } from "@glide/type-schema";
import {
    ComponentKindInlineList,
    makeEmptyComponentDescription,
    type InputOutputTables,
} from "@glide/common-core/dist/js/description";
import { DateFormat, DateTimeParts } from "@glide/data-types";
import type {
    WireAppCalendarListComponent,
    WireCalendarListGroup,
    WireCalendarListItem,
} from "@glide/fluent-components/dist/js/base-components";
import type { InlineListComponentDescription } from "@glide/function-utils";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    type PropertyTableGetter,
    EnumPropertyHandler,
    makeStringyColumnPropertyDescriptor,
    PropertySection,
    getPrimitiveNonHiddenColumnsSpec,
} from "@glide/function-utils";
import {
    type WireInflationBackend,
    type WireTableComponentHydratorConstructor,
    type WireAction,
    WireComponentKind,
} from "@glide/wire";
import { assert, defined, mapFilterUndefined } from "@glideapps/ts-necessities";
import { definedMap } from "collection-utils";
import flow from "lodash/fp/flow";
import groupBy from "lodash/fp/groupBy";
import sortBy from "lodash/fp/sortBy";
import { v4 as uuid } from "uuid";
import type { ScreenContext } from "../components/component-handler";
import { getActionsForArrayContent } from "../components/component-utils";
import { makeTitlePropertyDescriptor } from "../components/descriptor-utils";
import { ValueFormatKind, decomposeFormatFormula } from "@glide/formula-specifications";
import {
    type WireStringGetter,
    getAppArrayScreenEmptyMessage,
    hydrateAndRegisterAction,
    inflateActionsWithCanAutoRun,
    inflateDateTimeProperty,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import { ArrayScreenHandlerBase } from "./array-screen";
import type { ComponentScreenContextForConversion } from "./array-content";
import type { CalendarCollectionComponentDescription } from "@glide/fluent-components/dist/js/fluent-components";

interface CalendarListArrayContentDescription extends ArrayContentDescription {
    readonly titleProperty: LegacyPropertyDescription;
    readonly descriptionProperty: PropertyDescription | undefined;

    readonly dateTimeProperty: LegacyPropertyDescription;
    readonly endProperty: PropertyDescription | undefined;

    readonly order: PropertyDescription | undefined;
    readonly defaultModeProperty: PropertyDescription | undefined;
}

interface CalendarListArrayScreenDescription extends ArrayScreenDescription, CalendarListArrayContentDescription {
    readonly kind: ScreenDescriptionKind.Array;
    readonly format: ArrayScreenFormat.CalendarList;
}

const dateTimeProperties: ReadonlyArray<string> = ["date", "date-time", "datetime", "when", "time"];

export function makeEndDateTimePropertyDescriptor(
    getPropertyTable: PropertyTableGetter | undefined,
    section: PropertySection
): PropertyDescriptor {
    return {
        kind: PropertyKind.Column,
        property: { name: "endProperty" },
        section,
        label: "End",
        required: false,
        editable: true,
        searchable: false,
        getIndirectTable: getPropertyTable,
        columnFilter: getPrimitiveNonHiddenColumnsSpec,
        preferredNames: ["end", "finish", "event end", "event finish"],
        preferredType: "date-time",
    };
}

export function makeDescriptionPropertyDescriptor(
    getPropertyTable: PropertyTableGetter | undefined,
    section: PropertySection
): PropertyDescriptor {
    return {
        kind: PropertyKind.Column,
        property: { name: "descriptionProperty" },
        section,
        label: "Description",
        required: false,
        editable: true,
        searchable: true,
        getIndirectTable: getPropertyTable,
        columnFilter: getPrimitiveNonHiddenColumnsSpec,
        preferredNames: ["description", "subtitle", "caption"],
        preferredType: "string",
    };
}

const orderPropertyHandler = new EnumPropertyHandler(
    { order: CalendarListOrder.OldestFirst },
    "Order",
    "Order of events",
    [
        {
            value: CalendarListOrder.OldestFirst,
            label: "Oldest first",
        },
        {
            value: CalendarListOrder.NewestFirst,
            label: "Newest first",
        },
    ],
    PropertySection.Content,
    "dropdown"
);

const defaultModePropertyHandler = new EnumPropertyHandler(
    { defaultModeProperty: CalendarListMode.List },
    "Default Screen",
    "Default view",
    [
        {
            value: CalendarListMode.Calendar,
            label: "Calendar",
            icon: "calendar",
        },
        {
            value: CalendarListMode.List,
            label: "List",
            icon: "list",
        },
    ],
    PropertySection.Design,
    "small-images"
);

function getMustShowTime(desc: CalendarListArrayContentDescription, table: TableGlideType): boolean | undefined {
    const dateTimeProperty = getColumnProperty(desc.dateTimeProperty);
    if (dateTimeProperty === undefined) return undefined;

    const dateTimeColumn = getTableColumn(table, dateTimeProperty);
    if (dateTimeColumn === undefined) return undefined;

    // ##showTimeInCalendar:
    // We don't want to show the time in the Calendar list if the column's
    // format is set to "Date only".  See
    // https://github.com/quicktype/glide/issues/9711
    const formatSpec = definedMap(dateTimeColumn.displayFormula, decomposeFormatFormula);
    if (formatSpec?.kind === ValueFormatKind.DateTime) {
        return formatSpec.parts !== DateTimeParts.DateOnly;
    } else {
        return true;
    }
}

function groupItems(
    items: readonly WireCalendarListItem[],
    order: CalendarListOrder
): readonly WireCalendarListGroup[] {
    const multiplier = order === CalendarListOrder.OldestFirst ? 1 : -1;
    const grouped = flow(
        sortBy((i: WireCalendarListItem) => multiplier * i.start.getTimeZoneAwareValue()),
        // TODO: Remove the items that return `undefined` for format?
        groupBy(
            (i: WireCalendarListItem) => i.start.localStartOfDay().format(DateFormat.Long, undefined, "local") ?? ""
        )
    )(items);
    // lodash returns an object whose keys are the group names,
    // which means that the order of the groups is not preserved,
    // so we have to sort the group keys.
    const groupKeys = sortBy((k: string) => multiplier * grouped[k][0].start.getTimeZoneAwareValue())(
        Object.keys(grouped)
    );

    return groupKeys.map(dateString => ({
        title: dateString,
        items: defined(grouped[dateString]),
        seeAllAction: undefined,
    }));
}

export class CalendarListArrayScreenHandler extends ArrayScreenHandlerBase<
    CalendarListArrayContentDescription,
    CalendarListArrayScreenDescription
> {
    constructor() {
        super(ArrayScreenFormat.CalendarList, "Calendar", "calendar");
    }

    public getBasicSearchProperties(desc: CalendarListArrayContentDescription): readonly string[] {
        const titleProperty = getColumnProperty(desc.titleProperty);
        if (titleProperty === undefined) return [];
        return [titleProperty];
    }

    protected get canReverse(): boolean {
        return false;
    }

    public getNeedsOrder(): boolean {
        return false;
    }

    // TODO: Make sure we support limit when we move to NCM.  The issue is
    // that the calendar itself does the sorting, which means that it happens
    // after limiting, but it has to happen before.
    protected get supportsLimit(): boolean {
        return false;
    }

    public getContentPropertyDescriptors<T extends CalendarListArrayContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined,
        screenContext?: ScreenContext,
        appEnvironment?: MinimalAppEnvironment
    ): readonly PropertyDescriptor[] {
        const descriptors: PropertyDescriptor[] = [
            ...super.getContentPropertyDescriptors(
                getPropertyTable,
                insideInlineList,
                containingScreenTables,
                desc,
                ccc,
                mutatingScreenKind,
                isDefaultArrayScreen,
                withTransforms,
                forEasyTabConfiguration,
                isFirstComponent,
                screenContext,
                appEnvironment
            ),
            makeTitlePropertyDescriptor(getPropertyTable, true, false, PropertySection.Content),
            makeDescriptionPropertyDescriptor(getPropertyTable, PropertySection.Content),
            makeStringyColumnPropertyDescriptor(
                "dateTimeProperty",
                "Start",
                ["required", "editable", "searchable"],
                PropertySection.Content,
                getPropertyTable,
                dateTimeProperties
            ),
            makeEndDateTimePropertyDescriptor(getPropertyTable, PropertySection.Content),
            orderPropertyHandler,
        ];
        if (!insideInlineList) {
            descriptors.push(defaultModePropertyHandler);
        }
        return descriptors;
    }

    // TODO: The OCM `SortAndGroupCalendarItems` parses date/times via the
    // lazily loaded parser (`parseValueAsDate`), which we're not doing here.
    // If that becomes an issue we'll have to make it so that the component
    // gets hydrated again when the parser is loaded.  We can do that by
    // adding a state which is updated when the load is complete.
    public inflateContent<T extends CalendarListArrayContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        captionGetter: WireStringGetter | undefined,
        _containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        const {
            adc: { appKind },
            forBuilder,
        } = ib;

        const [titleGetter, titleType] = inflateStringProperty(ib, desc.titleProperty, true);
        const [descriptionGetter] = inflateStringProperty(ib, desc.descriptionProperty, true);

        const [startGetter, startType] = inflateDateTimeProperty(ib, desc.dateTimeProperty);
        const [endGetter] = inflateDateTimeProperty(ib, desc.endProperty);

        if (titleType === undefined || startType === undefined) return undefined;

        const showTime = getMustShowTime(desc, ib.tables.input);
        if (showTime === undefined) return undefined;

        const defaultMode = defaultModePropertyHandler.getEnum(desc);
        const order = orderPropertyHandler.getEnum(desc);

        const { actionHydrator, canAutoRunAction } = inflateActionsWithCanAutoRun(
            ib,
            getActionsForArrayContent(this, ib.tables, desc, ib.adc).actions
        );

        // We make a new UUID here so that the view resets to the default
        // whenever the user makes an edit in the builder.
        const stateSaveKey = uuid();

        return makeSimpleWireTableComponentHydratorConstructor(ib, (thb, chb, searchActive) => {
            const rows = thb.tableScreenContext.asArray();
            let firstListItemActionToRun: WireAction | undefined;
            const items = mapFilterUndefined(rows, row => {
                const rhb = thb.makeHydrationBackendForRow(row);

                const start = startGetter(rhb);
                if (start === undefined) return undefined;
                assert(start !== null);

                const title = titleGetter(rhb);

                const item: WireCalendarListItem = {
                    title,
                    subtitle: descriptionGetter(rhb),
                    start,
                    end: endGetter(rhb),
                    // It's important that the key is the row ID because we
                    // use it for the ##selectedMasterItemKey.
                    key: row.$rowID,
                    action: hydrateAndRegisterAction("tap", actionHydrator, rhb, false, title ?? undefined),
                };
                if (canAutoRunAction && firstListItemActionToRun === undefined) {
                    firstListItemActionToRun = item.action;
                }
                return item;
            });

            const groups = groupItems(items, order);

            const component: WireAppCalendarListComponent = {
                kind: WireComponentKind.List,
                ...spreadComponentID(componentID, forBuilder),
                format: ArrayScreenFormat.CalendarList,
                title: captionGetter?.(defined(chb)) ?? "",
                defaultMode,
                order,
                emptyMessage: getAppArrayScreenEmptyMessage(searchActive, appKind, "noUpcomingEvents"),
                groups,
                showTime,
                insideInlineList: !thb.isArrayScreen,
                stateSaveKey,
            };
            return {
                component,
                isValid: true,
                firstListItemActionToRun,
            };
        });
    }

    private convertContentToPage(desc: CalendarListArrayContentDescription) {
        const firstAction = Array.isArray(desc.actions) ? desc.actions[0] : desc.actions;
        return {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            format: makeEnumProperty(ArrayScreenFormat.CalendarCollection),
            caption: undefined,
            transforms: desc.transforms,
            action: definedMap(getActionProperty(firstAction), a => makeActionProperty(a)),
            title: definedMap(getSourceColumnProperty(desc.titleProperty), sc => makeSourceColumnProperty(sc)),
            startDate: definedMap(getSourceColumnProperty(desc.dateTimeProperty), sc => makeSourceColumnProperty(sc)),
            endDate: desc.endProperty,
        };
    }

    public convertInlineToPage(
        desc: CalendarListArrayContentDescription & InlineListComponentDescription
    ): CalendarCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: desc.propertyName,
            allowSearch: desc.allowSearch,
        } as CalendarCollectionComponentDescription;
    }

    public convertArrayScreenToPage(
        desc: ArrayScreenDescription & CalendarListArrayContentDescription,
        table: TableGlideType,
        adc: AppDescriptionContext,
        screenContext: ComponentScreenContextForConversion
    ): CalendarCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: makeTableProperty(getTableName(table)),
            allowSearch: makeSwitchProperty(getSwitchProperty(desc.search)),
            titleActions: definedMap(screenContext.titleAction, a => makeArrayProperty([a])),
            componentTitle: definedMap(screenContext.screenTitle, t => makeStringProperty(t)),
            multipleDynamicFilters: this.getDynamicMultipleFiltersForPageConversion(
                adc,
                table,
                desc.dynamicFilterColumn,
                desc.pivots
            ),
        };
    }
}
