import { styled } from "@glide/common";
import { css } from "styled-components";

export interface Props extends React.PropsWithChildren {
    distance?: string;
    clickable?: boolean;
    position?: boolean;
    bgColor?: string;
}

const positionProps = css<Props>`
    position: absolute;
    top: ${p => (p.distance ? p.distance : 0)};
    right: ${p => (p.distance ? p.distance : 0)};
`;

export const Badge = styled.div<Props>`
    ${p => p.position !== false && positionProps}
    padding: 2px 6px;

    text-transform: uppercase;

    background: ${p => (p.bgColor !== undefined ? p.bgColor : p.theme.bgColorAccent)};
    border-radius: 100px;

    color: ${p => p.theme.fgColorAccent};

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: 9px;

    ${p => p.clickable && "cursor: pointer;"}
`;
