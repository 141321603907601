import type { FrontendEventFields } from "@glide/common-core/dist/js/tracing";
import { getCurrentTimestampInMilliseconds, logError, maybe, QuadraticBackoffController } from "@glide/support";

import { sleep } from "@glideapps/ts-necessities";

import { getLocationSettings } from "@glide/common-core/dist/js/location";
import { isDocumentHidden } from "@glide/common-core/dist/js/support/browser-hacks";
import { isTryingToReload } from "@glide/common-core/dist/js/support/browser-reload";
import { standalone } from "@glide/common-core/dist/js/support/device";

// Not a secret
const apiKey = "529495202dce95b3d7cc813672bb34ad";
const apiHostname = "api.honeycomb.io";
const honeycombRetryTime = 100;
const maxHoneycombRetries = 5;
const honeycombSendTimeout = 12_000;
const maxHoneycombSendTimeSize = 20;
const honeycombSendTimes: number[] = [];
const sampleRate = 5;

async function honeycombSendEventInternal(
    event: FrontendEventFields,
    checkRetryable?: (fields: FrontendEventFields) => boolean
) {
    if (Math.random() > 1 / sampleRate) return;

    const backoff = new QuadraticBackoffController(honeycombRetryTime);
    let sleepTime = 0;
    const datasetName = getLocationSettings().honeycombDatasetName;

    let retries = maxHoneycombRetries;
    if (checkRetryable?.(event) !== true) {
        retries = 1;
    }

    const swController = maybe(() => navigator?.serviceWorker?.controller, null);

    while (backoff.attempts < retries) {
        await sleep(sleepTime);
        if (isTryingToReload()) break;

        const abortController = new AbortController();
        const abortTimeout = setTimeout(() => abortController.abort(), honeycombSendTimeout);
        const lastHoneycombSendTime = honeycombSendTimes.shift();

        try {
            const beforeFetch = getCurrentTimestampInMilliseconds();
            const response = await fetch(`https://${apiHostname}/1/events/${encodeURIComponent(datasetName)}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Honeycomb-Team": apiKey,
                },
                body: JSON.stringify({
                    ...event,
                    device_timestamp: new Date().toISOString(),
                    honeycomb_attempt: backoff.attempts + 1,
                    last_honeycomb_send_time: lastHoneycombSendTime,
                    document_visible: !isDocumentHidden(),
                    standalone,
                    using_service_worker: swController !== null,
                    sampleRate,
                }),
                // We can't use no-cors here because of the X-Honeycomb-Team header.
                mode: "cors",
                signal: abortController.signal,
            });
            // We don't care about the response body, but we do need to drain it,
            // otherwise we leak the HTTP connection.
            await response.text();
            const honeycombTime = getCurrentTimestampInMilliseconds() - beforeFetch;
            while (honeycombSendTimes.length >= maxHoneycombSendTimeSize) {
                honeycombSendTimes.shift();
            }
            honeycombSendTimes.push(honeycombTime);

            if (response.ok) break;
            // We don't retry HTTP 4xx errors.
            if (response.status < 500) break;
        } catch (x: unknown) {
            logError(`Encountered error while trying to send Honeycomb trace`, x);
            if (honeycombSendTimes.length < maxHoneycombSendTimeSize && lastHoneycombSendTime !== undefined) {
                honeycombSendTimes.unshift(lastHoneycombSendTime);
            }
        } finally {
            clearTimeout(abortTimeout);
        }
        sleepTime = backoff.getWaitTime();
    }
}

export function honeycombSendEvent(
    event: FrontendEventFields,
    checkRetryable: ((event: FrontendEventFields) => boolean) | undefined
): void {
    if (isTryingToReload()) return;
    void honeycombSendEventInternal(event, checkRetryable);
}
