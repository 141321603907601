import type { BaseTheme } from "@glide/base-theme";
import type { DeviceFormFactor, ThemePlatform } from "@glide/common";
import { TailwindThemeProvider, useIsOSThemeDark, getMemoizeTheme } from "@glide/common";
import { APP_MODAL_ROOT } from "@glide/wire";

interface ThemedModalRootProps {
    readonly isPages: boolean;
    readonly baseTheme: BaseTheme;
    readonly platform: ThemePlatform;
    readonly deviceFormFactor: DeviceFormFactor;
}

export const ThemedModalRoot: React.VFC<ThemedModalRootProps> = p => {
    const { isPages, baseTheme, platform, deviceFormFactor } = p;
    const isOSThemeDark = useIsOSThemeDark();

    const theme = getMemoizeTheme(isPages, baseTheme, platform, deviceFormFactor, undefined, isOSThemeDark);

    return (
        <TailwindThemeProvider theme={theme}>
            <div id={APP_MODAL_ROOT} tw="all-child:z-app-modal-root pointer-events-auto" />
        </TailwindThemeProvider>
    );
};
