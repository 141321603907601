import {
    type Icon,
    type ActionDescription,
    type ClassOrArrayScreenDescription,
    type ClassScreenDescription,
    type ColumnAssignment,
    type ComponentDescription,
    type EditScreenDescription,
    type MutatingScreenKind,
    ScreenDescriptionKind,
} from "@glide/app-description";
import { areTableNamesEqual, type TableColumn, getTableColumn, getTableName } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { type AppDescriptionContext, getMutatingKindForScreen } from "@glide/function-utils";
import { assert, mapFilterUndefined } from "@glideapps/ts-necessities";
import { logError } from "@glide/support";

import { handlerForActionKind } from "./actions";
import type { ComponentHandler } from "./components/component-handler";
import { handlerForPropertyKind } from "./components/description-handlers";
import { getColumnAssignments, inputOutputTablesForClassOrArrayScreen } from "./description-utils";
import { columnsUsedByScreen, handlerForComponentKind } from "./handlers";
import { getSubComponentsWithTables } from "./sub-components";
import type { GlideIconProps, SerializablePluginMetadata } from "@glide/plugins";
import { WireComponentKind } from "@glide/wire";

interface OutputColumnBindingInfo {
    readonly first: ComponentDescription | ColumnAssignment;
    readonly firstName: string;
    readonly others: number;
}

export type OutputColumnBindingInfos = ReadonlyMap<string, OutputColumnBindingInfo>;

export function getEditedColumnBindingsForComponents(
    rootComponents: readonly ComponentDescription[],
    rootTables: InputOutputTables | undefined,
    ccc: AppDescriptionContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    withActions: boolean
): OutputColumnBindingInfos {
    const properties = new Map<string, OutputColumnBindingInfo>();

    function iterateComponents(components: readonly ComponentDescription[], tables: InputOutputTables) {
        const outputTableName = getTableName(tables.output);
        for (const component of components) {
            const handler = handlerForComponentKind(component.kind);
            if (handler === undefined) {
                logError("No handler for component", component);
                continue;
            }
            // We don't want to do accounting for AI custom chat components for downstream duplicate highlighting.
            if (component.kind === WireComponentKind.AICustomChatComponent) {
                continue;
            }

            const { editedColumns } = handler.getEditedColumns(component, tables, ccc, mutatingScreenKind, withActions);
            for (const [column, editsInScreenContext, , tableName] of editedColumns) {
                if (!editsInScreenContext) continue;
                // This assertion won't hold anymore once we support
                // ##editedColumnsInUserProfile.
                assert(areTableNamesEqual(tableName, outputTableName));

                // There are screen configurations (e.g. custom collections) now where the tables being written to
                // matter and cause false positives in the binding mapping warnings
                // https://github.com/glideapps/glide/issues/33047
                const columnBindingEntryKey = `${column}:${tableName.name}`;
                const entry = properties.get(columnBindingEntryKey);
                if (entry === undefined) {
                    properties.set(columnBindingEntryKey, {
                        first: component,
                        firstName: handler.getDescriptiveName(component, tables, ccc)[0],
                        others: 0,
                    });
                } else if (entry.first !== component) {
                    // `getEditedColumns` can return duplicates, so we make sure
                    // that we only count one primary one.  The count for `others`
                    // might be incorrect, but we're not using it anyway, apart
                    // from checking whether it's more than zero.
                    properties.set(columnBindingEntryKey, { ...entry, others: entry.others + 1 });
                }
            }

            const maybeSubComponents = getSubComponentsWithTables(component, tables, ccc, mutatingScreenKind);
            if (maybeSubComponents !== undefined) {
                const [subComponents, subTables, subMutatingScreenKind] = maybeSubComponents;
                // Since this function returns the directly added columns for
                // the purpose of helping the builder, for example to
                // highlight columns that are assigned multiple times, we
                // don't go into subcomponents if the
                // ##subComponentMutatingScreenKind is defined.
                if (subMutatingScreenKind === undefined && subTables !== undefined) {
                    iterateComponents(subComponents, subTables);
                }
            }
        }
    }

    if (rootTables !== undefined) {
        iterateComponents(rootComponents, rootTables);
    }

    return properties;
}

function getEditedColumnBindingsForScreen(
    desc: ClassScreenDescription,
    rootTables: InputOutputTables,
    ccc: AppDescriptionContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    withActions: boolean
): OutputColumnBindingInfos {
    return getEditedColumnBindingsForComponents(desc.components, rootTables, ccc, mutatingScreenKind, withActions);
}

// Returns the ##columnsAlreadyAssignedInScreen.
export function getUsedAndEditedColumns(
    screenName: string,
    screen: ClassOrArrayScreenDescription,
    adc: AppDescriptionContext
): { usedColumns: ReadonlySet<TableColumn>; editedColumns: ReadonlySet<TableColumn> } | undefined {
    const tables = inputOutputTablesForClassOrArrayScreen(screen, adc.schema);
    if (tables === undefined) return undefined;

    const mutatingScreenKind = getMutatingKindForScreen(screenName, screen);

    const usedColumns = columnsUsedByScreen(screen, tables, adc);
    const outputColumnBindingsInfos =
        screen.kind === ScreenDescriptionKind.Class
            ? getEditedColumnBindingsForScreen(screen, tables, adc, mutatingScreenKind, false)
            : new Map();
    const editedColumns = new Set(
        mapFilterUndefined(outputColumnBindingsInfos.keys(), n => getTableColumn(tables.output, n))
    );

    return { usedColumns, editedColumns };
}

export function getAllColumnBindings(
    desc: ClassScreenDescription,
    isEditScreen: boolean,
    tables: InputOutputTables,
    ccc: AppDescriptionContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    pluginsMetadata: readonly SerializablePluginMetadata[] | undefined,
    withActions: boolean
): OutputColumnBindingInfos {
    const infos = new Map(getEditedColumnBindingsForScreen(desc, tables, ccc, mutatingScreenKind, withActions));

    if (isEditScreen) {
        const assignments = getColumnAssignments(desc as EditScreenDescription);
        for (const assignment of assignments) {
            const { destColumn, value } = assignment;
            const entry = infos.get(destColumn);

            if (entry === undefined) {
                const handler = handlerForPropertyKind(value.kind);
                infos.set(destColumn, {
                    first: assignment,
                    firstName: handler.getColumnAssignmentInfo(
                        ccc,
                        value,
                        tables.input,
                        pluginsMetadata,
                        ccc.userFeatures
                    ).name,
                    others: 0,
                });
            } else {
                infos.set(destColumn, { ...entry, others: entry.others + 1 });
            }
        }
    }

    return infos;
}

export function assignedColumnsInEditScreen(
    formDesc: EditScreenDescription,
    ccc: AppDescriptionContext,
    mutatingScreenKind: MutatingScreenKind,
    pluginsMetadata: readonly SerializablePluginMetadata[] | undefined,
    withActions: boolean
): ReadonlySet<TableColumn> | undefined {
    const inputOutputTables = inputOutputTablesForClassOrArrayScreen(formDesc, ccc.schema);
    if (inputOutputTables === undefined) return undefined;

    const columns = new Set<TableColumn>();

    for (const columnName of getAllColumnBindings(
        formDesc,
        true,
        inputOutputTables,
        ccc,
        mutatingScreenKind,
        pluginsMetadata,
        withActions
    ).keys()) {
        const column = getTableColumn(inputOutputTables.output, columnName);
        if (column === undefined) continue;

        columns.add(column);
    }

    return columns;
}

export function searchableComponentsWithHandlers(
    desc: ClassScreenDescription
): readonly { handler: ComponentHandler<ComponentDescription>; description: ComponentDescription }[] {
    return mapFilterUndefined(desc.components, c => {
        const handler = handlerForComponentKind(c.kind);
        if (handler === undefined) return undefined;
        if (!handler.isSearchable(c)) return undefined;
        return { handler, description: c };
    });
}

export function makeUniqueIDForArrayScreen(screenName: string): string {
    return screenName;
}

export function iconForActions(actions: readonly ActionDescription[]): GlideIconProps | Icon | true | undefined {
    if (actions.length === 0) return undefined;
    return handlerForActionKind(actions[0].kind).iconName;
}

export enum LinkAppearance {
    PageTitle = "page-title",
    LastPathComponent = "last-path-component",
    ShortURL = "short-url",
    FullURL = "full-url",
    CaptionAsTitle = "caption-as-title",
}
