import { lazilyLoadedComponent } from "@glide/common-core/dist/js/support/react-lazy-loading";
import * as React from "react";

import type { Props } from "./code";

const CodeLazy = lazilyLoadedComponent("code-editor", () => import("./code"));

export const Code = (props: Props) => {
    return (
        <React.Suspense fallback={<div>...</div>}>
            <CodeLazy {...props} />
        </React.Suspense>
    );
};
