import type { OverlayTheme } from "../overlay-theme";

export const androidOverlay: OverlayTheme = {
    preferredRoundingRadius: 6,

    overlayName: "AndroidOverlay",
    statusBar: {
        height: 24,
    },

    homeBar: {
        height: 48,
    },

    navBar: {
        height: 56,
        titleFontStyle: "_fontStyles.AccentHeader3Fixed",
        titleColor: "_primaryAccentColor",
        style: "Android",
        cancelColor: "_primaryAccentColor",
    },
    pivot: {
        style: "TopTabs",
        height: 48,
        bottomMargin: 0,
    },
    tabBar: {
        height: 56,
    },
    searchBar: {
        style: "Collapsed",
    },
    listItem: {
        underlineLeft: 0,
    },
    switch: {
        compact: {
            thumbColor: "#ECECEC",
        },
    },
    button: {
        borderRadius: 6,
        height: 41,
        fontStyle: "_fontStyles.Subheader5",
        uppercase: true,
        shadow: "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
    },
} as any;
