import { css } from "styled-components";
import tw from "twin.macro";
import { authThemeAccent, authThemeDark, authThemeHighlight } from "@glide/common-components";

export const signInNormalTextColors = css`
    .${authThemeDark} & {
        ${tw`text-[#FFF]`}
    }

    .${authThemeAccent}.mobile:not(.has-background-image.mobile) & {
        ${tw`text-white`}
    }

    .${authThemeHighlight} & {
        ${tw`text-[rgba(0,0,0,0.95)]`}
    }

    .has-background-image & {
        ${tw`text-w100A`}
    }
`;

export const signInSecondaryTextColors = css`
    & a,
    & .secondary {
        ${tw`text-n500`}
    }

    .has-background-image &,
    .has-background-image.mobile &,
    .${authThemeDark} &,
    .${authThemeAccent}.mobile & {
        ${tw`text-w80A`}
    }

    .has-background-image & a,
    .has-background-image & .secondary,
    .has-background-image.mobile & .secondary,
    .${authThemeDark} & a,
    .${authThemeDark} & .secondary,
    .${authThemeAccent}.mobile & .secondary {
        ${tw`text-w50A`}
    }
`;

export const signInCTAButtonStyles = css`
    .${authThemeAccent}.mobile & {
        ${tw`bg-n0 text-text-accent`}
    }
    .has-background-image.mobile & {
        ${tw`bg-accent text-w100A`}
    }
`;

export const signInSecondaryButtonStyles = css`
    .${authThemeAccent}.mobile & {
        ${tw`text-w50A`}
    }
`;
