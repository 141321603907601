import { definedMap } from "collection-utils";
import type { AppKind } from "@glide/location-common";
import { getLocalizedString } from "@glide/localization";
import type { MenuItemPurpose } from "@glide/common-core/dist/js/components/types";
import type { WireAppMenuItem, WireAppOverlayEditorComponent } from "@glide/fluent-components/dist/js/base-components";
import { WireComponentKind, type WireAction, type WireEditableValue, type WireScreen } from "@glide/wire";
import { encodeScreenKey } from "../wire/utils";

export function makeOverlayEditorSubsidiaryScreen(
    appKind: AppKind,
    title: string,
    editedValue: WireEditableValue<string>,
    placeholder: string | null,
    doneTitle: string,
    doneIcon: string,
    donePurpose: MenuItemPurpose,
    doneToken: string | undefined,
    closeSubsidiaryAction: WireAction,
    screenKey: string
): WireScreen {
    const editComponent: WireAppOverlayEditorComponent = {
        kind: WireComponentKind.AppOverlayEditor,
        placeholder,
        value: editedValue,
    };
    const cancelMenuItem: WireAppMenuItem = {
        kind: WireComponentKind.AppMenuItem,
        title: getLocalizedString("cancel", appKind),
        icon: "00-01-glide-close",
        style: "platform-cancel",
        purpose: undefined,
        action: closeSubsidiaryAction,
    };
    const doneMenuItem: WireAppMenuItem = {
        kind: WireComponentKind.AppMenuItem,
        title: doneTitle,
        icon: doneIcon,
        style: "platform-accept",
        purpose: donePurpose,
        action: definedMap(doneToken, t => ({ token: t })),
    };
    return {
        key: encodeScreenKey(screenKey),
        title,
        flags: [],
        specialComponents: [cancelMenuItem, doneMenuItem],
        components: [editComponent],
        isInModal: true,
        tabIcon: "",
    };
}
