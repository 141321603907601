/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { exceptionToString, defined } from "@glideapps/ts-necessities";
import { isDefined } from "@glide/support";
import type jsonatalib from "jsonata";

// Using this for dynamically importing jsonata
let jsonataPromise: Promise<typeof jsonatalib> | undefined;

async function getJsonata(): Promise<typeof jsonatalib> {
    if (!isDefined(jsonataPromise)) {
        jsonataPromise = new Promise(async res => {
            const jsonata = (await import("jsonata")).default;
            res(jsonata);
        });
    }

    return jsonataPromise;
}

export const plugin = glide.newNativePlugin({
    id: "json",
    name: "JSON",
    icon: {
        kind: "monotone",
        icon: "mt-header-json",
        bgColor: "var(--gv-icon-base)",
        fgColor: "var(--gv-lime500)",
    },
    description:
        "Create JSON Objects and Arrays, Query JSON with JSONata syntax (https://docs.jsonata.org/overview.html)",
    documentationUrl: "https://docs.jsonata.org/overview.html",
});

const builderKeyValues = glide.makeParameter({
    type: "jsonObject",
    name: "JSON Keys and Values",
    description: "Add key value pairs to a JSON object",
    required: true,
});

const outputJson = glide.makeParameter({
    type: "json",
    name: "Resulting JSON",
    description: "A JSON string",
});

const json = glide.makeParameter({
    type: "json",
    name: "JSON",
    description: "JSON to transform",
    placeholder: `{ "value": 42 }`,
    required: true,
});

function query(parameterName: string) {
    return glide.makeParameter({
        type: "jsonPath",
        valueFromProperty: parameterName,
        name: "Query",
        description: "JSONata query",
        placeholder: "value",
        required: true,
    });
}

const transformedJson = glide.makeParameter({
    type: "json",
    name: "Transformed JSON",
    description: "The transformed JSON",
});

export const JSON_COMPUTATION_ID = "build-json";
export const JSON_TEMPLATE_COMPUTATION_ID = "json-template";

plugin.addClientComputation({
    id: JSON_COMPUTATION_ID,
    name: "JSON Object",
    parameters: {
        builderKeyValues,
    },
    description: "Build a JSON object from keys mapped to values",
    group: "Data",
    results: {
        outputJson,
    },
    execute: async (_, { builderKeyValues }) => {
        if (builderKeyValues === undefined) {
            return glide.Result.Ok({ outputJson: {} });
        }

        return glide.Result.Ok({ outputJson: builderKeyValues });
    },
});

plugin.addClientComputation({
    id: "transform-json",
    name: "Query JSON",
    icon: {
        kind: "monotone",
        icon: "mt-header-filter-sort-limit",
        fgColor: "var(--gv-icon-pale)",
        bgColor: "var(--gv-lime500)",
    },
    keywords: ["transform", "json", "query"],
    parameters: {
        json,
        query: query("json"),
    },
    description: "Transform JSON using JSONata syntax",
    group: "Data",
    results: {
        transformedJson,
    },
    execute: async (_, { json, query }) => {
        if (json === undefined) {
            return glide.Result.Ok({ transformedJson: "" });
        }
        if (typeof json === "string") {
            // This is bad behavior to preserve backward compatibility.  The
            // old behavior was that the input was always a string, and it was
            // parsed as JSON.  The unfortunate side effect of this is that
            // this computation cannot operate directly on strings, because we
            // will always try to parse those as JSON.
            try {
                json = JSON.parse(json);
            } catch (e: unknown) {
                return glide.Result.FailPermanent(`Invalid JSON: ${json}`);
            }
        }
        if (typeof query === "string") {
            try {
                const jsonata = await getJsonata();
                const expression = jsonata(query ?? "$");
                const transformed = await expression.evaluate(json);
                return glide.Result.Ok({ transformedJson: transformed });
            } catch (err: unknown) {
                const str = exceptionToString(err);
                return glide.Result.FailPermanent(`Could not transform JSON: ${str}`);
            }
        } else {
            const transformed = getNestedValue(defined(json), query);
            return glide.Result.Ok({ transformedJson: transformed });
        }
    },
});

function getNestedValue(json: glide.JSONValue, path: readonly string[] | undefined) {
    // note that `path` here, is derived from the JSON provided in the first row
    // this could be different from the JSON provided in the subsequent rows
    // we have to guard against that here.
    if (!isDefined(path) || typeof json !== "object" || json === null) return json;

    let value: any = json;
    for (const key of path) {
        if (isDefined(value[key])) {
            value = value[key];
        } else {
            return undefined;
        }
    }

    return value;
}

plugin.addClientComputation({
    id: JSON_TEMPLATE_COMPUTATION_ID,
    name: "JSON Template",
    keywords: ["json", "create"],
    parameters: {
        template: glide.makeParameter({
            type: "string",
            name: "JSON Template",
            description:
                "Like the normal Template column, but for JSON; this creates a JSON value with substitutions. Write substituted like $name and $age, and bind them to columns.",
            placeholder: `{ "name": $name, "age": $age }`,
            multiLine: true,
            required: true,
            emptyByDefault: true,
        }),
        substitutions: glide.makeParameter({
            type: "jsonObject",
            name: "Substitutions",
            description: "Key value pairs to substitute in the template",
        }),
    },
    description: "Create JSON with values from multiple columns",
    group: "Data",
    results: {
        json: glide.makeParameter({
            type: "json",
            name: "JSON",
            description: "The JSON created from the template",
        }),
    },
    execute: async (_, { template, substitutions = {} }) => {
        if (template === undefined) {
            return glide.Result.FailPermanent(`JSON template required`);
        }
        substitutions = Object.fromEntries(
            Object.entries(substitutions).map(([key, value]) => [
                // Users may repeat the $ at the start, but jsonata doesn't expect it
                key.startsWith("$") ? key.slice(1) : key,
                value,
            ])
        );
        try {
            const jsonata = await getJsonata();
            const json = await jsonata?.(template).evaluate({}, substitutions);
            return glide.Result.Ok({ json });
        } catch {
            return glide.Result.FailPermanent(`Invalid JSON template`);
        }
    },
});
