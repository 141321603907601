import { isRight } from "fp-ts/lib/Either";
import * as t from "io-ts";

export const gcpScopeCodec = t.union([
    t.literal("https://www.googleapis.com/auth/sqlservice.admin"),
    t.literal("https://www.googleapis.com/auth/bigquery"),
]);

export type GCPScope = t.TypeOf<typeof gcpScopeCodec>;

export function isGCPScopes(x: unknown): x is GCPScope[] {
    return isRight(t.array(gcpScopeCodec).decode(x));
}

export const sendGmailEmailScope = "https://www.googleapis.com/auth/gmail.send";
export const gcpGmailScopeCodec = t.literal(sendGmailEmailScope);

export type GCPGmailScope = t.TypeOf<typeof gcpGmailScopeCodec>;
