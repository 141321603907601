/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { maybeParseJSON } from "@glide/support";
import { exceptionToString } from "@glideapps/ts-necessities";

export const plugin = glide.newPlugin({
    id: "giphy",
    name: "Giphy",
    description: "Search for and retrieve GIFs using the Giphy API",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/giphy.png",
    tier: "starter",
    parameters: {
        apiKey: glide.makeParameter({
            name: "API Key",
            type: "secret",
            description: "Your Giphy API Key",
            required: true,
        }),
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/giphy",
});

const searchInput = glide.makeParameter({
    type: "string",
    name: "Search Input",
    description: "The search term used to find a GIF",
    placeholder: "e.g. funny cat",
    required: true,
    useTemplate: "withLabel",
});

const gifUrl = glide.makeParameter({
    type: "string",
    name: "GIF URL",
    description: "The URL of the resulting GIF",
    useTemplate: "withLabel",
});

plugin.addComputation({
    id: "get-gif",
    name: "Get GIF",
    description: "Retrieve a GIF URL using the Giphy API",
    parameters: { searchInput },
    results: { gifUrl },
    billablesConsumed: 1,
    async execute(context, { searchInput, apiKey }) {
        if (apiKey === undefined || searchInput === undefined) {
            return glide.Result.FailPermanent("Search Input and API Key are required", {
                isPluginError: false,
            });
        }

        const encodedSearchInput = encodeURIComponent(searchInput);
        const url = `https://api.giphy.com/v1/gifs/search?api_key=${apiKey}&q=${encodedSearchInput}&limit=1&offset=0&rating=g&lang=en`;

        const r = await context.useCache(async () => {
            try {
                const response = await context.fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                const data = await response.json();

                if (!response.ok)
                    return glide.Result.FailFromHTTPStatus("Error talking to Giphy.", response.status, {
                        status: response.status,
                        data,
                    });

                if (data?.data !== undefined && data.data.length > 0) {
                    const url = data.data[0].images.original.url;
                    context.consumeBillable();
                    return glide.Result.Ok({ gifUrl: url });
                } else {
                    return glide.Result.Fail("No GIFs found for the search input", {
                        isPluginError: false,
                    });
                }
            } catch (error: unknown) {
                return glide.Result.Fail(`Error retrieving GIF: ${exceptionToString(error)}`, {
                    data: maybeParseJSON(error),
                });
            }
        }, [url]);
        return r;
    },
});
