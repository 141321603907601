import { Corners } from "@glide/common-core/dist/js/components/image-types";
import type { ArrayContentDescription } from "@glide/app-description";
import { TextSize } from "@glide/component-utils";
import {
    type PropertyDescriptor,
    EnumPropertyHandler,
    NumberPropertyHandler,
    NumberPropertyStyle,
    PropertySection,
    RequiredKind,
    SwitchPropertyHandler,
} from "@glide/function-utils";

export const cornersPropertyHandler = new EnumPropertyHandler(
    { corners: Corners.Rounded },
    "Corners",
    "Corners",
    [
        {
            value: Corners.Square,
            label: "Sharp",
            icon: "cornersSharp",
        },
        {
            value: Corners.Rounded,
            label: "Round",
            icon: "cornersRound",
        },
    ],
    PropertySection.Design,
    "small-images"
);

export const isHorizontalPropertyHandler = new EnumPropertyHandler(
    { isHorizontal: false },
    "Orientation",
    "Orientation",
    [
        {
            value: false,
            label: "Vertical",
            icon: "listOrientationVertical",
        },
        {
            value: true,
            label: "Horizontal",
            icon: "listOrientationHorizontal",
        },
    ],
    PropertySection.Design,
    "small-images"
);

export const textSizePropertyHandler = new EnumPropertyHandler(
    { textSize: TextSize.Medium },
    "Text Size",
    "Text Size",
    [
        {
            value: TextSize.Small,
            label: "Small",
            icon: "textSmall",
        },
        {
            value: TextSize.Medium,
            label: "Medium",
            icon: "textMedium",
        },
        {
            value: TextSize.Large,
            label: "Large",
            icon: "textLarge",
        },
    ],
    PropertySection.TextStyle,
    "small-images"
);

export const allCapsPropertyHandler = new SwitchPropertyHandler(
    { allCaps: false },
    "All-Caps titles",
    PropertySection.TextStyle
);

export const truncateListPropertyHandler = new NumberPropertyHandler(
    { truncate: 3 },
    'Show "See all" for more than',
    "3",
    RequiredKind.NotRequiredDefaultMissing,
    NumberPropertyStyle.Stepper,
    PropertySection.Options,
    0
);

function doesArrayContentSupportTruncation(
    isDefaultArrayScreen: boolean,
    desc: ArrayContentDescription | undefined
): boolean {
    return !isDefaultArrayScreen || truncateListPropertyHandler.getNumber(desc) !== undefined;
}

export function pushHorizontalAndTruncatePropertyHandlers(
    descrs: PropertyDescriptor[],
    desc: ArrayContentDescription | undefined,
    isDefaultArrayScreen: boolean,
    supportsHorizontal: boolean
): void {
    if (supportsHorizontal /* && ( !isDefaultArrayScreen || isHorizontalPropertyHandler.getEnum(desc)) */) {
        descrs.push(isHorizontalPropertyHandler);
    }
    if (doesArrayContentSupportTruncation(isDefaultArrayScreen, desc)) {
        descrs.push(truncateListPropertyHandler);
    }
}
