import type { CellValue, SerializableCellValue } from "./cell-values";
import { isBasePrimitiveValue } from "./cell-values";
import * as t from "io-ts";
import type { Either } from "fp-ts/lib/Either";
import { convertCellValueToSerializableValue, convertSerializableValueToCellValue } from "./data-conversions";
import { defined } from "@glideapps/ts-necessities";
import { GlideDateTime } from "./glide-date-time";
import { GlideJSON } from "./glide-json";

export const cellValueCodec = new t.Type<CellValue, SerializableCellValue, unknown>(
    "cellValue",
    // is
    (u: unknown): u is CellValue => {
        if (isBasePrimitiveValue(u)) return true;
        if (u instanceof GlideDateTime) return true;
        if (u instanceof GlideJSON) return true;
        if (Array.isArray(u)) return u.every(v => cellValueCodec.is(v));
        return false;
    },
    // validate
    (i: unknown, context): Either<t.Errors, CellValue> => {
        const r = convertSerializableValueToCellValue(
            {
                flattenArrays: false,
                semiStrictConvertSerializableValue: true,
            },
            i
        );
        if (r === undefined) {
            return t.failure(i, context, "Could not convert to CellValue");
        }
        return t.success(r);
    },
    // encode
    (a: CellValue): SerializableCellValue => {
        // `CellValue` cannot be `undefined`, so the result here must always
        // be defined.
        return defined(convertCellValueToSerializableValue(a));
    }
);
