import type { RuntimeTheme } from "@glide/common";
import { moodColors } from "@glide/common";
import type { Mood } from "@glide/component-utils";
import { Appearance } from "@glide/component-utils";
import { disabledProps, styled } from "@glide/common-components";
import { css } from "styled-components";
import chroma from "chroma-js";

const primaryButtonStyle = (theme: RuntimeTheme) => css`
    && {
        background-color: ${theme.primaryAccentColor};
        border-color: ${theme.primaryAccentColor};
        color: ${theme.button.filledTextColor};
        box-shadow: ${theme.button.shadow};

        :active {
            background-color: ${chroma(theme.primaryAccentColor).darken(7).css()};
        }
    }
`;

const secondaryButtonStyle = (theme: RuntimeTheme) => css`
    && {
        background-color: ${theme.primaryAccentColor + "1e"};
        border-color: ${theme.primaryAccentColor + "1e"};
        color: ${theme.primaryAccentColor};

        :active {
            color: ${chroma(theme.primaryAccentColor).darken(7).css()};
        }
    }
`;

const borderedButtonStyle = (theme: RuntimeTheme) => css`
    && {
        background-color: transparent;
        color: ${theme.primaryAccentColor};

        border-style: solid;
        border-width: 1px;
        border-color: ${theme.primaryAccentColor};

        :active {
            color: ${chroma(theme.primaryAccentColor).darken(7).css()};
            border-color: ${chroma(theme.primaryAccentColor).darken(7).css()};
        }
    }
`;

const simpleButtonStyle = (theme: RuntimeTheme) => css`
    && {
        background-color: transparent;
        color: ${theme.primaryAccentColor};

        :active {
            color: ${chroma(theme.primaryAccentColor).darken(7).css()};
        }
    }
`;

const fullBleedButtonStyle = (theme: RuntimeTheme) => css`
    ${primaryButtonStyle(theme)}
    && {
        margin: 0;
        border-radius: 0;
    }
`;

export const ButtonViewStyle = styled.div`
    display: flex;
`;

export interface ButtonStyleProps {
    readonly appearance: Appearance;
    readonly href?: string;
    readonly includeMargin?: boolean;
    readonly disabled?: boolean;
    readonly mood: Mood;
}

function modifyThemeForMood(theme: RuntimeTheme, mood: Mood): RuntimeTheme {
    const colors = moodColors(theme);
    const primaryAccentColor = colors[mood] ?? theme.primaryAccentColor;
    return { ...theme, primaryAccentColor };
}

export const ButtonStyle = styled.button<ButtonStyleProps>`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 12px;

    margin: ${p => p.theme.componentInnerVerticalPadding}px ${p => p.theme.pageMarginMedium}px;
    ${p => p.includeMargin === false && `margin: 0;`}

    border-radius: ${p => p.theme.button.borderRadius}px;
    min-height: ${p => p.theme.button.height}px;

    ${p => p.theme.button.fontStyle};

    ${p => p.theme.button.uppercase && "text-transform: uppercase;"}

    cursor: pointer;

    ${p => p.disabled && disabledProps}

    ${p => p.appearance === Appearance.Filled && primaryButtonStyle(modifyThemeForMood(p.theme, p.mood))}
    ${p => p.appearance === Appearance.Transparent && secondaryButtonStyle(modifyThemeForMood(p.theme, p.mood))}
    ${p => p.appearance === Appearance.Bordered && borderedButtonStyle(modifyThemeForMood(p.theme, p.mood))}
    ${p => p.appearance === Appearance.Simple && simpleButtonStyle(modifyThemeForMood(p.theme, p.mood))}
    ${p => p.appearance === Appearance.FullBleed && fullBleedButtonStyle(modifyThemeForMood(p.theme, p.mood))}
`;
