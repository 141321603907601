import { asMaybeBoolean } from "@glide/common-core/dist/js/type-conversions";
import { asMaybeString, boundMap } from "@glide/computation-model-types";
import { AppKind } from "@glide/location-common";
import {
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    getSwitchProperty,
} from "@glide/app-description";
import type {
    DefaultableComponentDescription,
    InputOutputTables,
    TitleDescription,
} from "@glide/common-core/dist/js/description";
import { type Doc, getDocURL } from "@glide/common-core/dist/js/docUrl";
import { type WireToggleComponent, WireToggleKind } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type EnumPropertyCase,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    EnumPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
    makeTextPropertyDescriptor,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import {
    type WireComponentHydrationResult,
    type WireComponentPreHydrationResult,
    type WireHydrationFollowUp,
    type WireRowComponentHydrationBackend,
    type WireRowComponentHydrator,
    type WireRowComponentHydratorConstructor,
    type WireValueGetterGeneric,
    type WireEditableValue,
    ValueChangeSource,
    WireComponentKind,
    type WireInflationBackend,
} from "@glide/wire";
import { assert, defined } from "@glideapps/ts-necessities";
import isBoolean from "lodash/isBoolean";

import { inflateBooleanProperty, inflateEditableProperty, spreadComponentID } from "../wire/utils";
import {
    doesMutatingScreenKindSupportDefaultValues,
    labelCaptionStringOptions,
    makeCaptionStringPropertyDescriptor,
    makeDefaultValuePropertyDescriptor,
    makePrimaryKeyPropertyHandler,
} from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

export interface ToggleComponentDescription extends DefaultableComponentDescription, TitleDescription {
    readonly propertyName: PropertyDescription;
    readonly primaryKeyProperty: PropertyDescription | undefined;
    readonly caption: PropertyDescription | undefined;
    readonly description: PropertyDescription | undefined;
    readonly mustBeActive: PropertyDescription | undefined;
    readonly allowWrapping: PropertyDescription | undefined;
    readonly toggleKind: PropertyDescription | undefined;
}

const allowWrappingPropertyHandler = new SwitchPropertyHandler(
    { allowWrapping: false },
    "Allow text wrapping",
    PropertySection.TextStyle
);

const toggleKindPropertyCases: EnumPropertyCase<WireToggleKind>[] = [
    { value: WireToggleKind.Switch, label: "Switch" },
    { value: WireToggleKind.Checkbox, label: "Checkbox" },
];

function makeToggleKindPropertyHandler(defaultKind: WireToggleKind) {
    return new EnumPropertyHandler(
        { toggleKind: defaultKind },
        "Style",
        "Style",
        toggleKindPropertyCases,
        PropertySection.Design,
        "text"
    );
}

export abstract class ToggleComponentHandler extends ComponentHandlerBase<ToggleComponentDescription> {
    private readonly _propertyNamePropertyHandler: ColumnPropertyHandler;
    private readonly toggleKindPropertyHandler: EnumPropertyHandler<WireToggleKind, { toggleKind: WireToggleKind }>;

    protected abstract readonly name: string;
    protected abstract readonly description: string;
    protected abstract readonly img: string;
    protected abstract readonly docPath: Doc;

    protected abstract readonly uiName: string;

    constructor(kind: ComponentKind, defaultToggleKind: WireToggleKind, preferredNames: readonly string[]) {
        super(kind);

        this._propertyNamePropertyHandler = new ColumnPropertyHandler(
            "propertyName",
            "Column",
            [
                ColumnPropertyFlag.Required,
                ColumnPropertyFlag.Editable,
                ColumnPropertyFlag.DefaultCaption,
                ColumnPropertyFlag.EditedInApp,
                ColumnPropertyFlag.AllowUserProfileColumns,
            ],
            undefined,
            preferredNames,
            getPrimitiveColumnsSpec,
            "boolean",
            PropertySection.Data
        );

        this.toggleKindPropertyHandler = makeToggleKindPropertyHandler(defaultToggleKind);
    }

    public needValidation(desc: ToggleComponentDescription): boolean {
        return getSwitchProperty(desc.mustBeActive) ?? false;
    }

    public getIsEditor(): boolean {
        return true;
    }

    public get isNonEmptyValidationStopper(): boolean {
        return true;
    }

    public getDescriptor(
        desc: ToggleComponentDescription | undefined,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [this._propertyNamePropertyHandler];

        const defaultDescr = makeDefaultValuePropertyDescriptor(ccc, desc?.propertyName, mutatingScreenKind, "boolean");
        if (defaultDescr !== undefined) {
            properties.push(defaultDescr);
        }

        if (ccc.appKind === AppKind.Page) {
            properties.push(this.toggleKindPropertyHandler);
        }

        properties.push(
            ...makePrimaryKeyPropertyHandler(ccc, tables?.input),
            makeCaptionStringPropertyDescriptor("Toggle", false, mutatingScreenKind, labelCaptionStringOptions),
            makeTextPropertyDescriptor("description", "Description", "Enter description", false, mutatingScreenKind, {
                searchable: false,
                propertySection: PropertySection.Design,
            })
        );
        if (ccc.appKind === AppKind.App) {
            properties.push(allowWrappingPropertyHandler);
        }
        properties.push(...this.getBasePropertyDescriptors());
        return {
            name: this.name,
            description: this.description,
            img: this.img,
            group: ccc.appKind === AppKind.Page ? "Form Elements" : "Buttons",
            helpUrl: getDocURL(this.docPath),
            properties,
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: ToggleComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const {
            forBuilder,
            adc: { appKind },
        } = ib;

        const [titleGetter] = ib.getValueGetterForProperty(desc.caption, true);
        const [descriptionGetter] = ib.getValueGetterForProperty(desc.description, true);

        const allowWrapping = allowWrappingPropertyHandler.getSwitch(desc);
        const toggleKind = this.toggleKindPropertyHandler.getEnum(desc);

        const { getter: valueGetter, isInContext } = inflateEditableProperty(
            ib,
            "set",
            desc.propertyName,
            asMaybeBoolean
        );
        if (valueGetter === undefined) return undefined;

        let defaultValueGetter: WireValueGetterGeneric<boolean | undefined> | undefined;
        if (doesMutatingScreenKindSupportDefaultValues(ib.mutatingScreenKind, desc.propertyName)) {
            defaultValueGetter = inflateBooleanProperty(ib, desc.defaultValue)[0];
        }

        const isRequired = this.needValidation(desc);
        // In Apps, toggles that are not required don't contribute to
        // validation.
        // https://github.com/quicktype/glide/issues/15547
        const alwaysHasValue = appKind === AppKind.App && !isRequired;

        class Hydrator implements WireRowComponentHydrator {
            public static readonly wantsSearch = false;

            private value: WireEditableValue<boolean> | undefined;

            constructor(private readonly hb: WireRowComponentHydrationBackend) {}

            public preHydrate(): WireComponentPreHydrationResult {
                const { hb } = this;

                const value = defined(valueGetter)(hb);
                if (value === undefined) return [false, undefined];

                this.value = { ...value, value: value.value ?? false };

                let followUp: WireHydrationFollowUp | undefined;
                // Yet another case of ##ncmDefaultValues.
                if (defaultValueGetter?.(hb) === true) {
                    if (value.value !== undefined) {
                        hb.getState("hadValue", isBoolean, true, false);
                    } else {
                        const hadValue = hb.getState("hadValue", isBoolean, false, false);
                        if (!hadValue.value) {
                            followUp = ab => {
                                if (value.onChangeToken !== undefined) {
                                    ab.valueChanged(value.onChangeToken, true, ValueChangeSource.Internal);
                                }
                                ab.valueChanged(hadValue.onChangeToken, true, ValueChangeSource.Internal);
                            };
                        }
                    }
                }

                return [true, followUp];
            }

            public hydrate(): WireComponentHydrationResult | undefined {
                const { hb, value } = this;

                assert(value !== undefined);

                const title = boundMap(titleGetter(hb), asMaybeString);
                const description = boundMap(descriptionGetter(hb), asMaybeString);

                const component: WireToggleComponent = {
                    kind: WireComponentKind.Toggle,
                    ...spreadComponentID(desc.componentID, forBuilder),
                    toggleKind,
                    title,
                    description,
                    value,
                    onToggle: undefined,
                    allowWrapping,
                    isRequired,
                };
                return {
                    component,
                    isValid: value.value || !isRequired,
                    editsInContext: isInContext,
                    hasValue: alwaysHasValue ? undefined : value.value,
                };
            }
        }

        return Hydrator;
    }
}
