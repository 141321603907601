import { logError } from "@glide/support";
import * as React from "react";

function load(key: string): unknown {
    try {
        const item = window.localStorage.getItem(key);
        if (item === null) return undefined;
        return JSON.parse(item);
    } catch {
        return undefined;
    }
}

export function useLocalStorage<T>(
    key: string,
    initialValue: T | (() => T)
): [T, (value: T | ((val: T) => T)) => void] {
    const [storedValue, setStoredValue] = React.useState<T>();
    const lastKey = React.useRef<string | undefined>();

    const currentValue =
        storedValue ?? (typeof initialValue === `function` ? (initialValue as () => T)() : initialValue);

    if (key !== lastKey.current) {
        const fromStorage = load(key);
        setStoredValue((fromStorage as T | undefined) ?? currentValue);
        lastKey.current = key;
    }

    const setValue = React.useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                const valueToStore = value instanceof Function ? value(currentValue) : value;
                setStoredValue(valueToStore);
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            } catch (error: unknown) {
                logError(error);
            }
        },
        [key, currentValue]
    );

    return [currentValue, setValue];
}
