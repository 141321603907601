import type { RuntimeTheme } from "./types";

export enum Mood {
    Default = "default",
    Neutral = "neutral",
    Warning = "warning",
    Danger = "error",
    Success = "success",
}

export const moodCases = [
    {
        value: Mood.Default,
        label: "Default",
    },
    {
        value: Mood.Neutral,
        label: "Neutral",
    },
    {
        value: Mood.Success,
        label: "Success",
    },
    {
        value: Mood.Warning,
        label: "Warning",
    },
    {
        value: Mood.Danger,
        label: "Danger",
    },
];

export const reducedMoodCases = [
    {
        value: Mood.Default,
        label: "Default",
    },
    {
        value: Mood.Success,
        label: "Success",
    },
    {
        value: Mood.Danger,
        label: "Danger",
    },
];

export function moodColors(theme: RuntimeTheme): Record<Mood, string> {
    return {
        [Mood.Default]: theme.primaryAccentColor,
        [Mood.Neutral]: "#797989",
        [Mood.Warning]: "#EC9D00",
        [Mood.Danger]: theme.destroyColor,
        [Mood.Success]: "#00CB69",
    };
}
