import type { SerializablePluginMetadata, AnalyticsEvent } from "@glide/plugins";
import pack from "@glide/glide-plugins/client";
import type { AppDescription, SerializedApp } from "@glide/app-description";
import React from "react";
import type { WirePageNavigationModel } from "@glide/wire";

export const eventTrackers: string[] = [];

export function registerEventTrackers(pluginMetadata: readonly SerializablePluginMetadata[]): void {
    for (const p of pluginMetadata) {
        if (p.hasEventTracker) {
            eventTrackers.push(p.id);
        }
    }
}

async function trackPluginEvent(event: AnalyticsEvent, pluginConfigs: AppDescription["pluginConfigs"]): Promise<void> {
    for (const p of eventTrackers) {
        try {
            const plugin = await pack.getPlugin(p);
            if (plugin.eventTracker === undefined) continue;
            plugin.eventTracker.track(pluginConfigs?.find(c => c.pluginID === p)?.parameters ?? {}, event);
        } catch {
            continue;
        }
    }
}

export function usePluginEvents(
    navModel: WirePageNavigationModel | undefined,
    pluginConfigs: SerializedApp["pluginConfigs"] | undefined
) {
    const pluginConfigsRef = React.useRef(pluginConfigs);
    pluginConfigsRef.current = pluginConfigs;
    React.useEffect(() => {
        void trackPluginEvent(
            {
                kind: "load",
            },
            pluginConfigsRef.current
        );
    }, []);

    const title = (navModel?.modal ?? navModel?.screen)?.title ?? "";
    React.useEffect(() => {
        if (title === "") return;
        void trackPluginEvent(
            {
                kind: "navigate",
                title,
            },
            pluginConfigsRef.current
        );
    }, [navModel?.urlPath, title]);

    const { signedInUserEmail, signedInUserImage, signedInUserName } = navModel?.userProfileButton ?? {};
    React.useEffect(() => {
        if (signedInUserEmail === undefined) return;
        void trackPluginEvent(
            {
                kind: "identify",
                email: signedInUserEmail,
                userID: signedInUserEmail,
                username: signedInUserName ?? "",
            },
            pluginConfigsRef.current
        );
    }, [navModel?.kind, signedInUserEmail, signedInUserImage, signedInUserName]);
}
