import type { GlideDateTime, GlideDateTimeDocumentData, GlideJSON, GlideJSONDocumentData } from "@glide/data-types";
import { glideDateTimeCodec, glideJSONCodec } from "@glide/data-types";
import { hasOwnProperty } from "@glideapps/ts-necessities";
import * as t from "io-ts";
import { glideIconPropsCodec } from "./glide-icon";
import { apiCapabilitiesCodec } from "./api-key-capabilities";

type PrettyIOTS<T> = {
    [K in keyof T]: T[K] extends object ? PrettyIOTS<T[K]> : T[K];
} & {};

export type TypeOfIOTS<T extends t.Any> = PrettyIOTS<t.TypeOf<T>>;

const oAuthProviderCodec = t.keyof({
    google: null,
    github: null,
    slack: null,
    paypal: null,
    "slack-bot": null,
    discord: null,
    zoom: null,
    hubspot: null,
    asana: null,
    "msal-plugins": null,
    docusign: null,
    airtable: null,
});
export type OAuthProvider = t.TypeOf<typeof oAuthProviderCodec>;

const authDefinitionCodec = t.type({
    provider: oAuthProviderCodec,
    scopes: t.readonlyArray(t.string),
});
export type AuthDefinition = t.TypeOf<typeof authDefinitionCodec>;

const otherTypeWithoutDateCodec = t.keyof({
    url: null,
    number: null,
    secret: null,
    array: null,
    stringArray: null,
    object: null,
    stringObject: null,
    boolean: null,
    json: null,
    jsonObject: null,
});
const dateTimeTypeCodec = t.keyof({
    dateTime: null,
});
const valueTypeWithoutDateCodec = t.union([t.literal("string"), otherTypeWithoutDateCodec]);
const valueTypeCodec = t.union([valueTypeWithoutDateCodec, dateTimeTypeCodec]);
export type ValueType = t.TypeOf<typeof valueTypeCodec>;
export type InputValueTypes = ValueType | "enum" | "table" | "generatedKeyPair" | "jsonPath";

const syncEnumValueType = t.readonlyArray(
    t.intersection([
        t.type({
            value: t.string,
            label: t.string,
        }),
        t.partial({
            icon: t.string,
        }),
    ])
);
type SyncEnumValueType = t.TypeOf<typeof syncEnumValueType>;

const enumValueType = t.union([
    syncEnumValueType,
    t.type({
        async: t.literal(true),
        fetchKey: t.string,
        defaultDisplayLabel: t.string,
    }),
]);
type EnumValueType = t.TypeOf<typeof enumValueType>;

export function isSyncEnumValues(val: EnumValueType): val is SyncEnumValueType {
    return !hasOwnProperty(val, "async");
}

export type JSONValue = string | number | boolean | null | { [x: string]: JSONValue } | JSONValue[];

const jsonValueCodec: t.Type<JSONValue> = t.recursion("JSONValueCodec", self =>
    t.union([t.string, t.number, t.boolean, t.null, t.array(self), t.record(t.string, self)])
);

// the weird readonly structure is because wrapping in a readonly invalidates the tagged union.
/**
 * ---------------------------
 * ** VERY IMPORTANT README **
 * ---------------------------
 *
 * ANY CHANGE TO THIS CODEC MUST BE BACKWARDS COMPABTIBLE.
 *
 * Plugin metadata is baked in the published app, so if you make a breaking change here
 * you'll break any apps that rely on integrations.
 *
 * THAT IS BAD
 */
const parameterPropsCodec = t.intersection([
    t.union([
        t.readonly(
            t.intersection([
                t.type({
                    type: t.literal("enum"),
                    values: enumValueType,
                }),
                t.partial({
                    defaultValue: t.string,
                }),
            ])
        ),
        t.readonly(
            t.intersection([
                t.type({
                    type: t.literal("string"),
                }),
                t.partial({
                    multiLine: t.boolean,
                    defaultValue: t.string,
                    // if undefined, will not use inline templates
                    // if "withoutLabel" it will look fullwidth without a label
                    // if "withLabel" it will look like other properties, label to the left of the input
                    // if "withLabelAndFullWidth" it will have a label and be full width
                    //
                    // For backwards compatibility:
                    // true -> withLabel
                    // false -> undefined
                    useTemplate: t.union([
                        t.literal("withoutLabel"),
                        t.literal("withLabel"),
                        t.literal("withLabelAndFullWidth"),
                        t.boolean,
                    ]),
                }),
            ])
        ),
        t.readonly(
            t.intersection([
                t.type({
                    type: t.literal("url"),
                }),
                t.partial({ defaultValue: t.string }),
            ])
        ),
        t.readonly(
            t.intersection([
                t.type({
                    type: t.literal("number"),
                }),
                t.partial({ defaultValue: t.number }),
            ])
        ),
        t.readonly(
            t.intersection([
                t.type({
                    type: t.literal("dateTime"),
                }),
                t.partial({ defaultValue: glideDateTimeCodec }),
            ])
        ),
        t.readonly(
            t.intersection([
                t.type({
                    type: t.literal("boolean"),
                }),
                t.partial({ defaultValue: t.boolean }),
            ])
        ),
        t.readonly(
            t.intersection([
                t.type({
                    type: t.literal("secret"),
                }),
                // `password`: show the secret as a password field
                // `hidden`: don't show the secret in the UI at all
                // `plain`: show the secret as a plain text field
                // FIXME: `plain` is not supported yet - we need it for SQL
                // The default is `password`.
                t.partial({
                    defaultValue: t.string,
                    display: t.union([
                        t.literal("password"),
                        t.literal("hidden"),
                        t.literal("plain"),
                        t.literal("uuid-picker"),
                    ]),
                }),
            ])
        ),
        t.readonly(
            t.type({
                type: t.literal("generatedKeyPair"),
                kind: t.literal("ssh-rsa-4096"),
            })
        ),

        t.readonly(
            t.intersection([
                t.type({
                    type: t.literal("stringObject"),
                }),
                t.partial({
                    withSecretConstants: t.boolean,
                }),
            ])
        ),

        t.readonly(
            t.intersection([
                t.type({
                    type: t.literal("json"),
                }),
                t.partial({ defaultValue: jsonValueCodec }),
            ])
        ),
        t.readonly(
            t.type({
                type: t.literal("jsonPath"),
                // valueFromProperty should match the parameter name from where we want to get the JSON.
                valueFromProperty: t.string,
            })
        ),
        // No defaultValue below
        t.readonly(
            t.type({
                type: t.union([
                    t.literal("table"),
                    t.literal("array"),
                    t.literal("stringArray"),
                    t.literal("object"),
                    t.literal("jsonObject"),
                ]),
            })
        ),
    ]),
    t.readonly(
        t.type({
            name: t.string,
        })
    ),
    t.readonly(
        t.partial({
            description: t.string,
            placeholder: t.string,
            dependsOn: t.string,
            operator: t.keyof({ is: null, "is-not": null }),
            when: t.readonlyArray(t.union([t.string, t.number, t.boolean])),
            // If defined, will only show the parameter if the computation runs in an action or column accordingly.
            whenInContext: t.union([t.literal("action"), t.literal("column")]),
            required: t.boolean,
            propertySection: t.type({
                name: t.string,
                order: t.number,
                collapsed: t.boolean,
            }),
            emptyByDefault: t.boolean,
            preferredNames: t.array(t.string),
            isUserProfileProperty: t.boolean,
            // Only applicable if `isUserProfileProperty` is `true`.  If set,
            // disallows writing to this column in the user profile via a Set
            // Columns action.
            disallowSettingColumn: t.boolean,
        })
    ),
]);

export type ParameterPropsBase = TypeOfIOTS<typeof parameterPropsCodec>;

const columnSortDirectionsCodec = t.union([t.literal("asc"), t.literal("desc")]);

export const pluginColumnSortCodec = t.tuple([t.string, columnSortDirectionsCodec]);
export type PluginColumnSort = t.TypeOf<typeof pluginColumnSortCodec>;

export const pluginColumnDefinitionCodec = t.intersection([
    t.type({ name: t.string }),
    t.partial({
        displayName: t.string,
        sortableByDirection: t.array(columnSortDirectionsCodec),
    }),
    t.union([
        t.type({ type: t.union([valueTypeWithoutDateCodec, t.literal("enum")]) }),
        t.intersection([t.type({ type: dateTimeTypeCodec }), t.partial({ timezoneAware: t.boolean })]),
    ]),
]);

export const primitiveValueCodec = t.union([t.string, t.number, glideDateTimeCodec, t.null, t.boolean, glideJSONCodec]);

type ArrayValueType = readonly (string | number | GlideDateTime | null | boolean | GlideJSON | ArrayValueType)[];
type ArrayValueTypeEncoded = readonly (
    | string
    | number
    | GlideDateTimeDocumentData
    | null
    | boolean
    | GlideJSONDocumentData
    | ArrayValueTypeEncoded
)[];
const arrayValueCodec: t.Type<ArrayValueType, ArrayValueTypeEncoded> = t.recursion("ArrayValueCodec", self =>
    t.readonlyArray(t.union([primitiveValueCodec, self]))
);

const valueWithoutTableCodec = t.union([
    primitiveValueCodec,
    t.record(t.string, primitiveValueCodec),
    t.record(t.string, t.string),
    t.record(t.string, t.unknown),
    arrayValueCodec,
    t.readonlyArray(jsonValueCodec),
]);

const pluginTableSchemaCodec = t.type({
    name: t.string,
    columns: t.array(pluginColumnDefinitionCodec),
});
export type PluginTableSchema = t.TypeOf<typeof pluginTableSchemaCodec>;

const pluginTableCodec = t.intersection([
    pluginTableSchemaCodec,
    t.type({
        rows: t.array(t.array(valueWithoutTableCodec)),
    }),
]);
export type PluginTable = t.TypeOf<typeof pluginTableCodec>;

export function isPluginTable(x: unknown): x is PluginTable {
    return pluginTableCodec.is(x);
}

export const pluginTableSchemaWithIDCodec = t.intersection([t.type({ id: t.string }), pluginTableSchemaCodec]);
export type PluginTableSchemaWithID = t.TypeOf<typeof pluginTableSchemaWithIDCodec>;

export const pluginKeyPairCodec = t.type({
    publicKey: t.string,
    privateKey: t.string,
});
export type PluginKeyPair = t.TypeOf<typeof pluginKeyPairCodec>;

export const valueCodec = t.union([valueWithoutTableCodec, pluginTableCodec]);
export const valueRecordCodec = t.record(t.string, t.union([valueCodec, pluginTableCodec]));
export type ValueRecord = t.TypeOf<typeof valueRecordCodec>;
export const resultsCodec = t.record(t.string, valueWithoutTableCodec);
export const cacheCodec = t.union([valueWithoutTableCodec, t.UnknownRecord]);

export const tierCodec = t.union([
    t.literal("free"),
    t.literal("starter"),
    t.literal("pro"),
    t.literal("business"),
    t.literal("enterprise"),
    t.literal("education"),
]);

export type PluginTier = t.TypeOf<typeof tierCodec>;

const tierListCodec = t.readonlyArray(tierCodec);
export type PluginTierList = t.TypeOf<typeof tierListCodec>;

const iconOrUrlCodec = t.union([
    t.string, // URL
    glideIconPropsCodec,
]);

export type PluginIcon = t.TypeOf<typeof iconOrUrlCodec>;

// NOTE: this must be kept in sync with `makePluginProps` and `convertToMetadata`
const pluginPropsCodec = t.readonly(
    t.intersection([
        t.type({
            id: t.string,
            name: t.string,
        }),
        t.partial({
            icon: iconOrUrlCodec,
            description: t.string,
            parameters: t.record(t.string, parameterPropsCodec),
            auth: authDefinitionCodec,
            tier: tierListCodec, // default is all tiers
            documentationUrl: t.string,
            disclosure: t.string,
            isNative: t.boolean,
            isExperimental: t.boolean,
            experimentFlag: t.string,
            deprecated: t.boolean,
            badge: t.string,
            supportsMultipleInstances: t.boolean,
        }),
    ])
);
export type PluginProps = t.TypeOf<typeof pluginPropsCodec>;

const billablesConsumedCodec = t.union([t.number, t.type({ number: t.number, per: t.string })]);
export type BillablesConsumed = t.TypeOf<typeof billablesConsumedCodec>;
export function isBillablesConsumedMoreThanZero(
    billable: BillablesConsumed | undefined
): billable is number | { number: number; per: string } {
    if (billable === undefined) return false;
    if (typeof billable === "number") {
        return billable > 0;
    }
    return billable.number > 0;
}

// NOTE: this must be kept in sync with `makeActionProps`
const actionPropsCodec = t.readonly(
    t.intersection([
        t.type({
            name: t.string,
            id: t.string,
            description: t.string,
            type: t.keyof({ server: null, client: null }),
            parameters: t.record(t.string, parameterPropsCodec),
            results: t.record(t.string, parameterPropsCodec),
        }),
        t.partial({
            configurationDescriptionPattern: t.string,
            group: t.string,
            billablesConsumed: billablesConsumedCodec,
            icon: iconOrUrlCodec,
            /**
             * Precedence is plugin/integration group first. Default is all
             * tiers if all are `undefined`.
             * */
            tier: tierListCodec,
            keywords: t.array(t.string),
            deprecated: t.boolean,
            /**
             * If `true` it means the action needs to run from an app.
             * Example: navigation or action that launch other apps on the
             * user device (e.g. compose an email).  Note that both
             * `needsClient` and `needsAutomation` cannot both be `true`.
             */
            needsClient: t.boolean,
            /**
             * If `true`, this action needs to run in an automated workflow,
             * i.e. cannot run in an app.  Note that both `needsClient` and
             * `needsAutomation` cannot both be `true`.
             */
            needsAutomation: t.boolean,
            /** Defaults to `false`. */
            isIdempotent: t.boolean,
            /** Defaults to `false`. */
            showAsSpecialValue: t.boolean,
            experimentFlag: t.string,
        }),
    ])
);
export type ActionProps = t.TypeOf<typeof actionPropsCodec>;

// NOTE: this must be kept in sync with `makeQueryableDataSourceProps`
const dataSourcePropsCodec = t.readonly(
    t.intersection([
        t.type({
            name: t.string,
            id: t.string,
            description: t.string,
            parameters: t.record(t.string, parameterPropsCodec),
        }),
        t.partial({
            icon: t.string,
            tier: tierListCodec, // default is all tiers
        }),
    ])
);
export type DataSourceProps = t.TypeOf<typeof dataSourcePropsCodec>;

// NOTE: this must be kept in sync with `makeSignOnProps`
const signOnPropsCodec = t.readonly(
    t.type({
        name: t.string,
        id: t.string,
        description: t.string,
    })
);
export type SignOnProps = t.TypeOf<typeof signOnPropsCodec>;

// NOTE: this must be kept in sync with `makeTriggerProps`
const triggerPropsCodec = t.readonly(
    t.intersection([
        t.type({
            name: t.string,
            id: t.string,
            description: t.string,
            parameters: t.record(t.string, parameterPropsCodec),
            results: t.record(t.string, parameterPropsCodec),
        }),
        t.partial({
            experimentFlag: t.string,
            badge: t.string,
        }),
    ])
);
export type TriggerProps = t.TypeOf<typeof triggerPropsCodec>;

// NOTE: this must be kept in sync with `makeNotificationSenderProps`
const notificationSenderPropsCodec = t.readonly(
    t.type({
        id: t.string,
        name: t.string,
        description: t.string,
        priority: t.number,
        billablesConsumed: t.union([billablesConsumedCodec, t.undefined]),
    })
);
export type NotificationSenderProps = t.TypeOf<typeof notificationSenderPropsCodec>;

const clientGlideAPICodec = t.type({
    id: t.string,
    tier: tierListCodec,
    capabilities: apiCapabilitiesCodec,
});
export type ClientGlideAPI = t.TypeOf<typeof clientGlideAPICodec>;

// NOTE: this must be kept in sync with `convertToMetadata`
const serializablePluginMetadataCodec = t.intersection([
    pluginPropsCodec,
    t.readonly(
        t.type({
            actions: t.readonlyArray(actionPropsCodec),
            computations: t.readonlyArray(actionPropsCodec),
            signOns: t.readonlyArray(signOnPropsCodec),
            triggers: t.readonlyArray(triggerPropsCodec),
            hasEventTracker: t.boolean,
        })
    ),
    t.partial({
        queryableDataSources: t.readonlyArray(dataSourcePropsCodec),
        notificationSenders: t.readonlyArray(notificationSenderPropsCodec),
        clientGlideAPIs: t.readonlyArray(clientGlideAPICodec),
    }),
]);
export const serializablePluginsMetadataCodec = t.readonlyArray(serializablePluginMetadataCodec);
export type SerializablePluginMetadata = t.TypeOf<typeof serializablePluginMetadataCodec>;
export function isSerializablePluginsMetadata(x: unknown): x is SerializablePluginMetadata[] {
    return t.readonlyArray(serializablePluginMetadataCodec).is(x);
}

// We can expand this to allow more types if we need to.
export const endpointDataCodec = t.record(t.string, t.string);
export type EndpointData = t.TypeOf<typeof endpointDataCodec>;
