import { getFontScale } from "@glide/common";
import { styled } from "@glide/common-components";
import * as React from "react";

const Style = styled.div<{ scale: number }>`
    --glide-font-scale: ${p => p.scale};
`;

const FontScaleProvider: React.FunctionComponent<React.PropsWithChildren> = p => {
    const [scale, setScale] = React.useState(() => getFontScale());

    React.useEffect(() => {
        const handle = setInterval(() => {
            const newScale = getFontScale(true);
            if (newScale !== scale) {
                setScale(newScale);
            }
        }, 10 * 1000);

        function cleanup() {
            clearInterval(handle);
        }

        return cleanup;
    }, [scale]);

    return <Style scale={scale}>{p.children}</Style>;
};
export default FontScaleProvider;
