import type {
    ComponentDescription,
    ActionComponentDescription,
    ComponentKind,
    LegacyPropertyDescription,
    MutatingScreenKind,
    ActionDescription,
} from "@glide/app-description";
import {
    ActionKind,
    getSwitchProperty,
    makeActionProperty,
    makeArrayProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeIconProperty,
    makeStringProperty,
} from "@glide/app-description";
import { type TableColumn, getTableColumnDisplayName, isPrimitiveType } from "@glide/type-schema";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { makeSMSURL, makeTelephoneURL } from "@glide/common-core/dist/js/urls";
import type { WireAppPhoneNumberComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    PropertySection,
    SwitchPropertyHandler,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { assert } from "@glideapps/ts-necessities";
import {
    type WireRowComponentHydratorConstructor,
    type WireAction,
    WireComponentKind,
    type WireInflationBackend,
} from "@glide/wire";

import {
    hydrateActionWithRunnerAfter,
    hydrateOpenURL,
    inflateActions,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerBusyActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { ActionComponentHandlerBase } from "./action-component";
import { getActionsForComponent } from "./component-utils";
import { makeCaptionStringPropertyDescriptor } from "./descriptor-utils";

const ComponentKindPhoneNumber: ComponentKind = "phone-number";

const withMessagePropertyHandler = new SwitchPropertyHandler(
    { withMessage: true },
    "Allow text messages",
    PropertySection.Design
);

const withDialPropertyHandler = new SwitchPropertyHandler(
    { withDial: true },
    "Allow phone calls",
    PropertySection.Design
);

interface PhoneNumberComponentDescription extends ActionComponentDescription {
    readonly propertyName: LegacyPropertyDescription;
    readonly messageBody: LegacyPropertyDescription | undefined;
    readonly caption: LegacyPropertyDescription | undefined;
    readonly withDial: LegacyPropertyDescription | undefined;
    readonly withMessage: LegacyPropertyDescription | undefined;
}

export class PhoneNumberComponentHandler extends ActionComponentHandlerBase<PhoneNumberComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindPhoneNumber);
    }

    public getDescriptor(
        desc: PhoneNumberComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const withMessage = withMessagePropertyHandler.getSwitch(desc);

        const properties = [
            makeTextPropertyDescriptor("propertyName", "Column", "Phone number", true, mutatingScreenKind, {
                isDefaultCaption: true,
                columnFirst: true,
            }),
            makeCaptionStringPropertyDescriptor("Phone", false, mutatingScreenKind),
            withDialPropertyHandler,
            withMessagePropertyHandler,
            ...this.getBasePropertyDescriptors(),
        ];

        if (withMessage) {
            properties.splice(
                2,
                0,
                makeTextPropertyDescriptor("messageBody", "Message body", "SMS text", false, mutatingScreenKind, {
                    emptyByDefault: true,
                    searchable: false,
                    columnFirst: true,
                    propertySection: PropertySection.Design,
                })
            );
        }

        return {
            name: "Phone",
            description: "A phone number that you can call or text",
            img: "co-phone",
            group: "Buttons",
            helpUrl: getDocURL("phoneNumber"),
            properties,
        };
    }

    public static defaultComponent(column: TableColumn): PhoneNumberComponentDescription {
        assert(isPrimitiveType(column.type));

        // FIXME: It would be nice if we could have this statically typed.
        return {
            ...makeEmptyComponentDescription(ComponentKindPhoneNumber),
            actions: [],
            propertyName: makeColumnProperty(column.name),
            messageBody: undefined,
            withDial: undefined,
            withMessage: undefined,
            caption: makeStringProperty(getTableColumnDisplayName(column)),
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: PhoneNumberComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const withMessage = withMessagePropertyHandler.getSwitch(desc);
        const withDial = withDialPropertyHandler.getSwitch(desc);
        if (!withMessage && !withDial) return undefined;

        const [numberGetter, numberType] = inflateStringProperty(ib, desc.propertyName, false);
        if (numberType === undefined) return undefined;

        const [numberWithFormatGetter] = inflateStringProperty(ib, desc.propertyName, true);

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);

        const [bodyGetter] = inflateStringProperty(ib, desc.messageBody, true);

        const { actions } = getActionsForComponent(this, desc, ib.tables, ib.adc, ib.mutatingScreenKind);
        const actionHydrator = inflateActions(ib, actions);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            let onPhoneCall: WireAction | undefined;
            let onTextMessage: WireAction | undefined;

            const phoneNumber = numberGetter(hb) ?? "";
            if (phoneNumber === null || phoneNumber === "") return undefined;

            const caption = captionGetter(hb);

            if (withDial) {
                const phoneURL = makeTelephoneURL(phoneNumber);
                if (phoneURL !== undefined) {
                    onPhoneCall = registerBusyActionRunner(hb, "phone", () =>
                        hydrateActionWithRunnerAfter(
                            actionHydrator,
                            hb,
                            false,
                            caption ?? undefined,
                            ...hydrateOpenURL(undefined, phoneURL, {})
                        )
                    );
                }
            }
            if (withMessage) {
                const body = bodyGetter(hb) ?? undefined;
                const smsURL = makeSMSURL(phoneNumber, body);
                if (smsURL !== undefined) {
                    onTextMessage = registerBusyActionRunner(hb, "sms", () =>
                        hydrateActionWithRunnerAfter(
                            actionHydrator,
                            hb,
                            false,
                            caption ?? undefined,
                            ...hydrateOpenURL(undefined, smsURL, {})
                        )
                    );
                }
            }

            const component: WireAppPhoneNumberComponent = {
                kind: WireComponentKind.AppPhoneNumber,
                ...spreadComponentID(desc.componentID, forBuilder),
                caption: caption ?? "",
                number: numberWithFormatGetter(hb) ?? "",
                onPhoneCall,
                onTextMessage,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: PhoneNumberComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        const dialAction =
            getSwitchProperty(desc.withDial) === true
                ? [
                      {
                          title: makeStringProperty("Dial phone number"),
                          icon: makeIconProperty("path:/svg/stroke/st-telephone.svg"),
                          action: makeActionProperty({
                              kind: ActionKind.PhoneCallWithArgs,
                              number: desc.propertyName,
                          } as ActionDescription),
                      },
                  ]
                : [];
        const smsAction =
            getSwitchProperty(desc.withMessage) === true
                ? [
                      {
                          title: makeStringProperty("Send text message"),
                          icon: makeIconProperty("path:/svg/stroke/st-message-circle.svg"),
                          action: makeActionProperty({
                              kind: ActionKind.TextMessageWithArgs,
                              number: desc.propertyName,
                              body: desc.messageBody,
                          } as ActionDescription),
                      },
                  ]
                : [];
        return {
            ...makeEmptyComponentDescription(WireComponentKind.ActionRow),
            text: desc.propertyName,
            label: desc.caption,
            textStyle: makeEnumProperty("Field"),
            imageStyle: makeEnumProperty("image-style-circle"),
            actions: makeArrayProperty([...dialAction, ...smsAction]),
        } as ComponentDescription;
    }
}
