import type { ComputationModel } from "@glide/computation-model-types";
import {
    type TableName,
    areTableNamesEqual,
    getTableColumn,
    isDataSourceColumn,
    isBigTableOrExternal,
} from "@glide/type-schema";
import { MutatingScreenKind, type SerializedSearchableColumns } from "@glide/app-description";
import { memoizeFunction, DefaultArrayMap } from "@glide/support";
import { doesTableAllowAddingUserSpecificColumns } from "@glide/common-core/dist/js/schema-properties";
import type { SearchableColumns } from "@glide/wire";
import type { AppDescriptionContext } from "@glide/function-utils";
import { assert, DefaultMap } from "@glideapps/ts-necessities";
import { setUnionInto } from "collection-utils";
import { type SearchResult, ColumnsUsedVisitor, SearchResultKind } from "./find-column-uses";
import { AppVisitorCache, walkAppDescription } from "./walk-app-description";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { isColumnAllowedForSearching } from "../allowed-columns";

function makeUses(): SearchableColumns {
    return {
        columnsUsedByTable: new DefaultArrayMap<TableName, Set<string>>(areTableNamesEqual, () => new Set()),
        columnsUsedByInlineLists: new DefaultMap<string, Set<string>>(() => new Set()),
    };
}

function mergeUsesInto(dest: SearchableColumns, src: SearchableColumns): void {
    for (const [tn, columns] of src.columnsUsedByTable) {
        setUnionInto(dest.columnsUsedByTable.get(tn), columns);
    }
    for (const [componentID, columns] of src.columnsUsedByInlineLists) {
        setUnionInto(dest.columnsUsedByInlineLists.get(componentID), columns);
    }
}

export function serializeSearchableColumns(uses: SearchableColumns): SerializedSearchableColumns {
    return {
        columnsUsedByTable: Array.from(uses.columnsUsedByTable).map(([k, v]) => [k, Array.from(v)]),
        columnsUsedByInlineLists: Array.from(uses.columnsUsedByInlineLists).map(([k, v]) => [k, Array.from(v)]),
    };
}

export function deserializeSearchableColumns(serialized: SerializedSearchableColumns): SearchableColumns {
    const uses = makeUses();
    for (const [k, v] of serialized.columnsUsedByTable) {
        uses.columnsUsedByTable.set(k, new Set(v));
    }
    for (const [k, v] of serialized.columnsUsedByInlineLists) {
        uses.columnsUsedByInlineLists.set(k, new Set(v));
    }
    return uses;
}

class Visitor extends ColumnsUsedVisitor<SearchableColumns> {
    public readonly uses = makeUses();
    private currentUses: SearchableColumns | undefined;

    constructor(ccc: AppDescriptionContext, private readonly cm: ComputationModel) {
        super(ccc);
    }

    public addState(state: SearchableColumns): void {
        assert(this.currentUses === undefined);
        mergeUsesInto(this.uses, state);
    }

    public willVisitWithState(state: SearchableColumns): void {
        assert(this.currentUses === undefined);
        this.currentUses = state;
    }

    public finishedVisitingWithState(state: SearchableColumns): void {
        assert(this.currentUses === state);
        mergeUsesInto(this.uses, state);
        this.currentUses = undefined;
    }

    protected addColumnUsed(tn: TableName, cn: string, searchResult: SearchResult): void {
        // `Screen` search results come from properties in array screens, such
        // as the title and subtitles of lists.
        if (searchResult.kind !== SearchResultKind.Component && searchResult.kind !== SearchResultKind.Screen) return;
        if (searchResult.descriptorCase?.searchable !== true) return;

        // Inline Lists can search, too, and their content properties are
        // indirect, so we allow searching those.  We will end up with
        // searchable columns that might not be visible in the detail screen
        // we're looking at, but that's how our search works right now - it's
        // "one list of searchable columns fits all".

        // Add and Form screens don't "read" from written columns
        if (
            searchResult.isWritten &&
            searchResult.mutatingScreenKind !== undefined &&
            searchResult.mutatingScreenKind !== MutatingScreenKind.EditScreen
        ) {
            return;
        }

        const table = this.ccc.findTable(tn);
        if (table === undefined) return;

        let isSlow = false;
        if (isBigTableOrExternal(table)) {
            const column = getTableColumn(table, cn);
            if (column === undefined) return;
            const gbtComputedColumnsAlpha = isExperimentEnabled("gbtComputedColumnsAlpha", this.ccc.userFeatures);
            const gbtDeepLookups = isExperimentEnabled("gbtDeepLookups", this.ccc.userFeatures);
            // We support user-specific search in queries only for Big Tables.
            const isUserSpecificSearch = isDataSourceColumn(column, doesTableAllowAddingUserSpecificColumns(table));
            if (!isUserSpecificSearch && !gbtComputedColumnsAlpha) return;

            const isComputedColumnAllowed = isColumnAllowedForSearching(
                this.ccc,
                table,
                column,
                undefined, // We don't need the computation model for queryables
                gbtComputedColumnsAlpha,
                gbtDeepLookups
            );

            if (!isComputedColumnAllowed) return;
        } else {
            const info = this.cm.getInfoForColumn(tn, cn);

            // If we search in columns that come from queries we will
            // potentially set off a huge number of queries.
            // https://github.com/quicktype/glide/issues/19514
            if (info === undefined || info.fromQuery) return;
            if (info.isSlow) {
                isSlow = true;
            }
        }

        const uses = this.currentUses ?? this.uses;

        // This is so we include ##slowColumnsInSearch if they're displayed in
        // the list.
        if (
            searchResult.kind === SearchResultKind.Component &&
            searchResult.descriptorCase.getIndirectTable !== undefined
        ) {
            uses.columnsUsedByInlineLists.get(searchResult.componentID).add(cn);
        }

        if (isSlow) return;

        uses.columnsUsedByTable.get(tn).add(cn);
    }
}

const cache = new AppVisitorCache<SearchableColumns>(makeUses);

export const getSearchableColumns = memoizeFunction(
    "getSearchableColumns",
    (adc: AppDescriptionContext, cm: ComputationModel, withCache: boolean) => {
        const visitor = new Visitor(adc, cm);
        walkAppDescription(adc, visitor, [], { cache: withCache ? cache : undefined });
        return visitor.uses;
    }
);
