import * as React from "react";

import { Smartphone } from "./output-pad-style";
import { SmartphoneInner } from "sharedUI/ui/views/smartphone/smartphone";

interface Props extends React.PropsWithChildren {
    width?: number;
    height?: number;
    scale?: number;
}

export default class OutputPad extends React.Component<Props> {
    public render() {
        const content = this.props.children;
        return (
            <Smartphone width={this.props.width} height={this.props.height} scale={this.props.scale} round={55}>
                <SmartphoneInner round={55}>{content}</SmartphoneInner>
            </Smartphone>
        );
    }
}
