import { GlideIcon } from "@glide/common";
import { motion } from "framer-motion";
import { VoiceVisualizer, type VoiceVisualizerHandle } from "./voice-visualizer";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { useEffect, useRef, useState } from "react";
import { isDefined } from "@glide/support";

interface RecorderUIProps {
    readonly isRecordingInProgress: boolean;
    readonly onStartRecording: () => void;
    readonly onCancelRecording: () => void;
    readonly onFinishRecording: () => void;
    readonly state: "idle" | "recording" | "processing" | "error";
    readonly startRecordingOnMount: boolean;
    readonly getAudioData: () => Float32Array;
    readonly getFormattedTime: () => string;
}

export const RecorderUI: React.FC<RecorderUIProps> = p => {
    const {
        onStartRecording,
        onCancelRecording,
        onFinishRecording,
        state,
        getAudioData,
        getFormattedTime,
        startRecordingOnMount,
    } = p;

    const theme = useWireAppTheme();
    const visualizerRef = useRef<VoiceVisualizerHandle>(null);
    const isInitialRender = useRef(true);
    const [displayTime, setDisplayTime] = useState("00:00");

    useEffect(() => {
        if (isInitialRender.current && startRecordingOnMount) {
            onStartRecording();
            isInitialRender.current = false;
        }
    }, [onStartRecording, startRecordingOnMount]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        const updateDisplayTime = () => {
            setDisplayTime(getFormattedTime());
        };

        if (state === "recording") {
            updateDisplayTime();
            intervalId = setInterval(updateDisplayTime, 1000);
        } else {
            setDisplayTime("00:00");
        }

        return () => {
            if (isDefined(intervalId)) {
                clearInterval(intervalId);
            }
            setDisplayTime("00:00");
        };
    }, [state, getFormattedTime]);

    const isRecordingInProgress = state === "recording" || state === "processing" || state === "error";

    const handleCancelRecording = () => {
        visualizerRef.current?.cleanup();
        onCancelRecording();
    };

    const handleFinishRecording = () => {
        visualizerRef.current?.cleanup();
        onFinishRecording();
    };

    return (
        <div tw="relative w-full h-11">
            {/* Inactive state layer */}
            <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: isRecordingInProgress ? 0 : 1 }}
                transition={{
                    duration: 0.2,
                }}
                style={{ pointerEvents: isRecordingInProgress ? "none" : "auto" }}
                tw="absolute inset-0 z-10 flex items-center justify-end rounded-full px-2 pr-1.5">
                <motion.button
                    whileTap={{ scale: 0.95 }}
                    transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 17,
                    }}
                    tw="flex items-center justify-center !w-9 !h-9 rounded-[36px] bg-accent"
                    onClick={onStartRecording}>
                    <GlideIcon icon={"st-microphone"} kind="stroke" iconSize={20} tw="text-w100A" />
                </motion.button>
            </motion.div>

            {/* Active state layer */}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: isRecordingInProgress ? 1 : 0 }}
                data-error={state === "error"}
                transition={{
                    duration: 0.2,
                }}
                style={{
                    pointerEvents: isRecordingInProgress ? "auto" : "none",
                    visibility: isRecordingInProgress ? "visible" : "hidden",
                }}
                tw="absolute inset-0 z-20 flex items-center bg-n200 data-[error=true]:bg-r200 rounded-full">
                <div tw="flex items-center gap-2 w-full h-full px-2 pr-1.5">
                    <motion.button
                        whileTap={{ scale: 0.95 }}
                        data-error={state === "error"}
                        transition={{
                            type: "spring",
                            stiffness: 400,
                            damping: 17,
                        }}
                        tw="flex items-center justify-center shrink-0 !w-6 !h-6 rounded-[36px] bg-n200A data-[error=true]:bg-r300 text-icon-dark data-[error=true]:text-r500"
                        onClick={handleCancelRecording}>
                        <GlideIcon icon={"st-close"} kind="stroke" iconSize={16} />
                    </motion.button>

                    <div tw="flex-1 h-full">
                        <VoiceVisualizer
                            ref={visualizerRef}
                            getAudioData={getAudioData}
                            isRecording={state === "recording"}
                            barColor={state === "error" ? theme.r500 : theme.iconDark}
                            secondaryBarColor={state === "error" ? theme.r300 : theme.iconPale}
                        />
                    </div>

                    <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isRecordingInProgress ? 1 : 0 }}
                        data-error={state === "error"}
                        transition={{
                            duration: 0.2,
                        }}
                        tw="tabular-nums shrink-0 font-semibold text-builder-sm text-text-base data-[error=true]:text-r500">
                        {state === "error" ? "Error" : displayTime}
                    </motion.p>

                    <motion.button
                        whileTap={{ scale: 0.95 }}
                        data-error={state === "error"}
                        disabled={state === "processing"}
                        transition={{
                            type: "spring",
                            stiffness: 400,
                            damping: 17,
                        }}
                        tw="flex items-center justify-center !w-9 !h-9 rounded-[36px] bg-accent data-[error=true]:bg-r400"
                        onClick={handleFinishRecording}>
                        {state === "processing" ? (
                            <GlideIcon icon={"st-spinner"} kind="stroke" iconSize={20} tw="text-w100A" spin />
                        ) : state === "error" ? (
                            <GlideIcon icon={"st-reload"} kind="stroke" iconSize={20} tw="text-w100A" />
                        ) : (
                            <GlideIcon icon={"st-check"} kind="stroke" iconSize={20} tw="text-w100A" />
                        )}
                    </motion.button>
                </div>
            </motion.div>
        </div>
    );
};
