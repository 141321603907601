import { isLoadingValue } from "@glide/computation-model-types";
import { asMaybeNumber, isRow } from "@glide/common-core/dist/js/computation-model/data";
import { type PropertyDescription, ActionKind, MutatingScreenKind } from "@glide/app-description";
import { type ActionWithOutputRowDescription, makeInputOutputTables } from "@glide/common-core/dist/js/description";
import { sheetNameForTable } from "@glide/type-schema";
import {
    type AppDescriptionContext,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    RequiredKind,
    makeNumberPropertyDescriptor,
    getPrimitiveNonHiddenColumnsSpec,
    makeSingleRelationOrThisItemPropertyDescriptor,
} from "@glide/function-utils";
import { nullToUndefined } from "@glide/support";
import {
    type WireActionResult,
    type WireActionResultBuilder,
    makeContextTableTypes,
    type WireActionHydrator,
    type WireActionInflationBackend,
} from "@glide/wire";
import { getRelationForDescription, makeGetIndirectTableForActionWithOutputRow } from "../description-utils";
import type { StaticActionContext } from "../static-context";
import { getCanEditRowFromNetworkStatus, makeRowGetter } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import type { DescriptionToken } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { getSupportsUserProfileRowAccess, getTokenizedSourceColumn } from "./set-columns";
import { ICON_BASE, LIME_500 } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins";

interface IncrementActionDescription extends ActionWithOutputRowDescription {
    readonly kind: ActionKind.Increment;
    readonly sourceColumn: PropertyDescription;
    readonly amount: PropertyDescription;
}

const getRelation = getRelationForDescription({
    inOutputRow: true,
    onlySingleRelations: true,
    defaultToThisRow: true,
    allowFullTable: false,
});
const getIndirectTable = makeGetIndirectTableForActionWithOutputRow(getRelation);

const columnPropertyHandler = new ColumnPropertyHandler(
    "sourceColumn",
    "Column",
    [
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DisallowPriorSteps,
    ],
    getIndirectTable,
    ["count", "sum"],
    getPrimitiveNonHiddenColumnsSpec,
    "number",
    PropertySection.Data
);

export class IncrementActionHandler extends BaseActionHandler<IncrementActionDescription> {
    public readonly kind = ActionKind.Increment;

    public readonly iconName: GlideIconProps = {
        icon: "st-exposure",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: LIME_500,
    };

    public readonly name = "Increment number";

    public getDescriptor(
        _desc: IncrementActionDescription | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Data,
            groupItemOrder: 4,
            needsScreenContext: true,
            properties: [
                makeSingleRelationOrThisItemPropertyDescriptor(
                    "outputRow",
                    "Row",
                    PropertySection.Data,
                    true,
                    getSupportsUserProfileRowAccess(ccc)
                ),
                columnPropertyHandler,
                makeNumberPropertyDescriptor(
                    "amount",
                    "Increment by",
                    "Enter a number",
                    RequiredKind.Required,
                    1,
                    mutatingScreenKind,
                    {
                        preferredNames: ["increment", "amount"],
                        isEditedInApp: false,
                    }
                ),
            ],
        };
    }

    public getTokenizedDescription(
        desc: IncrementActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const tokenizedRow = getTokenizedSourceColumn(desc.outputRow, "in", false, env);
        if (tokenizedRow === undefined) return undefined;

        if (tokenizedRow.length === 0) {
            const amountToken = tokenForProperty(desc.amount, env);
            const columntoken = tokenForProperty(desc.sourceColumn, env);
            if (columntoken === undefined || amountToken === undefined) return undefined;

            return [
                columntoken,
                {
                    kind: "string",
                    value: " by ",
                },
                amountToken,
            ];
        } else {
            return tokenizedRow;
        }
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: IncrementActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { eminenceFlags },
        } = ib;

        const destination = makeRowGetter(ib, desc.outputRow, { inOutputRow: true, defaultToThisRow: true });
        if (destination === undefined || destination === false) return arb.inflationError("Invalid destination row");
        const { table: destTable, rowGetter: destRowGetter } = destination;
        arb = arb.addData({ tableName: sheetNameForTable(destTable) });

        const destTables = makeInputOutputTables(destTable);
        const destIB = ib.makeInflationBackendForTables(makeContextTableTypes(destTables), ib.mutatingScreenKind);

        const { setterMaker, tableAndColumn } = destIB.getValueSetterForProperty(desc.sourceColumn, "increment-column");
        const [currentValueGetter] = destIB.getValueGetterForProperty(desc.sourceColumn, false);
        const [amountGetter] = ib.getValueGetterForProperty(desc.amount, false);

        const columnName = tableAndColumn?.column.name;
        if (columnName === undefined) return arb.inflationError("Invalid column");
        arb = arb.addData({ columnName });

        return (vp, skipLoading) => {
            const outputRow = destRowGetter(vp);
            if (isLoadingValue(outputRow)) return arb.maybeSkipLoading(skipLoading, "Destination row");
            if (outputRow === null || !isRow(outputRow)) return arb.error(true, "No destination row");

            if (!getCanEditRowFromNetworkStatus(outputRow, vp, eminenceFlags, MutatingScreenKind.EditScreen)) {
                return arb.offline();
            }

            const outputVP = vp.makeHydrationBackendForRow(outputRow, outputRow, destTables);

            const currentValue = nullToUndefined(currentValueGetter(outputVP));
            if (isLoadingValue(currentValue)) return arb.maybeSkipLoading(skipLoading, "Current value");
            const current = asMaybeNumber(currentValue) ?? 0;

            const amountValue = nullToUndefined(amountGetter(vp));
            if (isLoadingValue(amountValue)) return arb.maybeSkipLoading(skipLoading, "Amount");
            const amount = asMaybeNumber(amountValue) ?? 1;

            const nextValue = current + amount;

            const setTargetColumn = setterMaker(outputVP);
            if (isLoadingValue(setTargetColumn)) {
                return arb.errorIfSkipLoading(skipLoading, "Write to target column");
            }
            if (setTargetColumn === undefined) {
                return arb.error(true, "Can't write target column");
            }

            return async ab => {
                const result = await setTargetColumn(ab, nextValue);
                return arb.addData({ currentValue: current, nextValue }).fromResult(result);
            };
        };
    }
}
