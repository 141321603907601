import { GlideIcon } from "@glide/common";
import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { OrgIcon } from "../org-icon";
import type { DropResult } from "react-beautiful-dnd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { isUndefinedish } from "@glide/support";
import { isUserOrgID } from "../../lib/orgs-lib";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { SearchField } from "../search-field/search-field";
import { RadixPopoverContent } from "../../lib/radix-lib";

interface ListItemProps {
    team: TeamItem;
    selected: boolean;
    isTrigger: boolean;
    isDraggable: boolean;
    plan?: string;
    teamID: string;
}
const TeamListItem: React.FC<React.PropsWithChildren<ListItemProps>> = props => {
    const { team, selected, isTrigger, isDraggable, plan, teamID } = props;
    return (
        <div
            tw="flex gap-2 items-center w-full min-w-0 select-none"
            className="group"
            data-selected={selected}
            data-trigger={isTrigger}
            data-draggable={isDraggable}>
            <div tw="relative w-7 h-7 rounded-[7px] group-data-[trigger=true]:(w-9 h-9 rounded-lg) overflow-hidden shrink-0">
                {team.imageURL !== undefined ? (
                    <>
                        <img
                            tw="object-cover w-full h-full"
                            alt="team logo"
                            src={massageImageUrl(team.imageURL, { thumbnail: true }, teamID)}
                        />
                        <span
                            aria-hidden="true"
                            tw="absolute inset-0 rounded-[7px] group-data-[trigger=true]:rounded-lg [box-shadow: inset 0 0 0 1px rgb(128 128 128 / 8%)]"></span>
                    </>
                ) : (
                    <OrgIcon
                        size={isTrigger ? "larger" : "large"}
                        label={team.name}
                        backgroundColor={team.backgroundColor}
                    />
                )}
            </div>
            <div tw="flex overflow-hidden flex-col flex-1 gap-1">
                <div tw="text-text-dark font-semibold text-left truncate leading-snug text-builder-base group-data-[trigger=true]:text-builder-xl">
                    {team.name}
                </div>
                {plan && <div tw="font-semibold leading-none text-left text-aqua500 text-builder-sm">{plan}</div>}
            </div>
            {!isTrigger && (
                <div tw="p-1 group-data-[draggable=true]:cursor-grab">
                    {isDraggable ? (
                        <>
                            <GlideIcon
                                icon="st-drag"
                                kind="stroke"
                                iconSize={16}
                                tw="hidden text-icon-pale group-hover:block"
                            />
                            <GlideIcon
                                icon="st-check"
                                kind="stroke"
                                iconSize={16}
                                tw="text-text-accent hidden group-data-[selected=true]:block group-hover:hidden!"
                            />
                        </>
                    ) : (
                        <GlideIcon
                            icon="st-check"
                            kind="stroke"
                            iconSize={16}
                            tw="text-text-accent hidden group-data-[selected=true]:block"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

interface TeamItem {
    id: string;
    name: string;
    backgroundColor?: string;
    imageURL?: string;
}

interface Props extends React.PropsWithChildren {
    teams: TeamItem[];
    currentTeamId: string;
    currentTeamPlan: string;
    onChange: (teamId: string) => void;
    onCreateNew: () => void;
    onSort: (sourceIndex: number, destinationIndex: number) => Promise<void>;
}

const MenuItem = styled.div`
    ${tw`p-1.5 hover:bg-n200A data-[highlighted]:bg-n200A rounded-lg text-builder-base cursor-pointer`}
`;

export const TeamSwitcher: React.FC<React.PropsWithChildren<Props>> = props => {
    const { teams, currentTeamId, currentTeamPlan, onChange, onCreateNew, onSort } = props;
    const [query, setQuery] = useState("");
    const [portalContainer] = useState(() => document.getElementById("portal"));
    const [open, setOpen] = useState(false);

    if (teams.length === 0) {
        return null;
    }

    const currentTeam = teams.find(t => t.id === currentTeamId);

    if (currentTeam === undefined) {
        return null;
    }

    const isFiltering = query.trim() !== "";

    return (
        <Popover.Root open={open} onOpenChange={setOpen}>
            <Popover.Trigger tw="w-full p-2 flex items-center gap-1 rounded-xl transition-colors hover:bg-n200A data-[state=open]:bg-n200A">
                <TeamListItem
                    team={currentTeam}
                    selected={false}
                    isTrigger={true}
                    isDraggable={false}
                    plan={currentTeamPlan}
                    teamID={currentTeamId}
                />
                <GlideIcon kind="stroke" icon="st-chevron-down" iconSize={16} tw="m-1 text-icon-base shrink-0" />
            </Popover.Trigger>

            <Popover.Portal container={portalContainer}>
                <RadixPopoverContent
                    tw="p-1 min-w-[232px] max-w-[400px] shadow-lg-dark bg-bg-front rounded-xl overflow-y-auto"
                    align="start">
                    {teams.length > 20 && (
                        <SearchField value={query} placeholder="Search" onChange={setQuery} tw="mb-1" />
                    )}
                    <DragDropContext
                        onDragEnd={async (result: DropResult) => {
                            if (isUndefinedish(result.source) || isUndefinedish(result.destination)) return;
                            await onSort(result.source.index, result.destination.index);
                        }}>
                        <Droppable
                            droppableId="orgs"
                            renderClone={(draggableProps, _snapshot, rubric) => {
                                const t = teams.find(t2 => t2.id === rubric.draggableId);
                                if (t === undefined) return <div></div>;
                                return (
                                    <div
                                        key={t.id}
                                        ref={draggableProps.innerRef}
                                        {...draggableProps.dragHandleProps}
                                        {...draggableProps.draggableProps}>
                                        <MenuItem key={t.id}>
                                            <TeamListItem
                                                team={t}
                                                selected={t.id === currentTeamId}
                                                isTrigger={false}
                                                isDraggable={false}
                                                teamID={currentTeamId}
                                            />
                                        </MenuItem>
                                    </div>
                                );
                            }}
                            getContainerForClone={portalContainer === null ? undefined : () => portalContainer}>
                            {droppableProps => (
                                <div ref={droppableProps.innerRef} {...droppableProps.droppableProps}>
                                    {teams
                                        .filter(
                                            t =>
                                                !isFiltering ||
                                                t.name.toLowerCase().indexOf(query.trim().toLowerCase()) > -1
                                        )
                                        .map((t, index) => (
                                            <Draggable
                                                key={t.id}
                                                draggableId={t.id}
                                                index={index}
                                                isDragDisabled={isUserOrgID(t.id) || isFiltering}>
                                                {draggableProps => (
                                                    <div
                                                        key={t.id}
                                                        ref={draggableProps.innerRef}
                                                        {...draggableProps.dragHandleProps}
                                                        {...draggableProps.draggableProps}>
                                                        <MenuItem
                                                            key={t.id}
                                                            onClick={() => {
                                                                onChange(t.id);
                                                                setOpen(false);
                                                                setQuery("");
                                                            }}
                                                            className="group">
                                                            <TeamListItem
                                                                team={t}
                                                                selected={t.id === currentTeamId}
                                                                isTrigger={false}
                                                                isDraggable={!isUserOrgID(t.id) && !isFiltering}
                                                                teamID={currentTeamId}
                                                            />
                                                        </MenuItem>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {droppableProps.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <MenuItem onClick={onCreateNew}>
                        <div tw="flex gap-2 justify-center items-center grow">
                            <div tw="flex justify-center items-center w-7 h-7 rounded-lg bg-n300A">
                                <GlideIcon kind="stroke" icon="st-plus-add" tw="text-icon-base" iconSize={16} />
                            </div>
                            <div tw="font-semibold text-text-base text-builder-base grow">New team</div>
                        </div>
                    </MenuItem>
                </RadixPopoverContent>
            </Popover.Portal>
        </Popover.Root>
    );
};
