/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { isUndefinedish, maybeParseJSON } from "@glide/support";

const getPropValue = <T>(object: any, path: string): T | undefined =>
    path.split(".").reduce((o, x) => (isUndefinedish(o) ? o : o[x]), object);

const { Result } = glide;

const apiKey = glide.makeParameter({
    type: "secret",
    name: "API key",
    description: "You can find your API key in your [Clearbit dashboard](https://dashboard.clearbit.com/api)",
    required: true,
});

export const plugin = glide.newPlugin({
    id: "clearbit",
    name: "Clearbit",
    description: "Enrich your contacts with data from Clearbit",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/clearbit.png",
    tier: "starter",
    parameters: {
        apiKey,
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/clearbit",
});

const email = glide.makeParameter({
    type: "string",
    name: "Email",
    description: "The email of the contact you would like to enrich",
    placeholder: "e.g. alma@example.com",
    required: true,
    useTemplate: "withLabel",
});

const companyId = glide.makeParameter({
    type: "string",
    name: "company/id",
    description:
        "Company id (internal id). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyName = glide.makeParameter({
    type: "string",
    name: "company/name",
    description:
        "Company name. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyLegalName = glide.makeParameter({
    type: "string",
    name: "company/legalName",
    description:
        "Legal name of company. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyDomain = glide.makeParameter({
    type: "string",
    name: "company/domain",
    description:
        "Domain of company's website. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyDomainAliases = glide.makeParameter({
    type: "stringArray",
    name: "company/domainAliases",
    description:
        "List of domains also used by the company. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companySitePhoneNumbers = glide.makeParameter({
    type: "stringArray",
    name: "company/site/phoneNumbers",
    description:
        "List of phone numbers mentioned on the company's website. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companySiteEmailAddresses = glide.makeParameter({
    type: "stringArray",
    name: "company/site/emailAddresses",
    description:
        "List of email addresses mentioned on the company's website. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyTags = glide.makeParameter({
    type: "stringArray",
    name: "company/tags",
    description:
        "List of market categories (see a complete list). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyCategorySector = glide.makeParameter({
    type: "string",
    name: "company/category/sector",
    description:
        "Broad sector (see a complete list). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyCategoryIndustryGroup = glide.makeParameter({
    type: "string",
    name: "company/category/industryGroup",
    description:
        "Industry group (see a complete list). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyCategoryIndustry = glide.makeParameter({
    type: "string",
    name: "company/category/industry",
    description:
        "Industry (see a complete list). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyCategorySubIndustry = glide.makeParameter({
    type: "string",
    name: "company/category/subIndustry",
    description:
        "Sub industry (see a complete list). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyCategorySicCode = glide.makeParameter({
    type: "string",
    name: "company/category/sicCode",
    description:
        "Two digit category SIC code. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyCategoryNaicsCode = glide.makeParameter({
    type: "string",
    name: "company/category/naicsCode",
    description:
        "Two digit category NAICS code. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyDescription = glide.makeParameter({
    type: "string",
    name: "company/description",
    description:
        "Description of the company. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyFoundedYear = glide.makeParameter({
    type: "number",
    name: "company/foundedYear",
    description:
        "Year company was founded. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyLocation = glide.makeParameter({
    type: "string",
    name: "company/location",
    description:
        "Address of company. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyTimeZone = glide.makeParameter({
    type: "string",
    name: "company/timeZone",
    description:
        "The timezone for the company's location. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyUtcOffset = glide.makeParameter({
    type: "number",
    name: "company/utcOffset",
    description:
        "The offset from UTC in hours in the company's location. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyGeoStreetNumber = glide.makeParameter({
    type: "string",
    name: "company/geo/streetNumber",
    description:
        "Headquarters street number. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyGeoStreetName = glide.makeParameter({
    type: "string",
    name: "company/geo/streetName",
    description:
        "Headquarters street name. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyGeoSubPremise = glide.makeParameter({
    type: "string",
    name: "company/geo/subPremise",
    description:
        "Headquarters suite number. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyGeoCity = glide.makeParameter({
    type: "string",
    name: "company/geo/city",
    description:
        "Headquarters city name. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyGeoState = glide.makeParameter({
    type: "string",
    name: "company/geo/state",
    description:
        "Headquarters state name. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyGeoStateCode = glide.makeParameter({
    type: "string",
    name: "company/geo/stateCode",
    description:
        "Headquarters two character state code. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyGeoPostalCode = glide.makeParameter({
    type: "string",
    name: "company/geo/stateCode",
    description:
        "Headquarters two character state code. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyGeoCountry = glide.makeParameter({
    type: "string",
    name: "company/geo/country",
    description:
        "Headquarters country name. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyGeoCountryCode = glide.makeParameter({
    type: "string",
    name: "company/geo/countryCode",
    description:
        "Headquarters two character country code. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyGeoLat = glide.makeParameter({
    type: "number",
    name: "company/geo/lat",
    description:
        "Headquarters latitude. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyGeoLng = glide.makeParameter({
    type: "number",
    name: "company/geo/lng",
    description:
        "Headquarters longitude. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyIdentifiersUsEIN = glide.makeParameter({
    type: "string",
    name: "company/identifiers/usEIN",
    description:
        "US Employer Identification Number. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyMetricsRaised = glide.makeParameter({
    type: "number",
    name: "company/metrics/raised",
    description:
        "Total amount raised. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyMetricsAlexaUsRank = glide.makeParameter({
    type: "number",
    name: "company/metrics/alexaUsRank",
    description:
        "Alexa's US site rank. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyMetricsAlexaGlobalRank = glide.makeParameter({
    type: "number",
    name: "company/metrics/alexaGlobalRank",
    description:
        "Alexa’s global site rank. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyMetricsEmployees = glide.makeParameter({
    type: "number",
    name: "company/metrics/employees",
    description:
        "Amount of employees. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyMetricsEmployeesRange = glide.makeParameter({
    type: "string",
    name: "company/metrics/employeesRange",
    description:
        "Employees range (see a complete list). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyMetricsMarketCap = glide.makeParameter({
    type: "number",
    name: "company/metrics/marketCap",
    description:
        "Market Cap. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyMetricsAnnualRevenue = glide.makeParameter({
    type: "number",
    name: "company/metrics/annualRevenue",
    description:
        "Annual Revenue (public companies only). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyMetricsEstimatedAnnualRevenue = glide.makeParameter({
    type: "string",
    name: "company/metrics/estimatedAnnualRevenue",
    description:
        "Estimated annual revenue range (see a complete list). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyMetricsFiscalYearEnd = glide.makeParameter({
    type: "number",
    name: "company/metrics/fiscalYearEnd",
    description:
        "Month that the fiscal year ends (1-indexed). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyFacebookHandle = glide.makeParameter({
    type: "string",
    name: "company/facebook/handle",
    description:
        "Company’s Facebook ID. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyLinkedinHandle = glide.makeParameter({
    type: "string",
    name: "company/linkedin/handle",
    description:
        "Company’s Linkedin URL. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyTwitterHandle = glide.makeParameter({
    type: "string",
    name: "company/twitter/handle",
    description:
        "Twitter screen name. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyTwitterId = glide.makeParameter({
    type: "number",
    name: "company/twitter/id",
    description:
        "Twitter ID. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyTwitterBio = glide.makeParameter({
    type: "string",
    name: "company/twitter/bio",
    description:
        "Twitter Bio. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyTwitterFollowers = glide.makeParameter({
    type: "number",
    name: "company/twitter/followers",
    description:
        "Count of Twitter followers. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyTwitterFollowing = glide.makeParameter({
    type: "number",
    name: "company/twitter/following",
    description:
        "Count of Twitter friends. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyTwitterLocation = glide.makeParameter({
    type: "string",
    name: "company/twitter/location",
    description:
        "Twitter location. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyTwitterSite = glide.makeParameter({
    type: "string",
    name: "company/twitter/site",
    description:
        "Twitter site. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyTwitterAvatar = glide.makeParameter({
    type: "string",
    name: "company/twitter/avatar",
    description:
        "HTTP Twitter avatar. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyCrunchbaseHandle = glide.makeParameter({
    type: "string",
    name: "company/crunchbase/handle",
    description:
        "Crunchbase handle. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyLogo = glide.makeParameter({
    type: "string",
    name: "company/log",
    description:
        "SRC of company logo. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyEmailProvider = glide.makeParameter({
    type: "string", // FIXME this comes in as a bool tho...
    name: "company/emailProvider",
    description:
        "is the domain associated with a free email provider (i.e. Gmail)?. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyType = glide.makeParameter({
    type: "string",
    name: "company/type",
    description:
        "The company’s type, either education, government, nonprofit, private, public, or personal.. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyPhone = glide.makeParameter({
    type: "string",
    name: "company/phone",
    description:
        "International headquarters phone number. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyTech = glide.makeParameter({
    type: "stringArray",
    name: "company/tech",
    description:
        "Array of technology tags. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyTechCategories = glide.makeParameter({
    type: "stringArray",
    name: "company/techCategories",
    description:
        "Array of technology categories used by the company. [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
});

const companyParentDomain = glide.makeParameter({
    type: "string",
    name: "company/parent/domain",
    description:
        "The domain of the parent company (if any). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyUltimateParentDomain = glide.makeParameter({
    type: "string",
    name: "company/ultimateParent/domain",
    description:
        "The domain of the ultimate parent company (if any). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

const companyIndexedAt = glide.makeParameter({
    type: "string",
    name: "company/indexedAt",
    description:
        "The domain of the ultimate parent company (if any). [Learn more](https://dashboard.clearbit.com/docs?shell#enrichment-api-company-api-attributes)",
    useTemplate: "withLabel",
});

// Person
const personId = glide.makeParameter({
    type: "string",
    name: "id",
    description:
        "Person id (internal id). [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGivenName = glide.makeParameter({
    type: "string",
    name: "name/givenName",
    description:
        "First name of person (if found). [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personFamilyName = glide.makeParameter({
    type: "string",
    name: "name/familyName",
    description:
        "Last name of person (if found). [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personFullName = glide.makeParameter({
    type: "string",
    name: "name/fullName",
    description:
        "Full formatted name of person/ Sometimes this will be present even if the givenName or familyName aren't available. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personLocation = glide.makeParameter({
    type: "string",
    name: "location",
    description:
        "The most accurate location we have. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personTimeZone = glide.makeParameter({
    type: "string",
    name: "timeZone",
    description:
        "The timezone for the person's location. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personUtcOffset = glide.makeParameter({
    type: "number",
    name: "utcOffset",
    description:
        "The offset from UTC in hours in the person's location. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
});

const personGeoCity = glide.makeParameter({
    type: "string",
    name: "geo/city",
    description:
        "Normalized city based on location. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGeoState = glide.makeParameter({
    type: "string",
    name: "geo/state",
    description:
        "Normalized state based on location. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGeoCountry = glide.makeParameter({
    type: "string",
    name: "geo/country",
    description:
        "Normalized two letter country code based on location. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGeoLat = glide.makeParameter({
    type: "number",
    name: "geo/lat",
    description:
        "General latitude based on location. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
});

const personGeoLng = glide.makeParameter({
    type: "number",
    name: "geo/lng",
    description:
        "General longitude based on location. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
});

const personBio = glide.makeParameter({
    type: "string",
    name: "bio",
    description:
        "The most accurate bio we have. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personSite = glide.makeParameter({
    type: "string",
    name: "site",
    description:
        "The person's website. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personAvatar = glide.makeParameter({
    type: "string",
    name: "avatar",
    description:
        "The best avatar url we have. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personEmploymentName = glide.makeParameter({
    type: "string",
    name: "employment/name",
    description: "Company name. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personEmploymentTitle = glide.makeParameter({
    type: "string",
    name: "employment/title",
    description:
        "Title at Company. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personEmploymentRole = glide.makeParameter({
    type: "string",
    name: "employment/role",
    description:
        "Role at Company. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personEmploymentSubRole = glide.makeParameter({
    type: "string",
    name: "employment/subRole",
    description:
        "Sub-Role at Company. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personEmploymentSeniority = glide.makeParameter({
    type: "string",
    name: "employment/seniority",
    description:
        "Seniority at Company. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personEmploymentDomain = glide.makeParameter({
    type: "string",
    name: "employment/domain",
    description:
        "Company domain. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personFacebookHandle = glide.makeParameter({
    type: "string",
    name: "facebook/handle",
    description:
        "Facebook ID or screen name. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGithubHandle = glide.makeParameter({
    type: "string",
    name: "github/handle",
    description:
        "GitHub handle. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGithubId = glide.makeParameter({
    type: "number",
    name: "github/id",
    description: "GitHub ID. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
});

const personGithubAvatar = glide.makeParameter({
    type: "string",
    name: "github/avatar",
    description:
        "GitHub avatar. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGithubCompany = glide.makeParameter({
    type: "string",
    name: "github/company",
    description:
        "GitHub company. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGithubBlog = glide.makeParameter({
    type: "string",
    name: "github/blog",
    description:
        "GitHub blog url. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGithubFollowers = glide.makeParameter({
    type: "string",
    name: "github/followers",
    description:
        "Count of GitHub followers. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGithubFollowing = glide.makeParameter({
    type: "string",
    name: "github/following",
    description:
        "Count of GitHub following. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personTwitterHandle = glide.makeParameter({
    type: "string",
    name: "twitter/handle",
    description:
        "Twitter screen name. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personTwitterId = glide.makeParameter({
    type: "number",
    name: "twitter/id",
    description: "Twitter ID. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
});

const personTwitterFollowers = glide.makeParameter({
    type: "number",
    name: "twitter/followers",
    description:
        "Count of Twitter followers. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
});

const personTwitterFollowing = glide.makeParameter({
    type: "number",
    name: "twitter/following",
    description:
        "Count of Twitter friends. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
});

const personTwitterLocation = glide.makeParameter({
    type: "string",
    name: "twitter/location",
    description:
        "Twitter Location. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personTwitterSite = glide.makeParameter({
    type: "string",
    name: "twitter/site",
    description: "Twitter Site. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personTwitterStatuses = glide.makeParameter({
    type: "number",
    name: "twitter/statuses",
    description: "Tweet count. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
});

const personTwitterFavorites = glide.makeParameter({
    type: "string",
    name: "twitter/favorites",
    description:
        "Favorite count. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personTwitterAvatar = glide.makeParameter({
    type: "string",
    name: "twitter/avatar",
    description:
        "HTTP Twitter avatar. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personLinkedinHandle = glide.makeParameter({
    type: "string",
    name: "linkedin/handle",
    description:
        "LinkedIn url (i.e. /pub/alex-maccaw/78/929/ab5). [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGoogleplusHandle = glide.makeParameter({
    type: "string",
    name: "googleplus/handle",
    description:
        "Google Plus handle. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGravatarHandle = glide.makeParameter({
    type: "string",
    name: "gravatar/handle",
    description:
        "Gravatar handle. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGravatarUrls = glide.makeParameter({
    type: "stringArray",
    name: "gravatar/urls",
    description:
        "Array of URLs from Gravatar. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
});

const personGravatarAvatar = glide.makeParameter({
    type: "string",
    name: "gravatar/avatar",
    description:
        "Gravatar main avatar url. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personGravatarAvatars = glide.makeParameter({
    type: "string",
    name: "gravatar/avatars",
    description:
        "Array of objects containing a avatar url, and a type (i.e. thumbnail). [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personFuzzy = glide.makeParameter({
    type: "string", //FIXME boolean...
    name: "fuzzy",
    description:
        "Indicating whether or not the lookup is a fuzzy or exact search. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
});

const personEmailProvider = glide.makeParameter({
    type: "string", // FIXME this comes in as a bool tho...
    name: "emailProvider",
    description:
        "is the email domain associated with a free email provider (i.e. Gmail)?. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

const personIndexedAt = glide.makeParameter({
    type: "string",
    name: "indexedAt",
    description:
        "The time at which we indexed this data. [Learn more](https://dashboard.clearbit.com/docs#enrichment-api-person-api-attributes)",
    useTemplate: "withLabel",
});

plugin.addComputation({
    id: "enrich-contact",
    name: "Enrich contact",
    description: "Enrich your contact with Clearbit data",
    parameters: { email },
    billablesConsumed: 3,
    execute: async (context, { email, apiKey }) => {
        const clearbitUrl = `https://person-stream.clearbit.com/v2/combined/find?email=${email}`;

        return await context.useCache(async () => {
            const response = await context.fetch(clearbitUrl, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                },
            });
            if (!response.ok) {
                const asText = await response.text();
                return Result.FailFromHTTPStatus(asText, response.status, {
                    data: maybeParseJSON(asText),
                });
            }

            context.consumeBillable();
            const json = await response.json();

            return Result.Ok({
                companyId: getPropValue<string>(json, "company.id"),
                companyName: getPropValue<string>(json, "company.name"),
                companyLegalName: getPropValue<string>(json, "company.legalName"),
                companyDomain: getPropValue<string>(json, "company.domain"),
                companyDomainAliases: getPropValue<string[]>(json, "company.domainAliases"),
                companySitePhoneNumbers: getPropValue<string[]>(json, "company.site.phoneNumbers"),
                companySiteEmailAddresses: getPropValue<string[]>(json, "company.site.emailAddresses"),
                companyTags: getPropValue<string[]>(json, "company.tags"),
                companyCategorySector: getPropValue<string>(json, "company.category.sector"),
                companyCategoryIndustryGroup: getPropValue<string>(json, "company.category.industryGroup"),
                companyCategoryIndustry: getPropValue<string>(json, "company.category.industry"),
                companyCategorySubIndustry: getPropValue<string>(json, "company.category.subIndustry"),
                companyCategorySicCode: getPropValue<string>(json, "company.category.sicCode"),
                companyCategoryNaicsCode: getPropValue<string>(json, "company.category.naicsCode"),
                companyDescription: getPropValue<string>(json, "company.description"),
                companyFoundedYear: getPropValue<number>(json, "company.foundedYear"),
                companyLocation: getPropValue<string>(json, "company.location"),
                companyTimeZone: getPropValue<string>(json, "company.timeZone"),
                companyUtcOffset: getPropValue<number>(json, "company.utcOffset"),
                companyGeoStreetName: getPropValue<string>(json, "company.geo.streetName"),
                companyGeoStreetNumber: getPropValue<string>(json, "company.geo.streetNumber"),
                companyGeoSubPremise: getPropValue<string>(json, "company.geo.subPremise"),
                companyGeoCity: getPropValue<string>(json, "company.geo.city"),
                companyGeoState: getPropValue<string>(json, "company.geo.state"),
                companyGeoStateCode: getPropValue<string>(json, "company.geo.stateCode"),
                companyGeoPostalCode: getPropValue<string>(json, "company.geo.postalCode"),
                companyGeoCountry: getPropValue<string>(json, "company.geo.country"),
                companyGeoCountryCode: getPropValue<string>(json, "company.geo.countryCode"),
                companyGeoLat: getPropValue<number>(json, "company.geo.lat"),
                companyGeoLng: getPropValue<number>(json, "company.geo.lng"),
                companyIdentifiersUsEIN: getPropValue<string>(json, "company.identifiers.usEIN"),
                companyMetricsRaised: getPropValue<number>(json, "company.metrics.raised"),
                companyMetricsAlexaUsRank: getPropValue<number>(json, "company.metrics.alexaUsRank"),
                companyMetricsAlexaGlobalRank: getPropValue<number>(json, "company.metrics.alexaGlobalRank"),
                companyMetricsEmployees: getPropValue<number>(json, "company.metrics.employees"),
                companyMetricsEmployeesRange: getPropValue<string>(json, "company.metrics.employeesRange"),
                companyMetricsMarketCap: getPropValue<number>(json, "company.metrics.marketCap"),
                companyMetricsAnnualRevenue: getPropValue<number>(json, "company.metrics.annualRevenue"),
                companyMetricsEstimatedAnnualRevenue: getPropValue<string>(
                    json,
                    "company.metrics.estimatedAnnualRevenue"
                ),
                companyMetricsFiscalYearEnd: getPropValue<number>(json, "company.metrics.fiscalYearEnd"),
                companyFacebookHandle: getPropValue<string>(json, "company.facebook.handle"),
                companyLinkedinHandle: getPropValue<string>(json, "company.linkedin.handle"),
                companyTwitterHandle: getPropValue<string>(json, "company.twitter.handle"),
                companyTwitterId: getPropValue<number>(json, "company.twitter.id"),
                companyTwitterBio: getPropValue<string>(json, "company.twitter.bio"),
                companyTwitterFollowers: getPropValue<number>(json, "company.twitter.followers"),
                companyTwitterFollowing: getPropValue<number>(json, "company.twitter.following"),
                companyTwitterLocation: getPropValue<string>(json, "company.twitter.location"),
                companyTwitterSite: getPropValue<string>(json, "company.twitter.site"),
                companyTwitterAvatar: getPropValue<string>(json, "company.twitter.avatar"),
                companyCrunchbaseHandle: getPropValue<string>(json, "company.crunchbase.handle"),
                companyLogo: getPropValue<string>(json, "company.company.logo"),
                companyEmailProvider: getPropValue<string>(json, "company.emailProvider"),
                companyType: getPropValue<string>(json, "company.type"),
                companyPhone: getPropValue<string>(json, "company.phone"),
                companyTech: getPropValue<string[]>(json, "company.tech"),
                companyTechCategories: getPropValue<string[]>(json, "company.techCategories"),
                companyParentDomain: getPropValue<string>(json, "company.parent.domain"),
                companyUltimateParentDomain: getPropValue<string>(json, "company.ultimateParent.domain"),
                companyIndexedAt: getPropValue<string>(json, "company.indexedAt"),
                personId: getPropValue<string>(json, "person.id"),
                personGivenName: getPropValue<string>(json, "person.name.givenName"),
                personFamilyName: getPropValue<string>(json, "person.name.familyName"),
                personFullName: getPropValue<string>(json, "person.name.fullName"),
                personLocation: getPropValue<string>(json, "person.location"),
                personTimeZone: getPropValue<string>(json, "person.timeZone"),
                personUtcOffset: getPropValue<number>(json, "person.utcOffset"),
                personGeoCity: getPropValue<string>(json, "person.geo.city"),
                personGeoState: getPropValue<string>(json, "person.geo.state"),
                personGeoCountry: getPropValue<string>(json, "person.geo.country"),
                personGeoLat: getPropValue<number>(json, "person.geo.lat"),
                personGeoLng: getPropValue<number>(json, "person.geo.lng"),
                personBio: getPropValue<string>(json, "person.bio"),
                personSite: getPropValue<string>(json, "person.site"),
                personAvatar: getPropValue<string>(json, "person.avatar"),
                personEmploymentName: getPropValue<string>(json, "person.employment.name"),
                personEmploymentTitle: getPropValue<string>(json, "person.employment.title"),
                personEmploymentRole: getPropValue<string>(json, "person.employment.role"),
                personEmploymentSubRole: getPropValue<string>(json, "person.employment.subRole"),
                personEmploymentSeniority: getPropValue<string>(json, "person.employment.seniority"),
                personEmploymentDomain: getPropValue<string>(json, "person.employment.domain"),
                personFacebookHandle: getPropValue<string>(json, "person.facebook.handle"),
                personGithubHandle: getPropValue<string>(json, "person.github.handle"),
                personGithubId: getPropValue<number>(json, "person.github.id"),
                personGithubAvatar: getPropValue<string>(json, "person.github.avatar"),
                personGithubCompany: getPropValue<string>(json, "person.github.company"),
                personGithubBlog: getPropValue<string>(json, "person.github.blog"),
                personGithubFollowers: getPropValue<string>(json, "person.github.followers"),
                personGithubFollowing: getPropValue<string>(json, "person.github.following"),
                personTwitterHandle: getPropValue<string>(json, "person.twitter.handle"),
                personTwitterId: getPropValue<number>(json, "person.twitter.id"),
                personTwitterFollowers: getPropValue<number>(json, "person.twitter.followers"),
                personTwitterFollowing: getPropValue<number>(json, "person.twitter.following"),
                personTwitterLocation: getPropValue<string>(json, "person.twitter.location"),
                personTwitterSite: getPropValue<string>(json, "person.twitter.site"),
                personTwitterStatuses: getPropValue<number>(json, "person.twitter.statuses"),
                personTwitterFavorites: getPropValue<string>(json, "person.twitter.favorites"),
                personTwitterAvatar: getPropValue<string>(json, "person.twitter.avatar"),
                personLinkedinHandle: getPropValue<string>(json, "person.linkedin.handle"),
                personGoogleplusHandle: getPropValue<string>(json, "person.googleplug.handle"),
                personGravatarHandle: getPropValue<string>(json, "person.gravatar.handle"),
                personGravatarUrls: getPropValue<string[]>(json, "person.gravatar.urls"),
                personGravatarAvatar: getPropValue<string>(json, "person.gravatar.avatar"),
                personGravatarAvatars: getPropValue<string>(json, "person.gravatar.avatars"),
                personFuzzy: getPropValue<string>(json, "person.fuzzy"),
                personEmailProvider: getPropValue<string>(json, "person.emailiProvider"),
                personIndexedAt: getPropValue<string>(json, "person.indexedAt"),
            });
        }, [email]);
    },
    results: {
        companyId,
        companyName,
        companyLegalName,
        companyDomain,
        companyDomainAliases,
        companySitePhoneNumbers,
        companySiteEmailAddresses,
        companyTags,
        companyCategorySector,
        companyCategoryIndustryGroup,
        companyCategoryIndustry,
        companyCategorySubIndustry,
        companyCategorySicCode,
        companyCategoryNaicsCode,
        companyDescription,
        companyFoundedYear,
        companyLocation,
        companyTimeZone,
        companyUtcOffset,
        companyGeoStreetName,
        companyGeoStreetNumber,
        companyGeoSubPremise,
        companyGeoCity,
        companyGeoState,
        companyGeoStateCode,
        companyGeoPostalCode,
        companyGeoCountry,
        companyGeoCountryCode,
        companyGeoLat,
        companyGeoLng,
        companyIdentifiersUsEIN,
        companyMetricsRaised,
        companyMetricsAlexaUsRank,
        companyMetricsAlexaGlobalRank,
        companyMetricsEmployees,
        companyMetricsEmployeesRange,
        companyMetricsMarketCap,
        companyMetricsAnnualRevenue,
        companyMetricsEstimatedAnnualRevenue,
        companyMetricsFiscalYearEnd,
        companyFacebookHandle,
        companyLinkedinHandle,
        companyTwitterHandle,
        companyTwitterId,
        companyTwitterBio,
        companyTwitterFollowers,
        companyTwitterFollowing,
        companyTwitterLocation,
        companyTwitterSite,
        companyTwitterAvatar,
        companyCrunchbaseHandle,
        companyLogo,
        companyEmailProvider,
        companyType,
        companyPhone,
        companyTech,
        companyTechCategories,
        companyParentDomain,
        companyUltimateParentDomain,
        companyIndexedAt,
        personId,
        personGivenName,
        personFamilyName,
        personFullName,
        personLocation,
        personTimeZone,
        personUtcOffset,
        personGeoCity,
        personGeoState,
        personGeoCountry,
        personGeoLat,
        personGeoLng,
        personBio,
        personSite,
        personAvatar,
        personEmploymentName,
        personEmploymentTitle,
        personEmploymentRole,
        personEmploymentSubRole,
        personEmploymentSeniority,
        personEmploymentDomain,
        personFacebookHandle,
        personGithubHandle,
        personGithubId,
        personGithubAvatar,
        personGithubCompany,
        personGithubBlog,
        personGithubFollowers,
        personGithubFollowing,
        personTwitterHandle,
        personTwitterId,
        personTwitterFollowers,
        personTwitterFollowing,
        personTwitterLocation,
        personTwitterSite,
        personTwitterStatuses,
        personTwitterFavorites,
        personTwitterAvatar,
        personLinkedinHandle,
        personGoogleplusHandle,
        personGravatarHandle,
        personGravatarUrls,
        personGravatarAvatar,
        personGravatarAvatars,
        personFuzzy,
        personEmailProvider,
        personIndexedAt,
    },
});
