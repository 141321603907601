import { assert, panic } from "@glideapps/ts-necessities";
import type { ScreenDescription } from "@glide/app-description";
import { isFormScreen, MutatingScreenKind, ScreenDescriptionKind } from "@glide/app-description";
import { isAddClassScreenName, isEditClassScreenName, isFormScreenName } from "./screen-names";

export function getMutatingKindForScreen(
    screenName: string | undefined,
    description: ScreenDescription | undefined
): MutatingScreenKind | undefined {
    if (description !== undefined) {
        if (description.kind !== ScreenDescriptionKind.Class) return undefined;
        if (isFormScreen(description)) {
            assert(screenName === undefined || isFormScreenName(screenName));
        }
    }

    if (screenName === undefined) return undefined;

    if (isEditClassScreenName(screenName)) {
        if (isAddClassScreenName(screenName)) {
            return panic("Edit screen is also add screen?");
        } else if (description !== undefined && isFormScreen(description)) {
            return panic("Edit screen is also form screen?");
        }
        return MutatingScreenKind.EditScreen;
    } else if (isAddClassScreenName(screenName)) {
        if (description !== undefined && isFormScreen(description)) {
            return panic("Add screen is also form screen?");
        }
        return MutatingScreenKind.AddScreen;
    } else if (description !== undefined && isFormScreen(description)) {
        return MutatingScreenKind.FormScreen;
    }
    return undefined;
}
