import type { Formula } from "@glide/type-schema";
import { rewriteFormula } from "./rewrite-formula";

export function getFormulaDepth(rootFormula: Formula): number {
    const [, depth] = rewriteFormula<number>(
        rootFormula,
        (f, subDepths) => {
            const d = subDepths.length > 0 ? Math.max(...subDepths) + 1 : 1;
            return [f, d];
        },
        undefined
    );
    return depth;
}

export function isFormulaDepthOK(f: Formula): boolean {
    return getFormulaDepth(f) <= 150;
}
