import type { DataStoreMutationMetadata } from "@glide/common-core/dist/js/components/types";
import type { RowIndex } from "@glide/computation-model-types";
import type { TableName } from "@glide/type-schema";
import type { ColumnValues } from "@glide/common-core/dist/js/firebase-function-types";
import { exceptionToError } from "@glideapps/ts-necessities";
import deepEqual from "deep-equal";

export interface DebounceContextFreeArguments extends DataStoreMutationMetadata {
    readonly values: ColumnValues;
    readonly fromBuilder: boolean;
    readonly jobID: string;
}

export interface DebounceKeySerializable {
    readonly appID: string;
    readonly appUserID: string | undefined;
    readonly tableName: TableName;
    readonly rowIndex: RowIndex;
}

interface DebounceKey<T> extends DebounceKeySerializable {
    readonly executionTarget: (key: DebounceKey<T>, value: DebounceContextFreeArguments) => Promise<T>;
}

interface DebounceValue<T> extends DebounceContextFreeArguments {
    readonly pendingPromises: { resolve: (id: T) => void; reject: (e: Error) => void }[];
    readonly timeoutID: ReturnType<typeof setTimeout>;
}

type DebounceMap<T> = Map<DebounceKey<T>, DebounceValue<T>>;

interface DebounceMapArguments<T> extends DebounceKey<T>, DebounceContextFreeArguments {
    readonly debounceTimeout: number;
}

export class PostActionDebouncer<T> {
    private readonly _debounceEntries: DebounceMap<T> = new Map();

    constructor(
        private readonly isBuilder: boolean,
        private readonly onJobObsolete: (tableName: TableName, jobIDA: string, jobIDB: string) => void
    ) {}

    public async debounceOperation(args: DebounceMapArguments<T>, runImmediately: boolean): Promise<T> {
        const {
            appID,
            appUserID,
            tableName,
            rowIndex,
            executionTarget,
            values,
            fromDataEditor,
            screenPath,
            debounceTimeout,
            jobID,
        } = args;
        const prior = Array.from(this._debounceEntries).find(
            ([key]) =>
                key.appID === appID &&
                key.appUserID === appUserID &&
                deepEqual(key.tableName, tableName, { strict: true }) &&
                deepEqual(key.rowIndex, rowIndex, { strict: true }) &&
                key.executionTarget === executionTarget
        );

        const executeAfterDebounce = async (key: DebounceKey<T>) => {
            const value = this._debounceEntries.get(key);
            if (value === undefined) return;
            const { executionTarget: innerExecutionTarget } = key;
            const { pendingPromises } = value;
            this._debounceEntries.delete(key);
            try {
                const result = await innerExecutionTarget(key, value);
                for (const { resolve } of pendingPromises) {
                    resolve(result);
                }
            } catch (e: unknown) {
                for (const { reject } of pendingPromises) {
                    reject(exceptionToError(e));
                }
            }
        };

        return new Promise((resolve, reject) => {
            let mapKey: DebounceKey<T>;
            let mapValue: DebounceValue<T>;
            if (prior === undefined) {
                mapKey = {
                    appID,
                    appUserID,
                    tableName,
                    rowIndex,
                    executionTarget,
                };
                mapValue = {
                    values,
                    fromBuilder: this.isBuilder,
                    fromDataEditor,
                    screenPath,
                    pendingPromises: [{ resolve, reject }],
                    timeoutID: setTimeout(async () => {
                        await executeAfterDebounce(mapKey);
                    }, debounceTimeout),
                    jobID,
                };
            } else {
                [mapKey] = prior;
                const [, priorValue] = prior;
                clearTimeout(priorValue.timeoutID);
                this.onJobObsolete(tableName, priorValue.jobID, jobID);

                mapValue = {
                    values: { ...priorValue.values, ...values },
                    fromBuilder: this.isBuilder || priorValue.fromBuilder,
                    fromDataEditor: fromDataEditor || priorValue.fromDataEditor,
                    screenPath: screenPath ?? priorValue.screenPath,
                    pendingPromises: [...priorValue.pendingPromises, { resolve, reject }],
                    timeoutID: setTimeout(async () => {
                        await executeAfterDebounce(mapKey);
                    }, debounceTimeout),
                    jobID,
                };
            }
            this._debounceEntries.set(mapKey, mapValue);

            if (runImmediately) {
                // I wish this wasn't this ugly, but I didn't come up with a
                // better, short way of doing this.
                clearTimeout(mapValue.timeoutID);
                void executeAfterDebounce(mapKey);
            }
        });
    }
}
