import { UIBackgroundStyle, UIStyleVariant } from "@glide/wire";
import { css } from "@glide/common-components";
import { useSectionStyle } from "../wire-container/wire-container";
import tw from "twin.macro";
import type { TablePropertiesBindState } from "./card-collection-table-shared";
import { TableActionsButton, TextWithEllipsis } from "./card-collection-table-shared";
import { ignore } from "@glide/support";

interface TableHeaderProps {
    boundProperties: TablePropertiesBindState;
    title?: string;
    subtitle?: string;
    emphasis?: string;
}
export const TableHeader: React.VFC<TableHeaderProps> = p => {
    const { title, subtitle, emphasis, boundProperties } = p;
    const boundPropertiesCount = Object.values(boundProperties).filter(Boolean).length;

    const sectionStyle = useSectionStyle();
    return (
        <div
            css={css`
                .flat.${UIStyleVariant.Default} & {
                    ${tw`bg-transparent after:border-none`}
                }

                .dark.flat.${UIStyleVariant.Default} & {
                    ${tw`after:border-none bg-bg-container-base`}
                }

                .${UIStyleVariant.Minimal} & {
                    ${tw`pl-1 pr-2 bg-transparent rounded-lg`}
                }
            `}
            tw="relative hidden px-4 h-9 items-center bg-bg-container-base overflow-hidden after:(content-[''] absolute
                inset-0 pointer-events-none border-b border-border-base)
                gp-md:flex">
            <div
                className={sectionStyle}
                css={css`
                    grid-template-columns: repeat(${boundPropertiesCount}, minmax(0, 1fr));

                    .${UIBackgroundStyle.Image} & {
                        ${tw`text-text-dark`}
                    }

                    .${UIStyleVariant.Minimal} & {
                        ${tw`text-text-contextual-base`}
                    }
                `}
                tw="flex-1 grid gap-2 leading-tight text-xs uppercase font-semibold text-text-contextual-dark
                    all-child:(overflow-hidden whitespace-nowrap overflow-ellipsis)">
                {boundProperties.title && (
                    <div tw="flex items-center">
                        <TextWithEllipsis>{title}</TextWithEllipsis>
                    </div>
                )}
                {boundProperties.subtitle && (
                    <div tw="flex items-center">
                        <TextWithEllipsis>{subtitle}</TextWithEllipsis>
                    </div>
                )}
                {boundProperties.emphasis && (
                    <div tw="flex items-center">
                        <TextWithEllipsis>{emphasis}</TextWithEllipsis>
                    </div>
                )}
            </div>

            {/*
                    Kind of hacky. We want the space that the buttons will take in the table rows,
                    but we don't want to hardcode it. Alternatively we could make a huge grid out of
                    the table, but this works and it's easy to understand ¯\_(ツ)_/¯
                */}
            <TableActionsButton menuActions={[]} hasClickAction={false} onItemClick={ignore} />
        </div>
    );
};
