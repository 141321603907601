import { getLocalizedString } from "@glide/localization";
import { isLoadingValue } from "@glide/computation-model-types";
import { asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import { ActionKind } from "@glide/app-description";
import {
    type AppDescriptionContext,
    EnumPropertyHandler,
    makeInlineTemplatePropertyDescriptor,
    PropertySection,
    type ActionAvailability,
} from "@glide/function-utils";
import { isEmptyOrUndefined, nullToUndefined } from "@glide/support";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import { defined } from "@glideapps/ts-necessities";
import type { StaticActionContext } from "../static-context";
import { type ShowToastActionDescription, ToastIcon } from "../form-on-submit";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

const iconPropertyHandler = new EnumPropertyHandler(
    { icon: ToastIcon.Success },
    "Icon",
    "Icon",
    [
        {
            label: "Success",
            value: ToastIcon.Success,
        },
        {
            label: "Failure",
            value: ToastIcon.Failure,
        },
    ],
    PropertySection.Data,
    "dropdown"
);

function isSuccess(desc: ShowToastActionDescription): boolean {
    return iconPropertyHandler.getEnum(desc) === ToastIcon.Success;
}

export class ShowToastHandler extends BaseActionHandler<ShowToastActionDescription> {
    public readonly kind = ActionKind.ShowToast;

    public readonly iconName: GlideIconProps = {
        icon: "st-notification",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Show notification";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: ShowToastActionDescription | undefined,
        { mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 12,
            needsScreenContext: true,
            properties: [
                makeInlineTemplatePropertyDescriptor(
                    "message",
                    "Message",
                    "Success",
                    true,
                    "withLabel",
                    mutatingScreenKind,
                    {
                        preferredNames: ["message", "toast"],
                        searchable: false,
                        emptyByDefault: true,
                    }
                ),
                iconPropertyHandler,
            ],
        };
    }

    public getTokenizedDescription(
        desc: ShowToastActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const columnToken = tokenForProperty(desc.message, env);
        if (columnToken === undefined) return undefined;

        return [
            {
                kind: "string",
                value: isSuccess(desc) ? "🎉 " : "❗️ ",
            },
            columnToken,
        ];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: ShowToastActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const { appKind } = ib.adc;

        const success = isSuccess(desc);
        arb = arb.addData({ success });

        const [messageGetter] = ib.getValueGetterForProperty(desc.message, true);

        return (vp, skipLoading) => {
            let messageValue = nullToUndefined(messageGetter(vp));
            if (isLoadingValue(messageValue)) {
                if (skipLoading) {
                    messageValue = undefined;
                } else {
                    return arb.loading("Message");
                }
            }

            let message = asMaybeString(messageValue);
            if (isEmptyOrUndefined(message)) {
                message = getLocalizedString(success ? "formSubmissionSuccess" : "error", appKind);
            }

            return async ab => {
                ab.actionCallbacks.showToast(success, defined(message));
                return arb.addData({ message }).success();
            };
        };
    }
}
