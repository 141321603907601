import { saveUserUpdates } from "@glide/backend-api";
import type { Organization, OrganizationFolder } from "@glide/common-core/dist/js/Database";
import { isUserData } from "@glide/common-core/dist/js/Database";
import { trackEvent } from "@glide/common-core/dist/js/analytics";
import type {
    AddOrgFolderBody,
    DeleteOrgFolderBody,
    MoveAppIntoFolderBody,
    MoveOrgFolderBody,
    MoveOrgInUserBody,
    RenameOrgFolderBody,
    UserFlags,
} from "@glide/common-core/dist/js/firebase-function-types";
import { moveItemInArray, nativeTableIndexer } from "@glide/support";
import deepEqual from "deep-equal";
import produce from "immer";
import {
    removeOrganization,
    setOrganization,
    setOrganizationFolders,
    sortOrganizationsById,
} from "../reducers/organizations";
import { deleteOrg, updateUser, updateUserFlags } from "../reducers/user-data";
import type { GlideAsyncThunk, GlideThunk } from "./action-thunk";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";

export function setUserFlags(
    appFacilities: ActionAppFacilities,
    flags: Partial<UserFlags>,
    save: boolean = true
): GlideAsyncThunk {
    return async (dispatch, getState) => {
        const userData = getState().userData;
        if (!isUserData(userData)) return;

        const newFlags = { ...userData.flags, ...flags };
        if (deepEqual(userData.flags, newFlags)) return;

        dispatch(updateUserFlags(flags));

        if (save) {
            try {
                await saveUserUpdates({ flags }, {}, undefined, appFacilities);
            } catch {
                // Sometimes the theme can change when we do not have a valid user—
                // either we are logged out, or the theme changes very early in the builder
                // lifecycle. If we cannot save the theme to the user, we silently fail.
                return;
            }
        }
    };
}

export function deleteOrgFromUserData(orgID: string): GlideThunk {
    return (dispatch, getState) => {
        const { userData } = getState();
        if (!isUserData(userData)) return;

        dispatch(deleteOrg(orgID));
        dispatch(removeOrganization(orgID));
    };
}

export function setLastAcceptedTermsOfService(version: number, appFacilities: ActionAppFacilities): GlideAsyncThunk {
    return async dispatch => {
        const updates: Partial<UserFlags> = { lastAcceptedTermsOfService: version };
        dispatch(updateUserFlags(updates));
        await saveUserUpdates({ flags: updates as UserFlags }, {}, undefined, appFacilities);
    };
}

export function setBuilderTheme(
    theme: "light" | "dark",
    useSystemTheme: boolean,
    viaUserInteraction: boolean,
    appFacilities: ActionAppFacilities
): GlideAsyncThunk {
    return async dispatch => {
        const updates: Partial<UserFlags> = { builderTheme: theme, useSystemTheme };
        await dispatch(setUserFlags(appFacilities, updates, viaUserInteraction));
    };
}

export function reorderUserOrgs(
    appFacilities: ActionAppFacilities,
    sourceOrg: Organization,
    destinationOrg: Organization | undefined
): GlideAsyncThunk {
    return async (dispatch, getStore) => {
        const store = getStore();
        const user = store.userData;
        if (!isUserData(user)) return;

        const newIDs = moveItemInArray(user.orgUserIDs, sourceOrg.id, destinationOrg?.id);
        if (newIDs === undefined) return;

        dispatch(sortOrganizationsById(newIDs));
        dispatch(
            updateUser({
                ...user,
                orgUserIDs: newIDs,
            })
        );

        const body: MoveOrgInUserBody = {
            orgID: sourceOrg.id,
            afterOrgID: destinationOrg?.id,
        };
        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        // FIXME: Actually do something with this
        await appFacilities.callAuthCloudFunction("moveOrgInUser", body).then(r => r?.text());
    };
}

export function moveOrgFolder(
    appFacilities: ActionAppFacilities,
    org: Organization,
    folderID: string,
    destinationFolderID: string
): GlideAsyncThunk {
    return async dispatch => {
        if (org.folders === undefined) {
            return;
        }
        const destinationIndex = org.folders.findIndex(f => f.id === destinationFolderID);
        const currentIndex = org.folders.findIndex(f => f.id === folderID);
        if (currentIndex === destinationIndex || currentIndex < 0 || destinationIndex < 0) {
            return;
        }
        const folder = org.folders[currentIndex];
        let position = nativeTableIndexer.zero;
        if (destinationIndex === 0) {
            position = nativeTableIndexer.midpoint(nativeTableIndexer.minusOne, org.folders[0].position);
        } else if (destinationIndex === org.folders.length - 1) {
            position = nativeTableIndexer.nextNumber(org.folders[org.folders.length - 1].position);
        } else {
            position = nativeTableIndexer.midpoint(
                org.folders[destinationIndex - 1].position,
                org.folders[destinationIndex].position
            );
        }
        const newFolders = produce(org.folders, draft => {
            draft[currentIndex] = {
                ...folder,
                position,
            };
            draft.sort((a, b) => {
                if (a.position === b.position) return 0;
                return a.position < b.position ? -1 : 1;
            });
        });

        dispatch(setOrganization({ ...org, folders: newFolders }));

        const payload: MoveOrgFolderBody = {
            organizationID: org.id,
            folderID,
            position,
        };
        const response = await appFacilities.callAuthCloudFunction("moveOrgFolder", payload);
        if (response === undefined || !response.ok) {
            alert(`Something went wrong. Please try again later. Error: ${response?.statusText}`);
            return;
        }
        const folders: OrganizationFolder[] = await response.json();
        dispatch(setOrganizationFolders({ orgID: org.id, folders }));
    };
}

export function addOrgFolder(appFacilities: ActionAppFacilities, org: Organization, name: string): GlideAsyncThunk {
    return async dispatch => {
        const payload: AddOrgFolderBody = {
            organizationID: org.id,
            folderName: name,
        };
        const response = await appFacilities.callAuthCloudFunction("addOrgFolder", payload);
        if (response === undefined || !response.ok) {
            alert(`Something went wrong. Please try again later. Error: ${response?.statusText}`);
            return;
        }
        const folders: OrganizationFolder[] = await response.json();
        dispatch(setOrganizationFolders({ orgID: org.id, folders }));
        trackEvent("dashboard folder created", { org_id: org.id });
    };
}

export function renameOrgFolder(
    appFacilities: ActionAppFacilities,
    org: Organization,
    folderID: string,
    name: string
): GlideAsyncThunk {
    return async dispatch => {
        if (org.folders === undefined) {
            return;
        }
        const index = org.folders.findIndex(f => f.id === folderID);
        if (index < 0) {
            return;
        }
        const newFolders = produce(org.folders, draft => {
            const folder = draft[index];
            draft[index] = {
                ...folder,
                name,
            };
        });

        dispatch(setOrganization({ ...org, folders: newFolders }));

        const payload: RenameOrgFolderBody = {
            organizationID: org.id,
            folderName: name.trim(),
            folderID,
        };
        const response = await appFacilities.callAuthCloudFunction("renameOrgFolder", payload);
        if (response === undefined || !response.ok) {
            alert(`Something went wrong. Please try again later. Error: ${response?.statusText}`);
            return;
        }
        const folders: OrganizationFolder[] = await response.json();
        dispatch(setOrganizationFolders({ orgID: org.id, folders }));
        trackEvent("dashboard folder renamed", { org_id: org.id });
    };
}

export function deleteOrgFolder(
    appFacilities: ActionAppFacilities,
    org: Organization,
    folderID: string
): GlideAsyncThunk {
    return async dispatch => {
        const payload: DeleteOrgFolderBody = {
            organizationID: org.id,
            folderID,
        };
        const response = await appFacilities.callAuthCloudFunction("deleteOrgFolder", payload);
        if (response === undefined || !response.ok) {
            alert(`Something went wrong. Please try again later. Error: ${response?.statusText}`);
            return;
        }
        const folders: OrganizationFolder[] = await response.json();
        dispatch(setOrganizationFolders({ orgID: org.id, folders }));
        trackEvent("dashboard folder deleted", { org_id: org.id });
    };
}

interface MoveAppTrackingOptions {
    dragged: boolean;
    skipTracking?: boolean;
}

/**
 * folderID undefined means move to root
 */
export function moveAppIntoOrgFolder(
    appFacilities: ActionAppFacilities,
    org: Organization,
    appID: string,
    folderID: string | undefined,
    trackingOptions: MoveAppTrackingOptions
): GlideAsyncThunk {
    return async (dispatch, getStore) => {
        const { tiles } = getStore();
        const tileData = tiles[appID];
        if (tileData === undefined || tileData === "loading" || tileData.folderID === folderID) {
            return;
        }
        const payload: MoveAppIntoFolderBody = {
            organizationID: org.id,
            folderID,
            appID,
        };
        const response = await appFacilities.callAuthCloudFunction("moveAppIntoFolder", payload);
        if (response === undefined || !response.ok) {
            alert(`Something went wrong. Please try again later. Error: ${response?.statusText}`);
            return;
        }
        const folders: OrganizationFolder[] = await response.json();
        dispatch(setOrganizationFolders({ orgID: org.id, folders }));
        const { dragged, skipTracking = false } = trackingOptions;
        if (!skipTracking) {
            trackEvent("dashboard folder added app", { org_id: org.id, dragged });
        }
    };
}
