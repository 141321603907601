import { GlideIcon, isMonotoneIcon, isStrokeIcon, isTwotoneIcon } from "@glide/common";
import { Icon } from "@glide/common-core/dist/js/render/icon";
import classNames from "classnames";

import type { ButtonStyleProps } from "./button-lib";
import { getIconSize, isCustomButtonIcon } from "./button-lib";
import { ButtonContainer } from "./button-style";
import { Link } from "react-router-dom";
import * as React from "react";
import { SimpleTooltip } from "../tooltip/simple-tooltip";

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type ButtonWithDefaults = PartialBy<ButtonStyleProps, "size" | "variant" | "buttonType">;

export const Button: React.FC<ButtonWithDefaults> = p => {
    const { size = "md", variant = "default", buttonType = "primary" } = p;
    const btn = <ButtonWithStyledComponentNeeds {...p} size={size} variant={variant} buttonType={buttonType} />;
    if (p.iconType === "iconOnly" && p.label.trim() !== "") {
        return (
            <SimpleTooltip text={p.label} forceOneLine instant>
                {btn}
            </SimpleTooltip>
        );
    }
    return btn;
};

const ButtonWithStyledComponentNeeds: React.FC<ButtonStyleProps> = p => {
    const {
        href,
        icon,
        label,
        onClick,
        buttonRef,
        className,
        isDropdown,
        iconType,
        iconRotation,
        centeredTextAndIcon,
        localHref,
        testId,
    } = p;
    const isCustomIcon = isCustomButtonIcon(icon);
    const iconSize = getIconSize(p.size, iconType, p.split);

    const iconSpin = iconRotation === "spin";
    const iconRotateDeg = iconSpin ? 0 : iconRotation;

    const buttonContents = React.useMemo(
        () => (
            <div className={"button-container"}>
                {!isCustomIcon && isStrokeIcon(icon) && (
                    <GlideIcon
                        className={classNames("button-icon", p.isProcessing && "hide")}
                        kind="stroke"
                        icon={icon}
                        iconSize={iconSize}
                        rotateDeg={iconRotateDeg}
                        spin={iconSpin}
                    />
                )}
                {!isCustomIcon && isMonotoneIcon(icon) && (
                    <GlideIcon
                        className={classNames("button-icon", p.isProcessing && "hide")}
                        kind="monotone"
                        icon={icon}
                        iconSize={iconSize}
                        rotateDeg={iconRotateDeg}
                        spin={iconSpin}
                    />
                )}
                {!isCustomIcon && isTwotoneIcon(icon) && (
                    <GlideIcon
                        className={classNames("button-icon", p.isProcessing && "hide")}
                        kind="twotone"
                        icon={icon}
                        iconSize={iconSize}
                        rotateDeg={iconRotateDeg}
                        spin={iconSpin}
                    />
                )}
                {isCustomIcon && (
                    <Icon image={icon.image} size={iconSize} color={icon.color} clipCorners={true} appID={"unknown"} />
                )}
                <span className={classNames("label", p.isProcessing && "hide")}>{label}</span>
                {p.isProcessing && (
                    // this must not be left in the DOM as it will chew up CPU time
                    <GlideIcon
                        className={classNames("process-icon")}
                        spin={true}
                        icon={"st-half-spinner"}
                        kind="stroke"
                        iconSize={getIconSize(p.size, p.iconType, p.split)}
                        rotateDeg={iconRotateDeg}
                    />
                )}
                {isDropdown && (iconType === undefined || iconType === "default" || iconType === "iconLeading") && (
                    <GlideIcon
                        className="dropdown-icon"
                        kind="stroke"
                        icon="st-chevron-down"
                        iconSize={iconSize}
                        rotateDeg={iconRotateDeg}
                        spin={iconSpin}
                    />
                )}
            </div>
        ),
        [
            icon,
            iconSize,
            iconType,
            isCustomIcon,
            isDropdown,
            label,
            p.iconType,
            p.isProcessing,
            p.size,
            p.split,
            iconRotateDeg,
            iconSpin,
        ]
    );

    if (localHref !== undefined) {
        return (
            // @ts-ignore: TODO eventually, we can remove styled components
            // there is only one place in our app we are using this:
            // packages/designer-components/src/components/plugins-app-settings/plugin-details.tsx
            <ButtonContainer
                ref={buttonRef}
                className={classNames(className, p.split)}
                {...p}
                iconType={p.iconType ?? "default"}
                as={Link}
                to={localHref}
                data-testid={testId ?? "button-local-href"}
                aria-label={label}>
                {buttonContents}
            </ButtonContainer>
        );
    }

    if (href !== undefined) {
        return (
            <ButtonContainer
                ref={buttonRef}
                className={classNames(className, p.split)}
                {...p}
                iconType={p.iconType ?? "default"}
                as={"a"}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                data-testid={testId ?? "button-href"}
                aria-label={label}>
                {buttonContents}
            </ButtonContainer>
        );
    }

    return (
        <ButtonContainer
            centeredTextAndIcon={centeredTextAndIcon ?? false}
            ref={buttonRef}
            className={classNames(className, p.split)}
            {...p}
            iconType={p.iconType ?? "default"}
            onClick={onClick}
            onPointerDown={p.onPointerDown}
            data-testid={testId ?? "button-normal"}
            aria-label={label}>
            {buttonContents}
        </ButtonContainer>
    );
};
