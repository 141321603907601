import * as React from "react";
import type { Subtract } from "utility-types";

export type SelectMode = "play" | "edit";

const EditorSelectModeContext = React.createContext<SelectMode>("play");
const EditorSelectModeConsumer = EditorSelectModeContext.Consumer;
export const EditorSelectModeProvider = EditorSelectModeContext.Provider;

interface EditorSelectModeProps {
    selectMode: SelectMode;
}

export const withSelectMode = <P extends EditorSelectModeProps>(Component: React.ComponentType<P>) =>
    class WithSelectMode extends React.Component<Subtract<P, EditorSelectModeProps>> {
        public render() {
            return (
                <EditorSelectModeConsumer>
                    {selectMode => <Component {...(this.props as P)} selectMode={selectMode} />}
                </EditorSelectModeConsumer>
            );
        }
    };
