import { useCallback, useEffect, useState } from "react";
import type { Props as LabelProps } from "./input-label";
import InputLabel from "./input-label";

interface Props extends Omit<LabelProps, "value" | "onChange" | "type"> {
    min?: number;
    max?: number;
    value: number;
    onChange: (val: number) => void;
}

/*

This component is useful to improve the experience when dealing with number inputs.
Allowing the user to clear the field while not compromising the value of the input.

*/

function getValidValue(value: number | null | undefined, min: number | undefined, max: number | undefined): number {
    let v = value ?? 0;
    if (min !== undefined) {
        v = Math.max(min, v);
    }
    if (max !== undefined) {
        v = Math.min(max, v);
    }
    return v;
}

export const InputNumber: React.FC<Props> = p => {
    const { value, onChange, onBlur, min, max, ...rest } = p;
    const [displayValue, setDisplayValue] = useState<string>(getValidValue(value, min, max).toString());

    useEffect(() => {
        setDisplayValue(getValidValue(value, min, max).toString());
    }, [value, min, max]);

    const didChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            let num = e.target.valueAsNumber;
            if (isNaN(num)) {
                // this happens when the imput is empty, which is basically the whole point of this component.
                setDisplayValue(e.target.value);
                return;
            }
            num = getValidValue(num, min, max);
            setDisplayValue(num.toString());
            onChange(num);
        },
        [onChange, min, max]
    );

    const didBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (displayValue.trim() === "") {
                setDisplayValue(value.toString());
            }
            onBlur?.(e);
        },
        [onBlur, displayValue, value]
    );

    return (
        <InputLabel
            {...rest}
            value={displayValue}
            onChange={didChange}
            onBlur={didBlur}
            type="number"
            min={min}
            max={max}
        />
    );
};
