import { assertNever } from "@glideapps/ts-necessities";
import { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

type TransitionSpeed = "slow" | "base" | "fast";

interface Props extends React.PropsWithChildren {
    isOpen: boolean;
    className?: string;
    transitionSpeed?: TransitionSpeed;
    children: React.ReactNode;
    setHeightAuto?: boolean;
}

interface StyleProps {
    elementHeight: number | undefined;
    transitionSpeed: TransitionSpeed;
    autoHeight?: boolean;
}

const ContainerStyle = styled.div<StyleProps>`
    height: ${p =>
        p.elementHeight === undefined ? "auto" : p.autoHeight ? p.elementHeight + 16 + "px" : p.elementHeight + "px"};
    overflow: hidden;

    transition: height
        ${p => {
            switch (p.transitionSpeed) {
                case "slow":
                    return p.theme.transitionSlow;

                case "base":
                    return p.theme.transitionBase;

                case "fast":
                    return p.theme.transitionFast;

                default:
                    assertNever(p.transitionSpeed, "Invalid transition speed");
            }
        }};

    .children-container {
        width: 100%;
    }
`;

export const VerticallyExpandableContainer: React.VFC<Props> = p => {
    const { isOpen, children, className, transitionSpeed = "slow", setHeightAuto } = p;

    const container = useRef<HTMLDivElement | null>(null);
    const [containerHeight, setContainerHeight] = useState<number | undefined>(isOpen ? undefined : 0);

    // We want to run this effect on every re-render.
    // setContainerHeight won't make an infinite loop because
    // we're checking it against the ref's size.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        if (container.current === null || container.current.clientHeight === containerHeight) {
            return;
        }

        if (!setHeightAuto) {
            setContainerHeight(container.current.clientHeight);
        }
    });

    return (
        <ContainerStyle
            className={className}
            elementHeight={isOpen ? containerHeight : 0}
            aria-hidden={!isOpen}
            transitionSpeed={transitionSpeed}
            autoHeight={setHeightAuto}>
            <div ref={container} className="children-container">
                {children}
            </div>
        </ContainerStyle>
    );
};
