import { useChangeObservable, useDeepEqual } from "@glide/common";
import type { MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import type { Owner } from "@glide/common-core/dist/js/Database";
import type { EminenceFlags } from "@glide/billing-types";
import type { EminencesByAppAndOwner } from "@glide/common-core/dist/js/Database/eminence";
import { eminenceFull, freeFlags } from "@glide/common-core/dist/js/Database/eminence";
import type { GetOwnerEminenceBody } from "@glide/common-core/dist/js/firebase-function-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { waitForDocumentVisibility } from "@glide/common-core/dist/js/support/browser-hacks";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { useBuilderSelector } from "@glide/designer-components";
import { getAppOwner } from "@glide/action-reducer-utils";
import type { ChangeObservable } from "@glide/support";
import { AutoWatchable, Watchable, isResponseOK } from "@glide/support";
import { DefaultMap, definedMap } from "@glideapps/ts-necessities";
import type { ComponentType, FunctionComponent } from "react";
import React from "react";
import { shallowEqual } from "react-redux";
import type { Subtract } from "utility-types";
import { getAppEminenceFlags } from "@glide/player-core";

function useAppOwner(appID: string): Owner | undefined {
    const { userData, organizations } = useBuilderSelector(s => {
        return {
            userData: s.userData,
            organizations: s.organizations,
        };
    }, shallowEqual);

    const owner = React.useMemo(() => {
        return getAppOwner(appID, userData, organizations);
    }, [appID, userData, organizations]);

    return owner;
}

interface OwnerEminenceFlags {
    readonly ownerID: string;
    readonly apps: EminencesByAppAndOwner;
}

type OwnerIDOrAppID = { ownerID: string } | { appID: string };

async function getOwnerEminenceFlags(ownerIDOrAppID: OwnerIDOrAppID): Promise<OwnerEminenceFlags> {
    await waitForDocumentVisibility();
    const body: GetOwnerEminenceBody = ownerIDOrAppID;
    const eminenceResponse = await getAppFacilities().callAuthCloudFunction("getOwnerEminence", body);
    if (!isResponseOK(eminenceResponse)) throw new Error("Could not get Eminence for owner");
    const eminences = await eminenceResponse.json();
    return eminences as OwnerEminenceFlags;
}

const ownerEminenceUpdatePeriod = 5 * 60 * 1000; // 5 minutes
const ownerEminenceRapidUpdatePeriod = 60 * 1000; // 60 seconds

type BuilderOwnerEminenceFlags = DefaultMap<string, Watchable<EminencesByAppAndOwner>>;
type BuilderAppEminenceFlags = DefaultMap<string, Watchable<EminenceFlags>>;
const allWatchedFlags: BuilderOwnerEminenceFlags = new DefaultMap(k => {
    return k !== ""
        ? new AutoWatchable(
              {},
              async () => (await getOwnerEminenceFlags({ ownerID: k })).apps,
              ownerEminenceUpdatePeriod
          )
        : new Watchable({});
});

const appWatchedFlags: BuilderAppEminenceFlags = new DefaultMap(k => {
    return new AutoWatchable(eminenceFull, async () => getAppEminenceFlags(k), ownerEminenceUpdatePeriod);
});

// NOTE: If ownerID isn't specified, and the app owner's eminence has yet to be retrieved, this will return free flags.
/** @deprecated After changes in the new billing system the eminence flags are the same for all the apps of an
 * organization so the usage of them in the builder has been simplified in the `useEminenceFlags` hook.
 */
export function getBuilderEminenceFlags(appID: string, ownerID: string | undefined): EminenceFlags {
    if (appWatchedFlags.has(appID)) {
        return appWatchedFlags.get(appID).current;
    }

    if (ownerID !== undefined) {
        return allWatchedFlags.get(ownerID).current[appID] ?? freeFlags;
    }

    // We don't know who the app belongs to, so we just check all owners.
    for (const watchable of allWatchedFlags.values()) {
        const flags = watchable.current[appID];
        if (flags !== undefined) {
            return flags;
        }
    }

    return freeFlags;
}

export async function updateBuilderEminenceFlags(ownerIDOrAppID: OwnerIDOrAppID): Promise<void> {
    const eminences = await getOwnerEminenceFlags(ownerIDOrAppID);
    allWatchedFlags.get(eminences.ownerID).current = eminences.apps;
}

export async function updateBuilderEminenceFlagsForApp(appID: string): Promise<void> {
    const eminences = await getAppEminenceFlags(appID);
    appWatchedFlags.get(appID).current = eminences;
}

export function anticipateBuilderEminenceUpdate(ownerID: string) {
    if (ownerID === "") return;
    const watchableOnOwner: AutoWatchable<EminencesByAppAndOwner> = allWatchedFlags.get(
        ownerID
    ) as AutoWatchable<EminencesByAppAndOwner>;
    // This will set the update period until the user refreshes.
    // We'll accept this cost given that the vast majority of sessions will never upgrade.
    watchableOnOwner.setPeriod(ownerEminenceRapidUpdatePeriod);
}

/** @deprecated After changes in the new billing system the eminence flags are the same for all the apps of an
 * organization so the usage of them in the builder has been simplified in the `useEminenceFlags` hook.
 */
export function useBuilderEminenceFlags(appID: string): EminenceFlags {
    const owner = useAppOwner(appID);
    let observable: ChangeObservable<any> | undefined = undefined;
    let extract = false;
    if (appWatchedFlags.has(appID)) {
        observable = appWatchedFlags.get(appID);
    } else {
        observable = definedMap(owner, o => allWatchedFlags.get(o.id));
        extract = true;
    }
    const observableResult = useChangeObservable(observable);
    const extracted = extract ? observableResult?.[appID] : observableResult;
    const appEminenceFlags = useDeepEqual(extracted);
    return appEminenceFlags ?? freeFlags;
}

/** @deprecated After changes in the new billing system the eminence flags are the same for all the apps of an
 * organization so the usage of them in the builder has been simplified in the `useEminenceFlags` hook.
 */
export function useOwnerEminenceFlags(ownerID: string | undefined): EminenceFlags {
    const observable = definedMap(ownerID, o => allWatchedFlags.get(o));
    const observableResult = useChangeObservable(observable);
    const extracted = ownerID === undefined ? undefined : observableResult?.[ownerID];
    const ownerEminenceFlags = useDeepEqual(extracted);
    return ownerEminenceFlags ?? freeFlags;
}

interface WithBuilderEminenceFlagsProps {
    readonly eminenceFlags: EminenceFlags;
}

interface WrapperProps {
    readonly appID: string;
    readonly appEnvironment: MinimalAppEnvironment;
}

interface Props extends Partial<WrapperProps>, WithBuilderEminenceFlagsProps {}

/** @deprecated After changes in the new billing system the eminence flags are the same for all the apps of an
 * organization so the usage of them in the builder has been simplified in the `useEminenceFlags` hook.
 */
export const withBuilderEminenceFlags =
    <P extends Props>(
        Component: ComponentType<P> | React.FC<P>
    ): FunctionComponent<Subtract<P, WithBuilderEminenceFlagsProps>> =>
    p => {
        const routerAppID = useAppID();
        const appID = p.appID ?? p.appEnvironment?.appID ?? routerAppID ?? "";
        const eminenceFlags = useBuilderEminenceFlags(appID);
        return <Component {...(p as P)} eminenceFlags={eminenceFlags} />;
    };
