import { AppIcon, GlideIcon } from "@glide/common";
import type { Appearance } from "@glide/component-utils";
import { Mood } from "@glide/component-utils";
import { nonNull } from "@glide/support";
import * as React from "react";
import ReactDOM from "react-dom";

import ConfirmModal from "../../../chrome/views/confirm-modal/confirm-modal";
import { APP_MODAL_ROOT } from "../../../lib/constants";
import { ButtonStyle, ButtonViewStyle } from "./button-view-style";
import type { ConfirmProps } from "./button-view-types";

// TODO: Make sure all the returned button is in a format where:
// the parent is a a styled-component while the rest are standard DOM elements with potential classNames
// view avatar-overlay-view as an example
export interface ButtonViewProps {
    readonly className?: string;
    readonly title?: string;
    readonly appearance: Appearance;
    readonly onClick?: (handled: boolean) => void;
    readonly url?: string;
    readonly useTag?: boolean;
    readonly confirm?: ConfirmProps;
    readonly includeMargin?: boolean;
    readonly disabled?: boolean;
    readonly showConfirm?: boolean;
    readonly onShowConfirm?: (show: boolean) => void;
    readonly icon?: string;
    readonly busy?: boolean;
    readonly mood?: Mood;
}

interface Props extends ButtonViewProps {}

const ButtonInner: React.FC<React.PropsWithChildren<Props>> = p => {
    const {
        className,
        title,
        disabled,
        url,
        useTag,
        confirm: cf,
        showConfirm,
        onShowConfirm,
        onClick,
        icon,
        appearance,
        busy,
        includeMargin,
        mood = Mood.Default,
    } = p;

    const onClickHandled = React.useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            onClick?.(true);
        },
        [onClick]
    );

    const onClickUnhandled = React.useCallback(
        (e?: React.MouseEvent) => {
            e?.stopPropagation?.();
            onClick?.(false);
        },
        [onClick]
    );

    const onShowConfirmInner = React.useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            onShowConfirm?.(true);
        },
        [onShowConfirm]
    );

    const textElement = title ? title : null;

    const iconMargin = textElement === null ? 0 : 10;
    const iconElement =
        icon === undefined ? null : <AppIcon style={{ marginRight: iconMargin }} icon={icon ?? ""} size={20} />;

    const content = busy ? <GlideIcon kind="stroke" icon="st-half-spinner" spin={true} /> : textElement;

    if (url !== undefined && cf === undefined && useTag) {
        return (
            <ButtonViewStyle>
                <ButtonStyle
                    className={className}
                    includeMargin={!(includeMargin === false)}
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={url}
                    mood={mood}
                    appearance={appearance}
                    disabled={disabled}
                    onClick={onClickHandled}>
                    {iconElement}
                    {content}
                </ButtonStyle>
            </ButtonViewStyle>
        );
    }

    let portal: React.ReactNode;
    let click = onShowConfirmInner;
    if (showConfirm && cf !== undefined) {
        portal = ReactDOM.createPortal(
            <ConfirmModal
                {...cf}
                onFinish={(accepted: boolean) => {
                    onShowConfirm?.(false);
                    if (accepted) {
                        onClickUnhandled();
                    }
                }}
            />,
            nonNull(document.getElementById(APP_MODAL_ROOT))
        );
    } else if (cf === undefined) {
        click = onClickUnhandled;
    }

    return (
        <ButtonViewStyle>
            <ButtonStyle
                className={className}
                data-test="app-button-view"
                includeMargin={!(includeMargin === false)}
                mood={mood}
                appearance={appearance}
                disabled={disabled}
                onClick={click}>
                {iconElement}
                {content}
            </ButtonStyle>
            {portal}
        </ButtonViewStyle>
    );
};

export const ButtonView = ButtonInner;
