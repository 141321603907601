import { assertNever } from "@glideapps/ts-necessities";
import type { OverlayTheme } from "@glide/common";
import { useWireAppTheme } from "./use-wireapp-theme";
import {
    defaultWireAppAccentContextOverlay,
    defaultWireAppAccentContextOverlayUndo,
    defaultWireAppImageBrightenContextOverlay,
    defaultWireAppImageDarkenContextOverlay,
    mergeTheme,
    type WireAppTheme,
} from "@glide/theme";
import { TailwindThemeProvider } from "@glide/common";
import { HeroImageEffect, UIBackgroundStyle } from "@glide/wire";
import { SectionStyleProvider, useSectionStyle } from "../renderers/wire-container/wire-container";

export const WireSectionThemer: React.FC<
    React.PropsWithChildren<{
        backgroundStyle: UIBackgroundStyle;
        effect?: HeroImageEffect;
        className?: string;
    }>
> = p => {
    const { backgroundStyle, effect, children, className } = p;
    let theme = useWireAppTheme();

    let overlay: (Partial<WireAppTheme> & OverlayTheme) | undefined;

    switch (backgroundStyle) {
        case UIBackgroundStyle.Accent:
        case UIBackgroundStyle.AccentLegacy:
        case UIBackgroundStyle.AccentTranslucent:
        case UIBackgroundStyle.Transparent:
            overlay = defaultWireAppAccentContextOverlay;
            break;
        case UIBackgroundStyle.Card:
        case UIBackgroundStyle.Highlight:
        case UIBackgroundStyle.HighlightTranslucent:
        case UIBackgroundStyle.TransparentLight:
        case UIBackgroundStyle.White:
            overlay = defaultWireAppAccentContextOverlayUndo;
            break;
        case UIBackgroundStyle.Dark:
        case UIBackgroundStyle.DarkTranslucent:
        case UIBackgroundStyle.TransparentDark:
            overlay = defaultWireAppImageDarkenContextOverlay;
            break;
        case UIBackgroundStyle.Image:
            if (effect === HeroImageEffect.Lighten) {
                overlay = defaultWireAppImageBrightenContextOverlay;
            } else {
                overlay = defaultWireAppImageDarkenContextOverlay;
            }
            break;
        case UIBackgroundStyle.None:
        case UIBackgroundStyle.Soft:
            break;
        default:
            assertNever(backgroundStyle);
    }

    if (overlay !== undefined) {
        theme = mergeTheme(theme, [overlay]);
    }
    return (
        <SectionStyleProvider
            value={backgroundStyle === UIBackgroundStyle.AccentLegacy ? UIBackgroundStyle.Accent : backgroundStyle}>
            <TailwindThemeProvider theme={theme} className={className}>
                {children}
            </TailwindThemeProvider>
        </SectionStyleProvider>
    );
};

export const useIsFlat = () => {
    const sectionStyle = useSectionStyle();
    const theme = useWireAppTheme();

    if (
        [UIBackgroundStyle.Highlight, UIBackgroundStyle.HighlightTranslucent, UIBackgroundStyle.White].includes(
            sectionStyle
        )
    ) {
        /* There is a weird edge case when you have Highlight gobally selected and your WireContainer background
            is also a highlight one, colors are inverted in the container, so in this case, we don't want to show the flat style */
        return theme.pageBackground !== "Highlight";
    }

    // Card background should always match Flat.
    return sectionStyle === UIBackgroundStyle.Card || theme.pageBackground === "Highlight";
};
