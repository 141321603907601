import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import {
    getFirebaseAuthEmulatorHost,
    getFirestoreEmulatorHost,
    isRunningOnEmulators,
    logInfo,
    sessionStorageGetItem,
    sessionStorageRemoveItem,
    sessionStorageSetItem,
    setEmulatorHosts,
} from "@glide/support";
import { defined } from "@glideapps/ts-necessities";
import firebase from "firebase/compat/app";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import { frontendSendEvent } from "@glide/common-core/dist/js/tracing";
import { isPlayer } from "@glide/common-core/dist/js/routes";

export function initFirebase(): void {
    logInfo("initing firebase");

    // Sometimes multiple instances are created of this class,
    // but we can only initialize firebase once.
    if (firebase.apps.length > 0) {
        return;
    }

    const locationSettings = getLocationSettings();
    let firebaseConfig: Parameters<typeof firebase.initializeApp>[0] = locationSettings.firebaseConfig;
    const firebaseEmulators = locationSettings.firebaseEmulators;

    // If we're in the player, apply player-specific analytics ids
    if (isPlayer()) {
        firebaseConfig = {
            ...firebaseConfig,
            ...locationSettings.analyticsMixin,
        };
    }

    firebase.initializeApp(firebaseConfig);

    if (firebaseEmulators !== undefined) {
        setEmulatorHosts(firebaseEmulators.firestore, firebaseEmulators.auth);
    }

    // Note there are two ways to set emulator hosts - via location settings which we check above,
    // or via build-time env vars. So we need to check again if emulator hosts are set even if
    // we didn't set them above.
    if (isRunningOnEmulators()) {
        const firestoreEmulatorHost = getFirestoreEmulatorHost();
        const firebaseAuthEmulatorHost = getFirebaseAuthEmulatorHost();

        logInfo("Starting with firestore host", firestoreEmulatorHost, "and auth host", firebaseAuthEmulatorHost);

        // We know these are defined cause `isRunningOnEmulators` checks for them. TS is just not smart enough.
        const [firestoreHost, firestorePort] = defined(firestoreEmulatorHost);
        const authHost = defined(firebaseAuthEmulatorHost);

        firebase.auth().useEmulator(authHost);
        firebase.firestore().useEmulator(firestoreHost, firestorePort);
    }
}

const lastAuthFailureSessionKey = "glide-last-auth-failure";
export function setLastFirebaseAuthFailure(lastFailure: string) {
    sessionStorageSetItem(lastAuthFailureSessionKey, lastFailure);
}

export function reportLastAuthFailureReason(): void {
    const lastFailure = sessionStorageGetItem(lastAuthFailureSessionKey);
    if (lastFailure === undefined) return;

    sessionStorageRemoveItem(lastAuthFailureSessionKey);
    frontendSendEvent("firebase auth failure", 0, { reason: lastFailure });
}
