import * as React from "react";

interface SlideInHeightContext {
    height: number | undefined;
    setHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
    clearHeight: () => void;
}

const SlideInHeightContextImpl = React.createContext<SlideInHeightContext | undefined>(undefined);

export const SlideInHeightContextProvider: React.FC<React.PropsWithChildren> = p => {
    const { children } = p;

    const [height, setHeight] = React.useState<number | undefined>(undefined);

    const clearHeight = React.useCallback(() => {
        setHeight(undefined);
    }, []);

    const value: SlideInHeightContext = React.useMemo(() => {
        return {
            height,
            setHeight,
            clearHeight,
        };
    }, [clearHeight, height]);

    return <SlideInHeightContextImpl.Provider value={value}>{children}</SlideInHeightContextImpl.Provider>;
};

export function useSlideInHeight(): number | undefined {
    const ctx = React.useContext(SlideInHeightContextImpl);

    return ctx?.height;
}

export function useSetSlideInHeight(height: number): boolean {
    const ctx = React.useContext(SlideInHeightContextImpl);
    const [isReady, setIsReady] = React.useState(false);

    React.useLayoutEffect(() => {
        ctx?.setHeight(height);
        setIsReady(true);

        return () => {
            ctx?.clearHeight();
            setIsReady(false);
        };
    }, [ctx, height]);

    return isReady;
}
