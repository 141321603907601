import { MicrosoftGraphError, microsoftGraphFetch, microsoftGraphURL } from "@glide/microsoft-graph";
/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { compareStringsASCII, isUndefinedish, maybeParseJSON } from "@glide/support";
import { hasOwnProperty } from "@glideapps/ts-necessities";

const teamName = glide.makeParameter({
    type: "string",
    name: "Team name",
    description: "The name of your Microsoft Team",
    placeholder: "e.g. My team",
    required: true,
});

export const plugin = glide.newPlugin({
    id: "microsoft-teams",
    name: "Microsoft Teams",
    description: "Send messages to your Microsoft Team",

    tier: "starter",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/ms-teams.png",
    auth: {
        provider: "msal-plugins",
        scopes: ["Channel.ReadBasic.All", "ChannelMessage.Send", "Team.ReadBasic.All"],
    },
    parameters: {
        teamName,
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/microsoft-teams",
});

const channel = glide.makeParameter({
    type: "string",
    name: "Channel",
    placeholder: "e.g. general",
    required: true,
});

const message = glide.makeParameter({
    type: "string",
    name: "Message",
    required: true,
    useTemplate: "withLabel",
});

plugin.addAction({
    id: "send-message-to-teams-channel",
    name: "Send message to channel",
    description: "Send a message to a Teams channel",
    billablesConsumed: 1,
    parameters: { channel, message },
    execute: async (ctx, { teamName: maybeTeamName, channel: maybeChannel, message }) => {
        if (typeof maybeTeamName !== "string") {
            return glide.Result.FailPermanent("Please specify a Team name.", {
                isPluginError: false,
            });
        }
        const teamName = maybeTeamName.trim();
        if (teamName.length === 0) {
            return glide.Result.FailPermanent("Please specify a Team name.", {
                isPluginError: false,
            });
        }

        if (typeof maybeChannel !== "string") {
            return glide.Result.FailPermanent("Please specify a Channel name.", {
                isPluginError: false,
            });
        }
        const channel = maybeChannel.trim();
        if (channel.length === 0) {
            return glide.Result.FailPermanent("Please specify a Channel name.", {
                isPluginError: false,
            });
        }

        const fetch = async (requestInfo: RequestInfo, requestInit?: RequestInit) =>
            microsoftGraphFetch(ctx.fetch, ctx.refreshAccessToken, requestInfo, requestInit);
        const teamIDResult = await ctx.useCache(async () => {
            const targetURL = microsoftGraphURL("me/joinedTeams", [
                ["$filter", `displayName eq '${teamName.replace(/'/g, "''")}'`],
            ]);
            try {
                const { value } = await fetch(targetURL, { method: "GET" });
                if (!Array.isArray(value)) {
                    return glide.Result.Fail("Microsoft Graph API returned unexpected result listing teams", {
                        data: value,
                    });
                }
                value.sort((l, r) => {
                    if (isUndefinedish(l.displayName)) {
                        if (isUndefinedish(r.displayName)) return 0;
                        return 1;
                    }
                    if (isUndefinedish(r.displayName)) return 1;
                    const nameCompare = compareStringsASCII(l.displayName, r.displayName);
                    if (nameCompare !== 0) return nameCompare;
                    if (isUndefinedish(l.id)) {
                        if (isUndefinedish(r.id)) return 0;
                        return 1;
                    }
                    return compareStringsASCII(l.id, r.id);
                });
                const teams = value.filter(e => typeof e.displayName === "string" && e.displayName === teamName);
                if (teams.length === 0) {
                    return glide.Result.FailPermanent(`Could not find team named '${teamName}'`, {
                        isPluginError: false,
                    });
                }
                if (typeof teams[0].id !== "string") {
                    return glide.Result.Fail(`Microsoft Graph API did not return team ID for '${teamName}'`, {
                        data: maybeParseJSON(teams[0]),
                    });
                }
                return glide.Result.Ok(teams[0].id);
            } catch (e: unknown) {
                if (e instanceof MicrosoftGraphError) {
                    if (e.statusCode === 404) {
                        return glide.Result.FailPermanent(`Could not find team named '${teamName}'`, {
                            isPluginError: false,
                        });
                    }
                    ctx.log(`Failed to get teams with target URL '${targetURL}': ${e.body}`);
                    return glide.Result.FailFromHTTPStatus(e.body, e.statusCode, {
                        isPluginError: false,
                    });
                }
                throw e;
            }
        }, [teamName]);

        if (!teamIDResult.ok) return teamIDResult;
        const { result: teamID } = teamIDResult;
        const channelIDResult = await ctx.useCache(async () => {
            const targetURL = microsoftGraphURL(`teams/${teamID}/allChannels`, [
                ["$filter", `displayName eq '${channel.replace(/'/g, "''")}'`],
            ]);
            try {
                const { value } = await fetch(targetURL, { method: "GET" });
                if (!Array.isArray(value)) {
                    return glide.Result.Fail(
                        `Microsoft Graph API returned unexpected result listing channels in team '${teamName}'`
                    );
                }
                value.sort((l, r) => {
                    if (isUndefinedish(l.displayName)) {
                        if (isUndefinedish(r.displayName)) return 0;
                        return 1;
                    }
                    if (isUndefinedish(r.displayName)) return 1;
                    const nameCompare = compareStringsASCII(l.displayName, r.displayName);
                    if (nameCompare !== 0) return nameCompare;
                    if (isUndefinedish(l.id)) {
                        if (isUndefinedish(r.id)) return 0;
                        return 1;
                    }
                    return compareStringsASCII(l.id, r.id);
                });
                const channels = value.filter(e => typeof e.displayName === "string" && e.displayName === channel);
                if (channels.length === 0) {
                    return glide.Result.FailPermanent(
                        `Could not find channel named '${channel}' in team '${teamName}'`,
                        {
                            isPluginError: false,
                        }
                    );
                }
                if (typeof channels[0].id !== "string") {
                    return glide.Result.Fail(
                        `Microsoft Graph API did not return channel ID for '${channel}' in '${teamName}'`,
                        {
                            data: maybeParseJSON(channels[0]),
                        }
                    );
                }
                ctx.consumeBillable();
                return glide.Result.Ok(channels[0].id);
            } catch (e: unknown) {
                if (e instanceof MicrosoftGraphError) {
                    if (e.statusCode === 404) {
                        return glide.Result.FailPermanent(
                            `Could not find channel named '${channel}' in team '${teamName}'`,
                            {
                                isPluginError: false,
                            }
                        );
                    }

                    ctx.log(`Failed to get channels with target URL '${targetURL}': ${e.body}`);
                    return glide.Result.FailFromHTTPStatus(e.body, e.statusCode, {
                        isPluginError: false,
                    });
                }
                throw e;
            }
        }, [teamID, channel]);
        if (!channelIDResult.ok) return channelIDResult;
        const { result: channelID } = channelIDResult;

        const channelURL = microsoftGraphURL(`teams/${teamID}/channels/${channelID}/messages`);
        try {
            const { channelIdentity } = await fetch(channelURL, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ body: { content: message } }),
            });
            if (!hasOwnProperty(channelIdentity, "teamId") || !hasOwnProperty(channelIdentity, "channelId")) {
                return glide.Result.Fail(
                    `Microsoft Graph API returned unexpected result posting a message to channel '${channel}' in team '${teamName}'`,
                    {
                        data: maybeParseJSON(channelIdentity),
                    }
                );
            }
            if (channelIdentity.teamId !== teamID || channelIdentity.channelId !== channelID) {
                return glide.Result.FailPermanent(
                    `Microsoft Graph API sent message to the wrong channel, not channel '${channel}' in team '${teamName}'`
                );
            }
        } catch (e: unknown) {
            if (e instanceof MicrosoftGraphError) {
                if (e.statusCode === 404) {
                    return glide.Result.FailPermanent(
                        `Could not find channel named '${channel}' in team '${teamName}'`,
                        {
                            isPluginError: false,
                        }
                    );
                }
                return glide.Result.FailFromHTTPStatus(e.body, e.statusCode, {
                    data: maybeParseJSON(e.body),
                });
            }
            throw e;
        }

        return glide.Result.Ok();
    },
});
