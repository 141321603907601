import {
    Corners,
    ImageAspectRatio,
    OverlayTextAlignment,
    TextPosition,
    TilesPadding,
} from "@glide/common-core/dist/js/components/image-types";
import type { ComponentDescription } from "@glide/app-description";
import { ArrayScreenFormat, makeEnumProperty, makeSwitchProperty } from "@glide/app-description";
import { TextSize } from "@glide/component-utils";

import type { FixedArrayContent } from "./array-content";
import {
    type FallbackInitialsSummaryArrayContentDescription,
    type FallbackInitialsSummaryArrayScreenDescription,
    FallbackInitialsSummaryArrayScreenHandler,
    isolateFallbackInitialsSummaryArrayContentDescription,
} from "./summary-array-screen";
import type { TilesArrayContentDescription } from "./tiles-array-screen";

type ColumnContentDescription = FallbackInitialsSummaryArrayContentDescription;
type ColumnScreenDescription = FallbackInitialsSummaryArrayScreenDescription;

export class ColumnScreenHandler extends FallbackInitialsSummaryArrayScreenHandler<
    ColumnContentDescription,
    ColumnScreenDescription
> {
    protected readonly supportsNonURLImages = true;
    protected readonly supportsEmojiImages = false;
    protected readonly supportsTruncateList = false;

    constructor() {
        super(ArrayScreenFormat.Columns, "Columns", "columns", true);
    }

    public get isLegacy(): boolean {
        return true;
    }

    public convertArrayScreenToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }

    public convertInlineToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }

    public fixContentDescription(columnContent: ColumnContentDescription): FixedArrayContent | undefined {
        return {
            properties: {
                ...isolateFallbackInitialsSummaryArrayContentDescription(columnContent),
                size: makeEnumProperty(ImageAspectRatio.Square),
                numColumns: makeEnumProperty(2),
                padding: makeEnumProperty(TilesPadding.Loose),
                isHorizontal: makeEnumProperty(false),
                allowWrapping: makeSwitchProperty(false),
                corners: makeEnumProperty(Corners.Rounded),
                textPosition: makeEnumProperty(TextPosition.Below),
                overlayTextAlignment: makeEnumProperty(OverlayTextAlignment.Center),
                textSize: makeEnumProperty(TextSize.Medium),
                allCaps: makeSwitchProperty(false),
            } as TilesArrayContentDescription,
            format: ArrayScreenFormat.Tiles,
        };
    }
}
