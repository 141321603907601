import "twin.macro";
import * as React from "react";
import {
    GlideIcon,
    acceptedImportFileTypes,
    acceptedImportMIMETypes,
    filenameIsAcceptableImportViaSuffix,
} from "@glide/common";

import { getFirstFileFromInput } from "@glide/component-utils";
import { formatFileSize, isDefined } from "@glide/support";
import classNames from "classnames";

const cancelDragEventsForDropEnter = (ev: React.DragEvent<HTMLElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
};

const prepareEventsForDragOver = (ev: React.DragEvent<HTMLElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    ev.dataTransfer.dropEffect = "copy";
};

const isValidDragging = (ev: React.DragEvent<HTMLElement>): boolean => {
    if (ev.dataTransfer.items.length !== 1) return false;
    const item = ev.dataTransfer.items[0];
    if (item.kind !== "file") return false;
    const file = item.getAsFile();
    if (file !== null && !filenameIsAcceptableImportViaSuffix(file.name)) {
        // only on drop
        return false;
    }
    return acceptedImportMIMETypes.indexOf(item.type) > -1;
};

interface Props extends React.PropsWithChildren {
    readonly initialLabel?: string;
    readonly isUploading: boolean;
    readonly isUsersTable: boolean; // displays a warning about format.
    readonly userNameColumnLabel: string;
    readonly userEmailColumnLabel: string;
    readonly maxFileSize: number;
    readonly onFileDropped: (ev: React.DragEvent<HTMLElement>) => Promise<void> | void;
    readonly onFileUpload: (file: File) => Promise<void> | void;
    readonly className?: string;
    // if we don't use this anywhere else, we should probably make it mandatory.
    // let's see how the wiring up goes and revisit.
    readonly selectedFile?: File;
}

// TODO: deduplicate in other places around the builder... possibly
export const ImportFromFileUpload: React.VFC<Props> = props => {
    const {
        initialLabel = "Choose a file",
        isUsersTable,
        userNameColumnLabel,
        userEmailColumnLabel,
        onFileUpload,
        onFileDropped,
        maxFileSize,
        isUploading,
        className,
        selectedFile,
    } = props;

    const [isDragging, setIsDragging] = React.useState(false);
    const [isDraggingWrongFiles, setIsDraggingWrongFiles] = React.useState(false);

    // TODO: Localization in general.
    // let labelText = getLocalizedStringForBuilder("chooseAFile");
    // if (isUploading) {
    //     labelText = getLocalizedStringForBuilder("uploading");
    // } else if (isDragging) {
    //     labelText = isDraggingWrongFiles
    //         ? getLocalizedStringForBuilder("dragOnlyOneSupportedFile")
    //         : getLocalizedStringForBuilder("dropFileHere");
    // }

    // TODO: we can probably map an icon in the future.
    let labelText = selectedFile?.name ?? initialLabel;
    if (isUploading) {
        labelText = "Uploading";
    } else if (isDragging) {
        labelText = isDraggingWrongFiles ? "Drag only one supported file" : "Drop files here";
    }

    return (
        <div>
            <label
                className={classNames(
                    className,
                    isDefined(selectedFile) && "selected",
                    isDragging && "dragging",
                    isDragging && isDraggingWrongFiles && "wrong"
                )}
                tw="pointer-events-auto cursor-pointer flex flex-col items-start justify-start border-border-base
                    border-dashed border-2 rounded-xl text-center p-8 hover:bg-n100A w-full
                    [&.selected]:(border-solid border-accent)
                    [&.dragging]:(border-dashed border-aqua500)
                    [&.wrong]:(border-dashed border-r500)"
                onDragEnterCapture={cancelDragEventsForDropEnter}
                onDragOverCapture={(e: React.DragEvent<HTMLElement>) => {
                    prepareEventsForDragOver(e);
                    setIsDragging(true);
                    setIsDraggingWrongFiles(!isValidDragging(e));
                }}
                onDragLeaveCapture={(e: React.DragEvent<HTMLElement>) => {
                    prepareEventsForDragOver(e);
                    setIsDragging(false);
                }}
                onDropCapture={(e: React.DragEvent<HTMLElement>) => {
                    setIsDragging(false);
                    if (!isDraggingWrongFiles) {
                        void onFileDropped(e);
                    }
                }}
                aria-label="import file browser">
                <input
                    tw="hidden"
                    type="file"
                    accept={acceptedImportFileTypes}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        const firstFile = getFirstFileFromInput(ev.target.files);
                        if (firstFile === undefined) return;
                        void onFileUpload(firstFile);
                        ev.target.value = "";
                    }}
                />
                <div tw="flex pointer-events-none items-start gap-6">
                    <div tw="w-8 h-8 pt-2">
                        <GlideIcon tw="text-n900" kind="stroke" icon={"st-pdf-file"} iconSize={32} />
                    </div>
                    <div tw="flex flex-col items-start">
                        <div tw="text-builder-xl text-text-dark font-semibold pointer-events-none">{labelText}</div>
                        <div tw="text-sm text-text-pale pointer-events-none">
                            Excel or CSV files
                            {maxFileSize > 0 ? ` (max ${formatFileSize(maxFileSize)})` : "."}
                        </div>
                    </div>
                </div>
            </label>
            {isUsersTable && (
                <div tw="flex items-start p-3 mt-3 rounded-2xl border border-border-pale pointer-events-none bg-n100A">
                    <GlideIcon kind="monotone" icon="mt-info-circle" tw="mr-3 mt-1 text-icon-base" iconSize={16} />
                    <div tw="p-0 text-builder-base text-text-base">
                        Your file must have a "{userNameColumnLabel}" and an "{userEmailColumnLabel}" column. Data from
                        other matching columns will also be imported.
                    </div>
                </div>
            )}
        </div>
    );
};
