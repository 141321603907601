import type { WireAppTheme } from "@glide/theme";
import { useCoreTheme, isWireAppTheme } from "@glide/theme";

export function useWireAppTheme(): WireAppTheme {
    const theme = useCoreTheme();

    if (!isWireAppTheme(theme)) {
        // eslint-disable-next-line no-console
        console.error(`Tried to use a WireAppTheme but got a ${theme.themeKind}`);
    }

    return theme as WireAppTheme;
}
