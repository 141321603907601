import { AppIcon, ErrorBoundary, getGlideIcon } from "@glide/common";
import { asMaybeBoolean, asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import { TextComponentStyle } from "@glide/component-utils";
import type { WireFieldsComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isEmptyOrUndefinedish } from "@glide/support";
import React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";

import { Text } from "../text/text";
import type { WireRenderer } from "../../renderers/wire-renderer";
import { Img } from "../img/img";
import { MarkdownView } from "../markdown/markdown-view";

const StyleWrapper = styled.div`
    && {
        // This overrides the MarkdownView styles specificity
        p,
        li,
        small,
        strong {
            ${tw`text-base leading-snug text-text-contextual-dark`}
        }

        a {
            ${tw`break-all text-text-contextual-accent`}
        }
    }
`;

export const Fields: WireRenderer<WireFieldsComponent> = React.memo(props => {
    const { title, fields } = props;
    return (
        <div tw="text-text-contextual-base">
            {!isEmptyOrUndefinedish(title) && (
                <Text element="h2" variant={TextComponentStyle.headlineSmall} tw="mb-3">
                    {title}
                </Text>
            )}
            <ul
                tw="list-none grid grid-cols-1 text-base
                    gp-md:(grid-cols-2 gap-3)
                    gp-lg:grid-cols-2
                    gp-xl:grid-cols-3
                    gp-2xl:grid-cols-4"
            >
                {fields.map((f, i) => {
                    const v = asMaybeString(f.value?.value);
                    const kind = f.value?.typeKind ?? "string";
                    if (isEmptyOrUndefinedish(v)) return null;
                    let content: React.ReactNode;

                    if (kind === "uri") {
                        content = (
                            <a
                                href={v}
                                target="_blank"
                                rel="noreferrer"
                                tw="cursor-pointer text-base text-text-contextual-accent leading-snug break-all
                                    hover:(text-text-contextual-accent underline!)"
                            >
                                {v}
                            </a>
                        );
                    } else if (kind === "image-uri") {
                        content = <Img src={v} tw="[max-width:72px] [max-height:72px] object-contain rounded-md" />;
                    } else if (kind === "boolean") {
                        const value = asMaybeBoolean(f.value?.value);
                        const isTrue = value === true;
                        const icon = isTrue ? "st-check" : "st-close";
                        content = (
                            <AppIcon
                                className={isTrue ? "true-boolean" : ""}
                                css={css`
                                    &.true-boolean {
                                        ${tw`text-text-contextual-accent`}
                                    }
                                `}
                                tw="text-text-contextual-disabled"
                                size={20}
                                icon={getGlideIcon(icon)}
                            />
                        );
                    } else {
                        content = (
                            <ErrorBoundary>
                                <React.Suspense fallback={<div />}>
                                    <StyleWrapper>
                                        <MarkdownView disableStyleInjection={true} markdown={v} />
                                    </StyleWrapper>
                                </React.Suspense>
                            </ErrorBoundary>
                        );
                    }

                    return (
                        <li
                            key={i}
                            tw="flex justify-between py-2.5 border-b border-border-base last:border-0
                                gp-md:(py-1 flex-col border-0 justify-start)"
                        >
                            <div
                                tw="text-base text-text-contextual-pale leading-snug
                                    gp-md:mb-1"
                            >
                                {f.name}
                            </div>
                            <div
                                tw="ml-2 text-right [word-break: break-word]
                                    gp-md:(ml-0 text-left max-w-full)"
                            >
                                {content}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});
