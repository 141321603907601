import { AppIcon, ClickOutsideContainer } from "@glide/common";
import * as React from "react";
import { useLayer } from "react-laag";

import type { ItemDescription, ItemsType } from "../../lib/dropdown-types";
import { PushDropdown } from "../push-dropdown/push-dropdown";
import { MoreMenuStyle } from "./more-menu-style";

interface Props<T> extends React.HTMLAttributes<HTMLButtonElement> {
    readonly size?: number;
    readonly items: readonly ItemsType<T>[];
    readonly descriptionForItem: (item: T) => ItemDescription | string;
    readonly onItemClick: (item: T) => void;
    readonly onOpenChanged?: (isOpen: boolean) => void;
}

export function MoreMenu<T>(p: Props<T>): React.ReactElement | null {
    const { size, items, descriptionForItem, onItemClick, onOpenChanged, className, ...rest } = p;
    const [menuOpen, setMenuOpen] = React.useState(false);

    const onClose = () => {
        setMenuOpen(false);
        onOpenChanged?.(false);
    };

    const onMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const newVal = !menuOpen;
        setMenuOpen(newVal);
        onOpenChanged?.(newVal);
        event.stopPropagation();
    };

    const onSelect = React.useCallback(
        (item: T) => {
            onItemClick(item);
            setMenuOpen(false);
        },
        [onItemClick]
    );

    const { layerProps, triggerProps, renderLayer } = useLayer({
        isOpen: menuOpen,
        auto: true,
        placement: "bottom-start",
        container: "portal",
        triggerOffset: 2,
    });

    const realSize = size ?? 24;
    const iconSize = Math.round(realSize * 0.58);
    return (
        <MoreMenuStyle
            className={`click-outside-ignore ${className ?? ""}`}
            {...rest}
            {...triggerProps}
            onClick={onMenuClick}
            size={realSize}>
            <AppIcon icon="01-03-navigation-menu-vertical" size={iconSize} />
            {menuOpen &&
                renderLayer(
                    <div {...layerProps}>
                        <ClickOutsideContainer onClickOutside={onClose}>
                            <PushDropdown
                                items={items}
                                descriptionForItem={descriptionForItem}
                                onItemSelect={onSelect}
                            />
                        </ClickOutsideContainer>
                    </div>
                )}
        </MoreMenuStyle>
    );
}
