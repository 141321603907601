import type { WireButtonsComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isDefined, isEmptyOrUndefinedish } from "@glide/support";
import { UIButtonAppearance } from "@glide/wire";
import classNames from "classnames";
import * as React from "react";
import tw from "twin.macro";
import { styled } from "@glide/common-components";
import { makeSingleActionSpreadProps } from "../../wire-lib";
import { WireButton } from "../wire-button/wire-button";
import type { WireRenderer } from "../wire-renderer";

const ButtonBarStyle = styled.div`
    ${tw`relative flex flex-col items-center p-0 text-center rounded-xl text-text-dark`}

    &.start {
        ${tw`items-start text-left`};

        .button-box {
            justify-content: flex-start;
        }
    }

    &.end {
        ${tw`items-end text-right`};

        .button-box {
            justify-content: flex-end;
        }
    }

    &.stretch {
        ${tw`items-stretch text-left`};

        .button-box {
            justify-content: stretch;
        }
    }

    &.wide {
        button {
            flex-grow: 1;
            flex-basis: 0;
            ${tw`gp-sm:[max-width:50%]`}
        }
    }
`;

export const WireButtonBar: WireRenderer<WireButtonsComponent, { overrideAppearance?: UIButtonAppearance }> =
    React.memo(p => {
        const toRender = p.buttons;

        return (
            <ButtonBarStyle className={classNames(p.alignment, p.size)}>
                {p.title && <h2 tw="text-2xl font-bold mb-2 text-text-contextual-dark">{p.title}</h2>}
                {p.description && <h3 tw="mb-4 text-base text-text-contextual-pale">{p.description}</h3>}
                <div tw="flex self-stretch justify-center flex-wrap row-gap[12px]" className="button-box">
                    {toRender.map((b, i) => {
                        const { action, title } = b;
                        if (!isDefined(action) || isEmptyOrUndefinedish(title)) return undefined;
                        const appearance =
                            p.overrideAppearance ?? (i === 0 ? UIButtonAppearance.Filled : UIButtonAppearance.Bordered);
                        return (
                            <WireButton
                                key={i}
                                data-testid="wbb-button"
                                {...makeSingleActionSpreadProps(action, p.backend)}
                                tw="not-last:mr-3"
                                appearance={appearance}
                            >
                                {title}
                            </WireButton>
                        );
                    })}
                </div>
            </ButtonBarStyle>
        );
    });
