import type { GetAutomationRunResponseBody } from "@glide/common-core";
import * as React from "react";
import { formatDuration } from "./lib";
import "twin.macro";
import { JSONViewer } from "../json-viewer/json-viewer";
import {
    browserTimeZone,
    DateFormat,
    formatDateTime,
    GlideJSON,
    isGlideJSONDocumentData,
    TimeFormat,
} from "@glide/data-types";
import { isUndefinedish } from "@glide/support";
interface OverviewSectionProps {
    run: GetAutomationRunResponseBody["run"];
    // We use this to diferentiate between Integration trigger kinds
    triggerTitle?: string;
}

function getTriggerTitle(run: GetAutomationRunResponseBody["run"], triggerTitle?: string): string {
    if (run.triggerKind === "Integration") {
        return triggerTitle ?? run.triggerKind;
    }

    return run.triggerKind;
}
export const LogsOverviewSection: React.FC<OverviewSectionProps> = p => {
    const { run, triggerTitle } = p;
    const duration = React.useMemo(() => {
        const time = new Date(run?.finishedAt ?? "").getTime() - new Date(run.startedAt).getTime();
        return formatDuration(time / 1000);
    }, [run?.finishedAt, run.startedAt]);

    const formatedStartedAt = React.useMemo(() => {
        return (
            formatDateTime(
                new Date(run.startedAt),
                DateFormat.Medium,
                TimeFormat.WithoutSeconds,
                browserTimeZone(),
                true
            ) ?? ""
        );
    }, [run.startedAt]);
    return (
        <div tw="flex flex-col gap-4">
            <LogOverviewItem title="Trigger" value={getTriggerTitle(run, triggerTitle)} />
            <LogOverviewItem title="Duration" value={duration} />
            <LogOverviewItem title="Started At" value={formatedStartedAt} />
            {run.triggerData === undefined ? null : (
                <div tw="grid [grid-template-columns: subgrid] gap-4 col-span-2">
                    <span tw="text-text-base">Data</span>
                    <DataLog value={run.triggerData} />
                </div>
            )}
        </div>
    );
};

interface LogOverviewItemProps {
    readonly title: string;
    readonly value: string;
}

const LogOverviewItem: React.FC<LogOverviewItemProps> = p => {
    const { title, value } = p;
    return (
        <div tw="flex justify-between">
            <span tw="text-text-base">{title}</span>
            <span tw="text-text-base">{value}</span>
        </div>
    );
};

const DataLog: React.FC<{ value: unknown }> = p => {
    const { value } = p;
    const parsedValue = React.useMemo(() => {
        if (value instanceof GlideJSON) {
            return value.jsonValue;
        }
        if (isGlideJSONDocumentData(value)) {
            return GlideJSON.fromDocumentData(value).jsonValue;
        }

        if (typeof value === "string") {
            try {
                return JSON.parse(value);
            } catch {
                //
            }
        }

        return value;
    }, [value]);

    const isObject = typeof parsedValue === "object";

    if (isUndefinedish(parsedValue) || (isObject && Object.keys(parsedValue).length === 0)) {
        return <span tw="text-builder-sm text-text-pale">No data available for this trigger</span>;
    }

    return isObject ? <JSONViewer json={parsedValue} /> : <span tw="font-mono">{parsedValue}</span>;
};
