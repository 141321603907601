// NOTE: This is part of the lower level of the New Computation Model, and
// should be kept isolated from non-trivial Glide dependencies.  In
// particular, it shouldn't have to know anything about Glide table/column
// types/schemas.

import {
    type GlideDateTimeDocumentData,
    canParseUserDateTimeSync,
    GlideDateTime,
    GlideJSON,
    parseUserDateTimeZoneAgnostic,
    parseUserDateTimeZoneAgnosticSync,
} from "@glide/data-types";
import type { JSONValue } from "@glide/plugins";
import * as typeConversions from "../type-conversions";
import { assert, assertNever, defined, mapFilterUndefined, panic, hasOwnProperty } from "@glideapps/ts-necessities";
import { isArray, isDefined, isEmptyOrUndefined, logError, parseJSONSafely, formatNumber } from "@glide/support";
import {
    type ArrayValue,
    type ColumnPath,
    type GroundValue,
    type KeyPath,
    type LoadedGroundValue,
    type LoadedRow,
    type LoadingValue,
    type MutableRelativePath,
    type Path,
    type PrimitiveValue,
    type RelativePath,
    type Row,
    copyPath,
    isColumnPath,
    isLoadingValue,
    isPrimitive,
    isThunk,
    makeResumableLoadingValue,
    Table,
    asMaybeString as asMaybeStringInner,
    asString as asStringInner,
    asMaybeDate as asMaybeDateInner,
    asMaybeNumber as asMaybeNumberInner,
} from "@glide/computation-model-types";
import { makeParameterSourceColumnType, type ParameterSourceColumnType } from "./make-parameter-source-column-type";
import { getFeatureSetting } from "../feature-settings";

export function loadedDefinedMap<T, U>(v: T | LoadingValue | undefined, f: (v: T) => U): U | LoadingValue | undefined {
    if (v === undefined) return undefined;
    if (isLoadingValue(v)) return v;
    return f(v);
}

export function loadingToUndefined<T>(v: T | LoadingValue): T | undefined {
    if (isLoadingValue(v)) return undefined;
    return v;
}

/**
 * @deprecated This will not propagate loading values with display values.
 * That's ok if you know that the loading value doesn't have a display value,
 * such as in a component hydrator where the getter will already have
 * unwrapped it.  It's also ok if you don't want to treat loading values with
 * display values specially, such as in action hydrators.
 */
export function nullLoadingToUndefined<T>(v: T | LoadingValue | null): T | undefined {
    if (v === null || isLoadingValue(v)) return undefined;
    return v;
}

export function isLoadedRow(r: Row): r is LoadedRow {
    return !Object.values(r).some(isLoadingValue);
}

export function getSymbolicRepresentationForGroundValue(v: GroundValue): string {
    if (v === undefined) {
        return "undefined";
    } else if (isLoadingValue(v)) {
        return "LOADING";
    } else {
        return JSON.stringify(v);
    }
}

function removeLastPathElement<T extends Path>(path: T): { path: T; last: RelativePath } | undefined {
    assert(path !== undefined);

    if (path.rest === undefined) return undefined;

    const copy = defined(copyPath(path));
    let p: Path | undefined = copy;
    while (defined(defined(p).rest).rest !== undefined) {
        p = defined(p).rest;
    }
    const l = defined(defined(p).rest);
    (p as MutableRelativePath).rest = undefined;

    return { path: copy as T, last: l };
}

export function setThunkColumn(r: Row, p: string, f: () => GroundValue): void {
    r[p] = () => {
        r[p] = f();
    };
}

export function isArrayValue(v: LoadedGroundValue): v is ArrayValue {
    // `isArray` refuses here because of some type system weirdness.
    return Array.isArray(v);
}

export function getArrayItem(a: ArrayValue, index: number): PrimitiveValue | ArrayValue {
    return a[index];
}

export function isTable(v: LoadedGroundValue): v is Table {
    return v instanceof Table;
}

export function asTable(v: LoadedGroundValue): Table {
    if (!isTable(v)) {
        logError("Not a table", JSON.stringify(v));
        return panic("Not a table");
    }
    return v;
}

export function asPrimitive(v: LoadedGroundValue): PrimitiveValue {
    if (!isPrimitive(v)) {
        if (isArrayValue(v)) {
            // NOTE: This is technically an error condition - Glide shouldn't
            // get into the state where array values are being used as
            // primitives, but every now and then it happens for one reason or
            // another, and we don't want to brick Glide over it.  If the
            // computed column builder doesn't fully check all column types
            // then this can happen, for example. In the particular case the
            // prompted this change we ended up with array data in a primitive
            // column from Airtable, which couldn't even have been checked by
            // the computed column builder.
            // https://github.com/quicktype/glide/issues/21220
            return mapFilterUndefined(v, asMaybeString).join(",");
        } else {
            return undefined;
        }
    }
    return v;
}

export function asMaybeString(v: LoadedGroundValue): string | undefined {
    return asMaybeStringInner(asPrimitive(v), formatNumber);
}

export function asMaybeArrayOfStringsCoercedString(v: LoadedGroundValue): string[] | undefined {
    if ((typeof v === "string" && !isEmptyOrUndefined(v)) || typeof v === "number") {
        return [asString(v)];
    }

    return asMaybeArrayOfStrings(v);
}

export function asMaybeArrayOfStrings(v: LoadedGroundValue): string[] | undefined {
    if (!isArray(v)) {
        return undefined;
    }

    return mapFilterUndefined(v, asMaybeString);
}

export function asString(v: LoadedGroundValue): string {
    return asStringInner(asPrimitive(v), formatNumber);
}

export function asMaybeNumber(v: LoadedGroundValue): number | undefined {
    return asMaybeNumberInner(v);
}

export function asMaybeBoolean(v: LoadedGroundValue): boolean | undefined {
    return typeConversions.asMaybeBoolean(v);
}

export function asBoolean(v: LoadedGroundValue): boolean {
    return typeConversions.asBoolean(v);
}

// This will do very basic conversion of values to dates, but it won't do any
// fancy parsing.  If you need that use `parseValueAsGlideDateTime` or
// `parseValueAsGlideDateTimeSync`.
export function asMaybeDate(v: LoadedGroundValue, removeUTCMarker: boolean = true): GlideDateTime | undefined {
    return asMaybeDateInner(v, removeUTCMarker);
}

function shouldRenderAsDateTime(kind: ParameterSourceColumnType[0], repr: GlideDateTimeDocumentData["repr"]): boolean {
    if (!getFeatureSetting("requireDateColumnsToSendDatesInWebhooks")) return true;
    if (repr === undefined) return true;
    return kind === "date" || kind === "time" || kind === "date-time";
}

export function asMaybeJSONValueForColumnType(
    v: LoadedGroundValue,
    columnType: ParameterSourceColumnType | undefined
): JSONValue | undefined {
    if (!(isPrimitive(v) || isArray(v))) return undefined;
    if ((isArray(v) || v instanceof GlideJSON) && getFeatureSetting("acceptJSONInputsFromPrimitiveColumns")) {
        // We handle the array and JSON case here because for consistency's
        // sake we want to allow saving JSON values in primitive columns and
        // have them be treated as JSON, and not as strings, for example.  If
        // we went through the switch on the column type below then the
        // JSON/array would get stringified.  It's worth noting that Glide
        // always represents arrays as JS arrays, even if they come from JSON.
        // So a JSON object with an array inside would be represented by a
        // `GlideJSON`, but if one were to extract the array out of it (via
        // the Query JSON column, for example), the resulting array would be
        // represented as a straight JS array, not wrapped inside a
        // `GlideJSON`.  That makes array handling in Glide uniform and nice.
        // https://github.com/glideapps/glide/pull/29838
        return asJSONValue(v);
    }
    if (isDefined(columnType)) {
        const kind = columnType[0];
        switch (kind) {
            case "string":
            case "uri":
            case "image-uri":
            case "audio-uri":
            case "markdown":
            case "phone-number":
            case "email-address":
            case "time":
            case "emoji":
            case "date-time":
            case "date":
            case "duration":
                if (v instanceof GlideDateTime) {
                    const repr = v.getRepr();
                    if (shouldRenderAsDateTime(kind, repr)) {
                        return v.asUTCDate().toISOString();
                    }
                    return repr;
                }

                const str = asMaybeString(v);
                if (isEmptyOrUndefined(str)) return undefined;

                return str;

            case "number":
                return asMaybeNumber(v);

            case "boolean":
                return asBoolean(v);

            case "array":
                if (getFeatureSetting("acceptJSONInputsFromPrimitiveColumns")) {
                    // We handled that case at the start with `isArray(v)`.
                    return undefined;
                }
                const itemsType = columnType[1];
                if (itemsType === undefined || !isArray(v)) return undefined;

                return mapFilterUndefined(v, value =>
                    asMaybeJSONValueForColumnType(value, makeParameterSourceColumnType(itemsType))
                );

            case "json":
                return asJSONValue(v);

            case "table-ref":
                return undefined;
            default:
                assertNever(kind);
        }
    }

    return asJSONValueFromLiteral(v);
}

function asJSONValueFromLiteral(v: LoadedGroundValue): JSONValue | undefined {
    const jsonValue = asJSONValue(v);
    if (typeof jsonValue === "string") return parseJSONSafely(jsonValue) as JSONValue;
    return jsonValue;
}

export function asJSONValue(v: LoadedGroundValue): JSONValue | undefined {
    if (typeof v === "number" || typeof v === "boolean" || typeof v === "string") return v;
    if (!(isPrimitive(v) || isArrayValue(v)) || !isDefined(v)) return undefined;

    if (v instanceof GlideJSON) return v.jsonValue as JSONValue;
    if (v instanceof GlideDateTime) return v.asUTCDate().toISOString();

    if (isArrayValue(v)) {
        return mapFilterUndefined(v, asJSONValue);
    }

    return undefined;
}

// This is like `parseValueAsGlideDateTime` except it will only use the
// full-blown parser if it's already loaded, otherwise it will fail.  That way
// it can be sync.
export function parseValueAsGlideDateTimeSync(v: LoadedGroundValue): GlideDateTime | undefined {
    if (typeof v === "string" && canParseUserDateTimeSync()) {
        return parseUserDateTimeZoneAgnosticSync(v, true);
    }
    return asMaybeDate(v);
}

// This will use the full-blown permissive date parser to convert values to
// dates.  Unfortunately that parse is lazy-loaded, which is why this is
// async.
export function parseValueAsGlideDateTime(
    v: LoadedGroundValue
): Promise<GlideDateTime | undefined> | GlideDateTime | undefined {
    if (typeof v === "string") {
        return parseUserDateTimeZoneAgnostic(v, true);
    }
    return asMaybeDate(v);
}

export function isRow(v: LoadedGroundValue): v is Row {
    if (isPrimitive(v) || isArray(v)) return false;
    return hasOwnProperty(v, "$rowID");
}

export function asRow(v: LoadedGroundValue): Row {
    if (!isRow(v)) {
        logError("Not a row", JSON.stringify(v));
        return panic("Not a row");
    }
    return v;
}

// export function getTableRow(t: Table, index: number): Row | undefined {
//     return definedMap(getArrayItem(t, index), asRow);
// }

export function getRowColumn(r: Row, c: string): GroundValue {
    if (!hasOwnProperty(r, c)) return undefined;
    let v = r[c];
    if (v === undefined) return undefined;
    if (isLoadingValue(v)) return v;
    if (isThunk(v)) {
        v();
        v = r[c];
        if (isLoadingValue(v)) return v;
        assert(!isThunk(v));
    }
    return v;
}

// ##isNotEmpty:
// This is how the computation model checks whether a value is not empty. Note
// in particular that we always treat `undefined` and the empty string as the
// same.  That's because we use the empty string to clear values.
export function isNotEmpty(v: LoadedGroundValue): boolean {
    if (v === undefined) return false;
    if (v === "") return false;
    if (typeof v === "number" && isNaN(v)) return false;
    if (isArray(v)) return v.length > 0;
    if (isTable(v)) return v.size > 0;
    if (v instanceof GlideJSON) return !v.isNull;
    return true;
}

export function arrayMap<T>(a: ArrayValue, f: (v: LoadedGroundValue) => T): T[] {
    const result: T[] = [];
    for (let i = 0; i < a.length; i++) {
        result.push(f(getArrayItem(a, i)));
    }
    return result;
}

export function arrayForEach(a: ArrayValue, f: (v: LoadedGroundValue, i: number) => unknown): void {
    for (let i = 0; i < a.length; i++) {
        const v = defined(getArrayItem(a, i));
        f(v, i);
    }
}

// Returns the number of rows processed
export function tableForEach(t: Table, includeInvisible: boolean, f: (r: Row, i: number) => unknown): number {
    let i = 0;
    for (const r of t.values()) {
        if (!includeInvisible && !r.$isVisible) continue;

        f(r, i);
        i++;
    }
    return i;
}

export function tableMap<T>(t: Table, includeInvisible: boolean, f: (r: Row) => T): T[] {
    const result: T[] = [];
    tableForEach(t, includeInvisible, r => result.push(f(r)));
    return result;
}

// This will run `f` on each item in `tableOrArray`, and return the first
// defined return value.  Note that even if `f` returns a defined value for
// the first item, `f` will still be run on all the other items, too.
// https://github.com/quicktype/glide/issues/21240
export function forEachItem<T>(
    tableOrArray: LoadedGroundValue,
    f: (i: LoadedGroundValue) => T | undefined
): T | undefined {
    let result: T | undefined;
    if (isTable(tableOrArray)) {
        for (const r of tableOrArray.values()) {
            if (!r.$isVisible) continue;
            const v = f(r);
            if (result === undefined) {
                result = v;
            }
        }
    } else if (isArrayValue(tableOrArray)) {
        for (const i of tableOrArray) {
            const v = f(i);
            if (result === undefined) {
                result = v;
            }
        }
    } else {
        return panic("Can only iterate over arrays and tables");
    }
    return result;
}

// these exported constants are used by the tests
export const defaultComputationYieldInterval = 200;
export const iterationsBetweenTimeChecks = 10;

// If we exceed the yieldInterval, the loop is aborted and a
// ##resumableComputations `LoadingValue` is returned
export function forEachItemResumable(
    tableOrArray: LoadedGroundValue,
    f: (i: LoadedGroundValue) => LoadingValue | undefined,
    yieldInterval: number = defaultComputationYieldInterval
): LoadingValue | undefined {
    let count = 1; // Start at 1 so we don't check on first iter
    const startTime = Date.now();

    // Every Nth iteration, we check the time ellapsed. We don't check every iter
    //  because this is a hot path and Date.now() is somewhat expensive.
    return forEachItem(tableOrArray, i =>
        count++ % iterationsBetweenTimeChecks !== 0 || Date.now() - startTime < yieldInterval
            ? f(i)
            : makeResumableLoadingValue()
    );
}

export function deconstructTableColumnPath<T extends Path>(
    path: T
): { tablePath: T; columnPath: ColumnPath; keyPath: KeyPath } {
    const { path: tablePath, last: columnPath } = defined(removeLastPathElement(path));
    assert(isColumnPath(columnPath) && columnPath.rest === undefined);
    return {
        tablePath,
        columnPath,
        keyPath: {
            key: columnPath.column,
            rest: columnPath.rest,
        },
    };
}
