import { AppKind } from "@glide/location-common";
import { assertNever } from "@glideapps/ts-necessities";
import {
    type ColumnType,
    type PrimitiveArrayColumnType,
    type PrimitiveGlideType,
    type TableColumn,
    type TableGlideType,
    isDateTimeTypeKind,
    isNumberTypeKind,
    isPrimitiveTypeKind,
    isStringTypeKind,
    getTableName,
    isComputedColumn,
    isPrimitiveArrayType,
    isPrimitiveType,
    type SourceMetadata,
    isQueryableExternalTable,
    isBigTableOrExternal,
    getSourceMetadataFlags,
    isTableNameForNativeTable,
} from "@glide/type-schema";
import { isValidExternalDataSourceType, type ExternalDataSourceType } from "./firebase-function-types";

function isNativeTable(table: TableGlideType): boolean {
    // We're being conservative here
    return table.sourceMetadata?.type === "Native table" || isTableNameForNativeTable(getTableName(table));
}

// Does this table allow adding any basic column, sheet or user-specific?
// This is currently identical to `doesTableAllowAddingUserSpecificColumns`
// because there are no cases where we allow adding sheet columns but not
// user-specific columns.
export function doesTableAllowAddingBasicColumns(table: TableGlideType): boolean {
    return !isQueryableExternalTable(table);
}

export function doesTableAllowAddingUserSpecificColumns(table: TableGlideType): boolean {
    return !isQueryableExternalTable(table);
}

export function doesTableAllowAddingSheetColumns(table: TableGlideType): {
    allowPrimitives: boolean;
    allowArrays: boolean;
} {
    const allow = table.sourceMetadata?.externalSource === undefined;
    return {
        allowPrimitives: allow,
        allowArrays: allow && isNativeTable(table),
    };
}

export function doesTableNeedRandomColumnNames(table: TableGlideType): boolean {
    return isNativeTable(table);
}

export function doesAppKindSupportQueryableTables(appKind: AppKind): boolean {
    return appKind === AppKind.Page;
}

export function doesTableSupportRollups(table: TableGlideType): boolean {
    return getSourceMetadataFlags(table.sourceMetadata).queryable?.supportsAggregations ?? true;
}

export function doesTableSupportReverseSheetOrder(table: TableGlideType): boolean {
    return !isQueryableExternalTable(table);
}

export function doesTableSupportRandomSheetOrder(table: TableGlideType): boolean {
    return !isBigTableOrExternal(table);
}

// What should we do with `GlideDateTime`s?
export enum ShouldAgnostifyDateTimes {
    // Keep them as they are
    Keep = "keep",
    // Make them agnostic
    AlwaysAgnostic = "always-agnostic",
    // Make them agnostic if they're in a GMT column
    AgnosticIfGMT = "agnostic-if-gmt",
    // Make them aware
    AlwaysAware = "always-aware",
}

const alwaysAgnosticDataSources: readonly ExternalDataSourceType[] = ["excel-online", "bigquery"];

// Figure out whether/when to convert date/times to time-zone agnostic.
// The cases are:
// * Glide Tables: No conversion necessary.
// * Airtable/SQL: Convert if the column is set to GMT.
// * All other external sources, or if we're not sure: Always convert.
export function doesTableRequireTZAgnosticDateTimes(sm: SourceMetadata | undefined): ShouldAgnostifyDateTimes {
    if (sm === undefined) {
        // If we don't have a table or source metadata, we assume the worst
        // and always convert.
        return ShouldAgnostifyDateTimes.AlwaysAgnostic;
    } else if (sm.type === "Native table") {
        if (
            sm.externalSource !== undefined &&
            isValidExternalDataSourceType(sm.externalSource.type) &&
            alwaysAgnosticDataSources.includes(sm.externalSource.type)
        ) {
            return ShouldAgnostifyDateTimes.AlwaysAgnostic;
        } else if (sm.externalSource !== undefined) {
            // Airtable, SQL
            return ShouldAgnostifyDateTimes.AgnosticIfGMT;
        } else if (sm.needsQuery === true) {
            // Big Tables
            return ShouldAgnostifyDateTimes.AlwaysAware;
        } else {
            // Regular Glide Tables
            return ShouldAgnostifyDateTimes.Keep;
        }
    } else if (sm.type === "Google Sheet") {
        return ShouldAgnostifyDateTimes.AlwaysAgnostic;
    } else {
        return assertNever(sm);
    }
}

export function doesTableNeedFavoritesColumn(isQueryable: boolean): boolean {
    // Queryable tables are Pages-only, which don't use the favorites column.
    return !isQueryable;
}

export function isBasicColumn(column: TableColumn): column is TableColumn & {
    readonly type: PrimitiveGlideType | PrimitiveArrayColumnType;
    readonly formula?: undefined;
} {
    return !isComputedColumn(column) && (isPrimitiveType(column.type) || isPrimitiveArrayType(column.type));
}

export function canChangeDataColumnType(table: TableGlideType, column: TableColumn, newType: ColumnType): boolean {
    const oldType = column.type;

    if (isPrimitiveType(oldType)) {
        const oldTypeKind = oldType.kind;
        const newTypeKind = newType.kind;

        if (!isPrimitiveTypeKind(newTypeKind)) return false;

        if (isQueryableExternalTable(table)) {
            if (isDateTimeTypeKind(oldTypeKind)) {
                if (!isDateTimeTypeKind(newTypeKind)) return false;
            } else if (isStringTypeKind(oldTypeKind)) {
                if (!isStringTypeKind(newTypeKind)) return false;
            } else if (isNumberTypeKind(oldTypeKind)) {
                if (!isNumberTypeKind(newTypeKind)) return false;
            } else if (oldTypeKind === "boolean") {
                if (newTypeKind !== "boolean") return false;
            } else if (oldTypeKind === "json") {
                if (newTypeKind !== "json") return false;
            } else {
                return assertNever(oldTypeKind);
            }
        }

        return true;
    }
    if (isPrimitiveArrayType(oldType)) {
        if (!isPrimitiveArrayType(newType)) return false;
        return true;
    }
    // What type can this be???
    return true;
}

// ##externalSourceDisplayName:
// External tables don't support name changes from Glide yet, except for
// queryables.
export function canRenameTable(table: TableGlideType): boolean {
    // Only native tables can be renamed, i.e. we can't rename Google Sheets
    // tables.
    if (!isNativeTable(table)) return false;
    // Queryable tables are defined in Glide, so they can be renamed.
    if (isQueryableExternalTable(table)) return true;
    // Other external data sources are defined outside of Glide, so we allow
    // changing their names.
    if (table.sourceMetadata?.externalSource !== undefined) return false;
    // Native tables that are not attached to external sources can be renamed.
    return true;
}

// For queryable external tables, i.e. SQL, we must not generate row IDs when
// adding rows.  We must not do that even in the case where the user does not
// specify a value for the row ID, because it might be the database that
// automatically generates one, which will be sent back to Glide.
export function shouldGenerateRowIDForTable(table: TableGlideType): boolean {
    return !isQueryableExternalTable(table);
}
