import type { BasePrimitiveValue } from "@glide/data-types";
import { GlideDateTime, GlideJSON, isBasePrimitiveValue } from "@glide/data-types";
import { isArray } from "@glide/support";
import { assert } from "@glideapps/ts-necessities";
import type { LoadingValue } from "./loading-value";
import type { Query } from "./query-interface";
import type { Thunk } from "./thunk";

export type DefinedPrimitiveValue = BasePrimitiveValue | GlideDateTime | GlideJSON;
// ##primitiveValues:
// `GlideDateTime` is not a primitive in JS, but it is in Glide.
export type PrimitiveValue = DefinedPrimitiveValue | undefined;

export type WritableValue = PrimitiveValue | readonly WritableValue[];

export interface ColumnValues {
    [column: string]: GroundValue | Thunk;
}

export interface LoadedColumnValues {
    [column: string]: LoadedGroundValue;
}

interface AdditionalRowProperties {
    readonly $rowID: string;
    // Rows that are not visible are kept in tables and have computed columns
    // added, are passed through by sorts, but they're ignored by filters,
    // aggregates, and relations.  We use this to keep rows like the default
    // user profile row with computed columns.
    readonly $isVisible: boolean;
    // We insert a fallback user profile row with a dummy row ID, but when
    // writing to that row we have to use the row ID that the backend has,
    // which is this.
    // https://github.com/quicktype/glide/issues/15712
    readonly $backendRowID?: string;
    // When an Edit screen is pushed, Glide makes two copies of the origin
    // row: the row to be edited, and the "reference copy", which never
    // changes. When submitting, the row to be edited is compared against the
    // reference copy, instead of the original row.
    // https://github.com/quicktype/glide/pull/18115
    readonly $referenceCopy?: Row;
}

export interface Row extends ColumnValues, AdditionalRowProperties {}

export interface LoadedRow extends LoadedColumnValues, AdditionalRowProperties {}

export class Table implements ReadonlyMap<string, Row> {
    // Both of these are optional.  If they're both `undefined`, that's an
    // empty table.
    protected map: Map<string, Row> | undefined;
    // Most of the time these two will be the same (in the case where there
    // are no invisible rows).
    private array: readonly Row[] | undefined;
    private visibleRowsArray: readonly Row[] | undefined;

    public readonly errorMessage: string | undefined;

    // Takes ownership of `data`.
    constructor(data?: Map<string, Row> | readonly Row[], originOrError?: Table | string) {
        if (isArray(data)) {
            this.array = data;
        } else {
            this.map = data;
        }

        if (originOrError instanceof Table) {
            this.errorMessage = originOrError.errorMessage;
        } else if (typeof originOrError === "string") {
            this.errorMessage = originOrError;
        }
    }

    protected invalidateArray(): void {
        this.array = undefined;
        this.visibleRowsArray = undefined;
    }

    protected asMap(): Map<string, Row> {
        if (this.map === undefined) {
            this.map = new Map();
            for (const r of this.array ?? []) {
                this.map.set(r.$rowID, r);
            }
        }
        return this.map;
    }

    // The returned array might mutate at any point.
    public asMutatingArray(): readonly Row[] {
        if (this.array === undefined) {
            this.array = Array.from(this.asMap().values());
        }
        return this.array;
    }

    public asArray(): Row[] {
        return Array.from(this.asMutatingArray());
    }

    // The returned array might mutate at any point.
    public asMutatingVisibleRowsArray(): readonly Row[] {
        if (this.visibleRowsArray === undefined) {
            const array = this.asMutatingArray();
            if (array.every(r => r.$isVisible)) {
                this.visibleRowsArray = array;
            } else {
                this.visibleRowsArray = array.filter(r => r.$isVisible);
            }
        }
        return this.visibleRowsArray;
    }

    public get(rowID: string): Row | undefined {
        return this.asMap().get(rowID);
    }

    public has(rowID: string): boolean {
        return this.asMap().has(rowID);
    }

    public get size(): number {
        if (this.array !== undefined) {
            return this.array.length;
        } else {
            return this.asMap().size;
        }
    }

    public entries(): IterableIterator<[string, Row]> {
        if (this.array !== undefined) {
            return this.array.map((r): [string, Row] => [r.$rowID, r]).values();
        } else {
            return this.asMap().entries();
        }
    }

    public keys(): IterableIterator<string> {
        if (this.array !== undefined) {
            return this.array.map(r => r.$rowID).values();
        } else {
            return this.asMap().keys();
        }
    }

    public values(): IterableIterator<Row> {
        if (this.array !== undefined) {
            return this.array.values();
        } else {
            return this.asMap().values();
        }
    }

    public forEach(callbackfn: (value: Row, key: string, map: ReadonlyMap<string, Row>) => void, thisArg?: any): void {
        if (this.array !== undefined) {
            for (const r of this.array) {
                callbackfn.call(thisArg, r, r.$rowID, this);
            }
        } else {
            for (const [k, v] of this.asMap()) {
                callbackfn.call(thisArg, v, k, this);
            }
        }
    }

    public [Symbol.iterator](): IterableIterator<[string, Row]> {
        return this.entries();
    }
}

// This "collapses" down to a map when mutated.
export class MutableTable extends Table {
    public set(rowID: string, row: Row): void {
        this.asMap().set(rowID, row);
        this.invalidateArray();
    }

    public delete(rowID: string): void {
        this.asMap().delete(rowID);
        this.invalidateArray();
    }

    public clear(): void {
        this.map = undefined;
        this.invalidateArray();
    }

    public withErrorMessage(errorMessage: string | undefined): MutableTable {
        if (this.errorMessage === errorMessage) {
            return this;
        }
        return new MutableTable(this.asArray(), errorMessage);
    }

    // The passed lambda must not call any of the Map-based methods of this instance,
    //  including `asMap`, `get`, `has`, `set`, or `delete`.
    public mutateAsArray(fn: (arr: Row[]) => void): void {
        const arr = this.asMutatingArray() as Row[];
        this.map = undefined;
        fn(arr);
        assert(this.map === undefined);
    }
}

export type ArrayValue = readonly (PrimitiveValue | ArrayValue)[];

export type ResolvedGroundValue = PrimitiveValue | Row | Table | ArrayValue;
export type LoadedGroundValue = ResolvedGroundValue | Query;
export type GroundValue = LoadedGroundValue | LoadingValue;

export function isPrimitiveValue(v: unknown): v is PrimitiveValue {
    return v === undefined || isBasePrimitiveValue(v) || v instanceof GlideDateTime || v instanceof GlideJSON;
}

export function isPrimitive(v: LoadedGroundValue): v is PrimitiveValue {
    return isPrimitiveValue(v);
}

export function isThunk(v: LoadedGroundValue | Thunk): v is Thunk {
    return typeof v === "function";
}
