import type { BaseRowIndex } from "@glide/computation-model-types";

export function sortByRowIndex<T>(arr: Iterable<T>, getIndex: (x: T) => BaseRowIndex | undefined): T[] {
    return Array.from(arr).sort((a, b) => {
        const indexA = getIndex(a);
        const indexB = getIndex(b);
        if (indexA !== undefined) {
            if (indexB !== undefined) {
                if (indexA < indexB) return -1;
                if (indexA === indexB) return 0;
                return 1;
            } else {
                return -1;
            }
        } else {
            if (indexB !== undefined) {
                return 1;
            } else {
                return 0;
            }
        }
    });
}
