import type { PluginConfig } from "@glide/app-description";
import type { AppFacilities } from "@glide/common-core/dist/js/components/types";
import { getDeviceID } from "@glide/common-core/dist/js/device-id";
import type { ResultPluginValues } from "@glide/common-core/dist/js/plugin-value";
import type { RunIntegrationsBody } from "@glide/common-core/dist/js/firebase-function-types";

export function mockRunIntegrations(
    appFacilities: AppFacilities,
    actionKind: string,
    isAction: boolean,
    pluginConfig: PluginConfig | undefined,
    expectedActionParams: RunIntegrationsBody["instances"][number]["actionParams"],
    controller: AbortController | undefined
) {
    const spy = jest.spyOn(appFacilities, "callAuthIfAvailableCloudFunction");
    spy.mockImplementation(async (endpoint, body) => {
        expect(endpoint).toBe("runIntegrations");
        const expectedBody: RunIntegrationsBody = {
            appID: "APP-ID",
            actionKind,
            deviceID: getDeviceID(),
            pluginConfigID: pluginConfig?.configID,
            pluginParams: undefined,
            instances: [
                {
                    actionParams: {
                        ...expectedActionParams,
                    },
                    writeBackTo: {
                        fromBuilder: false,
                        fromDataEditor: false,
                        results: {},
                        appUserID: undefined,
                        writeSource: "player",
                    },
                    isAction,
                    instanceID: expect.anything(),
                },
            ],
        };
        expect(body).toEqual(expectedBody);

        const results: ResultPluginValues = {
            result: {
                type: "number",
                value: 246,
            },
        };
        const responseJSON = {
            instanceID: body.instances[0].instanceID,
            statusCode: 200,
            data: {
                results,
                actions: [],
            },
        };

        if (controller !== undefined) {
            controller.abort();
        }

        let didRead = false;
        return {
            ok: true,
            json: async () => responseJSON,
            text: async () => JSON.stringify(responseJSON),
            body: {
                getReader: () => ({
                    read: async () => {
                        if (didRead) {
                            return { value: undefined, done: true };
                        }
                        didRead = true;
                        return { value: new Uint8Array(Buffer.from(JSON.stringify(responseJSON))), done: false };
                    },
                }),
            },
        } as unknown as Response;
    });
    return spy;
}
