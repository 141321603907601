import md5 from "blueimp-md5";
import debounce from "lodash/debounce";
import { definedMap } from "@glideapps/ts-necessities";

import { load, save } from "../storage";
import type { LatLng } from "../Database";
import { loadLocationForAddress, unzipString, zipString } from "../Database";
import type { ActionAppEnvironment } from "./types";
import { logError, checkNumber, isResponseOK } from "@glide/support";
import type { GeocodeAddressesBody } from "../firebase-function-types";
import { callCloudFunction } from "../call-cloud-function";

const latLongPromises: Map<string, Promise<LatLng | undefined>> = new Map();
const latLongResultCache: Map<string, LatLng> = new Map();

const latLongRegexp = /^\s*(-?[0-9]+(\.[0-9]+)?)\s*,\s*(-?[0-9]+(\.[0-9]+)?)\s*$/;
// We have double parentheses in the Google Maps regexp to make the paren
// groups be at the same indexes as in the lat/long regexp.
const googleMapsLocationUrlRegexp =
    /https:\/\/www\.google.*\/maps\/.*@(-?[0-9]+\.[0-9]+)\s*,\s*((-?[0-9]+\.[0-9]+))\s*/;

type getGeocodeFunctionType = (address: string) => Promise<LatLng | undefined>;
let geocodeFunctionOverride: getGeocodeFunctionType | undefined;
export function setGeocodeAddressFunctionOverride(f: getGeocodeFunctionType | undefined): void {
    geocodeFunctionOverride = f;
}

export async function geocodeAddress(
    appEnvironment: ActionAppEnvironment,
    address: string,
    key: string | undefined
): Promise<LatLng | undefined> {
    address = address.trim().replace(/\r?\n/g, " ").replace(/\s+/g, " ");

    const maybeResult = latLongResultCache.get(address);
    if (maybeResult !== undefined) {
        return maybeResult;
    }

    if (geocodeFunctionOverride !== undefined) {
        let geoc = await geocodeFunctionOverride(address);
        if (geoc === undefined) {
            // eslint-disable-next-line no-console
            console.warn(`You're mocking geocodes but forgot about ${address}. Setting a random one.`);

            geoc = {
                lat: Math.random() * 178 - 89,
                lng: Math.random() * 358 - 179,
            };
        }
        latLongResultCache.set(address, geoc);
        return geoc;
    }

    const maybePromise = latLongPromises.get(address);
    if (maybePromise !== undefined) {
        return maybePromise;
    }

    const fromExtraction = tryGeocodeAddressFromExtraction(address);
    if (fromExtraction !== undefined) {
        return fromExtraction;
    }

    const result = geocodeAddressAsync(appEnvironment, address, key);
    latLongPromises.set(address, result);
    return result;
}

function makeAndCacheResult(address: string, result: LatLng, localStorageKey?: string): LatLng | undefined {
    if (result.lat < -90 || result.lat > 90 || result.lng < -180 || result.lng > 180) {
        logError("invalid geo coordinates", result);
        return undefined;
    }
    latLongResultCache.set(address, result);
    if (localStorageKey !== undefined /* && "a".includes("x") */) {
        save(localStorageKey, zipString(JSON.stringify(result)));
    }
    return result;
}

function tryGeocodeAddressFromExtraction(address: string): LatLng | undefined {
    const match = address.match(latLongRegexp) ?? address.match(googleMapsLocationUrlRegexp);
    if (match === null) return undefined;

    const lat = Number.parseFloat(match[1]);
    const lng = Number.parseFloat(match[3]);

    if (isNaN(lat) || isNaN(lng)) return undefined;
    return makeAndCacheResult(address, { lat, lng });
}

function localStorageKeyForAddress(address: string): string {
    return `gl-${md5(address)}`;
}

// This reports quota if it needs to get the geocode from Firestore.  It does
// not report when it gets it from Local Storage.
async function tryGeocodeAddressFromManagedSource(
    appEnvironment: ActionAppEnvironment,
    address: string
): Promise<LatLng | undefined> {
    const localStorageKey = localStorageKeyForAddress(address);
    const fromLocalStorage = load<string>(localStorageKey);
    if (fromLocalStorage !== undefined) {
        return makeAndCacheResult(address, JSON.parse(unzipString(fromLocalStorage)));
    }

    const { database } = appEnvironment;

    return definedMap(await loadLocationForAddress(database, address), l => {
        // FIXME: Report a hash of the address so we can count unique addresses.
        // We should do that even when we load the location from Firestore, because
        // otherwise a user could game us by pre-geocoding lots of addresses to then
        // use in another app.
        appEnvironment.reportGeocodes(1);
        return makeAndCacheResult(address, l, localStorageKey);
    });
}

// This reports quotas for all the addresses sent to the backend.
async function geocodeLookupViaFunction(
    appEnvironment: ActionAppEnvironment,
    addresses: readonly string[]
): Promise<Map<string, LatLng>> {
    const ret = new Map<string, LatLng>();

    if (appEnvironment.locationSettings === undefined) return ret;

    const body: GeocodeAddressesBody = { addresses };
    const result = await callCloudFunction(appEnvironment.locationSettings, "geocodeAddresses", body, {});

    if (!isResponseOK(result)) {
        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        logError("geocoding failed", addresses.join(", "), result?.ok, await result?.text());
        return ret;
    }

    appEnvironment.reportGeocodes(addresses.length);

    const json = await result.json();
    for (let offset = 0; offset < json.locations.length; offset++) {
        const location = json.locations[offset];
        if (location === null) continue;
        const address = addresses[offset];

        const lat = checkNumber(location.lat);
        const lng = checkNumber(location.lng);
        const latLng = { lat, lng };

        const cacheResult = makeAndCacheResult(address, latLng, localStorageKeyForAddress(address));
        if (cacheResult !== undefined) {
            ret.set(address, latLng);
        }
    }

    return ret;
}

type GeocodeLookupRequest = Readonly<{
    address: string;
    resolve: (ret: LatLng | undefined) => void;
    reject: (error: any) => void;
}>;

const outstandingGeocodeLookupRequests: GeocodeLookupRequest[] = [];
async function processOutstandingGeocodeLookupRequests(appEnvironment: ActionAppEnvironment): Promise<void> {
    const workingOn = outstandingGeocodeLookupRequests.splice(
        0,
        Math.min(outstandingGeocodeLookupRequests.length, 500)
    );
    if (workingOn.length === 0) return;

    if (outstandingGeocodeLookupRequests.length > 0) {
        const remainingGeocodeRequests = outstandingGeocodeLookupRequests.slice();
        processOutstandingGeocodeLookupRequests(appEnvironment).catch(e => {
            // This is a very pathological failure case.  Throw for all remaining
            // requests.
            for (const request of remainingGeocodeRequests) {
                const requestIndex = outstandingGeocodeLookupRequests.findIndex(r => Object.is(r, request));
                if (requestIndex >= 0) {
                    outstandingGeocodeLookupRequests.splice(requestIndex, 1);
                }
                request.reject(e);
            }
        });
    }

    try {
        //
        const returnByAddress = new Map<string, LatLng>();

        // We slice these by app environment because the backend function call uses
        // the app facilities, which might theoretically differ between app
        // environments.  This is maybe a bit too much work.

        const newResults = await geocodeLookupViaFunction(
            appEnvironment,
            workingOn.map(w => w.address)
        );
        for (const [address, result] of newResults.entries()) {
            returnByAddress.set(address, result);
        }

        for (const request of workingOn) {
            const { address, resolve } = request;
            resolve(returnByAddress.get(address));
        }
    } catch (e: unknown) {
        for (const request of workingOn) {
            request.reject(e);
        }
    }
}

const debouncedGeocodeLookupAddresses = debounce(processOutstandingGeocodeLookupRequests, 1 * 1000);

async function geocodeAddressAsync(
    appEnvironment: ActionAppEnvironment,
    address: string,
    quotaKey: string | undefined
): Promise<LatLng | undefined> {
    if (quotaKey !== undefined) {
        const isOverQuota = !appEnvironment.isGeocodeWithinQuota(quotaKey);
        appEnvironment.countGeocodeForQuota(quotaKey);
        if (isOverQuota) return undefined;
    }

    const fromManagedSource = await tryGeocodeAddressFromManagedSource(appEnvironment, address);
    if (fromManagedSource !== undefined) {
        return fromManagedSource;
    }

    if (quotaKey === undefined) {
        // The quotaKey is usually only undefined due to static map lookups.
        // We don't need to debounce those, they're just one location.
        const ret = await geocodeLookupViaFunction(appEnvironment, [address]);
        return ret.get(address);
    } else {
        return new Promise((resolve, reject) => {
            outstandingGeocodeLookupRequests.push({
                address,
                resolve,
                reject,
            });
            // The types are wrong here: this actually returns undefined, not a Promise.
            void debouncedGeocodeLookupAddresses(appEnvironment);
        });
    }
}
