import * as t from "io-ts";
import { tableNameCodec } from "./table-name";

export const userProfileTableInfoCodec = t.type({
    tableName: tableNameCodec,
    emailColumnName: t.string,
    // FIXME: Shouldn't these two be optional, at least the image column?
    nameColumnName: t.string,
    imageColumnName: t.string,
    rolesColumnName: t.union([t.string, t.undefined]),
});

export type UserProfileTableInfo = t.TypeOf<typeof userProfileTableInfoCodec>;
