interface QuotaState {
    readonly rowQuota: number;
    readonly geocodeQuota: number;
    readonly zapsQuota: number;
    readonly reloadQuota: number;
    readonly deliverEmailQuota: number;
    readonly fileSizeQuota: number;
}

type ProUpgradeCallback = (appID: string) => void;

const pendingProUpgrades: Record<string, QuotaState> = {};
const pendingProCallbacks: Record<string, readonly ProUpgradeCallback[]> = {};

export function confirmProUpgrade(appID: string) {
    const quotaInfo = pendingProUpgrades[appID];
    if (quotaInfo === undefined) return;

    const pendingCallbacks = pendingProCallbacks[appID] ?? [];
    for (const pendingCallback of pendingCallbacks) {
        pendingCallback(appID);
    }

    // DELETE
    // This appears unused

    delete pendingProUpgrades[appID];
    delete pendingProCallbacks[appID];
}
