import {
    GlideDateTime,
    convertDateToDaySerial,
    convertDateToTimeZoneAgnostic,
    makeDateFromString,
    GlideJSON,
    convertDaySerialToDate,
    formatDateTime,
    DateFormat,
    TimeFormat,
} from "@glide/data-types";
import { parseNumber, formatNumber as defaultFormatNumber } from "@glide/support";

// ##asMaybeNumber:
// This is how the computation model interprets values as numbers.
export function asMaybeNumber(v: unknown): number | undefined {
    if (v === undefined) return undefined;
    if (typeof v === "number") return v;
    if (typeof v === "boolean") return v ? 1 : 0;
    if (typeof v === "string") {
        const n = parseNumber(v);
        if (n !== undefined) return n;
    }

    if (v instanceof GlideDateTime) {
        v = v.asLocalTimeZoneAgnosticDate();
    }
    if (v instanceof Date) {
        return convertDateToDaySerial(v);
    }

    return undefined;
}

export function asNumber(v: unknown): number {
    return asMaybeNumber(v) ?? 0;
}

const datesForPrimitives = new Map<unknown, GlideDateTime | false>();
const datesForPrimitivesWithoutUTCMarker = new Map<unknown, GlideDateTime | false>();

export function asMaybeDate(v: unknown, removeUTCMarker: boolean = true): GlideDateTime | undefined {
    if (v instanceof GlideDateTime) return v;

    if (v instanceof GlideJSON) return undefined;

    // we have to key on the flagging now because a cache hit
    // can return the result we don't want if the flag is different
    // e.g. computation model path vs string parsing formatValueWithSpecification
    const cacheMap = removeUTCMarker ? datesForPrimitivesWithoutUTCMarker : datesForPrimitives;

    let d = cacheMap.get(v);
    if (d === false) return undefined;
    if (d !== undefined) return d;

    if (typeof v === "number") {
        const date = convertDaySerialToDate(v);
        if (date === undefined) return undefined;
        d = GlideDateTime.fromTimeZoneAgnosticDate(date);
    } else if (typeof v === "string") {
        const date = makeDateFromString(v, removeUTCMarker);
        if (date !== undefined) {
            d = GlideDateTime.fromTimeZoneAwareDate(date, v);
        }
    }

    cacheMap.set(v, d ?? false);

    return d;
}

/**
 * @deprecated You probably want to use `asMaybeDate`, which returns a
 * `GlideDateTime` and handles numbers as Google Sheets compatible "day
 * serials".
 */
export function asDate(v: unknown): Date | undefined {
    if (v instanceof Date) return v;
    if (v instanceof GlideDateTime) return v.asLocalTimeZoneAgnosticDate();
    if (typeof v === "number") return new Date(v);
    if (typeof v === "string") {
        const d = makeDateFromString(v, true);
        if (d !== undefined) {
            return convertDateToTimeZoneAgnostic(d);
        }
    }
    return undefined;
}

export function asMaybeString(
    x: unknown,
    formatNumber: (x: number) => string = defaultFormatNumber
): string | undefined {
    if (typeof x === "string") return x;
    if (typeof x === "number") return formatNumber(x);
    if (typeof x === "boolean") return x.toString();
    if (x instanceof Date) return formatDateTime(x, DateFormat.Short, TimeFormat.WithSeconds);
    if (x instanceof GlideDateTime) return x.asLocalString();
    // `GlideJSON` doesn't represent strings (those are just strings), so we
    // have to stringify in some way or form.  This is what it is for now.
    if (x instanceof GlideJSON) return x.jsonString;
    return undefined;
}

export function asString(x: unknown, formatNumber: (x: number) => string = defaultFormatNumber): string {
    return asMaybeString(x, formatNumber) ?? "";
}
