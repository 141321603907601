import Markdown from "marked-react";

type Props = {
    markdown: string;
    className?: string;
};

export const DesignerMarkdownView: React.VFC<Props> = p => {
    const { markdown, className } = p;
    return (
        <div className={className}>
            <Markdown>{markdown}</Markdown>
        </div>
    );
};
