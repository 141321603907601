import { ArrayScreenFormat } from "@glide/app-description";
import type {
    WireDataGridListComponent,
    WireForEachContainer,
    WireKanbanComponent,
    WireListCalendarCollection,
} from "@glide/fluent-components/dist/js/base-components";
import type {
    WireListCardCollection,
    WireListMapCollection,
    WireTitleComponent,
} from "@glide/fluent-components/dist/js/fluent-components";
import type { WireContainerComponent } from "@glide/fluent-components/dist/js/fluent-components-containers";
import type { WireListComponentGeneric } from "@glide/fluent-components/dist/js/fluent-components-spec";
import { type WireComponent, WireComponentKind } from "@glide/wire";

import { nullifyUnnecesaryMobileBreadcrumb } from "../chrome/content/lib/use-layer-compoents";

export const getComponentTitle = (untypedComponent: WireComponent | null | undefined, isMobile: boolean): string => {
    if (untypedComponent === undefined || untypedComponent === null) return "";

    if (untypedComponent.kind === WireComponentKind.List) {
        const component = untypedComponent as WireListComponentGeneric<ArrayScreenFormat>;
        if (
            [
                ArrayScreenFormat.DataGrid,
                ArrayScreenFormat.Kanban,
                ArrayScreenFormat.ForEachContainer,
                ArrayScreenFormat.Comments,
            ].includes(component.format)
        ) {
            return (component as WireKanbanComponent | WireDataGridListComponent | WireForEachContainer).title ?? "";
        }

        if (
            [
                ArrayScreenFormat.CardCollection,
                ArrayScreenFormat.PagesSimpleMap,
                ArrayScreenFormat.CalendarCollection,
            ].includes(component.format)
        ) {
            return (
                (component as WireListCardCollection | WireListCalendarCollection | WireListMapCollection)
                    .componentTitle ?? ""
            );
        }
    }

    if (untypedComponent.kind === WireComponentKind.Hero) {
        return (untypedComponent as WireTitleComponent).title ?? "";
    }

    if (untypedComponent.kind === WireComponentKind.Container) {
        return getComponentTitle(
            nullifyUnnecesaryMobileBreadcrumb((untypedComponent as WireContainerComponent).components, isMobile)[0],
            isMobile
        );
    }

    return "";
};
