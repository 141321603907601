import {
    setSnapshotLocationAndInitialSchemaFromResponse,
    type RootAuthenticator,
    storeCredentialsForFutureSignIn,
} from "./authenticator";
import {
    decodeOAuth2AuthErrorMessage,
    decodeOAuth2AuthTokenMessage,
} from "@glide/common-core/dist/js/authorization/auth";
import {
    type AppSnapshotURLs,
    type GetPasswordForOAuth2TokenBody,
    type NotAllowedToSignInReason,
    asAppLoginTokenContainer,
} from "@glide/common-core";
import { getDeviceID } from "@glide/common-core/dist/js/device-id";
import { callCloudFunctionWeb } from "@glide/common-core/dist/js/utility/function-utils";
import { type JSONObject, isResponseOK, logError } from "@glide/support";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import { parseNotAllowedToSignInReason } from "../utils/parse-not-allowed-to-sign-in-reason";
import { getNotAllowedToSignInMessage } from "../utils/get-not-allowed-to-sign-in-message";
import { getVirtualEmailAddress } from "../utils/get-virtual-email-address";

interface PasswordForOAuth2Token {
    email: string;
    password: string;
    customToken: string;
    userProfileRow: JSONObject | undefined;
    emailColumnName: string | undefined;
    newLoginToken: unknown;
    possiblySchema: unknown;
    snapshotURLs: AppSnapshotURLs;
}

interface OAuthSignInSuccess {
    success: true;
}

interface OAuthSignInError {
    success: false;
    error: string;
}

type OAuthSignInResult = OAuthSignInSuccess | OAuthSignInError;

function makeOAuthErrorResult(msg: string): OAuthSignInError {
    return {
        success: false,
        error: msg,
    };
}

function makeOAuthSuccessResult(): OAuthSignInSuccess {
    return {
        success: true,
    };
}

export class OAuthAuthenticator {
    constructor(private readonly appID: string, private readonly rootAuthenticator: RootAuthenticator) {}

    private flowWindow: Window | null = null;

    public startOAuthFlow = (flowStartURL: string) => {
        this.flowWindow = window.open(flowStartURL, "_blank");
    };

    public isMessageValid = (message: MessageEvent): boolean => {
        const hasReferenceToFlowWindow = this.flowWindow !== null;
        const isSameOrigin = message.origin === location.origin;

        // SSO redirects to the builder URL for now, eventually we'll have a standalone OAuth redirect.
        const isBuilderOrigin = getLocationSettings().urlPrefix.startsWith(message.origin);
        const isValidOrigin = isSameOrigin || isBuilderOrigin;

        const authErrorPayload = decodeOAuth2AuthErrorMessage(message.data);
        const authTokenPayload = decodeOAuth2AuthTokenMessage(message.data);
        const payloadIsValid = authErrorPayload !== undefined || authTokenPayload !== undefined;

        return isValidOrigin && payloadIsValid && hasReferenceToFlowWindow;
    };

    public signInFromOAuthMessage = async (message: MessageEvent): Promise<OAuthSignInResult> => {
        const authErrorPayload = decodeOAuth2AuthErrorMessage(message.data);
        if (authErrorPayload !== undefined) {
            logError("Something went wrong - got an error payload");
            return makeOAuthErrorResult(
                getLocalizedString(authErrorPayload.viaSSO === true ? "ssoAuthError" : "tryAgain", AppKind.Page)
            );
        }

        const authTokenPayload = decodeOAuth2AuthTokenMessage(message.data);
        if (authTokenPayload === undefined) {
            logError("Something went wrong - could not decode OAuth token");
            return makeOAuthErrorResult(getLocalizedString("tryAgain", AppKind.Page));
        }

        const result = await this.signInWithOAuthToken(authTokenPayload.authToken);
        return result;
    };

    private signInWithOAuthToken = async (authToken: string): Promise<OAuthSignInResult> => {
        const appFacilities = getAppFacilities();

        const body: GetPasswordForOAuth2TokenBody = {
            appID: this.appID,
            addUserProfileRow: true,
            deviceID: getDeviceID(),
            authToken,
            userAgreed: true,
        };

        const response = await callCloudFunctionWeb("getPasswordForOAuth2Token", body, {});
        if (response === undefined || !isResponseOK(response)) {
            logError("Could not get password for OAuth2 token", response);
            let notAllowedToSignInReason: NotAllowedToSignInReason | undefined = undefined;
            if (response !== undefined && response.status === 403) {
                notAllowedToSignInReason = await parseNotAllowedToSignInReason(response);
            }
            return makeOAuthErrorResult(getNotAllowedToSignInMessage(notAllowedToSignInReason));
        }

        let responseBody: unknown;
        try {
            responseBody = await response.json();
        } catch (e: unknown) {
            logError("Reading getPasswordForOAuth2Token body", e);
            return makeOAuthErrorResult(getLocalizedString("tryAgain", AppKind.Page));
        }

        const {
            email,
            password,
            customToken,
            userProfileRow,
            emailColumnName,
            possiblySchema,
            newLoginToken,
            snapshotURLs,
        } = this.getPasswordForOAuthTokenInfoFromResponse(responseBody);

        if (typeof email !== "string") {
            return makeOAuthErrorResult(getLocalizedString("tryAgain", AppKind.Page));
        }

        setSnapshotLocationAndInitialSchemaFromResponse(this.appID, snapshotURLs, possiblySchema);

        const wasSignInSuccessful = await appFacilities.signInWithCustomToken(customToken);

        if (!wasSignInSuccessful) {
            return makeOAuthErrorResult(getLocalizedString("tryAgain", AppKind.Page));
        }

        const loginToken = asAppLoginTokenContainer(newLoginToken);
        storeCredentialsForFutureSignIn(this.appID, email, password, loginToken);

        const appUserID = await this.rootAuthenticator.fetchAppUserID();

        if (appUserID === undefined) {
            return makeOAuthErrorResult(getLocalizedString("tryAgain", AppKind.Page));
        }

        this.rootAuthenticator.setAuthenticatedUser({
            appUserID,
            realEmail: email,
            virtualEmail: getVirtualEmailAddress(userProfileRow, emailColumnName) ?? email,
            userProfileRow,
            loginToken,
        });

        return makeOAuthSuccessResult();
    };

    private getPasswordForOAuthTokenInfoFromResponse = (response: unknown): PasswordForOAuth2Token => {
        const anyResponse = response as any;

        return {
            email: anyResponse.email,
            customToken: anyResponse.customToken,
            password: anyResponse.password,
            userProfileRow: anyResponse.userProfileRow,
            emailColumnName: anyResponse.userProfileRow,
            newLoginToken: anyResponse.newLoginToken,
            possiblySchema: anyResponse.schema,
            snapshotURLs: {
                dataSnapshot: anyResponse.dataSnapshot,
                privateDataSnapshot: anyResponse.privateDataSnapshot,
                unusedDataSnapshot: anyResponse.unusedDataSnapshot,
                publishedAppSnapshot: anyResponse.publishedAppSnapshot,
                nativeTableSnapshots: anyResponse.nativeTableSnapshots,
            },
        };
    };
}
