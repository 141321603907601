import { GlideIcon } from "@glide/common";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { TextComponentStyle } from "@glide/component-utils";
import { isSmallScreen, useResponsiveSizeClass, useRootResponsiveSizeClass, css } from "@glide/common-components";
import { isDefined, isEmptyOrUndefinedish } from "@glide/support";
import { isBound } from "@glide/computation-model-types";
import { UIBackgroundStyle, UIButtonAppearance, UIImageStyle, UIStyleVariant, UILayoutVariant } from "@glide/wire";
import classNames from "classnames";
import type { HTMLAttributes } from "react";
import tw from "twin.macro";

import { Img } from "../../components/img/img";
import { Text } from "../../components/text/text";
import { type ExtractedActions, breakoutActions, makeActionSpreadProps } from "../../wire-lib";
import { WireButton } from "../wire-button/wire-button";
import { useSectionStyle } from "../wire-container/wire-container";
import { WireMenuButton } from "../wire-menu-button/wire-menu-button";
import type { ItemDescription } from "./card-collection-types";
import styled from "styled-components";

interface ChecklistProps {
    desc: ItemDescription;
    className?: string;
}

const InputLabel = styled.label``;

export const Checklist: React.VFC<ChecklistProps> = p => {
    const { desc, className } = p;
    const { checked, onCheckedChange, canCheck } = desc;

    const rootSize = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(rootSize);

    const onCheckedChangeImpl = (e: React.ChangeEvent<HTMLInputElement>) => {
        onCheckedChange?.(e.target.checked);
    };

    const dontPropagate = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();

    const hasItemAction = isDefined(desc.onCardClick);

    const leftElement = (
        <InputLabel
            tw="w-10 h-full [min-height:36px] my-auto shrink-0 flex items-center [&.is-pointer]:cursor-pointer"
            onClick={isMobile ? dontPropagate : undefined}
            as={isMobile ? "label" : "div"}
            className={classNames({
                "is-pointer": isMobile || hasItemAction,
            })}
        >
            <div tw="w-6 h-6 relative ml-0.5 p-px">
                <input
                    type="checkbox"
                    checked={checked}
                    disabled={!canCheck}
                    onClick={isMobile ? undefined : dontPropagate}
                    onChange={onCheckedChangeImpl}
                    tw="rounded-full w-full h-full bg-transparent checked:bg-text-contextual-accent ring-1
                        ring-text-contextual-accent cursor-pointer page-hover:(ring-2) focus-visible:(ring-2) active:(ring-2)
                        transition-shadow"
                />

                <div
                    role="presentation"
                    tw="absolute inset-0 text-text-contextual-inverse flex justify-center items-center
                        pointer-events-none invisible data-[state=checked]:visible"
                    data-state={checked ? "checked" : "unchecked"}
                >
                    <GlideIcon kind="stroke" icon="st-checkmark" iconSize={14} />
                </div>
            </div>
        </InputLabel>
    );

    return (
        <BaseList
            desc={desc}
            className={className}
            leftElement={leftElement}
            styleVariant={UIStyleVariant.Minimal}
            fadeDescriptions={checked ?? false}
            supportsSingleLineMode={true}
        />
    );
};

interface ListProps {
    desc: ItemDescription;
    imageStyle: UIImageStyle;
    styleVariant: UIStyleVariant;
    className?: string;
    readonly dataTestId?: string;
    readonly layoutVariant?: UILayoutVariant;
    readonly appID: string;
}

export const List: React.VFC<ListProps> = p => {
    const { desc, imageStyle, layoutVariant = UILayoutVariant.Default, appID } = p;

    const leftElement = isBound(desc.image) ? (
        <Img
            data-testid="list-image"
            src={massageImageUrl(desc.image, { thumbnail: false }, appID)}
            isPages={true}
            tw="mr-4 shrink-0 object-cover bg-n50A rounded-lg text-2xl
                gp-md:(w-20 h-20)"
            className={classNames(imageStyle, layoutVariant)}
            css={css`
                ${tw`w-12 h-12`}

                &.${UIImageStyle.Circle} {
                    ${tw`rounded-full`}
                }

                &.${UILayoutVariant.Compact} {
                    ${tw`w-8 h-8 gp-md:(w-10 h-10) mr-3`}
                }
            `}
        />
    ) : null;

    return (
        <BaseList
            {...p}
            layoutVariant={layoutVariant}
            leftElement={leftElement}
            fadeDescriptions={false}
            supportsSingleLineMode={false}
        />
    );
};

interface BaseListProps {
    readonly desc: ItemDescription;
    readonly styleVariant: UIStyleVariant;
    readonly className?: string;
    readonly leftElement: React.ReactNode;
    readonly fadeDescriptions: boolean;
    readonly supportsSingleLineMode: boolean;
    readonly dataTestId?: string;
    readonly layoutVariant?: UILayoutVariant;
}

const BaseList: React.VFC<BaseListProps> = p => {
    const {
        desc,
        styleVariant,
        leftElement,
        className,
        fadeDescriptions,
        supportsSingleLineMode,
        dataTestId,
        layoutVariant,
    } = p;
    const [primary, secondary, ...maybeMenuActions] = breakoutActions(desc.menuItems, 2);
    let a11yProps: HTMLAttributes<HTMLDivElement> = {};

    const hasItemAction = isDefined(desc.onCardClick);
    if (hasItemAction) {
        a11yProps = {
            "aria-pressed": false,
            role: "button",
            tabIndex: 0,
        };
    }
    const size = useResponsiveSizeClass();
    const smallContainer = isSmallScreen(size);
    const menuActions: ExtractedActions = smallContainer ? [...desc.menuItems].filter(isDefined) : maybeMenuActions;

    const sectionStyle = useSectionStyle();

    return (
        <div
            data-testid={dataTestId}
            {...a11yProps}
            onKeyDown={e => {
                if (e.key === "Enter") desc.onCardClick?.();
            }}
            onClick={e => {
                desc.onCardClick?.();
                e.stopPropagation();
            }}
            className={classNames(
                styleVariant,
                hasItemAction ? "card-click" : undefined,
                "group",
                className,
                sectionStyle,
                "collection-item"
            )}
            tw="relative bg-bg-front text-text-contextual-dark grid overflow-hidden first-of-type:rounded-t-xl
                last-of-type:rounded-b-xl after:(content-[''] absolute pointer-events-none inset-0) not-last:(border-b
                border-border-base)
                gp-md:not-last:after:(border-b border-border-base)
                gp-md:not-last:(border-none)"
            css={css`
                &.card-click {
                    ${tw`cursor-pointer focus-visible:(ring ring-inset ring-text-contextual-accent)`}
                }

                .flat &.${UIStyleVariant.Default} {
                    ${tw`border-none bg-bg-behind after:border-none`}
                }

                &.${UIStyleVariant.Minimal} {
                    ${tw`bg-transparent last:after:(border-b border-border-base) last-of-type:rounded-b-none
                      gp-md:rounded-xl`}
                }
            `}
        >
            <div
                className={classNames(hasItemAction && "card-click")}
                tw="flex flex-1 col-start-1 row-start-1 transition-colors duration-75"
                css={css`
                    &.card-click {
                        ${tw`group-page-hover:bg-bg-hovered group-active:bg-bg-hovered`}
                    }
                `}
            />
            <div
                css={css`
                    .${UIStyleVariant.Minimal} & {
                        ${tw`no-hover:px-0`}
                    }
                `}
                tw="flex col-start-1 row-start-1 p-2 gp-md:p-2"
            >
                <div tw="flex flex-1 items-start overflow-wrap[anywhere]">
                    {leftElement}
                    <div
                        tw="flex flex-col self-center transition-opacity first:ml-1"
                        className={fadeDescriptions ? "fade-text" : "regular-text"}
                        css={css`
                            &.fade-text {
                                opacity: 0.6;
                            }
                        `}
                    >
                        <Descriptions
                            emphasis={desc.emphasis}
                            title={desc.title}
                            subtitle={desc.subtitle}
                            styleVariant={styleVariant}
                            supportsSingleLineMode={supportsSingleLineMode}
                            layoutVariant={layoutVariant}
                        />
                    </div>
                </div>
                <div
                    onKeyDown={e => e.key === "Enter" && e.stopPropagation()}
                    tw="flex justify-end items-center self-center ml-2 gp-md:mr-1 gp-lg:ml-8"
                >
                    {primary !== undefined && (
                        <WireButton
                            tw="mr-2 [min-width:unset] hidden
                                gp-md:flex"
                            data-testid="card-primary-button"
                            appearance={UIButtonAppearance.Filled}
                            {...makeActionSpreadProps(primary)}
                            size={layoutVariant === UILayoutVariant.Compact ? "xs" : "sm"}
                        />
                    )}
                    {secondary !== undefined && (
                        <WireButton
                            tw="mr-2 [min-width:unset] hidden
                                gp-md:flex"
                            appearance={UIButtonAppearance.Bordered}
                            {...makeActionSpreadProps(secondary)}
                            size={layoutVariant === UILayoutVariant.Compact ? "xs" : "sm"}
                        />
                    )}
                    {menuActions.length > 0 && (
                        <WireMenuButton
                            css={css`
                                /* Fix(mauri): This doesn't belong here, remove it once buttons support dark theme. */
                                .${UIBackgroundStyle.Image} .${UIStyleVariant.Minimal} &,
                                .${UIBackgroundStyle.Accent} .${UIStyleVariant.Minimal} &,
                                .${UIBackgroundStyle.Dark} .${UIStyleVariant.Minimal} & {
                                    ${tw`text-text-contextual-dark! focus-visible:after:(ring-text-contextual-dark)
                                      page-hover:after:(bg-bg-hovered) active:after:(bg-bg-hovered)`}
                                }
                            `}
                            menuItems={menuActions}
                            appearance={UIButtonAppearance.Floating}
                        />
                    )}
                    {hasItemAction && menuActions.length === 0 ? (
                        <div tw="self-center gp-md:ml-1">
                            <GlideIcon
                                className="arrow"
                                iconSize={20}
                                css={css`
                                    /* Fix(mauri): This doesn't belong here, remove it once buttons support dark theme. */
                                    .${UIBackgroundStyle.Accent} .${UIStyleVariant.Minimal} &,
                                    .${UIBackgroundStyle.Dark} .${UIStyleVariant.Minimal} & {
                                        ${tw`text-text-contextual-dark`}
                                    }
                                `}
                                tw="self-center text-text-pale"
                                icon="st-chevron-right"
                                kind="stroke"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

interface DescriptionsProps {
    readonly emphasis: string | null | undefined;
    readonly title: string | null | undefined;
    readonly subtitle: string | null | undefined;
    readonly styleVariant: UIStyleVariant;
    readonly supportsSingleLineMode: boolean;
    readonly layoutVariant?: UILayoutVariant;
}

const Descriptions: React.VFC<DescriptionsProps> = p => {
    const { emphasis, title, subtitle, styleVariant, supportsSingleLineMode, layoutVariant } = p;

    const definedTexts = [emphasis, title, subtitle].filter(isDefined);
    const hasOnlyOneText = definedTexts.length === 1;

    if (supportsSingleLineMode && hasOnlyOneText) {
        const text = definedTexts[0];

        return (
            <Text
                element="p"
                variant={TextComponentStyle.regular}
                className={styleVariant}
                css={css`
                    .${UIBackgroundStyle.Image} & {
                        ${tw`text-text-dark`}
                    }

                    .${UIStyleVariant.Minimal} & {
                        ${tw`text-text-contextual-dark`}
                    }
                `}
            >
                {text}
            </Text>
        );
    }
    const hasEmphasisInSubtitleLine = !isEmptyOrUndefinedish(emphasis) && layoutVariant === UILayoutVariant.Compact;
    const hasSubtitle = !isEmptyOrUndefinedish(subtitle);
    const shouldShowSubtitleLine = hasSubtitle || hasEmphasisInSubtitleLine;

    // TODO: Make these into Text components
    return (
        <>
            {emphasis && layoutVariant === UILayoutVariant.Default && (
                <p
                    css={css`
                        .${UIBackgroundStyle.Image} & {
                            ${tw`text-text-dark`}
                        }

                        .${UIStyleVariant.Minimal} & {
                            ${tw`text-text-contextual-accent`}
                        }
                    `}
                    tw="text-xs font-semibold leading-5 uppercase text-text-contextual-accent"
                    className={styleVariant}
                >
                    {emphasis}
                </p>
            )}

            {title && (
                <p
                    css={css`
                        .${UIBackgroundStyle.Image} & {
                            ${tw`text-text-dark`}
                        }

                        .${UIStyleVariant.Minimal} & {
                            ${tw`text-text-contextual-dark`}
                        }
                        &.${UILayoutVariant.Compact} {
                            ${tw`text-sm page-md:text-base`}
                        }
                    `}
                    tw="text-base font-semibold leading-normal whitespace-pre-wrap text-text-contextual-dark"
                    className={classNames(styleVariant, layoutVariant)}
                >
                    {title}
                </p>
            )}

            {shouldShowSubtitleLine && (
                <p
                    css={css`
                        .${UIBackgroundStyle.Image} & {
                            ${tw`text-text-pale`}
                        }

                        .${UIStyleVariant.Minimal} & {
                            ${tw`text-text-contextual-pale`}
                        }
                        &.${UILayoutVariant.Compact} {
                            ${tw`text-sm page-md:text-base`}
                        }
                    `}
                    tw="text-base leading-normal whitespace-pre-wrap text-text-contextual-pale"
                    className={classNames(styleVariant, layoutVariant)}
                >
                    {subtitle} {hasSubtitle && hasEmphasisInSubtitleLine ? "· " : null}
                    {hasEmphasisInSubtitleLine ? `${emphasis}` : ""}
                </p>
            )}
        </>
    );
};
