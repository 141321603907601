import {
    type FlowActionNode,
    type ConditionalActionNode,
    type AutomationRootNode,
    ActionNodeKind,
    type ActionNode,
} from "@glide/app-description";
import { replaceArrayItem } from "@glide/support";
import { assert, assertNever } from "@glideapps/ts-necessities";

function validateFlowActionNode(n: FlowActionNode): string | undefined {
    if (n.actions.length === 0) {
        return "Flow must not be empty";
    }
    return undefined;
}

export function validateCompoundActionNode(
    n: ConditionalActionNode | FlowActionNode | AutomationRootNode
): string | undefined {
    if (n.kind === ActionNodeKind.Flow) {
        return validateFlowActionNode(n);
    } else if (n.kind === ActionNodeKind.AutomationRoot) {
        return validateFlowActionNode(n.flow);
    }
    if (n.conditionalFlows.length === 0 && n.elseFlow === undefined) {
        return "Must have at least one flow";
    }
    for (const c of n.conditionalFlows) {
        const err = validateFlowActionNode(c.flow);
        if (err !== undefined) return err;
    }

    if (n.elseFlow === undefined) return undefined;

    return validateFlowActionNode(n.elseFlow);
}

export function processCompoundActionFlow(
    compound: ConditionalActionNode,
    processFlow: (flow: FlowActionNode) => void
): void {
    for (const flow of compound.conditionalFlows) {
        processFlow(flow.flow);
    }
    if (compound.elseFlow !== undefined) {
        processFlow(compound.elseFlow);
    }
}

export function walkActionNode(node: ActionNode, callback: (node: ActionNode) => ActionNode): ActionNode {
    let result = node;
    switch (result.kind) {
        case ActionNodeKind.Conditional:
            let newConditions = result.conditionalFlows;
            let i = 0;
            for (const c of result.conditionalFlows) {
                const r = walkActionNode(c, callback);
                assert(r.kind === ActionNodeKind.ConditionalFlow);
                if (r !== c) {
                    newConditions = replaceArrayItem(newConditions, i, r);
                }
                i++;
            }
            if (result.conditionalFlows !== newConditions) {
                result = {
                    ...result,
                    conditionalFlows: newConditions,
                };
            }
            if (result.elseFlow !== undefined) {
                const elseNode = walkActionNode(result.elseFlow, callback);
                assert(elseNode.kind === ActionNodeKind.Flow);
                if (elseNode !== result.elseFlow) {
                    result = {
                        ...result,
                        elseFlow: elseNode,
                    };
                }
            }
            break;
        case ActionNodeKind.ConditionalFlow:
            const newFlow = walkActionNode(result.flow, callback);
            assert(newFlow.kind === ActionNodeKind.Flow);
            if (newFlow !== result.flow) {
                result = {
                    ...result,
                    flow: newFlow,
                };
            }
            break;
        case ActionNodeKind.Flow:
            let newActions = result.actions;
            let j = 0;
            for (const c of result.actions) {
                const r = walkActionNode(c, callback);
                assert(
                    r.kind === ActionNodeKind.Primitive ||
                        r.kind === ActionNodeKind.Conditional ||
                        r.kind === ActionNodeKind.Loop
                );
                if (r !== c) {
                    newActions = replaceArrayItem(newActions, j, r);
                }
                j++;
            }
            if (result.actions !== newActions) {
                result = {
                    ...result,
                    actions: newActions,
                };
            }
            break;
        case ActionNodeKind.AutomationRoot:
            const newRootFlow = walkActionNode(result.flow, callback);
            assert(newRootFlow.kind === ActionNodeKind.Flow);
            if (newRootFlow !== result.flow) {
                result = {
                    ...result,
                    flow: newRootFlow,
                };
            }
            break;
        case ActionNodeKind.Loop:
            const newLoopFlow = walkActionNode(result.flow, callback);
            assert(newLoopFlow.kind === ActionNodeKind.Flow);
            if (newLoopFlow !== result.flow) {
                result = {
                    ...result,
                    flow: newLoopFlow,
                };
            }
            break;
        case ActionNodeKind.Primitive:
            // do nothing
            break;
        default:
            assertNever(result);
    }

    return callback(result);
}
