import { asMaybeNumber } from "@glide/common-core/dist/js/computation-model/data";
import {
    makeEnumProperty,
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
} from "@glide/app-description";
import { makeEmptyComponentDescription, type InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireAppRatingComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    EnumPropertyHandler,
    PropertySection,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type WireInflationBackend, type WireRowComponentHydratorConstructor, WireComponentKind } from "@glide/wire";
import {
    inflateEditablePropertyWithDefault,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import { labelCaptionStringOptions, makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";
import { assertNever } from "@glideapps/ts-necessities";

const ComponentKindRating: ComponentKind = "rating";

const columnPropertyHandler = new ColumnPropertyHandler(
    "dataColumnName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
    ],
    undefined,
    ["rating", "stars"],
    getPrimitiveColumnsSpec,
    "number",
    PropertySection.Data
);

const maxRatingPropertyHandler = new EnumPropertyHandler(
    { maxRating: 3 },
    "Max Rating",
    "Max Rating",
    [
        {
            value: 3,
            label: "3",
        },
        {
            value: 4,
            label: "4",
        },
        {
            value: 5,
            label: "5",
        },
    ],
    PropertySection.Design,
    "slider"
);

const ratingUnitPropertyHandler = new EnumPropertyHandler(
    { ratingUnit: "stars" },
    "Unit",
    "Unit",
    [
        {
            value: "stars",
            label: "Stars",
            icon: "ratingStar",
        },
        {
            value: "hearts",
            label: "Hearts",
            icon: "ratingHeart",
        },
        {
            value: "money",
            label: "$",
            icon: "ratingMoney",
        },
        {
            value: "time",
            label: "Time",
            icon: "ratingTime",
        },
        {
            value: "pizzas",
            label: "Pizzas",
            icon: "ratingPizza",
        },
    ],
    PropertySection.Design,
    "dropdown"
);

interface RatingComponentDescription extends ComponentDescription {
    readonly dataColumnName: PropertyDescription;
    readonly caption: PropertyDescription | undefined;
}

function convertMaxRatingToNewEnum(maxRating: ReturnType<typeof maxRatingPropertyHandler.getEnum>): "3" | "4" | "5" {
    switch (maxRating) {
        case 3:
            return "3";
        case 4:
            return "4";
        case 5:
            return "5";
        default:
            assertNever(maxRating);
    }
}

export class RatingComponentHandler extends ComponentHandlerBase<RatingComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindRating);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public get isNonEmptyValidationStopper(): boolean {
        return true;
    }

    public getDescriptor(
        _desc: RatingComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Rating",
            description: "Rate an item",
            img: "rating",
            group: "Pickers",
            helpUrl: getDocURL("rating"),
            isLegacy: false,
            properties: [
                columnPropertyHandler,
                ratingUnitPropertyHandler,
                maxRatingPropertyHandler,
                ...this.getBasePropertyDescriptors(),
                makeCaptionStringPropertyDescriptor(
                    "Rating",
                    false,
                    mutatingScreenKind,
                    labelCaptionStringOptions,
                    "caption"
                ),
            ],
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: RatingComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const { getter: valueGetter, isInContext } = inflateEditablePropertyWithDefault(
            ib,
            "set",
            desc.dataColumnName,
            asMaybeNumber,
            0
        );
        if (valueGetter === undefined) return undefined;

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);

        const maxRating = maxRatingPropertyHandler.getEnum(desc);
        const unit = ratingUnitPropertyHandler.getEnum(desc);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const value = valueGetter(hb);
            if (value === undefined) return undefined;

            const component: WireAppRatingComponent = {
                kind: WireComponentKind.AppRating,
                ...spreadComponentID(desc.componentID, forBuilder),
                caption: captionGetter(hb) ?? "",
                value,
                maxRating,
                unit,
            };
            return {
                component,
                isValid: true,
                editsInContext: isInContext,
                hasValue: value.value !== 0,
            };
        });
    }

    public convertToPage(
        desc: RatingComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        const maxRating = maxRatingPropertyHandler.getEnum(desc);
        return {
            ...makeEmptyComponentDescription(WireComponentKind.PageRating),
            label: desc.caption,
            rate: desc.dataColumnName,
            text: desc.dataColumnName,
            maxRating: makeEnumProperty(convertMaxRatingToNewEnum(maxRating)),
        } as ComponentDescription;
    }
}
