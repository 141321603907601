import { SeparatorSpacing } from "@glide/common-core/dist/js/components/separator-spacing";
import {
    makeEnumProperty,
    makeSwitchProperty,
    type ComponentDescription,
    type ComponentKind,
    type PropertyDescription,
} from "@glide/app-description";
import type { WireAppSeparatorComponent } from "@glide/fluent-components/dist/js/base-components";
import type { AppDescriptionContext } from "@glide/function-utils";
import { PropertySection, EnumPropertyHandler, SwitchPropertyHandler } from "@glide/function-utils";

import { AppKind } from "@glide/location-common";
import {
    type WireRowComponentHydratorConstructor,
    WireComponentKind,
    type WireComponentHydrationResult,
    type WireInflationBackend,
    SeparatorSize,
} from "@glide/wire";

import { makeSimpleWireRowComponentHydratorConstructor, spreadComponentID } from "../wire/utils";
import {
    type PropertyDescriptorWithArgumentName,
    type SimpleComponentDescription,
    SimpleComponentHandler,
} from "./simple-handler";
import { makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";

const ComponentKindSeparator: ComponentKind = "separator";

const separatorSpacings = [
    {
        value: SeparatorSpacing.Default,
        label: "Default",
    },
    {
        value: SeparatorSpacing.Small,
        label: "Small",
    },
    {
        value: SeparatorSpacing.Medium,
        label: "Medium",
    },
    {
        value: SeparatorSpacing.Large,
        label: "Large",
    },
];

const showLinePropertyHandler = new SwitchPropertyHandler(
    { showLine: true },
    "Show line divider",
    PropertySection.Options
);

const separatorSpacingPropertyHandler = new EnumPropertyHandler(
    { size: SeparatorSpacing.Default },
    "Spacing",
    "Spacing",
    separatorSpacings,
    PropertySection.Options,
    "dropdown"
);

interface SeparatorComponentDescription extends SimpleComponentDescription {
    readonly spacing: PropertyDescription;
    readonly showLine: PropertyDescription;
}

export class SeparatorComponentHandler extends SimpleComponentHandler {
    public readonly appKinds = AppKind.App;

    constructor() {
        super({
            kind: ComponentKindSeparator,
            name: "Separator",
            group: "Layout",
            description: "A horizontal line",
            img: "co-separator",
            helpPath: "separator",
        });
    }

    protected getPropertyDescriptors(): readonly PropertyDescriptorWithArgumentName[] {
        return [
            {
                descriptor: separatorSpacingPropertyHandler,
                argumentName: "spacing",
            },
            {
                descriptor: showLinePropertyHandler,
                argumentName: "showLine",
            },
        ];
    }

    public get needsColumns(): boolean {
        return false;
    }

    public inflate(
        ib: WireInflationBackend,
        desc: SimpleComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const spacing = separatorSpacingPropertyHandler.getEnum(desc);
        const showLine = showLinePropertyHandler.getSwitch(desc);

        const component: WireAppSeparatorComponent = {
            kind: WireComponentKind.AppSeparator,
            ...spreadComponentID(desc.componentID, forBuilder),
            spacing,
            showLine,
        };
        const result: WireComponentHydrationResult = {
            component,
            isValid: true,
        };
        return makeSimpleWireRowComponentHydratorConstructor(() => result);
    }

    private convertSpacingToSize(spacing: SeparatorSpacing): SeparatorSize {
        switch (spacing) {
            case SeparatorSpacing.Default:
                return SeparatorSize.Medium;
            case SeparatorSpacing.Small:
                return SeparatorSize.Small;
            case SeparatorSpacing.Medium:
                return SeparatorSize.Medium;
            case SeparatorSpacing.Large:
                return SeparatorSize.Large;
        }
    }

    public convertToPage(
        desc: SeparatorComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        const size = separatorSpacingPropertyHandler.getEnum(desc);
        const showLine = showLinePropertyHandler.getSwitch(desc);
        return {
            ...makeEmptyComponentDescription(WireComponentKind.Separator),
            size: makeEnumProperty(this.convertSpacingToSize(size)),
            drawLine: makeSwitchProperty(showLine),
        } as ComponentDescription;
    }
}
