import type { MinimalAppEnvironment } from "./components/types";
import * as React from "react";

type StateType = Record<string, { readonly appEnvironment: MinimalAppEnvironment }>;

export let getExtraAppsAndState: () => StateType = () => ({});
export let updateExtraAppsAndState: (cb: (state: StateType) => StateType | undefined) => void = () => undefined;

export function setAppEnvironmentForAppID(appEnvironment: MinimalAppEnvironment): void {
    updateExtraAppsAndState(s => ({ ...s, [appEnvironment.appID]: { appEnvironment } }));
}

export function getAppEnvironmentForAppID(appID: string): MinimalAppEnvironment | undefined {
    return getExtraAppsAndState()[appID]?.appEnvironment;
}

export function clearAppEnvironmentForAppID(appID: string): void {
    updateExtraAppsAndState(s => {
        const result = { ...s };
        delete result[appID];
        return result;
    });
}

export const AppsStateContext = React.createContext<StateType>({});

export const AppStateProvider: React.FC<React.PropsWithChildren> = p => {
    const [val, setVal] = React.useState({});

    const valRef = React.useRef(val);
    valRef.current = val;

    React.useEffect(() => {
        getExtraAppsAndState = () => valRef.current;
        updateExtraAppsAndState = cb => {
            const newVal = cb(valRef.current);
            if (newVal !== undefined) {
                setVal(newVal);
            }
        };
        return () => {
            getExtraAppsAndState = () => ({});
            updateExtraAppsAndState = () => undefined;
        };
    }, []);

    return <AppsStateContext.Provider value={val}>{p.children}</AppsStateContext.Provider>;
};
