/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

const writeKey = glide.makeParameter({
    type: "string",
    name: "Write Key",
    description:
        "a unique identifier for a source that tells Segment which source the data comes from, to which workspace the data belongs, and which destinations should receive the data",
    placeholder: "e.g. wXeME5...",
    required: true,
});

export const plugin = glide.newPlugin({
    name: "Segment",
    id: "segment",
    description: "Track user behavior with Segment",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/v1724267152/plugins/segment.png",
    tier: "starter",
    parameters: { writeKey },
    documentationUrl: "https://segment.com/docs/getting-started/02-simple-install/",
});

function isValidWriteKey(key: string): boolean {
    // Regex pattern to match a 32-character string of letters and numbers
    const apiKeyPattern = /^[A-Za-z0-9]{32}$/;

    // Test the key against the pattern and return the result
    return apiKeyPattern.test(key);
}

type Analytics = {
    track: (event: string, properties?: any) => void;
    identify: (userID: string, properties: any) => void;
    page: (title: string) => void;
};

function withAnalytics<T>(callback: (analytics: Analytics) => T): glide.Result<T> {
    const { analytics } = window as any;
    if (analytics === undefined) {
        return glide.Result.Fail("Segment not yet loaded.", {
            isPluginError: false, // ad blockers cause this
        });
    }
    return glide.Result.Ok(callback(analytics));
}

plugin.addHeader(({ writeKey }) => {
    return isValidWriteKey(writeKey)
        ? `
    <script>
        <!-- Segment snippet -->
       !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="uKvsRktK7e5FUQLgOd0reRBeaghHaH37";;analytics.SNIPPET_VERSION="5.2.0";
        analytics.load(${JSON.stringify(writeKey)});
        analytics.page();
        }}();
    </script
        `
        : `<script>console.log("Invalid Segment write key")</script>`;
});

plugin.setEventTracker((_params, event) => {
    withAnalytics(analytics => {
        switch (event.kind) {
            case "load":
                analytics.track("load");
                break;
            case "identify":
                analytics.identify(event.userID, {
                    name: event.username,
                    email: event.email,
                });
                break;
            case "navigate":
                analytics.page(event.title);
                break;
            case "action":
                analytics.track(event.name, event.data);
                break;
        }
    });
});

const event = glide.makeParameter({
    type: "string",
    name: "Name",
    required: true,
    useTemplate: "withLabel",
});

const properties = glide.makeParameter({
    type: "stringObject",
    name: "properties",
    description: "Properties",
});

plugin.addClientAction({
    id: "send-segment-event",
    name: "Track event",
    description: "Send an event to Segment",
    parameters: { event, properties },
    needsClient: true,
    async execute(_context, { event, properties }) {
        return withAnalytics(a => a.track(event ?? "", properties));
    },
});
