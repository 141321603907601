import {
    type NativeTableID,
    type TableName,
    type TableGlideType,
    type TypeSchema,
    getTableName,
    type SourceMetadata,
    getNativeTableSourceMetadata,
} from "@glide/type-schema";
import { assert } from "@glideapps/ts-necessities";
import type { SQLTableName } from "./firebase-function-types";

// This will return the native table ID for the query, which is what we use as
// the query ID.
export function getQueryIDForTable(
    sourceMetadata: readonly SourceMetadata[] | undefined,
    table: TableGlideType
): NativeTableID | undefined {
    const tableName = getTableName(table);

    let sm: SourceMetadata | undefined;
    if (sourceMetadata !== undefined) {
        sm = getNativeTableSourceMetadata(sourceMetadata, tableName);
    }
    if (sm === undefined) {
        sm = table.sourceMetadata;
    }

    if (sm?.type !== "Native table") return undefined;
    if (sm.needsQuery !== true) return undefined;

    return sm.id;
}

export function findTableNameForQuery(
    sourceMetadata: readonly SourceMetadata[] | undefined,
    schema: TypeSchema | undefined,
    queryID: NativeTableID
): TableName | undefined {
    function testSourceMetadata(sm: SourceMetadata): TableName | undefined {
        if (sm.type !== "Native table") return undefined;
        if (sm.id !== queryID) return undefined;
        assert(sm.needsQuery === true);
        return sm.tableName;
    }

    if (sourceMetadata !== undefined) {
        for (const sm of sourceMetadata) {
            const tableName = testSourceMetadata(sm);
            if (tableName !== undefined) {
                return tableName;
            }
        }
    }
    if (schema !== undefined) {
        for (const t of schema.tables) {
            if (t.sourceMetadata === undefined) continue;
            const tableName = testSourceMetadata(t.sourceMetadata);
            if (tableName !== undefined) {
                return tableName;
            }
        }
    }
    return undefined;
}

export function getDisplayNameForSQLTableName(name: SQLTableName): string {
    return name.join(".");
}
