import { asMaybeNumber, asString } from "@glide/common-core/dist/js/computation-model/data";
import { isLoadingValue, RollupKind } from "@glide/computation-model-types";
import { type TableColumn, getTableColumnDisplayName, maybeGetTableColumn } from "@glide/type-schema";
import {
    ArrayScreenFormat,
    getArrayProperty,
    getColumnProperty,
    getEnumProperty,
    getStringProperty,
    getSwitchProperty,
} from "@glide/app-description";
import {
    type WireChartData,
    type WireChartsComponent,
    CHART_X_AXIS_DATA_KEY,
    getChartDisplayDataKey,
} from "@glide/fluent-components/dist/js/base-components";
import { type ChartsColumnDescription, Charts } from "@glide/fluent-components/dist/js/fluent-components";
import type { InlineListComponentDescription } from "@glide/function-utils";
import {
    type WireRowHydrationValueProvider,
    type WireValueGetterGeneric,
    type BarChartType,
    type BarChartWeights,
    type ChartType,
    type WirePaging,
    WireComponentKind,
} from "@glide/wire";
import { DefaultMap, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import { formatValueWithSpecification } from "../format-value";
import type { ValueFormatSpecification } from "@glide/formula-specifications";
import { isQueryableColumn } from "../computed-columns";
import { decomposeAggregateRow, getAggregateFromRow } from "../wire/aggregates";
import {
    applyPaging,
    getFormatForInputColumn,
    inflateComponentEnricher,
    inflateNumberProperty,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
} from "../wire/utils";
import { makeFluentArrayContentHandler } from "./fluent-array-handler";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";

interface InflatedChartYAxis {
    // `undefined` means just the count
    readonly queryColumn: TableColumn | undefined;
    readonly valueGetter: WireValueGetterGeneric<number | undefined>;
    readonly format: ValueFormatSpecification | undefined;
}

function getAggregateNameForID(id: string) {
    return `agg-${id}`;
}

export const chartsFluentArrayContentHandler = makeFluentArrayContentHandler(Charts, (ib, desc, containingRowIB) => {
    if (containingRowIB === undefined) {
        return undefined;
    }

    const [componentTitleGetter] = inflateStringProperty(containingRowIB, desc.title, true);
    const graphDescs = getArrayProperty<ChartsColumnDescription>(desc.graph);

    if (graphDescs === undefined) {
        return undefined;
    }

    // This is a Record cause we're sending it through Wire. Needs to be serializable per spec.
    // It maps Y value ID to its caption.
    const captions: Record<string, string> = {};

    const [xAxisLabelGetter] = inflateStringProperty(ib, desc.xAxis, true);
    const xAxisColumnName = getColumnProperty(desc.xAxis);
    const xAxisFormat = getFormatForInputColumn(ib, xAxisColumnName);
    const xAxisColumn = maybeGetTableColumn(ib.tables.input, xAxisColumnName);

    const yAxisGetters: Map<string, InflatedChartYAxis> = new Map();

    const gbtComputedColumnsAlpha = isExperimentEnabled("gbtComputedColumnsAlpha", ib.adc.userFeatures);
    const gbtDeepLookups = isExperimentEnabled("gbtDeepLookups", ib.adc.userFeatures);

    // If no Y axis is configured, the special behavior is that all rows count as `1`
    if (graphDescs.length === 0) {
        const id = "0";
        const columnName = getColumnProperty(desc.xAxis);
        const column = maybeGetTableColumn(ib.tables.input, columnName);
        const caption = definedMap(column, c => getTableColumnDisplayName(c)) ?? "";

        const valueGetter: WireValueGetterGeneric<number | undefined> = _rhb => {
            return 1;
        };

        yAxisGetters.set(id, { queryColumn: undefined, valueGetter, format: undefined });
        captions[id] = caption;
    } else {
        // Incremental IDs, why not.
        for (let numericalId = 0; numericalId < graphDescs.length; numericalId++) {
            const graphDesc = graphDescs[numericalId];

            const id = numericalId.toString();

            const [valueGetter] = inflateNumberProperty(ib, graphDesc.value);
            const caption = getStringProperty(graphDesc.caption) ?? "";

            const columnName = getColumnProperty(graphDesc.value);
            const column = maybeGetTableColumn(ib.tables.input, columnName);
            const format = getFormatForInputColumn(ib, columnName);

            yAxisGetters.set(id, {
                queryColumn:
                    column !== undefined &&
                    isQueryableColumn(ib.adc, ib.tables.input, column, gbtComputedColumnsAlpha, gbtDeepLookups)
                        ? column
                        : undefined,
                valueGetter,
                format,
            });
            captions[id] = caption;
        }
    }

    const chartType = getEnumProperty<ChartType>(desc.chartType);
    const barChartType = getEnumProperty<BarChartType>(desc.barChartType);
    const barChartWeight = getEnumProperty<BarChartWeights>(desc.barChartWeight);
    const showXLabels = getSwitchProperty(desc.showXLabels);
    const showYLabels = getSwitchProperty(desc.showYLabels);
    const showGridLines = getSwitchProperty(desc.showGridLines);
    const showLegend = getSwitchProperty(desc.showLegend);

    const componentEnricher = inflateComponentEnricher<WireChartsComponent>(
        ib,
        desc as unknown as InlineListComponentDescription
    );

    function makeComponent(
        containingRowHB: WireRowHydrationValueProvider,
        chartData: WireChartData[],
        paging: WirePaging | undefined
    ) {
        const graphTitle = componentTitleGetter(containingRowHB);
        let component = componentEnricher({
            kind: WireComponentKind.List,
            format: ArrayScreenFormat.Charts,
            chartData,
            graphTitle,
            chartType,
            barChartType,
            barChartWeight,
            showXLabels,
            showYLabels,
            showGridLines,
            showLegend,
            captions,
        });
        if (paging !== undefined) {
            component = { ...component, paging };
        }
        return { component, isValid: true };
    }

    return makeSimpleWireTableComponentHydratorConstructor(
        ib,
        (hb, containingRowHB) => {
            if (containingRowHB === undefined) {
                return undefined;
            }

            const rows = hb.tableScreenContext.asArray();
            const chartData: WireChartData[] = [];

            // Each item in the X axis can have many Y values, this maps each Y value ID to its actual values.
            type CaptionToYValue = DefaultMap<string, { value: number; format: ValueFormatSpecification | undefined }>;

            // And this maps each X label to their corresponding Y values.
            const aggregatedValuesForLabels: DefaultMap<string, CaptionToYValue> = new DefaultMap(
                () => new DefaultMap(() => ({ value: 0, format: undefined }))
            );

            for (const row of rows) {
                const rhb = hb.makeHydrationBackendForRow(row);
                const xAxisForRow = xAxisLabelGetter(rhb) ?? "";

                const currentYValues = aggregatedValuesForLabels.get(xAxisForRow);

                for (const [id, { valueGetter, format }] of yAxisGetters.entries()) {
                    const value = valueGetter(rhb) ?? 0;

                    currentYValues.update(id, prev => ({ value: prev.value + value, format }));
                }
            }

            for (const [xLabel, yValues] of aggregatedValuesForLabels.entries()) {
                const chartDataForXLabel: WireChartData = {};
                chartDataForXLabel[CHART_X_AXIS_DATA_KEY] = xLabel;

                for (const [id, yValue] of yValues.entries()) {
                    chartDataForXLabel[id] = yValue.value;

                    const displayData = formatValueWithSpecification(yValue.format, yValue.value) ?? "";
                    chartDataForXLabel[getChartDisplayDataKey(id)] = displayData;
                }

                chartData.push(chartDataForXLabel);
            }

            return makeComponent(containingRowHB, chartData, undefined);
        },
        (rhb, query, _thb, _searchActive, pageIndexState, selectedPageSize) => {
            if (
                xAxisColumn === undefined ||
                !isQueryableColumn(ib.adc, ib.tables.input, xAxisColumn, gbtComputedColumnsAlpha, gbtDeepLookups)
            ) {
                return undefined;
            }

            query = query.withGroupBy({
                columns: [xAxisColumn.name],
                aggregates: mapFilterUndefined(Array.from(yAxisGetters), ([id, yAxis]) => {
                    if (yAxis.queryColumn === undefined) return undefined;
                    return {
                        column: yAxis.queryColumn.name,
                        kind: RollupKind.Sum,
                        name: getAggregateNameForID(id),
                    };
                }),
                sort: [{ columnName: xAxisColumn.name, order: "asc" }],
                limit: 1000,
            });
            const table = rhb.resolveQueryAsTable(query);
            if (table === undefined || isLoadingValue(table)) return undefined;

            const [paging, rows] = applyPaging(table.asMutatingArray(), pageIndexState, selectedPageSize);

            const chartData: WireChartData[] = [];
            for (const row of rows) {
                const { group, count } = decomposeAggregateRow(row);

                const chartDataForXLabel: WireChartData = {};
                chartDataForXLabel[CHART_X_AXIS_DATA_KEY] =
                    formatValueWithSpecification(xAxisFormat, group) ?? asString(group);

                for (const [id, yAxis] of yAxisGetters) {
                    let value: number;

                    if (yAxis.queryColumn === undefined) {
                        value = count;
                    } else {
                        // The backend will not return a value if there are no
                        // valid values to aggregate.
                        value = getAggregateFromRow(row, getAggregateNameForID(id), asMaybeNumber) ?? 0;
                    }

                    const key = id.toString();
                    chartDataForXLabel[key] = value;
                    chartDataForXLabel[getChartDisplayDataKey(key)] =
                        formatValueWithSpecification(yAxis.format, value) ?? "";
                }

                chartData.push(chartDataForXLabel);
            }

            return makeComponent(rhb, chartData, paging);
        }
    );
});
