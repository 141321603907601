import { GlobalStyle } from "@glide/common";
import React from "react";
import { domMovementContainerHandlers } from "./chrome/smart/swipe-out-controller/swipe-out-controller";
import { Scroller } from "@glide/common-components";

interface Props extends React.PropsWithChildren {
    appContainer: JSX.Element;
}

const GlideAppMobilePlayer = (props: Props) => {
    const { appContainer } = props;

    const ref = React.useRef<HTMLDivElement>(null);
    const movementHandlers = React.useMemo(() => domMovementContainerHandlers(ref), [ref]);

    return (
        <React.Fragment>
            <GlobalStyle />
            <Scroller ref={ref} {...movementHandlers}>
                {appContainer}
            </Scroller>
        </React.Fragment>
    );
};

export default GlideAppMobilePlayer;
