import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react";

import type { Content, Editor, JSONContent } from "@tiptap/react";
import { EditorContent, Extension, ReactNodeViewRenderer, useEditor } from "@tiptap/react";

import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";

import { EditorContextProvider, useEditorContext } from "./editor-context";
import { TokenExtension } from "./token-view";
import { buildSuggestions } from "./build-suggestions";
import styled from "styled-components";
import { MenuCombobox } from "./combobox";
import tw from "twin.macro";
import { isDefined } from "@glide/support";
import { useIsDarkTheme } from "../../hooks/use-builder-theme";

import { Decoration, DecorationSet } from "@tiptap/pm/view";

import { Plugin, PluginKey, TextSelection } from "@tiptap/pm/state";
import type { ImageViewProps } from "./image-view";
import { ImageView } from "./image-view";
import type { ImageOptions } from "@tiptap/extension-image";
import Image from "@tiptap/extension-image";
import { defaultAllowedMimeTypes, DropPasteImage, handleImageEvent } from "./drop-paste-image";
import type { tokenAttrsCodec, PlainTextInlineTemplateValue, TokenStyle } from "./utils";
import { unwrapParagraphs, wrapInParagraph } from "./utils";

import type * as t from "io-ts";
import { GlideIcon } from "@glide/common";
import { SingleImageBindingHandlerExtension } from "./single-image-binding";
import { useClickOutsideEditor } from "./useClickOutsideEditor";
import deepEqual from "deep-equal";
import { hasOwnProperty } from "@glideapps/ts-necessities";
import { useOffscreen } from "./useOffscreen";

const Container = styled.div<{ paddingRight: string }>`
    position: relative;
    .node-binding {
        display: inline-flex;
        max-width: 100%;
    }
    .is-editor-empty:first-child::before {
        ${tw`float-left h-0 pointer-events-none text-text-disabled text-builder-base`};
        content: attr(data-placeholder);
    }
    p {
        line-height: 20px;
    }

    p:first-of-type {
        padding-right: ${p => p.paddingRight};
    }
    .suggestion {
        position: relative;
    }

    .dynamic-placeholder::before {
        ${tw`text-text-disabled text-builder-base h-0 pointer-events-none top-0 left-0 absolute -mt-0.5`};
        content: "@Column";
    }
    .dynamic-placeholder {
        position: relative;
    }

    .is-empty {
        overflow: hidden;
        white-space: nowrap;
    }

    .ProseMirror-separator {
        opacity: 0;
    }

    .tiptap {
        padding: 5px 4px 5px 8px;
        height: 100%;
    }

    .single-node-binding {
        position: relative;
    }
    .single-node-binding::before {
        content: "Text or @column";
        ${tw`text-text-disabled text-builder-base h-0 pointer-events-none top-0 -right-[104px] absolute`};
    }

    .node-image {
        overflow: hidden;
        display: inline-flex;
        max-width: 100%;
        line-height: 20px;
    }
`;

export type TokenAttrs<T> = t.TypeOf<typeof tokenAttrsCodec> & {
    value?: T;
    onItemSecondaryClick?: () => void;
};

export interface MenuOption<T> extends TokenAttrs<T> {
    items?: TokenAttrs<T>[];
    onItemSecondaryClick?: () => void;
}

export interface InternalMenuOptionType<T> extends MenuOption<T> {
    group?: string;
}

interface BindingStorageExtension<T> {
    suggestions: MenuOption<T>[];
    contextualLabel: string | undefined;
}

type EditorProps<T> = {
    disabled?: boolean;
    placeholder?: string;
    focusPlaceholder?: string;
    className?: string;
    singleImage?: boolean;
    autoFocus?: boolean;
    onChange: (content: Content) => void;
    onKeyDown?: (e: KeyboardEvent, editor: Editor) => void;
    value: Content;
    contextualLabel?: string;
    showMenuOnFocus?: boolean;
    menuOptions: MenuOption<T>[];
    highlight?: boolean;
    hideDropdown?: boolean;
    disableNewLines?: boolean;
    disableBindings?: boolean;
    tokenStyle?: "default" | "compact"; // New prop for token style variant
    onUpload?: (file: File) => Promise<{
        url?: string;
    }>;
    uploadOptions?: {
        allowedMimeTypes?: string[];
        maxFileSize?: number;
    };
    disableTopBorder?: boolean;
};

const hasBase64Image = (nodes: JSONContent[]): boolean => {
    return nodes.some(node => {
        if (node.type === "image" && node.attrs?.src?.startsWith("data:image/")) {
            return true;
        }
        if (node.content) {
            return hasBase64Image(node.content);
        }
        return false;
    });
};

const defaultPlaceholder = "Text or @column";

type PlainTextInlineTemplatingInputProps<T> = {
    disabled?: boolean;
    placeholder?: string;
    focusPlaceholder?: string;
    className?: string;
    singleImage?: boolean;
    autoFocus?: boolean;
    onChange: (content: PlainTextInlineTemplateValue<T>) => void;
    value: PlainTextInlineTemplateValue<T>;
    showMenuOnFocus?: boolean;
    onKeyDown?: (e: KeyboardEvent, editor: Editor) => void;
    disableBindings?: boolean;
    menuOptions: MenuOption<T>[];
    disableNewLines?: boolean;
    tokenStyle?: TokenStyle;
    highlight?: boolean;
    contextualLabel?: string;
    /** Whether to unmount the editor component when it scrolls out of view. Defaults to true. */
    unmountWhenOffscreen?: boolean;
    onUpload?: (file: File) => Promise<{
        url?: string;
    }>;
    uploadOptions?: {
        allowedMimeTypes?: string[];
        maxFileSize?: number;
    };
    disableTopBorder?: boolean;
    hideDropdown?: boolean;
};

/**
 * React `forwardRef`'s official types do not work with generic components. This fixes that type:
 * https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref/58473012
 */
declare module "react" {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.JSX.Element | null
    ): (props: P & React.RefAttributes<T>) => React.JSX.Element | null;
}

export type EditorComponentRef = {
    submit(): string | false | undefined;
};

const PlainTextInlineTemplatingInputBase = <T,>(
    p: PlainTextInlineTemplatingInputProps<T>,
    ref: React.ForwardedRef<EditorComponentRef>
) => {
    const {
        menuOptions,
        onChange,
        value,
        className,
        disableNewLines,
        placeholder,
        focusPlaceholder,
        showMenuOnFocus,
        tokenStyle,
        onUpload,
        uploadOptions,
        disableBindings,
        onKeyDown,
        autoFocus,
        disabled,
        singleImage,
        disableTopBorder,
        highlight,
        unmountWhenOffscreen = true,
        contextualLabel,
        hideDropdown,
    } = p;
    const { ref: containerRef, isVisible } = useOffscreen();
    const isUploadDefined = isDefined(onUpload) && !disabled;

    // we have widget buttons (upload, four dots), so we need to adjust the padding
    const paddingRight = isUploadDefined ? "62px" : "28px";
    return (
        <Container paddingRight={paddingRight} ref={containerRef}>
            {isVisible || !unmountWhenOffscreen ? (
                <EditorContextProvider>
                    <EditorComponent
                        ref={ref}
                        contextualLabel={contextualLabel}
                        menuOptions={menuOptions}
                        showMenuOnFocus={showMenuOnFocus}
                        disableNewLines={disableNewLines}
                        value={wrapInParagraph<T>(value)}
                        className={className}
                        highlight={highlight}
                        placeholder={placeholder}
                        tokenStyle={tokenStyle}
                        onUpload={onUpload}
                        disableBindings={disableBindings}
                        uploadOptions={uploadOptions}
                        onKeyDown={onKeyDown}
                        autoFocus={autoFocus}
                        singleImage={singleImage}
                        disabled={disabled}
                        focusPlaceholder={focusPlaceholder}
                        onChange={updatedValue => {
                            onChange(unwrapParagraphs(updatedValue));
                        }}
                        disableTopBorder={disableTopBorder}
                        hideDropdown={hideDropdown}
                    />
                </EditorContextProvider>
            ) : (
                <div
                    data-disable-top-border={disableTopBorder}
                    className={className}
                    data-is-highlighted={highlight}
                    data-is-dark-theme={true}
                    tw="relative z-10 w-full rounded-lg border border-solid transition !min-h-[32px]
             focus-within:bg-n0 focus-within:transition-none focus-within:hover:bg-n0
            text-builder-base text-text-dark
            focus-within:border-aqua400 focus-within:hover:border-aqua400
            data-[is-active=true]:border-aqua400 data-[is-active=true]:hover:border-aqua400
            hover:border-border-dark hover:bg-n100A border-n50A bg-n200A
            data-[is-active=true]:bg-n0 data-[is-active=true]:transition-none data-[is-active=true]:hover:bg-n0
            data-[is-active=true]:data-[is-dark-theme=true]:bg-bg-front
            data-[is-active=true]:data-[is-dark-theme=true]:hover:bg-bg-front
            focus-within:data-[is-dark-theme=true]:bg-bg-front
            data-[disable-top-border=true]:border-t-0 data-[disable-top-border=true]:rounded-t-none
            data-[is-highlighted=true]:!outline data-[is-highlighted=true]:!outline-1 data-[is-highlighted=true]:!outline-b300 data-[is-highlighted=true]:hover:!outline-b300
             "></div>
            )}
        </Container>
    );
};

export const PlainTextInlineTemplatingInput = forwardRef(PlainTextInlineTemplatingInputBase);

const EditorComponentBase = <T = unknown,>(
    {
        menuOptions,
        showMenuOnFocus = false,
        disableNewLines = true,
        disableBindings = false,
        focusPlaceholder,
        onChange,
        value,
        className,
        placeholder,
        onUpload,
        uploadOptions,
        onKeyDown,
        autoFocus = false,
        disabled = false,
        singleImage = false,
        disableTopBorder = false,
        hideDropdown = false,
        highlight = false,
        contextualLabel,
    }: EditorProps<T>,
    ref: React.ForwardedRef<EditorComponentRef>
) => {
    const {
        selectedNodePosition,
        setSelectedNodePosition,
        setActiveMenu,
        activeMenu,
        triggerActive,
        setTriggerActive,
        setIsDragging,
    } = useEditorContext();
    const editorContainerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const menuPortalRef = useRef<HTMLDivElement>(null);
    const debounceRef = useRef<NodeJS.Timeout | number>(0);
    const inputLastFocused = useRef<boolean>(false);

    const DragHandlerExtension = Extension.create({
        name: "dragHandler",

        addProseMirrorPlugins() {
            return [
                new Plugin({
                    props: {
                        handleDOMEvents: {
                            dragstart: () => {
                                setIsDragging(true);
                                return false;
                            },
                            dragend: () => {
                                setIsDragging(false);
                                return false;
                            },
                        },
                    },
                }),
            ];
        },
    });
    const createAttribute = (name: string) => ({
        [name]: {
            default: null,
            parseHTML: (element: HTMLElement) => element.getAttribute(name),
            renderHTML: (attributes: Record<string, string>) => {
                if (!attributes[name]) {
                    return {};
                }
                return {
                    [name]: attributes[name],
                };
            },
        },
    });
    const ImageExtension = Image.extend<
        ImageOptions & {
            onUpload: (file: File) => Promise<{
                url?: string;
            }>;
            singleImage?: boolean;
        }
    >({
        name: "image",
        selectable: true,
        draggable: true,

        addNodeView() {
            return ReactNodeViewRenderer(props => (
                // onUpload will always be defined, we make a check when initializing the extension
                <ImageView
                    {...(props as unknown as ImageViewProps)}
                    onUpload={isDefined(onUpload) ? onUpload : () => Promise.resolve({})}
                />
            )) as any;
        },
        addOptions() {
            return {
                ...this.parent?.(),
                // onUpload will always be defined, we make a check when initializing the extension
                onUpload: isDefined(onUpload) ? onUpload : () => Promise.resolve({}),
            };
        },
        addAttributes() {
            return {
                ...createAttribute("state"),
                ...createAttribute("src"),
                ...createAttribute("alt"),
                ...createAttribute("title"),
            };
        },

        renderText(node) {
            const src = node?.node?.attrs?.src;
            return src ?? "";
        },
    });
    const DynamicPlaceholder = Placeholder.extend({
        name: "dynamicPlaceholder",
        addCommands() {
            return {
                ...this.parent?.(),
                updatePlaceholder: (newPlaceholder: string) => () => {
                    this.options.placeholder = newPlaceholder;
                },
            };
        },
    });

    const DisableNewLines = Extension.create({
        name: "disableNewLines",
        addKeyboardShortcuts() {
            return {
                Enter: () => {
                    this.editor.commands.blur();
                    setActiveMenu("none");
                    return true;
                },
            };
        },
    });
    const NewLinesWithShiftEnter = Extension.create({
        name: "newLinesWithShiftEnter",
        addKeyboardShortcuts() {
            return {
                "Shift-Enter": () => {
                    this.editor.commands.enter();
                    return true;
                },
            };
        },
    });

    const SpeechInputHandler = Extension.create({
        name: "speechInputHandler",

        addProseMirrorPlugins() {
            return [
                new Plugin({
                    key: new PluginKey("speechInputHandler"),
                    props: {
                        handleDOMEvents: {
                            beforeinput: (_view, event) => {
                                if (event.inputType === "insertCompositionText") {
                                    event.preventDefault();
                                    setActiveMenu("none");

                                    // When speech input is activated, the first character gets duplicated.
                                    // This block prevents that by clearing the content when the first character is entered.
                                    if (this.editor.isEmpty && event.data?.length === 1) {
                                        this.editor.commands.setContent("");
                                        return true;
                                    }
                                }
                                return false;
                            },
                        },
                    },
                }),
            ];
        },
    });

    const SelectAndMoveCustomKeymap = Extension.create({
        name: "selectAndMove",

        addKeyboardShortcuts() {
            return {
                "Mod-ArrowLeft": ({ editor }) => {
                    const { state, view } = editor;
                    const { tr, selection } = state;
                    const { $from } = selection;

                    // Find the start of the paragraph
                    const startOfParagraph = $from.start($from.depth);

                    // Set the new selection to the start of the paragraph
                    const newSelection = TextSelection.near(tr.doc.resolve(startOfParagraph));
                    tr.setSelection(newSelection);
                    view.dispatch(tr);
                    return true;
                },
                "Mod-Shift-ArrowLeft": ({ editor }) => {
                    const { state, view } = editor;
                    const { tr, selection } = state;
                    const { $from } = selection;

                    // Find the start of the paragraph
                    const startOfParagraph = $from.start($from.depth);

                    // Create a text selection from the current position to the start of the paragraph
                    const newSelection = TextSelection.create(state.doc, startOfParagraph, selection.to);
                    tr.setSelection(newSelection);
                    view.dispatch(tr);
                    return true;
                },

                "Mod-ArrowRight": ({ editor }) => {
                    const { state, view } = editor;
                    const { tr, selection } = state;
                    const { $from } = selection;

                    // Find the end of the paragraph
                    const endOfParagraph = $from.end($from.depth);

                    // Set the new selection to the end of the paragraph
                    const newSelection = TextSelection.near(tr.doc.resolve(endOfParagraph));
                    tr.setSelection(newSelection);
                    view.dispatch(tr);
                    return true;
                },
                "Mod-Shift-ArrowRight": ({ editor }) => {
                    const { state, view } = editor;
                    const { tr, selection } = state;
                    const { $from } = selection;

                    // Find the end of the paragraph
                    const endOfParagraph = $from.end($from.depth);

                    // Create a text selection from the current position to the end of the paragraph
                    const newSelection = TextSelection.create(state.doc, selection.from, endOfParagraph);
                    tr.setSelection(newSelection);
                    view.dispatch(tr);
                    return true;
                },
            };
        },
    });

    const DeleteEmptySpaceAtFirstPosition = Extension.create({
        name: "deleteEmptySpaceAtFirstPosition",

        addKeyboardShortcuts() {
            return {
                Backspace: ({ editor }) => {
                    const { state, view } = editor;
                    const { selection, doc } = state;
                    const { $from } = selection;

                    if ($from.parentOffset === 1 && $from.parent.type.isTextblock) {
                        const nodeStart = $from.start();
                        const firstChar = doc.textBetween(nodeStart, nodeStart + 1);
                        const secondNode = doc.nodeAt(nodeStart + 1);

                        if (
                            (firstChar === " " || firstChar !== "") &&
                            secondNode &&
                            (secondNode.type.name === "binding" || secondNode.type.name === "image")
                        ) {
                            const tr = state.tr;
                            tr.delete(nodeStart, nodeStart + 1);
                            view.dispatch(tr);
                            return true;
                        }
                    }
                    return false;
                },
                "Mod-Backspace": ({ editor }) => {
                    const { state, view } = editor;
                    const { selection, doc } = state;
                    const { $from } = selection;

                    // Delete everything from current to start
                    const tr = state.tr;
                    tr.delete($from.start(), $from.pos);

                    // Check if there's a space in the first position and a binding in the second
                    const newStart = tr.selection.$from.start();
                    const firstChar = doc.textBetween(newStart, newStart + 1);
                    const secondNode = doc.nodeAt(newStart + 1);

                    if (
                        firstChar === " " &&
                        secondNode &&
                        (secondNode.type.name === "binding" || secondNode.type.name === "image")
                    ) {
                        tr.delete(newStart, newStart + 1);
                    }

                    view.dispatch(tr);
                    return true;
                },
                "Alt-Backspace": ({ editor }) => {
                    const { state, view } = editor;
                    const { selection, doc } = state;
                    const { $from } = selection;

                    // Find the start of the current word or binding
                    let wordStart = $from.pos;
                    while (wordStart > $from.start()) {
                        const node = doc.nodeAt(wordStart - 1);
                        if (node && (node.type.name === "binding" || node.type.name === "image")) {
                            wordStart--;
                            break;
                        }
                        if (!/\S/.test(doc.textBetween(wordStart - 1, wordStart))) {
                            break;
                        }
                        wordStart--;
                    }

                    // Delete from current position to the start of the word or binding
                    const tr = state.tr;
                    tr.delete(wordStart, $from.pos);

                    // Check if there's a space before the word/binding
                    const charBeforeWord = doc.textBetween(wordStart - 1, wordStart);

                    if (charBeforeWord === " ") {
                        tr.delete(wordStart - 1, wordStart);
                    }

                    view.dispatch(tr);
                    return true;
                },
            };
        },
    });

    const typingRef = useRef(false);
    const TypingDetector = Extension.create({
        name: "typingDetector",

        addProseMirrorPlugins() {
            const plugin = new Plugin({
                key: new PluginKey("typingDetector"),
                view: () => {
                    let timeout: NodeJS.Timeout | null = null;

                    return {
                        update: (view, prevState) => {
                            if (view.state.doc !== prevState.doc) {
                                if (timeout !== null) {
                                    clearTimeout(timeout);
                                }
                                if (view.hasFocus()) {
                                    typingRef.current = true;

                                    timeout = setTimeout(() => {
                                        typingRef.current = false;
                                    }, 500);
                                }
                            }
                        },
                    };
                },
            });

            return [plugin];
        },
    });

    const FocusHandlerExtension = Extension.create({
        name: "focusHandler",

        addProseMirrorPlugins() {
            return [
                new Plugin({
                    key: new PluginKey("focusHandler"),
                    props: {
                        handleDOMEvents: {
                            focus: (view, event) => {
                                const tr = view.state.tr;
                                inputLastFocused.current = true;

                                if (isDefined(event.relatedTarget)) {
                                    // Move cursor to end using transaction

                                    const endPos = tr.doc.content.size;
                                    tr.setSelection(TextSelection.create(tr.doc, endPos));
                                    view.dispatch(tr);
                                }

                                if (!editor?.isEmpty) {
                                    setActiveMenu("none");
                                    setTriggerActive(false);
                                    return false;
                                }

                                if (editor?.isEmpty || showMenuOnFocus) {
                                    setActiveMenu("combobox");
                                    setTriggerActive(false);
                                    return false;
                                }

                                return false;
                            },
                        },
                    },
                }),
            ];
        },
    });

    const BindingStorage = Extension.create<{}, BindingStorageExtension<T>>({
        name: "BindingStorage",
        addStorage() {
            return {
                suggestions: menuOptions,
                contextualLabel,
            };
        },
    });

    const editor = useEditor({
        editable: !disabled,
        autofocus: autoFocus,
        editorProps: {
            attributes: {
                spellcheck: "false",
            },
        },
        onBlur: ({ editor: editorBlur }) => {
            typingRef.current = false;

            if (debounceRef.current) {
                clearTimeout(debounceRef.current as number);
                const editorContent = editorBlur?.getJSON().content ?? [];
                if (hasBase64Image(editorContent)) return;
                onChange(editorContent);
            }
        },
        onUpdate: ({ transaction, editor: editorInner }) => {
            setTriggerActive(false);
            setSelectedNodePosition(null);
            const content = editorInner?.getJSON().content ?? [];
            clearTimeout(debounceRef.current as number);

            // we don't want to update the content if it contains a base64 image
            // as it might be in the process of uploading
            const containsBase64Image = hasBase64Image(content);
            if (containsBase64Image) return;

            if (activeMenu === "combobox") {
                setActiveMenu("none");
                // If the input is empty, and the user is focusing the input for the first time, we want to provide immediate feedback
                onChange(content);
                return;
            }

            const addingASingleBinding = isAddingASingleBinding(transaction);
            if (addingASingleBinding) {
                // Immediately update the content when a binding is inserted
                onChange(content);
                return;
            }

            debounceRef.current = setTimeout(() => {
                onChange(content);
            }, 500);
        },
        extensions: [
            ...(disabled
                ? []
                : [
                      BindingStorage,
                      SpeechInputHandler,
                      SingleValueInputMode,
                      TypingDetector,

                      DeleteEmptySpaceAtFirstPosition,
                      DynamicPlaceholder.configure({
                          placeholder: placeholder ?? defaultPlaceholder,
                          includeChildren: true,
                      }),
                      DragHandlerExtension,
                      SelectAndMoveCustomKeymap,
                      ...(isDefined(onUpload)
                          ? [
                                ImageExtension.configure({
                                    inline: true,
                                    onUpload,
                                    allowBase64: singleImage ? false : true,
                                    singleImage,
                                }),
                                DropPasteImage.configure({
                                    onUpload,
                                    singleImage,
                                    allowedMimeTypes: uploadOptions?.allowedMimeTypes,
                                    maxFileSize: uploadOptions?.maxFileSize,
                                }),
                            ]
                          : []),
                      ...(disableNewLines ? [DisableNewLines] : [NewLinesWithShiftEnter]),
                      ...(disableBindings || (singleImage && isDefined(onUpload))
                          ? []
                          : [SingleNodeBindingDecoration, DynamicPlaceholderDecoration]),
                      ...(singleImage && isDefined(onUpload) ? [SingleImageBindingHandlerExtension] : []),
                      FocusHandlerExtension,
                  ]),

            StarterKit.configure({
                blockquote: false,
                codeBlock: false,
                heading: false,
                bulletList: false,
                hardBreak: false,
                horizontalRule: false,
                strike: false,
                bold: false,
                italic: false,
                orderedList: false,
                listItem: false,
                code: false,
            }),

            ...(disableBindings
                ? []
                : [
                      TokenExtension.configure({
                          suggestion: buildSuggestions<T>({
                              portalRef: menuPortalRef,
                              onToggle: toggleValue => {
                                  if (toggleValue === true) {
                                      setActiveMenu("search");
                                  } else {
                                      setActiveMenu("none");
                                  }
                              },
                          }),
                          deleteTriggerWithBackspace: true,
                      }),
                  ]),
        ],
        content: isDefined(value) ? value : [],
    });

    useEffect(() => {
        // This effect updates the editor's storage whenever the menu options or contextual label change
        // The storage is used by the suggestion plugin to filter and display the correct options
        // in the token dropdown menu when a user types '@'
        if (editor?.storage.BindingStorage !== undefined) {
            editor.storage.BindingStorage.suggestions = menuOptions;
            editor.storage.BindingStorage.contextualLabel = contextualLabel;
        }
    }, [menuOptions, editor, contextualLabel]);

    useImperativeHandle(
        ref,
        (): EditorComponentRef => ({
            submit() {
                if (!editor) return false;

                const containsBase64Image = hasBase64Image(editor.getJSON().content ?? []);

                // don't allow submit if still has image processing
                if (containsBase64Image) {
                    return false;
                }

                // Capture the text.
                const text = editor.getText();

                // Clear the editor content.
                editor.commands.setContent([
                    {
                        type: "text",
                        text: "",
                    },
                ]);

                // Fix the focus.
                editor.commands.focus("end");

                return text;
            },
        }),
        [editor]
    );

    const clearState = useCallback(() => {
        setActiveMenu("none");
        setSelectedNodePosition(null);
        setTriggerActive(false);
        inputLastFocused.current = false;

        // clear editor's selection
        editor?.commands.setTextSelection({
            from: 0,
            to: 0,
        });
    }, [setSelectedNodePosition, setActiveMenu, editor?.commands, setTriggerActive]);

    const onEditorContentKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Tab") {
                clearState();
                return;
            }

            if (event.key === "Escape") {
                editor?.commands.blur();
                clearState();
                event.preventDefault();

                return;
            }

            if (event.key === "ArrowDown") {
                if (activeMenu === "combobox") {
                    if (editor?.isFocused) {
                        const menuItem = document.querySelector("[role=menuitem]");
                        if (menuItem instanceof HTMLElement) {
                            menuItem.focus();
                        }
                        return;
                    }
                }

                if (!disableNewLines) {
                    return;
                }
                event.preventDefault();

                if (editor?.isFocused) {
                    setActiveMenu("combobox");
                    setSelectedNodePosition(null);
                    const menuItem = document.querySelector("[role=menuitem]");
                    if (menuItem instanceof HTMLElement) {
                        menuItem.focus();
                    }
                    return;
                }
            }
        },
        [editor, clearState, setActiveMenu, setSelectedNodePosition, disableNewLines, activeMenu]
    );

    const focusEditor = useCallback(() => {
        editor?.commands.focus("end");
    }, [editor?.commands]);

    const selectEditorContent = useCallback(() => {
        editor?.commands.selectAll();
    }, [editor?.commands]);

    useEffect(() => {
        if (disabled) {
            return;
        }
        const handleKeyDown = (event: KeyboardEvent) => {
            if (isDefined(onKeyDown) && isDefined(editor)) {
                onKeyDown(event, editor);
            }
            if (event.key === "Tab") {
                clearState();
            }

            if (event.key === "Escape" && activeMenu === "combobox") {
                event.preventDefault();
                clearState();
                focusEditor();
                return;
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [clearState, onKeyDown, editor, disabled, activeMenu, focusEditor]);

    const currentEditor = useRef(editor);
    currentEditor.current = editor;

    useEffect(() => {
        if (disabled) {
            return;
        }
        if (activeMenu === "combobox" || activeMenu === "search") {
            currentEditor.current?.commands?.updatePlaceholder(focusPlaceholder ?? defaultPlaceholder);
        } else {
            currentEditor.current?.commands?.updatePlaceholder(placeholder ?? defaultPlaceholder);
        }
    }, [placeholder, activeMenu, focusPlaceholder, disabled]);

    useClickOutsideEditor(editorContainerRef, clearState);

    const isDarkTheme = useIsDarkTheme();

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileUploadClick = () => fileInputRef.current?.click();

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isDefined(onUpload) || !isDefined(editor)) {
            return;
        }
        return handleImageEvent({
            view: editor.view,
            event,
            onUpload,
            singleImage,
            allowedMimeTypes: uploadOptions?.allowedMimeTypes ?? defaultAllowedMimeTypes,
            maxFileSize: uploadOptions?.maxFileSize ?? 20 * 1024 * 1024,
        });
    };

    useEffect(() => {
        if (isDefined(editor) && isDefined(value) && typingRef.current === false && !editor.isFocused) {
            const currentContent = editor.getJSON();
            if (!deepEqual(currentContent, value)) {
                // if user is typing, we don't want to update the content
                editor.commands.setContent(value, false);
            }
        }
    }, [editor, value]);

    if (!isDefined(editor)) {
        return <div tw="w-full min-h-[32px] bg-bg-middle rounded-lg" />;
    }

    const isEditorFocused = editor?.isFocused || activeMenu === "combobox";

    const isNodeSelected = isDefined(selectedNodePosition);

    const showMenu = activeMenu === "combobox" && !disabled;

    const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (disableBindings) {
            event.preventDefault();
            event.stopPropagation();
            focusEditor();
            return;
        }

        if (activeMenu === "combobox") {
            clearState();
            editor.commands.blur();
            setTriggerActive(false);
            return;
        }

        if (inputLastFocused.current) {
            event.preventDefault();
            event.stopPropagation();
            setActiveMenu("combobox");
            setTriggerActive(true);
            return;
        }

        if (activeMenu === "none") {
            event.preventDefault();
            event.stopPropagation();
            selectEditorContent();

            if (!editor.isFocused) {
                setActiveMenu("combobox");
            } else {
                setActiveMenu("combobox");
            }

            setTriggerActive(true);
            return;
        }

        if (activeMenu !== "search" && !showMenuOnFocus) {
            setActiveMenu("search");
            return;
        }
    };

    const isUploadDefined = isDefined(onUpload) && editor?.options.editable;

    return (
        <>
            <div className={"flex relative w-full"}>
                <div
                    data-is-active={isEditorFocused}
                    data-disable-top-border={disableTopBorder}
                    onClick={isEditorFocused ? undefined : focusEditor}
                    className={className}
                    data-is-dark-theme={isDarkTheme}
                    data-is-highlighted={highlight}
                    tw="relative z-10 w-full rounded-lg border border-solid transition !min-h-[32px]
                     focus-within:bg-n0 focus-within:transition-none focus-within:hover:bg-n0
                    text-builder-base text-text-dark
                    focus-within:border-aqua400 focus-within:hover:border-aqua400
                    data-[is-active=true]:border-aqua400 data-[is-active=true]:hover:border-aqua400
                    hover:border-border-dark hover:bg-n100A border-n50A bg-n200A
                    data-[is-active=true]:bg-n0 data-[is-active=true]:transition-none data-[is-active=true]:hover:bg-n0
                    data-[is-active=true]:data-[is-dark-theme=true]:bg-bg-front
                    data-[is-active=true]:data-[is-dark-theme=true]:hover:bg-bg-front
                    focus-within:data-[is-dark-theme=true]:bg-bg-front
                    data-[disable-top-border=true]:border-t-0 data-[disable-top-border=true]:rounded-t-none
                    data-[is-highlighted=true]:!outline data-[is-highlighted=true]:!outline-1 data-[is-highlighted=true]:!outline-b300 data-[is-highlighted=true]:hover:!outline-b300
                     "
                    ref={editorContainerRef}>
                    <div tw="absolute right-0.5 top-0.5 z-10">
                        {isUploadDefined && (
                            <>
                                <button
                                    disabled={disabled}
                                    onClick={handleFileUploadClick}
                                    tabIndex={-1}
                                    type="button"
                                    tw="rounded-md px-2 py-[5px] backdrop-blur-md transition hover:bg-n200A text-icon-base hover:text-icon-dark mr-1">
                                    <GlideIcon icon="st-image" iconSize={16} kind="stroke" />
                                </button>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    tw="hidden"
                                    value={undefined}
                                />
                            </>
                        )}

                        {!hideDropdown && (
                            <button
                                ref={buttonRef}
                                disabled={disabled}
                                data-is-focused={triggerActive}
                                onClick={onClickButton}
                                tabIndex={-1}
                                type="button"
                                tw=" rounded-md px-2 py-[5px] backdrop-blur-md transition before:absolute before:inset-y-1
            before:left-0 before:border-r  before:transition before:content-[''] hover:before:border-transparent before:border-border-pale hover:bg-n200A text-icon-base hover:text-icon-dark
             data-[is-focused=true]:bg-n200A data-[is-focused=true]:text-icon-dark data-[is-focused=true]:hover:bg-n200A data-[is-focused=true]:hover:text-icon-dark data-[is-focused=true]:before:border-transparent
             data-[is-focused=true]:transition-none data-[is-focused=true]:before:transition-none
            ">
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <title>Toggle Dropdown</title>
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10.25 4.5C10.25 5.19036 10.8096 5.75 11.5 5.75C12.1904 5.75 12.75 5.19036 12.75 4.5C12.75 3.80964 12.1904 3.25 11.5 3.25C10.8096 3.25 10.25 3.80964 10.25 4.5ZM3.25 11.5C3.25 12.1904 3.80964 12.75 4.5 12.75C5.19036 12.75 5.75 12.1904 5.75 11.5C5.75 10.8096 5.19036 10.25 4.5 10.25C3.80964 10.25 3.25 10.8096 3.25 11.5ZM11.5 12.7499C10.8096 12.7499 10.25 12.1903 10.25 11.4999C10.25 10.8096 10.8096 10.2499 11.5 10.2499C12.1904 10.2499 12.75 10.8096 12.75 11.4999C12.75 12.1903 12.1904 12.7499 11.5 12.7499ZM3.25 4.5C3.25 5.19036 3.80964 5.75 4.5 5.75C5.19036 5.75 5.75 5.19036 5.75 4.5C5.75 3.80964 5.19036 3.25 4.5 3.25C3.80964 3.25 3.25 3.80964 3.25 4.5Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </button>
                        )}
                    </div>

                    <EditorContent
                        spellCheck={false}
                        id="editor"
                        data-testid="editor"
                        tw={"h-full leading-5 cursor-text"}
                        onKeyDown={onEditorContentKeyDown}
                        editor={editor}
                    />
                </div>

                {disableBindings || menuOptions.length === 0 ? null : (
                    <MenuCombobox<T>
                        open={showMenu || (isNodeSelected && !disabled)}
                        editor={editor}
                        anchorRef={menuPortalRef}
                        options={menuOptions}
                        contextualLabel={contextualLabel}
                        clearValueOption={isDefined(onUpload) && singleImage}
                        onUploadOption={isDefined(onUpload)}
                        handleFileUploadClick={handleFileUploadClick}
                    />
                )}
            </div>
            <div id="editor-portal" tw="absolute inset-0" ref={menuPortalRef}></div>
        </>
    );
};

const EditorComponent = forwardRef(EditorComponentBase);

const SingleValueInputMode = Extension.create({
    name: "SingleValueInputMode",

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey("SpecialValueInputMode"),
                appendTransaction: (transactions, _oldState, newState) => {
                    const docChanged = transactions.some(tr => tr.docChanged);
                    if (!docChanged) return null;

                    const { tr } = newState;
                    let modified = false;

                    newState.doc.descendants((node, pos) => {
                        if (node.type.name === "binding" && node.attrs.singleValue === true) {
                            const $pos = newState.doc.resolve(pos);
                            const parent = $pos.parent;

                            // Ensure the special value node is the only content in the document
                            if (newState.doc.content.childCount > 1 || parent !== newState.doc) {
                                tr.delete(0, newState.doc.content.size);
                                tr.replaceWith(0, tr.doc.content.size, node);
                                modified = true;
                            }

                            return false; // Stop traversing
                        }
                        return true;
                    });

                    return modified ? tr : null;
                },
                filterTransaction: (tr, state) => {
                    if (!tr.docChanged) return true;

                    let allowTransaction = true;
                    state.doc.descendants((node, pos) => {
                        if (node.type.name === "binding" && node.attrs.singleValue === true) {
                            // Allow '@' character but prevent spaces
                            const newText = tr.doc.textBetween(pos, tr.doc.nodeSize - 2);
                            if (newText.includes(" ")) {
                                allowTransaction = false;
                            }
                            return false; // Stop traversing
                        }
                        return true;
                    });

                    return allowTransaction;
                },
            }),
        ];
    },
});

/**
 * DynamicPlaceholderDecoration adds a dynamic placeholder functionality.
 * Key features:
 * 1. Creates a custom decoration to display placeholder text.
 * 2. Triggers when the user types '@' to initiate a column reference.
 * 3. Displays "@Column" as a visual cue at the cursor position.

 * Behavior:
 * - Activates only when there's sufficient space to display the placeholder.
 * - Considers the editor's focus state and cursor position.
 * - Dynamically updates as the user types or moves the cursor.
 */

const DynamicPlaceholderDecoration = Extension.create({
    name: "combinedDecoration",

    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    decorations: state => {
                        if (!this.editor.isFocused) return;

                        const { doc, selection } = state;
                        const decorations: Decoration[] = [];

                        let hasEnoughSpace = false;

                        if (!doc.textContent.includes("@") || doc.textContent.trim().length === 0) {
                            return DecorationSet.empty;
                        }

                        doc.descendants((node, pos) => {
                            if (node.type.name === "text" && node.text) {
                                const regex = /(?:^|\s)@(?!.)/g;
                                let match;

                                while ((match = regex.exec(node.text)) !== null) {
                                    const from = pos + match.index + (match[0].startsWith(" ") ? 1 : 0);
                                    const to = from + 1;

                                    // Check if there's a binding node in the next position
                                    let hasBindingNodeNearby = false;

                                    const nextNode = doc.nodeAt(to);
                                    if (
                                        (nextNode &&
                                            nextNode.type.name === "binding" &&
                                            node.attrs.singleValue !== true) ||
                                        (nextNode && nextNode.type.name === "image")
                                    ) {
                                        hasBindingNodeNearby = true;
                                        break;
                                    }

                                    if (!hasBindingNodeNearby && node.text[match.index + match[0].length] !== " ") {
                                        if (this.editor.view.dom instanceof HTMLElement) {
                                            const editorRect = this.editor.view.dom.getBoundingClientRect();

                                            // @ts-expect-error $cursor exists but not in types
                                            const cursorPos = state.selection.$cursor?.pos ?? 0;
                                            const lineEnd = this.editor.state.doc.resolve(cursorPos).end();
                                            const endCoords = this.editor.view.coordsAtPos(lineEnd);

                                            const offset = 50;
                                            const remainingSpace = editorRect.right - (endCoords.left + offset);

                                            hasEnoughSpace = remainingSpace > 22;
                                        }

                                        if (hasEnoughSpace) {
                                            decorations.push(
                                                Decoration.inline(from, to, {
                                                    class: "dynamic-placeholder",
                                                })
                                            );
                                        }
                                    }
                                }
                            }
                        });

                        // Measure Space Decoration logic
                        // @ts-expect-error $cursor exists but not in types
                        const cursorPos = selection.$cursor ? selection.$cursor.pos : null;

                        if (cursorPos !== null) {
                            decorations.push(
                                Decoration.widget(
                                    cursorPos,
                                    () => {
                                        const span = document.createElement("span");
                                        span.textContent = "@Measure";
                                        span.style.visibility = "hidden";
                                        span.className = "measure-widget";
                                        span.style.position = "absolute";
                                        span.style.pointerEvents = "none";
                                        return span;
                                    },
                                    { side: 1 }
                                )
                            );
                        }

                        return DecorationSet.create(doc, decorations);
                    },
                },
            }),
        ];
    },
});

/**
 * SingleNodeBindingDecoration Extension
 *
 * This extension adds a decoration to a single binding node in the editor when certain conditions are met.
 *
 * Functionality:
 * 1. It only applies when the editor is focused.
 * 2. It checks if there's exactly one binding node in the document and no other non-space text nodes.
 * 3. It ensures there's enough space (>100px) between the binding node and the end of the input.
 * 4. If all conditions are met, it adds a 'single-node-binding' class to the node.
 *
 * Use case:
 * This extension is used to provide visual feedback -> @Column or text when a single binding is present
 */
const SingleNodeBindingDecoration = Extension.create({
    name: "singleNodeBindingDecoration",
    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    decorations: state => {
                        if (!this.editor.isFocused) return;
                        const { doc } = state;
                        const decorations: Decoration[] = [];

                        let nodeBindingCount = 0;
                        let singleNodeBindingPos: number | null = null;
                        let hasOtherNodes = false;
                        let hasEnoughSpace = true;

                        // Check if there's a selection
                        if (state.selection.from !== state.selection.to) {
                            return DecorationSet.empty;
                        }

                        doc.descendants((node, pos) => {
                            if (node.type.name === "binding" || node.type.name === "image") {
                                if (node.type.name === "binding" && node.attrs.singleValue === true) {
                                    return; // Skip this node
                                }
                                nodeBindingCount++;
                                singleNodeBindingPos = pos;
                            } else if (node.type.name === "text" && node.text && node.text !== " ") {
                                hasOtherNodes = true;
                            }
                        });

                        if (nodeBindingCount === 1 && singleNodeBindingPos !== null && !hasOtherNodes) {
                            // Check if there's enough space (>120px) between the node and the end of the input
                            if (this.editor.view.dom instanceof HTMLElement) {
                                const editorWidth = this.editor.view.dom.offsetWidth;
                                const nodeWidth = this.editor.view.dom.querySelector(".node-binding")?.clientWidth ?? 0;
                                hasEnoughSpace = editorWidth - nodeWidth > 120;
                            }

                            if (hasEnoughSpace) {
                                decorations.push(
                                    Decoration.node(singleNodeBindingPos, singleNodeBindingPos + 1, {
                                        class: "single-node-binding",
                                    })
                                );
                            }
                        }

                        return DecorationSet.create(doc, decorations);
                    },
                },
            }),
        ];
    },
});

declare module "@tiptap/react" {
    interface Commands {
        dynamicPlaceholder: {
            updatePlaceholder: (newPlaceholder: string) => () => void;
        };
        dropPasteImage: {};
    }
}

// runtime check for transaction.steps[0]?.slice?.content?.content?.[0]?.type?.name
const isAddingASingleBinding = (tx: unknown): boolean => {
    if (!hasOwnProperty(tx, "steps")) return false;
    const steps = tx.steps;
    if (!Array.isArray(steps)) return false;
    const firstStep = steps[0];
    if (!hasOwnProperty(firstStep, "slice")) return false;
    const slice = firstStep.slice;
    if (!hasOwnProperty(slice, "content")) return false;
    const sliceContent = slice.content;
    if (!hasOwnProperty(sliceContent, "content")) return false;
    const contentArray = sliceContent.content;
    if (!Array.isArray(contentArray)) return false;
    const firstContent = contentArray[0];
    if (!hasOwnProperty(firstContent, "type")) return false;
    const type = firstContent.type;
    if (!hasOwnProperty(type, "name")) return false;
    return type.name === "binding";
};
