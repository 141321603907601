import { type GlideFC, GlideIcon, isTwotoneIcon, isColorIcon } from "@glide/common";
import type { AppDescription } from "@glide/app-description";
import {
    areTableNamesEqual,
    type TableGlideType,
    getTableName,
    type ExternalSource,
    type SourceMetadata,
    isBigTableOrQueryableExternalSourceMetadata,
} from "@glide/type-schema";
import { getSourceMetadataForTable } from "@glide/common-core/dist/js/components/SerializedApp";
import { getUserProfileTableInfo } from "@glide/generator/dist/js/user-profile-info";
import { assertNever, definedMap } from "@glideapps/ts-necessities";
import type { ExternalDataSourceType } from "@glide/common-core";
import { logError } from "@glide/support";
import type { BuilderTheme } from "@glide/theme";
import { useBuilderTheme } from "../../hooks/use-builder-theme";

interface SheetIconProps {
    readonly externalSource: ExternalSource;
    readonly theme: BuilderTheme;
    readonly iconSize: number;
}

const SheetIcon: React.VFC<SheetIconProps> = ({ externalSource, theme, iconSize }) => {
    const sourceType = externalSource.type;
    switch (sourceType) {
        case "excel-online":
            return (
                <GlideIcon
                    kind="twotone"
                    icon={"tt-excel-sheet"}
                    iconSize={iconSize}
                    primaryColor={"#00622A"}
                    accentColor={"#009650"}
                />
            );
        case "airtable":
            return (
                <GlideIcon kind="monotone" icon={"mt-airtable-sheet-small"} iconSize={iconSize} fgColor={theme.n700} />
            );
        case "bigquery":
            return (
                <GlideIcon
                    kind="monotone"
                    icon="mt-bigquery"
                    iconSize={iconSize}
                    fgColor={theme.n800}
                    hideStroke={true}
                    borderRadiusWithoutStroke={true}
                />
            );
        case "data-plugin":
        case "mysql-gcp":
        // TODO confirm w design about data source icons from plugins,
        // this isn't really a problem though, the current assets match the Figma correctly
        case "queryable-plugin":
            return (
                <GlideIcon
                    kind="monotone"
                    icon="mt-sql-table"
                    iconSize={iconSize}
                    hideStroke={true}
                    borderRadiusWithoutStroke={true}
                />
            );
        default:
            return (
                <GlideIcon
                    kind="twotone"
                    icon={"tt-excel-sheet"}
                    iconSize={iconSize}
                    primaryColor={"#00622A"}
                    accentColor={"#009650"}
                />
            );
    }
};

interface PropsBase {
    readonly sourceMetadataType: SourceMetadata["type"];
    // `externalSource` takes precedence over `externalSourceType`
    readonly externalSource: ExternalSource | undefined;
    readonly externalSourceType: ExternalDataSourceType | undefined;
    readonly iconSize: number;
}

interface Props extends PropsBase {
    readonly isUserProfileTable: boolean;
    readonly isQueryable: boolean;
}

export const TableIcon: GlideFC<Props> = p => {
    const { isUserProfileTable, externalSource, externalSourceType, sourceMetadataType, iconSize, isQueryable } = p;
    const theme = useBuilderTheme();

    if (isUserProfileTable) {
        return (
            <UserProfileTableIcon
                externalSource={externalSource}
                externalSourceType={externalSourceType}
                sourceMetadataType={sourceMetadataType}
                iconSize={iconSize}
            />
        );
    }

    if (sourceMetadataType === "Native table") {
        if (externalSource?.type === undefined) {
            return (
                <GlideIcon
                    kind="twotone"
                    icon={isQueryable ? "tt-glide-big-table" : "tt-glide-table"}
                    iconSize={iconSize}
                    primaryColor={theme.n800}
                    accentColor={theme.n800}
                    className="icon-glide-table"
                />
            );
        } else {
            return <SheetIcon externalSource={externalSource} theme={theme} iconSize={iconSize} />;
        }
    } else if (sourceMetadataType === "Google Sheet") {
        return (
            <GlideIcon
                className="image"
                kind="twotone"
                icon="tt-google-sheet"
                iconSize={iconSize}
                primaryColor={"#0F9D58"}
                accentColor={theme.g400}
            />
        );
    } else {
        assertNever(sourceMetadataType);
    }
};

interface TableIconFromSourceMetadataProps {
    readonly sourceMetadata: SourceMetadata | undefined;
    readonly isUserProfileTable: boolean;
    readonly iconSize: number;
}

const TableIconFromSourceMetadata: GlideFC<TableIconFromSourceMetadataProps> = ({
    sourceMetadata,
    isUserProfileTable,
    iconSize,
}) => {
    return (
        <TableIcon
            sourceMetadataType={sourceMetadata?.type ?? "Native table"}
            isUserProfileTable={isUserProfileTable}
            externalSource={sourceMetadata?.type === "Native table" ? sourceMetadata.externalSource : undefined}
            externalSourceType={undefined}
            isQueryable={
                sourceMetadata?.type === "Native table" && isBigTableOrQueryableExternalSourceMetadata(sourceMetadata)
            }
            iconSize={iconSize}
        />
    );
};

interface TableIconFromAppProps {
    readonly app: AppDescription | undefined;
    readonly table: TableGlideType;
    readonly iconSize: number;
}

export const TableIconFromApp: React.VFC<TableIconFromAppProps> = ({ app, table, iconSize }) => {
    const sm = getSourceMetadataForTable(app, table);
    const userProfileTableInfo = definedMap(app, getUserProfileTableInfo);
    const isUserProfileTable = areTableNamesEqual(userProfileTableInfo?.tableName, getTableName(table));
    return (
        <TableIconFromSourceMetadata sourceMetadata={sm} isUserProfileTable={isUserProfileTable} iconSize={iconSize} />
    );
};

const UserProfileTableIcon: GlideFC<PropsBase> = p => {
    const { externalSource, externalSourceType, sourceMetadataType, iconSize } = p;
    const sourceType = externalSource?.type ?? externalSourceType;
    const theme = useBuilderTheme();

    let primaryColor: string;
    if (sourceMetadataType === "Native table") {
        if (
            sourceType === undefined ||
            sourceType === "unknown" || //TODO
            sourceType === "mysql-gcp" || //TODO
            sourceType === "queryable-plugin" || // TODO
            sourceType === "data-plugin" // TODO
        ) {
            primaryColor = theme.n800;
        } else if (sourceType === "excel-online") {
            primaryColor = "#009650";
        } else if (sourceType === "airtable") {
            primaryColor = theme.n700;
        } else if (sourceType === "bigquery") {
            primaryColor = "#1B448A";
        } else {
            assertNever(sourceType);
        }
    } else if (sourceMetadataType === "Google Sheet") {
        primaryColor = theme.g400;
    } else {
        assertNever(sourceMetadataType);
    }

    return (
        <GlideIcon
            kind="twotone"
            icon="tt-user-table"
            iconSize={iconSize}
            primaryColor={primaryColor}
            accentColor={theme.aqua400}
            className="icon-glide-table"
        />
    );
};

type IconProps = {
    className?: string;
    size?: number;
    icon: string;
};

export const DataSourceIcon: React.FC<React.PropsWithChildren<IconProps>> = p => {
    const { className, icon, size = 24 } = p;
    const theme = useBuilderTheme();
    const prefixedTwoToneIcon = `tt-${icon}`;

    // big tables is the only twotone icon (for now)
    // we may want to evaluate other new color icons and dark mode
    // mysql is a bit dark.
    if (isTwotoneIcon(prefixedTwoToneIcon) && prefixedTwoToneIcon === "tt-glide-big-tables") {
        return (
            <GlideIcon
                kind="twotone"
                icon={prefixedTwoToneIcon}
                iconSize={size}
                primaryColor={theme.n800}
                accentColor={theme.n800}
            />
        );
    }

    const prefixedColorIcon = `clr-${icon}`;

    // For now, we can't inline the sql server SVG graphic because it
    // uses gradient mapping techniques our GlideIcon inlining seems to
    // smash up. We can revisit later. Also... excel online needs some
    // work as its green "flag" part of the graphic overflows the view
    // box
    if (prefixedColorIcon === "clr-sqlserver" || prefixedColorIcon === "clr-excel") {
        return (
            <div className={className}>
                <img alt={`sql server`} src={`/svg/color/clr-${icon}.svg`} tw="object-contain" width={size} />
            </div>
        );
    }

    if (isColorIcon(prefixedColorIcon)) {
        return (
            <div className={className}>
                <GlideIcon kind="color" icon={prefixedColorIcon} iconSize={size} />
            </div>
        );
    }
    logError(`Invalid icon passed to DataSourceIcon: ${icon}`);
    return null;
};
