import type { AppKind } from "@glide/location-common";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import * as routes from "@glide/common-core/dist/js/routes";
import { isEmptyOrUndefined } from "@glide/support";

export function localhostURL(appID: string): string | undefined {
    const { hostname } = window.location;
    if (hostname !== "localhost") return undefined;
    return `${window.location.origin}${routes.play(appID)}`;
}

function playLinkForLocation(appID: string) {
    return `${getLocationSettings().urlPrefix}play/${appID}`;
}

function isValidShortNameForLocation(appKind: AppKind, shortName: string): boolean {
    return shortName.endsWith(getLocationSettings(appKind).shortnameSuffix);
}

export function urlForApp(
    appID: string,
    appKind: AppKind,
    app: { readonly shortName: string | undefined; readonly customDomain: string | undefined } | undefined
): string {
    const maybeURL = localhostURL(appID);
    if (maybeURL !== undefined) {
        return maybeURL;
    }

    if (app !== undefined) {
        const locationSettings = getLocationSettings();
        if (!isEmptyOrUndefined(app.customDomain)) {
            return locationSettings.alwaysUsePlayURLs
                ? playLinkForLocation(app.customDomain)
                : "https://" + app.customDomain;
        }
        if (!isEmptyOrUndefined(app.shortName) && isValidShortNameForLocation(appKind, app.shortName)) {
            return locationSettings.alwaysUsePlayURLs ? playLinkForLocation(app.shortName) : "https://" + app.shortName;
        }
    }

    return playLinkForLocation(appID);
}
