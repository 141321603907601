import classNames from "classnames";
import { AnimatePresence, LazyMotion, domMax, m } from "framer-motion";
import { useEffect, useRef } from "react";
import "twin.macro";
import type { WindowProps } from "../windowed-modal/windowed-modal-next";
import { Modal } from "../windowed-modal/windowed-modal-next";

export interface ToastProps extends WindowProps {
    onClose?: () => void;
    position: "bottom-right" | "bottom-left" | "bottom-center";
    show: boolean;
}

const noticationsHotkey = "F8";

/**
 * Wraps the modal window and creates a dialog instance for a11y labels
 */
export const Message: React.FC<React.PropsWithChildren<ToastProps>> = p => {
    const { children, className, size, show, position = "bottom-left", onClose } = p;

    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.code === noticationsHotkey) ref.current?.focus();
            if (event.key === "Escape" && onClose !== undefined) {
                onClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [onClose]);

    return (
        <section
            ref={ref}
            tabIndex={-1}
            aria-label={`Notifications ${noticationsHotkey}`}
            tw="fixed inset-0 pointer-events-none z-toast">
            <LazyMotion features={domMax}>
                <AnimatePresence>
                    {show && (
                        <m.div
                            aria-live="polite"
                            aria-atomic="true"
                            role="status"
                            transition={{
                                type: "spring",
                                mass: 1,
                                stiffness: 600,
                                damping: 40,
                            }}
                            initial={{ translateY: "150%" }}
                            animate={{ translateY: 0 }}
                            exit={{ translateY: "150%" }}
                            tabIndex={0}
                            className={classNames(className, size, position)}
                            tw="absolute flex items-center justify-center gap-2 py-3 px-4 bg-bg-front shadow-xl-dark rounded-xl overflow-hidden pointer-events-auto [&>button]:flex-shrink-0 [&>button]:flex-grow-0
                                [&.xs]:(max-w-xs)
                                [&.sm]:(max-w-lg)
                                [&.md]:(max-w-xl)
                                [&.lg]:(max-w-4xl)
                                [&.bottom-left]:(bottom-3 left-3)
                                [&.bottom-right]:(bottom-3 right-3)
                                [&.bottom-center]:(bottom-3 inset-x-0 mx-auto)
                                [&.xl]:(w-[95vw])">
                            {children}
                        </m.div>
                    )}
                </AnimatePresence>
            </LazyMotion>
        </section>
    );
};

export const Title: React.FC<React.PropsWithChildren<{ className?: string }>> = p => {
    const { children, className } = p;

    return (
        <h1 tw="flex-1 font-semibold leading-tight text-builder-2xl text-text-dark" className={className}>
            {children}
        </h1>
    );
};

export const Content: React.FC<React.PropsWithChildren<{ className?: string }>> = p => {
    const { children, className } = p;

    return (
        <div tw="w-full mr-0" className={className}>
            {children}
        </div>
    );
};

export const Toast = {
    Message,
    Title,
    Content,
    TitleBar: Modal.TitleBar,
    Close: Modal.Close,
    ButtonBar: Modal.ButtonBar,
    Button: Modal.Button,
} as const;

Toast.Message.displayName = "Toast.Message";
Toast.Title.displayName = "Toast.Title";
Toast.TitleBar.displayName = "Toast.TitleBar";
Toast.Content.displayName = "Toast.Content";
Toast.Close.displayName = "Toast.Close";
Toast.ButtonBar.displayName = "Toast.ButtonBar";
Toast.Button.displayName = "Toast.Button";
