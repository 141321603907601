import { type RuntimeTheme, useIsOSThemeDark } from "@glide/common";
import { defined } from "@glideapps/ts-necessities";
import { styled } from "@glide/common-components";
import { useRuntimeTheme } from "../../../../hooks/use-runtime-theme";
import { useBuilderScreenshooter } from "../../../../hooks/use-builder-screenshooter";

// Surely there is a better way built into JS

const HomeBarDrawing = (props: { theme: RuntimeTheme }) => {
    const width = props.theme.builderWidth;
    const height = props.theme.homeBar.height;
    const translate = `translate(${(width - 375) / 2}, ${height - 34})`;
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                transform={translate}
                d="M120 23.5C120 22.1193 121.119 21 122.5 21H252.5C253.881 21 255 22.1193 255 23.5C255 24.8807 253.881 26 252.5 26H122.5C121.119 26 120 24.8807 120 23.5Z"
            />
        </svg>
    );
};

const AndroidHomeBarDrawing = () => (
    <svg width="302" height="48" viewBox="0 0 302 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M245 18C245 17.4477 245.448 17 246 17H258C258.552 17 259 17.4477 259 18V30C259 30.5523 258.552 31 258 31H246C245.448 31 245 30.5523 245 30V18Z"
            stroke="white"
            strokeWidth="2"
        />
        <path
            d="M158 24C158 27.866 154.866 31 151 31C147.134 31 144 27.866 144 24C144 20.134 147.134 17 151 17C154.866 17 158 20.134 158 24Z"
            stroke="white"
            strokeWidth="2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.999 15.9832C56.3324 15.2124 58 16.1746 58 17.7147V29.2872C58 30.8273 56.3324 31.7895 54.999 31.0187L44.9899 25.2324C43.6579 24.4624 43.6579 22.5395 44.9899 21.7695L54.999 15.9832Z"
            stroke="white"
            strokeWidth="2"
        />
    </svg>
);

const HomeBarContainer = styled.div<{ drawBackground: boolean; isOSThemeDark?: boolean }>`
    background-color: ${p => (p.drawBackground ? p.theme.primaryOverlayColor : "transparent")};
    z-index: 40;
    .builder & {
        position: absolute;
    }
    .player & {
        position: fixed;
    }
    left: 0;
    bottom: 0;
    right: 0;
    height: ${p => p.theme.homeBar.height}px;
    overflow: hidden;

    svg {
        fill: ${p =>
            (p.isOSThemeDark && p.theme.pageEnvironment === "Auto") || p.theme.pageEnvironment === "Dark"
                ? "#FFFFFF"
                : "#333"};
    }
`;

const AndroidHomeBarContainer = styled(HomeBarContainer)`
    background-color: black;
    display: flex;
    justify-content: center;

    z-index: 45;
`;

interface Props extends React.PropsWithChildren {
    isSplash: boolean;
}

const HomeBar: React.FC<Props> = () => {
    const theme = useRuntimeTheme();
    const isOSThemeDark = useIsOSThemeDark();
    const isScreenshooting = useBuilderScreenshooter();

    if (isScreenshooting) {
        return null;
    }

    if (defined(theme).navBar.style === "iOS") {
        return (
            <HomeBarContainer drawBackground={false} isOSThemeDark={isOSThemeDark}>
                <HomeBarDrawing theme={theme} />
            </HomeBarContainer>
        );
    } else {
        return (
            <AndroidHomeBarContainer drawBackground={true}>
                <AndroidHomeBarDrawing />
            </AndroidHomeBarContainer>
        );
    }
};

export default HomeBar;
