import { extractIframe } from "@glide/common-core/dist/js/components/extract-iframe";
import { ImageAspectRatio } from "@glide/common-core/dist/js/components/image-types";
import { fetchPageTitle } from "@glide/common-core/dist/js/components/recognize-link";
import type { ComponentDescription, MutatingScreenKind, PropertyDescription } from "@glide/app-description";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireAppWebViewComponent } from "@glide/fluent-components/dist/js/base-components";
import type { WireWebViewDescription } from "@glide/fluent-components/dist/js/fluent-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    EnumPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type PluginTierList, makeTierList } from "@glide/plugins";
import { isUrl } from "@glide/support";
import { type WireInflationBackend, type WireRowComponentHydratorConstructor, WireComponentKind } from "@glide/wire";

import {
    hydrateAsyncComputation,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import { ComponentHandlerBase } from "./handler";
import { linkPropertyDescriptor } from "./link";

const ComponentKindWebview = "webview";

interface WebviewDescription extends ComponentDescription {
    readonly propertyName: PropertyDescription;
    readonly aspectRatio: PropertyDescription;
    readonly allowScrolling: PropertyDescription;
}

const allowScrollingPropertyHandler = new SwitchPropertyHandler(
    { allowScrolling: true },
    "Allow scrolling",
    PropertySection.Design
);

const aspectRatioPropertyHandler = new EnumPropertyHandler(
    { aspectRatio: ImageAspectRatio.Fit },
    "Size",
    "Size",
    [
        {
            value: ImageAspectRatio.ThreeByOne,
            label: "Small",
            icon: "border3by1",
        },
        {
            value: ImageAspectRatio.ThreeByTwo,
            label: "Medium",
            icon: "border3by2",
        },
        {
            value: ImageAspectRatio.Square,
            label: "Square",
            icon: "borderSquare",
        },
        {
            value: ImageAspectRatio.ThreeByFour,
            label: "Large",
            icon: "border3by4",
        },
        { value: ImageAspectRatio.Fit, label: "Filling", icon: "borderOriginal" },
        { value: ImageAspectRatio.Expand, label: "Expand", icon: "borderOriginal" },
    ],
    PropertySection.Design,
    "small-images"
);

export class WebviewComponentHandler extends ComponentHandlerBase<WebviewDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindWebview);
    }

    public getTier(): PluginTierList | undefined {
        return makeTierList("pro");
    }

    public getDescriptor(
        _desc: WebviewDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Web Embed",
            description: "A view that embeds other web assets",
            img: "co-web-view",
            group: "Media",
            helpUrl: getDocURL("webview"),
            properties: [
                linkPropertyDescriptor("propertyName", mutatingScreenKind, false),
                allowScrollingPropertyHandler,
                aspectRatioPropertyHandler,
                ...this.getBasePropertyDescriptors(),
            ],
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: WebviewDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const [urlGetter, urlType] = inflateStringProperty(ib, desc.propertyName, false);
        if (urlType === undefined) return undefined;

        const aspectRatio = aspectRatioPropertyHandler.getEnum(desc);
        const allowScrolling = allowScrollingPropertyHandler.getSwitch(desc);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const url = urlGetter(hb) ?? "";
            const extracted = extractIframe(url);
            if (extracted.source === undefined) return undefined;

            if (!isUrl(extracted.source)) return undefined;

            const result = hydrateAsyncComputation(hb, url, fetchPageTitle);

            const component: WireAppWebViewComponent = {
                kind: WireComponentKind.AppWebView,
                ...spreadComponentID(desc.componentID, forBuilder),
                link: extracted.source,
                aspectRatio,
                title: result.result,
                allowScrolling: extracted.scrollable && allowScrolling,
                explicitHeight: extracted.height,
            };
            return {
                component,
                isValid: true,
                followUp: result.followUp,
            };
        });
    }

    public convertToPage(desc: WebviewDescription, _ccc: AppDescriptionContext): ComponentDescription {
        const webview: WireWebViewDescription = {
            ...makeEmptyComponentDescription(WireComponentKind.WebView),
            url: desc.propertyName,
            scrollable: desc.allowScrolling,
        };
        return webview;
    }
}
