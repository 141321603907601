import { assertNever, panic } from "@glideapps/ts-necessities";
import toPairs from "lodash/toPairs";

export enum PropertySection {
    Top = "Top",
    Data = "Data",
    DataTop = "DataTop",
    Content = "Content",
    Width = "Width",
    Design = "Design",
    SignIn = "SignIn",
    Style = "Style",
    Source = "Source",
    Destination = "Destination",
    Events = "Events",
    Search = "Search",
    FilterByUser = "FilterByUser",
    FilterData = "FilterData",
    DynamicFilter = "DynamicFilter",
    Sort = "Sort",
    GroupBy = "GroupBy",
    InAppSortData = "InAppSortData",
    Visibility = "Visibility",
    Add = "Add",
    AddComponents = "AddComponents",
    AddColumns = "AddColumns",
    Edit = "Edit",
    Delete = "Delete",
    EditComponents = "EditComponents",
    EditColumns = "EditColumns",
    Components = "Components",
    CardComponents = "CardComponents",
    Configuration = "Configuration",
    Action = "Action",
    ActionAction = "ActionAction",
    ImportantAction = "ImportantAction",
    OnSubmitAction = "OnSubmitAction",
    LeftAction = "LeftAction",
    RightAction = "RightAction",
    ColumnHeaders = "ColumnHeaders",
    ScanAction = "ScanAction",
    SwipeCondition = "SwipeCondition",
    TitleBarAction = "TitleBarAction",
    CollectionItems = "CollectionItems",
    ItemClick = "ItemClick",
    CollectionItemActions = "CollectionItemActions",
    Actions = "Actions",
    EditingActions = "EditingActions",
    InlineActions = "InlineActions",
    Options = "Options",
    Columns = "Columns",
    PaymentProcessor = "PaymentProcessor",
    PaymentProductInfo = "PaymentProductInfo",
    Tab = "Tab",
    Page = "Page",
    Screen = "Screen",
    Screens = "Screens",
    Menu = "Menu",
    HiddenTabs = "HiddenTabs",
    NavigationOptions = "NavigationOptions",
    Navigation = "Navigation",
    Template = "Template",
    Replacements = "Replacements",
    QueryParameters = "QueryParameters",
    Cases = "Cases",
    AppURL = "AppURL",
    ShareOptions = "ShareOptions",
    Icon = "Icon",
    AccentColor = "AccentColor",
    Theme = "Theme",
    Theming = "Theming",
    Hidden = "Hidden",
    Title = "Title",
    Rows = "Rows",
    TextStyle = "TextStyle",
    PreviewAs = "PreviewAs",
    ColumnFormat = "ColumnFormat",
    Overlays = "Overlays",
    Reactions = "Reactions",
    Payment = "Payment",
    ScanningOptions = "ScanningOptions",
    UserProfile = "User profile",
    LeftButton = "Left Button",
    RightButton = "Right Button",
    Description = "Description",
    Layout = "Layout",
    CustomCSS = "CustomCSS",
    Phone = "Phone",
    Email = "Email",
    PluginConfig = "PluginConfig",
    Display = "Display",
    AdditionalColumns = "AdditionalColumns",
    Html = "Html",
    Generator = "Generator",
    Notes = "Notes",
}

interface CustomPropertySection {
    name: string;
    order: number;
    header?: HeaderSection;
    needsAddTab?: boolean;
    collapsed?: boolean;
    onSearch?: (search: string) => void;
}

export type SuperPropertySection = PropertySection | CustomPropertySection;

const ConfigurationSectionMap = {
    General: [
        PropertySection.Tab,
        PropertySection.Page,
        PropertySection.Screen,
        PropertySection.Top,
        PropertySection.DataTop,
        PropertySection.UserProfile,
        PropertySection.Layout,
        PropertySection.Style,
        PropertySection.Title,
        PropertySection.Source,
        PropertySection.Destination,
        PropertySection.Events,
        PropertySection.PaymentProcessor,
        PropertySection.Description,
        PropertySection.Data,
        PropertySection.PluginConfig,
        PropertySection.Content,
        PropertySection.ImportantAction,
        PropertySection.CollectionItems,
        PropertySection.ColumnHeaders,
        PropertySection.Width,
        PropertySection.Display,
        PropertySection.AdditionalColumns,
        PropertySection.Design,
        PropertySection.LeftButton,
        PropertySection.RightButton,
        PropertySection.PaymentProductInfo,
        PropertySection.TextStyle,
        PropertySection.Components,
        PropertySection.CardComponents,
        PropertySection.Columns,
        PropertySection.Configuration,
        PropertySection.Template,
        PropertySection.Replacements,
        PropertySection.QueryParameters,
        PropertySection.Cases,
        PropertySection.AppURL,
        PropertySection.ShareOptions,
        PropertySection.PreviewAs,
        PropertySection.ColumnFormat,
        PropertySection.Rows,
        PropertySection.Overlays,
        PropertySection.Reactions,
        PropertySection.Options,
        PropertySection.Action,
        PropertySection.LeftAction,
        PropertySection.RightAction,
        PropertySection.ScanAction,
        PropertySection.ScanningOptions,
        PropertySection.Delete,
        PropertySection.OnSubmitAction,
    ],
    Options: [
        PropertySection.Visibility,
        PropertySection.FilterData,
        PropertySection.Sort,
        PropertySection.GroupBy,
        PropertySection.FilterByUser,
        PropertySection.InAppSortData,
        PropertySection.Search,
        PropertySection.DynamicFilter,
        PropertySection.SwipeCondition,
        PropertySection.Navigation,
        PropertySection.CustomCSS,
        PropertySection.Generator,
        PropertySection.Html,
        PropertySection.Notes,
    ],
    Design: [PropertySection.Theming, PropertySection.Icon, PropertySection.AccentColor, PropertySection.Theme],
    "Sign-In": [PropertySection.SignIn],
    "Add Form": [PropertySection.Add, PropertySection.AddComponents, PropertySection.AddColumns],
    "Edit Form": [PropertySection.Edit, PropertySection.EditComponents, PropertySection.EditColumns],
    Navigation: [
        PropertySection.Screens,
        PropertySection.Menu,
        PropertySection.HiddenTabs,
        PropertySection.NavigationOptions,
    ],
    Hidden: [PropertySection.Hidden],
    Payment: [PropertySection.Payment],
    Actions: [
        PropertySection.ActionAction,
        PropertySection.Actions,
        PropertySection.InlineActions,
        PropertySection.EditingActions,
        PropertySection.TitleBarAction,
        PropertySection.ItemClick,
        PropertySection.CollectionItemActions,
    ],
};

export function isCollapsed(section: SuperPropertySection): boolean {
    if (typeof section === "string") {
        return false;
    } else {
        return section.collapsed ?? false;
    }
}

export function getSectionName(section: SuperPropertySection): string {
    if (typeof section !== "string") {
        return section.name;
    }
    switch (section) {
        case PropertySection.Top:
        case PropertySection.UserProfile:
        case PropertySection.Add:
        case PropertySection.Theming:
        case PropertySection.SignIn:
        case PropertySection.Hidden:
        case PropertySection.Payment:
        case PropertySection.PluginConfig:
            return "";
        case PropertySection.Edit:
            return "Edit";
        case PropertySection.Delete:
            return "Delete";
        case PropertySection.Data:
        case PropertySection.DataTop:
            return "Data";
        case PropertySection.Content:
            return "Content";
        case PropertySection.Width:
            return "Width";
        case PropertySection.Design:
            return "Design";
        case PropertySection.Style:
            return "Style";
        case PropertySection.Source:
            return "Source";
        case PropertySection.Destination:
            return "Destination";
        case PropertySection.Events:
            return "Events";
        case PropertySection.Search:
            return "Search";
        case PropertySection.FilterByUser:
            return "User Data";
        case PropertySection.FilterData:
            return "Filter data";
        case PropertySection.DynamicFilter:
            return "In-App filter";
        case PropertySection.Sort:
            return "Sort data";
        case PropertySection.GroupBy:
            return "Group";
        case PropertySection.InAppSortData:
            return "In-app Sort";
        case PropertySection.Visibility:
            return "Visibility";
        case PropertySection.AddComponents:
        case PropertySection.EditComponents:
        case PropertySection.Components:
            return "Components";
        case PropertySection.CardComponents:
            return "Card";
        case PropertySection.Configuration:
            return "Configuration";
        case PropertySection.Action:
        case PropertySection.ActionAction:
        case PropertySection.ImportantAction:
            return "Action";
        case PropertySection.OnSubmitAction:
            return "On submit";
        case PropertySection.LeftAction:
            return "Left action";
        case PropertySection.RightAction:
            return "Right action";
        case PropertySection.ScanAction:
            return "Scan action";
        case PropertySection.ScanningOptions:
            return "On scan";
        case PropertySection.SwipeCondition:
            return "Swipe condition";
        case PropertySection.NavigationOptions:
        case PropertySection.Options:
            return "Options";
        case PropertySection.Navigation:
            return "Navigation";
        case PropertySection.Columns:
        case PropertySection.EditColumns:
        case PropertySection.AddColumns:
            return "Columns";
        case PropertySection.PaymentProcessor:
            return "Service";
        case PropertySection.PaymentProductInfo:
            return "Product info";
        case PropertySection.Tab:
            return "Tab";
        case PropertySection.Page:
            return "Page";
        case PropertySection.Screen:
            return "Screen";
        case PropertySection.Screens:
            return "Screens";
        case PropertySection.HiddenTabs:
            return "Hidden Tabs";
        case PropertySection.Menu:
            return "Menu";
        case PropertySection.Replacements:
            return "Replacements";
        case PropertySection.QueryParameters:
            return "Query parameters";
        case PropertySection.Template:
            return "Template";
        case PropertySection.Cases:
            return "Cases";
        case PropertySection.AppURL:
            return "App URL";
        case PropertySection.ShareOptions:
            return "Share Options";
        case PropertySection.Icon:
            return "Icon";
        case PropertySection.AccentColor:
            return "Accent Color";
        case PropertySection.Theme:
            return "Theme";
        case PropertySection.Title:
            return "Title";
        case PropertySection.TextStyle:
            return "Text Style";
        case PropertySection.PreviewAs:
            return "View as user";
        case PropertySection.ColumnFormat:
            return "Display";
        case PropertySection.Rows:
            return "Rows";
        case PropertySection.Overlays:
            return "Overlays";
        case PropertySection.Reactions:
            return "Reactions";
        case PropertySection.LeftButton:
            return "Left Button";
        case PropertySection.RightButton:
            return "Right Button";
        case PropertySection.Description:
            return "Description";
        case PropertySection.TitleBarAction:
            return "Title Bar Action";
        case PropertySection.CollectionItems:
            return "Items Data";
        case PropertySection.ItemClick:
            return "Item Click";
        case PropertySection.CollectionItemActions:
            return "Collection Item Actions";
        case PropertySection.Actions:
            return "Actions";
        case PropertySection.InlineActions:
            return "Inline Actions";
        case PropertySection.EditingActions:
            return "Editing";
        case PropertySection.ColumnHeaders:
            return "Column Headers";
        case PropertySection.Layout:
            return "Layout";
        case PropertySection.CustomCSS:
            return "Custom CSS";
        case PropertySection.Phone:
            return "Phone";
        case PropertySection.Email:
            return "Email";
        case PropertySection.Display:
            return "Display data";
        case PropertySection.AdditionalColumns:
            return "Save data";
        case PropertySection.Html:
            return "HTML";
        case PropertySection.Generator:
            return "Generator";
        case PropertySection.Notes:
            return "Notes";
        default:
            assertNever(section);
    }
}

export function getSectionKey(section: SuperPropertySection, i: number): string {
    return typeof section === "string" ? section : section.name !== "" ? section.name : i.toString();
}

export function getSectionPriority(section: SuperPropertySection): number {
    if (typeof section !== "string") {
        return ConfigurationSectionMap.General.length + section.order;
    }
    return Math.max(
        ConfigurationSectionMap.General.indexOf(section),
        ConfigurationSectionMap.Options.indexOf(section),
        ConfigurationSectionMap["Sign-In"].indexOf(section),
        ConfigurationSectionMap["Add Form"].indexOf(section),
        ConfigurationSectionMap["Edit Form"].indexOf(section),
        ConfigurationSectionMap.Navigation.indexOf(section),
        ConfigurationSectionMap.Hidden.indexOf(section),
        ConfigurationSectionMap.Actions.indexOf(section)
    );
}

export type HeaderSection = keyof typeof ConfigurationSectionMap;

export const headerSectionOrder: readonly HeaderSection[] = [
    "General",
    "Options",
    "Design",
    "Sign-In",
    "Add Form",
    "Edit Form",
    "Navigation",
    "Hidden",
    "Payment",
    "Actions",
];

export function sectionBelongsToHeader(ps: SuperPropertySection, header: HeaderSection): boolean {
    if (typeof ps !== "string") {
        if (ps.header !== undefined) {
            return header === ps.header;
        } else {
            return header === "General";
        }
    }

    const l = ConfigurationSectionMap[header];

    if (l === undefined) return false;

    return l.indexOf(ps) !== -1;
}

export function headerSectionForPropertySection(ps: SuperPropertySection): HeaderSection {
    if (typeof ps !== "string") {
        return ps.header ?? "General";
    }
    for (const [hs, pss] of toPairs(ConfigurationSectionMap)) {
        if (pss.indexOf(ps) >= 0) return hs as HeaderSection;
    }
    return panic(`Property section ${ps} does not exist`);
}
