import type { ActionComponentDescription, MutatingScreenKind } from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import type { SchemaInspector } from "@glide/type-schema";
import type { ActionPropertyDescriptor } from "@glide/function-utils";

import { ComponentHandlerBase } from "./handler";
import { makeDefaultActionDescriptor } from "./primitive";

export abstract class ActionComponentHandlerBase<T extends ActionComponentDescription> extends ComponentHandlerBase<T> {
    public getActionDescriptors(
        _desc: T | undefined,
        _tables: InputOutputTables | undefined,
        schema: SchemaInspector | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly ActionPropertyDescriptor[] {
        return [makeDefaultActionDescriptor(schema, mutatingScreenKind, false)];
    }
}
