import { ArrayScreenFormat } from "@glide/app-description";
import type { WireListComponentGeneric } from "@glide/fluent-components/dist/js/fluent-components-spec";
import { type WireComponent, WireComponentKind } from "@glide/wire";
import * as React from "react";

enum ScreenSpecialCases {
    CommentScreen = "comment-screen",
}
const ScreenPecialCasesContext = React.createContext<ScreenSpecialCases | undefined>(undefined);

interface ScreenSpecialCasesProviderProps {
    readonly components: readonly (WireComponent | null)[];
}

export const ScreenSpecialCasesProvider: React.FC<React.PropsWithChildren<ScreenSpecialCasesProviderProps>> = p => {
    const { components } = p;

    const specialCases = React.useMemo<ScreenSpecialCases | undefined>(() => {
        if (components.length === 1 && components[0]?.kind === WireComponentKind.List) {
            const component = components[0] as WireListComponentGeneric<any>;
            if (component?.format === ArrayScreenFormat.Comments) {
                return ScreenSpecialCases.CommentScreen;
            }
        }

        return undefined;
    }, [components]);

    return <ScreenPecialCasesContext.Provider value={specialCases}>{p.children}</ScreenPecialCasesContext.Provider>;
};

function useScreenSpecialCases(): ScreenSpecialCases | undefined {
    return React.useContext(ScreenPecialCasesContext);
}

export function useIsCommentScreen() {
    const screenSpecialCase = useScreenSpecialCases();
    return screenSpecialCase === ScreenSpecialCases.CommentScreen;
}
