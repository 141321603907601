import { usePreviousValue } from "@glide/common";
import { defined } from "@glideapps/ts-necessities";
import * as React from "react";
import { shallowEqual } from "react-redux";

export function useCleanupMemo<T>(factory: () => [T, () => void], deps: React.DependencyList): T {
    const lastDeps = usePreviousValue(deps);
    const next = shallowEqual(lastDeps, deps) ? undefined : factory();

    const ref = React.useRef<[T, () => void]>();

    if (next !== undefined) {
        if (ref.current !== undefined) {
            ref.current[1]();
        }
        ref.current = next;
    }

    // This is called only on unmount, and we need it because the cleanup
    // above won't be called on unmount.
    // NOTE: This won't work in React 18!
    React.useEffect(() => {
        return () => {
            if (ref.current !== undefined) {
                ref.current[1]();
            }
        };
    }, []);

    return defined(ref.current)[0];
}
