import type { FilterArrayTransform } from "@glide/app-description";
import {
    type RelationLoopNode,
    type TableLoopNode,
    type LoopNode,
    type RangeLoopNode,
    type PropertyDescription,
    type ArrayLoopNode,
    type ActionOutputDescriptor,
    isTableLoopNode,
    isRelationLoopNode,
    isRangeLoopNode,
    isArrayLoopNode,
    ActionNodeKind,
    getNumberProperty,
    getSourceColumnProperty,
    makeSourceColumnProperty,
} from "@glide/app-description";
import type { SourceColumn, TableName } from "@glide/type-schema";
import {
    type PrimitiveGlideTypeKind,
    type TableColumn,
    type TableGlideType,
    isMultiRelationType,
    getTableName,
    makePrimitiveType,
    makeTableRef,
    sheetNameForTable,
    getTableColumnDisplayName,
    isPrimitiveArrayType,
    decomposeActionNodeOutputSourceColumn,
    isActionNodeOutputSourceColumn,
    decomposeRelationType,
} from "@glide/type-schema";
import { Result } from "@glide/plugins";
import type { ActionRunContext, EnvironmentDefinition, Stack } from "./types";
import type { DocumentData } from "@glide/common-core/dist/js/Database";
import { resolveSourceColumn, type ActionNodeInScope, type AppDescriptionContext } from "@glide/function-utils";
import {
    SyntheticColumnKind,
    type FilterReferenceSpecification,
    makeFilterReferenceFormulaUnsafe,
} from "@glide/formula-specifications";
import { getPrimitiveNodeName, makeActionOutputColumnName } from "./names";
import type { Row, Table } from "@glide/computation-model-types";
import { assertNever } from "@glideapps/ts-necessities";
import { checkString, checkArray, checkNumber, isArray } from "@glide/support";
import { getIDColumnForTable, getIDForRow } from "./row-id";
import { asMaybeNumber, isNotEmpty } from "@glide/common-core/dist/js/computation-model/data";
import type { PriorStep } from "@glide/generator/dist/js/prior-step";

interface LoopHandler {
    /**
     * Returns the display name of the automation step.
     * The consumer should choose a fitting default if this gives `undefined`.
     */
    getStepTitle(adc: AppDescriptionContext, actionNodesInScope: readonly PriorStep[]): string | undefined;
    /**
     * Returns the output descriptors for the loop node.  These outputs are
     * visible within the loop body.  Examples:
     *
     * - For a loop that loops over a range of number, the output would be
     *   current iteration's number.
     * - For a loop that loops over an array, the output would be the current
     *   iteration's element in the array.
     * - For a loop that loops over a table, the output would be the current
     *   iteration's row.
     *
     * Note that `makeInternalTableColumns` must return the output columns for
     * all of these.
     */
    getActionOutputs(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): Result<readonly ActionOutputDescriptor[]>;
    /**
     * Return the columns to be added to the internal table for the loop body.
     * These can be basic columns, but also computed columns.  These have to
     * include the output columns for all outputs returned by
     * `getActionOutputs`.  The names of these columns are generated with
     * `makeActionOutputColumnName`.  The data in basic columns has to be
     * produced by `makeDataForIteration`.  The computed columns will
     * typically use the basic columns as their inputs.  Examples:
     *
     * - For a loop that loops over a range of numbers, the only column would
     *   be the output: a basic column that contains the current iteration's
     *   number.
     * - For a loop that loops over an array, the only column would be the
     *   output: a basic column that contains the current iteration's element
     *   in the array.
     * - For a loop that loops over a table, there would be a basic column
     *   that has the current row's ID, and the output column would be a
     *   single-relation column to the current row, via the ID column.
     */
    makeInternalTableColumns(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): Result<readonly TableColumn[]>;

    /**
     * This method is invoked before each iteration of the loop.  It manages
     * data in two rows:
     *
     * - The "parent" row, which is the internal row for the scope that
     *   contains the loop.  It should use this row to persist all the data it
     *   needs to produce the outputs for each loop iteration.  On the first
     *   invocation (i.e. before the very first loop iteration), the
     *   `parentRow` will be `undefined` and this method should return the
     *   data for the `initialParentRowData`.  On subsequent invocations, that
     *   data will be present in the `parentRow`, and this method will
     *   typically return `undefined` for the `initialParentRowData`.
     * - The "loop" row, which is the internal row for the scope of the loop
     *   body.  It has to produce the data for the basic columns it returned
     *   from `makeInternalTableColumns`.
     *
     * Some examples:
     * - A loop that loops over a range of numbers would probably store the
     *   start an end values as well as the increment in the parent row.  On
     *   each iteration it would produce `start + index * increment` as the
     *   value for the output column in the loop row.
     * - A loop that loops over an array would probably store the full array
     *   in the parent row.  On each iteration it would produce the element at
     *   `index` in the array as the value for the output column in the loop
     *   row.
     * - A loop that loops over a table would store the IDs of the rows to
     *   loop over in the parent row.  On each iteration it would produce the
     *   ID at `index` as the value for the basic ID column in the loop row.
     *
     * Note that the column names it used in the parent row must be made
     * unique with `loop.key` because a single parent row can be shared by
     * more than one loop in that scope.  Note also the loop doesn't need to
     * declare the columns it uses in the parent row, because those can't be
     * used in the computation model - they're just persisted.
     *
     * If this method returns `undefined`, then the loop is finished.  It can
     * return `undefined` even on the first invocation, in which case the loop
     * won't do a single iteration.
     */
    makeDataForIteration(
        env: EnvironmentDefinition,
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[],
        runContext: ActionRunContext,
        index: number,
        parentRow: Row | undefined
    ): Promise<Result<[initialData: DocumentData, initialParentRowData: DocumentData | undefined] | undefined>>;

    // TODO: Maybe this and getLoopSourceColumnsOrTable could be combined into one method

    /**
     * Returns the loop filter along with the table.
     * We need the table we apply the filter on
     * in cases where we have a ActionNodeOutput as a source column.
     *
     * undefined means we don't have a filter, but we should not fail in that case
     * since we allow it.
     */
    getLoopFilter(
        adc: AppDescriptionContext,
        priorSteps: readonly PriorStep[]
    ): Result<{ filter: FilterArrayTransform; table: TableGlideType } | undefined>;

    /**
     * This returns the source columns or table used in the loop source.
     * We use this to check for missing columns or tables in the loop source.
     */
    getLoopSourceColumnsOrTable(): Result<SourceColumn[] | TableName>;
}

const loopIterablesColumnNamePrefix = "loop-iterables-";

export const loopCurrentRowIDColumnName = "loop-current-row-id";
// exported for testing
export const loopRowOutputName = "loop-row";

const loopCurrentRowIDColumn: TableColumn = {
    name: loopCurrentRowIDColumnName,
    type: makePrimitiveType("string"),
};

function makeLoopRowRelationColumn(actionTable: TableGlideType, nodeKey: string | undefined): Result<TableColumn> {
    const idColumn = getIDColumnForTable(actionTable);
    if (!idColumn.ok) return idColumn;

    const actionTableName = getTableName(actionTable);
    const relationColumnSpec: FilterReferenceSpecification = {
        kind: SyntheticColumnKind.FilterReference,
        hostColumn: loopCurrentRowIDColumn.name,
        targetTable: actionTableName,
        targetColumn: idColumn.result.name,
        multiple: false,
    };

    const formula = makeFilterReferenceFormulaUnsafe(relationColumnSpec, true, true);

    const columnName =
        nodeKey !== undefined ? makeActionOutputColumnName(nodeKey, loopRowOutputName) : loopRowOutputName;

    return Result.Ok({
        name: columnName,
        type: makeTableRef(actionTableName),
        formula,
    });
}

async function makeDataForIterationFromRowFetcher(
    index: number,
    table: TableGlideType,
    parentRow: Row | undefined,
    nodeKey: string,
    fetchRows: () => Promise<Result<Table>>
): Promise<Result<[initialData: DocumentData, initialParentRowData: DocumentData | undefined] | undefined>> {
    const idColumn = getIDColumnForTable(table);
    if (!idColumn.ok) return idColumn;

    // A single scope can contain multiple loops, so we need to
    // disambiguate them.
    const loopIterablesColumnName = loopIterablesColumnNamePrefix + nodeKey;

    let iterables: string[]; // the IDs to iterate over
    let initialParentRowData: DocumentData | undefined;
    if (parentRow?.[loopIterablesColumnName] !== undefined) {
        iterables = checkArray(parentRow[loopIterablesColumnName] as unknown, checkString);
    } else {
        const rowsResult = await fetchRows();
        if (!rowsResult.ok) return rowsResult;
        const rows = rowsResult.result;

        iterables = [];
        for (const row of rows.values()) {
            const id = getIDForRow(row, idColumn.result);
            if (!id.ok) return id;

            iterables.push(id.result);
        }

        initialParentRowData = { [loopIterablesColumnName]: iterables };
    }

    if (index >= iterables.length) return Result.Ok(undefined);

    return Result.Ok([{ [loopCurrentRowIDColumnName]: iterables[index] }, initialParentRowData]);
}

class TableLoopHandler implements LoopHandler {
    constructor(private readonly loop: TableLoopNode) {}

    public getStepTitle(adc: AppDescriptionContext): string | undefined {
        const table = adc.findTable(this.loop.source.tableName);
        if (table === undefined) return undefined;

        return sheetNameForTable(table);
    }

    public getActionOutputs(adc: AppDescriptionContext): Result<readonly ActionOutputDescriptor[]> {
        const table = adc.findTable(this.loop.source.tableName);
        if (table === undefined)
            return Result.FailPermanent("Table for loop not found", { isAutomationConfigurationError: true });

        return Result.Ok([
            {
                name: loopRowOutputName,
                displayName: "Current row",
                description: "The row for the current iteration",
                type: makeTableRef(table),
            },
        ]);
    }

    public makeInternalTableColumns(adc: AppDescriptionContext): Result<readonly TableColumn[]> {
        const table = adc.findTable(this.loop.source.tableName);
        if (table === undefined)
            return Result.FailPermanent("Table for loop not found", { isAutomationConfigurationError: true });

        const relationColumn = makeLoopRowRelationColumn(table, this.loop.key);
        if (!relationColumn.ok) return relationColumn;

        return Result.Ok([loopCurrentRowIDColumn, relationColumn.result]);
    }

    public async makeDataForIteration(
        env: Stack,
        adc: AppDescriptionContext,
        _actionNodesInScope: readonly ActionNodeInScope[],
        runContext: ActionRunContext,
        index: number,
        parentRow: Row | undefined
    ): Promise<Result<[initialData: DocumentData, initialParentRowData: DocumentData | undefined] | undefined>> {
        const table = adc.findTable(this.loop.source.tableName);
        if (table === undefined)
            return Result.FailPermanent("Table for loop not found", { isAutomationConfigurationError: true });

        return makeDataForIterationFromRowFetcher(index, table, parentRow, this.loop.key, () => {
            const { filter, limit } = this.loop.source;
            return runContext.fetchTable(env, getTableName(table), filter, limit);
        });
    }

    getLoopFilter(
        adc: AppDescriptionContext,
        _priorSteps: readonly PriorStep[]
    ): Result<{ filter: FilterArrayTransform; table: TableGlideType } | undefined> {
        if (this.loop.source.filter === undefined) return Result.Ok(undefined);
        const table = adc.findTable(this.loop.source.tableName);
        if (table === undefined)
            return Result.Fail("Table for loop filter not found", { isAutomationConfigurationError: true });

        return Result.Ok({ filter: this.loop.source.filter, table });
    }

    public getLoopSourceColumnsOrTable(): Result<SourceColumn[] | TableName> {
        return Result.Ok(this.loop.source.tableName);
    }
}

class RelationLoopHandler implements LoopHandler {
    constructor(private readonly loop: RelationLoopNode) {}

    public getStepTitle(adc: AppDescriptionContext, priorSteps: readonly PriorStep[]): string | undefined {
        const resolvedColumn = resolveSourceColumn(
            adc,
            this.loop.source.sourceColumn,
            undefined,
            undefined,
            priorSteps.map(p => p.node)
        );
        if (resolvedColumn === undefined || resolvedColumn.tableAndColumn === undefined) {
            return undefined;
        }

        const displayName = getTableColumnDisplayName(resolvedColumn.tableAndColumn.column);

        return displayName;
    }

    private getRelationTable(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): Result<TableGlideType> {
        const tableAndColumn = resolveSourceColumn(
            adc,
            this.loop.source.sourceColumn,
            undefined,
            undefined,
            actionNodesInScope
        );
        if (tableAndColumn === undefined || tableAndColumn.type === undefined) {
            return Result.FailPermanent("Source for loop not found", { isAutomationConfigurationError: true });
        }
        if (!isMultiRelationType(tableAndColumn.type)) {
            return Result.FailPermanent("Loop source is not a multi-relation", {
                isAutomationConfigurationError: true,
            });
        }
        const table = adc.findTable(tableAndColumn.type.items);
        if (table === undefined) {
            return Result.FailPermanent("Table for loop not found", { isAutomationConfigurationError: true });
        }

        return Result.Ok(table);
    }

    public getActionOutputs(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): Result<readonly ActionOutputDescriptor[]> {
        const table = this.getRelationTable(adc, actionNodesInScope);
        if (!table.ok) return table;

        return Result.Ok([
            {
                name: loopRowOutputName,
                displayName: "Current row",
                description: "The row for the current iteration",
                type: makeTableRef(table.result),
            },
        ]);
    }

    public makeInternalTableColumns(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): Result<readonly TableColumn[]> {
        const table = this.getRelationTable(adc, actionNodesInScope);
        if (!table.ok) return table;

        const relationColumn = makeLoopRowRelationColumn(table.result, this.loop.key);
        if (!relationColumn.ok) return relationColumn;

        return Result.Ok([loopCurrentRowIDColumn, relationColumn.result]);
    }

    public async makeDataForIteration(
        env: Stack,
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[],
        runContext: ActionRunContext,
        index: number,
        parentRow: Row | undefined
    ): Promise<Result<[initialData: DocumentData, initialParentRowData: DocumentData | undefined] | undefined>> {
        const table = this.getRelationTable(adc, actionNodesInScope);
        if (!table.ok) return table;

        return makeDataForIterationFromRowFetcher(index, table.result, parentRow, this.loop.key, () => {
            const { sourceColumn, filter, limit } = this.loop.source;
            return runContext.fetchMultiRelation(env, sourceColumn, filter, limit);
        });
    }

    getLoopFilter(
        adc: AppDescriptionContext,
        priorSteps: readonly PriorStep[]
    ): Result<{ filter: FilterArrayTransform; table: TableGlideType } | undefined> {
        if (this.loop.source.filter === undefined) return Result.Ok(undefined);
        const resolvedColumn = resolveSourceColumn(
            adc,
            this.loop.source.sourceColumn,
            undefined,
            undefined,
            priorSteps.map(p => p.node)
        );

        if (resolvedColumn === undefined || resolvedColumn.type === undefined) {
            return Result.Fail("Can't resolve relation source column", { isAutomationConfigurationError: true });
        }

        const maybeTable = decomposeRelationType(resolvedColumn.type);

        if (maybeTable === undefined)
            return Result.Fail("Source column is not a relation type", { isAutomationConfigurationError: true });
        const table = adc.findTable(maybeTable.tableRef);

        if (table === undefined)
            return Result.Fail("Table for loop filter not found", { isAutomationConfigurationError: true });

        return Result.Ok({ filter: this.loop.source.filter, table });
    }

    public getLoopSourceColumnsOrTable(): Result<SourceColumn[] | TableName> {
        return Result.Ok([this.loop.source.sourceColumn]);
    }
}

class RangeLoopHandler implements LoopHandler {
    private readonly loopIndexOutputName = "loop-index";
    private readonly loopIndexColumnName: string;

    constructor(private readonly loop: RangeLoopNode) {
        this.loopIndexColumnName = makeActionOutputColumnName(this.loop.key, this.loopIndexOutputName);
    }

    private getPropertyDisplay(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[],
        property: PropertyDescription
    ): string | undefined {
        const constantNumber = getNumberProperty(property);
        if (constantNumber !== undefined) {
            return constantNumber.toString();
        }

        const sourceColumn = getSourceColumnProperty(property);
        if (sourceColumn === undefined) {
            return undefined;
        }

        const resolvedColumn = resolveSourceColumn(adc, sourceColumn, undefined, undefined, actionNodesInScope);

        if (resolvedColumn === undefined || resolvedColumn.tableAndColumn === undefined) {
            return undefined;
        }

        const displayName = getTableColumnDisplayName(resolvedColumn.tableAndColumn.column);

        return displayName;
    }

    public getStepTitle(adc: AppDescriptionContext, priorSteps: readonly PriorStep[]): string | undefined {
        const { repetitions } = this.loop.source;

        const repetitionsDisplay = this.getPropertyDisplay(
            adc,
            priorSteps.map(p => p.node),
            repetitions
        );
        if (repetitionsDisplay === undefined) return undefined;

        return `${repetitionsDisplay} time${(repetitions.value as number) !== 1 ? "s" : ""}`;
    }

    public getActionOutputs(): Result<readonly ActionOutputDescriptor[]> {
        return Result.Ok([
            {
                name: this.loopIndexOutputName,
                displayName: "Current index",
                description: "The index for the current iteration",
                type: makePrimitiveType("number"),
            },
        ]);
    }

    public makeInternalTableColumns(): Result<readonly TableColumn[]> {
        const loopCurrentIndex: TableColumn = {
            name: this.loopIndexColumnName,
            type: makePrimitiveType("number"),
        };
        return Result.Ok([loopCurrentIndex]);
    }

    private async getLoopRange(env: Stack, runContext: ActionRunContext, parentRow: Row | undefined) {
        const loopStartColumnName = `loop-start-${this.loop.key}`;
        const loopRepetitionsColumnName = `loop-repetitions-${this.loop.key}`;
        const loopIncrementColumnName = `loop-increment-${this.loop.key}`;

        if (
            parentRow?.[loopStartColumnName] !== undefined &&
            parentRow?.[loopRepetitionsColumnName] !== undefined &&
            parentRow?.[loopIncrementColumnName] !== undefined
        ) {
            return Result.Ok({
                start: checkNumber(parentRow[loopStartColumnName]),
                repetitions: checkNumber(parentRow[loopRepetitionsColumnName]),
                increment: checkNumber(parentRow[loopIncrementColumnName]),
                initialParentRowData: undefined,
            });
        } else {
            const maybeStart = await runContext.getValueForProperty(env, this.loop.source.start, false);
            if (!maybeStart.ok) return maybeStart;

            const maybeRepetitions = await runContext.getValueForProperty(env, this.loop.source.repetitions, false);
            if (!maybeRepetitions.ok) return maybeRepetitions;

            const maybeIncrement = await runContext.getValueForProperty(env, this.loop.source.increment, false);
            if (!maybeIncrement.ok) return maybeIncrement;

            const start = asMaybeNumber(maybeStart.result);
            if (start === undefined) return Result.FailPermanent("Start is not a number");
            const repetitions = asMaybeNumber(maybeRepetitions.result);
            if (repetitions === undefined) return Result.FailPermanent("Repetitions is not a number");
            const increment = asMaybeNumber(maybeIncrement.result);
            if (increment === undefined) return Result.FailPermanent("Increment is not a number");

            return Result.Ok({
                start,
                repetitions,
                increment,
                initialParentRowData: {
                    [loopStartColumnName]: start,
                    [loopRepetitionsColumnName]: repetitions,
                    [loopIncrementColumnName]: increment,
                },
            });
        }
    }

    public async makeDataForIteration(
        env: Stack,
        _adc: AppDescriptionContext,
        _actionNodesInScope: readonly ActionNodeInScope[],
        runContext: ActionRunContext,
        index: number,
        parentRow: Row | undefined
    ): Promise<Result<[initialData: DocumentData, initialParentRowData: DocumentData | undefined] | undefined>> {
        const loopRangeResult = await this.getLoopRange(env, runContext, parentRow);
        if (!loopRangeResult.ok) return loopRangeResult;

        const { start, repetitions, increment, initialParentRowData } = loopRangeResult.result;

        const outputIndex = start + index * increment;

        if (index >= repetitions) return Result.Ok(undefined);

        return Result.Ok([{ [this.loopIndexColumnName]: outputIndex }, initialParentRowData]);
    }

    public getLoopFilter(
        _adc: AppDescriptionContext,
        _priorSteps: readonly PriorStep[]
    ): Result<{ filter: FilterArrayTransform; table: TableGlideType } | undefined> {
        return Result.Ok(undefined);
    }

    public getLoopSourceColumnsOrTable(): Result<SourceColumn[] | TableName> {
        const sourceColumns: SourceColumn[] = [];
        const maybeIncrementSourceColumn = getSourceColumnProperty(this.loop.source.increment);
        if (maybeIncrementSourceColumn !== undefined) {
            sourceColumns.push(maybeIncrementSourceColumn);
        }

        const maybeRepetitionsSourceColumn = getSourceColumnProperty(this.loop.source.repetitions);
        if (maybeRepetitionsSourceColumn !== undefined) {
            sourceColumns.push(maybeRepetitionsSourceColumn);
        }

        const maybeStartSourceColumn = getSourceColumnProperty(this.loop.source.start);
        if (maybeStartSourceColumn !== undefined) {
            sourceColumns.push(maybeStartSourceColumn);
        }

        return Result.Ok(sourceColumns);
    }
}

class ArrayLoopHandler implements LoopHandler {
    private readonly loopItemOutputName = "loop-array-item";
    private readonly loopItemColumnName: string;

    constructor(private readonly loop: ArrayLoopNode) {
        this.loopItemColumnName = makeActionOutputColumnName(this.loop.key, this.loopItemOutputName);
    }

    public getStepTitle(adc: AppDescriptionContext, priorSteps: readonly PriorStep[]): string | undefined {
        const actionNodesInScope = priorSteps.map(p => p.node);
        const resolvedColumn = resolveSourceColumn(
            adc,
            this.loop.source.sourceColumn,
            undefined,
            undefined,
            priorSteps.map(p => p.node)
        );
        if (resolvedColumn === undefined) {
            return undefined;
        }

        // If this happens, then it's possible that the source of this is loop is a prior step that outputs an array.
        // We look for
        if (resolvedColumn.tableAndColumn === undefined) {
            if (!isActionNodeOutputSourceColumn(this.loop.source.sourceColumn)) return undefined;
            const decomposed = decomposeActionNodeOutputSourceColumn(this.loop.source.sourceColumn);
            if (decomposed === undefined) return undefined;

            const [actionNodeKey] = decomposed;
            const maybeNode = actionNodesInScope.find(a => a.node.key === actionNodeKey);
            if (maybeNode === undefined) return undefined;
            // This case should not happen, but if it does, this wont hurt.
            if (maybeNode.node.kind !== ActionNodeKind.Primitive) return `Loop`;
            const nodeName = getPrimitiveNodeName(maybeNode.node, adc, priorSteps);

            return nodeName;
        }

        const displayName = getTableColumnDisplayName(resolvedColumn.tableAndColumn.column);

        return displayName;
    }

    private getArrayItemType(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): Result<PrimitiveGlideTypeKind> {
        const resolvedColumn = resolveSourceColumn(
            adc,
            this.loop.source.sourceColumn,
            undefined,
            undefined,
            actionNodesInScope
        );
        if (resolvedColumn === undefined || resolvedColumn.type === undefined) {
            return Result.FailPermanent("Can't resolve array source column", { isAutomationConfigurationError: true });
        }

        if (isPrimitiveArrayType(resolvedColumn.type)) {
            return Result.Ok(resolvedColumn.type.items.kind);
        }

        const isJSONtype = resolvedColumn.type.kind === "json";
        if (isJSONtype) {
            return Result.Ok("json");
        }

        return Result.FailPermanent("Source column is not an array type", { isAutomationConfigurationError: true });
    }

    public getActionOutputs(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): Result<readonly ActionOutputDescriptor[]> {
        const itemType = this.getArrayItemType(adc, actionNodesInScope);
        if (!itemType.ok) return itemType;

        return Result.Ok([
            {
                name: this.loopItemOutputName,
                displayName: "Current array item",
                description: "The array item for the current iteration",
                type: makePrimitiveType(itemType.result),
            },
        ]);
    }

    public makeInternalTableColumns(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): Result<readonly TableColumn[]> {
        const itemType = this.getArrayItemType(adc, actionNodesInScope);
        if (!itemType.ok) return itemType;

        const loopCurrentItem: TableColumn = {
            name: this.loopItemColumnName,
            type: makePrimitiveType(itemType.result),
        };
        return Result.Ok([loopCurrentItem]);
    }

    private async getAllLoopItems(env: Stack, runContext: ActionRunContext, parentRow: Row | undefined) {
        const loopAllItemsColumnName = `loop-all-items-${this.loop.key}`;

        if (parentRow?.[loopAllItemsColumnName] === undefined) {
            const columnProperty = makeSourceColumnProperty(this.loop.source.sourceColumn);
            const columnValueResult = await runContext.getValueForProperty(env, columnProperty, false);
            if (!columnValueResult.ok) return columnValueResult;
            if (!isNotEmpty(columnValueResult.result)) {
                return Result.Ok({
                    iterables: [],
                    initialParentRowData: { [loopAllItemsColumnName]: [] },
                });
            }

            if (!isArray(columnValueResult.result)) {
                return Result.FailPermanent("Loop source is not an array", { isAutomationConfigurationError: true });
            }

            const { limit } = this.loop.source;
            const iterables = columnValueResult.result.slice(0, limit);

            return Result.Ok({
                iterables,
                initialParentRowData: {
                    [loopAllItemsColumnName]: iterables,
                },
            });
        }

        const iterables = checkArray<unknown, unknown>(parentRow[loopAllItemsColumnName]);

        return Result.Ok({
            iterables,
            initialParentRowData: undefined,
        });
    }

    public async makeDataForIteration(
        env: Stack,
        _adc: AppDescriptionContext,
        _actionNodesInScope: readonly ActionNodeInScope[],
        runContext: ActionRunContext,
        index: number,
        parentRow: Row | undefined
    ): Promise<Result<[initialData: DocumentData, initialParentRowData: DocumentData | undefined] | undefined>> {
        const allLoopItems = await this.getAllLoopItems(env, runContext, parentRow);
        if (!allLoopItems.ok) return allLoopItems;

        const { initialParentRowData, iterables } = allLoopItems.result;

        if (index >= iterables.length) return Result.Ok(undefined);

        return Result.Ok([{ [this.loopItemColumnName]: iterables[index] }, initialParentRowData]);
    }

    public getLoopFilter(
        _adc: AppDescriptionContext,
        _priorSteps: readonly PriorStep[]
    ): Result<{ filter: FilterArrayTransform; table: TableGlideType } | undefined> {
        return Result.Ok(undefined);
    }

    public getLoopSourceColumnsOrTable(): Result<SourceColumn[] | TableName> {
        return Result.Ok([this.loop.source.sourceColumn]);
    }
}

// We use this in cases when we don't want to fail if a loop is misconfigured, like when we want to show errors in the UI
export class DefaultOnSourceErrorLoopHandler implements LoopHandler {
    constructor(private readonly inner: LoopHandler) {}

    public getStepTitle(adc: AppDescriptionContext, actionNodesInScope: readonly PriorStep[]): string | undefined {
        return this.inner.getStepTitle(adc, actionNodesInScope);
    }

    public getActionOutputs(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): Result<readonly ActionOutputDescriptor[]> {
        const outputsResult = this.inner.getActionOutputs(adc, actionNodesInScope);
        if (!outputsResult.ok) {
            return Result.Ok([]);
        }
        return outputsResult;
    }

    public makeInternalTableColumns(
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): Result<readonly TableColumn[]> {
        const columnsResult = this.inner.makeInternalTableColumns(adc, actionNodesInScope);
        if (!columnsResult.ok) {
            return Result.Ok([]);
        }
        return columnsResult;
    }

    public async makeDataForIteration(
        env: EnvironmentDefinition,
        adc: AppDescriptionContext,
        actionNodesInScope: readonly ActionNodeInScope[],
        runContext: ActionRunContext,
        index: number,
        parentRow: Row | undefined
    ): Promise<Result<[initialData: DocumentData, initialParentRowData: DocumentData | undefined] | undefined>> {
        return this.inner.makeDataForIteration(env, adc, actionNodesInScope, runContext, index, parentRow);
    }

    public getLoopFilter(
        adc: AppDescriptionContext,
        priorSteps: readonly PriorStep[]
    ): Result<{ filter: FilterArrayTransform; table: TableGlideType } | undefined> {
        return this.inner.getLoopFilter(adc, priorSteps);
    }

    public getLoopSourceColumnsOrTable(): Result<SourceColumn[] | TableName> {
        return this.inner.getLoopSourceColumnsOrTable();
    }
}

export function getHandlerForLoop(loop: LoopNode, useDefaultOnSourceError: boolean): LoopHandler {
    let handler: LoopHandler;
    if (isTableLoopNode(loop)) {
        handler = new TableLoopHandler(loop);
    } else if (isRelationLoopNode(loop)) {
        handler = new RelationLoopHandler(loop);
    } else if (isRangeLoopNode(loop)) {
        handler = new RangeLoopHandler(loop);
    } else if (isArrayLoopNode(loop)) {
        handler = new ArrayLoopHandler(loop);
    } else {
        return assertNever(loop);
    }

    if (useDefaultOnSourceError) {
        return new DefaultOnSourceErrorLoopHandler(handler);
    }
    return handler;
}
