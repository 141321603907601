import { runActionAndHandleURL, type WireRenderer } from "@glide/wire-renderer";
import type { WireSignInComponent } from "@glide/fluent-components/dist/js/base-components";
import * as React from "react";
import type { RootAuthenticator } from "@glide/auth-controller-core";
import { defined } from "@glideapps/ts-necessities";
import { PlayerAuthScreen } from "./auth-screen";

interface WireAuthControllerContext {
    readonly authenticator: RootAuthenticator;
    readonly appTitle: string;
}

const WireAuthControllerContextImpl = React.createContext<WireAuthControllerContext | undefined>(undefined);

export const WireAuthControllerProvider: React.FC<React.PropsWithChildren<WireAuthControllerContext>> = p => {
    const { authenticator, appTitle, children } = p;

    const value = React.useMemo(() => {
        return {
            authenticator,
            appTitle,
        };
    }, [appTitle, authenticator]);

    return <WireAuthControllerContextImpl.Provider value={value}>{children}</WireAuthControllerContextImpl.Provider>;
};

function useWireAuthControllerContext(): WireAuthControllerContext {
    return defined(
        React.useContext(WireAuthControllerContextImpl),
        "Cannot use WireAuthController outside of a WireAuthControllerProvider"
    );
}

export const WireAuthController: WireRenderer<WireSignInComponent, { customCssClassName?: string }> = p => {
    const { backend, onSuccess, iconImage } = p;

    const { authenticator, appTitle } = useWireAuthControllerContext();

    const onLoggedIn = React.useCallback(async () => {
        runActionAndHandleURL(onSuccess, backend);
    }, [backend, onSuccess]);

    return (
        <PlayerAuthScreen
            onLoggedIn={onLoggedIn}
            appFeatures={backend.appFeatures}
            iconImage={iconImage}
            appTitle={appTitle}
            authenticator={authenticator}
        />
    );
};
