import { useChangeObservable, useDeepEqual } from "@glide/common";
import type { MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import type { EminenceFlags } from "@glide/billing-types";
import { eminenceFull } from "@glide/common-core/dist/js/Database/eminence";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { getAppEminenceFlags } from "./get-app-eminence";
import { AutoWatchable, Watchable } from "@glide/support";
import { DefaultMap, hasOwnProperty } from "@glideapps/ts-necessities";
import type { ComponentType, FunctionComponent } from "react";
import type { Subtract } from "utility-types";

type PlayerEminenceFlags = DefaultMap<string, Watchable<EminenceFlags>>;
const playerWatchedFlags: PlayerEminenceFlags = new DefaultMap(k => {
    const flags =
        hasOwnProperty(window, "eminenceFlags") && window.eminenceFlags !== undefined
            ? (window.eminenceFlags as EminenceFlags)
            : eminenceFull;
    return k !== "" ? new AutoWatchable(flags, async () => getAppEminenceFlags(k), 60 * 1000) : new Watchable(flags);
});

export function getPlayerEminenceFlags(appID: string): EminenceFlags {
    return playerWatchedFlags.get(appID).current;
}

export function usePlayerEminenceFlags(appID: string): EminenceFlags {
    return useDeepEqual(useChangeObservable(playerWatchedFlags.get(appID)));
}

interface WithPlayerEminenceFlagsProps {
    readonly eminenceFlags: EminenceFlags;
}

interface WrapperProps {
    readonly appID: string;
    readonly appEnvironment: MinimalAppEnvironment;
}

interface Props extends Partial<WrapperProps>, WithPlayerEminenceFlagsProps {}

export const withPlayerEminenceFlags =
    <P extends Props>(Component: ComponentType<P>): FunctionComponent<Subtract<P, WithPlayerEminenceFlagsProps>> =>
    p => {
        const routerAppID = useAppID();
        const appID = p.appID ?? p.appEnvironment?.appID ?? routerAppID ?? (window as any).appID ?? "";
        const eminenceFlags = usePlayerEminenceFlags(appID);
        return <Component {...(p as P)} eminenceFlags={eminenceFlags} />;
    };
