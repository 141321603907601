import {
    ImageAspectRatio,
    ImageGravity,
    OverlayTextAlignment,
    TextPosition,
    TilesPadding,
} from "@glide/common-core/dist/js/components/image-types";
import type {
    ActionDescription,
    PropertyDescription,
    ScreenDescriptionKind,
    ArrayScreenDescription,
} from "@glide/app-description";
import {
    ArrayScreenFormat,
    getActionProperty,
    getSourceColumnProperty,
    getSwitchProperty,
    makeActionProperty,
    makeArrayProperty,
    makeEnumProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import { getTableName, type TableGlideType } from "@glide/type-schema";
import { GroupingSupport, TextStyle } from "@glide/component-utils";
import {
    type WireAppTilesBaseListComponent,
    type WireAppTilesListComponent,
    type WireTilesListItem,
    WireImageFallback,
} from "@glide/fluent-components/dist/js/base-components";
import type { AppDescriptionContext, InlineListComponentDescription } from "@glide/function-utils";
import {
    type PropertyDescriptor,
    type PropertyTableGetter,
    EnumPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
    getTitlePropertyFromInlineListCaption,
    makeImageHeightPropertyHandler,
} from "@glide/function-utils";
import {
    type WireTableComponentHydratorConstructor,
    type WireScreen,
    type WireAction,
    WireComponentKind,
    type WireInflationBackend,
    type WireRowComponentHydrationBackend,
    type WireTableComponentHydrationBackend,
    CardStyle,
    UIAspect,
    UISize,
    UIImageStyle,
    UIOrientation,
} from "@glide/wire";
import { assertNever, defined } from "@glideapps/ts-necessities";

import { getActionsForArrayContent } from "../components/component-utils";
import { useFallbackInitialsPropertyHandler } from "../components/descriptor-utils";
import {
    type OverlayContentDescription,
    getOverlayPropertyHandlers,
    getOverlaySearchProperties,
    inflateOverlays,
} from "../overlay-utils";
import {
    type WireStringGetter,
    getAppArrayScreenEmptyMessage,
    inflateActionsWithCanAutoRun,
    inflateImageSource,
    makeSimpleWireTableComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import {
    allCapsPropertyHandler,
    cornersPropertyHandler,
    isHorizontalPropertyHandler,
    pushHorizontalAndTruncatePropertyHandlers,
    textSizePropertyHandler,
    truncateListPropertyHandler,
} from "./properties";
import {
    type FallbackInitialsSummaryArrayContentDescription,
    type GroupByGetters,
    type SummaryArrayScreenDescription,
    FallbackInitialsSummaryArrayScreenHandler,
    getNumberToTruncateTo,
    hydrateListItem,
    inflateSummary,
    makeAppListGroups,
} from "./summary-array-screen";
import { makeEmptyComponentDescription, ComponentKindInlineList } from "@glide/common-core";
import type { CardCollectionComponentDescription } from "@glide/fluent-components/dist/js/fluent-components";
import { definedMap } from "collection-utils";
import type { ComponentScreenContextForConversion } from "./array-content";

export interface TilesBaseArrayContentDescription
    extends FallbackInitialsSummaryArrayContentDescription,
        OverlayContentDescription {
    readonly size: PropertyDescription;
    readonly numColumns: PropertyDescription;
    readonly isHorizontal: PropertyDescription;
    readonly corners: PropertyDescription;
    readonly textSize: PropertyDescription;
    readonly allCaps: PropertyDescription;
}

export interface TilesArrayContentDescription extends TilesBaseArrayContentDescription {
    readonly padding: PropertyDescription;
    readonly allowWrapping: PropertyDescription;
    readonly textPosition: PropertyDescription;
    readonly overlayTextAlignment: PropertyDescription;
    readonly gravity: PropertyDescription | undefined;
}

interface TilesArrayScreenDescription extends SummaryArrayScreenDescription, TilesArrayContentDescription {
    readonly kind: ScreenDescriptionKind.Array;
    readonly format: ArrayScreenFormat.Tiles;
}

const defaultSize = ImageAspectRatio.ThreeByOne;
export const sizePropertyHandler = makeImageHeightPropertyHandler(defaultSize, false, true, true, "Tile shape");

const gravityPropertyHandler = new EnumPropertyHandler(
    { gravity: ImageGravity.Center },
    "Crop Behavior",
    "Crop Behavior",
    [
        {
            value: ImageGravity.Faces,
            label: "Faces",
            icon: "faceGravity",
        },
        {
            value: ImageGravity.Center,
            label: "Center",
            icon: "centerGravity",
        },
    ],
    PropertySection.Design,
    "small-images"
);

export const numberOfColumnsPropertyHandler = new EnumPropertyHandler(
    { numColumns: 1 },
    "Tiles per row",
    "Tiles per row",
    [
        {
            value: 1,
            label: "1",
        },
        {
            value: 2,
            label: "2",
        },
        {
            value: 3,
            label: "3",
        },
        { value: 4, label: "4" },
    ],
    PropertySection.Design,
    "slider"
);

const paddingPropertyHandler = new EnumPropertyHandler(
    { padding: TilesPadding.Loose },
    "Padding",
    "Padding",
    [
        {
            value: TilesPadding.Loose,
            label: "Loose",
            icon: "paddingLoose",
        },
        {
            value: TilesPadding.Tight,
            label: "Tight",
            icon: "paddingTight",
        },
    ],
    PropertySection.Design,
    "small-images"
);

const textPositionPropertyHandler = new EnumPropertyHandler(
    { textPosition: TextPosition.Below },
    "Position",
    "Position",
    [
        {
            value: TextPosition.Below,
            label: "Below",
            icon: "textPositionBelow",
        },
        {
            value: TextPosition.Overlay,
            label: "Overlay",
            icon: "textPositionOverlay",
        },
    ],
    PropertySection.TextStyle,
    "small-images"
);

const overlayTextAlignmentPropertyHandler = new EnumPropertyHandler(
    { overlayTextAlignment: OverlayTextAlignment.Center },
    "Alignment",
    "Alignment",
    [
        {
            value: OverlayTextAlignment.TopLeft,
            label: "Top Left",
            icon: "alignmentTopLeft",
        },
        {
            value: OverlayTextAlignment.BottomLeft,
            label: "Lower Left",
            icon: "alignmentBottomLeft",
        },
        {
            value: OverlayTextAlignment.BottomRight,
            label: "Lower Right",
            icon: "alignmentBottomRight",
        },
        {
            value: OverlayTextAlignment.TopRight,
            label: "Top Right",
            icon: "alignmentTopRight",
        },
        {
            value: OverlayTextAlignment.Center,
            label: "Center",
            icon: "alignmentCenter",
        },
    ],
    PropertySection.TextStyle,
    "small-images"
);

const allowWrappingPropertyHandler = new SwitchPropertyHandler(
    { allowWrapping: false },
    "Allow text wrapping",
    PropertySection.TextStyle
);

export function isHorizontal(desc: TilesBaseArrayContentDescription | undefined): boolean {
    return isHorizontalPropertyHandler.getEnum(desc);
}

function isCircleShape(desc: TilesArrayContentDescription | undefined): boolean {
    return sizePropertyHandler.getEnum(desc) === ImageAspectRatio.Circle;
}

function isOverlay(desc: TilesArrayContentDescription | undefined): boolean {
    return textPositionPropertyHandler.getEnum(desc) === TextPosition.Overlay;
}

function overlaysAllowed(desc: TilesArrayContentDescription | undefined): boolean {
    return !isCircleShape(desc) && numberOfColumnsPropertyHandler.getEnum(desc) < 3;
}

export function getPageAspectFromClassicSize(size: ImageAspectRatio): UIAspect {
    switch (size) {
        case ImageAspectRatio.Square:
            return UIAspect.Square;
        case ImageAspectRatio.ThreeByOne:
            return UIAspect.SixteenByNine;
        case ImageAspectRatio.ThreeByTwo:
            return UIAspect.ThreeByTwo;
        case ImageAspectRatio.Circle:
            return UIAspect.Square;
        case ImageAspectRatio.FourByThree:
            return UIAspect.FourByThree;

        case ImageAspectRatio.ThreeByFour:
            return UIAspect.Square;
        case ImageAspectRatio.TwoByThree:
            return UIAspect.Square;

        case ImageAspectRatio.Fit:
        case ImageAspectRatio.Expand:
            return UIAspect.SixteenByNine;

        default:
            assertNever(size);
    }
}

export function inflateTilesBaseArrayContent<TFormat extends ArrayScreenFormat, TItem extends WireTilesListItem>(
    ib: WireInflationBackend,
    format: TFormat,
    desc: TilesBaseArrayContentDescription,
    actions: readonly ActionDescription[],
    otherCaption: PropertyDescription | undefined,
    captionGetter: WireStringGetter | undefined,
    groupByGetters: GroupByGetters | undefined,
    componentID: string | undefined,
    isInlineList: boolean,
    amendItem: (rhb: WireRowComponentHydrationBackend, b: WireTilesListItem) => TItem
): (
    thb: WireTableComponentHydrationBackend,
    rhb: WireRowComponentHydrationBackend | undefined,
    searchActive: boolean
) =>
    | {
          component: WireAppTilesBaseListComponent<TFormat, TItem>;
          subsidiaryScreen: WireScreen | undefined;
          firstListItemActionToRun: WireAction | undefined;
      }
    | undefined {
    const {
        forBuilder,
        adc: { appKind },
    } = ib;
    const tableName = getTableName(ib.tables.input);

    const size = sizePropertyHandler.getEnum(desc);
    const numColumns = numberOfColumnsPropertyHandler.getEnum(desc);
    const corners = cornersPropertyHandler.getEnum(desc);
    const textSize = textSizePropertyHandler.getEnum(desc);
    const allCaps = allCapsPropertyHandler.getSwitch(desc);
    const horizontal = isHorizontal(desc);
    const textStyle = allCaps ? TextStyle.AllCaps : TextStyle.Normal;

    const summaryGetters = inflateSummary(ib, desc);
    const imageSourceGetter = inflateImageSource(ib, desc.imageKind, desc.imageURLProperty);

    const overlaysHydrator = inflateOverlays(ib, desc, otherCaption, false, corners, textSize);

    const numToTruncate = getNumberToTruncateTo(desc, isInlineList, numColumns, GroupingSupport.Regular);
    const useFallbackInitials = useFallbackInitialsPropertyHandler.getSwitch(desc);

    const { actionHydrator, canAutoRunAction } = inflateActionsWithCanAutoRun(ib, actions);

    return (thb, chb, searchActive) => {
        let subsidiaryScreen: WireScreen | undefined;
        let firstListItemActionToRun: WireAction | undefined;

        const groups = makeAppListGroups<TItem>(thb, groupByGetters, numToTruncate, tableName, rhb => {
            const overlays = overlaysHydrator(rhb);
            subsidiaryScreen = subsidiaryScreen ?? overlays.subsidiaryScreen;
            const item = amendItem(rhb, {
                ...hydrateListItem(rhb, summaryGetters, actionHydrator),
                // It's important that the key is the row ID because we use it
                // for the ##selectedMasterItemKey.
                key: defined(rhb.rowContext?.inputRows[0].$rowID),
                image: imageSourceGetter(rhb),
                overlays: overlays.overlays,
            });
            if (canAutoRunAction && firstListItemActionToRun === undefined) {
                firstListItemActionToRun = item.action;
            }
            return item;
        });
        if (groups === undefined) return undefined;

        const component: WireAppTilesBaseListComponent<TFormat, TItem> = {
            kind: WireComponentKind.List,
            ...spreadComponentID(componentID, forBuilder),
            format,
            title: captionGetter?.(defined(chb)) ?? "",
            emptyMessage: getAppArrayScreenEmptyMessage(searchActive, appKind),
            size,
            numColumns,
            corners,
            textSize,
            textStyle,
            horizontal,
            imageFallback: useFallbackInitials ? WireImageFallback.Title : WireImageFallback.None,
            groups,
        };
        return { component, subsidiaryScreen, firstListItemActionToRun };
    };
}

export function getPageSizeFromClassicNumColumns(numColumns: 1 | 2 | 3 | 4): UISize {
    switch (numColumns) {
        case 1:
            return UISize.Large;
        case 2:
            return UISize.Medium;
        case 3:
            return UISize.Small;
        case 4:
            return UISize.XSmall;
        default:
            assertNever(numColumns);
    }
}

export class TilesArrayScreenHandler extends FallbackInitialsSummaryArrayScreenHandler<
    TilesArrayContentDescription,
    TilesArrayScreenDescription
> {
    protected readonly supportsNonURLImages = true;
    protected readonly supportsEmojiImages = false;
    protected readonly fullTitleFallback = true;
    // We only support optional truncation when vertical
    protected readonly supportsTruncateList = false;

    constructor() {
        super(ArrayScreenFormat.Tiles, "Tiles", "columns", true);
    }

    protected titleRequired() {
        return false;
    }

    protected get groupingSupport(): GroupingSupport {
        return GroupingSupport.Regular;
    }

    protected getEnableFallbackInitials(desc: TilesArrayScreenDescription): boolean {
        return desc.titleProperty !== undefined;
    }

    public getAdditionalPropertyDescriptors<T extends TilesArrayContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        _insideInlineList: boolean,
        desc: T | undefined,
        isDefaultArrayScreen: boolean
    ): ReadonlyArray<PropertyDescriptor> {
        const descrs: PropertyDescriptor[] = [];
        descrs.push(sizePropertyHandler, numberOfColumnsPropertyHandler);
        pushHorizontalAndTruncatePropertyHandlers(descrs, desc, isDefaultArrayScreen, true);
        if (!isCircleShape(desc)) {
            descrs.push(textPositionPropertyHandler);
            descrs.push(cornersPropertyHandler);
        }

        if (isOverlay(desc) && !isCircleShape(desc)) {
            descrs.push(overlayTextAlignmentPropertyHandler);
        }
        descrs.push(textSizePropertyHandler);
        if (!isHorizontal(desc) && !isCircleShape(desc)) {
            descrs.push(paddingPropertyHandler);
        }
        descrs.push(gravityPropertyHandler);
        if (!isOverlay(desc) && !isHorizontal(desc)) {
            descrs.push(allowWrappingPropertyHandler);
        }
        descrs.push(allCapsPropertyHandler);

        if (!overlaysAllowed(desc)) {
            return descrs;
        }

        descrs.push(...getOverlayPropertyHandlers(getPropertyTable, undefined));

        return descrs;
    }

    public getBasicSearchProperties(desc: TilesArrayContentDescription): readonly string[] {
        return [...super.getBasicSearchProperties(desc), ...getOverlaySearchProperties(desc)];
    }

    public adjustContentDescriptionAfterUpdate(
        desc: TilesArrayContentDescription,
        updates: Partial<TilesArrayContentDescription & InlineListComponentDescription> | undefined
    ): TilesArrayContentDescription {
        if (updates?.isHorizontal !== undefined && isHorizontalPropertyHandler.getEnum(desc)) {
            desc = truncateListPropertyHandler.setInDescription(desc, truncateListPropertyHandler.defaultValue);
        }
        return desc;
    }

    public inflateContent<T extends TilesArrayContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        captionGetter: WireStringGetter | undefined,
        containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        const allowWrapping = allowWrappingPropertyHandler.getSwitch(desc);
        const gravity = gravityPropertyHandler.getEnum(desc);
        const padding = paddingPropertyHandler.getEnum(desc);
        const textPosition = textPositionPropertyHandler.getEnum(desc);
        const overlayTextAlignment = overlayTextAlignmentPropertyHandler.getEnum(desc);

        const hydrateList = inflateTilesBaseArrayContent(
            ib,
            ArrayScreenFormat.Tiles,
            desc,
            getActionsForArrayContent(this, ib.tables, desc, ib.adc).actions,
            undefined,
            captionGetter,
            this.makeGroupByGetters(ib, desc),
            componentID,
            containingRowIB !== undefined,
            (_rhb, base) => base
        );

        return makeSimpleWireTableComponentHydratorConstructor(ib, (thb, chb, searchActive) => {
            const base = hydrateList(thb, chb, searchActive);
            if (base === undefined) return undefined;

            const component: WireAppTilesListComponent = {
                ...base.component,
                allowWrapping,
                gravity,
                textPosition,
                padding,
                overlayTextAlignment,
            };
            return {
                component,
                isValid: true,
                subsidiaryScreen: base.subsidiaryScreen,
                firstListItemActionToRun: base.firstListItemActionToRun,
            };
        });
    }

    private convertContentToPage(desc: TilesArrayContentDescription) {
        const originalSize = sizePropertyHandler.getEnum(desc);
        const aspect = getPageAspectFromClassicSize(originalSize);
        const numColumns = numberOfColumnsPropertyHandler.getEnum(desc);
        const size = getPageSizeFromClassicNumColumns(numColumns);
        const imageStyle = originalSize === ImageAspectRatio.Circle ? UIImageStyle.Circle : UIImageStyle.Rectilinear;

        const classicIsHorizontal = isHorizontal(desc);
        const orientation = classicIsHorizontal ? UIOrientation.Horizontal : UIOrientation.Vertical;

        return {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            format: makeEnumProperty(ArrayScreenFormat.CardCollection),
            caption: undefined,
            cardStyle: makeEnumProperty(CardStyle.Cover),
            transforms: desc.transforms,
            action: definedMap(getActionProperty(desc.actions), a => makeActionProperty(a)),
            title: definedMap(getSourceColumnProperty(desc.titleProperty), sc => makeSourceColumnProperty(sc)),
            subtitle: definedMap(getSourceColumnProperty(desc.subtitleProperty), sc => makeSourceColumnProperty(sc)),
            image: definedMap(getSourceColumnProperty(desc.imageURLProperty), sc => makeSourceColumnProperty(sc)),
            groupByColumn: desc.groupByColumn,
            aspectRatio: makeEnumProperty(aspect),
            size: makeEnumProperty(size),
            imageStyle: makeEnumProperty(imageStyle),
            orientation: makeEnumProperty(orientation),
            emphasis: desc.tagOverlayText,
        };
    }

    public convertInlineToPage(
        desc: InlineListComponentDescription & TilesArrayContentDescription
    ): CardCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: desc.propertyName,
            allowSearch: desc.allowSearch,
            componentTitle: getTitlePropertyFromInlineListCaption(desc),
        };
    }

    public convertArrayScreenToPage(
        desc: ArrayScreenDescription & TilesArrayContentDescription,
        table: TableGlideType,
        adc: AppDescriptionContext,
        screenContext: ComponentScreenContextForConversion
    ): CardCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: makeTableProperty(getTableName(table)),
            allowSearch: makeSwitchProperty(getSwitchProperty(desc.search)),
            titleActions: definedMap(screenContext.titleAction, a => makeArrayProperty([a])),
            componentTitle: definedMap(screenContext.screenTitle, t => makeStringProperty(t)),
            multipleDynamicFilters: this.getDynamicMultipleFiltersForPageConversion(
                adc,
                table,
                desc.dynamicFilterColumn,
                desc.pivots
            ),
        };
    }
}
