import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import type { LoadedGroundValue } from "@glide/computation-model-types";
import { isBasePrimitiveValue, GlideDateTime } from "@glide/data-types";
import { type PropertyDescription, ActionKind } from "@glide/app-description";
import type { WebhookValues } from "@glide/common-core/dist/js/firebase-function-types";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    StringPropertyHandler,
    PropertySection,
} from "@glide/function-utils";
import { Result } from "@glide/plugins";
import type {
    WireActionResultBuilder,
    WireActionResult,
    WireActionBackend,
    WireActionInflationBackend,
} from "@glide/wire";
import { exceptionToString, hasOwnProperty } from "@glideapps/ts-necessities";
import { type KVPInOutActionDescription, type KVPWriteBackTo, KVPInOutActionHandler } from "./kvp-in-out-action";

interface YesCodeActionData {
    readonly code: string;
}

interface YesCodeActionDescription extends KVPInOutActionDescription {
    readonly code: PropertyDescription;
}

const codePropertyHandler = new StringPropertyHandler(
    "code",
    "Code",
    'return { result: "Yes!" };',
    true,
    undefined,
    PropertySection.Data,
    true,
    "javascript"
);

export class YesCodeActionHandler extends KVPInOutActionHandler<YesCodeActionDescription, YesCodeActionData> {
    public readonly kind = ActionKind.YesCode;
    public readonly iconName = "01-47-flash";

    protected readonly actionName = "Run script";

    protected getIsLegacy(ccc: AppDescriptionContext): boolean {
        return !ccc.userFeatures.yesCodeAction;
    }

    protected getPropertyDescriptors(): readonly PropertyDescriptor[] {
        return [codePropertyHandler];
    }

    protected inflateData(
        _ib: WireActionInflationBackend,
        desc: YesCodeActionDescription,
        arb: WireActionResultBuilder
    ): YesCodeActionData | WireActionResult {
        const code = codePropertyHandler.getString(desc);
        if (code === undefined) return arb.inflationError("No code provided.");

        return { code };
    }

    protected async runAction(
        ab: WireActionBackend,
        _appID: string,
        _appFacilities: ActionAppFacilities,
        params: WebhookValues,
        writeBackTo: KVPWriteBackTo | undefined,
        _awaitSend: boolean,
        data: YesCodeActionData,
        arb: WireActionResultBuilder
    ): Promise<WireActionResult> {
        const lines: string[] = [];
        lines.push("(async function () {");
        for (const [name, value] of Object.entries(params)) {
            lines.push(`const ${name} = ${JSON.stringify(value.value)};`);
        }
        lines.push(data.code);
        lines.push("})()");

        let result: unknown;
        try {
            result = await eval(lines.join("\n"));
        } catch (e: unknown) {
            const error = exceptionToString(e);
            ab.actionCallbacks.showToast(false, `Error running action: ${error}`);
            return arb.fromException(true, e);
        }

        arb = arb.addData({ result });

        if (writeBackTo === undefined) {
            return arb.success();
        }

        let finalResult: Result = Result.Ok();
        for (const [name, { tableName, outputRow, columnName }] of Object.entries(writeBackTo.results)) {
            if (!hasOwnProperty(result, name)) continue;
            const untypedValue = result[name];
            let value: LoadedGroundValue;
            if (untypedValue instanceof Date) {
                value = GlideDateTime.fromTimeZoneAwareDate(untypedValue);
            } else if (isBasePrimitiveValue(untypedValue)) {
                value = untypedValue;
            } else {
                continue;
            }
            const setResult = await ab.setColumnsInRow(
                tableName,
                outputRow,
                { [columnName]: value },
                false,
                undefined,
                undefined
            );
            if (!setResult.ok) {
                finalResult = setResult;
            }
        }

        return arb.fromResult(finalResult);
    }
}
