import type { AppKind } from "@glide/location-common";
import type {
    ActionAppFacilities,
    MinimalAppEnvironment,
    QuotaBannerRequirements,
} from "@glide/common-core/dist/js/components/types";
import {
    type TableName,
    type Description,
    type TableAndColumn,
    type TableColumn,
    type TableGlideType,
    getTableColumnDisplayName,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    PropertyKind,
    getSourceColumnProperty,
    getStringProperty,
    makeEnumProperty,
    makeStringProperty,
} from "@glide/app-description";
import {
    type InputOutputTables,
    getInputOrOutputTable,
    makeEmptyComponentDescription,
} from "@glide/common-core/dist/js/description";
import { Mood } from "@glide/common";
import type { WireHintDescription } from "@glide/fluent-components/dist/js/fluent-components";
import {
    type ActionPropertyDescriptor,
    type AllowedDataEdits,
    type AppDescriptionContext,
    type ColumnPropertyDescriptorCase,
    type ComponentDescriptor,
    type ComponentErrorAndLink,
    type ComponentPrerequisite,
    type ComponentSpecialCaseDescriptor,
    type EditedColumnsAndTables,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    type RewritingComponentConfiguratorContext,
    type StringPropertyDescriptorCase,
    combineEditedColumnsAndTables,
    convertEditedColumnsToIndirect,
    findCaptionDescriptor,
    getColumnForSourceColumn,
    getPropertyDescription,
    PropertySection,
    StringPropertyHandler,
    visibilityPropertyDescriptor,
} from "@glide/function-utils";
import type { BillablesConsumed, PluginTierList } from "@glide/plugins";
import { defined, panic, definedMap } from "@glideapps/ts-necessities";
import { checkString, mapFilterUndefinedAsync, updateDeleteUndefined } from "@glide/support";
import { WireComponentKind, type WireRowComponentHydratorConstructor, type WireInflationBackend } from "@glide/wire";
import entries from "lodash/entries";

import { handlerForActionKind } from "../actions";
import { getPropertyDescriptorCaseForKind } from "../description-utils";
import { duplicateComponent, duplicateDescription } from "../handlers";
import type { ComponentEasyTabConfiguration, ComponentHandler, ScreenContext } from "./component-handler";
import { getActionsForComponent } from "./component-utils";
import { PopulationMode } from "./description-handlers";
import { getColumnsUsedInDescription, getEditedColumnsForProperties } from "./descriptor-utils";
import { populateDescription } from "./populate-description";
import type { StaticActionContext } from "../static-context";

export const notesPropertyDescriptor: PropertyDescriptor = new StringPropertyHandler(
    "notes",
    "Notes",
    "Add notes about this component",
    false,
    undefined,
    PropertySection.Notes,
    true,
    undefined,
    true,
    (_desc, _rootDesc, _tables, ccc) => ccc.userFeatures.builderNotes === true
);

export abstract class ComponentHandlerBase<T extends ComponentDescription> implements ComponentHandler<T> {
    public abstract readonly appKinds: AppKind | "both";

    constructor(public readonly kind: ComponentKind) {}

    public getTier(_appKind: AppKind): PluginTierList | undefined {
        return undefined;
    }

    public getBillablesConsumed(_env: StaticActionContext<AppDescriptionContext>): BillablesConsumed | undefined {
        return undefined;
    }

    protected getBasePropertyDescriptors(): PropertyDescriptor[] {
        return [visibilityPropertyDescriptor, notesPropertyDescriptor];
    }

    // `tables` might be `undefined` in cases where we want to find the
    // caption or action descriptors.
    public abstract getDescriptor(
        desc: T | undefined,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined,
        screenContext?: ScreenContext,
        appEnvironment?: MinimalAppEnvironment
    ): ComponentDescriptor;

    public getActionDescriptors(
        _desc: T | undefined,
        _tables: InputOutputTables | undefined,
        _schema: SchemaInspector | undefined,
        _mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly ActionPropertyDescriptor[] {
        return [];
    }

    public getPropertyAndActionDescriptors(
        desc: T | undefined,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined,
        screenContext?: ScreenContext,
        appEnvironment?: MinimalAppEnvironment
    ): readonly PropertyDescriptor[] {
        return [
            ...this.getDescriptor(
                desc,
                tables,
                ccc,
                mutatingScreenKind,
                forEasyTabConfiguration,
                isFirstComponent,
                screenContext,
                appEnvironment
            ).properties,
            ...this.getActionDescriptors(desc, tables, ccc, mutatingScreenKind),
        ];
    }

    public lowerDescriptionForBuilding(
        desc: T,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isFirstComponent: boolean | undefined,
        _forGC: boolean,
        screenContext?: ScreenContext,
        appEnvironment?: MinimalAppEnvironment
    ): [desc: T, descr: ComponentDescriptor] {
        const descr = this.getDescriptor(
            desc,
            tables,
            ccc,
            mutatingScreenKind,
            false,
            isFirstComponent,
            screenContext,
            appEnvironment
        );
        return [desc, descr];
    }

    public getSpecialCaseDescriptors(_ccc: AppDescriptionContext): readonly ComponentSpecialCaseDescriptor[] {
        return [];
    }

    public get needsColumns(): boolean {
        return true;
    }

    public get isNonEmptyValidationStopper(): boolean {
        return false;
    }

    public get prerequisites(): readonly ComponentPrerequisite[] {
        return [];
    }

    public get isGlideManaged(): boolean {
        return false;
    }

    public getSubComponents(_desc: T): readonly ComponentDescription[] | undefined {
        return undefined;
    }

    public getSubComponentTables(
        _desc: T,
        _containingTables: InputOutputTables,
        _schema: SchemaInspector,
        _mutatingScreenKind: MutatingScreenKind | undefined
    ): [InputOutputTables, MutatingScreenKind | undefined] | undefined {
        return undefined;
    }

    public getCaption(desc: T, tables: InputOutputTables | undefined, ccc: AppDescriptionContext): string | undefined {
        const pd = findCaptionDescriptor(this.getDescriptor(desc, tables, ccc, undefined, false, undefined).properties);
        if (pd === undefined) {
            return undefined;
        }
        const propDesc = getPropertyDescription(desc, pd);
        if (propDesc === undefined || propDesc.kind !== PropertyKind.String) return undefined;
        return checkString(propDesc.value);
    }

    public getIsEditor(
        _desc: T | undefined,
        _ccc: AppDescriptionContext,
        _specialCaseDescriptor: ComponentSpecialCaseDescriptor | undefined
    ): boolean {
        return false;
    }

    public getDescriptiveName(
        desc: T,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext
    ): [string, string] {
        const descriptor = this.getDescriptor(desc, tables, ccc, undefined, false, undefined);

        for (const needRequired of [true, false]) {
            for (const pd of descriptor.properties) {
                const maybeColumnCase = getPropertyDescriptorCaseForKind(PropertyKind.Column, pd);
                if (maybeColumnCase !== undefined) {
                    const columnCase = maybeColumnCase as ColumnPropertyDescriptorCase;
                    if (columnCase.required || !needRequired) {
                        const columnProperty = getSourceColumnProperty(getPropertyDescription(desc, pd));
                        if (columnProperty !== undefined) {
                            if (tables === undefined) return [descriptor.name, ""];

                            const table = getInputOrOutputTable(tables, columnCase.isEditedInApp === true);
                            const column = getColumnForSourceColumn(ccc, columnProperty, table, undefined, []);
                            return [descriptor.name, definedMap(column, getTableColumnDisplayName) ?? ""];
                        }
                    }
                }

                const maybeStringCase = getPropertyDescriptorCaseForKind(PropertyKind.String, pd);
                if (maybeStringCase !== undefined) {
                    const stringCase = maybeStringCase as StringPropertyDescriptorCase;
                    if (stringCase.required || !needRequired) {
                        const stringProperty = getStringProperty(getPropertyDescription(desc, pd));
                        if (stringProperty !== undefined) {
                            return [descriptor.name, stringProperty];
                        }
                    }
                }
            }
        }

        return [descriptor.name, ""];
    }

    public getComponentID(desc: T): string | undefined {
        return desc.componentID;
    }

    public getColumnsUsed(desc: T, tables: InputOutputTables, ccc: AppDescriptionContext): ReadonlyArray<TableColumn> {
        return Array.from(
            getColumnsUsedInDescription(
                this.getDescriptor(desc, tables, ccc, undefined, false, undefined).properties,
                desc,
                desc,
                tables,
                ccc,
                []
            ).direct
        );
    }

    public getAdditionalColumnsRead(_tables: InputOutputTables): readonly TableAndColumn[] {
        return [];
    }

    public getAdditionalTablesUsed(
        _desc: T,
        _schema: SchemaInspector,
        _tables: InputOutputTables | undefined
    ): readonly TableName[] {
        return [];
    }

    public getScreensUsed(
        _desc: T,
        _schema: SchemaInspector,
        _tables: InputOutputTables | undefined
    ): readonly string[] {
        return [];
    }

    public getTablesForActions(
        tables: InputOutputTables,
        _desc: T,
        _schema: SchemaInspector
    ): [InputOutputTables, boolean] | undefined {
        return [tables, true];
    }

    public getEditedColumns(
        desc: T,
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        withActions: boolean
    ): EditedColumnsAndTables {
        const descr = this.getDescriptor(desc, tables, ccc, mutatingScreenKind, false, undefined);

        let editedColumns = getEditedColumnsForProperties(descr.properties, desc, desc, withActions, {
            tables,
            mutatingScreenKind,
            context: ccc,
            isAutomation: false,
        });

        // I'd like to get rid of this.  It seems we're only using it in the
        // old component model builder.
        if (withActions) {
            const maybeTablesForActions = this.getTablesForActions(tables, desc, ccc);
            if (maybeTablesForActions !== undefined) {
                const [tablesForActions, isDirect] = maybeTablesForActions;
                const actionsRecord = getActionsForComponent(this, desc, tables, ccc, mutatingScreenKind);
                for (const [, actions] of entries(actionsRecord)) {
                    for (const action of actions) {
                        const handler = handlerForActionKind(action.kind);
                        let actionEdited = handler.getEditedColumns(action, {
                            tables: tablesForActions,
                            context: ccc,
                            mutatingScreenKind,
                            isAutomation: false,
                        });
                        if (!isDirect) {
                            actionEdited = {
                                editedColumns: convertEditedColumnsToIndirect(actionEdited?.editedColumns ?? []),
                                deletedTables: actionEdited?.deletedTables ?? [],
                            };
                        }

                        editedColumns = combineEditedColumnsAndTables(editedColumns, actionEdited);
                    }
                }
            }
        }

        return editedColumns;
    }

    public getAdditionalDataEdits(
        _desc: T,
        _tables: InputOutputTables,
        _ccc: AppDescriptionContext,
        _mutatingScreenKind: MutatingScreenKind | undefined
    ): AllowedDataEdits | undefined {
        return undefined;
    }

    public needValidation(_desc: T): boolean {
        return false;
    }

    public isSearchable(_desc: T): boolean {
        return false;
    }

    public getQuotaBannerRequirements(_desc: T): QuotaBannerRequirements {
        return { needListQuota: false, needMapQuota: false };
    }

    public rewriteAfterReload(
        desc: T,
        tables: InputOutputTables,
        ccc: RewritingComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isRewrite: boolean
    ): T | undefined {
        const newDesc = populateDescription(
            d => this.getPropertyAndActionDescriptors(d, tables, ccc, undefined, false, undefined),
            PopulationMode.Rewrite,
            desc,
            desc,
            tables,
            ccc,
            isRewrite,
            mutatingScreenKind
        );

        return newDesc;
    }

    protected makeEmptyDescription(): T {
        return makeEmptyComponentDescription(this.kind) as T;
    }

    public newComponent(
        tables: InputOutputTables,
        usedColumns: ReadonlySet<TableColumn>,
        editedColumns: ReadonlySet<TableColumn>,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): T | undefined {
        let desc: T | undefined = this.makeEmptyDescription();

        desc = populateDescription(
            d => this.getPropertyAndActionDescriptors(d, tables, iccc, mutatingScreenKind, false, undefined),
            PopulationMode.Default,
            desc,
            desc,
            tables,
            iccc,
            false,
            mutatingScreenKind,
            usedColumns,
            editedColumns,
            undefined,
            true
        );
        return desc;
    }

    public newSpecialCaseComponent(
        _specialCaseDescriptor: ComponentSpecialCaseDescriptor,
        _tables: InputOutputTables,
        _usedColumns: ReadonlySet<TableColumn>,
        _editedColumns: ReadonlySet<TableColumn>,
        _ccc: AppDescriptionContext,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _insideContainer: boolean,
        _onTopLevelScreen: boolean
    ): T | undefined {
        return panic("Please implement special case component");
    }

    public updateComponent(
        desc: T,
        updates: Partial<T>,
        tables: InputOutputTables | undefined,
        ccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): T {
        const newDesc = updateDeleteUndefined(desc, updates);
        if (tables === undefined) {
            return newDesc;
        }
        return defined(
            populateDescription(
                d => this.getPropertyAndActionDescriptors(d, tables, ccc, mutatingScreenKind, false, undefined),
                PopulationMode.Rewrite,
                newDesc,
                newDesc,
                tables,
                ccc,
                true,
                mutatingScreenKind,
                undefined,
                undefined
            )
        );
    }

    protected duplicateComponentBase(desc: T): T {
        return {
            ...desc,
            ...makeEmptyComponentDescription(desc.kind),
            visibilityFilters: desc.visibilityFilters,
            builderDisplayName: desc.builderDisplayName,
        };
    }

    public async duplicateComponent(
        desc: T,
        rootDesc: Description,
        copyTables: InputOutputTables,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<T> {
        const base = await duplicateDescription(
            this.duplicateComponentBase(desc),
            rootDesc,
            this.getPropertyAndActionDescriptors(desc, copyTables, iccc, mutatingScreenKind, false, undefined),
            copyTables,
            iccc,
            mutatingScreenKind,
            screensCreated,
            appFacilities
        );

        const oldComponents = this.getSubComponents(desc);
        if (oldComponents === undefined) {
            return base;
        }

        const subComponentTables = this.getSubComponentTables(desc, copyTables, iccc, mutatingScreenKind);
        if (subComponentTables === undefined) {
            return { ...base, components: [] };
        }

        const [subTables, subMutatingScreenKind] = subComponentTables;
        const components = await mapFilterUndefinedAsync(oldComponents, c =>
            duplicateComponent(c, subTables, iccc, subMutatingScreenKind, screensCreated, appFacilities)
        );

        return { ...base, components };
    }

    public getErrorAndLink(_desc: T, _table: TableGlideType): ComponentErrorAndLink | undefined {
        return undefined;
    }

    public getEasyTabConfiguration(_desc: T): ComponentEasyTabConfiguration | undefined {
        return undefined;
    }

    // TODO: I'm not using this as the implementation for `convertToPage`
    // because I want to make it easier to find missing conversions.
    protected defaultConvertToPage(desc: T, ccc: AppDescriptionContext): ComponentDescription {
        const descr = this.getDescriptor(desc, undefined, ccc, undefined, false, undefined);
        const hint: WireHintDescription = {
            ...makeEmptyComponentDescription(WireComponentKind.Hint),
            description: makeStringProperty(`This should be a ${descr.name} component.`),
            mood: makeEnumProperty(Mood.Danger),
        };
        return hint;
    }

    public abstract convertToPage(desc: T, ccc: AppDescriptionContext): ComponentDescription | undefined;

    public inflate?(ib: WireInflationBackend, desc: T): WireRowComponentHydratorConstructor | undefined;
}
