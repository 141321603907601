import {
    type RowIndex,
    type BaseRowIndex,
    isBaseRowIndex,
    type Row,
    isPrimitiveValue,
} from "@glide/computation-model-types";
import { nativeTableRowIDColumnName, rowIndexColumnName, type TableGlideType } from "@glide/type-schema";
import { getRowColumn } from "./data";

// We're not making a row index for invisible rows, because right now it's
// only used for setting columns, and that doesn't work on invisible rows
// (yet).
export function getRowIndexForRow(
    table: TableGlideType,
    primaryKeyColumnName: string | undefined,
    row: Row
): RowIndex | undefined {
    if (primaryKeyColumnName !== undefined) {
        const value = row[primaryKeyColumnName];
        if (isPrimitiveValue(value) && value !== undefined) {
            if (!row.$isVisible) return undefined;
            return {
                keyColumnName: primaryKeyColumnName,
                keyColumnValue: value,
            };
        }
    }

    const maybeRowIndex = getRowColumn(row, rowIndexColumnName);
    let rowIndexHint: BaseRowIndex | undefined;
    if (isBaseRowIndex(maybeRowIndex)) {
        rowIndexHint = maybeRowIndex;
    }

    if (table.rowIDColumn !== undefined && row.$backendRowID !== undefined) {
        // This is the one case where we don't care whether we're not allowed
        // because we're invisible, since we have a row ID that's known to the
        // backend.
        return { keyColumnName: table.rowIDColumn, keyColumnValue: row.$backendRowID, rowIndexHint };
    }

    if (!row.$isVisible) return undefined;

    if (table.rowIDColumn !== undefined) {
        const rowID = getRowColumn(row, table.rowIDColumn);
        if (typeof rowID !== "string") return;

        return { keyColumnName: table.rowIDColumn, keyColumnValue: rowID, rowIndexHint };
    } else if (rowIndexHint !== undefined) {
        return rowIndexHint;
    } else {
        return undefined;
    }
}

export function decomposeNativeTableRowIndex(
    rowIndexOrHint: RowIndex
): { keyColumnName: string; keyColumnValue: string } | undefined {
    let keyColumnName: string;
    let keyColumnValue: unknown;
    if (isBaseRowIndex(rowIndexOrHint)) {
        keyColumnName = rowIndexColumnName;
        keyColumnValue = rowIndexOrHint;
    } else {
        keyColumnName = rowIndexOrHint.keyColumnName;
        keyColumnValue = rowIndexOrHint.keyColumnValue;
    }

    if (
        (keyColumnName !== rowIndexColumnName && keyColumnName !== nativeTableRowIDColumnName) ||
        typeof keyColumnValue !== "string"
    ) {
        return undefined;
    }

    return { keyColumnName, keyColumnValue };
}

// NOTE: Only use this for native tables, because other data sources might use
// a different row ID column.
export function getNativeTableRowIDForRowIndex(rowIndexOrHint: RowIndex): string | undefined {
    const decomposed = decomposeNativeTableRowIndex(rowIndexOrHint);
    if (decomposed?.keyColumnName !== nativeTableRowIDColumnName) return undefined;
    return decomposed.keyColumnValue;
}

export function getRowIDForRowIndex(table: TableGlideType, rowIndexOrHint: RowIndex): string | undefined {
    if (isBaseRowIndex(rowIndexOrHint)) return undefined;
    if (
        rowIndexOrHint.keyColumnName === table.rowIDColumn ||
        rowIndexOrHint.keyColumnName === nativeTableRowIDColumnName
    ) {
        if (typeof rowIndexOrHint.keyColumnValue === "string") {
            return rowIndexOrHint.keyColumnValue;
        }
    }
    return undefined;
}
