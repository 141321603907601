import styled, { css } from "styled-components";
import { BUILDER_MAIN_HFLOW_INDEX } from "./z-index";
import { browserIsSafari } from "@glide/common";

export const Header = styled.h1`
    font-family: ${p => p.theme.headerFontFamily};
    font-weight: ${p => p.theme.headerWeight};
    font-size: ${p => p.theme.headerFontSize};
    color: ${p => p.theme.fgColorDark};

    margin: 0;
`;

export const Label = styled.div`
    font-family: ${p => p.theme.labelFontFamily};
    font-weight: ${p => p.theme.labelFontWeight};
    font-size: ${p => p.theme.labelFontSize};
    color: ${p => p.theme.fgColorMedium};
`;

export const Link = styled.a`
    font-family: ${p => p.theme.labelFontFamily};
    font-size: ${p => p.theme.labelFontSize};
    color: ${p => p.theme.blue};
    text-decoration: underline !important;
`;

export const CompatibilityWrapper = styled.div`
    display: flex;
    flex-direction: column;

    max-height: 400px;
    overflow-y: auto;

    color: ${p => p.theme.fgColorDark};
    font-size: 14px;

    > *:not(:last-child) {
        margin-bottom: 10px;
    }

    .cw-problem-list {
        list-style-position: inside;
        padding: 10px;
        background-color: ${p => p.theme.bgColorAlt};
        border-radius: 5px;
    }
`;

const sharedOverlayScrollbarStyle = css`
    color: rgba(0, 0, 0, 0);
    transition: color 0.15s;
    transition-delay: 0.2s;

    :hover {
        color: rgba(0, 0, 0, 0.3);
    }

    > * {
        color: ${p => p.theme.textDark};
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }
`;

export const overlayYScrollbar = browserIsSafari
    ? css`
          overflow-y: auto;
      `
    : css`
          overflow-y: auto;
          overflow-y: overlay;
          ${sharedOverlayScrollbarStyle}
          ::-webkit-scrollbar-thumb {
              border: solid 2px transparent;
              box-shadow: inset 0 0 10px 10px;
              border-radius: 100px;
              margin: 2px;

              :hover {
                  box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 0.5) !important;
              }
          }

          ::-webkit-scrollbar {
              width: 10px;
          }

          ::-webkit-scrollbar-track {
              background: transparent;
          }
      `;

export const AppWrapper = styled.div`
    display: flex;
    align-items: stretch;
    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${p => p.theme.bgColorAlt};

    .hflow {
        z-index: ${BUILDER_MAIN_HFLOW_INDEX};
        display: flex;
        align-items: stretch;
        position: relative;

        overflow: hidden;
        flex-grow: 1;
    }

    --tint-color: ${p => p.theme.n800};
    --tint-color-bg: ${p => p.theme.n200A};

    &.tabs,
    &.actions,
    &.layout {
        --tint-color-text: ${p => p.theme.b500};
        --tint-color: ${p => p.theme.b400};
        --tint-color-bg: ${p => p.theme.b100};
    }

    &.actions {
        --tint-color-text: ${p => p.theme.y500};
        --tint-color: ${p => p.theme.y400};
        --tint-color-bg: ${p => p.theme.y100};
    }

    &.data {
        --tint-color-text: ${p => p.theme.g500};
        --tint-color: ${p => p.theme.g400};
        --tint-color-bg: ${p => p.theme.g100};
    }
`;
