import type { WireInlineScannerComponent } from "@glide/fluent-components/dist/js/base-components";
import type { InlineScanner } from "@glide/fluent-components/dist/js/fluent-components";
import { getActionProperty } from "@glide/app-description";
import { type WireAlwaysEditableValue, WireComponentKind } from "@glide/wire";
import { definedMap } from "collection-utils";

import {
    hydrateSubAction,
    inflateActions,
    inflateComponentEnricher,
    makeSimpleWireRowComponentHydratorConstructor,
} from "../wire/utils";
import type { ComponentInflator } from "./fluent-components-handlers";

export const inlineScannerInflator: ComponentInflator<typeof InlineScanner> = (ib, desc) => {
    const scanAction = getActionProperty(desc.action);
    const onScanHydrator = definedMap(scanAction, a => inflateActions(ib, [a]));

    const { tokenMaker, tableAndColumn } = ib.getValueSetterForProperty(desc.writeTo, "set-scanned-column");
    if (tableAndColumn === undefined) return undefined;

    return makeSimpleWireRowComponentHydratorConstructor(hb => {
        const componentEnricher = inflateComponentEnricher<WireInlineScannerComponent>(ib, desc);

        const onChangeToken = tokenMaker(hb, async ab => {
            if (onScanHydrator === undefined) {
                return;
            }

            const runner = await hydrateSubAction(ab, onScanHydrator);
            await ab.invoke("code scanned", runner, false);

            return;
        });

        if (onChangeToken === false || onChangeToken === undefined) {
            return undefined;
        }

        const editableScan: WireAlwaysEditableValue<string> = {
            // We don't care what the value is, we just want to set a new one.
            value: "dummy-we-dont-need-this",
            onChangeToken,
        };

        return {
            component: componentEnricher({
                kind: WireComponentKind.InlineScanner,
                editableScan,
            }),
            isValid: true,
        };
    });
};
