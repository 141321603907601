import classNames from "classnames";
import { panic } from "@glideapps/ts-necessities";

import { getLocalizedString, getAnyLocalizedString } from "@glide/localization";
import { type IconImage, AppAuthenticationKind } from "@glide/app-description";
import { AppIcon } from "@glide/common";
import { type AuthControllerProps, type PagesLoginSource, useAuthController } from "@glide/auth-controller-core";
import { CloseContainer } from "@glide/common-components";
import { EmailPinStage, SignInStage, UsernameStage, RequestAccessStage } from "@glide/wire-renderer";

interface Props extends AuthControllerProps {
    readonly appTitle?: string;
    readonly customCssClassName?: string;
    readonly iconImage?: IconImage;

    readonly onPageSourceChanged?: (newPageSource: PagesLoginSource) => void;

    readonly removeBranding: boolean; // from EminenceFlags ideally
    readonly canCustomizeSignIn: boolean; // from EminenceFlags ideally
}
export const AuthController: React.VFC<Props> = p => {
    const {
        appTitle,
        onPageSourceChanged,
        authKind,
        removeBranding,
        canCustomizeSignIn,
        iconImage,
        customCssClassName,
        appID,
    } = p;
    const authProps = useAuthController(p);

    const {
        allowSaveLogin,
        appFeatures,
        appKind,
        authMethod,
        canContinue,
        canPressPin,
        canRequestAccess,
        currentState,
        error,
        isSending,
        onClose,
        onEmailChange,
        onEmailPressedContinue,
        onPinChange,
        onPressedBack,
        onPressedPin,
        onRequestAccessPressedContinue,
        onUserAgreedChange,
        onUsernamePressedContinue,
        onUserUpdateSaveLogin,
        pagesSource,
        pin,
        userAgreed,
        userEmail,
    } = authProps;

    let content: React.ReactNode;

    if (currentState === "username") {
        content = (
            <UsernameStage
                allowSaveLogin={allowSaveLogin}
                appFeatures={appFeatures}
                appTitle={appTitle}
                authMethod={authMethod}
                canContinue={canContinue}
                canCustomizeSignIn={canCustomizeSignIn}
                error={error}
                greeting={getLocalizedString("whatsYourName", appKind)}
                iconImage={iconImage}
                isSending={isSending}
                onEmailChange={onEmailChange}
                onPageSourceChanged={onPageSourceChanged}
                onPressedContinue={onUsernamePressedContinue}
                onUserAgreedChange={onUserAgreedChange}
                onUserUpdateSaveLogin={onUserUpdateSaveLogin}
                pagesSource={pagesSource}
                removeBranding={removeBranding}
                userAgreed={userAgreed}
                userEmail={userEmail}
                appID={appID}
            />
        );
    } else if (
        canRequestAccess &&
        (currentState === "request-access-prompt" || currentState === "request-access-complete")
    ) {
        content = (
            <RequestAccessStage
                pagesSource={pagesSource}
                onPageSourceChanged={onPageSourceChanged}
                greeting={
                    currentState === "request-access-prompt"
                        ? getAnyLocalizedString("requestAppUserAccessHeader", appKind)
                        : getAnyLocalizedString("requestSent", appKind)
                }
                description={
                    currentState === "request-access-prompt"
                        ? getAnyLocalizedString("requestAppUserAccessDescription", appKind)
                        : getAnyLocalizedString("requestCompleteAppUserAccessDescription", appKind)
                }
                onPressedContinue={onRequestAccessPressedContinue}
                onPressedBack={onPressedBack}
                appFeatures={appFeatures}
                canCustomizeSignIn={canCustomizeSignIn}
                isSending={isSending}
                removeBranding={removeBranding}
                userEmail={userEmail}
                currentState={currentState}
                iconImage={iconImage}
                appID={appID}
            />
        );
    } else if (currentState === "sign-in") {
        content = <SignInStage />;
    } else if (currentState === "challenge" && authKind === AppAuthenticationKind.EmailPin) {
        content = (
            <EmailPinStage
                appFeatures={appFeatures}
                canContinue={canContinue}
                canCustomizeSignIn={canCustomizeSignIn}
                canRequestAnotherPin={canPressPin}
                error={error}
                iconImage={iconImage}
                isSending={isSending}
                onPinChange={onPinChange}
                onPressedContinue={onEmailPressedContinue}
                onPressedPin={onPressedPin}
                pin={pin}
                removeBranding={removeBranding}
                userAgreed={userAgreed}
                userEmail={userEmail}
                appID={appID}
            />
        );
    } else {
        panic("Hell froze over");
    }

    return (
        <div className={classNames(customCssClassName, "page-controller")} tw="w-full">
            {content}
            {onClose !== undefined && (
                <CloseContainer onClick={onClose} role="button" aria-label="close">
                    <AppIcon icon="00-01-glide-close" />
                </CloseContainer>
            )}
        </div>
    );
};
