// NOTE: This is part of the lower level of the New Computation Model, and
// should be kept isolated from non-trivial Glide dependencies.  In
// particular, it shouldn't have to know anything about Glide table/column
// types/schemas.

import { assert, assertNever, defined, hasOwnProperty } from "@glideapps/ts-necessities";
import { isArray } from "@glide/support";

interface MutableRootPath {
    isRoot: true;
    rest: KeyPath;
}
export type RootPath = Readonly<MutableRootPath>;

interface MutableKeyPath {
    key: string;
    rest: RelativePathRest;
}
export type KeyPath = Readonly<MutableKeyPath>;

interface MutableIndexPath {
    index: number;
    rest: RelativePathRest;
}
type IndexPath = Readonly<MutableIndexPath>;

interface MutableIDPath {
    id: string;
    rest: RelativePathRest;
}
type IDPath = Readonly<MutableIDPath>;

interface MutableColumnPath {
    column: string;
    rest: RelativePathRest;
}
export type ColumnPath = Readonly<MutableColumnPath>;

export type MutableRelativePath = MutableKeyPath | MutableIndexPath | MutableIDPath | MutableColumnPath;
type MutablePath = MutableRootPath | MutableRelativePath;

export type RelativePath = KeyPath | IndexPath | IDPath | ColumnPath;
export type RelativePathRest = RelativePath | undefined;
export type Path = RootPath | RelativePath;

type PathPartSpec = string | number | [string] | { readonly c: string };

// [[value path, root path for value], [format path, root path for format]]
export type ValueAndFormatPaths = [[Path, RootPath | undefined], [Path | undefined, RootPath | undefined]];

export function isRootPath(p: Path): p is RootPath {
    return hasOwnProperty(p, "isRoot");
}

export function isKeyPath(p: Path): p is KeyPath {
    return hasOwnProperty(p, "key");
}

export function isIndexPath(p: Path): p is IndexPath {
    return hasOwnProperty(p, "index");
}

export function isIDPath(p: Path): p is IDPath {
    return hasOwnProperty(p, "id");
}

export function isColumnPath(p: Path): p is ColumnPath {
    return hasOwnProperty(p, "column");
}

export function isTopLevelPath(p: Path): p is RootPath {
    return isRootPath(p) && p.rest.rest === undefined;
}

export function getSymbolicRepresentationForPath(p: Path | undefined): string {
    if (p === undefined) {
        return "[.]";
    }

    let isRoot: boolean;
    const parts: string[] = [];

    if (isRootPath(p)) {
        isRoot = true;
        p = p.rest;
    } else {
        isRoot = false;
    }

    for (;;) {
        if (isKeyPath(p)) {
            parts.push(p.key);
        } else if (isIndexPath(p)) {
            parts.push(p.index.toString());
        } else if (isIDPath(p)) {
            parts.push("id:" + p.id);
        } else if (isColumnPath(p)) {
            parts.push("column:" + p.column);
        } else {
            return assertNever(p);
        }
        if (p.rest === undefined) break;
        p = p.rest;
    }

    if (isRoot) {
        return "[/" + parts.join("/") + "]";
    } else if (parts.length === 0) {
        return "[.]";
    } else {
        return "[" + parts.join("/") + "]";
    }
}

export function makeKeyPath(key: string): KeyPath {
    return { key, rest: undefined };
}

export function makeColumnPath(column: string): ColumnPath {
    return { column, rest: undefined };
}

function makeMutablePathFromPartSpec(key: PathPartSpec): MutableRelativePath {
    if (typeof key === "string") {
        return makeKeyPath(key);
    } else if (typeof key === "number") {
        return { index: key, rest: undefined };
    } else if (isArray(key)) {
        return { id: key[0], rest: undefined };
    } else {
        return { column: key.c, rest: undefined };
    }
}

export function makePath(firstPart: PathPartSpec, ...parts: PathPartSpec[]): RelativePath {
    let p = makeMutablePathFromPartSpec(firstPart);
    const path = p;
    for (const key of parts) {
        p.rest = makeMutablePathFromPartSpec(key);
        p = p.rest;
    }
    return path;
}

export function makeRootPath(firstKey: string, ...parts: PathPartSpec[]): RootPath {
    const rest = makePath(firstKey, ...parts);
    assert(isKeyPath(rest));
    return { isRoot: true, rest };
}

function copyPathElement(r: RelativePathRest): MutableRelativePath | undefined {
    if (r === undefined) return undefined;

    if (isKeyPath(r)) {
        return { key: r.key, rest: undefined };
    } else if (isIndexPath(r)) {
        return { index: r.index, rest: undefined };
    } else if (isIDPath(r)) {
        return { id: r.id, rest: undefined };
    } else {
        return { column: r.column, rest: undefined };
    }
}

function copyRelativePath(r: RelativePathRest): MutableRelativePath | undefined {
    const path = copyPathElement(r);
    if (path === undefined) return undefined;

    let p = path;
    r = r?.rest;

    while (r !== undefined) {
        const next = defined(copyPathElement(r));
        p.rest = next;
        p = p.rest;
        r = r.rest;
    }

    return path;
}

export function copyPath(original: RootPath): RootPath;
export function copyPath(original: RelativePath): RelativePath;
export function copyPath(original: Path): Path;
export function copyPath(original: undefined): undefined;
export function copyPath(original: Path | undefined): Path | undefined {
    if (original === undefined) return undefined;

    if (isRootPath(original)) {
        const copy = copyRelativePath(original.rest);
        assert(copy !== undefined && isKeyPath(copy));
        return {
            isRoot: true,
            rest: copy,
        };
    } else {
        return copyRelativePath(original);
    }
}

export function combinePaths<T extends Path>(p1: T, p2: RelativePathRest): T {
    const copy = copyPath(p1);
    if (copy === undefined) return p2 as T;
    let r: MutablePath = copy;
    while (r.rest !== undefined) {
        r = r.rest;
    }
    r.rest = p2;
    return copy as T;
}

export function amendPath<T extends Path>(p: T, firstPart: PathPartSpec, ...parts: PathPartSpec[]): T;
export function amendPath<T extends Path | undefined>(
    p: T,
    firstPart: PathPartSpec,
    ...parts: PathPartSpec[]
): RelativePath;
export function amendPath<T extends Path>(p: T, firstPart: PathPartSpec, ...parts: PathPartSpec[]): T {
    const rest = makePath(firstPart, ...parts);
    return combinePaths(p, rest);
}
