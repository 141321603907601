import { defined, hasOwnProperty, assert, assertNever, sleep, exceptionToError } from "@glideapps/ts-necessities";

import {
    type ActionAppFacilities,
    type UploadProgressHandler,
    type UploadSession as IUploadSession,
    type UploadFileResponseError,
    type UploadFileResponse,
    isUploadFileResponseError,
} from "./types";
import { isResponseOK, checkString, logError, QuadraticBackoffController, decodeURIOrElse } from "@glide/support";
import type { UploadAppFileV2Body, UploadComponentKind } from "../firebase-function-types";
import { getFeatureFlag } from "../feature-flags";
import { getFeatureSetting } from "../feature-settings";
import { getAppFacilities } from "../support/app-renderer";

export class CancelledError extends Error {
    constructor() {
        super("Operation was cancelled");
    }
}

export class NetworkOfflineError extends Error {
    constructor() {
        super("The network is not available");
    }
}

class RemoteResponseError extends Error {
    constructor(statusCode: number) {
        super(`Upload failed with status code ${statusCode}`);
    }
}

class InsecureUploadError extends Error {
    constructor(endpoint: string) {
        super(`Would not upload data to ${endpoint}`);
    }
}

export class FileReadError extends Error {
    constructor(fileName: string) {
        super(`Could not read ${fileName}`);
    }
}

export class FileSizeLimitExceededError extends Error {
    constructor(public givenSize: number, public maxSize: number) {
        super(`File size ${givenSize} exceeded maximum size ${maxSize}`);
    }
}

function errorForBadResponse(resp: Response | undefined): UploadFileResponseError {
    return {
        error: resp === undefined ? new NetworkOfflineError() : new RemoteResponseError(resp.status),
        quotaExceeded: resp?.status === 402,
    };
}

type UploadState = "request-upload" | "uploading-direct" | "reading-file" | "uploading-read" | "finish-upload";
type CancellableResponse<T> = Readonly<{
    cancel: () => void;
    responsePromise: Promise<T>;
}>;

type XHRResponse =
    | "unsupported-body"
    | "cancelled"
    | "timeout"
    | "error"
    | { remoteStatus: number; receivedBytes?: number };
export type XHRUpload = CancellableResponse<XHRResponse>;

export function fileSizeForBuffer(blob: File | ArrayBuffer): number {
    return (blob as File)?.size ?? (blob as ArrayBuffer).byteLength;
}

export function extractReceivedBytesFromRangeHeader(header: string | null) {
    if (header === null) return 0;
    const matched = /bytes=0-([0-9]+)/.exec(header);
    if (matched === null || matched[1] === undefined) return 0;
    try {
        return Number.parseInt(matched[1], 10) + 1;
    } catch {
        return 0;
    }
}

type InitializeXHRForUpload = typeof initializeXHRForUpload;

function initializeXHRForUpload(
    uploadPath: string,
    blob: File | ArrayBuffer,
    startingOffset: number,
    maximumSize: number,
    onProgress: UploadProgressHandler | undefined
): XHRUpload {
    const xhr = new XMLHttpRequest();
    const fileSize = fileSizeForBuffer(blob);
    xhr.upload.onprogress = e => {
        if (onProgress === undefined) return;
        onProgress(fileSize, startingOffset + e.loaded);
    };
    const cancel = () => xhr.abort();
    const upperBound = Math.min(startingOffset + maximumSize, fileSize) - 1;
    const responsePromise = new Promise<XHRResponse>(resolve => {
        let resolvedOnce = false;
        xhr.ontimeout = () => {
            if (resolvedOnce) return;
            resolvedOnce = true;
            resolve("timeout");
        };
        xhr.onerror = () => {
            if (resolvedOnce) return;
            resolvedOnce = true;
            resolve("error");
        };
        xhr.onabort = () => {
            if (resolvedOnce) return;
            resolvedOnce = true;
            resolve("cancelled");
        };
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && !resolvedOnce) {
                resolvedOnce = true;
                if (xhr.status === 0) {
                    resolve("error");
                } else {
                    const receivedBytes = extractReceivedBytesFromRangeHeader(xhr.getResponseHeader("range"));
                    resolve({ remoteStatus: xhr.status, receivedBytes });
                }
            }
        };
    });
    xhr.open("PUT", uploadPath, true);
    xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.setRequestHeader("Content-Range", `bytes ${startingOffset}-${upperBound}/${fileSize}`);
    try {
        xhr.send(blob.slice(startingOffset, upperBound + 1));
        return {
            cancel,
            responsePromise,
        };
    } catch {
        // It's unclear exactly when iOS started supporting Blob inputs
        // in XMLHttpResponse, but iOS 13.6 definitely does. As of 2020-08-11
        // MDN was showing question marks across the board about XHR.send
        // supporting anything other than ArrayBuffer in any Safari.
        // For that matter it was showing question marks about sending
        // anything other than UVString in iOS, but we know that ArrayBuffer
        // definitely works on iOS 12.
        return {
            cancel,
            responsePromise: Promise.resolve("unsupported-body"),
        };
    }
}

const lowestBackoffTimeMS = 500;
const maxFailures = 5;

async function runWithRetryBackoff<T>(
    fn: () => Promise<T | undefined>,
    desc: string,
    resultIsOK?: (r: T) => boolean
): Promise<T | undefined> {
    let sleepTime = 0;
    const backoff = new QuadraticBackoffController(lowestBackoffTimeMS);
    while (backoff.attempts < maxFailures) {
        await sleep(sleepTime);
        try {
            const response = await fn();
            if (response !== undefined && (resultIsOK === undefined || resultIsOK(response))) {
                return response;
            }
        } catch (e: unknown) {
            logError(`Error when ${desc}`, e);
        }
        sleepTime = backoff.getWaitTime();
    }
    return undefined;
}

async function pollForFileUploadStatus(uploadPath: string, fileSize: number): Promise<number | undefined> {
    const fetchFunction = async () => {
        if (getFeatureFlag("injectFileUploadFaults") && Math.random() < 0.25) return undefined;
        const abortController = new AbortController();

        const response = await fetch(uploadPath, {
            method: "PUT",
            body: null,
            headers: { "Content-Range": `bytes */${fileSize}` },
            signal: abortController.signal,
        });
        abortController.abort();
        return response;
    };
    const fetchIsOK = (r: Response) => {
        const ok = r.status === 200 || r.status === 201 || r.status === 308;
        if (!ok) {
            logError("Polling for upload status", r.statusText);
        }
        return ok;
    };
    const pollResponse = await runWithRetryBackoff(fetchFunction, "polling for upload status", fetchIsOK);
    if (pollResponse === undefined) return undefined;
    if (pollResponse.status === 308) return extractReceivedBytesFromRangeHeader(pollResponse.headers.get("range"));
    return fileSize;
}

function withInternalCancellation<T>(
    func: (setInternalCancel: (internalCancel: () => void) => void, isCancelled: () => boolean) => Promise<T>
): CancellableResponse<T> {
    let cancelInternal: (() => void) | undefined;
    let cancelled = false;
    const cancelExternal = () => {
        if (cancelled) return;
        cancelled = true;
        if (cancelInternal !== undefined) {
            cancelInternal();
        }
    };
    return {
        cancel: cancelExternal,
        responsePromise: func(
            cancel => {
                cancelInternal = cancel;
            },
            () => cancelled
        ),
    };
}

// Chunk size must be some multiple of 256 KB except for the last chunk.
const baseChunkSize = 256 * 1024;
const maximumChunks = 32;

function withUploadRetryLoop(
    uploadPath: string,
    blob: File | ArrayBuffer,
    elideFirstStatusPoll: boolean,
    onProgress: UploadProgressHandler | undefined,
    initializeUpload: InitializeXHRForUpload = initializeXHRForUpload
): XHRUpload {
    return withInternalCancellation(async (setInternalCancel, isCancelled) => {
        const fileSize = fileSizeForBuffer(blob);

        let needsStatusPoll = !elideFirstStatusPoll;
        let sleepTime = 0;
        let consecutiveFailures = 0;
        let startOffset = 0;
        let chunkCount = 1;
        const backoff = new QuadraticBackoffController(lowestBackoffTimeMS);

        const handleFailure = (resetSleep: boolean = false) => {
            if (resetSleep) {
                sleepTime = 0;
                backoff.resetAttempts();
            } else {
                sleepTime = backoff.getWaitTime();
            }
            consecutiveFailures++;
            chunkCount = 1;
        };

        while (true) {
            const chunkSize = chunkCount * baseChunkSize;
            if (getFeatureFlag("injectFileUploadFaults") && Math.random() < 0.25) {
                const roll = Math.random();
                if (roll < 0.33) {
                    return "timeout";
                } else if (roll < 0.66) {
                    return "error";
                } else if (blob instanceof File) {
                    return "unsupported-body";
                } else {
                    return { remoteStatus: 503 };
                }
            }

            if (needsStatusPoll) {
                const maybeStartOffset = await pollForFileUploadStatus(uploadPath, fileSize);
                if (maybeStartOffset === undefined) return "error";
                startOffset = maybeStartOffset;
            }
            needsStatusPoll = true;
            if (isCancelled()) return "cancelled";
            if (startOffset >= fileSize) return { remoteStatus: 200 };

            const xhrUpload = initializeUpload(uploadPath, blob, startOffset, chunkSize, onProgress);
            setInternalCancel(xhrUpload.cancel);

            const xhrResponse = await xhrUpload.responsePromise;
            if (typeof xhrResponse !== "string") {
                const { remoteStatus, receivedBytes } = xhrResponse;
                const firstDigit = Math.floor(remoteStatus / 100);
                if (firstDigit === 5) {
                    handleFailure();
                    if (consecutiveFailures > maxFailures && getFeatureSetting("uploadHandlerLoopPrevention"))
                        return "error";
                    // PAY CLOSE ATTENTION, THIS IS AN EARLY LOOP CONTINUE
                    // We are in the "success" path but have actually failed.
                    continue;
                } else if (firstDigit === 2 && startOffset + chunkSize < fileSize) {
                    startOffset += chunkSize;
                } else if (remoteStatus !== 308) {
                    // HTTP 308 means we have to continue with the upload.
                    // The docs aren't clear on this, but it definitely happens
                    // for every partial PUT request.
                    //
                    // Anything other than a 2xx, 308, or 5xx isn't something
                    // we should expect to recover from.
                    return xhrResponse;
                }
                if (receivedBytes !== undefined) {
                    startOffset = receivedBytes;
                    needsStatusPoll = false;
                }

                sleepTime = consecutiveFailures = 0;
                backoff.resetAttempts();
                chunkCount = Math.min(maximumChunks, chunkCount * 2);
            } else {
                // XMLHttpRequest abort, error, and timeout handlers can all race
                // with each other. We actually see "error" before "abort" on Firefox.
                // So we need to check whether we explicitly cancelled the operation.
                if (isCancelled()) return "cancelled";
                switch (xhrResponse) {
                    case "timeout":
                        // "timeout" differs from "error" in the sense that
                        // we've already implicitly slept. So we can reset
                        // the sleep counter, but it's still a consecutive failure.
                        handleFailure(true);
                        break;
                    case "error":
                        handleFailure(true);
                        break;
                    case "cancelled":
                    // fallthrough
                    case "unsupported-body":
                        return xhrResponse;
                    default:
                        return assertNever(xhrResponse);
                }
            }

            if (consecutiveFailures > maxFailures) return "error";
            await sleep(sleepTime);
        }
    });
}

function isValidUploadLocation(uploadLocation: string, origin: string) {
    return (
        uploadLocation.startsWith(origin + "/") ||
        uploadLocation.startsWith("https://storage.googleapis.com/") ||
        uploadLocation.startsWith("https://upload.glideapps.com/") ||
        uploadLocation.startsWith("https://upload.heyglide.com/")
    );
}

const cancelledError = {
    error: new CancelledError(),
    quotaExceeded: false,
};

interface UploadIdentity {
    uploadID: string;
    uploadLocation: string;
}

function isUploadIdentity(x: unknown): x is UploadIdentity {
    return hasOwnProperty(x, "uploadID") && hasOwnProperty(x, "uploadLocation");
}

export function getFilename(givenFilename: string): string {
    const pathComponents = givenFilename.split(/[\\\/]/g);
    if (pathComponents.length < 1) return givenFilename;
    return pathComponents[pathComponents.length - 1];
}

export function getFilenameWithoutExtension(givenFilename: string): string {
    const basename = getFilename(givenFilename);
    const lastDot = basename.lastIndexOf(".");
    if (lastDot < 0) return basename;
    return basename.slice(0, lastDot);
}

interface NotExactlyFile {
    name: string;
    type: string;
    contents: string | ArrayBuffer;
}

function isNotExactlyFile(f: File | NotExactlyFile): f is NotExactlyFile {
    return hasOwnProperty(f, "contents");
}

class UploadSession implements IUploadSession {
    private fallbackBuffer: ArrayBuffer | undefined;
    private needsConfirmedQuery = false;
    private uploadState: UploadState = "request-upload";
    private uploadIdentity: { uploadID: string; uploadLocation: string } | undefined;
    private isCancelledDirect = false;
    private isCancelled: (() => boolean) | undefined;
    private cancellationFunc: (() => void) | undefined;
    private readonly file: File | undefined;
    private readonly fileName: string;
    private readonly fileType: string;

    constructor(
        private appFacilities: ActionAppFacilities,
        private readonly appID: string,
        maybeFile: File | NotExactlyFile,
        private readonly includeFilename: boolean,
        private readonly component: UploadComponentKind,
        private readonly onProgress: UploadProgressHandler | undefined,
        private readonly onAttemptResponse?: (resp: UploadFileResponse) => void,
        private readonly origin?: string,
        private readonly initializeUpload: InitializeXHRForUpload = initializeXHRForUpload
    ) {
        if (isNotExactlyFile(maybeFile)) {
            if (typeof maybeFile.contents === "string") {
                const enc = new TextEncoder();
                this.fallbackBuffer = enc.encode(maybeFile.contents);
            } else {
                this.fallbackBuffer = maybeFile.contents;
            }
        } else {
            this.file = maybeFile;
        }
        this.fileName = maybeFile.name;
        this.fileType = maybeFile.type;
    }

    private checkIfCancelled(): boolean {
        let isCancelled = this.isCancelledDirect;
        if (this.isCancelled !== undefined) {
            isCancelled = isCancelled || this.isCancelled();
        }
        // We need to make sure the fallback buffer is undefined on cancellation,
        // otherwise that's a memory leak.
        if (isCancelled) {
            this.fallbackBuffer = undefined;
        }
        return isCancelled;
    }

    public cancel(): void {
        if (this.checkIfCancelled()) return;
        this.isCancelledDirect = true;
        // Clear the fallback buffer on cancellation, we won't need it.
        this.fallbackBuffer = undefined;
        if (this.cancellationFunc !== undefined) {
            this.cancellationFunc();
        }
    }

    private reportUploadError(error: Error) {
        if (this.uploadIdentity !== undefined) {
            const { uploadID } = this.uploadIdentity;
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void this.appFacilities
                .callAuthIfAvailableCloudFunction(
                    "uploadAppFileV2",
                    {
                        operation: "report-failure",
                        uploadID,
                        permanent: true,
                        kind: `${this.uploadState}-exception`,
                        message: `${error}`,
                    },
                    {}
                )
                .then(r => r?.text());
        }
    }

    private async updatingInternalCancellation<T>(
        func: (setInternalCancel: (internalCancel: () => void) => void) => Promise<T>
    ): Promise<T> {
        const { cancel, responsePromise } = withInternalCancellation(async (setInternalCancel, isCancelled) => {
            this.isCancelled = isCancelled;
            return func(setInternalCancel);
        });
        this.cancellationFunc = cancel;
        try {
            return await responsePromise;
        } finally {
            this.cancellationFunc = undefined;
        }
    }

    private async fillFallbackBuffer() {
        if (this.fallbackBuffer !== undefined) return;
        this.fallbackBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
            if (this.file === undefined) {
                reject(new TypeError("file is undefined"));
                return;
            }
            const reader = new FileReader();

            const err = (ev: ProgressEvent<FileReader>) => {
                if (ev.target?.error !== null && ev.target?.error !== undefined) {
                    reject(ev.target.error);
                } else {
                    reject(new FileReadError(this.fileName));
                }
            };

            const success = (ev: ProgressEvent<FileReader>) => {
                try {
                    assert(ev.target?.result instanceof ArrayBuffer);
                    resolve(ev.target.result);
                } catch (e: unknown) {
                    reject(e);
                }
            };

            reader.onabort = err;
            reader.onerror = err;
            reader.onloadend = success;
            reader.readAsArrayBuffer(this.file);
        });
    }

    private async uploadToStorageEndpoint(uploadPath: string): Promise<XHRResponse> {
        return this.updatingInternalCancellation(async setInternalCancel => {
            if (this.fallbackBuffer === undefined && this.file !== undefined) {
                this.uploadState = "uploading-direct";
                const nonReading = withUploadRetryLoop(
                    uploadPath,
                    this.file,
                    !this.needsConfirmedQuery,
                    this.onProgress,
                    this.initializeUpload
                );

                setInternalCancel(nonReading.cancel);
                const nonReadingResult = await nonReading.responsePromise;
                this.needsConfirmedQuery = true;

                if (this.checkIfCancelled()) return "cancelled";
                if (nonReadingResult !== "unsupported-body") return nonReadingResult;

                this.uploadState = "reading-file";
                if (getFeatureFlag("injectFileUploadFaults") && Math.random() < 0.5) {
                    return "unsupported-body";
                }
                try {
                    await this.fillFallbackBuffer();
                    assert(this.fallbackBuffer !== undefined);
                } catch {
                    return this.checkIfCancelled() ? "cancelled" : "unsupported-body";
                }

                if (this.checkIfCancelled()) return "cancelled";
            }

            this.uploadState = "uploading-read";
            const reading = withUploadRetryLoop(
                uploadPath,
                defined(this.fallbackBuffer),
                false,
                this.onProgress,
                this.initializeUpload
            );

            setInternalCancel(reading.cancel);
            const readingResult = await reading.responsePromise;

            return this.checkIfCancelled() ? "cancelled" : readingResult;
        });
    }

    private async beginUploadProcess(): Promise<UploadFileResponse | UploadIdentity> {
        if (this.uploadIdentity !== undefined) return this.uploadIdentity;

        return this.updatingInternalCancellation(async () => {
            const { file } = this;
            const { fileName, fileType: contentType } = this;

            if ((file?.size ?? 0) === 0) {
                // Our users regularly run into an iCloud Drive bug that
                // reports the file size as zero bytes. The file isn't zero bytes
                // in many of these cases. We _hope_ that just opening the file
                // and reading it in will result in the actual file contents
                // whenever we hit this bug.
                try {
                    await this.fillFallbackBuffer();
                } catch (e: unknown) {
                    // This sucks, but shouldn't preclude an upload attempt.
                }
            }

            const contentLength = this.fallbackBuffer?.byteLength ?? file?.size ?? 0;
            if (contentLength === 0) {
                return { error: new FileReadError(fileName), quotaExceeded: false };
            }

            const lastDot = fileName.lastIndexOf(".");
            const proposedExtension = lastDot < 0 ? undefined : fileName.substring(lastDot + 1);
            const proposedName = this.includeFilename ? getFilenameWithoutExtension(fileName) : undefined;

            const beginBody: UploadAppFileV2Body = {
                operation: "begin",
                appID: this.appID,
                contentType,
                contentLength,
                origin: this.origin ?? window.location.origin,
                proposedExtension,
                proposedName,
                component: this.component,
            };

            const initiatingResponse = await this.appFacilities.callAuthIfAvailableCloudFunction(
                "uploadAppFileV2",
                beginBody,
                {}
            );
            if (!isResponseOK(initiatingResponse) && initiatingResponse?.status !== 413) {
                // We have to drain out the body, otherwise we'll just leave the connection
                // around forever.
                void initiatingResponse?.text();
                return errorForBadResponse(initiatingResponse);
            }

            try {
                const initiatingPayload = await initiatingResponse.json();

                if (initiatingResponse.status === 413) {
                    // We have gone over the file size limit for this app.
                    // The body will tell us how much we could have uploaded.
                    const { maxUploadSize } = initiatingPayload;
                    return {
                        error: new FileSizeLimitExceededError(contentLength, maxUploadSize),
                        quotaExceeded: false,
                    };
                }

                const { uploadLocation: responseUploadLocation } = initiatingPayload;
                const uploadID = initiatingPayload.uploadID;

                assert(typeof uploadID === "string", "uploadID was not string");
                const uploadLocation = responseUploadLocation;
                assert(typeof uploadLocation === "string", "uploadLocation was not a string");
                this.uploadIdentity = { uploadID, uploadLocation };
            } catch (error: unknown) {
                this.reportUploadError(exceptionToError(error));
                if (this.checkIfCancelled()) return cancelledError;
                return {
                    error: exceptionToError(error),
                    quotaExceeded: false,
                };
            }

            if (this.checkIfCancelled()) return cancelledError;

            const { uploadLocation } = this.uploadIdentity;

            if (!isValidUploadLocation(uploadLocation, this.origin ?? window.location.origin)) {
                this.uploadIdentity = undefined;
                const error = new InsecureUploadError(uploadLocation);
                this.reportUploadError(error);
                return {
                    error,
                    quotaExceeded: false,
                };
            }
            return this.uploadIdentity;
        });
    }

    private async attemptInternal(): Promise<UploadFileResponse> {
        if (this.checkIfCancelled()) return cancelledError;

        const uploadIdentity = await this.beginUploadProcess();
        if (!isUploadIdentity(uploadIdentity)) {
            return uploadIdentity;
        }
        if (this.checkIfCancelled()) return cancelledError;

        const { uploadID, uploadLocation } = uploadIdentity;

        const uploadResponse = await this.uploadToStorageEndpoint(uploadLocation);
        if (this.checkIfCancelled()) return cancelledError;

        if (typeof uploadResponse === "string") {
            if (uploadResponse === "cancelled") return cancelledError;

            const error =
                uploadResponse === "unsupported-body" ? new FileReadError(this.fileName) : new NetworkOfflineError();
            this.reportUploadError(error);
            return {
                error,
                quotaExceeded: false,
            };
        }
        const { remoteStatus } = uploadResponse;
        if (Math.floor(remoteStatus / 100) !== 2) {
            const error = new RemoteResponseError(remoteStatus);
            this.reportUploadError(error);
            return {
                error,
                quotaExceeded: false,
            };
        }

        this.uploadState = "finish-upload";
        const completeBody: UploadAppFileV2Body = {
            operation: "complete",
            uploadID,
        };
        const confirmingResponse = await this.appFacilities.callAuthIfAvailableCloudFunction(
            "uploadAppFileV2",
            completeBody,
            {}
        );
        if (!isResponseOK(confirmingResponse)) {
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void confirmingResponse?.text();
            return errorForBadResponse(confirmingResponse);
        }

        try {
            const { path } = await confirmingResponse.json();
            if (this.checkIfCancelled()) return cancelledError;

            const pathString = checkString(path);

            let returnedPath: string;
            if (getFeatureSetting("disableFrontendEncodeUploadURI")) {
                returnedPath = pathString;
            } else {
                // The path may contain spaces coming from the backend.
                // This was a mistake, but it's one we can work around.
                // The reason this happened at all is that GCS doesn't mind spaces in object names,
                // and we're intentionally working with these file paths at the GCS level.
                //
                // We also want to do this on the frontend instead of the backend
                // to work around backend deployment race conditions. The frontend
                // always deploys after the backend, but the backend deploys at
                // its own pace with respect to itself.
                //
                // One day, this won't be necessary.
                // The pathString might have been URI encoded in the first place.
                // If it was encoded in the first place, then its decoding won't
                // be a fixed-point. And that means we should leave it alone.
                returnedPath = decodeURIOrElse(pathString) === pathString ? encodeURI(pathString) : pathString;
            }
            return { path: returnedPath };
        } catch (error: unknown) {
            this.reportUploadError(exceptionToError(error));
            return {
                error: exceptionToError(error),
                quotaExceeded: false,
            };
        }
    }

    public async attempt(): Promise<UploadFileResponse> {
        const resp = await this.attemptInternal();
        if (this.onAttemptResponse !== undefined) {
            this.onAttemptResponse(resp);
        }
        return resp;
    }
}

export function uploadFileIntoGlideStorage(
    appFacilities: ActionAppFacilities,
    appID: string,
    file: File | NotExactlyFile,
    component: UploadComponentKind,
    onProgress: UploadProgressHandler | undefined,
    onAttemptResponse?: (resp: UploadFileResponse) => void,
    includeFilename: boolean = false,
    origin?: string,
    initializeUpload: InitializeXHRForUpload = initializeXHRForUpload
): UploadSession {
    return new UploadSession(
        appFacilities,
        appID,
        file,
        includeFilename,
        component,
        onProgress,
        onAttemptResponse,
        origin,
        initializeUpload
    );
}

export async function handleFileUpload(
    file: File,
    appID: string,
    options?: {
        includeFilename: boolean;
    }
): Promise<string | undefined> {
    const session = uploadFileIntoGlideStorage(
        getAppFacilities(),
        appID,
        file,
        "data-editor",
        undefined,
        undefined,
        options?.includeFilename
    );
    const uploadResponse = await session.attempt();
    if (!isUploadFileResponseError(uploadResponse)) {
        return uploadResponse.path;
    }

    return undefined;
}
