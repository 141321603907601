export type OnTimedPromise = (name: string, time: number) => void;

/**
 * Measures the time taken for a promise to resolve or reject and calls a provided callback with that duration.
 * @param promiseName A name or identifier for the promise being timed.
 * @param inputPromise The promise to be timed.
 * @param onTimed A callback function that is invoked once the inputPromise resolves or rejects.
 *                This callback is provided with the promiseName and the time taken in milliseconds.
 * @returns Returns the resolved value of the inputPromise, or propagates its rejection.
 * @example
 * const myPromise = new Promise((resolve) => setTimeout(() => resolve("Done"), 2000));
 * await timePromise("MyPromise", myPromise, (name, duration) => {
 *     console.log(`${name} took ${duration}ms to complete.`);
 * });
 */
export async function timePromise<T>(
    promiseName: string,
    inputPromise: Promise<T>,
    onTimed: OnTimedPromise
): Promise<T> {
    const startTime = performance.now();
    try {
        const result = await inputPromise;
        onTimed(promiseName, performance.now() - startTime);
        return result;
    } catch (error: unknown) {
        onTimed(promiseName, performance.now() - startTime);
        throw error;
    }
}
