import "twin.macro";

import { AppKind } from "@glide/location-common";
import { assertNever } from "@glideapps/ts-necessities";

type SizeVariant = "regular" | "small" | "tiny";

interface UnifiedProjectIconProps {
    classicIcon: React.ReactNode;
    appKind: AppKind;
    sizeVariant?: SizeVariant;
    hideClassicIndicator?: boolean;
}

export const UnifiedProjectIcon: React.FC<UnifiedProjectIconProps> = p => {
    const { classicIcon, appKind, sizeVariant = "regular", hideClassicIndicator = false } = p;

    if (appKind === AppKind.App && !hideClassicIndicator) {
        return <ClassicWrapper sizeVariant={sizeVariant}>{classicIcon}</ClassicWrapper>;
    } else {
        return <>{classicIcon}</>;
    }
};

interface ClassicWrapperProps {
    sizeVariant: SizeVariant;
}

const ClassicWrapper: React.FC<React.PropsWithChildren<ClassicWrapperProps>> = p => {
    const { children, sizeVariant } = p;

    switch (sizeVariant) {
        case "regular": {
            return (
                <div tw="p-2 pb-4 rounded-3xl -m-2 -mb-4 relative [filter: grayscale(0.3)] bg-n300A">
                    {children}
                    <span tw="absolute inset-x-0 bottom-px text-builder-2xs text-text-pale font-medium uppercase text-center">
                        Classic
                    </span>
                </div>
            );
        }

        case "small": {
            return (
                <div tw="relative [filter: grayscale(0.3)]">
                    {children}
                    <div tw="absolute -inset-1 -bottom-3 rounded-lg flex items-end justify-center bg-n200A">
                        <span tw="[font-size: 7px] text-text-pale font-medium uppercase mb-px">Classic</span>
                    </div>
                </div>
            );
        }

        case "tiny": {
            return (
                <div tw="relative [filter: grayscale(0.3)]">
                    {children}
                    <div tw="absolute -inset-1 rounded-lg flex items-end justify-center bg-n200A"></div>
                </div>
            );
        }

        default: {
            assertNever(sizeVariant, "Invalid size variant");
        }
    }
};
