import { css, styled } from "@glide/common-components";

const normalFabFilter = css`
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.24)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.12));
`;

const pressedFabFilter = css`
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.35)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2)) brightness(0.8);
`;

const inactiveFabFilter = css`
    & > div {
        filter: opacity(0.4);
    }
    pointer-events: none;
`;

export const Wrapper = styled.button<{ enabled: boolean; distance: number; yOffset: number; color?: string }>`
    position: absolute;
    bottom: ${p => p.distance - p.yOffset}px;
    right: ${p => p.distance}px;

    width: 56px;
    height: 56px;

    border-radius: 28px;
    z-index: 100;

    background-color: ${p => p.theme.baseColor};

    ${p => (p.enabled ? normalFabFilter : inactiveFabFilter)}

    :active {
        ${p => (p.enabled ? pressedFabFilter : inactiveFabFilter)}
    }

    @keyframes fab-intro {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    transition: filter 0.2s;

    animation-name: fab-intro;
    animation-duration: 0.3s;
    animation-iteration-count: 1;

    cursor: pointer;

    .floating-btn-style {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 28px;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: ${p => (p.color !== undefined ? p.color : p.theme.primaryAccentColor)};
    }
`;

export const SimpleWrapper = styled.button<{ enabled: boolean; color?: string }>`
    position: relative;
    width: 56px;
    height: 56px;

    border-radius: 28px;

    background-color: ${p => p.theme.baseColor};

    ${p => (p.enabled ? normalFabFilter : inactiveFabFilter)}

    :active {
        ${p => (p.enabled ? pressedFabFilter : inactiveFabFilter)}
    }

    @keyframes fab-intro {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    transition: filter 0.2s;

    animation-name: fab-intro;
    animation-duration: 0.3s;
    animation-iteration-count: 1;

    cursor: pointer;

    .floating-btn-style {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 28px;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: ${p => (p.color !== undefined ? p.color : p.theme.primaryAccentColor)};
    }
`;
