import { definedMap } from "@glideapps/ts-necessities";
import { getBrowserLanguage, nullToUndefined, Watchable } from "@glide/support";
import { GlideDateTime } from "./glide-date-time";
import { sanitizeDateTime } from "./support";
import type { ChangeObservable } from "@glide/support";

// Functions in this file used to be part of Support.ts, but they've all been
// banished out here due to this async chrono-node import.
//
// The await import completely breaks Webpack when trying to build
// the service worker.

let chronoPromise: Promise<any> | undefined;
let chrono: any;

const parsedDatesWithUTCMarker = new Map<string, GlideDateTime | undefined>();
const parsedDatesWithoutUTCMarker = new Map<string, GlideDateTime | undefined>();

type ParsedDateType<T extends boolean> = T extends true ? GlideDateTime : Date;

const isChronoLoadedWatchable = new Watchable(false);
export const isChronoLoadedObservable: ChangeObservable<boolean> = isChronoLoadedWatchable;
let chronoForCurrentLocale: any | undefined;

async function loadChrono(): Promise<void> {
    if (chronoPromise === undefined) {
        chronoPromise = import("chrono-node");
    }
    chrono = await chronoPromise;
    isChronoLoadedWatchable.current = true;
}

/** @deprecated This is just for testing! */
export function requireChronoForTest(): void {
    // using this export to work around https://github.com/nodejs/node/issues/35889:
    // `Segmentation fault with import() instead of calling importModuleDynamically`
    // when testing with Jest
    chrono = require("chrono-node");
    chronoPromise = undefined;
}

/** @deprecated This is just for testing! */
export function resetChronoForTest(): void {
    chrono = undefined;
    chronoPromise = undefined;
    chronoForCurrentLocale = undefined;
    parsedDatesWithUTCMarker.clear();
    parsedDatesWithoutUTCMarker.clear();
}

export function canParseUserDateTimeSync(): boolean {
    if (chrono === undefined) {
        void loadChrono();
        return false;
    }
    return true;
}

export function doesLocaleUseDayMonth(): boolean {
    const isNode = (function () {
        try {
            return process.release.name === "node";
        } catch {
            return false;
        }
    })();

    if (isNode) {
        return false;
    }

    const browserLanguage = getBrowserLanguage();
    const format = new Intl.DateTimeFormat(browserLanguage);
    const parts = format.formatToParts(new Date(2002, 1, 3));
    const dayIndex = parts.findIndex(part => part.type === "day");
    const monthIndex = parts.findIndex(part => part.type === "month");
    return dayIndex >= 0 && monthIndex >= 0 && dayIndex < monthIndex;
}

function getChronoForCurrentLocale() {
    if (chronoForCurrentLocale !== undefined) {
        return chronoForCurrentLocale;
    }

    if (chrono === undefined) {
        void loadChrono();
        return undefined;
    }

    const isDayMonth = doesLocaleUseDayMonth();

    // Chrono only supports a limited number of locales.  We'll play it
    // safe and use GB if the locale's date format is day/month, and US
    // otherwise.
    chronoForCurrentLocale = isDayMonth ? chrono.en.GB : chrono.en;

    return chronoForCurrentLocale;
}

function parseUserDateTimeZoneAwareSync(str: string, removeUTCMarker: boolean): GlideDateTime | undefined {
    const c = getChronoForCurrentLocale();
    if (c === undefined) return undefined;

    const parsedDates = removeUTCMarker ? parsedDatesWithoutUTCMarker : parsedDatesWithUTCMarker;

    const cached = parsedDates.get(str);
    if (cached !== undefined) {
        return cached;
    }

    if (parsedDates.size > 10000) {
        parsedDates.clear();
    }

    const sanitized = sanitizeDateTime(str, removeUTCMarker, removeUTCMarker);
    const parsed = definedMap(nullToUndefined(c.parseDate(sanitized)), d =>
        GlideDateTime.fromTimeZoneAwareDate(d, str)
    );

    parsedDates.set(str, parsed);

    return parsed;
}

export function parseUserDateTimeZoneAgnosticSync<T extends boolean>(
    str: string,
    glideDateTime: T
): ParsedDateType<T> | undefined {
    const parsed = parseUserDateTimeZoneAwareSync(str, true);

    if (glideDateTime) {
        return parsed as ParsedDateType<T> | undefined;
    } else {
        return parsed?.asLocalTimeZoneAgnosticDate() as ParsedDateType<T> | undefined;
    }
}

async function importChronoAndParseDateTimeZoneAgnostic<T extends boolean>(
    str: string,
    glideDateTime: T
): Promise<ParsedDateType<T> | undefined> {
    await loadChrono();
    return parseUserDateTimeZoneAgnosticSync(str, glideDateTime);
}

export function parseUserDateTimeZoneAgnostic<T extends boolean>(
    str: string,
    glideDateTime: T
): Promise<ParsedDateType<T> | undefined> | ParsedDateType<T> | undefined {
    if (chrono === undefined) {
        return importChronoAndParseDateTimeZoneAgnostic(str, glideDateTime);
    }
    return parseUserDateTimeZoneAgnosticSync(str, glideDateTime);
}

async function importChronoAndParseDateTimeZoneAware(str: string): Promise<GlideDateTime | undefined> {
    await loadChrono();
    return parseUserDateTimeZoneAwareSync(str, false);
}

export function parseUserDateTimeZoneAware(
    str: string
): Promise<GlideDateTime | undefined> | GlideDateTime | undefined {
    if (chrono === undefined) {
        return importChronoAndParseDateTimeZoneAware(str);
    }
    return parseUserDateTimeZoneAwareSync(str, false);
}
