import type { OverlayTheme } from "../overlay-theme";

export const listOverlay: OverlayTheme = {
    overlayName: "listOverlayTheme",
    listItem: {
        titleFontStyle: "_fontStyles.AccentCallout4",
        titleLineHeight: "_fontStyles.AccentCallout4Height",
        detailFontStyle: "_fontStyles.Body5",
        detailLineHeight: "_fontStyles.Body5Height",
    },
};
