import { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { GlideIcon } from "@glide/common";
import { isDefined } from "@glide/support";
import uuid from "uuid";
import isString from "lodash/isString";
import {
    Container,
    InputContainer,
    Line,
    Lines,
    LineContent,
    LineActions,
    HasChanges,
    ChangeChiclet,
} from "./prompt-conversation-style";
import { type EditorComponentRef, PlainTextInlineTemplatingInput } from "../inline-templating-input/editor";
import { useOrgInfo } from "../../hooks/use-org-info";
import { Button } from "../button/button";
import { useIsUserVerified } from "../../lib/is-user-verified";
import EmptyState from "./empty-state";
import type { PlainTextInlineTemplateValue } from "../inline-templating-input/utils";
import { isCustomComponentLink } from "@glide/common-core/dist/js/custom-component";

const isImage = (content: unknown): boolean => {
    return isString(content) && /^https?:\/\/.*\.(png|jpg|jpeg|gif|svg|webp)$/.test(content.trim());
};

export type LineDef =
    | {
          type: "text";
          message: string;
          id: string;
      }
    | { type: "fieldAndActionChange"; id: string }
    | { type: "fieldChange"; id: string }
    | { type: "actionChange"; id: string };

export type Change = {
    key: string;
    label: string;
    onClick?: () => void;
};

const PromptLine = ({
    type,
    content,
    onDelete,
    isLastLine,
    isWorking,
    showDeleteButton,
}: {
    type: LineDef["type"];
    content: JSX.Element | string;
    onDelete?: () => void;
    isLastLine?: boolean;
    isWorking?: boolean;
    showDeleteButton?: boolean;
}) => {
    return (
        <Line>
            <LineContent type={type} isWorking={isWorking}>
                {content}
            </LineContent>

            {isLastLine && (
                <LineActions>
                    {isWorking ? (
                        <GlideIcon icon="st-spinner" kind="stroke" spin iconSize={16} strokeColor="var(--gv-n500A)" />
                    ) : (
                        showDeleteButton && (
                            <Button
                                buttonType="minimal"
                                variant="default"
                                size="xsm"
                                label="Remove"
                                icon="st-trash"
                                iconType="iconOnly"
                                onClick={onDelete}
                            />
                        )
                    )}
                </LineActions>
            )}
        </Line>
    );
};

const IN_FLIGHT_PREFIX = "IN_FLIGHT::";
const inFlightUuid = () => IN_FLIGHT_PREFIX + uuid.v4();
const isInFlightUuid = (id: string) => id.startsWith(IN_FLIGHT_PREFIX);

export const PromptConversation = ({
    className,
    lines,
    onAddLine,
    onRemoveLastLine,
    onUpload,
    isAIEnabled = false,
    emptyState,
    showDeleteButton,
    changes,
    setInputValue,
    inputValue,
    isWorking,
}: {
    className?: string;
    lines: LineDef[];
    onAddLine?: (value: LineDef) => void;
    onRemoveLastLine?: () => void;
    onUpload?: (file: File) => Promise<string | undefined>;
    isAIEnabled?: boolean;
    emptyState?: JSX.Element;
    showDeleteButton?: boolean;
    changes?: Array<Change>;
    setInputValue: (content: PlainTextInlineTemplateValue<unknown>) => void;
    inputValue?: PlainTextInlineTemplateValue<unknown>;
    isWorking: boolean;
}) => {
    const editorRef = useRef<EditorComponentRef | null>(null);
    const reversedLines = useMemo(() => [...lines].reverse(), [lines]);

    const [parent] = useAutoAnimate();

    const hasFieldChanges = !!changes?.some(change => change.key === "fields");
    const hasActionChanges = !!changes?.some(change => change.key === "actions");
    const hasChanges = hasFieldChanges || hasActionChanges;

    const onEnter = useCallback(
        (editorTextContent: string) => {
            if (isWorking) return;

            const hasText = editorTextContent.trim().length > 0;

            // Don't submit empty text.
            if (!hasChanges && !hasText) return;

            const type =
                hasFieldChanges && hasActionChanges
                    ? "fieldAndActionChange"
                    : hasFieldChanges
                    ? "fieldChange"
                    : "actionChange";

            const id = inFlightUuid();
            onAddLine?.(
                hasText
                    ? {
                          type: "text",
                          message: editorTextContent,
                          id,
                      }
                    : {
                          type,
                          id,
                      }
            );
        },
        [isWorking, hasChanges, hasFieldChanges, hasActionChanges, onAddLine]
    );

    useLayoutEffect(() => {
        const intercomLauncher = document.getElementById("intercom_custom_launcher");
        if (intercomLauncher) {
            intercomLauncher.style.display = "none";
        }

        return () => {
            if (intercomLauncher) {
                intercomLauncher.style.display = "flex";
            }
        };
    }, []);

    const runUpload = async (file: File) => {
        const url = await onUpload?.(file);

        if (!isDefined(url)) {
            throw new Error("File upload failed");
        }

        return {
            url,
        };
    };

    const submit = useCallback(() => {
        const result = editorRef.current?.submit();

        if (result === false) return false;

        onEnter(result ?? "");
        setInputValue?.([]);

        return true;
    }, [onEnter, setInputValue]);

    return (
        <Container className={className}>
            {reversedLines.length > 0 ? (
                <Lines ref={parent}>
                    {reversedLines.map((line, i) => {
                        const isLast = i === 0;
                        const label =
                            line.type === "text" ? (
                                isImage(line.message) ? (
                                    <img src={line.message.trim()} alt="" tw="rounded" />
                                ) : (
                                    <span>{line.message.trim()}</span>
                                )
                            ) : line.type === "fieldChange" ? (
                                <span>Fields were changed</span>
                            ) : line.type === "actionChange" ? (
                                <span>Actions were changed</span>
                            ) : line.type === "fieldAndActionChange" ? (
                                <span>Fields and actions were changed</span>
                            ) : (
                                <b>unknown</b>
                            );

                        return (
                            <PromptLine
                                key={line.id}
                                type={line.type}
                                content={label}
                                onDelete={() => onRemoveLastLine?.()}
                                isWorking={isLast && isWorking}
                                isLastLine={isLast}
                                showDeleteButton={showDeleteButton}
                            />
                        );
                    })}
                </Lines>
            ) : (
                emptyState
            )}

            <InputContainer hasChanges={hasChanges}>
                {hasChanges && (
                    <HasChanges>
                        {(changes ?? []).map(({ key, label, onClick }) => (
                            <ChangeChiclet key={key}>
                                {label}

                                <Button
                                    buttonType="minimal"
                                    variant="default"
                                    size="xsm"
                                    label="Remove"
                                    icon="st-close"
                                    iconType="iconOnly"
                                    onClick={onClick}
                                />
                            </ChangeChiclet>
                        ))}
                    </HasChanges>
                )}

                <PlainTextInlineTemplatingInput
                    ref={editorRef}
                    placeholder={hasChanges ? "Add changes or press Return to save" : "Ask for updates"}
                    focusPlaceholder={
                        hasChanges ? "Add changes or press Return to save" : "Upload an image or type a message"
                    }
                    menuOptions={[]}
                    onChange={setInputValue ?? (() => void 0)}
                    disabled={!isAIEnabled}
                    value={inputValue ?? []}
                    disableBindings
                    unmountWhenOffscreen={false}
                    autoFocus={true}
                    disableTopBorder={hasChanges}
                    tw="!h-[76px] !bg-n0 !border-aqua400 overflow-y-auto [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden"
                    disableNewLines={false}
                    onUpload={runUpload}
                    onKeyDown={e => {
                        if (isWorking) return;
                        if (e.key !== "Enter") return false;
                        if (e.shiftKey || e.metaKey) return false;

                        const result = submit();

                        if (!result) return false;

                        e.preventDefault();

                        return true;
                    }}
                    hideDropdown={true}
                />

                <Button
                    tw="absolute right-2 bottom-2 z-20 rounded-full bg-accent"
                    buttonType="primary"
                    size="md"
                    iconType="iconOnly"
                    icon="st-arrow-up"
                    label="Save"
                    onClick={submit}
                />
            </InputContainer>
        </Container>
    );
};

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export type PromptConversationRef = {
    listUuids(): Array<string>;
};

const PromptConversationWithLocalState = (
    {
        initialLines,
        onAddLine,
        onRemoveLastLine,
        onUpload,
        isAdmin,
        showDeleteButton,
        changes,
        onComponentLinkPasted,
        isWorking,
        setIsWorking,
    }: {
        initialLines?: LineDef[];
        onAddLine?: (value: LineDef) => Promise<string>;
        onRemoveLastLine?: () => void;
        onUpload?: (file: File) => Promise<string | undefined>;
        isAdmin: boolean;
        showDeleteButton?: boolean;
        changes?: Array<Change>;
        onComponentLinkPasted: (url: string) => void;
        isWorking: boolean;
        setIsWorking: (isWorking: boolean) => void;
    },
    ref: React.ForwardedRef<PromptConversationRef>
) => {
    const orgInfo = useOrgInfo();
    const [lines, setLines] = useState(initialLines ?? []);
    const [inputValue, setInputValue] = useState<PlainTextInlineTemplateValue<unknown>>([]);
    const prevInputValue = useRef<PlainTextInlineTemplateValue<unknown>>(inputValue);

    const onChangeInput = useCallback(
        (value: PlainTextInlineTemplateValue<unknown>) => {
            setInputValue(value);
            if (prevInputValue.current.length === 0 && value.length === 1) {
                if (value[0].type === "text") {
                    const trimmedText = value[0].text.trim();
                    if (isCustomComponentLink(trimmedText)) {
                        onComponentLinkPasted(trimmedText);
                    }
                }
            }
            prevInputValue.current = value;
        },
        [onComponentLinkPasted]
    );

    const isUserVerified = useIsUserVerified();
    const isAIEnabled = orgInfo.org?.isAIEnabled ?? isUserVerified;

    useImperativeHandle(
        ref,
        (): PromptConversationRef => ({
            listUuids() {
                return lines.map(line => line.id).filter(f => !isInFlightUuid(f));
            },
        }),
        [lines]
    );

    const addLine = useCallback(
        async (newLine: LineDef, promise?: Promise<string>) => {
            setLines(l => [...l, newLine]);

            if (onAddLine) {
                setIsWorking(true);

                // Show waiting UI for a minimum of 1 second, but for as
                // long as the onChange promise is pending.
                const timoutPromise = timeout(1000);
                const id = promise ? await promise : await onAddLine(newLine);

                // Replace uuid with the id returned from the promise
                setLines(l => {
                    const newLines = [...l];
                    const lastLine = newLines.pop();

                    if (lastLine) {
                        newLines.push({ ...lastLine, id });
                    }

                    return newLines;
                });

                await timoutPromise;

                setIsWorking(false);
            }
        },
        [onAddLine, setIsWorking]
    );

    const onAddLineLocal = useCallback(
        async (newLine: LineDef) => {
            await addLine(newLine);
        },
        [addLine]
    );

    const onRemoveLastLineLocal = useCallback(async () => {
        const updatedLines = [...lines];
        updatedLines.pop();
        setLines(updatedLines);

        onRemoveLastLine?.();
    }, [lines, onRemoveLastLine]);

    return (
        <PromptConversation
            isWorking={isWorking}
            lines={lines}
            onAddLine={onAddLineLocal}
            onRemoveLastLine={onRemoveLastLineLocal}
            onUpload={onUpload}
            isAIEnabled={isAIEnabled}
            emptyState={<EmptyState enabled={isAIEnabled} isAdmin={isAdmin} isUserVerified={isUserVerified} />}
            showDeleteButton={showDeleteButton}
            changes={changes}
            setInputValue={onChangeInput}
            inputValue={inputValue}
        />
    );
};

export default forwardRef(PromptConversationWithLocalState);
