import { GlideIcon } from "@glide/common";
import "twin.macro";
import { useBuilderTheme } from "../../hooks/use-builder-theme";
import type { ToastProps } from "./toast";
import { Toast } from "./toast";

type Props = {
    onClose: () => void;
    show: boolean;
    position?: ToastProps["position"];
    size?: ToastProps["size"];
    rowLimit: number;
};

const learnMoreLink = "https://www.glideapps.com/docs/reference/account/usage";

export const RowLimitWarningToast: React.FC<Props> = p => {
    const { onClose, show, size, position, rowLimit } = p;
    const theme = useBuilderTheme();
    const formattedRowLimit = rowLimit.toLocaleString();

    return (
        <Toast.Message size={size ?? "lg"} show={show} position={position ?? "bottom-center"} onClose={onClose}>
            <div tw="flex items-start">
                <GlideIcon
                    kind="stroke"
                    icon="st-alert-warning"
                    iconSize={36}
                    strokeColor={theme.textWarning}
                    strokeWidth={1.5}
                    tw="mr-3 shrink-0 text-text-warning bg-y300 rounded-full p-1.5"
                />
                <div tw="flex flex-1 items-center">
                    <Toast.Content tw="flex flex-col mr-2 text-builder-lg text-text-base">
                        <strong>Your app is over its row limit</strong>
                        <p>
                            Your app has more than {formattedRowLimit} rows in spreadsheet data sources. Deleting rows
                            or migrating to a high-scale data source such as Glide Big Tables will allow your app to
                            continue scaling.
                        </p>
                    </Toast.Content>
                    <Toast.Button
                        buttonType="secondary"
                        size="lg"
                        label="Learn more"
                        variant="default"
                        href={learnMoreLink}
                        tw="shrink-0"
                    />
                </div>
            </div>
            <Toast.Close onClose={onClose} />
        </Toast.Message>
    );
};
