import * as React from "react";

// This component will show a default "fallback" when a child component tries to throw.
// From the official docs: https://reactjs.org/docs/error-boundaries.html
// Intended for components that fail to lazily load.

interface Props extends React.PropsWithChildren {
    readonly fallback?: React.ReactNode;
}

interface State {
    readonly hasError: boolean;
}

const DefaultFallback: React.FC<React.PropsWithChildren> = () => <div />;

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error: unknown) {
        return { hasError: true };
    }

    componentDidCatch(_error: unknown, _errorInfo: any) {
        // FIXME: Fix errorInfo type, and log the error.
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback ?? <DefaultFallback />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
