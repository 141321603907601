import type { PropertyDescriptor } from "@glide/function-utils";
import type { ActionOutputDescriptor } from "@glide/app-description";

export enum ActionDisabledReason {
    PluginNotConfigured = "plugin-not-configured",
}

export enum ActionGroup {
    AI = "AI",
    Communication = "Communication",
    Computed = "Computed",
    Data = "Data",
    Integrations = "Integrations",
    Interaction = "Interaction",
    Navigation = "Navigation",
    WorkflowLogic = "Flow",
}

export interface ActionDescriptor {
    readonly name: string;
    readonly group?: ActionGroup | string;
    readonly groupItemOrder?: number;
    // FIXME: This belongs in the handler
    readonly needsScreenContext?: boolean;
    readonly properties: readonly PropertyDescriptor[];
    readonly outputs?: readonly ActionOutputDescriptor[];
    readonly isLegacy?: boolean;
    readonly quotaWarning?: string;
    // Once we have more reasons for why an action might be disabled we might
    readonly disabledReason?: ActionDisabledReason;
}
