import React from "react";
import { PlayerLoading } from "./player-loading";

const GlideAppPlayer = React.lazy(() => import("./glide-app-player"));

export const GlideAppPlayerLazy: React.VFC = () => {
    return (
        <React.Suspense fallback={<PlayerLoading />}>
            <GlideAppPlayer />
        </React.Suspense>
    );
};
