type ShouldLogFunction = () => boolean;

let shouldLog: ShouldLogFunction | undefined;

export function setShouldLog(newShouldLog: ShouldLogFunction | undefined): void {
    shouldLog = newShouldLog;
}

export function logInfo(...args: unknown[]): void {
    if (shouldLog === undefined || !shouldLog()) return;

    // eslint-disable-next-line no-console
    console.log(...args);
}

export function logDebug(...args: unknown[]): void {
    if (shouldLog === undefined || !shouldLog()) return;

    // eslint-disable-next-line no-console
    console.debug(...args);
}

export function logError(...args: unknown[]): void {
    if (shouldLog === undefined || !shouldLog()) return;

    // eslint-disable-next-line no-console
    console.error(...args); // We pass the args in directly so that you can inspect
}

export function logTrace(...args: unknown[]): void {
    if (shouldLog === undefined || !shouldLog()) return;

    // eslint-disable-next-line no-console
    console.trace(...args); // We pass the args in directly so that you can inspect
}
