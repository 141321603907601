import { nativeTableRowIDColumnName, type TableColumn, makePrimitiveType } from "@glide/type-schema";
import { defaultRowIDColumnName } from "./google-sheets";

export function makeNativeTableRowIDColumn(hidden: boolean): TableColumn {
    return {
        name: nativeTableRowIDColumnName,
        displayName: defaultRowIDColumnName,
        type: makePrimitiveType("string"),
        hidden,
        isUserSpecific: false,
        isProtected: false,
    };
}
