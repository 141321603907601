import "twin.macro";

import { getLocalizedString } from "@glide/localization";
import { isUploadFileResponseError } from "@glide/common-core/dist/js/components/types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { getGlideIcon, GlideIcon } from "@glide/common";
import { TextComponentStyle } from "@glide/component-utils";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { AppKind } from "@glide/location-common";
import { ConcurrencyLimiterWithBackpressure, ignore, isDefined, parseURL } from "@glide/support";
import { UIButtonAppearance, ValueChangeSource, type WireEditableValue } from "@glide/wire";
import { definedMap } from "collection-utils";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import * as React from "react";

import { Text } from "../../components/text/text";
import { WireButton } from "../wire-button/wire-button";
import { useFileUploader } from "../../utils/use-file-uploader";
import { useFieldStyles } from "../../utils/use-field-styles";

interface MultipleFilePickerProps {
    readonly title: string | undefined;
    readonly editableValue: WireEditableValue<readonly string[]>;
    readonly isRequired: boolean;
    readonly backend: WireBackendInterface;
}

export const MultipleFilePicker: React.VFC<MultipleFilePickerProps> = p => {
    const { title, editableValue, backend, isRequired } = p;
    const { value, onChangeToken } = editableValue;

    const { files, uploadFiles } = usePickerFiles(value, onChangeToken, backend);
    const hasFiles = !isEmpty(files);

    const hasTitleElement = isDefined(title) || isRequired;

    return (
        <div onClick={e => e.stopPropagation()}>
            {hasTitleElement && (
                <div tw="flex mb-2 justify-between">
                    {isDefined(title) ? (
                        <Text
                            element="h3"
                            variant={TextComponentStyle.small}
                            tw="font-semibold text-text-contextual-dark">
                            {title}
                        </Text>
                    ) : (
                        <div />
                    )}
                    {isRequired && (
                        <Text element="span" variant={TextComponentStyle.footnote}>
                            {getLocalizedString("required", backend.appKind)}
                        </Text>
                    )}
                </div>
            )}
            {hasFiles ? <FileList files={files} uploadFiles={uploadFiles} /> : <EmptyState uploadFiles={uploadFiles} />}
        </div>
    );
};

interface UploadInputProps {
    readonly uploadFiles: (files: File[] | null) => Promise<void>;
    readonly inputRef?: React.RefObject<HTMLInputElement>;
}

const UploadInput: React.VFC<UploadInputProps> = p => {
    const { uploadFiles, inputRef } = p;

    const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = ev.target.files;
        if (fileList === null) {
            return;
        }

        void uploadFiles([...fileList]);
    };

    return (
        <input
            ref={inputRef}
            data-testid="upload-files-input"
            tw="absolute w-0 h-0 invisible"
            type="file"
            multiple={true}
            onChange={onChange}
        />
    );
};

interface DragAndDropHandlers {
    handlers: {
        onDragOver: (e: React.DragEvent) => void;
        onDrop: (e: React.DragEvent) => void;
        onDragLeave: () => void;
    };
    isDraggingOver: boolean;
    draggedFiles: number;
}

function useDragAndDropFiles(uploadFiles: (files: File[] | null) => Promise<void>): DragAndDropHandlers {
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const [draggedFiles, setDraggedFiles] = React.useState(0);

    const onDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";

        const filesCount = e.dataTransfer.items.length;
        setDraggedFiles(filesCount);
        setIsDraggingOver(filesCount > 0);
    };

    const onDrop = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();

        const files = [...e.dataTransfer.files];

        void uploadFiles(files);
        setIsDraggingOver(false);
    };

    const onDragLeave = () => {
        setIsDraggingOver(false);
    };

    return {
        handlers: {
            onDragOver,
            onDrop,
            onDragLeave,
        },
        isDraggingOver,
        draggedFiles,
    };
}

interface EmptyStateProps {
    readonly uploadFiles: (files: File[] | null) => Promise<void>;
}

const EmptyState: React.VFC<EmptyStateProps> = p => {
    const { uploadFiles } = p;
    const { handlers, isDraggingOver, draggedFiles } = useDragAndDropFiles(uploadFiles);
    const fieldStyles = useFieldStyles();

    return (
        <div {...handlers}>
            <label
                className={isDraggingOver ? "dragging-over" : undefined}
                css={fieldStyles}
                tw="w-full flex py-2.5 px-3 relative cursor-pointer [&.dragging-over]:(border-text-contextual-accent ring-text-contextual-accent)">
                <UploadInput uploadFiles={uploadFiles} />
                <div tw="absolute inset-0 pointer-events-none flex justify-center items-center opacity-0 [.dragging-over &]:opacity-100">
                    <Text element="p" variant={TextComponentStyle.regular}>
                        Upload {draggedFiles} files
                    </Text>
                </div>
                <div tw="w-full flex items-center pointer-events-none transition-opacity [.dragging-over &]:opacity-0">
                    <GlideIcon kind="monotone" icon="mt-page-file-upload" iconSize={20} tw="mr-2.5 text-icon-base" />
                    <Text element="p" variant={TextComponentStyle.regular} tw="text-text-contextual-xpale select-none">
                        {getLocalizedString("chooseAFile", AppKind.Page)}
                    </Text>
                </div>
            </label>
        </div>
    );
};

interface PickerFile {
    readonly isLoading: boolean;
    readonly fileName: string;
    readonly onRemove: (() => void) | undefined;
}

interface UsePickerFiles {
    readonly files: readonly PickerFile[];
    readonly uploadFiles: (files: File[] | null) => Promise<void>;
}

function usePickerFiles(
    backendFiles: readonly string[],
    onChangeToken: string | undefined,
    backend: WireBackendInterface
): UsePickerFiles {
    const uploadAppFile = useFileUploader();
    const addedFiles = React.useRef<string[]>([]);

    React.useEffect(() => {
        // this effect avoids cases where the backend changes via
        // external update and files which were removed
        // are still part of addedFiles
        // https://github.com/glideapps/glide/issues/32714
        addedFiles.current = addedFiles.current.filter(file => backendFiles.includes(file));
    }, [backendFiles]);

    const pickerFilesFromBackend = backendFiles.map((f): PickerFile => {
        const onRemove = definedMap(onChangeToken, t => () => {
            const newFiles = backendFiles.filter(file => file !== f);
            addedFiles.current = addedFiles.current.filter(file => file !== f);
            const uniqueNewFiles = [...new Set([...newFiles, ...addedFiles.current])];
            backend.valueChanged(t, uniqueNewFiles, ValueChangeSource.User);
        });

        return {
            isLoading: false,
            fileName: f,
            onRemove,
        };
    });

    const [pickerFilesLoading, setPickerFilesLoading] = React.useState<PickerFile[]>([]);

    const files = React.useMemo(() => {
        return [...pickerFilesFromBackend, ...pickerFilesLoading];
    }, [pickerFilesFromBackend, pickerFilesLoading]);

    const filesRef = React.useRef(backendFiles);
    filesRef.current = backendFiles;

    const uploadFiles = React.useCallback(
        async (fileList: File[] | null) => {
            if (fileList === null || isEmpty(fileList) || onChangeToken === undefined) {
                return;
            }

            const uploadSessions = [...fileList].map(file => {
                const session = uploadAppFile(
                    backend.appID,
                    backend.appKind,
                    getAppFacilities(),
                    file,
                    "file-picker",
                    ignore,
                    ignore,
                    true
                );

                const fileName = file.name;

                const onRemove = () => {
                    session.cancel();
                    setPickerFilesLoading(removingCurrent => removingCurrent.filter(c => c.fileName !== fileName));
                };

                const pickerFile: PickerFile = {
                    fileName,
                    isLoading: true,
                    onRemove,
                };

                return {
                    session,
                    pickerFile,
                };
            });

            setPickerFilesLoading(current => {
                const newFiles = uploadSessions.map(s => s.pickerFile);
                return [...current, ...newFiles];
            });

            const limiter = new ConcurrencyLimiterWithBackpressure(5);
            void limiter.forEach(uploadSessions, async s => {
                const { session, pickerFile } = s;
                const response = await session.attempt();

                if (!isUploadFileResponseError(response)) {
                    addedFiles.current = [...addedFiles.current, response.path];
                    const uniqueNewFiles = [...new Set([...filesRef.current, ...addedFiles.current])];
                    backend.valueChanged(onChangeToken, uniqueNewFiles, ValueChangeSource.User);
                }

                pickerFile.onRemove?.();
            });
        },
        [backend, onChangeToken, uploadAppFile]
    );

    return {
        files,
        uploadFiles,
    };
}

interface FileListProps {
    readonly files: readonly PickerFile[];
    readonly uploadFiles: (files: File[] | null) => Promise<void>;
}

const FileList: React.VFC<FileListProps> = p => {
    const { files, uploadFiles } = p;

    const { handlers, isDraggingOver, draggedFiles } = useDragAndDropFiles(uploadFiles);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const triggerUpload = () => {
        inputRef.current?.click();
    };

    const fieldStyles = useFieldStyles();

    return (
        <>
            <div
                {...handlers}
                className={isDraggingOver ? "dragging-over" : undefined}
                css={fieldStyles}
                tw="w-full relative py-2.5 px-3">
                <div tw="absolute inset-0 pointer-events-none flex justify-center items-center opacity-0 transition-all [.dragging-over &]:opacity-100 backdrop-blur-2xl z-10">
                    <Text element="p" variant={TextComponentStyle.regular}>
                        Add {draggedFiles} files
                    </Text>
                </div>
                <div tw="flex flex-col">
                    {files.map((file, idx) => {
                        let label = file.fileName;

                        const uri = parseURL(file.fileName);
                        if (uri !== undefined) {
                            const fileName = last(uri.pathname.split("/"));
                            if (fileName !== undefined) {
                                label = fileName;
                            }
                        }

                        return (
                            <div
                                key={idx}
                                data-testid="picker-file"
                                tw="mb-2.5 pb-2.5 border-b border-border-pale last-of-type:(border-none mb-0 pb-0)">
                                <div
                                    onClick={e => e.stopPropagation()}
                                    className={file.isLoading ? "uploading" : "uploaded"}
                                    tw="w-full flex items-center justify-between [&.uploading]:opacity-50">
                                    <div tw="flex items-center overflow-hidden">
                                        <GlideIcon
                                            kind="monotone"
                                            icon="mt-page-file-upload"
                                            iconSize={20}
                                            tw="mr-2.5 shrink-0 text-text-contextual-pale"
                                        />
                                        <Text
                                            data-testid={`file-from-picker-${idx}`}
                                            element="p"
                                            variant={TextComponentStyle.small}
                                            tw="text-text-contextual-base truncate">
                                            {label}
                                        </Text>
                                    </div>
                                    {isDefined(file.onRemove) && (
                                        <RemoveButton onClick={file.onRemove} testIdIndex={idx} />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <WireButton
                onClick={triggerUpload}
                appearance={UIButtonAppearance.MinimalPrimary}
                iconName={getGlideIcon("st-plus-add")}
                tw="py-1! my-0.5"
                iconPlacement="right">
                Add
            </WireButton>
            <UploadInput uploadFiles={uploadFiles} inputRef={inputRef} />
        </>
    );
};

interface RemoveButtonProps {
    readonly onClick: () => void;
    readonly testIdIndex: number;
}

const RemoveButton: React.VFC<RemoveButtonProps> = p => {
    const { onClick, testIdIndex } = p;

    return (
        <button data-testid={`remove-file-${testIdIndex}`} onClick={onClick} tw="pl-2 shrink-0">
            <div tw="rounded-full bg-n600 w-[18px] h-[18px] flex items-center justify-center">
                <GlideIcon kind="stroke" icon="st-close" iconSize={12} tw="text-white" />
            </div>
        </button>
    );
};
