import type {
    GroundValue,
    LoadedGroundValue,
    LoadingValue,
    Query,
    QueryBase,
    Unbound,
} from "@glide/computation-model-types";
import {
    asMaybeDate,
    asMaybeNumber,
    asString,
    getSerializableForPrimitive,
    isLoadingValue,
    isBound,
} from "@glide/computation-model-types";
import type {
    SourceColumn,
    TableColumn,
    TableGlideType,
    FilterColumn as QueryColumn,
    FilterCondition as QueryCondition,
} from "@glide/type-schema";
import {
    BinaryPredicateFormulaOperator,
    BinaryPredicateCompositeOperator,
    SourceColumnKind,
    UnaryPredicateFormulaOperator,
    getSourceColumnSinglePath,
    getTableColumn,
    isSourceColumn,
} from "@glide/type-schema";
import { UnaryPredicateCompositeOperator } from "@glide/app-description";
import type { GlideDateTime } from "@glide/data-types";
import { convertValueToSerializable, isGlideJSONDocumentData } from "@glide/data-types";
import type {
    BinaryPredicateValueSpecification,
    PredicateCombinationSpecification,
    PredicateSpecification,
} from "./formula-specifications";
import { BinaryPredicateValueKind } from "./formula-specifications";
import { assert, assertNever, mapFilterUndefined } from "@glideapps/ts-necessities";

export interface QueryValueInflator<THydration> {
    makeNonDefaultSourceColumnGetter(sc: SourceColumn): ((hb: THydration) => GroundValue | Unbound) | undefined;
    makeVerifiedEmailAddressGetter(): (hb: THydration) => string | LoadingValue | undefined;
}

function makeQueryColumn(table: TableGlideType | readonly TableColumn[], sc: SourceColumn): QueryColumn | undefined {
    if (sc.kind !== SourceColumnKind.DefaultContext) return undefined;
    const columnName = getSourceColumnSinglePath(sc);
    if (columnName === undefined) return undefined;
    if (getTableColumn(table, columnName) === undefined) return undefined;
    return { columnName };
}

function inflateQueryRHS<THydration, T>(
    valueInflator: QueryValueInflator<THydration>,
    table: TableGlideType | readonly TableColumn[],
    spec: BinaryPredicateValueSpecification,
    convert: (v: LoadedGroundValue) => T | undefined,
    makeDateTime?: (what: BinaryPredicateValueKind.Now | BinaryPredicateValueKind.Today) => T | undefined
): ((hb: THydration) => QueryColumn | T | LoadingValue | undefined) | undefined {
    if (isSourceColumn(spec)) {
        if (
            spec.kind === SourceColumnKind.UserProfile ||
            spec.kind === SourceColumnKind.ContainingScreen ||
            spec.kind === SourceColumnKind.ActionNodeOutput
        ) {
            const getter = valueInflator.makeNonDefaultSourceColumnGetter(spec);
            if (getter === undefined) return undefined;
            return hb => {
                const v = getter(hb);
                if (isLoadingValue(v)) return v;
                if (!isBound(v)) return undefined;
                return convert(v);
            };
        } else if (spec.kind === SourceColumnKind.DefaultContext) {
            const queryColumn = makeQueryColumn(table, spec);
            if (queryColumn === undefined) return undefined;
            return () => queryColumn;
        } else {
            return assertNever(spec.kind);
        }
    } else if (spec.kind === BinaryPredicateValueKind.Literal) {
        const value = convert(spec.value);
        if (value === undefined) return undefined;
        return () => value;
    } else if (spec.kind === BinaryPredicateValueKind.Now || spec.kind === BinaryPredicateValueKind.Today) {
        return () => makeDateTime?.(spec.kind);
    } else {
        return assertNever(spec.kind);
    }
}

export function inflateConditionPredicate<THydration>(
    valueInflator: QueryValueInflator<THydration>,
    table: TableGlideType | readonly TableColumn[],
    pred: PredicateSpecification
): ((hb: THydration) => QueryCondition | LoadingValue | undefined) | undefined {
    if (pred.kind === "unary") {
        // FIXME: Currently we don't support special values in query conditions.
        // We should remove this once we do, if we do.
        assert(isSourceColumn(pred.column));

        const column = makeQueryColumn(table, pred.column);
        if (column === undefined) return undefined;

        switch (pred.operator) {
            case UnaryPredicateCompositeOperator.IsEmpty:
            case UnaryPredicateFormulaOperator.IsNotEmpty: {
                const negated = pred.operator !== UnaryPredicateCompositeOperator.IsEmpty;
                return () => ({ kind: "is-empty", column, negated });
            }
            case UnaryPredicateFormulaOperator.IsTruthy:
            case UnaryPredicateCompositeOperator.IsFalsey: {
                const negated = pred.operator !== UnaryPredicateFormulaOperator.IsTruthy;
                return () => ({ kind: UnaryPredicateFormulaOperator.IsTruthy, column, negated });
            }
            case UnaryPredicateCompositeOperator.MatchesVerifiedEmailAddress:
            case UnaryPredicateCompositeOperator.DoesNotMatchVerifiedEmailAddress: {
                const negated = pred.operator !== UnaryPredicateCompositeOperator.MatchesVerifiedEmailAddress;
                const getter = valueInflator.makeVerifiedEmailAddressGetter();
                return hb => {
                    const email = getter(hb);
                    if (email === undefined || isLoadingValue(email)) return email;
                    return {
                        kind: BinaryPredicateFormulaOperator.MatchesEmailAddress,
                        lhs: column,
                        rhs: email,
                        negated,
                    };
                };
            }
            default:
                return assertNever(pred);
        }
    }

    // FIXME: Currently we don't support special values in query conditions.
    // We should remove this once we do, if we do.
    assert(isSourceColumn(pred.column));

    const lhs = makeQueryColumn(table, pred.column);
    if (lhs === undefined) return undefined;

    switch (pred.operator) {
        case BinaryPredicateFormulaOperator.Equals:
        case BinaryPredicateCompositeOperator.DoesNotEqual: {
            const negated = pred.operator !== BinaryPredicateFormulaOperator.Equals;

            const rhsHydrator = inflateQueryRHS(valueInflator, table, pred.value, x => getSerializableForPrimitive(x));
            if (rhsHydrator === undefined) return undefined;

            return hb => {
                const rhs = rhsHydrator(hb);
                if (isLoadingValue(rhs)) return rhs;
                if (rhs === undefined || rhs === "") {
                    // The empty string and `undefined` must act the same way.
                    // Comparing a value to either of them is the same as
                    // checking whether the value is empty.
                    return { kind: "is-empty", column: lhs, negated };
                }
                return { kind: BinaryPredicateFormulaOperator.Equals, lhs, rhs, negated };
            };
        }
        case BinaryPredicateFormulaOperator.ContainsString:
        case BinaryPredicateFormulaOperator.IsContainedInString:
        case BinaryPredicateFormulaOperator.MatchesEmailAddress:
        case BinaryPredicateCompositeOperator.DoesNotContainString:
        case BinaryPredicateCompositeOperator.IsNotContainedInString: {
            const rhsHydrator = inflateQueryRHS(valueInflator, table, pred.value, asString);
            if (rhsHydrator === undefined) return undefined;

            const kind =
                pred.operator === BinaryPredicateCompositeOperator.DoesNotContainString
                    ? BinaryPredicateFormulaOperator.ContainsString
                    : pred.operator === BinaryPredicateCompositeOperator.IsNotContainedInString
                    ? BinaryPredicateFormulaOperator.IsContainedInString
                    : pred.operator;
            const negated = kind !== pred.operator;

            return hb => {
                const rhs = rhsHydrator(hb);
                if (rhs === undefined || isLoadingValue(rhs)) return rhs;
                return { kind, lhs, rhs, negated };
            };
        }
        case BinaryPredicateFormulaOperator.IsLessThan:
        case BinaryPredicateFormulaOperator.IsGreaterThan:
        case BinaryPredicateFormulaOperator.IsLessOrEqualTo:
        case BinaryPredicateFormulaOperator.IsGreaterOrEqualTo: {
            const { operator } = pred;
            const rhsHydrator = inflateQueryRHS(valueInflator, table, pred.value, asMaybeNumber);
            if (rhsHydrator === undefined) return undefined;

            return hb => {
                const rhs = rhsHydrator(hb);
                if (rhs === undefined || isLoadingValue(rhs)) return rhs;
                return { kind: operator, lhs, rhs, negated: false };
            };
        }
        case BinaryPredicateCompositeOperator.IsBefore:
        case BinaryPredicateCompositeOperator.IsAfter:
        case BinaryPredicateCompositeOperator.IsOnOrBefore:
        case BinaryPredicateCompositeOperator.IsOnOrAfter:
        case BinaryPredicateCompositeOperator.IsWithin: {
            const kind = pred.operator;
            const rhsHydrator = inflateQueryRHS<THydration, GlideDateTime | "now" | "start-of-today" | "end-of-today">(
                valueInflator,
                table,
                pred.value,
                asMaybeDate,
                what => {
                    if (what === BinaryPredicateValueKind.Now) {
                        return "now";
                    } else if (what === BinaryPredicateValueKind.Today) {
                        if (kind === BinaryPredicateCompositeOperator.IsBefore) {
                            return "start-of-today";
                        } else if (kind === BinaryPredicateCompositeOperator.IsAfter) {
                            return "end-of-today";
                        } else {
                            return "now";
                        }
                    } else {
                        return assertNever(what);
                    }
                }
            );
            if (rhsHydrator === undefined) return undefined;

            return hb => {
                const maybeRHS = rhsHydrator(hb);
                if (maybeRHS === undefined || isLoadingValue(maybeRHS)) return maybeRHS;
                const rhs = convertValueToSerializable(maybeRHS);
                // This should never happen because maybeRHS should never be a GlideJSON
                assert(!isGlideJSONDocumentData(rhs));
                return { kind, lhs, rhs, negated: false };
            };
        }
        case BinaryPredicateFormulaOperator.ArrayIncludes:
        case BinaryPredicateCompositeOperator.ArrayDoesNotInclude: {
            // We don't yet support ##arrayIncludesInQueries.
            return undefined;

            // const negated = pred.operator === BinaryPredicateCompositeOperator.ArrayDoesNotInclude;

            // const rhsHydrator = this.inflateQueryRHS(valueInflator, pred.value, v => {
            //     if (v === undefined || v === "") return undefined;
            //     if (isPrimitive(v)) return [v];
            //     if (isArray(v)) {
            //         return v.filter(i => i !== undefined && i !== "");
            //     }
            //     return undefined;
            // });
            // if (rhsHydrator === undefined) return undefined;

            // return hb => {
            //     const maybeRHS = rhsHydrator(hb);
            //     if (maybeRHS === undefined || isLoadingValue(maybeRHS)) return maybeRHS;
            //     const rhs = isArray(maybeRHS)
            //         ? filterUndefined(maybeRHS.map(v => (v instanceof GlideDateTime ? v.toDocumentData() : v)))
            //         : maybeRHS;
            //     // FIXME: Convert `rhs` to the type of the column
            //     return { kind: BinaryPredicateFormulaOperator.ArrayIncludes, lhs, rhs, negated };
            // };
        }
        default:
            return assertNever(pred);
    }
}

export function inflateQueryConditions<THydration, TQuery extends QueryBase = Query>(
    valueInflator: QueryValueInflator<THydration>,
    table: TableGlideType,
    spec: PredicateCombinationSpecification
): (hb: THydration, q: TQuery) => TQuery | LoadingValue | undefined {
    const conditionHydrators = mapFilterUndefined(spec.predicates, p =>
        inflateConditionPredicate(valueInflator, table, p)
    );
    if (spec.combinator === "or") {
        return (hb, q) => {
            const conditions: QueryCondition[] = [];
            let loadingValue: LoadingValue | undefined;
            for (const conditionHydrator of conditionHydrators) {
                const condition = conditionHydrator(hb);
                if (condition === undefined) continue;
                if (isLoadingValue(condition)) {
                    loadingValue = condition;
                    continue;
                }
                conditions.push(condition);
            }
            // An "or" without conditions is `false`.
            if (conditions.length === 0) return loadingValue;
            return q.withCondition(conditions);
        };
    } else {
        return (hb, q) => {
            for (const conditionHydrator of conditionHydrators) {
                const condition = conditionHydrator(hb);
                if (condition === undefined || isLoadingValue(condition)) return condition;
                q = q.withCondition(condition);
            }
            return q;
        };
    }
}
