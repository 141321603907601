import { WireButton } from "../../renderers/wire-button/wire-button";
import { UIButtonAppearance } from "@glide/wire";
import { Img } from "../img/img";
import { WireMenuButton } from "../../renderers/wire-menu-button/wire-menu-button";
import { isDefined } from "@glide/support";
import { MadeWithGlide } from "../made-with-glide";
import type { UnifiedNavBarProps } from "../../chrome/nav-bar/nav-bar-common";
import classNames from "classnames";
import { css } from "styled-components";
import tw from "twin.macro";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

interface SignUpOrUserProfileProps
    extends Pick<
        UnifiedNavBarProps,
        | "onSignUpPressed"
        | "onSignInPressed"
        | "onSignOutPressed"
        | "onUserButtonPressed"
        | "signedInUserImage"
        | "signedInUserName"
        | "signedInUserEmail"
    > {
    onClose: () => void;
    removeBranding: boolean;
}

export const MobileSignUpOrUserProfile: React.VFC<SignUpOrUserProfileProps> = p => {
    const {
        onSignInPressed,
        onSignUpPressed,
        onUserButtonPressed,
        signedInUserEmail,
        signedInUserImage,
        signedInUserName,
        onClose,
        onSignOutPressed,
        removeBranding,
    } = p;
    const theme = useWireAppTheme();
    const closeAfter = (fn: () => void) => () => {
        fn();
        onClose();
    };
    return (
        <div
            tw="relative flex flex-col items-start pb-20 mx-2"
            css={css`
                &.no-branding {
                    ${tw`pb-6`}
                }
            `}
            className={classNames(
                `user-controls-${(theme.pageTheme ?? "accent").toLowerCase()}`,
                removeBranding && "no-branding"
            )}
        >
            {isDefined(onSignUpPressed) && (
                <WireButton
                    onClick={closeAfter(onSignUpPressed)}
                    appearance={UIButtonAppearance.Filled}
                    tw="w-full mb-1"
                >
                    {getLocalizedString("signUp", AppKind.Page)}
                </WireButton>
            )}
            {isDefined(onSignInPressed) && (
                <WireButton
                    onClick={closeAfter(onSignInPressed)}
                    appearance={UIButtonAppearance.Bordered}
                    tw="w-full mt-1"
                >
                    {getLocalizedString("signIn", AppKind.Page)}
                </WireButton>
            )}
            {isDefined(onUserButtonPressed) && isDefined(onSignOutPressed) && (
                <div tw="flex items-center w-full">
                    <button
                        className="menu-item"
                        onClick={closeAfter(onUserButtonPressed)}
                        tw="flex w-full items-center py-2.5 pl-2 pr-11 rounded-lg active:bg-n100A"
                    >
                        {signedInUserImage !== undefined && (
                            <Img tw="rounded-full w-9 h-9 object-cover" src={signedInUserImage} />
                        )}
                        <div tw="ml-2.5 overflow-hidden">
                            <p
                                tw="whitespace-nowrap overflow-ellipsis overflow-hidden font-semibold text-base
                                    text-left text-text-base"
                            >
                                {signedInUserName}
                            </p>
                            <p
                                tw="whitespace-nowrap overflow-ellipsis overflow-hidden text-sm text-text-pale ml-0.5
                                    font-size[12px]"
                            >
                                {signedInUserEmail}
                            </p>
                        </div>
                    </button>
                    <WireMenuButton
                        tw="transform rotate-90 absolute right-0"
                        menuItems={[
                            {
                                title: getLocalizedString("viewProfile", AppKind.Page),
                                enabled: true,
                                run: closeAfter(onUserButtonPressed),
                            },
                            {
                                title: getLocalizedString("signOut", AppKind.Page),
                                enabled: true,
                                run: closeAfter(onSignOutPressed),
                            },
                        ]}
                        defaultPlacement="top-start"
                        appearance={UIButtonAppearance.Floating}
                    />
                </div>
            )}
            {!removeBranding && <MadeWithGlide className="mobile" tw="left-2 right[unset] bottom-10" />}
        </div>
    );
};
