const publicKey = "pk_24451a94b0b5aaff552b7b7a42ca2c18ff19";

export const initKoala = () => {
    const script = `
    !(function (t) {
      if (window.ko) return;
      (window.ko = []),
        [
          "identify",
          "track",
          "removeListeners",
          "open",
          "on",
          "off",
          "qualify",
          "ready",
        ].forEach(function (t) {
          ko[t] = function () {
            var n = [].slice.call(arguments);
            return n.unshift(t), ko.push(n), ko;
          };
        });
      var n = document.createElement("script");
      (n.async = !0),
        n.setAttribute(
          "src",
          "https://cdn.getkoala.com/v1/${publicKey}/sdk.js"
        ),
        (document.body || document.head).appendChild(n);
    })();
  `;

    const scriptElement = document.createElement("script");
    scriptElement.innerHTML = script;
    document.head.appendChild(scriptElement);
};

export const trackKoalaEvent = (eventName: string, properties?: Record<string, any>) => {
    if (window?.ko !== undefined) {
        window.ko.track(eventName, properties);
    }
};

export const identifyKoalaUser = (userId: string, traits?: Record<string, any>) => {
    if (window?.ko !== undefined) {
        window.ko.identify(userId, traits);
    }
};

declare global {
    interface Window {
        ko: {
            identify: (userId: string, traits?: Record<string, any>) => void;
            track: (eventName: string, properties?: Record<string, any>) => void;
            removeListeners: () => void;
            open: () => void;
            on: (event: string, callback: Function) => void;
            off: (event: string, callback: Function) => void;
            qualify: (callback: Function) => void;
            ready: (callback: Function) => void;
            push: (args: any[]) => void;
        };
    }
}
