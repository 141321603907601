import { ErrorBoundary } from "@glide/common";
import { lazilyLoadedComponent } from "@glide/common-core/dist/js/support/react-lazy-loading";
import type { WireVideoComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isDefined } from "@glide/support";
import * as React from "react";
import "twin.macro";
import { WiredAspectContainer } from "../wire-image/wire-image";
import type { WireRenderer } from "../wire-renderer";

const VideoPlayer = lazilyLoadedComponent("video-player", () => import("../../components/video-player/video-player"));

export const WireVideo: WireRenderer<WireVideoComponent> = p => {
    const { video, aspectRatio } = p;
    if (!isDefined(video)) return null;
    return (
        <ErrorBoundary>
            <React.Suspense fallback={<div />}>
                <WiredAspectContainer {...p} aspectRatio={aspectRatio}>
                    <VideoPlayer url={video} tw="absolute! inset-0! w-full! h-full! object-cover!" />
                </WiredAspectContainer>
            </React.Suspense>
        </ErrorBoundary>
    );
};
