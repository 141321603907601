import {
    type AppDescription,
    type UserProfileDescription,
    getColumnProperty,
    getTableProperty,
} from "@glide/app-description";
import { type TableGlideType, type TypeSchema, findTable, type UserProfileTableInfo } from "@glide/type-schema";

export function getUserProfileTableInfo({
    userProfile,
}: {
    readonly userProfile?: UserProfileDescription;
}): UserProfileTableInfo | undefined {
    if (userProfile === undefined) return undefined;

    const tableName = getTableProperty(userProfile.userProfileTable);
    const emailColumnName = getColumnProperty(userProfile.emailColumn);
    const nameColumnName = getColumnProperty(userProfile.nameColumn);
    const imageColumnName = getColumnProperty(userProfile.imageColumn);
    const rolesColumnName = getColumnProperty(userProfile.rolesColumn);

    if (
        tableName === undefined ||
        emailColumnName === undefined ||
        nameColumnName === undefined ||
        imageColumnName === undefined
    ) {
        return undefined;
    }

    return {
        tableName,
        emailColumnName,
        nameColumnName,
        imageColumnName,
        rolesColumnName,
    };
}

export function getUserProfileTableForAppAndSchema(
    appDescription: AppDescription | undefined,
    schema: TypeSchema | undefined
): TableGlideType | undefined {
    if (schema === undefined) return undefined;
    if (appDescription === undefined) return undefined;
    const userProfileTableName = getUserProfileTableInfo(appDescription)?.tableName;
    if (userProfileTableName === undefined) return undefined;
    return findTable(schema, userProfileTableName);
}

export interface UserProfileRow {
    readonly email: string;
    readonly name: string | undefined;
    readonly imageURL: string | undefined;
    readonly roles: readonly string[];
    readonly key: string;
}
