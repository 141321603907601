import { AppIcon } from "@glide/common";
import { TextComponentStyle } from "@glide/component-utils";
import type { WireLinkComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { LinkStyle } from "@glide/wire";
import chroma from "chroma-js";
import classNames from "classnames";
import * as React from "react";
import { css } from "styled-components";
import "twin.macro";

import { Text } from "../../components/text/text";
import type { WireRenderer } from "../wire-renderer";
import { isArray, isDefined, isEmptyOrUndefinedish, parseURL } from "@glide/support";
import last from "lodash/last";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

export const WireLink: WireRenderer<WireLinkComponent> = React.memo(p => {
    const { icon, caption, linkTo, title, style } = p;

    const theme = useWireAppTheme();

    if (isArray(linkTo)) {
        return <MultipleLinks links={linkTo} icon={icon} />;
    }

    if (isEmptyOrUndefinedish(linkTo)) {
        return null;
    }

    if (style === LinkStyle.Row) {
        const hasCaption = isDefined(caption) && caption.length > 0;
        const hasTitle = isDefined(title) && title.length > 0;
        return (
            <a
                href={linkTo}
                target="_blank"
                rel="noreferrer"
                tw="flex items-stretch gap-x-6 transition-colors px-4 py-2 -mx-4 rounded-none page-hover:bg-bg-hovered
                    active:bg-bg-hovered page-sm:rounded-lg page-md:(p-2 -mx-2)"
            >
                <div tw="flex flex-col flex-1 overflow-auto self-stretch justify-center break-words">
                    <Text tw="text-text-contextual-base" variant={TextComponentStyle.regular}>
                        {title}
                    </Text>
                    <Text tw="text-text-contextual-pale" element="p" variant={TextComponentStyle.regular}>
                        {caption}
                    </Text>
                </div>
                <div tw="flex items-center [max-height:42px]">
                    <div
                        className={classNames({ "with-caption": hasCaption, "with-title": hasTitle })}
                        tw="w-8 h-8 rounded-full text-text-contextual-accent flex justify-center items-center"
                        css={css`
                            background-color: ${chroma(theme.textContextualAccent).alpha(0.1).css()};
                        `}
                    >
                        {icon && <AppIcon icon={icon} size={20} />}
                    </div>
                </div>
            </a>
        );
    } else {
        return <SingleCompactLink link={linkTo} icon={icon} title={title} />;
    }
});

interface SingleCompactLinkProps {
    link: string;
    title: string | undefined | null;
    icon: string | undefined;
}

const SingleCompactLink: React.VFC<SingleCompactLinkProps> = p => {
    const { link, icon, title } = p;

    const theme = useWireAppTheme();

    return (
        <a
            href={link}
            target="_blank"
            rel="noreferrer"
            css={css`
                --bg-color: ${chroma(theme.textContextualAccent).alpha(0.07).css()};
                --bg-hovered-color: ${chroma(theme.textContextualAccent).alpha(0.12).css()};
            `}
            tw="flex items-center text-text-contextual-accent rounded-full w-max py-0.5 pl-2 pr-3 transition-colors max-w-full [background-color: var(--bg-color)] page-hover:([background-color: var(--bg-hovered-color)])"
        >
            {isDefined(icon) && <AppIcon icon={icon} size={20} tw="mr-1" />}
            <p tw="text-sm truncate">{title}</p>
        </a>
    );
};

interface MultipleLinksProps {
    links: string[];
    icon: string | undefined;
}

const MultipleLinks: React.VFC<MultipleLinksProps> = p => {
    const { links, icon } = p;

    if (links.length === 0) {
        return null;
    }

    return (
        <div tw="flex flex-col gap-2">
            {links.map((link, idx) => {
                let title = link;

                const uri = parseURL(link);
                if (uri !== undefined) {
                    const fileName = last(uri.pathname.split("/"));
                    if (fileName !== undefined) {
                        title = fileName;
                    }
                }

                return <SingleCompactLink key={idx} link={link} icon={icon} title={title} />;
            })}
        </div>
    );
};
