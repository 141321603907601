import React from "react";

type StopwatchLog = {
    startedOn: Date;
    stoppedOn?: Date;
};

export type ElapsedTime = {
    milliseconds: number;
    seconds: number;
};

type ElapsedTimeRef = React.MutableRefObject<ElapsedTime>;

export type StopwatchState = {
    isRunning: boolean;
    fauxElapsedTime: number;
    logs: StopwatchLog[];
};

type StopwatchHook = StopwatchState & {
    start: () => void;
    stop: () => void;
    reset: () => void;
    elapsedTime: ElapsedTime;
};

function useElapsedTime(initialElapsedTime: number = 0, isRunning: boolean = false): ElapsedTimeRef {
    // react internal state will always be too slow to track actual elapsed time
    const elapsedTimeRef = React.useRef<ElapsedTime>({
        milliseconds: initialElapsedTime,
        seconds: Math.floor(initialElapsedTime / 1000),
    });
    const startTimeRef = React.useRef<number>();
    const frameRef = React.useRef<number>();

    React.useEffect(() => {
        if (isRunning) {
            startTimeRef.current = performance.now() - elapsedTimeRef.current.milliseconds;

            const tick = () => {
                const now = performance.now();
                const elapsed = now - (startTimeRef.current ?? now);
                elapsedTimeRef.current = {
                    milliseconds: Math.round(elapsed),
                    seconds: Math.floor(elapsed / 1000),
                };
                frameRef.current = requestAnimationFrame(tick);
            };
            frameRef.current = requestAnimationFrame(tick);
        }

        return () => {
            if (frameRef.current !== undefined) {
                cancelAnimationFrame(frameRef.current);
            }
        };
    }, [isRunning]);

    return elapsedTimeRef;
}

export function useStopwatch(
    initialState: StopwatchState,
    initialElapsedTime: number,
    onChange: (newState: StopwatchState, elapsedTimeFromStopwatch: ElapsedTime) => void
): StopwatchHook {
    const [state, setState] = React.useState<StopwatchState>(initialState);
    const elapsedTimeRef = useElapsedTime(initialElapsedTime, state.isRunning);
    // This "faux" state allows us to re-render the ref in react which
    // needs to exist outside react state to preserve accuracy
    const [_, setFauxElapsedTime] = React.useState<number>(0);
    React.useEffect(() => {
        // if we want to "show" hundredths ticking by, 10ms interval targets that
        // but unfortunately it is not sufficient for computing actual elapsed time
        // inside of the react effect loop
        const interval = setInterval(() => {
            setFauxElapsedTime(elapsedTimeRef.current.milliseconds);
        }, 10);
        return () => clearInterval(interval);
    }, [elapsedTimeRef]);

    const start = () => {
        const startedOn = new Date();
        const newLogs = [...state.logs, { startedOn, stoppedOn: undefined }];
        const nextState = { ...state, isRunning: true, logs: newLogs };
        setState(nextState);
        onChange(nextState, elapsedTimeRef.current);
    };

    const stop = () => {
        const stoppedOn = new Date();
        const updatedLogs = [...state.logs];
        const lastLog = updatedLogs[updatedLogs.length - 1];
        lastLog.stoppedOn = stoppedOn;
        const nextState = { ...state, isRunning: false, logs: updatedLogs };
        setState(nextState);
        onChange(nextState, elapsedTimeRef.current);
    };

    const reset = () => {
        const defaultState = { isRunning: false, logs: [], fauxElapsedTime: 0 };
        setState(defaultState);
        onChange(defaultState, { milliseconds: 0, seconds: 0 });
        elapsedTimeRef.current = { milliseconds: 0, seconds: 0 };
    };

    return {
        ...state,
        elapsedTime: elapsedTimeRef.current,
        start,
        stop,
        reset,
    };
}

export function computeElapsedSecondsFromLogEntires(logs: StopwatchLog[]): number {
    // sum the duration of the previous logs using the running time start/stop
    const logWithoutStop = logs.find(log => log.stoppedOn === undefined);
    const logsWithStop = logs.filter(log => log.stoppedOn !== undefined);
    let loggedDuration: number = 0;

    logsWithStop.forEach(entry => {
        if (entry.stoppedOn === undefined) return;
        loggedDuration += Math.floor((entry.stoppedOn.getTime() - entry.startedOn.getTime()) / 1000);
    });

    const now = new Date();
    const lastStartedOn = logWithoutStop?.startedOn ?? now; // we use now if all the logs are stopped
    const elapsed = Math.floor((now.getTime() - lastStartedOn.getTime()) / 1000);

    return loggedDuration + elapsed;
}
