export enum MapLocationKind {
    LatLong,
    Address,
}

interface LatLongMapLocation {
    readonly kind: MapLocationKind.LatLong;
    readonly latitude: number;
    readonly longitude: number;
}

interface AddressMapLocation {
    readonly kind: MapLocationKind.Address;
    readonly address: string;
}

export type MapLocation = LatLongMapLocation | AddressMapLocation;

export enum ImageSourceKind {
    URLs,
    GeoCoordinates,
}

interface URLsImageSource {
    readonly kind: ImageSourceKind.URLs;
    readonly urls: ReadonlyArray<string>;
}

export interface MapImageSource {
    readonly kind: ImageSourceKind.GeoCoordinates;
    readonly location: MapLocation;
}

export type ImageSource = URLsImageSource | MapImageSource;
