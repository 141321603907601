import {
    type ColumnType,
    type Description,
    type TableColumn,
    type TableGlideType,
    isStringTypeOrStringTypeArray,
    getCompoundColumns,
    getNonHiddenColumns,
    getPrimitiveOrPrimitiveArrayNonHiddenColumns,
    isPrimitiveArrayType,
    isPrimitiveType,
    type SchemaInspector,
} from "@glide/type-schema";

export type GetAllowedColumnsFunction = (
    table: TableGlideType,
    desc: Description | undefined,
    schema: SchemaInspector
) => readonly TableColumn[];

/**
 * We use this to determine which columns or action step outputs are allowed
 * in a particular situation, usually as a binding in a component or action.
 * The way these are applied in the case of a table is that we call
 * `getCandidateColumns` and then we filter the returned columns by their
 * types with `columnTypeIsAllowed`.  Previous action step outputs don't live
 * in tables, so in those cases we only filter by the type with
 * `columnTypeIsAllowed`.  Anything that can be used in an action has to
 * implement `columnTypeIsAllowed` properly.  If a property is only used in
 * components, it's (currently) fine if `getCandidateColumns` also does the
 * type filtering and `columnTypeIsAllowed` just returns `true`.  Note that
 * it's also fine in all cases if both functions filter by type, as long as
 * they use the same logic.
 */
export interface ColumnFilterSpec {
    readonly getCandidateColumns: GetAllowedColumnsFunction;
    readonly columnTypeIsAllowed: (t: ColumnType, s: SchemaInspector) => boolean;
}

export function applyColumnFilterSpec(spec: ColumnFilterSpec): GetAllowedColumnsFunction {
    return (table, desc, schema) =>
        spec.getCandidateColumns(table, desc, schema).filter(c => spec.columnTypeIsAllowed(c.type, schema));
}

export const getPrimitiveColumnsSpec: ColumnFilterSpec = {
    columnTypeIsAllowed: isPrimitiveType,
    getCandidateColumns: table => table.columns,
};

export const getPrimitiveNonHiddenColumnsSpec: ColumnFilterSpec = {
    columnTypeIsAllowed: isPrimitiveType,
    getCandidateColumns: table => getNonHiddenColumns(table),
};

export const getCompoundColumnsSpec: ColumnFilterSpec = {
    getCandidateColumns: getCompoundColumns,
    columnTypeIsAllowed: t => !isPrimitiveType(t),
};

export const getPrimitiveOrPrimitiveArrayNonHiddenColumnsSpec: ColumnFilterSpec = {
    getCandidateColumns: table => getPrimitiveOrPrimitiveArrayNonHiddenColumns(table),
    columnTypeIsAllowed: t => isPrimitiveType(t) || isPrimitiveArrayType(t),
};

export const getStringTypeOrStringTypeArrayColumnsSpec: ColumnFilterSpec = {
    columnTypeIsAllowed: isStringTypeOrStringTypeArray,
    getCandidateColumns: t => t.columns,
};
