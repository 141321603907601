import type { EminenceFlags } from "@glide/billing-types";
import { getFeatureSetting } from "@glide/common-core";
import {
    type TableColumn,
    type TableGlideType,
    getPrimitiveNonHiddenColumns,
    getTableName,
    isPrimitiveType,
    isUserAgnosticDataColumn,
    type UserProfileTableInfo,
    canTableBeUserProfiles,
} from "@glide/type-schema";
import {
    type UserProfileDescription,
    makeColumnProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import {
    type ColumnFilterSpec,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
    TablePropertyHandler,
    findFittingColumnInTable,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { definedMap } from "@glideapps/ts-necessities";
import { canBeRoleOrOwnersColumn, isRoleOrOwnersColumnType } from "./description-utils";

export function makeUserProfileDataForTable(table: TableGlideType): UserProfileDescription | undefined {
    const excludeNames = new Set<string>();

    const emailColumn = findFittingColumnInTable(table, ["email"], undefined, "email-address", isPrimitiveType, false);
    if (emailColumn !== undefined) {
        excludeNames.add(emailColumn.name);
    }

    const nameColumn = findFittingColumnInTable(table, ["name"], excludeNames, "string", isPrimitiveType, false);
    if (nameColumn !== undefined) {
        excludeNames.add(nameColumn.name);
    }

    const imageColumn = findFittingColumnInTable(
        table,
        ["image", "photo", "picture", "pic"],
        excludeNames,
        "image-uri",
        isPrimitiveType,
        false
    );

    return {
        userProfileTable: makeTableProperty(getTableName(table)),
        emailColumn: definedMap(emailColumn?.name, makeColumnProperty),
        nameColumn: definedMap(nameColumn?.name, makeColumnProperty),
        imageColumn: definedMap(imageColumn?.name, makeColumnProperty),
        allowImageUpload: makeSwitchProperty(true),
        rolesColumn: undefined,
    };
}

export function makeUserProfileDataFromUserProfileTableInfo(tableInfo: UserProfileTableInfo): UserProfileDescription {
    return {
        userProfileTable: makeTableProperty(getTableName(tableInfo.tableName)),
        emailColumn: makeColumnProperty(tableInfo.emailColumnName),
        nameColumn: makeColumnProperty(tableInfo.nameColumnName),
        imageColumn: makeColumnProperty(tableInfo.imageColumnName),
        allowImageUpload: makeSwitchProperty(true),
        rolesColumn: definedMap(tableInfo.rolesColumnName, makeColumnProperty),
    };
}

function getPrimitiveNonHiddenUserAgnosticDataColumns(t: TableGlideType): readonly TableColumn[] {
    return getPrimitiveNonHiddenColumns(t).filter(isUserAgnosticDataColumn);
}

const getPrimitiveNonHiddenUserAgnosticDataColumnsSpec: ColumnFilterSpec = {
    getCandidateColumns: getPrimitiveNonHiddenUserAgnosticDataColumns,
    columnTypeIsAllowed: isPrimitiveType,
};

const tablePropertyHandler = new TablePropertyHandler(
    "userProfileTable",
    "Sheet",
    false,
    (_desc, schema) =>
        schema.schema.tables.filter(t => canTableBeUserProfiles(t, getFeatureSetting("gbtUserProfileTables"))),
    PropertySection.Source
);
const namePropertyHandler = new ColumnPropertyHandler(
    "nameColumn",
    "Name",
    [ColumnPropertyFlag.Editable, ColumnPropertyFlag.Required],
    undefined,
    ["name"],
    getPrimitiveNonHiddenUserAgnosticDataColumnsSpec,
    "string",
    PropertySection.Data,
    "Please assign a user name column for the user profile table to work correctly"
);
const emailPropertyHandler = new ColumnPropertyHandler(
    "emailColumn",
    "Email",
    [ColumnPropertyFlag.Editable, ColumnPropertyFlag.Required],
    undefined,
    ["email"],
    getPrimitiveNonHiddenUserAgnosticDataColumnsSpec,
    "email-address",
    PropertySection.Data,
    "Please assign a user email column for the user profile table to work correctly"
);

function makeImagePropertyHandler(uploadEnabled: boolean) {
    const flags = [ColumnPropertyFlag.Editable, ColumnPropertyFlag.Required];
    if (uploadEnabled) {
        flags.push(ColumnPropertyFlag.EditedInApp);
    }
    return new ColumnPropertyHandler(
        "imageColumn",
        "Image",
        flags,
        undefined,
        ["image", "photo", "picture", "pic"],
        getPrimitiveNonHiddenUserAgnosticDataColumnsSpec,
        "image-uri",
        PropertySection.Data,
        "Please assign a user image column for the user profile table to work correctly"
    );
}

export const allowImageUploadPropertyHandler = new SwitchPropertyHandler(
    { allowImageUpload: true },
    "Allow image upload",
    PropertySection.Options
);

const rolePropertyHandler = new ColumnPropertyHandler(
    "rolesColumn",
    "Role",
    [ColumnPropertyFlag.Editable, ColumnPropertyFlag.Optional, ColumnPropertyFlag.EmptyByDefault],
    undefined,
    ["role", "permissions", "access"],
    {
        getCandidateColumns: t => t.columns.filter(c => canBeRoleOrOwnersColumn(t, c)),
        columnTypeIsAllowed: c => isRoleOrOwnersColumnType(undefined, c),
    },
    "string",
    PropertySection.Data,
    undefined
);

export function getUserProfileDescriptors(
    flags: EminenceFlags,
    existing: UserProfileDescription | undefined,
    appKind: AppKind
): readonly PropertyDescriptor[] {
    const withImageUpload = appKind !== AppKind.Page;
    const handlers: PropertyDescriptor[] = [
        tablePropertyHandler,
        namePropertyHandler,
        emailPropertyHandler,
        makeImagePropertyHandler(withImageUpload && allowImageUploadPropertyHandler.getSwitch(existing)),
    ];
    if (withImageUpload) {
        handlers.push(allowImageUploadPropertyHandler);
    }
    const withRoles = flags.roles;
    if (withRoles) {
        handlers.push(rolePropertyHandler);
    }
    return handlers;
}
