import { Menu, MenuGroup, MenuItem, MenuSeparator } from "./menu";
import type { Editor } from "@tiptap/react";
import type { InternalMenuOptionType } from "./editor";
import { IconRenderer } from "./icon-renderer";
import { isDefined, isEmptyOrUndefined } from "@glide/support";
import { Combobox } from "@ariakit/react/combobox";
import React, { useState } from "react";
import { filterActions } from "./utils";
import { useIsDarkTheme } from "../../hooks/use-builder-theme";
import { GlideIcon } from "@glide/common";
import { getMenuOptionIconSize } from "./token-view";
import { useEditorContext } from "./editor-context";
import deepEqual from "deep-equal";
type MenuComboboxProps<T> = {
    open: boolean;
    editor: Editor;
    anchorRef: React.RefObject<HTMLDivElement> | React.RefObject<HTMLButtonElement>;
    options: InternalMenuOptionType<T>[];
    clearValueOption?: boolean;
    contextualLabel?: string;
    onUploadOption?: boolean;
    handleFileUploadClick?: () => void;
};

interface ExtendedInternalMenuOptionType<T> extends InternalMenuOptionType<T> {
    when?: () => boolean;
    onClick?: () => void;
    onItemSecondaryClick?: () => void;
    items?: InternalMenuOptionType<T>[];
    isSelected?: boolean;
}

export function MenuCombobox<T>({
    open,
    editor,
    anchorRef,
    options,
    clearValueOption,
    onUploadOption,
    contextualLabel,
    handleFileUploadClick,
}: MenuComboboxProps<T>) {
    const [searchValue, setSearchValue] = useState("");

    const extendedOptions: ExtendedInternalMenuOptionType<T>[] = [
        ...(!editor.isEmpty && clearValueOption
            ? [
                  {
                      label: "Clear value",
                      icon: "st-trash",
                      withDivider: false,
                      preview: undefined,
                      onClick: () => {
                          editor.commands.clearContent(true);
                          editor.commands.focus("start");
                      },
                      invalid: false,
                      singleValue: undefined,
                  },
              ]
            : []),
        ...(onUploadOption && isDefined(handleFileUploadClick)
            ? [
                  {
                      label: "Upload image",
                      icon: "st-image",
                      preview: undefined,
                      onClick: handleFileUploadClick,
                      withDivider: true,
                      invalid: false,
                      singleValue: undefined,
                  },
              ]
            : []),
        ...options,
    ];

    const matches = filterActions<T>(extendedOptions, searchValue, contextualLabel);

    const isSearching = searchValue.length > 0;

    const finalOptions = isSearching ? matches : extendedOptions;

    const onSearchChange = (value: string) => {
        setSearchValue(value);
    };
    const isDark = useIsDarkTheme();

    const flattenedOptions = extendedOptions.flatMap(option => (option.items ? [option, ...option.items] : [option]));

    const { selectedNodePosition } = useEditorContext();

    const getElementBySelectedNodePosition = () => {
        if (!isDefined(selectedNodePosition)) {
            return null;
        }
        return editor.state.doc.nodeAt(selectedNodePosition);
    };

    const selectedNode = getElementBySelectedNodePosition();
    const selectedValue = selectedNode?.attrs?.value;

    const { from, to } = editor.state.selection;

    // Maps menu items and adds selection state based on editor selection and nested item selection
    const mapItemsWithSelection = (
        menuItems: ExtendedInternalMenuOptionType<T>[]
    ): ExtendedInternalMenuOptionType<T>[] => {
        if (!menuItems) return [];

        return menuItems.map((subItem: ExtendedInternalMenuOptionType<T>): ExtendedInternalMenuOptionType<T> => {
            // Recursively map nested items if they exist
            const nestedItems = subItem.items ? mapItemsWithSelection(subItem.items) : undefined;

            // Check if this item matches the currently selected value
            const isGroupWithSelectedItem =
                selectedValue !== undefined && subItem.value !== undefined && deepEqual(subItem.value, selectedValue);

            // item with items can't be selected so we don't need to check for nested items
            if (isGroupWithSelectedItem) {
                return {
                    ...subItem,
                    isSelected: true,
                };
            }

            // Check if any nested items are selected
            let hasSelectedNestedItem = false;
            if (nestedItems !== undefined && nestedItems.length > 0) {
                const checkForSelectedItems = (i: ExtendedInternalMenuOptionType<T>[]): boolean => {
                    return i.some(item => {
                        if (item.isSelected) return true;
                        if (item.items) return checkForSelectedItems(item.items);
                        return false;
                    });
                };
                hasSelectedNestedItem = checkForSelectedItems(nestedItems);
            }

            // Check if this item or its nested items are within the current editor selection
            let isGroupWithItemInEditorSelection = false;

            // We loop through every position in the current editor selection (from -> to)
            // to check if any node in this range matches the current menu item's value.
            // This is needed because a selection can span multiple nodes and we want to
            // highlight menu items that correspond to any node within the selection.
            for (let pos = from; pos < to; pos++) {
                const currentNode = editor.state.doc.nodeAt(pos);
                if (hasSelectedNestedItem) {
                    isGroupWithItemInEditorSelection = true;
                    break;
                }

                if (
                    currentNode !== null &&
                    subItem.value !== undefined &&
                    deepEqual(currentNode.attrs?.value, subItem.value)
                ) {
                    isGroupWithItemInEditorSelection = true;
                    break;
                }
            }

            const isSelected = isGroupWithItemInEditorSelection;

            return {
                ...subItem,
                items: nestedItems,
                isSelected,
            };
        });
    };

    const itemsWithSelection = mapItemsWithSelection(finalOptions ?? []);

    return (
        <Menu editor={editor} open={open} anchorRef={anchorRef} label="Parent" onSearchChange={onSearchChange}>
            <div
                tw={"sticky -top-1 z-10 data-[is-dark=true]:bg-bg-middle bg-n0"}
                data-is-dark={isDark ? "true" : "false"}>
                <div tw="flex relative w-full">
                    {flattenedOptions.length > 5 ? (
                        <Combobox
                            id={"clear-search"}
                            autoSelect
                            value={searchValue}
                            placeholder="Search"
                            data-is-dark={isDark ? "true" : "false"}
                            tw="p-2 py-1 mx-1 rounded-md bg-bg-back data-[is-dark=true]:bg-n100A text-text-dark mb-1.5 my-1 w-full"
                        />
                    ) : null}
                    {searchValue && (
                        <button
                            tw="absolute right-3 top-2.5  rounded-full  p-0.5 transition-colors hover:bg-n300A text-icon-base hover:text-icon-dark"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSearchValue("");
                            }}
                            id="clear-search"
                            aria-label="Clear search">
                            <IconRenderer className="clear-search" tw="pointer-events-none" icon="st-close" size={12} />
                        </button>
                    )}
                </div>
            </div>
            <AllItems<T> items={itemsWithSelection ?? []} editor={editor} isSearching={isSearching} />
        </Menu>
    );
}

interface AllItemsProps<T> {
    items: ExtendedInternalMenuOptionType<T>[];
    editor: Editor;
    isSearching?: boolean;
}

interface RenderMenuItemProps<T> {
    item: ExtendedInternalMenuOptionType<T>;
    isSubItem?: boolean;
    editor: Editor;
}

const RenderMenuItem = <T,>({ item, editor }: RenderMenuItemProps<T>): React.ReactElement => {
    const value = item.value;
    const iconSize = getMenuOptionIconSize(item.icon);

    const showSecondaryClick = isDefined(item.onItemSecondaryClick);

    return (
        <MenuItem<T>
            label={item.label}
            value={value}
            editor={editor}
            icon={item.icon}
            singleValue={item.singleValue}
            preview={item.preview}
            {...(item?.onClick ? { onClick: item?.onClick } : {})}>
            <span
                tw="w-full flex items-center justify-between gap-1 px-2 py-1.5 text-left relative overflow-hidden"
                className="group">
                <span tw="inline-flex gap-2 items-center w-full">
                    {isDefined(item.icon) && (
                        <IconRenderer icon={item.icon} size={iconSize} tw="flex-none text-icon-base" />
                    )}
                    <span
                        data-show-action={showSecondaryClick ? "true" : "false"}
                        tw="flex-grow flex-shrink-0 truncate max-w-[90%]
                        data-[show-action=true]:max-w-[80%]  
                        ">
                        {item.label}
                    </span>

                    {isDefined(item.preview) && (
                        <span tw="flex-shrink ml-2 truncate transition-colors text-builder-xs text-text-xpale group-hover:hidden">
                            {item.preview}
                        </span>
                    )}

                    {item.isSelected ? (
                        <GlideIcon
                            kind="stroke"
                            icon="st-check"
                            iconSize={16}
                            strokeWidth={1.25}
                            tw="opacity-100 text-b400 group-hover:opacity-0 shrink-0"
                        />
                    ) : null}
                </span>

                {showSecondaryClick && (
                    <button
                        role="menuitem"
                        id="navigate-to-column"
                        tw="absolute -right-1 p-1 m-auto rounded-full opacity-0 transition-colors transform -translate-x-1/2 -translate-y-1/2 top-[50%] text-icon-base hover:text-icon-dark group-hover:opacity-100"
                        onClick={async e => {
                            e.stopPropagation();
                            e.preventDefault();
                            // typescript doesn't understand that we're checking for the existence of onItemSecondaryClick above
                            if (isDefined(item.onItemSecondaryClick)) void item.onItemSecondaryClick();
                        }}>
                        <GlideIcon kind="stroke" icon="st-arrow-inspect" iconSize={12} strokeWidth={1.25} />
                    </button>
                )}
            </span>
        </MenuItem>
    );
};

const AllItems = <T,>({ items, editor, isSearching }: AllItemsProps<T>): React.ReactElement => {
    return (
        <>
            {isSearching && items.length === 0 && (
                <span tw="px-2 py-2.5 text-n600A text-builder-sm text-center">Nothing found</span>
            )}
            {items.map((item, index) => {
                if (isSearching && !isEmptyOrUndefined(item.items)) {
                    return (
                        <MenuGroup key={index} label={item.label}>
                            {item.items.map((subItem, subIndex) => {
                                return (
                                    <RenderMenuItem<T> item={subItem} key={`${index}-${subIndex}`} editor={editor} />
                                );
                            })}
                            {index < items.length - 1 && <MenuSeparator />}
                        </MenuGroup>
                    );
                }

                const withDivider = index < items.length - 1 ? item.withDivider : false;

                const element = item.items ? (
                    <Menu
                        editor={editor}
                        label={item.label}
                        icon={item.icon}
                        key={item.label}
                        preview={item.preview}
                        isItemSelected={item.isSelected}
                        withDivider={withDivider}>
                        <AllItems items={item.items} editor={editor} />
                    </Menu>
                ) : (
                    <RenderMenuItem<T> item={item} editor={editor} key={index} />
                );

                return element;
            })}
        </>
    );
};
