import { AppKind } from "@glide/location-common";
import { makePluginUserFeatureName } from "@glide/plugins-utils";
import { assert, assertNever, definedMap } from "@glideapps/ts-necessities";
import { removeUndefinedProperties, EmailClient, isArray } from "@glide/support";
import {
    areTableNamesEqual,
    type TableGlideType,
    getTableName,
    type SourceMetadata,
    getGoogleSheetsSourceMetadata,
    getNativeTableIDFromTableName,
} from "@glide/type-schema";
import {
    type TabDescription,
    type IconImage,
    type EmailPinAppAuthentication,
    type AppAuthentication,
    type UserSettableAppFeatures,
    type PersistentAppFeatures,
    type NonUserAppFeatures,
    type AppDescription,
    type AppManifest,
    type UserFeatures,
    type AppFeatures,
    type SerializedApp,
    type AppLoginData,
    type PasswordAppAuthentication,
    AppAuthenticationKind,
    AuthenticationEmailSource,
    TemplateProvenance,
    defaultUserFeatures,
} from "@glide/app-description";
import { getFeatureSetting } from "../feature-settings";

export enum ScreenFlag {
    IsEditScreen = "is-edit-screen",
    IsAddItemScreen = "is-add-item-screen",
    IsFormScreen = "is-form-screen",
    IsTopScreen = "is-top-screen",
    IsArrayScreen = "is-array-screen",
    IsMapScreen = "is-map-screen",
    IsUserProfileScreen = "is-user-profile-screen",
    IsUnconfigurable = "is-unconfigurable",
    IsSignaturePad = "is-signature-pad",
    IsShareScreen = "is-share-screen",
    IsFinalTransient = "is-final-transient",
    IsErrorScreen = "is-error-screen",
}

export function isPrivateAuthentication(authentication: AppAuthentication): boolean {
    if (authentication.kind === AppAuthenticationKind.Password) return true;
    if (authentication.kind === AppAuthenticationKind.EmailPin) {
        if (
            authentication.source !== undefined &&
            (authentication.source === AuthenticationEmailSource.JustMe ||
                authentication.source === AuthenticationEmailSource.OrgMembers ||
                authentication.source === AuthenticationEmailSource.UserProfiles ||
                authentication.source === AuthenticationEmailSource.AllowlistedDomains ||
                (authentication.source === AuthenticationEmailSource.Table &&
                    authentication.emailTableName !== undefined))
        )
            return true;
    }
    return false;
}

export function getAppAuthenticationKind({ kind }: AppAuthentication): AppAuthenticationKind {
    return kind ?? AppAuthenticationKind.Password;
}

export function isPasswordAppAuthentication(
    appAuthentication: AppAuthentication
): appAuthentication is PasswordAppAuthentication {
    return getAppAuthenticationKind(appAuthentication) === AppAuthenticationKind.Password;
}

export function isUserProfileAuthentication(auth: AppAuthentication): boolean {
    return auth.kind === AppAuthenticationKind.EmailPin && auth?.source === AuthenticationEmailSource.UserProfiles;
}

export function getAuthenticationEmailSource(auth: EmailPinAppAuthentication): AuthenticationEmailSource {
    return auth.source ?? AuthenticationEmailSource.Table;
}

export function detailsForAppAuthentication(authentication: AppAuthentication | undefined): string {
    if (authentication === undefined) return "Your app is public";

    switch (authentication.kind) {
        case AppAuthenticationKind.Disabled:
            return "Your app does not allow signing in";
        case AppAuthenticationKind.Password:
            return "Your app is password protected";
        case AppAuthenticationKind.EmailPin:
            if (authentication.source === AuthenticationEmailSource.Table) {
                if (authentication.emailTableName === undefined) {
                    return "Public with email";
                } else {
                    return "Access limited by email";
                }
            } else if (authentication.source === AuthenticationEmailSource.JustMe) {
                return "Only you can access your app";
            } else if (authentication.source === AuthenticationEmailSource.OrgMembers) {
                return "Team members only";
            } else if (authentication.source === AuthenticationEmailSource.UserProfiles) {
                return "Only users with a profile can access your app";
            } else if (authentication.source === AuthenticationEmailSource.AllowlistedDomains) {
                return "Only users from your allowlisted email domains";
            } else if (authentication.source === undefined) {
                return "Email Pin";
            } else {
                return assertNever(authentication.source);
            }
        case undefined:
            return "Your app is public";
        default:
            return assertNever(authentication);
    }
}

export function isAppKindSupported(appKind: AppKind, supported: AppKind | "both"): boolean {
    return supported === "both" || appKind === supported;
}

export function doesTemplateProvenanceAllowCopying(provenance: TemplateProvenance | undefined): boolean {
    return (provenance ?? TemplateProvenance.None) !== TemplateProvenance.Paid;
}

export function doesTemplateProvenanceAllowSubmitting(provenance: TemplateProvenance | undefined): boolean {
    return provenance === undefined || provenance === TemplateProvenance.None || provenance === TemplateProvenance.Free;
}

export function getAppTabs(app: AppDescription): readonly TabDescription[] {
    return app.tabs ?? [];
}

export function getAppIconImage(app: AppDescription): IconImage {
    if (app.iconImage !== undefined) {
        return app.iconImage;
    }
    return { emoji: "😱" };
}

export const defaultAppDescriptionText = "";
export function isDefaultAppDescriptionText(desc: string): boolean {
    return desc.trim().length === 0;
}

export function getSourceMetadata(appDescription: AppDescription): readonly SourceMetadata[] {
    if (appDescription.sourceMetadataArray !== undefined) {
        return appDescription.sourceMetadataArray;
    }
    assert(appDescription.sourceMetadata !== undefined);
    return [appDescription.sourceMetadata];
}

// `false` means Google Sheets
export function getSourceMetadataForTable(
    appOrMetadata: AppDescription | readonly SourceMetadata[] | undefined,
    table: TableGlideType | false
): SourceMetadata | undefined {
    if (appOrMetadata !== undefined && !isArray(appOrMetadata)) {
        appOrMetadata = getSourceMetadata(appOrMetadata);
    }

    if (table !== false) {
        // We're going through a bunch options, in decreasing order of
        // preference.
        // FIXME: The source metadata should always be on the table, i.e. in
        // the schema, and the app description shouldn't carry it at all.

        const tableName = getTableName(table);

        // The app description should in theory always have the source
        // metadata.  We go there first.
        if (appOrMetadata !== undefined) {
            for (const m of appOrMetadata) {
                if (m.type === "Native table" && areTableNamesEqual(tableName, m.tableName)) {
                    if (table.sourceMetadata?.type === "Native table") {
                        if (
                            table.sourceMetadata.id !== m.id &&
                            getFeatureSetting("checkAppAccessWhenValidatingAction")
                        ) {
                            // If there's a mismatch between the native table
                            // ID in the app description and the schema, we
                            // trust the schema, because the app description
                            // is managed by the client.
                            // https://github.com/glideapps/glide/pull/30911
                            continue;
                        }
                    }
                    return m;
                }
            }
        }

        // This is the future: the source metadata is on the table
        if (table.sourceMetadata !== undefined) {
            return table.sourceMetadata;
        }

        // Now we're getting desperate, and we do what we can.
        const nativeTableID = getNativeTableIDFromTableName(tableName);
        if (nativeTableID !== undefined) {
            return {
                type: "Native table",
                id: nativeTableID,
                tableName,
            };
        }
    }

    return definedMap(appOrMetadata, getGoogleSheetsSourceMetadata);
}

export function sheetFileIDForAppDescription(appDescription: AppDescription): string | undefined {
    return getGoogleSheetsSourceMetadata(getSourceMetadata(appDescription))?.id;
}

export const defaultNewUserFeatures = {
    ...defaultUserFeatures,
    canApplyPromoCodes: true,
    hasV3TeamBasedPricing: true,
    isV3FreePlan: true,
};

type OrUndefined<T> = {
    [P in keyof T]: T[P] | undefined;
};

type RequiredNonUserAppFeatures = Required<NonUserAppFeatures>;
export function isolateNonUserAppFeatures(features: NonUserAppFeatures): OrUndefined<RequiredNonUserAppFeatures> {
    return {
        emailClient: features.emailClient,
        requirePinForComments: features.requirePinForComments,
        automaticRefreshMinutes: features.automaticRefreshMinutes,
        templateProvenance: features.templateProvenance,
        googleAnalyticsMeasureIDs: features.googleAnalyticsMeasureIDs,
        showSignInWithEmailPin: features.showSignInWithEmailPin,
        showSignInWithGoogle: features.showSignInWithGoogle,
        // For SSO buttons to show up, this flag must be enabled, but the app
        // must also have an SSO integration configured, or we wouldn't even
        // know what to show.
        showSignInWithSSO: features.showSignInWithSSO,
        disableSharing: features.disableSharing,
        enableShareScreen: features.enableShareScreen,
        shareScreenTitle: features.shareScreenTitle,
        defaultTabletMode: features.defaultTabletMode,
        useCustomSignInBackgroundImageInLoading: features.useCustomSignInBackgroundImageInLoading,
        virtualEmailAddresses: features.virtualEmailAddresses,
        askUserToSaveAuthCookie: features.askUserToSaveAuthCookie,
        isTrialLocked: features.isTrialLocked,
        appKind: features.appKind,
        useNCMOverWire: features.useNCMOverWire,
        useUnifiedApps: features.useUnifiedApps,
        canAppUserRequestAccess: features.canAppUserRequestAccess,
        noLegacyActions: features.noLegacyActions,
        magicLinkInPinEmail: features.magicLinkInPinEmail,
        compileCustomCss: features.compileCustomCss,
        useGmailForSendEmails: features.useGmailForSendEmails,
        actionsRecentRuns: features.actionsRecentRuns,
        newAuthController: features.newAuthController,
        shouldUsePlay2: features.shouldUsePlay2,
        shouldUseVercelPlay: features.shouldUseVercelPlay,
        pageCopiedFromClassic: features.pageCopiedFromClassic,
    };
}

export function isolateUserSettableAppFeatures(
    features: UserSettableAppFeatures
): OrUndefined<Required<UserSettableAppFeatures>> {
    return {
        emailClient: features.emailClient,
        requirePinForComments: features.requirePinForComments,
        automaticRefreshMinutes: features.automaticRefreshMinutes,
        googleAnalyticsMeasureIDs: features.googleAnalyticsMeasureIDs,
        showSignInWithEmailPin: features.showSignInWithEmailPin,
        showSignInWithGoogle: features.showSignInWithGoogle,
        showSignInWithSSO: features.showSignInWithSSO,
        useCustomSignInBackgroundImageInLoading: features.useCustomSignInBackgroundImageInLoading,
        enableShareScreen: features.enableShareScreen,
        shareScreenTitle: features.shareScreenTitle,
        disableSharing: features.disableSharing,
        defaultTabletMode: features.defaultTabletMode,
        virtualEmailAddresses: features.virtualEmailAddresses,
        askUserToSaveAuthCookie: features.askUserToSaveAuthCookie,
        appKind: features.appKind,
        useNCMOverWire: features.useNCMOverWire,
        useUnifiedApps: features.useUnifiedApps,
        canAppUserRequestAccess: features.canAppUserRequestAccess,
        noLegacyActions: features.noLegacyActions,
        magicLinkInPinEmail: features.magicLinkInPinEmail,
        compileCustomCss: features.compileCustomCss,
        useGmailForSendEmails: features.useGmailForSendEmails,
        actionsRecentRuns: features.actionsRecentRuns,
        newAuthController: features.newAuthController,
        shouldUsePlay2: features.shouldUsePlay2,
        shouldUseVercelPlay: features.shouldUseVercelPlay,
        pageCopiedFromClassic: features.pageCopiedFromClassic,
    };
}

export function isolatePersistentAppFeatures(features: PersistentAppFeatures): PersistentAppFeatures {
    return {
        templateProvenance: features.templateProvenance,
    };
}

// NOTE: We're assuming that all the grandfathering features are boolean
// in `makeAppFeatures`.
export const defaultNonUserAppFeatures: Omit<Required<NonUserAppFeatures>, "useGmailForSendEmails"> = {
    emailClient: EmailClient.NativeMail,
    requirePinForComments: true,
    automaticRefreshMinutes: 0,
    templateProvenance: TemplateProvenance.None,
    googleAnalyticsMeasureIDs: [],
    showSignInWithEmailPin: true,
    showSignInWithGoogle: false,
    showSignInWithSSO: false,
    useCustomSignInBackgroundImageInLoading: false,
    enableShareScreen: false,
    disableSharing: false,
    shareScreenTitle: "",
    defaultTabletMode: false,
    virtualEmailAddresses: false,
    askUserToSaveAuthCookie: false,
    isTrialLocked: false,
    appKind: AppKind.App,
    useNCMOverWire: false,
    useUnifiedApps: false,
    canAppUserRequestAccess: false,
    noLegacyActions: true,
    magicLinkInPinEmail: true,
    compileCustomCss: true,
    actionsRecentRuns: false,
    newAuthController: false,
    shouldUsePlay2: false,
    shouldUseVercelPlay: false,
    pageCopiedFromClassic: false,
};

export const newAppNonUserAppFeatures = {
    ...defaultNonUserAppFeatures,
    canAppUserRequestAccess: true,
};

export const defaultAppFeatures = {
    ...defaultUserFeatures,
    ...defaultNonUserAppFeatures,
};

export function getEmailClient(appFeatures: UserSettableAppFeatures): EmailClient {
    return appFeatures.emailClient ?? defaultAppFeatures.emailClient;
}

export function getAppFeatures(app: SerializedApp): AppFeatures;
export function getAppFeatures(app: AppDescription): NonUserAppFeatures;
export function getAppFeatures(app: AppDescription | SerializedApp): NonUserAppFeatures | AppFeatures {
    return app.features ?? defaultAppFeatures;
}

export const defaultAppKind: AppKind = AppKind.App;

export function getAppKindFromFeatures(features: NonUserAppFeatures | undefined): AppKind {
    return features?.appKind ?? defaultAppKind;
}

export function getAppKind(app: AppDescription | undefined): AppKind {
    return getAppKindFromFeatures(definedMap(app, getAppFeatures));
}

export function isolateAppLoginData(desc: SerializedApp & { readonly manifest: AppManifest }): AppLoginData {
    return {
        title: desc.title,
        theme: desc.theme,
        description: desc.description,
        author: desc.author,
        iconImage: getAppIconImage(desc),
        features: removeUndefinedProperties(desc.features ?? defaultAppFeatures),
        manifest: removeUndefinedProperties(desc.manifest),
        pagesCustomCss: desc.pagesCustomCss,
        // We're only using this for the template previewer. It'll only show if the app is a template.
        templateAuthorEmail: desc.templateAuthorEmail,
    };
}

export function makeSourceMetadataForAppDescription(sourceMetadataArray: readonly SourceMetadata[]): {
    readonly sourceMetadata: SourceMetadata | undefined;
    readonly sourceMetadataArray: readonly SourceMetadata[];
} {
    return {
        sourceMetadata: getGoogleSheetsSourceMetadata(sourceMetadataArray),
        sourceMetadataArray,
    };
}

export function isolateAppDescription(
    desc: AppDescription,
    sourceMetadata?: readonly SourceMetadata[]
): AppDescription {
    let isolated = {
        title: desc.title,
        description: desc.description,
        author: desc.author,
        sourceMetadata: desc.sourceMetadata,
        sourceMetadataArray: getSourceMetadata(desc),
        iconImage: desc.iconImage,
        tabs: desc.tabs,
        screenDescriptions: desc.screenDescriptions,
        theme: desc.theme,
        authentication: desc.authentication,
        allowedEmailDomains: desc.allowedEmailDomains,
        features: desc.features,
        userProfile: desc.userProfile,
        pluginConfigs: desc.pluginConfigs,
        pagesCustomCss: desc.pagesCustomCss,
        templateAuthorEmail: desc.templateAuthorEmail,
        previousAuthenticationDetails: desc.previousAuthenticationDetails,
    };
    if (sourceMetadata !== undefined) {
        isolated = { ...isolated, ...makeSourceMetadataForAppDescription(sourceMetadata) };
    }
    return isolated;
}

export function doesOwnerHavePluginFeature(pluginID: string, userFeatures: UserFeatures | undefined): boolean {
    if (userFeatures === undefined) {
        return false;
    }
    // We're doing the `?.` just in case `getUserFeatures` might ever return
    // `undefined` in the future, since type checking won't do anything here.
    return (userFeatures as any)?.[makePluginUserFeatureName(pluginID)] === true;
}
