import { useCallback, useRef, useState, useEffect } from "react";

interface UseOffscreenProps {
    root?: Element | null;
    rootMargin?: string;
    enabled?: boolean;
    idleTimeout?: number;
}

const requestIdleCallbackPolyfill = (callback: () => void, options?: { timeout?: number }) => {
    if (typeof window.requestIdleCallback === "function") {
        return window.requestIdleCallback(callback, options);
    }
    return window.setTimeout(callback, options?.timeout ?? 1);
};

const cancelIdleCallbackPolyfill = (id: number) => {
    if (typeof window.cancelIdleCallback === "function") {
        window.cancelIdleCallback(id);
    } else {
        window.clearTimeout(id);
    }
};

export function useOffscreen({
    root = null,
    rootMargin = "50%",
    enabled = true,
    idleTimeout = 1000,
}: UseOffscreenProps = {}) {
    const [isVisible, setIsVisible] = useState(false);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const idleCallbackIdRef = useRef<number>(0);
    const elementRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        const cleanupIdleCallback = () => {
            if (idleCallbackIdRef.current) {
                cancelIdleCallbackPolyfill(idleCallbackIdRef.current);
                idleCallbackIdRef.current = 0;
            }
        };

        return () => {
            cleanupIdleCallback();
            observerRef.current?.disconnect();
        };
    }, []);

    const ref = useCallback(
        (element: HTMLElement | null) => {
            elementRef.current = element;

            // Cleanup previous observer
            if (observerRef.current) {
                observerRef.current.disconnect();
                observerRef.current = null;
            }

            // If no element or not enabled, just cleanup
            if (!element || !enabled) {
                setIsVisible(true); // Show content when disabled
                return;
            }

            // Create new observer
            observerRef.current = new IntersectionObserver(
                entries => {
                    const entry = entries[0];
                    if (!entry) return;

                    idleCallbackIdRef.current = requestIdleCallbackPolyfill(
                        () => {
                            if (elementRef.current) {
                                // Check if element still exists
                                setIsVisible(entry.isIntersecting);
                            }
                        },
                        { timeout: idleTimeout }
                    );
                },
                {
                    root,
                    rootMargin,
                    threshold: 0,
                }
            );

            observerRef.current.observe(element);

            // Initial check for visibility
            if (element.getBoundingClientRect().top < window.innerHeight) {
                setIsVisible(true);
            }
        },
        [enabled, root, rootMargin, idleTimeout]
    );

    // Ensure visibility when disabled
    if (!enabled && !isVisible) {
        setIsVisible(true);
    }

    return {
        ref,
        isVisible: enabled ? isVisible : true,
    };
}
