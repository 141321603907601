import {
    type TableColumn,
    type TableGlideType,
    getTableColumn,
    isColumnWritable,
    isComputedColumn,
    isBigTableOrExternal,
    type SchemaInspector,
} from "@glide/type-schema";
import { getSourceMetadataForTable } from "@glide/common-core/dist/js/components/SerializedApp";
import { decomposeFilterReferenceFormula } from "@glide/formula-specifications";

export interface LinkTargetType {
    readonly targetTable: TableGlideType;
    readonly isMulti: boolean;
}

export interface LinkTarget extends LinkTargetType {
    readonly hostColumn: TableColumn;
    readonly targetColumn: TableColumn;
}

export function getTargetForLink(
    hostTable: TableGlideType,
    linkColumn: TableColumn,
    schema: SchemaInspector,
    // Do we also allow single-relation columns configured as regular computed
    // columns?  Those have a host column that's not hidden.
    withUserConfiguredLink: boolean
): LinkTarget | undefined {
    if (!isComputedColumn(linkColumn)) return undefined;
    const spec = decomposeFilterReferenceFormula(linkColumn.formula);
    if (spec === undefined) return undefined;

    const hostColumn = getTableColumn(hostTable, spec.hostColumn);
    if (hostColumn === undefined) return undefined;
    if (!withUserConfiguredLink && hostColumn.hidden !== true) return undefined;
    if (
        !isColumnWritable(hostColumn, hostTable, false, { allowProtected: false, allowArrays: true, allowHidden: true })
    ) {
        return undefined;
    }

    if (spec.multiple && hostColumn.type.kind !== "array") return undefined;

    if (hostColumn.type.kind === "array") {
        // We support writing to array columns only in Airtable for now.
        const metadata = getSourceMetadataForTable(schema.sourceMetadata ?? [], hostTable);
        if (metadata?.type !== "Native table") return undefined;
        if (metadata.externalSource?.type !== "airtable") return undefined;
    }

    const targetTable = schema.findTable(spec.targetTable);
    if (targetTable === undefined) return undefined;
    if (isBigTableOrExternal(targetTable)) return undefined;

    const targetColumn = getTableColumn(targetTable, spec.targetColumn);
    if (targetColumn === undefined) return undefined;

    return { hostColumn, targetTable, targetColumn, isMulti: spec.multiple };
}
