import numeral from "numeral";

export interface Currency {
    readonly code: string;
    readonly symbol?: string;
    readonly name: string;
    readonly isZeroDecimal: boolean;
}

export const defaultCurrency: Currency = { code: "usd", symbol: "$", name: "US Dollar", isZeroDecimal: false };

export const currencies: readonly Currency[] = [
    defaultCurrency,
    { code: "eur", symbol: "€", name: "Euro", isZeroDecimal: false },
    { code: "aed", symbol: "د.إ", name: "United Arab Emirates dirham", isZeroDecimal: false },
    { code: "all", symbol: "L", name: "Albanian lek", isZeroDecimal: false },
    { code: "amd", symbol: "֏", name: "Armenian dram", isZeroDecimal: false },
    { code: "ang", symbol: "ƒ", name: "Netherlands Antillean guilder", isZeroDecimal: false },
    { code: "aud", symbol: "A$", name: "Australian dollar", isZeroDecimal: false },
    { code: "awg", symbol: "ƒ", name: "Aruban florin", isZeroDecimal: false },
    { code: "azn", symbol: "₼", name: "Azerbaijani manat", isZeroDecimal: false },
    { code: "bam", symbol: "KM", name: "Bosnia and Herzegovina convertible mark", isZeroDecimal: false },
    { code: "bbd", symbol: "Bds$", name: "Barbados dollar", isZeroDecimal: false },
    { code: "bdt", symbol: "৳", name: "Bangladeshi taka", isZeroDecimal: false },
    { code: "bgn", symbol: "лв.", name: "Bulgarian lev", isZeroDecimal: false },
    { code: "bif", symbol: "FBu", name: "Burundian franc", isZeroDecimal: true },
    { code: "bmd", symbol: "BD$", name: "Bermudian dollar", isZeroDecimal: false },
    { code: "bnd", symbol: "B$", name: "Brunei dollar", isZeroDecimal: false },
    { code: "brl", symbol: "R$", name: "Brazilian real", isZeroDecimal: false },
    { code: "bsd", symbol: "B$", name: "Bahamian dollar", isZeroDecimal: false },
    { code: "bwp", symbol: "P", name: "Botswana pula", isZeroDecimal: false },
    { code: "bzd", symbol: "BZ$", name: "Belize dollar", isZeroDecimal: false },
    { code: "cad", symbol: "CAD$", name: "Canadian dollar", isZeroDecimal: false },
    { code: "cdf", symbol: "₣", name: "Congolese franc", isZeroDecimal: false },
    { code: "chf", symbol: "CHF", name: "Swiss franc", isZeroDecimal: false },
    { code: "cny", name: "Renminbi (Chinese) yuan", isZeroDecimal: false },
    { code: "dkk", symbol: "kr", name: "Danish krone", isZeroDecimal: false },
    { code: "dop", symbol: "RD$", name: "Dominican peso", isZeroDecimal: false },
    { code: "dzd", symbol: "دج", name: "Algerian dinar", isZeroDecimal: false },
    { code: "egp", symbol: "E£", name: "Egyptian pound", isZeroDecimal: false },
    { code: "etb", symbol: "Br", name: "Ethiopian birr", isZeroDecimal: false },
    { code: "fjd", symbol: "FJ$", name: "Fiji dollar", isZeroDecimal: false },
    { code: "gbp", symbol: "£", name: "Pound sterling", isZeroDecimal: false },
    { code: "gel", symbol: "₾", name: "Georgian lari", isZeroDecimal: false },
    { code: "gip", symbol: "£", name: "Gibraltar pound", isZeroDecimal: false },
    { code: "gmd", symbol: "D", name: "Gambian dalasi", isZeroDecimal: false },
    { code: "gyd", symbol: "G$", name: "Guyanese dollar", isZeroDecimal: false },
    { code: "hkd", symbol: "HK$", name: "Hong Kong dollar", isZeroDecimal: false },
    { code: "hrk", symbol: "kn", name: "Croatian kuna", isZeroDecimal: false },
    { code: "htg", symbol: "G", name: "Haitian gourde", isZeroDecimal: false },
    { code: "idr", symbol: "Rp", name: "Indonesian rupiah", isZeroDecimal: false },
    { code: "ils", symbol: "₪", name: "Israeli new shekel", isZeroDecimal: false },
    { code: "inr", symbol: "₹", name: "Indian rupee", isZeroDecimal: false },
    { code: "isk", symbol: "kr", name: "Icelandic króna", isZeroDecimal: false },
    { code: "jmd", symbol: "J$", name: "Jamaican dollar", isZeroDecimal: false },
    { code: "jpy", symbol: "¥", name: "Japanese yen", isZeroDecimal: true },
    { code: "kes", symbol: "Ksh", name: "Kenyan shilling", isZeroDecimal: false },
    { code: "kgs", symbol: "сом", name: "Kyrgyzstani som", isZeroDecimal: false },
    { code: "khr", symbol: "៛", name: "Cambodian riel", isZeroDecimal: false },
    { code: "kmf", symbol: "₣", name: "Comoro franc", isZeroDecimal: true },
    { code: "krw", symbol: "₩", name: "South Korean won", isZeroDecimal: true },
    { code: "kyd", symbol: "CI$", name: "Cayman Islands dollar", isZeroDecimal: false },
    { code: "kzt", name: "Kazakhstani tenge", isZeroDecimal: false },
    { code: "lbp", symbol: "LL", name: "Lebanese pound", isZeroDecimal: false },
    { code: "lkr", symbol: "₨", name: "Sri Lankan rupee", isZeroDecimal: false },
    { code: "lrd", symbol: "L$", name: "Liberian dollar", isZeroDecimal: false },
    { code: "lsl", symbol: "M", name: "Lesotho loti", isZeroDecimal: false },
    { code: "mad", symbol: "د.م.", name: "Moroccan dirham", isZeroDecimal: false },
    { code: "mdl", name: "Moldovan leu", isZeroDecimal: false },
    { code: "mga", symbol: "Ar", name: "Malagasy ariary", isZeroDecimal: true },
    { code: "mkd", symbol: "ден", name: "Macedonian denar", isZeroDecimal: false },
    { code: "mmk", symbol: "K", name: "Myanmar kyat", isZeroDecimal: false },
    { code: "mnt", symbol: "₮", name: "Mongolian tögrög", isZeroDecimal: false },
    { code: "mop", symbol: "MOP$", name: "Macanese pataca", isZeroDecimal: false },
    { code: "mro", symbol: "UM", name: "Mauritanian ouguiya", isZeroDecimal: false },
    { code: "mvr", symbol: "Rf.", name: "Maldivian rufiyaa", isZeroDecimal: false },
    { code: "mwk", symbol: "K", name: "Malawian kwacha", isZeroDecimal: false },
    { code: "mxn", symbol: "Mex$", name: "Mexican peso", isZeroDecimal: false },
    { code: "myr", symbol: "RM", name: "Malaysian ringgit", isZeroDecimal: false },
    { code: "mzn", symbol: "MT", name: "Mozambican metical", isZeroDecimal: false },
    { code: "nad", symbol: "N$", name: "Namibian dollar", isZeroDecimal: false },
    { code: "ngn", symbol: "₦", name: "Nigerian naira", isZeroDecimal: false },
    { code: "nok", symbol: "kr", name: "Norwegian krone", isZeroDecimal: false },
    { code: "npr", symbol: "₨", name: "Nepalese rupee", isZeroDecimal: false },
    { code: "nzd", symbol: "NZ$", name: "New Zealand dollar", isZeroDecimal: false },
    { code: "pgk", symbol: "K", name: "Papua New Guinean kina", isZeroDecimal: false },
    { code: "php", symbol: "₱", name: "Philippine peso", isZeroDecimal: false },
    { code: "pkr", symbol: "₨", name: "Pakistani rupee", isZeroDecimal: false },
    { code: "pln", symbol: "zł", name: "Polish złoty", isZeroDecimal: false },
    { code: "qar", symbol: "ر.ق", name: "Qatari riyal", isZeroDecimal: false },
    { code: "ron", name: "Romanian leu", isZeroDecimal: false },
    { code: "rsd", symbol: "дин", name: "Serbian dinar", isZeroDecimal: false },
    { code: "rub", symbol: "₽", name: "Russian ruble", isZeroDecimal: false },
    { code: "rwf", symbol: "FRw", name: "Rwandan franc", isZeroDecimal: true },
    { code: "sar", symbol: "ر.س", name: "Saudi riyal", isZeroDecimal: false },
    { code: "sbd", symbol: "SI$", name: "Solomon Islands dollar", isZeroDecimal: false },
    { code: "scr", symbol: "SRe", name: "Seychelles rupee", isZeroDecimal: false },
    { code: "sek", symbol: "kr", name: "Swedish krona", isZeroDecimal: false },
    { code: "sgd", symbol: "S$", name: "Singapore dollar", isZeroDecimal: false },
    { code: "sll", symbol: "Le", name: "Sierra Leonean leone", isZeroDecimal: false },
    { code: "sos", symbol: "Sh.So.", name: "Somali shilling", isZeroDecimal: false },
    { code: "szl", symbol: "E", name: "Swazi lilangeni", isZeroDecimal: false },
    { code: "thb", symbol: "฿", name: "Thai baht", isZeroDecimal: false },
    { code: "tjs", name: "Tajikistani somoni", isZeroDecimal: false },
    { code: "top", symbol: "T$", name: "Tongan paʻanga", isZeroDecimal: false },
    { code: "try", symbol: "₺", name: "Turkish lira", isZeroDecimal: false },
    { code: "ttd", symbol: "TT$", name: "Trinidad and Tobago dollar", isZeroDecimal: false },
    { code: "twd", symbol: "NT$", name: "New Taiwan dollar", isZeroDecimal: false },
    { code: "tzs", symbol: "Tsh", name: "Tanzanian shilling", isZeroDecimal: false },
    { code: "uah", symbol: "₴", name: "Ukrainian hryvnia", isZeroDecimal: false },
    { code: "ugx", symbol: "USh", name: "Ugandan shilling", isZeroDecimal: true },
    { code: "uzs", name: "Uzbekistan som", isZeroDecimal: false },
    { code: "vnd", symbol: "₫", name: "Vietnamese đồng", isZeroDecimal: true },
    { code: "vuv", symbol: "VT", name: "Vanuatu vatu", isZeroDecimal: true },
    { code: "wst", symbol: "WS$", name: "Samoan tala", isZeroDecimal: false },
    { code: "xaf", symbol: "FCFA", name: "CFA franc BEAC", isZeroDecimal: true },
    { code: "xcd", symbol: "EC$", name: "East Caribbean dollar", isZeroDecimal: false },
    { code: "yer", symbol: "ر.ي", name: "Yemeni rial", isZeroDecimal: false },
    { code: "zar", symbol: "R", name: "South African rand", isZeroDecimal: false },
    { code: "zmw", symbol: "K", name: "Zambian kwacha", isZeroDecimal: false },
];

export function findCurrency(symbol: string): Currency | undefined {
    symbol = symbol.toLowerCase();
    return currencies.find(c => c.code === symbol);
}

export function formatPrice(price: number, currency: Currency): string {
    return (currency.symbol ?? currency.code.toUpperCase() + " ") + numeral(price).format("0,0.00");
}
