import { getLocalizedString, makeLocalizedNumberOfItems } from "@glide/localization";
import { isLoadingValue } from "@glide/computation-model-types";
import {
    type ComponentDescription,
    type ComponentKind,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    type TransformableContentDescription,
    makeColumnProperty,
    makeStringProperty,
    makeTableProperty,
} from "@glide/app-description";
import {
    type Description,
    type TableColumn,
    getTableColumnDisplayName,
    getTableName,
    isSingleRelationType,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type InputOutputTables,
    type TitleDescription,
    makeEmptyComponentDescription,
} from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { ListItemFlags } from "@glide/component-utils";
import type { WireAppAdaptiveListItemComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type InteractiveComponentConfiguratorContext,
    type ListSourcePropertyDescription,
    PropertySection,
    arrayScreenName,
    getInlineListPropertyTable,
    makeTableOrRelationPropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { panic, defined, definedMap } from "@glideapps/ts-necessities";
import {
    type WireInflationBackend,
    type WireRowComponentHydratorConstructor,
    WireActionResult,
    PageScreenTarget,
    WireComponentKind,
} from "@glide/wire";
import { propertyDescriptorsForTransforms } from "../array-screens/array-content";
import { summaryColumnsForTable } from "../description-utils";
import {
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";
import { getDescriptiveNameForTableOrRow, getListTypes } from "./inline-list";

const ComponentKindListReference: ComponentKind = "list-reference";

interface ListReferenceComponentDescription
    extends ComponentDescription,
        TitleDescription,
        TransformableContentDescription {
    readonly propertyName: ListSourcePropertyDescription | undefined;
    readonly caption: LegacyPropertyDescription | undefined;
}

export class ListReferenceComponentHandler extends ComponentHandlerBase<ListReferenceComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindListReference);
    }

    public getDescriptor(
        _desc: ListReferenceComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const getPT = (t: InputOutputTables | undefined, rootDesc: Description, _d: Description, s: SchemaInspector) =>
            getInlineListPropertyTable(t?.input, (rootDesc as ListReferenceComponentDescription).propertyName, s);
        return {
            name: "List Relation",
            description: "A link to a list of related items",
            img: "co-list-relation",
            group: "Lists",
            helpUrl: getDocURL("listReference"),
            properties: [
                makeTableOrRelationPropertyDescriptor(
                    mutatingScreenKind,
                    "Values",
                    PropertySection.Source,
                    {
                        allowTables: true,
                        allowSingleRelations: false,
                        preferFullRow: false,
                        allowMultiRelations: true,
                        allowQueryableTables: false,
                        allowUserProfileTableAndRow: true,
                        sourceIsDefaultCaption: true,
                        allowRewrite: true,
                        allowUserProfile: false,
                        forWriting: false,
                    },
                    {
                        name: "propertyName",
                    },
                    true
                ),
                makeCaptionStringPropertyDescriptor("List", false, mutatingScreenKind),
                ...propertyDescriptorsForTransforms(getPT, true, true, true),
                ...this.getBasePropertyDescriptors(),
            ],
        };
    }

    public getScreensUsed(
        desc: ListReferenceComponentDescription,
        schema: SchemaInspector,
        tables: InputOutputTables | undefined
    ): readonly string[] {
        if (tables === undefined) return [];

        const data = definedMap(desc.propertyName, pd => getListTypes(schema, pd, tables.input, false));
        if (data === undefined || !isSingleRelationType(data.itemsType)) return [];

        const screenName = arrayScreenName(schema, data.itemsType);
        if (screenName === undefined) return [];
        return [screenName];
    }

    public static defaultComponent(
        schema: SchemaInspector,
        column: TableColumn
    ): ListReferenceComponentDescription | undefined {
        if (column.type.kind !== "array") {
            return panic("Type of list reference column must be array");
        }

        const desc: ListReferenceComponentDescription = {
            ...makeEmptyComponentDescription(ComponentKindListReference),
            propertyName: makeColumnProperty(column.name),
            caption: makeStringProperty(getTableColumnDisplayName(column)),
            transforms: [],
        };

        const maybeTableRef = column.type.items;
        if (isSingleRelationType(maybeTableRef)) {
            const summary = definedMap(schema.findTable(maybeTableRef), t => summaryColumnsForTable(t, true, false));
            if (summary === undefined) return undefined;
            Object.assign(desc, summary);
        }

        return desc;
    }

    // This is only here so it's possible to add this component without there being
    // a reference column.
    // FIXME: This should really be handled by `populateDescription` I think.
    public newComponent(
        tables: InputOutputTables,
        usedColumns: ReadonlySet<TableColumn>,
        editedColumns: ReadonlySet<TableColumn>,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ListReferenceComponentDescription | undefined {
        const desc = super.newComponent(tables, usedColumns, editedColumns, iccc, mutatingScreenKind);
        if (desc?.propertyName !== undefined) return desc;

        const table = iccc.schema.tables.filter(t => !getTableName(t).isSpecial)[0];
        if (table !== undefined) {
            return {
                ...makeEmptyComponentDescription(ComponentKindListReference),
                kind: ComponentKindListReference,
                propertyName: makeTableProperty(getTableName(table)),
                caption: "List relation",
                transforms: [],
            };
        }

        return {
            ...makeEmptyComponentDescription(ComponentKindListReference),
            propertyName: undefined,
            caption: "List relation",
            transforms: [],
        };
    }

    public getDescriptiveName(
        desc: ListReferenceComponentDescription,
        tables: InputOutputTables | undefined,
        ctx: AppDescriptionContext
    ): [string, string] {
        const name = definedMap(tables?.input, t => getDescriptiveNameForTableOrRow(desc.propertyName, t, ctx));

        if (name === undefined) {
            return super.getDescriptiveName(desc, tables, ctx);
        } else {
            return ["List relation", name];
        }
    }

    public inflate(
        ib: WireInflationBackend,
        desc: ListReferenceComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const {
            forBuilder,
            adc: { appKind },
        } = ib;

        const maybeGetter = ib.getTableGetterWithTransforms(desc.propertyName, desc.transforms ?? [], true, false);
        if (maybeGetter === undefined) return undefined;

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);

        const { getter: tableGetter, ib: contentIB } = maybeGetter;
        const itemsTable = contentIB.tables.input;

        const screenTitle = this.getCaption(desc, ib.tables, ib.adc) ?? getLocalizedString("data", appKind);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const contentHB = tableGetter(hb);
            if (contentHB === undefined || isLoadingValue(contentHB)) return undefined;

            const table = contentHB.tableScreenContext;
            const length = table.size;
            if (length === 0) return undefined;

            const title = defined(makeLocalizedNumberOfItems(length, appKind));

            const onTap = registerActionRunner(hb, "onTap", async ab => {
                ab.pushDefaultArrayScreen(getTableName(itemsTable), table, screenTitle, PageScreenTarget.Current);
                return WireActionResult.nondescriptSuccess();
            });
            if (onTap === undefined) return undefined;

            const component: WireAppAdaptiveListItemComponent = {
                kind: WireComponentKind.AppAdaptiveListItem,
                ...spreadComponentID(desc.componentID, forBuilder),
                title,
                subtitle: captionGetter(hb),
                caption: null,
                image: null,
                onTap,
                flags: ListItemFlags.WrapText | ListItemFlags.InvertTitleAndSubtitle,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: ListReferenceComponentDescription,
        ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return this.defaultConvertToPage(desc, ccc);
    }
}
