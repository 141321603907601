import type { MonotoneIcons } from "@glide/plugins";
import { GlideIcon } from "@glide/common";
import { isUploadFileResponseError } from "@glide/common-core/dist/js/components/types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import type { WireAudioRecorderComponent } from "@glide/fluent-components/dist/js/fluent-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { isSmallScreen, useResponsiveSizeClass } from "@glide/common-components";
import { assertNever } from "@glideapps/ts-necessities";
import { ignore, isDefined, logError, undefinedIfEmptyString } from "@glide/support";
import { type WireAction, type WireEditableValue, UIButtonAppearance, ValueChangeSource } from "@glide/wire";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";

import { runActionAndHandleURL } from "../../wire-lib";
import { WireButton } from "../wire-button/wire-button";
import type { WireRenderer } from "../wire-renderer";
import { type FileUploader, useFileUploader } from "../../utils/use-file-uploader";
import { AppKind } from "@glide/location-common";

import type { FFmpeg } from "web-ffmpeg2";

let _ffmpeg: FFmpeg | undefined = undefined;

const getFFmpeg = async (): Promise<FFmpeg> => {
    const { createFFmpeg } = await import("web-ffmpeg2");
    if (_ffmpeg === undefined) {
        _ffmpeg = createFFmpeg({
            mainName: "main",
            corePath: "https://unpkg.com/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js",
        });
    }
    if (_ffmpeg.isLoaded() === false) {
        await _ffmpeg.load();
    }
    return _ffmpeg;
};

const transcodeToMP3 = async (filename: string, input: Blob) => {
    const ffmpeg = await getFFmpeg();

    const buffer = await input.arrayBuffer();
    ffmpeg.FS("writeFile", filename, new Uint8Array(buffer));

    await ffmpeg.run("-i", filename, "output.mp3");
    const data = ffmpeg.FS("readFile", "output.mp3");
    ffmpeg.exit();
    const blob = new Blob([data.buffer], { type: "audio/mpeg" });
    return blob;
};

export const WireAudioRecorder: WireRenderer<WireAudioRecorderComponent> = memo(p => {
    const { backend, saveTo, recordText, uploadingText, title, onRecordingComplete } = p;

    if (!isDefined(saveTo)) {
        return null;
    }

    return (
        <WireAudioRecorderImp
            backend={backend}
            saveTo={saveTo}
            recordText={recordText ?? undefined}
            uploadingText={uploadingText ?? undefined}
            title={title ?? undefined}
            onRecordingComplete={onRecordingComplete}
        />
    );
});

interface WireAudioRecorderImplProps {
    backend: WireBackendInterface;
    saveTo: WireEditableValue<string>;
    recordText: string | undefined;
    uploadingText: string | undefined;
    title: string | undefined;
    onRecordingComplete: WireAction | null | undefined;
}

// FIXME: THIS IS THE SAME AS `upload-utils.ts`
export async function handleFileUpload(
    fileUploader: FileUploader,
    file: File,
    appID: string
): Promise<string | undefined> {
    const session = fileUploader(
        appID,
        AppKind.Page,
        getAppFacilities(),
        file,
        // This should not be data-editor. This was already here when I made this refactor.
        "data-editor",
        ignore,
        ignore,
        false
    );

    const uploadResponse = await session.attempt();
    if (!isUploadFileResponseError(uploadResponse)) {
        return uploadResponse.path;
    }

    return undefined;
}

type State = "idle" | "record" | "recording" | "stop" | "uploading" | "uploaded";

function getButtonIcon(state: State): MonotoneIcons {
    switch (state) {
        case "idle":
            return "mt-rec";
        case "record":
        case "recording":
            return "mt-stop";
        case "stop":
        case "uploading":
        case "uploaded":
            return "mt-dots-horizontal";
        default:
            assertNever(state);
    }
}

function getOptions() {
    if (MediaRecorder.isTypeSupported("audio/mpeg")) {
        return { mimeType: "audio/mpeg" };
    } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
        return { mimeType: "audio/mp4" };
    } else if (MediaRecorder.isTypeSupported("audio/aac")) {
        return { mimeType: "audio/aac" };
    } else if (MediaRecorder.isTypeSupported("audio/webm")) {
        return { mimeType: "audio/webm" };
    } else if (MediaRecorder.isTypeSupported("video/mp4")) {
        // ios fallback..
        return { mimeType: "video/mp4", videoBitsPerSecond: 100000 };
    } else {
        logError("no suitable mimetype found for this device");
    }
    return undefined;
}

function extentionFromBlob(blob: Blob): string | undefined {
    const filetype = blob.type.split("/").pop();
    return filetype === "mpeg" ? "mp3" : filetype;
}

const WireAudioRecorderImp: WireRenderer<WireAudioRecorderImplProps> = p => {
    const { backend, saveTo, recordText = "Record", uploadingText, title, onRecordingComplete } = p;
    const [state, setState] = useState<State>("idle");
    const recorder = useRef<MediaRecorder | null>(null);

    const options = useMemo(getOptions, []);
    const uploadFile = useFileUploader();

    const setValue = useCallback(
        async (newVal: Blob) => {
            if (saveTo.onChangeToken === undefined) return;
            const ext = extentionFromBlob(newVal);
            const file = new File([newVal], `${title ?? "recording"}.${ext}`);
            setState("uploading");
            try {
                const url = await handleFileUpload(uploadFile, file, backend.appID);
                if (url === undefined) {
                    toast.error("Failed to save audio", { icon: null });
                    setState("idle");
                    return;
                } else {
                    backend.valueChanged(saveTo.onChangeToken, url, ValueChangeSource.User);
                    // For some reason, if we don't setImmediate here, the action _sometimes_ doesn't run.
                    setTimeout(() => {
                        runActionAndHandleURL(onRecordingComplete, backend, onRecordingComplete?.url !== undefined);
                    }, 0);
                }
            } catch (e: unknown) {
                toast.error("Failed to save audio", { icon: null });
                logError(e);
                setState("idle");
                return;
            }
            setState("uploaded");
        },

        [saveTo.onChangeToken, title, uploadFile, backend, onRecordingComplete]
    );

    useEffect(() => {
        if (navigator?.mediaDevices?.getUserMedia === undefined) {
            logError("No user media device - ensure you're running on https");
            return;
        }

        switch (state) {
            case "idle":
                break;
            case "record":
                navigator.mediaDevices
                    .getUserMedia({
                        audio: true,
                    })
                    .then(stream => {
                        setState("recording");
                        const mediaRecorder = new MediaRecorder(stream, options);
                        recorder.current = mediaRecorder;
                        mediaRecorder.start();
                        mediaRecorder.addEventListener("dataavailable", async (event: { data: Blob }) => {
                            const ext = extentionFromBlob(event.data);
                            const transcoded = await transcodeToMP3(`recording.${ext}`, event.data);
                            await setValue(transcoded);
                            stream.getTracks().forEach(track => track.stop());
                        });
                    })
                    .catch(err => {
                        logError(err);
                        toast.error("Error capturing audio", { icon: null });
                        setState("idle");
                    });
                break;
            case "recording":
                break;
            case "stop":
                recorder.current?.stop();
                break;
            case "uploading":
                break;
            case "uploaded":
                setState("idle");
                break;
            default:
                assertNever(state);
        }
    }, [options, setValue, state]);

    const buttonTextRaw = (s: State): string | undefined => {
        switch (s) {
            case "idle":
            case "record":
            case "uploaded":
                return undefinedIfEmptyString(recordText) ? "Record" : recordText;
            case "recording":
            case "stop":
                return "Stop";
            case "uploading":
                return uploadingText;
            default:
                assertNever(s);
        }
    };

    const buttonText = undefinedIfEmptyString(buttonTextRaw(state));

    const sizeClass = useResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    if (options === undefined) return null;

    return (
        <div tw="flex flex-row justify-between items-center w-full text-text-contextual-dark">
            {title !== undefined && <div tw="items-baseline w-full font-semibold">{title}</div>}
            <WireButton
                data-testid="record-button"
                tw="min-w-[fit-content]"
                size={isMobile ? "mini" : "md"}
                appearance={UIButtonAppearance.Bordered}
                onClick={() =>
                    state === "recording" ? setState("stop") : state === "idle" ? setState("record") : undefined
                }
            >
                <div tw="flex flex-row items-center [min-width:fit-content]">
                    <GlideIcon icon={getButtonIcon(state)} kind="monotone" iconSize={isMobile ? 16 : 20} />
                    {buttonText !== undefined && <div tw="ml-1.5 font-semibold ">{buttonText}</div>}
                </div>
            </WireButton>
        </div>
    );
};
