import { SyntheticColumnKind, type SyntheticColumnSpecification, decomposeAll } from "@glide/formula-specifications";
import { type ColumnTypeKind, type Formula, type TableColumn, isComputedColumn } from "@glide/type-schema";
import { assertNever } from "@glideapps/ts-necessities";
import { getHeaderIconForEditedColumn, getHeaderIconNameForEditedColumn } from "@glide/component-utils";
import type { GlideIconProps } from "@glide/plugins-codecs";
import { maybeGetHeaderIconColorForMonotoneIcon } from "@glide/common";
import type { ArrayFauxColumnKind } from "@glide/common-core/dist/js/faux-array-types";

export function getHeaderIconForTypeKind(kind: ColumnTypeKind | ArrayFauxColumnKind): GlideIconProps {
    switch (kind) {
        case "array":
            return { kind: "monotone", icon: "mt-header-array" };
        case "string-array":
            return { kind: "monotone", icon: "mt-header-multiple-strings" };
        case "uri-array":
            return { kind: "monotone", icon: "mt-header-multiple-files" };
        case "image-uri-array":
            return { kind: "monotone", icon: "mt-header-multiple-images" };
        case "table-ref":
            return { kind: "monotone", icon: "mt-header-reference" };
        case "audio-uri":
            return { kind: "monotone", icon: "mt-header-uri" };
        case "boolean":
            return { kind: "monotone", icon: "mt-header-boolean" };
        case "date":
        case "date-time":
            return { kind: "monotone", icon: "mt-header-date" };
        case "email-address":
            return { kind: "monotone", icon: "mt-header-email" };
        case "json":
            return { kind: "monotone", icon: "mt-header-email" };
        case "emoji":
            return { kind: "monotone", icon: "mt-header-emoji" };
        case "image-uri":
            return { kind: "monotone", icon: "mt-header-image" };
        case "markdown":
            return { kind: "monotone", icon: "mt-header-markdown" };
        case "duration":
            return { kind: "monotone", icon: "mt-header-duration" };
        case "number":
            return { kind: "monotone", icon: "mt-header-number" };
        case "phone-number":
            return { kind: "monotone", icon: "mt-header-phone" };
        case "string":
            return { kind: "monotone", icon: "mt-header-string" };
        case "time":
            return { kind: "monotone", icon: "mt-header-time" };
        case "uri":
            return { kind: "monotone", icon: "mt-header-uri" };
        default:
            return assertNever(kind);
    }
}

function getIntegrationIcon(spec: SyntheticColumnSpecification): GlideIconProps | undefined {
    if (spec.kind === SyntheticColumnKind.PluginComputation) {
        if (spec.pluginID === "glide-ai") {
            if (spec.computationID.startsWith("complete-prompt"))
                return {
                    kind: "monotone",
                    icon: "mt-column-generate-text",
                    ...maybeGetHeaderIconColorForMonotoneIcon("mt-column-generate-text"),
                };
            if (spec.computationID.startsWith("text-to-choice"))
                return {
                    kind: "monotone",
                    icon: "mt-column-choice",
                    ...maybeGetHeaderIconColorForMonotoneIcon("mt-column-choice"),
                };
            if (spec.computationID.startsWith("text-to-date"))
                return {
                    kind: "monotone",
                    icon: "mt-column-text-to-date",
                    ...maybeGetHeaderIconColorForMonotoneIcon("mt-column-text-to-date"),
                };
            if (spec.computationID.startsWith("text-to-boolean"))
                return {
                    kind: "monotone",
                    icon: "mt-column-boolean",
                    ...maybeGetHeaderIconColorForMonotoneIcon("mt-column-boolean"),
                };
            if (spec.computationID.startsWith("text-to-number"))
                return {
                    kind: "monotone",
                    icon: "mt-column-number",
                    ...maybeGetHeaderIconColorForMonotoneIcon("mt-column-number"),
                };
            if (spec.computationID.startsWith("audio-to-text"))
                return {
                    kind: "monotone",
                    icon: "mt-column-audio",
                    ...maybeGetHeaderIconColorForMonotoneIcon("mt-column-audio"),
                };
            if (spec.computationID.startsWith("image-to-text"))
                return {
                    kind: "monotone",
                    icon: "mt-column-image",
                    ...maybeGetHeaderIconColorForMonotoneIcon("mt-column-image"),
                };
            if (spec.computationID.startsWith("document-to-text"))
                return {
                    kind: "monotone",
                    icon: "mt-column-document",
                    ...maybeGetHeaderIconColorForMonotoneIcon("mt-column-document"),
                };
            if (spec.computationID.startsWith("text-to-json"))
                return {
                    kind: "monotone",
                    icon: "mt-column-json",
                    ...maybeGetHeaderIconColorForMonotoneIcon("mt-column-json"),
                };

            if (spec.computationID.startsWith("text-to-texts"))
                return {
                    kind: "monotone",
                    icon: "mt-column-json",
                    ...maybeGetHeaderIconColorForMonotoneIcon("mt-column-generate-text"),
                };
        }

        return { kind: "monotone", icon: "mt-header-integration" };
    } else {
        return undefined;
    }
}

function getHeaderIconForFormula(formula: Formula): GlideIconProps | undefined {
    const spec = decomposeAll(formula);
    if (spec !== undefined) {
        const integrationIcon = getIntegrationIcon(spec);
        if (integrationIcon !== undefined) return integrationIcon;
        return getHeaderIconForEditedColumn(spec.kind);
    }
    return;
}

export function getHeaderIcon(column: TableColumn): GlideIconProps {
    if (isComputedColumn(column)) {
        const maybeFormulaIcon = getHeaderIconForFormula(column.formula);
        if (maybeFormulaIcon !== undefined) return maybeFormulaIcon;
    }
    return getHeaderIconForTypeKind(column.type.kind);
}

export function getHeaderIconNameForTypeKind(kind: ColumnTypeKind | ArrayFauxColumnKind): string {
    switch (kind) {
        case "array":
            return "headerArray";
        case "string-array":
            return "headerMultipleStrings";
        case "image-uri-array":
            return "headerMultipleImages";
        case "uri-array":
            return "headerMultipleFiles";
        case "table-ref":
            return "headerReference";
        case "audio-uri":
            return "headerAudioUri";
        case "boolean":
            return "headerBoolean";
        case "date":
        case "date-time":
            return "headerDate";
        case "email-address":
            return "headerEmail";
        case "json":
            // FIXME: Use proper icon
            return "headerEmail";
        case "emoji":
            return "headerEmoji";
        case "image-uri":
            return "headerImage";
        case "markdown":
            return "headerMarkdown";
        case "duration":
            return "headerDuration";
        case "number":
            return "headerNumber";
        case "phone-number":
            return "headerPhone";
        case "string":
            return "headerString";
        case "time":
            return "headerTime";
        case "uri":
            return "headerUri";
        default:
            return assertNever(kind);
    }
}

function getIntegrationIconName(spec: SyntheticColumnSpecification): string | undefined {
    if (spec.kind === SyntheticColumnKind.PluginComputation) {
        if (spec.pluginID === "glide-ai") {
            if (spec.computationID.startsWith("complete-prompt")) return "headerGlideAIGenerateText";
            if (spec.computationID.startsWith("text-to-texts")) return "headerGlideAIGenerateText";
            if (spec.computationID.startsWith("text-to-choice")) return "headerGlideAIChoice";
            if (spec.computationID.startsWith("text-to-date")) return "headerGlideAITextToDate";
            if (spec.computationID.startsWith("text-to-boolean")) return "headerGlideAIBoolean";
            if (spec.computationID.startsWith("text-to-number")) return "headerGlideAINumber";
            if (spec.computationID.startsWith("audio-to-text")) return "headerGlideAIAudio";
            if (spec.computationID.startsWith("image-to-text")) return "headerGlideAIImage";
            if (spec.computationID.startsWith("document-to-text")) return "headerGlideAIDocument";
            if (spec.computationID.startsWith("text-to-json")) return "headerGlideAIJson";
        }
        return "headerIntegration";
    } else {
        return undefined;
    }
}

export function getHeaderIconNameForFormula(formula: Formula): string | undefined {
    const spec = decomposeAll(formula);
    if (spec !== undefined) {
        const integrationIcon = getIntegrationIconName(spec);
        if (integrationIcon !== undefined) return integrationIcon;
        return getHeaderIconNameForEditedColumn(spec.kind);
    }
    return;
}

export function getHeaderIconName(column: TableColumn): string {
    if (isComputedColumn(column)) {
        const maybeFormulaIcon = getHeaderIconNameForFormula(column.formula);
        if (maybeFormulaIcon !== undefined) return maybeFormulaIcon;
    }
    return getHeaderIconNameForTypeKind(column.type.kind);
}
