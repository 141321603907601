import { GlideIcon } from "@glide/common";
import type { IconImage } from "@glide/app-description";
import { usePlayerEminenceFlags } from "@glide/player-core";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { SignInBranding, useWireAppTheme } from "@glide/wire-renderer";
import { defined } from "@glideapps/ts-necessities";
import "twin.macro";
import { AuthWrapper } from "@glide/auth-controller";
import classNames from "classnames";
import chroma from "chroma-js";

type Props = {
    iconImage?: IconImage;
};

export const PlayerLoading: React.FC<Props> = ({ iconImage }) => {
    const appID = defined(useAppID());
    const theme = useWireAppTheme();
    const eminenceFlags = usePlayerEminenceFlags(appID);
    const canCustomizeSignIn = eminenceFlags.canCustomizeSignIn;
    const hasBackgroundImage = typeof theme.signInBackground === "string" && eminenceFlags.canCustomizeSignIn;
    const useLighterSpinner =
        theme.pageTheme === "Dark" ||
        (theme.pageTheme === "Highlight" && theme.pageEnvironment === "Dark") ||
        (theme.pageTheme === "Accent" && chroma(theme.primaryAccentColor).luminance() < 0.5) ||
        hasBackgroundImage;

    return (
        <AuthWrapper>
            <div tw="flex justify-center w-full">
                <div
                    className={classNames(hasBackgroundImage && "has-background-image")}
                    tw="flex flex-col items-center rounded-3xl m-8 [&.has-background-image]:bg-[rgba(0,0,0,0.9)] [&.has-background-image]:supports-[backdrop-filter]:(bg-[rgba(0,0,0,0.5)] [backdrop-filter:blur(18px)]) w-full max-w-[390px]">
                    {iconImage !== undefined ? (
                        <div
                            className={classNames(theme.pagesSignInLogo !== undefined && "has-logo")}
                            tw="mb-10 gp-md:(mb-0) [&.has-logo]:mb-8">
                            <SignInBranding
                                theme={theme}
                                iconImage={iconImage}
                                logo={theme.pagesSignInLogo}
                                canCustomizeSignIn={canCustomizeSignIn}
                                appFeatures={{}}
                                appID={appID}
                            />
                        </div>
                    ) : null}
                    <GlideIcon
                        kind="stroke"
                        icon="st-half-spinner"
                        spin={true}
                        tw="mb-8"
                        strokeColor={useLighterSpinner ? "rgb(200, 200, 200)" : undefined}
                    />
                </div>
            </div>
        </AuthWrapper>
    );
};
