import { asString, isPrimitiveValue } from "@glide/computation-model-types";
import {
    type TableName,
    areTableNamesEqual,
    isTableName,
    makeTableName,
    type TableAndColumn,
    type TableColumn,
    SourceColumnKind,
    getTableName,
    getTableRefTableName,
    isSingleRelationType,
    tableColumnCodec,
} from "@glide/type-schema";
import { namesForRows } from "@glide/common-core/dist/js/description";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { getColumnNameAndGroup } from "@glide/generator/dist/js/description-utils";
import { isEmptyOrUndefined } from "@glide/support";
import { defined, hasOwnProperty, isEnumValue } from "@glideapps/ts-necessities";
import * as React from "react";
import { shallowEqual } from "react-redux";
import { useBuilderSelector } from "../../hooks/use-builder-selector";
import type { ItemDescription, ItemPath } from "../../lib/dropdown-types";
import { getHeaderIcon } from "../../lib/header-icons";
import { type GlideDropdownProps, GlideDropdown } from "../glide-dropdown/glide-dropdown";
import type { GetColumnValue } from "./get-column-value";
import type { ExtendedActionOutputDescriptor } from "@glide/generator/dist/js/prior-step";
import type { GlideIconProps } from "@glide/plugins-codecs";
import { GlideIcon } from "@glide/common";

interface Props<T> extends Omit<GlideDropdownProps<T | ColumnItem>, "descriptionForItem" | "highlight"> {
    readonly getColumnValue?: GetColumnValue;
    readonly descriptionForItem?: (item: T) => ItemDescription;
    readonly navigateToColumn: (appID: string, tableName: TableName, columnName: string | undefined) => Promise<void>;
    readonly disableHighlight?: boolean;
    readonly writesToColumn?: TableAndColumn;
}

export interface ColumnItem {
    readonly kind: SourceColumnKind;
    // `undefined` means the whole row
    readonly column: TableColumn | undefined;
    // This is only used for highlighting in global search.
    readonly containingRelationColumn?: { tableName: TableName; column: TableColumn };
    readonly table: TableName | undefined;
}

export function isColumnItem(item: unknown): item is ColumnItem {
    if (!hasOwnProperty(item, "kind")) return false;
    if (!isEnumValue(SourceColumnKind, item.kind)) return false;
    if (hasOwnProperty(item, "column")) {
        if (item.column !== undefined && !tableColumnCodec.is(item.column)) return false;
    }
    return true;
}

// This is a class to prevent conflicts
export class ActionOutputItem {
    constructor(
        public actionOutput: ExtendedActionOutputDescriptor,
        public isGrouped: boolean,
        public actionName: string,
        public key: string,
        public icon: string | GlideIconProps
    ) {}
}

function getActionOutputItemTableName(item: ActionOutputItem): string | TableName | undefined {
    if (isSingleRelationType(item.actionOutput.type)) {
        const maybeTableName = getTableRefTableName(item.actionOutput.type);
        return isTableName(maybeTableName) ? maybeTableName : undefined;
    }

    return item.actionOutput.table?.name;
}

export function BaseColumnSelect<T>(p: Props<T>): React.ReactElement | null {
    const {
        descriptionForItem,
        getColumnValue,
        disableHighlight,
        onItemActionClicked,
        writesToColumn,
        customInputValue,
        navigateToColumn,
        constantIsSecret,
        ...rest
    } = p;
    const { selected } = p;

    const appID = useAppID();

    const search = useBuilderSelector(s => s.search, shallowEqual);

    const onItemActionClickedInner = React.useCallback(
        async (item: ColumnItem | ActionOutputItem | T, path: ItemPath, e: React.MouseEvent) => {
            if (!isColumnItem(item) && !(item instanceof ActionOutputItem)) {
                onItemActionClicked?.(item, path, e);
                return;
            }
            const tableName = isColumnItem(item) ? item.table : getActionOutputItemTableName(item);
            const column = isColumnItem(item) ? item.column?.name : item.actionOutput.columnName;
            if (appID === undefined || tableName === undefined) return;

            await navigateToColumn(appID, makeTableName(tableName), column);
        },

        [appID, navigateToColumn, onItemActionClicked]
    );

    const descriptionForItemImpl = React.useCallback(
        (item: T | ColumnItem): ItemDescription => {
            if (!isColumnItem(item)) {
                return defined(descriptionForItem)(item);
            }

            if (item.column === undefined) {
                return {
                    name: namesForRows[item.kind],
                    layoutStyle: "vertical",
                    actionIcon: "01-04-logout-alternate",
                };
            }

            const value = getColumnValue?.(item.kind, item.column);
            const hint = isPrimitiveValue(value) ? asString(value) : undefined;
            return {
                name: getColumnNameAndGroup(item.column)[1],
                icon: <GlideIcon {...getHeaderIcon(item.column)} />,
                hint,
                layoutStyle: "vertical",
                actionIcon: "01-04-logout-alternate",
            };
        },
        [descriptionForItem, getColumnValue]
    );

    let highlight = false;
    let highlightLabel = false;
    if (disableHighlight !== true && search.showSearch) {
        function doesColumnNameMatch(n: string) {
            return search.columnName === n || search.childColumnNames?.includes(n) === true;
        }

        if (isColumnItem(selected)) {
            const { column, table, containingRelationColumn: relation } = selected;
            if (
                column !== undefined &&
                doesColumnNameMatch(column.name) &&
                areTableNamesEqual(table, search.tableName)
            ) {
                highlight = true;
            } else if (relation !== undefined) {
                highlight =
                    doesColumnNameMatch(relation.column.name) &&
                    areTableNamesEqual(relation.tableName, search.tableName);
            }
        } else if (selected !== undefined) {
            const columnWritten = descriptionForItem?.(selected).columnWritten;
            if (columnWritten !== undefined) {
                const [writtenTableName, writtenColumnName] = columnWritten;
                highlight =
                    doesColumnNameMatch(writtenColumnName) && areTableNamesEqual(search.tableName, writtenTableName);
            }
        }

        if (
            writesToColumn !== undefined &&
            doesColumnNameMatch(writesToColumn.column.name) &&
            areTableNamesEqual(getTableName(writesToColumn.table), search.tableName) &&
            (selected !== undefined || !isEmptyOrUndefined(customInputValue))
        ) {
            highlightLabel = true;
        }
    }

    return (
        <GlideDropdown
            {...rest}
            customInputValue={customInputValue}
            highlight={highlight}
            highlightLabel={highlightLabel}
            descriptionForItem={descriptionForItemImpl}
            onItemActionClicked={onItemActionClickedInner}
            constantIsSecret={constantIsSecret}
        />
    );
}
