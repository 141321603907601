import { formatLocalizedString } from "@glide/localization";
import type { PrimitiveValue } from "@glide/computation-model-types";
import { asMaybeNumber } from "@glide/common-core/dist/js/computation-model/data";
import {
    getSwitchProperty,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
} from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireNumberFieldComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    RequiredKind,
    makeNumberPropertyDescriptor,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { checkNumber } from "@glide/support";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import { ValueFormatKind } from "@glide/formula-specifications";
import { inflateNumberProperty } from "../wire/utils";
import { type BaseFieldComponentDescription, BaseFieldComponentHandler, defaultFieldComponent } from "./base-field";
import { autofocusPropertyHandler } from "./autofocus-field";

const ComponentKindNumberField: ComponentKind = "number-field";

const propertyNamePropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
        ColumnPropertyFlag.Searchable,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    undefined,
    getPrimitiveColumnsSpec,
    "number",
    PropertySection.Data
);

interface NumberFieldComponentDescription extends BaseFieldComponentDescription {
    readonly minValue: PropertyDescription | undefined;
    readonly maxValue: PropertyDescription | undefined;
    readonly autofocus?: boolean;
}

function convertInForNumberField(input: PrimitiveValue): number | undefined {
    if (typeof input === "number") {
        return input;
    } else {
        return asMaybeNumber(input);
    }
}

export class NumberFieldComponentHandler extends BaseFieldComponentHandler<NumberFieldComponentDescription> {
    public readonly appKinds = "both";

    constructor() {
        super(ComponentKindNumberField, "number");
    }

    public needValidation(desc: NumberFieldComponentDescription): boolean {
        return super.needValidation(desc) || desc.minValue !== undefined || desc.maxValue !== undefined;
    }

    public getDescriptor(
        desc: NumberFieldComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties = [
            makeNumberPropertyDescriptor(
                "minValue",
                "Min Value",
                "0",
                RequiredKind.NotRequiredDefaultMissing,
                0,
                mutatingScreenKind,
                {
                    searchable: false,
                    emptyByDefault: true,
                }
            ),
            makeNumberPropertyDescriptor(
                "maxValue",
                "Max Value",
                "100",
                RequiredKind.NotRequiredDefaultMissing,
                100,
                mutatingScreenKind,
                {
                    searchable: false,
                    emptyByDefault: true,
                }
            ),
            autofocusPropertyHandler,
        ];
        return this.getFieldDescriptor(
            desc,
            ccc,
            "Number Entry",
            "Edit a number",
            "Entry Fields",
            "co-number-entry",
            propertyNamePropertyHandler,
            false,
            getDocURL("numberField"),
            mutatingScreenKind,
            "Number",
            properties
        );
    }

    public inflate(
        ib: WireInflationBackend,
        desc: NumberFieldComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { appKind } = ib.adc;

        const [minGetter] = inflateNumberProperty(ib, desc.minValue);
        const [maxGetter] = inflateNumberProperty(ib, desc.maxValue);
        const autofocus = getSwitchProperty(desc.autofocus);

        const isValid = (n: number, min: number | undefined, max: number | undefined): number => {
            if (min !== undefined && n < min) return -1;
            if (max !== undefined && n > max) return 1;
            return 0;
        };

        return super.inflateComponent(
            ib,
            desc,
            (base, value, token, hb, formatSpec) => {
                const n = convertInForNumberField(value);
                const minValue = minGetter(hb);
                const maxValue = maxGetter(hb);
                const valid = n === undefined ? 0 : isValid(n, minValue ?? undefined, maxValue ?? undefined);
                let prefix: string | undefined;
                let suffix: string | undefined;
                if (formatSpec?.kind === ValueFormatKind.Number) {
                    if (formatSpec.unitAfterNumber) {
                        suffix = formatSpec.unit;
                    } else {
                        prefix = formatSpec.unit;
                    }
                }
                const component: WireNumberFieldComponent = {
                    ...base,
                    kind: WireComponentKind.NumberField,
                    value: {
                        value: n,
                        onChangeToken: token,
                        error:
                            valid < 0
                                ? formatLocalizedString(
                                      "mustBeGreaterThan",
                                      [checkNumber(minValue).toString()],
                                      appKind
                                  )
                                : valid > 0
                                ? formatLocalizedString("mustBeLessThan", [checkNumber(maxValue).toString()], appKind)
                                : undefined,
                    },
                    minValue,
                    maxValue,
                    prefix,
                    suffix,
                    autofocus,
                };
                return component;
            },
            (value, hb) => {
                const n = convertInForNumberField(value);
                if (n === undefined) return true;
                return isValid(n, minGetter(hb) ?? undefined, maxGetter(hb) ?? undefined) === 0;
            }
        );
    }

    public static defaultComponent(column: TableColumn): NumberFieldComponentDescription {
        return { ...defaultFieldComponent(column, ComponentKindNumberField), minValue: undefined, maxValue: undefined };
    }
}
