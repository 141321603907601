import {
    asString,
    type DefinedPrimitiveValue,
    type LoadedGroundValue,
    type QueryAggregate,
    type QueryGroupBy,
    RollupKind,
    type GroupByRowBase,
} from "@glide/computation-model-types";
import {
    type BasePrimitiveValue,
    GlideDateTime,
    GlideJSON,
    isGlideDateTimeDocumentData,
    isGlideJSONDocumentData,
} from "@glide/data-types";
import { asMaybeDate } from "@glide/common-core/dist/js/computation-model/data";
import type { TableColumn } from "@glide/type-schema";
import { DefinedPrimitiveValueMap } from "@glide/generator/dist/js/wire/utils";
import { asBoolean, asNumber, asMaybeNumber } from "@glide/common-core/dist/js/type-conversions";
import { isArray, isEmpty } from "@glide/support";
import { assertNever } from "@glideapps/ts-necessities";
import { convertValueForColumnType } from "./query-eval";
import { applySort } from "./query-sort";
import { compareValues } from "@glide/common-core/dist/js/components/primitives";
import { type ColumnValueGetter, asPrimitive } from "./refilter";

export type RowColumnValueGetter<T> = (row: T, columnName: string) => unknown;

// modifies groupByRow
export function updateAggregate(
    groupByRow: any,
    aggregate: QueryAggregate,
    getColumnValue: ColumnValueGetter,
    columns: readonly TableColumn[]
) {
    const { column, name, kind } = aggregate;
    const columnValue = getColumnValue(column);
    const prev = groupByRow[name];
    switch (kind) {
        case RollupKind.AllTrue:
            groupByRow[name] = (prev === undefined || asBoolean(prev)) && asBoolean(columnValue);
            break;
        case RollupKind.SomeTrue:
            groupByRow[name] = asBoolean(prev) || asBoolean(columnValue);
            break;
        case RollupKind.Sum: {
            const value = asMaybeNumber(columnValue);
            if (value !== undefined) {
                groupByRow[name] = asNumber(prev) + value;
            }
            break;
        }
        case RollupKind.CountNonEmpty:
            groupByRow[name] = asNumber(prev) + (isEmpty(columnValue) ? 0 : 1);
            break;
        case RollupKind.Average: {
            // divided later
            const value = asMaybeNumber(columnValue);
            if (value !== undefined) {
                const [sum, count] = Array.isArray(prev) ? prev : [0, 0];
                groupByRow[name] = [sum + value, count + 1];
            }
            break;
        }
        case RollupKind.Minimum: {
            const value = asMaybeNumber(columnValue);
            if (value !== undefined) {
                groupByRow[name] = prev === undefined ? value : Math.min(asNumber(prev), value);
            }
            break;
        }
        case RollupKind.Maximum: {
            const value = asMaybeNumber(columnValue);
            if (value !== undefined) {
                groupByRow[name] = prev === undefined ? value : Math.max(asNumber(prev), value);
            }
            break;
        }
        case RollupKind.Earliest: {
            // primValue might be `null`. This is a consequence of column
            // ordering support in plugin tables. Consider the following setup:
            //    const columns = ["A", "B", "C", "D"];
            //    const row = [1, null, 2];
            // A=1, C=2, B and D are empty, but C is always set.
            // This was necessary due to JSON not having an `undefined`.
            // But Glide internally wants to use `undefined` instead of null,
            // especially for the type conversion code. So we have to say
            // primValue ?? undefined instead, since it could be null, but
            // these functions don't take null.
            const tableColumn = columns.find(col => col.name === column);
            const primValue = asPrimitive(columnValue);
            const value =
                tableColumn === undefined
                    ? asMaybeDate(primValue ?? undefined)
                    : convertValueForColumnType(primValue ?? undefined, tableColumn, undefined);
            if (value instanceof GlideDateTime) {
                const prevDate = asMaybeDate(prev);
                groupByRow[name] = prevDate === undefined || value.compareTo(prevDate) < 0 ? value : prevDate;
            }
            break;
        }
        case RollupKind.Latest: {
            // primValue might be `null`. This is a consequence of column
            // ordering support in plugin tables. Consider the following setup:
            //    const columns = ["A", "B", "C", "D"];
            //    const row = [1, null, 2];
            // A=1, C=2, B and D are empty, but C is always set.
            // This was necessary due to JSON not having an `undefined`.
            // But Glide internally wants to use `undefined` instead of null,
            // especially for the type conversion code. So we have to say
            // primValue ?? undefined instead, since it could be null, but
            // these functions don't take null.
            const tableColumn = columns.find(col => col.name === column);
            const primValue = asPrimitive(columnValue);
            const value =
                tableColumn === undefined
                    ? asMaybeDate(primValue ?? undefined)
                    : convertValueForColumnType(primValue ?? undefined, tableColumn, undefined);
            if (value instanceof GlideDateTime) {
                const prevDate = asMaybeDate(prev);
                groupByRow[name] = prevDate === undefined || value.compareTo(prevDate) > 0 ? value : prevDate;
            }
            break;
        }
        case RollupKind.Range: {
            // subtracted later
            const value = asMaybeNumber(columnValue);
            if (value !== undefined) {
                const [min, max] = Array.isArray(prev) ? prev : [value, value];
                groupByRow[name] = [Math.min(min, value), Math.max(max, value)];
            }
            break;
        }
        case RollupKind.CountTrue:
            groupByRow[name] = asNumber(prev) + (asBoolean(columnValue) ? 1 : 0);
            break;
        case RollupKind.CountNotTrue:
            groupByRow[name] = asNumber(prev) + (asBoolean(columnValue) ? 0 : 1);
            break;
        case RollupKind.CountUnique: // counted later
            const set = prev instanceof Set ? prev : (groupByRow[name] = new Set());
            set.add(columnValue);
            break;
        case "join-strings": {
            const raw = columnValue;
            let value: string;
            if (isGlideDateTimeDocumentData(raw)) {
                if (raw.repr !== undefined) {
                    value = raw.repr;
                } else {
                    const date = new Date(raw.value);
                    value = date.toISOString().substring(0, 19) + "Z";
                }
            } else if (isGlideJSONDocumentData(raw)) {
                value = raw.value;
            } else {
                value = asString(raw);
            }
            if (value === "") break;

            let result = asString(prev);
            if (result === "") {
                result = value;
            } else {
                result = result + aggregate.separator + value;
            }
            groupByRow[name] = result;
            break;
        }
        case "unique-array-elements": {
            const unique =
                prev instanceof DefinedPrimitiveValueMap ? prev : (groupByRow[name] = new DefinedPrimitiveValueMap());
            function process(v: unknown | readonly unknown[]) {
                if (isArray(v)) {
                    for (const e of v) {
                        process(e);
                    }
                    return;
                }
                const primitiveValue = asPrimitive(v);

                if (primitiveValue === null || primitiveValue === "") return;

                if (!unique.has(primitiveValue)) {
                    unique.set(primitiveValue, primitiveValue);
                }
            }
            process(columnValue);
            break;
        }
        default:
            return assertNever(kind);
    }
    return;
}

function sortUniqueArrayElements(arr: DefinedPrimitiveValue[]) {
    return arr.sort((a, b) => compareValues(a as unknown as LoadedGroundValue, b as unknown as LoadedGroundValue) ?? 0);
}

interface GroupEntry<T> {
    subEntries?: DefinedPrimitiveValueMap<GroupEntry<T>>;
    value?: T;
}

class Grouper<T> {
    private readonly root: GroupEntry<T> = {};

    public addGroup(group: readonly DefinedPrimitiveValue[], makeValue: () => T): T {
        let entry = this.root;
        for (const value of group) {
            if (entry.subEntries === undefined) {
                entry.subEntries = new DefinedPrimitiveValueMap();
            }
            let sub = entry.subEntries.get(value);
            if (sub === undefined) {
                sub = {};
                entry.subEntries.set(value, sub);
            }
            entry = sub;
        }
        if (entry.value === undefined) {
            entry.value = makeValue();
        }
        return entry.value;
    }
}

export function evaluateGroupBy<TRow, TNonBase>(
    { aggregates, columns: groupByColumns, sort, limit }: QueryGroupBy,
    columns: readonly TableColumn[],
    results: readonly TRow[],
    getColumnValue: RowColumnValueGetter<TRow>,
    toNonBase: (v: GlideDateTime | GlideJSON) => TNonBase
): readonly GroupByRowBase<TNonBase>[] {
    function convertValueToOut(value: DefinedPrimitiveValue): BasePrimitiveValue | TNonBase {
        if (value instanceof GlideDateTime || value instanceof GlideJSON) {
            return toNonBase(value);
        }
        return value;
    }

    const grouper = new Grouper<GroupByRowBase<TNonBase>>();
    const groupByResults: GroupByRowBase<TNonBase>[] = [];
    for (const row of results) {
        // value might be `null`. This is a consequence of column
        // ordering support in plugin tables. Consider the following setup:
        //    const columns = ["A", "B", "C", "D"];
        //    const row = [1, null, 2];
        // A=1, C=2, B and D are empty, but C is always set.
        // This was necessary due to JSON not having an `undefined`.
        // But Glide internally wants to use `undefined` instead of null,
        // especially for the type conversion code. So we have to say
        // value ?? undefined instead, since it could be null, but
        // these functions don't take null.
        const group = groupByColumns.map(columnName => {
            const value = asPrimitive(getColumnValue(row, columnName)) ?? undefined;
            const column = columns.find(col => col.name === columnName);
            return column === undefined ? value ?? "" : convertValueForColumnType(value, column, "");
        });
        const groupByResult = grouper.addGroup(group, () => {
            const groupByRow: GroupByRowBase<TNonBase> = {
                group: group.map(convertValueToOut),
                count: 0,
            };
            groupByResults.push(groupByRow);
            return groupByRow;
        });
        groupByResult.count++;

        for (const aggregate of aggregates) {
            updateAggregate(groupByResult, aggregate, cn => getColumnValue(row, cn), columns);
        }
    }
    for (const aggregate of aggregates) {
        switch (aggregate.kind) {
            case RollupKind.Average:
                for (const groupByResult of groupByResults) {
                    const value = groupByResult[aggregate.name];
                    if (value !== undefined) {
                        const [sum, count] = value as [number, number];
                        groupByResult[aggregate.name] = sum / count;
                    }
                }
                break;
            case RollupKind.Range:
                for (const groupByResult of groupByResults) {
                    const value = groupByResult[aggregate.name];
                    if (value !== undefined) {
                        const [min, max] = value as [number, number];
                        groupByResult[aggregate.name] = max - min;
                    }
                }
                break;
            case RollupKind.CountUnique:
                for (const groupByResult of groupByResults) {
                    groupByResult[aggregate.name] = (groupByResult[aggregate.name] as unknown as Set<any>)?.size ?? 0;
                }
                break;
            case RollupKind.Earliest:
            case RollupKind.Latest:
                for (const groupByResult of groupByResults) {
                    const value = groupByResult[aggregate.name];
                    if (value instanceof GlideDateTime) {
                        groupByResult[aggregate.name] = toNonBase(value);
                    }
                }
                break;
            case "unique-array-elements":
                for (const groupByResult of groupByResults) {
                    const value = groupByResult[aggregate.name] as unknown as DefinedPrimitiveValueMap<unknown>;
                    groupByResult[aggregate.name] = sortUniqueArrayElements([...value.keys()]).map(convertValueToOut);
                }
        }
    }
    if (sort !== undefined) {
        // FIXME: We need to pass the proper aggregate columns in the first argument here
        applySort(
            columns,
            sort,
            groupByResults,
            (r, c) => {
                const i = groupByColumns.indexOf(c);
                return i > -1 ? r.group[i] : r[c];
            },
            false
        );
    }

    return groupByResults.slice(0, limit);
}
