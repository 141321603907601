import { useState } from "react";

export function useRefState<T>(): [(el: T | null) => void, T | undefined] {
    const [refState, setRefState] = useState<T | undefined>();
    const refFunc = (el: T | null) => {
        if (el === null) {
            setRefState(undefined);
        } else {
            setRefState(el);
        }
    };
    return [refFunc, refState];
}
