import {
    type ArrayItemType,
    type TableName,
    type TableRefGlideType,
    isTableName,
    getTableName,
    getTableRefTableName,
    isSingleRelationType,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type ClassOrArrayScreenDescription,
    type EditScreenDescription,
    type ScreenDescription,
    ScreenDescriptionKind,
} from "@glide/app-description";

export type SignInUpScreenName = "sign-in" | "sign-up";

export const signInScreenName = "sign-in" as const;
export const signUpScreenName = "sign-up" as const;
export const chatScreenName = "chat" as const;
export const shoppingCartScreenName = "shopping-cart" as const;
export const buyScreenName = "buy-screen" as const;
export const userProfileScreenName = "user-profile" as const;
export const webViewScreenName = "web-view" as const;
export const shareScreenName = "share" as const;
export const sendAppFeedbackScreenName = "author-feedback" as const;
export const codeScannerScreenName = "code-scanner" as const;
export const liveLinearBarcodeScannerScreenName = "live-linear-barcode-scanner" as const;
export const requestSignatureScreenName = "request-signature-screen" as const;
export const voiceEntryScreenName = "voice-entry-screen" as const;

const classScreenPrefix = "class-";
const arrayScreenPrefix = "array-";
const arrayTableScreenPrefix = arrayScreenPrefix + "table-";
export const formScreenPrefix = "form-";
export const freeScreenPrefix = "free-";
const menuScreenPrefix = "menu-";
const variantPrefix = "variant-";
const addClassScreenPrefix = "add-class-";
const editClassScreenPrefix = "edit-class-";

export function classScreenName(nameOrRef: TableName | TableRefGlideType): string {
    return classScreenPrefix + getTableRefTableName(nameOrRef).name;
}

export function arrayScreenName(schema: SchemaInspector, itemType: TableRefGlideType): string;
export function arrayScreenName(schema: SchemaInspector, itemType: ArrayItemType): string | undefined;
export function arrayScreenName(schema: SchemaInspector, itemType: ArrayItemType): string | undefined {
    if (isSingleRelationType(itemType)) {
        let name = getTableRefTableName(itemType);
        if (!isTableName(name)) {
            const t = schema.findTable(name);
            if (t === undefined) return undefined;
            name = getTableName(t);
        }
        return arrayTableScreenPrefix + name.name;
    } else {
        return arrayScreenPrefix + "primitive-" + itemType.kind;
    }
}

export function isDefaultArrayScreenName(screenName: string): boolean {
    return screenName.startsWith(arrayScreenPrefix);
}

export function getClassScreenTableName(screenName: string): string | undefined {
    if (screenName.startsWith(classScreenPrefix)) {
        return screenName.substring(classScreenPrefix.length);
    }
    return undefined;
}

export function getAddClassScreenTableName(screenName: string): string | undefined {
    if (screenName.startsWith(addClassScreenPrefix)) {
        return screenName.substring(addClassScreenPrefix.length);
    }
    return undefined;
}

export function getEditClassScreenTableName(screenName: string): string | undefined {
    if (screenName.startsWith(editClassScreenPrefix)) {
        return screenName.substring(editClassScreenPrefix.length);
    }
    return undefined;
}

export function getArrayScreenTableName(screenName: string): string | undefined {
    if (screenName.startsWith(arrayTableScreenPrefix)) {
        return screenName.substring(arrayTableScreenPrefix.length);
    }
    return undefined;
}

export function getClassOrArrayScreenTableName(screenName: string): string | undefined {
    return getClassScreenTableName(screenName) ?? getArrayScreenTableName(screenName);
}

export function addClassScreenName(nameOrRef: TableName | TableRefGlideType): string {
    const name = getTableRefTableName(nameOrRef);
    return addClassScreenPrefix + name.name;
}

export function editClassScreenName(nameOrRef: TableName | TableRefGlideType): string {
    const name = getTableRefTableName(nameOrRef);
    return editClassScreenPrefix + name.name;
}

export function isAddClassScreenName(screenName: string): boolean {
    return screenName.startsWith(addClassScreenPrefix);
}

export function isEditClassScreenName(screenName: string): boolean {
    return screenName.startsWith(editClassScreenPrefix);
}

export function isVariantScreenName(screenName: string): boolean {
    return screenName.startsWith(variantPrefix);
}

export function isAddOrEditOrFormScreenDescription(
    screenName: string,
    desc: ScreenDescription
): desc is EditScreenDescription {
    if (desc.kind !== ScreenDescriptionKind.Class) return false;
    return isEditClassScreenName(screenName) || isAddClassScreenName(screenName) || desc.isForm === true;
}

export function makeFormScreenName(formName: string): string {
    return formScreenPrefix + formName;
}

export function isFormScreenName(name: string): boolean {
    return name.startsWith(formScreenPrefix);
}

export function isFreeScreenName(name: string): boolean {
    return name.startsWith(freeScreenPrefix);
}

export function isFreeScreen(name: string, desc: ScreenDescription): desc is ClassOrArrayScreenDescription {
    if (desc.kind !== ScreenDescriptionKind.Class && desc.kind !== ScreenDescriptionKind.Array) return false;
    return isFreeScreenName(name);
}

export function getMenuScreenName(menuID: string, primaryKeyHash: string | undefined): string {
    return `${menuScreenPrefix}${menuID}-${primaryKeyHash ?? ""}`;
}
