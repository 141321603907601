import type { Row } from "@glide/computation-model-types";
import { asMaybeNumber, asMaybeString, isBound, isLoadingValue, RollupKind } from "@glide/computation-model-types";
import {
    ArrayScreenFormat,
    getArrayProperty,
    getEnumProperty,
    getStringProperty,
    getColumnProperty,
    getSwitchProperty,
    getSourceColumnProperty,
} from "@glide/app-description";
import type { DataPlotRowData, WireDataPlotComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    ChartingGradientMapping,
    DataPlotColorMode,
    type DataPlotDataPointDescription,
    DataPlot,
    ChartingColorScheme,
    DataPlotChartSize,
} from "@glide/fluent-components/dist/js/fluent-components";

import { getTableAndColumnForSourceColumn, type InlineListComponentDescription } from "@glide/function-utils";
import type { WireRowComponentHydrationBackend } from "@glide/wire";
import { DataPlotType, type WireRowHydrationValueProvider, WireComponentKind } from "@glide/wire";

import {
    inflateComponentEnricher,
    inflateNumberProperty,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
} from "../wire/utils";
import { makeFluentArrayContentHandler } from "./fluent-array-handler";
import { definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import { getTableColumnDisplayName, maybeGetTableColumn } from "@glide/type-schema";
import { isEmpty } from "@glide/support";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { isQueryableColumn } from "../computed-columns";
import { decomposeAggregateRow } from "../wire/aggregates";

export const dataPlotFluentArrayContentHandler = makeFluentArrayContentHandler(
    DataPlot,
    (ib, desc, containingRowIB) => {
        if (containingRowIB === undefined) {
            return undefined;
        }

        const [componentTitleGetter] = inflateStringProperty(containingRowIB, desc.title, true);
        const dataPoints = getArrayProperty<DataPlotDataPointDescription>(desc.points);
        if (dataPoints === undefined || dataPoints.length === 0) {
            return undefined;
        }

        const dataPointRowValueGetters = dataPoints.map(p => {
            return inflateNumberProperty(ib, p.value);
        });

        const undefinedColorGetter = () => undefined;
        const dataPointColorValueGetters = dataPoints.map(p => {
            const colorMode = getEnumProperty<DataPlotColorMode>(p.colorMode);
            if (colorMode === DataPlotColorMode.Auto) {
                return [undefinedColorGetter];
            }
            return inflateStringProperty(ib, p.color, true);
        });

        const columnsToDisplay = mapFilterUndefined(dataPoints, p => {
            const dataKey = getColumnProperty(p.value);

            const dataKeyColumn = definedMap(getSourceColumnProperty(p.value), sc =>
                getTableAndColumnForSourceColumn(ib.adc, sc, ib.tables.input, undefined)
            )?.column;
            const colorMode = getEnumProperty<DataPlotColorMode>(p.colorMode);
            const color = getStringProperty(p.color);
            if (dataKey === undefined || dataKeyColumn === undefined) {
                return undefined;
            }
            const label = getTableColumnDisplayName(dataKeyColumn);
            return {
                label,
                dataKey,
                columnType: dataKeyColumn.type,
                color: isEmpty(color) || colorMode === DataPlotColorMode.Auto ? undefined : color,
                type: getEnumProperty<DataPlotType>(p.displayType) ?? DataPlotType.Bar,
            };
        });

        const [labelGetter] = inflateStringProperty(ib, desc.label, true);
        const labelAxisColumnName = getColumnProperty(desc.label);
        const labelAxisColumn = maybeGetTableColumn(ib.tables.input, labelAxisColumnName);
        const chartSize = getEnumProperty<DataPlotChartSize>(desc.chartSize) ?? DataPlotChartSize.Medium;
        const colorScheme = getEnumProperty<ChartingColorScheme>(desc.colorScheme) ?? ChartingColorScheme.Default;
        const gradientColorProperties = (getArrayProperty<{ color?: string }>(desc.gradientColors) ?? []).map(g =>
            getStringProperty(g.color)
        );

        const gradientColors = mapFilterUndefined(gradientColorProperties, c => (!isEmpty(c) ? c : undefined));
        const gradientMapping =
            dataPoints.length === 1
                ? getEnumProperty<ChartingGradientMapping>(desc.gradientMapping)
                : ChartingGradientMapping.Index;

        const componentEnricher = inflateComponentEnricher<WireDataPlotComponent>(
            ib,
            desc as unknown as InlineListComponentDescription
        );

        const enableAggregation = getSwitchProperty(desc.enableAggregation) ?? false;
        const gbtComputedColumnsAlpha = isExperimentEnabled("gbtComputedColumnsAlpha", ib.adc.userFeatures);
        const gbtDeepLookups = isExperimentEnabled("gbtDeepLookups", ib.adc.userFeatures);

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);

        function makeComponent(containingRowHB: WireRowHydrationValueProvider, data: DataPlotRowData[]) {
            const graphTitle = componentTitleGetter(containingRowHB);
            const component = componentEnricher({
                kind: WireComponentKind.List,
                format: ArrayScreenFormat.DataPlot,
                data,
                columnsToDisplay,
                graphTitle,
                caption: captionGetter(containingRowHB) ?? "",
                showYAxisLabels: getSwitchProperty(desc.showYAxisLabels),
                showLegend: getSwitchProperty(desc.showLegend),
                chartSize,
                colorScheme,
                gradientColors,
                gradientMapping,
            });

            return { component, isValid: true };
        }

        function makeChartDataFromRow(rhb: WireRowComponentHydrationBackend, row: Row): DataPlotRowData | undefined {
            if (dataPoints === undefined || dataPoints.length === 0) {
                return undefined;
            }

            const labelName = labelGetter(rhb);
            if (!isBound(labelName)) {
                return undefined;
            }

            const chartRowData: DataPlotRowData = {
                name: labelName,
                points: {},
            };

            // if the row has a group property, we need to decompose it to get the grouped labels.
            // fortunately, the values we expect get set at correct data key via withGroupBy
            if (row.hasOwnProperty("group")) {
                const { group } = decomposeAggregateRow(row);
                const stringGroup = asMaybeString(group);
                if (stringGroup !== undefined) {
                    chartRowData.name = stringGroup;
                }
            }

            dataPoints.forEach((_, index) => {
                const [valueGetter] = dataPointRowValueGetters[index];
                const [colorGetter] = dataPointColorValueGetters[index];
                const value = valueGetter(rhb);
                const color = colorGetter(rhb);
                const maybeNumericValue = asMaybeNumber(value);
                const dataKey = columnsToDisplay[index]?.dataKey;
                if (value === undefined || maybeNumericValue === undefined || dataKey === undefined) {
                    return;
                }
                chartRowData.points[dataKey] = {
                    value: maybeNumericValue,
                    color: isBound(color) ? color : undefined,
                };
            });
            return chartRowData;
        }

        return makeSimpleWireTableComponentHydratorConstructor(
            ib,

            () => {
                // noop 🎉 see withOnlyQueries in fluent spec.
                return undefined;
            },
            (rhb, query) => {
                if (labelAxisColumn === undefined) {
                    return undefined;
                }

                if (enableAggregation) {
                    query = query.withGroupBy({
                        columns: [labelAxisColumn.name],
                        aggregates: mapFilterUndefined(dataPoints, (dataPoint, i) => {
                            const columnName = getColumnProperty(dataPoint.value);
                            const column = maybeGetTableColumn(ib.tables.input, columnName);
                            const columnType = columnsToDisplay[i].columnType;
                            const rollupKind =
                                columnType.kind === "number"
                                    ? getEnumProperty(dataPoint.rollupKind)
                                    : RollupKind.CountNonEmpty;
                            if (column === undefined || columnName === undefined || rollupKind === undefined) {
                                return undefined;
                            }
                            const columnIsQueryable = isQueryableColumn(
                                ib.adc,
                                ib.tables.input,
                                column,
                                gbtComputedColumnsAlpha,
                                gbtDeepLookups
                            );

                            if (!columnIsQueryable) {
                                return undefined;
                            }

                            return {
                                column: columnName,
                                kind: rollupKind as RollupKind,
                                name: columnName,
                            };
                        }),
                        sort: [],
                        limit: 1000,
                    });
                }

                const table = rhb.resolveQueryAsTable(query);

                if (table === undefined || isLoadingValue(table)) {
                    return undefined;
                }

                const rows = table.asMutatingArray();

                const chartData: DataPlotRowData[] = [];
                for (const row of rows) {
                    const queryableRhb = rhb?.makeHydrationBackendForRow(row, undefined, ib.tables);
                    if (queryableRhb === undefined) {
                        continue;
                    }
                    const chartDataRow = makeChartDataFromRow(queryableRhb, row);
                    if (chartDataRow !== undefined) {
                        chartData.push(chartDataRow);
                    }
                }

                return makeComponent(rhb, chartData);
            }
        );
    }
);
