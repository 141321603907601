import type { History } from "history";
import type { AnyAction, Store } from "redux";
import type { ThunkAction } from "redux-thunk";
import type { RootState } from "../store/types";

export type GlideThunk<T = void> = ThunkAction<T, RootState, History<any>, AnyAction>;
export type GlideAsyncThunk<T = void> = ThunkAction<Promise<T>, RootState, History<any>, AnyAction>;

export type MappedThunk<T> = T extends (...args: infer U) => ThunkAction<infer R, any, any, any>
    ? (...args: U) => R
    : never;

export function dispatchThunk<T>(
    store: Store<RootState>,
    thunk: ThunkAction<T, RootState, History<any>, AnyAction>
): T {
    return store.dispatch(thunk as unknown as AnyAction) as unknown as T;
}
