import { GlideIcon } from "@glide/common";
import classNames from "classnames";
import { useMemo } from "react";
import "twin.macro";

interface Props {
    readonly variant: "info" | "warning" | "error" | "bill";
    readonly children: React.ReactNode;
    className?: string;
}

const ICON_SIZE = 16;

export const Banner: React.FC<Props> = ({ variant, children, className }) => {
    const icon = useMemo(() => {
        switch (variant) {
            case "info":
                return (
                    <GlideIcon kind="stroke" icon="st-info-circle" iconSize={ICON_SIZE} tw="shrink-0 text-icon-base" />
                );
            case "warning":
                return (
                    <GlideIcon
                        kind="stroke"
                        icon="st-alert-warning"
                        iconSize={ICON_SIZE}
                        tw="shrink-0 text-text-warning"
                    />
                );
            case "error":
                return (
                    <GlideIcon
                        kind="stroke"
                        icon="st-alert-warning"
                        iconSize={ICON_SIZE}
                        tw="shrink-0 text-text-danger"
                    />
                );
            case "bill":
                return (
                    <GlideIcon kind="stroke" icon="st-action-bolt" iconSize={ICON_SIZE} tw="shrink-0 text-aqua500" />
                );
        }
    }, [variant]);
    return (
        <div
            className={classNames(variant, className)}
            tw="p-2 rounded-lg text-builder-sm [line-height:133%] flex items-start gap-1.5 text-text-base
            [&.bill]:(bg-aqua100)
            [&.info]:(border border-border-base)
            [&.warning]:(bg-y100)
            [&.error]:(bg-r100)
        ">
            {icon}
            <div>{children}</div>
        </div>
    );
};
