let savedFirestoreEmulatorHost = "";
let savedFirebaseAuthEmulatorHost = "";

export function setEmulatorHosts(firestoreEmulatorHost: string, firebaseAuthEmulatorHost: string) {
    savedFirestoreEmulatorHost = firestoreEmulatorHost;
    savedFirebaseAuthEmulatorHost = firebaseAuthEmulatorHost;
}

// Expect something like ["localhost", 8080]
export function getFirestoreEmulatorHost(): [string, number] | undefined {
    const emulatorHost = process.env.FIRESTORE_EMULATOR_HOST ?? savedFirestoreEmulatorHost;

    if (emulatorHost === "") {
        return undefined;
    }

    const [host, portStr] = emulatorHost.split(":");
    const port = parseInt(portStr);

    if (Number.isNaN(port)) {
        // eslint-disable-next-line no-console
        console.warn(
            `Env variable for FIRESTORE_EMULATOR_HOST doesn't look fine.\nIt should look like 'host:port'\nBut it looks like ${process.env.FIRESTORE_EMULATOR_HOST}`
        );
        return undefined;
    }

    return [host, port];
}

// Expect something like http://localhost:9099
export function getFirebaseAuthEmulatorHost(): string | undefined {
    const emulatorHost = process.env.FIREBASE_AUTH_EMULATOR_HOST ?? savedFirebaseAuthEmulatorHost;

    if (emulatorHost === "") {
        return undefined;
    }

    return emulatorHost;
}

export function isRunningOnEmulators() {
    const firestoreHost = getFirestoreEmulatorHost();
    const authHost = getFirebaseAuthEmulatorHost();

    return firestoreHost !== undefined && authHost !== undefined;
}

export function storageEmulatorURL(): string | undefined {
    return process.env.GLIDE_GCS_EMULATOR_URL;
}
