import { type Currency, currencies, findCurrency } from "@glide/common-core/dist/js/components/buy-button-defaults";
import { getLocalizedString } from "@glide/localization";
import {
    type AppDescription,
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    ScreenDescriptionKind,
    getPaymentMethodProperty,
    defaultPaymentMethod,
} from "@glide/app-description";
import { type LoadedRow, isBaseRowIndex } from "@glide/computation-model-types";
import {
    type Owner,
    type PaymentInformation,
    type PaymentInformationForBuyButtons,
    getStripeIntegration,
} from "@glide/common-core/dist/js/Database";
import {
    rowIndexColumnName,
    type TableColumn,
    type TableGlideType,
    type TypeSchema,
    findTable,
    getTableColumn,
    makeSourceColumn,
    getNonHiddenColumns,
    isComputedColumn,
    isPrimitiveType,
} from "@glide/type-schema";
import { localDataStoreName, shoppingCartTableName } from "@glide/common-core/dist/js/database-strings";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import { Appearance, Mood } from "@glide/component-utils";
import type { WireAppButtonComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type EnumPropertyCase,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    type ColumnFilterSpec,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    ComponentPrerequisite,
    EnumPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
    buyScreenName,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { logError, logInfo } from "@glide/support";
import {
    type WireInflationBackend,
    type WireRowComponentHydratorConstructor,
    type WireAction,
    WireActionResult,
    PageScreenTarget,
    WireComponentKind,
    WireActionOffline,
} from "@glide/wire";
import { proveNever } from "@glideapps/ts-necessities";
import entries from "lodash/entries";
import { v4 as uuid } from "uuid";
import {
    inflateNumberProperty,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerBusyActionRunner,
} from "../wire/utils";
import { makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

const getBackendCompatiblePrimitiveColumnsSpec: ColumnFilterSpec = {
    getCandidateColumns: (table: TableGlideType) => getNonHiddenColumns(table).filter(c => !isComputedColumn(c)),
    columnTypeIsAllowed: isPrimitiveType,
};

const currencyCases: EnumPropertyCase<string>[] = currencies.map(({ code: symbol, name }) => ({
    value: symbol,
    label: `${symbol.toUpperCase()} — ${name}`,
}));

const nameProperty = new ColumnPropertyHandler(
    "nameProperty",
    "Name",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable, ColumnPropertyFlag.Searchable],
    undefined,
    ["name", "title", "product", "item"],
    getBackendCompatiblePrimitiveColumnsSpec,
    "string",
    PropertySection.PaymentProductInfo
);
const descriptionProperty = new ColumnPropertyHandler(
    "descriptionProperty",
    "Description",
    [ColumnPropertyFlag.Editable, ColumnPropertyFlag.Searchable],
    undefined,
    ["description"],
    getPrimitiveColumnsSpec,
    "string",
    PropertySection.PaymentProductInfo
);
const imageProperty = new ColumnPropertyHandler(
    "imageProperty",
    "Image",
    [ColumnPropertyFlag.Editable],
    undefined,
    ["image"],
    getPrimitiveColumnsSpec,
    "image-uri",
    PropertySection.PaymentProductInfo
);
const skuProperty = new ColumnPropertyHandler(
    "skuProperty",
    "Product ID",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable, ColumnPropertyFlag.Searchable],
    undefined,
    ["sku", "id"],
    getBackendCompatiblePrimitiveColumnsSpec,
    "string",
    PropertySection.PaymentProductInfo
);
const priceProperty = new ColumnPropertyHandler(
    "priceProperty",
    "Price",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable],
    undefined,
    ["price", "cost"],
    getBackendCompatiblePrimitiveColumnsSpec,
    "number",
    PropertySection.PaymentProductInfo
);
const currencyHandler = new EnumPropertyHandler(
    {
        currency: "usd",
    },
    "Currency",
    "Currency",
    currencyCases,
    PropertySection.Options,
    "dropdown"
);
const shoppingCartHandler = new SwitchPropertyHandler(
    { addToShoppingCart: false },
    "Add to shopping cart",
    PropertySection.Options
);
const withShippingHandler = new SwitchPropertyHandler(
    { withShipping: true },
    "Require shipping address",
    PropertySection.Options
);
const showStripeLogoHandler = new SwitchPropertyHandler(
    { showStripeLogo: false },
    "Display Stripe logo below button",
    PropertySection.Options
);

export const ComponentKindBuyButton: ComponentKind = "buy-button";

interface BuyButtonComponentDescription extends ComponentDescription {
    readonly paymentMethod: PropertyDescription | undefined;
    readonly nameProperty: PropertyDescription;
    readonly descriptionProperty: PropertyDescription | undefined;
    readonly imageProperty: PropertyDescription | undefined;
    readonly skuProperty: PropertyDescription;
    readonly priceProperty: PropertyDescription;
    readonly currency: PropertyDescription | undefined;
    readonly addToShoppingCart: PropertyDescription | undefined;
    readonly withShipping: PropertyDescription;
    readonly caption: PropertyDescription | undefined;
    // FIXME: Why is this not returned by `getComponentID`?
    readonly id: string;
}

export class BuyButtonComponentHandler extends ComponentHandlerBase<BuyButtonComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindBuyButton);
    }

    public getDescriptor(
        desc: BuyButtonComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const baseDescriptor: ComponentDescriptor = {
            name: "Buy Button",
            description: "Sell goods or services",
            img: "co-buy-button",
            group: "Buttons",
            helpUrl: getDocURL("buyButton"),
            isLegacy: !ccc.eminenceFlags.buyButton,
            properties: [
                {
                    kind: PropertyKind.PaymentMethod,
                    property: { name: "paymentMethod" },
                    label: "Payment method",
                    section: PropertySection.PaymentProcessor,
                },
                ...this.getBasePropertyDescriptors(),
            ],
        };

        // If the payment method is not defined, we need to have the user set it before going forward.
        if (desc === undefined || (desc.paymentMethod === undefined && !getFeatureSetting("buyButtonWithoutStripe"))) {
            return baseDescriptor;
        }

        const properties: PropertyDescriptor[] = [
            ...baseDescriptor.properties,
            nameProperty,
            descriptionProperty,
            imageProperty,
            skuProperty,
            priceProperty,
            currencyHandler,
            shoppingCartHandler,
            withShippingHandler,
            showStripeLogoHandler,
            makeCaptionStringPropertyDescriptor("Buy", false, mutatingScreenKind, {
                propertySection: PropertySection.Top,
                placeholder: "ex: Buy Now",
            }),
        ];

        return { ...baseDescriptor, properties };
    }

    public newComponent(
        tables: InputOutputTables,
        usedColumns: ReadonlySet<TableColumn>,
        editedColumns: ReadonlySet<TableColumn>,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): BuyButtonComponentDescription | undefined {
        const desc = super.newComponent(tables, usedColumns, editedColumns, iccc, mutatingScreenKind);
        if (desc === undefined) return undefined;

        return {
            ...desc,
            id: uuid(),
        };
    }

    protected duplicateComponentBase(desc: BuyButtonComponentDescription): BuyButtonComponentDescription {
        return { ...super.duplicateComponentBase(desc), id: uuid() };
    }

    public get prerequisites(): readonly ComponentPrerequisite[] {
        if (getFeatureSetting("buyButtonWithoutStripe")) return [];
        return [ComponentPrerequisite.StripeConnect];
    }

    public inflate(
        ib: WireInflationBackend,
        desc: BuyButtonComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { appKind } = ib.adc;

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);
        const [nameGetter, nameType] = inflateStringProperty(ib, desc.nameProperty, true);
        const [descriptionGetter] = inflateStringProperty(ib, desc.descriptionProperty, true);
        const [imageGetter] = inflateStringProperty(ib, desc.imageProperty, true);
        const [skuGetter] = inflateStringProperty(ib, desc.skuProperty, true);
        const [priceGetter, priceType] = inflateNumberProperty(ib, desc.priceProperty);
        const [rowIndexGetter, rowIndexType] = ib.getValueGetterForSourceColumn(
            makeSourceColumn(rowIndexColumnName),
            false,
            false
        );

        if (nameType === undefined || priceType === undefined || rowIndexType === undefined) {
            return undefined;
        }

        const addToCart = shoppingCartHandler.getSwitch(desc);
        const withShipping = withShippingHandler.getSwitch(desc);
        const showStripeLogo = showStripeLogoHandler.getSwitch(desc);
        const currencyCode = currencyHandler.getEnum(desc);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const nameValue = nameGetter(hb) ?? "";
            const descriptionValue = descriptionGetter(hb) ?? undefined;
            const imageValue = imageGetter(hb) ?? undefined;
            const skuValue = skuGetter(hb) ?? "";
            const priceValue = priceGetter(hb) ?? -1;
            const rowIndex = rowIndexGetter(hb);

            if (nameValue === "" || skuValue === "" || priceValue < 0) return undefined;
            if (!isBaseRowIndex(rowIndex)) return undefined;

            let title = captionGetter(hb) ?? "";
            if (title === "") {
                title = getLocalizedString(addToCart ? "addToCart" : "buy", appKind);
            }

            let onTap: WireAction | undefined;
            if (addToCart || hb.getIsOnline()) {
                const row: LoadedRow = {
                    $rowID: makeRowID(),
                    $isVisible: false,
                    itemName: nameValue,
                    itemDescription: descriptionValue,
                    itemImage: imageValue,
                    itemSKU: skuValue,
                    itemPrice: priceValue,
                    currencyCode,
                    withShipping,
                    showStripeLogo,
                    buttonID: desc.id,
                    rowIndex,
                };

                onTap = registerBusyActionRunner(hb, "", () => async ab => {
                    if (addToCart) {
                        const addedRow = await ab.addRow(shoppingCartTableName, row, localDataStoreName, true);
                        if (!addedRow.ok) {
                            ab.actionCallbacks.showToast(false, getLocalizedString("error", appKind));
                            return WireActionResult.fromResult(addedRow);
                        }
                        ab.actionCallbacks.showToast(true, getLocalizedString("added", appKind));
                    } else {
                        ab.addSpecialScreenRow(row);
                        ab.pushFreeScreen(buyScreenName, [row], "", PageScreenTarget.Current, undefined);
                    }
                    return WireActionResult.nondescriptSuccess();
                });
                if (onTap === undefined) return undefined;
            } else {
                onTap = {
                    token: WireActionOffline,
                };
            }

            const component: WireAppButtonComponent = {
                kind: WireComponentKind.AppButton,
                title,
                mood: Mood.Default,
                style: Appearance.Filled,
                onTap,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: BuyButtonComponentDescription,
        ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return this.defaultConvertToPage(desc, ccc);
    }
}

export interface BuyButtonColumns {
    readonly nameColumn: TableColumn;
    readonly skuColumn: TableColumn;
    readonly priceColumn: TableColumn;
    readonly currency: Currency;
}

export function getColumns(desc: BuyButtonComponentDescription, table: TableGlideType): BuyButtonColumns | undefined {
    const nameColumnName = nameProperty.getColumnName(desc);
    const skuColumnName = skuProperty.getColumnName(desc);
    const priceColumnName = priceProperty.getColumnName(desc);

    if (nameColumnName === undefined || skuColumnName === undefined || priceColumnName === undefined) return undefined;

    const nameColumn = getTableColumn(table, nameColumnName);
    const skuColumn = getTableColumn(table, skuColumnName);
    const priceColumn = getTableColumn(table, priceColumnName);

    if (nameColumn === undefined || skuColumn === undefined || priceColumn === undefined) return undefined;

    const currency = findCurrency(currencyHandler.getEnum(desc));
    if (currency === undefined) return undefined;

    return { nameColumn, skuColumn, priceColumn, currency };
}

interface BuyButtonInfo {
    readonly buyButton: BuyButtonComponentDescription;
    readonly table: TableGlideType;
}

export function getBuyButtons(appDescription: AppDescription, schema: TypeSchema): readonly BuyButtonInfo[] {
    const buyButtons: BuyButtonInfo[] = [];

    for (const [screenName, screenDesc] of entries(appDescription.screenDescriptions)) {
        if (screenDesc.kind !== ScreenDescriptionKind.Class) continue;

        for (const desc of screenDesc.components) {
            if (desc.kind !== ComponentKindBuyButton) continue;

            const buyButton = desc as BuyButtonComponentDescription;

            const table = findTable(schema, screenDesc.type);
            if (table === undefined) {
                logError("Could not find table for buy button", screenName, screenDesc.type);
                continue;
            }

            buyButtons.push({ buyButton, table });
        }
    }

    logInfo("Number of buy buttons", buyButtons.length);

    return buyButtons;
}

export function getPaymentInformationForBuyButtons(
    appDescription: AppDescription,
    schema: TypeSchema,
    owner: Owner,
    withTestMode: boolean
): PaymentInformationForBuyButtons {
    const integration = getStripeIntegration(owner);
    if (integration === undefined) {
        logInfo("No Stripe integration");
        return {};
    }

    const infos: { [buyButtonID: string]: PaymentInformation } = {};
    const { stripeUserID, livePublishableKey, testPublishableKey } = integration;
    for (const { buyButton } of getBuyButtons(appDescription, schema)) {
        const paymentMethod = getPaymentMethodProperty(buyButton.paymentMethod) ?? defaultPaymentMethod;
        let { processor } = paymentMethod;
        if (processor !== "stripe") {
            processor = proveNever(processor, "Invalid processor on buy button payment method", "stripe");
        }

        infos[buyButton.id] = withTestMode
            ? { processor: "stripe", stripeUserID, livePublishableKey, testPublishableKey }
            : { processor: "stripe", stripeUserID };
    }

    return infos;
}
