import { mapFilterUndefined } from "@glideapps/ts-necessities";
import { geocodeAddress } from "@glide/common-core/dist/js/components/geocode";
import type { MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import type { LatLng } from "@glide/common-core/dist/js/Database";
import zip from "lodash/zip";
import React from "react";

export interface MapMarker<T> {
    title: string;
    lat: number;
    lng: number;
    itemID: string;
    handle: T;
}

interface MapItem {
    readonly title?: string | null;
    readonly key?: string;
    readonly location: string;
}

export function useGeocodeMapMarkers<T extends MapItem>(
    appEnv: MinimalAppEnvironment | undefined,
    items: readonly T[] | undefined,
    quotaKey: string | undefined
): readonly MapMarker<T>[] | undefined {
    const [mapMarkers, setMapMarkers] = React.useState<readonly MapMarker<T>[] | undefined>(undefined);

    const quotaKeyRef = React.useRef(quotaKey);
    quotaKeyRef.current = quotaKey;

    React.useEffect(() => {
        let cancelled = false;

        const fn = async () => {
            function makeMarker(item: T, latlng: LatLng): MapMarker<T> {
                return {
                    ...latlng,
                    handle: item,
                    itemID: item.key ?? item.location,
                    title: item.title ?? "",
                };
            }

            if (appEnv === undefined) {
                return;
            }

            if (items === undefined) {
                setMapMarkers([]);
                return;
            }

            const promises = items.map(async i => {
                const geoc = await geocodeAddress(appEnv, i.location, quotaKeyRef.current);
                return geoc;
            });
            const results = await Promise.all(promises);

            if (cancelled) return;

            const markers = mapFilterUndefined(zip(items, results), ([i, l]) =>
                i !== undefined && l !== undefined ? makeMarker(i, l) : undefined
            );

            setMapMarkers(markers);
        };
        void fn();

        return () => {
            cancelled = true;
        };
    }, [appEnv, items]);

    return mapMarkers;
}
