import { iterableFirst } from "collection-utils";
import type { PrimitiveGlideTypeKind } from "./description";

// FIXME: Be smarter with unifying here.  For example, `date` and `date-time` can be
// unified.
const typeLineage: Record<PrimitiveGlideTypeKind, readonly PrimitiveGlideTypeKind[]> = {
    string: ["string"],
    boolean: ["string", "boolean"],
    number: ["string", "number"],
    duration: ["string", "number", "duration"],
    uri: ["string", "uri"],
    "image-uri": ["string", "uri", "image-uri"],
    "audio-uri": ["string", "uri", "audio-uri"],
    date: ["string", "date"],
    "date-time": ["string", "date-time"],
    time: ["string", "time"],
    markdown: ["string", "markdown"],
    "phone-number": ["string", "phone-number"],
    "email-address": ["string", "email-address"],
    emoji: ["string", "emoji"],
    json: ["string", "json"],
};

function unifyTwoTypeKinds(left: PrimitiveGlideTypeKind, right: PrimitiveGlideTypeKind): PrimitiveGlideTypeKind {
    if (left === right) return left;

    const leftPath = typeLineage[left];
    const rightPath = typeLineage[right];

    let offset = 0;
    for (let i = 0; i < leftPath.length && i < rightPath.length; i++) {
        if (leftPath[i] !== rightPath[i]) break;
        offset = i;
    }

    return leftPath[offset];
}

export function unifyTypeKinds(kinds: ReadonlySet<PrimitiveGlideTypeKind>): PrimitiveGlideTypeKind {
    if (kinds.size < 2) {
        return iterableFirst(kinds) ?? "string";
    }

    return Array.from(kinds).reduce(unifyTwoTypeKinds);
}
