export enum TextSize {
    Small = "small",
    Medium = "medium",
    Large = "large",
}

export enum TextStyle {
    Normal = "normal",
    AllCaps = "all-caps",
}
