import "twin.macro";

import type { GlideFC } from "@glide/common";
import type { ReactNode } from "react";

export const PopularPill: GlideFC = () => (
    <span
        tw="bg-aqua100 text-aqua400 px-2 py-1 font-semibold text-xs rounded-full
            relative top[-1px]">
        Popular
    </span>
);

type AccentPillProps = {
    children: ReactNode;
    className?: string;
};

export const AccentPill: React.FC<React.PropsWithChildren<AccentPillProps>> = p => {
    const { children, className } = p;
    return (
        <span
            tw="bg-aqua100 text-aqua400 px-2 font-semibold text-xs leading-4 rounded-full relative whitespace-nowrap"
            className={className}>
            {children}
        </span>
    );
};

type GreyPillProps = {
    children: ReactNode;
    className?: string;
};

export const GreyPill: React.VFC<GreyPillProps> = p => {
    const { children, className } = p;
    return (
        <div
            tw="inline-flex items-center text-builder-xs rounded-full py-0.5 px-2 cursor-pointer
                bg-n200A text-text-base whitespace-nowrap"
            className={className}>
            {children}
        </div>
    );
};
