import { styled } from "@glide/common";

import { disabledProps } from "@glide/common-components";
import { definedMap } from "@glideapps/ts-necessities";
import { css } from "styled-components";
import type { SpaceProps } from "styled-system";
import { space } from "styled-system";
import { systemFontFamily } from "@glide/base-theme";

export type ButtonStyleProps = {
    variant?: "primary" | "outline" | "tertiary" | "cancel" | "destroy" | "plain" | "filled" | "inline";
    fullSize?: boolean;
    disabled?: boolean;
    width?: number;
    fullRounding?: boolean;
    ty?: number;
    inline?: boolean;
    tallerVariation?: boolean;
} & SpaceProps;

const variants = {
    primary: css`
        background-color: ${p => p.theme.n200A};

        &:disabled,
        &[disabled] {
            opacity: 0.5;
        }
    `,
    filled: css`
        background: ${p => p.theme.acceptColor};
        color: ${p => p.theme.button.fgAccept};

        &:disabled,
        &[disabled] {
            opacity: 0.5;
        }
    `,
    outline: css`
        background-color: ${p => p.theme.buttonOutline.bgColor};
        box-shadow: ${p => p.theme.buttonOutline.shadow};
        color: ${p => p.theme.textDark};
        transition: all ${p => p.theme.transitionBase};

        &:hover,
        &:focus {
            background-color: ${p => p.theme.buttonOutline.bgHover};
            box-shadow: ${p => p.theme.buttonOutline.shadowHover};
        }
    `,
    tertiary: css`
        background-color: ${p => p.theme.n200A};
        color: ${p => p.theme.textBase};

        &:hover,
        &:focus {
            background-color: ${p => p.theme.n300A};
        }
    `,
    cancel: css`
        background-color: ${p => p.theme.n200A};
        color: ${p => p.theme.fgColorDark};
    `,
    destroy: css`
        background: ${p => p.theme.destroyColor}1E;
        color: ${p => p.theme.destroyColor};
    `,
    plain: css`
        background: transparent;
        color: ${p => p.theme.fgColorMedium};
    `,
    inline: css`
        background: transparent;
        min-width: unset;
        height: unset;
        font-size: unset;
        padding: unset;
    `,
};

/**
 * @deprecated Use button instead
 */
export const ButtonLegacyStyle = styled.button<ButtonStyleProps>`
    font-family: ${systemFontFamily};
    font-weight: 500;
    font-size: 13px;
    padding: 0 12px;
    text-align: center;
    color: ${p => p.theme.acceptColor};
    transition: all ${p => p.theme.transitionBase};

    ${p => p.disabled && disabledProps}

    height: ${p => (p.tallerVariation ? "40px" : "32px")};
    min-width: ${props => props.width ?? 98}px;
    ${props => props.fullSize === true && "width: 100%;"}
    ${props => definedMap(props.ty, ty => `transform: translateY(${ty}px);`) ?? ""}
    border-radius: ${p => definedMap(p.fullRounding, x => (x ? "16px" : "8px")) ?? "8px"};

    display: ${p => (p.inline ? "inline-flex" : "flex")};
    align-items: center;
    justify-content: center;

    ${props => variants[props.variant ?? "primary"]};
    ${space};

    &:hover {
        cursor: pointer;
        ${p =>
            p.fullRounding &&
            css`
                background-color: ${p.theme.button.bgHover};
            `};
    }
`;
