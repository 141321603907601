import {
    isFavoritedColumnName,
    type TableColumn,
    type TableGlideType,
    BinaryPredicateFormulaOperator,
    isMultiRelationType,
    isPrimitiveArrayType,
    isPrimitiveType,
    isSingleRelationType,
    makeTableRef,
} from "@glide/type-schema";
import {
    type ArrayContentDescription,
    type ArrayFilter,
    type ArrayPivot,
    type ArrayScreenDescription,
    type ArrayScreenItemType,
    type ClassOrArrayScreenDescription,
    type ClassScreenDescription,
    type ComponentDescription,
    ActionKind,
    ArrayScreenFormat,
    PropertyKind,
    ScreenDescriptionKind,
    makeColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import {
    type TitleDescription,
    getScreenComponentsArray,
    makeInputOutputTables,
} from "@glide/common-core/dist/js/description";
import type { AppDescriptionContext } from "@glide/function-utils";
import { assert, definedMap } from "@glideapps/ts-necessities";

import type { ArrayScreenHandler } from "./array-screens";
import type { ListArrayScreenDescription } from "./array-screens/list-array-screen";
import { defaultAudioComponent } from "./components/audio-player";
import { EmailComponentHandler } from "./components/email";
import { ImageComponentHandler } from "./components/image";
import { InlineListComponentHandler } from "./components/inline-list";
import { LinkComponentHandler } from "./components/link";
import { ListReferenceComponentHandler } from "./components/list-reference";
import { defaultMarkdownComponent } from "./components/markdown";
import { PhoneNumberComponentHandler } from "./components/phone-number";
import { PrimitiveComponentHandler } from "./components/primitive";
import { ReferenceComponentHandler } from "./components/reference";
import { TitleComponentHandler } from "./components/title";
import { propertiesInSummary, summaryColumnsForTable } from "./description-utils";

export function componentDescriptionForColumn(
    ccc: AppDescriptionContext,
    table: TableGlideType,
    column: TableColumn
): ComponentDescription | undefined {
    // FIXME: Component handlers should report which column types they're
    // responsible for.
    switch (column.type.kind) {
        case "markdown":
            return defaultMarkdownComponent(column);
        case "image-uri":
            return ImageComponentHandler.defaultComponent(column);
        case "audio-uri":
            return defaultAudioComponent(column);
        case "uri":
            return LinkComponentHandler.defaultComponent(column);
        case "table-ref":
            return ReferenceComponentHandler.defaultComponent(ccc, column);
        case "array":
            if (isPrimitiveType(column.type.items)) {
                return ListReferenceComponentHandler.defaultComponent(ccc, column);
            } else {
                return InlineListComponentHandler.defaultComponent(column, ccc, makeInputOutputTables(table));
            }
        case "email-address":
            return EmailComponentHandler.defaultComponent(column);
        case "phone-number":
            return PhoneNumberComponentHandler.defaultComponent(column);
    }
    assert(isPrimitiveType(column.type));
    return PrimitiveComponentHandler.defaultComponent(column);
}

// We won't make more components than this, plus one inline list.
const maxSimpleComponentsInScreen = 7;

function componentDescriptionsForColumns(
    ccc: AppDescriptionContext,
    table: TableGlideType,
    summary: TitleDescription | undefined,
    columns: readonly TableColumn[]
): ComponentDescription[] {
    const ignore = definedMap(summary, s => new Set(propertiesInSummary(s))) ?? new Set();

    const components: ComponentDescription[] =
        definedMap(summary, s => [TitleComponentHandler.defaultComponent(s)]) ?? [];
    const arrayComponents: ComponentDescription[] = [];
    for (const column of columns) {
        if (column.hidden === true) continue;
        if (ignore.has(column.name)) continue;
        // We will already make components for the individual columns of
        // arrays of primitives, so making a list for the array is unnecessary.
        if (isPrimitiveArrayType(column.type)) continue;

        const desc = componentDescriptionForColumn(ccc, table, column);
        if (desc === undefined) continue;

        if (column.type.kind === "array") {
            arrayComponents.push(desc);
        } else {
            components.push(desc);
        }

        if (components.length + arrayComponents.length >= maxSimpleComponentsInScreen) break;
    }

    return [...components, ...arrayComponents];
}

export function makeComponentsForTable(
    ccc: AppDescriptionContext,
    table: TableGlideType
): readonly ComponentDescription[] {
    const summary = summaryColumnsForTable(table, false, false);
    const summaryProperties = definedMap(summary, propertiesInSummary) ?? [];
    const arrayProperties = Array.from(
        table.columns.filter(cp => isMultiRelationType(cp.type) && summaryProperties.indexOf(cp.name) < 0)
    );
    const inlineArrayProperty: TableColumn | undefined = arrayProperties[0];

    const components = componentDescriptionsForColumns(
        ccc,
        table,
        summary,
        table.columns.filter(
            c =>
                c.hidden !== true &&
                c.isProtected !== true &&
                c !== inlineArrayProperty &&
                c.name !== isFavoritedColumnName &&
                c.name !== table.rowIDColumn
        )
    );

    if (inlineArrayProperty !== undefined) {
        const inlineList = InlineListComponentHandler.defaultComponent(
            inlineArrayProperty,
            ccc,
            makeInputOutputTables(table)
        );
        if (inlineList !== undefined) {
            components.push(inlineList);
        }
    }

    return components;
}

// This is how we make ##favoritesPivots.
export function makeArrayScreenFavoritesPivots(withPivot: boolean | string): readonly ArrayPivot[] | undefined {
    if (withPivot === false) return undefined;

    const filter: ArrayFilter = {
        key: { kind: PropertyKind.Constant, value: true },
        operator: BinaryPredicateFormulaOperator.Equals,
        value: makeColumnProperty(isFavoritedColumnName),
    };
    return [
        { titleLocalizedStringKey: "all", title: undefined },
        typeof withPivot === "string"
            ? {
                  titleLocalizedStringKey: undefined,
                  title: withPivot,
                  filter,
              }
            : {
                  titleLocalizedStringKey: "favorites",
                  title: undefined,
                  filter,
              },
    ];
}

export function makeArrayScreenDescription(
    handler: ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>,
    itemType: ArrayScreenItemType,
    ccc: AppDescriptionContext,
    fetchesData: boolean,
    forNewApp: boolean,
    existingScreen?: ClassOrArrayScreenDescription
): ArrayScreenDescription {
    const withPivot = existingScreen?.kind === ScreenDescriptionKind.Array && existingScreen.pivots !== undefined;
    const pivots = makeArrayScreenFavoritesPivots(withPivot);
    const components = definedMap(existingScreen, getScreenComponentsArray) ?? [];

    if (isSingleRelationType(itemType)) {
        const table = ccc.findTable(itemType);
        if (table !== undefined) {
            const screen = handler.defaultDescription(makeInputOutputTables(table), ccc, components, forNewApp);

            if (screen !== undefined) {
                let transforms = screen?.transforms;
                if (transforms === undefined || transforms.length === 0) {
                    transforms = existingScreen?.transforms ?? [];
                }
                return { ...screen, fetchesData, pivots, transforms };
            }
        }
    }

    // FIXME: This is kinda weird.  Shouldn't the list array screen handler be able
    // to give us this?
    const desc: ListArrayScreenDescription = {
        kind: ScreenDescriptionKind.Array,
        fetchesData,
        type: itemType,
        format: ArrayScreenFormat.List,
        search: makeSwitchProperty(true),
        searchPlaceholder: makeStringProperty(""),
        canAddRow: makeSwitchProperty(forNewApp),
        canAddRowFilters: [],
        reverse: makeSwitchProperty(false),
        groupByColumn: undefined,
        transforms: existingScreen?.transforms,
        pivots,
        actions: [{ kind: ActionKind.PushDetailScreen }],
        components,
        dynamicFilterColumn: undefined,
        dynamicSortColumns: undefined,
    };
    return desc;
}

export function makeClassScreenDescription(
    table: TableGlideType,
    ccc: AppDescriptionContext,
    fetchesData: boolean,
    forNewApp: boolean,
    existingScreen?: ClassOrArrayScreenDescription
): ClassScreenDescription {
    const tableRef = makeTableRef(table);
    let components: readonly ComponentDescription[];
    if (existingScreen?.components !== undefined && existingScreen.components.length > 0) {
        components = existingScreen.components;
    } else {
        components = makeComponentsForTable(ccc, table);
    }

    const screen: ClassScreenDescription = {
        kind: ScreenDescriptionKind.Class,
        type: tableRef,
        canEdit: forNewApp,
        canEditFilters: [],
        canDelete: false,
        canDeleteFilters: [],
        searchPlaceholder: undefined,
        isForm: false,
        fetchesData,
        title: undefined,
        components,
        transforms: existingScreen?.transforms ?? [],
    };
    return screen;
}
