import { styled } from "@glide/common";

export const SplitContainer = styled.div`
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 100%;
    grid-column-gap: 16px;

    grid-template-areas: "left right right";

    align-items: center;

    & > :first-child {
        grid-area: left;
    }

    & > :last-child {
        grid-area: right;
    }
`;

export const TwoByOneContainer = styled.div`
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 100%;
    grid-column-gap: 16px;

    grid-template-areas: "left left right";

    align-items: center;

    & > :first-child {
        grid-area: left;
    }

    & > :last-child {
        grid-area: right;
    }
`;

export const EvenSplitContainer = styled.div`
    display: grid;

    grid-template-columns: 142px minmax(0, 1fr);

    grid-gap: 8px;

    margin: 8px 0;

    align-items: center;

    &&& > *:first-child {
        grid-column: 1;
        margin-left: 0px;
    }

    &&& > *:nth-child(2) {
        grid-column: 2;
        margin-left: 0px;
        overflow: hidden;
        word-break: break-all;
    }

    &&& > * {
        margin: 0;
        grid-column: 1/3;
    }

    &&& > *:last-child:not(:nth-child(2)) {
        margin-bottom: 16px;
    }

    & > *:first-child ${SplitContainer} > :first-child,
    & > *:nth-child(2) ${SplitContainer} > :first-child {
        display: none;
    }

    & > *:first-child ${SplitContainer}, & > *:nth-child(2) ${SplitContainer} {
        display: block;
    }
`;

export const SplitDropdownContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const RemoveIconContainer = styled.div`
    margin-left: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
