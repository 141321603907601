import * as React from "react";
import classNames from "classnames";
import { TitleArea } from "./property-title-style";

interface Props extends React.PropsWithChildren {
    title: string;
    subtitle?: string;
    highlight?: boolean;
    onClick?: () => void;
}

export default class PropertyTitle extends React.PureComponent<Props> {
    public render(): React.ReactNode {
        const { onClick, highlight } = this.props;
        return (
            <TitleArea className={classNames(highlight && "highlight")} onClick={onClick} title={this.props.title}>
                {this.props.title}
            </TitleArea>
        );
    }
}
