import { asMaybeNumber } from "@glide/common-core/dist/js/computation-model/data";
import type {
    ComponentDescription,
    ComponentKind,
    MutatingScreenKind,
    PropertyDescription,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireAppLikeComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import {
    inflateEditablePropertyWithDefault,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import { labelCaptionStringOptions, makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

const ComponentKindLike: ComponentKind = "like";

const columnPropertyHandler = new ColumnPropertyHandler(
    "dataColumnName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
    ],
    undefined,
    ["like", "thumbs"],
    getPrimitiveColumnsSpec,
    "number",
    PropertySection.Data
);

interface LikeComponentDescription extends ComponentDescription {
    readonly caption: PropertyDescription | undefined;
    readonly dataColumnName: PropertyDescription;
}

export class LikeComponentHandler extends ComponentHandlerBase<LikeComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindLike);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public get isNonEmptyValidationStopper(): boolean {
        return true;
    }

    public getDescriptor(
        _desc: LikeComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Like",
            description: "Like or dislike an item",
            img: "thumbsUp",
            group: "Pickers",
            helpUrl: getDocURL("like"),
            properties: [
                columnPropertyHandler,
                ...this.getBasePropertyDescriptors(),
                makeCaptionStringPropertyDescriptor(
                    "Like",
                    false,
                    mutatingScreenKind,
                    labelCaptionStringOptions,
                    "caption"
                ),
            ],
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: LikeComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const { getter: valueGetter, isInContext } = inflateEditablePropertyWithDefault(
            ib,
            "set",
            desc.dataColumnName,
            asMaybeNumber,
            0
        );
        if (valueGetter === undefined) return undefined;

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const value = valueGetter(hb);
            if (value === undefined) return undefined;

            const component: WireAppLikeComponent = {
                kind: WireComponentKind.AppLike,
                ...spreadComponentID(desc.componentID, forBuilder),
                caption: captionGetter(hb) ?? "",
                value,
            };
            return {
                component,
                isValid: true,
                editsInContext: isInContext,
                hasValue: value.value !== 0,
            };
        });
    }

    public convertToPage(desc: LikeComponentDescription, ccc: AppDescriptionContext): ComponentDescription | undefined {
        return this.defaultConvertToPage(desc, ccc);
    }
}
