import { truthify } from "@glide/support";

const version = 5;

function mkey(key: string) {
    return `v${version}.${key}`;
}

export function save<T>(key: string, val: T): boolean {
    try {
        localStorage[mkey(key)] = JSON.stringify(val);
    } catch (e: unknown) {
        return false;
    }
    return true;
}

export function load<T>(key: string, def?: T, onError?: T): T | undefined {
    try {
        const saved = truthify(localStorage[mkey(key)]);
        if (!saved) {
            return def;
        }
        return JSON.parse(localStorage[mkey(key)]) as T;
    } catch (e: unknown) {
        return onError !== undefined ? onError : def;
    }
}

export function unset(key: string): boolean {
    try {
        localStorage.removeItem(mkey(key));
        return true;
    } catch {
        return false;
    }
}
