import { assertNever } from "@glideapps/ts-necessities";
import * as iots from "io-ts";
import type { TableGlideType } from "./description";
import type { NativeTableID, TableName } from "./table-name";
import { makeTableName, nativeTableIDCodec, areTableNamesEqual, tableNameCodec } from "./table-name";

const googleSheetSourceMetadata = iots.intersection([
    iots.type({
        type: iots.literal("Google Sheet"),
        title: iots.string,
        id: iots.string,
    }),
    iots.partial({
        fromSharedDrive: iots.boolean,
    }),
]);
export type GoogleSheetSourceMetadata = iots.TypeOf<typeof googleSheetSourceMetadata>;

// It's ok if this is missing full provenance because it's just used for
// display in the frontend.
const externalSource = iots.union([
    iots.intersection([
        iots.type({
            type: iots.literal("excel-online"),
            url: iots.string,
            workbookID: iots.string,
            userID: iots.string,
        }),
        iots.partial({
            workbookName: iots.string,
        }),
    ]),
    iots.type({
        type: iots.literal("airtable"),
        url: iots.string,
        userID: iots.string,
        baseID: iots.string,
    }),
    iots.intersection([
        // We've removed this from the code, but since we might have existing
        // serialized data with this, we have to keep it in the codec.
        iots.type({
            type: iots.literal("mysql-gcp"),
        }),
        iots.partial({
            instanceID: iots.string,
            url: iots.string,
        }),
    ]),
    iots.type({
        type: iots.literal("bigquery"),
        // FIXME: Save the project ID and dataset ID in this type whenever they are available.
        // This should, at some point, be "always" for the project ID and "when qualified by dataset"
        // for the dataset ID.
    }),
    iots.type({
        type: iots.literal("queryable-plugin"),
        pluginConfigID: iots.string,
    }),
    iots.intersection([
        iots.type({
            type: iots.literal("data-plugin"),
            displayName: iots.string,
            pluginID: iots.string,
            dataSourceID: iots.string,
            tableCollectionID: iots.string,
        }),
        iots.partial({
            url: iots.string,
        }),
    ]),
    iots.type({
        type: iots.literal("unknown"),
    }),
]);
export type ExternalSource = iots.TypeOf<typeof externalSource>;

export const nativeTableQueryType = iots.literal("glide-table-on-big-tables");
export type NativeTableQueryType = iots.TypeOf<typeof nativeTableQueryType>;

export const nativeTableSourceMetadata = iots.intersection([
    iots.type({
        type: iots.literal("Native table"),
        id: nativeTableIDCodec,
        tableName: tableNameCodec,
    }),
    iots.partial({
        externalSource,
        needsQuery: iots.boolean,
        queryType: nativeTableQueryType,
    }),
]);

const externalSourceMetadata = iots.intersection([
    iots.type({
        type: iots.literal("Native table"),
        id: iots.string,
        tableName: tableNameCodec,
        externalSource,
    }),
    iots.partial({
        needsQuery: iots.boolean,
    }),
]);

export type ExternalSourceMetadata = iots.TypeOf<typeof externalSourceMetadata>;

export type NativeTableSourceMetadata = iots.TypeOf<typeof nativeTableSourceMetadata>;

export const sourceMetadataCodec = iots.union([googleSheetSourceMetadata, nativeTableSourceMetadata]);
export type SourceMetadata = iots.TypeOf<typeof sourceMetadataCodec>;

interface QueryableSourceMetadataFlags {
    readonly canGetAllRows: boolean;
    readonly usesStoredQuery: boolean;
    readonly supportsAggregations: boolean;
    readonly supportsUniqueArrayElements: boolean;
    readonly supportsFilteringByArrays: boolean;
    readonly supportsQueryingComputedColumns: boolean;
}

interface SourceMetadataFlags {
    /**
     * Undefined if not a queryable source.
     * Glide Tables that have been migrated to GBT are considered queryable in this context.
     */
    readonly queryable: QueryableSourceMetadataFlags | undefined;

    /**
     * This is `true` if the source cannot be the user profile table.
     * However, this being `false` does not necessarily guarantee that the table can be the user profile.
     */
    readonly cannotBeUserProfile: boolean;
}

const externalSourceTypesThatMirrorIntoGBT: readonly ExternalSource["type"][] = [];

export function isExternalSourceTypeThatMirrorsIntoGBT(ty: ExternalSource["type"]): boolean {
    return externalSourceTypesThatMirrorIntoGBT.includes(ty);
}

/**
 * Returns `true` if the given source metadata is stored in the Glide Big Tables data store.
 *  This will include Glide Big Tables, as well as any Glide Tables that have been migrated to GBT.
 */
export function isGBTDataStoreSourceMetadata(sm: SourceMetadata | undefined): sm is NativeTableSourceMetadata {
    return (
        sm?.type === "Native table" &&
        sm.needsQuery === true &&
        (sm.externalSource === undefined || isExternalSourceTypeThatMirrorsIntoGBT(sm.externalSource.type))
    );
}

/**
 * Returns `true` if the given table is stored in the Glide Big Tables data store.
 *  This will include Glide Big Tables, as well as any Glide Tables that have been migrated to GBT.
 */
export function isTableInGBTDataStore(table: TableGlideType): boolean {
    return isGBTDataStoreSourceMetadata(table.sourceMetadata);
}

export function getSourceMetadataFlags(sm: SourceMetadata | undefined): SourceMetadataFlags {
    let cannotBeUserProfile = false;
    let queryable: QueryableSourceMetadataFlags | undefined;
    if (sm?.type === "Native table" && sm.needsQuery === true) {
        const isGBT = isGBTDataStoreSourceMetadata(sm);

        // External queryable sources cannot be user profile tables.
        cannotBeUserProfile = !isGBT;

        queryable = {
            canGetAllRows: isGBT,

            // GBT is the only queryable data source that does not use stored queries.
            // SQL always uses stored queries, though sometimes they are not user-editable.
            usesStoredQuery: !isGBT,

            // BigQuery is the only queryable data source that doesn't support
            // aggregations.  Back when we built it we didn't have aggegations in
            // queries yet, and we didn't backfill it because it's not important
            // enough.  If it does become important, we should just move BigQuery to
            // the JDBC architecture.
            supportsAggregations: sm.externalSource?.type !== "bigquery",

            // Only GBT supports `unique-array-elements`
            supportsUniqueArrayElements: isGBT,
            supportsFilteringByArrays: isGBT,
            supportsQueryingComputedColumns: isGBT,
        };
    }
    return { queryable, cannotBeUserProfile };
}

// NOTE: This won't check whether the external source is equal, or the sheet
// display name.  It'll only check the basic IDs.
export function areSourceMetadatasEqual(sm1: SourceMetadata, sm2: SourceMetadata): boolean {
    switch (sm1.type) {
        case "Google Sheet":
            if (sm2.type !== "Google Sheet") return false;
            return sm1.id === sm2.id;
        case "Native table":
            if (sm2.type !== "Native table") return false;
            return sm1.id === sm2.id && areTableNamesEqual(sm1.tableName, sm2.tableName);
        default:
            return assertNever(sm1);
    }
}

// Only returns true if we know for sure that the external sources both refer to the same thing, otherwise false.
export function isDefinitelySameExternalSource(es1: ExternalSource, es2: ExternalSource): boolean {
    switch (es1.type) {
        case "airtable":
            return es2.type === "airtable" && es1.baseID === es2.baseID;
        case "excel-online":
            return es2.type === "excel-online" && es1.workbookID === es2.workbookID;
        // FIXME: Check other external source types?
        // default:
        //     assertNever(es1.type);
    }
    return false;
}

// FIXME: replace all these functions with `getSourceMetadataForTable` which
// is currently in `generator`.
export function getGoogleSheetsSourceMetadata(
    sourceMetadata: readonly SourceMetadata[]
): GoogleSheetSourceMetadata | undefined {
    for (const sm of sourceMetadata) {
        if (sm.type === "Google Sheet") {
            return sm;
        }
    }
    return undefined;
}

export function getNativeTableSourceMetadata(
    sourceMetadata: readonly SourceMetadata[],
    tableName: TableName
): NativeTableSourceMetadata | undefined {
    return sourceMetadata.find(sm => sm.type === "Native table" && areTableNamesEqual(sm.tableName, tableName)) as
        | NativeTableSourceMetadata
        | undefined;
}

export function getSourceMetadataForNativeTable(
    sourceMetadata: readonly SourceMetadata[],
    nativeTableID: NativeTableID
): NativeTableSourceMetadata | undefined {
    for (const sm of sourceMetadata) {
        if (sm.type === "Native table" && sm.id === nativeTableID) {
            return sm;
        }
    }
    return undefined;
}

export function getNativeTableSourceMetadatas(sourceMetadata: readonly SourceMetadata[]): NativeTableSourceMetadata[] {
    return sourceMetadata.filter(sm => sm.type === "Native table") as NativeTableSourceMetadata[];
}

export function isQueryableExternalSourceMetadata(
    sm: SourceMetadata | undefined
): sm is NativeTableSourceMetadata & { needsQuery: true; externalSource: ExternalSource } {
    return sm?.type === "Native table" && sm.needsQuery === true && sm.externalSource !== undefined;
}

export function isQueryableExternalTable(table: TableGlideType): boolean {
    return isQueryableExternalSourceMetadata(table.sourceMetadata);
}

export function isBigTableOrExternal(table: TableGlideType): boolean {
    return isBigTableOrQueryableExternalSourceMetadata(table.sourceMetadata);
}

export function isBigTableOrQueryableExternalSourceMetadata(sm?: NativeTableSourceMetadata): boolean {
    // A Glide table on GBT needs to be treated as non-queryable in the computation model.
    if (isGlideTableInGBTDataStore(sm)) return false;
    return sm?.needsQuery === true;
}

/**
 * Returns `true` if the given table can be queried.
 *  Includes Glide Big Tables, Glide Tables in the GBT data store, and external queryable tables (e.g. SQL)
 */
export function isQueryableTable(table: TableGlideType): boolean {
    return table.sourceMetadata?.needsQuery === true;
}

/**
 * Returns `true` if the given source metadata refers to a Glide Table that is stored in the GBT data store.
 *  Does NOT include Glide Big Tables.
 */
export function isGlideTableInGBTDataStore(sm: NativeTableSourceMetadata | undefined): boolean {
    return isGBTDataStoreSourceMetadata(sm) && sm.queryType === "glide-table-on-big-tables";
}

/**
 * Returns `true` if the given source metadata refers to a Glide Big Table.
 *  Does NOT include Glide Tables that are stored in the GBT data store.
 */
export function isGlideBigTablesSourceMetadata(sm: NativeTableSourceMetadata | undefined): boolean {
    return isGBTDataStoreSourceMetadata(sm) && sm.queryType !== "glide-table-on-big-tables";
}

export function replaceSingleNativeTableID(
    replacements: Record<NativeTableID, NativeTableID>,
    metadata: SourceMetadata
): SourceMetadata {
    if (metadata.type !== "Native table") return metadata;

    const thisReplacement = replacements[metadata.id];
    if (thisReplacement === undefined) return metadata;

    return {
        ...metadata,
        id: thisReplacement,
    };
}

export function hasThirdPartyDataSource(sourceMetadata: readonly SourceMetadata[]): boolean {
    const sheetMetadata = getGoogleSheetsSourceMetadata(sourceMetadata);
    return (
        sheetMetadata !== undefined || sourceMetadata.some(sm => sm.type === "Native table" && "externalSource" in sm)
    );
}

export function canTableBeUserProfiles(table: TableGlideType, allowGBTs: boolean): boolean {
    return (
        !getSourceMetadataFlags(table.sourceMetadata).cannotBeUserProfile &&
        !makeTableName(table.name).isSpecial &&
        // This clause is just for feature flagging and can be removed
        //  when the `gbtUserProfileTables` feature flag is removed.
        (allowGBTs || !isGlideBigTablesSourceMetadata(table.sourceMetadata))
    );
}
