export enum CodeScannerStandard {
    QR = "qrcode",
    Code128 = "code_128",
    EAN_13 = "ean_13",
    EAN_5 = "ean_5",
    EAN_2 = "ean_2",
    EAN_8 = "ean_8",
    Code39 = "code_39",
    Code39_VIN = "code_39_vin",
    Codabar = "codabar",
    UPC_A = "upc_a",
    UPC_E = "upc_e",
    i2of5 = "i2of5",
    TwoOfFive = "2of5",
    Code93 = "code_93",
    Code32 = "code_32",
    Unknown = "unknown",
    None = "none",
}

export type CodeScannerStandards = readonly CodeScannerStandard[];

export function isEnabledCodeScannerStandard(all: CodeScannerStandards, check: CodeScannerStandard): boolean {
    return all.indexOf(check) >= 0;
}
