export interface GlideAppIconData {
    readonly name: string;
    readonly path: string;
    readonly category: string;
    readonly metadata: readonly string[];
}

export const GLIDE_APP_ICONS: readonly GlideAppIconData[] = [
    {
        name: "st-message-circle",
        path: `/svg/stroke/st-message-circle.svg`,
        metadata: ["message", "circle", "bubble", "chat"],
        category: "Essentials",
    },
    { name: "st-user", path: `/svg/stroke/st-user.svg`, metadata: ["user", "person"], category: "Essentials" },
    {
        name: "st-mail",
        path: `/svg/stroke/st-mail.svg`,
        metadata: ["mail", "email", "message", "enveloppe"],
        category: "Essentials",
    },
    {
        name: "st-action-bolt",
        path: `/svg/stroke/st-action-bolt.svg`,
        metadata: ["action", "bolt"],
        category: "Essentials",
    },
    {
        name: "st-components",
        path: `/svg/stroke/st-components.svg`,
        metadata: ["components", "dashboard", "squares", "grid"],
        category: "Essentials",
    },
    {
        name: "st-chart",
        path: `/svg/stroke/st-chart.svg`,
        metadata: ["chart", "pie", "distribution"],
        category: "Essentials",
    },
    {
        name: "st-locked",
        path: `/svg/stroke/st-locked.svg`,
        metadata: ["locked", "lock", "security", "private"],
        category: "Essentials",
    },
    {
        name: "st-language-website",
        path: `/svg/stroke/st-language-website.svg`,
        metadata: ["language", "website", "globe", "network"],
        category: "Essentials",
    },
    {
        name: "st-upload",
        path: `/svg/stroke/st-upload.svg`,
        metadata: ["upload", "cloud", "import", "export"],
        category: "Essentials",
    },
    { name: "st-star-5", path: `/svg/stroke/st-star-5.svg`, metadata: ["star", "favorite"], category: "Essentials" },
    {
        name: "st-eye",
        path: `/svg/stroke/st-eye.svg`,
        metadata: ["eye", "vision", "hide", "view"],
        category: "Essentials",
    },
    {
        name: "st-settings",
        path: `/svg/stroke/st-settings.svg`,
        metadata: ["settings", "cog", "parameters", "advanced"],
        category: "Essentials",
    },
    {
        name: "st-edit-pencil",
        path: `/svg/stroke/st-edit-pencil.svg`,
        metadata: ["edit", "pencil", "pen", "form", "write"],
        category: "Essentials",
    },
    { name: "st-grid", path: `/svg/stroke/st-grid.svg`, metadata: ["grid", "data"], category: "Essentials" },
    {
        name: "st-users",
        path: `/svg/stroke/st-users.svg`,
        metadata: ["users", "persons", "group", "team"],
        category: "Essentials",
    },
    {
        name: "st-video",
        path: `/svg/stroke/st-video.svg`,
        metadata: ["video", "youtube", "play"],
        category: "Essentials",
    },
    {
        name: "st-burger",
        path: `/svg/stroke/st-burger.svg`,
        metadata: ["burger", "menu", "lines"],
        category: "Essentials",
    },
    { name: "st-plus-add", path: `/svg/stroke/st-plus-add.svg`, metadata: ["plus", "add"], category: "Essentials" },
    { name: "st-search", path: `/svg/stroke/st-search.svg`, metadata: ["search"], category: "Essentials" },
    {
        name: "st-check",
        path: `/svg/stroke/st-check.svg`,
        metadata: ["check", "done", "validate"],
        category: "Essentials",
    },
    {
        name: "st-check-circle",
        path: `/svg/stroke/st-check-circle.svg`,
        metadata: ["check", "circle", "done", "validate"],
        category: "Essentials",
    },
    {
        name: "st-gague",
        path: `/svg/stroke/st-gague.svg`,
        metadata: ["gague", "dashboard", "performance"],
        category: "Essentials",
    },
    {
        name: "st-house",
        path: `/svg/stroke/st-house.svg`,
        metadata: ["house", "home", "overview"],
        category: "Essentials",
    },
    {
        name: "st-heart",
        path: `/svg/stroke/st-heart.svg`,
        metadata: ["heart", "favourite", "matt"],
        category: "Essentials",
    },
    {
        name: "st-more",
        path: `/svg/stroke/st-more.svg`,
        metadata: ["more", "menu", "options"],
        category: "Essentials",
    },
    {
        name: "st-light-bulb",
        path: `/svg/stroke/st-light-bulb.svg`,
        metadata: ["light", "bulb", "idea", "light"],
        category: "Objects",
    },
    {
        name: "st-research-lab",
        path: `/svg/stroke/st-research-lab.svg`,
        metadata: ["research", "lab", "test", "explore"],
        category: "Objects",
    },
    { name: "st-flag", path: `/svg/stroke/st-flag.svg`, metadata: ["flag", "mark"], category: "Objects" },
    {
        name: "st-guide-book",
        path: `/svg/stroke/st-guide-book.svg`,
        metadata: ["guide", "book", "directory", "open", "read"],
        category: "Objects",
    },
    {
        name: "st-tool",
        path: `/svg/stroke/st-tool.svg`,
        metadata: ["tool", "wrench", "parameters", "settings"],
        category: "Objects",
    },
    {
        name: "st-mail-opened",
        path: `/svg/stroke/st-mail-opened.svg`,
        metadata: ["mail", "opened", "view", "read"],
        category: "Objects",
    },
    {
        name: "st-unlocked",
        path: `/svg/stroke/st-unlocked.svg`,
        metadata: ["unlocked", "unlock", "access"],
        category: "Objects",
    },
    { name: "st-trash", path: `/svg/stroke/st-trash.svg`, metadata: ["trash", "delete", "bin"], category: "Objects" },
    {
        name: "st-filter",
        path: `/svg/stroke/st-filter.svg`,
        metadata: ["filter", "parameters", "settings"],
        category: "Objects",
    },
    {
        name: "st-design-brush",
        path: `/svg/stroke/st-design-brush.svg`,
        metadata: ["design", "brush", "paint", "edit"],
        category: "Objects",
    },
    {
        name: "st-bird-house",
        path: `/svg/stroke/st-bird-house.svg`,
        metadata: ["bird", "house", "home"],
        category: "Objects",
    },
    {
        name: "st-clock",
        path: `/svg/stroke/st-clock.svg`,
        metadata: ["clock", "snooze", "time", "watch"],
        category: "Objects",
    },
    {
        name: "st-folder",
        path: `/svg/stroke/st-folder.svg`,
        metadata: ["folder", "group"],
        category: "Objects",
    },
    {
        name: "st-image",
        path: `/svg/stroke/st-image.svg`,
        metadata: ["image", "picture", "photo", "frame", "upload"],
        category: "Objects",
    },
    {
        name: "st-link",
        path: `/svg/stroke/st-link.svg`,
        metadata: ["link", "url", "website"],
        category: "Communication",
    },
    {
        name: "st-share",
        path: `/svg/stroke/st-share.svg`,
        metadata: ["share", "connect", "connections"],
        category: "Communication",
    },
    {
        name: "st-help",
        path: `/svg/stroke/st-help.svg`,
        metadata: ["help", "question", "question", "mark"],
        category: "Communication",
    },
    {
        name: "st-edit-notebook",
        path: `/svg/stroke/st-edit-notebook.svg`,
        metadata: ["edit", "notebook", "write", "compose"],
        category: "Communication",
    },
    {
        name: "st-message-square",
        path: `/svg/stroke/st-message-square.svg`,
        metadata: ["message", "square", "bubble", "chat"],
        category: "Communication",
    },
    {
        name: "st-send",
        path: `/svg/stroke/st-send.svg`,
        metadata: ["send", "compose", "email"],
        category: "Communication",
    },
    {
        name: "st-blog-content",
        path: `/svg/stroke/st-blog-content.svg`,
        metadata: ["blog", "content", "media", "document"],
        category: "Communication",
    },
    {
        name: "st-message-square-chat",
        path: `/svg/stroke/st-message-square-chat.svg`,
        metadata: ["message", "square", "chat", "bubble", "write", "compose"],
        category: "Communication",
    },
    {
        name: "st-message-square-remove",
        path: `/svg/stroke/st-message-square-remove.svg`,
        metadata: ["message", "square", "remove", "delete"],
        category: "Communication",
    },
    {
        name: "st-alert-warning",
        path: `/svg/stroke/st-alert-warning.svg`,
        metadata: ["alert", "warning", "exclamation"],
        category: "Communication",
    },
    {
        name: "st-feature",
        path: `/svg/stroke/st-feature.svg`,
        metadata: ["feature", "new", "notification"],
        category: "Communication",
    },
    {
        name: "st-custom-input",
        path: `/svg/stroke/st-custom-input.svg`,
        metadata: ["custom", "input", "write"],
        category: "Communication",
    },
    {
        name: "st-check-task",
        path: `/svg/stroke/st-check-task.svg`,
        metadata: ["check", "task", "document", "done", "read", "paper"],
        category: "Communication",
    },
    {
        name: "st-edit-content",
        path: `/svg/stroke/st-edit-content.svg`,
        metadata: ["edit", "content", "document", "sheet", "paper", "write", "pencil"],
        category: "Communication",
    },
    {
        name: "st-list",
        path: `/svg/stroke/st-list.svg`,
        metadata: ["list", "text", "left", "align"],
        category: "Communication",
    },
    {
        name: "st-edit-browser",
        path: `/svg/stroke/st-edit-browser.svg`,
        metadata: ["edit", "browser", "pencil"],
        category: "Communication",
    },
    {
        name: "st-mail-opened",
        path: `/svg/stroke/st-mail-opened.svg`,
        metadata: ["mail", "opened", "email", "enveloppe"],
        category: "Communication",
    },
    {
        name: "st-telephone",
        path: `/svg/stroke/st-telephone.svg`,
        metadata: ["telephone", "phone", "call"],
        category: "Communication",
    },
    {
        name: "st-password",
        path: `/svg/stroke/st-password.svg`,
        metadata: ["password"],
        category: "Communication",
    },
    {
        name: "st-facebook",
        path: `/svg/stroke/st-facebook.svg`,
        metadata: ["facebook", "social"],
        category: "Communication",
    },
    {
        name: "st-whatsapp",
        path: `/svg/stroke/st-whatsapp.svg`,
        metadata: ["whatsapp", "social"],
        category: "Communication",
    },
    {
        name: "st-linkedin",
        path: `/svg/stroke/st-linkedin.svg`,
        metadata: ["linkedin", "social"],
        category: "Communication",
    },
    {
        name: "st-twitter",
        path: `/svg/stroke/st-twitter.svg`,
        metadata: ["twitter", "social"],
        category: "Communication",
    },
    {
        name: "st-calendar",
        path: `/svg/stroke/st-calendar.svg`,
        metadata: ["calendar", "event", "schedule"],
        category: "Events & People",
    },
    {
        name: "st-address-book",
        path: `/svg/stroke/st-address-book.svg`,
        metadata: ["address", "book", "directory", "people", "contacts"],
        category: "Events & People",
    },
    {
        name: "st-single-man-flag",
        path: `/svg/stroke/st-single-man-flag.svg`,
        metadata: ["single", "man", "flag", "person"],
        category: "Events & People",
    },
    {
        name: "st-emoji-happy",
        path: `/svg/stroke/st-emoji-happy.svg`,
        metadata: ["emoji", "happy", "face", "laugh"],
        category: "Events & People",
    },
    {
        name: "st-emoji-ok",
        path: `/svg/stroke/st-emoji-ok.svg`,
        metadata: ["emoji", "ok", "face", "smile"],
        category: "Events & People",
    },
    {
        name: "st-emoji-hmm",
        path: `/svg/stroke/st-emoji-hmm.svg`,
        metadata: ["emoji", "hmm", "face", "neutral"],
        category: "Events & People",
    },
    {
        name: "st-emoji-sad",
        path: `/svg/stroke/st-emoji-sad.svg`,
        metadata: ["emoji", "sad", "face"],
        category: "Events & People",
    },
    {
        name: "st-arrow-inspect",
        path: `/svg/stroke/st-arrow-inspect.svg`,
        metadata: ["arrow", "inspect", "circle", "next"],
        category: "Arrows",
    },
    {
        name: "st-sync",
        path: `/svg/stroke/st-sync.svg`,
        metadata: ["sync", "reload", "arrow", "arrows"],
        category: "Arrows",
    },
    {
        name: "st-arrow-external",
        path: `/svg/stroke/st-arrow-external.svg`,
        metadata: ["arrow", "external", "open", "link"],
        category: "Arrows",
    },
    {
        name: "st-arrow-left",
        path: `/svg/stroke/st-arrow-left.svg`,
        metadata: ["arrow", "left", "previous", "full"],
        category: "Arrows",
    },
    {
        name: "st-arrow-right",
        path: `/svg/stroke/st-arrow-right.svg`,
        metadata: ["arrow", "right", "next", "full"],
        category: "Arrows",
    },
    {
        name: "st-arrow-down",
        path: `/svg/stroke/st-arrow-down.svg`,
        metadata: ["arrow", "down", "import", "download"],
        category: "Arrows",
    },
    {
        name: "st-arrow-up",
        path: `/svg/stroke/st-arrow-up.svg`,
        metadata: ["arrow", "up", "upload"],
        category: "Arrows",
    },
    {
        name: "st-arrow-up-left",
        path: `/svg/stroke/st-arrow-up-left.svg`,
        metadata: ["arrow", "left", "up", "back", "close"],
        category: "Arrows",
    },
    {
        name: "st-arrow-up-right",
        path: `/svg/stroke/st-arrow-up-right.svg`,
        metadata: ["arrow", "right", "up", "external", "link"],
        category: "Arrows",
    },
    {
        name: "st-chevron-selector",
        path: `/svg/stroke/st-chevron-selector.svg`,
        metadata: ["chevron", "selector", "arrows", "up down", "select"],
        category: "Arrows",
    },
    { name: "st-redo", path: `/svg/stroke/st-redo.svg`, metadata: ["arrow", "redo", "next"], category: "Arrows" },
    {
        name: "st-undo",
        path: `/svg/stroke/st-undo.svg`,
        metadata: ["arrow", "undo", "preview", "reply"],
        category: "Arrows",
    },
    {
        name: "st-download",
        path: `/svg/stroke/st-download.svg`,
        metadata: ["arrow", "download", "import", "place"],
        category: "Arrows",
    },
    {
        name: "st-sort",
        path: `/svg/stroke/st-sort.svg`,
        metadata: ["arrows", "up down", "filter", "sort"],
        category: "Arrows",
    },
    {
        name: "st-laptop-remote",
        path: `/svg/stroke/st-laptop-remote.svg`,
        metadata: ["laptop", "remote", "pin", "desktop", "computer"],
        category: "Devices",
    },
    {
        name: "st-desktop",
        path: `/svg/stroke/st-desktop.svg`,
        metadata: ["desktop", "device", "monitor", "screen", "large"],
        category: "Devices",
    },
    {
        name: "st-tablet",
        path: `/svg/stroke/st-tablet.svg`,
        metadata: ["tablet", "device", "ipad", "screen", "medium"],
        category: "Devices",
    },
    {
        name: "st-mobile",
        path: `/svg/stroke/st-mobile.svg`,
        metadata: ["mobile", "device", "mobile", "iphone", "call"],
        category: "Devices",
    },
    {
        name: "st-templates",
        path: `/svg/stroke/st-templates.svg`,
        metadata: ["templates", "devices", "mobile"],
        category: "Devices",
    },
    {
        name: "st-briefcase",
        path: `/svg/stroke/st-briefcase.svg`,
        metadata: ["briefcase", "business", "toolbox"],
        category: "Business",
    },
    {
        name: "st-buildings-business",
        path: `/svg/stroke/st-buildings-business.svg`,
        metadata: ["buildings", "business", "enterprise"],
        category: "Business",
    },
    {
        name: "st-trolley-cart",
        path: `/svg/stroke/st-trolley-cart.svg`,
        metadata: ["trolley", "cart", "shop", ", "],
        category: "Business",
    },
    {
        name: "st-data",
        path: `/svg/stroke/st-data.svg`,
        metadata: ["data", "grid", "table", ", "],
        category: "Business",
    },
    {
        name: "st-pin",
        path: `/svg/stroke/st-pin.svg`,
        metadata: ["pin", "place", "map", ", "],
        category: "Maps & Travel",
    },
    {
        name: "st-luggage",
        path: `/svg/stroke/st-luggage.svg`,
        metadata: ["luggage", "case", "travel"],
        category: "Maps & Travel",
    },
    {
        name: "st-wellbeing-peace",
        path: `/svg/stroke/st-wellbeing-peace.svg`,
        metadata: ["wellbeing", "peace", "balance"],
        category: "Maps & Travel",
    },
    {
        name: "st-snorkle",
        path: `/svg/stroke/st-snorkle.svg`,
        metadata: ["snorkle", "face"],
        category: "Maps & Travel",
    },
    {
        name: "st-rocket",
        path: `/svg/stroke/st-rocket.svg`,
        metadata: ["rocket", "launch"],
        category: "Maps & Travel",
    },
    {
        name: "st-globe",
        path: `/svg/stroke/st-globe.svg`,
        metadata: ["globe", "earth", "planet", ", "],
        category: "Maps & Travel",
    },
    {
        name: "st-network-pin",
        path: `/svg/stroke/st-network-pin.svg`,
        metadata: ["globe", "earth", "planet", "place", "pin", "location"],
        category: "Maps & Travel",
    },
    { name: "st-checkbox", path: `/svg/stroke/st-checkbox.svg`, metadata: ["square", "squircle"], category: "Shapes" },
    { name: "st-radio", path: `/svg/stroke/st-radio.svg`, metadata: ["circle", "round"], category: "Shapes" },
    { name: "st-stairs", path: `/svg/stroke/st-stairs.svg`, metadata: ["stairs", "steps"], category: "Shapes" },
    { name: "st-apps", path: `/svg/stroke/st-apps.svg`, metadata: ["apps", "app", "square"], category: "Shapes" },
    { name: "st-pages", path: `/svg/stroke/st-pages.svg`, metadata: ["pages", "screen", "large"], category: "Shapes" },
    {
        name: "st-products",
        path: `/svg/stroke/st-products.svg`,
        metadata: ["products", "overlap", "duplicate"],
        category: "Shapes",
    },
    {
        name: "st-copy",
        path: `/svg/stroke/st-copy.svg`,
        metadata: ["copy", "duplicate", "squares"],
        category: "Shapes",
    },
    { name: "st-star-4", path: `/svg/stroke/st-star-4.svg`, metadata: ["star", "4", "new"], category: "Shapes" },
    {
        name: "st-shield-security",
        path: `/svg/stroke/st-shield-security.svg`,
        metadata: ["shield", "security", "privacy"],
        category: "Shapes",
    },
    { name: "st-bap", path: `/svg/stroke/st-bap.svg`, metadata: ["bap", "exlamation", "asterisk"], category: "Shapes" },
    { name: "st-close", path: `/svg/stroke/st-close.svg`, metadata: ["close", "delete", "x"], category: "Shapes" },
    { name: "st-null", path: `/svg/stroke/st-null.svg`, metadata: ["minus", "null", "less"], category: "Shapes" },
    {
        name: "st-sso",
        path: `/svg/stroke/st-sso.svg`,
        metadata: ["sso"],
        category: "Communication",
    },
];

// Used to return Legacy RemoteIcons. Now only returns Glide icons.
export function getRandomIcon(): string {
    const idx = Math.floor(Math.random() * GLIDE_APP_ICONS.length);
    const icon = GLIDE_APP_ICONS[idx];
    return `path:${icon.path}`;
}
