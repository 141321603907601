import { getWireAppThemeForPlatform } from "@glide/theme";
import { memoizeFunction } from "@glide/support";
import type { BaseTheme } from "@glide/base-theme";
import type { ThemePlatform } from "./theme";
import { getRuntimeThemeForPlatform } from "./theme";
import type { DeviceFormFactor } from "../utility";

export const getMemoizeTheme = memoizeFunction(
    "memoizeTheme",
    (
        isPages: boolean,
        theme: BaseTheme | undefined,
        platform: ThemePlatform,
        deviceFormFactor: DeviceFormFactor,
        isDarkMode?: boolean,
        isOSThemeDark?: boolean
    ) => {
        if (isPages) {
            return getWireAppThemeForPlatform(theme, isOSThemeDark ?? false);
        } else {
            return getRuntimeThemeForPlatform(theme, platform, deviceFormFactor, isDarkMode);
        }
    }
);
