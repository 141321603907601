import { type BaseTheme, Color } from "@glide/base-theme";
import { assertNever } from "@glideapps/ts-necessities";
import * as deepmerge from "deepmerge";

import { DeviceFormFactor } from "../utility";
import { defaultRuntimeTheme } from "./core-themes/runtime-theme";
import type { OverlayTheme } from "./overlay-theme";
import { androidTabletOverlay, desktopOverlay, tabletOverlay } from "./tablet-overlay";
import { androidOverlay } from "./theme-overlays/android-theme-overlay";
import { colorDarkOverlay, colorOverlay } from "./theme-overlays/color-theme-overlay";
import { darkOverlay } from "./theme-overlays/dark-theme-overlays/dark-app-theme";
import { pureBlackOverlay } from "./theme-overlays/pure-black-theme-overlay";
import { pureWhiteOverlay } from "./theme-overlays/pure-white-theme-overlay";
import type { RuntimeTheme } from "./types";
import { type CoreTheme, type WireAppTheme, mergeTheme } from "@glide/theme";
import chroma from "chroma-js";

export const mobileSystemFontFamily = "-apple-system,BlinkMacSystemFont,Roboto,sans-serif";
export const pagesFontFamily = "Inter,-apple-system,BlinkMacSystemFont,Roboto,sans-serif";

export const primaryColors = Object.keys(Color).map(name => ({ name, value: (Color as any)[name] }));
const types = ["modern", "editorial", "future", "system"];
export const typographyTypes = types.map(type => ({
    name: type.slice(0, 1).toUpperCase() + type.slice(1),
    value: type,
}));

let currentThemeCache: RuntimeTheme | WireAppTheme | undefined;
let initialsFontColorCache: string | undefined;
let initialsBackgroundCache: string | undefined;

export function initialsFontColor(theme: RuntimeTheme): string {
    // must use any here or we break typing
    if (theme === currentThemeCache && initialsFontColorCache !== undefined) {
        return initialsFontColorCache;
    }

    const result = chroma(theme.primaryAccentColor).darken(theme.listItem.textDarkenAmount).css();
    currentThemeCache = theme;
    initialsFontColorCache = result;
    return result;
}

export function initialsBackground(theme: RuntimeTheme): string {
    // must use any here or we break typing
    if (theme === currentThemeCache && initialsBackgroundCache !== undefined) {
        return initialsBackgroundCache;
    }

    const result = chroma(theme.primaryAccentColor).desaturate().alpha(0.12).css();
    currentThemeCache = theme;
    initialsBackgroundCache = result;
    return result;
}

export type ThemePlatform = "iOS" | "Android";

const someColors = ["#731358", "#D85722", "#D99E00", "#789436", "#2F6783", "#3B47C6", "#613DC7", "#4B5D67"];

export function randomTheme(): BaseTheme {
    const randomColorIndex = Math.floor(Math.random() * someColors.length);
    const randomColor = someColors[randomColorIndex];

    return {
        primaryAccentColor: randomColor,
        showTabLabels: true,
        increaseContrast: false,
        themeOverlay: "none",
        showDesktopSideBar: false,
        showIconsInNavBar: true,
        showLabelsInTabBar: true,
        pageTheme: "Accent",
        pageEnvironment: "Auto",
        logoDisplay: "iconAndTitle",
    };
}

export function getRuntimeThemeForPlatform(
    appTheme?: BaseTheme,
    platform?: ThemePlatform,
    deviceFormFactor?: DeviceFormFactor,
    darkMode?: boolean
): RuntimeTheme {
    currentThemeCache = undefined;
    initialsFontColorCache = undefined;
    initialsBackgroundCache = undefined;

    if (platform === undefined) {
        platform = "iOS";
    }

    const adaptive = appTheme?.themeIsAdaptive === true;
    const isOSDark: boolean = darkMode ?? isSystemDarkThemePreferred();

    let overlay: OverlayTheme | undefined;
    if (appTheme !== undefined) {
        switch (appTheme.themeOverlay) {
            case "dark":
                overlay = darkOverlay;
                if (!isOSDark && adaptive) {
                    overlay = undefined;
                }
                break;
            case "pureblack":
                overlay = pureBlackOverlay;
                if (!isOSDark && adaptive) {
                    overlay = pureWhiteOverlay;
                }
                break;
            case "color":
                overlay = colorOverlay(appTheme);
                if (isOSDark && adaptive) {
                    overlay = colorDarkOverlay(appTheme);
                }
                break;
            case "colorDark":
                overlay = colorDarkOverlay(appTheme);
                if (!isOSDark && adaptive) {
                    overlay = colorOverlay(appTheme);
                }
                break;
            case "purewhite":
                overlay = pureWhiteOverlay;
                if (isOSDark && adaptive) {
                    overlay = pureBlackOverlay;
                }
                break;
            default:
                if (isOSDark && adaptive) {
                    overlay = darkOverlay;
                }
        }
    }

    const baseTheme = randomTheme();
    if (appTheme === undefined) {
        appTheme = baseTheme;
    }

    const deepMergedThemes = deepmerge.all([baseTheme, appTheme, defaultRuntimeTheme]) as RuntimeTheme;
    const overlays = [overlay];
    if (deviceFormFactor === DeviceFormFactor.Tablet) {
        if (platform === "Android") {
            overlays.push(androidTabletOverlay);
        } else {
            overlays.push(tabletOverlay);
        }
    } else if (deviceFormFactor === DeviceFormFactor.Desktop) {
        overlays.push(desktopOverlay);
    }

    const resultingTheme = getThemeForPlatform(platform, deepMergedThemes, overlays);

    setMetaAppleStatusBar(resultingTheme);
    setMetaThemeColor(resultingTheme);

    return resultingTheme;
}

function setMetaAppleStatusBar(theme: RuntimeTheme) {
    if (theme.themeOverlay === "dark" || theme.themeOverlay === "pureblack") {
        const statusBarMeta = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
        if (statusBarMeta !== null) {
            statusBarMeta.setAttribute("content", "black");
        }
    } else if (theme.themeOverlay === "color" || theme.themeOverlay === "colorDark") {
        const statusBarMeta = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
        if (statusBarMeta !== null) {
            statusBarMeta.setAttribute("content", "black-translucent");
        }
    }
}

// This meta tag is appended in the server's request, for some reason.
// See functions/src/functions/play.ts
function setMetaThemeColor(theme: CoreTheme) {
    const themeColorMeta = document.querySelector('meta[name="theme-color"]');
    if (themeColorMeta !== null) {
        themeColorMeta.setAttribute("content", theme.OSstatusBar);
    }
}

function getThemeForPlatform(
    platform: ThemePlatform,
    originalTheme: RuntimeTheme,
    overlays: (OverlayTheme | undefined)[]
): RuntimeTheme {
    switch (platform) {
        case "iOS":
            return mergeTheme(originalTheme, [...overlays]);
        case "Android":
            return mergeTheme(originalTheme, [androidOverlay, ...overlays]);
        default:
            assertNever(platform);
    }
}

export function isDarkTheme(theme: { themeOverlay?: string }): boolean {
    return theme.themeOverlay === "dark" || theme.themeOverlay === "pureblack" || theme.themeOverlay === "colorDark";
}

export function isSystemDarkThemePreferred(): boolean {
    return (
        typeof window !== "undefined" && window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    );
}
