export const Pallettes = {
    Barragan: [
        "#FEE202",
        "#E52E1F",
        "#FF7527",
        "#DE56B5",
        "#289FFF",
        "#57D6C0",
        "#FDAED0",
        "#CCCCCC",
        "#9F2B1D",
        "#FFB78E",
    ],
    Hockney: [
        "#63E0D8",
        "#EC78BE",
        "#2E67F5",
        "#F63156",
        "#FCE002",
        "#00D9A8",
        "#C175EF",
        "#5AA3E6",
        "#FD733D",
        "#01C552",
    ],

    Kahlo: [
        "#333FFF",
        "#EA2C0B",
        "#FFBA01",
        "#0A6C25",
        "#48BE1F",
        "#FC8716",
        "#FF85BC",
        "#B9B047",
        "#B57A6A",
        "#3EA66C",
    ],
    Quinquela: [
        "#D64820",
        "#8AC0E4",
        "#0065AB",
        "#189D98",
        "#FFBA01",
        "#47BAE4",
        "#F46E78",
        "#393A7A",
        "#99A490",
        "#E78C0B",
    ],
    Magritte: [
        "#81A5D9",
        "#DA605B",
        "#8FC747",
        "#E4A480",
        "#3A5765",
        "#7BD2F0",
        "#BA97BE",
        "#C66111",
        "#8C9399",
        "#A7CCAA",
    ],

    Pamplemousse: [
        "#F7907D",
        "#F2BD7B",
        "#D64959",
        "#018AC0",
        "#0DD7D1",
        "#1B3676",
        "#6C6C6C",
        "#989898",
        "#CACACA",
        "#E8E8E8",
    ],
    Missy: [
        "#5E14D4",
        "#3360FF",
        "#A81DDE",
        "#E051F8",
        "#29CDFF",
        "#093793",
        "#FC085F",
        "#00B6B1",
        "#FF77D1",
        "#FDD53C",
    ],
    RuPaul: [
        "#F9F84F",
        "#FF718D",
        "#29CDFF",
        "#42E5E0",
        "#7A79FF",
        "#4467F8",
        "#E16AE3",
        "#18A0AE",
        "#E19A7A",
        "#A659E3",
    ],
    Vermeer: [
        "#F0C9AF",
        "#7690B6",
        "#F0D695",
        "#C25249",
        "#396496",
        "#EBE3E1",
        "#566579",
        "#D8AA5D",
        "#E8B5B2",
        "#6C5C43",
    ],
    Ansel: [
        "#252525",
        "#3F3F3F",
        "#686868",
        "#A4A4A4",
        "#D2D2D2",
        "#303030",
        "#505050",
        "#808080",
        "#BDBBBB",
        "#E1E1E1",
    ],
    Turrell: [
        "#1522B1",
        "#2F37DA",
        "#4E60FE",
        "#647BFF",
        "#728BFF",
        "#8597FD",
        "#99A9FF",
        "#AAB7FF",
        "#BDC7FF",
        "#CBD3FF",
    ],
};

export type PalletteType = keyof typeof Pallettes;

export const ColorTheme = {
    Ansel: "Ansel",
    Barragan: "Barragan",
    Hockney: "Hockney",
    Kahlo: "Kahlo",
    Magritte: "Magritte",
    Missy: "Missy",
    Pamplemousse: "Pamplemousse",
    Quinquela: "Quinquela",
    RuPaul: "RuPaul",
    Turrell: "Turrell",
    Vermeer: "Vermeer",
} as Record<PalletteType, string>;
