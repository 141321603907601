import type { BaseTheme } from "@glide/base-theme";
import type { OverlayTheme } from "../overlay-theme";
import { darkAppCoreTheme } from "./dark-theme-overlays/dark-app-theme";

export function colorOverlay(baseTheme: BaseTheme): OverlayTheme {
    const increaseContrast = baseTheme.increaseContrast === true;
    return {
        overlayName: `Color${baseTheme.primaryAccentColor}Theme`,
        statusBar: {
            color: "#ffffff",
        },

        list: {
            topMargin: "_pageMarginMedium" as any,
        },

        pivot: {
            troughColor: "rgba(0, 0, 0, 0.15)",
            troughFg: "#ffffff",
        },

        navBar: {
            titleColor: "#ffffff",
            fgColor: "#ffffff",
            bgColor: "_primaryAccentColor",
            transparentBgColor: "_primaryAccentColor",
            fadeTo: undefined,
            cancelColor: "#ffffff",

            largeTitleHeight: 56,
        },
        offlineBanner: {
            gap: 0,
        },

        searchBar: {
            placeholderColor: increaseContrast ? "rgba(0, 0, 0, 0.65)" : "rgba(255, 255, 255, 0.5)",
            textColor: increaseContrast ? "rgba(0, 0, 0, 0.65)" : "#FFFFFF",
            backgroundColor: increaseContrast ? "#FFFFFF" : "rgba(0, 0, 0, 0.15)",
        },

        flyout: {
            overlayColor: "#00000099",
            bgColor: "white",
            headerFg: "white",
            headerBg: "_primaryAccentColor",
            headerBorderColor: "transparent",
            rightBorderColor: "transparent",
            headerImgColor: "rgba(255, 255, 255, 0.3)",
        },

        materialDateTimePicker: {
            header: {
                background: "_primaryAccentColor",
                textDark: "rgba(255, 255, 255, 1.0)",
                textLight: "rgba(255, 255, 255, 0.6)",
            },
            tabs: {
                background: "_primaryAccentColor",
                iconColor: "rgba(255, 255, 255, 0.6)",
                iconSelectedColor: "rgba(255, 255, 255, 1.0)",
            },
        },

        OSstatusBar: baseTheme.primaryAccentColor,
    };
}

export function colorDarkOverlay(baseTheme: BaseTheme): OverlayTheme {
    const increaseContrast = baseTheme.increaseContrast === true;
    return {
        overlayName: `ColorDark${baseTheme.primaryAccentColor}Theme`,

        ...darkAppCoreTheme,

        fgColorLight: "_n500",
        fgColorMedium: "_n600",
        fgColorDark: "_n900",
        fgColorOpaque: "_n900",
        baseColor: "_n0",
        offBaseColor: "_n200",
        skeletonColor: "_n100",
        linkColor: "#5db7ef",
        separatorColor: "_n200A",

        primaryOverlayColor: "#000000F9",
        transparentOverlayColor: "#000000C9",
        mapTheme: "dark",

        avoidColors: [
            { color: "#15181E", contrast: 3.0, isBase: true },
            { color: "#ffffffe6", contrast: 1.2, isBase: false },
        ],
        statusBar: {
            color: "#ffffff",
        },

        calendar: {
            pastEventColor: "rgba(255, 255, 255, 0.12)",
            pastEventAccent: "#6c6c6c",
        },

        list: {
            topMargin: "_pageMarginMedium" as any,
        },

        signature: {
            invert: true,
        },

        textField: {
            underlineColor: "rgba(255, 255, 255, 0.15)",
        },

        navBar: {
            titleColor: "#ffffff",
            fgColor: "#ffffff",
            bgColor: "_primaryAccentColor",
            transparentBgColor: "_primaryAccentColor",
            fadeTo: "undefined",
            cancelColor: "#ffffff",

            largeTitleHeight: 56,
        },

        pivot: {
            troughColor: "rgba(0, 0, 0, 0.15)",
        },

        searchBar: {
            placeholderColor: increaseContrast ? "rgba(0, 0, 0, 0.65)" : "rgba(255, 255, 255, 0.5)",
            textColor: increaseContrast ? "rgba(0, 0, 0, 0.65)" : "#FFFFFF",
            backgroundColor: increaseContrast ? "#FFFFFF" : "rgba(0, 0, 0, 0.15)",
        },

        flyout: {
            overlayColor: "#00000099",
            bgColor: "black",
            headerFg: "white",
            headerBg: "black",
            headerBorderColor: "#E0E0E033",
            rightBorderColor: "#E0E0E033",
            headerImgColor: "rgba(255, 255, 255, 0.3)",
        },

        expandableOptions: {
            modal: {
                bgColor: "#000000",
            },
            options: {
                fgColorSelected: "#000000",
                bgColorUnselected: "#22252C",
            },
            button: {
                fgColorSelected: "#000000",
                fgColorUnselected: "#999999",
                bgColorUnselected: "#22252C",
            },
        },

        materialDateTimePicker: {
            header: {
                background: "_primaryAccentColor",
                textDark: "rgba(255, 255, 255, 1.0)",
                textLight: "rgba(255, 255, 255, 0.6)",
            },
            tabs: {
                background: "_primaryAccentColor",
                iconColor: "rgba(255, 255, 255, 0.6)",
                iconSelectedColor: "rgba(255, 255, 255, 1.0)",
            },
        },

        picker: {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            hoverBgColor: "rgba(255, 255, 255, 0.1)",
            activeBgColor: "rgba(255, 255, 255, 0.12)",
            currentBgColor: "rgba(255, 255, 255, 0.15)",
            currentColor: "_fgColorDark",
        },

        OSstatusBar: baseTheme.primaryAccentColor,
    };
}
