import {
    type ComputationModel,
    type GroundValue,
    type LoadingValue,
    Table,
    isLoadingValue,
    isQuery,
    type QueryBase,
} from "@glide/computation-model-types";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import { asString, isRow, isTable } from "@glide/common-core/dist/js/computation-model/data";
import type { Database } from "@glide/common-core/dist/js/Database/core";
import type { SpecialValueDescription } from "@glide/type-schema";
import {
    rowIndexColumnName,
    type SourceColumn,
    type TableGlideType,
    getTableColumn,
    getTableName,
    isBigTableOrExternal,
} from "@glide/type-schema";
import {
    type ArrayTransform,
    type LegacyPropertyDescription,
    type LimitArrayTransform,
    type MutatingScreenKind,
    type ShuffleArrayTransform,
    type TableOrderArrayTransform,
    ArrayTransformKind,
    SortOrder,
    getNumberProperty,
} from "@glide/app-description";
import { findFilterArrayTransform, makeInputOutputTables } from "@glide/common-core/dist/js/description";
import { doesTableSupportReverseSheetOrder } from "@glide/common-core/dist/js/schema-properties";
import {
    inflateQueryConditions,
    type QueryValueInflator,
    type PredicateCombinationSpecification,
    decomposePredicateCombinationFormula,
    decomposeSortKey,
} from "@glide/formula-specifications";
import type { ExistingAppDescriptionContext } from "@glide/function-utils";
import { getSortFromArrayTransforms } from "@glide/generator/dist/js/description-utils";
import { sortRows } from "@glide/generator/dist/js/wire/utils";
import { findType } from "@glide/support";
import {
    type ContextTableTypes,
    type InflatedColumn,
    type InflatedProperty,
    type InflatedTableGetter,
    type SearchableColumns,
    type WireInflationBackend,
    type WirePredicate,
    type WireQueryGetter,
    type WireRowHydrationValueProvider,
    type WireTableBackendGetter,
    type WireTableTransformer,
    makeContextTableTypes,
} from "@glide/wire";
import { assertNever, defined, definedMap, panic } from "@glideapps/ts-necessities";
import sortBy from "lodash/sortBy";
import { getSearchableColumns } from "@glide/generator/dist/js/components/searchable-columns";
import { ActionInflationBackend, makeValueGetterForSpecialValue } from "./action-inflation-backend";
import type { WriteSourceType } from "@glide/common-core";

export class InflationBackend extends ActionInflationBackend implements WireInflationBackend {
    constructor(
        appFacilities: ActionAppFacilities,
        adc: ExistingAppDescriptionContext,
        tables: ContextTableTypes,
        public readonly computationModel: ComputationModel,
        mutatingScreenKind: MutatingScreenKind | undefined,
        forBuilder: boolean,
        public readonly db: Database | undefined,
        private readonly precomputedSearchableColumns: SearchableColumns | undefined,
        writeSource: WriteSourceType
    ) {
        super(
            appFacilities,
            adc,
            tables,
            computationModel,
            mutatingScreenKind,
            forBuilder,
            false,
            undefined,
            writeSource
        );
    }

    public get searchableColumns(): SearchableColumns {
        return this.precomputedSearchableColumns ?? getSearchableColumns(this.adc, this.computationModel, true);
    }

    public inflateTransforms(transforms: readonly ArrayTransform[]): {
        filter: WireTableTransformer;
        sort: WireTableTransformer;
        limit: WireTableTransformer;
        limitRowNumber: number | undefined;
        numFilters: number;
        hasExplicitSort: boolean;
    } {
        const reverseTransform = findType(
            transforms,
            (t): t is TableOrderArrayTransform => t.kind === ArrayTransformKind.TableOrder && t.reverse
        );
        const shuffleTransform = findType(
            transforms,
            (t): t is ShuffleArrayTransform => t.kind === ArrayTransformKind.Shuffle
        );
        const limitTransform = findType(
            transforms,
            (t): t is LimitArrayTransform => t.kind === ArrayTransformKind.Limit
        );
        const limitRowNumber = definedMap(limitTransform, t => getNumberProperty(t.numRows));

        const filterTransform = findFilterArrayTransform(transforms);
        let filterPredicate: WirePredicate | undefined;
        let numFilters = 0;
        if (filterTransform !== undefined) {
            [filterPredicate, numFilters] = this.inflateFilters([filterTransform], false);
        }

        let sortGetterAndReverse: [InflatedColumn, boolean] | undefined;
        const maybeSort = getSortFromArrayTransforms(transforms);
        if (maybeSort !== undefined) {
            const { columnName, order } = maybeSort;
            const keyGetter = this.getValueGetterForColumnInRow(columnName, false, false);
            if (keyGetter !== undefined) {
                sortGetterAndReverse = [keyGetter, order === SortOrder.Descending];
            }
        }

        const filterTransformer: WireTableTransformer = (ttvp, table) => {
            // This doesn't just return `table` because it's also removing
            // non-visible rows.  This is probably not the best place to do
            // that.
            let rows = table.asMutatingArray().filter(r => r.$isVisible);

            if (filterPredicate === undefined) {
                return new Table(rows, table);
            }

            // NOTE: We could be much smarter here.  If there's no sort,
            // then we can just stop gathering rows until we've fulfilled
            // our limit, or however many rows we need to display in the
            // component.
            rows = rows.filter(r => {
                // TODO: If we have filter and sort we're making value
                // providers twice by row.
                const rhb = ttvp.makeRowValueProvider(r);
                return defined(filterPredicate)(rhb);
            });

            return new Table(rows, table);
        };

        const sortTransformer: WireTableTransformer = (ttvp, table) => {
            const originalArray = table.asMutatingArray();
            let rows = originalArray;

            if (sortGetterAndReverse !== undefined) {
                const [keyGetter, reverse] = sortGetterAndReverse;
                rows = sortRows(keyGetter, reverse, new Table(rows), ttvp).asMutatingArray();
            } else if (reverseTransform !== undefined) {
                rows = Array.from(rows).reverse();
            } else if (shuffleTransform !== undefined) {
                const order = ttvp.getShuffleOrder();
                rows = sortBy(rows, r => order.get(r.$rowID));
            } else {
                rows = rows;
            }

            return new Table(rows === originalArray ? Array.from(rows) : rows, table);
        };

        const limitTransformer: WireTableTransformer = (_ttvp, table) => {
            if (limitRowNumber === undefined) {
                return table;
            }

            const rows = table.asMutatingArray();
            if (rows.length <= limitRowNumber) {
                return table;
            }

            return new Table(rows.slice(0, limitRowNumber), table);
        };

        // An "explicit" sort is anything other than the row order ascending
        // or shuffle.  This is just for statistics.
        const hasExplicitSort = sortGetterAndReverse !== undefined || reverseTransform !== undefined;

        return {
            filter: filterTransformer,
            sort: sortTransformer,
            limit: limitTransformer,
            limitRowNumber,
            numFilters,
            hasExplicitSort,
        };
    }

    // This is what we use in Inline Lists to make the inflation backend for
    // the items in the list, which also makes the "current table" into the
    // "containing screen table".
    public getTableGetterWithTransforms(
        desc: LegacyPropertyDescription,
        transforms: readonly ArrayTransform[],
        applyLimit: boolean,
        allowSingleRelations: boolean
    ): InflatedTableGetter | undefined {
        const maybeTableGetter = this.getTableGetter(desc, allowSingleRelations);
        if (maybeTableGetter === undefined) return undefined;
        const [getter, tableType, numTableFilters] = maybeTableGetter;

        // ##containingScreenRowForTransforms:
        // We use the output table as the type for the containing screen row
        // here because in add/edit/form screens the output row is the
        // containing screen row, and in regular screens input and output
        // tables are the same, so in that case it doesn't matter.
        const ib = this.makeInflationBackendForTables(
            makeContextTableTypes(makeInputOutputTables(tableType), this.tables.output),
            this.mutatingScreenKind
        );

        const {
            filter: filterTransform,
            sort: sortTransform,
            limit: limitTransform,
            limitRowNumber,
            numFilters,
            hasExplicitSort,
        } = ib.inflateTransforms(transforms);

        const transformedGetter: WireTableBackendGetter = hb => {
            const table = defined(getter)(hb);
            if (table === undefined || isLoadingValue(table)) return table;

            const ttvp = hb.makeTableTransformValueProvider(getTableName(tableType));
            let rows = sortTransform(ttvp, filterTransform(ttvp, table));
            if (applyLimit) {
                rows = limitTransform(ttvp, rows);
            }

            return hb.makeHydrationBackendForTable(tableType, rows);
        };

        return {
            getter: transformedGetter,
            ib,
            limitTransform,
            limit: limitRowNumber,
            numFilters: numFilters + numTableFilters,
            hasExplicitSort,
        };
    }

    private inflateQueryCondition(
        table: TableGlideType,
        spec: PredicateCombinationSpecification
    ): (hb: WireRowHydrationValueProvider, q: QueryBase) => QueryBase | LoadingValue | undefined {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const backend = this;

        const valueInflator: QueryValueInflator<WireRowHydrationValueProvider> = {
            makeNonDefaultSourceColumnGetter(sc: SourceColumn) {
                const [getter, type] = backend.getValueGetterForSourceColumn(sc, false, false);
                if (type === undefined) return undefined;
                return getter;
            },
            makeVerifiedEmailAddressGetter() {
                return hb => {
                    const email = hb.getGlobalValue(
                        undefined,
                        backend.computationModel.getVerifiedEmailAddressPath(),
                        true
                    );
                    if (email === undefined || isLoadingValue(email)) return email;
                    return asString(email);
                };
            },
        };

        return inflateQueryConditions(valueInflator, table, spec);
    }

    public inflateQueryTransformer(
        table: TableGlideType,
        transforms: readonly ArrayTransform[]
    ): (hb: WireRowHydrationValueProvider, q: QueryBase) => QueryBase | LoadingValue | undefined {
        const transformers: ((
            hb: WireRowHydrationValueProvider,
            q: QueryBase
        ) => QueryBase | LoadingValue | undefined)[] = [];

        for (const t of transforms) {
            switch (t.kind) {
                case ArrayTransformKind.Filter:
                    if (t.isActive === false) continue;
                    const decomposed = decomposePredicateCombinationFormula(t.predicate);
                    if (decomposed === undefined) continue;
                    transformers.push(this.inflateQueryCondition(table, decomposed.spec));
                    break;
                case ArrayTransformKind.Limit: {
                    const numRows = getNumberProperty(t.numRows);
                    if (numRows === undefined) continue;
                    transformers.push((_hb, q) => q.withLimit(numRows));
                    break;
                }
                case ArrayTransformKind.Sort: {
                    const [key] = t.keys;
                    if (key === undefined) continue;
                    const columnName = decomposeSortKey(key.key);
                    if (columnName === undefined) continue;
                    if (getTableColumn(table, columnName) === undefined) continue;
                    transformers.push((_hb, q) => q.withSort([{ columnName, order: key.order }]));
                    break;
                }
                case ArrayTransformKind.TableOrder: {
                    // GBT orders by row index ASC by default; BQ doesn't support this.
                    if (t.reverse && doesTableSupportReverseSheetOrder(table)) {
                        transformers.push((_hb, q) => q.withSort([{ columnName: rowIndexColumnName, order: "desc" }]));
                    }
                    break;
                }
                case ArrayTransformKind.Shuffle:
                    // We're not supporting shuffle.
                    break;
                default:
                    return assertNever(t);
            }
        }

        if (transformers.length === 0) {
            return (_hb, q) => q;
        } else {
            return (hb, q) => {
                for (const t of transformers) {
                    const maybeQuery = t(hb, q);
                    if (maybeQuery === undefined || isLoadingValue(maybeQuery)) return maybeQuery;
                    q = maybeQuery;
                }
                return q;
            };
        }
    }

    public getQueryGetter(
        desc: LegacyPropertyDescription,
        transforms: readonly ArrayTransform[],
        allowSingleRelations: boolean,
        onlyUseQueries: boolean
    ): [getter: WireQueryGetter, ib: WireInflationBackend, isQueryableSource: boolean] | undefined {
        const maybeGetter = this.getTableOrQueryGetter(desc, allowSingleRelations, onlyUseQueries);
        if (maybeGetter === undefined) return undefined;

        const [getter, tableType] = maybeGetter;
        if (!(isBigTableOrExternal(tableType) || onlyUseQueries)) return undefined;

        // The output table is the correct one for the
        // ##containingScreenRowForTransforms.
        const contentIB = this.makeInflationBackendForTables(
            makeContextTableTypes(makeInputOutputTables(tableType), this.tables.output),
            this.mutatingScreenKind
        );

        const transformer = contentIB.inflateQueryTransformer(tableType, transforms);

        return [
            hb => {
                const queryOrTable = getter(hb);
                if (queryOrTable === undefined || isLoadingValue(queryOrTable)) return queryOrTable;
                if (isQuery(queryOrTable)) {
                    const transformHB = hb.makeHydrationBackendForQuery(tableType);
                    return transformer(transformHB, queryOrTable);
                }
                if (isTable(queryOrTable)) {
                    // We can get an ##emptyTableFromGetter.
                    return queryOrTable;
                }
                if (isRow(queryOrTable)) {
                    return new Table([queryOrTable]);
                }

                return panic("Unexpected value from getter");
            },
            contentIB,
            isBigTableOrExternal(tableType),
        ];
    }

    public getValueGetterForSpecialValue(kind: SpecialValueDescription): InflatedProperty<GroundValue> {
        return makeValueGetterForSpecialValue(kind, this.computationModel);
    }

    public makeInflationBackendForTables(
        tables: ContextTableTypes,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): InflationBackend {
        return new InflationBackend(
            this.appFacilities,
            this.adc,
            tables,
            this.computationModel,
            mutatingScreenKind,
            this.forBuilder,
            this.db,
            this.precomputedSearchableColumns,
            this.writeSource
        );
    }

    public makeInflationBackendForOutputTable(): InflationBackend {
        return this.makeInflationBackendForTables(
            makeContextTableTypes(makeInputOutputTables(this.tables.output), this.tables.containingScreen),
            // When running an action on the output table, we run it like
            // we're in a regular detail screen.
            // https://github.com/quicktype/glide/issues/18945
            undefined
        );
    }
}
