// Copied from https://github.com/hotjar/hotjar-js/blob/main/src/index.ts and modified
import type { InitOpts } from "./hj-utils";
import { checkReadyState, executeHotjarCommand, initScript } from "./hj-utils";

type UserInfo = Record<string | number, string | number | Date | boolean>;

const HOTJAR_ID = 3220231;
const HOTJAR_VERSION = 6;

export const Hotjar = {
    init: (opts?: InitOpts): boolean => {
        try {
            initScript(HOTJAR_ID, HOTJAR_VERSION, opts);
            return true;
        } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error("Error:", error);
            return false;
        }
    },
    event: (actionName: string): boolean => {
        try {
            executeHotjarCommand("event", actionName);
            return true;
        } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error("Error:", error);
            return false;
        }
    },
    identify: (userId: string | null, userInfo: UserInfo): boolean => {
        try {
            executeHotjarCommand("identify", userId, userInfo);
            return true;
        } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error("Error:", error);
            return false;
        }
    },
    stateChange: (relativePath: string): boolean => {
        try {
            executeHotjarCommand("stateChange", relativePath);
            return true;
        } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error("Error:", error);
            return false;
        }
    },
    isReady: checkReadyState,
};
