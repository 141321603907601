import { asString } from "@glide/computation-model-types";
import { getLocalizedString } from "@glide/localization";
import { getSwitchProperty, type MutatingScreenKind } from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { isValidEmailAddress } from "@glide/support";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import { type BaseFieldComponentDescription, BaseFieldComponentHandler, defaultFieldComponent } from "./base-field";
import { autofocusPropertyHandler } from "./autofocus-field";

const ComponentKindEmailField = "email-field";

const propertyNamePropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
        ColumnPropertyFlag.Searchable,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    ["email"],
    getPrimitiveColumnsSpec,
    "string",
    PropertySection.Data
);

interface EmailFieldComponentDescription extends BaseFieldComponentDescription {
    autofocus?: boolean;
}

export class EmailFieldComponentHandler extends BaseFieldComponentHandler<EmailFieldComponentDescription> {
    public readonly appKinds = "both";

    constructor() {
        super(ComponentKindEmailField, "email-address");
    }

    public needValidation(): boolean {
        return true;
    }

    public getDescriptor(
        desc: EmailFieldComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return this.getFieldDescriptor(
            desc,
            ccc,
            "Email Entry",
            "Edit an email address",
            "Entry Fields",
            "co-email-entry",
            propertyNamePropertyHandler,
            false,
            getDocURL("emailField"),
            mutatingScreenKind,
            "Email",
            [autofocusPropertyHandler]
        );
    }

    public inflate(
        ib: WireInflationBackend,
        desc: EmailFieldComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { appKind } = ib.adc;
        const autofocus = getSwitchProperty(desc.autofocus);
        return super.inflateComponent(
            ib,
            desc,
            (base, value, token) => {
                const email = asString(value);
                const isValid = isValidEmailAddress(email);
                return {
                    ...base,
                    kind: WireComponentKind.EmailField,
                    value: {
                        value: email,
                        onChangeToken: token,
                        error:
                            email !== "" && !isValid ? getLocalizedString("pleaseEnterValidEmail", appKind) : undefined,
                    },
                    autofocus,
                };
            },
            value => {
                const s = asString(value);
                return s === "" || isValidEmailAddress(s);
            }
        );
    }

    public static defaultComponent(column: TableColumn): EmailFieldComponentDescription {
        return defaultFieldComponent(column, ComponentKindEmailField);
    }
}
