import { AppKind } from "@glide/location-common";
import { isNotEmpty, isTable } from "@glide/common-core/dist/js/computation-model/data";
import { SyntheticColumnKind } from "@glide/formula-specifications";
import {
    type ActionDescription,
    type PropertyDescription,
    type ActionKind,
    getFormulaProperty,
    type ActionOutputDescriptor,
} from "@glide/app-description";
import { getHeaderIconForEditedColumn, nameForSyntheticColumnKind } from "@glide/component-utils";
import { isLoadingValue, unwrapLoadingValue, isBound, isQuery } from "@glide/computation-model-types";
import {
    type AppDescriptionContext,
    PropertyConfiguratorKind,
    PropertySection,
    makeCustomConfiguratorPropertyDescriptor,
    type ActionAvailability,
} from "@glide/function-utils";
import type {
    WireActionInflationBackend,
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
} from "@glide/wire";
import { isArray, isEnumValue } from "@glideapps/ts-necessities";
import { makeTypeForComputation } from "../computed-columns";
import { type StaticActionContext, makeTypeForActionNodeOutputGetterFromStaticContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { actionAvailabilityAutomations } from "./action-handler";
import { BaseActionHandler } from "./base";
import { isSingleRelationType } from "@glide/type-schema";

const kindPrefix = "computed-column-";

const computationOutputName = "result";

export function makeActionKindForComputation(syntheticColumnKind: SyntheticColumnKind): ActionKind {
    return `${kindPrefix}${syntheticColumnKind}` as ActionKind;
}

export function getSyntheticColumnKindForComputationActionKind(
    actionKind: ActionKind
): SyntheticColumnKind | undefined {
    if (!actionKind.startsWith(kindPrefix)) return undefined;
    const syntheticColumnKind = actionKind.slice(kindPrefix.length);
    if (!isEnumValue(SyntheticColumnKind, syntheticColumnKind)) return undefined;
    return syntheticColumnKind as SyntheticColumnKind;
}

export interface ComputationActionDescription extends ActionDescription {
    readonly kind: ActionKind;
    readonly formula: PropertyDescription | undefined;
    readonly displayFormula: PropertyDescription | undefined;
}

export class ComputationActionHandler extends BaseActionHandler<ComputationActionDescription> {
    constructor(private readonly syntheticColumnKind: SyntheticColumnKind) {
        super();
    }

    public get name(): string {
        return nameForSyntheticColumnKind[this.syntheticColumnKind];
    }

    public get kind(): ActionKind {
        return makeActionKindForComputation(this.syntheticColumnKind);
    }

    public get availability(): ActionAvailability {
        return actionAvailabilityAutomations;
    }

    public get appKinds(): AppKind | "both" {
        return AppKind.Page;
    }

    public get iconName() {
        return getHeaderIconForEditedColumn(this.syntheticColumnKind);
    }

    public getIsIdempotent(): boolean {
        return true;
    }

    public newActionDescription(): ComputationActionDescription | undefined {
        return {
            kind: this.kind,
            formula: undefined,
            displayFormula: undefined,
        };
    }

    public getDescriptor(
        desc: ComputationActionDescription | undefined,
        env: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const formula = getFormulaProperty(desc?.formula);
        const outputs: ActionOutputDescriptor[] = [];
        if (formula !== undefined) {
            const type = makeTypeForComputation(
                env.context,
                env.tables?.input,
                formula,
                makeTypeForActionNodeOutputGetterFromStaticContext(env)
            );
            if (!isArray(type)) {
                outputs.push({
                    name: computationOutputName,
                    displayName: "Result",
                    type,
                    displayFormula: getFormulaProperty(desc?.displayFormula),
                    description: "The result of the computation",
                });
            }
        }

        return {
            name: this.name,
            group: ActionGroup.Computed,
            groupItemOrder: 5,
            properties: [
                makeCustomConfiguratorPropertyDescriptor<ComputationActionDescription>(
                    PropertyConfiguratorKind.ComputationAction,
                    PropertySection.Data,
                    (action, visitor) => {
                        let f = getFormulaProperty(action.formula);
                        if (f !== undefined) {
                            visitor.visitFormula(f);
                        }

                        f = getFormulaProperty(action.displayFormula);
                        if (f !== undefined) {
                            visitor.visitFormula(f);
                        }
                    }
                ),
            ],
            outputs,
        };
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: ComputationActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const formula = getFormulaProperty(desc.formula);
        if (formula === undefined) return arb.error(true, "Computation is not configured");
        const [getter, type, , errorMessage] = ib.getFormulaGetter(formula);
        if (type === undefined) return arb.error(true, errorMessage ?? "Computation is not valid");
        return (hb, skipLoading) => {
            let value = getter(hb);
            if (isSingleRelationType(type) && isQuery(value)) {
                value = hb.resolveQueryAsTable(value);
            }
            if (isLoadingValue(value)) {
                const unwrapped = unwrapLoadingValue(value);
                let withUnwrapped = arb;
                if (!isLoadingValue(unwrapped)) {
                    withUnwrapped = arb.addData({ unfinishedResult: unwrapped });
                }
                return withUnwrapped.errorIfSkipLoading(skipLoading, "Computation result");
            }
            if (!isBound(value)) return arb.error(true, "Result is not bound");
            if (isSingleRelationType(type) && isTable(value)) {
                // ##resolveQueryAsRow:
                // Unfortunately we have to special-case the single-relation
                // case here because we don't have a `hb.resolveQueryAsRow`.
                // We're doing the same thing in
                // `NamespaceImpl.resolveQueryWithFixup`.
                const rows = value.asMutatingArray();
                if (rows.length > 1) {
                    return arb.error(true, "Query result has more than one row");
                }
                // This can be `undefined`, but that's ok.  It just means the
                // result is empty.
                value = rows[0];
            }
            if (!isNotEmpty(value)) return arb.nothingToDo("Result is empty");
            const resultValue = value; // to make TS happy
            return async () => arb.withOutputs({ result: resultValue }).success();
        };
    }
}
