import { asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import { TextComponentStyle } from "@glide/component-utils";
import type { WireBigNumbersComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isEmptyOrUndefinedish } from "@glide/support";
import { isBound } from "@glide/computation-model-types";
import { UIBackgroundStyle } from "@glide/wire";
import classNames from "classnames";
import React from "react";
import tw from "twin.macro";

import { css } from "@glide/common-components";
import { Text } from "../../components/text/text";
import { SectionStyleProvider } from "../wire-container/wire-container";
import type { WireRenderer } from "../wire-renderer";
import { defaultWireAppAccentContextOverlayUndo, mergeTheme } from "@glide/theme";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { TailwindThemeProvider } from "@glide/common";
import { runActionAndHandleURL } from "../../wire-lib";

// Zero width space to trick HTML into making space for some text
const ZeroWidthSpaceCharacterNOTEMPTY = "​";

function forceSpace(input: string | undefined): string {
    return input === undefined || input.trim() === "" ? ZeroWidthSpaceCharacterNOTEMPTY : input;
}
export const WireBigNumbers: WireRenderer<WireBigNumbersComponent> = React.memo(props => {
    const { data, title, backend } = props;

    const theme = mergeTheme(useWireAppTheme(), [defaultWireAppAccentContextOverlayUndo]);

    const onlyOne = data.length === 1;
    const onlyTwo = data.length === 2;
    const onlyThree = data.length === 3;

    return (
        <div>
            {!isEmptyOrUndefinedish(title) && (
                <Text variant={TextComponentStyle.headlineMedium} element="h2" tw="mb-4">
                    {title}
                </Text>
            )}
            <SectionStyleProvider value={UIBackgroundStyle.Highlight}>
                <TailwindThemeProvider theme={theme}>
                    <div
                        className={classNames({ onlyOne, onlyTwo, onlyThree })}
                        css={css`
                            &.onlyOne,
                            &.onlyTwo {
                                ${tw`grid-cols-1 gp-sm:grid-cols-2 gp-md:grid-cols-2`}
                            }

                            &.onlyThree {
                                ${tw`grid-cols-2 gp-sm:grid-cols-3 gp-md:grid-cols-3`}
                            }
                        `}
                        tw="grid gap-2 grid-cols-2
                            gp-sm:[grid-template-columns:repeat(auto-fill, minmax(10.125rem, 1fr))]
                            gp-md:(gap-3 [grid-template-columns:repeat(auto-fill, minmax(14.5rem, 1fr))])">
                        {data.map((num, index) => {
                            const hasAction = num.action !== undefined;
                            const value = isBound(num.value) ? asMaybeString(num.value) : undefined;
                            const description = isBound(num.description) ? asMaybeString(num.description) : undefined;
                            const haveValue = !isEmptyOrUndefinedish(value);
                            const haveDescription = !isEmptyOrUndefinedish(description);
                            if (!haveValue && !haveDescription) return undefined;
                            return (
                                <div
                                    data-testid="big-numbers-card"
                                    key={index}
                                    className={classNames(
                                        asMaybeString(num.customCssClassName?.value),
                                        hasAction && "has-action"
                                    )}
                                    onClick={
                                        hasAction ? () => runActionAndHandleURL(num.action, backend, true) : undefined
                                    }
                                    tw="flex flex-col p-4 py-3 gp-md:(p-5 py-4) rounded-xl border border-border-base bg-bg-front overflow-hidden [&.has-action]:(cursor-pointer hover:bg-n100A)">
                                    {isBound(num.name) && (
                                        <Text
                                            element="p"
                                            variant={TextComponentStyle.small}
                                            tw="font-medium tracking-tight truncate text-text-contextual-pale page-md:text-base">
                                            {forceSpace(asMaybeString(num.name ?? ""))}
                                        </Text>
                                    )}
                                    {haveValue && (
                                        <div tw="mt-1 text-xl font-semibold tracking-tight leading-snug truncate page-md:text-2xl text-text-contextual-dark">
                                            {value}
                                        </div>
                                    )}
                                    {haveDescription && (
                                        <Text
                                            element="p"
                                            variant={TextComponentStyle.small}
                                            tw="mt-1 whitespace-pre-wrap text-text-pale page-md:text-base">
                                            {description}
                                        </Text>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </TailwindThemeProvider>
            </SectionStyleProvider>
        </div>
    );
});
