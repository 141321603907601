import type {
    ColumnFlags,
    PathForColumn,
    ColumnBuildMessage,
    Path,
    RelativePath,
    RootPath,
    Handler,
    TableAggregateComputation,
} from "@glide/computation-model-types";
import type { ActionAppEnvironment } from "@glide/common-core/dist/js/components/types";
import type { BasePrimitiveValue } from "@glide/data-types";
import type {
    TableName,
    UniversalTableName,
    SourceColumn,
    TableAndColumn,
    TableColumn,
    TableGlideType,
    SchemaInspector,
    PrimitiveGlideType,
    SpecialValueDescription,
} from "@glide/type-schema";
import type {
    ColumnOrValueSpecification,
    QueryParametersSpecification,
    SingleValuePosition,
} from "@glide/formula-specifications";
import type { ActionProps, SerializablePluginMetadata } from "@glide/plugins";
import type { PluginConfig } from "@glide/app-description";
import type { Mutable } from "utility-types";

export enum BuildResultKind {
    WaitingOnDependency,
    Faulty,
    NotImplemented,
}

export class BuildResult {
    constructor(public readonly kind: BuildResultKind, public readonly message: ColumnBuildMessage) {}
}

export type TableAndColumnInfo = TableAndColumn & PathForColumn;

export type MutableColumnFlags = Mutable<ColumnFlags>;

export type SourceColumnResult = TableAndColumnInfo & {
    readonly inHostRow: boolean;
};

export interface ColumnOrValueResult extends ColumnFlags, Partial<TableAndColumn> {
    readonly tablePath: RootPath | undefined;
    readonly valuePath: Path;
    readonly column: TableColumn | undefined;
}

export interface ColumnOrValuesResultWithType extends ColumnOrValueResult {
    readonly type: PrimitiveGlideType;
}

export interface ParametersResult extends ColumnFlags {
    readonly paramPaths: ReadonlyMap<string, [value: Path, formatted: Path]>;
    readonly tablePaths: readonly RootPath[];
}

export interface ColumnPathsWithThunksFlag {
    // The path of the index.
    readonly valuePath: Path;
    // An additional path to subscribe to, for the index.
    readonly tablePath: RootPath | undefined;
    readonly usesThunks: boolean;
}

export interface AggregateComputationForSingleValuePositionResult {
    readonly computation: TableAggregateComputation<unknown, any>;
    readonly tablePath: RootPath | undefined;
    readonly usesThunks: boolean;
    readonly canBeGlobal: boolean;
}

export interface LookupTableAndColumnOptions {
    readonly withFormat?: boolean; // defaults to `false`
    // old-style relation targets are always looked up as the columns
    // directly, not as potential computed columns.
    readonly oldStyleRelation?: boolean; // defaults to `false`
    readonly countAsDependency?: boolean; // defaults to `true`
    // ##forceBaseColumn:
    // This will go directly to the base column, vs anything computed on
    // top of it.  Will obviously fail if this is not a base column.  We
    // only use this for time-zone conversion right now.
    readonly forceBaseColumn?: boolean; // defaults to `false`
}

export interface PluginComputationData {
    readonly pluginConfig: PluginConfig | undefined;
    readonly computation: ActionProps;
}

export interface ColumnBuilderHelper {
    readonly inspector: SchemaInspector;
    readonly tableBasePaths: ReadonlyMap<TableName, RootPath>;
    readonly appEnvironment: ActionAppEnvironment | undefined;
    readonly pluginMetadata: readonly SerializablePluginMetadata[];

    makeRandomID(): string;

    lookupTableAndColumn(
        tableOrTableName: TableGlideType | UniversalTableName,
        columnName: string,
        opts?: LookupTableAndColumnOptions
    ): TableAndColumnInfo | BuildResult;

    makePathForConstant(value: BasePrimitiveValue): RootPath;
    makePathForSourceColumn(
        // This is the table of the "default context".  In a filter this would
        // be the table of the rows that are filtered.
        contextTable: TableGlideType,
        sc: SourceColumn,
        withFormat: boolean,
        // This is the table for the "containing screen" context.  In a filter
        // over a relation this would be table that the relation is embedded
        // in, so that the filter can refer to the row that contains the
        // relation.
        hostTable: TableGlideType | undefined
    ): SourceColumnResult | BuildResult;
    makePathForColumnOrValue(
        tac: TableAndColumn,
        spec: ColumnOrValueSpecification<BasePrimitiveValue>,
        withFormat: boolean,
        hostTable: TableGlideType | undefined
    ): ColumnOrValueResult | BuildResult;
    makePathsForQueryParameters(
        tac: TableAndColumn,
        params: QueryParametersSpecification,
        withFormat: boolean
    ): ParametersResult | BuildResult;
    makePathForSpecialValue(
        tac: TableAndColumn,
        sv: SpecialValueDescription
    ): ColumnOrValuesResultWithType | BuildResult;

    getPluginComputation(pluginID: string, computationID: string): PluginComputationData | BuildResult;

    combineTablePaths(paths: readonly RootPath[], forCurrentColumnOnly: boolean): RootPath;

    getVerifiedEmailAddressPath(): RootPath;
    getTimestampPath(): RootPath;
    getEmptyArrayPath(): RootPath;
    getCurrentLocationPath(): RootPath;
    getRandomOrderSerialPath(): RootPath;

    getPathsForSingleValueOffset(
        contextTable: TableGlideType | undefined,
        offset: ColumnOrValueSpecification<number>
    ): ColumnPathsWithThunksFlag | BuildResult;
    getStartOrEndOfTodayPath(startOrEnd: "start" | "end"): RootPath;
    getFirstRowOfTable(table: TableGlideType): RootPath;
    getLastRowOfTable(table: TableGlideType): RootPath;
    getAggregateComputationForSingleValuePosition(
        position: SingleValuePosition,
        valuePath: RelativePath | undefined,
        contextTable: TableGlideType | undefined,
        hasCustomOrder: boolean
    ): AggregateComputationForSingleValuePositionResult | BuildResult;
    extractRow(
        table: TableGlideType,
        aggregateComputation: TableAggregateComputation<unknown>,
        forCurrentColumnOnly: boolean
    ): RootPath;

    addEntity(name: string, handler: Handler, forCurrentColumnOnly: boolean): RootPath;
}
