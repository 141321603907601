export const audioProperties: ReadonlyArray<string> = [
    "audio",
    "recording",
    "sound",
    "mp3",
    "mp4",
    "ogg",
    "wav",
    "webm",
    "content",
];
