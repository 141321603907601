import "twin.macro";
import { Toast } from "./toast";
import type { ToastProps } from "./toast";
import { GlideIcon } from "@glide/common";

type Props = {
    onClose: () => void;
    show: boolean;
    position?: ToastProps["position"];
    size?: ToastProps["size"];
};

const learnMoreLink =
    "https://www.glideapps.com/docs/reference/account/classic-apps#migrating-from-classic-apps-to-apps";

export const ClassicAppsDeprecationToast: React.FC<Props> = p => {
    const { onClose, show, size, position } = p;

    return (
        <Toast.Message size={size ?? "lg"} show={show} position={position ?? "bottom-center"} onClose={onClose}>
            <GlideIcon
                kind="stroke"
                icon="st-alert-warning"
                iconSize={24}
                strokeWidth={1.5}
                tw="m-2 text-text-base shrink-0"
            />
            <Toast.Content tw="flex flex-col mr-2 text-builder-lg text-text-base">
                <strong>Classic Apps are no longer supported</strong>
                <p>You can migrate your Classic App to a New Glide App with our migration tool.</p>
            </Toast.Content>
            <Toast.Button
                buttonType="tertiary"
                size="lg"
                label="Try now"
                variant="default"
                href={learnMoreLink}
                tw="shrink-0"
            />
            <Toast.Close onClose={onClose} />
        </Toast.Message>
    );
};
