import type { ComputationModel } from "@glide/computation-model-types";
import {
    type BinaryPredicateOperator,
    type UnaryPredicateOperator,
    ActionNodeKind,
    UnaryPredicateCompositeOperator,
} from "@glide/app-description";
import type { ActionNodeOutputSourceColumn, SpecialValueDescription } from "@glide/type-schema";
import {
    type ColumnType,
    type SourceColumn,
    type TableAndColumn,
    type TableColumn,
    type TableGlideType,
    BinaryPredicateCompositeOperator,
    BinaryPredicateFormulaOperator,
    SourceColumnKind,
    UnaryPredicateFormulaOperator,
    getSourceColumnPath,
    getTableColumnDisplayName,
    isDateOrDateTimeTypeKind,
    isNumberTypeKind,
    isPrimitiveArrayType,
    isPrimitiveType,
    isSingleRelationType,
    isSourceColumn,
    isStringyStringTypeKind,
    makePrimitiveType,
    makeSourceColumn,
    isBigTableOrExternal,
    type SchemaInspector,
    decomposeActionNodeOutputSourceColumn,
    isActionNodeOutputSourceColumn,
} from "@glide/type-schema";
import { type ActionNodeInScope, getColumnForSourceColumnFromEnvironment } from "@glide/function-utils";
import { defined, definedMap, hasOwnProperty } from "@glideapps/ts-necessities";
import last from "lodash/last";
import { isColumnAllowedForFilteringRows } from "../allowed-columns";
import type { ColumnsShown } from "../favorites";
import {
    type DateTimeValueKind,
    BinaryPredicateValueKind,
    type PredicateSpecification,
} from "@glide/formula-specifications";
import type { SpecialValueDescriptor } from "../components/special-values";
import { findSpecialValueDescriptor } from "../components/special-values";
import { handlerForActionKind } from "../actions";

export type OperatorSpec =
    | {
          readonly op: UnaryPredicateOperator;
          readonly name: string | ((c: ColumnType) => string);
          readonly shortName?: string;
          readonly isUnary: true;
          readonly isDate: false;
          readonly isNumber: false;
          readonly allowedForType: (c: ColumnType) => boolean;
      }
    | {
          readonly op: BinaryPredicateOperator;
          readonly name: string | ((c: ColumnType) => string);
          readonly shortName?: string;
          readonly isUnary: false;
          readonly isDate: boolean;
          readonly isDuration: boolean;
          readonly isNumber: boolean;
          readonly allowedInQueryableTable: boolean;
          readonly allowedForType: (c: ColumnType) => boolean;
          // If this isn't defined, then `allowedForType` is also used for the
          // RHS.
          readonly rhsAllowedForType?: (c: ColumnType) => boolean;
      };

export interface DateTimeSpec {
    readonly kind: DateTimeValueKind;
    readonly name: string;
}

function makeNumberComparisonOperator(op: BinaryPredicateOperator, name: string, shortName: string): OperatorSpec {
    return {
        op,
        name,
        shortName,
        isUnary: false,
        isDate: false,
        isDuration: false,
        isNumber: true,
        allowedInQueryableTable: true,
        allowedForType: t => isNumberTypeKind(t.kind),
    };
}

function makeDateTimeComparisonOperator(
    op: BinaryPredicateCompositeOperator,
    name: string,
    isDuration: boolean
): OperatorSpec {
    return {
        op,
        name,
        isUnary: false,
        isDate: true,
        isDuration,
        isNumber: false,
        allowedInQueryableTable: true,
        allowedForType: t => isDateOrDateTimeTypeKind(t.kind),
    };
}

export const operatorSpecs: readonly OperatorSpec[] = [
    {
        op: BinaryPredicateFormulaOperator.Equals,
        name: t => (isNumberTypeKind(t.kind) ? "equals" : "is"),
        isUnary: false,
        isDate: false,
        isDuration: false,
        isNumber: false,
        allowedInQueryableTable: true,
        allowedForType: isPrimitiveType,
    },
    {
        op: BinaryPredicateCompositeOperator.DoesNotEqual,
        name: t => (isNumberTypeKind(t.kind) ? "doesn't equal" : "is not"),
        isUnary: false,
        isDate: false,
        isDuration: false,
        isNumber: false,
        allowedInQueryableTable: true,
        allowedForType: isPrimitiveType,
    },
    {
        op: UnaryPredicateCompositeOperator.IsEmpty,
        name: "is empty",
        isUnary: true,
        isDate: false,
        isNumber: false,
        allowedForType: t => isPrimitiveType(t) || t.kind === "array" || isSingleRelationType(t),
    },
    {
        op: UnaryPredicateFormulaOperator.IsNotEmpty,
        name: "is not empty",
        isUnary: true,
        isDate: false,
        isNumber: false,
        allowedForType: t => isPrimitiveType(t) || t.kind === "array" || isSingleRelationType(t),
    },
    {
        op: BinaryPredicateFormulaOperator.ContainsString,
        name: "includes",
        isUnary: false,
        isDate: false,
        isDuration: false,
        isNumber: false,
        allowedInQueryableTable: true,
        allowedForType: t => isStringyStringTypeKind(t.kind),
    },
    {
        op: BinaryPredicateCompositeOperator.DoesNotContainString,
        name: "doesn't include",
        isUnary: false,
        isDate: false,
        isDuration: false,
        isNumber: false,
        allowedInQueryableTable: true,
        allowedForType: t => isStringyStringTypeKind(t.kind),
    },
    {
        op: BinaryPredicateFormulaOperator.IsContainedInString,
        name: "is included in",
        isUnary: false,
        isDate: false,
        isDuration: false,
        isNumber: false,
        allowedInQueryableTable: true,
        allowedForType: t => isStringyStringTypeKind(t.kind),
    },
    {
        op: BinaryPredicateCompositeOperator.IsNotContainedInString,
        name: "is not included in",
        isUnary: false,
        isDate: false,
        isDuration: false,
        isNumber: false,
        allowedInQueryableTable: true,
        allowedForType: t => isStringyStringTypeKind(t.kind),
    },
    makeNumberComparisonOperator(BinaryPredicateFormulaOperator.IsLessThan, "is less than", "<"),
    makeNumberComparisonOperator(BinaryPredicateFormulaOperator.IsGreaterThan, "is greater than", ">"),
    makeNumberComparisonOperator(BinaryPredicateFormulaOperator.IsLessOrEqualTo, "is less or equal to", "≤"),
    makeNumberComparisonOperator(BinaryPredicateFormulaOperator.IsGreaterOrEqualTo, "is greater or equal to", "≥"),
    makeDateTimeComparisonOperator(BinaryPredicateCompositeOperator.IsWithin, "is within", true),
    makeDateTimeComparisonOperator(BinaryPredicateCompositeOperator.IsBefore, "is before", false),
    makeDateTimeComparisonOperator(BinaryPredicateCompositeOperator.IsAfter, "is after", false),
    makeDateTimeComparisonOperator(BinaryPredicateCompositeOperator.IsOnOrBefore, "is on or before", true),
    makeDateTimeComparisonOperator(BinaryPredicateCompositeOperator.IsOnOrAfter, "is on or after", true),
    {
        op: BinaryPredicateFormulaOperator.ArrayIncludes,
        name: "contains",
        isUnary: false,
        isDate: false,
        isDuration: false,
        isNumber: false,
        allowedForType: isPrimitiveArrayType,
        // ##arrayIncludesInQueries:
        // Not supported yet.  BigQuery has arrays, so it would make sense to
        // implement it there.  Big Glide Tables don't yet support arrays.
        allowedInQueryableTable: false,
        rhsAllowedForType: t => isPrimitiveType(t) || isPrimitiveArrayType(t),
    },
    {
        op: BinaryPredicateCompositeOperator.ArrayDoesNotInclude,
        name: "does not contain",
        isUnary: false,
        isDate: false,
        isDuration: false,
        isNumber: false,
        allowedForType: isPrimitiveArrayType,
        allowedInQueryableTable: false,
        rhsAllowedForType: t => isPrimitiveType(t) || isPrimitiveArrayType(t),
    },
    {
        op: UnaryPredicateCompositeOperator.MatchesVerifiedEmailAddress,
        name: "is signed-in user",
        isUnary: true,
        isDate: false,
        isNumber: false,
        allowedForType: t => t.kind === "email-address" || (t.kind === "array" && t.items.kind === "email-address"),
    },
    {
        op: UnaryPredicateCompositeOperator.DoesNotMatchVerifiedEmailAddress,
        name: "isn't signed-in user",
        isUnary: true,
        isDate: false,
        isNumber: false,
        allowedForType: t => t.kind === "email-address" || (t.kind === "array" && t.items.kind === "email-address"),
    },
    {
        op: UnaryPredicateFormulaOperator.IsTruthy,
        name: "is checked",
        isUnary: true,
        isDate: false,
        isNumber: false,
        allowedForType: t => t.kind === "boolean",
    },
    {
        op: UnaryPredicateCompositeOperator.IsFalsey,
        name: "is not checked",
        isUnary: true,
        isDate: false,
        isNumber: false,
        allowedForType: t => t.kind === "boolean",
    },
];

export const dataTimeDurationSpecs: readonly DateTimeSpec[] = [
    {
        kind: BinaryPredicateValueKind.Today,
        name: "today",
    },
];

export const dateTimeSpecs: readonly DateTimeSpec[] = [
    ...dataTimeDurationSpecs,
    {
        kind: BinaryPredicateValueKind.Now,
        name: "now",
    },
];

export function getDateTimeSpec(kind: DateTimeValueKind): DateTimeSpec {
    return defined(dateTimeSpecs.find(s => s.kind === kind));
}

export function isOperatorAllowed(spec: OperatorSpec, tac: ColumnType | TableAndColumn | undefined): boolean {
    if (tac === undefined) return false;
    if (hasOwnProperty(tac, "kind")) {
        return spec.allowedForType(tac);
    }
    if (!spec.isUnary && !spec.allowedInQueryableTable && isBigTableOrExternal(tac.table)) return false;
    return spec.allowedForType(tac.column.type);
}

export function operatorName(spec: OperatorSpec, column: TableColumn | undefined, short: boolean): string {
    if (short && spec.shortName !== undefined) {
        return spec.shortName;
    }

    if (typeof spec.name === "string") return spec.name;
    if (column === undefined) {
        return spec.name(makePrimitiveType("string"));
    } else {
        return spec.name(column.type);
    }
}

export function getOperatorSpecFrom(
    specs: readonly OperatorSpec[],
    op: UnaryPredicateOperator | BinaryPredicateOperator
): (OperatorSpec & { readonly index: number }) | undefined {
    const index = specs.findIndex(s => s.op === op);
    if (index < 0) return undefined;
    return { ...specs[index], index };
}

// exported for testing
export function getColumnsAllowedForCondition(
    schema: SchemaInspector | undefined,
    table: TableGlideType,
    columnsShown: ColumnsShown,
    forFilteringRows: boolean,
    computationModel: ComputationModel | undefined,
    allowComputedQueryableFilterSort: boolean,
    gbtDeepLookups: boolean
): readonly TableColumn[] {
    return table.columns.filter(c => {
        if (
            !isColumnAllowedForFilteringRows(
                forFilteringRows,
                false,
                schema,
                table,
                c,
                computationModel,
                allowComputedQueryableFilterSort,
                gbtDeepLookups
            )
        ) {
            return false;
        }
        return operatorSpecs.some(s => s.allowedForType(c.type)) && columnsShown.isColumnShown(table, c, false);
    });
}

export function getFirstAllowedOperator(tac: TableAndColumn | ColumnType): OperatorSpec | undefined {
    for (const spec of operatorSpecs) {
        if (isOperatorAllowed(spec, tac)) return spec;
    }
    return undefined;
}

export function makeDefaultPredicate(
    schema: SchemaInspector | undefined,
    table: TableGlideType | undefined,
    containingScreenTable: TableGlideType | undefined,
    columnsShown: ColumnsShown,
    allowComputedQueryableFilterSort: boolean,
    gbtDeepLookups: boolean
): PredicateSpecification | undefined {
    function getDefaultColumn(
        innerTable: TableGlideType | undefined,
        kind: SourceColumnKind
    ): SourceColumn | undefined {
        if (innerTable === undefined) return undefined;

        // We're being lazy here and just make a query as if for filtering
        // rows.
        const filterableColumns = getColumnsAllowedForCondition(
            schema,
            innerTable,
            columnsShown,
            true,
            undefined,
            allowComputedQueryableFilterSort,
            gbtDeepLookups
        );
        if (filterableColumns.length === 0) return undefined;

        const column = filterableColumns.find(c => c.name !== innerTable.rowIDColumn);

        return makeSourceColumn(column?.name ?? filterableColumns[0].name, kind);
    }

    const userProfileTable = definedMap(schema?.userProfileTableInfo?.tableName, tn => schema?.findTable(tn));
    const sourceColumn =
        getDefaultColumn(table, SourceColumnKind.DefaultContext) ??
        getDefaultColumn(userProfileTable, SourceColumnKind.UserProfile) ??
        getDefaultColumn(containingScreenTable, SourceColumnKind.ContainingScreen);
    if (sourceColumn === undefined) return;

    return {
        kind: "unary",
        column: sourceColumn,
        operator: UnaryPredicateFormulaOperator.IsNotEmpty,
    };
}

function getTableColumn(
    column: SourceColumn,
    schemaInspector: SchemaInspector | undefined,
    table: TableGlideType | undefined,
    containingScreenTable: TableGlideType | undefined,
    actionNodesInScope: ActionNodeInScope[]
): TableColumn | undefined {
    if (schemaInspector === undefined) return undefined;
    return getColumnForSourceColumnFromEnvironment(column, {
        context: schemaInspector,
        defaultTable: table,
        containingScreenTable: containingScreenTable,
        actionNodesInScope,
    });
}

function getOperatorSpec(
    op: UnaryPredicateOperator | BinaryPredicateOperator
): OperatorSpec & { readonly index: number } {
    return defined(getOperatorSpecFrom(operatorSpecs, op));
}

function getActionNodeOutputName(
    schemaInspector: SchemaInspector | undefined,
    sourceColumn: ActionNodeOutputSourceColumn,
    actionNodesInScope: ActionNodeInScope[]
): string {
    const decomposed = decomposeActionNodeOutputSourceColumn(sourceColumn);
    if (decomposed === undefined) return "";
    const [actionNodeKey, outputName, columnInRow] = decomposed;
    const actionNodeInScope = actionNodesInScope.find(a => a.node.key === actionNodeKey);
    if (actionNodeInScope === undefined) return "";
    const { node, outputs } = actionNodeInScope;

    if (columnInRow !== undefined) {
        const maybeColumn = getTableColumn(sourceColumn, schemaInspector, undefined, undefined, actionNodesInScope);
        if (maybeColumn === undefined) {
            return columnInRow;
        }

        return getTableColumnDisplayName(maybeColumn);
    }

    if (node.customTitle !== undefined) {
        return node.customTitle;
    }

    if (node.kind === ActionNodeKind.Primitive) {
        return handlerForActionKind(node.actionDescription.kind).name;
    }

    const output = outputs.find(o => o.name === outputName);
    if (output === undefined) {
        return outputName;
    }

    return getTableColumnDisplayName(output);
}

function getColumnDisplayName(
    schemaInspector: SchemaInspector | undefined,
    column: TableColumn | undefined,
    sourceColumn: SourceColumn,
    actionNodesInScope: ActionNodeInScope[]
): string {
    if (column !== undefined) {
        return getTableColumnDisplayName(column);
    }

    if (isActionNodeOutputSourceColumn(sourceColumn)) {
        return getActionNodeOutputName(schemaInspector, sourceColumn, actionNodesInScope);
    }

    return last(getSourceColumnPath(sourceColumn)) ?? "?";
}

function getSpecialValueDisplayName(
    specialValueDescriptors: readonly SpecialValueDescriptor[],
    value: SpecialValueDescription
): string {
    const descriptor = findSpecialValueDescriptor(specialValueDescriptors, value);
    return descriptor?.label ?? "";
}

export function tokenizePredicate(
    item: PredicateSpecification,
    schemaInspector: SchemaInspector | undefined,
    table: TableGlideType | undefined,
    containingScreenTable: TableGlideType | undefined,
    actionNodesInScope: ActionNodeInScope[],
    specialValueDescriptors: readonly SpecialValueDescriptor[]
): readonly [string, string] | [string, string, string] {
    if (item.kind === "unary") {
        const { column: sourceColumn, operator } = item;
        const filterOp = getOperatorSpec(operator);
        if (!isSourceColumn(sourceColumn)) {
            return [
                getSpecialValueDisplayName(specialValueDescriptors, sourceColumn.specialValue),
                operatorName(filterOp, undefined, false),
            ];
        }
        const column = getTableColumn(sourceColumn, schemaInspector, table, containingScreenTable, actionNodesInScope);
        return [
            getColumnDisplayName(schemaInspector, column, sourceColumn, actionNodesInScope),
            operatorName(filterOp, column, false),
        ];
    } else {
        const { column: sourceColumn, operator, value } = item;

        const column = isSourceColumn(sourceColumn)
            ? getTableColumn(sourceColumn, schemaInspector, table, containingScreenTable, actionNodesInScope)
            : undefined;
        const filterOp = getOperatorSpec(operator);
        let filterValue: string;
        if (value.kind === BinaryPredicateValueKind.Literal) {
            filterValue = value.value.toString();
        } else if (isSourceColumn(value)) {
            filterValue = getColumnDisplayName(
                schemaInspector,
                getTableColumn(value, schemaInspector, table, containingScreenTable, actionNodesInScope),
                value,
                actionNodesInScope
            );
        } else {
            filterValue = getDateTimeSpec(value.kind).name;
        }

        const displayName = isSourceColumn(sourceColumn)
            ? getColumnDisplayName(schemaInspector, column, sourceColumn, actionNodesInScope)
            : getSpecialValueDisplayName(specialValueDescriptors, sourceColumn.specialValue);
        return [displayName, operatorName(filterOp, column, false), filterValue];
    }
}
