import chroma from "chroma-js";
import tw from "twin.macro";

import { css, authThemeAccent, authThemeDark, authThemeHighlight } from "@glide/common-components";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import classNames from "classnames";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    label: string | React.ReactNode;
    details?: string | React.ReactNode;
    checked: boolean;
    onCheck: (val: boolean) => void;
    className?: string;
}

export const WireCheckbox: React.VFC<Props> = p => {
    const { label, checked, onCheck, details, className } = p;

    const statusClassName = checked ? "checked" : "unchecked";

    const theme = useWireAppTheme();

    return (
        <label tw="flex items-start">
            <input
                type="checkbox"
                onChange={e => onCheck(e.target.checked)}
                onClick={e => e.stopPropagation()}
                checked={checked}
                tw="mt-0.5 w-4 h-4 rounded flex justify-center items-center mr-2 cursor-pointer transition shrink-0"
                className={classNames(statusClassName, className)}
                css={css`
                    background-position: center;
                    background-repeat: no-repeat;
                    &.checked {
                        ${tw`bg-accent`}
                        box-shadow: 0px 0px 0px 1px rgba(62, 65, 86, 0.2), 0px 0px 0px 1px ${theme.primaryAccentColor};
                        background-image: url("data:image/svg+xml;utf8, <svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3.90042 7.29052C3.613 7.5965 3.14871 7.5965 2.86129 7.29052L0.215564 4.47395C-0.0718547 4.16797 -0.0718547 3.6737 0.215564 3.36772C0.502983 3.06174 0.967274 3.06174 1.25469 3.36772L3.37717 5.62725L8.44753 0.229484C8.73495 -0.0764945 9.19924 -0.0764945 9.48666 0.229484C9.77408 0.535462 9.77408 1.02973 9.48666 1.33571L3.90042 7.29052Z' fill='white' /></svg>");
                    }
                    &.unchecked {
                        ${tw`transition-none bg-white`}
                        box-shadow: ${theme.shadowXdark0};
                        :focus-visible {
                            box-shadow: 0px 0px 0px 3px ${chroma(theme.primaryAccentColor).alpha(0.2).css()},
                                0px 0px 0px 1px ${theme.primaryAccentColor};
                        }
                        :hover {
                            box-shadow: 0px 0px 0px 1px ${chroma(theme.n700).alpha(0.36).css()};
                        }
                    }

                    .${authThemeHighlight} &.unchecked,
                    .${authThemeAccent} &.unchecked {
                        ${tw`bg-transparent`}
                        box-shadow: 0px 0px 0px 1px ${chroma(theme.n300A).css()};
                        :focus-visible {
                            box-shadow: 0px 0px 0px 3px ${chroma(theme.primaryAccentColor).alpha(0.2).css()},
                                0px 0px 0px 1px ${theme.primaryAccentColor};
                        }
                        :hover {
                            box-shadow: 0px 0px 0px 1px ${chroma(theme.n300A).css()};
                        }
                    }

                    .${authThemeAccent}.mobile &.checked {
                        ${tw`bg-white text-[theme.primaryAccentColor]`}
                        background-image: url("data:image/svg+xml;utf8, <svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3.90042 7.29052C3.613 7.5965 3.14871 7.5965 2.86129 7.29052L0.215564 4.47395C-0.0718547 4.16797 -0.0718547 3.6737 0.215564 3.36772C0.502983 3.06174 0.967274 3.06174 1.25469 3.36772L3.37717 5.62725L8.44753 0.229484C8.73495 -0.0764945 9.19924 -0.0764945 9.48666 0.229484C9.77408 0.535462 9.77408 1.02973 9.48666 1.33571L3.90042 7.29052Z' fill='${encodeURIComponent(
                            theme.primaryAccentColor
                        )}' /></svg>");
                    }
                    .${authThemeAccent}.mobile &.unchecked {
                        ${tw`bg-accent`}
                    }

                    .has-background-image &.unchecked,
                    .${authThemeDark} &.unchecked,
                    .${authThemeAccent}.mobile &.unchecked {
                        ${tw`bg-transparent`}
                        box-shadow: 0px 0px 0px 1px ${chroma(theme.w30A).css()};
                        :focus-visible {
                            box-shadow: 0px 0px 0px 3px ${chroma(theme.primaryAccentColor).alpha(0.2).css()},
                                0px 0px 0px 1px ${theme.primaryAccentColor};
                        }
                        :hover {
                            box-shadow: 0px 0px 0px 1px ${chroma(theme.w30A).css()};
                        }
                    }

                    .${authThemeDark} &.checked,
                    .${authThemeHighlight} &.checked,
                    .${authThemeAccent} &.checked {
                        box-shadow: none;
                    }
                `}
            />
            <div>
                <div
                    css={css`
                        ${tw`text-sm leading-5 text-n700`}

                        .${authThemeAccent}.mobile & {
                            ${tw`text-w80A`}
                        }
                        .${authThemeAccent}.mobile & a {
                            ${tw`text-w50A border-[currentColor]`}
                        }
                    `}>
                    {label}
                </div>
                {details !== undefined && <div tw="mt-1 text-xs text-n500 leading-4">{details}</div>}
            </div>
        </label>
    );
};
