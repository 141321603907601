import { PagePreviewDevice } from "@glide/common-core/dist/js/render/form-factor";
import classNames from "classnames";
import { css } from "styled-components";
import tw from "twin.macro";

import { usePreviewerState } from "./usePreviewerState";

export const Title = tw.span`text-builder-xl leading-4 font-extrabold text-n900`;

export const Subtitle = tw.span`text-builder-xs leading-4 text-n600A`;

export const TemplateInfoPad = () => {
    const { previewDevice } = usePreviewerState();
    const tablet = previewDevice === PagePreviewDevice.Desktop;

    return (
        <div
            css={css`
                &.hidden {
                    ${tw`hidden`}
                }
            `}
            className={classNames({ hidden: tablet })}
            tw="flex flex-col w-60 absolute transform[translateX(150%)]">
            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20 34.3335V42.6669M20 9.3335V17.6668M12.783 30.1668L5.56616 34.3335M34.4337 17.6668L27.2168 21.8335M12.783 21.8335L5.56617 17.6668M34.4337 34.3335L27.2168 30.1668"
                    stroke="#36374F"
                    strokeOpacity="0.8"
                    strokeWidth="3"
                />
                <g filter="url(#filter0_dd_346_13870)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24.303 22.2865L46.87 30.4255C50.9744 31.9057 51.0646 37.7473 47.008 39.3567L46.2139 39.6718C41.4125 41.5765 37.5922 45.3894 35.6433 50.2217L35.3159 51.0336C33.6928 55.058 28.0308 54.9656 26.5367 50.8905L18.3044 28.4372C16.9125 24.6408 20.5342 20.9273 24.303 22.2865ZM46.0793 32.6713C48.1315 33.4115 48.1766 36.3323 46.1482 37.137L45.3541 37.452C39.9527 39.5949 35.6548 43.8844 33.4623 49.3207L33.1349 50.1326C32.3233 52.1448 29.4923 52.0986 28.7452 50.061L20.5129 27.6077C19.817 25.7095 21.6279 23.8528 23.5123 24.5324L46.0793 32.6713Z"
                        fill="white"
                    />
                    <path
                        d="M46.1482 37.137C48.1765 36.3323 48.1314 33.4115 46.0792 32.6713L23.5122 24.5324C21.6278 23.8528 19.8169 25.7096 20.5129 27.6077L28.7452 50.061C29.4923 52.0986 32.3233 52.1448 33.1348 50.1326L33.4623 49.3207C35.6548 43.8844 39.9526 39.5949 45.3541 37.452L46.1482 37.137Z"
                        fill="#12CCE5"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_dd_346_13870"
                        x="6"
                        y="16"
                        width="56"
                        height="56"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="6" />
                        <feGaussianBlur stdDeviation="6" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.243137 0 0 0 0 0.254902 0 0 0 0 0.337255 0 0 0 0.1 0"
                        />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_346_13870" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="0.5" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.243137 0 0 0 0 0.254902 0 0 0 0 0.337255 0 0 0 0.25 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="effect1_dropShadow_346_13870"
                            result="effect2_dropShadow_346_13870"
                        />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_346_13870" result="shape" />
                    </filter>
                </defs>
            </svg>
            <Title tw="pb-1 text-n700A">Try it live</Title>
            <span tw="text-builder-base text-n700A">
                You can try this template
                <br />
                using your mouse or trackpad.
            </span>
        </div>
    );
};

export const PageContainer = tw.div`w-[100vw] h-full relative shadow-md-dark overflow-hidden transition[width 150ms ease]`;
