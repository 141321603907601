import * as React from "react";
import type { Subtract } from "utility-types";

export const ScrollOverlayIDContext = React.createContext<string>("this-is-not-an-id");
export const ScrollOverlayConsumer = ScrollOverlayIDContext.Consumer;
export const ScrollOverlayProvider = ScrollOverlayIDContext.Provider;

interface WithScrollOverlayIDProps {
    scrollOverlayID: string;
}

export const withScrollOverlayID = <P extends WithScrollOverlayIDProps>(Component: React.ComponentType<P>) =>
    class WithScrollOverlayID extends React.Component<Subtract<P, WithScrollOverlayIDProps>> {
        public render() {
            return (
                <ScrollOverlayConsumer>
                    {id => <Component {...(this.props as P)} scrollOverlayID={id} />}
                </ScrollOverlayConsumer>
            );
        }
    };
