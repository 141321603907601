import type { BasePrimitiveValue } from "@glide/data-types";
import {
    type GroundValue,
    type LoadingValue,
    type Row,
    type Table,
    type RelativePath,
    type RootPath,
    isKeyPath,
    isTopLevelPath,
    makeKeyPath,
    isBaseRowIndex,
    type Query,
    unwrapLoadingValue,
} from "@glide/computation-model-types";
import { getRowColumn } from "@glide/common-core/dist/js/computation-model/data";
import {
    type TableName,
    areTableNamesEqual,
    isTableName,
    nativeTableRowIDColumnName,
    rowIndexColumnName,
    type TableGlideType,
    getTableColumn,
    getTableName,
    isColumnWritable,
} from "@glide/type-schema";
import { type InputOutputTables, makeInputOutputTables } from "@glide/common-core/dist/js/description";
import type { WireFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import { makeUniqueIDForArrayScreen } from "@glide/generator/dist/js/builder-utils";
import type {
    HydratedRowContext,
    WireActionRunner,
    WireAlwaysEditableValue,
    WireComponentHydrationResult,
    WireComponentHydratorWithID,
    WireComponentWithFlags,
    WireHydratedSubComponents,
    WireHydrationBackend,
    WireRowActionHydrationValueProvider,
    WireRowComponentHydrationBackend,
    WireRowHydrationValueProvider,
    WireTableComponentHydrationBackend,
    WireTableTransformValueProvider,
    HydratedScreenContext,
    ValueChangeFollowUp,
    WireActionBackend,
    WireComponentEditor,
    WireScreenStateProvider,
    WireSubComponentsGetter,
    WireScreen,
    WireSubsidiaryScreen,
    NavigationPath,
    ParsedScreen,
    WireComponent,
    WireEditableValue,
    WireScreenPosition,
    ActiveScope,
} from "@glide/wire";
import { AppKind } from "@glide/location-common";
import { ConditionVariable, RecurrentBackgroundJob, cancellableSleep, shallowEqualArrays } from "@glide/support";
import { type DefaultMap, assert, defined, definedMap, panic, sleep } from "@glideapps/ts-necessities";
import fromPairs from "lodash/fromPairs";

import {
    addSubscriptionHandler,
    emptyHydratedScreenContext,
    makeHydratedScreenContext,
    makeMutableSubscriptionSources,
} from "./internal";
import type {
    ActionHydrationContext,
    BuilderTableData,
    ComponentState,
    EffectSubscription,
    InternalTab,
    MutableSubscriptionSources,
    OnChangeFollowUp,
    ScreenHydrationContext,
    StateValuesMap,
    SubscriptionHandlerAndPath,
    SubscriptionInfo,
    SubscriptionSources,
    ValueProviderActionContext,
    ValueProviderContext,
} from "./internal-types";
import { type SubscriptionHandler, makeSubscriptionHandler } from "./subscription";
import { mapMerge } from "collection-utils";
import { addGlobalKey, resolveQueryAsTable } from "./resolve-query";
import { follow } from "@glide/computation-model";

export type InternalTabHydrator = (hb: RowHydrationValueProvider<ValueProviderActionContext>) => InternalTab;

// TODO: State management as we do it right now is a bit of a mess.  Each
// component has its own state manager, and for list components we use name
// and key prefixes to disambiguate between rows.
//
// A proper way to manage state would be to have a single state tree, where
// each component, or sub-row, or whatever, can claim a sub-tree.  We can load
// individual keys within that tree on demand from local storage.  I'm not
// sure how to do the "dirtying", but it might be as simple as having a bit on
// each sub-tree that replaces the `stateChanged` bit that we currently have
// on the `ComponentState`.
export class StateManager {
    public readonly stateValues: StateValuesMap = new Map();

    constructor(public readonly oldStateValues: StateValuesMap | undefined) {}

    public getState<T>(
        // The `name` must be unique within the hydration unit
        name: string,
        // The `token` must be completely unique
        token: string,
        validate: ((v: unknown) => v is T) | undefined,
        defaultValue: T,
        shouldSave: boolean,
        debounceMS: number | undefined
    ): WireAlwaysEditableValue<T> {
        if (shouldSave) {
            assert(validate !== undefined);
        } else if (validate === undefined) {
            validate = (_v): _v is T => true;
        }

        const newExisting = this.stateValues.get(name);
        if (newExisting !== undefined) {
            const [v, t] = newExisting;
            assert(validate(v));
            return { value: v, onChangeToken: t };
        }

        let value: T;
        // It's important that we only take the value of the old state, and
        // not its token for reuse.  State loading gives us a dummy token, and
        // using that would cause weird effects.
        const existing = this.oldStateValues?.get(name)?.[0];
        if (existing !== undefined && validate(existing)) {
            value = existing;
        } else {
            value = defaultValue;
        }
        this.stateValues.set(name, [value, token, shouldSave]);
        return { value, onChangeToken: token, debounceMS };
    }
}

function getScreenState<T extends GroundValue>(
    context: ScreenHydrationContext,
    sources: MutableSubscriptionSources,
    tokens: Set<string>,
    name: string,
    validate: (v: GroundValue) => v is T,
    defaultValue: T
): WireEditableValue<T> {
    if (context.screenStateKeeper === undefined) {
        return {
            value: defaultValue,
            onChangeToken: undefined,
        };
    }

    const rowID = `${context.screenKey}-${name}`;
    const [stateKeeper, statePath] = context.screenStateKeeper;
    let row = stateKeeper.table.get(rowID);

    if (row === undefined) {
        row = {
            $rowID: rowID,
            $isVisible: true,
        };
        stateKeeper.addRow(row);
    }

    const groundValue = definedMap(row, r => getRowColumn(r, "value"));
    const value = validate(groundValue) ? groundValue : defaultValue;

    sources.columnsInRows.get(statePath.rest.key).get(rowID).add("value");

    const token = `screenState-${rowID}`;

    tokens.add(token);
    context.onChangeValueRows.set(token, {
        kind: "column",
        source: {
            kind: "keeper",
            keeper: stateKeeper,
        },
        columnName: "value",
        row,
        followUp: undefined,
    });

    return {
        value,
        onChangeToken: token,
    };
}

function getParsedPath(sources: MutableSubscriptionSources, context: ValueProviderActionContext): NavigationPath {
    sources.needs = { ...sources.needs, parsedPath: true };
    return context.parsedPath;
}

function getIsOnline(sources: MutableSubscriptionSources, context: ValueProviderActionContext): boolean {
    sources.needs = { ...sources.needs, online: true };
    return context.isOnline;
}

const rowIndexPath = makeKeyPath(rowIndexColumnName);

// In Google Sheets, without a row ID, newly added rows don't have row indexes
// yet, so we can't edit them yet because we wouldn't know where those edits
// should go.  Thus, we can edit a row if it has a "real" row ID, i.e. one
// that's written to the table, or if it has a row index.
function isRowIdentified(
    hb: WireRowHydrationValueProvider,
    context: ScreenHydrationContext,
    tables: InputOutputTables | undefined,
    outputRow: boolean
): boolean {
    const table = outputRow ? tables?.output : tables?.input;
    if (table === undefined) return false;
    // The input row poses as the output row in many cases.
    const row = (outputRow ? hb.rowContext?.outputRow : undefined) ?? hb.rowContext?.inputRows[0];
    if (row === undefined) return false;
    const rootPath = context.getBaseRootPathForTable(getTableName(table));
    if (rootPath === undefined) return false;

    // We use invisible rows in add/edit/form screens, where they are always
    // editable.
    if (!row.$isVisible) return true;
    if (table.rowIDColumn !== undefined) return true;
    const rowIndex = hb.getColumnInRow(getTableName(table), rootPath, row, rowIndexPath);
    return isBaseRowIndex(rowIndex);
}

abstract class LoadingValueUnwrapper {
    constructor(protected readonly useLoadingDisplayValues: boolean) {}

    // This is an arrow function because we're passing it around.
    protected unwrap = (v: GroundValue): GroundValue => {
        if (this.useLoadingDisplayValues) {
            return unwrapLoadingValue(v);
        } else {
            return v;
        }
    };
}

abstract class HydrationValueProvider<TContext extends ValueProviderContext>
    extends LoadingValueUnwrapper
    implements WireRowHydrationValueProvider
{
    protected readonly sources = makeMutableSubscriptionSources();
    protected readonly stateManager: StateManager;

    constructor(
        public readonly context: TContext,
        public readonly rowContext: HydratedRowContext | undefined,
        oldStateValues: StateValuesMap | undefined,
        // Technically only needs to be `public` in
        // `RowComponentHydrationBackend`, but it doesn't hurt.
        public readonly stateSaveKey: string | undefined,
        protected readonly componentID: string | undefined,
        useLoadingDisplayValues: boolean,
        public readonly activeScopes: readonly ActiveScope[]
    ) {
        super(useLoadingDisplayValues);

        this.stateManager = new StateManager(oldStateValues);

        context.registerObjectToRetire(this);
    }

    public abstract makeHydrationBackendForRow(
        inputRow: Row | undefined,
        outputRow: Row | undefined,
        tables: InputOutputTables | undefined
    ): WireRowHydrationValueProvider;

    public getState<T>(
        name: string,
        validate: (v: unknown) => v is T,
        defaultValue: T,
        shouldSave: boolean,
        debounceMS: number | undefined
    ): WireAlwaysEditableValue<T> {
        return this.stateManager.getState(
            name,
            `${this.componentID}-${this.rowContext?.inputRows[0]?.$rowID}-${name}`,
            validate,
            defaultValue,
            shouldSave,
            debounceMS
        );
    }

    public getGlobalValue(tableName: TableName | undefined, path: RootPath, column: string | true): GroundValue {
        addGlobalKey(this.sources, path.rest.key, column);
        if (tableName !== undefined) {
            this.context.fetchTableData(tableName);
        }

        return this.unwrap(this.context.namespace?.get(path));
    }

    public getColumnInRow(tableName: TableName, rootPath: RootPath, row: Row, columnPath: RelativePath): GroundValue {
        assert(isTopLevelPath(rootPath));
        assert(isKeyPath(columnPath) && columnPath.rest === undefined);

        this.sources.columnsInRows.get(rootPath.rest.key).get(row.$rowID).add(columnPath.key);
        this.context.fetchTableData(tableName);

        // ##getWhileHydrating:
        // If we didn't do this, the values in `row` would be out of date. The
        // fact that we subscribe doesn't help us, because the subscription
        // handler might not get any dirt pushed if this handler was already
        // dirty.  Also, when we run an action we might get a "loading" value,
        // which would cause us to run the action even though we should wait.
        this.context.namespace?.get(rootPath);

        return this.unwrap(follow(row, columnPath));
    }

    public resolveQueryAsTable(query: Query): Table | LoadingValue | undefined {
        return resolveQueryAsTable(query, this.context, this.sources, this.unwrap);
    }

    abstract listenForChanges(timeoutMS: number | undefined, getData: () => boolean): Promise<boolean>;
}

abstract class HydrationBackendBase<TContext extends ValueProviderActionContext>
    extends HydrationValueProvider<TContext>
    implements WireHydrationBackend
{
    // TODO: Maybe this doesn't belong in here and should be abstracted.
    protected readonly tokens = new Set<string>();

    protected addToken(token: string): void {
        this.tokens.add(token);
    }

    public registerAction(key: string, run: WireActionRunner): string {
        const token = `${this.stateSaveKey}-${key}`;
        this.context.actions.set(token, [run, this.rowContext ?? emptyHydratedScreenContext]);
        this.addToken(token);
        return token;
    }
}

function makeSubscription(
    subscriptionName: string,
    onChange: () => void,
    context: ValueProviderActionContext,
    sources: SubscriptionSources,
    tokens: ReadonlySet<string>,
    subsidiaries: readonly SubscriptionInfo[]
): SubscriptionInfo {
    let handlerAndPath: SubscriptionHandlerAndPath | undefined;
    if (context.namespace !== undefined) {
        const handler = makeSubscriptionHandler(sources, onChange);
        if (handler !== undefined) {
            const path = addSubscriptionHandler(context, subscriptionName, handler);
            handlerAndPath = { handler, path };
        }
    }
    return {
        isComponentState: false,
        handlerAndPath,
        tokens,
        needs: sources.needs,
        effect: undefined,
        subsidiaries,
    };
}

async function listenForChanges<T extends SubscriptionInfo>(
    timeoutMS: number | undefined,
    // NOTE: This callback has to always subscribe to everything it might
    // need, meaning it can't use ##shortCircuitPredicateEvaluation.
    // TODO: Ideally we shouldn't require this.  A better way to do it would
    // be to re-subscribe on every evaluation.
    getData: () => boolean,
    subscribe: (name: string, onChange: () => void) => T,
    acknowledge: (si: T) => void,
    retireSubscriptionInfo: (si: T) => void
): Promise<boolean> {
    // We need to do this first once to collect everything we have to
    // subscribe to.
    if (getData()) {
        return true;
    }

    const getDataCV = new ConditionVariable();
    let gotData = false,
        timedOut = false;
    // The below is like throttle(() => {}, 0, { trailing: true }), except we're more
    // confident in its behavior than what `throttle` might do.
    //
    // Specifically, we want the subscription to only fire getData once in a big old change loop.
    // You can hammer this same subscriber a few thousand times, and so long as you hit it in
    // the thousands of times before `getData` gets run, `getData` gets run exactly once.
    //
    // `throttle` might do this but that's depending on an undocumented implementation detail.
    // What if they start using `performance.now()` on top of the presumed `setTimeout`? Then
    // it's useless. This way, we _know_ what the behavior is going to be.

    const getDataJob = new RecurrentBackgroundJob(async () => {
        // Make sure we're not immediately invoking getData() on request.
        await sleep(1);
        gotData = getData();
        // If we don't acknowledge then the subscription will stay in a dirty
        // state and won't fire again.
        acknowledge(si);
        if (gotData === true) {
            getDataCV.notifyAll();
        }
    });
    const si = subscribe("listenForChanges", () => getDataJob.request());
    const ac = new AbortController();
    getDataJob.request();
    while (!gotData && !timedOut) {
        await Promise.race([
            getDataCV.wait(),
            timeoutMS === undefined
                ? undefined
                : cancellableSleep(timeoutMS, ac.signal).then(() => {
                      timedOut = true;
                  }),
        ]);
    }
    ac.abort();
    retireSubscriptionInfo(si);
    return gotData;
}

interface SubscriptionInfoWithQueryCallback extends SubscriptionInfo {
    onQueryChange: () => void;
}

export class RowHydrationValueProvider<TContext extends ValueProviderActionContext>
    extends HydrationBackendBase<TContext>
    implements WireRowHydrationValueProvider
{
    private subsidiaries: RowHydrationValueProvider<TContext>[] = [];
    private additionalSubscriptionInfo: SubscriptionInfo[] = [];

    public getParsedPath(): NavigationPath {
        return getParsedPath(this.sources, this.context);
    }

    public getIsOnline(): boolean {
        return getIsOnline(this.sources, this.context);
    }

    protected addSubsidiary(subsidiary: RowHydrationValueProvider<TContext>): void {
        this.subsidiaries.push(subsidiary);
    }

    public addSubscriptionInfo(si: SubscriptionInfo): void {
        this.additionalSubscriptionInfo.push(si);
    }

    public makeHydrationBackendForRow(
        inputRow: Row | undefined,
        outputRow: Row | undefined,
        _tables: InputOutputTables | undefined
    ): WireRowHydrationValueProvider {
        const rowContext = makeHydratedScreenContext(inputRow, outputRow, this.rowContext?.containingScreenRow);
        const hb = new RowHydrationValueProvider(
            this.context,
            rowContext,
            this.stateManager.oldStateValues,
            this.stateSaveKey,
            this.componentID,
            this.useLoadingDisplayValues,
            this.activeScopes
        );
        this.addSubsidiary(hb);
        return hb;
    }

    // TODO: It's very odd that this class handles state, but then also offers
    // subscription without them.  It seems this needs to be broken down
    // further.
    //
    // `subscriptionName` is only used for debugging.
    // FIXME: this `onChange` also has to run when the queries listened to update
    public subscribe(subscriptionName: string, onChange: () => void): SubscriptionInfo {
        return makeSubscription(subscriptionName, onChange, this.context, this.sources, this.tokens, [
            ...this.subsidiaries.map((s, i) => s.subscribe(`${subscriptionName}-${i}`, onChange)),
            ...this.additionalSubscriptionInfo,
        ]);
    }

    public subscribeComponent(
        c: WireComponentWithFlags | undefined,
        subsidiaryScreen: WireScreen | undefined,
        editor: WireComponentEditor | undefined,
        additionalInputs: readonly unknown[],
        builderTableData: Record<string, BuilderTableData> | undefined,
        subscriptionName: string,
        _oldSubscriptionInfo: SubscriptionInfo | undefined,
        onChange: () => void
    ): ComponentState {
        return {
            ...this.subscribe(subscriptionName, onChange),
            isComponentState: true,
            stateValues: mapMerge(this.stateManager.oldStateValues ?? new Map(), this.stateManager.stateValues),
            stateSaveKey: this.stateSaveKey,
            stateChanged: false,
            isValid: c?.isValid ?? true,
            editsInContext: c?.editsInContext,
            hasValue: c?.hasValue,
            canBeSearched: c?.canBeSearched ?? false,
            editor,
            subComponentStates: {},
            additionalInputs,
            builderTableData,
            subsidiaryScreen,
            effect: undefined,
        };
    }

    public async listenForChanges(timeoutMS: number | undefined, getData: () => boolean): Promise<boolean> {
        return listenForChanges<SubscriptionInfoWithQueryCallback>(
            timeoutMS,
            getData,
            (name, onChange) => {
                this.context.addQueryChangedCallback(onChange);
                return {
                    ...this.subscribe(name, onChange),
                    onQueryChange: onChange,
                };
            },
            si => {
                if (si.handlerAndPath === undefined) return;
                this.context.namespace?.get(si.handlerAndPath.path);
            },
            si => {
                this.context.removeQueryChangedCallback(si.onQueryChange);
                this.context.retireSubscriptionInfo(si);
            }
        );
    }
}

export class RowActionHydrationBackend<TContext extends ActionHydrationContext>
    extends RowHydrationValueProvider<TContext>
    implements WireRowActionHydrationValueProvider
{
    public getIsUserSignedIn(): boolean {
        const email = definedMap(this.context.verifiedEmailAddressPath, p => this.getGlobalValue(undefined, p, true));
        return email !== undefined;
    }

    public getIsTabVisible(tabScreenName: string): boolean {
        return this.context.tabScreenVisibilityPredicates.get(tabScreenName)?.(this) === true;
    }

    public getIsModalVisible(): boolean {
        return this.context.parsedPath.getParsedScreens().modal !== undefined;
    }

    public fetchTable(tableName: TableName): void {
        this.context.fetchTableData(tableName);
    }

    public makeHydrationBackendForRow(
        inputRow: Row | undefined,
        outputRow: Row | undefined,
        _tables: InputOutputTables | undefined
    ): WireRowActionHydrationValueProvider {
        const rowContext = makeHydratedScreenContext(inputRow, outputRow, this.rowContext?.containingScreenRow);
        // TODO: This should probably add more info to the `componentID` to
        // disambiguate.
        const hb = new RowActionHydrationBackend(
            this.context,
            rowContext,
            this.stateManager.oldStateValues,
            this.stateSaveKey,
            this.componentID,
            this.useLoadingDisplayValues,
            this.activeScopes
        );
        this.addSubsidiary(hb);
        return hb;
    }

    public makeHydrationBackendForAction(): WireRowActionHydrationValueProvider {
        if (!this.useLoadingDisplayValues) return this;
        const hb = new RowActionHydrationBackend(
            this.context,
            this.rowContext,
            this.stateManager.oldStateValues,
            this.stateSaveKey,
            this.componentID,
            false,
            this.activeScopes
        );
        this.addSubsidiary(hb);
        return hb;
    }
}

interface ComponentSubscriber {
    subscribeComponent(
        c: WireComponentWithFlags | undefined,
        subsidiaryScreen: WireScreen | undefined,
        editor: WireComponentEditor | undefined,
        additionalInputs: readonly unknown[],
        builderTableData: Record<string, BuilderTableData> | undefined,
        subscriptionName: string,
        oldSubscriptionInfo: SubscriptionInfo | undefined,
        onChange: () => void
    ): ComponentState;
}

export interface HydrationResult {
    // These are redundant, for convenience
    readonly componentWithFlags: WireComponentHydrationResult | undefined;
    readonly component: WireComponent | null;
    readonly subsidiaryScreen: WireScreen | undefined;
    readonly hb: ComponentSubscriber | undefined;
    readonly oldComponentState: ComponentState | undefined;
    readonly builderTableData: Record<string, BuilderTableData> | undefined;
}

export interface HydrationHelper {
    hydrateSubComponents(
        name: string,
        hydrators: readonly WireComponentHydratorWithID[],
        tables: InputOutputTables | undefined,
        rowContext: HydratedRowContext,
        getSubComponents: WireSubComponentsGetter
    ): readonly HydrationResult[];
    subscribeSubComponents(results: readonly HydrationResult[], superComponentName: string): readonly ComponentState[];

    getEditedRow(tableName: TableName): Row | undefined;
}

function makeHydratedSubComponentsFromHydrationResults(
    subComponentResults: readonly HydrationResult[],
    hydrationRowContext: HydratedRowContext
): WireHydratedSubComponents {
    const components: (WireComponent | null)[] = [];
    let allValid = true;
    let haveValues: boolean | undefined;
    let subComponentSubsidiaryScreen: WireSubsidiaryScreen | undefined = undefined;
    let editsInContext = false;
    for (const { component, componentWithFlags: c, subsidiaryScreen: innerSubsidiaryScreen } of subComponentResults) {
        if (c?.editsInContext === true) {
            if (!c.isValid) {
                allValid = false;
            }
            if (c.hasValue !== undefined) {
                haveValues = haveValues === true || c.hasValue;
            }
            editsInContext = true;
        }
        components.push(component);

        if (subComponentSubsidiaryScreen === undefined && innerSubsidiaryScreen !== undefined) {
            subComponentSubsidiaryScreen = innerSubsidiaryScreen;
        }
    }

    return {
        components,
        allValid,
        haveValues,
        editsInContext,
        componentsRowContext: hydrationRowContext,
        subsidiaryScreen: subComponentSubsidiaryScreen,
    };
}

export class TableTransformValueProvider extends LoadingValueUnwrapper implements WireTableTransformValueProvider {
    constructor(
        private readonly hydrationContext: ScreenHydrationContext,
        private readonly tableName: TableName,
        private readonly sources: MutableSubscriptionSources,
        private readonly containingScreenRow: Row | undefined,
        protected readonly useLoadingDisplayValues: boolean,
        private readonly activeScopes: readonly ActiveScope[]
    ) {
        super(useLoadingDisplayValues);

        hydrationContext.registerObjectToRetire(this);
    }

    public makeRowValueProvider(row: Row | undefined): WireRowHydrationValueProvider {
        return new RowFromTableHydrationValueProvider(
            this.hydrationContext,
            makeHydratedScreenContext(row, undefined, this.containingScreenRow),
            this.tableName,
            this.sources,
            this.useLoadingDisplayValues,
            this.activeScopes
        );
    }

    public getGlobalValue(tableName: TableName | undefined, path: RootPath, column: string | true): GroundValue {
        addGlobalKey(this.sources, path.rest.key, column);
        if (tableName !== undefined) {
            this.hydrationContext.fetchTableData(tableName);
        }

        return this.unwrap(this.hydrationContext.namespace?.get(path));
    }

    public requireColumnInTable(rootPath: RootPath, columnPath: RelativePath): void {
        assert(isKeyPath(columnPath) && columnPath.rest === undefined);

        // For the moment we don't technically need to ##getWhileHydrating
        // because this is not used for actions, but it might be in the
        // future.
        this.hydrationContext.namespace?.get(rootPath);
        // We subscribe to the table's row ID column so that we get
        // added/deleted rows as well as the transition from loading to
        // loaded.
        addGlobalKey(this.sources, rootPath.rest.key, nativeTableRowIDColumnName);
        // We subscribe to the column to get changes, which we get per row.
        this.sources.columnsInAllDirectRows.get(rootPath.rest.key).add(columnPath.key);
    }

    public getShuffleOrder(): DefaultMap<string, number> {
        this.sources.needs = { ...this.sources.needs, shuffleOrder: true };
        return this.hydrationContext.shuffleOrder;
    }
}

// The way we use this is that we schedule a follow up callback which calls
// `subscribe`, which returns `unsubscribe`, which we then put in here.
interface MutableEffectSubscription extends EffectSubscription {
    readonly dependencies: readonly BasePrimitiveValue[];
    readonly subscribe: (ab: WireActionBackend) => () => void;
    unsubscribe: () => void;
    isUnsubscribed: boolean;
}

// We make one of these per component we hydrate, and make it keep track of
// all the values gotten.
export class RowComponentHydrationBackend
    extends RowActionHydrationBackend<ScreenHydrationContext>
    implements WireRowComponentHydrationBackend, ComponentSubscriber
{
    private readonly tableBackends: TableComponentHydrationBackend[] = [];
    private subComponentResults: readonly HydrationResult[] | undefined;
    private effectSubscription: MutableEffectSubscription | undefined;

    constructor(
        hydrationContext: ScreenHydrationContext,
        rowScreenContext: HydratedRowContext | undefined,
        oldStateValues: StateValuesMap | undefined,
        stateSaveKey: string | undefined,
        componentID: string | undefined,
        useLoadingDisplayValues: boolean,
        private readonly tables: InputOutputTables | undefined,
        public readonly currentScreenTitle: string | undefined,
        private readonly hydrationHelper: HydrationHelper | undefined,
        public readonly getFormFactor: () => WireFormFactor,
        public readonly activeScopes: readonly ActiveScope[]
    ) {
        super(
            hydrationContext,
            rowScreenContext,
            oldStateValues,
            stateSaveKey,
            componentID,
            useLoadingDisplayValues,
            activeScopes
        );
    }

    public get screenPosition(): WireScreenPosition {
        return this.context.position;
    }

    public getScreenState<T extends GroundValue>(
        name: string,
        validate: (v: GroundValue) => v is T,
        defaultValue: T
    ): WireEditableValue<T> {
        return getScreenState(this.context, this.sources, this.tokens, name, validate, defaultValue);
    }

    private makeFollowUp(followUp: ValueChangeFollowUp | undefined): OnChangeFollowUp | undefined {
        if (followUp === undefined) return undefined;
        return {
            rowScreenContext: this.rowContext,
            screenPosition: this.screenPosition,
            modalSize: this.context.size,
            followUp,
        };
    }

    public isRowIdentified(outputRow: boolean): boolean {
        return isRowIdentified(this, this.context, this.tables, outputRow);
    }

    public registerOnValueChange(
        key: string,
        columnName: string,
        primaryKeyColumnName?: string,
        followUp?: ValueChangeFollowUp
    ): string | false | undefined {
        if (this.tables === undefined) return false;

        const row = this.rowContext?.outputRow ?? this.rowContext?.inputRows[0];
        if (row === undefined) return false;

        const column = getTableColumn(this.tables.output, columnName);
        if (
            column === undefined ||
            !isColumnWritable(column, this.tables.output, false, { allowArrays: true, allowHidden: true })
        ) {
            return false;
        }

        if (!this.isRowIdentified(true)) return undefined;
        if (this.context.appKind === AppKind.App && !this.getIsOnline() && row.$isVisible) return undefined;

        const token = `${this.stateSaveKey}-${key}`;
        this.context.onChangeValueRows.set(token, {
            kind: "column",
            source: {
                kind: "table",
                tableName: getTableName(this.tables.output),
                primaryKeyColumnName,
            },
            columnName,
            row,
            followUp: this.makeFollowUp(followUp),
        });
        this.addToken(token);
        return token;
    }

    public registerOnSpecialScreenRowValueChange(
        // Must be unique within the component or subcomponent, or row
        key: string,
        row: Row,
        columnName: string,
        followUp?: ValueChangeFollowUp
    ): string {
        const token = `${this.stateSaveKey}-${key}`;
        this.context.onChangeValueRows.set(token, {
            kind: "column",
            source: {
                kind: "special-row",
            },
            columnName,
            row,
            followUp: this.makeFollowUp(followUp),
        });
        this.addToken(token);
        return token;
    }

    // This must be called (and the row backend must be subscribed), or
    // everything depended on in `makeRowValueProvider` will not actually be
    // listened to.
    public makeHydrationBackendForTable(tableType: TableGlideType, table: Table): WireTableComponentHydrationBackend {
        const tb = new TableComponentHydrationBackend(
            this.context,
            table,
            this.rowContext?.containingScreenRow ?? this.rowContext?.inputRows[0],
            makeInputOutputTables(tableType),
            false,
            this.currentScreenTitle,
            this.stateManager,
            this.stateSaveKey,
            `${this.componentID}-${this.rowContext?.inputRows[0]?.$rowID}-table-`,
            // FIXME: can we just do this?
            this.hydrationHelper,
            this.sources,
            this.useLoadingDisplayValues,
            this.getFormFactor,
            this.activeScopes
        );
        this.tableBackends.push(tb);
        return tb;
    }

    // This is where we assign the row for the
    // ##containingScreenRowForTransforms.  That's the output row in
    // add/edit/form screens, otherwise it's the input row.  We don't have the
    // mutating screen kind here, so we use the heuristic of the input and
    // outut tables being the same to decide whether we're in a "regular"
    // screen.  The worst that can happen if that goes wrong is that we filter
    // for a value where we would otherwise have failed, but it's not nice.
    //
    // More explanation:
    //
    // Input and output tables are always defined. In all screens except for
    // Form screens they're the same. In Form screens they can be different,
    // but they don't have to be - you can have a Form that's initiated from a
    // Products screen, say, that creates a new Products row. So if they're
    // different then we know we're in a Form screen, but if they're the same
    // we could be in any screen.
    //
    // Input and output rows, however, can be missing. An input row can be
    // missing on a screen that's bound to an empty table, or if it filters
    // out all the rows. Also detail screens have no output row defined -
    // writing writes to the input row, i.e. the input row also acts as output
    // row. Maybe a questionable design decision, but that's what it is now.
    //
    // What we're trying to do in this function is: For add/edit/form screens,
    // get the output row, and for regular screens get the input row. Unless
    // something goes really wrong, add/edit/form screens should always have
    // an output row. If there's no output row, then we should really be in a
    // regular screen, but we do the additional table check to get even a bit
    // more confident. The failure case that this code leaves open is:
    // something has gone really wrong and an add/edit/form screen has no
    // output row, and its input and output tables are the same so we go to
    // its input row.
    //
    // FIXME: fix this by either threading the `MutatingScreenKind` in here,
    // or at least a flag.
    private getContainingScreenRowForTransforms(): Row | undefined {
        let containingScreenRow = this.rowContext?.outputRow;
        if (containingScreenRow === undefined && this.tables?.input === this.tables?.output) {
            containingScreenRow = this.rowContext?.inputRows[0];
        }
        return containingScreenRow;
    }

    public makeHydrationBackendForQuery(tableType: TableGlideType): WireRowComponentHydrationBackend {
        const containingScreenRow = this.getContainingScreenRowForTransforms();
        const hb = new RowComponentHydrationBackend(
            this.context,
            makeHydratedScreenContext([], undefined, containingScreenRow),
            this.stateManager.oldStateValues,
            this.stateSaveKey,
            this.componentID,
            this.useLoadingDisplayValues,
            makeInputOutputTables(tableType),
            this.currentScreenTitle,
            this.hydrationHelper,
            this.getFormFactor,
            this.activeScopes
        );
        this.addSubsidiary(hb);
        return hb;
    }

    public makeTableTransformValueProvider(tableName: TableName): WireTableTransformValueProvider {
        const containingScreenRow = this.getContainingScreenRowForTransforms();
        return new TableTransformValueProvider(
            this.context,
            tableName,
            this.sources,
            containingScreenRow,
            this.useLoadingDisplayValues,
            this.activeScopes
        );
    }

    public subscribeComponent(
        c: WireComponentWithFlags | undefined,
        subsidiaryScreen: WireScreen | undefined,
        editor: WireComponentEditor | undefined,
        additionalInputs: readonly unknown[],
        builderTableData: Record<string, BuilderTableData> | undefined,
        subscriptionName: string,
        oldSubscriptionInfo: SubscriptionInfo | undefined,
        onChange: () => void
    ): ComponentState {
        let info = super.subscribeComponent(
            c,
            subsidiaryScreen,
            editor,
            additionalInputs,
            builderTableData,
            subscriptionName,
            oldSubscriptionInfo,
            onChange
        );
        assert(Object.entries(info.subComponentStates).length === 0);
        info = {
            ...info,
            subsidiaries: [
                ...info.subsidiaries,
                ...this.tableBackends.map(tb =>
                    tb.subscribeComponent(
                        c,
                        undefined,
                        undefined,
                        additionalInputs,
                        builderTableData,
                        subscriptionName,
                        oldSubscriptionInfo,
                        onChange
                    )
                ),
            ],
        };
        if (this.subComponentResults !== undefined) {
            assert(this.hydrationHelper !== undefined);
            info = {
                ...info,
                subComponentStates: {
                    ...info.subComponentStates,
                    // We're hardcoding the ##subComponentsStateName here, not
                    // sure if this will be a problem at some point.
                    components: [
                        this.rowContext,
                        this.hydrationHelper.subscribeSubComponents(this.subComponentResults, subscriptionName),
                    ],
                },
            };
        }

        const { effectSubscription } = this;
        if (effectSubscription !== undefined) {
            if (
                oldSubscriptionInfo?.effect !== undefined &&
                shallowEqualArrays(oldSubscriptionInfo.effect.dependencies, effectSubscription.dependencies)
            ) {
                info = {
                    ...info,
                    effect: oldSubscriptionInfo.effect,
                };
            } else {
                this.context.followUpWith(async ab => {
                    if (effectSubscription.isUnsubscribed) return;
                    const unsubscribe = effectSubscription?.subscribe(ab);
                    effectSubscription.unsubscribe = () => {
                        assert(!effectSubscription.isUnsubscribed);
                        effectSubscription.isUnsubscribed = true;
                        unsubscribe();
                    };
                    oldSubscriptionInfo?.effect?.unsubscribe?.();
                });
                info = {
                    ...info,
                    effect: effectSubscription,
                };
            }
        }
        return info;
    }

    public makeHydrationBackendForRow(
        inputRow: Row | undefined,
        outputRow: Row | undefined,
        tables: InputOutputTables | undefined
    ): WireRowComponentHydrationBackend {
        const rowContext = makeHydratedScreenContext(inputRow, outputRow, this.rowContext?.containingScreenRow);
        const hb = new RowComponentHydrationBackend(
            this.context,
            rowContext,
            this.stateManager.oldStateValues,
            this.stateSaveKey,
            this.componentID,
            this.useLoadingDisplayValues,
            tables,
            this.currentScreenTitle,
            this.hydrationHelper,
            this.getFormFactor,
            this.activeScopes
        );
        this.addSubsidiary(hb);
        return hb;
    }

    public makeHydrationBackendForOutputRow(): WireRowComponentHydrationBackend | undefined {
        if (this.rowContext?.outputRow === undefined) return undefined;
        return this.makeHydrationBackendForRow(
            this.rowContext.outputRow,
            undefined,
            definedMap(this.tables?.output, makeInputOutputTables)
        );
    }

    public getScreenTitle(screen: ParsedScreen): string | null | undefined {
        return this.context.getScreenTitle(screen, this);
    }

    public hydrateSubComponents(
        name: string,
        hydrators: readonly WireComponentHydratorWithID[],
        outputTable: TableGlideType | undefined,
        getSubComponents: WireSubComponentsGetter
    ): WireHydratedSubComponents {
        assert(this.subComponentResults === undefined);
        assert(this.hydrationHelper !== undefined);

        const failureResult: WireHydratedSubComponents = {
            components: [],
            editsInContext: false,
            allValid: true,
            haveValues: undefined,
            componentsRowContext: undefined,
            subsidiaryScreen: undefined,
        };

        if (this.tables === undefined || this.rowContext === undefined) return failureResult;

        let hydrationTables: InputOutputTables;
        let hydrationRowContext: HydratedRowContext;

        if (outputTable !== undefined) {
            const row = this.hydrationHelper.getEditedRow(getTableName(outputTable));
            if (row === undefined) return failureResult;

            hydrationTables = makeInputOutputTables(this.tables.input, outputTable);
            hydrationRowContext = makeHydratedScreenContext(
                this.rowContext?.inputRows,
                row,
                this.rowContext?.containingScreenRow
            );
        } else {
            hydrationTables = this.tables;
            hydrationRowContext = this.rowContext;
        }

        this.subComponentResults = this.hydrationHelper.hydrateSubComponents(
            name,
            hydrators,
            hydrationTables,
            hydrationRowContext,
            getSubComponents
        );

        return makeHydratedSubComponentsFromHydrationResults(this.subComponentResults, hydrationRowContext);
    }

    public useEffect(
        subscribe: (ab: WireActionBackend) => () => void,
        dependencies: readonly BasePrimitiveValue[]
    ): void {
        assert(this.effectSubscription === undefined);

        this.effectSubscription = {
            dependencies,
            subscribe,
            isUnsubscribed: false,
            unsubscribe: () => {
                defined(this.effectSubscription).isUnsubscribed = true;
            },
        };
    }
}

// This will modify the data in `sources` during hydration.
class RowFromTableHydrationValueProvider extends LoadingValueUnwrapper implements WireRowHydrationValueProvider {
    constructor(
        protected readonly context: ScreenHydrationContext,
        public readonly rowContext: HydratedRowContext,
        private readonly tableName: TableName,
        protected readonly sources: MutableSubscriptionSources,
        useLoadingDisplayValues: boolean,
        readonly activeScopes: readonly ActiveScope[]
    ) {
        super(useLoadingDisplayValues);

        context.registerObjectToRetire(this);
    }

    public getGlobalValue(tableName: TableName | undefined, path: RootPath, column: string | true): GroundValue {
        addGlobalKey(this.sources, path.rest.key, column);
        if (tableName !== undefined) {
            this.context.fetchTableData(tableName);
        }

        return this.unwrap(this.context.namespace?.get(path));
    }

    public getColumnInRow(tableName: TableName, rootPath: RootPath, row: Row, columnPath: RelativePath): GroundValue {
        assert(isTopLevelPath(rootPath));
        assert(isKeyPath(columnPath) && columnPath.rest === undefined);

        const isDirect = row === this.rowContext.inputRows[0] && areTableNamesEqual(tableName, this.tableName);
        (isDirect ? this.sources.columnsInAllDirectRows : this.sources.columnsInAllIndirectRows)
            .get(rootPath.rest.key)
            .add(columnPath.key);
        this.context.fetchTableData(tableName);

        // We have to ##getWhileHydrating.
        this.context.namespace?.get(rootPath);

        return this.unwrap(follow(row, columnPath));
    }

    public makeHydrationBackendForRow(
        inputRow: Row | undefined,
        outputRow: Row | undefined,
        _tables: InputOutputTables | undefined
    ): WireRowHydrationValueProvider {
        const rowContext = makeHydratedScreenContext(inputRow, outputRow, this.rowContext?.containingScreenRow);
        return new RowFromTableHydrationValueProvider(
            this.context,
            rowContext,
            this.tableName,
            this.sources,
            this.useLoadingDisplayValues,
            this.activeScopes
        );
    }

    public resolveQueryAsTable(query: Query): Table | LoadingValue | undefined {
        return resolveQueryAsTable(query, this.context, this.sources, this.unwrap);
    }

    // `subscriptionName` is only used for debugging.
    // FIXME: this `onChange` also has to run when the queries listened to update
    private subscribe(subscriptionName: string, onChange: () => void): SubscriptionInfo {
        return makeSubscription(
            subscriptionName,
            onChange,
            this.context,
            this.sources,
            // We don't have any change tokens here.
            new Set(),
            []
        );
    }

    public async listenForChanges(timeoutMS: number | undefined, getData: () => boolean): Promise<boolean> {
        return listenForChanges<SubscriptionInfoWithQueryCallback>(
            timeoutMS,
            getData,
            (name, onChange) => {
                this.context.addQueryChangedCallback(onChange);
                return {
                    ...this.subscribe(name, onChange),
                    onQueryChange: onChange,
                };
            },
            si => {
                if (si.handlerAndPath === undefined) return;
                this.context.namespace?.get(si.handlerAndPath.path);
            },
            si => {
                this.context.removeQueryChangedCallback(si.onQueryChange);
                this.context.retireSubscriptionInfo(si);
            }
        );
    }
}

class RowFromTableHydrationBackend
    extends RowFromTableHydrationValueProvider
    implements WireRowComponentHydrationBackend
{
    private readonly tables: InputOutputTables | undefined;

    constructor(
        context: ScreenHydrationContext,
        rowScreenContext: HydratedRowContext,
        tablesOrInputTableName: InputOutputTables | TableName,
        public readonly currentScreenTitle: string | undefined,
        private readonly stateManager: StateManager,
        private readonly stateNamePrefix: string,
        private readonly stateTokenPrefix: string,
        sources: MutableSubscriptionSources,
        useLoadingDisplayValues: boolean,
        private readonly tokens: Set<string>,
        private readonly registerActionWithContext: (
            key: string,
            run: WireActionRunner,
            rowScreenContext: HydratedRowContext | undefined
        ) => string,
        public readonly getFormFactor: () => WireFormFactor,
        public activeScopes: readonly ActiveScope[]
    ) {
        let tableName: TableName;
        let tables: InputOutputTables | undefined;
        if (isTableName(tablesOrInputTableName)) {
            tableName = tablesOrInputTableName;
        } else {
            tables = tablesOrInputTableName;
            tableName = getTableName(tables.input);
        }

        super(context, rowScreenContext, tableName, sources, useLoadingDisplayValues, activeScopes);
        this.tables = tables;
    }

    public get stateSaveKey(): string {
        return `${this.stateTokenPrefix}${this.rowContext.inputRows[0]?.$rowID}`;
    }

    public get screenPosition(): WireScreenPosition {
        return this.context.position;
    }

    public getParsedPath(): NavigationPath {
        return getParsedPath(this.sources, this.context);
    }

    public getIsOnline(): boolean {
        return getIsOnline(this.sources, this.context);
    }

    public fetchTable(tableName: TableName): void {
        this.context.fetchTableData(tableName);
    }

    public registerAction(key: string, run: WireActionRunner): string {
        return this.registerActionWithContext(key, run, this.rowContext);
    }

    public isRowIdentified(outputRow: boolean): boolean {
        return isRowIdentified(this, this.context, this.tables, outputRow);
    }

    public registerOnValueChange(
        key: string,
        columnName: string,
        primaryKeyColumnName?: string,
        followUp?: ValueChangeFollowUp
    ): string | false | undefined {
        if (this.tables === undefined) return false;

        const [row] = this.rowContext.inputRows;
        if (row === undefined) return false;

        // TODO: This is duplicated in `RowComponentHydrationBackend`
        const column = getTableColumn(this.tables.output, columnName);
        if (
            column === undefined ||
            !isColumnWritable(column, this.tables.output, false, { allowArrays: true, allowHidden: true })
        ) {
            return false;
        }

        if (!this.isRowIdentified(true)) return undefined;
        if (this.context.appKind === AppKind.App && !this.getIsOnline() && row.$isVisible) return undefined;

        let onChangeFollowUp: OnChangeFollowUp | undefined;
        if (followUp !== undefined) {
            onChangeFollowUp = {
                rowScreenContext: this.rowContext,
                screenPosition: this.screenPosition,
                modalSize: this.context.size,
                followUp,
            };
        }

        const token = `${this.stateTokenPrefix}${this.rowContext.inputRows[0]?.$rowID}-${key}`;
        this.context.onChangeValueRows.set(token, {
            kind: "column",
            source: {
                kind: "table",
                tableName: getTableName(this.tables.output),
                primaryKeyColumnName,
            },
            columnName,
            row,
            followUp: onChangeFollowUp,
        });
        this.tokens.add(token);
        return token;
    }

    public registerOnSpecialScreenRowValueChange() {
        return panic("Special row changes are not supported here");
    }

    public makeTableTransformValueProvider() {
        return panic("Cannot make a table from a row in a table");
    }

    public makeHydrationBackendForTable() {
        return panic("Cannot make a table from a row in a table");
    }

    public makeHydrationBackendForQuery(): WireRowComponentHydrationBackend {
        return panic("Cannot make a query from a row in a table");
    }

    public getScreenState<T extends GroundValue>(
        name: string,
        validate: (v: GroundValue) => v is T,
        defaultValue: T
    ): WireEditableValue<T> {
        return getScreenState(this.context, this.sources, this.tokens, name, validate, defaultValue);
    }

    // ##uniqueStateNames:
    // FIXME: `name` here must be unique within the component, not just within
    // the row.  That's odd and weird and not composable.
    public getState<T>(
        name: string,
        validate: ((v: unknown) => v is T) | undefined,
        defaultValue: T,
        shouldSave: boolean,
        durationMS: number | undefined
    ): WireAlwaysEditableValue<T> {
        return this.stateManager.getState(
            `${this.stateNamePrefix}${name}`,
            `${this.stateSaveKey}-${name}`,
            validate,
            defaultValue,
            shouldSave,
            durationMS
        );
    }

    // TODO: These two are repeated above.  It would be nice to just have them
    // once, especially if we add more.
    public getIsUserSignedIn(): boolean {
        const email = definedMap(this.context.verifiedEmailAddressPath, p => this.getGlobalValue(undefined, p, true));
        return email !== undefined;
    }

    public getIsTabVisible(tabScreenName: string): boolean {
        return this.context.tabScreenVisibilityPredicates.get(tabScreenName)?.(this) === true;
    }

    public getIsModalVisible(): boolean {
        return this.context.parsedPath.getParsedScreens().modal !== undefined;
    }

    public makeHydrationBackendForRow(
        inputRow: Row | undefined,
        outputRow: Row | undefined,
        tables: InputOutputTables | undefined
    ): WireRowComponentHydrationBackend {
        // There's only one call site where can can make a
        // ##hydrationBackendWithUndefinedTables and it should never call it
        // on this class.
        assert(tables !== undefined);
        const rowContext = makeHydratedScreenContext(inputRow, outputRow, this.rowContext?.containingScreenRow);
        const hb = new RowFromTableHydrationBackend(
            this.context,
            rowContext,
            tables,
            this.currentScreenTitle,
            this.stateManager,
            this.stateNamePrefix,
            this.stateTokenPrefix,
            this.sources,
            this.useLoadingDisplayValues,
            this.tokens,
            this.registerActionWithContext,
            this.getFormFactor,
            this.activeScopes
        );
        return hb;
    }

    public makeHydrationBackendForOutputRow(): WireRowComponentHydrationBackend | undefined {
        return panic("We don't support this yet");
    }

    public makeHydrationBackendForAction(): WireRowActionHydrationValueProvider {
        const hb = new RowFromTableHydrationBackend(
            this.context,
            this.rowContext,
            defined(this.tables),
            this.currentScreenTitle,
            this.stateManager,
            this.stateNamePrefix,
            this.stateTokenPrefix,
            this.sources,
            false,
            this.tokens,
            this.registerActionWithContext,
            this.getFormFactor,
            this.activeScopes
        );
        return hb;
    }

    public getScreenTitle(screen: ParsedScreen): string | null | undefined {
        return this.context.getScreenTitle(screen, this);
    }

    public hydrateSubComponents(): WireHydratedSubComponents {
        return panic("We don't support this yet");
    }

    public useEffect(): void {
        return panic("We don't support this yet.");
    }
}

export class TableComponentHydrationBackend
    extends LoadingValueUnwrapper
    implements WireTableComponentHydrationBackend, WireScreenStateProvider, ComponentSubscriber
{
    private readonly tokens = new Set<string>();
    private readonly subComponentResults: Record<string, [HydratedScreenContext, readonly HydrationResult[]]> = {};

    public readonly numRowsBeforeLimit: number;

    constructor(
        private readonly context: ScreenHydrationContext,
        private table: Table,
        private readonly containingScreenRow: Row | undefined,
        private readonly tablesOrInputTableName: InputOutputTables | TableName,
        public readonly isArrayScreen: boolean,
        public readonly currentScreenTitle: string | undefined,
        private readonly stateManager: StateManager,
        public readonly stateSaveKey: string | undefined,
        private readonly stateTokenPrefix: string,
        // If this is `undefined` then we can't hydrate sub components.
        private readonly hydrationHelper: HydrationHelper | undefined,
        private readonly sources: MutableSubscriptionSources = makeMutableSubscriptionSources(),
        useLoadingDisplayValues: boolean,
        public readonly getFormFactor: () => WireFormFactor,
        public readonly activeScopes: readonly ActiveScope[]
    ) {
        super(useLoadingDisplayValues);

        if (!isTableName(tablesOrInputTableName)) {
            assert(tablesOrInputTableName.input === tablesOrInputTableName.output);
        }

        this.numRowsBeforeLimit = table.size;

        context.registerObjectToRetire(this);
    }

    public get screenPosition(): WireScreenPosition {
        return this.context.position;
    }

    public get quotaKey(): string {
        return makeUniqueIDForArrayScreen(this.context.internalScreen.screenName);
    }

    public get tableScreenContext(): Table {
        return this.table;
    }

    public registerAction(key: string, run: WireActionRunner, rowContext?: HydratedRowContext): string {
        const token = `${this.stateTokenPrefix}-${key}`;
        this.context.actions.set(token, [run, rowContext ?? emptyHydratedScreenContext]);
        this.tokens.add(token);
        return token;
    }

    public getState<T>(
        name: string,
        validate: ((v: unknown) => v is T) | undefined,
        defaultValue: T,
        shouldSave: boolean,
        debounceMS: number | undefined
    ): WireAlwaysEditableValue<T> {
        return this.stateManager.getState(
            `array-${name}`,
            `${this.stateTokenPrefix}-${name}`,
            validate,
            defaultValue,
            shouldSave,
            debounceMS
        );
    }

    public getScreenState<T extends GroundValue>(
        name: string,
        validate: (v: GroundValue) => v is T,
        defaultValue: T
    ): WireEditableValue<T> {
        return getScreenState(this.context, this.sources, this.tokens, name, validate, defaultValue);
    }

    public makeHydrationBackendForRow(row: Row): WireRowComponentHydrationBackend {
        const rowScreenContext = makeHydratedScreenContext(row, undefined, this.containingScreenRow);

        return new RowFromTableHydrationBackend(
            this.context,
            rowScreenContext,
            this.tablesOrInputTableName,
            this.currentScreenTitle,
            this.stateManager,
            "row-",
            `${this.stateTokenPrefix}-row-`,
            this.sources,
            this.useLoadingDisplayValues,
            this.tokens,
            (key, run, ctx) => this.registerAction(`${row.$rowID}-${key}`, run, ctx),
            this.getFormFactor,
            this.activeScopes
        );
    }

    public makeTableTransformValueProvider(tableName: TableName): WireTableTransformValueProvider {
        return new TableTransformValueProvider(
            this.context,
            tableName,
            this.sources,
            undefined,
            this.useLoadingDisplayValues,
            this.activeScopes
        );
    }

    public hydrateSubComponents(
        name: string,
        row: Row,
        hydrators: readonly WireComponentHydratorWithID[],
        getSubComponents: WireSubComponentsGetter
    ): WireHydratedSubComponents {
        assert(this.hydrationHelper !== undefined);

        const hydrationRowContext = makeHydratedScreenContext(row, undefined, this.containingScreenRow);
        const results = this.hydrationHelper.hydrateSubComponents(
            name,
            hydrators,
            isTableName(this.tablesOrInputTableName) ? undefined : this.tablesOrInputTableName,
            hydrationRowContext,
            getSubComponents
        );
        this.subComponentResults[name] = [hydrationRowContext, results];

        return makeHydratedSubComponentsFromHydrationResults(results, hydrationRowContext);
    }

    private makeSubscriptionHandler(onChange: () => void): SubscriptionHandler | undefined {
        return makeSubscriptionHandler(this.sources, onChange);
    }

    public subscribeComponent(
        component: WireComponentWithFlags | undefined,
        subsidiaryScreen: WireScreen | undefined,
        editor: WireComponentEditor | undefined,
        additionalInputs: readonly unknown[],
        builderTableData: Record<string, BuilderTableData> | undefined,
        subscriptionName: string,
        _oldSubscriptionInfo: SubscriptionInfo | undefined,
        onChange: () => void
    ): ComponentState {
        assert(additionalInputs.length === 0);

        let handlerAndPath: SubscriptionHandlerAndPath | undefined;
        if (this.context.namespace !== undefined) {
            const handler = this.makeSubscriptionHandler(onChange);
            if (handler !== undefined) {
                const path = addSubscriptionHandler(this.context, subscriptionName, handler);
                handlerAndPath = { handler, path };
            }
        }

        return {
            isComponentState: true,
            handlerAndPath,
            tokens: this.tokens,
            needs: this.sources.needs,
            subsidiaries: [],
            stateValues: this.stateManager.stateValues,
            stateSaveKey: this.stateSaveKey,
            stateChanged: false,
            isValid: component?.isValid ?? true,
            editsInContext: component?.editsInContext,
            hasValue: component?.hasValue,
            canBeSearched: component?.canBeSearched ?? false,
            editor,
            subComponentStates: fromPairs(
                Object.entries(this.subComponentResults).map(([n, [ctx, hr]]) => {
                    const cs = defined(this.hydrationHelper).subscribeSubComponents(hr, subscriptionName);
                    return [n, [ctx, cs]];
                })
            ),
            additionalInputs: [],
            builderTableData,
            subsidiaryScreen,
            effect: undefined,
        };
    }

    // We could alternatively make a new instance with the new rows, but we'd
    // have to be careful to keep modifying the same state as this instance,
    // or subscribing won't work.
    public withTable(table: Table): this {
        this.table = table;
        return this;
    }

    public getIsOnline(): boolean {
        return getIsOnline(this.sources, this.context);
    }

    public getGlobalValue(tableName: TableName | undefined, path: RootPath, column: string | true): GroundValue {
        addGlobalKey(this.sources, path.rest.key, column);
        if (tableName !== undefined) {
            this.context.fetchTableData(tableName);
        }

        return this.unwrap(this.context.namespace?.get(path));
    }
}
