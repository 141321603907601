import { hasOwnProperty, exceptionToString } from "@glideapps/ts-necessities";
import { getDeviceID } from "./device-id";
import { getCloudRegion } from "./function-region";
import { generateHexString, getCurrentTimestampInMilliseconds, logError } from "@glide/support";

export type FrontendEventFields = Record<string, string | boolean | number | undefined>;

type FrontendEventSender = (
    fields: FrontendEventFields,
    eventSendIsRetryable?: (fields: FrontendEventFields) => boolean
) => void;

let sendEvent: FrontendEventSender | undefined;
let inBuilder: boolean = false;

const appIDRegexp = /.+\/(app|play)\/([^/]+)(\/|$)/;

function getAppID(): string | undefined {
    if (hasOwnProperty(window, "appID")) {
        const { appID } = window;
        if (typeof appID === "string") {
            return appID;
        }
    }

    const match = window.location.href.match(appIDRegexp);
    if (match !== null) {
        return match[2];
    }

    return undefined;
}

function getStandardFields(name: string, appID: string | undefined, duration: number): FrontendEventFields {
    let userAgent: string | undefined;
    try {
        userAgent = navigator.userAgent;
    } catch {
        // We won't always have access to a userAgent.
        // For example, if we call this from Node.
    }

    const fields: FrontendEventFields = {
        source: inBuilder ? "builder" : "player",
        name,
        duration_ms: duration,
        browser_location: window.location.href,
        region: getCloudRegion(),
        deployment_version: (window as any).glideDeploymentVersion,
        "trace.trace_id": generateHexString(32),
        device_id: getDeviceID(),
        user_agent: userAgent,
    };
    if (appID !== undefined) {
        fields.app_id = appID;
    }
    return fields;
}

export function frontendSendEvent(
    name: string,
    duration: number,
    fields: FrontendEventFields,
    eventSendIsRetryable: boolean = true
): void {
    if (sendEvent === undefined) return;

    const appID = getAppID();
    if (appID === undefined) return;

    sendEvent(
        {
            ...getStandardFields(name, appID, duration),
            ...fields,
        },
        () => eventSendIsRetryable
    );
}

function shouldTrace(name: string, fromBuilder: boolean | "always"): boolean {
    if (fromBuilder === "always") return true;
    if (inBuilder === fromBuilder) return true;
    // We always trace `function`, whether we're in the player or in the
    // builder.
    if (name === "function") return true;
    return false;
}

export async function frontendTrace<T>(
    name: string,
    fields: FrontendEventFields | undefined,
    f: (extraFields: FrontendEventFields) => Promise<T>,
    fromBuilder: boolean | "always" = false,
    traceSendingIsRetryable?: (extraFields: FrontendEventFields) => boolean
): Promise<T> {
    if (sendEvent === undefined || !shouldTrace(name, fromBuilder)) {
        return await f({});
    }

    const extraFields: FrontendEventFields = {};

    const appID = getAppID();
    if (appID === undefined && fromBuilder === false) {
        return await f(extraFields);
    }

    const start = getCurrentTimestampInMilliseconds();
    let exception: string | undefined;
    try {
        return await f(extraFields);
    } catch (e: unknown) {
        exception = exceptionToString(e);
        throw e;
    } finally {
        try {
            const duration = getCurrentTimestampInMilliseconds() - start;
            sendEvent(
                {
                    ...getStandardFields(name, appID, duration),
                    exception,
                    ...fields,
                    ...extraFields,
                },
                traceSendingIsRetryable
            );
        } catch (e: unknown) {
            logError("Exception thrown when sending trace", e);
        }
    }
}

export function frontendTraceSync<T>(
    name: string,
    fields: FrontendEventFields | undefined,
    f: (extraFields: FrontendEventFields) => T,
    fromBuilder: boolean | "always" = false,
    traceSendingIsRetryable?: (extraFields: FrontendEventFields) => boolean
): T {
    if (sendEvent === undefined || !shouldTrace(name, fromBuilder)) {
        return f({});
    }

    const extraFields: FrontendEventFields = {};

    const appID = getAppID();
    if (appID === undefined && fromBuilder === false) {
        return f(extraFields);
    }

    const start = getCurrentTimestampInMilliseconds();
    let exception: string | undefined;
    try {
        return f(extraFields);
    } catch (e: unknown) {
        exception = exceptionToString(e);
        throw e;
    } finally {
        try {
            const duration = getCurrentTimestampInMilliseconds() - start;
            sendEvent(
                {
                    ...getStandardFields(name, appID, duration),
                    exception,
                    ...fields,
                    ...extraFields,
                },
                traceSendingIsRetryable
            );
        } catch (e: unknown) {
            logError("Exception thrown when sending trace", e);
        }
    }
}

export async function maybeFrontendTrace<T>(
    name: string,
    fields: FrontendEventFields | undefined,
    p: number,
    f: (extraFields: FrontendEventFields) => Promise<T>
): Promise<T> {
    if (Math.random() < p) {
        return await frontendTrace(name, fields, f);
    } else {
        return await f({});
    }
}

export function registerFrontendEventSender(s: FrontendEventSender, areWeInBuilder: boolean): void {
    sendEvent = s;
    inBuilder = areWeInBuilder;
}
