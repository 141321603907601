import {
    asMaybeNumber,
    type GroundValue,
    type LoadingValue,
    type PrimitiveValue,
    isLoadingValue,
    isPrimitive,
} from "@glide/computation-model-types";
import {
    type Formula,
    type PrimitiveGlideTypeKind,
    FormulaKind,
    isDateOrDateTimeTypeKind,
    type BinaryMathFormula,
    type ConstantFormula,
    type GetVariableFormula,
    type UnaryMathFormula,
    type WithUserEnteredTextFormula,
    BinaryMathFunction,
    UnaryMathFunction,
} from "@glide/type-schema";
import { getWeekNumber, log2, modulo, round2, trunc2 } from "@glide/common-core/dist/js/math-functions";
import { GlideDateTime, convertGlideDurationToJS, convertJSDurationToGlide } from "@glide/data-types";
import { definedMap, panic } from "@glideapps/ts-necessities";

import { DateTimeAsyncParser } from "./parse-date-time";

class UndefinedDate {}
const undefinedDate = new UndefinedDate();

export interface MathContext {
    // This must be usable as a first-class function
    setAllDirty: () => void;
    lookupVariable(n: string): GroundValue;
}

function cleanNumber(x: number): number | undefined {
    if (isNaN(x) || !isFinite(x)) return undefined;
    return x;
}

export class MathInterpreter extends DateTimeAsyncParser {
    constructor(
        protected readonly _formula: Formula,
        private readonly _variableTypes: ReadonlyMap<string, PrimitiveGlideTypeKind>
    ) {
        super();
    }

    public eval(context: MathContext): GroundValue {
        const withDate = (operandValue: PrimitiveValue, f: (d: Date) => GroundValue): GroundValue => {
            const date = this.parseDateTime(operandValue, context.setAllDirty)?.asLocalTimeZoneAgnosticDate();
            if (date === undefined || isLoadingValue(date)) return date;
            return f(date);
        };

        const withNumber = (operandValue: PrimitiveValue, f: (d: number) => number): GroundValue => {
            const operand = asMaybeNumber(operandValue);
            if (operand === undefined) return undefined;
            return cleanNumber(f(operand));
        };

        const compute = (formula: Formula): PrimitiveValue | UndefinedDate | LoadingValue => {
            switch (formula.kind) {
                case FormulaKind.Constant:
                    return (formula as ConstantFormula).value;
                case FormulaKind.Random:
                    return cleanNumber(Math.random());
                case FormulaKind.UnaryMath: {
                    const f = formula as UnaryMathFormula;
                    const operandValue = compute(f.operand);
                    if (operandValue instanceof UndefinedDate) return undefined;
                    if (isLoadingValue(operandValue)) return operandValue;

                    switch (f.fn) {
                        case UnaryMathFunction.Negate:
                            return withNumber(operandValue, n => -n);
                        case UnaryMathFunction.Absolute:
                            return withNumber(operandValue, n => Math.abs(n));
                        case UnaryMathFunction.Floor:
                            return withNumber(operandValue, n => Math.floor(n));
                        case UnaryMathFunction.Ceiling:
                            return withNumber(operandValue, n => Math.ceil(n));
                        case UnaryMathFunction.Round:
                            return withNumber(operandValue, n => Math.round(n));
                        case UnaryMathFunction.Sign:
                            return withNumber(operandValue, n => Math.sign(n));
                        case UnaryMathFunction.Truncate:
                            return withNumber(operandValue, n => Math.trunc(n));
                        case UnaryMathFunction.SquareRoot:
                            return withNumber(operandValue, n => Math.sqrt(n));
                        case UnaryMathFunction.Sine:
                            return withNumber(operandValue, n => Math.sin(n));
                        case UnaryMathFunction.Cosine:
                            return withNumber(operandValue, n => Math.cos(n));
                        case UnaryMathFunction.Tangent:
                            return withNumber(operandValue, n => Math.tan(n));
                        case UnaryMathFunction.ArcSine:
                            return withNumber(operandValue, n => Math.asin(n));
                        case UnaryMathFunction.ArcCosine:
                            return withNumber(operandValue, n => Math.acos(n));
                        case UnaryMathFunction.ArcTangent:
                            return withNumber(operandValue, n => Math.atan(n));
                        case UnaryMathFunction.Logarithm:
                            return withNumber(operandValue, n => Math.log10(n));
                        case UnaryMathFunction.Year:
                            return withDate(operandValue, d => d.getUTCFullYear());
                        case UnaryMathFunction.Month:
                            return withDate(operandValue, d => d.getUTCMonth() + 1);
                        case UnaryMathFunction.Day:
                            return withDate(operandValue, d => d.getUTCDate());
                        case UnaryMathFunction.Hour:
                            return withDate(operandValue, d => d.getUTCHours());
                        case UnaryMathFunction.Minute:
                            return withDate(operandValue, d => d.getUTCMinutes());
                        case UnaryMathFunction.Second:
                            return withDate(operandValue, d => d.getUTCSeconds());
                        case UnaryMathFunction.Weekday:
                            return withDate(operandValue, d => d.getUTCDay() + 1);
                        case UnaryMathFunction.WeekNumber:
                            return withDate(operandValue, getWeekNumber);
                        default:
                            return undefined;
                    }
                }
                case FormulaKind.BinaryMath: {
                    const f = formula as BinaryMathFormula;

                    const aValue = compute(f.left);
                    if (isLoadingValue(aValue)) return aValue;

                    const bValue = compute(f.right);
                    if (isLoadingValue(bValue)) return bValue;
                    if (bValue instanceof UndefinedDate) return undefined;
                    const b = asMaybeNumber(bValue);

                    // ##dateMathInNewComputationModel:
                    // FIXME: The formulas don't contain type information, we
                    // only bring it in via the type kind in `GetVariable`
                    // below. The NCM doesn't compile formulas yet, so we're
                    // dispatching on the type dynamically.  This is slow.
                    if (aValue instanceof GlideDateTime) {
                        switch (f.fn) {
                            case BinaryMathFunction.Add:
                                if (b === undefined) return undefined;
                                return aValue.transformTimeZoneAwareValue(v => v + convertGlideDurationToJS(b));
                            case BinaryMathFunction.Subtract:
                                if (bValue === undefined) return undefined;
                                if (bValue instanceof GlideDateTime) {
                                    return cleanNumber(
                                        convertJSDurationToGlide(
                                            aValue.getLocalTimeZoneAgnosticValue() -
                                                bValue.getLocalTimeZoneAgnosticValue()
                                        )
                                    );
                                } else if (b !== undefined) {
                                    return aValue.transformTimeZoneAwareValue(v => v - convertGlideDurationToJS(b));
                                }
                                return undefined;
                            default:
                                // logError("Invalid math on date", f.fn);
                                return undefined;
                        }
                    }
                    if (aValue instanceof UndefinedDate) {
                        switch (f.fn) {
                            case BinaryMathFunction.Add:
                                return undefinedDate;
                            case BinaryMathFunction.Subtract:
                                return undefined;
                            default:
                                // logError("Invalid math on date", f.fn);
                                return undefined;
                        }
                    }

                    const a = asMaybeNumber(aValue);

                    switch (f.fn) {
                        case BinaryMathFunction.Add:
                            if (a === undefined && b === undefined) return undefined;
                            if (a === undefined) {
                                if (f.right.kind === FormulaKind.Constant) return undefined;
                                return b;
                            }
                            if (b === undefined) {
                                if (f.left.kind === FormulaKind.Constant) return undefined;
                                return a;
                            }
                            return cleanNumber(a + b);
                        case BinaryMathFunction.Subtract:
                            if (a === undefined) return undefined;
                            if (b === undefined) {
                                if (f.left.kind === FormulaKind.Constant) return undefined;
                                return a;
                            }
                            return cleanNumber(a - b);
                    }

                    if (a === undefined || b === undefined) return undefined;

                    let result: number | undefined;
                    switch (f.fn) {
                        case BinaryMathFunction.Multiply:
                            result = a * b;
                            break;
                        case BinaryMathFunction.Divide:
                            result = a / b;
                            break;
                        case BinaryMathFunction.Modulo:
                            result = modulo(a, b);
                            break;
                        case BinaryMathFunction.Minimum:
                            result = Math.min(a, b);
                            break;
                        case BinaryMathFunction.Maximum:
                            result = Math.max(a, b);
                            break;
                        case BinaryMathFunction.Power:
                            result = Math.pow(a, b);
                            break;
                        case BinaryMathFunction.Logarithm:
                            result = log2(a, b);
                            break;
                        case BinaryMathFunction.ArcTangent2:
                            result = Math.atan2(a, b);
                            break;
                        case BinaryMathFunction.Round:
                            result = round2(a, b);
                            break;
                        case BinaryMathFunction.Truncate:
                            result = trunc2(a, b);
                            break;
                        default:
                            return panic("FIXME: implement");
                    }
                    return definedMap(result, cleanNumber);
                }
                case FormulaKind.GetVariable:
                    const f = formula as GetVariableFormula;
                    const kind = this._variableTypes.get(f.name);
                    if (kind === undefined) return undefined;
                    const v = context.lookupVariable(f.name);
                    if (isLoadingValue(v)) return v;
                    if (isDateOrDateTimeTypeKind(kind)) {
                        if (!isPrimitive(v)) return undefinedDate;
                        return this.parseDateTime(v, context.setAllDirty) ?? undefinedDate;
                    } else {
                        return v;
                    }
                default:
                    return panic("FIXME: implement");
            }
        };

        let rootFormula = this._formula;
        if (rootFormula.kind === FormulaKind.WithUserEnteredText) {
            rootFormula = (rootFormula as WithUserEnteredTextFormula).formula;
        }

        // The old computation model just passes through values if the formula
        // is just something like `x`.  It doesn't coerce into number or date,
        // so we don't, either.
        if (rootFormula.kind === FormulaKind.GetVariable) {
            return context.lookupVariable((rootFormula as GetVariableFormula).name);
        }

        const result = compute(rootFormula);
        if (result instanceof UndefinedDate) return undefined;
        return result;
    }
}
