import type {
    PreviewAddonChargeBody,
    PreviewChargeResponse,
    SubscriptionAddon,
} from "@glide/common-core/dist/js/firebase-function-types";
import { previewChargeResponse } from "@glide/common-core/dist/js/firebase-function-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { maybeAsync } from "@glide/support";
import { useQuery } from "@tanstack/react-query";
import { isRight } from "fp-ts/lib/Either";

async function previewAddonCharge(
    addonChargeBody: PreviewAddonChargeBody
): Promise<PreviewChargeResponse | undefined> {
    const res = await getAppFacilities().callAuthCloudFunction("previewAddonCharges", addonChargeBody);

    const charges = await maybeAsync(async () => {
        if (res === undefined) return undefined;
        const result = await res.json();

        const decoded = previewChargeResponse.decode(result);
        return isRight(decoded) ? decoded.right : undefined;
    }, undefined);

    return charges;
}

export function useAddonCharge(
    ownerID: string | undefined,
    addons: SubscriptionAddon[]
): {
    isLoading: boolean;
    isFetching: boolean;
    isRefetching: boolean;
    refetch: () => void;
    preview: PreviewChargeResponse | undefined;
} {
    const { isLoading, isFetching, refetch, isRefetching, data } = useQuery(
        ["useAddonCharge", ownerID ?? "never happens"],
        async ({ queryKey: [, id] }) => {
            return await previewAddonCharge({ ownerID: id, addons: addons });
        },
        {
            enabled: ownerID !== undefined && ownerID !== "0",
            refetchInterval: 60_000,
            staleTime: Infinity,
            cacheTime: 1_000,
        }
    );
    return { isLoading, isFetching, isRefetching, refetch, preview: data };
}
