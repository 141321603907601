import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import {
    type ClassOrArrayScreenDescription,
    type ClassScreenDescription,
    type MutatingScreenKind,
    ScreenDescriptionKind,
} from "@glide/app-description";
import type { InteractiveComponentConfiguratorContext } from "@glide/function-utils";
import { mapFilterUndefinedAsync } from "@glide/support";

import { copyActionsRecord } from "./actions/copy-actions";
import { handlerForArrayScreenFormat } from "./array-screens";
import { getActionsForArrayContent } from "./components/component-utils";
import { inputOutputTablesForClassOrArrayScreen } from "./description-utils";
import { duplicateComponent } from "./handlers";

export async function copyClassScreen<T extends ClassScreenDescription>(
    screenDesc: T,
    iccc: InteractiveComponentConfiguratorContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    screensCreated: Set<string>,
    appFacilities: ActionAppFacilities
): Promise<T | undefined> {
    const tables = inputOutputTablesForClassOrArrayScreen(screenDesc, iccc.schema);
    if (tables === undefined) return undefined;

    return {
        ...screenDesc,
        components: await mapFilterUndefinedAsync(screenDesc.components, c =>
            duplicateComponent(c, tables, iccc, mutatingScreenKind, screensCreated, appFacilities)
        ),
    };
}

export async function copyClassOrArrayScreen(
    screenDesc: ClassOrArrayScreenDescription,
    iccc: InteractiveComponentConfiguratorContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    screensCreated: Set<string>,
    appFacilities: ActionAppFacilities
): Promise<ClassOrArrayScreenDescription | undefined> {
    const tables = inputOutputTablesForClassOrArrayScreen(screenDesc, iccc.schema);
    if (tables === undefined) return undefined;

    if (screenDesc.kind === ScreenDescriptionKind.Class) {
        return {
            ...screenDesc,
            components: await mapFilterUndefinedAsync(screenDesc.components, c =>
                duplicateComponent(c, tables, iccc, mutatingScreenKind, screensCreated, appFacilities)
            ),
        };
    } else {
        const handler = handlerForArrayScreenFormat(screenDesc.format);
        if (handler === undefined) return undefined;
        return {
            ...screenDesc,
            ...(await copyActionsRecord(
                getActionsForArrayContent(handler, tables, screenDesc, iccc),
                screenDesc,
                tables,
                iccc,
                mutatingScreenKind,
                screensCreated,
                appFacilities
            )),
        };
    }
}
