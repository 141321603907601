import "firebase/compat/analytics";
import firebase from "firebase/compat/app";
import "focus-visible";

import { ErrorBoundary, TailwindThemeProvider, browserIsSafari, shortUserAgent } from "@glide/common";
import { getBuilderTheme } from "@glide/theme";
import { initializeAnalytics } from "@glide/common-core/dist/js/analytics";
import { AppStateProvider } from "@glide/common-core/dist/js/app-state-context";
import { addOnFunctionNetworkError, addOnFunctionNetworkSuccess } from "@glide/common-core/dist/js/call-cloud-function";
import { initializeLocalization } from "@glide/localization";
import { isCustomDomain } from "@glide/common-core/dist/js/customDomains";
import { getFeatureFlag, initFeatureFlagsWithStorage } from "@glide/common-core/dist/js/feature-flags";
import { setStringGeneratorRNG as setFirestoreIDGeneratorRNG, logError, logInfo, setShouldLog } from "@glide/support";
import { setExplicitLocation } from "@glide/common-core/dist/js/location";
import * as routes from "@glide/common-core/dist/js/routes";
import { monitorSafariStateHack } from "@glide/common-core/dist/js/support/browser-hacks";
import { reloadBrowserWindow, reportLastReloadReason } from "@glide/common-core/dist/js/support/browser-reload";
import { initRefreshOnStandalone, standalone } from "@glide/common-core/dist/js/support/device";
import { initFirebase, reportLastAuthFailureReason } from "@glide/firebase-stuff";
import { lazilyLoadedComponent } from "@glide/common-core/dist/js/support/react-lazy-loading";
import { frontendSendEvent, registerFrontendEventSender } from "@glide/common-core/dist/js/tracing";
import { default as registerServiceWorker } from "@glide/common-core/dist/js/utility/register-service-worker";
import { honeycombSendEvent } from "./lib/honeycomb";
import { hookIndexedDBForUnreliableOpens } from "@glide/indexeddb-hacks";
import { addFirestorePermanentFailureHandler } from "@glide/client-database";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { type RouteComponentProps, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { GlobalStyles } from "twin.macro";
import { initDebugDataViewer } from "./webapp/lib/debug-data-viewer";
import { cryptoRandom } from "./lib/crypto-random";
import { registerLongPress } from "./lib/long-press-event";
import { setOriginOnline, trapFirestoreHttpErrors } from "./lib/network-tracking";
import { disableBounce, disableContext } from "./lib/nobounce";
import { watchUpdates } from "./lib/watch-updates";
import GlideAppPlayer from "./webapp/glide-app-player";
import { GlideAppPlayerLazy, OAuthRedirectPageLazy } from "@glide/player-component";
import { PlayerObservability } from "@glide/player-frontend-observability";
import { enableMapSet } from "immer";

const App = lazilyLoadedComponent("designer", () => import("./designer/designer"));
const AIPlayground = lazilyLoadedComponent("AIPlayground", () => import("./ai/playground"));

const LazyTemplatePreviewer = lazilyLoadedComponent("template-previewer", () => import("./templates/previewer"));
async function preparePushNotifications(): Promise<void> {
    const { prepareActivePushNotifications } = await import("@glide/firebase-stuff");
    await prepareActivePushNotifications();
}

const PlayerCustomCss: React.FC = () => {
    const injectedCustomCss = (window as any).pagesCustomCss;

    if (injectedCustomCss === undefined) {
        return null;
    }

    return <style>{injectedCustomCss}</style>;
};

const GlideAppPlayer2: React.FC = () => {
    return (
        <>
            <PlayerCustomCss />
            <GlideAppPlayerLazy />
        </>
    );
};

function GlideAppPlayerRoute(p: RouteComponentProps<{ id: string }>) {
    const appID = decodeURIComponent(p.match.params.id);
    return (
        <>
            <PlayerCustomCss />
            <GlideAppPlayer id={appID} />
        </>
    );
}

const CustomDomainGlideAppPlayer: React.FC = () => {
    return (
        <>
            <PlayerCustomCss />
            <GlideAppPlayer />
        </>
    );
};

function AIPlaygroundElement() {
    return (
        <Suspense fallback={<div />}>
            <AIPlayground />
        </Suspense>
    );
}

function Designer() {
    return (
        <Suspense fallback={<div />}>
            <App />
        </Suspense>
    );
}

function TemplatePreviewer() {
    // to show a preview of the template in the dashboard, we load this into an iframe,
    // the target should change accordingly
    return (
        <Suspense fallback={<div />}>
            <LazyTemplatePreviewer isIframe={window.self !== window.top} />
        </Suspense>
    );
}

function render() {
    let rootRoutes: React.ReactElement<any>;

    const shouldPlayOnPlay2 = (window as any).shouldUsePlay2 === true;

    if (isCustomDomain(window.location.hostname)) {
        const PlayerComponent = shouldPlayOnPlay2 ? GlideAppPlayer2 : CustomDomainGlideAppPlayer;

        rootRoutes = (
            <Switch>
                <Route exact={false} path={routes.play2OauthRedirect} component={OAuthRedirectPageLazy} />
                <Route exact={false} path={routes.customDomainPlayPattern} component={PlayerComponent} />
            </Switch>
        );
    } else {
        const PlayerComponent = shouldPlayOnPlay2 ? GlideAppPlayer2 : GlideAppPlayerRoute;

        rootRoutes = (
            <ErrorBoundary>
                <Switch>
                    <Route exact={true} path={routes.AIPlayground} component={AIPlaygroundElement} />
                    <Route exact={false} path={routes.templatePreviewPattern} component={TemplatePreviewer} />
                    <Route exact={false} path={routes.playPattern} component={PlayerComponent} />
                    <Route exact={false} path={routes.play2Pattern} component={GlideAppPlayer2} />
                    <Route exact={false} path={routes.play2OauthRedirect} component={OAuthRedirectPageLazy} />
                    <Route component={Designer} />
                </Switch>
            </ErrorBoundary>
        );
    }

    const root = createRoot(document.getElementById("root") as HTMLElement);
    root.render(
        <Router>
            <GlobalStyles />
            <AppStateProvider>
                <TailwindThemeProvider theme={getBuilderTheme(false)}>{rootRoutes}</TailwindThemeProvider>
            </AppStateProvider>
        </Router>
    );
}

function maybeSetExplicitLocation() {
    // "local" is used with local kubernetes (scripts/local-env), whereas dev
    // is for when you're running local code that talks to staging.
    if (process.env.GLIDE_IS_LOCAL === "true") {
        setExplicitLocation("local");
    }
}

function mainWithLocalizationLoaded() {
    // We do this only here, early, so that it's not easy to inject the
    // variable which should be set by `play`.
    initDebugDataViewer();

    initFeatureFlagsWithStorage();
    // We sometimes need to override the location settings.
    maybeSetExplicitLocation();
    initFirebase();
    monitorSafariStateHack();
    // This improves the entropy of Firestore ID generation.
    // Math.random isn't guaranteed to be a particularly good source
    // of random values, so we sort of reimplement it using
    // window.crypto.getRandomValues if it is available.
    setFirestoreIDGeneratorRNG(cryptoRandom);
    registerFrontendEventSender(honeycombSendEvent, false);
    reportLastReloadReason();
    reportLastAuthFailureReason();
    trapFirestoreHttpErrors();

    registerServiceWorker();
    initializeAnalytics(() => firebase.analytics()).catch((e: unknown) => {
        logError(`Could not initialize analytics: ${e}`);
    });
    setShouldLog(() => getFeatureFlag("debugPrint"));
    addFirestorePermanentFailureHandler(() => {
        // We're going to get the referrer in the event anyway, which is enough for debugging.
        frontendSendEvent("irrecoverable Firestore error", 0, { userAgent: shortUserAgent });
    });

    // Firestore will enable persistence only if the window is standalone when it is first loaded.
    // If the window becomes standalone (which would enable persistence), then we must refresh so that Firestore has a
    // chance to enable its persistence.
    initRefreshOnStandalone();
    if (standalone) {
        // NOTE: Any new lazy-loaded components must be added here to work in offline mode!
        // If you don't, the user will need to lazy-load them online first, then go offline.
        const preInstall = async () => {
            const preinstallStart = window.performance.now();
            const FormSubmissionSuccessToast = import(
                "./webapp/chrome/views/form-submission-success-toast/form-submission-success-toast"
            );
            const QRCode = import("qrcode.react");
            const BarChart = import("./webapp/ui/smart/charts/bar-chart/bar-chart");
            const ComponentContextMenu = import("./sharedUI/ui/smart/component-context-menu/component-context-menu");
            const MarkdownView = import("@glide/wire-renderer");
            const PieChart = import("./webapp/ui/smart/charts/pie-chart/pie-chart");
            const StackedBarChart = import("./webapp/ui/smart/charts/stacked-bar-chart/stacked-bar-chart");
            const VideoPlayer = import("./webapp/ui/smart/video-player/video-player");
            const AS = import("react-virtualized/dist/es/AutoSizer");
            const MaterialDateTimePicker = import(
                "./webapp/ui/smart/date-time-picker/private/material-date-time-picker"
            );
            const ImageOverlayView = import("./webapp/ui/views/image-overlay/image-overlay-view");
            const MapBoxMap = import("./webapp/ui/smart/map-list/private/mapbox-map-list-map");
            await Promise.all([
                FormSubmissionSuccessToast,
                QRCode,
                BarChart,
                ComponentContextMenu,
                MarkdownView,
                PieChart,
                StackedBarChart,
                VideoPlayer,
                AS,
                MaterialDateTimePicker,
                ImageOverlayView,
                MapBoxMap,
            ]);
            frontendSendEvent("preinstall components", window.performance.now() - preinstallStart, {
                userAgent: shortUserAgent,
                standalone,
            });
        };

        setTimeout(() => {
            preInstall().catch(err => logInfo("Failed to install modules", err));
        }, 3500);
    }

    watchUpdates().catch(err => logInfo(err));

    disableContext();
    disableBounce();

    registerLongPress(window, document);
    preparePushNotifications().catch(e => logInfo("Could not prepare push notifications", e));

    const onConnected = () => setOriginOnline(true);
    const onDisconnected = () => setOriginOnline(false);
    addOnFunctionNetworkError(onDisconnected);
    addOnFunctionNetworkSuccess(onConnected);
    // We used to use navigator.onLine, window.ononline, and window.onoffline
    // to detect network connectivity. We can no longer do this due to either
    // a bug in macOS Big Sur, a bug in Chromium, or a bug in both. See
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1236624.
    //
    // This practice was mostly unnecessary. Firestore Listener errors give us
    // a slightly delayed "offline" signal compared to the "offline" event,
    // under the assumption that the "offline" event is not firing false
    // positives. Since "offline" is now known to fire false positives without
    // ever recovering with a true negative, we have to abandon it and live
    // with the slight delay incurred by the Firestore Listener error handlers.
    //
    // We're going to have to do something similar when we finally migrate
    // off Firestore.

    const loadStart = (window as any).loadStart;
    if (typeof loadStart === "number") {
        const loadEnd = window.performance.now();
        frontendSendEvent("bootstrap", loadEnd - loadStart, { userAgent: shortUserAgent, standalone });
    }

    render();
}

const ddInitializeTiming = PlayerObservability.makePlayerTiming("dd-initialize");
const initializeForApp = PlayerObservability.makePlayerEvent("initialized-for-app", ["appID"]);
function initializePlayObservability() {
    if (!routes.isPlayer()) return;

    const shouldUsePlay2 = (window as any).shouldUsePlay2 === true;
    PlayerObservability.initialize({
        logToCLI: window.location.hostname === "localhost",
        forLegacy: !shouldUsePlay2,
    });

    ddInitializeTiming.track();
    initializeForApp.track({
        appID: (window as any).appID ?? "unknown",
    });
}

function main() {
    enableMapSet();
    (window as any).glidebeacon = true;
    const r = document.getElementById("reload-area");
    if (r !== null) {
        r.style.display = "none";
    }

    let { language } = window.navigator;

    // This has to be in here, and not in `initializeLocalization`, because
    // the latter is in `common` and can't use `browser-detect`.
    // See https://github.com/quicktype/glide/issues/6157
    if (browserIsSafari) {
        if (language === "pt") {
            language = "pt-BR";
        } else if (language === "en") {
            language = "en-US";
        }
    }

    // See https://bugs.webkit.org/show_bug.cgi?id=226547 for the motivation behind
    // this. It is possible on certain versions of Safari for the initial connection
    // request to hang indefinitely. However, a valid workaround is to make multiple
    // connection requests: the later ones succeed where the eariler ones fail.

    // This was not a part of anyone's failure expectations for IndexedDB, so we have
    // to monkey-patch it, particularly for Firebase.
    hookIndexedDBForUnreliableOpens(window);

    // We'll initialize observability as soon as possible.
    initializePlayObservability();

    // Mind the .catch().then().catch() chain.
    // If we reject while trying to load localization, we reload.
    // If mainWithLocalizationLoaded() crashes then we just log it.
    // FIXME: Is that the most appropriate thing to do?

    initializeLocalization(language)
        .catch(() => reloadBrowserWindow("localization initialization failed"))
        .then(() => mainWithLocalizationLoaded())
        .catch(e => logError(e));
}

main();
