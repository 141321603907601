import type { DropImageHandler } from "@glide/common-core/dist/js/components/portable-renderers";
import { massageCloudURL } from "@glide/common-core/dist/js/components/portable-renderers";
import type { DropData } from "@glide/common-core/dist/js/drop-controller";
import { setDropData } from "@glide/common-core/dist/js/drop-controller";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import * as React from "react";
import { GLIDE_NEW_COMPONENT_DATA_FORMAT } from "../../wire-lib";

interface Props extends React.PropsWithChildren {
    onDropImage?: DropImageHandler;
}

interface State {
    dragOver: boolean;
}

export class Droppable<TProps extends Props, TState extends State> extends React.PureComponent<TProps, TState> {
    private onDragOver = (ev: React.DragEvent<HTMLElement>) => {
        if (ev.dataTransfer.types.some(t => t.startsWith("text/glide")) || isPlayer()) return;
        ev.preventDefault();
        ev.stopPropagation();

        this.setState({ dragOver: true });
    };

    private onDragLeave = (ev: React.DragEvent<HTMLElement>) => {
        ev.preventDefault();
        if (isPlayer()) return;

        this.setState({ dragOver: false });
    };

    private onDrop = (ev: React.DragEvent<HTMLElement>) => {
        if (ev.dataTransfer.types.includes(GLIDE_NEW_COMPONENT_DATA_FORMAT)) return;
        ev.preventDefault();
        ev.stopPropagation();
        if (isPlayer()) return;

        this.setState({ dragOver: false });

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                const item = ev.dataTransfer.items[i];
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    if (file !== null) {
                        this.onFileDropped(file);
                        // break for now since we dont know what to do with multiple
                        break;
                    }
                } else if (item.kind === "string" && item.type === "text/uri-list") {
                    item.getAsString(uri => this.onURIDropped(uri));
                    // break for now since we dont know what to do with multiple
                    break;
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                const file = ev.dataTransfer.files[i];
                this.onFileDropped(file);
                // break for now since we dont know what to do with multiple
                break;
            }
        }
    };

    protected onDataDropped(dropData: DropData): void {
        const f = this.props.onDropImage;
        if (f !== undefined) {
            const handle = setDropData(dropData);
            f(handle);
        }
    }

    private onFileDropped(file: File): void {
        this.onDataDropped({ kind: "file", file });
    }

    private onURIDropped(uri: string): void {
        uri = massageCloudURL(uri);
        this.onDataDropped({ kind: "uri", uri });
    }

    protected getDroppableProps() {
        if (this.props.onDropImage === undefined) {
            return {};
        }
        return {
            onDrop: this.onDrop,
            onDragLeave: this.onDragLeave,
            onDragOver: this.onDragOver,
        };
    }

    protected getDroppableStyle(): React.CSSProperties {
        if (this.props.onDropImage === undefined) {
            return {};
        }

        let style: React.CSSProperties = {
            transition: "transform 0.2s",
        };

        if (this.state.dragOver) {
            style = {
                ...style,
                transform: "scale(1.1)",
                filter: "brightness(60%)",
            };
        }

        return style;
    }
}

export class DroppableDiv extends Droppable<
    Props & { className?: string; dragOverClassName?: string; innerRef?: React.Ref<HTMLDivElement> },
    State
> {
    public state: State = {
        dragOver: false,
    };
    public render(): React.ReactNode {
        let c = this.props.className ?? "";
        if (this.state.dragOver) {
            c += " " + this.props.dragOverClassName ?? "";
        }
        return (
            <div className={c} {...this.getDroppableProps()} ref={this.props.innerRef}>
                {this.props.children}
            </div>
        );
    }
}
