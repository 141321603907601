import { defined } from "@glideapps/ts-necessities";
import type { SimpleModalContextProps } from "./modal-context";

// We don't expose this singleton so we have better control of how this is accessed and mutated.
let modalsV2: SimpleModalContextProps | undefined = undefined;

/**
 * @deprecated
 * We still need this to support `getModals` (deprecated).
 */
export function bindModals(contextModals: SimpleModalContextProps): void {
    modalsV2 = contextModals;
}

export function unbindModals(): void {
    modalsV2 = undefined;
}

/**
 * @deprecated
 * DISCLAMER:
 *
 * This API should be avoided. For historical reasons, we sometimes trigger
 * fire-and-forget modals from redux actions. As you can't consume context from
 * actions, we provide this late-binding singleton API.
 *
 * Keep in mind this is just for compatibility reasons, and usually there will be
 * a better way to trigger modals than using this API.
 */
export function getModals(): SimpleModalContextProps {
    return defined(modalsV2, "Cannot access ModalsV2 before mounting SimpleModalProviderV2");
}
