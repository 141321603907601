import React from "react";

interface DisplayProps {
    className?: string;
    elapsedTime: number;
}

const formatTime = (milliseconds: number): string => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600)
        .toString()
        .padStart(2, "0");
    const minutes = Math.floor((totalSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0");
    const seconds = Math.floor(totalSeconds % 60)
        .toString()
        .padStart(2, "0");
    const hundredths = Math.floor((milliseconds % 1000) / 10)
        .toString()
        .padStart(2, "0");
    return `${hours}:${minutes}:${seconds}.${hundredths}`;
};

export const StopwatchDisplay: React.FC<DisplayProps> = p => {
    const { className, elapsedTime } = p;
    const formattedTime = React.useMemo(() => formatTime(elapsedTime), [elapsedTime]);
    return (
        <div test-id="stopwatch-time" className={className} style={{ fontVariantNumeric: "tabular-nums" }}>
            {formattedTime}
        </div>
    );
};
