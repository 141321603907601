import PubSub from "pubsub-js";

export enum ConfirmModalStyle {
    Confirm = "confirm",
    Delete = "delete",
    QuotaWarning = "quota-warning",
}

export interface ConfirmArgs {
    readonly title: string;
    readonly accept: string;
    readonly cancel?: string;
    readonly description: string;
    readonly style: ConfirmModalStyle;
    awaitable?: Promise<boolean>;
}

export const confirmModalTopic = "SHOW_CONFIRM_MODAL";

export async function showConfirmModal(args: ConfirmArgs): Promise<boolean | undefined> {
    PubSub.publishSync(confirmModalTopic, args);

    return await args.awaitable;
}
