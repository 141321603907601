import {
    type ColumnType,
    type SourceColumn,
    type TableAndColumn,
    type TableColumn,
    type TableGlideType,
    type TableOrColumns,
    type TableOrColumnsAndColumn,
    SourceColumnKind,
    getSourceColumnPath,
    getTableColumn,
    isSingleRelationType,
    makeTableRef,
    decomposeActionNodeOutputSourceColumn,
    type SchemaInspector,
    decomposeRelationType,
} from "@glide/type-schema";
import { assertNever, definedMap } from "@glideapps/ts-necessities";
import { isArray } from "@glide/support";
import type { ActionNodeInScope } from "./property/lib";

export const thisRowSourceColumn: SourceColumn = { kind: SourceColumnKind.DefaultContext, name: [] };
export const userProfileRowSourceColumn: SourceColumn = { kind: SourceColumnKind.UserProfile, name: [] };

export interface ResolvedSourceColumn<T extends TableOrColumnsAndColumn> {
    // The original source column that this is resolving.
    readonly sourceColumn: SourceColumn;
    // Can be `undefined` if we're called with a column array, or without a
    // table.
    readonly type: ColumnType | undefined;
    // The context table (default, user profile, or containing screen), if we
    // have it.
    readonly contextTable: TableGlideType | undefined;
    // Will be empty for "whole row"
    readonly path: readonly T[];
    // The last item in `path`
    readonly tableAndColumn: T | undefined;
}

interface SourceColumnContext {
    readonly context: SchemaInspector;
    readonly defaultTable: TableOrColumns | undefined;
    readonly containingScreenTable: TableOrColumns | undefined;
    readonly actionNodesInScope: readonly ActionNodeInScope[];
}

export function resolveSourceColumnFromEnvironment(
    sc: SourceColumn,
    env: SourceColumnContext
): ResolvedSourceColumn<TableOrColumnsAndColumn> | undefined {
    let table: readonly TableColumn[] | TableGlideType | undefined;
    if (sc.kind === SourceColumnKind.DefaultContext) {
        table = env.defaultTable;
    } else if (sc.kind === SourceColumnKind.UserProfile) {
        const tableName = env.context.userProfileTableInfo?.tableName;
        if (tableName !== undefined) {
            table = env.context.findTable(tableName);
        }
    } else if (sc.kind === SourceColumnKind.ContainingScreen) {
        table = env.containingScreenTable;
    } else if (sc.kind === SourceColumnKind.ActionNodeOutput) {
        const decomposed = decomposeActionNodeOutputSourceColumn(sc);
        if (decomposed === undefined) return undefined;
        const [actionNodeKey, outputName, columnInRow] = decomposed;
        const actionNodeInScope = env.actionNodesInScope.find(a => a.node.key === actionNodeKey);
        if (actionNodeInScope === undefined) return undefined;

        const output = actionNodeInScope.outputs.find(o => o.name === outputName);
        if (output === undefined) return undefined;

        if (columnInRow === undefined) {
            const maybeTable = decomposeRelationType(output.type);
            return {
                sourceColumn: sc,
                type: output.type,
                contextTable: definedMap(maybeTable, t => env.context.findTable(t.tableRef)),
                path: [],
                tableAndColumn: undefined,
            };
        } else {
            if (!isSingleRelationType(output.type)) return undefined;
            table = env.context.findTable(output.type);
            if (table === undefined) return undefined;
            const column = getTableColumn(table, columnInRow);
            if (column === undefined) return undefined;
            const tableAndColumn = { table, column };
            return {
                sourceColumn: sc,
                type: column.type,
                contextTable: table,
                path: [tableAndColumn],
                tableAndColumn,
            };
        }
    } else {
        return assertNever(sc.kind);
    }

    let contextTable: TableGlideType | undefined;
    if (!isArray(table)) {
        contextTable = table;
    }

    const resolved: TableOrColumnsAndColumn[] = [];
    let tableAndColumn: TableOrColumnsAndColumn | undefined;
    for (const columnName of getSourceColumnPath(sc)) {
        if (table === undefined) return undefined;

        const column = getTableColumn(table, columnName);
        if (column === undefined) return undefined;

        tableAndColumn = { table, column };
        resolved.push(tableAndColumn);

        if (isSingleRelationType(column.type)) {
            table = env.context.findTable(column.type);
        }
    }

    let type: ColumnType | undefined;
    if (tableAndColumn !== undefined) {
        type = tableAndColumn.column.type;
    } else if (table !== undefined && !isArray(table)) {
        type = makeTableRef(table);
    }

    return { sourceColumn: sc, type, contextTable, path: resolved, tableAndColumn };
}

export function resolveSourceColumn(
    schemaInspector: SchemaInspector,
    sc: SourceColumn,
    defaultTable: TableGlideType | undefined,
    containingScreenTable: TableGlideType | undefined,
    actionNodesInScope: readonly ActionNodeInScope[] | undefined
): ResolvedSourceColumn<TableAndColumn> | undefined;
export function resolveSourceColumn(
    schemaInspector: SchemaInspector,
    sc: SourceColumn,
    defaultTable: readonly TableColumn[] | TableGlideType | undefined,
    containingScreenTable: readonly TableColumn[] | TableGlideType | undefined,
    actionNodesInScope: readonly ActionNodeInScope[] | undefined
): ResolvedSourceColumn<TableOrColumnsAndColumn> | undefined;
export function resolveSourceColumn(
    schemaInspector: SchemaInspector,
    sc: SourceColumn,
    defaultTable: readonly TableColumn[] | TableGlideType | undefined,
    containingScreenTable: readonly TableColumn[] | TableGlideType | undefined,
    actionNodesInScope: readonly ActionNodeInScope[] | undefined
): ResolvedSourceColumn<TableOrColumnsAndColumn> | undefined {
    return resolveSourceColumnFromEnvironment(sc, {
        context: schemaInspector,
        defaultTable,
        containingScreenTable,
        actionNodesInScope: actionNodesInScope ?? [],
    });
}

/**
 * This is not to be used for automations, because it can't resolve prior
 * action step outputs.
 */
export function getTableAndColumnForSourceColumn(
    schemaInspector: SchemaInspector,
    sc: SourceColumn,
    table: TableGlideType | undefined,
    containingScreenTable: TableGlideType | undefined
): TableAndColumn | undefined {
    return resolveSourceColumn(schemaInspector, sc, table, containingScreenTable, undefined)?.tableAndColumn;
}

export function getColumnForSourceColumnFromEnvironment(
    sc: SourceColumn,
    env: SourceColumnContext
): TableColumn | undefined {
    return resolveSourceColumnFromEnvironment(sc, env)?.tableAndColumn?.column;
}

export function getColumnForSourceColumn(
    schemaInspector: SchemaInspector,
    sc: SourceColumn,
    columnsOrTable: readonly TableColumn[] | TableGlideType | undefined,
    containingScreenColumnsOrTable: readonly TableColumn[] | TableGlideType | undefined,
    actionNodesInScope: readonly ActionNodeInScope[]
): TableColumn | undefined {
    return getColumnForSourceColumnFromEnvironment(sc, {
        context: schemaInspector,
        defaultTable: columnsOrTable,
        containingScreenTable: containingScreenColumnsOrTable,
        actionNodesInScope,
    });
}
