import { AppKind } from "@glide/location-common";
import { isLoadingValue, boundMap } from "@glide/computation-model-types";
import { asMaybeArrayOfStrings, asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import type {
    ComponentDescription,
    ComponentKind,
    MutatingScreenKind,
    PropertyDescription,
} from "@glide/app-description";
import { getSourceColumnProperty, makeColumnProperty } from "@glide/app-description";
import { type TableColumn, isPrimitiveArrayType, isPrimitiveType } from "@glide/type-schema";
import {
    type DefaultableComponentDescription,
    type InputOutputTables,
    makeEmptyComponentDescription,
} from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type {
    WireFilePickerComponentBase,
    WireMultiPickerValue,
    WireSinglePickerValue,
} from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
    isRequiredPropertyHandler,
    getPrimitiveOrPrimitiveArrayNonHiddenColumnsSpec,
} from "@glide/function-utils";
import { assert } from "@glideapps/ts-necessities";
import { isEmptyOrUndefined } from "@glide/support";
import {
    type WireRowComponentHydrationBackend,
    type WireRowComponentHydratorConstructor,
    type WireComponent,
    WireComponentKind,
    type WireInflationBackend,
} from "@glide/wire";
import isBoolean from "lodash/isBoolean";
import { makeSimpleWireRowComponentHydratorConstructor, spreadComponentID } from "../wire/utils";
import {
    doesMutatingScreenSupportIsRequired,
    labelCaptionStringOptions,
    makeCaptionStringPropertyDescriptor,
    makeDefaultValuePropertyDescriptor,
} from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

export const ComponentKindFilePicker: ComponentKind = "file-picker";

const propertyNamePropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    ["file", "upload"],
    getPrimitiveOrPrimitiveArrayNonHiddenColumnsSpec,
    "uri",
    PropertySection.Data
);
const includeFilenamePropertyHandler = new SwitchPropertyHandler(
    { includeFilename: false },
    "Keep file name when uploading",
    PropertySection.Options
);

export interface FilePickerComponentDescription extends DefaultableComponentDescription {
    readonly caption: PropertyDescription | undefined;
}

type InflatedEditableValueForFilePicker =
    | [
          getter: (hb: WireRowComponentHydrationBackend) => WireMultiPickerValue | WireSinglePickerValue | undefined,
          editsInContext: boolean
      ]
    | undefined;

function inflateEditableValueForFilePicker(
    ib: WireInflationBackend,
    desc: FilePickerComponentDescription
): InflatedEditableValueForFilePicker {
    const sourceColumn = getSourceColumnProperty(desc.propertyName);
    if (sourceColumn === undefined) return undefined;

    const { tableAndColumn, tokenMaker, isInContext } = ib.getValueSetterForProperty(desc.propertyName, "set-files");
    if (tableAndColumn === undefined) return undefined;

    const [getter, type] = ib.getValueGetterForSourceColumn(sourceColumn, true, false);
    if (type === undefined) return undefined;

    const isMulti = isPrimitiveArrayType(type);

    return [
        hb => {
            const value = getter(hb);
            if (value === null || isLoadingValue(value)) return undefined;

            const onChangeToken = tokenMaker(hb);
            if (onChangeToken === false) return undefined;

            if (isMulti) {
                return {
                    value: {
                        value: asMaybeArrayOfStrings(value) ?? [],
                        onChangeToken,
                    },
                    isMultiple: true,
                };
            } else {
                return {
                    value: {
                        value: asMaybeString(value) ?? "",
                        onChangeToken,
                    },
                    isMultiple: false,
                };
            }
        },
        isInContext,
    ];
}

export function inflateFileOrImagePicker(
    ib: WireInflationBackend,
    desc: FilePickerComponentDescription,
    finish: (partial: Omit<WireFilePickerComponentBase, "kind">) => WireComponent
): WireRowComponentHydratorConstructor | undefined {
    const { forBuilder } = ib;

    const isRequired = isRequiredPropertyHandler.getSwitch(desc);
    const includeFilename = includeFilenamePropertyHandler.getSwitch(desc);

    const [titleGetter] = ib.getValueGetterForProperty(desc.caption, true);

    const maybeGetter = inflateEditableValueForFilePicker(ib, desc);
    if (maybeGetter === undefined) return undefined;
    const [getter, editsInContext] = maybeGetter;

    return makeSimpleWireRowComponentHydratorConstructor(hb => {
        const title = boundMap(titleGetter(hb), asMaybeString);

        const singleOrMultiValue = getter(hb);
        if (singleOrMultiValue === undefined) return undefined;
        const { value } = singleOrMultiValue;

        const hasValue = !isEmptyOrUndefined(value.value);

        const isBusy = hb.getState("busy", isBoolean, false, false);

        return {
            component: finish({
                ...spreadComponentID(desc.componentID, forBuilder),
                editableValue: singleOrMultiValue,
                title,
                isBusy,
                isRequired,
                includeFilename,
            }),
            isValid: !isBusy.value && (hasValue || !isRequired),
            editsInContext,
            hasValue,
        };
    });
}

// FIXME: Generalize this with `ImagePickerComponentHandler`
export class FilePickerComponentHandler extends ComponentHandlerBase<FilePickerComponentDescription> {
    constructor() {
        super(ComponentKindFilePicker);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public get appKinds(): AppKind | "both" {
        return "both";
    }

    public needValidation(): boolean {
        return true;
    }

    public getDescriptor(
        desc: FilePickerComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [
            propertyNamePropertyHandler,
            makeCaptionStringPropertyDescriptor("File", false, mutatingScreenKind, labelCaptionStringOptions),
            includeFilenamePropertyHandler,
        ];
        if (doesMutatingScreenSupportIsRequired(mutatingScreenKind, desc?.propertyName)) {
            properties.push(isRequiredPropertyHandler);
        }

        if (ccc.appKind === AppKind.App) {
            const defaultDescr = makeDefaultValuePropertyDescriptor(ccc, desc?.propertyName, mutatingScreenKind, "uri");
            if (defaultDescr !== undefined) {
                properties.push(defaultDescr);
            }
        }

        properties.push(...this.getBasePropertyDescriptors());
        return {
            name: "File Picker",
            description: "Upload any file",
            img: "co-file-picker",
            group: ccc.appKind === AppKind.Page ? "Form Elements" : "Pickers",
            helpUrl: getDocURL("filePicker"),
            properties,
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: FilePickerComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        return inflateFileOrImagePicker(ib, desc, p => ({ ...p, kind: WireComponentKind.FilePicker }));
    }

    public static defaultComponent(column: TableColumn): FilePickerComponentDescription {
        assert(isPrimitiveType(column.type) || isPrimitiveArrayType(column.type));
        return {
            ...makeEmptyComponentDescription(ComponentKindFilePicker),
            propertyName: makeColumnProperty(column.name),
            defaultValue: undefined,
            caption: undefined,
        };
    }

    public convertToPage(
        desc: FilePickerComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return desc;
    }
}
