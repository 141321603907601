import * as React from "react";

import ListConfigUIItemView from "./list-config-ui-item-view";
import type { SettingsElement, SettingsElementWithSectionTitle } from "./list-config-ui-types";

interface Props extends React.PropsWithChildren {
    list: SettingsElementWithSectionTitle[];
    selected?: SettingsElement;
    onListItemClick: (item: SettingsElement) => (e: React.MouseEvent) => void;
}

export default class ListConfigUIView extends React.PureComponent<Props, {}> {
    render() {
        const { list, onListItemClick, selected } = this.props;

        return (
            <>
                {list.map(item =>
                    typeof item === "string" ? (
                        <div
                            key={`${item}-list-item`}
                            tw="h-8 mt-2 px-5 flex items-center uppercase font-semibold text-builder-xs">
                            {item}
                        </div>
                    ) : (
                        <div
                            key={`${item.title}-list-item`}
                            className={selected === item ? "selected" : "normal"}
                            onClick={onListItemClick(item)}>
                            <ListConfigUIItemView item={item} />
                        </div>
                    )
                )}
            </>
        );
    }
}
