import { convertOAuth2RedirectTokenToState } from "@glide/common-core/dist/js/authorization/auth";
import type { AppFeatures } from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import { defaultAppFeatures } from "@glide/common-core/dist/js/components/SerializedApp";

import type { AuthenticationMethod } from "@glide/common-core/dist/js/Database";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import { isGoogleSignInEnabled } from "@glide/common-core/dist/js/utility/pins";
import { isArray } from "@glide/support";
import { UIButtonAppearance } from "@glide/wire";
import type { ReactNode } from "react";

import { GoogleAuthProviderButton, GoogleSignInButton } from "./google";
import type { AuthProviderCallbacks, InteractionProps } from "./types";
import { WireGoogleAuthProviderButton, WireGoogleSignInButton } from "./wire-google-sso";
import { WireButton } from "../../../renderers/wire-button/wire-button";
import type { SignOnButton } from "@glide/plugins";

type MethodFeatures = Readonly<{
    authMethod: AuthenticationMethod | undefined;
    appFeatures: AppFeatures | undefined;
}>;

type Props = MethodFeatures & AuthProviderCallbacks & InteractionProps;

// This is for use in the player, so we don't use `isSSOSignInEnabled` but
// instead we trust the app features and the `signOnButtons` we get from the
// `play` function.
export function canShowExternalAuthButtons(appFeatures: AppFeatures | undefined): boolean {
    return isGoogleSignInEnabled(appFeatures) || showSSOSignInButtons(appFeatures);
}

function showSSOSignInButtons(appFeatures: AppFeatures | undefined): boolean {
    // In theory it should be enough to just check for the `signOnButtons`,
    // but it doesn't hurt being convervative and also checking the feature.
    const isEnabled = appFeatures?.showSignInWithSSO ?? defaultAppFeatures.showSignInWithSSO;
    if (!isEnabled) return false;
    if (!isArray((window as any).signOnButtons)) return false;
    return (window as any).signOnButtons.length > 0;
}

export function ExternalAuthProviderButtons(props: Props): JSX.Element | null {
    const buttons: ReactNode[] = [];
    const googleAuthButton = buildGoogleAuthButton(props);
    if (googleAuthButton) {
        buttons.push(googleAuthButton);
    }

    for (const signOnButton of buildSignOnButtons(props)) {
        buttons.push(signOnButton);
    }

    return <>{buttons}</>;
}

function buildSignOnButtons(props: Props): ReactNode[] {
    const signOnButtons = (window as any).signOnButtons as readonly SignOnButton[];
    if (!isArray(signOnButtons)) return [];

    return signOnButtons.map((signOnButton, i) => {
        const key = `signOnButton=${i}`;
        return (
            <WireButton
                key={key}
                appearance={UIButtonAppearance.Bordered}
                tw="mt-3 w-full"
                disabled={!props.userAgreed}
                onClick={() => props.createPopupForSignOnFlow(signOnButton.url)}
            >
                {signOnButton.label}
            </WireButton>
        );
    });
}

function buildGoogleAuthButton(props: Props): ReactNode {
    if (!isGoogleSignInEnabled(props.appFeatures)) return;

    if (!isPlayer()) {
        if (props.appKind === AppKind.Page) {
            return <WireGoogleSignInButton key="google" userAgreed={props.userAgreed} appKind={props.appKind} />;
        }
        return <GoogleSignInButton key="google" userAgreed={props.userAgreed} appKind={props.appKind} />;
    }

    // We can't show any of the external auth buttons if we don't have any state
    // to pass through the authentication flows: that state is what tells the
    // receiving Cloud Function to redirect to us!
    const oauth2State = convertOAuth2RedirectTokenToState();
    if (oauth2State === undefined) {
        return;
    }

    if (props.appKind === AppKind.Page) {
        return <WireGoogleAuthProviderButton key="google" {...props} />;
    } else {
        return <GoogleAuthProviderButton key="google" {...props} />;
    }
}
