// Inputs are specified as arrays of objects.  The memoization
// table is a tree of `WeakMap`s, where the first map is indexed
// by the first entry in the array, the result of which, if it's
// not empty, has a list of memoized results for where the input
// is only that first object, as well as another map for a second
// array item, etc.

import { logInfo } from "./debug-print";

interface Node<T> {
    result: T | undefined;
    next: WeakMap<any, Node<T>> | undefined;
}

interface InternedPrimitive {
    readonly value: unknown;
}

const internedPrimitives = new Map<unknown, InternedPrimitive>();

function internIfNecessary(x: unknown): unknown {
    if (x instanceof Object) {
        return x;
    } else {
        let i = internedPrimitives.get(x);
        if (i === undefined) {
            i = { value: x };
            internedPrimitives.set(x, i);
            if (internedPrimitives.size >= 1000) {
                internedPrimitives.clear();
                logInfo("Cleared interned primitives");
            }
        }
        return i;
    }
}

export function memoizeFunction<TFunction extends (...args: any[]) => any>(
    _name: string,
    f: TFunction,
    numArgsToMemoize?: number
): TFunction {
    const root: Node<ReturnType<TFunction>> = { result: undefined, next: undefined };
    let numCalls = 0;
    let numHits = 0;
    let nextPrint = 10;

    return ((...args: any[]): ReturnType<TFunction> => {
        numCalls++;

        let node = root;
        let numArgs = args.length;
        if (numArgsToMemoize !== undefined && numArgsToMemoize < numArgs) {
            numArgs = numArgsToMemoize;
        }
        for (let i = 0; i < numArgs; i++) {
            if (node.next === undefined) {
                node.next = new WeakMap();
            }

            const v = args[i];
            const w = internIfNecessary(v);
            let next = node.next.get(w);
            if (next === undefined) {
                next = { result: undefined, next: undefined };
                node.next.set(w, next);
            }

            node = next;
        }
        if (node.result === undefined) {
            node.result = f(...args);
        } else {
            numHits++;
        }

        if (numCalls === nextPrint) {
            // NOTE: uncomment this for debugging, but don't commit it!
            // https://github.com/glideapps/glide/pull/23358
            // logInfo(`memoize ${name} calls ${numCalls} hits ${numHits}`);
            void numHits;
            nextPrint *= 10;
        }

        return node.result as ReturnType<TFunction>;
    }) as TFunction;
}

// A trick to memoizing functions where some inputs are ad-hoc
// constructed objects, and/or primitive values: we keep a list
// of interned values of those around, and look up the
// object/primitive in that list for deep equality.  We'll keep
// the list to some small fixed size, to keep memory usage in
// check.  That will only work if the number of distinct values
// is reasonably small.  That is certainly the case for app
// features as well as booleans, for example.
