import * as t from "io-ts";

export const googleDriveIntegrationHead = t.type({
    name: t.string,
    kind: t.literal("google-drive"),
});

export const gcpOAuthIntegrationHead = t.type({
    name: t.string,
    kind: t.literal("gcp-oauth"),
});

export const gcpGmailIntegrationHead = t.type({
    name: t.string, // gauth > idToken.payload.sub value
    kind: t.literal("gcp-gmail"),
});

export const stripeGenericIntegrationHead = t.type({
    name: t.string,
    kind: t.literal("stripe"),
});

export const microsoftIntegrationHead = t.type({
    name: t.string,
    kind: t.literal("microsoft"),
});

export const airtableIntegrationHead = t.type({ name: t.string, kind: t.literal("airtable") });

export const gcpIntegrationHead = t.type({
    name: t.string,
    kind: t.literal("gcp-service-account"),
});

export const stripeTemplateStoreIntegrationHead = t.type({
    name: t.string,
    kind: t.literal("stripe-template-store"),
});

const integrationHead = t.union([
    googleDriveIntegrationHead,
    stripeGenericIntegrationHead,
    stripeTemplateStoreIntegrationHead,
    microsoftIntegrationHead,
    airtableIntegrationHead,
    gcpIntegrationHead,
    gcpOAuthIntegrationHead,
    gcpGmailIntegrationHead,
]);

export const sharedIntegration = t.intersection([
    integrationHead,
    t.type({ userID: t.string }),
    t.partial({ username: t.string }),
]);
export type SharedIntegration = t.TypeOf<typeof sharedIntegration>;
