import type { QuotaStateForOrgResponse } from "@glide/common-core/dist/js/firebase-function-types";
import { useQuery } from "@tanstack/react-query";

import { getQuotaStateForOrg } from "../lib/usage-lib";

export function useQuotaState(orgID: string | undefined): {
    data: QuotaStateForOrgResponse | undefined;
    isLoading: boolean;
} {
    const { data, isLoading } = useQuery(
        ["useQuotaState", orgID ?? "never happens"],
        async ({ queryKey: [, id] }) => {
            return await getQuotaStateForOrg(id);
        },
        {
            enabled: orgID !== undefined && orgID !== "0",
            refetchInterval: 60_000,
            staleTime: Infinity,
            cacheTime: 5_000,
        }
    );
    return { data, isLoading };
}
