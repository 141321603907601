import { defaultEminenceFlagsValues } from "@glide/billing-types";
import { useEminenceFlags } from "@glide/common-components";
import { isDefined } from "@glide/support";
import React from "react";
import "twin.macro";
import { useOrgInfo } from "../../../hooks/use-org-info";
import { DataSourceIcon } from "../../data-sources/data-source-icon";
import { ImportFromSourceWideBox } from "../../data-sources/import-from-external-source";
import { ImportFromFileUpload } from "../../data-sources/import-from-file-upload";
import { Modal } from "../windowed-modal-next";

type ImportDataModalProps = {
    onBack: () => void;
    onClose: () => void;
    onContinue: (selectedFile: File | undefined, selectedImportType: string | undefined) => void;
    isUploading?: boolean;
};

export const ImportDataModal: React.FC<React.PropsWithChildren<ImportDataModalProps>> = p => {
    const { onBack, onClose, onContinue, isUploading } = p;

    const [selectedFile, setSelectedFile] = React.useState<File | undefined>(undefined);
    const [selectedImportType, setSelectedImportType] = React.useState<string | undefined>(undefined);
    const orgInfo = useOrgInfo();
    const callingOrgID = orgInfo.orgID;
    const { data: maybeEminenceFlags } = useEminenceFlags(callingOrgID);
    const eminenceFlags = maybeEminenceFlags ?? defaultEminenceFlagsValues;
    const hasBigTables = eminenceFlags.bigTables;

    const handleDroppedFile = React.useCallback((ev: React.DragEvent<HTMLElement>) => {
        ev.preventDefault();
        ev.stopPropagation();

        const { dataTransfer } = ev;
        const file = dataTransfer.items[0].getAsFile();
        if (file) {
            setSelectedFile(file);
        }
    }, []);

    const handleSelectedFile = React.useCallback((file: File) => {
        setSelectedImportType(undefined);
        setSelectedFile(file);
    }, []);

    const handleSelectedImportType = React.useCallback((type: string) => {
        setSelectedImportType(type);
        setSelectedFile(undefined);
    }, []);

    const isAbleToContinue = (isDefined(selectedFile) || isDefined(selectedImportType)) && !isUploading;

    const handleContinue = () => {
        onContinue(selectedFile, selectedImportType);
    };
    return (
        <Modal.Container onClose={onClose}>
            <Modal.Window size={"md"} tw="min-w-fit">
                <Modal.TitleBar tw="py-4 pr-5 pl-6 text-builder-2xl items-center">
                    <Modal.Title>Import data into Glide Tables</Modal.Title>
                    <Modal.Close onClose={onClose} />
                </Modal.TitleBar>
                <Modal.Content tw="py-2 px-8">
                    <ImportFromFileUpload
                        isUsersTable={false}
                        isUploading={false}
                        userNameColumnLabel={"userName"}
                        userEmailColumnLabel={"email"}
                        maxFileSize={hasBigTables ? 0 : 5_000_0000}
                        onFileDropped={handleDroppedFile}
                        onFileUpload={handleSelectedFile}
                        selectedFile={selectedFile}
                    />
                    <ImportFromSourceWideBox
                        tw="mt-5"
                        label={`Google Sheets`}
                        icon={<DataSourceIcon size={32} icon="gsheet" />}
                        selected={selectedImportType === "gsheet"}
                        onClick={() => handleSelectedImportType("gsheet")}
                        labelDescription="Each tab will become a table"
                    />
                </Modal.Content>
                <Modal.ButtonBar tw="py-6 px-8 pt-6" align="split">
                    <Modal.Button
                        tw="h-10 m-0"
                        buttonType="minimal"
                        size="lg"
                        label={"Back"}
                        variant="default"
                        onClick={onBack}
                    />
                    <Modal.Button
                        tw="h-10 m-0 bg-accent"
                        buttonType="primary"
                        size="lg"
                        label={isUploading ? "Uploading..." : "Continue"}
                        variant="default"
                        disabled={!isAbleToContinue}
                        onClick={handleContinue}
                    />
                </Modal.ButtonBar>
            </Modal.Window>
        </Modal.Container>
    );
};
