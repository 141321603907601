import type { WireBreadcrumb, WireBreadcrumbsComponent } from "@glide/fluent-components/dist/js/base-components";
import type { Breadcrumbs } from "@glide/fluent-components/dist/js/fluent-components";
import type { FluentComponent } from "@glide/fluent-components/dist/js/fluent-components-spec";
import type { ParsedScreen, WireRowComponentHydrationBackend } from "@glide/wire";
import { WireActionResult, WireComponentKind, WireNavigationAction, WireScreenPosition } from "@glide/wire";
import { assert } from "@glideapps/ts-necessities";
import { definedMap } from "collection-utils";
import type { ComponentEnricher } from "../wire/utils";
import { inflateComponentEnricher, makeSimpleWireRowComponentHydratorConstructor } from "../wire/utils";
import type { ComponentInflator } from "./fluent-components-handlers";
import { inlineScannerInflator } from "./inline-scanner";
import { signatureFieldInflator } from "./signature-field";

export function hydrateBreadcrumbs(
    componentEnricher: ComponentEnricher<WireBreadcrumbsComponent>,
    hb: WireRowComponentHydrationBackend,
    extraCrumbs: WireBreadcrumb[] = [],
    currentPageTitle?: string
) {
    const path = hb.getParsedPath();

    const rootScreen = path.getRootScreen();
    if (rootScreen === undefined) return undefined;
    const screenStack = path.getScreenStack();
    const tabScreenName = path.getTabScreenName();

    const tabTitle = hb.getScreenTitle(rootScreen);

    if (screenStack.length === 0) {
        return {
            component: componentEnricher({
                kind: WireComponentKind.Breadcrumbs,
                currentPageTitle: currentPageTitle ?? tabTitle,
                crumbs: [...extraCrumbs],
                crumbsOmitted: false,
            }),
            isValid: true,
        };
    }

    const modals = screenStack.filter((s: { kind: WireScreenPosition }) => s.kind === WireScreenPosition.Modal);

    const crumbs: WireBreadcrumb[] = [];
    let soFar: ParsedScreen[];
    let screensForCrumbs: ParsedScreen[];
    let omitted = false;

    if (modals.length === 0) {
        crumbs.push({
            title: tabTitle,
            action: definedMap(tabScreenName, tsn => ({
                token: hb.registerAction("depth-0", async ab =>
                    WireActionResult.fromResult(ab.navigateToTab(tsn, false))
                ),
            })),
        });
        soFar = [];
        screensForCrumbs = screenStack.slice(0, screenStack.length - 1);
    } else {
        const firstModalIndex = screenStack.findIndex(
            (s: { kind: WireScreenPosition }) => s.kind === WireScreenPosition.Modal
        );
        assert(firstModalIndex >= 0);
        soFar = screenStack.slice(0, firstModalIndex);
        screensForCrumbs = screenStack.slice(firstModalIndex, screenStack.length - 1);
    }

    for (;;) {
        const addCrumb = crumbs.length === 0 || screensForCrumbs.length <= 1;

        const screen = screensForCrumbs.shift();
        if (screen === undefined) break;

        soFar.push(screen);
        if (!addCrumb) {
            omitted = true;
            continue;
        }

        crumbs.push({
            title: hb.getScreenTitle(screen),
            action: {
                token: hb.registerAction(`depth-${crumbs.length}`, async ab =>
                    WireActionResult.fromResult(
                        ab.navigateToPath(path.withScreenStack([...soFar]), WireNavigationAction.Pop)
                    )
                ),
            },
        });
    }

    crumbs.push(...extraCrumbs);

    return {
        component: componentEnricher({
            kind: WireComponentKind.Breadcrumbs,
            currentPageTitle: currentPageTitle ?? hb.currentScreenTitle,
            crumbs,
            crumbsOmitted: omitted,
        }),
        isValid: true,
    };
}

const breadcrumbsInflator: ComponentInflator<typeof Breadcrumbs> = (ib, desc) => {
    const componentEnricher = inflateComponentEnricher<WireBreadcrumbsComponent>(ib, desc);

    return makeSimpleWireRowComponentHydratorConstructor(hb => {
        return hydrateBreadcrumbs(componentEnricher, hb);
    });
};

export function getInflatorForComponent<TProps, TForm, TWire, TInternalState>(
    kind: WireComponentKind
): ComponentInflator<FluentComponent<TProps, TForm, TWire, TInternalState>> | undefined {
    if (kind === WireComponentKind.Breadcrumbs) {
        return breadcrumbsInflator;
    } else if (kind === WireComponentKind.InlineScanner) {
        return inlineScannerInflator;
    } else if (kind === WireComponentKind.SignatureField) {
        return signatureFieldInflator;
    }
    return undefined;
}
