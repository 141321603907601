import { browserMightBeOniOS, styled } from "@glide/common";
import * as React from "react";
import { css } from "styled-components";

interface Token {
    readonly start: number;
    readonly end: number;
    readonly color: string;
    readonly onDrop?: (e: React.DragEvent<HTMLElement>) => void;
}

interface Props
    extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    readonly inputRef?: any;
    readonly tokens?: readonly Token[];
}

const inputProps = css`
    font-family: inherit;
    margin: 0;
    line-height: normal;

    font-size: inherit;
`;

const TokenEntryStyle = styled.div`
    position: relative;

    min-width: 60px;

    .token-entry-input {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        border-radius: 0px;
        color: ${p => p.theme.textDark};

        resize: none;
        white-space: normal;
        overflow: hidden;
        border: 0;
        background-color: transparent;

        word-wrap: break-word;
        overflow-wrap: break-word;

        ::placeholder {
            color: ${p => p.theme.textXpale};
        }

        ${inputProps}

        padding: inherit;
    }

    .token-entry-shadow-box {
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;

        color: transparent !important;

        width: 100%;

        ${inputProps}

        ${() => browserMightBeOniOS && "padding: 0 3px;"}
    }
`;

const TokenSpan = styled.span<{ color: string; spaceLeft: boolean; spaceRight: boolean }>`
    position: relative;
    ::after {
        content: "";
        position: absolute;
        left: ${p => (p.spaceLeft ? -3 : 0)}px;
        top: -1px;
        width: calc(100% + ${p => (p.spaceLeft ? 3 : 0)}px + ${p => (p.spaceRight ? 3 : 0)}px);
        height: calc(100% + 2px);

        background-color: ${p => p.color};

        transition: background-color 0.2s;
    }
`;

export const TokenEntry: React.FC<React.PropsWithChildren<Props>> = p => {
    const { className, value, inputRef, tokens, style, ...rest } = p;

    const useValue = value?.toString() ?? "";

    const allowDrop = React.useCallback((ev: React.DragEvent<HTMLSpanElement>) => {
        ev.stopPropagation();
        ev.preventDefault();
    }, []);

    const refMap = React.useRef<Map<Token, HTMLSpanElement | null>>(new Map());
    const multiRef = React.useCallback((instance: HTMLSpanElement | null, token: Token) => {
        refMap.current.set(token, instance);
    }, []);

    React.useEffect(() => {
        if (tokens === undefined) {
            refMap.current.clear();
            return;
        }

        const keysToRemove = Array.from(refMap.current.keys()).filter(k => !tokens.includes(k));
        keysToRemove.forEach(k => refMap.current.delete(k));
    }, [tokens]);

    const spans = React.useMemo(() => {
        if (tokens === undefined || tokens.length === 0) {
            if (useValue === "") return rest.placeholder + "\n";
            return useValue + "\n";
        }

        const ordered = Array.from(tokens).sort((a, b) => a.start - b.start);

        let cur = 0;
        const result: React.ReactNode[] = [];
        for (const token of ordered) {
            const { start, end, color } = token;

            if (start < cur || end > useValue.length) {
                return useValue + "\n";
            }

            if (cur < start) {
                result.push(<span key={cur}>{useValue.slice(cur, start)}</span>);
                cur = start;
            }

            const spaceLeft = cur > 0 && useValue[cur - 1] === " ";
            const spaceRight = end >= useValue.length || useValue[end] === " ";

            result.push(
                <TokenSpan
                    ref={(e: HTMLSpanElement | null) => multiRef(e, token)}
                    key={token.start}
                    onDrop={token.onDrop}
                    onDragEnter={token.onDrop === undefined ? undefined : allowDrop}
                    onDragOver={token.onDrop === undefined ? undefined : allowDrop}
                    color={color}
                    spaceLeft={spaceLeft}
                    spaceRight={spaceRight}>
                    {useValue.slice(start, end)}
                </TokenSpan>
            );
            cur = end;
        }

        if (cur < useValue.length) {
            result.push(<span key={"tail"}>{useValue.slice(cur, useValue.length)}</span>);
        }

        result.push(<span key="newline">{"\n"}</span>);

        return result;
    }, [allowDrop, multiRef, rest.placeholder, tokens, useValue]);

    const onDropTextArea = React.useCallback((event: React.DragEvent<HTMLTextAreaElement>) => {
        let found = false;
        refMap.current.forEach((span, token) => {
            if (found || span === null) return;

            const bounds = span.getBoundingClientRect();
            const { clientX, clientY } = event;

            if (
                clientX >= bounds.left &&
                clientX <= bounds.right &&
                clientY >= bounds.top &&
                clientY <= bounds.bottom
            ) {
                found = true;
                token.onDrop?.(event);
            }
        });

        event.preventDefault();
    }, []);

    return (
        <TokenEntryStyle style={style} className={className}>
            <div className="token-entry-shadow-box">{spans}</div>
            <textarea
                {...rest}
                ref={inputRef}
                className="token-entry-input"
                value={useValue}
                onDragEnter={allowDrop}
                onDragOver={allowDrop}
                onDrop={onDropTextArea}
            />
        </TokenEntryStyle>
    );
};
