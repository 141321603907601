/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

export const plugin = glide.newNativePlugin({
    name: "App special values",
    id: "app-special-values",
    icon: "st-apps",
    description: "A bunch of special values about the app",
    experimentFlag: "appSpecialValuesPlugin",
});

const appTitleResult = glide.makeParameter({
    type: "string",
    name: "Title",
    description: "The title of the app",
});

const appDescriptionResult = glide.makeParameter({
    type: "string",
    name: "Description",
    description: "A longer description of the app",
});

const appIconResult = glide.makeParameter({
    type: "string",
    name: "Icon",
    description: "A URL to the app's icon",
});

const appURLResult = glide.makeParameter({
    type: "string",
    name: "URL",
    description: "The play URL of the app",
});

plugin.addClientComputation({
    id: "app-info",
    name: "App",
    description: "Information about the app",
    showAsSpecialValue: true,
    parameters: {},
    results: {
        appTitle: appTitleResult,
        appDescription: appDescriptionResult,
        appIcon: appIconResult,
        appURL: appURLResult,
    },

    execute: async ctx => {
        const [appIcon, appURL] = await Promise.all([ctx.app.getIcon(), ctx.app.getPlayURL()]);
        return glide.Result.Ok({ appTitle: ctx.app.name, appDescription: ctx.app.description, appIcon, appURL });
    },
});
