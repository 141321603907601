import type { TypeSchema } from "@glide/type-schema";
import type { AppDescription } from "@glide/app-description";
import { getSourceMetadataForTable } from "@glide/common-core/dist/js/components/SerializedApp";
import { isSSOSignInEnabled } from "@glide/common-core/dist/js/utility/pins";
import type { SerializablePluginMetadata } from "@glide/plugins";

interface PluginRemovalPreventionReasons {
    readonly sso: boolean;
    readonly sql: boolean;
}

export function getPluginRemovalPreventionReasons(
    plugin: SerializablePluginMetadata,
    configID: string | undefined,
    appDesc: AppDescription,
    schema: TypeSchema | undefined,
    allPlugins: readonly SerializablePluginMetadata[]
): PluginRemovalPreventionReasons | undefined {
    const isSSOEnabled = isSSOSignInEnabled(appDesc, allPlugins).isEnabled;
    const sso = isSSOEnabled && plugin.signOns.length > 0;

    let sql = false;
    if (configID !== undefined && schema !== undefined) {
        for (const table of schema.tables) {
            const sm = getSourceMetadataForTable(appDesc, table);
            if (sm?.type !== "Native table") continue;
            if (sm.externalSource?.type !== "queryable-plugin") continue;
            if (sm.externalSource.pluginConfigID !== configID) continue;

            sql = true;
            break;
        }
    }

    if (sso || sql) {
        return { sso, sql };
    } else {
        return undefined;
    }
}
