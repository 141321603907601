export function modulo(x: number, y: number): number | undefined {
    if (y === 0) return undefined;

    if (x >= 0) {
        if (y > 0) {
            return x % y;
        } else {
            return (x % -y) + y;
        }
    } else {
        if (y > 0) {
            return (x % y) + y;
        } else {
            return x % y;
        }
    }
}

export function round2(num: number, decimals: number) {
    const validDecimals = Math.trunc(decimals);
    if (validDecimals >= 0) {
        const factor = 10 ** validDecimals;
        return Math.round(num * factor) / factor;
    } else {
        // Handle negative decimals by working on the integer part
        const factor = 10 ** -validDecimals;
        return Math.round(num / factor) * factor;
    }
}

export function trunc2(num: number, decimals: number) {
    const validDecimals = Math.trunc(decimals);
    if (validDecimals >= 0) {
        const factor = 10 ** validDecimals;
        return Math.trunc(num * factor) / factor;
    } else {
        // Handle negative decimals by working on the integer part
        const factor = 10 ** -validDecimals;
        return Math.trunc(num / factor) * factor;
    }
}

export function log2(x: number, base: number): number | undefined {
    if (base <= 0 || base === 1) return undefined;
    return Math.log(x) / Math.log(base);
}

// https://github.com/formulajs/formulajs/blob/master/lib/date-time.js#L449
export function getWeekNumber(date: Date): number {
    const a = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    const b = a.getTime() - Math.abs(a.getUTCDay()) * 24 * 60 * 60 * 1000;

    return Math.floor((date.getTime() - b) / (1000 * 60 * 60 * 24) / 7 + 1);
}
