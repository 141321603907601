import { isRight } from "fp-ts/lib/Either";
import type { TypeOf } from "io-ts";
import { string, type, union } from "io-ts";

export const iconImageCodec = union([
    type({
        emoji: string,
    }),
    type({
        url: string,
    }),
    type({
        name: string,
    }),
]);
export type IconImage = TypeOf<typeof iconImageCodec>;

export function isIconImage(icon: unknown): icon is IconImage {
    return icon !== undefined && isRight(iconImageCodec.decode(icon));
}
