import { type AppDescription, ScreenDescriptionKind, isFormScreen, getScreenProperty } from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import { getAppKind, getAppTabs } from "@glide/common-core/dist/js/components/SerializedApp";
import {
    buyScreenName,
    codeScannerScreenName,
    isAddClassScreenName,
    liveLinearBarcodeScannerScreenName,
    requestSignatureScreenName,
    sendAppFeedbackScreenName,
    shareScreenName,
    signInScreenName,
    signUpScreenName,
    userProfileScreenName,
    voiceEntryScreenName,
    webViewScreenName,
} from "@glide/function-utils";
import { findTabInAppDescription } from "@glide/generator/dist/js/description-utils";
import { assertNever, hasOwnProperty } from "@glideapps/ts-necessities";
import { shallowEqualArrays, truthify } from "@glide/support";
import {
    type ParsedMainScreen,
    type ParsedModalScreen,
    type ParsedPath,
    type ParsedScreen,
    WireModalSize,
    WireScreenPosition,
} from "@glide/wire";
import md5 from "blueimp-md5";

// A path has has the following components:
//
//   TAB SCREEN [SCREEN ...]
//
// where TAB is the (hash of the) screen name or slug of a tab, and each
// SCREEN is
//
//   PREFIX NAME ["r" ROW-ID]
//
// where PREFIX is one of
//
//   "s" ... primary screen
//   "m" ... small modal
//   "M" ... big modal
//   "S" ... SlideIn
//
// NAME is the hash of the screen name and ROW-ID is the row ID of the row
// that the screen displays.  The row ID is optional because some screens can
// fetch their own data, i.e. they're configured to show a specific row.
//
// As an example, let's look at this path:
//
//   da19fa/s/da19fa/m/c1ff28/r/7tDm2TgxSc2cac7ye6A2Ww
//
// This breaks down to
//
//   TAB          da19fa
//   SCREEN       s/da19fa
//     PREFIX       s
//     NAME         da19fa
//   SCREEN       m/c1ff28/r/7tDm2TgxSc2cac7ye6A2Ww
//     PREFIX       m
//     NAME         c1ff28
//     ROW-ID       7tDm2TgxSc2cac7ye6A2Ww
//
// FIXME: Note that we're repeating the tab screen, which is redundant.  We
// should probably fix that.  I don't think there can be a case where the
// root screen is different than the tab screen.

type ScreenPrefix = "s" | "m" | "M" | "S";

// Why is this an object??
const specialScreenNames = {
    [signInScreenName]: null,
    [signUpScreenName]: null,
    [webViewScreenName]: null,
    [codeScannerScreenName]: null,
    [liveLinearBarcodeScannerScreenName]: null,
    [requestSignatureScreenName]: null,
    [shareScreenName]: null,
    [sendAppFeedbackScreenName]: null,
    [buyScreenName]: null,
    [voiceEntryScreenName]: null,
};

export type SpecialScreenName = keyof typeof specialScreenNames;

export const invisibleParsedPath: ParsedPath = { isVisible: false };

export function isSpecialScreenName(screenName: string): screenName is SpecialScreenName {
    return hasOwnProperty(specialScreenNames, screenName);
}

function parsedPathRemoveModals(parsedPath: ParsedPath): ParsedPath {
    if (!parsedPath.isVisible) return parsedPath;

    let { screenStack } = parsedPath;
    screenStack = screenStack.filter(s => s.kind === WireScreenPosition.Main);
    return { ...parsedPath, screenStack };
}

export function parsedPathPush(parsedPath: ParsedPath, screen: ParsedScreen, removeModalsIfMain: boolean): ParsedPath {
    if (screen.kind === WireScreenPosition.Main && removeModalsIfMain) {
        parsedPath = parsedPathRemoveModals(parsedPath);
    }

    if (!parsedPath.isVisible) return parsedPath;

    return { ...parsedPath, screenStack: [...parsedPath.screenStack, screen] };
}

function encodeParsedScreenName(screenName: string) {
    return md5(screenName).substring(0, 6);
}

function isMainScreen(screen: ParsedScreen): screen is ParsedMainScreen {
    return screen.kind === WireScreenPosition.Main;
}

function isModalScreen(screen: ParsedScreen): screen is ParsedModalScreen {
    return screen.kind === WireScreenPosition.Modal;
}

function getScreenPrefix(screen: ParsedScreen): ScreenPrefix {
    if (isMainScreen(screen)) {
        return "s";
    }

    if (isModalScreen(screen)) {
        switch (screen.size) {
            case WireModalSize.SlideIn:
                return "S";

            case WireModalSize.Small:
                return "m";

            // XSmall modal might not be used anymore,
            // but for consistency with existing code, it returns "M"
            case WireModalSize.XSmall:
            case WireModalSize.Large:
                return "M";

            default:
                assertNever(screen.size);
        }
    }

    assertNever(screen);
}

export function unparseParsedScreen(screen: ParsedScreen): readonly string[] {
    const components: string[] = [];

    const prefix = getScreenPrefix(screen);
    components.push(prefix);
    components.push(encodeParsedScreenName(screen.screenName));
    if (screen.rowIDs.length > 0) {
        components.push("r", screen.rowIDs[0]);
    }
    return components;
}

function normalizeURLPath(urlPath: string): string {
    if (urlPath.startsWith("/")) {
        urlPath = urlPath.substring(1);
    }
    return urlPath;
}

function normalizeSlug(slug: string): string {
    return slug.trim();
}

export function parseURLPath(urlPath: string, appDescription: AppDescription): ParsedPath | undefined {
    const screenNames = [
        ...Object.keys(appDescription.screenDescriptions),
        userProfileScreenName,
        ...Object.keys(specialScreenNames),
    ].map(sn => [sn, md5(sn)] as const);

    urlPath = normalizeURLPath(urlPath);
    const components = urlPath.split("/").map(decodeURIComponent);

    const parseScreenName = () => {
        const hash = components[0];
        if (hash === undefined || hash.length <= 0) return undefined;

        const entry = screenNames.find(([, h]) => h.startsWith(hash));
        if (entry === undefined) return undefined;

        components.shift();

        return entry[0];
    };

    // If I substitute `first()` with its body below, TS thinks that
    // the first check means that `components[0] === "s"` forever
    // after, despite the `unshift`s in between.
    function first() {
        return components[0];
    }

    const parseScreen = <T extends ScreenPrefix>(
        prefix: T
    ): (T extends "s" ? ParsedMainScreen : ParsedModalScreen) | undefined => {
        if (first() !== prefix) return undefined;
        components.shift();

        const screenName = parseScreenName();
        if (screenName === undefined) return undefined;

        let rowIDs: readonly string[];
        if (first() === "r" && components[1] !== undefined && components[1].length > 0) {
            rowIDs = [components[1]];
            components.shift();
            components.shift();
        } else {
            rowIDs = [];
        }

        const base = { screenName, rowIDs };
        switch (prefix) {
            case "s": {
                const p: ParsedMainScreen = { kind: WireScreenPosition.Main, sourceItemRowID: undefined, ...base };
                return p as any;
            }
            case "m": {
                const p: ParsedModalScreen = { kind: WireScreenPosition.Modal, size: WireModalSize.Small, ...base };
                return p as any;
            }
            case "M": {
                const p: ParsedModalScreen = { kind: WireScreenPosition.Modal, size: WireModalSize.Large, ...base };
                return p as any;
            }
            case "S": {
                const p: ParsedModalScreen = { kind: WireScreenPosition.Modal, size: WireModalSize.SlideIn, ...base };
                return p as any;
            }
            default:
                return assertNever(prefix);
        }
    };

    let tabScreenName: string | undefined;
    // We check all the non-hidden tabs first, then we check the hidden ones.
    // In the builder it's possible to navigate to a hidden tab, so have to
    // allow it.
    for (const doHidden of [false, true]) {
        for (const tab of getAppTabs(appDescription)) {
            if (truthify(doHidden) !== truthify(tab.hidden)) continue;

            const slug = normalizeSlug(tab.slug ?? "");
            if (slug !== "" && components[0] === slug) {
                tabScreenName = getScreenProperty(tab.screenName);
                if (tabScreenName !== undefined) {
                    components.shift();
                    break;
                }
            }
        }

        if (tabScreenName !== undefined) break;
    }
    if (tabScreenName === undefined) {
        tabScreenName = parseScreenName();
        if (tabScreenName === undefined) return undefined;
    }

    const rootScreen: ParsedMainScreen = {
        kind: WireScreenPosition.Main,
        screenName: tabScreenName,
        rowIDs: [],
        sourceItemRowID: undefined,
    };
    const screenStack: ParsedScreen[] = [];

    for (;;) {
        const prefix = first();
        if (prefix === "s" || prefix === "m" || prefix === "M" || prefix === "S") {
            const screen = parseScreen(prefix);
            if (screen !== undefined) {
                screenStack.push(screen);
                continue;
            }
        }
        break;
    }

    return { isVisible: true, tabScreenName, rootScreen, screenStack };
}

export function unparseParsedPath(path: ParsedPath, appDescription: AppDescription): string {
    if (!path.isVisible) return "";

    const components: string[] = [];

    function pushScreenName(screenName: string) {
        components.push(encodeParsedScreenName(screenName));
    }

    const tab = findTabInAppDescription(path.tabScreenName, appDescription)?.[0];

    if (tab?.slug !== undefined && normalizeSlug(tab.slug) !== "") {
        components.push(encodeURIComponent(normalizeSlug(tab.slug)));
    } else {
        pushScreenName(path.tabScreenName);
    }

    // components.push(...unparseParsedScreen(path.rootScreen));
    for (const s of path.screenStack) {
        components.push(...unparseParsedScreen(s));
    }

    return components.map(encodeURIComponent).join("/");
}

export function isParsedPathValid(path: ParsedPath, appDescription: AppDescription, allowHidden: boolean): boolean {
    const appKind = getAppKind(appDescription);
    const allowNonClassScreens = appKind === AppKind.App;
    const allowAddScreens = appKind === AppKind.App;

    if (!path.isVisible) return false;

    if (path.tabScreenName !== userProfileScreenName) {
        if (appDescription.screenDescriptions[path.tabScreenName] === undefined) return false;
        const foundTab = findTabInAppDescription(path.tabScreenName, appDescription)?.[0];
        if (foundTab === undefined || (foundTab.hidden && !allowHidden)) return false;
    }

    const checkScreen = (parsed: ParsedScreen) => {
        if (isSpecialScreenName(parsed.screenName)) return true;

        const screen = appDescription.screenDescriptions[parsed.screenName];
        if (screen === undefined) {
            return parsed.screenName === userProfileScreenName;
        }

        if (screen.kind !== ScreenDescriptionKind.Class) {
            if (screen.kind === ScreenDescriptionKind.Array) {
                if (!allowNonClassScreens) return false;
            } else if (
                screen.kind === ScreenDescriptionKind.Chat ||
                screen.kind === ScreenDescriptionKind.ShoppingCart
            ) {
                return allowNonClassScreens;
            } else {
                return false;
            }
        }
        if (allowAddScreens && isAddClassScreenName(parsed.screenName)) {
            // this is allowed
        } else if (
            screen.fetchesData !== true &&
            // we allow form screens without input rows now
            !isFormScreen(screen) &&
            parsed.rowIDs.length === 0
        ) {
            return false;
        }

        return true;
    };

    if (!checkScreen(path.rootScreen)) return false;
    if (!path.screenStack.every(checkScreen)) return false;

    return true;
}

export function makeRootParsedPath(appDescription: AppDescription): ParsedPath {
    const tabs = getAppTabs(appDescription);

    // ##flyoutTabsComeSecond:
    // Whenever we need to pick a default tab, "regular" tabs come first.
    // Only if we can't find one do we look at flyout tabs.
    let tab = tabs.find(t => t.inFlyout !== true && !t.hidden);
    if (tab === undefined) {
        tab = tabs.find(t => !t.hidden);
    }

    const screenName = getScreenProperty(tab?.screenName);
    if (screenName === undefined) return invisibleParsedPath;

    return {
        isVisible: true,
        tabScreenName: screenName,
        rootScreen: { kind: WireScreenPosition.Main, screenName, rowIDs: [], sourceItemRowID: undefined },
        screenStack: [],
    };
}

export function areParsedPathsEqual(p1: ParsedPath, p2: ParsedPath, onlyCompareFirstRowID: boolean): boolean {
    function areScreensEqual(s1: ParsedScreen, s2: ParsedScreen) {
        if (s1.screenName !== s2.screenName) return false;
        if (!shallowEqualArrays(s1.rowIDs, s2.rowIDs)) {
            if (onlyCompareFirstRowID) {
                // At least one of them must have at least one row ID, since
                // the arrays are not equal.
                if (s1.rowIDs[0] !== s2.rowIDs[0]) return false;
            } else {
                return false;
            }
        }

        if (s1.kind === WireScreenPosition.Main) {
            if (s2.kind !== WireScreenPosition.Main) return false;
        } else if (s1.kind === WireScreenPosition.Modal) {
            if (s2.kind !== WireScreenPosition.Modal) return false;
            if (s1.size !== s2.size) return false;
        } else {
            return assertNever(s1);
        }
        return true;
    }

    if (p1.isVisible) {
        if (!p2.isVisible) return false;
        if (p1.tabScreenName !== p2.tabScreenName) return false;
        if (!areScreensEqual(p1.rootScreen, p2.rootScreen)) return false;
        if (p1.screenStack.length !== p2.screenStack.length) return false;
        for (let i = 0; i < p1.screenStack.length; i++) {
            if (!areScreensEqual(p1.screenStack[i], p2.screenStack[i])) return false;
        }
    } else {
        if (p2.isVisible) return false;
    }
    return true;
}
