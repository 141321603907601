import type { PluginConfig, SerializedApp } from "@glide/app-description";
import type { ValidatePluginResult } from "@glide/common-core/dist/js/firebase-function-types";
import type { ParameterProps } from "@glide/plugins";
import React from "react";
import "twin.macro";
import type { TableGlideType } from "@glide/type-schema";
import { PluginParametersConfigBase } from "./plugin-parameter-config-base";

export interface PluginParametersPropsBase {
    readonly appID: string;
    readonly pluginConfigs: readonly PluginConfig[] | undefined;
    readonly updatePluginConfig: (
        appID: string,
        pluginConfig: SerializedApp["pluginConfigs"],
        isSettingDefault?: boolean
    ) => void;
}

interface Props extends PluginParametersPropsBase {
    readonly config: PluginConfig;
    readonly parameters: Record<string, ParameterProps>;
    readonly errorState: ValidatePluginResult | undefined;
    readonly userProfileTable: TableGlideType | undefined;
}

export const PluginParametersConfig: React.VFC<Props> = props => {
    const { appID, pluginConfigs = [], updatePluginConfig, config, parameters, userProfileTable, errorState } = props;

    const setDesc = React.useCallback(
        (params: PluginConfig["parameters"], isSettingDefault: boolean) => {
            const newConfig = {
                ...config,
                parameters: params,
            };

            const newConfigs = [...pluginConfigs.filter(p => p.configID !== config.configID), newConfig];
            updatePluginConfig(appID, newConfigs, isSettingDefault);
        },
        [appID, config, pluginConfigs, updatePluginConfig]
    );

    return (
        <PluginParametersConfigBase
            appID={appID}
            configID={config.configID}
            pluginID={config.pluginID}
            parameters={parameters}
            errorState={errorState}
            onChange={setDesc}
            parameterValues={config.parameters}
            userProfileTable={userProfileTable}
        />
    );
};
