import { isLoadingValue } from "@glide/computation-model-types";
import { asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import { type ActionDescription, type PropertyDescription, ActionKind } from "@glide/app-description";
import { makeSMSURL } from "@glide/common-core/dist/js/urls";
import {
    type AppDescriptionContext,
    makeInlineTemplatePropertyDescriptor,
    type ActionAvailability,
} from "@glide/function-utils";
import { nullToUndefined } from "@glide/support";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { makePhoneNumberPropertyDescriptor } from "./phone-call";
import { ICON_BASE, PURPLE_500 } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins";

interface TextMessageActionDescription extends ActionDescription {
    // This property name is also hardcoded in ##convertLegacyActions
    readonly number?: PropertyDescription;
    readonly body?: PropertyDescription;
}

export class TextMessageWithArgsHandler extends BaseActionHandler<TextMessageActionDescription> {
    public readonly kind = ActionKind.TextMessageWithArgs;

    public readonly iconName: GlideIconProps = {
        icon: "st-message-circle-workflows",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: PURPLE_500,
    };

    public readonly name: string = "Compose SMS";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: TextMessageActionDescription | undefined,
        { mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Communication,
            groupItemOrder: 3,
            needsScreenContext: true,
            properties: [
                makePhoneNumberPropertyDescriptor(mutatingScreenKind),
                makeInlineTemplatePropertyDescriptor(
                    "body",
                    "Message",
                    "Enter message body",
                    false,
                    "withLabel",
                    mutatingScreenKind,
                    {
                        preferredNames: ["message", "sms", "body", "text"],
                        preferredType: "string",
                        searchable: false,
                        emptyByDefault: true,
                        columnFirst: true,
                    }
                ),
            ],
        };
    }

    public getTokenizedDescription(
        desc: TextMessageActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const columnToken = tokenForProperty(desc.number, env);
        if (columnToken === undefined) return undefined;

        return [
            {
                kind: "string",
                value: "to ",
            },
            columnToken,
        ];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: TextMessageActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const [numberGetter] = ib.getValueGetterForProperty(desc.number, false);
        const [bodyGetter] = ib.getValueGetterForProperty(desc.body, true);

        return (vp, skipLoading) => {
            const numberValue = nullToUndefined(numberGetter(vp));
            if (isLoadingValue(numberValue)) return arbBase.maybeSkipLoading(skipLoading, "Number");
            const number = asMaybeString(numberValue);

            const messageBodyValue = nullToUndefined(bodyGetter(vp));
            if (isLoadingValue(messageBodyValue)) return arbBase.maybeSkipLoading(skipLoading, "Message body");
            const messageBody = asMaybeString(messageBodyValue);

            const arb = arbBase.addData({ number, messageBody });

            const url = makeSMSURL(number, messageBody);
            if (url === undefined) return arb.error(true, "Invalid phone number");

            return async ab => {
                ab.actionCallbacks.openLink(url);
                return arb.success();
            };
        };
    }
}
