import type { MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import type { PrimitiveValue, Row, Query, QuerySort } from "@glide/computation-model-types";
import { isLoadingValue, Table } from "@glide/computation-model-types";
import type { ComponentDescription, MutatingScreenKind } from "@glide/app-description";
import {
    ArrayScreenFormat,
    PropertyKind,
    getColumnProperty,
    getNumberProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import {
    type Description,
    type TableColumn,
    type TableGlideType,
    BinaryPredicateFormulaOperator,
    getTableName,
    isBigTableOrExternal,
} from "@glide/type-schema";
import { type InputOutputTables, makeInputOutputTables } from "@glide/common-core/dist/js/description";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import type { GroupingSupport } from "@glide/component-utils";
import { convertValueToSerializable } from "@glide/data-types";
import type { WireBreadcrumbsComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    easyCRUDAddPropertyHandler,
    easyCRUDDeletePropertyHandler,
    easyCRUDEditPropertyHandler,
    easyCRUDUndefinedProperties,
    easyTabAddPropertyHandler,
    easyTabDeletePropertyHandler,
    easyTabEditPropertyHandler,
    getEasyCRUDFlags,
} from "@glide/fluent-components/dist/js/easy-crud";
import {
    getSpecialCaseDescriptorFromSpec,
    type ArrayContentDescriptionForProperties,
    type FluentArrayContent,
    type WireListComponentGeneric,
} from "@glide/fluent-components/dist/js/fluent-components-spec";
import { makeActionDescriptors } from "@glide/fluent-components/dist/js/fluent-properties-spec";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentSpecialCaseDescriptor,
    type EditedColumnsAndTables,
    type InlineListComponentDescription,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    type PropertyTableGetter,
    type QueryableTableSupport,
    PropertySection,
    combineEditedColumnsAndTables,
    emptyEditedColumnsAndTables,
    promoteAppDescriptionContext,
} from "@glide/function-utils";
import { isDefined } from "@glide/support";
import {
    type WireAlwaysEditableValue,
    type WireRowComponentHydrationBackend,
    type WireTableComponentHydrationBackend,
    type WireTableComponentHydratorConstructor,
    type WireSubsidiaryScreen,
    type WirePagedGroup,
    type WirePaging,
    ValueChangeSource,
    WireComponentKind,
    UIOrientation,
    type WireInflationBackend,
} from "@glide/wire";
import { assert, defined, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import { getDefaultPrimitiveActionKinds } from "../actions";
import {
    type ArrayContentHandler,
    type DynamicFilterSupport,
    type SearchSupport,
    ArrayContentHandlerBase,
} from "../array-screens/array-content";
import { getColumnAssignments, getSortFromArrayTransforms } from "../description-utils";
import { formatValueWithSpecification } from "../format-value";
import { decomposeAggregateRow } from "../wire/aggregates";
import {
    type SimpleTableComponentHydratorFromQuery,
    type WireStringGetter,
    applyGroupPaging,
    applyPaging,
    encodeScreenKey,
    getItemsPageIndex,
    getPageSizeForPaging,
    getQueryLimitForPaging,
    inflateComponentEnricher,
    makeGroups,
    makeSimpleWireTableComponentHydratorConstructor,
} from "../wire/utils";
import type { ComponentEasyTabConfiguration, ScreenContext } from "./component-handler";
import { PopulationMode } from "./description-handlers";
import {
    type CaptionPropertyDescriptorFlags,
    getWritableColumnsForColumnAssignment,
    makePropertyDescriptorsForColumns,
} from "./descriptor-utils";
import { hydrateBreadcrumbs } from "./fluent-component-inflators";
import { adjustSeparatorsForContainer, hydrateGetters, inflateGetters } from "./fluent-components-handlers";
import { populateDescription } from "./populate-description";
import { getFeatureSetting } from "@glide/common-core";
import type { GroupByGetters } from "../array-screens/summary-array-screen";
import { notesPropertyDescriptor } from "./handler";

type ArrayContentInflator<TProps> = (
    ib: WireInflationBackend,
    desc: ArrayContentDescriptionForProperties<TProps>,
    containingRowIB: WireInflationBackend | undefined,
    componentID: string | undefined,
    filterColumnName: string | undefined,
    additionalTargetColumnsNames: ReadonlySet<string>
) => WireTableComponentHydratorConstructor | undefined;

function makeEasyCRUDSwitchProperties(): Description {
    return {
        [easyCRUDAddPropertyHandler.name]: makeSwitchProperty(easyCRUDAddPropertyHandler.defaultValue),
        [easyCRUDEditPropertyHandler.name]: makeSwitchProperty(easyCRUDEditPropertyHandler.defaultValue),
        [easyCRUDDeletePropertyHandler.name]: makeSwitchProperty(easyCRUDDeletePropertyHandler.defaultValue),
    };
}

function makeGroupKey(groupValue: PrimitiveValue): string {
    return JSON.stringify(convertValueToSerializable(groupValue));
}

interface MakeQueryGroupArgs {
    thb: WireTableComponentHydrationBackend;
    chb: WireRowComponentHydrationBackend;
    query: Query;
    groupByGetters: GroupByGetters;
    selectedPageSize: number | undefined;
    visibleRowGroups: readonly Row[];
    makeGroupToggleSubsidiaryActionToken?: (groupKey: string) => { token: string } | undefined;
    itemRowHydrator?: (rows: Row) => Row;
    queryableTableSupport: QueryableTableSupport;
    sort?: QuerySort[];
}

export function makeQueryGroup({
    chb,
    groupByGetters,
    itemRowHydrator,
    query,
    queryableTableSupport,
    selectedPageSize,
    thb,
    visibleRowGroups,
    makeGroupToggleSubsidiaryActionToken,
    sort = undefined,
}: MakeQueryGroupArgs) {
    return mapFilterUndefined(visibleRowGroups, groupRow => {
        const { group: groupValue } = decomposeAggregateRow(groupRow);
        if (groupValue === undefined) return undefined;

        const groupKey = makeGroupKey(groupValue);

        const pageIndexState = getItemsPageIndex(thb, groupByGetters !== undefined, groupKey);

        let groupQuery = query.withCondition({
            kind: BinaryPredicateFormulaOperator.Equals,
            lhs: { columnName: groupByGetters.column.name },
            rhs: convertValueToSerializable(groupValue),
            negated: false,
        });

        if (sort !== undefined) {
            groupQuery = groupQuery.withoutLimit().withSort(sort);
        }

        groupQuery = groupQuery.withLimit(
            getQueryLimitForPaging(queryableTableSupport, selectedPageSize, pageIndexState)
        );

        const rowsInGroup = chb.resolveQueryAsTable(groupQuery);
        if (rowsInGroup === undefined || isLoadingValue(rowsInGroup)) return undefined;

        const title = formatValueWithSpecification(groupByGetters.format, groupValue);

        const [itemsPaging, visibleRows] = applyPaging(
            rowsInGroup.asMutatingVisibleRowsArray(),
            pageIndexState,
            selectedPageSize
        );

        const group = {
            title: title ?? "",
            items: isDefined(itemRowHydrator) ? visibleRows.map(itemRowHydrator) : visibleRows,
            paging: itemsPaging,
            toggleSeeAllAction: makeGroupToggleSubsidiaryActionToken?.(groupKey),
            groupKey,
        };
        return group;
    });
}

export function makeFluentArrayContentHandler<TItemProps, TComponentProps>(
    { spec }: FluentArrayContent<TItemProps, TComponentProps, unknown>,
    contentInflator?: ArrayContentInflator<TComponentProps & TItemProps>,
    getEditedColumns?: (
        desc: ArrayContentDescriptionForProperties<TComponentProps & TItemProps>,
        tables: InputOutputTables,
        itemTable: TableGlideType | undefined,
        ccc: AppDescriptionContext
    ) => EditedColumnsAndTables | undefined
): ArrayContentHandler<ArrayContentDescriptionForProperties<TComponentProps & TItemProps>> {
    const {
        icon,
        displayName,
        format,
        specialCases,
        experimentFlag,
        featureSetting,
        experimentFlagAndFeatureSetting,
        groupingSupport,
        paging,
        maxPageSize,
        handlesSorting,
        customFiltering,
        searchDefault,
        canDeleteRows,
        canShowIfEmpty,
        queryableTableSupport,
        allowsQueryableSort,
        easyCRUDHandler,
        itemProperties: { propertySpecs: itemPropertySpecs, arraySpecs: itemArraySpecs, actionSpecs: itemActionSpecs },
        componentProperties: {
            propertySpecs: componentPropertySpecs,
            arraySpecs: componentArraySpecs,
            actionSpecs: componentActionSpecs,
        },
        extraPropertyDescriptors,
        makeSubComponents,
        columnAssignments,
        handlesLimit,
        onlyQueries,
    } = spec;
    // TODO: See whether we're actually using this - the way we get searchable
    // components in Pages is separate from this.
    const searchProperties = itemPropertySpecs.filter(p => p.isSearchable).map(p => p.name);
    const isContainer = makeSubComponents !== undefined;

    function hasEasyCRUD(desc: Description | undefined): boolean {
        if (desc === undefined || getEasyCRUDFlags(desc as ComponentDescription) === undefined) {
            return false;
        }
        return true;
    }

    class Handler extends ArrayContentHandlerBase<ArrayContentDescriptionForProperties<TComponentProps & TItemProps>> {
        public get icon(): string | undefined {
            return icon;
        }

        public get displayName(): string | undefined {
            return displayName;
        }

        public getCaptionFlags(): CaptionPropertyDescriptorFlags | undefined {
            return undefined;
        }

        protected get groupingSupport(): GroupingSupport {
            return groupingSupport;
        }

        public get supportsDynamicFilter(): DynamicFilterSupport | undefined {
            return { viaQueries: !customFiltering };
        }

        public get needsDynamicFilterValues(): boolean {
            return customFiltering;
        }

        public get hasComponents(): boolean {
            return isContainer;
        }

        public getSpecialCaseDescriptors(ccc: AppDescriptionContext): readonly ComponentSpecialCaseDescriptor[] {
            const isLegacyBecauseOfFeatureSetting =
                featureSetting !== undefined && getFeatureSetting(featureSetting.value) === featureSetting.isNegated;
            const isLegacyBecauseOfExperiment =
                experimentFlag !== undefined &&
                isExperimentEnabled(experimentFlag.value, ccc.userFeatures) === experimentFlag.isNegated;
            const isLegacy =
                experimentFlagAndFeatureSetting === true
                    ? isLegacyBecauseOfFeatureSetting && isLegacyBecauseOfExperiment
                    : isLegacyBecauseOfFeatureSetting || isLegacyBecauseOfExperiment;

            if (isLegacy) {
                return [];
            }

            return specialCases.map(caseSpec => getSpecialCaseDescriptorFromSpec(caseSpec, ccc.userFeatures));
        }

        public getBasicSearchProperties(): readonly string[] {
            return searchProperties;
        }

        public get defaultPageSize(): number | undefined {
            return typeof paging === "number" ? paging : paging !== false ? 24 : undefined;
        }

        public get maxPageSize(): number | undefined {
            return maxPageSize;
        }

        public get hasFixedPaging(): boolean {
            return typeof paging === "number";
        }

        public get doesCustomPaging(): boolean {
            return paging === "custom";
        }

        public get doesCustomLimit(): boolean {
            return handlesLimit;
        }

        public get allowChangePageSize(): boolean {
            return typeof paging !== "number" && this.defaultPageSize !== undefined;
        }

        public getNeedsOrder(): boolean {
            return !handlesSorting;
        }

        public get supportsSearch(): SearchSupport | undefined {
            return { enabledByDefault: searchDefault };
        }

        public get supportsEasyTabConfiguration(): boolean {
            return easyCRUDHandler !== undefined;
        }

        public get queryableTableSupport(): QueryableTableSupport {
            return queryableTableSupport;
        }

        public get allowsQueryableSort(): boolean {
            return allowsQueryableSort;
        }

        public get sourcePropertyLabel(): [string, boolean] {
            return ["Source", true];
        }

        public get showIfEmpty(): boolean {
            return canShowIfEmpty;
        }

        public get onlyQueries(): boolean {
            return onlyQueries;
        }

        public getContentPropertyDescriptors<
            T extends ArrayContentDescriptionForProperties<TComponentProps & TItemProps>
        >(
            getPropertyTable: PropertyTableGetter | undefined,
            insideInlineList: boolean,
            containingScreenTables: InputOutputTables | undefined,
            desc: T | undefined,
            ccc: AppDescriptionContext,
            mutatingScreenKind: MutatingScreenKind | undefined,
            isDefaultArrayScreen: boolean,
            withTransforms: boolean,
            forEasyTabConfiguration: boolean,
            isFirstComponent: boolean | undefined,
            screenContext: ScreenContext | undefined,
            appEnvironment: MinimalAppEnvironment | undefined
        ): readonly PropertyDescriptor[] {
            const actionKinds = getDefaultPrimitiveActionKinds(ccc, mutatingScreenKind);
            const superProps = super.getContentPropertyDescriptors(
                getPropertyTable,
                insideInlineList,
                containingScreenTables,
                desc,
                ccc,
                mutatingScreenKind,
                isDefaultArrayScreen,
                withTransforms,
                forEasyTabConfiguration,
                isFirstComponent,
                screenContext,
                appEnvironment
            );

            const forEasyCRUD = hasEasyCRUD(desc);

            const componentPropsBefore = mapFilterUndefined(
                [...componentPropertySpecs.filter(s => !s.addAtBottom), ...componentArraySpecs],
                p =>
                    p.descriptorConstructor({
                        desc,
                        parentDesc: undefined,
                        getPropertyTable: undefined,
                        adc: ccc,
                        tables: containingScreenTables,
                        mutatingScreenKind,
                        sectionOverride: undefined,
                        isEditedInApp: false,
                        forEasyCRUD,
                        forEasyTabConfiguration,
                        isFirstComponent,
                        actionKinds,
                    })
            );
            const itemProps = mapFilterUndefined([...itemPropertySpecs, ...itemArraySpecs], p =>
                p.descriptorConstructor({
                    desc,
                    parentDesc: desc,
                    getPropertyTable,
                    adc: ccc,
                    tables: containingScreenTables,
                    mutatingScreenKind,
                    sectionOverride: undefined,
                    isEditedInApp: false,
                    forEasyCRUD,
                    forEasyTabConfiguration,
                    isFirstComponent,
                    actionKinds,
                })
            );
            const componentPropsAfter = mapFilterUndefined(
                componentPropertySpecs.filter(s => s.addAtBottom),
                p =>
                    p.descriptorConstructor({
                        desc,
                        parentDesc: undefined,
                        getPropertyTable: undefined,
                        adc: ccc,
                        tables: containingScreenTables,
                        mutatingScreenKind,
                        sectionOverride: undefined,
                        isEditedInApp: false,
                        forEasyCRUD,
                        forEasyTabConfiguration,
                        isFirstComponent,
                        actionKinds,
                    })
            );

            const easyCRUDProps: PropertyDescriptor[] = [];

            if (easyCRUDHandler !== undefined) {
                if (forEasyCRUD) {
                    if (forEasyTabConfiguration) {
                        easyCRUDProps.push(easyTabAddPropertyHandler);
                        if (easyCRUDHandler.withEdit !== false) {
                            easyCRUDProps.push(easyTabEditPropertyHandler.withIndirectTable(getPropertyTable));
                        }
                        if (easyCRUDHandler.withDelete) {
                            easyCRUDProps.push(easyTabDeletePropertyHandler.withIndirectTable(getPropertyTable));
                        }
                    } else {
                        let addHandler = easyCRUDAddPropertyHandler.withConditionEnabled(
                            easyCRUDHandler.withConditions
                        );
                        if (easyCRUDHandler.withAddTitle === false) {
                            addHandler = addHandler.withoutTitle();
                        }

                        easyCRUDProps.push(addHandler);

                        if (easyCRUDHandler.withEdit !== false) {
                            let editHandler = easyCRUDEditPropertyHandler
                                .withConditionEnabled(easyCRUDHandler.withConditions)
                                .withIndirectTable(getPropertyTable);
                            if (easyCRUDHandler.withEditTitle === false) {
                                editHandler = editHandler.withoutTitle();
                            }

                            easyCRUDProps.push(editHandler);
                        }

                        if (easyCRUDHandler.withDelete) {
                            let deleteHandler = easyCRUDDeletePropertyHandler
                                .withConditionEnabled(easyCRUDHandler.withConditions)
                                .withIndirectTable(getPropertyTable);
                            if (easyCRUDHandler.withDeleteTitle === false) {
                                deleteHandler = deleteHandler.withoutTitle();
                            }

                            easyCRUDProps.push(deleteHandler);
                        }

                        const switchToCustomProperty: PropertyDescriptor = {
                            kind: PropertyKind.ConfigurationButton,
                            property: { name: "dummy" },
                            label: "Enable advanced actions",
                            section: {
                                name: "Advanced actions",
                                order: 0,
                                header: "Actions",
                            },
                            buttonTitle: "Enable advanced actions",
                            explanation:
                                "Advanced actions allow you to add your own actions as buttons in the collection items or in the title bar.",
                            onClick: update =>
                                update(d => {
                                    const flags = getEasyCRUDFlags(d as ComponentDescription);
                                    return {
                                        ...(flags === undefined ? undefined : easyCRUDHandler.lower(d, flags, false)),
                                        ...easyCRUDUndefinedProperties,
                                    };
                                }),
                        };
                        easyCRUDProps.push(switchToCustomProperty);
                    }
                } else if (!forEasyTabConfiguration) {
                    const switchToEasyCRUDProperty: PropertyDescriptor = {
                        kind: PropertyKind.ConfigurationButton,
                        property: { name: "dummy" },
                        label: "Switch back to default actions",
                        section: easyCRUDAddPropertyHandler.section,
                        buttonTitle: "Switch back to default actions",
                        explanation: undefined,
                        onClick: (update, tables, iccc) =>
                            update(d => {
                                const itemTable = getPropertyTable?.(tables, d, d, iccc, [])?.table;
                                if (itemTable === undefined) return undefined;
                                return {
                                    ...easyCRUDHandler.updateSource(d, tables, itemTable, iccc),
                                    ...makeEasyCRUDSwitchProperties(),
                                };
                            }),
                    };
                    easyCRUDProps.push(switchToEasyCRUDProperty);
                }
            }

            const extraProps = forEasyTabConfiguration
                ? [notesPropertyDescriptor]
                : extraPropertyDescriptors?.(getPropertyTable) ?? [];

            const additionalColumnsProps = this.getColumnAssignmentPropertyDescriptor(
                desc,
                ccc,
                getPropertyTable,
                containingScreenTables
            );

            return [
                ...superProps,
                ...componentPropsBefore,
                ...itemProps,
                ...componentPropsAfter,
                ...easyCRUDProps,
                ...extraProps,
                ...additionalColumnsProps,
            ];
        }

        public getActionDescriptors<T extends ArrayContentDescriptionForProperties<TComponentProps & TItemProps>>(
            desc: T,
            containingScreenTables: InputOutputTables | undefined,
            getIndirectTable: PropertyTableGetter | undefined,
            adc: AppDescriptionContext
        ): readonly ActionPropertyDescriptor[] {
            const forEasyCRUD = hasEasyCRUD(desc);
            const actionKinds = getDefaultPrimitiveActionKinds(adc, undefined);

            return [
                ...makeActionDescriptors(componentActionSpecs ?? [], {
                    desc,
                    parentDesc: undefined,
                    getPropertyTable: undefined,
                    adc,
                    tables: containingScreenTables,
                    mutatingScreenKind: undefined,
                    sectionOverride: undefined,
                    forEasyCRUD,
                    forEasyTabConfiguration: false,
                    isFirstComponent: undefined,
                    actionKinds,
                }),
                ...makeActionDescriptors(itemActionSpecs ?? [], {
                    desc,
                    parentDesc: undefined,
                    getPropertyTable: getIndirectTable,
                    adc,
                    tables: containingScreenTables,
                    mutatingScreenKind: undefined,
                    sectionOverride: undefined,
                    forEasyCRUD,
                    forEasyTabConfiguration: false,
                    isFirstComponent: undefined,
                    actionKinds,
                }),
            ];
        }

        private getExcludedOutputColumns(desc: Description | undefined): ReadonlySet<string> {
            if (!isDefined(desc) || !isDefined(columnAssignments)) return new Set();

            const excludeOutputColumns: string[] = [];
            const { excludedProperties } = columnAssignments;

            for (const excludedProperty of excludedProperties) {
                const property = (desc as any)[excludedProperty];
                const column = getColumnProperty(property);
                if (column !== undefined) {
                    excludeOutputColumns.push(column);
                }
            }

            return new Set(excludeOutputColumns);
        }

        private getColumnAssignmentPropertyDescriptor<
            T extends ArrayContentDescriptionForProperties<TComponentProps & TItemProps>
        >(
            desc: T | undefined,
            ccc: AppDescriptionContext,
            getPropertyTable: PropertyTableGetter | undefined,
            containingScreenTables: InputOutputTables | undefined
        ): PropertyDescriptor[] {
            const columnAssignmentsProps: PropertyDescriptor[] = [];

            if (!isDefined(columnAssignments) || !isDefined(desc) || !isDefined(containingScreenTables)) {
                return columnAssignmentsProps;
            }

            const commentsTable = getPropertyTable?.(containingScreenTables, desc, desc, ccc, [])?.table;
            if (!isDefined(commentsTable)) {
                return columnAssignmentsProps;
            }

            const columnAssignmentsTables = makeInputOutputTables(containingScreenTables.input, commentsTable);

            const [columnAssignmentsPropertyDescriptor] =
                makePropertyDescriptorsForColumns<InlineListComponentDescription>(
                    ccc,
                    columnAssignmentsTables.input,
                    columnAssignmentsTables.output,
                    getColumnAssignments,
                    columns => ({ columnAssignments: columns } as Partial<InlineListComponentDescription>),
                    {
                        withActionSource: false,
                        withClearColumn: false,
                        withLinkColumns: true,
                        withArrays: true,
                        forAddingRow: true,
                        allowCustomAndUserProfile: true,
                        emptyByDefault: true,
                        propertySection: PropertySection.AdditionalColumns,
                        excludeOutputColumns: this.getExcludedOutputColumns(desc),
                    }
                );
            columnAssignmentsProps.push(...columnAssignmentsPropertyDescriptor);
            return columnAssignmentsProps;
        }

        public lowerDescriptionForBuilding<
            T extends ArrayContentDescriptionForProperties<TComponentProps & TItemProps>
        >(desc: T, forGC: boolean): T {
            if (easyCRUDHandler === undefined) return desc;
            const flags = getEasyCRUDFlags(desc as unknown as ComponentDescription);
            if (flags === undefined) return desc;
            return { ...desc, ...easyCRUDHandler.lower(desc, flags, forGC), ...easyCRUDUndefinedProperties };
        }

        public getEasyTabConfiguration<T extends ArrayContentDescriptionForProperties<TComponentProps & TItemProps>>(
            desc: T
        ): ComponentEasyTabConfiguration | undefined {
            if (!hasEasyCRUD(desc)) return undefined;
            return {
                titlePropertyName: componentPropertySpecs.find(s => s.isComponentTitle)?.name,
            };
        }

        public defaultContentDescription<TBase>(
            baseDesc: TBase,
            tables: InputOutputTables,
            adc: AppDescriptionContext,
            insideInlineList: boolean,
            containingScreenTables: InputOutputTables | undefined,
            mutatingScreenKind: MutatingScreenKind | undefined,
            isDefaultArrayScreen: boolean,
            getIndirectTable: PropertyTableGetter | undefined,
            specialCaseDescriptor: ComponentSpecialCaseDescriptor | undefined,
            usedColumns: ReadonlySet<TableColumn> | undefined,
            editedColumns: ReadonlySet<TableColumn> | undefined
        ): (TBase & ArrayContentDescriptionForProperties<TComponentProps & TItemProps>) | undefined {
            const ccc = promoteAppDescriptionContext(adc);
            const withEasyCRUD = easyCRUDHandler !== undefined && ccc !== undefined;

            if (withEasyCRUD) {
                baseDesc = {
                    ...baseDesc,
                    ...makeEasyCRUDSwitchProperties(),
                };
            }

            const desc = super.defaultContentDescription(
                baseDesc as Description,
                tables,
                adc,
                insideInlineList,
                containingScreenTables,
                mutatingScreenKind,
                isDefaultArrayScreen,
                getIndirectTable,
                specialCaseDescriptor,
                usedColumns,
                editedColumns
            );
            if (desc === undefined) return undefined;

            const itemTable = getIndirectTable?.(tables, desc, desc, adc, [])?.table;
            if (itemTable === undefined) return undefined;

            let populated = populateDescription(
                d => this.getActionDescriptors(d, containingScreenTables, getIndirectTable, adc),
                PopulationMode.Default,
                desc,
                desc,
                tables,
                adc,
                false,
                undefined
            ) as any;
            if (populated === undefined) return undefined;

            if (makeSubComponents !== undefined) {
                populated = {
                    ...populated,
                    components: makeSubComponents(tables),
                } as ComponentDescription;
            }

            if (specialCaseDescriptor !== undefined) {
                const scd = defined(specialCases.find(c => c.name === specialCaseDescriptor.name));
                if (scd.setAsCurrent !== undefined) {
                    populated = scd.setAsCurrent(populated, tables, itemTable, adc, mutatingScreenKind);
                }
            }

            if (withEasyCRUD) {
                populated = {
                    ...populated,
                    ...easyCRUDHandler.updateSource(defined(populated), tables, itemTable, ccc),
                };
            }

            return populated as any;
        }

        public getDescriptiveName(
            _desc: ArrayContentDescriptionForProperties<TComponentProps & TItemProps>,
            _caption: string | undefined,
            _inlineListHostTable: TableGlideType | undefined,
            propertyDisplayName: string | undefined
        ): [string, string] | undefined {
            if (displayName === undefined) return undefined;
            return [displayName, propertyDisplayName ?? ""];
        }

        public getEditedColumns<T extends ArrayContentDescriptionForProperties<TComponentProps & TItemProps>>(
            getPropertyTable: PropertyTableGetter | undefined,
            desc: T,
            tables: InputOutputTables,
            ccc: AppDescriptionContext,
            mutatingScreenKind: MutatingScreenKind | undefined,
            insideInlineList: boolean,
            containingScreenTables: InputOutputTables | undefined,
            isDefaultArrayScreen: boolean,
            withActions: boolean
        ): EditedColumnsAndTables {
            let edited = super.getEditedColumns(
                getPropertyTable,
                desc,
                tables,
                ccc,
                mutatingScreenKind,
                insideInlineList,
                containingScreenTables,
                isDefaultArrayScreen,
                withActions
            );

            let itemTable: TableGlideType | undefined;
            if (getPropertyTable !== undefined) {
                itemTable = getPropertyTable(tables, desc, desc, ccc, [])?.table;
            } else {
                itemTable = tables.input;
            }

            if (getEditedColumns !== undefined) {
                const additional = getEditedColumns(desc, tables, itemTable, ccc);
                return combineEditedColumnsAndTables(edited, additional ?? emptyEditedColumnsAndTables);
            }

            if (canDeleteRows && itemTable !== undefined) {
                edited = {
                    ...edited,
                    deletedTables: [...edited.deletedTables, getTableName(itemTable)],
                };
            }
            return edited;
        }

        public adjustContentDescriptionAfterUpdate(
            desc: ArrayContentDescriptionForProperties<TComponentProps & TItemProps>,
            updates:
                | Partial<
                      ArrayContentDescriptionForProperties<TComponentProps & TItemProps> &
                          InlineListComponentDescription
                  >
                | undefined,
            tables: InputOutputTables,
            ccc: InteractiveComponentConfiguratorContext,
            getPropertyTable: PropertyTableGetter | undefined
        ): ArrayContentDescriptionForProperties<TComponentProps & TItemProps> {
            if (isContainer) {
                desc = adjustSeparatorsForContainer(desc);
            }

            const withEasyCRUD = hasEasyCRUD(desc);
            if (!withEasyCRUD || updates?.propertyName === undefined) {
                return desc;
            }

            assert(easyCRUDHandler !== undefined);
            const itemTable =
                getPropertyTable === undefined ? tables.input : getPropertyTable?.(tables, desc, desc, ccc, [])?.table;
            if (itemTable === undefined) return desc;
            return {
                ...desc,
                ...easyCRUDHandler.updateSource(desc, tables, itemTable, ccc),
            };
        }

        public inflateContent<T extends ArrayContentDescriptionForProperties<TComponentProps & TItemProps>>(
            ib: WireInflationBackend,
            desc: T,
            _captionGetter: WireStringGetter | undefined,
            containingRowIB: WireInflationBackend | undefined,
            componentID: string | undefined,
            filterColumnName: string | undefined
        ): WireTableComponentHydratorConstructor | undefined {
            if (contentInflator !== undefined) {
                const additionalTargetColumnsNames = getWritableColumnsForColumnAssignment(ib.adc, ib.tables.output, {
                    withLinkColumns: true,
                    withArrays: true,
                    forAddingRow: true,
                    excludeOutputColumns: this.getExcludedOutputColumns(desc),
                }).map(c => c.name);
                return contentInflator(
                    ib,
                    desc,
                    containingRowIB,
                    componentID,
                    filterColumnName,
                    new Set(additionalTargetColumnsNames)
                );
            }

            assert(paging === "custom" || typeof paging === "number");
            const { defaultPageSize, hasFixedPaging } = this;

            // This is ugly.  Maybe we should somehow type
            // this class so that `desc` is always an
            // `InlineListComponentDescription`.

            const pageSize = getPageSizeForPaging(
                getNumberProperty((desc as unknown as InlineListComponentDescription).pageSize),
                defaultPageSize,
                hasFixedPaging
            );

            const tableName = getTableName(ib.tables.input);

            const componentGetters = inflateGetters(defined(containingRowIB), spec.componentProperties, desc);
            const itemGetters = inflateGetters(ib, spec.itemProperties, desc);

            const groupByGetters = this.makeGroupByGetters(ib, desc);

            // To make TS happy
            assert(format !== ArrayScreenFormat.DataGrid);

            const componentEnricher = inflateComponentEnricher<WireListComponentGeneric<ArrayScreenFormat>>(
                ib,
                desc as unknown as InlineListComponentDescription
            );

            const breadcrumbsEnricher = inflateComponentEnricher<WireBreadcrumbsComponent>(ib, {
                kind: WireComponentKind.Breadcrumbs,
                componentID: `see-all-breadcrumb-${componentID}`,
            });

            const hasSeeAllAction = format === ArrayScreenFormat.CardCollection;

            const makeToggleSubsidiaryActionToken = (
                thb: WireTableComponentHydrationBackend,
                key: string,
                seeAllSubsidiaryOpenState: WireAlwaysEditableValue<string | undefined>
            ) => {
                if (!hasSeeAllAction) return undefined;

                const token = thb.registerAction(`toggleSeeAllSubsidiary-${key}`, async ab => {
                    const shouldClose = seeAllSubsidiaryOpenState.value === key;
                    return ab.valueChanged(
                        seeAllSubsidiaryOpenState.onChangeToken,
                        shouldClose ? undefined : key,
                        ValueChangeSource.User
                    );
                });
                return { token };
            };

            const finishComponent = (
                thb: WireTableComponentHydrationBackend,
                chb: WireRowComponentHydrationBackend | undefined,
                groups: WirePagedGroup<unknown>[],
                groupPaging: WirePaging,
                searchActive: boolean,
                seeAllSubsidiaryOpenState: WireAlwaysEditableValue<string | undefined>
            ) => {
                const hydrated = definedMap(chb, b => hydrateGetters<any>(componentGetters, b, "component."));

                const isSubsidiaryOpen = hasSeeAllAction && seeAllSubsidiaryOpenState.value !== undefined;

                const closeSeeAllSubsidiaryActionToken = thb.registerAction(`closeSeeAllSubsidiary`, async ab => {
                    return ab.valueChanged(seeAllSubsidiaryOpenState.onChangeToken, undefined, ValueChangeSource.User);
                });
                const closeSeeAllSubsidiaryAction = { token: closeSeeAllSubsidiaryActionToken };
                function makeSubsidiary(): WireSubsidiaryScreen {
                    const hydratedCrumbs = definedMap(chb, hb =>
                        hydrateBreadcrumbs(
                            breadcrumbsEnricher,
                            hb,
                            [
                                {
                                    title: hb.currentScreenTitle,
                                    action: closeSeeAllSubsidiaryAction,
                                },
                            ],
                            "All"
                        )
                    );
                    const crumbsComponent = hydratedCrumbs?.component ?? null;
                    const listComponent = componentEnricher({
                        kind: WireComponentKind.List,
                        format: defined(format),
                        helpPath: spec.docURL,
                        componentTitle: "",
                        quotaKey: spec.withQuotaKey === true ? thb.quotaKey : undefined,
                        groups: groups
                            .filter(group => group.groupKey === seeAllSubsidiaryOpenState.value)
                            .map(({ toggleSeeAllAction, ...group }) => group),
                        paging: groupPaging,
                        toggleSeeAllAction: undefined,
                        ...hydrated?.result,
                        orientation: UIOrientation.Vertical,
                        titleActions: undefined,
                    });

                    return {
                        key: encodeScreenKey(`see-all-${componentID}`),
                        size: "current",
                        title: "",
                        flags: [],
                        specialComponents: [],
                        components: [crumbsComponent, listComponent],
                        // FIXME: No need for `isInModal` in subsidiaries
                        isInModal: true,
                        tabIcon: "",
                        closeAction: closeSeeAllSubsidiaryAction,
                    };
                }

                const subsidiary: WireSubsidiaryScreen | undefined = isSubsidiaryOpen ? makeSubsidiary() : undefined;

                if (groups.length === 0 && hydrated?.showIfCollectionEmpty !== true && !searchActive) {
                    return undefined;
                }

                return {
                    component: componentEnricher({
                        kind: WireComponentKind.List,
                        format: defined(format),
                        helpPath: spec.docURL,
                        componentTitle: "",
                        quotaKey: spec.withQuotaKey === true ? thb.quotaKey : undefined,
                        groups,
                        paging: groupPaging,
                        ...hydrated?.result,
                    }),
                    isValid: true,
                    // We don't do ##firstListItemActionToRun in Pages -
                    // we'll have to implement this once we support Apps
                    // here.
                    firstListItemActionToRun: undefined,
                    subsidiaryScreen: subsidiary,
                };
            };

            let hydrateFromQuery: SimpleTableComponentHydratorFromQuery | undefined;
            if (isBigTableOrExternal(ib.tables.input) && groupByGetters !== undefined) {
                const maybeSort = getSortFromArrayTransforms(desc.transforms ?? []);

                hydrateFromQuery = (chb, query, thb, searchActive, _pageIndexState, selectedPageSize, rowBackends) => {
                    assert(thb !== undefined);
                    const groupByColumnName = groupByGetters.column.name;
                    // If the Collection's sort column is the same as the
                    // group's sort column, then we have to use the order from
                    // the Collection's sort.
                    // https://github.com/quicktype/glide/issues/21706
                    const sortOrder = maybeSort?.columnName === groupByColumnName ? maybeSort.order : "asc";

                    const groupsAggregateQuery = query.withoutLimit().withGroupBy({
                        columns: [groupByColumnName],
                        limit: 1000,
                        aggregates: [],
                        sort: [
                            {
                                columnName: groupByColumnName,
                                order: sortOrder,
                            },
                        ],
                    });
                    const rowGroups = chb.resolveQueryAsTable(groupsAggregateQuery);
                    const hasNoGroups = rowGroups === undefined || isLoadingValue(rowGroups);
                    if (hasNoGroups && !searchActive) {
                        return undefined;
                    }

                    const defaultedRowGroups = hasNoGroups ? new Table() : rowGroups;

                    const [groupPaging, visibleRowGroups] = applyGroupPaging(thb, defaultedRowGroups.asArray());
                    const seeAllSubsidiaryOpenState = thb.getState<string | undefined>(
                        "seeAllSubsidiaryOpen",
                        undefined,
                        undefined,
                        false
                    );
                    const groups = makeQueryGroup({
                        itemRowHydrator: row => {
                            const rhb = rowBackends.get(row);
                            // We need to be aware of ##uniqueStateNames
                            // here.
                            return hydrateGetters<any>(itemGetters, rhb, `item[${row.$rowID}].`).result;
                        },
                        makeGroupToggleSubsidiaryActionToken: groupKey =>
                            makeToggleSubsidiaryActionToken(thb, groupKey, seeAllSubsidiaryOpenState),
                        chb,
                        groupByGetters,
                        query,
                        queryableTableSupport,
                        selectedPageSize,
                        thb,
                        visibleRowGroups,
                    });

                    return finishComponent(thb, chb, groups, groupPaging, searchActive, seeAllSubsidiaryOpenState);
                };
            }

            return makeSimpleWireTableComponentHydratorConstructor(
                ib,
                (thb, chb, searchActive, _dynamicFilterResult, rowBackends) => {
                    const rowGroups = makeGroups(thb, groupByGetters, tableName, (title, rows, groupValue) => {
                        return [title, rows, groupValue] as const;
                    });
                    if (rowGroups === undefined) return undefined;

                    const [groupPaging, visibleRowGroups] = applyGroupPaging(thb, rowGroups);
                    const seeAllSubsidiaryOpenState = thb.getState<string | undefined>(
                        "seeAllSubsidiaryOpen",
                        undefined,
                        undefined,
                        false
                    );
                    const groups = visibleRowGroups.map(([title, rows, groupValue]) => {
                        const groupKey = makeGroupKey(groupValue);
                        const pageIndexState = getItemsPageIndex(thb, groupByGetters !== undefined, groupKey);
                        const [itemsPaging, visibleRows] = applyPaging(rows, pageIndexState, pageSize);
                        const group: WirePagedGroup<unknown> = {
                            title: title ?? "",
                            items: visibleRows.map(row => {
                                const rhb = rowBackends.get(row);
                                // We need to be aware of ##uniqueStateNames
                                // here.
                                return hydrateGetters<any>(itemGetters, rhb, `item[${row.$rowID}].`).result;
                            }),
                            paging: itemsPaging,
                            toggleSeeAllAction: makeToggleSubsidiaryActionToken(
                                thb,
                                groupKey,
                                seeAllSubsidiaryOpenState
                            ),
                            groupKey,
                        };
                        return group;
                    });

                    return finishComponent(thb, chb, groups, groupPaging, searchActive, seeAllSubsidiaryOpenState);
                },
                hydrateFromQuery
            );
        }

        public convertInlineToPage(): ComponentDescription | undefined {
            return this.defaultArrayContentConvertToPage();
        }

        public convertArrayScreenToPage(): ComponentDescription | undefined {
            return this.defaultArrayContentConvertToPage();
        }
    }

    return new Handler(defined(format));
}
