import * as React from "react";
import type { SearchFieldVariant } from "./search-field-style";
import { SearchFieldStyle } from "./search-field-style";
import { AppIcon, GlideIcon, useAccelerator, isMac } from "@glide/common";
import "twin.macro";
import type { GlideFC } from "@glide/common";

/**
 * The parent should be relative and input element should be a sibling containing "peer" in the class names.
 */
export const SearchKBD: React.VFC = () => {
    const specialKey = isMac ? "⌘" : "Alt ";
    return (
        <div tw="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 transition-opacity opacity-100 peer-focus:opacity-0">
            <kbd tw="inline-flex items-center rounded bg-n200A mt-px px-[3px] py-px text-builder-xs text-text-xpale leading-normal tracking-tight [font-family: inherit]">
                {specialKey}K
            </kbd>
        </div>
    );
};

interface Props extends React.PropsWithChildren {
    value: string;
    onChange: (value: string) => void;
    onEnter?: () => void;
    placeholder: string;
    variant?: SearchFieldVariant;
    className?: string;
    autoFocus?: boolean;
    onFocus?: () => void;
    triggerViaShortcut?: boolean;
}

export const SearchField: GlideFC<Props> = p => {
    const {
        onEnter,
        value,
        onChange,
        variant,
        placeholder,
        className,
        autoFocus,
        onFocus: dispatchOnFocus,
        triggerViaShortcut = false,
        ...rest
    } = p;

    const [isFocused, setIsFocused] = React.useState(autoFocus ?? false);

    const inputRef = React.useRef<HTMLInputElement>(null);

    useAccelerator(
        triggerViaShortcut ? ["alt+k", "cmd+k"] : [],
        React.useCallback(() => {
            inputRef.current?.focus();
        }, [])
    );

    const close = React.useCallback(() => {
        onChange("");
        inputRef.current?.blur();
        setIsFocused(false);
    }, [onChange]);

    const onKeyUp = React.useCallback(
        (evt: React.KeyboardEvent<HTMLDivElement>) => {
            if (evt.key === "Escape" && isFocused) {
                close();
            } else if (evt.key === "Enter" && onEnter !== undefined) {
                onEnter();
            }
        },
        [close, isFocused, onEnter]
    );

    const onFocus = React.useCallback(() => {
        setIsFocused(true);
        dispatchOnFocus?.();
    }, [dispatchOnFocus]);

    const onBlur = React.useCallback(() => {
        if (value === "") {
            setIsFocused(false);
        }
    }, [value]);

    const onMouseDown = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const onInputChange = React.useCallback(
        (evnt: React.ChangeEvent<HTMLInputElement>) => {
            evnt.stopPropagation();
            onChange(evnt.target.value);
        },
        [onChange]
    );

    const variantOrDefault = variant ?? "default";
    const showClearButton = value !== "";

    return (
        <SearchFieldStyle
            variant={variantOrDefault}
            showClearButton={showClearButton}
            isFocused={isFocused}
            className={className}
            {...rest}>
            <div className="sf-slider">
                <GlideIcon kind="stroke" icon="st-search" iconSize={20} tw="mr-2 text-icon-dark" />
                <input
                    className="sf-input peer"
                    ref={inputRef}
                    placeholder={placeholder}
                    onKeyUp={onKeyUp}
                    value={value}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onInputChange}
                    autoFocus={autoFocus ?? false}
                />
                {showClearButton ? (
                    <button onClick={close} onMouseDown={onMouseDown} className="sf-close-button">
                        <AppIcon icon="01-43-remove-circle" size={16} />
                    </button>
                ) : (
                    triggerViaShortcut && <SearchKBD />
                )}
            </div>
        </SearchFieldStyle>
    );
};
