import { type ConfirmArgs, confirmModalTopic } from "@glide/common-core/dist/js/components/confirm-modal";
import type { AppFeatures } from "@glide/app-description";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { isPlayer, isTemplatePlayer } from "@glide/common-core/dist/js/routes";
import { standalone } from "@glide/common-core/dist/js/support/device";
import { AppKind } from "@glide/location-common";
import { WireModal } from "@glide/wire-renderer";
import classNames from "classnames";
import PubSub from "pubsub-js";
import * as React from "react";

import { APP_MODAL_ROOT, APP_OVERLAY_ROOT, APP_ROOT, FLYOUT_ROOT, PUSH_MODAL_ROOT } from "../../../lib/constants";
import ConfirmModal from "../confirm-modal/confirm-modal";
import { AppRoot, AppStyle, FlyoutContainer, ModalContainer, ModalRoot, PushModalContainer } from "./app-style";

type ConfirmArgsWithResolve = ConfirmArgs & { resolve: (result: boolean) => void };

interface Props extends React.PropsWithChildren {
    className?: string;
    children: React.ReactNode;
    refCallback?: ((element: HTMLElement) => void) & string;
    appFeatures: AppFeatures;
}

interface State {
    confirmArgs: ConfirmArgsWithResolve | undefined;
}

export default class App extends React.PureComponent<Props, State> {
    public state: State = {
        confirmArgs: undefined,
    };

    private token: any;
    public componentDidMount() {
        this.token = PubSub.subscribe(confirmModalTopic, (_method: string, args: ConfirmArgs) => {
            const result = new Promise<boolean>(resolve => {
                this.setState({
                    confirmArgs: {
                        ...args,
                        resolve,
                    },
                });
            });

            args.awaitable = result;
        });
    }

    public componentDidUpdate(_: Props, prevState: State) {
        const { confirmArgs } = prevState;
        // If we're replacing confirm modals, reject the last visible one.
        if (confirmArgs !== undefined && confirmArgs !== this.state.confirmArgs) {
            confirmArgs.resolve(false);
        }
    }

    public componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    public render() {
        const { className, children, refCallback, appFeatures } = this.props;
        const isBuilder = className ? className.includes("builder") : false;
        const appKind = getAppKindFromFeatures(appFeatures);
        const isPage = appKind === AppKind.Page;
        const isTemplatePreviewer = isTemplatePlayer();
        const { confirmArgs } = this.state;
        let confirm: React.ReactNode;

        if (confirmArgs !== undefined) {
            if (appKind === AppKind.Page) {
                confirm = (
                    <WireModal
                        title={confirmArgs.title}
                        accept={confirmArgs.accept ?? "Continue"}
                        cancel={confirmArgs.cancel}
                        description={confirmArgs.description}
                        onFinish={(accepted: boolean) => {
                            confirmArgs.resolve(accepted);
                            this.setState({ confirmArgs: undefined });
                        }}
                    />
                );
            } else {
                confirm = (
                    <ConfirmModal
                        title={confirmArgs.title}
                        description={confirmArgs.description}
                        accept={confirmArgs.accept}
                        cancel={confirmArgs.cancel}
                        modalStyle={confirmArgs.style}
                        onFinish={(accepted: boolean) => {
                            confirmArgs.resolve(accepted);
                            this.setState({ confirmArgs: undefined });
                        }}
                    />
                );
            }
        }

        return (
            <AppStyle isBuilderOrTemplatePreviewer={isBuilder || isTemplatePreviewer} isAppKindPage={isPage}>
                <AppRoot
                    isAppKindPage={isPage}
                    id={APP_ROOT}
                    className={classNames(className, { standalone })}
                    ref={refCallback as any}>
                    {children}
                    <PushModalContainer>
                        <ModalRoot id={PUSH_MODAL_ROOT} />
                    </PushModalContainer>
                    {/* The builder previewer is `transform`ed so `fixed` elements inside of it are messed up
                    We're slapping the app modal root outside of the transform in the Smartphone and BrowserContainer components */}
                    {isPlayer() && (
                        <ModalContainer>
                            <ModalRoot id={APP_MODAL_ROOT} />
                        </ModalContainer>
                    )}
                    <ModalContainer>
                        <ModalRoot id={APP_OVERLAY_ROOT} />
                    </ModalContainer>
                    <FlyoutContainer>
                        <ModalRoot id={FLYOUT_ROOT}>{confirm !== undefined && confirm}</ModalRoot>
                    </FlyoutContainer>
                </AppRoot>
            </AppStyle>
        );
    }
}
