import {
    type Icon,
    type ActionComponentDescription,
    type ActionDescription,
    type ComponentDescription,
    type ComponentKind,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
    PropertyKind,
    getActionProperty,
    getEnumProperty,
    getIconProperty,
    makeActionProperty,
    makeArrayProperty,
    makeEnumProperty,
    makeStringProperty,
} from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import type { TableColumn, SchemaInspector } from "@glide/type-schema";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { Appearance, Mood, reducedMoodCases } from "@glide/component-utils";
import type { WireAppButton, WireAppButtonComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type WireButtonsBlockDescription,
    makeActionWithTitle,
} from "@glide/fluent-components/dist/js/fluent-components";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentDescriptor,
    type ComponentSpecialCaseDescriptor,
    type EnumPropertyCase,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    PropertySection,
    SwitchPropertyHandler,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { makeTierList } from "@glide/plugins";
import { defined, panic } from "@glideapps/ts-necessities";
import {
    type WireRowComponentHydratorConstructor,
    WireActionResult,
    WireComponentKind,
    WireActionOffline,
    type WireInflationBackend,
    type WireRowComponentHydrationBackend,
} from "@glide/wire";
import { handlerForActionKind } from "../actions";
import { tokenForProperty } from "../actions/base";
import { makeShowToastAction } from "../form-on-submit";
import {
    hydrateAction,
    inflateActions,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerBusyActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { getActionsForComponent } from "./component-utils";
import { labelCaptionStringOptions, makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";
import { makeDefaultActionDescriptor } from "./primitive";
import type { StaticActionContext } from "../static-context";

export const ComponentKindButton: ComponentKind = "button";

const defaultFloatingIcon: Icon = "01-47-flash-1";

interface ButtonComponentDescription extends ActionComponentDescription {
    // The form submission button doesn't have a property name
    readonly propertyName: LegacyPropertyDescription | undefined;
    readonly appearance: LegacyPropertyDescription;
    readonly floatingIcon: PropertyDescription | undefined;

    readonly caption: LegacyPropertyDescription | undefined;
    readonly mood: Mood | undefined;

    readonly nonBlocking: PropertyDescription | undefined;
}

// ##convertLegacyActions:
// These old actions aren't supported in NCM anymore.  They don't take
// arguments, but instead rely on the button to provide the argument, which is
// a mechanism we long abandoned.
const newActionForLegacy: Partial<Record<ActionKind, [kind: ActionKind, propertyName: string]>> = {
    [ActionKind.OpenLink]: [ActionKind.OpenLinkWithArgs, "link"],
    [ActionKind.PhoneCall]: [ActionKind.PhoneCallWithArgs, "number"],
    [ActionKind.TextMessage]: [ActionKind.TextMessageWithArgs, "number"],
    [ActionKind.CopyToClipboard]: [ActionKind.CopyToClipboardWithArgs, "data"],
    [ActionKind.OpenMap]: [ActionKind.OpenMapWithArgs, "address"],
    [ActionKind.SendEmail]: [ActionKind.SendEmailWithArgs, "email"],
    [ActionKind.ShowShareSheet]: [ActionKind.ShowShareSheetWithArgs, "data"],
};

const appearanceCases: readonly EnumPropertyCase<Appearance>[] = [
    {
        value: Appearance.Filled,
        label: "Filled",
        icon: "buttonFilled",
    },
    {
        value: Appearance.Transparent,
        label: "Transparent",
        icon: "buttonTransparent",
    },
    {
        value: Appearance.Bordered,
        label: "Bordered",
        icon: "buttonBordered",
    },
    {
        value: Appearance.Simple,
        label: "Simple",
        icon: "buttonSimple",
    },
    {
        value: Appearance.Floating,
        label: "Floating",
        icon: "buttonFloating",
    },
];

const nonBlockingPropertyHandler = new SwitchPropertyHandler(
    { nonBlocking: false },
    "Non-blocking",
    PropertySection.Options
);

function isFormButton({ actions }: ButtonComponentDescription): boolean {
    return getActionProperty(actions)?.kind === ActionKind.FormScreen;
}

function isScanBarcodeButton({ actions }: ButtonComponentDescription): boolean {
    return getActionProperty(actions)?.kind === ActionKind.LiveScanLinearBarcode;
}

function buttonNeedsProperty(desc: ButtonComponentDescription): boolean {
    const action = getActionProperty(desc.actions);
    return action !== undefined && handlerForActionKind(action.kind).needsData === true;
}

function getAppearance(desc: ButtonComponentDescription): Appearance | undefined {
    return getEnumProperty(desc.appearance);
}

function getMood(desc: ButtonComponentDescription): Mood | undefined {
    return getEnumProperty(desc.mood);
}

const formButtonSpecialCase: ComponentSpecialCaseDescriptor = {
    name: "Form Button",
    analyticsName: "form-button",
    description: "Open a form screen",
    img: "co-form-button",
    group: "Buttons",
    isNew: false,
    needsInputContext: true,
    appKinds: AppKind.App,
};
const floatingButtonSpecialCase: ComponentSpecialCaseDescriptor = {
    name: "Floating Button",
    analyticsName: "floating-button",
    description: "Tap to perform an action",
    img: "co-floating-button",
    group: "Buttons",
    isNew: false,
    needsInputContext: true,
    appKinds: AppKind.App,
};
const scanBarcodeButtonSpecialCase: ComponentSpecialCaseDescriptor = {
    name: "Scan Barcode Button",
    analyticsName: "scan-linear-barcode-button",
    description: "Tap to scan a barcode",
    // FIXME: Proper icon
    img: "co-button",
    group: "Buttons",
    needsInputContext: false,
    tier: makeTierList("pro"),
    appKinds: AppKind.App,
};

function makeButtonWithAction(caption: string, action: ActionDescription): ButtonComponentDescription {
    return {
        ...makeEmptyComponentDescription(ComponentKindButton),
        propertyName: undefined,
        caption: makeStringProperty(caption),
        appearance: makeEnumProperty(Appearance.Filled),
        floatingIcon: undefined,
        actions: [action],
        mood: undefined,
        nonBlocking: undefined,
    };
}

export function inflateAppButton(
    ib: WireInflationBackend,
    key: string,
    titleDesc: LegacyPropertyDescription | undefined,
    style: Appearance,
    mood: Mood,
    icon: string | undefined,
    actions: readonly ActionDescription[],
    alwaysReturnAction: boolean
): ((hb: WireRowComponentHydrationBackend) => WireAppButton | undefined) | undefined {
    let actionHydrator = inflateActions(ib, actions);
    if (actionHydrator instanceof WireActionResult) {
        const result = actionHydrator;
        if (!alwaysReturnAction) return undefined;
        actionHydrator = () => result;
    }

    const [titleGetter] = inflateStringProperty(ib, titleDesc, true);

    return hb => {
        let onTap = registerBusyActionRunner(hb, key, () =>
            hydrateAction(defined(actionHydrator), hb, false, undefined)
        );
        if (onTap === undefined) {
            if (!alwaysReturnAction) return undefined;
            onTap = { token: WireActionOffline };
        }
        return {
            title: titleGetter(hb) ?? "",
            mood,
            icon,
            style,
            onTap,
        };
    };
}

export class ButtonComponentHandler extends ComponentHandlerBase<ButtonComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindButton);
    }

    public get needsColumns(): boolean {
        return false;
    }

    public getDescriptor(
        desc: ButtonComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [];
        const isForm = desc !== undefined && isFormButton(desc);
        if (desc === undefined || buttonNeedsProperty(desc)) {
            properties.push({
                kind: PropertyKind.Column,
                property: { name: "propertyName" },
                label: "Data",
                required: true,
                editable: true,
                searchable: false,
                isDefaultCaption: true,
                columnFilter: getPrimitiveColumnsSpec,
                section: PropertySection.Data,
            });
        }

        properties.push(
            makeCaptionStringPropertyDescriptor("Go!", true, mutatingScreenKind, labelCaptionStringOptions)
        );

        const isFloating = desc !== undefined && getAppearance(desc) === Appearance.Floating;

        if (!isFloating) {
            properties.push({
                kind: PropertyKind.Enum,
                property: { name: "mood" },
                label: "Mood",
                menuLabel: "Mood",
                cases: reducedMoodCases,
                defaultCaseValue: Mood.Default,
                section: PropertySection.Design,
                visual: "dropdown",
                isSearchable: false,
            });
        }

        properties.push({
            kind: PropertyKind.Icon,
            property: { name: "floatingIcon" },
            label: "Icon",
            defaultIcon: isFloating ? defaultFloatingIcon : undefined,
            section: PropertySection.Design,
        });

        properties.push({
            kind: PropertyKind.Enum,
            property: { name: "appearance" },
            label: "Style",
            menuLabel: "Show as",
            cases: appearanceCases,
            defaultCaseValue: Appearance.Filled,
            section: PropertySection.Design,
            visual: "small-images",
        });

        if (ccc.userFeatures.nonBlockingButton) {
            properties.push(nonBlockingPropertyHandler);
        }

        properties.push(...this.getBasePropertyDescriptors());

        return {
            name: "Button",
            description: "Tap to perform an action",
            img: isFloating ? "co-floating-button" : "co-button",
            group: "Buttons",
            helpUrl: getDocURL(isForm ? "formButton" : "button"),
            properties,
        };
    }

    public getSpecialCaseDescriptors(): readonly ComponentSpecialCaseDescriptor[] {
        return [formButtonSpecialCase, floatingButtonSpecialCase, scanBarcodeButtonSpecialCase];
    }

    public getActionDescriptors(
        _desc: ButtonComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        schema: SchemaInspector | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly ActionPropertyDescriptor[] {
        return [makeDefaultActionDescriptor(schema, mutatingScreenKind, () => makeShowToastAction("Yay!"))];
    }

    public newSpecialCaseComponent(
        specialCaseDescriptor: ComponentSpecialCaseDescriptor,
        tables: InputOutputTables,
        usedProperties: ReadonlySet<TableColumn>,
        editedProperties: ReadonlySet<TableColumn>,
        context: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ButtonComponentDescription | undefined {
        const env: StaticActionContext<InteractiveComponentConfiguratorContext> = {
            tables,
            context,
            mutatingScreenKind,
            isAutomation: false,
        };

        if (specialCaseDescriptor === formButtonSpecialCase) {
            // not a problem
            const action = defined(handlerForActionKind(ActionKind.FormScreen)).newActionDescription(env);
            if (action === undefined) return undefined;

            return makeButtonWithAction("Open Form", action);
        } else if (specialCaseDescriptor === floatingButtonSpecialCase) {
            const desc = this.newComponent(tables, usedProperties, editedProperties, context, mutatingScreenKind);
            if (desc === undefined) return undefined;
            return { ...desc, appearance: makeEnumProperty(Appearance.Floating) };
        } else if (specialCaseDescriptor === scanBarcodeButtonSpecialCase) {
            // not a problem
            const action = defined(handlerForActionKind(ActionKind.LiveScanLinearBarcode)).newActionDescription(env);
            if (action === undefined) return undefined;

            return makeButtonWithAction("Scan Barcode", action);
        } else {
            return panic("No such button special case");
        }
    }

    public getDescriptiveName(
        desc: ButtonComponentDescription,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext
    ): [string, string] {
        let name: string;
        if (isFormButton(desc)) {
            name = "Form Button";
        } else if (isScanBarcodeButton(desc)) {
            name = "Scan Barcode Button";
        } else if (getAppearance(desc) === Appearance.Floating) {
            name = "Floating Button";
        } else {
            name = "Button";
        }
        const token = tokenForProperty(desc.caption, {
            context: ccc,
            tables,
            mutatingScreenKind: undefined,
            isAutomation: false,
        });
        return [name, token?.value ?? ""];
    }

    public inflate(
        ib: WireInflationBackend,
        desc: ButtonComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const { actions } = getActionsForComponent(this, desc, ib.tables, ib.adc, ib.mutatingScreenKind);

        const appearance = getAppearance(desc) ?? Appearance.Filled;
        const mood = getMood(desc) ?? Mood.Default;

        let floatingIcon = getIconProperty(desc.floatingIcon);

        // We must have a default icon if floating.
        if (appearance === Appearance.Floating) {
            floatingIcon = floatingIcon ?? defaultFloatingIcon;
        }

        const hydrator = inflateAppButton(ib, "", desc.caption, appearance, mood, floatingIcon, actions, false);
        if (hydrator === undefined) return undefined;

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const button = hydrator(hb);
            if (button === undefined) return undefined;

            const component: WireAppButtonComponent = {
                kind: WireComponentKind.AppButton,
                ...spreadComponentID(desc.componentID, forBuilder),
                ...button,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public static convertToNewActions(desc: ComponentDescription): ButtonComponentDescription {
        const button = desc as ButtonComponentDescription;
        const action = getActionProperty(button.actions);
        if (action === undefined) return button;
        const newAction = newActionForLegacy[action.kind];
        if (newAction === undefined) return button;
        const [newKind, propertyName] = newAction;
        return {
            ...button,
            propertyName: undefined,
            actions: makeActionProperty({
                ...action,
                kind: newKind,
                [propertyName]: button.propertyName,
            }),
        };
    }

    public convertToPage(appButton: ButtonComponentDescription): ComponentDescription {
        const button: WireButtonsBlockDescription = {
            ...makeEmptyComponentDescription(WireComponentKind.ButtonsBlock),
            buttons: makeArrayProperty([
                makeActionWithTitle(
                    appButton.caption as any,
                    getActionProperty(appButton.actions),
                    getIconProperty(appButton.floatingIcon)
                ),
            ]),
        };
        return button;
    }
}
