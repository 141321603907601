import "isomorphic-fetch";

import { v4 as uuid } from "uuid";
import { browserMightBeOniOS } from "@glide/common";
import {
    type JSONObject,
    type MapLocation,
    ExpiringCache,
    isResponseOK,
    localStorageGetItem,
    localStorageRemoveItem,
    localStorageSetItem,
    logError,
    logInfo,
    makeMapURL,
    maybe,
    nullToUndefined,
    parseAnonymousUserID,
    publishedAppKeyForID,
} from "@glide/support";
import { DefaultMap, assert, definedMap } from "@glideapps/ts-necessities";
// NOTE: This must remain a `type` import - we can't depend on Firebase here
import type firebase from "firebase/compat/app";
import { isLeft } from "fp-ts/lib/Either";
import { Base64 } from "js-base64";
import mapValues from "lodash/mapValues";
import { trackEvent } from "../analytics";
import type { AppAnalyticsEvents } from "../analytics/app-events";
import type { AnalyticsEvents } from "../analytics/events";
import {
    hookFirebaseSignOutTransitionEvent,
    importFirebase,
    signInToFirebaseWithCustomToken,
    waitForFirebaseSignIn,
} from "../authorization/auth";
import { rewriteCloudStorage } from "../cloud-storage";
import type {
    ActionAppFacilities,
    AppFacilities,
    IntegrationsAggregator,
    NativeTableSnapshot,
    TableSnapshot,
} from "../components/types";
import {
    type DataSnapshot,
    type PublishedAppSnapshot,
    publishedAppSnapshotForJSON,
    unzipPublishedAppString,
    unzipString,
    zipPublishedAppString,
} from "../Database";
import {
    type TableName,
    type TypeSchema,
    makeTypeSchema,
    tableGlideTypeCodecTableToTableGlideType,
} from "@glide/type-schema";
import { type AppSnapshotURLs, type RunIntegrationsBody, getAppSnapshotResponseBody } from "../firebase-function-types";
import { getLocationSettings } from "../location";
import * as routes from "../routes";
import { reloadBrowserWindow } from "../support/browser-reload";
import { type FrontendEventFields, frontendTrace } from "../tracing";
import { callCloudFunctionWeb, callServiceGatewayWeb } from "./function-utils";
import { generateFirestoreDocumentID } from "../id-generator";
import { getDeviceID } from "../device-id";

export async function getCurrentUser(): Promise<firebase.User | undefined> {
    const fb = await importFirebase();
    await waitForFirebaseSignIn();
    return nullToUndefined(fb.auth().currentUser);
}

async function addAuthorization(
    headers: { [key: string]: string },
    user: firebase.User
): Promise<{ [key: string]: string }> {
    return { ...headers, Authorization: `Bearer ${await user.getIdToken()}` };
}

interface SnapshotLocations extends AppSnapshotURLs {
    readonly fallbackPublicDataSnapshot?: string;
    readonly fallbackPrivateDataSnapshot?: string;
    readonly fallbackUnusedDataSnapshot?: string;
    readonly fallbackPublishedAppSnapshot?: string;
    readonly fallbackNativeTableSnapshots?: Record<string, string>;
}

interface CachedSnapshot {
    readonly appDataPromise: Promise<DataSnapshot | undefined>;

    // We keep track of which tables we've already fetched so we can log
    //  when we request a snapshot for a table more than once.
    // Only used for tracing.
    readonly fetchedTableNames: Set<string>;
}

function isCachedSnapshot(cached: Promise<DataSnapshot | undefined> | CachedSnapshot): cached is CachedSnapshot {
    return "appDataPromise" in cached && "fetchedTableNames" in cached;
}

// CachedSnapshot is used when `tableName` is passed to the DataSnapshotLoader methods.
interface AppDataSnapshotPromises {
    publicAppData?: Promise<DataSnapshot | undefined> | CachedSnapshot;
    privateAppData?: Promise<DataSnapshot | undefined> | CachedSnapshot;
    unusedAppData?: Promise<DataSnapshot | undefined> | CachedSnapshot;
}

interface SnapshotPromises extends AppDataSnapshotPromises {
    publishedApp?: Promise<PublishedAppSnapshot | undefined>;
    readonly nativeTables: Map<string, Promise<NativeTableSnapshot | undefined>>;
}

const storageRegex = /^https:\/\/storage\.googleapis\.com\/glide-[^.]+\.appspot\.com\//;

function replaceCloudStorageWithSameOrigin(u: string): string {
    let replacementTarget = `${window.location.origin}/data/`;
    if (replacementTarget.startsWith("/")) {
        replacementTarget = `${window.location.origin}${replacementTarget}`;
    }
    return u.replace(storageRegex, replacementTarget);
}

function replaceOriginsForSafariCORSBug(locations: SnapshotLocations): SnapshotLocations {
    // This has two purposes now:
    // 1. Work around a bad Safari CORS bug
    // 2. Work around hostile networks that block storage.googleapis.com
    // The remainder of this comment addresses the first purpose.
    //
    // We _finally_ have a handle on what the bug was.
    // We've historically used Pako to compress snapshots using gzip, and have been
    // labelling the result as "application/gzip". However, we've also been laundering
    // the result through UTF-16 LE strings, which makes the content not exactly "application/gzip".
    //
    // Because the response didn't actually have a valid gzip header, Safari would fail
    // the response under the guise of it being a CORS failure. So we'd proxy the response
    // through nginx endpoints to work around that.
    //
    // For a while, those nginx endpoints liked to fail with partial response bodies. A lot.
    // So we had to try the GCP endpoints first, and Fly second.
    //
    // Fly is now stable enough that we can do it the other way. Sadly, we're still obfuscating
    // snapshots in a way that Safari doesn't like when it's cross-origin so we still have to do this.
    //
    // This is always computed so that we can drop a debugger point here in dev.
    const alteredLocations: SnapshotLocations = {
        dataSnapshot: definedMap(locations.dataSnapshot, replaceCloudStorageWithSameOrigin),
        privateDataSnapshot: definedMap(locations.privateDataSnapshot, replaceCloudStorageWithSameOrigin),
        unusedDataSnapshot: definedMap(locations.unusedDataSnapshot, replaceCloudStorageWithSameOrigin),
        publishedAppSnapshot: definedMap(locations.publishedAppSnapshot, replaceCloudStorageWithSameOrigin),
        nativeTableSnapshots: definedMap(locations.nativeTableSnapshots, r =>
            mapValues(r, replaceCloudStorageWithSameOrigin)
        ),
        fallbackPublicDataSnapshot: locations.dataSnapshot,
        fallbackPrivateDataSnapshot: locations.privateDataSnapshot,
        fallbackUnusedDataSnapshot: locations.unusedDataSnapshot,
        fallbackPublishedAppSnapshot: locations.publishedAppSnapshot,
        fallbackNativeTableSnapshots: locations.nativeTableSnapshots,
    };

    return alteredLocations;
}

const snapshotHeaderTimeout = 5000;
const snapshotBodyTimeout = 30000;

async function fetchUnzip<T>(url: string, kind: string, extraFields: FrontendEventFields = {}): Promise<T | undefined> {
    url = rewriteCloudStorage(url);
    const abortController = new AbortController();
    try {
        const zipped = await frontendTrace("fetchSnapshot", { ...extraFields, url, kind }, async fields => {
            const headerTimeoutHandle = setTimeout(() => abortController.abort(), snapshotHeaderTimeout);
            const snapshotResponse = await fetch(url, {
                method: "GET",
                headers: { "Accept-Encoding": "gzip" },
                signal: abortController.signal,
            });
            clearTimeout(headerTimeoutHandle);

            if (!snapshotResponse.ok) {
                // We no longer care about the response body, so just stop receiving it.
                abortController.abort();
                return undefined;
            }

            const bodyTimeoutHandle = setTimeout(() => abortController.abort(), snapshotBodyTimeout);
            const text = await snapshotResponse.text();
            clearTimeout(bodyTimeoutHandle);

            fields.zippedLength = text.length;
            return text;
        });

        if (zipped === undefined) return undefined;

        return await frontendTrace(
            "unzipAndParseSnapshot",
            { url, kind, zippedLength: zipped.length },
            async fields => {
                const stringified = unzipString(zipped);
                fields.unzippedLength = stringified.length;
                let result = maybe(() => JSON.parse(stringified), undefined);
                if (result === undefined) {
                    result = maybe(() => JSON.parse(Base64.decode(stringified)), undefined);
                }
                return result;
            }
        );
    } catch (e: unknown) {
        logError(`Could not load ${kind} snapshot`, e);
        return undefined;
    }
}

async function fetchUnzipWithFallback<T>(
    url: string | undefined,
    fallbackURL: string | undefined,
    kind: string,
    extraFields: FrontendEventFields = {}
): Promise<T | undefined> {
    let result: T | undefined = url === undefined ? undefined : await fetchUnzip<T>(url, kind, extraFields);
    if (result === undefined && fallbackURL !== undefined) {
        result = await fetchUnzip<T>(fallbackURL, kind, extraFields);
    }
    return result;
}

// This is 2.5 minutes. These links expire after 5 minutes so we halve our
// internal expiry to give us some wiggle room.
const snapshotExpiryTime = 150_000;

export interface IntegrationsAggregatorFactory {
    get(appFacilities: ActionAppFacilities, props: Omit<RunIntegrationsBody, "instances">): IntegrationsAggregator;
}

export class MaterialAppFacilities implements AppFacilities {
    private isIOS = false;
    private snapshotLocationsByAppID: DefaultMap<string, SnapshotLocations> = new DefaultMap(() => ({}));
    private snapshotPromisesByAppID: DefaultMap<string, SnapshotPromises> = new DefaultMap(() => ({
        nativeTables: new Map(),
    }));
    private initialSchemasByAppID = new Map<string, TypeSchema>();
    private numRowsUsedByAppID = new Map<string, number>();

    public readonly fetch = fetch;

    constructor(private readonly integrationsAggregatorFactory: IntegrationsAggregatorFactory) {
        this.isIOS = browserMightBeOniOS;
    }

    public get deviceID(): string {
        return getDeviceID();
    }

    public makeRowID(): string {
        return generateFirestoreDocumentID();
    }

    public makeUUID(): string {
        return uuid();
    }

    public get isBackend(): boolean {
        return false;
    }

    public get locationSettings() {
        return getLocationSettings();
    }

    public mapURL(location: MapLocation): string {
        return makeMapURL(location, this.isIOS);
    }

    public async signInWithCustomToken(token: string): Promise<boolean> {
        const result = await signInToFirebaseWithCustomToken(token);
        if (result.user !== null) {
            await hookFirebaseSignOutTransitionEvent(() => reloadBrowserWindow("Firebase signed us out"));
        }
        return result.user !== null;
    }

    public async isSignedInToFirebase(): Promise<boolean> {
        const currentUser = await getCurrentUser();
        if (currentUser === undefined) return false;
        logInfo("is signed in?", currentUser);
        return parseAnonymousUserID(currentUser.uid) === undefined;
    }

    public async getAuthUserID(): Promise<string | undefined> {
        const user = await getCurrentUser();
        logInfo("current user", user);
        if (user === undefined) return undefined;
        return user.uid;
    }

    public async getAppUserEmail(): Promise<string | undefined> {
        const user = await getCurrentUser();
        if (user === undefined) return undefined;
        return nullToUndefined(user.email);
    }

    public async getIDToken(): Promise<string | undefined> {
        const user = await getCurrentUser();
        if (user === undefined) return undefined;
        return await user.getIdToken();
    }

    public async callCloudFunction(
        functionName: string,
        body: any,
        headers: { [key: string]: string } = {},
        stringify: boolean = true
    ): Promise<Response | undefined> {
        return await callCloudFunctionWeb(functionName, body, headers, stringify);
    }

    public async callAuthCloudFunction(
        functionName: string,
        body: any,
        headers: { [key: string]: string } = {},
        stringify: boolean = true
    ): Promise<Response | undefined> {
        const currentUser = await getCurrentUser();
        if (currentUser === undefined) return undefined;
        return await this.callCloudFunction(
            functionName,
            body,
            await addAuthorization(headers, currentUser),
            stringify
        );
    }

    public async callAuthIfAvailableCloudFunction(
        functionName: string,
        body: any,
        headers: { [key: string]: string } = {},
        stringify: boolean = true
    ): Promise<Response | undefined> {
        const currentUser = await getCurrentUser();
        if (currentUser !== undefined) {
            headers = await addAuthorization(headers, currentUser);
        }
        return await this.callCloudFunction(functionName, body, headers, stringify);
    }

    public async callServiceGateway(
        endpoint: string,
        body: any,
        headers: { [key: string]: string },
        stringify?: boolean,
        method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE"
    ): Promise<Response | undefined> {
        return await callServiceGatewayWeb(endpoint, body, headers, stringify, method);
    }

    public async callAuthServiceGateway(
        endpoint: string,
        body: any,
        headers: { [key: string]: string } = {},
        stringify: boolean = true,
        method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE"
    ): Promise<Response | undefined> {
        const currentUser = await getCurrentUser();
        if (currentUser === undefined) return undefined;
        return await this.callServiceGateway(
            endpoint,
            body,
            await addAuthorization(headers, currentUser),
            stringify,
            method
        );
    }

    public async callAuthIfAvailableServiceGateway(
        endpoint: string,
        body: any,
        headers: { [key: string]: string },
        stringify?: boolean,
        method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE"
    ): Promise<Response | undefined> {
        const currentUser = await getCurrentUser();
        if (currentUser !== undefined) {
            headers = await addAuthorization(headers, currentUser);
        }
        return this.callServiceGateway(endpoint, body, headers, stringify, method);
    }

    public getIntegrationsAggregator(base: Omit<RunIntegrationsBody, "instances">): IntegrationsAggregator {
        return this.integrationsAggregatorFactory.get(this, base);
    }

    public setSnapshotLocationsForAppID(
        appID: string,
        {
            dataSnapshot,
            privateDataSnapshot,
            unusedDataSnapshot,
            publishedAppSnapshot,
            nativeTableSnapshots,
        }: SnapshotLocations
    ): void {
        this.snapshotLocationsByAppID.set(appID, {
            dataSnapshot,
            privateDataSnapshot,
            unusedDataSnapshot,
            publishedAppSnapshot,
            nativeTableSnapshots,
        });

        // There could be some latency between when we download the published app and app data
        // snapshots, so to make everything slightly faster we just immediately attempt to prefetch
        // both of them concurrently.
        const priorPromises = this.snapshotPromisesByAppID.get(appID);
        this.snapshotPromisesByAppID.set(appID, {
            ...priorPromises,
            publishedApp:
                routes.isPlayer() && publishedAppSnapshot !== undefined
                    ? priorPromises.publishedApp ?? this.backgroundLoadPublishedAppSnapshot(appID)
                    : undefined,
        });
    }

    private snapshotLinkCache = new ExpiringCache<string, Promise<SnapshotLocations>>(snapshotExpiryTime);

    private async getSnapshotLocationsImpl(appID: string, useCacheFirst: boolean): Promise<SnapshotLocations> {
        // This can be called from the builder, too.  The builder loads app
        // data just like apps, by trying to load the snapshot first.  This
        // will also load the schema and make it available as the "initial"
        // schema, which the data store might use.
        const locations = this.snapshotLocationsByAppID.get(appID);
        const { dataSnapshot, privateDataSnapshot, unusedDataSnapshot, publishedAppSnapshot, nativeTableSnapshots } =
            locations;

        if (
            useCacheFirst &&
            (dataSnapshot !== undefined ||
                privateDataSnapshot !== undefined ||
                unusedDataSnapshot !== undefined ||
                publishedAppSnapshot !== undefined ||
                nativeTableSnapshots !== undefined)
        ) {
            return replaceOriginsForSafariCORSBug(locations);
        }

        const snapshotURLResponse = await this.callAuthIfAvailableCloudFunction("getAppSnapshot", { appID });
        if (snapshotURLResponse === undefined || !isResponseOK(snapshotURLResponse)) {
            void snapshotURLResponse?.text();
            return replaceOriginsForSafariCORSBug(locations);
        }

        try {
            const decodedResponse = getAppSnapshotResponseBody.decode(await snapshotURLResponse.json());
            if (isLeft(decodedResponse)) {
                return replaceOriginsForSafariCORSBug(locations);
            }
            const { schema: newSchema, numRowsUsedInApp } = decodedResponse.right;
            this.setSnapshotLocationsForAppID(appID, decodedResponse.right);
            if (newSchema !== undefined) {
                this.setInitialSchemaForAppID(
                    appID,
                    makeTypeSchema(newSchema.tables.map(tableGlideTypeCodecTableToTableGlideType)),
                    numRowsUsedInApp
                );
            }
            return replaceOriginsForSafariCORSBug(decodedResponse.right);
        } catch (e: unknown) {
            logError("Could not decode getAppSnapshot response", e);
            return replaceOriginsForSafariCORSBug(locations);
        }
    }

    private getSnapshotLocations(appID: string, useCacheFirst: boolean): Promise<SnapshotLocations> {
        return this.snapshotLinkCache.getOrCreate(appID, () => [
            this.getSnapshotLocationsImpl(appID, useCacheFirst),
            new Date(Date.now() + snapshotExpiryTime),
        ]);
    }

    private async loadDataSnapshot(
        appID: string,
        key: keyof AppDataSnapshotPromises,
        loader: (appID: string, fetchingAgain: boolean) => Promise<DataSnapshot | undefined>,
        tableName: string
    ): Promise<TableSnapshot | undefined> {
        const cachesForApp = this.snapshotPromisesByAppID.get(appID);
        let fetchedTableNames: Set<string> | undefined;
        let appDataPromise: Promise<DataSnapshot | undefined> | undefined;

        // This will be set to true if we're trying to re-load a snapshot for a table we've already successfully fetched.
        //  Only used for tracing.
        let fetchingAgain = false;

        const cacheForSnapshot = cachesForApp[key];
        if (cacheForSnapshot !== undefined && isCachedSnapshot(cacheForSnapshot)) {
            fetchingAgain = cacheForSnapshot.fetchedTableNames.has(tableName);
            if (!fetchingAgain) {
                fetchedTableNames = cacheForSnapshot.fetchedTableNames;
                appDataPromise = cacheForSnapshot.appDataPromise;
            }
        }

        if (fetchedTableNames === undefined) {
            fetchedTableNames = new Set();
        }
        if (appDataPromise === undefined) {
            appDataPromise = loader(appID, fetchingAgain).catch(e => {
                cachesForApp[key] = undefined;
                throw e;
            });
        }

        // Set these before we await in case this is called again before we resume
        fetchedTableNames.add(tableName);
        cachesForApp[key] = { appDataPromise, fetchedTableNames };

        const snapshot = await appDataPromise;
        if (snapshot !== undefined) {
            const rows = snapshot.data[tableName];
            if (rows !== undefined) {
                delete snapshot.data[tableName];
                return {
                    rows,
                    formatVersion: 1,
                    version: snapshot.version,
                };
            }
        }

        return;
    }

    // copy-paste of the below two
    // arrow function because it's passed as an argument
    private backgroundLoadPublicDataSnapshot = async (
        appID: string,
        fetchingAgain?: boolean
    ): Promise<DataSnapshot | undefined> => {
        const { dataSnapshot, fallbackPublicDataSnapshot: fallbackDataSnapshot } = await this.getSnapshotLocations(
            appID,
            true
        );
        return await fetchUnzipWithFallback(dataSnapshot, fallbackDataSnapshot, "data", {
            app_id: appID,
            fetchingAgain,
        });
    };

    public loadPublicDataSnapshot(
        appID: string,
        tableName: string | undefined
    ): Promise<TableSnapshot | DataSnapshot | undefined> {
        if (tableName !== undefined) {
            // If `tableName` is given, use the new codepath that saves memory by removing the requested table from the cache.
            return this.loadDataSnapshot(appID, "publicAppData", this.backgroundLoadPublicDataSnapshot, tableName);
        }

        const removeOnFailure = async (promise: Promise<DataSnapshot | undefined>) => {
            try {
                return await promise;
            } catch (e: unknown) {
                this.snapshotPromisesByAppID.get(appID).publicAppData = undefined;
                throw e;
            }
        };

        let { publicAppData: appDataPromise } = this.snapshotPromisesByAppID.get(appID);
        if (appDataPromise instanceof Promise) {
            return removeOnFailure(appDataPromise);
        }
        appDataPromise = this.backgroundLoadPublicDataSnapshot(appID);
        this.snapshotPromisesByAppID.get(appID).publicAppData = appDataPromise;
        return removeOnFailure(appDataPromise);
    }

    // copy-paste of the above two methods
    // arrow function because it's passed as an argument
    private backgroundLoadPrivateDataSnapshot = async (
        appID: string,
        fetchingAgain?: boolean
    ): Promise<DataSnapshot | undefined> => {
        const { privateDataSnapshot, fallbackPrivateDataSnapshot } = await this.getSnapshotLocations(appID, true);
        return await fetchUnzipWithFallback(privateDataSnapshot, fallbackPrivateDataSnapshot, "privateData", {
            app_id: appID,
            fetchingAgain,
        });
    };

    public loadPrivateDataSnapshot(
        appID: string,
        tableName: string | undefined
    ): Promise<TableSnapshot | DataSnapshot | undefined> {
        if (tableName !== undefined) {
            // If `tableName` is given, use the new codepath that saves memory by removing the requested table from the cache.
            return this.loadDataSnapshot(appID, "privateAppData", this.backgroundLoadPrivateDataSnapshot, tableName);
        }

        const removeOnFailure = async (promise: Promise<DataSnapshot | undefined>) => {
            try {
                return await promise;
            } catch (e: unknown) {
                this.snapshotPromisesByAppID.get(appID).privateAppData = undefined;
                throw e;
            }
        };

        let { privateAppData: appDataPromise } = this.snapshotPromisesByAppID.get(appID);
        if (appDataPromise instanceof Promise) {
            return removeOnFailure(appDataPromise);
        }
        appDataPromise = this.backgroundLoadPrivateDataSnapshot(appID);
        this.snapshotPromisesByAppID.get(appID).privateAppData = appDataPromise;
        return removeOnFailure(appDataPromise);
    }

    // copy-paste of the above two methods
    // arrow function because it's passed as an argument
    private backgroundLoadUnusedDataSnapshot = async (
        appID: string,
        fetchingAgain?: boolean
    ): Promise<DataSnapshot | undefined> => {
        const { unusedDataSnapshot, fallbackUnusedDataSnapshot } = await this.getSnapshotLocations(appID, true);
        return await fetchUnzipWithFallback(unusedDataSnapshot, fallbackUnusedDataSnapshot, "unusedData", {
            app_id: appID,
            fetchingAgain,
        });
    };

    public loadUnusedDataSnapshot(
        appID: string,
        tableName: string | undefined
    ): Promise<TableSnapshot | DataSnapshot | undefined> {
        if (tableName !== undefined) {
            // If `tableName` is given, use the new codepath that saves memory by removing the requested table from the cache.
            return this.loadDataSnapshot(appID, "unusedAppData", this.backgroundLoadUnusedDataSnapshot, tableName);
        }

        const removeOnFailure = async (promise: Promise<DataSnapshot | undefined>) => {
            try {
                return await promise;
            } catch (e: unknown) {
                this.snapshotPromisesByAppID.get(appID).unusedAppData = undefined;
                throw e;
            }
        };

        let { unusedAppData: appDataPromise } = this.snapshotPromisesByAppID.get(appID);
        if (appDataPromise instanceof Promise) {
            return removeOnFailure(appDataPromise);
        }
        appDataPromise = this.backgroundLoadUnusedDataSnapshot(appID);
        this.snapshotPromisesByAppID.get(appID).unusedAppData = appDataPromise;
        return removeOnFailure(appDataPromise);
    }

    public purgeDataSnapshot(appID: string): void {
        this.snapshotLocationsByAppID.delete(appID);
        this.snapshotPromisesByAppID.delete(appID);
    }

    public loadPublishedAppFromLocalStorage(appID: string): PublishedAppSnapshot | undefined {
        const locally = localStorageGetItem(publishedAppKeyForID(appID));
        return definedMap(locally, unzipPublishedAppString);
    }

    private async backgroundLoadPublishedAppSnapshot(appID: string): Promise<PublishedAppSnapshot | undefined> {
        const publishedAppFromLocalStorage = this.loadPublishedAppFromLocalStorage(appID);
        const olderThan = publishedAppFromLocalStorage?.publishedAt;

        const { publishedAppSnapshot, fallbackPublishedAppSnapshot } = await this.getSnapshotLocations(appID, false);

        const firstSnapshot = publishedAppSnapshot ?? fallbackPublishedAppSnapshot;
        if (firstSnapshot === undefined) {
            return publishedAppFromLocalStorage;
        }

        const publishedAppSnapshotURL = new URL(firstSnapshot);
        const hashParameters = new URLSearchParams(publishedAppSnapshotURL.hash.substring(1));
        const remotePublishedAt = hashParameters.get("PublishedAt");
        if (
            remotePublishedAt !== null &&
            olderThan !== undefined &&
            Number.parseInt(remotePublishedAt, 10) <= olderThan.getTime()
        ) {
            return publishedAppFromLocalStorage;
        }

        const response = await fetchUnzipWithFallback<JSONObject>(
            publishedAppSnapshot,
            fallbackPublishedAppSnapshot,
            "published app"
        );

        if (response === undefined) return response;
        const publishedApp = publishedAppSnapshotForJSON(response);
        this.persistPublishedApp(appID, publishedApp);
        return publishedApp;
    }

    private async backgroundLoadNativeTableSnapshot(
        appID: string,
        tableName: TableName
    ): Promise<NativeTableSnapshot | undefined> {
        assert(!tableName.isSpecial);

        const { nativeTableSnapshots, fallbackNativeTableSnapshots } = await this.getSnapshotLocations(appID, true);
        const snapshotURL = nativeTableSnapshots?.[tableName.name];
        const fallbackSnapshotURL = fallbackNativeTableSnapshots?.[tableName.name];

        return fetchUnzipWithFallback(snapshotURL, fallbackSnapshotURL, "native table");
    }

    public async eagerLoadAppSnapshot(appID: string): Promise<void> {
        await this.getSnapshotLocations(appID, true);
    }

    public loadPublishedAppSnapshot(appID: string): Promise<PublishedAppSnapshot | undefined> {
        const awaitAndRemove = async (promise: Promise<PublishedAppSnapshot | undefined>) => {
            try {
                return await promise;
            } finally {
                this.snapshotPromisesByAppID.get(appID).publishedApp = undefined;
            }
        };

        let { publishedApp: publishedAppPromise } = this.snapshotPromisesByAppID.get(appID);
        if (publishedAppPromise !== undefined) {
            return awaitAndRemove(publishedAppPromise);
        }

        publishedAppPromise = this.backgroundLoadPublishedAppSnapshot(appID);
        this.snapshotPromisesByAppID.get(appID).publishedApp = publishedAppPromise;
        return awaitAndRemove(publishedAppPromise);
    }

    public async loadNativeTableSnapshot(
        appID: string,
        tableName: TableName
    ): Promise<NativeTableSnapshot | undefined> {
        if (tableName.isSpecial) return undefined;
        return await this.backgroundLoadNativeTableSnapshot(appID, tableName);
    }

    private persistPublishedApp(appID: string, publishedApp: PublishedAppSnapshot): void {
        const key = publishedAppKeyForID(appID);
        // Ensure we don't get stuck on extremely old apps if anything goes wrong.
        // At the worst, we fail somewhere in the middle and have to revert to
        // slower loading.
        localStorageRemoveItem(key);
        localStorageSetItem(key, zipPublishedAppString(publishedApp));
    }

    public setInitialSchemaForAppID(appID: string, schema: TypeSchema, numRowsUsedInApp?: number): void {
        this.initialSchemasByAppID.set(appID, { ...schema });
        if (numRowsUsedInApp !== undefined) {
            this.numRowsUsedByAppID.set(appID, numRowsUsedInApp);
        }
    }

    public getInitialSchemaForAppID(appID: string): TypeSchema | undefined {
        return this.initialSchemasByAppID.get(appID);
    }

    public getNumRowsUsedByAppID(appID: string): number | undefined {
        return this.numRowsUsedByAppID.get(appID);
    }

    public trackEvent<Name extends keyof AppAnalyticsEvents>(event: Name, options: AppAnalyticsEvents[Name]): void {
        trackEvent(event, options as AnalyticsEvents[Name]);
    }
}

type FunctionMockResolver = (body: any) => Promise<any>;

export type MockedCloudFunctions = Record<string, FunctionMockResolver>;

// TODO: There are a zillion ways to call a cloud function.
// I'm only mocking callAuthCloudFunction. We should do better eventually.
// FIXME: This is test code and doesn't belong in here
export class MockedMaterialAppFacilities extends MaterialAppFacilities {
    constructor(
        integrationsAggregatorFactory: IntegrationsAggregatorFactory,
        private readonly mockedFunctions: MockedCloudFunctions
    ) {
        super(integrationsAggregatorFactory);
    }

    public async callAuthCloudFunction(
        functionName: string,
        body: any,
        headers: { [key: string]: string } = {},
        stringify: boolean = true
    ): Promise<Response | undefined> {
        return await this.callCloudFunction(functionName, body, headers, stringify);
    }

    public async callCloudFunction(
        functionName: string,
        body: any,
        _headers: { [key: string]: string } = {},
        _stringify: boolean = true
    ): Promise<Response | undefined> {
        if (!Object.keys(this.mockedFunctions).includes(functionName)) {
            throw new Error(`Function ${functionName} is not mocked. I won't call an actual endpoint`);
        }

        const resolver = this.mockedFunctions[functionName];
        const response = await resolver(body);

        return new Response(JSON.stringify(response, null, 4), {
            headers: {
                "Content-type": "application/json",
            },
        });
    }
}
