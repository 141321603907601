import {
    type ArrayTransform,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    ArrayTransformKind,
    getTableProperty,
    isTableViewProperty,
    getEnumProperty,
    getJSONPathProperty,
    getNumberProperty,
    getSecretProperty,
    getSourceColumnProperty,
    getSpecialValueProperty,
    getStringProperty,
    getSwitchProperty,
    getInlineComputationProperty,
    type InlineComputation,
} from "@glide/app-description";
import {
    type ColumnType,
    type Formula,
    type SourceColumn,
    type TableColumn,
    type TableGlideType,
    isSingleRelationType,
    isMultiRelationType,
    SpecialValueKind,
    specialValueTypeKinds,
    getSourceColumnPath,
    getTableColumn,
    getTableName,
    makeArrayType,
    makePrimitiveType,
    makeTableRef,
    SourceColumnKind,
    type TableName,
    isTableName,
    nativeTableRowIDColumnName,
    isBigTableOrExternal,
    type SpecialValueDescription,
} from "@glide/type-schema";
import { type WriteSourceType, makeInputOutputTables } from "@glide/common-core";
import {
    Query,
    isQuery,
    type GroundValue,
    type LoadedGroundValue,
    type LoadingValue,
    type Row,
    Table,
    isLoadingValue,
    type Path,
    type RootPath,
    isRootPath,
    type ActionComputationModel,
    type RelativePath,
    QueryFromRows,
    UnboundVal,
    isBound,
} from "@glide/computation-model-types";
import { type ActionAppFacilities, ActionSource } from "@glide/common-core/dist/js/components/types";
import {
    asTable,
    isRow,
    isTable,
    parseValueAsGlideDateTimeSync,
    asRow,
    loadedDefinedMap,
    asString,
} from "@glide/common-core/dist/js/computation-model/data";
import { type ConditionValuePreparer, prepareCondition, computeCondition, follow } from "@glide/computation-model";
import {
    type ActionNodeInScope,
    type ExistingAppDescriptionContext,
    getTableForRelationType,
    resolveSourceColumn,
} from "@glide/function-utils";
import {
    decomposePredicateCombinationFormula,
    makeInlineTemplateSpecFromInlineComputation,
} from "@glide/formula-specifications";
import { logError, nullToUndefined } from "@glide/support";
import type {
    ContextTableTypes,
    InflatedColumn,
    InflatedProperty,
    ValueSetterResult,
    WireActionBackend,
    WireActionInflationBackend,
    WirePredicate,
    WireRowGetter,
    WireRowHydrationValueProvider,
    WireValueGetter,
    WireValueGetterGeneric,
} from "@glide/wire";
import { assertNever, assert, panic, hasOwnProperty, defined, isArray } from "@glideapps/ts-necessities";
import { v4 as uuid } from "uuid";
import md5 from "blueimp-md5";
import { makeTypeForComputation } from "@glide/generator/dist/js/computed-columns";
import type { TypeForActionNodeOutputGetter } from "@glide/generator/dist/js/static-context";

const unboundValueGetter: WireValueGetterGeneric<any> = () => UnboundVal;

function makeUnboundColumnGetter<T>(errorMessage: string | undefined): InflatedProperty<T> {
    return [unboundValueGetter, undefined, false, errorMessage];
}

export const unboundColumnGetter = makeUnboundColumnGetter<any>(undefined);

export function makeValueGetterForSpecialValue(
    specialValue: SpecialValueDescription,
    computationModel: ActionComputationModel
): InflatedProperty<GroundValue> {
    let getter: WireValueGetter;
    let type: ColumnType;
    if (typeof specialValue === "string") {
        switch (specialValue) {
            case SpecialValueKind.Timestamp:
                getter = hb => hb.getGlobalValue(undefined, computationModel.getTimestampPath(), true);
                break;
            case SpecialValueKind.VerifiedEmailAddress:
                getter = hb => hb.getGlobalValue(undefined, computationModel.getVerifiedEmailAddressPath(), true);
                break;
            case SpecialValueKind.RealEmailAddress:
                getter = hb => hb.getGlobalValue(undefined, computationModel.getRealEmailAddressPath(), true);
                break;
            case SpecialValueKind.UserName:
                getter = hb => hb.getGlobalValue(undefined, computationModel.getUserNamePath(), true);
                break;
            case SpecialValueKind.UniqueIdentifier:
                getter = () => uuid();
                break;
            case SpecialValueKind.ActionSource:
                getter = () => ActionSource.None;
                break;
            case SpecialValueKind.CurrentURL:
                getter = hb => hb.getGlobalValue(undefined, computationModel.getAppURLPath(), true);
                break;
            case SpecialValueKind.ClearColumn:
                // FIXME: this should return an empty array
                // if the destination column type is a primitive array
                getter = () => "";
                break;
            default:
                return assertNever(specialValue);
        }
        type = makePrimitiveType(specialValueTypeKinds[specialValue]);
    } else {
        const path = computationModel.getPathForPluginSpecialValue(specialValue);
        if (hasOwnProperty(path, "message")) return unboundColumnGetter;
        getter = hb => hb.getGlobalValue(undefined, path.path, true);
        type = path.type;
    }
    return [getter, type, false];
}

function getColumnNameForTableOrViewReference(x: unknown): SourceColumn | undefined {
    if (isTableViewProperty(x)) {
        const { tableOrColumn } = x.value;
        if (tableOrColumn.kind !== "column") return undefined;
        return tableOrColumn.value;
    }
    return isTableName(x) ? undefined : getSourceColumnProperty(x);
}

function getTableNameForTableOrView(x: unknown): TableName | undefined {
    if (isTableViewProperty(x)) {
        const { tableOrColumn } = x.value;
        if (tableOrColumn.kind !== "table") return undefined;
        return tableOrColumn.value;
    }
    return isTableName(x) ? x : getTableProperty(x);
}

function getSelectedColumnsForViewReference(x: unknown): ReadonlySet<string> | undefined {
    if (!isTableViewProperty(x)) return undefined;
    const { selectedColumns } = x.value;
    if (selectedColumns === undefined) return undefined;
    return new Set(selectedColumns);
}

function getNameOverridesForViewReference(x: unknown): Record<string, string> | undefined {
    if (!isTableViewProperty(x)) return undefined;
    const { nameOverrides } = x.value;
    return nameOverrides;
}

export class ActionInflationBackend implements WireActionInflationBackend {
    constructor(
        public readonly appFacilities: ActionAppFacilities,
        public readonly adc: ExistingAppDescriptionContext,
        public readonly tables: ContextTableTypes,
        protected readonly acm: ActionComputationModel,
        public readonly mutatingScreenKind: MutatingScreenKind | undefined,
        public readonly forBuilder: boolean,
        public readonly forAutomation: boolean,
        private readonly getTypeForActionNodeOutput: TypeForActionNodeOutputGetter | undefined,
        public readonly writeSource: WriteSourceType
    ) {}

    // We don't need actionNodesInScope, this class is used in apps.
    // AutomationInflationBackend handles workflows
    public getActionNodesInScope(): ActionNodeInScope[] {
        return [];
    }

    // The getter will never return `null` (for unbound)
    public getUserProfileRowGetter(): [WireRowGetter, TableGlideType] | undefined {
        const info = this.adc.userProfileTableInfo;
        if (info === undefined) return undefined;

        const tableName = info.tableName;
        const table = this.adc.findTable(tableName);
        if (table === undefined) return undefined;

        const userProfilePath = this.acm.getUserProfileRowPath();
        if (userProfilePath === undefined) return undefined;

        return [hb => loadedDefinedMap(hb.getGlobalValue(tableName, userProfilePath, true), asRow), table];
    }

    protected getPathForColumn(
        table: TableGlideType,
        columnName: string,
        withFormat: boolean
    ): [column: TableColumn, path: Path, rootPath: RootPath | undefined, hasFormat: boolean] | undefined {
        const column = getTableColumn(table, columnName);

        const tableName = getTableName(table);
        const paths = this.acm.getColumnPaths(tableName)?.get(columnName);

        if (column === undefined || paths === undefined) return undefined;

        const [[valuePath, valueRootPath], [formatPath, formatRootPath]] = paths;
        const path = withFormat ? formatPath ?? valuePath : valuePath;
        const rootPath = withFormat ? formatRootPath ?? valueRootPath : valueRootPath;

        return [column, path, rootPath, formatPath !== undefined];
    }

    public getValueGetterForColumnInRow(
        columnName: string,
        inOutputRow: boolean,
        withFormat: boolean
    ): InflatedColumn | undefined {
        const table = inOutputRow ? this.tables.output : this.tables.input;
        const tableName = getTableName(table);

        const maybePath = this.getPathForColumn(table, columnName, withFormat);
        if (maybePath === undefined) return undefined;
        const [column, path, rootPath, hasFormat] = maybePath;

        if (isRootPath(path)) {
            return {
                subscribe: () => undefined,
                getter: (_row, ttvp) => ttvp.getGlobalValue(tableName, path, true),
                type: column.type,
                isGlobal: true,
                hasFormat,
            };
        } else {
            return {
                subscribe: ttvp => {
                    if (rootPath !== undefined) {
                        ttvp.requireColumnInTable(rootPath, path);
                    }
                },
                getter: row => follow(row, path),
                type: column.type,
                isGlobal: false,
                hasFormat,
            };
        }
    }

    protected getValueGetterForActionNodeOutput(
        _nodeID: string,
        _outputName: string,
        _withFormat: boolean
    ): InflatedProperty<GroundValue> {
        // This needs to be implemented by a subclass if it needs to support
        // action node outputs.
        return unboundColumnGetter;
    }

    private getValueGetterForColumnFromRowGetter(
        table: TableGlideType,
        rowGetter: WireRowGetter,
        columnName: string,
        withFormat: boolean
    ): InflatedProperty<GroundValue> {
        const maybePath = this.getPathForColumn(table, columnName, withFormat);
        if (maybePath === undefined) return unboundColumnGetter;
        const [column, path, rootPath, hasFormat] = maybePath;

        const tableName = getTableName(table);
        let getter: WireValueGetter;
        if (isRootPath(path)) {
            getter = hb => hb.getGlobalValue(tableName, path, true);
        } else {
            getter = hb => {
                if (rootPath === undefined) return undefined;

                const row = rowGetter(hb);
                if (row === undefined || isLoadingValue(row)) return row;

                return hb.getColumnInRow(tableName, rootPath, row, path);
            };
        }
        return [getter, column.type, hasFormat];
    }

    private getResolvedRowGetter(
        inflatedProperty: InflatedProperty<GroundValue>
    ): [WireRowGetter, TableGlideType] | undefined {
        const [getter, type] = inflatedProperty;
        if (type === undefined || !isSingleRelationType(type)) return undefined;
        const maybeTable = this.adc.findTable(type);
        if (maybeTable === undefined) return undefined;

        return [
            hb => {
                const v = getter(hb);
                if (v === undefined || isLoadingValue(v)) return v;
                if (isQuery(v)) {
                    const resolved = hb.resolveQueryAsTable(v);
                    if (resolved === undefined || isLoadingValue(resolved)) return resolved;
                    assert(resolved.size <= 1);
                    return resolved.asMutatingArray()[0];
                }
                if (!isBound(v) || !isRow(v)) return undefined;
                return v;
            },
            maybeTable,
        ];
    }

    private getResolvedValueGetterForActionNodeOutput(
        sourceColumn: SourceColumn,
        withFormat: boolean
    ): InflatedProperty<GroundValue> {
        assert(sourceColumn.kind === SourceColumnKind.ActionNodeOutput);
        const sourceColumnPath = getSourceColumnPath(sourceColumn);
        assert(sourceColumnPath.length >= 2);

        const [nodeID, outputName, ...rest] = sourceColumnPath;
        const inflated = this.getValueGetterForActionNodeOutput(nodeID, outputName, withFormat);
        if (rest.length === 0) {
            return inflated;
        } else if (rest.length === 1) {
            const maybeResolvedRowGetter = this.getResolvedRowGetter(inflated);
            if (maybeResolvedRowGetter === undefined) {
                return unboundColumnGetter;
            }
            const [rowGetter, table] = maybeResolvedRowGetter;

            return this.getValueGetterForColumnFromRowGetter(table, rowGetter, rest[0], withFormat);
        } else {
            return panic("We don't support inline lookups yet");
        }
    }

    public getValueGetterForSourceColumn(
        sourceColumn: SourceColumn,
        // This only applies if the `sourceColumn` is
        // `SourceColumnKind.DefaultContext`.
        inOutputRow: boolean,
        withFormat: boolean
    ): InflatedProperty<GroundValue> {
        const { tables } = this;
        const sourceColumnPath = getSourceColumnPath(sourceColumn);

        let table: TableGlideType;
        let rowGetter: WireRowGetter;

        if (sourceColumn.kind === SourceColumnKind.DefaultContext) {
            table = inOutputRow ? tables.output : tables.input;

            rowGetter = hb => {
                const sc = hb.rowContext;
                return inOutputRow ? sc?.outputRow ?? sc?.inputRows[0] : sc?.inputRows[0];
            };
        } else if (sourceColumn.kind === SourceColumnKind.UserProfile) {
            const userProfileResult = this.getUserProfileRowGetter();
            if (userProfileResult === undefined) return unboundColumnGetter;

            rowGetter = userProfileResult[0];
            table = userProfileResult[1];
        } else if (sourceColumn.kind === SourceColumnKind.ContainingScreen) {
            if (tables.containingScreen === undefined) return unboundColumnGetter;
            table = tables.containingScreen;

            rowGetter = hb => hb.rowContext?.containingScreenRow;
        } else if (sourceColumn.kind === SourceColumnKind.ActionNodeOutput) {
            return this.getResolvedValueGetterForActionNodeOutput(sourceColumn, withFormat);
        } else {
            return assertNever(sourceColumn.kind);
        }

        if (sourceColumnPath.length === 0) {
            return [rowGetter, makeTableRef(table), false];
        }

        assert(sourceColumnPath.length === 1);
        const [columnName] = sourceColumnPath;

        return this.getValueGetterForColumnFromRowGetter(table, rowGetter, columnName, withFormat);
    }

    public getValueSetterForProperty(desc: LegacyPropertyDescription | undefined, key: string): ValueSetterResult {
        const errorResult: ValueSetterResult = {
            tableAndColumn: undefined,
            tokenMaker: () => false,
            setterMaker: () => undefined,
            isInContext: false,
        };

        const sourceColumn = getSourceColumnProperty(desc);
        if (sourceColumn === undefined) return errorResult;

        const actionNodesInScope = this.getActionNodesInScope();
        const resolved = resolveSourceColumn(this.adc, sourceColumn, this.tables.output, undefined, actionNodesInScope);
        if (resolved?.tableAndColumn === undefined) return errorResult;
        const { tableAndColumn } = resolved;

        assert(resolved.path.length === 1);

        function setColumn(outputRow: Row, ab: WireActionBackend, value: LoadedGroundValue) {
            return ab.setColumnsInRow(
                getTableName(tableAndColumn.table),
                outputRow,
                {
                    [tableAndColumn.column.name]: value,
                },
                false,
                undefined,
                undefined
            );
        }

        if (sourceColumn.kind === SourceColumnKind.DefaultContext) {
            const isMutatingScreen = this.mutatingScreenKind !== undefined;

            return {
                tokenMaker: (hb, followUp, primaryKeyColumnName, keyPrefix) =>
                    hb.registerOnValueChange(
                        (keyPrefix ?? "") + key,
                        tableAndColumn.column.name,
                        primaryKeyColumnName,
                        followUp
                    ),
                tableAndColumn,
                setterMaker: hb => {
                    const outputRow = isMutatingScreen ? hb.rowContext?.outputRow : hb.rowContext?.inputRows[0];
                    if (outputRow === undefined) return undefined;
                    return (ab, value) => setColumn(outputRow, ab, value);
                },
                // We don't support anything other than `1` here, i.e. a
                // column in the current row, but if we ever do, then those
                // will not be in the context.
                isInContext: resolved.path.length === 1,
            };
        } else if (sourceColumn.kind === SourceColumnKind.UserProfile) {
            const userProfileResult = this.getUserProfileRowGetter();
            if (userProfileResult === undefined) return errorResult;
            const [getter, table] = userProfileResult;
            assert(table === tableAndColumn.table);

            return {
                tokenMaker: (hb, followUp, primaryKeyColumnName, keyPrefix) => {
                    const userProfileRow = getter(hb);
                    if (userProfileRow === undefined || isLoadingValue(userProfileRow)) return undefined;
                    const userProfileHB = hb.makeHydrationBackendForRow(
                        userProfileRow,
                        undefined,
                        makeInputOutputTables(table)
                    );
                    return userProfileHB.registerOnValueChange(
                        (keyPrefix ?? "") + key,
                        tableAndColumn.column.name,
                        primaryKeyColumnName,
                        followUp
                    );
                },
                tableAndColumn,
                setterMaker: hb => {
                    const userProfileRow = getter(hb);
                    if (userProfileRow === undefined || isLoadingValue(userProfileRow)) return userProfileRow;
                    return (ab, value) => setColumn(userProfileRow, ab, value);
                },
                isInContext: false,
            };
        } else if (sourceColumn.kind === SourceColumnKind.ContainingScreen) {
            // Not implemented (yet)
            return errorResult;
        } else if (sourceColumn.kind === SourceColumnKind.ActionNodeOutput) {
            const sourceColumnPath = getSourceColumnPath(sourceColumn);
            // We're writing to a column in a row, so we have:
            // 1. an action node ID
            // 2. the output name
            // 3. and the column in that output
            assert(sourceColumnPath.length === 3);
            const [nodeID, outputName] = sourceColumnPath;
            const inflatedValueGetterForNodeOutput = this.getValueGetterForActionNodeOutput(nodeID, outputName, false);

            return {
                tokenMaker: (hb, followUp, primaryKeyColumnName, keyPrefix) =>
                    hb.registerOnValueChange(
                        (keyPrefix ?? "") + key,
                        tableAndColumn.column.name,
                        primaryKeyColumnName,
                        followUp
                    ),
                tableAndColumn,
                setterMaker: hb => {
                    const maybeResolvedRowGetter = this.getResolvedRowGetter(inflatedValueGetterForNodeOutput);
                    if (maybeResolvedRowGetter === undefined) {
                        return undefined;
                    }
                    const [rowGetter] = maybeResolvedRowGetter;
                    const outputRow = rowGetter(hb);
                    if (outputRow === undefined || isLoadingValue(outputRow)) return outputRow;
                    return (ab, value) => setColumn(outputRow, ab, value);
                },
                isInContext: false,
            };
        } else {
            return assertNever(sourceColumn.kind);
        }
    }

    private makeTableGetter(
        tableName: TableName
    ): ((hb: WireRowHydrationValueProvider) => Table | LoadingValue | undefined) | undefined {
        const table = this.adc.findTable(tableName);
        if (table === undefined) return undefined;

        if (isBigTableOrExternal(table)) {
            return hb => hb.resolveQueryAsTable(new Query(tableName));
        }

        const rootPath = this.acm.getBasePathForTable(tableName);
        if (rootPath === undefined) return undefined;

        return hb => loadedDefinedMap(hb.getGlobalValue(tableName, rootPath, nativeTableRowIDColumnName), asTable);
    }

    private getValueGetterForInlineComputation(
        computation: InlineComputation,
        inOutputRow: boolean
    ): InflatedProperty<string | LoadingValue> {
        const templateSpec = makeInlineTemplateSpecFromInlineComputation(computation);

        const valueGetters: WireValueGetterGeneric<GroundValue>[] = [];
        for (const node of templateSpec.nodes) {
            if (node.kind === "text") {
                valueGetters.push(() => {
                    return node.value;
                });
            }
            if (node.kind === "column") {
                const [columnGetter] = this.getValueGetterForSourceColumn(node.value, inOutputRow, true);
                valueGetters.push(columnGetter);
            }
            if (node.kind === "specialValue") {
                const [specialValueGetter] = makeValueGetterForSpecialValue(node.value, this.acm);
                valueGetters.push(specialValueGetter);
            }
        }

        const getter: WireValueGetterGeneric<string | LoadingValue> = hb => {
            const parts: string[] = [];

            for (const valueGetter of valueGetters) {
                const val = valueGetter(hb);

                // If _some_ column is loading, the entire template is loading
                if (isLoadingValue(val)) {
                    return val;
                }

                // FIXME: if `val` is unbound, shouldn't this return unbound,
                // too?

                const strValue = asString(val ?? undefined);
                parts.push(strValue);
            }

            return parts.join("");
        };

        return [getter, makePrimitiveType("string"), true];
    }

    // TODO: This should be in the description handlers for the most part.  Maybe.
    public getValueGetterForProperty(
        desc: LegacyPropertyDescription | undefined,
        withFormat: boolean,
        opts?: {
            inOutputRow?: boolean;
            columnFirst?: boolean;
        }
    ): InflatedProperty<GroundValue> {
        const inOutputRow = opts?.inOutputRow ?? false;
        const columnFirst = opts?.columnFirst ?? true;

        const columnValue = getSourceColumnProperty(desc);
        if (columnFirst && columnValue !== undefined) {
            return this.getValueGetterForSourceColumn(columnValue, inOutputRow, withFormat);
        }

        const inlineComputation = getInlineComputationProperty(desc);
        if (inlineComputation !== undefined) {
            return this.getValueGetterForInlineComputation(inlineComputation, inOutputRow);
        }

        const stringValue = getStringProperty(desc);
        if (stringValue !== undefined) {
            if (stringValue === "") return unboundColumnGetter;
            return [() => stringValue, makePrimitiveType("string"), false];
        }

        const secretValue = getSecretProperty(desc);
        if (secretValue !== undefined) {
            if (secretValue === "") return unboundColumnGetter;
            return [() => secretValue, makePrimitiveType("string"), false];
        }

        if (columnValue !== undefined) {
            return this.getValueGetterForSourceColumn(columnValue, inOutputRow, withFormat);
        }

        const switchValue = getSwitchProperty(desc);
        if (switchValue !== undefined) {
            return [() => switchValue, makePrimitiveType("boolean"), false];
        }

        const numberValue = getNumberProperty(desc);
        if (numberValue !== undefined) {
            return [() => numberValue, makePrimitiveType("number"), false];
        }

        const enumValue = getEnumProperty(desc);
        if (enumValue !== undefined) {
            let type: ColumnType;
            if (typeof enumValue === "string") {
                type = makePrimitiveType("string");
            } else if (typeof enumValue === "number") {
                type = makePrimitiveType("number");
            } else if (typeof enumValue === "boolean") {
                type = makePrimitiveType("boolean");
            } else {
                return panic("enum value is not a primitive");
            }
            return [() => enumValue, type, false];
        }

        const jsonPathValue = getJSONPathProperty(desc);
        if (jsonPathValue !== undefined) {
            return [() => jsonPathValue, makeArrayType(makePrimitiveType("string")), false];
        }

        const specialValueProperty = getSpecialValueProperty(desc);
        if (specialValueProperty !== undefined) {
            return makeValueGetterForSpecialValue(specialValueProperty, this.acm);
        }

        return unboundColumnGetter;
    }

    public inflatePredicate(
        predicate: Formula,
        inOutputRow: boolean,
        shortCircuit: boolean
    ): [predicate: WirePredicate, numConditions: number] | undefined {
        const condition = decomposePredicateCombinationFormula(predicate)?.spec;
        if (condition === undefined) return undefined;

        const preparer: ConditionValuePreparer<WireValueGetter> = {
            makePathForSourceColumn: sc => this.getValueGetterForSourceColumn(sc, inOutputRow, false)[0],
            getVerifiedEmailAddressPath: () => hb =>
                hb.getGlobalValue(undefined, this.acm.getVerifiedEmailAddressPath(), true),
            getTimestampPath: () => hb => hb.getGlobalValue(undefined, this.acm.getTimestampPath(), true),
            getStartOrEndOfTodayPath: startOrEnd => hb =>
                hb.getGlobalValue(undefined, this.acm.getStartOrEndOfTodayPath(startOrEnd), true),
            makePathForSpecialValue: svs => {
                const [getter] = makeValueGetterForSpecialValue(svs.specialValue, this.acm);
                return getter;
            },
        };
        const conditions = condition.predicates.map(p => prepareCondition(p, preparer));
        return [
            hb => {
                function resolveQuery(v: GroundValue) {
                    if (isLoadingValue(v)) {
                        return v;
                    } else if (isQuery(v)) {
                        return hb.resolveQueryAsTable(v);
                    } else {
                        return v;
                    }
                }
                let finalResult: boolean | LoadingValue | undefined;
                for (const c of conditions) {
                    const result =
                        computeCondition(
                            c,
                            getter => nullToUndefined(getter(hb)),
                            parseValueAsGlideDateTimeSync,
                            resolveQuery
                        ) ?? false;
                    if (isLoadingValue(result)) {
                        if (finalResult === undefined) {
                            finalResult = result;
                        }
                    } else if (result && condition.combinator === "or") {
                        if (finalResult === undefined) {
                            finalResult = true;
                        }
                    } else if (!result && condition.combinator === "and") {
                        if (finalResult === undefined) {
                            finalResult = false;
                        }
                    }
                    if (finalResult !== undefined && shortCircuit) {
                        return finalResult;
                    }
                }
                if (finalResult !== undefined) {
                    return finalResult;
                } else {
                    return condition.combinator === "or" ? false : true;
                }
            },
            conditions.length,
        ];
    }

    public inflateFilters(
        filters: readonly ArrayTransform[],
        inOutputRow: boolean
    ): [predicate: WirePredicate, numConditions: number] {
        const predicates: WirePredicate[] = [];

        let numConditions = 0;
        for (const filterTransform of filters) {
            if (filterTransform.kind !== ArrayTransformKind.Filter) continue;

            const maybePredicate = this.inflatePredicate(filterTransform.predicate, inOutputRow, true);
            if (maybePredicate === undefined) continue;
            const [predicate, numSubConditions] = maybePredicate;

            predicates.push(predicate);
            numConditions += numSubConditions;
        }

        return [
            hb => {
                for (const p of predicates) {
                    const v = p(hb);
                    if (isLoadingValue(v) || !v) {
                        return false;
                    }
                }
                return true;
            },
            numConditions,
        ];
    }

    protected getTableOrQueryGetter(
        table: LegacyPropertyDescription,
        allowSingleRelations: boolean,
        onlyUseQueries: boolean
    ):
        | [
              getter: (hb: WireRowHydrationValueProvider) => Table | Query | QueryFromRows | LoadingValue | undefined,
              tableType: TableGlideType,
              // relations have an implicit filter
              numFilters: number,
              selectedColumns?: ReadonlySet<string>,
              nameOverrides?: Record<string, string>
          ]
        | undefined {
        let getter: (hb: WireRowHydrationValueProvider) => Table | Query | QueryFromRows | LoadingValue | undefined;
        let tableType: TableGlideType | undefined;
        let numFilters: number;

        const columnName = getColumnNameForTableOrViewReference(table);
        if (columnName !== undefined) {
            const [valueGetter, columnType] = this.getValueGetterForSourceColumn(columnName, false, false);

            if (columnType === undefined || (!allowSingleRelations && !isMultiRelationType(columnType)))
                return undefined;
            tableType = getTableForRelationType(this.adc, columnType);
            if (tableType === undefined) return undefined;

            getter = hb => {
                const v = valueGetter(hb);
                // `null` means unbound, which shouldn't happen because we
                // checked that the table exists above.
                assert(v !== null);
                assert(tableType !== undefined);
                if (isLoadingValue(v) || isQuery(v)) return v;
                if (isTable(v)) {
                    return onlyUseQueries ? new QueryFromRows(tableType, v) : v;
                } else if (isRow(v) && allowSingleRelations) {
                    const t = new Table([v]);
                    return onlyUseQueries ? new QueryFromRows(tableType, t) : t;
                } else if (v === undefined) {
                    // ##emptyTableFromGetter:
                    // Single-relations are `undefined` when empty, but when
                    // we want them as a table, we want an empty table.  It's
                    // possible multi-relations are also like that sometimes,
                    // even though they shouldn't be.
                    const t = new Table();
                    return onlyUseQueries ? new QueryFromRows(tableType, t) : t;
                }

                logError("Should be table or query, but isn't", v);
                return undefined;
            };
            numFilters = 1;
        } else {
            const tableName = getTableNameForTableOrView(table);
            if (tableName === undefined) return undefined;

            tableType = this.adc.findTable(tableName);

            if (tableType !== undefined && (isBigTableOrExternal(tableType) || onlyUseQueries)) {
                getter = () => new Query(tableName);
            } else {
                const maybeGetter = this.makeTableGetter(tableName);
                if (maybeGetter === undefined) return undefined;
                getter = maybeGetter;
            }
            numFilters = 0;
        }

        if (getter === undefined || tableType === undefined) return undefined;

        return [
            getter,
            tableType,
            numFilters,
            getSelectedColumnsForViewReference(table),
            getNameOverridesForViewReference(table),
        ];
    }

    public getTableGetter(
        table: LegacyPropertyDescription | TableName,
        allowSingleRelations: boolean
    ):
        | [
              getter: (hb: WireRowHydrationValueProvider) => Table | LoadingValue | undefined,
              tableType: TableGlideType,
              numFilters: number,
              selectedColumns?: ReadonlySet<string>,
              nameOverrides?: Record<string, string>
          ]
        | undefined {
        const maybeGetter = this.getTableOrQueryGetter(table, allowSingleRelations, false);
        if (maybeGetter === undefined) return undefined;

        const [queryOrTableGetter, tableType, numFilters, selectedColumns, nameOverrides] = maybeGetter;
        const tableGetter = (hb: WireRowHydrationValueProvider) => {
            const queryOrTable = queryOrTableGetter(hb);
            if (isLoadingValue(queryOrTable)) return queryOrTable;
            if (isQuery(queryOrTable)) {
                return hb.resolveQueryAsTable(queryOrTable);
            }
            return queryOrTable;
        };

        return [tableGetter, tableType, numFilters, selectedColumns, nameOverrides];
    }

    public getFormulaGetter(formula: Formula): InflatedProperty<GroundValue> {
        const key = md5(JSON.stringify(formula));
        const columnName = `formula_${key}`;
        const tableName = getTableName(this.tables.input);

        const type = makeTypeForComputation(this.adc, this.tables.input, formula, this.getTypeForActionNodeOutput);
        if (isArray(type)) return makeUnboundColumnGetter(type.join(", "));

        return [
            hb => {
                const row = hb.rowContext?.inputRows[0];
                if (row === undefined) return UnboundVal;

                const forTable = this.acm.getColumnPaths(tableName);
                if (forTable === undefined) return UnboundVal;

                let columnPath: RelativePath | undefined;
                let rootPath: RootPath | undefined;

                const existing = forTable.get(columnName);
                if (existing === undefined) {
                    const column: TableColumn = {
                        name: columnName,
                        type,
                        formula,
                    };
                    const info = this.acm.buildTemporaryComputedColumn(tableName, column, true);
                    if (!hasOwnProperty(info, "info")) {
                        return UnboundVal;
                    } else {
                        if (info.pathForValue === undefined) return UnboundVal;
                        if (info.pathForValue.isGlobal) {
                            rootPath = info.pathForValue.valuePath;
                        } else {
                            rootPath = info.pathForValue.tablePath;
                            columnPath = info.pathForValue.valuePath;
                        }
                    }
                } else {
                    const [[path]] = existing;
                    if (isRootPath(path)) {
                        rootPath = path;
                    } else {
                        columnPath = path;
                        rootPath = existing[0][1];
                    }
                }

                assert(rootPath !== undefined);
                if (columnPath === undefined) {
                    return hb.getGlobalValue(tableName, rootPath, true);
                } else {
                    return hb.getColumnInRow(tableName, defined(rootPath), row, columnPath);
                }
            },
            type,
            false,
        ];
    }

    public makeInflationBackendForTables(
        tables: ContextTableTypes,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): WireActionInflationBackend {
        return new ActionInflationBackend(
            this.appFacilities,
            this.adc,
            tables,
            this.acm,
            mutatingScreenKind,
            this.forBuilder,
            this.forAutomation,
            this.getTypeForActionNodeOutput,
            this.writeSource
        );
    }
}
