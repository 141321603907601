import { render } from "@testing-library/react";
import type { AppFeatures } from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import { TailwindThemeProvider, getRuntimeThemeForPlatform } from "@glide/common";
import { getWireAppThemeForPlatform } from "@glide/theme";

export function getAppFeaturesFromPlay(): AppFeatures | undefined {
    return (window as any)?.appLogin?.features;
}

export function getAppKindFromPlay() {
    return getAppFeaturesFromPlay()?.appKind === "page" ? AppKind.Page : AppKind.App;
}

function getTheme() {
    const isPage = getAppKindFromPlay() === AppKind.Page;

    const platformTheme = isPage
        ? getWireAppThemeForPlatform(
              {
                  primaryAccentColor: "#EC6337",
                  showTabLabels: true,
                  themeOverlay: "none",
                  themeIsAdaptive: false,
                  increaseContrast: false,
                  showDesktopSideBar: false,
              },
              false
          )
        : getRuntimeThemeForPlatform(
              {
                  primaryAccentColor: "pink",
                  showTabLabels: true,
                  themeOverlay: "none",
                  themeIsAdaptive: false,
                  increaseContrast: false,
                  showDesktopSideBar: false,
              },
              "iOS"
          );
    return platformTheme;
}

export function renderWithTheme(ui: React.ReactElement): ReturnType<typeof render> {
    return render(<TailwindThemeProvider theme={getTheme()}>{ui}</TailwindThemeProvider>);
}
