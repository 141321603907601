import { useChangeObservable } from "@glide/common";
import type { WireBackend } from "@glide/hydrated-ui";
import type { WireNavigationModel } from "@glide/wire";
import React from "react";

export function useWireNavigationModel(
    backend: WireBackend | undefined,
    urlStack: string[]
): WireNavigationModel | undefined {
    const navModel = useChangeObservable(backend?.wireNavigationModelObservable);

    const urlPath = navModel?.urlPath ?? "";
    React.useEffect(() => {
        if (urlPath !== urlStack.slice(-1)[0]) {
            urlStack.push(urlPath);
        }
    }, [urlPath, urlStack]);

    return navModel;
}

export const WireNavigationModelContext = React.createContext<WireNavigationModel | undefined>(undefined);

export function useWireNavigationModelContext(): WireNavigationModel | undefined {
    return React.useContext(WireNavigationModelContext);
}
