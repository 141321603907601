import type { GlideFC } from "@glide/common";
import * as React from "react";
import "twin.macro";
import { UIBackgroundStyle, UIButtonAppearance } from "@glide/wire";
import { WireButton } from "../wire-button/wire-button";
import { SectionStyleProvider } from "../wire-container/wire-container";

interface Props extends React.PropsWithChildren {
    title: string;
    description: string;
    onFinish: (accepted: boolean) => void;
    accept: string;
    cancel?: string;
    discard?: string;
    onDiscard?: () => void;
}

export const WireModal: GlideFC<Props> = p => {
    const { onDiscard, onFinish, title, description, accept, cancel, discard } = p;

    const onAccept = React.useCallback(
        (ev: React.MouseEvent<HTMLButtonElement>) => {
            onFinish(true);
            ev.stopPropagation();
        },
        [onFinish]
    );
    const onCancel = React.useCallback(
        (ev: React.MouseEvent<HTMLDivElement>) => {
            onFinish(false);
            ev.stopPropagation();
        },
        [onFinish]
    );
    const onDiscarded = React.useCallback(
        (ev: React.MouseEvent<HTMLButtonElement>) => {
            if (onDiscard && discard) onDiscard();
            onFinish(false);
            ev.stopPropagation();
        },
        [discard, onDiscard, onFinish]
    );

    return (
        <SectionStyleProvider value={UIBackgroundStyle.Highlight}>
            <div
                tw="absolute top-0 left-0 h-full w-full flex justify-center items-center background-color[rgba(17, 24,
                    39, 0.33)] z-20"
                onClick={onCancel}>
                <div
                    tw="relative [width:400px] height[fit-content] bg-bg-front shadow-2xl-xdark border-radius[16px] flex
                        flex-col items-center justify-center padding[32px]"
                    onClick={e => {
                        e.stopPropagation();
                    }}>
                    <div tw="w-full text-text-dark text-lg font-bold text-left mb-3">{title}</div>
                    <div tw="w-full text-text-pale text-base text-left mb-6">{description}</div>
                    <div tw="height[48x] w-full flex justify-between items-end">
                        {cancel !== undefined && (
                            <WireButton
                                appearance={UIButtonAppearance.Bordered}
                                onClick={e => {
                                    onFinish(false);
                                    e.stopPropagation();
                                }}>
                                {cancel}
                            </WireButton>
                        )}

                        {discard !== undefined && onDiscard !== undefined && (
                            <WireButton
                                tw="ml-auto mr-2"
                                appearance={UIButtonAppearance.Bordered}
                                onClick={onDiscarded}>
                                {discard}
                            </WireButton>
                        )}

                        <WireButton onClick={onAccept} appearance={UIButtonAppearance.Filled} tw="shrink-0">
                            {accept}
                        </WireButton>
                    </div>
                </div>
            </div>
        </SectionStyleProvider>
    );
};
