import "twin.macro";

import { AppIcon, GlideIcon } from "@glide/common";
import { trackEvent } from "@glide/common-core/dist/js/analytics";
import { getFeatureFlag } from "@glide/common-core/dist/js/feature-flags";
import { fetchPageMetadata, youTubeThumbnailFromLink } from "@glide/support";
import { defined, hasOwnProperty } from "@glideapps/ts-necessities";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useHover, useLayer } from "react-laag";
import type { Options } from "react-laag/dist/types";
import { css } from "styled-components";
import { useBuilderTheme } from "../../hooks/use-builder-theme";

import { Button } from "../button/button";
import { VideoPlayer } from "../video-player/video-player";
import type { GlideIconProps } from "@glide/plugins";

type TooltipDisplayProps = {
    title: string;
    content: string;
    video?: string;
    docsURL?: string;
};

const cache: Record<string, TooltipDisplayProps> = {};

type DocRef = { docURL: string };

function isDocRef(ref: unknown): ref is DocRef {
    return hasOwnProperty(ref, "docURL") && typeof ref.docURL === "string";
}

type RichTooltipProps = {
    placement?: Options["placement"];
    icon?: GlideIconProps;
} & (TooltipDisplayProps | DocRef);

const Tooltip: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <div tw="rounded-lg p-4 bg-bg-front [width:272px] text-text-base shadow-xl-dark">{children}</div>;
};

const cleanTitle = (title: string): string => {
    const arr = title.split("•");
    if (arr.length > 1) {
        arr.shift();
    }
    return arr.join("•");
};

const RichTooltipContent: React.VFC<RichTooltipProps> = props => {
    const id = isDocRef(props) ? props.docURL : props.title;

    const trackPlayEvent = useCallback(() => trackEvent("tooltip_video_click", { id }), [id]);
    const onDocsClick = useCallback(() => trackEvent("tooltip_doc_click", { id }), [id]);

    const needsOpenGraphInfo = isDocRef(props);
    const [openGraphInfo, setOpenGraphInfo] = useState<TooltipDisplayProps | undefined>(
        needsOpenGraphInfo ? cache[props.docURL] : undefined
    );

    useEffect(() => {
        let cancelled = false;
        if (needsOpenGraphInfo && openGraphInfo === undefined) {
            const docURL = props.docURL;
            void fetchPageMetadata(docURL, getFeatureFlag("useLocalPageMetadataService")).then(data => {
                if (data === undefined || cancelled) return;
                const info: TooltipDisplayProps = {
                    title: cleanTitle(data.title),
                    content: data.description ?? "Not available",
                    video: data.video,
                    docsURL: docURL,
                };
                cache[docURL] = info;
                setOpenGraphInfo(info);
            });
        }
        return () => {
            cancelled = true;
        };
    }, [props, needsOpenGraphInfo, openGraphInfo]);

    if (needsOpenGraphInfo && openGraphInfo === undefined) {
        return (
            <Tooltip>
                <div tw="flex justify-center">
                    <AppIcon icon="halfSpinner" />
                </div>
            </Tooltip>
        );
    }

    const { title, content, video, docsURL } = needsOpenGraphInfo ? defined(openGraphInfo) : props;

    // We only show the video if we can also get a thumbnail.
    // This means that for now, only YouTube videos work.
    const videoThumbnail = video === undefined ? undefined : youTubeThumbnailFromLink(video);

    return (
        <Tooltip>
            <p tw="font-bold text-builder-lg">{title}</p>
            <p tw="pt-2 pb-3 text-builder-sm">{content}</p>
            {video && videoThumbnail && (
                <VideoPlayer
                    videoURL={video}
                    thumbnailURL={videoThumbnail}
                    onPlayVideo={trackPlayEvent}
                    onCloseVideo={videoStatus =>
                        trackEvent("tooltip_video_close", {
                            id,
                            played: videoStatus.played,
                            playedSeconds: videoStatus.playedSeconds,
                        })
                    }
                />
            )}
            {docsURL && (
                <Button
                    tw="mt-3"
                    buttonType="secondary"
                    size="sm"
                    variant="default"
                    href={docsURL}
                    label="Learn more"
                    icon="st-book"
                    iconType="iconTrailing"
                    onClick={onDocsClick}
                />
            )}
        </Tooltip>
    );
};

export const RichTooltip: React.VFC<RichTooltipProps> = props => {
    const { placement = "bottom-center" } = props;
    const id = isDocRef(props) ? props.docURL : props.title;

    const [isHovered, hoverProps, close] = useHover({ delayEnter: 300, delayLeave: 300 });
    const [isClicked, setOpenWithClick] = useState(false);
    const theme = useBuilderTheme();

    const isOpen = isClicked || isHovered;

    const openWithClick = useCallback(
        (e: React.SyntheticEvent) => {
            e.stopPropagation();
            setOpenWithClick(true);
            trackEvent("tooltip_click", { id });
        },
        [id]
    );

    const closeWithClick = useCallback(() => {
        setOpenWithClick(false);
        // sets isHovered=false to allow a click to close
        // otherwise if a user clicks, this menu stays open forever
        close();
    }, [close]);

    useEffect(() => {
        if (!isClicked && isHovered) {
            trackEvent("tooltip_hover", { id });
        }
    }, [id, isClicked, isHovered]);

    const { layerProps, renderLayer, triggerProps } = useLayer({
        isOpen,
        container: "portal",
        auto: true,
        overflowContainer: true,
        containerOffset: 14,
        triggerOffset: 4,
        onOutsideClick: closeWithClick,
        placement,
    });

    const propsWithDefaultIcon: GlideIconProps = props.icon ?? { kind: "monotone", icon: "mt-info-circle-filled" };
    return (
        <span {...hoverProps} tw="flex items-center pl-1">
            <button
                {...triggerProps}
                css={css`
                    /*  transform: translateY(-1px); // potential fix to optical illusion */
                    &.active {
                        color: ${theme.n800A};
                    }
                `}
                onClick={!isOpen ? openWithClick : closeWithClick}
                className={classNames({ active: isOpen })}
                tw="transition-colors hover:text-n800A text-n500A">
                <GlideIcon iconSize={16} {...propsWithDefaultIcon} />
            </button>

            {isOpen &&
                renderLayer(
                    <div
                        className="rich-tooltip-container click-outside-ignore"
                        onClick={e => e.stopPropagation()}
                        {...layerProps}>
                        <RichTooltipContent {...props} />
                    </div>
                )}
        </span>
    );
};
