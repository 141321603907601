import type { OverlayTheme } from "../overlay-theme";

export const largeListItemOverlay: OverlayTheme = {
    overlayName: "LargeListItemOverlay",
    listItem: {
        defaultRowHeight: 86,
        underlineLeft: "_pageMarginMedium",
        underlineRight: "_pageMarginMedium" as any,

        mainImage: {
            width: 62,
            height: 62,
            borderRadius: "4px",
        },
    },
};

export const bigCaptionListItemOverlay: OverlayTheme = {
    overlayName: "BigCaptionListItem",
    listItem: {
        captionFontStyle: "_fontStyles.Body4",
        captionAlign: "right",
    },
};
