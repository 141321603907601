import { isLoadingValue } from "@glide/computation-model-types";
import { asString } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ActionDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
    makeColumnProperty,
} from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import { makeTelephoneURL } from "@glide/common-core/dist/js/urls";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    makeInlineTemplatePropertyDescriptor,
    type ActionAvailability,
} from "@glide/function-utils";
import { nullToUndefined } from "@glide/support";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { ICON_BASE, PURPLE_500 } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

interface PhoneCallActionDescription extends ActionDescription {
    readonly kind: ActionKind.PhoneCallWithArgs;
    // This property name is also hardcoded in ##convertLegacyActions
    readonly number?: PropertyDescription;
}

export function makePhoneNumberPropertyDescriptor(
    mutatingScreenKind: MutatingScreenKind | undefined
): PropertyDescriptor {
    return makeInlineTemplatePropertyDescriptor(
        "number",
        "Phone number",
        "Enter phone number",
        true,
        "withLabel",
        mutatingScreenKind,
        {
            preferredNames: ["phone", "mobile", "number"],
            preferredType: "phone-number",
            searchable: false,
            columnFirst: true,
            applyFormat: false,
        }
    );
}

export class PhoneCallWithArgsHandler extends BaseActionHandler<PhoneCallActionDescription> {
    public readonly kind = ActionKind.PhoneCallWithArgs;

    public readonly iconName: GlideIconProps = {
        icon: "st-telephone",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: PURPLE_500,
    };

    public readonly name: string = "Dial phone number";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: PhoneCallActionDescription | undefined,
        { mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Communication,
            groupItemOrder: 4,
            needsScreenContext: true,
            properties: [makePhoneNumberPropertyDescriptor(mutatingScreenKind)],
        };
    }

    public newDefaultActionDescription(c: TableColumn): PhoneCallActionDescription | undefined {
        if (c.type.kind !== "phone-number") return undefined;
        return { kind: ActionKind.PhoneCallWithArgs, number: makeColumnProperty(c.name) };
    }

    public getTokenizedDescription(
        desc: PhoneCallActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const columnToken = tokenForProperty(desc.number, env);
        if (columnToken === undefined) return undefined;

        return [columnToken];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: PhoneCallActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const [numberGetter] = ib.getValueGetterForProperty(desc.number, false);

        return (vp, skipLoading) => {
            const numberValue = nullToUndefined(numberGetter(vp));
            if (isLoadingValue(numberValue)) return arbBase.maybeSkipLoading(skipLoading, "Phone number");
            const number = asString(numberValue);
            let arb = arbBase.addData({ number });

            const url = makeTelephoneURL(number);
            if (url === undefined) return arb.error(true, "Invalid phone number");
            arb = arb.addData({ url });

            return async ab => {
                ab.actionCallbacks.openLink(url);
                return arb.success();
            };
        };
    }
}
