import { type ActionDescription, ActionKind } from "@glide/app-description";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import type { ActionAvailability } from "@glide/function-utils";
import { actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

export class PopScreenHandler extends BaseActionHandler<ActionDescription> {
    public readonly kind = ActionKind.PopScreen;
    public readonly iconName: GlideIconProps = {
        icon: "st-go-back",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Go back";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 9,
            properties: [],
        };
    }

    public inflate(
        _ib: WireActionInflationBackend,
        _desc: ActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        return () => {
            return async ab => {
                const result = ab.navigateUp();
                return arb.fromResult(result);
            };
        };
    }
}
