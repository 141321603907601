// FIXME: This should be open sourced as part of a library at some point.

// This is an extreme simplification of a Condition Variable. Since we're
// expecting to run in the same serial event loop as anything else, we don't
// need a Mutex. We also don't yet need to notify exactly one waiter, so we just
// have notifyAll().
export class ConditionVariable {
    private waiters: (() => void)[] = [];

    public notifyAll(): void {
        const toNotify = this.waiters.splice(0, this.waiters.length);
        for (const callback of toNotify) {
            callback();
        }
    }

    public async wait(): Promise<void> {
        return new Promise(resolve => {
            this.waiters.push(resolve);
        });
    }
}

export class Semaphore {
    private readonly cv = new ConditionVariable();
    private count: number = 0;

    public acquire() {
        this.count++;
    }

    public release() {
        this.count = Math.max(this.count - 1, 0);
        if (this.count === 0) {
            this.cv.notifyAll();
        }
    }

    public currentCount() {
        return this.count;
    }

    public async wait() {
        while (this.count > 0) {
            await this.cv.wait();
        }
    }
}
