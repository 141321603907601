import { type LoadingValue, isLoadingValue } from "@glide/computation-model-types";
import { GlideDateTime, GlideJSON, isBasePrimitiveValue } from "@glide/data-types";
import type { FilterDataValue } from "@glide/type-schema";
import { getFeatureSetting } from "../feature-settings";

// ##convertingRelationKeys:
// This is how the new computation model converts primitive values to strings
// when they are keys for relations.  This is for implementing relations in
// the computation model in memory.
export function convertToRelationKey(x: unknown): LoadingValue | string | undefined {
    if (isLoadingValue(x)) return x;

    if (typeof x === "string") return x;
    if (typeof x === "number") return x.toString();
    if (typeof x === "boolean") return x.toString();
    if (x instanceof GlideDateTime) {
        // FIXME: Use ISO for this?
        return x.asLocalString();
    }
    // FIXME: handle `GlideJSON`
    if (x === undefined) return "";
    return undefined;
}

// This is for doing relations on queryable data sources.
export function convertToArrayOverlapKey(x: unknown): LoadingValue | FilterDataValue | undefined {
    if (isLoadingValue(x)) return x;

    if (getFeatureSetting("gbtCanonicalArrayOverlap")) {
        // Note that we don't convert empty values (`undefined` and the empty
        // string) to keys in queryable data sources because we don't want to
        // repeat the mistake of relations matching empty values to empty values.
        if (x === "") return undefined;
        if (isBasePrimitiveValue(x)) return x;
        if (x instanceof GlideDateTime) return x.toDocumentData();
        if (x instanceof GlideJSON) return x.toDocumentData();
        return undefined;
    } else {
        if (typeof x === "string") return x;
        if (typeof x === "number") return x.toString();
        if (typeof x === "boolean") return x.toString();
        if (x instanceof GlideDateTime) {
            return x.asTimeZoneAwareDate().toISOString();
        }
        return undefined;
    }
}
