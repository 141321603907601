import "twin.macro";

import type { GlideFC } from "@glide/common";
import * as React from "react";

import { Tooltip } from "@glide/common-components";
type Position = "right" | "left" | "top" | "bottom" | "bottom-center" | "bottom-right" | "bottom-left";

interface Props extends React.PropsWithChildren {
    content: string;
    url?: string;
    emphasizedText?: string;
    icon?: React.ReactNode;
    position?: Position;
    width?: number;
    backgroundColor?: number;
}

const BubbleIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.99998 0.333252C3.31998 0.333252 0.333313 3.31992 0.333313 6.99992C0.333313 10.6799 3.31998 13.6666 6.99998 13.6666C10.68 13.6666 13.6666 10.6799 13.6666 6.99992C13.6666 3.31992 10.68 0.333252 6.99998 0.333252ZM7.66665 11.6666H6.33331V10.3333H7.66665V11.6666ZM9.04665 6.49992L8.44665 7.11325C8.11331 7.45325 7.87331 7.75992 7.75331 8.23992C7.69998 8.45325 7.66665 8.69325 7.66665 8.99992H6.33331V8.66659C6.33331 8.35992 6.38665 8.06659 6.47998 7.79325C6.61331 7.40659 6.83331 7.05992 7.11331 6.77992L7.93998 5.93992C8.24665 5.64659 8.39331 5.20659 8.30665 4.73992C8.21998 4.25992 7.84665 3.85325 7.37998 3.71992C6.63998 3.51325 5.95331 3.93325 5.73331 4.56659C5.65331 4.81325 5.44665 4.99992 5.18665 4.99992H4.98665C4.59998 4.99992 4.33331 4.62659 4.43998 4.25325C4.72665 3.27325 5.55998 2.52659 6.59331 2.36659C7.60665 2.20659 8.57331 2.73325 9.17331 3.56659C9.95998 4.65325 9.72665 5.81992 9.04665 6.49992Z"
            fill="currentColor"
        />
    </svg>
);

const HelpBubble: GlideFC<Props> = p => {
    const { position, width, url, content, icon, emphasizedText } = p;
    const wrapperRef = React.useRef<any>(null);

    const helpIcon = icon ?? <BubbleIcon />;

    const helpBody = (
        <>
            {helpIcon}
            <Tooltip show={true} target={wrapperRef} position={position ?? "bottom"} width={width ?? 280}>
                {emphasizedText && <p tw="font-bold text-sm text-n200 mb-1">{emphasizedText}</p>}
                {content}
            </Tooltip>
        </>
    );

    if (url === undefined) {
        return (
            <div tw="flex items-center justify-center w-4 h-4" ref={wrapperRef}>
                {helpBody}
            </div>
        );
    }

    return (
        <a tw="flex items-center justify-center w-4 h-4" ref={wrapperRef} href={url} target="_blank" rel="noreferrer">
            {helpBody}
        </a>
    );
};

export default HelpBubble;
