import type { FeatureSettingName } from "./feature-settings";

interface Experiment {
    // This will become a user feature and a feature setting.  When a team
    // opts into an experiment, it sets their user feature.  To enable the
    // feature for everybody, set the feature setting.  That will also remove
    // it from the experiments panel.
    readonly id: ExperimentName;
    // If this is set, then the experiment can only be enabled or disabled
    // if this feature setting is enabled.
    readonly allowOptInFeatureSetting?: FeatureSettingName;
    readonly name: string;
    readonly description: string;
    readonly learnMoreURL?: string;
    readonly kind: "experiment" | "developerTool";
}

const allExperiments = [
    {
        id: "plugin-push-notifications",
        name: "Push Notifications (Beta)",
        description: "Enables an integration to send push notifications to app users",
        learnMoreURL: "https://www.glideapps.com/docs/push-notifications",
        allowOptInFeatureSetting: "allowPluginPushNotifications",
        kind: "experiment",
    },
    // TODO: Sholud we remove this?
    {
        id: "billingV4Interface",
        name: "Billing v4 interface",
        description: "Enables the new Billing v4 interface in the whole webapp",
        learnMoreURL: "https://www.glideapps.com/pricing",
        allowOptInFeatureSetting: "allowBillingV4Interface",
        kind: "experiment",
    },
    {
        id: "pricingv4-triggerWebhookPlugin",
        name: "Trigger webhook plugin",
        description: "Enables the trigger webhook plugin",
        learnMoreURL: "https://www.glideapps.com/docs/automation/actions/webhook",
        allowOptInFeatureSetting: "pricingv4-allowTriggerWebhookPlugin",
        kind: "experiment",
    },
    {
        id: "newAppModalWorkflow",
        name: "New app modal workflow",
        description: "shows a new app modal workflow",
        allowOptInFeatureSetting: "allowNewAppModalWorkflow",
        kind: "experiment",
    },
    {
        id: "renderableContentComponent",
        name: "Renderable content component",
        description: "Render Glide components from structured data",
        allowOptInFeatureSetting: "allowRenderableContentComponent",
        kind: "experiment",
    },
    {
        id: "pricingv4-openAIv2Plugin",
        name: "OpenAI v2 plugin",
        description: "Enables OpenAI v2 Plugin",
        allowOptInFeatureSetting: "pricingv4-allowOpenAIv2Plugin",
        kind: "experiment",
    },
    {
        id: "gbtComputedColumnsAlpha",
        name: "More computed columns in Glide Big Tables",
        description: "Allow filtering by some computed columns and rollups over Template column",
        kind: "experiment",
    },
    {
        id: "gbtDeepLookups",
        name: "Lookups of Lookups in Glide Big Tables",
        description: "Allow filtering/sorting/rolling up of Lookups of Lookups in Glide Big Tables",
        kind: "experiment",
        allowOptInFeatureSetting: "allowGBTDeepLookups",
    },
    {
        id: "plugin-make",
        name: "Make integration",
        description: "Enables an integration for Make.com",
        kind: "experiment",
    },
    {
        id: "plugin-zapier",
        name: "Zapier integration",
        description: "Enables a new integration for Zapier",
        kind: "experiment",
    },
    {
        id: "manualTriggers",
        name: "Manual workflow triggers",
        description: "Enables workflows to be set up with manual triggers in your app",
        kind: "experiment",
        allowOptInFeatureSetting: "allowManualTriggers",
    },
    {
        id: "pagesSelectMode",
        name: "Select mode",
        description: "Select components directly from the preview",
        kind: "experiment",
    },
    // This was previously a preview of both code component, and AI
    // component.  We're splitting them up now, and this only controls
    // the Code component.
    {
        id: "customAIComponent",
        name: "Code Component",
        description: "Create a custom component with HTML",
        allowOptInFeatureSetting: "allowCustomAIComponent",
        kind: "experiment",
    },
    {
        id: "allowAnalyzePerformanceForUsers",
        name: "Analyze Table Performance",
        description: "Enable the Analyze Table Performance feature",
        kind: "developerTool",
    },
    {
        id: "pluginWebhookTrigger",
        name: "Webhook trigger",
        description: "Trigger workflows via webhooks",
        kind: "experiment",
    },
    {
        id: "pluginEmailTrigger",
        name: "Email trigger",
        description: "Trigger workflows via emails",
        kind: "experiment",
        allowOptInFeatureSetting: "allowPluginEmailTrigger",
    },
    {
        id: "glideAPIPlugin",
        name: "Glide API plugin",
        description: "Enables the Glide API plugin for Glide Big Table data operations",
        kind: "experiment",
        allowOptInFeatureSetting: "allowGlideAPIPlugin",
    },
    {
        id: "pluginBigQuery",
        name: "BigQuery plugin",
        description: "Run BigQuery queries",
        kind: "experiment",
        allowOptInFeatureSetting: "enableBigQueryPlugin",
    },
    {
        id: "pluginSlackTrigger",
        name: "Slack trigger",
        description: "Trigger workflows with Slack messages",
        kind: "experiment",
        allowOptInFeatureSetting: "allowPluginSlackTrigger",
    },
    {
        id: "redirectToCustomDomain",
        name: "Redirect to custom hostname",
        description: "Redirect to the configured custom hostname",
        kind: "experiment",
        allowOptInFeatureSetting: "allowRedirectToCustomDomain",
    },
    {
        id: "builderNotes",
        name: "Builder Notes",
        description: "Add notes to screens and components",
        kind: "experiment",
        allowOptInFeatureSetting: "allowBuilderNotes",
    },
    {
        id: "humanInTheLoop",
        name: "Human in the Loop for Workflows",
        description: "Allows interaction within a workflow",
        kind: "experiment",
        allowOptInFeatureSetting: "allowHumanInTheLoop",
    },
    {
        id: "iframeEmbedding",
        name: "iFrame Embedding",
        description: "Enhances embedding a Glide app within an iframe",
        kind: "experiment",
        allowOptInFeatureSetting: "allowIFrameEmbedding",
    },
    {
        id: "stopwatchComponent",
        name: "Stopwatch component",
        description: "Adds a new stopwatch component",
        kind: "experiment",
        allowOptInFeatureSetting: "allowStopwatchComponent",
    },
    {
        id: "classicAppsToPages",
        name: "Classic Apps to new Apps migration",
        description: "Lets you duplicate a Classic App as a new App",
        kind: "experiment",
        allowOptInFeatureSetting: "allowClassicAppsToPages",
    },
    {
        id: "audioEntryAction",
        name: "Record and transcribe voice in an action",
        description: "Adds an action to record and transcribe voice",
        kind: "experiment",
        allowOptInFeatureSetting: "allowAudioEntryAction",
    },
    {
        id: "querySingleColumn",
        name: "Query for single row",
        description: "Allow the Query column to get a single row, like Single Relation",
        kind: "experiment",
    },
    {
        id: "appSpecialValuesPlugin",
        name: "App special values",
        description: "Show app special values like name or icon",
        kind: "experiment",
    },
    {
        id: "deviceSpecialValuesPlugin",
        name: "Device special values",
        description: "Show device special values like screen width",
        kind: "experiment",
    },
    {
        id: "triggerAutomationAction",
        name: "Trigger workflow step",
        description: "A workflow step that allows triggering another workflow",
        kind: "experiment",
        allowOptInFeatureSetting: "allowTriggerAutomationAction",
    },
    {
        id: "pluginRizaCodeInterpreter",
        name: "Riza Code Interpreter",
        description: "Enables the Riza Code Interpreter plugin for executing code in a secure sandbox.",
        kind: "experiment",
        allowOptInFeatureSetting: "enablePrivateBeta",
    },
    {
        id: "dataPlotComponent",
        name: "Data Plot: Charting component",
        description:
            "The Data Plot component allows you to create custom charts from raw table data that won't performing any automatic grouping or aggregations.",
        kind: "experiment",
        allowOptInFeatureSetting: "allowDataPlotComponent",
    },
    {
        id: "inlineAudioTranscript",
        name: "Inline voice transcription",
        description: "Transcribe voice in text entries and Notes components",
        kind: "experiment",
        allowOptInFeatureSetting: "allowInlineAudioTranscript",
    },
    {
        id: "aiAgent",
        name: "AI Agent",
        description: "Vibe no-coding",
        kind: "experiment",
        allowOptInFeatureSetting: "allowAIAgent",
    },
] as const;

const experimentIds = allExperiments.map(x => x.id);

export type ExperimentName = (typeof experimentIds)[number];

export const experiments: readonly Experiment[] = allExperiments;

export const experimentDefaultValues: Record<ExperimentName, boolean> = experimentIds
    .map(x => ({ [x]: false }))
    .reduce((acc, cv) => ({ ...acc, ...cv })) as Record<ExperimentName, boolean>;
