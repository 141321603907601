import { assertNever, panic } from "@glideapps/ts-necessities";
import { makeComponentID, memoizeFunction } from "@glide/support";
import { type Description, type TableGlideType, SourceColumnKind, SpecialValueKind } from "@glide/type-schema";
import {
    type PropertyDescription,
    type LegacyPropertyDescription,
    type ActionDescription,
    type ComponentKind,
    type ComponentDescription,
    type BaseContainerComponentDescription,
    type PredicateOperator,
    type ArrayFilter,
    type TransformableContentDescription,
    type ClassScreenDescription,
    type FilterArrayTransform,
    type SortArrayTransform,
    type ShuffleArrayTransform,
    type TableOrderArrayTransform,
    type ArrayTransform,
    type ArrayContentDescription,
    type ClassOrArrayScreenDescription,
    type ScreenDescription,
    PropertyKind,
    ScreenDescriptionKind,
    UnaryPredicateCompositeOperator,
    ArrayTransformKind,
    ArrayScreenFormat,
    MutatingScreenKind,
} from "@glide/app-description";

export const namesForRows: Record<SourceColumnKind, string> = {
    [SourceColumnKind.DefaultContext]: "This item",
    [SourceColumnKind.UserProfile]: "User profile row",
    [SourceColumnKind.ContainingScreen]: "Containing screen row",
    [SourceColumnKind.ActionNodeOutput]: "Action node output",
};

export interface InputOutputTables {
    readonly input: TableGlideType;
    readonly output: TableGlideType;
}

export const makeInputOutputTables = memoizeFunction(
    "makeInputOutputTables",
    (input: TableGlideType, output?: TableGlideType): InputOutputTables => {
        return { input, output: output ?? input };
    }
);

export function withOutputTable(tables: InputOutputTables, output: TableGlideType): InputOutputTables {
    return makeInputOutputTables(tables.input, output);
}

export function getInputOrOutputTable(tables: InputOutputTables, isEdit: boolean): TableGlideType {
    if (isEdit) {
        return tables.output;
    } else {
        return tables.input;
    }
}

export enum ImageKind {
    URL = "url",
    MapFromAddress = "map-from-address",
}

interface ImageDescription {
    readonly imageKind: LegacyPropertyDescription;
    readonly imageURLProperty: LegacyPropertyDescription;
}

export interface TitleDescription extends Partial<ImageDescription> {
    readonly titleProperty?: LegacyPropertyDescription;
    readonly subtitleProperty?: LegacyPropertyDescription;
}

export interface ActionWithOutputRowDescription extends ActionDescription {
    readonly outputRow: PropertyDescription;
}

export const ComponentKindInlineList: ComponentKind = "inline-list";

export function makeEmptyComponentDescription(kind: ComponentKind): ComponentDescription {
    return { kind, componentID: makeComponentID(), visibilityFilters: undefined };
}

export interface DefaultableComponentDescription extends ComponentDescription {
    readonly propertyName: PropertyDescription;
    readonly defaultValue: PropertyDescription | undefined;
}

export enum ContainerLayout {
    Full = "full",
    OneToOne = "oneToOne",
    ThreeColumns = "threeColumns",
    TwoToOne = "twoToOne",
    OneToTwo = "oneToTwo",
    OneToThree = "oneToThree",
    ThreeToOne = "threeToOne",
    FourColumns = "fourColumns",
    Grid = "grid",
}

export enum ContainerWidth {
    Normal = "normal",
    Small = "small",
    XSmall = "xsmall",
}

export enum ContainerPadding {
    Medium = "container-padding-md",
    Large = "container-padding-lg",
    XLarge = "container-padding-xl",
}

export enum ContainerAlignment {
    top = "top",
    center = "center",
}

export function getContainerLayoutColumnsCount(layout: ContainerLayout = ContainerLayout.Full): number {
    switch (layout) {
        case ContainerLayout.Grid:
        case ContainerLayout.Full:
            return 1;
        case ContainerLayout.OneToOne:
        case ContainerLayout.TwoToOne:
        case ContainerLayout.OneToTwo:
        case ContainerLayout.ThreeToOne:
        case ContainerLayout.OneToThree:
            return 2;
        case ContainerLayout.ThreeColumns:
            return 3;
        case ContainerLayout.FourColumns:
            return 4;

        default:
            assertNever(layout, "Invalid container layout");
    }
}

// ##containerComponents:
// We only use this in fluent.
export interface ContainerComponentDescription extends BaseContainerComponentDescription {
    readonly layout: PropertyDescription | undefined;
}

export type UpdateDescriptionFunc = (makeUpdates: (desc: Description) => Partial<Description> | undefined) => void;
export type UpdateScreenDescriptionFunc = <T extends ScreenDescription>(
    makeUpdates: (desc: T) => Partial<T> | undefined
) => void;

export function isMatchEmailOperator(op: PredicateOperator): boolean {
    return (
        op === UnaryPredicateCompositeOperator.MatchesVerifiedEmailAddress ||
        op === UnaryPredicateCompositeOperator.DoesNotMatchVerifiedEmailAddress
    );
}

export function isEmailArrayFilter(filter: ArrayFilter): boolean {
    return filter.key.kind === PropertyKind.SpecialValue && filter.key.value === SpecialValueKind.VerifiedEmailAddress;
}

export type OrderingArrayTransform = SortArrayTransform | TableOrderArrayTransform | ShuffleArrayTransform;

function isOrderingArrayTransform(t: ArrayTransform): t is OrderingArrayTransform {
    return (
        t.kind === ArrayTransformKind.Sort ||
        t.kind === ArrayTransformKind.Shuffle ||
        t.kind === ArrayTransformKind.TableOrder
    );
}

export function filterOrderingArrayTransforms(
    transforms: readonly ArrayTransform[]
): readonly OrderingArrayTransform[] {
    return transforms.filter(isOrderingArrayTransform) as OrderingArrayTransform[];
}

export function findFilterArrayTransform(transforms: readonly ArrayTransform[]): FilterArrayTransform | undefined {
    const filterTransforms = transforms.filter(t => t.kind === ArrayTransformKind.Filter) as FilterArrayTransform[];
    if (filterTransforms.length === 0) return undefined;
    if (filterTransforms.length > 1) {
        return panic("More than one filter transform");
    }
    return filterTransforms[0];
}

export function withDefaultOrderingArrayTransform<T extends ArrayTransform>(
    transforms: readonly T[]
): readonly (T | OrderingArrayTransform)[] {
    if (filterOrderingArrayTransforms(transforms).length === 0) {
        return [...transforms, { kind: ArrayTransformKind.TableOrder, reverse: false }];
    } else {
        return transforms;
    }
}

export function getArrayTransforms(
    desc: TransformableContentDescription,
    withOrder: boolean = true,
    withLimit: boolean = true
): readonly ArrayTransform[] {
    let transforms = desc.transforms ?? [];
    if (withOrder) {
        transforms = withDefaultOrderingArrayTransform(transforms);
    } else {
        transforms = transforms.filter(t => !isOrderingArrayTransform(t));
    }
    if (!withLimit) {
        transforms = transforms.filter(t => t.kind !== ArrayTransformKind.Limit);
    }
    return transforms;
}

export interface DynamicSortColumn {
    readonly column: PropertyDescription;
}

export function isClassOrArrayScreenDescription(
    desc: ScreenDescription | undefined
): desc is ClassOrArrayScreenDescription {
    return desc?.kind === ScreenDescriptionKind.Class || desc?.kind === ScreenDescriptionKind.Array;
}

// In almost all cases you'll want to use ##getScreenComponents instead of
// this.
export function getScreenComponentsArray(
    desc: ClassScreenDescription | ArrayContentDescription
): readonly ComponentDescription[] {
    return desc.components ?? [];
}

// ##getScreenComponents:
// This will return the components in the screen
// description only if the screen actually supports components.
export function getScreenComponents(desc: ClassOrArrayScreenDescription): readonly ComponentDescription[] {
    if (desc.kind === ScreenDescriptionKind.Class) {
        return getScreenComponentsArray(desc);
    } else if (desc.kind === ScreenDescriptionKind.Array) {
        // FIXME: Don't hardcode Tinder here, but use the array screen handler
        // instead.  That needs a bit more dependency distentanglement,
        // though.
        if (desc.format === ArrayScreenFormat.Tinder) {
            return getScreenComponentsArray(desc);
        } else {
            return [];
        }
    } else {
        return assertNever(desc);
    }
}

export function hasInputContext(kind: MutatingScreenKind | undefined): boolean {
    return kind === undefined || kind === MutatingScreenKind.FormScreen || kind === MutatingScreenKind.EditScreen;
}

export function doesMutatingScreenAddRows(kind: MutatingScreenKind | undefined): boolean {
    return kind === MutatingScreenKind.AddScreen || kind === MutatingScreenKind.FormScreen;
}
