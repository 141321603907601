import type { BasePrimitiveValue } from "@glide/data-types";
import { getAppTabs } from "@glide/common-core/dist/js/components/SerializedApp";
import type { SpecialValueDescription, ColumnType, Description, TableColumn, TableGlideType } from "@glide/type-schema";
import {
    areTableNamesEqual,
    getDebugPrintTableName,
    SourceColumnKind,
    SpecialValueKind,
    getSourceColumnPath,
    getTableColumnDisplayName,
    getTableName,
    getTableRefTableName,
    isColumnWritable,
    isSingleRelationType,
    makePrimitiveType,
    makeSourceColumn,
    sheetNameForTable,
    type SchemaInspector,
} from "@glide/type-schema";
import type { UserFeatures } from "@glide/app-description";
import {
    type ActionDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
    PropertyKind,
    type FlowActionNode,
    ActionNodeKind,
    getActionProperty,
    getArrayProperty,
    getColumnProperty,
    getCompoundActionProperty,
    getEnumProperty,
    getFilterProperty,
    getScreenProperty,
    getSourceColumnProperty,
    getSpecialValueProperty,
    getStringProperty,
    isTableViewProperty,
    makeEnumProperty,
    makeFilterProperty,
    makeScreenProperty,
    makeSourceColumnProperty,
} from "@glide/app-description";
import {
    type InputOutputTables,
    doesMutatingScreenAddRows,
    makeInputOutputTables,
    namesForRows,
} from "@glide/common-core/dist/js/description";
import {
    type ActionNodeInScope,
    type ActionPropertyDescriptorCase,
    type AppDescriptionContext,
    type ArrayPropertyDescriptorCase,
    type ColumnPropertyDescriptorCase,
    type CompoundActionPropertyDescriptorCase,
    type ConfigurationButtonPropertyDescriptorCase,
    type ConstantPropertyDescriptorCase,
    type EditedColumn,
    type EditedColumnsAndTables,
    type EmojiPropertyDescriptorCase,
    type EnumPropertyDescriptorCase,
    type FilterPropertyDescriptorCase,
    type FormulaPropertyDescriptorCase,
    type IconPropertyDescriptorCase,
    type InlineComputationPropertyDescriptorCase,
    type InteractiveComponentConfiguratorContext,
    type JSONPathPropertyDescriptorCase,
    type NumberPropertyDescriptorCase,
    type PaymentMethodPropertyDescriptorCase,
    type PropertyDescriptor,
    type PropertyDescriptorCase,
    type RewritingComponentConfiguratorContext,
    type ScreenPropertyDescriptorCase,
    type SecretPropertyDescriptorCase,
    type SortsPropertyDescriptorCase,
    type SpecialValuePropertyDescriptorCase,
    type StringPropertyDescriptorCase,
    type SwitchPropertyDescriptorCase,
    type TablePropertyDescriptorCase,
    type TableViewPropertyDescriptorCase,
    type TransformsPropertyDescriptorCase,
    type WarningPropertyDescriptorCase,
    type WebhookPropertyDescriptorCase,
    type ZapPropertyDescriptorCase,
    applyColumnFilterSpec,
    RequiredKind,
    combineEditedColumnsAndTables,
    emptyEditedColumnsAndTables,
    findFittingColumn,
    getFilterTable,
    resolveSourceColumn,
    thisRowSourceColumn,
    updatesForProperty,
    updatesForPropertyValue,
    updatesForUntypedProperty,
} from "@glide/function-utils";
import { assert, assertNever, defined, mapFilterUndefined, panic, definedMap } from "@glideapps/ts-necessities";
import { isArray, isDefined, isResponseOK, logError, logInfo } from "@glide/support";
import { arrayMapSync, setMap } from "collection-utils";
import first from "lodash/first";
import { handlerForActionKind } from "../../actions";
import type { CompoundActionDescription } from "../../actions/compound-handler";
import { getAllowedAndSelectedColumns, getAllowedColumnsForProperty } from "../../allowed-columns";
import { duplicateDescription } from "../../handlers";
import {
    getColumnForProperty,
    getColumnsUsedInDescription,
    getEditedColumnsForProperties,
    getInputOrOutputTableForProperty,
    makeDefaultAction,
    makeEmptyColumnsUsedInDescription,
    mergeIntoColumnsUsedInDescription,
    rewriteFilter,
} from "../descriptor-utils";
import { populateDescription } from "../populate-description";
import type { ColumnAssignmentInfo } from ".";
import { findSpecialValueDescriptor, getSpecialValueDescriptors, getTypeForSpecialValue } from "../special-values";
import {
    type CompatibilityHelper,
    type DescriptionHandler,
    type SearchedColumn,
    PopulationMode,
    isRewritePopulationMode,
    registerDescriptionHandler,
} from ".";
import { v4 as uuid } from "uuid";
import type { DuplicatePluginSecretBody } from "@glide/common-core";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import { processCompoundActionFlow } from "../walk-action";
import type { StaticActionContext } from "../../static-context";
import type { SerializablePluginMetadata } from "@glide/plugins-codecs";

abstract class BaseDescriptionHandler<TDescriptorCase extends PropertyDescriptorCase>
    implements DescriptionHandler<TDescriptorCase>
{
    constructor(public readonly kind: PropertyKind) {}

    public abstract defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        rootDesc: Description,
        desc: Description,
        pdc: TDescriptorCase,
        avoidDirectColumns: Set<TableColumn>,
        avoidEditedColumns: Set<TableColumn>,
        avoidIndirectColumns: Set<TableColumn>,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined,
        ccc: AppDescriptionContext,
        populationMode: PopulationMode,
        getDefaultCaption: () => string | undefined
    ): Partial<Description> | undefined;

    public getEditedColumns(
        _pdc: TDescriptorCase,
        _pd: PropertyDescription,
        _rootDesc: Description,
        _env: StaticActionContext<AppDescriptionContext>,
        _withActions: boolean
    ): EditedColumnsAndTables {
        return { editedColumns: [], deletedTables: [] };
    }

    public rewriteWithCase(
        _descr: PropertyDescriptor,
        _pdc: TDescriptorCase,
        _pd: PropertyDescription,
        _newRootDesc: Description,
        _newDesc: Description,
        _tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _ignoreRequired: boolean,
        _ccc: RewritingComponentConfiguratorContext,
        _populationMode: PopulationMode
    ): Partial<Description> | undefined {
        return {};
    }

    public async duplicate(
        _descr: PropertyDescriptor,
        _pdc: TDescriptorCase,
        _propertyDescription: PropertyDescription,
        _containingDescription: Description,
        _rootDesc: Description,
        _tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _iccc: InteractiveComponentConfiguratorContext,
        _screensCreated: Set<string>,
        _appFacilities: ActionAppFacilities
    ): Promise<Partial<Description>> {
        return {};
    }

    public getType(
        _schemaInspector: SchemaInspector,
        pd: PropertyDescription,
        _table: TableGlideType
    ): ColumnType | undefined {
        return panic(`Cannot get type for property ${JSON.stringify(pd)}`);
    }

    public abstract getColumnAssignmentInfo(
        schema: SchemaInspector,
        pd: PropertyDescription,
        table: TableGlideType,
        pluginMetadata: readonly SerializablePluginMetadata[] | undefined,
        userFeatures: UserFeatures
    ): ColumnAssignmentInfo;

    public isSearchedColumn(
        _searched: SearchedColumn,
        _schema: SchemaInspector,
        _pd: PropertyDescription,
        _table: TableGlideType
    ): boolean {
        return false;
    }

    public checkCompatibility(
        _propertyDescription: PropertyDescription,
        _desc: Description,
        _propertyDescriptor: PropertyDescriptor,
        _oldTables: InputOutputTables,
        _helper: CompatibilityHelper
    ): void {
        return;
    }
}

interface TableAndMaybeColumn {
    readonly table: TableGlideType;
    readonly column: TableColumn | undefined;
    readonly inContext: boolean;
}

class ColumnDescriptionHandler extends BaseDescriptionHandler<ColumnPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Column);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        rootDesc: Description,
        desc: Description,
        pdc: ColumnPropertyDescriptorCase,
        avoidDirectColumns: Set<TableColumn>,
        avoidEditedColumns: Set<TableColumn>,
        avoidIndirectColumns: Set<TableColumn>,
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        ccc: AppDescriptionContext,
        populationMode: PopulationMode
    ): Partial<Description> | undefined {
        if (
            (!pdc.required && populationMode === PopulationMode.Rewrite) ||
            (populationMode === PopulationMode.RewriteWithNewTable && pdc.emptyByDefault === true)
        ) {
            return updatesForProperty(descr.property, desc, undefined, undefined);
        }

        const allowFullRow = isDefined(tables) ? pdc.allowFullRow?.(tables.input, ccc) ?? false : false;
        const fullRowUpdate =
            allowFullRow !== false
                ? updatesForProperty(descr.property, desc, makeSourceColumnProperty(thisRowSourceColumn), undefined)
                : undefined;
        if (allowFullRow === "preferred") {
            return defined(fullRowUpdate);
        }

        // We don't (yet) support prior steps here for filling in default
        // columns.
        const acceptedColumns = getAllowedColumnsForProperty(pdc, rootDesc, desc, {
            tables,
            context: ccc,
            mutatingScreenKind,
            isAutomation: false,
        });
        const isIndirect = pdc.getIndirectTable !== undefined;
        const avoidColumns = isIndirect
            ? avoidIndirectColumns
            : pdc.isEditedInApp === true
            ? avoidEditedColumns
            : avoidDirectColumns;
        const avoidColumnNames = setMap(avoidColumns, c => c.name);
        const avoidColumnNamesSecondary =
            avoidColumns === avoidEditedColumns ? setMap(avoidDirectColumns, c => c.name) : undefined;
        const excludeColumnNamesFromProperties = mapFilterUndefined(pdc.ignoreColumnsFromProperties ?? [], c =>
            getColumnProperty((desc as any)[c])
        );

        // First, try avoiding the column names from other properties.
        // If we can't do that, and the property is required, try again
        // with allowing those, too.
        let column = findFittingColumn(
            acceptedColumns.allowed,
            acceptedColumns.avoid,
            pdc.preferredNames ?? [],
            avoidColumnNames,
            avoidColumnNamesSecondary,
            new Set(excludeColumnNamesFromProperties),
            pdc.preferredType,
            true
        );
        if (column === undefined && pdc.required) {
            // We try again without excluding columns from the list, or with
            // the wrong primitive types.
            column = findFittingColumn(
                acceptedColumns.allowed,
                acceptedColumns.avoid,
                pdc.preferredNames ?? [],
                avoidColumnNames,
                avoidColumnNamesSecondary,
                undefined,
                pdc.preferredType,
                false
            );
        }

        if (column === undefined || (populationMode === PopulationMode.Default && pdc.emptyByDefault === true)) {
            if (pdc.required && pdc.emptyByDefault !== true) {
                return fullRowUpdate;
            }
            return updatesForProperty(descr.property, desc, undefined, undefined);
        } else {
            avoidColumns.add(column);
            if (pdc.isEditedInApp === true) {
                avoidEditedColumns.add(column);
            }
            return updatesForPropertyValue(pdc.kind, descr.property, desc, makeSourceColumn(column.name), undefined);
        }
    }

    private resolveColumn(
        pd: PropertyDescription,
        table: TableGlideType | undefined,
        schema: SchemaInspector,
        actionNodesInScope: readonly ActionNodeInScope[] | undefined
    ): TableAndMaybeColumn | undefined {
        const sc = getSourceColumnProperty(pd);
        if (sc === undefined) return undefined;
        // We know this is a column, so we don't need `actionNodesInScope`
        // here.
        const resolved = resolveSourceColumn(schema, sc, table, undefined, actionNodesInScope);
        if (resolved === undefined) return undefined;
        if (resolved.tableAndColumn !== undefined) {
            // A column in a table
            const inContext = sc.kind === SourceColumnKind.DefaultContext && getSourceColumnPath(sc).length === 1;
            return { ...resolved.tableAndColumn, inContext };
        } else if (resolved.contextTable !== undefined) {
            // A whole row in a table
            return { table: resolved.contextTable, column: undefined, inContext: false };
        } else {
            return undefined;
        }
    }

    public getEditedColumns(
        pdc: ColumnPropertyDescriptorCase,
        pd: PropertyDescription,
        rootDesc: Description,
        env: StaticActionContext<AppDescriptionContext>
    ): EditedColumnsAndTables {
        const { context: ccc, tables, mutatingScreenKind } = env;

        let outputTable: TableGlideType | undefined;
        let inScreenContext: boolean;
        if (tables !== undefined) {
            if (pdc.getIndirectTable !== undefined) {
                const actionNodesInScope = env.priorSteps?.map(s => s.node) ?? [];
                const indirectTable = pdc.getIndirectTable(tables, rootDesc, rootDesc, ccc, actionNodesInScope);
                outputTable = indirectTable?.table;
                inScreenContext = indirectTable?.inScreenContext === true;
            } else {
                outputTable = getInputOrOutputTableForProperty(tables, pdc, mutatingScreenKind);
                inScreenContext = true;
            }
        } else {
            inScreenContext = false;
        }

        const resolved = this.resolveColumn(
            pd,
            outputTable,
            ccc,
            env.priorSteps?.map(ps => ps.node)
        );
        if (resolved?.column === undefined) return emptyEditedColumnsAndTables;

        const { table: t, column: c } = resolved;
        if (!resolved.inContext) {
            inScreenContext = false;
        }

        // ##editedColumnsInUserProfile:
        // It's possible to get in a state where a column property is in the
        // user profile's context, but it's also marked editable.  We don't
        // support this at the moment, so we nip it in the bud here.
        if (inScreenContext && t !== outputTable) return emptyEditedColumnsAndTables;

        function isWritable(forAdd: boolean) {
            return isColumnWritable(c, t, forAdd, {
                allowHidden: true,
                allowArrays: true,
            });
        }

        const editedColumns: EditedColumn[] = [];

        let haveAdd: boolean;
        if (
            pdc.isEditedInApp === true ||
            pdc.isEditedInApp === "if-writable" ||
            pdc.isEditedInApp === "even-if-not-writable"
        ) {
            haveAdd = inScreenContext && doesMutatingScreenAddRows(mutatingScreenKind);
            if (isWritable(haveAdd)) {
                // Adding rows never happens in the screen context
                editedColumns.push([c.name, inScreenContext && !haveAdd, haveAdd, getTableName(t)]);
            }
        } else {
            haveAdd = false;
        }

        if (!haveAdd && pdc.isAddedInApp === true) {
            if (isWritable(true)) {
                // Adding rows never happens in the screen context
                editedColumns.push([c.name, false, true, getTableName(t)]);
            }
        }

        return {
            editedColumns,
            deletedTables: [],
        };
    }

    public rewriteWithCase(
        descr: PropertyDescriptor,
        pdc: ColumnPropertyDescriptorCase,
        pd: PropertyDescription,
        newRootDesc: Description,
        newDesc: Description,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined,
        ignoreRequired: boolean,
        ccc: RewritingComponentConfiguratorContext
    ): Partial<Description> | undefined {
        const sourceColumn = getSourceColumnProperty(pd);
        const allowedColumns = getAllowedAndSelectedColumns(pdc, sourceColumn, newRootDesc, newDesc, {
            tables,
            context: ccc,
            mutatingScreenKind,
            // We currently don't hit this code path for automations, so we
            // don't need to pass in the prior steps.  If we ever do, we will
            // have to.
            priorSteps: undefined,
            isAutomation: false,
        });
        if (allowedColumns?.resolvedSelectedColumn === undefined) {
            if (!ignoreRequired && pdc.required) return undefined;

            return updatesForProperty(descr.property, newDesc, undefined, undefined);
        }
        return {};
    }

    public getType(
        schemaInspector: SchemaInspector,
        pd: PropertyDescription,
        table: TableGlideType
    ): ColumnType | undefined {
        const sc = defined(getSourceColumnProperty(pd));
        // This is a column, so we don't need `actionNodesInScope` here.
        return resolveSourceColumn(schemaInspector, sc, table, undefined, undefined)?.type;
    }

    private getColumnAssignmentName(pd: PropertyDescription): string {
        const sc = defined(getSourceColumnProperty(pd));
        switch (sc.kind) {
            case SourceColumnKind.DefaultContext:
                return "Screen column";
            case SourceColumnKind.UserProfile:
                return "User profile column";
            case SourceColumnKind.ContainingScreen:
                return "Screen column";
            case SourceColumnKind.ActionNodeOutput:
                return "Action node output";
            default:
                return assertNever(sc.kind);
        }
    }

    private getColumnAssignmentLabel(
        schemaInspector: SchemaInspector,
        pd: PropertyDescription,
        table: TableGlideType
    ): string {
        const resolved = this.resolveColumn(pd, table, schemaInspector, undefined);
        if (resolved === undefined) return "?";
        if (resolved.column !== undefined) {
            return getTableColumnDisplayName(resolved.column);
        } else {
            // If we don't have a column, then we have a whole row in a table,
            // in which case the path must not point to a column, i.e. be
            // empty.
            const sc = defined(getSourceColumnProperty(pd));
            assert(getSourceColumnPath(sc).length === 0);
            return namesForRows[sc.kind];
        }
    }

    public getColumnAssignmentInfo(
        schema: SchemaInspector,
        pd: PropertyDescription,
        table: TableGlideType
    ): ColumnAssignmentInfo {
        return {
            name: this.getColumnAssignmentName(pd),
            icon: "componentColumn",
            label: this.getColumnAssignmentLabel(schema, pd, table),
        };
    }

    public isSearchedColumn(
        searched: SearchedColumn,
        schema: SchemaInspector,
        pd: PropertyDescription,
        table: TableGlideType
    ): boolean {
        if (searched.columnName === undefined || searched.tableName === undefined) return false;
        // Previous action steps are not relevant to searched columns because
        // those don't take Automations into account.
        const resolved = this.resolveColumn(pd, table, schema, undefined);
        if (resolved?.column === undefined) return false;
        return (
            searched.columnName === resolved.column.name &&
            areTableNamesEqual(searched.tableName, getTableName(resolved.table))
        );
    }

    public checkCompatibility(
        propertyDescription: PropertyDescription,
        desc: Description,
        propertyDescriptor: PropertyDescriptor,
        oldTables: InputOutputTables,
        helper: CompatibilityHelper
    ): void {
        // FIXME: use source column
        const columnName = getColumnProperty(propertyDescription);
        if (columnName === undefined) return undefined;

        const { screenName } = helper;

        const oldColumnResult = getColumnForProperty(
            propertyDescriptor,
            desc,
            // FIXME: I don't think this is right (at least it behaves the
            // same way it used to)
            desc,
            oldTables,
            helper.oldComponentConfiguratorContext,
            []
        );
        if (oldColumnResult === undefined) {
            // logError(`Old column ${columnName} not found, for screen ${screenName}`);
            return;
        }

        const newTable = helper.findTable(getTableName(oldColumnResult.table));
        if (newTable === undefined) return undefined;

        const newColumn = helper.findNewColumn(newTable, columnName);
        if (newColumn === undefined) return;

        const oldColumn = oldColumnResult.column;
        const newTableDisplayName = sheetNameForTable(newTable);
        if (isSingleRelationType(oldColumn.type)) {
            // A table reference must point to the same table.
            if (!isSingleRelationType(newColumn.type)) {
                helper.addOtherIssue(
                    `Column ${columnName} in sheet ${newTableDisplayName} is not a relation, but has type ${newColumn.type.kind}, for screen ${screenName}`
                );
                return;
            }
            if (!areTableNamesEqual(getTableRefTableName(oldColumn.type), getTableRefTableName(newColumn.type))) {
                helper.addOtherIssue(
                    `Column ${columnName} in sheet ${newTableDisplayName} does not relate to table ${getDebugPrintTableName(
                        getTableRefTableName(oldColumn.type)
                    )}, but table ${getDebugPrintTableName(
                        getTableRefTableName(newColumn.type)
                    )}, for screen ${screenName}`
                );
                return;
            }
        }

        if (oldColumn.type.kind === "array") {
            if (newColumn.type.kind !== "array") {
                helper.addOtherIssue(
                    `Column ${columnName} in sheet ${newTableDisplayName} is not an array, but has type ${newColumn.type.kind}, for screen ${screenName}`
                );
                return;
            }

            if (isSingleRelationType(oldColumn.type.items)) {
                // An array table reference must point to the same table.
                if (!isSingleRelationType(newColumn.type.items)) {
                    helper.addOtherIssue(
                        `Column ${columnName} in sheet ${newTableDisplayName} is not a relation array, but an array of ${newColumn.type.items.kind}, for screen ${screenName}`
                    );
                    return;
                }
                if (
                    !areTableNamesEqual(
                        getTableRefTableName(oldColumn.type.items),
                        getTableRefTableName(newColumn.type.items)
                    )
                ) {
                    helper.addOtherIssue(
                        `Column ${columnName} in sheet ${newTableDisplayName} does not relate to table ${getDebugPrintTableName(
                            getTableRefTableName(oldColumn.type.items)
                        )}, but table ${getDebugPrintTableName(
                            getTableRefTableName(newColumn.type.items)
                        )}, for screen ${screenName}`
                    );
                    return;
                }
            }
        }
    }
}

class TableDescriptionHandler extends BaseDescriptionHandler<TablePropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Table);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        rootDesc: Description,
        desc: Description,
        pdc: TablePropertyDescriptorCase,
        _avoidDirectColumns: Set<TableColumn>,
        _avoidEditedColumns: Set<TableColumn>,
        _avoidIndirectColumns: Set<TableColumn>,
        tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        schema: SchemaInspector
    ): Partial<Description> | undefined {
        const allowedTables = pdc.getAllowedTables(rootDesc, schema, tables).filter(t => !getTableName(t).isSpecial);
        if (allowedTables.length === 0) return undefined;
        return updatesForPropertyValue(pdc.kind, descr.property, desc, getTableName(allowedTables[0]), undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Table", icon: undefined, label: undefined };
    }
}

class TableViewDescriptionHandler extends BaseDescriptionHandler<TableViewPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.TableView);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        rootDesc: Description,
        desc: Description,
        pdc: TableViewPropertyDescriptorCase,
        _avoidDirectColumns: Set<TableColumn>,
        _avoidEditedColumns: Set<TableColumn>,
        _avoidIndirectColumns: Set<TableColumn>,
        tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        adc: AppDescriptionContext
    ): Partial<Description> | undefined {
        const allowedTables = pdc.getAllowedTables(rootDesc, adc, tables);
        if (allowedTables.length > 0) {
            return updatesForPropertyValue(
                pdc.kind,
                descr.property,
                desc,
                { tableOrColumn: { kind: "table", value: allowedTables[0].name } },
                undefined
            );
        }
        const allowedColumns = applyColumnFilterSpec(pdc.columnFilter)(tables.input, rootDesc, adc);
        if (allowedColumns.length > 0) {
            return updatesForPropertyValue(
                pdc.kind,
                descr.property,
                desc,
                { tableOrcolumn: { kind: "column", value: makeSourceColumn(allowedColumns[0].name) } },
                undefined
            );
        }
        return undefined;
    }

    private getColumnAssignmentName(pd: PropertyDescription): string {
        if (!isTableViewProperty(pd)) return "";
        return pd.value.tableOrColumn.kind === "table" ? "Table" : "Column";
    }

    public getColumnAssignmentInfo(_schema: SchemaInspector, pd: PropertyDescription): ColumnAssignmentInfo {
        return { name: this.getColumnAssignmentName(pd), icon: undefined, label: undefined };
    }
}

class EnumDescriptionHandler<P extends BasePrimitiveValue> extends BaseDescriptionHandler<
    EnumPropertyDescriptorCase<P>
> {
    constructor() {
        super(PropertyKind.Enum);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: EnumPropertyDescriptorCase<P>
    ): Partial<Description> | undefined {
        if (pdc.defaultCaseValue === undefined) {
            return updatesForProperty(descr.property, desc, undefined, undefined);
        }
        return updatesForPropertyValue(pdc.kind, descr.property, desc, pdc.defaultCaseValue, undefined);
    }

    public getEditedColumns(
        pdc: EnumPropertyDescriptorCase<P>,
        pd: PropertyDescription,
        rootDesc: Description,
        env: StaticActionContext<AppDescriptionContext>
    ): EditedColumnsAndTables {
        const { context: ccc, tables, mutatingScreenKind } = env;

        const enumValue = getEnumProperty(pd);
        if (enumValue === undefined || typeof pdc.cases === "function") return emptyEditedColumnsAndTables;

        // FIXME: The point of this loop is to find the case with `c.value ===
        // enumValue` and then return some result based on that.  This loop is
        // an awkward way of expressing that.
        for (const c of pdc.cases) {
            if (c.value !== enumValue) continue;

            // `columnWritten` is only used for the "Is Favorited?" column, so
            // it doesn't need to take action steps into account.
            if (c.columnWritten === undefined || tables === undefined) return emptyEditedColumnsAndTables;

            let table: TableGlideType | undefined = tables.output;
            let inScreenContext = true;
            if (pdc.getIndirectTable !== undefined) {
                const actionNodesInScope = env.priorSteps?.map(s => s.node) ?? [];
                const propertyTable = pdc.getIndirectTable(tables, rootDesc, rootDesc, ccc, actionNodesInScope);
                table = propertyTable?.table;
                inScreenContext = propertyTable?.inScreenContext === true;
            }
            if (table === undefined) return emptyEditedColumnsAndTables;

            return {
                editedColumns: [
                    [
                        c.columnWritten,
                        inScreenContext,
                        inScreenContext && doesMutatingScreenAddRows(mutatingScreenKind),
                        getTableName(table),
                    ],
                ],
                deletedTables: [],
            };
        }

        return emptyEditedColumnsAndTables;
    }

    public rewriteWithCase(
        descr: PropertyDescriptor,
        pdc: EnumPropertyDescriptorCase<P>,
        pd: PropertyDescription,
        _newRootDesc: Description,
        newDesc: Description
    ): Partial<Description> | undefined {
        const enumValue = getEnumProperty(pd);
        if (enumValue === undefined) {
            if (pdc.defaultCaseValue === undefined) {
                logInfo("No value for enum", descr.property);
                return undefined;
            }
            return updatesForProperty(descr.property, newDesc, makeEnumProperty(pdc.defaultCaseValue), undefined);
        }
        return {};
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Enum", icon: undefined, label: undefined };
    }
}

class StringDescriptionHandler extends BaseDescriptionHandler<StringPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.String);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: StringPropertyDescriptorCase,
        _avoidDirectColumns: Set<TableColumn>,
        _avoidEditedColumns: Set<TableColumn>,
        _avoidIndirectColumns: Set<TableColumn>,
        _tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _schema: SchemaInspector,
        populationMode: PopulationMode,
        getDefaultCaption: () => string | undefined
    ): Partial<Description> | undefined {
        if (!isRewritePopulationMode(populationMode) || pdc.required) {
            let stringValue = pdc.defaultValue;
            if (stringValue === undefined && pdc.isCaption !== undefined) {
                stringValue = getDefaultCaption() ?? pdc.isCaption;
            }
            if (stringValue === undefined && pdc.required) {
                stringValue = "";
            }
            return updatesForPropertyValue(pdc.kind, descr.property, desc, stringValue, undefined);
        } else if (pdc.defaultValue !== undefined) {
            return updatesForPropertyValue(pdc.kind, descr.property, desc, pdc.defaultValue, undefined);
        } else {
            return updatesForProperty(descr.property, desc, pdc.defaultValue, undefined);
        }
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "String", icon: undefined, label: undefined };
    }

    public async duplicate(
        descr: PropertyDescriptor,
        pdc: StringPropertyDescriptorCase,
        propertyDescription: PropertyDescription,
        containingDescription: Description,
        rootDesc: Description,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<Partial<Description>> {
        const baseUpdates = await super.duplicate(
            descr,
            pdc,
            propertyDescription,
            containingDescription,
            rootDesc,
            tables,
            mutatingScreenKind,
            iccc,
            screensCreated,
            appFacilities
        );

        if (pdc.isSecret === true) {
            const originalSecretID = getStringProperty(propertyDescription);
            if (originalSecretID === undefined) {
                return baseUpdates;
            }

            const newSecretID = uuid();

            const { appID } = iccc;

            const body: DuplicatePluginSecretBody = { appID, originalSecretID, newSecretID };

            const response = await appFacilities.callAuthCloudFunction("duplicatePluginSecret", body, {}, true);
            const newValue = isResponseOK(response) ? newSecretID : undefined;

            const secretUpdates = updatesForPropertyValue(
                pdc.kind,
                descr.property,
                containingDescription,
                newValue,
                undefined
            );

            return { ...baseUpdates, ...secretUpdates };
        }

        return baseUpdates;
    }
}

class SecretDescriptionHandler extends BaseDescriptionHandler<SecretPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Secret);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description
    ): Partial<Description> | undefined {
        return updatesForProperty(descr.property, desc, undefined, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Secret", icon: undefined, label: undefined };
    }
}

class NumberDescriptionHandler extends BaseDescriptionHandler<NumberPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Number);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: NumberPropertyDescriptorCase,
        _avoidDirectColumns: Set<TableColumn>,
        _avoidEditedColumns: Set<TableColumn>,
        _avoidIndirectColumns: Set<TableColumn>,
        _tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _schema: SchemaInspector,
        populationMode: PopulationMode
    ): Partial<Description> | undefined {
        if (
            populationMode === PopulationMode.UserRequest ||
            pdc.required === RequiredKind.Required ||
            (populationMode === PopulationMode.Default && pdc.required === RequiredKind.NotRequiredDefaultPresent)
        ) {
            return updatesForPropertyValue(pdc.kind, descr.property, desc, pdc.defaultValue, undefined);
        } else {
            return updatesForProperty(descr.property, desc, undefined, undefined);
        }
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Number", icon: undefined, label: undefined };
    }
}

class SwitchDescriptionHandler extends BaseDescriptionHandler<SwitchPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Switch);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: SwitchPropertyDescriptorCase
    ): Partial<Description> | undefined {
        return updatesForPropertyValue(pdc.kind, descr.property, desc, pdc.defaultValue === true, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Switch", icon: undefined, label: undefined };
    }
}

class ConstantDescriptionHandler extends BaseDescriptionHandler<ConstantPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Constant);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: ConstantPropertyDescriptorCase
    ): Partial<Description> | undefined {
        return updatesForPropertyValue(pdc.kind, descr.property, desc, pdc.value, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Constant", icon: undefined, label: undefined };
    }
}

class SpecialValueDescriptionHandler extends BaseDescriptionHandler<SpecialValuePropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.SpecialValue);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: SpecialValuePropertyDescriptorCase
    ): Partial<Description> | undefined {
        return updatesForPropertyValue(pdc.kind, descr.property, desc, SpecialValueKind.Timestamp, undefined);
    }

    public getType(
        _schemaInspector: SchemaInspector,
        pd: PropertyDescription,
        _table: TableGlideType
    ): ColumnType | undefined {
        const sv = getSpecialValueProperty(pd);
        if (sv === undefined) return undefined;
        return getTypeForSpecialValue(sv);
    }

    public getColumnAssignmentInfo(
        _schemaInspector: SchemaInspector,
        pd: PropertyDescription,
        _table: TableGlideType,
        pluginMetadata: readonly SerializablePluginMetadata[] | undefined,
        userFeatures: UserFeatures
    ): ColumnAssignmentInfo {
        const sv = pd.value as SpecialValueDescription;

        const descrs = getSpecialValueDescriptors(
            { withActionSource: true, withClearColumn: true },
            pluginMetadata,
            userFeatures
        );
        const descr = findSpecialValueDescriptor(descrs, sv);

        return {
            name: "Special value",
            icon: descr?.img ?? "separator",
            label: descr?.label,
        };
    }
}

class ScreenDescriptionHandler extends BaseDescriptionHandler<ScreenPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Screen);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: ScreenPropertyDescriptorCase,
        _avoidDirectColumns: Set<TableColumn>,
        _avoidEditedColumns: Set<TableColumn>,
        _avoidIndirectColumns: Set<TableColumn>,
        _tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        ccc: AppDescriptionContext
    ): Partial<Description> | undefined {
        const { appDescription } = ccc;
        if (appDescription !== undefined) {
            const tab = first(getAppTabs(appDescription).filter(t => !t.hidden));
            const screenName = getScreenProperty(tab?.screenName);
            if (screenName !== undefined) {
                return updatesForPropertyValue(pdc.kind, descr.property, desc, screenName, undefined);
            }
        }
        return updatesForUntypedProperty(descr.property, undefined);
    }

    public rewriteWithCase(
        descr: PropertyDescriptor,
        _pdc: ScreenPropertyDescriptorCase,
        pd: PropertyDescription,
        _newRootDesc: Description,
        newDesc: Description,
        _tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _ignoreRequired: boolean,
        ccc: RewritingComponentConfiguratorContext
    ): Partial<Description> | undefined {
        const screen = getScreenProperty(pd);
        if (screen !== undefined) {
            const success = ccc.requireScreen(screen);
            return updatesForProperty(
                descr.property,
                newDesc,
                success ? makeScreenProperty(screen) : undefined,
                undefined
            );
        }
        return {};
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Screen", icon: undefined, label: undefined };
    }
}

class PaymentMethodDescriptionHandler extends BaseDescriptionHandler<PaymentMethodPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.PaymentMethod);
    }

    public defaultUpdateForPropertyCase(): Partial<Description> | undefined {
        // FIXME: If the user has a payment method set up already,
        // return an update for that payment method.
        return {};
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Payment method", icon: undefined, label: undefined };
    }
}

class FilterDescriptionHandler extends BaseDescriptionHandler<FilterPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Filter);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: FilterPropertyDescriptorCase
    ): Partial<Description> | undefined {
        return updatesForPropertyValue(pdc.kind, descr.property, desc, undefined, undefined);
    }

    public rewriteWithCase(
        descr: PropertyDescriptor,
        pdc: FilterPropertyDescriptorCase,
        pd: PropertyDescription,
        newRootDesc: Description,
        newDesc: Description,
        tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _ignoreRequired: boolean,
        ccc: RewritingComponentConfiguratorContext
    ): Partial<Description> | undefined {
        const filter = getFilterProperty(pd);
        if (filter !== undefined) {
            const table = getFilterTable(pdc, tables, newRootDesc, newDesc, ccc, []);
            if (table === undefined) return undefined;
            const newFilter = rewriteFilter(filter, table);
            if (newFilter === undefined) return undefined;
            return updatesForProperty(descr.property, newDesc, makeFilterProperty(newFilter), undefined);
        }
        return newDesc;
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Filter", icon: undefined, label: undefined };
    }
}

class FormulaDescriptionHandler extends BaseDescriptionHandler<FormulaPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Formula);
    }

    public defaultUpdateForPropertyCase(descr: PropertyDescriptor): Partial<Description> | undefined {
        return updatesForUntypedProperty(descr.property, undefined);
    }

    // We're not implementing `getType`: we'd need more information to
    // implement it, in particular previous action steps, but since it's only
    // used for column assignments, we don't need it.

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Formula", icon: undefined, label: undefined };
    }
}

class TransformsDescriptionHandler extends BaseDescriptionHandler<TransformsPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Transforms);
    }

    public defaultUpdateForPropertyCase(descr: PropertyDescriptor): Partial<Description> | undefined {
        return updatesForUntypedProperty(descr.property, []);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Transforms", icon: undefined, label: undefined };
    }
}

class SortsDescriptionHandler extends BaseDescriptionHandler<SortsPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Sorts);
    }

    public defaultUpdateForPropertyCase(descr: PropertyDescriptor): Partial<Description> | undefined {
        return updatesForUntypedProperty(descr.property, []);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Sorts", icon: undefined, label: undefined };
    }
}

class ArrayDescriptionHandler extends BaseDescriptionHandler<ArrayPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Array);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        rootDesc: Description,
        desc: Description,
        pdc: ArrayPropertyDescriptorCase,
        _avoidDirectColumns: Set<TableColumn>,
        _avoidEditedColumns: Set<TableColumn>,
        _avoidIndirectColumns: Set<TableColumn>,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined,
        ccc: AppDescriptionContext
    ): Partial<Description> | undefined {
        let newItems: readonly Description[] | undefined;
        if (isArray(pdc.defaultValue)) {
            if (ccc.getIsRewritingComponentConfiguratorContext()) {
                const columnsUsed = makeEmptyColumnsUsedInDescription();
                newItems = mapFilterUndefined(pdc.defaultValue, d =>
                    populateDescription(
                        () => pdc.properties,
                        PopulationMode.RewriteWithNewTable,
                        rootDesc,
                        d,
                        tables,
                        ccc,
                        true,
                        mutatingScreenKind,
                        columnsUsed.direct,
                        undefined,
                        columnsUsed.indirect
                    )
                );
            } else {
                newItems = pdc.defaultValue;
            }
        } else if (!pdc.allowEmpty || pdc.defaultValue === true) {
            const numItems = pdc.numDefaultItems ?? 1;
            const columnsUsed = makeEmptyColumnsUsedInDescription();

            const items: Description[] = [];
            for (let i = 0; i < numItems; i++) {
                const newItem = populateDescription(
                    () => pdc.properties,
                    PopulationMode.Default,
                    rootDesc,
                    {} as Description,
                    tables,
                    ccc,
                    false,
                    mutatingScreenKind,
                    columnsUsed.direct,
                    undefined,
                    columnsUsed.indirect
                );
                if (newItem === undefined) return undefined;
                items.push(newItem);

                mergeIntoColumnsUsedInDescription(
                    columnsUsed,
                    getColumnsUsedInDescription(pdc.properties, newItem, rootDesc, tables, ccc, [])
                );
            }
            newItems = items;
        }

        return updatesForPropertyValue(pdc.kind, descr.property, desc, newItems ?? [], undefined);
    }

    public getEditedColumns(
        pdc: ArrayPropertyDescriptorCase,
        pd: PropertyDescription,
        rootDesc: Description,
        env: StaticActionContext<AppDescriptionContext>,
        withActions: boolean
    ): EditedColumnsAndTables {
        const items = getArrayProperty<Description>(pd);
        if (items === undefined) return emptyEditedColumnsAndTables;

        let columnsEdited: EditedColumnsAndTables = emptyEditedColumnsAndTables;
        for (const item of items) {
            columnsEdited = combineEditedColumnsAndTables(
                columnsEdited,
                getEditedColumnsForProperties(pdc.properties, item, rootDesc, withActions, env)
            );
        }

        return columnsEdited;
    }

    public rewriteWithCase(
        descr: PropertyDescriptor,
        pdc: ArrayPropertyDescriptorCase,
        propertyDescription: PropertyDescription,
        newRootDesc: Description,
        newDesc: Description,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined,
        ignoreRequired: boolean,
        ccc: RewritingComponentConfiguratorContext,
        populationMode: PopulationMode
    ): Partial<Description> | undefined {
        const items = getArrayProperty<Description>(propertyDescription);
        if (items === undefined) return undefined;

        const columnsUsed = makeEmptyColumnsUsedInDescription();

        const newItems = mapFilterUndefined(items, item => {
            const newItem = populateDescription(
                () => pdc.properties,
                populationMode,
                newRootDesc,
                item,
                tables,
                ccc,
                true,
                mutatingScreenKind,
                columnsUsed.direct,
                undefined,
                columnsUsed.indirect,
                ignoreRequired
            );
            if (newItem === undefined) return undefined;

            mergeIntoColumnsUsedInDescription(
                columnsUsed,
                getColumnsUsedInDescription(pdc.properties, newItem, newRootDesc, tables, ccc, [])
            );
            return newItem;
        });
        return updatesForPropertyValue(pdc.kind, descr.property, newDesc, newItems, undefined);
    }

    public async duplicate(
        descr: PropertyDescriptor,
        pdc: ArrayPropertyDescriptorCase,
        propertyDescription: PropertyDescription,
        containingDescription: Description,
        rootDesc: Description,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<Partial<Description>> {
        const items = getArrayProperty<Description>(propertyDescription);
        if (items === undefined) return {};

        const newItems = await arrayMapSync(items, item =>
            duplicateDescription(
                item,
                rootDesc,
                pdc.properties,
                tables,
                iccc,
                mutatingScreenKind,
                screensCreated,
                appFacilities
            )
        );

        return updatesForPropertyValue(pdc.kind, descr.property, containingDescription, newItems, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Array", icon: undefined, label: undefined };
    }
}

class ZapDescriptionHandler extends BaseDescriptionHandler<ZapPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Zap);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: ZapPropertyDescriptorCase
    ): Partial<Description> | undefined {
        return updatesForPropertyValue(pdc.kind, descr.property, desc, undefined, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Zap", icon: undefined, label: undefined };
    }
}

class WebhookDescriptionHandler extends BaseDescriptionHandler<WebhookPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Webhook);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: WebhookPropertyDescriptorCase
    ): Partial<Description> | undefined {
        return updatesForPropertyValue(pdc.kind, descr.property, desc, undefined, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Webhook", icon: undefined, label: undefined };
    }
}

class IconDescriptionHandler extends BaseDescriptionHandler<IconPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Icon);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: IconPropertyDescriptorCase
    ): Partial<Description> | undefined {
        return updatesForPropertyValue(pdc.kind, descr.property, desc, undefined, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Icon", icon: undefined, label: undefined };
    }
}

class EmojiDescriptionHandler extends BaseDescriptionHandler<EmojiPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Emoji);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: EmojiPropertyDescriptorCase,
        _avoidDirectColumns: Set<TableColumn>,
        _avoidEditedColumns: Set<TableColumn>,
        _avoidIndirectColumns: Set<TableColumn>,
        _tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _ccc: AppDescriptionContext,
        populationMode: PopulationMode
    ): Partial<Description> | undefined {
        let value: string | undefined;
        if (populationMode === PopulationMode.Default) {
            value = pdc.defaultEmoji;
        }
        return updatesForPropertyValue(pdc.kind, descr.property, desc, value, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Emoji", icon: undefined, label: undefined };
    }
}

class ActionDescriptionHandler extends BaseDescriptionHandler<ActionPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Action);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: ActionPropertyDescriptorCase,
        _avoidDirectColumns: Set<TableColumn>,
        _avoidEditedColumns: Set<TableColumn>,
        _avoidIndirectColumns: Set<TableColumn>,
        tables: InputOutputTables,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _ccc: AppDescriptionContext,
        populationMode: PopulationMode
    ): Partial<Description> | undefined {
        let action: ActionDescription | undefined;
        // We don't auto-populate the action if we're rewriting or the user
        // chose to unset it, unless the action is actually required.
        if (pdc.required === true || populationMode === PopulationMode.Default) {
            action = makeDefaultAction(pdc, tables, desc);
        }
        return updatesForPropertyValue(pdc.kind, descr.property, desc, action, undefined);
    }

    public getEditedColumns(
        _pdc: ActionPropertyDescriptorCase,
        pd: PropertyDescription,
        _rootDesc: Description,
        env: StaticActionContext<AppDescriptionContext>,
        withActions: boolean
    ): EditedColumnsAndTables {
        if (!withActions) return emptyEditedColumnsAndTables;

        const v = getActionProperty(pd);
        if (v === undefined) return emptyEditedColumnsAndTables;

        const handler = handlerForActionKind(v.kind);
        return handler.getEditedColumns(v, env) ?? emptyEditedColumnsAndTables;
    }

    public rewriteWithCase(
        descr: PropertyDescriptor,
        pdc: ActionPropertyDescriptorCase,
        propertyDescription: PropertyDescription,
        newRootDesc: Description,
        newDesc: Description,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined,
        ignoreRequired: boolean,
        ccc: RewritingComponentConfiguratorContext,
        populationMode: PopulationMode
    ): Partial<Description> | undefined {
        const action = getActionProperty(propertyDescription);
        if (action === undefined) return {};

        const actionTables =
            definedMap(pdc.getIndirectTable?.(tables, newRootDesc, newDesc, ccc, [])?.table, makeInputOutputTables) ??
            tables;

        if (action.kind === ActionKind.Compound) {
            const compound = action as CompoundActionDescription;
            const actionID = getCompoundActionProperty(compound.actionID);
            if (actionID === undefined) return undefined;

            const builderAction = ccc.builderActions.get(actionID);
            // If we can't find the action, we keep it, just to be safe.
            if (builderAction === undefined) return {};

            const perApp = builderAction.perApp[ccc.appID];
            // Same here, playing it safe.
            if (perApp === undefined) return {};

            if (areTableNamesEqual(getTableName(actionTables.input), perApp.tableName)) {
                return {};
            } else {
                // This is where we remove the action, because the tables don't
                // match anymore.
                // https://github.com/quicktype/glide/issues/17263
                logError("Removing action because the incoming table doesn't match up with the action's table");
                return undefined;
            }
        }

        const handler = handlerForActionKind(action.kind);

        const columnsUsed = makeEmptyColumnsUsedInDescription();

        const newAction = populateDescription(
            d =>
                handler.getDescriptor(d, {
                    context: ccc,
                    tables: actionTables,
                    mutatingScreenKind,
                    isAutomation: false,
                })?.properties,
            populationMode,
            newRootDesc,
            action,
            actionTables,
            ccc,
            true,
            mutatingScreenKind,
            columnsUsed.direct,
            undefined,
            columnsUsed.indirect,
            ignoreRequired
        );
        if (newAction === undefined) return undefined;

        // TODO: Also rewrite the `condition`.  We don't have code for that
        // yet.  When we do, also use it in `TransformsDescriptionHandler`.

        return updatesForPropertyValue(pdc.kind, descr.property, newDesc, newAction, undefined);
    }

    public async duplicate(
        descr: PropertyDescriptor,
        pdc: ActionPropertyDescriptorCase,
        propertyDescription: PropertyDescription,
        containingDescription: Description,
        rootDesc: Description,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<Partial<Description>> {
        const action = getActionProperty(propertyDescription);
        if (action === undefined) return {};

        const actionTables =
            definedMap(pdc.getIndirectTable?.(tables, rootDesc, rootDesc, iccc, [])?.table, makeInputOutputTables) ??
            tables;

        const handler = handlerForActionKind(action.kind);

        const copy = await handler.reuseOrDuplicateAction(
            action,
            rootDesc,
            actionTables,
            iccc,
            mutatingScreenKind,
            screensCreated,
            appFacilities
        );

        return updatesForPropertyValue(pdc.kind, descr.property, containingDescription, copy, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Action", icon: undefined, label: undefined };
    }
}

class CompoundActionDescriptionHandler extends BaseDescriptionHandler<CompoundActionPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.CompoundAction);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: CompoundActionPropertyDescriptorCase
    ): Partial<Description> | undefined {
        return updatesForPropertyValue(pdc.kind, descr.property, desc, undefined, undefined);
    }

    public getEditedColumns(
        _pdc: CompoundActionPropertyDescriptorCase,
        pd: PropertyDescription,
        _rootDesc: Description,
        env: StaticActionContext<AppDescriptionContext>
    ): EditedColumnsAndTables {
        const actionID = getCompoundActionProperty(pd);
        if (actionID === undefined) return emptyEditedColumnsAndTables;

        const compound = env.context.getBuilderAction(actionID);
        if (compound === undefined) return emptyEditedColumnsAndTables;

        let editedColumns: EditedColumnsAndTables = emptyEditedColumnsAndTables;

        function processFlow(flow: FlowActionNode): void {
            for (const a of flow.actions) {
                if (a.kind === ActionNodeKind.Primitive) {
                    const { actionDescription } = a;
                    const handler = handlerForActionKind(actionDescription.kind);
                    const e = handler.getEditedColumns(actionDescription, env);
                    editedColumns = combineEditedColumnsAndTables(editedColumns, e);
                } else if (a.kind === ActionNodeKind.Conditional) {
                    processCompoundActionFlow(a, processFlow);
                } else if (a.kind === ActionNodeKind.Loop) {
                    processFlow(a.flow);
                } else {
                    return assertNever(a);
                }
            }
        }

        // FIXME this should be a single function
        if (compound.action.kind === ActionNodeKind.Conditional) {
            processCompoundActionFlow(compound.action, processFlow);
        } else if (compound.action.kind === ActionNodeKind.AutomationRoot) {
            processFlow(compound.action.flow);
        } else {
            processFlow(compound.action);
        }

        return editedColumns;
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Compound action", icon: undefined, label: undefined };
    }
}

class ConfigurationButtonDescriptionHandler extends BaseDescriptionHandler<ConfigurationButtonPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.ConfigurationButton);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: ConfigurationButtonPropertyDescriptorCase
    ): Partial<Description> | undefined {
        return updatesForPropertyValue(pdc.kind, descr.property, desc, undefined, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Configuration button", icon: undefined, label: undefined };
    }
}

class JSONPathDescriptionHandler extends BaseDescriptionHandler<JSONPathPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.JSONPath);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: JSONPathPropertyDescriptorCase
    ): Partial<Description> | undefined {
        return updatesForPropertyValue(pdc.kind, descr.property, desc, undefined, undefined);
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "JSON Path", icon: undefined, label: undefined };
    }
}

class InlineComputationDescriptionHandler extends BaseDescriptionHandler<InlineComputationPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.InlineComputation);
    }

    public defaultUpdateForPropertyCase(
        descr: PropertyDescriptor,
        _rootDesc: Description,
        desc: Description,
        pdc: InlineComputationPropertyDescriptorCase,
        _avoidDirectColumns: Set<TableColumn>,
        _avoidEditedColumns: Set<TableColumn>,
        _avoidIndirectColumns: Set<TableColumn>,
        _tables: InputOutputTables | undefined,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _adc: AppDescriptionContext,
        populationMode: PopulationMode,
        getDefaultCaption: () => string | undefined
    ): Partial<Description> | undefined {
        const defaultValue = pdc.options.defaultValue;

        if (!isRewritePopulationMode(populationMode) || pdc.required) {
            let stringValue = defaultValue;
            if (stringValue === undefined && pdc.options.isCaption !== undefined) {
                stringValue = getDefaultCaption() ?? pdc.options.isCaption;
            }
            if (stringValue === undefined && pdc.required) {
                stringValue = "";
            }
            return updatesForPropertyValue(PropertyKind.String, descr.property, desc, stringValue, undefined);
        }

        return updatesForPropertyValue(PropertyKind.String, descr.property, desc, defaultValue, undefined);
    }

    public getType() {
        return makePrimitiveType("string");
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return {
            name: "Inline computation",
            label: "Inline computation",
            icon: "componentColumn",
        };
    }
}

class WarningDescriptionHandler extends BaseDescriptionHandler<WarningPropertyDescriptorCase> {
    constructor() {
        super(PropertyKind.Warning);
    }

    public defaultUpdateForPropertyCase(): Partial<Description> | undefined {
        return {};
    }

    public getColumnAssignmentInfo(): ColumnAssignmentInfo {
        return { name: "Warning", icon: undefined, label: undefined };
    }
}

let didInit = false;

export function registerDescriptionHandlers(): void {
    if (didInit) return;
    didInit = true;

    registerDescriptionHandler(new ColumnDescriptionHandler());
    registerDescriptionHandler(new TableDescriptionHandler());
    registerDescriptionHandler(new EnumDescriptionHandler());
    registerDescriptionHandler(new StringDescriptionHandler());
    registerDescriptionHandler(new SecretDescriptionHandler());
    registerDescriptionHandler(new ZapDescriptionHandler());
    registerDescriptionHandler(new WebhookDescriptionHandler());
    registerDescriptionHandler(new NumberDescriptionHandler());
    registerDescriptionHandler(new SwitchDescriptionHandler());
    registerDescriptionHandler(new ConstantDescriptionHandler());
    registerDescriptionHandler(new SpecialValueDescriptionHandler());
    registerDescriptionHandler(new ScreenDescriptionHandler());
    registerDescriptionHandler(new PaymentMethodDescriptionHandler());
    registerDescriptionHandler(new FilterDescriptionHandler());
    registerDescriptionHandler(new FormulaDescriptionHandler());
    registerDescriptionHandler(new TransformsDescriptionHandler());
    registerDescriptionHandler(new SortsDescriptionHandler());
    registerDescriptionHandler(new ArrayDescriptionHandler());
    registerDescriptionHandler(new IconDescriptionHandler());
    registerDescriptionHandler(new EmojiDescriptionHandler());
    registerDescriptionHandler(new ActionDescriptionHandler());
    registerDescriptionHandler(new CompoundActionDescriptionHandler());
    registerDescriptionHandler(new ConfigurationButtonDescriptionHandler());
    registerDescriptionHandler(new TableViewDescriptionHandler());
    registerDescriptionHandler(new JSONPathDescriptionHandler());
    registerDescriptionHandler(new InlineComputationDescriptionHandler());
    registerDescriptionHandler(new WarningDescriptionHandler());
}
