import { DefaultMap } from "@glideapps/ts-necessities";

import { type TableGlideType, sheetNameForTable } from "@glide/type-schema";
import type { MissingTablesAndColumns } from "@glide/common-core/dist/js/Database";

export class MissingTablesAndColumnsAccumulator {
    // We only keep display names for missing tables and columns
    private readonly _missingTables: Set<string> = new Set();
    private readonly _missingColumns: DefaultMap<string, Set<string>> = new DefaultMap(() => new Set());

    public addTable(table: TableGlideType): void {
        this._missingTables.add(sheetNameForTable(table));
    }

    public addColumn(table: TableGlideType, columnName: string): void {
        this._missingColumns.get(sheetNameForTable(table)).add(columnName);
    }

    public getMissing(): MissingTablesAndColumns | undefined {
        if (this._missingTables.size === 0 && this._missingColumns.size === 0) {
            return undefined;
        }

        return {
            tables: Array.from(this._missingTables),
            columns: Array.from(this._missingColumns.entries()).map(([n, cs]) => ({
                table: n,
                columns: Array.from(cs),
            })),
        };
    }
}
