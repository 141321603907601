import type { IconName } from "@glide/common";
import { TextBoxJustify } from "@glide/common-core/dist/js/components/text-box-types";
import {
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getEnumProperty,
} from "@glide/app-description";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { Mood, moodCases } from "@glide/component-utils";
import type { WireAppHintComponent } from "@glide/fluent-components/dist/js/base-components";
import type { WireHintDescription } from "@glide/fluent-components/dist/js/fluent-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type PropertyDescriptor,
    EnumPropertyHandler,
    PropertySection,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";

import { inflateStringProperty, makeSimpleWireRowComponentHydratorConstructor, spreadComponentID } from "../wire/utils";
import { ComponentHandlerBase } from "./handler";

const ComponentKindHint: ComponentKind = "hint";

const expand = (val: string, icon?: IconName) => ({
    value: val,
    label: val,
    icon,
});

const hintMoodPropertyHandler = new EnumPropertyHandler(
    { mood: Mood.Default },
    "Mood",
    "Mood",
    moodCases,
    PropertySection.Design,
    "dropdown"
);

// FIXME: THIS IS A LOT OF COPYPASTA
const defaultJustify = TextBoxJustify.Left;
const textBoxJustifyProperty: PropertyDescriptor = {
    kind: PropertyKind.Enum,
    property: { name: "justify" },
    label: "Align",
    menuLabel: "Align",
    cases: [
        expand(TextBoxJustify.Left, "leftAlign"),
        expand(TextBoxJustify.Center, "centerAlign"),
        expand(TextBoxJustify.Right, "rightAlign"),
        expand(TextBoxJustify.Justify, "justifyAlign"),
    ],
    defaultCaseValue: defaultJustify,
    section: PropertySection.Design,
    visual: "small-images",
};
function getTextBoxJustify(desc: HintComponentDescription): TextBoxJustify {
    return getEnumProperty(desc.justify) ?? defaultJustify;
}

interface HintComponentDescription extends ComponentDescription {
    readonly propertyName: PropertyDescription;
    readonly title?: PropertyDescription;
    readonly text?: PropertyDescription;
    readonly mood: PropertyDescription;
    readonly justify?: PropertyDescription;
}

export class HintComponentHandler extends ComponentHandlerBase<HintComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindHint);
    }

    public get needsColumns(): boolean {
        return false;
    }

    public getDescriptor(
        _desc: HintComponentDescription,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [
            makeTextPropertyDescriptor("title", "Title", "Enter title", true, mutatingScreenKind, {
                searchable: false,
            }),
            makeTextPropertyDescriptor("text", "Text", "Enter hint text", true, mutatingScreenKind, {
                searchable: true,
            }),
            hintMoodPropertyHandler,
            textBoxJustifyProperty,
            ...this.getBasePropertyDescriptors(),
        ];

        return {
            name: "Hint",
            description: "Special text",
            img: "co-hint-text",
            group: "Text",
            helpUrl: getDocURL("hint"),
            properties,
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: HintComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const [titleGetter, titleType] = inflateStringProperty(ib, desc.title, true);
        const [textGetter, textType] = inflateStringProperty(ib, desc.text, true);
        if (titleType === undefined && textType === undefined) return undefined;

        const mood = hintMoodPropertyHandler.getEnum(desc);
        const justify = getTextBoxJustify(desc);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const title = titleGetter(hb) ?? "";
            const text = textGetter(hb) ?? "";
            if (title === "" && text === "") return undefined;

            const component: WireAppHintComponent = {
                kind: WireComponentKind.AppHint,
                ...spreadComponentID(desc.componentID, forBuilder),
                title,
                text,
                mood,
                justify,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(hintDesc: HintComponentDescription, _ccc: AppDescriptionContext): ComponentDescription {
        const hint: WireHintDescription = {
            ...makeEmptyComponentDescription(WireComponentKind.Hint),
            description: hintDesc.text,
            mood: hintDesc.mood,
        };

        return hint;
    }
}
