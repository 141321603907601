import {
    type GlideFC,
    GlideIcon,
    useColorFromSeed,
    useIsWireThemeDark,
    useLocalStorage,
    useAccelerator,
    TailwindThemeProvider,
    getGlideIcon,
} from "@glide/common";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { APP_MODAL_ROOT, UIButtonAppearance, type WireTab } from "@glide/wire";
import chroma from "chroma-js";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useLayer } from "react-laag";
import tw from "twin.macro";
import { motion, AnimatePresence } from "framer-motion";
import { Img } from "../../components/img/img";
import { css, isSmallScreen, styled, Tooltip, useResponsiveSizeClass } from "@glide/common-components";
import { WireButton } from "../../renderers/wire-button/wire-button";
import {
    type UnifiedSideBarProps,
    TabIcon,
    UserProfileMenu,
    getLogoOrIcon,
    useLogoAndTitleDisplayProps,
} from "./nav-bar-common";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { defaultWireAppAccentContextOverlay, mergeTheme } from "@glide/theme";

import { hasOwnProperty } from "@glideapps/ts-necessities";

import { Kbd } from "../kbd";

const ToggleButton = styled(motion.button)`
    color: ${p => p.theme.textContextualPale};

    &:hover {
        color: ${props => props.theme.textContextualBase};

        &.light-system-theme.highlight.page-background-neutral {
            background-color: ${p => p.theme.bgBack};
        }
    }

    &.light-system-theme.highlight,
    &.light-system-theme.neutral {
        &:focus-visible,
        &:hover {
            background-color: ${props => props.theme.n100};
        }

        border-color: ${props => chroma(props.theme.textContextualBase).alpha(0.1).css()};

        &.page-background-highlight {
            background: ${p => p.theme.bgBack};
            &:hover {
                background-color: ${props => chroma(props.theme.bgBack).brighten(0.1).css()};
            }
        }

        &.page-background-neutral {
            background: ${p => p.theme.bgFront};
        }
    }

    &.accent,
    &.dark,
    &.dark.light-system-theme,
    &.dark.dark-system-theme,
    &.dark-system-theme.highlight,
    &.dark-system-theme.neutral {
        border-color: ${props => chroma(props.theme.w10A).css()};
    }

    &.accent {
        background-color: ${props => props.theme.primaryAccentColor};
        &:focus-visible,
        &:hover {
            background-color: ${props => chroma(props.theme.primaryAccentColor).brighten(0.2).css()};
        }
    }

    &.dark {
        background-color: ${props => props.theme.darkAccent};
        &:focus-visible,
        &:hover {
            background-color: ${props => chroma(props.theme.darkAccent).brighten(0.4).css()};
        }
    }

    &.highlight.dark-system-theme {
        &:hover {
            background-color: ${props => chroma(props.theme.bgContainerHighlight).brighten(0.4).css()};
        }
    }

    &.highlight {
        background-color: ${props => props.theme.bgContainerHighlight};
    }

    & .user-controls-accent {
        background-color: ${props => props.theme.primaryAccentColor};
        &:focus-visible,
        &:hover {
            background-color: ${props => chroma(props.theme.primaryAccentColor).brighten(0.2).css()};
        }
    }

    & .user-controls-dark {
        background-color: ${props => props.theme.darkAccent};
        &:focus-visible,
        &:hover {
            background-color: ${props => chroma(props.theme.darkAccent).brighten(0.2).css()};
        }
    }

    & .user-controls-highlight {
        background-color: ${props => props.theme.bgContainerHighlight};
    }
`;
const Style = styled.nav`
    overflow: hidden;
    ${tw`font-medium z-unified-side-bar`}
    @supports (-webkit-touch-callout: none) {
        @media (hover: none) and (pointer: coarse) and (display-mode: standalone) {
            padding-top: var(--safe-area-inset-top, 0);
        }
    }

    color: ${p => p.theme.textContextualPale};

    .menu-item {
        color: ${p => p.theme.textContextualPale};
    }

    &.light-system-theme.highlight,
    &.light-system-theme.neutral {
        &.page-background-highlight {
            background: ${p => p.theme.bgBack};
        }

        &.page-background-neutral {
            background: ${p => p.theme.bgFront};
        }
        box-shadow: inset -1px 0 1px ${p => p.theme.n200A};

        .menu-item:hover {
            background-color: ${p => p.theme.n50A};
        }
        .menu-item:focus-visible {
            outline: none;
            background-color: ${p => p.theme.n50A};
        }

        .menu-item.active {
            background-color: ${p => p.theme.n100A};
            color: ${p => p.theme.textContextualDark};
        }
    }

    &.accent,
    &.dark,
    &.dark.light-system-theme,
    &.dark.dark-system-theme,
    &.dark-system-theme.highlight,
    &.dark-system-theme.neutral {
        box-shadow: inset -1px 0 0 ${p => p.theme.w05A};

        .menu-item:hover {
            background-color: ${p => p.theme.w05A};
        }
        .menu-item:focus-visible {
            background-color: ${p => p.theme.w05A};
        }

        .menu-item.active {
            background-color: ${p => p.theme.w10A};
            color: ${p => p.theme.textContextualDark};
        }
    }

    &.accent {
        background: ${p => p.theme.primaryAccentColor};
    }

    &.dark {
        background-color: ${p => p.theme.darkAccent};
        color: ${p => p.theme.n0};
    }

    &.highlight {
        background: ${p => p.theme.bgContainerHighlight};
    }

    & .user-controls-accent {
        background: ${p => p.theme.primaryAccentColor};
    }

    & .user-controls-dark {
        background-color: ${p => p.theme.darkAccent};
    }

    & .user-controls-highlight {
        background: ${p => p.theme.bgContainerHighlight};
    }

    &.light-system-theme.page-background-highlight .user-controls-highlight {
        background: ${p => p.theme.bgBack};
    }

    &.light-system-theme.page-background-neutral .user-controls-highlight {
        background: ${p => p.theme.bgFront};
    }
`;

function NavItem(props: { t: WireTab; openSidebar: boolean; onClick: () => void; index: number }) {
    const theme = useWireAppTheme();
    const buttonRef = useRef<HTMLButtonElement>(null);

    return (
        <li tw={"flex w-full shrink-0"}>
            <button
                ref={buttonRef}
                type={"button"}
                onClick={props.onClick}
                className={classNames(props.t.isActive && "active", "menu-item")}
                tw="flex gap-x-2 items-center px-3 w-full h-9 text-base font-medium rounded-lg transition duration-75 cursor-pointer hover:bg-w05A shrink-0">
                {(theme.showIconsInNavBar === true || !props.openSidebar) && <TabIcon icon={props.t.icon} />}
                <AnimatePresence initial={false}>
                    {props.openSidebar ? (
                        <motion.span
                            tw="block relative ml-1 whitespace-nowrap shrink-0"
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.15, ease: [0.69, 0.26, 0, 1] }}
                            exit={{ opacity: 0, x: -10 }}>
                            {props.t.title}
                        </motion.span>
                    ) : null}
                </AnimatePresence>
            </button>
            {props.index > 9 ? null : props.openSidebar ? null : (
                <Tooltip target={buttonRef} position="right">
                    {props.t.title}{" "}
                    <Kbd>
                        <svg
                            className="flex-shrink-0 size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <title>Control</title>
                            <path d="m18 15-6-6-6 6" />
                        </svg>
                        {props.index}
                    </Kbd>
                </Tooltip>
            )}
        </li>
    );
}

const SIDEBAR_OPEN_STORAGE_KEY = "SIDEBAR_OPEN";

export const UnifiedSideBar: GlideFC<UnifiedSideBarProps> = p => {
    const {
        tabs,
        title,
        className,
        onSelectionChange,
        onSignInPressed,
        onUserButtonPressed,
        onSignOutPressed,
        signedInUserEmail,
        signedInUserName,
        isUserProfileActive,
        signedInUserImage,
        navigateToRoot,
        logo,
        iconImage,
        appID,
    } = p;

    const userMenuRef = useRef<HTMLDivElement>(null);
    const [openSidebar, setOpenSidebar] = useLocalStorage(SIDEBAR_OPEN_STORAGE_KEY, true);

    const username = signedInUserName ?? signedInUserEmail ?? "?";

    const colorPreview = useColorFromSeed(username);
    const background = chroma(colorPreview).brighten(3).css();

    const theme = useWireAppTheme();

    const [showUserMenu, setShowUserMenu] = React.useState(false);

    const { layerProps, triggerProps, renderLayer } = useLayer({
        isOpen: showUserMenu,
        auto: true,
        triggerOffset: 4,
        placement: "top-start",
        onOutsideClick: () => setShowUserMenu(false),
        container: APP_MODAL_ROOT,
    });

    const isWireThemeDark = useIsWireThemeDark(theme.pageEnvironment);

    const { showLogo, showIcon, showTitle } = useLogoAndTitleDisplayProps(logo, theme, iconImage);

    const allTabs = [...tabs.primary, ...tabs.secondary].slice(0, 9);

    useAccelerator(
        ["ctrl+\\"],
        () => {
            setOpenSidebar(open => !open);
        },
        true
    );

    const sizeClass = useResponsiveSizeClass();
    const isMobileOrTablet = isSmallScreen(sizeClass) || sizeClass === "md";

    useAccelerator(["ctrl+1", "ctrl+2", "ctrl+3", "ctrl+4", "ctrl+5", "ctrl+6", "ctrl+7", "ctrl+8", "ctrl+9"], e => {
        const index = Number.parseInt(e.key.slice(-1)) - 1;
        if (allTabs[index] !== undefined) {
            onSelectionChange?.(allTabs[index]);
        }
    });

    const isSideBarActuallyOpen = !isMobileOrTablet && openSidebar;

    const logoTitle = (
        <>
            {showIcon ? (
                hasOwnProperty(iconImage, "url") ? (
                    <div tw="overflow-hidden relative w-8 h-8 rounded-lg shrink-0 bg-accent not-last:mr-3">
                        <Img
                            src={massageImageUrl(
                                getLogoOrIcon(showLogo, showIcon, logo, iconImage),
                                {
                                    height: 75,
                                    thumbnail: false,
                                },
                                appID
                            )}
                            tw="object-cover w-full h-full"
                        />
                    </div>
                ) : (
                    <Img
                        src={massageImageUrl(
                            getLogoOrIcon(showLogo, showIcon, logo, iconImage),
                            {
                                height: 75,
                                thumbnail: false,
                            },
                            appID
                        )}
                        tw="max-h-6 [max-width:188px] object-contain not-last:mr-3"
                    />
                )
            ) : null}

            {showLogo ? (
                <Img
                    src={massageImageUrl(
                        getLogoOrIcon(showLogo, showIcon, logo, iconImage),
                        {
                            height: 75,
                            thumbnail: false,
                        },
                        appID
                    )}
                    tw="max-h-6 [max-width:188px] not-last:mr-2 object-contain"
                />
            ) : null}
            {showTitle && (
                <motion.span
                    tw="leading-tight text-left gp-md:text-lg line-clamp-2"
                    initial={{
                        opacity: 0,
                    }}
                    transition={{
                        duration: !isSideBarActuallyOpen ? 0.0 : 0.15,
                        ease: [0.69, 0.26, 0, 1],
                        delay: !isSideBarActuallyOpen ? 0 : 0.1,
                    }}
                    animate={{
                        opacity: !isSideBarActuallyOpen ? 0 : 1,
                    }}>
                    {title}
                </motion.span>
            )}
        </>
    );

    let newTheme = theme;

    if (theme.pageTheme === "Dark" || theme.pageTheme === "Accent") {
        newTheme = mergeTheme(theme, [defaultWireAppAccentContextOverlay]);
    }

    const toggleSidebarRef = useRef<HTMLButtonElement>(null);
    const [toggleHovered, setToggleHovered] = useState(false);

    return (
        <TailwindThemeProvider theme={newTheme} tw="relative" className={"group"}>
            <motion.div
                className={"toggle-sidebar"}
                animate={{
                    right: isSideBarActuallyOpen ? 12 : -4,
                }}
                tw="isolate absolute top-8 z-20 opacity-0 transition duration-150 -translate-y-1/2 group-hover:opacity-100 group-hover:delay-150">
                {isMobileOrTablet ? null : (
                    <ToggleButton
                        onMouseEnter={() => setToggleHovered(true)}
                        onMouseLeave={() => setToggleHovered(false)}
                        ref={toggleSidebarRef}
                        className={classNames(
                            className,
                            theme?.pageBackground?.toLowerCase() === "neutral"
                                ? "page-background-neutral"
                                : "page-background-highlight",
                            (theme.pageTheme ?? "accent").toLowerCase(),
                            isWireThemeDark ? "dark-system-theme" : "light-system-theme"
                        )}
                        tw="rounded-md border border-text-base py-1.5 transition-colors hidden md:block"
                        onClick={() => setOpenSidebar(!openSidebar)}>
                        <motion.span
                            tw="flex justify-center items-center"
                            animate={{
                                rotate: isSideBarActuallyOpen ? 180 : 0,
                            }}
                            transition={{
                                duration: 0.15,
                                ease: [0.69, 0.26, 0, 1],
                            }}>
                            <GlideIcon kind="stroke" icon="st-chevron-right" iconSize={14} />
                        </motion.span>

                        <Tooltip target={toggleSidebarRef} position={"right"} delayMS={500} show={toggleHovered}>
                            {isSideBarActuallyOpen ? "Collapse Sidebar" : "Expand Sidebar"}{" "}
                            <Kbd>
                                <svg
                                    className="flex-shrink-0 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <title>Control</title>
                                    <path d="m18 15-6-6-6 6" />
                                </svg>
                                \
                            </Kbd>
                        </Tooltip>
                    </ToggleButton>
                )}
            </motion.div>

            <motion.div
                animate={{
                    width: isSideBarActuallyOpen ? 64 * 4 : 17 * 4,
                }}
                transition={{
                    duration: 0.15,
                    ease: [0.69, 0.26, 0, 1],
                }}
                tw="overflow-hidden h-full will-change-transform">
                <Style
                    className={classNames(
                        className,
                        theme?.pageBackground?.toLowerCase() === "neutral"
                            ? "page-background-neutral"
                            : "page-background-highlight",
                        (theme.pageTheme ?? "accent").toLowerCase(),
                        isWireThemeDark ? "dark-system-theme" : "light-system-theme"
                    )}
                    tw="flex overflow-y-auto flex-col px-3 w-full h-full shrink-0">
                    <h1
                        onClick={navigateToRoot}
                        className={classNames(navigateToRoot !== undefined ? "active-title" : "", {
                            "no-logo-and-title": theme.logoDisplay === "none",
                            "icon-as-image": showIcon && hasOwnProperty(iconImage, "url"),
                        })}
                        css={css`
                            &.active-title {
                                ${tw`cursor-pointer`}
                            }
                            &.no-logo-and-title {
                                ${tw`h-0 p-0 m-3`}
                            }
                            &.icon-as-image {
                                ${tw`pl-2`}
                            }
                        `}
                        tw="flex relative justify-start items-center pl-3 my-4 h-8 text-lg font-semibold text-center text-text-contextual-dark">
                        {logoTitle}

                        {showLogo || showIcon ? null : (
                            <motion.span
                                initial={{
                                    opacity: 0,
                                }}
                                transition={{
                                    duration: isSideBarActuallyOpen ? 0.0 : 0.15,
                                    ease: [0.69, 0.26, 0, 1],
                                    delay: isSideBarActuallyOpen ? 0 : 0.1,
                                }}
                                animate={{
                                    opacity: isSideBarActuallyOpen ? 0 : 1,
                                }}
                                tw="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                {showTitle ? title.at(0) : null}
                            </motion.span>
                        )}
                    </h1>

                    <ul className="main-nav" tw="flex overflow-y-auto overflow-x-hidden flex-col gap-y-px grow">
                        {tabs.primary.map((t, index) => {
                            return (
                                <NavItem
                                    key={`primary-${index}`}
                                    index={index + 1}
                                    t={t}
                                    openSidebar={isSideBarActuallyOpen}
                                    onClick={() => onSelectionChange?.(t)}
                                />
                            );
                        })}

                        {tabs.secondary.length > 0 ? (
                            <>
                                {tabs.primary.length > 1 ? (
                                    <hr tw="mx-auto my-2 w-11/12 h-px border-border-base" />
                                ) : null}
                                {tabs.secondary.map((t, index) => {
                                    return (
                                        <NavItem
                                            key={`secondary-${index}`}
                                            t={t}
                                            index={index + 1 + tabs.primary.length}
                                            openSidebar={isSideBarActuallyOpen}
                                            onClick={() => onSelectionChange?.(t)}
                                        />
                                    );
                                })}
                            </>
                        ) : null}
                    </ul>
                    <div
                        tw="flex sticky bottom-0 justify-start pb-3 shrink-0"
                        className={`user-controls-${(theme.pageTheme ?? "accent").toLowerCase()}`}>
                        <>
                            {onSignInPressed !== undefined && (
                                <WireButton
                                    onClick={onSignInPressed}
                                    appearance={UIButtonAppearance.Bordered}
                                    iconOnly={!openSidebar}
                                    iconName={!openSidebar ? getGlideIcon("st-user") : undefined}
                                    tw="shrink-0 grow">
                                    {getLocalizedString("signIn", AppKind.Page)}
                                </WireButton>
                            )}
                        </>
                        {onUserButtonPressed !== undefined && (
                            <>
                                <button
                                    {...triggerProps}
                                    className="menu-item"
                                    onClick={() => setShowUserMenu(cv => !cv)}
                                    tw="flex overflow-hidden flex-1 items-center p-2 rounded-lg">
                                    <div
                                        ref={userMenuRef}
                                        className={classNames(isUserProfileActive && "force-outline")}
                                        style={{
                                            color: colorPreview,
                                            backgroundColor: background,
                                        }}
                                        css={css`
                                            &.force-outline {
                                                ${tw`ring-2 ring-white`}
                                            }
                                        `}
                                        tw="flex relative capitalize justify-center items-center text-base w-6 h-6
                                    shrink-0 cursor-pointer rounded-full mr-2 shadow-none transform transition
                                    after:(content-[''] absolute -inset-0.5 rounded-full pointer-events-none
                                    transition-shadow) focus-visible:after:(ring-2 ring-white) hover:(shadow-lg-soft
                                    scale-105)
                                    gp-sm:(w-7 h-7)">
                                        <div tw="flex overflow-hidden absolute inset-0 justify-center items-center leading-none capitalize rounded-full">
                                            {username[0]}
                                            {signedInUserImage !== undefined && (
                                                <Img
                                                    tw="object-cover absolute inset-0 w-full h-full"
                                                    src={signedInUserImage}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div tw="overflow-hidden min-w-0 text-base font-medium overflow-ellipsis whitespace-nowrap shrink">
                                        {username}
                                    </div>
                                </button>
                                {isSideBarActuallyOpen ? null : (
                                    <Tooltip target={userMenuRef} position={"right"}>
                                        {username}
                                    </Tooltip>
                                )}

                                {showUserMenu &&
                                    renderLayer(
                                        <UserProfileMenu
                                            layerProps={layerProps}
                                            onClose={() => setShowUserMenu(false)}
                                            onUserButtonPressed={onUserButtonPressed}
                                            onSignOutPressed={onSignOutPressed}
                                        />
                                    )}
                            </>
                        )}
                    </div>
                </Style>
            </motion.div>
        </TailwindThemeProvider>
    );
};
