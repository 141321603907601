import { assertNever } from "@glideapps/ts-necessities";
import type { PrimitiveValue } from "@glide/computation-model-types";
import {
    asJSONValue,
    asMaybeDate,
    asMaybeNumber,
    asMaybeString,
} from "@glide/common-core/dist/js/computation-model/data";
import { formatDuration } from "@glide/common-core/dist/js/format-duration";
import { formatJSON } from "@glide/common-core/dist/js/format-json";
import { formatNumberWithSpecification } from "./format-number";
import { type ValueFormatSpecification, ValueFormatKind } from "@glide/formula-specifications";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";

export function formatValueWithSpecification(
    spec: ValueFormatSpecification | undefined,
    v: PrimitiveValue
): string | undefined {
    if (v === undefined) return undefined;
    if (spec === undefined) {
        return asMaybeString(v);
    }

    // https://github.com/glideapps/glide/issues/28013
    const removeUTCMarker = !getFeatureSetting("keepUTCMarkerWhenParsingDateStrings");

    switch (spec.kind) {
        case ValueFormatKind.Number:
            const n = asMaybeNumber(v);
            if (n === undefined) return undefined;
            return formatNumberWithSpecification(spec, n);

        case ValueFormatKind.DateTime:
            const dt = asMaybeDate(v, removeUTCMarker);
            if (dt === undefined) return undefined;
            return dt.formatWithSpecification(spec, spec.timeZone);

        case ValueFormatKind.Duration:
            const d = asMaybeNumber(v);
            if (d === undefined) return undefined;
            return formatDuration(d);
        case ValueFormatKind.JSON:
            const jsonValue = asJSONValue(v);
            if (jsonValue === undefined) return undefined;
            return formatJSON(jsonValue);

        default:
            return assertNever(spec);
    }
}
