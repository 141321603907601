import {
    SyntheticColumnKind,
    decomposeSingleRelationLookup,
    ColumnOrValueKind,
    containingScreenContextName,
    decomposeAll,
} from "@glide/formula-specifications";
import {
    type TableName,
    type UniversalTableName,
    areTableNamesEqual,
    type ColumnType,
    type Formula,
    type TableAndColumn,
    type TableColumn,
    type TableGlideType,
    type TypeSchema,
    SourceColumnKind,
    FormulaKind,
    canBeRowIDColumn,
    decomposeRelationType,
    getEmailOwnersColumnNames,
    getTableColumn,
    getTableName,
    getTableRefTableName,
    isComputedColumn,
    isDataSourceColumn,
    isNativeTable,
    isPrimitiveType,
    makePrimitiveType,
    makeTypeSchema,
    type UserProfileTableInfo,
    type ApplyColumnFormatFormula,
    type ArrayContainsFormula,
    type AssignVariablesFormula,
    type ConstructURLFormula,
    type FilterRowsFormula,
    type FilterSortLimitFormula,
    type FindRowFormula,
    type FormatDateTimeFormula,
    type FormatDurationFormula,
    type FormatNumberFixedFormula,
    type GenerateImageFormula,
    type GeocodeAddressFormula,
    type GetColumnFormula,
    type GetNthFormula,
    type GetTableRowsFormula,
    type GetVariableFormula,
    type IfThenElseFormula,
    type IsInRangeFormula,
    type JoinStringsFormula,
    type LeftRightFormula,
    type MakeArrayFormula,
    type MapRowsFormula,
    type PluginComputationFormula,
    type RandomPickFormula,
    type ReduceFormula,
    type ReduceToMemberByFormula,
    type SplitStringFormula,
    type StartOrEndOfDayFormula,
    type TextTemplateFormula,
    type UnaryMathFormula,
    type UserAPIFetchFormula,
    type WithFormula,
    type WithUserEnteredTextFormula,
    type YesCodeFormula,
    type SourceMetadata,
    getSourceMetadataFlags,
    isBigTableOrExternal,
    type SchemaInspector,
    type GetActionNodeOutputFormula,
    isRelationType,
} from "@glide/type-schema";
import { mapFilterUndefined, assert, DefaultMap, definedMap } from "@glideapps/ts-necessities";
import {
    memoizeFunction,
    ArraySet,
    areSetsEqual,
    isArray,
    logInfo,
    logError,
    replaceArrayItem,
    truthify,
} from "@glide/support";
import { doesTableSupportRollups } from "@glide/common-core/dist/js/schema-properties";
import { resolveSourceColumn } from "@glide/function-utils";
import { walkDescriptionUntyped } from "@glide/app-description";
import { getCycleNodesInGraph, getCyclesInGraph, makeGraphFromEdges } from "@glideapps/graphs";
import deepEqual from "deep-equal";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { makeSimpleSchemaInspector } from "./components/simple-ccc";
import { areColumnTypesEqualExceptPrimitives, compareSchemas } from "./description-utils";
import { compileFormula } from "./formulas";
import { canAddRowIDColumn } from "./schema-utils";
import type { TypeForActionNodeOutputGetter } from "./static-context";
import type { PriorStep } from "./prior-step";

export function makeTypeForComputation(
    schema: SchemaInspector,
    sourceTable: TableGlideType | undefined,
    formula: Formula,
    getTypeForActionNodeOutput: TypeForActionNodeOutputGetter | undefined
): ColumnType | readonly string[] {
    const compileResult = compileFormula(schema, sourceTable, formula, getTypeForActionNodeOutput);
    if (!compileResult.success) {
        return [...compileResult.errors, ...compileResult.issues];
    }
    if (compileResult.type === undefined) {
        return makePrimitiveType("string");
    } else {
        return compileResult.type;
    }
}

// This has to return `schema` if there are no changes, i.e. if everything
// was correct.
export function sanitizeSchemaAfterChanges(
    schema: TypeSchema,
    sourceMetadata: readonly SourceMetadata[] | undefined,
    userProfileTableInfo: UserProfileTableInfo | undefined
): TypeSchema {
    // We apply changes until nothing changes anymore.
    // We do this because we might have dependencies between computed columns.
    for (;;) {
        let didChange = false;

        // We change `schema` below, so we need to make a new inspector here
        // on every iteration.
        const schemaInspector = makeSimpleSchemaInspector(schema, sourceMetadata, userProfileTableInfo);

        // eslint-disable-next-line no-loop-func
        const tables = schema.tables.map(table => {
            const columns = mapFilterUndefined(table.columns, column => {
                const { formula } = column;
                if (formula === undefined) return column;

                const type = makeTypeForComputation(schemaInspector, table, formula, undefined);
                if (isArray(type)) {
                    // This is an error, but we're using logInfo intentionally:
                    // unless you're running something via the CLI or have
                    // enabled debug logging, we really don't want this
                    // spamming up ou logs.
                    logInfo(type);
                    // This is where we used to delete columns.  We don't do this
                    // anymore because it's too disruptive.
                    return column;
                }

                // ##preserveUniversalTableNames:
                // We assume that compiling a column will give us the same
                // table name in its type as the column declares, so we can
                // just do a deep equal.
                if (deepEqual(column.type, type, { strict: true })) {
                    return column;
                }

                didChange = true;
                return { ...column, type };
            });
            const uniqueColumns = uniqBy(columns, c => c.name);
            if (columns.length !== uniqueColumns.length) {
                didChange = true;
            }

            // Some old apps have duplicate column names
            let result = { ...table, columns: uniqueColumns };

            const { rowIDColumn } = result;
            const emailOwnersColumns = getEmailOwnersColumnNames(result);
            const existingEmailOwnersColumns = emailOwnersColumns.filter(c => getTableColumn(result, c) !== undefined);
            if (!areSetsEqual(emailOwnersColumns, existingEmailOwnersColumns)) {
                result = { ...result, emailOwnersColumn: existingEmailOwnersColumns };
                didChange = true;
            }
            if (rowIDColumn !== undefined && getTableColumn(result, rowIDColumn) === undefined) {
                result = { ...result, rowIDColumn: undefined };
                didChange = true;
            }

            return result;
        });

        if (didChange) {
            schema = makeTypeSchema(tables);
            continue;
        }

        return schema;
    }
}

type ColumnsUsedInFormula = [
    columnsUsed: ReadonlyMap<TableGlideType, ReadonlySet<string>>,
    tablesNamesUsed: ReadonlySet<UniversalTableName>,
    userProfileUsed: boolean
];

export function getColumnsUsedInFormula(
    schema: SchemaInspector,
    contextTableName: TableName | undefined,
    containingScreenTableName: TableName | undefined,
    rootFormula: Formula,
    priorSteps: readonly PriorStep[]
): ColumnsUsedInFormula {
    const columnsUsed = new DefaultMap<TableGlideType, Set<string>>(() => new Set());
    const tableNamesUsed = new ArraySet<UniversalTableName>(areTableNamesEqual);
    let userProfileUsed = false;

    const rootContextTable = schema.findTable(contextTableName);
    const containingScreenTable = schema.findTable(containingScreenTableName);

    function findTable(tn: UniversalTableName | undefined) {
        if (tn === undefined) return undefined;

        tableNamesUsed.add(tn);
        return schema.findTable(tn);
    }

    function addUsed(
        formula: Formula | undefined,
        contextTable: TableGlideType | undefined,
        ctxs: ReadonlyMap<string, TableGlideType>,
        vars: ReadonlyMap<string, TableGlideType>
    ): TableGlideType | undefined {
        if (formula === undefined) return undefined;

        function recur(f: Formula) {
            return addUsed(f, contextTable, ctxs, vars);
        }

        function addContext(name: string, table: TableGlideType | undefined) {
            if (table === undefined) return ctxs;
            return new Map([...ctxs, [name, table]]);
        }

        switch (formula.kind) {
            case FormulaKind.FilterRows:
            case FormulaKind.FindRow: {
                const f = formula as FilterRowsFormula | FindRowFormula;
                const c = recur(f.rows);
                addUsed(f.predicate, contextTable, addContext(f.predicateContextName, c), vars);
                return c;
            }
            case FormulaKind.MapRows: {
                const f = formula as MapRowsFormula;
                const c = recur(f.rows);
                addUsed(f.function, contextTable, addContext(f.functionContextName, c), vars);
                break;
            }
            case FormulaKind.Reduce: {
                const f = formula as ReduceFormula;
                const c = recur(f.rows);
                addUsed(f.value, contextTable, addContext(f.valueContextName, c), vars);
                break;
            }
            case FormulaKind.ReduceToMemberBy: {
                const f = formula as ReduceToMemberByFormula;
                const c = recur(f.rows);
                const nctxs = addContext(f.valueContextName, c);
                addUsed(f.key, contextTable, nctxs, vars);
                return addUsed(f.value, contextTable, nctxs, vars);
            }
            case FormulaKind.JoinStrings: {
                const f = formula as JoinStringsFormula;
                const c = recur(f.rows);
                addUsed(f.value, contextTable, addContext(f.valueContextName, c), vars);
                recur(f.separator);
                break;
            }
            case FormulaKind.SplitString: {
                const f = formula as SplitStringFormula;
                recur(f.string);
                recur(f.separator);
                break;
            }
            case FormulaKind.IfThenElse: {
                const f = formula as IfThenElseFormula;
                recur(f.condition);
                const u = recur(f.consequent);
                const v = definedMap(f.alternative, a => recur(a));
                if (u === v) {
                    return u;
                }
                break;
            }
            case FormulaKind.CompareValues:
            case FormulaKind.ArraysOverlap:
            case FormulaKind.BinaryMath:
            case FormulaKind.And:
            case FormulaKind.Or:
            case FormulaKind.GeoDistance: {
                const f = formula as LeftRightFormula;
                recur(f.left);
                recur(f.right);
                break;
            }
            case FormulaKind.MakeArray: {
                const f = formula as MakeArrayFormula;
                for (const i of f.items) {
                    recur(i);
                }
                break;
            }
            case FormulaKind.GetColumn: {
                const f = formula as GetColumnFormula;
                const u = f.contextName === undefined ? contextTable : ctxs.get(f.contextName);
                if (u !== undefined) {
                    const c = getTableColumn(u, f.column);
                    if (c !== undefined) {
                        columnsUsed.get(u).add(f.column);
                        const tableRef = decomposeRelationType(c.type)?.tableRef;
                        if (tableRef !== undefined) {
                            return findTable(getTableRefTableName(tableRef));
                        }
                    }
                }
                break;
            }
            case FormulaKind.GetActionNodeOutput: {
                const f = formula as GetActionNodeOutputFormula;
                const actionNodeKey = f.actionNodeKey;
                const outputName = f.outputName;
                const columnInRow = f.columnInRow;

                const priorStep = priorSteps.find(a => a.node.node.key === actionNodeKey);
                if (priorStep === undefined) return undefined;

                const output = priorStep.outputs.find(o => o.name === outputName);
                if (output === undefined) return undefined;

                if (!isRelationType(output.type)) return undefined;
                const priorStepTableRef = decomposeRelationType(output.type)?.tableRef;
                const table = schema.findTable(priorStepTableRef);

                if (table === undefined) return undefined;
                if (columnInRow === undefined) return table;

                const column = getTableColumn(table, columnInRow);
                if (column === undefined) return table;

                columnsUsed.get(table).add(columnInRow);

                if (isRelationType(column.type)) {
                    const columnTableRef = decomposeRelationType(column.type)?.tableRef;
                    const columnTable = schema.findTable(columnTableRef);
                    if (columnTable !== undefined) return columnTable;
                }

                return table;
            }
            case FormulaKind.TextTemplate: {
                const f = formula as TextTemplateFormula;
                recur(f.template);
                for (const { pattern, replacement } of f.replacements) {
                    recur(pattern);
                    recur(replacement);
                }
                break;
            }
            case FormulaKind.ArrayContains: {
                const f = formula as ArrayContainsFormula;
                recur(f.array);
                recur(f.item);
                break;
            }
            case FormulaKind.CheckValue:
            case FormulaKind.Not:
            case FormulaKind.ConvertToType:
            case FormulaKind.ApplyColumnFormat:
                recur((formula as ApplyColumnFormatFormula).value);
                break;
            case FormulaKind.StartOfDay:
            case FormulaKind.EndOfDay:
                recur((formula as StartOrEndOfDayFormula).dateTime);
                break;
            case FormulaKind.With: {
                const f = formula as WithFormula;
                const u = recur(f.context);
                return addUsed(f.value, contextTable, addContext(f.contextName, u), vars);
            }
            case FormulaKind.GetNth:
            case FormulaKind.GetNthLast: {
                const f = formula as GetNthFormula;
                const u = recur(f.array);
                recur(f.index);
                return u;
            }
            case FormulaKind.RandomPick:
                return recur((formula as RandomPickFormula).array);
            case FormulaKind.IsInRange: {
                const f = formula as IsInRangeFormula;
                recur(f.value);
                recur(f.start);
                recur(f.end);
                break;
            }
            case FormulaKind.AssignVariables: {
                const f = formula as AssignVariablesFormula;
                const newVars = new Map(vars);
                for (const [name, rhs] of f.assignments) {
                    const u = addUsed(rhs, contextTable, ctxs, newVars);
                    if (u !== undefined) {
                        newVars.set(name, u);
                    }
                }
                return addUsed(f.body, contextTable, ctxs, newVars);
            }
            case FormulaKind.Random:
                break;
            case FormulaKind.UnaryMath:
                recur((formula as UnaryMathFormula).operand);
                break;
            case FormulaKind.Constant:
            case FormulaKind.Empty:
            case FormulaKind.SpecialValue:
            case FormulaKind.CurrentLocation:
            case FormulaKind.GetContext:
                break;
            case FormulaKind.GetVariable:
                return vars.get((formula as GetVariableFormula).name);
            case FormulaKind.GetTableRows:
                return findTable((formula as GetTableRowsFormula).table);
            case FormulaKind.GetUserProfileRow:
                userProfileUsed = true;
                return findTable(schema.userProfileTableInfo?.tableName);
            case FormulaKind.WithUserEnteredText:
                return recur((formula as WithUserEnteredTextFormula).formula);
            case FormulaKind.FormatNumberFixed: {
                const f = formula as FormatNumberFixedFormula;
                recur(f.value);
                recur(f.decimalsAfterPoint);
                recur(f.groupSeparator);
                if (f.currency !== undefined) {
                    recur(f.currency);
                }
                break;
            }
            case FormulaKind.FormatDateTime: {
                const f = formula as FormatDateTimeFormula;
                recur(f.value);
                if (f.dateFormat !== undefined) {
                    recur(f.dateFormat);
                }
                if (f.timeFormat !== undefined) {
                    recur(f.timeFormat);
                }
                break;
            }
            case FormulaKind.FormatDuration: {
                const f = formula as FormatDurationFormula;
                recur(f.value);
                break;
            }
            case FormulaKind.GeocodeAddress:
                recur((formula as GeocodeAddressFormula).address);
                break;
            case FormulaKind.GenerateImage: {
                const f = formula as GenerateImageFormula;
                recur(f.input);
                recur(f.imageKind);
                break;
            }
            case FormulaKind.UserAPIFetch: {
                const f = formula as UserAPIFetchFormula;
                recur(f.webhookID);
                for (const { name, value } of f.params) {
                    recur(name);
                    recur(value);
                }
                break;
            }
            case FormulaKind.ConstructURL: {
                const f = formula as ConstructURLFormula;
                for (const { name, value } of f.params) {
                    recur(name);
                    recur(value);
                }
                recur(f.scheme);
                recur(f.host);
                recur(f.path);
                break;
            }
            case FormulaKind.YesCode: {
                const f = formula as YesCodeFormula;
                for (const { name, value } of f.params) {
                    recur(name);
                    recur(value);
                }
                recur(f.url);
                break;
            }
            case FormulaKind.PluginComputation: {
                const f = formula as PluginComputationFormula;
                recur(f.pluginID);
                recur(f.computationID);
                recur(f.resultName);
                walkDescriptionUntyped(f.parameters ?? {}, {
                    visitColumn(sc) {
                        if (sc.kind === SourceColumnKind.UserProfile) {
                            userProfileUsed = true;
                        }

                        const resolved = resolveSourceColumn(
                            schema,
                            sc,
                            contextTable,
                            containingScreenTable,
                            // FIXME: Source columns can refer to columns in
                            // rows coming from action outputs, which means
                            // that we need to pass `actionNodesInScope` here.
                            undefined
                        );
                        if (resolved?.tableAndColumn === undefined) return undefined;

                        columnsUsed.get(resolved.tableAndColumn.table).add(resolved.tableAndColumn.column.name);
                        return undefined;
                    },
                });
                break;
            }
            case FormulaKind.FilterSortLimit: {
                const f = formula as FilterSortLimitFormula;
                const rowsTable = addUsed(f.rows, contextTable, ctxs, vars);
                // Inside the predicate and sort the context is different - the
                // "current" context is referred to as "containing screen",
                // and the regular context is the table type of `rows`.
                const newContext = addContext(containingScreenContextName, contextTable);
                addUsed(f.predicate, rowsTable, newContext, vars);
                for (const ordering of f.orderings) {
                    addUsed(ordering.sortKey, rowsTable, newContext, vars);
                }
                break;
            }
            default:
                logError(`Unknown formula kind ${formula.kind}`);
                return undefined;
        }
        return undefined;
    }

    const rootContexts = new Map<string, TableGlideType>();
    if (containingScreenTable !== undefined) {
        rootContexts.set(containingScreenContextName, containingScreenTable);
    }
    addUsed(rootFormula, rootContextTable, rootContexts, new Map());
    return [columnsUsed, tableNamesUsed, userProfileUsed];
}

export function getColumnsUsedInColumn(
    schema: SchemaInspector,
    contextTableName: TableName,
    column: TableColumn
): ColumnsUsedInFormula {
    const resultColumns = new DefaultMap<TableGlideType, Set<string>>(() => new Set());
    const resultTables = new ArraySet<UniversalTableName>(areTableNamesEqual);
    let userProfileUsed = false;
    function add(columnsUsed: ColumnsUsedInFormula) {
        for (const [table, columnNames] of columnsUsed[0]) {
            const set = resultColumns.get(table);
            for (const cn of columnNames) {
                set.add(cn);
            }
        }
        for (const tableName of columnsUsed[1]) {
            resultTables.add(tableName);
        }
        if (columnsUsed[2]) {
            userProfileUsed = true;
        }
    }

    if (column.formula !== undefined) {
        add(getColumnsUsedInFormula(schema, contextTableName, undefined, column.formula, []));
    }
    if (column.displayFormula !== undefined) {
        add(getColumnsUsedInFormula(schema, contextTableName, undefined, column.displayFormula, []));
    }

    return [resultColumns, resultTables, userProfileUsed];
}

function makeColumnAdjacencyForSchema(
    schema: SchemaInspector,
    additionalColumn: { tableName: TableName; columnName: string; formula: Formula } | undefined
): ReadonlyMap<TableAndColumn, Set<TableAndColumn>> {
    const tacForColumn = new Map<TableColumn, TableAndColumn>();
    const columnsUsedByColumns = new DefaultMap<TableAndColumn, Set<TableAndColumn>>(() => new Set());

    function getTableAndColumn(t: TableGlideType, c: TableColumn): TableAndColumn {
        let tac = tacForColumn.get(c);
        if (tac === undefined) {
            tac = { table: t, column: c };
            tacForColumn.set(c, tac);
        }
        return tac;
    }

    for (const table of schema.schema.tables) {
        const tableName = getTableName(table);
        for (const column of table.columns) {
            let formula: Formula;
            if (
                additionalColumn !== undefined &&
                areTableNamesEqual(tableName, additionalColumn.tableName) &&
                column.name === additionalColumn.columnName
            ) {
                formula = additionalColumn.formula;
            } else if (isComputedColumn(column)) {
                formula = column.formula;
            } else {
                continue;
            }

            const [used] = getColumnsUsedInFormula(schema, tableName, undefined, formula, []);
            for (const [t, columnNames] of used) {
                for (const columnName of columnNames) {
                    const c = getTableColumn(t, columnName);
                    if (c === undefined) continue;

                    columnsUsedByColumns.get(getTableAndColumn(table, column)).add(getTableAndColumn(t, c));
                }
            }
        }
    }

    return columnsUsedByColumns;
}

export function doesSchemaContainCycles(
    schema: SchemaInspector,
    additionalColumn?: { tableName: TableName; columnName: string; formula: Formula }
): boolean {
    const columnsUsedByColumns = makeColumnAdjacencyForSchema(schema, additionalColumn);
    return getCycleNodesInGraph(makeGraphFromEdges(columnsUsedByColumns)) !== undefined;
}

export type SchemaCycles = readonly (readonly TableAndColumn[])[];

export function getSchemaCycles(
    schema: SchemaInspector,
    additionalColumn?: { tableName: TableName; columnName: string; formula: Formula }
): SchemaCycles | undefined {
    const columnsUsedByColumns = makeColumnAdjacencyForSchema(schema, additionalColumn);
    const graph = makeGraphFromEdges(columnsUsedByColumns);
    const cycleNodes = getCycleNodesInGraph(graph);
    if (cycleNodes === undefined) return undefined;
    return getCyclesInGraph(graph, cycleNodes);
}

export function modifyColumnsInSchema(
    oldSchema: TypeSchema,
    sourceMetadata: readonly SourceMetadata[] | undefined,
    userProfileTableInfo: UserProfileTableInfo | undefined,
    removed: ReadonlyMap<string, readonly string[]>,
    modified: ReadonlyMap<string, readonly TableColumn[]>,
    moved: ReadonlyMap<string, readonly [string, number][]>,
    setProtected: ReadonlyMap<string, readonly [string, boolean][]>,
    rowIDColumns: ReadonlyMap<string, string | undefined>,
    allowDeletingSheetColumns: boolean
): { schema: TypeSchema; errors: readonly string[] } {
    const errors: string[] = [];

    const tables = oldSchema.tables.map(table => {
        const tableName = getTableName(table);
        if (tableName.isSpecial) return table;
        const isNative = sourceMetadata !== undefined && isNativeTable(sourceMetadata, table);
        const isExternalNative =
            sourceMetadata?.find(
                sm =>
                    sm.type === "Native table" &&
                    areTableNamesEqual(tableName, sm.tableName) &&
                    sm.externalSource !== undefined
            ) !== undefined;

        const removedColumns = removed.get(tableName.name) ?? [];
        const modifiedColumns = modified.get(tableName.name) ?? [];
        const setProtectedColumns = setProtected.get(tableName.name) ?? [];

        if (
            removedColumns.length === 0 &&
            modifiedColumns.length === 0 &&
            moved.size === 0 &&
            setProtected.size === 0 &&
            !rowIDColumns.has(tableName.name)
        ) {
            return table;
        }

        const modifiesRowIDColumn = rowIDColumns.has(tableName.name);
        const rowIDColumnName = rowIDColumns.get(tableName.name);

        let newColumns: TableColumn[] = [];
        for (let c of table.columns) {
            if (removedColumns.indexOf(c.name) >= 0) {
                if (isNative && c.name === table.rowIDColumn) {
                    c = { ...c, hidden: true };
                } else {
                    if (allowDeletingSheetColumns || !isDataSourceColumn(c, isNative)) continue;

                    errors.push("Trying to delete sheet columns without permission");
                }
            }

            if (isNative && canAddRowIDColumn(table) && c.name === rowIDColumnName) {
                // We have some native tables around where the row ID column
                // is protected for some kind, in particular one of the Glide
                // Table template tables.
                // https://github.com/quicktype/glide/issues/11239
                c = { ...c, hidden: false, isProtected: false };
            }

            if ((!isNative || isExternalNative) && isDataSourceColumn(c, isNative)) {
                const setProtectedFlag = setProtectedColumns.find(e => e[0] === c.name)?.[1];
                if (setProtectedFlag !== undefined) {
                    c = { ...c, isProtected: setProtectedFlag };
                }
            }

            newColumns.push(c);
        }

        for (const column of modifiedColumns) {
            if (column.hidden === true) {
                errors.push("Cannot make column hidden");
                continue;
            }
            if (isComputedColumn(column) && column.isUserSpecific === true) {
                errors.push("Computed columns cannot be user-specific");
                continue;
            }
            if (column.displayFormula !== undefined && !isPrimitiveType(column.type)) {
                errors.push("Display formulas not possible on non-primitive columns");
                continue;
            }

            const index = newColumns.findIndex(c => c.name === column.name);
            if (index >= 0) {
                const existingColumn = newColumns[index];

                if (
                    !allowDeletingSheetColumns &&
                    (isDataSourceColumn(column, isNative) !== isDataSourceColumn(existingColumn, isNative) ||
                        truthify(column.isUserSpecific) !== truthify(existingColumn.isUserSpecific))
                ) {
                    errors.push("Cannot replace synthetic column with non-synthetic one, and vice versa");
                    continue;
                }

                newColumns = replaceArrayItem(newColumns, index, column);
            } else {
                if (column.name.length === 0) {
                    errors.push("Cannot create a column with an empty name");
                    continue;
                }
                newColumns.push(column);
            }
        }

        // If we set a row ID column for this table, it moves all the way to the left
        if (rowIDColumnName !== undefined) {
            const newMoved = new Map(moved);
            const existingMoves = (moved.get(tableName.name) ?? []).filter(([n]) => n !== rowIDColumnName);
            existingMoves.push([rowIDColumnName, 0]);
            newMoved.set(tableName.name, existingMoves);
            moved = newMoved;
        }

        const movedColumns = sortBy(moved.get(tableName.name) ?? [], m => m[1]);
        const reorderedColumns = newColumns.filter(c => !movedColumns.some(m => m[0] === c.name));
        for (const [name, index] of movedColumns) {
            const c = getTableColumn(newColumns, name);
            if (c === undefined) continue;
            reorderedColumns.splice(index, 0, c);
        }

        let result = { ...table, columns: reorderedColumns };
        if (
            rowIDColumnName !== undefined &&
            canBeRowIDColumn(result, rowIDColumnName) &&
            sourceMetadata !== undefined
        ) {
            if (isNative) {
                if (rowIDColumnName !== result.rowIDColumn) {
                    errors.push("Wrong name for native row ID column");
                }
            } else {
                result = { ...result, rowIDColumn: rowIDColumnName };
            }
        } else if (rowIDColumnName === undefined && modifiesRowIDColumn) {
            result = { ...result, rowIDColumn: undefined };
        }

        return result;
    });

    const schema = makeTypeSchema(tables);
    const newSchema = sanitizeSchemaAfterChanges(schema, sourceMetadata, userProfileTableInfo);
    // Sanitizing the schema can change primitive types of computed columns when
    // types of basic columns change.  For example, changing a string to a date
    // column will change the type of a lookup column that targets that date
    // column.  We're comparing here to make sure that only primitive types
    // change.
    const schemaDifferences = compareSchemas(schema, newSchema, false, areColumnTypesEqualExceptPrimitives);
    if (schemaDifferences !== undefined) {
        logError("Schema differences after sanitizing", JSON.stringify(schemaDifferences));
        errors.push("Sanitizing the schema changed it");
    }

    const inspector = makeSimpleSchemaInspector(newSchema, sourceMetadata, userProfileTableInfo);
    if (doesSchemaContainCycles(inspector)) {
        // ##schemasWithCycles:
        // We didn't always correctly detect cycles, and we can't refuse all
        // changes to existing schemas that contain cycles, so we only count
        // it as an error to create a cycle in a schema that didn't already
        // have one.
        const oldInspector = makeSimpleSchemaInspector(oldSchema, sourceMetadata, userProfileTableInfo);
        if (!doesSchemaContainCycles(oldInspector)) {
            errors.push("Table contains cycle in computed columns");
        }
    }

    return { schema: newSchema, errors };
}

// This will return a map from all queryable computed columns to the
// non-computed columns they directly or indirectly depend on.  If a column is
// not in the map, it means that it's not a queryable computed columns, i.e.
// it's either not computed, or the table is not queryable, or we can't query
// the column.
const getDependenciesOfQueryableComputedColumnsMemoized = memoizeFunction(
    "getDependenciesOfQueryableComputedColumns",
    (
        schema: SchemaInspector,
        table: TableGlideType,
        gbtComputedColumnsAlpha: boolean,
        gbtDeepLookups: boolean
    ): ReadonlyMap<TableColumn, readonly TableAndColumn[]> => {
        // Returns `undefined` if the computed column definitely doesn't
        // support computations in GBT.  Otherwise it returns all the
        // columns this column depends on in order to support computations.
        function doesGBTSupportComputation(t: TableGlideType, c: TableColumn): ColumnsUsedInFormula[0] | undefined {
            assert(isComputedColumn(c));
            const spec = decomposeAll(c.formula);
            if (spec === undefined) return undefined;

            switch (spec.kind) {
                case SyntheticColumnKind.Math:
                    break;
                case SyntheticColumnKind.IfThenElse:
                    break;
                case SyntheticColumnKind.TextTemplate:
                    if (!gbtComputedColumnsAlpha || spec.template.kind !== ColumnOrValueKind.Constant) return undefined;
                    break;
                case SyntheticColumnKind.MakeArray:
                    break;
                case SyntheticColumnKind.SplitString:
                    break;
                case SyntheticColumnKind.Lookup:
                    if (!gbtComputedColumnsAlpha) return undefined;

                    const lookup = decomposeSingleRelationLookup(t, spec);
                    if (lookup === undefined) return undefined;
                    const { hostColumnName, targetTableName, targetColumnName, lookupColumnName } = lookup;

                    // The target table must also be a GBT
                    const targetTable = schema.findTable(targetTableName);
                    if (
                        targetTable === undefined ||
                        getSourceMetadataFlags(targetTable.sourceMetadata).queryable
                            ?.supportsQueryingComputedColumns !== true
                    ) {
                        return undefined;
                    }
                    // Both the host and target columns must be non-computed
                    // primitive columns
                    const hostColumn = getTableColumn(t, hostColumnName);
                    const targetColumn = getTableColumn(targetTable, targetColumnName);
                    if (hostColumn === undefined || isComputedColumn(hostColumn) || !isPrimitiveType(hostColumn.type)) {
                        return undefined;
                    }
                    if (
                        targetColumn === undefined ||
                        isComputedColumn(targetColumn) ||
                        !isPrimitiveType(targetColumn.type) ||
                        targetColumn.isUserSpecific === true
                    ) {
                        return undefined;
                    }

                    // The lookup column must also be a non-computed column, unless `gbtDeepLookups` is enabled.
                    const lookupColumn = getTableColumn(targetTable, lookupColumnName);
                    if (lookupColumn === undefined) return undefined;
                    if (isComputedColumn(lookupColumn)) {
                        if (gbtDeepLookups) {
                            return doesGBTSupportComputation(targetTable, lookupColumn);
                        } else {
                            return undefined;
                        }
                    }

                    // We've already checked the dependencies here, and we
                    // don't want them registered below because we have
                    // another check that disallows computed columns if they
                    // involve another table.
                    // TODO: do this in a more principled way
                    return new Map();
                default:
                    return undefined;
            }
            return getColumnsUsedInColumn(schema, getTableName(t), c)[0];
        }

        // We only support querying computed columns in GBT.
        if (getSourceMetadataFlags(table.sourceMetadata).queryable?.supportsQueryingComputedColumns !== true) {
            return new Map();
        }

        // table -> column -> dependencies
        const dependencies = new DefaultMap<TableGlideType, Map<TableColumn, readonly TableAndColumn[]>>(
            () => new Map()
        );

        const started = new Set<TableColumn>();

        // This functions populates the `dependencies` map.
        function getDependencies(t: TableGlideType, c: TableColumn): readonly TableAndColumn[] | false {
            if (!isComputedColumn(c)) {
                return [{ table: t, column: c }];
            }

            const existing = dependencies.get(t).get(c);
            if (existing !== undefined) {
                return existing;
            }

            const usedByTable = doesGBTSupportComputation(t, c);
            if (usedByTable === undefined) return false;

            // If we ever try to start on a column again it either means we've
            // finished it unsuccessfully, or we have a circular dependency.
            if (started.has(c)) return false;
            started.add(c);

            const result: TableAndColumn[] = [];

            for (const [dt, columnsUsed] of usedByTable) {
                for (const dn of columnsUsed) {
                    const dc = getTableColumn(dt, dn);
                    if (dc === undefined) return false;

                    const deps = getDependencies(dt, dc);
                    if (deps === false) return false;

                    result.push(...deps);
                }
            }

            dependencies.get(t).set(c, result);
            return result;
        }

        for (const c of table.columns) {
            getDependencies(table, c);
        }

        return dependencies.get(table);
    }
);

// Exported for testing only
export function getDependenciesOfQueryableComputedColumns(
    schema: SchemaInspector,
    table: TableGlideType,
    gbtComputedColumnsAlpha: boolean,
    gbtDeepLookups: boolean
): ReadonlyMap<TableColumn, readonly TableAndColumn[]> {
    return getDependenciesOfQueryableComputedColumnsMemoized(schema, table, gbtComputedColumnsAlpha, gbtDeepLookups);
}

export function isQueryableColumn(
    schema: SchemaInspector,
    table: TableGlideType,
    c: TableColumn,
    gbtComputedColumnsAlpha: boolean,
    gbtDeepLookups: boolean
): boolean {
    if (!isComputedColumn(c)) {
        return true;
    }

    if (!isBigTableOrExternal(table)) return false;

    const dependencies = getDependenciesOfQueryableComputedColumns(
        schema,
        table,
        gbtComputedColumnsAlpha,
        gbtDeepLookups
    ).get(c);
    if (dependencies === undefined) return false;

    if (!gbtDeepLookups) {
        // Only with deep lookups can we query columns that refer to other
        // tables.
        if (dependencies.some(tac => tac.table !== table)) return false;
    } else {
        if (dependencies.some(tac => !isBigTableOrExternal(tac.table))) return false;
    }

    return true;
}

export function doesColumnSupportRollups(
    schema: SchemaInspector,
    table: TableGlideType,
    column: TableColumn,
    gbtComputedColumnsAlpha: boolean,
    gbtDeepLookups: boolean
): boolean {
    if (!doesTableSupportRollups(table)) return false;

    if (isBigTableOrExternal(table)) {
        return isQueryableColumn(schema, table, column, gbtComputedColumnsAlpha, gbtDeepLookups);
    } else {
        // FIXME: Technically this is not always true.  The column could be a
        // Rollup or Lookup into a queryable table, or derived from one.
        return true;
    }
}

export function getColumnsAffectedByDataColumns(
    schema: SchemaInspector,
    table: TableGlideType | undefined,
    dataColumnNames: readonly string[],
    gbtComputedColumnsAlpha: boolean,
    gbtDeepLookups: boolean
): ReadonlySet<string> {
    const columnsAffected = new Set<string>(dataColumnNames);
    if (table !== undefined) {
        const dependencies = getDependenciesOfQueryableComputedColumns(
            schema,
            table,
            gbtComputedColumnsAlpha,
            gbtDeepLookups
        );
        for (const [col, deps] of dependencies) {
            if (deps.some(d => d.table === table && columnsAffected.has(d.column.name))) {
                columnsAffected.add(col.name);
            }
        }
    }
    return columnsAffected;
}
