export enum WireComponentKind {
    // shared
    TextField = "text-field",
    NumberField = "number-field",
    EmailField = "email-field",
    PhoneField = "phone-field",
    DateTimeField = "date-field",
    FilePicker = "file-picker",
    ImagePicker = "image-picker",
    Toggle = "toggle",
    ActivitySpinner = "activity-spinner",

    // pages
    Button = "page-button",
    Hint = "page-hint",
    Hero = "hero",
    ContactForm = "contact-form",
    DynamicButtonBar = "dynamic-button-bar",
    ButtonsBlock = "buttons-block",
    List = "list",
    Container = "container",
    InternalSeparator = "internal-separator",
    FormContainer = "form-container",
    Fields = "fields",
    BigNumbers = "big-numbers",
    Breadcrumbs = "breadcrumbs",
    RichText = "page-rich-text",
    Note = "page-note",
    Image = "page-image",
    SimpleImage = "page-simple-image",
    Video = "page-video",
    SimpleVideo = "page-simple-video",
    Separator = "page-separator",
    SignIn = "sign-in",
    Custom = "custom",
    Text = "text",
    WebView = "page-web-view",
    AICustomComponent = "ai-custom-component",
    AICustomChatComponent = "ai-custom-chat-component",
    InlineScanner = "inline-scanner",
    Location = "page-location",
    Link = "page-link",
    // SignaturePad is the component we show in the sign overlay
    SignaturePad = "page-signature-pad",
    // SignatureField is the component that you can slap in your Page
    // it trigger the sign overlay
    SignatureField = "page-signature-field",
    ActionRow = "page-action-row",
    PageRating = "page-rating",
    PageProgress = "page-progress",
    PageAudio = "page-audio",
    PageAudioRecorder = "page-audio-recorder",
    MultipleFilters = "page-multiple-filters",
    RenderableContent = "page-renderable-content",
    Stopwatch = "page-stopwatch",
    Spinner = "page-spinner",
    VoiceEntry = "page-voice-entry",

    // apps
    AppLabel = "app-label",
    AppButton = "app-button",
    AppButtonBar = "app-button-bar",
    AppTitle = "app-title",
    AppUserProfile = "app-user-profile",
    AppAdaptiveListItem = "app-adaptive-list-item",
    AppImage = "app-image",
    AppSeparator = "app-separator",
    AppTextBox = "app-text-box",
    AppPhoneNumber = "app-phone-number",
    AppSimpleTable = "app-simple-table",
    AppHint = "app-hint",
    AppMarkdown = "app-markdown",
    AppAudioPlayer = "app-audio-player",
    AppVideoPlayer = "app-video-player",
    AppProgress = "app-progress",
    AppLike = "app-like",
    AppRating = "app-rating",
    AppReaction = "app-reaction",
    AppTinder = "app-tinder",
    AppWebView = "app-web-view",
    AppMap = "app-map",
    AppNotes = "app-notes",
    AppLocation = "app-location",
    AppOverlayEditor = "app-overlay-editor",
    AppComments = "app-comments",
    AppChat = "app-chat",
    AppBuyScreen = "app-buy-screen",
    AppCodeScannerScreen = "app-code-scanner-screen",
    AppShareScreen = "app-share-screen",
    AppSignatureField = "app-signature-field",
    AppSignaturePad = "app-signature-pad",
    AppStopwatch = "app-stopwatch",
    AppEventPicker = "app-event-picker",
    AppEventPickerScreen = "app-event-picker-screen",
    AppPieChart = "app-pie-chart",
    AppStackedBarChart = "app-stacked-bar-chart",
    AppDonutChart = "app-donut-chart",
    AppBarChart = "app-bar-chart",

    // overlays/accessories
    AppTagOverlay = "app-tag-overlay",
    AppAvatarOverlay = "app-avatar-overlay",
    AppToggleIconOverlay = "app-toggle-icon-overlay",
    AppIconAccessory = "app-icon-accessory",
    AppCircleButtonAccessory = "app-circle-button-accessory",

    //  special components
    AppMenuItem = "app-menu-item",
    AppSearchBar = "app-search-bar",
    AppPivotBar = "app-pivot-bar",
}
