import {
    type BasePrimitiveValue,
    type GlideDateTimeDocumentData,
    type GlideJSONDocumentData,
    GlideDateTime,
    GlideJSON,
} from "@glide/data-types";
import type { LoadedGroundValue } from "./data";
import { isPrimitive } from "./data";

export function getSerializableForPrimitive(
    x: LoadedGroundValue
): BasePrimitiveValue | GlideDateTimeDocumentData | GlideJSONDocumentData | undefined {
    if (x instanceof GlideDateTime || x instanceof GlideJSON) return x.toDocumentData();
    if (isPrimitive(x)) return x;
    return undefined;
}
