export function getFirstFileFromInput(files: FileList | null): File | undefined {
    if (files === null || files.length === 0) return undefined;
    const firstFile = files.item(0);
    if (firstFile === null) return undefined;
    return firstFile;
}

export async function readFileAsURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = (_e: Event) => {
            const newUrl = fr.result as string;
            resolve(newUrl);
        };

        fr.onerror = (_e: Event) => {
            reject(fr.error);
        };

        fr.readAsDataURL(file);
    });
}
