import { assert, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import type { MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import { asMaybeString, isTable } from "@glide/common-core/dist/js/computation-model/data";
import { isArray } from "@glide/support";
import type { UserProfileRow } from "@glide/generator/dist/js/user-profile-info";
import { useChangeObservable } from "@glide/common";
import { getValueAtPath } from "./getters";
import type { TableName, UserProfileTableInfo } from "@glide/type-schema";
import { Query, type ComputationModel, isLoadingValue } from "@glide/computation-model-types";
import { getFeatureSetting } from "@glide/common-core";

export function makeQueryForUserProfileRow(
    userProfileTableName: TableName,
    emailColumnName: string,
    emails: readonly string[]
): Query {
    assert(emails.length > 0);

    return new Query(userProfileTableName)
        .withCondition(
            emails.map(email => ({
                kind: "matches-email-address",
                lhs: { columnName: emailColumnName },
                rhs: email,
                negated: false,
            }))
        )
        .withLimit(1);
}

// Only returns profiles that are loaded locally, which may not be all, e.g. in the case of a GBT user table
export function getUserProfiles(
    computationModel: ComputationModel,
    info: UserProfileTableInfo
): readonly UserProfileRow[] | undefined {
    const columnPaths = computationModel.getColumnPaths(info.tableName);
    if (columnPaths === undefined) return undefined;

    const emailPaths = columnPaths.get(info.emailColumnName);
    if (emailPaths === undefined) return undefined;
    const [[emailPath, emailRootPath]] = emailPaths;
    // If `emailRootPath === undefined` then it's a computed column that's
    // global, i.e. has the same value in all rows, which doesn't make sense.
    if (emailRootPath === undefined) return undefined;

    // We get the formatted path for the name, for the others we get the value
    // paths.
    const [[nameValuePath, nameValueRootPath], [nameFormattedPath, nameFormattedRootPath]] = columnPaths.get(
        info.nameColumnName
    ) ?? [[], []];
    const namePath = nameFormattedPath ?? nameValuePath;
    const nameRootPath = nameFormattedRootPath ?? nameValueRootPath;
    const [[imagePath, imageRootPath]] = columnPaths.get(info.imageColumnName) ?? [[]];
    const [[rolePath, roleRootPath]] = definedMap(info.rolesColumnName, cn => columnPaths.get(cn)) ?? [[]];

    const table = computationModel.ns.get(emailRootPath);
    if (isLoadingValue(table) || !isTable(table)) return undefined;

    // Force computation of the columns
    for (const rootPath of [nameRootPath, imageRootPath, roleRootPath]) {
        if (rootPath === undefined) continue;
        computationModel.ns.get(rootPath);
    }

    const userProfileRows: UserProfileRow[] = [];
    for (const row of table.values()) {
        if (!row.$isVisible) continue;

        const emailValue = getValueAtPath(computationModel.ns, row, emailPath);
        // We've had users set the email column to a date/time column, which
        // confuses Glide and can show a preview-as user when in reality the
        // computation model doesn't use that user but gives the fallback user
        // profile row instead, so now we're strict here and won't even show
        // that row to begin with.
        // https://github.com/quicktype/glide/issues/15957
        if (typeof emailValue !== "string") continue;

        const nameValue = definedMap(namePath, p => getValueAtPath(computationModel.ns, row, p));
        if (isLoadingValue(nameValue)) continue;
        const name = asMaybeString(nameValue);

        const imageURLValue = definedMap(imagePath, p => getValueAtPath(computationModel.ns, row, p));
        if (isLoadingValue(imageURLValue)) continue;
        const imageURL = asMaybeString(imageURLValue);

        let roles: readonly string[] | undefined;

        if (rolePath !== undefined) {
            const roleValue = getValueAtPath(computationModel.ns, row, rolePath);
            if (isLoadingValue(roleValue)) continue;

            if (isArray(roleValue)) {
                roles = mapFilterUndefined(roleValue, asMaybeString);
            } else {
                const role = asMaybeString(roleValue);
                if (role !== undefined) {
                    roles = [role];
                }
            }
        }

        userProfileRows.push({
            email: emailValue,
            name,
            imageURL,
            roles: roles ?? [],
            key: row.$rowID,
        });
    }

    return userProfileRows;
}

export function useUserProfiles(appEnvironment: MinimalAppEnvironment | undefined): {
    loadedRows: readonly UserProfileRow[] | undefined;
    totalProfilesCount: number | undefined;
} {
    const computationModel = useChangeObservable(appEnvironment?.dataStore.getComputationModelObservable(true));
    const info = appEnvironment?.userProfileTableInfo;

    // If the user table is queryable, make sure we have the correct total row count
    //  (If it's not, then this will just be undefined)
    const totalProfilesCount = useChangeObservable(
        getFeatureSetting("gbtUserProfileTables") && info !== undefined
            ? appEnvironment?.queryableDataStore?.getNumberOfRowsInTable(info.tableName)
            : undefined
    );

    const loadedRows =
        computationModel !== undefined && info !== undefined ? getUserProfiles(computationModel, info) : undefined;

    return { loadedRows, totalProfilesCount };
}
