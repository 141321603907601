import type { ArrayColumnType, ColumnType, ColumnTypeKind } from "@glide/type-schema";

type NonArrayColumnTypeKind = Exclude<ColumnTypeKind, "array">;
type ArrayColumnTypeKind = Extract<ColumnTypeKind, "array">;
type ArrayColumnTypeItemKind = ArrayColumnType["items"]["kind"];

// We need the column type in the plugins parameter source for proper JSON parsing.
// so we use this to trck the column type, since we support array columns, we need to track the item type as well.
// in case of an array column, the second item in the tuple is the item type.
type ParameterSourceNonArrayColumnType = [NonArrayColumnTypeKind];
type ParameterSourceArrayColumnType = [ArrayColumnTypeKind, ArrayColumnTypeItemKind];
export type ParameterSourceColumnType = ParameterSourceNonArrayColumnType | ParameterSourceArrayColumnType;

export function makeParameterSourceColumnType(
    columnOrColumnTypeKind: ColumnType | NonArrayColumnTypeKind
): ParameterSourceColumnType {
    if (typeof columnOrColumnTypeKind === "string") return [columnOrColumnTypeKind];

    if (columnOrColumnTypeKind.kind === "array") {
        return [columnOrColumnTypeKind.kind, columnOrColumnTypeKind.items.kind];
    }

    return [columnOrColumnTypeKind.kind];
}
