import {
    type LoadingValue,
    Table,
    Query,
    type ComputationModel,
    type GroundValue,
    type QueryBase,
    type RootPath,
    type Row,
    isLoadingValue,
    QueryFromRows,
} from "@glide/computation-model-types";
import { asTable } from "@glide/common-core/dist/js/computation-model/data";
import { getTableName } from "@glide/type-schema";
import { panic } from "@glideapps/ts-necessities";
import type { MutableSubscriptionSources, ValueProviderContext } from "./internal-types";
import { applySort, areConditionsTrueForRow } from "@glide/query-conditions";
import { getValueAtPath, applyQueryGroupBy, applyQueryLimit } from "@glide/computation-model";

export function addGlobalKey(sources: MutableSubscriptionSources, rootPath: string, column: string | true) {
    if (column === true) {
        sources.globalKeys.set(rootPath, true);
    } else {
        const existing = sources.globalKeys.get(rootPath);
        if (existing === undefined) {
            sources.globalKeys.set(rootPath, new Set([column]));
        } else if (existing !== true) {
            existing.add(column);
        }
    }
}

export function resolveQueryFromRows(
    query: QueryFromRows,
    cm: ComputationModel,
    sources: MutableSubscriptionSources
): Table | undefined {
    const serialized = query.serialize();
    const tableType = query.tableType;

    const paths = cm.getColumnPaths(getTableName(tableType));
    if (paths === undefined) return undefined;

    const { ns } = cm;

    const globalKeysAdded = new Set<string>();
    function addRootPath(path: RootPath | undefined) {
        if (path === undefined) return;
        if (globalKeysAdded.has(path.rest.key)) return;
        globalKeysAdded.add(path.rest.key);
        // FIXME: do we want `column: true` here?
        addGlobalKey(sources, path.rest.key, true);
        ns.get(path);
    }

    let rows = query.rows.asArray();

    function getColumnValueForRow(row: Row, cn: string) {
        if (paths === undefined) return undefined;
        const maybePaths = paths.get(cn);
        if (maybePaths === undefined) return undefined;
        const [[valuePath, rootPath]] = maybePaths;
        addRootPath(rootPath);
        return getValueAtPath(ns, row, valuePath);
    }

    rows = rows.filter(r => areConditionsTrueForRow(serialized, cn => getColumnValueForRow(r, cn), tableType.columns));

    applySort(tableType.columns, serialized.sort, rows, getColumnValueForRow);

    rows = applyQueryLimit(serialized, rows);

    rows = applyQueryGroupBy(serialized, tableType.columns, rows, getColumnValueForRow);

    return new Table(rows);
}

export function resolveQueryAsTable(
    query: QueryBase,
    context: ValueProviderContext,
    sources: MutableSubscriptionSources,
    unwrap: (v: GroundValue) => GroundValue
): Table | LoadingValue | undefined {
    if (query instanceof QueryFromRows) {
        return context.resolveQueryFromRows(query, sources);
    } else if (query instanceof Query) {
        const path = context.getPathForQuery(query);

        let value: GroundValue;
        if (path !== undefined) {
            // FIXME: do we want `column: true` here?
            addGlobalKey(sources, path.rest.key, true);
            value = context.namespace?.get(path);
        } else {
            value = context.resolveQuery(query);
        }
        const unwrapped = unwrap(value);
        if (unwrapped === undefined || isLoadingValue(unwrapped)) return unwrapped;
        return asTable(unwrapped);
    } else {
        // Maybe return `undefined`?
        return panic("Unknown query type");
    }
}
