import md5 from "blueimp-md5";

export const map: Record<string, string> = {
    activity: "01-graph-stats",
    airplay: "15-video-player-monitor",
    "alert-circle": "01-alert-circle",
    "alert-octagon": "01-stop-sign",
    "alert-triangle": "47-road-sign-warning",
    "align-center": "01-paragraph-center-align",
    "align-justify": "01-paragraph-justified-align",
    "align-left": "01-paragraph-left-align",
    "align-right": "01-paragraph-right-align",
    anchor: "50-anchor",
    aperture: "13-lens-shutter",
    archive: "11-archive",
    "arrow-down-circle": "01-navigation-down-circle",
    "arrow-down-left": "52-arrow-corner-left",
    "arrow-down-right": "52-arrow-corner-right",
    "arrow-down": "52-arrow-down",
    "arrow-left-circle": "01-navigation-left-circle-1",
    "arrow-left": "52-arrow-left",
    "arrow-right-circle": "01-navigation-right-circle-1",
    "arrow-right": "52-arrow-right",
    "arrow-up-circle": "01-navigation-up-circle",
    "arrow-up": "52-arrow-up",
    "at-sign": "19-read-email-at-1",
    award: "22-award-ribbon-star-3",
    "bar-chart-2": "06-performance-increase",
    "bar-chart": "06-analytics-bars",
    "battery-charging": "03-battery-charge",
    battery: "20-charging-battery-full-1",
    "bell-off": "01-alarm-bell-off",
    bell: "01-alarm-bell",
    bluetooth: "05-bluetooth",
    bold: "01-text-bold",
    "book-open": "11-book-flip-page",
    book: "11-book-close-2",
    bookmark: "01-bookmarks-1",
    box: "10-shipment-box",
    briefcase: "01-tool-box",
    calendar: "01-calendar",
    "camera-off": "25-allowances-no-photos",
    camera: "13-camera-2",
    cast: "05-wifi-monitor",
    "check-circle": "01-check-circle",
    "check-square": "01-check-square",
    check: "01-check-1",
    "chevron-down": "52-arrow-down-1",
    "chevron-left": "52-arrow-left-1",
    "chevron-right": "52-arrow-right-1",
    "chevron-up": "52-arrow-up-1",
    "chevrons-down": "52-arrow-button-down-1",
    "chevrons-left": "52-arrow-button-left-1",
    "chevrons-right": "52-arrow-button-right-1",
    "chevrons-up": "52-arrow-button-up-1",
    chrome: "48-compass-2",
    circle: "48-sign-badge-circle",
    clipboard: "07-task-list-plain",
    clock: "01-time-clock-circle-1",
    "cloud-drizzle": "34-weather-cloud-hail",
    "cloud-lightning": "34-weather-cloud-snow-thunder",
    "cloud-off": "05-cloud-off",
    "cloud-rain": "34-weather-cloud-rain",
    "cloud-snow": "34-weather-cloud-snow",
    cloud: "34-weather-cloud-1",
    code: "04-programming-hold-code-2",
    codepen: "12-shape-cube",
    coffee: "26-coffee-to-go",
    columns: "01-layout-three-columns",
    command: "01-keyboard-command",
    compass: "48-compass-directions",
    copy: "16-common-file-double-2",
    cpu: "03-computer-chip",
    "credit-card": "08-credit-card-1",
    crop: "12-artboard-expand",
    crosshair: "01-cursor-target-2",
    database: "04-database-2",
    delete: "01-bin",
    disc: "03-cd",
    "dollar-sign": "08-currency-dollar",
    "download-cloud": "05-cloud-download",
    download: "05-download-bottom",
    droplet: "12-color-drop-pick",
    "edit-2": "11-content-pen-3",
    "edit-3": "11-content-pen-write",
    edit: "01-pencil-write-2",
    "external-link": "01-expand-6",
    "eye-off": "01-view-off",
    eye: "01-view-1",
    facebook: "53-social-media-facebook",
    "fast-forward": "01-navigation-arrows-right-1",
    feather: "33-peacock-feather",
    "file-minus": "16-common-file-subtract",
    "file-plus": "16-common-file-add",
    "file-text": "16-common-file-text",
    file: "16-common-file-empty",
    film: "15-movies-film",
    filter: "01-filter",
    flag: "22-flag-plain-3",
    "folder-minus": "16-folder-subtract",
    "folder-plus": "16-folder-add",
    folder: "16-folder-empty",
    frown: "21-smiley-unhappy-1",
    gift: "22-gift-box",
    github: "53-developer-community-github-1",
    globe: "48-earth-3",
    grid: "01-layout-module-2",
    "hard-drive": "03-hard-drive",
    hash: "01-hash",
    headphones: "14-headphones-2",
    heart: "22-love-it",
    "help-circle": "01-question-circle",
    hexagon: "01-layout-corners-dashboard",
    home: "49-house",
    image: "13-picture-sun",
    inbox: "19-drawer-open",
    info: "01-information-circle",
    instagram: "53-social-instagram",
    italic: "01-text-italic",
    key: "01-login-key-1",
    layers: "12-layers-stacked",
    layout: "01-layout-6",
    "life-buoy": "01-help-wheel",
    "link-2": "01-hyperlink",
    link: "01-hyperlink-3",
    linkedin: "53-professional-network-linkedin",
    list: "01-list-bullets",
    loader: "01-loading",
    lock: "01-lock-6",
    "log-in": "01-login-3",
    "log-out": "01-logout-2",
    mail: "19-envelope",
    "map-pin": "48-pin",
    map: "48-maps",
    "maximize-2": "01-expand-diagonal",
    maximize: "01-expand-full",
    meh: "21-smiley-indifferent",
    menu: "01-navigation-menu",
    "message-circle": "21-messages-bubble",
    "message-square": "21-messages-bubble-square",
    "mic-off": "14-microphone-off",
    mic: "14-microphone",
    "minimize-2": "01-shrink-2",
    minimize: "04-app-window-minimize",
    "minus-circle": "01-subtract-circle",
    "minus-square": "01-subtract-square",
    minus: "01-subtract",
    monitor: "03-screen-1",
    moon: "37-astronomy-moon",
    "more-horizontal": "01-navigation-menu-horizontal",
    "more-vertical": "01-navigation-menu-vertical",
    "mouse-pointer": "01-cursor",
    move: "01-direction-button-arrows",
    music: "14-music-note-2",
    "navigation-2": "48-compass-arrow",
    navigation: "48-compass-direction",
    octagon: "48-sign-badge-circle",
    package: "10-shipment-upload",
    paperclip: "01-attachment",
    "pause-circle": "01-button-pause-1",
    pause: "15-controls-pause",
    "pen-tool": "12-vectors-pen",
    percent: "09-discount-1",
    "phone-call": "20-phone-actions-ringing",
    "phone-forwarded": "20-phone-actions-forward-1",
    "phone-incoming": "20-phone-actions-receive",
    "phone-missed": "20-phone-actions-missed-call",
    "phone-off": "20-phone-actions-off",
    "phone-outgoing": "20-phone-actions-call",
    phone: "20-phone",
    "pie-chart": "06-analytics-pie-1",
    "play-circle": "01-button-play-1",
    play: "52-arrow-button-right-2",
    "plus-circle": "01-add-circle",
    "plus-square": "01-add-square",
    plus: "01-add",
    pocket: "11-content-pens-pocket",
    power: "01-power-button",
    printer: "01-print-text",
    radio: "01-wifi-signal-1",
    "refresh-ccw": "01-button-refresh-arrows",
    "refresh-cw": "01-button-refresh-arrows",
    repeat: "01-button-refresh-one",
    rewind: "01-navigation-arrows-left-1",
    "rotate-ccw": "12-rotate-back",
    "rotate-cw": "12-rotate-forward",
    rss: "01-rss-feed-square",
    save: "03-floppy-disk",
    scissors: "01-scissors-2",
    search: "01-search-1",
    send: "19-send-email-1",
    server: "05-server-2",
    settings: "01-cog",
    "share-2": "01-share",
    share: "01-share-1",
    "shield-off": "01-remove-shield",
    shield: "46-army-shield",
    "shopping-bag": "09-shopping-bag-side",
    "shopping-cart": "09-shopping-cart-1",
    shuffle: "01-button-shuffle",
    sidebar: "01-layout-left",
    "skip-back": "15-controls-previous",
    "skip-forward": "15-controls-next",
    slash: "01-delete",
    sliders: "01-settings-slider",
    smartphone: "20-mobile-phone",
    smile: "21-smiley-smile-2",
    speaker: "14-speakers-1",
    square: "15-controls-stop",
    star: "22-rating-star",
    "stop-circle": "01-button-stop",
    sun: "34-weather-sun",
    sunrise: "34-day-sunrise-2",
    sunset: "34-day-sunset-2",
    tablet: "03-tablet-1",
    tag: "01-tags",
    target: "01-cursor-target",
    terminal: "04-app-window-code",
    thermometer: "34-temperature-thermometer",
    "thumbs-down": "22-dislike-1",
    "thumbs-up": "22-like-1",
    "toggle-left": "01-settings-off",
    "toggle-right": "01-settings-on",
    tool: "30-tools-wench",
    "trash-2": "01-bin",
    trash: "01-bin-1",
    "trending-down": "01-graph-stats-descend",
    "trending-up": "01-graph-stats-ascend",
    triangle: "12-shape-triangle",
    truck: "47-truck-1",
    tv: "15-modern-tv-wide",
    twitch: "45-video-game-logo-twitch",
    twitter: "53-social-media-twitter",
    type: "01-text-style",
    umbrella: "34-rain-umbrella",
    underline: "01-text-underline",
    unlock: "01-lock-unlock-4",
    "upload-cloud": "05-cloud-upload",
    upload: "05-upload-bottom",
    "user-check": "17-single-neutral-actions-check-2",
    "user-minus": "17-single-neutral-actions-subtract",
    "user-plus": "17-single-neutral-actions-add",
    "user-x": "17-single-neutral-actions-remove",
    user: "17-single-neutral",
    users: "17-multiple-neutral-1",
    "video-off": "15-controls-camera-off",
    video: "07-meeting-camera",
    voicemail: "20-voice-mail",
    "volume-1": "14-volume-control-medium",
    "volume-2": "14-volume-control-full",
    "volume-x": "14-volume-control-off",
    volume: "14-volume-control-low",
    watch: "03-smart-watch-square-app",
    "wifi-off": "05-wifi-off",
    wifi: "05-wifi",
    wind: "34-weather-cloud-wind-4",
    "x-circle": "01-delete-1",
    "x-square": "01-remove-square-1",
    x: "01-close",
    youtube: "53-social-video-youtube-clip",
    "zap-off": "01-flash-off",
    zap: "01-flash-1",
    "zoom-in": "01-zoom-in",
    "zoom-out": "01-zoom-out",
};

function normalizeIcon(icon: string): string {
    const mapped = map[icon];
    if (mapped !== undefined) return mapped;
    return icon;
}

export function getIconPath(icon: string) {
    if (!/^[0-9]/.test(icon)) {
        icon = normalizeIcon(icon);
    }
    let sliceAmount = 3;
    if (/^[0-9][0-9]-[0-9][0-9]-.*$/.test(icon)) {
        sliceAmount = 6;
    }

    const cacheBustNumber = 1;
    const file = icon.slice(sliceAmount) + `.svg`;
    const result = "/icons/" + md5(file) + `-${cacheBustNumber}.svg`;
    return result;
}
