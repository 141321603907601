import { assert } from "@glideapps/ts-necessities";
import isHotkey from "is-hotkey";
import * as React from "react";

export type AcceleratorCallback = (e: KeyboardEvent, binding: string) => void;

export function useAccelerator(
    bindings: readonly string[],
    callback: AcceleratorCallback,
    ignoreWhenInputFocused: boolean = false
): void {
    assert(bindings.length <= 30, "useAccelerator only accepts up to 30 bindings at once");

    const sorted = [...bindings].sort().concat(Array(30 - bindings.length).map(() => ""));

    React.useEffect(() => {
        if (bindings.length === 0) return;
        const cb = (e: KeyboardEvent) => {
            if (
                ignoreWhenInputFocused &&
                (e.target instanceof HTMLInputElement ||
                    e.target instanceof HTMLTextAreaElement ||
                    (e.target instanceof HTMLElement && e.target.isContentEditable))
            ) {
                return;
            }
            for (const binding of bindings) {
                if (binding === "") break;
                if (isHotkey(binding)(e)) {
                    callback(e, binding);
                    break;
                }
            }
        };
        document.addEventListener("keydown", cb, {
            capture: true,
        });
        return () =>
            document.removeEventListener("keydown", cb, {
                capture: true,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback, ...sorted]);
}
