import { AppKind } from "@glide/location-common";
import {
    type ComponentDescription,
    type ComponentKind,
    type LegacyPropertyDescription,
    makeColumnProperty,
} from "@glide/app-description";
import { type TableColumn, isStringTypeKind } from "@glide/type-schema";
import { makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { type ComponentDescriptor, makeStringyColumnPropertyDescriptor, PropertySection } from "@glide/function-utils";

import { assert } from "@glideapps/ts-necessities";

import { ComponentHandlerBase } from "./handler";

const ComponentKindYouTube: ComponentKind = "youtube";

interface YouTubeComponentDescription extends ComponentDescription {
    readonly propertyName: LegacyPropertyDescription;
}

export class YouTubeComponentHandler extends ComponentHandlerBase<YouTubeComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindYouTube);
    }

    public getDescriptor(): ComponentDescriptor {
        return {
            name: "YouTube",
            description: "Play a youtube video",
            img: "co-video",
            group: "Basic",
            helpUrl: getDocURL("youtube"),
            isLegacy: true,
            properties: [
                makeStringyColumnPropertyDescriptor(
                    "propertyName",
                    "Video",
                    ["required", "editable"],
                    PropertySection.Data,
                    undefined,
                    ["video", "youtube"],
                    [],
                    "uri"
                ),
                ...this.getBasePropertyDescriptors(),
            ],
        };
    }

    public static defaultComponent(column: TableColumn): YouTubeComponentDescription {
        assert(isStringTypeKind(column.type.kind));
        return {
            ...makeEmptyComponentDescription(ComponentKindYouTube),
            propertyName: makeColumnProperty(column.name),
        };
    }

    public convertToPage(): ComponentDescription | undefined {
        return undefined;
    }
}
