import { useColorFromSeed } from "@glide/common";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { isSmallScreen, useResponsiveSizeClass } from "@glide/common-components";
import { isDefined } from "@glide/support";
import chroma from "chroma-js";
import classNames from "classnames";
import * as React from "react";
import { useLayer } from "react-laag";
import { css } from "styled-components";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
import { Img } from "../../components/img/img";
import { APP_MODAL_ROOT } from "../../wire-lib";
import { DesktopNavBar } from "./desktop-nav-bar";
import { MobileNavBar } from "./mobile-nav-bar";
import { type UnifiedNavBarProps, UserProfileMenu, getLogoOrIcon, useLogoAndTitleDisplayProps } from "./nav-bar-common";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { defined, hasOwnProperty } from "@glideapps/ts-necessities";
import type { IconImage } from "@glide/app-description";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";

export const UnifiedNavBar: React.VFC<UnifiedNavBarProps> = p => {
    const {
        title,
        tabs,
        iconImage,
        className,
        selected,
        onSelectionChange,
        onSignUpPressed,
        onUserButtonPressed,
        onSignInPressed,
        signedInUserEmail,
        signedInUserName,
        signedInUserImage,
        onSignOutPressed,
        isUserProfileActive,
        logo,
        navigateToRoot,
        scrollToTop,
        detailsTitle,
        isInDetailsScreen,
        isInModal,
        onBack,
        canGoBack,
        setShowMenu,
        onModalClose,
        modalFormControls,
        containerRef,
        belowScreenCanGoBack,
        belowScreenTitle,
        navBarCrossfadeMotionValue,
        topFirstComponentHasTitle,
        belowFirstComponentHasTitle,
        mustShowTitleInNavBar,
        showMenuButton,
        showTransparentNavBar,
    } = p;

    const username = signedInUserName ?? signedInUserEmail ?? "?";

    const colorPreview = useColorFromSeed(username);
    const background = chroma.mix("#fff", colorPreview, 0.2, "rgb").css();
    const theme = useWireAppTheme();

    const sizeClass = useResponsiveSizeClass();

    const [showUserMenu, setShowUserMenu] = React.useState(false);

    const { layerProps, triggerProps, renderLayer } = useLayer({
        isOpen: showUserMenu,
        auto: true,
        triggerOffset: 4,
        placement: "bottom-end",
        onOutsideClick: () => setShowUserMenu(false),
        container: APP_MODAL_ROOT,
    });

    const userButton =
        onUserButtonPressed === undefined ? undefined : (
            <>
                <button
                    {...triggerProps}
                    onClick={() => setShowUserMenu(cv => !cv)}
                    data-testid="nav-bar-user-button"
                    className={classNames(isUserProfileActive && "force-outline")}
                    style={{
                        color: colorPreview,
                        backgroundColor: background,
                    }}
                    css={css`
                        &.force-outline {
                            ${tw`after:ring-2 ring-white`}
                        }
                    `}
                    tw="relative text-base w-6 h-6 shrink-0 cursor-pointer rounded-full mr-1 shadow-none transform
                        transition after:(content-[''] absolute -inset-0.5 rounded-full pointer-events-none
                        transition-shadow) focus-visible:after:(ring-2 ring-white) hover:(shadow-lg-dark scale-105)
                        gp-md:(w-7 h-7)"
                >
                    <div tw="flex overflow-hidden absolute inset-0 justify-center items-center leading-none capitalize rounded-full">
                        {username[0]}
                        {signedInUserImage !== undefined && (
                            <Img tw="object-cover absolute inset-0 w-full h-full" src={signedInUserImage} />
                        )}
                    </div>
                </button>
                {showUserMenu &&
                    renderLayer(
                        <UserProfileMenu
                            layerProps={layerProps}
                            onClose={() => setShowUserMenu(false)}
                            onUserButtonPressed={onUserButtonPressed}
                            onSignOutPressed={onSignOutPressed}
                        />
                    )}
            </>
        );

    const { showLogo, showIcon, showTitle } = useLogoAndTitleDisplayProps(logo, theme, iconImage);

    const isMobile = isSmallScreen(sizeClass);
    if (isMobile) {
        const navTitle = isInModal ? detailsTitle : title;
        const mustShowLogo = showLogo && !canGoBack && !isInModal;
        const mustShowIcon = showIcon && !canGoBack && !isInModal;

        const logoAndTitle = (
            <LogoAndTitle
                isBelow={false}
                title={showTitle ? navTitle : undefined}
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                logo={logo || iconImage}
                renderedLogo={getLogoOrIcon(mustShowLogo, mustShowIcon, logo, iconImage)}
                isMobile={isMobile}
                firstComponentHasTitle={topFirstComponentHasTitle}
                mustShowTitleInNavBar={mustShowTitleInNavBar}
                isInModal={isInModal}
            />
        );
        const belowLogoAndTitle = (
            <LogoAndTitle
                isMobile={isMobile}
                isBelow
                title={showTitle ? belowScreenTitle : undefined}
                logo={showLogo && !belowScreenCanGoBack ? logo : undefined}
                firstComponentHasTitle={belowFirstComponentHasTitle}
                mustShowTitleInNavBar={false}
                isInModal={isInModal}
            />
        );

        const onTitleClick = isInDetailsScreen ? scrollToTop : navigateToRoot;

        return (
            <MobileNavBar
                containerRef={containerRef}
                onTitleClick={onTitleClick}
                logoAndTitle={logoAndTitle}
                belowLogoAndTitle={belowLogoAndTitle}
                className={className}
                modalFormControls={modalFormControls}
                isInModal={isInModal}
                canGoBack={canGoBack}
                belowScreenCanGoBack={belowScreenCanGoBack}
                onBack={onBack}
                setShowMenu={setShowMenu}
                onModalClose={onModalClose}
                navBarCrossfadeMotionValue={navBarCrossfadeMotionValue}
                mustShowTitleInNavBar={mustShowTitleInNavBar}
                showMenuButton={showMenuButton}
                showTransparentNavBar={showTransparentNavBar}
            />
        );
    }

    const logoAndTitle = (
        <LogoAndTitle
            isBelow={false}
            title={showTitle ? title : undefined}
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            logo={logo || iconImage}
            renderedLogo={getLogoOrIcon(showLogo, showIcon, logo, iconImage)}
            isMobile={isMobile}
            mustShowTitleInNavBar={true}
            isInModal={isInModal}
        />
    );

    return (
        <DesktopNavBar
            containerRef={containerRef}
            navigateToRoot={navigateToRoot}
            logoAndTitle={logoAndTitle}
            onSignInPressed={onSignInPressed}
            onSignUpPressed={onSignUpPressed}
            userButton={userButton}
            className={className}
            selected={selected}
            onSelectionChange={onSelectionChange}
            tabs={tabs}
        />
    );
};

interface LogoAndTitleProps {
    logo: string | undefined | IconImage;
    renderedLogo?: string | undefined;
    title: string | undefined;
    isBelow: boolean;
    isMobile: boolean;
    firstComponentHasTitle?: boolean;
    mustShowTitleInNavBar: boolean;
    isInModal: boolean;
}

const LogoAndTitle: React.VFC<LogoAndTitleProps> = p => {
    const { logo, renderedLogo, title, isBelow, isMobile, firstComponentHasTitle, mustShowTitleInNavBar, isInModal } =
        p;

    const appID = useAppID();

    const logoComponent = isDefined(renderedLogo) ? (
        hasOwnProperty(logo, "url") ? (
            <div tw="hidden overflow-hidden relative w-8 h-8 rounded-lg shrink-0 gp-md:block bg-accent not-last:mr-3">
                <Img
                    src={massageImageUrl(
                        renderedLogo,
                        {
                            height: 75,
                            thumbnail: false,
                        },
                        defined(appID)
                    )}
                    tw="object-cover w-full h-full"
                />
            </div>
        ) : (
            <div tw="hidden not-last:mr-3 gp-md:block">
                <Img
                    src={massageImageUrl(
                        renderedLogo,
                        {
                            height: 75,
                            thumbnail: false,
                        },
                        defined(appID)
                    )}
                    tw="object-contain max-h-6 max-w-[188px] not-last:mr-2"
                />
            </div>
        )
    ) : null;

    const textComponent = isDefined(title) && <span tw="text-base truncate gp-md:text-lg">{title}</span>;

    if (!isMobile || isInModal || !firstComponentHasTitle) {
        return (
            <>
                {logoComponent}
                {textComponent}
            </>
        );
    }

    if (isBelow && firstComponentHasTitle) {
        return null;
    }

    return (
        <>
            {logoComponent}
            {isDefined(title) && (
                <span
                    css={css`
                        ${tw`duration-150 ease-out transform translate-y-full opacity-0`}

                        &.show-title {
                            ${tw`translate-y-0 opacity-100`}
                        }
                    `}
                    className={classNames(mustShowTitleInNavBar && "show-title")}
                    style={{ willChange: "opacity, transform" }}
                    tw="text-base truncate gp-md:text-lg"
                >
                    {title}
                </span>
            )}
        </>
    );
};
