import { isCustomDomain } from "./customDomains";
import { getFeatureSetting } from "./feature-settings";
import type { match } from "react-router-dom";

export const verifyEmailPattern = "/verifyEmail";
export const overview = "/";
export const preConnectStripe = "/preConnectStripe";
export const connectStripe = "/connectStripe";
export const preConnectMicrosoft = "/preConnectMicrosoft";
export const connectMicrosoft = "/connectMicrosoft";
export const authorizePlugin = "/authorizeplugin/:id/:extra?";
export const reauthorizePlugin = "/reauthorizeplugin/:id";
export const preConnectGCP = "/preConnectGCP";
export const connectGCP = "/connectGCP";
export const preConnectGmail = "/preConnectGmail";
export const playPattern = "/play/:id/:mode?";
// Play2 is the route for the new player, it will only support Pages.
export const play2Pattern = "/play2/:id/:mode?";
export const play2OauthRedirect = "/oauth-redirect-play2/:id";
export const customDomainPlayPattern = "/:mode?";
export const templatePreviewPattern = "/template/:id";
export const AIPlayground = "/invent";

export function app(appID: string, page?: string, sub?: string, subData?: string): string {
    let r = `/app/${appID}/${page ?? "layout"}`;
    if (sub !== undefined) {
        r = `${r}/${sub}`;
        if (subData !== undefined) {
            r = `${r}/${subData}`;
        }
    }
    return r;
}

export function play(appID: string): string {
    return `/play/${appID}`;
}

export function preConnectStripePath(forTemplateStore: boolean, onBehalfOf?: string): string {
    const basisURL = new URL(window.location.href);
    basisURL.pathname = preConnectStripe;

    if (onBehalfOf !== undefined) {
        basisURL.searchParams.append("onBehalfOf", onBehalfOf);
    }
    if (forTemplateStore) {
        basisURL.searchParams.append("forTemplateStore", "");
    }
    return basisURL.href;
}

export function purchaseTemplate(appID: string, onBehalfOf: string, idempotencyKey: string): string {
    return `/purchaseTemplate/${appID}?onBehalfOf=${onBehalfOf}&idempotencyKey=${encodeURIComponent(idempotencyKey)}`;
}

export function org(orgID: string | undefined): string {
    return `/o/${orgID ?? "0"}`;
}

export function orgFolder(orgID: string | undefined, folderID: string): string {
    return `${org(orgID)}/f/${folderID}`;
}

export function newApp(orgID: string): string {
    return `/o/${orgID}/newApp`;
}

export function newAppFromAI(orgID: string): string {
    return `/o/${orgID}/newApp#create-app-ai`;
}

export function freeTrial(orgID: string): string {
    return `/o/${orgID}/trial`;
}

export function newAppFullScreen(orgID: string): string {
    return `/o/${orgID}/newProject`;
}

export function memberJoinedOrg(orgID: string): string {
    return `/o/${orgID}/joined`;
}

export function templates(orgID: string | undefined): string {
    return `${org(orgID)}/templates`;
}

export function members(orgID: string | undefined): string {
    return `${org(orgID)}/members`;
}

export function billing(orgID: string | undefined): string {
    return `${org(orgID)}/billing`;
}

export function learn(orgID: string | undefined): string {
    return `${org(orgID ?? "0")}/learn`;
}

export function upgrade(orgID: string, includeFree?: boolean): string {
    return `${org(orgID)}/upgrade${includeFree === true ? "/new" : ""}`;
}

export function usage(orgID: string): string {
    return `${org(orgID)}/usage`;
}

export function newUsage(orgID: string): string {
    return `${org(orgID)}/usage-new`;
}

export function orgSettings(orgID: string): string {
    return `${org(orgID)}/settings`;
}

export function appTabs(appID: string, tab?: string): string {
    if (tab === undefined) {
        return `${app(appID, "tabs")}/tabs`;
    } else {
        return `${app(appID, "tabs")}/${tab}`;
    }
}

export function appData(appID: string): string {
    return `${app(appID, "data")}`;
}

export function appSettings(appID: string, tab?: string): string {
    if (tab === undefined) {
        return `${app(appID, "settings")}`;
    } else {
        return `${app(appID, "settings")}/${tab}`;
    }
}

export function acceptRequestedAppUser(appID: string, requestID: string): string {
    return app(appID, "approve-app-user", requestID);
}

export function appAdmin(appID: string): string {
    return `${app(appID, "admin")}`;
}

export function template(templateID: string): string {
    return `/template/${templateID}`;
}

export function newFromTemplate(templateID: string): string {
    return `/newFromTemplate/${templateID}`;
}

export interface ActionsRouteProps {
    actionID: string;
    nodeKey?: string;
}

export function getWorkflowPathName(): "workflows" | "actions" {
    return getFeatureSetting("replaceActionsRoutesWithWorkflows") ? "workflows" : "actions";
}

export function appActions(appID: string, actionID: string, showRecentRuns?: boolean): string {
    return `${app(appID, getWorkflowPathName())}/${actionID}${showRecentRuns === true ? "/recent" : ""}`;
}

export function appLayoutActions(appID: string, actionID: string, showRecentRuns?: boolean): string {
    return `${app(appID, `layout/${getWorkflowPathName()}`)}/${actionID}${showRecentRuns === true ? "/recent" : ""}`;
}

export function appWorkflowsHistoryRun(appID: string, actionID: string, runID: string): string {
    return `${appActions(appID, actionID)}/history/${runID}`;
}
export function appIntegrations(appID: string): string {
    return app(appID, "settings/integrations");
}

export function appIntegrationWithPluginID(appID: string, pluginID: string): string {
    return `${app(appID, "settings/integrations")}/${pluginID}`;
}

export function appIntegrationWithConfigID(appID: string, configID: string): string {
    return `${app(appID, "settings/integrations")}/${configID}`;
}

export interface AppSettingsTabRouteProps {
    sectionID?: string;
}

export function appAutoPublish(appID: string): string {
    return app(appID, "autoPublish");
}

export function fullScreen(appID: string, retainDeeplink: boolean): string {
    const url = new URL(window.location.href);

    url.searchParams.set("full", "t");

    if (retainDeeplink) {
        url.pathname = window.location.pathname;
    }

    if (!isCustomDomain(window.location.hostname)) {
        url.pathname = play(appID) + url.pathname;
    }

    return url.pathname + url.search;
}

export function isTemplatePlayer(): boolean {
    return window.location.href.includes("/template/");
}

export function isSuperchargedStorybook(): boolean {
    return window.location.host === "localhost:9001";
}

export function isPlayer(): boolean {
    const isStory = isSuperchargedStorybook();
    const isPlayRoute = window.location.href.includes("/play/");
    const isPlay2Route = window.location.href.includes("/play2/");
    const isTemplateRoute = isTemplatePlayer();
    return isPlayRoute || isPlay2Route || isTemplateRoute || isStory || isCustomDomain(window.location.hostname);
}

export function isStorybook(): boolean {
    return window.location.host.endsWith(":9009") || window.location.host.endsWith(":9001");
}

export function isPlayerFullScreen(): boolean {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split("/");
    const isFullPath = pathParts.length > 1 && pathParts[pathParts.length - 1] === "full";
    const isFullQuery = url.searchParams.get("full") !== null;
    return (isPlayer() && isFullPath) || isFullQuery;
}

export function appIdOrCustomDomain(): string {
    const match = window.location.pathname.match(/\/play\/(\w+)/);
    return match === null ? window.location.hostname : decodeURIComponent(match[1]);
}

export const appPattern = "/app/:id/:page?/:tab?/:tabdata?";

export interface AppPatternRouteProps {
    id: string;
    page: string | undefined;
    tab: string | undefined;
    tabdata: string | undefined;
}

export interface OrgPatternRouteProps {
    orgID: string;
    page: string | undefined;
}

export interface OrgFolderPatternRouteProps {
    orgID: string;
    folderID: string;
}

export function appQueryBasePath(appID: string): string {
    return `/app/${appID}/queries/`;
}

export function appQueryPath(appID: string, sourceKind: string, sourceID: string) {
    return `${appQueryBasePath(appID)}${encodeURIComponent(sourceKind)}/${encodeURIComponent(sourceID)}`;
}

export const appLayoutPattern = "/app/:id/layout/:tab?";
export const appDataPattern = "/app/:id/data";
export const appQueriesPattern = "/app/:id/queries";
export const appPreviewPattern = "/app/:id/live-preview";
export const appSettingsPattern = "/app/:id/settings/:tab?/:sectionID?";
export const appSettingsSigninPattern = "/app/:id/settings/signin";
export const appSettingsUserAgreementsPattern = "/app/:id/settings/agreements";
// We use (actions|workflows) to match both old and new routes
export const appWorkflowsPattern = "/app/:id/:page(actions|workflows)/:actionID?";
export const appLayoutWorkflowsPattern = "/app/:id/layout/:page(actions|workflows)/:actionID?";
export const appWorkflowsHistoryPattern = `${appWorkflowsPattern}/history/:runID`;
export const acceptRequestedAppUserPattern = "/app/:id/approve-app-user/:requestID?";

export const newRoute = "/new";
export const orgPattern = "/o/:orgID/:page?";
export const folderPattern = `${orgPattern}/f/:folderID`;
export const buyPattern = "/buy/:target";
export const orgNewApp = `${orgPattern}/newApp`;
export const startTrialPattern = `${orgPattern}/trial`;
export const orgCreatePattern = `${orgPattern}/create`;
export const orgCreatedPattern = `${orgPattern}/created`;
export const orgTemplatesPattern = `${orgPattern}/templates`;
export const orgMembersPattern = `${orgPattern}/members`;
export const orgBillingPattern = `${orgPattern}/billing`;
export const usagePattern = `${orgPattern}/usage`;
export const analyticsPattern = `${orgPattern}/analytics`;
export const settingsPattern = `${orgPattern}/settings`;
export const learnPattern = `${orgPattern}/learn`;
export const orgAcceptPattern = `/join/:joinID/:orgName?`;
export const dataRightsPattern = `${orgPattern}/account/rights`;
export const pricingTableCheckoutSuccessPattern = "/checkoutSuccess";

export const downloadExportPattern = "/downloadExport/:exportID";

export const appPaths: string[] = [
    appLayoutPattern,
    appDataPattern,
    appSettingsPattern,
    appWorkflowsPattern,
    appPattern,
    acceptRequestedAppUserPattern,
];

export const orgsPaths: string[] = [
    overview,
    orgPattern,
    folderPattern,
    newRoute,
    buyPattern,
    orgNewApp,
    startTrialPattern,
    orgMembersPattern,
    orgBillingPattern,
    usagePattern,
    dataRightsPattern,
    verifyEmailPattern,
    orgAcceptPattern,
];

export const anyActionsPattern: string[] = ["/app/:id/actions/:actionID", "/app/:id/layout/actions/:actionID"];

export const purchaseTemplatePattern = "/purchaseTemplate/:templateID";
export const newFromTemplatePattern = "/newFromTemplate/:templateID";
export const supportCodePattern = "/support/:code";

function isAppBuilder(): boolean {
    return window.location.href.includes("/app/");
}

export function getPlayLinkForCurrentLocation(preferredDefaultLocation: string | undefined): string | undefined {
    if (isPlayer()) return window.location.href;
    if (!isAppBuilder()) return undefined;
    if (/\/app\/sample/.test(window.location.href)) return undefined;
    if (/\/app\/.*-template$/.test(window.location.href)) return undefined;

    return preferredDefaultLocation ?? window.location.href.replace("/app/", "/play/");
}

// These two are for compatibility reasons.
export function isWorkflowsPage(match: match<AppPatternRouteProps> | null) {
    if (match === null || match.params.page === undefined) return false;
    return ["workflows", "actions"].includes(match.params.page);
}

export function isWorkflowsTab(match: match<AppPatternRouteProps> | null) {
    if (match === null || match.params.tab === undefined) return false;
    return ["workflows", "actions"].includes(match.params.tab);
}
