import { getAppTabs } from "@glide/common-core/dist/js/components/SerializedApp";
import {
    type ActionDescription,
    type PropertyDescription,
    type TabDescription,
    ActionKind,
    PropertyKind,
    getScreenProperty,
} from "@glide/app-description";
import { type AppDescriptionContext, PropertySection, type ActionAvailability } from "@glide/function-utils";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

interface NavigateToTabActionDescription extends ActionDescription {
    readonly screen: PropertyDescription | undefined;
}

function getTab(
    ccc: AppDescriptionContext,
    desc: NavigateToTabActionDescription
): { tabIndex: number; tab: TabDescription } | undefined {
    const { appDescription } = ccc;
    if (appDescription === undefined) return undefined;

    const screenName = getScreenProperty(desc.screen);
    if (screenName === undefined) return undefined;
    const tabs = getAppTabs(appDescription);
    const tabIndex = tabs.findIndex(t => getScreenProperty(t.screenName) === screenName);
    const tab = tabs[tabIndex];
    if (tab === undefined || tab.hidden) return undefined;
    return { tab, tabIndex };
}

export class NavigateToTabHandler extends BaseActionHandler<NavigateToTabActionDescription> {
    public readonly kind = ActionKind.NavigateToTab;
    public readonly iconName: GlideIconProps = {
        icon: "st-arrow-inspect",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Go to tab";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 8,
            needsScreenContext: true,
            properties: [
                {
                    property: { name: "screen" },
                    section: PropertySection.Data,
                    label: "Tab",
                    kind: PropertyKind.Screen,
                },
            ],
        };
    }

    public getDescriptionToWalk(
        desc: NavigateToTabActionDescription,
        ccc: AppDescriptionContext
    ): NavigateToTabActionDescription {
        const maybeTab = getTab(ccc, desc);
        if (maybeTab === undefined) {
            return { ...desc, screen: undefined };
        } else {
            return desc;
        }
    }

    public getTokenizedDescription(
        desc: NavigateToTabActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const maybeTab = getTab(env.context, desc);
        if (maybeTab === undefined) return undefined;

        return [
            {
                kind: "column",
                value: maybeTab.tab.title ?? maybeTab.tab.icon,
            },
        ];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: NavigateToTabActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const maybeTab = getTab(ib.adc, desc);
        const screenName = getScreenProperty(maybeTab?.tab.screenName);
        if (screenName === undefined) return arb.inflationError("Invalid tab");

        arb = arb.addData({ title: maybeTab?.tab.title, screenName });

        return hb => {
            if (!hb.getIsTabVisible(screenName)) return arb.error(true, "Tab is not visible");
            return async ab => {
                const result = ab.navigateToTab(screenName, true);
                return arb.fromResult(result);
            };
        };
    }
}
