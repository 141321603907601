import { css, styled } from "@glide/common-components";
export const AnimatedImage = styled.img<{ fadeIn: boolean }>`
    ${p =>
        p.fadeIn === true &&
        css`
            @keyframes fadein {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            animation: fadein 0.1s linear 1;
        `};
`;

export const EmojiImgWrapper = styled.div`
    height: 100%;
`;

export const EmojiImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
