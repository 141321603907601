import type { EminenceFlags } from "@glide/billing-types";
import type { GetOwnerEminenceBody } from "@glide/common-core";
import { getOwnerEminenceResponse } from "@glide/common-core";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { getQuadraticBackoffWaitTime, isResponseOK } from "@glide/support";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { isLeft } from "fp-ts/lib/Either";

const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;

/**
 * Custom hook to fetch the eminence flags for an organization via TanStack query.
 *
 * @param organizationID - The ID of the organization.
 * @returns A useQuery result for the eminence flags of the organization.
 */
export function useEminenceFlags(organizationID: string | undefined): UseQueryResult<EminenceFlags> {
    return useQuery({
        queryKey: ["useEminenceFlags", organizationID],
        queryFn: async () => {
            if (organizationID === undefined) {
                throw new Error("Cannot fetch eminence flags without an organization ID");
            }

            const body: GetOwnerEminenceBody = {
                ownerID: organizationID,
            };
            const response = await getAppFacilities().callAuthCloudFunction("getOwnerEminence", body);
            if (!isResponseOK(response)) {
                throw new Error("Error fetching getOwnerEminence");
            }

            const decodedResponse = getOwnerEminenceResponse.decode(await response.json());
            if (isLeft(decodedResponse)) {
                throw new Error("Error decoding getOwnerEminence response");
            }

            const organizationEminenceFlags = decodedResponse.right.apps[organizationID];
            return organizationEminenceFlags;
        },
        retryDelay: attemptIndex => getQuadraticBackoffWaitTime(500, attemptIndex),
        refetchOnWindowFocus: false,
        enabled: organizationID !== undefined,
        refetchInterval: FIVE_MINUTES_IN_MILLISECONDS,
    });
}
