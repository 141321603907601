import type { WireAppTheme } from "@glide/theme";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { TextComponentStyle } from "@glide/component-utils";
import classNames from "classnames";
import type { HTMLAttributes } from "react";
import * as React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";

type Span = { element?: "span" } & HTMLAttributes<HTMLSpanElement>;
type Paragraph = { element: "p" } & HTMLAttributes<HTMLParagraphElement>;
type Heading = { element: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" } & HTMLAttributes<HTMLHeadingElement>;

type TextKinds = Span | Paragraph | Heading;

type TextProps = TextKinds & {
    variant: TextComponentStyle;
};

type Color = string;

const getThemeColorForVariant = (theme: WireAppTheme, variant: TextComponentStyle): Color => {
    if (
        [
            TextComponentStyle.headlineXLarge,
            TextComponentStyle.headlineLarge,
            TextComponentStyle.headlineMedium,
            TextComponentStyle.headlineSmall,
            TextComponentStyle.headlineXSmall,
            TextComponentStyle.headlineXXSmall,
            TextComponentStyle.headlineXXXSmall,
        ].includes(variant)
    )
        return theme.textContextualDark;

    if ([TextComponentStyle.large, TextComponentStyle.regular, TextComponentStyle.small].includes(variant))
        return theme.textContextualPale;

    if (TextComponentStyle.metaText === variant) return theme.textContextualAccent;

    if (TextComponentStyle.footnote) return theme.textContextualXpale;

    return theme.textContextualBase;
};

const TextBase = styled.span``;

export const Text = React.forwardRef<HTMLSpanElement, TextProps>(
    ({ element = "span", children, variant, className, ...p }, ref) => {
        const theme = useWireAppTheme();
        const textColor = React.useMemo(() => getThemeColorForVariant(theme, variant), [theme, variant]);

        return (
            <TextBase
                ref={ref}
                css={css`
                    --text-color: ${textColor};

                    ${tw`[color:var(--text-color)]`}

                    &.${TextComponentStyle.headlineXLarge},
                    &.${TextComponentStyle.headlineLarge},
                    &.${TextComponentStyle.headlineMedium},
                    &.${TextComponentStyle.headlineSmall},
                    &.${TextComponentStyle.headlineXSmall},
                    &.${TextComponentStyle.headlineXXSmall},
                    &.${TextComponentStyle.headlineXXXSmall} {
                        ${tw`font-semibold`}
                    }

                    &.${TextComponentStyle.headlineXLarge} {
                        ${tw`[font-size:30px] leading-tight tracking-tight page-md:([font-size:48px]) page-lg:(text-5xl)`}
                    }

                    &.${TextComponentStyle.headlineLarge} {
                        ${tw`[font-size:26px] leading-snug tracking-tight page-md:([font-size:32px]) page-lg:(text-3xl)`}
                    }

                    &.${TextComponentStyle.headlineMedium} {
                        ${tw`text-2xl leading-snug tracking-tight`}
                    }

                    &.${TextComponentStyle.headlineSmall} {
                        ${tw`[font-size:20px] leading-snug tracking-tight page-lg:text-xl`}
                    }

                    &.${TextComponentStyle.headlineXSmall} {
                        ${tw`[font-size:18px] leading-normal tracking-tight text-lg`}
                    }

                    &.${TextComponentStyle.headlineXXSmall} {
                        ${tw`[font-size:17px] leading-normal tracking-tight`}
                    }

                    &.${TextComponentStyle.headlineXXXSmall} {
                        ${tw`text-base leading-normal tracking-tight`}
                    }

                    &.${TextComponentStyle.large}, &.${TextComponentStyle.regular}, &.${TextComponentStyle.small} {
                        ${tw`leading-normal`}
                    }

                    &.${TextComponentStyle.metaText} {
                        ${tw`text-xs font-semibold leading-normal uppercase`}
                    }

                    &.${TextComponentStyle.large} {
                        ${tw`text-lg`}
                    }

                    &.${TextComponentStyle.regular} {
                        ${tw`text-base`}
                    }

                    &.${TextComponentStyle.small} {
                        ${tw`text-sm`}
                    }

                    &.${TextComponentStyle.footnote} {
                        ${tw`text-xs leading-normal`}
                    }
                `}
                as={element}
                className={classNames(className, variant)}
                {...p}>
                {children}
            </TextBase>
        );
    }
);
