// This is the "new" barcode scanner, which includes the Dynamsoft scanner.

import {
    type ActionDescription,
    type PropertyDescription,
    ActionKind,
    PropertyKind,
    getActionProperty,
} from "@glide/app-description";
import { hasInputContext } from "@glide/common-core/dist/js/description";
import {
    type ActionsRecord,
    type AppDescriptionContext,
    type EditedColumnsAndTables,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    SwitchPropertyHandler,
    combineEditedColumnsAndTables,
    mustUseDynamsoftScanner,
    hasCustomKeyForDynamsoftScanner,
    dynamsoftScannerNumUpdates,
    getPrimitiveNonHiddenColumnsSpec,
    type ActionAvailability,
} from "@glide/function-utils";
import type {
    WireActionHydrator,
    WireActionInflationBackend,
    WireActionResult,
    WireActionResultBuilder,
} from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";
import type { AppKind } from "@glide/location-common";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { inflateScanBarcodeAction } from "./scan-code";
import { getPrimitiveActionKinds, handlerForActionKind } from ".";
import { type GlideIconProps, type PluginTierList, makeTierList } from "@glide/plugins";
import type { StaticActionContext } from "../static-context";
import { ICON_PALE } from "../plugins/icon-colors";

interface LiveScanLinearBarcodeActionDescription extends ActionDescription {
    readonly output: PropertyDescription;
    readonly onScanAction: PropertyDescription | undefined;
    readonly closeAfterScanning: PropertyDescription | undefined;
}

const columnPropertyHandler = new ColumnPropertyHandler(
    "output",
    "Column",
    [
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    ["item", "sku", "upc", "ean", "barcode"],
    getPrimitiveNonHiddenColumnsSpec,
    "string",
    PropertySection.Data
);
const closeAfterScanningPropertyHandler = new SwitchPropertyHandler(
    { closeAfterScanning: true },
    "Automatically close after scanning",
    PropertySection.ScanningOptions
);

export class LiveScanLinearBarcodeActionHandler extends BaseActionHandler<LiveScanLinearBarcodeActionDescription> {
    public readonly kind = ActionKind.LiveScanLinearBarcode;

    public readonly iconName: GlideIconProps = {
        icon: "st-scan-barcode",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };
    public readonly name: string = "Scan barcode or QR code";

    public get handlesSequencing(): boolean {
        return true;
    }

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getTier(appKind: AppKind): PluginTierList | undefined {
        if (mustUseDynamsoftScanner(appKind)) {
            return makeTierList("business");
        } else {
            return makeTierList("starter");
        }
    }

    public getBillablesConsumed(env: StaticActionContext<AppDescriptionContext>): number | undefined {
        const hasThirdPartyScanner = hasCustomKeyForDynamsoftScanner(env.context.userFeatures);
        if (hasThirdPartyScanner) {
            return undefined;
        } else {
            return dynamsoftScannerNumUpdates;
        }
    }

    public getDescriptor(
        _desc: LiveScanLinearBarcodeActionDescription | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const hasThirdPartyScanner = hasCustomKeyForDynamsoftScanner(ccc.userFeatures);

        const actionKinds: readonly ActionKind[] = getPrimitiveActionKinds(
            hasInputContext(mutatingScreenKind),
            ccc.userProfileTableInfo !== undefined
        );

        const properties: PropertyDescriptor[] = ccc.userFeatures.massScanBarcodes
            ? [
                  columnPropertyHandler,
                  {
                      kind: PropertyKind.Action,
                      property: { name: "onScanAction" },
                      label: "Scan action ",
                      required: false,
                      kinds: actionKinds,
                      section: PropertySection.ScanAction,
                  },
                  closeAfterScanningPropertyHandler,
              ]
            : [columnPropertyHandler];

        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 4,
            needsScreenContext: true,
            isLegacy: !(mustUseDynamsoftScanner(ccc.appKind) || hasThirdPartyScanner),
            properties,
        };
    }

    public getActions(desc: LiveScanLinearBarcodeActionDescription): ActionsRecord {
        const onScan = getActionProperty(desc.onScanAction);
        return { actions: [], onScanAction: definedMap(onScan, a => [a]) ?? [] };
    }

    public getEditedColumns(
        desc: LiveScanLinearBarcodeActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): EditedColumnsAndTables | undefined {
        const editedColumns = super.getEditedColumns(desc, env);
        if (editedColumns === undefined) return undefined;

        const onScanDesc = getActionProperty(desc.onScanAction);
        if (onScanDesc === undefined) return editedColumns;

        const handler = handlerForActionKind(onScanDesc.kind);

        const onScanEdited = handler.getEditedColumns(onScanDesc, env);
        if (onScanEdited === undefined) return editedColumns;

        return combineEditedColumnsAndTables(editedColumns, onScanEdited);
    }

    public getTokenizedDescription(
        desc: LiveScanLinearBarcodeActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const token = tokenForProperty(desc.output, env);
        if (token === undefined) return undefined;
        return [{ kind: "string", value: "into " }, token];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: LiveScanLinearBarcodeActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        return inflateScanBarcodeAction(ib, desc.output, undefined, arb);
    }
}
