import { exceptionToString } from "@glideapps/ts-necessities";
import { RecurrentBackgroundJob, logError, logInfo } from "@glide/support";
import isError from "lodash/isError";

import { frontendSendEvent } from "../tracing";

const pollingPeriod = 10 * 1000;

let pollerId = 0;

interface Listener<T> {
    readonly onUpdate: (results: T, durationMs?: number) => void;
    readonly onError?: (e: Error) => void;
}

export class Poller<TState, TResult> {
    private readonly listeners: Listener<TResult>[] = [];

    // only used for logging
    private readonly name: string;

    private readonly job: RecurrentBackgroundJob;

    // The last polling state, if any
    private state?: TState;

    // A value that can be passed to clearInterval
    private interval?: ReturnType<typeof setInterval>;

    // The given call back should perform a paginated poll, starting from the record indicated by `state` and calling `cb` for each page of results.
    public constructor(
        name: string,
        private readonly poll: (
            state: TState | undefined,
            cb: (results: TResult, durationMs?: number) => Promise<boolean>
        ) => Promise<TState | undefined>
    ) {
        this.name = `Poller${pollerId++}:${name}`;
        this.job = new RecurrentBackgroundJob(() => this.pollAndUpdateListeners());
    }

    private log(str: string) {
        logInfo(`[${new Date().toLocaleString()}] [${this.name}] -  ${str}`);
    }

    private async pollAndUpdateListeners() {
        const { listeners, state } = this;
        if (listeners.length === 0) {
            this.log("pollAndUpdateListeners: Returning early because we have no more listeners");
            return;
        }
        try {
            this.log(`pollAndUpdateListeners: calling poll() with state: ${state}`);
            this.state = await this.poll(state, async (result, durationMs) => {
                // This callback will be called multiple times per poll for paginated results,
                //  so it's possible all listeners were removed during this time. Returning false
                //  here should prevent further enumeration of paginated results.
                if (listeners.length === 0) {
                    this.log("poll callback: Returning false because listeners.length === 0");
                    return false;
                }

                this.log("poll callback: updating listeners");
                for (const listener of listeners) {
                    try {
                        listener.onUpdate(result, durationMs);
                    } catch (le1: unknown) {
                        logError("Error calling listener.onUpdate", le1);
                    }
                }
                return true;
            });
            this.log(`pollAndUpdateListeners: poll() finished with new state: ${this.state}`);
        } catch (e: unknown) {
            logError("pollAndUpdateListeners: Failed to poll database", e);
            frontendSendEvent("firestorePollingFailed", 0, {
                poll_name: this.name,
                exception: exceptionToString(e),
            });
            if (isError(e)) {
                for (const listener of listeners) {
                    try {
                        listener.onError?.(e);
                    } catch (le2: unknown) {
                        logError("pollAndUpdateListeners: Error calling listener.onError", le2);
                    }
                }
            }
        }
    }

    // Adds a listener to this poller and (re)starts listening
    public async addListener(listener: Listener<TResult>, abort: AbortSignal) {
        const { listeners, interval, job } = this;
        const i = listeners.indexOf(listener);
        if (i >= 0 || abort.aborted) return;

        // We'll reset the interval if one was set, to sync this new listener with existing listeners.
        if (interval !== undefined) {
            clearInterval(interval);
            this.interval = undefined;
        }

        // If there's already a poll in flight, wait for it to finish first, so this listener doesn't
        //  get partial results.
        await job.wait();
        if (abort.aborted) return;

        listeners.push(listener);

        // If no one beat us to it, reset the state and interval and poll for all data for this new listener
        //  (existing listeners will get all the data too, but this shouldn't be an issue)
        if (this.interval === undefined) {
            this.state = undefined;
            this.interval = setInterval(() => job.request(), pollingPeriod);
            job.request();
        }
    }

    public removeListener(listener: Listener<TResult>) {
        const { listeners, interval } = this;
        const i = listeners.indexOf(listener);
        if (i >= 0) listeners.splice(i, 1);

        if (listeners.length === 0 && interval !== undefined) {
            this.log("removeListener: clearing interval because there are no more listeners");
            clearInterval(interval);
            this.interval = undefined;
        }
    }
}
