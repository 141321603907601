import { styled } from "@glide/common";

export const SectionTabBarWrapper = styled.div`
    border-radius: 10px;
    padding: 1px;
    background-color: ${p => p.theme.sectionTabBar.bgColor};
    height: 32px;
    display: flex;
    flex-shrink: 0; /* Without this, Safari won't render the tabs properly. */
    overflow: hidden;

    &.wide {
        width: 100%;
        max-width: 65%;

        .stb-item {
            width: 1px;
            min-width: 1px;
            flex-grow: 1;
        }
    }

    &.stretch .stb-item {
        width: 1px;
        min-width: 1px;
        flex-grow: 1;
    }

    .stb-item {
        position: relative;
        line-height: 120%;
        margin: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: ${p => p.theme.sectionTabBar.item.fgColor};
        background-color: transparent;
        border-radius: ${p => p.theme.formControlRadius};
        font-size: 13px;
        overflow: hidden;
        font-weight: 400;
        transition: all 0.125s ease-out;

        :hover,
        :focus {
            background-color: ${p => p.theme.sectionTabBar.item.bgColorHover};
            color: ${p => p.theme.sectionTabBar.item.fgColorHover};
        }

        &.selected,
        &.selected:hover,
        &.selected:focus {
            font-weight: 600;
            background-color: ${p => p.theme.sectionTabBar.item.bgColorSelected};
            box-shadow: ${p => p.theme.shadow2};
            color: ${p => p.theme.sectionTabBar.item.fgColorSelected};
        }
    }
`;
