interface SVGProps {
    href: string;
}

export const SVG: React.VFC<SVGProps> = ({ href }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <use xlinkHref={`${href}#icon-import`} />
        </svg>
    );
};
