import type { WireAppTheme } from "@glide/theme";

type ThemeValue = string | { key: string };
type ThemeConfig = Record<string, ThemeValue>;

export function replaceReferences(theme: WireAppTheme): ThemeConfig {
    const replacedTheme: ThemeConfig = {};

    for (const [key, value] of Object.entries(theme)) {
        if (typeof value === "string") {
            replacedTheme[key] = value.startsWith("_") ? `var(--${value.slice(1)})` : value;
        } else if (typeof value === "object" && value !== null && "key" in value) {
            replacedTheme[key] = {
                key: (
                    value as {
                        key: string;
                    }
                ).key.startsWith("_")
                    ? `var(--${value.key.slice(1)})`
                    : value.key,
            };
        } else {
            replacedTheme[key] = value;
        }
    }

    return replacedTheme;
}

export function getTokenList(theme: Record<string, any>): Record<string, string> {
    const specificTokenKeys = [
        "textDark",
        "textPale",
        "iconBase",
        "iconPale",
        "bgFront",
        "bgBack",
        "borderBase",
        "borderPale",
    ];

    const colorTokenKeys = [
        "n0",
        "n0A",
        "n50",
        "n50A",
        "n100",
        "n100A",
        "n200",
        "n200A",
        "n300",
        "n300A",
        "n400",
        "n400A",
        "n500",
        "n500A",
        "n600",
        "n600A",
        "n700",
        "n700A",
        "n800",
        "n800A",
        "n900",
        "n900A",
    ];

    const allTokenKeys = [...specificTokenKeys, ...colorTokenKeys];

    return allTokenKeys.reduce((acc, key) => {
        if (key in theme) {
            const value = theme[key];
            let formattedKey: string;

            if (colorTokenKeys.includes(key)) {
                formattedKey = key; // Keep color tokens as they are
            } else {
                formattedKey = key.replace(/([A-Z])/g, "-$1").toLowerCase();
            }

            acc[formattedKey] = typeof value === "string" ? value : value.key;
        }
        return acc;
    }, {} as Record<string, string>);
}
