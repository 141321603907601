import { setCloudRegion } from "@glide/common-core/dist/js/function-region";
import { generateFirestoreDocumentID } from "@glide/common-core/dist/js/id-generator";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import { isDocumentHidden } from "@glide/common-core/dist/js/support/browser-hacks";
import { isTryingToReload, reloadBrowserWindow } from "@glide/common-core/dist/js/support/browser-reload";
import { waitForUnblockedWindowExit } from "@glide/common-core/dist/js/support/window-exit-blocking";
import { frontendSendEvent } from "@glide/common-core/dist/js/tracing";
import { blockingServiceWorkerInstall } from "@glide/common-core/dist/js/utility/register-service-worker";
import { exceptionToString, sleep } from "@glideapps/ts-necessities";
import {
    getCurrentTimestampInMilliseconds,
    hasUsableSessionStorage,
    logError,
    logInfo,
    sessionStorageGetItem,
    sessionStorageSetItem,
} from "@glide/support";

import { setOriginOnline } from "./network-tracking";
import { getFeatureSetting } from "@glide/common-core";

const deploymentVersion = (window as any).glideDeploymentVersion as string;
// 10 second header timeouts interact poorly with bad Service Worker cache
// write performance, as that has a 15 second timeout.
const updateHeaderTimeout = 20_000;
const updateBodyTimeout = 40_000;

const deploymentVersionRegex = /(?:^|\s|;|>)window\.glideDeploymentVersion\s*=\s*[\"']([^\"']+)[\"']/;
function extractGlideDeploymentVersion(body: string): string | undefined {
    const match = deploymentVersionRegex.exec(body);
    return match === null ? undefined : match[1];
}

const nextVersionSessionStorageKey = "glide-next-version";
const lastRegionSessionKey = "glide-last-region";

export async function watchUpdates(): Promise<void> {
    let lastUpdate = Date.now();

    // We can't check for updates unless session storage works.
    // This is in place to guard against reload loops caused by deployment
    // race conditions.
    if (!hasUsableSessionStorage()) {
        logError("Can't watch for updates due to broken SessionStorage");
        return;
    }
    const expectedVersion = sessionStorageGetItem(nextVersionSessionStorageKey);
    if (expectedVersion === undefined || expectedVersion === deploymentVersion) {
        // If we've never established our next version, or we've successfully
        // loaded our expected next version, we should check for updates immediately.
        // If we've established our next version but have somehow failed to load it,
        // we're going to wait a full ten minutes before we try again.
        lastUpdate -= 10 * 60 * 1000;
    }

    const lastLoadedRegion = sessionStorageGetItem(lastRegionSessionKey);
    if (lastLoadedRegion !== undefined) {
        setCloudRegion(lastLoadedRegion);
    }

    async function waitForever(seconds: number): Promise<true> {
        await sleep(seconds * 1000);
        return true;
    }

    let data: string | undefined;
    do {
        // moving this here as to skip the rest of the loop if we want to disable watch updates.
        // we can't return early since the feature flags are loaded on first call.

        const returnEarlyInFavorOfPolling =
            (isPlayer() && getFeatureSetting("clientVersionPollingForUpdatesInPlayer")) ||
            (!isPlayer() && getFeatureSetting("clientVersionPollingForUpdates"));

        if (returnEarlyInFavorOfPolling) {
            // breaking actually means "do the reload", so we just want to continue.
            // TODO: once the above flags are on, remove all this code.
            continue;
        }

        let fetchedHeaders = false,
            fetchedBody = false,
            timedOut = false,
            status: number | undefined,
            headerTime: number | undefined;
        const requestID = generateFirestoreDocumentID();
        const startTime = getCurrentTimestampInMilliseconds();
        try {
            if (Date.now() - lastUpdate < 1000 * 60 * 10) {
                // Only perform new version checks once every 10 minutes
                continue;
            }

            // If we're already trying to reload there's no point to watching for updates
            if (isTryingToReload()) break;

            const keepGoing = await blockingServiceWorkerInstall(async () => {
                const abortController = new AbortController();

                const headerTimeoutHandle = setTimeout(() => {
                    timedOut = true;
                    abortController.abort();
                }, updateHeaderTimeout);

                // We have to contend with /app/ and /template/ updating at different cadences
                // than / in the builder. In the player, it's all getting routed to the same cached
                // / anyway.
                const fetchURL = new URL(isPlayer() ? document.location.origin : window.location.href);
                fetchURL.searchParams.set("reqid", requestID);

                // The .href is spurious but our types don't seem to know that
                // `fetch` can just take a URL.
                const response = await fetch(fetchURL.href, {
                    referrerPolicy: "origin",
                    signal: abortController.signal,
                    headers: {
                        "fly-customer-request-id": requestID,
                    },
                });

                const newRegion = response.headers.get("x-fly-region") ?? undefined;
                setCloudRegion(newRegion);
                if (newRegion !== undefined) {
                    sessionStorageSetItem(lastRegionSessionKey, newRegion);
                }

                fetchedHeaders = true;
                headerTime = getCurrentTimestampInMilliseconds() - startTime;
                clearTimeout(headerTimeoutHandle);
                status = response.status;

                const bodyTimeoutHandle = setTimeout(() => abortController.abort(), updateBodyTimeout);
                if (!response.ok) {
                    // Make sure we don't keep the connection stuck open
                    await response.text();
                    fetchedBody = true;
                    clearTimeout(bodyTimeoutHandle);
                    return true;
                }
                setOriginOnline(true);

                data = await response.text();
                fetchedBody = true;
                clearTimeout(bodyTimeoutHandle);

                const nextVersion = extractGlideDeploymentVersion(data);
                if (nextVersion === undefined) {
                    logInfo("Couldn't find deployment version");
                } else {
                    logInfo("Next version is going to be", nextVersion);
                }

                if (nextVersion !== undefined && nextVersion !== deploymentVersion) {
                    logInfo("Setting next Glide deployment version to", nextVersion);
                    sessionStorageSetItem(nextVersionSessionStorageKey, nextVersion);
                    return false;
                }

                lastUpdate = Date.now();
                return true;
            });
            if (!keepGoing) break;
        } catch (e: unknown) {
            logError(`Polling for application updates: ${e}`);
            if (!fetchedHeaders || !fetchedBody) {
                const takenTime = getCurrentTimestampInMilliseconds() - startTime;
                frontendSendEvent(
                    "watchUpdates failure",
                    takenTime,
                    {
                        exception: exceptionToString(e),
                        request_id: requestID,
                        failed_at: fetchedHeaders ? "body" : "headers",
                        status,
                        header_time: headerTime ?? takenTime,
                        reloading: isTryingToReload(),
                    },
                    !timedOut && navigator.onLine === true && !isTryingToReload()
                );
                setOriginOnline(false);
            }
            // We don't need to fast-update at all if we're a hidden document.
            if (isDocumentHidden()) {
                lastUpdate = Date.now();
            }
        }
    } while (await waitForever(10));

    if (isTryingToReload()) return;

    // The remote deployment version is different from our deployment version.
    // We should force a reload to update (this is necessary on iOS PWAs)
    // Chrome Android actually runs in the background, though, and sometimes just _fails_
    // to reload the browser window. Silently, too. That would make us wait for the next
    // 10 minute refresh check window, which we could also miss because we're still
    // in the background.
    //
    // Now, whenever we know we need to reload, we'll be somewhat beligerent about it:
    // try again every 10 seconds until you succeed. 1 second was too long, the Internet
    // is just bad.
    //
    // FIXME: This state should probably block app actions until the window reloads.
    do {
        await waitForUnblockedWindowExit();
        reloadBrowserWindow("deployment versions differ", deploymentVersion, data);
    } while (await waitForever(10));
}
