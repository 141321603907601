import { type LoadedRow, type LoadingValue, isLoadingValue } from "@glide/computation-model-types";
import {
    type TableName,
    type TableGlideType,
    SourceColumnKind,
    findTable,
    getAllowedTablesForAddRow,
    getPrimitiveNonHiddenColumns,
    getTableColumn,
    getTableName,
    isColumnNonHidden,
    isComputedColumn,
    isPrimitiveType,
    isTableWritable,
    makeTableRef,
    sheetNameForTable,
    makePrimitiveType,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type ActionDescription,
    type ColumnAssignment,
    type ColumnAssignmentsWithLegacyDescription,
    type PropertyDescription,
    ActionKind,
    MutatingScreenKind,
    PropertyKind,
    getSourceColumnProperty,
    getTableProperty,
    makeColumnProperty,
    makeTableProperty,
    type ActionOutputDescriptor,
} from "@glide/app-description";
import {
    type AppDescriptionContext,
    type EditedColumn,
    type EditedColumns,
    type EditedColumnsAndTables,
    type PropertyDescriptor,
    getPrimitiveNonHiddenColumnsSpec,
    resolveSourceColumn,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
} from "@glide/function-utils";
import type {
    ValueSetterResult,
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";
import flatMap from "lodash/flatMap";
import fromPairs from "lodash/fromPairs";
import omit from "lodash/omit";
import { getWritableColumnAssignments, makePropertyDescriptorsForColumns } from "../components/descriptor-utils";
import { getColumnAssignmentsWithLegacy } from "../description-utils";
import type { StaticActionContext } from "../static-context";
import { getTargetForLink } from "../link-columns";
import { getCanEditFromNetworkStatus, inflateColumnAssignments } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import type { ActionColumnAssignments, DescriptionToken } from "./action-handler";
import { type AwaitSendDescription, getAwaitSendPropertyDescriptor, shouldAwaitSend } from "./await-send-property";
import { BaseActionHandler, getBillablesConsumedForCRUDActions, tokenForProperty } from "./base";
import type { BillablesConsumed, GlideIconProps } from "@glide/plugins-codecs";

interface AddRowActionDescription
    extends ActionDescription,
        AwaitSendDescription,
        ColumnAssignmentsWithLegacyDescription {
    readonly kind: ActionKind.AddRow;
    readonly tableName: PropertyDescription | undefined;
    readonly writeRowIDTo: PropertyDescription | undefined;
}

const newRowIDResultName = "newRowID";

const writeRowIDToPropertyHandler = new ColumnPropertyHandler(
    "writeRowIDTo",
    "Write row ID to",
    [
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.AllowUserProfileColumns,
        ColumnPropertyFlag.EmptyByDefault,
    ],
    undefined,
    undefined,
    getPrimitiveNonHiddenColumnsSpec,
    "string",
    { name: "Result", order: 0 }
);

function getTable(
    desc: AddRowActionDescription,
    schema: SchemaInspector
): { tableName: TableName; table: TableGlideType } | undefined {
    const tableName = getTableProperty(desc.tableName);
    const table = schema.findTable(tableName);
    if (tableName === undefined || table === undefined) return undefined;
    return { tableName, table };
}

export function makeEditedColumnsFromColumnAssignments(
    columnAssignments: ActionColumnAssignments,
    editsInScreenContext: boolean,
    schema: SchemaInspector
): EditedColumns {
    const { assignments, table, isAddRow } = columnAssignments;
    const tableName = getTableName(table);

    return flatMap(assignments, a => {
        const c = getTableColumn(table, a.destColumn);
        if (c === undefined) return [];

        const results: EditedColumn[] = [[a.destColumn, editsInScreenContext, isAddRow, tableName]];

        const linkTarget = getTargetForLink(table, c, schema, false);
        if (linkTarget !== undefined) {
            // This is where we handle ##editedLinkColumns for add/set
            // actions, as well as for form components.
            results.push([linkTarget.hostColumn.name, editsInScreenContext, isAddRow, tableName]);
        }

        return results;
    });
}

export class AddRowActionHandler extends BaseActionHandler<AddRowActionDescription> {
    public readonly kind = ActionKind.AddRow;
    public readonly iconName: GlideIconProps = {
        icon: "st-plus-add",
        kind: "stroke",
        strokeColor: "var(--gv-lime500)",
    };
    public readonly name: string = "Add row";

    public getIsApplicable(screenHasInputContext: boolean, _appHasUserProfiles: boolean): boolean {
        return screenHasInputContext;
    }

    public getDescriptor(
        desc: AddRowActionDescription | undefined,
        { context: ccc, tables }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const hasUserFeature = ccc.userFeatures.addRowToSheet;
        const destTable = definedMap(desc, d =>
            definedMap(getTableProperty(d.tableName), t => findTable(ccc.schema, makeTableRef(t)))
        );
        let propertyDescriptors: readonly PropertyDescriptor[];
        if (destTable !== undefined) {
            propertyDescriptors = makePropertyDescriptorsForColumns<AddRowActionDescription>(
                ccc,
                tables?.input,
                destTable,
                getColumnAssignmentsWithLegacy,
                columns => ({ columnAssignments: columns, columns: undefined }),
                {
                    withActionSource: hasUserFeature,
                    withClearColumn: false,
                    withLinkColumns: true,
                    withArrays: true,
                    forAddingRow: true,
                    allowCustomAndUserProfile: true,
                    emptyByDefault: false,
                }
            )[0];
        } else {
            propertyDescriptors = [];
        }
        propertyDescriptors = [...propertyDescriptors, ...getAwaitSendPropertyDescriptor(ccc)];
        if (destTable?.rowIDColumn !== undefined) {
            propertyDescriptors = [...propertyDescriptors, writeRowIDToPropertyHandler];
        }

        const outputs: ActionOutputDescriptor[] = [
            {
                name: newRowIDResultName,
                type: makePrimitiveType("string"),
                description: "The RowID of the newly added row",
            },
        ];

        return {
            name: this.name,
            group: ActionGroup.Data,
            groupItemOrder: 1,
            needsScreenContext: true,
            properties: [
                {
                    kind: PropertyKind.Table,
                    property: { name: "tableName" },
                    label: "Table",
                    required: true,
                    getAllowedTables: (_desc, schema) => getAllowedTablesForAddRow(schema.schema),
                    section: PropertySection.Source,
                },
                ...propertyDescriptors,
            ],
            outputs,
        };
    }

    public newActionDescription(env: StaticActionContext<AppDescriptionContext>): AddRowActionDescription | undefined {
        const { tables, context } = env;
        const inputTable = tables?.input;
        let targetTable: TableGlideType | undefined = inputTable;
        if (targetTable === undefined || !isTableWritable(targetTable)) {
            targetTable = context.schema.tables.find(t => !getTableName(t).isSpecial && isTableWritable(t));
            if (targetTable === undefined) return undefined;
        }

        const columnAssignments: ColumnAssignment[] = [];

        for (const c of getPrimitiveNonHiddenColumns(targetTable)) {
            const valueColumn = inputTable !== undefined ? getTableColumn(inputTable, c.name) : undefined;
            if (
                valueColumn !== undefined &&
                isColumnNonHidden(valueColumn) &&
                !isComputedColumn(valueColumn) &&
                isPrimitiveType(valueColumn.type)
            ) {
                columnAssignments.push({ destColumn: c.name, value: makeColumnProperty(valueColumn.name) });
            }
        }

        return {
            kind: this.kind,
            tableName: makeTableProperty(getTableName(targetTable)),
            columnAssignments,
            writeRowIDTo: undefined,
        };
    }

    public getColumnAssignments(
        desc: AddRowActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): ActionColumnAssignments | undefined {
        const ccc = env.context;
        const table = getTable(desc, ccc)?.table;
        if (table === undefined) return undefined;
        const assignments = getWritableColumnAssignments(ccc, table, getColumnAssignmentsWithLegacy(desc), true);
        return { assignments, isAddRow: true, table };
    }

    public getEditedColumns(
        desc: AddRowActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): EditedColumnsAndTables | undefined {
        // `this.getColumnAssignments` will remove assignments that are not
        // actually assigned to.
        const assignments = this.getColumnAssignments(desc, env);
        if (assignments === undefined) return undefined;

        const editedColumns = Array.from(makeEditedColumnsFromColumnAssignments(assignments, false, env.context));

        const writeRowIDTo = getSourceColumnProperty(desc.writeRowIDTo);
        if (writeRowIDTo !== undefined) {
            const resolved = resolveSourceColumn(env.context, writeRowIDTo, env.tables?.input, undefined, undefined);
            if (resolved?.tableAndColumn !== undefined) {
                const { table, column } = resolved.tableAndColumn;
                editedColumns.push([
                    column.name,
                    writeRowIDTo.kind === SourceColumnKind.DefaultContext,
                    false,
                    getTableName(table),
                ]);
            }
        }

        return { editedColumns, deletedTables: [] };
    }

    public getTokenizedDescription(
        desc: AddRowActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const columnToken = tokenForProperty(desc.tableName, env);
        if (columnToken === undefined) return undefined;

        return [{ kind: "string", value: "to " }, columnToken];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: AddRowActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { eminenceFlags },
            appFacilities,
        } = ib;

        const maybeTable = getTable(desc, ib.adc);
        if (maybeTable === undefined) return arbBase.inflationError("Table not found");
        const { tableName, table } = maybeTable;
        arbBase = arbBase.addData({ tableName: sheetNameForTable(table) });

        if (!isTableWritable(table)) return arbBase.inflationError("Table not writable");

        const outputValueGetters = inflateColumnAssignments(ib, table, getColumnAssignmentsWithLegacy(desc), true);
        if (outputValueGetters.length === 0) return arbBase.nothingToDo("No values to set");

        const awaitSend = shouldAwaitSend(ib.adc, desc);

        const writeRowIDMaker =
            table.rowIDColumn !== undefined
                ? ib.getValueSetterForProperty(desc.writeRowIDTo, "write-row-id-to").setterMaker
                : undefined;

        return (vp, skipLoading) => {
            if (!getCanEditFromNetworkStatus(vp, eminenceFlags, MutatingScreenKind.AddScreen)) return arbBase.offline();

            let loadingValue: LoadingValue | undefined;
            const outputValues = outputValueGetters.map(([n, g]) => {
                let v = g(vp);
                if (isLoadingValue(v)) {
                    loadingValue = v;
                    v = undefined;
                }
                return [n, v];
            });

            let arb = arbBase.addData({ row: outputValues });
            if (loadingValue !== undefined) {
                if (!skipLoading) return arb.loading();
            }

            let writeRowID: Exclude<ReturnType<ValueSetterResult["setterMaker"]>, LoadingValue>;
            if (writeRowIDMaker !== undefined) {
                const maybeWriteRowID = writeRowIDMaker(vp);
                if (isLoadingValue(maybeWriteRowID)) {
                    return arb.errorIfSkipLoading(skipLoading, "Write to row ID column");
                }
                writeRowID = maybeWriteRowID;
            }

            const outputRow: LoadedRow = {
                ...fromPairs(outputValues),
                $rowID: appFacilities.makeRowID(),
                $isVisible: false,
            };

            arb = arb.addData({ row: omit(outputRow, ["$isVisible"]) });

            // FIXME: If we don't have any columns assigned, and the table
            // doesn't have a row ID, I don't think we should add a row.  We
            // probably also shouldn't fail.

            return async ab => {
                const newRow = await ab.addRow(tableName, outputRow, undefined, awaitSend);
                if (newRow.ok && newRow.result !== undefined) {
                    await writeRowID?.(ab, newRow.result.$rowID);
                    return arb.withOutputs({ [newRowIDResultName]: newRow.result.$rowID }).fromResult(newRow);
                } else {
                    return arb.fromResult(newRow);
                }
            };
        };
    }

    public getBillablesConsumed(env: StaticActionContext<AppDescriptionContext>): BillablesConsumed | undefined {
        return getBillablesConsumedForCRUDActions(env);
    }
}
