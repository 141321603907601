import * as glide from "@glide/plugins";
import ms from "ms";
import type { StringValue } from "ms";

export const waitDurationParameter = glide.makeParameter({
    type: "string",
    name: "Wait duration",
    description: "Stop waiting after this long",
    defaultValue: "8 hours",
    placeholder: "8 hours",
});

export function parseWaitDuration(waitDuration: string | undefined) {
    waitDuration = (waitDuration ?? "8 hours").trim();
    // If `waitDuration` only contains digits, append " hours"
    if (/^\d+$/.test(waitDuration)) {
        waitDuration += " hours";
    }

    const waitDurationMS = ms(waitDuration as StringValue);
    if (waitDurationMS === undefined) {
        return glide.Result.Fail(`Invalid wait duration: ${waitDuration}`);
    }
    if (waitDurationMS <= 10 * 1000) {
        return glide.Result.Fail("Wait duration must be at least 10 seconds");
    }
    // We're capping the wait duration at one week for now.  It's easy to
    // increase this limit.  NOTE: If we increase this limit, we might have to
    // increase the corresponding limit `maxSignalTimeoutMS` in the
    // Automations service.
    if (waitDurationMS > 7 * 24 * 60 * 60 * 1000) {
        return glide.Result.Fail("Wait duration must be less than 7 days");
    }

    return glide.Result.Ok(waitDurationMS);
}
