import React from "react";
import { shallowEqual } from "react-redux";

export function useShallowMemo<T>(obj: T): T {
    const last = React.useRef(obj);

    if (shallowEqual(last.current, obj)) {
        return last.current;
    }

    last.current = obj;
    return obj;
}
