import { styled } from "@glide/common-components";
import { css } from "styled-components";

const androidBorderRadius = "12px";
const iOSBorderRadius = "14px";

export const ModalArea = styled.div`
    ${p => (p.theme.navBar.style === "iOS" ? iOSModalArea : androidModalArea)};

    position: relative;

    background-color: ${p => p.theme.offBaseColor};

    display: flex;
    flex-direction: column;
`;

const androidModalArea = css`
    width: 312px;
    height: 212px;
    border-radius: ${androidBorderRadius};
    padding: 1.5rem;
`;

const iOSModalArea = css`
    width: 270px;
    height: 160px;

    border-radius: ${iOSBorderRadius};
    padding: 16px 16px 60px 16px;
    justify-content: space-between;
`;

export const Title = styled.div`
    color: ${p => p.theme.fgColorDark};
    ${p => (p.theme.navBar.style === "iOS" ? iOSTitle : androidTitle)};
`;

const androidTitle = css`
    ${p => p.theme.fontStyles.Body2};
    line-height: 2rem;
`;

const iOSTitle = css`
    ${p => p.theme.fontStyles.AccentHeader4};
    text-align: center;
    letter-spacing: -0.0241176em;
`;

export const Description = styled.div`
    ${p => (p.theme.navBar.style === "iOS" ? iOSDescription : androidDescription)};
`;

const androidDescription = css`
    ${p => p.theme.fontStyles.Body5}
    color: ${p => p.theme.fgColorMedium};
    padding: 1rem 0;
`;

const iOSDescription = css`
    color: ${p => p.theme.fgColorDark};
    ${p => p.theme.fontStyles.Body6}
    text-align: center;
`;

export const ButtonWrapper = styled.div`
    display: flex;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    ${p => (p.theme.navBar.style === "iOS" ? iOSButtonWrapper : androidButtonWrapper)};
`;

const androidButtonWrapper = css`
    padding: 1.5rem;
    justify-content: flex-end;
`;

const iOSButtonWrapper = css`
    height: 44px;
    border-top-color: ${p => p.theme.separatorColor};
    border-top-style: solid;
    border-top-width: 1px;
`;

const buttonProps = css`
    ${p => (p.theme.navBar.style === "iOS" ? iOSButtonProps : androidButtonProps)};

    min-width: 1px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${p => p.theme.fgColorDark};

    :active {
        background-color: ${p => p.theme.picker.activeBgColor};
    }
    :hover {
        background-color: rgba(0, 0, 0, 0.03);
    }

    cursor: pointer;
`;

const androidButtonProps = css`
    height: 2.5rem;
    border-radius: 6.25rem;
    padding: 0.625rem 0.75rem;
    ${p => p.theme.fontStyles.Header5};
`;

const iOSButtonProps = css`
    flex: 1;
    ${p => p.theme.fontStyles.Body4};
`;

export const Cancel = styled.div`
    ${buttonProps}

    ${p => (p.theme.navBar.style === "iOS" ? iOSCancel : androidCancel)};
`;

const androidCancel = css`
    color: ${p => p.theme.primaryAccentColor};
    margin-right: 0.5rem;
`;

const iOSCancel = css`
    border-right-color: ${p => p.theme.separatorColor};
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-left-radius: ${iOSBorderRadius};
`;

export const Accept = styled.div<{ isDelete: boolean }>`
    ${buttonProps}

    color: ${p => p.theme.primaryAccentColor};
    ${p => (p.theme.navBar.style === "iOS" ? p.theme.fontStyles.Header4 : "")}

    border-bottom-right-radius: ${p => (p.theme.navBar.style === "iOS" ? iOSBorderRadius : "")};
`;

export const Wrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;

    background-color: ${p => p.theme.picker.activeBgColor};

    display: flex;
    justify-content: center;
    align-items: center;

    &.delete ${Accept} {
        ${p =>
            p.theme.navBar.style === "iOS"
                ? css`
                      color: ${p.theme.destroyColor};
                  `
                : ""};
    }

    &.quota-warning {
        ${Accept} {
            ${p => p.theme.fontStyles.Body4}
        }

        ${Cancel} {
            ${p => p.theme.fontStyles.Subheader4}
            color: ${p => p.theme.primaryAccentColor};
        }
    }
`;
