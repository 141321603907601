import React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { GlideIcon } from "@glide/common";
import "twin.macro";

export const Root = DropdownMenuPrimitive.Root;

export const Trigger = DropdownMenuPrimitive.Trigger;

export const Portal = DropdownMenuPrimitive.Portal;

export const Content = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
    <DropdownMenuPrimitive.Content
        ref={ref}
        tw="z-50 min-w-[8rem] bg-bg-front cursor-default text-text-dark rounded-lg shadow-lg-dark overflow-hidden flex flex-col [max-height:calc(50vh-120px)] overflow-y-auto p-1"
        {...props}
    />
));
Content.displayName = DropdownMenuPrimitive.Content.displayName;

export const CheckboxItem = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, ...props }, ref) => (
    <DropdownMenuPrimitive.CheckboxItem
        ref={ref}
        tw="relative outline-none rounded cursor-pointer leading-5 w-full text-sm font-normal transition-colors pl-1 pr-5 py-1.5 not-last:mb-px select-none flex items-center data-[disabled]:pointer-events-none data-[disabled]:bg-none hocus:bg-n100A"
        {...props}>
        <span tw="absolute right-2 flex h-2 w-2 items-center justify-center">
            <DropdownMenuPrimitive.ItemIndicator>
                <GlideIcon tw="text-text-accent" kind="stroke" icon="st-check" iconSize={16} />
            </DropdownMenuPrimitive.ItemIndicator>
        </span>
        {children}
    </DropdownMenuPrimitive.CheckboxItem>
));
CheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;
