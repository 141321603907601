import type { ActionDescription } from "@glide/app-description";
import type { LoadedGroundValue } from "@glide/computation-model-types";
import type { Result } from "@glide/plugins";
import type { JSONObject } from "@glide/support";
import { assert, exceptionToString, definedMap } from "@glideapps/ts-necessities";
import type { ActionConditionLog } from "./types";

// These are action hydration results that don't have a runner.
export enum WireActionResultKind {
    // The action is waiting for some value to load.  We'd show a disabled
    // button in this case.  This can only be returned by the hydrator.
    Loading = "Loading",
    // The device is offline.  We'd show a disabled button in this case as well as messaging explaining why the button doesn't work.
    // This can only be returned by the hydrator.
    Offline = "offline",
    // There was an error inflating, in which case we wouldn't show a button,
    // but we would not abort a custom action.  We would abort an Automation,
    // however.  This can only be returned by the inflator.
    InflationError = "inflation-error",
    // There was an error hydrating or running.  We'd abort a custom action,
    // and if the error was during hydration, we wouldn't show a button.
    RetryableError = "transient-error",
    PermanentError = "permanent-error",
    // The action ran successfully.  This can be returned early by the
    // inflator or hydrator to signal that the action doesn't have anything to
    // do.
    Success = "success",
}

type DescType = ActionDescription | ActionConditionLog;

export type WireActionOutputs = Record<string, LoadedGroundValue>;

export interface WireContinueWithSignal {
    readonly signalScope: string;
    readonly signalID: string;
    readonly timeoutMS: number;
}

interface ResultDataBase {
    readonly desc?: DescType;
    readonly nodeKey?: string;
    readonly data: JSONObject;
    readonly outputs: WireActionOutputs;
}

interface WireActionResultData extends ResultDataBase {
    readonly kind: WireActionResultKind;
    readonly message?: string;
    readonly errorData?: JSONObject;
    readonly continueWithSignal?: WireContinueWithSignal;
}

// This is the result of inflating, hydrating, or running an action.  Do not
// instantiate this class directly when implementing a proper action (as
// opposed to just using an impromptu action runner in the component model).
// Instead use the `WireActionResultBuilder`.
export class WireActionResult {
    public readonly kind: WireActionResultKind;
    public readonly desc: DescType | undefined;
    public readonly nodeKey: string | undefined;
    public readonly message: string | undefined;
    /**
     * `data` can be any JSON object that provides more information about
     * the action that's being run.
     */
    public readonly data: JSONObject;
    public readonly outputs: WireActionOutputs;
    /**
     * This is the equivalent of `ErrorResult.data`.
     */
    public readonly errorData: JSONObject | undefined;
    public readonly continueWithSignal: WireContinueWithSignal | undefined;

    constructor(data: WireActionResultData) {
        this.kind = data.kind;
        this.desc = data.desc;
        this.nodeKey = data.nodeKey;
        this.message = data.message;
        this.data = data.data;
        this.outputs = data.outputs;
        this.errorData = data.errorData;
        this.continueWithSignal = data.continueWithSignal;

        if (this.continueWithSignal !== undefined) {
            assert(this.kind === WireActionResultKind.Success);
        }
    }

    public get isSuccessInAutomation(): boolean {
        return this.kind === WireActionResultKind.Success;
    }

    public get isSuccessInApp(): boolean {
        // We've historically ignored inflation errors, so we have to treat
        // them as successes in the app.
        return this.isSuccessInAutomation || this.kind === WireActionResultKind.InflationError;
    }

    public get isPermanentError(): boolean {
        return this.kind === WireActionResultKind.InflationError || this.kind === WireActionResultKind.PermanentError;
    }

    // NOTE: Never use this in "proper" actions that the user configures.  For
    // those, always use the `WireActionResultBuilder` and provide as much
    // information as possible.  This is just for actions internal to the
    // component system.
    public static nondescriptSuccess(nodeKey?: string): WireActionResult {
        return new WireActionResult({ kind: WireActionResultKind.Success, nodeKey, data: {}, outputs: {} });
    }

    // NOTE: Never use this in "proper" actions that the user configures.  For
    // those, always use the `WireActionResultBuilder` and provide as much
    // information as possible.  This is just for actions internal to the
    // component system.
    public static nondescriptError(
        isPermanent: boolean,
        message?: string,
        nodeKey?: string,
        errorData?: JSONObject
    ): WireActionResult {
        return new WireActionResult({
            kind: isPermanent ? WireActionResultKind.PermanentError : WireActionResultKind.RetryableError,
            nodeKey,
            message,
            data: {},
            outputs: {},
            errorData,
        });
    }

    // NOTE: Only use this if there is no actual "regular" action in play.  If
    // there is an action, use a `WireActionResultBuilder` and provide as much
    // information as possible.
    public static nothingToDo(message?: string): WireActionResult {
        return new WireActionResult({ kind: WireActionResultKind.Success, message, data: {}, outputs: {} });
    }

    // NOTE: Only use this if there is no actual "regular" action in play.  If
    // there is an action, use a `WireActionResultBuilder` and provide as much
    // information as possible.
    public static fromResult(runResult: Result<unknown>, nodeKey?: string): WireActionResult {
        if (runResult.ok) {
            return this.nondescriptSuccess(nodeKey);
        } else {
            return this.nondescriptError(
                runResult.data?.isPermanent === true,
                runResult.message,
                nodeKey,
                runResult.data
            );
        }
    }

    public static fromException(isPermanent: boolean, e: unknown): WireActionResult {
        return this.nondescriptError(isPermanent, "Exception: " + exceptionToString(e));
    }
}

// This is a builder for `WireActionResult`.  Use this when you're inflating,
// hydrating, or running a proper Glide Action.  Update it with data as you
// gather it, and then call one of the methods to create the final result.
export class WireActionResultBuilder {
    private constructor(private readonly data: ResultDataBase) {}

    public static fromDescription(desc: DescType, nodeKey: string | undefined): WireActionResultBuilder {
        return new WireActionResultBuilder({ desc, nodeKey, data: {}, outputs: {} });
    }

    // NOTE: Only use this if there is no actual "regular" action in play.
    public static nondescript(): WireActionResultBuilder {
        return new WireActionResultBuilder({ desc: undefined, nodeKey: undefined, data: {}, outputs: {} });
    }

    public addData(moreData: JSONObject): WireActionResultBuilder {
        return new WireActionResultBuilder({ ...this.data, data: { ...this.data.data, ...moreData } });
    }

    public withOutputs(outputs: WireActionOutputs): WireActionResultBuilder {
        return new WireActionResultBuilder({ ...this.data, outputs });
    }

    public loading(what?: string): WireActionResult {
        return new WireActionResult({
            kind: WireActionResultKind.Loading,
            desc: this.data.desc,
            nodeKey: this.data.nodeKey,
            message: definedMap(what, w => `Still loading ${w}`),
            data: this.data.data,
            outputs: this.data.outputs,
        });
    }

    public disabled(message: string): WireActionResult {
        return new WireActionResult({
            kind: WireActionResultKind.Offline,
            desc: this.data.desc,
            nodeKey: this.data.nodeKey,
            message,
            data: this.data.data,
            outputs: this.data.outputs,
        });
    }

    public nothingToDo(message?: string): WireActionResult {
        return new WireActionResult({
            kind: WireActionResultKind.Success,
            desc: this.data.desc,
            nodeKey: this.data.nodeKey,
            message,
            data: this.data.data,
            outputs: this.data.outputs,
        });
    }

    public inflationError(what: string): WireActionResult {
        return new WireActionResult({
            kind: WireActionResultKind.InflationError,
            desc: this.data.desc,
            nodeKey: this.data.nodeKey,
            message: `Action is misconfigured: ${what}`,
            data: this.data.data,
            outputs: this.data.outputs,
        });
    }

    public error(isPermanent: boolean, message: string, errorData?: JSONObject): WireActionResult {
        return new WireActionResult({
            kind: isPermanent ? WireActionResultKind.PermanentError : WireActionResultKind.RetryableError,
            desc: this.data.desc,
            nodeKey: this.data.nodeKey,
            message,
            data: this.data.data,
            outputs: this.data.outputs,
            errorData,
        });
    }

    public errorFromHTTPStatus(status: number, message: string, errorData?: JSONObject): WireActionResult {
        // 429 is "too many requests", which is actually a retryable error
        // despite being a 4xx.
        const isRetryable = status >= 500 || status === 429;
        return this.error(!isRetryable, message, { ...errorData, status });
    }

    public success(): WireActionResult {
        return new WireActionResult({
            kind: WireActionResultKind.Success,
            desc: this.data.desc,
            nodeKey: this.data.nodeKey,
            data: this.data.data,
            outputs: this.data.outputs,
        });
    }

    public continueWithSignal(signal: WireContinueWithSignal): WireActionResult {
        return new WireActionResult({
            kind: WireActionResultKind.Success,
            desc: this.data.desc,
            nodeKey: this.data.nodeKey,
            data: this.data.data,
            outputs: this.data.outputs,
            continueWithSignal: signal,
        });
    }

    public fromResult(runResult: Result<unknown>): WireActionResult {
        if (runResult.ok) {
            return this.success();
        } else {
            return this.error(runResult.data?.isPermanent === true, runResult.message, runResult.data);
        }
    }

    public fromException(isPermanent: boolean, e: unknown): WireActionResult {
        return this.error(isPermanent, "Exception: " + exceptionToString(e), undefined);
    }

    public offline(): WireActionResult {
        return this.disabled("Offline");
    }

    public maybeSkipLoading(skipLoading: boolean, what: string): WireActionResult {
        if (skipLoading) {
            let message = "Skipped data that's still loading";
            if (what !== undefined) {
                message += `: ${what}`;
            }
            return this.nothingToDo(message);
        } else {
            return this.loading(what);
        }
    }

    public errorIfSkipLoading(skipLoading: boolean, what: string): WireActionResult {
        if (skipLoading) {
            let message = "Data has not loaded";
            if (what !== undefined) {
                message += `: ${what}`;
            }
            return this.error(false, message, undefined);
        } else {
            return this.loading(what);
        }
    }
}
