import "twin.macro";

import type { WireButtonComponent } from "@glide/fluent-components/dist/js/fluent-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { ResponsiveProvider, isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import type { ResponsiveSizeClass, WireComponent } from "@glide/wire";
import { UIButtonAppearance, WireComponentKind } from "@glide/wire";
import { assert } from "@glideapps/ts-necessities";
import * as React from "react";

import { WireButton } from "../../renderers/wire-button/wire-button";
import { makeSingleActionSpreadProps, useFloatingLayerPortalId } from "../../wire-lib";
import type { ChromeFormControls } from "../nav-bar/nav-bar-common";
import { getFormOrEditControls } from "../nav-bar/nav-bar-common";

interface SpecialGroupComponentsProps {
    readonly specialComponents: WireComponent[];
    readonly backend: WireBackendInterface;
}

export const SpecialGroupComponents: React.VFC<SpecialGroupComponentsProps> = p => {
    const { specialComponents, backend } = p;

    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    if (specialComponents.length === 0) {
        return null;
    }

    const formControls = getFormOrEditControls(specialComponents, backend);
    const shouldShowSpecialFormControls = isMobile && formControls !== undefined;

    if (shouldShowSpecialFormControls) {
        return <FormSpecialComponents formControls={formControls} />;
    } else {
        return <RegularSpecialComponents specialComponents={specialComponents} backend={backend} />;
    }
};

interface FormSpecialComponentsProps {
    readonly formControls: ChromeFormControls;
}

const FormSpecialComponents: React.VFC<FormSpecialComponentsProps> = p => {
    const { formControls } = p;
    const { submit, cancel } = formControls;

    return (
        <>
            <div tw="grow" />
            <div
                tw="sticky rounded-b-xl z-special-components self-stretch [bottom:-0.5px] px-6 pt-5 [padding-bottom:max(20px, var(--safe-area-inset-bottom))] flex gap-4 bg-bg-front [box-shadow:0 -1px 0
                    rgba(62, 65, 86, 0.08)]">
                <WireButton {...cancel} appearance={UIButtonAppearance.Bordered} tw="grow">
                    {cancel.label}
                </WireButton>
                <WireButton
                    {...submit}
                    appearance={UIButtonAppearance.Filled}
                    tw="grow"
                    data-testid="form-submit-button">
                    {submit.label}
                </WireButton>
            </div>
        </>
    );
};

interface RegularSpecialComponentsProps {
    readonly specialComponents: WireComponent[];
    readonly backend: WireBackendInterface;
}

const RegularSpecialComponents: React.VFC<RegularSpecialComponentsProps> = p => {
    const { backend, specialComponents } = p;

    return (
        <>
            <div tw="grow" />
            <div
                tw="sticky rounded-b-xl z-special-components self-stretch [bottom:-0.5px] px-6 py-5 flex bg-bg-front [box-shadow:0 -1px 0
                    rgba(62, 65, 86, 0.08)]">
                {specialComponents.map((b, i) => {
                    assert(b.kind === WireComponentKind.Button);
                    const button = b as WireButtonComponent;
                    return (
                        <WireButton
                            {...makeSingleActionSpreadProps(button.action, backend)}
                            appearance={button.appearance}
                            key={i}
                            tw="mr-4">
                            {button.title ?? "Err"}
                        </WireButton>
                    );
                })}
            </div>
        </>
    );
};

export const FloatingPortalLayer: React.VFC<{ maxSize: ResponsiveSizeClass }> = ({ maxSize }) => {
    const portalId = useFloatingLayerPortalId();
    return (
        <ResponsiveProvider maxSize={maxSize} tw="inset-0 absolute flex h-full w-full pointer-events-none">
            <div
                data-testid="floating-portal-layer"
                tw="relative flex h-full w-full pointer-events-none all-child:pointer-events-auto z-floating-portal-layer"
                id={portalId}
            />
        </ResponsiveProvider>
    );
};
const ScrollRefContenxt = React.createContext<React.RefObject<HTMLElement> | null>(null);

export const ScrollRefProvider: React.FC<React.PropsWithChildren<{ scrollRef: React.RefObject<HTMLElement> }>> = ({
    scrollRef,
    children,
}) => {
    return <ScrollRefContenxt.Provider value={scrollRef}>{children}</ScrollRefContenxt.Provider>;
};

export const useScrollRef = () => {
    return React.useContext(ScrollRefContenxt);
};
