import { css, isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import type { TwStyle } from "twin.macro";
import tw from "twin.macro";
import { forwardRef } from "react";

export type PageSwitchSizes = "default" | "small" | "xsmall";

interface PagesSwitchProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size" | "style" | "className"> {
    // Defaults to "default"
    size?: PageSwitchSizes;
}

const sizes: Record<"mobile" | "desktop", Record<PageSwitchSizes, TwStyle>> = {
    mobile: {
        xsmall: tw`w-8 h-[18px] after:w-[14px] after:h-[14px]`,
        small: tw`w-9 h-5 after:w-4 after:h-4`,
        default: tw`w-11 h-6 after:w-5 after:h-5`,
    },
    desktop: {
        xsmall: tw`w-6 h-[14px] after:w-2.5 after:h-2.5`,
        default: tw`w-9 h-5 after:w-4 after:h-4`,
        small: tw`w-8 h-[18px] after:w-[14px] after:h-[14px]`,
    },
};

export const PagesSwitch = forwardRef<HTMLInputElement, PagesSwitchProps>((props, ref) => {
    const { size = "default", ...rest } = props;
    const screenSize = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(screenSize);
    const dimensions = sizes[isMobile ? "mobile" : "desktop"][size];

    return (
        <label
            tw="inline-flex items-center cursor-pointer"
            css={css`
                .style-accent-bg & .peer:checked ~ div {
                    ${tw`bg-white`}
                    &:after {
                        ${tw`bg-accent`}
                    }
                }
            `}>
            <input
                {...rest}
                ref={ref}
                /*Properties that cannot be overridden*/
                tw="sr-only"
                className="peer"
                type="checkbox"
            />
            <div
                css={css`
                    ${dimensions}
                    ${tw`bg-n400 hover:bg-n500 hover:transition-colors ease-out peer-checked:bg-accent after:border-transparent after:bg-white`}

                    &:active {
                        transform: scale(0.96);
                    }
                    .style-dark & {
                        ${tw`ring-white ring-offset-black ring-offset-2 bg-w30A`}
                    }
                    .style-accent-bg & {
                        ${tw`ring-white ring-offset-accent ring-offset-2 bg-w30A hover:bg-w40A after:bg-white`}
                    }
                `}
                tw="relative rounded-full [box-shadow:0px 4px 13px rgba(96, 96, 116, 0.1)] ring-accent ring-offset-white ring-offset-2 peer-focus:outline-none peer-focus-visible:ring-2  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:rounded-full after:transition-all after:inset-[2px]"
            />
        </label>
    );
});
