import type { Icon, ActionDescription, ActionKind, ColumnAssignment, MutatingScreenKind } from "@glide/app-description";
import type { AppKind } from "@glide/location-common";
import type { TableName, Description, TableColumn, TableGlideType } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import type {
    ActionAvailability,
    ActionsRecord,
    AppDescriptionContext,
    EditedColumnsAndTables,
    InteractiveComponentConfiguratorContext,
    RewritingComponentConfiguratorContext,
} from "@glide/function-utils";
import type { PluginTierList, BillablesConsumed, GlideIconProps as GlideIcon } from "@glide/plugins";
import type {
    WireActionHydrator,
    WireActionInflationBackend,
    WireActionResult,
    WireActionResultBuilder,
} from "@glide/wire";
import type { ActionDescriptor } from "./action-descriptor";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import type { StaticActionContext } from "../static-context";

export interface DescriptionToken {
    kind: "string" | "column" | "const";
    value: string;
}

export interface ActionColumnAssignments {
    readonly assignments: readonly ColumnAssignment[];
    readonly table: TableGlideType;
    readonly isAddRow: boolean;
}

export const actionAvailabilityApps: ActionAvailability = { apps: true, automations: false };
export const actionAvailabilityAutomations: ActionAvailability = { apps: false, automations: true };
export const actionAvailabilityBoth: ActionAvailability = { apps: true, automations: true };

export interface ActionHandler<T extends ActionDescription> {
    readonly kind: ActionKind;
    readonly needsData: boolean;
    readonly availability: ActionAvailability;
    // If `true`, there will be a chevron.  `undefined` means nothing.
    readonly iconName: GlideIcon | Icon | true | undefined;
    readonly handlesSequencing: boolean;
    // Can this action be a ##firstListItemActionToRun?
    readonly canAutoRun: boolean;
    readonly appKinds: AppKind | "both";
    readonly name: string;

    getIsApplicable(screenHasInputContext: boolean, appHasUserProfiles: boolean): boolean;
    getIsIdempotent(desc: T): boolean;

    getTier(appKind: AppKind): PluginTierList | undefined;
    getBillablesConsumed(env: StaticActionContext<AppDescriptionContext>): BillablesConsumed | undefined;
    getDescriptor(desc: T | undefined, env: StaticActionContext<AppDescriptionContext>): ActionDescriptor;
    getActions(desc: T): ActionsRecord;
    getDescriptionToWalk(desc: Description, ccc: AppDescriptionContext): T;
    getScreensUsed(desc: T, env: StaticActionContext<AppDescriptionContext>): readonly string[];
    newActionDescription(env: StaticActionContext<InteractiveComponentConfiguratorContext>): T | undefined;
    rewriteAfterReload(
        desc: T,
        tables: InputOutputTables,
        ccc: RewritingComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): T | undefined;
    // This is the same as ##getEditedColumns in component handlers.  The
    // difference between this and `getAllowedDataEdits` below. FIXME: Ideally
    // there would be only one function that does both, for example by marking
    // in the allowed data edits which ones can happen in the output context.
    // Returns [edited columns, deleted tables]
    getEditedColumns(desc: T, env: StaticActionContext<AppDescriptionContext>): EditedColumnsAndTables | undefined;
    getAdditionalTablesUsed(tables: InputOutputTables): readonly TableName[];
    getColumnAssignments(desc: T, env: StaticActionContext<AppDescriptionContext>): ActionColumnAssignments | undefined;
    // This will not adapt the action to the input/output tables.  It will
    // refuse to copy if it can't be made compatible, however.  That's the
    // case for compound actions and free screens that take different types.
    duplicateAction(
        desc: T,
        rootDesc: Description,
        copyTables: InputOutputTables | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<T | undefined>;
    // This is the same as `duplicateAction`, but for compound actions it will
    // return the same action if the tables fit.
    reuseOrDuplicateAction(
        desc: T,
        rootDesc: Description,
        copyTables: InputOutputTables | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<T | undefined>;
    newDefaultActionDescription(_column: TableColumn): T | undefined;
    // Should just return `desc` if nothing changed
    fixActionDescription(desc: T): T;
    getTokenizedDescription(
        desc: T,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined;

    inflate?(
        b: WireActionInflationBackend,
        desc: T,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult;

    updateAction?(
        desc: T,
        updates: Partial<T>,
        tables: InputOutputTables | undefined,
        ccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): T;
}
