import type { OverlayTheme } from "../overlay-theme";

export const pureWhiteOverlay: OverlayTheme = {
    overlayName: "PureWhiteTheme",
    primaryAccentColor: "#000000",
    accentForegroundColor: "#ffffff",

    avoidColors: [],

    list: {
        topMargin: 0,
    },

    navBar: {
        titleColor: "#000000",
        fgColor: "#000000",
        bgColor: "#ffffff",
        transparentBgColor: "#ffffff",
        fadeTo: "white",
        cancelColor: "#000000",

        largeTitleHeight: 56,
    },

    tabBar: {
        unselectedColor: "rgba(153, 153, 153, 1)",
    },

    switch: {
        compact: {
            backgroundColor: "#000000",
        },
        big: {
            selectedBackgroundColor: "#000000",
            borderColor: "rgba(0, 0, 0, 0.24)",
        },
    },
};
