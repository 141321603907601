import { useEffect, useState } from "react";

export function useBuilderScreenshooter(): boolean {
    const [isShooting, setIsShooting] = useState(false);

    useEffect(() => {
        function onMessage(event: MessageEvent) {
            const visible = event.data.value;
            if (event.data.type === "GLIDE_BUILDER_SHOOTER_CONTENT_VISIBILITY" && typeof visible === "boolean") {
                setIsShooting(!visible);
            }
        }
        window.addEventListener("message", onMessage);
        return () => {
            window.removeEventListener("message", onMessage);
        };
    }, []);

    return isShooting;
}
