import { useLayoutEffect, useRef, forwardRef, useImperativeHandle, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";
import { roundedRect } from "@glideapps/glide-data-grid";

const BAR_WIDTH = 2;
const BAR_GAP = 1;
const BAR_ROUNDNESS = 5;
const DISPLAY_HEIGHT = 40;
const SILENCE_HEIGHT = 2;
const ANIMATION_SPEED = 0.03; // in pixels per millisecond
const MAX_FRAME_TIME = 32; // Cap at ~30fps to prevent huge jumps

type BarItem = {
    startY: number;
    barHeight: number;
};

interface VoiceVisualizerProps {
    readonly barColor: string;
    readonly secondaryBarColor: string;
    readonly isRecording: boolean;
    readonly getAudioData: () => Float32Array;
}

export interface VoiceVisualizerHandle {
    cleanup: () => void;
}

export const VoiceVisualizer = forwardRef<VoiceVisualizerHandle, VoiceVisualizerProps>((p, ref) => {
    const { barColor, secondaryBarColor, isRecording, getAudioData } = p;

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const picksRef = useRef<BarItem[]>([]);
    const animationFrameRef = useRef<number>();
    const isInitializedRef = useRef(false);
    const lastDrawTimeRef = useRef(0);
    const offsetXRef = useRef(0);

    // FIXME: Shouldn't this cleanup happen when we're not recording anymore,
    // instead of lifting the responsibility to the consumer?
    useImperativeHandle(ref, () => ({
        cleanup: () => {
            if (typeof animationFrameRef.current === "number") {
                cancelAnimationFrame(animationFrameRef.current);
            }
            resetVisualization();
        },
    }));

    const resetVisualization = () => {
        isInitializedRef.current = false;
        picksRef.current = [];
        offsetXRef.current = 0;
        lastDrawTimeRef.current = 0;
        canvasRef.current?.getContext("2d")?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };

    const unit = BAR_WIDTH + BAR_GAP;

    const initializeBars = useCallback(
        (displayWidth: number) => {
            const totalBars = Math.floor(displayWidth / unit);
            picksRef.current = Array(totalBars)
                .fill(null)
                .map(() => ({
                    startY: DISPLAY_HEIGHT / 2,
                    barHeight: 0,
                }));
            isInitializedRef.current = true;
        },
        [unit]
    );

    useEffect(() => {
        // Only initialize if not already initialized
        if (isRecording && !isInitializedRef.current) {
            const container = containerRef.current;
            if (container) {
                const rect = container.getBoundingClientRect();
                initializeBars(rect.width);
            }
        }
    }, [unit, initializeBars, isRecording]);

    useLayoutEffect(() => {
        const canvas = canvasRef.current;
        const container = containerRef.current;
        if (!canvas || !container) return;

        const drawToCanvas = () => {
            const context = canvas.getContext("2d");
            if (!context) return;

            const dpr = window.devicePixelRatio || 1;

            // Reset any previous transforms
            context.setTransform(1, 0, 0, 1, 0, 0);
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.scale(dpr, dpr);

            drawBars(context, dpr);
        };

        const drawBars = (context: CanvasRenderingContext2D, dpr: number) => {
            context.fillStyle = barColor;
            const displayWidth = canvas.width / dpr;
            const totalBars = picksRef.current.length;
            const offset = offsetXRef.current % unit;

            for (let i = 0; i < totalBars; i++) {
                const pick = picksRef.current[i];
                if (!pick) continue;

                const x = i * unit + offset;

                // Only draw bars within the visible area
                if (x + BAR_WIDTH >= 0 && x <= displayWidth) {
                    context.fillStyle = pick.barHeight <= SILENCE_HEIGHT ? secondaryBarColor : barColor;
                    if (typeof roundedRect === "function") {
                        context.beginPath();
                        roundedRect(context, x, pick.startY, BAR_WIDTH, pick.barHeight, BAR_ROUNDNESS);
                        context.fill();
                    } else {
                        context.fillRect(x, pick.startY, BAR_WIDTH, pick.barHeight);
                    }
                }
            }
        };

        const updateCanvasSize = () => {
            requestAnimationFrame(() => {
                const rect = container.getBoundingClientRect();
                const dpr = window.devicePixelRatio || 1;
                const displayWidth = Math.floor(rect.width);

                if (canvas.width !== displayWidth * dpr || canvas.height !== DISPLAY_HEIGHT * dpr) {
                    canvas.style.width = `${displayWidth}px`;
                    canvas.style.height = `${DISPLAY_HEIGHT}px`;
                    canvas.width = displayWidth * dpr;
                    canvas.height = DISPLAY_HEIGHT * dpr;

                    initializeBars(displayWidth);
                    drawToCanvas();
                }
            });
        };

        const debouncedUpdateCanvasSize = debounce(updateCanvasSize, 16);
        const resizeObserver = new ResizeObserver(() => {
            debouncedUpdateCanvasSize();
        });

        resizeObserver.observe(container);
        updateCanvasSize();

        const updateOffsetX = (timeMS: number, audioData: Float32Array) => {
            const movement = ANIMATION_SPEED * timeMS;
            offsetXRef.current -= movement;

            const chunkSize = Math.floor(audioData.length / 8);
            let maxValue = 0;
            let sum = 0;
            let count = 0;

            for (let i = audioData.length - chunkSize; i < audioData.length; i++) {
                const value = Math.abs(audioData[i] || 0);
                maxValue = Math.max(maxValue, value);
                sum += value;
                count++;
            }

            const avgValue = sum / count;
            const smoothedValue = maxValue * 0.7 + avgValue * 0.3;
            const centerY = DISPLAY_HEIGHT / 2;
            const barHeight = Math.min(smoothedValue * centerY * 2.5, DISPLAY_HEIGHT - 4);

            // Update bars based on accumulated movement
            if (picksRef.current.length > 0 && offsetXRef.current <= -unit) {
                const newPicks = picksRef.current.slice(1);
                newPicks.push({
                    startY: centerY - (barHeight > SILENCE_HEIGHT ? barHeight / 2 : SILENCE_HEIGHT / 2),
                    barHeight: Math.max(barHeight, SILENCE_HEIGHT),
                });
                picksRef.current = newPicks;
                offsetXRef.current += unit;
            }
        };

        const animate = (timestamp: number) => {
            if (!isRecording) {
                return;
            }

            const audioData = getAudioData();

            if (lastDrawTimeRef.current === 0) {
                lastDrawTimeRef.current = timestamp;
            }

            const deltaTime = Math.min(timestamp - lastDrawTimeRef.current, MAX_FRAME_TIME);

            updateOffsetX(deltaTime, audioData);
            drawToCanvas();

            lastDrawTimeRef.current = timestamp;
            animationFrameRef.current = requestAnimationFrame(animate);
        };

        animate(performance.now());

        return () => {
            if (typeof animationFrameRef.current === "number") {
                cancelAnimationFrame(animationFrameRef.current);
            }
            resizeObserver.disconnect();
        };
    }, [barColor, getAudioData, initializeBars, isRecording, secondaryBarColor, unit]);

    return (
        <div
            ref={containerRef}
            style={{
                width: "100%",
                height: `${DISPLAY_HEIGHT}px`,
                display: "block",
                position: "relative",
            }}
            tw="overflow-hidden">
            <canvas
                ref={canvasRef}
                style={{
                    width: "100%",
                    height: "100%",
                    display: "block",
                    position: "absolute",
                    left: 0,
                    top: 0,
                }}
            />
        </div>
    );
});
