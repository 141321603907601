import { ImageAspectRatio } from "@glide/common-core/dist/js/components/image-types";
import { TitleAppearance } from "@glide/common-core/dist/js/components/title-appearance";
import {
    type ActionComponentDescription,
    type ComponentDescription,
    type ComponentKind,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getActionProperty,
    getEnumProperty,
    isPropertyDescription,
    makeArrayProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeStringProperty,
} from "@glide/app-description";
import {
    type InputOutputTables,
    type TitleDescription,
    makeEmptyComponentDescription,
} from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireAppTitleComponent } from "@glide/fluent-components/dist/js/base-components";
import { type HeroSectionDescription, makeActionWithTitle } from "@glide/fluent-components/dist/js/fluent-components";
import type { SchemaInspector } from "@glide/type-schema";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentDescriptor,
    type PropertyDescriptor,
    PropertySection,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";
import { inflateSummary } from "../array-screens/summary-array-screen";
import {
    hydrateAction,
    inflateActions,
    inflateImageSource,
    makeSimpleWireRowComponentHydratorConstructor,
    registerBusyActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { getActionsForComponent } from "./component-utils";
import { makeSummaryImageKindPropertyDescriptor, makeTitleSubtitleImagePropertyDescriptors } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";
import { makeDefaultActionDescriptor } from "./primitive";

const ComponentKindTitle: ComponentKind = "big-item";

interface TitleComponentDescription extends ActionComponentDescription, ComponentDescription, TitleDescription {
    readonly appearanceProperty: LegacyPropertyDescription | undefined;
    readonly aspectProperty: PropertyDescription | undefined;
}

export class TitleComponentHandler extends ComponentHandlerBase<TitleComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindTitle);
    }

    public getDescriptor(
        desc: TitleComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _adc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const appearance = definedMap(desc, d => getEnumProperty(d.appearanceProperty));

        const properties: PropertyDescriptor[] = [
            ...makeTitleSubtitleImagePropertyDescriptors(mutatingScreenKind, false, false, undefined, true),
            makeSummaryImageKindPropertyDescriptor(undefined),
            ...this.getBasePropertyDescriptors(),
            {
                kind: PropertyKind.Enum,
                property: { name: "appearanceProperty" },
                label: "Appearance",
                menuLabel: "Appearance",
                cases: [
                    {
                        value: TitleAppearance.Filled,
                        label: "Full",
                        icon: "buttonFilled",
                    },
                    {
                        value: TitleAppearance.Card,
                        label: "Card",
                        icon: "buttonBordered",
                    },
                    {
                        value: TitleAppearance.Profile,
                        label: "Profile",
                        icon: "buttonFloating",
                    },
                ],
                defaultCaseValue: TitleAppearance.Filled,
                section: PropertySection.Design,
                visual: "small-images",
            },
        ];

        if (appearance !== undefined && appearance !== TitleAppearance.Profile) {
            properties.push({
                kind: PropertyKind.Enum,
                property: { name: "aspectProperty" },
                label: "Size",
                menuLabel: "Size",
                cases: [
                    {
                        // actually means 300px due to back-compat
                        value: undefined,
                        label: "Fixed Height",
                        icon: "borderSquare",
                    },
                    {
                        value: ImageAspectRatio.ThreeByTwo,
                        label: "3:2",
                        icon: "border3by2",
                    },
                    {
                        value: ImageAspectRatio.ThreeByOne,
                        label: "3:1",
                        icon: "border3by1",
                    },
                ],
                defaultCaseValue: undefined,
                section: PropertySection.Design,
                visual: "small-images",
            });
        }

        return {
            name: "Title",
            helpUrl: getDocURL("title"),
            description: "A title, subtitle, and optional image",
            img: "co-title",
            group: "Layout",
            properties,
        };
    }

    public getActionDescriptors(
        _desc: TitleComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        schema: SchemaInspector | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly ActionPropertyDescriptor[] {
        return [makeDefaultActionDescriptor(schema, mutatingScreenKind, false)];
    }

    public static defaultComponent(desc: TitleDescription): TitleComponentDescription {
        return {
            ...makeEmptyComponentDescription(ComponentKindTitle),
            titleProperty: desc.titleProperty,
            subtitleProperty: desc.subtitleProperty,
            imageURLProperty: desc.imageURLProperty,
            imageKind: desc.imageKind,
            appearanceProperty: makeEnumProperty(TitleAppearance.Filled),
            aspectProperty: undefined,
            actions: undefined,
        };
    }

    private convertLegacyColumnFirstProperty(property: unknown): PropertyDescription | undefined {
        if (isPropertyDescription(property)) {
            return property;
        }

        if (typeof property === "string") {
            return makeColumnProperty(property);
        }

        return undefined;
    }

    private convertLegacyStringFirstProperty(property: unknown): PropertyDescription | undefined {
        if (isPropertyDescription(property)) {
            return property;
        }

        if (typeof property === "string") {
            return makeStringProperty(property);
        }

        return undefined;
    }

    public convertToPage(appTitle: TitleComponentDescription): ComponentDescription {
        const hero: HeroSectionDescription = {
            ...makeEmptyComponentDescription(WireComponentKind.Hero),
            title: this.convertLegacyColumnFirstProperty(appTitle.titleProperty),
            subtitle: this.convertLegacyColumnFirstProperty(appTitle.subtitleProperty),
            image: this.convertLegacyStringFirstProperty(appTitle.imageURLProperty),
            buttons: definedMap(getActionProperty(appTitle.actions), a =>
                makeArrayProperty([makeActionWithTitle("Action", a, undefined)])
            ),
        };
        return hero;
    }

    public inflate(
        ib: WireInflationBackend,
        desc: TitleComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const { actions } = getActionsForComponent(this, desc, ib.tables, ib.adc, ib.mutatingScreenKind);
        const actionHydrator = inflateActions(ib, actions);

        const { titleGetter, subtitleGetter } = inflateSummary(ib, desc);
        const imageSourceGetter = inflateImageSource(ib, desc.imageKind, desc.imageURLProperty);

        const appearance = getEnumProperty<TitleAppearance>(desc.appearanceProperty) ?? TitleAppearance.Filled;
        const aspect = getEnumProperty<ImageAspectRatio>(desc.aspectProperty);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const title = titleGetter(hb) ?? undefined;

            const onTap = definedMap(actionHydrator, ah =>
                registerBusyActionRunner(hb, "", () => hydrateAction(ah, hb, false, title))
            );

            const component: WireAppTitleComponent = {
                kind: WireComponentKind.AppTitle,
                ...spreadComponentID(desc.componentID, forBuilder),
                title,
                subtitle: subtitleGetter(hb) ?? undefined,
                image: imageSourceGetter(hb),
                appearance,
                aspect,
                onTap,
            };

            return {
                component,
                isValid: true,
            };
        });
    }
}
