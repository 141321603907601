import { assert } from "@glideapps/ts-necessities";
import type { Request } from "express";

// It's really dumb that we have to do this in plugins, but that's how our
// middlewares are set up.  To do things properly we would have the plugins
// endpoint have a very specific, well-defined set of middlewares.
export function parseURLEncoded(req: Request): Record<string, string> {
    assert(req.headers["content-type"] === "application/x-www-form-urlencoded");

    const headers: Record<string, string> = {};

    if (typeof req.body === "object") {
        for (const [key, value] of Object.entries(req.body)) {
            if (typeof value !== "string") continue;
            headers[key.toLowerCase()] = value;
        }
    } else {
        const parsed = new URLSearchParams(req.body.toString());
        parsed.forEach((value, key) => {
            headers[key.toLowerCase()] = value;
        });
    }

    return headers;
}
