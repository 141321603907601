import type { WireBackend, WireBackendInterface } from "@glide/hydrated-ui";
import { BackendProxy } from "@glide/hydrated-ui";
import { ignore } from "@glide/support";
import React from "react";
import type { WireNavigationModel } from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";

export function useUnconfirmedChangeManager<TNavModel extends WireNavigationModel>(
    backend: WireBackend | undefined,
    navModel: TNavModel | undefined
): [WireBackendInterface | undefined, TNavModel | undefined] {
    const [forceUpdate, setForceUpdate] = React.useState({});

    const backendProxy = React.useMemo(() => {
        if (backend === undefined) return undefined;
        return new BackendProxy(backend, setForceUpdate);
    }, [backend]);

    const navModelWithChanges = React.useMemo(() => {
        ignore(forceUpdate);
        return definedMap(navModel, m => backendProxy?.changeManager.overlayChanges(m));
    }, [backendProxy, navModel, forceUpdate]);

    return [backendProxy, navModelWithChanges];
}
