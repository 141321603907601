import { hasOwnProperty } from "@glideapps/ts-necessities";

const twoPow32 = Math.pow(2, 32);

export const cryptoRandom =
    hasOwnProperty(window, "crypto") && hasOwnProperty(window.crypto, "getRandomValues")
        ? () => {
              const buffer = new ArrayBuffer(4);
              const view = new Uint32Array(buffer);
              window.crypto.getRandomValues(view);
              return view[0] / twoPow32;
          }
        : Math.random;
