import styled from "styled-components";
import tw from "twin.macro";

import { signInSecondaryTextColors } from "../custom-sign-in-styles";

export const SecondaryLabelWrapper = styled.div`
    ${tw`leading-5 text-n700`}

    ${signInSecondaryTextColors}
`;
