import { isDefined } from "@glide/support";
import type { RefObject } from "react";
import { useEffect } from "react";

// These selectors represent elements that live in a different portal.
// We can't use our standard useClickOutside hook because these elements
// are rendered outside the normal DOM hierarchy of our editor component.
// This is why we need to explicitly check for these selectors when handling
// click events outside the editor.
// I know this is not ideal, but the elements are controlled.

const allowedSelectors = [
    "[role=dialog]", // Clicked Dropdown
    "[role=menu]", // Nested Menus in the click dropdown
    "#clear-search", // The clear search button in the search input
    "[role=menuitem]", // Individual items within a menu
];

export const useClickOutsideEditor = (editorRef: RefObject<HTMLElement>, clearState: () => void) => {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const target = event.target as Element;

            // Check if the click target matches any of the allowed selectors
            const isAllowedTarget = allowedSelectors.some(
                selector => target.matches(selector) || target.closest(selector) !== null
            );

            if (isAllowedTarget) {
                return;
            }

            if (isDefined(editorRef.current) && editorRef.current !== null && !editorRef.current.contains(target)) {
                clearState();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [editorRef, clearState]);
};
