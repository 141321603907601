import type { WireToggleComponent } from "@glide/fluent-components/dist/js/base-components";
import { WireToggleKind } from "@glide/fluent-components/dist/js/base-components";
import { ValueChangeSource } from "@glide/wire";
import * as React from "react";
import { css } from "styled-components";
import tw from "twin.macro";

import { PagesCheckbox } from "../../components/pages-checkbox";
import {PagesSwitch, type PageSwitchSizes} from "../../components/pages-switch";
import { useWireValue as useWireEditableValue } from "../../wire-lib";
import type { WireRenderer } from "../wire-renderer";

export const WireSwitch: WireRenderer<WireToggleComponent> = React.memo(p => {
    const { value, backend, title, description, toggleKind, size } = p;

    const [checked, setChecked] = useWireEditableValue(value, backend);

    const onChange = (newValue: boolean) => {
        setChecked?.(newValue, ValueChangeSource.User);
    };

    const isCheckbox = toggleKind === WireToggleKind.Checkbox;

    const descriptions = (
        <div tw="flex flex-col">
            <div
                tw="text-base text-text-contextual-dark"
                className={isCheckbox ? "checkbox" : "switch"}
                css={css`
                    &.checkbox {
                        ${tw`font-normal`}
                    }

                    &.switch {
                        ${tw`font-semibold`}
                    }
                `}>
                {title}
            </div>
            <div tw="text-sm text-text-contextual-pale">{description}</div>
        </div>
    );

    if (isCheckbox) {
        return <WireSwitchCheckboxView descriptions={descriptions} checked={checked} onChange={onChange} />;
    } else {
        return <WireSwitchSwitchView descriptions={descriptions} checked={checked} onChange={onChange} size={size}/>;
    }
});

interface WireSwitchViewProps {
    readonly descriptions: React.ReactNode;
    readonly checked: boolean;
    readonly onChange: (newValue: boolean) => void;
    readonly size?: PageSwitchSizes
}

const WireSwitchCheckboxView: React.VFC<WireSwitchViewProps> = p => {
    const { descriptions, checked, onChange } = p;

    return (
        <label tw="flex relative">
            <PagesCheckbox checked={checked} onChange={onChange} />
            {descriptions}
        </label>
    );
};

const WireSwitchSwitchView: React.VFC<WireSwitchViewProps> = p => {
    const { descriptions, checked, onChange, size } = p;

    return (
        <label tw="flex relative justify-between">
            {descriptions}
            <PagesSwitch checked={checked} onChange={event => onChange(event.currentTarget.checked)} size={size} />
        </label>
    );
};
