import { type GlideFC, GlideIcon } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";

import "twin.macro";
import { WireBasePickerStyle } from "./wire-base-picker-style";
import { useFieldStyles } from "../../utils/use-field-styles";
import { useSectionStyle } from "../wire-container/wire-container";
import classNames from "classnames";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

interface WireBasePickerProps {
    className?: string;
    isPlaceholder?: boolean;
    isRequired?: boolean;
    onClick?(ev: React.MouseEvent<HTMLElement>): void;
    leftItem?: React.ReactElement;
    rightItem?: React.ReactElement | "clear";
    rightItemOnClick?(ev: React.MouseEvent<HTMLElement>): void;
    title?: string;
    progressPercentage?: number;
}

export const WireBasePicker: GlideFC<React.PropsWithChildren<WireBasePickerProps>> = props => {
    const {
        isPlaceholder = false,
        title,
        leftItem,
        rightItemOnClick,
        children,
        onClick,
        progressPercentage,
        isRequired,
    } = props;
    const theme = useWireAppTheme();

    let { rightItem } = props;
    if (rightItem === "clear") {
        rightItem = <GlideIcon kind="monotone" icon="mt-circular-cross" iconSize={20} fgColor={theme.n600} />;
    }

    const fieldStyles = useFieldStyles();
    const sectionStyle = useSectionStyle();
    const { pageBackground } = useWireAppTheme();

    return (
        <div tw="flex flex-col">
            <div tw="mb-2 text-sm font-semibold flex justify-between items-baseline gp-md:text-base">
                <h3
                    data-testid="wbp-title"
                    tw="shrink text-text-contextual-dark overflow-hidden text-ellipsis whitespace-nowrap"
                >
                    {title}
                </h3>
                {isRequired && (
                    <div data-testid="wbp-required" tw="shrink-0 ml-2 text-xs text-text-contextual-pale font-normal">
                        {getLocalizedString("required", AppKind.Page)}
                    </div>
                )}
            </div>
            <WireBasePickerStyle
                data-test="base-picker-button"
                tw="px-3 flex items-center [text-align: start] h-10 transition"
                isPlaceholder={isPlaceholder}
                onClick={onClick}
                css={fieldStyles}
                className={classNames(`style-${sectionStyle}`, `page-bg-${pageBackground}`)}
            >
                {leftItem && <div className="leftItem">{leftItem}</div>}
                <div className="main-content" tw="flex-1 text-left text-text-contextual-base">
                    {children}
                </div>
                {rightItem && (
                    <div onClick={rightItemOnClick} className="rightItem">
                        {rightItem}
                    </div>
                )}

                {progressPercentage !== undefined && (
                    <div style={{ width: `${progressPercentage ?? 0}%` }} className="progress-bar" />
                )}
            </WireBasePickerStyle>
        </div>
    );
};
