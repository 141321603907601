import type { TableColumn } from "@glide/type-schema";
import { isTzAwareColumn } from "@glide/formula-specifications";
import { assertNever } from "@glideapps/ts-necessities";
import type { PluginTableColumn } from "./refilter";

export function convertColumnToPluginColumn(c: TableColumn): PluginTableColumn {
    const { name, displayName, type } = c;
    let pluginType: PluginTableColumn["type"] = "string";
    switch (type.kind) {
        case "string":
        // fallthrough
        case "email-address":
        // fallthrough
        case "emoji":
        // fallthrough
        case "markdown":
        // fallthrough
        case "phone-number":
        // Phone numbers are treated like strings internally.
        // fallthrough
        case "time":
            pluginType = "string";
            break;
        case "duration":
        // fallthrough
        case "number":
            pluginType = "number";
            break;
        case "boolean":
            pluginType = "boolean";
            break;
        case "array":
        // fallthrough
        case "table-ref":
            pluginType = "array";
            break;
        case "uri":
        // fallthrough
        case "image-uri":
        // fallthrough
        case "audio-uri":
            pluginType = "url";
            break;
        case "json":
            pluginType = "json";
            break;
        case "date":
        // fallthrough
        case "date-time":
            return { name, displayName, type: "dateTime", timezoneAware: isTzAwareColumn(c) ? true : undefined };
        default:
            return assertNever(type);
    }
    return { name, displayName, type: pluginType };
}
