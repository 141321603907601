import { fetchPageTitle, recognizeLink } from "@glide/common-core/dist/js/components/recognize-link";
import {
    type ComponentDescription,
    type ComponentKind,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    PropertyKind,
    getEnumProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeIconProperty,
    makeStringProperty,
} from "@glide/app-description";
import { type TableColumn, getTableColumnDisplayName, isPrimitiveType } from "@glide/type-schema";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { ListItemFlags } from "@glide/component-utils";
import type { WireAppAdaptiveListItemComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type EnumPropertyCase,
    type PropertyDescriptor,
    PropertySection,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { assert, assertNever } from "@glideapps/ts-necessities";
import { getLastURLPathComponent, tryShortenLinkToHostname } from "@glide/support";
import {
    type WireHydrationFollowUp,
    type WireInflationBackend,
    type WireRowComponentHydratorConstructor,
    WireActionResult,
    WireComponentKind,
} from "@glide/wire";
import { LinkAppearance } from "../builder-utils";
import {
    hydrateAsyncComputation,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

const ComponentKindLink: ComponentKind = "link";

interface LinkComponentDescription extends ComponentDescription {
    readonly propertyName: LegacyPropertyDescription;
    readonly caption: LegacyPropertyDescription | undefined;
    readonly appearance: LegacyPropertyDescription;
}

interface AppearanceData extends EnumPropertyCase<LinkAppearance> {
    uiFunction: string;
}

export const linkAppearances: readonly AppearanceData[] = [
    {
        value: LinkAppearance.PageTitle,
        label: "Page title",
        uiFunction: "pageTitleSmartLink",
    },
    {
        value: LinkAppearance.LastPathComponent,
        label: "Last path component",
        uiFunction: "lastPathComponentSmartLink",
    },
    {
        value: LinkAppearance.ShortURL,
        label: "Short URL",
        uiFunction: "shortURLSmartLink",
    },
    {
        value: LinkAppearance.FullURL,
        label: "Full URL",
        uiFunction: "urlSmartLink",
    },
];

const allAppearances: readonly AppearanceData[] = [
    ...linkAppearances,
    {
        value: LinkAppearance.CaptionAsTitle,
        label: "Caption as title",
        uiFunction: "captionAsTitleSmartLink",
    },
];

export function linkPropertyDescriptor(
    name: string,
    mutatingScreenKind: MutatingScreenKind | undefined,
    isDefaultCaption: boolean
): PropertyDescriptor {
    return makeTextPropertyDescriptor(name, "Link", "Enter URL", true, mutatingScreenKind, {
        preferredNames: ["link", "url"],
        columnFirst: true,
        searchable: false,
        preferredType: "uri",
        isDefaultCaption,
    });
}

export function getTitleAndCaptionForAppearance(
    appearance: LinkAppearance,
    url: string,
    title: string | undefined,
    caption: string | undefined
): [title: string | undefined, caption: string | undefined] {
    switch (appearance) {
        case LinkAppearance.PageTitle:
            return [title, caption];

        case LinkAppearance.LastPathComponent:
            return [getLastURLPathComponent(url) ?? tryShortenLinkToHostname(url), caption];

        case LinkAppearance.ShortURL:
            return [tryShortenLinkToHostname(url), caption];

        case LinkAppearance.FullURL:
            return [url, caption];

        case LinkAppearance.CaptionAsTitle:
            return [caption ?? undefined, undefined];

        default:
            return assertNever(appearance);
    }
}

export class LinkComponentHandler extends ComponentHandlerBase<LinkComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindLink);
    }

    public getDescriptor(
        _desc: LinkComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Link",
            description: "A link that opens when you tap it",
            img: "co-link",
            group: "Buttons",
            helpUrl: getDocURL("link"),
            properties: [
                linkPropertyDescriptor("propertyName", mutatingScreenKind, true),
                {
                    kind: PropertyKind.Enum,
                    property: { name: "appearance" },
                    label: "Label",
                    menuLabel: "Show as",
                    cases: allAppearances,
                    defaultCaseValue: LinkAppearance.PageTitle,
                    section: PropertySection.Design,
                    visual: "dropdown",
                },
                makeCaptionStringPropertyDescriptor("Link", false, mutatingScreenKind),
                ...this.getBasePropertyDescriptors(),
            ],
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: LinkComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const [urlGetter, urlType] = inflateStringProperty(ib, desc.propertyName, false);
        if (urlType === undefined) return undefined;

        const appearance = getEnumProperty<LinkAppearance>(desc.appearance);
        if (appearance === undefined) return undefined;

        const needPageTitle = appearance === LinkAppearance.PageTitle;

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const url = urlGetter(hb) ?? "";
            if (url === "") return undefined;

            const recognized = recognizeLink(url);

            const caption = captionGetter(hb);

            let { title } = recognized;
            let followUp: WireHydrationFollowUp | undefined;
            if (needPageTitle && title === undefined) {
                const result = hydrateAsyncComputation(hb, url, fetchPageTitle);
                title = result.result;
                followUp = result.followUp;
            }

            const [componentTitle, componentCaption] = getTitleAndCaptionForAppearance(
                appearance,
                url,
                title,
                caption ?? undefined
            );

            const action = registerActionRunner(hb, "", [
                async (ab, handled) => {
                    if (!handled) {
                        ab.actionCallbacks.openLink(url);
                    }
                    return WireActionResult.nondescriptSuccess();
                },
                url,
            ]);

            const component: WireAppAdaptiveListItemComponent = {
                kind: WireComponentKind.AppAdaptiveListItem,
                ...spreadComponentID(desc.componentID, forBuilder),
                title: componentTitle ?? null,
                subtitle: componentCaption ?? null,
                caption: null,
                image: null,
                icon: recognized.icon,
                flags: ListItemFlags.WrapText | ListItemFlags.InvertTitleAndSubtitle,
                onTap: action,
            };
            return {
                component,
                isValid: true,
                followUp,
            };
        });
    }

    public static defaultComponent(column: TableColumn): LinkComponentDescription {
        assert(isPrimitiveType(column.type));
        return {
            ...makeEmptyComponentDescription(ComponentKindLink),
            propertyName: makeColumnProperty(column.name),
            caption: makeStringProperty(getTableColumnDisplayName(column)),
            appearance: makeEnumProperty(LinkAppearance.PageTitle),
        };
    }

    public convertToPage(
        desc: LinkComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return {
            ...makeEmptyComponentDescription(WireComponentKind.Link),
            linkTo: desc.propertyName,
            title: desc.caption,
            icon: makeIconProperty("path:/svg/stroke/st-link.svg"),
        } as ComponentDescription;
    }
}
