import React from "react";

const OAuthRedirectPage = React.lazy(() => import("./oauth-redirect"));

const Loader: React.VFC = () => {
    return <p>loading...</p>;
};

export const OAuthRedirectPageLazy: React.VFC = () => {
    return (
        <React.Suspense fallback={<Loader />}>
            <OAuthRedirectPage />
        </React.Suspense>
    );
};
