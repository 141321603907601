import type { SerializablePluginMetadata } from "@glide/plugins";
import type { AppDescription, AppFeatures, NonUserAppFeatures } from "@glide/app-description";
import { getAppFeatures, defaultAppFeatures } from "../components/SerializedApp";

import { getFeatureSetting } from "../feature-settings";

// FIXME: nothing in this file needs to be in `common-core`

export function isMagicLinkInPinEmailEnabled(appFeatures: AppFeatures | NonUserAppFeatures | undefined): boolean {
    return appFeatures?.magicLinkInPinEmail ?? defaultAppFeatures.magicLinkInPinEmail;
}

export function shouldSendMagicLinkInPinEmail(appFeatures: AppFeatures | NonUserAppFeatures | undefined): boolean {
    return getFeatureSetting("magicLinkInPinEmail") && isMagicLinkInPinEmailEnabled(appFeatures);
}

export function isEmailPinEnabled(appFeatures: AppFeatures | NonUserAppFeatures | undefined): boolean {
    return appFeatures?.showSignInWithEmailPin ?? defaultAppFeatures.showSignInWithEmailPin;
}

export function isGoogleSignInEnabled(appFeatures: AppFeatures | NonUserAppFeatures | undefined): boolean {
    return appFeatures?.showSignInWithGoogle ?? defaultAppFeatures.showSignInWithGoogle;
}

// This is for use in the builder, or wherever plugin metadata is conveniently
// available.
export function isSSOSignInEnabled(
    app: AppDescription,
    plugins: readonly SerializablePluginMetadata[] | undefined
): {
    readonly isConfigured: boolean;
    readonly isEnabled: boolean;
} {
    const isConfigured =
        app.pluginConfigs?.some(cfg => {
            const plugin = plugins?.find(p => p.id === cfg.pluginID);
            if (plugin === undefined) return false;
            return plugin.signOns.length > 0;
        }) === true;
    if (!isConfigured) return { isConfigured: false, isEnabled: false };

    const isEnabled = isConfigured && (getAppFeatures(app).showSignInWithSSO ?? defaultAppFeatures.showSignInWithSSO);

    return { isConfigured, isEnabled };
}
