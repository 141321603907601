import { callCloudFunction, urlForCloudFunction } from "../call-cloud-function";
import { callServiceGateway } from "../call-service-gateway";
import { getLocationSettings } from "../location";
import { blockingServiceWorkerInstall } from "./register-service-worker";

export async function callCloudFunctionWeb(
    functionName: string,
    body: any,
    headers: { [key: string]: string } = {},
    stringify: boolean = true
) {
    return blockingServiceWorkerInstall(() =>
        callCloudFunction(getLocationSettings(), functionName, body, headers, stringify)
    );
}

export async function callServiceGatewayWeb(
    endpoint: string,
    body: any,
    headers: { [key: string]: string } = {},
    stringify: boolean = true,
    method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE" = "POST"
) {
    return blockingServiceWorkerInstall(() => callServiceGateway(endpoint, body, headers, stringify, method));
}

export function beaconCloudFunctionWeb(functionName: string, body: { [key: string]: string }): boolean {
    if (!("sendBeacon" in navigator)) return false;

    // It would be great if we could just send application/json here, but we can't,
    // and that's Chrome's fault. For just about three years (2017-2020), instead
    // of fixing a CORS bug with sendBeacon, they just dropped everything that wasn't
    // one of:
    //
    // - text/plain
    // - application/x-www-form-urlencoded
    // - multipart/form-data
    //
    // See https://bugs.chromium.org/p/chromium/issues/detail?id=720283.
    //
    // Since Cloud Functions doesn't natively support multipart/form-data, we'll
    // send application/x-www-form-urlencoded instead.
    const urlParams = new URLSearchParams();
    for (const key of Object.keys(body)) {
        urlParams.append(key, body[key]);
    }
    const { url: targetURL } = urlForCloudFunction(getLocationSettings(), functionName);
    const blob = new Blob([urlParams.toString()], { type: "application/x-www-form-urlencoded" });
    return navigator.sendBeacon(targetURL, blob);
}
