import numeral from "numeral";

import type { EminenceFlags } from "@glide/billing-types";

export const quotasCollectionName = "quotas";

// --- About QuotaContext ---
// Having a QuotaContext of App means all quota usage on the app is specific to that app.
// "org" means that quota usage on the app is against a pool shared with all of an org's apps.
// "app" should not be used in the current pricing model except for QuotaKind.RowsUsed,
// which is a quota that we only enforce at the app level.
// Want to know which QuotaContext to use? Ask the EminenceFlags.
export type QuotaContext = EminenceFlags["quotaContext"];

// ##quotasArePersisted:
// Note that we persist these in quota documents, so we can't change their
// names.  Deleting them should be ok and is probably preferable to keeping
// them around, which might have other side effects we don't know about.
export enum QuotaKind {
    RowsUsed = "rows-used",
    ZapsAndWebhook = "zaps",
    Reloads = "reloads",
    Signatures = "signatures",
    DeliverEmail = "deliver-email",
    FileBytesUsed = "file-bytes-used",
    BarcodesScanned = "barcodes-scanned",
    MapPins = "map-pins",
    PrivateUsers = "private-users",
    Geocodes = "geocodes",
    PublicUsers = "public-users",
    AppEditors = "app-editors",
    Updates = "updates",
    AirtableRowsUsed = "airtable-rows-used",
    PublishedApps = "published-apps",
    BigTableRows = "big-table-rows",
    SQLRows = "sql-rows",
    Users = "users",
}

export const quotaNeedsReset: Record<QuotaKind, boolean> = {
    [QuotaKind.RowsUsed]: false,
    [QuotaKind.ZapsAndWebhook]: true,
    [QuotaKind.Reloads]: true,
    [QuotaKind.Signatures]: true,
    [QuotaKind.DeliverEmail]: true,
    [QuotaKind.FileBytesUsed]: false,
    [QuotaKind.BarcodesScanned]: true,
    [QuotaKind.MapPins]: true,
    [QuotaKind.PrivateUsers]: false,
    [QuotaKind.Geocodes]: true,
    [QuotaKind.PublicUsers]: false,
    [QuotaKind.AppEditors]: false,
    [QuotaKind.Updates]: true,
    [QuotaKind.AirtableRowsUsed]: true,
    [QuotaKind.PublishedApps]: false,
    [QuotaKind.BigTableRows]: false,
    [QuotaKind.SQLRows]: false,
    [QuotaKind.Users]: false,
};

export const frontendCheckQuotaKinds: QuotaKind[] = [];

export function isQuotaKind(k: string): k is QuotaKind {
    return Object.keys(quotaNeedsReset).indexOf(k) >= 0;
}

export interface QuotaLimits {
    readonly prepaidUsage: number;
    readonly maxOverage: number;
}

export function areQuotaLimitsEqual(l1: QuotaLimits | undefined, l2: QuotaLimits | undefined): boolean {
    return l1?.prepaidUsage === l2?.prepaidUsage && l1?.maxOverage === l2?.maxOverage;
}

export function getQuotaLimitIncludingOverage(l: QuotaLimits): number {
    return Math.min(Number.MAX_SAFE_INTEGER, l.prepaidUsage + l.maxOverage);
}

export type QuotaLimitList = Record<QuotaKind, QuotaLimits>;

export const defaultQuotaLimits: QuotaLimitList = {
    [QuotaKind.RowsUsed]: { prepaidUsage: 500, maxOverage: 0 },
    [QuotaKind.ZapsAndWebhook]: { prepaidUsage: 100, maxOverage: 0 },
    [QuotaKind.Reloads]: { prepaidUsage: 1000, maxOverage: 0 },
    [QuotaKind.Signatures]: { prepaidUsage: 10, maxOverage: 0 },
    [QuotaKind.DeliverEmail]: { prepaidUsage: 0, maxOverage: 0 },
    [QuotaKind.FileBytesUsed]: { prepaidUsage: 100 * 1000 * 1000, maxOverage: 0 },
    [QuotaKind.BarcodesScanned]: { prepaidUsage: 10, maxOverage: 0 },
    [QuotaKind.MapPins]: { prepaidUsage: 10, maxOverage: 0 },
    [QuotaKind.PrivateUsers]: { prepaidUsage: 0, maxOverage: 0 },
    [QuotaKind.Geocodes]: { prepaidUsage: 10, maxOverage: 0 },
    [QuotaKind.PublicUsers]: { prepaidUsage: 100, maxOverage: 0 },
    [QuotaKind.AppEditors]: { prepaidUsage: 1, maxOverage: 0 },
    [QuotaKind.Updates]: { prepaidUsage: Number.MAX_SAFE_INTEGER, maxOverage: 0 },
    [QuotaKind.AirtableRowsUsed]: { prepaidUsage: 10, maxOverage: 0 },
    [QuotaKind.PublishedApps]: { prepaidUsage: 1_000_000, maxOverage: 0 },
    [QuotaKind.BigTableRows]: { prepaidUsage: 100_000, maxOverage: 0 },
    [QuotaKind.SQLRows]: { prepaidUsage: 100_000, maxOverage: 0 },
    [QuotaKind.Users]: { prepaidUsage: Number.MAX_SAFE_INTEGER, maxOverage: 0 },
};

export const prepaidUsersOnPrivateAppPlan = 20;

export interface QuotaValues {
    readonly current: number;
    readonly lastResetAt?: Date;
}

interface QuotaInfo {
    readonly kind: QuotaKind;
    readonly shortName: string;
    readonly longName?: string;
    readonly subtitle: string | undefined;
    readonly formatFunc?: (item: number) => string;
}

export const quotaInfos: QuotaInfo[] = [
    {
        kind: QuotaKind.Updates,
        shortName: "Updates",
        subtitle: undefined,
    },
    {
        kind: QuotaKind.RowsUsed,
        shortName: "Row",
        subtitle: undefined,
    },
    {
        kind: QuotaKind.FileBytesUsed,
        longName: "Images & Files",
        shortName: "File Storage",
        subtitle: undefined,
        formatFunc: i => numeral(i).format("0b"),
    },
    {
        kind: QuotaKind.Reloads,
        shortName: "Edits",
        longName: "Sheet Edits",
        subtitle: "(Per month)",
    },
    {
        kind: QuotaKind.ZapsAndWebhook,
        longName: "Zaps & Webhooks",
        shortName: "Webhooks",
        subtitle: "(Per month)",
    },
    {
        kind: QuotaKind.Signatures,
        shortName: "Signatures",
        subtitle: "(Per month)",
    },
    {
        kind: QuotaKind.DeliverEmail,
        longName: "Emails Sent",
        shortName: "Emails",
        subtitle: "(Per month)",
    },
    {
        kind: QuotaKind.BarcodesScanned,
        longName: "Barcodes Scanned",
        shortName: "Barcodes",
        subtitle: "(Per month)",
    },
    {
        kind: QuotaKind.MapPins,
        longName: "Map Pins Created",
        shortName: "Map Pins",
        subtitle: "(Per month)",
    },
    {
        kind: QuotaKind.PrivateUsers,
        longName: "Private User Signins",
        shortName: "Private Users",
        subtitle: "(Per month)",
    },
    {
        kind: QuotaKind.PublicUsers,
        longName: "Public User Signins",
        shortName: "Public Users",
        subtitle: "(Per month)",
    },
    {
        kind: QuotaKind.AppEditors,
        longName: "App Editors",
        shortName: "Editors",
        subtitle: "(Per month)",
    },
];

export type AllQuotaValues = Record<QuotaKind, QuotaValues>;

export type QuotaValuesForKinds = Partial<AllQuotaValues>;

export interface QuotaDocument {
    readonly quotas: QuotaValuesForKinds;
    readonly quotaVersions: Partial<Record<QuotaKind, { confirmed: number; next: number }>>;
}

export function makeQuotaDocumentID(context: QuotaContext, id: string): string {
    return `${context}-${id}`;
}
