import { sessionStorageGetItem, sessionStorageRemoveItem, sessionStorageSetItem } from "@glide/support";

function explicitlySignedOutSessionKey(appID: string): string {
    return `glide-signed-out-${appID}`;
}

export function flagExplicitSignOut(appID: string): void {
    sessionStorageSetItem(explicitlySignedOutSessionKey(appID), "yes");
}

// If we reach a point where we have to check for an explicit sign-out, we also
// need to clear the "explicit sign out key". Checking this twice in a row means
// that we weren't explicitly signed out the second time.
//
// We could eagerly clear this whenever we sign in, but that's a lot of different
// entry points to hook, and the resulting "bug" is simply that you'll have to
// explicitly authenticate again.
export function checkExplicitSignOut(appID: string) {
    const key = explicitlySignedOutSessionKey(appID);
    const explicit = sessionStorageGetItem(key);
    if (explicit !== undefined) {
        sessionStorageRemoveItem(key);
    }
    return explicit !== undefined;
}
