import { styled } from "@glide/common";
import * as Elements from "@glide/common-core/dist/js/shared-ui/style";

export const Wrapper = styled.div`
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
`;

export const Header = styled(Elements.Header)`
    font-weight: ${p => p.theme.ultraHeavyFontWeight};
`;

export const Subtitle = styled(Elements.Label)`
    margin-top: 4px;
    color: ${p => p.theme.fgColorLight};
`;

export const Body = styled(Elements.Label)`
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 20px;

    color: ${p => p.theme.fgColorDark};

    white-space: pre-wrap;

    max-height: 70vh;
    overflow: hidden;
`;

export const ShareLink = styled(Elements.Label)<{ color?: string }>`
    position: relative;
    height: 32px;
    width: 340px;
    border-radius: 16px;
    padding: 0 12px;
    margin-right: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${p => (p.color !== undefined ? p.color : p.theme.fgColorMedium)};
    p,
    a {
        margin: 0 auto;
        font-weight: ${p => p.theme.heavyFontWeight};
        font-size: 14px;
        color: white;
        text-transform: uppercase;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 12px;
`;

export const ShareWrapper = styled.div`
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 350px;
    cursor: pointer;

    .share-link {
        margin-bottom: 10px;
    }
`;
