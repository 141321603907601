import * as React from "react";
import { Wrapper, ModalArea, Description, ButtonWrapper, Cancel, Title, Accept } from "./confirm-modal-style";
import type { ConfirmModalStyle } from "@glide/common-core/dist/js/components/confirm-modal";

interface Props extends React.PropsWithChildren {
    title: string;
    description: string;
    onFinish: (accepted: boolean) => void;
    accept: string;
    cancel?: string;
    modalStyle: ConfirmModalStyle;
}

export default class ConfirmModal extends React.PureComponent<Props> {
    private onAccept = (ev: React.MouseEvent<HTMLDivElement>) => {
        this.props.onFinish(true);
        ev.stopPropagation();
    };

    private onCancel = (ev: React.MouseEvent<HTMLDivElement>) => {
        this.props.onFinish(false);
        ev.stopPropagation();
    };

    private onIgnore = (ev: React.MouseEvent<HTMLDivElement>) => {
        ev.stopPropagation();
    };

    public render(): React.ReactNode {
        const { title, description, accept, cancel, modalStyle: style } = this.props;

        return (
            <Wrapper onClick={this.onCancel} className={style}>
                <ModalArea onClick={this.onIgnore}>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    <ButtonWrapper>
                        {cancel !== undefined && <Cancel onClick={this.onCancel}>{cancel}</Cancel>}
                        <Accept isDelete={style === "delete"} onClick={this.onAccept}>
                            {accept}
                        </Accept>
                    </ButtonWrapper>
                </ModalArea>
            </Wrapper>
        );
    }
}
