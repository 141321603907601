/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { maybeParseJSON } from "@glide/support";

const { Result } = glide;

export const plugin = glide.newPlugin({
    id: "twilio", // DO NOT CHANGE
    name: "Twilio",
    description: "Send SMS messages from your Twilio account",

    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/twilio.png",
    tier: "starter",
    parameters: {
        accountSID: glide.makeParameter({
            type: "string",
            name: "Account SID",
            description:
                "Your SID is a 34-digit string that can be found in your [Twilio console](https://www.twilio.com/console)",
            required: true,
        }),
        authToken: glide.makeParameter({
            type: "secret",
            name: "Auth token",
            description: "Your token can be found in your [Twilio console](https://www.twilio.com/console)",
            required: true,
        }),
        senderPhoneNumber: glide.makeParameter({
            type: "string",
            name: "Sender number",
            description: "This is the Twilio number that will be used to send SMS messages",
            placeholder: "e.g. +12345678900",
            required: true,
        }),
        userPhoneNumber: glide.makeUserProfileParameter({
            type: "string",
            name: "Number for pins",
            description:
                "The column that has your users' phone numbers to send pin texts. If no number is set, the user will receive a pin email.",
            placeholder: "e.g. +12345678900",
            disallowSettingColumn: true,
        }),
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/twilio",
});

const phoneNumber = glide.makeParameter({
    type: "string",
    name: "Recipient",
    placeholder: "e.g. +1 (234) 567-8900",
    required: true,
    useTemplate: "withLabel",
});

const message = glide.makeParameter({
    type: "string",
    name: "Message",
    required: true,
    useTemplate: "withLabel",
});

async function sendSMS(
    ctx: glide.ServerExecutionContext,
    accountSID: string | undefined,
    authToken: string | undefined,
    senderPhoneNumber: string | undefined,
    toPhoneNumber: string | undefined,
    message: string | undefined
) {
    const endpoint = `https://api.twilio.com/2010-04-01/Accounts/${accountSID}/Messages.json`;
    const encodedPhoneNumber = urlEncode(toPhoneNumber);
    const encodedSenderPhoneNumber = urlEncode(senderPhoneNumber);
    const encodedMessage = urlEncode(message);

    try {
        const response = await ctx.fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Basic " + Buffer.from(`${accountSID}:${authToken}`).toString("base64"),
            },
            body: `From=${encodedSenderPhoneNumber}&To=${encodedPhoneNumber}&Body=${encodedMessage}`,
        });

        const asText = await response.text();

        if (!response.ok) {
            return Result.FailFromHTTPStatus(asText, response.status, {
                data: maybeParseJSON(asText),
            });
        } else {
            const asJSON = JSON.parse(asText);
            if (asJSON.error_code === null) {
                ctx.consumeBillable();
                return Result.Ok();
            }
            return Result.FailFromHTTPStatus(asJSON.error, response.status, {
                data: maybeParseJSON(asText),
            });
        }
    } catch (e: unknown) {
        return Result.Fail("There was an unknown error", {
            data: maybeParseJSON(e),
        });
    }
}

plugin.addAction({
    id: "send-sms",
    name: "Send SMS message",
    description: "Send an SMS message with Twilio",
    billablesConsumed: 2,
    parameters: {
        phoneNumber,
        message,
    },
    execute: (context, { phoneNumber, message, accountSID, senderPhoneNumber, authToken }) => {
        return sendSMS(context, accountSID, authToken, senderPhoneNumber, phoneNumber, message);
    },
});

plugin.addAction({
    id: "send-whatsapp-message",
    name: "Send WhatsApp Message",
    description: "Send a WhatsApp message using Twilio",
    billablesConsumed: 5,
    parameters: {
        phoneNumber,
        message,
    },
    execute: async (context, { phoneNumber, message, accountSID, senderPhoneNumber, authToken }) => {
        const endpoint = `https://api.twilio.com/2010-04-01/Accounts/${accountSID}/Messages.json`;
        const encodedPhoneNumber = urlEncode(phoneNumber);
        const encodedSenderPhoneNumber = urlEncode(senderPhoneNumber);
        const encodedMessage = urlEncode(message);

        try {
            const response = await context.fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Basic " + Buffer.from(`${accountSID}:${authToken}`).toString("base64"),
                },
                body: `From=whatsapp:${encodedSenderPhoneNumber}&To=whatsapp:${encodedPhoneNumber}&Body=${encodedMessage}`,
            });

            const asText = await response.text();

            if (!response.ok) {
                return Result.FailFromHTTPStatus(asText, response.status, {
                    data: maybeParseJSON(asText),
                });
            } else {
                const asJSON = JSON.parse(asText);
                if (asJSON.error_code === null) {
                    context.consumeBillable();
                    return Result.Ok();
                }
                return Result.Fail(asJSON.error, {
                    status: response.status,
                    data: maybeParseJSON(asText),
                });
            }
        } catch (e: unknown) {
            return Result.Fail("There was an unknown error", {
                data: maybeParseJSON(e),
            });
        }
    },
});

plugin.addNotificationSender({
    id: "twilio-sms-notification",
    name: "Pin texts from Twilio",
    description: "Users receive pins via SMS.",
    priority: 120,
    sendNotification: async (
        context,
        { accountSID, authToken, senderPhoneNumber, userPhoneNumber },
        _receiverEmail,
        subject,
        _body,
        _htmlBody
    ) => {
        // console.error("Sending SMS notification", accountSID, authToken, senderPhoneNumber, userPhoneNumber, subject);
        userPhoneNumber = (userPhoneNumber ?? "").trim();
        if (userPhoneNumber === "") return Result.Fail("No user phone number provided");
        const r = await sendSMS(context, accountSID, authToken, senderPhoneNumber, userPhoneNumber, subject);
        if (!r.ok) return r;
        return glide.Result.Ok({ method: "sms", address: userPhoneNumber });
    },
    billablesConsumed: 2,
});

function urlEncode(value: string | undefined): string | undefined {
    if (value === undefined) return value;
    return encodeURIComponent(value);
}
