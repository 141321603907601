export function* lazyChunk<T>(it: Iterable<T>, chunkSize: number = 1): IterableIterator<T[]> {
    let curResults: T[] = [];
    for (const item of it) {
        curResults.push(item);
        if (curResults.length >= chunkSize) {
            yield curResults;
            curResults = [];
        }
    }
    if (curResults.length > 0) yield curResults;
}
