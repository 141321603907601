import type { Owner, UserDataAndState } from "@glide/common-core/dist/js/Database";
import { isOrganizationAdmin, isUserData } from "@glide/common-core/dist/js/Database";
import { OwnerKind } from "@glide/common-core/dist/js/Database/owner-kind";
import type { MakeSupportCodeForAppBody } from "@glide/common-core/dist/js/firebase-function-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import type { SimpleModalContextProps } from "@glide/designer-components";
import { deleteOrg, CreateSupportLink } from "@glide/designer-components";
import { checkString, isResponseOK } from "@glide/support";

export function isOrgOwner(owner: Owner | undefined, userData: UserDataAndState): boolean {
    if (owner !== undefined && owner.kind === OwnerKind.Organization && isUserData(userData)) {
        if (isOrganizationAdmin(owner, userData.id)) {
            return true;
        }
    }
    return false;
}

const createSupportURL = async (appID: string, modals: SimpleModalContextProps): Promise<string | undefined> => {
    const body: MakeSupportCodeForAppBody = { appID };
    const response = await getAppFacilities().callAuthCloudFunction("makeSupportCodeForApp", body);
    if (!isResponseOK(response)) {
        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        void response?.text();
        await modals.showErrorModal(1006);
        return;
    }
    const { url } = await response.json();
    return url;
};

export const makeSupportCode = async (appID: string, modals: SimpleModalContextProps): Promise<void> => {
    const url = await createSupportURL(appID, modals);
    const getSupport = await modals.showAcceptCancelModal(
        CreateSupportLink(checkString(url)).title,
        CreateSupportLink(checkString(url)).bodyContent,
        "Get Support"
    );
    if (!getSupport) return;

    const anyWindow = window as any;
    if (anyWindow.Intercom === undefined) return;
    anyWindow.Intercom("show");
};

export async function showCannotDeleteTeamInfoModal(modals: SimpleModalContextProps) {
    await modals.showSimpleInfoModal(
        "Cannot delete team",
        "This team cannot be deleted because it is the last team you are a member of. \n\nTo delete this team please first make another team using the left panel."
    );
}

// Returns whether the org was deleted
export const showDeleteOrgModal = async (
    orgID: string,
    modals: SimpleModalContextProps,
    orgDisplayName?: string
): Promise<boolean> => {
    const shouldDelete = await modals.showAcceptCancelModal(
        `Delete ${orgDisplayName}?`,
        `Your team and all of its apps will be permanently deleted with no chance of recovery, even if you contact Glide support.`,
        `Delete`,
        {
            acceptButtonVariance: "danger",
            requireUserToType: `delete ${orgDisplayName} forever`,
        }
    );
    if (!shouldDelete) return false;
    const didDelete = await deleteOrg(orgID);
    if (!didDelete) {
        await modals.showErrorModal(1063);
    }
    return didDelete;
};
