import type { ColumnType } from "@glide/type-schema";

export const titleProperties: ReadonlyArray<string> = ["name", "title", "summary", "caption"];
export const phoneProperties: readonly string[] = ["phone", "mobile"];
export const imageProperties: ReadonlyArray<string> = [
    "photo",
    "photos",
    "image",
    "images",
    "picture",
    "pictures",
    "img",
    "imgs",
    "pic",
    "pics",
    "gallery",
    "carousel",
    "logo",
];

export function isImageType(t: ColumnType, withEmoji: boolean, allowArrayColumn: boolean): boolean {
    return (
        t.kind === "image-uri" ||
        (withEmoji && t.kind === "emoji") ||
        (allowArrayColumn && t.kind === "array" && t.items.kind === "image-uri")
    );
}
