import type { ComponentIndexes } from "@glide/common-core/dist/js/component-indexes";
import type {
    ClassOrArrayScreenDescription,
    ComponentDescription,
    MutatingScreenKind,
    ScreenDescription,
} from "@glide/app-description";
import {
    type InputOutputTables,
    getScreenComponents,
    isClassOrArrayScreenDescription,
} from "@glide/common-core/dist/js/description";
import type { SchemaInspector } from "@glide/type-schema";
import { getMutatingKindForScreen } from "@glide/function-utils";
import { assert, assertNever } from "@glideapps/ts-necessities";
import { iterableEnumerate } from "collection-utils";

import { inputOutputTablesForClassOrArrayScreen } from "./description-utils";
import { handlerForComponentKind } from "./handlers";

export function getSubComponents(desc: ComponentDescription): readonly ComponentDescription[] | undefined {
    const handler = handlerForComponentKind(desc.kind);
    return handler?.getSubComponents(desc);
}

export function getSubComponentsWithTables(
    desc: ComponentDescription,
    containingTables: InputOutputTables | undefined,
    schema: SchemaInspector,
    mutatingScreenKind: MutatingScreenKind | undefined
):
    | [
          subComponents: readonly ComponentDescription[],
          tables: InputOutputTables | undefined,
          mutatingScreenKind: MutatingScreenKind | undefined
      ]
    | undefined {
    const handler = handlerForComponentKind(desc.kind);
    if (handler === undefined) return undefined;

    const components = handler.getSubComponents(desc);
    if (components === undefined) return undefined;

    if (containingTables === undefined) {
        return [components, undefined, undefined];
    }

    const tables = handler.getSubComponentTables(desc, containingTables, schema, mutatingScreenKind);
    if (tables === undefined) return undefined;

    return [components, ...tables];
}

// If the component is part of the screen, this will return at least the
// indexes.
export function getTablesForComponentInScreen(
    screenName: string,
    screenDesc: ClassOrArrayScreenDescription,
    desc: ComponentDescription,
    schema: SchemaInspector
): [InputOutputTables | undefined, MutatingScreenKind | undefined, ComponentIndexes] | undefined {
    const indexes = findComponentIndex(screenDesc, desc);
    if (indexes === undefined) return undefined;

    const screenTables = inputOutputTablesForClassOrArrayScreen(screenDesc, schema.schema);
    if (screenTables === undefined) return [undefined, undefined, indexes];

    if (indexes.length === 2) {
        const [thisComponent, parentComponent] = getComponentAtIndexes(screenDesc, indexes);
        assert(thisComponent === desc && parentComponent !== undefined);

        const parentHandler = handlerForComponentKind(parentComponent.kind);
        if (parentHandler === undefined) return undefined;

        const maybeTables = parentHandler.getSubComponentTables(
            parentComponent,
            screenTables,
            schema,
            getMutatingKindForScreen(screenName, screenDesc)
        );
        if (maybeTables === undefined) return [undefined, undefined, indexes];

        return [...maybeTables, indexes];
    } else {
        return [screenTables, getMutatingKindForScreen(screenName, screenDesc), indexes];
    }
}

export function isFirstComponentIndex(indexes: ComponentIndexes): boolean {
    if (indexes.length !== 1) return false;
    return indexes[0] === 0;
}

// Returns [component, top-level].  Those two are the same if `indexes` has
// length 1.  If not, then `top-level` is the parent of `component`.
export function getComponentAtIndexes(
    screen: ScreenDescription,
    indexes: ComponentIndexes
): [ComponentDescription, ComponentDescription] | [undefined, ComponentDescription | undefined] {
    if (!isClassOrArrayScreenDescription(screen)) return [undefined, undefined];
    const components = getScreenComponents(screen);
    const parent = components[indexes[0]];
    if (indexes.length === 1) {
        return [parent, parent];
    } else if (indexes.length === 2) {
        if (parent === undefined) return [undefined, undefined];
        const subComponents = getSubComponents(parent);
        return [subComponents?.[indexes[1]], parent];
    } else {
        return assertNever(indexes);
    }
}

// This will return an index that's a valid component index, with one
// exception: If `stayInsideContainer` is set, and `indexes` points to the
// last component inside a container, it will return one beyond the index of
// that last component inside the container.  We use that for duplicating
// components, so duplicating the last component inside a container also puts
// the copy into the container.
export function nextComponentIndex(
    screen: ScreenDescription,
    indexes: ComponentIndexes,
    stayInsideContainer: boolean
): ComponentIndexes | undefined {
    if (!isClassOrArrayScreenDescription(screen)) return undefined;
    const components = getScreenComponents(screen);

    if (indexes.length === 1) {
        if (indexes[0] + 1 >= components.length) return undefined;
        return [indexes[0] + 1];
    } else if (indexes.length === 2) {
        const [, parent] = getComponentAtIndexes(screen, indexes);
        if (parent === undefined) return undefined;
        const subComponents = getSubComponents(parent);
        if (subComponents !== undefined) {
            const nextIndex = indexes[1] + 1;
            if (nextIndex < subComponents.length || (stayInsideContainer && nextIndex <= subComponents.length)) {
                return [indexes[0], indexes[1] + 1];
            }
        }
        if (indexes[0] + 1 >= components.length) return undefined;
        return [indexes[0] + 1];
    } else {
        return assertNever(indexes);
    }
}

export function prevComponentIndex(indexes: ComponentIndexes): ComponentIndexes {
    if (indexes.length === 1) {
        return [Math.max(0, indexes[0] - 1)];
    } else if (indexes.length === 2) {
        return [indexes[0], Math.max(0, indexes[1] - 1)];
    } else {
        return assertNever(indexes);
    }
}

function findComponentIndex(screen: ScreenDescription, component: ComponentDescription): ComponentIndexes | undefined {
    if (!isClassOrArrayScreenDescription(screen)) return undefined;
    const components = getScreenComponents(screen);

    for (const [index, parent] of iterableEnumerate(components)) {
        if (parent === component) {
            return [index];
        }
        const subComponents = getSubComponents(parent);
        if (subComponents === undefined) continue;
        for (const [subIndex, sub] of iterableEnumerate(subComponents)) {
            if (sub === component) {
                return [index, subIndex];
            }
        }
    }
    return undefined;
}
