import * as t from "io-ts";

type PrettyIOTS<T> = {
    [K in keyof T]: T[K] extends object ? PrettyIOTS<T[K]> : T[K];
} & {};

type TypeOfIOTS<T extends t.Any> = PrettyIOTS<t.TypeOf<T>>;

const propertyDescriptionCodec = t.any;

const themeOverlayCodec = t.keyof({
    none: null,
    dark: null,
    color: null,
    colorDark: null,
    pureblack: null,
    purewhite: null,
});

const policyLinkCodec = t.type({
    title: propertyDescriptionCodec,
    url: propertyDescriptionCodec,
});

const logoDisplayCodec = t.union([
    t.literal("none"),
    t.literal("logoAndTitle"),
    t.literal("logoOnly"),
    t.literal("titleOnly"),
    t.literal("iconAndTitle"),
]);

export const baseThemeCodec = t.intersection([
    t.type({
        primaryAccentColor: t.string,
        showTabLabels: t.boolean,
    }),
    t.partial({
        // FIXME: Why is the theme overlay sometimes missing?
        themeOverlay: themeOverlayCodec,
        themeIsAdaptive: t.boolean,
        increaseContrast: t.boolean,
        showDesktopSideBar: t.boolean,
        showIconsInNavBar: t.boolean,
        showLabelsInTabBar: t.boolean,

        pageTheme: t.union([t.literal("Accent"), t.literal("Dark"), t.literal("Highlight")]),
        pageBackground: t.union([t.literal("Neutral"), t.literal("Highlight")]),
        pageEnvironment: t.union([t.literal("Auto"), t.literal("Light"), t.literal("Dark")]),
        pageContainer: t.union([t.literal("Normal"), t.literal("Tight"), t.literal("Narrow")]),

        // FIXME: Type properly
        signInColorTheme: t.string,
        signInBackground: t.string,
        signInBackgroundUploadFileName: t.string,
        signInLogo: t.string,
        signInLogoUploadFileName: t.string,
        // "signInLogo" for pages was borrowed by the "Name & Icon"
        // settings section and subsequently used in the player. To
        // complete https://github.com/quicktype/glide/issues/18334 we
        // needed to create another property that is the "signInLogo"
        // (on sign in screen)
        pagesSignInLogo: t.string,
        pagesSignInLogoUploadFileName: t.string,
        signInGreetingText: t.string,
        signInDescriptionText: t.string,
        signInBackgroundOverlay: t.string,
        signInLogoSize: t.string,

        fontTheme: t.string,
        // user agreement props
        requiresAgreement: t.boolean,
        agreementText: propertyDescriptionCodec,
        policyLinks: t.readonlyArray(policyLinkCodec),
        logoDisplay: logoDisplayCodec,
    }),
]);

export type BaseTheme = TypeOfIOTS<typeof baseThemeCodec>;
export type ThemeOverlay = t.TypeOf<typeof themeOverlayCodec>;
