import {
    type TableName,
    makeTableName,
    nativeTableRowIDColumnName,
    type ColumnType,
    type PrimitiveGlideTypeKind,
    type TableColumn,
    type TableGlideType,
    type TableRefGlideType,
    type TypeSchema,
    findTable,
    makePrimitiveType,
    makeTypeSchema,
    type UserProfileTableInfo,
    type ExternalSource,
    type MultiRelationType,
    type NativeTableID,
} from "@glide/type-schema";
import { panic, assert, defined, definedMap, hasOwnProperty, mapFilterUndefined } from "@glideapps/ts-necessities";
import { isArray, replaceArrayItem } from "@glide/support";

import { commentsTable } from "../comments-table";
import { makeSimpleSchemaInspector } from "../components/simple-ccc";
import { makeTypeForComputation } from "../computed-columns";
import {
    type SyntheticColumnSpecification,
    getDefaultDisplayFormulaForTypeKind,
    makeFormulaForSpecification,
} from "@glide/formula-specifications";

export interface DataColumnSpec {
    readonly name: string;
    readonly type: PrimitiveGlideTypeKind | "primitive-array" | TableRefGlideType | MultiRelationType;
    readonly withDisplayFormula?: boolean;
    readonly isUserSpecific?: boolean;
}

interface ComputedColumnSpec {
    readonly name: string;
    readonly spec: SyntheticColumnSpecification;
    // If this is given, then we take it as given and don't try to compute the
    // type.  This is for debugging cases where the schema is inconsistent.
    readonly forceType?: ColumnType;
}

// A `string` is the column name for a string column
export type ColumnSpec = string | DataColumnSpec | ComputedColumnSpec;

export interface TableSpec {
    readonly name: string | TableName;
    readonly columns: readonly ColumnSpec[];
    readonly isQueryable?: boolean;
    // Must be defined if `isQueryable` is `true`
    readonly nativeTableID?: NativeTableID;
    readonly emailOwnersColumn?: readonly string[];
    readonly externalSource?: ExternalSource;
    readonly sheetName?: string;
}

export interface MakeSchemaOptions {
    readonly includeComments?: boolean;
    readonly userProfile?: UserProfileTableInfo;
}

export function makeSchema(tableSpecs: readonly TableSpec[], opts?: MakeSchemaOptions): TypeSchema {
    let toAdd: [TableSpec, ColumnSpec][] = [];
    for (const table of tableSpecs) {
        for (const column of table.columns) {
            toAdd.push([table, column]);
        }
    }

    let tables: TableGlideType[] = [];
    function setTable(old: TableGlideType, replacement: TableGlideType) {
        const index = tables.indexOf(old);
        assert(index >= 0);
        tables = replaceArrayItem(tables, index, replacement);
    }

    while (toAdd.length > 0) {
        const remaining: typeof toAdd = [];
        const schemaSoFar = makeTypeSchema(tables);
        const schemaInspector = makeSimpleSchemaInspector(
            schemaSoFar,
            mapFilterUndefined(schemaSoFar.tables, t => t.sourceMetadata),
            opts?.userProfile
        );

        for (const [tableSpec, columnSpec] of toAdd) {
            const tableName = makeTableName(tableSpec.name);

            // add the table if it's not there yet
            let table = findTable(tables, tableName);
            if (table === undefined) {
                table = {
                    name: tableName,
                    rowIDColumn: nativeTableRowIDColumnName,
                    columns: [],
                    sourceMetadata: definedMap(tableSpec.nativeTableID, id => ({
                        type: "Native table",
                        id,
                        tableName,
                        externalSource: tableSpec.externalSource,
                    })),
                    emailOwnersColumn: tableSpec.emailOwnersColumn,
                };
                if (tableSpec.sheetName !== undefined) {
                    table = { ...table, sheetName: tableSpec.sheetName };
                }
                if (tableSpec.isQueryable === true) {
                    table = {
                        ...table,
                        sourceMetadata: {
                            type: "Native table",
                            id: defined(tableSpec.nativeTableID),
                            tableName,
                            needsQuery: true,
                            externalSource: tableSpec.externalSource,
                        },
                    };
                }
                tables.push(table);
            }

            // try adding the column
            let newColumn: TableColumn | undefined;
            if (typeof columnSpec === "string") {
                newColumn = {
                    name: columnSpec,
                    type: { kind: "string" },
                };
            } else if (hasOwnProperty(columnSpec, "type")) {
                if (columnSpec.type === "primitive-array") {
                    newColumn = {
                        name: columnSpec.name,
                        type: {
                            kind: "array",
                            items: makePrimitiveType("string"),
                        },
                        isUserSpecific: columnSpec.isUserSpecific,
                    };
                } else {
                    newColumn = {
                        name: columnSpec.name,
                        type:
                            typeof columnSpec.type === "string" ? makePrimitiveType(columnSpec.type) : columnSpec.type,
                        isUserSpecific: columnSpec.isUserSpecific,
                        displayFormula:
                            columnSpec.withDisplayFormula === true && typeof columnSpec.type === "string"
                                ? getDefaultDisplayFormulaForTypeKind(columnSpec.type, "local")
                                : undefined,
                    };
                }
            } else {
                const formula = makeFormulaForSpecification(schemaInspector, tableName, columnSpec.spec);
                if (formula !== undefined) {
                    const type =
                        columnSpec.forceType ?? makeTypeForComputation(schemaInspector, table, formula, undefined);
                    if (!isArray(type)) {
                        newColumn = { name: columnSpec.name, type, formula };
                    } else {
                        // console.error("Could not make type for column", JSON.stringify(formula), JSON.stringify(type));
                    }
                } else {
                    // console.error("Failed to make formula for", JSON.stringify(columnSpec.spec));
                }
            }

            if (newColumn !== undefined) {
                // if it works, great
                setTable(table, { ...table, columns: [...table.columns, newColumn] });
            } else {
                // if it doesn't, push to remaining
                remaining.push([tableSpec, columnSpec]);
            }
        }

        // If this fails it usually means that some column couldn't be built.
        // Check that you're not referring to tables that don't exist, for
        // example.
        assert(remaining.length < toAdd.length);
        toAdd = remaining;
    }

    if (opts?.includeComments === true) {
        tables.push(commentsTable);
    }

    return makeTypeSchema(tables);
}

export function makeTable(tableSpec: TableSpec): TableGlideType {
    const schema = makeSchema([tableSpec]);
    assert(schema.tables.length === 1);
    return schema.tables[0];
}

export function specFromTable(table: TableGlideType): TableSpec {
    assert(table.emailOwnersColumn === undefined);
    return {
        ...table,
        emailOwnersColumn: undefined,
        columns: table.columns.map(c => {
            const type = c.type.kind;
            if (type === "array" || type === "table-ref") {
                return panic("Only supports PrimitiveGlideTypeKind or 'primitive-array'");
            }
            return { ...c, type };
        }),
    };
}
