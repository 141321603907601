import { fetchPageTitle } from "@glide/common-core/dist/js/components/recognize-link";
import { AppKind } from "@glide/location-common";
import { type LoadedRow, isLoadingValue } from "@glide/computation-model-types";
import { asString } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ActionDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
    makeColumnProperty,
    makeEnumProperty,
} from "@glide/app-description";
import { type TableColumn, isLinkTypeKind } from "@glide/type-schema";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    EnumPropertyHandler,
    webViewScreenName,
    PropertySection,
    makeInlineTemplatePropertyDescriptor,
    type ActionAvailability,
} from "@glide/function-utils";
import { isUrl, nullToUndefined, safeURL } from "@glide/support";
import {
    type WireActionRunner,
    type WireActionResult,
    type WireActionResultBuilder,
    PageScreenTarget,
    type WireActionHydrator,
    type WireActionInflationBackend,
} from "@glide/wire";
import { assertNever, definedMap } from "@glideapps/ts-necessities";
import { LinkAppearance } from "../builder-utils";
import { getTitleAndCaptionForAppearance, linkAppearances } from "../components/link";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

export interface OpenWebViewActionDescription extends ActionDescription {
    // This property name is also hardcoded in ##convertLegacyActions
    readonly link: PropertyDescription | undefined;
    readonly appearance: PropertyDescription | undefined;
}

export const webViewAppearancePropertyHandler = new EnumPropertyHandler(
    { appearance: LinkAppearance.PageTitle },
    "Title",
    "Show as",
    linkAppearances,
    PropertySection.Design,
    "dropdown"
);

enum Target {
    Browser = "browser",
    WebView = "web-view",
}

interface OpenLinkActionDescription extends OpenWebViewActionDescription {
    readonly target: PropertyDescription | undefined;
}

export function makeLinkColumnPropertyDescriptor(
    mutatingScreenKind: MutatingScreenKind | undefined
): PropertyDescriptor {
    return makeInlineTemplatePropertyDescriptor("link", "URL", "Enter URL", true, "withLabel", mutatingScreenKind, {
        preferredNames: ["link", "url"],
        preferredType: "uri",
        searchable: false,
        applyFormat: false,
        columnFirst: true,
    });
}

const targetPropertyHandler = new EnumPropertyHandler(
    { target: Target.Browser },
    "Target",
    "Target",
    [
        {
            label: "Browser",
            value: Target.Browser,
        },
        {
            label: "Web View",
            value: Target.WebView,
        },
    ],
    PropertySection.Options,
    "dropdown"
);

function getTarget(desc: OpenLinkActionDescription | undefined): Target {
    return definedMap(desc, d => targetPropertyHandler.getEnum(d)) ?? targetPropertyHandler.defaultCaseValue;
}

export function hydrateOpenWebView(
    arb: WireActionResultBuilder,
    url: string,
    appearance: LinkAppearance
): WireActionRunner | WireActionResult {
    if (!isUrl(url)) return arb.error(true, "Invalid URL");

    let title: string | undefined;
    async function fetchTitle() {
        title = await fetchPageTitle(url);
    }
    void fetchTitle();

    const row: LoadedRow = {
        $rowID: makeRowID(),
        $isVisible: false,
        link: url,
    };

    return async ab => {
        const [screenTitle] = getTitleAndCaptionForAppearance(appearance, url, title, undefined);

        ab.addSpecialScreenRow(row);
        ab.pushFreeScreen(webViewScreenName, [row], screenTitle, PageScreenTarget.LargeModal, undefined);
        return arb.addData({ screenTitle }).success();
    };
}

export class OpenLinkWithArgsHandler extends BaseActionHandler<OpenLinkActionDescription> {
    public readonly kind = ActionKind.OpenLinkWithArgs;

    public readonly iconName: GlideIconProps = {
        icon: "st-open-link",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Open link";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        desc: OpenLinkActionDescription | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const properties: PropertyDescriptor[] = [makeLinkColumnPropertyDescriptor(mutatingScreenKind)];
        if (ccc.appKind !== AppKind.Page) {
            properties.push(targetPropertyHandler);
            if (getTarget(desc) === Target.WebView) {
                properties.push(webViewAppearancePropertyHandler);
            }
        }

        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 10,
            needsScreenContext: true,
            properties,
        };
    }

    public newDefaultActionDescription(c: TableColumn): OpenLinkActionDescription | undefined {
        if (!isLinkTypeKind(c.type.kind)) return undefined;
        return {
            kind: ActionKind.OpenLinkWithArgs,
            link: makeColumnProperty(c.name),
            target: makeEnumProperty(Target.Browser),
            appearance: undefined,
        };
    }

    public getTokenizedDescription(
        desc: OpenLinkActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const token = tokenForProperty(desc.link, env);
        if (token === undefined) return undefined;
        return [token];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: OpenLinkActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const [linkGetter, linkType] = ib.getValueGetterForProperty(desc.link, false);
        if (linkType === undefined) return arbBase.inflationError("Invalid link");

        const appearance = webViewAppearancePropertyHandler.getEnum(desc);

        const target = getTarget(desc);

        return (vp, skipLoading) => {
            const linkValue = nullToUndefined(linkGetter(vp));
            if (isLoadingValue(linkValue)) return arbBase.maybeSkipLoading(skipLoading, "URL");
            const linkValueString = asString(linkValue).trim();
            const link = safeURL(linkValueString);

            const arb = arbBase.addData({ link: link ?? linkValueString, target, appearance });
            if (link === undefined) return arb.error(true, "Invalid URL");

            if (target === Target.Browser) {
                return [
                    async (ab, handledByFrontend) => {
                        if (!handledByFrontend) {
                            ab.actionCallbacks.openLink(link);
                        }
                        return arb.success();
                    },
                    link,
                ];
            } else if (target === Target.WebView) {
                return hydrateOpenWebView(arb, link, appearance);
            } else {
                return assertNever(target);
            }
        };
    }
}
