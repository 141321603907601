import { AppIcon, GlideIcon, useMediaQuery } from "@glide/common";
import { isPlayer, isTemplatePlayer } from "@glide/common-core/dist/js/routes";
import { TextComponentStyle } from "@glide/component-utils";
import classNames from "classnames";
import { type PanInfo, AnimatePresence, m } from "framer-motion";
import { useCallback, useEffect } from "react";
import { css } from "styled-components";
import tw from "twin.macro";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";

import { MobileSignUpOrUserProfile } from "../../components/mobile-user-profile/mobile-user-profile";
import { Text } from "../../components/text/text";
import { type UnifiedNavBarProps, getLogoOrIcon, TabIcon, useLogoAndTitleDisplayProps } from "./nav-bar-common";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import type { WireTab } from "@glide/wire";
import type { IconImage } from "@glide/app-description";
import { hasOwnProperty } from "@glideapps/ts-necessities";
import { Img } from "../../components/img/img";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";

type WantedUnifiedNavBarProps = Pick<
    UnifiedNavBarProps,
    | "setShowMenu"
    | "onSelectionChange"
    | "selected"
    | "signedInUserEmail"
    | "signedInUserName"
    | "signedInUserImage"
    | "onSignInPressed"
    | "onSignOutPressed"
    | "onSignUpPressed"
    | "onUserButtonPressed"
>;
interface ChromseSidemenuProps extends WantedUnifiedNavBarProps {
    readonly showMenu: boolean;
    readonly tabs: WireTab[];
    readonly removeBranding: boolean;
    readonly showAddToHomescreenDialog: () => void;
    readonly showInstallApp: boolean;
    readonly logo?: string;
    readonly iconImage?: IconImage;
    readonly title: string;
    readonly navigateToRoot?: () => void;
    readonly appID: string;
}

export const pushedContentCSS = css`
    .sidemenu-open & {
        transform: translateX(286px);
    }
`;

const sidebarCss = css`
    .sidemenu-open & {
        transform: translateX(0);
        width: calc(100% + 286px);
    }

    transform: translateX(-286px);
    width: 286px;
`;

const sideBarContentCSS = css`
    .sidemenu-open & {
        ${tw`all-child:opacity-100`}
    }
    ${tw`all-child:opacity-0`};
`;

export const ChromeMobileSidemenu: React.VFC<ChromseSidemenuProps> = p => {
    const {
        showMenu,
        setShowMenu,
        tabs,
        onSelectionChange,
        selected,
        onSignInPressed,
        onSignOutPressed,
        onSignUpPressed,
        onUserButtonPressed,
        signedInUserEmail,
        signedInUserImage,
        signedInUserName,
        removeBranding,
        showAddToHomescreenDialog,
        showInstallApp,
        logo,
        iconImage,
        title,
        navigateToRoot,
        appID,
    } = p;

    const isPortrait = useMediaQuery("(orientation:portrait)");

    const onPan = useCallback(
        (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
            if (showMenu && info.offset.x < -40) {
                setShowMenu(false);
            }
        },
        [setShowMenu, showMenu]
    );

    const onClose = useCallback(() => {
        setShowMenu(false);
    }, [setShowMenu]);

    useEffect(() => {
        // If we're showing the menu and orientation changes we need to close it.
        if (!isPortrait && showMenu && isPlayer() && !isTemplatePlayer()) {
            onClose();
        }
    }, [isPortrait, onClose, showMenu]);

    const theme = useWireAppTheme();

    const pageThemeClass = (theme.pageTheme ?? "accent").toLowerCase();
    const { showLogo, showIcon, showTitle } = useLogoAndTitleDisplayProps(logo, theme, iconImage);

    const logoTitle = (
        <>
            {showIcon ? (
                hasOwnProperty(iconImage, "url") ? (
                    <div tw="overflow-hidden relative w-8 h-8 rounded-lg shrink-0 bg-accent not-last:mr-3">
                        <Img
                            src={massageImageUrl(
                                getLogoOrIcon(showLogo, showIcon, logo, iconImage),
                                {
                                    height: 75,
                                    thumbnail: false,
                                },
                                appID
                            )}
                            tw="object-cover w-full h-full"
                        />
                    </div>
                ) : (
                    <Img
                        src={massageImageUrl(
                            getLogoOrIcon(showLogo, showIcon, logo, iconImage),
                            {
                                height: 75,
                                thumbnail: false,
                            },
                            appID
                        )}
                        tw="max-h-6 [max-width:188px] object-contain not-last:mr-3"
                    />
                )
            ) : null}

            {showLogo ? (
                <Img
                    src={massageImageUrl(
                        getLogoOrIcon(showLogo, showIcon, logo, iconImage),
                        {
                            height: 75,
                            thumbnail: false,
                        },
                        appID
                    )}
                    tw="max-h-6 [max-width:188px] not-last:mr-2 object-contain"
                />
            ) : null}
            {showTitle && <span tw="h-auto leading-tight text-left gp-md:text-lg line-clamp-2">{title}</span>}
        </>
    );

    return (
        <m.div
            onPan={onPan}
            css={sidebarCss}
            tw="absolute flex page-md:hidden z-sidemenu transition-transform overflow-y-hidden h-full duration-200
                touch-action[none]">
            <nav
                tw="[min-width:286px] px-2 flex flex-col flex-1 h-full border-r border-b border-border-base bg-n0
                    all-child:transition-opacity"
                aria-label="Side Navigation"
                css={css`
                    ${sideBarContentCSS}
                    padding-top: var(--safe-area-inset-top);
                `}>
                <h1
                    onClick={navigateToRoot}
                    className={classNames(navigateToRoot !== undefined ? "active-title" : "", {
                        "no-logo-and-title": theme.logoDisplay === "none",
                        "icon-as-image": showIcon && hasOwnProperty(iconImage, "url"),
                    })}
                    css={css`
                        &.active-title {
                            ${tw`cursor-pointer`}
                        }
                        &.icon-as-image {
                            ${tw`pl-2`}
                        }
                    `}
                    style={{
                        height: "auto",
                    }}
                    tw="flex relative justify-between items-center px-4 mt-3 h-8 text-lg font-semibold text-center text-text-contextual-dark">
                    <span tw={"flex relative justify-between items-center"}>{logoTitle}</span>
                    <button
                        type="button"
                        css={css`
                            .dark & {
                                ${tw`text-text-contextual-dark`}
                            }
                        `}
                        tw="text-text-contextual-pale ml-3.5 hover:text-text-contextual-base transition-colors"
                        onClick={onClose}>
                        <GlideIcon kind="stroke" icon="st-close" iconSize={22} />
                        <span tw="sr-only">Close</span>
                    </button>
                </h1>

                <ul tw="overflow-y-auto mt-3 h-full">
                    {tabs.map((tab, index) => {
                        return (
                            <li
                                key={index}
                                tw="flex relative mb-2 h-11"
                                className={classNames(selected === tab && "selected", pageThemeClass)}
                                css={css`
                                    &.selected .tab-name-and-icon {
                                        ${tw`font-medium opacity-100 text-text-contextual-accent`}
                                    }
                                `}>
                                <div
                                    tw="absolute inset-0 rounded-lg"
                                    css={css`
                                        .selected & {
                                            ${tw`font-medium bg-text-contextual-accent opacity-10`}
                                        }
                                    `}
                                />
                                <button
                                    tw="relative flex-1 rounded-lg border-transparent transition duration-200
                                        cursor-pointer whitespace-nowrap bg-n0A focus-visible:(ring-2 ring-offset-1
                                        ring-n0A) text-left text-text-contextual-base text-sm active:bg-bg-hovered"
                                    onClick={() => {
                                        onSelectionChange(tab);
                                        setShowMenu(false);
                                    }}>
                                    <div
                                        tw="flex justify-start items-center font-weight[inherit] mx-4 my-2.5"
                                        className="tab-name-and-icon">
                                        {theme.showIconsInNavBar === true && <TabIcon icon={tab.icon} tw="mr-3" />}
                                        <span tw="text-base">{tab.title}</span>
                                    </div>
                                </button>
                            </li>
                        );
                    })}
                </ul>
                {showInstallApp && <InstallAppButton showAddToHomescreenDialog={showAddToHomescreenDialog} />}
                <MobileSignUpOrUserProfile
                    onSignInPressed={onSignInPressed}
                    onSignUpPressed={onSignUpPressed}
                    onUserButtonPressed={onUserButtonPressed}
                    signedInUserEmail={signedInUserEmail}
                    signedInUserImage={signedInUserImage}
                    signedInUserName={signedInUserName}
                    onSignOutPressed={onSignOutPressed}
                    onClose={onClose}
                    removeBranding={removeBranding}
                />
            </nav>
            <AnimatePresence>
                {showMenu && (
                    <m.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={onClose}
                        css={css`
                            background: linear-gradient(
                                180deg,
                                rgba(0, 0, 0, 0) 0%,
                                rgba(0, 0, 0, 0.05) 18%,
                                rgba(0, 0, 0, 0.05) 100%
                            );
                        `}
                        tw="w-full"
                    />
                )}
            </AnimatePresence>
        </m.div>
    );
};

interface InstallAppButtonProps extends Pick<ChromseSidemenuProps, "showAddToHomescreenDialog"> {}

const InstallAppButton: React.VFC<InstallAppButtonProps> = ({ showAddToHomescreenDialog }) => {
    return (
        <button
            onClick={showAddToHomescreenDialog}
            tw="flex gap-x-3 flex-1 rounded-lg text-left px-4 py-2.5 focus-visible:(ring-2 ring-offset-1 ring-n0A) text-text-contextual-base active:bg-bg-hovered">
            <AppIcon icon="install" size={20} />
            <Text variant={TextComponentStyle.regular}>
                {getLocalizedString("mobileSidebarInstallApp", AppKind.Page)}
            </Text>
        </button>
    );
};
