import { isLoadingValue } from "@glide/computation-model-types";
import { asString } from "@glide/common-core/dist/js/computation-model/data";
import { type ActionDescription, type PropertyDescription, ActionKind } from "@glide/app-description";
import { type AppDescriptionContext, makeTextPropertyDescriptor, type ActionAvailability } from "@glide/function-utils";
import { MapLocationKind, nullToUndefined } from "@glide/support";
import type {
    WireActionHydrator,
    WireActionInflationBackend,
    WireActionResult,
    WireActionResultBuilder,
} from "@glide/wire";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

interface OpenMapActionDescription extends ActionDescription {
    // This property name is also hardcoded in ##convertLegacyActions
    readonly address?: PropertyDescription;
}

export class OpenMapWithArgsHandler extends BaseActionHandler<OpenMapActionDescription> {
    public readonly kind = ActionKind.OpenMapWithArgs;

    public readonly iconName: GlideIconProps = {
        icon: "st-open-address",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Open address on map";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: OpenMapActionDescription | undefined,
        { mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 11,
            needsScreenContext: true,
            properties: [
                makeTextPropertyDescriptor("address", "Address", "Enter address", true, mutatingScreenKind, {
                    preferredNames: ["address", "location"],
                    preferredType: "string",
                    searchable: false,
                    columnFirst: true,
                    applyFormat: false,
                }),
            ],
        };
    }

    public getTokenizedDescription(
        desc: OpenMapActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const columnToken = tokenForProperty(desc.address, env);
        if (columnToken === undefined) return undefined;

        return [columnToken];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: OpenMapActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const { appFacilities } = ib;

        const [addressGetter, addressType] = ib.getValueGetterForProperty(desc.address, false);
        if (addressType === undefined) return arbBase.inflationError("Invalid address");

        return (vp, skipLoading) => {
            const addressValue = nullToUndefined(addressGetter(vp));
            if (isLoadingValue(addressValue)) return arbBase.maybeSkipLoading(skipLoading, "Address");
            const address = asString(addressValue).trim();
            if (address === "") return arbBase.error(true, "No address");
            let arb = arbBase.addData({ address });

            const url = appFacilities.mapURL({ kind: MapLocationKind.Address, address });
            if (url === undefined) return arb.error(true, "Cannot get URL for address");
            arb = arb.addData({ url });

            return [
                async (ab, handledByFrontend) => {
                    if (!handledByFrontend) {
                        ab.actionCallbacks.openLink(url);
                    }
                    return arb.success();
                },
                url,
            ];
        };
    }
}
