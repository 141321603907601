import { appIsInstalled, browserOnPhone } from "@glide/common";
import { getLocalizedString } from "@glide/localization";

import type { BaseProps } from "./custom-sign-in-props";
import { Button, ButtonWrapper, ContentContainer, Input, Subtitle, Title } from "./custom-sign-in-style";
import { signInNormalTextColors } from "./custom-sign-in-styles";

interface Props extends BaseProps {}

const NameInputImpl: React.FC<React.PropsWithChildren<Props>> = p => {
    const { greeting, description, colorTheme, onPressedContinue, canContinue, username, onUsernameChange, appKind } =
        p;

    return (
        <>
            <ContentContainer>
                <div>
                    <Title data-test="app-sign-in-title" css={signInNormalTextColors}>
                        {greeting}
                    </Title>
                    <Subtitle id="sign-in-description" data-test="app-sign-in-subtitle">
                        {description}
                    </Subtitle>
                    <Input
                        data-test="app-name-input"
                        value={username}
                        onChange={e => onUsernameChange(e.target.value)}
                        type="text"
                        inputMode="text"
                        placeholder={getLocalizedString("name", appKind)}
                        colorTheme={colorTheme}
                        autoFocus={!browserOnPhone || appIsInstalled()}
                    />
                </div>
            </ContentContainer>
            <ButtonWrapper colorTheme={colorTheme} showFooter={false}>
                <Button onClick={onPressedContinue} disabled={canContinue === false}>
                    {getLocalizedString("continue", appKind)}
                </Button>
            </ButtonWrapper>
        </>
    );
};

const NameInput = NameInputImpl;
export default NameInput;
