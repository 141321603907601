import { definedMap, hasOwnProperty } from "collection-utils";
import type { AppLoginAuthData, AppLoginAuthDataFromPlay } from "@glide/common-core/dist/js/Database";
import { logInfo , checkString , localStorageGetItem, localStorageSetItem, makeAppLoginKey } from "@glide/support";



export function saveAppLogin(appLogin: AppLoginAuthData, appID: string, timestamp: number): void {
    logInfo("Saving app login");
    const full: AppLoginAuthDataFromPlay = { ...appLogin, appID, timestamp };
    localStorageSetItem(makeAppLoginKey(appID), JSON.stringify(full));
}

export function getLastKnownAppLogin(): AppLoginAuthDataFromPlay | undefined {
    if (!hasOwnProperty(window, "appLogin")) {
        logInfo("No app login from play function");
        return undefined;
    }
    const fromWindow = window.appLogin as AppLoginAuthDataFromPlay;

    const appID = checkString(fromWindow.appID);

    const fromLocalStorage: AppLoginAuthDataFromPlay | undefined = definedMap(
        localStorageGetItem(makeAppLoginKey(appID)),
        s => JSON.parse(s)
    );
    if (fromLocalStorage === undefined) {
        logInfo("No app login in local storage, using from play");
        return fromWindow;
    }

    if (fromWindow.timestamp > fromLocalStorage.timestamp) {
        logInfo("App login from play is newer than from local storage");
        saveAppLogin(fromWindow, appID, fromWindow.timestamp);
        return fromWindow;
    } else {
        logInfo("App login from local storage is newer than from play");
        return fromLocalStorage;
    }
}
