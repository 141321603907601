import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div<{ contextual: boolean }>`
    color: ${p => (p.contextual ? p.theme.textContextualDark : p.theme.textDark)};
    hyphens: auto;
    word-break: break-word;
    white-space: normal;

    && * {
        font-family: Inter, -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
    }

    && {
        h1,
        h2,
        h3,
        h4,
        p {
            &:first-child {
                margin-top: unset;
            }

            &:last-child {
                margin-bottom: unset;
            }
        }

        p,
        li,
        small,
        strong {
            ${tw`text-base leading-normal`}
            color: ${p => (p.contextual ? p.theme.textContextualBase : p.theme.textBase)};
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            color: ${p => (p.contextual ? p.theme.textContextualDark : p.theme.textDark)};
        }

        h1 {
            ${tw`mt-10 mb-5 text-3xl page-lg:text-4xl font-bold [line-height:1.1] [letter-spacing:-0.02em]`}
        }

        h2 {
            ${tw`mt-8 mb-4 text-2xl page-lg:text-3xl font-bold [line-height:1.1] [letter-spacing:-0.02em]`}
        }

        h3 {
            ${tw`mt-8 mb-4 text-xl page-lg:text-2xl font-bold [line-height:1.2] [letter-spacing:-0.01em]`}
        }

        h4 {
            ${tw`mt-6 mb-2 text-lg page-lg:text-xl font-bold [line-height:1.2]`}
        }

        h5 {
            ${tw`mt-6 text-base page-sm:text-lg font-bold [line-height:1.33]`}
        }

        h6 {
            ${tw`mt-6 text-[14px] font-normal leading-snug text-n500`}
        }

        p {
            ${tw`my-3 leading-snug`}
            white-space: pre-wrap;
        }

        li > p {
            ${tw`mt-0`}
        }

        p > img {
            ${tw`my-8`}
        }

        ol,
        ul {
            ${tw`my-4 pl-6 space-y-1 list-disc`}
        }
        ol {
            ${tw`list-decimal`}
        }
        li p {
            ${tw`m-0`}
        }

        a {
            ${tw`font-medium cursor-pointer`}
            color: ${p => (p.contextual ? p.theme.textContextualAccent : p.theme.textAccent)};
        }

        blockquote {
            ${tw`p-3 mt-4 mb-4 border-l-4 border-l-n300`}
        }

        hr {
            ${tw`border-t-2 border-dotted border-t-n300 mx-auto w-full`}
        }

        mark {
            background-color: #f0ff92;
        }

        strong {
            font-size: inherit;
        }
    }

    .ProseMirror {
        min-height: 100px;
        max-height: 100%;
    }
`;
