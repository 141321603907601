import type {
    BinaryPredicateOperator,
    SortArrayTransform,
    TableOrderArrayTransform,
    UnaryPredicateOperator,
} from "@glide/app-description";
import { ArrayTransformKind, SortOrder, UnaryPredicateCompositeOperator } from "@glide/app-description";
import {
    SourceColumnKind,
    areSourceColumnsEqual,
    getSourceColumnPath,
    getSourceColumnSinglePath,
    isSourceColumn,
    makeSourceColumn,
    makeActionNodeOutputSourceColumn,
    decomposeActionNodeOutputSourceColumn,
    isActionNodeOutputSourceColumn,
    BinaryPredicateCompositeOperator,
    BinaryPredicateFormulaOperator,
    UnaryPredicateFormulaOperator,
    FormulaKind,
    SpecialValueKind,
    getTableColumn,
    isDateTimeTypeKind,
    isNumberTypeKind,
    isPrimitiveType,
    BinaryMathFunction,
    GeneratedImageKind,
    ReduceOperator,
    ReduceToMemberByOperator,
    isComputedColumn,
    isUniversalTableName,
} from "@glide/type-schema";
import { milesPerKM, isDefined, objectKeyForValue, parseNumberDiligently } from "@glide/support";
import { RollupKind } from "@glide/computation-model-types";
import type {
    Description,
    ColumnType,
    ColumnTypeKind,
    Formula,
    PrimitiveGlideTypeKind,
    UniversalTableName,
    AndOrFormula,
    ApplyColumnFormatFormula,
    ArrayContainsFormula,
    ArraysOverlapFormula,
    AssignVariablesFormula,
    BinaryMathFormula,
    CheckValueFormula,
    CompareValuesFormula,
    ConstantFormula,
    ConstructURLFormula,
    ConvertToTypeFormula,
    FilterRowsFormula,
    FilterSortLimitFormula,
    FindRowFormula,
    FormatDateTimeFormula,
    FormatDurationFormula,
    FormatJSONFormula,
    FormatNumberFixedFormula,
    GenerateImageFormula,
    GeoDistanceFormula,
    GeocodeAddressFormula,
    GetColumnFormula,
    GetContextFormula,
    GetNthFormula,
    GetTableRowsFormula,
    GetUserProfileRowFormula,
    GetActionNodeOutputFormula,
    GetVariableFormula,
    IfThenElseFormula,
    IsInRangeFormula,
    JoinStringsFormula,
    MakeArrayFormula,
    MapRowsFormula,
    NotFormula,
    Ordering,
    PluginComputationFormula,
    QueryParametersFormulas,
    RandomPickFormula,
    ReduceFormula,
    ReduceToMemberByFormula,
    SpecialValueFormula,
    SplitStringFormula,
    StartOrEndOfDayFormula,
    TextTemplateFormula,
    UserAPIFetchFormula,
    WithFormula,
    YesCodeFormula,
    ActionNodeOutputSourceColumn,
    SourceColumn,
    SchemaInspector,
    TableOrColumns,
    SpecialValueDescription,
} from "@glide/type-schema";
import type { BasePrimitiveValue, GlideDateTimeFormatSpecification, GlideDateTimeZone } from "@glide/data-types";
import { DateFormat, DateTimeParts, TimeFormat } from "@glide/data-types";
import { assert, assertNever, defined, definedMap, panic } from "@glideapps/ts-necessities";
import deepEqual from "deep-equal";
import toPairs from "lodash/toPairs";
import { parseMath, unparseMath } from "./math";
import { isFormulaDepthOK } from "./formula-depth";

export enum DataColumnKind {
    Regular = "regular",
    UserSpecific = "user-specific",
    RowID = "row-id",
}

export enum SyntheticColumnKind {
    FilterReference = "filter-reference",
    IfThenElse = "if-then-else",
    Lookup = "lookup",
    SingleValue = "single-value",
    TextTemplate = "text-template",
    Math = "math",
    Rollup = "rollup",
    JoinStrings = "join-strings",
    SplitString = "split-string",
    MakeArray = "make-array",
    GeoDistance = "geo-distance",
    GenerateImage = "generate-image",
    UserAPIFetch = "user-api-fetch",
    ConstructURL = "construct-url",
    YesCode = "yes-code",
    PluginComputation = "plugin-computation",
    FilterSortLimit = "filter-sort-limit",
}

export type EditedColumnKind = SyntheticColumnKind | DataColumnKind;

export enum ValueFormatKind {
    Number = "number",
    DateTime = "date-time",
    Duration = "duration",
    JSON = "json",
}

export interface FilterReferenceSpecification {
    readonly kind: SyntheticColumnKind.FilterReference;
    readonly hostColumn: string;
    readonly targetTable: UniversalTableName;
    readonly targetColumn: string;
    readonly multiple: boolean;
    readonly omitSort?: true;
}

export function makeFilterReferenceFormulaUnsafe(
    { hostColumn, targetTable, targetColumn, multiple, omitSort }: FilterReferenceSpecification,
    hostIsPrimitive: boolean,
    targetIsPrimitive: boolean
): Formula {
    let predicate: Formula;
    const predicateContextName = "matchee";
    const hostFormula: GetColumnFormula = {
        kind: FormulaKind.GetColumn,
        column: hostColumn,
    };
    const targetFormula: GetColumnFormula = {
        kind: FormulaKind.GetColumn,
        column: targetColumn,
        contextName: predicateContextName,
    };
    if (hostIsPrimitive && targetIsPrimitive) {
        predicate = {
            kind: FormulaKind.CompareValues,
            left: hostFormula,
            operator: BinaryPredicateFormulaOperator.Equals,
            right: targetFormula,
        } as CompareValuesFormula;
    } else if (hostIsPrimitive || targetIsPrimitive) {
        predicate = {
            kind: FormulaKind.ArrayContains,
            array: hostIsPrimitive ? targetFormula : hostFormula,
            item: hostIsPrimitive ? hostFormula : targetFormula,
        } as ArrayContainsFormula;
    } else {
        predicate = {
            kind: FormulaKind.ArraysOverlap,
            left: hostFormula,
            right: targetFormula,
        } as ArraysOverlapFormula;
    }

    const rows: GetTableRowsFormula = {
        kind: FormulaKind.GetTableRows,
        table: targetTable,
    };
    if (multiple) {
        return { kind: FormulaKind.FilterRows, rows, predicateContextName, predicate, omitSort } as FilterRowsFormula;
    } else {
        return { kind: FormulaKind.FindRow, rows, predicateContextName, predicate, omitSort } as FindRowFormula;
    }
}

export function makeFilterReferenceFormula(
    schema: SchemaInspector,
    hostTable: UniversalTableName,
    spec: FilterReferenceSpecification
): Formula | undefined {
    const { hostColumn, targetTable, targetColumn } = spec;
    const host = definedMap(schema.findTable(hostTable), t => getTableColumn(t, hostColumn));
    const target = definedMap(schema.findTable(targetTable), t => getTableColumn(t, targetColumn));
    if (host === undefined || target === undefined) return undefined;
    const hostIsPrimitive = isPrimitiveType(host.type);
    const targetIsPrimitive = isPrimitiveType(target.type);

    return makeFilterReferenceFormulaUnsafe(spec, hostIsPrimitive, targetIsPrimitive);
}

export type SyntheticColumnSpecification =
    | FilterReferenceSpecification
    | IfThenElseSpecification
    | LookupSpecification
    | SingleValueSpecification
    | TextTemplateSpecification
    | MathSpecification
    | RollupSpecification
    | JoinStringsSpecification
    | SplitStringSpecification
    | MakeArraySpecification
    | GenerateImageSpecification
    | GeoDistanceSpecification
    | UserAPIFetchSpecification
    | ConstructURLSpecification
    | YesCodeSpecification
    | PluginComputationSpecification
    | FilterSortLimitSpecification;

export function decomposeAll(formula: Formula): SyntheticColumnSpecification | undefined {
    const shrooms = [
        decomposeFilterReferenceFormula,
        decomposeIfThenElseFormula,
        decomposeLookupFormula,
        decomposeRepeatSingleValueFormula,
        decomposeTextTemplateFormula,
        decomposeMathFormula,
        decomposeRollupFormula,
        decomposeJoinStringsFormula,
        decomposeSplitStringFormula,
        decomposeMakeArrayFormula,
        decomposeGenerateImageFormula,
        decomposeGeoDistanceFormula,
        decomposeUserAPIFetchFormula,
        decomposeConstructURLFormula,
        decomposeYesCodeFormula,
        decomposePluginComputationFormula,
        decomposeFilterSortLimitFormula,
    ];

    const ds = shrooms.find(s => s(formula) !== undefined);
    if (ds !== undefined) return ds(formula);
    return undefined;
}

export function makeFormulaForSpecification(
    schema: SchemaInspector,
    hostTableName: UniversalTableName | undefined,
    spec: SyntheticColumnSpecification
): Formula | undefined {
    switch (spec.kind) {
        case SyntheticColumnKind.FilterReference:
            if (hostTableName === undefined) return undefined;
            return makeFilterReferenceFormula(schema, hostTableName, spec);
        case SyntheticColumnKind.IfThenElse:
            return makeIfThenElseFormula(spec);
        case SyntheticColumnKind.Lookup:
            return makeLookupFormula(spec);
        case SyntheticColumnKind.SingleValue:
            return makeSingleValueFormula(spec);
        case SyntheticColumnKind.TextTemplate:
            return makeTextTemplateFormula(spec);
        case SyntheticColumnKind.Math:
            return makeMathFormula(spec);
        case SyntheticColumnKind.Rollup:
            return makeRollupFormula(spec);
        case SyntheticColumnKind.JoinStrings:
            return makeJoinStringsFormula(spec);
        case SyntheticColumnKind.SplitString:
            return makeSplitStringFormula(spec);
        case SyntheticColumnKind.MakeArray:
            return makeMakeArrayFormula(spec);
        case SyntheticColumnKind.GeoDistance:
            return makeGeoDistanceFormula(spec);
        case SyntheticColumnKind.GenerateImage:
            return makeGenerateImageFormula(spec);
        case SyntheticColumnKind.UserAPIFetch:
            return makeUserAPIFetchFormula(spec);
        case SyntheticColumnKind.ConstructURL:
            return makeConstructURLFormula(spec);
        case SyntheticColumnKind.YesCode:
            return makeYesCodeFormula(spec);
        case SyntheticColumnKind.PluginComputation:
            return makePluginComputationFormula(spec);
        case SyntheticColumnKind.FilterSortLimit:
            return makeFilterSortLimitFormula(spec);
        default:
            return assertNever(spec);
    }
}

export function decomposeFilterReferenceFormula(formula: Formula): FilterReferenceSpecification | undefined {
    if (formula.kind !== FormulaKind.FilterRows && formula.kind !== FormulaKind.FindRow) return undefined;

    const { rows, predicate, predicateContextName, omitSort } = formula as FilterRowsFormula | FindRowFormula;

    if (rows.kind !== FormulaKind.GetTableRows) return undefined;
    const r = rows as GetTableRowsFormula;

    let hostFormula: Formula;
    let targetFormula: Formula;
    if (
        predicate.kind === FormulaKind.CompareValues &&
        (predicate as CompareValuesFormula).operator === BinaryPredicateFormulaOperator.Equals
    ) {
        const p = predicate as CompareValuesFormula;
        hostFormula = p.left;
        targetFormula = p.right;
    } else if (predicate.kind === FormulaKind.ArrayContains) {
        const { array, item } = predicate as ArrayContainsFormula;
        if (array.kind !== FormulaKind.GetColumn || item.kind !== FormulaKind.GetColumn) return undefined;
        const a = array as GetColumnFormula;
        if (a.contextName === undefined) {
            hostFormula = a;
            targetFormula = item;
        } else {
            hostFormula = item;
            targetFormula = array;
        }
    } else if (predicate.kind === FormulaKind.ArraysOverlap) {
        const p = predicate as ArraysOverlapFormula;
        hostFormula = p.left;
        targetFormula = p.right;
    } else {
        return undefined;
    }

    if (hostFormula.kind === FormulaKind.GetColumn && targetFormula.kind === FormulaKind.GetColumn) {
        const h = hostFormula as GetColumnFormula;
        const t = targetFormula as GetColumnFormula;
        if (h.contextName === undefined && t.contextName === predicateContextName) {
            return {
                kind: SyntheticColumnKind.FilterReference,
                hostColumn: h.column,
                targetTable: r.table,
                targetColumn: t.column,
                multiple: formula.kind === FormulaKind.FilterRows,
                omitSort,
            };
        }
    }

    return undefined;
}

export enum ColumnOrValueKind {
    Column,
    Constant,
    SpecialValue,
    Empty,
}

export interface ColumnSpecification {
    readonly kind: ColumnOrValueKind.Column;
    readonly column: SourceColumn;
}

export interface ValueSpecification<T> {
    readonly kind: ColumnOrValueKind.Constant;
    readonly value: T;
}

export interface SpecialValueSpecification {
    readonly kind: ColumnOrValueKind.SpecialValue;
    readonly specialValue: SpecialValueDescription;
}

interface EmptyValueSpecification {
    readonly kind: ColumnOrValueKind.Empty;
}

export type ColumnOrValueSpecification<T extends BasePrimitiveValue> =
    | ColumnSpecification
    | ValueSpecification<T>
    | SpecialValueSpecification
    | EmptyValueSpecification;

export type QueryParametersSpecification = readonly [string, ColumnOrValueSpecification<BasePrimitiveValue>][];

function makeQueryParametersFormulas(params: QueryParametersSpecification): QueryParametersFormulas {
    return params
        .filter(r => r[1] !== undefined)
        .map(([p, r]) => ({
            name: makeConstant(p),
            value: makeColumnOrValue(r, false, true, false),
        }));
}

function decomposeQueryParametersFormulas(
    paramsFormulas: QueryParametersFormulas,
    allowSpecialValues: boolean,
    allowOnlyStrings: boolean
): QueryParametersSpecification | undefined {
    const params: [string, ColumnOrValueSpecification<string>][] = [];
    for (const { name, value } of paramsFormulas) {
        if (name.kind !== FormulaKind.Constant) return undefined;
        const n = name as ConstantFormula;
        if (typeof n.value !== "string") return undefined;

        const columnOrValue = decomposeColumnOrValue(value);
        if (columnOrValue === undefined || !isColumnOrPrimitive(columnOrValue, allowSpecialValues, allowOnlyStrings)) {
            return undefined;
        }

        params.push([n.value, columnOrValue]);
    }
    return params;
}

interface SpecificationWithQueryParameters {
    readonly params: QueryParametersSpecification;
}

export interface TextTemplateSpecification extends SpecificationWithQueryParameters {
    readonly kind: SyntheticColumnKind.TextTemplate;
    readonly template: ColumnOrValueSpecification<string>;
}

function applyColumnFormat(value: Formula, column: string, contextName: string | undefined): Formula {
    return { kind: FormulaKind.ApplyColumnFormat, value, column, contextName } as ApplyColumnFormatFormula;
}

function makeSpecialValueFormula(sv: SpecialValueSpecification): Formula {
    return { kind: FormulaKind.SpecialValue, valueKind: sv.specialValue } as SpecialValueFormula;
}

function makeSourceColumnFormula(c: SourceColumn, withFormat: boolean): Formula {
    if (c.kind === SourceColumnKind.ActionNodeOutput) {
        const [actionNodeKey, outputName, columnInRow] = defined(decomposeActionNodeOutputSourceColumn(c));
        const f: GetActionNodeOutputFormula = {
            kind: FormulaKind.GetActionNodeOutput,
            actionNodeKey,
            outputName,
            columnInRow,
            withFormat,
        };
        return f;
    }

    function format<T extends Formula>(contextName: string | undefined, x: T): Formula {
        if (!withFormat) return x;
        return applyColumnFormat(x, columnName, contextName);
    }

    // FIXME: Support paths in formulas
    const path = getSourceColumnPath(c);
    assert(path.length === 1);
    const columnName = path[0];

    if (c.kind === SourceColumnKind.DefaultContext) {
        return format(undefined, {
            kind: FormulaKind.GetColumn,
            column: columnName,
        });
    } else if (c.kind === SourceColumnKind.UserProfile) {
        return {
            kind: FormulaKind.With,
            context: {
                kind: FormulaKind.GetUserProfileRow,
            } as GetUserProfileRowFormula,
            contextName: "user",
            value: format("user", {
                kind: FormulaKind.GetColumn,
                column: columnName,
                contextName: "user",
            }),
        } as WithFormula;
    } else if (c.kind === SourceColumnKind.ContainingScreen) {
        assert(!withFormat);

        return {
            kind: FormulaKind.GetColumn,
            column: columnName,
            contextName: containingScreenContextName,
        } as GetColumnFormula;
    } else {
        return assertNever(c.kind);
    }
}

function decomposeSourceColumnFormula(formula: Formula): { column: SourceColumn; withFormat: boolean } | undefined {
    if (formula.kind === FormulaKind.ApplyColumnFormat) {
        const f = formula as ApplyColumnFormatFormula;
        const result = decomposeSourceColumnFormula(f.value);
        if (result === undefined) return undefined;
        if (
            result.column.kind === SourceColumnKind.DefaultContext &&
            !result.withFormat &&
            f.column === getSourceColumnSinglePath(result.column)
        ) {
            return { column: result.column, withFormat: true };
        }
        return undefined;
    }

    if (formula.kind === FormulaKind.GetColumn) {
        const f = formula as GetColumnFormula;
        if (f.kind === FormulaKind.GetColumn && f.contextName === undefined) {
            return { column: makeSourceColumn(f.column), withFormat: false };
        } else if (f.kind === FormulaKind.GetColumn && f.contextName === containingScreenContextName) {
            return { column: makeSourceColumn(f.column, SourceColumnKind.ContainingScreen), withFormat: false };
        }
    } else if (formula.kind === FormulaKind.With) {
        const w = formula as WithFormula;
        if (w.context.kind === FormulaKind.GetUserProfileRow) {
            if (w.value.kind === FormulaKind.GetColumn) {
                const v = w.value as GetColumnFormula;
                if (w.contextName === v.contextName) {
                    return { column: { kind: SourceColumnKind.UserProfile, name: v.column }, withFormat: false };
                }
            } else if (w.value.kind === FormulaKind.ApplyColumnFormat) {
                const v = w.value as ApplyColumnFormatFormula;
                if (
                    v.value.kind === FormulaKind.GetColumn &&
                    w.contextName === v.contextName &&
                    w.contextName === (v.value as GetColumnFormula).contextName
                ) {
                    return {
                        column: makeSourceColumn(v.column, SourceColumnKind.UserProfile),
                        withFormat: true,
                    };
                }
            }
        }
    } else if (formula.kind === FormulaKind.GetActionNodeOutput) {
        const f = formula as GetActionNodeOutputFormula;
        return {
            column: makeActionNodeOutputSourceColumn(f.actionNodeKey, f.outputName, f.columnInRow),
            withFormat: f.withFormat,
        };
    }
    return undefined;
}

function decomposeSourceColumnFormulaWithoutFormat<T extends Formula>(formula: T): SourceColumn | undefined {
    const result = decomposeSourceColumnFormula(formula);
    if (result?.withFormat !== false) return undefined;
    return result.column;
}

// This is only meant to decompose new plugin special values. It should not work with the old ones.
function decomposePluginSpecialValueFormula(formula: Formula): SpecialValueSpecification | undefined {
    if (formula.kind !== FormulaKind.SpecialValue) return undefined;
    const f = formula as SpecialValueFormula;
    // We intentionally don't decompose SpecialValueKind with this function.
    if (typeof f.valueKind === "string") return undefined;

    return { kind: ColumnOrValueKind.SpecialValue, specialValue: (formula as SpecialValueFormula).valueKind };
}

export function makeConstant(value: BasePrimitiveValue): ConstantFormula {
    return { kind: FormulaKind.Constant, value };
}

function makeColumnOrValue<T extends BasePrimitiveValue>(
    spec: ColumnOrValueSpecification<T>,
    parseNumber: boolean,
    allowEmptyString: boolean,
    withFormat: boolean
): Formula {
    if (spec.kind === ColumnOrValueKind.Column) {
        return makeSourceColumnFormula(spec.column, withFormat);
    } else if (spec.kind === ColumnOrValueKind.Constant) {
        let value: BasePrimitiveValue = spec.value;
        if (parseNumber && typeof value === "string") {
            const num = parseNumberDiligently(value);
            if (num !== undefined) {
                value = num;
            }
        }
        if (value === "" && !allowEmptyString) {
            return { kind: FormulaKind.Empty };
        } else {
            return makeConstant(value);
        }
    } else if (spec.kind === ColumnOrValueKind.SpecialValue) {
        return { kind: FormulaKind.SpecialValue, valueKind: spec.specialValue } as SpecialValueFormula;
    } else if (spec.kind === ColumnOrValueKind.Empty) {
        return { kind: FormulaKind.Empty };
    } else {
        return assertNever(spec);
    }
}

function decomposeColumnOrValue(
    formula: Formula,
    allowFormat: boolean = false
): ColumnOrValueSpecification<BasePrimitiveValue> | undefined {
    if (formula.kind === FormulaKind.Constant) {
        return {
            kind: ColumnOrValueKind.Constant,
            value: (formula as ConstantFormula).value,
        };
    } else if (formula.kind === FormulaKind.Empty) {
        return {
            kind: ColumnOrValueKind.Empty,
        };
    } else if (formula.kind === FormulaKind.SpecialValue) {
        return {
            kind: ColumnOrValueKind.SpecialValue,
            specialValue: (formula as SpecialValueFormula).valueKind,
        };
    }

    const column = decomposeSourceColumnFormula(formula);
    if (column === undefined) return undefined;
    if (!allowFormat && column.withFormat) return undefined;

    return {
        kind: ColumnOrValueKind.Column,
        column: column.column,
    };
}

function isColumnOrPrimitive(
    spec: ColumnOrValueSpecification<any>,
    allowSpecialValues: boolean,
    allowOnlyStrings: boolean
): spec is ColumnOrValueSpecification<string> {
    if (spec.kind === ColumnOrValueKind.Column) return true;
    if (spec.kind === ColumnOrValueKind.SpecialValue) return allowSpecialValues;
    if (spec.kind === ColumnOrValueKind.Empty) return false;
    if (spec.kind !== ColumnOrValueKind.Constant) {
        return assertNever(spec);
    }
    if (allowOnlyStrings) {
        return typeof spec.value === "string";
    } else {
        return true;
    }
}

function isColumnOrStringOrEmpty(
    spec: ColumnOrValueSpecification<any>,
    allowSpecialValues: boolean
): spec is ColumnOrValueSpecification<string> {
    if (spec.kind === ColumnOrValueKind.Empty) return true;
    return isColumnOrPrimitive(spec, allowSpecialValues, true);
}

function isColumnOrStringOrNumberOrEmpty(
    spec: ColumnOrValueSpecification<any>
): spec is ColumnOrValueSpecification<string | number> {
    if (spec.kind === ColumnOrValueKind.Column) return true;
    if (spec.kind === ColumnOrValueKind.Empty) return true;
    if (spec.kind !== ColumnOrValueKind.Constant) return false;
    return typeof spec.value === "string" || typeof spec.value === "number";
}

export function makeTextTemplateFormula({ template, params: replacements }: TextTemplateSpecification): Formula {
    replacements = replacements.filter(r => r[1] !== undefined);
    return {
        kind: FormulaKind.TextTemplate,
        template: makeColumnOrValue(template, false, false, false),
        replacements: replacements.map(([p, r]) => ({
            pattern: makeConstant(p),
            replacement: makeColumnOrValue(r, false, true, true),
        })),
    } as TextTemplateFormula;
}

export function decomposeTextTemplateFormula(formula: Formula): TextTemplateSpecification | undefined {
    if (formula.kind !== FormulaKind.TextTemplate) return undefined;
    const f = formula as TextTemplateFormula;

    const template = decomposeColumnOrValue(f.template);
    if (
        template === undefined ||
        (template.kind !== ColumnOrValueKind.Empty && !isColumnOrPrimitive(template, false, true))
    ) {
        return undefined;
    }

    const replacementSpecs: [string, ColumnOrValueSpecification<string>][] = [];
    for (const { pattern, replacement } of f.replacements) {
        if (pattern.kind !== FormulaKind.Constant) return undefined;
        const p = pattern as ConstantFormula;

        if (typeof p.value !== "string") return undefined;

        const column = decomposeColumnOrValue(replacement, true);
        if (column === undefined || !isColumnOrPrimitive(column, true, true)) return undefined;

        replacementSpecs.push([p.value, column]);
    }

    return { kind: SyntheticColumnKind.TextTemplate, template, params: replacementSpecs };
}

export interface UnaryPredicateSpecification {
    readonly kind: "unary";
    readonly column: SourceColumn | SpecialValueSpecification;
    readonly operator: UnaryPredicateOperator;
}

export enum BinaryPredicateValueKind {
    Literal = "literal",
    Now = "now",
    Today = "today",
}

export type DateTimeValueKind = BinaryPredicateValueKind.Now | BinaryPredicateValueKind.Today;

interface BinaryPredicateLiteralValueSpecification {
    readonly kind: BinaryPredicateValueKind.Literal;
    readonly value: BasePrimitiveValue;
}

interface BinaryPredicateDateTimeValueSpecification {
    readonly kind: DateTimeValueKind;
}

export type BinaryPredicateValueSpecification =
    | SourceColumn
    | BinaryPredicateLiteralValueSpecification
    | BinaryPredicateDateTimeValueSpecification;

export interface BinaryPredicateSpecification {
    readonly kind: "binary";
    readonly column: SourceColumn | SpecialValueSpecification;
    readonly operator: BinaryPredicateOperator;
    readonly value: BinaryPredicateValueSpecification;
}

const unaryPredicateNegations: {
    [op in UnaryPredicateCompositeOperator]?: UnaryPredicateFormulaOperator | undefined;
} = {
    [UnaryPredicateCompositeOperator.IsEmpty]: UnaryPredicateFormulaOperator.IsNotEmpty,
    [UnaryPredicateCompositeOperator.IsFalsey]: UnaryPredicateFormulaOperator.IsTruthy,
};

export function makeUnaryPredicateFormula({ column, operator }: UnaryPredicateSpecification): Formula {
    const columnValueOrSpecialValue = isSourceColumn(column)
        ? makeSourceColumnFormula(column, false)
        : makeSpecialValueFormula(column);

    let formula: Formula;
    let negate: boolean;

    if (
        operator === UnaryPredicateCompositeOperator.MatchesVerifiedEmailAddress ||
        operator === UnaryPredicateCompositeOperator.DoesNotMatchVerifiedEmailAddress
    ) {
        formula = {
            kind: FormulaKind.CompareValues,
            left: {
                kind: FormulaKind.SpecialValue,
                valueKind: SpecialValueKind.VerifiedEmailAddress,
            } as SpecialValueFormula,
            operator: BinaryPredicateFormulaOperator.MatchesEmailAddress,
            right: columnValueOrSpecialValue,
        } as CompareValuesFormula;
        negate = operator === UnaryPredicateCompositeOperator.DoesNotMatchVerifiedEmailAddress;
    } else {
        let op = unaryPredicateNegations[operator as UnaryPredicateCompositeOperator];
        if (op === undefined) {
            op = operator as UnaryPredicateFormulaOperator;
            negate = false;
        } else {
            negate = true;
        }
        formula = {
            kind: FormulaKind.CheckValue,
            operator: op,
            value: columnValueOrSpecialValue,
        } as CheckValueFormula;
    }

    if (negate) {
        formula = { kind: FormulaKind.Not, value: formula } as NotFormula;
    }
    return formula;
}

const binaryPredicateNegations: { [op in BinaryPredicateCompositeOperator]?: BinaryPredicateFormulaOperator } = {
    [BinaryPredicateCompositeOperator.DoesNotEqual]: BinaryPredicateFormulaOperator.Equals,
    [BinaryPredicateCompositeOperator.DoesNotContainString]: BinaryPredicateFormulaOperator.ContainsString,
    [BinaryPredicateCompositeOperator.IsNotContainedInString]: BinaryPredicateFormulaOperator.IsContainedInString,
};

const binaryPredicateDateTimeOperators: {
    [op in BinaryPredicateCompositeOperator]?: {
        op: BinaryPredicateFormulaOperator;
        needsDuration: boolean;
        endOfDuration: boolean;
    };
} = {
    [BinaryPredicateCompositeOperator.IsBefore]: {
        op: BinaryPredicateFormulaOperator.IsLessThan,
        needsDuration: false,
        endOfDuration: false,
    },
    [BinaryPredicateCompositeOperator.IsAfter]: {
        // ##isAfterVsGreaterOrEqualTo:
        // This erroneously used to be `IsGreaterOrEqualTo`, and there are still
        // formulas around that use that.
        op: BinaryPredicateFormulaOperator.IsGreaterThan,
        needsDuration: false,
        endOfDuration: true,
    },
    [BinaryPredicateCompositeOperator.IsOnOrBefore]: {
        op: BinaryPredicateFormulaOperator.IsLessThan,
        needsDuration: true,
        endOfDuration: true,
    },
    [BinaryPredicateCompositeOperator.IsOnOrAfter]: {
        op: BinaryPredicateFormulaOperator.IsGreaterOrEqualTo,
        needsDuration: true,
        endOfDuration: false,
    },
};

function makeConvertToDateTime(value: Formula, endOfDay: boolean | undefined): Formula {
    const f: Formula = {
        kind: FormulaKind.ConvertToType,
        typeKind: "date-time",
        value,
    } as ConvertToTypeFormula;
    if (endOfDay === undefined) {
        return f;
    }
    return {
        kind: endOfDay ? FormulaKind.EndOfDay : FormulaKind.StartOfDay,
        dateTime: f,
    } as StartOrEndOfDayFormula;
}

function makeBinaryPredicateValueFormula(spec: BinaryPredicateValueSpecification, endOfDuration?: boolean): Formula {
    if (isSourceColumn(spec)) {
        const f = makeSourceColumnFormula(spec, false);
        if (endOfDuration === undefined) return f;
        return makeConvertToDateTime(f, endOfDuration);
    }
    switch (spec.kind) {
        case BinaryPredicateValueKind.Literal:
            assert(endOfDuration === undefined);
            return makeConstant(spec.value);
        case BinaryPredicateValueKind.Now:
            return {
                kind: FormulaKind.SpecialValue,
                valueKind: SpecialValueKind.Timestamp,
            } as SpecialValueFormula;
        case BinaryPredicateValueKind.Today:
            return {
                kind: endOfDuration === true ? FormulaKind.EndOfDay : FormulaKind.StartOfDay,
                dateTime: {
                    kind: FormulaKind.SpecialValue,
                    valueKind: SpecialValueKind.Timestamp,
                },
            } as StartOrEndOfDayFormula;
        default:
            return assertNever(spec);
    }
}

export function makeBinaryPredicateFormula({ column, operator, value }: BinaryPredicateSpecification): Formula {
    let left = isSourceColumn(column) ? makeSourceColumnFormula(column, false) : makeSpecialValueFormula(column);
    let right: Formula;

    const negatedOperator = binaryPredicateNegations[operator as BinaryPredicateCompositeOperator];
    const dateTimeOperator = binaryPredicateDateTimeOperators[operator as BinaryPredicateCompositeOperator];
    let negate = false;
    if (dateTimeOperator !== undefined) {
        operator = dateTimeOperator.op;
        left = makeConvertToDateTime(left, undefined);
        if (isSourceColumn(value)) {
            right = makeConvertToDateTime(
                makeSourceColumnFormula(value, false),
                dateTimeOperator.needsDuration ? dateTimeOperator.endOfDuration : undefined
            );
        } else {
            right = makeBinaryPredicateValueFormula(value, dateTimeOperator.endOfDuration);
        }
    } else if (operator === BinaryPredicateCompositeOperator.IsWithin) {
        return {
            kind: FormulaKind.IsInRange,
            value: makeConvertToDateTime(left, undefined),
            start: makeBinaryPredicateValueFormula(value, false),
            end: makeBinaryPredicateValueFormula(value, true),
        } as IsInRangeFormula;
    } else {
        right = makeBinaryPredicateValueFormula(value);
        if (negatedOperator !== undefined) {
            operator = negatedOperator;
            negate = true;
        }
    }

    let formula: Formula = {
        kind: FormulaKind.CompareValues,
        left,
        operator: operator as BinaryPredicateFormulaOperator,
        right,
    } as CompareValuesFormula;
    if (negate) {
        formula = { kind: FormulaKind.Not, value: formula } as NotFormula;
    }

    return formula;
}

function isFormulaNegated(formula: Formula): { value: Formula; isNegated: boolean } {
    if (formula.kind !== FormulaKind.Not) {
        return { value: formula, isNegated: false };
    } else {
        return { value: (formula as NotFormula).value, isNegated: true };
    }
}

export function decomposeUnaryPredicateFormula(formula: Formula): UnaryPredicateSpecification | undefined {
    const { value: f, isNegated } = isFormulaNegated(formula);

    let column: SourceColumn | SpecialValueSpecification | undefined;
    let op: UnaryPredicateOperator | undefined;
    if (f.kind === FormulaKind.CompareValues) {
        const c = f as CompareValuesFormula;
        if (
            c.operator === BinaryPredicateFormulaOperator.MatchesEmailAddress &&
            c.left.kind === FormulaKind.SpecialValue &&
            (c.left as SpecialValueFormula).valueKind === SpecialValueKind.VerifiedEmailAddress
        ) {
            column = decomposeSourceColumnFormulaWithoutFormat(c.right) ?? decomposePluginSpecialValueFormula(c.right);
            op = UnaryPredicateCompositeOperator.MatchesVerifiedEmailAddress;
        }
    } else if (f.kind === FormulaKind.CheckValue) {
        const { operator, value } = f as CheckValueFormula;
        column = decomposeSourceColumnFormulaWithoutFormat(value) ?? decomposePluginSpecialValueFormula(value);
        op = operator;
    }

    if (column === undefined) return undefined;
    assert(op !== undefined);

    if (isNegated) {
        if (op === UnaryPredicateCompositeOperator.MatchesVerifiedEmailAddress) {
            op = UnaryPredicateCompositeOperator.DoesNotMatchVerifiedEmailAddress;
        } else {
            op = defined(objectKeyForValue(unaryPredicateNegations, op));
        }
    }

    return { kind: "unary", column, operator: op };
}

function decomposeBinaryPredicateValueFormula(formula: Formula): BinaryPredicateValueSpecification | undefined {
    const sourceColumn = decomposeSourceColumnFormulaWithoutFormat(formula);
    if (sourceColumn !== undefined) return sourceColumn;

    switch (formula.kind) {
        case FormulaKind.Constant:
            return { kind: BinaryPredicateValueKind.Literal, value: (formula as ConstantFormula).value };
        case FormulaKind.SpecialValue: {
            switch ((formula as SpecialValueFormula).valueKind) {
                case SpecialValueKind.Timestamp:
                    return { kind: BinaryPredicateValueKind.Now };
            }
        }
    }
    return undefined;
}

export type PredicateSpecification = UnaryPredicateSpecification | BinaryPredicateSpecification;

export interface PredicateCombinationSpecification {
    readonly kind: "predicate-combination";
    readonly combinator: "and" | "or";
    readonly predicates: readonly PredicateSpecification[];
}

function getColumnFromPredicateSpec(spec: PredicateSpecification): SourceColumn[] {
    const columns: SourceColumn[] = [];

    if (isSourceColumn(spec.column)) {
        columns.push(spec.column);
    }

    if (spec.kind === "binary" && isSourceColumn(spec.value)) {
        columns.push(spec.value);
    }
    return columns;
}

export function getColumnsFromPredicateCombinationSpec(spec: PredicateCombinationSpecification): SourceColumn[] {
    const columns: SourceColumn[] = spec.predicates.flatMap(getColumnFromPredicateSpec);
    return columns;
}

function makePredicateFormula(spec: PredicateSpecification): Formula {
    if (spec.kind === "unary") {
        return makeUnaryPredicateFormula(spec);
    } else if (spec.kind === "binary") {
        return makeBinaryPredicateFormula(spec);
    } else {
        return assertNever(spec);
    }
}

function decomposePredicateFormula(formula: Formula): PredicateSpecification | undefined {
    const unary = decomposeUnaryPredicateFormula(formula);
    if (unary !== undefined) return unary;

    const binary = decomposeBinaryPredicateFormula(formula);
    if (binary !== undefined) return binary;

    return undefined;
}

export function makePredicateCombinationFormula({
    combinator,
    predicates,
}: PredicateCombinationSpecification): Formula {
    let isAnd: boolean;

    if (combinator === "and") {
        isAnd = true;
    } else if (combinator === "or") {
        isAnd = false;
    } else {
        return assertNever(combinator);
    }

    let result: Formula | undefined;
    for (const spec of [...predicates].reverse()) {
        const f = makePredicateFormula(spec);
        if (result === undefined) {
            result = f;
        } else {
            result = { kind: isAnd ? FormulaKind.And : FormulaKind.Or, left: f, right: result } as AndOrFormula;
        }
    }

    if (result === undefined) {
        return makeConstant(isAnd);
    }

    return result;
}

export function decomposePredicateCombinationFormula(
    formula: Formula
): { spec: PredicateCombinationSpecification; formulas: readonly Formula[] } | undefined {
    if (formula.kind === FormulaKind.Constant) {
        const { value } = formula as ConstantFormula;
        if (typeof value === "boolean") {
            return {
                spec: { kind: "predicate-combination", combinator: value ? "and" : "or", predicates: [] },
                formulas: [],
            };
        } else {
            return undefined;
        }
    }

    // Singletons are ands as well.
    const isAnd = formula.kind !== FormulaKind.Or;
    const predicates: PredicateSpecification[] = [];
    const formulas: Formula[] = [];

    let error = false;
    function collect(f: Formula): void {
        if ((f.kind === FormulaKind.And && !isAnd) || (f.kind === FormulaKind.Or && isAnd)) {
            error = true;
            return;
        }

        if (f.kind === FormulaKind.And || f.kind === FormulaKind.Or) {
            const ao = f as AndOrFormula;
            collect(ao.left);
            collect(ao.right);
        } else {
            const p = decomposePredicateFormula(f);
            if (p === undefined) {
                error = true;
                return;
            }
            predicates.push(p);
            formulas.push(f);
        }
    }

    collect(formula);

    if (error) return undefined;
    return { spec: { kind: "predicate-combination", combinator: isAnd ? "and" : "or", predicates }, formulas };
}

function isFormulaRegularDateTime(formula: Formula): Formula | undefined {
    if (
        formula.kind === FormulaKind.SpecialValue &&
        (formula as SpecialValueFormula).valueKind === SpecialValueKind.Timestamp
    ) {
        return formula;
    }
    if (formula.kind === FormulaKind.ConvertToType) {
        const f = formula as ConvertToTypeFormula;
        if (f.typeKind === "date-time") {
            return f.value;
        }
    }
    return undefined;
}

function isFormulaDateTimeDuration(
    formula: Formula
): { value: SourceColumn | BinaryPredicateDateTimeValueSpecification; endOfDuration: boolean | undefined } | undefined;
function isFormulaDateTimeDuration(
    formula: Formula,
    isEnd: boolean
): { value: SourceColumn | BinaryPredicateDateTimeValueSpecification; endOfDuration: boolean } | undefined;
function isFormulaDateTimeDuration(
    formula: Formula,
    isEnd?: boolean
): { value: SourceColumn | BinaryPredicateDateTimeValueSpecification; endOfDuration: boolean | undefined } | undefined {
    if (
        formula.kind === FormulaKind.SpecialValue &&
        (formula as SpecialValueFormula).valueKind === SpecialValueKind.Timestamp
    ) {
        return { value: { kind: BinaryPredicateValueKind.Now }, endOfDuration: isEnd };
    }
    if (formula.kind !== FormulaKind.StartOfDay && formula.kind !== FormulaKind.EndOfDay) {
        return undefined;
    }

    const { dateTime } = formula as StartOrEndOfDayFormula;

    isEnd = formula.kind === FormulaKind.EndOfDay;

    if (
        dateTime.kind === FormulaKind.SpecialValue &&
        (dateTime as SpecialValueFormula).valueKind === SpecialValueKind.Timestamp
    ) {
        return {
            value: { kind: BinaryPredicateValueKind.Today },
            endOfDuration: isEnd,
        };
    }

    if (dateTime.kind !== FormulaKind.ConvertToType) return undefined;
    const dt = dateTime as ConvertToTypeFormula;
    if (dt.typeKind !== "date-time") return undefined;

    const sourceColumn = decomposeSourceColumnFormulaWithoutFormat(dt.value);
    if (sourceColumn === undefined) return undefined;
    return { value: sourceColumn, endOfDuration: isEnd };
}

function getDateTimeOperator(
    op: BinaryPredicateOperator,
    endOfDuration: boolean | undefined
): BinaryPredicateCompositeOperator | undefined {
    for (const [compositeOp, entry] of toPairs(binaryPredicateDateTimeOperators)) {
        if (entry === undefined) continue;
        if (entry.op === op && (endOfDuration === undefined || entry.endOfDuration === endOfDuration)) {
            return compositeOp as BinaryPredicateCompositeOperator;
        }
    }
    return undefined;
}

export function decomposeBinaryPredicateFormula(formula: Formula): BinaryPredicateSpecification | undefined {
    if (formula.kind === FormulaKind.IsInRange) {
        const f = formula as IsInRangeFormula;
        const dateTimeValue = isFormulaRegularDateTime(f.value);
        const dateTimeStart = isFormulaDateTimeDuration(f.start, false);
        const dateTimeEnd = isFormulaDateTimeDuration(f.end, true);
        const valueColumn = definedMap(dateTimeValue, decomposeSourceColumnFormulaWithoutFormat);
        if (valueColumn === undefined) return undefined;
        if (dateTimeStart === undefined || dateTimeEnd === undefined) return undefined;
        if (dateTimeStart.endOfDuration || !dateTimeEnd.endOfDuration) return undefined;
        if (isSourceColumn(dateTimeStart.value) && isSourceColumn(dateTimeEnd.value)) {
            if (!areSourceColumnsEqual(dateTimeStart.value, dateTimeEnd.value)) return undefined;
        } else if (isSourceColumn(dateTimeStart.value)) {
            return undefined;
        } else if (dateTimeStart.value.kind !== dateTimeEnd.value.kind) {
            return undefined;
        }

        return {
            kind: "binary",
            column: valueColumn,
            operator: BinaryPredicateCompositeOperator.IsWithin,
            value: dateTimeStart.value,
        };
    }

    const { value: f, isNegated } = isFormulaNegated(formula);

    if (f.kind !== FormulaKind.CompareValues) return undefined;

    let { left, right } = f as CompareValuesFormula;
    const { operator } = f as CompareValuesFormula;
    // We've had a bug where this happened
    if (operator === undefined) return undefined;

    if (isNegated && operator === BinaryPredicateFormulaOperator.Equals && right.kind === FormulaKind.StartOfDay) {
        const r = right as StartOrEndOfDayFormula;
        if (
            r.dateTime.kind === FormulaKind.SpecialValue &&
            (r.dateTime as SpecialValueFormula).valueKind === SpecialValueKind.Timestamp
        ) {
            // This is a bugfix for some incorrect formula that we apparently
            // produced, or maybe even produce right now.  It was seen in
            // app `6hBXMWjT5qzJetMCuhPO`.
            const l = decomposeSourceColumnFormulaWithoutFormat(left);
            if (l !== undefined) {
                return {
                    kind: "binary",
                    column: l,
                    operator: BinaryPredicateCompositeOperator.IsOnOrAfter,
                    value: {
                        kind: BinaryPredicateValueKind.Today,
                    },
                };
            }
        }
    }

    let op: BinaryPredicateOperator = operator;
    let value: BinaryPredicateValueSpecification | undefined;
    if (isNegated) {
        const maybeOp = objectKeyForValue(binaryPredicateNegations, op);
        if (maybeOp === undefined) return undefined;
        op = maybeOp;
    } else {
        const dateTimeLeft = isFormulaRegularDateTime(left);
        const dateTimeRight = isFormulaRegularDateTime(right);
        if (dateTimeLeft !== undefined && dateTimeRight !== undefined) {
            left = dateTimeLeft;
            right = dateTimeRight;
            const maybeOperator = getDateTimeOperator(op, undefined);
            if (maybeOperator === undefined) return undefined;
            op = maybeOperator;
        } else if (dateTimeLeft !== undefined) {
            left = dateTimeLeft;
            const duration = isFormulaDateTimeDuration(right);
            if (duration === undefined) return undefined;
            let maybeOperator = getDateTimeOperator(op, duration.endOfDuration);
            if (maybeOperator === undefined) {
                // This is the special case for old formulas.  ##isAfterVsGreaterOrEqualTo
                if (op === BinaryPredicateFormulaOperator.IsGreaterOrEqualTo && duration.endOfDuration === true) {
                    maybeOperator = BinaryPredicateCompositeOperator.IsAfter;
                } else {
                    return undefined;
                }
            }
            if (isSourceColumn(duration.value)) {
                value = duration.value;
            } else {
                value = { kind: duration.value.kind };
            }
            op = maybeOperator;
        }
    }

    const leftColumn = decomposeSourceColumnFormulaWithoutFormat(left) ?? decomposePluginSpecialValueFormula(left);
    if (leftColumn === undefined) return undefined;

    if (value === undefined) {
        value = decomposeBinaryPredicateValueFormula(right);
        if (value === undefined) return undefined;
    }

    return {
        kind: "binary",
        column: leftColumn,
        operator: op,
        value,
    };
}

export interface IfThenClauseSpecification {
    readonly condition: PredicateSpecification;
    readonly thenValue: ColumnOrValueSpecification<string | number>;
}

export interface IfThenElseSpecification {
    readonly kind: SyntheticColumnKind.IfThenElse;
    readonly clauses: readonly IfThenClauseSpecification[];
    readonly elseValue: ColumnOrValueSpecification<string | number>;
}

export function makeIfThenElseFormula({ clauses, elseValue }: IfThenElseSpecification): Formula {
    let formula: Formula = makeColumnOrValue(elseValue, true, false, false);
    for (const { condition, thenValue } of clauses.slice().reverse()) {
        formula = {
            kind: FormulaKind.IfThenElse,
            condition:
                condition.kind === "binary"
                    ? makeBinaryPredicateFormula(condition)
                    : makeUnaryPredicateFormula(condition),
            consequent: makeColumnOrValue(thenValue, true, false, false),
            alternative: formula,
        } as IfThenElseFormula;
    }
    return formula;
}

function decomposeIfThenElseFormula(formula: Formula): IfThenElseSpecification | undefined {
    const clauses: IfThenClauseSpecification[] = [];

    while (formula.kind === FormulaKind.IfThenElse) {
        const { condition, consequent, alternative } = formula as IfThenElseFormula;
        if (alternative === undefined) return undefined;

        const conditionSpec = decomposeBinaryPredicateFormula(condition) ?? decomposeUnaryPredicateFormula(condition);
        if (conditionSpec === undefined) return undefined;

        const thenValue = decomposeColumnOrValue(consequent);
        if (thenValue === undefined || !isColumnOrStringOrNumberOrEmpty(thenValue)) return undefined;

        clauses.push({ condition: conditionSpec, thenValue });

        formula = alternative;
    }

    const elseString = decomposeColumnOrValue(formula);
    if (elseString === undefined || !isColumnOrStringOrNumberOrEmpty(elseString)) return undefined;

    return {
        kind: SyntheticColumnKind.IfThenElse,
        clauses,
        elseValue: elseString,
    };
}

export function getComputedColumnKind(formula: Formula): SyntheticColumnKind | undefined {
    if (decomposeFilterReferenceFormula(formula) !== undefined) {
        return SyntheticColumnKind.FilterReference;
    }
    if (decomposeTextTemplateFormula(formula) !== undefined) {
        return SyntheticColumnKind.TextTemplate;
    }
    if (decomposeIfThenElseFormula(formula) !== undefined) {
        return SyntheticColumnKind.IfThenElse;
    }
    if (decomposeLookupFormula(formula) !== undefined) {
        return SyntheticColumnKind.Lookup;
    }
    if (decomposeRepeatSingleValueFormula(formula) !== undefined) {
        return SyntheticColumnKind.SingleValue;
    }
    if (decomposeMathFormula(formula) !== undefined) {
        return SyntheticColumnKind.Math;
    }
    if (decomposeRollupFormula(formula) !== undefined) {
        return SyntheticColumnKind.Rollup;
    }
    if (decomposeGeoDistanceFormula(formula) !== undefined) {
        return SyntheticColumnKind.GeoDistance;
    }
    return undefined;
}

export interface LookupSpecification {
    readonly kind: SyntheticColumnKind.Lookup;
    readonly tableOrRelationColumn: TableOrRelationColumnOrActionNodeOutput;
    readonly isMultiRelation: boolean;
    readonly valueColumn: string;
}

export function makeLookupFormula({
    tableOrRelationColumn,
    isMultiRelation,
    valueColumn,
}: LookupSpecification): Formula {
    const contextName = "relation";

    if (isUniversalTableName(tableOrRelationColumn)) {
        assert(isMultiRelation);
    }

    const { rows: getRelationColumn, value: getColumnInRelation } = makeTableOrRelationAggregateRowsAndValue(
        tableOrRelationColumn,
        valueColumn,
        false,
        contextName
    );

    if (isMultiRelation) {
        return {
            kind: FormulaKind.MapRows,
            rows: getRelationColumn,
            functionContextName: contextName,
            function: getColumnInRelation,
        } as MapRowsFormula;
    } else {
        return {
            kind: FormulaKind.With,
            contextName,
            context: getRelationColumn,
            value: getColumnInRelation,
        } as WithFormula;
    }
}

function decomposeLookupFormula(formula: Formula): LookupSpecification | undefined {
    let isMultiRelation: boolean;
    let getRelationColumn: Formula;
    let getColumnInRelation: Formula;
    let contextName: string;

    if (formula.kind === FormulaKind.With) {
        const f = formula as WithFormula;
        isMultiRelation = false;
        getRelationColumn = f.context;
        getColumnInRelation = f.value;
        contextName = f.contextName;
    } else if (formula.kind === FormulaKind.MapRows) {
        const f = formula as MapRowsFormula;
        isMultiRelation = true;
        getRelationColumn = f.rows;
        getColumnInRelation = f.function;
        contextName = f.functionContextName;
    } else {
        return undefined;
    }

    const decomposed = decomposeTableOrRelationAggregateRowsAndValue(
        getRelationColumn,
        getColumnInRelation,
        contextName,
        false
    );
    if (decomposed === undefined) return undefined;

    return {
        kind: SyntheticColumnKind.Lookup,
        tableOrRelationColumn: decomposed.tableOrRelationColumn,
        valueColumn: decomposed.valueColumn,
        isMultiRelation,
    };
}

export function decomposeSingleRelationLookup(tableOrColumns: TableOrColumns, spec: LookupSpecification) {
    // We only support lookups of single relations
    if (spec.isMultiRelation || typeof spec.tableOrRelationColumn !== "string") return undefined;
    const relationColumn = getTableColumn(tableOrColumns, spec.tableOrRelationColumn);
    if (relationColumn === undefined || !isComputedColumn(relationColumn)) return undefined;
    const relationSpec = decomposeAll(relationColumn.formula);
    if (relationSpec?.kind !== SyntheticColumnKind.FilterReference) return undefined;
    if (relationSpec.multiple) return undefined;

    return {
        hostColumnName: relationSpec.hostColumn,
        targetTableName: relationSpec.targetTable,
        targetColumnName: relationSpec.targetColumn,
        lookupColumnName: spec.valueColumn,
    };
}

export enum SingleValuePositionKind {
    First,
    Last,
    Random,
    FromStart,
    FromEnd,
}

export interface SimpleSingleValuePosition {
    readonly kind: SingleValuePositionKind.First | SingleValuePositionKind.Last | SingleValuePositionKind.Random;
}

export interface SingleValuePositionWithOffset {
    readonly kind: SingleValuePositionKind.FromStart | SingleValuePositionKind.FromEnd;
    readonly offset: ColumnOrValueSpecification<number>;
}

export type SingleValuePosition = SimpleSingleValuePosition | SingleValuePositionWithOffset;

interface SingleValueRowSpecification {
    readonly tableOrRelationColumn: TableOrRelationColumnOrActionNodeOutput;
    readonly position: SingleValuePosition;
}

export interface SingleValueSpecification {
    readonly kind: SyntheticColumnKind.SingleValue;
    readonly arraySource: ArraySourceSpecification;
    readonly position: SingleValuePosition;
}

function makeTableOrRelationColumn(tableOrRelationColumn: TableOrRelationColumnOrActionNodeOutput): Formula {
    if (typeof tableOrRelationColumn === "string") {
        return {
            kind: FormulaKind.GetColumn,
            column: tableOrRelationColumn,
        } as GetColumnFormula;
    } else if (isSourceColumn(tableOrRelationColumn)) {
        return makeSourceColumnFormula(tableOrRelationColumn, false);
    } else {
        return {
            kind: FormulaKind.GetTableRows,
            table: tableOrRelationColumn,
        } as GetTableRowsFormula;
    }
}

function decomposeTableOrRelationColumn(formula: Formula): TableOrRelationColumnOrActionNodeOutput | undefined {
    if (formula.kind === FormulaKind.GetColumn) {
        return (formula as GetColumnFormula).column;
    } else if (formula.kind === FormulaKind.GetTableRows) {
        return (formula as GetTableRowsFormula).table;
    } else {
        const decomposed = decomposeSourceColumnFormula(formula);
        if (decomposed !== undefined && isActionNodeOutputSourceColumn(decomposed.column) && !decomposed.withFormat) {
            return decomposed.column;
        }
        return undefined;
    }
}

export function makeSingleValueFormula({ arraySource, position }: SingleValueSpecification): Formula {
    const array = makeTableOrRelationColumn(arraySource.tableOrRelationColumn);

    let row: Formula;
    switch (position.kind) {
        case SingleValuePositionKind.First:
        case SingleValuePositionKind.Last:
            row = {
                kind: position.kind === SingleValuePositionKind.First ? FormulaKind.GetNth : FormulaKind.GetNthLast,
                array,
                index: makeConstant(0),
            } as GetNthFormula;
            break;
        case SingleValuePositionKind.Random:
            row = {
                kind: FormulaKind.RandomPick,
                array,
            } as RandomPickFormula;
            break;
        case SingleValuePositionKind.FromStart:
        case SingleValuePositionKind.FromEnd:
            row = {
                kind: position.kind === SingleValuePositionKind.FromStart ? FormulaKind.GetNth : FormulaKind.GetNthLast,
                array,
                index: makeColumnOrValue(position.offset, true, false, false),
            } as GetNthFormula;
            break;
        default:
            return assertNever(position);
    }
    if (arraySource.valueColumn === undefined) {
        return row;
    }

    const contextName = "first";
    return {
        kind: FormulaKind.With,
        contextName,
        context: row,
        value: {
            kind: FormulaKind.GetColumn,
            column: arraySource.valueColumn,
            contextName,
        },
    } as WithFormula;
}

function decomposeSingleValueRow(formula: Formula): SingleValueRowSpecification | undefined {
    if (formula.kind === FormulaKind.GetNth || formula.kind === FormulaKind.GetNthLast) {
        const f = formula as GetNthFormula;
        const tableOrRelationColumn = decomposeTableOrRelationColumn(f.array);
        if (tableOrRelationColumn === undefined) return undefined;

        const offset = decomposeColumnOrValue(f.index);
        if (offset === undefined) return undefined;
        if (offset.kind === ColumnOrValueKind.Constant && typeof offset.value !== "number") return undefined;

        let position: SingleValuePosition;
        if (offset.kind === ColumnOrValueKind.Constant && offset.value === 0) {
            const kind = f.kind === FormulaKind.GetNth ? SingleValuePositionKind.First : SingleValuePositionKind.Last;
            position = { kind };
        } else {
            const kind =
                f.kind === FormulaKind.GetNth ? SingleValuePositionKind.FromStart : SingleValuePositionKind.FromEnd;
            position = { kind, offset: offset as ColumnOrValueSpecification<number> };
        }

        return { tableOrRelationColumn, position };
    } else if (formula.kind === FormulaKind.RandomPick) {
        const f = formula as RandomPickFormula;
        const tableOrRelationColumn = decomposeTableOrRelationColumn(f.array);
        if (tableOrRelationColumn === undefined) return undefined;

        return { tableOrRelationColumn, position: { kind: SingleValuePositionKind.Random } };
    } else {
        return undefined;
    }
}

function decomposeRepeatSingleValueFormula(formula: Formula): SingleValueSpecification | undefined {
    if (formula.kind !== FormulaKind.With) {
        const singleValueRow = decomposeSingleValueRow(formula);
        if (singleValueRow === undefined) return undefined;

        return {
            kind: SyntheticColumnKind.SingleValue,
            arraySource: {
                tableOrRelationColumn: singleValueRow.tableOrRelationColumn,
                valueColumn: undefined,
            },
            position: singleValueRow.position,
        };
    }

    const { context, contextName, value } = formula as WithFormula;

    const row = decomposeSingleValueRow(context);
    if (row === undefined) return undefined;

    if (value.kind !== FormulaKind.GetColumn) return undefined;
    const v = value as GetColumnFormula;

    if (contextName !== v.contextName) return undefined;

    return {
        kind: SyntheticColumnKind.SingleValue,
        arraySource: {
            tableOrRelationColumn: row.tableOrRelationColumn,
            valueColumn: v.column,
        },
        position: row.position,
    };
}

// FIXME: Use `ColumnOrValueSpecification`
export type MathVariableAssignment = SourceColumn | SpecialValueKind.Timestamp;

export interface MathSpecification {
    readonly kind: SyntheticColumnKind.Math;
    readonly expr: string;
    // [variableName, column]
    readonly variables: readonly [string, MathVariableAssignment][];
}

export function addAssignmentsToMathFormula(
    formula: Formula,
    variableNames: readonly string[],
    variables: readonly [string, MathVariableAssignment][]
): Formula | undefined {
    const assignments: [string, Formula][] = [];
    for (const n of variableNames) {
        const variable = variables.find(v => v[0] === n);
        if (variable === undefined) return undefined;
        const assignment = variable[1];
        let f: Formula;
        if (assignment === SpecialValueKind.Timestamp) {
            f = { kind: FormulaKind.SpecialValue, valueKind: SpecialValueKind.Timestamp } as SpecialValueFormula;
        } else {
            f = makeSourceColumnFormula(assignment, false);
        }
        assignments.push([n, f]);
    }

    return { kind: FormulaKind.AssignVariables, assignments, body: formula } as AssignVariablesFormula;
}

export function makeMathFormula({ expr, variables }: MathSpecification): Formula | undefined {
    const result = parseMath(expr, false);
    if (result.success === false) return undefined;
    if (!isFormulaDepthOK(result.formula)) return undefined;

    return addAssignmentsToMathFormula(result.formula, result.variableNames, variables);
}

function decomposeMathFormula(formula: Formula): MathSpecification | undefined {
    if (formula.kind !== FormulaKind.AssignVariables) return undefined;
    const { assignments, body } = formula as AssignVariablesFormula;

    const variables: [string, MathVariableAssignment][] = [];
    for (const [name, f] of assignments) {
        let assignment: MathVariableAssignment | undefined;
        if (
            f.kind === FormulaKind.SpecialValue &&
            (f as SpecialValueFormula).valueKind === SpecialValueKind.Timestamp
        ) {
            assignment = SpecialValueKind.Timestamp;
        } else {
            assignment = decomposeSourceColumnFormulaWithoutFormat(f);
        }
        if (assignment === undefined) return undefined;

        variables.push([name, assignment]);
    }

    const expr = unparseMath(body);
    if (expr === undefined) return undefined;

    return { kind: SyntheticColumnKind.Math, expr, variables };
}

export interface RollupSpecification {
    readonly kind: SyntheticColumnKind.Rollup;
    readonly arraySource: ArraySourceSpecification;
    readonly rollupKind: RollupKind;
}

export const reduceOperatorForRollupKind: Partial<Record<RollupKind, ReduceOperator>> = {
    [RollupKind.AllTrue]: ReduceOperator.AllTrue,
    [RollupKind.SomeTrue]: ReduceOperator.SomeTrue,
    [RollupKind.Sum]: ReduceOperator.Sum,
    [RollupKind.CountNonEmpty]: ReduceOperator.CountNonEmpty,
    [RollupKind.Average]: ReduceOperator.Average,
    [RollupKind.CountTrue]: ReduceOperator.CountTrue,
    [RollupKind.CountNotTrue]: ReduceOperator.CountNotTrue,
    [RollupKind.CountUnique]: ReduceOperator.CountUnique,
    [RollupKind.Range]: ReduceOperator.Range,
};

const reduceToMemberByOperatorForRollupKind: Partial<
    Record<RollupKind, [ReduceToMemberByOperator, PrimitiveGlideTypeKind]>
> = {
    [RollupKind.Minimum]: [ReduceToMemberByOperator.Minimum, "number"],
    [RollupKind.Maximum]: [ReduceToMemberByOperator.Maximum, "number"],
    [RollupKind.Earliest]: [ReduceToMemberByOperator.Minimum, "date-time"],
    [RollupKind.Latest]: [ReduceToMemberByOperator.Maximum, "date-time"],
};

// The `ActionNodeOutputSourceColumn` case here must refer to a relation-y thing.
export type TableOrRelationColumnOrActionNodeOutput = UniversalTableName | string | ActionNodeOutputSourceColumn;

export interface ArraySourceSpecification {
    readonly tableOrRelationColumn: TableOrRelationColumnOrActionNodeOutput;
    readonly valueColumn: string | undefined;
}

function makeTableOrRelationAggregateRowsAndValue(
    tableOrRelationColumn: TableOrRelationColumnOrActionNodeOutput,
    valueColumn: string,
    withFormat: boolean,
    valueContextName: string = "value"
): { rows: Formula; value: Formula; valueContextName: string } {
    const rows = makeTableOrRelationColumn(tableOrRelationColumn);

    let value: Formula = {
        kind: FormulaKind.GetColumn,
        column: valueColumn,
        contextName: valueContextName,
    } as GetColumnFormula;
    if (withFormat) {
        value = applyColumnFormat(value, valueColumn, valueContextName);
    }

    return { rows, value, valueContextName };
}

function makeAggregateRowsAndValue(
    source: ArraySourceSpecification,
    withFormat: boolean,
    valueContextName: string = "value"
): { rows: Formula; value: Formula; valueContextName: string } {
    if (source.valueColumn !== undefined) {
        return makeTableOrRelationAggregateRowsAndValue(
            source.tableOrRelationColumn,
            source.valueColumn,
            withFormat,
            valueContextName
        );
    } else if (typeof source.tableOrRelationColumn === "string") {
        let value: Formula = { kind: FormulaKind.GetContext, contextName: valueContextName } as GetContextFormula;
        if (withFormat) {
            value = applyColumnFormat(value, source.tableOrRelationColumn, undefined);
        }
        return {
            rows: { kind: FormulaKind.GetColumn, column: source.tableOrRelationColumn } as GetColumnFormula,
            value,
            valueContextName,
        };
    } else if (isSourceColumn(source.tableOrRelationColumn)) {
        const value: Formula = { kind: FormulaKind.GetContext, contextName: valueContextName } as GetContextFormula;
        return {
            rows: makeSourceColumnFormula(source.tableOrRelationColumn, withFormat),
            value,
            valueContextName,
        };
    } else {
        return panic("Whole row in table is not supported");
    }
}

export function makeRollupFormula({ arraySource, rollupKind }: RollupSpecification): Formula {
    const { rows, value, valueContextName } = makeAggregateRowsAndValue(arraySource, false);

    function makeReduce(operator: ReduceOperator): ReduceFormula {
        return {
            kind: FormulaKind.Reduce,
            operator,
            rows,
            valueContextName,
            value,
        };
    }

    const reduceOperator = reduceOperatorForRollupKind[rollupKind];
    if (reduceOperator !== undefined) {
        return makeReduce(reduceOperator);
    }

    const operatorAndType = reduceToMemberByOperatorForRollupKind[rollupKind];
    if (operatorAndType !== undefined) {
        const [operator, typeKind] = operatorAndType;
        return {
            kind: FormulaKind.ReduceToMemberBy,
            operator,
            rows,
            valueContextName,
            value,
            key: {
                kind: FormulaKind.ConvertToType,
                typeKind,
                value,
            },
        } as ReduceToMemberByFormula;
    }

    return makeReduce(ReduceOperator.CountNonEmpty);
}

function stripFormat(value: Formula, valueContextName: string | undefined, withFormat: boolean): Formula | undefined {
    if (!withFormat || value.kind !== FormulaKind.ApplyColumnFormat) {
        return value;
    }

    const v = value as ApplyColumnFormatFormula;
    if (v.contextName !== valueContextName) return undefined;
    return v.value;
}

function decomposeTableOrRelationAggregateRowsAndValue(
    rows: Formula,
    value: Formula,
    valueContextName: string,
    withFormat: boolean
): { tableOrRelationColumn: TableOrRelationColumnOrActionNodeOutput; valueColumn: string } | undefined {
    const strippedValue = stripFormat(value, valueContextName, withFormat);
    if (strippedValue === undefined) return undefined;

    if (strippedValue.kind !== FormulaKind.GetColumn) return undefined;
    const sv = strippedValue as GetColumnFormula;
    if (valueContextName !== sv.contextName) return undefined;

    const tableOrRelationColumn = decomposeTableOrRelationColumn(rows);
    if (tableOrRelationColumn === undefined) return undefined;

    return { tableOrRelationColumn, valueColumn: sv.column };
}

function decomposeAggregateRowsAndValue(
    rows: Formula,
    value: Formula,
    valueContextName: string,
    withFormat: boolean
): ArraySourceSpecification | undefined {
    const fromTableOrRelation = decomposeTableOrRelationAggregateRowsAndValue(
        rows,
        value,
        valueContextName,
        withFormat
    );
    if (fromTableOrRelation !== undefined) return { ...fromTableOrRelation };

    const strippedValue = stripFormat(value, undefined, withFormat);
    if (strippedValue === undefined) return undefined;
    if (
        strippedValue.kind !== FormulaKind.GetContext ||
        (strippedValue as GetContextFormula).contextName !== valueContextName
    ) {
        return undefined;
    }

    if (rows.kind === FormulaKind.GetColumn) {
        const r = rows as GetColumnFormula;
        if (r.contextName !== undefined) return undefined;

        return { tableOrRelationColumn: r.column, valueColumn: undefined };
    } else {
        const sc = decomposeSourceColumnFormula(rows);
        if (sc !== undefined && sc.withFormat === withFormat && isActionNodeOutputSourceColumn(sc.column)) {
            return { tableOrRelationColumn: sc.column, valueColumn: undefined };
        }
    }

    return undefined;
}

function decomposeRollupFormula(formula: Formula): RollupSpecification | undefined {
    if (formula.kind !== FormulaKind.Reduce && formula.kind !== FormulaKind.ReduceToMemberBy) return undefined;

    const { rows, valueContextName, value, operator } = formula as ReduceFormula | ReduceToMemberByFormula;

    const arraySource = decomposeAggregateRowsAndValue(rows, value, valueContextName, false);
    if (arraySource === undefined) return undefined;

    let rollupKind: RollupKind | undefined;
    if (formula.kind === FormulaKind.Reduce) {
        rollupKind = objectKeyForValue(reduceOperatorForRollupKind, operator);
    } else if (formula.kind === FormulaKind.ReduceToMemberBy) {
        const { key } = formula as ReduceToMemberByFormula;

        if (key.kind !== FormulaKind.ConvertToType) return undefined;
        const k = key as ConvertToTypeFormula;
        if (!deepEqual(k.value, value, { strict: true })) return undefined;

        rollupKind = objectKeyForValue(reduceToMemberByOperatorForRollupKind, [operator, k.typeKind], (a, b) =>
            deepEqual(a, b, { strict: true })
        );
    }

    if (rollupKind === undefined) return undefined;
    return { kind: SyntheticColumnKind.Rollup, arraySource, rollupKind };
}

export interface JoinStringsSpecification {
    readonly kind: SyntheticColumnKind.JoinStrings;
    readonly arraySource: ArraySourceSpecification;
    readonly separator: ColumnOrValueSpecification<string>;
}

export function makeJoinStringsFormula({ arraySource, separator }: JoinStringsSpecification): Formula {
    const { rows, value, valueContextName } = makeAggregateRowsAndValue(arraySource, true);

    return {
        kind: FormulaKind.JoinStrings,
        rows,
        value,
        valueContextName,
        separator: makeColumnOrValue(separator, false, true, false),
    } as JoinStringsFormula;
}

function decomposeJoinStringsFormula(formula: Formula): JoinStringsSpecification | undefined {
    if (formula.kind !== FormulaKind.JoinStrings) return undefined;
    const f = formula as JoinStringsFormula;

    const arraySource = decomposeAggregateRowsAndValue(f.rows, f.value, f.valueContextName, true);
    if (arraySource === undefined) return undefined;

    const separator = decomposeColumnOrValue(f.separator);
    if (separator === undefined || !isColumnOrPrimitive(separator, false, true)) return undefined;

    return {
        kind: SyntheticColumnKind.JoinStrings,
        arraySource,
        separator,
    };
}

export interface SplitStringSpecification {
    readonly kind: SyntheticColumnKind.SplitString;
    readonly stringColumn: SourceColumn;
    readonly separator: ColumnOrValueSpecification<string>;
}

export function makeSplitStringFormula({ stringColumn, separator }: SplitStringSpecification): SplitStringFormula {
    return {
        kind: FormulaKind.SplitString,
        string: makeSourceColumnFormula(stringColumn, false),
        separator: makeColumnOrValue(separator, false, true, false),
    };
}

function decomposeSplitStringFormula(formula: Formula): SplitStringSpecification | undefined {
    if (formula.kind !== FormulaKind.SplitString) return undefined;
    const f = formula as SplitStringFormula;

    const stringColumn = decomposeSourceColumnFormula(f.string);
    if (stringColumn === undefined || stringColumn.withFormat) return undefined;

    const separator = decomposeColumnOrValue(f.separator);
    if (separator === undefined || !isColumnOrPrimitive(separator, false, true)) return undefined;

    return { kind: SyntheticColumnKind.SplitString, stringColumn: stringColumn.column, separator };
}

export interface MakeArraySpecification {
    readonly kind: SyntheticColumnKind.MakeArray;
    readonly items: readonly ColumnOrValueSpecification<BasePrimitiveValue>[];
}

export function makeMakeArrayFormula({ items }: MakeArraySpecification): MakeArrayFormula {
    return {
        kind: FormulaKind.MakeArray,
        items: items.map(i => makeColumnOrValue(i, false, true, false)),
    };
}

function decomposeMakeArrayFormula(formula: Formula): MakeArraySpecification | undefined {
    if (formula.kind !== FormulaKind.MakeArray) return undefined;
    const f = formula as MakeArrayFormula;

    const items = f.items.map(i => {
        const c = decomposeColumnOrValue(i);
        if (c === undefined || (c.kind !== ColumnOrValueKind.Column && c.kind !== ColumnOrValueKind.Constant))
            return undefined;
        return c;
    });
    if (!items.every(isDefined)) return undefined;

    return {
        kind: SyntheticColumnKind.MakeArray,
        items,
    };
}

type SourceColumnOrHere = SourceColumn | "here";

export enum DistanceUnit {
    KM = "km",
    Miles = "Miles",
}

export interface GeoDistanceSpecification {
    readonly kind: SyntheticColumnKind.GeoDistance;
    readonly locationColumn: SourceColumn;
    readonly otherLocationColumn: SourceColumnOrHere;
    readonly unit: DistanceUnit;
}

function makeGeocodeSourceColumnOrHereFormula(l: SourceColumnOrHere): Formula {
    if (l === "here") {
        return { kind: FormulaKind.CurrentLocation };
    } else {
        return {
            kind: FormulaKind.GeocodeAddress,
            address: makeSourceColumnFormula(l, false),
        } as GeocodeAddressFormula;
    }
}

export function makeGeoDistanceFormula({
    locationColumn,
    otherLocationColumn,
    unit,
}: GeoDistanceSpecification): Formula {
    const km: GeoDistanceFormula = {
        kind: FormulaKind.GeoDistance,
        left: makeGeocodeSourceColumnOrHereFormula(otherLocationColumn),
        right: makeGeocodeSourceColumnOrHereFormula(locationColumn),
    };
    if (unit === DistanceUnit.KM) {
        return km;
    } else if (unit === DistanceUnit.Miles) {
        const f: BinaryMathFormula = {
            kind: FormulaKind.BinaryMath,
            fn: BinaryMathFunction.Multiply,
            left: km,
            right: makeConstant(milesPerKM),
        };
        return f;
    } else {
        return assertNever(unit);
    }
}

function decomposeGeocodeSourceColumnFormula(f: Formula): SourceColumn | undefined {
    if (f.kind === FormulaKind.GeocodeAddress) {
        return decomposeSourceColumnFormulaWithoutFormat((f as GeocodeAddressFormula).address);
    }
    return undefined;
}

function decomposeGeocodeSourceColumnOrHereFormula(f: Formula): SourceColumnOrHere | undefined {
    const sourceColumn = decomposeGeocodeSourceColumnFormula(f);
    if (sourceColumn !== undefined) return sourceColumn;

    if (f.kind === FormulaKind.CurrentLocation) {
        return "here";
    }
    return undefined;
}

function decomposeGeoDistanceColumnsFormula(
    formula: Formula
): { locationColumn: SourceColumn; otherLocationColumn: SourceColumnOrHere } | undefined {
    if (formula.kind !== FormulaKind.GeoDistance) return undefined;
    const f = formula as GeoDistanceFormula;

    const locationColumn = decomposeGeocodeSourceColumnFormula(f.right);
    if (locationColumn === undefined) return undefined;

    const otherLocationColumn = decomposeGeocodeSourceColumnOrHereFormula(f.left);
    if (otherLocationColumn === undefined) return undefined;

    return { locationColumn, otherLocationColumn };
}

function decomposeGeoDistanceFormula(f: Formula): GeoDistanceSpecification | undefined {
    let unit: DistanceUnit | undefined;

    if (f.kind === FormulaKind.BinaryMath) {
        const { fn, left, right } = f as BinaryMathFormula;
        if (
            fn === BinaryMathFunction.Multiply &&
            right.kind === FormulaKind.Constant &&
            (right as ConstantFormula).value === milesPerKM
        ) {
            unit = DistanceUnit.Miles;
            f = left;
        }
    }

    if (unit === undefined) {
        unit = DistanceUnit.KM;
    }

    const columns = decomposeGeoDistanceColumnsFormula(f);
    if (columns === undefined) return undefined;

    return { kind: SyntheticColumnKind.GeoDistance, ...columns, unit };
}

export interface GenerateImageSpecification {
    readonly kind: SyntheticColumnKind.GenerateImage;
    readonly input: ColumnOrValueSpecification<string>;
    readonly imageKind: GeneratedImageKind;
}

export function makeGenerateImageFormula({ input, imageKind }: GenerateImageSpecification): GenerateImageFormula {
    const inputFormula = makeColumnOrValue(input, false, true, false);
    return {
        kind: FormulaKind.GenerateImage,
        input: inputFormula,
        imageKind: makeConstant(imageKind),
    };
}

function decomposeGenerateImageFormula(formula: Formula): GenerateImageSpecification | undefined {
    if (formula.kind !== FormulaKind.GenerateImage) return undefined;
    const { input: inputFormula, imageKind: imageKindFormula } = formula as GenerateImageFormula;

    const input = decomposeColumnOrValue(inputFormula);
    if (input === undefined || !isColumnOrPrimitive(input, false, true)) return undefined;

    if (imageKindFormula.kind !== FormulaKind.Constant) return undefined;
    const imageKindValue = (imageKindFormula as ConstantFormula).value;

    let imageKind: GeneratedImageKind;
    if (imageKindValue === GeneratedImageKind.Mesh || imageKindValue === GeneratedImageKind.Triangles) {
        imageKind = imageKindValue;
    } else {
        imageKind = GeneratedImageKind.Triangles;
    }

    return {
        kind: SyntheticColumnKind.GenerateImage,
        input,
        imageKind,
    };
}

export interface UserAPIFetchSpecification extends SpecificationWithQueryParameters {
    readonly kind: SyntheticColumnKind.UserAPIFetch;
    readonly webhookID: string;
}

function makeUserAPIFetchFormula({ webhookID, params }: UserAPIFetchSpecification): UserAPIFetchFormula {
    return {
        kind: FormulaKind.UserAPIFetch,
        webhookID: makeConstant(webhookID),
        params: makeQueryParametersFormulas(params),
    };
}

function decomposeUserAPIFetchFormula(formula: Formula): UserAPIFetchSpecification | undefined {
    if (formula.kind !== FormulaKind.UserAPIFetch) return undefined;
    const f = formula as UserAPIFetchFormula;

    if (f.webhookID?.kind !== FormulaKind.Constant) return undefined;
    const id = f.webhookID as ConstantFormula;
    if (typeof id.value !== "string") return undefined;

    const params = decomposeQueryParametersFormulas(f.params, true, true);
    if (params === undefined) return undefined;

    return { kind: SyntheticColumnKind.UserAPIFetch, webhookID: id.value, params };
}

export interface ConstructURLSpecification extends SpecificationWithQueryParameters {
    readonly kind: SyntheticColumnKind.ConstructURL;

    // missing: user info, port, fragment

    readonly scheme: ColumnOrValueSpecification<string>;
    readonly host: ColumnOrValueSpecification<string>;
    readonly path: ColumnOrValueSpecification<string>;
}

export function makeConstructURLFormula({
    scheme,
    host,
    path,
    params,
}: ConstructURLSpecification): ConstructURLFormula {
    return {
        kind: FormulaKind.ConstructURL,
        scheme: makeColumnOrValue(scheme, false, false, false),
        host: makeColumnOrValue(host, false, false, false),
        path: makeColumnOrValue(path, false, false, false),
        params: makeQueryParametersFormulas(params),
    };
}

function decomposeConstructURLFormula(formula: Formula): ConstructURLSpecification | undefined {
    if (formula.kind !== FormulaKind.ConstructURL) return undefined;
    const f = formula as ConstructURLFormula;

    const scheme = decomposeColumnOrValue(f.scheme);
    if (scheme === undefined || !isColumnOrStringOrEmpty(scheme, false)) return undefined;

    const host = decomposeColumnOrValue(f.host);
    if (host === undefined || !isColumnOrStringOrEmpty(host, false)) return undefined;

    const path = decomposeColumnOrValue(f.path);
    if (path === undefined || !isColumnOrStringOrEmpty(path, false)) return undefined;

    const params = decomposeQueryParametersFormulas(f.params, true, true);
    if (params === undefined) return undefined;

    return { kind: SyntheticColumnKind.ConstructURL, scheme, host, path, params };
}

export interface YesCodeSpecification extends SpecificationWithQueryParameters {
    readonly kind: SyntheticColumnKind.YesCode;
    readonly url: string;
    readonly type: ColumnType;
    readonly typeIsPrecise: boolean;
}

export function makeYesCodeFormula({ url, params, type, typeIsPrecise }: YesCodeSpecification): YesCodeFormula {
    return {
        kind: FormulaKind.YesCode,
        url: makeConstant(url),
        params: makeQueryParametersFormulas(params),
        type,
        typeIsPrecise,
    };
}

export function decomposeYesCodeFormula(formula: Formula): YesCodeSpecification | undefined {
    if (formula.kind !== FormulaKind.YesCode) return undefined;
    const f = formula as YesCodeFormula;

    if (f.url.kind !== FormulaKind.Constant) return undefined;
    const url = f.url as ConstantFormula;
    if (typeof url.value !== "string") return undefined;

    const params = decomposeQueryParametersFormulas(f.params, true, true);
    if (params === undefined) return undefined;

    return { kind: SyntheticColumnKind.YesCode, url: url.value, params, type: f.type, typeIsPrecise: f.typeIsPrecise };
}

export interface PluginComputationSpecification {
    readonly kind: SyntheticColumnKind.PluginComputation;
    // FIXME: add the config ID to the spec once we support more
    // than one.
    readonly pluginID: string;
    readonly computationID: string;
    readonly parameters: Description;
    readonly resultName: string;
    readonly resultType: ColumnType;
}

export function makePluginComputationFormula({
    pluginID,
    computationID,
    parameters,
    resultName,
    resultType,
}: PluginComputationSpecification): PluginComputationFormula {
    return {
        kind: FormulaKind.PluginComputation,
        pluginID: makeConstant(pluginID),
        computationID: makeConstant(computationID),
        parameters,
        resultName: makeConstant(resultName),
        resultType,
        resultTypeIsPrecise: true,
    };
}

function decomposePluginComputationFormula(formula: Formula): PluginComputationSpecification | undefined {
    if (formula.kind !== FormulaKind.PluginComputation) return undefined;
    const f = formula as PluginComputationFormula;

    if (f.pluginID.kind !== FormulaKind.Constant) return undefined;
    const pluginID = f.pluginID as ConstantFormula;
    if (typeof pluginID.value !== "string") return undefined;

    const computationIDFormula = f.computationID ?? (f as any).computationName;
    if (computationIDFormula.kind !== FormulaKind.Constant) return undefined;
    const computationID = computationIDFormula as ConstantFormula;
    if (typeof computationID.value !== "string") return undefined;

    if (f.resultName.kind !== FormulaKind.Constant) return undefined;
    const resultName = f.resultName as ConstantFormula;
    if (typeof resultName.value !== "string") return undefined;

    return {
        kind: SyntheticColumnKind.PluginComputation,
        pluginID: pluginID.value,
        computationID: computationID.value,
        parameters: f.parameters ?? {},
        resultName: resultName.value,
        resultType: f.resultType,
    };
}

export interface FilterSortLimitSpecification {
    readonly kind: SyntheticColumnKind.FilterSortLimit;
    readonly tableOrRelationColumn: TableOrRelationColumnOrActionNodeOutput;
    // The containing row will be referred to with the  "containing screen"
    // context.
    readonly filter: PredicateCombinationSpecification | undefined;
    readonly ordering: SortArrayTransform | TableOrderArrayTransform | undefined;
    readonly limit: number | undefined;
    readonly multiple: boolean;
}

export function makeFilterSortLimitFormula({
    tableOrRelationColumn,
    filter,
    ordering,
    limit,
    multiple,
}: FilterSortLimitSpecification): Formula {
    let orderings: Ordering[];
    if (ordering?.kind === ArrayTransformKind.Sort) {
        orderings = ordering.keys.map(k => ({
            sortKey: k.key,
            reverse: k.order === SortOrder.Descending,
        }));
    } else if (ordering?.kind === ArrayTransformKind.TableOrder) {
        orderings = [
            {
                sortKey: undefined,
                reverse: ordering.reverse,
            },
        ];
    } else {
        orderings = [];
    }

    const filterSortLimit = {
        kind: FormulaKind.FilterSortLimit,
        rows: makeTableOrRelationColumn(tableOrRelationColumn),
        predicate: definedMap(filter, makePredicateCombinationFormula),
        orderings,
        limit: definedMap(limit, makeConstant),
    } as FilterSortLimitFormula;

    if (multiple) {
        return filterSortLimit;
    } else {
        return {
            kind: FormulaKind.GetNth,
            array: filterSortLimit,
            index: makeConstant(0),
        } as GetNthFormula;
    }
}

function decomposeFilterSortLimitFormula(formula: Formula): FilterSortLimitSpecification | undefined {
    let multiple: boolean;

    if (formula.kind === FormulaKind.GetNth) {
        const getNth = formula as GetNthFormula;
        if (getNth.index.kind !== FormulaKind.Constant) return undefined;
        const index = getNth.index as ConstantFormula;
        if (index.value !== 0) return undefined;

        formula = getNth.array;
        multiple = false;
    } else {
        multiple = true;
    }

    if (formula.kind !== FormulaKind.FilterSortLimit) return undefined;
    const f = formula as FilterSortLimitFormula;

    const tableOrRelationColumn = decomposeTableOrRelationColumn(f.rows);
    if (tableOrRelationColumn === undefined) return undefined;

    const filter = definedMap(f.predicate, decomposePredicateCombinationFormula)?.spec;

    let ordering: SortArrayTransform | TableOrderArrayTransform | undefined;
    // NOTE: This only supports a single sort
    if (f.orderings.length > 0) {
        if (f.orderings[0].sortKey === undefined) {
            ordering = {
                kind: ArrayTransformKind.TableOrder,
                reverse: f.orderings[0].reverse,
            };
        } else {
            ordering = {
                kind: ArrayTransformKind.Sort,
                keys: [
                    {
                        key: f.orderings[0].sortKey,
                        order: f.orderings[0].reverse ? SortOrder.Descending : SortOrder.Ascending,
                    },
                ],
            };
        }
    }

    let limit: number | undefined;
    if (f.limit?.kind === FormulaKind.Constant) {
        const l = f.limit as ConstantFormula;
        if (typeof l.value === "number") {
            limit = l.value;
        }
    }

    return {
        kind: SyntheticColumnKind.FilterSortLimit,
        tableOrRelationColumn,
        filter,
        ordering,
        limit,
        multiple,
    };
}

const displayFormulaInputVariableName = "x";
export const containingScreenContextName = "screen";

const emptyDisplayFormula: GetVariableFormula = {
    kind: FormulaKind.GetVariable,
    name: displayFormulaInputVariableName,
};

export function makeEmptyDisplayFormulaForType(type: ColumnType): Formula | undefined {
    if (isPrimitiveType(type)) {
        return emptyDisplayFormula;
    } else {
        return undefined;
    }
}

export interface FormatNumberSpecification {
    readonly kind: ValueFormatKind.Number;
    readonly decimalsAfterPoint: number;
    readonly groupSeparator: boolean;
    readonly unit: string | undefined;
    readonly unitAfterNumber: boolean;
}

export const defaultFormatNumberSpecification: FormatNumberSpecification = {
    kind: ValueFormatKind.Number,
    decimalsAfterPoint: 2,
    groupSeparator: true,
    unit: undefined,
    unitAfterNumber: true,
};

function makeFormatNumberFormula({
    decimalsAfterPoint,
    groupSeparator,
    unit,
    unitAfterNumber,
}: FormatNumberSpecification): Formula {
    const number: FormatNumberFixedFormula = {
        kind: FormulaKind.FormatNumberFixed,
        value: {
            kind: FormulaKind.GetVariable,
            name: displayFormulaInputVariableName,
        } as GetVariableFormula,
        decimalsAfterPoint: makeConstant(decimalsAfterPoint),
        groupSeparator: makeConstant(groupSeparator),
        currency: undefined,
    };
    if (unit === undefined) {
        return number;
    }

    // Keep in sync with ##formatCurrency.
    if (unit === "$" && !unitAfterNumber) {
        return { ...number, currency: makeConstant("USD") } as FormatNumberFixedFormula;
    }

    return {
        kind: FormulaKind.IfThenElse,
        condition: {
            kind: FormulaKind.CheckValue,
            operator: UnaryPredicateFormulaOperator.IsNotEmpty,
            value: {
                kind: FormulaKind.GetVariable,
                name: displayFormulaInputVariableName,
            },
        },
        consequent: {
            kind: FormulaKind.TextTemplate,
            template: makeConstant(unitAfterNumber ? "NU" : "UN"),
            replacements: [
                {
                    pattern: makeConstant("N"),
                    replacement: number,
                },
                {
                    pattern: makeConstant("U"),
                    replacement: makeConstant(unit),
                },
            ],
        },
    } as IfThenElseFormula;
}

export function isGetDisplayFormulaInput(formula: Formula): boolean {
    return (
        formula.kind === FormulaKind.GetVariable &&
        (formula as GetVariableFormula).name === displayFormulaInputVariableName
    );
}

function decomposeFormatNumberFormula(formula: Formula): FormatNumberSpecification | undefined {
    function decomposeNumber(f: Formula): FormatNumberSpecification | undefined {
        if (f.kind !== FormulaKind.FormatNumberFixed) return undefined;
        const { value, decimalsAfterPoint, groupSeparator, currency } = f as FormatNumberFixedFormula;

        if (!isGetDisplayFormulaInput(value)) return undefined;

        if (decimalsAfterPoint.kind !== FormulaKind.Constant) return undefined;
        const dap = decimalsAfterPoint as ConstantFormula;
        if (typeof dap.value !== "number") return undefined;

        if (groupSeparator.kind !== FormulaKind.Constant) return undefined;
        const gs = groupSeparator as ConstantFormula;
        if (typeof gs.value !== "boolean") return undefined;

        const spec: FormatNumberSpecification = {
            kind: ValueFormatKind.Number,
            decimalsAfterPoint: dap.value,
            groupSeparator: gs.value,
            unit: undefined,
            unitAfterNumber: true,
        };

        if (currency !== undefined) {
            if (currency.kind !== FormulaKind.Constant || (currency as ConstantFormula).value !== "USD")
                return undefined;
            return {
                ...spec,
                unit: "$",
                unitAfterNumber: false,
            };
        }
        return spec;
    }

    if (formula.kind !== FormulaKind.IfThenElse) {
        return decomposeNumber(formula);
    }

    const { condition, consequent, alternative } = formula as IfThenElseFormula;
    if (condition.kind !== FormulaKind.CheckValue) return undefined;
    const c = condition as CheckValueFormula;
    if (c.operator !== UnaryPredicateFormulaOperator.IsNotEmpty) return undefined;
    if (!isGetDisplayFormulaInput(c.value)) return undefined;
    if (alternative !== undefined) return undefined;

    if (consequent.kind !== FormulaKind.TextTemplate) return undefined;
    const { template, replacements } = consequent as TextTemplateFormula;

    if (template.kind !== FormulaKind.Constant) return undefined;
    const t = template as ConstantFormula;

    let unitAfterNumber: boolean;
    if (t.value === "NU") {
        unitAfterNumber = true;
    } else if (t.value === "UN") {
        unitAfterNumber = false;
    } else {
        return undefined;
    }

    if (replacements.length !== 2) return undefined;
    const [numberReplacement, unitReplacement] = replacements;

    if (
        numberReplacement.pattern.kind !== FormulaKind.Constant ||
        (numberReplacement.pattern as ConstantFormula).value !== "N"
    )
        return undefined;
    const number = decomposeNumber(numberReplacement.replacement);
    if (number === undefined) return undefined;

    if (
        unitReplacement.pattern.kind !== FormulaKind.Constant ||
        (unitReplacement.pattern as ConstantFormula).value !== "U"
    )
        return undefined;
    if (unitReplacement.replacement.kind !== FormulaKind.Constant) return undefined;
    const ur = unitReplacement.replacement as ConstantFormula;
    if (typeof ur.value !== "string") return undefined;

    return {
        ...number,
        unit: ur.value,
        unitAfterNumber,
    };
}

export interface FormatDateTimeSpecification extends GlideDateTimeFormatSpecification {
    readonly kind: ValueFormatKind.DateTime;
    readonly timeZone: GlideDateTimeZone;
}

const defaultDateFormat = DateFormat.Medium;
const defaultTimeFormat = TimeFormat.WithoutSeconds;

export function makeDefaultFormatDateTimeSpecification(timeZone: GlideDateTimeZone): FormatDateTimeSpecification {
    return {
        kind: ValueFormatKind.DateTime,
        parts: DateTimeParts.DateTime,
        dateFormat: defaultDateFormat,
        timeFormat: defaultTimeFormat,
        timeZone,
    };
}

const defaultFormatDateSpecification: FormatDateTimeSpecification = {
    kind: ValueFormatKind.DateTime,
    parts: DateTimeParts.DateOnly,
    dateFormat: defaultDateFormat,
    timeFormat: defaultTimeFormat,
    timeZone: "agnostic",
};

function makeFormatDateTimeFormula({ parts, dateFormat, timeFormat, timeZone }: FormatDateTimeSpecification): Formula {
    return {
        kind: FormulaKind.FormatDateTime,
        value: {
            kind: FormulaKind.GetVariable,
            name: displayFormulaInputVariableName,
        },
        dateFormat: parts === DateTimeParts.TimeOnly ? undefined : makeConstant(dateFormat),
        timeFormat: parts === DateTimeParts.DateOnly ? undefined : makeConstant(timeFormat),
        timeZone: makeConstant(timeZone),
    } as FormatDateTimeFormula;
}

function decomposeFormatDateTimeFormula(formula: Formula): FormatDateTimeSpecification | undefined {
    if (formula.kind !== FormulaKind.FormatDateTime) return undefined;

    const { value, dateFormat, timeFormat, timeZone } = formula as FormatDateTimeFormula;

    if (
        value.kind !== FormulaKind.GetVariable ||
        (value as GetVariableFormula).name !== displayFormulaInputVariableName
    )
        return undefined;

    let date: DateFormat | undefined;
    let time: TimeFormat | undefined;

    if (dateFormat !== undefined) {
        if (dateFormat.kind !== FormulaKind.Constant) return undefined;
        date = (dateFormat as ConstantFormula).value as DateFormat;
    }

    if (timeFormat !== undefined) {
        if (timeFormat.kind !== FormulaKind.Constant) return undefined;
        time = (timeFormat as ConstantFormula).value as TimeFormat;
    }

    if (date === undefined && time === undefined) return undefined;

    const parts =
        date === undefined
            ? DateTimeParts.TimeOnly
            : time === undefined
            ? DateTimeParts.DateOnly
            : DateTimeParts.DateTime;

    if (timeZone !== undefined && timeZone.kind !== FormulaKind.Constant) return undefined;
    const timeZoneValue = (timeZone as ConstantFormula | undefined)?.value;

    return {
        kind: ValueFormatKind.DateTime,
        parts,
        dateFormat: date ?? defaultDateFormat,
        timeFormat: time ?? defaultTimeFormat,
        timeZone: timeZoneValue === "local" ? "local" : "agnostic",
    };
}

export interface FormatDurationSpecification {
    readonly kind: ValueFormatKind.Duration;
}

export const defaultFormatDurationSpecification: FormatDurationSpecification = {
    kind: ValueFormatKind.Duration,
};

function makeFormatDurationFormula(): Formula {
    return {
        kind: FormulaKind.FormatDuration,
        value: {
            kind: FormulaKind.GetVariable,
            name: displayFormulaInputVariableName,
        },
    } as FormatDurationFormula;
}

function decomposeFormatDurationFormula(formula: Formula): FormatDurationSpecification | undefined {
    if (formula.kind !== FormulaKind.FormatDuration) return undefined;

    const { value } = formula as FormatDurationFormula;

    if (
        value.kind !== FormulaKind.GetVariable ||
        (value as GetVariableFormula).name !== displayFormulaInputVariableName
    )
        return undefined;

    return { kind: ValueFormatKind.Duration };
}
interface FormatJSONSpecification {
    readonly kind: ValueFormatKind.JSON;
}

function makeFormatJSONFormula(): Formula {
    return {
        kind: FormulaKind.FormatJSON,
        value: {
            kind: FormulaKind.GetVariable,
            name: displayFormulaInputVariableName,
        },
    } as FormatJSONFormula;
}

function decomposeFormatJSONFormula(formula: Formula): FormatJSONSpecification | undefined {
    if (formula.kind !== FormulaKind.FormatJSON) return undefined;

    const { value } = formula as FormatJSONFormula;

    if (
        value.kind !== FormulaKind.GetVariable ||
        (value as GetVariableFormula).name !== displayFormulaInputVariableName
    )
        return undefined;

    return { kind: ValueFormatKind.JSON };
}

export type ValueFormatSpecification =
    | FormatNumberSpecification
    | FormatDateTimeSpecification
    | FormatDurationSpecification
    | FormatJSONSpecification;

export function makeFormatFormula(spec: ValueFormatSpecification): Formula {
    switch (spec.kind) {
        case ValueFormatKind.Number:
            return makeFormatNumberFormula(spec);
        case ValueFormatKind.DateTime:
            return makeFormatDateTimeFormula(spec);
        case ValueFormatKind.Duration:
            return makeFormatDurationFormula();
        case ValueFormatKind.JSON:
            return makeFormatJSONFormula();
        default:
            return assertNever(spec);
    }
}

export function decomposeFormatFormula(formula: Formula): ValueFormatSpecification | undefined {
    const number = decomposeFormatNumberFormula(formula);
    if (number !== undefined) return number;

    const dateTime = decomposeFormatDateTimeFormula(formula);
    if (dateTime !== undefined) return dateTime;

    const duration = decomposeFormatDurationFormula(formula);
    if (duration !== undefined) return duration;

    const json = decomposeFormatJSONFormula(formula);
    if (json !== undefined) return json;

    return undefined;
}

export function getDefaultFormatForTypeKind(
    kind: ColumnTypeKind,
    timeZone: GlideDateTimeZone
): ValueFormatSpecification | undefined {
    if (kind === "duration") return defaultFormatDurationSpecification;
    if (isNumberTypeKind(kind)) return defaultFormatNumberSpecification;
    if (kind === "date") return defaultFormatDateSpecification;
    if (isDateTimeTypeKind(kind)) return makeDefaultFormatDateTimeSpecification(timeZone);
    return undefined;
}

export function getDefaultDisplayFormulaForTypeKind(
    kind: ColumnTypeKind,
    timeZone: GlideDateTimeZone
): Formula | undefined {
    const spec = getDefaultFormatForTypeKind(kind, timeZone);
    if (spec === undefined) return undefined;
    return makeFormatFormula(spec);
}

// returns a column name
export function decomposeSortKey(key: Formula): string | undefined {
    if (key.kind !== FormulaKind.GetColumn) return undefined;
    const k = key as GetColumnFormula;
    if (k.contextName !== undefined) return undefined;
    return k.column;
}
