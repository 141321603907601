import * as glide from "@glide/plugins";

export default glide.makePack({
    name: "Glide Core Plugins",
    packID: "glideapps.com",
    author: {
        name: "Glide",
        description: "The best way to make apps.",
        homepage: "https://glideapps.com",
    },
    plugins: {
        "iframe-embed": async () => (await import("./plugins/iframe-embed")).plugin,
        mixpanel: async () => (await import("./plugins/mixpanel")).plugin,
        segment: async () => (await import("./plugins/segment")).plugin,
        "open-ai": async () => (await import("./plugins/openai")).plugin,
        "open-ai-v2": async () => (await import("./plugins/openai")).pluginV2,
        "slack-bot": async () => (await import("./plugins/slack-bot")).plugin,
        twilio: async () => (await import("./plugins/twilio")).plugin,
        discord: async () => (await import("./plugins/discord")).plugin,
        gravatar: async () => (await import("./plugins/gravatar")).plugin,
        giphy: async () => (await import("./plugins/giphy")).plugin,
        intercom: async () => (await import("./plugins/intercom")).plugin,
        "meta-pixel": async () => (await import("./plugins/meta-pixel")).plugin,
        urlbox: async () => (await import("./plugins/urlbox")).plugin,
        files: async () => (await import("./plugins/files")).plugin,
        gtag: async () => (await import("./plugins/gtag")).plugin,
        "google-analytics-non-ua": async () => (await import("./plugins/google-analytics-non-ua")).plugin,
        zendesk: async () => (await import("./plugins/zendesk")).plugin,
        clearbit: async () => (await import("./plugins/clearbit")).plugin,
        "glide-ai": async () => (await import("./plugins/glide-ai")).plugin,
        "glide-api": async () => (await import("./plugins/glide-api")).plugin,
        docsautomator: async () => (await import("./plugins/docsautomator")).plugin,
        "generate-csv": async () => (await import("./plugins/generate-csv")).plugin,
        "google-maps": async () => (await import("./plugins/google-maps")).plugin,
        "microsoft-teams": async () => (await import("./plugins/microsoft-teams")).plugin,
        "push-notifications": async () => (await import("./plugins/push-notifications")).plugin,
        "device-features": async () => (await import("./plugins/device-features")).plugin,
        "pdf-moneky": async () => (await import("./plugins/pdf-monkey")).plugin, // Yes this is misspelled - we need to keep this scar forever
        "data-structures": async () => (await import("./plugins/data-structures")).plugin,
        radar: async () => (await import("./plugins/radar")).plugin,
        replicate: async () => (await import("./plugins/replicate")).plugin,
        yelp: async () => (await import("./plugins/yelp")).plugin,
        "generic-api": async () => (await import("./plugins/generic-api/generic-api")).plugin,
        json: async () => (await import("./plugins/JSON/json-plugin")).plugin,
        "native-barcode-scanner": async () => (await import("./plugins/native-barcode-scanner")).plugin,
        "trigger-webhook": async () => (await import("./plugins/trigger-webhook/trigger-webhook")).plugin,
        opengraph: async () => (await import("./plugins/opengraph")).plugin,
        elevenlabs: async () => (await import("./plugins/elevenlabs")).plugin,
        zenrows: async () => (await import("./plugins/zenrows")).plugin,
        koala: async () => (await import("./plugins/koala")).plugin,
        pinecone: async () => (await import("./plugins/pinecone")).plugin,
        xml: async () => (await import("./plugins/xml")).plugin,
        "app-special-values": async () => (await import("./plugins/app-special-values")).plugin,
        posthog: async () => (await import("./plugins/posthog")).plugin,
    },
});
