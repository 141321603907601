import { ArrayMap } from "@glide/support";
import { type TableName, areTableNamesEqual } from "@glide/type-schema";
import type { TableKeeper } from "./table-keeper";
import type { TableKeeperStore } from "./table-keeper-store";

export class TableKeeperStoreImpl<T extends TableKeeper> implements TableKeeperStore<T> {
    private readonly _forTable = new ArrayMap<TableName, T>(areTableNamesEqual);

    constructor(private readonly _makeKeeper: (tn: TableName) => T) {}

    public getTableKeeperForTable(tn: TableName): T {
        return this._forTable.getOrSet(tn, () => this._makeKeeper(tn));
    }
}
