import { assert, assertNever } from "@glideapps/ts-necessities";
import { produce } from "immer";
import type { EminenceFlags } from "@glide/billing-types";
import type { UserFeatures } from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import {
    type AppTrialInfo,
    type Owner,
    type UserData,
    daysLeftInTrial,
    daysSinceTrialStart,
    fourteenDayMax,
    getAppTrialInfo,
} from "../Database";
import {
    type EminenceFlagsThatUsersCanUpgradeFor,
    eminenceOrgInternal,
    eminenceOrgPublic,
    eminencePro,
    freeFlags,
    freePageFlags,
    maxQuotaNumber,
    unlimitedEminenceFlags,
} from "../Database/eminence";
import { OwnerKind } from "../Database/owner-kind";
import { QuotaKind } from "../Database/quotas";
import { getFeatureSetting, getIsV3FreePlan } from "../feature-settings";
import {
    type Subscription,
    type SubscriptionAddon,
    AddonKind,
    PlanKind,
    addonIsAppPlan,
    isSubscriptionComplete,
    planKindIsUpgradeLocked,
} from "./subscriptions";

export function getFirstUpgradeablePlanSatisfying(
    requirement: (e: EminenceFlagsThatUsersCanUpgradeFor) => boolean
): PlanKind {
    for (const plan of Object.values(PlanKind)) {
        if (plan === PlanKind.Free) {
            continue;
        }
        if (planKindIsUpgradeLocked(plan)) {
            continue;
        }
        if (requirement(eminenceFlagsThatUsersCanUpgradeForByPlan(plan))) {
            return plan;
        }
    }
    throw new Error(`No plan satisfies the requirement`);
}

function shouldForbidEminence(subscriptionStatus: string) {
    // For, we'll call it "customer support reasons", we explicitly want to grant paid Eminence to apps governed by
    // subscriptions which are past due, but not yet canceled.
    return subscriptionStatus !== "past_due" && !isSubscriptionComplete(subscriptionStatus);
}

type EminenceFlagsTransformer = (flags: EminenceFlags) => EminenceFlags;

/*
 * This function applies a number of flags => flags transforms on the provided flags in the given order.
 * A call to
 *   transformFlags(flags, applyA, applyB, applyC);
 * is equivalent to
 *   applyC(applyB(applyA(flags)));
 * This was written so that a chain of simple flag transforms made more sense to read.
 *
 * As a side note, I really wish Typescript had curry-by-default.
 *
 * - Victoria
 */
function transformFlags(flags: EminenceFlags, ...f: EminenceFlagsTransformer[]): EminenceFlags {
    return f.reduce<EminenceFlags>((draft, transformer) => transformer(draft), flags);
}

function applyV2BasicFlags(flags: EminenceFlags): EminenceFlags {
    return {
        ...flags,
        isFreeEminence: false,
        isV1Pro: false,
        needsBuilderModifyAppFeatures: false,
        removeBranding: false,
        lowTransactionFees: false,
        isUserMetered: false,
        googleSharedDrive: false,
        confirmChangeAuthOnUpgrade: true,
        customDomains: false,
        badge: "Basic",
        automaticRefresh: false,
        googleAnalytics: false,
        canAccessAnalytics: false,
        unlimitedZapier: false,
        signInAgreements: false,
        customSignInAppearance: false,
        authenticationSupport: {
            public: true,
            publicWithEmail: true,
            publicDisabled: false,
            password: false,
            emailWhitelist: false,
            userProfiles: false,
            allowedEmailDomains: true,
            organizationMembers: false,
            justMe: false,
            optionalPublicWithEmail: true,
            optionalOtherEmail: false,
        },
        upgradeButton: true,
        proComponents: false,
        proIcons: false,
        canDisableShare: false,
        buyButton: true,
        canUpgrade: false,
        canDowngrade: false,
        canTransferBetweenUsers: false,
        canTransferToOrg: true,
        canPausePublish: false,
        canUseSignInWithGoogle: true,
        downgradeBeforeDelete: true,
        downgradeBeforeDeleteStripeCancellation: undefined,
        logOrgLogin: false,
        canSubmitTemplate: true,
        needsPublishableOrgToTransfer: false,
        transferToOrgAs: "public",
        showQuotaBanners: true,
        unlimitedPasswordAndTeamMembersOnlyUsers: false,
        proDisplayQuotas: false,
        tabletMode: false,
        showReportApp: true,
        roles: false,
        rowQuotasIncludeBigTables: false,
        protectedColumns: false,
        allowRealEmails: true,
        stripeTransactionFeePercentage: 5,
        base64EncodePublicUserProfiles: true,
        canChangeAuthType: true,
        isPrivateProTrial: false,
        appLoginsSheet: true,
        supportsQuotaUpgrades: true,
        maxSingleUploadBytes: 1024 * 1024 * 1024,
        quotas: {
            ...flags.quotas,
            [QuotaKind.BarcodesScanned]: { prepaidUsage: 100, maxOverage: 0 },
            [QuotaKind.AirtableRowsUsed]: { prepaidUsage: 10, maxOverage: 0 },
            [QuotaKind.FileBytesUsed]: { prepaidUsage: 2 * 1000 * 1000 * 1000, maxOverage: 0 },
            [QuotaKind.Geocodes]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
            [QuotaKind.Reloads]: { prepaidUsage: 5000, maxOverage: 0 },
            [QuotaKind.RowsUsed]: { prepaidUsage: 5000, maxOverage: 0 },
            [QuotaKind.Signatures]: { prepaidUsage: 50, maxOverage: 0 },
            [QuotaKind.ZapsAndWebhook]: { prepaidUsage: 500, maxOverage: 0 },
        },
        canAppUserRequestAccess: false,
        appUserRequestAccessInPrivateProjectsDefault: true,
        virtualEmailAddressesInPublicProjectsDefault: false,
        pluginTier: "starter",
        canCustomizeSignIn: false,
    };
}

function applyV2ProFlags(flags: EminenceFlags): EminenceFlags {
    return {
        ...applyV2BasicFlags(flags),
        removeBranding: true,
        lowTransactionFees: true,
        automaticRefresh: true,
        signInAgreements: true,
        customSignInAppearance: true,
        customDomains: true,
        badge: "Pro",
        googleAnalytics: true,
        canAccessAnalytics: false,
        unlimitedZapier: true,
        showQuotaBanners: false,
        tabletMode: true,
        proDisplayQuotas: true,
        showReportApp: false,
        stripeTransactionFeePercentage: 2,
        upgradeButton: false,
        allowRealEmails: true,
        base64EncodePublicUserProfiles: true,
        canPausePublish: true,
        googleSharedDrive: true,
        proComponents: true,
        proIcons: true,
        canDisableShare: true,
        protectedColumns: true,
        quotas: {
            ...flags.quotas,
            [QuotaKind.BarcodesScanned]: { prepaidUsage: 1000, maxOverage: 0 },
            [QuotaKind.AirtableRowsUsed]: { prepaidUsage: 10, maxOverage: 0 },
            [QuotaKind.FileBytesUsed]: { prepaidUsage: 10 * 1000 * 1000 * 1000, maxOverage: maxQuotaNumber },
            [QuotaKind.Geocodes]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
            [QuotaKind.Reloads]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
            [QuotaKind.RowsUsed]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
            [QuotaKind.Signatures]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
            [QuotaKind.ZapsAndWebhook]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
            [QuotaKind.MapPins]: { prepaidUsage: 1000, maxOverage: maxQuotaNumber },
        },
        apiCapabilities: {
            mutateTables: true,
            queryTables: false,
            requestReload: false,
            createAnonymousUser: false,
        },
        virtualEmailAddressesInPublicProjectsDefault: false,
        pluginTier: getFeatureSetting("proV2OnlyHasFreeTierPlugins") ? "free" : "pro",
        canCustomizeSignIn: true,
    };
}

function applyV2PrivateFlags(flags: EminenceFlags): EminenceFlags {
    return {
        ...flags,
        isUserMetered: true,
        confirmChangeAuthOnUpgrade: false,
        authenticationSupport: {
            public: false,
            publicWithEmail: false,
            publicDisabled: false,
            password: true,
            emailWhitelist: true,
            userProfiles: true,
            allowedEmailDomains: true,
            organizationMembers: false,
            justMe: true,
            optionalPublicWithEmail: false,
            optionalOtherEmail: false,
        },
        unlimitedPasswordAndTeamMembersOnlyUsers: true,
        transferToOrgAs: "internal",
        roles: true,
        base64EncodePublicUserProfiles: false,
        quotas: {
            ...flags.quotas,
            [QuotaKind.PrivateUsers]: { prepaidUsage: 20, maxOverage: maxQuotaNumber },
        },
        canAppUserRequestAccess: true,
        virtualEmailAddressesInPublicProjectsDefault: false,
    };
}

// The V2 Basic plan doesn't come with 1000 emails, but the rest do.  We have to make this additional application in all
// other cases except for V2 Basic.
function applyV2PrivateEmailFlags(flags: EminenceFlags): EminenceFlags {
    return {
        ...flags,
        quotas: {
            ...flags.quotas,
            [QuotaKind.DeliverEmail]: { prepaidUsage: 1000, maxOverage: 0 },
        },
    };
}

// Pro private pages have tons of differences from Pro Private apps.
function applyV2ProPrivatePageFlags(flags: EminenceFlags): EminenceFlags {
    return produce(flags, draft => {
        draft.allowRealEmails = true;
        draft.authenticationSupport = {
            public: false,
            publicWithEmail: true,
            publicDisabled: true,
            password: false,
            emailWhitelist: false,
            userProfiles: true,
            allowedEmailDomains: true,
            organizationMembers: true,
            justMe: false,
            optionalPublicWithEmail: true,
            optionalOtherEmail: true,
        };
        draft.appLoginsSheet = false;
        draft.supportsQuotaUpgrades = false;
        draft.removeBranding = false;
    });
}

function applyAppTrialFlags(flags: EminenceFlags, trialInfo: AppTrialInfo): EminenceFlags {
    const { trialType } = trialInfo;
    return produce(transformFlags(flags, applyV2ProFlags, applyV2PrivateFlags, applyV2PrivateEmailFlags), draft => {
        switch (trialType) {
            case "private-pro":
                draft.displayName = "Private Pro Trial";
                draft.badge = "Private Pro Trial";
                draft.canChangeAuthType = false;
                draft.downgradeBeforeDelete = false;
                draft.canUpgrade = true;
                draft.upgradeButton = true;
                draft.isPrivateProTrial = true;
                draft.authenticationSupport = {
                    ...draft.authenticationSupport,
                    optionalPublicWithEmail: false,
                };
                break;
            default:
                assertNever(trialType, "trialType is not explicitly handled in applyAppTrialFlags");
        }
    });
}

function applyExpiredTrialFlags(flags: EminenceFlags): EminenceFlags {
    return produce(flags, draft => {
        // This is important for locking trial access in app features.
        draft.isPrivateProTrial = true;
    });
}

function applyV3FreeFlags(flags: EminenceFlags): EminenceFlags {
    return {
        ...flags,
        isFreeEminence: true,
        isV1Pro: false,
        needsBuilderModifyAppFeatures: false,
        removeBranding: false,
        lowTransactionFees: true,
        isUserMetered: true,
        googleSharedDrive: true,
        confirmChangeAuthOnUpgrade: false,
        customDomains: false,
        badge: undefined,
        automaticRefresh: false,
        googleAnalytics: false,
        canAccessAnalytics: false,
        unlimitedZapier: false,
        signInAgreements: false,
        customSignInAppearance: false,
        authenticationSupport: {
            public: true,
            publicWithEmail: true,
            // This is a hack to allow all Pages to have publicDisabled.
            publicDisabled: flags.authenticationSupport.publicDisabled,
            password: false,
            emailWhitelist: true,
            userProfiles: true,
            allowedEmailDomains: true,
            organizationMembers: true,
            justMe: false,
            optionalPublicWithEmail: true,
            optionalOtherEmail: true,
        },
        upgradeButton: true,
        proComponents: false,
        proIcons: true,
        canDisableShare: false,
        buyButton: false,
        canUpgrade: true,
        canDowngrade: false,
        canTransferBetweenUsers: false,
        canTransferToOrg: true,
        canPausePublish: false,
        canUseSignInWithGoogle: true,
        downgradeBeforeDelete: false,
        downgradeBeforeDeleteStripeCancellation: undefined,
        logOrgLogin: false,
        canSubmitTemplate: true,
        needsPublishableOrgToTransfer: false,
        transferToOrgAs: "free",
        showQuotaBanners: true,
        unlimitedPasswordAndTeamMembersOnlyUsers: false,
        proDisplayQuotas: false,
        tabletMode: true,
        showReportApp: true,
        roles: true,
        rowQuotasIncludeBigTables: false,
        protectedColumns: false,
        allowRealEmails: "private",
        stripeTransactionFeePercentage: 0,
        base64EncodePublicUserProfiles: true,
        canChangeAuthType: true,
        isPrivateProTrial: false,
        appLoginsSheet: true,
        supportsQuotaUpgrades: true,
        maxSingleUploadBytes: 1024 * 1024 * 1024,
        quotas: {
            ...flags.quotas,
            [QuotaKind.BarcodesScanned]: { prepaidUsage: 0, maxOverage: 0 },
            [QuotaKind.AirtableRowsUsed]: { prepaidUsage: 500, maxOverage: 0 },
            [QuotaKind.FileBytesUsed]: { prepaidUsage: 200 * 1024 * 1024, maxOverage: 0 },
            [QuotaKind.Geocodes]: { prepaidUsage: 10, maxOverage: 0 },
            [QuotaKind.Reloads]: { prepaidUsage: 1000, maxOverage: maxQuotaNumber },
            [QuotaKind.RowsUsed]: { prepaidUsage: 500, maxOverage: 0 },
            [QuotaKind.Signatures]: { prepaidUsage: 0, maxOverage: 0 },
            [QuotaKind.ZapsAndWebhook]: { prepaidUsage: maxQuotaNumber, maxOverage: 0 },
            [QuotaKind.PublicUsers]: { prepaidUsage: 10, maxOverage: 0 },
            [QuotaKind.PrivateUsers]: { prepaidUsage: 3, maxOverage: 0 },
            [QuotaKind.AppEditors]: { prepaidUsage: 2, maxOverage: 0 },
            [QuotaKind.Updates]: { prepaidUsage: 1000, maxOverage: 0 },
            [QuotaKind.PublishedApps]: { prepaidUsage: 3, maxOverage: 0 },
        },
        quotaContext: "org",
        shouldUseNewPrivacy: true,
        quotaEnforcementScheme: "free",
        canAppUserRequestAccess: "private",
        appUserRequestAccessInPrivateProjectsDefault: true,
        virtualEmailAddressesInPublicProjectsDefault: true,
        pluginTier: "free",
        canCustomizeSignIn: false,
    };
}

function applyV3StarterFlags(flags: EminenceFlags, planIteration: number): EminenceFlags {
    return produce(flags, draft => {
        draft.displayName = "Starter";
        draft.isFreeEminence = false;
        draft.isV1Pro = false;
        draft.needsBuilderModifyAppFeatures = false;
        draft.removeBranding = false;
        draft.lowTransactionFees = true;
        draft.isUserMetered = true;
        draft.googleSharedDrive = true;
        draft.confirmChangeAuthOnUpgrade = false;
        draft.customDomains = true;
        draft.badge = "Starter";
        draft.automaticRefresh = true;
        draft.googleAnalytics = false;
        draft.canAccessAnalytics = false;
        draft.unlimitedZapier = false;
        draft.signInAgreements = false;
        draft.customSignInAppearance = true;
        draft.authenticationSupport.public = true;
        draft.authenticationSupport.publicWithEmail = true;
        draft.authenticationSupport.password = false;
        draft.authenticationSupport.emailWhitelist = true;
        draft.authenticationSupport.userProfiles = true;
        draft.authenticationSupport.allowedEmailDomains = true;
        draft.authenticationSupport.organizationMembers = true;
        draft.authenticationSupport.justMe = false;
        draft.authenticationSupport.optionalPublicWithEmail = true;
        draft.authenticationSupport.optionalOtherEmail = true;
        draft.upgradeButton = false;
        draft.proComponents = true;
        draft.proIcons = true;
        draft.canDisableShare = true;
        draft.buyButton = true;
        draft.canUpgrade = true;
        draft.canDowngrade = true;
        draft.canTransferBetweenUsers = false;
        draft.canTransferToOrg = true;
        draft.canPausePublish = true;
        draft.canUseSignInWithGoogle = true;
        draft.downgradeBeforeDelete = false;
        draft.downgradeBeforeDeleteStripeCancellation = undefined;
        draft.logOrgLogin = true;
        draft.canSubmitTemplate = true;
        draft.needsPublishableOrgToTransfer = false;
        draft.transferToOrgAs = "public";
        draft.showQuotaBanners = true;
        draft.unlimitedPasswordAndTeamMembersOnlyUsers = true;
        draft.proDisplayQuotas = false;
        draft.tabletMode = true;
        draft.showReportApp = true;
        draft.roles = true;
        draft.protectedColumns = false;
        draft.allowRealEmails = true;
        draft.stripeTransactionFeePercentage = 0;
        draft.base64EncodePublicUserProfiles = true;
        draft.canChangeAuthType = true;
        draft.isPrivateProTrial = false;
        draft.appLoginsSheet = true;
        draft.supportsQuotaUpgrades = true;
        draft.maxSingleUploadBytes = 1024 * 1024 * 1024;
        draft.quotas[QuotaKind.AirtableRowsUsed].prepaidUsage = 5000;
        draft.quotas[QuotaKind.AirtableRowsUsed].maxOverage = 0;
        draft.quotas[QuotaKind.AppEditors].prepaidUsage = 5;
        draft.quotas[QuotaKind.AppEditors].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.BarcodesScanned].prepaidUsage = 0;
        draft.quotas[QuotaKind.BarcodesScanned].maxOverage = 0;
        draft.quotas[QuotaKind.DeliverEmail].prepaidUsage = 1000;
        draft.quotas[QuotaKind.DeliverEmail].maxOverage = 0;
        draft.quotas[QuotaKind.FileBytesUsed].prepaidUsage = 5 * 1024 * 1024 * 1024;
        draft.quotas[QuotaKind.FileBytesUsed].maxOverage = 0;
        draft.quotas[QuotaKind.Geocodes].prepaidUsage = 10_000;
        draft.quotas[QuotaKind.Geocodes].maxOverage = 0;
        draft.quotas[QuotaKind.MapPins].prepaidUsage = 50;
        draft.quotas[QuotaKind.MapPins].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.PrivateUsers].prepaidUsage = 15;
        draft.quotas[QuotaKind.PrivateUsers].maxOverage = 0;

        // TODO these limits need to be lower, and the maxOverage should be 0(?)
        draft.quotas[QuotaKind.PublicUsers].prepaidUsage = 1000;
        draft.quotas[QuotaKind.PublicUsers].maxOverage = maxQuotaNumber;

        draft.quotas[QuotaKind.PublishedApps].prepaidUsage = 5;
        draft.quotas[QuotaKind.PublishedApps].maxOverage = 0;
        draft.quotas[QuotaKind.Reloads].prepaidUsage = 2500;
        draft.quotas[QuotaKind.Reloads].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.RowsUsed].prepaidUsage = 5000;
        draft.quotas[QuotaKind.RowsUsed].maxOverage = 0;
        draft.quotas[QuotaKind.Signatures].prepaidUsage = 0;
        draft.quotas[QuotaKind.Signatures].maxOverage = 0;
        draft.quotas[QuotaKind.Updates].prepaidUsage = 2500;
        draft.quotas[QuotaKind.Updates].maxOverage = 0;
        draft.quotas[QuotaKind.ZapsAndWebhook].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.ZapsAndWebhook].maxOverage = 0;
        draft.quotaContext = "org";
        draft.shouldUseNewPrivacy = true;
        // This is not a typo.
        // "free" means "use the same enforcement mechanisms as the
        // free plan", which is what has been requested.
        draft.quotaEnforcementScheme = "free";
        draft.canAppUserRequestAccess = "private";
        draft.appUserRequestAccessInPrivateProjectsDefault = true;
        draft.virtualEmailAddressesInPublicProjectsDefault = false;
        draft.pluginTier = "starter";
        switch (planIteration) {
            case 0:
                break;
            case 1:
                draft.quotas[QuotaKind.PrivateUsers].prepaidUsage = 10;
                break;
            default:
                break;
        }
    });
}

function applyV3ProFlags(flags: EminenceFlags, planIteration: number): EminenceFlags {
    return produce(applyV3StarterFlags(flags, planIteration), draft => {
        draft.displayName = "Pro";
        draft.lowTransactionFees = true;
        draft.includedWhitelabels = 3;
        draft.automaticRefresh = true;
        draft.signInAgreements = true;
        draft.customSignInAppearance = true;
        draft.customDomains = true;
        draft.badge = "Pro";
        draft.googleAnalytics = true;
        draft.canAccessAnalytics = false;
        draft.unlimitedZapier = false;
        draft.showQuotaBanners = true;
        draft.tabletMode = true;
        draft.proDisplayQuotas = true;
        draft.showReportApp = true;
        draft.stripeTransactionFeePercentage = 0;
        draft.upgradeButton = false;
        draft.allowRealEmails = true;
        draft.base64EncodePublicUserProfiles = true;
        draft.canPausePublish = true;
        draft.googleSharedDrive = true;
        draft.proComponents = true;
        draft.proIcons = true;
        draft.canDisableShare = true;
        draft.protectedColumns = true;
        draft.quotas[QuotaKind.AirtableRowsUsed].prepaidUsage = 25000;
        draft.quotas[QuotaKind.AirtableRowsUsed].maxOverage = 0;
        draft.quotas[QuotaKind.AppEditors].prepaidUsage = 10;
        draft.quotas[QuotaKind.AppEditors].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.BarcodesScanned].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.BarcodesScanned].maxOverage = 0;
        draft.quotas[QuotaKind.DeliverEmail].prepaidUsage = 1000;
        draft.quotas[QuotaKind.DeliverEmail].maxOverage = 0;
        draft.quotas[QuotaKind.FileBytesUsed].prepaidUsage = 50 * 1024 * 1024 * 1024;
        draft.quotas[QuotaKind.FileBytesUsed].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.Geocodes].prepaidUsage = 150_000;
        draft.quotas[QuotaKind.Geocodes].maxOverage = 0;
        draft.quotas[QuotaKind.MapPins].prepaidUsage = 1000;
        draft.quotas[QuotaKind.MapPins].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.PrivateUsers].prepaidUsage = 100;
        draft.quotas[QuotaKind.PrivateUsers].maxOverage = 0;

        // TODO these limits need to be lower, and the maxOverage should be 0(?)
        draft.quotas[QuotaKind.PublicUsers].prepaidUsage = 5000;
        draft.quotas[QuotaKind.PublicUsers].maxOverage = maxQuotaNumber;

        draft.quotas[QuotaKind.PublishedApps].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.PublishedApps].maxOverage = 0;
        draft.quotas[QuotaKind.Reloads].prepaidUsage = 10000;
        draft.quotas[QuotaKind.Reloads].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.RowsUsed].prepaidUsage = 25000;
        draft.quotas[QuotaKind.RowsUsed].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.Signatures].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.Signatures].maxOverage = 0;
        draft.quotas[QuotaKind.Updates].prepaidUsage = 10000;
        draft.quotas[QuotaKind.Updates].maxOverage = 0;
        draft.quotas[QuotaKind.ZapsAndWebhook].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.ZapsAndWebhook].maxOverage = 0;
        draft.apiCapabilities.mutateTables = true;
        draft.apiCapabilities.queryTables = false;
        draft.apiCapabilities.requestReload = false;
        draft.apiCapabilities.createAnonymousUser = false;
        draft.quotaEnforcementScheme = "paid";
        draft.pluginTier = "pro";
        draft.canCreateSupportTicket = true;
        draft.canCustomizeSignIn = true;
        switch (planIteration) {
            case 0:
                break;
            case 1:
                draft.quotas[QuotaKind.PrivateUsers].prepaidUsage = 50;
                break;
            default:
                break;
        }
    });
}

// NOTE: When adding new features that are exclusive to the business plan,
// please consider whether or not the feature should be made available
// to nonprofit customers as well. If not, be sure to make the correct
// changes to `applyV3NonProfitFlags()`.
function applyV3BusinessFlags(flags: EminenceFlags, planIteration: number): EminenceFlags {
    return produce(applyV3ProFlags(flags, planIteration), draft => {
        draft.displayName = "Business";
        draft.lowTransactionFees = true;
        draft.includedWhitelabels = 5;
        draft.automaticRefresh = true;
        draft.signInAgreements = true;
        draft.customSignInAppearance = true;
        draft.customDomains = true;
        draft.badge = "Business";
        draft.googleAnalytics = true;
        draft.canAccessAnalytics = true;
        draft.unlimitedZapier = false;
        draft.showQuotaBanners = true;
        draft.tabletMode = true;
        draft.proDisplayQuotas = true;
        draft.showReportApp = true;
        draft.stripeTransactionFeePercentage = 0;
        draft.upgradeButton = false;
        draft.allowRealEmails = true;
        draft.base64EncodePublicUserProfiles = true;
        draft.canPausePublish = true;
        draft.googleSharedDrive = true;
        draft.proComponents = true;
        draft.proIcons = true;
        draft.canDisableShare = true;
        draft.protectedColumns = true;
        draft.offlineActionQueue = true;
        draft.pagesCustomCss = true;
        draft.quotas[QuotaKind.AirtableRowsUsed].prepaidUsage = 25000;
        draft.quotas[QuotaKind.AirtableRowsUsed].maxOverage = 0;
        draft.quotas[QuotaKind.AppEditors].prepaidUsage = 25;
        draft.quotas[QuotaKind.AppEditors].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.BarcodesScanned].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.BarcodesScanned].maxOverage = 0;
        draft.quotas[QuotaKind.DeliverEmail].prepaidUsage = 1000;
        draft.quotas[QuotaKind.DeliverEmail].maxOverage = 0;
        draft.quotas[QuotaKind.FileBytesUsed].prepaidUsage = 1024 * 1024 * 1024 * 1024;
        draft.quotas[QuotaKind.FileBytesUsed].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.Geocodes].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.Geocodes].maxOverage = 0;
        draft.quotas[QuotaKind.MapPins].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.MapPins].maxOverage = 0;
        draft.quotas[QuotaKind.PrivateUsers].prepaidUsage = 250;
        draft.quotas[QuotaKind.PrivateUsers].maxOverage = 0;
        draft.quotas[QuotaKind.PublicUsers].prepaidUsage = 10000;
        draft.quotas[QuotaKind.PublicUsers].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.PublishedApps].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.PublishedApps].maxOverage = 0;
        draft.quotas[QuotaKind.Reloads].prepaidUsage = 50000;
        draft.quotas[QuotaKind.Reloads].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.RowsUsed].prepaidUsage = 25000;
        draft.quotas[QuotaKind.RowsUsed].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.Signatures].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.Signatures].maxOverage = maxQuotaNumber;
        draft.quotas[QuotaKind.Updates].prepaidUsage = 50000;
        draft.quotas[QuotaKind.Updates].maxOverage = 0;
        draft.quotas[QuotaKind.ZapsAndWebhook].prepaidUsage = maxQuotaNumber;
        draft.quotas[QuotaKind.ZapsAndWebhook].maxOverage = 0;
        draft.apiCapabilities.mutateTables = true;
        draft.apiCapabilities.queryTables = true;
        draft.apiCapabilities.requestReload = false;
        draft.apiCapabilities.createAnonymousUser = false;
        draft.quotaEnforcementScheme = "paid";
        draft.gmailForSendEmails = true;
        draft.pluginTier = "business";
        draft.canCreateSupportTicket = true;
        draft.bigQuery = true;
        draft.bigTables = true;
        draft.canCustomizeSignIn = true;
        switch (planIteration) {
            case 0:
                break;
            case 1:
                draft.quotas[QuotaKind.PrivateUsers].prepaidUsage = 100;
                draft.quotas[QuotaKind.Updates].prepaidUsage = 25000;
                break;
            default:
                break;
        }
    });
}

function applyV3NonProfitFlags(flags: EminenceFlags, planIteration: number): EminenceFlags {
    return produce(applyV3BusinessFlags(flags, planIteration), draft => {
        draft.displayName = "Non-Profit";
        draft.badge = "Non-profit";
        draft.includedWhitelabels = 0;
        draft.removeBranding = false;
        draft.pluginTier = "education" as const;
        draft.quotaEnforcementScheme = "free";
        draft.pagesCustomCss = false;
        draft.offlineActionQueue = false;
        draft.quotas[QuotaKind.PrivateUsers].prepaidUsage = 100;
        draft.quotas[QuotaKind.PrivateUsers].maxOverage = 0;
        draft.quotas[QuotaKind.RowsUsed].prepaidUsage = 25000;
        draft.quotas[QuotaKind.RowsUsed].maxOverage = 0;
        draft.quotas[QuotaKind.Updates].prepaidUsage = 25000;
        draft.quotas[QuotaKind.Updates].maxOverage = 0;
        draft.apiCapabilities.queryTables = false;
    });
}

function applyV3EnterpriseBaseNoUPFlags(flags: EminenceFlags, _planIteration: number): EminenceFlags {
    return produce(flags, draft => {
        draft.quotaEnforcementScheme = "paid";
        draft.quotas[QuotaKind.PrivateUsers].prepaidUsage = 0;
        draft.quotas[QuotaKind.PrivateUsers].maxOverage = 0;
        draft.quotas[QuotaKind.Updates].prepaidUsage = 0;
        draft.quotas[QuotaKind.Updates].maxOverage = 0;
    });
}

// Certain user features should override Eminence. Instead of checking both, we'll just... override Eminence!
function applyUserFeatures(flags: EminenceFlags, features: UserFeatures | undefined): EminenceFlags {
    if (features === undefined) return flags;
    return produce(flags, draft => {
        if (features.removeBranding === true) {
            draft.removeBranding = true;
        }
        if (features.offlineActionQueue === true) {
            draft.offlineActionQueue = true;
        }
        if (features.unlimitedBarcodeScanning === true || features.dbrScannerHandshake !== undefined) {
            draft.quotas[QuotaKind.BarcodesScanned].prepaidUsage = maxQuotaNumber;
        }
        if (features.pagesCustomCss === true) {
            draft.pagesCustomCss = true;
        }
        if (features.authenticationSupportPassword === true) {
            draft.authenticationSupport.password = true;
        }
        if (features.authenticationSupportEmailWhitelist === true) {
            draft.authenticationSupport.emailWhitelist = true;
        }
    });
}

function applyOwnerFlags(
    flags: EminenceFlags,
    owner: Owner,
    admin: UserData | undefined,
    appPlanKind: AddonKind | undefined
): EminenceFlags {
    return produce(flags, draft => {
        if (owner.kind === OwnerKind.Organization) {
            switch (appPlanKind) {
                // In this case, `undefined` means a Free App/Page.
                case undefined:
                case AddonKind.V1ProApp:
                case AddonKind.V2BasicPrivateApp:
                case AddonKind.V2ProPrivateApp:
                    draft.authenticationSupport.organizationMembers = true;
                    draft.authenticationSupport.justMe = false;
                    break;
                case AddonKind.V2BasicPublicApp:
                case AddonKind.V2ProPublicApp:
                    draft.authenticationSupport.organizationMembers = false;
                    draft.authenticationSupport.justMe = false;
                    break;
                default:
                    break;
            }
        }

        // Only apps on free plans (free apps prior to V3 Pricing) should get
        // referral bonuses.
        if (
            !getFeatureSetting("disableReferralBonuses") &&
            flags.isFreeEminence &&
            flags.quotaEnforcementScheme === undefined
        ) {
            const referralOwner = owner.kind === OwnerKind.User ? owner : admin;
            if (referralOwner !== undefined) {
                const { referrals } = referralOwner;
                draft.quotas[QuotaKind.RowsUsed].prepaidUsage += referrals * 100;
                draft.quotas[QuotaKind.Reloads].prepaidUsage += referrals * 200;
            }
        }

        // We always thread this through, because all Pages should have this set.
        draft.authenticationSupport.publicDisabled = flags.authenticationSupport.publicDisabled;
    });
}

function applyReferralBonuses(
    flags: EminenceFlags,
    referrals: number,
    bonuses: Partial<Record<QuotaKind, number>>
): EminenceFlags {
    if (getFeatureSetting("disableReferralBonuses")) return flags;

    return produce(flags, draft => {
        for (const [quota, perReferral] of Object.entries(bonuses)) {
            draft.quotas[quota as QuotaKind].prepaidUsage += referrals * perReferral;
        }
    });
}

function applySingleAddon(flags: EminenceFlags, addon: SubscriptionAddon): EminenceFlags {
    const { kind, count } = addon;
    if (addonIsAppPlan(kind)) {
        // We need to handle AppPlan addons differently because we are overwriting the entire object.
        switch (kind) {
            case AddonKind.V1ProApp:
                return {
                    ...flags,
                    ...eminencePro,
                    displayName: "Legacy Pro",
                    badge: "Legacy",
                };
            case AddonKind.V1OrgProPublic:
                return {
                    ...flags,
                    ...eminenceOrgPublic,
                    displayName: "Org Public",
                    badge: "Legacy",
                };
            case AddonKind.V1OrgProInternalUserOverage:
                return {
                    ...flags,
                    ...eminenceOrgInternal,
                    displayName: "Org Private",
                    badge: "Legacy",
                };
            case AddonKind.V2BasicPublicApp:
                return {
                    ...applyV2BasicFlags(flags),
                    displayName: "Basic",
                };
            case AddonKind.V2BasicPrivateApp:
                return {
                    ...transformFlags(flags, applyV2BasicFlags, applyV2PrivateFlags),
                    displayName: "Private Basic",
                };
            case AddonKind.V2ProPublicApp:
                return {
                    ...applyV2ProFlags(flags),
                    displayName: "Pro",
                };
            case AddonKind.V2ProPrivateApp:
                return {
                    ...transformFlags(flags, applyV2ProFlags, applyV2PrivateFlags, applyV2PrivateEmailFlags),
                    displayName: "Private Pro",
                };
            case AddonKind.V2ProPrivatePage:
                return {
                    ...transformFlags(
                        flags,
                        applyV2ProFlags,
                        applyV2PrivateFlags,
                        applyV2PrivateEmailFlags,
                        applyV2ProPrivatePageFlags
                    ),
                    displayName: "Pro",
                };
            default:
                return flags;
        }
    }
    // In the case of actual addons, we use Immer to overwrite a very specific portion of the object.
    return produce(flags, draft => {
        switch (kind) {
            case AddonKind.V1ProApp:
            case AddonKind.V1OrgProPublic:
            case AddonKind.V1OrgProInternalUserOverage:
            case AddonKind.V2BasicPublicApp:
            case AddonKind.V2BasicPrivateApp:
            case AddonKind.V2ProPublicApp:
            case AddonKind.V2ProPrivateApp:
            case AddonKind.V2ProPrivatePage:
                // These addons should have been handled above. Panic if not.
                assert(false);
                break;
            case AddonKind.V2BasicPrivateUser:
            case AddonKind.V2ProPrivateUser:
            case AddonKind.V2ProPrivatePageUser:
                draft.quotas[QuotaKind.PrivateUsers].prepaidUsage += count;
                break;
            case AddonKind.V3AllowWhiteLabelling:
            case AddonKind.V3StarterPlanWhitelabel:
            case AddonKind.V3ProPlanWhitelabel:
            case AddonKind.V3BusinessPlanWhitelabel:
                draft.removeBranding = true;
                break;
            case AddonKind.V3StarterPlanUpdateOverage:
            case AddonKind.V3ProPlanUpdateOverage:
            case AddonKind.V3NonProfitPlanUpdateOverage:
                draft.quotas[QuotaKind.Updates].maxOverage = draft.quotas[QuotaKind.Updates].prepaidUsage * 5;
                break;
            case AddonKind.V3BusinessPlanUpdateOverage:
                // #17008: PAYG Updates under the Business tier should
                // be uncapped.
                draft.quotas[QuotaKind.Updates].maxOverage = maxQuotaNumber;
                break;
            case AddonKind.V3SalesPackagedUpdate:
                draft.quotas[QuotaKind.Updates].prepaidUsage += count;
                break;
            case AddonKind.V3SalesPackagedPrivateUser:
                draft.quotas[QuotaKind.PrivateUsers].prepaidUsage += count;
                break;
            case AddonKind.V3SalesPackagedDeliverEmail:
                draft.quotas[QuotaKind.DeliverEmail].prepaidUsage += count;
                break;
            // These "overage" addons do not affect Eminence, because they
            // aren't real overages. They should never be present on
            // subscription objects.
            case AddonKind.V2BasicPrivateUserOverage:
            case AddonKind.V2ProPrivateUserOverage:
            case AddonKind.V2ProPrivatePageUserOverage:
                break;
            default:
                assertNever(kind, "AddonKind is not explicitly handled in applySingleAddon - this is an error.");
        }
    });
}

function applyAddons(flags: EminenceFlags, addons: SubscriptionAddon[]): EminenceFlags {
    return addons.reduce(applySingleAddon, flags);
}

// Do not export this. Use makeEminenceFlags() instead.
function eminenceFlagsForPlan(
    plan: PlanKind,
    freeFlagsBasis: EminenceFlags = freeFlags,
    planIteration: number = 0
): EminenceFlags {
    switch (plan) {
        case PlanKind.Free:
            return freeFlagsBasis;
        case PlanKind.Unlimited:
            return unlimitedEminenceFlags;
        case PlanKind.V3Starter:
            return applyV3StarterFlags(freeFlagsBasis, planIteration);
        case PlanKind.V3Pro:
            return applyV3ProFlags(freeFlagsBasis, planIteration);
        case PlanKind.V3Business:
            return applyV3BusinessFlags(freeFlagsBasis, planIteration);
        case PlanKind.V3NonProfit:
            return applyV3NonProfitFlags(freeFlagsBasis, planIteration);
        case PlanKind.V3EnterpriseBaseNoUP:
            // Rather than being derived from any free flags,
            // derive from the unlimited flags we keep as a reference.
            return applyV3EnterpriseBaseNoUPFlags(unlimitedEminenceFlags, planIteration);
        case PlanKind.TestPro:
            return produce(unlimitedEminenceFlags, draft => {
                draft.badge = "Pro";
            });
        default:
            assertNever(plan, "PlanKind is not explicitly handled in eminenceFlagsForPlan");
    }
}

// eminenceFlagsForPlan is not meant to be exported, since makeEminenceFlags
// is the authoritative implementation. However, it is safe for EminenceFlagsThatUsersCanUpgradeFor.
export function eminenceFlagsThatUsersCanUpgradeForByPlan(plan: PlanKind): EminenceFlagsThatUsersCanUpgradeFor {
    return eminenceFlagsForPlan(plan);
}

export function makeEminenceFlags(
    owner: Owner,
    admin: UserData | undefined,
    sub?: Subscription,
    appID?: string,
    appKind: AppKind = AppKind.App
): EminenceFlags {
    const appPlan: SubscriptionAddon | undefined = sub?.addons.find(a => a.appID === appID && addonIsAppPlan(a.kind));
    const addons =
        sub?.addons.filter(a => (a.appID === undefined || a.appID === appID) && !addonIsAppPlan(a.kind)) ?? [];
    let flags = appKind === AppKind.App ? freeFlags : freePageFlags;
    const freeFlagsBasis = { ...flags };

    const isV3FreePlan = getIsV3FreePlan(owner);
    if (isV3FreePlan === true) {
        flags = applyV3FreeFlags(flags);
    }

    // In some cases, we want to short-circuit the application of subscription data to Eminence - usually for
    // subscriptions which are canceled.
    if (sub !== undefined && shouldForbidEminence(sub.subscriptionStatus)) {
        flags = applyOwnerFlags(flags, owner, admin, undefined);
        return flags;
    }

    // If a trial applies, we want to apply it to this app.
    // We apply it first, such that if the user has upgraded the app to a paid plan, the upgrade takes precedence.
    if (appID !== undefined) {
        const trial = getAppTrialInfo(owner, appID);
        if (trial !== undefined) {
            const daysLeft = daysLeftInTrial(daysSinceTrialStart(owner, appID, Date.now()), fourteenDayMax);
            if (daysLeft !== undefined && daysLeft > 0) {
                flags = applyAppTrialFlags(flags, trial);
            } else {
                flags = applyExpiredTrialFlags(flags);
            }
        }
    }

    // If `appPlan` is defined, then that means the app in question has its own paid plan, so we will generate starting
    // non-free flags from that. Otherwise, we'll switch to flags derived from the owner's task-based plan.
    if (appPlan !== undefined) {
        flags = applySingleAddon(flags, appPlan); // apply a single app plan because you only care about this appID, v2 only
    } else if (sub?.plan !== undefined && sub.plan !== PlanKind.Free && flags.isFreeEminence) {
        flags = eminenceFlagsForPlan(sub.plan, freeFlagsBasis, sub.planIteration); // basically compute the flags
    }

    flags = applyOwnerFlags(flags, owner, admin, appPlan?.kind); // things that are different between orgs and users
    flags = applyAddons(flags, addons); // per app shit
    flags = applyUserFeatures(flags, owner.features); // these are super powers we give you specifically, no more of these

    if (isV3FreePlan === true && !getFeatureSetting("disableReferralBonuses")) {
        // we dont do this anymore
        flags = applyReferralBonuses(flags, owner.referrals ?? 0, {
            [QuotaKind.RowsUsed]: 100,
            // We are intentionally not giving any updates bonus for referrals.
            [QuotaKind.Updates]: 0,
        });
    }

    return flags;
}
