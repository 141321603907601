import { makeNameUnique } from "@glide/support";
import type { BrandedString } from "@glideapps/ts-necessities";
import { assertNever, makeBrandString } from "@glideapps/ts-necessities";
import { isRight } from "fp-ts/lib/Either";
import * as iots from "io-ts";

export type NativeTableID = BrandedString<"NativeTableID">;
export const makeNativeTableID = makeBrandString<NativeTableID>();

export const nativeTableIDCodec = new iots.Type<NativeTableID, NativeTableID, unknown>(
    "nativeTableIDCodec",
    (i: unknown): i is NativeTableID => typeof i === "string",
    (i, context) => {
        if (typeof i === "string") return iots.success(makeNativeTableID(i));
        return iots.failure(i, context);
    },
    i => i
);

const nativeTableNamePrefix = "native-table-";

export const tableNameCodec = iots.type({
    name: iots.string,
    /**
     * This is only `true` for the "comments" table in Classic Apps.  Pretend
     * it's called `isThisTheCommentsTableInClassicApps`.
     */
    isSpecial: iots.boolean,
});
export type TableName = iots.TypeOf<typeof tableNameCodec>;

export function isTableName(x: unknown): x is TableName {
    return isRight(tableNameCodec.decode(x));
}

/**
 * `isSpecial` must only `true` for the "comments" table in Classic Apps.  It
 * has nothing to do with the "special table" facility we use for GBT/SQL
 * audit log tables and workflow run/step logs.  See `SpecialTableKind` for
 * that.
 */
export function makeTableName(name: string | TableName): TableName;
export function makeTableName(name: string, isSpecial: boolean): TableName;
export function makeTableName(name: string | TableName, isSpecial: boolean = false): TableName {
    if (typeof name !== "string") return name;
    return { name, isSpecial };
}

export function makeTableNameForNativeTable(id: NativeTableID, nameExists?: (tn: TableName) => boolean): TableName {
    let name = nativeTableNamePrefix + id;
    if (nameExists !== undefined) {
        name = makeNameUnique(name, n => nameExists(makeTableName(n)));
    }
    return makeTableName(name);
}

export const nativeTableNameCodec = iots.type({
    isNativeTableName: iots.literal(true),
    nativeTableID: nativeTableIDCodec,
    // Just to make compatibility to `TableName` easier
    isSpecial: iots.literal(false),
});
export type NativeTableName = iots.TypeOf<typeof nativeTableNameCodec>;

export function isNativeTableName(x: unknown): x is NativeTableName {
    return isRight(nativeTableNameCodec.decode(x));
}

export function makeNativeTableName(nativeTableID: NativeTableID): NativeTableName {
    return { isNativeTableName: true, isSpecial: false, nativeTableID };
}

const universalTableNameCodec = iots.union([tableNameCodec, nativeTableNameCodec]);
export type UniversalTableName = iots.TypeOf<typeof universalTableNameCodec>;

export function areTableNamesEqual(n1: UniversalTableName | undefined, n2: UniversalTableName | undefined): boolean {
    if (n1 === undefined || n2 === undefined) return n1 === n2;
    if (isTableName(n1)) {
        if (!isTableName(n2)) return false;
        return n1.name.normalize() === n2.name.normalize() && n1.isSpecial === n2.isSpecial;
    } else if (isNativeTableName(n1)) {
        if (!isNativeTableName(n2)) return false;
        return n1.nativeTableID === n2.nativeTableID;
    } else {
        return assertNever(n1);
    }
}

export function getDebugPrintTableName(n: UniversalTableName): string {
    if (isTableName(n)) {
        return n.name;
    } else if (isNativeTableName(n)) {
        return `native table ${n.nativeTableID}`;
    } else {
        return assertNever(n);
    }
}

// NOTE: The ID returned here might or might not be the correct ID for the
// native table with that table name.  Use this only when really desperate.
export function getNativeTableIDFromTableName(n: TableName): NativeTableID | undefined {
    if (!n.name.startsWith(nativeTableNamePrefix)) return undefined;
    return makeNativeTableID(n.name.substring(nativeTableNamePrefix.length));
}

export function isTableNameForNativeTable(n: TableName): boolean {
    return getNativeTableIDFromTableName(n) !== undefined;
}
