import {
    type ComponentDescription,
    type FilterArrayTransform,
    type SwitchDescription,
    getActionProperty,
    makeActionProperty,
    makeIconProperty,
    makeStringProperty,
} from "@glide/app-description";
import type { Description } from "@glide/type-schema";
import { type SuperPropertySection, PropertySection, SwitchPropertyHandler } from "@glide/function-utils";
import { defined } from "@glideapps/ts-necessities";

import type { ActionWithTitleDescription } from "./fluent-components";

const easyTabActionSection: SuperPropertySection = {
    name: "Actions",
    order: 0,
    header: "General",
};

export const easyCRUDAddPropertyHandler = new SwitchPropertyHandler(
    { easyCRUDAdd: true },
    "Allow users to add items",
    PropertySection.Actions,
    {
        showContextAsContainingScreen: true,
        icon: "st-plus-add",
        conditionPrompt: "",
        defaultTitle: "Add",
    }
);
export const easyTabAddPropertyHandler = easyCRUDAddPropertyHandler.withSection(easyTabActionSection).withoutTitle();

export const easyCRUDEditPropertyHandler = new SwitchPropertyHandler(
    { easyCRUDEdit: true },
    "Allow users to edit items",
    PropertySection.Actions,
    {
        icon: "st-edit-pencil",
        conditionPrompt: "",
        defaultTitle: "Edit",
    }
);
export const easyTabEditPropertyHandler = easyCRUDEditPropertyHandler.withSection(easyTabActionSection).withoutTitle();

export const easyCRUDDeletePropertyHandler = new SwitchPropertyHandler(
    { easyCRUDDelete: false },
    "Allow users to delete items",
    PropertySection.Actions,
    {
        icon: "st-trash",
        conditionPrompt: "",
        defaultTitle: "Delete",
    }
);
export const easyTabDeletePropertyHandler = easyCRUDDeletePropertyHandler
    .withSection(easyTabActionSection)
    .withoutTitle();

export const easyCRUDUndefinedProperties: Partial<ComponentDescription> = {
    [easyCRUDAddPropertyHandler.name]: undefined,
    [easyCRUDEditPropertyHandler.name]: undefined,
    [easyCRUDDeletePropertyHandler.name]: undefined,
};

interface Flag {
    // If undefined it means it's always on
    readonly condition: FilterArrayTransform | undefined;
    // This will always be defined, even if the collection doesn't support a
    // title there - just ignore it in those cases.
    readonly title: string;
}

// `undefined` means it's off.
export interface EasyCRUDFlags {
    readonly add: Flag | undefined;
    readonly edit: Flag | undefined;
    readonly delete: Flag | undefined;
}

function getFlag(desc: SwitchDescription): Flag | undefined {
    if (desc.value === false) return undefined;
    return {
        condition: desc.condition,
        title: defined(desc.title),
    };
}

// Returns `undefined` iff easy CRUD is turned off.
export function getEasyCRUDFlags(desc: ComponentDescription): EasyCRUDFlags | undefined {
    if (
        easyCRUDAddPropertyHandler.getProperty(desc) === undefined &&
        easyCRUDEditPropertyHandler.getProperty(desc) === undefined &&
        easyCRUDDeletePropertyHandler.getProperty(desc) === undefined
    ) {
        return undefined;
    }

    return {
        add: getFlag(easyCRUDAddPropertyHandler.getSwitchWithCondition(desc)),
        edit: getFlag(easyCRUDEditPropertyHandler.getSwitchWithCondition(desc)),
        delete: getFlag(easyCRUDDeletePropertyHandler.getSwitchWithCondition(desc)),
    };
}

export function addConditionToActionWithTitleAndIcon(
    desc: Description | undefined,
    flag: Flag | undefined,
    icon: string
): ActionWithTitleDescription | undefined {
    if (desc === undefined || flag === undefined) return undefined;

    const actionWithTitle = desc as ActionWithTitleDescription;
    const action = getActionProperty(actionWithTitle.action);
    if (action === undefined) return undefined;

    const newAction = { ...action, condition: flag.condition };
    return {
        ...actionWithTitle,
        action: makeActionProperty(newAction),
        title: makeStringProperty(flag.title),
        icon: makeIconProperty(icon),
    };
}
