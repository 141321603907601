import "twin.macro";

import { useState } from "react";
import type { TooltipProps } from "recharts";
import { ResponsiveContainer, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import type { BuilderTheme } from "@glide/theme";
import { useBuilderTheme } from "../../hooks/use-builder-theme";

type PeriodKey = "planCost" | "additionalUsersCost" | "additionalUpdatesCost";

const X_LABELS: Record<PeriodKey, string> = {
    planCost: "Subscription",
    additionalUsersCost: "Additional users",
    additionalUpdatesCost: "Additional updates",
};

const getXColor = (key: PeriodKey, theme: BuilderTheme): string => {
    switch (key) {
        case "planCost":
            return theme.aqua400;
        case "additionalUsersCost":
            return theme.aqua500;
        case "additionalUpdatesCost":
            return theme.purple500;
    }
};

export interface PeriodData {
    firstDay: Date;
    planCost: number;
    additionalUsersCost: number;
    additionalUpdatesCost: number;
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>): JSX.Element | null => {
    const theme = useBuilderTheme();

    if (!active || payload === undefined || payload.length === 0) {
        return null;
    }
    let total = 0;
    payload.forEach(p => {
        total += p.value ?? 0;
    });

    const customHR = <hr tw="h-px border-0 bg-n200" />;

    const firstDay = label as Date;
    return (
        <div tw="rounded-lg pointer-events-auto text-text-base text-builder-base bg-bg-front shadow-xl-dark">
            <div tw="flex justify-between items-end px-4 py-3">
                <div tw="font-semibold text-builder-xl">{`${firstDay.toLocaleString(undefined, {
                    month: "long",
                })} ${firstDay.getFullYear()}`}</div>
            </div>
            {customHR}
            <div tw="px-4 py-2.5">
                {payload.map(p =>
                    (p.value ?? 0) === 0 ? null : (
                        <div key={p.name} tw="flex justify-between py-0.5">
                            <div tw="flex items-center">
                                <div
                                    tw="mr-2 w-3 h-3 rounded-full"
                                    style={{ backgroundColor: getXColor(p.name as PeriodKey, theme) }}
                                />
                                <div tw="mr-4">{X_LABELS[(p.name ?? "") as PeriodKey]}</div>
                            </div>
                            <div>
                                $
                                {(p.value ?? 0).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </div>
                        </div>
                    )
                )}
            </div>
            {customHR}
            <div tw="flex justify-between px-4 py-3 font-semibold">
                <div>Total</div>
                <div>${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
        </div>
    );
};

interface MonthlySpendChartProps {
    readonly periods: PeriodData[];
}

const renderGreyLegendText = (value: string) => {
    return <span tw="text-text-base text-builder-base mr-1.5">{X_LABELS[value as PeriodKey]}</span>;
};

const renderYLabels = (value: number) => {
    return `$${value.toLocaleString()}`;
};

const renderXLabels = (value: Date) => {
    return value.toLocaleString(undefined, { month: "short" });
};

export const MonthlySpendChart: React.VFC<MonthlySpendChartProps> = props => {
    const { periods } = props;
    const [activeTooltipIndex, setActiveTooltipIndex] = useState(-1);

    const theme = useBuilderTheme();

    return (
        <ResponsiveContainer height={227} width="100%">
            <BarChart
                barCategoryGap="30%"
                data={periods}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                onMouseMove={p => {
                    if (p.isTooltipActive) {
                        setActiveTooltipIndex(p.activeTooltipIndex ?? 0);
                    } else {
                        setActiveTooltipIndex(-1);
                    }
                }}>
                <XAxis
                    dataKey="firstDay"
                    tickLine={false}
                    axisLine={{ stroke: theme.n200A }}
                    tick={{ fontSize: 12, fill: theme.textPale }}
                    tickFormatter={renderXLabels}
                />
                <YAxis
                    tickLine={false}
                    axisLine={{ stroke: theme.n200A }}
                    tickFormatter={renderYLabels}
                    tick={{ fontSize: 12, fill: theme.textPale }}
                />
                <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: "transparent" }}
                    isAnimationActive={false}
                    allowEscapeViewBox={{ x: true, y: true }}
                />
                <Legend iconType="circle" iconSize={12} formatter={renderGreyLegendText} />
                <Bar dataKey="planCost" stackId="a" fill={getXColor("planCost", theme)} isAnimationActive={false}>
                    {periods.map((_entry, index) => (
                        <Cell
                            stroke={theme.b400}
                            strokeWidth={activeTooltipIndex === index ? 2 : 0}
                            key={`cell-${index}`}
                        />
                    ))}
                </Bar>
                <Bar
                    dataKey="additionalUsersCost"
                    stackId="a"
                    fill={getXColor("additionalUsersCost", theme)}
                    isAnimationActive={false}>
                    {periods.map((_entry, index) => (
                        <Cell
                            stroke={theme.b400}
                            strokeWidth={activeTooltipIndex === index ? 2 : 0}
                            key={`cell-${index}`}
                        />
                    ))}
                </Bar>
                <Bar
                    dataKey="additionalUpdatesCost"
                    stackId="a"
                    fill={getXColor("additionalUpdatesCost", theme)}
                    isAnimationActive={false}>
                    {periods.map((_entry, index) => (
                        <Cell
                            stroke={theme.b400}
                            strokeWidth={activeTooltipIndex === index ? 2 : 0}
                            key={`cell-${index}`}
                        />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
