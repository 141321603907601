import type { RootPath, Handler, SerializedQuery, Query } from "@glide/computation-model-types";
import { areSerializedQueriesEquivalent } from "@glide/query-conditions";
import { ArrayMap, logInfo } from "@glide/support";
import { DefaultMap } from "@glideapps/ts-necessities";
import type { NamespaceImpl } from "./namespace";

export class QueriesInNamespaceManager {
    // table name -> query -> path
    private readonly _queries = new DefaultMap<string, ArrayMap<SerializedQuery, RootPath>>(
        () => new ArrayMap(areSerializedQueriesEquivalent)
    );

    constructor(private readonly _ns: NamespaceImpl<unknown>) {}

    public getPathForQuery(query: Query, makeHandler: () => Handler | undefined): RootPath | undefined {
        const serialized = query.serialize();
        const forTable = this._queries.get(query.tableName.name);
        let path = forTable.get(serialized);
        if (path === undefined) {
            const handler = makeHandler();
            if (handler === undefined) return undefined;
            path = this._ns.addEntity(`query ${query.tableName.name}`, handler, undefined);
            forTable.set(serialized, path);
        }
        return path;
    }

    public cleanUpUnusedQueryHandlers(): void {
        let numDeleted = 0;

        for (const [tableName, forTable] of Array.from(this._queries.entries())) {
            const newKVPs: [SerializedQuery, RootPath][] = [];

            for (const [k, v] of forTable) {
                if (!this._ns.deleteEntityWithoutDependents(v)) {
                    newKVPs.push([k, v]);
                } else {
                    numDeleted++;
                }
            }

            this._queries.set(tableName, new ArrayMap(areSerializedQueriesEquivalent, newKVPs));
        }

        // FIXME: remove
        logInfo("deleted query handlers", numDeleted);
    }
}
