import type { Row, WritableValue } from "@glide/computation-model-types";
import { extractActionValues } from "@glide/common-core/dist/js/computation-model/row-data";
import { assert, defined } from "@glideapps/ts-necessities";

// We keep track of rows that have at least one local change applied, or have
// been deleted locally.

// For each row, we know the version `originalRowVersion` at which it was
// current.  All local changes for that row must be either not confirmed yet,
// or have a confirmed version `confirmedVersion` that's newer than the row's
// `originalRowVersion`.  That invariant implies that we have to do stuff when
// one of two things happen:
//
// * A previously unconfirmed version is confirmed with a version that's older
//   than `originalRowVersion`.
// * We get a new version of the row that's as new or newer than some of the
//   changes' `confirmedVersion`.
//
// In both cases we remove the change that's outdated now, overlay the
// remaining changes over the base row, and give that row to the store.

interface RowChange {
    changes: Record<string, WritableValue>;
    readonly jobID: string;
    confirmedVersion: number | undefined;
    // True iff the row was added by this client.
    readonly wasAdded: boolean;
}

interface RowEntry {
    // If this is `undefined` it means that we've registered a change to a row
    // that the data row store didn't (yet) have.  We still need to keep track
    // of the change in case the row is loaded later with a version that
    // doesn't have the change yet.
    //
    // https://github.com/quicktype/glide/issues/18595
    originalRow: Row | undefined;
    originalRowVersion: number;
    changes: RowChange[];
}

export class RowChangeOverlayer {
    // row ID -> entry
    // `false` means the row has been deleted
    private readonly entries = new Map<string, RowEntry | false>();

    public addRow(row: Row, maxVersion: number, jobID: string): void {
        let entry = this.entries.get(row.$rowID);
        assert(entry === undefined);

        entry = {
            originalRow: undefined,
            originalRowVersion: maxVersion,
            changes: [
                { changes: extractActionValues(row, undefined), jobID, confirmedVersion: undefined, wasAdded: true },
            ],
        };

        this.entries.set(row.$rowID, entry);
    }

    // `maxVersion` is not necessarily the version of the row, but the latest
    // table version the frontend has seen from the backend.
    public setColumnsInRow(
        rowOrRowID: Row | string,
        maxVersion: number,
        changes: Record<string, WritableValue>,
        jobID: string
    ): void {
        const rowID = typeof rowOrRowID === "string" ? rowOrRowID : rowOrRowID.$rowID;

        let entry = this.entries.get(rowID);
        if (entry === false) return;

        // If we already have an entry, we assume all changes in it are still
        // valid.  If we had gotten a newer version then we would have removed
        // older changes.
        if (entry === undefined) {
            entry = {
                originalRow: typeof rowOrRowID === "string" ? undefined : { ...rowOrRowID },
                originalRowVersion: maxVersion,
                changes: [],
            };
            this.entries.set(rowID, entry);
        }
        entry.changes.push({ changes, jobID, confirmedVersion: undefined, wasAdded: false });
    }

    public deleteRow(rowID: string): void {
        // TODO: We currently keep these entries perpetually.  We technically
        // don't need them anymore once they're confirmed, but it's also not a
        // big deal, unless a client deletes hundreds of thousands of rows.
        this.entries.set(rowID, false);
    }

    private getRowWithChanges(rowID: string, entry: RowEntry): Row {
        assert(entry.changes.length > 0);

        let row: Row;

        if (entry.originalRow !== undefined) {
            row = entry.originalRow;
        } else {
            assert(entry.changes[0].wasAdded);
            row = { $rowID: rowID, $isVisible: true };
        }

        for (const c of entry.changes) {
            assert(c.confirmedVersion === undefined || c.confirmedVersion > entry.originalRowVersion);
            row = { ...row, ...c.changes };
        }

        return row;
    }

    // This should be called whenever a row is returned from a query. Will
    // return `undefined` if the row has been deleted
    public overlayChangesToRow(row: Row, version: number): Row | undefined {
        const entry = this.entries.get(row.$rowID);
        if (entry === undefined) {
            return row;
        } else if (entry === false) {
            return undefined;
        }

        assert(entry.changes.length > 0);
        entry.originalRow = { ...row };
        entry.originalRowVersion = version;
        entry.changes = entry.changes.filter(
            // We remove `wasAdded` changes because once we get back that row
            // from a query we know that the backend has the row.
            c => !c.wasAdded && (c.confirmedVersion === undefined || c.confirmedVersion > version)
        );

        if (entry.changes.length === 0) {
            this.entries.delete(row.$rowID);
            return row;
        }

        return this.getRowWithChanges(row.$rowID, entry);
    }

    // Removes the given change and returns the row with the updated changes
    private removeChange(rowID: string, entry: RowEntry, change: RowChange): Row | undefined {
        entry.changes = entry.changes.filter(c => c !== change);

        if (entry.changes.length === 0) {
            this.entries.delete(rowID);
            return entry.originalRow;
        }

        return defined(this.getRowWithChanges(rowID, entry));
    }

    public confirmAction(rowID: string, jobID: string, version: number): Row | undefined {
        const entry = this.entries.get(rowID);
        if (entry === undefined || entry === false) return undefined;

        const change = entry.changes.find(c => c.jobID === jobID);
        if (change === undefined) return undefined;

        if (change.confirmedVersion !== undefined) {
            assert(change.confirmedVersion > entry.originalRowVersion);
            if (version >= change.confirmedVersion) return undefined;
        }
        change.confirmedVersion = version;

        // If the change is still in the future, there's nothing to do.
        if (change.confirmedVersion > entry.originalRowVersion) return undefined;

        // Now we know the change was applied in a version earlier than the
        // one we have from the backend, which means we need to recompute the
        // row and return it to the caller so it can update it.

        return this.removeChange(rowID, entry, change);
    }

    // This is called when a job was not accepted by the backend, so it needs
    // to be rolled back on the frontend.  The result is
    //
    // * `undefined` if nothing needs to be done
    // * A `Row` if the row needs to be updated
    //   FIXME: what if the row needs to be resurrected?
    // * `"delete"` if the row needs to be deleted
    public rollbackAction(rowID: string, jobID: string): Row | "delete" | undefined {
        const entry = this.entries.get(rowID);
        if (entry === undefined || entry === false) return undefined;

        const change = entry.changes.find(c => c.jobID === jobID);
        if (change === undefined) return undefined;

        assert(change.confirmedVersion === undefined);

        if (change.wasAdded) {
            this.deleteRow(rowID);
            return "delete";
        } else {
            return this.removeChange(rowID, entry, change);
        }
    }

    // We call this when a job A has been obsoleted by another job B, meaning
    // that
    //
    // * we won't get a confirmation for job A on its own, but
    // * we will get confirmation for job B, which will also implicitly
    //   confirm A, and
    // * B is applied after A
    //
    // Right now this happens when we debounce actions.
    // https://github.com/quicktype/glide/pull/18608
    public obsoleteAction(jobIDA: string, jobIDB: string): void {
        for (const entry of this.entries.values()) {
            if (entry === false) continue;

            let changeA: RowChange | undefined;
            let changeB: RowChange | undefined;
            // This will include all changes except for `changeA`.
            const changes: RowChange[] = [];

            for (const c of entry.changes) {
                if (c.jobID === jobIDA) {
                    changeA = c;
                } else {
                    if (c.jobID === jobIDB) {
                        changeB = c;
                    }
                    changes.push(c);
                }
            }

            if (changeA !== undefined) {
                assert(changeB !== undefined);
                // We have to fold `changeA` into `changeB` because it might
                // modify columns that `changeB` does not.
                changeB.changes = { ...changeA.changes, ...changeB.changes };
            }

            // We've either kept all changes in `entry`, or we've found
            // `changeA`.  In both cases there must be at least one.
            assert(changes.length > 0);

            // We only overwrite `changes` if it has actually changed.
            if (changes.length === entry.changes.length) continue;

            entry.changes = changes;
        }
    }

    // This returns all rows that have been added by the client via an action
    // and that we don't know the backend has yet.
    public getAddedRows(): readonly Row[] {
        const rows: Row[] = [];

        for (const entry of this.entries.values()) {
            if (entry === false) continue;
            if (entry.changes[0]?.wasAdded !== true) continue;

            const values = entry.changes[0].changes;
            if (typeof values.$rowID !== "string") continue;

            rows.push({
                ...values,
                $rowID: values.$rowID,
                $isVisible: true,
            });
        }

        return rows;
    }

    public clear(): void {
        this.entries.clear();
    }
}
