import { areValuesEqual, compareValues } from "@glide/common-core/dist/js/components/primitives";
import {
    type DefinedPrimitiveValue,
    type GroundValue,
    type LoadedGroundValue,
    type LoadingValue,
    isLoadingValue,
    isPrimitive,
    type Path,
    type RelativePath,
    getSymbolicRepresentationForPath,
    isRootPath,
    type ConditionValuePath,
} from "@glide/computation-model-types";
import {
    arrayMap,
    asBoolean,
    asString,
    getSymbolicRepresentationForGroundValue,
    isNotEmpty,
} from "@glide/common-core/dist/js/computation-model/data";
import type { GlideDateTime } from "@glide/data-types";
import { areSetsOverlapping, isArray, normalizeEmailAddress } from "@glide/support";
import { assert, assertNever } from "@glideapps/ts-necessities";

export type PathOrGroundValue<T> =
    | { readonly isPath: false; readonly value: LoadedGroundValue }
    | { readonly isPath: true; readonly path: T };

export type UnaryConditionKind = "is-truthy" | "is-empty";
export type BinaryConditionKind =
    | "equals"
    | "contains-string"
    | "is-contained-in-string"
    | "is-less"
    | "is-greater"
    | "is-less-or-equal"
    | "is-greater-or-equal"
    | "is-before"
    | "is-after"
    | "is-on-or-before"
    | "is-on-or-after"
    | "is-within"
    | "matches-email-address"
    | "array-includes";

interface UnaryCondition<T> {
    readonly kind: UnaryConditionKind;
    readonly negate: boolean;
    readonly value: PathOrGroundValue<T>;
}

interface BinaryCondition<T> {
    readonly kind: BinaryConditionKind;
    readonly negate: boolean;
    readonly left: PathOrGroundValue<T>;
    readonly right: PathOrGroundValue<T>;
}

export type Condition<T> = UnaryCondition<T> | BinaryCondition<T>;

function isUnaryCondition<T>(c: Condition<T>): c is UnaryCondition<T> {
    return c.kind === "is-truthy" || c.kind === "is-empty";
}

export function getSymbolicRepresentationForPathOrGroundValue<T>(
    value: PathOrGroundValue<T>,
    getPath: (p: T) => Path
): string {
    if (value.isPath) {
        return getSymbolicRepresentationForPath(getPath(value.path));
    } else {
        return getSymbolicRepresentationForGroundValue(value.value);
    }
}

export function getSymbolicRepresentationForCondition(c: Condition<ConditionValuePath>): string {
    let symbolic: string;
    if (isUnaryCondition(c)) {
        symbolic = `(${c.kind} ${getSymbolicRepresentationForPathOrGroundValue(c.value, p => p.path)})`;
    } else {
        symbolic = `(${getSymbolicRepresentationForPathOrGroundValue(c.left, p => p.path)} ${
            c.kind
        } ${getSymbolicRepresentationForPathOrGroundValue(c.right, p => p.path)})`;
    }
    if (c.negate) {
        symbolic = "!" + symbolic;
    }
    return symbolic;
}

export function getPathsForCondition<T extends boolean>(
    c: Condition<ConditionValuePath>,
    inHostRow: T
): T extends true ? readonly RelativePath[] : readonly Path[] {
    const paths: Path[] = [];

    function process(p: PathOrGroundValue<ConditionValuePath>) {
        if (!p.isPath) return;
        if (p.path.inHostRow !== inHostRow) return;
        if (inHostRow) {
            assert(!isRootPath(p.path.path));
        }
        paths.push(p.path.path);
    }

    switch (c.kind) {
        case "equals":
        case "contains-string":
        case "is-contained-in-string":
        case "is-less":
        case "is-greater":
        case "is-less-or-equal":
        case "is-greater-or-equal":
        case "is-before":
        case "is-after":
        case "is-on-or-before":
        case "is-on-or-after":
        case "is-within":
        case "matches-email-address":
        case "array-includes":
            process(c.left);
            process(c.right);
            break;
        case "is-truthy":
        case "is-empty":
            process(c.value);
            break;
        default:
            return assertNever(c);
    }

    return paths as any;
}

// ##computeCondition:
// This is how the computation model evaluates conditions.
// ##wrapLoadingValues
// Does not care abour wrapping loading values.
// If the passed getters unwrap, this returns unwraped.
export function computeCondition<T>(
    c: Condition<T>,
    getValueForPath: (path: T) => GroundValue,
    parseDateTime: (v: LoadedGroundValue) => GlideDateTime | undefined,
    resolveQuery: ((v: GroundValue, path: T) => GroundValue) | undefined
): boolean | LoadingValue | undefined {
    function get(pov: PathOrGroundValue<T>): GroundValue {
        if (pov.isPath) {
            return getValueForPath(pov.path);
        } else {
            return pov.value;
        }
    }

    function getNormalized(pov: PathOrGroundValue<T>): GroundValue {
        const v = get(pov);
        if (v === "") return undefined;
        return v;
    }

    function getWithResolveQuery(pov: PathOrGroundValue<T>): GroundValue {
        if (pov.isPath) {
            let v = getValueForPath(pov.path);
            if (resolveQuery !== undefined) {
                v = resolveQuery(v, pov.path);
            }
            return v;
        } else {
            return pov.value;
        }
    }

    function getNormalizedWithResolveQuery(pov: PathOrGroundValue<T>): GroundValue {
        const v = getWithResolveQuery(pov);
        if (v === "") return undefined;
        return v;
    }

    function getDefinedPrimitiveArray(
        pov: PathOrGroundValue<T>
    ): readonly DefinedPrimitiveValue[] | LoadingValue | undefined {
        const v = get(pov);
        if (v === undefined || isLoadingValue(v)) return v;
        if (isArray(v)) {
            let loadingValue: LoadingValue | undefined;
            const filtered: DefinedPrimitiveValue[] = v.filter(
                (i): i is DefinedPrimitiveValue => i !== undefined && i !== ""
            );
            return loadingValue ?? filtered;
        }
        if (isPrimitive(v) && v !== "") {
            return [v];
        }
        return undefined;
    }

    function makeEmailAddressSet(v: LoadedGroundValue): ReadonlySet<string> {
        let arr: readonly string[];
        if (isArray(v)) {
            arr = arrayMap(v, p => normalizeEmailAddress(asString(p)));
        } else {
            arr = [normalizeEmailAddress(asString(v))];
        }
        arr = arr.filter(p => p !== "");
        return new Set(arr);
    }

    let result: boolean;
    switch (c.kind) {
        case "equals": {
            const left = get(c.left);
            const right = get(c.right);
            if (isLoadingValue(left)) return left;
            if (isLoadingValue(right)) return right;
            if (!isPrimitive(left) || !isPrimitive(right)) {
                result = left === right;
            } else {
                const areEqual = areValuesEqual(left, right);
                if (areEqual === undefined) return undefined;
                result = areEqual;
            }
            break;
        }
        case "contains-string": {
            const leftValue = getNormalized(c.left);
            const rightValue = getNormalized(c.right);
            if (isLoadingValue(leftValue)) return leftValue;
            if (isLoadingValue(rightValue)) return rightValue;
            const left = asString(leftValue).toLowerCase();
            const right = asString(rightValue).toLowerCase();
            result = left.includes(right);
            break;
        }
        case "is-contained-in-string": {
            const leftValue = getNormalized(c.left);
            const rightValue = getNormalized(c.right);
            if (isLoadingValue(leftValue)) return leftValue;
            if (isLoadingValue(rightValue)) return rightValue;
            const left = asString(leftValue).toLowerCase();
            const right = asString(rightValue).toLowerCase();
            result = right.includes(left);
            break;
        }
        case "is-less": {
            const left = get(c.left);
            const right = get(c.right);
            if (isLoadingValue(left)) return left;
            if (isLoadingValue(right)) return right;
            if (!isPrimitive(left) || !isPrimitive(right)) return undefined;
            const r = compareValues(left, right);
            if (r === undefined) return undefined;
            result = r < 0;
            break;
        }
        case "is-greater": {
            const left = get(c.left);
            const right = get(c.right);
            if (isLoadingValue(left)) return left;
            if (isLoadingValue(right)) return right;
            if (!isPrimitive(left) || !isPrimitive(right)) return undefined;
            const r = compareValues(left, right);
            if (r === undefined) return undefined;
            result = r > 0;
            break;
        }
        case "is-less-or-equal": {
            const left = get(c.left);
            const right = get(c.right);
            if (isLoadingValue(left)) return left;
            if (isLoadingValue(right)) return right;
            if (!isPrimitive(left) || !isPrimitive(right)) return undefined;
            const r = compareValues(left, right);
            if (r === undefined) return undefined;
            result = r <= 0;
            break;
        }
        case "is-greater-or-equal": {
            const left = get(c.left);
            const right = get(c.right);
            if (isLoadingValue(left)) return left;
            if (isLoadingValue(right)) return right;
            if (!isPrimitive(left) || !isPrimitive(right)) return undefined;
            const r = compareValues(left, right);
            if (r === undefined) return undefined;
            result = r >= 0;
            break;
        }
        case "is-before": {
            const leftValue = get(c.left);
            const rightValue = get(c.right);
            if (isLoadingValue(leftValue)) return leftValue;
            if (isLoadingValue(rightValue)) return rightValue;
            const left = parseDateTime(leftValue);
            const right = parseDateTime(rightValue);
            if (left === undefined || right === undefined) return undefined;
            result = left.compareTo(right) < 0;
            break;
        }
        case "is-after": {
            const leftValue = get(c.left);
            const rightValue = get(c.right);
            if (isLoadingValue(leftValue)) return leftValue;
            if (isLoadingValue(rightValue)) return rightValue;
            const left = parseDateTime(leftValue);
            const right = parseDateTime(rightValue);
            if (left === undefined || right === undefined) return undefined;
            result = left.compareTo(right) > 0;
            break;
        }
        case "is-on-or-before": {
            const leftValue = get(c.left);
            const rightValue = get(c.right);
            if (isLoadingValue(leftValue)) return leftValue;
            if (isLoadingValue(rightValue)) return rightValue;
            const left = parseDateTime(leftValue);
            const right = parseDateTime(rightValue);
            if (left === undefined || right === undefined) return undefined;
            result = left.compareTo(right.localEndOfDay()) <= 0;
            break;
        }
        case "is-on-or-after": {
            const leftValue = get(c.left);
            const rightValue = get(c.right);
            if (isLoadingValue(leftValue)) return leftValue;
            if (isLoadingValue(rightValue)) return rightValue;
            const left = parseDateTime(leftValue);
            const right = parseDateTime(rightValue);
            if (left === undefined || right === undefined) return undefined;
            result = left.compareTo(right.localStartOfDay()) >= 0;
            break;
        }
        case "is-within": {
            const leftValue = get(c.left);
            const rightValue = get(c.right);
            if (isLoadingValue(leftValue)) return leftValue;
            if (isLoadingValue(rightValue)) return rightValue;
            const left = parseDateTime(leftValue);
            const right = parseDateTime(rightValue);
            if (left === undefined || right === undefined) return undefined;
            result = left.compareTo(right.localStartOfDay()) >= 0 && left.compareTo(right.localEndOfDay()) <= 0;
            break;
        }
        case "matches-email-address":
            if (c.left === undefined || c.right === undefined) {
                result = false;
            } else {
                const leftValue = getNormalized(c.left);
                const rightValue = getNormalized(c.right);
                if (isLoadingValue(leftValue)) return leftValue;
                if (isLoadingValue(rightValue)) return rightValue;
                const left = makeEmailAddressSet(leftValue);
                const right = makeEmailAddressSet(rightValue);
                result = areSetsOverlapping(left, right);
            }
            break;
        case "is-truthy": {
            const value = getNormalized(c.value);
            if (isLoadingValue(value)) return value;
            result = asBoolean(value);
            break;
        }
        case "is-empty": {
            // This is the only condition that operates on values that can
            // potentially be queries, i.e. relations.  That's why we only use
            // this function here, and just use `getNormalized` in the other
            // conditions.
            const value = getNormalizedWithResolveQuery(c.value);
            if (isLoadingValue(value)) return value;
            result = !isNotEmpty(value);
            break;
        }
        case "array-includes": {
            const container = getDefinedPrimitiveArray(c.left) ?? [];
            if (isLoadingValue(container)) return container;
            if (container.length === 0) {
                result = false;
            } else {
                const contained = getDefinedPrimitiveArray(c.right);
                if (contained === undefined) {
                    result = false;
                } else {
                    if (isLoadingValue(contained)) return contained;
                    if (contained.length === 0) {
                        result = false;
                    } else {
                        // This might be very slow (it's quadratic).  We can
                        // accelerate it, but it's not trivial.  We have to be
                        // very careful to preserve the semantics of
                        // `areValuesEqual`.
                        result = contained.every(i => container.some(j => areValuesEqual(i, j)));
                    }
                }
            }
            break;
        }
        default:
            return assertNever(c);
    }

    if (c.negate) {
        result = !result;
    }
    return result;
}
