export function* generateAllSubsets<T>(set: readonly T[]): Iterable<readonly T[]> {
    if (set.length === 0) {
        yield [];
        return;
    }

    for (const rest of generateAllSubsets(set.slice(1))) {
        yield [set[0], ...rest];
        yield rest;
    }
}

export function* generateAllNonEmptySubsets<T>(set: readonly T[]): Iterable<readonly T[]> {
    for (const subset of generateAllSubsets(set)) {
        if (subset.length > 0) {
            yield subset;
        }
    }
}
