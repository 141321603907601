import * as React from "react";
import {
    encodeOAuth2AuthErrorMessage,
    encodeOAuth2AuthTokenMessage,
    extractOAuth2AuthTokenFromCurrentURL,
} from "@glide/common-core/dist/js/authorization/auth";
import { exceptionToString } from "@glideapps/ts-necessities";
import { isUndefinedish, logError } from "@glide/support";

export const useOAuthLogin = () => {
    const [error, setError] = React.useState<string | undefined>();
    React.useEffect(() => {
        const oauth2AuthToken = extractOAuth2AuthTokenFromCurrentURL();
        const opener = window.opener;
        // nothing to do if there is no opener.
        if (isUndefinedish(opener)) {
            return;
        }
        try {
            if (location.hash === "#signInWithOAuth2Error") {
                opener.postMessage(encodeOAuth2AuthErrorMessage(), location.origin);
                window.close();
                return;
            }

            if (oauth2AuthToken === undefined) {
                setError("Could not get OAuth token");
                return;
            }

            opener.postMessage(encodeOAuth2AuthTokenMessage(oauth2AuthToken), location.origin);
            window.close();
            return;
        } catch (err: unknown) {
            logError(exceptionToString(err));
            setError("Could not post message to opener window");
            return;
        }
    }, []);

    return { error };
};
