import * as glide from "@glide/plugins";

// Helper function to validate URL
function isValidUrl(urlString: string | undefined | null): boolean {
    if (urlString === undefined || urlString === null || urlString.trim() === "") {
        return false;
    }
    try {
        new URL(urlString);
        return true;
    } catch {
        return false;
    }
}

export const plugin = glide.newPlugin({
    id: "opengraph",
    name: "OpenGraph.io",
    icon: "https://ogp.me/logo.png",
    description: "Get website metadata using OpenGraph.io API",
    tier: "starter",
    parameters: {
        apiKey: glide.makeParameter({
            type: "secret",
            name: "API Key",
            description: "Your OpenGraph.io API key",
            required: true,
        }),
    },
});

const advancedSection = {
    name: "Advanced",
    order: 1,
    collapsed: true,
};

plugin.addComputation({
    id: "get-open-graph",
    name: "Get Website Metadata",
    description: "Fetches metadata from a website URL using OpenGraph.io",
    billablesConsumed: 1,
    parameters: {
        url: glide.makeParameter({
            type: "string",
            name: "URL",
            description: "The website URL to fetch metadata for",
            required: true,
        }),
        cacheOk: glide.makeParameter({
            type: "boolean",
            name: "Allow Cache",
            description: "Whether to allow cached results",
            required: false,
            propertySection: advancedSection,
        }),
        fullRender: glide.makeParameter({
            type: "boolean",
            name: "Full Render",
            description: "Fully render the site using a browser before parsing",
            required: false,
            propertySection: advancedSection,
        }),
        useProxy: glide.makeParameter({
            type: "boolean",
            name: "Use Proxy",
            description: "Route request through residential/mobile proxies",
            required: false,
            propertySection: advancedSection,
        }),
        acceptLang: glide.makeParameter({
            type: "string",
            name: "Accept Language",
            description: "Specify request language (e.g., 'en-US,en;q=0.9')",
            required: false,
            propertySection: advancedSection,
        }),
    },
    results: {
        result: glide.makeParameter({
            type: "json",
            name: "Result",
            description: "The complete OpenGraph metadata response",
        }),
        title: glide.makeParameter({
            type: "string",
            name: "Title",
            description: "The title of the webpage",
        }),
        description: glide.makeParameter({
            type: "string",
            name: "Description",
            description: "The description of the webpage",
        }),
        type: glide.makeParameter({
            type: "string",
            name: "Type",
            description: "The type of content (e.g., article, website, etc.)",
        }),
        image: glide.makeParameter({
            type: "url",
            name: "Image URL",
            description: "The main image URL from the webpage",
        }),
        url: glide.makeParameter({
            type: "url",
            name: "URL",
            description: "The canonical URL of the webpage",
        }),
        favicon: glide.makeParameter({
            type: "url",
            name: "Favicon URL",
            description: "The website's favicon URL",
        }),
        siteName: glide.makeParameter({
            type: "string",
            name: "Site Name",
            description: "The name of the website",
        }),
        publishedTime: glide.makeParameter({
            type: "dateTime",
            name: "Published Time",
            description: "When the article was published (for article type content)",
        }),
        author: glide.makeParameter({
            type: "url",
            name: "Author URL",
            description: "URL to the author's profile (for article type content)",
        }),
    },
    execute: async (context, params) => {
        const { url, apiKey, cacheOk, fullRender, useProxy, acceptLang } = params;

        // Validate API key
        if (apiKey === undefined || apiKey === null || apiKey.trim() === "") {
            return glide.Result.FailPermanent("API key is required");
        }

        // Validate URL
        if (!isValidUrl(url)) {
            return glide.Result.FailPermanent("Invalid URL provided");
        }

        const apiUrl = new URL(`https://opengraph.io/api/1.1/site/${encodeURIComponent(url as string)}`);
        apiUrl.searchParams.append("app_id", apiKey);
        if (cacheOk !== undefined) apiUrl.searchParams.append("cache_ok", cacheOk.toString());
        if (fullRender !== undefined) apiUrl.searchParams.append("full_render", fullRender.toString());
        if (useProxy !== undefined) apiUrl.searchParams.append("use_proxy", useProxy.toString());
        if (acceptLang !== undefined) apiUrl.searchParams.append("accept_lang", acceptLang);

        try {
            const response = await context.useCache(async () => {
                const fetchResponse = await context.fetch(apiUrl.toString());
                if (!fetchResponse.ok) {
                    const errorText = await fetchResponse.text();
                    return glide.Result.FailFromHTTPStatus(errorText, fetchResponse.status);
                }
                const data = await fetchResponse.json();
                context.consumeBillable(1);
                return glide.Result.Ok(data);
            }, [apiUrl.toString()]);

            if (!response.ok) {
                return response;
            }

            const { hybridGraph } = response.result;

            return glide.Result.Ok({
                result: JSON.stringify(response.result),
                title: hybridGraph.title,
                description: hybridGraph.description,
                type: hybridGraph.type,
                image: hybridGraph.image,
                url: hybridGraph.url,
                favicon: hybridGraph.favicon,
                siteName: hybridGraph.site_name,
                publishedTime: hybridGraph.articlePublishedTime,
                author: hybridGraph.articleAuthor,
            });
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error";
            return glide.Result.FailPermanent(`Failed to fetch OpenGraph data: ${message}`);
        }
    },
});
