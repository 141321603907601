import type { GlideFC } from "@glide/common";
import { getFirstElementFromArrayOrSingleElement } from "@glide/common-core/dist/js/components/component-helpers";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import type { WireImageComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isDefined, isEmptyOrUndefinedish } from "@glide/support";
import type { Unbound } from "@glide/computation-model-types";
import type { UIAspect, UIInsetStyle, UISize } from "@glide/wire";
import classNames from "classnames";
import React from "react";
import tw from "twin.macro";

import { Img } from "../../components/img/img";
import { styled } from "@glide/common-components";
import type { WireRenderer } from "../wire-renderer";

interface AspectContainerProps {
    title?: string | Unbound;
    alt?: string | Unbound;
    caption?: string | Unbound;
    aspectRatio?: UIAspect | Unbound;
    insetStyle?: UIInsetStyle | Unbound;
    size?: UISize | Unbound;
}

const WireAspectContainerStyle = styled.div`
    &.size-small .width-container {
        ${tw`w-full
          gp-sm:[width:35%]`}
    }

    &.size-medium .width-container {
        ${tw`w-full
          gp-lg:[width:50%]`}
    }

    &.size-large .width-container {
        ${tw`w-full
          gp-xl:[width:65%]`}
    }

    &.size-full .width-container {
        width: 100%;
    }

    &&&&&:not(.has-title):not(.has-caption) .width-container {
        width: 100%;
    }

    &.aspect-square .aspect-container {
        padding-top: 100%;
    }

    &.aspect-four-by-three .aspect-container {
        padding-top: 75%;
    }

    &.aspect-sixteen-by-nine .aspect-container {
        padding-top: 56.25%;
    }

    &.aspect-three-by-one .aspect-container {
        padding-top: 33%;
    }

    &.aspect-auto .aspect-container {
        height: auto;

        > * {
            position: relative;
            height: auto;
            width: 100%;
        }
    }

    &.size-small.has-title,
    &.size-small.has-caption {
        ${tw`p-6 gp-sm:p-0`}

        .v-text {
            ${tw`block gp-sm:hidden`}
        }

        .h-text {
            ${tw`hidden gp-sm:block`}
        }

        .aspect-container > * {
            ${tw`overflow-hidden rounded-md gp-sm:rounded-none`}
        }
    }

    &.size-medium.has-title,
    &.size-medium.has-caption {
        ${tw`p-6 gp-lg:p-0`}

        .v-text {
            ${tw`block gp-lg:hidden`}
        }

        .h-text {
            ${tw`hidden gp-lg:block`}
        }

        .aspect-container > * {
            ${tw`overflow-hidden rounded-md gp-lg:rounded-none`}
        }
    }

    &.size-large.has-title,
    &.size-large.has-caption {
        ${tw`p-6 gp-xl:p-0`}

        .v-text {
            ${tw`block gp-xl:hidden`}
        }

        .h-text {
            ${tw`hidden gp-xl:block`}
        }

        .aspect-container > * {
            ${tw`overflow-hidden rounded-md gp-xl:rounded-none`}
        }
    }

    &.size-full.has-title,
    &.size-full.has-caption {
        ${tw`p-6`}

        .v-text {
            ${tw`block`}
        }

        .h-text {
            ${tw`hidden`}
        }

        .aspect-container > * {
            ${tw`overflow-hidden rounded-md`}
        }
    }
`;
// Originally named WiredAspectContainer but since there's duplicate term called "WireAspectContainer" in
// this package. found in shared.tsx.
// I renamed this to add a D to the end of it updated it's references.
export const WiredAspectContainer: GlideFC<React.PropsWithChildren<AspectContainerProps>> = p => {
    const { aspectRatio, caption, children, insetStyle, size, title } = p;

    const hasTitle = !isEmptyOrUndefinedish(title);
    const hasCaption = !isEmptyOrUndefinedish(caption);

    const titleNode = hasTitle ? <div tw="mb-4 text-text-dark text-2xl font-bold">{title}</div> : undefined;
    const captionNode = hasCaption ? <div tw="mt-4 text-text-xpale text-sm min-w-0 w-full">{caption}</div> : undefined;

    return (
        <WireAspectContainerStyle
            className={classNames(aspectRatio, insetStyle, size, hasTitle && "has-title", hasCaption && "has-caption")}
            tw="bg-bg-front w-full rounded-xl overflow-hidden shadow-md flex flex-col relative"
        >
            {hasTitle && <div className="v-text">{titleNode}</div>}
            <div tw="flex">
                <div className="width-container" tw="shrink-0">
                    <div
                        className="aspect-container"
                        tw="relative min-h-full h-0 all-child:(absolute inset-0 w-full h-full object-cover)"
                    >
                        {children}
                    </div>
                </div>
                <div className="h-text" tw="m-6">
                    {titleNode}
                    {captionNode}
                </div>
            </div>
            {hasCaption && <div className="v-text">{captionNode}</div>}
        </WireAspectContainerStyle>
    );
};

export const WireImage: WireRenderer<WireImageComponent> = React.memo(p => {
    const { image, alt } = p;
    const firstImage = getFirstElementFromArrayOrSingleElement(image);
    if (!isDefined(firstImage)) return null;
    return (
        <WiredAspectContainer {...p}>
            <Img
                alt={alt ?? undefined}
                src={massageImageUrl(
                    firstImage,
                    {
                        thumbnail: false,
                        width: window.innerWidth - 64,
                    },
                    p.backend.appID
                )}
                isPages={true}
            />
        </WiredAspectContainer>
    );
});
