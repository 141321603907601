// This is mostly similar to `Set`, except that it compares keys not with
// `===` but with a supplied equality function.
//
// Since it uses an array through which it searches linearly it’s not a good
// choice if there’s a large number or items, or the comparison function is
// slow.

export class ArraySet<K> implements ReadonlySet<K> {
    private readonly arr: K[] = [];

    constructor(private readonly areKeysEqual: (a: K, b: K) => boolean) {}

    public get size(): number {
        return this.arr.length;
    }

    public has(k: K): boolean {
        for (const l of this.arr) {
            if (this.areKeysEqual(k, l)) return true;
        }
        return false;
    }

    public add(k: K): void {
        if (this.has(k)) return;
        this.arr.push(k);
    }

    public entries(): IterableIterator<[K, K]> {
        return this.arr.map(k => [k, k] as [K, K]).values();
    }

    public keys(): IterableIterator<K> {
        return this.arr.values();
    }

    public values(): IterableIterator<K> {
        return this.keys();
    }

    // FIXME: technically we have to support `thisArg` too
    public forEach(callbackfn: (value: K, value2: K, set: ReadonlySet<K>) => void): void {
        for (const k of this.arr) {
            callbackfn(k, k, this);
        }
    }

    // eslint-disable-next-line require-yield
    public [Symbol.iterator](): IterableIterator<K> {
        return this.keys();
    }
}
