import type { QuotaBannerRequirements } from "@glide/common-core/dist/js/components/types";
import type {
    MutatingScreenKind,
    PropertyDescription,
    ScreenDescriptionKind,
    ArrayScreenDescription,
} from "@glide/app-description";
import {
    ArrayScreenFormat,
    PropertyKind,
    getActionProperty,
    getColumnProperty,
    getEnumProperty,
    getSourceColumnProperty,
    getSwitchProperty,
    makeActionProperty,
    makeArrayProperty,
    makeEnumProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import {
    ComponentKindInlineList,
    makeEmptyComponentDescription,
    type InputOutputTables,
} from "@glide/common-core/dist/js/description";
import { MapListMode, MapVisualType } from "@glide/component-utils";
import type { WireAppMapListComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type InlineListComponentDescription,
    type PropertyDescriptor,
    type PropertyTableGetter,
    EnumPropertyHandler,
    PropertySection,
    getTitlePropertyFromInlineListCaption,
    makeStringyColumnPropertyDescriptor,
} from "@glide/function-utils";
import { defined } from "@glideapps/ts-necessities";
import type { WireInflationBackend, WireTableComponentHydratorConstructor, WireAction } from "@glide/wire";
import { UIMapAspect, WireComponentKind } from "@glide/wire";
import { getActionsForArrayContent } from "../components/component-utils";
import { makeCaptionPropertyDescriptor } from "../components/descriptor-utils";
import {
    type WireStringGetter,
    getAppArrayScreenEmptyMessage,
    inflateActionsWithCanAutoRun,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import {
    type SummaryArrayContentDescription,
    type SummaryArrayScreenDescription,
    SummaryArrayScreenHandler,
    hydrateListItem,
    inflateSummary,
} from "./summary-array-screen";
import type { MapCollectionComponentDescription } from "@glide/fluent-components/dist/js/fluent-components";
import { type TableGlideType, getTableName } from "@glide/type-schema";
import { definedMap } from "collection-utils";
import type { ComponentScreenContextForConversion } from "./array-content";

interface MapScreenContentDescription extends SummaryArrayContentDescription {
    readonly locationProperty: PropertyDescription;
    readonly captionProperty: PropertyDescription;
    readonly defaultModeProperty: PropertyDescription | undefined;
    readonly allowUserLocationProperty: PropertyDescription | undefined;
    readonly visualTypeProperty: PropertyDescription | undefined;
}

interface MapScreenDescription extends SummaryArrayScreenDescription, MapScreenContentDescription {
    readonly kind: ScreenDescriptionKind.Array;
    readonly format: ArrayScreenFormat.Map;
}

const visualTypePropertyHandler = new EnumPropertyHandler(
    { visualTypeProperty: MapVisualType.Street },
    "Visual type",
    "Visual type",
    [
        {
            label: "Street",
            value: MapVisualType.Street,
        },
        {
            label: "Satellite",
            value: MapVisualType.Satellite,
        },
    ],
    PropertySection.Design,
    "dropdown"
);

export class MapScreenHandler extends SummaryArrayScreenHandler<MapScreenContentDescription, MapScreenDescription> {
    protected readonly supportsNonURLImages = false;
    protected readonly supportsEmojiImages = false;
    protected readonly supportsTruncateList = false;

    constructor() {
        super(ArrayScreenFormat.Map, "Map", "map", false);
    }

    public get quotaBannerRequirements(): QuotaBannerRequirements {
        return { needListQuota: true, needMapQuota: true };
    }

    public getBasicSearchProperties(desc: MapScreenContentDescription): readonly string[] {
        const props = super.getBasicSearchProperties(desc);
        const captionColumn = getColumnProperty(desc.captionProperty);
        if (captionColumn === undefined) {
            return props;
        } else {
            return [...props, captionColumn];
        }
    }

    public getContentPropertyDescriptors<T extends MapScreenContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined
    ): ReadonlyArray<PropertyDescriptor> {
        const descriptors = super.getContentPropertyDescriptors(
            getPropertyTable,
            insideInlineList,
            containingScreenTables,
            desc,
            ccc,
            mutatingScreenKind,
            isDefaultArrayScreen,
            withTransforms,
            forEasyTabConfiguration,
            isFirstComponent
        );
        return [
            makeStringyColumnPropertyDescriptor(
                "locationProperty",
                "Address",
                ["required", "editable", "searchable"],
                PropertySection.Content,
                getPropertyTable,
                ["location", "address"]
            ),
            ...descriptors,
            makeCaptionPropertyDescriptor(getPropertyTable, false, PropertySection.Content),
            {
                kind: PropertyKind.Enum,
                property: { name: "defaultModeProperty" },
                label: "Default Screen",
                menuLabel: "Default view",
                cases: [
                    {
                        value: MapListMode.Map,
                        label: "Map",
                        icon: "co-map",
                    },
                    {
                        value: MapListMode.List,
                        label: "List",
                        icon: "co-inline-list",
                    },
                ],
                defaultCaseValue: MapListMode.Map,
                section: PropertySection.Design,
                visual: "small-images",
            },
            {
                kind: PropertyKind.Switch,
                property: { name: "allowUserLocationProperty" },
                label: "Allow user to show current location",
                section: PropertySection.Options,
                defaultValue: true,
            },
            visualTypePropertyHandler,
        ];
    }

    public inflateContent<T extends MapScreenContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        captionGetter: WireStringGetter | undefined,
        _containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        const {
            forBuilder,
            adc: { appKind },
        } = ib;
        const [locationGetter, locationGetterType] = inflateStringProperty(ib, desc.locationProperty, false);
        if (locationGetterType === undefined) return undefined;

        const visualType = visualTypePropertyHandler.getEnum(desc);
        const defaultMode = getEnumProperty<MapListMode>(desc.defaultModeProperty) ?? MapListMode.Map;
        const allowUserLocation = getSwitchProperty(desc.allowUserLocationProperty) ?? false;

        const summaryGetters = inflateSummary(ib, desc);
        const [itemCaptionGetter] = inflateStringProperty(ib, desc.captionProperty, true);
        const { actionHydrator, canAutoRunAction } = inflateActionsWithCanAutoRun(
            ib,
            getActionsForArrayContent(this, ib.tables, desc, ib.adc).actions
        );

        return makeSimpleWireTableComponentHydratorConstructor(ib, (thb, chb, searchIsActive) => {
            const rows = thb.tableScreenContext.asArray();
            let firstListItemActionToRun: WireAction | undefined;
            const component: WireAppMapListComponent = {
                ...spreadComponentID(componentID, forBuilder),
                kind: WireComponentKind.List,
                format: ArrayScreenFormat.Map,
                title: captionGetter?.(defined(chb)) ?? "",
                emptyMessage: getAppArrayScreenEmptyMessage(searchIsActive, appKind),
                searchIsActive,
                insideInlineList: !thb.isArrayScreen,
                quotaKey: thb.quotaKey,
                stateSaveKey: thb.stateSaveKey ?? chb?.stateSaveKey,

                visualType,
                // This will be modified by `postProcessNavigationModel`
                displayContext: "default",
                defaultMode,
                allowUserLocation,
                items: rows.map(row => {
                    const rhb = thb.makeHydrationBackendForRow(row);
                    const item = {
                        ...hydrateListItem(rhb, summaryGetters, actionHydrator),
                        key: row.$rowID,
                        image: summaryGetters.imageGetter(rhb),
                        caption: itemCaptionGetter(rhb),
                        location: locationGetter(rhb) ?? "",
                    };
                    if (canAutoRunAction && firstListItemActionToRun === undefined) {
                        firstListItemActionToRun = item.action;
                    }
                    return item;
                }),
            };
            return {
                component,
                isValid: true,
                firstListItemActionToRun,
            };
        });
    }

    private convertContentToPage(desc: MapScreenContentDescription) {
        const visualType = visualTypePropertyHandler.getEnum(desc);

        const style = visualType === MapVisualType.Street ? "standard" : "satellite";

        return {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            format: makeEnumProperty(ArrayScreenFormat.PagesSimpleMap),
            caption: undefined,
            transforms: desc.transforms,
            action: definedMap(getActionProperty(desc.actions), a => makeActionProperty(a)),
            title: definedMap(getSourceColumnProperty(desc.titleProperty), sc => makeSourceColumnProperty(sc)),
            image: definedMap(getSourceColumnProperty(desc.imageURLProperty), sc => makeSourceColumnProperty(sc)),
            subtitle: desc.captionProperty,
            groupByColumn: desc.groupByColumn,
            mobileView: makeEnumProperty("fullWidth"),
            location: desc.locationProperty,
            aspectRatio: makeEnumProperty(UIMapAspect.Large),
            style: makeEnumProperty(style),
        };
    }

    public convertInlineToPage(
        desc: InlineListComponentDescription & MapScreenContentDescription
    ): MapCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: desc.propertyName,
            allowSearch: desc.allowSearch,
            componentTitle: getTitlePropertyFromInlineListCaption(desc),
        };
    }

    public convertArrayScreenToPage(
        desc: ArrayScreenDescription & MapScreenContentDescription,
        table: TableGlideType,
        adc: AppDescriptionContext,
        screenContext: ComponentScreenContextForConversion
    ): MapCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: makeTableProperty(getTableName(table)),
            allowSearch: makeSwitchProperty(getSwitchProperty(desc.search)),
            titleActions: definedMap(screenContext.titleAction, a => makeArrayProperty([a])),
            componentTitle: definedMap(screenContext.screenTitle, t => makeStringProperty(t)),
            multipleDynamicFilters: this.getDynamicMultipleFiltersForPageConversion(
                adc,
                table,
                desc.dynamicFilterColumn,
                desc.pivots
            ),
        };
    }
}
