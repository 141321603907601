import React from "react";
import type { NonUserAppFeatures } from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import { getLocalizedString } from "@glide/localization";
import { assertNever } from "@glideapps/ts-necessities";
import { isValidEmailAddress } from "@glide/support";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { WireField } from "../../renderers/wire-field/wire-field";
import {
    getNotAllowedToSignInMessage,
    getDefaultPagesGreeting,
    type EmailPinAuthenticator,
} from "@glide/auth-controller-core";
import type { NotificationTarget } from "@glide/plugins";
import { PlayerObservability } from "@glide/player-frontend-observability";
import { useAuthAgreement, UserAgreementButtons } from "../custom-sign-in/custom-wire-sign-in/user-agreements";
import { GreetingStyles } from "../custom-sign-in/greeting-styles";
import { SignInPrimaryButton } from "./sign-in-buttons";

interface Props extends React.PropsWithChildren {
    readonly appFeatures: NonUserAppFeatures;
    readonly userEmail: string;
    readonly appTitle: string;
    readonly onEmailChange: (newEmail: string) => void;
    readonly onPinSent: (target: string, method: NotificationTarget["method"], provider: string) => void;
    readonly sendPinForEmail: EmailPinAuthenticator["sendPinForEmail"];
    readonly canRequestAccess?: boolean;
    readonly onRequestAccess: () => void;
}

const pinRequestMetric = PlayerObservability.makePlayerMetric("requested-pin-for-email", ["status"]);

export const EmailStage: React.FC<Props> = p => {
    const {
        appTitle,
        appFeatures,
        onEmailChange,
        onPinSent,
        userEmail,
        sendPinForEmail,
        canRequestAccess,
        onRequestAccess,
    } = p;
    const { hasAgreed, userAgreed, setUserAgreed, setAllowSaveLogin, allowSaveLogin } = useAuthAgreement();

    const theme = useWireAppTheme();

    const [isSendingPin, setIsSendingPin] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>();

    const greeting = theme.signInGreetingText ?? getDefaultPagesGreeting(undefined, appTitle, AppKind.Page);

    const onContinue = React.useCallback(async () => {
        setIsSendingPin(true);

        const stop = pinRequestMetric.start();
        const { status, target, method, reason, provider } = await sendPinForEmail(userEmail);
        stop({ status });

        switch (status) {
            case "Success":
                onPinSent(target ?? userEmail, method ?? "email", provider ?? "glide");
                return;

            case "Forbidden":
                if (canRequestAccess === true) {
                    onRequestAccess();
                } else {
                    setError(getNotAllowedToSignInMessage(reason));
                }
                setIsSendingPin(false);
                return;

            case "Offline":
                setError(getLocalizedString("networkAppearsOffline", AppKind.Page));
                setIsSendingPin(false);
                return;

            case "PaymentRequired":
                setError("This team has reached its user limit. Please contact the app owner to resolve this issue.");
                setIsSendingPin(false);
                return;

            default:
                assertNever(status);
        }
    }, [canRequestAccess, onPinSent, onRequestAccess, sendPinForEmail, userEmail]);

    const canProgressToPin = isValidEmailAddress(userEmail) && !isSendingPin && hasAgreed;

    const onFormSubmit = React.useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();
            if (!canProgressToPin) {
                return;
            }
            await onContinue();
        },
        [onContinue, canProgressToPin]
    );

    const title = theme.signInDescriptionText ?? getLocalizedString("pleaseEnterEmail", AppKind.Page);

    const buttonText = isSendingPin
        ? getLocalizedString("sendingPin", AppKind.Page)
        : getLocalizedString("continue", AppKind.Page);

    return (
        <>
            <div>
                <GreetingStyles greeting={greeting} />

                <form onSubmit={onFormSubmit}>
                    <WireField
                        isSignInEmail={true}
                        title={title}
                        data-test="app-email-input"
                        isEnabled={!isSendingPin}
                        placeholder={getLocalizedString("enterYourEmail", AppKind.Page)}
                        value={userEmail}
                        onChange={onEmailChange}
                        dataType="email"
                        error={error}
                        autoFocus={true}
                    />
                    <UserAgreementButtons
                        appFeatures={appFeatures}
                        theme={theme}
                        userAgreed={userAgreed}
                        allowSaveLogin={allowSaveLogin}
                        onUserAgreedChange={setUserAgreed}
                        onUserUpdateSaveLogin={setAllowSaveLogin}
                    />
                </form>
            </div>
            <div>
                <SignInPrimaryButton onClick={onContinue} disabled={!canProgressToPin} label={buttonText} />
            </div>
        </>
    );
};
