import type { NonUserAppFeatures } from "@glide/app-description";
import { EmailStage, PinStage, RequestAccessV2Stage as RequestAccessStage } from "@glide/wire-renderer";
import * as React from "react";
import type { EmailPinAuthenticator } from "@glide/auth-controller-core";
import type { NotificationTarget } from "@glide/plugins";
import { isEmailPinEnabled } from "@glide/common-core/dist/js/utility/pins";
import type { ResponseStatus } from "@glide/common-core/dist/js/components/types";
import type { SignInState } from "@glide/common-components";

interface EmailPinAuthControllerProps {
    readonly appFeatures: NonUserAppFeatures;
    readonly appTitle: string;
    readonly onLoggedIn: () => void;
    readonly authenticator: EmailPinAuthenticator;
    /**
     * Call this method if you need the entire auth screen for yourself.
     * For example: to show a "signing in..." message
     */
    readonly requestExclusiveRender: () => void;
    readonly yieldExclusiveRender: () => void;
    readonly signinState: SignInState;
    readonly onSigninStateChange: (state: SignInState, pinMethod?: NotificationTarget["method"]) => void;
}

type StageInfo =
    | {
          stage: "email";
      }
    | {
          stage: "request-access";
          status?: "Sending" | ResponseStatus;
      }
    | {
          stage: "pin";
          target: string;
          method: NotificationTarget["method"];
          provider: string;
      };

export const EmailPinAuthController: React.FC<EmailPinAuthControllerProps> = p => {
    const {
        appFeatures,
        appTitle,
        onLoggedIn,
        authenticator,
        requestExclusiveRender,
        yieldExclusiveRender,
        signinState,
        onSigninStateChange,
    } = p;

    const [email, setEmail] = React.useState("");

    const [stageInfo, setStageInfo] = React.useState<StageInfo>({ stage: "email" });

    const onPinSent = (target: string, method: NotificationTarget["method"], provider: string) => {
        requestExclusiveRender();
        setStageInfo({
            stage: "pin",
            target,
            method,
            provider,
        });
        onSigninStateChange("insert-pin", method);
    };

    const onRequestAccess = () => {
        requestExclusiveRender();
        setStageInfo({
            stage: "request-access",
        });
    };

    const onRequestAnotherPin = () => {
        yieldExclusiveRender();
        setStageInfo({
            stage: "email",
        });
    };

    const onRequestAccessPressedContinue = async () => {
        yieldExclusiveRender();
        setStageInfo({
            stage: "request-access",
            status: "Sending",
        });
        const result = await authenticator.onRequestAccessPressedContinue(email);
        setStageInfo({
            stage: "request-access",
            status: result.status,
        });
    };

    const onRequestAccessPressedBack = () => {
        yieldExclusiveRender();
        setStageInfo({
            stage: "email",
        });
    };

    if (!isEmailPinEnabled(appFeatures)) {
        return null;
    }

    return (
        <div tw="w-full">
            {stageInfo.stage === "email" ? (
                <EmailStage
                    appFeatures={appFeatures}
                    userEmail={email}
                    onEmailChange={setEmail}
                    onPinSent={onPinSent}
                    appTitle={appTitle}
                    sendPinForEmail={authenticator.sendPinForEmail}
                    canRequestAccess={appFeatures.canAppUserRequestAccess} // TODO double check what else should be in this condition
                    onRequestAccess={onRequestAccess}
                />
            ) : stageInfo.stage === "request-access" ? (
                <RequestAccessStage
                    userEmail={email}
                    onPressedContinue={onRequestAccessPressedContinue}
                    status={stageInfo.status}
                    onPressedBack={onRequestAccessPressedBack}
                />
            ) : (
                <PinStage
                    pinTarget={stageInfo.target}
                    pinMethod={stageInfo.method}
                    pinProvider={stageInfo.provider}
                    appFeatures={appFeatures}
                    userEmail={email}
                    onRequestAnotherPin={onRequestAnotherPin}
                    sendPinForEmail={authenticator.sendPinForEmail}
                    onLoggedIn={onLoggedIn}
                    getPasswordForPin={authenticator.getPasswordForPin}
                    signinState={signinState}
                    onSigninStateChange={onSigninStateChange}
                />
            )}
        </div>
    );
};
