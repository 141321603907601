import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

export const SingleImageBindingHandlerExtension = Extension.create({
    name: "singleImageBindingHandler",

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey("singleImageBindingHandler"),
                appendTransaction: (transactions, oldState, newState) => {
                    if (transactions.every(tr => !tr.steps.length)) return null;

                    const containsImageOrBinding = (doc: any) => {
                        let hasImageOrBinding = false;
                        doc.descendants((node: any) => {
                            if (node.type.name === "image" || node.type.name === "binding") {
                                hasImageOrBinding = true;
                                return false;
                            }
                            return true;
                        });
                        return hasImageOrBinding;
                    };

                    const oldDoc = oldState.doc;

                    if (containsImageOrBinding(oldDoc)) {
                        const tr = newState.tr;

                        // Replace all content with binding or image
                        if (
                            transactions.some(t =>
                                t.steps.some((s: any) => {
                                    const newNodeType = s.slice?.content?.content?.[0]?.type?.name;
                                    return newNodeType === "binding" || newNodeType === "image";
                                })
                            )
                        ) {
                            //@ts-expect-error the types aren't reflecting the correct structure, (slice exists)
                            const newNode = transactions[0].steps[0].slice.content.content[0];

                            // Check if oldDoc.content.size is greater than 0 before replacing
                            if (oldDoc.content.size > 0) {
                                tr.replaceWith(0, Math.min(oldDoc.content.size, newState.doc.content.size), newNode);
                            } else {
                                tr.insert(0, newNode);
                            }
                            return tr;
                        }
                    }

                    return null;
                },
                filterTransaction: (transaction, state) => {
                    const containsBinding = (doc: any) => {
                        let hasImageOrBinding = false;
                        doc.descendants((node: any) => {
                            if (node.type.name === "binding") {
                                hasImageOrBinding = true;
                                return false;
                            }
                            return true;
                        });
                        return hasImageOrBinding;
                    };

                    const containsImage = (doc: any) => {
                        let hasImageOrBinding = false;
                        doc.descendants((node: any) => {
                            if (node.type.name === "image") {
                                hasImageOrBinding = true;
                                return false;
                            }
                            return true;
                        });
                        return hasImageOrBinding;
                    };
                    const containsImageOrBinding = containsBinding(state.doc) || containsImage(state.doc);

                    // Allow clearing content
                    if (transaction.docChanged && transaction.steps.length === 1) {
                        const step = transaction.steps[0];
                        //@ts-expect-error the types aren't reflecting the correct structure, (from. to exists)
                        if (step.from === 0 && step.to === state.doc.content.size) {
                            return true;
                        }
                    }

                    // Allow select all and arrow navigation
                    if (transaction.selectionSet && !containsImage(state.doc)) {
                        return true;
                    }

                    // Allow if it's just focus in the input
                    if (transaction.selectionSet && !transaction.docChanged) {
                        return true;
                    }

                    if (containsImageOrBinding) {
                        // Allow deleting content with backspace
                        // @ts-expect-error the types aren't reflecting the correct structure, (slice exists)
                        if (transaction.steps.some(step => step.slice && step.slice.content.size === 0)) {
                            return true;
                        }

                        // Allow if it's a binding, image insertion, or text
                        return transaction.steps.some((step: any) => {
                            const content = step.slice?.content;
                            if (!content) return false;

                            return (
                                content.childCount === 1 &&
                                (content.child(0).type.name === "binding" ||
                                    content.child(0).type.name === "image" ||
                                    (content.child(0).type.name === "text" && !containsImage(state.doc)))
                            );
                        });
                    }

                    return true;
                },
            }),
        ];
    },
});
