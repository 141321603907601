import type { ParameterProps } from "@glide/plugins";
import { assertNever, definedMap } from "@glideapps/ts-necessities";
import { logError } from "@glide/support";
import type { ColumnType, PrimitiveGlideTypeKind } from "@glide/type-schema";
import { makeArrayType, makePrimitiveType } from "@glide/type-schema";

export function getTypeKindForPrimitivePluginType(t: ParameterProps["type"]): PrimitiveGlideTypeKind | undefined {
    switch (t) {
        case "array":
        case "stringArray":
            logError("Array types are not primitive", t);
            return undefined;
        case "object":
        case "stringObject":
        case "jsonObject":
            return "json";
        case "table":
        case "generatedKeyPair":
            logError("Value type not supported as primitive", t);
            return undefined;
        case "dateTime":
            return "date-time";
        default:
            if (t === "secret" || t === "enum" || t === "jsonPath") return "string";
            if (t === "url") return "uri";
            return t;
    }
}

export function getGlideTypeForPluginType(t: ParameterProps["type"]): ColumnType | undefined {
    switch (t) {
        case "stringArray":
            return makeArrayType(makePrimitiveType("string"));
        default:
            return definedMap(getTypeKindForPrimitivePluginType(t), makePrimitiveType);
    }
}

export function getPluginTypeForGlideType(glideType: ColumnType): ParameterProps["type"] | undefined {
    switch (glideType.kind) {
        case "array":
            // FIXME: not all arrays are string arrays, in particular
            // multi-relations!
            return "stringArray";
        case "audio-uri":
            return "string";
        case "boolean": // FIXME: Booleans are perfectly cromulent plugin value types!
            return "string";
        case "date":
            return "dateTime";
        case "date-time":
            return "dateTime";
        case "duration":
            return "string";
        case "email-address":
            return "string";
        case "emoji":
            return "string";
        case "image-uri":
            return "string";
        case "markdown":
            return "string";
        case "number":
            return "number";
        case "phone-number":
            return "string";
        case "string":
            return "string";
        case "table-ref":
            return undefined;
        case "time":
            return "string";
        case "uri":
            return "string";
        case "json":
            return "json";
        default:
            return assertNever(glideType);
    }
}
