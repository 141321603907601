import type { SerializedQuery, QueryAggregate } from "@glide/computation-model-types";
import type {
    ExecuteQueryResponseBody,
    NativeTableQueryID,
    BuilderQueryInfo,
    ExecuteQueryRequest,
    QueryResponseSuccessEntry,
} from "@glide/common-core";
import type { ActionAppEnvironment } from "@glide/common-core/dist/js/components/types";
import { areSerializedQueriesEquivalent } from "@glide/query-conditions";
import type { JSONObject } from "@glide/support";
import { defined, assert } from "@glideapps/ts-necessities";
import { iterableEnumerate } from "collection-utils";
import chunk from "lodash/chunk";
import type { QueryableBackendCaller } from "./queryable-data-store";
import type { MinRequiredVersion } from "@glide/common-core/dist/js/firebase-function-types";

export function makeQueryRequest(
    queryID: string,
    subQuery: SerializedQuery,
    minVersion: MinRequiredVersion | undefined,
    appUserID: string | undefined
): ExecuteQueryRequest {
    return {
        queryID,
        minVersion,
        appUserID,
        ...subQuery,
        limit: subQuery.limit === Number.MAX_SAFE_INTEGER ? undefined : subQuery.limit,
    };
}

// The `executeQuery` call gives us two different opportunities to reduce the
// number of calls for aggregate queries:

// The first one is that we can send it more than one query, as long as they
// all query the same table.  In that case the backend will do all those
// queries to the database independently, but we still save on a lot of
// overhead compared to doing individual calls.  The situation here is
// basically that we're sending two queries with different shapes in one call:
//
//   select * from X where A and B
//   select * from X where C or D

// The second one is that we can combine different aggregates of the same base
// query into a single query, turning something like
//
//   select sum(FOO) as agg from X where A and B group by C
//   select avg(BAR) as agg from X where A and B group by C
//
// into
//
//   select sum(FOO) as agg1, avg(BAR) as agg2 from X where A and B group by C
//
// The original two queries, when combined into one, will now run as a single
// query on the database, which is faster and reduces load.  Note that part of
// this transformation is to rename the resulting column names to avoid
// conflicts.  We need to do this renaming when combining the queries and then
// when "distributing" the results to the callers.

type AggregateQueryResult = ExecuteQueryResponseBody | string;

interface QueuedAggregateQuery {
    readonly query: SerializedQuery;
    readonly queryID: string;
    readonly appUserID: string | undefined;
    readonly resolve: (result: AggregateQueryResult) => void;
}

export interface AggregateQueryAggregatorHelper {
    readonly appEnvironment: ActionAppEnvironment;
    readonly backendCaller: QueryableBackendCaller;
    readonly nativeTableID: NativeTableQueryID;
    readonly builderInfo: true | BuilderQueryInfo | undefined;
    readonly minVersion: MinRequiredVersion | undefined;
}

// We can combine two queries if they target the same app user ID, and they're
// the same except for the aggregations they do.
function canCombine(e1: QueuedAggregateQuery, e2: QueuedAggregateQuery) {
    if (e1.appUserID !== e2.appUserID) return false;

    return areSerializedQueriesEquivalent(
        {
            ...e1.query,
            groupBy: {
                ...defined(e1.query.groupBy),
                aggregates: [],
            },
        },
        {
            ...e2.query,
            groupBy: {
                ...defined(e2.query.groupBy),
                aggregates: [],
            },
        }
    );
}

// A `combination` is an array of `QueuedAggregateQuery`, all of which share
// the same base query, i.e. which can be combined into a single aggregation
// query.
type AggregateQueryCombination = readonly QueuedAggregateQuery[];

function findCombinations(queue: readonly QueuedAggregateQuery[]): readonly AggregateQueryCombination[] {
    const combinations: QueuedAggregateQuery[][] = [];

    for (const entry of queue) {
        let combination: QueuedAggregateQuery[] | undefined;
        for (const c of combinations) {
            const e = defined(c[0]);
            if (canCombine(entry, e)) {
                combination = c;
                break;
            }
        }
        if (combination === undefined) {
            combination = [];
            combinations.push(combination);
        }

        combination.push(entry);
    }

    return combinations;
}

function makeName(i: number, name: string) {
    return `${i}-${name}`;
}

// Combines all queries in a combination into a single query, which involves
// renaming the result columns.
function combineCombination(combination: AggregateQueryCombination, minVersion: MinRequiredVersion | undefined) {
    const aggregates: QueryAggregate[] = [];

    for (const [i, entry] of iterableEnumerate(combination)) {
        for (const agg of defined(entry.query.groupBy).aggregates) {
            aggregates.push({
                ...agg,
                name: makeName(i, agg.name),
            });
        }
    }

    const firstEntry = defined(combination[0]);
    const query: SerializedQuery = {
        ...firstEntry.query,
        groupBy: {
            ...defined(firstEntry.query.groupBy),
            aggregates,
        },
    };

    return makeQueryRequest(firstEntry.queryID, query, minVersion, firstEntry.appUserID);
}

// Pries apart the single response to a combination by reversing the renaming,
// and calls the `resolve` functions.
function resolveCombination(queryResponse: QueryResponseSuccessEntry, combination: AggregateQueryCombination) {
    for (const [i, entry] of iterableEnumerate(combination)) {
        const rows = queryResponse.rows.map((fullRow: any) => {
            const r: JSONObject = {
                group: fullRow.group,
                count: fullRow.count,
            };
            for (const agg of defined(entry.query.groupBy).aggregates) {
                r[agg.name] = fullRow[makeName(i, agg.name)];
            }
            return r;
        });

        const entryResponse: ExecuteQueryResponseBody = {
            responses: [
                {
                    ...queryResponse,
                    queryID: entry.queryID,
                    rows,
                },
            ],
        };

        entry.resolve(entryResponse);
    }
}

export class AggregateQueryAggregator {
    private readonly queue: QueuedAggregateQuery[] = [];

    constructor(private readonly _helper: AggregateQueryAggregatorHelper) {}

    private async runCombinationChunk(combinationChunk: readonly AggregateQueryCombination[]) {
        const response = await this._helper.backendCaller.requestExecuteQuery(
            this._helper.appEnvironment,
            this._helper.nativeTableID,
            this._helper.builderInfo,
            combinationChunk.map(combination => combineCombination(combination, this._helper.minVersion))
        );

        for (const combination of combinationChunk) {
            if (typeof response === "string") {
                for (const entry of combination) {
                    entry.resolve(response);
                }
                continue;
            }

            const firstEntry = defined(combination[0]);

            const queryResponse = response.responses.find(r => r.queryID === firstEntry.queryID);
            if (queryResponse?.kind !== "success") {
                for (const entry of combination) {
                    entry.resolve(queryResponse?.message ?? "No response for query");
                }
                continue;
            }

            resolveCombination(queryResponse, combination);
        }
    }

    private runQueries() {
        const queue = Array.from(this.queue);
        this.queue.length = 0;

        const combinations = findCombinations(queue);

        // `chunk` on an empty array will produce no chunks
        for (const combinationChunk of chunk(combinations, 5)) {
            void this.runCombinationChunk(combinationChunk);
        }
    }

    public requestExecuteQuery(query: SerializedQuery, queryID: string): Promise<AggregateQueryResult> {
        assert(query.groupBy !== undefined);

        return new Promise(resolve => {
            this.queue.push({
                query,
                queryID,
                // We need to get the app user ID here because it can change.
                appUserID: this._helper.appEnvironment?.authenticator.appUserID,
                resolve,
            });
            // Use a bit of a timeout to let a bunch of aggregations
            // accumulate.
            setTimeout(() => this.runQueries(), 10);
        });
    }
}
