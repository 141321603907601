import * as iots from "io-ts";
import { stringGlideTypeKind } from "@glide/type-schema";

const yesCodePrimitiveType = iots.union([
    iots.literal("primitive"),
    iots.literal("number"),
    iots.literal("boolean"),
    stringGlideTypeKind,
]);
const yesCodeType = iots.union([
    yesCodePrimitiveType,
    iots.type({
        kind: iots.literal("array"),
        items: yesCodePrimitiveType,
    }),
]);
export type YesCodeType = iots.TypeOf<typeof yesCodeType>;

const yesCodePrimitiveValue = iots.union([iots.string, iots.number, iots.boolean]);

const yesCodeValue = iots.union([
    yesCodePrimitiveValue,
    iots.readonlyArray(iots.union([yesCodePrimitiveValue, iots.undefined])),
]);
export type YesCodeValue = iots.TypeOf<typeof yesCodeValue>;

const yesCodeParameterSpec = iots.type({
    name: iots.string,
    displayName: iots.string,
    type: yesCodeType,
});

export const yesCodeColumnManifest = iots.intersection([
    iots.type({
        name: iots.string,
        description: iots.string,
        deprecated: iots.union([iots.boolean, iots.undefined]),
        author: iots.string,
        params: iots.readonlyArray(yesCodeParameterSpec),
        result: iots.type({
            type: yesCodeType,
        }),
    }),
    iots.partial({
        kind: iots.literal("column"),
        category: iots.string,
        title: iots.string,
        icon: iots.string,
        released: iots.union([iots.literal("direct"), iots.literal("sandboxed")]),
    }),
]);
export type YesCodeColumnManifest = iots.TypeOf<typeof yesCodeColumnManifest>;

const yesCodeParameter = iots.intersection([
    iots.type({
        name: iots.string,
        type: yesCodeType,
    }),
    iots.partial({
        value: yesCodeValue,
    }),
]);
export type YesCodeParameter = iots.TypeOf<typeof yesCodeParameter>;

const yesCodeColumnRequest = iots.type({
    key: iots.string,
    params: iots.readonlyArray(yesCodeParameter),
});
export type YesCodeColumnRequest = iots.TypeOf<typeof yesCodeColumnRequest>;

export const yesCodeColumnResponse = iots.intersection([
    iots.type({
        key: iots.string,
    }),
    iots.partial({
        result: iots.type({
            value: yesCodeValue,
        }),
        type: yesCodeType,
        error: iots.string,
    }),
]);

export const approvedYesCodeHost = "column.sh";
