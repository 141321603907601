import { AppIcon, GlideIcon, useDebouncedValue } from "@glide/common";
import { InputsValidContext } from "@glide/common-core/dist/js/support/inputs-valid-context";
import { assert } from "@glideapps/ts-necessities";
import debounce from "lodash/debounce";
import * as React from "react";

import { withLabelAndByline } from "../../lib/with-label";
import { TokenEntry } from "../token-entry/token-entry";
import { Error, HighlightTextAreaContainer } from "./highlight-textarea-style";
import { SimpleTooltip } from "../tooltip/simple-tooltip";

type InputValue = string | number | readonly string[] | undefined;

interface Props
    extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    placeholder?: string;
    inputRef?: any;
    error?: string;
    monospace?: boolean;
    debounceUpdate?: boolean;
    minHeight?: number;
    maxHeight?: number;
    alignTop?: boolean;
    onContextButton?: () => void;
    validate?: (newVal: string) => boolean;
    showInlineWarning?: boolean;
    warningType?: "error" | "warn";
}

export const HighlightTextArea: React.VFC<Props> = p => {
    const {
        value,
        ref,
        onFocus,
        onBlur,
        monospace,
        onChange,
        debounceUpdate,
        onContextButton,
        minHeight,
        maxHeight,
        alignTop,
        validate,
        showInlineWarning,
        warningType,
        ...rest
    } = p;

    const [focused, setFocused] = React.useState(false);
    const [debounceValue, setDebounceValue] = React.useState<InputValue>(value);

    const [myIdentity] = React.useState({});
    const inputsValid = React.useContext(InputsValidContext);

    React.useEffect(() => {
        if (debounceUpdate !== true) return;
        setDebounceValue(value);
    }, [debounceUpdate, value]);

    const debouncedSendUpdate = React.useMemo(
        () =>
            debounce((e: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (onChange !== undefined) {
                    onChange(e);
                }
                inputsValid?.setInput(myIdentity, true);
            }, 500),
        [onChange, inputsValid, myIdentity]
    );

    const debouncedOnChange = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (validate !== undefined && !validate(e.target.value)) {
                return;
            }
            inputsValid?.setInput(myIdentity, false);
            debouncedSendUpdate(e);
            setDebounceValue(e.target.value);
        },
        [validate, debouncedSendUpdate, inputsValid, myIdentity]
    );

    const onFocusInner = React.useCallback(
        (event: React.FocusEvent<HTMLTextAreaElement>) => {
            if (onFocus !== undefined) {
                onFocus(event);
            }
            setFocused(true);
        },
        [onFocus]
    );

    const onBlurInner = React.useCallback(
        (event: React.FocusEvent<HTMLTextAreaElement>) => {
            if (onBlur !== undefined) {
                onBlur(event);
            }
            setFocused(false);
        },
        [onBlur]
    );

    let useValue = value ?? "";
    let useOnChange = onChange;
    if (debounceUpdate === true) {
        useValue = debounceValue ?? "";
        useOnChange = debouncedOnChange;
    }

    const debounceInlineWarning = useDebouncedValue(showInlineWarning, 500);

    assert(p.onChange !== undefined, "GrowingEntry must be a controlled input area");

    return (
        <>
            <HighlightTextAreaContainer
                focused={focused}
                showContextButton={onContextButton !== undefined}
                minHeight={minHeight}
                maxHeight={maxHeight}
                alignTop={alignTop}
                font={monospace === true ? "'Roboto Mono', monospace" : undefined}>
                <TokenEntry
                    {...rest}
                    onFocus={onFocusInner}
                    onBlur={onBlurInner}
                    value={useValue}
                    onChange={useOnChange}
                />
                <div className="context-button" tw={"flex-none"}>
                    {p.error !== undefined && debounceInlineWarning === true && <InlineError error={p.error} />}
                    {onContextButton !== undefined && (
                        <button
                            tw="h-4 w-4"
                            onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                onContextButton();
                            }}>
                            <AppIcon icon="moreVertical" size={16} />
                            <span tw="sr-only">More</span>
                        </button>
                    )}
                </div>
            </HighlightTextAreaContainer>
            {p.error !== undefined && showInlineWarning !== true && (
                <Error className="error" warningType={warningType}>
                    {p.error}
                </Error>
            )}
        </>
    );
};

export const LabeledHighlightTextArea = withLabelAndByline(HighlightTextArea, "stacked");

interface InlineErrorProps {
    readonly error: string;
}

const InlineError: React.VFC<InlineErrorProps> = p => {
    const { error } = p;
    return (
        <SimpleTooltip text={error} instant>
            <GlideIcon kind="monotone" icon="mt-warning" iconSize={16} tw="text-text-danger" />
        </SimpleTooltip>
    );
};
