/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { assert } from "@glideapps/ts-necessities";
import { v4 as uuidv4 } from "uuid";

export const baseUrl = "https://api.glideapps.com";

const apiKey = glide.makeParameter({
    type: "secret",
    name: "API Key",
    description: "The API key to use for the Glide API",
    required: true,
});

export const plugin = glide.newPlugin({
    name: "Glide Big Tables",
    id: "glide-api",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/glide.png",
    description: "Data operations for Glide Big Tables",
    tier: "business",
    parameters: { apiKey },
    experimentFlag: "allowGlideAPIPlugin",
    isExperimental: true,
});

plugin.useSecret({
    kind: "authorization-bearer",
    value: apiKey,
    baseUrl,
});

/*
const tableName = glide.makeParameter({
    type: "string",
    name: "Table Name",
    description: "The name of the table",
    required: true,
});
*/

const tableId = plugin.makeAsyncParameter({
    key: "table-id",
    name: "Table",
    defaultDisplayLabel: "Select a table",
    required: true,
    values: async context => {
        const response = await context.fetch(`${baseUrl}/tables`);
        const tablesResult = await parseGlideAPIResponse(
            response,
            tablesResult => tablesResult as { data: { id: string; name: string }[] }
        );
        if (!tablesResult.ok) {
            context.log("Failed to fetch tables", tablesResult.message);
            return [];
        }
        return tablesResult.result.data
            .map(({ id: value, name: label }) => ({ value, label }))
            .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
    },
});

const stashId = glide.makeParameter({
    type: "string",
    name: "Stash ID",
    description: "The ID of the stash",
    required: true,
});

const rowData = glide.makeParameter({
    type: "string",
    name: "Row Data",
    description: "JSON, CSV, or TSV",
    required: true,
});

const stashIdOrRowData = glide.makeParameter({
    type: "enum",
    name: "Data Source",
    description:
        "Inline Row Data can be used for small amounts of data, otherwise select Stash and provide a Stash ID previously used in one or more Add to Stash actions",
    values: [
        { value: "rowData", label: "Inline Row Data" },
        { value: "stashId", label: "Stash" },
    ],
    defaultValue: "rowData",
});

const stashIdWhenSelected = stashId.when(stashIdOrRowData, "is", "stashId");
const rowDataWhenSelected = rowData.when(stashIdOrRowData, "is", "rowData");

const onSchemaError = glide.makeParameter({
    type: "enum",
    name: "On Schema Error",
    description: "What to do if the schema of the incoming data does not match the existing table",
    values: [
        { value: "abort", label: "Abort" },
        { value: "dropColumns", label: "Drop Columns" },
        { value: "updateSchema", label: "Update Schema" },
    ],
});

export enum ComplexValueBehavior {
    /** Default behavior - fails when encountering complex values (objects/arrays) as the API does not support them */
    Error = "error",
    /** Attempts to coerce complex values into simple ones. For objects with a single field, uses that field's value. Arrays are dropped. */
    Smart = "smart",
    /** Ignores complex values (objects/arrays) by dropping them from the data */
    Ignore = "ignore",
}

const complexValueBehavior = glide.makeParameter({
    type: "enum",
    name: "Complex Value Behavior",
    description: "How to handle complex object values in the data",
    values: [
        { value: ComplexValueBehavior.Error, label: "Error" },
        { value: ComplexValueBehavior.Smart, label: "Smart" },
        { value: ComplexValueBehavior.Ignore, label: "Ignore" },
    ],
    defaultValue: ComplexValueBehavior.Error,
});

// Helper function to handle complex values based on the specified behavior
export function handleComplexValue(value: unknown, behavior: ComplexValueBehavior): glide.Result<unknown | undefined> {
    // First, check if the value is complex (object or array)
    const isComplex = typeof value === "object" && value !== null;
    if (!isComplex) {
        return glide.Result.Ok(value);
    }

    // For complex values, handle based on behavior
    if (behavior === ComplexValueBehavior.Error) {
        return glide.Result.FailPermanent("Complex values are not supported", {
            isPluginError: false,
        });
    }

    // Drop arrays in both Smart and Ignore modes
    if (Array.isArray(value) || behavior === ComplexValueBehavior.Ignore) {
        return glide.Result.Ok(undefined);
    }

    // Smart behavior for objects
    const entries = Object.entries(value);
    if (entries.length === 0) {
        return glide.Result.FailPermanent("Empty objects are not allowed", {
            isPluginError: false,
        });
    }
    if (entries.length === 1) {
        return glide.Result.Ok(entries[0][1]);
    }

    return glide.Result.FailPermanent(
        `Object has ${entries.length} fields, but only single-field objects can be simplified in smart mode`,
        { isPluginError: false }
    );
}

// Helper function to process row data with complex value handling
export function processRowData(data: unknown[], behavior: ComplexValueBehavior): glide.Result<unknown[]> {
    const processedRows: unknown[] = [];

    for (const row of data) {
        if (typeof row !== "object" || row === null || Array.isArray(row)) {
            processedRows.push(row);
            continue;
        }

        const processedRow: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(row)) {
            const processedValue = handleComplexValue(value, behavior);
            if (!processedValue.ok) {
                return processedValue;
            }
            if (processedValue.result !== undefined) {
                processedRow[key] = processedValue.result;
            }
        }
        processedRows.push(processedRow);
    }

    return glide.Result.Ok(processedRows);
}

// exported for tests
export function getRequestHeadersAndBody(
    {
        stashId,
        rowData,
        stashIdOrRowData,
        complexValueBehavior = ComplexValueBehavior.Error,
    }: {
        stashId?: string;
        rowData?: string;
        stashIdOrRowData?: string;
        complexValueBehavior?: string;
    },
    bodyKey?: string
): glide.Result<{ headers: Record<string, string>; body: string }> {
    if (stashIdOrRowData === "stashId" && stashId !== undefined && stashId !== "") {
        return glide.Result.Ok({
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyKey === undefined ? { $stashID: stashId } : { [bodyKey]: { $stashID: stashId } }),
        });
    }
    if (stashIdOrRowData === "stashId" || rowData === undefined || rowData === "") {
        return glide.Result.FailPermanent("Missing required parameter", {
            isPluginError: false,
        });
    }

    let parsed: unknown;
    try {
        parsed = JSON.parse(rowData);
    } catch {
        // FIXME: Can we validate CSV/TSV data better? This just ensures that there are at least 2 lines
        //  (e.g. column names header and at least one row of data)
        if (!rowData.includes("\n")) {
            return glide.Result.FailPermanent("Row data must be in JSON, CSV, or TSV format", {
                isPluginError: false,
            });
        }
        // HACK: The API accepts CSV or TSV data using this content type
        return glide.Result.Ok({
            headers: { "Content-Type": "text/csv" },
            body: rowData,
        });
    }

    if (!Array.isArray(parsed)) {
        return glide.Result.FailPermanent("JSON must be an array", {
            isPluginError: false,
        });
    }

    // Process the data with complex value handling if needed
    const processedData = processRowData(parsed, complexValueBehavior as ComplexValueBehavior);
    if (!processedData.ok) {
        return processedData;
    }

    return glide.Result.Ok({
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyKey === undefined ? processedData.result : { [bodyKey]: processedData.result }),
    });
}

async function parseGlideAPIResponse<T>(
    response: Response,
    mapResult: (result: unknown) => T
): Promise<glide.Result<T>> {
    const json = await response.json();
    if (!response.ok) {
        const message = `Glide API Error: ${json.error.message}`;
        return glide.Result.FailPermanent(message, {
            isPluginError: false,
        });
    }
    return glide.Result.Ok(mapResult(json));
}

// List tables disabled for now. We should consider making a data source for this type of stuff instead,
//  since the data is tabular in nature, and working with it in a JSON column is not a great experience.
/*
const tablesResult = glide.makeParameter({
    type: "json",
    name: "Tables",
    description: "The tables in the Glide API",
});

plugin.addComputation({
    name: "List Tables",
    id: "list-tables",
    description: "List all Big Tables",
    parameters: {},
    results: { tablesResult },
    async execute(context) {
        const response = await context.fetch(`${baseUrl}/tables`);
        return await parseGlideAPIResponse(response, tablesResult => ({ tablesResult }));
    },
});
*/

// Create new table disabled for now. Is this useful without a way to link it to an app?
/*
plugin.addAction({
    name: "Create Table",
    id: "create-table",
    description: "Create a new Big Table",
    parameters: {
        tableName,
        rowData,
    },
    results: { tableId },
    async execute(context, { tableName, rowData }) {
        if (tableName === undefined) {
            return glide.Result.FailPermanent("Table name is required", {
                isPluginError: false,
            });
        }
        const contentType = getRowDataContentType(rowData);
        if (!contentType.ok) return contentType;

        const url = new URL(`${baseUrl}/tables`);
        url.searchParams.set("name", tableName);

        const response = await context.fetch(url.toString(), {
            method: "POST",
            headers: {
                "Content-Type": contentType.result,
            },
            body: rowData,
        });
        return await parseGlideAPIResponse(response, json => ({ tableId: json.data.tableID }));
    },
});
*/

plugin.addAction({
    name: "Add Rows",
    id: "add-rows",
    description: "Add rows to a Big Table",
    parameters: {
        tableId,
        stashIdOrRowData,
        rowData: rowDataWhenSelected,
        stashId: stashIdWhenSelected,
        onSchemaError,
        complexValueBehavior,
    },
    results: {},
    async execute(context, { tableId, onSchemaError, ...requestParams }) {
        if (typeof tableId !== "string" || tableId === "") {
            return glide.Result.FailPermanent("Table ID is required", {
                isPluginError: false,
            });
        }

        const url = new URL(`${baseUrl}/tables/${tableId}/rows`);
        if (onSchemaError !== undefined) {
            url.searchParams.set("onSchemaError", onSchemaError);
        }

        const request = getRequestHeadersAndBody(requestParams);
        if (!request.ok) return request;

        const response = await context.fetch(url.toString(), {
            method: "POST",
            ...request.result,
        });

        return await parseGlideAPIResponse(response, () => ({}));
    },
});

plugin.addAction({
    name: "Overwrite Table",
    id: "overwrite-table",
    description: "Replace all rows in a Big Table",
    parameters: {
        tableId,
        stashIdOrRowData,
        rowData: rowDataWhenSelected,
        stashId: stashIdWhenSelected,
        onSchemaError,
        complexValueBehavior,
    },
    results: {},
    async execute(context, { tableId, onSchemaError, ...requestParams }) {
        if (typeof tableId !== "string" || tableId === "") {
            return glide.Result.FailPermanent("Table ID is required", {
                isPluginError: false,
            });
        }

        const url = new URL(`${baseUrl}/tables/${tableId}`);
        if (onSchemaError !== undefined) {
            url.searchParams.set("onSchemaError", onSchemaError);
        }

        const request = getRequestHeadersAndBody(requestParams, "rows");
        if (!request.ok) return request;

        const response = await context.fetch(url.toString(), {
            method: "PUT",
            ...request.result,
        });

        return await parseGlideAPIResponse(response, () => ({}));
    },
});

plugin.addClientComputation({
    name: "Create Stash ID",
    id: "create-stash-id",
    description: "Creates a unique stash ID",
    parameters: {},
    results: { stashId },
    async execute() {
        // Generate a unique ID with a date prefix for better organization
        const date = new Date().toISOString().slice(0, 10).replace(/-/g, "");
        const uniqueId = uuidv4().slice(0, 6);
        return glide.Result.Ok({ stashId: `${date}-${uniqueId}` });
    },
});

plugin.addAction({
    name: "Add to Stash",
    id: "add-to-stash",
    description: "Add data to a stash for later use",
    parameters: {
        stashId,
        rowData,
    },
    results: {},
    async execute(context, { stashId, ...requestParams }) {
        if (typeof stashId !== "string" || stashId === "") {
            return glide.Result.FailPermanent("Stash ID is required", {
                isPluginError: false,
            });
        }

        const request = getRequestHeadersAndBody(requestParams);
        if (!request.ok) return request;

        // For now, we'll just use elapsed time as the stash serial, which hopefully ensures that
        //  successive Add to Stash actions will append to the stash and not overwrite each other.
        //  1. `performance.now()` only measures elapsed time since the Node process was started,
        //   so we need to add it to the time origin to get absolute time.
        //  2. Multiply by 100 to get a more granular number when converting to an integer.
        //  3. Finally, convert it to an integer so the stash chunks are properly sorted.
        const serial = Math.round((performance.now() + performance.timeOrigin) * 100);
        assert(Number.isSafeInteger(serial));

        const response = await context.fetch(`${baseUrl}/stashes/${stashId}/${serial}`, {
            method: "PUT",
            ...request.result,
        });

        return await parseGlideAPIResponse(response, () => ({}));
    },
});
