import type { AutomationTriggerKind } from "@glide/automations-codecs";
import { defaultEminenceFlagsValues } from "@glide/billing-types";
import { useEminenceFlags } from "@glide/common-components";
import { type Subscription, PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import type { Owner } from "@glide/common-core/dist/js/Database";
import { useFeatureSettings } from "@glide/common-core/dist/js/use-feature-settings";
import { getRequiredPlan } from "@glide/component-utils";
import { useCallback, useMemo } from "react";
import type { SimpleModalContextProps } from "../components/modal-provider-v2/modal-context";
import { useModals } from "../components/modal-provider-v2/modal-provider-v2";
import type { UpgradeModalConfig } from "../components/paywall/upgrade-utils";
import type { ConfiguredPluginTrigger } from "../components/windowed-modal/modals/select-action-trigger-type";

export const CONTACT_SALES_URL =
    "https://www.glideapps.com/enterprise?contactSales&utm_source=upgrade-modal&utm_medium=referral&utm_campaign=workflows";

type CanUseAutomationsEditor = {
    canUseAutomationTrigger: (kind: AutomationTriggerKind) => boolean;
    showUpgradeOrContactSalesFlow: (pluginTrigger?: ConfiguredPluginTrigger) => Promise<void>;
};

export function useCanUseAutomationsEditorHelpers(
    owner: Owner | undefined,
    showUpgradeModal: (
        modals: SimpleModalContextProps,
        owner: Owner,
        config: UpgradeModalConfig,
        subscription?: Subscription
    ) => Promise<void>
): CanUseAutomationsEditor {
    const { data: maybeEminenceFlags } = useEminenceFlags(owner?.id);
    const { automationTriggerKind, pluginTier } = maybeEminenceFlags ?? defaultEminenceFlagsValues;
    const [enforceAutomationEntitlements] = useFeatureSettings(["enforceAutomationEntitlements"]);

    const canUseAutomationTrigger = useCallback<(kind: AutomationTriggerKind) => boolean>(
        kind => {
            if (!enforceAutomationEntitlements) return true;

            return automationTriggerKind.includes(kind);
        },
        [automationTriggerKind, enforceAutomationEntitlements]
    );

    const modals = useModals();
    const showUpgradeOrContactSalesFlow = useCallback(
        async (pluginTrigger: ConfiguredPluginTrigger | undefined) => {
            const isEnteprise = pluginTier === "enterprise";
            if (owner === undefined) return;
            if (isEnteprise) {
                window.open(CONTACT_SALES_URL, "_blank");
            } else {
                const targetPlan = getRequiredPlan(pluginTrigger?.tier, pluginTier) ?? PlanKind.V3Starter;
                await showUpgradeModal(modals, owner, {
                    targetPlan,
                    showTargetPlanDetails: true,
                });
            }
        },
        [modals, owner, pluginTier, showUpgradeModal]
    );

    return useMemo(
        () => ({
            canUseAutomationTrigger,
            showUpgradeOrContactSalesFlow,
        }),
        [canUseAutomationTrigger, showUpgradeOrContactSalesFlow]
    );
}
