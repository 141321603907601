import { GlideIcon } from "@glide/common";
import type { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import type { SerializablePluginMetadata } from "@glide/plugins";
import React from "react";
import { css } from "styled-components";
import tw from "twin.macro";
import { UpgradePill } from "../paywall/upgrade-pill";
import { FeatureRow } from "./plugin-details";
import { SettingsSectionHeader } from "./setting-section-header";
import { glideIconPropsCodec } from "@glide/plugins-codecs";

const MAX_FEATURES = 4;

interface Props extends React.PropsWithChildren {
    plugin: SerializablePluginMetadata;
    requiredPlan?: PlanKind;
    onExpandFeatures: (plugin: SerializablePluginMetadata) => void;
    size?: "medium" | "large";
    extended?: boolean;
    splitFeaturesInTwoColumns?: boolean;
    hideDescription?: boolean;
}

export const PluginDescription: React.VFC<Props> = props => {
    const {
        plugin,
        requiredPlan,
        onExpandFeatures,
        size = "medium",
        extended = true,
        splitFeaturesInTwoColumns = false,
        hideDescription = false,
    } = props;
    const { icon, name, description, queryableDataSources = [] } = plugin;

    const features = [
        ...plugin.actions.map(a => ["action", a] as const),
        ...plugin.computations.map(c => ["column", c] as const),
        ...plugin.signOns.map(s => ["sign-on", s] as const),
        ...queryableDataSources.map(s => ["data-source", s] as const),
    ];
    let hiddenFeaturesCount = 0;
    if (features.length > MAX_FEATURES) {
        hiddenFeaturesCount = features.length - (MAX_FEATURES - 1);
        features.splice(MAX_FEATURES - 1, hiddenFeaturesCount);
    }

    const onExpand = React.useCallback(() => {
        onExpandFeatures(plugin);
    }, [plugin, onExpandFeatures]);

    return (
        <div tw="text-text-base">
            <div tw="flex">
                {glideIconPropsCodec.is(icon) ? (
                    <GlideIcon
                        {...icon}
                        iconSize={size === "large" ? 64 : 48}
                        css={css`
                            ${size === "large" && tw`mr-4`}
                            ${size === "medium" && tw`mr-3`}
                        `}
                    />
                ) : (
                    <img
                        css={css`
                            ${size === "large" && tw`w-16 h-16 mr-4 rounded-lg`}
                            ${size === "medium" && tw`w-12 h-12 mr-3 rounded-lg`}
                        `}
                        src={icon}
                        alt="Icon"
                    />
                )}

                <div>
                    <div tw="flex items-center mb-1">
                        <h3
                            css={css`
                                ${size === "large" && tw`mr-2 font-size[18px] font-semibold`}
                                ${size === "medium" && tw`mr-2 font-semibold text-builder-lg`}
                            `}
                        >
                            {name}
                        </h3>

                        {requiredPlan !== undefined && (
                            <UpgradePill
                                planKind={requiredPlan}
                                extended={extended}
                                location={`integration_settings_${name}`}
                            />
                        )}
                    </div>
                    {description && (
                        <p
                            css={css`
                                ${size === "large" && tw`text-text-base text-builder-base`}
                                ${size === "medium" && tw`text-text-pale text-builder-sm`}
                            `}
                        >
                            {description}
                            {plugin.documentationUrl !== undefined && (
                                <>
                                    {description.endsWith(".") === false && <span>.</span>}{" "}
                                    <a
                                        tw="text-text-accent hover:underline!"
                                        href={plugin.documentationUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Learn more
                                    </a>
                                    .
                                </>
                            )}
                        </p>
                    )}
                </div>
            </div>
            <SettingsSectionHeader>Features</SettingsSectionHeader>
            <div
                style={{
                    display: splitFeaturesInTwoColumns ? "grid" : "flex",
                    flexDirection: "column",
                    gridTemplateColumns: splitFeaturesInTwoColumns ? "repeat(2,minmax(0,1fr))" : undefined,
                    gap: 8,
                }}
            >
                {features.map(([type, f]) => (
                    <FeatureRow
                        key={f.id}
                        type={type}
                        title={f.name}
                        description={hideDescription ? undefined : f.description}
                    />
                ))}
                {hiddenFeaturesCount > 0 && (
                    <div tw="flex items-center text-text-accent cursor-pointer" className="group" onClick={onExpand}>
                        <div tw="p-1 bg-b100 group-hover:bg-b400 group-hover:text-white rounded-full mr-2">
                            <GlideIcon kind="stroke" icon="st-plus-add" iconSize={16} />
                        </div>
                        <div tw="text-builder-sm font-medium">{hiddenFeaturesCount} more</div>
                    </div>
                )}
            </div>
        </div>
    );
};
