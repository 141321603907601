import type { MinimalAppFacilities } from "@glide/common-core/dist/js/components/types";
import type { EminenceFlags } from "@glide/billing-types";
import {
    type AllQuotaValues,
    type QuotaValues,
    type QuotaValuesForKinds,
    type QuotaDocument,
    QuotaKind,
    quotaInfos,
} from "@glide/common-core/dist/js/Database/quotas";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import type { HeartbeatAndQuotaManager } from "@glide/common-core/dist/js/heartbeat-and-quota";
import { getIsOnline } from "@glide/common-core/dist/js/hooks/use-network-status";
import { getPersistentDeviceID } from "@glide/common-core/dist/js/persistent-device-id";
import * as routes from "@glide/common-core/dist/js/routes";
import {
    isDocumentHidden,
    onVisibilityChange,
    removeOnVisibilityChangeHandler,
} from "@glide/common-core/dist/js/support/browser-hacks";
import { standalone } from "@glide/common-core/dist/js/support/device";
import { defined, DefaultMap, isEnumValue } from "@glideapps/ts-necessities";
import {
    getBrowserLanguage,
    getQuotasKey,
    isOlderThan,
    isResponseOK,
    localStorageGetItem,
    localStorageSetItem,
    logError,
} from "@glide/support";
import entries from "lodash/entries";
import fromPairs from "lodash/fromPairs";
import type { EnsureDataLivelinessBody } from "@glide/common-core";

const offlineHeartbeatPeriod = 60 * 1000;
const onlineHeartbeatPeriod = 15 * 60 * 1000;
const heartbeatCheckPeriod = 10 * 1000;
const heartbeatImmediateDelay = 250;

const getHeartbeatPeriod = () => (getIsOnline() === true ? onlineHeartbeatPeriod : offlineHeartbeatPeriod);

function loadQuotasFromLocalStorage(appID: string): QuotaValuesForKinds | undefined {
    const fromStorage = localStorageGetItem(getQuotasKey(appID));
    if (fromStorage === undefined) return undefined;

    return JSON.parse(fromStorage) as QuotaValuesForKinds;
}

function saveQuotasToLocalStorage(appID: string, quotas: QuotaValuesForKinds): void {
    localStorageSetItem(getQuotasKey(appID), JSON.stringify(quotas));
}

// sendingForEditor -> appID
const ensureDataLivelinessTimestamps = new DefaultMap<boolean, Map<string, number>>(() => new Map());

export class HeartbeatManager {
    private intervalHandle: NodeJS.Timeout | undefined = undefined;
    // -1 to avoid timing based race conditions.
    private lastTime = new Date(Date.now() - getHeartbeatPeriod() - 1);
    private refcount = 0;
    private currentlyForEditor: boolean = false;
    private sendingForEditor: boolean = false;

    constructor(private readonly appID: string, private readonly appFacilities: MinimalAppFacilities) {}

    public enter(runImmediately: boolean = true): void {
        this.refcount++;
        if (this.refcount === 1) {
            // This implies refcount was 0 before we incremented. It's
            // important that the increment happens _before_ we fire this,
            // because otherwise we'll clear our interval incorrectly, and
            // fire twice.
            onVisibilityChange(this.handleVisibilityChange);
        }
        if (this.intervalHandle === undefined) {
            // We only need to reset the lastTime if we are just now
            // entering the heartbeat manager.
            if (runImmediately) {
                // -1 to avoid timing based race conditions.
                this.lastTime = new Date(Date.now() - getHeartbeatPeriod() - 1);
            }
            this.resetInterval(runImmediately);
        }
    }

    public exit(): void {
        if (this.refcount > 0) {
            this.refcount--;
        }
        if (this.refcount === 0) {
            removeOnVisibilityChangeHandler(this.handleVisibilityChange);
            if (this.intervalHandle !== undefined) {
                clearInterval(this.intervalHandle);
                this.intervalHandle = undefined;
            }
        }
    }

    private resetInterval(handleImmediately: boolean): void {
        if (this.intervalHandle !== undefined) {
            clearTimeout(this.intervalHandle);
        }
        this.intervalHandle = setInterval(() => this.handleHeartbeat(), heartbeatCheckPeriod);
        if (handleImmediately) {
            setTimeout(() => {
                if (this.refcount < 1) return;
                this.handleHeartbeat();
            }, heartbeatImmediateDelay);
        }
    }

    private shouldHandleHeartbeat(): boolean {
        return !isDocumentHidden() && isOlderThan(this.lastTime, getHeartbeatPeriod());
    }

    private handleHeartbeat(): void {
        if (!this.shouldHandleHeartbeat()) {
            return;
        }
        if (this.refcount < 1 && this.intervalHandle !== undefined) {
            clearTimeout(this.intervalHandle);
            this.intervalHandle = undefined;
        }
        this.lastTime = new Date();
        const priorTimeMap = ensureDataLivelinessTimestamps.get(this.sendingForEditor);
        const priorTime = priorTimeMap.get(this.appID);
        if (priorTime !== undefined && this.lastTime.getTime() < priorTime + heartbeatCheckPeriod) {
            // Rapid changes to the app definition can cause ensureDataLiveliness to go kinda insane
            // This has extremely negative consequences for our HTTP connections, so we have to
            // prevent it at the global level.
            return;
        }
        priorTimeMap.set(this.appID, this.lastTime.getTime());
        // The significance of the `editor` boolean is that only tables used in
        // the app will be reloaded on automatic refreshes if this is not set to `true`.
        // So, unless you open the Data Editor, periodic refreshes and drive notifications
        // will only cause updates to tables actually used in the app.
        //
        // This does have the side effect of not showing new tables until either
        // 1. The Data Editor has been open at least once
        // 2. The "reload" button has been pressed
        //
        // We'd do this with beaconCloudFunctionWeb, except between 2017 and 2020
        // Chrome wouldn't sendBeacon with JSON data to work around its own internal
        // security SNAFU. ensureDataLiveliness expects actual JSON, and will continue
        // to do so, so no beaconing here.
        const body: EnsureDataLivelinessBody = {
            appID: this.appID,
            editor: this.sendingForEditor,
            deviceID: getPersistentDeviceID(this.appID),
            standalone,
            builder: !routes.isPlayer(),
            respondWithBody: getFeatureSetting("requestFullBodyInEnsureDataLiveliness"),
            locale: getBrowserLanguage(),
        };
        this.appFacilities
            .callAuthIfAvailableCloudFunction("ensureDataLiveliness", body, {})
            // Make sure we aren't just leaking these open
            .then(r => r?.text())
            .catch(e => logError(`Could not ensureDataLiveliness: ${e}`));
        if (!this.currentlyForEditor) {
            this.sendingForEditor = false;
        }
    }

    private handleVisibilityChange = () => {
        if (this.refcount > 0 && this.shouldHandleHeartbeat()) {
            this.resetInterval(true);
        }
    };

    public enterEditor(): void {
        let didReset = false;
        if (!this.sendingForEditor) {
            this.resetInterval(true);
            didReset = true;
        }
        this.sendingForEditor = true;
        this.currentlyForEditor = true;
        // We won't have to run the interval immediately if we did a reset
        // above; the reset already immediately ran the interval.
        this.enter(!didReset);
    }

    public exitEditor(): void {
        this.currentlyForEditor = false;
        this.exit();
    }
}

const cache: Record<
    string, // appID
    {
        onUpdates: Array<(data: QuotaDocument) => void>;
        onErrors: Array<(e: Error) => void>;
        timerHandle: number;
        lastResponse: QuotaDocument | undefined;
    }
> = {};

function pollQuotaDoc(
    appFacilities: MinimalAppFacilities,
    appID: string,
    onUpdate: (data: QuotaDocument) => void,
    onError?: (e: Error) => void
): () => void {
    if (cache[appID] !== undefined) {
        cache[appID].onUpdates = [...cache[appID].onUpdates, onUpdate];
        if (onError !== undefined) cache[appID].onErrors = [...cache[appID].onErrors, onError];

        const last = cache[appID].lastResponse;
        if (last !== undefined) onUpdate(last);
    } else {
        // populate this first on the insane chance doPoll returns synchronously
        cache[appID] = {
            onUpdates: [onUpdate],
            onErrors: onError !== undefined ? [onError] : [],
            timerHandle: -1,
            lastResponse: undefined,
        };
        const doPoll = async () => {
            if (cache[appID].onUpdates.length === 0) return;
            const res = await appFacilities.callCloudFunction("getQuotaStateForApp", { appID }, {});
            try {
                if (!isResponseOK(res)) {
                    throw new Error(`Could not get quotas for app ${appID}: ${res?.statusText}`);
                }
                const json = await res.json();

                // fixme add io-ts validation
                const quotaDoc = json as QuotaDocument;
                cache[appID].lastResponse = quotaDoc;
                for (const update of cache[appID].onUpdates) update(quotaDoc);
            } catch (e: unknown) {
                for (const error of cache[appID].onErrors) error?.(e as Error);
            }
        };
        void doPoll();
        if (!getFeatureSetting("disableHeartbeatInterval")) {
            cache[appID].timerHandle = window.setInterval(doPoll, onlineHeartbeatPeriod);
        }
    }

    // 15 minutes should be fast enough for anyone. If you need it faster, just refresh like a champ.
    // Further optimization: If the user is already over quota, reduce this number.

    return () => {
        if (cache[appID] === undefined) return;
        cache[appID].onUpdates = cache[appID].onUpdates.filter(x => x !== onUpdate);
        cache[appID].onErrors = cache[appID].onErrors.filter(x => x !== onError);
    };
}

abstract class HeartbeatAndQuotaManagerBase implements HeartbeatAndQuotaManager {
    private _heartbeatManager: HeartbeatManager;
    private _mountCount: number = 0;
    private _quotaUnlisten: (() => void) | undefined;
    private _quotas: QuotaValuesForKinds = {};
    private _quotasFromLocalStorage: QuotaValuesForKinds | undefined;

    // NOTE: If the quotaID isn't defined and the eminence stipulates
    // that the Quota Context should be on the org, then quotas won't be
    // handled for the lifetime of the heartbeat manager.
    constructor(private readonly appID: string, private readonly appFacilities: MinimalAppFacilities) {
        this._heartbeatManager = new HeartbeatManager(appID, appFacilities);
        const glideQuotaState = (window as any).glideQuotaState;
        if (glideQuotaState !== undefined) {
            this._quotas = glideQuotaState;
        }
    }

    protected abstract getEminenceFlags(): EminenceFlags;
    protected abstract quotasDidUpdate?(newQuotas: AllQuotaValues, oldQuotas: AllQuotaValues): void;
    protected abstract updateQuotasReached?(kinds: QuotaKind[]): void;

    private loadStoredQuotas(): QuotaValuesForKinds | undefined {
        if (this._quotasFromLocalStorage === undefined) {
            this._quotasFromLocalStorage = loadQuotasFromLocalStorage(this.appID);
        }
        return this._quotasFromLocalStorage;
    }

    public getQuotaValues(kind: QuotaKind): QuotaValues {
        let values = this._quotas[kind];
        if (values === undefined) {
            this.loadStoredQuotas();
            values = this._quotasFromLocalStorage?.[kind];
        }

        return {
            current: values?.current ?? 0,
        };
    }

    public getQuotaReached(kind: QuotaKind): boolean {
        const { quotas } = this.getEminenceFlags();
        const value = this.getQuotaValues(kind);
        const quota = quotas[kind];

        // If we don't have a quota set in the eminenceFlags, we'll be polite
        // and assume it's not enforced.
        if (quota === undefined) return false;

        return value.current > Math.max(1, quota.prepaidUsage + quota.maxOverage);
    }

    private get allQuotas(): AllQuotaValues {
        const pairs = quotaInfos.map(
            qi => [qi.kind, defined(this.getQuotaValues(qi.kind))] as [QuotaKind, QuotaValues]
        );
        return fromPairs(pairs) as AllQuotaValues;
    }

    // This function updates all quotas that belong on the context
    // stated by the Eminence flags.
    private updateQuotas(quotas: QuotaValuesForKinds): void {
        const prior = this.allQuotas;
        const reachedQuotas: QuotaKind[] = [];
        for (const [kind, values] of entries(quotas)) {
            if (values === undefined) continue;

            if (!isEnumValue(QuotaKind, kind)) continue;

            const old = this._quotas[kind];
            if (old === undefined) {
                this._quotas = { ...this._quotas, [kind]: values };
            } else {
                const updates: QuotaValues = { current: values.current };
                this._quotas = { ...this._quotas, [kind]: updates };
            }

            const newValues = this.getQuotaValues(kind);
            if (newValues === undefined) continue;

            const { prepaidUsage, maxOverage } = this.getEminenceFlags().quotas[kind] ?? {
                prepaidUsage: Number.MAX_SAFE_INTEGER,
                maxOverage: 0,
            };
            const limit = prepaidUsage + maxOverage;
            const reached = newValues.current > limit;
            if (reached) {
                reachedQuotas.push(kind);
            }
        }

        this.updateQuotasReached?.(reachedQuotas);

        saveQuotasToLocalStorage(this.appID, this.allQuotas);

        this.quotasDidUpdate?.(this.allQuotas, prior);
    }

    private ensureQuotaPollingBackendIfNeeded(): void {
        if (getFeatureSetting("disableHeartbeatEnforcement")) return;
        if (this._mountCount > 0) {
            if (this._quotaUnlisten === undefined) {
                this._quotaUnlisten = pollQuotaDoc(this.appFacilities, this.appID, data => {
                    this.updateQuotas(data.quotas);
                });
            }
        } else {
            if (this._quotaUnlisten !== undefined) {
                this._quotaUnlisten();
                this._quotaUnlisten = undefined;
            }
        }
    }

    public isMounted(inEditor: boolean): void {
        if (inEditor) {
            this._heartbeatManager.enterEditor();
        } else {
            this._heartbeatManager.enter();
        }

        this._mountCount += 1;
        this.ensureQuotaPollingBackendIfNeeded();
    }

    public isUnmounted(inEditor: boolean): void {
        if (inEditor) {
            this._heartbeatManager.exitEditor();
        } else {
            this._heartbeatManager.exit();
        }

        this._mountCount -= 1;
        this.ensureQuotaPollingBackendIfNeeded();
    }
}

export abstract class SimpleHeartbeatAndQuotaManager extends HeartbeatAndQuotaManagerBase {
    constructor(appID: string, appFacilities: MinimalAppFacilities) {
        super(appID, appFacilities);
    }

    protected quotasDidUpdate?(newQuotas: AllQuotaValues, oldQuotas: AllQuotaValues): void;
    protected updateQuotasReached?(kinds: QuotaKind[]): void;
}
