import type { EnqueueSingleActionRequest } from "@glide/common-core";
import { blockingWindowExit } from "@glide/common-core/dist/js/support/window-exit-blocking";
import { logError, RecurrentBackgroundJob } from "@glide/support";
import { assert } from "@glideapps/ts-necessities";
import {
    postAddRowToTableActionReliably,
    postSetColumnsInRowActionReliably,
    postDeleteRowActionReliably,
} from "./post-action";
import type { ActionPoster, ActionPosterAccessors } from "./action-manager";
import {
    type EnqueueDataActionResult,
    PermanentActionFailureException,
    TransientPassThroughFailureException,
} from "./enqueue-data-action";
import {
    makeAddRowToTableActionRequest,
    makeSetColumnsInRowActionRequest,
    makeDeleteRowActionRequest,
} from "./http-crud-actions";
import type {
    AddRowActionWithMetadataArguments,
    SetColumnsActionWithMetadataArguments,
    DeleteRowActionWithMetadataArguments,
} from "./types";
import { enqueueDataActionBatch } from "@glide/backend-api";
import type { TableName } from "@glide/type-schema";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";

export class SimpleActionPoster implements ActionPoster {
    constructor(private readonly _accessors: ActionPosterAccessors) {}

    public async addRowToTable(data: AddRowActionWithMetadataArguments): Promise<EnqueueDataActionResult> {
        return await blockingWindowExit(() => postAddRowToTableActionReliably(this._accessors.appFacilities(), data));
    }

    public async setColumnsInRow(data: SetColumnsActionWithMetadataArguments): Promise<EnqueueDataActionResult> {
        return await blockingWindowExit(() => postSetColumnsInRowActionReliably(this._accessors.appFacilities(), data));
    }

    public async deleteRow(data: DeleteRowActionWithMetadataArguments): Promise<EnqueueDataActionResult> {
        return await blockingWindowExit(() => postDeleteRowActionReliably(this._accessors.appFacilities(), data));
    }
}

interface ActionQueueEntry {
    readonly action: EnqueueSingleActionRequest;
    readonly resolve: (result: EnqueueDataActionResult) => void;
    readonly reject: (e: Error) => void;

    resolved: boolean;
}

export class BatchingActionPoster implements ActionPoster {
    private readonly actionQueue: ActionQueueEntry[] = [];
    private readonly job = new RecurrentBackgroundJob(() => this.run());

    /**
     * @param passFailuresThrough will retry on failures infinitely if this is
     * `false`.
     */
    constructor(private readonly appFacilities: ActionAppFacilities, private readonly passFailuresThrough: boolean) {}

    private async run(): Promise<void> {
        const actions = [...this.actionQueue];
        this.actionQueue.length = 0;

        if (actions.length === 0) return;
        assert(actions.every(a => !a.resolved));

        const { appID } = actions[0].action.actionMetadata;

        const actionsToRetry: ActionQueueEntry[] = [];

        const actionFailedTransiently = (action: ActionQueueEntry, message: string) => {
            if (this.passFailuresThrough) {
                action.reject(
                    new TransientPassThroughFailureException(
                        appID,
                        action.action.kind,
                        action.action.actionMetadata.jobID,
                        message
                    )
                );
            } else {
                actionsToRetry.push(action);
            }
        };

        try {
            const response = await enqueueDataActionBatch(
                appID,
                actions.map(a => a.action),
                this.appFacilities
            );
            if (response === undefined) {
                // If we didn't get a response back we treat it as a transient
                // failure.
                for (const action of actions) {
                    actionFailedTransiently(action, "no response");
                }
            } else {
                for (const success of response.successes) {
                    const action = actions.find(a => a.action.actionMetadata.jobID === success.jobID);
                    if (action === undefined) {
                        logError("got a success response for a job we didn't send");
                        continue;
                    }

                    action.resolve({ confirmedAtVersion: success.confirmedAtVersion, newRow: success.newRow });
                    action.resolved = true;
                }
                for (const failure of response.failures) {
                    const action = actions.find(a => a.action.actionMetadata.jobID === failure.jobID);
                    if (action === undefined) {
                        logError("got a failure response for a job we didn't send");
                        continue;
                    }

                    if (!failure.isPermanentFailure) {
                        actionFailedTransiently(action, failure.message);
                        continue;
                    }

                    action.reject(
                        new PermanentActionFailureException(
                            appID,
                            action.action.kind,
                            action.action.actionMetadata.jobID,
                            failure.message
                        )
                    );
                    action.resolved = true;
                }
            }
        } finally {
            for (const action of actions) {
                if (action.resolved) continue;
                if (actionsToRetry.includes(action)) continue;

                // If we didn't get a response for this action, we assume it
                // failed.
                action.reject(
                    new PermanentActionFailureException(
                        appID,
                        action.action.kind,
                        action.action.actionMetadata.jobID,
                        undefined
                    )
                );
                action.resolved = true;
            }
        }

        if (actionsToRetry.length > 0) {
            // If we have transient failures, we move them back to the front
            // of the queue and try again.
            this.actionQueue.unshift(...actionsToRetry);
            this.job.request();
        }
    }

    public addRowToTable(data: AddRowActionWithMetadataArguments): Promise<EnqueueDataActionResult> {
        return blockingWindowExit(
            () =>
                new Promise((resolve, reject) => {
                    const action = makeAddRowToTableActionRequest(data);
                    this.actionQueue.push({ action, resolve, reject, resolved: false });
                    this.job.request();
                })
        );
    }

    public setColumnsInRow(data: SetColumnsActionWithMetadataArguments): Promise<EnqueueDataActionResult> {
        return blockingWindowExit(
            () =>
                new Promise((resolve, reject) => {
                    const action = makeSetColumnsInRowActionRequest(data);
                    this.actionQueue.push({ action, resolve, reject, resolved: false });
                    this.job.request();
                })
        );
    }

    public deleteRow(data: DeleteRowActionWithMetadataArguments): Promise<EnqueueDataActionResult> {
        return blockingWindowExit(
            () =>
                new Promise((resolve, reject) => {
                    const action = makeDeleteRowActionRequest(data);
                    this.actionQueue.push({ action, resolve, reject, resolved: false });
                    this.job.request();
                })
        );
    }

    // This is just for testing
    public async wait(): Promise<void> {
        await this.job.wait();
    }
}

export class MultiplexingActionPoster implements ActionPoster {
    constructor(private readonly getPosterForTable: (tn: TableName) => ActionPoster) {}

    public addRowToTable(data: AddRowActionWithMetadataArguments): Promise<EnqueueDataActionResult> {
        const poster = this.getPosterForTable(data.tableName);
        return poster.addRowToTable(data);
    }

    public setColumnsInRow(data: SetColumnsActionWithMetadataArguments): Promise<EnqueueDataActionResult> {
        const poster = this.getPosterForTable(data.tableName);
        return poster.setColumnsInRow(data);
    }

    public deleteRow(data: DeleteRowActionWithMetadataArguments): Promise<EnqueueDataActionResult> {
        const poster = this.getPosterForTable(data.tableName);
        return poster.deleteRow(data);
    }
}
