import {
    type ActionKind,
    type ComponentKind,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    makeColumnProperty,
} from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import { hasInputContext, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import type { WireAppMarkdownComponent } from "@glide/fluent-components/dist/js/base-components";
import type { AppDescriptionContext } from "@glide/function-utils";
import { makeTextPropertyDescriptor } from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";

import { getPrimitiveActionKinds } from "../actions";
import {
    hydrateAction,
    inflateActions,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerBusyActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { getActionsForComponent } from "./component-utils";
import { type SimpleComponentDescription, SimpleComponentHandler } from "./simple-handler";

const ComponentKindMarkdown: ComponentKind = "markdown";
const componentName = "Rich Text";

interface MarkdownComponentDescription extends SimpleComponentDescription {
    readonly propertyName: LegacyPropertyDescription;
}

export class MarkdownComponentHandler extends SimpleComponentHandler {
    public readonly appKinds = AppKind.App;

    constructor() {
        super({
            kind: ComponentKindMarkdown,
            name: componentName,
            group: "Text",
            description: "Rich text with images",
            img: "co-rich-text",
            helpPath: "markdown",
        });
    }

    public get needsColumns(): boolean {
        return false;
    }

    protected getPropertyDescriptors(
        _desc: SimpleComponentDescription,
        mutatingScreenKind: MutatingScreenKind | undefined
    ) {
        return [
            {
                descriptor: () =>
                    makeTextPropertyDescriptor("propertyName", "Text", "Markdown text", true, mutatingScreenKind, {
                        isMultiLine: true,
                        preferredNames: ["text", "description", "markdown"],
                        preferredType: "markdown",
                        columnFirst: true,
                    }),
                argumentName: "markdown",
            },
        ];
    }

    protected getActionKinds(
        mutatingScreenKind: MutatingScreenKind | undefined,
        appHasUserProfile: boolean
    ): readonly ActionKind[] {
        return getPrimitiveActionKinds(hasInputContext(mutatingScreenKind), appHasUserProfile);
    }

    public inflate(
        ib: WireInflationBackend,
        desc: MarkdownComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const [textGetter, textType] = inflateStringProperty(ib, desc.propertyName, true);
        if (textType === undefined) return undefined;

        const { actions } = getActionsForComponent(this, desc, ib.tables, ib.adc, ib.mutatingScreenKind);
        const actionHydrator = inflateActions(ib, actions);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const text = textGetter(hb) ?? "";
            if (text === "") return undefined;

            const onTap = definedMap(actionHydrator, ah =>
                registerBusyActionRunner(hb, "", () => hydrateAction(ah, hb, false, undefined))
            );

            const component: WireAppMarkdownComponent = {
                kind: WireComponentKind.AppMarkdown,
                ...spreadComponentID(desc.componentID, forBuilder),
                text,
                onTap,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(desc: MarkdownComponentDescription, _ccc: AppDescriptionContext) {
        return {
            ...makeEmptyComponentDescription(WireComponentKind.RichText),
            text: desc.propertyName,
            actions: desc.actions,
        };
    }
}

export function defaultMarkdownComponent(column: TableColumn): MarkdownComponentDescription {
    return {
        ...makeEmptyComponentDescription(ComponentKindMarkdown),
        propertyName: makeColumnProperty(column.name),
        actions: [],
    };
}
