import { copyToClipboard as copy } from "@glide/common";
import { useEffect, useState } from "react";

import { Button } from "../button/button";
import { Code } from "../code/code-lazy";
import { SegmentedButton } from "../segmented-button/segmented-button";
import { RichTooltip } from "../tooltip/rich-tooltip";
import { type Language, languages } from "./languages";
import type { Props, PropsStrict } from "./types";

function toStrict(props: Props): PropsStrict {
    return {
        appID: "YOUR_APP_ID",
        apiKey: "YOUR_API_KEY",
        endpoint: x => `https://api.glideapp.io/api/function/${x}`,
        ...props,
    };
}

const RESTORE_LABEL_DELAY = 1000;

export const APIInstructions = (incompleteProps: Props) => {
    const props = toStrict(incompleteProps);

    const languageNames = Object.keys(languages) as Language[];

    const [selectedLanguage, setSelectedLanguage] = useState(languageNames[0]);
    const [examples, setExamples] = useState(languages[selectedLanguage].examples);
    const [selectedExample, setSelectedExample] = useState(Object.keys(examples)[0]);
    const [justCopiedToken, setJustCopiedToken] = useState(false);
    const [justCopiedCode, setJustCopiedCode] = useState(false);

    useEffect(() => {
        const newExamples = languages[selectedLanguage].examples;
        setExamples(newExamples);
        setSelectedExample(Object.keys(newExamples)[0]);
    }, [props.canGetAllRows, selectedLanguage]);

    useEffect(() => {
        if (!justCopiedToken) return;
        const timer = window.setTimeout(() => setJustCopiedToken(false), RESTORE_LABEL_DELAY);
        return () => clearTimeout(timer);
    }, [justCopiedToken]);

    useEffect(() => {
        if (!justCopiedCode) return;
        const timer = window.setTimeout(() => setJustCopiedCode(false), RESTORE_LABEL_DELAY);
        return () => clearTimeout(timer);
    }, [justCopiedCode]);

    const language = languages[selectedLanguage];
    const example = examples[selectedExample];

    const code = example.render(props);
    const codeRedacted = code.replaceAll(props.apiKey, "*********************");
    return (
        <div tw="space-y-4 w-[600px] pb-6">
            <div tw="flex justify-between">
                <SegmentedButton
                    size="md"
                    selectedStyle="tertiary"
                    values={languageNames}
                    value={selectedLanguage}
                    setValue={setSelectedLanguage}
                    displayName={x => languages[x].name}
                />
                <SegmentedButton
                    size="sm"
                    selectedStyle="secondary"
                    values={Object.keys(examples)}
                    value={selectedExample}
                    setValue={setSelectedExample}
                    displayName={x => examples[x].title}
                />
            </div>

            <div tw="rounded-lg cursor-pointer bg-bg-back overflow-scroll h-[360px] text-sm leading-snug">
                <Code code={codeRedacted} language={language.syntax} />
            </div>

            <div tw="flex justify-end space-x-2">
                <RichTooltip content="API" docURL="https://www.glideapps.com/docs/using-glide-tables-api" />
                <Button
                    variant={"default"}
                    buttonType={"secondary"}
                    size="md"
                    label={justCopiedToken ? "Copied!" : "Copy secret token"}
                    tw="min-w-[139px]"
                    onClick={() => {
                        copy(props.apiKey);
                        setJustCopiedToken(true);
                    }}
                />
                <Button
                    variant={"default"}
                    buttonType={"primary"}
                    size="md"
                    label={justCopiedCode ? "Copied!" : "Copy code"}
                    tw="min-w-[92px]"
                    onClick={() => {
                        copy(code);
                        setJustCopiedCode(true);
                    }}
                />
            </div>
        </div>
    );
};
