import { matchSorter } from "match-sorter";
import type { InternalMenuOptionType, MenuOption, TokenAttrs } from "./editor";
import { mapFilterUndefined } from "@glideapps/ts-necessities";
import { isDefined, isEmptyOrUndefined, logError } from "@glide/support";
import type { Content, JSONContent } from "@tiptap/core";
import * as t from "io-ts";

export function filterActions<T>(
    actions: MenuOption<T>[],
    searchValue: string,
    contextualLabel?: string
): InternalMenuOptionType<T>[] | null {
    const options = flattenActions<T>(actions);
    const matches = matchSorter(options, searchValue, {
        keys: ["label", "group"],
    });

    return nestActions<T>(matches, contextualLabel);
}

function flattenActions<T>(actions: MenuOption<T>[], parentGroup?: string): InternalMenuOptionType<T>[] {
    return actions.flatMap(item => {
        // Create the current group path by combining parent group (if exists) with current label
        const currentGroup = parentGroup !== undefined ? `${parentGroup} / ${item.label}` : item.label;

        if (isDefined(item.items)) {
            return flattenActions(
                item.items.map(action => ({
                    ...action,
                    group: currentGroup,
                })),
                currentGroup
            );
        }
        return item;
    });
}

const WITHOUT_GROUP = "Without Group";

export function nestActions<T>(
    actions: InternalMenuOptionType<T>[] | null,
    contextualLabel?: string
): InternalMenuOptionType<T>[] | null {
    if (actions === null) return null;

    const nestedActions: InternalMenuOptionType<T>[] = [];

    for (const option of actions) {
        if (option.group !== undefined) {
            const group = nestedActions.find(action => action.label === option.group);
            if (group !== undefined) {
                group.items = isDefined(group.items) ? group.items : [];
                group.items.push(option);
            } else {
                nestedActions.push({
                    label: option.group,
                    items: [option],
                    value: option.value,
                    icon: option.icon,
                    preview: option.preview,
                    withDivider: option.withDivider,
                    invalid: option.invalid,
                    singleValue: option.singleValue,
                });
            }
        } else {
            if (isDefined(option.items)) {
                nestedActions.push({
                    label: option.label,
                    items: option.items,
                    value: option.value,
                    icon: option.icon,
                    preview: option.preview,
                    withDivider: option.withDivider,
                    invalid: option.invalid,
                    onItemSecondaryClick: option.onItemSecondaryClick,
                    singleValue: option.singleValue,
                });
            } else {
                const findOther = nestedActions.find(action => action.group === WITHOUT_GROUP);
                if (findOther !== undefined) {
                    findOther.items = isDefined(findOther.items) ? findOther.items : [];
                    findOther.items.push({
                        label: option.label,
                        value: option.value,
                        icon: option.icon,
                        preview: option.preview,
                        withDivider: option.withDivider,
                        invalid: option.invalid,
                        onItemSecondaryClick: option.onItemSecondaryClick,
                        singleValue: option.singleValue,
                    });
                } else {
                    nestedActions.push({
                        label: contextualLabel ?? " ",
                        group: WITHOUT_GROUP,
                        items: [option],
                        value: option.value,
                        icon: option.icon,
                        preview: option.preview,
                        withDivider: option.withDivider,
                        invalid: option.invalid,
                        singleValue: option.singleValue,
                    });
                }
            }
        }
    }

    // Move contextualLabel group to front if it exists
    const contextualLabelGroup = nestedActions.find(action => action.group === WITHOUT_GROUP);
    const otherActions = nestedActions.filter(action => action.group !== WITHOUT_GROUP);
    return isDefined(contextualLabelGroup) ? [contextualLabelGroup, ...otherActions] : nestedActions;
}

export interface Binding<T> {
    type: "binding";
    attrs: TokenAttrs<T>;
}

export interface TextContent {
    type: "text";
    text: string;
}

interface Paragraph<T> {
    type: "paragraph";
    content: PlainTextInlineTemplateValue<T>;
}

export type TokenStyle = "default" | "compact";

export type PlainTextInlineTemplateValue<T> = (Binding<T> | TextContent | ImageBinding)[];

export const tokenAttrsCodec = t.type({
    icon: t.union([t.string, t.undefined]),
    label: t.string,
    preview: t.union([t.string, t.undefined]),
    withDivider: t.union([t.boolean, t.undefined]),
    singleValue: t.union([t.boolean, t.undefined]),
    invalid: t.boolean,
});

const imageBindingAttrsCodec = t.type({
    src: t.string,
    alt: t.union([t.string, t.null]),
    title: t.union([t.string, t.undefined]),
    state: t.union([t.literal("loaded"), t.literal("error"), t.literal("loading"), t.undefined]),
});

type ImageBindingAttrs = t.TypeOf<typeof imageBindingAttrsCodec>;

export interface ImageBinding {
    type: "image";
    attrs: ImageBindingAttrs;
}

export function wrapInParagraph<T>(content: PlainTextInlineTemplateValue<T> | undefined): Content {
    if (!isDefined(content) || content.length === 0) {
        return { type: "doc", content: [{ type: "paragraph" }] };
    }
    // Check if content contains a single node and if it's an image
    if (content.length === 1 && content[0].type === "image") {
        return {
            type: "doc",
            content: [
                {
                    type: "paragraph",
                    content: [content[0]],
                },
            ],
        };
    }

    const paragraphs: Paragraph<T>[] = [];
    let currentParagraph: PlainTextInlineTemplateValue<T> = [];

    content.forEach(item => {
        if (item.type === "text" && item.text === "\n") {
            paragraphs.push({ type: "paragraph", content: currentParagraph });
            currentParagraph = [];
        } else {
            // Ungroup text nodes by splitting on newlines
            if (item.type === "text") {
                const parts = item.text.split("\n");
                parts.forEach((part, index) => {
                    if (index > 0) {
                        paragraphs.push({ type: "paragraph", content: currentParagraph });
                        currentParagraph = [];
                    }
                    if (part !== "") {
                        currentParagraph.push({ type: "text", text: part });
                    }
                });
            } else {
                currentParagraph.push(item);
            }
        }
    });

    // Add the last paragraph, even if it's empty
    paragraphs.push({ type: "paragraph", content: currentParagraph });

    return {
        type: "doc",
        content: paragraphs.map(paragraph => ({
            type: "paragraph",
            ...(paragraph.content.length > 0 && { content: paragraph.content }),
        })),
    };
}

function parseTemplateValueNodeFromJSONContent<T>(
    item: JSONContent
): Binding<T> | TextContent | ImageBinding | undefined {
    switch (item.type) {
        case "binding": {
            if (!tokenAttrsCodec.is(item.attrs)) {
                return undefined;
            }

            const binding: Binding<T> = {
                type: "binding",
                attrs: item.attrs,
            };

            return binding;
        }

        case "image": {
            if (!imageBindingAttrsCodec.is(item.attrs)) {
                return undefined;
            }

            const image: ImageBinding = {
                type: "image",
                attrs: item.attrs,
            };

            return image;
        }

        case "text": {
            if (item.text === undefined) {
                return undefined;
            }

            const text: TextContent = {
                type: "text",
                text: item.text,
            };

            return text;
        }

        default: {
            logError(`Unexpected node of type ${item.type}`, item);
            return undefined;
        }
    }
}

export function unwrapParagraphs<T>(input: Content): PlainTextInlineTemplateValue<T> {
    const isInputUndefined = !isDefined(input);
    const isInputEmptyArray = Array.isArray(input) && input.length === 0;
    const isInputSingleEmptyParagraph =
        Array.isArray(input) &&
        input.length === 1 &&
        input[0].type === "paragraph" &&
        isEmptyOrUndefined(input[0].content);

    if (isInputUndefined || isInputEmptyArray || isInputSingleEmptyParagraph) {
        return [];
    }

    if (typeof input === "string") {
        return [{ type: "text", text: input }];
    }

    const inputArray = Array.isArray(input) ? input : [input];

    const result: PlainTextInlineTemplateValue<T> = [];
    let currentTextNode: TextContent | null = null;

    inputArray.forEach((item, index) => {
        if (item.type !== "paragraph") {
            return;
        }

        // Handle empty paragraphs
        if (isEmptyOrUndefined(item.content)) {
            if (isDefined(currentTextNode)) {
                currentTextNode.text += "\n";
            } else {
                currentTextNode = { type: "text", text: "\n" };
                result.push(currentTextNode);
            }
            return;
        }

        const content = mapFilterUndefined(item.content, node => parseTemplateValueNodeFromJSONContent<T>(node));

        content.forEach(node => {
            if (node.type === "text") {
                if (isDefined(currentTextNode)) {
                    currentTextNode.text += node.text;
                } else {
                    currentTextNode = { type: "text", text: node.text };
                    result.push(currentTextNode);
                }
            } else {
                currentTextNode = null;
                result.push(node);
            }
        });

        // Add a newline character after each paragraph, except for the last one
        if (index < inputArray.length - 1) {
            if (isDefined(currentTextNode)) {
                currentTextNode.text += "\n";
            } else {
                currentTextNode = { type: "text", text: "\n" };
                result.push(currentTextNode);
            }
        }
    });

    return result;
}
