import type { AppUserAuthenticator } from "@glide/common-core/dist/js/components/types";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import { checkExplicitSignOut } from "../utils/session-storage";

export function getCredentials() {
    return getLocationSettings().googleCredentials;
}

function googleOAuth2CallbackURLForLocation(): string {
    return getLocationSettings().oAuthCallbackURL;
}

const baseScopes = ["email", "profile", "openid"];

function getCommonGoogleOAuth2RedirectURL(state: string, scopes: readonly string[], redirectHref: string): URL {
    const creds = getCredentials();
    const redirectURL = new URL("https://accounts.google.com/o/oauth2/v2/auth");
    const { searchParams } = redirectURL;
    searchParams.append("scope", scopes.join(" "));
    searchParams.append("access_type", "offline");
    searchParams.append("include_granted_scopes", "false");
    searchParams.append("state", state);
    searchParams.append("redirect_uri", redirectHref);
    searchParams.append("response_type", "code");
    searchParams.append("client_id", creds.clientId);
    return redirectURL;
}

export function getHTTPGoogleOAuth2RedirectURL(state: string, authenticator: AppUserAuthenticator | undefined): URL {
    const redirectURL = getCommonGoogleOAuth2RedirectURL(state, baseScopes, googleOAuth2CallbackURLForLocation());
    if (
        (authenticator?.failedSignIns ?? 0) > 0 ||
        (authenticator !== undefined && checkExplicitSignOut(authenticator?.appID))
    ) {
        // If we have failed a sign-in attempt from before, we need to force the select_account prompt,
        // because if we don't, we'll get stuck failing to sign in with the wrong account repeatedly.
        //
        // We also need to force the account selector if we have explicitly signed out.
        redirectURL.searchParams.append("prompt", "select_account consent");
    }
    return redirectURL;
}

export const queryableGCPScopes = [
    "https://www.googleapis.com/auth/sqlservice.admin",
    "https://www.googleapis.com/auth/bigquery",
] as const;

export function getHTTPGoogleOAuth2RedirectURLForGCP(state: string, newScopes: readonly string[]): URL {
    const scopes = [...baseScopes, ...newScopes];
    const redirectURL = getCommonGoogleOAuth2RedirectURL(state, scopes, getLocationSettings().gcpOAuthCallbackURL);
    redirectURL.searchParams.append("prompt", "select_account consent");
    return redirectURL;
}
