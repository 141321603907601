import * as t from "io-ts";
import type Stripe12 from "stripe-12";
import { z } from "zod";
import type { EminenceFlags } from "./entitlements";

export type MillisecondsSinceEpoch = number;
export type SecondsSinceEpoch = number;

// Asserts the given string is a valid quota key.
function quota<Str extends keyof EminenceFlags["quotas"]>(str: Str): Str {
    return str;
}

// Asserts the given string is not a valid quota key.
function notQuota<Str extends string>(str: Str extends keyof EminenceFlags["quotas"] ? never : Str): Str {
    return str;
}

// The following quota enums should use one of the above functions for their values to ensure
//  they deliberately match up with the quota keys in the entitlements schema.

// We can turn anything tagged `notQuota` into a quota by simply adding it to EminenceFlags["quotas"]
//  and then using `quota` below instead of `notQuota`. The enforcement system will then enforce it.

export const NonResettableQuota = {
    Editors: quota("app-editors"),
    FileBytesUsed: quota("file-bytes-used"),
    PublishedApps: quota("published-apps"),
    BigTableRows: quota("big-table-rows"),
    SQLRows: quota("sql-rows"),
} as const;
export type NonResettableQuota = (typeof NonResettableQuota)[keyof typeof NonResettableQuota];

export const UserKind = {
    PrivateUser: quota("private-users"),
    PublicUser: quota("public-users"),
} as const;
export type UserKind = (typeof UserKind)[keyof typeof UserKind];

export const OverageQuota = {
    ...UserKind,
    User: quota("users"),
    Update: quota("updates"),
} as const;
export type OverageQuota = (typeof OverageQuota)[keyof typeof OverageQuota];

const UpdateKind = {
    AddRowToTable: notQuota("add-row-to-table"),
    SetColumnsInRow: notQuota("set-columns-in-row"),
    DeleteRow: notQuota("delete-row"),
    Sync: quota("reloads"),
    RunIntegration: notQuota("run-integrations"),
    QueryTablesAPI: notQuota("query-tables-api"),
    MutateTablesAPI: notQuota("mutate-tables-api"),
    Signature: quota("signatures"),
    AutomationTrigger: notQuota("automation-triggers"),
    DeliverEmailAction: notQuota("deliver-email-action"),
    GlideAPIv2: notQuota("glide-api-v2"),
} as const;
type UpdateKind = (typeof UpdateKind)[keyof typeof UpdateKind];

export const ResettableQuota = {
    ...OverageQuota,
    ...UpdateKind,
} as const;
export type ResettableQuota = (typeof ResettableQuota)[keyof typeof ResettableQuota];

export const QuotaType = {
    ...NonResettableQuota,
    ...ResettableQuota,
};
export type QuotaType = (typeof QuotaType)[keyof typeof QuotaType];

export const BillingPeriod = {
    Monthly: "monthly",
    Yearly: "yearly",
} as const;
export type BillingPeriod = (typeof BillingPeriod)[keyof typeof BillingPeriod];

export const BillingPeriodMetadata = {
    ...BillingPeriod,
    Both: "both",
} as const;
export type BillingPeriodMetadata = (typeof BillingPeriodMetadata)[keyof typeof BillingPeriodMetadata];

export const BillingPlan = {
    Starter: "starter",
    Pro: "pro",
    Business: "business",
    Enterprise: "enterprise",
} as const;
export type BillingPlan = (typeof BillingPlan)[keyof typeof BillingPlan];

export const billingPlansInOrder: BillingPlan[] = [
    BillingPlan.Starter,
    BillingPlan.Pro,
    BillingPlan.Business,
    BillingPlan.Enterprise,
];

/**
 * Checks if the given value is a valid member of the passed enum.
 * @param billingEnum the enum to check against
 * @param toCheck the value to check
 * @returns true if the value is a valid member of the enum, false otherwise
 */
export function isBillingEnumValue<T>(billingEnum: T, toCheck: unknown): toCheck is T[keyof T] {
    return Object.values(billingEnum).includes(toCheck as T[keyof T]);
}

export function asMaybeBillingEnumValue<T>(billingEnum: T, toCheck: unknown): T[keyof T] | undefined {
    return isBillingEnumValue(billingEnum, toCheck) ? toCheck : undefined;
}

// These are the thresholds that cause a new aggregated usage event to be inserted.
//  The first element of each tuple is the `kind`, and the second is the threshold value.
export const QuotaThresholds: readonly [string, number][] = [
    ["threshold-75", 0.75],
    ["threshold-90", 0.9],
    ["threshold-100", 1.0],
    ["threshold-150", 1.5],
    ["threshold-200", 2.0],
    ["threshold-300", 3.0],
    ["threshold-400", 4.0],
    ["threshold-500", 5.0],
    ["threshold-600", 6.0],
    ["threshold-700", 7.0],
    ["threshold-800", 8.0],
    ["threshold-900", 9.0],
    ["threshold-1000", 10.0],
];

// This is glorious. The reason for not simply using all digits for all parts of the string is so that
// we dont completely overwhelm the typescript compiler. This only has 25 thousandish possible values
// instead of 10 millionish
type NonZeroDigits = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
type AllDigits = NonZeroDigits | "0";
type ZeroPrefixDigits = `0${NonZeroDigits}`;
type DayDigits =
    | ZeroPrefixDigits
    | NonZeroDigits
    | "10"
    | "11"
    | "12"
    | "13"
    | "14"
    | "15"
    | "16"
    | "17"
    | "18"
    | "19"
    | "20"
    | "21"
    | "22"
    | "23"
    | "24"
    | "25"
    | "26"
    | "27"
    | "28"
    | "29"
    | "30"
    | "31";
type MonthDigits = ZeroPrefixDigits | NonZeroDigits | "10" | "11" | "12";
type YearThirdDigits = "2" | "3" | "4"; // If we're still in business in 2050, we have already won
export type BillingVersion = `20${YearThirdDigits}${AllDigits}-${MonthDigits}-${DayDigits}`;

export function isBillingVersion(value: unknown): value is BillingVersion {
    return typeof value === "string" && value.match(/^20[2-4][0-9]-[0-1]?[0-9]-[0-3]?[0-9]$/) !== null;
}

export interface UserData {
    readonly displayName: string;
    readonly email: string;
    readonly id: string;
    readonly testClock?: string;
    readonly paymentMethod?: string;
    readonly partnerStackReferralPartnerKey?: string;
}

interface PriceSummaryBase {
    readonly priceID: string;
    readonly productID: string;
    readonly plan: BillingPlan;
    /**
     * Whether or not this price is active. If false, this price is not available for purchase.
     * @default true
     */
    readonly isActive?: boolean;
    readonly creationDate: number;
    readonly version: BillingVersion;
}

interface PlanPriceSummary extends PriceSummaryBase {
    readonly period: BillingPeriod;
    readonly overageType?: undefined;
    readonly trialPeriodDays?: number;
    readonly migrateTo?: {
        toPriceID: string;
        after: number;
    };
}

interface OveragePriceSummary extends PriceSummaryBase {
    readonly overageType: OverageQuota;
    readonly period: BillingPeriod | "both";
    readonly trialPeriodDays?: undefined;
    readonly migrateTo?: undefined;
}

export type PriceSummary = PlanPriceSummary | OveragePriceSummary;

export interface SubscriptionSummary {
    readonly ownerID: string;
    readonly customerID: string;
    readonly priceID: string;
    readonly subscriptionID: string;
    readonly periodStart: number;
    readonly periodEnd: number;
    readonly periodKind: BillingPeriod;
    readonly planKind: BillingPlan;
    readonly allowOverages: boolean;
    readonly isReverseFreeTrial: boolean;
    readonly trialEnd: number | null;
    readonly status: Stripe12.Subscription.Status;
    readonly version: BillingVersion;
    readonly isCustomerPaymentMethodAttached: boolean;
    readonly isSubscriptionPaymentMethodAttached: boolean;
}

// Metadata schema for usage records that are scoped to an app.
export const appScopedUsageMetadataSchema = z.object({
    appID: z.string(),
    pluginID: z.string().optional(),
});

export type AppScopedUsageMetadata = z.infer<typeof appScopedUsageMetadataSchema>;

// Metadata schema for usage records from Glide API v2.
export const glideAPIV2UsageMetadataSchema = z.object({
    tableID: z.string(),
    operationKind: z.enum(["read", "add", "edit", "delete"]),
});

export type GlideAPIV2UsageMetadata = z.infer<typeof glideAPIV2UsageMetadataSchema>;

export const usageMetadataSchema = z.union([appScopedUsageMetadataSchema, glideAPIV2UsageMetadataSchema]);

export type UsageMetadata = z.infer<typeof usageMetadataSchema>;

type KeysOfUnion<T> = T extends any ? keyof T : never;

export type UsageMetadataKeys = KeysOfUnion<UsageMetadata>;

export interface UsageRecord {
    readonly quantity: number;
    readonly timestamp: MillisecondsSinceEpoch;
    readonly kind: ResettableQuota;
    readonly idempotencyKey: string;
    readonly orgID: string;
    readonly metadata?: UsageMetadata;
    readonly quantityFloat: number;
}

export enum V4StripeMetadataKey {
    V4MigrateTo = "v4-migrate-to",
    V4MigrateAfter = "v4-migrate-after",
    V4Version = "v4-version",
    V4Period = "v4-period",
    V4Plan = "v4-plan",
    V4Active = "v4-active",
    V4OverageType = "v4-overageType",
    OwnerID = "ownerID",
    AllowOverages = "allowOverages",
    AllowOveragesLastToggledAt = "allowOveragesLastToggledAt",
    V4TrialUsed = "v4-trialUsed",
    V4IsReverseFreeTrial = "v4-isReverseFreeTrial",
    V4TrialPeriodDays = "v4-trialPeriodDays",
    CustomerKey = "customer_key",
    PartnerStackReferralPartnerKey = "partnerStackReferralPartnerKey",
    V4OffTheMenuPortal = "v4-offTheMenuPortal",
    V4OffTheMenuPortalConfigurationKey = "key",
    V4OveragesInvoicePeriodStart = "periodStart",
    V4OveragesInvoicePeriodEnd = "periodEnd",
}

export const expiredSubscriptionStatuses: Stripe12.Subscription.Status[] = [
    "canceled",
    "incomplete_expired",
    "paused",
    "unpaid",
];

export const activeSubscriptionStatuses: Stripe12.Subscription.Status[] = [
    "active",
    "incomplete",
    "past_due",
    "trialing",
];

export function isExpiredSubscriptionStatus(status: Stripe12.Subscription.Status): boolean {
    return expiredSubscriptionStatuses.includes(status);
}

// This is the maximum number that we can pass as the `limit` option for the `autoPagingToArray` method of list queries
// in the Stripe SDK. More information: https://github.com/stripe/stripe-node#auto-pagination
export const maxStripeAutoPagingLimit = 10_000;

// These enum matches (some of) the possible values for `Stripe12.BillingPortal.SessionCreateParams.FlowData.Type`.
export enum StripeCustomerPortalFlowTypes {
    PaymentMethodUpdate = "payment_method_update",
    SubscriptionUpdate = "subscription_update",
}

export const usagePeriodIDCodec = t.union([t.literal("current"), t.literal("previous")]);
export type UsagePeriodID = t.TypeOf<typeof usagePeriodIDCodec>;

const usagePeriodsCodec = t.record(usagePeriodIDCodec, t.type({ inclusiveStart: t.number, exclusiveEnd: t.number }));

export type UsagePeriods = t.TypeOf<typeof usagePeriodsCodec>;
