import { GlideIcon } from "@glide/common";
import { UIBackgroundStyle, UIButtonAppearance } from "@glide/wire";
import classNames from "classnames";
import type { MotionValue } from "framer-motion";
import { AnimatePresence, m, useTransform } from "framer-motion";
import { css } from "styled-components";
import tw from "twin.macro";

import { WireButton } from "../../renderers/wire-button/wire-button";
import { WireContainer, WireContainerSpacing } from "../../renderers/wire-container/wire-container";
import type { ChromeFormControls } from "./nav-bar-common";
import { getContainerTheme } from "./nav-bar-common";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

function useCrossfadeNavItem(navBarCrossfadeMotionValue: MotionValue<number>) {
    const topOpacity = useTransform(navBarCrossfadeMotionValue, [0, 0.5, 1], [1, 0, 0]);
    const bottomOpacity = useTransform(navBarCrossfadeMotionValue, [0, 0.5, 1], [0, 0, 1]);
    const topScale = useTransform(navBarCrossfadeMotionValue, [0, 0.5, 1], [1, 0.95, 0.95]);
    const bottomScale = useTransform(navBarCrossfadeMotionValue, [0, 0.5, 1], [0.95, 0.95, 1]);

    return { topOpacity, bottomOpacity, topScale, bottomScale };
}

interface MobileNavBarProps {
    readonly containerRef: React.Ref<HTMLDivElement>;
    readonly onTitleClick: (() => void) | undefined;
    readonly logoAndTitle: React.ReactNode;
    readonly modalFormControls: ChromeFormControls | undefined;
    readonly isInModal: boolean;
    readonly canGoBack: boolean;
    readonly setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
    readonly onBack: (isInModal: boolean) => void;
    readonly onModalClose: () => void;
    readonly className?: string;
    readonly belowScreenCanGoBack: boolean;
    readonly belowLogoAndTitle: React.ReactNode;
    readonly navBarCrossfadeMotionValue: MotionValue<number>;
    readonly mustShowTitleInNavBar: boolean;
    readonly showMenuButton: boolean;
    readonly showTransparentNavBar: boolean;
}

export const MobileNavBar: React.VFC<MobileNavBarProps> = p => {
    const {
        modalFormControls,
        isInModal,
        canGoBack,
        setShowMenu,
        onBack,
        onModalClose,
        containerRef,
        onTitleClick,
        logoAndTitle,
        className,
        belowLogoAndTitle,
        belowScreenCanGoBack,
        navBarCrossfadeMotionValue,
        mustShowTitleInNavBar,
        showMenuButton,
        showTransparentNavBar,
    } = p;

    const theme = useWireAppTheme();

    const hasFormControl = modalFormControls !== undefined && isInModal;
    const leftIcon = hasFormControl ? null : (
        <MobileNavLeftButtonIcon
            canGoBack={canGoBack}
            isInModal={isInModal}
            showTransparentNavBar={showTransparentNavBar}
        />
    );
    const belowIcon = (
        <MobileNavLeftButtonIcon
            canGoBack={belowScreenCanGoBack}
            isInModal={isInModal}
            showTransparentNavBar={showTransparentNavBar}
        />
    );

    const onLeftClick = (e: React.MouseEvent<any>) => {
        if (modalFormControls !== undefined) {
            modalFormControls.cancel.onClick?.(e);
            return;
        }

        if (canGoBack) {
            onBack(isInModal);
            return;
        }

        setShowMenu(cv => !cv);
    };

    const leftContent = (
        <MobileNavLeftContent
            onClick={onLeftClick}
            icon={leftIcon}
            belowIcon={belowIcon}
            formActionLabel={modalFormControls?.cancel.label}
            hasFormControl={hasFormControl}
            navBarCrossfadeMotionValue={navBarCrossfadeMotionValue}
            showLeftButton={showMenuButton || canGoBack}
            formActionIsDisabled={modalFormControls?.cancel.disabled ?? false}
            showTransparentNavBar={showTransparentNavBar}
        />
    );

    const onRightClick = (e: React.MouseEvent<any>) => {
        if (modalFormControls !== undefined) {
            modalFormControls.submit.onClick?.(e);
            return;
        }

        if (isInModal) {
            onModalClose();
            return;
        }
    };

    const rightContent = (
        <MobileNavRightContent
            onClick={onRightClick}
            isInModal={isInModal}
            formActionLabel={modalFormControls?.submit.label}
            formActionIsDisabled={modalFormControls?.submit.disabled ?? false}
        />
    );

    const containerTheme = isInModal ? UIBackgroundStyle.White : getContainerTheme(theme.pageTheme, false);

    return (
        <MobileNavBarContent
            containerRef={containerRef}
            containerTheme={containerTheme}
            onTitleClick={onTitleClick}
            leftContent={leftContent}
            rightContent={rightContent}
            logoAndTitle={logoAndTitle}
            belowLogoAndTitle={belowLogoAndTitle}
            className={className}
            navBarCrossfadeMotionValue={navBarCrossfadeMotionValue}
            hasFormControl={hasFormControl}
            mustShowTitleInNavBar={mustShowTitleInNavBar}
            showTransparentNavBar={showTransparentNavBar}
        />
    );
};

interface MobileNavBarPropsContent {
    readonly containerRef: React.Ref<HTMLDivElement>;
    readonly containerTheme: UIBackgroundStyle;
    readonly className?: string;
    readonly onTitleClick: (() => void) | undefined;
    readonly leftContent: React.ReactNode;
    readonly rightContent: React.ReactNode;
    readonly logoAndTitle: React.ReactNode;
    readonly belowLogoAndTitle: React.ReactNode;
    readonly navBarCrossfadeMotionValue: MotionValue<number>;
    readonly hasFormControl: boolean;
    readonly mustShowTitleInNavBar: boolean;
    readonly showTransparentNavBar: boolean;
}

const MobileNavBarContent: React.VFC<MobileNavBarPropsContent> = p => {
    const {
        containerRef,
        containerTheme,
        className,
        onTitleClick,
        leftContent,
        rightContent,
        logoAndTitle,
        belowLogoAndTitle,
        navBarCrossfadeMotionValue,
        hasFormControl,
        mustShowTitleInNavBar,
        showTransparentNavBar,
    } = p;

    const theme = useWireAppTheme();
    const { topOpacity, topScale, bottomOpacity, bottomScale } = useCrossfadeNavItem(navBarCrossfadeMotionValue);
    return (
        <WireContainer
            ref={containerRef}
            spacing={WireContainerSpacing.collapsed}
            ignoreHighlight={true}
            background={containerTheme}
            className={classNames(
                theme.pageTheme === "Dark" && "dark",
                mustShowTitleInNavBar && "show-title-in-nav",
                showTransparentNavBar && "transparent-nav-bar"
            )}
            tw="relative transform translate-x-0 transition-all after:(absolute w-full origin-center scale-0
                left-0 bottom-0 transform transition-all content-[''] h-px)"
            css={css`
                --shadow-color: ${theme.n200A};

                padding: 0;

                & .section-container {
                    padding-top: var(--safe-area-inset-top);
                }

                &.dark {
                    --shadow-color: rgba(225, 225, 255, 0.14); // Dark theme n200A
                }

                ${tw`after:[box-shadow:0 1px 1px var(--shadow-color)]`}

                &.show-title-in-nav {
                    ${tw`after:(scale-100)`}
                }

                &.transparent-nav-bar:not(.show-title-in-nav) {
                    ${tw`bg-transparent`}
                }
            `}>
            <header
                className={className}
                tw="h-12 flex flex-col text-text-contextual-dark w-full z-mobile-nav-bar
                    gp-md:h-14">
                <div
                    tw="grid"
                    className={hasFormControl ? "in-form-nav" : undefined}
                    css={css`
                        grid-template-columns: 56px 1fr 56px;

                        &.in-form-nav {
                            grid-template-columns: 1fr auto 1fr;
                        }
                    `}>
                    {leftContent}
                    <div
                        onClick={onTitleClick}
                        tw="relative h-12 flex flex-grow justify-center items-center font-semibold font-size[17px]
                            truncate min-w-0 cursor-pointer
                            gp-md:h-14">
                        <m.div
                            style={{ opacity: topOpacity, scale: topScale }}
                            tw="w-full flex justify-center items-center">
                            {logoAndTitle}
                        </m.div>
                        <m.div
                            style={{ opacity: bottomOpacity, scale: bottomScale }}
                            tw="absolute flex items-center justify-center inset-0 pointer-events-none w-full">
                            {belowLogoAndTitle}
                        </m.div>
                    </div>
                    {rightContent}
                </div>
            </header>
        </WireContainer>
    );
};

interface MobileNavLeftContentProps {
    readonly onClick: (e: React.MouseEvent<any>) => void;
    readonly icon: React.ReactNode;
    readonly formActionLabel: string | undefined;
    readonly hasFormControl: boolean;
    readonly belowIcon: React.ReactNode;
    readonly navBarCrossfadeMotionValue: MotionValue<number>;
    readonly showLeftButton: boolean;
    readonly formActionIsDisabled: boolean;
    readonly showTransparentNavBar: boolean;
}

const MobileNavLeftContent: React.VFC<MobileNavLeftContentProps> = p => {
    const {
        icon,
        onClick,
        formActionLabel,
        hasFormControl,
        belowIcon,
        navBarCrossfadeMotionValue,
        showLeftButton,
        formActionIsDisabled,
        showTransparentNavBar,
    } = p;

    const { topOpacity, topScale, bottomOpacity, bottomScale } = useCrossfadeNavItem(navBarCrossfadeMotionValue);
    let content: React.ReactNode;
    if (hasFormControl) {
        content = (
            <WireButton
                onClick={onClick}
                appearance={UIButtonAppearance.Simple}
                tw="p-0! font-size[15px]"
                disabled={formActionIsDisabled}>
                {formActionLabel}
            </WireButton>
        );
    } else if (showLeftButton) {
        content = (
            <m.button
                data-testid="menu-left-button"
                style={{ opacity: topOpacity, scale: topScale }}
                tw="p-2 pl-0 text-text-contextual-pale hover:text-text-contextual-base transition-colors"
                onClick={onClick}>
                {icon}
            </m.button>
        );
    }

    if (showTransparentNavBar) {
        return (
            <div tw="flex relative justify-start items-center">
                {content}
                <div tw="flex absolute inset-0 items-center pointer-events-none">
                    <m.div
                        style={{ opacity: bottomOpacity, scale: bottomScale }}
                        tw="p-2 pl-0 text-text-contextual-pale">
                        {belowIcon}
                    </m.div>
                </div>
            </div>
        );
    }

    return (
        <div tw="flex relative justify-start items-center">
            {content}
            <div tw="flex absolute inset-0 items-center pointer-events-none">
                <m.div style={{ opacity: bottomOpacity, scale: bottomScale }} tw="p-2 pl-0 text-text-contextual-pale">
                    {belowIcon}
                </m.div>
            </div>
        </div>
    );
};

interface MobileNavLeftButtonIconProps {
    readonly canGoBack: boolean;
    readonly isInModal: boolean;
    readonly showTransparentNavBar: boolean;
}

const MobileNavLeftButtonIcon: React.VFC<MobileNavLeftButtonIconProps> = p => {
    const { canGoBack, isInModal, showTransparentNavBar } = p;

    return (
        <AnimatePresence>
            <m.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, ease: "easeIn" }}
                className={classNames(showTransparentNavBar && "show-transparent-nav-bar")}
                tw="[&.show-transparent-nav-bar]:(rounded-full bg-[rgba(51, 51, 51, 0.20)] p-2 backdrop-blur-[6px] text-white -ml-2)"
                css={css`
                    & .hidden-icon {
                        display: none;
                    }

                    & .visible-icon {
                        display: block;
                    }
                    .accent-bg &,
                    .dark & {
                        ${tw`transition-none text-text-contextual-dark hover:text-text-contextual-dark active:text-text-contextual-pale`}
                    }
                `}>
                <GlideIcon
                    className={classNames(
                        canGoBack ? "visible-icon" : "hidden-icon",
                        showTransparentNavBar && "show-transparent-nav-bar"
                    )}
                    tw="text-text-contextual-accent [&.show-transparent-nav-bar]:text-white"
                    kind="stroke"
                    icon="st-arrow-full"
                    rotateDeg={180}
                    strokeWidth={1.5}
                />
                <GlideIcon
                    className={classNames(
                        !canGoBack && !isInModal ? "visible-icon" : "hidden-icon",
                        showTransparentNavBar && "show-transparent-nav-bar"
                    )}
                    tw="[&.show-transparent-nav-bar]:text-white"
                    kind="stroke"
                    icon="st-burger"
                    iconSize={24}
                />
            </m.div>
        </AnimatePresence>
    );
};

interface MobileNavRightContent {
    readonly onClick: (e: React.MouseEvent<any>) => void;
    readonly isInModal: boolean;
    readonly formActionLabel: string | undefined;
    readonly formActionIsDisabled: boolean;
}

const MobileNavRightContent: React.VFC<MobileNavRightContent> = p => {
    const { isInModal, formActionLabel, onClick, formActionIsDisabled } = p;

    let content: React.ReactNode;
    if (formActionLabel !== undefined && isInModal) {
        content = (
            <WireButton
                onClick={onClick}
                disabled={formActionIsDisabled}
                appearance={UIButtonAppearance.Simple}
                tw="p-0! font-size[15px]">
                {formActionLabel}
            </WireButton>
        );
    } else if (isInModal) {
        content = (
            <button onClick={onClick}>
                <GlideIcon kind="stroke" icon="st-close" />
            </button>
        );
    }

    return <div tw="flex justify-end items-center">{content}</div>;
};
