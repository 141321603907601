import * as t from "io-ts";

export const apiCapabilitiesCodec = t.type({
    requestReload: t.boolean,
    mutateTables: t.boolean,
    queryTables: t.boolean,
    createAnonymousUser: t.boolean,
});

export type APICapabilities = t.TypeOf<typeof apiCapabilitiesCodec>;
