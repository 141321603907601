import { asString } from "@glide/common-core/dist/js/computation-model/data";
import type { ComponentKind, MutatingScreenKind } from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import { type BaseFieldComponentDescription, BaseFieldComponentHandler, defaultFieldComponent } from "./base-field";

const ComponentKindPhoneField: ComponentKind = "phone-field";

const propertyNamePropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    undefined,
    getPrimitiveColumnsSpec,
    "number",
    PropertySection.Data
);

interface PhoneFieldComponentDescription extends BaseFieldComponentDescription {}

export class PhoneFieldComponentHandler extends BaseFieldComponentHandler<PhoneFieldComponentDescription> {
    public readonly appKinds = "both";

    constructor() {
        super(ComponentKindPhoneField, "phone-number");
    }

    public getDescriptor(
        desc: PhoneFieldComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return this.getFieldDescriptor(
            desc,
            ccc,
            "Phone Entry",
            "Edit a phone number",
            "Entry Fields",
            "co-phone-entry",
            propertyNamePropertyHandler,
            false,
            getDocURL("phoneField"),
            mutatingScreenKind,
            "Phone"
        );
    }

    public inflate(
        ib: WireInflationBackend,
        desc: PhoneFieldComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        return super.inflateComponent(ib, desc, (base, value, token) => ({
            ...base,
            kind: WireComponentKind.PhoneField,
            value: { value: asString(value), onChangeToken: token },
        }));
    }

    public static defaultComponent(column: TableColumn): PhoneFieldComponentDescription {
        return defaultFieldComponent(column, ComponentKindPhoneField);
    }
}
