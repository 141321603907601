import { TailwindThemeProvider, getRuntimeThemeForPlatform, madeWithGlide } from "@glide/common";
import { getSpinner } from "@glide/common-core/dist/js/components/activity-spinner/activity-spinner";
import { AppKind } from "@glide/location-common";
import { defaultAppFeatures, getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import type { AppLoginAuthData } from "@glide/common-core/dist/js/Database";
import type { EminenceFlags } from "@glide/billing-types";
import type { DeviceFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import { isTemplatePlayer } from "@glide/common-core/dist/js/routes";
import { reloadBrowserWindow } from "@glide/common-core/dist/js/support/browser-reload";
import { Appearance } from "@glide/component-utils";
import { glideViralReferralLink } from "@glide/support";
import { withPlayerEminenceFlags, withOSTheme } from "@glide/player-core";
import { getAppKindFromPlay } from "lib/enzyme-utils";
import * as React from "react";
import type { FirebaseAppUserAuthenticator } from "sharedUI/lib/app-user-authenticator";
import { TemplateKindContainer } from "templates/template-app-container";
import AppContainer from "./chrome/smart/app-container/app-container";
import { GlideAppDesktopPlayer, GlideTemplateDesktopPlayer } from "./glide-app-desktop-player";
import { ConnectionIssueWrapper, PlayerContentPlaceholder } from "@glide/common-components";
import Button from "./ui/smart/button/button";
import { getWireAppThemeForPlatform } from "@glide/theme";

interface Props extends React.PropsWithChildren {
    useFrame: boolean;
    deviceFormFactor: DeviceFormFactor;
    showContentBlockMessage: boolean;
    checkContent: (content: React.ReactNode | undefined) => void; // REVIEW: This is kind of nasty
    id?: string;
    authenticator?: FirebaseAppUserAuthenticator;
    loginDataOrFallback?: AppLoginAuthData;
    eminenceFlags: EminenceFlags;
    appInfo: JSX.Element;
    isOSThemeDark: boolean;
}
class GlideAppPlayerSkeletonImpl extends React.PureComponent<Props> {
    private showPlaceholder = (eminenceFlags: EminenceFlags, loginDataOrFallback?: AppLoginAuthData): JSX.Element => {
        let placeholder: JSX.Element;
        const MadeWithGlide = madeWithGlide;
        if (loginDataOrFallback !== undefined) {
            const { theme, features } = loginDataOrFallback;
            const { removeBranding } = eminenceFlags;
            const platformTheme =
                getAppKindFromFeatures(features) === AppKind.Page
                    ? getWireAppThemeForPlatform(theme, this.props.isOSThemeDark)
                    : getRuntimeThemeForPlatform(theme);
            const { signInBackground } = theme;
            const showBackground =
                features.useCustomSignInBackgroundImageInLoading === true && signInBackground !== undefined;

            placeholder = (
                <TailwindThemeProvider theme={platformTheme}>
                    <PlayerContentPlaceholder backgroundImage={showBackground ? signInBackground : undefined}>
                        {!removeBranding && (
                            <a href={glideViralReferralLink("loading")} target="_blank" rel="noopener noreferrer">
                                <MadeWithGlide fgColor={platformTheme.fgColorDark} />
                            </a>
                        )}
                        <div className="spinner">
                            {getSpinner(platformTheme.primaryAccentColor, "loading-placeholder")}
                        </div>
                    </PlayerContentPlaceholder>
                </TailwindThemeProvider>
            );
        } else {
            // FIXME: Remove this flow when loginData can never be undefined. See https://github.com/quicktype/glide/issues/7271
            // I'm guessing this will never be undefined but added the check just in case
            const appLogin = (window as any).appLogin as AppLoginAuthData | undefined;
            const showBackground =
                appLogin !== undefined &&
                appLogin.features.useCustomSignInBackgroundImageInLoading === true &&
                appLogin.theme.signInBackground !== undefined;

            placeholder = (
                <PlayerContentPlaceholder
                    backgroundImage={
                        appLogin !== undefined && showBackground ? appLogin.theme.signInBackground : undefined
                    }
                >
                    <div className="spinner">{getSpinner("#000000", "loading-placeholder")}</div>
                </PlayerContentPlaceholder>
            );
        }

        return placeholder;
    };

    render() {
        const {
            useFrame,
            deviceFormFactor,
            showContentBlockMessage,
            checkContent,
            id,
            authenticator,
            loginDataOrFallback,
            eminenceFlags,
            appInfo,
        } = this.props;

        let content = this.showPlaceholder(eminenceFlags, loginDataOrFallback);
        const platformTheme =
            getAppKindFromFeatures(loginDataOrFallback?.features) === AppKind.Page
                ? getWireAppThemeForPlatform(
                      {
                          primaryAccentColor: loginDataOrFallback?.theme.primaryAccentColor ?? "#FFF",
                          pageBackground: loginDataOrFallback?.theme.pageBackground ?? "Highlight",
                          showTabLabels: true,
                          themeOverlay: "none",
                          increaseContrast: false,
                          showDesktopSideBar: false,
                      },
                      this.props.isOSThemeDark
                  )
                : getRuntimeThemeForPlatform({
                      primaryAccentColor: loginDataOrFallback?.theme.primaryAccentColor ?? "#FFF",
                      pageBackground: loginDataOrFallback?.theme.pageBackground ?? "Highlight",
                      showTabLabels: true,
                      themeOverlay: "none",
                      increaseContrast: false,
                      showDesktopSideBar: false,
                  });
        if (showContentBlockMessage) {
            content = (
                <TailwindThemeProvider theme={platformTheme}>
                    <ConnectionIssueWrapper>
                        <div>
                            Something prevented this app from running. Perhaps it was a connectivity issue or a content
                            blocker.
                        </div>
                        <Button
                            appearance={Appearance.Bordered}
                            url={undefined}
                            title="Try Again"
                            onClick={async () => {
                                reloadBrowserWindow("User requested reload");
                                return undefined;
                            }}
                            appKind={getAppKindFromPlay()}
                        />
                    </ConnectionIssueWrapper>
                </TailwindThemeProvider>
            );
        }

        checkContent(content);
        if (!useFrame) return content;

        const appContainer = (
            <AppContainer
                appFeatures={defaultAppFeatures}
                authenticator={authenticator}
                theme={loginDataOrFallback?.theme}
                title={loginDataOrFallback?.title}
                iconImage={loginDataOrFallback?.iconImage}
                appID={id}
                forceTheme="iOS"
                isSplash={false}
                isBuilder={false}
                showBranding={false}
                showingApp={false}
                useFrame={true}
                deviceFormFactor={deviceFormFactor}
            >
                {content}
            </AppContainer>
        );

        if (isTemplatePlayer()) {
            return (
                <GlideTemplateDesktopPlayer
                    showBranding={false}
                    isSkeleton={true}
                    appContainer={
                        <TemplateKindContainer
                            appKind={getAppKindFromFeatures(loginDataOrFallback?.features ?? defaultAppFeatures)}
                            deviceFormFactor={deviceFormFactor}
                            previewTheme="iOS"
                            theme={loginDataOrFallback?.theme ?? platformTheme}
                        >
                            {appContainer}
                        </TemplateKindContainer>
                    }
                    appInfo={appInfo}
                />
            );
        }

        return (
            <GlideAppDesktopPlayer
                showBranding={false}
                isSkeleton={true}
                appContainer={appContainer}
                appInfo={appInfo}
            />
        );
    }
}

export const GlideAppPlayerSkeleton = withOSTheme(withPlayerEminenceFlags(GlideAppPlayerSkeletonImpl));
