import { mobileSystemFontFamily, pagesFontFamily } from "@glide/common";
import { css, styled } from "@glide/common-components";
import tw from "twin.macro";
const disableSelectableText = css<{ isAppKindPage: boolean }>`
    ${p =>
        !p.isAppKindPage &&
        `
        -khtml-user-select: none; /* Konqueror */
        -webkit-user-select: none; /* Safari */
        -webkit-touch-callout: none; /* iOS */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE and Edge */
        user-select: none;
        `}
`;
const generalStyle = css<{ isBuilderOrTemplatePreviewer: boolean }>`
    button:focus,
    input:focus,
    textarea:focus {
        outline: none;
    }

    --safe-area-inset-bottom: 0px;
    --safe-area-inset-top: 0px;
    --safe-area-inset-left: 0px;
    --safe-area-inset-right: 0px;

    ${p =>
        !p.isBuilderOrTemplatePreviewer &&
        `
            @supports (padding-bottom: constant(safe-area-inset-bottom)) {
                --safe-area-inset-bottom: constant(safe-area-inset-bottom);
                --safe-area-inset-top: constant(safe-area-inset-top);
                --safe-area-inset-left: constant(safe-area-inset-left);
                --safe-area-inset-right: constant(safe-area-inset-right);
            }

            @supports (padding-bottom: env(safe-area-inset-bottom)) {
                --safe-area-inset-bottom: env(safe-area-inset-bottom);
                --safe-area-inset-top: env(safe-area-inset-top);
                --safe-area-inset-left: env(safe-area-inset-left);
                --safe-area-inset-right: env(safe-area-inset-right);
            }
    `}

    ${p =>
        p.isBuilderOrTemplatePreviewer &&
        `
        --safe-area-inset-bottom: ${p.theme.homeBar.height}px;
        --safe-area-inset-top: ${p.theme.statusBar.height}px;
        --safe-area-inset-left: 0px;
        --safe-area-inset-right: 0px;
    `}
`;

export const AppStyle = styled.div<{ isBuilderOrTemplatePreviewer: boolean; isAppKindPage: boolean }>`
    display: flex;
    flex-direction: row;

    font-family: ${p => (p.isAppKindPage ? pagesFontFamily : mobileSystemFontFamily)};
    ${generalStyle}

    background-color: ${p => p.theme.bgBack};

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const AppRoot = styled.div<{ isAppKindPage: boolean }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;

    background-color: transparent;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    ${disableSelectableText}
`;

export const ModalRoot = styled.div`
    pointer-events: auto;
`;

export const ModalContainer = styled.div`
    position: absolute;
    top: var(--safe-area-inset-top);
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    ${tw`z-app-modal-root`};

    & > * {
        --safe-area-inset-top: 0px;
    }
`;

export const PushModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 15;
`;

export const FlyoutContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 35;
`;
