import type {
    AppSnapshotURLs,
    GetPasswordForEmailPinBody,
    NotAllowedToSignInReason,
    RequestAppUserAccessBody,
    SendPinForEmailBody,
} from "@glide/common-core";
import { asAppLoginTokenContainer } from "@glide/common-core";
import type { ResponseStatus } from "@glide/common-core/dist/js/components/types";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import type { JSONObject } from "@glide/support";
import { isResponseOK, logError } from "@glide/support";
import { getDeviceID } from "@glide/common-core/dist/js/device-id";
import { callCloudFunctionWeb } from "@glide/common-core/dist/js/utility/function-utils";
import {
    setSnapshotLocationAndInitialSchemaFromResponse,
    type RootAuthenticator,
    storeCredentialsForFutureSignIn,
} from "./authenticator";
import { parseSendPinForEmailResponse } from "../utils/parse-pin-email-response";
import type { NotificationTarget } from "@glide/plugins";
import { parseNotAllowedToSignInReason } from "../utils/parse-not-allowed-to-sign-in-reason";
import { getVirtualEmailAddress } from "../utils/get-virtual-email-address";

interface PasswordForPin {
    password: string;
    loginToken: AppLoginTokenContainer | undefined;
}

interface PasswordForPinResponse {
    appUserID: string;
    customToken: string;
    newLoginToken: unknown;
    emailColumnName: string | undefined;
    userProfileRow: JSONObject | undefined;
    password: string;
    possiblySchema: unknown;
    snapshotURLs: AppSnapshotURLs;
}

interface PinForEmailResponse {
    status: ResponseStatus;
    target?: string;
    method?: NotificationTarget["method"];
    provider?: string;
    reason?: NotAllowedToSignInReason;
}

interface RequestAppUserAccessResponse {
    status: ResponseStatus;
}

export class EmailPinAuthenticator {
    constructor(
        private readonly appID: string,
        private readonly rootAuthenticator: RootAuthenticator,
        private readonly privateMagicLinkToken: string | undefined
    ) {}

    public onRequestAccessPressedContinue = async (email: string): Promise<RequestAppUserAccessResponse> => {
        if (this.appID !== undefined) {
            const body: RequestAppUserAccessBody = { appID: this.appID, email };
            const response = await getAppFacilities().callAuthIfAvailableCloudFunction(
                "requestAppUserAccess",
                body,
                {}
            );

            if (!isResponseOK(response)) {
                if (response === undefined || Math.floor(response.status / 100) !== 4) {
                    const responseKind = response === undefined ? "no" : "bad";
                    logError(`Could not request app access - ${responseKind} response, check network status`, response);
                    return { status: "Offline" };
                }
                if (response.status === 402) {
                    return { status: "PaymentRequired" };
                }
                return { status: "Forbidden" };
            }
        }
        return { status: "Success" };
    };

    public sendPinForEmail = async (email: string): Promise<PinForEmailResponse> => {
        const body: SendPinForEmailBody = {
            appID: this.appID,
            email,
            privateMagicLinkToken: this.privateMagicLinkToken,
            glideCommit: "NO_IDEA",
        };
        const response = await getAppFacilities().callAuthIfAvailableCloudFunction("sendPinForEmail", body, {});
        if (response?.ok === true) {
            const responseText = await response.text();
            const { sendTarget, sendMethod, sendProvider } = parseSendPinForEmailResponse(responseText);
            return { status: "Success", target: sendTarget, method: sendMethod, provider: sendProvider };
        }

        if (response?.status === 402) {
            logError(`Payment required`, response);
            return { status: "PaymentRequired" };
        }

        if (response !== undefined && response.status === 401) {
            const notAllowedToSignInReason = await parseNotAllowedToSignInReason(response);
            return { status: "Forbidden", reason: notAllowedToSignInReason };
        }

        if (response === undefined || response.status >= 500) {
            const responseKind = response === undefined ? "no" : "bad";
            logError(`Could not get send pin for email - ${responseKind} response, check network status`, response);
            return { status: "Offline" };
        }

        logError("Could not get send pin for email - email doesn't have access", response);
        return { status: "Forbidden" };
    };

    public getPasswordForPin = async (email: string, pin: string): Promise<PasswordForPin | undefined> => {
        const appFacilities = getAppFacilities();

        const body: GetPasswordForEmailPinBody = {
            appID: this.appID,
            addUserProfileRow: true,
            deviceID: getDeviceID(),
            email,
            pin,
            userAgreed: true,
        };
        const response = await callCloudFunctionWeb("getPasswordForEmailPin", body, {});
        if (!isResponseOK(response)) {
            logError("Could not get password for pin", response);
            return undefined;
        }

        let responseBody: unknown;
        try {
            responseBody = await response.json();
        } catch (e: unknown) {
            logError("Reading getPasswordForEmailPin body", e);
            return undefined;
        }

        const decoded = this.getPasswordForPinInfoFromResponse(responseBody);
        const {
            appUserID,
            customToken,
            possiblySchema,
            userProfileRow,
            emailColumnName,
            newLoginToken,
            password,
            snapshotURLs,
        } = decoded;

        setSnapshotLocationAndInitialSchemaFromResponse(this.appID, snapshotURLs, possiblySchema);

        const wasSignInSuccessful = await appFacilities.signInWithCustomToken(customToken);
        if (!wasSignInSuccessful) return undefined;

        const loginToken = asAppLoginTokenContainer(newLoginToken);
        storeCredentialsForFutureSignIn(this.appID, email, password, loginToken);

        this.rootAuthenticator.setAuthenticatedUser({
            appUserID,
            realEmail: email,
            virtualEmail: getVirtualEmailAddress(userProfileRow, emailColumnName) ?? email,
            userProfileRow,
            loginToken,
        });

        return { password, loginToken };
    };

    private getPasswordForPinInfoFromResponse = (response: unknown): PasswordForPinResponse => {
        const anyResponse = response as any;

        return {
            appUserID: anyResponse.appUserID,
            customToken: anyResponse.customToken,
            newLoginToken: anyResponse.newLoginToken,
            password: anyResponse.password,
            possiblySchema: anyResponse.schema,
            emailColumnName: anyResponse.emailColumnName,
            userProfileRow: anyResponse.userProfileRow,
            snapshotURLs: {
                dataSnapshot: anyResponse.dataSnapshot,
                privateDataSnapshot: anyResponse.privateDataSnapshot,
                unusedDataSnapshot: anyResponse.unusedDataSnapshot,
                publishedAppSnapshot: anyResponse.publishedAppSnapshot,
                nativeTableSnapshots: anyResponse.nativeTableSnapshots,
            },
        };
    };
}
