let rng = () => Math.random();

// Math.random() tends to be a fairly weak RNG. This is usually "good enough"
// but we may want to expose better RNG whenever possible.
export function setStringGeneratorRNG(fn: () => number): () => number {
    rng = fn;
    return rng;
}

export function generateStringFromAlphabet(stringSize: number, alphabet: string): string {
    let buffer = "";
    while (buffer.length < stringSize) {
        buffer += alphabet[Math.floor(rng() * alphabet.length)];
    }
    return buffer;
}

const hexCharacters = "abcdef0123456789";

export function generateHexString(stringSize: number): string {
    return generateStringFromAlphabet(stringSize, hexCharacters);
}
