import type { SerializedApp } from "@glide/app-description";
import type { PluginValidationByAppResponse } from "@glide/common-core/dist/js/firebase-function-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { getQuadraticBackoffWaitTime } from "@glide/support";
import { useQuery } from "@tanstack/react-query";

const getPluginValidationByApp = async (appID: string): Promise<PluginValidationByAppResponse | undefined> => {
    const f = getAppFacilities();
    const res = await f.callAuthCloudFunction("validateAppPlugins", { appID });
    if (res === undefined) return undefined;
    return await res.json();
};

export function usePluginValidationByApp(
    enabled: boolean,
    appID: string,
    pluginConfigs: SerializedApp["pluginConfigs"],
    refetchOnMount: boolean | "always"
) {
    const { data, isLoading, isRefetching } = useQuery(
        ["usePluginValidationByApp", appID, pluginConfigs],
        async () => {
            const inApp = new Set(pluginConfigs?.map(p => p.pluginID));
            const results = await getPluginValidationByApp(appID);
            return results?.filter(r => inApp.has(r)); // Only return errors for plugins that are added to app
        },
        {
            enabled,
            refetchOnMount,
            staleTime: Infinity,
            cacheTime: 3_600_000, // 1 hour
            retryDelay: attemptIndex => getQuadraticBackoffWaitTime(500, attemptIndex),
        }
    );

    return { data, isLoading, isRefetching };
}
