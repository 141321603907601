import { asMaybeDate, asMaybeNumber } from "@glide/common-core/dist/js/computation-model/data";
import {
    makeSwitchProperty,
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
} from "@glide/app-description";
import { makeEmptyComponentDescription, type InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { GlideDateTime } from "@glide/data-types";
import type { WireAppStopwatchComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type WireInflationBackend, type WireRowComponentHydratorConstructor, WireComponentKind } from "@glide/wire";

import {
    inflateEditablePropertyWithDefault,
    makeSimpleWireRowComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import { ComponentHandlerBase } from "./handler";

const ComponentKindStopwatch: ComponentKind = "stopwatch";

interface StopwatchComponentDescription extends ComponentDescription {
    readonly startTime: PropertyDescription;
    readonly duration: PropertyDescription;
}

const startTimePropertyHandler = new ColumnPropertyHandler(
    "startTime",
    "Start time",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable, ColumnPropertyFlag.EditedInApp],
    undefined,
    ["start", "begin"],
    getPrimitiveColumnsSpec,
    "date-time",
    PropertySection.Data
);
const durationPropertyHandler = new ColumnPropertyHandler(
    "duration",
    "Duration",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable, ColumnPropertyFlag.EditedInApp],
    undefined,
    ["duration", "length"],
    getPrimitiveColumnsSpec,
    "duration",
    PropertySection.Data
);

export class StopwatchComponentHandler extends ComponentHandlerBase<StopwatchComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindStopwatch);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public getDescriptor(
        _desc: StopwatchComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        _mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Stopwatch",
            description: "Measure a duration",
            img: "co-stopwatch",
            group: "Entry Fields",
            // FIXME: doc url
            helpUrl: getDocURL("stopwatch"),
            properties: [startTimePropertyHandler, durationPropertyHandler, ...this.getBasePropertyDescriptors()],
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: StopwatchComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const startTimeProperty = startTimePropertyHandler.getProperty(desc);
        const durationProperty = durationPropertyHandler.getProperty(desc);

        const { getter: startTimeGetter } = inflateEditablePropertyWithDefault<GlideDateTime | "">(
            ib,
            "startTime",
            startTimeProperty,
            asMaybeDate,
            ""
        );
        const { getter: durationGetter } = inflateEditablePropertyWithDefault<number | "">(
            ib,
            "duration",
            durationProperty,
            asMaybeNumber,
            ""
        );
        if (startTimeGetter === undefined || durationGetter === undefined) return undefined;

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const component: WireAppStopwatchComponent = {
                ...spreadComponentID(desc.componentID, ib.forBuilder),
                kind: WireComponentKind.AppStopwatch,
                startTime: startTimeGetter(hb),
                duration: durationGetter(hb),
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: StopwatchComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return {
            ...makeEmptyComponentDescription(WireComponentKind.Stopwatch),
            elapsedTime: desc.duration,
            showReset: makeSwitchProperty(true),
        } as ComponentDescription;
    }
}
