import {
    isLiveUpdateQuery,
    type LoadingValue,
    type MutableTable,
    type SerializedQuery,
    type QueryTableVersions,
} from "@glide/computation-model-types";
import type { TableGlideTypeWithUniversalName } from "@glide/type-schema";
import type { QueryContinuation, QueryResponseContinuation, QueryResponseEntry } from "@glide/common-core";
import type { JSONObject } from "@glide/support";

export enum QueryState {
    Execute = "execute",
    Continue = "continue",
    Done = "done",
}

export interface ActiveQueryBase {
    readonly subQueryID: string;

    // We modify this to update the limit.  Note that the `limit` in this does
    // not represent how many the app wants as a limit, but rather how many we
    // need to query next time we do either `executeQuery` or `continueQuery`.
    subQuery: SerializedQuery;

    // We use a set for this because currently the `WireBackend` uses a single
    // callback, but can add it quite often.
    readonly callbacks: Set<() => void>;
    state: QueryState;
    currentValue: MutableTable | LoadingValue;

    // This can only be set as long as a re-query is in progress.  While it's
    // set, this is the value that should be returned for the current result
    // set, because the `currentValue` might not be "complete" yet.
    lastValue: MutableTable | undefined;

    warningMessage: string | undefined;
    errorMessage: string | undefined;

    readonly updateLocally: boolean;

    tableVersions: QueryTableVersions | undefined;
}

export interface ActiveQuery extends ActiveQueryBase {
    readonly saveSerial: number;

    nextContinuation: { token: QueryResponseContinuation; expiresAfter: Date } | undefined;
    // This is how many rows we've ever queried, including all continuation
    // queries.  It doesn't mean that we've ever queried all of them in one
    // go, just that we've accumulated that many via `executeQuery` and
    // follow-up `continueQuery` calls.
    maxLimit: number;
}

export function isActiveQuery(query: ActiveQueryBase): query is ActiveQuery {
    const asActive = query as ActiveQuery;
    return typeof asActive.saveSerial === "number" && typeof asActive.maxLimit === "number";
}

// A query is considered the first request if it's an aggregation query, or it doesn't have a continuation defined.
// Subsequent requests will have a `nextContinuation` value defined.
export function isFirstQuery(query: ActiveQueryBase): boolean {
    if (!isActiveQuery(query)) return true;
    return query.nextContinuation === undefined;
}

// Record the versions from the request if none have been stored, this is the first request in a series, or it's a
// requery triggered by live updates. Table and row versions could change after following a continuation query. In that
// case, we want to keep the existing table/row version to represent the version of the oldest data in the set.
export function setTableVersionsForQuery(
    query: ActiveQueryBase,
    newTableVersions: QueryTableVersions | undefined
): void {
    if (newTableVersions === undefined) return;
    if (query.tableVersions !== undefined && !isFirstQuery(query) && !isLiveUpdateQuery(query.subQuery)) return;
    query.tableVersions = newTableVersions;
}

export interface ActiveAggregationQuery extends ActiveQueryBase {}

export interface QueryRunnerCallbacks {
    subQueryID: string;
    // If this returns `false` the caller does not need to continue.
    // If `rows` is `undefined` it means that we haven't gotten a
    // `continueQuery` response yet, i.e. we might or might not be getting
    // rows in the future.
    addRows(rows: readonly JSONObject[] | undefined): boolean;

    installNewTable(table: TableGlideTypeWithUniversalName): void;

    runFirstQuery(): Promise<QueryResponseEntry | undefined>;

    runContinuationQuery(continuation: QueryContinuation): Promise<readonly QueryResponseEntry[] | undefined>;
}
