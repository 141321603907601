import type { ActionComponentDescription, ActionKind, ComponentKind, MutatingScreenKind } from "@glide/app-description";
import { type TableGlideType, isPrimitiveType, isStringTypeKind, type SchemaInspector } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { type Doc, getDocURL } from "@glide/common-core/dist/js/docUrl";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ColumnTypePredicate,
    type ComponentDescriptor,
    type PropertyDescriptor,
    type PropertySection,
    isNamedPropertySource,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
} from "@glide/function-utils";
import { panic } from "@glideapps/ts-necessities";
import { ComponentHandlerBase } from "./handler";
import { makeDefaultActionDescriptorWithKinds } from "./primitive";

export interface PropertyDescriptorWithArgumentName {
    readonly descriptor: PropertyDescriptor | (() => PropertyDescriptor);
    readonly argumentName: string | undefined;
}

function getDescriptorForProperty({ descriptor }: PropertyDescriptorWithArgumentName): PropertyDescriptor {
    if (typeof descriptor === "function") {
        return descriptor();
    } else {
        return descriptor;
    }
}

function isPropertyDescriptorWithArgumentName(x: unknown): x is PropertyDescriptorWithArgumentName {
    if (typeof x !== "object" || x === null) return false;
    if (Object.keys(x).length !== 2) return false;
    const { descriptor, argumentName } = x as any;
    if (descriptor === undefined) return false;
    if (argumentName !== undefined && typeof argumentName !== "string") return false;
    return true;
}

interface SimpleComponentArguments {
    readonly kind: ComponentKind;
    readonly name: string;
    readonly group: string;
    readonly description: string;
    readonly img?: string;
    readonly helpPath: Doc;
}

function toLowerDashed(s: string): string {
    return s.toLowerCase().trim().replace(/\s+/g, "-");
}

export interface SimpleComponentDescription extends ActionComponentDescription {}

export abstract class SimpleComponentHandler extends ComponentHandlerBase<SimpleComponentDescription> {
    private readonly _name: string;
    private readonly _group: string;
    private readonly _description: string;
    private readonly _img: string;
    private readonly _helpPath: Doc;

    constructor({ kind, name, group, description, img, helpPath }: SimpleComponentArguments) {
        super(kind);

        const nameLowerDashed = toLowerDashed(name);

        this._name = name;
        this._group = group;
        this._description = description;

        this._img = img ?? nameLowerDashed;
        this._helpPath = helpPath;
    }

    protected get configuresScreenTitle(): boolean {
        return false;
    }

    protected abstract getPropertyDescriptors(
        desc: SimpleComponentDescription | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        schema: SchemaInspector | undefined,
        table: TableGlideType | undefined
    ): readonly (PropertyDescriptor | PropertyDescriptorWithArgumentName)[];

    protected getActionKinds?(
        mutatingScreenKind: MutatingScreenKind | undefined,
        appHasUserProfile: boolean
    ): readonly ActionKind[];

    protected getIsLegacy(): boolean {
        return false;
    }

    private getProperties(
        desc: SimpleComponentDescription | undefined,
        table: TableGlideType | undefined,
        schema: SchemaInspector | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly PropertyDescriptorWithArgumentName[] {
        return this.getPropertyDescriptors(desc, mutatingScreenKind, schema, table).map(p => {
            if (isPropertyDescriptorWithArgumentName(p)) return p;
            if (!isNamedPropertySource(p.property)) {
                return panic("Simple component handler only supports named property sources");
            }
            return { descriptor: p, argumentName: p.property.name };
        });
    }

    public getDescriptor(
        desc: SimpleComponentDescription | undefined,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties = this.getProperties(desc, tables?.input, ccc, mutatingScreenKind);

        return {
            name: this._name,
            description: this._description,
            img: this._img,
            group: this._group,
            helpUrl: getDocURL(this._helpPath),
            isLegacy: this.getIsLegacy(),
            properties: [...properties.map(getDescriptorForProperty), ...this.getBasePropertyDescriptors()],
            configuresScreenTitle: this.configuresScreenTitle,
        };
    }

    public getActionDescriptors(
        _desc: SimpleComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        schema: SchemaInspector | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly ActionPropertyDescriptor[] {
        const actionKinds = this.getActionKinds?.(mutatingScreenKind, schema?.userProfileTableInfo !== undefined);
        if (actionKinds === undefined) return [];
        return [makeDefaultActionDescriptorWithKinds(actionKinds, false)];
    }
}

interface SimplePrimitiveColumnPropertyArguments {
    readonly name: string;
    readonly label: string;
    readonly preferredType: ColumnTypePredicate;
    readonly isRequired?: boolean;
    readonly searchable?: boolean;
    readonly preferredNames?: readonly string[];
    readonly mustBeString?: boolean;
    readonly section: PropertySection;
}

export class SimplePrimitiveColumnPropertyHandler extends ColumnPropertyHandler {
    constructor({
        name,
        label,
        preferredType,
        isRequired,
        searchable,
        preferredNames,
        mustBeString,
        section,
    }: SimplePrimitiveColumnPropertyArguments) {
        const flags = [ColumnPropertyFlag.Editable];
        if (isRequired ?? true) {
            flags.push(ColumnPropertyFlag.Required);
        }
        if (searchable ?? false) {
            flags.push(ColumnPropertyFlag.Searchable);
        }

        super(
            name,
            label,
            flags,
            undefined,
            preferredNames,
            {
                columnTypeIsAllowed: t => (mustBeString === true ? isStringTypeKind(t.kind) : isPrimitiveType(t)),
                getCandidateColumns: t => t.columns,
            },
            preferredType,
            section
        );
    }
}
