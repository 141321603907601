import type { StrokeIcons } from "@glide/plugins";

import type { EminenceFlagsThatUsersCanUpgradeFor } from "../Database/eminence";

interface UpgradeInformation {
    name: string;
    description: string;
    icon: StrokeIcons;
}

export const upgradeInformation: Record<keyof EminenceFlagsThatUsersCanUpgradeFor, UpgradeInformation> = {
    signInAgreements: {
        name: "Custom User Agreements",
        description: "Require users to agree to custom terms of service and privacy policy",
        icon: "st-users",
    },
    canDisableShare: {
        name: "Custom About Screen",
        description: "(Glide Apps only) Change the name or visibility of the About screen in your app menu",
        icon: "st-share",
    },
    automaticRefresh: {
        name: "Extra Sync Mode",
        description: "Sync every few minutes while app is active",
        icon: "st-reload",
    },
    customSignInAppearance: {
        name: "Branded Sign-in Screen",
        description: "Add a background image or logo",
        icon: "st-design-brush",
    },
    bigQuery: {
        name: "Connect to Google BigQuery",
        description: "Google's data warehouse",
        icon: "st-data",
    },
    canAccessAnalytics: {
        name: "Gain access to App Analytics",
        description: "Analytics Screen",
        icon: "st-chart",
    },
    // note, this key is for pages customization vs classic apps.
    canCustomizeSignIn: {
        name: "Branded Sign-in Screen",
        description: "Add a background image or logo",
        icon: "st-design-brush",
    },
};
