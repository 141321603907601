import type { GlobalStyleComponent, Interpolation, ThemedStyledProps } from "styled-components";
import { createGlobalStyle as createGlobalStyleUntyped } from "styled-components";
import type { BuilderTheme } from "@glide/theme";

export function createGlobalStyle<P = {}>(
    strings: TemplateStringsArray,
    ...interpolations: Array<Interpolation<ThemedStyledProps<P, BuilderTheme>>>
): GlobalStyleComponent<P, BuilderTheme> {
    // sorry for the as any's, TS Server wont shuttup about this one, it's just wrong
    return createGlobalStyleUntyped(strings, ...(interpolations as any)) as any;
}
