import "twin.macro";

import {
    AppIcon,
    ClickOutsideContainer,
    GlideIcon,
    isComponentIcon,
    isMonotoneIcon,
    isStrokeIcon,
    isTwotoneIcon,
    useAccelerator,
} from "@glide/common";
import type { AcceleratorCallback } from "@glide/common";
import { trackEventOnce } from "@glide/common-core/dist/js/analytics";
import { X } from "@glide/common-core/dist/js/react-plucked";
import classnames from "classnames";
import * as React from "react";
import { mergeRefs, useLayer } from "react-laag";

import type { ItemDescription } from "../../lib/dropdown-types";
import { PushDropdown } from "../push-dropdown/push-dropdown";
import { Tooltip } from "@glide/common-components";
import { DragItemStyle, ErrorMessage, OuterWrapper, Secondary, Title, VisibilityWrapper } from "./drag-item-style";

export enum SelectedState {
    Selected,
    InPath,
    None,
}

export interface DragItemProps<TMenuItem> {
    readonly isHighlighted: boolean;
    readonly name: string;

    readonly highlightName?: boolean;
    readonly icon?: string | JSX.Element;
    readonly isDraggable: boolean;
    readonly isDragging: boolean;
    readonly isHidden?: boolean;
    readonly isLocked?: boolean;
    readonly isNotFirstAssignment?: boolean;
    readonly menuItems?: readonly TMenuItem[];
    readonly secondary: string;
    readonly accessory?: JSX.Element;
    readonly selected?: SelectedState;
    readonly tooltip?: JSX.Element;
    readonly warn?: boolean;
    readonly tail?: JSX.Element;
    readonly tailOnHover?: JSX.Element;

    readonly afterStealingRenameFocus?: () => void;
    readonly descriptionForItem?: (menuItem: TMenuItem) => ItemDescription;
    readonly onClick?: () => void;
    readonly onContextMenu?: (e: React.MouseEvent) => void;
    readonly onCopy?: () => void;
    readonly onCut?: () => void;
    readonly onDuplicate?: () => void;
    readonly onHiddenClick?: () => void;
    readonly onMenuItemClicked?: (item: TMenuItem) => void;
    readonly onMouseEnter?: () => void;
    readonly onMouseLeave?: () => void;
    readonly onNameChanged?: (newValue: string) => void;
    readonly onRemove?: () => void;
}

export function DragItem<TMenuItem>(props: DragItemProps<TMenuItem>): React.ReactElement | null {
    const {
        afterStealingRenameFocus,
        descriptionForItem,
        highlightName,
        icon,
        isDragging,
        isHidden,
        isHighlighted,
        isLocked = false,
        isNotFirstAssignment,
        menuItems,
        name,
        onClick,
        onContextMenu,
        onCopy,
        onCut,
        onDuplicate,
        onHiddenClick: onHiddenClickReal,
        onMenuItemClicked,
        onMouseEnter,
        onMouseLeave,
        onNameChanged,
        onRemove,
        secondary,
        accessory,
        selected,
        tooltip,
        warn,
        tail,
        tailOnHover,
    } = props;

    const [hovered, setHovered] = React.useState(false);
    const [isRenaming, setIsRenaming] = React.useState(false);
    const [renameValue, setRenameValue] = React.useState(name);
    const [showMenu, setShowMenu] = React.useState(false);
    const ref = React.useRef<HTMLDivElement>(null);

    const bindings: string[] = [];
    if (selected === SelectedState.Selected) {
        if (onCopy !== undefined) {
            bindings.push("ctrl+c", "cmd+c");
        }
        if (onCut !== undefined) {
            bindings.push("ctrl+x", "cmd+x");
        }
    }
    useAccelerator(
        bindings,
        React.useCallback<AcceleratorCallback>(
            (e, binding) => {
                if (binding === "ctrl+c" || binding === "cmd+c") {
                    if (document.activeElement !== ref.current) return;
                    onCopy?.();
                    e.preventDefault();
                    e.stopImmediatePropagation();
                } else if (binding === "ctrl+x" || binding === "cmd+x") {
                    onCut?.();
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            },
            [onCopy, onCut]
        ),
        true
    );

    const onRemoveClicked = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onRemove?.();
        },
        [onRemove]
    );

    const onDuplicateClicked = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onDuplicate?.();
        },
        [onDuplicate]
    );

    const onHiddenClick = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onHiddenClickReal?.();
        },
        [onHiddenClickReal]
    );

    const onRenameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setRenameValue(e.target.value);
    }, []);

    const onRenameStart = React.useCallback(() => {
        if (onNameChanged === undefined) return;

        setIsRenaming(true);
        setRenameValue(name);
    }, [name, onNameChanged]);

    const onRenameEnd = React.useCallback(() => {
        if (renameValue.length > 0) {
            onNameChanged?.(renameValue);
        }
        setIsRenaming(false);
    }, [onNameChanged, renameValue]);

    const onKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Escape") {
                event.stopPropagation();
                const target = event.currentTarget;
                setRenameValue(name);
                window.requestAnimationFrame(target.blur);
            } else if (event.key === "Enter") {
                event.stopPropagation();
                event.currentTarget.blur();
            }
        },
        [name]
    );

    const onContextMenuInner = React.useCallback(
        (event: React.MouseEvent) => {
            if (onContextMenu || menuItems !== undefined) {
                trackEventOnce("did right-click", {});
            }
            onContextMenu?.(event);
            if (menuItems === undefined || onContextMenu) return;

            event.stopPropagation();
            event.preventDefault();
            setShowMenu(true);
        },
        [menuItems, onContextMenu]
    );

    let iconNode: React.ReactNode;
    if (typeof icon === "string") {
        if (isStrokeIcon(icon)) {
            iconNode = <GlideIcon kind="stroke" icon={icon} iconSize={16} />;
        } else if (isTwotoneIcon(icon)) {
            iconNode = <GlideIcon kind="twotone" icon={icon} iconSize={16} />;
        } else if (isMonotoneIcon(icon)) {
            iconNode = <GlideIcon kind="monotone" icon={icon} iconSize={16} />;
        } else if (isComponentIcon(icon)) {
            iconNode = <GlideIcon kind="component" icon={icon} iconSize={16} />;
        } else {
            iconNode = <AppIcon icon={icon} size={16} />;
        }
    } else if (icon !== undefined) {
        iconNode = icon;
    }

    const menuIsOpen = showMenu && menuItems !== undefined && descriptionForItem !== undefined;

    const { renderLayer, layerProps, triggerProps, triggerBounds } = useLayer({
        isOpen: menuIsOpen,
        triggerOffset: 2,
        container: "portal",
        auto: true,
        placement: "bottom-end",
    });

    const onItemSelectInner = React.useCallback(
        (item: TMenuItem) => {
            onMenuItemClicked?.(item);
            setShowMenu(false);
        },
        [onMenuItemClicked]
    );

    const onWrapperClick = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (document.activeElement === e.currentTarget && selected === SelectedState.Selected) {
                onRenameStart();
            } else {
                e.currentTarget.focus();
                onClick?.();
            }
            e.stopPropagation();
        },
        [onClick, onRenameStart, selected]
    );

    const duplicateRef = React.useRef<HTMLDivElement | null>(null);
    const lockedRef = React.useRef<HTMLDivElement | null>(null);

    const onInnerMouseDown = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation(), []);

    if (
        selected === SelectedState.Selected &&
        ref.current !== null &&
        document.activeElement !== ref.current &&
        afterStealingRenameFocus !== undefined
    ) {
        ref.current.focus();
        onRenameStart();
        afterStealingRenameFocus();
    }

    return (
        <>
            <OuterWrapper
                data-test={"bld-drag-item-" + secondary}
                className={classnames(
                    selected === SelectedState.Selected && "selected",
                    selected === SelectedState.InPath && "in-path"
                )}
                isHidden={isHidden ?? false}
                onContextMenu={onContextMenuInner}>
                {showMenu &&
                    menuItems !== undefined &&
                    descriptionForItem !== undefined &&
                    renderLayer(
                        <div {...layerProps}>
                            <ClickOutsideContainer onClickOutside={() => setShowMenu(false)}>
                                <PushDropdown
                                    width={triggerBounds?.width}
                                    items={menuItems}
                                    descriptionForItem={descriptionForItem as any}
                                    onItemSelect={onItemSelectInner as any}
                                />
                            </ClickOutsideContainer>
                        </div>
                    )}
                <DragItemStyle
                    onClick={onWrapperClick}
                    isDragging={isDragging}
                    isHighlighted={isHighlighted}
                    tabIndex={-1}
                    hasBottom={false}
                    className={classnames("group", warn && "warn", highlightName && "prelight")}
                    onMouseEnter={() => {
                        onMouseEnter?.();
                        setHovered(true);
                    }}
                    onMouseLeave={() => {
                        onMouseLeave?.();
                        setHovered(false);
                    }}
                    ref={mergeRefs(ref, triggerProps.ref)}>
                    <div className="top-wrapper">
                        {iconNode !== undefined && <div className="icon-area">{iconNode}</div>}
                        {isRenaming && (
                            <input
                                onMouseDown={onInnerMouseDown}
                                onKeyDown={onKeyDown}
                                autoFocus={true}
                                className="input-rename"
                                onBlur={onRenameEnd}
                                onFocus={e => e.target.select()}
                                onChange={onRenameChange}
                                value={renameValue}
                            />
                        )}
                        {!isRenaming && <Title>{name}</Title>}
                        <Secondary>{secondary}</Secondary>
                        {accessory}
                        {isLocked && (
                            <div ref={lockedRef} tw="grid place-content-center">
                                <AppIcon
                                    tw="opacity-0 group-hover:opacity-100 ml-auto mr-2 text-icon-pale
                                        hover:text-icon-base"
                                    size={14}
                                    icon="lock"
                                />
                                <Tooltip position="top" target={lockedRef}>
                                    This screen is required
                                </Tooltip>
                            </div>
                        )}
                        {!isRenaming && onDuplicate !== undefined && (
                            <div
                                ref={duplicateRef}
                                className="remove-wrap child-button with-ring"
                                onMouseDown={onInnerMouseDown}
                                onClick={onDuplicateClicked}>
                                <AppIcon size={16} icon="duplicate" />
                                <Tooltip position="top" target={duplicateRef}>
                                    Duplicate Item
                                </Tooltip>
                            </div>
                        )}
                        {!isRenaming && onRemove !== undefined && (
                            <div
                                className="remove-wrap child-button with-ring"
                                onMouseDown={onInnerMouseDown}
                                onClick={e => {
                                    e.stopPropagation();
                                    onRemoveClicked(e);
                                    setHovered(false);
                                }}>
                                <X size={10} />
                            </div>
                        )}
                        {!isRenaming && onHiddenClickReal !== undefined && (isHidden || hovered) && (
                            <VisibilityWrapper onClick={onHiddenClick} onMouseDown={onInnerMouseDown}>
                                {isHidden ? (
                                    <GlideIcon kind={"monotone"} icon={"mt-eye-hide"} iconSize={16} />
                                ) : (
                                    <GlideIcon kind={"monotone"} icon={"mt-eye"} iconSize={16} />
                                )}
                            </VisibilityWrapper>
                        )}
                        {tailOnHover !== undefined && hovered && (
                            <div className="remove-wrap child-button with-ring">{tailOnHover}</div>
                        )}
                        {tail}
                        {tooltip !== undefined && <div className="tooltip">{tooltip}</div>}
                    </div>
                </DragItemStyle>
            </OuterWrapper>
            {isNotFirstAssignment && <ErrorMessage>Another component is already writing to this column</ErrorMessage>}
        </>
    );
}
