import type { IconName } from "@glide/common";
import { getNamedIcon, GlideIcon, isIconName } from "@glide/common";
import type { GlideIconProps } from "@glide/plugins-codecs";
import { glideIconPropsCodec } from "@glide/plugins-codecs";
import { isUrl } from "@glide/support";

interface ActionIconProps {
    readonly iconName: string | GlideIconProps;
    readonly iconSize?: number;
}
export const ActionIcon: React.FC<ActionIconProps> = p => {
    const { iconName, iconSize } = p;

    if (typeof iconName === "string" && isUrl(iconName)) {
        return <img src={iconName} tw="rounded-lg" alt="icon" />;
    } else if (glideIconPropsCodec.is(iconName)) {
        return <GlideIcon {...iconName} iconSize={iconSize} />;
    } else {
        // Since iconName can be any string, we need to ensure it's a valid icon name.
        // If it's not, we default to "lightningAction" to avoid crashing.
        const realIconName: IconName = isIconName(iconName) ? iconName : "lightningAction";
        const Component = getNamedIcon(realIconName);
        return <Component />;
    }
};
