import type { PluginSpecialValueDescription, SpecialValueDescription, PrimitiveGlideType } from "@glide/type-schema";
import { areSpecialValuesEqual, specialValueTypeKinds, makePrimitiveType, SpecialValueKind } from "@glide/type-schema";
import type { SerializablePluginMetadata } from "@glide/plugins-codecs";
import { getTypeKindForPrimitivePluginType } from "../plugins/convert-types";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import type { ExperimentName, UserFeatures } from "@glide/app-description";

export interface SpecialValueDescriptor {
    readonly kind: SpecialValueDescription;
    readonly label: string;
    readonly description: string;
    readonly img: string;
}

const baseSpecialValueDescriptors: readonly SpecialValueDescriptor[] = [
    {
        kind: SpecialValueKind.Timestamp,
        label: "Current date/time",
        description: "Current time",
        img: "headerDate",
    },
    {
        kind: SpecialValueKind.UniqueIdentifier,
        label: "Unique identifier",
        description: "A unique value",
        img: "headerUniqueId",
    },
];

const clearColumnDescriptor: SpecialValueDescriptor = {
    kind: SpecialValueKind.ClearColumn,
    label: "Clear value",
    description: "Clear out the value in the column",
    img: "cancelX",
};

interface GetSpecialValueDescriptorsOptions {
    /**
     * Defaults to `false`.
     */
    readonly excludePrimitiveValues?: boolean;
    /**
     * Defaults to `false`.
     */
    readonly withActionSource?: boolean;
    /**
     * Defaults to `false`.
     */
    readonly withClearColumn?: boolean;
    /**
     * In Automations we exclude the `VerifiedEmailAddress` and `CurrentURL`.
     * Defaults to `false`.
     */
    readonly forAutomation?: boolean;
}

function getPluginIconForSpecialValue(
    plugin: SerializablePluginMetadata,
    computation: SerializablePluginMetadata["computations"][number]
): string {
    if (computation.icon !== undefined) {
        return typeof computation.icon === "string" ? computation.icon : computation.icon?.icon ?? "headerPlugin";
    }
    return typeof plugin.icon === "string" ? plugin.icon : plugin.icon?.icon ?? "headerPlugin";
}

export function getPluginsSpecialValuesDescriptors(
    pluginMetadata: readonly SerializablePluginMetadata[],
    userFeatures: UserFeatures
): SpecialValueDescriptor[] {
    const descrs: SpecialValueDescriptor[] = [];

    for (const plugin of pluginMetadata) {
        if (
            plugin.experimentFlag !== undefined &&
            !isExperimentEnabled(plugin.experimentFlag as ExperimentName, userFeatures)
        ) {
            continue;
        }

        for (const computation of plugin.computations) {
            if (computation.showAsSpecialValue !== true) continue;
            if (
                computation.experimentFlag !== undefined &&
                !isExperimentEnabled(computation.experimentFlag as ExperimentName, userFeatures)
            )
                continue;

            for (const [resultName, result] of Object.entries(computation.results)) {
                const typeKind = getTypeKindForPrimitivePluginType(result.type);
                if (typeKind === undefined) continue;

                descrs.push({
                    kind: {
                        pluginID: plugin.id,
                        computationID: computation.id,
                        resultName,
                        type: makePrimitiveType(typeKind),
                    },
                    label: `${computation.name}/${result.name}`,
                    description: result.description ?? result.name,
                    img: getPluginIconForSpecialValue(plugin, computation),
                });
            }
        }
    }

    return descrs;
}

export function getSpecialValueDescriptors(
    {
        excludePrimitiveValues = false,
        withActionSource = false,
        withClearColumn = false,
        forAutomation: forAutomations = false,
    }: GetSpecialValueDescriptorsOptions,
    pluginMetadata: readonly SerializablePluginMetadata[] | undefined,
    userFeatures: UserFeatures
): readonly SpecialValueDescriptor[] {
    if (excludePrimitiveValues) {
        if (withClearColumn) {
            return [clearColumnDescriptor];
        } else {
            return [];
        }
    }

    const descrs = [...baseSpecialValueDescriptors];

    if (!forAutomations) {
        descrs.push({
            kind: SpecialValueKind.VerifiedEmailAddress,
            label: "App user's email",
            description: "The email address of the app's user",
            img: "headerEmail",
        });

        descrs.push({
            kind: SpecialValueKind.CurrentURL,
            label: "Link to current screen",
            description: "The URL of the current screen",
            img: "headerUri",
        });
    }

    if (withActionSource) {
        descrs.push({
            kind: SpecialValueKind.ActionSource,
            label: "Action source",
            description: "An identifier for the source of the action",
            img: "co-separator",
        });
    }
    if (withClearColumn) {
        descrs.push(clearColumnDescriptor);
    }

    if (pluginMetadata !== undefined) {
        const pluginDescrs = getPluginsSpecialValuesDescriptors(pluginMetadata, userFeatures);
        descrs.push(...pluginDescrs);
    }

    return descrs;
}

export function findSpecialValueDescriptor(
    descrs: readonly SpecialValueDescriptor[],
    kind: SpecialValueDescription
): SpecialValueDescriptor | undefined {
    return descrs.find(i => areSpecialValuesEqual(i.kind, kind));
}

interface ResolvedPluginSpecialValue {
    readonly type: PrimitiveGlideType;
}

export function resolvePluginSpecialValue(
    { pluginID, computationID, resultName }: PluginSpecialValueDescription,
    pluginMetadata: readonly SerializablePluginMetadata[]
): ResolvedPluginSpecialValue | string {
    const plugin = pluginMetadata.find(p => p.id === pluginID);
    if (plugin === undefined) {
        return `Plugin ${pluginID} for special value ${computationID} not found`;
    }

    const computation = plugin.computations.find(c => c.id === computationID);
    if (computation === undefined) {
        return `Special value ${computationID} not found in plugin ${pluginID}`;
    }
    if (computation.showAsSpecialValue !== true) {
        return `Computation ${computationID} in plugin ${pluginID} is not a special value`;
    }

    const result = computation.results[resultName];
    if (result === undefined) {
        return `Special value ${computationID} in plugin ${pluginID} does not have a result ${resultName}`;
    }

    const typeKind = getTypeKindForPrimitivePluginType(result.type);
    if (typeKind === undefined) {
        return `Result ${resultName} for special value ${computationID} in plugin ${pluginID} has unsupported type ${result.type}`;
    }

    return { type: makePrimitiveType(typeKind) };
}

export function getTypeForSpecialValue(sv: SpecialValueDescription): PrimitiveGlideType {
    if (typeof sv === "string") {
        return makePrimitiveType(specialValueTypeKinds[sv]);
    } else {
        return sv.type;
    }
}
