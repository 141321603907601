import { glideDateTimeDocumentDataCodec, glideJSONDocumentDataCodec } from "@glide/data-types";
import * as t from "io-ts";

// The following types used to live in "@glide/common-core", but we ended up needing them for plugin data sources.
// A lot of the comments are carried over with respect to "queryable data sources". A plugin data source may be
// queryable to some degree, so the comments are still fairly accurate.

export type BinaryPredicateCompositeOperator =
    | "does-not-equal"
    | "does-not-contain-string"
    | "is-not-contained-in-string"
    | "is-before"
    | "is-after"
    | "is-on-or-before"
    | "is-on-or-after"
    | "is-within"
    | "array-does-not-include";

export const BinaryPredicateCompositeOperator = {
    DoesNotEqual: "does-not-equal" as const,
    DoesNotContainString: "does-not-contain-string" as const,
    IsNotContainedInString: "is-not-contained-in-string" as const,
    IsBefore: "is-before" as const,
    IsAfter: "is-after" as const,
    IsOnOrBefore: "is-on-or-before" as const,
    IsOnOrAfter: "is-on-or-after" as const,
    IsWithin: "is-within" as const,
    ArrayDoesNotInclude: "array-does-not-include" as const,
};

export type BinaryPredicateFormulaOperator =
    | "equals"
    | "contains-string"
    | "is-contained-in-string"
    | "is-less-than"
    | "is-greater-than"
    | "is-less-or-equal-to"
    | "is-greater-or-equal-to"
    | "matches-email-address"
    | "array-includes";

export const BinaryPredicateFormulaOperator = {
    Equals: "equals" as const,
    ContainsString: "contains-string" as const,
    IsContainedInString: "is-contained-in-string" as const,
    IsLessThan: "is-less-than" as const,
    IsGreaterThan: "is-greater-than" as const,
    IsLessOrEqualTo: "is-less-or-equal-to" as const,
    IsGreaterOrEqualTo: "is-greater-or-equal-to" as const,
    MatchesEmailAddress: "matches-email-address" as const,
    ArrayIncludes: "array-includes" as const,
};

export type UnaryPredicateFormulaOperator = "is-not-empty" | "is-truthy";

export const UnaryPredicateFormulaOperator = {
    IsNotEmpty: "is-not-empty" as const,
    IsTruthy: "is-truthy" as const,
};

const dataValueCodec = t.union([
    t.string,
    t.number,
    t.boolean,
    glideDateTimeDocumentDataCodec,
    glideJSONDocumentDataCodec,
]);
export type FilterDataValue = t.TypeOf<typeof dataValueCodec>;

const columnCodec = t.type({ columnName: t.string });
export type FilterColumn = t.TypeOf<typeof columnCodec>;

export function isFilterColumn(v: unknown): v is FilterColumn {
    return columnCodec.is(v);
}

const columnOrDataValueCodec = t.union([columnCodec, dataValueCodec]);
export type FilterColumnOrDataValue = t.TypeOf<typeof columnOrDataValueCodec>;

// When in doubt, the semantics of these should be as close to
// ##computeCondition as possible, to have consistent behavior across the
// computation model and queryable data sources, to avoid confusing users and
// make it easier to migrate in the future.

// Definitions:
//
// * Primitive value: Strings, number, boolean, date-times, and the undefined
//     value.  See ##primitiveValues.
// * Empty value: Undefined values, the empty string, NaN, empty arrays, and
//     empty tables, count as empty.  See ##isNotEmpty.
// * Conversion to string: Primitive values are converted to strings according
//     to ##convertingRelationKeys.
// * Conversion to boolean: Must work like ##asMaybeBoolean.
// * Conversion to number: `false` is 0, `true` is 1.  Strings are trimmed and
//   then interpreted as JSON numbers.
// * Conversion to date-times: Queries do very basic conversion to date-times:
//     Only strings in the format returned by Javascript's
//     `Date.prototype.toISOString()` are converted to date-times.  Converting
//     time-zone-agnostic date-times to time-zone-aware ones works like
//     ##convertToTimeZoneAware, except that the time-zone offset is sent by
//     the device.
// * Normalized email addresses: Must work like ##normalizeEmailAddress.
// * Comparing primitive values: We only ever compare primitive values of the
//     same types, except that either side can be undefined.  Two undefineds
//     are equal, otherwise the undefined is greater than the defined value
//     (it comes last in sorts, for example).  `false` is less than `true`.
//     Strings are compared case-insensitively (note that we're not
//     implementing special-casing for numbers and booleans like
//     ##compareStrings does). Date-times are compared according to their UTC
//     timestamp, ignoring their time zones. For example 1pm Pacific is equal
//     to 4pm Eastern.

// This is what we use for relations and dynamic filters.  It checks whether
// any non-empty value (see definition above) in `column`, converted to a
// string (see definition above), is one of the strings in `array`.  Strings
// are compared case-sensitive.
const arrayOverlapFilterConditionCodec = t.intersection([
    t.type({
        kind: t.literal("array-overlap"),
        // This can be a primitive column or an array column.  If it's a
        // primitive, it acts like an array of one element.
        column: columnCodec,
        array: t.readonlyArray(dataValueCodec),
    }),
    t.partial({
        // If true, trim, lower-case and hash LHS as though it had been passed to ##makeRoleHash
        //  This is used to implement the row owner query condition.
        roleHashLHS: t.literal(true),
    }),
]);
export type ArrayOverlapFilterCondition = t.TypeOf<typeof arrayOverlapFilterConditionCodec>;

// This is `true` if the array in `lhs`
// * contains `rhs` if `rhs` is not an array
// * contains all elements of `rhs` if `rhs` is an array
//
// We don't yet support ##arrayIncludesInFilters.
//
// const arrayIncludesFilterConditionCodec = t.type({
//     kind: t.literal(BinaryPredicateFormulaOperator.ArrayIncludes),
//     lhs: columnCodec,
//     rhs: t.union([columnCodec, t.readonlyArray(dataValueCodec)]),
// });

const unaryFilterConditionCodec = t.type({
    kind: t.union([
        // Converts the column value to a boolean (see definition).
        t.literal(UnaryPredicateFormulaOperator.IsTruthy),
        // Checks whether the column value is not empty (see definition).
        t.literal("is-empty"),
    ]),
    column: columnCodec,
});
export type UnaryFilterCondition = t.TypeOf<typeof unaryFilterConditionCodec>;

// Converts the LHS value and the RHS value to the type of the LHS column, and
// then checks whether the converted values are strictly equal. We only use
// this on primitive values (see definition).
const equalsFilterConditionCodec = t.type({
    kind: t.literal(BinaryPredicateFormulaOperator.Equals),
    lhs: columnCodec,
    rhs: columnOrDataValueCodec,
});
export type EqualsFilterCondition = t.TypeOf<typeof equalsFilterConditionCodec>;

const binaryStringFilterConditionCodec = t.type({
    kind: t.union([
        // Converts the LHS and RHS values to strings (see definition) and
        // checks whether the converted LHS contains the converted RHS as a
        // substring, case insensitively.
        t.literal(BinaryPredicateFormulaOperator.ContainsString),
        // The same as `ContainsString`, but with LHS and RHS switched.
        t.literal(BinaryPredicateFormulaOperator.IsContainedInString),
        // Converts the LHS and RHS values to normalized email addresses (see
        // definition) and checks whether they're equal.
        t.literal(BinaryPredicateFormulaOperator.MatchesEmailAddress),
    ]),
    lhs: columnCodec,
    rhs: t.union([columnCodec, t.string]),
});
export type BinaryStringFilterCondition = t.TypeOf<typeof binaryStringFilterConditionCodec>;

// All of these convert the LHS and RHS values to the type of the LHS column,
// and then compare those primitive values (see definition).
const binaryComparisonFilterConditionCodec = t.type({
    kind: t.union([
        t.literal(BinaryPredicateFormulaOperator.IsLessThan),
        t.literal(BinaryPredicateFormulaOperator.IsGreaterThan),
        t.literal(BinaryPredicateFormulaOperator.IsLessOrEqualTo),
        t.literal(BinaryPredicateFormulaOperator.IsGreaterOrEqualTo),
    ]),
    lhs: columnCodec,
    rhs: columnOrDataValueCodec,
});
export type BinaryComparisonFilterCondition = t.TypeOf<typeof binaryComparisonFilterConditionCodec>;

const specialDateTimeValueCodec = t.union([t.literal("now"), t.literal("start-of-today"), t.literal("end-of-today")]);
export type SpecialDateTimeValue = t.TypeOf<typeof specialDateTimeValueCodec>;

// All of these convert both values to time-zone-aware date-times (see
// definition) and do comparisons on those converted values.
const binaryDateFilterConditionCodec = t.type({
    kind: t.union([
        // These two just compare the time-zone-aware timestamps.
        t.literal(BinaryPredicateCompositeOperator.IsBefore),
        t.literal(BinaryPredicateCompositeOperator.IsAfter),
        // These compare whether the LHS date-time is on and/or
        // before/after the day specified by the RHS, according to the
        // device's time zone.  Note that exact midnight belongs only to
        // the day that's beginning, not the previous day.  For example,
        // if the device is in Eastern time, then the date-time "2/2/2000
        // 2pm Eastern" specifies the day starting at "2/2/2000 12pm
        // Eastern" (inclusive), ending at "2/3/2000 12pm Eastern"
        // (exclusive).
        t.literal(BinaryPredicateCompositeOperator.IsOnOrBefore),
        t.literal(BinaryPredicateCompositeOperator.IsOnOrAfter),
        t.literal(BinaryPredicateCompositeOperator.IsWithin),
    ]),
    lhs: columnCodec,
    // If the RHS is the string "now" then use the current date/time on the
    // backend.  Note that the client's time zone offset still has to be
    // respected.  The reason we're doing this, instead of just using the
    // client's current date/time, is because if we did that, queries
    // involving "now" would constantly differ from each other and we'd
    // re-query them all the time. "start-of-today" and "end-of-today" are the
    // start and end of today, in the device's time zone, respectively.  Note
    // that both the start and the end of today are part of today.  The start
    // is midnight of today, and the end is the instant before midnight
    // tomorrow, i.e. end of today is right before start of tomorrow.
    rhs: t.union([columnCodec, glideDateTimeDocumentDataCodec, specialDateTimeValueCodec]),
});
export type BinaryDateFilterCondition = t.TypeOf<typeof binaryDateFilterConditionCodec>;

const filterConditionBase = t.union([
    arrayOverlapFilterConditionCodec,
    // arrayIncludesFilterConditionCodec,
    unaryFilterConditionCodec,
    equalsFilterConditionCodec,
    binaryStringFilterConditionCodec,
    binaryComparisonFilterConditionCodec,
    binaryDateFilterConditionCodec,
]);
export type FilterConditionBase = t.TypeOf<typeof filterConditionBase>;

export const filterConditionCodec = t.intersection([
    filterConditionBase,
    t.type({
        // If this is `true`, the condition's result is to be negated.
        negated: t.boolean,
    }),
]);
export type FilterCondition = t.TypeOf<typeof filterConditionCodec>;
