import type { UploadFileResponse } from "@glide/common-core/dist/js/components/types";
import { uploadAppFile } from "@glide/component-utils";
import { sleep } from "@glideapps/ts-necessities";
import * as React from "react";

export type FileUploader = typeof uploadAppFile;

const FileUploaderContext = React.createContext<FileUploader>(uploadAppFile);

interface FileUploaderProviderProps {
    fileUploader: FileUploader;
}

export const FileUploaderProvider: React.FC<React.PropsWithChildren<FileUploaderProviderProps>> = p => {
    const { fileUploader, children } = p;

    return <FileUploaderContext.Provider value={fileUploader}>{children}</FileUploaderContext.Provider>;
};

export function useFileUploader(): FileUploader {
    return React.useContext(FileUploaderContext);
}

export function makeMockFileUploader(
    emulateNetwork: boolean,
    includeFilename: boolean,
    onFileUploaded?: (fileName: string) => void
): FileUploader {
    const mockFileUploader: FileUploader = (_appID, _appKind, _appFacilities, file, _component, onProgress) => {
        let cancelled = false;

        const cancel = () => {
            cancelled = true;
        };

        const attempt = async (): Promise<UploadFileResponse> => {
            const cancelledResult = {
                error: new Error("Cancelled upload"),
                quotaExceeded: false,
            };

            if (emulateNetwork) {
                const resolveTimeMs = 1000 + Math.random() * 2000;
                const UPLOAD_STEPS = 10;
                for (let uploadStep = 0; uploadStep < UPLOAD_STEPS; uploadStep++) {
                    await sleep(resolveTimeMs / UPLOAD_STEPS);

                    if (cancelled) {
                        return cancelledResult;
                    }

                    onProgress?.(UPLOAD_STEPS - 1, uploadStep);
                }
            }

            if (cancelled) {
                return cancelledResult;
            }

            onFileUploaded?.(file.name);
            const path = includeFilename ? file.name : URL.createObjectURL(file);
            return { path };
        };

        return {
            cancel,
            attempt,
        };
    };

    return mockFileUploader;
}
