import Tooltip from "rc-tooltip";
import type { TooltipProps } from "rc-tooltip/lib/Tooltip";

interface Props extends React.PropsWithChildren {
    text: string;
    placement?: string;
    hide?: boolean;
    disabled?: boolean;
    children: React.ReactElement;
    instant?: boolean;
    forceOneLine?: boolean;
}

export const SimpleTooltip: React.VFC<Props> = p => {
    const tooltipProps: TooltipProps = {
        placement: p.placement ? p.placement : "top",
        overlay: <span style={{ whiteSpace: p.forceOneLine === true ? "nowrap" : undefined }}>{p.text}</span>,
        destroyTooltipOnHide: true,
        mouseEnterDelay: p.instant ? 0.1 : 0.5,
        mouseLeaveDelay: 0,
        transitionName: "rc-tooltip-fade",
        getTooltipContainer: () => document.getElementById("portal") ?? document.body,
    };
    if (p.hide) {
        tooltipProps.visible = false;
    }

    return <Tooltip {...tooltipProps}>{p.disabled === true ? <span>{p.children}</span> : p.children}</Tooltip>;
};
