import { AppIcon, copyToClipboard as copy } from "@glide/common";
import * as React from "react";

import { HiddenCopyButtonButton, HiddenCopyButtonIcon, HiddenCopyButtonWrapper } from "./hidden-copy-button-style";
import { withLabelAndByline } from "../../lib/with-label";

interface Props extends React.PropsWithChildren {
    label: string;
    value: string | undefined;
}
interface State {
    valueCopied: boolean;
}

class HiddenCopyButtonImpl extends React.PureComponent<Props, State> {
    public state: State = {
        valueCopied: false,
    };

    private onValueCopied = (value: string) => {
        copy(value);
        this.setState({ valueCopied: true });
        setTimeout(() => {
            this.setState({ valueCopied: false });
        }, 3000);
    };

    public render(): React.ReactNode {
        const { value } = this.props;
        const { valueCopied } = this.state;

        if (value === undefined) return null;

        return (
            <HiddenCopyButtonWrapper>
                <HiddenCopyButtonButton onClick={() => this.onValueCopied(value)}>
                    <HiddenCopyButtonIcon>
                        <AppIcon icon="01-29-copy-paste" size={12} />
                    </HiddenCopyButtonIcon>
                    {valueCopied ? "Copied!" : "Copy to Clipboard"}
                </HiddenCopyButtonButton>
            </HiddenCopyButtonWrapper>
        );
    }
}

export const HiddenCopyButton = withLabelAndByline(HiddenCopyButtonImpl, "split");
