import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import {
    type TableName,
    areTableNamesEqual,
    rowIndexColumnName,
    type Description,
    getAllowedTablesForAddRow,
    getTableName,
    getTableRefTableName,
    isSingleRelationType,
    makeSourceColumn,
} from "@glide/type-schema";
import {
    type ActionDescription,
    type PropertyDescription,
    ActionKind,
    MutatingScreenKind,
    isFormScreen,
    makeStringProperty,
} from "@glide/app-description";
import { type InputOutputTables, withOutputTable } from "@glide/common-core/dist/js/description";
import type {
    AppDescriptionContext,
    InteractiveComponentConfiguratorContext,
    PropertyDescriptor,
    RewritingComponentConfiguratorContext,
    ActionAvailability,
} from "@glide/function-utils";
import { defined } from "@glideapps/ts-necessities";
import {
    type WireActionResult,
    type WireActionResultBuilder,
    PageScreenTarget,
    type WireActionHydrator,
    type WireActionInflationBackend,
} from "@glide/wire";
import { makeScreenTitlePropertyDescriptor } from "../components/descriptor-utils";
import { copyClassScreen } from "../copy-screen";
import type { StaticActionContext } from "../static-context";
import { makeFormScreenDescription } from "../make-edit-screen";
import { getCanEditFromNetworkStatus, inflateStringProperty } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import {
    type NavigationTargetDescription,
    makeNavigationPropertyDescriptors,
    navigationTargetPropertyHandlers,
} from "./link-to-screen";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

export interface FormScreenActionDescription extends ActionDescription, NavigationTargetDescription {
    readonly kind: ActionKind.FormScreen;
    readonly title: PropertyDescription | undefined;
    readonly formScreenName: string;
}

export class FormScreenActionHandler extends BaseActionHandler<FormScreenActionDescription> {
    public readonly kind = ActionKind.FormScreen;

    public readonly iconName: GlideIconProps = {
        icon: "st-form-screen",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Show form screen";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: FormScreenActionDescription | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const properties: PropertyDescriptor[] = [];
        if (ccc.appKind === AppKind.App) {
            properties.push(makeScreenTitlePropertyDescriptor(mutatingScreenKind, "Form title"));
        }
        // This only applies to Pages...
        properties.push(...makeNavigationPropertyDescriptors(ccc, PageScreenTarget.SlideIn));

        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 3,
            needsScreenContext: true,
            properties,
        };
    }

    public static newActionForTables(
        tables: InputOutputTables,
        iccc: InteractiveComponentConfiguratorContext
    ): FormScreenActionDescription | undefined {
        const { appKind } = iccc;
        const title = makeStringProperty(
            appKind === AppKind.Page ? getLocalizedString("addItem", appKind) : getLocalizedString("form", appKind)
        );

        return {
            kind: ActionKind.FormScreen,
            title,
            formScreenName: iccc.addFormScreen(new Set(), () =>
                makeFormScreenDescription(
                    tables,
                    appKind,
                    // In our consistency checking code we check that for apps,
                    // ##formScreensHaveNoTitle
                    appKind === AppKind.App ? undefined : title,
                    iccc
                )
            ),
            navigationTarget: undefined,
        };
    }

    public newActionDescription(
        env: StaticActionContext<InteractiveComponentConfiguratorContext>
    ): FormScreenActionDescription | undefined {
        const { context, tables } = env;
        const targetTables = getAllowedTablesForAddRow(context.schema);
        if (targetTables.length === 0) return undefined;
        if (tables === undefined) return undefined;

        const formTable = defined(targetTables.find(t => t !== tables.input) ?? targetTables[0]);
        return FormScreenActionHandler.newActionForTables(withOutputTable(tables, formTable), context);
    }

    public getScreensUsed(desc: FormScreenActionDescription): readonly string[] {
        return [desc.formScreenName];
    }

    public getAdditionalTablesUsed(tables: InputOutputTables): readonly TableName[] {
        // We need this because this action needs an input row to work, but
        // there's no other way to communicate that we need it.
        // https://github.com/quicktype/glide/issues/13108
        const fromSuper = super.getAdditionalTablesUsed(tables);
        return [...fromSuper, getTableName(tables.input)];
    }

    public rewriteAfterReload(
        desc: FormScreenActionDescription,
        tables: InputOutputTables,
        ccc: RewritingComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): FormScreenActionDescription | undefined {
        if (!ccc.requireScreen(desc.formScreenName)) return undefined;

        return super.rewriteAfterReload(desc, tables, ccc, mutatingScreenKind);
    }

    public async duplicateAction(
        desc: FormScreenActionDescription,
        _rootDesc: Description,
        copyTables: InputOutputTables | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<FormScreenActionDescription | undefined> {
        if (copyTables === undefined) return undefined;

        const screenDesc = iccc.appDescription.screenDescriptions[desc.formScreenName];
        if (screenDesc === undefined || !isFormScreen(screenDesc)) return undefined;

        if (
            !isSingleRelationType(screenDesc.type) ||
            !areTableNamesEqual(getTableName(copyTables.input), getTableRefTableName(screenDesc.type))
        ) {
            return undefined;
        }

        const screenCopy = await copyClassScreen(screenDesc, iccc, mutatingScreenKind, screensCreated, appFacilities);
        if (screenCopy === undefined) return undefined;

        const newScreenName = iccc.addFormScreen(screensCreated, () => screenCopy);

        return { ...desc, formScreenName: newScreenName };
    }

    public getTokenizedDescription(
        desc: FormScreenActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        // In Pages, the form action doesn't provide the title.
        if (env.context.appKind !== AppKind.App) return undefined;

        const titleToken = tokenForProperty(desc.title, env);
        if (titleToken === undefined) return undefined;

        return [{ kind: "string", value: "title " }, titleToken];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: FormScreenActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { appKind, eminenceFlags },
        } = ib;

        const defaultTarget = appKind === AppKind.App ? PageScreenTarget.SmallModal : PageScreenTarget.SlideIn;

        const target = navigationTargetPropertyHandlers.get(defaultTarget).getEnum(desc);

        const [rowIndexGetter] = ib.getValueGetterForSourceColumn(makeSourceColumn(rowIndexColumnName), false, false);

        const [titleGetter] = inflateStringProperty(ib, desc.title, true);

        arb = arb.addData({
            formScreenName: desc.formScreenName,
            target,
        });

        return vp => {
            if (!getCanEditFromNetworkStatus(vp, eminenceFlags, MutatingScreenKind.FormScreen)) return arb.offline();

            // We do this to make super duper sure we get rehydrated when we
            // first get an input row, or when the input row changes.  FIXME:
            // Make sure that everything always rehydrates in that case - it
            // maybe already does.
            rowIndexGetter(vp);

            const inputRow = vp.rowContext?.inputRows[0];

            // In Pages, the form screen itself sets the title.
            const title = appKind === AppKind.App ? titleGetter(vp) ?? "" : undefined;

            return async ab => {
                ab.pushFormScreen(desc.formScreenName, inputRow, title, target);
                return arb.addData({ title }).success();
            };
        };
    }
}
