import { trackEvent } from "@glide/common-core/dist/js/analytics";
import type { SerializedApp } from "@glide/app-description";
import type {
    ValidatePluginRequestBody,
    ValidatePluginResult,
} from "@glide/common-core/dist/js/firebase-function-types";
import { performOAuthStageOne } from "@glide/common-core/dist/js/plugin-oauth-stage-one";
import type { PluginTier, SerializablePluginMetadata } from "@glide/plugins";
import { serializablePluginsMetadataCodec } from "@glide/plugins-codecs";
import { isRight } from "fp-ts/lib/Either";
import React from "react";
import { css } from "styled-components";
import tw from "twin.macro";
import { Button } from "../button/button";
import { SimpleModalProviderLaag } from "../modal-provider-v2/modal-provider-laag";
import { getRequiredPlan } from "@glide/component-utils";
import { PluginDescription } from "./plugin-description";
import { type PluginParametersPropsBase, PluginParametersConfig } from "./plugin-parameters-config";
import { isBuilderTemplate } from "@glide/common-core/dist/js/database-strings";
import type { TableGlideType } from "@glide/type-schema";
import { makeNewPluginConfig } from "../../lib/new-plugin-config";

const AddButton: React.VFC<{
    from: "column" | "action-selection";
    name: string;
    disabled?: boolean;
    onClick?: () => void;
}> = p => {
    const { from, name, disabled, onClick } = p;
    return (
        <div tw="mt-5">
            <Button
                key="add"
                buttonType="primary"
                variant="accent"
                size="md"
                label={`Add ${name}`}
                isFullWidth={true}
                disabled={disabled}
                onClick={() => {
                    if (!(disabled === true) && onClick !== undefined) {
                        onClick();
                    }
                }}
            />
            {from === "column" ? <div tw="border-b border-n200A mt-3" /> : <div tw="mb-3" />}
        </div>
    );
};

export interface Props extends PluginParametersPropsBase {
    readonly ownerID: string;
    readonly validatePluginConfig: (
        appID: string,
        pluginID: string,
        configID: string,
        pluginParms: ValidatePluginRequestBody["pluginParams"]
    ) => Promise<ValidatePluginResult>;
    readonly appTier: PluginTier;
    readonly afterToggleApp?: () => void;
    readonly onDone?: () => void;
    readonly onShowSettings: () => void;
    readonly from: "column" | "action-selection";
    readonly userProfileTable: TableGlideType | undefined;
}

export const isPluginArrType = (value: unknown): value is SerializablePluginMetadata[] => {
    const parsed = serializablePluginsMetadataCodec.decode(value);
    return isRight(parsed);
};

export const AddPluginFromUsage: React.VFC<Props & { plugin: SerializablePluginMetadata }> = props => {
    const {
        appID,
        pluginConfigs = [],
        ownerID,
        plugin,
        updatePluginConfig,
        appTier,
        afterToggleApp,
        onShowSettings,
        from,
    } = props;

    const { name, auth, disclosure } = plugin;
    const requiredPlan = getRequiredPlan(plugin.tier, appTier);
    const [isOpen, setIsOpen] = React.useState(false);

    const connect = React.useCallback(
        async (configIDInner: string) => {
            if (auth === undefined) return;
            trackEvent("plugin connect started", {
                plugin_id: plugin.id,
                app_id: appID,
                auth_provider_id: auth.provider,
            });
            await performOAuthStageOne({
                authProvider: auth.provider,
                ownerID,
                appID,
                pluginID: plugin.id,
                configID: configIDInner,
                explicitScopes: plugin.auth?.scopes,
            });
        },
        [appID, auth, ownerID, plugin.auth?.scopes, plugin.id]
    );

    const toggle = React.useCallback(async () => {
        const newConfig = makeNewPluginConfig(plugin.id, pluginConfigs);
        if (auth !== undefined) {
            await connect(newConfig.configID);
        }
        updatePluginConfig(appID, [...pluginConfigs, newConfig]);
        trackEvent("plugin turned on", { plugin_id: plugin.id, app_id: appID });
        setIsOpen(true);

        if (afterToggleApp !== undefined) {
            afterToggleApp();
        }
    }, [afterToggleApp, appID, auth, connect, plugin, pluginConfigs, updatePluginConfig]);

    return (
        <>
            {!isOpen && (
                <div
                    css={css`
                        ${from === "action-selection" ? tw`mt-5` : ""}
                    `}>
                    <PluginDescription plugin={plugin} onExpandFeatures={onShowSettings} />
                </div>
            )}

            {Object.keys(plugin.parameters ?? {}).length === 0 ? (
                !isOpen ? (
                    <>
                        <AddButton
                            name={name}
                            onClick={toggle}
                            from={from}
                            disabled={requiredPlan !== undefined && !isBuilderTemplate(appID)}
                        />
                        {disclosure && (
                            <div
                                tw="text-builder-sm text-text-pale mt-4"
                                css={`
                                    a {
                                        text-decoration-line: underline !important;
                                    }
                                `}
                                dangerouslySetInnerHTML={{ __html: disclosure }}
                            />
                        )}
                    </>
                ) : null
            ) : (
                <SimpleModalProviderLaag
                    onTrigger={toggle}
                    triggerElement={
                        !isOpen ? (
                            <AddButton
                                name={name}
                                from={from}
                                disabled={requiredPlan !== undefined && !isBuilderTemplate(appID)}
                            />
                        ) : null
                    }
                    modalHandler={(onClose: () => void) => (
                        <div tw="my-6">
                            <PluginConfigFromUsage {...props} title={`Configure ${plugin.name}`} onDone={onClose} />
                        </div>
                    )}
                />
            )}
        </>
    );
};

interface PluginConfigFromUsageProps {
    readonly appID: string;
    readonly ownerID: string;
    readonly pluginConfigs: SerializedApp["pluginConfigs"];
    readonly validatePluginConfig: (
        appID: string,
        pluginID: string,
        configID: string,
        pluginParms: ValidatePluginRequestBody["pluginParams"]
    ) => Promise<ValidatePluginResult>;
    readonly updatePluginConfig: (appID: string, pluginConfig: SerializedApp["pluginConfigs"]) => void;
    readonly onDone?: () => void;
    readonly title?: string;
    readonly userProfileTable: TableGlideType | undefined;
}

export const PluginConfigFromUsage: React.VFC<
    PluginConfigFromUsageProps & { plugin: SerializablePluginMetadata }
> = props => {
    const {
        appID,
        pluginConfigs = [],
        plugin,
        updatePluginConfig,
        validatePluginConfig,
        onDone,
        userProfileTable,
        title,
    } = props;

    const { parameters = {} } = plugin;

    const config = pluginConfigs.find(c => c.pluginID === plugin.id);
    const configID = config?.configID;
    const isEnabled = config !== undefined;

    const [errorState, setErrorState] = React.useState<ValidatePluginResult | undefined>(undefined);
    React.useEffect(() => {
        if (configID === undefined || config === undefined) return;
        let canceled = false;
        const fn = async () => {
            const validationResult = await validatePluginConfig(appID, plugin.id, configID, config.parameters);
            if (canceled) return;
            setErrorState(validationResult);
        };
        void fn();
        return () => {
            canceled = true;
        };
    }, [appID, config, configID, plugin.id, validatePluginConfig]);

    return (
        <>
            {title && <div tw="text-text-base text-builder-xl font-extrabold mb-6">{title}</div>}
            {isEnabled && Object.entries(parameters).length > 0 && (
                <PluginParametersConfig
                    appID={appID}
                    pluginConfigs={pluginConfigs}
                    updatePluginConfig={updatePluginConfig}
                    config={config}
                    parameters={parameters}
                    errorState={errorState}
                    userProfileTable={userProfileTable}
                />
            )}
            {isEnabled && (
                <>
                    <div tw="flex space-x-2 mt-5 justify-end">
                        {onDone !== undefined && (
                            <>
                                <Button
                                    key="cancel"
                                    buttonType="secondary"
                                    variant="default"
                                    size="md"
                                    label="Cancel"
                                    onClick={onDone}
                                />
                                <Button
                                    key="done"
                                    buttonType="primary"
                                    variant="accent"
                                    size="md"
                                    label="Done"
                                    disabled={errorState?.ok !== true}
                                    onClick={onDone}
                                />
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
