/**
 * Sometimes we lazy load a component that provides something to other lazy loaded components.
 * If the children arive first, we might be in an inconsistent state, or even crash.
 * This provides an alternative to `React.lazy` that lets you sequence loadings.
 *
 * Originally made to solve this race condition that resulted in crashing: https://github.com/glideapps/glide/issues/27803
 */

import { ignore } from "@glide/support";
import * as React from "react";

type LazyComponentResult = ReturnType<typeof React.lazy>;
type LazyComponentLoader = Parameters<typeof React.lazy>[0];

class VoidPromiseWithResolver {
    public promise: Promise<void>;
    private resolver: () => void;

    constructor() {
        this.resolver = ignore;

        this.promise = new Promise<void>(resolve => {
            this.resolver = resolve;
        });
    }

    public resolve(): void {
        this.resolver();
    }
}

export const lazyLoadWithSequence = (
    loader: LazyComponentLoader,
    sequencer?: VoidPromiseWithResolver
): [LazyComponentResult, VoidPromiseWithResolver] => {
    const newSequencer = new VoidPromiseWithResolver();

    const Component = React.lazy(async () => {
        const [loaderResult] = await Promise.all([loader(), sequencer?.promise]);
        newSequencer.resolve();
        return loaderResult;
    });

    return [Component, newSequencer];
};
