export enum DropdownAnchor {
    TopLeft,
    TopRight,
    BottomLeft,
    BottomRight,
    SmartRight,
}

export interface DropdownItem {
    content: React.ReactNode;
    isEnabled?: boolean;
    onClick?: () => void;
}
