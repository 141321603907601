import { asMaybeString, asString, type LoadingValue, type Table, isLoadingValue } from "@glide/computation-model-types";
import { getLocalizedString } from "@glide/localization";
import { type BasicUserProfile, MenuItemPurpose } from "@glide/common-core/dist/js/components/types";
import { nullLoadingToUndefined } from "@glide/common-core/dist/js/computation-model/data";
import type { Database } from "@glide/common-core/dist/js/Database/core";
import { type DocumentData, documentIDColumnName } from "@glide/common-core/dist/js/Database";
import { commentsTableName, makeCommentsPath } from "@glide/common-core/dist/js/database-strings";
import {
    type TableGlideType,
    SourceColumnKind,
    SpecialValueKind,
    makeSourceColumn,
    type UserProfileTableInfo,
} from "@glide/type-schema";
import { makeInputOutputTables } from "@glide/common-core/dist/js/description";
import type { CommentData } from "@glide/component-utils";
import type { WireAppCommentsComponent } from "@glide/fluent-components/dist/js/base-components";
import type { AppKind } from "@glide/location-common";
import { checkString, isArray, normalizeEmailAddress } from "@glide/support";
import {
    type InflatedColumn,
    type WireInflationBackend,
    type WireRowActionHydrationValueProvider,
    type WireValueGetter,
    type WireComponentKind,
    type WireScreen,
    WireActionResult,
    ValueChangeSource,
    makeContextTableTypes,
    type WireComponentHydrationResult,
    type WireRowComponentHydrationBackend,
} from "@glide/wire";
import { defined, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import isString from "lodash/isString";
import {
    hydrateSubsidiaryScreenFlag,
    registerActionRunner,
    registerActionWithForcedSignIn,
    spreadComponentID,
} from "../wire/utils";
import { makeOverlayEditorSubsidiaryScreen } from "./overlay-editor";

export interface CommentUserGetters {
    readonly userProfileTableInfo: UserProfileTableInfo | undefined;

    readonly userProfileTableGetter:
        | ((hb: WireRowActionHydrationValueProvider) => Table | LoadingValue | undefined)
        | undefined;

    readonly userEmailGetter: InflatedColumn | undefined;
    readonly userNameGetter: InflatedColumn | undefined;
    readonly userImageURLGetter: InflatedColumn | undefined;

    readonly thisUserEmailGetter: WireValueGetter;
    readonly thisUserNameGetter: WireValueGetter | undefined;
}

export function inflateCommentUserGetters(ib: WireInflationBackend): CommentUserGetters {
    const {
        adc: { userProfileTableInfo },
    } = ib;

    let userProfileTable: TableGlideType | undefined;
    let userProfileTableGetter:
        | ((hb: WireRowActionHydrationValueProvider) => Table | LoadingValue | undefined)
        | undefined;
    if (userProfileTableInfo !== undefined) {
        const maybeGetter = ib.getTableGetter(userProfileTableInfo.tableName, false);
        if (maybeGetter !== undefined) {
            userProfileTableGetter = maybeGetter[0];
            userProfileTable = maybeGetter[1];
        }
    }

    const [thisUserEmailGetter] = ib.getValueGetterForSpecialValue(SpecialValueKind.VerifiedEmailAddress);

    let userEmailGetter: InflatedColumn | undefined;
    let userNameGetter: InflatedColumn | undefined;
    let userImageURLGetter: InflatedColumn | undefined;
    let thisUserNameGetter: WireValueGetter | undefined;
    if (userProfileTableInfo !== undefined && userProfileTable !== undefined) {
        const userProfileIB = ib.makeInflationBackendForTables(
            makeContextTableTypes(makeInputOutputTables(userProfileTable)),
            ib.mutatingScreenKind
        );
        const emailGetter = userProfileIB.getValueGetterForColumnInRow(
            userProfileTableInfo.emailColumnName,
            false,
            false
        );
        if (emailGetter !== undefined) {
            userNameGetter = userProfileIB.getValueGetterForColumnInRow(
                userProfileTableInfo.nameColumnName,
                false,
                true
            );
            userImageURLGetter = userProfileIB.getValueGetterForColumnInRow(
                userProfileTableInfo.imageColumnName,
                false,
                true
            );

            if (userNameGetter !== undefined || userImageURLGetter !== undefined) {
                userEmailGetter = emailGetter;
            }
        }

        thisUserNameGetter = ib.getValueGetterForSourceColumn(
            makeSourceColumn(userProfileTableInfo.nameColumnName, SourceColumnKind.UserProfile),
            false,
            true
        )[0];
    }

    return {
        userProfileTableInfo,
        userProfileTableGetter,
        userEmailGetter,
        userNameGetter,
        userImageURLGetter,
        thisUserEmailGetter,
        thisUserNameGetter,
    };
}

export function hydrateComments(
    hb: WireRowComponentHydrationBackend,
    db: Database,
    appID: string,
    appKind: AppKind,
    componentID: string,
    forBuilder: boolean,
    topic: string,
    sortOrder: "asc" | "desc",
    kind: WireComponentKind.AppComments | WireComponentKind.AppChat,
    getters: CommentUserGetters
): WireComponentHydrationResult {
    const {
        userProfileTableInfo,
        userProfileTableGetter,
        userEmailGetter,
        userNameGetter,
        userImageURLGetter,
        thisUserEmailGetter,
        thisUserNameGetter,
    } = getters;
    const commentsPath = makeCommentsPath(appID);

    const commentsEditable = hb.getState("comments", (x): x is readonly CommentData[] => isArray(x), [], false);
    hb.useEffect(
        ab => {
            // console.log("listen", topic);
            const unlisten = db.listenWhere(
                commentsPath,
                [{ fieldPath: "topic", opString: "==", value: topic }],
                "dateTime",
                sortOrder,
                undefined,
                results => {
                    const comments = mapFilterUndefined(results, ({ data, id }) => {
                        try {
                            const dateTime = db.convertDocumentProperty(data.dateTime);
                            if (!(dateTime instanceof Date)) return undefined;
                            const comment: CommentData = {
                                username: checkString(data.username),
                                email: definedMap(data.email, checkString),
                                dateTime,
                                comment: checkString(data.comment),
                                documentID: id,
                            };
                            return comment;
                        } catch {
                            return undefined;
                        }
                    });
                    ab.valueChanged(commentsEditable.onChangeToken, comments, ValueChangeSource.Internal);
                }
            );
            return () => {
                // console.log("unlisten", topic);
                unlisten();
            };
        },
        [topic]
    );

    const newCommentValue = hb.getState("newComment", isString, "", false);

    const [subsidiaryOpen, toggleSubsidiaryRunner] = hydrateSubsidiaryScreenFlag(
        hb,
        "newCommentSubsidiaryOpen",
        (ab, willOpen) => {
            if (willOpen) {
                ab.valueChanged(newCommentValue.onChangeToken, "", ValueChangeSource.Internal);
            }
        }
    );

    const { action: onNewComment, subsidiaryScreen: signInSubsidiaryScreen } = registerActionWithForcedSignIn(
        hb,
        "onNewComment",
        true,
        toggleSubsidiaryRunner
    );

    const comments = commentsEditable.value;

    // email -> user
    const users = new Map<string, BasicUserProfile>();
    if (comments.length > 0 && userEmailGetter !== undefined) {
        const emails = new Set(mapFilterUndefined(comments, cd => definedMap(cd.email, normalizeEmailAddress)));
        if (emails.size > 0) {
            const table = userProfileTableGetter?.(hb);
            if (!isLoadingValue(table) && table !== undefined) {
                const ttvp = hb.makeTableTransformValueProvider(defined(userProfileTableInfo?.tableName));
                userEmailGetter.subscribe(ttvp);
                userNameGetter?.subscribe(ttvp);
                userImageURLGetter?.subscribe(ttvp);

                for (const r of table.values()) {
                    const email = definedMap(nullLoadingToUndefined(userEmailGetter.getter(r, ttvp)), v =>
                        normalizeEmailAddress(asString(v))
                    );
                    if (email === undefined || !emails.has(email)) continue;
                    const name = asMaybeString(nullLoadingToUndefined(userNameGetter?.getter(r, ttvp)));
                    const imageURL = asMaybeString(nullLoadingToUndefined(userImageURLGetter?.getter(r, ttvp)));
                    if (name === undefined && imageURL === undefined) continue;

                    users.set(email, { email, name, image: imageURL });
                }
            }
        }
    }

    let subsidiaryScreen: WireScreen | undefined;
    if (signInSubsidiaryScreen === undefined && subsidiaryOpen.value) {
        const email = definedMap(nullLoadingToUndefined(thisUserEmailGetter(hb)), asString);
        let doneToken: string | undefined;
        if (newCommentValue.value !== "") {
            // FIXME: Fallback user name
            const username = asString(nullLoadingToUndefined(thisUserNameGetter?.(hb)));

            doneToken = hb.registerAction("postComment", async ab => {
                const document: DocumentData = {
                    topic,
                    username,
                    comment: newCommentValue.value,
                    dateTime: { $dateTime: new Date(), $isTimeZoneAgnostic: false },
                };
                if (email !== undefined) {
                    document.email = normalizeEmailAddress(email);
                }
                ab.valueChanged(subsidiaryOpen.onChangeToken, false, ValueChangeSource.User);
                await db.setDocument(makeCommentsPath(appID), undefined, document);
                return WireActionResult.nondescriptSuccess();
            });
        }

        const toggleSubsidiaryAction = registerActionRunner(hb, "toggleEditor", toggleSubsidiaryRunner);
        if (toggleSubsidiaryAction !== undefined) {
            subsidiaryScreen = makeOverlayEditorSubsidiaryScreen(
                appKind,
                getLocalizedString("comment", appKind),
                newCommentValue,
                getLocalizedString("writeAComment", appKind),
                getLocalizedString("post", appKind),
                "send",
                MenuItemPurpose.AddRow,
                doneToken,
                toggleSubsidiaryAction,
                `comment-${componentID}`
            );
        }
    }

    const component: WireAppCommentsComponent = {
        kind,
        ...spreadComponentID(componentID, forBuilder),
        topic,
        comments: comments.map(c => ({
            ...c,
            onDelete: {
                token: hb.registerAction(`delete-${c.documentID}`, async ab => {
                    const result = await ab.deleteRowAtIndex(
                        commentsTableName,
                        {
                            keyColumnName: documentIDColumnName,
                            keyColumnValue: c.documentID,
                        },
                        true
                    );
                    return WireActionResult.fromResult(result);
                }),
            },
        })),
        users: Array.from(users.values()),
        onNewComment,
    };
    return {
        component,
        isValid: true,
        subsidiaryScreen: signInSubsidiaryScreen ?? subsidiaryScreen,
    };
}
