// NOTE: This is part of the lower level of the New Computation Model, and
// should be kept isolated from non-trivial Glide dependencies.  In
// particular, it shouldn't have to know anything about Glide table/column
// types/schemas.

import { getFeatureSetting } from "@glide/common-core";
import {
    type QueryPathGetter,
    type GroundValue,
    type LoadingValue,
    type ResolvedGroundValue,
    isLoadingValue,
    type NamespaceGraph,
    type NamespaceGraphEdge,
    type NamespaceGraphNode,
    type Path,
    type RootPath,
    isKeyPath,
    isRootPath,
    isTopLevelPath,
    makeRootPath,
    type Dirt,
    type Handler,
    type IncomingSlot,
    type MutableNamespace,
    type OnChangeCallback,
    fullDirt,
    type PerformanceCounters,
    type Query,
    isQuery,
    ComputationTimeoutException,
} from "@glide/computation-model-types";
import { isRow, isTable } from "@glide/common-core/dist/js/computation-model/data";
import type { TableName } from "@glide/type-schema";
import { frontendSendEvent } from "@glide/common-core/dist/js/tracing";
import {
    getComputationModelWatchKey,
    getCurrentTimestampInMilliseconds,
    localStorageGetItem,
    localStorageSetItem,
    logError,
    logInfo,
    makeNameUnique,
} from "@glide/support";
import { DefaultMap, assert, assertNever, defined, definedMap } from "@glideapps/ts-necessities";
import md5 from "blueimp-md5";
import sortBy from "lodash/sortBy";
import { follow } from "./getters";
import { makeLoadingValueWrapper } from "./loading-value";
import type { ErrorResult } from "@glide/plugins";

function jsonifyDirt(d: Dirt): unknown {
    if (d.kind === "row") {
        return {
            kind: "row",
            rowID: d.rowID,
            columns: d.columns === true ? true : Array.from(d.columns),
        };
    } else if (d.kind === "table") {
        return {
            kind: "table",
            columns: d.columns === true ? true : Array.from(d.columns),
        };
    } else {
        return assertNever(d);
    }
}

interface TimingResult<TMetadata> {
    readonly metadata: TMetadata | undefined;
    readonly name: string;
    readonly totalTime: number;
}

// Resolving queries requires not just getting the results from the database,
// but then also fixing up results with local modifications, which the
// `FixupQueryHandler` does.  Queries are done dynamically in the computation
// model, which means that when constructing the computation model we don't
// yet know which entities will require queries, and which ones those will be,
// which means that we also can't put those into the graph.  Instead, we add
// those entities whenever they're needed and keep track of the dependencies
// in the graph with these `DynamicDependent` objects.
interface DynamicDependent {
    readonly key: string;
    readonly dirtPath: RootPath;
    readonly dirt: Dirt;
}

interface Entity<TMetadata> {
    // Identifies the entity - the first item in the root path
    readonly key: string;
    readonly handler: Handler;
    // Set of keys
    readonly dependents: Set<string>;
    dynamicDependents: DynamicDependent[];
    // These two cache `handler.getSlots()`
    readonly slots: readonly IncomingSlot[];
    readonly slotsByKey: ReadonlyMap<string, readonly IncomingSlot[]>;
    // FIXME: Maybe this should live in the handler, so we can
    // look at that locally to keep consistency?
    hasDirtyDependencies: boolean;
    readonly dirtyDynamicDependencies: Set<string>;
    callbacksNotified: boolean;

    // For debugging
    readonly name: string;
    totalTime: number;
    readonly metadata: TMetadata | undefined;
}

export interface QueryFetcher {
    fetchQuery(query: Query, onChange: () => void): ResolvedGroundValue | LoadingValue;
    getLocallyModifiedRowIDs(tableName: TableName): ReadonlySet<string>;
}

function makePushDirtAccountKey(from: Entity<unknown>, to: Entity<unknown>): string {
    return `${from.name} -> ${to.name} (${from.key} -> ${to.key})`;
}

// The namespace is only concerned with "indirectly" dirty entities, which is
// entities whose dependencies have received dirt but not yet passed on.
// For those, we need to first do a depth-first pass over their dependencies
// get all the dirt to percolate up.
export class NamespaceImpl<TMetadata> implements MutableNamespace<TMetadata> {
    private _watchKey: string | undefined;
    private _entitiesByKey = new Map<string, Entity<TMetadata>>();
    private _entitiesByHandler = new Map<Handler, Entity<TMetadata>>();
    private _numPushes = 0;
    private _numProcesses = 0;
    private readonly _pushDirtAccounts = new DefaultMap<string, number>(() => 0);
    private _watchedEntities: Set<Entity<TMetadata>> | undefined;

    // [keys, callback]
    private _subscribers: [ReadonlySet<string>, OnChangeCallback][] = [];

    private _isStrictMode = false;
    private _timeOutAt: number | undefined;

    private _isRetired = false;

    // used to ensure we only report metrics on top-level ns.get
    private _getRecursionLevel = 0;

    private _errorFromComputation: ErrorResult | undefined;

    // `_appID` is only used for debugging, it can safely be given as
    // `undefined`.
    constructor(
        private readonly _appID: string | undefined,
        private readonly _queryFetcher: QueryFetcher | undefined,
        private readonly _queryPathGetter: QueryPathGetter | undefined,
        private readonly _printDirt: boolean
    ) {
        this._watchKey = definedMap(_appID, id => localStorageGetItem(getComputationModelWatchKey(id)));
    }

    private getMaybeEntity(path: string | RootPath): Entity<TMetadata> | undefined {
        if (typeof path !== "string") {
            path = path.rest.key;
        }
        return this._entitiesByKey.get(path);
    }

    private getEntity(path: string | RootPath): Entity<TMetadata> {
        return defined(this.getMaybeEntity(path));
    }

    private assertConnections(): void {
        if (process.env.NODE_ENV === "production") return;

        for (const [key, entity] of this._entitiesByKey) {
            for (const slot of entity.slots) {
                const e = this.getEntity(slot.sourcePath);
                assert(e.dependents.has(key));
            }

            for (const k of entity.dependents) {
                const e = this.getEntity(k);
                assert(e.slotsByKey.has(key));
            }

            for (const d of entity.dynamicDependents) {
                this.getEntity(d.key);
            }
        }
    }

    public get isStrictMode(): boolean {
        return this._isStrictMode;
    }

    public get isRetired(): boolean {
        return this._isRetired;
    }

    public setStrictMode(isStrictMode: boolean) {
        this._isStrictMode = isStrictMode;
    }

    public get numEntities(): number {
        return this._entitiesByKey.size;
    }

    private rebuildWatchedEntities(): void {
        assert(this._watchKey !== undefined);

        const entities = new Set<Entity<TMetadata>>();

        const add = (k: string) => {
            const e = this.getEntity(k);
            if (entities.has(e)) return;
            entities.add(e);

            for (const s of e.slots) {
                add(s.sourcePath.rest.key);
            }
            for (const d of e.dirtyDynamicDependencies) {
                add(d);
            }
        };

        add(this._watchKey);

        logInfo(
            ">>> watching entities",
            Array.from(entities).map(e => e.key)
        );

        this._watchedEntities = entities;
    }

    // Call this on the JS console with an entity key and it will from then
    // on, even across sessions, add tons of debug logging for that entity as
    // well as for all of its direct and indirect dependencies, i.e. the whole
    // computation graph leading up to that entity.
    public watch(key: string): void {
        if (this._isRetired) return;

        this._watchKey = key;
        if (this._appID !== undefined) {
            localStorageSetItem(getComputationModelWatchKey(this._appID), key);
        }

        this.rebuildWatchedEntities();
    }

    public get(path: RootPath, assertNotDirty: boolean = false): GroundValue {
        if (this._isRetired) return undefined;

        const entity = this.getMaybeEntity(path);
        if (entity === undefined) {
            logError("No entity for path", path);
            return undefined;
        }

        if (this._watchedEntities?.has(entity) === true) {
            logInfo(
                ">>> getting",
                entity.key,
                entity.name,
                entity.handler.constructor.name,
                "dirty dependencies",
                entity.hasDirtyDependencies
            );
        }

        if (entity.hasDirtyDependencies) {
            assert(!assertNotDirty);
            // logInfo("getting slots", entity.key, slots.length);
            for (const slot of entity.slots) {
                this.get(slot.sourcePath, false);
            }
            const dirtyDynamicDependencies = Array.from(entity.dirtyDynamicDependencies);
            entity.dirtyDynamicDependencies.clear();
            for (const key of dirtyDynamicDependencies) {
                this.get(makeRootPath(key), false);

                const e = this.getEntity(key);
                // The dynamic dependency has just had its chance to push dirt
                // to us.  If we're dirty, we will recompute, and we will
                // re-add it if we still depend on it.
                e.dynamicDependents = e.dynamicDependents.filter(d => d.key !== entity.key);
            }

            // logInfo("undirtying dependencies", entity.key);
            if (!getFeatureSetting("queriesInComputationModel")) {
                entity.hasDirtyDependencies = false;
            }
            for (const slot of entity.slots) {
                assert(!this.getEntity(slot.sourcePath).hasDirtyDependencies);
            }
            for (const key of dirtyDynamicDependencies) {
                assert(this.getMaybeEntity(key)?.hasDirtyDependencies !== true);
            }
        } else {
            if (process.env.NODE_ENV !== "production") {
                for (const slot of entity.slots) {
                    const e = this.getEntity(slot.sourcePath);
                    assert(!e.hasDirtyDependencies);
                    assert(!e.handler.isDirty);
                }
            }
        }

        // logInfo("recomputing", entity.key);
        let start: number | undefined;
        if (this._timeOutAt !== undefined) {
            start = getCurrentTimestampInMilliseconds();
            if (start > this._timeOutAt) {
                throw new ComputationTimeoutException();
            }
        }

        this._getRecursionLevel++;
        try {
            // Don't call getCurrentTimestampInMilliseconds() unless we really want timings
            if (this._getRecursionLevel === 1 || entity.totalTime >= 0) {
                if (start === undefined) {
                    start = getCurrentTimestampInMilliseconds();
                }
            } else {
                // Otherwise, clear it out so we don't call it after recompute
                start = undefined;
            }

            const result = entity.handler.recompute(this);
            if (getFeatureSetting("queriesInComputationModel")) {
                // If we have queries in the computation model it's possible
                // that during the recomputation we evaluate a thunk which
                // then does a query which adds a dynamic dependency, which
                // can lead to dependencies being marked dirty, but since
                // we're recomputing we know that at the end of the day we
                // won't be dirty, so we clear the flag here, instead of
                // further above where we checked for `hasDirtyDependencies`.
                entity.hasDirtyDependencies = false;
                for (const slot of entity.slots) {
                    assert(!this.getEntity(slot.sourcePath).hasDirtyDependencies);
                }
            } else {
                assert(!entity.hasDirtyDependencies);
            }
            entity.callbacksNotified = false;

            if (start !== undefined) {
                const duration = getCurrentTimestampInMilliseconds() - start;
                if (entity.totalTime >= 0) {
                    entity.totalTime += duration;
                }
                // only report for the top-level ns.get, not recursive ones
                if (duration >= 500 && this._getRecursionLevel === 1) {
                    frontendSendEvent("slowComputation", duration, { app_id: this._appID });
                }
            }

            if (path.rest.rest === undefined) {
                return result;
            } else {
                return follow(result, path.rest.rest);
            }
        } finally {
            this._getRecursionLevel--;
        }
    }

    private markDependentsDirty(entity: Entity<TMetadata>): void {
        const markKey = (key: string, isDynamic: boolean) => {
            const e = this.getEntity(key);
            if (e.hasDirtyDependencies) {
                assert(e.callbacksNotified);
                return;
            }
            if (this._watchedEntities?.has(entity) === true) {
                logInfo(">>> marking dependency dirty", key, "from", entity.key);
            }
            e.hasDirtyDependencies = true;
            if (isDynamic) {
                e.dirtyDynamicDependencies.add(entity.key);
            }
            e.handler.dependenciesAreDirty?.();
            this.markDependentsDirty(e);
        };

        this.notifySubscribers(entity);

        for (const key of entity.dependents) {
            markKey(key, false);
        }
        for (const { key } of entity.dynamicDependents) {
            markKey(key, true);
        }
    }

    public handlerWasDirtied(handler: Handler): void {
        if (this._isRetired) return;

        const entity = defined(this._entitiesByHandler.get(handler));
        assert(handler.isDirty);
        if (this._watchedEntities?.has(entity) === true) {
            logInfo(">>> handler was dirtied", entity.key);
        }
        this.markDependentsDirty(entity);
    }

    public pushDirt(handler: Handler, dirt: Dirt): void {
        if (this._isRetired) return;

        const entity = defined(this._entitiesByHandler.get(handler));

        this._numPushes++;

        const printPush = this._watchedEntities?.has(entity) === true;
        if (printPush) {
            logInfo(">>> pushing dirt from", entity.key, dirt);
        }

        // FIXME: Shouldn't this run in a `setTimeout`?
        this.notifySubscribers(entity);

        // The caller, `entity`, has pushed dirt.  To propagate it, we iterate
        // over all the entities that directly depend on `entity`.
        for (const dependent of entity.dependents) {
            const e = this.getEntity(dependent);
            // We find all the slots in `dependent` that connect it to
            // `entity`.
            const slots = e.slotsByKey.get(entity.key);
            if (slots === undefined) continue;

            let propagate = false;
            for (const slot of slots) {
                this._numProcesses++;

                if (printPush || this._watchedEntities?.has(e) === true) {
                    logInfo(">>> pushing dirt to", e.key);
                }

                if (this._printDirt) {
                    logInfo(`  ${entity.name} -> ${e.name}: ${JSON.stringify(jsonifyDirt(dirt))}`);
                }

                // We call the each slot's callback, which will return a flag
                // telling us whether the dirt was "new", in which case we
                // need to propage the "dirty" flag to all dependents of
                // `dependent`.
                if (slot.process(dirt)) {
                    this._pushDirtAccounts.update(makePushDirtAccountKey(entity, e), x => x + 1);
                    if (printPush || this._watchedEntities?.has(e) === true) {
                        logInfo("  >>> propagate dirt");
                    }
                    propagate = true;
                }
            }

            if (propagate) {
                // At least one of the slots of `dependent` reported that the
                // dirt we sent was new, so we set `hasDirtyDependencies`.
                this.markDependentsDirty(e);
            }
        }

        const dynamicDependents = entity.dynamicDependents;
        // We clear the dynamic dependents so that the connection between the
        // entities doesn't stick around.  Once we've pushed the dirt there's
        // nothing more to do anyway because it would only ever push the same
        // dirt.  If the dependent still depends on this entity, it will
        // re-add it when it recomputes via `resolveQueryWithFixup`.
        entity.dynamicDependents = [];
        for (const { key, dirtPath, dirt: d } of dynamicDependents) {
            const e = this.getEntity(key);
            const slots = e.slotsByKey.get(dirtPath.rest.key);
            if (slots === undefined) continue;

            let propagate = false;
            for (const slot of slots) {
                if (slot.process(d)) {
                    propagate = true;
                }
            }

            if (propagate) {
                this.markDependentsDirty(e);
            }
        }
    }

    public fetchQuery(query: Query, callback: () => boolean, handler: Handler): GroundValue {
        if (this._queryFetcher === undefined) return undefined;

        const fetchCallback = () => {
            const entity = this._entitiesByHandler.get(handler);
            // It's possible the entity was deleted in the meantime.
            if (entity === undefined) return;
            const propagate = callback();
            if (propagate) {
                this.markDependentsDirty(entity);
            }
        };

        return this._queryFetcher.fetchQuery(query, fetchCallback);
    }

    public resolveQueryWithFixup(
        maybeQuery: GroundValue,
        path: Path,
        context: GroundValue,
        contextPath: RootPath | undefined,
        handler: Handler,
        asRow: boolean
    ): GroundValue {
        if (isLoadingValue(maybeQuery) || !isQuery(maybeQuery)) {
            return maybeQuery;
        }

        let dirtPath: RootPath;
        let dirt: Dirt;
        if (isRootPath(path)) {
            dirtPath = path;
            dirt = fullDirt;
        } else {
            assert(isKeyPath(path));
            dirtPath = defined(contextPath);
            assert(!isLoadingValue(context) && isRow(context));
            dirt = { kind: "row", rowID: context.$rowID, columns: new Set([path.key]) };
        }

        const callback = () => {
            const entity = this._entitiesByHandler.get(handler);
            if (entity === undefined) return;

            const slots = defined(entity.slotsByKey.get(dirtPath.rest.key));

            let propagate = false;
            for (const slot of slots) {
                if (slot.process(dirt)) {
                    propagate = true;
                }
            }
            if (propagate) {
                this.markDependentsDirty(entity);
            }
        };

        if (asRow) {
            maybeQuery = maybeQuery.withLimit(1);
        }

        let result: GroundValue;
        if (maybeQuery.serialize().groupBy !== undefined || !getFeatureSetting("queriesInComputationModel")) {
            if (this._queryFetcher === undefined) return undefined;

            result = this._queryFetcher.fetchQuery(maybeQuery, callback);
        } else {
            if (this._queryPathGetter === undefined) return undefined;

            const queryPath = this._queryPathGetter.getPathForQuery(maybeQuery);
            if (queryPath === undefined) return undefined;

            // We get the results first, before we add the dependency, so that
            // dirt pushed from it doesn't get added to the new dependency that's
            // currently already computing.
            result = this.get(queryPath);

            const entity = defined(this._entitiesByHandler.get(handler));
            const queryEntity = this.getEntity(queryPath);
            queryEntity.dynamicDependents.push({ key: entity.key, dirtPath, dirt });
        }

        if (asRow) {
            // This is how we ##resolveQueryAsRow.
            const wrapper = makeLoadingValueWrapper();
            const unwrapped = wrapper.unwrap(result);
            if (!isLoadingValue(unwrapped) && isTable(unwrapped)) {
                // This can return `undefined`, which is fine - there's no row.
                return wrapper.wrap(unwrapped.asMutatingArray()[0]);
            }
        }

        return result;
    }

    private makeKeyForNewEntity(name: string, handlerName: string, slots: readonly IncomingSlot[]): string {
        const slotKeys = slots.map(s => s.sourcePath.rest.key);
        const hopefullyUniqueName = `${name} ${handlerName} ${slotKeys.join(" ")}`;
        // We're doing the `md5` here to get a short, reproducible key, so
        // that they're the same between session, which is useful for
        // debugging.
        const key = makeNameUnique(md5(hopefullyUniqueName), k => this._entitiesByKey.has(k));
        return key;
    }

    public addEntity(name: string, handler: Handler, metadata: TMetadata | undefined): RootPath {
        if (this._isRetired) return makeRootPath("RETIRED");

        const slots = handler.getSlots();
        const key = this.makeKeyForNewEntity(name, handler.symbolicRepresentation, slots);
        const slotsByKey = new Map<string, IncomingSlot[]>();
        for (const slot of slots) {
            const k = slot.sourcePath.rest.key;
            let slotsForKey = slotsByKey.get(k);
            if (slotsForKey === undefined) {
                slotsForKey = [];
                slotsByKey.set(k, slotsForKey);
            }
            slotsForKey.push(slot);
        }
        const entity: Entity<TMetadata> = {
            key,
            name,
            handler,
            dependents: new Set(),
            dynamicDependents: [],
            slots,
            slotsByKey,
            hasDirtyDependencies: true,
            dirtyDynamicDependencies: new Set(),
            callbacksNotified: true,
            totalTime: -1, // prevents expensive timing calls, unless resetTiming was called, which sets this to 0
            metadata,
        };
        assert(!this._entitiesByKey.has(key));
        this._entitiesByKey.set(key, entity);
        assert(!this._entitiesByHandler.has(handler));
        this._entitiesByHandler.set(handler, entity);

        for (const q of entity.slots) {
            const e = this.getEntity(q.sourcePath);
            e.dependents.add(key);

            if (this._watchedEntities?.has(e) === true) {
                logInfo(">>> adding dependency of", e.key, ":", key, name);
            }
        }

        handler.setDirty();
        handler.connect?.(this);

        this.assertConnections();

        if (key === this._watchKey) {
            this.rebuildWatchedEntities();
        }

        return makeRootPath(key);
    }

    public deleteEntity(path: RootPath): void {
        if (this._isRetired) return;

        assert(isTopLevelPath(path));
        const {
            rest: { key },
        } = path;

        const entity = this.getEntity(key);
        if (this._watchedEntities?.has(entity) === true) {
            logInfo(">>> deleting", key);
        }

        entity.handler.disconnect?.(this);

        assert(this._entitiesByKey.has(key));
        this._entitiesByKey.delete(key);
        assert(this._entitiesByHandler.has(entity.handler));
        this._entitiesByHandler.delete(entity.handler);

        const dependencies: Entity<TMetadata>[] = [];
        for (const q of entity.slots) {
            const e = this.getEntity(q.sourcePath);
            assert(e.dependents.has(key));
            dependencies.push(e);

            if (this._watchedEntities?.has(e) === true) {
                logInfo(">>> deleting dependency of", e.key, key);
            }
        }
        for (const e of dependencies) {
            e.dependents.delete(key);
        }

        for (const d of entity.dynamicDependents) {
            const e = this.getEntity(d.key);
            if (!e.dirtyDynamicDependencies.has(key)) continue;

            e.dirtyDynamicDependencies.delete(key);

            if (this._watchedEntities?.has(e) === true) {
                logInfo(">>> deleting dirty dynamic dependency of", e.key, key);
            }
        }

        for (const k of entity.dirtyDynamicDependencies) {
            const e = this.getEntity(k);
            e.dynamicDependents = e.dynamicDependents.filter(d => d.key !== key);

            if (this._watchedEntities?.has(e) === true) {
                logInfo(">>> deleting dynamic dependent of", e.key, key);
            }
        }

        this.assertConnections();
    }

    // Removes the entity if it doesn't have dependents.  Returns whether the
    // entity was removed.
    public deleteEntityWithoutDependents(path: RootPath): boolean {
        if (this._isRetired) return false;

        assert(isTopLevelPath(path));

        // Just in case one of the paths is passed to us more than once we
        // don't assume that it exists - we could have deleted it in an
        // earlier iteration.
        const entity = this.getEntity(path);
        if (entity.dependents.size > 0 || entity.dynamicDependents.length > 0) return false;

        this.deleteEntity(path);
        return true;
    }

    public hasHandler(handler: Handler): boolean {
        return this._entitiesByHandler.has(handler);
    }

    public hasPath(path: RootPath): boolean {
        return this.getMaybeEntity(path) !== undefined;
    }

    public getAndResetPerformanceCounters(): PerformanceCounters {
        const numPushes = this._numPushes;
        const numProcesses = this._numProcesses;
        const pushDirtAccounts = this._pushDirtAccounts;
        this._numPushes = 0;
        this._numProcesses = 0;
        this._pushDirtAccounts.clear();
        return { numPushes, numProcesses, pushDirtAccounts };
    }

    // entity key -> level (starting at 0)
    private makeLevelsForGraph(): ReadonlyMap<string, number> {
        const inDegree = new DefaultMap<string, number>(() => 0);

        for (const key of this._entitiesByKey.keys()) {
            inDegree.set(key, 0);
        }
        for (const entity of this._entitiesByKey.values()) {
            for (const depKey of entity.dependents) {
                inDegree.update(depKey, x => x + 1);
            }
            for (const { key: depKey } of entity.dynamicDependents) {
                inDegree.update(depKey, x => x + 1);
            }
        }

        const level = new Map<string, number>();
        let nextLevel = 0;
        while (inDegree.size > 0) {
            const zeroes = Array.from(inDegree.entries())
                .filter(([, d]) => d === 0)
                .map(([k]) => k);
            assert(zeroes.length > 0);

            for (const k of zeroes) {
                inDegree.delete(k);
                const entity = defined(this._entitiesByKey.get(k));
                for (const depKey of entity.dependents) {
                    inDegree.update(depKey, x => x - 1);
                }
                for (const { key: depKey } of entity.dynamicDependents) {
                    inDegree.update(depKey, x => x - 1);
                }
                level.set(k, nextLevel);
            }

            nextLevel++;
        }

        return level;
    }

    public makeGraph(): NamespaceGraph {
        if (this._isRetired) return { nodes: [], edges: [] };

        const level = this.makeLevelsForGraph();

        let nextNodeID = 1;
        let nextEdgeID = 1;
        const nodeIDForKey = new DefaultMap<string, number>(() => nextNodeID++);

        const nodes: NamespaceGraphNode[] = [];
        const edges: NamespaceGraphEdge[] = [];

        for (const [key, entity] of this._entitiesByKey) {
            const id = nodeIDForKey.get(key);
            nodes.push({ id, label: `${entity.name}\n${entity.totalTime}`, level: defined(level.get(key)), key });
            for (const depKey of entity.dependents) {
                edges.push({ id: nextEdgeID++, from: id, to: nodeIDForKey.get(depKey), arrows: "to" });
            }
            for (const { key: depKey } of entity.dynamicDependents) {
                edges.push({ id: nextEdgeID++, from: id, to: nodeIDForKey.get(depKey), arrows: "to" });
            }
        }

        return { nodes, edges };
    }

    public debugPrintNode({ label, key }: NamespaceGraphNode): void {
        logInfo("Node", label, this.getEntity(key));
    }

    public makeGraphviz(): string {
        if (this._isRetired) return "";

        const { nodes, edges } = this.makeGraph();

        const lines: string[] = [];
        function nameForID(id: number) {
            return `node${id}`;
        }

        lines.push("digraph D {");
        lines.push("    rankdir=LR;");

        for (const { id, label } of nodes) {
            lines.push(`    ${nameForID(id)} [label="${label}"];`);
        }

        for (const { from, to } of edges) {
            lines.push(`    ${nameForID(from)} -> ${nameForID(to)};`);
        }

        lines.push("}");

        return lines.join("\n");
    }

    public setAllDirty(): void {
        if (this._isRetired) return;

        if (this._watchedEntities !== undefined) {
            logInfo(">>> setting all dirty");
        }

        for (const entity of this._entitiesByKey.values()) {
            entity.handler.setDirty();
            entity.hasDirtyDependencies = true;
            entity.handler.dependenciesAreDirty?.();
            this.notifySubscribers(entity);
        }
    }

    public setTimeOutAt(timeOutAt: number | undefined): void {
        this._timeOutAt = timeOutAt;
    }

    public resetTiming(print: boolean = false, onlyIfAlreadyTiming: boolean = false): void {
        let alreadyTiming = false;
        const entities = sortBy(Array.from(this._entitiesByKey.values()), e => -e.totalTime);
        if (print) {
            logInfo("*** Reset Timing ***");
        }
        for (const e of entities) {
            if (print) {
                logInfo("   ", e.name, e.totalTime);
            }
            alreadyTiming ||= e.totalTime >= 0;
            if (print || !onlyIfAlreadyTiming || alreadyTiming) {
                e.totalTime = 0;
            }
        }
    }

    public getTiming(): readonly TimingResult<TMetadata>[] {
        const entries: TimingResult<TMetadata>[] = [];
        for (const e of this._entitiesByKey.values()) {
            if (e.totalTime > 0) {
                entries.push({ metadata: e.metadata, name: e.name, totalTime: e.totalTime });
            }
        }
        return entries;
    }

    public checkConsistency(): void {
        assert(this._entitiesByKey.size === this._entitiesByHandler.size);

        for (const [key, entity] of this._entitiesByKey.entries()) {
            assert(this._entitiesByHandler.get(entity.handler) === entity);

            for (const slot of entity.slots) {
                const e = this.getEntity(slot.sourcePath);
                assert(e.dependents.has(key));

                if (!entity.hasDirtyDependencies) {
                    assert(!e.hasDirtyDependencies);
                    assert(!e.handler.isDirty);
                }
            }

            for (const k of entity.dependents) {
                const e = defined(this._entitiesByKey.get(k));
                assert(Array.from(e.slots).some(s => this.getEntity(s.sourcePath) === entity));
            }

            for (const d of entity.dynamicDependents) {
                this.getEntity(d.key);
            }

            for (const slot of entity.slots) {
                const e = this.getEntity(slot.sourcePath);
                if (e.hasDirtyDependencies) {
                    assert(entity.hasDirtyDependencies);
                }
            }
        }
    }

    // When do subscribers have to fire?
    //
    // * When dirt is pushed from the subscribed path.
    // * When we add dirt to the subscribed path.
    // * When we mark the path as `hasDirtyDependencies`.
    //
    // Doing both will lead to subscribers firing more than once.  Let's say C
    // depends on B, depends on A, and there's a subscriber on C. A is updated
    // somehow and pushes dirt to B.  Then we mark B's dependents as dirty,
    // i.e. C.  This fires the callback.  Now we recompute C, which recomputed
    // B, which pushes dirt from B to C, which fires the callback again.  If C
    // also pushes dirt, it'll fire the callback a third time.
    //
    // We might be able to solve this by keeping track of whether we've fired
    // for a specific entity, and not firing again until it's fully
    // recomputed.
    private notifySubscribers(entity: Entity<TMetadata>): void {
        const { callbacksNotified, key } = entity;

        if (callbacksNotified) return;
        entity.callbacksNotified = true;

        for (const [keys, onChange] of this._subscribers) {
            if (keys.has(key)) {
                onChange();
            }
        }
    }

    public addSubscriber(paths: readonly RootPath[], onChange: OnChangeCallback): void {
        if (this._isRetired) return;

        assert(!this._subscribers.some(([, c]) => c === onChange));

        const keys = new Set(paths.map(p => p.rest.key));
        this._subscribers.push([keys, onChange]);
    }

    public removeSubscriber(onChange: OnChangeCallback): void {
        if (this._isRetired) return;

        let numFound = 0;
        this._subscribers = this._subscribers.filter(([, c]) => {
            if (c !== onChange) return true;
            numFound++;
            return false;
        });
        assert(numFound === 1);
    }

    public setErrorFromComputation(result: ErrorResult): void {
        this._errorFromComputation = result;
    }

    public getErrorFromComputation(): ErrorResult | undefined {
        return this._errorFromComputation;
    }

    public resetErrorFromComputation(): void {
        this._errorFromComputation = undefined;
    }

    public retire(): void {
        if (this._isRetired) return;
        this._isRetired = true;

        for (const e of this._entitiesByKey.values()) {
            e.handler.disconnect?.(this);
        }

        this._entitiesByKey.clear();
        this._entitiesByHandler.clear();
        this._pushDirtAccounts.clear();
        this._watchedEntities?.clear();
        this._subscribers = [];
    }

    public debugPrintGraph(): void {
        if (this._isRetired) return;

        for (const entity of this._entitiesByKey.values()) {
            const flags: string[] = [];
            if (entity.hasDirtyDependencies) {
                flags.push("dirtyDeps");
            }
            if (entity.callbacksNotified) {
                flags.push("notified");
            }
            if (this._subscribers.some(([ks]) => ks.has(entity.key))) {
                flags.push("subscr");
            }
            logInfo(`${entity.key} ${entity.name} ${flags.join(",")}`);
            const slots = entity.slotsByKey;
            if (slots.size > 0) {
                logInfo(
                    `  <- ${Array.from(slots.keys())
                        .map(k => k)
                        .join(" ")}`
                );
            }
            if (entity.dependents.size > 0) {
                logInfo(`  -> ${Array.from(entity.dependents).join(" ")}`);
            }

            if (entity.dynamicDependents.length > 0) {
                logInfo(`  --> ${entity.dynamicDependents.map(d => d.key).join(" ")}`);
            }
        }
    }

    public getSymbolicRepresentation(
        symbolicRepresentationForMetadata: (m: TMetadata) => string | undefined
    ): readonly string[] {
        const logs: string[] = [];

        if (this._isRetired) return logs;

        const levels = this.makeLevelsForGraph();
        const keys = sortBy(Array.from(this._entitiesByKey.keys()), k => defined(levels.get(k)));
        for (const k of keys) {
            const entity = defined(this._entitiesByKey.get(k));
            const label = definedMap(entity.metadata, symbolicRepresentationForMetadata) ?? entity.name;
            logs.push(`${k} = ${entity.handler.symbolicRepresentation}${label === undefined ? "" : ` // ${label}`}`);
        }

        return logs;
    }
}
