import { getFeatureSetting } from "@glide/common-core";
import type {
    Batch,
    DiffResults,
    DocumentData,
    Query,
    QueryResults,
    SortDirection,
    Transaction,
} from "@glide/common-core/dist/js/Database";
import { DatabaseBase, type PollingOptions } from "@glide/common-core/dist/js/Database/core";
import { Poller } from "@glide/common-core/dist/js/Database/polling-listener";
import { frontendTrace } from "@glide/common-core/dist/js/tracing";
import { convertDateToTimeZoneAgnostic } from "@glide/data-types";
import {
    type JSONObject,
    allSettled,
    getCurrentTimestampInMilliseconds,
    isArray,
    logError,
    logInfo,
    withTimeoutError,
} from "@glide/support";
import { assert, hasOwnProperty, panic } from "@glideapps/ts-necessities";
import firebase from "firebase/compat/app";
import toPairs from "lodash/toPairs";
import pLimit from "p-limit";

// This is used when the `polling` parameter is passed to `listenWhere` or `listenDiffWhere`.
//  Currently, this is just a guess-- the "ideal" value would be based on the size of each page (row in native table),
//  but we also don't want to set this too small or it will take forever to page in all the data (though the snapshot mitigates this)
const pollingPageSize = 500;

function queryResultsFromSnapshots(docs: firebase.firestore.QueryDocumentSnapshot[]): QueryResults {
    return docs.map(doc => ({ data: doc.data(), id: doc.id, path: doc.ref.path }));
}

export function isPermissionsError(e: unknown): e is { code: "permission-denied" } {
    return hasOwnProperty(e, "code") && e.code === "permission-denied";
}

function isTimeoutError(e: unknown): e is { message: "timeout" } {
    return hasOwnProperty(e, "message") && e.message === "timeout";
}

class ClientDatabaseBatch implements Batch {
    private _batch: firebase.firestore.WriteBatch;
    private _count: number = 0;

    constructor(public readonly database: ClientDatabase, private readonly _db: firebase.firestore.Firestore) {
        this._batch = this._db.batch();
    }

    private async commit(): Promise<void> {
        if (this._count === 0) return;

        await this._batch.commit();
        this._batch = this._db.batch();
        this._count = 0;
    }

    private async operationAdded(): Promise<void> {
        this._count += 1;
        if (this._count >= 500) {
            await this.commit();
        }
    }

    public async setDocument(collectionName: string, id: string | undefined, data: DocumentData): Promise<string> {
        const collection = this._db.collection(collectionName);
        const doc = id === undefined ? collection.doc() : collection.doc(id);

        this._batch.set(doc, data);
        await this.operationAdded();

        return doc.id;
    }

    public async deleteDocument(collectionName: string, id: string): Promise<void> {
        const doc = this._db.collection(collectionName).doc(id);

        this._batch.delete(doc);
        await this.operationAdded();
    }

    public async updateDocument(collectionName: string, id: string | undefined, data: DocumentData): Promise<string> {
        const collection = this._db.collection(collectionName);
        const doc = id === undefined ? collection.doc() : collection.doc(id);

        this._batch.set(doc, data, { merge: true });
        await this.operationAdded();

        return doc.id;
    }

    public async updateDocumentWithNesting(collectionName: string, id: string, data: DocumentData): Promise<void> {
        const doc = this._db.collection(collectionName).doc(id);
        this._batch.update(doc, data);
        await this.operationAdded();
    }

    public async finish(): Promise<void> {
        await this.commit();
    }
}

class ClientDatabaseTransaction implements Transaction {
    constructor(
        public readonly database: ClientDatabase,
        private readonly _db: firebase.firestore.Firestore,
        private readonly _transaction: firebase.firestore.Transaction
    ) {}

    public async getDocument(collectionName: string, id: string): Promise<DocumentData | undefined> {
        const doc = this._db.collection(collectionName).doc(id);
        const snapshot = await this._transaction.get(doc);
        return snapshot.data();
    }

    public async setDocument(collectionName: string, id: string | undefined, data: DocumentData): Promise<string> {
        const collection = this._db.collection(collectionName);
        const doc = id === undefined ? collection.doc() : collection.doc(id);

        this._transaction.set(doc, data);
        return doc.id;
    }

    public async deleteDocument(collectionName: string, id: string): Promise<void> {
        const doc = this._db.collection(collectionName).doc(id);
        this._transaction.delete(doc);
    }

    public async updateDocument(collectionName: string, id: string | undefined, data: DocumentData): Promise<string> {
        const collection = this._db.collection(collectionName);
        const doc = id === undefined ? collection.doc() : collection.doc(id);

        this._transaction.set(doc, data, { merge: true });
        return doc.id;
    }

    public async updateDocumentWithNesting(collectionName: string, id: string, data: DocumentData): Promise<void> {
        const doc = this._db.collection(collectionName).doc(id);
        this._transaction.update(doc, data);
    }
}

export abstract class ClientDatabase extends DatabaseBase {
    protected abstract getDB(): Promise<firebase.firestore.Firestore>;

    public get projectID(): string {
        return panic("projectID shouldn't be called in ClientDatabase");
    }

    public get deleteFieldValue(): unknown {
        return firebase.firestore.FieldValue.delete();
    }

    public get serverTimestampFieldValue(): unknown {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    public arrayUnionFieldValue(...values: unknown[]): unknown {
        return firebase.firestore.FieldValue.arrayUnion(...values);
    }

    public arrayRemoveFieldValue(...values: unknown[]): unknown {
        return firebase.firestore.FieldValue.arrayRemove(...values);
    }

    public incrementFieldValue(increment: number = 1): unknown {
        return firebase.firestore.FieldValue.increment(increment);
    }

    public async makeDocumentID(): Promise<string> {
        const collection = (await this.getDB()).collection("dummy");
        return collection.doc().id;
    }

    public async getDocument(collectionName: string, id: string): Promise<DocumentData | undefined> {
        await this.enterNetworkConnectionLock();
        try {
            return await frontendTrace("getDocument", { collection: collectionName }, async () => {
                const doc = await (await this.getDB()).collection(collectionName).doc(id).get();
                return doc.data();
            });
        } finally {
            this.exitNetworkConnectionLock();
        }
    }

    protected async makeQuery(
        collectionName: string,
        queries: ReadonlyArray<Query>,
        sortFieldPath?: string,
        sortDirection?: SortDirection,
        limit?: number
    ): Promise<firebase.firestore.Query> {
        let query: firebase.firestore.Query = (await this.getDB()).collection(collectionName);
        if (sortFieldPath !== undefined) {
            query = query.orderBy(sortFieldPath, sortDirection);
        }
        for (const { fieldPath, opString, value } of queries) {
            query = query.where(fieldPath, opString, value);
        }
        if (limit !== undefined) {
            query = query.limit(limit);
        }
        return query;
    }

    public async getDocumentsWhere(
        collectionName: string,
        queries: ReadonlyArray<Query>,
        sortFieldPath?: string,
        sortDirection?: SortDirection,
        limit?: number
    ): Promise<QueryResults> {
        const query = await this.makeQuery(collectionName, queries, sortFieldPath, sortDirection, limit);
        await this.enterNetworkConnectionLock();
        try {
            return await frontendTrace("getDocumentsWhere", { collection: collectionName }, async () => {
                const docs = (await query.get()).docs;
                return docs.map(doc => ({ data: doc.data(), id: doc.id, path: doc.ref.path }));
            });
        } finally {
            this.exitNetworkConnectionLock();
        }
    }

    private async runPaginatedQuery(
        collectionName: string,
        queries: ReadonlyArray<Query>,
        sortFieldPath: string | undefined,
        sortDirection: SortDirection | undefined,
        basePageSize: number,
        concurrently: boolean,
        // When we're listening we want to be notified when the collection is
        // empty, but when we're just getting we don't want an empty page
        // returned.
        callWithEmptyPages: boolean,
        callback: (page: QueryResults, durationMs?: number) => Promise<boolean | void>
    ): Promise<void> {
        // These consts and runPaginatedQuery largely copied from admin-database/core
        //  FIXME: we should try to avoid this duplication, especially of these magic numbers.
        const minPageSizeForSplit = 64;
        const firestoreFullOperationTimeout = 600_000; // 10 minutes

        const promises: Promise<unknown>[] = [];
        const limit = concurrently ? pLimit(10) : undefined;

        let lastDoc: firebase.firestore.QueryDocumentSnapshot | undefined;
        let abort = false;
        let pageSize = basePageSize;
        for (;;) {
            if (abort) break;
            let query = await this.makeQuery(collectionName, queries, sortFieldPath, sortDirection, pageSize);

            // Something dumb happens deep in the Firestore library if a query contains a FieldPath type.
            // The library errors out in the most unhelpful way getting the second page, stating
            // "Error: 3 INVALID_ARGUMENT: order by clause cannot contain more fields after the key."
            // But if it just so happens that our FieldPath is explicitly shoved in the orderBy,
            // everything is nice and happy.
            //
            // Shoving the fieldPath member of the first query in the orderBy call works for both strings
            // and FieldPath instances.
            //
            // However, if that first query is an equality query, we get an
            // "Error: 3 INVALID_ARGUMENT: order by clause cannot contain a field with an equality filter"
            // so we need to not orderBy in those cases.
            //
            // FIXME: These Firestore bugs have not been reported anywhere. We should report them.
            // FIXME: Our Firestore library is really old, maybe updating fixes this.
            if (
                sortFieldPath === undefined &&
                queries.length > 0 &&
                !(queries[0].opString === "==" || queries[0].opString === "array-contains")
            ) {
                query = query.orderBy(queries[0].fieldPath, "asc");
            }
            if (lastDoc !== undefined) {
                query = query.startAfter(lastDoc);
            }
            try {
                const start = getCurrentTimestampInMilliseconds();
                const { size, docs } = await withTimeoutError(query.get(), firestoreFullOperationTimeout);
                if (size === 0 && !callWithEmptyPages) break;

                const page = queryResultsFromSnapshots(docs);
                // `size` can be `0`, in which case `lastDoc` will be
                // `undefined`.
                lastDoc = docs[size - 1];

                async function runCallback(p: QueryResults): Promise<void> {
                    const result = await callback(p, getCurrentTimestampInMilliseconds() - start);
                    if (result === false) {
                        abort = true;
                    }
                }

                if (limit !== undefined) {
                    promises.push(limit(() => runCallback(page)));
                } else {
                    await runCallback(page);
                }

                // We want the callbacks to run even if `size === 0` because
                // otherwise we won't ever report back in case the result set
                // is empty.
                if (size === 0) break;
            } catch (e: unknown) {
                if (isTimeoutError(e) && pageSize > minPageSizeForSplit) {
                    logError("Failed to read Firestore page", pageSize, e);
                    pageSize = Math.ceil(pageSize / 1.414);
                } else {
                    throw e;
                }
            }
        }
        await allSettled(promises);
    }

    public async getDocumentsWherePaginated(
        collectionName: string,
        queries: ReadonlyArray<Query>,
        pageSize: number,
        concurrently: boolean,
        callback: (page: QueryResults) => Promise<boolean | void>,
        sortFieldPath?: string,
        sortFieldDirection?: SortDirection
    ): Promise<void> {
        return await this.runPaginatedQuery(
            collectionName,
            queries,
            sortFieldPath,
            sortFieldDirection,
            pageSize,
            concurrently,
            false,
            callback
        );
    }

    public async setDocument(collectionName: string, id: string | undefined, data: DocumentData): Promise<string> {
        await this.enterNetworkConnectionLock();
        try {
            return await frontendTrace("setDocument", { collection: collectionName }, async () => {
                const collection = (await this.getDB()).collection(collectionName);
                const doc = id === undefined ? collection.doc() : collection.doc(id);
                await doc.set(data);
                return doc.id;
            });
        } finally {
            this.exitNetworkConnectionLock();
        }
    }

    public async deleteDocument(collectionName: string, id: string): Promise<void> {
        await this.enterNetworkConnectionLock();
        try {
            return await frontendTrace("deleteDocument", { collection: collectionName }, async () => {
                await (await this.getDB()).collection(collectionName).doc(id).delete();
            });
        } finally {
            this.exitNetworkConnectionLock();
        }
    }

    public async updateDocument(collectionName: string, id: string | undefined, data: DocumentData): Promise<string> {
        await this.enterNetworkConnectionLock();
        try {
            return await frontendTrace("updateDocument", { collection: collectionName }, async () => {
                const collection = (await this.getDB()).collection(collectionName);
                const doc = id === undefined ? collection.doc() : collection.doc(id);
                await doc.set(data, { merge: true });
                return doc.id;
            });
        } finally {
            this.exitNetworkConnectionLock();
        }
    }

    public async updateDocumentWithNesting(collectionName: string, id: string, data: DocumentData): Promise<void> {
        await this.enterNetworkConnectionLock();
        try {
            return await frontendTrace("updateDocumentWithNesting", { collection: collectionName }, async () => {
                const doc = (await this.getDB()).collection(collectionName).doc(id);
                await doc.update(data);
            });
        } finally {
            this.exitNetworkConnectionLock();
        }
    }

    public async runBatch<T>(name: string, f: (b: Batch) => Promise<T>): Promise<T> {
        const batch = new ClientDatabaseBatch(this, await this.getDB());
        await this.enterNetworkConnectionLock();
        return await frontendTrace(name, undefined, async () => {
            try {
                return await f(batch);
            } finally {
                try {
                    await batch.finish();
                } finally {
                    this.exitNetworkConnectionLock();
                }
            }
        });
    }

    public async runTransaction<T>(name: string, f: (t: Transaction) => Promise<T>): Promise<T> {
        const db = await this.getDB();
        await this.enterNetworkConnectionLock();
        return await frontendTrace(name, undefined, async () => {
            try {
                return await db.runTransaction(async t => f(new ClientDatabaseTransaction(this, db, t)));
            } finally {
                this.exitNetworkConnectionLock();
            }
        });
    }

    public listenToDocument(
        collectionName: string,
        id: string,
        onUpdate: (data: DocumentData | undefined) => void,
        onError: ((e: Error) => void) | undefined
    ): () => void {
        if (onError === undefined) {
            onError = e => {
                const permError = isPermissionsError(e);
                const logFunc = permError ? logInfo : logError;
                logFunc("Error listening to document", collectionName, id, e);
            };
        }

        const ongoing = this.getDB().then(db =>
            db
                .collection(collectionName)
                .doc(id)
                .onSnapshot(snapshot => {
                    const data = snapshot.data();
                    onUpdate(data);
                }, onError)
        );
        return () => {
            ongoing.then(cancel => cancel()).catch(onError);
        };
    }

    private makeVersionedQuery(
        queries: readonly Query[],
        sortFieldPath: string | undefined,
        sortDirection: SortDirection | undefined,
        polling: PollingOptions,
        currentVersion: number | undefined
    ) {
        if (currentVersion !== undefined) {
            const [versionQuery, versionSortFieldPath] = polling.makeRowVersionQuery(currentVersion);
            assert(sortFieldPath === undefined);
            queries = [...queries, versionQuery];
            sortFieldPath = versionSortFieldPath;
            sortDirection = "asc";
        }
        return {
            queries,
            sortFieldPath,
            sortDirection,
        };
    }

    public listenWhere(
        collectionName: string,
        queries: Query[],
        sortFieldPath: string | undefined,
        sortDirection: SortDirection | undefined,
        limit: number | undefined,
        onUpdate: (results: QueryResults) => void,
        onError?: (e: Error) => void,
        polling?: PollingOptions
    ): () => void {
        if (onError === undefined) {
            onError = e => {
                logError("Error listening to query", collectionName, e);
            };
        }

        if (polling !== undefined) {
            let calledBackOnce = false;

            // FIXME: This instance could be cached for the same collectionName, queries, etc
            const poller = new Poller<number, QueryResults>(collectionName, async (lastVersion, cb) => {
                const pageSize = limit ?? pollingPageSize;
                let currentVersion = lastVersion ?? polling.initialVersion;
                const versioned = this.makeVersionedQuery(
                    queries,
                    sortFieldPath,
                    sortDirection,
                    polling,
                    currentVersion
                );
                await this.runPaginatedQuery(
                    collectionName,
                    versioned.queries,
                    versioned.sortFieldPath,
                    versioned.sortDirection,
                    pageSize,
                    false,
                    true,
                    (pages, duration) => {
                        if (getFeatureSetting("elideEmptyPollingCallbacks")) {
                            if (pages.length === 0 && calledBackOnce) return Promise.resolve(true);
                        }

                        currentVersion = pages.reduce(
                            (v, page) =>
                                v !== undefined
                                    ? Math.max(v, polling.getVersionFromData(page.data))
                                    : polling.getVersionFromData(page.data),
                            currentVersion
                        );
                        calledBackOnce = true;
                        return cb(pages, duration);
                    }
                );
                return currentVersion;
            });
            const listener = { onUpdate, onError };
            const abort = new AbortController();
            void poller.addListener(listener, abort.signal);
            return () => {
                abort.abort();
                poller.removeListener(listener);
            };
        }

        const ongoing = this.makeQuery(collectionName, queries, sortFieldPath, sortDirection, limit).then(query =>
            query.onSnapshot(snapshot => {
                onUpdate(queryResultsFromSnapshots(snapshot.docs));
            }, onError)
        );
        return () => {
            ongoing.then(cancel => cancel()).catch(onError);
        };
    }

    public listenDiffWhere(
        collectionName: string,
        queries: Query[],
        sortFieldPath: string | undefined,
        sortDirection: SortDirection | undefined,
        onUpdate: (results: DiffResults, durationMs?: number) => void,
        onError: (e?: Error) => void,
        polling?: PollingOptions
    ): () => void {
        if (polling !== undefined) {
            let calledBackOnce = false;

            // FIXME: This instance could be cached for the same collectionName, queries, etc
            const poller = new Poller<number, DiffResults>(collectionName, async (lastVersion, cb) => {
                let currentVersion = lastVersion ?? polling.initialVersion;
                const versioned = this.makeVersionedQuery(
                    queries,
                    sortFieldPath,
                    sortDirection,
                    polling,
                    currentVersion
                );

                await this.runPaginatedQuery(
                    collectionName,
                    versioned.queries,
                    versioned.sortFieldPath,
                    versioned.sortDirection,
                    pollingPageSize,
                    false,
                    true,
                    (pages, duration) => {
                        if (getFeatureSetting("elideEmptyPollingCallbacks")) {
                            if (pages.length === 0 && calledBackOnce) return Promise.resolve(true);
                        }

                        currentVersion = pages.reduce(
                            (v, page) =>
                                v !== undefined
                                    ? Math.max(v, polling.getVersionFromData(page.data))
                                    : polling.getVersionFromData(page.data),
                            currentVersion
                        );

                        calledBackOnce = true;
                        // So we just treat all returned rows here as "added" .. I don't think this makes too much difference, because updateWithDiffResults
                        //  treats "added" and "modified" the same. We won't receive "removed" events, but with our row deletion tombstone, I don't think this matters too much?
                        return cb(
                            pages.map(page => ({ ...page, kind: "added" })),
                            duration
                        );
                    }
                );
                return currentVersion;
            });
            const listener = { onUpdate, onError };
            const abort = new AbortController();
            void poller.addListener(listener, abort.signal);
            return () => {
                abort.abort();
                poller.removeListener(listener);
            };
        }

        const ongoing = this.makeQuery(collectionName, queries, sortFieldPath, sortDirection).then(query =>
            query.onSnapshot(
                snapshot => {
                    const changes = snapshot.docChanges();
                    onUpdate(changes.map(({ type, doc }) => ({ kind: type, id: doc.id, data: doc.data() })));
                },
                e => {
                    logError("Error listening to diff query", collectionName, e);
                    onError(e);
                }
            )
        );
        return () => {
            ongoing.then(cancel => cancel()).catch(logError);
        };
    }

    public dateFromTimestamp(timestamp: unknown): Date {
        // sometimes(?) Firebase returns a timestamp as a Date
        if (timestamp instanceof Date) return timestamp;
        return (timestamp as firebase.firestore.Timestamp).toDate();
    }

    public convertDocumentProperty(x: unknown): unknown {
        if (x instanceof firebase.firestore.Timestamp) {
            return (x as firebase.firestore.Timestamp).toDate();
        }
        // FIXME: This doesn't really belong in here.  It should be somewhere
        // in `firestore-datastore.ts`, except that right now we special-case
        // loading comments, and that's somewhere else.  It's also duplicated
        // across both `Database` implementations.
        if (hasOwnProperty(x, "$dateTime")) {
            let dateTime = this.convertDocumentProperty(x.$dateTime);
            if (dateTime instanceof Date) {
                if (hasOwnProperty(x, "$isTimeZoneAgnostic") && x.$isTimeZoneAgnostic !== true) {
                    dateTime = convertDateToTimeZoneAgnostic(dateTime);
                }
                return dateTime;
            }
        }
        return x;
    }

    private convertValueFromDocument(v: unknown): unknown {
        assert(v !== undefined);
        if (isArray(v)) {
            return v.map(x => this.convertValueFromDocument(x));
        } else if (v instanceof firebase.firestore.Timestamp) {
            return v.toDate();
        } else if (typeof v === "object" && v !== null) {
            return this.convertFromDocument(v);
        } else if (
            v === null ||
            typeof v === "string" ||
            typeof v === "number" ||
            typeof v === "boolean" ||
            v instanceof Date
        ) {
            return v;
        } else {
            return panic(`Invalid value in Firestore document ${v}`);
        }
    }

    public convertFromDocument(data: DocumentData): JSONObject {
        const result: DocumentData = {};
        for (const [k, v] of toPairs(data)) {
            if (v === undefined) continue;
            result[k] = this.convertValueFromDocument(v);
        }
        return result;
    }
}
