import { UnaryPredicateFormulaOperator, isFilterColumn as isQueryColumn } from "@glide/type-schema";
import type { SerializedQuery } from "@glide/computation-model-types";
import { type DeepWritable, assert, defined } from "@glideapps/ts-necessities";

// This is where we walk ##queryColumns. There are 2 versions,
// `walkQueryColumns`, and `modifyQueryColumns`:
//
// `walkQueryColumns` is useful for finding a specific column or aggregating
// columns. If callback returns anything other than `undefined`,
// `walkQueryColumns` will stop and return that value.
//
// `modifyQueryColumns` is useful for renaming columns and deleting references to
// columns.  The second argument to the callback is used to map the column
// name to something else, and the third argument to delete the reference to
// that column in the query.  A new `SerializedQuery` is returned from
// `walkQueryColumns` with the new column names.

// This function does both, and we just call it differently:
function walkQueryColumnsInternal(
    query: SerializedQuery,
    // The callback returns whether to continue walking.  If it ever returns
    // `false` then the function will return `undefined`, otherwise it will
    // return the modified query.
    callback: (
        columnName: string,
        setColumnName: (newColumnName: string) => void,
        deleteColumnReference: () => void
    ) => boolean
): SerializedQuery | undefined {
    // Create a deep copy of query so we can mutate it
    // FIXME: use structuredClone once we have node 17
    const q: DeepWritable<typeof query> = JSON.parse(JSON.stringify(query));

    // We walk backward so we can easily delete conditions
    for (let i = q.conditions.length - 1; i >= 0; i--) {
        for (let j = q.conditions[i].length - 1; j >= 0; j--) {
            const condition = q.conditions[i][j];
            switch (condition.kind) {
                case "array-overlap":
                case "is-empty":
                case UnaryPredicateFormulaOperator.IsTruthy: {
                    let del = false;
                    const result = callback(
                        condition.column.columnName,
                        c => {
                            const v = defined(q?.conditions[i][j]);
                            assert(v.kind === condition.kind);
                            v.column.columnName = c;
                        },
                        () => {
                            del = true;
                        }
                    );
                    if (!result) return undefined;
                    if (del) {
                        q.conditions[i].splice(j, 1);
                    }
                    break;
                }

                default: {
                    let del = false;
                    let result = callback(
                        condition.lhs.columnName,
                        c => {
                            const v = defined(q?.conditions[i][j]);
                            assert(v.kind === condition.kind);
                            v.lhs.columnName = c;
                        },
                        () => {
                            del = true;
                        }
                    );
                    if (!result) return undefined;
                    if (isQueryColumn(condition.rhs)) {
                        result = callback(
                            condition.rhs.columnName,
                            c => {
                                const v = defined(q?.conditions[i][j]);
                                assert(v.kind === condition.kind && isQueryColumn(v.rhs));
                                v.rhs.columnName = c;
                            },
                            () => {
                                del = true;
                            }
                        );
                        if (!result) return undefined;
                    }
                    if (del) {
                        q.conditions[i].splice(j, 1);
                    }
                    break;
                }
            }
        }
        if (q.conditions[i].length === 0) {
            q.conditions.splice(i, 1);
        }
    }

    for (let i = q.sort.length - 1; i >= 0; i--) {
        let del = false;
        const result = callback(
            q.sort[i].columnName,
            c => {
                defined(q?.sort[i]).columnName = c;
            },
            () => {
                del = true;
            }
        );
        if (!result) return undefined;
        if (del) {
            q.sort.splice(i, 1);
        }
    }

    if (q.search !== undefined) {
        for (let i = q.search.columnNames.length - 1; i >= 0; i--) {
            let del = false;
            const result = callback(
                q.search.columnNames[i],
                c => {
                    defined(q?.search).columnNames[i] = c;
                },
                () => {
                    del = true;
                }
            );
            if (!result) return undefined;
            if (del) {
                q.search.columnNames.splice(i, 1);
            }
        }
        if (q.search.columnNames.length === 0) {
            q.search = undefined;
        }
    }

    if (q.groupBy !== undefined) {
        for (let i = q.groupBy.columns.length - 1; i >= 0; i--) {
            let del = false;
            const result = callback(
                q.groupBy.columns[i],
                c => {
                    defined(q?.groupBy).columns[i] = c;
                },
                () => {
                    del = true;
                }
            );
            if (!result) return undefined;
            if (del) {
                q.groupBy.columns.splice(i, 1);
            }
        }
        for (let i = q.groupBy.sort.length - 1; i >= 0; i--) {
            let del = false;
            const result = callback(
                q.groupBy.sort[i].columnName,
                c => {
                    defined(q?.groupBy?.sort[i]).columnName = c;
                },
                () => {
                    del = true;
                }
            );
            if (!result) return undefined;
            if (del) {
                q.groupBy.sort.splice(i, 1);
            }
        }
        for (let i = q.groupBy.aggregates.length - 1; i >= 0; i--) {
            let del = false;
            const result = callback(
                q.groupBy.aggregates[i].column,
                c => {
                    defined(q?.groupBy?.aggregates[i]).column = c;
                },
                () => {
                    del = true;
                }
            );
            if (!result) return undefined;
            if (del) {
                q.groupBy.aggregates.splice(i, 1);
            }
        }
    }

    return q;
}

export function walkQueryColumns<T>(
    query: SerializedQuery,
    callback: (columnName: string) => T | undefined
): T | undefined {
    let result: T | undefined;
    walkQueryColumnsInternal(query, columnName => {
        const r = callback(columnName);
        if (r !== undefined) {
            result = r;
            return false;
        }
        return true;
    });
    return result;
}

export function modifyQueryColumns(
    query: SerializedQuery,
    callback: (
        columnName: string,
        setColumnName: (newColumnName: string) => void,
        deleteColumnReference: () => void
    ) => void
): SerializedQuery {
    return defined(
        walkQueryColumnsInternal(query, (...args) => {
            callback(...args);
            return true;
        })
    );
}

export function doesQueryReferToColumns(query: SerializedQuery, columnNames: ReadonlySet<string>): boolean {
    return walkQueryColumns(query, columnName => (columnNames.has(columnName) ? true : undefined)) ?? false;
}
