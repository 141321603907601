import {
    type ComponentDescription,
    ArrayScreenFormat,
    getActionProperty,
    getEnumProperty,
} from "@glide/app-description";
import { ContainerAlignment, ContainerLayout } from "@glide/common-core/dist/js/description";
import type { WireForEachContainer, WireForEachContainerItem } from "@glide/fluent-components/dist/js/base-components";
import { ForEachContainer } from "@glide/fluent-components/dist/js/fluent-components";
import type { WireListComponentGeneric } from "@glide/fluent-components/dist/js/fluent-components-spec";
import type { InlineListComponentDescription } from "@glide/function-utils";
import {
    WireComponentKind,
    UIBackgroundStyle,
    UISize,
    UITitleStyle,
    WireActionResult,
    type WireSubsidiaryScreen,
} from "@glide/wire";
import { assert, definedMap } from "@glideapps/ts-necessities";

import { getSubComponents } from "../sub-components";
import {
    doTitleActionsForceComponentToShow,
    hydrateAndRegisterAction,
    inflateActions,
    inflateActionsWithTitles,
    inflateComponent,
    inflateComponentEnricher,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
} from "../wire/utils";
import { makeFluentArrayContentHandler } from "./fluent-array-handler";

export const forEachContainerFluentArrayContentHandler = makeFluentArrayContentHandler(
    ForEachContainer,
    (ib, desc, containingRowIB) => {
        if (containingRowIB === undefined) return undefined;

        const components = getSubComponents(desc as unknown as ComponentDescription);
        if (components === undefined || components.length === 0) return undefined;

        const [titleActionsHydrator] = inflateActionsWithTitles(containingRowIB, desc.titleActions, "title");
        const [titleGetter] = inflateStringProperty(containingRowIB, desc.title, true);
        const titleStyle = getEnumProperty<UITitleStyle>(desc.titleStyle) ?? UITitleStyle.Simple;
        const [titleImageGetter] = inflateStringProperty(containingRowIB, desc.titleImage, false);

        const itemAction = getActionProperty(desc.action);
        const itemActionGetter = definedMap(itemAction, x => inflateActions(ib, [x])) ?? WireActionResult.nothingToDo();

        const alignment = getEnumProperty<ContainerAlignment>(desc.alignment) ?? ContainerAlignment.center;
        const layout = getEnumProperty<ContainerLayout>(desc.layout) ?? ContainerLayout.Full;
        const containerStyle =
            getEnumProperty<UIBackgroundStyle.None | UIBackgroundStyle.Card>(desc.containerStyle) ??
            UIBackgroundStyle.None;

        const gridSize = getEnumProperty<UISize>(desc.gridSize) ?? UISize.Large;

        const componentIB = ib.makeInflationBackendForTables(ib.tables, ib.mutatingScreenKind);
        const hydrators = components.map(c => inflateComponent(componentIB, c, false));

        const componentEnricher = inflateComponentEnricher<WireForEachContainer>(
            ib,
            desc as unknown as InlineListComponentDescription
        );

        return makeSimpleWireTableComponentHydratorConstructor(
            ib,
            (thb, chb, searchActive, _dynamicFilterResult, rowBackends) => {
                if (chb === undefined) return undefined;

                const titleActions = titleActionsHydrator?.(chb, "") ?? [];
                const showIfEmpty = doTitleActionsForceComponentToShow(titleActions);

                const rows = thb.tableScreenContext.asArray();
                if (rows.length === 0 && !searchActive && !showIfEmpty) return undefined;

                const rowIDs: string[] = [];
                const itemForRowID: Record<string, WireForEachContainerItem> = {};

                let subComponentSubsidiaryScreen: WireSubsidiaryScreen | undefined = undefined;
                for (const row of rows) {
                    const rhb = rowBackends.get(row);

                    // FIXME: handle `allValid` and `haveValues`?
                    const { components: innerComponents, subsidiaryScreen: innerSubsidiaryScreen } =
                        thb.hydrateSubComponents(row.$rowID, row, hydrators, c => {
                            assert(c.kind === WireComponentKind.List);
                            const l = c as WireListComponentGeneric<ArrayScreenFormat>;
                            assert(l.format === ArrayScreenFormat.ForEachContainer);
                            return (l as WireForEachContainer).itemForRowID[row.$rowID]?.components ?? [];
                        });

                    rowIDs.push(row.$rowID);

                    itemForRowID[row.$rowID] = {
                        components: innerComponents,
                        action: hydrateAndRegisterAction("itemAction", itemActionGetter, rhb, false, undefined),
                    };

                    if (subComponentSubsidiaryScreen === undefined && innerSubsidiaryScreen !== undefined) {
                        subComponentSubsidiaryScreen = innerSubsidiaryScreen;
                    }
                }

                const component: WireForEachContainer = componentEnricher({
                    kind: WireComponentKind.List,
                    format: ArrayScreenFormat.ForEachContainer,
                    layout,
                    containerStyle,
                    rowIDs,
                    itemForRowID,
                    title: titleGetter(chb),
                    titleImage: titleImageGetter(chb),
                    titleStyle,
                    titleActions,
                    alignment,
                    gridSize,
                });

                return {
                    component,
                    hasValue: false,
                    isValid: true,
                    subsidiaryScreen: subComponentSubsidiaryScreen,
                };
            }
        );
    }
);
