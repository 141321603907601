import type { Corners } from "@glide/common-core/dist/js/components/image-types";
import { isFavoritedColumnName, type Description } from "@glide/type-schema";
import { type MutatingScreenKind, type PropertyDescription, getColumnProperty } from "@glide/app-description";
import type { TextSize } from "@glide/component-utils";
import type {
    WireAppAvatarOverlayComponent,
    WireAppOverlays,
    WireAppTagOverlayComponent,
    WireAppToggleIconOverlayComponent,
} from "@glide/fluent-components/dist/js/base-components";
import {
    type PropertyDescriptor,
    type PropertyTableGetter,
    EnumPropertyHandler,
    PropertySection,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { undefinedIfEmptyOrWhitespaceString } from "@glide/support";
import {
    type WireInflationBackend,
    type WireRowComponentHydrationBackend,
    WireComponentKind,
    type WireScreen,
} from "@glide/wire";

import { type WireStringGetter, inflateFavorite, inflateStringProperty } from "./wire/utils";

export interface OverlayContentDescription {
    readonly tagOverlayText: PropertyDescription | undefined;
    readonly avatarImage: PropertyDescription | undefined;
    readonly avatarCaption: PropertyDescription | undefined;
    readonly buttonOverlay: PropertyDescription | undefined;
}

export function defaultOverlayComponent() {
    return {
        tagOverlayText: undefined,
        avatarImage: undefined,
        avatarCaption: undefined,
        buttonOverlay: undefined,
    };
}

enum ButtonOverlay {
    None = "none",
    Favorite = "favorite",
}

const buttonOverlayPropertyHandler = new EnumPropertyHandler(
    { buttonOverlay: ButtonOverlay.None },
    "Button",
    "Button",
    [
        {
            value: ButtonOverlay.None,
            label: "—",
        },
        {
            value: ButtonOverlay.Favorite,
            label: "Favorite",
            icon: "co-favorite",
            columnWritten: isFavoritedColumnName,
        },
    ],
    PropertySection.Overlays,
    "small-images"
);

export function inflateOverlays(
    ib: WireInflationBackend,
    desc: Description & OverlayContentDescription,
    otherCaptionDesc: PropertyDescription | undefined,
    preferAvatarRight: boolean,
    tagCorners: Corners,
    textSize: TextSize
): (hb: WireRowComponentHydrationBackend) => { overlays: WireAppOverlays; subsidiaryScreen: WireScreen | undefined } {
    const [tagOverlayTextGetter, tagOverlayTextType] = inflateStringProperty(ib, desc.tagOverlayText, true);

    const [avatarImageGetter, avatarImageType] = inflateStringProperty(ib, desc.avatarImage, true);
    const [avatarCaptionGetter, avatarCaptionType] = inflateStringProperty(ib, desc.avatarCaption, true);

    const button = buttonOverlayPropertyHandler.getEnum(desc);
    const favoriteHydrator = button === ButtonOverlay.Favorite ? inflateFavorite(ib, undefined) : undefined;

    let otherCaptionGetter: WireStringGetter | undefined;
    if (otherCaptionDesc !== undefined) {
        const [getter, type] = inflateStringProperty(ib, otherCaptionDesc, true);
        if (type !== undefined) {
            otherCaptionGetter = getter;
        }
    }

    return hb => {
        let topLeft: WireAppTagOverlayComponent | undefined;
        if (tagOverlayTextType !== undefined) {
            const text = tagOverlayTextGetter(hb);
            if (text !== null && text !== "") {
                topLeft = {
                    kind: WireComponentKind.AppTagOverlay,
                    text,
                    corners: tagCorners,
                };
            }
        }

        let subsidiaryScreen: WireScreen | undefined;

        let topRight: WireAppToggleIconOverlayComponent | undefined;
        const favorite = favoriteHydrator?.(hb);
        if (favorite !== undefined) {
            topRight = {
                kind: WireComponentKind.AppToggleIconOverlay,
                value: favorite.editable,
                onToggle: favorite.onToggle,
            };
            subsidiaryScreen = favorite.subsidiaryScreen;
        }

        let bottomLeft: WireAppAvatarOverlayComponent | undefined;
        let bottomRight: WireAppAvatarOverlayComponent | undefined;
        if (otherCaptionGetter !== undefined) {
            bottomRight = {
                kind: WireComponentKind.AppAvatarOverlay,
                image: undefined,
                caption: otherCaptionGetter(hb) ?? "",
                textSize,
            };
        }
        if (avatarImageType !== undefined || avatarCaptionType !== undefined) {
            const overlay: WireAppAvatarOverlayComponent = {
                kind: WireComponentKind.AppAvatarOverlay,
                image: undefinedIfEmptyOrWhitespaceString(avatarImageGetter(hb) ?? ""),
                caption: avatarCaptionGetter(hb) ?? "",
                textSize,
            };
            if (preferAvatarRight && bottomRight === undefined) {
                bottomRight = overlay;
            } else {
                bottomLeft = overlay;
            }
        }

        return {
            overlays: {
                topLeft,
                topRight,
                bottomLeft,
                bottomRight,
            },
            subsidiaryScreen,
        };
    };
}

export function getOverlayPropertyHandlers(
    getIndirectTable: PropertyTableGetter | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined,
    captionLabel: string = "Caption"
): readonly PropertyDescriptor[] {
    return [
        buttonOverlayPropertyHandler.withGetIndirectTable(getIndirectTable),
        makeTextPropertyDescriptor("tagOverlayText", "Tag", "Enter tag", false, mutatingScreenKind, {
            emptyByDefault: true,
            propertySection: PropertySection.Overlays,
            preferredNames: ["tag"],
            getIndirectTable,
        }),
        makeTextPropertyDescriptor("avatarImage", "Avatar", "Enter URL", false, mutatingScreenKind, {
            emptyByDefault: true,
            propertySection: PropertySection.Overlays,
            preferredNames: ["avatar", "photo", "image", "picture", "pic"],
            preferredType: "image-uri",
            searchable: false,
            getIndirectTable,
        }),
        makeTextPropertyDescriptor("avatarCaption", captionLabel, "Enter avatar text", false, mutatingScreenKind, {
            emptyByDefault: true,
            propertySection: PropertySection.Overlays,
            preferredNames: ["caption", "description"],
            getIndirectTable,
        }),
    ];
}

export function getOverlaySearchProperties(desc: OverlayContentDescription): readonly string[] {
    const properties: string[] = [];

    const tag = getColumnProperty(desc.tagOverlayText);
    if (tag !== undefined) {
        properties.push(tag);
    }

    const avatarCaption = getColumnProperty(desc.avatarCaption);
    if (avatarCaption !== undefined) {
        properties.push(avatarCaption);
    }

    return properties;
}
