import type { AuthDefinition, OAuthProvider, Result } from "@glide/plugins";
import {
    base64URLEncodeArrayBuffer,
    checkString,
    digitsAndLetters,
    generateStringFromAlphabet,
    logDebug,
} from "@glide/support";
import { assertNever, definedMap, exceptionToString, hasOwnProperty, sleep } from "@glideapps/ts-necessities";
import * as t from "io-ts";
import uniq from "lodash/uniq";

import type { OAuthClientPlugin } from "./oauth-providers";
import { authPlugins } from "./oauth-providers";

const OAUTH_LOCAL_KEY = "plugin-oauth-state";

function addBearerToken(token: string, req: RequestInit): RequestInit {
    return {
        ...req,
        headers: {
            ...req.headers,
            Authorization: `Bearer ${token}`,
        },
    };
}

export function wrapFetch(
    f: typeof fetch,
    authPlugin: OAuthClientPlugin,
    getAccessToken: () => Promise<Result<string>>,
    refreshToken: () => Promise<Result<string>>,
    additionalCredential?: string
): typeof fetch {
    return async (req, init = {}) => {
        const url = typeof req === "string" ? req : req.url;
        const authDomainRoot = authPlugin.execution.authDomainRoot;
        const checkDomain =
            typeof authDomainRoot === "string" ? (u: string) => u.startsWith(authDomainRoot as string) : authDomainRoot;
        if (authDomainRoot === undefined || !checkDomain(url)) {
            return f(req, init);
        }

        const accessToken = await getAccessToken();
        let withAuth: RequestInit;
        if (!accessToken.ok) {
            withAuth = init;
        } else if (authPlugin.execution.addAuthorizationToRequest !== undefined) {
            withAuth = authPlugin.execution.addAuthorizationToRequest(accessToken.result, init, {
                additionalCredential,
            });
        } else {
            withAuth = addBearerToken(accessToken.result, init);
        }
        let res = await f(req, withAuth);

        const cloneResponse = res.clone();
        let requiresRefresh = await authPlugin.execution.checkResponseRequiresRefresh?.(cloneResponse);
        if (requiresRefresh === undefined) {
            requiresRefresh = cloneResponse.status === 401;
        }

        if (requiresRefresh) {
            // this means we need to refresh
            const newToken = await refreshToken();
            if (!newToken.ok) {
                return res;
            }
            res = await f(
                req,
                authPlugin.execution.addAuthorizationToRequest?.(newToken.result, init, { additionalCredential }) ??
                    addBearerToken(newToken.result, init)
            );
        }

        return res;
    };
}

export function getAuthPlugin(service: OAuthProvider): OAuthClientPlugin {
    for (const p of authPlugins) {
        if (p.canonicalName === service) {
            return p;
        }
    }

    throw new Error("Could not find plugin for service: " + service);
}

const localStoragePayloadCodec = t.intersection([
    t.type({
        state: t.string,
        authProvider: t.string,
        pluginID: t.string,
        orgID: t.string,
        appID: t.string,
        instanceID: t.string,
    }),
    t.partial({
        proofKey: t.string,
        forOwner: t.boolean,
        expectedProviderID: t.string,
        expectedCredentialID: t.string,
    }),
]);
type LocalStoragePayload = t.TypeOf<typeof localStoragePayloadCodec>;

function generateRandomString(): string {
    let randomString = "";
    for (let i = 0; i < 20; i++) {
        randomString += digitsAndLetters.charAt(Math.floor(Math.random() * digitsAndLetters.length));
    }
    return randomString;
}

// See IETF RFC 7636: Proof Key for Code Exchange by OAuth Public Clients
// OAuth providers are increasingly making this mandatory.
export type OAuthProofKeyChallengeMethod = "S256" | "plain";
// Note that this is actually a subset of the RFC 7636 alphabet, particularly
// missing the "~" character. Airtable's implementation incorrectly rejects
// this character. Any implementation of Proof Key for Code Exchange that
// provides an explicit size parameter must be aware of the negative
// implications for entropy. The default of 64 characters is greater than
// the minimum of 43 characters, so in most cases this isn't a concern.
const pkceAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._";
const pkceDefaultSize = 64;

async function generateEncodeProofKey(
    method: OAuthProofKeyChallengeMethod,
    size: number | undefined
): Promise<{ transformed: string; proofKey: string }> {
    // IETF RFC 7636 recommends between 43 and 128 characters, so 64 is a good default number.
    // Some implementations (*cough* Airtable) mandate a specific length, so 64 is the fallback.
    const proofKey = generateStringFromAlphabet(size ?? pkceDefaultSize, pkceAlphabet);
    if (method === "plain") {
        return { proofKey, transformed: proofKey };
    } else if (method === "S256") {
        const encoder = new TextEncoder();
        const bytes = await crypto.subtle.digest("sha-256", encoder.encode(proofKey));
        // See IETF RFC 7636 appendix A
        const transformed = base64URLEncodeArrayBuffer(bytes).replace(/=/g, "");
        return { proofKey, transformed };
    } else {
        return assertNever(method);
    }
}

interface OAuthStartProps {
    readonly redirectURL: string;
    readonly clientID: string;
    readonly pluginID: string;
    readonly orgID: string;
    readonly appID: string;
    readonly instanceID: string; // this is the ID of the instance of the plugin
    readonly proofKeyMethod: OAuthProofKeyChallengeMethod | undefined;
    readonly proofKeySize: number | undefined;
    readonly forceDeployedRedirect: boolean;
    readonly forOwner: boolean;
    readonly expectedProviderID?: string;
    readonly expectedCredentialID?: string;
    readonly openInNewWindow?: boolean;
}
/**
 * redirect the user to the website where they will grant permission for access
 *
 * This method is async because it attempts to generate random values using
 * the `crypto` API if the proofKeyMethod is passed.
 *
 * @throws error when plugin provider cannot be loaded
 */
export async function pluginOAuthStage1(authDefinition: AuthDefinition, props: OAuthStartProps): Promise<string> {
    const {
        clientID,
        redirectURL,
        pluginID,
        orgID,
        appID,
        instanceID,
        proofKeyMethod,
        proofKeySize,
        forceDeployedRedirect,
        expectedCredentialID,
        expectedProviderID,
        openInNewWindow,
        forOwner,
    } = props;
    const { scopes, provider } = authDefinition;
    let stateNonce = generateRandomString();
    if (forceDeployedRedirect) {
        stateNonce = `${stateNonce}.localhost`;
    }
    const authPlugin = getAuthPlugin(provider);
    const urlString = authPlugin.stage1.authorizationEndpoint;
    const url = new URL(urlString);
    url.searchParams.append("redirect_uri", redirectURL); // `https://go.glideapps.com/authorizeplugin/${OAuthProvider}`
    url.searchParams.append(
        authPlugin.stage1.scopeNameOverride ?? "scope",
        uniq([...scopes, ...authPlugin.stage1.mandatoryScopes]).join(" ")
    );
    url.searchParams.append("client_id", clientID);
    url.searchParams.append("state", stateNonce);

    let proofKey: string | undefined;
    if (proofKeyMethod !== undefined) {
        url.searchParams.append("code_challenge_method", proofKeyMethod);
        const result = await generateEncodeProofKey(proofKeyMethod, proofKeySize);
        proofKey = result.proofKey;
        url.searchParams.append("code_challenge", result.transformed);
    }

    for (const param of authPlugin.stage1.extraSearchParams) {
        url.searchParams.append(...param);
    }

    logDebug(`Opening ${url.href} for OAuth flow`);

    const payload: LocalStoragePayload = {
        authProvider: provider,
        state: stateNonce,
        pluginID,
        orgID,
        appID,
        instanceID,
        proofKey,
        expectedCredentialID,
        expectedProviderID,
        forOwner,
    };
    localStorage.setItem(OAUTH_LOCAL_KEY, JSON.stringify(payload));
    if (openInNewWindow !== false) {
        window.open(url, "_blank");
    } else {
        window.location.assign(url.href);
    }

    return stateNonce;
}

// receive the authorization grant from the user and exchange it for an access token
// The _blank tab has now been redirected back to glide, but we are still in the new tab.
// Our goal here is just to hand back to the caller the AuthorizationCode which will be
// passed to an authenticated firebase function to convert to an AccessToken.
//
// This is async so that we can sleep forever if we have to redirect to localhost.
interface OauthStageTwoProps {
    redirectToIfLocalhost: string | undefined;
}

export async function pluginOAuthStage2({ redirectToIfLocalhost }: OauthStageTwoProps): Promise<
    | {
          readonly pluginID: string;
          readonly authorizationCode: string;
          readonly orgID: string;
          readonly appID: string;
          readonly instanceID: string;
          readonly proofKey: string | undefined;
          readonly forOwner: boolean;
          readonly expectedCredentialID: string | undefined;
          readonly expectedProviderID: string | undefined;
      }
    | false
> {
    const { location } = window;
    const params = new URLSearchParams(location.search);
    const state = params.get("state") ?? undefined;
    const authorizationCode = params.get("code") ?? undefined;

    if (state === undefined || authorizationCode === undefined) return false;

    if (state.endsWith(".localhost") && redirectToIfLocalhost !== undefined) {
        const replacementBasis = new URL(redirectToIfLocalhost);
        const replacement = new URL(window.location.href);
        replacement.host = replacementBasis.host;
        replacement.protocol = replacementBasis.protocol;
        window.location.href = replacement.href;

        // At this point, we should be redirecting to localhost.
        while (true) {
            await sleep(1_000);
        }
    }

    const payloadString = localStorage.getItem(OAUTH_LOCAL_KEY);
    if (payloadString === null) return false;

    try {
        const payload = JSON.parse(payloadString);
        if (!localStoragePayloadCodec.is(payload)) {
            return false;
        }

        if (state !== payload.state) return false;

        localStorage.removeItem(OAUTH_LOCAL_KEY);

        return {
            authorizationCode,
            pluginID: payload.pluginID,
            orgID: payload.orgID,
            appID: payload.appID,
            instanceID: payload.instanceID,
            proofKey: payload.proofKey,
            forOwner: payload.forOwner ?? false,
            expectedCredentialID: payload.expectedCredentialID,
            expectedProviderID: payload.expectedProviderID,
        };
    } catch {
        return false;
    }
}

interface CodeToTokenResult {
    readonly accessToken: string;
    readonly idToken: string | undefined;
    readonly refreshToken: string | undefined;
    readonly staticID: string | undefined;
}

// FIXME: These could _really_ use some AbortControllers.
// HTTP endpoints fail open all the time.

export async function pluginOAuthStage3(
    authorizationCode: string,
    authPlugin: OAuthClientPlugin,
    clientID: string,
    clientSecret: string,
    redirectUri: string,
    proofKey: string | undefined,
    log?: (str: string) => void
): Promise<CodeToTokenResult | false> {
    // return the access token or false if there is an error
    const s = new URLSearchParams();
    s.append("code", authorizationCode);
    s.append("client_id", clientID);
    s.append("grant_type", "authorization_code");
    s.append("redirect_uri", redirectUri);

    if (authPlugin.stage3.addAuthorizationToRequest === undefined) {
        s.append("client_secret", clientSecret);
    }
    if (proofKey !== undefined) {
        s.append("code_verifier", proofKey);
    }

    let request: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
        },
        body: s,
    };

    if (authPlugin.stage3.addAuthorizationToRequest !== undefined) {
        request = authPlugin.stage3.addAuthorizationToRequest(request, { clientID, clientSecret });
    }

    // make the request
    const response = await fetch(authPlugin.stage3.tokenEndpoint, request);

    if (!response.ok) {
        // there was an error with the request, return false
        const error = await response.text();
        log?.(`Authentication flow failed: ${error}`);
        return false;
    }

    // parse the response as JSON
    try {
        let json = await response.json();
        if (json === undefined) {
            // there was an error parsing the JSON, return false
            return false;
        }

        if (authPlugin.stage3.responseRootPath !== undefined) {
            json = json[authPlugin.stage3.responseRootPath];
        }

        // return the access token
        return {
            accessToken: checkString(json.access_token),
            idToken: definedMap(json.id_token, checkString),
            refreshToken: definedMap(json.refresh_token, checkString),
            staticID:
                authPlugin.stage3.responseUserIDPath === undefined
                    ? undefined
                    : definedMap(json[authPlugin.stage3.responseUserIDPath], checkString),
        };
    } catch (e: unknown) {
        log?.(exceptionToString(e));
        return false;
    }
}

interface RefreshTokenError {
    error: string;
    errorDescription?: string;
    errorURI?: string;
}

export function isOAuthRefreshTokenError(res: CodeToTokenResult | RefreshTokenError): res is RefreshTokenError {
    return hasOwnProperty(res, "error") && typeof res.error === "string";
}

// We need to pass fetch in as fetchImpl instead of depending on a global implementation.
// Node before v18 doesn't have a global fetch implementation, and buggy code (ours, oops)
// keeps trying to monkey-patch in its own idea of what `fetch` should be into the
// global scope.
export async function performOAuthTokenRefresh(
    fetchImpl: typeof fetch,
    authPlugin: OAuthClientPlugin,
    clientID: string,
    clientSecret: string,
    refreshToken: string,
    scopes: readonly string[] | undefined,
    log?: (str: string, metadata?: Record<string, unknown>, exception?: unknown) => void,
    signal?: AbortSignal
): Promise<CodeToTokenResult | RefreshTokenError | "again" | undefined> {
    const s = new URLSearchParams();
    if (authPlugin.refreshAuthMethod === "post-auth" && authPlugin.stage3.addAuthorizationToRequest === undefined) {
        s.append("client_id", clientID);
        s.append("client_secret", clientSecret);
    }
    s.append("grant_type", "refresh_token");
    s.append("refresh_token", refreshToken);
    if (scopes !== undefined) {
        s.append("scope", scopes.join(" "));
    }

    log?.("refreshing oauth token", { endpoint: authPlugin.stage3.tokenEndpoint });

    let request: RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
        },
        body: s,
        signal,
    };
    if (authPlugin.stage3.addAuthorizationToRequest !== undefined) {
        request = authPlugin.stage3.addAuthorizationToRequest(request, { clientID, clientSecret });
    }
    const response = await fetchImpl(authPlugin.stage3.tokenEndpoint, request);

    if (!response.ok) {
        const error = await response.text();
        log?.("oauth token refresh failed", {
            status: response.status,
            status_text: response.statusText,
            response_body: error,
        });

        // If we are rate limited, we should just try again (after our backoff)
        if (response.status === 429) return "again";

        // We can sometimes get application/json; charset="utf-8". We just want the
        // "application/json" part.
        if (response.headers.get("Content-Type")?.split(";")?.[0] === "application/json") {
            try {
                const asJSON = JSON.parse(error);
                if (hasOwnProperty(asJSON, "error") && typeof asJSON.error === "string") {
                    return {
                        error: asJSON.error,
                        errorDescription: (asJSON as any).error_description as string | undefined,
                        errorURI: (asJSON as any).error_uri as string | undefined,
                    };
                }
            } catch (e: unknown) {
                // ignore
            }
        }
        return response.status >= 500 || response.status === 404 || response.status === 400 ? "again" : undefined;
    }

    const json = await response.json();

    if (hasOwnProperty(json, "error") && typeof json.error === "string") {
        log?.("oauth token refresh error", {
            status: response.status,
            status_text: response.statusText,
            response_body: json,
        });

        return {
            error: json.error,
            errorDescription: (json as any).error_description as string | undefined,
            errorURI: (json as any).error_uri as string | undefined,
        };
    }

    // Don't log successful response bodies. They contain oauth secrets we don't want in the logs.
    log?.("oauth token refreshed", {
        status: response.status,
        status_text: response.statusText,
    });

    return {
        accessToken: json.access_token as string,
        idToken: json.id_token as string | undefined,
        refreshToken: json.refresh_token as string | undefined,
        staticID:
            authPlugin.stage3.responseUserIDPath === undefined ? undefined : json[authPlugin.stage3.responseUserIDPath],
    };
}
