/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { maybeParseJSON } from "@glide/support";

const { Result } = glide;

export const plugin = glide.newPlugin({
    id: "discord",
    name: "Discord",
    description: "Send messages to your Discord server",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/discord.png",
    tier: "starter",
    auth: {
        provider: "discord",
        scopes: [],
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/discord",
});

const channel = glide.makeParameter({
    type: "string",
    name: "Channel ID",
    description:
        "Find your channel ID by copying the link to the channel. The ID is the 19-character string at the end of the URL.",
    placeholder: "e.g. 1234567890123456789",
    required: true,
    useTemplate: "withLabel",
});

const message = glide.makeParameter({
    type: "string",
    name: "Message",
    required: true,
    useTemplate: "withLabel",
});

plugin.addAction({
    id: "send-discord-message",
    name: "Send message to channel",
    description: "Send a message to a Discord channel",
    parameters: { channel, message },
    billablesConsumed: 1,
    execute: async (context, { channel, message }) => {
        const payload = {
            content: message,
        };

        try {
            const response: Response = await context.fetch(`https://discord.com/api/channels/${channel}/messages`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            const asText = await response.text();

            if (!response.ok) {
                return Result.FailFromHTTPStatus("Could not send message", response.status, {
                    status: response.status,
                    data: maybeParseJSON(asText),
                });
            } else {
                context.consumeBillable();
                return Result.Ok();
            }
        } catch {
            return Result.Fail("There was an unknown error");
        }
    },
});
