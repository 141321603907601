import { fireLongPressEvent, longPressTargetClass } from "./long-press-event";
import MobileDetect from "mobile-detect";

enum ScrollableDirection {
    None = 0,
    X = 1 << 1,
    Y = 1 << 2,
}

let initY = 0;
let el: HTMLElement | null;
let atTop = false;
let atBtm = false;
let overflowX = "";
let overflowY = "";
let style: CSSStyleDeclaration;
let targetScrollAxis = ScrollableDirection.None;
let finger: Touch;

export function disableContext() {
    document.addEventListener("contextmenu", event => {
        let target = event.target as HTMLElement | null;
        if (target !== null && (target.tagName === "VIDEO" || target.tagName === "AUDIO")) {
            event.preventDefault();
        }
        while (target !== null && target.classList !== undefined) {
            if (target.classList.contains(longPressTargetClass)) {
                event.preventDefault();
                fireLongPressEvent(target, true);
                break;
            }
            if (target.parentElement === undefined) break;
            target = target.parentElement;
        }
    });
}

export function disableBounce() {
    if (new MobileDetect(window.navigator.userAgent).is("Android")) return;
    window.addEventListener("touchstart", e => {
        el = e.target as HTMLElement;
        initY = e.changedTouches[0].pageY;
        atTop = false;
        atBtm = false;
        targetScrollAxis = ScrollableDirection.None;
        // Find scrollable element
        while (el !== document.body && el !== null) {
            style = getComputedStyle(el);
            if (style?.getPropertyValue === undefined) {
                el = el.parentElement;
                continue;
            }
            overflowY = style.getPropertyValue("overflow-y");
            overflowX = style.getPropertyValue("overflow-x");
            // Detect scrollable elements scroll axis
            if (el.scrollHeight > el.offsetHeight && (overflowY === "auto" || overflowY === "scroll")) {
                targetScrollAxis |= ScrollableDirection.Y;
                // Top or bottom end
                if (el.scrollTop < 1) {
                    atTop = true;
                } else if (el.scrollTop + el.offsetHeight === el.scrollHeight) {
                    atBtm = true;
                }
            } else if (el.scrollWidth > el.offsetWidth && (overflowX === "auto" || overflowX === "scroll")) {
                targetScrollAxis |= ScrollableDirection.X;
            }

            el = el.parentElement;
        }
    });
    window.addEventListener(
        "touchmove",
        e => {
            finger = e.changedTouches[0];

            if (!e.cancelable) return;

            const canScrollX = (targetScrollAxis & ScrollableDirection.X) === ScrollableDirection.X;
            const canScrollY = (targetScrollAxis & ScrollableDirection.Y) === ScrollableDirection.Y;

            if (canScrollY) {
                // implicitly this also happens if canScrollX is also true, this is on purpose.
                if ((atTop && initY < finger.pageY) || (atBtm && initY > finger.pageY)) {
                    e.preventDefault();
                }
            } else if (canScrollX) {
                if (Math.abs(finger.pageY - initY) > 10) e.preventDefault();
            } else {
                e.preventDefault();
            }
        },
        { passive: false, capture: true }
    );
}
