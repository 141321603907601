import type { RuntimeTheme } from "@glide/common";
import type { SignInTheme } from "@glide/common-components";
import { disabledProps, styled } from "@glide/common-components";

export const ButtonWrapper = styled.div<{ colorTheme: string; showFooter: boolean }>`
    display: flex;

    margin: 12px 0px;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    & button {
        cursor: pointer;
    }

    & button:not(.minimal) {
        background-color: ${p => p.colorTheme};
        ${p => (p.colorTheme === "white" ? `color: black` : `color: white`)};
    }

    & button.minimal {
        margin-top: 8px;
        ${p => (p.colorTheme === "white" ? `color: white` : `color: black`)};
    }

    & button:disabled {
        ${disabledProps}
    }

    & button.back {
        margin-top: 12px;
        color: ${p => p.theme.n0};
        background-color: ${p => (p.colorTheme === "white" ? `#FFFFFF3F` : `#0000003F`)};
    }
`;

interface Props extends React.PropsWithChildren {
    colorTheme: SignInTheme;
    useParallax: boolean;
    isBuilder: boolean;
    buttonOffset: number;
    previewMode: boolean;
}

// Hacky workaround to not get white-on-white or black-on-black.
// Relying on theme name is pretty ugly, but it doesn't seem to be an easy way of avoiding this.
function getBackgroundColor(colorTheme: SignInTheme, theme: RuntimeTheme): string {
    if (colorTheme === "black" && theme.overlayName.includes("PureWhiteTheme")) {
        return "#ffffff";
    }

    if (colorTheme === "white" && theme.overlayName.includes("PureBlackTheme")) {
        return "#000000";
    }

    return theme.primaryAccentColor;
}

export const CustomSignInStyle = styled.div<Props>`
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;

    ${p => (p.previewMode ? "pointer-events: none;" : "")}

    ${p => (p.colorTheme === "white" ? `color: white` : `color: black`)};

    &.accent {
        background-color: ${p => getBackgroundColor(p.colorTheme, p.theme)};
    }
    &.black {
        background-color: ${p => p.theme.bgFront};
    }
    &.white {
        background-color: ${p => p.theme.bgBack};
    }

    & .bc-background-image.dark {
        opacity: 0.5;
    }

    & .bc-background-image.gradient {
        mask-image: linear-gradient(to bottom, rgba(255, 0, 0, 1), rgba(255, 0, 0, 1), rgba(255, 0, 0, 0.5));
    }

    & .bc-background-image {
        position: absolute;
        top: 0;
        left: -30px;

        width: calc(100% + 60px);
        max-width: unset;
        height: 100%;

        object-fit: cover;

        transform: translateX(${p => (p.useParallax ? -30 : 0)}px);

        transition: 0.3s transform ease-out;
    }

    & .bc-content-container {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;

        > * {
            flex-shrink: 0;
        }

        width: 100%;

        --bottom-size: ${p => (p.buttonOffset === 0 ? "var(--safe-area-inset-bottom)" : "0px")};
        margin-bottom: var(--bottom-size);
        padding: 0 30px;
    }

    & ::-webkit-scrollbar {
        display: none;
    }

    & ${ButtonWrapper} {
        padding-bottom: ${p => p.buttonOffset}px;
    }

    & .bc-main-content {
        overflow-y: auto;
        max-width: 450px;

        height: 100%;
        width: 100%;

        display: flex;
    }

    & .bc-footer-wrap {
        width: 100%;

        display: flex;
        justify-content: center;

        padding-bottom: 12px;
    }
`;

export const ContentContainer = styled.div`
    position: relative;

    width: 100%;
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    justify-content: center;

    & form {
        width: 100%;
    }

    .overlay-container {
        display: grid;
        > * {
            overflow: hidden;
            grid-column: 1;
            grid-row: 1;
        }
    }

    .email-trans-enter {
        transform: translateX(150%);
    }

    .email-trans-enter-active {
        transform: translateX(0);
        transition: transform 250ms;
    }

    .email-trans-exit {
        transform: translateX(0);
    }

    .email-trans-exit-active {
        transform: translateX(-150%);
        transition: transform 250ms;
    }

    &.reverse {
        .email-trans-enter {
            transform: translateX(-150%);
        }

        .email-trans-enter-active {
            transform: translateX(0);
            transition: transform 250ms;
        }

        .email-trans-exit {
            transform: translateX(0);
        }

        .email-trans-exit-active {
            transform: translateX(150%);
            transition: transform 250ms;
        }
    }
`;

const TitleStyle = styled.div<{ fontSize: number }>`
    font-size: ${p => p.fontSize}px;
    line-height: 1.2em;
    font-weight: 900;
    word-break: break-word;

    margin-bottom: 12px;

    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;

    @media only screen and (max-height: 500px) {
        font-size: 48px;
        margin-bottom: 8px;
    }
`;

export function Title(props: { children: string | null | undefined }) {
    const { children } = props;
    let size = 64;

    if (children !== null && children !== undefined) {
        size = Math.min(64, Math.max(36, Math.round(450 / children.length)));
    }
    return <TitleStyle fontSize={size}>{children}</TitleStyle>;
}

export const Subtitle = styled.div`
    font-size: 17px;
    line-height: 1.2em;

    text-align: center;

    margin-bottom: 24px;

    @media only screen and (max-height: 500px) {
        font-size: 14px;
        margin-bottom: 12px;
    }
`;

export const Button = styled.button`
    height: 44px;
    width: 100%;

    border-radius: 6px;

    font-size: 17px;
    font-weight: 600;
`;

export const Input = styled.input<{ colorTheme: "white" | "black" }>`
    height: 46px;
    width: 100%;

    && {
        border: 2px ${p => (p.colorTheme === "white" ? `` : `black`)} solid;
        background-color: ${p => (p.colorTheme === "white" ? `#FFFFFF3F` : `#0000003F`)};
    }
    border-radius: 6px;

    padding: 0 12px;

    font-size: 18px;
    ${p => (p.colorTheme === "white" ? `color: white` : `color: black`)};

    ::placeholder {
        ${p => (p.colorTheme === "white" ? `color: white` : `color: black`)};
    }

    &.error {
        border: 2px ${p => p.theme.destroyColor} solid;
    }

    @media only screen and (max-height: 500px) {
        height: 36px;
        font-size: 14px;
    }
`;

export const AppTitle = styled.div`
    text-align: center;

    font-weight: bold;
`;

export const IconContainer = styled.div<{ size: number }>`
    position: relative;
    margin-top: var(--safe-area-inset-top);

    width: 100%;
    height: ${p => p.size}px;

    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
        object-fit: contain;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    @media only screen and (max-height: 500px) {
        display: none;
    }
`;

export const ErrorContainer = styled.div`
    width: 100%;

    margin-top: 8px;

    color: ${p => p.theme.destroyColor};
    font-size: 13px;
`;
