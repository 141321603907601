import type { SelectMode } from "@glide/common";
import type { LoadQuerySavedIdentity, SQLQueryBase } from "@glide/common-core";
import type { SubscriptionState } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import type { ComponentIndexes } from "@glide/common-core/dist/js/component-indexes";
import {
    type AppDescription,
    type BuilderAction,
    type ComponentDescription,
    ScreenDescriptionKind,
} from "@glide/app-description";
import type { RecomputeTiming } from "@glide/computation-model-types";
import {
    type AppAnalytics,
    type AppMetadata,
    type CompatibilityProblems,
    type Organization,
    type Owner,
    type TemplateSubmissions,
    type UserDataAndState,
    type WebhookIntegrationWithID,
    type ZapData,
    isUserData,
} from "@glide/common-core/dist/js/Database";
import type { TableName, TypeSchema, SourceMetadata } from "@glide/type-schema";
import type { Pricing } from "@glide/common-core/dist/js/Database/pricing";
import type { AllQuotaValues } from "@glide/common-core/dist/js/Database/quotas";
import type { TemplateTileDescription } from "@glide/common-core/dist/js/Database/templates-types";
import type { TileDescription as TileDescriptionType } from "@glide/common-core/dist/js/Database/tile-type";
import type { DeviceFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import type { HeaderSection, PasteboardState } from "@glide/function-utils";
import type { GridSelection } from "@glideapps/glide-data-grid";
import { handlerForArrayScreenFormat } from "@glide/generator/dist/js/array-screens";
import type { SearchedColumn } from "@glide/generator/dist/js/components/description-handlers";
import { getComponentAtIndexes } from "@glide/generator/dist/js/sub-components";
import type { EditedColumn } from "./tables-state";

export interface NCMPerformanceAnalysisPayload {
    shouldTrigger: boolean;
    type: "strict" | "deferred";
}
interface TablesState {
    readonly selectedTable: TableName | undefined;
    readonly editedColumn: EditedColumn | undefined;
    readonly showAllData: boolean;
    readonly isValid: boolean;
    readonly columnFilter: string;
    // A gridSelection can be a `string` if you select a column by its name.
    // We'll transform that into an actual GridSelection at consume time in `ncm-table-container`.
    readonly gridSelection: GridSelection | string | undefined;
    readonly triggerNCMPermanceAnalysis: NCMPerformanceAnalysisPayload;
    readonly ncmTimingStats: RecomputeTiming | undefined;
}
export type TileDescription = TileDescriptionType;

export interface PublishState {
    readonly isPublished: boolean;
    readonly isPublishingActive: boolean;
    readonly publishedAt: Date | undefined;
    // "again" means currently publishing, and after it's done, should be published again
    readonly publishing: boolean | "again";
    readonly appURL: string | undefined;
    readonly shortName: string | undefined;
    readonly customDomain: string | undefined;
    readonly desiredDomain: string | undefined;
    readonly error: string | undefined;
}

export type PublishStateOrLoading = PublishState | "loading";
export type AppMetadataOrLoading = AppMetadata | "loading";

export interface AppAndState {
    readonly sourcePad: SourcePadState;
    readonly serializedApp: AppDescription;
    readonly serial: number;
    readonly builderActions: Map<string, BuilderAction>;
    readonly reloadIssues: CompatibilityProblems | undefined;
    readonly publishState: PublishStateOrLoading;
    readonly appMetadata: AppMetadataOrLoading;
    readonly analytics: AppAnalytics | undefined;
    readonly zapierAPIKey: string | undefined;
    readonly zaps: readonly ZapData[];
    readonly webhooks: readonly WebhookIntegrationWithID[];
    readonly quotas: AllQuotaValues | undefined;
    // The user ID of the owner.  We use this to not try to save
    // another user's app that's opened by an admin, because that
    // would give an error from Firestore and reload the builder.
    readonly ownerID: string | undefined;
    readonly lastUpdated: Date | undefined;
}

export interface AppsAndState {
    [id: string]: AppAndState | "loading";
}

export interface TileDescriptionsState {
    [id: string]: TileDescription | TemplateTileDescription | "loading";
}

export interface TemplateStoreInfoForTemplate {
    readonly title: string;
    readonly author: string | undefined;
    readonly email: string | undefined;
    readonly pricing: Pricing;
}

export interface TemplateStoreState {
    [templateID: string]: TemplateStoreInfoForTemplate;
}

export interface SearchState extends SearchedColumn {
    readonly actionID?: string;
    readonly showSearch: boolean;
    readonly childColumnNames?: readonly string[];
}

export type OrganizationsState = readonly Organization[];

export type SubscriptionsState = readonly SubscriptionState[];

interface NewQueryState {
    queryName: string;
    queryText: string;
}

export interface ExistingQueryState {
    readonly queryName: string;
    readonly queryBase: SQLQueryBase;
    readonly savedIdentity: LoadQuerySavedIdentity;
    readonly sourceKind: string;
}

export interface RootState {
    apps: AppsAndState;
    templateStore: TemplateStoreState;
    tiles: TileDescriptionsState;
    userData: UserDataAndState;
    organizations: OrganizationsState;
    // FIXME: organizations: OrganizationsState | false;
    // Unfortunately too many code paths depend on it being
    // just an array, so to work around that, we use this flag.
    organizationsLoaded: boolean;
    subscriptions: SubscriptionsState;
    uiState: UIState;
    tables: TablesState;
    newComponents: ReadonlySet<string>;
    templateSubmissions: TemplateSubmissions;
    search: SearchState;
    pasteboard: PasteboardState;
    newQueries: Record<string, NewQueryState>;
    existingQueries: Record<string, ExistingQueryState | false>;
}

// also found in @glide/wire-renderers/wire-lib
export interface ComponentSelectorState {
    isOpen: boolean;
    targetIndexes: ComponentIndexes | undefined;
}

export interface LeftPanelState {
    shouldExpand: boolean;
    copyingSample: boolean;
}

export interface SelectedComponentPath {
    appID: string;
    screenName: string;
    indexes: ComponentIndexes;
    subcomponent?: string;
}

export interface UIState {
    readonly leftPanelState: LeftPanelState;
    readonly componentSelector: ComponentSelectorState;
    readonly previewPlatform: "iOS" | "Android";
    readonly deviceFormFactor: DeviceFormFactor;
    readonly maxPageWidth: number | undefined;
    readonly selectedComponent: SelectedComponentPath | undefined;
    readonly selectedColumn: SelectedComponentPath | undefined;
    readonly selectMode: SelectMode;
    readonly desiredHeaderSection: HeaderSection | undefined;
    readonly highlightComponent:
        | {
              readonly id: string;
              readonly doNotScrollIntoView?: boolean;
          }
        | undefined;
    readonly pagesCustomCssEnabled: boolean;
    readonly pagesCustomCssHasErrors: boolean;
}

export interface SourcePadState {
    readonly sourceMetadata: readonly SourceMetadata[];
    readonly spreadsheetName: string | undefined;
    readonly schema: TypeSchema;
}

export function getCurrentAppAndState(appID: string | undefined | null, apps: AppsAndState): AppAndState | undefined {
    if (appID === undefined || appID === null) return undefined;

    const maybeAppAndState: AppAndState | "loading" | undefined = apps[appID];
    if (maybeAppAndState === undefined || maybeAppAndState === "loading") {
        return undefined;
    }
    return maybeAppAndState;
}

export function getCurrentAppAndStateOrDefaults(appID: string | undefined, apps: AppsAndState): Partial<AppAndState> {
    if (appID === undefined) return {};
    const currentAppAndState = getCurrentAppAndState(appID, apps);
    if (currentAppAndState === undefined) return {};
    return currentAppAndState;
}

export function getAppOwner(
    appID: string | undefined,
    userData: UserDataAndState,
    organizations: OrganizationsState
): Owner | undefined {
    if (appID === undefined) return undefined;
    if (isUserData(userData) && userData.appIDs.indexOf(appID) >= 0) {
        return userData;
    }
    for (const org of organizations) {
        if (org.appIDs.indexOf(appID) >= 0) {
            return org;
        }
    }
    return undefined;
}

export function getComponentAtPath(app: AppDescription, path: SelectedComponentPath): ComponentDescription | undefined {
    const screen = app.screenDescriptions[path.screenName];
    if (screen === undefined) return undefined;

    if (
        screen.kind === ScreenDescriptionKind.Array &&
        handlerForArrayScreenFormat(screen.format)?.hasComponents !== true
    ) {
        // We are in an array screen without components. Any selection here is indicating for a "fake"
        // component because we have not yet ported to all free screens.
        return undefined;
    }
    const [component] = getComponentAtIndexes(screen, path.indexes);
    return component;
}

export interface NewQueryUpdate {
    sourceKind: string;
    sourceID: string;
    queryName: string;
    queryText: string;
}

export interface ExistingQueryUpdate {
    update: ExistingQueryState | false;
    queryID: string;
}
