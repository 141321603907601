import { setIsOnline } from "@glide/common-core/dist/js/hooks/use-network-status";
import { NetworkStatus } from "@glide/common-core/dist/js/network-status";
import { maybe, parseURL } from "@glide/support";
import once from "lodash/once";

let originOnline = true;
let databaseOnline = true;

export function setOriginOnline(isOriginOnline: boolean): void {
    // We only want to fire setIsOnline on actual changes to the state,
    // otherwise we'll end up with a bunch of spurious re-renders.
    if (originOnline === isOriginOnline) return;
    originOnline = isOriginOnline;
    if (originOnline) {
        setIsOnline(databaseOnline ? NetworkStatus.Online : NetworkStatus.Disconnecting);
    } else {
        setIsOnline(NetworkStatus.Offline);
    }
}

function setDatabaseOnline(isDatabaseOnline: boolean): void {
    // We only want to fire setIsOnline on actual changes to the state,
    // otherwise we'll end up with a bunch of spurious re-renders.
    if (databaseOnline === isDatabaseOnline) return;
    databaseOnline = isDatabaseOnline;

    // We only want to change network status from this failure if we think we have network connectivity.
    if (originOnline) {
        setIsOnline(databaseOnline ? NetworkStatus.Online : NetworkStatus.Disconnecting);
    }
}

const firestoreHostname = "firestore.googleapis.com";

class WrappingXHR extends XMLHttpRequest {
    private _openURL: string | undefined;

    constructor() {
        super();

        // Take note of the ?.() invocations here.
        // It seems that older Safari (confirmed on 12.1.1) shares its
        // event listeners in XMLHttpRequest... globally. Which isn't great.
        // And because of this, this._isFirestoreRequest is sometimes undefined.
        const errorHandler = () => {
            if (this._isFirestoreRequest?.() === true) {
                // If the status is set to something that's in the 1xx-4xx range, then we're actually online,
                // because we got a response from the server.  We only want to set the app to offline if the request
                // could not complete, so a status that's less than 100 or within the 5xx range.
                setDatabaseOnline(this.status >= 100 && this.status < 500);
            }
        };
        const successHandler = () => {
            if (this._isFirestoreRequest?.() === true) {
                setDatabaseOnline(true);
            }
        };
        super.addEventListener("error", errorHandler);
        // FIXME: Should we actually be doing this with timeout too?
        super.addEventListener("timeout", errorHandler);

        super.addEventListener("load", successHandler);
        super.addEventListener("progress", successHandler);
    }

    private _isFirestoreRequest() {
        const parsedUrl = parseURL(this._openURL ?? "");
        return parsedUrl !== undefined && parsedUrl.hostname === firestoreHostname;
    }

    public open(method: string, url: string, ...rest: any[]) {
        if (this._openURL === undefined) {
            this._openURL = url;
        }
        return super.open(method, url, rest?.[0], rest?.[1], rest?.[2]);
    }
}

const origFetch = fetch;

async function wrappingFetch(request: string | Request, options?: RequestInit) {
    const requestUrl = maybe(() => (typeof request === "string" ? request : request?.url), undefined);
    const parsedUrl = parseURL(requestUrl ?? "");
    try {
        const ret = await origFetch(request, options);
        if (parsedUrl?.hostname === firestoreHostname) {
            maybe(() => setDatabaseOnline(ret.status < 500), undefined);
        }
        return ret;
    } catch (e: unknown) {
        if (parsedUrl?.hostname === firestoreHostname) {
            maybe(() => setDatabaseOnline(false), undefined);
        }
        throw e;
    }
}

export const trapFirestoreHttpErrors = once(() => {
    if (window.XMLHttpRequest !== WrappingXHR) {
        window.XMLHttpRequest = WrappingXHR;
    }
    // Firebase SDK 9.x switches to fetch instead of XHR for Firestore.
    if (window.fetch !== wrappingFetch) {
        (window as any).fetch = wrappingFetch;
    }
});
