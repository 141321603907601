import tw from "twin.macro";
import { css } from "styled-components";

interface TableProps {
    className?: string;
    children?: React.ReactNode;
}

const Table: React.FC<TableProps> = p => {
    const { className, ...props } = p;
    return (
        <div
            css={css`
                margin: 0 calc(-1 * var(--container-x-pad));
            `}>
            <div
                tw="overflow-x-auto relative pb-0.5 w-full"
                css={css`
                    @supports selector(::-webkit-scrollbar) {
                        ::-webkit-scrollbar {
                            width: 8px;
                            height: 8px;
                            background-color: transparent;
                        }
                        ::-webkit-scrollbar-thumb {
                            ${tw`bg-n400 rounded-lg hover:bg-n500`}
                        }
                    }

                    padding: 0 calc(1 * var(--container-x-pad));
                `}>
                <table tw="w-full text-sm page-md:text-base" className={className} {...props} />
            </div>
        </div>
    );
};
Table.displayName = "Table";

const TableHeader: React.FC<TableProps> = p => {
    const { className, ...props } = p;
    return <thead tw="border-b border-border-base" className={className} {...props} />;
};
TableHeader.displayName = "TableHeader";

const TableBody: React.FC<TableProps> = p => {
    const { className, ...props } = p;
    return <tbody className={className} {...props} />;
};
TableBody.displayName = "TableBody";

interface TableRowProps extends TableProps {
    readonly onClick?: (e: React.SyntheticEvent) => void;
}

const TableRow: React.FC<TableRowProps> = p => {
    const { className, ...props } = p;
    return <tr className={className} tw="border-b transition-colors border-border-base last:border-0" {...props} />;
};
TableRow.displayName = "TableRow";

const TableHeaderCell: React.FC<TableProps> = p => {
    const { className, ...props } = p;
    return (
        <th
            className={className}
            tw="h-9 px-2 text-left align-middle font-semibold text-text-contextual-pale uppercase text-xs transition truncate [&:has([role=checkbox])]:pr-0"
            {...props}
        />
    );
};
TableHeaderCell.displayName = "TableHead";

interface TableCellProps
    extends React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> {}

const TableCell: React.FC<TableCellProps> = p => {
    const { className, ...props } = p;
    return (
        <td
            className={className}
            tw="p-1.5 align-middle leading-6 truncate text-text-base [&:has([role=checkbox])]:pr-0"
            {...props}
        />
    );
};
TableCell.displayName = "TableCell";

export { Table, TableHeader, TableBody, TableHeaderCell, TableRow, TableCell };
