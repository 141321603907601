import {
    type ComponentDescription,
    type ComponentKind,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getColumnProperty,
} from "@glide/app-description";
import {
    type TableGlideType,
    getTableColumn,
    isPrimitiveType,
    isSingleRelationType,
    isStringTypeOrStringTypeArray,
} from "@glide/type-schema";
import type { InputOutputTables, TitleDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    PropertySection,
    getCompoundColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { definedMap } from "@glideapps/ts-necessities";
import { makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";
import { rewriteColumn, rewritePrimitiveColumn } from "./rewrite-columns";

const ComponentKindCompound: ComponentKind = "compound";

// For array and map properties, the summary applies to their
// element types, or their element's element types, etc, i.e.,
// arrays and maps are followed deeply to get the summary.
interface CompoundComponentDescription extends ComponentDescription, TitleDescription {
    readonly propertyName: LegacyPropertyDescription;
    readonly caption: LegacyPropertyDescription | undefined;
}

function rewriteImageColumn(
    columnName: LegacyPropertyDescription,
    table: TableGlideType
): PropertyDescription | undefined {
    return rewriteColumn(columnName, table, isStringTypeOrStringTypeArray);
}

// This must return all summary properties, with missing ones as `undefined`,
// vs just leaving them out, because we spread this to override previous
// descriptions, so the now missing ones must be cleared.
function rewriteSummaryAfterReload(sd: TitleDescription, table: TableGlideType): TitleDescription | undefined {
    return {
        titleProperty: definedMap(sd.titleProperty, p => rewritePrimitiveColumn(p, table)),
        subtitleProperty: definedMap(sd.subtitleProperty, p => rewritePrimitiveColumn(p, table)),
        imageURLProperty: definedMap(sd.imageURLProperty, p => rewriteImageColumn(p, table)),
    };
}

export class CompoundComponentHandler extends ComponentHandlerBase<CompoundComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindCompound);
    }

    public rewriteAfterReload(
        desc: CompoundComponentDescription,
        { input }: InputOutputTables
    ): CompoundComponentDescription | undefined {
        const propertyName = getColumnProperty(desc.propertyName);
        if (propertyName === undefined) return undefined;
        const column = getTableColumn(input, propertyName);
        if (column === undefined || isPrimitiveType(column.type)) return undefined;

        const newDesc: CompoundComponentDescription = {
            kind: ComponentKindCompound,
            propertyName: desc.propertyName,
            caption: desc.caption,
            visibilityFilters: [],
            componentID: desc.componentID,
        };
        if (isSingleRelationType(column.type)) {
            Object.assign(newDesc, rewriteSummaryAfterReload(desc, input));
        }
        // FIXME: Support expanded summaries
        return newDesc;
    }

    public newComponent(): CompoundComponentDescription | undefined {
        return undefined;
    }

    public getDescriptor(
        _desc: CompoundComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Workflow",
            description: "Refers to a related row",
            img: "co-relation",
            group: "Basic",
            helpUrl: getDocURL("compoundComponent"),
            isLegacy: true,
            properties: [
                {
                    kind: PropertyKind.Column,
                    property: { name: "propertyName" },
                    label: "Value",
                    required: true,
                    editable: true,
                    searchable: false,
                    isDefaultCaption: true,
                    columnFilter: getCompoundColumnsSpec,
                    section: PropertySection.Data,
                },
                makeCaptionStringPropertyDescriptor("Data", false, mutatingScreenKind),
                ...this.getBasePropertyDescriptors(),
            ],
        };
    }

    public convertToPage() {
        return undefined;
    }
}
