import { assertNever, definedMap, isArray, assert, defined, mapFilterUndefined } from "@glideapps/ts-necessities";
import { mapMap } from "collection-utils";
import { generateImageFromSeed } from "@glide/common-core/dist/js/components/triangle-image";
import {
    type GroundValue,
    type KeyPath,
    type LoadedGroundValue,
    type LoadingValue,
    type PrimitiveValue,
    type Row,
    isLoadingValue,
    isPrimitive,
    isPrimitiveValue,
    Table,
    type Path,
    type RootPath,
    combinePaths,
    getSymbolicRepresentationForPath,
    type Computation,
    type Namespace,
    type QueryResolveInfo,
    type RootPathResolver,
    generateArrayOverlapQueryCondition,
    Query,
    type ComputationValueGetters,
    type ConditionValuePath,
    makeColumnPath,
} from "@glide/computation-model-types";
import {
    isNotEmpty,
    arrayForEach,
    asMaybeDate,
    asMaybeNumber,
    asMaybeString,
    asPrimitive,
    asString,
    isArrayValue,
    isRow,
    isTable,
    tableForEach,
    asJSONValue,
    getArrayItem,
} from "@glide/common-core/dist/js/computation-model/data";
import type { TableName, Formula, PrimitiveGlideTypeKind, FilterDataValue } from "@glide/type-schema";
import { formatDuration } from "@glide/common-core/dist/js/format-duration";
import { formatJSON } from "@glide/common-core/dist/js/format-json";
import { GlideDateTime } from "@glide/data-types";
import { formatNumberWithSpecification } from "@glide/generator/dist/js/format-number";
import {
    type FormatDurationSpecification,
    type FormatNumberSpecification,
    unparseMath,
} from "@glide/formula-specifications";
import { type TextTemplateToken, isEmptyOrUndefined, tokenizeTemplateString } from "@glide/support";
import {
    type Condition,
    type PathOrGroundValue,
    computeCondition,
    getPathsForCondition,
    getSymbolicRepresentationForCondition,
    getSymbolicRepresentationForPathOrGroundValue,
} from "./conditions";
import { type MathContext, MathInterpreter } from "./math-interpreter";
import { DateTimeAsyncParser } from "./parse-date-time";
import { convertToArrayOverlapKey } from "@glide/common-core/dist/js/computation-model/relation-keys";
import { isKeyRefObject } from "@glide/common-core/dist/js/components/data";

export class WithDefaultComputation implements Computation {
    constructor(private readonly _valuePath: Path, private readonly _fallbackPath: Path) {}

    public getPaths(): readonly Path[] {
        return [this._valuePath, this._fallbackPath];
    }

    public compute(ns: RootPathResolver, context: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const value = valueGetters.getValueAt(ns, context, this._valuePath);

        if (!isLoadingValue(value) && isNotEmpty(value)) {
            return value;
        }

        const fallback = valueGetters.getValueAt(ns, context, this._fallbackPath);
        if (isLoadingValue(fallback) || !isNotEmpty(fallback)) {
            return value;
        }

        return fallback;
    }

    public get symbolicRepresentation(): string {
        return `(with-default ${getSymbolicRepresentationForPath(
            this._valuePath
        )} fallback: ${getSymbolicRepresentationForPath(this._fallbackPath)})`;
    }
}

export class StaticTextTemplateComputation implements Computation {
    constructor(private readonly _tokens: readonly TextTemplateToken<Path>[]) {}

    public getPaths(): readonly Path[] {
        return mapFilterUndefined(this._tokens, t => (!t.isText ? t.value : undefined));
    }

    public compute(ns: RootPathResolver, context: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const parts: string[] = [];
        for (const t of this._tokens) {
            if (t.isText) {
                parts.push(t.text);
            } else {
                const v = valueGetters.getValueAt(ns, context, t.value);
                if (isLoadingValue(v)) return v;
                if (!isPrimitive(v)) continue;
                const s = asMaybeString(v);
                if (s === undefined) continue;
                parts.push(s);
            }
        }
        return parts.join("");
    }

    public get symbolicRepresentation(): string {
        const parts: string[] = [];
        for (const t of this._tokens) {
            if (t.isText) {
                parts.push(JSON.stringify(t.text));
            } else {
                parts.push(getSymbolicRepresentationForPath(t.value));
            }
        }
        return "(template " + parts.join("+") + ")";
    }
}

export class DynamicTextTemplateComputation implements Computation {
    constructor(private readonly _templatePath: Path, private readonly _replacementPaths: ReadonlyMap<string, Path>) {}

    public getPaths(): readonly Path[] {
        return [this._templatePath, ...this._replacementPaths.values()];
    }

    public compute(ns: RootPathResolver, context: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const templateValue = valueGetters.getValueAt(ns, context, this._templatePath);
        if (isLoadingValue(templateValue)) return templateValue;
        const template = asMaybeString(asPrimitive(templateValue));
        if (template === undefined) return undefined;

        let loadingValue: LoadingValue | undefined;
        const tokens = tokenizeTemplateString(template, Array.from(this._replacementPaths.keys()));
        const parts = tokens.map(t => {
            if (t.isText) {
                return t.text;
            } else {
                const path = defined(this._replacementPaths.get(t.value));
                const v = valueGetters.getValueAt(ns, context, path);
                if (isLoadingValue(v)) {
                    loadingValue = v;
                    return;
                }
                return asString(v);
            }
        });
        if (loadingValue !== undefined) return loadingValue;
        return parts.join("");
    }

    public get symbolicRepresentation(): string {
        return `(template ${getSymbolicRepresentationForPath(this._templatePath)} ${Array.from(this._replacementPaths)
            .map(([k, p]) => `${k}: ${getSymbolicRepresentationForPath(p)}`)
            .join(" ")})`;
    }
}

export class FormatNumberComputation implements Computation {
    constructor(private readonly _numberPath: Path, private readonly _spec: FormatNumberSpecification) {}

    public getPaths(): readonly Path[] {
        return [this._numberPath];
    }

    public formatValue(numberValue: LoadedGroundValue): string | undefined {
        const n = asMaybeNumber(numberValue);
        if (n === undefined) return undefined;
        return formatNumberWithSpecification(this._spec, n);
    }

    public compute(ns: RootPathResolver, context: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const value = valueGetters.getValueAt(ns, context, this._numberPath);
        if (isLoadingValue(value)) {
            return value;
        }
        return this.formatValue(value);
    }

    public get symbolicRepresentation(): string {
        return `(format-number ${getSymbolicRepresentationForPath(this._numberPath)})`;
    }
}

export class FormatDurationComputation implements Computation {
    constructor(private readonly _durationPath: Path, _spec: FormatDurationSpecification) {}

    public getPaths(): readonly Path[] {
        return [this._durationPath];
    }

    public formatValue(durationValue: LoadedGroundValue): string | undefined {
        const n = asMaybeNumber(durationValue);
        if (n === undefined || isNaN(n)) return undefined;

        return formatDuration(n);
    }

    public compute(ns: RootPathResolver, context: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const value = valueGetters.getValueAt(ns, context, this._durationPath);
        if (isLoadingValue(value)) {
            return value;
        }
        return this.formatValue(value);
    }

    public get symbolicRepresentation(): string {
        return `(format-duration ${getSymbolicRepresentationForPath(this._durationPath)})`;
    }
}

export class FormatJSONComputation implements Computation {
    constructor(private readonly _jsonPath: Path) {}

    public getPaths(): readonly Path[] {
        return [this._jsonPath];
    }

    public formatValue(jsonValue: LoadedGroundValue): string | undefined {
        const value = asJSONValue(jsonValue);
        if (value === undefined) return undefined;

        return formatJSON(value);
    }

    public compute(ns: RootPathResolver, context: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const value = valueGetters.getValueAt(ns, context, this._jsonPath);
        if (isLoadingValue(value)) {
            return value;
        }
        return this.formatValue(value);
    }

    public get symbolicRepresentation(): string {
        return `(format-json ${getSymbolicRepresentationForPath(this._jsonPath)})`;
    }
}

type Formatter = FormatNumberComputation | FormatDurationComputation | FormatJSONComputation;

export class FormatArrayComputation implements Computation {
    constructor(private readonly _path: Path, readonly _formatter: Formatter) {}

    public getPaths(): readonly Path[] {
        return [this._path];
    }

    public compute(ns: RootPathResolver, context: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const v = valueGetters.getValueAt(ns, context, this._path);
        if (isLoadingValue(v)) return v;

        const result: PrimitiveValue[] = [];
        if (isArrayValue(v)) {
            arrayForEach(v, a => {
                const formatted = this._formatter.formatValue(a);

                if (formatted === undefined || formatted === "") return;
                if (!isPrimitive(formatted)) return;
                result.push(formatted);
            });
        }
        return result;
    }

    public get symbolicRepresentation(): string {
        return `(format-array ${getSymbolicRepresentationForPath(this._path)})`;
    }
}

export class GenerateImageComputation implements Computation {
    constructor(private readonly _seedPath: Path, private readonly _isMesh: boolean) {}

    public getPaths(): readonly Path[] {
        return [this._seedPath];
    }

    public compute(
        resolver: RootPathResolver,
        context: GroundValue,
        valueGetters: ComputationValueGetters
    ): GroundValue {
        const seedValue = valueGetters.getValueAt(resolver, context, this._seedPath);
        if (isLoadingValue(seedValue)) return seedValue;
        const seed = asString(seedValue);
        if (seed === "") return undefined;
        return generateImageFromSeed(seed, this._isMesh);
    }

    public get symbolicRepresentation(): string {
        return `(generate-image ${getSymbolicRepresentationForPath(this._seedPath)})`;
    }
}

export class MathComputation extends MathInterpreter implements Computation {
    constructor(
        formula: Formula,
        private readonly _variablePaths: ReadonlyMap<string, [Path, PrimitiveGlideTypeKind]>
    ) {
        super(
            formula,
            mapMap(_variablePaths, ([, k]) => k)
        );
    }

    public getPaths(): readonly Path[] {
        return Array.from(this._variablePaths.values()).map(([p]) => p);
    }

    public compute(
        ns: RootPathResolver,
        context: GroundValue,
        valueGetters: ComputationValueGetters,
        setAllDirty: () => void
    ): GroundValue {
        const mathContext: MathContext = {
            setAllDirty,
            lookupVariable: n => {
                const p = this._variablePaths.get(n);
                if (p === undefined) return undefined;
                return valueGetters.getValueAt(ns, context, p[0]);
            },
        };
        return this.eval(mathContext);
    }

    public get symbolicRepresentation(): string {
        return `(math ${unparseMath(this._formula)} ${Array.from(this._variablePaths)
            .map(([k, [p]]) => `${k}: ${getSymbolicRepresentationForPath(p)}`)
            .join(" ")})`;
    }
}

export interface IfThenElseClause {
    readonly condition: Condition<ConditionValuePath>;
    readonly then: PathOrGroundValue<ConditionValuePath>;
}

export class IfThenElseComputation extends DateTimeAsyncParser implements Computation {
    constructor(
        private readonly _clauses: readonly IfThenElseClause[],
        private readonly _else: PathOrGroundValue<ConditionValuePath>
    ) {
        super();
    }

    public getPaths(): readonly Path[] {
        const paths: Path[] = [];

        for (const c of this._clauses) {
            assert(getPathsForCondition(c.condition, true).length === 0);
            paths.push(...getPathsForCondition(c.condition, false));
            if (c.then.isPath) paths.push(c.then.path.path);
        }
        if (this._else.isPath) paths.push(this._else.path.path);

        return paths;
    }

    public compute(
        ns: Namespace,
        context: GroundValue,
        valueGetters: ComputationValueGetters,
        setAllDirty: () => void,
        qri: QueryResolveInfo
    ): GroundValue {
        const get = valueGetters.makePathOrGroundValueGetter(ns, context, undefined);

        const resolveQuery = (v: GroundValue, p: ConditionValuePath) =>
            valueGetters.resolveQueryWithFixup(ns, v, p.path, context, qri.contextPath, qri.handler, true);

        for (const c of this._clauses) {
            const result = computeCondition(c.condition, get, v => this.parseDateTime(v, setAllDirty), resolveQuery);
            if (isLoadingValue(result)) return result;
            // `undefined` defaults to `false`
            if (result === true) {
                return c.then.isPath ? get(c.then.path) : c.then.value;
            }
        }
        return this._else.isPath ? get(this._else.path) : this._else.value;
    }

    public get symbolicRepresentation(): string {
        return `(if ${this._clauses
            .map(
                c =>
                    `(if: ${getSymbolicRepresentationForCondition(
                        c.condition
                    )} then: ${getSymbolicRepresentationForPathOrGroundValue(c.then, p => p.path)})`
            )
            .join(" ")} else: ${getSymbolicRepresentationForPathOrGroundValue(this._else, p => p.path)})`;
    }
}

export class SplitStringComputation implements Computation {
    constructor(private readonly _inputPath: Path, private readonly _separatorPath: Path) {}

    public getPaths(): readonly Path[] {
        return [this._inputPath, this._separatorPath];
    }

    public compute(ns: RootPathResolver, context: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const inputValue = valueGetters.getValueAt(ns, context, this._inputPath);
        if (isLoadingValue(inputValue)) return inputValue;
        const input = asMaybeString(inputValue) ?? "";

        const separatorValue = valueGetters.getValueAt(ns, context, this._separatorPath);
        if (isLoadingValue(separatorValue)) return separatorValue;
        const separator = asMaybeString(separatorValue) ?? "";

        return input
            .split(separator)
            .map(s => s.trim())
            .filter(s => s !== "");
    }

    public get symbolicRepresentation(): string {
        return `(split-string ${getSymbolicRepresentationForPath(
            this._inputPath
        )} by: ${getSymbolicRepresentationForPath(this._separatorPath)})`;
    }
}

export class MakeArrayComputation implements Computation {
    constructor(private readonly _paths: readonly Path[]) {}

    public getPaths(): readonly Path[] {
        return this._paths;
    }

    public compute(ns: RootPathResolver, context: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        let loadingValue: LoadingValue | undefined;
        const result: PrimitiveValue[] = [];

        function push(v: GroundValue) {
            if (isLoadingValue(v)) {
                loadingValue = v;
                return;
            }

            if (isArrayValue(v)) {
                arrayForEach(v, push);
                return;
            }

            if (v === undefined || v === "") return;
            if (!isPrimitive(v)) return;
            result.push(v);
        }

        for (const p of this._paths) {
            push(valueGetters.getValueAt(ns, context, p));
        }

        if (loadingValue !== undefined) return loadingValue;
        return result;
    }

    public get symbolicRepresentation(): string {
        return `(make-array ${this._paths.map(getSymbolicRepresentationForPath).join(" ")})`;
    }
}

export class ConstructURLComputation implements Computation {
    constructor(
        private readonly _schemePath: Path,
        private readonly _hostPath: Path,
        private readonly _pathPath: Path,
        private readonly _queryParameterPaths: ReadonlyMap<string, Path>
    ) {}

    public getPaths(): readonly Path[] {
        return [this._schemePath, this._hostPath, this._pathPath, ...Array.from(this._queryParameterPaths.values())];
    }

    public compute(ns: RootPathResolver, context: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const hostValue = valueGetters.getValueAt(ns, context, this._hostPath);
        if (isLoadingValue(hostValue)) return hostValue;
        const host = definedMap(hostValue, asString);
        if (isEmptyOrUndefined(host)) return undefined;

        const schemeValue = valueGetters.getValueAt(ns, context, this._schemePath);
        if (isLoadingValue(schemeValue)) return schemeValue;
        let scheme = definedMap(schemeValue, asString)?.trim();
        if (scheme === undefined || scheme === "") {
            scheme = "https";
        }

        const pathValue = valueGetters.getValueAt(ns, context, this._pathPath);
        if (isLoadingValue(pathValue)) return pathValue;
        let path = definedMap(pathValue, asString)?.trim();
        if (isEmptyOrUndefined(path)) {
            path = "/";
        } else if (!path.startsWith("/")) {
            path = "/" + path;
        }

        let loadingValue: LoadingValue | undefined;
        const query = mapFilterUndefined(this._queryParameterPaths.entries(), ([k, p]) => {
            const v = valueGetters.getValueAt(ns, context, p);
            if (isLoadingValue(v)) {
                loadingValue = v;
                return undefined;
            }
            const s = definedMap(v, asString);
            if (s === undefined) return undefined;
            return `${encodeURIComponent(k)}=${encodeURIComponent(s)}`;
        }).join("&");
        if (loadingValue !== undefined) return loadingValue;

        let url = `${scheme}://${host}${path}`;
        if (query.length > 0) {
            url = url + "?" + query;
        }

        return url;
    }

    public get symbolicRepresentation(): string {
        return `(construct-url scheme: ${getSymbolicRepresentationForPath(
            this._schemePath
        )} host: ${getSymbolicRepresentationForPath(this._hostPath)} path: ${getSymbolicRepresentationForPath(
            this._pathPath
        )} ${Array.from(this._queryParameterPaths)
            .map(([k, p]) => `${k}: ${getSymbolicRepresentationForPath(p)}`)
            .join(" ")} )`;
    }
}

// ##singleLookupViaCombine:
// FIXME: Do this via combine instead, clearing the whole table.
export class SingleLookupComputation implements Computation {
    // `_columnInRelationTablePath` is only here to dirty us if the looked-up
    // column changes.
    constructor(
        private readonly _relationPath: Path,
        private readonly _columnInRelationPath: KeyPath,
        private readonly _columnInRelationTablePath: RootPath | undefined
    ) {}

    public getPaths(): readonly Path[] {
        if (this._columnInRelationTablePath === undefined) {
            return [this._relationPath];
        } else {
            // We only need to recompute if we get dirt from the looked-up
            // column.  This is checked via ##ncmFilterLookupDirt.
            const columnInRelationTablePath = combinePaths(
                this._columnInRelationTablePath,
                makeColumnPath(this._columnInRelationPath.key)
            );
            return [this._relationPath, columnInRelationTablePath];
        }
    }

    // FIXME: This is hella way too complex
    public compute(
        ns: Namespace,
        row: GroundValue,
        valueGetters: ComputationValueGetters,
        _setAllDirty: () => void,
        qri: QueryResolveInfo
    ): GroundValue {
        const relationValue = valueGetters.getValueAt(ns, row, this._relationPath);
        const relation = valueGetters.resolveQueryWithFixup(
            ns,
            relationValue,
            this._relationPath,
            row,
            qri.contextPath,
            qri.handler,
            true
        );
        return valueGetters.follow(relation, this._columnInRelationPath);
    }

    public get symbolicRepresentation(): string {
        return `(lookup ${getSymbolicRepresentationForPath(
            this._columnInRelationPath
        )} from: ${getSymbolicRepresentationForPath(this._relationPath)})`;
    }
}

export enum ModifyDateKind {
    StartOfDay = "start-of-day",
    EndOfDay = "end-of-day",
}

export class ModifyDateComputation implements Computation {
    constructor(private readonly _datePath: Path, private readonly _kind: ModifyDateKind) {}

    public getPaths(): readonly Path[] {
        return [this._datePath];
    }

    public compute(ns: Namespace, row: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const dateValue = valueGetters.getValueAt(ns, row, this._datePath);
        if (isLoadingValue(dateValue)) return dateValue;

        const date = asMaybeDate(dateValue);
        if (date === undefined) return undefined;

        switch (this._kind) {
            case ModifyDateKind.StartOfDay:
                return date.localStartOfDay();
            case ModifyDateKind.EndOfDay:
                return date.localEndOfDay();
            default:
                return assertNever(this._kind);
        }
    }

    public get symbolicRepresentation(): string {
        return `(${this._kind} ${getSymbolicRepresentationForPath(this._datePath)})`;
    }
}

export class MakeTimeZoneAgnosticComputation implements Computation {
    constructor(private readonly _dateTimePath: Path) {}

    public getPaths(): readonly Path[] {
        return [this._dateTimePath];
    }

    public compute(ns: Namespace, row: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const dateValue = valueGetters.getValueAt(ns, row, this._dateTimePath);
        if (!(dateValue instanceof GlideDateTime)) return dateValue;

        return dateValue.toOriginTimeZoneAgnostic();
    }

    public get symbolicRepresentation(): string {
        return `(make-time-zone-agnostic ${getSymbolicRepresentationForPath(this._dateTimePath)})`;
    }
}

export enum ForwardingKind {
    LookupPrimitive = "lookup-primitive",
    LookupRow = "lookup-row",
    SingleValue = "single-value",
}

export class GlobalGuardedForwardComputation implements Computation {
    constructor(
        private readonly _forwardeePath: RootPath,
        private readonly _arbiterPath: Path,
        private readonly _kind: ForwardingKind
    ) {}

    public getPaths(): readonly Path[] {
        return [this._forwardeePath, this._arbiterPath];
    }

    public compute(
        ns: Namespace,
        row: GroundValue,
        valueGetters: ComputationValueGetters,
        _setAllDirty: () => void,
        qri: QueryResolveInfo
    ): GroundValue {
        const arbiterValue = valueGetters.getValueAt(ns, row, this._arbiterPath);
        const arbiter = valueGetters.resolveQueryWithFixup(
            ns,
            arbiterValue,
            this._arbiterPath,
            row,
            qri.contextPath,
            qri.handler,
            // We don't support multi-lookups in queryables, so we resolve
            // this as a row.
            // FIXME: what to do here?
            true
        );

        if (isLoadingValue(arbiter)) return arbiter;

        const forwardee = valueGetters.getValueAt(ns, row, this._forwardeePath);
        if (isLoadingValue(forwardee)) return forwardee;

        if (this._kind === ForwardingKind.SingleValue) {
            if (arbiter === undefined) return undefined;
            if (isTable(arbiter) && arbiter.size === 0) return undefined;
            return forwardee;
        }

        if (isRow(arbiter)) {
            return forwardee;
        } else if (isTable(arbiter)) {
            const tableResult = new Map<string, Row>();
            const primitiveResults: PrimitiveValue[] = [];
            tableForEach(arbiter, false, () => {
                if (forwardee === undefined) return;
                if (isRow(forwardee)) {
                    tableResult.set(forwardee.$rowID, forwardee);
                } else if (isArrayValue(forwardee)) {
                    arrayForEach(forwardee, v => {
                        if (v === undefined) return;
                        if (isPrimitiveValue(v)) {
                            primitiveResults.push(v);
                        }
                    });
                } else if (isPrimitiveValue(forwardee)) {
                    primitiveResults.push(forwardee);
                }
            });
            if (this._kind === ForwardingKind.LookupRow) {
                return new Table(tableResult);
            } else {
                return primitiveResults;
            }
        }

        return undefined;
    }

    public get symbolicRepresentation(): string {
        return `(guarded-forward ${getSymbolicRepresentationForPath(
            this._forwardeePath
        )} arbiter: ${getSymbolicRepresentationForPath(this._arbiterPath)} kind: ${this._kind})`;
    }
}

function getArrayOverlapKeys(v: GroundValue, sourceKeyIsArray: boolean): LoadingValue | readonly FilterDataValue[] {
    if (isLoadingValue(v)) {
        return v;
    } else if (isArray(v)) {
        const a: FilterDataValue[] = [];
        for (let i = 0; i < v.length; i++) {
            const s = convertToArrayOverlapKey(getArrayItem(v, i));
            if (isLoadingValue(s)) return s;
            if (s !== undefined) {
                a.push(s);
            }
        }
        return a;
    } else if (isKeyRefObject(v)) {
        const s = convertToArrayOverlapKey(v.$value);
        if (isLoadingValue(s)) return s;
        if (s === undefined) return [];
        return [s];
    } else if (sourceKeyIsArray && v === undefined) {
        return [];
    } else {
        const s = convertToArrayOverlapKey(v);
        if (isLoadingValue(s)) return s;
        if (s === undefined) return [];
        return [s];
    }
}

export class QueryRelationComputation implements Computation {
    constructor(
        private readonly _hostKeyPath: Path,
        private readonly _queryTableName: TableName,
        private readonly _queryColumnName: string,
        private readonly _isMulti: boolean
    ) {}

    public getPaths(): readonly Path[] {
        return [this._hostKeyPath];
    }

    public compute(ns: Namespace, row: GroundValue, valueGetters: ComputationValueGetters): GroundValue {
        const hostKeyValue = valueGetters.getValueAt(ns, row, this._hostKeyPath);
        const maybeKeys = getArrayOverlapKeys(hostKeyValue, true);
        if (isLoadingValue(maybeKeys)) return maybeKeys;
        const keys = maybeKeys.filter(s => s !== "");
        if (keys.length === 0) return undefined;
        const condition = generateArrayOverlapQueryCondition(this._queryColumnName, keys);
        if (condition === undefined) return undefined;
        let query = new Query(this._queryTableName).withCondition({ ...condition, negated: false });
        if (!this._isMulti) {
            query = query.withLimit(1);
        }
        return query;
    }

    public get symbolicRepresentation(): string {
        return `(query-relation ${getSymbolicRepresentationForPath(this._hostKeyPath)} target: ${
            this._queryTableName.name
        }->${this._queryColumnName} multi: ${this._isMulti})`;
    }
}
