import { asAppLoginTokenContainer } from "@glide/common-core";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import {
    getEmailKey,
    getLoginTokenKey,
    getPasswordForEmailKey,
    localStorageGetItem,
    localStorageRemoveItem,
    localStorageSetItem,
} from "@glide/support";

export interface LocalEmailPinCredentials {
    email: string;
    emailPassword: string | undefined;
}

export function getEmailPinCredentialsFromStorage(appID: string): LocalEmailPinCredentials | undefined {
    const email = localStorageGetItem(getEmailKey(appID));
    const emailPassword = localStorageGetItem(getPasswordForEmailKey(appID));
    if (email === undefined) {
        return undefined;
    }
    return { email, emailPassword };
}

export function getLoginTokenFromStorage(appID: string): AppLoginTokenContainer | undefined {
    const maybeToken = localStorageGetItem(getLoginTokenKey(appID));
    if (maybeToken === undefined) return undefined;

    try {
        return asAppLoginTokenContainer(JSON.parse(maybeToken));
    } catch {
        return undefined;
    }
}

export function setEmailPinCredentialsFromStorage(appID: string, emailPinCredentials: LocalEmailPinCredentials) {
    const { email, emailPassword } = emailPinCredentials;
    localStorageSetItem(getEmailKey(appID), email);
    if (emailPassword !== undefined) {
        localStorageSetItem(getPasswordForEmailKey(appID), emailPassword);
    }
}

export function setLoginTokenFromStorage(appID: string, loginToken: AppLoginTokenContainer) {
    localStorageSetItem(getLoginTokenKey(appID), JSON.stringify(loginToken));
}

export function clearExistingUserFromStorage(appID: string) {
    localStorageRemoveItem(getEmailKey(appID));
    localStorageRemoveItem(getPasswordForEmailKey(appID));
    localStorageRemoveItem(getLoginTokenKey(appID));
}
