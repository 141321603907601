import type { OverlayTheme } from "./overlay-theme";

export const TabletMaxListWidth = 700;

export const tabletOverlay: OverlayTheme = {
    overlayName: "TabletTheme",

    builderHeight: 768,
    builderWidth: 1024,

    scrollView: {
        backgroundColor: "_baseColor",
        marginTop: 16,
        marginLeft: 0,
        borderRadius: 10,
        paddingTop: 0,
    },

    tileItem: {
        padding: 5,
        borderRadius: 6,
        selectedColor: "rgba(0, 0, 0, 0.05)",
    },

    navBar: {
        borderRadius: 12,
    },

    homeBar: {
        height: 16,
    },

    searchBar: {
        maxWidth: TabletMaxListWidth + "px",
    },
};

export const androidTabletOverlay: OverlayTheme = {
    overlayName: "TabletTheme",

    builderHeight: 768,
    builderWidth: 1024,

    scrollView: {
        backgroundColor: "_baseColor",
        marginTop: 16,
        marginLeft: 0,
        borderRadius: 10,
        paddingTop: 0,
    },

    tileItem: {
        padding: 5,
        borderRadius: 6,
        selectedColor: "rgba(0, 0, 0, 0.05)",
    },

    navBar: {
        borderRadius: 12,
    },

    searchBar: {
        maxWidth: TabletMaxListWidth + "px",
    },
};

export const desktopOverlay: OverlayTheme = {
    overlayName: "DesktopTheme",

    builderHeight: 768,
    builderWidth: 1024,

    statusBar: {
        height: 49, // must be height of tab bar
    },

    homeBar: {
        height: 0,
    },

    scrollView: {
        backgroundColor: "_baseColor",
        marginTop: 16,
        marginLeft: 0,
        borderRadius: 10,
        paddingTop: 0,
    },

    tileItem: {
        padding: 5,
        borderRadius: 6,
        selectedColor: "rgba(0, 0, 0, 0.05)",
    },

    navBar: {
        borderRadius: 12,
        height: 0,
        largeTitleHeight: 0,
    },

    searchBar: {
        maxWidth: TabletMaxListWidth + "px",
    },
};
