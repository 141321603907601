import "twin.macro";

import { GlideIcon } from "@glide/common";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import type { WireSimpleImageComponent } from "@glide/fluent-components/dist/js/fluent-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { useResponsiveWidth } from "@glide/common-components";
import { isArray, isEmptyOrUndefined } from "@glide/support";
import type { UIAlignment, UIImageStyle, UISize, WireAction } from "@glide/wire";
import { UIAspect } from "@glide/wire";
import type { Transition } from "framer-motion";
import { AnimatePresence, m } from "framer-motion";
import range from "lodash/range";
import React from "react";
import { v4 as uuid } from "uuid";

import { Img } from "../../components/img/img";
import { WireAspectContainer } from "../../components/shared";
import { runActionAndHandleURL } from "../../wire-lib";
import type { WireRenderer } from "../wire-renderer";

export const WireSimpleImage: WireRenderer<WireSimpleImageComponent> = React.memo(p => {
    const { image, style, aspectRatio, size, align, action, backend, alt } = p;

    const images = image ?? undefined;
    if (isEmptyOrUndefined(images)) {
        return null;
    }

    const imagesArray = isArray(images) ? images : [images];

    const aspect = imagesArray.length > 1 && aspectRatio === UIAspect.Auto ? UIAspect.Square : aspectRatio;

    return (
        <ImageWrapper style={style} aspectRatio={aspect} size={size} align={align} action={action} backend={backend}>
            <ImagesCarousel images={imagesArray} alt={alt ?? undefined} appID={backend.appID} />
        </ImageWrapper>
    );
});

interface ImagesCarouselProps {
    images: string[];
    alt?: string;
    appID: string;
}

function moduloThatWrapsForNegativesToo(x: number, y: number): number {
    return ((x % y) + y) % y;
}

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? "100%" : "-100%",
        };
    },
    center: {
        x: 0,
    },
    exit: (direction: number) => {
        return {
            x: direction < 0 ? "100%" : "-100%",
        };
    },
};

const moveTransition: Transition = {
    ease: "easeInOut",
    duration: 0.15,
};

type PaginatorDirection = -1 | 0 | 1;

interface ImagePaginator {
    page: number;
    direction: PaginatorDirection;
}

const ImagesCarousel: React.VFC<ImagesCarouselProps> = p => {
    const { images, alt, appID } = p;

    const [paginator, setPaginator] = React.useState<ImagePaginator>({ page: 0, direction: 0 });

    const { page, direction } = paginator;

    const currentImageIndex = moduloThatWrapsForNegativesToo(page, images.length);
    const rawSrc = images[currentImageIndex];
    const containerWidth = useResponsiveWidth();
    const src = massageImageUrl(
        rawSrc,
        {
            width: containerWidth - 64,
            thumbnail: false,
        },
        appID
    );

    const paginate = (newDirection: PaginatorDirection) => {
        setPaginator(prevPaginator => {
            return {
                page: prevPaginator.page + newDirection,
                direction: newDirection,
            };
        });
    };

    const isCarousel = images.length > 1;

    return (
        <div tw="relative w-full h-full grid grid-cols-1 grid-rows-[100%] overflow-hidden">
            <AnimatePresence initial={false} custom={direction}>
                <m.div
                    className={isCarousel ? "is-carousel" : "not-carousel"}
                    tw="[&.not-carousel]:(row-start-1 col-start-1 w-full h-full) [&.is-carousel]:(absolute inset-0) flex items-center"
                    key={page}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={moveTransition}>
                    <Img
                        data-testid={`simple-image-${currentImageIndex}`}
                        tw="w-full max-h-full"
                        src={src}
                        isPages={true}
                        alt={alt}
                        altBehavior="both"
                        alternate={<ImgPlaceholder />}
                    />
                </m.div>
            </AnimatePresence>
            <NavigationControls paginate={paginate} imagesCount={images.length} currentImageIndex={currentImageIndex} />
        </div>
    );
};

const ImgPlaceholder: React.VFC = () => {
    return <div tw="absolute inset-0 bg-n200" />;
};

interface NavigationControlsProps {
    readonly paginate: (direction: PaginatorDirection) => void;
    readonly imagesCount: number;
    readonly currentImageIndex: number;
}

const NavigationControls: React.VFC<NavigationControlsProps> = p => {
    const { paginate, imagesCount, currentImageIndex } = p;

    // No need for controls if we don't have multiple images
    if (imagesCount < 2) {
        return null;
    }

    return (
        <>
            <NavButton onClick={() => paginate(-1)} tw="left-2">
                <GlideIcon kind="stroke" icon="st-chevron-left" data-testid="carousel-back-button" />
            </NavButton>
            <NavButton onClick={() => paginate(1)} tw="right-2">
                <GlideIcon kind="stroke" icon="st-chevron-right" data-testid="carousel-forwards-button" />
            </NavButton>
            <CurrentImageIndicator imagesCount={imagesCount} currentImageIndex={currentImageIndex} />
        </>
    );
};

function useMountId(): string {
    return React.useMemo(() => {
        return uuid();
    }, []);
}

interface CurrentImageIndicatorProps {
    imagesCount: number;
    currentImageIndex: number;
}

const CurrentImageIndicator: React.VFC<CurrentImageIndicatorProps> = p => {
    const { imagesCount, currentImageIndex } = p;

    const layoutId = useMountId();

    return (
        <div tw="absolute bottom-2 w-full pointer-events-none flex justify-center">
            <div tw="flex w-1/2 gap-1">
                {range(0, imagesCount).map(i => {
                    const isCurrent = i === currentImageIndex;

                    return (
                        <div key={i} tw="h-1 flex-1 rounded-full bg-n200A relative">
                            {isCurrent && (
                                <m.div
                                    layoutId={layoutId}
                                    transition={moveTransition}
                                    tw="absolute inset-0 rounded-full bg-n400A"
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

interface NavButtonProps {
    readonly onClick: () => void;
    readonly className?: string;
}

const NavButton: React.FC<React.PropsWithChildren<NavButtonProps>> = p => {
    const { className, children, onClick } = p;

    const onClickImpl = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
    };

    return (
        <button
            onClick={onClickImpl}
            className={className}
            tw="absolute top-1/2 -translate-y-1/2 bg-n200A transition-colors hover:(bg-n300A) backdrop-blur-2xl h-16 w-6 rounded-lg text-white">
            {children}
        </button>
    );
};

interface ImageWrapperProps {
    readonly style: UIImageStyle;
    readonly aspectRatio: UIAspect;
    readonly size: UISize;
    readonly align: UIAlignment;
    readonly action: WireAction | null | undefined;
    readonly backend: WireBackendInterface;
}

const ImageWrapper: React.FC<React.PropsWithChildren<ImageWrapperProps>> = p => {
    const { children, style, aspectRatio, size, align, action, backend } = p;

    const isUrl = action?.url !== undefined;

    const hasAction = action !== undefined;

    const onClick = hasAction
        ? () => {
              runActionAndHandleURL(action, backend, isUrl);
          }
        : undefined;

    const wrapper = (
        <WireAspectContainer
            className={hasAction ? "with-action" : "no-action"}
            tw="[&.with-action]:cursor-pointer"
            imageStyle={style}
            aspectRatio={aspectRatio}
            size={size}
            align={align}
            onClick={onClick}>
            {children}
        </WireAspectContainer>
    );

    if (isUrl) {
        return (
            <a href={action.url} target="_blank" rel="noreferrer">
                {wrapper}
            </a>
        );
    }

    return wrapper;
};
