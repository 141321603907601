import { asMaybeString } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getArrayProperty,
} from "@glide/app-description";
import type { Description } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireAppReactionComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    ArrayPropertyStyle,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    EnumPropertyHandler,
    PropertySection,
    StringPropertyHandler,
    getPrimitiveNonHiddenColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { mapFilterUndefined } from "@glideapps/ts-necessities";
import { isEmptyOrUndefined } from "@glide/support";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import {
    inflateComponentEnricher,
    inflateEditablePropertyWithDefault,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
} from "../wire/utils";
import { labelCaptionStringOptions, makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

const ComponentKindReaction: ComponentKind = "reaction";

const columnPropertyHandler = new ColumnPropertyHandler(
    "dataColumnName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
    ],
    undefined,
    ["reaction"],
    getPrimitiveNonHiddenColumnsSpec,
    "string",
    PropertySection.Data
);

export const emojis =
    "😀😃😄😁😆😅😂🤣😊😇🙂🙃😉😌😍🥰😘😗😙😚😋😛😝😜🤪🤨🧐🤓😎🤩🥳😏😒😞😔😟😕🙁😣😖😫😩🥺😢😭😤😠😡🤬🤯😳🥵🥶😱😨😰😥😓🤗🤔🤭🤫🤥😶😐😑😬🙄😯😦😧😮😲🥱😴🤤😪😵🤐🥴🤢🤮🤧😷🤒🤕🤑🤠😈👿👹👺🤡💩👻💀👽👾🤖🎃😺😸😹😻😼😽🙀😿😾💅🦄";

const emojiPropertyHandler = new EnumPropertyHandler(
    { emoji: "😀" },
    "Icon",
    "Emoji",
    Array.from(emojis).map(e => ({ value: e, label: e })),
    PropertySection.Data,
    "dropdown"
);

const reactionTextPropertyHandler = new StringPropertyHandler(
    "reactionText",
    "Text",
    "Enter Text",
    true,
    undefined,
    PropertySection.Reactions
);

interface ReactionDescription {
    readonly emoji: PropertyDescription;
    readonly reactionText: PropertyDescription;
}

interface ReactionComponentDescription extends ComponentDescription {
    readonly title: PropertyDescription | undefined;
    readonly dataColumnName: PropertyDescription;
    readonly reactions: PropertyDescription;
}

export class ReactionComponentHandler extends ComponentHandlerBase<ReactionComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindReaction);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public get isNonEmptyValidationStopper(): boolean {
        return true;
    }

    public getDescriptor(
        _desc: ReactionComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Reaction",
            description: "Add a reaction to an item",
            img: "reactionComponent",
            group: "Pickers",
            helpUrl: getDocURL("reaction"),
            properties: [
                columnPropertyHandler,
                {
                    kind: PropertyKind.Array,
                    label: "Reactions",
                    property: { name: "reactions" },
                    section: PropertySection.Reactions,
                    properties: [reactionTextPropertyHandler, emojiPropertyHandler],
                    allowEmpty: false,
                    maxItems: 6,
                    allowReorder: true,
                    addItemLabels: ["Add reaction"],
                    style: ArrayPropertyStyle.KeyValue,
                },
                ...this.getBasePropertyDescriptors(),
                makeCaptionStringPropertyDescriptor(
                    "Reaction",
                    false,
                    mutatingScreenKind,
                    labelCaptionStringOptions,
                    "title"
                ),
            ],
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: ReactionComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { getter: valueGetter, isInContext } = inflateEditablePropertyWithDefault(
            ib,
            "set",
            desc.dataColumnName,
            asMaybeString,
            ""
        );
        if (valueGetter === undefined) return undefined;

        const reactionDescriptions = getArrayProperty<ReactionDescription>(desc.reactions);
        if (reactionDescriptions === undefined) return undefined;

        const reactions = mapFilterUndefined(reactionDescriptions, rd => {
            // FIXME: Fix this ugly typing - we shouldn't have to cast to `Description`
            const emoji = emojiPropertyHandler.getEnum(rd as unknown as Description);
            const value = reactionTextPropertyHandler.getString(rd as unknown as Description);
            if (emoji === undefined) return undefined;
            return { emoji, value: isEmptyOrUndefined(value) ? emoji : value };
        });

        const [captionGetter] = inflateStringProperty(ib, desc.title, true);

        const componentEnricher = inflateComponentEnricher<WireAppReactionComponent>(ib, desc);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const value = valueGetter(hb);
            if (value === undefined) return undefined;

            const valueFound = reactions.some(r => r.value === value.value);

            const component = componentEnricher({
                kind: WireComponentKind.AppReaction,
                caption: captionGetter(hb) ?? "",
                value,
                reactions,
            });
            return {
                component,
                isValid: true,
                editsInContext: isInContext,
                hasValue: valueFound,
            };
        });
    }

    public convertToPage(
        desc: ReactionComponentDescription,
        ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return this.defaultConvertToPage(desc, ccc);
    }
}
