import type { IconImage } from "@glide/app-description";
import type { DeviceFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import type { AppKind } from "@glide/location-common";

import type { WireAction, WireComponent, WireMessage, WireModalSize } from "./types";

export enum WireScreenFlag {
    IsSignIn = "sign-in",
    IsCodeScanner = "code-scanner",
    IsSignaturePad = "signature-pad",
    IsVoiceEntry = "voice-entry",
}

export type WireScreenKey = string & { __brand: "screen-key" };

export interface WireScreen {
    // Right now this is just the parsed path fragment and the depth.  Must be
    // unique within the navigation model at least.
    readonly key: WireScreenKey;

    // Only used by the builder in apps, for direct component interaction.
    // NOTE: Must only be set if we're in the builder!
    readonly screenName?: string;

    readonly title: string;

    // We're using `null` in the wire types because we want them to be JSON,
    // which doesn't have `undefined`.
    readonly components: readonly (WireComponent | null)[];
    readonly specialComponents: readonly (WireComponent | null)[];

    readonly backAction?: WireAction;
    readonly isInModal: boolean;
    // ##tabIconOfScreen:
    // The tab icon of the tab that this screen comes from.  This can differ
    // from which tab is selected, because flyouts push onto the current tab
    // and aren't tabs themselves.
    readonly tabIcon: string;

    // Only for pages
    readonly closeAction?: WireAction;
    readonly dragBackAction?: WireAction;

    readonly flags: readonly WireScreenFlag[];
}

export interface WireSubsidiaryScreen extends WireScreen {
    // In Classic Apps we don't support this. In Pages, undefined just means "current".
    readonly size?: WireModalSize | "current";
}

export interface WireModalScreen extends WireScreen {
    readonly size: WireModalSize;
}

export interface WireTab {
    readonly title: string;
    readonly action: WireAction;
    readonly icon: string;
    readonly isActive: boolean;
}

export interface WireUserProfileButton {
    readonly signedInUserEmail: string;

    // If the user profile is not configured, there won't be a name or an
    // action.
    readonly userProfileAction?: WireAction;
    readonly signedInUserName?: string;
    readonly signedInUserImage?: string;
    readonly userProfileScreenIsOpen: boolean;
}

export interface WireUserProfile {
    // If `userProfileButton` is defined, then `signInAction` and
    // `signUpAction` won't be, and vice versa. It's possible that nothing is
    // defined.
    //
    // If `signInAction` is defined, the user isn't signed in, and a
    // sign-in button should show that calls this action.  If `signUpAction`
    // is defined, the user isn't signed in, and a sign-up button should show.
    //
    // If `userProfileButton` is defined, it gives the
    // signed-in user and an action to open the user profile screen.
    readonly signInAction?: WireAction;
    readonly signUpAction?: WireAction;

    readonly signOutAction?: WireAction;

    readonly userProfileButton?: WireUserProfileButton;
}

export interface WireNavigationModelBase extends WireUserProfile {
    readonly urlPath: string;

    readonly appTitle: string;
    readonly appTitleAction?: WireAction;

    readonly blockingMessage?: WireMessage;

    readonly tabs: readonly WireTab[];

    // If this is `undefined`, don't show the hamburger menu, and instead show
    // the "i" button with `infoButtonAction`.  Note: even if this an empty
    // array, the hamburger still has to show.
    readonly flyoutTabs: readonly WireTab[] | undefined;

    readonly removeBranding: boolean;

    readonly busyMessage?: string;

    readonly iconImage?: IconImage;

    readonly serial?: number;
}

export interface WirePageNavigationModel extends WireNavigationModelBase {
    readonly kind: AppKind.Page;

    readonly screen: WireScreen;
    readonly belowScreen: WireScreen | undefined;
    readonly modal?: WireModalScreen;
    readonly lastNavigation?: WireNavigation;
    readonly isTabRootScreen: boolean;
}

export enum WireNavigationAction {
    Push = "push",
    PushModal = "push-modal",
    Pop = "pop",
    PopModal = "pop-modal",
    SwitchTab = "switch-tab",
    URLChanged = "url-changed",
    DragBack = "drag-back",
}

export interface WireNavigation {
    readonly priorScreen: WireScreen;
    readonly navigationAction: WireNavigationAction;
}

export interface WireAppPhoneScreens {
    readonly form: DeviceFormFactor.Phone;

    readonly screen: WireScreen;
    readonly belowScreen: WireScreen | undefined;

    readonly lastNavigation?: WireNavigation;
}

export interface WireAppTabletScreens {
    readonly form: DeviceFormFactor.Tablet;

    readonly masterScreen: WireScreen | undefined;
    readonly detailScreen: WireScreen | undefined;
    // The ##selectedMasterItemKey in the navigation model.
    readonly selectedMasterItemKey: string | undefined;
    readonly modalScreen: WireScreen | undefined;
}

export type WireAppScreens = WireAppPhoneScreens | WireAppTabletScreens;

export interface WireAppNavigationModel extends WireNavigationModelBase {
    readonly kind: AppKind.App;

    readonly screens: WireAppScreens;
    // If this is also `undefined`, then don't show the "i" button, either.
    readonly infoButtonAction: WireAction | undefined;

    // Set iff we're in Tablet mode and the detail screen shows the map of a
    // top-level map screen.
    readonly isTabletTopLevelMap: boolean;
}

export type WireNavigationModel = WirePageNavigationModel | WireAppNavigationModel;
