import "mapbox-gl/dist/mapbox-gl.css";
import "twin.macro";

import type { WireListMapCollection } from "@glide/fluent-components/dist/js/fluent-components";
import { isSmallScreen, useResponsiveSizeClass } from "@glide/common-components";
import { UIStyleVariant } from "@glide/wire";
import classNames from "classnames";
import React from "react";

import { WireAspectContainer } from "../../components/shared";
import { extractActions } from "../../wire-lib";
import {
    WireListContainer,
    useDynamicFilter,
    useMultipleDynamicFilters,
    useSearchBar,
} from "../wire-list-container/wire-list-container";
import type { WireRenderer } from "../wire-renderer";
import { usePreventDragBack } from "../../chrome/content/lib/prevent-drag-back";

const LazyMapCollection = React.lazy(() => import("./map-collection-lazy"));

type WireListMapCollectionRenderer = WireRenderer<WireListMapCollection, { isFirstComponent: boolean }>;

export const MapCollectionRenderer: WireListMapCollectionRenderer = React.memo(p => {
    const { backend, aspectRatio, mobileView, isFirstComponent, searchBar } = p;
    const pageIndexToken = p.paging?.pageIndex.onChangeToken;

    const size = useResponsiveSizeClass();
    const isMobile = isSmallScreen(size);

    const filterArgs = useDynamicFilter(p.dynamicFilter, backend);
    const multipleFilterProps = useMultipleDynamicFilters(p.multipleDynamicFilters, backend);

    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, pageIndexToken);

    const preventDragBackContext = usePreventDragBack();

    const preventDragBack = () => {
        preventDragBackContext?.setIsPrevented(true);
    };

    const allowDragBack = () => {
        preventDragBackContext?.setIsPrevented(false);
    };

    return (
        <WireListContainer
            title={p.componentTitle ?? ""}
            titleActions={extractActions(p.titleActions, backend)}
            {...filterArgs}
            multipleFilterProps={multipleFilterProps}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            styleVariant={UIStyleVariant.Default}
            appKind={backend.appKind}
            isFirstComponent={isFirstComponent}>
            <WireAspectContainer
                aspectRatio={aspectRatio}
                className={classNames(
                    mobileView === "fullWidth" && isFirstComponent && "full-width-mobile",
                    !isMobile && "desktop"
                )}
                tw="-mx-4 w-auto mt-2 [&.full-width-mobile]:(-mt-2) [&.desktop]:(-mt-2) ">
                <div
                    className={classNames(mobileView === "fullWidth" ? "full-width-mobile" : "normal-rounded-inset")}
                    tw="absolute inset-0 overflow-hidden transform-gpu
                        [&.full-width-mobile]:(rounded-none mx-0 page-md:(rounded-xl mx-4))
                        [&.normal-rounded-inset]:(rounded-xl mx-4)
                    "
                    onMouseDown={preventDragBack}
                    onMouseUp={allowDragBack}
                    onMouseLeave={allowDragBack}>
                    <div tw="overflow-auto relative w-full h-full">
                        <React.Suspense fallback={<LoadingFallback />}>
                            <LazyMapCollection {...p} />
                        </React.Suspense>
                    </div>
                </div>
            </WireAspectContainer>
        </WireListContainer>
    );
});

const LoadingFallback: React.FC = () => {
    return <div tw="absolute inset-0 w-full bg-bg-middle" />;
};
