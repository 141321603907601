import type { IconName } from "@glide/common";
import {
    type ArrayContentDescription,
    type ArrayScreenDescription,
    type ComponentDescription,
    ArrayScreenFormat,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import type { AppDescriptionContext, PropertyDescriptor } from "@glide/function-utils";
import { assert, defined } from "@glideapps/ts-necessities";

import type { ArrayContentHandler } from "./array-content";

export const appSortAscendingIconName = "01-40-keyboard-arrow-down";
export const appSortDescendingIconName = "01-40-keyboard-arrow-up";
export const appSortShuffleIconName = "01-42-button-shuffle";

export interface ArrayScreenHandler<
    TContentDesc extends ArrayContentDescription,
    TScreenDesc extends ArrayScreenDescription & TContentDesc
> extends ArrayContentHandler<TContentDesc> {
    readonly label: string;
    readonly icon: IconName;
    readonly isLegacy: boolean;
    readonly supportsInlineList: boolean;

    defaultDescription(
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        components: readonly ComponentDescription[],
        forNewApp: boolean
    ): TScreenDesc | undefined;

    getPropertyDescriptors(
        tables: InputOutputTables | undefined,
        desc: TScreenDesc | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        ccc: AppDescriptionContext
    ): readonly PropertyDescriptor[];
    getPropertyAndActionDescriptors(
        tables: InputOutputTables | undefined,
        desc: TScreenDesc | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        ccc: AppDescriptionContext
    ): readonly PropertyDescriptor[];
}
export const allArrayScreenHandlers: ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>[] = [];

export function registerArrayScreenHandler(
    handler: ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>
): void {
    assert(allArrayScreenHandlers.every(h => h.format !== handler.format));
    allArrayScreenHandlers.push(handler);
}

export function handlerForArrayScreenFormat(
    format: ArrayScreenFormat
): ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription> | undefined {
    return allArrayScreenHandlers.find(h => h.format === format);
}

export function getDefaultArrayScreenHandler(): ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription> {
    return defined(handlerForArrayScreenFormat(ArrayScreenFormat.List));
}

export function getListDisplayName(
    handler: ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription> | undefined
): string {
    if (handler === undefined) {
        return "Inline List";
    }
    return handler.label;
}
