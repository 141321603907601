/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { isUndefinedish, maybeParseJSON } from "@glide/support";
import { defined } from "@glideapps/ts-necessities";

const { Result } = glide;

const apiKey = glide.makeParameter({
    type: "string",
    name: "Live publishable (client)",
    placeholder: "prj_live_sk_...",
    description:
        "You can find your Live publishable key in your [dashboard](https://radar.com/dashboard). [Learn more](https://radar.com/documentation/api#authentication) about getting an API key",
});

const enableGeoTracking = glide.makeParameter({
    type: "boolean",
    name: "Enable geo tracking",
});

export const plugin = glide.newPlugin({
    id: "radar",
    name: "Radar",
    description: "Geocoding and address completion",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/radar.png",
    tier: "starter",
    parameters: {
        apiKey,
        enableGeoTracking,
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/radar",
});

plugin.useSecret({
    baseUrl: "https://api.radar.io/",
    kind: "authorization-bearer",
    includeBearer: false,
    value: apiKey,
});

const address = glide.makeParameter({
    type: "string",
    name: "Address",
    description: "Physical location",
    placeholder: "1600 Pennsylvanie Ave",
});

const lat = glide.makeParameter({
    type: "number",
    name: "Latitude",
    description: "Latitude",
    placeholder: "38.8976633",
});

const lng = glide.makeParameter({
    type: "number",
    name: "Longitude",
    description: "Longitude",
    placeholder: "-77.0365739",
});

const latLng = glide.makeParameter({
    type: "string",
    name: "Latitude, Longitude",
    description: "Lattitude and Longitude as a string",
    placeholder: "38.8976633,-77.0365739",
});

interface Results {
    meta: Meta;
    addresses?: Address[];
    routes?: Routes;
}

interface Address {
    latitude?: number;
    longitude?: number;
    geometry?: Geometry;
    country?: string;
    countryCode?: string;
    countryFlag?: string;
    county?: string;
    confidence?: string;
    borough?: string;
    city?: string;
    number?: string;
    neighborhood?: string;
    postalCode?: string;
    stateCode?: string;
    state?: string;
    street?: string;
    layer?: string;
    formattedAddress?: string;
    addressLabel?: string;
}

interface Geometry {
    type?: string;
    coordinates?: number[];
}

export interface Meta {
    code: number;
    param?: string;
    message?: string;
}

interface Routes {
    geodesic?: Geodesic;
    foot?: RouteData;
    bike?: RouteData;
    car?: RouteData;
}

interface RouteData {
    distance: ValueTuple;
    duration: ValueTuple;
}

interface ValueTuple {
    value: number;
    text: string;
}

interface Geodesic {
    distance: ValueTuple;
}

plugin.addComputation({
    id: "address-to-coordinates",
    name: "Get coordinates for address",
    description: "Get geocoordinates for an address",
    billablesConsumed: 1,
    parameters: { address },
    results: { lat, lng, latLng },

    async execute(context, { address }) {
        if (address === undefined) return Result.Ok({});

        const r = await context.useCache(async () => {
            const url = new URL("https://api.radar.io/v1/geocode/forward");
            url.searchParams.append("query", address);
            const response: Results = await context.fetch(url.toString()).then(x => x.json());
            if (response.meta.code === 200) {
                context.consumeBillable();

                return Result.Ok(response.addresses?.[0]);
            }
            return Result.FailFromHTTPStatus(response.meta.message ?? "", response.meta.code, {
                data: maybeParseJSON(response),
            });
        }, [address, "forward"]);

        if (!r.ok) return r;

        return Result.Ok({
            lat: r.result?.latitude,
            lng: r.result?.longitude,
            latLng: r.result === undefined ? undefined : `${r.result?.latitude},${r.result?.longitude}`,
        });
    },
});

plugin.addComputation({
    id: "coordinates-to-address",
    name: "Get address from coordinates",
    description: "Get an address from geocoordinates",
    billablesConsumed: 1,
    parameters: { lat, lng },
    results: { address },

    async execute(context, { lat, lng }) {
        if (lat === undefined || lng === undefined) return Result.Ok({});

        const r = await context.useCache(async () => {
            const url = new URL("https://api.radar.io/v1/geocode/reverse");
            url.searchParams.append("coordinates", `${lat},${lng}`);
            const response: Results = await context.fetch(url.toString()).then(x => x.json());
            if (response.meta.code === 200) {
                context.consumeBillable();

                return Result.Ok(response.addresses?.[0]);
            }
            return Result.FailFromHTTPStatus(response.meta.message ?? "", response.meta.code, {
                data: maybeParseJSON(response),
            });
        }, [lat, lng, "reverse"]);

        if (!r.ok) return r;

        return Result.Ok({
            address: r.result?.formattedAddress,
        });
    },
});

plugin.addComputation({
    id: "complete-address",
    name: "Complete address",
    description: "Complete a partial or unformatted address",
    billablesConsumed: 1,
    parameters: { address },
    results: { address },

    async execute(context, { address }) {
        if (address === undefined) return Result.Ok({});

        const r = await context.useCache(async () => {
            const url = new URL("https://api.radar.io/v1/search/autocomplete");
            url.searchParams.append("query", address);
            url.searchParams.append("limit", "1");

            const response: Results = await context.fetch(url.toString()).then(x => x.json());
            if (response.meta.code !== 200)
                return Result.FailFromHTTPStatus(response.meta.message ?? "", response.meta.code, {
                    data: maybeParseJSON(response),
                });

            context.consumeBillable();
            return Result.Ok(response.addresses?.[0]);
        }, [address, "autocomplete"]);
        if (!r.ok) return r;
        return Result.Ok({
            address: r.result?.formattedAddress,
        });
    },
});

const origin = glide.makeParameter({
    type: "string",
    name: "Origin",
    description: "Physical location",
    placeholder: "1600 Pennsylvanie Ave",
});

const destination = glide.makeParameter({
    type: "string",
    name: "Destination",
    description: "Physical location",
    placeholder: "1600 Pennsylvanie Ave",
});

const distance = glide.makeParameter({
    type: "number",
    name: "Distance",
});

const duration = glide.makeParameter({
    type: "number",
    name: "Duration (minutes)",
});

const units = glide.makeParameter({
    type: "enum",
    name: "Units",
    values: [
        {
            label: "Feet",
            value: "imperial",
        },
        {
            label: "Meters",
            value: "metric",
        },
    ],
});

const mode = glide.makeParameter({
    type: "enum",
    name: "Travel Mode",
    values: [
        {
            label: "Car",
            value: "car",
        },
        {
            label: "Bike",
            value: "bike",
        },
        {
            label: "Foot",
            value: "foot",
        },
    ],
});

function isLatLng(input: string | undefined): boolean {
    if (input === undefined) return false;
    const split = input.split(",");
    if (split.length !== 2) return false;
    return split.every(x => !isNaN(Number.parseFloat(x)));
}

plugin.addComputation({
    id: "distance-between-locations",
    name: "Calculate distance",
    description: "Get the distance between two addresses",
    billablesConsumed: 1,
    parameters: { origin, destination, units, mode },
    results: { distance, duration },

    async execute(context, { origin, destination, units = "metric", mode = "car" }) {
        if (origin === undefined || destination === undefined) return Result.Ok({});

        const getAddress = async (addr: string): Promise<glide.Result<string>> => {
            const r = await context.useCache(async () => {
                const url = new URL("https://api.radar.io/v1/geocode/forward");
                url.searchParams.append("query", addr);
                const response: Results = await context.fetch(url.toString()).then(x => x.json());
                if (response.meta.code === 200) {
                    context.consumeBillable();

                    return Result.Ok(response.addresses?.[0]);
                }
                return Result.FailFromHTTPStatus(response.meta.message ?? "", response.meta.code, {
                    data: maybeParseJSON(response),
                });
            }, [addr, "forward"]);

            if (!r.ok) return r;
            if (r.result?.latitude === undefined || r.result?.longitude === undefined) {
                return Result.FailPermanent("Could not geocode address: " + addr);
            }

            return Result.Ok(`${r.result.latitude},${r.result.longitude}`);
        };

        if (!isLatLng(origin)) {
            const newOrigin = await getAddress(origin);
            if (!newOrigin.ok) return newOrigin;
            origin = newOrigin.result;
        }

        if (!isLatLng(destination)) {
            const newDestination = await getAddress(destination);
            if (!newDestination.ok) return newDestination;
            destination = newDestination.result;
        }

        const r = await context.useCache(async () => {
            const url = new URL("https://api.radar.io/v1/route/distance");
            url.searchParams.append("origin", defined(origin));
            url.searchParams.append("destination", defined(destination));
            url.searchParams.append("units", units);
            url.searchParams.append("modes", mode);

            const response: Results = await context.fetch(url.toString()).then(x => x.json());
            if (response.meta.code !== 200)
                return Result.FailFromHTTPStatus(response.meta.message ?? "", response.meta.code, {
                    data: maybeParseJSON(response),
                });

            const part = response.routes?.[mode as keyof typeof response.routes] as RouteData | undefined;
            if (part === undefined)
                return Result.Fail("Unknown error calling Radar", {
                    status: response.meta.code,
                    data: maybeParseJSON(response),
                });

            context.consumeBillable();
            return Result.Ok({ distance: part.distance.value, duration: part.duration.value });
        }, [origin, destination, units, mode, "autocomplete"]);
        return r;
    },
});

plugin.addHeader(
    ({ apiKey, enableGeoTracking }) => `
<script src="https://js.radar.com/v3.6.1/radar.min.js"></script>
<script>
  !function(t,e){if(void 0===e[t]){e[t]=function(){(e[t].q=e[t].q||[]).push(arguments)};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://js.radar.com/v3.6.1/radar.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)}}("Radar",window);
  Radar.initialize("${apiKey}");
  ${enableGeoTracking ? `setInterval(() => { Radar?.trackOnce(); }, 60000);` : ""}
</script>
`
);

plugin.addClientAction({
    id: "trackOnce",
    name: "Track once",
    description: "Track user location once",
    results: { lat, lng, latLng },
    needsClient: true,
    execute: async () => {
        if ((window as any).Radar === undefined)
            return Result.Fail("Could not find Radar plugin. This is not available in the builder.", {
                isPluginError: false,
            });
        const r = await new Promise<{ lat: number; lng: number } | string>(resolve => {
            (window as any).Radar?.trackOnce((err: any, result: any) => {
                if (!isUndefinedish(err)) {
                    resolve(JSON.stringify(err));
                } else {
                    // result contains the user's current location
                    if (result.status === "SUCCESS") {
                        resolve({
                            lat: result.location.latitude as number,
                            lng: result.location.longitude as number,
                        });
                    } else {
                        resolve(JSON.stringify(result));
                    }
                }
            });
        });

        if (typeof r !== "string") {
            return Result.Ok({
                lat: r.lat,
                lng: r.lng,
                latLng: `${r.lat},${r.lng}`,
            });
        }
        return Result.Fail(r);
    },
});

plugin.setEventTracker((_params, event) => {
    const anyWindow = window as any;
    if (anyWindow.Radar === undefined) return;
    switch (event.kind) {
        case "load":
            anyWindow.Radar.setMetadata({ appID: anyWindow.appID });
            break;
        case "identify":
            anyWindow.Radar.setUserId(event.userID);
            break;
    }
});
