import { styled } from "@glide/common";
import { DropdownAnchor } from "./dropdown-menu-types";
import { css } from "styled-components";

import * as Elements from "@glide/common-core/dist/js/shared-ui/style";

interface DropdownItemProps {
    disabled?: boolean;
    active?: boolean;
}

interface DropdownMenuProps {
    isHidden: boolean;
    anchor: DropdownAnchor;
    minWidth: number;
}

export const DropdownWrapper = styled.div`
    position: relative;
`;

const dropdownItemActive = (props: DropdownItemProps) =>
    props.active &&
    css`
        color: ${p => p.theme.fgColorDark};
        text-decoration: none;
        background-color: ${p => p.theme.blue};
    `;

const dropdownItemDisabled = (props: DropdownItemProps) =>
    props.disabled &&
    css`
        color: ${p => p.theme.fgColorLight};
        font-weight: 500;
        background-color: transparent;
        cursor: default;
        &:hover,
        &:focus {
            text-decoration: none;
            background-color: transparent;
        }
    `;

export const DropdownMenuItem = styled(Elements.Label)<DropdownItemProps>`
    padding: 12px 16px;
    clear: both;
    color: ${p => p.theme.fgColorDark};
    background-color: transparent;
    border: 0;
    font-size: 14px;
    cursor: pointer;
    &:hover,
    &:focus {
        text-decoration: none;
        background-color: ${p => p.theme.bgColorMedium};
    }
    ${dropdownItemActive};
    ${dropdownItemDisabled};
`;

const dropdownMenuHidden = (props: DropdownMenuProps) =>
    props.isHidden
        ? css`
              visibility: hidden;
              opacity: 0;
              transform: translate(
                  0px,
                  ${props.anchor === DropdownAnchor.BottomLeft || props.anchor === DropdownAnchor.BottomRight
                      ? "-16px"
                      : "16px"}
              );
          `
        : css`
              visibility: visible;
              opacity: 1;
              transform: translate(0px, 0px);
          `;

const dropdownMenuPosition = (props: DropdownMenuProps) => {
    switch (props.anchor) {
        case DropdownAnchor.BottomLeft:
            return css`
                top: 100%;
                left: 0;
            `;
        case DropdownAnchor.BottomRight:
            return css`
                top: 100%;
                right: 0;
            `;
        case DropdownAnchor.TopLeft:
            return css`
                bottom: 100%;
                left: 0;
            `;
        case DropdownAnchor.TopRight:
            return css`
                bottom: 100%;
                right: 0;
            `;
        default:
            return;
    }
};

export const DropdownMenu = styled.div<DropdownMenuProps>`
    position: absolute;
    background: ${p => p.theme.bgColorLight};
    box-shadow: ${p => p.theme.modalShadow};
    border-radius: 4px;
    float: left;
    min-width: ${p => p.minWidth}px;
    padding: 12px 0 12px 0;
    text-align: left;
    list-style: none;
    background-clip: padding-box;

    z-index: 1;

    transition: visibility 0s linear ${p => (p.isHidden ? "0.2s" : "0s")}, opacity 0.2s, transform 0.2s;
    ${dropdownMenuHidden};
    ${dropdownMenuPosition};
`;
