import type { Subscription } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { AddonKind, PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { defaultNewUserFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import type { Organization, Owner, UserData } from "@glide/common-core/dist/js/Database";
import { OwnerKind } from "@glide/common-core/dist/js/Database/owner-kind";

function createMockUser(): UserData {
    const mockUser: UserData = {
        id: "mockUser1234",
        kind: OwnerKind.User,
        displayName: "James Mock",
        appIDs: [],
        authIDs: ["abcde123"],
        emails: [],
        orgUserIDs: [],
        planCounts: { pro: 0 },
        appTrials: undefined,
        customerInformation: undefined,
        integrations: [],
        planAppIDs: { pro: [] },
        referrals: 0,
        stripeCustomerIDs: [],
        templateLicenses: [],
    };
    return mockUser;
}

function createMockSubscription(owner: Owner): Subscription {
    return {
        ownerID: owner.id,
        subscriptionStatus: "active",
        plan: PlanKind.Free,
        planIteration: 0,
        period: "monthly",
        addons: [
            {
                appID: "PRO",
                kind: AddonKind.V1ProApp,
                count: 1,
            },
            {
                appID: "ORG-PUBLIC",
                kind: AddonKind.V1OrgProPublic,
                count: 1,
            },
            {
                appID: "ORG-INTERNAL-USER-OVERAGE",
                kind: AddonKind.V1OrgProInternalUserOverage,
                count: 0,
            },
            {
                appID: "BASIC-PUBLIC",
                kind: AddonKind.V2BasicPublicApp,
                count: 1,
            },
            {
                appID: "BASIC-PRIVATE",
                kind: AddonKind.V2BasicPrivateApp,
                count: 1,
            },
            {
                appID: "PRO-PUBLIC",
                kind: AddonKind.V2ProPublicApp,
                count: 1,
            },
            {
                appID: "PRO-PRIVATE",
                kind: AddonKind.V2ProPrivateApp,
                count: 1,
            },
            {
                appID: "PRO-PRIVATE-PAGE",
                kind: AddonKind.V2ProPrivatePage,
                count: 1,
            },
        ],
    };
}

export const org: Organization = {
    kind: OwnerKind.Organization,
    id: "UMnIrxB9g3jCcPLMZqbV",
    displayName: "Glide-Test",
    appIDs: ["EXWnrDaI5ppEBCsiYZIh"],
    integrations: [],
    stripeCustomerIDs: [],
    partnerStackReferrerPartnerKey: undefined,
    monthlySubscription: undefined,
    annualSubscription: undefined,
    features: defaultNewUserFeatures,
    appTrials: {},
    memberUserIDs: ["VqXYuxKyJmspPFMuP6vX"],
    adminUserIDs: ["VqXYuxKyJmspPFMuP6vX"],
    companyInformation: {
        industryKind: "Individual",
        numAppEditors: {
            min: 0,
            max: 0,
        },
        numAppUsers: {
            min: 0,
            max: 0,
        },
        numEmployees: {
            min: 1,
            max: 50,
        },
    },
    planCounts: {
        organizationPublic: 0,
    },
    planAppIDs: {
        organizationPublic: [],
    },
    hasSubscription: false,
    isAgency: false,
    templateLicenses: [],
    customerInformation: {
        serial: -1,
    },
};

const user = createMockUser();
export const freeSubscription = createMockSubscription(user);
