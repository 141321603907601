import type { NodeViewProps } from "@tiptap/react";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { type ReactNodeViewProps, useEditorContext } from "./editor-context";
import { Tooltip, TooltipAnchor, TooltipProvider } from "@ariakit/react/tooltip";
import Mention from "@tiptap/extension-mention";
import { IconRenderer } from "./icon-renderer";
import type { ComponentType } from "react";
import { isValidElement, useRef } from "react";
import { isDefined, isEmptyOrUndefined } from "@glide/support";
import { isMonotoneIcon } from "@glide/common";

const isHeaderIcon = (icon: string | undefined): boolean => icon?.startsWith("header") ?? false;
export const getMenuOptionIconSize = (icon: string | undefined): number =>
    isHeaderIcon(icon) || isMonotoneIcon(icon) ? 16 : 12;

function truncateMiddle(str: string, maxLength: number, separator = "…"): string {
    if (str.length <= maxLength) return str;

    const sepLength = separator.length;
    const charsToShow = maxLength - sepLength;
    const halfChars = charsToShow >> 1; // Faster integer division by 2

    return str.slice(0, halfChars) + separator + str.slice(-halfChars);
}

// For _some_ reason we're getting build errors when we don't cast the TooltipProvider
// we couldn't figure out why, and this still works, so I guess we'll find out eventually.
const TypedTooltipProvider = TooltipProvider as React.FC<{
    open?: boolean;
    children: React.ReactNode;
}>;

const createAttribute = (name: string, defaultValue: unknown = undefined) => ({
    [name]: {
        default: defaultValue,
        parseHTML: (element: HTMLElement) => element.getAttribute(name),
        renderHTML: (attributes: Record<string, string>) => {
            if (!attributes[name]) {
                return {};
            }
            return {
                [name]: attributes[name],
            };
        },
    },
});

const createJSONAttribute = (name: string, defaultValue: unknown = undefined) => ({
    [name]: {
        default: defaultValue,
        parseHTML: (element: HTMLElement) => {
            const value = element.getAttribute(name);
            if (value === null) return undefined;
            return JSON.parse(value);
        },
        renderHTML: (attributes: Record<string, unknown>) => {
            const objectValue = attributes[name];
            if (!isDefined(objectValue)) return {};
            const serializedValue = JSON.stringify(objectValue);
            return {
                [name]: serializedValue,
            };
        },
    },
});

export const TokenExtension = Mention.extend({
    name: "binding",
    inline: true,
    selectable: true,
    draggable: true,
    addAttributes() {
        return {
            ...createAttribute("label"),
            ...createAttribute("preview"),
            ...createJSONAttribute("value"),
            ...createAttribute("icon"),
            ...createAttribute("withDivider"),
            ...createAttribute("singleValue"),
            ...createAttribute("invalid", false),
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(TokenView as ComponentType<NodeViewProps>);
    },
});

const TokenView = ({ node, tokenStyle = "default", ...props }: ReactNodeViewProps) => {
    const { setSelectedNodePosition, selectedNodePosition, isDragging } = useEditorContext();
    const divRef = useRef<HTMLDivElement | null>(null);
    const isEditable = props.editor.options.editable;

    const toggleActiveNode = () => {
        if (selectedNodePosition === props.getPos()) {
            setSelectedNodePosition(null);
            props.editor.commands.focus("end");

            return;
        }

        props.editor.commands.setNodeSelection(props.getPos());

        setSelectedNodePosition(props.getPos());
    };

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isEditable) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        toggleActiveNode();
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!isEditable) {
            return;
        }
        if (e.key === "Backspace") {
            e.preventDefault();
            e.stopPropagation();

            props.deleteNode();
            props.editor.commands.focus();
        }
    };

    const label = typeof node.attrs.label === "string" ? node.attrs.label : "";

    const isTokenSelected = selectedNodePosition === props.getPos() || props.selected;
    const truncatedLabel = truncateMiddle(label, 25);

    const preview =
        typeof node.attrs.preview === "object" && isValidElement(node.attrs.preview) ? node.attrs.preview : undefined;

    const isInvalid = node.attrs.invalid === true;

    const icon = typeof node.attrs.icon === "string" ? node.attrs.icon : "";

    const iconSize = getMenuOptionIconSize(icon);

    return (
        <NodeViewWrapper tw="inline-flex max-w-full leading-none">
            <TypedTooltipProvider open={isDragging ? false : undefined}>
                <TooltipAnchor
                    render={
                        <div
                            ref={divRef}
                            data-drag-handle
                            onKeyDown={onKeyDown}
                            aria-label={label}
                            data-variant={!isEmptyOrUndefined(icon) ? tokenStyle : "compact"}
                            tw="inline-flex flex-shrink flex-nowrap items-center gap-1 h-5 mx-px py-1 pl-1 pr-1.5 bg-bg-front cursor-pointer select-none ring-1 rounded-md 
                            font-medium outline-none transition text-builder-base ring-border-dark leading-4 hover:ring-n500A
                            data-[selected=true]:ring-2 data-[selected=true]:ring-aqua400 data-[selected=true]:transition-none 
                            active:ring-2 active:ring-aqua400 active:transition-none 

                            data-[variant=compact]:pl-0.5 data-[variant=compact]:py-0.5 data-[variant=compact]:pr-0.5
                            relative max-w-fit overflow-hidden min-h-[20px]
                            data-[variant=compact]:gap-0
                            data-[invalid=true]:text-r400 data-[invalid=true]:ring-r400
                            "
                            data-selected={isTokenSelected}
                            data-invalid={isInvalid}
                            tabIndex={-1}
                            onClick={onClick}>
                            {!isEmptyOrUndefined(icon) ? (
                                tokenStyle === "compact" ? (
                                    <span tw="h-[9px]"></span>
                                ) : null
                            ) : (
                                <span tw="h-[9px]"></span>
                            )}

                            {icon && tokenStyle === "default" ? (
                                <>
                                    <span tw="inline-flex relative w-3 h-[9px] shrink-0"></span>
                                    <span
                                        data-negative-margin={
                                            isHeaderIcon(icon) || isMonotoneIcon(icon) ? "true" : "false"
                                        }
                                        tw="absolute top-1 left-1 text-n600A data-[negative-margin=true]:top-0.5 data-[negative-margin=true]:left-0.5 ">
                                        <IconRenderer icon={icon} size={iconSize} />
                                    </span>
                                </>
                            ) : null}
                            <span tw="truncate">{truncatedLabel}</span>
                        </div>
                    }
                />

                <Tooltip
                    portal
                    portalElement={document.getElementById("portal")}
                    tw="p-1.5 rounded-md shadow-xl cursor-default z-blocking-message text-text-inverse bg-n800A">
                    <div tw="flex gap-1 items-center text-builder-sm">
                        {tokenStyle === "compact" && icon ? (
                            <IconRenderer icon={icon} size={13} tw="text-icon-base" />
                        ) : null}
                        {tokenStyle === "compact" && icon ? (
                            <div tw="text-text-pale">
                                {label}
                                {isDefined(preview) ? ` - ${preview}` : ""}
                            </div>
                        ) : (
                            <div tw="text-text-inverse">
                                {label}
                                {isDefined(preview) ? ` - ${preview}` : ""}
                            </div>
                        )}
                    </div>
                </Tooltip>
            </TypedTooltipProvider>
        </NodeViewWrapper>
    );
};
