import { useBuilderTheme } from "../../hooks/use-builder-theme";
import JsonView from "@uiw/react-json-view";

interface Props {
    json: any;
    collapsed?: boolean | number;
}

export const JSONViewer: React.FC<Props> = p => {
    const { json, collapsed } = p;

    const theme = useBuilderTheme();

    const darkColor = theme.textBase;
    const midColor = theme.textPale;
    const disabled = theme.textXpale;

    const customTheme = {
        "--w-rjv-background-color": "#00000000",
        "--w-rjv-key-number": darkColor,
        "--w-rjv-key-string": darkColor,
        "--w-rjv-line-color": theme.borderColor,
        "--w-rjv-arrow-color": midColor,
        "--w-rjv-info-color": midColor,
        "--w-rjv-copied-success-color": midColor,
        "--w-rjv-curlybraces-color": midColor,
        "--w-rjv-colon-color": midColor,
        "--w-rjv-brackets-color": midColor,
        "--w-rjv-ellipsis-color": midColor,
        "--w-rjv-quotes-color": midColor,
        "--w-rjv-quotes-string-color": midColor,
        "--w-rjv-type-string-color": midColor,
        "--w-rjv-type-int-color": midColor,
        "--w-rjv-type-float-color": midColor,
        "--w-rjv-type-bigint-color": midColor,
        "--w-rjv-type-boolean-color": midColor,
        "--w-rjv-type-date-color": midColor,
        "--w-rjv-type-url-color": midColor,
        "--w-rjv-type-null-color": disabled,
        "--w-rjv-type-nan-color": disabled,
        "--w-rjv-type-undefined-color": disabled,
    };

    return (
        <JsonView
            style={customTheme as React.CSSProperties}
            value={json}
            collapsed={collapsed}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            shortenTextAfterLength={0}
        />
    );
};
