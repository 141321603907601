import {
    type Action,
    type ActionProps,
    type Computation,
    type DataSourceProps,
    type NotificationSenderProps,
    type SignOnProps,
    type SignOn,
    type TriggerProps,
    type Trigger,
    type NativePlugin,
    type ParameterRecord,
    type PluginProps,
    type QueryableDataSource,
    type SerializablePluginMetadata,
    type NotificationSender,
    Plugin,
} from "@glide/plugins";
import { mapRecord, hasOwnProperty } from "@glideapps/ts-necessities";
import { parseJSONSafely } from "@glide/support";

export {
    getAuthPlugin,
    isOAuthRefreshTokenError,
    performOAuthTokenRefresh,
    pluginOAuthStage1,
    pluginOAuthStage2,
    pluginOAuthStage3,
    wrapFetch,
} from "./lib/oauth";

export * from "./lib/fetch-wrappers";

export type { OAuthClientPlugin, OAuthUserData } from "./lib/oauth-providers";

export { pluginResultToGlideType, pluginResultToGlideTypeWithParameterProps } from "./lib/plugin-result-to-glide-type";

export { DataExecutionContext } from "./data-execution-context";

export { convertValueToJSONValue } from "./json-utils";

export function getPluginParameters<T extends ParameterRecord>(
    plugin: Plugin<T> | NativePlugin
): ParameterRecord | undefined {
    const parameters = plugin instanceof Plugin ? plugin.fields.parameters : undefined;
    return parameters;
}

export function makePluginProps<T extends ParameterRecord>(plugin: Plugin<T> | NativePlugin): PluginProps {
    const parameters = getPluginParameters(plugin);
    return {
        id: plugin.fields.id,
        name: plugin.fields.name,
        icon: plugin.fields.icon,
        disclosure: plugin.fields.disclosure,
        // What's the type of this??
        parameters: mapRecord(parameters ?? {}, (p: any) => p.props),
        auth: plugin instanceof Plugin ? plugin.fields.auth : undefined,
        tier: plugin.fields.tier,
        badge: plugin.fields.badge,
        deprecated: plugin.fields.deprecated,
    };
}

export function makeActionProps<TResults extends ParameterRecord, TParams extends ParameterRecord>(
    action: Action<TResults, TParams, TParams> | Computation<TResults, TParams, TParams>
): ActionProps {
    const { execute, parameters, results, ...rest } = action;
    return {
        ...rest,
        parameters: mapRecord(parameters ?? {}, p => p.props),
        results: mapRecord(results ?? {}, p => p.props),
    };
}

function makeQueryableDataSourceProps<TParams extends ParameterRecord>(
    dataSource: QueryableDataSource<TParams>
): DataSourceProps {
    const { makeConnectionObject, parameters, ...rest } = dataSource;
    return {
        ...rest,
        parameters: mapRecord(parameters ?? {}, p => p.props),
    };
}

function makeSignOnProps<TParams extends ParameterRecord>(signOn: SignOn<TParams>): SignOnProps {
    const { id, name, description } = signOn;
    return { id, name, description };
}

function makeTriggerProps<TResults extends ParameterRecord, TParams extends ParameterRecord>(
    trigger: Trigger<TResults, TParams>
): TriggerProps {
    const { id, name, description, parameters, results } = trigger;
    return {
        id,
        name,
        description,
        parameters: mapRecord(parameters ?? {}, p => p.props),
        results: mapRecord(results ?? {}, p => p.props),
        experimentFlag: trigger.experimentFlag,
        badge: trigger.badge,
    };
}

function makeNotificationSenderProps(notificationSender: NotificationSender): NotificationSenderProps {
    const { id, name, description, priority, billablesConsumed } = notificationSender;
    return { id, name, description, priority, billablesConsumed };
}

export function convertToMetadata(p: Plugin | NativePlugin): SerializablePluginMetadata {
    const parameters = p instanceof Plugin ? p.fields.parameters : undefined;

    const result = {
        name: p.fields.name,
        icon: p.fields.icon,
        tier: p.fields.tier,
        description: p.fields.description,
        documentationUrl: p.fields.documentationUrl,
        disclosure: p.fields.disclosure,
        // What's the type of this??
        parameters: mapRecord(parameters ?? {}, (param: any) => param.props),
        actions: p.actions.map(makeActionProps),
        computations: p.computations.map(makeActionProps),
        queryableDataSources: p.queryableDataSources.map(makeQueryableDataSourceProps),
        notificationSenders: p.notificationSenders.map(makeNotificationSenderProps),
        signOns: p instanceof Plugin ? p.signOns.map(makeSignOnProps) : [],
        triggers: p instanceof Plugin ? p.triggers.map(makeTriggerProps) : [],
        hasEventTracker: p instanceof Plugin && p.eventTracker !== undefined,
        // ##hasCorporateSignOn:
        // For now, all sign-ons are corporate
        // NOTE: This is not in the type anymore, but we have to keep it in
        // here because existing frontends still expect it.
        hasCorporateSignOn: p instanceof Plugin && p.signOns.length > 0,
        id: p.fields.id,
        experimentFlag: p.fields.experimentFlag,
        badge: p.fields.badge,
    };
    if (p instanceof Plugin) {
        return {
            ...result,
            auth: p.fields.auth,
            isExperimental: p.fields.isExperimental,
            supportsMultipleInstances: p.fields.supportsMultipleInstances,
            deprecated: p.fields.deprecated,
            clientGlideAPIs: p.glideAPIClients,
        };
    }
    return { ...result, isNative: true };
}

export function makePluginActionKindFromIDs(pluginID: string, actionID: string): string {
    return `plugin-${pluginID}-${actionID}`;
}

export function makePluginActionKind(plugin: PluginProps, action: ActionProps): string {
    return makePluginActionKindFromIDs(plugin.id, action.id);
}

export function makePluginComputationKindFromIDs(pluginID: string, computationID: string): string {
    return `plugin-${pluginID}-${computationID}`;
}

export function makePluginComputationKind(plugin: PluginProps, computation: ActionProps): string {
    return makePluginComputationKindFromIDs(plugin.id, computation.id);
}

// The name of the ##pluginUserFeature, for plugins that have `isExperimental`
// set.
export function makePluginUserFeatureName(pluginID: string): string {
    return `plugin-${pluginID}`;
}

export function makeSignalScope(appID: string, pluginID: string, signalID: string): string {
    return JSON.stringify({ appID, pluginID, signalID });
}

export function parseSignalScope(
    signalScope: string
): { appID: string; pluginID: string; signalID: string } | undefined {
    const json = parseJSONSafely(signalScope);
    if (json === undefined) return undefined;
    if (!hasOwnProperty(json, "appID") || !hasOwnProperty(json, "pluginID") || !hasOwnProperty(json, "signalID"))
        return undefined;
    if (typeof json.appID !== "string" || typeof json.pluginID !== "string" || typeof json.signalID !== "string")
        return undefined;
    return { appID: json.appID, pluginID: json.pluginID, signalID: json.signalID };
}

type SerializedAction = SerializablePluginMetadata["actions"][0];
type SerializedComputation = SerializablePluginMetadata["computations"][0];

export function findAction<T extends Action | Computation>(actions: readonly T[], id: string): T | undefined;
export function findAction<T extends SerializedAction | SerializedComputation>(
    actions: readonly T[],
    id: string
): T | undefined;
export function findAction(
    actions: readonly (Action | Computation | SerializedAction | SerializedComputation)[],
    id: string
) {
    for (const a of actions) {
        if (a.id === id || a.name === id) return a;
    }
    return undefined;
}

export function forEachPluginAction(
    plugins: readonly SerializablePluginMetadata[],
    f: (plugin: PluginProps, action: ActionProps) => void
): void {
    // We're being super cautious here because we've broken the plugin
    // metadata serialization format at some point.
    try {
        for (const plugin of plugins) {
            for (const action of plugin.actions) {
                f(plugin, action);
            }
            for (const computation of plugin.computations) {
                f(plugin, computation);
            }
        }
    } catch (e: unknown) {
        // logError("Error iterating over plugins", exceptionToString(e));
    }
}

export function forEachPluginComputation(
    plugins: readonly SerializablePluginMetadata[],
    f: (plugin: PluginProps, computation: ActionProps) => void
): void {
    for (const plugin of plugins) {
        for (const computation of plugin.computations) {
            f(plugin, computation);
        }
    }
}
