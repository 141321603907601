import { type RuntimeTheme, isRuntimeTheme } from "@glide/common";
import { AppKind } from "@glide/location-common";
import { defined } from "@glideapps/ts-necessities";
import { styled } from "@glide/common-components";
import * as React from "react";
import { useBuilderScreenshooter } from "../../../../hooks/use-builder-screenshooter";
import { withTheme } from "styled-components";
import type { BaseTheme } from "@glide/base-theme";

// Surely there is a better way built into JS
function dateString(): string {
    let hours = new Date().getHours();
    let minutes = new Date().getMinutes().toString();

    if (hours > 12) hours = hours - 12;
    if (hours === 0) hours = 12;

    if (minutes.length === 1) minutes = "0" + minutes;
    return hours + ":" + minutes;
}

const AndroidStatusBarWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: black;
    color: white;
    height: 24px;
    width: 100%;
    padding-right: 16px;
`;

function AndroidStatusBar() {
    return (
        <AndroidStatusBarWrapper>
            <svg width="57" height="16" viewBox="0 0 57 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M50 1.875V1L47 1V1.875H44V15H53V1.875H50Z"
                    fill="white"
                />
                <path fillRule="evenodd" clipRule="evenodd" d="M21 15H35V1L21 15Z" fill="white" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.977295 4.01593L9.99992 15L19.0226 4.01593C16.5117 2.12256 13.3869 1 9.99992 1C6.61294 1 3.48814 2.12256 0.977295 4.01593Z"
                    fill="white"
                />
            </svg>
            {dateString()}
        </AndroidStatusBarWrapper>
    );
}

function StatusBarDrawing(props: { theme: RuntimeTheme }) {
    const time = dateString();
    // these artisnal tuned constants brought to you by taking screenshots and measuring
    let timeX = 30;
    if (time.length > 4) {
        timeX -= 9;
    }

    const width = props.theme.builderWidth;
    // Assumes that values greater than 393 are going to be shown the tablet status bar.
    if (width <= 393) {
        return (
            <svg width="393" height="54" viewBox="0 0 393 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <text
                    fontFamily="-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif"
                    fontSize="17px"
                    fontWeight="600"
                    x="52"
                    y="36"
                >
                    {time}
                </text>
                <rect x="134" y="11" width="124" height="36" rx="18" fill="black" />
                <g>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M300.824 23.6514H299.647C298.997 23.6514 298.471 24.1521 298.471 24.7697V34.4621C298.471 35.0798 298.997 35.5805 299.647 35.5805H300.824C301.473 35.5805 302 35.0798 302 34.4621V24.7697C302 24.1521 301.473 23.6514 300.824 23.6514ZM294.157 26.2617H295.334C295.984 26.2617 296.51 26.7624 296.51 27.3801V34.463C296.51 35.0806 295.984 35.5813 295.334 35.5813H294.157C293.508 35.5813 292.981 35.0806 292.981 34.463V27.3801C292.981 26.7624 293.508 26.2617 294.157 26.2617ZM289.843 28.8706H288.667C288.017 28.8706 287.49 29.3713 287.49 29.989V34.4624C287.49 35.08 288.017 35.5807 288.667 35.5807H289.843C290.493 35.5807 291.02 35.08 291.02 34.4624V29.989C291.02 29.3713 290.493 28.8706 289.843 28.8706ZM284.353 31.1074H283.176C282.527 31.1074 282 31.6081 282 32.2258V34.4625C282 35.0801 282.527 35.5808 283.176 35.5808H284.353C285.003 35.5808 285.529 35.0801 285.529 34.4625V32.2258C285.529 31.6081 285.003 31.1074 284.353 31.1074Z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M317.229 25.4616C319.748 25.4618 322.17 26.4294 323.995 28.1645C324.132 28.2985 324.352 28.2968 324.487 28.1608L325.801 26.8349C325.869 26.7659 325.908 26.6724 325.907 26.5751C325.907 26.4779 325.867 26.3849 325.798 26.3166C321.008 21.7258 313.45 21.7258 308.66 26.3166C308.591 26.3848 308.551 26.4778 308.551 26.5751C308.55 26.6723 308.588 26.7658 308.657 26.8349L309.971 28.1608C310.106 28.297 310.326 28.2987 310.463 28.1645C312.288 26.4293 314.711 25.4616 317.229 25.4616ZM317.229 29.7749C318.613 29.7748 319.947 30.2891 320.973 31.2179C321.111 31.3497 321.33 31.3468 321.465 31.2114L322.777 29.8855C322.846 29.816 322.885 29.7216 322.884 29.6236C322.883 29.5256 322.842 29.432 322.772 29.3639C319.649 26.4586 314.812 26.4586 311.689 29.3639C311.618 29.432 311.578 29.5256 311.577 29.6237C311.576 29.7218 311.615 29.8161 311.684 29.8855L312.996 31.2114C313.131 31.3468 313.349 31.3497 313.488 31.2179C314.513 30.2897 315.846 29.7755 317.229 29.7749ZM319.857 32.6774C319.859 32.7757 319.821 32.8705 319.751 32.9394L317.481 35.2301C317.414 35.2974 317.323 35.3353 317.229 35.3353C317.134 35.3353 317.043 35.2974 316.977 35.2301L314.707 32.9394C314.636 32.8704 314.598 32.7756 314.6 32.6773C314.602 32.579 314.645 32.4859 314.718 32.42C316.167 31.1939 318.29 31.1939 319.74 32.42C319.813 32.4859 319.855 32.5791 319.857 32.6774Z"
                    />
                    <path
                        opacity="0.4"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M337.5 23.6514H353.501C355.434 23.6514 357.001 25.2184 357.001 27.1514V31.1514C357.001 33.0844 355.434 34.6514 353.501 34.6514H337.5C335.567 34.6514 334 33.0844 334 31.1514V27.1514C334 25.2184 335.567 23.6514 337.5 23.6514ZM333 27.1514C333 24.6661 335.015 22.6514 337.5 22.6514H353.501C355.987 22.6514 358.001 24.6661 358.001 27.1514V31.1514C358.001 33.6366 355.987 35.6514 353.501 35.6514H337.5C335.015 35.6514 333 33.6366 333 31.1514V27.1514ZM360.517 29.1985C360.517 30.2074 359.913 31.1179 358.983 31.5094V26.8877C359.913 27.2791 360.517 28.1897 360.517 29.1985Z"
                    />
                    <path d="M335 27.1514C335 25.7707 336.119 24.6514 337.5 24.6514H353.501C354.882 24.6514 356.001 25.7707 356.001 27.1514V31.1514C356.001 32.5321 354.882 33.6514 353.501 33.6514H337.5C336.119 33.6514 335 32.5321 335 31.1514V27.1514Z" />
                </g>
            </svg>
        );
    } else {
        const translateTablet = `translate(-170, 0)`;

        return (
            <svg width={width} height="24" viewBox={`0 0 ${width} 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    transform={translateTablet}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1173.5 6.50001H1156.5C1155.12 6.50001 1154 7.6193 1154 9.00001V15.5C1154 16.8807 1155.12 18 1156.5 18H1173.5C1174.88 18 1176 16.8807 1176 15.5V9.00001C1176 7.6193 1174.88 6.50001 1173.5 6.50001ZM1155 9.00012C1155 8.17169 1155.67 7.50012 1156.5 7.50012H1173.5C1174.33 7.50012 1175 8.17169 1175 9.00012V15.5001C1175 16.3285 1174.33 17.0001 1173.5 17.0001H1156.5C1155.67 17.0001 1155 16.3285 1155 15.5001V9.00012ZM1178.25 12.0001C1178.25 11.0747 1177.75 10.2668 1177 9.83455V14.1656C1177.75 13.7334 1178.25 12.9255 1178.25 12.0001Z"
                />
                <path
                    transform={translateTablet}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1157 8.50011H1173C1173.55 8.50011 1174 8.94783 1174 9.50011V15.0001C1174 15.5524 1173.55 16.0001 1173 16.0001H1157C1156.45 16.0001 1156 15.5524 1156 15.0001V9.50011C1156 8.94783 1156.45 8.50011 1157 8.50011Z"
                />

                <path
                    transform={translateTablet}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1129.37 9.69885L1129.37 9.464C1127.31 7.62122 1124.66 6.605 1121.88 6.605C1119.09 6.605 1116.44 7.627 1114.38 9.4792L1114.37 9.71384L1115.59 10.9352L1115.81 10.9423C1117.49 9.46527 1119.63 8.65246 1121.88 8.65246C1124.12 8.65246 1126.26 9.45975 1127.93 10.9277L1128.15 10.9204L1129.37 9.69885ZM1126.69 12.1343L1126.7 12.3723L1125.48 13.5974L1125.27 13.6094C1124.3 12.8415 1123.12 12.4241 1121.88 12.4241C1120.63 12.4241 1119.44 12.8466 1118.48 13.6231L1118.26 13.6115L1117.04 12.3868L1117.05 12.1492C1118.4 11.0039 1120.1 10.3766 1121.88 10.3766C1123.65 10.3766 1125.35 10.9983 1126.69 12.1343ZM1121.98 17.105H1121.75L1119.72 15.0728C1119.65 15.0019 1119.66 14.8842 1119.74 14.8264C1120.37 14.385 1121.11 14.1482 1121.88 14.1482C1122.64 14.1482 1123.38 14.38 1124 14.8127C1124.08 14.8703 1124.09 14.9885 1124.02 15.0597L1121.98 17.105Z"
                />
                <path
                    transform={translateTablet}
                    d="M1142.91 16.2848C1142.7 16.7829 1141.96 16.629 1141.96 16.0862V13.0449C1141.96 12.7688 1141.73 12.5449 1141.46 12.5449H1138.41C1137.87 12.5449 1137.72 11.8016 1138.22 11.5861L1145.34 8.5019C1145.76 8.32192 1146.18 8.74355 1146 9.15938L1142.91 16.2848Z"
                />
                <text
                    fontFamily="-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif"
                    fontSize="14px"
                    fontWeight="normal"
                    x={timeX}
                    y="18"
                >
                    {`${dateString()}`}
                </text>
            </svg>
        );
    }
}

const StatusBarContainer = styled.div<{ drawBackground: boolean; statusBarFill: string }>`
    background-color: ${p => (p.drawBackground ? p.theme.navBar.bgColor : "transparent")};
    width: 100%;
    z-index: 45;
    position: absolute;
    left: 0;
    top: 0;
    height: ${p => p.theme.statusBar?.height ?? 54}px;
    font-family: "Roboto";

    svg {
        fill: ${p => p.statusBarFill};
    }
`;

interface Props extends React.PropsWithChildren {
    themeOverride?: BaseTheme;
    isSplash: boolean;
    appKind: AppKind;
    isPageLight: boolean;
}

class StatusBar extends React.Component<Props & { theme: RuntimeTheme | BaseTheme; isScreenshooting: boolean }> {
    private interval: number = 0;

    public componentDidMount() {
        this.interval = window.setInterval(() => this.setState({ time: Date.now() }), 1000);
    }

    public componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    public render() {
        const { theme: fallbackTheme, appKind, isPageLight, isSplash, isScreenshooting, themeOverride } = this.props;

        const theme = themeOverride ?? fallbackTheme;

        if (!isRuntimeTheme(theme)) {
            return null;
        }

        const isIOS = defined(theme).navBar.style === "iOS";
        const content = isIOS ? <StatusBarDrawing theme={theme} /> : <AndroidStatusBar />;

        const statusBarFill = appKind === AppKind.App ? theme.statusBar.color : isPageLight ? "black" : "white";

        return (
            <StatusBarContainer statusBarFill={statusBarFill} drawBackground={!isIOS && !isSplash}>
                {isScreenshooting ? null : content}
            </StatusBarContainer>
        );
    }
}

const StatusBarWithTheme = withTheme(StatusBar);

const StatusBarWrapper: React.FC<Props> = p => {
    const isScreenshooting = useBuilderScreenshooter();
    return <StatusBarWithTheme isScreenshooting={isScreenshooting} {...p} />;
};

export default StatusBarWrapper;
