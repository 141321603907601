import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import {
    type GroundValue,
    type LoadedGroundValue,
    type LoadingValue,
    type Row,
    isLoadingValue,
    isBound,
} from "@glide/computation-model-types";
import { asMaybeString, isRow } from "@glide/common-core/dist/js/computation-model/data";
import { getRowIndexForRow } from "@glide/common-core/dist/js/computation-model/row-index";
import { type ColumnType, type TableGlideType, getTableName, isDateTimeTypeKindUntyped } from "@glide/type-schema";
import {
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getArrayProperty,
    getColumnProperty,
    getSecretProperty,
    getStringProperty,
} from "@glide/app-description";
import type { ActionWithOutputRowDescription } from "@glide/common-core/dist/js/description";
import type {
    WebhookValue,
    WebhookValues,
    WebhookWriteBackToColumn,
    WriteSourceType,
} from "@glide/common-core/dist/js/firebase-function-types";
import { GlideDateTime, GlideJSON } from "@glide/data-types";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    ArrayPropertyStyle,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    getPrimitiveNonHiddenColumnsSpec,
    getPrimitiveOrPrimitiveArrayNonHiddenColumnsSpec,
    makeSingleRelationOrThisItemPropertyDescriptor,
} from "@glide/function-utils";
import { assert, assertNever, mapFilterUndefined, definedMap } from "@glideapps/ts-necessities";
import { isArray, nullToUndefined } from "@glide/support";
import {
    WireActionResult,
    type WireActionBackend,
    type WireValueGetterGeneric,
    type WireActionResultBuilder,
    type WireActionHydrator,
    type WireActionInflationBackend,
} from "@glide/wire";
import { type YesCodeValueWithType, convertYesCodeValue } from "@glide/yes-code";
import {
    makeCaptionStringPropertyDescriptor,
    makeTextPropertyDescriptorWithSpecialValue,
} from "../components/descriptor-utils";
import { getRelationForDescription, makeGetIndirectTableForActionWithOutputRow } from "../description-utils";
import { makeRowGetter } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type AwaitSendDescription, getAwaitSendPropertyDescriptor, shouldAwaitSend } from "./await-send-property";
import { BaseActionHandler } from "./base";
import { getSupportsUserProfileRowAccess } from "./set-columns";
import type { StaticActionContext } from "../static-context";

export interface NameAndValueDescription {
    readonly name: PropertyDescription;
    readonly value: PropertyDescription;
}

export function makeNamesAndStringValuesPropertyDescriptor(
    propertyName: string,
    mutatingScreenKind: MutatingScreenKind | undefined,
    required: boolean,
    withSecretConstants: boolean = false
): PropertyDescriptor {
    return {
        kind: PropertyKind.Array,
        label: "Values",
        property: { name: propertyName },
        section: PropertySection.Data,
        properties: [
            makeCaptionStringPropertyDescriptor("Value", required, mutatingScreenKind, undefined, "name"),
            makeTextPropertyDescriptorWithSpecialValue("value", "Value", "Enter value", required, mutatingScreenKind, {
                columnFirst: true,
                isDefaultCaption: true,
                withSecretConstants,
                textMenuLabel: withSecretConstants ? "Secret" : "Custom",
            }),
        ],
        allowEmpty: !required,
        allowReorder: false,
        addItemLabels: ["Add value"],
        style: ArrayPropertyStyle.KeyValue,
    };
}

export function makeNamesAndJSONValuesPropertyDescriptor(
    propertyName: string,
    mutatingScreenKind: MutatingScreenKind | undefined,
    required: boolean
): PropertyDescriptor {
    return {
        kind: PropertyKind.Array,
        label: "Values",
        property: { name: propertyName },
        section: PropertySection.Data,
        properties: [
            makeCaptionStringPropertyDescriptor("Value", required, mutatingScreenKind, undefined, "name"),
            makeTextPropertyDescriptorWithSpecialValue("value", "Value", "Enter value", required, mutatingScreenKind, {
                columnFirst: true,
                isDefaultCaption: true,
                columnFilter: getPrimitiveOrPrimitiveArrayNonHiddenColumnsSpec,
                checkForValidJSON: true,
                showInlineWarning: true,
            }),
        ],
        allowEmpty: !required,
        allowReorder: false,
        addItemLabels: ["Add value"],
        style: ArrayPropertyStyle.KeyValue,
    };
}

interface InflatedNameAndValue {
    nameGetter: WireValueGetterGeneric<GroundValue>;
    valueGetter: WireValueGetterGeneric<GroundValue>;
    valueType: ColumnType;
    valueIsStringConstant: boolean;
}

export function inflateNamesAndValues(
    ib: WireActionInflationBackend,
    desc: PropertyDescription
): readonly InflatedNameAndValue[] {
    const namesAndValues = getArrayProperty<NameAndValueDescription>(desc) ?? [];
    const nameAndValueGetters = mapFilterUndefined(namesAndValues, nav => {
        const [nameGetter, nameType] = ib.getValueGetterForProperty(nav.name, false);
        const [valueGetter, valueType] = ib.getValueGetterForProperty(nav.value, false);
        const stringConstant = getStringProperty(nav.value) ?? getSecretProperty(nav.value);

        if (nameType === undefined || valueType === undefined) return undefined;
        return { nameGetter, valueGetter, valueType, valueIsStringConstant: stringConstant !== undefined };
    });
    return nameAndValueGetters;
}

interface ResultColumn {
    readonly name: PropertyDescription;
    readonly column: PropertyDescription;
}

export interface KVPInOutActionDescription extends ActionWithOutputRowDescription, AwaitSendDescription {
    readonly namesAndValues: PropertyDescription;
    readonly resultColumns: PropertyDescription | undefined;
}

const getRelation = getRelationForDescription({
    inOutputRow: true,
    onlySingleRelations: true,
    defaultToThisRow: true,
    allowFullTable: false,
});
const getIndirectTable = makeGetIndirectTableForActionWithOutputRow(getRelation);

function convertYesCodeValueToWebhookValue(yesCode: YesCodeValueWithType): WebhookValue | undefined {
    if (yesCode.value === undefined) return undefined;
    if (typeof yesCode.type !== "string" || isArray(yesCode.value)) return undefined;
    if (typeof yesCode.value === "number") {
        return { type: "number", value: yesCode.value };
    }
    if (typeof yesCode.value === "boolean") {
        return { type: "boolean", value: yesCode.value };
    }
    if (typeof yesCode.value === "string") {
        if (isDateTimeTypeKindUntyped(yesCode.type)) {
            return { type: "date-time", value: yesCode.value };
        } else {
            return { type: "string", value: yesCode.value };
        }
    }
    assertNever(yesCode.value);
}

export function convertToWebhookValue(value: LoadedGroundValue): WebhookValue | undefined {
    if (value === undefined) return undefined;
    // We're deprecating this in favor of Call API, so I wouldn't bother with JSON support.
    if (value instanceof GlideJSON) return undefined;
    if (value instanceof GlideDateTime) {
        // We used to only have time-zone agnostic values, which were
        // transferred as-is as UTC strings.  Now we also have time-zone aware
        // values, for which we also transfer their UTC strings, i.e. it's the
        // same code for both.
        return { type: "date-time", value: value.asUTCDate().toISOString() };
    }
    return convertYesCodeValueToWebhookValue(convertYesCodeValue([value, value], "primitive"));
}

interface KVPWriteBackToColumn extends WebhookWriteBackToColumn {
    readonly outputRow: Row;
}

type KVPWriteBackToColumns = Record<string, KVPWriteBackToColumn>;

export interface KVPWriteBackTo {
    readonly results: KVPWriteBackToColumns;
    readonly fromBuilder: boolean;
    readonly fromDataEditor: boolean;
    readonly appUserID: string | undefined;
    readonly writeSource: WriteSourceType;
}

export abstract class KVPInOutActionHandler<
    TDesc extends KVPInOutActionDescription,
    TInflatedData
> extends BaseActionHandler<TDesc> {
    protected abstract readonly actionName: string;

    protected abstract getPropertyDescriptors(): readonly PropertyDescriptor[];
    protected abstract inflateData(
        ib: WireActionInflationBackend,
        desc: TDesc,
        arb: WireActionResultBuilder
    ): TInflatedData | WireActionResult;
    protected abstract runAction(
        ab: WireActionBackend,
        appID: string,
        appFacilities: ActionAppFacilities,
        params: WebhookValues,
        writeBackTo: KVPWriteBackTo | undefined,
        awaitSend: boolean,
        data: TInflatedData,
        arb: WireActionResultBuilder
    ): Promise<WireActionResult>;

    protected getIsLegacy(_ccc: AppDescriptionContext): boolean {
        return false;
    }

    protected getSupportsWriteBack(): boolean {
        return true;
    }

    public get name(): string {
        return this.actionName;
    }

    public getDescriptor(
        _desc: KVPInOutActionDescription | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const properties: PropertyDescriptor[] = [
            ...this.getPropertyDescriptors(),
            makeNamesAndStringValuesPropertyDescriptor("namesAndValues", mutatingScreenKind, true),
        ];
        if (this.getSupportsWriteBack()) {
            properties.push(
                makeSingleRelationOrThisItemPropertyDescriptor(
                    "outputRow",
                    "Row",
                    PropertySection.Data,
                    true,
                    getSupportsUserProfileRowAccess(ccc)
                ),
                {
                    kind: PropertyKind.Array,
                    label: "Results",
                    property: { name: "resultColumns" },
                    section: PropertySection.Data,
                    properties: [
                        makeCaptionStringPropertyDescriptor("Value", true, mutatingScreenKind, undefined, "name"),
                        new ColumnPropertyHandler(
                            "column",
                            "Column",
                            [ColumnPropertyFlag.Editable, ColumnPropertyFlag.Required, ColumnPropertyFlag.EditedInApp],
                            getIndirectTable,
                            undefined,
                            getPrimitiveNonHiddenColumnsSpec,
                            "string",
                            PropertySection.Data
                        ),
                    ],
                    allowEmpty: true,
                    allowReorder: false,
                    addItemLabels: ["Add result"],
                    style: ArrayPropertyStyle.KeyValue,
                }
            );
        }
        properties.push(...getAwaitSendPropertyDescriptor(ccc));

        return {
            name: this.actionName,
            group: ActionGroup.Communication,
            groupItemOrder: 6,
            needsScreenContext: true,
            isLegacy: this.getIsLegacy(ccc),
            properties,
        };
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: TDesc,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { appID },
            appFacilities,
            forBuilder,
            writeSource,
        } = ib;

        const inflatedData = this.inflateData(ib, desc, arb);
        if (inflatedData instanceof WireActionResult) return inflatedData;

        const nameAndValueGetters = inflateNamesAndValues(ib, desc.namesAndValues);

        const resultColumns = getArrayProperty<ResultColumn>(desc.resultColumns) ?? [];
        const resultColumnGetters = mapFilterUndefined(resultColumns, rc => {
            const [nameGetter, nameType] = ib.getValueGetterForProperty(rc.name, false);
            const columnName = getColumnProperty(rc.column);
            if (nameType === undefined || columnName === undefined) return undefined;
            return { nameGetter, columnName };
        });
        let outputTable: TableGlideType | undefined;
        let outputRowGetter: WireValueGetterGeneric<GroundValue> | undefined;
        if (resultColumnGetters.length > 0) {
            const destination = makeRowGetter(ib, desc.outputRow, { inOutputRow: true, defaultToThisRow: true });
            if (destination === undefined || destination === false) {
                return arb.inflationError("Invalid destination row");
            }
            ({ table: outputTable, rowGetter: outputRowGetter } = destination);
        }

        const awaitSend = shouldAwaitSend(ib.adc, desc);

        return (vp, skipLoading) => {
            const params: WebhookValues = {};
            // ##runAllGetters:
            // We don't stop when we find the first loading value, even though
            // we know that we can't run the action at that point.  Instead we
            // run all the getters first and then report that the action is
            // still loading.  We do this so that all async computations and
            // fetches that the getters might run get kicked off right from
            // the start, instead of one by one, thereby delaying the action.
            let loadingValue: LoadingValue | undefined;
            for (const g of nameAndValueGetters) {
                const nameValue = nullToUndefined(g.nameGetter(vp));
                if (isLoadingValue(nameValue)) {
                    loadingValue = nameValue;
                    continue;
                }
                const name = asMaybeString(nameValue);
                if (name === undefined) return arb.error(true, "Missing input name");

                const valueOrLoading = nullToUndefined(g.valueGetter(vp));
                if (isLoadingValue(valueOrLoading)) {
                    loadingValue = valueOrLoading;
                    continue;
                }
                const value = convertToWebhookValue(valueOrLoading);
                if (value === undefined) continue;

                params[name] = value;
            }

            let writeBackToWithoutAppUserID: Omit<KVPWriteBackTo, "appUserID"> | undefined;
            if (outputRowGetter !== undefined) {
                assert(outputTable !== undefined);
                const row = outputRowGetter(vp);
                if (isLoadingValue(row)) {
                    loadingValue = row;
                } else if (isBound(row) && isRow(row)) {
                    const rowIndex = getRowIndexForRow(outputTable, undefined, row);
                    if (rowIndex !== undefined) {
                        const writeBackToColumns: KVPWriteBackToColumns = {};
                        for (const g of resultColumnGetters) {
                            const nameValue = nullToUndefined(g.nameGetter(vp));
                            if (isLoadingValue(nameValue)) {
                                loadingValue = nameValue;
                                continue;
                            }
                            const name = asMaybeString(nameValue);
                            if (name === undefined) return arb.error(true, "Missing result name");

                            writeBackToColumns[name] = {
                                tableName: getTableName(outputTable),
                                rowIndex,
                                columnName: g.columnName,
                                outputRow: row,
                            };
                        }

                        if (Object.keys(writeBackToColumns).length > 0) {
                            writeBackToWithoutAppUserID = {
                                results: writeBackToColumns,
                                fromBuilder: forBuilder,
                                fromDataEditor: false,
                                writeSource,
                            };
                        }
                    }
                }
            }

            if (loadingValue !== undefined) {
                if (!skipLoading) return arb.loading();
            }

            return async ab => {
                const writeBackTo = definedMap(writeBackToWithoutAppUserID, wbt => ({
                    ...wbt,
                    appUserID: ab.getAppUserID(),
                }));
                return await this.runAction(
                    ab,
                    appID,
                    appFacilities,
                    params,
                    writeBackTo,
                    awaitSend,
                    inflatedData,
                    arb.addData({ ...params })
                );
            };
        };
    }
}
