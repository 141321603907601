import * as React from "react";
import type { WireAppTheme } from "@glide/theme";
import { getUrlHandling, UrlHandling } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { getStringProperty, type AppFeatures, type NonUserAppFeatures } from "@glide/app-description";
import { defined } from "@glideapps/ts-necessities";
import { isEmptyOrUndefined } from "@glide/support";
import { SecondaryLabelWrapper } from "./secondary-label-wrapper";
import type { UserAgreementProps } from "../custom-sign-in-props";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { WireCheckbox } from "../../../renderers/wire-checkbox/wire-checkbox";

interface Props extends UserAgreementProps {
    readonly appFeatures: AppFeatures | NonUserAppFeatures;
    readonly theme: WireAppTheme;
}

type AgreementContextValue = {
    userAgreed: boolean;
    allowSaveLogin: boolean;
    setUserAgreed: (userAgreed: boolean) => void;
    setAllowSaveLogin: (allowSaveLogin: boolean) => void;
    hasAgreed: boolean;
    requiresTerms: boolean;
};

const AuthAgreementContext = React.createContext<AgreementContextValue | undefined>(undefined);

export const useAuthAgreement = (): AgreementContextValue => {
    const context = React.useContext(AuthAgreementContext);
    if (context === undefined) {
        throw new Error("useAuthAgreement must be used within an AuthAgreementProvider");
    }
    return context;
};

type AgreementContextProps = React.PropsWithChildren & {
    readonly theme: WireAppTheme;
};

export const AuthAgreementProvider: React.FC<AgreementContextProps> = ({ children, theme }) => {
    const [userAgreed, setUserAgreed] = React.useState<boolean>(false);
    // TODO: how is allowSaveLogin used? searching for it does not make it obvious or clear.
    const [allowSaveLogin, setAllowSaveLogin] = React.useState<boolean>(false);
    const requiresTerms = theme.requiresAgreement === true;
    const hasAgreed = requiresTerms === false || (requiresTerms && userAgreed);

    return (
        <AuthAgreementContext.Provider
            value={{
                userAgreed,
                setUserAgreed,
                allowSaveLogin,
                setAllowSaveLogin,
                hasAgreed,
                requiresTerms,
            }}
        >
            {children}
        </AuthAgreementContext.Provider>
    );
};

export const UserAgreementButtons: React.VFC<Props> = p => {
    const { appFeatures, theme, userAgreed, allowSaveLogin, onUserAgreedChange, onUserUpdateSaveLogin } = p;
    const { agreementText: agreementTextProperty, policyLinks, requiresAgreement } = theme;

    const appKind = getAppKindFromFeatures(appFeatures);
    const userAgreementRequired = requiresAgreement === true;

    const agreementText = getStringProperty(agreementTextProperty);
    const agreementTextOrDefault = isEmptyOrUndefined(agreementText)
        ? getLocalizedString("userAgreement", appKind)
        : agreementText;

    const policyLinksNode =
        policyLinks === undefined || policyLinks.length === 0 ? null : (
            <>
                {policyLinks.map((pl, i) => {
                    const title = getStringProperty(pl.title);
                    const href = getStringProperty(pl.url);
                    const handling = getUrlHandling(defined(href), false);
                    const useTag = handling === UrlHandling.UseATag || handling === UrlHandling.UseATagWithBlank;
                    const target = handling === UrlHandling.UseATagWithBlank ? "_blank" : undefined;

                    if (isEmptyOrUndefined(title) || isEmptyOrUndefined(href)) return <React.Fragment key={i} />;

                    return (
                        // NOTE: Only prefix with commas for links if it not the first element
                        <React.Fragment key={i}>
                            {i !== 0 ? <span>, </span> : null}
                            <a
                                tw="leading-5 border-b border-n700 ml-1"
                                href={useTag ? href : undefined}
                                target={target}
                                key={i}
                            >
                                {title}
                            </a>
                        </React.Fragment>
                    );
                })}
            </>
        );

    const agreementLabel = (
        <SecondaryLabelWrapper>
            <span>{agreementTextOrDefault.trim()}</span>
            {policyLinksNode}
        </SecondaryLabelWrapper>
    );

    const authCookieLabel = (
        <SecondaryLabelWrapper>
            <div>{getLocalizedString("saveLoginPrimay", appKind)}</div>
            <div className="secondary" tw="text-xs">
                {getLocalizedString("saveLoginSecondary", appKind)}
            </div>
        </SecondaryLabelWrapper>
    );

    return (
        <>
            {
                // TODO: allow this with SSO, too
                userAgreementRequired && (
                    <div tw="mt-5">
                        <WireCheckbox label={agreementLabel} checked={userAgreed} onCheck={onUserAgreedChange} />
                    </div>
                )
            }
            {
                // TODO: allow this with SSO, too
                appFeatures.askUserToSaveAuthCookie && (
                    <div tw="mt-4">
                        <WireCheckbox
                            label={authCookieLabel}
                            checked={allowSaveLogin}
                            onCheck={onUserUpdateSaveLogin}
                        />
                    </div>
                )
            }
        </>
    );
};
