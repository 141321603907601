import { type CellValue, GlideJSON } from "@glide/data-types";
import type { PluginValue } from "./plugin-value";

export function pluginValueToCellValue(v: PluginValue): CellValue {
    if (v.type === "glide-json") {
        if (v.value instanceof GlideJSON) {
            return v.value;
        } else {
            return v.value.map(pluginValueToCellValue);
        }
    } else {
        return v.value;
    }
}
