// For some reason ESLint is saying `SignOnButton` is used by decorator metadata. That is not true.
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import type { SignOnButton } from "@glide/plugins";
import { isArray } from "@glide/support";
import { UIButtonAppearance } from "@glide/wire";
import { useAuthAgreement, useWireAppTheme, WireButton } from "@glide/wire-renderer";
import { useOAuthListener } from "./use-oauth-listener";
import type { OAuthAuthenticator } from "@glide/auth-controller-core";
import * as React from "react";
import { GlideIcon } from "@glide/common";

function getSignOnButtonsFromPlayResponse(): readonly SignOnButton[] | undefined {
    const signOnButtons = (window as any).signOnButtons as readonly SignOnButton[];

    if (!isArray(signOnButtons)) {
        return undefined;
    }

    if (signOnButtons.length === 0) {
        return undefined;
    }

    return signOnButtons;
}

interface SSOAuthControllerProps {
    readonly authenticator: OAuthAuthenticator;
    readonly onLoggedIn: () => void;
    /**
     * Call this method if you need the entire auth screen for yourself.
     * For example: to show a "signing in..." message
     */
    readonly requestExclusiveRender: () => void;
    readonly yieldExclusiveRender: () => void;
}

export const SSOAuthController: React.VFC<SSOAuthControllerProps> = p => {
    const { authenticator, onLoggedIn, requestExclusiveRender, yieldExclusiveRender } = p;
    const signInButtons = getSignOnButtonsFromPlayResponse();

    if (signInButtons === undefined) {
        return null;
    }

    return (
        <AllSignOnButtons
            signInButtons={signInButtons}
            authenticator={authenticator}
            onLoggedIn={onLoggedIn}
            requestExclusiveRender={requestExclusiveRender}
            yieldExclusiveRender={yieldExclusiveRender}
        />
    );
};

interface AllSignOnButtonsProps {
    readonly signInButtons: readonly SignOnButton[];
    readonly authenticator: OAuthAuthenticator;
    readonly onLoggedIn: () => void;
    readonly requestExclusiveRender: () => void;
    readonly yieldExclusiveRender: () => void;
}

const AllSignOnButtons: React.VFC<AllSignOnButtonsProps> = p => {
    const { signInButtons, authenticator, onLoggedIn, requestExclusiveRender, yieldExclusiveRender } = p;
    const { requiresTerms, userAgreed } = useAuthAgreement();
    const theme = useWireAppTheme();
    const { isWaitingForAuth, error, clearError } = useOAuthListener({
        authenticator,
        onLoggedIn,
        requestExclusiveRender,
        yieldExclusiveRender,
    });

    const startOAuthFlow = React.useCallback(
        (url: string) => {
            clearError();
            authenticator.startOAuthFlow(url);
        },
        [authenticator, clearError]
    );

    if (isWaitingForAuth) {
        return (
            <div tw="flex justify-center w-full py-4">
                <GlideIcon kind="stroke" icon="st-half-spinner" spin={true} strokeColor={theme.darkAccent} />
            </div>
        );
    }

    return (
        <>
            {signInButtons.map((buttonInfo, i) => {
                const key = `signOnButton=${i}`;
                return (
                    <SignOnButton
                        key={key}
                        buttonInfo={buttonInfo}
                        startOAuthFlow={startOAuthFlow}
                        disabled={requiresTerms && !userAgreed}
                    />
                );
            })}
            {error ? <div tw="text-text-danger text-xs py-4">{error}</div> : null}
        </>
    );
};

interface SignOnButtonProps {
    readonly buttonInfo: SignOnButton;
    readonly startOAuthFlow: (OAuthFlowStartURL: string) => void;
    readonly disabled: boolean;
}

const SignOnButton: React.VFC<SignOnButtonProps> = p => {
    const { buttonInfo, startOAuthFlow, disabled } = p;

    return (
        <WireButton
            appearance={UIButtonAppearance.Bordered}
            tw="mt-3 w-full"
            onClick={() => startOAuthFlow(buttonInfo.url)}
            disabled={disabled}>
            {buttonInfo.label}
        </WireButton>
    );
};
