const data: any = {};

let id = 0;

export type DropData =
    | {
          kind: "file";
          file: File;
      }
    | {
          kind: "uri";
          uri: string;
      };

export function extractDropData(handle: string): DropData | undefined {
    const result = data[handle];
    data[handle] = undefined;
    return result;
}

export function setDropData(file: DropData): string {
    id++;
    const idString = id.toString();
    data[idString] = file;
    return idString;
}
