import type {
    YesCodeParameterMap,
    MinimalAppEnvironment,
    DataStore,
    MinimalAppFacilities,
} from "@glide/common-core/dist/js/components/types";
import {
    defaultComputationYieldInterval,
    iterationsBetweenTimeChecks,
} from "@glide/common-core/dist/js/computation-model/data";
import { SyntheticColumnKind, type ColumnSpecification, ColumnOrValueKind } from "@glide/formula-specifications";
import { makeSourceColumn } from "@glide/type-schema";
import type { ColumnSpec } from "@glide/generator/dist/js/__tests__/schema-support";
import { sleep } from "@glideapps/ts-necessities";

export function makeColumnSpec(name: string): ColumnSpecification {
    return {
        kind: ColumnOrValueKind.Column,
        column: makeSourceColumn(name),
    };
}

export const testYesCodeColumn: ColumnSpec = {
    name: "yc",
    spec: {
        kind: SyntheticColumnKind.YesCode,
        url: "foo://bar",
        params: [["p1", makeColumnSpec("name")]],
        type: { kind: "string" },
        typeIsPrecise: true,
    },
};

// FIXME: Use a real dummy MinimalAppEnvironment when it's more feasible
// note, this being borrowed by "lookups use resumption" in computed-columns.test
export function makeDummyAppEnvWithSlowComputation(
    appID: string,
    appFacilities: MinimalAppFacilities,
    dataStore: DataStore,
    isAsync: boolean
) {
    const callYesCode = isAsync
        ? async (_url: string, _params: YesCodeParameterMap) => {
              const interval = defaultComputationYieldInterval / iterationsBetweenTimeChecks + 15;
              await sleep(interval);
              return "bar";
          }
        : (_url: string, _params: YesCodeParameterMap) => {
              const start = Date.now();
              const interval = defaultComputationYieldInterval / iterationsBetweenTimeChecks + 15;
              while (Date.now() - start < interval) {
                  // CPU-bound loop
              }
              return "bar";
          };

    return {
        appID,
        appFacilities,
        dataStore,
        authenticator: {
            addCallback: () => undefined,
            removeCallback: () => undefined,
        },
        callYesCode: jest.fn(callYesCode as any),
        subscribeToActionsState: () => undefined,
        unsubscribeFromActionState: () => undefined,
    } as unknown as MinimalAppEnvironment;
}
