import urllib from "url"; // Note that node-polyfill-webpack-plugin is supposed to take care of this in the browser,
import { definedMap, assertNever, mapFilterUndefined } from "@glideapps/ts-necessities";
import numeral from "numeral";
// @ts-ignore
import unicode from "unicode-properties";
import { arrayComparator } from "./array-comparator";
import { type MapLocation, MapLocationKind } from "./image-source";
import {
    type JSONData,
    type JSONObject,
    truthify,
    isEmptyOrUndefined,
    isValidEmailAddress,
    normalizeEmailAddress,
    parseURL,
    pathExtension,
} from ".";

// something about Webpack is buggy and ignores all of the imported classes.
// FIXME: Why is Webpack dropping the classes out of this?

// Really, JavaScript?
export function reverseString(s: string): string {
    return s.split("").reverse().join("");
}

export function tryShortenLinkToHostname(link: string): string {
    const hostname = parseURL(link)?.hostname;
    if (hostname !== undefined && hostname !== "") {
        return hostname;
    } else {
        return link;
    }
}

export function extractInitials(s: string): string {
    const parts = s.split(" ").splice(0, 2);
    const initials = parts.map(p => p[0]);
    return initials.join("");
}

export enum EmailClient {
    NativeMail = "native-mail",
    Gmail = "gmail",
    MSOutlook = "ms-outlook",
}

export function splitEmailAddresses(str: string): string[] {
    return mapFilterUndefined(str.split(","), e => {
        e = normalizeEmailAddress(e);
        if (!isValidEmailAddress(e)) return undefined;
        return e;
    });
}

export function makeMailtoURL(
    emailClient: EmailClient,
    to: string | undefined,
    subject: string | undefined,
    body: string | undefined,
    cc: string | undefined,
    bcc: string | undefined
): string | undefined {
    function makeEmailList(s: string | undefined) {
        return splitEmailAddresses(s ?? "").join(",");
    }

    to = makeEmailList(to);
    cc = makeEmailList(cc);
    bcc = makeEmailList(bcc);

    if (
        isEmptyOrUndefined(to) &&
        isEmptyOrUndefined(subject) &&
        isEmptyOrUndefined(body) &&
        isEmptyOrUndefined(cc) &&
        isEmptyOrUndefined(bcc)
    ) {
        return undefined;
    }

    const params: { key: string; value: string }[] = [];
    function encodeParams(): string {
        return mapFilterUndefined(params, ({ key, value }) => {
            if (value === "") return undefined;
            return `${key}=${encodeURIComponent(value)}`;
        }).join("&");
    }
    if (subject !== undefined) {
        params.push({ key: "subject", value: subject });
    }
    if (body !== undefined) {
        params.push({ key: "body", value: body });
    }
    if (cc !== undefined) {
        params.push({ key: "cc", value: cc });
    }
    if (bcc !== undefined) {
        params.push({ key: "bcc", value: bcc });
    }
    switch (emailClient) {
        case EmailClient.NativeMail: {
            const encodedTo = encodeURIComponent(to).replace(/%40/g, "@");
            const encodedParams = encodeParams();
            const url = "mailto:" + encodedTo;
            if (encodedParams === "") return url;
            return url + "?" + encodedParams;
        }

        case EmailClient.Gmail:
            params.push({ key: "to", value: to });
            return "googlegmail://co?" + encodeParams();

        case EmailClient.MSOutlook:
            params.push({ key: "to", value: to });
            return "ms-outlook://compose?" + encodeParams();

        default:
            return assertNever(emailClient);
    }
}

export function getLastURLPathComponent(link: string): string | undefined {
    const url = parseURL(link);
    if (url === undefined) return undefined;
    const components = url.pathname.split("/");
    for (let i = components.length - 1; i >= 0; i--) {
        const c = components[i];
        if (c !== "") {
            return c;
        }
    }
    return undefined;
}

const SIZE_BASE = 1024;

export function formatFileSize(sizeInBytes: number): string {
    const units = [" bytes", "KB", "MB", "GB", "TB"];
    let index = 0;
    let sizeInUnits = sizeInBytes;

    while (sizeInUnits >= SIZE_BASE && index < units.length - 1) {
        sizeInUnits /= SIZE_BASE;
        index++;
    }

    return numeral(sizeInUnits).format("0,0[.]0") + units[index];
}

// FIXME: Surely this already exists?
// We must not use locale-aware sorting, as we are going by byte order.
export function compareStringsASCII(left: string, right: string): number {
    if (left === right) return 0;
    return left < right ? -1 : 1;
}

export const compareStringArraysASCII = arrayComparator(compareStringsASCII);

export function makeMapURL(location: MapLocation, isIOS: boolean): string {
    if (location.kind === MapLocationKind.LatLong) {
        const { latitude, longitude } = location;
        const url = isIOS
            ? `https://maps.apple.com/?ll=${latitude},${longitude}`
            : `https://www.google.com/maps/?q=${latitude},${longitude}`;

        return url;
    } else if (location.kind === MapLocationKind.Address) {
        const { address } = location;
        const url = isIOS
            ? `https://maps.apple.com/?address=${encodeURIComponent(address)}`
            : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
        return url;
    } else {
        return assertNever(location);
    }
}

const imageExtensions = new Set([".jpg", ".jpeg", ".png", ".gif", ".svg"]);
const audioExtensions = new Set([".mp3", ".ogg", ".wav", ".opus"]);

export function getURITypeForString(
    s: string
): [type: "uri" | "image-uri" | "audio-uri", protocol: string] | undefined {
    try {
        const uri = new (urllib.URL ?? URL)(s);
        const extension = pathExtension(uri.pathname);
        const extensions = definedMap(extension, x => [x.toLowerCase()]) ?? [];
        const protocol = uri.protocol;
        if (extensions.some(x => imageExtensions.has(x))) {
            return ["image-uri", protocol];
        }
        if (extensions.some(x => audioExtensions.has(x))) {
            return ["audio-uri", protocol];
        }
        return ["uri", protocol];
    } catch {
        return undefined;
    }
}

export function decodeURIOrElse(uri: string): string {
    try {
        return decodeURI(uri);
    } catch {
        return uri;
    }
}

export function decodeURIComponentOrElse(uriComponent: string): string {
    try {
        return decodeURIComponent(uriComponent);
    } catch {
        return uriComponent;
    }
}

export function generateCloudinaryPublicId(url: string): string {
    const components = url.split(".");
    components.pop();
    return encodeURIComponent(components.join("."));
}

function safelyConvertToString(input: any): string {
    if (input !== undefined && typeof input.toString === "function") {
        try {
            return input.toString();
        } catch {
            // Ignore errors and fall back to default string conversion
        }
    }
    return `${input}`;
}

export function maybeParseJSON(input: any | undefined): string | JSONObject | any[] | undefined {
    if (typeof input !== "string") {
        try {
            JSON.stringify(input);
            return input;
        } catch {
            return safelyConvertToString(input);
        }
    }

    const str = input as string;

    if (str === undefined || !(str.startsWith("{") || str.startsWith("["))) return str;
    try {
        return JSON.parse(str);
    } catch {
        return str;
    }
}

export function parseJSONSafely(v: string): JSONData {
    try {
        return JSON.parse(v);
    } catch {
        return undefined;
    }
}

export function isDigit(c: string): boolean {
    return "0123456789".indexOf(c) >= 0;
}

export function isAlphabetic(c: string): boolean {
    return truthify(unicode.isAlphabetic(c.charCodeAt(0)));
}

export function isAlphanumeric(c: string): boolean {
    return isAlphabetic(c) || isDigit(c);
}

export function isWhitespace(c: string): boolean {
    return truthify(unicode.isWhiteSpace(c.charCodeAt(0)));
}

const digitsRegex = /^([0-9]+)(.*)/;

export function compareStringsSmartly(a: string, b: string): number {
    const aMatch = digitsRegex.exec(a);
    const bMatch = digitsRegex.exec(b);
    if (aMatch !== null && bMatch !== null) {
        const aNum = parseInt(aMatch[1]);
        const bNum = parseInt(bMatch[1]);
        if (aNum !== bNum) {
            return aNum - bNum;
        }

        a = aMatch[2];
        b = bMatch[2];
    }

    a = a.toLocaleLowerCase();
    b = b.toLocaleLowerCase();
    if (a < b) {
        return -1;
    } else if (a > b) {
        return 1;
    } else {
        return 0;
    }
}

// Returns the byte length of a UTF-8 string
// Adapted from https://stackoverflow.com/a/23329386
// License: https://creativecommons.org/licenses/by-sa/4.0/
export function byteLength(str: string) {
    let s = str.length;
    for (let i = str.length - 1; i >= 0; i--) {
        const code = str.charCodeAt(i);
        if (code > 0x7f && code <= 0x7ff) s++;
        else if (code > 0x7ff && code <= 0xffff) s += 2;
        if (code >= 0xdc00 && code <= 0xdfff) i--;
    }
    return s;
}

export function makeRandomString(len: number, chars: string): string {
    const numChars = chars.length;
    return Array(len)
        .fill(undefined)
        .map(() => chars[Math.min(Math.floor(Math.random() * numChars), numChars - 1)])
        .join("");
}

/**
 * Encode a string to base64url encoding which is safe for URLs.
 *
 * Take for example "Hello?+Test/=":
 * - base64: "SGVsbG8/K1Rlc3QvPQ==" is not URL-safe
 * - base64url: "SGVsbG8_K1Rlc3QvPQ" is URL-safe
 */
export function base64UrlEncode(str: string): string {
    return Buffer.from(str).toString("base64").replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

/**
 * Decode a base64url encoded string. Adding padding if necessary.
 */
export function base64UrlDecode(encodedStr: string): string {
    encodedStr = encodedStr.replace(/-/g, "+").replace(/_/g, "/");
    while (encodedStr.length % 4 !== 0) {
        encodedStr += "=";
    }
    return Buffer.from(encodedStr, "base64").toString();
}
