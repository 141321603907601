import "twin.macro";

import React from "react";
import type { TooltipProps } from "recharts";
import {
    ResponsiveContainer,
    Cell,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Rectangle,
    CartesianGrid,
} from "recharts";
import { isDefined } from "@glide/support";
import type { BuilderTheme } from "@glide/theme";
import { useBuilderTheme } from "../../hooks/use-builder-theme";

type PeriodKey = "planCost" | "additionalUsersCost" | "additionalUpdatesCost";

const X_LABELS: Record<PeriodKey, string> = {
    planCost: "Subscription",
    additionalUsersCost: "Additional users",
    additionalUpdatesCost: "Additional updates",
};

const getXColor = (key: PeriodKey, theme: BuilderTheme): string => {
    switch (key) {
        case "planCost":
            return theme.aqua400;
        case "additionalUsersCost":
            return theme.aqua500;
        case "additionalUpdatesCost":
            return theme.newBrand2;
        default:
            return theme.n200;
    }
};

export interface PeriodData {
    firstDay: Date;
    planCost: number;
    additionalUsersCost: number;
    additionalUpdatesCost: number;
    isProjected?: boolean;
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>): JSX.Element | null => {
    const theme = useBuilderTheme();
    if (!active || payload === undefined || payload.length === 0) {
        return null;
    }

    // extract projections from data for display
    const [
        {
            payload: { isProjected },
        },
    ] = payload;

    let total = 0;
    payload.forEach(p => {
        total += p.value ?? 0;
    });

    const customHR = <hr tw="h-px border-0 bg-n200" />;

    const firstDay = label as Date;
    return (
        <div tw="relative rounded-lg pointer-events-auto text-text-base text-builder-base bg-bg-front shadow-xl-dark">
            <div tw="flex justify-between items-end px-4 py-3">
                <div tw="font-semibold text-builder-xl">{`${firstDay.toLocaleString(undefined, {
                    month: "long",
                    timeZone: "UTC",
                })} ${firstDay.getUTCFullYear()}`}</div>
            </div>
            {customHR}
            <div tw="px-4 py-2.5">
                {payload.map(p =>
                    (p.value ?? 0) === 0 ? null : (
                        <div key={p.name} tw="flex justify-between py-0.5">
                            <div tw="flex items-center">
                                <div
                                    tw="mr-2 w-3 h-3 rounded-full"
                                    style={{ backgroundColor: getXColor(p.name as PeriodKey, theme) }}
                                />
                                <div tw="mr-4">{X_LABELS[(p.name ?? "") as PeriodKey]}</div>
                            </div>
                            <div>
                                $
                                {(p.value ?? 0).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </div>
                        </div>
                    )
                )}
            </div>
            {customHR}
            <div tw="flex justify-between px-4 py-3 font-semibold">
                <div>Total {isProjected ? <span>(Estimated)</span> : null}</div>
                <div>${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
        </div>
    );
};

interface MonthlySpendChartProps {
    readonly periods: PeriodData[];
}

const renderGreyLegendText = (value: string) => {
    return <span tw="text-text-base text-builder-base mr-1.5">{X_LABELS[value as PeriodKey]}</span>;
};

const renderYLabels = (value: number) => {
    return `$${value.toLocaleString()}`;
};

const renderXLabels = (value: Date) => {
    return value.toLocaleString(undefined, { month: "short", timeZone: "UTC" });
};

export const MonthlySpendChartV2: React.VFC<MonthlySpendChartProps> = props => {
    const { periods } = props;

    const theme = useBuilderTheme();
    const [activeTooltipIndex, setActiveTooltipIndex] = React.useState(-1);
    const getBarRadius = React.useCallback(
        (
            planSegment: PeriodKey,
            { additionalUpdatesCost, additionalUsersCost, planCost }: Partial<PeriodData>
        ): [number, number, number, number] => {
            let [topLeft, topRight, bottomRight, bottomLeft] = [5, 5, 5, 5];
            switch (planSegment) {
                case "planCost":
                    if (
                        (isDefined(additionalUsersCost) && additionalUsersCost > 0) ||
                        (isDefined(additionalUpdatesCost) && additionalUpdatesCost > 0)
                    ) {
                        topLeft = 0;
                        topRight = 0;
                    }

                    bottomRight = 5;
                    bottomLeft = 5;
                    break;

                case "additionalUsersCost":
                    if (isDefined(additionalUpdatesCost) && additionalUpdatesCost > 0) {
                        topLeft = 0;
                        topRight = 0;
                    }
                    if (isDefined(planCost) && planCost > 0) {
                        bottomRight = 0;
                        bottomLeft = 0;
                    }

                    break;

                case "additionalUpdatesCost":
                    topLeft = 5;
                    topRight = 5;

                    if (
                        (isDefined(additionalUpdatesCost) && additionalUpdatesCost > 0) ||
                        (isDefined(additionalUsersCost) && additionalUsersCost > 0)
                    ) {
                        bottomLeft = 0;
                        bottomRight = 0;
                    }
                    break;
            }

            return [topLeft, topRight, bottomRight, bottomLeft];
        },
        []
    );

    const { yAxisDomain, ticks } = React.useMemo(() => {
        const totals = periods.map(p => p.additionalUpdatesCost + p.planCost + p.additionalUsersCost);
        const largestSpend = Math.max(...totals);

        // this creates a bit of padding at the top of the graph vs always having the tallest spend at the top
        const maxValueToDisplay = Math.round(largestSpend * 1.05);

        // Note, that if we reduce the height of the chart you may not see all the axes.
        const horizontalBarsCount = 3;
        const interval = maxValueToDisplay / horizontalBarsCount + 1;

        const computedTicks = [];
        for (let i = 1; i <= horizontalBarsCount; i++) {
            computedTicks.push(Math.round(i * interval));
        }

        return {
            yAxisDomain: [0, maxValueToDisplay],
            ticks: computedTicks,
        };
    }, [periods]);

    return (
        <ResponsiveContainer height={300} width="100%">
            <BarChart
                barCategoryGap="30%"
                data={periods}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                onMouseMove={p => {
                    if (p.isTooltipActive) {
                        setActiveTooltipIndex(p.activeTooltipIndex ?? 0);
                    } else {
                        setActiveTooltipIndex(-1);
                    }
                }}>
                <XAxis
                    dataKey="firstDay"
                    tickLine={false}
                    axisLine={{ stroke: theme.n200A }}
                    tick={{ fontSize: 12, fill: theme.textPale }}
                    tickFormatter={renderXLabels}
                />
                <YAxis
                    tickLine={false}
                    axisLine={{ stroke: theme.n200A }}
                    tickFormatter={renderYLabels}
                    tick={{ fontSize: 12, fill: theme.textPale, stroke: theme.n200A }}
                    ticks={ticks}
                    domain={yAxisDomain}
                />
                <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: "transparent" }}
                    isAnimationActive={false}
                    allowEscapeViewBox={{ x: true, y: true }}
                />
                <CartesianGrid strokeDasharray="0" stroke={theme.n200A} vertical={false} />
                <Legend
                    iconType="circle"
                    iconSize={8}
                    formatter={renderGreyLegendText}
                    verticalAlign="top"
                    height={40}
                />
                <Bar
                    dataKey="planCost"
                    stackId="a"
                    fill={getXColor("planCost", theme)}
                    isAnimationActive={false}
                    shape={p => {
                        p.radius = getBarRadius("planCost", p);
                        if (p.isProjected) {
                            p.fillOpacity = 0.15;
                            p.stroke = p.fill;
                            p.strokeWidth = 1;
                        }
                        return <Rectangle {...p} />;
                    }}>
                    {periods.map((_entry, index) => (
                        <Cell
                            stroke={theme.b400}
                            strokeWidth={0}
                            key={`cell-${index}`}
                            opacity={activeTooltipIndex === index || activeTooltipIndex === -1 ? 1 : 0.3}
                            style={{ transition: "opacity 125ms ease-in-out" }}
                        />
                    ))}
                </Bar>
                <Bar
                    dataKey="additionalUsersCost"
                    stackId="a"
                    fill={getXColor("additionalUsersCost", theme)}
                    isAnimationActive={false}
                    shape={p => {
                        p.radius = getBarRadius("additionalUsersCost", p);
                        if (p.isProjected) {
                            p.fillOpacity = 0.15;
                            p.stroke = p.fill;
                            p.strokeWidth = 1;
                        }
                        return <Rectangle {...p} />;
                    }}>
                    {periods.map((_entry, index) => (
                        <Cell
                            stroke={theme.b400}
                            strokeWidth={0}
                            key={`cell-${index}`}
                            opacity={activeTooltipIndex === index || activeTooltipIndex === -1 ? 1 : 0.3}
                            style={{ transition: "opacity 125ms ease-in-out" }}
                        />
                    ))}
                </Bar>
                <Bar
                    dataKey="additionalUpdatesCost"
                    stackId="a"
                    fill={getXColor("additionalUpdatesCost", theme)}
                    isAnimationActive={false}
                    shape={p => {
                        p.radius = getBarRadius("additionalUpdatesCost", p);
                        if (p.isProjected) {
                            p.fillOpacity = 0.15;
                            p.stroke = p.fill;
                            p.strokeWidth = 1;
                        }
                        return <Rectangle {...p} />;
                    }}>
                    {periods.map((_entry, index) => (
                        <Cell
                            stroke={theme.b400}
                            strokeWidth={0}
                            key={`cell-${index}`}
                            opacity={activeTooltipIndex === index || activeTooltipIndex === -1 ? 1 : 0.3}
                            style={{ transition: "opacity 125ms ease-in-out" }}
                        />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
