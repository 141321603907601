import { disabledProps, styled } from "@glide/common-components";

export const WireBasePickerStyle = styled.label<{
    isEnabled?: boolean;
    isPlaceholder?: boolean;
}>`
    justify-content: stretch;

    ${p => p.isEnabled === false && disabledProps}

    transition: box-shadow 150ms ease-in-out;

    cursor: pointer;
    position: relative;
    overflow: hidden;

    .main-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: ${p => (p.isPlaceholder === true ? p.theme.textContextualXpale : p.theme.textContextualBase)};

        cursor: pointer;

        & input {
            opacity: 0;
            position: absolute;
        }

        label {
            cursor: pointer;
        }
        input[type="file"],
        input[type="file"]::-webkit-file-upload-button {
            cursor: pointer;
        }
    }

    &.style-dark,
    &.style-accent-bg {
        > .main-content {
            color: ${p => (p.isPlaceholder === true ? p.theme.w70A : p.theme.w100A)};
        }
    }

    .footer {
        margin-top: 4px;

        font-weight: 400;
        font-size: 12px;
        line-height: 17px;

        min-height: 17px;
        color: ${p => p.theme.fgColorLight};
    }

    .rightItem {
        flex-grow: 0;
        flex-shrink: 0;
        color: ${p => p.theme.textContextualPale};
        margin-left: 10px;
    }

    .leftItem {
        color: ${p => p.theme.textContextualPale};
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 10px;

        .image {
            border-radius: 5px;
            width: 32px;
            height: 32px;
            object-fit: cover;
        }
    }

    .preview-wrapper > div {
        display: block;
    }

    .progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        transition: width 50ms;

        background-color: ${p => p.theme.primaryAccentColor};
    }
`;
