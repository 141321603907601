import { GlideIcon } from "@glide/common";
import { Button } from "../button/button";

const Bubble: React.FC<React.PropsWithChildren> = ({ children }) => (
    <div tw="flex items-center p-1 bg-aqua200 rounded-full">{children}</div>
);

const AIFeaturesSettingsDetails: React.FC = () => (
    <ul tw="space-y-1.5 text-text-base">
        <li tw="flex gap-x-2">
            <div>
                <Bubble>
                    <GlideIcon tw="text-g500" kind="stroke" icon="st-check" iconSize={14} />
                </Bubble>
            </div>
            <div>We may train on table, column, screen, component, and action configurations</div>
        </li>

        <li tw="flex gap-x-2">
            <div>
                <Bubble>
                    <GlideIcon tw="text-r500 text-icon-pale" kind="stroke" icon="st-close" iconSize={14} />
                </Bubble>
            </div>
            <div>We never train on the content of tables, files, app user behavior, or personal information</div>
        </li>
    </ul>
);

type ModalProps = { displayName: string; onClick: () => void };

const ModalDescription: React.FC<ModalProps> = ({ displayName, onClick }) => (
    <div tw="mt-16 mb-11 w-[384px]">
        <div tw="h-[300px]">
            <h1 tw="font-size[26px] font-weight[600]">Enable Builder AI features for {displayName}</h1>

            <div tw="flex flex-col mt-6 space-y-2 font-size[15px] gap-4">
                <p tw="text-text-base">
                    Boost your build speed and customization with AI features.{" "}
                    <a tw="text-aqua500" href="https://www.glideapps.com/ai">
                        Learn more
                    </a>
                </p>
                <AIFeaturesSettingsDetails />
            </div>
        </div>
        <div>
            <Button
                buttonType="primary"
                variant="accent"
                size="xlg"
                isFullWidth={true}
                onClick={onClick}
                label="Enable AI"
                tw="bg-aqua400"
            />
            <p tw="font-size[12px] text-text-base mt-5">
                By clicking to enable you agree with our updated{" "}
                <a tw="text-aqua500" href="https://www.glideapps.com/legal/terms">
                    Terms of Service
                </a>{" "}
                and opt-in to AI training.
            </p>
        </div>
    </div>
);

export default ModalDescription;
