/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

const measurementID = glide.makeParameter({
    type: "string",
    name: "Measurement ID",
    description:
        "The Measurement ID of your Google Analytics property. You may have a GTM-ABC1234 or G-ABC1234567. [Learn more](https://support.google.com/analytics/answer/9304153#add-tag&zippy=%2Cadd-the-google-tag-directly-to-your-web-pages).",
    placeholder: "e.g. GTM-ABC1234",
    required: true,
});

export const plugin = glide.newPlugin({
    name: "Google Tag Manager",
    id: "gtag",
    description: "Track user behavior with Google Tag Manager",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/gtag.png",
    tier: "starter",

    parameters: { measurementID },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/google-tag-manager",
});

plugin.addHeader(({ measurementID }) => {
    // Google changed the shape of measurement IDs
    // https://support.google.com/tagmanager/answer/13543899?hl=en
    // So you may have a  GTM-ABC1234 or G-ABC1234567
    if (!(measurementID?.length === 11 || measurementID?.length === 12)) {
        return `<script>console.log("Invalid Google Tag Manager measurement ID")</script>`;
    }
    return `
        <!-- Google tag (gtag.js) -->
        <script async src="https://www.googletagmanager.com/gtm.js?id=${measurementID}"></script>
        <script>
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${measurementID}');
        </script>
        `;
});

plugin.setEventTracker((_params, event) => {
    const { gtag } = window as any;
    if (gtag === undefined) return;
    switch (event.kind) {
        case "load":
            gtag("event", "load");
            break;
        case "identify":
            gtag("event", "identify", event);
            break;
        case "navigate":
            gtag("event", "navigate", { title: event.title });
            break;
        case "action":
            gtag("event", event.name, event.data);
            break;
    }
});

const event = glide.makeParameter({
    type: "string",
    name: "Event name",
    required: true,
    useTemplate: "withLabel",
});

const properties = glide.makeParameter({
    type: "stringObject",
    name: "properties",
    description: "Event properties",
});

plugin.addClientAction({
    id: "send-gtag-event",
    name: "Track custom event",
    description: "Send a custom event to Google Tag Manager",
    parameters: { event, properties },
    needsClient: true,

    async execute(_context, { event, properties }) {
        const { gtag } = window as any;
        if (gtag === undefined) {
            return glide.Result.Fail("Google Tag Manager not yet loaded.", {
                isPluginError: false, // ad blockers cause this
            });
        }

        gtag("event", event, properties);
        return glide.Result.Ok();
    },
});
