import type { WireComponentHydrationResult } from "@glide/wire";
import { WireComponentKind } from "@glide/wire";

import type { WireActivitySpinnerComponent } from "./base-components";
import type { WireContainerComponent, WireFormContainerComponent } from "./fluent-components-containers";

export type WireAnyContainerComponent = WireContainerComponent | WireFormContainerComponent;

export const activitySpinnerComponent: WireActivitySpinnerComponent = {
    kind: WireComponentKind.ActivitySpinner,
};

export const activitySpinnerComponentHydrationResult: WireComponentHydrationResult = {
    component: activitySpinnerComponent,
    editsInContext: undefined,
    isValid: false,
};
