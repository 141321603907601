import classNames from "classnames";
import type { BaseTheme } from "@glide/base-theme";
import { useWireBackendContext } from "hooks/use-wire-backend";
import * as React from "react";
import { AutoSizer } from "react-virtualized/dist/es/AutoSizer";
import styled from "styled-components";
import { useBuilderScreenshooter } from "../../../../hooks/use-builder-screenshooter";

const Outer = styled.div`
    width: 100%;
    height: 100%;

    pointer-events: none;
    > * {
        pointer-events: auto;
    }
`;

export const SmartphoneStyle = styled.div<{ accent: string; round: number; tablet: boolean; removeInset?: boolean }>`
    position: fixed;

    --app-color-accent: ${p => p.accent};
    box-shadow: 0 0 0 5px #151515, 0 0 0 6px var(--app-color-accent), 0 -7.5px 1.5px rgba(255, 255, 255, 0.4),
        7.5px 0 1.5px rgba(255, 255, 255, 0.25), -7.5px 0 1.5px rgba(255, 255, 255, 0.4),
        0 7.5px 1.5px rgba(255, 255, 255, 0.25), 0 0 0 9px var(--app-color-accent), 6px 8px 16px rgba(0, 0, 0, 0.25),
        20px 32px 72px rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;
    width: ${p => (p.tablet ? 1024 : 393)}px;
    height: ${p => (p.tablet ? 768 : 852)}px;

    transition: opacity 50ms linear;

    &:after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: ${p => p.round}px;
        ${p => p.removeInset !== true && "box-shadow: 0 0 0 1px black, inset 0 0 0 1px black;"};
        pointer-events: none;
    }

    &.anim {
        transition: transform 150ms linear, opacity 50ms linear;
    }

    #loader-1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .preview-overlay {
        border-radius: ${p => p.round}px;

        position: absolute;
        left: 0;
        top: 0%;
        width: 100%;
        height: 100%;

        background-color: rgba(255, 255, 255, 0.47);
        backdrop-filter: blur(15px);
        font-size: 13px;

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .preview-text {
            height: 32px;
            margin-bottom: 4px;
            color: black;
        }

        .preview-btn {
            height: 32px;
            margin-bottom: 4px;
            color: ${p => p.theme.blue};
            cursor: pointer;
        }

        .main {
            font-weight: 600;
        }
    }

    .dark.preview-overlay {
        background-color: rgba(0, 0, 0, 0.47);

        .preview-text {
            color: white;
        }

        .preview-btn {
            color: ${p => p.theme.blue};
        }
    }
`;

// We need to do things this way because safari just straight ignores our border-radius
export const SmartphoneInner = styled.div<{ round: number; blur?: boolean; dontClip?: boolean }>`
    ${p => (p.dontClip !== true ? `clip-path: inset(0px round ${p.round}px);` : "")}
    border-radius: ${p => p.round}px;
    overflow: hidden;
    overflow: clip;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.edit {
        cursor: url("/images/finger-cursor1x.png") 2 2, auto;
        cursor: -webkit-image-set(url("/images/finger-cursor1x.png") 1x, url("/images/finger-cursor2x.png") 2x) 2 2,
            auto;
        * {
            cursor: url("/images/finger-cursor1x.png") 2 2, auto !important;
            cursor: -webkit-image-set(url("/images/finger-cursor1x.png") 1x, url("/images/finger-cursor2x.png") 2x) 2 2,
                auto !important;
        }
    }
`;

interface Props extends React.PropsWithChildren {
    readonly round: number;
    readonly tablet: boolean;
    readonly hidden?: boolean;
    readonly theme: BaseTheme;
    readonly currentIsVisible: boolean;
    readonly modalRoot: React.ReactNode;
}

export const Smartphone: React.FC<React.PropsWithChildren<Props>> = props => {
    const { currentIsVisible, theme, tablet, hidden = false, round, modalRoot } = props;

    const { backend } = useWireBackendContext();

    const onHide = React.useCallback(() => backend?.navigateToVisibleTab(), [backend]);

    const [showOverlay, setShowOverlay] = React.useState(false);

    // anytime the visibility state changes we want to use it
    React.useEffect(() => {
        setShowOverlay(!currentIsVisible);
    }, [currentIsVisible]);

    return (
        <PlayerSmartphone
            round={round}
            theme={theme}
            tablet={tablet}
            onHide={onHide}
            hidden={hidden}
            showOverlay={showOverlay}
            setShowOverlay={setShowOverlay}
            modalRoot={modalRoot}>
            {props.children}
        </PlayerSmartphone>
    );
};

interface PlayerSmartphoneProps extends Omit<Props, "currentIsVisible" | "currentIndex"> {
    onHide: () => void;
    showOverlay: boolean;
    setShowOverlay: (showOverlay: boolean) => void;
}

export const PlayerSmartphone: React.FC<React.PropsWithChildren<PlayerSmartphoneProps>> = props => {
    const { showOverlay, setShowOverlay, theme, tablet, hidden = false, onHide, modalRoot } = props;

    const ref = React.useRef<HTMLDivElement | null>(null);
    const lastWidth = React.useRef(0);
    const lastInnerSize = React.useRef(0);

    const width = tablet ? 1024 : 393;
    const height = tablet ? 768 : 852;

    // This hickity-hickity-hack is due to the other hack where we render this enter OutputEditor hidden
    // to prevent the data model from unloading because we haven't ported everything to NCM. This causes
    // all sorts of fun layouting problems that we are just sidestepping here.
    // FIXME: Remove this once apps are ported to NCM.
    const [opacity, setOpactiy] = React.useState("1");
    React.useEffect(() => {
        if (hidden) {
            setOpactiy("0");
        } else {
            lastWidth.current = 0;
            window.requestAnimationFrame(() => {
                setOpactiy("1");
            });
        }
    }, [hidden]);

    const isScreenshooting = useBuilderScreenshooter();

    return (
        <Outer ref={ref}>
            <AutoSizer>
                {(size: { width: number; height: number }) => {
                    let scale = 1;
                    if (size.height < height + 32) {
                        scale = size.height / (height + 32);
                    }

                    const outer = ref.current?.getBoundingClientRect();
                    if (outer === undefined) return null;

                    if (tablet && size.width < width + 32) {
                        scale = Math.min(scale, Math.max(0.6, size.width / (width + 32)));
                    }

                    let x = outer.left + size.width / 2 - Math.floor(width / 2);
                    const y = outer.top + size.height / 2 - Math.floor(height / 2);

                    const windowWidth = window.innerWidth;
                    // we always center when no left panel
                    if (outer.left !== 0) {
                        const minX = outer.left + 10;
                        const maxX = Math.max(minX, outer.right - width - 20);
                        const trueCenter = windowWidth / 2 - Math.floor(width / 2);

                        if (trueCenter >= minX && trueCenter <= maxX) {
                            x = trueCenter;
                        }
                    }

                    const style: React.CSSProperties = {
                        transform: `translateX(${Math.round(x)}px) translateY(${Math.round(y)}px) scale(${scale})`,
                        borderRadius: props.round,
                        opacity,
                    };

                    // we never want to animate if we were just sized at 0px. This is never a good thing.
                    const anim = lastWidth.current === windowWidth && lastInnerSize.current !== 0 && size.width !== 0;
                    lastInnerSize.current = size.width;
                    if (!anim) {
                        lastWidth.current = windowWidth;
                    }

                    return (
                        <ScaleRenderContext.Provider value={scale}>
                            {modalRoot}
                            <SmartphoneStyle
                                id="screen"
                                style={style}
                                className={anim ? "anim" : "no-anim"}
                                tablet={tablet}
                                accent={theme.primaryAccentColor}
                                round={props.round}
                                removeInset={isScreenshooting}>
                                <SmartphoneInner
                                    round={isScreenshooting ? 0 : props.round}
                                    blur={showOverlay}
                                    dontClip={isScreenshooting}>
                                    {props.children}
                                </SmartphoneInner>
                                {showOverlay && (
                                    <div
                                        className={classNames(
                                            "preview-overlay",
                                            (theme.themeOverlay === "colorDark" ||
                                                theme.themeOverlay === "dark" ||
                                                theme.themeOverlay === "pureblack") &&
                                                "dark"
                                        )}>
                                        <div className="preview-text">This screen’s visibility condition is false.</div>
                                        <button className="preview-btn" onClick={() => setShowOverlay(false)}>
                                            Preview anyway
                                        </button>
                                        <button className="preview-btn main" onClick={onHide}>
                                            Hide screen
                                        </button>
                                    </div>
                                )}
                            </SmartphoneStyle>
                        </ScaleRenderContext.Provider>
                    );
                }}
            </AutoSizer>
        </Outer>
    );
};

export const ScaleRenderContext = React.createContext(1);
