import { ClickOutsideContainer, GlideIcon } from "@glide/common";
import { useState } from "react";
import { useLayer } from "react-laag";
import "twin.macro";
import { PushDropdown } from "../push-dropdown/push-dropdown";
import { SimpleTooltip } from "../tooltip/simple-tooltip";

interface Props {
    value: string;
    onChange: (value: string) => void;
}

const timezones: string[] =
    typeof (Intl as any)?.supportedValuesOf === "function" ? (Intl as any).supportedValuesOf("timeZone") : [];

const now = new Date();

function formatTimeZone(timeZone: string): string {
    return timeZone.replaceAll("_", " ");
}

function formatTimeZoneShort(timeZone: string): string {
    const lastComponent = timeZone.split("/").pop();
    return formatTimeZone(lastComponent ?? timeZone);
}

function formatTimeZoneFull(timeZone: string): string {
    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: timeZone,
        timeZoneName: "short",
    });

    // This will give us a string that includes time and the short offset
    const parts = formatter.formatToParts(now);
    const timezoneEl = parts.find(part => part.type === "timeZoneName");
    if (timezoneEl !== undefined) {
        // Filter out the parts to only keep the timeZoneName
        let timeZonePart = timezoneEl.value;

        if (/^GMT[+-]\d+$/.test(timeZonePart)) {
            // Extract the sign (+ or -)
            const sign = timeZonePart[3];
            // Extract the number and convert it to an integer
            const number = parseInt(timeZonePart.slice(4), 10);
            // Format the number to ensure it's two digits
            const formattedNumber = number < 10 ? "0" + Math.abs(number) : Math.abs(number);
            // Return the formatted time zone
            timeZonePart = `GMT${sign}${formattedNumber}:00`;
        }
        return `${formatTimeZone(timeZone)} (${timeZonePart})`;
    }

    return formatTimeZone(timeZone);
}

export const TimezoneSelector: React.FC<Props> = p => {
    const { value, onChange } = p;
    const [open, setOpen] = useState(false);

    const { layerProps, renderLayer, triggerProps } = useLayer({
        isOpen: open,
        container: "portal",
        placement: "bottom-start",
        auto: true,
        triggerOffset: 4,
        overflowContainer: true,
    });

    return (
        <div tw="flex items-center gap-1">
            <GlideIcon kind="stroke" icon="st-language-website" tw="text-icon-pale" iconSize={16} />{" "}
            <SimpleTooltip
                text={`Timezone: ${formatTimeZone(value)}. Click to change.`}
                instant
                forceOneLine
                hide={open}>
                <button
                    tw="text-builder-sm text-text-pale hover:(text-text-dark underline)"
                    onClick={() => setOpen(true)}
                    {...triggerProps}>
                    {formatTimeZoneShort(value)}
                </button>
            </SimpleTooltip>
            {open &&
                renderLayer(
                    <ClickOutsideContainer onClickOutside={() => setOpen(false)}>
                        <div {...layerProps}>
                            <PushDropdown
                                searchable
                                items={timezones}
                                width={300}
                                selected={value}
                                descriptionForItem={t => formatTimeZoneFull(t)}
                                onItemSelect={t => {
                                    onChange(t);
                                    setOpen(false);
                                }}
                            />
                        </div>
                    </ClickOutsideContainer>
                )}
        </div>
    );
};
