import "twin.macro";

import { GlideIcon } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { TextComponentStyle } from "@glide/component-utils";
import { GlideDateTime } from "@glide/data-types";
import { AppKind } from "@glide/location-common";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { getBrowserLanguage } from "@glide/support";

import { Img } from "../../../components/img/img";
import { Text } from "../../../components/text/text";

const ONE_MINUTE_IN_MILLISECONDS = 60000;
const ONE_HOUR_IN_MILLISECONDS = 3600000;
const ONE_DAY_IN_MILLISECONDS = 86400000;
const ONE_WEEK_IN_MILLISECONDS = 604800000;
const ONE_YEAR_IN_MILLISECONDS = 31556952000;

const relativeFormatter = new Intl.RelativeTimeFormat(getBrowserLanguage(), {
    style: "narrow",
});

function getWantedTime(date: GlideDateTime | undefined | null): string | undefined {
    const timeDiff = date?.compareTo(GlideDateTime.now()) ?? 0;
    const timeDiffToCompare = Math.abs(timeDiff);
    if (timeDiffToCompare > ONE_YEAR_IN_MILLISECONDS) {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "short",
            day: "numeric",
        };
        const dateFormat = new Intl.DateTimeFormat(getBrowserLanguage(), options);
        return dateFormat.format(date?.asTimeZoneAwareDate());
    } else if (timeDiffToCompare > ONE_WEEK_IN_MILLISECONDS) {
        const options: Intl.DateTimeFormatOptions = {
            month: "short",
            day: "numeric",
        };
        const dateFormat = new Intl.DateTimeFormat(getBrowserLanguage(), options);
        return dateFormat.format(date?.asTimeZoneAwareDate());
    } else if (timeDiffToCompare > ONE_DAY_IN_MILLISECONDS) {
        const days = Math.floor(timeDiff / ONE_DAY_IN_MILLISECONDS);
        return relativeFormatter.format(days, "day");
    } else if (timeDiffToCompare > ONE_HOUR_IN_MILLISECONDS) {
        const hours = Math.floor(timeDiff / ONE_HOUR_IN_MILLISECONDS);
        return relativeFormatter.format(hours, "hour");
    } else if (timeDiffToCompare > ONE_MINUTE_IN_MILLISECONDS) {
        const minutes = Math.floor(timeDiff / ONE_MINUTE_IN_MILLISECONDS);
        return relativeFormatter.format(minutes, "minutes");
    }

    return getLocalizedString("justNow", AppKind.Page);
}

interface ChatAuthorAndTimeProps {
    name: string | null | undefined;
    date: GlideDateTime | null | undefined;
    className?: string;
}

export const ChatAuthorAndTime: React.VFC<ChatAuthorAndTimeProps> = p => {
    const { name, date, className } = p;

    const time = getWantedTime(date);

    return (
        <div tw="flex items-center" className={className}>
            <Text tw="text-ellipsis overflow-hidden whitespace-nowrap" variant={TextComponentStyle.small}>
                {name}
            </Text>
            <Text tw="px-1 text-text-contextual-xpale" variant={TextComponentStyle.regular}>
                ·
            </Text>
            <Text tw="text-ellipsis overflow-hidden whitespace-nowrap" variant={TextComponentStyle.small}>
                {time}
            </Text>
        </div>
    );
};

interface CommentAuthorAndTimeProps {
    name: string | null | undefined;
    photo: string | null | undefined;
    date: GlideDateTime | null | undefined;
    appID: string;
}

export const CommentAuthorAndTime: React.VFC<CommentAuthorAndTimeProps> = p => {
    const { name, photo, date, appID } = p;

    const time = getWantedTime(date);
    const screenSize = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(screenSize);

    const nameVariant = isMobile ? TextComponentStyle.small : TextComponentStyle.headlineXXXSmall;
    const timeVariant = isMobile ? TextComponentStyle.small : TextComponentStyle.regular;
    return (
        <div tw="flex items-center py-1 pl-1 pr-2 gap-x-1 rounded-full bg-n100A page-md:(bg-transparent p-0 rounded-none gap-x-2) max-w-[calc(100% - 32px)]">
            <UserAvatar photo={photo} size="small" appID={appID} />
            <Text tw="text-ellipsis overflow-hidden whitespace-nowrap" variant={nameVariant}>
                {name}
            </Text>
            <Text tw="page-md:hidden text-text-contextual-xpale" variant={TextComponentStyle.small}>
                ·
            </Text>
            <Text
                tw="page-md:text-text-contextual-xpale text-ellipsis overflow-hidden whitespace-nowrap"
                variant={timeVariant}
            >
                {time}
            </Text>
        </div>
    );
};

interface UserAvatarProps {
    readonly photo: string | null | undefined;
    readonly size: "large" | "small";
    readonly appID: string;
}
export const UserAvatar: React.VFC<UserAvatarProps> = p => {
    const { photo, size, appID } = p;

    return (
        <Img
            className={size}
            tw="w-6 h-6 [&.large]:(w-8 h-8) rounded-full page-md:rounded-[5px] page-md:[&.large]:(rounded-full)"
            src={massageImageUrl(
                photo ?? "",
                {
                    thumbnail: true,
                },
                appID
            )}
            alternate={
                <div
                    className={size}
                    tw="w-6 h-6 [&.large]:(w-8 h-8) rounded-full page-md:rounded-[5px] page-md:[&.large]:(rounded-full) bg-n200 flex items-center justify-center"
                >
                    <GlideIcon tw="text-n500 w-4 h-4" kind="stroke" icon="st-user" />
                </div>
            }
        />
    );
};
