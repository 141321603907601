import type { WireAppTheme } from "@glide/theme";
import { formatLocalizedString, getAnyLocalizedString, getLocalizedString } from "@glide/localization";
import type { IconImage } from "@glide/app-description";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { isRunningInNativeIos } from "@glide/common-core/dist/js/native-hooks";
import { isEmailPinEnabled } from "@glide/common-core/dist/js/utility/pins";
import { isValidEmailAddress } from "@glide/support";
import isString from "lodash/isString";
import * as React from "react";
import "twin.macro";

import type { BaseProps } from "../custom-sign-in-props";
import { ExternalAuthProviderButtons } from "../google-sso";
import { UserAgreementButtons } from "./user-agreements";
import { WireField } from "../../../renderers/wire-field/wire-field";
import { MadeWithGlide } from "../../made-with-glide";
import { SignInBranding } from "../sign-in-branding";
import { GreetingStyles } from "../greeting-styles";
import { type PagesLoginSource, getDefaultPagesGreeting } from "@glide/auth-controller-core";
import { SignUpFooter } from "../../sign-in/sign-up-footer";
import { SignInPrimaryButton, SignInSecondaryButton } from "../../sign-in-v2/sign-in-buttons";

interface Props extends BaseProps {
    readonly theme: WireAppTheme;
    readonly pagesSource?: PagesLoginSource;
    readonly onPageSourceChanged?: (newPageSource: PagesLoginSource) => void;
    readonly iconImage?: IconImage;
    readonly appID: string;
}

export const WireEmailInput: React.VFC<Props> = p => {
    const {
        greeting,
        description,
        userEmail,
        pin,
        canContinue,
        canPressPin,
        error,
        isSending,
        iconImage,
        theme,
        pinTarget,
        pinMethod,
        userAgreed,
        appFeatures,
        authMethod,
        pagesSource,
        appTitle,
        allowSaveLogin,
        onPressedContinue,
        onEmailChange,
        onPinChange,
        onPressedPin,
        onUserAgreedChange,
        onPageSourceChanged,
        onUserUpdateSaveLogin,
        onPressedBack,
        canCustomizeSignIn,
        removeBranding,
        appID,
    } = p;
    const showSignInWithEmailPin = isEmailPinEnabled(appFeatures);
    const currentState = showSignInWithEmailPin ? p.currentState : undefined;

    const logo = theme.pagesSignInLogo;

    const onFormSubmit = React.useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();

            const userAgreementRequired = theme.requiresAgreement === true;
            const userAgreedValidated = !userAgreementRequired || (userAgreementRequired && userAgreed);

            if (onPressedContinue && userAgreedValidated) onPressedContinue();
        },
        [onPressedContinue, theme.requiresAgreement, userAgreed]
    );

    // REVIEW: Why do we pull from features here?
    const appKind = getAppKindFromFeatures(appFeatures);
    const { requiresAgreement } = theme;

    const userAgreementRequired = requiresAgreement === true;
    const userAgreedValidated = !userAgreementRequired || (userAgreementRequired && userAgreed);

    const canProgressToPin = canContinue === true && isValidEmailAddress(userEmail) && userAgreedValidated;

    const isGreetingDefault = getDefaultPagesGreeting(pagesSource, appTitle, appKind) === greeting;

    const hasBackgroundImage = isString(theme.signInBackground) && canCustomizeSignIn;

    let topContent: React.ReactNode;
    let buttons: React.ReactNode;

    if (currentState === "challenge") {
        topContent = (
            <div>
                <SignInBranding
                    theme={theme}
                    iconImage={iconImage}
                    appFeatures={appFeatures}
                    logo={logo}
                    canCustomizeSignIn={canCustomizeSignIn}
                    appID={appID}
                />

                <GreetingStyles
                    greeting={getLocalizedString(pinMethod === "sms" ? "checkYourMessages" : "checkYourEmail", appKind)}
                />
                <form action="" onSubmit={onFormSubmit}>
                    <WireField
                        isSignInEmail={true}
                        data-test="app-pin-input"
                        title={formatLocalizedString("weHaveSentAPinTo", [pinTarget ?? userEmail], appKind)}
                        isEnabled={!isSending}
                        placeholder="00000"
                        value={pin}
                        onChange={onPinChange}
                        dataType="tel"
                        dataTypePattern="[0-9]*"
                        error={error}
                        autoFocus={true}
                    />
                </form>
            </div>
        );

        buttons = (
            <div>
                <SignInPrimaryButton
                    onClick={onPressedContinue}
                    disabled={canContinue === false}
                    label={getLocalizedString("signIn", appKind)}
                />
                <SignInSecondaryButton
                    onClick={onPressedPin}
                    disabled={canPressPin === false}
                    label={getLocalizedString("iNeedAnotherPin", appKind)}
                />
            </div>
        );
    } else if (currentState === "request-access-prompt" || currentState === "request-access-complete") {
        topContent = (
            <div>
                <SignInBranding
                    theme={theme}
                    iconImage={iconImage}
                    appFeatures={appFeatures}
                    logo={logo}
                    canCustomizeSignIn={canCustomizeSignIn}
                    appID={appID}
                />

                <GreetingStyles greeting={greeting} />
                <div tw="text-n600A">{description}</div>
                <div
                    data-test="request-access-email"
                    tw="w-full h-12 rounded-[10px] mt-2.5 [font-size: 16px] [line-height: 48px] select-text text-n800A font-semibold">
                    {userEmail}
                </div>
            </div>
        );

        const buttonText = isSending
            ? getAnyLocalizedString("requestingAccess", appKind)
            : getAnyLocalizedString("requestAccess", appKind);

        buttons = (
            <div>
                {currentState === "request-access-prompt" && (
                    <SignInPrimaryButton onClick={onPressedContinue} disabled={isSending} label={buttonText} />
                )}
                {onPressedBack !== undefined && (
                    <SignInSecondaryButton
                        onClick={onPressedBack}
                        label={getAnyLocalizedString("backToSignIn", appKind)}
                    />
                )}
            </div>
        );
    } else {
        topContent = (
            <div>
                <SignInBranding
                    theme={theme}
                    iconImage={iconImage}
                    appFeatures={appFeatures}
                    logo={logo}
                    canCustomizeSignIn={canCustomizeSignIn}
                    appID={appID}
                />

                <GreetingStyles greeting={greeting} />
                {showSignInWithEmailPin && (
                    <form action="" onSubmit={onFormSubmit}>
                        <WireField
                            isSignInEmail={true}
                            title={description ?? undefined}
                            data-test="app-email-input"
                            isEnabled={!isSending}
                            placeholder={getLocalizedString("enterYourEmail", appKind)}
                            value={userEmail}
                            onChange={onEmailChange}
                            dataType="email"
                            error={error}
                            autoFocus={true}
                        />
                    </form>
                )}
                <UserAgreementButtons
                    appFeatures={appFeatures}
                    theme={theme}
                    userAgreed={userAgreed}
                    allowSaveLogin={allowSaveLogin}
                    onUserAgreedChange={onUserAgreedChange}
                    onUserUpdateSaveLogin={onUserUpdateSaveLogin}
                />
            </div>
        );

        let buttonText = getLocalizedString("continue", appKind);
        if (isSending) {
            buttonText = getLocalizedString("sendingPin", appKind);
        } else if (!isGreetingDefault) {
            buttonText =
                pagesSource === "modal-sign-up"
                    ? getLocalizedString("signUp", appKind)
                    : getLocalizedString("signIn", appKind);
        }

        buttons = (
            <div>
                {showSignInWithEmailPin && (
                    <SignInPrimaryButton onClick={onPressedContinue} disabled={!canProgressToPin} label={buttonText} />
                )}

                {!isRunningInNativeIos() && (
                    <ExternalAuthProviderButtons
                        authMethod={authMethod}
                        {...p}
                        userAgreed={userAgreedValidated}
                        appKind={appKind}
                    />
                )}
                {/*
                 * The email input shows the error if it's enabled. If the email input isn't enabled
                 * we have to show it here instead.
                 */}
                {!showSignInWithEmailPin && error !== undefined && (
                    <div tw="flex justify-center mt-2 text-text-danger text-center">{error}</div>
                )}
            </div>
        );
    }

    return (
        <>
            {topContent}
            {buttons}
            <SignUpFooter pagesSource={pagesSource} onPageSourceChanged={onPageSourceChanged} appKind={appKind} />
            {!removeBranding ? (
                <MadeWithGlide position={hasBackgroundImage ? "relative" : "static"} background="none" />
            ) : (
                <div tw="gp-sm:h-8"></div>
            )}
        </>
    );
};
