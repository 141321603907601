export function deepSet(
    obj: Record<string, any> | undefined,
    path: readonly (string | number)[] | undefined,
    setVal: unknown,
    createPath: boolean = false
): any {
    if (obj === undefined || path === undefined) return obj;
    if (path.length === 0) return obj;
    let cur = obj;
    for (let i = 0; i < path.length - 1; i++) {
        let val = cur[path[i]];
        if (val === null || (val === undefined && !createPath)) return obj;
        if (createPath && val === undefined) {
            val = {};
            cur[path[i]] = val;
        }
        if (typeof val !== "object") return obj;
        cur = val;
    }
    cur[path[path.length - 1]] = setVal;
    return obj;
}