import {
    type PluginConfig,
    getEnumProperty,
    getNumberProperty,
    getSecretProperty,
    getSourceColumnProperty,
    getStringProperty,
    getSwitchProperty,
    makeEnumProperty,
    makeNumberProperty,
    makeSecretProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
    type PropertyDescription,
    type AutomationPluginTrigger,
    makeArrayProperty,
    getArrayProperty,
} from "@glide/app-description";
import type { ValidatePluginResult } from "@glide/common-core/dist/js/firebase-function-types";
import { isParameterActive, preProcessParameters } from "@glide/generator/dist/js/plugins/parameters";
import type { ParameterProps } from "@glide/plugins";
import { isSyncEnumValues } from "@glide/plugins-codecs";
import { parseJSONSafely, parseNumber } from "@glide/support";
import { assert, DefaultMap, definedMap, hasOwnProperty, panic } from "@glideapps/ts-necessities";
import React from "react";
import { v4 as uuid } from "uuid";

import { GlideDropdown } from "../glide-dropdown/glide-dropdown";
import { LabeledSwitchToggle } from "../switch-toggle/switch-toggle";
import { GeneratedKeyPairConfigurator } from "./generated-key-pair-configurator";
import { SequencingInput, UuidPickerInput } from "./sequencing-input";

import "twin.macro";
import { ColumnSelect } from "../column-select/column-select";
import { getPrimitiveNonComputedNonHiddenColumns, type TableGlideType } from "@glide/type-schema";
import { asString } from "@glide/computation-model-types";
import { HighlightTextArea } from "../highlight-textarea/highlight-textarea";
import { Button } from "../button/button";
import InputLabel from "../input-label/input-label";

interface Props {
    readonly appID: string;
    readonly onChange: (parameters: PluginConfig["parameters"], isSettingDefault: boolean) => void;
    readonly pluginID: string | undefined;
    readonly configID: string | undefined;
    readonly parameterValues: PluginConfig["parameters"];
    readonly parameters: Record<string, ParameterProps>;
    readonly errorState: ValidatePluginResult | undefined;
    readonly userProfileTable: TableGlideType | undefined;
}

export const PluginParametersConfigBase: React.VFC<Props> = props => {
    const { appID, parameters, userProfileTable, errorState, configID, pluginID, onChange, parameterValues } = props;

    const processedParameters = React.useMemo(() => preProcessParameters(parameters, ""), [parameters]);

    // If we did't keep these default secret IDs we'd generate a new one every
    // time this renders, which would result in trying to re-fetch the secret
    // and the input glitching in and out of the loading state.
    const defaultSecretIDForParameter = React.useMemo(() => {
        void pluginID;
        void configID;

        return new DefaultMap<string, string>(() => uuid());
    }, [pluginID, configID]);

    const [isPristine, setIsPristine] = React.useState(true);
    return (
        <>
            {processedParameters.map(param => {
                if (
                    param.type !== "string" &&
                    param.type !== "url" &&
                    param.type !== "secret" &&
                    param.type !== "boolean" &&
                    param.type !== "number" &&
                    param.type !== "enum" &&
                    param.type !== "generatedKeyPair" &&
                    param.type !== "json" &&
                    param.type !== "stringArray"
                ) {
                    return panic(`We don't support ${param.type} parameters in the plugin config`);
                }

                const key = param.parameterName;
                const desc = parameterValues[key];

                if (!isParameterActive(param, processedParameters, parameterValues, undefined)) return null;

                function setDesc(newDesc: PropertyDescription | undefined, isSettingDefault: boolean = false) {
                    const newConfig: AutomationPluginTrigger["parameters"] = {
                        ...parameterValues,
                    };
                    if (newDesc !== undefined) {
                        newConfig[key] = newDesc;
                    } else {
                        delete newConfig[key];
                    }

                    onChange(newConfig, isSettingDefault);
                }

                if (param.isUserProfileProperty) {
                    const userProfileColumns = definedMap(
                        userProfileTable,
                        getPrimitiveNonComputedNonHiddenColumns
                    )?.filter(c => c.isUserSpecific !== true);
                    return (
                        <React.Fragment key={key}>
                            <ColumnSelect
                                allowNone={false}
                                allowProtected={false}
                                allowedColumns={{
                                    userProfile: userProfileColumns ?? [],
                                    userProfileRow: false,
                                    userProfileTable,
                                    context: [],
                                    contextRow: false,
                                    contextTable: undefined,
                                    priorStepsOutputs: undefined,
                                }}
                                displayLabel={param.name}
                                selectedValue={getSourceColumnProperty(desc)}
                                helpText={param.description}
                                navigateToColumn={async () => undefined}
                                updateProperty={c => {
                                    if (c === undefined) return;
                                    setDesc(makeSourceColumnProperty(c));
                                }}
                            />
                        </React.Fragment>
                    );
                } else if (param.type === "enum") {
                    assert(isSyncEnumValues(param.values));

                    let enumValue = getEnumProperty(desc);
                    if (enumValue === undefined) {
                        enumValue = param.defaultValue ?? param.values[0].value;
                        // This is not nice. We're running code with many complex side effects at render time.
                        // FIXME: How do we untangle this?
                        setDesc(makeEnumProperty(enumValue), true);
                    }
                    const selected = param.values.find(x => x.value === enumValue);

                    return (
                        <React.Fragment key={key}>
                            <GlideDropdown
                                label={param.name}
                                items={param.values}
                                descriptionForItem={item => ({ name: item.label, icon: item.icon })}
                                selected={selected}
                                onItemSelect={item => setDesc(makeEnumProperty(item.value))}
                            />
                        </React.Fragment>
                    );
                } else if (param.type === "generatedKeyPair") {
                    return (
                        <React.Fragment key={key}>
                            <GeneratedKeyPairConfigurator appID={appID} desc={desc} setDesc={setDesc} />
                        </React.Fragment>
                    );
                }

                let defaultValueToSet: PropertyDescription | undefined;
                let value: string | number | boolean | string[] | undefined;
                let originalSecretID: string | undefined;
                let secretID: string | undefined;
                let useUuidPicker = false;
                let showSecret = false;
                if (param.type === "secret") {
                    // Hidden secrets don't get a `value`, so they won't show
                    // a configurator, but they'll still show an error message
                    // if there is one.
                    if (param.display !== "hidden") {
                        // We don't use `defaultValueToSet` for the secret because
                        // secrets have no default values.
                        value = "";
                        originalSecretID = getSecretProperty(desc);
                        secretID = originalSecretID ?? defaultSecretIDForParameter.get(param.name);
                        showSecret = param.display === "plain";
                        if (param.display === "uuid-picker") {
                            useUuidPicker = true;
                        }
                    }
                } else if (param.type === "boolean") {
                    const maybeValue = getSwitchProperty(desc);
                    if (maybeValue !== undefined) {
                        value = maybeValue;
                    } else {
                        value = param.defaultValue ?? false;
                        defaultValueToSet = makeSwitchProperty(value);
                    }
                } else if (param.type === "number") {
                    const maybeValue = getNumberProperty(desc);
                    if (maybeValue !== undefined) {
                        value = maybeValue;
                    } else {
                        value = param.defaultValue ?? 0;
                        defaultValueToSet = makeNumberProperty(value);
                    }
                } else if (param.type === "json") {
                    const maybeValue = getStringProperty(desc);
                    if (maybeValue !== undefined) {
                        value = maybeValue;
                    } else {
                        value = asString(param.defaultValue);
                        defaultValueToSet = makeStringProperty(value);
                    }
                } else if (param.type === "stringArray") {
                    let maybeValue = getArrayProperty(desc);
                    if (maybeValue === undefined) {
                        // We store this as a string with comma sparated values.
                        // so we're building that up here
                        maybeValue = getStringProperty(desc)?.split(",");
                    }

                    if (maybeValue !== undefined) {
                        value = maybeValue.map(val => asString(val));
                    } else {
                        value = [];
                        defaultValueToSet = makeArrayProperty(value);
                    }
                } else {
                    const maybeValue = getStringProperty(desc);
                    if (maybeValue !== undefined) {
                        value = maybeValue;
                    } else {
                        value = hasOwnProperty(param, "defaultValue") ? asString(param.defaultValue) : "";
                        defaultValueToSet = makeStringProperty(value);
                    }
                }

                if (defaultValueToSet !== undefined) {
                    // This is not nice. We're running code with many complex side effects at render time.
                    // FIXME: How do we untangle this?
                    setDesc(defaultValueToSet, true);
                }

                const error = errorState?.errors.find(x => x.parameter === key);
                const warn = errorState?.warnings.find(x => x.parameter === key);

                if (param.type === "json") {
                    if (typeof value !== "string") return null;

                    return (
                        <React.Fragment key={key}>
                            <JSONInputHandler
                                name={param.name}
                                value={value}
                                onChange={newValue => {
                                    setDesc(makeStringProperty(newValue), false);
                                }}
                                placeholder={param.placeholder}
                            />
                        </React.Fragment>
                    );
                }

                if (param.type === "stringArray") {
                    if (!Array.isArray(value)) return null;

                    return (
                        <React.Fragment key={key}>
                            <StringArrayInputHandler
                                name={param.name}
                                values={value}
                                onChange={newValues => {
                                    setDesc(makeArrayProperty(newValues), false);
                                }}
                            />
                        </React.Fragment>
                    );
                }

                return (
                    <React.Fragment key={key}>
                        {useUuidPicker && (typeof value === "string" || typeof value === "number") && (
                            <UuidPickerInput
                                {...param}
                                appID={appID}
                                value={value.toString()}
                                secretIsSet={originalSecretID !== undefined}
                                secretID={secretID}
                                showSecret={true}
                                isPristine={isPristine}
                                isRequired={param.required === true}
                                setSecretID={(overrideSecretID: string | undefined) => {
                                    setIsPristine(false);
                                    if (originalSecretID !== undefined && overrideSecretID === undefined) return;
                                    const newSecretID = overrideSecretID ?? secretID;
                                    assert(newSecretID !== undefined);
                                    setDesc(makeSecretProperty(newSecretID));
                                }}
                                clearSecretID={() => {
                                    if (originalSecretID === undefined) return;
                                    setIsPristine(false);
                                    defaultSecretIDForParameter.delete(param.name);
                                    setDesc(undefined);
                                }}
                            />
                        )}
                        {!useUuidPicker && (typeof value === "string" || typeof value === "number") && (
                            <SequencingInput
                                {...param}
                                appID={appID}
                                value={value.toString()}
                                secretIsSet={originalSecretID !== undefined}
                                secretID={secretID}
                                showSecret={showSecret}
                                isPristine={isPristine}
                                onChange={newValue => {
                                    setIsPristine(false);
                                    let newDesc: PropertyDescription;
                                    if (secretID !== undefined) {
                                        if (originalSecretID !== undefined) return;
                                        newDesc = makeSecretProperty(secretID);
                                    } else if (typeof value === "number") {
                                        const num = parseNumber(newValue);
                                        if (num === undefined) return;
                                        newDesc = makeNumberProperty(num);
                                    } else {
                                        newDesc = makeStringProperty(newValue);
                                    }

                                    setDesc(newDesc);
                                }}
                            />
                        )}
                        {typeof value === "boolean" && (
                            <label tw="flex items-center justify-between h-8 cursor-pointer">
                                <div>{param.name}</div>
                                <LabeledSwitchToggle
                                    tw="ml-2 text-builder-lg"
                                    size="default"
                                    toggled={value}
                                    onChange={newValue => setDesc(makeSwitchProperty(newValue))}
                                />
                            </label>
                        )}
                        {error !== undefined && error.message !== "Required" && (
                            <div tw="text-builder-sm text-r400 -mt-2 mb-1 margin-left[35%]">{error.message}</div>
                        )}
                        {warn !== undefined && (
                            <div tw="text-builder-sm text-y400 -mt-2 mb-1 margin-left[35%]">{warn.message}</div>
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};

interface JSONInputHandlerProps {
    readonly value: string;
    readonly onChange: (value: string) => void;
    readonly name: string;
    readonly placeholder?: string;
}

const JSONInputHandler: React.FC<JSONInputHandlerProps> = p => {
    const { value: initialValue, onChange: setDesc, name, placeholder } = p;
    const timeoutRef = React.useRef<NodeJS.Timeout>();
    const isMountedRef = React.useRef(true);
    const [value, setValue] = React.useState(initialValue);
    React.useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const [hasError, setHasError] = React.useState(false);

    const checkIfValidAndUpdateDescription = React.useCallback(
        (maybeValue: string) => {
            if (!isMountedRef.current) return;

            if (maybeValue === "") {
                setHasError(false);
                return;
            }
            const parsedValue = parseJSONSafely(maybeValue);
            setHasError(parsedValue === undefined);
            if (parsedValue !== undefined) {
                setDesc(JSON.stringify(parsedValue));
            }
        },
        [setDesc]
    );

    const onChangeImpl = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (timeoutRef.current !== undefined) clearTimeout(timeoutRef.current);
            const currentValue = e.target.value;
            setValue(currentValue);
            timeoutRef.current = setTimeout(() => checkIfValidAndUpdateDescription(currentValue), 750);
        },
        [checkIfValidAndUpdateDescription]
    );

    const onBlur = React.useCallback(() => {
        if (timeoutRef.current !== undefined) clearTimeout(timeoutRef.current);
        checkIfValidAndUpdateDescription(value);
    }, [checkIfValidAndUpdateDescription, value]);

    return (
        <div tw="flex flex-col gap-2">
            <p>{name}</p>
            <HighlightTextArea
                tw="min-h-[72px] [textarea]:placeholder:whitespace-pre-wrap"
                placeholder={placeholder}
                onChange={onChangeImpl}
                onBlur={onBlur}
                value={value}
                error={hasError ? "Invalid JSON" : undefined}
            />
        </div>
    );
};

interface StringArrayInputHandlerProps {
    readonly name: string;
    readonly values: string[];
    readonly onChange: (values: string[]) => void;
}

const StringArrayInputHandler: React.FC<StringArrayInputHandlerProps> = p => {
    const { values, onChange, name } = p;

    const onAddItem = React.useCallback(() => {
        onChange([...values, ""]);
    }, [onChange, values]);

    return (
        <div tw="flex flex-col gap-2">
            <div tw="flex items-center justify-between">
                <p>{name}</p>
                <Button
                    buttonType="minimal"
                    size="sm"
                    variant="accent"
                    icon="st-plus-stroke"
                    iconType="iconLeading"
                    onClick={onAddItem}
                    label={"Add item"}
                />
            </div>
            <ul tw="flex flex-col gap-2">
                {values.map((value, i) => (
                    <li tw="flex gap-2 items-center" key={i}>
                        <InputLabel
                            tw="w-full m-0"
                            value={value}
                            onChange={e => {
                                const newValues = [...values];
                                newValues[i] = e.target.value;
                                onChange(newValues);
                            }}
                        />
                        <Button
                            buttonType="minimal"
                            variant="default"
                            size="xsm"
                            label=""
                            icon="st-trash"
                            iconType="iconOnly"
                            onClick={() => {
                                const newValues = [...values];
                                newValues.splice(i, 1);
                                onChange(newValues);
                            }}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};
