import type { ThemeOverlay } from "./codecs";

export enum Color {
    Pink = "#731358",
    Orange = "#D85722",
    Yellow = "#D99E00",
    Olive = "#789436",
    Steel = "#2F6783",
    Nightblue = "#3B47C6",
    Purple = "#613DC7",
    Slate = "#4B5D67",
}

export function staticHtmlStatusBarThemeColor(themeOverlay: ThemeOverlay | undefined): string | undefined {
    if (themeOverlay === undefined) return undefined;
    // Note that this is all a bunch of duplication from @glide/common/theme.
    // We CAN NOW get to there from here, but we didn't yet, hence the duplication.
    // Browsers _will_ actually set this dynamically once the real theme comes in,
    // but we want a fallback for initial loading.
    if (themeOverlay === "pureblack" || themeOverlay === "dark" || themeOverlay === "colorDark") return "#000000";
    if (themeOverlay === "purewhite") return "#FFFFFF";
    return "#F6F6F6";
}

export type { BaseTheme, ThemeOverlay } from "./codecs";
export { baseThemeCodec } from "./codecs";
export { systemFontFamily } from "./fonts";
