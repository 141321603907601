import type { TableGlideType } from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { memoizeFunction } from "@glide/support";
import type { ContextTableTypes } from "./backends";

export const makeContextTableTypes = memoizeFunction(
    "makeContextTableTypes",
    (tables: InputOutputTables, containingScreen?: TableGlideType): ContextTableTypes => ({
        ...tables,
        containingScreen,
    })
);
