import { styled } from "@glide/common";
import { disabledProps } from "@glide/common-components";
import { assertNever } from "@glideapps/ts-necessities";
import { css } from "styled-components";

interface BorderProps {
    warn: boolean;
    isOpen: boolean;
}

const borderProps = css<BorderProps>`
    border-radius: ${p => p.theme.formControlRadius};
    border: 1px solid ${p => p.theme.dropdownButton.borderNormal};
    .error & {
        border: 1px solid
            ${p => {
                if (p.warn) {
                    return p.theme.errorColor;
                } else if (p.isOpen) {
                    return p.theme.dropdownButton.borderOpen;
                }
                return p.theme.dropdownButton.borderNormal;
            }};
    }

    &:focus {
        border: 1px solid ${p => p.theme.b400};
    }
`;

const buttonProps = css<{ isOpen: boolean }>`
    background-color: ${p => (p.isOpen ? p.theme.dropdownButton.bgActive : p.theme.dropdownButton.bgColor)};
    transition: background-color ${p => p.theme.transitionBase};

    &:hover,
    &:focus {
        background-color: ${p => p.theme.dropdownButton.bgHover};
    }
`;

const inlineProps = css<{ isOpen: boolean }>`
    background-color: ${p => (p.isOpen ? p.theme.inlineDropdownButton.bgActive : p.theme.inlineDropdownButton.bgColor)};
`;

export const ErrorMessage = styled.div`
    margin-top: 4px;
    font-size: 12px;
    color: ${p => p.theme.errorColor};
    .warn & {
        color: ${p => p.theme.y500};
    }
`;

export type HeightVariation = "medium" | "tall" | "2xtall";

interface WrapperProps {
    isOpen: boolean;
    warn: boolean;
    drawBorder: boolean;
    slim: boolean;
    isEnabled: boolean;
    isInline: boolean;
    heightVariation: HeightVariation;
}

export function getHeight(h: HeightVariation): number {
    switch (h) {
        case "medium":
            return 32;
        case "tall":
            return 40;
        case "2xtall":
            return 56;
        default:
            assertNever(h);
    }
}

function getPadding(h: HeightVariation): number {
    switch (h) {
        case "medium":
        case "tall":
            return 8;
        case "2xtall":
            return 20;
        default:
            assertNever(h);
    }
}

function getFontSize(h: HeightVariation): number {
    switch (h) {
        case "medium":
        case "tall":
            return 13;
        case "2xtall":
            return 15;
        default:
            assertNever(h);
    }
}

export const Wrapper = styled.button<WrapperProps>`
    position: relative;
    display: flex;
    align-items: center;

    width: 100%;
    height: ${p => getHeight(p.heightVariation)}px;
    font-weight: normal;
    font-size: ${p => getFontSize(p.heightVariation)}px;
    text-align: left;
    padding: 0 0 0 ${p => getPadding(p.heightVariation)}px;
    color: ${p => p.theme.textBase};

    ${p => p.drawBorder && borderProps}
    ${p => (p.isInline ? inlineProps : buttonProps)}

    cursor: pointer;
    ${p => !p.isEnabled && disabledProps}
`;

export const DropdownLabel = styled.div`
    min-width: 138px;
`;

export const DropdownValue = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    width: 0;
    display: flex;
`;

export const DropdownValueItem = styled.div`
    display: flex;
    align-items: center;

    min-width: 42px;
    flex-shrink: 1;
    overflow: hidden;

    .part-text {
        min-width: 0;
        flex-shrink: 1;
        overflow: hidden;
    }

    .caret {
        color: ${p => p.theme.textXpale};
        padding: 0;
        flex-shrink: 0;
    }

    :last-child {
        min-width: unset;
        flex-shrink: 0;

        > .caret:last-child {
            display: none;
        }

        > .part-text {
            flex-shrink: 0;
        }
    }
`;

export const IconWrapper = styled.div<{ isOpen?: boolean; heightVariation?: HeightVariation }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 6px;
    height: ${p => (p.heightVariation === "medium" ? 20 : 24)}px;
    width: ${p => (p.heightVariation === "medium" ? 20 : 24)}px;
    color: ${p => (p.isOpen ? p.theme.iconBase : p.theme.iconBase)};
    transition: color ${p => p.theme.transitionFast};
`;

export const TranformableIconWrapper = styled(IconWrapper)`
    color: ${p => p.theme.iconBase};
`;
