import type { MonotoneIcons } from "@glide/plugins";
import { showUploadFailedRetryModal } from "@glide/common-core/dist/js/components/in-app-modals";
import { getLocalizedString } from "@glide/localization";
import {
    type UploadProgressHandler,
    type UploadSession,
    isUploadFileResponseError,
} from "@glide/common-core/dist/js/components/types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { ImagePickerSourceHint } from "@glide/component-utils";
import type {
    WireFilePickerComponent,
    WireImagePickerComponent,
} from "@glide/fluent-components/dist/js/base-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { nullToUndefined } from "@glide/support";
import { ValueChangeSource, type WireEditableValue } from "@glide/wire";
import * as React from "react";

import { useFileUploader } from "../../utils/use-file-uploader";
import type { WireRenderer } from "../wire-renderer";
import WireCoreFilePicker from "./wire-core-file-picker";
import { MultipleFilePicker } from "./wire-multiple-file-picker";
import { MultipleImagePicker } from "./wire-multiple-image-picker";
import { useIsHoverable } from "@glide/common-components";

interface Props extends React.PropsWithChildren {
    readonly title?: string;
    readonly value: WireEditableValue<string>;
    readonly backend: WireBackendInterface;
    readonly mimePattern?: string;
    readonly valueLabel?: string;
    readonly iconSource: MonotoneIcons;
    readonly isRequired: boolean;
    readonly showPreview: boolean;
    readonly isBusy: WireEditableValue<boolean>;
    readonly includeFilename: boolean;
    readonly capture?: string;
}

const Picker: React.VFC<Props> = p => {
    const { showPreview, valueLabel, value, title, backend, mimePattern, iconSource, isRequired, isBusy, capture } = p;
    const { appKind } = backend;

    const valueTokenRef = React.useRef<string>();
    valueTokenRef.current = value.onChangeToken;

    const isBusyTokenRef = React.useRef<string>();
    isBusyTokenRef.current = isBusy.onChangeToken;

    // NOTE: We're assuming that whenever this is changed we're also doing
    // something else to trigger a repaint.
    const isCancelledRef = React.useRef(false);

    const [lastValue, setLastValue] = React.useState<string>();
    const [uploadSession, setUploadSession] = React.useState<UploadSession>();

    const uploadAppFile = useFileUploader();

    const setUploadSessionAndIsBusy = React.useCallback(
        (session: UploadSession | undefined) => {
            setUploadSession(session);
            if (isBusyTokenRef.current !== undefined) {
                backend.valueChanged(isBusyTokenRef.current, session !== undefined, ValueChangeSource.Internal);
            }
        },
        [backend]
    );

    const onChange = React.useCallback(
        (newValue: string) => {
            if (valueTokenRef.current === undefined) return;
            backend.valueChanged(valueTokenRef.current, newValue, ValueChangeSource.User);
        },
        [backend]
    );

    const onNewURL = React.useCallback(
        (newURL: string | undefined) => {
            if (newURL !== undefined) {
                onChange(newURL);
            }

            return newURL !== undefined;
        },
        [onChange]
    );

    const onDropFile = React.useCallback(
        (file: File, progressHandler: UploadProgressHandler, includeFilename?: boolean) => {
            async function f() {
                setLastValue(value.value);
                isCancelledRef.current = false;
                if (uploadSession !== undefined) {
                    uploadSession.cancel();
                }
                const session = uploadAppFile(
                    backend.appID,
                    appKind,
                    getAppFacilities(),
                    file,
                    "image-picker",
                    progressHandler,
                    () => undefined,
                    includeFilename ?? false
                );
                setUploadSessionAndIsBusy(session);

                const clearUploadSession = () => {
                    if (uploadSession === session) {
                        setUploadSessionAndIsBusy(undefined);
                    } else {
                        if (isBusyTokenRef.current !== undefined) {
                            backend.valueChanged(isBusyTokenRef.current, false, ValueChangeSource.Internal);
                        }
                    }
                };

                while (true) {
                    const response = await session.attempt();
                    if (isCancelledRef.current) return false;
                    if (!isUploadFileResponseError(response)) {
                        clearUploadSession();
                        return onNewURL(response.path);
                    }
                    if (!(await showUploadFailedRetryModal(response, appKind))) {
                        clearUploadSession();
                        return false;
                    }
                }
            }

            return f();
        },
        [value.value, uploadSession, uploadAppFile, backend, appKind, setUploadSessionAndIsBusy, onNewURL]
    );

    const onCancel = React.useCallback(
        (cancelled: boolean) => {
            if (cancelled && !isCancelledRef.current) {
                isCancelledRef.current = true;
                if (uploadSession !== undefined) {
                    uploadSession.cancel();
                    setUploadSessionAndIsBusy(undefined);
                }
                onNewURL(lastValue);
            }
        },
        [lastValue, onNewURL, uploadSession, setUploadSessionAndIsBusy]
    );

    return (
        <WireCoreFilePicker
            value={value.value}
            valueLabel={valueLabel}
            isEnabled={value.onChangeToken !== undefined}
            mimePattern={mimePattern}
            iconSource={iconSource}
            showPreview={showPreview}
            title={title}
            appKind={appKind}
            onDropFile={onDropFile}
            onChange={onChange}
            onCancel={onCancel}
            isRequired={isRequired}
            includeFilename={p.includeFilename}
            capture={capture}
        />
    );
};

export const WireImagePicker: WireRenderer<WireImagePickerComponent> = React.memo(p => {
    const { editableValue, sourceHint } = p;

    const { isMultiple, value } = editableValue;

    const title = nullToUndefined(p.title);

    const isCameraOnly = sourceHint === ImagePickerSourceHint.CameraOnly;

    const isTouchDevice = !useIsHoverable();

    const defaultLabelValue =
        isCameraOnly && isTouchDevice
            ? getLocalizedString("takeAPicture", p.backend.appKind)
            : getLocalizedString("chooseAnImage", p.backend.appKind);

    const defaultIconSource = isCameraOnly && isTouchDevice ? "mt-page-camera-upload" : "mt-page-image-upload";

    if (isMultiple) {
        return (
            <MultipleImagePicker
                title={title}
                editableValue={value}
                isRequired={p.isRequired}
                backend={p.backend}
                sourceHint={sourceHint}
            />
        );
    }

    // This android mime pattern hack is an arbitrary and can be anything,
    // But we only want to do it when we want to allow gallery selection.
    // https://github.com/glideapps/glide/issues/29427
    // https://issuetracker.google.com/issues/317289301#comment12
    const mimePattern =
        sourceHint === ImagePickerSourceHint.Any ? "image/*,android/glide-force-camera-workaround" : "image/*";

    return (
        <Picker
            title={title}
            value={value}
            valueLabel={value.value || defaultLabelValue}
            backend={p.backend}
            showPreview={true}
            mimePattern={mimePattern}
            iconSource={defaultIconSource}
            isRequired={p.isRequired}
            isBusy={p.isBusy}
            includeFilename={false}
            capture={isCameraOnly ? "environment" : undefined}
        />
    );
});

export const WireFilePicker: WireRenderer<WireFilePickerComponent> = React.memo(p => {
    const { editableValue } = p;

    const { isMultiple, value } = editableValue;

    const title = nullToUndefined(p.title);

    if (isMultiple) {
        return <MultipleFilePicker title={title} editableValue={value} isRequired={p.isRequired} backend={p.backend} />;
    }

    return (
        <Picker
            title={nullToUndefined(p.title)}
            valueLabel={value.value || getLocalizedString("chooseAFile", p.backend.appKind)}
            showPreview={false}
            value={value}
            backend={p.backend}
            iconSource={"mt-page-file-upload"}
            isRequired={p.isRequired}
            isBusy={p.isBusy}
            includeFilename={p.includeFilename}
        />
    );
});
