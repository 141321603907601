import { styled } from "@glide/common";
import { disabledProps } from "@glide/common-components";
import type { HeightVariation } from "./private/glide-dropdown-button-style";
import { getHeight } from "./private/glide-dropdown-button-style";

export const GlideDropdownWrapper = styled.div<{ isEnabled: boolean }>`
    position: relative;

    font-size: 12px;
    line-height: 16px;

    margin: 8px 0;

    &.slim {
        margin: 0;
    }

    color: ${p => p.theme.fgColorMedium};

    display: flex;
    flex-direction: column;
    .highlight-container > *:not(.error) {
        background-color: ${p => p.theme.dropdownButton.bgColor};
        border-radius: 6px;
    }

    .no-border > * {
        border-radius: 0;
    }
    ${p => !p.isEnabled && disabledProps}

    .dropdown-label {
        padding-bottom: 8px;
    }
`;

export const DropdownLabel = styled.div<{ heightVariation?: HeightVariation }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    // Label were losing alignment when we show warn messages in dropdown
    // Fixed height and align-self to the start solves this problem
    height: ${p => getHeight(p.heightVariation ?? "medium")}px; // This is the height of the GlideDropdownButton
    align-self: flex-start;
    min-width: 0;
`;

export const IconWrapper = styled.a`
    margin: 4px 0 0 4px;
    width: 14px;
    height: 14px;
    color: ${p => p.theme.fgColorLight};
    cursor: pointer;

    :hover {
        color: ${p => p.theme.fgColorDark};
    }
`;
