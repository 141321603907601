// We use the English localized strings as a basis for the types,
// which are used by all other languages.

// International English tends to effectively be GB English.
// The US is usually the odd one out.
// However, iOS 13.4 removed the US country code out of the default
// English so we have to default to that.
export const englishLocalizedStrings = {
    newItem: "New item",
    add: "Add",
    edit: "Edit",
    done: "Done",
    back: "Back",
    close: "Close",
    cancel: "Cancel",
    send: "Send",
    post: "Post",
    link: "Link",
    yes: "Yes",
    no: "No",
    all: "All",
    favorites: "Favourites",
    image: "Image",
    audio: "Audio",
    data: "Data",
    noItems: "none",
    oneItem: "1 item",
    twoToFourItems: "{0} items",
    numberOfItems: "{0} items",
    notes: "Notes",
    writeANote: "Write a note…",
    addANewNote: "Add new note…",
    editNote: "Edit note",
    chat: "Chat",
    comment: "Comment",
    newMessage: "New message",
    writeMessage: "Write message…",
    writeAComment: "Write a comment…",
    addComment: "Add comment…",
    noUpcomingEvents: "No upcoming events.",
    thereAreNoItemsYet: "There are no items yet.",
    loading: "Loading…",
    error: "Error",
    // Search bar placeholder
    search: "Search",
    share: "Share",
    shareApp: "Share app",
    shareThisAppBy: "Share this app by scanning the code with your phone's camera.",
    copyLink: "Copy Link",
    linkCopied: "Link Copied",
    whatsYourName: "What's your name?",
    name: "Name",
    emailAddress: "Email address",
    signIn: "Sign In",
    signOut: "Sign Out",
    signingIn: "Signing in…",
    weWillEmailYouAPin: "We'll email you a pin to sign in",
    emailAddressDoesntHaveAccess: "The email address doesn’t have access to this app. Please try another.",
    getPin: "Get pin",
    sendingPin: "Sending pin…",
    iAlreadyHaveAPin: "I already have a pin",
    iNeedAnotherPin: "I need another pin",
    enterPin: "Enter pin",
    weHaveSentAPinTo: "We've sent a pin to {0}",
    checkYourSpamFolderForPin: "Check your spam folder if you don't receive it.",
    delete: "Delete",
    deleteItem: "Delete item",
    areYouSureYouWantToDelete: "Are you sure you want to delete this item?",
    actionCannotBeUndone: "This action cannot be undone.",
    editItem: "Edit item",
    loadingComments: "Loading comments",
    noComments: "No comments",
    oneComment: "1 comment",
    moreComments: "{0} comments",
    favorite: "Favourite",
    justNow: "Just now",
    nMinutes: "{0}m",
    nHours: "{0}h",
    today: "Today",
    yesterday: "Yesterday",
    madeWithGlideApps: "made with glideapps.com",
    enterPassword: "Enter password",
    oneHour: "1h",
    addThisAppToYourHomeScreen: "Add this app to your home screen for easy access and a better experience.",
    tapThenAddPrefix: "Tap",
    tapThenAddSuffix: 'then "Add to Home Screen"',
    oneEvent: "1 EVENT",
    moreEvents: "{0} EVENTS",
    shareLink: "Share Link",
    upload: "Upload",
    uploading: "Uploading…",
    areYouSureYouWantToDeleteThisImage: "Are you sure you want to delete this image?",
    copied: "Copied",
    submit: "Submit",
    required: "Required",
    buy: "Buy",
    paymentMethod: "Payment Method",
    shippingAddress: "Shipping Address",
    addressLineN: "Address Line {0}",
    city: "City",
    state: "State",
    zip: "Postal code",
    country: "Country",
    total: "Total",
    submitOrder: "Place order",
    yourOrderHasBeenPlaced: "Your order has been placed.",
    retry: "Retry",
    catchallPaymentError: "Something went wrong. Please try again later.",
    cardRequiresAuth: "We don't yet support 3D Secure. Please try with a different card.",
    cardInfoRejected: "There was something wrong with your payment information. Please try again.",
    purchaseItemHasBeenRemoved: "This item is no longer available.",
    checkout: "Checkout",
    formSubmissionSuccess: "Success",
    listIsFull: "Storage for this app is full. Some items may not be displayed.",
    mapIsFull: "Free map limit reached. Some locations may not be displayed.",
    cardPaymentDeclined: "Your payment was declined. Please try a different payment method.",
    copyGeneral: "Copy",
    copySpecific: "Copy {0}",
    areYouSureYouWantToDeleteThisFile: "Are you sure you want to delete this file?",
    filter: "Filter",
    filterBy: "Filter by",
    filterAndSort: "Filter and Sort",
    addToCart: "Add to cart",
    yourCartIsEmpty: "Your cart is empty",
    added: "Added",
    noResultsFound: "No results found",
    sortBy: "Sort by",
    random: "Random",
    default: "Default",
    welcome: "Welcome",
    pleaseEnterPassword: "Please enter the password",
    pleaseEnterEmail: "Please enter your email address",
    weHaveEmailedPinTo: "We've sent you a PIN to {0}",
    enterEmail: "Enter email",
    wrongPassword: "Wrong password",
    wrongPasscode: "Wrong passcode",
    continue: "Continue",
    tryAgain: "Try again",
    liked: "Liked",
    disliked: "Disliked",
    seeAll: "See All",
    signInWithGoogle: "Sign in with Google",
    noMessages: "No messages",
    profile: "Profile",
    contactInformation: "Contact Information",
    phoneNumber: "Phone",
    optionalInputPlaceholderLabel: "{0} (Optional)",
    webviewBlockedByContent: "{0} does not allow itself to be embedded",
    webviewNotPossible: '"{0}" cannot be opened',
    clear: "Clear",
    clearAll: "Clear all",
    signature: "Signature",
    addSignature: "Add Signature",
    signatureMessage: "Sign using your finger",
    useMyCurrentLocation: "Use my current location",
    retrievingLocation: "Retrieving location…",
    info: "Info",
    sendFeedback: "Send feedback",
    from: "From",
    message: "Message",
    about: "About",
    brandingHero1: "This app was made from a spreadsheet, <b>for free</b>.",
    brandingHero2: "Create an app like this for free with <b>glideapps.com</b>",
    brandingConfirmText1: "Make an app like this",
    brandingConfirmText2: "Learn more",
    brandingCancelText1: "Not now",
    userAgreement: "I agree to the Terms & Conditions",
    author: "Author",
    support: "Support",
    youllBeSignedOut: "Someone else is using this app",
    freePasswordAppsExplanation:
        "Private personal apps can be used by one user at a time. Please upgrade to allow many users at the same time.",
    pleaseEnterValidEmail: "Please enter a valid email address.",
    thisActionCouldntBeTriggered: "This action couldn't be triggered",
    thisAppMustBeUpgraded: "This app must be upgraded in order to proceed",
    ok: "OK",
    security: "Security",
    reportThisApp: "Report this app",
    reason: "Reason",
    displaysInappropriateContent: "Displays inappropriate content",
    impersonationAndIntellectualProperty: "Impersonation and Intellectual Property",
    privacySecurityOrDeception: "Privacy, Security, or Deception",
    spamAndMinimumFunctionality: "Spam and Minimum Functionality",
    other: "Other",
    copyThisApp: "Copy this app",
    showFullscreen: "Show fullscreen",
    shareOn: "Share on",
    mustBeGreaterThan: "Minimum value is {0}.",
    mustBeLessThan: "Maximum value is {0}.",
    cantAddNewSignatures: "Can't add new signatures",
    thisAppMustBeUpgradedToAllowMoreSignatures: "This app must be upgraded to allow more signatures.",
    browseForFile: "Browse…",
    uploadProgress: "Uploading… {0}%",
    uploadFailedInline: "Upload failed. Please try again.",
    cancelUpload: "Cancel Upload?",
    uploadProgressWillBeLost: "All progress will be lost if you cancel the upload.",
    uploadFailedTitle: "Upload Failed",
    uploadFailedDetails: "The file could not be uploaded. Please try again later.",
    fileReadErrorTitle: "File is Unreadable",
    fileReadErrorDetails: "The contents of the file could not be read. Check file access permissions and try again.",
    networkDownTitle: "Network is Offline",
    uploadNetworkDownDetails: "The upload could not be completed because the device is offline.",
    fileTooLargeTitle: "File is Too Large",
    fileTooLargeDescription: "The file is {0} in size. Files must be under {1} in size to upload.",
    more: "more",
    charactersRequired: `{0} characters required`,
    newEvent: "New Event",
    pickADateAndTime: "Set date and duration",
    scanCode: "Scan Code",
    precisionLabel: "Precision",
    coarsePrecision: "Coarse",
    normalPrecision: "Normal",
    finePrecision: "Fine",
    toleranceLabel: "Tolerance",
    looseTolerance: "Loose",
    normalTolerance: "Normal",
    tightTolerance: "Tight",
    saveLoginTitle: "Allow login cookie?",
    saveLoginPrimay: "Keep me signed in",
    saveLoginSecondary: "Allow authentication cookie",
    shouldSaveAuthCookie: "Do you want to allow an authentication cookie to keep you signed into the app?",
    allow: "Allow",
    doNotAllow: "Don't allow",
    trialExpiredTitle: "Private Pro Trial Expired",
    trialExpiredDescription: "Please upgrade to use your app",
    trialExpiredMessageCreator: "Contact Developer",
    networkAppearsOffline: "It appears that you're offline. Please check your network connection.",
    noCameraAccess: "Could not initialize camera.",
    scanQuotaModalHeader: "Scan Quota Reached",
    scanQuotaModalBody:
        "This app has exhausted its Barcode Scanning quota. Please upgrade to increase the allowed amount of scans.",
    madeWithGlidePlay: "Made with Glide from a Google Sheet, without writing any code.",
    scanCodePreview: "Scan code to install. <a>Learn how</a>",
    authoredBy: "<i>by</i> {0}",
    uploadQuotaReached: "The upload quota for this app has been reached.",
    youAreOffline: "You are offline.",
    youAreOfflineSubtitle: "You can view this app, but not make changes.",
    chooseAFile: "Choose a file…",
    chooseAnImage: "Choose an image…",
    takeAPicture: "Take a picture",
    requestAppUserAccessHeader: "You need access",
    requestAppUserAccessDescription: "Request access, or switch to an account with access to sign in to this page.",
    requestingAccess: "Requesting access…",
    requestAccess: "Request access",
    requestSent: "Request sent",
    requestCompleteAppUserAccessDescription: "You will get an email letting you know if your request was approved.",
    resendAccessRequest: "Resend access request",
    backToSignIn: "Back to sign in",
    privateInviteLinkError: "Could not access app with private invite link",
    enterYourEmail: "Enter your email",
    checkYourEmail: "Check your email",
    checkYourMessages: "Check your messages",
    viewProfile: "View Profile",
    maxChoices: `Max {0}. `,
    continueWithGoogle: "Continue with Google",
    signUp: "Sign Up",
    logInWithOptions: "Log in{0}",
    signUpWithOptions: "Sign up{0}",
    appTitleOptions: " to {0}",
    customerLogIn: "Customer log in",
    dontHaveAnAccount: "Don't have an account yet?",
    alreadyHaveAnAccount: "Already have an account?",
    addItem: "Add item",
    form: "Form",
    notValidUrl: "is not a valid URL.",
    httpOrHttps: "Please make sure it starts with http or https.",
    uncategorized: "Uncategorized",
    titleElips: "Title…",
    descriptionElips: "Description…",
    day: "Day",
    month: "Month",
    week: "Week",
    event: "Event",
    eventTitle: "Event title",
    save: "Save",
    aQuickMessage: "A quick message…",
    addTo: `Add to {0} `,
    yourPinIs: `Your pin is {0}`,
    openAppDirectly: `Open app directly`,
    wasMadeWith: `{0} was made with {1}`,
    yourPinForIs: `Your pin for {0} is {1}`,
    yourAccessPinIs: "Your access pin is",
    ssoAuthError: "Logging in with single sign-on failed. Please contact the app developer.",
    ssoButtonError: "Single sign-on is not available. Please alert the app developer to contact support.",
    thisComponentIsAvailableOnPlan: "This component is available on the {0} plan.",
    saving: "Saving…",
    next: "Next",
    previous: "Previous",
    chooseSomething: "Choose something",
    writeSomething: "Write something…",
    mobileSidebarInstallApp: "Install app",
    calendarShowMore: "+{0} more",
    iDidntGetPinEmail: "I didn’t get the email",
    checkYourSpam: "Check your spam",
    checkYourSpamMessage: "Check your spam folder. Allow a few minutes for it to arrive. Pin was sent to {0}",
    notFoundInSpam: "No email in the spam folder",
    searchAllEmail: "Search all mail",
    searchAllEmailMessage:
        "We’ve sent you another pin email. Check again and, if needed, search for emails from {0}. Pin was sent to {1}",
    notFoundEmailSearch: "I still cannot find the email",
    iFoundThePin: "I found the pin",
    contactIT: "Contact IT",
    contactITMessage: "We can help you send instructions to your IT team to allow emails from {0}",
    composeEmail: "Compose email",
    pinITEmailSubject: "Unable to receive pin emails",
    pinITEmailBody:
        "Hello,\n\nI'm having trouble receiving pin emails from this app ({0}). I have attached instructions on how to troubleshoot email deliverability problems.\n\nPin emails are being sent from {1}. Could you check if emails from this address are in our company's spam or quarantine filters?\n\nYou can find more information in this doc: {2}\n\nThank you for your help!",
    // New! You can generate additional keys like this:
    // node add-translation.js youHaveNChancesLeft "You have {0} chances left"
    uploadNImages: "Upload {0} Images",
    dragImagesHereOr: "Drag images here or",
    addNImages: "Add {0} images",
};
