export function dirDisplayName(dir: string): string {
    return dir
        .split("-")
        .slice(1)
        .map(s => s[0].toUpperCase() + s.slice(1))
        .join(" ");
}

// Helper for internal use, the `iconName` is not a thing, always specify the full path.
export function getGlideIcon(iconName: string): string {
    return `path:/svg/stroke/${iconName}.svg`;
}

// Helper for internal use, the `iconName` is not a thing, always specify the full path.
export function getMonotoneGlideIcon(iconName: string): string {
    return `path:/svg/monotone/${iconName}.svg`;
}
