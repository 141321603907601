import type { AppDescription, UserFeatures, BuilderActionsForApp } from "@glide/app-description";
import type { TypeSchema } from "@glide/type-schema";
import type { MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import type { EminenceFlags } from "@glide/billing-types";
import type { SearchableColumns, WireBackendCallbacks } from "@glide/wire";
import { type WireBackend, WirePageBackend, getPageNavigationPathTransformer } from "@glide/hydrated-ui";
import { makeSimpleAppDescriptionContext } from "@glide/generator/dist/js/components/simple-ccc";
import { AppKind } from "@glide/location-common";
import { assert, panic } from "@glideapps/ts-necessities";
import { ignore, localStorageGetItem, localStorageSetItem } from "@glide/support";
import { getNetworkStatusChangeObservable } from "@glide/common-core/dist/js/hooks/use-network-status";
import { QuotaKind, areQuotaLimitsEqual } from "@glide/common-core/dist/js/Database/quotas";
import * as React from "react";
import { useCleanupMemo } from "../utils/use-cleanup-memo";
import { DeviceFormFactor } from "@glide/common";

interface WireBackendResult {
    readonly backend?: WireBackend;
    readonly urlStack: string[];
    readonly onBack: () => void;
}

export type AuthState = "authenticated" | "needs-authentication" | "not-authenticated";

export function usePlayerWireBackend(
    appEnvironment: MinimalAppEnvironment,
    appDescription: AppDescription | undefined,
    appHost: string,
    fallbackAuthorName: string,
    builderActions: BuilderActionsForApp | undefined,
    schema: TypeSchema | undefined,
    eminenceFlags: EminenceFlags,
    ownerFeatures: UserFeatures,
    precomputedSearchableColumns: SearchableColumns | undefined,
    startPath: string,
    authState: AuthState
): WireBackendResult {
    const { appID, dataStore, localDataStore } = appEnvironment;

    const urlStack = React.useRef<string[]>(startPath === undefined ? [] : [startPath]);

    const backendRef = React.useRef<WireBackend>();
    const onBack = React.useCallback(() => {
        urlStack.current.pop();
        backendRef.current?.urlPathChanged(urlStack.current.slice(-1)[0] ?? "");
    }, []);
    const lastEminenceFlags = React.useRef(eminenceFlags);

    const backend = useCleanupMemo(() => {
        if (
            appDescription === undefined ||
            schema === undefined ||
            dataStore === undefined ||
            localDataStore === undefined
        ) {
            return [undefined, () => undefined];
        }
        const adc = makeSimpleAppDescriptionContext(
            appID,
            AppKind.Page,
            appDescription,
            builderActions,
            schema,
            ownerFeatures,
            eminenceFlags
        );
        const backendCallbacks: WireBackendCallbacks = {
            loadStateValues: key => {
                try {
                    const s = localStorageGetItem(`state-${key}`);
                    if (s === undefined) return {};
                    return JSON.parse(s);
                } catch {
                    return {};
                }
            },
            saveStateValues: (key, values) => localStorageSetItem(`state-${key}`, JSON.stringify(values)),
            getPaymentInformationForBuyButton: () => {
                return panic("No buy button in Pages");
            },
            setPreviewAsUser: ignore,
        };

        const urlPathOrPrevious = backendRef.current ?? urlStack.current.slice(-1)[0];
        if (typeof urlPathOrPrevious !== "string" && urlPathOrPrevious !== undefined) {
            assert(urlPathOrPrevious instanceof WirePageBackend);
        }
        const result = new WirePageBackend(
            appEnvironment,
            adc,
            precomputedSearchableColumns,
            getNetworkStatusChangeObservable(),
            backendCallbacks,
            false,
            getPageNavigationPathTransformer(),
            authState === "authenticated",
            appHost,
            fallbackAuthorName,
            DeviceFormFactor.Tablet,
            window.location.href,
            urlPathOrPrevious,
            "player"
        );

        if (
            !areQuotaLimitsEqual(
                lastEminenceFlags.current.quotas[QuotaKind.RowsUsed],
                eminenceFlags.quotas[QuotaKind.RowsUsed]
            )
        ) {
            // Handle ##dataStoreRowQuotaChange
            dataStore.resetFromUpstream();
        }

        lastEminenceFlags.current = eminenceFlags;

        return [result, () => setTimeout(() => result.retire(), 0)];
    }, [
        appDescription,
        schema,
        dataStore,
        localDataStore,
        appID,
        builderActions,
        ownerFeatures,
        eminenceFlags,
        appEnvironment,
        precomputedSearchableColumns,
        authState,
        appHost,
        fallbackAuthorName,
    ]);

    backendRef.current = backend;

    return React.useMemo(() => ({ backend, urlStack: urlStack.current, onBack }), [backend, urlStack, onBack]);
}
