import * as React from "react";

export function useDebouncedValue<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

    const delayValue = Math.max(delay, 0);

    React.useEffect(() => {
        if (value === debouncedValue) return;
        if (delayValue > 0) {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delayValue);
            return () => {
                clearTimeout(handler);
            };
        } else {
            setDebouncedValue(value);
            return;
        }
    }, [value, delayValue, debouncedValue, setDebouncedValue]);

    return debouncedValue;
}
