import uuid from "uuid";

import { digitsAndLetters } from "@glide/support";

// Even though we now filter out "+" and "/" below, this still needs to accept them
// because there may be existing row IDs that contain them.
const rowIDRegex = /^[a-zA-Z0-9_./+-]{15,23}$/;

/**
 * Loosely validate a row ID. It will accept strings beyond what `makeRowID` generates
 *  to minimize the chance of rejecting any row IDs in use in the wild.
 */
export function looksLikeRowID(id: string): boolean {
    return rowIDRegex.test(id);
}

export function makeRowID(seed?: number): string {
    function makeRand(n: number) {
        const r = [];
        for (let i = 0; i < 16; i++) {
            r.push(Math.floor(Math.abs(Math.sin((n + 1) * (i + 1))) * 255));
        }
        return r;
    }

    const buf = Buffer.from(uuid(seed !== undefined ? { random: makeRand(seed) } : null, [] as number[], 0));
    let str = buf.toString("base64");
    // Make it more URL-friendly
    // https://community.glideapps.com/t/rowid-with-slash-can-that-be-avoided/11185/7
    str = str.replace(/\+/g, "-").replace(/\//g, ".");
    // Don't start with a special character
    // https://community.glideapps.com/t/row-id-with-leading-special-character-breaks-etl-integration/12065
    if (digitsAndLetters.indexOf(str[0]) < 0) {
        str = "a" + str;
    }
    // Remove padding - we won't need to decode it
    while (str.endsWith("=")) {
        str = str.substring(0, str.length - 1);
    }
    return str;
}
