import type { UserDataAndState } from "@glide/common-core/dist/js/Database";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import type { AppAndState, RootState } from "@glide/action-reducer-utils";
import { getCurrentAppAndState } from "@glide/action-reducer-utils";
import { useSelector } from "react-redux";

export function useBuilderSelector<T>(
    selector: (state: RootState) => T,
    equalityFn?: ((left: T, right: T) => boolean) | undefined
): T {
    return useSelector(selector, equalityFn);
}

export function useCurrentAppAndStateSelector<T>(
    selector: (state: AppAndState) => T,
    equalityFn?: ((left: T | undefined, right: T | undefined) => boolean) | undefined
): T | undefined {
    const appID = useAppID();
    return useBuilderSelector(s => {
        const currentAppAndState = getCurrentAppAndState(appID, s.apps);
        if (currentAppAndState === undefined) return undefined;

        return selector(currentAppAndState);
    }, equalityFn);
}

let mockedUseUserData: (() => UserDataAndState) | undefined;
export function useUserData() {
    // This is only used in tests and should be safe to use in a conditional.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return mockedUseUserData !== undefined ? mockedUseUserData() : useBuilderSelector(state => state.userData);
}

export function mockUserData(fn: typeof mockedUseUserData) {
    mockedUseUserData = fn;
}
