import { logError } from "@glide/support";
import { InputLabelBase } from "../input-label/input-label";
import * as React from "react";
import { GlideIcon, useDebouncedValue } from "@glide/common";
import "twin.macro";

interface AsyncDebouncedInputProps {
    readonly getAsyncValue: () => Promise<string>;
    readonly setAsyncValue: (newValue: string) => Promise<void>;
    readonly placeholder?: string;
}

export const AsyncDebouncedInput: React.VFC<AsyncDebouncedInputProps> = p => {
    const { getAsyncValue, setAsyncValue, placeholder } = p;

    const { value, error } = useInitialValue(getAsyncValue);

    if (error) {
        return <ErrorNotice />;
    }

    if (value === undefined) {
        return <LoadingNotice />;
    }

    return <AsyncDebouncedInputImpl initialValue={value} setAsyncValue={setAsyncValue} placeholder={placeholder} />;
};

interface InitialValue {
    readonly value: string | undefined;
    readonly error: boolean;
}

function useInitialValue(getValue: () => Promise<string>): InitialValue {
    const [error, setError] = React.useState(false);
    const [value, setValue] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        const fn = async () => {
            try {
                const fetchedValue = await getValue();
                setValue(fetchedValue);
            } catch (err: unknown) {
                logError(err);
                setError(true);
            }
        };

        void fn();
    }, [getValue]);

    return { value, error };
}

const LoadingNotice: React.VFC = () => {
    return (
        <div tw="relative">
            <InputLabelBase tw="my-0 bg-transparent!" value="" disabled={true}>
                <GlideIcon
                    tw="absolute left-2 opacity-50 text-text-base"
                    kind="stroke"
                    icon="st-half-spinner"
                    iconSize={24}
                    spin={true}
                />
            </InputLabelBase>
        </div>
    );
};

const ErrorNotice: React.VFC = () => {
    return <InputLabelBase tw="my-0 bg-transparent!" value="Something went wrong" disabled={true} />;
};

interface AsyncDebouncedInputImplProps {
    initialValue: string;
    setAsyncValue: (newValue: string) => Promise<void>;
    placeholder?: string;
}

const AsyncDebouncedInputImpl: React.VFC<AsyncDebouncedInputImplProps> = p => {
    const { initialValue, setAsyncValue, placeholder } = p;

    const [value, setValue] = React.useState(initialValue);

    const debouncedValue = useDebouncedValue(value, 300);
    const setAsyncValueStable = React.useRef(setAsyncValue);
    setAsyncValueStable.current = setAsyncValue;
    React.useEffect(() => {
        if (debouncedValue !== initialValue) {
            void setAsyncValueStable.current(debouncedValue);
        }
    }, [debouncedValue, initialValue]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    return (
        <InputLabelBase
            tw="my-0 bg-transparent!"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            autoFocus={true}
        />
    );
};
