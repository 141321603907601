import type { AutomationRunInfo, RunAutomationTriggerKind } from "@glide/common-core";
import "twin.macro";
import * as Popover from "@radix-ui/react-popover";
import { useCallback, useMemo, useState } from "react";
import { AppIcon, GlideIcon } from "@glide/common";
import { RadixPopoverContent } from "../../lib/radix-lib";

import type { StrokeIcons } from "@glide/plugins-codecs";
import { assertNever } from "@glideapps/ts-necessities";
import classNames from "classnames";
import { Img } from "@glide/wire-renderer";
import type { OrgMember } from "../../lib/orgs-lib";
import { url as gravatar } from "gravatar";
import { useBuilderTheme } from "../../hooks/use-builder-theme";
import { formatDuration, formatTime } from "./lib";

interface Props {
    readonly workflowName: string;
    readonly runs: readonly AutomationRunInfo[];
    readonly selectedRunID: string;
    readonly onSelectRun: (runID: string) => void;
    readonly orgMembers: readonly OrgMember[];
}

function getStIconForTriggerKind(kind: RunAutomationTriggerKind, isRunning: boolean): StrokeIcons {
    if (isRunning) {
        return "st-spinner";
    }
    switch (kind) {
        case "App":
            return "st-navigation";
        case "Schedule":
            return "st-clock";
        case "Builder":
            return "st-play";
        case "CLI":
            return "st-terminal";
        case "Integration":
            // FIXME: which icon?
            return "st-connect";
        default:
            assertNever(kind);
    }
}

const TriggerInner: React.FC<{ workflowName: string; run: AutomationRunInfo }> = ({ workflowName, run }) => {
    const { startedAt, finishedAt, triggerKind, success } = run;
    const isRunning = finishedAt === undefined;
    const failed = !success && !isRunning;

    const iconName = useMemo(() => getStIconForTriggerKind(triggerKind, isRunning), [triggerKind, isRunning]);

    const label = useMemo(() => {
        const startedAtDate = new Date(startedAt);
        return `${workflowName} - ${formatTime(startedAtDate)}`;
    }, [startedAt, workflowName]);

    return (
        <>
            <GlideIcon
                kind="stroke"
                icon={iconName}
                iconSize={20}
                spin={isRunning}
                className={classNames({ inProgress: isRunning, failed })}
                tw="text-icon-base [&.failed]:text-r400 [&.in-progress]:text-text-warning"
            />
            <div tw="font-semibold leading-none text-builder-xl text-text-dark grow">{label}</div>
            <GlideIcon kind="stroke" icon="st-chevron-selector" iconSize={20} tw="text-icon-pale" />
        </>
    );
};

const ManualTriggerer: React.FC<{ email: string | undefined; orgMembers: readonly OrgMember[] }> = p => {
    const { email, orgMembers } = p;
    const theme = useBuilderTheme();
    const member = orgMembers.find(m => m.email === email);

    if (email === undefined || member === undefined) return null;

    return (
        <>
            <Img
                altBehavior="both"
                className="icon"
                alternate={<AppIcon tw="flex" size={12} icon="avatarEmpty" color={theme.iconPale} />}
                alt=""
                tw="w-3 h-3 rounded-full"
                src={
                    member?.photoURL ??
                    gravatar(email ?? "", {
                        default: "404",
                        s: "12",
                    })
                }
            />
            <span tw="text-text-xpale">·</span>
        </>
    );
};

const ListItem: React.FC<{
    readonly run: AutomationRunInfo;
    readonly isSelected: boolean;
    readonly onSelect: (runID: string) => void;
    readonly orgMembers: readonly OrgMember[];
}> = p => {
    const { run, isSelected, onSelect, orgMembers } = p;
    const { startedAt, finishedAt, success, triggerKind, triggeredBy } = run;
    const isRunning = finishedAt === undefined;
    const failed = !success && !isRunning;

    const iconName = useMemo(() => getStIconForTriggerKind(triggerKind, isRunning), [triggerKind, isRunning]);

    const label = useMemo(() => {
        const startedAtDate = new Date(startedAt);
        return formatTime(startedAtDate);
    }, [startedAt]);

    const didClick = useCallback(() => {
        onSelect(run.runID);
    }, [run.runID, onSelect]);

    const accessoryView = useMemo(() => {
        if (isRunning) return null;
        const duration = new Date(finishedAt).getTime() / 1000 - new Date(startedAt).getTime() / 1000;
        return (
            <div tw="flex items-center gap-0.5">
                {triggerKind === "Builder" && <ManualTriggerer email={triggeredBy} orgMembers={orgMembers} />}
                <div tw="leading-none text-builder-sm text-text-pale">{formatDuration(duration)}</div>
            </div>
        );
    }, [isRunning, finishedAt, startedAt, triggeredBy, orgMembers, triggerKind]);

    return (
        <button tw="flex gap-2 items-center px-2 h-7 rounded-lg hover:bg-n200A" onClick={didClick}>
            <GlideIcon
                kind="stroke"
                icon={iconName}
                iconSize={16}
                strokeWidth={1.25}
                spin={isRunning}
                className={classNames({ inProgress: isRunning, failed })}
                tw="text-icon-base [&.failed]:text-r400 [&.in-progress]:text-text-warning"
            />
            <div tw="font-medium leading-none text-left text-builder-base text-text-base grow">{label}</div>
            {isSelected && <GlideIcon kind="stroke" icon="st-check" iconSize={16} tw="text-text-accent" />}
            {accessoryView}
        </button>
    );
};

export const PastRunSwitcher: React.FC<Props> = p => {
    const { workflowName, runs, selectedRunID, onSelectRun, orgMembers } = p;
    const [isOpen, setIsOpen] = useState(false);
    const selectedRun = runs.find(run => run.runID === selectedRunID);
    const [portalContainer] = useState(() => document.getElementById("portal"));
    const [triggerWidth, setTriggerWidth] = useState<number>(0);

    const onTriggerMount = useCallback((node: HTMLElement | null) => {
        if (node !== null) {
            setTriggerWidth(node.offsetWidth);
        }
    }, []);

    const onSelect = useCallback(
        (runID: string) => {
            onSelectRun(runID);
            setIsOpen(false);
        },
        [onSelectRun]
    );

    const sortedRuns = useMemo(
        () => [...runs].sort((a, b) => new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime()),
        [runs]
    );

    if (selectedRun === undefined) {
        return null;
    }

    return (
        <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
            <Popover.Trigger ref={onTriggerMount} tw="p-2 py-1.5 hover:bg-n200A flex items-center gap-2 rounded-lg">
                <TriggerInner workflowName={workflowName} run={selectedRun} />
            </Popover.Trigger>
            <Popover.Portal container={portalContainer}>
                <RadixPopoverContent
                    tw="overflow-y-auto p-2 rounded-xl shadow-lg-dark bg-bg-front"
                    align="start"
                    style={{ minWidth: triggerWidth }}>
                    <div tw="flex flex-col gap-1">
                        {sortedRuns.map(run => (
                            <ListItem
                                key={run.runID}
                                run={run}
                                isSelected={run.runID === selectedRunID}
                                onSelect={onSelect}
                                orgMembers={orgMembers}
                            />
                        ))}
                    </div>
                </RadixPopoverContent>
            </Popover.Portal>
        </Popover.Root>
    );
};
