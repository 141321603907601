import type { WireBackendInterface } from "@glide/hydrated-ui";
import { emptyScreen } from "@glide/hydrated-ui";
import { ResponsiveProvider, isSmallScreen, useResponsiveSizeClass } from "@glide/common-components";
import { ignore, isDefined } from "@glide/support";
import type { ResponsiveSizeClass, WireModalScreen, WireNavigation } from "@glide/wire";
import { WireComponentKind, WireModalSize, WireNavigationAction, WireScreenFlag } from "@glide/wire";
import classNames from "classnames";
import { definedMap } from "collection-utils";
import type { Transition } from "framer-motion";
import { AnimatePresence, m } from "framer-motion";
import * as React from "react";
import { css } from "styled-components";
import tw from "twin.macro";

import type { LayerInfo, LayerTransition, PageTransitionInfo } from "../../chrome-common";
import {
    LayerPositionProvider,
    OverlayHeader,
    OverlayHeaderBackButton,
    OverlayHeaderCloseButton,
    PageTransitions,
    TransitionProvider,
    getFullLayerTransition,
    useOverlayHeaderHeight,
} from "../../chrome-common";
import type { PagesRendererComponentDependencies } from "../../renderers/pages-component-renderer";
import { PagesComponentRenderer } from "../../renderers/pages-component-renderer";
import {
    PortalIdContext,
    createOverlayPortalIdFromKey,
    runActionAndHandleURL,
    usePortalBaseName,
} from "../../wire-lib";
import { FloatingPortalLayer, ScrollRefProvider, SpecialGroupComponents } from "./content-common";
import { ScreenSpecialCasesProvider } from "./lib/screen-special-cases";
import { IsInsideOverlayProvider } from "./lib/use-is-inside-overlay";
import { useScreenComponents } from "./lib/use-layer-compoents";

interface OverlayContentProps {
    readonly modalScreen: WireModalScreen | undefined;
    readonly onBackdropClick: () => void;
    readonly navBarHeight: number;
    readonly onClose: () => void;
    readonly maxSize: ResponsiveSizeClass;
    readonly isFormOrEdit: boolean;
    readonly isScrolled: boolean;
    readonly setIsScrolled: React.Dispatch<React.SetStateAction<boolean>>;
    readonly backend: WireBackendInterface;
    readonly lastNavigation: WireNavigation | undefined;
    readonly componentDependencies: PagesRendererComponentDependencies;
}

export const ChromeOverlayContent: React.VFC<OverlayContentProps> = p => {
    const {
        modalScreen,
        onBackdropClick,
        navBarHeight,
        onClose,
        maxSize,
        isFormOrEdit,
        isScrolled,
        setIsScrolled,
        backend,
        lastNavigation,
        componentDependencies,
    } = p;

    const onBack = definedMap(modalScreen?.backAction, a => () => runActionAndHandleURL(a, backend));

    const { layers, onAnimationComplete } = useOverlayTransitions(modalScreen, lastNavigation);

    const mainOverlayLayer = React.useMemo<LayerInfo>(() => {
        return {
            screen: modalScreen ?? emptyScreen,
            transition: undefined,
            persist: true,
        };
    }, [modalScreen]);

    return (
        <AnimatePresence>
            {modalScreen !== undefined && modalScreen.size !== WireModalSize.SlideIn && (
                <Overlay onBackdropClick={onBackdropClick} navBarHeight={navBarHeight}>
                    <UnifiedOverlayCard
                        isModalFormOrEdit={isFormOrEdit}
                        size={modalScreen.size}
                        modalScreen={modalScreen}
                        isOverlayScrolled={isScrolled}
                        onBack={onBack}
                        onClose={onClose}
                        pageSize={maxSize}>
                        <div id="modal-root" tw="w-full h-full grid grid-cols-1">
                            {layers === undefined ? (
                                <>
                                    <OverlayContentLayer
                                        key={modalScreen.key}
                                        maxSize={maxSize}
                                        backend={backend}
                                        onAnimationComplete={ignore}
                                        layer={mainOverlayLayer}
                                        setIsScrolled={setIsScrolled}
                                        componentDependencies={componentDependencies}
                                    />
                                </>
                            ) : (
                                <>
                                    <OverlayContentLayer
                                        key={layers.bottom.screen.key}
                                        maxSize={maxSize}
                                        backend={backend}
                                        onAnimationComplete={onAnimationComplete}
                                        layer={layers.bottom}
                                        setIsScrolled={setIsScrolled}
                                        componentDependencies={componentDependencies}
                                    />
                                    <OverlayContentLayer
                                        key={layers.top.screen.key}
                                        maxSize={maxSize}
                                        backend={backend}
                                        onAnimationComplete={onAnimationComplete}
                                        layer={layers.top}
                                        setIsScrolled={setIsScrolled}
                                        componentDependencies={componentDependencies}
                                    />
                                </>
                            )}
                        </div>
                    </UnifiedOverlayCard>
                </Overlay>
            )}
        </AnimatePresence>
    );
};

const ANIMATED_ACTIONS: (WireNavigationAction | undefined)[] = [WireNavigationAction.Push, WireNavigationAction.Pop];

function useOverlayTransitions(
    currentScreen: WireModalScreen | undefined,
    lastNavigation: WireNavigation | undefined
): PageTransitionInfo {
    const [lastNavigationKey, setLastAnimationKey] = React.useState<string | undefined>();

    const size = useResponsiveSizeClass();
    const isMobile = size === undefined || size === "sm";

    const onAnimationComplete = React.useCallback(() => {
        setLastAnimationKey(lastNavigation?.priorScreen.key);
    }, [lastNavigation?.priorScreen.key]);

    // Animating the desktop overlay doesn't look great cause the size of the modal changes
    // In mobile modals are full screen, so there it looks nice.
    const animationCompleted =
        lastNavigationKey === lastNavigation?.priorScreen.key ||
        !ANIMATED_ACTIONS.includes(lastNavigation?.navigationAction) ||
        !isMobile;

    const layers = React.useMemo<LayerTransition | undefined>(() => {
        if (!animationCompleted && currentScreen !== undefined) {
            switch (lastNavigation?.navigationAction) {
                case WireNavigationAction.Push: {
                    return {
                        bottom: {
                            screen: lastNavigation.priorScreen,
                            transition: undefined,
                            persist: false,
                        },
                        top: {
                            screen: currentScreen,
                            transition: PageTransitions.pushIn,
                            persist: true,
                        },
                    };
                }

                case WireNavigationAction.Pop: {
                    return {
                        bottom: {
                            screen: currentScreen,
                            transition: undefined,
                            persist: true,
                        },
                        top: {
                            screen: lastNavigation.priorScreen,
                            transition: PageTransitions.popOut,
                            persist: false,
                        },
                    };
                }
            }
        }

        return undefined;
    }, [animationCompleted, currentScreen, lastNavigation?.navigationAction, lastNavigation?.priorScreen]);

    return {
        layers,
        onAnimationComplete,
    };
}

interface OverlayProps {
    readonly navBarHeight: number;
    readonly onBackdropClick: () => void;
}

const desktopEntry: Transition = {
    type: "spring",
    mass: 1,
    stiffness: 800,
    damping: 60,
};

const desktopExit: Transition = {
    duration: 0.15,
};

const mobileEntry: Transition = {
    type: "spring",
    bounce: 0,
    duration: 0.3,
};

const mobileExit: Transition = {
    type: "spring",
    bounce: 0,
    duration: 0.7,
};

const Overlay: React.FC<React.PropsWithChildren<OverlayProps>> = p => {
    const { children, navBarHeight, onBackdropClick } = p;

    const size = useResponsiveSizeClass();
    const isFullScreen = size === undefined || size === "sm";

    const initialY = isFullScreen ? "100%" : 20;
    const exitY = isFullScreen ? "100%" : 80;

    const entryTransition = isFullScreen ? mobileEntry : desktopEntry;
    const exitTransition = isFullScreen ? mobileExit : desktopExit;

    return (
        <m.div
            initial={{ opacity: 0, y: initialY }}
            animate={{ opacity: 1, y: 0, transition: entryTransition }}
            exit={{ opacity: 0, y: exitY, transition: exitTransition }}
            tw="absolute inset-0 z-overlay-layer"
            className={isFullScreen ? "full-screen-modal" : "floating-modal"}
            css={css`
                will-change: transform, opacity;

                &.full-screen-modal {
                    top: ${navBarHeight}px;
                }

                &.floating-modal {
                    top: 0;
                }
            `}>
            <div tw="relative w-full h-full flex flex-col" onClick={onBackdropClick}>
                {children}
            </div>
        </m.div>
    );
};

interface UnifiedOverlayCardProps {
    readonly size: WireModalSize;
    readonly onClose: () => void;
    readonly modalScreen: WireModalScreen;
    readonly isOverlayScrolled: boolean;
    readonly onBack: (() => void) | undefined;
    readonly pageSize: ResponsiveSizeClass | undefined;
    readonly isModalFormOrEdit: boolean;
}

const UnifiedOverlayCard: React.FC<React.PropsWithChildren<UnifiedOverlayCardProps>> = p => {
    const { children, onClose, onBack, modalScreen, isOverlayScrolled, pageSize, isModalFormOrEdit } = p;

    const isSignIn = modalScreen.flags.includes(WireScreenFlag.IsSignIn);
    const isCodeScanner = modalScreen.components.some(c => c?.kind === WireComponentKind.InlineScanner);
    const size = modalScreen.size;

    const sizeClass = useResponsiveSizeClass();

    const isDesktopModal = !isSmallScreen(sizeClass);

    const { headerRef, height } = useOverlayHeaderHeight();

    const containerStyle: React.CSSProperties | undefined = isDesktopModal
        ? {
              height: `calc(100% - ${height})`,
              marginTop: height,
          }
        : undefined;

    return (
        <div tw="flex flex-col w-full h-full items-center justify-center">
            <div
                onClick={e => e.stopPropagation()}
                className={classNames(
                    `page-size-${pageSize}`,
                    isSignIn && "sign-in-modal",
                    isDesktopModal && "desktop-modal",
                    isModalFormOrEdit && "form-or-edit-modal",
                    isCodeScanner && "code-scanner-modal"
                )}
                tw="rounded-none overflow-hidden flex shrink flex-col h-full relative page-md:(h-auto rounded-xl shadow-2xl-dark)
                    page-md:after:(content-[''] rounded-xl absolute inset-0 ring-1 ring-inset ring-w05A pointer-events-none z-overlay-layer isolate)"
                css={css`
                    width: 100%;
                    max-width: 768px;

                    &.desktop-modal {
                        width: calc(100% - 16px);
                    }

                    // Overlays are 90% of the page size.
                    &.page-size-lg {
                        max-width: calc(0.9 * 768px);
                    }

                    &.page-size-xl {
                        max-width: calc(0.9 * 1024px);
                    }

                    &.page-size-2xl {
                        max-width: calc(0.9 * 1280px);
                    }

                    // Except for the sign in one, which is a bit special.
                    &.sign-in-modal.sign-in-modal {
                        max-width: 520px;
                    }

                    // And Form/Edit/Scanner are also a bit special
                    &.form-or-edit-modal.form-or-edit-modal,
                    &.code-scanner-modal.code-scanner-modal {
                        max-width: 640px;

                        .component-root:first-of-type {
                            &.code-scanner-container {
                                margin-top: 0;
                            }
                        }
                    }
                `}>
                {isDesktopModal && (
                    <OverlayHeader
                        isOverlayScrolled={isOverlayScrolled}
                        title={modalScreen.title}
                        isDraggable={false}
                        headerRef={headerRef}
                        leftButton={<OverlayHeaderBackButton onBack={onBack} />}
                        rightButton={<OverlayHeaderCloseButton onClose={onClose} />}
                    />
                )}

                <div role="dialog" tw="all-child:shrink-0 flex flex-col relative h-full page-md:h-auto">
                    <div
                        key={size}
                        className="overlay-card"
                        tw="shadow-2xl-dark relative h-full bg-bg-front page-md:h-auto"
                        style={containerStyle}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface OverlayContentLayerProps {
    readonly maxSize: ResponsiveSizeClass;
    readonly backend: WireBackendInterface;
    readonly layer: LayerInfo;
    readonly onAnimationComplete: () => void;
    readonly setIsScrolled: React.Dispatch<React.SetStateAction<boolean>>;
    readonly componentDependencies: PagesRendererComponentDependencies;
}

const OverlayContentLayer: React.VFC<OverlayContentLayerProps> = p => {
    const { layer, backend, onAnimationComplete, maxSize, setIsScrolled, componentDependencies } = p;

    const specialComponents = layer.screen.specialComponents.filter(isDefined) ?? [];

    const sizeClass = useResponsiveSizeClass();

    const isMobile = isSmallScreen(sizeClass);

    const [finished, setFinished] = React.useState(false);

    const onAnimationCompleteImpl = React.useCallback(() => {
        onAnimationComplete();
        setFinished(true);
    }, [onAnimationComplete]);

    const fullLayerTransition = getFullLayerTransition(layer.transition);
    // This ugly thing is basically getDerivedStateFromProps
    if (layer.persist && finished) {
        setFinished(false);
    }

    // Yeah, I know... But when this component goes away, we're no longer scrolled, so...
    React.useEffect(() => {
        return () => {
            setIsScrolled(false);
        };
    }, [setIsScrolled]);

    const onScroll: React.UIEventHandler<HTMLDivElement> = e => {
        if (e.currentTarget.scrollTop > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const components = useScreenComponents(layer.screen);
    const portalBaseName = usePortalBaseName();
    const portalId = createOverlayPortalIdFromKey(portalBaseName, layer.screen.key);
    const scrollRef = React.useRef<HTMLDivElement | null>(null);
    if (finished) {
        return null;
    }

    const isScannerLayer = layer.screen.components.some(c => c?.kind === WireComponentKind.InlineScanner);
    // Defaulting to top layer since it's the only one in overlays
    return (
        <LayerPositionProvider position="top">
            <IsInsideOverlayProvider isInsideOverlay={true}>
                <TransitionProvider transition={layer.transition}>
                    <PortalIdContext.Provider value={portalId}>
                        <ScreenSpecialCasesProvider components={components}>
                            <ScrollRefProvider scrollRef={scrollRef}>
                                <div
                                    ref={scrollRef}
                                    className={classNames(fullLayerTransition, isMobile && "mobile-layer")}
                                    onAnimationEnd={onAnimationCompleteImpl}
                                    tw="flex overflow-y-auto bg-bg-front w-full h-full"
                                    css={css`
                                        &.mobile-layer {
                                            ::-webkit-scrollbar {
                                                display: none;
                                            }
                                            scrollbar-width: none;
                                        }
                                    `}
                                    style={{
                                        gridRowStart: 1,
                                        gridColumnStart: 1,
                                        maxHeight: isMobile ? undefined : "70vh",
                                    }}
                                    onScroll={onScroll}>
                                    <ResponsiveProvider
                                        maxSize={maxSize}
                                        id="overlay-root"
                                        tw="w-full flex flex-col relative">
                                        <div
                                            id="overlay-scroll"
                                            tw="all-child:shrink-0 flex flex-col relative flex-grow"
                                            className={isScannerLayer ? "code-scanner-layer" : undefined}
                                            css={css`
                                                ${tw`pb-6`}

                                                &.code-scanner-layer {
                                                    ${tw`pb-0`}
                                                }
                                            `}>
                                            <PagesComponentRenderer
                                                components={components}
                                                backend={backend}
                                                componentDependencies={componentDependencies}
                                            />
                                        </div>
                                        {!isMobile && (
                                            <SpecialGroupComponents
                                                specialComponents={specialComponents}
                                                backend={backend}
                                            />
                                        )}
                                    </ResponsiveProvider>
                                </div>
                                <FloatingPortalLayer maxSize={maxSize} />
                            </ScrollRefProvider>
                        </ScreenSpecialCasesProvider>
                    </PortalIdContext.Provider>
                </TransitionProvider>
            </IsInsideOverlayProvider>
        </LayerPositionProvider>
    );
};
