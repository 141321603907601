import { useIsOSThemeDark } from "@glide/common";
import type { Subtract } from "utility-types";

interface WithOSThemeProps {
    readonly isOSThemeDark: boolean;
}

export const withOSTheme =
    <P extends WithOSThemeProps>(Component: React.ComponentType<P>): React.FC<Subtract<P, WithOSThemeProps>> =>
    p => {
        const isOSThemeDark = useIsOSThemeDark();
        return <Component {...(p as P)} isOSThemeDark={isOSThemeDark} />;
    };
