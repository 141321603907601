import type { WireAICustomComponent } from "@glide/fluent-components/dist/js/fluent-components";
import React, { useCallback, useMemo, useState } from "react";
import type { WireRenderer } from "../wire-renderer";

import { ValueChangeSource } from "@glide/wire";
import fromPairs from "lodash/fromPairs";
import camelCase from "lodash/camelCase";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { CustomComponent } from "@glideapps/custom-component";
import { mapFilterUndefined } from "@glideapps/ts-necessities";
import type { Theme as CustomComponentTheme, ThemeV2 as CustomComponentThemeV2 } from "@glideapps/custom-component";
import { getTokenList, replaceReferences } from "./custom-component-theme";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
interface ExtraProps {
    isInMultipleColumnLayout?: boolean;
}

const url = (() => {
    const locationSettings = getLocationSettings();
    try {
        const endpoint = localStorage.getItem("custom-components-endpoint");
        if (typeof endpoint === "string" && new URL(endpoint)) {
            return endpoint;
        }
    } catch {
        // eslint-disable-next-line no-console
        console.error("Invalid custom-components-endpoint");
    }
    return getFeatureSetting("frontCustomComponents")
        ? `${window.location.origin}/service/custom-component/generate`
        : `${locationSettings.aiCustomComponentDomain}/generate`;
})();

function formatTitleKeys(title: string | undefined): string {
    // we need standized action keys with no spaces so lets try camelCase
    return camelCase(title);
}

export const WireAICustom: WireRenderer<WireAICustomComponent, ExtraProps> = React.memo(p => {
    const { prompt, html: htmlInput, fields, generator, backend, actions } = p;

    const [_running, setRunning] = useState(false); // TODO spinner?

    const theme = useWireAppTheme();
    const tokenList = getTokenList(replaceReferences(theme));

    const customComponentTheme = React.useMemo<CustomComponentTheme>(() => {
        const { primaryAccentColor } = backend.getAIComponentInstructionsMetadata();
        return {
            colors: {
                foreground: theme.textBase,
                background: theme.bgContainerBase,
                accent: primaryAccentColor,
            },
        };
    }, [backend, theme.textBase, theme.bgContainerBase]);
    const customComponentThemeV2 = React.useMemo<CustomComponentThemeV2>(() => {
        const { primaryAccentColor } = backend.getAIComponentInstructionsMetadata();
        return {
            accent: primaryAccentColor,
            ...tokenList,
        };
    }, [backend, tokenList]);
    const setFieldDataFromComponent = useCallback(
        (key: string, setValue: any) => {
            // TODO: stricter here?
            const field = fields.find(f => formatTitleKeys(f.name ?? undefined) === key);
            const onChangeToken = field?.value?.onChangeToken;
            if (onChangeToken === undefined) return;
            backend.valueChanged(onChangeToken, setValue, ValueChangeSource.User);
        },
        [backend, fields]
    );

    const setState = useCallback(
        (newState: Record<string, any>) => {
            for (const k of Object.keys(newState)) {
                setFieldDataFromComponent(k, newState[k]);
            }
        },
        [setFieldDataFromComponent]
    );

    const setAction = useCallback(
        (key: string) => {
            const action = actions.find(a => formatTitleKeys(a.title ?? undefined) === key);
            if (action === undefined) return;
            backend.runAction(action.action?.token, action.action?.url !== undefined);
        },
        [actions, backend]
    );

    const promptInput = useMemo(
        () => (generator === "html-tailwind-alpine" ? htmlInput : prompt) ?? "",
        [generator, htmlInput, prompt]
    );

    const state = useMemo(() => {
        return fromPairs(fields.map(f => [formatTitleKeys(f.name ?? undefined), f.value?.value]));
    }, [fields]);

    const schema = useMemo(
        () => fields.map(f => ({ name: formatTitleKeys(f.name ?? ""), type: "string" as const })), // TODO get actual type
        [fields]
    );
    const actionsFormatted = useMemo(
        () =>
            mapFilterUndefined(actions, a =>
                typeof a.title !== "string" ? undefined : { title: formatTitleKeys(a.title) }
            ),
        [actions]
    );

    return (
        <CustomComponent
            endpoint={url}
            generator={generator}
            prompt={promptInput}
            schema={schema}
            state={state}
            setState={setState}
            setRunning={setRunning}
            theme={customComponentTheme}
            themeV2={customComponentThemeV2}
            actions={actionsFormatted}
            onActionTriggered={setAction}
        />
    );
});
