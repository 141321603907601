import "twin.macro";

import { getFirstUpgradeablePlanSatisfying } from "@glide/common-core/dist/js/billing-vnext/eminence-from-subscriptions";
import { getPlanInfoForPlanKind } from "@glide/common-core/dist/js/billing-vnext/org-plan-lib";
import { upgradeInformation } from "@glide/common-core/dist/js/billing-vnext/upgrade-information";
import type { EminenceFlagsThatUsersCanUpgradeFor } from "@glide/common-core/dist/js/Database/eminence";
import type { PropsWithChildren } from "react";
import React, { useContext } from "react";

import { SubscriptionContext, SubscriptionContextAlternateState } from "./context";
import { FeatureBox } from "./feature-box";
import { canCurrentPlanPassGate } from "./lib/can-pass-gate";

interface Props extends React.PropsWithChildren {
    readonly requires: keyof EminenceFlagsThatUsersCanUpgradeFor;
}

export const PaywallContainer: React.FC<React.PropsWithChildren<PropsWithChildren<Props>>> = props => {
    const { children, requires } = props;

    const subscriptionContext = useContext(SubscriptionContext);
    if (subscriptionContext === SubscriptionContextAlternateState.Loading) {
        return null;
    }

    const targetPlan = getFirstUpgradeablePlanSatisfying(e => e[requires]);
    const feature = upgradeInformation[requires];

    if (subscriptionContext === SubscriptionContextAlternateState.AccountCannotUpgrade) {
        return (
            <FeatureBox
                {...feature}
                note="This is a legacy account that cannot upgrade to use this feature. Please migrate to a team plan."
            />
        );
    }

    const { subscription, showUpgradeModal } = subscriptionContext;
    const planData = getPlanInfoForPlanKind(subscription.plan);
    const currentPlanGreaterThanOrEqualToTargetPlan = canCurrentPlanPassGate(planData.planKind, targetPlan);
    const upgradeRequired = !currentPlanGreaterThanOrEqualToTargetPlan;

    return (
        <>
            {!upgradeRequired && children}
            {upgradeRequired && (
                <FeatureBox
                    {...feature}
                    targetPlan={targetPlan}
                    onClick={async () => {
                        await showUpgradeModal({
                            location: `paywall_container_${requires}`,
                            targetPlan,
                            unsubscribeAppPlans: false,
                            upsell: true,
                        });
                    }}
                    onComparePlans={async () => {
                        await showUpgradeModal({
                            location: `paywall_container_${requires}`,
                            unsubscribeAppPlans: false,
                            upsell: true,
                            showPlanSelector: true,
                        });
                    }}
                />
            )}
        </>
    );
};
