import { styled } from "@glide/common";
import "twin.macro";
import { ButtonLegacyStyle } from "../button-legacy/button-style";
import { BaseScreenStyle } from "../base-screen/base-screen-style";

const BaseStyle = styled(BaseScreenStyle)`
    padding: 0;
    & .cos-main-button {
        align-self: center;
        height: 48px;
        width: unset;
        padding: 13px 22px;
        font-weight: 600;
        font-size: 18px;

        margin-top: 32px;
    }
`;

export const StageOneWrapper = styled(BaseStyle)`
    color: ${p => p.theme.fgColorDark};
    height: 100%;
    background-color: ${p => p.theme.bgColorLight};

    & .cos1-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 64px 48px 48px 48px;
    }

    & .cos1-header {
        font-weight: bold;
        font-size: 32px;
        line-height: 110%;
        text-align: center;
    }

    & .cos1-main-image {
        position: relative;
        left: 70px;
    }

    & .cos1-center-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: 480px;

        display: flex;
        flex-direction: column;
    }

    & .cos1-main-text {
        font-size: 20px;
        line-height: 120%;
        margin-top: 48px;
        text-align: center;
    }

    & .cos1-footer {
        font-weight: 600;
        font-size: 18px;
        color: ${p => p.theme.fgColorMedium};
        a {
            text-decoration: underline !important;
            color: ${p => p.theme.acceptColor};
        }
    }
`;

export const StageTwoWrapper = styled(BaseStyle)`
    height: 100%;

    display: flex;
    color: ${p => p.theme.fgColorDark};

    & .cos2-leftFrame {
        width: 480px;
        background-color: ${p => p.theme.bgColorAltLight};
        overflow: hidden;
    }

    & .cos2-rightFrame {
        height: 100%;
        overflow: auto;
        padding: 64px 48px 48px 64px;
        flex-grow: 1;
        background-color: ${p => p.theme.bgColorLight};
        display: flex;
        flex-direction: column;
    }

    & .cos2-centered {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & .cos2-header,
    & .cos2-subheader {
        font-weight: 600;
        line-height: 110%;
    }

    & .cos2-header {
        font-size: 32px;
    }

    & .cos2-subheader {
        margin: 12px 0px;
        font-size: 24px;
        color: ${p => p.theme.fgColorMedium};
    }

    & .cos2-option {
        margin-top: 24px;
        font-weight: 600;
        font-size: 16px;

        color: ${p => p.theme.fgColorDark};
    }

    & .cos2-input-wrap,
    & .cos2-textarea-wrap,
    & .cos2-drop-wrap {
        width: 310px;
        margin-top: 8px;

        font-weight: normal;
    }

    & .cos2-drop-wrap {
        width: 230px;
    }

    & .cos2-textarea-wrap {
        height: 115px;
    }

    & .cos2-text {
        margin-top: 4px;
        font-weight: normal;
        font-size: 14px;
        color: ${p => p.theme.fgColorDark};

        &.quiet {
            margin-top: 5px;
            color: ${p => p.theme.fgColorMedium};
        }

        ul {
            margin-left: 20px;

            li {
                margin-top: 5px;
            }
        }

        a {
            text-decoration: underline !important;
            color: ${p => p.theme.acceptColor};
        }
    }

    & .cos2-checkbox {
        margin-top: 24px;
        color: ${p => p.theme.fgColorMedium};
    }

    & .cos2-continue {
        position: absolute;
        bottom: 64px;
        right: 64px;

        display: flex;

        .cos-main-button {
            font-weight: normal;
        }
    }
`;

export const ContinueBlock = ({ children }: { children: React.ReactNode }) => {
    return <div tw="absolute bottom-16 right-16 flex">{children}</div>;
};

export const ContinueButton = styled(ButtonLegacyStyle)`
    align-self: center;
    height: 48px;
    width: unset;
    padding: 13px 22px;
    font-weight: normal;
    font-size: 18px;
    margin-top: 32px;
`;

export const StageOneOrgsImage = () => (
    <svg width="455" height="335" viewBox="0 0 455 335" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M198.336 155.963H122.163C119.864 155.963 118 154.098 118 151.8V93.1625C118 90.8645 119.864 89 122.163 89H139.575C140.912 89 142.167 89.6442 142.95 90.7282L146.264 95.3243C147.048 96.4083 148.302 97.0525 149.64 97.0525H198.333C200.631 97.0525 202.496 98.917 202.496 101.215V151.8C202.499 154.098 200.634 155.963 198.336 155.963Z"
            fill="#DFDFDF"
        />
        <path
            d="M198.336 245.963H122.163C119.864 245.963 118 244.098 118 241.8V183.163C118 180.864 119.864 179 122.163 179H139.575C140.912 179 142.167 179.644 142.95 180.728L146.264 185.324C147.048 186.408 148.302 187.053 149.64 187.053H198.333C200.631 187.053 202.496 188.917 202.496 191.215V241.8C202.499 244.098 200.634 245.963 198.336 245.963Z"
            fill="#DFDFDF"
        />
        <path
            d="M80.3362 245.963H4.16253C1.86446 245.963 0 244.098 0 241.8V183.163C0 180.864 1.86446 179 4.16253 179H21.5745C22.9125 179 24.1668 179.644 24.9504 180.728L28.2643 185.324C29.0479 186.408 30.3022 187.053 31.6402 187.053H80.3331C82.6311 187.053 84.4956 188.917 84.4956 191.215V241.8C84.4987 244.098 82.6342 245.963 80.3362 245.963Z"
            fill="#DFDFDF"
        />
        <path
            d="M145.453 6.90917L145.454 6.91015C146.425 8.25298 147.98 9.05251 149.64 9.05251H198C199.694 9.05251 201 10.4163 201 12.215V62.8003V62.8016C201.002 64.598 199.695 65.9628 198 65.9628H122.163C120.417 65.9628 119 64.5461 119 62.8003V4.16253C119 2.41675 120.417 1 122.163 1H139.575C140.59 1 141.543 1.48862 142.14 2.3134C142.14 2.31361 142.14 2.31381 142.14 2.31402L145.453 6.90917Z"
            stroke="black"
            strokeWidth="2"
        />
        <path
            d="M27.4532 95.9092L27.4539 95.9101C28.4245 97.253 29.9797 98.0525 31.6402 98.0525H80C81.6943 98.0525 83 99.4163 83 101.215V151.8V151.802C83.0024 153.598 81.6954 154.963 80 154.963H4.16253C2.41675 154.963 1 153.546 1 151.8V93.1625C1 91.4168 2.41675 90 4.16253 90H21.5745C22.5897 90 23.543 90.4886 24.1395 91.3134C24.1397 91.3136 24.1398 91.3138 24.14 91.314L27.4532 95.9092Z"
            stroke="black"
            strokeWidth="2"
        />
        <path
            d="M264.453 274.909L264.454 274.91C265.425 276.253 266.98 277.053 268.64 277.053H317C318.694 277.053 320 278.416 320 280.215V330.8V330.802C320.002 332.598 318.695 333.963 317 333.963H241.163C239.417 333.963 238 332.546 238 330.8V272.163C238 270.417 239.417 269 241.163 269H258.575C259.59 269 260.543 269.489 261.14 270.313C261.14 270.314 261.14 270.314 261.14 270.314L264.453 274.909Z"
            stroke="black"
            strokeWidth="2"
        />
        <path
            d="M80.3362 66.9628H4.16253C1.86446 66.9628 0 65.0983 0 62.8003V4.16253C0 1.86447 1.86446 0 4.16253 0H21.5745C22.9125 0 24.1668 0.644201 24.9504 1.72819L28.2643 6.32432C29.0479 7.40831 30.3022 8.05251 31.6402 8.05251H80.3331C82.6311 8.05251 84.4956 9.91698 84.4956 12.215V62.8003C84.4987 65.0983 82.6342 66.9628 80.3362 66.9628Z"
            fill="#DFDFDF"
        />
        <path
            d="M198.336 334.963H122.163C119.864 334.963 118 333.098 118 330.8V272.163C118 269.864 119.864 268 122.163 268H139.575C140.912 268 142.167 268.644 142.95 269.728L146.264 274.324C147.048 275.408 148.302 276.053 149.64 276.053H198.333C200.631 276.053 202.496 277.917 202.496 280.215V330.8C202.499 333.098 200.634 334.963 198.336 334.963Z"
            fill="#DFDFDF"
        />
        <path
            d="M80.3362 334.963H4.16253C1.86446 334.963 0 333.098 0 330.8V272.163C0 269.864 1.86446 268 4.16253 268H21.5745C22.9125 268 24.1668 268.644 24.9504 269.728L28.2643 274.324C29.0479 275.408 30.3022 276.053 31.6402 276.053H80.3331C82.6311 276.053 84.4956 277.917 84.4956 280.215V330.8C84.4987 333.098 82.6342 334.963 80.3362 334.963Z"
            fill="#DFDFDF"
        />
        <path
            d="M197.126 158.536C197.126 158.536 190.631 146.482 191.752 142.985C193.35 138.011 203.918 131.804 203.918 131.804"
            stroke="white"
            strokeWidth="4"
            strokeMiterlimit="10"
        />
        <path
            d="M292.883 141.412H257.712C248.179 141.412 240.452 133.684 240.452 124.151C240.452 114.618 248.179 106.891 257.712 106.891H292.886C302.419 106.891 310.146 114.618 310.146 124.151C310.143 133.684 302.416 141.412 292.883 141.412Z"
            fill="#00D563"
        />
        <path
            d="M411.161 277.799L379.313 304.301L275.176 193.462L170.926 304.534L139.432 277.638L243.861 164.129C251.786 155.522 262.905 150.554 274.606 150.399C286.703 150.238 298.299 155.231 306.494 164.129L411.161 277.799Z"
            fill="#4F5DFF"
        />
        <path
            d="M399.558 296.077C408.256 288.65 413.451 280.457 411.163 277.778C408.875 275.098 399.969 278.946 391.272 286.373C382.575 293.8 377.379 301.993 379.667 304.672C381.955 307.352 390.861 303.504 399.558 296.077Z"
            fill="white"
        />
        <path d="M395.643 286.428L414.858 309.755L454.999 266.6L413.191 289.469L405.517 280.292" fill="black" />
        <path
            d="M146.887 269.533C146.887 269.533 199.166 267.127 199.089 250.164C199.033 237.676 176.746 237.075 176.746 237.075"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M207.655 265.401C207.655 265.401 183.971 249.083 176.6 255.435C169.229 261.787 183.547 291.083 183.547 291.083"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M257.677 154.159C257.677 154.159 244.898 176.167 256.906 188.413C268.913 200.659 300.748 158.932 300.748 158.932"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M219.771 252.493C219.771 252.493 214.974 222.782 239.116 208.043"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M196.358 215.761C196.358 215.761 241.31 242.762 242.326 210.614"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path d="M416.511 292.334L420.726 281.426" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M422.078 290.014L426.294 279.106" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M427.906 287.193L432.122 276.285" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path
            d="M317.059 191.247C317.059 191.247 271.342 196.828 282.572 151.075"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M170.95 304.526C173.239 301.846 168.043 293.654 159.346 286.227C150.648 278.8 141.743 274.952 139.454 277.631C137.166 280.311 142.362 288.504 151.059 295.931C159.757 303.358 168.662 307.206 170.95 304.526Z"
            fill="white"
        />
        <path d="M154.937 286.304L135.722 309.628L95.5806 266.476L137.389 289.342L145.06 280.169" fill="black" />
        <path d="M134.068 292.21L129.853 281.299" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M128.502 289.891L124.287 278.979" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M122.674 287.069L118.459 276.158" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
        <path
            d="M317.164 245.493H240.99C238.692 245.493 236.828 243.629 236.828 241.331V182.693C236.828 180.395 238.692 178.531 240.99 178.531H258.402C259.74 178.531 260.994 179.175 261.778 180.259L265.092 184.855C265.876 185.939 267.13 186.583 268.468 186.583H317.161C319.459 186.583 321.323 188.447 321.323 190.746V241.331C321.326 243.629 319.462 245.493 317.164 245.493Z"
            fill="black"
        />
        <path d="M292.762 201.108L308.362 175.842" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
        <path
            d="M302.267 144.481L316.244 164.522L292.371 203.184L311.214 215.764C311.214 215.764 344.691 174.526 341.538 159.731C338.289 144.484 303.918 111.574 303.918 111.574"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M226.961 166.111L217.36 149.668L242.081 137.667"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M249.333 179.937C249.522 188.636 242.625 195.84 233.922 196.029C226.232 196.196 219.709 190.823 218.17 183.563C217.969 182.609 217.851 181.628 217.829 180.618C217.641 171.921 224.538 164.714 233.241 164.525C237.769 164.426 241.888 166.247 244.827 169.239C247.54 172.005 249.244 175.765 249.333 179.937Z"
            fill="#00D563"
        />
        <path
            d="M256.907 108.158C243.345 108.495 192.933 132.73 190.771 142.573C188.612 152.413 212.68 184.155 215.947 186.394C219.633 188.915 236.472 194.484 236.8 192.396C237.191 189.906 221.439 182.136 221.439 182.136C221.439 182.136 242.261 188.695 242.772 186.487C243.311 184.146 222.578 175.114 222.578 175.114C222.578 175.114 239.043 176.121 238.81 174.334C238.541 172.265 223.96 167.582 223.96 167.582"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinejoin="bevel"
        />
        <path
            d="M292.062 100.972C290.429 108.78 282.779 113.785 274.972 112.153C268.068 110.71 263.357 104.565 263.484 97.7638C263.503 96.8718 263.602 95.9706 263.791 95.0631C265.423 87.2584 273.073 82.2504 280.881 83.8825C284.944 84.7311 288.246 87.2088 290.25 90.4887C292.102 93.5177 292.845 97.2311 292.062 100.972Z"
            fill="#FFC200"
        />
        <path
            d="M294.059 85.363C293.381 88.6056 290.203 90.6838 286.96 90.0086C284.093 89.4109 282.135 86.8558 282.188 84.0312C282.194 83.6595 282.237 83.2848 282.315 82.91C282.993 79.6673 286.171 77.5892 289.413 78.2644C291.101 78.6174 292.473 79.6457 293.306 81.0084C294.078 82.2658 294.384 83.8082 294.059 85.363Z"
            fill="black"
        />
        <path
            d="M284.674 81.6772C284.262 83.6532 282.326 84.9199 280.35 84.508C278.603 84.1425 277.411 82.5878 277.442 80.8658C277.445 80.6397 277.473 80.4105 277.519 80.1813C277.931 78.2053 279.867 76.9386 281.843 77.3505C282.871 77.5642 283.707 78.193 284.215 79.023C284.683 79.7911 284.872 80.7326 284.674 81.6772Z"
            fill="black"
        />
        <path
            d="M286.513 108.158C286.513 108.158 283.571 107.318 283.853 104.581"
            stroke="black"
            strokeMiterlimit="10"
        />
        <path
            d="M287.846 102.982C287.854 101.609 287.161 100.491 286.297 100.486C285.433 100.48 284.726 101.589 284.717 102.963C284.709 104.336 285.402 105.454 286.266 105.46C287.13 105.465 287.837 104.356 287.846 102.982Z"
            fill="black"
        />
        <path
            d="M282.646 103.122C282.655 101.749 281.962 100.631 281.098 100.625C280.234 100.62 279.527 101.729 279.518 103.103C279.51 104.476 280.203 105.594 281.067 105.599C281.931 105.605 282.638 104.496 282.646 103.122Z"
            fill="black"
        />
        <path
            d="M268.926 96.1378C268.334 98.9748 265.556 100.793 262.719 100.198C260.213 99.6747 258.501 97.4417 258.547 94.9702C258.553 94.645 258.59 94.3198 258.659 93.9884C259.25 91.1546 262.028 89.3335 264.865 89.9281C266.339 90.2378 267.541 91.136 268.269 92.3284C268.941 93.4309 269.21 94.7782 268.926 96.1378Z"
            fill="#FFC200"
        />
        <path
            d="M289.674 89.9746C289.674 89.9746 283.991 92.124 275.257 88.5685L272.851 96.9555L267.577 94.5769C267.577 94.5769 267.174 89.3614 262.051 90.114C262.051 90.114 263.683 80.3209 269.782 79.6023C280.442 78.3511 288.343 84.5825 289.674 89.9746Z"
            fill="black"
        />
        <path
            d="M393.451 284.56C393.451 284.56 381.36 244.366 345.244 268.037"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M364.702 288.748C364.702 288.748 343.948 237.636 370.469 233.606"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M320.373 207.089C320.373 207.089 345.342 215.095 345.246 229.196C345.15 243.301 326.561 248.151 326.561 248.151"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M240.308 245.493H317.046C318.672 245.493 320.157 244.58 320.867 243.143L338.873 206.631C340.234 203.868 338.181 200.656 335.051 200.656H258.311C256.684 200.656 255.2 201.57 254.489 203.007L236.484 239.519C235.126 242.285 237.179 245.493 240.308 245.493Z"
            fill="#DFDFDF"
        />
        <path
            d="M246.9 171.305C246.9 171.305 242.137 175.021 242.899 176.833C243.661 178.645 249.998 177.31 249.998 177.31"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinejoin="bevel"
        />
    </svg>
);

export const StageTwoOrgsImage = () => (
    <svg width="480" height="1024" viewBox="0 0 480 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M414.965 940.399C420.571 935.483 421.13 926.953 416.214 921.348C411.298 915.742 402.768 915.183 397.163 920.099C391.557 925.015 390.998 933.545 395.914 939.15C400.831 944.756 409.36 945.315 414.965 940.399Z"
                fill="#00D563"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M404.905 949.401C391.724 934.372 379.827 920.807 369.299 908.803C358.088 918.635 356.971 935.694 366.803 946.905C376.635 958.116 393.694 959.234 404.905 949.401Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M389.599 871C396.38 878.733 401.473 884.54 407.401 891.299C396.19 901.132 379.131 900.014 369.299 888.803C377.985 881.185 383.255 876.563 389.599 871Z"
                fill="#5850F5"
            />
            <path
                d="M83.1437 998.117C108.029 998.117 128.202 977.944 128.202 953.059C128.202 928.173 108.029 908 83.1437 908C58.2584 908 38.085 928.173 38.085 953.059C38.085 977.944 58.2584 998.117 83.1437 998.117Z"
                fill="#00D563"
            />
            <path
                d="M83.1437 972.578C93.9242 972.578 102.663 963.839 102.663 953.059C102.663 942.278 93.9242 933.539 83.1437 933.539C72.3633 933.539 63.624 942.278 63.624 953.059C63.624 963.839 72.3633 972.578 83.1437 972.578Z"
                fill="white"
            />
            <path d="M51.5801 161L-67.915 229.993L-36.5901 284.247L82.905 215.255L51.5801 161Z" fill="#4F5DFF" />
            <path
                d="M218.985 80.26C224.856 80.26 229.615 75.5008 229.615 69.63C229.615 63.7592 224.856 59 218.985 59C213.114 59 208.355 63.7592 208.355 69.63C208.355 75.5008 213.114 80.26 218.985 80.26Z"
                fill="#4F5DFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M387.869 246L419.992 278.123L419.123 278.992L387 246.869L387.869 246Z"
                fill="#170C2C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M236.297 787.222L278.817 803.216L278.384 804.366L235.864 788.372L236.297 787.222Z"
                fill="#170C2C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M178.687 70L223.479 104.56L222.729 105.532L177.937 70.9726L178.687 70Z"
                fill="#170C2C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M234.178 708.631L212.156 748.367L211.082 747.772L233.103 708.036L234.178 708.631Z"
                fill="#170C2C"
            />
            <path
                d="M-96 570.627C-41.3145 522.575 41.9729 527.952 90.0248 582.637L-8.99049 669.647L-96 570.627Z"
                fill="#00D563"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M107.805 967.513L152.033 957.129L152.313 958.324L108.086 968.709L107.805 967.513Z"
                fill="#170C2C"
            />
            <path
                d="M496.424 779.65C499.094 787.189 495.151 795.469 487.608 798.138L417.408 823.01C409.869 825.68 401.59 821.736 398.92 814.194C396.25 806.655 400.193 798.376 407.736 795.706L477.937 770.834C485.475 768.165 493.755 772.112 496.424 779.65Z"
                fill="#00D563"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M470.244 842.33L446.843 803.393L447.896 802.76L471.297 841.697L470.244 842.33Z"
                fill="#170C2C"
            />
            <path
                d="M480.997 122.603C480.997 125.261 478.833 127.459 476.174 127.498C473.472 127.541 471.239 125.378 471.195 122.676L471.195 122.598C471.195 119.94 473.359 117.742 476.018 117.703C477.325 117.681 478.564 118.172 479.506 119.084C480.445 119.996 480.975 121.217 480.997 122.524L480.997 122.603Z"
                fill="white"
            />
            <path
                d="M475.847 122.598C475.847 120.292 477.724 118.389 480.031 118.354C482.372 118.315 484.31 120.192 484.345 122.533L484.345 122.603C484.345 124.909 482.468 126.811 480.161 126.846C479.027 126.864 477.954 126.438 477.137 125.652C476.32 124.861 475.864 123.801 475.847 122.668L475.847 122.598ZM474.543 122.603C474.543 122.633 474.543 122.663 474.543 122.694C474.591 125.76 477.115 128.206 480.183 128.158C483.22 128.11 485.648 125.634 485.648 122.611C485.648 122.581 485.648 122.55 485.648 122.52C485.601 119.453 483.076 117.008 480.009 117.056C476.968 117.099 474.543 119.575 474.543 122.603Z"
                fill="#170C2C"
            />
            <path
                d="M487.414 412.367C487.414 415.026 485.25 417.223 482.591 417.263C479.889 417.306 477.656 415.143 477.612 412.441L477.612 412.363C477.612 409.705 479.776 407.507 482.435 407.468C483.743 407.446 484.981 407.937 485.924 408.849C486.862 409.761 487.392 410.982 487.414 412.289L487.414 412.367Z"
                fill="white"
            />
            <path
                d="M476.264 412.363C476.264 410.057 478.141 408.154 480.448 408.12C481.582 408.102 482.655 408.528 483.472 409.314C484.289 410.105 484.745 411.165 484.762 412.298L484.762 412.368C484.762 414.674 482.886 416.577 480.578 416.611C478.237 416.651 476.299 414.774 476.264 412.433L476.264 412.363ZM474.961 412.363C474.961 412.394 474.961 412.424 474.961 412.455C475.008 415.521 477.533 417.967 480.6 417.919C483.637 417.871 486.066 415.395 486.066 412.372C486.066 412.342 486.066 412.311 486.066 412.281C486.018 409.214 483.494 406.769 480.426 406.817C477.389 406.864 474.961 409.34 474.961 412.363Z"
                fill="#170C2C"
            />
            <path
                d="M281.186 852.527C281.186 853.057 281.099 853.587 280.925 854.095C280.061 856.653 277.276 858.035 274.712 857.17C272.718 856.497 271.38 854.634 271.38 852.527C271.38 851.997 271.467 851.467 271.641 850.959C272.058 849.721 272.935 848.718 274.108 848.136C275.282 847.553 276.611 847.467 277.849 847.884C279.848 848.557 281.186 850.425 281.186 852.527Z"
                fill="white"
            />
            <path
                d="M272.036 852.528C272.036 852.067 272.11 851.611 272.262 851.168C273.014 848.948 275.429 847.754 277.65 848.501C279.379 849.083 280.539 850.703 280.539 852.523C280.539 852.984 280.465 853.44 280.313 853.883C279.948 854.956 279.188 855.829 278.171 856.328C277.154 856.832 276.003 856.91 274.925 856.546C273.196 855.968 272.036 854.352 272.036 852.528ZM270.733 852.528C270.733 854.843 272.193 857.006 274.508 857.788C277.415 858.769 280.565 857.21 281.547 854.304C281.747 853.713 281.842 853.114 281.842 852.528C281.842 850.213 280.382 848.049 278.067 847.268C275.16 846.286 272.01 847.845 271.028 850.751C270.824 851.342 270.733 851.941 270.733 852.528Z"
                fill="#170C2C"
            />
            <path
                d="M68.786 671.758C70.5778 670.504 71.014 668.035 69.7599 666.244C68.5057 664.452 66.0362 664.017 64.2444 665.27C62.4526 666.524 62.0164 668.993 63.2706 670.784C64.5247 672.576 66.9942 673.012 68.786 671.758Z"
                fill="black"
            />
            <path
                d="M199.786 248.758C201.578 247.504 202.014 245.035 200.76 243.244C199.506 241.452 197.036 241.017 195.245 242.27C193.453 243.524 193.017 245.993 194.271 247.784C195.525 249.576 197.994 250.012 199.786 248.758Z"
                fill="black"
            />
            <path
                d="M119.31 132.423L124.728 128.631L128.521 134.047L123.103 137.839L119.31 132.423ZM117.494 132.105L122.781 139.659L130.337 134.373L125.049 126.819L117.494 132.105Z"
                fill="#170C2C"
            />
            <path d="M123.906 891.585L128.299 908" stroke="#170C2C" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M-3.13025 399.854L9.47822 388.456" stroke="#170C2C" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M268.53 165.166L284.041 158.22" stroke="#170C2C" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M119.77 534.281L112.823 518.77" stroke="#170C2C" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M435.112 256.847L418.532 253.094" stroke="#170C2C" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M6.81491 828.481L-6.60168 837.09" stroke="#170C2C" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M405.328 643.462L443.695 665.306L400.715 720.903L419.552 667.226L402.531 653.808" fill="#4F5DFF" />
            <path d="M287.223 642.129L287.396 678.831L221.764 653.943L273.525 658.976L273.515 634.271" fill="#4F5DFF" />
            <path
                d="M373.488 515.626C397.99 516.122 413.956 525.178 417.732 543.982C420.844 559.486 408.053 582.538 385.828 606.984L422.974 637.515C422.974 637.515 428.475 648.763 417.301 659.869C406.403 670.699 394.561 669.131 394.561 669.131L348.343 629.354C337.558 620.072 335.452 604.172 343.446 592.398L365.977 559.211L323.938 584.715L299.741 647.757C299.741 647.757 294.379 655.013 279.65 652.312C263.469 649.344 258.684 641.152 258.684 641.152L281.572 571.953C284.427 563.324 289.739 555.722 296.858 550.078C313.356 536.996 345.318 515.058 373.488 515.626Z"
                fill="#00CB69"
            />
            <path
                d="M279.08 627.083C280.974 627.549 282.158 629.475 281.721 631.377C281.506 632.311 280.94 633.107 280.129 633.619C279.318 634.131 278.352 634.291 277.418 634.076L277.361 634.062C275.466 633.597 274.283 631.671 274.72 629.769C275.161 627.838 277.093 626.625 279.023 627.069L279.08 627.083ZM279.343 626.013C279.318 626.007 279.293 626.001 279.268 625.994C276.74 625.414 274.222 626.994 273.642 629.523C273.068 632.027 274.611 634.521 277.094 635.132C277.119 635.138 277.144 635.144 277.169 635.15C279.697 635.73 282.215 634.151 282.795 631.622C283.369 629.118 281.826 626.623 279.343 626.013Z"
                fill="#170C2C"
            />
            <path
                d="M294.022 571.26C295.917 571.726 297.1 573.652 296.663 575.553C296.222 577.485 294.29 578.697 292.36 578.253L292.303 578.239C290.409 577.773 289.225 575.848 289.662 573.946C289.877 573.011 290.443 572.216 291.254 571.703C292.065 571.191 293.031 571.031 293.965 571.246L294.022 571.26ZM294.286 570.19C294.261 570.184 294.236 570.177 294.211 570.171C291.682 569.591 289.164 571.171 288.584 573.7C288.01 576.204 289.553 578.698 292.036 579.309C292.061 579.315 292.086 579.321 292.111 579.327C294.639 579.907 297.158 578.328 297.738 575.799C298.312 573.295 296.769 570.8 294.286 570.19Z"
                fill="#170C2C"
            />
            <path
                d="M320.165 552.989C322.059 553.454 323.243 555.38 322.806 557.282C322.365 559.213 320.432 560.426 318.503 559.982L318.446 559.968C316.551 559.502 315.368 557.576 315.805 555.674C316.02 554.74 316.586 553.944 317.397 553.432C318.207 552.92 319.173 552.76 320.108 552.975L320.165 552.989ZM320.428 551.918C320.403 551.912 320.378 551.906 320.353 551.9C317.825 551.32 315.307 552.899 314.727 555.428C314.153 557.932 315.696 560.427 318.179 561.037C318.204 561.043 318.229 561.05 318.254 561.056C320.782 561.636 323.3 560.056 323.88 557.527C324.458 555.024 322.915 552.53 320.428 551.918Z"
                fill="#170C2C"
            />
            <path
                d="M300.409 547.239C302.034 549.258 301.72 552.215 299.701 553.842C297.682 555.468 294.725 555.154 293.099 553.135"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M364.766 526.344C366.66 526.81 367.844 528.735 367.407 530.637C367.192 531.572 366.626 532.368 365.815 532.88C365.004 533.392 364.038 533.552 363.103 533.337L363.046 533.323C361.152 532.857 359.968 530.932 360.406 529.03C360.847 527.098 362.779 525.886 364.709 526.33L364.766 526.344ZM365.029 525.274C365.004 525.267 364.979 525.261 364.954 525.255C362.426 524.675 359.908 526.255 359.328 528.784C358.754 531.287 360.297 533.782 362.78 534.393C362.805 534.399 362.83 534.405 362.855 534.411C365.383 534.991 367.901 533.412 368.481 530.883C369.059 528.38 367.516 525.885 365.029 525.274Z"
                fill="#170C2C"
            />
            <path
                d="M332.104 526.847C333.205 527.954 333.728 529.581 333.357 531.212C332.777 533.741 330.259 535.32 327.731 534.74C325.893 534.319 324.555 532.87 324.186 531.14"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M352.325 547.265C354.219 547.731 355.403 549.656 354.965 551.558C354.751 552.493 354.184 553.289 353.374 553.801C352.563 554.313 351.597 554.473 350.662 554.258L350.605 554.244C348.711 553.778 347.527 551.852 347.965 549.951C348.179 549.016 348.746 548.22 349.556 547.708C350.367 547.196 351.333 547.036 352.268 547.251L352.325 547.265ZM352.588 546.195C352.563 546.188 352.538 546.182 352.513 546.176C349.985 545.596 347.467 547.176 346.887 549.705C346.313 552.208 347.856 554.703 350.339 555.314C350.364 555.32 350.389 555.326 350.413 555.332C352.942 555.912 355.46 554.332 356.04 551.804C356.614 549.3 355.071 546.805 352.588 546.195Z"
                fill="#170C2C"
            />
            <path
                d="M397.359 519.781C396.269 521.151 394.455 521.852 392.636 521.435C390.62 520.973 389.206 519.275 389.011 517.327"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M383.085 566.316C384.98 566.781 386.163 568.707 385.726 570.609C385.511 571.544 384.945 572.339 384.134 572.851C383.324 573.364 382.358 573.523 381.423 573.309L381.366 573.295C379.472 572.829 378.288 570.903 378.725 569.001C379.166 567.07 381.098 565.858 383.028 566.302L383.085 566.316ZM383.349 565.245C383.324 565.239 383.299 565.233 383.274 565.227C380.745 564.647 378.227 566.226 377.647 568.755C377.073 571.259 378.616 573.754 381.099 574.364C381.124 574.37 381.149 574.377 381.174 574.383C383.702 574.963 386.22 573.383 386.801 570.854C387.375 568.35 385.832 565.856 383.349 565.245Z"
                fill="#170C2C"
            />
            <path
                d="M363.11 595.984C365.005 596.449 366.188 598.375 365.751 600.277C365.31 602.208 363.378 603.421 361.448 602.977L361.391 602.963C359.497 602.497 358.313 600.571 358.75 598.669C358.965 597.735 359.531 596.939 360.342 596.427C361.153 595.915 362.119 595.755 363.053 595.97L363.11 595.984ZM363.374 594.913C363.349 594.907 363.324 594.901 363.299 594.895C360.77 594.315 358.252 595.894 357.672 598.423C357.098 600.927 358.641 603.422 361.124 604.032C361.149 604.038 361.174 604.045 361.199 604.051C363.727 604.631 366.246 603.051 366.826 600.522C367.4 598.018 365.857 595.524 363.374 594.913Z"
                fill="#170C2C"
            />
            <path
                d="M405.656 623.279C404.555 624.31 402.977 624.788 401.398 624.427C398.869 623.847 397.291 621.328 397.871 618.799C397.992 618.276 398.193 617.796 398.461 617.366"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M423.521 650.386C420.931 650.242 418.953 648.026 419.094 645.435C419.239 642.844 421.455 640.865 424.046 641.006"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M338.838 611.725C339.234 611.713 339.642 611.749 340.047 611.841C342.575 612.421 344.154 614.94 343.574 617.468C343.281 618.751 342.485 619.789 341.443 620.426"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M393.787 536.128C395.681 536.593 396.865 538.519 396.428 540.421C395.987 542.352 394.054 543.565 392.124 543.12L392.067 543.106C390.173 542.641 388.99 540.715 389.427 538.813C389.641 537.878 390.208 537.083 391.018 536.571C391.829 536.059 392.795 535.899 393.73 536.114L393.787 536.128ZM394.047 535.056C394.022 535.05 393.997 535.044 393.972 535.038C391.443 534.458 388.925 536.037 388.345 538.566C387.771 541.07 389.314 543.565 391.797 544.175C391.822 544.181 391.847 544.187 391.872 544.194C394.4 544.774 396.918 543.194 397.499 540.665C398.076 538.162 396.533 535.668 394.047 535.056Z"
                fill="#170C2C"
            />
            <path
                d="M394.953 596.365C393.094 595.405 392.047 593.278 392.534 591.142C393.114 588.614 395.632 587.034 398.16 587.614C399.109 587.832 399.924 588.32 400.54 588.982"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M365.244 643.903C366.285 643.191 367.613 642.9 368.937 643.207C371.465 643.787 373.044 646.305 372.464 648.834C372.386 649.182 372.267 649.513 372.118 649.824"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M414.195 565.938C412.542 564.907 411.645 562.908 412.104 560.902C412.636 558.585 414.8 557.062 417.105 557.273"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M298.532 604.405C300.426 604.87 301.61 606.796 301.173 608.698C300.732 610.629 298.8 611.842 296.87 611.398L296.813 611.384C294.918 610.918 293.735 608.992 294.172 607.09C294.387 606.155 294.953 605.36 295.764 604.848C296.574 604.336 297.54 604.176 298.475 604.391L298.532 604.405ZM298.795 603.334C298.77 603.328 298.745 603.322 298.72 603.316C296.192 602.736 293.674 604.315 293.094 606.844C292.52 609.348 294.063 611.843 296.546 612.453C296.571 612.459 296.596 612.465 296.621 612.472C299.149 613.052 301.667 611.472 302.247 608.943C302.825 606.44 301.282 603.945 298.795 603.334Z"
                fill="#170C2C"
            />
            <path
                d="M316.704 603.904C314.283 602.979 313.067 600.266 313.991 597.844C314.915 595.423 317.628 594.205 320.049 595.13"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M272.622 599.109C275.079 599.937 276.402 602.601 275.575 605.057C274.748 607.514 272.084 608.838 269.628 608.011"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M329.282 581.473C328.988 580.655 328.913 579.743 329.122 578.83C329.702 576.301 332.221 574.721 334.749 575.301C335.698 575.52 336.512 576.007 337.129 576.67"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M369.647 614.649C371.542 615.115 372.725 617.041 372.288 618.943C371.847 620.874 369.915 622.086 367.985 621.642L367.928 621.628C366.034 621.162 364.85 619.237 365.287 617.335C365.502 616.4 366.068 615.605 366.879 615.093C367.69 614.58 368.656 614.421 369.59 614.635L369.647 614.649ZM369.911 613.579C369.886 613.573 369.861 613.567 369.836 613.56C367.307 612.98 364.789 614.56 364.209 617.089C363.635 619.593 365.178 622.087 367.661 622.698C367.686 622.704 367.711 622.71 367.736 622.716C370.264 623.296 372.783 621.717 373.363 619.188C373.937 616.684 372.394 614.189 369.911 613.579Z"
                fill="#170C2C"
            />
            <path
                d="M394.977 640.22C396.871 640.686 398.054 642.612 397.617 644.514C397.176 646.445 395.244 647.658 393.314 647.213L393.257 647.199C391.363 646.734 390.179 644.808 390.616 642.906C390.831 641.971 391.397 641.176 392.208 640.664C393.019 640.152 393.985 639.992 394.919 640.206L394.977 640.22ZM395.236 639.149C395.211 639.143 395.186 639.137 395.161 639.131C392.633 638.551 390.115 640.13 389.535 642.659C388.961 645.163 390.504 647.658 392.987 648.268C393.012 648.274 393.037 648.28 393.062 648.287C395.59 648.866 398.108 647.287 398.688 644.758C399.266 642.255 397.723 639.76 395.236 639.149Z"
                fill="#170C2C"
            />
            <path
                d="M398.618 669.049C397.554 667.899 397.088 666.24 397.532 664.616C398.216 662.113 400.799 660.64 403.298 661.323C405.801 662.006 407.273 664.589 406.59 667.089"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M304.704 634.912C302.267 634.025 301.011 631.332 301.893 628.896C302.78 626.458 305.472 625.202 307.907 626.084"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path
                d="M279.39 652.32C279.558 649.731 281.789 647.767 284.377 647.934C286.965 648.101 288.928 650.332 288.761 652.921"
                stroke="#170C2C"
                strokeMiterlimit="10"
            />
            <path d="M423.602 674.692L410.429 679.696" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M420.769 681.386L407.593 686.385" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M417.325 688.391L404.153 693.391" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M268.887 662.346L257.744 653.719" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M261.703 663.422L250.56 654.794" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path
                d="M380.49 422.406C378.654 431.29 384.366 439.971 393.244 441.805C401.093 443.425 408.791 439.149 411.737 431.999C412.123 431.061 412.429 430.076 412.641 429.046C414.476 420.166 408.765 411.481 399.883 409.646C395.264 408.692 390.699 409.78 387.123 412.295C383.825 414.613 381.367 418.149 380.49 422.406Z"
                fill="#FFC200"
            />
            <path
                d="M385.476 405.219C384.713 408.909 387.087 412.517 390.774 413.276C394.036 413.949 397.231 412.172 398.457 409.201C398.617 408.812 398.744 408.401 398.831 407.972C399.594 404.285 397.22 400.674 393.533 399.915C391.614 399.519 389.717 399.972 388.231 401.014C386.858 401.982 385.839 403.45 385.476 405.219Z"
                fill="black"
            />
            <path
                d="M396.929 405.628C396.464 407.874 397.91 410.073 400.156 410.538C402.144 410.947 404.089 409.866 404.837 408.056C404.933 407.819 405.013 407.57 405.066 407.31C405.531 405.063 404.085 402.865 401.839 402.4C400.669 402.157 399.515 402.434 398.61 403.07C397.774 403.656 397.152 404.551 396.929 405.628Z"
                fill="black"
            />
            <path
                d="M382.333 423.93C383.294 424.129 384.311 423.144 384.603 421.731C384.894 420.318 384.351 419.011 383.39 418.813C382.428 418.614 381.412 419.599 381.12 421.012C380.828 422.425 381.371 423.731 382.333 423.93Z"
                fill="black"
            />
            <path
                d="M388.082 425.302C389.044 425.501 390.06 424.516 390.352 423.103C390.644 421.69 390.101 420.383 389.139 420.185C388.178 419.986 387.161 420.971 386.87 422.384C386.578 423.797 387.121 425.103 388.082 425.302Z"
                fill="black"
            />
            <path
                d="M406.802 427.838C406.137 431.065 408.21 434.22 411.437 434.884C414.288 435.472 417.084 433.919 418.154 431.321C418.294 430.981 418.405 430.622 418.482 430.248C419.147 427.02 417.074 423.866 413.847 423.201C412.17 422.853 410.51 423.251 409.211 424.162C408.015 425.01 407.121 426.293 406.802 427.838Z"
                fill="#FFC200"
            />
            <path
                d="M387.959 412.016C387.959 412.016 392.911 416.832 403.629 417.079L402.341 426.919L408.916 426.825C408.916 426.825 411.699 421.568 416.698 424.67C416.698 424.67 419.429 413.717 413.395 410.205C402.85 404.071 391.788 406.997 387.959 412.016Z"
                fill="black"
            />
            <path
                d="M385.219 424.329C385.219 424.329 383.552 426.557 381.321 425.429"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M411.216 493.903L369.157 490.908C358.289 490.135 350.108 480.696 350.885 469.826C351.659 458.955 361.097 450.771 371.964 451.547L414.022 454.542C424.89 455.314 433.071 464.754 432.295 475.624C431.521 486.494 422.083 494.678 411.216 493.903Z"
                fill="#4F5DFF"
            />
            <path
                d="M420.776 483.822C420.776 483.822 354.345 511.567 343.999 502.651C332.04 492.344 337.058 440.48 337.058 440.48C337.058 440.48 318.63 424.71 319.52 422.492C320.605 419.785 333.958 427.593 333.958 427.593C333.958 427.593 319.551 415.885 320.709 413.96C322.788 410.506 338.412 423.663 338.412 423.663C338.412 423.663 328.61 408.493 329.978 407.157C331.182 405.985 345.49 421.108 345.49 421.108C345.49 421.108 344.402 412.009 346.308 411.475C348.952 410.736 352.993 421.954 355.963 427.535C358.633 432.555 361.027 476.785 361.027 476.785L400.514 461.677"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinejoin="bevel"
            />
            <path
                d="M333.715 487.901C333.715 487.901 317.809 484.8 309.06 477.117C299.334 468.576 287.919 424.819 287.919 424.819C287.919 424.819 270.055 406.044 272.2 404.123C274.44 402.12 286.433 411.414 286.433 411.414C286.433 411.414 273.206 395.97 275.614 393.958C278.808 391.293 292.109 406.743 292.109 406.743C292.109 406.743 285.42 392.158 287.344 391.658C289.953 390.986 299.218 404.866 299.218 404.866C299.218 404.866 302.73 396.89 304.652 397.427C307.421 398.198 304.441 407.811 307.278 414.934C309.286 419.972 327.822 458.847 327.822 458.847L333.816 461.164"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinejoin="bevel"
            />
            <path d="M375.851 546.393L353.298 574.867" stroke="white" strokeWidth="5" strokeMiterlimit="10" />
            <path
                d="M155.947 423.605C155.947 423.605 207.823 418.92 218.58 411.633C229.719 404.086 243.396 377.048 249.74 363.896C250.754 361.791 267.146 349.26 265.985 347.471C264.487 345.152 254.347 355.004 254.347 355.004"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinejoin="bevel"
            />
            <path
                d="M235.505 362.937C253.984 362.937 268.965 347.953 268.965 329.469C268.965 310.984 253.984 296 235.505 296C217.025 296 202.045 310.984 202.045 329.469C202.045 347.953 217.025 362.937 235.505 362.937Z"
                fill="#00D563"
            />
            <path d="M71.8414 561.878L37 564.171L49.3914 631.123L56.7645 576.319L80.2064 574.669" fill="black" />
            <path d="M151.829 579.156L125.382 602.475L174.661 648.302L148.295 599.988L166.028 584.213" fill="black" />
            <path
                d="M161.168 454.263C144.261 446.428 121.238 449.603 112.057 464.265C101.823 480.606 101.147 495.647 126.458 547.657L72.8885 552.181C72.8885 552.181 61.2134 555.852 62.6645 574.343C64.0716 592.271 73.175 595.74 73.175 595.74L145.382 590.717C163.236 589.475 175.451 571.745 170.605 554.103L157.149 505.127L194.859 522.019L151.252 577.227C151.252 577.227 147.59 585.741 159.485 594.66C169.757 602.362 182.317 599.793 182.317 599.793L229.418 543.913C244.472 525.76 239.708 507.762 225.395 495.65C200.542 474.628 180.631 463.284 161.168 454.263Z"
                fill="#4F5DFF"
            />
            <path
                d="M80.4601 595.269C80.1552 589.831 79.3506 583.595 76.4741 578.848C73.6895 574.251 67.8483 572.039 62.7198 573.325C62.0365 573.494 62.3267 574.552 63.01 574.379C68.2156 573.078 73.8291 575.728 76.1582 580.571C78.3183 585.054 79.0898 590.353 79.3653 595.269C79.4057 595.971 80.5005 595.975 80.4601 595.269Z"
                fill="white"
            />
            <path
                d="M73.2447 552.133C73.3512 562.444 81.9918 571.443 90.2429 576.448C96.0143 579.95 102.675 582.452 109.526 581.875C115.933 581.335 121.385 577.841 124.026 571.888C125.584 568.375 126.124 564.439 125.867 560.618C125.573 556.215 124.015 552.133 122.557 548.014C122.325 547.356 121.267 547.639 121.502 548.304C123.872 554.999 125.992 561.614 123.997 568.731C122.127 575.401 116.94 579.976 110.04 580.729C103.589 581.431 97.3185 579.255 91.7895 576.088C86.7859 573.222 82.2563 569.389 78.9536 564.638C76.4261 561 74.3835 556.638 74.3394 552.129C74.3284 551.427 73.2373 551.427 73.2447 552.133Z"
                fill="white"
            />
            <path
                d="M154.356 587.674C154.4 581.49 151.976 575.834 148.096 571.094C144.342 566.504 139.441 562.275 133.894 560.044C128.361 557.821 122.23 557.968 116.701 560.136C110.283 562.653 105.426 567.53 101.268 572.884C96.0437 579.608 91.4883 586.884 87.4473 594.373C87.113 594.994 88.0571 595.545 88.3914 594.924C91.6316 588.923 95.2135 583.117 99.1664 577.558C103.013 572.145 107.252 566.64 113.024 563.164C118.148 560.074 124.394 558.655 130.29 560.048C136.161 561.433 141.061 565.244 145.191 569.492C150.007 574.445 153.313 580.648 153.262 587.681C153.258 588.38 154.349 588.38 154.356 587.674Z"
                fill="white"
            />
            <path
                d="M162.354 512.318C155.833 510.356 148.963 509.779 142.215 510.713C137.424 511.374 130.055 512.535 127.266 517.132C124.291 522.041 130.132 527.27 133.545 530.07C138.589 534.208 144.57 537.185 150.539 539.735C157.729 542.803 165.234 545.056 172.916 546.503C173.603 546.632 173.897 545.577 173.206 545.449C161.509 543.248 149.878 539.228 139.672 533.04C137.207 531.544 134.845 529.868 132.722 527.913C130.65 526.006 127.784 523.368 127.546 520.388C127.079 514.564 136.425 512.829 140.525 512.087C147.7 510.786 155.095 511.278 162.067 513.377C162.74 513.575 163.03 512.52 162.354 512.318Z"
                fill="white"
            />
            <path
                d="M107.59 501.967C113.266 503.58 119.357 503.797 124.875 501.46C130.04 499.273 133.508 495.018 135.621 489.914C138.119 483.88 139.236 477.17 140.316 470.766C141.462 463.975 142.737 456.835 141.554 449.967C141.436 449.276 140.382 449.567 140.499 450.257C141.645 456.912 140.371 463.894 139.261 470.472C138.299 476.167 137.263 482.003 135.374 487.478C133.552 492.766 130.503 497.517 125.349 500.049C119.886 502.731 113.611 502.544 107.88 500.912C107.205 500.721 106.914 501.776 107.59 501.967Z"
                fill="white"
            />
            <path
                d="M113.696 461.858C122.898 471.835 136.484 475.293 149.636 475.679C161.527 476.024 174.198 473.771 183.588 466.003C184.132 465.555 183.353 464.783 182.817 465.232C173.658 472.805 161.211 474.925 149.636 474.587C136.763 474.212 123.483 470.857 114.471 461.087C113.993 460.565 113.222 461.34 113.696 461.858Z"
                fill="white"
            />
            <path
                d="M202.633 477.729C198.004 479.838 194.517 483.377 193.026 488.297C191.637 492.883 192.016 497.976 193.68 502.441C197.585 512.928 208.084 519.113 217.702 523.607C220.942 525.121 224.245 526.51 227.592 527.77C230.773 528.964 234.01 530.155 237.459 529.993C238.161 529.96 238.165 528.865 237.459 528.898C234.565 529.034 231.85 528.167 229.164 527.19C226.321 526.153 223.51 525.022 220.74 523.794C215.935 521.667 211.178 519.315 206.843 516.327C198.717 510.727 192.166 502.081 193.426 491.715C194.157 485.707 197.714 481.169 203.187 478.673C203.827 478.383 203.272 477.439 202.633 477.729Z"
                fill="white"
            />
            <path
                d="M172.012 566.082C183.022 564.546 194.966 567.761 199.609 578.807C199.877 579.447 200.935 579.167 200.664 578.517C195.767 566.861 183.393 563.399 171.722 565.027C171.028 565.123 171.322 566.177 172.012 566.082Z"
                fill="white"
            />
            <path
                d="M209.502 567.008C200.81 567.802 191.821 569.955 184.587 575.052C176.332 580.869 172.607 589.963 171.186 599.694C171.086 600.381 172.137 600.679 172.24 599.984C173.643 590.368 177.291 581.335 185.568 575.698C192.574 570.925 201.163 568.86 209.502 568.099C210.197 568.037 210.204 566.942 209.502 567.008Z"
                fill="white"
            />
            <path
                d="M182.817 516.974C189.727 512.109 198.232 509.834 206.626 509.702C213.632 509.592 222.092 511.142 226.284 517.397C230.836 524.187 228.18 533.312 223.11 539.007C217.666 545.122 209.767 548.734 202.357 551.857C193.037 555.789 182.762 558.608 172.681 556.105C171.998 555.936 171.708 556.991 172.391 557.16C181.906 559.523 191.623 557.3 200.55 553.768C208.65 550.564 217.456 546.717 223.485 540.213C228.547 534.752 231.545 526.403 228.499 519.205C225.509 512.135 217.706 509.327 210.583 508.732C200.814 507.916 190.319 510.356 182.266 516.026C181.693 516.43 182.236 517.378 182.817 516.974Z"
                fill="white"
            />
            <path
                d="M134.552 592.271C166.586 591.305 173.537 571.88 171.557 558.42C170.308 549.943 153.199 488.485 153.199 488.485"
                stroke="white"
                strokeWidth="5"
                strokeMiterlimit="10"
            />
            <path d="M50.3282 579.244L59.4427 589.32" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M49.9132 586.307L59.024 596.383" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M49.8838 593.91L58.9946 603.986" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M144.676 605.489L157.832 608.167" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M148.394 611.46L161.55 614.139" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M152.733 617.633L165.885 620.312" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <path
                d="M157.358 394.829C160.815 414.536 147.648 433.317 127.942 436.779C108.24 440.237 89.464 427.067 86.0034 407.356"
                fill="#00D563"
            />
            <path
                d="M132.197 370.128C133.207 380.057 125.981 388.924 116.054 389.935C107.278 390.828 99.3318 385.283 96.9108 377.125C96.5948 376.056 96.3708 374.942 96.2532 373.788C95.2429 363.863 102.469 354.993 112.395 353.982C117.561 353.457 122.436 355.158 126.069 358.314C129.419 361.225 131.712 365.366 132.197 370.128Z"
                fill="#FFC200"
            />
            <path
                d="M126.065 358.314C122.211 368.324 112.998 375.857 101.598 377.018C100.015 377.18 98.4499 377.209 96.907 377.125C96.5911 376.056 96.367 374.942 96.2494 373.788C95.2391 363.863 102.465 354.993 112.392 353.982C117.561 353.457 122.432 355.158 126.065 358.314Z"
                fill="black"
            />
            <path
                d="M96.0953 361.831C99.8975 361.831 102.98 358.748 102.98 354.945C102.98 351.142 99.8975 348.059 96.0953 348.059C92.2931 348.059 89.2108 351.142 89.2108 354.945C89.2108 358.748 92.2931 361.831 96.0953 361.831Z"
                fill="black"
            />
            <path
                d="M96.6977 348.452C107.52 348.452 116.293 339.676 116.293 328.851C116.293 318.026 107.52 309.251 96.6977 309.251C85.8754 309.251 77.1022 318.026 77.1022 328.851C77.1022 339.676 85.8754 348.452 96.6977 348.452Z"
                fill="black"
            />
            <path
                d="M129.283 373.185C130.272 373.084 130.946 371.745 130.788 370.195C130.63 368.644 129.7 367.469 128.711 367.569C127.722 367.67 127.048 369.009 127.206 370.559C127.364 372.11 128.294 373.286 129.283 373.185Z"
                fill="black"
            />
            <path
                d="M123.35 373.986C124.339 373.885 125.013 372.546 124.855 370.996C124.697 369.445 123.767 368.27 122.778 368.37C121.789 368.471 121.115 369.81 121.273 371.361C121.431 372.911 122.361 374.087 123.35 373.986Z"
                fill="black"
            />
            <path
                d="M129.596 377.72C129.596 377.72 126.565 377.305 126.458 374.556"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M113.204 428.279C113.204 428.279 170.943 416.87 180.804 408.407C195.304 395.965 209.572 359.891 209.572 359.891C209.572 359.891 229.3 343.638 227.684 341.525C225.994 339.32 213.349 347.011 213.349 347.011C213.349 347.011 228.235 333.474 226.391 331.243C223.947 328.285 208.996 341.819 208.996 341.819C208.996 341.819 217.743 328.377 216.075 327.675C213.808 326.724 202.831 339.188 202.831 339.188C202.831 339.188 201.057 331.027 199.201 331.328C196.531 331.765 197.523 341.459 193.643 348.073C190.899 352.751 166.932 388.494 166.932 388.494L108.454 401.631"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinejoin="bevel"
            />
            <path
                d="M229.462 362.522L207.746 391.676L198.786 391.173"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinejoin="bevel"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="480" height="1024" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
