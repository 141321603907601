import * as t from "io-ts";
import { makeUncheckedStringCodec } from "@glide/support";

export type ScheduleKind =
    | "every-5-mins"
    | "every-15-mins"
    | "every-30-mins"
    | "every-hour"
    | "every-day"
    | "every-week"
    | "every-month"
    | "custom";
export const scheduleKindCodec = makeUncheckedStringCodec<ScheduleKind>();

export type AutomationTriggerKind = "app" | "schedule" | "plugin" | "manual";
export const automationTriggerKind = makeUncheckedStringCodec<AutomationTriggerKind>();

export const automationMaxStepsCodec = t.number;
