import {
    type SuperTableViewWindow,
    type WireActionWithTitle,
    type WireNewDataGridListComponent,
    type WireNewDataGridRow,
    type WireNewDataGridBooleanCell,
    type WireNewDataGridButtonCell,
    type WireNewDataGridExtraActionsCell,
    type WireNewDataGridNumberCell,
    type WireNewDataGridRowHeaderCell,
    type WireNewDataGridTextCell,
    type WireNewDataGridChoiceCell,
    type WireNewDataGridTagsCell,
    type WireTagOrChoiceItem,
    getSuperTableViewWindowKey,
} from "@glide/fluent-components/dist/js/base-components";
import type { WireRenderer } from "../wire-renderer";
import * as React from "react";

import {
    type WireAlwaysEditableValue,
    type WirePaging,
    APP_MODAL_ROOT,
    UIStyleVariant,
    ValueChangeSource,
} from "@glide/wire";
import { assertNever, defined } from "@glideapps/ts-necessities";
import { ignore } from "@glide/support";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { definedMap } from "collection-utils";
import { WireListContainer, useMultipleDynamicFilters, useSearchBar } from "../wire-list-container/wire-list-container";
import { extractActions, runActionAndHandleURL } from "../../wire-lib";
import { type UseLayerProps, useLayer } from "react-laag";
import { WireFloatingMenu } from "../wire-menu-button/wire-menu-button";
import { ClickOutsideContainer, getGenerativeColor, getScrollBarWidth } from "@glide/common";
import "twin.macro";
import type { GridSelection, Theme } from "@glideapps/glide-data-grid";
import chroma from "chroma-js";
import { useContainerBackground } from "../wire-container/wire-container";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { Pager } from "../card-collection/card-collection";
import type {
    NewDataGridRow,
    NewDataGridBooleanCell,
    NewDataGridButtonCell,
    NewDataGridExtraActionsCell,
    NewDataGridNumberCell,
    NewDataGridRowHeaderCell,
    NewDataGridTextCell,
    TagOrChoiceItem,
    NewDataGridChoiceCell,
    NewDataGridTagsCell,
} from "@glide/component-utils";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import type { WireAppTheme } from "@glide/theme";
import { isDarkAppTheme } from "../../utils/is-dark-theme";

const StreamingNewDataGrid = React.lazy(() => import("../../components/new-data-grid/streaming-new-data-grid"));

class PageStream {
    private queue: NewDataGridRow[][] = [];
    private notifyNextPage: () => void;
    private isFinished: boolean;

    readonly stream: AsyncGenerator<NewDataGridRow[]>;

    constructor() {
        this.isFinished = false;
        this.queue = [];
        this.notifyNextPage = ignore;
        this.stream = this.makeStream();
    }

    private async *makeStream() {
        for (;;) {
            if (this.isFinished) {
                return;
            }

            // Wait for the next page to be yielded
            // We may have yielded many pages, so we'll re-iterate until the queue is empty.
            if (this.queue.length === 0) {
                await new Promise<void>(resolve => (this.notifyNextPage = resolve));
            } else {
                // This will always be defined, we checked the length above.
                const newPage = defined(this.queue.shift());
                yield newPage;
            }
        }
    }

    public yieldPage = (page: NewDataGridRow[]): void => {
        this.queue.push(page);
        this.notifyNextPage();
    };

    public close = () => {
        this.isFinished = true;
    };
}

const PAGE_SIZE = 500;

function makeNewDataGridRowFromWire(
    backend: WireBackendInterface,
    wireRow: WireNewDataGridRow,
    setExtraActionsProps: React.Dispatch<React.SetStateAction<ExtraActionsProps | undefined>>,
    theme: WireAppTheme
): NewDataGridRow {
    const row: NewDataGridRow = wireRow.cells.map(wireCell => {
        switch (wireCell.kind) {
            case "text":
                return makeNewDataGridTextCellFromWire(backend, wireCell);

            case "number":
                return makeNewDataGridNumberCellFromWire(backend, wireCell);

            case "boolean":
                return makeNewDataGridBooleanCellFromWire(backend, wireCell);

            case "image":
                return wireCell;

            case "link":
                return wireCell;

            case "button":
                return makeNewDataGridButtonCellFromWire(backend, wireCell);

            case "row-header":
                return makeNewDataGridRowHeaderCellFromWire(backend, wireCell);

            case "extra-actions":
                return makeNewDataGridExtraActionsCellFromWire(backend, wireCell, setExtraActionsProps);

            case "choice":
                return makeNewDataGridChoiceCellFromWire(backend, wireCell);

            case "tag":
                return makeNewDataGridTagsCellFromWire(backend, wireCell, theme);

            case "blank":
                return wireCell;

            default:
                assertNever(wireCell);
        }
    });

    return row;
}

function makeOnValueChange<T>(
    backend: WireBackendInterface,
    onChangeToken: string | undefined
): ((newValue: T) => void) | undefined {
    return definedMap(onChangeToken, t => {
        return (newValue: T) => {
            backend.valueChanged(t, newValue, ValueChangeSource.User);
        };
    });
}

function makeChoiceOptionFromWire(wireOption: WireTagOrChoiceItem, backend: WireBackendInterface): TagOrChoiceItem {
    const { onSelect, ...rest } = wireOption;

    return {
        ...rest,
        onSelect: definedMap(onSelect, action => () => {
            runActionAndHandleURL(action, backend, true);
        }),
    };
}

function makeNewDataGridTextCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireNewDataGridTextCell
): NewDataGridTextCell {
    const { editableValue } = wireCell;
    const { value, displayValue, onChangeToken } = editableValue;

    return {
        kind: "text",
        value: value,
        displayValue: displayValue ?? value,
        onValueChange: makeOnValueChange(backend, onChangeToken),
    };
}

function makeNewDataGridNumberCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireNewDataGridNumberCell
): NewDataGridNumberCell {
    const { editableValue } = wireCell;
    const { value, displayValue, onChangeToken } = editableValue;

    return {
        kind: "number",
        value: value,
        displayValue: displayValue ?? value.toString(),
        onValueChange: makeOnValueChange(backend, onChangeToken),
    };
}

function makeNewDataGridBooleanCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireNewDataGridBooleanCell
): NewDataGridBooleanCell {
    const { editableValue } = wireCell;
    const { value, onChangeToken } = editableValue;

    return {
        kind: "boolean",
        value: value,
        onValueChange: makeOnValueChange(backend, onChangeToken),
    };
}

function makeNewDataGridButtonCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireNewDataGridButtonCell
): NewDataGridButtonCell {
    const { action, label } = wireCell;

    const onClick = () => {
        runActionAndHandleURL(action, backend, true);
    };

    return {
        kind: "button",
        value: undefined,
        label,
        onClick,
    };
}

function makeNewDataGridRowHeaderCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireNewDataGridRowHeaderCell
): NewDataGridRowHeaderCell {
    const { editableValue } = wireCell;
    const { value, displayValue, onChangeToken } = editableValue;

    const onClick = () => {
        runActionAndHandleURL(wireCell.action, backend, true);
    };

    return {
        kind: "row-header",
        value: value,
        displayValue: displayValue ?? value,
        onValueChange: makeOnValueChange(backend, onChangeToken),
        onClick,
    };
}

function makeNewDataGridExtraActionsCellFromWire(
    _backend: WireBackendInterface,
    wireCell: WireNewDataGridExtraActionsCell,
    setExtraActionsProps: React.Dispatch<React.SetStateAction<ExtraActionsProps | undefined>>
): NewDataGridExtraActionsCell {
    const { actions } = wireCell;

    return {
        kind: "extra-actions",
        value: undefined,
        onClick: (screenX: number, screenY: number) => {
            setExtraActionsProps({
                actions,
                screenX,
                screenY,
            });
        },
    };
}

function getTagOptionsWithColor(cell: WireNewDataGridTagsCell, theme: WireAppTheme): WireTagOrChoiceItem[] {
    if (cell.tagColorKind === "Manual") {
        return cell.options;
    }
    const isDark = isDarkAppTheme(theme);
    const coloredOptions: WireTagOrChoiceItem[] = [];
    for (let i = 0; i < cell.options.length; i++) {
        const option = cell.options[i];
        const color = getGenerativeColor(theme.accent, i, isDark);

        coloredOptions.push({
            ...option,
            color,
        });
    }

    return coloredOptions;
}

function getChoiceOrTagItemsFromValue(value: string[], options: TagOrChoiceItem[]): TagOrChoiceItem[] {
    const items: TagOrChoiceItem[] = [];
    for (const v of value) {
        const valueNotInOptions: TagOrChoiceItem = {
            value: v,
            displayValue: v,
            color: undefined,
            onSelect: ignore,
        };

        const valueInOptions = options.find(o => o.value === v);

        items.push(valueInOptions ?? valueNotInOptions);
    }

    return items;
}

function makeNewDataGridChoiceCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireNewDataGridChoiceCell
): NewDataGridChoiceCell {
    const { value, options: wireOptions, isEditable, isMulti } = wireCell;

    const options = wireOptions.map(o => makeChoiceOptionFromWire(o, backend));

    return {
        kind: "choice",
        value: getChoiceOrTagItemsFromValue(value, options),
        options,
        isEditable,
        isMulti,
    };
}

function makeNewDataGridTagsCellFromWire(
    backend: WireBackendInterface,
    wireCell: WireNewDataGridTagsCell,
    theme: WireAppTheme
): NewDataGridTagsCell {
    const options = getTagOptionsWithColor(wireCell, theme).map(o => makeChoiceOptionFromWire(o, backend));

    const { value, isEditable, isMulti } = wireCell;

    return {
        kind: "tag",
        value: getChoiceOrTagItemsFromValue(value, options),
        options,
        isEditable,
        isMulti,
    };
}

interface ExtraActionsProps {
    readonly actions: readonly WireActionWithTitle[];
    readonly screenX: number;
    readonly screenY: number;
}

interface ExtraActionsMenuProps {
    readonly laagProps: UseLayerProps;
    readonly extraActionsProps: ExtraActionsProps | undefined;
    readonly setExtraActionsProps: React.Dispatch<React.SetStateAction<ExtraActionsProps | undefined>>;
}

function useExtraActionsMenu(): ExtraActionsMenuProps {
    const [extraActionsProps, setExtraActionsProps] = React.useState<ExtraActionsProps | undefined>();

    const getBounds = React.useCallback(() => {
        const screenX = extraActionsProps?.screenX ?? 0;
        const screenY = extraActionsProps?.screenY ?? 0;

        // eyeballed size to make it look similar to other menus
        return {
            top: screenY,
            left: screenX,
            right: screenX + 42,
            bottom: screenY + 36,
            width: 42,
            height: 36,
        };
    }, [extraActionsProps?.screenX, extraActionsProps?.screenY]);

    const laagProps = useLayer({
        isOpen: extraActionsProps !== undefined,
        auto: true,
        placement: "bottom-end",
        possiblePlacements: ["top-end", "top-start", "bottom-end", "bottom-start"],
        container: APP_MODAL_ROOT,
        triggerOffset: 5,
        trigger: {
            getBounds,
        },
    });

    return {
        laagProps,
        extraActionsProps,
        setExtraActionsProps,
    };
}

interface SuperTableProps {
    readonly getPageStream: (range: readonly [start: number, end: number]) => AsyncGenerator<readonly NewDataGridRow[]>;
    readonly rowsCount: number;
}

function useSuperTableProps(wireProps: {
    backend: WireBackendInterface;
    rows: readonly WireNewDataGridRow[];
    viewWindow: WireAlwaysEditableValue<SuperTableViewWindow>;
    hasNoMoreRows: boolean;
    setExtraActionsProps: React.Dispatch<React.SetStateAction<ExtraActionsProps | undefined>>;
}): SuperTableProps {
    const { backend, rows, viewWindow, hasNoMoreRows, setExtraActionsProps } = wireProps;

    // Here we keep track of the streams for each page.
    const streamForPage = React.useRef<Map<string, PageStream>>(new Map());

    const { onChangeToken: viewWindowChangeToken, value: viewWindowValue } = viewWindow;
    const { end: viewWindowEnd, maxRequested } = viewWindowValue;

    const setViewWindow = React.useCallback(
        (start: number, end: number) => {
            const newWindow: SuperTableViewWindow = {
                start,
                end,
                maxRequested: Math.max(viewWindowEnd, end),
            };
            backend.valueChanged(viewWindowChangeToken, newWindow, ValueChangeSource.User);
        },
        [backend, viewWindowChangeToken, viewWindowEnd]
    );

    const getPageStream = React.useCallback(
        (range: readonly [start: number, end: number]): AsyncGenerator<readonly NewDataGridRow[]> => {
            const [start, end] = range;

            const windowKey = getSuperTableViewWindowKey(start, end);

            const existingStreamForWindow = streamForPage.current.get(windowKey);
            if (existingStreamForWindow !== undefined) {
                return existingStreamForWindow.stream;
            }

            const pageStream = new PageStream();
            streamForPage.current.set(windowKey, pageStream);

            setViewWindow(start, end);

            return pageStream.stream;
        },
        [setViewWindow]
    );

    const theme = useWireAppTheme();

    /**
     * Here we yield all the page changes.
     */
    React.useEffect(() => {
        const pagesCount = Math.ceil(rows.length / PAGE_SIZE);
        for (let i = 0; i < pagesCount; i++) {
            const start = i * PAGE_SIZE;
            const end = (i + 1) * PAGE_SIZE;
            const keyForWindow = getSuperTableViewWindowKey(start, end);

            const pageStream = streamForPage.current.get(keyForWindow);
            if (pageStream === undefined) {
                continue;
            }

            const chunk = rows
                .slice(start, end)
                .map(r => makeNewDataGridRowFromWire(backend, r, setExtraActionsProps, theme));
            pageStream.yieldPage(chunk);
        }
    }, [backend, rows, setExtraActionsProps, theme]);

    // Make sure we close all the streams
    React.useEffect(() => {
        const streams = streamForPage.current;
        return () => {
            for (const pageStream of streams.values()) {
                pageStream.close();
            }
        };
    }, []);

    // If we count maxRequested super-table keeps on requesting more and more pages in a loop.
    // This -100 fixes that.
    // Don't ask me how.
    const rowsCount = hasNoMoreRows ? rows.length : maxRequested - 100;

    return {
        getPageStream,
        rowsCount,
    };
}

type WireNewDataGridRenderer = WireRenderer<WireNewDataGridListComponent, { isFirstComponent: boolean }>;

const ROW_HEIGHT = 36;
const HEADER_HEIGHT = 32;

export const WireNewDataGrid: WireNewDataGridRenderer = React.memo(p => {
    const {
        columns,
        rows,
        viewWindow,
        backend,
        hasNoMoreRows,
        isFirstComponent,
        multipleDynamicFilters,
        searchBar,
        title,
        titleActions,
        paging,
    } = p;

    const { laagProps, extraActionsProps, setExtraActionsProps } = useExtraActionsMenu();
    const { renderLayer, layerProps } = laagProps;

    const closeMenu = React.useCallback(() => {
        setExtraActionsProps(undefined);
    }, [setExtraActionsProps]);

    const menuItems = React.useMemo(() => {
        const actions = extraActionsProps?.actions ?? [];

        return extractActions(actions, backend);
    }, [backend, extraActionsProps?.actions]);

    const { getPageStream, rowsCount } = useSuperTableProps({
        backend,
        viewWindow,
        hasNoMoreRows,
        rows,
        setExtraActionsProps,
    });

    const multipleFilterProps = useMultipleDynamicFilters(multipleDynamicFilters, backend);
    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, undefined);

    const theme = useWireAppTheme();

    const containerBackground = useContainerBackground();

    const superTableTheme: Partial<Theme> = React.useMemo(() => {
        const bg = containerBackground ?? theme.bgContainerBase;

        return {
            bgCell: bg,
            bgHeader: bg,
            bgHeaderHasFocus: bg,
            bgHeaderHovered: bg,
            textHeader: theme.textContextualXpale,
            textDark: theme.textContextualBase,
            textMedium: theme.textContextualPale,
            textLight: theme.textContextualXpale,
            linkColor: theme.textContextualAccent,
            accentColor: theme.textContextualAccent,
            accentLight: chroma(theme.textContextualAccent).alpha(0.1).css(),
            headerFontStyle: "500 14px",
            baseFontStyle: "400 14px",
            borderColor: theme.borderBase,
        };
    }, [containerBackground, theme]);

    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    const onDelete = React.useCallback(
        (selection: GridSelection) => {
            if (selection.rows.length > 0) {
                for (const r of selection.rows) {
                    const item = rows[r];
                    if (item !== undefined) {
                        runActionAndHandleURL(item.deleteAction, backend);
                    }
                }
                return false;
            }
            return true;
        },
        [backend, rows]
    );

    const onHeaderAction = React.useCallback(
        (columnIdx: number) => {
            const headerAction = columns[columnIdx].headerAction;
            if (headerAction !== undefined) {
                runActionAndHandleURL(headerAction, backend);
            }
        },
        [backend, columns]
    );

    return (
        <>
            <WireListContainer
                title={title ?? undefined}
                titleActions={extractActions(titleActions, backend)}
                styleVariant={UIStyleVariant.Default}
                isFirstComponent={isFirstComponent}
                appKind={backend.appKind}
                searchValue={searchValue}
                onSearchChange={onSearchChange}
                multipleFilterProps={multipleFilterProps}
                // This is for legacy filters
                hasFilter={false}>
                <div
                    style={{
                        margin: "0 calc(-1 * var(--container-x-pad))",
                    }}>
                    <React.Suspense fallback={<DataGridFallback rowsCount={rowsCount} />}>
                        <StreamingNewDataGrid
                            doubleClickActivation={!isMobile}
                            width="100%"
                            pageSize={PAGE_SIZE}
                            getPageStream={getPageStream}
                            columns={columns}
                            rows={rowsCount}
                            baseTheme={superTableTheme}
                            onItemHovered={undefined}
                            getRowThemeOverride={undefined}
                            onDelete={onDelete}
                            headerAction={onHeaderAction}
                            rowMarkers={"both"}
                            rowHeight={ROW_HEIGHT}
                            headerHeight={HEADER_HEIGHT}
                        />
                    </React.Suspense>
                </div>
                <Paginator paging={paging} backend={backend} />
            </WireListContainer>
            {extraActionsProps !== undefined &&
                menuItems.length > 0 &&
                renderLayer(
                    <div {...layerProps}>
                        <ClickOutsideContainer onClickOutside={closeMenu}>
                            <WireFloatingMenu menuItems={menuItems} closeMenu={closeMenu} />
                        </ClickOutsideContainer>
                    </div>
                )}
        </>
    );
});

interface PaginatorProps {
    readonly paging: WirePaging | undefined;
    readonly backend: WireBackendInterface;
}

const Paginator: React.FC<PaginatorProps> = p => {
    const { paging, backend } = p;

    if (paging === undefined) {
        return null;
    }

    const { numPages, pageIndex } = paging;

    const token = pageIndex.onChangeToken;

    if (numPages < 2 || token === undefined) {
        return null;
    }

    const onPageChange = (newPage: number) => {
        backend.valueChanged(token, newPage, ValueChangeSource.User);
    };

    return (
        <div tw="self-center">
            <Pager numPages={numPages} page={pageIndex.value} onPageChange={onPageChange} />
        </div>
    );
};

interface DataGridFallbackProps {
    readonly rowsCount: number;
}

const DataGridFallback: React.FC<DataGridFallbackProps> = p => {
    const { rowsCount } = p;

    const scrollbarHeight = getScrollBarWidth();
    const height = HEADER_HEIGHT + ROW_HEIGHT * rowsCount + scrollbarHeight;

    return <div style={{ height }} />;
};
