import React from "react";

function truthify(x: unknown): boolean {
    return !!(x as boolean);
}

export function isOSThemeDark(): boolean {
    return Boolean(truthify(window.matchMedia)) && window.matchMedia("(prefers-color-scheme: dark)").matches;
}

type ThemeCallback = (isDark: boolean) => void;
const callbacks: Set<ThemeCallback> = new Set();

let match: MediaQueryList | undefined;
function init() {
    if (match === undefined && Boolean(truthify(window.matchMedia))) {
        match = window.matchMedia("(prefers-color-scheme: dark)");

        match.addListener(e => {
            callbacks.forEach(cb => cb(e.matches));
        });
    }
}

export function watchOSThemeDark(callback: ThemeCallback) {
    init();
    callbacks.add(callback);
    callback(isOSThemeDark());
}

function unwatchOSThemeDark(callback: ThemeCallback) {
    callbacks.delete(callback);
}

export function useOSThemeDark() {
    const [isDark, setIsDark] = React.useState(isOSThemeDark());

    React.useEffect(() => {
        watchOSThemeDark(setIsDark);
        return () => {
            unwatchOSThemeDark(setIsDark);
        };
    }, []);

    return isDark;
}
