import React from "react";
import type { WireStopwatch } from "@glide/fluent-components/dist/js/fluent-components";
import type { WireRenderer } from "../wire-renderer";
import { WireButton } from "../wire-button/wire-button";
import { UIButtonAppearance, ValueChangeSource } from "@glide/wire";
import { StopwatchDisplay } from "../../components/stopwatch/stopwatch";
import type { ElapsedTime, StopwatchState } from "../../components/stopwatch/use-stopwatch";
import { computeElapsedSecondsFromLogEntires, useStopwatch } from "../../components/stopwatch/use-stopwatch";
import { isEmpty, isUndefinedish } from "@glide/support";
import "twin.macro";

interface StopwatchProps extends Omit<StopwatchState, "fauxElapsedTime"> {
    onChange: (s: StopwatchState, elapsedTimeFromStopwatch: ElapsedTime) => void;
    initialElapsedTime: number;
    showReset: boolean;
}

export const WireStopwatchContainer: WireRenderer<WireStopwatch> = props => {
    const { backend, isRunning, elapsedTime, showReset, internalLogs } = props;

    const deserializedLogs = React.useMemo(() => {
        const logsWithStringDates = !isEmpty(internalLogs?.value) ? JSON.parse(internalLogs?.value ?? "[]") : [];
        return logsWithStringDates.map((log: any) => ({
            ...log,
            startedOn: new Date(log.startedOn),
            stoppedOn: log.stoppedOn !== undefined ? new Date(log.stoppedOn) : undefined,
        }));
    }, [internalLogs]);

    const internalElapsedTime = React.useMemo(() => {
        return computeElapsedSecondsFromLogEntires(deserializedLogs);
    }, [deserializedLogs]);

    const stopwatchState = {
        logs: deserializedLogs,
        isRunning: Boolean(isRunning?.value),
    };

    const onChange = React.useCallback(
        (state: StopwatchState, elapsedTimeFromStopwatch: ElapsedTime) => {
            // note that we update seconds here, not milliseconds.
            // event seconds may prove to burn updates too quickly
            const durationToken = elapsedTime?.onChangeToken;
            if (!isUndefinedish(durationToken)) {
                backend.valueChanged(durationToken, elapsedTimeFromStopwatch.seconds, ValueChangeSource.User);
            }

            const logsToken = internalLogs?.onChangeToken;
            if (!isUndefinedish(logsToken)) {
                backend.valueChanged(logsToken, JSON.stringify(state.logs), ValueChangeSource.User);
            }

            const isRunningToken = isRunning?.onChangeToken;
            if (!isUndefinedish(isRunningToken)) {
                backend.valueChanged(isRunningToken, state.isRunning, ValueChangeSource.User);
            }
        },
        [backend, isRunning?.onChangeToken, internalLogs?.onChangeToken, elapsedTime?.onChangeToken]
    );

    return (
        <Stopwatch
            onChange={onChange}
            isRunning={stopwatchState.isRunning}
            initialElapsedTime={internalElapsedTime}
            logs={stopwatchState.logs}
            showReset={showReset}
        />
    );
};

export const Stopwatch: React.FC<StopwatchProps> = p => {
    const { showReset, initialElapsedTime } = p;
    const { elapsedTime, isRunning, start, stop, reset } = useStopwatch(
        {
            isRunning: p.isRunning,
            logs: p.logs,
            fauxElapsedTime: 0,
        },
        initialElapsedTime,
        p.onChange
    );

    return (
        <div test-id="stopwatch-container" tw="flex flex-col items-center space-y-2">
            <StopwatchDisplay tw="text-text-base text-4xl" elapsedTime={elapsedTime.milliseconds} />
            <WireButton
                tw="w-full"
                onClick={() => {
                    if (isRunning) {
                        stop();
                    } else {
                        start();
                    }
                }}
                appearance={UIButtonAppearance.Filled}>
                {isRunning ? "Stop" : "Start"}
            </WireButton>
            {showReset ? (
                <WireButton tw="w-full" onClick={reset} appearance={UIButtonAppearance.MobileSecondary}>
                    Reset
                </WireButton>
            ) : null}
        </div>
    );
};
