import type { OverlayTheme } from "../overlay-theme";

export const smallListItemOverlay = {
    overlayName: "SmallListItemOverlay",
    listItem: {
        defaultRowHeight: 48,
        initialsFontSize: 20,

        mainImage: {
            width: 32,
            height: 32,
        },

        actionImage: {
            width: 32,
            height: 32,
        },
    },
} as OverlayTheme;
