import {
    type ColumnType,
    type UniversalTableName,
    isSingleRelationType,
    getTableRefTableName,
    isMultiRelationType,
} from "@glide/type-schema";

export function getRelationTableRef(type: ColumnType): UniversalTableName | undefined {
    if (isSingleRelationType(type)) {
        return getTableRefTableName(type);
    } else if (isMultiRelationType(type)) {
        return getTableRefTableName(type.items);
    } else {
        return undefined;
    }
}
