import { GlideIcon, type GlideFC } from "@glide/common";
import type { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { UpgradePill } from "../paywall/upgrade-pill";
import { glideIconPropsCodec, type GlideIconProps } from "@glide/plugins-codecs";
import Markdown from "marked-react";
import { css } from "styled-components";
import { useBuilderTheme } from "../../hooks/use-builder-theme";
import "twin.macro";
import { useMemo } from "react";

interface Props extends React.PropsWithChildren {
    icon?: string | GlideIconProps;
    name: string;
    description?: string;
    requiredPlan?: PlanKind;
}

export const IntegrationDetailsHeader: GlideFC<Props> = ({ icon, name, description, requiredPlan }) => {
    const theme = useBuilderTheme();
    const strokeIcon = useMemo(() => {
        if (icon && glideIconPropsCodec.is(icon) && icon.kind === "stroke") {
            return { ...icon, strokeWidth: icon.kind === "stroke" ? 3 : undefined };
        }
        return undefined;
    }, [icon]);
    return (
        <div tw="mb-4">
            {icon && glideIconPropsCodec.is(icon) && (
                <div tw="flex items-center justify-center w-16 h-16 mb-3 text-icon-base rounded-lg bg-n0">
                    <GlideIcon {...(strokeIcon ?? icon)} iconSize={48} />
                </div>
            )}
            {icon && typeof icon === "string" && <img tw="w-16 h-16 rounded-lg mb-3" src={icon} alt="Icon" />}
            <div tw="flex items-center mb-1">
                <h3 tw="font-semibold text-lg mr-2">{name}</h3>
                {requiredPlan !== undefined && (
                    <UpgradePill planKind={requiredPlan} extended={true} location={`integration_settings_${name}`} />
                )}
            </div>
            {description && (
                <p
                    tw="text-text-pale"
                    css={css`
                        a {
                            color: ${theme.blue};
                        }
                    `}
                >
                    <Markdown>{description}</Markdown>
                </p>
            )}
        </div>
    );
};
