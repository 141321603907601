import type { GlideFC } from "@glide/common";
import React from "react";
import { useLayer } from "react-laag";

import WindowedModalV2 from "../windowed-modal/windowed-modal";

interface SimpleModalProviderLaagProps {
    triggerElement?: React.ReactElement | null;
    modalHandler: (onClose: () => void) => React.ReactElement;
    onTrigger?: () => void;
    startOpen?: boolean;
}

export const SimpleModalProviderLaag: GlideFC<SimpleModalProviderLaagProps> = p => {
    const { triggerElement = null, modalHandler, onTrigger, startOpen } = p;

    const [isOpen, setIsOpen] = React.useState(startOpen ?? false);
    const { layerProps, triggerProps, renderLayer } = useLayer({
        isOpen,
        container: "portal",
        onParentClose: () => setIsOpen(false),
        onOutsideClick: () => setIsOpen(false),
    });

    const onTriggerClick = React.useCallback(() => {
        if (onTrigger !== undefined) {
            onTrigger();
        }
        setIsOpen(true);
    }, [onTrigger, setIsOpen]);

    const modal = React.useMemo(
        () =>
            modalHandler(() => {
                setIsOpen(false);
            }),
        [modalHandler]
    );

    return (
        <div>
            <div {...triggerProps} onClick={onTriggerClick}>
                {triggerElement}
            </div>
            {isOpen &&
                renderLayer(
                    <div {...layerProps} className="click-outside-ignore">
                        <WindowedModalV2
                            tw="fixed left-0 top-0 w-full h-full"
                            canClose={true}
                            onClose={() => setIsOpen(false)}
                            noPortal={true}>
                            {modal}
                        </WindowedModalV2>
                    </div>
                )}
        </div>
    );
};
