import {
    type ActionComponentDescription,
    type ActionDescription,
    type ActionKind,
    type ComponentDescription,
    type ComponentKind,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getActionProperty,
    getColumnProperty,
    getIconProperty,
    makeActionProperty,
    makeArrayProperty,
    makeColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import {
    type Description,
    type TableColumn,
    type TableGlideType,
    getTableColumn,
    getTableColumnDisplayName,
    isPrimitiveType,
    type SchemaInspector,
} from "@glide/type-schema";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { ListItemFlags } from "@glide/component-utils";
import type { WireAppAdaptiveListItemComponent } from "@glide/fluent-components/dist/js/base-components";
import { type WireActionRowDescription, makeActionWithTitle } from "@glide/fluent-components/dist/js/fluent-components";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentDescriptor,
    PropertySection,
    SwitchPropertyHandler,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { logInfo } from "@glide/support";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import { assert, definedMap } from "@glideapps/ts-necessities";

import { defaultActionForColumn, getDefaultPrimitiveActionKinds } from "../actions";
import { tokenForProperty } from "../actions/base";
import { iconForActions } from "../builder-utils";
import {
    hydrateAction,
    inflateActions,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerBusyActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { getActionsForComponent } from "./component-utils";
import { makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

const ComponentKindPrimitive: ComponentKind = "primitive";

const componentName = "Action Text";

interface PrimitiveComponentDescription extends ActionComponentDescription {
    readonly propertyName: LegacyPropertyDescription;
    readonly caption: LegacyPropertyDescription | undefined;
    readonly truncate: PropertyDescription | undefined;
    readonly actionIcon: PropertyDescription | undefined;
}

const truncatePropertyHandler = new SwitchPropertyHandler(
    {
        truncate: false,
    },
    "Trim text to first 4 lines",
    PropertySection.Options
);

export function makeDefaultActionDescriptorWithKinds(
    kinds: readonly ActionKind[],
    defaultAction: boolean | ((table: TableGlideType, desc: Description) => ActionDescription | undefined)
): ActionPropertyDescriptor {
    return {
        property: { name: "actions" },
        kind: PropertyKind.Action,
        label: "Action",
        kinds,
        defaultAction,
        section: PropertySection.Action,
    };
}

export function makeDefaultActionDescriptor(
    schema: SchemaInspector | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined,
    defaultAction: boolean | ((table: TableGlideType, desc: Description) => ActionDescription | undefined),
    extraKinds?: readonly ActionKind[]
): ActionPropertyDescriptor {
    const kinds = [...(extraKinds ?? []), ...getDefaultPrimitiveActionKinds(schema, mutatingScreenKind)];
    return makeDefaultActionDescriptorWithKinds(kinds, defaultAction);
}

export class PrimitiveComponentHandler extends ComponentHandlerBase<PrimitiveComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindPrimitive);
    }

    public get needsColumns(): boolean {
        return false;
    }

    public getDescriptor(
        desc: PrimitiveComponentDescription | undefined,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const actions = definedMap(desc, d =>
            getActionsForComponent(this, d, tables, ccc, mutatingScreenKind)
        )?.actions;
        let icon = definedMap(actions, iconForActions);
        if (icon === true) icon = undefined;
        const defaultIcon = typeof icon === "string" ? icon : undefined;
        return {
            name: componentName,
            description: "A labeled value",
            img: "co-action-text",
            group: "Text",
            helpUrl: getDocURL("actionText"),
            properties: [
                makeTextPropertyDescriptor("propertyName", "Text", "Enter text", true, mutatingScreenKind, {
                    isMultiLine: true,
                    preferredNames: ["text"],
                    columnFirst: true,
                    isDefaultCaption: true,
                }),
                makeCaptionStringPropertyDescriptor("Data", false, mutatingScreenKind),
                truncatePropertyHandler,
                {
                    kind: PropertyKind.Icon,
                    property: { name: "actionIcon" },
                    label: "Icon",
                    defaultIcon,
                    section: PropertySection.Design,
                },
                ...this.getBasePropertyDescriptors(),
            ],
        };
    }

    public getActionDescriptors(
        _desc: PrimitiveComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        schema: SchemaInspector | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly ActionPropertyDescriptor[] {
        return [
            makeDefaultActionDescriptor(schema, mutatingScreenKind, (t, d) => {
                const p = d as PrimitiveComponentDescription;
                const columnProperty = getColumnProperty(p.propertyName);
                if (columnProperty === undefined) return undefined;

                const column = getTableColumn(t, columnProperty);
                if (column === undefined) {
                    logInfo("Could not find column for table for primitive action", columnProperty, t);
                    return undefined;
                }
                return defaultActionForColumn(column);
            }),
        ];
    }

    public static defaultComponent(column: TableColumn): PrimitiveComponentDescription {
        assert(isPrimitiveType(column.type));

        const action = defaultActionForColumn(column);
        return {
            ...makeEmptyComponentDescription(ComponentKindPrimitive),
            propertyName: makeColumnProperty(column.name),
            caption: makeStringProperty(getTableColumnDisplayName(column)),
            actions: definedMap(action, makeActionProperty),
            truncate: makeSwitchProperty(false),
            actionIcon: undefined,
        };
    }

    public getDescriptiveName(
        desc: PrimitiveComponentDescription,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext
    ): [string, string] {
        return [
            componentName,
            tokenForProperty(desc.propertyName, {
                context: ccc,
                tables,
                mutatingScreenKind: undefined,
                isAutomation: false,
            })?.value ?? "",
        ];
    }

    public convertToPage(appPrimitive: PrimitiveComponentDescription): ComponentDescription {
        const actionRow: WireActionRowDescription = {
            ...makeEmptyComponentDescription(WireComponentKind.ActionRow),
            text: appPrimitive.propertyName as any,
            label: appPrimitive.caption as any,
            actions: definedMap(getActionProperty(appPrimitive.actions), a =>
                makeArrayProperty([makeActionWithTitle(undefined, a, getIconProperty(appPrimitive.actionIcon))])
            ),
        };
        return actionRow;
    }

    public inflate(
        ib: WireInflationBackend,
        desc: PrimitiveComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const { actions } = getActionsForComponent(this, desc, ib.tables, ib.adc, ib.mutatingScreenKind);
        const actionHydrator = inflateActions(ib, actions);

        const [titleGetter, titleType] = inflateStringProperty(ib, desc.propertyName, true);
        if (titleType === undefined) return undefined;

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);

        const actionIcon = getIconProperty(desc.actionIcon);
        const iconName = actionIcon ?? definedMap(actions, iconForActions);
        const truncate = truncatePropertyHandler.getSwitch(desc);

        let icon: string | undefined;
        let flags = ListItemFlags.WrapText | ListItemFlags.InvertTitleAndSubtitle | ListItemFlags.ConvertNewlines;
        if (truncate) {
            flags |= ListItemFlags.TruncateWrap;
        }
        if (iconName === undefined) {
            flags |= ListItemFlags.DisableChevron;
        } else if (typeof iconName === "string") {
            icon = iconName;
        }

        if (actions.length === 0) {
            flags |= ListItemFlags.LightWeight;
        }

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const title = titleGetter(hb);
            if (title === null || title === "") return undefined;

            const onTap = definedMap(actionHydrator, ah =>
                registerBusyActionRunner(hb, "", () => hydrateAction(ah, hb, false, title))
            );

            const component: WireAppAdaptiveListItemComponent = {
                kind: WireComponentKind.AppAdaptiveListItem,
                ...spreadComponentID(desc.componentID, forBuilder),
                title,
                subtitle: captionGetter(hb),
                caption: null,
                image: null,
                icon,
                onTap,
                flags,
            };
            return {
                component,
                isValid: true,
            };
        });
    }
}
