import { browserIsSafari, browserMightBeOniOS, iOSVersion, shortUserAgent } from "@glide/common";
import { sleep } from "@glideapps/ts-necessities";
import {
    SyncJobQueue,
    ConditionVariable,
    Semaphore,
    getCurrentTimestampInMilliseconds,
    hasUsableSessionStorage,
    logError,
    logInfo,
    sessionStorageGetItem,
    sessionStorageRemoveItem,
    sessionStorageSetItem,
    withTimeout,
} from "@glide/support";
import { getFeatureFlag } from "../feature-flags";
import { getLocationSettings } from "../location";
import { isStorybook } from "../routes";
import { isDocumentHidden } from "../support/browser-hacks";
import { reloadBrowserWindow } from "../support/browser-reload";
import { standalone } from "../support/device";
import { frontendSendEvent } from "../tracing";
import {
    isServiceWorkerErrorMessage,
    makeServiceWorkerInstallTimingRequest,
    swInstallTimingResponseMessageCodec,
    makeCacheCleanupMessage,
    makeDbCleanupMessage,
} from "@glide/service-worker-types";

let serviceWorkerEverInstalled = false;
const serviceWorkerEverInstalledCV = new ConditionVariable();
const networkBlockingSemaphore = new Semaphore();
const serviceWorkerBlockingSemaphore = new Semaphore();

async function waitForInstalledServiceWorker() {
    // There aint no service worker on storybook
    if (isStorybook()) return;
    while (!serviceWorkerEverInstalled) {
        await serviceWorkerEverInstalledCV.wait();
    }
}

export async function blockingServiceWorkerInstall<T>(fn: () => Promise<T>): Promise<T> {
    await waitForInstalledServiceWorker();
    while (networkBlockingSemaphore.currentCount() > 0) {
        await networkBlockingSemaphore.wait();
    }
    serviceWorkerBlockingSemaphore.acquire();
    try {
        return await fn();
    } finally {
        serviceWorkerBlockingSemaphore.release();
    }
}

async function acquireNetworkBlock() {
    while (serviceWorkerBlockingSemaphore.currentCount() > 0) {
        await serviceWorkerBlockingSemaphore.wait();
    }
    networkBlockingSemaphore.acquire();
}

async function blockingNetworkActivity<T>(fn: (unblockEarly: () => void) => Promise<T>): Promise<T> {
    await acquireNetworkBlock();
    let released = false;
    const unblockNetwork = () => {
        if (released) return;
        networkBlockingSemaphore.release();
        released = true;
    };
    try {
        return await fn(unblockNetwork);
    } finally {
        unblockNetwork();
    }
}

const prebootServiceWorkerError = "glide-sw-preboot-error";

function setServiceWorkerInstalled() {
    const oldInstalled = serviceWorkerEverInstalled;
    serviceWorkerEverInstalled = true;
    serviceWorkerEverInstalledCV.notifyAll();

    if (oldInstalled === false && sessionStorageGetItem(prebootServiceWorkerError) !== undefined) {
        sessionStorageRemoveItem(prebootServiceWorkerError);
        frontendSendEvent("early boot stuck service worker", 0, {
            standalone,
            browser_might_be_on_ios: browserMightBeOniOS,
            ios_major_version: iOSVersion[0],
            ios_minor_version: iOSVersion[1],
            userAgent: shortUserAgent,
        });
    }
}

async function removeAllRegistrations(sw: ServiceWorkerContainer) {
    const registrations = await sw.getRegistrations();
    for (const registration of registrations) {
        await registration.unregister();
    }
}

export default function register() {
    if (!("serviceWorker" in navigator)) return setServiceWorkerInstalled();
    // We don't need to try and register a service worker if we're opening
    // ourself. This often happens in the sign-in-with-Google flow, and is
    // a constant source of pain in Safari 16.
    try {
        if (window.opener !== null && window.opener.origin === window.origin) return setServiceWorkerInstalled();
    } catch (e: unknown) {
        logError("Could not check window.opener for Service Worker avoidance", e);
    }
    if (standalone && browserMightBeOniOS && iOSVersion[0] === 13) {
        // See https://github.com/djsweet/ios-pwa-freeze-bug:
        // The presence of Service Workers in iOS 13 PWAs causes them to freeze
        // when multitasking.
        logInfo("Removing service worker due to buggy iOS multitasking");
        window.addEventListener("load", () => {
            removeAllRegistrations(navigator.serviceWorker)
                .catch(() => {
                    /* Shut up ESlint, we don't care. */
                })
                .finally(() => setServiceWorkerInstalled());
        });
        return;
    }

    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL ?? "", window.location.toString());
    if (publicUrl.origin !== window.location.origin) {
        // Our service worker won't work if PUBLIC_URL is on a different origin
        // from what our page is served on. This might happen if a CDN is used to
        // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
        return setServiceWorkerInstalled();
    }

    const dv = (window as any).glideDeploymentVersion;

    if (browserIsSafari) {
        // We _must_ do this immediately on Safari, or else we get a completely
        // hung page for 10+ seconds.
        tryRegisterSW(`${getLocationSettings().serviceWorkerLocation}?dv=${dv}`);
    } else {
        // But if we're on Chrome, we have to defer to "load" or else we also
        // get hangs.
        window.addEventListener("load", () => {
            tryRegisterSW(`${getLocationSettings().serviceWorkerLocation}?dv=${dv}`);
        });
    }
}

const cacheCleanupPeriod = 15_000;
const cacheGCTimeout = 10_000;
const permanentHangTimeout = browserIsSafari ? 3_000 : 7_000;
let controllerCleanupInterval: NodeJS.Timeout | undefined;
let controllerVisibilityCleanupHandler: (() => void) | undefined;

function possiblyDispatchWorkerError(ev: MessageEvent) {
    // We don't handle cross-origin messages here. If we did,
    // that'd be a potential security vulnerability.
    if (ev.origin !== window.location.origin) return;

    if (!isServiceWorkerErrorMessage(ev.data)) return;
    const { action, exceptionStr } = ev.data;

    // FIXME: We should send these as normal "exception" and "action"
    // fields. The only reason we don't is so we don't overwhelm the
    // alerts with possible spurious failures.
    logError("Service worker exception", exceptionStr);
    frontendSendEvent("service worker exception", 0, {
        swException: exceptionStr,
        swAction: action,
    });
}

function possiblyDispatchWorkerTimings(ev: MessageEvent) {
    // We don't handle cross-origin messages here. If we did,
    // that'd be a potential security vulnerability.
    if (ev.origin !== window.location.origin) return;
    if (!swInstallTimingResponseMessageCodec.is(ev.data)) return;

    const {
        rootFetchStartTime,
        rootFetchHeaderDurationMS,
        rootFetchBodyDurationMS,
        cacheCheckStartTime,
        cacheCheckDurationMS,
        cacheCheckedEntries,
        cacheFetchStartTime,
        cacheFetchDurationMS,
        cacheFetchedEntries,
        cacheFetchTimings,
    } = ev.data;

    try {
        if (rootFetchStartTime !== undefined) {
            if (rootFetchHeaderDurationMS !== undefined) {
                frontendSendEvent("serviceWorker root fetch header", rootFetchHeaderDurationMS, {
                    timestamp: new Date(rootFetchStartTime).toISOString(),
                });
            }
            if (rootFetchBodyDurationMS !== undefined) {
                frontendSendEvent("serviceWorker root fetch body", rootFetchBodyDurationMS, {
                    timestamp: new Date(rootFetchStartTime).toISOString(),
                });
            }
        }
        frontendSendEvent("serviceWorker cache check", cacheCheckDurationMS, {
            timestamp: new Date(cacheCheckStartTime).toISOString(),
            entries: cacheCheckedEntries,
        });
        frontendSendEvent("serviceWorker cache fetch", cacheFetchDurationMS, {
            timestamp: new Date(cacheFetchStartTime).toISOString(),
            entries: cacheFetchedEntries,
        });

        if (cacheFetchTimings !== undefined && getLocationSettings().sendCacheTimingsToHoneycomb) {
            for (const { startTime, href, durationMS } of cacheFetchTimings) {
                frontendSendEvent("serviceWorker cache fetch entry", durationMS, {
                    timestamp: new Date(startTime).toISOString(),
                    href,
                });
            }
        }
    } catch (e: unknown) {
        logError(`Couldn't dispatch worker timings`, e);
    }
}

const serviceWorkerRegistrationTimeout = 7_000;
const serviceWorkerClearRegistrationTimeout = 2_000;
const controllersWithSetup = new WeakSet<ServiceWorker>();

function tryPostMessage(c: ServiceWorker, m: any) {
    try {
        c.postMessage(m);
    } catch {
        // If we can't post a message, that's not the end of the world.
    }
}

function setupServiceWorkerListeners(controller: ServiceWorker | null) {
    if (!("serviceWorker" in navigator)) return;
    const { serviceWorker } = navigator;
    if (controller === null) return;
    if (controllersWithSetup.has(controller)) return;

    if (controllerCleanupInterval !== undefined) {
        clearInterval(controllerCleanupInterval);
    }
    if (controllerVisibilityCleanupHandler !== undefined) {
        document.removeEventListener("visibilitychange", controllerVisibilityCleanupHandler);
    }

    controllerVisibilityCleanupHandler = () => {
        if (isDocumentHidden()) return;
        tryPostMessage(controller, makeDbCleanupMessage());
    };

    // Doing this both in an interval and a visibilitychange event listener
    // seems redundant, but there's a reason for it. Safari likes to forget
    // to dispatch events to background workers on page visibility transitions.
    // The hope behind this setup is that the cache cleanup message gets stuck
    // when being dispatched to the Service Worker, but further messages force
    // the Service Worker to be serviced immediately.
    controllerCleanupInterval = setInterval(controllerVisibilityCleanupHandler, cacheCleanupPeriod);
    document.addEventListener("visibilitychange", controllerVisibilityCleanupHandler);
    setTimeout(() => {
        tryPostMessage(controller, makeCacheCleanupMessage());
    }, cacheGCTimeout);
    serviceWorker.addEventListener("message", possiblyDispatchWorkerError);
    serviceWorker.addEventListener("message", possiblyDispatchWorkerTimings);

    // This little hack ensures that we have critical-path CSS and translation JS
    // available for offline use, even though we got them before we had a Service Worker.
    // Now that we have a Service Worker, fetching will automatically land them in the cache.
    const fetchQueue = new SyncJobQueue();
    const scripts = document.getElementsByTagName("script");
    for (let i = 0; i < scripts.length; i++) {
        const scriptElem = scripts[i];
        if (scriptElem === undefined) break;
        if (scriptElem.src.startsWith("/")) {
            // We need to make sure we actually drain the body, or else we'll
            // get stuck with an open connection.
            void fetchQueue.run(() => fetch(scriptElem.src).then(r => r?.text()));
        }
    }

    const links = document.getElementsByTagName("link");
    for (let i = 0; i < links.length; i++) {
        const linkElem = links[i];
        if (linkElem === undefined) break;
        if (linkElem.href.startsWith("/")) {
            // We need to make sure we actually drain the body, or else we'll
            // get stuck with an open connection.
            void fetchQueue.run(() => fetch(linkElem.href).then(r => r?.text()));
        }
    }

    controllersWithSetup.add(controller);
}

const hungServiceWorkerSessionKey = "glide-hung-service-worker";

function tryRegisterSW(swUrl: string) {
    if (!("serviceWorker" in navigator)) {
        setServiceWorkerInstalled();
        return;
    }

    if (!hasUsableSessionStorage()) {
        setServiceWorkerInstalled();
        return;
    }

    const wasHungFromReload = sessionStorageGetItem(hungServiceWorkerSessionKey);
    if (typeof wasHungFromReload === "string" && wasHungFromReload !== "do-again") {
        if (wasHungFromReload === "do-report") {
            logError("early boot stuck service worker installation");
            frontendSendEvent("early boot stuck service worker installation", 0, {
                standalone,
                browser_might_be_on_ios: browserMightBeOniOS,
                ios_major_version: iOSVersion[0],
                ios_minor_version: iOSVersion[1],
                userAgent: shortUserAgent,
            });
        }
        setServiceWorkerInstalled();
        sessionStorageSetItem(hungServiceWorkerSessionKey, "dont-report");
        return;
    }

    let didLogAgain = false;
    function setServiceWorkerInstalledLogIfDoAgain() {
        sessionStorageRemoveItem(hungServiceWorkerSessionKey);
        if (wasHungFromReload === "do-again" && !didLogAgain) {
            didLogAgain = true;
            logError("recovered from stuck service worker installation");
            frontendSendEvent("recovered from stuck service worker installation", 0, {
                browser_might_be_on_ios: browserMightBeOniOS,
                ios_major_version: iOSVersion[0],
                ios_minor_version: iOSVersion[1],
                userAgent: shortUserAgent,
            });
        }
        return setServiceWorkerInstalled();
    }

    function setupServiceWorkerController(controller: ServiceWorker | null) {
        if (controller === null) {
            return setServiceWorkerInstalled();
        }
        controller.addEventListener("statechange", () => {
            if (controller.state === "activated") {
                setupServiceWorkerListeners(controller);
                return setServiceWorkerInstalledLogIfDoAgain();
            }
        });
        controller.addEventListener("error", () => {
            if (controller.state === "installed" || controller.state === "activated") return;
            // We can't log a recovery if installing the Service Worker resulted in an error.
            return setServiceWorkerInstalled();
        });

        if (controller.state === "activating") {
            setTimeout(async () => {
                if (navigator.serviceWorker.controller !== controller) return;

                // This keeps being a problem on Safari. What we know works is
                // forcibly reloading the page when the Service Worker gets stuck.
                //
                // We're trying extremely hard to _not_ get stuck but we can't seem
                // to catch everything.
                if (navigator.serviceWorker.controller?.state === "activating") {
                    logError("Service Worker got stuck activating");
                    await withTimeout(
                        removeAllRegistrations(navigator.serviceWorker),
                        undefined,
                        undefined,
                        serviceWorkerClearRegistrationTimeout
                    );
                    // We have to do this _after_ we unregister the Service Worker,
                    // or else the request to Honeycomb will get swallowed.
                    frontendSendEvent("stuck service worker", 0, {
                        standalone,
                        browser_might_be_on_ios: browserMightBeOniOS,
                        ios_major_version: iOSVersion[0],
                        ios_minor_version: iOSVersion[1],
                        userAgent: shortUserAgent,
                    });

                    // Safari continues to give us Service Worker heartburn.
                    // 16.3 can get stuck in a permanent activation loop, so
                    // we abandon the idea of starting a service worker if activation
                    // gets stuck twice.
                    sessionStorageSetItem(
                        hungServiceWorkerSessionKey,
                        wasHungFromReload === undefined ? "do-again" : "dont-report"
                    );
                    // If we reload too quickly the outstanding request might get dropped.
                    await sleep(1_000);
                    reloadBrowserWindow("Service Worker got stuck activating");
                }
            }, serviceWorkerRegistrationTimeout);
        }
        if (controller.state === "activated") {
            setupServiceWorkerListeners(controller);
            return setServiceWorkerInstalledLogIfDoAgain();
        }
    }

    if (navigator.serviceWorker.controller !== null) {
        const setupStartTime = getCurrentTimestampInMilliseconds();
        setupServiceWorkerController(navigator.serviceWorker.controller);
        void waitForInstalledServiceWorker().then(() =>
            frontendSendEvent(
                "serviceWorker existing registration",
                getCurrentTimestampInMilliseconds() - setupStartTime,
                {}
            )
        );
    }

    const origController = navigator.serviceWorker.controller;

    const controllerChangeTime = getCurrentTimestampInMilliseconds();
    navigator.serviceWorker.addEventListener("controllerchange", () => {
        setupServiceWorkerController(navigator.serviceWorker.controller);
        void waitForInstalledServiceWorker().then(() =>
            frontendSendEvent(
                "serviceWorker controller changed",
                getCurrentTimestampInMilliseconds() - controllerChangeTime,
                {}
            )
        );
    });

    const registerStartTime = getCurrentTimestampInMilliseconds();

    logInfo("Registering service worker", swUrl);
    void blockingNetworkActivity(async unblockNetworkEarly => {
        const hungInstalledTimeout = setTimeout(async () => {
            const newController = navigator.serviceWorker.controller;
            // This is an intentional object identity comparison; the idea is that
            // if we _actually_ set up a new Service Worker, it _should_ result in
            // a new Controller object.

            // Safari 16.x on macOS and iOS has grown a bug where the service worker refuses to initialize
            // the very first time you try to use it. This results in extremely bad refresh behavior.
            // When you reload, the serviceWorker _is_ there as the origController.
            if (newController !== origController || browserIsSafari) {
                if (
                    newController?.state === "installed" ||
                    newController?.state === "activating" ||
                    newController?.state === "activated"
                ) {
                    logInfo("Service worker changed without browser report");
                    // Safari 16.0 hangs in .register(), .ready(), and .unregister()
                    // when switching service workers. We may still be able to recover
                    // from this, even though Safari is really not supposed to hang here.
                    setupServiceWorkerController(newController);
                    void waitForInstalledServiceWorker().then(() => {
                        unblockNetworkEarly();
                        frontendSendEvent(
                            "serviceWorker controller changed without notification",
                            getCurrentTimestampInMilliseconds() - controllerChangeTime,
                            {}
                        );
                    });
                    return;
                } else {
                    logError(
                        "Service worker changed without browser report, still in state",
                        newController?.state,
                        origController?.state
                    );
                }
            }
            if (getFeatureFlag("bypassPermanentServiceWorkerHangReset")) {
                logError(
                    "Service worker likely hung permanently, but not reloading due to bypassPermanentServiceWorkerHangReset"
                );
                return;
            }
            sessionStorageSetItem(
                hungServiceWorkerSessionKey,
                wasHungFromReload === "do-again" ? "do-report" : "do-again"
            );
            if (!browserIsSafari || wasHungFromReload === "do-again") {
                await withTimeout(
                    removeAllRegistrations(navigator.serviceWorker),
                    undefined,
                    undefined,
                    serviceWorkerClearRegistrationTimeout
                );
            }
            logError("Service worker installation hung permanently");
            reloadBrowserWindow("Service worker installation hung permanently");
        }, permanentHangTimeout);

        return await navigator.serviceWorker
            .register(swUrl, { updateViaCache: window.location.hostname === "localhost" ? "none" : "imports" })
            .then(registration => {
                logInfo("Service worker registration completed");
                if (registration.installing === null) {
                    logInfo("Service worker installing state is null");
                    clearTimeout(hungInstalledTimeout);
                    setupServiceWorkerController(registration.active);
                    void waitForInstalledServiceWorker().then(() => {
                        logInfo("Service worker was installed");
                        frontendSendEvent(
                            "serviceWorker installing",
                            getCurrentTimestampInMilliseconds() - registerStartTime,
                            {}
                        );
                        if (registration.active !== null) {
                            tryPostMessage(registration.active, makeServiceWorkerInstallTimingRequest());
                        }
                    });
                } else {
                    logInfo("Service worker is still installing");
                }
                const updateStartTime = getCurrentTimestampInMilliseconds();
                registration.onupdatefound = () => {
                    logInfo("Found Service Worker update");
                    clearTimeout(hungInstalledTimeout);
                    setupServiceWorkerController(registration.installing);

                    // By default, waitForInstalledServiceWorker is expected to happen when the above
                    // controllerchange event fires. However, we suspect that Safari is failing to fire the
                    // above event in a timely manner, so we'll set up a polling mechanism that aggressively
                    // checks whether the installation happened.
                    let controllerChangeViaPoll = false;
                    let controllerUpdateInterval: NodeJS.Timeout;
                    // eslint-disable-next-line prefer-const
                    controllerUpdateInterval = setInterval(() => {
                        if (serviceWorkerEverInstalled) return clearInterval(controllerUpdateInterval);
                        if (registration.installing !== null) return;
                        if (registration.active === null) return;
                        const { state } = registration.active;
                        if (state !== "installed" && state !== "activating" && state !== "activated") return;
                        setServiceWorkerInstalled();
                        controllerChangeViaPoll = true;
                        clearInterval(controllerUpdateInterval);
                    }, 250);

                    void waitForInstalledServiceWorker().then(() => {
                        logInfo("Service worker was updated");
                        clearInterval(controllerUpdateInterval);
                        frontendSendEvent(
                            "serviceWorker updating",
                            getCurrentTimestampInMilliseconds() - updateStartTime,
                            { controllerChangeViaPoll }
                        );
                        if (registration.active !== null) {
                            tryPostMessage(registration.active, makeServiceWorkerInstallTimingRequest());
                        }
                    });
                };
            })
            .catch(error => {
                logError("Error during service worker registration:", error);
                clearTimeout(hungInstalledTimeout);
                setServiceWorkerInstalled();
            });
    });
}
