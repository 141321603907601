import type { AppUserData, DataRowStore } from "@glide/common-core/dist/js/components/datastore/data-row-store";
import {
    type ComputationModel,
    type GroundValue,
    type LoadedGroundValue,
    type LoadingValue,
    type ResolvedGroundValue,
    type Row,
    type WritableValue,
    isLoadingValue,
    makeLoadingValue,
    MutableTable,
    Table,
    type TableKeeper,
    type TableKeeperStore,
    isLiveUpdateQuery,
    isRowVersionCapableQuery,
    makeLoadingValueWithDisplayValue,
    mapLoadingValue,
    unwrapLoadingValue,
    type Handler,
    type BaseRowIndex,
    type QueryTableVersions,
    type RowIndex,
    isBaseRowIndex,
    type SerializedQuery,
    Query,
} from "@glide/computation-model-types";
import {
    type ActionAppEnvironment,
    type ActionAppFacilities,
    type ActionOperationsState,
    type ActionOutstandingOperationsHandler,
    type AddRowToTableResult,
    type DataStoreMutationOptions,
    type GetOverrideRowID,
    type MutationResult,
    type OnQuerySaveHandler,
    type QueryableDataStore,
    type QueryableRowsRootFinder,
    emptyActionOperationsState,
} from "@glide/common-core/dist/js/components/types";
import { isTable } from "@glide/common-core/dist/js/computation-model/data";
import { extractActionValues } from "@glide/common-core/dist/js/computation-model/row-data";
import {
    getNativeTableRowIDForRowIndex,
    getRowIDForRowIndex,
    getRowIndexForRow,
} from "@glide/common-core/dist/js/computation-model/row-index";
import { decomposeRowIndex } from "@glide/common-core/dist/js/Database";
import {
    type TableName,
    areTableNamesEqual,
    makeTableNameForNativeTable,
    nativeTableRowIDColumnName,
    rowIndexColumnName,
    type TableGlideType,
    type TypeSchema,
    findTable,
    getEmailOwnersColumnNames,
    getTableColumn,
    isComputedColumn,
    isPrimitiveType,
    isTableWritable,
    makeTableName,
    makeTypeSchema,
    sheetNameForTable,
    tableGlideTypeCodecTableToTableGlideType,
    type NativeTableID,
    getTableName,
    isGlideTableInGBTDataStore,
    makeNativeTableID,
    nativeTableRowDeletedColumnName,
} from "@glide/type-schema";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import {
    type BuilderQueryInfo,
    type ContinueQueryRequestBody,
    type ExecuteQueryRequestBody,
    type ExecuteQueryResponseBody,
    type MinRequiredVersion,
    type NativeTableQueryID,
    type QueryContinuation,
    type QueryResponseEntry,
    type SaveQueryRequestBody,
    type SQLQueryBase,
    type WriteSourceType,
    executeQueryResponseBodyCodec,
} from "@glide/common-core/dist/js/firebase-function-types";
import { findTableNameForQuery, getQueryIDForTable } from "@glide/common-core/dist/js/queryable-table";
import { ShouldAgnostifyDateTimes } from "@glide/common-core/dist/js/schema-properties";
import { decodeTuple64Strings, encodeTuple64Strings } from "@glide/common-core/dist/js/support/tuple64";
import { type ColumnCellValues, convertSerializableValueToCellValue } from "@glide/data-types";
import { AppKind } from "@glide/location-common";
import {
    type ChangeObservable,
    type JSONObject,
    ArrayMap,
    CombinedChangeObservable,
    concatIterableIterators,
    ConditionVariable,
    DefaultArrayMap,
    getResponseErrorMessage,
    ignore,
    isResponseOK,
    iterableFilter,
    iterableTake,
    logError,
    logInfo,
    nativeTableIndexer,
    RecurrentBackgroundJob,
    SyncJobQueue,
    Watchable,
} from "@glide/support";
import {
    type ActionManagerAcessors,
    type ActionPoster,
    ActionManager,
    adaptValuesForWriting,
    BatchingActionPoster,
    debounceDataEditorSetColumnsTimeout,
    debounceSetColumnsTimeout,
    MultiplexingActionPoster,
    SimpleActionPoster,
} from "@glide/post-action";
import {
    applySort,
    areConditionsTrueForRow,
    areSerializedQueriesEquivalent,
    doesQueryReferToColumns,
    evaluateQueryForRows,
    getSerializedQueryDistance,
} from "@glide/query-conditions";
import {
    assert,
    assertNever,
    DefaultMap,
    defined,
    definedMap,
    exceptionToString,
    filterUndefined,
    hasOwnProperty,
    mapFilterUndefined,
    panic,
    sleep,
} from "@glideapps/ts-necessities";
import { continuePreviewQueryDataSource, previewQueryDataSource, saveQuery } from "@glide/backend-api";
import { setUnionInto } from "collection-utils";
import pLimit from "p-limit";
import { ComputationModelDataRowStoreBase } from "./data-row-store";
import { isRowOwnedByUser } from "./is-row-owned";
import { RowChangeOverlayer } from "./row-change-overlayer";
import { getDeviceID } from "@glide/common-core/dist/js/device-id";
import { getColumnsAffectedByDataColumns } from "@glide/generator/dist/js/computed-columns";
import { makeSimpleSchemaInspector } from "@glide/generator/dist/js/components/simple-ccc";
import { getColumnsUsedByQuery } from "./columns-used-by-query";
import {
    type AggregateQueryAggregatorHelper,
    AggregateQueryAggregator,
    makeQueryRequest,
} from "./aggregate-query-aggregator";
import { getLocalizedString } from "@glide/localization";
import {
    type ActiveAggregationQuery,
    type ActiveQuery,
    type ActiveQueryBase,
    type QueryRunnerCallbacks,
    QueryState,
    setTableVersionsForQuery,
} from "./query/active-query";
import type {
    BuildQueryRefresher,
    QueryRefresher,
    RunningQueryFreshener,
    StartQueryFreshener,
} from "./query/query-refresher";
import { TestLocalDataStoreImpl } from "./local-datastore";
import type { SimpleTableKeeper } from "./simple-table-keeper";
import deepEqual from "deep-equal";

const queryTablePrefix = "query-";
const previewQueryTablePrefix = queryTablePrefix + "preview";
const previewQueryNewPrefix = previewQueryTablePrefix + "-new-";
const previewQueryExistingPrefix = previewQueryTablePrefix + "-existing-";
const continuationWaitTime = 333; // Up to 3 calls per second, likely less with network latency.
export const existingQueryExecutionDelay = 100;
const queryBatchSize = 10; // FIXME: See if this can go higher
const previewQueryLimit = 1000;
// FIXME: Longer?
const defaultContinuationExpiration = 60_000; // 1 minute

export const glideTableInGBTRowLimit = 25_000;

export function previewQueryTableNameForNew(sourceKind: string, sourceID: string): TableName {
    const savedName = `${previewQueryNewPrefix}${encodeTuple64Strings([sourceKind, sourceID])}`;
    return {
        name: savedName,
        isSpecial: true,
    };
}

function sourceKindIDFromPreviewQueryTableNameForNew(
    tableName: TableName
): { sourceID: string; sourceKind: string } | undefined {
    if (!tableName.name.startsWith(previewQueryNewPrefix)) return undefined;
    const t64Strings = tableName.name.substring(previewQueryNewPrefix.length);
    try {
        const decoded = decodeTuple64Strings(t64Strings);
        if (decoded.length !== 2) return undefined;
        return { sourceKind: decoded[0], sourceID: decoded[1] };
    } catch {
        return undefined;
    }
}

export function previewQueryTableNameForExisting(queryID: string): TableName {
    return {
        name: `${previewQueryExistingPrefix}${queryID}`,
        isSpecial: true,
    };
}

function queryIDFromPreviewQueryTableNameForExisting(tableName: TableName): NativeTableID | undefined {
    if (!tableName.name.startsWith(previewQueryExistingPrefix)) return undefined;
    return makeNativeTableID(tableName.name.substring(previewQueryExistingPrefix.length));
}

function getRowIndex(rowData: JSONObject): BaseRowIndex | undefined {
    const maybeRowIndex = rowData[rowIndexColumnName];
    if (isBaseRowIndex(maybeRowIndex)) {
        return maybeRowIndex;
    } else {
        return undefined;
    }
}

function getRowDeleted(rowData: JSONObject): boolean | undefined {
    const maybeRowDeleted = rowData[nativeTableRowDeletedColumnName];
    if (typeof maybeRowDeleted === "boolean") return maybeRowDeleted;
    return undefined;
}

// exported for testing
export function makeRowIDForRow(
    table: TableGlideType | undefined,
    row: object,
    appFacilities: ActionAppFacilities
): string {
    const rowIDColumn = table?.rowIDColumn;
    if (rowIDColumn !== undefined && hasOwnProperty(row, rowIDColumn)) {
        const v = row[rowIDColumn];
        if (typeof v === "string") {
            return v;
        } else if (typeof v === "number") {
            return v.toString();
        }
    }
    return appFacilities.makeRowID();
}

// exported for testing
export function makeRowFromRowData(
    table: TableGlideType | undefined,
    rowID: string,
    rowData: JSONObject,
    rowIndexFallback: BaseRowIndex
): Row {
    const rowIndex = getRowIndex(rowData) ?? rowIndexFallback;

    const cells: ColumnCellValues = {};
    // Most internals of Glide are only prepared to handle `undefined`, not `null`.
    // But SQL data sources only have `null`, not `undefined`. Instead of imposing
    // a JavaScript conversion requirement on these sources, we'll fix this
    // discrepancy here.
    if (table !== undefined) {
        for (const c of table.columns) {
            const value = rowData[c.name] ?? undefined;
            const cellValue = convertSerializableValueToCellValue(
                {
                    flattenArrays: false,
                    semiStrictConvertSerializableValue: getFeatureSetting("semiStrictConvertSerializableValue"),
                },
                value
            );
            if (cellValue === undefined) continue;
            cells[c.name] = cellValue;
        }
    } else {
        for (const [k, v] of Object.entries(rowData)) {
            const value = v ?? undefined;
            const cellValue = convertSerializableValueToCellValue(
                {
                    flattenArrays: false,
                    semiStrictConvertSerializableValue: getFeatureSetting("semiStrictConvertSerializableValue"),
                },
                value
            );
            if (cellValue === undefined) continue;
            cells[k] = cellValue;
        }
    }

    return {
        ...cells,
        [nativeTableRowIDColumnName]: rowID,
        $isVisible: true,
        [rowIndexColumnName]: rowIndex,
        [nativeTableRowDeletedColumnName]: getRowDeleted(rowData),
    };
}

// FIXME: Do we ever have to unset `this.dataIsLoading`?
// FIXME: Get rid of this class and merge it into `PreviewQueryRowDataStoreBase`
abstract class QueryRowDataStoreBase<TQuery extends { queryName: string }, TBefore = any>
    extends ComputationModelDataRowStoreBase
    implements DataRowStore, Handler, TableKeeper
{
    private _currentTable: TableGlideType | undefined;
    protected _needsAnotherFetch: boolean = true;
    protected _currentQuery: TQuery | undefined;

    private readonly _fallbackTable: TableGlideType;

    constructor(
        protected readonly _appEnvironment: ActionAppEnvironment,
        protected readonly _tableName: TableName,
        displayName: string,
        private readonly updateTable: (newTable: TableGlideType) => void,
        protected readonly _queryRunning: Watchable<boolean>,
        // For debugging only
        private readonly _forBuilder: boolean
    ) {
        super(false, false);
        _queryRunning.current = false;
        this._fallbackTable = {
            name: _tableName,
            sheetName: displayName,
            columns: [],
            isReadOnly: true,
            emailOwnersColumn: undefined,
        };

        ignore(this._forBuilder);
    }

    public get symbolicRepresentation(): string {
        return `query-row-data-store ${this._tableName.name}`;
    }

    public readonly errorMessage = new Watchable<string | undefined>(undefined);
    public readonly warningMessage = new Watchable<string | undefined>(undefined);

    public getTable(): TableGlideType {
        if (this._currentTable === undefined) return this._fallbackTable;
        return this._currentTable;
    }

    protected getAppUserData(): AppUserData | undefined {
        return undefined;
    }

    protected setErrorMessage(message: string | undefined) {
        this.errorMessage.current = message;
    }

    protected setWarningMessage(message: string | undefined) {
        this.warningMessage.current = message;
    }

    public clear(): void {
        return panic("We shouldn't be cleared");
    }

    protected installNewTable(newTable: TableGlideType) {
        this._currentTable = newTable;
        this.updateTable(this._currentTable);
    }

    protected abstract runFirstQuery(
        queryID: string,
        querySpecs: TQuery,
        signal: AbortSignal
    ): Promise<QueryResponseEntry | undefined>;

    protected abstract runContinuationQuery(
        queryID: string,
        querySpecs: TQuery,
        continuation: unknown,
        signal: AbortSignal
    ): Promise<readonly QueryResponseEntry[] | undefined>;

    protected abstract onQuerySuccess(cookie: TBefore): void;
    protected abstract onQueryEnd(): void;

    // This will not set `_needsAnotherFetch`
    protected clearAll(): void {
        super.clear();
        this.tableData.clear();
        this.pushDirtForTable();
    }

    protected abstract fetchData(): void;
    public abstract abortOngoingQuery(): void;
    public abstract runQuery(subQuery: SerializedQuery, callback: () => void): Table | LoadingValue | undefined;
}

interface PreviewQuery {
    queryName: string;
    queryString: string;
    onBehalfOfUser: string | undefined;
}

abstract class PreviewQueryRowDataStoreBase extends QueryRowDataStoreBase<PreviewQuery, number> {
    private editSerial: number = 0;
    private savedSerial: number = -1;
    private runSerial: number = -1;
    protected setRemoteSaveSerial?(remoteSaveSerial: number): void;

    private _ongoingQueryAbortController: AbortController | undefined;
    private _queryQueue = new SyncJobQueue();

    constructor(
        appEnvironment: ActionAppEnvironment,
        tableName: TableName,
        public readonly appOwner: string | undefined,
        updatePreviewTable: (newPreviewTable: TableGlideType) => void,
        queryRunning: Watchable<boolean>,
        private readonly _continuationWaitTime: number
    ) {
        super(appEnvironment, tableName, "Preview Query", updatePreviewTable, queryRunning, true);
    }

    public currentQuerySaved(): boolean {
        return this.savedSerial === this.editSerial;
    }

    public canSaveCurrentQuery(): boolean {
        return this.runSerial === this.editSerial;
    }

    public canSaveCurrentQueryObservable = new Watchable<boolean>(false);

    public setQueryText(
        queryName: string | undefined,
        queryString: string | undefined,
        remoteSerial: number | undefined
    ): void {
        if (queryString === undefined) {
            this._currentQuery = undefined;
            this.editSerial++;
        } else if (queryName !== this._currentQuery?.queryName || queryString !== this._currentQuery?.queryString) {
            this._currentQuery = {
                onBehalfOfUser: this.appOwner,
                queryName: queryName ?? "Untitled Query",
                queryString,
            };
            this.editSerial++;
            if (remoteSerial !== undefined) {
                this.setRemoteSaveSerial?.(remoteSerial);
            }
        }
        this.canSaveCurrentQueryObservable.current = this.canSaveCurrentQuery();
    }

    public clearQuery() {
        this._currentQuery = undefined;
        this.editSerial++;
        if (this._queryRunning.current === true) return;

        this.clearAll();
        this.installNewTable({ name: this._tableName, columns: [], emailOwnersColumn: undefined });
        this.canSaveCurrentQueryObservable.current = this.canSaveCurrentQuery();
    }

    protected onQuerySuccess(cookie: number): void {
        this.runSerial = Math.max(this.runSerial, cookie);
        this.canSaveCurrentQueryObservable.current = this.canSaveCurrentQuery();
    }

    protected onQueryEnd(): void {
        if (this._currentQuery === undefined) {
            this.clearAll();
            this.installNewTable({ name: this._tableName, columns: [], emailOwnersColumn: undefined });
            this.canSaveCurrentQueryObservable.current = this.canSaveCurrentQuery();
        }
    }

    protected abstract getQuerySaveID(): SaveQueryRequestBody["savedIdentity"];

    private async saveQueryInternal(
        current: typeof this._currentQuery,
        editSerial: number,
        resultingTable: TableGlideType,
        onSaveHandler: OnQuerySaveHandler | undefined
    ): Promise<TableGlideType | undefined> {
        if (current === undefined) return undefined;
        if (this.appOwner === undefined) return undefined;
        const { queryName, queryString } = current;

        this.setErrorMessage(undefined);
        this.setWarningMessage(undefined);

        try {
            const resp = await saveQuery(
                this.getQuerySaveID(),
                this._appEnvironment.appID,
                this.appOwner,
                queryName,
                { kind: "query", query: queryString },
                resultingTable,
                this._appEnvironment.appFacilities
            );
            if (typeof resp === "string") {
                this.setErrorMessage(resp);
                return undefined;
            }

            const {
                savedIdentity: { currentSerial: remoteSerial, nativeTableID },
                requestWasSaved,
            } = resp;
            this.savedSerial = editSerial;
            this.setRemoteSaveSerial?.(remoteSerial);
            this.canSaveCurrentQueryObservable.current = this.canSaveCurrentQuery();
            const resultTable = {
                ...resultingTable,
                name: makeTableNameForNativeTable(nativeTableID),
            };
            if (requestWasSaved) {
                await onSaveHandler?.(resultTable, nativeTableID, remoteSerial);
                return resultTable;
            } else {
                this.setErrorMessage("Query was not saved due to contents conflict");
                return undefined;
            }
        } catch (e: unknown) {
            logError("Exception when trying to save query", e);
            this.setErrorMessage(`Encountered error when trying to save query: ${exceptionToString(e)}`);
            return undefined;
        }
    }

    private saveQueue = new SyncJobQueue();
    public isSaving = new Watchable<boolean>(false);

    public async saveCurrentQuery(
        getOverrideRowID: GetOverrideRowID,
        handler: OnQuerySaveHandler | undefined
    ): Promise<TableGlideType | undefined> {
        if (!this.canSaveCurrentQuery()) return;

        const current = this._currentQuery;
        const { editSerial } = this;

        let resultingTable = this.getTable();

        const rowIDOverride = await getOverrideRowID(resultingTable);
        if (rowIDOverride === undefined) return undefined;

        if (rowIDOverride !== resultingTable.rowIDColumn) {
            const column = getTableColumn(resultingTable, rowIDOverride);
            assert(column !== undefined && isPrimitiveType(column.type));
            resultingTable = {
                ...resultingTable,
                rowIDColumn: rowIDOverride,
            };
            this.installNewTable(resultingTable);
        }

        if (resultingTable.rowIDColumn === undefined) {
            return;
        }

        const savePromise = this.saveQueue.run(() =>
            this.saveQueryInternal(current, editSerial, resultingTable, handler)
        );
        this.isSaving.current = this.saveQueue.hasOutstandingWork();
        try {
            return await savePromise;
        } finally {
            this.isSaving.current = this.saveQueue.hasOutstandingWork();
        }
    }

    protected async runFirstQuery(
        queryID: string,
        querySpecs: PreviewQuery,
        _signal: AbortSignal
    ): Promise<QueryResponseEntry | undefined> {
        // FIXME: Actually use the AbortSignal here.

        const { onBehalfOfUser: onBehalfOf, queryName, queryString } = querySpecs;
        const savedIdentity = this.getQuerySaveID();

        const resp = await previewQueryDataSource(
            savedIdentity,
            onBehalfOf,
            queryName,
            queryID,
            { kind: "query", query: queryString },
            previewQueryLimit,
            this._appEnvironment.appFacilities
        );
        if (typeof resp === "string") {
            this.setErrorMessage(resp);
            return undefined;
        }
        return resp;
    }

    protected async runContinuationQuery(
        queryID: string,
        querySpecs: PreviewQuery,
        continuation: QueryContinuation,
        _signal: AbortSignal
    ): Promise<readonly QueryResponseEntry[] | undefined> {
        // FIXME: Actually use the AbortSignal here.

        const { onBehalfOfUser: onBehalfOf } = querySpecs;
        const savedIdentity = this.getQuerySaveID();

        const result = await continuePreviewQueryDataSource(
            this._appEnvironment.appFacilities,
            savedIdentity,
            onBehalfOf,
            queryID,
            continuation
        );
        if (typeof result === "string") {
            this.setWarningMessage(result);
            return undefined;
        }

        return result;
    }

    private async runQueryInQueueInternal(callbacks: QueryRunnerCallbacks): Promise<void> {
        let numRowsAdded = 0;
        function addMaybeRows(maybeRows: readonly unknown[]) {
            const rows: JSONObject[] = [];
            for (const row of maybeRows) {
                if (row === null || typeof row !== "object") continue;
                rows.push(row as JSONObject);
            }
            if (rows.length === 0) return false;
            callbacks.addRows(rows);
            numRowsAdded += rows.length;
            return true;
        }

        const cookie = this.editSerial;
        const firstResponse = await callbacks.runFirstQuery();
        if (firstResponse === undefined) return;

        if (firstResponse.kind === "error") {
            logError("First query attempt error:", firstResponse);
            this.setErrorMessage(firstResponse.message);
            return;
        }

        let currentContinuation = firstResponse.continuation;
        let needsClearing = true;
        if (firstResponse.rows.length > 0 || currentContinuation === undefined) {
            this.clearAll();
            needsClearing = false;
            callbacks.installNewTable(firstResponse.table);
        }
        if (addMaybeRows(firstResponse.rows)) {
            this.pushDirtForTable();
        }
        let loggedError = false;
        while (currentContinuation !== undefined && numRowsAdded < 1000) {
            // We don't need to saturate the network just running continuations.
            await sleep(this._continuationWaitTime);
            const startContinuation = currentContinuation;
            const nextResponse = await callbacks.runContinuationQuery(startContinuation);
            if (nextResponse === undefined) break;

            const queryResult = nextResponse.find(r => r.queryID === callbacks.subQueryID);
            if (queryResult === undefined) {
                logError("Continuation attempt didn't return the right query ID", nextResponse);
                this.setErrorMessage("Invalid response from backend");
                return;
            }

            if (queryResult.kind === "error") {
                logError("Continuation attempt error:", queryResult);
                if (!loggedError) {
                    this.setErrorMessage(queryResult.message);
                    loggedError = true;
                }
                if (currentContinuation === startContinuation) {
                    currentContinuation = undefined;
                }
            } else {
                currentContinuation = queryResult.continuation;
                if (needsClearing) {
                    this.clearAll();
                    needsClearing = false;
                }
                callbacks.installNewTable(queryResult.table);
                if (addMaybeRows(queryResult.rows)) {
                    this.pushDirtForTable();
                }
            }
        }
        if (!loggedError && cookie !== undefined) {
            this.onQuerySuccess(cookie);
        }
    }

    private async runQueryInQueue(query: PreviewQuery, signal: AbortSignal): Promise<void> {
        try {
            this.setErrorMessage(undefined);
            this.setWarningMessage(undefined);
            this._needsAnotherFetch = false;
            this._queryRunning.current = true;

            const subQueryID = this._appEnvironment.appFacilities.makeUUID();

            let rowIndex = nativeTableIndexer.zero;
            await this.runQueryInQueueInternal({
                subQueryID,
                addRows: rows => {
                    const table = this.getTable();
                    for (const rowData of rows ?? []) {
                        const rowID = makeRowIDForRow(table, rowData, this._appEnvironment.appFacilities);
                        this.addRow(makeRowFromRowData(table, rowID, rowData, rowIndex));
                        rowIndex = nativeTableIndexer.nextNumber(rowIndex);
                    }
                    return true;
                },
                installNewTable: t =>
                    this.installNewTable({
                        emailOwnersColumn: undefined,
                        ...t,
                        name: this._tableName,
                        sheetName: query.queryName,
                    }),
                runFirstQuery: async () => {
                    const response = await this.runFirstQuery(subQueryID, query, signal);
                    if (signal.aborted) return undefined;
                    return response;
                },
                runContinuationQuery: cont => this.runContinuationQuery(subQueryID, query, cont, signal),
            });
        } finally {
            this.setDirty();
            this._queryRunning.current = false;
            this.onQueryEnd();
        }
    }

    protected fetchData(): void {
        if (!this._needsAnotherFetch || this._currentQuery === undefined) return;
        this.abortOngoingQuery();
        const controller = new AbortController();
        this._ongoingQueryAbortController = controller;
        const query = this._currentQuery;

        void this._queryQueue.run(() => this.runQueryInQueue(query, controller.signal));
    }

    public abortOngoingQuery(): void {
        if (this._ongoingQueryAbortController !== undefined) {
            this._ongoingQueryAbortController.abort();
        }
    }

    public runQuery() {
        this._needsAnotherFetch = true;
        this.fetchData();
        return undefined;
    }
}

// Exported for testing
export class PreviewNewQueryRowDataStore extends PreviewQueryRowDataStoreBase {
    constructor(
        appEnvironment: ActionAppEnvironment,
        private readonly _sourceKind: string,
        private readonly _sourceID: string,
        onBehalfOf: string | undefined,
        updatePreviewTable: (newPreviewTable: TableGlideType) => void,
        queryRunning: Watchable<boolean>,
        waitTime: number = continuationWaitTime
    ) {
        super(
            appEnvironment,
            previewQueryTableNameForNew(_sourceKind, _sourceID),
            onBehalfOf,
            updatePreviewTable,
            queryRunning,
            waitTime
        );
    }

    protected getQuerySaveID() {
        return {
            kind: "new" as const,
            sourceKind: this._sourceKind,
            sourceID: this._sourceID,
        };
    }
}

class PreviewExistingQueryRowDataStore extends PreviewQueryRowDataStoreBase {
    private _remoteSerial: number | undefined;
    private readonly _queryID: NativeTableQueryID;

    constructor(
        appEnvironment: ActionAppEnvironment,
        queryID: string,
        onBehalfOf: string | undefined,
        updatePreviewTable: (newPreviewTable: TableGlideType) => void,
        queryRunning: Watchable<boolean>
    ) {
        super(
            appEnvironment,
            previewQueryTableNameForExisting(queryID),
            onBehalfOf,
            updatePreviewTable,
            queryRunning,
            continuationWaitTime
        );
        this._queryID = {
            kind: "native-table",
            value: makeNativeTableID(queryID),
        };
    }

    protected getQuerySaveID() {
        return {
            kind: "existing" as const,
            id: this._queryID,
            lastSerial: this._remoteSerial ?? -1,
        };
    }

    protected setRemoteSaveSerial(remoteSerial: number) {
        this._remoteSerial = remoteSerial;
    }
}

function runAndClearCallbacks(q: ActiveQueryBase): void {
    // just to be super safe
    const callbacks = Array.from(q.callbacks);
    q.callbacks.clear();

    for (const cb of callbacks) {
        try {
            cb();
        } catch (e: unknown) {
            logError("Error calling query callback", e);
        }
    }
}

function unlimitSerializedQuery(serializedQuery: SerializedQuery): SerializedQuery {
    const result = { ...serializedQuery };
    delete result["limit"];
    return result;
}

function getRowsToAdd(responseRows: readonly unknown[]): readonly JSONObject[] {
    return responseRows.filter(r => r !== null && typeof r === "object") as JSONObject[];
}

// Adding this many rows since the last GC will trigger another one.
const numRowsToTriggerGC = 5000;

export interface ExistingQueryRowDataStoreCallbacks {
    readonly isBuilder: boolean;

    setNumberOfRowsInTable(tableName: TableName, numRows: number): void;
    makeRowIndexFallback(): string;
}

export interface QueryableBackendCaller {
    requestExecuteQuery(
        appEnv: ActionAppEnvironment,
        savedID: NativeTableQueryID,
        builderInfo: true | BuilderQueryInfo | undefined,
        requests: ExecuteQueryRequestBody["requests"]
    ): Promise<ExecuteQueryResponseBody | string>;
    requestContinueQuery(
        appEnv: ActionAppEnvironment,
        savedID: NativeTableQueryID,
        builderInfo: true | BuilderQueryInfo | undefined,
        requests: ContinueQueryRequestBody["requests"]
    ): Promise<ExecuteQueryResponseBody | string>;
}

// exported only for testing
export class QueryableBackendCallerImpl implements QueryableBackendCaller {
    // We limit the number of concurrent `executeQuery` requests on the
    // frontend.  If we ever run into allocating too many promises here, or if
    // we want to abort queries, we will have to the
    // `ConcurrencyLimitedWithBackpressure`.
    private readonly limit = pLimit(5);

    constructor(private readonly deviceID: string) {}

    private async parseResponse(response: Response | undefined): Promise<ExecuteQueryResponseBody | string> {
        if (response === undefined) return "Could not contact the servers to run the query.";

        let message: string | undefined;
        try {
            const responseBody = await response.json();
            if (executeQueryResponseBodyCodec.is(responseBody)) {
                return responseBody;
            }

            if (isResponseOK(response)) {
                message = "Unexpected response from the server";
            } else {
                message = await getResponseErrorMessage(response);
            }
        } catch {
            // nothing to do
        }

        if (message === undefined) {
            message = "Unknown error executing query";
        }
        logError("Error in response", message);
        return message;
    }

    private async executeQuery(
        appFacilities: ActionAppFacilities,
        body: ExecuteQueryRequestBody
    ): Promise<ExecuteQueryResponseBody | string> {
        const response = await appFacilities.callAuthIfAvailableCloudFunction("executeQuery", body, {}, true);
        return this.parseResponse(response);
    }

    public async requestExecuteQuery(
        appEnv: ActionAppEnvironment,
        savedID: NativeTableQueryID,
        builderInfo: true | BuilderQueryInfo | undefined,
        requests: ExecuteQueryRequestBody["requests"]
    ): Promise<ExecuteQueryResponseBody | string> {
        const { appFacilities, appID } = appEnv;
        const body: ExecuteQueryRequestBody = { appID, savedID, requests, builderInfo, deviceID: this.deviceID };

        return await this.limit(() => this.executeQuery(appFacilities, body));
    }

    public async requestContinueQuery(
        appEnv: ActionAppEnvironment,
        savedID: NativeTableQueryID,
        builderInfo: true | BuilderQueryInfo | undefined,
        requests: ContinueQueryRequestBody["requests"]
    ): Promise<ExecuteQueryResponseBody | string> {
        const { appFacilities, appID } = appEnv;

        const body: ContinueQueryRequestBody = {
            appID,
            savedID,
            requests,
            builderInfo,
            deviceID: this.deviceID,
        };
        const response = await appFacilities.callAuthIfAvailableCloudFunction("continueQuery", body, {}, true);
        return this.parseResponse(response);
    }
}

export class ExistingQueryRowDataStore
    extends ComputationModelDataRowStoreBase
    implements DataRowStore, Handler, TableKeeper, QueryRefresher
{
    // private _queryQueue = new SyncJobQueue();
    private readonly _activeQueries = new ArrayMap<SerializedQuery, ActiveQuery>(areSerializedQueriesEquivalent);
    private readonly _activeAggregationQueries = new ArrayMap<SerializedQuery, ActiveAggregationQuery>(
        areSerializedQueriesEquivalent
    );
    private readonly _invalidatedAggregateQueries = new ArrayMap<SerializedQuery, MutableTable>(
        areSerializedQueriesEquivalent
    );
    private readonly _aggregateAggregator: AggregateQueryAggregator;
    // This exists for the benefit of the builder, to handle modified query definitions
    // for existing queries.
    private _saveSerial = 0;
    private _numRowsAddedSinceGC = 0;

    private _activeQueriesCV = new ConditionVariable();
    private _builderInfo: true | BuilderQueryInfo | undefined;

    private readonly _queriesRunningWatchable = new Watchable(false);

    private readonly _rowChangeOverlayer = new RowChangeOverlayer();

    // job ID -> column names | true (for all columns)
    private readonly _columnsAffectedByJob = new Map<string, ReadonlySet<string> | true>();

    private readonly _queryRefresher: QueryRefresher;
    private readonly _rowVersions: RowVersions = new RowVersions();

    constructor(
        private _appEnvironment: ActionAppEnvironment,
        private readonly _tableName: TableName,
        private _table: TableGlideType | undefined,
        private readonly _savedID: NativeTableQueryID,
        // The latest version we've received from the backend, which is the
        // minimum version we demand when doing queries.
        private _latestReceivedVersion: MinRequiredVersion | undefined,
        _buildQueryRefresher: BuildQueryRefresher,
        private readonly _backendCaller: QueryableBackendCaller,
        private readonly _callbacks: QueryableRowsRootFinder & ExistingQueryRowDataStoreCallbacks
    ) {
        super(false, false);

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const dataStore = this;
        const helper: AggregateQueryAggregatorHelper = {
            get appEnvironment(): ActionAppEnvironment {
                return dataStore._appEnvironment;
            },
            get backendCaller(): QueryableBackendCaller {
                return dataStore._backendCaller;
            },
            get nativeTableID(): NativeTableQueryID {
                return dataStore._savedID;
            },
            get builderInfo(): true | BuilderQueryInfo | undefined {
                return dataStore._builderInfo;
            },
            get minVersion(): MinRequiredVersion | undefined {
                return dataStore._latestReceivedVersion;
            },
        };
        this._aggregateAggregator = new AggregateQueryAggregator(helper);

        this._queryRefresher = _buildQueryRefresher(
            () => concatIterableIterators(this._activeAggregationQueries.values(), this._activeQueries.values()),
            () => this.handleQueriesRefreshed()
        );
    }

    private handleQueriesRefreshed(): void {
        this._activeQueriesCV.notifyAll();
        this._serviceNewQueriesJob.request();
        this._serviceNewAggregationsJob.request();
    }

    /** @deprecated This is only for testing */
    public get numInvalidatedAggregateQueries(): number {
        return this._invalidatedAggregateQueries.size;
    }

    public setBuilderInfo(builderInfo: true | BuilderQueryInfo | undefined) {
        this._builderInfo = builderInfo;
    }

    public setTable(table: TableGlideType): void {
        const computedColumns = new Set(
            mapFilterUndefined(this._table?.columns ?? [], c => (isComputedColumn(c) ? c.name : undefined))
        );

        this._table = table;

        this.invalidateQueriesAffectedByColumns(this._activeQueries, computedColumns, undefined);
        this.invalidateQueriesAffectedByColumns(this._activeAggregationQueries, computedColumns, undefined);

        // We clear the invalidated queries because they might not even
        // reflect the current schema anymore.
        this._invalidatedAggregateQueries.clear();
    }

    private updateLatestReceivedVersion(newVersion: number | undefined): void {
        if (newVersion === undefined) return;
        if (typeof this._latestReceivedVersion !== "number" || newVersion > this._latestReceivedVersion) {
            this._latestReceivedVersion = newVersion;
        }
    }

    public get areSubscribedQueriesRunning(): ChangeObservable<boolean> {
        return this._queriesRunningWatchable;
    }

    private updateQueriesRunningWatchable(): void {
        for (const q of concatIterableIterators(
            this._activeQueries.values(),
            this._activeAggregationQueries.values()
        )) {
            if (!this.isRelevantRunningQuery(q)) continue;
            this._queriesRunningWatchable.current = true;
            return;
        }
        this._queriesRunningWatchable.current = false;
    }

    private isRelevantRunningQuery(q: ActiveQueryBase): boolean {
        if (q.state === QueryState.Done) return false;

        // Don't show the loading indicator for reloading queries.
        if (q.lastValue !== undefined) return false;

        // We have to check whether there are callbacks, because queries
        // that don't have callbacks won't inform the backend that
        // anything changed, which means it can get stuck in a perpetual
        // "loading" state.  The alternative would be to make "queries
        // running" into an observable.
        if (q.callbacks.size === 0) return false;

        return true;
    }

    private getActiveQuery(subQuery: SerializedQuery): ActiveQuery | undefined {
        return this._activeQueries.get(unlimitSerializedQuery(subQuery));
    }

    private getClosestResolvedOrInvalidatedQuery(
        subQuery: SerializedQuery,
        activeQueries: ArrayMap<SerializedQuery, ActiveQueryBase>
    ): MutableTable | LoadingValue | undefined {
        const invalidated = this._invalidatedAggregateQueries.get(subQuery);
        if (invalidated !== undefined) {
            return makeLoadingValueWithDisplayValue(invalidated);
        }

        let closest: MutableTable | undefined;
        let closestDistance = Number.MAX_SAFE_INTEGER;
        for (const active of activeQueries.values()) {
            if (isLoadingValue(active.currentValue)) continue;

            const distance = getSerializedQueryDistance(subQuery, active.subQuery);
            if (distance === false || distance === 0) continue;
            if (distance < closestDistance) {
                closestDistance = distance;
                closest = active.currentValue;
            }
        }
        if (closestDistance > 10) return undefined;

        return makeLoadingValueWithDisplayValue(closest);
    }

    // NOTE: We're not calling `updateQueriesRunningWatchable` in these two
    // methods because the code paths they're in do eventually call it.  If
    // that changes we might have to call from here, too.
    private setActiveQuery(subQuery: SerializedQuery, active: ActiveQuery) {
        this._activeQueries.set(unlimitSerializedQuery(subQuery), active);
    }
    private deleteActiveQuery(subQuery: SerializedQuery) {
        this._activeQueries.delete(unlimitSerializedQuery(subQuery));
    }

    public reportSave() {
        this._saveSerial++;
        this.pushDirtForTable();
    }

    public recompute(): GroundValue {
        const result = super.recompute();

        // The above can return a dummy Table for the user profile table, so we need to run this
        //  `if` block regardless of what that returns.
        if (getFeatureSetting("glideTablesOnGBT") && isGlideTableInGBTDataStore(this.getTable()?.sourceMetadata)) {
            // FIXME: 25k rows is the documented limit for a Glide Table, but I worry that much data
            //  might still cause our executeQuery pods to OOM. We should instead load in smaller batches.
            const query = forceLoadQueryableTable(
                this._tableName,
                this,
                glideTableInGBTRowLimit,
                this._builderInfo === true
            );
            if (isLoadingValue(result)) {
                return query;
            }
        }

        // FIXME: Returning `new Table()` is a hack so that we can show screens that have
        // this table as its source.  We should do a proper query in those cases.
        return isLoadingValue(result) ? new Table() : result;
    }

    protected getAppUserData(): AppUserData | undefined {
        return undefined;
    }

    protected getTable(): TableGlideType | undefined {
        return this._table;
    }

    protected fetchData(): void {
        // Not doing anything - this needs to be queries explicitly
    }

    // This must only be called if `active.currentValue` is not loading
    private sortRowsIfNecessary(active: ActiveQuery): void {
        if (getFeatureSetting("queriesInComputationModel")) return;

        if (!active.updateLocally) return;

        if (isLoadingValue(active.currentValue)) return;

        const columns = this._table?.columns ?? [];
        active.currentValue.mutateAsArray(rows => {
            applySort(columns, active.subQuery.sort, rows);
        });
    }

    private async waitForFullActiveQuerySet(targetState: QueryState, signal: AbortSignal): Promise<ActiveQuery[]> {
        if (signal.aborted) return [];
        while (true) {
            const rightNow = new Date();
            // Before we get the active query set, let's reset all expired continuations that have no callbacks.
            const removeTargets = Array.from(
                iterableFilter(
                    this._activeQueries.values(),
                    e =>
                        e.state === QueryState.Continue &&
                        e.callbacks.size === 0 &&
                        e.nextContinuation?.expiresAfter !== undefined &&
                        e.nextContinuation.expiresAfter < rightNow
                )
            );
            for (const target of removeTargets) {
                target.state = QueryState.Execute;
                target.nextContinuation = undefined;
                target.tableVersions = undefined;
            }

            const targets = iterableTake(
                iterableFilter(this._activeQueries.values(), e => e.state === targetState && e.callbacks.size > 0),
                queryBatchSize
            );
            if (targets.length >= queryBatchSize || signal.aborted) {
                for (const target of targets) {
                    // As silly as this seems, there's a point to it.
                    // ArrayMaps preserve insertion order when iterated, similar to the
                    // guaranteed behavior of Map in ECMAScript 6. This allows us to treat
                    // an ArrayMap like a queue in certain circumstances. What we're
                    // effectively doing here is "resetting" the queue for these entries,
                    // to enforce some degree of fairness.
                    this.deleteActiveQuery(target.subQuery);
                    this.setActiveQuery(target.subQuery, target);
                }
                if (targets.length > 0) {
                    this._activeQueriesCV.notifyAll();
                }
                this.updateQueriesRunningWatchable();
                return targets;
            }
            this.updateQueriesRunningWatchable();
            await this._activeQueriesCV.wait();
        }
    }

    private gcIfNecessary(): void {
        if (this._numRowsAddedSinceGC < numRowsToTriggerGC) return;
        if (!this._callbacks.isReadyForGC) {
            logInfo("not ready for GC");
            return;
        }

        this._callbacks.resetReadyForGC();

        const rootRowIDs = Array.from(this._callbacks.getRowIDsForTable(this._tableName));
        const callbacksToRun: (() => void)[] = [];
        const unusedQueries: SerializedQuery[] = [];

        for (const [serialized, active] of this._activeQueries.entries()) {
            if (active.callbacks.size > 0) {
                logInfo("query has callbacks", active);
                if (active.state === QueryState.Done) {
                    // We run these again so that listeners have to decide
                    // whether they still need them.  Without doing this the
                    // callbacks would hang around forever.
                    callbacksToRun.push(...active.callbacks);
                    active.callbacks.clear();
                }
                continue;
            }
            if (isLoadingValue(active.currentValue)) {
                logInfo("query is loading", active);
                continue;
            }
            const table = active.currentValue;
            if (rootRowIDs.some(id => table.has(id))) {
                logInfo("query has root row", active);
                continue;
            }

            logInfo("query is unused", active);
            unusedQueries.push(serialized);
        }

        this._numRowsAddedSinceGC = 0;

        // If we haven't found any unused queries, there's no point trying to
        // delete rows.
        logInfo("unused queries", unusedQueries.length);
        if (unusedQueries.length > 0) {
            // First we delete all unused queries
            for (const serialized of unusedQueries) {
                this._activeQueries.delete(serialized);
            }

            // Then we get all the rows we currently have.
            const unusedRowIDs = new Set(this.tableData.keys());

            // From that list we remove all the rows that are still in use.
            for (const active of this._activeQueries.values()) {
                const table = active.currentValue;
                if (isLoadingValue(table)) continue;

                for (const rowID of table.keys()) {
                    unusedRowIDs.delete(rowID);
                }
            }

            // All the rows left in the list are not used anymore, so we
            // delete them.
            logInfo("unused rows", unusedRowIDs.size);
            for (const rowID of unusedRowIDs) {
                // We don't push dirt for these because they shouldn't be in
                // use anywhere.
                this.tableData.delete(rowID);
            }
        }

        this.updateQueriesRunningWatchable();

        for (const cb of callbacksToRun) {
            cb();
        }
    }

    private scheduleGC(): void {
        // We call this right after servicing queries, which is a good point
        // to schedule stuff because it's what happens regularly when the data
        // store does actual work, but it's almost the worst time to do a GC
        // because we've just called and cleared out all callbacks and not
        // given whoever listened to them a chance to resubscribe, so if we
        // did a GC then it's likely we would collect a bunch of queries that
        // are just about to be subscribed to again.  So we wait a bit.
        setTimeout(() => this.gcIfNecessary(), 10);
    }

    private finishQuery(q: ActiveQueryBase): void {
        q.state = QueryState.Done;

        let errorMessage = q.errorMessage;
        if (
            errorMessage !== undefined &&
            !this._callbacks.isBuilder &&
            !getFeatureSetting("sqlShowDetailedErrorMessageInPlayer")
        ) {
            errorMessage = getLocalizedString("error", AppKind.Page);
        }

        if (isLoadingValue(q.currentValue)) {
            if (errorMessage !== undefined) {
                q.currentValue = new MutableTable(undefined, errorMessage);
            }
        } else {
            q.currentValue = q.currentValue.withErrorMessage(errorMessage);
        }
        runAndClearCallbacks(q);

        if (q.warningMessage !== undefined) {
            logError(`Warning running query ${this._savedID}`, q.warningMessage);
        }
        if (q.errorMessage !== undefined) {
            logError(`Error running query ${this._savedID}`, q.errorMessage);
            // FIXME: Do we signal the error to the user?  Do we put it back
            // into loading state?
        }

        this.onAfterQuery(q);
    }

    // `targetState` is the state of the queries this should run
    private async serviceQueriesInternal(
        targetState: QueryState,
        makeRequest: (targets: readonly ActiveQuery[]) => Promise<ExecuteQueryResponseBody | string>
    ) {
        const controller = new AbortController();
        const timeout = setTimeout(() => {
            controller.abort();
            this._activeQueriesCV.notifyAll();
        }, existingQueryExecutionDelay);
        const targets = await this.waitForFullActiveQuerySet(targetState, controller.signal);
        clearTimeout(timeout);

        // There's no need to make a network call if there are no requests to
        // actually run.
        if (targets.length === 0) {
            // We might still have queries that haven't even started running.
            if (targetState === QueryState.Continue) {
                this._serviceNewQueriesJob.request();
            }
            return;
        }

        // FIXME: Allow query calls to be aborted independent of the above timeout
        const queriesByID = new Map(targets.map(t => [t.subQueryID, t]));
        const firstQueryBody = await makeRequest(targets);

        if (typeof firstQueryBody === "string") {
            // FIXME: Shouldn't we be sending a signal to consumers somehow?
            for (const target of targets) {
                target.errorMessage = firstQueryBody;
                this.finishQuery(target);
            }
            return;
        }

        // if (Math.random() < 0.5) {
        //     logInfo("Simulating BigQuery error");
        //     return setErrorMessage("Simulated BigQuery error");
        // }

        let again = false;
        for (const response of firstQueryBody.responses) {
            const { queryID } = response;
            const receiver = queriesByID.get(queryID);
            if (receiver === undefined) {
                logError("No receiver for query", queryID);
                continue;
            }

            if (response.kind === "error") {
                queriesByID.delete(queryID);
                receiver.errorMessage = response.message;
                this.finishQuery(receiver);
            } else if (response.kind === "success") {
                if (this._table === undefined) {
                    // We only use the table we get back from the query if
                    // we're desperately in need of it.  It doesn't contain
                    // computed columns, or source metadata, so aggregate
                    // invalidation won't work with it, for example.
                    this.setTable({
                        ...tableGlideTypeCodecTableToTableGlideType(response.table),
                        name: this._tableName,
                    });
                }

                this.updateLatestReceivedVersion(response.version);
                this._rowVersions.update(receiver.subQueryID, receiver.subQuery, response.version);
                setTableVersionsForQuery(receiver, response.tableVersions);

                queriesByID.delete(queryID);
                receiver.errorMessage = undefined;
                receiver.warningMessage = undefined;
                if (response.numRowsInTable !== undefined) {
                    this._callbacks.setNumberOfRowsInTable(this._tableName, response.numRowsInTable);
                }
                const rowsToAdd = getRowsToAdd(response.rows);
                // We pass `undefined` to signal that we don't know yet
                // whether there will be rows or not.  In that case we should
                // keep the loading state. If `addRows` returns true, we should
                // continue.
                const shouldContinue = this.addRowsToActiveQuery(
                    receiver,
                    rowsToAdd.length > 0 || response.continuation === undefined ? rowsToAdd : undefined
                );

                if (response.continuation === undefined) {
                    // If this was a row version query, it won't return a continuation. That shouldn't be a problem
                    // because this shouldn't have make a row version query unless it has the full table downloaded
                    // which means there was no next continuation to begin with.
                    receiver.nextContinuation = undefined;
                    this.finishQuery(receiver);
                    receiver.lastValue = undefined;
                } else {
                    // FIXME: What do we do about local clock skew?
                    const expiresAfter = new Date(
                        response.continuation?.expirationTimestampMS ?? Date.now() + defaultContinuationExpiration
                    );
                    receiver.nextContinuation = {
                        token: response.continuation,
                        expiresAfter,
                    };

                    // FIXME: Why don't we use `finishQuery` here if
                    // `!shouldContinue`?  Don't we want all the stuff it
                    // does?
                    receiver.state = shouldContinue ? QueryState.Continue : QueryState.Done;
                    if (!shouldContinue) {
                        receiver.lastValue = undefined;
                    }
                    this.onAfterQuery(receiver);

                    // FIXME: shouldn't this be `shouldContinue`, and not `true`?
                    again = true;
                }
            } else {
                return assertNever(response);
            }
        }

        // These queries didn't receive any continuations or rows. Assume they're done.
        for (const receiver of queriesByID.values()) {
            logError("Backend did not respond with subquery", receiver.subQueryID);
            receiver.errorMessage = undefined;
            receiver.warningMessage = undefined;
            this.addRowsToActiveQuery(receiver, []);
            this.finishQuery(receiver);
        }

        this.updateQueriesRunningWatchable();

        if (again) {
            this._serviceExistingQueriesJob.request();
        } else if (targetState === QueryState.Execute) {
            // We might still have other "executing" queries with callbacks.
            this._serviceNewQueriesJob.request();
        }

        return;
    }

    private requestExistingQueryContinuation = async (
        targets: readonly ActiveQuery[]
    ): Promise<ExecuteQueryResponseBody | string> => {
        const requests = mapFilterUndefined(targets, t =>
            t.nextContinuation === undefined
                ? undefined
                : {
                      queryID: t.subQueryID,
                      continuation: t.nextContinuation?.token,
                      minVersion: this._latestReceivedVersion,
                      limit: t.subQuery.limit,
                  }
        );
        return await this._backendCaller.requestContinueQuery(
            this._appEnvironment,
            this._savedID,
            this._builderInfo,
            requests
        );
    };

    private async serviceExistingQueries() {
        await this.serviceQueriesInternal(QueryState.Continue, this.requestExistingQueryContinuation);
        this.scheduleGC();
    }

    private _serviceExistingQueriesJob = new RecurrentBackgroundJob(() => this.serviceExistingQueries());

    private requestNewQueries = async (targets: readonly ActiveQuery[]) => {
        const requests = targets.map(({ subQuery, subQueryID, maxLimit, nextContinuation }) => {
            const endOfTable = nextContinuation === undefined;
            const rowVersionQuery = this._rowVersions.makeQuery(subQueryID, subQuery, endOfTable);
            const adjustedLimitQuery = adjustRequeryLimit(rowVersionQuery, maxLimit);

            return makeQueryRequest(
                subQueryID,
                adjustedLimitQuery,
                this._latestReceivedVersion,
                this._appEnvironment.authenticator.appUserID
            );
        });
        return this._backendCaller.requestExecuteQuery(
            this._appEnvironment,
            this._savedID,
            this._builderInfo,
            requests
        );
    };

    private async serviceNewQueries() {
        await this.serviceQueriesInternal(QueryState.Execute, this.requestNewQueries);
        this.scheduleGC();
    }

    private async serviceNewAggregations() {
        for (const q of this._activeAggregationQueries.values()) {
            if (q.state !== QueryState.Execute) continue;
            void this.runAggregationQuery(q);
        }
        this.updateQueriesRunningWatchable();
    }

    private async runAggregationQuery(target: ActiveAggregationQuery): Promise<void> {
        const responseBody = await this._aggregateAggregator.requestExecuteQuery(target.subQuery, target.subQueryID);

        // FIXME: It's possible that at this point `target` is not an active
        // query anymore because it's been invalidated itself.  In that case
        // it should not remove the invalidated query here.  See this Replay
        // for a reproduction of this bug:
        // https://legacy.replay.io/recording/why-does-rollup-disappear--73d96247-2790-4243-91ec-08de75159d4e
        this._invalidatedAggregateQueries.delete(target.subQuery);

        if (typeof responseBody === "string") {
            target.errorMessage = responseBody;
        } else {
            assert(responseBody.responses.length <= 1);
            const response = responseBody.responses[0];
            if (response === undefined) {
                target.errorMessage = "No response";
            } else if (response.kind === "error") {
                target.errorMessage = response.message;
            } else {
                let rowIndex = nativeTableIndexer.zero;
                const rows = getRowsToAdd(response.rows).map(r => {
                    const rowID = this._appEnvironment.appFacilities.makeRowID();
                    const row = makeRowFromRowData(undefined, rowID, r, rowIndex);
                    rowIndex = nativeTableIndexer.nextNumber(rowIndex);
                    return row;
                });
                target.currentValue = new MutableTable(rows);
                target.lastValue = undefined;
                setTableVersionsForQuery(target, response.tableVersions);
            }
        }

        this.finishQuery(target);
        this.updateQueriesRunningWatchable();
    }

    private _serviceNewQueriesJob = new RecurrentBackgroundJob(() => this.serviceNewQueries());
    private _serviceNewAggregationsJob = new RecurrentBackgroundJob(() => this.serviceNewAggregations());

    private doesQueryUseComputedColumns(q: SerializedQuery): boolean {
        const columnNames = getColumnsUsedByQuery(q);
        const hasComputed = this._table?.columns.some(c => isComputedColumn(c) && columnNames.has(c.name)) === true;
        return hasComputed;
    }

    private get latestReceivedVersionForOverlaying(): number {
        if (typeof this._latestReceivedVersion === "number") {
            return this._latestReceivedVersion;
        } else {
            return 0;
        }
    }

    private addRowsToActiveQuery(active: ActiveQuery, rows: readonly JSONObject[] | undefined) {
        const { subQuery, subQueryID } = active;

        // It's important that we bail if we can't find our own query
        // anymore.  The GC, for example, will remove queries that
        // aren't in use anymore.
        const maybeOther = this.getActiveQuery(subQuery);
        if (maybeOther === undefined || maybeOther.subQueryID !== subQueryID) return false;

        let shouldContinue: boolean;
        if (rows !== undefined) {
            const queriesToUpdate = new Set<SerializedQuery>();
            active.currentValue = unwrapLoadingTable(active.currentValue);

            for (const rowData of rows) {
                const rowID = makeRowIDForRow(this._table, rowData, this._appEnvironment.appFacilities);
                const existingRow = this.tableData.get(rowID);
                const existingRowIndex = definedMap(existingRow, getRowIndex);
                const rowIndexFallback = existingRowIndex ?? this._callbacks.makeRowIndexFallback();

                let row = makeRowFromRowData(this._table, rowID, rowData, rowIndexFallback);
                const withChanges = this._rowChangeOverlayer.overlayChangesToRow(
                    row,
                    this.latestReceivedVersionForOverlaying
                );

                if (withChanges === undefined) {
                    // The row has been deleted locally
                    if (existingRow !== undefined) {
                        this.deleteRow(row.$rowID);
                    }
                    continue;
                } else {
                    // This row was deleted from the database
                    if (withChanges[nativeTableRowDeletedColumnName] === true) {
                        this.deleteRow(row.$rowID);
                        this.deleteRowFromActiveQueries(row.$rowID);
                        continue;
                    }
                }

                if (existingRow !== undefined) {
                    this.updateRowData(existingRow, withChanges, false, false, false);
                    row = existingRow;
                } else {
                    this.addRow(withChanges, queriesToUpdate);
                    this._numRowsAddedSinceGC++;
                    row = withChanges;
                }
                // TODO: `updateRowData` and `addRow` should already
                // have added the row to this query, so this should be
                // superfluous.  We should make sure that's the case
                // and then maybe change this to an `assert`.
                active.currentValue.set(row.$rowID, row);
            }

            active.currentValue = active.currentValue.withErrorMessage(active.errorMessage);
            this.sortRowsIfNecessary(active);

            const toCallBack: ActiveQuery[] = [];
            for (const [serializedQuery, activeQuery] of this._activeQueries) {
                if (!queriesToUpdate.has(serializedQuery)) continue;

                this.sortRowsIfNecessary(activeQuery);
                toCallBack.push(activeQuery);
            }

            for (const activeQuery of toCallBack) {
                runAndClearCallbacks(activeQuery);
            }

            shouldContinue = active.currentValue.size < active.maxLimit;
        } else {
            shouldContinue = true;
        }

        // FIXME: Do we really need to run callbacks if `rows` is `undefined`?
        runAndClearCallbacks(defined(active));
        return shouldContinue;
    }

    public runQuery(subQuery: SerializedQuery, callback: () => void): Table | LoadingValue {
        const getCurrentValueOrClosest = <T extends boolean>(
            active: T extends true ? ActiveAggregationQuery : ActiveQuery,
            isAggregation: T
        ) => {
            if (isLoadingValue(active.currentValue)) {
                const closest = this.getClosestResolvedOrInvalidatedQuery(
                    subQuery,
                    isAggregation ? this._activeAggregationQueries : this._activeQueries
                );
                if (closest !== undefined) {
                    return closest;
                }
            }

            if (active.lastValue !== undefined) {
                return makeLoadingValueWithDisplayValue(active.lastValue);
            }

            return active.currentValue;
        };

        if (subQuery.groupBy !== undefined) {
            let active = this._activeAggregationQueries.get(subQuery);

            if (active !== undefined) {
                active.callbacks.add(callback);
                return getCurrentValueOrClosest(active, true);
            }

            active = {
                subQuery,
                subQueryID: this._appEnvironment.appFacilities.makeUUID(),
                callbacks: new Set([callback]),
                state: QueryState.Execute,
                currentValue: makeLoadingValue(),
                lastValue: undefined,
                errorMessage: undefined,
                warningMessage: undefined,
                updateLocally: !this.doesQueryUseComputedColumns(subQuery),
                tableVersions: undefined,
            };
            this._activeAggregationQueries.set(subQuery, active);
            void this.runAggregationQuery(active);
            this.updateQueriesRunningWatchable();

            return getCurrentValueOrClosest(active, true);
        }

        const subQueryLimit = subQuery.limit ?? Number.MAX_SAFE_INTEGER;

        const finish = (job: RecurrentBackgroundJob) => {
            this._activeQueriesCV.notifyAll();
            job.request();
            this.updateQueriesRunningWatchable();

            return getCurrentValueOrClosest(defined(active), false);
        };

        let active = this.getActiveQuery(subQuery);
        let currentValue: MutableTable | LoadingValue;
        if (active !== undefined) {
            // We always add the callback, even if the query is already done.
            // We do that to keep track of which queries are still needed:
            // queries that don't have callbacks aren't in use anymore and we
            // can GC them.
            active.callbacks.add(callback);

            // This is where we use a continuation and increase the limit
            // instead of making a new query.  We only do this if the query is
            // still on the same serial and the continuation hasn't expired
            // yet.
            if (
                active.state === QueryState.Done &&
                active.saveSerial === this._saveSerial &&
                active.maxLimit < subQueryLimit &&
                active.nextContinuation !== undefined &&
                active.nextContinuation.expiresAfter > new Date()
            ) {
                // Note that we're using `maxLimit` here, vs
                // `currentValue.size`. `maxLimit` is the most we've ever
                // queried.  `currentValue.size` can actually be larger than
                // that because we add rows from other queries to it if they
                // match the conditions.  But consider a situation where we
                // query one table from both sides, i.e. one query sorts
                // ascending and the other descending. We start by doing 3
                // rows for each, so we get A, B, C for the ascending query
                // and X, Y, Z for the descending query.  They use the same
                // condition, however, so we add each queries results to the
                // other query's `currentValue`, so they will now both have 6
                // rows.  Since the `maxLimit` is still 3 we will never return
                // those other rows, but now imagine we increase the limit to
                // 6 but decide that we don't actually need to query because
                // we already have 6 rows.  The ascending query will now
                // return A, B, C, X, Y, Z, when it should actually query for
                // 3 more rows after C and eventually return A, B, C, D, E, F.
                const numRowsMissing = subQueryLimit - active.maxLimit;
                assert(numRowsMissing > 0);

                active.subQuery = { ...active.subQuery, limit: numRowsMissing };
                active.maxLimit = subQueryLimit;
                active.state = QueryState.Continue;

                return finish(this._serviceExistingQueriesJob);
            }

            if (active.state !== QueryState.Done) {
                if (active.state === QueryState.Execute) {
                    this._serviceNewQueriesJob.request();
                } else {
                    this._serviceExistingQueriesJob.request();
                }
            }
            // If the save serial has changed, or the limit has increased, we
            // need to run it again anyway.
            if (active.saveSerial === this._saveSerial) {
                currentValue = active.currentValue;
                if (subQueryLimit <= active.maxLimit) {
                    return getCurrentValueOrClosest(active, false);
                }
            } else {
                currentValue = makeLoadingValue();
            }
        } else if (!getFeatureSetting("queriesInComputationModel")) {
            const addedRows = this._rowChangeOverlayer.getAddedRows();
            const columns = this._table?.columns ?? [];
            const matchingRows = mapFilterUndefined(addedRows, r => {
                const row = this.tableData.get(r.$rowID);
                if (row === undefined) return undefined;
                if (!areConditionsTrueForRow(subQuery, cn => row[cn], columns)) return undefined;
                return row;
            });
            if (matchingRows.length > 0) {
                currentValue = makeLoadingValueWithDisplayValue(new MutableTable(matchingRows));
            } else {
                currentValue = makeLoadingValue();
            }
        } else {
            currentValue = makeLoadingValue();
        }
        const subQueryID = this._appEnvironment.appFacilities.makeUUID();

        active = {
            subQuery,
            subQueryID,
            callbacks: new Set([callback]),
            state: QueryState.Execute,
            // If we have an active query with the same save serial, it had a lower limit
            // than we wanted. We need to preserve the currentValue the previous active
            // query had so that we don't blink in and out of existence.
            currentValue,
            lastValue: undefined,
            warningMessage: undefined,
            errorMessage: undefined,
            tableVersions: undefined,
            nextContinuation: undefined,
            saveSerial: this._saveSerial,
            maxLimit: subQueryLimit,
            updateLocally: !this.doesQueryUseComputedColumns(subQuery),
        };
        // If we initialized `currentValue` with rows that were added by
        // actions, they need to be sorted.
        this.sortRowsIfNecessary(active);
        this.setActiveQuery(subQuery, active);

        return finish(this._serviceNewQueriesJob);
    }

    public resetAllQueries(version: number | undefined, appUserChanged: boolean) {
        this.updateLatestReceivedVersion(version);

        if (appUserChanged) {
            this._rowChangeOverlayer.clear();
        }

        // Minimize callbacks we need to call
        const callbacks = new Set<() => void>();

        for (const q of this._activeQueries.values()) {
            setUnionInto(callbacks, q.callbacks);
        }
        this._activeQueries.clear();

        for (const q of this._activeAggregationQueries.values()) {
            setUnionInto(callbacks, q.callbacks);
            if (!isLoadingValue(q.currentValue)) {
                this._invalidatedAggregateQueries.set(q.subQuery, q.currentValue);
            }
        }
        this._activeAggregationQueries.clear();

        this.updateQueriesRunningWatchable();

        // We call them after deleting the queries, just in case they call us
        // back synchronously.
        for (const cb of callbacks) {
            cb();
        }
    }

    public addRow(row: Row, queriesToUpdate?: Set<SerializedQuery>): void {
        super.addRow(row);

        const toCallBack: ActiveQuery[] = [];

        for (const [serializedQuery, active] of this._activeQueries.entries()) {
            if (!active.updateLocally || getFeatureSetting("queriesInComputationModel")) continue;

            if (serializedQuery.groupBy !== undefined) continue;

            const columns = this._table?.columns ?? [];
            if (!areConditionsTrueForRow(serializedQuery, cn => row[cn], columns)) continue;

            if (isLoadingValue(active.currentValue)) {
                let rows: Row[];
                const displayValue = unwrapLoadingValue(active.currentValue);
                if (!isLoadingValue(displayValue) && isTable(displayValue)) {
                    rows = Array.from(displayValue.asMutatingArray());
                } else {
                    rows = [];
                }
                rows.push(row);
                active.currentValue = makeLoadingValueWithDisplayValue(new MutableTable(rows));
            } else {
                active.currentValue.set(row.$rowID, row);
                if (queriesToUpdate === undefined) {
                    // We need to preserve the sort, if there is one
                    this.sortRowsIfNecessary(active);
                }
            }

            toCallBack.push(active);

            queriesToUpdate?.add(serializedQuery);
        }

        if (queriesToUpdate === undefined) {
            for (const active of toCallBack) {
                runAndClearCallbacks(active);
            }
        }
    }

    public addRowFromAction(row: Row, jobID: string): void {
        this._rowChangeOverlayer.addRow(row, this.latestReceivedVersionForOverlaying, jobID);

        this._columnsAffectedByJob.set(jobID, true);

        this.addRow(row);
    }

    // This should never be called from outside
    public setColumnsInRow(rowIndex: RowIndex, values: Record<string, WritableValue>): void {
        super.setColumnsInRow(rowIndex, values);

        const toCallBack = new Set<ActiveQuery>();

        for (const row of this.findRows(rowIndex)) {
            for (const [serializedQuery, active] of this._activeQueries.entries()) {
                if (!active.updateLocally || getFeatureSetting("queriesInComputationModel")) continue;

                if (serializedQuery.groupBy !== undefined) continue;

                if (isLoadingValue(active.currentValue)) continue;

                const columns = this._table?.columns ?? [];
                const shouldBeIncluded = areConditionsTrueForRow(serializedQuery, cn => row[cn], columns);
                // If it wasn't included, and still shouldn't be, we're good,
                // otherwise we have to do stuff.
                if (!shouldBeIncluded && !active.currentValue.has(row.$rowID)) continue;

                if (shouldBeIncluded) {
                    active.currentValue.set(row.$rowID, row);
                    this.sortRowsIfNecessary(active);
                } else {
                    active.currentValue.delete(row.$rowID);
                }
                toCallBack.add(active);
            }
        }

        for (const active of toCallBack) {
            runAndClearCallbacks(active);
        }
    }

    public setColumnsInRowFromAction(rowIndex: RowIndex, values: Record<string, WritableValue>, jobID: string): void {
        const rows = this.findRows(rowIndex);
        if (rows.length > 0) {
            for (const row of rows) {
                this._rowChangeOverlayer.setColumnsInRow(row, this.latestReceivedVersionForOverlaying, values, jobID);
            }
        } else {
            const { keyColumnName, keyColumnValue } = decomposeRowIndex(rowIndex);
            if (keyColumnName === nativeTableRowIDColumnName && typeof keyColumnValue === "string") {
                this._rowChangeOverlayer.setColumnsInRow(
                    keyColumnValue,
                    this.latestReceivedVersionForOverlaying,
                    values,
                    jobID
                );
            }
        }

        // TODO: We'll need the full schema when we support computed columns
        // spanning more than one table.
        const schema = makeSimpleSchemaInspector(makeTypeSchema(filterUndefined([this._table])), undefined, undefined);
        this._columnsAffectedByJob.set(
            jobID,
            getColumnsAffectedByDataColumns(schema, this._table, Object.keys(values), true, true)
        );

        this.setColumnsInRow(rowIndex, values);
    }

    private deleteRowFromActiveQueries(rowID: string): readonly ActiveQuery[] {
        const toCallBack: ActiveQuery[] = [];
        for (const active of this._activeQueries.values()) {
            if (isLoadingValue(active.currentValue)) continue;

            if (active.currentValue.has(rowID)) {
                active.currentValue.delete(rowID);
                toCallBack.push(active);
            }
        }
        return toCallBack;
    }

    public deleteRowAtIndex(rowIndex: RowIndex): void {
        const toCallBack: ActiveQuery[] = [];

        for (const row of this.findRows(rowIndex)) {
            this._rowChangeOverlayer.deleteRow(row.$rowID);

            toCallBack.push(...this.deleteRowFromActiveQueries(row.$rowID));
        }

        // We need to call this here, as opposed to be at the top, because
        // otherwise `findRows` wouldn't find the rows anymore.
        super.deleteRowAtIndex(rowIndex);

        for (const active of toCallBack) {
            runAndClearCallbacks(active);
        }
    }

    public deleteRowAtIndexFromAction(rowIndex: RowIndex, jobID: string): void {
        this.deleteRowAtIndex(rowIndex);
        this._columnsAffectedByJob.set(jobID, true);
    }

    private invalidateQueriesAffectedByColumns(
        activeQueries: ArrayMap<SerializedQuery, ActiveQueryBase>,
        columnsAffected: ReadonlySet<string> | true,
        receivedVersion: number | undefined
    ): void {
        if (columnsAffected !== true && columnsAffected.size === 0) return;

        const queriesToInvalidate: [SerializedQuery, ActiveQueryBase][] = [];
        const callbacks = new Set<() => void>();
        activeQueries.filterInPlace((active, query) => {
            if (columnsAffected !== true && !doesQueryReferToColumns(query, columnsAffected)) {
                return true;
            }

            queriesToInvalidate.push([query, active]);
            setUnionInto(callbacks, active.callbacks);
            return false;
        });

        if (queriesToInvalidate.length === 0) {
            assert(callbacks.size === 0);
            return;
        }

        this.updateLatestReceivedVersion(receivedVersion);

        if (activeQueries === this._activeAggregationQueries) {
            for (const [subQuery, active] of queriesToInvalidate) {
                const currentValue = unwrapLoadingValue(active.currentValue);
                if (!isLoadingValue(currentValue)) {
                    assert(currentValue instanceof MutableTable);
                    this._invalidatedAggregateQueries.set(subQuery, currentValue);
                }
            }
        }

        this.updateQueriesRunningWatchable();

        for (const cb of callbacks) {
            cb();
        }
    }

    public confirmAction(rowID: string | undefined, jobID: string, version: number): void {
        if (rowID !== undefined) {
            const maybeChangedRow = this._rowChangeOverlayer.confirmAction(rowID, jobID, version);
            if (maybeChangedRow !== undefined) {
                const row = this.tableData.get(rowID);
                if (row !== undefined) {
                    this.updateRowData(row, maybeChangedRow, false, false, true);
                }
            }
        }

        const columnsAffected = this._columnsAffectedByJob.get(jobID);
        if (columnsAffected !== undefined) {
            this._columnsAffectedByJob.delete(jobID);
            this.invalidateQueriesAffectedByColumns(this._activeAggregationQueries, columnsAffected, version);
        }
    }

    public rollbackAction(rowID: string, jobID: string): void {
        const maybeChangedRow = this._rowChangeOverlayer.rollbackAction(rowID, jobID);
        if (maybeChangedRow === undefined) return;

        if (maybeChangedRow === "delete") {
            const toCallBack = this.deleteRowFromActiveQueries(rowID);

            // We need to call this here, as opposed to be at the top, because
            // otherwise `findRows` wouldn't find the rows anymore.
            this.deleteRow(rowID);

            for (const active of toCallBack) {
                runAndClearCallbacks(active);
            }
        } else {
            const row = this.tableData.get(rowID);
            if (row !== undefined) {
                this.updateRowData(row, maybeChangedRow, false, false, true);
            }

            // FIXME: don't we have to update local queries here?
        }
    }

    public obsoleteAction(jobIDA: string, jobIDB: string): void {
        this._rowChangeOverlayer.obsoleteAction(jobIDA, jobIDB);
    }

    public tableVersions(): Record<string, QueryTableVersions> {
        return this._queryRefresher.tableVersions();
    }

    public requery(queryIDs: Set<string>): void {
        return this._queryRefresher.requery(queryIDs);
    }

    public onAfterQuery(query: ActiveQueryBase): void {
        return this._queryRefresher.onAfterQuery(query);
    }
}

type MakeExistingQueryRowDataStore = (
    ...args: ConstructorParameters<typeof ExistingQueryRowDataStore>
) => ExistingQueryRowDataStore;

interface Overrides {
    readonly actionPoster: ActionPoster;
    readonly simpleActionPoster: ActionPoster;
    readonly batchingActionPoster: BatchingActionPoster;
    readonly makeExistingQueryRowDataStore: MakeExistingQueryRowDataStore;
    readonly debounceTimeout: number;
}

export class QueryableDataStoreImpl
    implements QueryableDataStore, QueryableRowsRootFinder, ExistingQueryRowDataStoreCallbacks
{
    private readonly _queryableRowsRootFinders = new Set<QueryableRowsRootFinder>();
    // These hold the total number of rows in this query, including all the
    // ones we haven't loaded yet.
    private readonly _numRowsForTable = new DefaultArrayMap<TableName, Watchable<number | undefined>>(
        areTableNamesEqual,
        () => new Watchable<number | undefined>(undefined)
    );
    private readonly _locallyModifiedRows = new DefaultArrayMap<TableName, Set<string>>(
        areTableNamesEqual,
        () => new Set()
    );
    private readonly _backendCaller: QueryableBackendCaller;
    private readonly _simpleActionPoster: ActionPoster;
    private _batchingActionPoster: BatchingActionPoster | undefined;
    private _actionPoster: ActionPoster | undefined;
    private _actionManager: ActionManager | undefined;
    private _outstandingOperationsHandlers = new Set<ActionOutstandingOperationsHandler>();

    private readonly _makeExistingQueryRowDataStore: MakeExistingQueryRowDataStore;

    private _appUserDataObservable: ChangeObservable<AppUserData> | undefined;

    // We're using a global row index fallback across all tables, and across
    // the builder and player data stores.  This is technically not super
    // nice, but we're only using the row index fallback for row ordering, and
    // we don't compare rows between the builder and player.  Even if we were
    // more meticulous here we'd end up with inconsistent row indexes because
    // the builder and player run different queries, so the same row can be
    // returned at different times.
    private _nextRowIndexFallback: string = nativeTableIndexer.zero;

    private readonly _queryFreshener: RunningQueryFreshener;

    constructor(
        private readonly _appEnvironment: ActionAppEnvironment,
        public readonly isBuilder: boolean,
        public readonly writeSource: WriteSourceType,
        private readonly deviceIDOverride: string | undefined,
        backendCallerOverride: QueryableBackendCaller | undefined,
        private readonly appOwnerID: string | undefined,
        private readonly _forceInitialQueryToPrimary: boolean,
        private readonly startQueryFreshener: StartQueryFreshener,
        private readonly accessors: ActionManagerAcessors,
        private readonly overrides?: Partial<Overrides>
    ) {
        this._simpleActionPoster = overrides?.simpleActionPoster ?? new SimpleActionPoster(this.accessors);
        this._actionPoster = overrides?.actionPoster;
        this._batchingActionPoster = overrides?.batchingActionPoster;
        this._makeExistingQueryRowDataStore =
            overrides?.makeExistingQueryRowDataStore ?? ((...args) => new ExistingQueryRowDataStore(...args));
        this._backendCaller =
            backendCallerOverride ?? new QueryableBackendCallerImpl(deviceIDOverride ?? getDeviceID());

        this._queryFreshener = this.startQueryFreshener(
            2_000,
            () => this.allTables(),
            () => this._appEnvironment
        );
    }

    public allTables() {
        return concatIterableIterators(this.playerExistingTables.values(), this.builderExistingTables.values());
    }

    public subscribeToOutstandingOperations(handler: ActionOutstandingOperationsHandler): void {
        if (this._actionManager !== undefined) {
            this._actionManager.subscribeToOutstandingOperations(handler);
            assert(this._outstandingOperationsHandlers.size === 0);
        } else if (!this._outstandingOperationsHandlers.has(handler)) {
            this._outstandingOperationsHandlers.add(handler);
            handler(this.getActionOutstandingOperations());
        }
    }

    public unsubscribeFromOutstandingOperations(handler: ActionOutstandingOperationsHandler): void {
        if (this._actionManager !== undefined) {
            this._actionManager.unsubscribeFromOutstandingOperations(handler);
        } else {
            this._outstandingOperationsHandlers.delete(handler);
        }
    }

    public getActionOutstandingOperations(): ActionOperationsState {
        if (this._actionManager !== undefined) {
            return this._actionManager.getActionOutstandingOperations();
        }
        return emptyActionOperationsState;
    }

    private getExistingDataRowStoresForTable(tableName: TableName): readonly ExistingQueryRowDataStore[] {
        return mapFilterUndefined([false, true], forBuilder => {
            const target = this.getDataRowStoreForTable(tableName, forBuilder);
            if (target instanceof ExistingQueryRowDataStore) {
                return target;
            } else {
                return undefined;
            }
        });
    }

    private get batchingActionPoster(): BatchingActionPoster {
        if (this._batchingActionPoster === undefined) {
            this._batchingActionPoster = new BatchingActionPoster(this._appEnvironment.appFacilities, false);
        }

        return this._batchingActionPoster;
    }

    // This is `public` only for testing
    public getActionPosterForTable(tableName: TableName): ActionPoster {
        const table = this.findTable(tableName);
        if (table === undefined) return this._simpleActionPoster;

        return this.batchingActionPoster;
    }

    private getActionManager(): ActionManager {
        if (this._actionManager === undefined) {
            if (this._actionPoster === undefined) {
                this._actionPoster = new MultiplexingActionPoster(tn => this.getActionPosterForTable(tn));
            }
            this._actionManager = new ActionManager(
                this.accessors,
                this.deviceIDOverride,
                this.overrides?.debounceTimeout ?? debounceSetColumnsTimeout,
                this.overrides?.debounceTimeout ?? debounceDataEditorSetColumnsTimeout,
                this.isBuilder,
                this.writeSource,
                "-queryable",
                this._actionPoster,
                async (tableName, rowIndex, jobID, version) => {
                    const table = this.findTable(tableName);
                    if (table === undefined) return;

                    const rowID = getRowIDForRowIndex(table, rowIndex);
                    if (rowID === undefined) return;

                    for (const target of this.getExistingDataRowStoresForTable(tableName)) {
                        target.confirmAction(rowID, jobID, version);
                    }
                },
                async (tableName, rowIndex, jobID) => {
                    const table = this.findTable(tableName);
                    if (table === undefined) return;

                    const rowID = getRowIDForRowIndex(table, rowIndex);
                    if (rowID === undefined) return;

                    for (const target of this.getExistingDataRowStoresForTable(tableName)) {
                        target.rollbackAction(rowID, jobID);
                    }
                },
                (tableName, jobIDA, jobIDB) => {
                    for (const target of this.getExistingDataRowStoresForTable(tableName)) {
                        target.obsoleteAction(jobIDA, jobIDB);
                    }
                }
            );

            const handler = Array.from(this._outstandingOperationsHandlers);
            this._outstandingOperationsHandlers.clear();
            for (const h of handler) {
                this._actionManager.subscribeToOutstandingOperations(h);
            }
        } else {
            assert(this._outstandingOperationsHandlers.size === 0);
        }
        return this._actionManager;
    }

    private _queryRunningStates = new DefaultMap<string, Watchable<boolean>>(() => new Watchable<boolean>(false));
    private _schema: TypeSchema | undefined;

    public get schema(): TypeSchema {
        return panic("The schema should come from the firestore datastore");
    }

    private _previewSchemaSerial = new Watchable(0);

    // FIXME: do we need this anymore?
    public get previewSchemaSerial(): ChangeObservable<number> {
        return this._previewSchemaSerial;
    }

    public getComputationModelObservable(_forBuilder: boolean): ChangeObservable<ComputationModel | undefined> {
        return panic("Get the computation model from the main data store");
    }

    public fetchTableRows(_tableName: TableName, _forBuilder: boolean): void {
        // nothing to do
    }

    public getFirstRowObservable(
        tableName: TableName,
        isBuilder: boolean
    ): ChangeObservable<Row | undefined> | undefined {
        return this.getDataRowStoreForTable(tableName, isBuilder)?.firstRowObservable;
    }

    private updateTable = (newTable: TableGlideType) => {
        const { name: maybeTableName } = newTable;
        const tableName = makeTableName(maybeTableName);
        if (tableName.name.startsWith(previewQueryTablePrefix)) {
            this._previewSchemaSerial.current += 1;
        }
    };

    public getQueryRunningFlag(tableName: TableName): Watchable<boolean> | undefined {
        if (!tableName.name.startsWith(queryTablePrefix)) return undefined;
        return this._queryRunningStates.get(tableName.name);
    }

    private previewTablesForSources = new DefaultMap<string, DefaultMap<string, PreviewNewQueryRowDataStore>>(
        sourceKind =>
            new DefaultMap<string, PreviewNewQueryRowDataStore>(sourceID => {
                const internalTableName = previewQueryTableNameForNew(sourceKind, sourceID);
                const queryRunningFlag = defined(this.getQueryRunningFlag(internalTableName));
                const dataStore = new PreviewNewQueryRowDataStore(
                    this._appEnvironment,
                    sourceKind,
                    sourceID,
                    this.appOwnerID,
                    this.updateTable,
                    queryRunningFlag
                );
                return dataStore;
            })
    );

    public getPreviewTableForNew(sourceKind: string, sourceID: string): TableGlideType {
        return this.previewTablesForSources.get(sourceKind).get(sourceID).getTable();
    }

    private previewTablesForExisting = new DefaultMap<NativeTableID, PreviewExistingQueryRowDataStore>(queryID => {
        const internalTableName = previewQueryTableNameForExisting(queryID);
        const queryRunningFlag = defined(this.getQueryRunningFlag(internalTableName));
        const dataStore = new PreviewExistingQueryRowDataStore(
            this._appEnvironment,
            queryID,
            this.appOwnerID,
            this.updateTable,
            queryRunningFlag
        );
        return dataStore;
    });

    public getPreviewTableForExisting(queryID: NativeTableID): TableGlideType {
        return this.previewTablesForExisting.get(queryID).getTable();
    }

    private readonly existingTableDisplayNames = new Map<string, string>();

    private readonly playerQueriesRunningObservable = new CombinedChangeObservable<boolean, boolean>(a =>
        a.some(x => x)
    );
    private readonly builderQueriesRunningObservable = new CombinedChangeObservable<boolean, boolean>(a =>
        a.some(x => x)
    );

    private getQueriesRunningObservable(forBuilder: boolean): CombinedChangeObservable<boolean, boolean> {
        return forBuilder ? this.builderQueriesRunningObservable : this.playerQueriesRunningObservable;
    }

    private getBuilderInfo(forBuilder: boolean): true | BuilderQueryInfo | undefined {
        return (
            forBuilder ||
            definedMap(this._appUserDataObservable?.current, appUserData => ({
                previewAsEmail: appUserData.email ?? "",
                previewAsRoles: Array.from(appUserData.roles),
            }))
        );
    }

    private makeExistingTablesMap(
        forBuilder: boolean
    ): DefaultMap<NativeTableID, ExistingQueryRowDataStore | undefined> {
        return new DefaultMap(queryID => {
            if (forBuilder && !this.isBuilder) return undefined;

            const tableName = findTableNameForQuery(this._appEnvironment.sourceMetadata, this._schema, queryID);
            // FIXME: When we return `undefined` here then it's forever.  Instead we
            // should try again the next time.
            if (tableName === undefined) return undefined;
            const table = definedMap(this._schema, s => findTable(s, tableName));
            const dataStore = this._makeExistingQueryRowDataStore(
                this._appEnvironment,
                tableName,
                table,
                { kind: "native-table", value: makeNativeTableID(queryID) },
                this._forceInitialQueryToPrimary ? "latest" : undefined,
                this._queryFreshener.buildQueryRefresher,
                this._backendCaller,
                this
            );
            dataStore.setBuilderInfo(this.getBuilderInfo(forBuilder));
            this.getQueriesRunningObservable(forBuilder).addInput(dataStore.areSubscribedQueriesRunning);
            return dataStore;
        });
    }

    private readonly playerExistingTables = this.makeExistingTablesMap(false);
    private readonly builderExistingTables = this.makeExistingTablesMap(true);

    public appEnvironmentUpdated(): void {
        this.getActionManager().updateOfflineQueue();
        this.getActionManager().resetReloadResiliencyQueue();
    }

    private appUserChanged = (): void => {
        this.getActionManager().updateOfflineQueue();
        this.getActionManager().resetReloadResiliencyQueue();

        const schema = this._schema;
        if (schema === undefined) return;

        for (const table of schema.tables) {
            const queryID = this.getQueryIDForTable(table);
            if (queryID === undefined) continue;

            const hasRowOwners = getEmailOwnersColumnNames(table).length > 0;
            const hasUserSpecific = table.columns.some(c => c.isUserSpecific === true);

            if (hasUserSpecific || hasRowOwners) {
                this.resetQueryByID(queryID, undefined, true);
            }
        }
    };

    private findTable(tableName: TableName): TableGlideType | undefined {
        return definedMap(this._schema, s => findTable(s, tableName));
    }

    public async addRowToTable(
        { tableName, sendToBackend, setUnderlyingData, awaitSend, onError, ...metadata }: DataStoreMutationOptions,
        columnValues: Record<string, LoadedGroundValue>,
        columnNames: ReadonlySet<string> | undefined
    ): Promise<AddRowToTableResult> {
        const table = this.findTable(tableName);
        if (table === undefined || !isTableWritable(table)) {
            return {
                didAdd: false,
                playerRow: undefined,
                builderRow: undefined,
                jobID: undefined,
                confirmedAtVersion: undefined,
            };
        }

        const values = {
            // We allow `columnValues` to overwrite the row ID.  We need to
            // specify it out here, or otherwise `extractActionValues` might
            // remove it.
            $rowID: this._appEnvironment.appFacilities.makeRowID(),
            ...adaptValuesForWriting(
                table,
                this._appEnvironment.sourceMetadata ?? ShouldAgnostifyDateTimes.Keep,
                {
                    convertToString: false,
                    removeUnknownColumns: false,
                    allowRowID: true,
                    appUserEmail:
                        this._appEnvironment.authenticator.virtualEmail ?? this._appEnvironment.authenticator.realEmail,
                    fromDataEditor: metadata.fromDataEditor,
                },
                extractActionValues(columnValues, columnNames)
            ),
        };

        const jobID = this._appEnvironment.appFacilities.makeRowID();

        let playerRow: Row = { ...values, $isVisible: true };
        assert(typeof playerRow.$rowID === "string");

        const promise = sendToBackend
            ? this.getActionManager().addRowToTable(
                  tableName,
                  getRowIndexForRow(table, undefined, playerRow),
                  values,
                  jobID,
                  metadata,
                  onError,
                  true
              )
            : Promise.resolve(false);

        let confirmedAtVersion: number | undefined;
        // NOTE: We hardcode here the fact that external queryable data
        // sources (in particular SQL) might return a new row that's different
        // from the one we added.
        if (table.sourceMetadata?.externalSource !== undefined) {
            const result = await promise;
            if (result === false) {
                return { didAdd: false, playerRow: undefined, builderRow: undefined, jobID, confirmedAtVersion: false };
            }
            if (typeof result !== "boolean" && result.newRow !== undefined) {
                const rowData = {
                    // To make TS happy, and in case the backend didn't send
                    // us a row ID back.
                    $rowID: values.$rowID,
                    ...result.newRow,
                    $isVisible: true,
                };
                playerRow = makeRowFromRowData(
                    table,
                    makeRowIDForRow(table, rowData, this._appEnvironment.appFacilities),
                    rowData,
                    this.makeRowIndexFallback()
                );
            }
            confirmedAtVersion = typeof result !== "boolean" ? result.confirmedAtVersion : undefined;
        }

        // The player and builder rows must be distinct objects, or the
        // computation model will do weird things.
        const builderRow = { ...playerRow };

        if (setUnderlyingData) {
            for (const forBuilder of [false, true]) {
                const rowToAdd = forBuilder ? builderRow : playerRow;
                const target = this.getDataRowStoreForTable(tableName, forBuilder);
                if (target instanceof ExistingQueryRowDataStore) {
                    target.addRowFromAction(rowToAdd, jobID);
                    if (confirmedAtVersion !== undefined) {
                        // ##doubleAddRowActionConfirmation:
                        // The `ActionManager` will already have confirmed
                        // this action, but it will have done that up where we
                        // awaited `promise`, which is before we added the row
                        // to the data row store, which means it won't have
                        // done anything.  So here we do it again, this time
                        // knowing that the data row store knows about this
                        // job.  We could eliminate the superfluous
                        // confirmation, but it's not harmful, and it would
                        // require additional bookkeeping.
                        target.confirmAction(rowToAdd.$rowID, jobID, confirmedAtVersion);
                    }
                } else {
                    target?.addRow(rowToAdd);
                }
            }
            this._locallyModifiedRows.get(tableName).add(playerRow.$rowID);
        }

        let didAdd = true;
        if (awaitSend) {
            const result = await promise;
            // If this fails then we depend on the action rollback to remove
            // the row again.

            didAdd = result !== false;

            if (typeof result !== "boolean" && result.confirmedAtVersion !== undefined) {
                confirmedAtVersion = result.confirmedAtVersion;
            }
        }

        return { didAdd, playerRow, builderRow, jobID, confirmedAtVersion };
    }

    public async setColumnsInRow(
        { tableName, sendToBackend, setUnderlyingData, awaitSend, onError, ...metadata }: DataStoreMutationOptions,
        rowIndex: RowIndex,
        columnValues: Record<string, LoadedGroundValue>,
        withDebounce: boolean,
        _onCompletion: (() => void) | undefined,
        existingJobID: string | undefined,
        confirmedAtVersion: number | undefined
    ): Promise<MutationResult> {
        if (Object.keys(columnValues).length === 0) return { jobID: undefined, confirmedAtVersion: true };

        // This should have already been covered by the constructor, but if there's
        // a transient exception that got thrown we might be able to recover.
        this.getActionManager().processSetColumnsInRowResiliencyQueueWhenOnline();

        const table = this.findTable(tableName);
        if (table === undefined || !isTableWritable(table)) return { jobID: undefined, confirmedAtVersion: false };

        const values = adaptValuesForWriting(
            table,
            this._appEnvironment.sourceMetadata ?? ShouldAgnostifyDateTimes.Keep,
            {
                convertToString: false,
                removeUnknownColumns: false,
                allowRowID: false,
                fromDataEditor: metadata.fromDataEditor,
            },
            extractActionValues(columnValues, undefined)
        );

        const jobID = existingJobID ?? this._appEnvironment.appFacilities.makeRowID();

        if (setUnderlyingData) {
            const rowID = getNativeTableRowIDForRowIndex(rowIndex);
            for (const forBuilder of [false, true]) {
                const target = this.getDataRowStoreForTable(tableName, forBuilder);
                if (target instanceof ExistingQueryRowDataStore) {
                    target.setColumnsInRowFromAction(rowIndex, values, jobID);
                    if (confirmedAtVersion !== undefined) {
                        if (rowID !== undefined) {
                            target.confirmAction(rowID, jobID, confirmedAtVersion);
                        }
                    }
                } else {
                    // This shouldn't actually ever happen - only the
                    // `ExistingQueryRowDataStore` allows mutation.
                    target?.setColumnsInRow(rowIndex, values);
                }
            }
            if (rowID !== undefined) {
                this._locallyModifiedRows.get(tableName).add(rowID);
            }
        }

        if (confirmedAtVersion === undefined && sendToBackend) {
            return await this.getActionManager().setColumnsInRow(
                tableName,
                rowIndex,
                values,
                existingJobID !== undefined,
                jobID,
                withDebounce,
                metadata,
                onError,
                true
            );
        }

        return { jobID, confirmedAtVersion };
    }

    public async deleteRowsAtIndexes(
        { tableName, sendToBackend, setUnderlyingData, awaitSend, onError, ...metadata }: DataStoreMutationOptions,
        rowIndexes: readonly RowIndex[]
    ): Promise<MutationResult> {
        const table = this.findTable(tableName);
        if (table === undefined || !isTableWritable(table)) return { jobID: undefined, confirmedAtVersion: false };

        // This is a faux job ID we use to track confirmation.  We don't ever
        // actually create an action document with this ID.
        const jobID = this._appEnvironment.appFacilities.makeRowID();

        if (setUnderlyingData) {
            for (const forBuilder of [false, true]) {
                const target = this.getDataRowStoreForTable(tableName, forBuilder);
                for (const rowIndex of rowIndexes) {
                    if (target instanceof ExistingQueryRowDataStore) {
                        target.deleteRowAtIndexFromAction(rowIndex, jobID);
                    } else {
                        target?.deleteRowAtIndex(rowIndex);
                    }

                    // Make sure we only do this once
                    if (!forBuilder) {
                        const rowID = getNativeTableRowIDForRowIndex(rowIndex);
                        if (rowID !== undefined) {
                            this._locallyModifiedRows.get(tableName).add(rowID);
                        }
                    }
                }
            }
        }

        if (sendToBackend) {
            const promise = this.getActionManager().deleteRows(tableName, rowIndexes, metadata, jobID, onError, true);

            if (awaitSend) {
                return await promise;
            }
        }

        return { jobID, confirmedAtVersion: undefined };
    }

    public setEmailOwnersColumns(): void {
        // Not actually implemented.
    }

    public afterSetEmailOwnersColumns(tableName: TableName): void {
        this.resetQueryByTableName(tableName, undefined);
    }

    private getQueryIDForTable(table: TableGlideType): NativeTableID | undefined {
        return getQueryIDForTable(this._appEnvironment.sourceMetadata, table);
    }

    private getQueryIDForTableName(tableName: TableName): NativeTableID | undefined {
        const table = this.findTable(tableName);
        if (table === undefined) return undefined;
        return this.getQueryIDForTable(table);
    }

    public setSchema(schema: TypeSchema | undefined): void {
        this._schema = schema;

        const newTables = [...(schema?.tables ?? []).filter(t => this.getQueryIDForTable(t) !== undefined)];

        // We always preserve the preview tables for new queries.
        for (const sourceIDMap of this.previewTablesForSources.values()) {
            for (const dataStore of sourceIDMap.values()) {
                const table = dataStore.getTable();
                const tableName = makeTableName(table.name);
                const newSchemaIndex = newTables.findIndex(t => areTableNamesEqual(makeTableName(t.name), tableName));
                if (newSchemaIndex >= 0) {
                    newTables[newSchemaIndex] = table;
                } else {
                    newTables.push(table);
                }
            }
        }
        const retainQueryIDs = new Set(
            mapFilterUndefined(newTables, t => queryIDFromPreviewQueryTableNameForExisting(makeTableName(t.name)))
        );
        const removeQueryIDs = new Set<NativeTableID>();
        for (const [queryID, dataStore] of this.previewTablesForExisting.entries()) {
            if (!retainQueryIDs.has(queryID)) {
                removeQueryIDs.add(queryID);
                continue;
            }
            const table = dataStore.getTable();
            const tableName = makeTableName(table.name);
            const newSchemaIndex = newTables.findIndex(t => areTableNamesEqual(makeTableName(t.name), tableName));
            if (newSchemaIndex >= 0) {
                newTables[newSchemaIndex] = table;
            } else {
                newTables.push(table);
            }
        }
        for (const queryID of removeQueryIDs) {
            this.previewTablesForExisting.delete(queryID);
            this.existingTableDisplayNames.delete(queryID);
            this.playerExistingTables.delete(queryID);
            this.builderExistingTables.delete(queryID);
        }
        for (const table of newTables) {
            const maybeExistingTableID = this.getQueryIDForTable(table);
            if (maybeExistingTableID === undefined) continue;
            if (table.sourceMetadata?.type !== "Native table") continue;
            if (table.sourceMetadata.type === "Native table" && table.sourceMetadata.needsQuery !== true) continue;
            const displayName = sheetNameForTable(table);
            this.existingTableDisplayNames.set(maybeExistingTableID, displayName);

            for (const existing of [this.builderExistingTables, this.playerExistingTables]) {
                // `existing` is a `DefaultMap`, so this `has` check is
                // necessary.
                if (!existing.has(maybeExistingTableID)) continue;
                existing.get(maybeExistingTableID)?.setTable(table);
            }
        }
        this._previewSchemaSerial.current += 1;
    }

    public setAppUserDataObservable(observable: ChangeObservable<AppUserData>): void {
        if (this._appUserDataObservable === observable) return;

        this._appUserDataObservable?.unsubscribe(this.appUserChanged);
        this._appUserDataObservable = observable;
        this._appUserDataObservable.subscribe(this.appUserChanged);

        // We're assuming the app user has changed when the provider changes,
        // because we might already have queried with the wrong "builder
        // info".
        // https://github.com/quicktype/glide/issues/19242
        this.appUserChanged();
    }

    public userProfileTableChanged(): void {
        // Not actually implemented.
    }

    public isRowOwnedByUser(tableName: TableName, row: Row): boolean {
        const table = this.findTable(tableName);
        const appUserData = this._appUserDataObservable?.current;
        return isRowOwnedByUser(row, appUserData, table);
    }

    public resetFromUpstream(): void {
        // Actually implement this one.
    }

    public addRowOwnerChangeCallback(): void {
        // Not actually implemented.
    }

    public removeRowOwnerChangeCallback(): void {
        // Not actually implemented.
    }

    public retire(): void {
        this._appUserDataObservable?.unsubscribe(this.appUserChanged);
        this._queryFreshener.stop();
    }

    public getDataRowStoreForTable(tableName: TableName, forBuilder: boolean) {
        const maybePreviewSourceKindID = sourceKindIDFromPreviewQueryTableNameForNew(tableName);
        if (maybePreviewSourceKindID !== undefined) {
            const { sourceKind, sourceID } = maybePreviewSourceKindID;
            return this.previewTablesForSources.get(sourceKind).get(sourceID);
        }
        const maybePreviewExisting = queryIDFromPreviewQueryTableNameForExisting(tableName);
        if (maybePreviewExisting !== undefined) {
            return this.previewTablesForExisting.get(maybePreviewExisting);
        }
        const maybeExistingTableID = this.getQueryIDForTableName(tableName);
        if (maybeExistingTableID !== undefined) {
            if (forBuilder) {
                return this.builderExistingTables.get(maybeExistingTableID);
            } else {
                return this.playerExistingTables.get(maybeExistingTableID);
            }
        }
        return undefined;
    }

    public getPreviewQueryCanSaveFlagForNew(sourceKind: string, sourceID: string): ChangeObservable<boolean> {
        return this.previewTablesForSources.get(sourceKind).get(sourceID).canSaveCurrentQueryObservable;
    }

    public setPreviewQueryForNew(sourceKind: string, sourceID: string, queryName: string, queryString: string): void {
        this.previewTablesForSources.get(sourceKind).get(sourceID).setQueryText(queryName, queryString, undefined);
    }

    public clearPreviewQueryForNew(sourceKind: string, sourceID: string): void {
        this.previewTablesForSources.get(sourceKind).get(sourceID).clearQuery();
    }

    public async savePreviewQueryForNew(
        sourceKind: string,
        sourceID: string,
        getOverrideRowID: GetOverrideRowID,
        saveHandler?: OnQuerySaveHandler
    ): Promise<TableGlideType | undefined> {
        return await this.previewTablesForSources
            .get(sourceKind)
            .get(sourceID)
            .saveCurrentQuery(getOverrideRowID, saveHandler);
    }

    public getPreviewQueryForNewSavingFlag(sourceKind: string, sourceID: string): ChangeObservable<boolean> {
        return this.previewTablesForSources.get(sourceKind).get(sourceID).isSaving;
    }

    public getPreviewQueryCanSaveForExisting(queryID: NativeTableID): ChangeObservable<boolean> {
        return this.previewTablesForExisting.get(queryID).canSaveCurrentQueryObservable;
    }

    public setPreviewQueryForExisting(
        queryID: NativeTableID,
        queryName: string,
        queryString: string,
        remoteSerial?: number | undefined
    ): void {
        this.previewTablesForExisting.get(queryID).setQueryText(queryName, queryString, remoteSerial);
    }

    private reportSave(queryID: NativeTableID): void {
        if (this.builderExistingTables.has(queryID)) {
            const existingTable = this.builderExistingTables.get(queryID);
            existingTable?.reportSave();
        }
        if (this.playerExistingTables.has(queryID)) {
            const existingTable = this.playerExistingTables.get(queryID);
            existingTable?.reportSave();
        }
    }

    public async savePreviewQueryForExisting(
        queryID: NativeTableID,
        getOverrideRowID: GetOverrideRowID,
        saveHandler?: OnQuerySaveHandler
    ): Promise<TableGlideType | undefined> {
        const tableType = await this.previewTablesForExisting
            .get(queryID)
            .saveCurrentQuery(getOverrideRowID, saveHandler);
        if (tableType !== undefined) {
            this.reportSave(queryID);
        }
        return tableType;
    }

    public getPreviewQueryForExistingSavingFlag(queryID: NativeTableID): ChangeObservable<boolean> {
        return this.previewTablesForExisting.get(queryID).isSaving;
    }

    public getQueryErrorMessage(tableName: TableName): ChangeObservable<string | undefined> | undefined {
        const target = this.getDataRowStoreForTable(tableName, true);
        if (target === undefined || target instanceof ExistingQueryRowDataStore) return undefined;
        return target.errorMessage;
    }

    public getQueryWarningMessage(tableName: TableName): ChangeObservable<string | undefined> | undefined {
        const target = this.getDataRowStoreForTable(tableName, true);
        if (target === undefined || target instanceof ExistingQueryRowDataStore) return undefined;
        return target?.warningMessage;
    }

    public async rediscoverQuery(
        tableName: TableName,
        queryName: string,
        queryBase: SQLQueryBase,
        remoteSerial: number,
        getOverrideRowID: GetOverrideRowID,
        retrySleep: number = continuationWaitTime
    ): Promise<string | undefined> {
        if (this.appOwnerID === undefined) return "Unknown error rediscovering query";

        const table = this.findTable(tableName);
        if (table === undefined) return "Table does not exist";

        const queryID = this.getQueryIDForTable(table);
        if (queryID === undefined) return "Table cannot be reloaded";

        const savedIdentity = {
            kind: "existing",
            id: {
                kind: "native-table",
                value: makeNativeTableID(queryID),
            },
            lastSerial: remoteSerial,
        } as const;
        const requestID = this._appEnvironment.appFacilities.makeUUID();
        const response = await previewQueryDataSource(
            savedIdentity,
            this.appOwnerID,
            queryName,
            requestID,
            queryBase,
            1,
            this._appEnvironment.appFacilities
        );
        if (typeof response === "string") return response;

        let previewTable: TableGlideType | undefined;
        if (response.rows.length > 0 || response.continuation === undefined) {
            previewTable = { emailOwnersColumn: undefined, ...response.table };
        } else {
            let continuation = response.continuation;
            for (;;) {
                await sleep(retrySleep);
                const entries = await continuePreviewQueryDataSource(
                    this._appEnvironment.appFacilities,
                    savedIdentity,
                    this.appOwnerID,
                    requestID,
                    continuation
                );
                if (typeof entries === "string") return entries;
                if (entries.length !== 1) return "Invalid response from backend";

                const [entry] = entries;
                if (entry.kind === "error") return entry.message;

                if (entry.rows.length > 0 || entry.continuation === undefined) {
                    previewTable = { emailOwnersColumn: undefined, ...entry.table };
                    break;
                }

                continuation = entry.continuation;
            }
        }

        // If the response's row ID column is the native table one that means
        // the data source has a primary key and we're using it.  Otherwise we
        // try to reuse the existing row ID column.
        let rowIDColumn =
            previewTable.rowIDColumn === nativeTableRowIDColumnName ? nativeTableRowIDColumnName : table.rowIDColumn;
        if (rowIDColumn === undefined || getTableColumn(previewTable.columns, rowIDColumn) === undefined) {
            // If we don't have a row ID column name, or there's no column by
            // that name, we invoke the primary key picker.
            rowIDColumn = await getOverrideRowID(previewTable);
            // If `rowIDColumn` is `undefined` then the user has canceled, so
            // we return `undefined` for success.
            if (rowIDColumn === undefined) return undefined;
        }

        await saveQuery(
            savedIdentity,
            this._appEnvironment.appID,
            this.appOwnerID,
            queryName,
            queryBase,
            {
                ...tableGlideTypeCodecTableToTableGlideType(previewTable),
                name: table.name,
                sheetName: table.sheetName,
                rowIDColumn,
            },
            this._appEnvironment.appFacilities
        );

        this.reportSave(queryID);

        return undefined;
    }

    // This is currently only called from the query editor when submitting a
    // query.
    //
    // FIXME: Get rid of this arbitrary limit here - would be nice to support
    // pagination.
    public runQuery(tableName: TableName): void {
        const target = this.getDataRowStoreForTable(tableName, true);
        if (target === undefined) return undefined;
        if (target instanceof ExistingQueryRowDataStore) {
            target.runQuery(new Query(tableName).withLimit(100).serialize(), () => undefined);
        } else {
            target.runQuery();
        }
    }

    public setNumberOfRowsInTable(tableName: TableName, numRows: number): void {
        this._numRowsForTable.get(tableName).current = numRows;
    }

    public makeRowIndexFallback(): string {
        const rowIndex = this._nextRowIndexFallback;
        this._nextRowIndexFallback = nativeTableIndexer.nextNumber(this._nextRowIndexFallback);
        return rowIndex;
    }

    public getNumberOfRowsInTable(tableName: TableName): ChangeObservable<number | undefined> | undefined {
        return this._numRowsForTable.get(tableName);
    }

    public fetchQuery(
        query: Query,
        onChange: () => void,
        forBuilder: boolean
    ): ResolvedGroundValue | LoadingValue | undefined {
        const queryID = this.getQueryIDForTableName(query.tableName);
        if (queryID === undefined) return undefined;

        const dataRowStore = forBuilder
            ? this.builderExistingTables.get(queryID)
            : this.playerExistingTables.get(queryID);
        if (dataRowStore === undefined) return undefined;

        const serialized = query.serialize();

        const result = dataRowStore.runQuery(serialized, onChange);
        if (result === undefined) return result;

        return mapLoadingValue(result, t => {
            assert(isTable(t));
            return query.postProcess(t);
        });
    }

    public getLocallyModifiedRowIDs(tableName: TableName): ReadonlySet<string> {
        return this._locallyModifiedRows.get(tableName);
    }

    private resetQueryByID(queryID: NativeTableID, version: number | undefined, appUserChanged: boolean): void {
        // We always reset for builder and player, even though technically
        // it's not always necessary.  If we didn't do this we'd sometimes
        // have inconsistencies between them which might confuse users.
        for (const forBuilder of [false, true]) {
            const tables = forBuilder ? this.builderExistingTables : this.playerExistingTables;
            const query = tables.get(queryID);
            if (query !== undefined) {
                query.setBuilderInfo(this.getBuilderInfo(forBuilder));
                query.resetAllQueries(version, appUserChanged);
            }
        }
    }

    public resetQueryByTableName(tableName: TableName, version: number | undefined): void {
        const queryID = this.getQueryIDForTableName(tableName);
        if (queryID === undefined) return;

        this.resetQueryByID(queryID, version, false);
    }

    public addQueryableRowsRootFinder(finder: QueryableRowsRootFinder): void {
        this._queryableRowsRootFinders.add(finder);
    }

    public removeQueryableRowsRootFinder(finder: QueryableRowsRootFinder): void {
        this._queryableRowsRootFinders.delete(finder);
    }

    public getRowIDsForTable(tableName: TableName): ReadonlySet<string> {
        const result = new Set<string>();
        for (const finder of this._queryableRowsRootFinders) {
            const rowIDs = finder.getRowIDsForTable(tableName);
            setUnionInto(result, rowIDs);
        }
        return result;
    }

    public get isReadyForGC(): boolean {
        return Array.from(this._queryableRowsRootFinders).every(f => f.isReadyForGC);
    }

    public resetReadyForGC(): void {
        for (const f of this._queryableRowsRootFinders) {
            f.resetReadyForGC();
        }
    }

    public getAreSubscribedQueriesRunning(forBuilder: boolean): ChangeObservable<boolean> {
        return this.getQueriesRunningObservable(forBuilder);
    }

    public getAppUserID(): string | undefined {
        return undefined;
    }
}

export class QueryableLayeredTableKeeper implements TableKeeperStore<TableKeeper> {
    constructor(
        private readonly _queryableDataStore: QueryableDataStore | undefined,
        private readonly _origKeeper: TableKeeperStore<TableKeeper>,
        private readonly _forBuilder: boolean,
        private readonly _shouldQuery: (tn: TableName) => boolean
    ) {}

    public getTableKeeperForTable(tn: TableName): TableKeeper {
        if (!this._shouldQuery(tn)) return this._origKeeper.getTableKeeperForTable(tn);
        const fromQueryable = this._queryableDataStore?.getDataRowStoreForTable(tn, this._forBuilder);
        return fromQueryable ?? this._origKeeper.getTableKeeperForTable(tn);
    }
}

export class MockQueryableDataStore extends TestLocalDataStoreImpl implements QueryableDataStore {
    private areSubscribedQueriesRunning: ChangeObservable<boolean>;
    private queryCache: ArrayMap<SerializedQuery, ResolvedGroundValue | LoadingValue>;

    constructor(appID: string, private tables: ReadonlyArray<TableGlideType>) {
        super(appID, false);

        this.areSubscribedQueriesRunning = new Watchable(false);
        this.queryCache = new ArrayMap((a, b) => deepEqual(a, b));
    }

    setAppUserDataObservable(_observable: ChangeObservable<AppUserData>): void {
        throw new Error("setAppUserDataObservable NOT IMPLEMENTED");
    }

    getDataRowStoreForTable(_tableName: TableName, _forBuilder: boolean): (DataRowStore & TableKeeper) | undefined {
        throw new Error("getDataRowStoreForTable NOT IMPLEMENTED");
    }

    fetchQuery(
        query: Query,
        onChange: () => void,
        _forBuilder: boolean
    ): ResolvedGroundValue | LoadingValue | undefined {
        const tableName = query.tableName.name;
        const serializedQuery = query.serialize();

        if (this.queryCache.has(serializedQuery)) {
            return this.queryCache.get(serializedQuery);
        }

        const maybeTable = this.tables.find(t => getTableName(t).name === tableName);
        if (maybeTable === undefined) {
            // eslint-disable-next-line no-console
            console.error(`Could not find table ${tableName}`);
            return undefined;
        }

        const keeper = this.tableKeeperStore.getTableKeeperForTable(query.tableName) as SimpleTableKeeper;

        const allRows = keeper.table.asArray();
        const response = evaluateQueryForRows(
            serializedQuery,
            maybeTable.columns,
            allRows,
            (r, cn) => r[cn],
            undefined
        );
        if (response instanceof Error) {
            logError(`Evaluate query failed: ${response.message}`);
            return undefined;
        }

        const result = query.postProcess(new Table(response.rows as Row[]));
        setTimeout(() => {
            this.queryCache.set(serializedQuery, result);
            onChange();
        }, 500);

        return makeLoadingValue();
    }

    getLocallyModifiedRowIDs(): ReadonlySet<string> {
        return new Set();
    }

    resetQueryByTableName(_tableName: TableName, _version: number | undefined): void {
        throw new Error("resetQueryByTableName NOT IMPLEMENTED");
    }

    getAreSubscribedQueriesRunning(_forBuilder: boolean): ChangeObservable<boolean> {
        return this.areSubscribedQueriesRunning;
    }

    // Everything that follows is used by the builder only.
    readonly previewSchemaSerial: ChangeObservable<number> = new Watchable<number>(0);

    getNumberOfRowsInTable(_tableName: TableName): ChangeObservable<number | undefined> | undefined {
        throw new Error("getNumberOfRowsInTable NOT IMPLEMENTED");
    }

    getQueryRunningFlag(_tableName: TableName): ChangeObservable<boolean> | undefined {
        throw new Error("getQueryRunningFlag NOT IMPLEMENTED");
    }
    getQueryErrorMessage(_tableName: TableName): ChangeObservable<string | undefined> | undefined {
        throw new Error("getQueryErrorMessage NOT IMPLEMENTED");
    }
    getQueryWarningMessage(_tableName: TableName): ChangeObservable<string | undefined> | undefined {
        throw new Error("getQueryWarningMessage NOT IMPLEMENTED");
    }
    runQuery(_tableName: TableName): void {
        throw new Error("runQuery NOT IMPLEMENTED");
    }

    savePreviewQueryForNew(
        _sourceKind: string,
        _sourceID: string,
        _getOverrideRowID: GetOverrideRowID,
        _onSave?: OnQuerySaveHandler
    ): Promise<TableGlideType | undefined> {
        throw new Error("savePreviewQueryForNew NOT IMPLEMENTED");
    }

    getPreviewTableForNew(_sourceKind: string, _sourceID: string): TableGlideType {
        throw new Error("getPreviewTableForNew NOT IMPLEMENTED.");
    }
    getPreviewTableForExisting(_queryID: string): TableGlideType {
        throw new Error("getPreviewTableForExisting NOT IMPLEMENTED.");
    }

    getPreviewQueryCanSaveFlagForNew(_sourceKind: string, _sourceID: string): ChangeObservable<boolean> {
        throw new Error("getPreviewQueryCanSaveFlagForNew NOT IMPLEMENTED.");
    }
    setPreviewQueryForNew(_sourceKind: string, _sourceID: string, _queryName: string, _queryString: String): void {
        throw new Error("setPreviewQueryForNew NOT IMPLEMENTED.");
    }
    clearPreviewQueryForNew(_sourceKind: string, _sourceID: string): void {
        throw new Error("clearPreviewQueryForNew NOT IMPLEMENTED.");
    }
    getPreviewQueryForNewSavingFlag(_sourceKind: string, _sourceID: string): ChangeObservable<boolean> {
        throw new Error("getPreviewQueryForNewSavingFlag NOT IMPLEMENTED.");
    }

    getPreviewQueryCanSaveForExisting(_queryID: string): ChangeObservable<boolean> {
        throw new Error("getPreviewQueryCanSaveForExisting NOT IMPLEMENTED.");
    }
    setPreviewQueryForExisting(
        _queryID: string,
        _queryName: string,
        _queryString: string,
        _remoteSerial?: number
    ): void {
        throw new Error("setPreviewQueryForExisting NOT IMPLEMENTED.");
    }
    savePreviewQueryForExisting(
        _queryID: string,
        _getOverrideRowID: GetOverrideRowID,
        _onSave?: OnQuerySaveHandler
    ): Promise<TableGlideType | undefined> {
        throw new Error("savePreviewQueryForExisting NOT IMPLEMENTED.");
    }
    getPreviewQueryForExistingSavingFlag(_queryID: string): ChangeObservable<boolean> {
        throw new Error("getPreviewQueryForExistingSavingFlag NOT IMPLEMENTED.");
    }

    rediscoverQuery(
        _tableName: TableName,
        _queryName: string,
        _queryBase: SQLQueryBase,
        _remoteSerial: number
    ): Promise<string | undefined> {
        throw new Error("rediscoverQuery NOT IMPLEMENTED.");
    }

    addQueryableRowsRootFinder(_finder: QueryableRowsRootFinder): void {
        // in practice, the `finder` here is the WireBackend.
        return;
    }
    removeQueryableRowsRootFinder(_finder: QueryableRowsRootFinder): void {
        throw new Error("removeQueryableRowsRootFinder NOT IMPLEMENTED.");
    }
}

export function forceLoadQueryableTable(
    tableName: TableName,
    ds: ExistingQueryRowDataStore | QueryableDataStore,
    limit: number,
    forBuilder: boolean
): GroundValue {
    const query = new Query(tableName).withLimit(limit);

    // Callbacks are cleared when the query returns. Need to rerun the query in the callback to ensure a query for
    // this table is always active. Otherwise, live updates or the reload button doesn't work. This won't kick off
    // a new query it will simply add a callback to the existing query's callbacks set.
    const recursivelyRunQuery: () => GroundValue = () =>
        ds instanceof ExistingQueryRowDataStore
            ? ds.runQuery(query.serialize(), recursivelyRunQuery)
            : ds.fetchQuery(query, recursivelyRunQuery, forBuilder);

    return recursivelyRunQuery();
}

export function unwrapLoadingTable(active: MutableTable | LoadingValue): MutableTable {
    if (!isLoadingValue(active)) return active;

    const unwrapped = unwrapLoadingValue(active);
    if (isLoadingValue(unwrapped) || !isTable(unwrapped)) return new MutableTable();
    return new MutableTable(unwrapped.asArray());
}

export function adjustRequeryLimit(query: SerializedQuery, maxLimit: number): SerializedQuery {
    if (!isLiveUpdateQuery(query)) return query;
    return { ...query, limit: maxLimit };
}

export class RowVersions {
    private readonly _versions = new Map<string, number>();

    update(queryID: string, query: SerializedQuery, newVersion: number | undefined): void {
        if (newVersion === undefined) return;

        const current = this._versions.get(queryID);
        if (current === undefined || isLiveUpdateQuery(query)) {
            this._versions.set(queryID, newVersion);
        }
    }

    makeQuery(queryID: string, query: SerializedQuery, endOfTable: boolean): SerializedQuery {
        if (!this.useRowVersion(query, endOfTable)) return query;

        const rowVersion = this._versions.get(queryID);
        if (rowVersion === undefined) return query;

        return { ...query, rowVersion };
    }

    useRowVersion(query: SerializedQuery, endOfTable: boolean): boolean {
        return getFeatureSetting("gbtLiveUpdateRowVersionQueries") && endOfTable && isRowVersionCapableQuery(query);
    }
}
