const dateTimeSeparatorRegexp = /(.+\d\d)T(\d\d.+)/;

// This is a terrible hack.  We write date/times out to the sheet in
// ISO format, with the GMT time zone, which means they look like this:
//
//   2019-11-20T10:00:12.636Z
//
// We need to not interpret the time zone, however, because we treat
// everything as time-zone agnostic, but Chrono will interpret the time
// zone in that format.  To make it not do that we have to remove the
// "T" and the "Z".
export function sanitizeDateTime(str: string, removeUTCMarker: boolean, removeSeparator: boolean): string {
    if (removeUTCMarker && str.endsWith("Z")) {
        str = str.substring(0, str.length - 1);
    }
    if (removeSeparator) {
        // Safari can't do `new Date(str)` when the separator
        // is removed.
        const match = str.match(dateTimeSeparatorRegexp);
        if (match !== null) {
            str = match[1] + " " + match[2];
        }
    }
    return str;
}

export function makeDateFromString(
    s: string,
    removeUTCMarker: boolean = false,
    useModernFillDate: boolean = false
): Date | undefined {
    s = s.trim();
    if (s === "") return undefined;

    if (removeUTCMarker) {
        // We need this whenever the caller expects the date/time to be
        // interpreted as local time, no matter what.  That's the case
        // in `asDate`, where the date might come with or without the
        // `Z` prefix, but we need to parse it in local time in either
        // case, so `asDate` can safely convert it to time zone agnostic.
        s = sanitizeDateTime(s, true, false);
    }
    let d = new Date(s);
    if (!isNaN(d as any)) {
        return d;
    }

    // This is how Google Sheets represents raw times
    // 1999-12-30T00:00:00.000Z is the optionally used "modern date"
    // 1899-12-30T00:00:00.000Z which results in a strange timezone
    // offset of 36 minutes.
    // https://github.com/glideapps/glide/issues/30199
    const fillDate = useModernFillDate ? "1999-12-30T" : "1899-12-30T";
    d = new Date(fillDate + s + "Z");
    if (!isNaN(d as any)) {
        return d;
    }
    return undefined;
}

export function convertDateToTimeZoneAgnostic(d: Date): Date {
    return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
}

export function convertDateFromTimeZoneAgnostic(d: Date): Date {
    // So, convertDateToTimeZoneAgnostic() properly coerces Dates to UTC.
    // But coercion back to Time Zone Aware across a DST boundary breaks
    // horribly.
    //
    // Consider this example, valid in any US timezone observing DST,
    // happening on the time boundary of a transition into DST (You will
    // have to explicitly set your clock to such a timezone to reproduce this):
    //
    // convertDateFromTimeZoneAgnostic(
    //     convertDateToTimeZoneAgnostic(new Date("2020-03-08 3:00"))
    // ).getHours() === 2 // We expected 3 here
    //
    // We want the UTC hours and UTC minutes of the UTC date to
    // match the non-UTC hours and non-UTC minutes of the converted non-UTC
    // date, but they cannot whenever the UTC offset conversion in
    // convertDateToTimeZoneAgnostic switches time zones by aligning to UTC.
    //
    // What we do know is that whenever this happens, the UTC hours and minutes
    // of the "UTC date" don't match up with the non-UTC hours and minutes of
    // the non-UTC date. To correct for this, we
    // 1. convert the wrong non-UTC date to a wrong UTC date
    // 2. compute the difference between the right UTC date and the wrong UTC date
    // 3. Add that difference to the wrong non-UTC date to get the right non-UTC date.
    //
    // This works because time zones are (currently) only granular in terms of hours
    // and minutes. And we don't have to worry about wrapping around by days either,
    // because worst-case timezone offsets are always less than 24 hours.
    const basis = new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    // All of these `getHours()`, ... methods only work with the local time
    // zone, which is why ##deviceTimeZoneInAgnosticDates don't work.  What
    // might work here is to apply the difference between the local time zone
    // and the target time zone to `basis`.
    if (basis.getHours() === d.getUTCHours() && basis.getMinutes() === d.getUTCMinutes()) {
        return basis;
    }
    const basisAsUTC = Date.UTC(
        basis.getFullYear(),
        basis.getMonth(),
        basis.getDate(),
        basis.getHours(),
        basis.getMinutes(),
        basis.getSeconds()
    );
    return new Date(basis.getTime() + (d.getTime() - basisAsUTC));
}

export function getNowTimeZoneAgnostic(): Date {
    return convertDateToTimeZoneAgnostic(new Date());
}

// This will give the start of the UTC day in the local timezone
export function makeStartOfDay(d: Date): Date {
    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
}

// This will give the start of the UTC day
export function makeStartOfDayUTC(d: Date): Date {
    return new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
}

function getDateString(d: Date): string {
    const asString = d.toISOString();
    return asString.substring(0, asString.indexOf("T"));
}

export function getISODateString(d: Date): string {
    return getDateString(makeStartOfDay(d));
}

// This assumes `d` is time-zone agnostic, and returns a time-zone
// agnostic Date.
export function makeStartOfDayTimeZoneAgnostic(d: Date): Date {
    return convertDateToTimeZoneAgnostic(makeStartOfDay(d));
}

const durationConversionFactor = 24 * 60 * 60 * 1000;

// Glide durations are like Google Sheet durations: numbers
// with unit "day", i.e. 0.5 means 12 hour, 1 means 1 day, etc.
export function convertJSDurationToGlide(jsDuration: number): number {
    return jsDuration / durationConversionFactor;
}

export function convertGlideDurationToJS(glideDuration: number): number {
    return glideDuration * durationConversionFactor;
}

const epochDaySerial = 25569;

// These are for converting from/to Google Sheet day serials,
// which are encoded as the duration since `12/30/1899 0:00:00`.
// Dates are time-zone agnostic.
export function convertDaySerialToDate(daySerial: number): Date | undefined {
    const value = new Date(Math.round((daySerial - epochDaySerial) * durationConversionFactor));
    if (isNaN(value as any)) return undefined;
    return value;
}

export function convertDateToDaySerial(date: Date): number {
    return date.getTime() / durationConversionFactor + epochDaySerial;
}

export function addDaysToDate(daysToAdd: number, startDate: Date | undefined): number {
    const date = startDate ?? new Date();
    return Math.round(date.setDate(date.getDate() + daysToAdd) / 1000);
}

export function parseTimezoneOffsetString(s: string | undefined): number | undefined {
    if (s === undefined) return undefined;
    if (s === "Z") return 0;
    if (!["+", "-"].includes(s[0])) return undefined;

    const parts = s.split(":");
    if (parts.length > 2) return undefined;

    let sign = -1;
    let hours = Number(parts[0].slice(0, 3));
    if (hours < 0) {
        // Intentionally inverted; this is how timezone offsets work
        sign = 1;
        hours = -hours;
    }
    if (!Number.isInteger(hours) || hours > 23) return undefined;

    const minStr = parts[1] ?? parts[0].slice(3, 5);
    if (minStr[0] === "+") return undefined;
    const minutes = minStr === "" ? 0 : Number(minStr); // the spec allows for omitting minutes
    if (!Number.isInteger(minutes) || minutes < 0 || minutes > 59) return undefined;

    return sign * (hours * 60 + minutes);
}
