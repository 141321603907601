import { browserMightBeOniOS } from "./browser-detect";

export enum UrlHandling {
    SetWindowHref = 0,
    UseATag = 1,
    UseATagWithBlank = 2,
}

export function getUrlHandling(url: string, fromJavascript: boolean): UrlHandling {
    url = url.toLowerCase();
    if (
        (url.startsWith("mailto") ||
            url.startsWith("sms") ||
            url.startsWith("googlegmail") ||
            url.startsWith("maps")) &&
        browserMightBeOniOS
    ) {
        return UrlHandling.SetWindowHref;
    }

    if (browserMightBeOniOS) {
        if (fromJavascript) {
            return UrlHandling.SetWindowHref;
        }
        return UrlHandling.UseATagWithBlank;
    }

    if (url.startsWith("http")) {
        return UrlHandling.UseATagWithBlank;
    }
    return UrlHandling.UseATag;
}
