import {
    authThemeAccent,
    authThemeDark,
    authThemeHighlight,
    ResponsiveProvider,
    isSmallScreen,
    useRootResponsiveSizeClass,
} from "@glide/common-components";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { usePlayerEminenceFlags } from "@glide/player-core";
import { useWireAppTheme } from "@glide/wire-renderer";
import classNames from "classnames";
import { styled } from "@glide/common";
import "twin.macro";

interface AuthWrapperProps {
    customCssClassName?: string;
}

const StyledAuthScreenWrapper = styled.div`
    &.auth-theme-dark.mobile {
        background-color: ${p => p.theme.darkerAccent};
    }

    &.auth-theme-accent {
        background-color: ${p => p.theme.primaryAccentColor};
    }
`;

export const AuthWrapper: React.FC<React.PropsWithChildren<AuthWrapperProps>> = props => {
    const { customCssClassName } = props;

    const theme = useWireAppTheme();
    const appId = useAppID();
    const eminenceFlags = usePlayerEminenceFlags(appId ?? "");

    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    let myClass = authThemeHighlight;

    // Using classes here so that the child components can know what the theme is
    // without having to pass it down as a prop
    if (theme.pageTheme === "Dark") {
        if (isMobile) {
            myClass = `${authThemeDark} mobile`;
        } else {
            myClass = authThemeDark;
        }
    } else if (theme.pageTheme === `Highlight`) {
        if (isMobile) {
            myClass = `${authThemeHighlight} mobile`;
        } else {
            myClass = authThemeHighlight;
        }
    } else {
        if (isMobile) {
            myClass = `${authThemeAccent} mobile`;
        } else {
            myClass = authThemeAccent;
        }
    }

    // also make sure the player pluginTier e.g. "pro" and up can customize
    const hasBackgroundImage = typeof theme.signInBackground === "string" && eminenceFlags.canCustomizeSignIn;
    return (
        <StyledAuthScreenWrapper
            style={
                hasBackgroundImage
                    ? {
                          backgroundImage: `url(${massageImageUrl(
                              theme.signInBackground,
                              {
                                  width: 1920,
                                  thumbnail: false,
                              },
                              appId ?? "unknown"
                          )})`,
                      }
                    : undefined
            }
            className={classNames(myClass + " " + customCssClassName, hasBackgroundImage && "has-background-image")}
            tw="w-full h-full flex flex-col justify-center items-center bg-cover bg-center [&.auth-theme-highlight]:bg-n100 [&.auth-theme-highlight.mobile]:bg-bg-front  [&.auth-theme-dark]:bg-bg-front">
            <div tw="[flex-grow: 7]"></div>
            <ResponsiveProvider tw="w-full">{props.children}</ResponsiveProvider>
            <div tw="[flex-grow: 10]"></div>
        </StyledAuthScreenWrapper>
    );
};
