// FIXME: Is this going to break things?
import type { LocationSettings } from "@glide/location-common";
import { exceptionToString, sleep } from "@glideapps/ts-necessities";
import {
    QuadraticBackoffController,
    getCurrentTimestampInMilliseconds,
    logError,
    maybe,
    optimalInitialWindowForHoldingTimeFunction,
} from "@glide/support";
import { getFeatureFlag } from "./feature-flags";
import { getFeatureSetting, getFeatureSettingProbability } from "./feature-settings";
import { setCloudRegion } from "./function-region";
import { generateFirestoreDocumentID } from "./id-generator";
import { frontendSendEvent, frontendTrace } from "./tracing";

type Superfunction =
    | "builderFunctionSmall"
    | "builderFunctionLarge"
    | "playerFunctionSmall"
    | "playerFunctionLarge"
    | "playerFunctionCritical";

const functionDispatchTable: Record<string, Superfunction> = {
    acquireStripeSession: "builderFunctionSmall",
    acquireStripeSessionForProPurchase: "builderFunctionSmall",
    acquireStripeSessionForTemplatePurchase: "builderFunctionSmall",
    addTableToApp: "builderFunctionLarge",
    linkTablesToApp: "builderFunctionSmall",
    removeTableFromApp: "builderFunctionSmall",
    removeGoogleSheetFromApp: "builderFunctionSmall",
    duplicateTableInApp: "builderFunctionSmall",
    getPluginsMetadata: "builderFunctionSmall",
    triggerAutomation: "builderFunctionSmall",
    cancelAutomationRun: "builderFunctionSmall",
    syncTables: "builderFunctionSmall",
    deleteTable: "builderFunctionSmall",
    listTables: "builderFunctionSmall",
    setBoostsForOwner: "builderFunctionSmall",
    updateUnifiedPaymentInformation: "builderFunctionSmall",
    checkDomainConfigured: "builderFunctionSmall",
    createOrganization: "builderFunctionSmall",
    editOrganizationSettings: "builderFunctionSmall",
    getAutomationsRunData: "builderFunctionSmall",
    getAutomationRun: "builderFunctionSmall",
    getAutomationRunStatusForPolling: "builderFunctionSmall",
    getAutomationRuns: "builderFunctionSmall",
    getAutomationUsage: "builderFunctionSmall",
    getActionsStatuses: "builderFunctionSmall",
    generateOrgIconUploadSignature: "builderFunctionSmall",
    deleteOrganization: "builderFunctionSmall",
    setOrgAdmin: "builderFunctionSmall",
    optIntoBeta: "builderFunctionSmall",
    getConfiguredPluginsForApp: "builderFunctionSmall",
    acceptOrganizationInvite: "builderFunctionSmall",
    generateAppFromDescription: "builderFunctionLarge",
    customComponentProxy: "builderFunctionSmall",
    customComponentRequestsProxy: "builderFunctionSmall",
    getOAuth2TokensForGoogleSheets: "builderFunctionSmall",
    getOrganizationBilling: "builderFunctionSmall",
    getOrganizationMembers: "builderFunctionSmall",
    setOrganizationMemberNotificationsSettings: "builderFunctionSmall",
    getDashboardData: "builderFunctionSmall",
    getOrgUsages: "builderFunctionSmall",
    getQuotaStateForApp: "playerFunctionCritical",
    getOrganizationMemberIntegrations: "builderFunctionSmall",
    getOrganizationPersistentInviteLink: "builderFunctionSmall",
    getOrganizationPlans: "builderFunctionSmall",
    getFeaturesForOrg: "builderFunctionSmall",
    getUnsplashImages: "builderFunctionSmall",
    integrateWithStripe: "builderFunctionSmall",
    integrateWithMicrosoft: "builderFunctionSmall",
    integrateWithAirtable: "builderFunctionSmall",
    convertAuthToCustomToken: "builderFunctionSmall",
    inviteToOrganization: "builderFunctionSmall",
    modifySyntheticColumns: "builderFunctionLarge",
    moveTableInSchema: "builderFunctionSmall",
    deleteAllRowsInTable: "builderFunctionSmall",
    moveOrgInUser: "builderFunctionSmall",
    sendAppMagicLinks: "builderFunctionSmall",
    getAppMagicLinks: "builderFunctionSmall",
    privateMagicLink: "builderFunctionSmall",
    getTemplatePreviewUrl: "builderFunctionSmall",
    pingUnsplashDownload: "builderFunctionSmall",
    acceptPluginAuthCode: "builderFunctionSmall",
    acceptPluginAuthCodeForOwner: "builderFunctionSmall",
    removeFromOrganization: "builderFunctionSmall",
    setCustomDomain: "builderFunctionSmall",
    setEmailOwnersColumns: "builderFunctionSmall",
    setOrUpdateUser: "builderFunctionSmall",
    storeAppDevicePreference: "builderFunctionSmall",
    setOrgOnboarding: "builderFunctionSmall",
    isEmailSignedUp: "builderFunctionSmall",
    setShortName: "builderFunctionSmall",
    isPersonalEmail: "builderFunctionSmall",
    enrollExpert: "builderFunctionSmall",
    checkShortName: "builderFunctionSmall",
    setPublishingActive: "builderFunctionSmall",
    discardPausedAppChanges: "builderFunctionSmall",
    transferAppBetweenUsers: "builderFunctionSmall",
    transferAppToOrganization: "builderFunctionSmall",
    checkExternalDataSourceExists: "builderFunctionSmall",
    uploadImg: "builderFunctionSmall",
    createAppFromTemplate: "builderFunctionLarge",
    createTemplateFromApp: "builderFunctionLarge",
    deleteApp: "builderFunctionLarge",
    unpublishApp: "builderFunctionLarge",
    duplicateApp: "builderFunctionLarge",
    duplicateClassicAppAsPage: "builderFunctionLarge",
    duplicateClassicAppAnalysis: "builderFunctionLarge",
    addTemplateTabToApp: "builderFunctionLarge",
    submitTemplate: "builderFunctionLarge",
    shadowPublishTemplate: "builderFunctionLarge",
    generatePublishedAppDataFromSheet: "builderFunctionLarge",
    reloadPublishedAppDataFromSheet: "builderFunctionLarge",
    prepareReplaceGoogleSheetInApp: "builderFunctionLarge",
    prepareRemoveGoogleSheetInApp: "builderFunctionLarge",
    exportAppData: "builderFunctionLarge",
    exportQueryableTable: "builderFunctionSmall",
    deleteAppUserForApp: "builderFunctionLarge",
    newGlideTableApp: "builderFunctionLarge",
    newApp: "builderFunctionLarge",
    authorizeUserForApp: "playerFunctionCritical",
    reportBillable: "playerFunctionCritical",
    geocodeAddresses: "playerFunctionSmall",
    getAppUserForAuthenticatedUser: "playerFunctionSmall",
    getCustomTokenForApp: "playerFunctionCritical",
    getPasswordForEmailPin: "playerFunctionCritical",
    getPasswordForOAuth2Token: "playerFunctionCritical",
    inAppPurchase: "playerFunctionSmall",
    registerForPushNotifications: "playerFunctionSmall",
    requestAppUserAccess: "playerFunctionSmall",
    requestAppUserAccessApproval: "builderFunctionSmall",
    reportGeocodesInApp: "playerFunctionSmall",
    writeActionLog: "playerFunctionSmall",
    sendPinForEmail: "playerFunctionSmall",
    sendActionErrorEmail: "playerFunctionSmall",
    stripeInAppPurchaseConfirmIntent: "playerFunctionSmall",
    uploadAppFileV2: "playerFunctionLarge",
    getAppSnapshot: "playerFunctionCritical",
    ensureDataLiveliness: "playerFunctionSmall",
    triggerZap: "playerFunctionSmall",
    transcribeAudio: "playerFunctionSmall",
    deliverEmailFromAction: "playerFunctionSmall",
    testIframeEmbeddable: "playerFunctionSmall",
    getPreviewAsUser: "builderFunctionSmall",
    sendAppFeedback: "playerFunctionSmall",
    reportApp: "playerFunctionSmall",
    appBeacon: "playerFunctionSmall",
    triggerAppWebhookAction: "playerFunctionSmall",
    callAPIColumn: "playerFunctionSmall",
    enqueueDeleteRows: "playerFunctionSmall",
    getSimpleValue: "playerFunctionSmall",
    renameTable: "builderFunctionSmall",
    renameOrganization: "builderFunctionSmall",
    getOrgFolders: "builderFunctionSmall",
    addOrgFolder: "builderFunctionSmall",
    renameOrgFolder: "builderFunctionSmall",
    deleteOrgFolder: "builderFunctionSmall",
    moveOrgFolder: "builderFunctionSmall",
    moveAppIntoFolder: "builderFunctionSmall",
    applyPromoCode: "builderFunctionSmall",
    makeSupportCodeForApp: "builderFunctionSmall",
    accessSupportCode: "builderFunctionSmall",
    setAdditionalBillingInfo: "builderFunctionSmall",
    getTaxInfo: "builderFunctionSmall",
    setTaxInfo: "builderFunctionSmall",
    deleteTaxInfo: "builderFunctionSmall",
    requestDownloadLinkForExport: "builderFunctionSmall",
    requestDataExport: "builderFunctionSmall",
    addWebhook: "builderFunctionSmall",
    createAndSendAuthLink: "builderFunctionSmall",
    initFreeTrials: "builderFunctionSmall",
    sendEmailVerification: "builderFunctionSmall",
    recoverHubspotContactFromWaitingArea: "builderFunctionSmall",
    storeChurnSurvey: "builderFunctionSmall",
    finishOnboarding: "builderFunctionSmall",
    checkReverseFreeTrialEligibility: "builderFunctionSmall",
    maybeCreateReverseFreeTrial: "builderFunctionSmall",
    endReverseFreeTrial: "builderFunctionSmall",
    requestTrialExtension: "builderFunctionSmall",
    getSuggestedTemplates: "builderFunctionSmall",
    reportQuotaIncreases: "playerFunctionSmall",
    setAppIsFavorite: "builderFunctionSmall",
    callOpenWeatherMapApi: "builderFunctionSmall",
    getAirtableBases: "builderFunctionSmall",
    getExcelWorkbooks: "builderFunctionSmall",
    getAvailableMicrosoftDrives: "builderFunctionSmall",
    removeMSAccount: "builderFunctionSmall",
    getGlideSubscription: "builderFunctionSmall",
    subscribeToAddons: "builderFunctionLarge",
    getAllSubscriptionsForUser: "builderFunctionSmall",
    subscribeToPlan: "builderFunctionLarge",
    cancelAppPlan: "builderFunctionLarge",
    getOwnerEminence: "builderFunctionSmall",
    getV4StripeCheckoutSession: "builderFunctionSmall",
    v4PricingTable: "builderFunctionSmall",
    getOrganizationFromCheckoutSession: "builderFunctionSmall",
    getV4StripeCustomerPortal: "builderFunctionSmall",
    v4AppTransferCheck: "builderFunctionSmall",
    setPayAsYouGo: "builderFunctionSmall",
    getAppEminence: "playerFunctionCritical",
    previewAddonCharges: "builderFunctionSmall",
    getSubscriptionVerificationSecret: "builderFunctionSmall",
    deleteAccount: "builderFunctionLarge",
    publishTemplateForScreenshot: "builderFunctionLarge",
    captureScreenshot: "builderFunctionLarge",
    privateTemplatesAccess: "builderFunctionLarge",
    sendHubspotCustomEvent: "builderFunctionSmall",
    loadBuilderActions: "builderFunctionSmall",
    getLatestPriceForAddon: "builderFunctionSmall",
    getQuotaStateForOrg: "builderFunctionSmall",
    getOrganizationUsage: "builderFunctionSmall",
    getLoginLogs: "builderFunctionSmall",
    getOrgLoginLogs: "builderFunctionSmall",
    getOrgMostRecentLoginLogs: "builderFunctionSmall",
    getAppModifications: "builderFunctionSmall",
    getOrgEditorCount: "builderFunctionSmall",
    getAppDataForOrg: "builderFunctionSmall",
    getQuotasForApps: "builderFunctionSmall",
    createNativeTableForImport: "builderFunctionSmall",
    importRowsIntoNewNativeTable: "builderFunctionSmall",
    importRowsIntoNativeTable: "builderFunctionSmall",
    delistTemplate: "builderFunctionSmall",
    getAppDescription: "builderFunctionSmall",
    storeAppDescription: "builderFunctionSmall",
    previewQueryDataSource: "builderFunctionSmall",
    continuePreviewQueryDataSource: "builderFunctionSmall",
    saveQuery: "builderFunctionSmall",
    executeQuery: "playerFunctionLarge",
    continueQuery: "playerFunctionLarge",
    checkQueryTableVersions: "playerFunctionSmall",
    loadQueries: "builderFunctionSmall",
    integrateWithGCP: "builderFunctionSmall",
    listBigQueryProjectIDs: "builderFunctionSmall",
    listBigQueryDatasetIDs: "builderFunctionSmall",
    setupBigQueryDataSource: "builderFunctionSmall",
    enqueueDataAction: "playerFunctionSmall",
    enqueueDataActionBatch: "playerFunctionSmall",
    getLongHTTPFunctionStatus: "builderFunctionSmall",
    runIntegrations: "playerFunctionCritical",
    fetchAsyncEnum: "builderFunctionSmall",
    validatePlugin: "builderFunctionSmall",
    completePlugin: "builderFunctionSmall",
    triggerRemovePlugin: "builderFunctionSmall",
    validateAppPlugins: "builderFunctionSmall",
    setPluginSecret: "builderFunctionSmall",
    duplicatePluginSecret: "builderFunctionSmall",
    getPluginSecret: "builderFunctionSmall",
    getPluginTriggerNotes: "builderFunctionSmall",
    getCompiledCustomCss: "builderFunctionSmall",
    addMemberIntegrationToOrg: "builderFunctionSmall",
    getAppActionLogs: "builderFunctionSmall",
    writeBackPluginActionResults: "playerFunctionSmall",
    sendFrontendPushNotification: "playerFunctionSmall",
    getAvailableOwnerOAuth: "builderFunctionSmall",
    updateOwnerOAuthDisplayName: "builderFunctionSmall",
    removeOwnerOAuthClaim: "builderFunctionSmall",
    setupSQLDataSource: "builderFunctionSmall",
    listSQLDataSourceTables: "builderFunctionSmall",
    createNativeTablesFromGoogleSheets: "builderFunctionSmall",
    authenticateIntercom: "builderFunctionSmall",
    updateIntercomUser: "builderFunctionSmall",
    getDataSourceWarnings: "builderFunctionSmall",
    getClientDeploymentVersion: "builderFunctionSmall",
    getClientDeploymentVersionForPlayer: "playerFunctionSmall",
    build: "builderFunctionSmall",
    runCLIRoutine: "builderFunctionSmall",
};

const defaultIdempotentCallHeaderTimeout = 12_000; // Same as Honeycomb, which seems to be more reliable than 10...
// See `app/src/lib/file-import.ts` for a rationale behind the extra 20 seconds.
const importRowsHeaderTimeout = 20_000 + defaultIdempotentCallHeaderTimeout;

const idempotentFunctions = new Map<keyof typeof functionDispatchTable, number>([
    ...[
        "getAppEminence",
        "ensureDataLiveliness",
        "getAppSnapshot",
        "getCustomTokenForApp",
        // The name of this one is a bit of a misnomer. It's not the actual
        // upload, it's just a control endpoint for file uploads. And it's
        // also idempotent by design. If a network connection fails while
        // attempting to get a valid upload destination, then GCS will deallocate
        // the upload destination automatically after some period of time, and it
        // will be like the upload was never attempted in the first place.
        "uploadAppFileV2",
        // Not really, but we have a cleanup mechanism that makes this _seem_ idempotent.
        "createNativeTableForImport",
        "listBigQueryProjectIDs",
        "listBigQueryDatasetIDs",
        // Enqueueing data actions has been idempotent for a long time.
        "enqueueDataAction",
        // As long as the client manages the idempotency key for triggerAppWebhookAction,
        // the behavior is idempotent.
        "triggerAppWebhookAction",
        "getAvailableOwnerOAuth",
    ].map(f => [f, defaultIdempotentCallHeaderTimeout] as [string, number]),
    ...["importRowsIntoNewNativeTable", "importRowsIntoNativeTable"].map(
        f => [f, importRowsHeaderTimeout] as [string, number]
    ),
]);

const expectedConcurrency = 100;
const holdTime = 50;
const maxRetries = 6;
const baseBackoffWindow = optimalInitialWindowForHoldingTimeFunction(holdTime, maxRetries)(expectedConcurrency);
const queueDropAdditionalBackoffTimes = [3, 12, 20];

function additionalBackoffTimeForQueueDrop(attempt: number): number {
    return queueDropAdditionalBackoffTimes[Math.min(attempt, queueDropAdditionalBackoffTimes.length - 1)];
}

function statusIndicatesQueueDrop(status: number): boolean {
    return status === 503;
}

enum OriginKind {
    Same = "same",
    Cross = "cross",
    Container = "container",
    Api = "api",
}

// We need to hook all HTTP calls to Cloud Functions to determine
// whether or not we're suffering from network connectivity issues.
// Unfortunately, we react to network connectivity issues in
// app/src/webapp, upon which we cannot depend in app/src/common.
// So instead, someone has to register the reaction from the outside world.
type NetworkErrorStateHandler = (locationSettings: LocationSettings, functionName: string) => void;
const onNetworkError = new Set<NetworkErrorStateHandler>();
const onNetworkSuccess = new Set<NetworkErrorStateHandler>();

export function addOnFunctionNetworkError(handler: NetworkErrorStateHandler): void {
    onNetworkError.add(handler);
}

export function addOnFunctionNetworkSuccess(handler: NetworkErrorStateHandler): void {
    onNetworkSuccess.add(handler);
}

function runHandlers(
    locationSettings: LocationSettings,
    functionName: string,
    handlers: Iterable<NetworkErrorStateHandler>
): void {
    for (const handler of handlers) {
        maybe(() => handler(locationSettings, functionName), undefined);
    }
}

const defaultCallHeaderTimeout = 125_000;
const builderCallHeaderTimeout = 300_000;
const playerBodyReadTimeout = defaultCallHeaderTimeout; // Clients can be slow sometimes

function getXGlideAnonymousUserHeader(): string {
    // We don't have this server side
    if (typeof document === "undefined") return "";

    // there is a cookie that might be set on the documnet which is called 'anonymous_user' and is formatted as
    // encodeURIComponenot('${userID}|${teamID}|${apiKey}'). If that cookie is present we want to return an unencoded
    // version of it.

    const cookie = document.cookie.split(";").find(c => c.trim().startsWith("anonymous_user="));
    if (cookie === undefined) return "";
    return decodeURIComponent(cookie.split("=")[1]);
}

function isFormData(body: any): body is FormData {
    // `FormData` is not available in Node.js, so we need to check for it.
    try {
        return body instanceof FormData;
    } catch {
        return false;
    }
}

function singleFetchNoRetry(
    functionName: string,
    url: string,
    headers: Record<string, string>,
    body: any,
    originKind: OriginKind,
    superFunction: string | undefined,
    timeout: number,
    attempt: number,
    requestID: string,
    method: "POST" | "GET"
): Promise<Response | undefined> {
    let callURL: string = url;
    try {
        const urlObj = new URL(url.startsWith("/") ? `${window.location.origin}${url}` : url);
        // Setting the query parameter on cross-origin requests will force a new
        // CORS OPTIONS every time, so we'll only do it in development and on
        // same-origin requests.
        if (urlObj.host === window.location.host || urlObj.hostname === "localhost") {
            urlObj.searchParams.set("reqid", requestID);
            callURL = urlObj.href;
        }
    } catch {
        // Oh well, we tried.
    }

    return frontendTrace(
        "function",
        {
            function: functionName,
            url,
            origin: originKind,
            superfunction: superFunction,
            attempt,
            request_id: requestID,
        },
        async fields => {
            // This is a bit of a legacy testing hack; uploadAppFileV2 has always had a retry loop,
            // but now the retry loop is being handled in here.
            if (
                functionName === "uploadAppFileV2" &&
                getFeatureFlag("injectFileUploadFaults") &&
                Math.random() < 0.25
            ) {
                return undefined;
            }

            // FIXME: We need a timeout for the body too!
            // This needs to be provided by callers, as well.
            const abortController = new AbortController();
            const timeoutHandle = setTimeout(() => abortController.abort(), timeout);

            const anonymousUserHeader = getXGlideAnonymousUserHeader();

            try {
                const contentTypeHeader = isFormData(body)
                    ? undefined
                    : {
                          "Content-Type": "application/json",
                      };

                const response = await fetch(callURL, {
                    method,
                    body,
                    headers: {
                        ...contentTypeHeader,
                        "X-Glide-Attempt": attempt.toString(),
                        "Fly-Customer-Request-Id": requestID,
                        "X-Glide-Anonymous-User": anonymousUserHeader,
                        ...headers,
                    },
                    signal: abortController.signal,
                    mode: url.startsWith("/") ? "same-origin" : "cors",
                    // We use the Authorization header with a custom `Bearer` token
                    // as credentials, and don't need to send cookies, custom TLS certificates,
                    // or browser-based passwords. Keeping cookies out of Cloud Functions
                    // requests is important to work around adblockers that don't like Google Analytics,
                    // or any other marketing-based tracking cookies, to show up in `fetch` calls.
                    credentials: "omit",
                    cache: "no-store",
                });
                clearTimeout(timeoutHandle);

                fields.status = response.status;
                fields.success = response.ok;

                if (superFunction?.startsWith("player") === true) {
                    // Make sure we don't leak body reads in the player.
                    async function ensureBodyUsed() {
                        const timeoutTime = getCurrentTimestampInMilliseconds() + playerBodyReadTimeout;
                        while (!response.bodyUsed && getCurrentTimestampInMilliseconds() < timeoutTime) {
                            await sleep(1_000);
                        }
                        if (response.bodyUsed) return;
                        logError("We didn't read a body for", functionName);
                        frontendSendEvent(
                            "function body abandoned",
                            getCurrentTimestampInMilliseconds() - timeoutTime,
                            {
                                function: functionName,
                                origin: originKind,
                                superfunction: superFunction,
                                attempt,
                                request_id: requestID,
                            }
                        );
                        abortController.abort();
                    }
                    void ensureBodyUsed();
                }

                return response;
            } catch (e: unknown) {
                // FIXME: Appropriately handle exceptions here
                try {
                    // On the backend this will throw because `DOMException`
                    // doesn't exist.
                    if (!(e instanceof DOMException) || e.code !== e.ABORT_ERR) {
                        logError("In callCloudFunction", functionName, e);

                        fields.exception = exceptionToString(e);
                    }
                } catch {
                    logError("In callCloudFunction", functionName, e);
                    fields.exception = exceptionToString(e);
                }
                fields.success = false;
                fields.fetch_repr = maybe(() => window.fetch.toString().substring(0, 64), undefined);

                return undefined;
            }
        },
        "always",
        // Trace sending is retryable if the status is actually a number, or we have an exception.
        // This is to say, we don't retry sending timed out traces.
        fields => typeof fields.status === "number" || typeof fields.exception === "string"
    );
}

function isProxyEnabled(locationSettings: LocationSettings, superFunction: string): boolean {
    if (locationSettings.isDevelopment) {
        return getFeatureFlag("useFunctionsProxy");
    }

    const flagOrSetting =
        superFunction === "builderFunctionSmall" || superFunction === "builderFunctionLarge"
            ? "useBuilderFunctionsProxy"
            : "useFunctionsProxy";

    return getFeatureFlag(flagOrSetting) || Math.random() < getFeatureSettingProbability(flagOrSetting);
}

export function urlForCloudFunction(
    locationSettings: LocationSettings,
    functionName: string,
    disableProxy: boolean = false
): { url: string; originKind: OriginKind; superFunction: Superfunction | undefined } {
    const path = functionName.split("/");
    const superFunction = functionDispatchTable[path[0]];
    if (superFunction !== undefined) {
        functionName = `${superFunction}/${functionName}`;
    }

    let originKind = OriginKind.Container;
    let callFunctionURL = `${locationSettings.containerURLPrefix}/${functionName}`;

    // On short names and custom domains, we want to avoid the overhead of a CORS request
    // and also reduce RTT for non-US connections (this is especially important for setting up new TLS connections).
    // To facilitate this, we may proxy all functions requests through /api/ to Cloud Functions or http containers
    if (isProxyEnabled(locationSettings, superFunction) && !disableProxy) {
        // ##relativeFunctionURLs:
        // Note the relative URL. Our origin should always be to the edge now that Hosting is gone,
        // so this should always work.
        callFunctionURL = `/api/container/${functionName}`;
        originKind = OriginKind.Api;
    }

    return { url: callFunctionURL, originKind, superFunction };
}

function statusNeedsRetry(s: number): boolean {
    if (s === 0) return true;
    const statusClass = Math.floor(s / 100);
    return statusClass === 5;
}

function getCallHeaderTimeout(superFunction: Superfunction | undefined, functionName: string): number {
    const timeoutForIdempotentFunction = idempotentFunctions.get(functionName);
    if (timeoutForIdempotentFunction !== undefined) return timeoutForIdempotentFunction;
    return superFunction === "builderFunctionSmall" || superFunction === "builderFunctionLarge"
        ? builderCallHeaderTimeout
        : defaultCallHeaderTimeout;
}

export interface CallCloudFunctionOptions {
    readonly disableProxy: boolean;
    readonly failImmediately: boolean;
}

export async function callCloudFunction(
    locationSettings: LocationSettings,
    functionName: string,
    body: any,
    headers: { [key: string]: string } = {},
    stringify: boolean = true,
    method: "POST" | "GET" = "POST",
    { disableProxy = false, failImmediately = false }: Partial<CallCloudFunctionOptions> = {}
): Promise<Response | undefined> {
    const requestBody = stringify ? JSON.stringify(body) : body;
    const { url, originKind, superFunction } = urlForCloudFunction(locationSettings, functionName, disableProxy);

    const shouldRetry = !failImmediately && idempotentFunctions.has(functionName);
    const maxConnectionAttempts = shouldRetry ? maxRetries : 1;
    const backoff = new QuadraticBackoffController(baseBackoffWindow);
    const callHeaderTimeout = getCallHeaderTimeout(superFunction, functionName);
    const requestID = generateFirestoreDocumentID();

    for (let i = 1; i <= maxConnectionAttempts; i++) {
        const response = await singleFetchNoRetry(
            functionName,
            url,
            headers,
            requestBody,
            originKind,
            superFunction,
            callHeaderTimeout,
            i,
            requestID,
            method
        );
        let additionalSleepTime = 0;
        if (response !== undefined) {
            setCloudRegion(response.headers.get("x-fly-region") ?? undefined);
            // Successful responses can return early, no matter what.
            // We consider 4xx response statuses to be successful; only 5xx
            // and dropped connections are unsuccessful.
            if (!statusNeedsRetry(response.status)) {
                runHandlers(locationSettings, functionName, onNetworkSuccess);
                return response;
            }
            if (statusIndicatesQueueDrop(response.status) && getFeatureSetting("extraBackoffTimeForQueueDrops")) {
                additionalSleepTime = additionalBackoffTimeForQueueDrop(i - 1);
            }
        }

        if (i === maxConnectionAttempts) {
            // If we've gotten to this point, it's a network error.
            runHandlers(locationSettings, functionName, onNetworkError);
            return response;
        }
        if (response !== undefined) {
            // We need to retry the status, and we're about to do so. We need to "drain"
            // the response so that we don't leak a connection.
            response.text().catch(() => {
                // But we really don't care about the resulting error.
            });
        }

        const sleepTime = backoff.getWaitTime() + additionalSleepTime;
        await sleep(sleepTime);
    }

    runHandlers(locationSettings, functionName, onNetworkError);
    return undefined;
}
