import { ActionKind } from "@glide/common-core/dist/js/database-strings";
import type { TableGlideType } from "@glide/type-schema";
import type { TableMutation } from "@glide/common-core/dist/js/firebase-function-types";
import type { JSONObject } from "@glide/support";

import type { Examples, PropsStrict } from "../types";
import {
    makeAddRowMutation,
    makeDeleteRowMutation,
    makeQueryTableBody,
    makeSetColumnsMutation,
    makeTableMutationBody,
} from "./common";

function makeCURLForAPI(url: string, apiKey: string, body: JSONObject, topComment?: string): string {
    let comment = "";
    if (topComment !== undefined) {
        comment = `# ${topComment}\n`;
    }
    return `${comment}curl --request POST '${url}' \\
  --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${apiKey}' \\
  --data-raw '${JSON.stringify(body, undefined, 2)}'`;
}

function makeCURL(props: PropsStrict, endpoint: string, mutate: (table: TableGlideType) => TableMutation): string {
    return makeCURLForAPI(
        props.endpoint(endpoint),
        props.apiKey,
        makeTableMutationBody(props.appID, mutate(props.table))
    );
}

const curlExamples: Examples = {
    ["Get rows"]: {
        title: "Get rows",
        render: props =>
            makeCURLForAPI(
                props.endpoint("queryTables"),
                props.apiKey,
                makeQueryTableBody(props.appID, props.table),
                "Get rows requires Business plan or above"
            ),
    },
    [ActionKind.AddRowToTable]: {
        title: "Add rows",
        render: props => makeCURL(props, "mutateTables", makeAddRowMutation),
    },
    [ActionKind.SetColumnsInRow]: {
        title: "Edit rows",
        render: props => makeCURL(props, "mutateTables", makeSetColumnsMutation),
    },
    [ActionKind.DeleteRow]: {
        title: "Delete rows",
        render: props => makeCURL(props, "mutateTables", makeDeleteRowMutation),
    },
};

export const curl = { name: "curl", examples: curlExamples, syntax: "bash" };
