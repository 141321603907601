import {
    type AutomationRootNode,
    type ConditionalActionNode,
    type FlowActionNode,
    type InlineComputation,
    LoopSourceKind,
    ActionNodeKind,
    type AppDescription,
    type BuilderAction,
    type ActionDescription,
    type ArrayTransform,
    type ColumnAssignment,
    type ComponentDescription,
    type EditScreenDescription,
    type PropertyDescription,
    type ScreenDescription,
    ActionKind,
    ArrayTransformKind,
    MutatingScreenKind,
    PropertyKind,
    ScreenDescriptionKind,
    getInlineComputationProperty,
    makeSourceColumnProperty,
    getFormulaProperty,
    getActionProperty,
    getArrayProperty,
    getCompoundActionProperty,
    getScreenProperty,
    getSwitchProperty,
    isPropertyDescription,
} from "@glide/app-description";
import type { ComponentIndexes } from "@glide/common-core/dist/js/component-indexes";
import { getAppTabs } from "@glide/common-core/dist/js/components/SerializedApp";
import {
    type TableName,
    isTableName,
    type Description,
    type Formula,
    type TableColumn,
    type TableGlideType,
    getTableColumn,
    getTableName,
    makeTableRef,
} from "@glide/type-schema";
import { commentsTableName } from "@glide/common-core/dist/js/database-strings";
import {
    type InputOutputTables,
    doesMutatingScreenAddRows,
    getScreenComponents,
    isClassOrArrayScreenDescription,
    makeInputOutputTables,
} from "@glide/common-core/dist/js/description";
import {
    type ActionPropertyDescriptor,
    type ActionPropertyDescriptorCase,
    type AllowedDataEdits,
    type AppDescriptionContext,
    type ArrayPropertyDescriptorCase,
    type ComponentDescriptor,
    type EditedColumnsAndTables,
    type PropertyDescriptor,
    type PropertyDescriptorCase,
    type TransformsPropertyDescriptorCase,
    type SuperPropertySection,
    type ActionNodeInScope,
    type ExistingAppDescriptionContext,
    isMultiCasePropertyDescriptor,
    addClassScreenName,
    arrayScreenName,
    classScreenName,
    editClassScreenName,
    getMutatingKindForScreen,
    isDefaultArrayScreenName,
    isFreeScreen,
    isNamedPropertySource,
    userProfileScreenName,
    PropertySection,
} from "@glide/function-utils";
import type { Result, SerializablePluginMetadata } from "@glide/plugins";
import { isArray } from "@glide/support";
import {
    DefaultMap,
    assert,
    assertNever,
    defined,
    definedMap,
    proveNever,
    filterUndefined,
} from "@glideapps/ts-necessities";
import { iterableEnumerate } from "collection-utils";
import { handlerForActionKind } from "../actions";
import { handlerForArrayScreenFormat } from "../array-screens";
import {
    type ActionsWithDescriptors,
    getColumnAssignments,
    getPropertyAndDescriptorFromDescription,
    inputOutputTablesForClassOrArrayScreen,
    makeInputOutputTablesForFormOnSubmitAction,
} from "../description-utils";
import { handlerForComponentKind } from "../handlers";
import { getTargetForLink } from "../link-columns";
import type { StaticActionContext } from "../static-context";
import { getSubComponents, getSubComponentsWithTables } from "../sub-components";
import { getUserProfileDescriptors } from "../user-profile";
import { getUserProfileTableInfo } from "../user-profile-info";
import { getActionsWithDescriptorsForArrayContent, getActionsWithDescriptorsForComponent } from "./component-utils";
import { walkActionNode } from "./walk-action";
import type { PriorStep } from "../prior-step";
import type { PreprocessedAction } from "../actions/automation-types";
import { getColumnsUsedInFormula } from "../computed-columns";

export enum LocationKind {
    Tab,
    Screen,
    Component,
    ScreenColumnAssignment,
    Action,
    UserProfile,
    Column,
    PluginConfig,
}

interface TabLocation {
    readonly kind: LocationKind.Tab;
    readonly index: number;
    readonly descriptorCase: PropertyDescriptorCase | undefined;
}

interface ScreenLocation {
    readonly kind: LocationKind.Screen;
    readonly screenName: string;
    readonly mutatingScreenKind: MutatingScreenKind | undefined;
    readonly tabIndex: number | undefined;
    readonly propertySection: PropertySection | undefined;
    readonly descriptorCase: PropertyDescriptorCase | undefined;
}

interface ComponentLocation {
    readonly kind: LocationKind.Component;
    readonly screenName: string;
    readonly mutatingScreenKind: MutatingScreenKind | undefined;
    readonly tabIndex: number | undefined;
    readonly componentID: string;
    readonly componentIndexes: ComponentIndexes;
    readonly componentDisplayName: string;
    readonly componentIsGlideManaged: boolean;
    readonly descriptorCase: PropertyDescriptorCase | undefined;
}

interface ScreenColumnAssignmentLocation {
    readonly kind: LocationKind.ScreenColumnAssignment;
    readonly screenName: string;
    readonly mutatingScreenKind: MutatingScreenKind | undefined;
    readonly tabIndex: number | undefined;
    readonly tableName: TableName;
    readonly columnName: string;
    readonly assignmentIndex: number;
    readonly descriptorCase: PropertyDescriptorCase | undefined;
}

interface ActionLocation {
    readonly kind: LocationKind.Action;
    readonly actionID: string;
    readonly nodeKey: string;
    readonly actionDisplayName: string | undefined;
    readonly descriptorCase: PropertyDescriptorCase | undefined;
}

interface UserProfileLocation {
    readonly kind: LocationKind.UserProfile;
    readonly name: string;
    readonly descriptorCase: PropertyDescriptorCase | undefined;
}

interface ColumnLocation {
    readonly kind: LocationKind.Column;
    readonly tableName: TableName;
    readonly columnName: string;
}

interface PluginConfigLocation {
    readonly kind: LocationKind.PluginConfig;
    readonly pluginConfigID: string;
}

export type Location =
    | TabLocation
    | ScreenLocation
    | ComponentLocation
    | ScreenColumnAssignmentLocation
    | ActionLocation
    | UserProfileLocation
    | ColumnLocation
    | PluginConfigLocation;

const dummyLocation: Location = { kind: LocationKind.UserProfile, name: "DUMMY", descriptorCase: undefined };

export function getScreenNameForLocation(l: Location): string | undefined {
    switch (l.kind) {
        case LocationKind.Screen:
        case LocationKind.Component:
        case LocationKind.ScreenColumnAssignment:
            return l.screenName;

        case LocationKind.Tab:
        case LocationKind.Action:
        case LocationKind.UserProfile:
        case LocationKind.Column:
        case LocationKind.PluginConfig:
            return undefined;

        default:
            return assertNever(l);
    }
}

// table -> column names
export type ColumnsUsedInTables = ReadonlyMap<TableGlideType, ReadonlySet<string>>;

export interface AppDescriptionVisitor<TState = undefined> {
    // FIXME: This state stuff puts too much work on the visitor.  The visitor
    // shouldn't have to keep track of the "state".  The walker should do that
    // and pass the state into the `visitXXX` methods.
    addState?(state: TState): void;

    willVisitWithState?(state: TState): void;
    finishedVisitingWithState?(state: TState): void;

    visitScreen?(screenName: string, screen: ScreenDescription, tables: InputOutputTables | undefined): void;
    visitComponent?(
        component: ComponentDescription,
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        location: Location
    ): void;
    visitAction?(
        location: Location,
        action: ActionDescription,
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        propertySection: SuperPropertySection | undefined
    ): void;
    visitFormula?(
        location: Location,
        formula: Formula,
        contextTableName: TableName | undefined,
        containingScreenTableName: TableName | undefined,
        propertyDescr: PropertyDescriptor | undefined,
        priorSteps: readonly PriorStep[] | undefined
    ): void;
    visitColumnsUsed?(
        location: Location,
        columnsUsed: ColumnsUsedInTables,
        propertyDescr: PropertyDescriptor | undefined
    ): void;
    visitProperty?(
        location: Location,
        desc: PropertyDescription,
        propertyDescr: PropertyDescriptor | undefined,
        pdc: PropertyDescriptorCase | undefined,
        /** The component/action/screen description containing this property */
        rootDesc: Description,
        containingDesc: Description | undefined,
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        priorSteps: readonly PriorStep[] | undefined
    ): void;
    visitArrayTransforms?(transforms: readonly ArrayTransform[], contextTableName: TableName | undefined): void;
    /** This might get invoked with duplicates */
    visitColumnWritten?(location: Location, tableName: TableName, columnName: string, isAdd: boolean): void;
    visitAdditionalTableUsed?(tableName: TableName): void;
    visitTableDeleted?(location: Location, tableName: TableName): void;
    visitBuilderAction?(actionID: string, table: TableGlideType | undefined): void;
}

type VisitedItemKind = "screen" | "action";
interface VisitedItem {
    readonly kind: VisitedItemKind;
    readonly id: string;
}

export type VisitedItemDependencies = ReadonlyMap<VisitedItem, ReadonlySet<VisitedItem>>;

class DependencyTracker {
    private readonly visitedScreenItems = new Map<string, VisitedItem>();
    private readonly visitedActionItems = new Map<string, VisitedItem>();
    private readonly itemsUsedByItem = new DefaultMap<VisitedItem, Set<VisitedItem>>(() => new Set());

    private getItem(id: string, map: Map<string, VisitedItem>, kind: VisitedItemKind): VisitedItem {
        let item = map.get(id);
        if (item === undefined) {
            item = { kind, id };
            map.set(id, item);
        }
        assert(item.kind === kind);
        return item;
    }

    public getScreenItem(id: string): VisitedItem {
        return this.getItem(id, this.visitedScreenItems, "screen");
    }

    public getActionItem(id: string): VisitedItem {
        return this.getItem(id, this.visitedActionItems, "action");
    }

    public addDependency(user: VisitedItem, usedItem: VisitedItem): void {
        this.itemsUsedByItem.get(user).add(usedItem);
    }

    public getFullDependencies(): VisitedItemDependencies {
        const dependencies = new Map<VisitedItem, Set<VisitedItem>>();
        for (const item of this.itemsUsedByItem.keys()) {
            dependencies.set(item, new Set([item]));
        }
        for (;;) {
            let didChange = false;
            for (const [, usedItems] of dependencies) {
                for (const usedItem of usedItems) {
                    const newUsedItems = this.itemsUsedByItem.get(usedItem);
                    for (const newUsedItem of newUsedItems) {
                        if (usedItems.has(newUsedItem)) continue;
                        usedItems.add(newUsedItem);
                        didChange = true;
                    }
                }
            }
            if (!didChange) break;
        }
        return dependencies;
    }
}

interface BuilderActionToVisit {
    readonly id: string;
    readonly tableName: TableName | undefined;
    readonly mutatingScreenKind: MutatingScreenKind | undefined;
    readonly isIndirect: boolean;
}
export interface WalkBuilderActionPriorStepsBuilder {
    preprocessAction(rootNode: AutomationRootNode, adc: AppDescriptionContext): Result<PreprocessedAction>;
    makePriorSteps(
        pre: Pick<PreprocessedAction, "nodeForKey">,
        adc: AppDescriptionContext,
        nodeKey: string
    ): readonly PriorStep[];
}

interface IncludeOptions {
    readonly includeHiddenTabs: boolean;
    readonly includeDefaultScreens: boolean;
    readonly includeAutomations: boolean;
    readonly includeAllActions: boolean;
    readonly includeAllTables: boolean;
}

const defaultIncludeOptions: IncludeOptions = {
    includeHiddenTabs: false,
    includeDefaultScreens: false,
    includeAutomations: false,
    includeAllActions: false,
    includeAllTables: true,
};

class AppWalker<TState> {
    private readonly builderActionsToVisit: BuilderActionToVisit[] = [];
    private readonly screensToVisit: string[] = [];
    private readonly tablesToVisit: TableGlideType[] = [];

    private readonly dependencyTracker: DependencyTracker | undefined;
    private currentVisitedItem: VisitedItem | undefined;

    constructor(
        private readonly ccc: AppDescriptionContext,
        private readonly plugins: readonly SerializablePluginMetadata[],
        private readonly visitor: AppDescriptionVisitor<TState>,
        private readonly cache: AppVisitorCache<TState> | undefined,
        private readonly priorStepsBuilder: WalkBuilderActionPriorStepsBuilder | undefined,
        private readonly include: IncludeOptions,
        private readonly forGC: boolean,
        withDependencyTracking: boolean
    ) {
        assert(ccc.appDescription !== undefined);

        if (withDependencyTracking) {
            this.dependencyTracker = new DependencyTracker();
        }
    }

    private get app(): AppDescription {
        return defined(this.ccc.appDescription);
    }

    private addScreensToVisit(screenNames: Iterable<string>): void {
        for (const screenName of screenNames) {
            this.screensToVisit.push(screenName);
            this.cache?.getStateForCurrentSource()?.screensUsed.add(screenName);
            if (this.currentVisitedItem !== undefined && this.dependencyTracker !== undefined) {
                this.dependencyTracker.addDependency(
                    this.currentVisitedItem,
                    this.dependencyTracker.getScreenItem(screenName)
                );
            }
        }
    }

    private addBuilderActionsToVisit(actions: Iterable<BuilderActionToVisit>): void {
        this.builderActionsToVisit.push(...actions);
        this.cache?.getStateForCurrentSource()?.actionsUsed.push(...actions);
    }

    // The rule for when to call these is that leaf methods must call them
    // with all the tables they know about.
    private addTableToVisit(tableOrTableName: TableGlideType | TableName | undefined): void {
        if (this.include.includeAllTables) return;
        if (tableOrTableName === undefined) return;

        let table: TableGlideType | undefined;
        if (isTableName(tableOrTableName)) {
            table = this.ccc.findTable(tableOrTableName);
            if (table === undefined) return;
        } else {
            table = tableOrTableName;
        }

        this.tablesToVisit.push(table);
    }

    private addInputOutputTablesToVisit(tables: InputOutputTables | undefined): void {
        this.addTableToVisit(tables?.input);
        this.addTableToVisit(tables?.output);
    }

    private addStateForSource(state: StateForSource<TState>): void {
        this.addScreensToVisit(state.screensUsed);
        this.addBuilderActionsToVisit(state.actionsUsed);
    }

    private visitFormula(
        location: Location,
        formula: Formula,
        contextTableName: TableName | undefined,
        containingScreenTableName: TableName | undefined,
        propertyDescr: PropertyDescriptor | undefined,
        priorSteps: readonly PriorStep[] | undefined
    ): void {
        this.visitor.visitFormula?.(
            location,
            formula,
            contextTableName,
            containingScreenTableName,
            propertyDescr,
            priorSteps
        );

        // If we don't need to call the visitor, nor mark the tables to be
        // visited, we can avoid calling `getColumnsUsedInFormula`.
        if (this.visitor.visitColumnsUsed === undefined && this.include.includeAllTables) return;

        const [columnsUsed] = getColumnsUsedInFormula(
            this.ccc,
            contextTableName,
            containingScreenTableName,
            formula,
            priorSteps ?? []
        );
        this.visitor.visitColumnsUsed?.(location, columnsUsed, propertyDescr);

        for (const table of columnsUsed.keys()) {
            this.addTableToVisit(table);
        }
    }

    private visitArrayTransforms(
        transforms: readonly ArrayTransform[] | undefined,
        contextTableName: TableName | undefined,
        containingScreenTableName: TableName | undefined,
        propertyDescr: PropertyDescriptor | undefined,
        priorSteps: readonly PriorStep[] | undefined,
        makeLocation: (kind: ArrayTransformKind.Filter | ArrayTransformKind.Sort) => Location
    ): void {
        if (transforms === undefined) return;

        this.addTableToVisit(contextTableName);
        this.addTableToVisit(containingScreenTableName);

        for (const transform of transforms) {
            switch (transform.kind) {
                case ArrayTransformKind.Filter:
                    this.visitFormula(
                        makeLocation(transform.kind),
                        transform.predicate,
                        contextTableName,
                        containingScreenTableName,
                        propertyDescr,
                        priorSteps
                    );
                    break;
                case ArrayTransformKind.Sort:
                    for (const key of transform.keys) {
                        this.visitFormula(
                            makeLocation(transform.kind),
                            key.key,
                            contextTableName,
                            containingScreenTableName,
                            propertyDescr,
                            priorSteps
                        );
                    }
                    break;
                // Here's where we have to add the property `numRows` for
                // ##limitTransformInGlobalSearch.
                case ArrayTransformKind.Shuffle:
                case ArrayTransformKind.TableOrder:
                case ArrayTransformKind.Limit:
                    break;
                default:
                    return proveNever(transform, "Unknown array transform", undefined);
            }
        }

        this.visitor.visitArrayTransforms?.(transforms, contextTableName);
    }

    private visitInlineComputation(
        location: Location,
        computation: InlineComputation,
        tables: InputOutputTables | undefined,
        // This is the description of the inline computation itself
        desc: Description,
        containingDesc: Description,
        mutatingScreenKind: MutatingScreenKind | undefined,
        priorSteps: readonly PriorStep[] | undefined
    ): void {
        assert(computation === getInlineComputationProperty(desc));

        this.addInputOutputTablesToVisit(tables);

        for (const [, sc] of computation.bindings) {
            this.visitor.visitProperty?.(
                location,
                // ##walkingInlineComputation:
                // FIXME: It's a bit shitty that we can only do this via a
                // fake property description for now.  We should amend the
                // `Visitor` with a `visitSourceColumn` method instead of
                // making it get source columns out of property desciptions
                // itself.
                makeSourceColumnProperty(sc),
                undefined,
                undefined,
                containingDesc,
                desc,
                tables,
                mutatingScreenKind,
                priorSteps
            );
        }
        for (const c of computation.computationTable.columns) {
            if (c.formula !== undefined) {
                this.visitFormula(location, c.formula, undefined, undefined, undefined, priorSteps);
            }
            if (c.displayFormula !== undefined) {
                this.visitFormula(location, c.displayFormula, undefined, undefined, undefined, priorSteps);
            }
        }
    }

    private getActionTables(
        pdc: ActionPropertyDescriptorCase,
        containingDesc: Description,
        tables: InputOutputTables | undefined,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): { readonly actionTables: InputOutputTables | undefined; readonly actionIsIndirect: boolean } {
        this.addInputOutputTablesToVisit(tables);

        if (pdc.getIndirectTable === undefined) {
            return { actionTables: tables, actionIsIndirect: false };
        }
        if (tables === undefined) {
            return { actionTables: undefined, actionIsIndirect: true };
        }
        const actionTable = pdc.getIndirectTable(tables, containingDesc, containingDesc, this.ccc, actionNodesInScope);
        return {
            actionTables: definedMap(actionTable?.table, makeInputOutputTables),
            actionIsIndirect: actionTable?.inScreenContext !== true,
        };
    }

    private visitProperty(
        propertyDesc: PropertyDescription | undefined,
        propertyDescr: PropertyDescriptor | undefined,
        pdc: PropertyDescriptorCase | undefined,
        tables: InputOutputTables | undefined,
        desc: Description,
        containingDesc: Description,
        mutatingScreenKind: MutatingScreenKind | undefined,
        priorSteps: readonly PriorStep[] | undefined,
        actionsAreIndirect: boolean,
        makeLocation: (
            pdc: PropertyDescriptorCase | undefined,
            propertyDescr: PropertyDescriptor | undefined
        ) => Location
    ): void {
        this.addInputOutputTablesToVisit(tables);

        if (propertyDescr !== undefined && isMultiCasePropertyDescriptor(propertyDescr)) {
            propertyDescr.visitProperty?.(containingDesc, {
                visitFormula: (f: Formula) => {
                    this.visitFormula(
                        makeLocation(pdc, propertyDescr),
                        f,
                        definedMap(tables?.input, getTableName),
                        undefined,
                        propertyDescr,
                        priorSteps
                    );
                },
            });
        }

        // FIXME: This whole thing should be in the description handler
        if (propertyDesc === undefined) return;

        if (propertyDescr?.when?.(desc, containingDesc, tables, this.ccc, undefined) === false) {
            return;
        }

        const location = makeLocation(pdc, propertyDescr);

        this.visitor.visitProperty?.(
            location,
            propertyDesc,
            propertyDescr,
            pdc,
            containingDesc,
            desc,
            tables,
            mutatingScreenKind,
            priorSteps
        );

        if (!isPropertyDescription(propertyDesc)) return;

        if (
            propertyDesc.kind === PropertyKind.Transforms &&
            (pdc === undefined || pdc.kind === PropertyKind.Transforms)
        ) {
            const tpdc = pdc as TransformsPropertyDescriptorCase | undefined;
            const contextTable = definedMap(
                tables,
                // FIXME: We need to pass the action nodes in scope here, but we need to build them as we walk the app.
                // That's not trivial to do right now.
                t => tpdc?.getIndirectTable?.(t, containingDesc, desc, this.ccc, [])?.table ?? t.input
            );
            const containingScreenTable = tpdc?.withContainingScreen === true ? tables?.input : undefined;
            assert(isArray(propertyDesc.value));
            this.visitArrayTransforms(
                propertyDesc.value as readonly ArrayTransform[],
                definedMap(contextTable, getTableName),
                definedMap(containingScreenTable, getTableName),
                propertyDescr,
                priorSteps,
                () => makeLocation(tpdc, propertyDescr)
            );
            return;
        }

        const action = getActionProperty(propertyDesc);
        if (action !== undefined) {
            // ##priorStepsForWalkingActions:
            // NOTE: If/when we support calling actions that have prior steps
            // from properties, we will have to "make" them here and pass them
            // in.  For the time being, prior steps are only supported in
            // Automations, and for that case we pass them in from
            // `walkBuilderAction` below.

            let actionTables = tables;
            let actionIsIndirect = false;
            if (pdc?.kind === PropertyKind.Action) {
                const maybeIndirect = this.getActionTables(
                    pdc as ActionPropertyDescriptorCase,
                    containingDesc,
                    tables,
                    []
                );
                actionTables = maybeIndirect.actionTables;
                actionIsIndirect = maybeIndirect.actionIsIndirect;
            }
            this.visitAction(
                propertyDescr,
                action,
                actionTables,
                mutatingScreenKind,
                actionIsIndirect,
                makeLocation,
                undefined,
                undefined
            );
        }

        const actionID = getCompoundActionProperty(propertyDesc);
        if (actionID !== undefined) {
            this.addBuilderActionsToVisit([
                {
                    id: actionID,
                    tableName: definedMap(tables?.input, getTableName),
                    mutatingScreenKind,
                    isIndirect: actionsAreIndirect,
                },
            ]);
            if (this.currentVisitedItem !== undefined && this.dependencyTracker !== undefined) {
                this.dependencyTracker.addDependency(
                    this.currentVisitedItem,
                    this.dependencyTracker.getActionItem(actionID)
                );
            }
            return;
        }

        const array = getArrayProperty<Description>(propertyDesc);
        if (array !== undefined && pdc?.kind === PropertyKind.Array) {
            for (const itemDesc of array) {
                this.visitProperties(
                    itemDesc,
                    containingDesc,
                    (pdc as ArrayPropertyDescriptorCase).properties,
                    tables,
                    mutatingScreenKind,
                    priorSteps,
                    actionsAreIndirect,
                    makeLocation
                );
            }
        }

        const formula = getFormulaProperty(propertyDesc);
        if (formula !== undefined) {
            this.visitFormula(
                location,
                formula,
                definedMap(tables?.input, getTableName),
                undefined,
                propertyDescr,
                priorSteps
            );
        }

        const inlineComputation = getInlineComputationProperty(propertyDesc);
        if (inlineComputation !== undefined) {
            this.visitInlineComputation(
                location,
                inlineComputation,
                tables,
                propertyDesc,
                containingDesc,
                mutatingScreenKind,
                priorSteps
            );
        }
    }

    private visitProperties(
        desc: Description,
        containingDesc: Description,
        descriptors: readonly PropertyDescriptor[],
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        priorSteps: readonly PriorStep[] | undefined,
        actionsAreIndirect: boolean,
        makeLocation: (
            pdc: PropertyDescriptorCase | undefined,
            propertyDescr: PropertyDescriptor | undefined
        ) => Location
    ): void {
        this.addInputOutputTablesToVisit(tables);

        for (const propertyDescr of descriptors) {
            const propertyAndDescriptor = getPropertyAndDescriptorFromDescription(desc, propertyDescr);

            // `visitProperty` can handle the description and descriptor case
            // to be `undefined`.
            this.visitProperty(
                propertyAndDescriptor?.description,
                propertyDescr,
                propertyAndDescriptor?.descriptorCase,
                tables,
                desc,
                containingDesc,
                mutatingScreenKind,
                priorSteps,
                actionsAreIndirect,
                makeLocation
            );
        }
    }

    private get needsVisitAllowedDataEdits(): boolean {
        return this.visitor.visitColumnWritten !== undefined || this.visitor.visitTableDeleted !== undefined;
    }

    // NOTE: This must be kept in sync with the "needs" getter above
    private visitAllowedDataEdits(edits: AllowedDataEdits, location: Location): void {
        const visitMap = (map: ReadonlyMap<TableName, ReadonlySet<string>>, isAddRow: boolean) => {
            for (const [tableName, columns] of map) {
                if (columns.size === 0) continue;
                this.addTableToVisit(tableName);
                for (const column of columns) {
                    this.visitor.visitColumnWritten?.(location, tableName, column, isAddRow);
                }
            }
        };
        visitMap(edits.addRowToTable, true);
        visitMap(edits.setColumnsInRow, false);
        for (const t of edits.deleteRow) {
            this.addTableToVisit(t);
            this.visitor.visitTableDeleted?.(location, getTableName(t));
        }
    }

    private get needsVisitColumnEdits(): boolean {
        return this.visitor.visitColumnWritten !== undefined || this.visitor.visitTableDeleted !== undefined;
    }

    // NOTE: This must be kept in sync with the "needs" getter above
    private visitColumnEdits(edits: EditedColumnsAndTables, location: Location): void {
        for (const [columnName, , isAddRow, tableName] of edits.editedColumns) {
            this.addTableToVisit(tableName);
            this.visitor.visitColumnWritten?.(location, tableName, columnName, isAddRow);
        }
        for (const tableName of edits.deletedTables) {
            this.addTableToVisit(tableName);
            this.visitor.visitTableDeleted?.(location, tableName);
        }
    }

    private visitComponent(
        originalDesc: ComponentDescription,
        index: number,
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        parentIndex: readonly [] | readonly [number],
        makeLocation: (
            desc: ComponentDescription,
            index: ComponentIndexes,
            descr: ComponentDescriptor | undefined,
            pdc: PropertyDescriptorCase | undefined
        ) => Location
    ): void {
        this.addInputOutputTablesToVisit(tables);

        const { ccc } = this;
        const componentIndexes: ComponentIndexes = [...parentIndex, index];

        const handler = handlerForComponentKind(originalDesc.kind);
        if (handler === undefined) {
            this.visitor.visitComponent?.(
                originalDesc,
                tables,
                mutatingScreenKind,
                makeLocation(originalDesc, componentIndexes, undefined, undefined)
            );
            return;
        }

        const [desc, descr] = handler.lowerDescriptionForBuilding(
            originalDesc,
            tables,
            ccc,
            mutatingScreenKind,
            undefined,
            this.forGC,
            undefined
        );

        this.visitor.visitComponent?.(
            desc,
            tables,
            mutatingScreenKind,
            makeLocation(originalDesc, componentIndexes, descr, undefined)
        );

        this.addScreensToVisit(handler.getScreensUsed(desc, ccc, tables));

        this.visitProperties(desc, desc, descr.properties, tables, mutatingScreenKind, undefined, false, pdc =>
            makeLocation(desc, componentIndexes, descr, pdc)
        );

        this.visitActionsRecord(
            getActionsWithDescriptorsForComponent(handler, desc, tables, ccc, mutatingScreenKind),
            tables,
            mutatingScreenKind,
            desc,
            pdc => makeLocation(desc, componentIndexes, descr, pdc)
        );

        if (this.needsVisitColumnEdits || this.needsVisitAllowedDataEdits) {
            if (tables !== undefined) {
                const location = makeLocation(desc, componentIndexes, descr, undefined);
                const edits = handler.getEditedColumns(desc, tables, ccc, mutatingScreenKind, false);
                this.visitColumnEdits(edits, location);

                const additionalEdits = handler.getAdditionalDataEdits(desc, tables, ccc, mutatingScreenKind);
                if (additionalEdits !== undefined) {
                    this.visitAllowedDataEdits(additionalEdits, location);
                }
            }
        }

        if (this.visitor.visitAdditionalTableUsed !== undefined) {
            for (const tn of handler.getAdditionalTablesUsed(desc, ccc, tables)) {
                this.addTableToVisit(tn);
                this.visitor.visitAdditionalTableUsed(tn);
            }
        }

        const subComponents = getSubComponents(desc);
        if (subComponents !== undefined) {
            assert(componentIndexes.length === 1);
            const subTables = definedMap(tables, t => getSubComponentsWithTables(desc, t, ccc, mutatingScreenKind));
            this.visitComponents(subComponents, subTables?.[1], subTables?.[2], componentIndexes, makeLocation);
        }
    }

    private visitComponents(
        components: readonly ComponentDescription[],
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        parentIndex: readonly [] | readonly [number],
        makeLocation: (
            desc: ComponentDescription,
            index: ComponentIndexes,
            descr: ComponentDescriptor | undefined,
            pdc: PropertyDescriptorCase | undefined
        ) => Location
    ): void {
        for (const [index, desc] of iterableEnumerate(components)) {
            this.visitComponent(desc, index, tables, mutatingScreenKind, parentIndex, makeLocation);
        }
    }

    private visitColumnAssignments(
        columnAssignments: readonly ColumnAssignment[],
        tables: InputOutputTables,
        isAddRow: boolean,
        desc: Description,
        mutatingScreenKind: MutatingScreenKind | undefined,
        priorSteps: readonly PriorStep[] | undefined,
        makeLocation: (assignment: ColumnAssignment, index: number) => Location
    ): void {
        let x = 0;
        for (const assignment of columnAssignments) {
            const location = makeLocation(assignment, x);
            const tableName = getTableName(tables.output);
            this.visitor.visitColumnWritten?.(location, tableName, assignment.destColumn, isAddRow);
            this.visitProperty(
                assignment.value,
                undefined,
                undefined,
                tables,
                desc,
                desc,
                mutatingScreenKind,
                priorSteps,
                false,
                () => location
            );

            const column = getTableColumn(tables.output, assignment.destColumn);
            if (column !== undefined) {
                const linkTarget = getTargetForLink(tables.output, column, this.ccc, true);
                if (linkTarget !== undefined) {
                    // ##editedLinkColumns:
                    // When writing to link columns, we need to count not only
                    // the link columns themselves as edited, but also their
                    // underlying host columns.
                    this.visitor.visitColumnWritten?.(location, tableName, linkTarget.hostColumn.name, isAddRow);
                }
            }

            x++;
        }
    }

    public visitAction(
        propertyDescr: PropertyDescriptor | undefined,
        action: ActionDescription,
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        actionIsIndirect: boolean,
        makeLocation: (
            pdc: PropertyDescriptorCase | undefined,
            propertyDescr: PropertyDescriptor | undefined
        ) => Location,
        root: AutomationRootNode | undefined,
        nodeKey: string | undefined
    ): void {
        this.addInputOutputTablesToVisit(tables);

        const { ccc } = this;
        let priorSteps: PriorStep[] = [];

        if (root !== undefined) {
            const preProcessedAction = this.priorStepsBuilder?.preprocessAction(root, ccc);

            if (preProcessedAction?.ok === true) {
                const madePriorSteps = this.priorStepsBuilder?.makePriorSteps(
                    preProcessedAction.result,
                    ccc,
                    nodeKey ?? ""
                );
                if (madePriorSteps !== undefined) {
                    priorSteps = [...madePriorSteps];
                }
            }
        }
        const env: StaticActionContext<AppDescriptionContext> = {
            context: ccc,
            tables,
            mutatingScreenKind,
            isAutomation: this.priorStepsBuilder !== undefined,
            priorSteps,
        };

        this.visitor.visitAction?.(
            makeLocation(undefined, undefined),
            action,
            tables,
            mutatingScreenKind,
            propertyDescr?.section
        );

        const handler = handlerForActionKind(action.kind);

        action = handler.getDescriptionToWalk(action, ccc);

        this.addScreensToVisit(handler.getScreensUsed(action, env));

        const descr = handler.getDescriptor(action, env);

        this.visitProperties(
            action,
            action,
            descr.properties,
            tables,
            mutatingScreenKind,
            priorSteps,
            actionIsIndirect,
            makeLocation
        );

        if (action.condition !== undefined) {
            this.visitArrayTransforms(
                [action.condition],
                definedMap(tables?.input, getTableName),
                undefined,
                propertyDescr,
                priorSteps,
                () => makeLocation(undefined, undefined)
            );
        }

        // All the rest below will be processed as part of the builder action
        // later.
        if (action.kind === ActionKind.Compound) return;

        if (tables !== undefined) {
            const assignments = handler.getColumnAssignments(action, env);
            if (assignments !== undefined) {
                const location = makeLocation(undefined, undefined);
                this.visitColumnAssignments(
                    assignments.assignments,
                    makeInputOutputTables(tables.input, assignments.table),
                    assignments.isAddRow,
                    action,
                    mutatingScreenKind,
                    priorSteps,
                    () => location
                );
            }

            // If the action is indirect, such as a Set action in an Inline
            // List, the mutating screen kind doesn't make a difference, so we
            // pass `undefined`.
            const edits = handler.getEditedColumns(action, {
                ...env,
                mutatingScreenKind: actionIsIndirect ? undefined : mutatingScreenKind,
            });
            if (edits !== undefined) {
                this.visitColumnEdits(edits, makeLocation(undefined, undefined));
            }

            for (const tableName of handler.getAdditionalTablesUsed(tables)) {
                this.addTableToVisit(tableName);
                this.visitor.visitAdditionalTableUsed?.(tableName);
            }
        }
    }

    public visitActionWithDescriptor(
        descr: ActionPropertyDescriptor,
        action: ActionDescription,
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        containingDesc: Description,
        makeLocation: (pdc: PropertyDescriptorCase | undefined) => Location
    ): void {
        this.addInputOutputTablesToVisit(tables);

        // We don't yet need to support ##priorStepsForWalkingActions.
        const { actionTables, actionIsIndirect } = this.getActionTables(descr, containingDesc, tables, []);
        this.visitAction(
            descr,
            action,
            actionTables,
            mutatingScreenKind,
            actionIsIndirect,
            pdc => makeLocation(pdc),
            undefined,
            undefined
        );
    }

    private visitActionsRecord(
        actionsWithDescriptors: ActionsWithDescriptors,
        tables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        containingDesc: Description,
        makeLocation: (pdc: PropertyDescriptorCase | undefined, propertyName: string) => Location
    ): void {
        for (const [descr, action, name] of actionsWithDescriptors) {
            if (action === undefined) continue;
            this.visitActionWithDescriptor(descr, action, tables, mutatingScreenKind, containingDesc, pdc =>
                makeLocation(pdc, name)
            );
        }
    }

    private visitScreen(screenName: string, screen: ScreenDescription): void {
        const { ccc, app } = this;

        let tables: InputOutputTables | undefined;
        if (isClassOrArrayScreenDescription(screen)) {
            tables = inputOutputTablesForClassOrArrayScreen(screen, this.ccc.schema);
        }

        this.addInputOutputTablesToVisit(tables);

        this.visitor.visitScreen?.(screenName, screen, tables);

        if (screen.kind === ScreenDescriptionKind.Chat) {
            this.visitor.visitAdditionalTableUsed?.(commentsTableName);
        }

        if (!isClassOrArrayScreenDescription(screen)) return;

        const contextTableName = definedMap(tables?.input, getTableName);

        const mutatingScreenKind = getMutatingKindForScreen(screenName, screen);
        const tabs = getAppTabs(app);
        const maybeTabIndex = tabs.findIndex(t => getScreenProperty(t.screenName) === screenName);
        const tabIndex = maybeTabIndex >= 0 ? maybeTabIndex : undefined;

        const location = { kind: LocationKind.Screen, screenName, mutatingScreenKind, tabIndex } as const;

        this.visitArrayTransforms(screen.transforms, contextTableName, undefined, undefined, undefined, kind => ({
            ...location,
            propertySection: kind === ArrayTransformKind.Filter ? PropertySection.FilterData : PropertySection.Sort,
            descriptorCase: undefined,
        }));

        const visitScreenComponents = (): void => {
            assert(isClassOrArrayScreenDescription(screen));
            const components = getScreenComponents(screen);
            this.visitComponents(components, tables, mutatingScreenKind, [], (desc, index, descr, pdc) => {
                const handler = handlerForComponentKind(desc.kind);
                return {
                    kind: LocationKind.Component,
                    screenName,
                    mutatingScreenKind,
                    tabIndex,
                    componentID: desc.componentID,
                    componentIndexes: index,
                    componentDisplayName: desc.builderDisplayName ?? descr?.name ?? "Component",
                    componentIsGlideManaged: handler?.isGlideManaged ?? false,
                    descriptorCase: pdc,
                };
            });
        };

        if (screen.kind === ScreenDescriptionKind.Class) {
            if (mutatingScreenKind === undefined && screen.canEdit === true) {
                if (contextTableName !== undefined) {
                    this.addScreensToVisit([editClassScreenName(contextTableName)]);
                }

                // FIXME: Do we have a descriptor for this?
                this.visitArrayTransforms(
                    screen.canEditFilters,
                    contextTableName,
                    undefined,
                    undefined,
                    undefined,
                    () => ({
                        ...location,
                        propertySection: PropertySection.Edit,
                        descriptorCase: undefined,
                    })
                );
            }
            if (mutatingScreenKind === MutatingScreenKind.EditScreen && screen.canDelete === true) {
                // FIXME: Do we have a descriptor for this?
                this.visitArrayTransforms(
                    screen.canDeleteFilters,
                    contextTableName,
                    undefined,
                    undefined,
                    undefined,
                    () => ({
                        ...location,
                        propertySection: PropertySection.Delete,
                        descriptorCase: undefined,
                    })
                );
            }

            this.visitProperty(
                screen.title,
                undefined,
                undefined,
                tables,
                screen,
                screen,
                mutatingScreenKind,
                undefined,
                false,
                pdc => ({
                    ...location,
                    propertySection: PropertySection.Navigation,
                    descriptorCase: pdc,
                })
            );

            // FIXME: use `searchPlaceholderPropertyHandler`
            // FIXME: In array screens, only visit if `desc.search` switch
            // property is true.  In class screens, only visit if it has a
            // searched inline list.
            this.visitProperty(
                screen.searchPlaceholder,
                undefined,
                undefined,
                tables,
                screen,
                screen,
                mutatingScreenKind,
                undefined,
                false,
                pdc => ({
                    ...location,
                    propertySection: PropertySection.Search,
                    descriptorCase: pdc,
                })
            );

            visitScreenComponents();

            if (mutatingScreenKind !== undefined && tables !== undefined) {
                const editScreen = screen as EditScreenDescription;
                const columnAssignments = getColumnAssignments(editScreen);
                this.visitColumnAssignments(
                    columnAssignments,
                    tables,
                    doesMutatingScreenAddRows(mutatingScreenKind),
                    screen,
                    mutatingScreenKind,
                    undefined,
                    (assignment, index) => ({
                        kind: LocationKind.ScreenColumnAssignment,
                        screenName,
                        mutatingScreenKind,
                        tabIndex,
                        tableName: getTableName(defined(tables).output),
                        columnName: assignment.destColumn,
                        assignmentIndex: index,
                        descriptorCase: undefined,
                    })
                );

                for (const action of editScreen.onSubmitActions ?? []) {
                    this.visitAction(
                        undefined,
                        action,
                        makeInputOutputTablesForFormOnSubmitAction(tables),
                        // There is no ##onSubmitMutatingScreenKind.
                        undefined,
                        false,
                        pdc => ({
                            ...location,
                            propertySection: PropertySection.OnSubmitAction,
                            descriptorCase: pdc,
                        }),
                        // We don't yet need to support ##priorStepsForWalkingActions.
                        undefined,
                        undefined
                    );
                }
            }
        } else if (screen.kind === ScreenDescriptionKind.Array) {
            assert(mutatingScreenKind === undefined);

            if (getSwitchProperty(screen.canAddRow) === true) {
                // The filters are handled by the property descriptors.

                if (contextTableName !== undefined) {
                    this.addScreensToVisit([addClassScreenName(contextTableName)]);
                }
            }

            const screenHandler = handlerForArrayScreenFormat(screen.format);
            if (screenHandler === undefined) return;

            this.addScreensToVisit(screenHandler.getScreensUsed(screen, tables, ccc, false));

            if (screenHandler.hasComponents) {
                visitScreenComponents();
            }

            const descrs = screenHandler.getPropertyDescriptors(
                tables,
                screen,
                isDefaultArrayScreenName(screenName),
                isFreeScreen(screenName, screen),
                ccc
            );
            this.visitProperties(screen, screen, descrs, tables, mutatingScreenKind, undefined, false, pdc => ({
                ...location,
                propertySection: undefined,
                descriptorCase: pdc,
            }));

            const actionDescrs = screenHandler.getActionDescriptors(screen, tables, undefined, ccc);
            this.visitActionsRecord(
                getActionsWithDescriptorsForArrayContent(screenHandler, tables, screen, ccc),
                tables,
                undefined,
                screen,
                (pdc, name) => {
                    const actionDescr = actionDescrs.find(
                        a => isNamedPropertySource(a.property) && a.property.name === name
                    );
                    return {
                        ...location,
                        propertySection:
                            typeof actionDescr?.section === "string" ? actionDescr.section : PropertySection.Action,
                        descriptorCase: pdc,
                    };
                }
            );

            if (tables !== undefined) {
                const editedColumns = screenHandler.getEditedColumns(
                    undefined,
                    screen,
                    tables,
                    ccc,
                    mutatingScreenKind,
                    false,
                    undefined,
                    isDefaultArrayScreenName(screenName),
                    false
                );
                this.visitColumnEdits(editedColumns, {
                    ...location,
                    propertySection: undefined,
                    descriptorCase: undefined,
                });
            }
        } else {
            return assertNever(screen);
        }
    }

    // If the action is invoked from a Form screen, for example, a Set Columns
    // action would actually count as an Add when it comes to modified
    // columns.
    public walkBuilderAction(
        actionID: string,
        root: ConditionalActionNode | FlowActionNode | AutomationRootNode,
        perAppTable: TableGlideType | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        actionsAreIndirect: boolean
    ): void {
        this.addTableToVisit(perAppTable);

        const { ccc } = this;
        const preprocessedAction =
            root.kind === ActionNodeKind.AutomationRoot
                ? this.priorStepsBuilder?.preprocessAction(root, ccc)
                : undefined;

        walkActionNode(root, n => {
            const makePriorSteps = () =>
                preprocessedAction?.ok === true
                    ? this.priorStepsBuilder?.makePriorSteps(preprocessedAction.result, ccc, n.key)
                    : [];
            const location: Location = {
                kind: LocationKind.Action,
                actionID,
                nodeKey: n.key,
                actionDisplayName: undefined,
                descriptorCase: undefined,
            } as const;
            const visitProperty = (pd: PropertyDescription, desc: Description) =>
                this.visitProperty(
                    pd,
                    undefined,
                    undefined,
                    definedMap(perAppTable, makeInputOutputTables),
                    desc,
                    root,
                    undefined,
                    makePriorSteps(),
                    false,
                    () => location
                );

            switch (n.kind) {
                case ActionNodeKind.Conditional:
                case ActionNodeKind.Flow:
                case ActionNodeKind.AutomationRoot:
                    break;
                case ActionNodeKind.Loop:
                    if (n.source.kind === LoopSourceKind.Table || n.source.kind === LoopSourceKind.Relation) {
                        this.visitArrayTransforms(
                            filterUndefined([n.source.filter]),
                            definedMap(perAppTable, getTableName),
                            undefined,
                            undefined,
                            makePriorSteps(),
                            () => location
                        );
                    }
                    switch (n.source.kind) {
                        case LoopSourceKind.Table:
                            this.addTableToVisit(n.source.tableName);
                            break;
                        case LoopSourceKind.Relation:
                        case LoopSourceKind.Array:
                            // TODO: I really don't like that we have to
                            // create a property description here. We
                            // shouldn't ever visit anything that doesn't
                            // exist as-is.  Unfortunately we can't just visit
                            // a column right now, given the callbacks in the
                            // visitor, so fixing this would require a larger
                            // refactor.
                            visitProperty(makeSourceColumnProperty(n.source.sourceColumn), n.source);
                            break;
                        case LoopSourceKind.Range:
                            for (const pd of [n.source.start, n.source.increment, n.source.repetitions]) {
                                visitProperty(pd, n.source);
                            }
                            break;
                        default:
                            return assertNever(n.source);
                    }
                    break;
                case ActionNodeKind.ConditionalFlow:
                    this.visitFormula(
                        location,
                        n.condition,
                        definedMap(perAppTable, getTableName),
                        undefined,
                        undefined,
                        makePriorSteps()
                    );
                    break;
                case ActionNodeKind.Primitive:
                    // not a problem
                    const handler = handlerForActionKind(n.actionDescription.kind);
                    const descr = handler.getDescriptor(n.actionDescription, {
                        context: ccc,
                        tables: undefined,
                        mutatingScreenKind,
                        isAutomation: false,
                    });
                    this.visitAction(
                        undefined,
                        n.actionDescription,
                        definedMap(perAppTable, makeInputOutputTables),
                        mutatingScreenKind,
                        actionsAreIndirect,
                        pdc => ({
                            ...location,
                            actionDisplayName: descr.name,
                            descriptorCase: pdc,
                        }),
                        root.kind === ActionNodeKind.AutomationRoot ? root : undefined,
                        n.key
                    );
                    break;
                default:
                    return assertNever(n);
            }
            return n;
        });
    }

    private visitBuilderAction(
        actionID: string,
        builderAction: BuilderAction | undefined,
        table: TableGlideType | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        actionsAreIndirect: boolean
    ): void {
        const { ccc } = this;

        // It's important that we call this before we check whether the action
        // exists, because the visitor might be curious about actions that got
        // lost.
        this.visitor.visitBuilderAction?.(actionID, table);

        if (builderAction === undefined) return;

        if (table === undefined) {
            const perApp = builderAction.perApp[ccc.appID];
            table = ccc.findTable(perApp?.tableName);
        }

        this.walkBuilderAction(actionID, builderAction.action, table, mutatingScreenKind, actionsAreIndirect);
    }

    private withVisitedItem(item: VisitedItem | undefined, f: () => void): void {
        assert(this.currentVisitedItem === undefined);
        this.currentVisitedItem = item;
        f();
        this.currentVisitedItem = undefined;
    }

    // Returns whether an action was visited
    public walkBuilderActionsToVisit(builderActionsVisited: Set<string>): boolean {
        let didVisit = false;

        for (;;) {
            const toVisit = this.builderActionsToVisit.shift();
            if (toVisit === undefined) break;

            const { id: actionID, tableName, mutatingScreenKind, isIndirect: actionsAreIndirect } = toVisit;

            if (builderActionsVisited.has(actionID)) continue;
            builderActionsVisited.add(actionID);
            didVisit = true;

            const builderAction = this.ccc.getBuilderAction(actionID);

            let state = definedMap(builderAction, b => this.cache?.shouldVisitSource(b));
            if (state !== undefined) {
                this.addStateForSource(state);
                this.visitor.addState?.(state.state);
                continue;
            }
            if (builderAction !== undefined) {
                state = this.cache?.getStateForCurrentSource();
            }

            if (state !== undefined) {
                this.visitor.willVisitWithState?.(state?.state);
            }

            const table = this.ccc.findTable(tableName);
            this.withVisitedItem(this.dependencyTracker?.getActionItem(actionID), () =>
                this.visitBuilderAction(actionID, builderAction, table, mutatingScreenKind, actionsAreIndirect)
            );

            if (state !== undefined) {
                this.visitor.finishedVisitingWithState?.(state.state);
                this.cache?.finishVisitingSource(defined(builderAction));
            }
        }

        return didVisit;
    }

    public walkAppDescription(): { screensVisited: ReadonlySet<string> } {
        const { ccc, app } = this;

        if (app.userProfile !== undefined) {
            let tables: InputOutputTables | undefined;
            const info = getUserProfileTableInfo(app);
            if (info !== undefined) {
                const table = ccc.findTable(info.tableName);
                if (table !== undefined) {
                    tables = makeInputOutputTables(table);
                }
            }
            this.visitProperties(
                app.userProfile,
                app.userProfile,
                getUserProfileDescriptors(ccc.eminenceFlags, app.userProfile, ccc.appKind),
                tables,
                undefined,
                undefined,
                false,
                (pdc, pd) => ({
                    kind: LocationKind.UserProfile,
                    name: (typeof pd?.label === "function" ? pd.label(undefined) : pd?.label) ?? "Property",
                    descriptorCase: pdc,
                })
            );
        }

        this.addScreensToVisit([userProfileScreenName]);

        const tabs = getAppTabs(app);
        for (const [index, tab] of iterableEnumerate(tabs)) {
            if (tab.hidden && !this.include.includeHiddenTabs) continue;

            const screenName = getScreenProperty(tab.screenName);
            if (screenName !== undefined) {
                this.addScreensToVisit([screenName]);
            }

            const location: Location = { kind: LocationKind.Tab, index, descriptorCase: undefined };
            // this.visitor.visitTabDescription?.(tab);
            // FIXME: Do we have a descriptor for this?
            this.visitArrayTransforms(
                tab.visibilityFilters,
                undefined,
                undefined,
                undefined,
                undefined,
                () => location
            );
        }

        if (this.include.includeDefaultScreens) {
            for (const table of ccc.schema.tables) {
                const tableRef = makeTableRef(table);
                this.addScreensToVisit([arrayScreenName(ccc, tableRef)]);
                this.addScreensToVisit([classScreenName(tableRef)]);
                this.addScreensToVisit([addClassScreenName(tableRef)]);
                this.addScreensToVisit([editClassScreenName(tableRef)]);
            }
        }

        if (this.include.includeAutomations || this.include.includeAllActions) {
            const visitableBuilderActions: BuilderActionToVisit[] = [];
            for (const [id, builderAction] of ccc.builderActions.entries()) {
                if (builderAction.hasAutomation === false && !this.include.includeAllActions) continue;
                const visitableBuilderAction: BuilderActionToVisit = {
                    id,
                    tableName: undefined,
                    mutatingScreenKind: undefined,
                    isIndirect: false,
                };
                visitableBuilderActions.push(visitableBuilderAction);
            }
            this.addBuilderActionsToVisit(visitableBuilderActions);
        }

        const screensVisited = new Set<string>();
        const builderActionsVisited = new Set<string>();
        for (;;) {
            const screenName = this.screensToVisit.shift();
            if (screenName !== undefined) {
                if (screensVisited.has(screenName)) continue;
                screensVisited.add(screenName);

                const screen = app.screenDescriptions[screenName];
                if (screen === undefined) continue;

                let state = this.cache?.shouldVisitSource(screen);
                if (state !== undefined) {
                    this.addStateForSource(state);
                    this.visitor.addState?.(state.state);
                    continue;
                }
                state = this.cache?.getStateForCurrentSource();

                if (state !== undefined) {
                    this.visitor.willVisitWithState?.(state.state);
                }

                this.withVisitedItem(this.dependencyTracker?.getScreenItem(screenName), () =>
                    this.visitScreen(screenName, screen)
                );

                if (state !== undefined) {
                    this.visitor.finishedVisitingWithState?.(state.state);
                    this.cache?.finishVisitingSource(screen);
                }

                continue;
            }

            if (this.walkBuilderActionsToVisit(builderActionsVisited)) continue;

            break;
        }

        const tablesVisited = new Set<TableGlideType>();
        for (;;) {
            // If we `includeAllTables`, we use all the tables in the schema.
            let tablesToVisit: readonly TableGlideType[];
            if (this.include.includeAllTables) {
                tablesToVisit = this.ccc.schema.tables;
            } else {
                tablesToVisit = Array.from(this.tablesToVisit);
                this.tablesToVisit.length = 0;
            }

            for (const t of tablesToVisit) {
                if (tablesVisited.has(t)) continue;
                tablesVisited.add(t);

                const contextTableName = getTableName(t);
                for (const c of t.columns) {
                    let state = this.cache?.shouldVisitSource(c);
                    if (state !== undefined) {
                        assert(state.screensUsed.size === 0);
                        assert(state.actionsUsed.length === 0);
                        this.visitor.addState?.(state.state);
                        continue;
                    }
                    state = this.cache?.getStateForCurrentSource();

                    if (state !== undefined) {
                        this.visitor.willVisitWithState?.(state.state);
                    }

                    const location: ColumnLocation = {
                        kind: LocationKind.Column,
                        tableName: getTableName(contextTableName),
                        columnName: c.name,
                    };

                    const visit = (f: Formula) => {
                        this.visitFormula(location, f, contextTableName, undefined, undefined, undefined);
                    };

                    if (c.formula !== undefined) {
                        visit(c.formula);
                    }
                    if (c.displayFormula !== undefined) {
                        visit(c.displayFormula);
                    }

                    if (state !== undefined) {
                        this.visitor.finishedVisitingWithState?.(state.state);
                        this.cache?.finishVisitingSource(c);
                    }
                }
            }

            // If we `includeAllTables`, we never add to this array, so we
            // will break after the first iteration.
            if (this.tablesToVisit.length === 0) break;
        }

        // FIXME: move this up, because in the future it might also add tables
        // to visit
        for (const c of app.pluginConfigs ?? []) {
            if (c.configID === undefined) continue;

            const plugin = this.plugins.find(p => p.id === c.pluginID);
            if (plugin === undefined) continue;

            const location: PluginConfigLocation = {
                kind: LocationKind.PluginConfig,
                pluginConfigID: c.configID,
            };
            for (const name of Object.keys(plugin.parameters ?? {})) {
                const value = c.parameters[name];
                if (value === undefined) continue;
                this.visitor.visitProperty?.(
                    location,
                    value,
                    undefined,
                    undefined,
                    value,
                    undefined,
                    undefined,
                    undefined,
                    undefined
                );
            }
        }

        return { screensVisited };
    }

    public getFullDependencies(): VisitedItemDependencies | undefined {
        return this.dependencyTracker?.getFullDependencies();
    }
}

export interface WalkAppDescriptionOptions<T> extends IncludeOptions {
    readonly cache: AppVisitorCache<T>;
    readonly priorStepsBuilder: WalkBuilderActionPriorStepsBuilder;
    readonly forGC: boolean;
    readonly withDependencyTracking: boolean;
}

export function walkAppDescription<T>(
    ccc: AppDescriptionContext,
    visitor: AppDescriptionVisitor<T>,
    plugins: readonly SerializablePluginMetadata[],
    opts: Partial<WalkAppDescriptionOptions<T>> = {}
): VisitedItemDependencies | undefined {
    if (ccc.appDescription === undefined) return;

    const { cache = undefined, priorStepsBuilder = undefined, forGC = false, withDependencyTracking = false } = opts;

    const walker = new AppWalker(
        ccc,
        plugins,
        visitor,
        cache,
        priorStepsBuilder,
        {
            ...defaultIncludeOptions,
            ...opts,
        },
        forGC,
        withDependencyTracking
    );
    walker.walkAppDescription();
    return walker.getFullDependencies();
}

export function walkBuilderAction<T>(
    ccc: AppDescriptionContext,
    visitor: AppDescriptionVisitor<T>,
    priorStepsBuilder: WalkBuilderActionPriorStepsBuilder | undefined,
    actionID: string,
    root: ConditionalActionNode | AutomationRootNode,
    table: TableGlideType | undefined
): void {
    if (ccc.appDescription === undefined) return;

    const walker = new AppWalker(ccc, [], visitor, undefined, priorStepsBuilder, defaultIncludeOptions, false, false);
    walker.walkBuilderAction(actionID, root, table, undefined, false);
}

export function walkActionWithDescriptor<TDescription extends Description, TState>(
    ccc: AppDescriptionContext,
    visitor: AppDescriptionVisitor<TState>,
    priorStepsBuilder: WalkBuilderActionPriorStepsBuilder | undefined,
    descr: ActionPropertyDescriptor,
    action: ActionDescription,
    tables: InputOutputTables,
    mutatingScreenKind: MutatingScreenKind | undefined,
    containingDesc: TDescription
): void {
    if (ccc.appDescription === undefined) return;

    const walker = new AppWalker(ccc, [], visitor, undefined, priorStepsBuilder, defaultIncludeOptions, false, false);
    walker.visitActionWithDescriptor(descr, action, tables, mutatingScreenKind, containingDesc, () => dummyLocation);
}

export function walkAction<T>(
    ccc: ExistingAppDescriptionContext,
    visitor: AppDescriptionVisitor<T>,
    priorStepsBuilder: WalkBuilderActionPriorStepsBuilder | undefined,
    action: ActionDescription,
    // These tables must already be resolved via the descriptor's
    // `getIndirectTable`
    tables: InputOutputTables,
    mutatingScreenKind: MutatingScreenKind | undefined,
    actionIsIndirect: boolean,
    withBuilderActions: boolean
): void {
    const walker = new AppWalker(ccc, [], visitor, undefined, priorStepsBuilder, defaultIncludeOptions, false, false);
    walker.visitAction(
        undefined,
        action,
        tables,
        mutatingScreenKind,
        actionIsIndirect,
        () => dummyLocation,
        undefined,
        undefined
    );
    if (withBuilderActions) {
        walker.walkBuilderActionsToVisit(new Set());
    }
}

type VisitingSource = ScreenDescription | BuilderAction | TableColumn;

interface StateForSource<T> {
    readonly state: T;
    readonly screensUsed: Set<string>;
    readonly actionsUsed: BuilderActionToVisit[];
}

export class AppVisitorCache<T> {
    private readonly dataForSource = new WeakMap<VisitingSource, StateForSource<T>>();
    private currentSource: VisitingSource | undefined;

    constructor(private readonly makeEmptyState: () => T) {}

    // Returns the current data for the source if we shouldn't visit.  Returns
    // `undefined` if we should visit.  In that case `finishVisitingSource`
    // must eventually be called to reset the current source.
    public shouldVisitSource(source: VisitingSource): StateForSource<T> | undefined {
        assert(this.currentSource === undefined);
        const data = this.dataForSource.get(source);
        if (data !== undefined) {
            return data;
        }
        this.currentSource = source;
        this.dataForSource.set(source, { state: this.makeEmptyState(), screensUsed: new Set(), actionsUsed: [] });
        return undefined;
    }

    public finishVisitingSource(source: VisitingSource): void {
        assert(this.currentSource === source);
        this.currentSource = undefined;
    }

    public getStateForCurrentSource(): StateForSource<T> | undefined {
        if (this.currentSource === undefined) return undefined;
        return this.dataForSource.get(this.currentSource);
    }
}
