import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { getSpinner } from "@glide/common-core/dist/js/components/activity-spinner/activity-spinner";

export const SignInStage: React.VFC = () => {
    const theme = useWireAppTheme();

    return (
        <div
            tw="flex justify-center items-center self-center [margin-top: 80px] [min-height: 200px] [margin-bottom: 80px]"
            className="spinner">
            {getSpinner(theme.n0, "loading-placeholder")}
        </div>
    );
};
