import { formatShortDate, formatShortTime, getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import { type MinimalAppEnvironment, MenuItemPurpose } from "@glide/common-core/dist/js/components/types";
import { asMaybeDate } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ArrayContentDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ArrayScreenFormat,
    PropertyKind,
    getStringProperty,
    type ComponentDescription,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import type { Doc } from "@glide/common-core/dist/js/docUrl";
import { GlideDateTime } from "@glide/data-types";
import type {
    WireAppEventPickerComponent,
    WireAppEventPickerItem,
    WireAppEventPickerScreenComponent,
    WireAppMenuItem,
} from "@glide/fluent-components/dist/js/base-components";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentSpecialCaseDescriptor,
    type PropertyDescriptor,
    type PropertyTableGetter,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    isRequiredPropertyHandler,
    makeTextPropertyDescriptor,
    titleProperties,
    getPrimitiveNonHiddenColumnsSpec,
} from "@glide/function-utils";
import {
    type WireInflationBackend,
    type WireTableComponentHydratorConstructor,
    type WireScreen,
    WireActionResult,
    ValueChangeSource,
    WireComponentKind,
} from "@glide/wire";
import { assert, defined, mapFilterUndefined } from "@glideapps/ts-necessities";
import type { ScreenContext } from "../components/component-handler";
import {
    type CaptionPropertyDescriptorFlags,
    doesMutatingScreenSupportIsRequired,
    labelCaptionStringOptions,
    makePlaceholderPropertyDescriptor,
} from "../components/descriptor-utils";
import {
    type WireStringGetter,
    encodeScreenKey,
    hydrateSubsidiaryScreenFlag,
    inflateDateTimeProperty,
    inflateEditablePropertyWithDefault,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
    registerActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { ArrayContentHandlerBase } from "./array-content";
import { makeDescriptionPropertyDescriptor, makeEndDateTimePropertyDescriptor } from "./calendar-screen";

interface EventPickerArrayContentDescription extends ArrayContentDescription {
    readonly startProperty: PropertyDescription | undefined;
    readonly endProperty: PropertyDescription | undefined;
    readonly titleProperty: PropertyDescription | undefined;
    readonly descriptionProperty: PropertyDescription | undefined;

    readonly editStartProperty: PropertyDescription;
    readonly editEndProperty: PropertyDescription;

    readonly screenTitle: PropertyDescription | undefined;
    readonly placeholder: PropertyDescription | undefined;

    readonly isRequired: PropertyDescription;
}

const editStartPropertyHandler: ColumnPropertyHandler = new ColumnPropertyHandler(
    "editStartProperty",
    "Event start",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable, ColumnPropertyFlag.EditedInApp],
    undefined,
    undefined,
    getPrimitiveNonHiddenColumnsSpec,
    "date-time",
    PropertySection.DataTop
);
const editEndPropertyHandler: ColumnPropertyHandler = new ColumnPropertyHandler(
    "editEndProperty",
    "Event end",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable, ColumnPropertyFlag.EditedInApp],
    undefined,
    undefined,
    getPrimitiveNonHiddenColumnsSpec,
    "date-time",
    PropertySection.DataTop
);

export class EventPickerArrayContentHandler extends ArrayContentHandlerBase<EventPickerArrayContentDescription> {
    constructor() {
        super(ArrayScreenFormat.EventPicker);
    }

    public get helpPath(): Doc {
        return "eventPicker";
    }

    public get isEditor(): boolean {
        return true;
    }

    public get supportsEmptyArrays(): boolean {
        return true;
    }

    public get allowChangePageSize(): boolean {
        return true;
    }

    public getSpecialCaseDescriptors(): readonly ComponentSpecialCaseDescriptor[] {
        return [
            {
                name: "Event Picker",
                analyticsName: "event-picker",
                description: "Pick start and end times for an event",
                img: "co-date-picker",
                group: "Pickers",
                appKinds: AppKind.App,
            },
        ];
    }

    public getCaptionFlags(
        _desc: EventPickerArrayContentDescription | undefined
    ): CaptionPropertyDescriptorFlags | undefined {
        return { ...labelCaptionStringOptions, propertySection: PropertySection.Design };
    }

    public getDescriptiveName(
        _desc: EventPickerArrayContentDescription,
        caption: string | undefined
    ): [string, string] | undefined {
        if (caption === undefined) return undefined;
        return ["Event Picker", caption];
    }

    public get needsOutputContext(): boolean {
        return true;
    }

    public get sourcePropertyLabel(): readonly [string, boolean] {
        return ["Source", true];
    }

    public getContentPropertyDescriptors<T extends EventPickerArrayContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined,
        screenContext?: ScreenContext,
        appEnvironment?: MinimalAppEnvironment
    ): readonly PropertyDescriptor[] {
        const properties = [
            editStartPropertyHandler,
            editEndPropertyHandler,
            makeTextPropertyDescriptor(
                "screenTitle",
                "Screen Title",
                getLocalizedString("newEvent", ccc.appKind),
                false,
                mutatingScreenKind,
                {
                    preferredType: "string",
                    searchable: false,
                    emptyByDefault: true,
                    propertySection: PropertySection.Design,
                }
            ),
            makePlaceholderPropertyDescriptor(
                mutatingScreenKind,
                PropertySection.Design,
                getLocalizedString("pickADateAndTime", ccc.appKind)
            ),
        ];
        if (
            doesMutatingScreenSupportIsRequired(mutatingScreenKind, desc?.startProperty) &&
            doesMutatingScreenSupportIsRequired(mutatingScreenKind, desc?.endProperty)
        ) {
            properties.push(isRequiredPropertyHandler);
        }
        properties.push(
            ...super.getContentPropertyDescriptors(
                getPropertyTable,
                insideInlineList,
                containingScreenTables,
                desc,
                ccc,
                mutatingScreenKind,
                isDefaultArrayScreen,
                withTransforms,
                forEasyTabConfiguration,
                isFirstComponent,
                screenContext,
                appEnvironment
            ),
            {
                kind: PropertyKind.Column,
                property: { name: "startProperty" },
                section: PropertySection.Content,
                label: "Start",
                required: false,
                editable: true,
                searchable: false,
                getIndirectTable: getPropertyTable,
                columnFilter: getPrimitiveNonHiddenColumnsSpec,
                preferredNames: ["start", "begin", "event start", "event end"],
                preferredType: "date-time",
            },
            makeEndDateTimePropertyDescriptor(getPropertyTable, PropertySection.Content),
            {
                kind: PropertyKind.Column,
                property: { name: "titleProperty" },
                section: PropertySection.Content,
                label: "Title",
                required: false,
                editable: true,
                searchable: false,
                getIndirectTable: getPropertyTable,
                columnFilter: getPrimitiveNonHiddenColumnsSpec,
                preferredNames: titleProperties,
                preferredType: "string",
            },
            makeDescriptionPropertyDescriptor(getPropertyTable, PropertySection.Content)
        );
        return properties;
    }

    public getActionDescriptors(): readonly ActionPropertyDescriptor[] {
        return [];
    }

    public getBasicSearchProperties(): readonly string[] {
        return [];
    }

    public needValidation(desc: EventPickerArrayContentDescription): boolean {
        return isRequiredPropertyHandler.getSwitch(desc);
    }

    public get showIfEmpty(): boolean {
        return true;
    }

    public inflateContent<T extends EventPickerArrayContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        captionGetter: WireStringGetter | undefined,
        containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        const {
            forBuilder,
            mutatingScreenKind,
            adc: { appKind },
        } = ib;

        assert(containingRowIB !== undefined);

        const editStartProperty = editStartPropertyHandler.getProperty(desc);
        const editEndProperty = editEndPropertyHandler.getProperty(desc);

        const { getter: editStartGetter, isInContext: isStartInContext } = inflateEditablePropertyWithDefault<
            GlideDateTime | ""
        >(containingRowIB, "editStart", editStartProperty, asMaybeDate, "");
        const { getter: editEndGetter, isInContext: isEndInContext } = inflateEditablePropertyWithDefault<
            GlideDateTime | ""
        >(containingRowIB, "editEnd", editEndProperty, asMaybeDate, "");
        if (editStartGetter === undefined || editEndGetter === undefined) return undefined;

        const [startGetter, startType] = inflateDateTimeProperty(ib, desc.startProperty);
        const [endGetter, endType] = inflateDateTimeProperty(ib, desc.endProperty);
        if (startType === undefined || endType === undefined) return undefined;

        const [titleGetter] = inflateStringProperty(ib, desc.titleProperty, true);
        const [descGetter] = inflateStringProperty(ib, desc.descriptionProperty, true);

        const [screenTitleGetter] = inflateStringProperty(ib, desc.screenTitle, true);

        let placeholder = getStringProperty(desc.placeholder) ?? "";
        if (placeholder === "") {
            placeholder = getLocalizedString("pickADateAndTime", appKind);
        }
        const isRequired = mutatingScreenKind !== undefined && isRequiredPropertyHandler.getSwitch(desc);

        return makeSimpleWireTableComponentHydratorConstructor(ib, (thb, chb) => {
            assert(chb !== undefined);

            const editStart = editStartGetter(chb);
            const editEnd = editEndGetter(chb);
            if (editStart?.onChangeToken === undefined || editEnd?.onChangeToken === undefined) return undefined;

            const [subsidiaryOpen, toggleSubsidiaryRunner] = hydrateSubsidiaryScreenFlag(
                chb,
                "pickerSubsidiaryOpen",
                undefined
            );
            const toggleSubsidiaryAction = defined(registerActionRunner(chb, "togglePicker", toggleSubsidiaryRunner));

            let eventTitle: string;
            let eventDescription: string;
            if (editStart.value !== "" && editEnd.value !== "") {
                const startString = formatShortDate(editStart.value.asLocalTimeZoneAgnosticDate(), false) ?? "";
                const endString = formatShortDate(editEnd.value.asLocalTimeZoneAgnosticDate(), false) ?? "";
                if (startString === endString) {
                    eventTitle = startString;
                } else {
                    eventTitle = `${startString} — ${endString}`;
                }
                eventDescription = `${formatShortTime(
                    editStart.value.asLocalTimeZoneAgnosticDate()
                )} — ${formatShortTime(editEnd.value.asLocalTimeZoneAgnosticDate())}`;
            } else {
                eventTitle = eventDescription = "";
            }

            const component: WireAppEventPickerComponent = {
                ...spreadComponentID(componentID, forBuilder),
                kind: WireComponentKind.AppEventPicker,
                title: captionGetter?.(chb) ?? "",
                placeholder,
                isRequired,
                eventTitle,
                eventDescription,
                editStart,
                editEnd,
                onClick: toggleSubsidiaryAction,
            };

            let subsidiaryScreen: WireScreen | undefined;
            if (subsidiaryOpen.value) {
                let screenTitle = screenTitleGetter(chb) ?? "";
                if (screenTitle === "") {
                    screenTitle = getLocalizedString("newEvent", appKind);
                }

                const items = mapFilterUndefined(thb.tableScreenContext.asArray(), r => {
                    const rhb = thb.makeHydrationBackendForRow(r);
                    const start = startGetter(rhb) ?? undefined;
                    const end = endGetter(rhb) ?? undefined;
                    if (start === undefined) return undefined;

                    const item: WireAppEventPickerItem = {
                        key: r.$rowID,
                        start,
                        end,
                        title: titleGetter(rhb),
                        description: descGetter(rhb),
                    };
                    return item;
                });

                function makeDateEditable(key: string) {
                    return defined(chb).getState<GlideDateTime | "">(
                        key,
                        (v: unknown): v is GlideDateTime | "" => v === "" || v instanceof GlideDateTime,
                        "",
                        false
                    );
                }
                const startEditable = makeDateEditable("start");
                const endEditable = makeDateEditable("end");

                const picker: WireAppEventPickerScreenComponent = {
                    kind: WireComponentKind.AppEventPickerScreen,
                    items,
                    editStart: startEditable,
                    editEnd: endEditable,
                };
                const cancelMenuItem: WireAppMenuItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title: getLocalizedString("cancel", appKind),
                    icon: "00-01-glide-close",
                    style: "platform-cancel",
                    purpose: undefined,
                    action: toggleSubsidiaryAction,
                };
                const doneMenuItem: WireAppMenuItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title: getLocalizedString("done", appKind),
                    icon: "00-01-glide-check",
                    style: "platform-accept",
                    purpose: MenuItemPurpose.SetValue,
                    action: {
                        token: chb.registerAction("done", async ab => {
                            ab.valueChanged(
                                defined(editStart.onChangeToken),
                                startEditable.value,
                                ValueChangeSource.User
                            );
                            ab.valueChanged(defined(editEnd.onChangeToken), endEditable.value, ValueChangeSource.User);
                            ab.valueChanged(subsidiaryOpen.onChangeToken, false, ValueChangeSource.User);
                            return WireActionResult.nondescriptSuccess();
                        }),
                    },
                };
                subsidiaryScreen = {
                    key: encodeScreenKey("picker"),
                    title: screenTitle,
                    flags: [],
                    specialComponents: [cancelMenuItem, doneMenuItem],
                    components: [picker],
                    isInModal: true,
                    tabIcon: "",
                };
            }

            const hasValue = (editStart?.value ?? "") !== "" && (editEnd?.value ?? "") !== "";

            return {
                component,
                isValid: !isRequired || hasValue,
                editsInContext: isStartInContext === true || isEndInContext === true,
                hasValue,
                subsidiaryScreen,
            };
        });
    }

    public convertArrayScreenToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }

    public convertInlineToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }
}
