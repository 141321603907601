import "twin.macro";

import { useMediaQuery } from "@glide/common";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import { isDefined } from "@glide/support";
import classNames from "classnames";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

type ResponsiveSizeClass = "sm" | "md" | "lg" | "xl" | "2xl";

const defaultResponsivePrefix = "gp";

interface ResponsiveProviderProps extends React.PropsWithChildren {
    id?: string;
    className?: string;
    isRoot?: boolean;
    maxSize?: ResponsiveSizeClass;
    testID?: string;
}

export const isSmallScreen = (sizeClass: ResponsiveSizeClass | undefined) =>
    sizeClass === undefined || sizeClass === "sm";

export const ResponsiveProvider: React.FC<React.PropsWithChildren<ResponsiveProviderProps>> = p => {
    const { isRoot, maxSize = "2xl", id, className, testID } = p;
    const prefix = isRoot ? "page" : defaultResponsivePrefix;
    const rootResponsiveSizeClass = useRootResponsiveSizeClass();
    let canHover = useMediaQuery("(any-hover: hover) and (any-pointer: fine)");
    const mockResponsiveProvider = useMockResponsiveSizeClassWidth();
    const [defaultWidth, setDefaultWidth] = React.useState(mockResponsiveProvider?.mockWidth ?? 0);
    const [hasDefaultWidth, setHasDefaultWidth] = React.useState(mockResponsiveProvider?.mockHasDefaultWidth ?? false);

    if (isDefined(mockResponsiveProvider)) {
        canHover = mockResponsiveProvider.mockCanHover;
    }

    const targetRef = React.useRef<HTMLDivElement>(null);
    React.useLayoutEffect(() => {
        if (mockResponsiveProvider?.mockWidth === undefined) {
            setDefaultWidth(targetRef.current?.clientWidth ?? 0);
        }
        if (mockResponsiveProvider?.mockHasDefaultWidth === undefined) {
            setHasDefaultWidth(true);
        }
    }, [mockResponsiveProvider?.mockHasDefaultWidth, mockResponsiveProvider?.mockWidth]);

    const resizeProps = useResizeDetector({ handleWidth: true, handleHeight: false, targetRef });
    let width = resizeProps.width;
    let cname = "";
    let sizeClass: ResponsiveSizeClass | undefined;
    width = width ?? defaultWidth;
    if (width > 400) {
        cname += `${prefix}-sm `;
        sizeClass = "sm";
    }
    if (width > 640) {
        cname += `${prefix}-md `;
        sizeClass = "md";
    }
    if (width > 768 && (maxSize === "2xl" || maxSize === "xl" || maxSize === "lg")) {
        cname += `${prefix}-lg `;
        sizeClass = "lg";
    }
    if (width > 1024 && (maxSize === "2xl" || maxSize === "xl")) {
        cname += `${prefix}-xl `;
        sizeClass = "xl";
    }
    if (width > 1280 && maxSize === "2xl") {
        cname += `${prefix}-2xl `;
        sizeClass = "2xl";
    }

    const smallScreen = isRoot ? isSmallScreen(sizeClass) : isSmallScreen(rootResponsiveSizeClass);
    const needsHover = isRoot && canHover && !smallScreen;
    const rootResponsiveSizeClassValue = isRoot ? sizeClass : rootResponsiveSizeClass;

    return (
        <div
            ref={targetRef}
            id={id}
            className={classNames(cname, className, `max-size-${maxSize}`, needsHover && "is-hoverable")}
            data-testid={testID}>
            {hasDefaultWidth ? (
                <RootResponsiveSizeClassContext.Provider value={rootResponsiveSizeClassValue}>
                    <CanHoverContext.Provider value={canHover}>
                        <ResponsiveSizeContext.Provider value={sizeClass}>
                            <ResponsiveWidthContext.Provider value={width}>
                                {p.children}
                            </ResponsiveWidthContext.Provider>
                        </ResponsiveSizeContext.Provider>
                    </CanHoverContext.Provider>
                </RootResponsiveSizeClassContext.Provider>
            ) : null}
        </div>
    );
};

interface ResponsiveResetProps extends React.PropsWithChildren {
    className?: string;
}

export const ResponsiveWidthContext = React.createContext<number>(0);

export function useResponsiveWidth(): number {
    return React.useContext(ResponsiveWidthContext);
}

/**
 * Sadly, we can't nest this. You can only reset the responsive container one level deep.
 */
export const ResponsiveReset: React.FC<React.PropsWithChildren<ResponsiveResetProps>> = p => {
    return <div className={classNames(p.className, "responsive-blocker")}>{p.children}</div>;
};

export const ResponsiveSizeContext = React.createContext<ResponsiveSizeClass | undefined>(undefined);

export function useResponsiveSizeClass(): ResponsiveSizeClass | undefined {
    return React.useContext(ResponsiveSizeContext);
}

export const RootResponsiveSizeClassContext = React.createContext<ResponsiveSizeClass | undefined>(undefined);

export function useRootResponsiveSizeClass(): ResponsiveSizeClass | undefined {
    return React.useContext(RootResponsiveSizeClassContext);
}

export const CanHoverContext = React.createContext<boolean>(
    typeof window !== "undefined" ? window.matchMedia("(any-hover: hover) and (any-pointer: fine)").matches : false
);

function useCanHoverContext(): boolean {
    return React.useContext(CanHoverContext);
}

export const useIsHoverable = (): boolean => {
    const sizeClass = useRootResponsiveSizeClass();
    const canHover = useCanHoverContext();
    const smallScreen = isSmallScreen(sizeClass);
    return isPlayer() ? canHover : !smallScreen;
};

// This is a helper for testing different screen sizes on wire-renderer.
interface MockResponsiveProvider {
    readonly mockWidth: number;
    readonly mockCanHover: boolean;
    readonly mockHasDefaultWidth: boolean;
}
const MockResponsiveProviderContext = React.createContext<ResponsiveSizeClass | undefined>(undefined);

export const MockResponsiveProvider: React.FC<
    React.PropsWithChildren<{ mockSizeClass: ResponsiveSizeClass } & React.PropsWithChildren>
> = p => {
    return (
        <MockResponsiveProviderContext.Provider value={p.mockSizeClass}>
            {p.children}
        </MockResponsiveProviderContext.Provider>
    );
};

function useMockResponsiveSizeClassWidth(): MockResponsiveProvider | undefined {
    const mockedSizeClass = React.useContext(MockResponsiveProviderContext);

    if (mockedSizeClass === "sm") {
        return { mockWidth: 401, mockCanHover: false, mockHasDefaultWidth: true };
    } else if (mockedSizeClass === "md") {
        return { mockWidth: 641, mockCanHover: false, mockHasDefaultWidth: true };
    } else if (mockedSizeClass === "lg") {
        return { mockWidth: 769, mockCanHover: true, mockHasDefaultWidth: true };
    } else if (mockedSizeClass === "xl") {
        return { mockWidth: 1025, mockCanHover: true, mockHasDefaultWidth: true };
    } else if (mockedSizeClass === "2xl") {
        return { mockWidth: 1281, mockCanHover: true, mockHasDefaultWidth: true };
    }
    return undefined;
}
