/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import md5 from "blueimp-md5";

const { Result } = glide;

export const plugin = glide.newNativePlugin({
    id: "gravatar",
    name: "Gravatar",
    description: "Convert emails to gravatar based icons",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/gravatar.png",
    tier: "free",
});

const email = glide.makeParameter({
    type: "string",
    name: "email",
    description: "email for gravatar",
    placeholder: "e.g. alma@example.com",
    required: true,
});

const alreadyCharged = new Set<string>();

plugin.addClientColumn({
    id: "get-gravatar-icon",
    name: "Get gravatar icon",
    description: "Get a gravatar icon from an email",
    parameters: { email },
    execute: async (_context, { email = "" }) => {
        const trimmed = email.trim().toLowerCase();
        const hash = md5(trimmed);
        const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?s=200`;

        if (!alreadyCharged.has(trimmed)) {
            alreadyCharged.add(trimmed);
        }
        return Result.Ok(gravatarUrl);
    },
    result: "string",
});
