export const TITLE_BAR_TAP = "TITLE_BAR_TAP";
export const COMPONENTS_CHANGED = "COMPONENTS_CHANGED";
export const SCROLL_CHANGED = "SCROLL_CHANGED";

export const APP_MODAL_ROOT = "app-modal-root";
export const APP_OVERLAY_ROOT = "app-overlay-root";
export const PUSH_MODAL_ROOT = "push-modal-root";
export const FLYOUT_ROOT = "flyout-root";

export const APP_ROOT = "app-root";

export const TABLET_CONTENT_ROOT = "tablet";
export const PHONE_CONTENT_ROOT = "phone-content";

export const softEnforcementDismissTimeoutMS = 3000;
