import type { EminenceFlags } from "@glide/billing-types";
import { getFeatureSetting } from "./feature-settings";
import type { OfflineQueue } from "./components/types";
import { exceptionToString } from "@glideapps/ts-necessities";
import { logError, isArray, getCurrentTimestampInMilliseconds, logInfo } from "@glide/support";
import { NetworkStatus } from "./network-status";
import { frontendSendEvent } from "./tracing";

export function supportsOfflineActionQueue(eminenceFlags: EminenceFlags): boolean {
    return eminenceFlags.offlineActionQueue || getFeatureSetting("offlineActionQueueForEverybody");
}

export interface OfflineQueueSaveOperations {
    getItem: (key: string) => string | undefined;
    setItem: (key: string, value: string) => void;
}

export class OfflineQueueImplWithoutOnlineHooks<T> implements OfflineQueue<T> {
    private readonly jobsProcessing = new Set<string>();

    constructor(
        private readonly name: string,
        private readonly onOnline: () => void,
        private readonly idForItem: (item: T) => string,
        private readonly ops: OfflineQueueSaveOperations
    ) {}

    protected readonly onlineChanged = (online: NetworkStatus) => {
        if (online !== NetworkStatus.Offline) {
            this.onOnline();
        }
    };

    protected getIsOnline(): boolean {
        return true;
    }

    private get localStorageKey(): string {
        return "offline-queue-" + this.name;
    }

    private memoryQueue: readonly T[] | undefined;

    private loadQueue(): readonly T[] {
        if (this.memoryQueue !== undefined) return this.memoryQueue;

        try {
            const queueString = this.ops.getItem(this.localStorageKey);
            if (queueString === undefined) {
                this.memoryQueue = [];
                return [];
            }

            const queue = JSON.parse(queueString);
            if (!isArray(queue)) {
                logError("offline queue is not an array", this.name, queueString);
                this.memoryQueue = [];
                return [];
            }

            return queue as T[];
        } catch (e: unknown) {
            logError("Error loading offline queue", exceptionToString(e));
            this.memoryQueue = [];
            return [];
        }
    }

    private saveQueue(queue: readonly T[]): void {
        this.memoryQueue = queue;
        logInfo("Saving offline queue", this.memoryQueue);
        this.ops.setItem(this.localStorageKey, JSON.stringify(this.memoryQueue));
    }

    public enqueue(item: T): void {
        this.saveQueue([...this.loadQueue(), item]);
    }

    public previewQueueIfOnline(): Iterable<T> {
        return [...this.loadQueue()];
    }

    public claimItem(item: T): boolean {
        const id = this.idForItem(item);
        if (this.jobsProcessing.has(id)) return false;

        this.jobsProcessing.add(id);
        return true;
    }

    public confirmItem(item: T): void {
        const id = this.idForItem(item);
        this.saveQueue(
            this.loadQueue().filter(i => {
                return this.idForItem(i) !== id;
            })
        );
        this.jobsProcessing.delete(id);
    }

    public returnItem(item: T): void {
        const id = this.idForItem(item);
        this.jobsProcessing.delete(id);
    }

    // If `processItem` did process the item, it must return `true`, in which
    // case the queue removes the item.
    public async processIfOnline(processItem: (item: T) => Promise<boolean>): Promise<void> {
        for (;;) {
            const queue = this.loadQueue();

            let allSkipped = true;
            for (const item of queue) {
                const claimed = this.claimItem(item);
                if (!claimed) continue;

                let didProcess: boolean;
                const processStartTime = getCurrentTimestampInMilliseconds();
                try {
                    didProcess = await processItem(item);
                } catch (e: unknown) {
                    logError("Error processing offline queue item", this.name, item, exceptionToString(e));
                    frontendSendEvent(
                        "offline queue processing failure",
                        getCurrentTimestampInMilliseconds() - processStartTime,
                        { exception: exceptionToString(e), queue_name: this.name }
                    );
                    didProcess = true;
                }

                if (didProcess) {
                    allSkipped = false;
                    this.confirmItem(item);
                } else {
                    this.returnItem(item);
                }
            }

            if (allSkipped) break;
        }
    }

    public forEach(processItem: (item: T) => void): void {
        const queue = this.loadQueue();
        for (const item of queue) {
            processItem(item);
        }
    }

    public retire(): void {
        // nothing to do
    }
}
