import { styled } from "@glide/common";

export const BaseScreenStyle = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    background-color: ${p => p.theme.bgFront};
    overflow-y: auto;
    color: ${p => p.theme.textBase};
`;
