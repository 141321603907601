import * as React from "react";

import type { DropdownItem } from "./dropdown-menu-types";
import { DropdownAnchor } from "./dropdown-menu-types";
import { DropdownMenuItem, DropdownWrapper, DropdownMenu } from "./dropdown-menu-style";

interface Props extends React.PropsWithChildren {
    menu: ReadonlyArray<DropdownItem>;
    anchor: DropdownAnchor;
    minWidth?: number;
}
interface State {
    dropdownVisible: boolean;
    effectiveAnchor: DropdownAnchor;
}

export class Dropdown extends React.PureComponent<Props, State> {
    public state: State = { dropdownVisible: false, effectiveAnchor: DropdownAnchor.BottomLeft };

    private wrapperRef = React.createRef<any>();
    private buttonRef = React.createRef<any>();

    public componentDidMount() {
        document.addEventListener("mousedown", this.clickOutside);
        this.updateAnchor();
    }

    public componentDidUpdate() {
        this.updateAnchor();
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.clickOutside);
    }

    private updateAnchor(): void {
        const current = this.state.effectiveAnchor;
        let target = this.props.anchor;

        if (this.props.anchor === DropdownAnchor.SmartRight) {
            const height = window.innerHeight;

            const y = this.buttonRef.current.getBoundingClientRect().top;

            if (y > height / 2) {
                target = DropdownAnchor.TopRight;
            } else {
                target = DropdownAnchor.BottomRight;
            }
        }

        if (current !== target) {
            this.setState({ effectiveAnchor: target });
        }
    }

    private clickOutside = (e: MouseEvent) => this.handleClickOutside(e);
    private handleClickOutside(event: MouseEvent) {
        if (
            this.state.dropdownVisible &&
            this.wrapperRef.current !== null &&
            !this.wrapperRef.current.contains(event.target) &&
            this.buttonRef.current !== null &&
            !this.buttonRef.current.contains(event.target)
        ) {
            this.setState({ dropdownVisible: false });
        }
    }

    private handleClick(item: DropdownItem) {
        if (item.onClick !== undefined) {
            item.onClick();
        }

        this.setState({ dropdownVisible: false });
    }

    public render(): React.ReactNode {
        const menuItems = this.props.menu.map((mi, i) => {
            const shadow = mi;
            return (
                <DropdownMenuItem
                    key={i}
                    disabled={mi.isEnabled !== undefined && !mi.isEnabled}
                    onClick={() => this.handleClick(shadow)}>
                    {shadow.content}
                </DropdownMenuItem>
            );
        });

        return (
            <DropdownWrapper
                ref={this.buttonRef}
                style={{ cursor: "pointer" }}
                onClick={e => {
                    this.setState({ dropdownVisible: !this.state.dropdownVisible });
                    e.stopPropagation();
                }}>
                {this.props.children}
                <DropdownMenu
                    ref={this.wrapperRef}
                    minWidth={this.props.minWidth ?? 200}
                    isHidden={!this.state.dropdownVisible}
                    anchor={this.state.effectiveAnchor}>
                    {menuItems}
                </DropdownMenu>
            </DropdownWrapper>
        );
    }
}
