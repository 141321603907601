import type { AppDescription, UserFeatures, BuilderActionWithID, BuilderActionsForApp } from "@glide/app-description";
import type { AppKind } from "@glide/location-common";
import { getAppKind, getSourceMetadata } from "@glide/common-core/dist/js/components/SerializedApp";
import {
    type TableName,
    areTableNamesEqual,
    type TypeSchema,
    findTable,
    type UserProfileTableInfo,
    type SourceMetadata,
    type SchemaInspector,
} from "@glide/type-schema";
import type { EminenceFlags } from "@glide/billing-types";
import { memoizeFunction } from "@glide/support";
import type {
    AppDescriptionContext,
    ExistingAppDescriptionContext,
    RewritingComponentConfiguratorContext,
} from "@glide/function-utils";
import { definedMap } from "@glideapps/ts-necessities";

import { getUserProfileTableInfo } from "../user-profile-info";

export function makeSimpleSchemaInspector(
    schema: TypeSchema,
    sourceMetadata: readonly SourceMetadata[] | undefined,
    userProfileTableInfo: UserProfileTableInfo | undefined
): SchemaInspector {
    return {
        schema,
        userProfileTableInfo,
        sourceMetadata,
        findTable: r => findTable(schema, r, sourceMetadata),
    };
}

export function getBuilderActionsForTable(
    appID: string,
    builderActions: BuilderActionsForApp,
    tableName: TableName
): BuilderActionWithID[] {
    return [...builderActions.entries()]
        .map(([id, i]) => ({ id, ...i }))
        .filter(i => areTableNamesEqual(i.perApp[appID]?.tableName, tableName));
}

function makeSimpleAppDescriptionContextUnmemoized(
    appID: string,
    appKind: AppKind,
    appDescription: AppDescription,
    builderActions: BuilderActionsForApp | undefined,
    schema: TypeSchema,
    userFeatures: UserFeatures,
    eminenceFlags: EminenceFlags
): ExistingAppDescriptionContext;
function makeSimpleAppDescriptionContextUnmemoized(
    appID: string,
    appKind: AppKind,
    appDescription: AppDescription | undefined,
    builderActions: BuilderActionsForApp | undefined,
    schema: TypeSchema,
    userFeatures: UserFeatures,
    eminenceFlags: EminenceFlags
): AppDescriptionContext;
function makeSimpleAppDescriptionContextUnmemoized(
    appID: string,
    appKind: AppKind,
    appDescription: AppDescription | undefined,
    builderActions: BuilderActionsForApp | undefined,
    schema: TypeSchema,
    userFeatures: UserFeatures,
    eminenceFlags: EminenceFlags
): AppDescriptionContext {
    return {
        ...makeSimpleSchemaInspector(
            schema,
            definedMap(appDescription, getSourceMetadata),
            definedMap(appDescription, getUserProfileTableInfo)
        ),
        appID,
        appKind,
        appDescription,
        builderActions: builderActions ?? new Map(),
        userFeatures,
        eminenceFlags,
        webhookIntegrations: [],
        builderComputationModel: undefined,
        getBuilderAction(id) {
            return builderActions?.get(id);
        },
        getBuilderActionsForTable(tableName: TableName) {
            return definedMap(builderActions, a => getBuilderActionsForTable(appID, a, tableName)) ?? [];
        },
        getIsRewritingComponentConfiguratorContext() {
            return false;
        },
    };
}

export const makeSimpleAppDescriptionContext = memoizeFunction(
    "makeSimpleAppDescriptionContext",
    makeSimpleAppDescriptionContextUnmemoized
);

export function elevateAppDescriptionContext<T extends AppDescriptionContext>(
    adc: T
): (T & ExistingAppDescriptionContext) | undefined {
    if (adc.appDescription === undefined) return undefined;
    return adc as T & ExistingAppDescriptionContext;
}

export function makeSimpleComponentConfiguratorContext(
    appID: string,
    appDescription: AppDescription,
    builderActions: BuilderActionsForApp,
    schema: TypeSchema,
    userFeatures: UserFeatures,
    eminenceFlags: EminenceFlags
): RewritingComponentConfiguratorContext {
    return {
        ...makeSimpleAppDescriptionContext(
            appID,
            getAppKind(appDescription),
            appDescription,
            builderActions,
            schema,
            userFeatures,
            eminenceFlags
        ),
        appKind: getAppKind(appDescription),
        getBuilderAction: id => builderActions.get(id),
        requireScreen: n => appDescription.screenDescriptions[n] !== undefined,
        requireMenuScreen: () => true,
    };
}
