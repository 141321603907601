import { definedMap } from "@glideapps/ts-necessities";

let forceFunctionsEmulator = false;

export function forceFunctionsEmulatorMode(newMode: boolean) {
    forceFunctionsEmulator = newMode;
}

export function isFunctionsEmulator(): boolean {
    return forceFunctionsEmulator || process.env.FUNCTIONS_EMULATOR === "true";
}

export function projectId(): string {
    const { GCP_PROJECT, FIREBASE_CONFIG } = process.env;
    if (GCP_PROJECT !== undefined) {
        return GCP_PROJECT;
    }
    const firebaseConfig = definedMap(FIREBASE_CONFIG, s => JSON.parse(s));
    if (firebaseConfig !== undefined) {
        return firebaseConfig.projectId;
    }
    throw new Error("Could not determine project ID - must set GCP_PROJECT or FIREBASE_CONFIG.");
}
