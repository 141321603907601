import * as t from "io-ts";
import { isFavoritedColumnName, rowIndexColumnName, type TableColumn, makePrimitiveType } from "@glide/type-schema";

// FIXME: Almost none of this has anything to do with Google Sheets

export function makeRowIndexTableColumn(typeKind: "number" | "string"): TableColumn {
    return {
        name: rowIndexColumnName,
        type: makePrimitiveType(typeKind),
        hidden: true,
        isProtected: false,
        isUserSpecific: false,
        isReadOnly: true,
    };
}

// FIXME: These don't belong here, as they have nothing to do with Google Sheets

export const isFavoritedTableColumn: TableColumn = {
    name: isFavoritedColumnName,
    displayName: "Is Favorited?",
    type: makePrimitiveType("boolean"),
    isUserSpecific: true,
    isProtected: false,
};

export const rowAddedByJobColumnName = "$rowAddedByJob";
// Note that we don't have a TableColumn for this. It's not actually important
// to the schema; it's only used as part of the data store's update reconciliation.

export const defaultRowIDColumnName = "🔒 Row ID";

export const refreshResultCodec = t.union([
    t.literal("refreshed"),
    t.literal("no-changes"),
    t.literal("reload-disabled"),
    t.literal("quota-reached"),
    t.literal("automatic-refresh-only"),
    t.literal("requested"),
    t.literal("incompatible"),
    t.literal("error"),
    t.literal("timeout"),
]);
export type RefreshResult = t.TypeOf<typeof refreshResultCodec>;
