import * as React from "react";

const IsInsideOverlayContext = React.createContext<boolean | undefined>(undefined);

interface IsInsideOverlayProviderProps {
    readonly isInsideOverlay: boolean;
}
export const IsInsideOverlayProvider: React.FC<React.PropsWithChildren<IsInsideOverlayProviderProps>> = p => {
    const { isInsideOverlay, children } = p;

    return <IsInsideOverlayContext.Provider value={isInsideOverlay}>{children}</IsInsideOverlayContext.Provider>;
};

export function useIsInsideOverlay() {
    const isInsideOverlay = React.useContext(IsInsideOverlayContext);
    return isInsideOverlay === true;
}
