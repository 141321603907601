import { logError } from "@glide/support";
import { defined } from "@glideapps/ts-necessities";

class AudioPlayer {
    private audioElement: HTMLAudioElement;
    constructor(src: string) {
        this.audioElement = new Audio(src);
        this.audioElement.preload = "auto";
    }

    public play() {
        this.audioElement.currentTime = 0;
        this.audioElement.play().catch(err => {
            logError(err);
        });
    }
}

const audioCache = new Map<string, AudioPlayer>();

export function getAudioPlayer(src: string) {
    if (audioCache.has(src)) {
        return defined(audioCache.get(src));
    }

    const audioPlayer = new AudioPlayer(src);
    audioCache.set(src, audioPlayer);
    return audioPlayer;
}
