/* eslint-disable @typescript-eslint/no-shadow */
import { GlideDateTime, GlideJSON } from "@glide/data-types";
import * as glide from "@glide/plugins";
import { isPluginTable } from "@glide/plugins-codecs";
import { stringify } from "csv-stringify/sync";
import parse from "csv-parse/lib/sync";

const { Result } = glide;

export const plugin = glide.newPlugin({
    id: "generate-csv", // DO NOT CHANGE
    name: "CSV",
    description: "Generate a CSV file from a row, a table, or a relation",
    icon: "https://res.cloudinary.com/glide/image/upload/plugins/csv.png",
    tier: "free", // FIXME: Is this really the right tier to work at?
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/generate-csv",
});

plugin.addClientAction({
    id: "generate-csv-file",
    name: "Generate CSV file",
    description: "Generate a CSV file from a row, a table, or a relation",
    group: "Data",
    icon: {
        kind: "stroke",
        icon: "st-generate-csv",
        strokeFgColor: "var(--gv-icon-base)",
        strokeColor: "var(--gv-lime500)",
    },
    billablesConsumed: 0,
    parameters: {
        inputTable: glide.makeParameter({
            type: "table",
            name: "Source data",
            description: "The data that will be in the CSV file",
            required: true,
        }),
    },
    results: {
        fileName: glide.makeParameter({
            type: "string",
            name: "File output",
            description: "Choose the column where the URL to the CSV file will be saved",
        }),
    },
    async execute(context, { inputTable }) {
        if (!isPluginTable(inputTable))
            return Result.FailPermanent("Invalid input table", {
                isPluginError: false,
            });
        const { columns, rows } = inputTable;
        const headerRow = columns.map(c => c.displayName ?? c.name);
        const output = stringify([headerRow, ...rows], {
            cast: {
                object: (o: Record<string, unknown>) => {
                    if (o instanceof GlideDateTime) return o.toString();
                    if (o instanceof GlideJSON) return o.jsonString;
                    return Object.prototype.toString.call(o);
                },
            },
            bom: true,
        });
        const uploaded = await context.uploadFile(`${inputTable.name.replace(".", "_")}.csv`, "text/csv", output);
        if (!uploaded.ok) return uploaded;
        context.consumeBillable();
        return Result.Ok({ fileName: uploaded.result });
    },
});

// This only exists to validate that server-side file upload functions correctly
/*
plugin.addAction({
    name: "Generate CSV (Server Side)",
    description: "Generate a CSV file from a table or relation (for debugging purposes only)",
    parameters: {
        inputTable: glide.makeParameter({
            type: "table",
            name: "Input Table",
            description: "Table to transform into CSV",
        }),
    },
    results: {
        fileName: glide.makeParameter({
            type: "string",
            name: "Uploaded CSV",
            description: "A link to the uploaded CSV",
        }),
    },
    async execute({ inputTable }, context) {
        if (!glide.isPluginTableType(inputTable)) return Result.Fail("Invalid input table");
        const { columns, rows } = inputTable;
        const headerRow = columns.map(c => c.displayName ?? c.name);
        const output = stringify([headerRow, ...rows], {
            cast: {
                object: (o: Record<string, unknown>) => {
                    return o instanceof GlideDateTime ? o.toString() : Object.prototype.toString.call(o);
                },
            },
        });
        const fileName = await context.uploadFile(`${inputTable.name.replace(".", "_")}.csv`, "text/csv", output);
        return Result.Ok({ fileName });
    },
});
// */

const csvString = glide.makeParameter({
    name: "CSV String",
    type: "string",
    description: "The CSV string to convert into JSON objects (not a URL to a CSV file)",
    required: true,
});

const json = glide.makeParameter({
    name: "JSON",
    type: "json",
});

type CSVRecord = Record<string, glide.JSONValue>;

function csvToJSONObjects(csvString: string): glide.Result<CSVRecord[]> {
    try {
        const records = parse(csvString, {
            columns: true,
            skip_empty_lines: true,
            cast: true,
        }) as CSVRecord[];
        return Result.Ok(records);
    } catch (error: unknown) {
        return Result.FailPermanent(`Error parsing CSV: ${error}`);
    }
}

function isValidURL(str: string): boolean {
    try {
        new URL(str);
        return true;
    } catch {
        return false;
    }
}

plugin.addClientComputation({
    id: "csv-to-json",
    name: "CSV to JSON",
    description: "Converts a CSV string into a JSON string",
    parameters: {
        csvString,
    },
    results: {
        json,
    },
    execute: async (_context, { csvString }) => {
        if (csvString === undefined) {
            return Result.FailPermanent("CSV string is required", { isPluginError: false });
        }

        if (isValidURL(csvString.trim())) {
            return Result.FailPermanent(
                "Please provide the actual CSV content as a string, not a URL. If you need to download CSV from a URL, use 'Call API'.",
                { isPluginError: false }
            );
        }

        const objects = csvToJSONObjects(csvString);
        if (objects.ok) {
            return Result.Ok({ json: objects.result });
        } else {
            return objects;
        }
    },
});
