import { logError } from "@glide/support";
import { getFeatureSettingProbability } from "../feature-settings";
import { getIsOnline } from "../hooks/use-network-status";
import { maybeFrontendTrace } from "../tracing";
import { blockingServiceWorkerInstall } from "../utility/register-service-worker";

// Reproduced here because Support.ts is massive
// and we can't afford the importz
function sleep(time: number, signal?: AbortSignal) {
    return new Promise(resolve => {
        const sleepTimeout = setTimeout(resolve, time);
        signal?.addEventListener("abort", () => clearTimeout(sleepTimeout));
    });
}

// This should be the same as the Service Worker.
const timeout = 60000;

export class LazyLoadError extends Error {}

export async function lazyLoading<T>(
    assetName: string,
    canFailIfOffline: boolean,
    loader: () => Promise<T>
): Promise<T> {
    const abortController = new AbortController();
    let timedOut = false;

    // Only report on 5% of the JavaScript loads; we want to reduce Honeycomb costs.
    const result = await maybeFrontendTrace(
        "lazyLoad",
        { assetName },
        getFeatureSettingProbability("traceLazyLoadingProbability"),
        async () => {
            try {
                const loadResult = await Promise.race([
                    blockingServiceWorkerInstall(() => loader()).then(content => ({
                        kind: "loaded" as const,
                        content,
                    })),
                    sleep(timeout, abortController.signal).then(() => ({ kind: "timeout" as const })),
                ]);
                abortController.abort();
                if (loadResult.kind === "loaded") return loadResult.content;
                timedOut = true;
                throw new Error(`Timed out after ${timeout} ms`);
            } catch (e: unknown) {
                logError(`Lazy-loading ${assetName}`, e);
                return undefined;
            }
        }
    );

    // If we can't actually load the asset, we're toast.
    // Let's bail and, if we're online, try all over again.
    if (result === undefined) {
        const failureReason = timedOut ? "timeout" : "network";
        if (canFailIfOffline && !getIsOnline()) {
            throw new LazyLoadError(
                `Failed to load asset (${failureReason}), and the browser appears offline. Load canceled.`
            );
        }

        (window as any).showNetworkRetryButton();
        return new Promise<T>(() => {
            // Do nothing here; as of current we can't recover from a load
            // failure and just need to show the "Retry" button instead.
        });
    }

    return result;
}

export function makeLazyLoader<T>(
    assetName: string,
    { canFailIfOffline, loadImmediately }: { canFailIfOffline: boolean; loadImmediately: boolean },
    loader: () => Promise<T>
): () => Promise<T> {
    let promise: Promise<T> | undefined;

    const fn = () => {
        if (promise === undefined) {
            promise = lazyLoading(assetName, canFailIfOffline, loader);
        }
        return promise;
    };

    if (loadImmediately) {
        void fn();
    }

    return fn;
}
