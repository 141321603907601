import type { Description, SourceColumn } from "@glide/type-schema";
import { mapRecord } from "@glideapps/ts-necessities";
import {
    getArrayProperty,
    getSourceColumnProperty,
    isPropertyDescription,
    makeArrayProperty,
    makeSourceColumnProperty,
} from "./property-utils";

export interface DescriptionVisitor {
    // Returning `undefined` means don't change the property.
    visitColumn(sc: SourceColumn): SourceColumn | undefined;

    // We don't currently need this.
    // visitTable(table: TableName): TableName | undefined;
}

// This will walk a description without a descriptor, relying only on the
// propery kinds.
export function walkDescriptionUntyped<TDesc extends Description>(desc: TDesc, visitor: DescriptionVisitor): TDesc {
    function visit<T>(d: any): T {
        return mapRecord(d, p => {
            if (!isPropertyDescription(p)) return p;

            const sc = getSourceColumnProperty(p);
            if (sc !== undefined) {
                return makeSourceColumnProperty(visitor.visitColumn(sc) ?? sc);
            }

            // const tn = getTableProperty(p);
            // if (tn !== undefined) {
            //     return makeTableProperty(visitor.visitTable(tn) ?? tn);
            // }

            const arr = getArrayProperty(p);
            if (arr !== undefined) {
                return makeArrayProperty(arr.map(visit));
            }

            return p;
        }) as T;
    }

    return visit(desc);
}
