import styled, { css } from "styled-components";

interface IconWrapperProps {
    size: number;
    roundingSize: number;
    background: string;
    clipped: boolean;
    showPointer: boolean;
    omitShadow: boolean;
    fullscreen: boolean;
}

interface ImageStyleProps {
    roundingSize: number;
    clipped: boolean;
    padding?: number;
    lightGradientOverlay?: boolean;
}

// Clip needs to be done with the extra rounding on the ::before content so that
// when the two are overlayed you dont get a colored "halo" around the corners

const clip = (props: IconWrapperProps) =>
    props.clipped &&
    css`
        border-radius: ${props.roundingSize}px;
        ${!props.omitShadow &&
        `box-shadow: 0px ${props.roundingSize / 2}px ${props.roundingSize}px ${
            props.roundingSize / -4
        }px rgba(0, 0, 0, 0.1);`}

        ::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            clip-path: inset(0px round ${props.roundingSize + 1}px);
            background-color: ${props.background};
        }
    `;

export const ImageStyle = styled.img<ImageStyleProps>`
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;

    ${p =>
        p.lightGradientOverlay === true &&
        `background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));`}

    padding: ${p => (p.padding === undefined ? "0px" : `${p.padding}px`)};

    ${p => p.clipped && `clip-path: inset(0px round ${p.roundingSize}px);`}
`;

export const IconWrapper = styled.div<IconWrapperProps>`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    flex-shrink: 0;
    position: relative;
    ${p => p.showPointer && `cursor: pointer;`}

    ${p => !p.clipped && `background-color: ${p.background};`}

    ${p => p.fullscreen && `margin-left: auto; margin-right: auto;`}

    ${clip}
`;
