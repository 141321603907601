import {
    type StartQueryFreshener,
    GbtQueryFreshener,
    LocalDataStoreImpl,
    NoOpQueryFreshener,
    QueryableDataStoreImpl,
} from "@glide/computation-model";
import type { AppDescription, AppFeatures } from "@glide/app-description";
import { getSourceMetadata } from "@glide/common-core/dist/js/components/SerializedApp";
import type {
    AppPublishInfo,
    AppUserAuthenticator,
    MinimalAppEnvironment,
    MinimalAppFacilities,
} from "@glide/common-core/dist/js/components/types";
import { isSample } from "@glide/common-core/dist/js/database-strings";
import type { TypeSchema } from "@glide/type-schema";
import { isTemplatePlayer } from "@glide/common-core/dist/js/routes";
import { FirestoreDataStore, makeMakeFirestoreTableListener } from "@glide/firestore-datastore";
import { getUserProfileTableInfo } from "@glide/generator/dist/js/user-profile-info";
import type { SerializablePluginMetadata } from "@glide/plugins";
import type { ActionManagerAcessors } from "@glide/post-action";
import { getWebDatabase } from "@glide/client-database";
import type { EminenceFlags } from "@glide/billing-types";
import { WireAppEnvironment } from "./wire-app-environment";
import { type WriteSourceType, getFeatureSetting } from "@glide/common-core";
import { makeAppData } from "@glide/generator/dist/js/plugin-app-data";

export function makeAppEnvironmentForAppDescription(
    appDescription: AppDescription,
    schema: TypeSchema | undefined,
    appFacilities: MinimalAppFacilities,
    authenticator: AppUserAuthenticator,
    appFeatures: AppFeatures,
    isBuilder: boolean,
    writeSource: WriteSourceType,
    getPublishInfo: () => AppPublishInfo,
    pluginMetadata: readonly SerializablePluginMetadata[],
    getEminence: (appID: string) => EminenceFlags,
    appOwnerID?: string
): MinimalAppEnvironment {
    const { appID } = authenticator;
    const db = getWebDatabase();
    const isPersistent = !(isSample(appID) && isTemplatePlayer());
    const makeTableListener = makeMakeFirestoreTableListener(appFacilities, isBuilder, () =>
        appFacilities.getNumRowsUsedByAppID(appID)
    );
    const dataStore = new FirestoreDataStore(
        appID,
        db,
        schema,
        appFacilities,
        authenticator,
        {
            isBuilder,
            isAutomations: false,
            writeSource,
            isPersistent,
            makeTableListener,
        },
        pluginMetadata
    );

    const sourceMetadata = getSourceMetadata(appDescription);
    const userProfileTableInfo = getUserProfileTableInfo(appDescription);

    const appEnvironment = new WireAppEnvironment(
        appFeatures,
        sourceMetadata,
        userProfileTableInfo,
        appDescription.pluginConfigs ?? [],
        makeAppData(appID, appDescription, appFacilities),
        authenticator,
        db,
        dataStore,
        new LocalDataStoreImpl(appID),
        isBuilder,
        writeSource,
        getPublishInfo,
        getEminence
    );
    dataStore.setAppEnvironment(appEnvironment);

    const accessors: ActionManagerAcessors = {
        appFacilities() {
            return appEnvironment.appFacilities;
        },
        database() {
            return db;
        },
        appID() {
            return appID;
        },
        appUserID() {
            return authenticator.appUserID;
        },
        schema() {
            return dataStore.schema;
        },
        makeOfflineQueue(name, onOnline) {
            return appEnvironment.makeOfflineQueue(name, onOnline, i => i.data.jobID);
        },
        makeReloadResiliencyQueue(name, onOnline) {
            return appEnvironment.makeReloadResiliencyQueue(name, onOnline, i => i.data.jobID);
        },
    };

    // Note that queryableDataStores won't work with static app configs.
    const queryableDataStore = new QueryableDataStoreImpl(
        appEnvironment,
        isBuilder,
        writeSource,
        undefined,
        undefined,
        appOwnerID,
        false,
        getQueryFreshener(),
        accessors
    );
    appEnvironment.setQueryableDataStore(queryableDataStore);

    return appEnvironment;
}

function getQueryFreshener(): StartQueryFreshener {
    if (getFeatureSetting("gbtLiveUpdates")) return GbtQueryFreshener.start;
    return NoOpQueryFreshener.start;
}
