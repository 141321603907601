import type { WireComponent } from "@glide/wire";

export function getRenderableComponentIndexAtPosition(
    components: readonly (WireComponent | null)[],
    position: number
): number | null {
    if (position > components.length || position < 0) {
        return null;
    }

    let nonNullCount = 0;

    for (const [index, component] of components.entries()) {
        if (component !== null) {
            nonNullCount++;
            if (nonNullCount - 1 === position) {
                return index;
            }
        }
    }

    return null;
}
