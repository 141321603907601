import * as t from "io-ts";
import { isLeft } from "fp-ts/Either";

export enum ReverseFreeTrialStatuses {
    NotEligible = "not-eligible",
    Control = "control",
    TreatmentNotStarted = "treatment-not-started",
    TreatmentStarted = "treatment-started",
    TreatmentReadyToEnd = "treatment-ready-to-end",
    TreatmentEnded = "treatment-ended",
}

const reverseFreeTrialStatusCodec = t.union([
    t.literal(ReverseFreeTrialStatuses.NotEligible),
    t.literal(ReverseFreeTrialStatuses.Control),
    t.literal(ReverseFreeTrialStatuses.TreatmentNotStarted),
    t.literal(ReverseFreeTrialStatuses.TreatmentStarted),
    t.literal(ReverseFreeTrialStatuses.TreatmentReadyToEnd),
    t.literal(ReverseFreeTrialStatuses.TreatmentEnded),
]);

export function validateReverseFreeTrialStatusAgainstCodec(
    firestoreValue: unknown
): ReverseFreeTrialStatuses | undefined {
    const reverseFreeTrialStatusIOTSValidation = reverseFreeTrialStatusCodec.decode(firestoreValue);
    if (isLeft(reverseFreeTrialStatusIOTSValidation)) {
        return undefined;
    }

    return reverseFreeTrialStatusIOTSValidation.right;
}
