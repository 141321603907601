import "twin.macro";

import { GlideIcon, copyToClipboard as copy } from "@glide/common";
import { trackEvent } from "@glide/common-core/dist/js/analytics";
import type { AppDescription, PluginConfig, SerializedApp, PropertyDescription } from "@glide/app-description";
import type { TypeSchema } from "@glide/type-schema";
import type {
    CompletePluginResponseBody,
    TriggerRemovePluginResponseBody,
    ValidatePluginResult,
} from "@glide/common-core";
import { getUserProfileTableForAppAndSchema } from "@glide/generator/dist/js/user-profile-info";
import { performOAuthStageOne } from "@glide/common-core/dist/js/plugin-oauth-stage-one";
import { isSSOSignInEnabled } from "@glide/common-core/dist/js/utility/pins";
import type {
    CompleteExternalConfigurationStep,
    ExternalConfigurationStep,
    IncompleteExternalConfigurationStep,
    SerializablePluginMetadata,
    StrokeIcons,
} from "@glide/plugins";
import { assertNever } from "@glideapps/ts-necessities";
import { logError } from "@glide/support";
import React from "react";
import { Button } from "../button/button";
import { IntegrationDetailsHeader } from "./integration-details-header";
import { PluginParametersConfig } from "./plugin-parameters-config";
import { SettingsSectionDivider, SettingsSectionHeader } from "./setting-section-header";
import { getPluginRemovalPreventionReasons } from "../../lib/plugin-removal";
import { useAuthConfiguredPlugins } from "./use-auth-configured-plugins";
import * as routes from "@glide/common-core/dist/js/routes";
import { useHistory } from "react-router-dom";
import type { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import type { Owner } from "@glide/common-core/dist/js/Database";
import { ApiKeysList, type ClientDetails } from "./api-keys-list";
import { makeNewPluginConfig } from "../../lib/new-plugin-config";

export function canAddMultiplePluginInstances(plugin: SerializablePluginMetadata): boolean {
    return plugin.supportsMultipleInstances ?? false;
}

export const FeatureRow: React.VFC<{
    type: "action" | "column" | "sign-on" | "data-source" | "notification-sender";
    title: string;
    description?: string;
}> = ({ type, title, description }) => {
    function getIcon(): StrokeIcons {
        switch (type) {
            case "action":
                return "st-action-bolt";

            case "column":
                return "st-column-action";

            case "sign-on":
                return "st-locked";

            case "data-source":
                return "st-data";

            case "notification-sender":
                return "st-password";

            default:
                assertNever(type);
        }
    }

    return (
        <div tw="flex items-start">
            <div tw="p-1 bg-n200A rounded-md text-text-pale mr-2">
                <GlideIcon kind="stroke" icon={getIcon()} iconSize={16} strokeWidth={1} />
            </div>
            <div tw="flex flex-col justify-center [min-height: 24px]">
                <div tw="text-builder-sm font-medium">{title}</div>
                {description !== undefined && <div tw="text-builder-sm text-text-pale">{description}</div>}
            </div>
        </div>
    );
};

interface BaseExternalConfigurationStepProps<TStep extends ExternalConfigurationStep> {
    step: TStep;
    onRevalidate: () => void;
}

type ExternalConfigurationStepDisplayProps = BaseExternalConfigurationStepProps<ExternalConfigurationStep>;
type IncompleteExternalConfigurationStepDisplayProps =
    BaseExternalConfigurationStepProps<IncompleteExternalConfigurationStep>;
type CompleteExternalConfigurationStepDisplayProps =
    BaseExternalConfigurationStepProps<CompleteExternalConfigurationStep>;

const ExternalConfigurationStepDisplay: React.VFC<ExternalConfigurationStepDisplayProps> = p => {
    const { step, onRevalidate } = p;
    if (step.complete) {
        return <CompleteExternalConfigurationStepDisplay step={step} onRevalidate={onRevalidate} />;
    } else {
        return <IncompleteExternalConfigurationStepDisplay step={step} onRevalidate={onRevalidate} />;
    }
};

const IncompleteExternalConfigurationStepDisplay: React.VFC<IncompleteExternalConfigurationStepDisplayProps> = p => {
    const { step, onRevalidate } = p;
    const { title, description, backgroundImageUrl, buttonLabel, buttonUrl, revalidateLabel } = step;

    const copyLink = () => {
        copy(buttonUrl);
    };

    return (
        <div tw="flex flex-col items-center mt-4 py-4 rounded-2xl [background: linear-gradient(180deg, rgba(62, 116, 253, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%)]">
            <img src={backgroundImageUrl} alt="" tw="w-full mb-5" />
            <div tw="flex flex-col items-center px-4 w-full">
                <h4 tw="text-text-dark font-semibold mb-1 text-center text-builder-base">{title}</h4>
                <p tw="text-text-base mb-4 text-center text-builder-sm leading-normal">{description}</p>
                <div tw="flex items-center justify-between w-full mb-4">
                    <a
                        tw="grow min-w-0 truncate text-b400 text-builder-lg"
                        href={buttonUrl}
                        rel="noopener noreferrer"
                        target="_blank">
                        {buttonUrl}
                    </a>
                    <Button
                        tw="shrink-0 ml-1"
                        buttonType="secondary"
                        size="lg"
                        variant="accent"
                        label="Copy link"
                        onClick={copyLink}
                    />
                </div>
                <Button
                    tw="mb-2"
                    buttonType="primary"
                    size="lg"
                    variant="default"
                    label={buttonLabel}
                    href={buttonUrl}
                    isFullWidth={true}
                />
                <Button
                    icon="st-reload"
                    buttonType="minimal"
                    size="lg"
                    variant="default"
                    label={revalidateLabel}
                    onClick={onRevalidate}
                    isFullWidth={true}
                    centeredTextAndIcon={true}
                />
            </div>
        </div>
    );
};

const CompleteExternalConfigurationStepDisplay: React.VFC<CompleteExternalConfigurationStepDisplayProps> = p => {
    const { title, description, buttonLabel, buttonUrl } = p.step;

    const copyLink = () => {
        copy(buttonUrl);
    };

    return (
        <div tw="flex flex-col items-center mt-4 pt-5 pb-4 px-4 rounded-2xl bg-n200A">
            <div tw="p-2 rounded-full bg-n900 mb-3">
                <GlideIcon kind="stroke" icon="st-settings" iconSize={20} tw="text-text-inverse" />
            </div>
            <div tw="flex flex-col items-center w-full">
                <h4 tw="text-text-dark font-semibold mb-1 text-center text-builder-base">{title}</h4>
                <p tw="text-text-pale mb-4 text-center text-builder-sm leading-normal">{description}</p>
                <div tw="flex items-center gap-4 justify-between w-full">
                    <Button
                        tw="flex-1 text-builder-base"
                        buttonType="secondary"
                        size="lg"
                        variant="inverse"
                        label="Copy link"
                        onClick={copyLink}
                    />
                    <Button
                        tw="flex-1 text-builder-base"
                        buttonType="tertiary"
                        size="lg"
                        variant="default"
                        label={buttonLabel}
                        href={buttonUrl}
                    />
                </div>
            </div>
        </div>
    );
};

type PluginInstance<T> = (
    appID: string,
    pluginID: string,
    pluginConfigID: string,
    pluginParams: Record<string, PropertyDescription>
) => Promise<T>;

type UpdatePluginConfigParameters = (
    appID: string,
    pluginConfigID: string,
    pluginParams: Record<string, PropertyDescription>
) => void;

export interface Props extends React.PropsWithChildren {
    readonly plugin: SerializablePluginMetadata;
    readonly pluginConfig: PluginConfig | undefined;
    readonly appID: string;
    readonly appDesc: AppDescription;
    readonly schema: TypeSchema | undefined;
    readonly owner: Owner;
    readonly validatePluginConfig: PluginInstance<ValidatePluginResult>;
    readonly completePluginConfig: PluginInstance<CompletePluginResponseBody>;
    readonly triggerRemovePlugin: PluginInstance<TriggerRemovePluginResponseBody>;
    readonly updatePluginConfig: (appID: string, pluginConfig: SerializedApp["pluginConfigs"]) => void;
    readonly updatePluginConfigParameters: UpdatePluginConfigParameters;
    readonly requiredPlan: PlanKind | undefined;
    // FIXME: This is basically "are plugins enabled and is this a page?".  At
    // this point plugins are enabled, and `appDesc` tells us whether this is
    // a page, so we should be able to remove this.
    readonly canAdd: boolean;
    readonly allPlugins: readonly SerializablePluginMetadata[];
    readonly useAPIKeyForPlugin: (
        userID: string,
        clientDetails: ClientDetails
    ) => {
        isLoading: boolean;
        error: unknown;
        apiKeys: Record<string, string | undefined> | undefined;
    };
}

interface ValidateState extends ValidatePluginResult {
    readonly timeout: ReturnType<typeof setTimeout> | undefined;
}

export const PluginDetails: React.VFC<Props> = props => {
    const {
        appID,
        owner,
        plugin,
        pluginConfig,
        allPlugins,
        appDesc,
        schema,
        updatePluginConfig,
        updatePluginConfigParameters,
        validatePluginConfig,
        completePluginConfig,
        triggerRemovePlugin,
        requiredPlan,
        canAdd,
        useAPIKeyForPlugin,
    } = props;

    const { name, icon, parameters = {}, auth, description = "No description", disclosure } = plugin;

    const configuredIds = useAuthConfiguredPlugins(appID);
    const history = useHistory();
    const clientDetails: ClientDetails = {
        clients: plugin?.clientGlideAPIs ?? [],
        appTier: requiredPlan,
    };

    const pluginConfigs = appDesc.pluginConfigs ?? [];
    const configID = pluginConfig?.configID;
    // Does this plugin have a config?  Might be the one we're looking at.
    const isConfigured = pluginConfigs.some(x => x.pluginID === plugin.id);
    // Are we showing the UI for adding?
    const forAdding = pluginConfig === undefined;
    // If we do show the adding UI, should adding be enabled?  We can only add
    // if the app is on an allowed plan, our caller tells us we can add, and
    // the plugin is either not already configured or it allows multiple
    // instances.
    const isAddingEnabled =
        requiredPlan === undefined && canAdd && (!isConfigured || canAddMultiplePluginInstances(plugin));

    const connect = async (configIDInner: string) => {
        if (auth === undefined) return;
        trackEvent("plugin connect started", { plugin_id: plugin.id, app_id: appID, auth_provider_id: auth.provider });
        await performOAuthStageOne({
            authProvider: auth.provider,
            ownerID: owner.id,
            appID,
            pluginID: plugin.id,
            configID: configIDInner,
            explicitScopes: plugin.auth?.scopes,
        });
    };

    const triggerRemove = async (): Promise<boolean> => {
        if (configID === undefined || pluginConfig === undefined) return true;

        const result = await triggerRemovePlugin(appID, plugin.id, configID, pluginConfig.parameters);
        if (!result.ok) {
            logError("Failed to remove plugin", plugin.id, appID, result.errors);
            trackEvent("plugin removal failed", { plugin_id: plugin.id, app_id: appID });
        } else {
            trackEvent("plugin removed", { plugin_id: plugin.id, app_id: appID });
        }
        return result.ok;
    };

    const toggle = async () => {
        if (forAdding) {
            if (!isAddingEnabled) return;

            const newConfig = makeNewPluginConfig(plugin.id, pluginConfigs);
            if (auth !== undefined) {
                await connect(newConfig.configID);
            }
            updatePluginConfig(appID, [...pluginConfigs, newConfig]);
            history.push(routes.appIntegrationWithConfigID(appID, newConfig.configID));
            trackEvent("plugin turned on", { plugin_id: plugin.id, app_id: appID });
        } else {
            const removed = await triggerRemove();
            if (!removed) return;

            updatePluginConfig(
                appID,
                pluginConfigs.filter(x => {
                    if (configID !== undefined) {
                        return x.configID !== configID;
                    } else {
                        // If we don't have a config ID then we have to match
                        // by plugin ID.
                        return x.pluginID !== pluginConfig.pluginID;
                    }
                })
            );
            history.push(routes.appIntegrationWithPluginID(appID, pluginConfig.pluginID));
            trackEvent("plugin turned off", { plugin_id: plugin.id, app_id: appID });
        }
    };

    const [revalidate, setRevalidate] = React.useState({});
    const onRevalidate = React.useCallback(() => setRevalidate({}), []);

    const [validateState, setValidateState] = React.useState<ValidateState | undefined>(undefined);

    // This is a "recursive" effect.
    // The setTimeout down there is changing some state that re-runs this effect.
    // This is probably unnecessarily convoluted.
    React.useEffect(() => {
        // This needs to be a dependency
        void revalidate;

        if (configID === undefined || pluginConfig === undefined) return;
        let canceled = false;

        const fn = async () => {
            const validateResult = await validatePluginConfig(appID, plugin.id, configID, pluginConfig.parameters);
            if (canceled) return;

            // FIXME: This should be a pure function:
            // https://react.dev/reference/react/useState#setstate
            // "It must be pure, should take the pending state as its only argument, and should return the next state."
            setValidateState(old => {
                if (old?.timeout !== undefined) {
                    clearTimeout(old.timeout);
                }

                let timeout: ReturnType<typeof setTimeout> | undefined;
                const expiresAt = validateResult.externalConfigurationStep?.expiresAt;
                if (expiresAt !== undefined) {
                    try {
                        const timeLeft = expiresAt.getTime() - Date.now();
                        // If the URL expires in less than 60 seconds we won't
                        // bother to refresh it.
                        if (timeLeft >= 60 * 1000) {
                            // Refresh 10 seconds before it expires
                            timeout = setTimeout(() => onRevalidate(), timeLeft - 10 * 1000);
                        }
                    } catch (e: unknown) {
                        logError("Failed to parse expiresAt", e);
                    }
                } else if (!validateResult.ok) {
                    // Used for things like waiting for OAuth completion
                    timeout = setTimeout(() => onRevalidate(), 2 * 1000);
                }

                return {
                    ...validateResult,
                    timeout,
                };
            });
        };
        void fn();
        return () => {
            canceled = true;
        };
    }, [appID, configID, onRevalidate, plugin.id, pluginConfig, revalidate, validatePluginConfig]);

    // We don't want to complete a config more than once, so we keep track of
    // which ones we've been completed.  It's safe but wasteful to complete
    // more than once, so it's ok to keep this in component state.
    const [completedConfigIDs, setCompletedConfigIDs] = React.useState<string[]>([]);
    // We also want to know whether we're currently completing so we can show
    // a spinner instead of the "incomplete" config.
    const [isCompleting, setIsCompleting] = React.useState(false);
    React.useEffect(() => {
        if (configID === undefined || pluginConfig === undefined) return;

        if (completedConfigIDs.includes(configID)) return;
        setCompletedConfigIDs([...completedConfigIDs, configID]);
        setIsCompleting(true);

        const fn = async () => {
            try {
                const completeResult = await completePluginConfig(appID, plugin.id, configID, pluginConfig.parameters);
                updatePluginConfigParameters(appID, configID, completeResult.updates);
            } finally {
                setIsCompleting(false);
            }
        };

        void fn();
    }, [
        appID,
        completePluginConfig,
        completedConfigIDs,
        configID,
        plugin.id,
        pluginConfig,
        updatePluginConfigParameters,
    ]);

    const errors = validateState?.errors.filter(x => x.parameter === undefined) ?? [];
    const warnings = validateState?.warnings.filter(x => x.parameter === undefined) ?? [];

    const isSSOComplete = validateState?.externalConfigurationStep?.complete === true;
    const isSSOEnabled = isSSOSignInEnabled(appDesc, allPlugins).isEnabled;
    const removalPrevention = getPluginRemovalPreventionReasons(plugin, configID, appDesc, schema, allPlugins);
    const ssoConfiguredNotEnabled =
        pluginConfig !== undefined && plugin.signOns.length > 0 && isSSOComplete && !isSSOEnabled;
    const clientGlideAPIs = plugin.clientGlideAPIs;

    const userProfileTable = getUserProfileTableForAppAndSchema(appDesc, schema);

    const actions = plugin.actions.filter(a => a.deprecated !== true);
    const computations = plugin.computations.filter(a => a.deprecated !== true);
    const signOns = plugin.signOns;

    return (
        <>
            <div>
                <IntegrationDetailsHeader
                    icon={icon}
                    name={name}
                    description={description}
                    requiredPlan={requiredPlan}
                />
                {!canAdd && (
                    <div tw="flex p-2 bg-aqua200 mb-4 rounded-lg">
                        <GlideIcon
                            iconSize={16}
                            tw="text-aqua400 mt-0.5 flex-shrink-0"
                            icon="st-star-4"
                            kind="stroke"
                        />
                        <div tw="ml-2">This integration is no available for Classic Apps.</div>
                    </div>
                )}
                <div tw="flex space-x-2">
                    {forAdding ? (
                        <Button
                            key="add"
                            buttonType="primary"
                            variant="accent"
                            size="md"
                            label="Add to app"
                            onClick={toggle}
                            disabled={!isAddingEnabled}
                        />
                    ) : (
                        <>
                            <Button
                                key="remove"
                                buttonType="secondary"
                                variant="danger"
                                size="md"
                                label="Remove from app"
                                onClick={toggle}
                                disabled={removalPrevention !== undefined}
                            />
                            {auth !== undefined &&
                                configID !== undefined &&
                                (!configuredIds.includes(configID) || errors.length > 0) && (
                                    <Button
                                        key="connect"
                                        buttonType="secondary"
                                        variant="default"
                                        size="md"
                                        label="Reconnect"
                                        onClick={() => connect(configID)}
                                    />
                                )}
                        </>
                    )}
                    {plugin.documentationUrl !== undefined && (
                        <Button
                            key="guide"
                            buttonType="secondary"
                            variant="default"
                            size="md"
                            label="Docs"
                            iconType="iconLeading"
                            icon="mt-info-circle"
                            href={plugin.documentationUrl}
                        />
                    )}
                </div>
                {disclosure && (
                    <div
                        tw="text-builder-sm text-text-pale mt-4"
                        css={`
                            a {
                                text-decoration-line: underline !important;
                            }
                        `}
                        dangerouslySetInnerHTML={{ __html: disclosure }}
                    />
                )}
            </div>
            {removalPrevention?.sso === true && (
                <PluginConfigCTA
                    isAccent={false}
                    label="Go to Users & Authentication"
                    CTAHref={routes.appSettings(appID, "users")}>
                    Enable another authentication method and disable Single sign-on to remove from app.
                </PluginConfigCTA>
            )}
            {ssoConfiguredNotEnabled && (
                <PluginConfigCTA
                    isAccent={true}
                    label="Go to Users & Authentication"
                    CTAHref={routes.appSettings(appID, "users")}>
                    Enable as an authentication method to display the Single sign-on button on sign-in screen.
                </PluginConfigCTA>
            )}
            {removalPrevention?.sql === true && (
                <PluginConfigCTA isAccent={false} label="Go to the Data Editor" CTAHref={routes.appData(appID)}>
                    To remove this integration, you must first unlink tables from your SQL database.
                </PluginConfigCTA>
            )}
            {isCompleting && (
                <span>
                    <GlideIcon kind="stroke" icon="st-half-spinner" iconSize={24} spin={true} />
                    <span tw="ml-2">Finishing setup...</span>
                </span>
            )}
            {!isCompleting && pluginConfig !== undefined && Object.entries(parameters).length > 0 && (
                <>
                    <SettingsSectionDivider />
                    <SettingsSectionHeader>Settings</SettingsSectionHeader>
                    <PluginParametersConfig
                        appID={appID}
                        pluginConfigs={pluginConfigs}
                        updatePluginConfig={updatePluginConfig}
                        config={pluginConfig}
                        parameters={parameters}
                        userProfileTable={userProfileTable}
                        errorState={validateState}
                    />
                </>
            )}
            {pluginConfig !== undefined && (errors.length > 0 || warnings.length > 0) && (
                <>
                    <div tw="bg-bg-front rounded-lg mr-3.5 my-2.5">
                        <div tw="py-2.5 px-3 bg-r100 flex text-builder-base rounded-lg text-text-base">
                            <div tw="text-text-danger p-2 rounded-full mr-3">
                                <GlideIcon kind="monotone" icon="mt-warning" iconSize={20} />
                            </div>
                            <div tw="flex items-center">
                                {errors.map((error, i) => (
                                    <div tw="text-text-danger" key={`e${i}`}>
                                        {error.message}
                                    </div>
                                ))}
                                {warnings.map((warn, i) => (
                                    <div key={`w${i}`} tw="text-text-pale">
                                        {warn.message}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {pluginConfig !== undefined &&
                !isCompleting &&
                clientGlideAPIs !== undefined &&
                clientGlideAPIs.length > 0 && (
                    <>
                        <SettingsSectionDivider />
                        <ApiKeysList
                            ownerID={owner.id}
                            clientDetails={clientDetails}
                            useAPIKeyForPlugin={useAPIKeyForPlugin}
                        />
                    </>
                )}
            {!isCompleting && validateState?.externalConfigurationStep !== undefined && (
                <ExternalConfigurationStepDisplay
                    step={validateState.externalConfigurationStep}
                    onRevalidate={onRevalidate}
                />
            )}
            {(actions.length > 0 || computations.length > 0 || signOns.length > 0) && (
                <>
                    <SettingsSectionDivider />
                    <SettingsSectionHeader>Features</SettingsSectionHeader>
                    <div tw="flex flex-col gap-2">
                        {actions.map(a => (
                            <FeatureRow key={a.id} type="action" title={a.name} description={a.description} />
                        ))}
                        {computations.map(a => (
                            <FeatureRow key={a.id} type="column" title={a.name} description={a.description} />
                        ))}
                        {signOns.map(a => (
                            <FeatureRow key={a.id} type="sign-on" title={a.name} description={a.description} />
                        ))}
                        {plugin.notificationSenders?.map(s => (
                            <FeatureRow
                                key={s.id}
                                type="notification-sender"
                                title={s.name}
                                description={s.description}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

interface PluginConfigCTAProps {
    readonly CTAHref: string;
    readonly isAccent: boolean;
    readonly label: string;
    readonly children: React.ReactNode;
}

const PluginConfigCTA: React.VFC<PluginConfigCTAProps> = p => {
    const { CTAHref, isAccent, label, children } = p;
    return (
        <div tw="flex flex-col w-full items-start mt-4">
            <div
                className={isAccent ? "accent" : "regular"}
                tw="p-4 [&.accent]:bg-b100 [&.regular]:bg-n200A rounded-2xl">
                <p tw="text-text-base text-builder-base">{children}</p>
                <Button
                    tw="mt-3"
                    buttonType={isAccent ? "primary" : "tertiary"}
                    variant="default"
                    size="md"
                    isFullWidth={true}
                    localHref={CTAHref}
                    label={label}
                />
            </div>
        </div>
    );
};
