import { Link } from "react-router-dom";

import type { ButtonStyleProps } from "./button-style";
import { ButtonLegacyStyle } from "./button-style";
import type { LinkProps } from "react-router-dom";

// TODO: Fix types
interface AnchorProps {
    href: string;
}

interface LegacyButtonProps {
    type?: string;
    onClick?: () => void;
    inline?: boolean;
}

type Props = ButtonStyleProps & (AnchorProps | LinkProps | LegacyButtonProps);

/**
 * @deprecated Use Button instead
 */
export const ButtonLegacy = (props: Props) => {
    if ((props as AnchorProps).href !== undefined) {
        return <ButtonLegacyStyle as="a" {...(props as any)} target="_blank" rel="noopener noreferrer" />;
    }

    if ((props as LinkProps).to !== undefined) {
        return <ButtonLegacyStyle as={Link} {...(props as any)} />;
    }

    return <ButtonLegacyStyle {...(props as any)} />;
};

ButtonLegacy.defaultProps = { variant: "primary" };
