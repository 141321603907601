import type { ActionNode, ActionOutputDescriptor } from "@glide/app-description";
import { ActionNodeKind } from "@glide/app-description";
import type { ActionNodeInScope } from "@glide/function-utils";
import type { GlideIconProps } from "@glide/plugins-codecs";
import { glideIconPropsCodec } from "@glide/plugins-codecs";
import type { Formula, TableGlideType } from "@glide/type-schema";
import { assertNever } from "@glideapps/ts-necessities";
import { handlerForActionKind } from "./actions";

export interface ExtendedActionOutputDescriptor extends ActionOutputDescriptor {
    /**
     * If the action output is a row (from a loop, for example), then we also
     * let the user pick the columns from that row, in which case we make one
     * of these objects for each column, which `columnName` being the name of
     * the column.
     */
    readonly columnName: string | undefined;
    /**
     * This is handy for when we are seeing a prior step and we want to know
     * information about the table, so we can do functionality like
     * navigate to table or showing proper column icons
     */
    readonly table: TableGlideType | undefined;
    /**
     * Is the column writeable?
     */
    readonly writeable: boolean;
    /**
     * When the output source is a computed column, we need to know about the formula
     */
    readonly formula: Formula | undefined;
    readonly icon: string | GlideIconProps | undefined;
}

export interface PriorStep {
    readonly node: ActionNodeInScope;
    readonly outputs: ExtendedActionOutputDescriptor[];
    readonly displayName: string;
    readonly icon: string | GlideIconProps;
}

export function getIconForActionNode(
    node: ActionNode,
    triggerKind: "scheduled" | "app" = "scheduled"
): string | GlideIconProps {
    const kind = node.kind;
    switch (kind) {
        case ActionNodeKind.Primitive: {
            const handler = handlerForActionKind(node.actionDescription.kind);
            return typeof handler?.iconName === "string" || glideIconPropsCodec.is(handler.iconName)
                ? handler.iconName
                : "infoCircle";
        }
        case ActionNodeKind.Flow:
            return {
                kind: "stroke",
                icon: "st-else-flow",
            };
        case ActionNodeKind.Conditional:
            return "conditional";
        case ActionNodeKind.ConditionalFlow:
            return {
                kind: "stroke",
                icon: "st-condition",
                strokeColor: "var(--gv-icon-base)",
                strokeFgColor: "var(--gv-icon-pale)",
            };
        case ActionNodeKind.Loop:
            return {
                icon: "st-arrows-loop",
                kind: "stroke",
                strokeColor: "var(--gv-icon-base)",
                strokeFgColor: "var(--gv-icon-pale)",
            };
        case ActionNodeKind.AutomationRoot:
            return { kind: "stroke", icon: triggerKind === "app" ? "st-navigation" : "st-connect" };
        default:
            assertNever(kind);
    }
}
