import "twin.macro";

import { getLocalizedString } from "@glide/localization";
import { isUploadFileResponseError } from "@glide/common-core/dist/js/components/types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import type { WirePageSignaturePadComponent } from "@glide/fluent-components/dist/js/base-components";
import type { AppKind } from "@glide/location-common";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { type ConditionVariable, isDefined } from "@glide/support";
import { UIButtonAppearance, ValueChangeSource } from "@glide/wire";
import * as React from "react";

import { useFileUploader } from "../../utils/use-file-uploader";
import { runActionAndHandleURL } from "../../wire-lib";
import { WireButton } from "../wire-button/wire-button";
import type { WireRenderer } from "../wire-renderer";
import { SignatureAutoSizer } from "./private/signature-auto-sizer";
import type { Path } from "./private/signature-types";
import { SignatureView } from "./private/signature-view";

function dataURIToFile(dataURI: string): File | undefined {
    const parts = dataURI.split(/[:;,]/);

    if (parts.length !== 4 || parts[0] !== "data" || parts[1] !== "image/png" || parts[2] !== "base64") return;

    const mimeString = parts[1];
    const byteString = atob(parts[3]);

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([intArray], { type: mimeString });

    // NOTE: The "signature" filename will be overwritten with a generated id when uploaded via `uploadAppFile`
    return new File([blob], "signature", { type: blob.type });
}

export const WireSignaturePad: WireRenderer<WirePageSignaturePadComponent> = React.memo(p => {
    const { backend, image, onWriteComplete, hasPaths, isWriting } = p;

    const sizeClass = useRootResponsiveSizeClass();
    const smallScreen = isSmallScreen(sizeClass);

    const uploadAppFile = useFileUploader();

    const uploadImageCB = React.useCallback(
        async (file: File) => {
            if (image?.onChangeToken === undefined || onWriteComplete === undefined) return;

            // FIXME: Support progress reporting
            // FIXME: Handle errors
            // FIXME: Suppor cancellation
            const session = uploadAppFile(
                backend.appID,
                backend.appKind,
                getAppFacilities(),
                file,
                "signature",
                undefined,
                () => undefined,
                false
            );
            if (session === undefined) return;
            const response = await session.attempt();

            if (!isUploadFileResponseError(response)) {
                backend.valueChanged(image.onChangeToken, response.path, ValueChangeSource.User);
                runActionAndHandleURL(onWriteComplete, backend);
            }
        },
        [backend, image.onChangeToken, onWriteComplete, uploadAppFile]
    );

    const shouldWrite = isDefined(onWriteComplete?.token);
    const uploadImage = shouldWrite ? uploadImageCB : undefined;

    return (
        <Signature
            appKind={backend.appKind}
            shouldWriteFlag={shouldWrite}
            shouldWriteCV={undefined}
            onSetFile={uploadImage}
            message={"Sign"}
            smoothing={8}
            onHasPathsChanged={f => backend.valueChanged(hasPaths.onChangeToken, f, ValueChangeSource.User)}
            isWriting={isWriting}
            smallScreen={smallScreen}
        />
    );
});

interface SignatureProps {
    readonly appKind: AppKind;
    readonly message: string;
    readonly shouldWriteFlag: boolean | undefined;
    readonly shouldWriteCV: ConditionVariable | undefined;
    readonly onSetFile?: (file: File) => void;
    readonly onHasPathsChanged?: (hasPaths: boolean) => void;
    readonly smoothing: number;
    readonly isWriting: boolean;
    readonly smallScreen: boolean;
}

interface SignatureState {
    paths: Path[];
    lastWidth?: number;
    lastHeight?: number;
    shouldDraw: boolean;
    shouldWrite: boolean;
    cvWaitingOn: ConditionVariable | undefined;
}

class Signature extends React.PureComponent<SignatureProps, SignatureState> {
    public state: SignatureState = {
        paths: [],
        shouldDraw: false,
        shouldWrite: false,
        cvWaitingOn: undefined,
    };

    private cleanPath = (path: Path): Path => {
        if (path.length === 0) return path;
        if (path.length === 1) return [path[0], path[0]];

        const result = [path[0]];

        path.slice(1).forEach((point, index, arr) => {
            const cur = result[result.length - 1];
            const distance = Math.sqrt(Math.pow(cur.x - point.x, 2) + Math.pow(cur.y - point.y, 2));

            if (distance > this.props.smoothing || index === arr.length - 1) {
                result.push(point);
            }
        });

        return result;
    };

    private onPadDown = () => {
        const paths: Path[] = [...this.state.paths, []];
        this.setState({ paths, shouldDraw: true });
        if (paths.length === 1) {
            this.props.onHasPathsChanged?.call(undefined, true);
        }
    };

    private onPadUp = () => {
        const paths = this.state.paths;

        if (paths.length === 0 || this.state.shouldDraw === false) return;

        const newPath = this.cleanPath(paths[paths.length - 1]);
        const newPaths = [...paths];
        newPaths[paths.length - 1] = newPath;
        this.setState({ shouldDraw: false, paths: newPaths });
    };

    private onPadMove = (x: number, y: number) => {
        const { shouldDraw, paths } = this.state;
        if (shouldDraw) {
            const currentPath = paths[paths.length - 1];
            const updatedPath = [...currentPath, { x, y }];
            const newPaths = [...paths];
            newPaths[paths.length - 1] = updatedPath;
            this.setState({ paths: newPaths });
        }
    };

    private onImageData = (dataURI: string) => {
        const { onSetFile } = this.props;
        const file = dataURIToFile(dataURI);
        if (onSetFile === undefined || file === undefined) return;
        onSetFile(file);
    };

    private onClear = () => {
        this.setState({ paths: [] });
        this.props.onHasPathsChanged?.call(undefined, false);
    };

    public componentDidUpdate(): void {
        if (this.state.shouldWrite) return;

        if (this.props.shouldWriteFlag === true) {
            this.setState({ shouldWrite: true });
        }

        const { shouldWriteCV } = this.props;
        if (shouldWriteCV !== undefined && shouldWriteCV !== this.state.cvWaitingOn) {
            void shouldWriteCV.wait().then(() => this.setState({ shouldWrite: true }));
            this.setState({ cvWaitingOn: shouldWriteCV });
        }
    }

    render() {
        const { appKind, message, isWriting, smallScreen } = this.props;
        const { paths, lastWidth, lastHeight, shouldWrite } = this.state;

        return (
            <div tw="flex-grow flex flex-col page-md:height[480px]" data-test="app-signature-view">
                <SignatureAutoSizer>
                    {p => {
                        if (p.width !== lastWidth || p.height !== lastHeight) {
                            setTimeout(() => {
                                this.setState({
                                    paths: [],
                                    lastWidth: p.width,
                                    lastHeight: p.height,
                                });
                            }, 0);
                        }
                        return (
                            <SignatureView
                                shouldWrite={shouldWrite}
                                message={message}
                                width={p.width ?? 100}
                                height={p.height ?? 100}
                                paths={paths}
                                onPadDown={this.onPadDown}
                                onPadUp={this.onPadUp}
                                onPadMove={this.onPadMove}
                                onImageData={this.onImageData}
                                smallScreen={smallScreen}
                            />
                        );
                    }}
                </SignatureAutoSizer>
                <div tw="absolute h-11 bottom-4 page-md:bottom-8 w-full flex justify-center text-text-accent">
                    {paths.length > 0 && !isWriting && (
                        <WireButton
                            onClick={paths.length > 0 ? this.onClear : undefined}
                            appearance={UIButtonAppearance.Bordered}
                        >
                            {getLocalizedString("clear", appKind)}
                        </WireButton>
                    )}
                </div>
            </div>
        );
    }
}
