/*!
 * Modified from long-press-event.js
 * Original can be found at
 * https://github.com/john-doherty/long-press-event
 * @author John Doherty <www.johndoherty.info>
 * @license MIT
 */

import MobileDetect from "mobile-detect";

const defaultDelay = 500;

/**
 * Fires the 'long-press' event on element
 * @returns {void}
 */
export function fireLongPressEvent(e: Element, isRightClick: boolean): void {
    e.dispatchEvent(new CustomEvent("long-press", { bubbles: true, cancelable: true, detail: { isRightClick } }));
}

export const longPressTargetClass = "takes-long-press";

// check if we're using a touch screen
const isMobile = new MobileDetect(window.navigator.userAgent).mobile() !== null;
const useTouchForLongPress =
    isMobile && ("ontouchstart" in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0);

export function registerLongPress(window: any, document: Document) {
    let timer: number | undefined;

    // switch to touch events if using a touch screen
    const mouseDown = useTouchForLongPress ? "touchstart" : "mousedown";
    const mouseOut = useTouchForLongPress ? "touchcancel" : "mouseout";
    const mouseUp = useTouchForLongPress ? "touchend" : "mouseup";
    const mouseMove = useTouchForLongPress ? "touchmove" : "mousemove";

    // wheel/scroll events
    const mouseWheel = "mousewheel";
    const wheel = "wheel";
    const scrollEvent = "scroll";

    // patch CustomEvent to allow constructor creation (IE/Chrome)
    if (typeof window.CustomEvent !== "function") {
        window.CustomEvent = (event: string, params: any) => {
            params = params ?? { bubbles: false, cancelable: false, detail: undefined };

            const evt = document.createEvent("CustomEvent");
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
            return evt;
        };

        window.CustomEvent.prototype = window.Event.prototype;
    }

    function clear() {
        clearTimeout(timer);
        timer = undefined;
    }

    // listen to mousedown event on any child element of the body
    document.addEventListener(mouseDown, e => {
        const el = e.target as Element;

        if (el !== null) {
            // get delay from html attribute if it exists, otherwise default to 500
            const delayAttribute = el.getAttribute("data-long-press-delay");
            const specifiedDelay = delayAttribute === null ? null : parseInt(delayAttribute, 10);
            const longPressDelayInMs = specifiedDelay === null || isNaN(specifiedDelay) ? defaultDelay : specifiedDelay;
            // start the timer
            timer = setTimeout(
                (() => {
                    fireLongPressEvent(el, false);
                    clear();
                }) as TimerHandler,
                longPressDelayInMs
            );
        }
    });

    // clear the timeout if the user releases the mouse/touch
    document.addEventListener(mouseUp, clear);

    // clear the timeout if the user leaves the element
    document.addEventListener(mouseOut, clear);

    // clear if the mouse moves
    document.addEventListener(mouseMove, clear);

    // clear if the Wheel event is fired in the element
    document.addEventListener(mouseWheel, clear);

    // clear if the Scroll event is fired in the element
    document.addEventListener(wheel, clear);

    // clear if the Scroll event is fired in the element
    document.addEventListener(scrollEvent, clear);
}
