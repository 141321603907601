import type { TemporaryComputedColumn, ColumnBuildMessage } from "@glide/computation-model-types";
import {
    type SyntheticColumnKind,
    DataColumnKind,
    type ConstructURLSpecification,
    type FilterReferenceSpecification,
    type FilterSortLimitSpecification,
    type GenerateImageSpecification,
    type GeoDistanceSpecification,
    type IfThenElseSpecification,
    type JoinStringsSpecification,
    type LookupSpecification,
    type MakeArraySpecification,
    type MathSpecification,
    type PluginComputationSpecification,
    type RollupSpecification,
    type SingleValueSpecification,
    type SplitStringSpecification,
    type TextTemplateSpecification,
    type UserAPIFetchSpecification,
    type ValueFormatSpecification,
    type YesCodeSpecification,
} from "@glide/formula-specifications";
import type { PrimitiveArrayColumnType, PrimitiveGlideType, TableColumn, TypeSchema } from "@glide/type-schema";
import type { SchemaCycles } from "@glide/generator/dist/js/computed-columns";

export function isBasicDataColumnKind<T>(
    k: DataColumnKind | T
): k is DataColumnKind.Regular | DataColumnKind.UserSpecific {
    return k === DataColumnKind.Regular || k === DataColumnKind.UserSpecific;
}

export type TemporaryColumnData =
    | ReferenceColumnData
    | TextTemplateColumnData
    | IfThenElseColumnData
    | LookupColumnData
    | SingleValueColumnData
    | MathColumnData
    | RollupColumnData
    | JoinStringsColumnData
    | SplitStringColumnData
    | MakeArrayColumnData
    | FilterSortLimitData
    | GeoDistanceColumnData
    | GenerateImageColumnData
    | UserAPIFetchColumnData
    | ConstructURLColumnData
    | YesCodeColumnData
    | PluginComputationColumnData
    | RegularColumnData
    | UserSpecificColumnData
    | RowIDColumnData;

export function isTemporaryDataColumnData(t: TemporaryColumnData): t is RegularColumnData | UserSpecificColumnData {
    return isBasicDataColumnKind(t.kind);
}

interface SyntheticColumnData<TKind, TSpec> {
    readonly kind: TKind;
    readonly spec: Partial<TSpec>;
}

export type ReferenceColumnData = SyntheticColumnData<
    SyntheticColumnKind.FilterReference,
    FilterReferenceSpecification
>;
export type TextTemplateColumnData = SyntheticColumnData<SyntheticColumnKind.TextTemplate, TextTemplateSpecification>;
export type IfThenElseColumnData = SyntheticColumnData<SyntheticColumnKind.IfThenElse, IfThenElseSpecification>;
export type LookupColumnData = SyntheticColumnData<SyntheticColumnKind.Lookup, LookupSpecification>;
export type SingleValueColumnData = SyntheticColumnData<SyntheticColumnKind.SingleValue, SingleValueSpecification>;
export type MathColumnData = SyntheticColumnData<SyntheticColumnKind.Math, MathSpecification>;
export type RollupColumnData = SyntheticColumnData<SyntheticColumnKind.Rollup, RollupSpecification>;
export type JoinStringsColumnData = SyntheticColumnData<SyntheticColumnKind.JoinStrings, JoinStringsSpecification>;
export type SplitStringColumnData = SyntheticColumnData<SyntheticColumnKind.SplitString, SplitStringSpecification>;
export type MakeArrayColumnData = SyntheticColumnData<SyntheticColumnKind.MakeArray, MakeArraySpecification>;
export type FilterSortLimitData = SyntheticColumnData<
    SyntheticColumnKind.FilterSortLimit,
    FilterSortLimitSpecification
>;
export type GeoDistanceColumnData = SyntheticColumnData<SyntheticColumnKind.GeoDistance, GeoDistanceSpecification>;
export type GenerateImageColumnData = SyntheticColumnData<
    SyntheticColumnKind.GenerateImage,
    GenerateImageSpecification
>;
export type UserAPIFetchColumnData = SyntheticColumnData<SyntheticColumnKind.UserAPIFetch, UserAPIFetchSpecification>;
export type ConstructURLColumnData = SyntheticColumnData<SyntheticColumnKind.ConstructURL, ConstructURLSpecification>;
export type YesCodeColumnData = SyntheticColumnData<SyntheticColumnKind.YesCode, YesCodeSpecification>;
export type PluginComputationColumnData = SyntheticColumnData<
    SyntheticColumnKind.PluginComputation,
    PluginComputationSpecification
>;

type RegularColumnType = PrimitiveGlideType | PrimitiveArrayColumnType;

export interface DataColumnSpec {
    readonly type: RegularColumnType;
}

export interface RegularColumnData {
    readonly kind: DataColumnKind.Regular;
    readonly spec: DataColumnSpec;
}

export interface UserSpecificColumnData {
    readonly kind: DataColumnKind.UserSpecific;
    readonly spec: DataColumnSpec;
}

interface RowIDColumnData {
    readonly kind: DataColumnKind.RowID;
    readonly spec: {};
}

export interface EditedColumn {
    readonly name: string;
    readonly displayName: string;
    readonly temporaryColumn: TemporaryColumnData;
    // This is `undefined` iff we're configuring a new column.
    readonly originalColumn: TableColumn | undefined;
    readonly originalSchema: TypeSchema | undefined;
    readonly temporaryColumnFormat: ValueFormatSpecification | undefined;
    readonly computationModelInfo: TemporaryComputedColumn | undefined;
    readonly errorMessage: ColumnBuildMessage | undefined;
    readonly warningMessage: ColumnBuildMessage | undefined;
    readonly newColumn: TableColumn | undefined;
    // The cycles in the schema if the new column was committed.
    readonly cycles: SchemaCycles | undefined;
    // Does the existing/old schema have cycles?  This is only valid
    // if `cycles` is defined.  If it's not, this will be `false`.  We
    // have to keep track of this because of ##schemasWithCycles.
    readonly haveExistingCycles: boolean;
    readonly after?: string;
}

function updateTemporaryColumnData(
    state: TemporaryColumnData,
    update: Partial<TemporaryColumnData>
): TemporaryColumnData {
    return {
        ...state,
        ...update,
        spec: {
            ...state.spec,
            ...update.spec,
        },
    } as TemporaryColumnData;
}

// NOTE: This function doesn't update the column format in `newColumn`.
// That's the caller's responsibility.
export function updateEditedColumn(
    state: EditedColumn,
    name: string | undefined,
    displayName: string | undefined,
    columnUpdate: Partial<TemporaryColumnData>,
    formatUpdate: ValueFormatSpecification | undefined,
    newColumn: TableColumn | undefined
): EditedColumn {
    return {
        ...state,
        name: name ?? state.name,
        displayName: displayName ?? state.displayName,
        temporaryColumn: updateTemporaryColumnData(state.temporaryColumn, columnUpdate),
        temporaryColumnFormat: formatUpdate,
        newColumn: newColumn ?? state.newColumn,
    };
}
