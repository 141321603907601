import { GlideDateTime, GlideJSON } from "@glide/data-types";
import { compareStrings } from "@glide/support";
import { type LoadedGroundValue, isPrimitiveValue, asString } from "@glide/computation-model-types";

function compareValuesPrimitive(one: any, two: any): number {
    if (one === two) return 0;
    if (one < two) return -1;
    return 1;
}

type ComparisonOptions = {
    compareTimesOnly?: boolean;
};

export function compareValues(
    one: LoadedGroundValue,
    two: LoadedGroundValue,
    opts: ComparisonOptions = {}
): number | undefined {
    if (one === two) return 0;

    // `undefined` is only equal to `undefined` and the empty string
    if (one === undefined) {
        if (two === "") return 0;
        return undefined;
    }
    if (two === undefined) {
        if (one === "") return 0;
        return undefined;
    }

    // GlideDateTime internally implements its own compareTo.
    // Unfortunately nothing else interacts properly with GlideDateTime.
    //
    // If the right hand side is a GlideDateTime then we preserve expected
    // behavior by simply negating the resulting comparison.
    if (one instanceof GlideDateTime) {
        return one.compareTo(two, opts);
    } else if (two instanceof GlideDateTime) {
        const reversed = two.compareTo(one, opts);
        return reversed === 0 ? 0 : -reversed;
    }

    if (one instanceof GlideJSON) {
        if (two instanceof GlideJSON) {
            // ##comparingJSON:
            // FIXME: This isn't technically correct.  Two JSON values could
            // be equal but still have different string representations.  It
            // would be easy to fix this if we just checked for equality here,
            // but we need ordering.  We probably need some canonical string
            // representation.
            return compareStrings(one.jsonString, two.jsonString);
        }
        return undefined;
    }

    if (!isPrimitiveValue(one) || !isPrimitiveValue(two)) return undefined;

    const oneType = typeof one;
    const twoType = typeof two;

    // If the values are of the same type, just compare them directly below.
    // If they're not, this gets us to a common type.
    if (oneType !== twoType) {
        const newOne = asString(one);
        const newTwo = asString(two);
        return compareStrings(newOne, newTwo);
    } else if (oneType === "string") {
        return compareStrings(one as string, two as string);
    }

    return compareValuesPrimitive(one, two);
}

export function areValuesEqual(
    one: LoadedGroundValue,
    two: LoadedGroundValue,
    opts: ComparisonOptions = {}
): boolean | undefined {
    if (one === two) return true;

    // `undefined` is only equal to `undefined` and the empty string, but we will
    // treat the result of testing for equality as always defined in this case.
    if (one === undefined) {
        return two === "";
    }
    if (two === undefined) {
        return one === "";
    }

    const result = compareValues(one, two, opts);
    if (result === undefined) return undefined;
    return result === 0;
}
