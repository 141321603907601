import { GlideIcon } from "@glide/common";
import type { GlideFC } from "@glide/common";

interface Props extends React.PropsWithChildren {}

export const MyAppsDeprecationBanner: GlideFC<Props> = () => {
    return (
        <div tw="rounded-lg bg-r500 w-full shadow-sm">
            <div tw="flex text-text-base justify-evenly gap-4 w-full rounded-lg p-5 items-center">
                <GlideIcon kind="stroke" icon="st-alert-warning" iconSize={40} strokeWidth={1.25} strokeColor="white" />

                <div tw="flex flex-col w-full">
                    <div tw="mt-1 text-text-inverse text-builder-xl line-height[133%]">
                        My Apps are no longer supported. To create new apps, access paid features, or receive customer
                        support, you will need to transfer your apps to a team.
                    </div>
                </div>
            </div>
        </div>
    );
};
