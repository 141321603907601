import { AppKind } from "@glide/location-common";
import { isLoadingValue } from "@glide/computation-model-types";
import { isRow } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ActionDescription,
    type PropertyDescription,
    ActionKind,
    MutatingScreenKind,
} from "@glide/app-description";
import { getTableName, sheetNameForTable } from "@glide/type-schema";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    editClassScreenName,
    PropertySection,
    makeSingleRelationOrThisItemPropertyDescriptor,
    type ActionAvailability,
} from "@glide/function-utils";
import {
    type WireActionResult,
    type WireActionResultBuilder,
    PageScreenTarget,
    type WireActionHydrator,
    type WireActionInflationBackend,
} from "@glide/wire";
import { makeScreenTitlePropertyDescriptor } from "../components/descriptor-utils";
import type { StaticActionContext } from "../static-context";
import { getCanEditFromNetworkStatus, makeRowGetter } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";
import {
    type NavigationTargetDescription,
    type SingleRelationOrThisDescription,
    getTableForSourceColumnOrThis,
    makeNavigationPropertyDescriptors,
    navigationTargetPropertyHandlers,
} from "./link-to-screen";
import { getSupportsUserProfileRowAccess } from "./set-columns";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

interface PushEditScreenActionDescription
    extends ActionDescription,
        SingleRelationOrThisDescription,
        NavigationTargetDescription {
    readonly kind: ActionKind.PushEditScreen;

    readonly title: PropertyDescription | undefined;
}

export class PushEditScreenHandler extends BaseActionHandler<PushEditScreenActionDescription> {
    public readonly kind = ActionKind.PushEditScreen;

    public readonly iconName: GlideIconProps = {
        icon: "st-pencil",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Show edit screen";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(
        _desc: PushEditScreenActionDescription | undefined,
        { context: ccc, mutatingScreenKind }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const properties: PropertyDescriptor[] = [];
        if (ccc.appKind === AppKind.App) {
            properties.push(makeScreenTitlePropertyDescriptor(mutatingScreenKind));
        }
        properties.push(
            makeSingleRelationOrThisItemPropertyDescriptor(
                "sourceColumn",
                "Data",
                PropertySection.Source,
                true,
                getSupportsUserProfileRowAccess(ccc)
            ),
            ...makeNavigationPropertyDescriptors(ccc, PageScreenTarget.SmallModal)
        );

        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 2,
            needsScreenContext: true,
            properties,
        };
    }

    public getScreensUsed(
        desc: PushEditScreenActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly string[] {
        const table = getTableForSourceColumnOrThis(desc.sourceColumn, true, true, env);
        if (table === undefined) return [];

        return [editClassScreenName(getTableName(table))];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: PushEditScreenActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { eminenceFlags },
        } = ib;
        const maybeGetter = makeRowGetter(ib, desc.sourceColumn, { inOutputRow: false, defaultToThisRow: true });
        if (maybeGetter === undefined || maybeGetter === false) return arbBase.inflationError("Invalid data row");
        const { rowGetter, table } = maybeGetter;
        const tableName = getTableName(table);

        const target = navigationTargetPropertyHandlers.get(PageScreenTarget.SmallModal).getEnum(desc);

        return (vp, skipLoading) => {
            const arb = arbBase.addData({ tableName: sheetNameForTable(table), target });

            if (!getCanEditFromNetworkStatus(vp, eminenceFlags, MutatingScreenKind.EditScreen)) return arb.offline();

            const row = rowGetter(vp);
            if (isLoadingValue(row)) return arb.maybeSkipLoading(skipLoading, "Row to edit");
            if (row === null || !isRow(row)) return arb.error(true, "No row");

            return async ab => {
                ab.pushDefaultEditScreen(tableName, row, target);
                return arb.addData({ rowID: row.$rowID }).success();
            };
        };
    }
}
