export enum TextBoxStyle {
    Footnote = "Footnote",
    BodySmall = "Small",
    Body = "Regular",
    BodyLarge = "Large",
    H1 = "Headline 1",
    H2 = "Headline 2",
    H3 = "Headline 3",
    H4 = "Headline 4",
}

export enum TextBoxJustify {
    Center = "Center",
    Left = "Left",
    Right = "Right",
    Justify = "Justify",
}

export enum TextBoxLineLimit {
    None = "None",
    One = "One",
    Two = "Two",
    Three = "Three",
    Four = "Four",
    Five = "Five",
    Six = "Six",
}
