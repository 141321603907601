import "twin.macro";
import { Toast } from "./toast";

type Props = {
    onClose: (() => void) | undefined;
    onConfirm: () => void;
    className?: string;
    show: boolean;
};

export const VersionReloadPromptToast: React.FC<Props> = p => {
    const { onClose, onConfirm, className, show } = p;
    return (
        <Toast.Message size={"md"} className={className} show={show} position="bottom-left" onClose={onClose}>
            <Toast.Content tw="flex flex-col mr-2 text-builder-lg text-text-base">
                <strong>A new version of Glide is available</strong>
                <p>Reload to get the newest features.</p>
            </Toast.Content>
            <Toast.Button
                buttonType="primary"
                size="lg"
                label="Reload"
                variant="default"
                onClick={onConfirm}
                tw="bg-accent"
            />
            {onClose ? <Toast.Close onClose={onClose} /> : null}
        </Toast.Message>
    );
};
