import styled from "styled-components";

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${p => p.theme.fgColorDark};
    font-weight: 400;
    font-size: 14px;

    .description {
        display: inline;
    }

    .body-text {
        color: ${p => p.theme.textDark};
        font-size: 19px;
        line-height: 23px;
        font-weight: 800;
    }

    .quicklink,
    a {
        color: ${p => p.theme.acceptColor};
    }

    .applink {
        display: flex;
        align-items: center;
        border-radius: 12px;
        padding: 10px;
        color: ${p => p.theme.fgColorMedium};
        text-decoration: none;
        margin: 20px 0;
        cursor: pointer;
        box-shadow: ${p => p.theme.shadow0};
        transition: 150ms ease-in-out;
        transition-property: transform box-shadow;
        transform: scale(1);
        :hover {
            transform: scale(1.01);
            box-shadow: ${p => p.theme.shadow1};
        }
        :active {
            box-shadow: ${p => p.theme.shadow4};
            content: "Copied";
            transform: scale(0.99);
        }
    }
`;
