import type { WriteSourceType } from "@glide/common-core";
import type { WireBackendAppEnvironment } from "@glide/common-core/dist/js/components/types";
import type { NetworkStatus } from "@glide/common-core/dist/js/network-status";
import type { WireFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import type { ExistingAppDescriptionContext } from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import type { ChangeObservable } from "@glide/support";
import type { SearchableColumns, WireBackendCallbacks } from "@glide/wire";
import { assert, assertNever } from "@glideapps/ts-necessities";

import { WireBackendBase } from "./backend";
import { WireAppBackend } from "./backend-app";
import { WirePageBackend } from "./backend-page";
import { getAppNavigationPathTransformer, getPageNavigationPathTransformer } from "./navigation-path";
import type { WireBackend } from "./types";

export const makeWireBackend = (
    appEnvironment: WireBackendAppEnvironment,
    context: ExistingAppDescriptionContext,
    precomputedSearchableColumns: SearchableColumns | undefined,
    networkStatusWatchable: ChangeObservable<NetworkStatus>,
    callbacks: WireBackendCallbacks,
    isBuilder: boolean,
    appHost: string,
    fallbackAuthorName: string,
    formFactor: WireFormFactor,
    currentURL: string,
    urlPathOrPrevious: string | WireBackend | undefined,
    writeSource: WriteSourceType
): WireBackend => {
    const { authenticator } = appEnvironment;

    if (typeof urlPathOrPrevious !== "string" && urlPathOrPrevious !== undefined) {
        assert(urlPathOrPrevious instanceof WireBackendBase);
    }

    const userIsLoggedIn = authenticator?.realEmail !== undefined;
    if (context.appKind === AppKind.Page) {
        return new WirePageBackend(
            appEnvironment,
            context,
            precomputedSearchableColumns,
            networkStatusWatchable,
            callbacks,
            isBuilder,
            getPageNavigationPathTransformer(),
            userIsLoggedIn,
            appHost,
            fallbackAuthorName,
            formFactor,
            currentURL,
            urlPathOrPrevious,
            writeSource
        );
    } else if (context.appKind === AppKind.App) {
        return new WireAppBackend(
            appEnvironment,
            context,
            precomputedSearchableColumns,
            networkStatusWatchable,
            callbacks,
            isBuilder,
            getAppNavigationPathTransformer(),
            userIsLoggedIn,
            appHost,
            fallbackAuthorName,
            formFactor,
            currentURL,
            urlPathOrPrevious,
            writeSource
        );
    } else {
        return assertNever(context.appKind);
    }
};
