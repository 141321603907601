import { assert, definedMap, mapFilterUndefined, hasOwnProperty } from "@glideapps/ts-necessities";
import deepmerge from "deepmerge";

function getUnnormalized(theme: any) {
    const unnormalized = theme.unnormalized ?? theme;
    assert(unnormalized.unnormalized === undefined);
    return unnormalized;
}

interface MutationLookup {
    key: string;
    mutator: (val: string) => string;
}

function isMutationLookup(val: any): val is MutationLookup {
    return typeof val === "object" && hasOwnProperty(val, "key") && hasOwnProperty(val, "mutator");
}

export function lookupWithMutator(key: string, mutator: (val: string) => string) {
    // We infer the theme type from its value,
    // so when we lookup some other value, we must return a "string".
    // obviously this is not great, but the alternative is a larger refactor.
    return {
        key,
        mutator,
    } as unknown as string;
}

function normalizeTheme(root: any, lookupSource?: any, theme?: any) {
    const isRoot = theme === undefined;
    if (isRoot) {
        assert(getUnnormalized(root) === root);
        theme = deepmerge(root, {});
        lookupSource = theme;
    }

    const doLookup = (key: string): string | null => {
        const lookupKey = key.slice(1, 1 + key.length - 1);

        const split = lookupKey.split(".");
        let newValue = lookupSource;
        for (const s of split) {
            newValue = newValue[s];
        }

        if (typeof newValue === "string" && newValue.startsWith("_")) {
            return null;
        }

        return newValue;
    };

    let finished = false;
    while (!finished) {
        let skipped = false;
        // eslint-disable-next-line guard-for-in
        for (const key in theme) {
            const val = theme[key];
            try {
                if (isMutationLookup(val)) {
                    const newValue = doLookup(val.key);
                    if (newValue === null) {
                        skipped = true;
                        continue;
                    }
                    theme[key] = val.mutator(newValue);
                } else if (typeof val === "string" && val.startsWith("_")) {
                    const newValue = doLookup(val);
                    if (newValue === null) {
                        skipped = true;
                        continue;
                    }
                    theme[key] = newValue;
                } else if (typeof val === "object") {
                    normalizeTheme(root, lookupSource, val);
                }
            } catch {
                // do nothing we just wont normalize the failed value
            }
        }

        finished = !skipped;
    }

    // We have to set `unnormalized` here at the end, or else the loop above
    // would recur into it.
    if (isRoot) {
        theme.unnormalized = root;
    }

    return theme;
}

const themeCache = new Map();

// why/when should we use this?
export function clearThemeCache() {
    themeCache.clear();
}

export function mergeTheme<TBase extends {}, TOverlay extends { overlayName: string }>(
    baseTheme: TBase,
    inputOverlay: (TOverlay | undefined)[]
): TBase {
    baseTheme = getUnnormalized(baseTheme);
    const overlays = mapFilterUndefined(inputOverlay, (o: any) => definedMap(o, getUnnormalized));

    const themeName: string | undefined = (baseTheme as any).overlayName;

    if (overlays.length === 0) return normalizeTheme(baseTheme);

    const overlayPartialKey = overlays
        .map(o => o.overlayName)
        .filter(n => n !== undefined)
        .join("+");

    const fullName = themeName + "+" + overlayPartialKey;

    // See if normalizedTheme has been cached
    const cachedTheme = themeCache.get(fullName);
    if (cachedTheme !== undefined) {
        return cachedTheme;
    }

    // OVERLAY ORDER IS IMPORTANT
    const unnormalized = deepmerge.all([baseTheme, ...overlays], {
        arrayMerge: (_, src) => src,
    }) as TOverlay;
    unnormalized.overlayName = fullName;

    const result = normalizeTheme(unnormalized) as TBase;

    themeCache.set(fullName, result);

    return result;
}
