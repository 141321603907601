import { AppKind } from "@glide/location-common";
import { getSourceMetadataForTable } from "@glide/common-core/dist/js/components/SerializedApp";
import type { MinimalAppEnvironment, QuotaBannerRequirements } from "@glide/common-core/dist/js/components/types";
import {
    type LoadingValue,
    type Row,
    Table,
    isLoadingValue,
    isQuery,
    type QueryBase,
} from "@glide/computation-model-types";
import {
    type TableName,
    type ArrayColumnType,
    type ArrayItemType,
    type Description,
    type TableColumn,
    type TableGlideType,
    type TableRefGlideType,
    getPrimitiveNonHiddenColumns,
    getPrimitiveOrPrimitiveArrayNonHiddenColumns,
    getSourceColumnPath,
    getTableColumnDisplayName,
    getTableName,
    getTableRefTableName,
    isComputedColumn,
    isMultiRelationType,
    isSingleRelationType,
    makeArrayType,
    makeTableRef,
    sheetNameForTable,
    type SchemaInspector,
    getSourceMetadataFlags,
    isBigTableOrExternal,
} from "@glide/type-schema";
import {
    type ArrayContentDescription,
    type ArrayScreenDescription,
    type BaseContainerComponentDescription,
    type ComponentDescription,
    type MutatingScreenKind,
    ActionKind,
    ArrayScreenFormat,
    PropertyKind,
    getEnumProperty,
    getNumberProperty,
    getSourceColumnProperty,
    getStringProperty,
    getSwitchProperty,
    getTableProperty,
    makeActionProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeNumberProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import {
    type InputOutputTables,
    namesForRows,
    ComponentKindInlineList,
    makeEmptyComponentDescription,
    makeInputOutputTables,
} from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { assert, defined, DefaultMap, assertNever } from "@glideapps/ts-necessities";
import {
    memoizeFunction,
    getCurrentTimestampInMilliseconds,
    isDefined,
    isEmptyOrUndefined,
    logError,
    logInfo,
    updateDeleteUndefined,
} from "@glide/support";
import { DeviceFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import { frontendSendEvent } from "@glide/common-core/dist/js/tracing";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { Mood } from "@glide/component-utils";
import type {
    WireActivitySpinnerComponent,
    WireMultipleFilterComponent,
} from "@glide/fluent-components/dist/js/base-components";
import { activitySpinnerComponentHydrationResult } from "@glide/fluent-components/dist/js/component";
import {
    type WireButtonComponent,
    type WireHintComponent,
    CardCollection,
    MapCollection,
} from "@glide/fluent-components/dist/js/fluent-components";
import {
    type ActionPropertyDescriptor,
    type AllowedDataEdits,
    type AppDescriptionContext,
    type ComponentDescriptor,
    type ComponentSpecialCaseDescriptor,
    type EditedColumnsAndTables,
    type InlineListComponentDescription,
    type InteractiveComponentConfiguratorContext,
    type ListSourcePropertyDescription,
    type PropertyDescriptor,
    type PropertyTable,
    type RewritingComponentConfiguratorContext,
    ArrayPropertyStyle,
    NumberPropertyHandler,
    NumberPropertyStyle,
    PropertySection,
    QueryableTableSupport,
    RequiredKind,
    SwitchPropertyHandler,
    combineEditedColumnsAndTables,
    convertEditedColumnsToAllowedDataEdits,
    getColumnForSourceColumn,
    getInlineListPropertyTable,
    getTableForRelationType,
    makeDynamicFilterColumnPropertyHandler,
    mergeAllowedDataEdits,
    resolveSourceColumn,
    type TableAllowedPropertyOptions,
    isTableAllowedForProperty,
    makeTableOrRelationPropertyDescriptor,
} from "@glide/function-utils";
import {
    type BuilderCallbacks,
    type DynamicFilterResult,
    type RowBackends,
    type WireAlwaysEditableValue,
    type WireComponentHydrationResult,
    type WireComponentPreHydrationResult,
    type WireInflationBackend,
    type WireQueryGetter,
    type WireRowComponentHydrationBackend,
    type WireRowComponentHydrator,
    type WireRowComponentHydratorConstructor,
    type WireTableBackendGetter,
    type WireTableComponentHydrationBackend,
    type WireTableComponentHydrator,
    type WireTableComponentQueryHydrator,
    type WireTableTransformer,
    type WireSubsidiaryScreen,
    type WireComponent,
    type WireEditableValue,
    type WirePaging,
    WireActionResult,
    ValueChangeSource,
    WireComponentKind,
    UIButtonAppearance,
    WireModalSize,
} from "@glide/wire";
import { definedMap, hasOwnProperty } from "collection-utils";
import flatMap from "lodash/flatMap";
import flatten from "lodash/flatten";
import isString from "lodash/isString";
import last from "lodash/last";
import { getSupportsUserProfileRowAccess } from "../actions/set-columns";
import {
    type ArrayScreenHandler,
    allArrayScreenHandlers,
    getDefaultArrayScreenHandler,
    handlerForArrayScreenFormat,
} from "../array-screens";
import type { ArrayContentHandler } from "../array-screens/array-content";
import { PieChartArrayContentHandler } from "../array-screens/charts";
import { ChoiceArrayContentHandler } from "../array-screens/choice";
import { EventPickerArrayContentHandler } from "../array-screens/event-picker";
import {
    type DynamicFilterState,
    type InflatedDynamicFilter,
    type MultipleDynamicFilterState,
    type MultipleDynamicFiltersResult,
    type QueryableMultipleFilterEntriesWithCaption,
    type WireStringGetter,
    type FilterEntry,
    applyDynamicFilter,
    applyMultipleDynamicFilters,
    applyPaging,
    applySearchToQuery,
    encodeScreenKey,
    getDynamicFilterState,
    getItemsPageIndex,
    getMultipleDynamicFilterState,
    getPageSizeForPaging,
    getQueryLimitForPaging,
    getScreenSearchState,
    inflateDynamicFilter,
    inflateMultipleDynamicFilters,
    inflateStringProperty,
    makeSearchPropertyGetters,
    makeSearchableColumnsForList,
    queryDynamicFilter,
    queryMultipleDynamicFilters,
    registerActionRunner,
    searchRows,
} from "../wire/utils";
import { calendarCollectionArrayContentHandler } from "./calendar-collection-array-contents";
import { chartsFluentArrayContentHandler } from "./charts-array-content";
import { commentsFluentArrayContentHandler } from "./comments-array-content";
import type { ComponentEasyTabConfiguration, ScreenContext } from "./component-handler";
import { dataGridFluentArrayContentHandler } from "./data-grid-array-content";
import { dataGridFluentArrayContentHandler as newDataGridFluentArrayContentHandler } from "./new-data-grid-array-content";

import { PopulationMode } from "./description-handlers";
import { getDefaultCaption, makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { makeFluentArrayContentHandler } from "./fluent-array-handler";
import { forEachContainerFluentArrayContentHandler } from "./for-each-array-content";
import { ComponentHandlerBase } from "./handler";
import { kanbanFluentArrayContentHandler } from "./kanban-array-content";
import { populateDescription } from "./populate-description";
import { radialChartFluentArrayContentHandler } from "./radial-chart-array-content";
import { getLocalizedString } from "@glide/localization";
import { superTableFluentArrayContentHandler } from "./super-table-array-content";
import { getDefaultTable } from "../schema-utils";
import { dataPlotFluentArrayContentHandler } from "./data-plot-array-content";

// For historical reasons NewDataGrid and SuperTable have a different title property name to CardCollection.
// See fluent-components.ts for their definitions.
//
// To correctly propagate the titles between them we find what's the current title
// and return both properties with the same value.
//
// Exported for test
export function remapDescriptionTitle(d: Description, to: ArrayContentHandler<ArrayContentDescription>): Description {
    const currentFormat = hasOwnProperty(d, "format") ? getEnumProperty<ArrayScreenFormat>(d.format) : undefined;
    if (currentFormat === undefined) return d;

    const isNewTableOrDataGrid =
        currentFormat === ArrayScreenFormat.NewDataGrid || currentFormat === ArrayScreenFormat.SuperTable;
    const currentTitlePropertyName = isNewTableOrDataGrid ? "title" : "componentTitle";
    const currentTitleProperty = hasOwnProperty(d, currentTitlePropertyName) ? d[currentTitlePropertyName] : undefined;

    const toNewTableOrDataGrid =
        to.format === ArrayScreenFormat.NewDataGrid || to.format === ArrayScreenFormat.SuperTable;

    if (toNewTableOrDataGrid) {
        return {
            ...d,
            title: currentTitleProperty,
            componentTitle: undefined,
        };
    }

    return {
        ...d,
        componentTitle: currentTitleProperty,
        title: undefined,
    };
}

function makeMultipleDynamicFiltersPropertyHandler(ccc: AppDescriptionContext): PropertyDescriptor {
    const gbtComputedColumnsAlpha = isExperimentEnabled("gbtComputedColumnsAlpha", ccc.userFeatures);

    return {
        kind: PropertyKind.Array,
        label: "Multiple filters",
        property: { name: "multipleDynamicFilters" },
        section: PropertySection.DynamicFilter,
        properties: [
            makeCaptionStringPropertyDescriptor("Filter", true, undefined, { placeholder: "Filter" }),
            {
                kind: PropertyKind.Column,
                property: { name: "column" },
                section: PropertySection.Options,
                label: "Filter by",
                required: true,
                editable: true,
                searchable: false,
                emptyByDefault: false,
                getIndirectTable: getPropertyTableForContent,
                isDefaultCaption: true,
                forFilteringRows: true,
                columnFilter: {
                    columnTypeIsAllowed: () => true,
                    getCandidateColumns: (t, _d, s) => {
                        const sm = getSourceMetadataForTable(s.sourceMetadata, t);
                        const { queryable } = getSourceMetadataFlags(sm);
                        if (queryable !== undefined) {
                            // The only queryable data source that supports
                            // filtering by arrays is GBT, and only if the
                            // experiment is enabled, because it doesn't yet work
                            // for date/times and JSON data.
                            if (!queryable.supportsFilteringByArrays || !gbtComputedColumnsAlpha) {
                                return getPrimitiveNonHiddenColumns(t);
                            }
                        }
                        return getPrimitiveOrPrimitiveArrayNonHiddenColumns(t);
                    },
                },
            },
        ],
        allowEmpty: true,
        allowReorder: true,
        addItemLabels: ["Add filter"],
        style: ArrayPropertyStyle.KeyValue,
    };
}

const searchFilterTimeout: number | undefined = undefined;

const defaultArrayScreenFormat = ArrayScreenFormat.List;

function makeAllowSearchPropertyHandler(allowByDefault: boolean) {
    return new SwitchPropertyHandler({ allowSearch: allowByDefault }, "Show search bar", PropertySection.Search);
}

function makePageSizePropertyHandler(defaultPageSize: number, maxPageSize?: number) {
    return new NumberPropertyHandler(
        { pageSize: defaultPageSize },
        "Page size",
        defaultPageSize.toString(),
        RequiredKind.Required,
        NumberPropertyStyle.Entry,
        PropertySection.Options,
        undefined,
        maxPageSize
    );
}

function getPropertyTableForContent(
    containingScreenTables: InputOutputTables | undefined,
    rootDesc: Description,
    _desc: Description,
    s: SchemaInspector
): PropertyTable | undefined {
    const [property] = getListSourceProperty(rootDesc as InlineListComponentDescription, containingScreenTables, s);
    return getInlineListPropertyTable(containingScreenTables?.input, property, s);
}

function getListSourceProperty(
    desc: InlineListComponentDescription | undefined,
    containingScreenTables: InputOutputTables | undefined,
    schema: SchemaInspector
): [desc: ListSourcePropertyDescription | undefined, sourcePicker: boolean] {
    if (desc === undefined) return [undefined, true];

    const handler = getArrayContentHandlerForDescription(desc);
    return handler.getListSourceProperty(desc, containingScreenTables, schema) ?? [undefined, true];
}

const getApplicableHandlersMemoized = memoizeFunction(
    "getApplicableHandlersMemoized",
    (
        ccc: AppDescriptionContext,
        table: TableGlideType | undefined,
        containingScreenTables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined
    ) => {
        const applicableHandlers = allArrayScreenHandlers.filter(a => !a.isLegacy && a.supportsInlineList);
        if (table === undefined) {
            return applicableHandlers;
        }
        return applicableHandlers.filter(
            h =>
                h.defaultContentDescription(
                    {
                        // We're giving `getPropertyTableForContent`, so we
                        // have to define what the indirect table is.
                        propertyName: makeTableProperty(getTableName(table)),
                    },
                    makeInputOutputTables(table),
                    ccc,
                    true,
                    containingScreenTables,
                    mutatingScreenKind,
                    false,
                    getPropertyTableForContent,
                    undefined,
                    undefined,
                    undefined
                ) !== undefined
        );
    }
);

// FIXME: This should return an array of `ArrayContentHandler`s.
function getApplicableHandlers(
    desc: InlineListComponentDescription | undefined,
    column: TableColumn | undefined,
    tables: InputOutputTables | undefined,
    ccc: AppDescriptionContext,
    mutatingScreenKind: MutatingScreenKind | undefined
): ReadonlyArray<ArrayScreenHandler<ArrayContentDescription, ArrayScreenDescription>> {
    const [property] = getListSourceProperty(desc, tables, ccc);

    if (column === undefined && property !== undefined && tables !== undefined) {
        const sc = getSourceColumnProperty(property);
        if (sc !== undefined) {
            column = getColumnForSourceColumn(ccc, sc, tables.input, undefined, []);
        }
    }

    const table = definedMap(column, c => getTableForRelationType(ccc, c.type));
    let applicableHandlers = getApplicableHandlersMemoized(ccc, table, tables, mutatingScreenKind);
    if (applicableHandlers.length === 0) {
        // FIXME: This is a hack so that we don't have to be able to return
        // an `undefined` result here, which would cause more refactoring.
        applicableHandlers = [getDefaultArrayScreenHandler()];
    }
    return applicableHandlers;
}

export function getDescriptiveNameForTableOrRow(
    property: ListSourcePropertyDescription | undefined,
    table: TableGlideType,
    schema: SchemaInspector
): string | undefined {
    const sc = getSourceColumnProperty(property);
    if (sc !== undefined) {
        const resolved = resolveSourceColumn(schema, sc, table, undefined, undefined);

        if (resolved?.tableAndColumn?.column !== undefined) {
            return getTableColumnDisplayName(resolved.tableAndColumn?.column);
        }

        const path = getSourceColumnPath(sc);
        if (path.length === 0) {
            return namesForRows[sc.kind];
        }

        return last(getSourceColumnPath(sc));
    } else {
        const tableProperty = getTableProperty(property);
        if (tableProperty !== undefined) {
            const appropriateTable = schema.findTable(tableProperty);
            return definedMap(appropriateTable, t => sheetNameForTable(t));
        }
    }
    return undefined;
}

export function isInlineListComponentDescription(desc: ComponentDescription): desc is InlineListComponentDescription {
    return desc.kind === ComponentKindInlineList;
}

function getInlineListArrayScreenFormat(desc: InlineListComponentDescription): ArrayScreenFormat {
    return getEnumProperty(desc.format) ?? defaultArrayScreenFormat;
}

const specialCaseContentHandlers: readonly ArrayContentHandler<ArrayContentDescription>[] = [
    new PieChartArrayContentHandler() as ArrayContentHandler<ArrayContentDescription>,
    new ChoiceArrayContentHandler() as ArrayContentHandler<ArrayContentDescription>,
    new EventPickerArrayContentHandler() as ArrayContentHandler<ArrayContentDescription>,
    makeFluentArrayContentHandler(CardCollection),
    superTableFluentArrayContentHandler,
    newDataGridFluentArrayContentHandler,
    dataGridFluentArrayContentHandler,
    calendarCollectionArrayContentHandler,
    makeFluentArrayContentHandler(MapCollection),
    kanbanFluentArrayContentHandler,
    chartsFluentArrayContentHandler,
    dataPlotFluentArrayContentHandler,
    forEachContainerFluentArrayContentHandler,
    radialChartFluentArrayContentHandler,
    commentsFluentArrayContentHandler,
];

function getArrayContentHandler(format: ArrayScreenFormat): ArrayContentHandler<ArrayContentDescription> {
    let handler = specialCaseContentHandlers.find(h => h.format === format);
    if (handler !== undefined) {
        return handler;
    }
    handler = handlerForArrayScreenFormat(format);
    if (handler === undefined) {
        handler = defined(handlerForArrayScreenFormat(defaultArrayScreenFormat));
    }
    return handler;
}

function getArrayContentHandlerForDescription(
    desc: InlineListComponentDescription | undefined
): ArrayContentHandler<ArrayContentDescription> {
    const format = definedMap(desc, getInlineListArrayScreenFormat) ?? defaultArrayScreenFormat;
    return getArrayContentHandler(format);
}

// This function gets the special case descriptor even if it's legacy
// This is what we want, otherwise legacy components break.
function getArrayContentHandlerForSpecialCaseDescriptor(
    ccc: AppDescriptionContext,
    specialCaseDescriptor: ComponentSpecialCaseDescriptor
): ArrayContentHandler<ArrayContentDescription> | undefined {
    return specialCaseContentHandlers.find(
        h => h.getSpecialCaseDescriptors?.(ccc)?.some(d => d.name === specialCaseDescriptor.name) === true
    );
}

export function getDefaultInlineListSpecialCaseForEasyTabConfiguration(
    adc: AppDescriptionContext
): ComponentSpecialCaseDescriptor | undefined {
    for (const handler of specialCaseContentHandlers) {
        if (!handler.supportsEasyTabConfiguration) continue;

        // Pick the first non-legacy one
        const specialCase = handler.getSpecialCaseDescriptors?.(adc).filter(s => s.isLegacy !== true)[0];
        if (specialCase !== undefined) return specialCase;
    }

    return undefined;
}

const easyTabSwitchOrderd = ["Card", "List", "Table", "Data Grid", "Checklist", "Calendar", "Kanban"];
function easyTabSwitchComponentOrder(
    a: readonly [ComponentSpecialCaseDescriptor, ArrayContentHandler<ArrayContentDescription>],
    b: readonly [ComponentSpecialCaseDescriptor, ArrayContentHandler<ArrayContentDescription>]
) {
    const orderA = easyTabSwitchOrderd.indexOf(a[0].name);
    const orderB = easyTabSwitchOrderd.indexOf(b[0].name);

    // If not found, place it at the end by assigning a value higher than the last index of known labels
    const maxOrderIndex = easyTabSwitchOrderd.length;
    const indexA = orderA === -1 ? maxOrderIndex : orderA;
    const indexB = orderB === -1 ? maxOrderIndex : orderB;
    return indexA - indexB;
}

// This is the ##pagesTabStyleSwitcher we get when we're in easy mode.
export function makeEasyTabStyleSwitcher(
    adc: AppDescriptionContext,
    currentSpecialCase: ComponentSpecialCaseDescriptor | undefined,
    getUpdateForSpecialCase: (
        desc: Description,
        specialCase: readonly [ComponentSpecialCaseDescriptor, ArrayContentHandler<ArrayContentDescription>],
        iccc: InteractiveComponentConfiguratorContext | undefined
    ) => Partial<Description>,
    withCustom: boolean
): PropertyDescriptor {
    // [descriptor, handler]
    // should we filter the legacy cases here?
    const easySpecialCases = flatMap(specialCaseContentHandlers, h =>
        h.supportsEasyTabConfiguration
            ? (h.getSpecialCaseDescriptors?.(adc).filter(d => d.isLegacy !== true) ?? []).map(d => [d, h] as const)
            : []
    ).sort(easyTabSwitchComponentOrder);

    const currentIndex = easySpecialCases.findIndex(([d]) => d.name === currentSpecialCase?.name) ?? -1;
    const currentProperty = makeEnumProperty(currentIndex);

    const enumCases = [
        ...easySpecialCases.map(([d], i) => ({
            value: i,
            label: d.name,
            icon: d.img,
        })),
    ];

    if (withCustom) {
        enumCases.push({
            value: -1,
            label: "Custom",
            icon: "mt-component-custom",
        });
    }

    return {
        kind: PropertyKind.Enum,
        property: {
            id: "easyTabStyle",
            get: () => currentProperty,
            update: (d, value, updater) => {
                if (updater === undefined) return {};
                const index = getEnumProperty(value);
                if (typeof index !== "number") return {};
                const newSpecialCase = easySpecialCases[index];
                const descriptionWithRemappedTitle = remapDescriptionTitle(d, newSpecialCase[1]);
                return getUpdateForSpecialCase(descriptionWithRemappedTitle, newSpecialCase, updater);
            },
        },
        label: "Style",
        menuLabel: "Style",
        cases: enumCases,
        section: PropertySection.Style,
        visual: "large-images",
        changesDescriptor: true,
        defaultCaseValue: -1,
    };
}

type ListSourceItemType<T extends boolean> = T extends true ? TableRefGlideType : ArrayItemType;

function getListSourceArrayType(
    pd: ListSourcePropertyDescription,
    table: TableGlideType | undefined,
    schema: SchemaInspector
): { column: TableColumn; arrayType: ArrayColumnType; hasFormula: boolean } | undefined {
    const sc = getSourceColumnProperty(pd);
    if (sc === undefined) return undefined;
    const column = getColumnForSourceColumn(schema, sc, table, undefined, []);
    if (column === undefined) {
        // logInfo(
        //     `Could not find column ${JSON.stringify(sc)} in table ${
        //         definedMap(table, getTableName)?.name
        //     } for list items`
        // );
        return undefined;
    }

    if (column.type.kind !== "array") return undefined;
    return { column, arrayType: column.type, hasFormula: isComputedColumn(column) };
}

interface ListSourceTypes<T extends boolean> {
    readonly column: TableColumn | undefined;
    readonly arrayType: ArrayColumnType;
    readonly itemsType: ListSourceItemType<T>;
    readonly hasSpecification: boolean;
}

function getListSourceTypes<T extends boolean>(
    pd: ListSourcePropertyDescription,
    table: TableGlideType | undefined,
    onlyTableRef: T,
    schema: SchemaInspector
): ListSourceTypes<T> | undefined {
    const maybeArrayType = getListSourceArrayType(pd, table, schema);
    if (maybeArrayType === undefined) return undefined;

    const { column, arrayType, hasFormula: hasSpecification } = maybeArrayType;
    let { items } = arrayType;

    if (onlyTableRef) {
        if (!isSingleRelationType(items)) {
            logInfo("Can't make list from array of non-references");
            return undefined;
        }
    }

    if (isSingleRelationType(items)) {
        // We need to do this because `items` is a universal table name, but
        // `itemsType` must be a "normal" table name.
        const t = schema.findTable(items);
        if (t === undefined) return undefined;
        items = makeTableRef(t);
    }

    return { column, arrayType, itemsType: items as ListSourceItemType<T>, hasSpecification };
}

export function getListTypes<T extends boolean>(
    schema: SchemaInspector,
    pd: ListSourcePropertyDescription,
    table: TableGlideType | undefined,
    onlyTableRef: T
): ListSourceTypes<T> | undefined {
    const sourceColumn = getSourceColumnProperty(pd);
    const tableName = getTableProperty(pd);

    if (sourceColumn !== undefined) {
        return getListSourceTypes(pd, table, onlyTableRef, schema);
    } else {
        const t = schema.findTable(tableName);
        if (t === undefined) return undefined;

        const itemsType = makeTableRef(t) as ListSourceItemType<T>;
        return {
            column: undefined,
            itemsType,
            arrayType: makeArrayType(itemsType),
            hasSpecification: false,
        };
    }
}

function makeTableAllowedOptions(
    contentHandler: ArrayContentHandler<ArrayContentDescription>
): TableAllowedPropertyOptions {
    return {
        allowQueryableTables: contentHandler.queryableTableSupport !== QueryableTableSupport.Disable,
        allowUserProfileTableAndRow: true,
        forWriting: false,
    };
}

export class InlineListComponentHandler extends ComponentHandlerBase<InlineListComponentDescription> {
    constructor() {
        super(ComponentKindInlineList);
    }

    public get appKinds(): AppKind | "both" {
        return AppKind.App;
    }

    public getSubComponents(desc: InlineListComponentDescription): readonly ComponentDescription[] | undefined {
        const handler = getArrayContentHandlerForDescription(desc);
        if (!handler.hasComponents) return undefined;

        const containerDesc = desc as unknown as BaseContainerComponentDescription;
        return containerDesc.components ?? [];
    }

    public getSubComponentTables(
        desc: InlineListComponentDescription,
        containingTables: InputOutputTables,
        schema: SchemaInspector,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): [InputOutputTables, MutatingScreenKind | undefined] | undefined {
        const handler = getArrayContentHandlerForDescription(desc);
        if (!handler.hasComponents) return undefined;

        const table = getPropertyTableForContent(containingTables, desc, desc, schema);
        if (table === undefined) return undefined;

        // If the Inline List shows "this row", then whichever edits happen in
        // the subcomponents are like they'd happen in the main screen.
        return [makeInputOutputTables(table.table), table.inScreenContext ? mutatingScreenKind : undefined];
    }

    public getIsEditor(
        desc: InlineListComponentDescription | undefined,
        ccc: AppDescriptionContext,
        specialCaseDescriptor: ComponentSpecialCaseDescriptor | undefined
    ): boolean {
        let handler: ArrayContentHandler<ArrayContentDescription> | undefined;
        if (desc !== undefined) {
            handler = getArrayContentHandlerForDescription(desc);
        } else if (specialCaseDescriptor !== undefined) {
            handler = getArrayContentHandlerForSpecialCaseDescriptor(ccc, specialCaseDescriptor);
        }
        if (handler === undefined) return false;
        return handler.isEditor;
    }

    public isSearchable(desc: InlineListComponentDescription): boolean {
        return getSwitchProperty(desc.allowSearch) === true;
    }

    public needValidation(desc: InlineListComponentDescription): boolean {
        const contentHandler = getArrayContentHandlerForDescription(desc);
        return contentHandler.needValidation(desc);
    }

    public getQuotaBannerRequirements(desc: InlineListComponentDescription): QuotaBannerRequirements {
        const contentHandler = getArrayContentHandlerForDescription(desc);
        return contentHandler.quotaBannerRequirements;
    }

    private getContentPropertyDescriptors(
        desc: ArrayContentDescription | undefined,
        handler: ArrayContentHandler<ArrayContentDescription>,
        ccc: AppDescriptionContext,
        containingScreenTables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined,
        screenContext: ScreenContext | undefined,
        appEnvironment: MinimalAppEnvironment | undefined
    ): readonly PropertyDescriptor[] {
        return handler.getContentPropertyDescriptors(
            getPropertyTableForContent,
            true,
            containingScreenTables,
            desc,
            ccc,
            mutatingScreenKind,
            false,
            !forEasyTabConfiguration,
            forEasyTabConfiguration,
            isFirstComponent,
            screenContext,
            appEnvironment
        );
    }

    private makeEasyTabStyleSwitcher(
        ccc: AppDescriptionContext,
        specialCase: ComponentSpecialCaseDescriptor,
        tables: InputOutputTables | undefined,
        contentHandler: ArrayContentHandler<ArrayContentDescription>,
        mutatingScreenKind: MutatingScreenKind | undefined,
        forEasyTabConfiguration: boolean
    ): PropertyDescriptor {
        return makeEasyTabStyleSwitcher(
            ccc,
            specialCase,
            (d, newSpecialCase, iccc) => {
                if (newSpecialCase === undefined) {
                    return {
                        forEasyTabConfiguration: undefined,
                    };
                }

                if (iccc === undefined) return {};

                const [newDescriptor, newHandler] = newSpecialCase;
                const itemTable = getPropertyTableForContent(tables, d, d, iccc)?.table;
                if (newHandler === contentHandler) {
                    return {
                        forEasyTabConfiguration: makeSwitchProperty(forEasyTabConfiguration),
                        ...newDescriptor.setAsCurrent?.(
                            d as ComponentDescription,
                            tables,
                            itemTable,
                            iccc,
                            mutatingScreenKind
                        ),
                    };
                } else if (hasOwnProperty(newDescriptor, "setAsCurrent")) {
                    return {
                        forEasyTabConfiguration: makeSwitchProperty(forEasyTabConfiguration),
                        ...newDescriptor.setAsCurrent?.(
                            d as ComponentDescription,
                            tables,
                            itemTable,
                            iccc,
                            mutatingScreenKind
                        ),
                    };
                } else {
                    const table = itemTable ?? tables?.input ?? getDefaultTable(iccc.schema);
                    if (table === undefined) return {};

                    const titlePropertyName = contentHandler.getEasyTabConfiguration(
                        d as ArrayContentDescription
                    )?.titlePropertyName;
                    let title: string | undefined;
                    if (titlePropertyName !== undefined) {
                        title = getStringProperty((d as any)[titlePropertyName]);
                    }

                    return (
                        iccc.makeInlineListForEasyTabConfiguration(table, mutatingScreenKind, newDescriptor, title) ??
                        {}
                    );
                }
            },
            forEasyTabConfiguration
        );
    }

    public getDescriptor(
        desc: InlineListComponentDescription | undefined,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined,
        screenContext?: ScreenContext,
        appEnvironment?: MinimalAppEnvironment
    ): ComponentDescriptor {
        const applicableHandlers = getApplicableHandlers(desc, undefined, tables, ccc, mutatingScreenKind);
        const cases = applicableHandlers.map(h => ({ value: h.format, label: h.label, icon: h.icon }));

        const contentHandler = getArrayContentHandlerForDescription(desc);
        const specialCases = contentHandler.getSpecialCaseDescriptors?.(ccc) ?? [];
        const haveSpecialCases = specialCases.length > 0;

        let specialCase: ComponentSpecialCaseDescriptor | undefined;
        if (specialCases.length === 1) {
            specialCase = specialCases[0];
        } else if (desc !== undefined) {
            specialCase = specialCases.find(sc => sc.getIsCurrent?.(desc) === true);
        }

        const contentPropertyDescriptors = this.getContentPropertyDescriptors(
            desc,
            contentHandler,
            ccc,
            tables,
            mutatingScreenKind,
            forEasyTabConfiguration,
            isFirstComponent,
            screenContext,
            appEnvironment
        );

        const [, sourcePicker] = getListSourceProperty(desc, tables, ccc);

        const [sourceLabel, isSourceDefaultCaption] = contentHandler.sourcePropertyLabel ?? ["Source", true];
        const propertySection: PropertySection =
            contentHandler.sourcePropertySection ??
            (forEasyTabConfiguration ? PropertySection.Screen : PropertySection.DataTop);

        const properties: PropertyDescriptor[] = [];

        if (sourcePicker) {
            properties.push(
                makeTableOrRelationPropertyDescriptor(
                    mutatingScreenKind,
                    sourceLabel,
                    propertySection,
                    {
                        ...makeTableAllowedOptions(contentHandler),
                        allowTables: true,
                        preferFullRow: false,
                        allowRewrite: true,
                        forWriting: false,
                        // For ##easyTabConfiguration we don't allow a bunch
                        // of stuff.  Basically you can only pick a full
                        // table.
                        allowSingleRelations: !forEasyTabConfiguration,
                        allowMultiRelations: !forEasyTabConfiguration,
                        sourceIsDefaultCaption: isSourceDefaultCaption,
                        allowUserProfile: !forEasyTabConfiguration && getSupportsUserProfileRowAccess(ccc),
                    },
                    { name: "propertyName" },
                    true
                )
            );
        }

        if (!forEasyTabConfiguration) {
            const captionFlags = contentHandler.getCaptionFlags(desc);
            if (captionFlags !== undefined) {
                properties.push(makeCaptionStringPropertyDescriptor("List", false, mutatingScreenKind, captionFlags));
            }
        }

        if (forEasyTabConfiguration) {
            if (specialCase !== undefined) {
                properties.push(
                    this.makeEasyTabStyleSwitcher(
                        ccc,
                        specialCase,
                        tables,
                        contentHandler,
                        mutatingScreenKind,
                        forEasyTabConfiguration
                    )
                );
            }
        } else if (!haveSpecialCases) {
            properties.push({
                kind: PropertyKind.Enum,
                property: { name: "format" },
                label: "Layout",
                menuLabel: "Choose layout",
                cases,
                defaultCaseValue: cases[0].value,
                section: PropertySection.Style,
                visual: "large-images",
                changesDescriptor: true,
            });
        } else if (
            specialCase !== undefined &&
            specialCaseContentHandlers.some(
                handler => handler.format === contentHandler.format && handler.supportsEasyTabConfiguration
            )
        ) {
            properties.push(
                this.makeEasyTabStyleSwitcher(
                    ccc,
                    specialCase,
                    tables,
                    contentHandler,
                    mutatingScreenKind,
                    forEasyTabConfiguration
                )
            );
        }
        properties.push(...contentPropertyDescriptors);

        if (!forEasyTabConfiguration) {
            const searchSupport = contentHandler.supportsSearch;
            if (
                searchSupport !== undefined &&
                (ccc.appKind !== AppKind.App || (!haveSpecialCases && mutatingScreenKind === undefined))
            ) {
                properties.push(makeAllowSearchPropertyHandler(searchSupport.enabledByDefault));
            }

            if (contentHandler.supportsDynamicFilter !== undefined) {
                const dynamicFilterProperty = makeDynamicFilterColumnPropertyHandler(getPropertyTableForContent);

                properties.push(makeMultipleDynamicFiltersPropertyHandler(ccc));

                if (desc !== undefined && dynamicFilterProperty.getColumnName(desc) !== undefined) {
                    properties.push(dynamicFilterProperty);
                }
            }

            properties.push(...this.getBasePropertyDescriptors());

            if (contentHandler.allowChangePageSize && contentHandler.defaultPageSize !== undefined) {
                properties.push(
                    makePageSizePropertyHandler(contentHandler.defaultPageSize, contentHandler.maxPageSize)
                );
            }
        }

        return {
            name: specialCase?.name ?? contentHandler.displayName ?? "Inline List",
            description: "An inline collection of related items",
            img: specialCase?.img ?? contentHandler.icon ?? "componentInlineList",
            group: "Lists",
            // FIXME: special case doc link
            helpUrl: getDocURL(contentHandler.helpPath),
            properties,
            specialCaseDescriptor: specialCase,
        };
    }

    public getActionDescriptors(
        desc: InlineListComponentDescription,
        tables: InputOutputTables | undefined,
        adc: AppDescriptionContext,
        _mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly ActionPropertyDescriptor[] {
        const contentHandler = getArrayContentHandlerForDescription(desc);
        return contentHandler.getActionDescriptors(desc, tables, getPropertyTableForContent, adc);
    }

    public lowerDescriptionForBuilding(
        originalDesc: InlineListComponentDescription,
        tables: InputOutputTables | undefined,
        adc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isFirstComponent: boolean | undefined,
        forGC: boolean,
        screenContext?: ScreenContext,
        appEnvironment?: MinimalAppEnvironment
    ): [desc: InlineListComponentDescription, descr: ComponentDescriptor] {
        const contentHandler = getArrayContentHandlerForDescription(originalDesc);

        const desc = contentHandler.lowerDescriptionForBuilding(originalDesc, forGC);

        const descr = this.getDescriptor(
            desc,
            tables,
            adc,
            mutatingScreenKind,
            false,
            isFirstComponent,
            screenContext,
            appEnvironment
        );

        return [desc, descr];
    }

    public static defaultComponent(
        column: TableColumn,
        ccc: AppDescriptionContext,
        tables: InputOutputTables
    ): InlineListComponentDescription | undefined {
        const table = getTableForRelationType(ccc, column.type);
        if (table === undefined) return undefined;

        const handler = getApplicableHandlers(undefined, column, undefined, ccc, undefined)[0];

        const baseDesc = {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            format: makeEnumProperty(handler.format),
            propertyName: makeColumnProperty(column.name),
            caption: makeStringProperty(getTableColumnDisplayName(column)),
            allowSearch: makeSwitchProperty(handler.supportsSearch?.enabledByDefault === true),
            pageSize: definedMap(handler.defaultPageSize, makeNumberProperty),
            forEasyTabConfiguration: undefined,
        };
        return handler.defaultContentDescription(
            baseDesc,
            tables,
            ccc,
            true,
            tables,
            undefined,
            false,
            getPropertyTableForContent,
            undefined,
            undefined,
            undefined
        );
    }

    private newComponentFromHandler(
        tables: InputOutputTables,
        propertyName: ListSourcePropertyDescription,
        handler: ArrayContentHandler<ArrayContentDescription>,
        ccc: AppDescriptionContext,
        setCaption: boolean,
        mutatingScreenKind: MutatingScreenKind | undefined,
        specialCaseDescriptor: ComponentSpecialCaseDescriptor | undefined,
        usedColumns: ReadonlySet<TableColumn> | undefined,
        editedColumns: ReadonlySet<TableColumn> | undefined
    ): InlineListComponentDescription | undefined {
        const base = {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            format: makeEnumProperty(handler.format),
            propertyName,
            allowSearch: makeSwitchProperty(handler.supportsSearch?.enabledByDefault === true),
            reverse: makeSwitchProperty(false),
            pageSize: definedMap(handler.defaultPageSize, makeNumberProperty),
            forEasyTabConfiguration: undefined,
        };
        const content = handler.defaultContentDescription(
            base,
            tables,
            ccc,
            true,
            tables,
            mutatingScreenKind,
            false,
            getPropertyTableForContent,
            specialCaseDescriptor,
            usedColumns,
            editedColumns
        );
        if (content === undefined) return undefined;

        let caption: string | undefined;
        if (setCaption) {
            const descriptors = this.getContentPropertyDescriptors(
                content,
                handler,
                ccc,
                tables,
                undefined,
                false,
                undefined,
                undefined,
                undefined
            );
            caption = getDefaultCaption(descriptors, content, tables, ccc);
            if (caption === undefined) {
                caption = sheetNameForTable(tables.input);
            }
        }

        return {
            ...content,
            caption: makeStringProperty(caption),
        };
    }

    private newComponentFromHandlerForAnyTable(
        handler: ArrayContentHandler<ArrayContentDescription>,
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        setCaption: boolean,
        mutatingScreenKind: MutatingScreenKind | undefined,
        specialCaseDescriptor: ComponentSpecialCaseDescriptor | undefined,
        usedColumns: ReadonlySet<TableColumn> | undefined,
        editedColumns: ReadonlySet<TableColumn> | undefined
    ): InlineListComponentDescription | undefined {
        const tryTable = (table: TableGlideType) => {
            const opts = makeTableAllowedOptions(handler);
            if (!isTableAllowedForProperty(ccc, table, true, opts)) return undefined;

            const tableName = getTableName(table);
            if (tableName.isSpecial) return undefined;

            return this.newComponentFromHandler(
                tables,
                makeTableProperty(tableName),
                handler,
                ccc,
                setCaption,
                mutatingScreenKind,
                specialCaseDescriptor,
                usedColumns,
                editedColumns
            );
        };

        let desc = tryTable(tables.input);
        if (desc !== undefined) {
            return desc;
        }

        const { schema } = ccc;
        for (const table of schema.tables) {
            desc = tryTable(table);
            if (desc !== undefined) {
                return desc;
            }
        }

        return undefined;
    }

    // This is only here so it's possible to add this component without there being
    // a reference column.
    public newComponent(
        tables: InputOutputTables,
        usedColumns: ReadonlySet<TableColumn>,
        editedColumns: ReadonlySet<TableColumn>,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): InlineListComponentDescription | undefined {
        let desc = super.newComponent(tables, usedColumns, editedColumns, iccc, mutatingScreenKind);
        if (getListSourceProperty(desc, tables, iccc)[0] !== undefined) return desc;

        const handler = handlerForArrayScreenFormat(defaultArrayScreenFormat);
        if (handler !== undefined) {
            desc = this.newComponentFromHandlerForAnyTable(
                handler,
                tables,
                iccc,
                true,
                mutatingScreenKind,
                undefined,
                undefined,
                undefined
            );
            if (desc !== undefined) {
                return desc;
            }
        }

        return {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            format: makeEnumProperty(defaultArrayScreenFormat),
            propertyName: undefined,
            caption: makeStringProperty("Inline list"),
            allowSearch: makeSwitchProperty(false),
            reverse: makeSwitchProperty(false),
            groupByColumn: undefined,
            transforms: [],
            actions: makeActionProperty({ kind: ActionKind.PushDetailScreen }),
            components: [],
            pageSize: definedMap(handler?.defaultPageSize, makeNumberProperty),
            forEasyTabConfiguration: undefined,
        };
    }

    public updateComponent(
        desc: InlineListComponentDescription,
        updates: Partial<InlineListComponentDescription>,
        tables: InputOutputTables | undefined,
        ccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): InlineListComponentDescription {
        const isNewTable = updates.propertyName !== undefined;

        desc = updateDeleteUndefined(desc, updates);
        if (tables !== undefined) {
            desc = defined(
                populateDescription(
                    d => this.getPropertyAndActionDescriptors(d, tables, ccc, mutatingScreenKind, false, undefined),
                    isNewTable ? PopulationMode.RewriteWithNewTable : PopulationMode.Rewrite,
                    desc,
                    desc,
                    tables,
                    ccc,
                    true,
                    mutatingScreenKind,
                    undefined,
                    undefined,
                    undefined,
                    true
                )
            );
        }

        if (tables !== undefined) {
            const handler = getArrayContentHandlerForDescription(desc);
            desc = {
                ...desc,
                ...handler.adjustContentDescriptionAfterUpdate(desc, updates, tables, ccc, getPropertyTableForContent),
            };
        }

        return desc;
    }

    public getSpecialCaseDescriptors(ccc: AppDescriptionContext): readonly ComponentSpecialCaseDescriptor[] {
        return flatten(specialCaseContentHandlers.map(h => h.getSpecialCaseDescriptors?.(ccc) ?? []));
    }

    public newSpecialCaseComponent(
        specialCaseDescriptor: ComponentSpecialCaseDescriptor,
        tables: InputOutputTables,
        usedColumns: ReadonlySet<TableColumn>,
        editedColumns: ReadonlySet<TableColumn>,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        _insideContainer: boolean,
        onTopLevelScreen: boolean,
        forceSourceToBeTable?: boolean
    ): InlineListComponentDescription | undefined {
        const handler = getArrayContentHandlerForSpecialCaseDescriptor(iccc, specialCaseDescriptor);
        if (handler === undefined) return undefined;

        const fromTable = this.newComponentFromHandlerForAnyTable(
            handler,
            tables,
            iccc,
            handler.getCaptionFlags(undefined) !== undefined,
            mutatingScreenKind,
            specialCaseDescriptor,
            usedColumns,
            editedColumns
        );
        // On top-level screens we default to tables vs relations.
        // https://github.com/quicktype/glide/issues/14882
        if ((onTopLevelScreen || handler.defaultIsTable) && fromTable !== undefined) {
            return fromTable;
        }
        // If we force the source to be a table and it isn't, we return
        // `undefined`.
        if (forceSourceToBeTable === true) {
            return fromTable;
        }

        for (const multiRelation of [true, false]) {
            for (const column of tables.input.columns) {
                const isMulti = isMultiRelationType(column.type);
                if (isMulti !== multiRelation) continue;

                const table = getTableForRelationType(iccc, column.type);
                if (table === undefined) continue;

                const desc = this.newComponentFromHandler(
                    tables,
                    makeColumnProperty(column.name),
                    handler,
                    iccc,
                    handler.getCaptionFlags(undefined) !== undefined,
                    mutatingScreenKind,
                    specialCaseDescriptor,
                    usedColumns,
                    editedColumns
                );
                if (desc !== undefined) {
                    return desc;
                }
            }
        }

        return fromTable;
    }

    public getDescriptiveName(
        desc: InlineListComponentDescription,
        tables: InputOutputTables | undefined,
        ctx: AppDescriptionContext
    ): [string, string] {
        const handler = getArrayContentHandlerForDescription(desc);
        const specialCases = handler.getSpecialCaseDescriptors?.(ctx) ?? [];

        let specialCase: ComponentSpecialCaseDescriptor | undefined;
        if (specialCases.length === 1) {
            specialCase = specialCases[0];
        } else if (desc !== undefined) {
            specialCase = specialCases.find(sc => sc.getIsCurrent?.(desc) === true);
        }

        let componentName: string;
        if (specialCase !== undefined) {
            componentName = specialCase.name;
        } else {
            componentName = "Inline List";
        }

        if (tables === undefined) return [componentName, ""];

        const [property] = getListSourceProperty(desc, tables, ctx);
        const name = getDescriptiveNameForTableOrRow(property, tables.input, ctx);

        const descriptiveName = handler.getDescriptiveName(
            desc,
            getStringProperty(desc.caption),
            // We are an inline list masquerading as a picker when the format is "choice",
            // and as such we must return a descriptive name reflecting our picking nature
            // in this case. That is to say, users are more interested in our output than our
            // input when we have a "choice" foramt.
            getEnumProperty(desc.format) === ArrayScreenFormat.Choice ? tables.output : tables.input,
            name
        );
        if (descriptiveName !== undefined) {
            return descriptiveName;
        }

        if (name === undefined) return [componentName, ""];
        return [componentName, name];
    }

    private getColumnsEditedInContent(
        desc: InlineListComponentDescription,
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        withActions: boolean
    ): EditedColumnsAndTables | undefined {
        const contentHandler = getArrayContentHandlerForDescription(desc);
        return contentHandler.getEditedColumns(
            getPropertyTableForContent,
            desc,
            tables,
            ccc,
            mutatingScreenKind,
            true,
            tables,
            false,
            withActions
        );
    }

    public getTablesForActions(
        tables: InputOutputTables,
        desc: InlineListComponentDescription,
        schema: SchemaInspector
    ): [InputOutputTables, boolean] | undefined {
        const table = getPropertyTableForContent(tables, desc, desc, schema);
        if (table === undefined) return undefined;
        return [makeInputOutputTables(table.table), table.inScreenContext];
    }

    public getEditedColumns(
        desc: InlineListComponentDescription,
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        withActions: boolean
    ): EditedColumnsAndTables {
        const fromSuper = super.getEditedColumns(desc, tables, ccc, mutatingScreenKind, withActions);
        // FIXME: This will count things twice
        const fromContent = this.getColumnsEditedInContent(desc, tables, ccc, mutatingScreenKind, withActions);
        return combineEditedColumnsAndTables(fromSuper, fromContent);
    }

    public getAdditionalDataEdits(
        desc: InlineListComponentDescription,
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): AllowedDataEdits | undefined {
        const fromSuper = super.getAdditionalDataEdits(desc, tables, ccc, mutatingScreenKind);

        const editedColumns = this.getColumnsEditedInContent(desc, tables, ccc, mutatingScreenKind, true);
        if (editedColumns === undefined) return fromSuper;

        const dataEdits = convertEditedColumnsToAllowedDataEdits(editedColumns.editedColumns);
        mergeAllowedDataEdits(dataEdits, fromSuper);
        return dataEdits;
    }

    public getAdditionalTablesUsed(
        desc: InlineListComponentDescription,
        schema: SchemaInspector,
        tables: InputOutputTables | undefined
    ): readonly TableName[] {
        const listTypes = getListTypes(schema, desc.propertyName, tables?.input, true);
        if (listTypes === undefined) return [];
        return [getTableRefTableName(listTypes.itemsType)];
    }

    public getScreensUsed(
        desc: InlineListComponentDescription,
        schema: SchemaInspector,
        tables: InputOutputTables | undefined
    ): readonly string[] {
        if (tables === undefined) return [];

        const handler = getArrayContentHandlerForDescription(desc);

        const [property] = getListSourceProperty(desc, tables, schema);
        const table = getInlineListPropertyTable(tables.input, property, schema)?.table;
        if (table === undefined) return [];

        return handler.getScreensUsed(desc, makeInputOutputTables(table), schema, true);
    }

    public rewriteAfterReload(
        desc: InlineListComponentDescription,
        tables: InputOutputTables,
        ccc: RewritingComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isRewrite: boolean
    ): InlineListComponentDescription | undefined {
        const handler = getArrayContentHandlerForDescription(desc);

        const [property] = getListSourceProperty(desc, tables, ccc);
        const table = getInlineListPropertyTable(tables.input, property, ccc)?.table;
        if (table === undefined) {
            logError("Table for Inline List not found");
            return undefined;
        }

        return handler.rewriteContentAfterReload(
            desc,
            makeInputOutputTables(table),
            d => this.getDescriptor(d, tables, ccc, undefined, false, undefined).properties,
            tables,
            ccc,
            mutatingScreenKind,
            isRewrite
        );
    }

    public getEasyTabConfiguration(desc: InlineListComponentDescription): ComponentEasyTabConfiguration | undefined {
        if (getSwitchProperty(desc.forEasyTabConfiguration) !== true) return undefined;
        if (getTableProperty(desc.propertyName) === undefined) return undefined;

        const handler = getArrayContentHandlerForDescription(desc);
        return handler.getEasyTabConfiguration(desc);
    }

    public convertToPage(appList: InlineListComponentDescription): ComponentDescription | undefined {
        const handler = getArrayContentHandlerForDescription(appList);
        const content = handler.convertInlineToPage(appList);

        return content;
    }

    public inflate(
        ib: WireInflationBackend,
        originalDesc: InlineListComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const {
            forBuilder,
            adc: { appKind },
        } = ib;
        // https://github.com/quicktype/glide/issues/15506
        const applyLimitBeforeSearch = appKind !== AppKind.App;

        const [desc] = this.lowerDescriptionForBuilding(
            originalDesc,
            ib.tables,
            ib.adc,
            ib.mutatingScreenKind,
            undefined,
            false
        );
        const { componentID } = desc;

        const handler = getArrayContentHandlerForDescription(desc);

        const [property] = getListSourceProperty(desc, ib.tables, ib.adc);

        let tableGetter: WireTableBackendGetter | undefined;
        let queryGetter: WireQueryGetter | undefined;
        let contentIB: WireInflationBackend;
        let limitTransform: WireTableTransformer | undefined;
        let numLimitRows: number | undefined;
        let numFilters: number | undefined;
        let hasExplicitSort: boolean | undefined;
        let isDataSourceQueryable = false;

        const selectedPageSize = getPageSizeForPaging(
            getNumberProperty(desc.pageSize),
            handler.defaultPageSize,
            handler.hasFixedPaging
        );

        const onlyUseQueries = handler.onlyQueries;
        const maybeQueryGetter = ib.getQueryGetter(property, desc.transforms ?? [], true, onlyUseQueries);
        if (maybeQueryGetter !== undefined) {
            [queryGetter, contentIB, isDataSourceQueryable] = maybeQueryGetter;
        } else {
            // This is where static filter and sort is handled.
            const maybeGetter = ib.getTableGetterWithTransforms(
                property,
                desc.transforms ?? [],
                handler.doesCustomLimit !== true && applyLimitBeforeSearch,
                true
            );
            if (maybeGetter === undefined) return undefined;

            ({
                getter: tableGetter,
                ib: contentIB,
                limitTransform,
                limit: numLimitRows,
                numFilters,
                hasExplicitSort,
            } = maybeGetter);
        }

        const inputTable = contentIB.tables.input;
        const tableName = getTableName(inputTable);

        let captionGetter: WireStringGetter | undefined;
        if (handler.getCaptionFlags(desc) !== undefined) {
            captionGetter = inflateStringProperty(ib, desc.caption, true)[0];
        }

        const searchSupport = handler.supportsSearch;
        const searchableColumns = makeSearchableColumnsForList(
            contentIB,
            searchSupport !== undefined &&
                makeAllowSearchPropertyHandler(searchSupport.enabledByDefault).getSwitch(desc),
            isDataSourceQueryable,
            componentID
        );
        const searchPropertyGetters = makeSearchPropertyGetters(contentIB, searchableColumns);

        const inflatedMultipleDynamicFilters = inflateMultipleDynamicFilters(
            contentIB,
            desc,
            handler.supportsDynamicFilter,
            onlyUseQueries
        );

        // so even if we have inflated multiple filters here, for Kanban we still need to
        // inflate the dynamic filters, because the "tags" property is derived from that rather than row/column data
        // https://github.com/glideapps/glide/issues/27037
        const isUsingMultipleFilters = inflatedMultipleDynamicFilters !== undefined;
        const shouldNotInflateOldDynamicFilter = isUsingMultipleFilters && handler.format !== ArrayScreenFormat.Kanban;
        const inflatedDynamicFilter: InflatedDynamicFilter | undefined = inflateDynamicFilter(
            contentIB,
            desc,
            shouldNotInflateOldDynamicFilter ? undefined : handler.supportsDynamicFilter
        );

        const contentHydrator = handler.inflateContent(
            contentIB,
            desc,
            captionGetter,
            ib,
            desc.componentID,
            inflatedDynamicFilter?.filterColumn.name
        );
        if (contentHydrator === undefined) return undefined;

        class HydratorForTable implements WireRowComponentHydrator {
            private readonly rowBackends: RowBackends = new DefaultMap(r =>
                defined(this.contentHB).makeHydrationBackendForRow(r)
            );
            private searchBar: WireEditableValue<string> | undefined;
            private searchNeedle: string | undefined;
            private contentHB: WireTableComponentHydrationBackend | undefined;
            private rows: readonly Row[] | undefined;
            private hydrator: WireTableComponentHydrator | undefined;
            private multipleFiltersState: MultipleDynamicFilterState | undefined;

            public static wantsSearch = searchPropertyGetters !== undefined;

            constructor(
                private readonly hb: WireRowComponentHydrationBackend,
                private readonly builder: BuilderCallbacks | undefined
            ) {}

            public preHydrate(): WireComponentPreHydrationResult {
                const { hb, builder } = this;

                // We do this here early so that if for some reason we don't
                // hydrate this component, the builder doesn't fall back to
                // showing the screen's data.  This way the peek-a-boo will
                // show an empty table.
                // https://github.com/quicktype/glide/issues/15394
                builder?.overrideTableData(componentID, new Table(), inputTable);

                if (searchPropertyGetters !== undefined) {
                    if (appKind === AppKind.App) {
                        // In Apps, there's one search bar per screen.
                        const [editableValue] = getScreenSearchState(hb);
                        this.searchBar = editableValue;
                    } else if (appKind === AppKind.Page) {
                        // In Pages, each Inline List has its own search.
                        this.searchBar = hb.getState("search", isString, "", true);
                    } else {
                        return assertNever(appKind);
                    }
                }

                const maybeContentHB = defined(tableGetter)(hb);
                if (maybeContentHB === undefined || isLoadingValue(maybeContentHB)) return [false, undefined];
                this.contentHB = maybeContentHB;

                this.rows = this.contentHB.tableScreenContext.asArray();

                if (this.rows.length === 0) {
                    if (!handler.showIfEmpty) {
                        return [false, undefined];
                    }
                }

                this.searchNeedle = this.searchBar?.value.toLowerCase().trim();

                assert(contentHydrator !== undefined);
                this.multipleFiltersState = getMultipleDynamicFilterState(hb);
                const appliedSomeFilter = Object.values(this.multipleFiltersState.filterEditable.value).some(v => {
                    return isDefined(v) && v.length > 0;
                });

                const searchOrFilterActive = appliedSomeFilter || !isEmptyOrUndefined(this.searchNeedle);

                this.hydrator = contentHydrator.makeHydrator(hb, this.rowBackends, searchOrFilterActive, this.builder);

                if (this.hydrator.preHydrate !== undefined) {
                    return this.hydrator.preHydrate(this.contentHB);
                } else {
                    return [true, undefined];
                }
            }

            public hydrate(): WireComponentHydrationResult | undefined {
                const { hb, hydrator } = this;
                assert(this.contentHB !== undefined);
                let { rows, contentHB } = this;

                assert(hydrator !== undefined && rows !== undefined);

                // If we exit early and don't get the state before we do, it'll be
                // gone on the next render.
                const pageIndexState = getItemsPageIndex(contentHB, false, "");

                // The order of operations is:
                //
                // 1. We let the hydrator prefilter.
                // 2. On the remaining rows after prefiltering we apply the
                //    dynamic filter.
                // 3. On the remaining rows after dynamic filtering we apply
                //    search.
                // 4. Then, in apps, we apply the limit, which is stupid, and
                //    a historic relic:
                //    https://github.com/quicktype/glide/issues/15506
                // 5. Then we do paging.
                //
                // This order is important.  Prefiltering has to see all the
                // rows, so it needs to run first.  The categories and counts
                // we display in the dynamic filter menu should not be
                // affected by the search, so dynamic filter has to run before
                // search.  Paging has to operate on the final list of rows,
                // so it has to run last.

                // Metrics that let us know about query shapes
                const numInitialRows = contentHB.numRowsBeforeLimit;
                const startTime =
                    !forBuilder && numInitialRows >= 1000 ? getCurrentTimestampInMilliseconds() : undefined;
                let numActiveFilters = numFilters ?? 0;
                let isSearched = false;

                // Let the hydrator prefilter.
                if (hydrator.prefilterRows !== undefined) {
                    // We must call `prefilterRows` with all rows, before any
                    // search/filter is applied.  Kanban uses it to figure out
                    // indexes.
                    const maybeContentHB = hydrator.prefilterRows(contentHB);
                    if (maybeContentHB === undefined) return undefined;
                    contentHB = maybeContentHB;
                    rows = contentHB.tableScreenContext.asArray();
                }

                this.builder?.overrideTableData(componentID, new Table(rows), inputTable);

                const ttvp = contentHB.makeTableTransformValueProvider(tableName);

                // Apply the dynamic filter
                let dynamicFilterResult: DynamicFilterResult | undefined;
                if (inflatedDynamicFilter !== undefined) {
                    const isApp = appKind === AppKind.App;
                    dynamicFilterResult = applyDynamicFilter(
                        hb,
                        ttvp,
                        inflatedDynamicFilter,
                        new Table(rows),
                        // Use screen state for app, componenent state for
                        // pages (which can have more than one dynamic filter
                        // per screen)
                        isApp ? getDynamicFilterState(hb, false) : getDynamicFilterState(hb, true),
                        appKind,
                        handler.needsDynamicFilterValues,
                        undefined
                    );
                    rows = dynamicFilterResult.table.asArray();
                    if (dynamicFilterResult.isActive) {
                        numActiveFilters += 1;
                    }
                }

                let multipleDynamicFilterResult: MultipleDynamicFiltersResult | undefined;
                if (inflatedMultipleDynamicFilters !== undefined && appKind !== AppKind.App) {
                    multipleDynamicFilterResult = applyMultipleDynamicFilters(
                        hb,
                        ttvp,
                        inflatedMultipleDynamicFilters,
                        new Table(rows),
                        defined(this.multipleFiltersState),
                        handler.needsDynamicFilterValues,
                        undefined
                    );

                    rows = multipleDynamicFilterResult.table.asArray();
                }

                // Apply search
                const canBeSearched = this.searchBar !== undefined && rows.length > 0;
                if (canBeSearched && !isEmptyOrUndefined(this.searchNeedle)) {
                    rows = searchRows(
                        this.searchNeedle,
                        new Table(rows),
                        defined(searchPropertyGetters),
                        ttvp,
                        searchFilterTimeout
                    ).asArray();
                    isSearched = true;
                }

                if (startTime !== undefined) {
                    const duration = getCurrentTimestampInMilliseconds() - startTime;
                    // Don't retry - this is not critical
                    frontendSendEvent(
                        "inlineListQueryShape",
                        duration,
                        {
                            app_kind: appKind,
                            format: handler.format,
                            initial_rows: numInitialRows,
                            final_rows: rows.length,
                            searched: isSearched,
                            num_filters: numActiveFilters,
                            explicit_sort: hasExplicitSort,
                            row_limit: numLimitRows,
                        },
                        false
                    );
                }

                // Apply limit in Apps
                if (handler.doesCustomLimit !== true && !applyLimitBeforeSearch) {
                    rows = defined(limitTransform)(ttvp, new Table(rows)).asArray();
                }

                // Apply paging
                let paging: WirePaging | undefined;
                if (!handler.doesCustomPaging) {
                    const [thePaging, pagedRows] = applyPaging(rows, pageIndexState, selectedPageSize);
                    paging = thePaging;
                    rows = pagedRows;
                }

                contentHB = contentHB.withTable(new Table(rows));

                const hydratedContent = hydrator.hydrate(contentHB, dynamicFilterResult, numLimitRows);
                if (hydratedContent === undefined) return undefined;

                function makeMultipleFilterSubsidiary(): WireSubsidiaryScreen | undefined {
                    if (hb.getFormFactor() !== DeviceFormFactor.Phone) {
                        return undefined;
                    }

                    const dynamicFilters = multipleDynamicFilterResult?.multipleDynamicFilters;

                    if (dynamicFilters === undefined) {
                        return undefined;
                    }

                    const { value, onChangeToken } = dynamicFilters.isOpen;

                    if (value !== true || onChangeToken === undefined) {
                        return undefined;
                    }

                    const clearAllButton: WireButtonComponent = {
                        kind: WireComponentKind.Button,
                        title: getLocalizedString("clearAll", AppKind.Page),
                        action: dynamicFilters.clearAction,
                        appearance: UIButtonAppearance.Bordered,
                    };

                    const closeActionToken = hb.registerAction("close-multiple-filters", async ab => {
                        ab.valueChanged(onChangeToken, false, ValueChangeSource.User);
                        return WireActionResult.nondescriptSuccess();
                    });

                    const doneButton: WireButtonComponent = {
                        kind: WireComponentKind.Button,
                        title: getLocalizedString("done", AppKind.Page),
                        action: { token: closeActionToken },
                        appearance: UIButtonAppearance.Filled,
                    };

                    const multipleFiltersComponent: WireMultipleFilterComponent = {
                        kind: WireComponentKind.MultipleFilters,
                        multipleFilters: dynamicFilters,
                    };

                    return {
                        key: encodeScreenKey(`multiple-filters-${componentID}`),
                        size: WireModalSize.SlideIn,
                        title: getLocalizedString("filter", AppKind.Page),
                        flags: [],
                        specialComponents: [doneButton, clearAllButton],
                        components: [multipleFiltersComponent],
                        isInModal: true,
                        tabIcon: "",
                        closeAction: { token: closeActionToken },
                    };
                }

                return {
                    component: {
                        // We put `paging` before `hydratedContent.component`
                        // so that the component can override it.
                        paging,
                        ...hydratedContent.component,
                        searchBar: this.searchBar,
                        dynamicFilter: dynamicFilterResult?.dynamicFilter,
                        multipleDynamicFilters: multipleDynamicFilterResult?.multipleDynamicFilters,
                    } as WireComponent,
                    isValid: hydratedContent.isValid,
                    editsInContext: hydratedContent.editsInContext,
                    hasValue: hydratedContent.hasValue,
                    followUp: hydratedContent.followUp,
                    canBeSearched: appKind === AppKind.App && canBeSearched,
                    subsidiaryScreen: hydratedContent.subsidiaryScreen ?? makeMultipleFilterSubsidiary(),
                    editor: hydratedContent.editor,
                };
            }
        }

        class HydratorForQuery implements WireRowComponentHydrator {
            private contentHB: WireTableComponentHydrationBackend | undefined;
            private readonly rowBackends: RowBackends = new DefaultMap(r =>
                defined(this.contentHB).makeHydrationBackendForRow(r)
            );
            private hydrator: WireTableComponentHydrator | undefined;
            private hydratorForQuery: WireTableComponentQueryHydrator | undefined;
            private filterState: DynamicFilterState | undefined;
            private multipleFiltersState: MultipleDynamicFilterState | undefined;
            private multipleFilterEntriesByColumnName:
                | Record<string, QueryableMultipleFilterEntriesWithCaption>
                | undefined;
            private table: Table | QueryBase | LoadingValue | undefined;
            private pageIndexState: WireAlwaysEditableValue<number> | undefined;
            private searchBar: WireEditableValue<string> | undefined;
            private filterEntries: readonly FilterEntry[] | LoadingValue | undefined;

            public static wantsSearch = searchableColumns.size > 0;

            constructor(
                private readonly hb: WireRowComponentHydrationBackend,
                private readonly builder: BuilderCallbacks | undefined
            ) {}

            public preHydrate(): WireComponentPreHydrationResult {
                const { hb, builder } = this;

                // FIXME: It's ugly that we have to create the content HB here
                // instead of down where we already have the table.  We need
                // it, vs `this.hb` because the content hydrator uses
                // `contentHB` to get the page index state, and if we used
                // `this.hb` it wouldn't be the same state.
                this.contentHB = hb.makeHydrationBackendForTable(inputTable, new Table());

                // If we exit early and don't get the page index state before
                // we do, it'll be gone on the next render.
                this.pageIndexState = getItemsPageIndex(this.contentHB, false, "");

                // See comment about this in `HydratorForTable`
                builder?.overrideTableData(componentID, new Table(), inputTable);

                let query = defined(queryGetter)(this.hb);
                if (query === undefined) return [false, undefined];
                if (isLoadingValue(query)) {
                    this.table = query;
                    return [true, undefined];
                }

                if (inflatedDynamicFilter !== undefined) {
                    const queryResult = queryDynamicFilter(this.hb, inflatedDynamicFilter, query);
                    this.filterState = queryResult.filterState;
                    this.filterEntries = queryResult.filterEntries;
                    if (queryResult.query !== undefined) {
                        query = queryResult.query;
                    }
                }

                /**
                 * We didn't even inflate the regular dynamic filter if multiple filters are configured
                 * so we're good, we're never querying both.
                 */
                let appliedSomeFilter = false;
                if (inflatedMultipleDynamicFilters !== undefined) {
                    const queryResult = queryMultipleDynamicFilters(this.hb, inflatedMultipleDynamicFilters, query);

                    this.multipleFiltersState = queryResult.multipleFiltersState;
                    this.multipleFilterEntriesByColumnName = queryResult.multipleFilterEntriesByColumnName;

                    appliedSomeFilter = Object.values(this.multipleFiltersState.filterEditable.value).some(v => {
                        return isDefined(v) && v.length > 0;
                    });

                    if (queryResult.query !== undefined) {
                        query = queryResult.query;
                    }
                }

                // FIXME: `query` can be a `Table`, either an
                // ##emptyTableFromGetter or a singleton table if the source
                // of the Inline List is the context (or containing screen?)
                // row.  In those cases we still have to apply search and the
                // dynamic filter below, but we don't!  We could do that
                // either by
                //
                // * making the getter always return a query, but allowing
                //   queries to be pre-resolved, and then make the methods on
                //   that query do the search/filter stuff
                // * use the search/filter code we use for "regular" Inline
                //   List above.

                const debounceQueryTableSearchMs = isBigTableOrExternal(contentIB.tables.input) ? 500 : undefined;
                const searchResult = applySearchToQuery(this.hb, searchableColumns, query, debounceQueryTableSearchMs);
                const searchOrFilterActive = searchResult.searchActive || appliedSomeFilter;

                query = searchResult.query ?? query;
                this.searchBar = searchResult.searchEditable;

                if (isQuery(query) && contentHydrator?.makeHydratorForQuery !== undefined) {
                    this.table = query;

                    this.hydratorForQuery = contentHydrator.makeHydratorForQuery(
                        this.hb,
                        query,
                        this.contentHB,
                        searchOrFilterActive,
                        defined(this.pageIndexState),
                        selectedPageSize,
                        this.rowBackends
                    );

                    if (this.hydratorForQuery.preHydrate !== undefined) {
                        return this.hydratorForQuery.preHydrate(this.contentHB);
                    }

                    if (isDefined(this.builder)) {
                        query = query.withLimit(
                            getQueryLimitForPaging(handler.queryableTableSupport, selectedPageSize, this.pageIndexState)
                        );

                        const table = this.hb.resolveQueryAsTable(query);
                        if (table === undefined) return [false, undefined];

                        this.builder.overrideTableData(
                            componentID,
                            isLoadingValue(table) ? new Table() : table,
                            inputTable
                        );
                    }

                    return [true, undefined];
                }

                assert(contentHydrator !== undefined);
                this.hydrator = contentHydrator.makeHydrator(
                    this.hb,
                    this.rowBackends,
                    searchOrFilterActive,
                    this.builder
                );

                if (isQuery(query)) {
                    query = query.withLimit(
                        getQueryLimitForPaging(handler.queryableTableSupport, selectedPageSize, this.pageIndexState)
                    );

                    if (this.hydrator.modifyQuery !== undefined) {
                        query = this.hydrator.modifyQuery(query);
                        if (query === undefined) {
                            return [false, undefined];
                        }
                    }

                    this.table = this.hb.resolveQueryAsTable(query);
                } else {
                    this.table = query;
                }

                if (this.table === undefined) return [false, undefined];
                if (isLoadingValue(this.table)) {
                    // FIXME: Handle the loading state better - new queries
                    // are always in a loading state to start with, so every
                    // search goes back to loading, for example.
                    this.table = new Table();
                }

                this.contentHB = this.contentHB.withTable(this.table);

                this.builder?.overrideTableData(componentID, this.table, inputTable);

                if (this.hydrator.preHydrate !== undefined) {
                    return this.hydrator.preHydrate(this.contentHB);
                } else {
                    return [true, undefined];
                }
            }

            public hydrate(): WireComponentHydrationResult | undefined {
                let table = defined(this.table);
                if (isLoadingValue(table)) {
                    return activitySpinnerComponentHydrationResult;
                }

                const applyMultipleFilters = (rows: Table | undefined) => {
                    if (inflatedMultipleDynamicFilters === undefined) {
                        return undefined;
                    }

                    const ttvp = this.hb.makeTableTransformValueProvider(tableName);

                    return applyMultipleDynamicFilters(
                        this.hb,
                        ttvp,
                        inflatedMultipleDynamicFilters,
                        rows,
                        defined(this.multipleFiltersState),
                        handler.needsDynamicFilterValues,
                        this.multipleFilterEntriesByColumnName
                    );
                };

                const applyFilter = (rows: Table | undefined) => {
                    if (inflatedDynamicFilter === undefined) return undefined;

                    const ttvp = this.hb.makeTableTransformValueProvider(tableName);
                    return applyDynamicFilter(
                        this.hb,
                        ttvp,
                        inflatedDynamicFilter,
                        rows,
                        defined(this.filterState),
                        appKind,
                        handler.needsDynamicFilterValues,
                        this.filterEntries
                    );
                };

                const makeMultipleFilterSubsidiary = (): WireSubsidiaryScreen | undefined => {
                    if (this.hb.getFormFactor() !== DeviceFormFactor.Phone) {
                        return undefined;
                    }

                    const dynamicFilters = multipleDynamicFilterResult?.multipleDynamicFilters;

                    if (dynamicFilters === undefined) {
                        return undefined;
                    }

                    const { value, onChangeToken } = dynamicFilters.isOpen;

                    if (value !== true || onChangeToken === undefined) {
                        return undefined;
                    }

                    const { isLoading } = dynamicFilters;

                    const clearAllButton: WireButtonComponent = {
                        kind: WireComponentKind.Button,
                        title: getLocalizedString("clearAll", AppKind.Page),
                        action: isLoading === true ? { token: null } : dynamicFilters.clearAction,
                        appearance: UIButtonAppearance.Bordered,
                    };

                    const closeActionToken = this.hb.registerAction("close-multiple-filters", async ab => {
                        ab.valueChanged(onChangeToken, false, ValueChangeSource.User);
                        return WireActionResult.nondescriptSuccess();
                    });

                    const doneButton: WireButtonComponent = {
                        kind: WireComponentKind.Button,
                        title: getLocalizedString("done", AppKind.Page),
                        action: { token: closeActionToken },
                        appearance: UIButtonAppearance.Filled,
                    };

                    const multipleFiltersComponent: WireMultipleFilterComponent = {
                        kind: WireComponentKind.MultipleFilters,
                        multipleFilters: dynamicFilters,
                    };

                    const activitySpinnerComponent: WireActivitySpinnerComponent = {
                        kind: WireComponentKind.ActivitySpinner,
                        isInline: true,
                    };

                    return {
                        key: encodeScreenKey(`multiple-filters-${componentID}`),
                        size: WireModalSize.SlideIn,
                        title: getLocalizedString("filter", AppKind.Page),
                        flags: [],
                        specialComponents: [doneButton, clearAllButton],
                        components: isLoading === true ? [activitySpinnerComponent] : [multipleFiltersComponent],
                        isInModal: true,
                        tabIcon: "",
                        closeAction: { token: closeActionToken },
                    };
                };

                let dynamicFilterResult: ReturnType<typeof applyDynamicFilter> | undefined;
                let multipleDynamicFilterResult: MultipleDynamicFiltersResult | undefined;

                if (isQuery(table)) {
                    dynamicFilterResult = applyFilter(undefined);
                    multipleDynamicFilterResult = applyMultipleFilters(undefined);

                    const hydratedContent = this.hydratorForQuery?.hydrateForQuery();
                    if (hydratedContent === undefined) return undefined;

                    const paging: WirePaging = {
                        pageIndex: { value: 0, onChangeToken: undefined },
                        numPages: 1,
                        pageSize: 1,
                        itemsCount: 1,
                    };

                    return {
                        component: {
                            paging,
                            ...hydratedContent.component,
                            searchBar: this.searchBar,
                            dynamicFilter: dynamicFilterResult?.dynamicFilter,
                            multipleDynamicFilters: multipleDynamicFilterResult?.multipleDynamicFilters,
                        } as WireComponent,
                        isValid: hydratedContent.isValid,
                        editsInContext: hydratedContent.editsInContext,
                        hasValue: hydratedContent.hasValue,
                        followUp: hydratedContent.followUp,
                        subsidiaryScreen: hydratedContent.subsidiaryScreen ?? makeMultipleFilterSubsidiary(),
                        editor: hydratedContent.editor,
                    };
                }

                const hydrator = defined(this.hydrator);
                let contentHB = defined(this.contentHB).withTable(table);

                // Let the hydrator prefilter.
                if (hydrator.prefilterRows !== undefined) {
                    // We must call `prefilterRows` with all rows, before any
                    // search/filter is applied.  Kanban uses it to figure out
                    // indexes.
                    const maybeContentHB = hydrator.prefilterRows(contentHB);
                    if (maybeContentHB === undefined) return undefined;
                    contentHB = maybeContentHB;

                    this.builder?.overrideTableData(componentID, contentHB.tableScreenContext, inputTable);
                }

                // Apply the dynamic filter
                if (inflatedDynamicFilter !== undefined) {
                    dynamicFilterResult = defined(applyFilter(table));
                    table = dynamicFilterResult.table;
                }

                if (inflatedMultipleDynamicFilters !== undefined) {
                    multipleDynamicFilterResult = defined(applyMultipleFilters(table));
                    table = multipleDynamicFilterResult.table;
                }

                // FIXME: This only works for Pages.  Also obviously don't
                // just make a hint if there are rows present.
                if (table.errorMessage !== undefined) {
                    const action = registerActionRunner(this.hb, "retry-query", async ab => {
                        ab.resetQuery(tableName);
                        return WireActionResult.nondescriptSuccess();
                    });
                    const hintComponent: WireHintComponent = {
                        kind: WireComponentKind.Hint,
                        mood: Mood.Warning,
                        description: table.errorMessage,
                        actionTitle: getLocalizedString("retry", appKind),
                        action,
                    };
                    return {
                        component: hintComponent,
                        isValid: true,
                    };
                }

                // Apply paging
                let paging: WirePaging | undefined;
                if (!handler.doesCustomPaging) {
                    const rows = table.asArray();
                    const [thePaging, pagedRows] = applyPaging(rows, defined(this.pageIndexState), selectedPageSize);
                    paging = thePaging;
                    table = new Table(pagedRows);
                }

                contentHB = contentHB.withTable(table);

                const hydratedContent = defined(this.hydrator).hydrate(contentHB, dynamicFilterResult, numLimitRows);
                if (hydratedContent === undefined) return undefined;

                return {
                    component: {
                        // We put `paging` before `hydratedContent.component`
                        // so that the component can override it.
                        paging,
                        ...hydratedContent.component,
                        searchBar: this.searchBar,
                        dynamicFilter: dynamicFilterResult?.dynamicFilter,
                        multipleDynamicFilters: multipleDynamicFilterResult?.multipleDynamicFilters,
                    } as WireComponent,
                    isValid: hydratedContent.isValid,
                    editsInContext: hydratedContent.editsInContext,
                    hasValue: hydratedContent.hasValue,
                    followUp: hydratedContent.followUp,
                    subsidiaryScreen: hydratedContent.subsidiaryScreen ?? makeMultipleFilterSubsidiary(),
                    editor: hydratedContent.editor,
                };
            }
        }

        return queryGetter !== undefined ? HydratorForQuery : HydratorForTable;
    }
}
