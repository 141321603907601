import { curl } from "./curl";
import { javascript } from "./javascript";
import { python } from "./python";

export const languages = {
    javascript,
    python,
    curl,
};

export type Language = keyof typeof languages;
