import { RecurrentBackgroundJob } from "./recurrent-background-job";

export class Periodically {
    readonly handler: () => void;
    timer: ReturnType<typeof setInterval> | undefined;

    static run(intervalMS: number, op: () => Promise<void>): () => ReturnType<Periodically["stop"]> {
        const p = new Periodically(intervalMS, op).start();
        return () => p.stop();
    }

    constructor(readonly intervalMS: number, op: () => Promise<void>) {
        // `RecurrentBackgroundJob` used here to enforce a single concurrent execution of `op`.
        const job = new RecurrentBackgroundJob(op);
        this.handler = () => job.request();
    }

    start(set = setInterval): Periodically {
        if (this.timer !== undefined) return this;

        this.timer = set(this.handler, this.intervalMS);
        return this;
    }

    stop(clear = clearInterval): void {
        if (this.timer === undefined) return;

        clear(this.timer);
        this.timer = undefined;
    }
}
