import type { NotAllowedToSignInReason } from "@glide/common-core";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";

export function getNotAllowedToSignInMessage(reason: NotAllowedToSignInReason | undefined): string {
    if (reason === "non-personal-email-not-allowed") {
        return "Please use your personal email address to access this app.";
    }

    return getLocalizedString("emailAddressDoesntHaveAccess", AppKind.Page);
}
