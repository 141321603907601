import {
    ContainerAlignment,
    ContainerLayout,
    ContainerPadding,
    ContainerWidth,
} from "@glide/common-core/dist/js/description";
import type { ComponentDescription } from "@glide/app-description";
import { getEnumProperty } from "@glide/app-description";
import { PropertySection } from "@glide/function-utils";
import { WireComponentKind, HeroImageEffect, UIBackgroundStyle, type WireComponent } from "@glide/wire";

import type { WireButtonComponent } from "./fluent-components";
import type { DescriptionOfComponent } from "./fluent-components-spec";
import {
    type WirePropertiesOfComponent,
    ContainerKind,
    FluentComponent,
    containerPropertiesWithCSS,
    emptyPropertiesSpec,
    propertiesWithCSS,
} from "./fluent-components-spec";

function container<TKind extends WireComponentKind>(kind: TKind) {
    return new FluentComponent<
        unknown,
        unknown,
        { readonly kind: TKind; readonly components: readonly (WireComponent | null)[] },
        {}
    >({
        kind,
        containerKind: ContainerKind.Regular,
        properties: emptyPropertiesSpec,
        formProperties: emptyPropertiesSpec,
        includeRegularComponent: true,
        internalState: {},
    });
}

function formContainer<TKind extends WireComponentKind>(kind: TKind) {
    return new FluentComponent<
        unknown,
        unknown,
        {
            readonly kind: TKind;
            readonly components: readonly (WireComponent | null)[];
            readonly specialComponents: readonly (WireButtonComponent | null)[];
        },
        {}
    >({
        kind,
        containerKind: ContainerKind.Form,
        properties: emptyPropertiesSpec,
        formProperties: emptyPropertiesSpec,
        includeRegularComponent: true,
        internalState: {},
    });
}

export const Container = container(WireComponentKind.Container)
    .withDisplayName("Container")
    .withIcon("mt-component-container")
    .withDescription("Group multiple components together and edit the background")
    .withProperties(
        containerPropertiesWithCSS
            .withEnum(
                "containerStyle",
                [
                    { value: UIBackgroundStyle.None, label: "None" },
                    { value: UIBackgroundStyle.Card, label: "Card" },
                    { value: UIBackgroundStyle.Accent, label: "Accent" },
                    { value: UIBackgroundStyle.Dark, label: "Dark" },
                    { value: UIBackgroundStyle.Highlight, label: "Highlight" },
                    { value: UIBackgroundStyle.Image, label: "Image" },
                ],
                {
                    displayName: "Background",
                    propertySection: PropertySection.Design,
                }
            )
            .withEnum(
                "padding",
                [
                    { value: ContainerPadding.Medium, label: "Normal", icon: "mt-padding-sm" },
                    { value: ContainerPadding.Large, label: "Large", icon: "mt-padding-md" },
                    { value: ContainerPadding.XLarge, label: "XLarge", icon: "mt-padding-lg" },
                ],
                {
                    propertySection: PropertySection.Design,
                    enumVisual: "small-images",
                }
            )
            .withEnum(
                "width",
                [
                    { value: ContainerWidth.Normal, label: "Large", icon: "mt-container-width-md" },
                    { value: ContainerWidth.Small, label: "Medium", icon: "mt-container-width-sm" },
                    { value: ContainerWidth.XSmall, label: "Small", icon: "mt-container-width-xs" },
                ],
                {
                    propertySection: PropertySection.Design,
                    enumVisual: "small-images",
                }
            )
            .withEnum(
                "alignment",
                [
                    { value: ContainerAlignment.center, label: "center", icon: "mt-container-align-center" },
                    { value: ContainerAlignment.top, label: "top", icon: "mt-container-align-top" },
                ],
                {
                    propertySection: PropertySection.Design,
                    enumVisual: "small-images",
                    when: d => {
                        const layout = getEnumProperty(d.layout);
                        return layout !== undefined && layout !== ContainerLayout.Full;
                    },
                }
            )

            .withImage("backgroundImage", {
                propertySection: PropertySection.Design,
                when: d => getEnumProperty(d.containerStyle) === UIBackgroundStyle.Image,
                subcomponent: {
                    name: "Background",
                    important: true,
                },
            })
            .withEnum("backgroundEffect", [HeroImageEffect.None, HeroImageEffect.Darken, HeroImageEffect.Lighten], {
                displayName: "Background Effect",
                propertySection: PropertySection.Design,
                when: d => getEnumProperty(d.containerStyle) === UIBackgroundStyle.Image,
                subcomponent: {
                    name: "background",
                    important: true,
                },
            })
            .withSwitch("withBlur", false, {
                propertySection: PropertySection.Design,
                when: d => getEnumProperty(d.containerStyle) === UIBackgroundStyle.Image,
                subcomponent: {
                    name: "Background",
                    important: true,
                },
            })
    );
export type WireContainerComponent = WirePropertiesOfComponent<typeof Container>;
export type WireContainerComponentDescription = DescriptionOfComponent<typeof Container> & {
    components: readonly ComponentDescription[];
};

export const FormContainer = formContainer(WireComponentKind.FormContainer)
    .withDisplayName("Form Container")
    .withDescription("Attach a group of form components to any source in your project.")
    .withIcon("mt-component-container-form")
    .withProperties(
        propertiesWithCSS.withEnum(
            "containerStyle",
            [
                { value: UIBackgroundStyle.None, label: "None" },
                { value: UIBackgroundStyle.Card, label: "Card" },
                { value: UIBackgroundStyle.Accent, label: "Accent" },
                { value: UIBackgroundStyle.Dark, label: "Dark" },
                { value: UIBackgroundStyle.Highlight, label: "Highlight" },
            ],
            {
                displayName: "Background",
                propertySection: PropertySection.Design,
            }
        )
    );
export type WireFormContainerComponent = WirePropertiesOfComponent<typeof FormContainer>;
