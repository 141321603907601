import React, { useEffect, useRef } from "react";
import { panic } from "@glideapps/ts-necessities";

import { type LocalizedStringKey, getLocalizedString } from "@glide/localization";
import { AuthenticationMethod } from "@glide/common-core/dist/js/Database";
import {
    MaxPinLifeMins,
    getAllowLoginSaveKey,
    getEmailKey,
    getPasswordKey,
    getPinKey,
    getUsernameKey,
    isEmptyOrUndefined,
    isEmptyOrUndefinedish,
    isValidEmailAddress,
    localStorageGetItem,
    localStorageRemoveItem,
    localStorageSetItem,
    logError,
    Watchable,
} from "@glide/support";
import {
    decodeOAuth2AuthErrorMessage,
    decodeOAuth2AuthTokenMessage,
    extractPrivateInviteLinkTokenFromCurrentURL,
} from "@glide/common-core/dist/js/authorization/auth";
import { type AppFeatures, AppAuthenticationKind } from "@glide/app-description";
import type { AppKind } from "@glide/location-common";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import type { SignInState } from "@glide/common-components";

import {
    getLocalAppLoginToken,
    getLocalEmailLoginCredentials,
    useCredentials,
} from "../utils/app-container/app-container-lib";
import { handleGoogleAuthCodeFromNative, nativeAuthControllerCallbacks } from "../google/google-auth";

// TODO: Find a better place to establish this hook.
(window as any).glideNativeGoogleSignInHook = (authCode: string) => {
    void handleGoogleAuthCodeFromNative(authCode);
};

const samePageAuthToken = new Watchable<string | undefined>(undefined);
export function setSamePageAuthToken(token: string) {
    // TODO need to refactor glide-app-player in play2
    samePageAuthToken.current = token;
}

export interface AuthControllerProps {
    readonly appID: string;
    readonly authKind: AppAuthenticationKind;
    readonly previewMode: boolean;

    // if these are set, then show username first
    readonly getUsername?: boolean;
    readonly username?: string;

    // only call this when logging in with email
    readonly onLoggedIn: ((email: string) => Promise<void>) | undefined;

    // passcode auth method
    readonly submitPasscode?: (passcode: string, loginToken: AppLoginTokenContainer | undefined) => Promise<boolean>;

    // allows user to simply exit
    readonly onClose?: () => void;

    // This is used when we just try to log in, but start the app
    // if it doesn't work.  Used in public apps that can still sign
    // in.
    readonly onFailed?: () => void;

    // email auth controller doohicky

    // used to figure out if we can show gmail auth
    readonly appFeatures: AppFeatures;
    readonly authMethod?: AuthenticationMethod;

    readonly pagesSource?: "main-sign-in" | "modal-sign-in" | "modal-sign-up";
}

interface AuthControllerReturn {
    readonly allowSaveLogin: boolean;
    readonly appFeatures: AppFeatures;
    readonly appKind: AppKind;
    readonly authMethod?: AuthenticationMethod;
    readonly canContinue: boolean;
    readonly canPressPin: boolean;
    readonly canRequestAccess: boolean;
    readonly currentState: SignInState;
    readonly error: string | undefined;
    readonly isSending: boolean;
    readonly onClose?: () => void;
    readonly onEmailChange: (newEmail: string) => void;
    readonly onEmailPressedContinue: () => Promise<void>;
    readonly onPinChange: (newPin: string) => void;
    readonly onPressedBack: () => void;
    readonly onPressedPin: () => void;
    readonly onRequestAccessPressedContinue: () => Promise<void>;
    readonly onUserAgreedChange: (newUserAgreed: boolean) => void;
    readonly onUsernamePressedContinue: () => Promise<void>;
    readonly onUserUpdateSaveLogin: (newAllowSaveLogin: boolean) => void;
    readonly pagesSource?: "main-sign-in" | "modal-sign-in" | "modal-sign-up";
    readonly pin: string;
    readonly userAgreed: boolean;
    readonly userEmail: string;
}

export const useAuthController = (p: AuthControllerProps): AuthControllerReturn => {
    const {
        appID,
        onLoggedIn,
        authKind,
        authMethod,
        appFeatures,
        previewMode,
        onClose,
        pagesSource,
        onFailed,
        submitPasscode,
        getUsername,
    } = p;

    const [currentState, setState] = React.useState<SignInState>("credentials");
    const [email, setEmail] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [pin, setPin] = React.useState("");
    const [userAgreed, setUserAgreed] = React.useState(false);
    const [allowSaveLogin, setAllowSaveLogin] = React.useState(false);
    const [canContinue, setCanContinue] = React.useState(false);
    const [canPressPin, setCanPressPin] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [isSending, setIsSending] = React.useState(false);

    const [privateInviteLinkToken, setPrivateInviteLinkToken] = React.useState<string | undefined>();

    const expectedAuthMessageSource = useRef<Window | null>(null);

    const credentials = useCredentials();

    // Memos

    const appKind = React.useMemo(() => getAppKindFromFeatures(appFeatures), [appFeatures]);

    const canRequestAccess = React.useMemo(() => {
        if (authMethod === undefined) return false;
        return (
            appFeatures.canAppUserRequestAccess === true &&
            authKind === AppAuthenticationKind.EmailPin &&
            authMethod === AuthenticationMethod.UserProfileEmailPin
        );
    }, [appFeatures, authKind, authMethod]);

    // Callbacks

    const onPinChange = React.useCallback(
        (newPin: string) => {
            setPin(newPin);
            setCanContinue(pin.length > 3);
            setCanPressPin(true);
        },
        [pin.length]
    );

    const onEmailChange = React.useCallback((emailInner: string) => {
        const isValid = isValidEmailAddress(emailInner);
        setEmail(emailInner);
        setCanContinue(isValid);
        setCanPressPin(isValid);
    }, []);

    const onUsernameChange = React.useCallback((newVal: string) => {
        setUsername(newVal);
        setCanContinue(newVal.length > 0);
    }, []);

    const setCurrentState = React.useCallback(
        (newState: SignInState) => {
            setState(newState);
            setError(undefined);
            if (newState === "credentials") {
                onEmailChange(email);
            } else if (newState === "challenge") {
                onPinChange(pin);
            } else if (newState === "username") {
                onUsernameChange(username);
            }
        },
        [email, onEmailChange, onPinChange, onUsernameChange, pin, username]
    );

    const onEnterOAuth2Flow = React.useCallback(() => {
        setCurrentState("sign-in");
        setEmail("");
    }, [setCurrentState]);

    const checkLocalStoragePin = React.useCallback(
        async (password: string | undefined, emailInner: string, loginToken: AppLoginTokenContainer | undefined) => {
            setCurrentState("sign-in");
            const authed = await credentials.authenticate(emailInner, password, loginToken);
            if (authed && onLoggedIn !== undefined) {
                await onLoggedIn(emailInner);
            } else {
                onFailed?.();

                setCurrentState("credentials");
            }
        },
        [credentials, onFailed, onLoggedIn, setCurrentState]
    );

    const checkLocalStoragePasscode = React.useCallback(
        async (passcodeInner: string, loginToken: AppLoginTokenContainer | undefined) => {
            setCurrentState("sign-in");
            if (submitPasscode === undefined) {
                return panic("Forgot to assign submit passcode");
            }
            const worked = await submitPasscode(passcodeInner, loginToken);
            if (!worked) {
                setCurrentState("credentials");
            }
            return;
        },
        [setCurrentState, submitPasscode]
    );

    const saveAllowLogin = React.useCallback(
        (allowSaveLoginInner: boolean) => {
            const value = allowSaveLoginInner ? "true" : "false";
            localStorageSetItem(getAllowLoginSaveKey(appID), value);
        },
        [appID]
    );

    const onUserAgreedChange = React.useCallback((newUserAgreed: boolean) => {
        setUserAgreed(newUserAgreed);
    }, []);

    const onUserUpdateSaveLogin = React.useCallback(
        (newAllowSaveLogin: boolean) => {
            saveAllowLogin(newAllowSaveLogin);
            setAllowSaveLogin(newAllowSaveLogin);
        },
        [saveAllowLogin]
    );

    const isWithinDateTimestamp = React.useCallback((): boolean => {
        const date = localStorageGetItem(getPinKey(appID));
        if (date === undefined) return false;

        return new Date().getTime() - parseInt(date, 10) < MaxPinLifeMins * 60 * 1000;
    }, [appID]);

    const checkLocalStorageToken = React.useCallback(
        async (loginToken: AppLoginTokenContainer, _email: string | undefined, _authKind: AppAuthenticationKind) => {
            if (_authKind !== AppAuthenticationKind.Password && _authKind !== AppAuthenticationKind.EmailPin) return;
            setCurrentState("sign-in");
            let worked: boolean;
            if (_authKind === AppAuthenticationKind.Password) {
                if (submitPasscode === undefined) {
                    return panic("Forgot to assign submit passcode");
                }
                // FIXME: We shouldn't even be trying a passcode in this case
                worked = await submitPasscode("", loginToken);
            } else {
                const targetEmail = _email ?? loginToken.overrideEmail ?? "";
                worked = await credentials.authenticate(targetEmail, undefined, loginToken);
                if (worked && onLoggedIn !== undefined) {
                    onEmailChange(targetEmail);
                    setCurrentState("sign-in");
                    await onLoggedIn(targetEmail);
                }
            }
            if (!worked) {
                setCurrentState("credentials");
            }
            return;
        },
        [credentials, onEmailChange, onLoggedIn, setCurrentState, submitPasscode]
    );

    const checkEmailPasswordCanAuth = React.useCallback(async () => {
        const inMemoryPasscode = credentials.password;
        const inMemoryEmail = credentials.email;
        const inMemoryEmailPassword = credentials.emailPassword;
        // We sometimes pass in an app login token via the hash fragment,
        // mainly for testing purposes. However, if the credentials already
        // have a login token it's expected to be "fresher" so we should
        // defer to it first.
        const inMemoryLoginToken = credentials.loginToken;

        const localStoragePasscode = localStorageGetItem(getPasswordKey(appID));
        const localStorageEmailCredentials = getLocalEmailLoginCredentials(appID);
        const localStorageEmail = inMemoryLoginToken?.overrideEmail ?? localStorageEmailCredentials?.email;
        const localStorageEmailPassword = localStorageEmailCredentials?.emailPassword;
        const localStorageLoginToken = getLocalAppLoginToken(appID);

        // The reason we need to pull from this.props.credentials is in the case of chat and comments.
        // It is possible to log in and opt out of auth cookie storage. This will keep the credentials in memory but will
        // wipe out the local storage items. We should first try the in-memory credentials to avoid having
        // to log in again while using the app. If the in memory creditials are not defined, we fallback to
        // the credentials stored in local storage for initial log-in flow
        const passcodeDraft = !isEmptyOrUndefined(inMemoryPasscode) ? inMemoryPasscode : localStoragePasscode;
        const emailDraft = !isEmptyOrUndefined(inMemoryEmail) ? inMemoryEmail : localStorageEmail;
        const emailPassword = !isEmptyOrUndefined(inMemoryEmailPassword)
            ? inMemoryEmailPassword
            : localStorageEmailPassword;
        const loginToken = inMemoryLoginToken ?? localStorageLoginToken;

        if (emailDraft !== undefined) {
            onEmailChange(emailDraft);
        }

        if (!isEmptyOrUndefined(passcodeDraft) && authKind === AppAuthenticationKind.Password) {
            await checkLocalStoragePasscode(passcodeDraft, loginToken);
        } else if (
            (!isEmptyOrUndefined(emailPassword) || loginToken !== undefined) &&
            !isEmptyOrUndefined(emailDraft) &&
            authKind === AppAuthenticationKind.EmailPin
        ) {
            await checkLocalStoragePin(emailPassword, emailDraft, loginToken);
        } else if (
            !isEmptyOrUndefined(emailDraft) &&
            isWithinDateTimestamp() &&
            authKind === AppAuthenticationKind.EmailPin &&
            loginToken === undefined
        ) {
            setCurrentState("challenge");
        } else if (loginToken !== undefined) {
            await checkLocalStorageToken(loginToken, emailDraft, authKind);
        }
    }, [
        appID,
        authKind,
        checkLocalStoragePasscode,
        checkLocalStoragePin,
        checkLocalStorageToken,
        credentials.email,
        credentials.emailPassword,
        credentials.loginToken,
        credentials.password,
        isWithinDateTimestamp,
        onEmailChange,
        setCurrentState,
    ]);

    const onUsernamePressedContinue = React.useCallback(async () => {
        if (credentials.canChangeUsername) {
            await credentials.setUsername(username);
        }
        setCurrentState("credentials");
        await checkEmailPasswordCanAuth();
    }, [checkEmailPasswordCanAuth, credentials, setCurrentState, username]);

    const onRequestAccessPressedContinue = React.useCallback(async () => {
        if (!canRequestAccess) return;

        setIsSending(true);
        setCanContinue(false);
        const result = await credentials.requestAccess(email);
        if (result.status === "Forbidden") {
            setError(getLocalizedString("emailAddressDoesntHaveAccess", appKind));
            setIsSending(false);
            setCanContinue(true);
            return;
        } else if (result.status === "Offline") {
            setError(getLocalizedString("networkAppearsOffline", appKind));
            setIsSending(false);
            return;
        } else if (result.status === "PaymentRequired") {
            setError("This team has reached its user limit. Please contact the app owner to resolve this issue.");
            setIsSending(false);
            return;
        } else if (result.status === "Success") {
            setIsSending(false);
            setCanContinue(true);
            setCurrentState("request-access-complete");
        }
    }, [appKind, canRequestAccess, credentials, email, setCurrentState]);

    const onPressedBack = React.useCallback(() => {
        setCurrentState("credentials");
    }, [setCurrentState]);

    const onEmailPressedContinue = React.useCallback(async () => {
        if (currentState === "credentials") {
            // Some browsers (I'm looking at you Firefox) like to
            // trigger button press callbacks multiple times per press.
            // So we can't rely on the button being deactivated, we have
            // to do this ourselves.
            if (isSending) return;

            setCanContinue(false);
            setIsSending(true);
            const { status: sent, link } = await credentials.sendPin(email, privateInviteLinkToken);
            if (sent === "Forbidden") {
                if (canRequestAccess) {
                    setCurrentState("request-access-prompt");
                    setIsSending(false);
                    setCanContinue(true);
                } else {
                    setError(getLocalizedString("emailAddressDoesntHaveAccess", appKind));
                    setIsSending(false);
                }
                return;
            } else if (sent === "Offline") {
                setError(getLocalizedString("networkAppearsOffline", appKind));
                setIsSending(false);
                return;
            } else if (sent === "PaymentRequired") {
                setError("This team has reached its user limit. Please contact the app owner to resolve this issue.");
                setIsSending(false);
                return;
            }

            // Required here for pin flow to handle the case
            // where backgrounding iOS could terminate the app. We need
            // the email address to continue from the 1/2 pin state
            // This will be removed if appropriate in the credential.authenticate method.
            localStorageSetItem(getEmailKey(appID), email);
            localStorageSetItem(getPinKey(appID), new Date().getTime().toString());

            // Store opt in locally to avoid cookie pop up
            if (appFeatures.askUserToSaveAuthCookie === true) {
                saveAllowLogin(allowSaveLogin);
            }

            setCurrentState("challenge");
            setIsSending(false);

            // In local-env, it returns a link because no email is sent.
            if (!!link) {
                try {
                    // Don't blow up if the link is invalid
                    const hash = new URL(link).hash;
                    const newURL = new URL(window.location.href);
                    newURL.hash = hash;
                    window.location.href = newURL.toString();
                } catch (e: unknown) {
                    logError("Failed to create URL from returned link", e);
                }
            }
        } else if (currentState === "challenge") {
            setCurrentState("sign-in");
            const pw = await credentials.passwordForPin(email, pin, userAgreed);
            if (pw !== undefined) {
                const authed = await credentials.authenticate(email, pw.password, pw.loginToken);
                if (authed && onLoggedIn !== undefined) {
                    await onLoggedIn(email);
                    localStorageRemoveItem(getPinKey(appID));
                } else {
                    setError(getLocalizedString("tryAgain", appKind));
                    setCurrentState("credentials");
                }
            } else {
                setCurrentState("challenge");
                setError(getLocalizedString("wrongPasscode", appKind));
                return;
            }
        }
    }, [
        allowSaveLogin,
        appFeatures.askUserToSaveAuthCookie,
        appID,
        appKind,
        canRequestAccess,
        credentials,
        currentState,
        email,
        isSending,
        onLoggedIn,
        pin,
        privateInviteLinkToken,
        saveAllowLogin,
        setCurrentState,
        userAgreed,
    ]);

    const onPressedPin = React.useCallback(() => {
        if (authKind === AppAuthenticationKind.EmailPin) {
            if (currentState === "credentials") {
                setCurrentState("challenge");
                setError(undefined);
            } else if (currentState === "challenge") {
                setError(undefined);
                setPin("");
                setCurrentState("credentials");
            }
        }
    }, [authKind, currentState, setCurrentState]);

    const onOAuth2Token = React.useCallback(
        async (authToken: string) => {
            onEnterOAuth2Flow();
            const maybeEmailPassword = await credentials.emailAndPasswordForOAuth2Token(authToken, userAgreed);

            if (maybeEmailPassword === undefined) {
                // FIXME: We should use AuthState.OAuth2Unauthorized
                setCurrentState("credentials");
            } else if (maybeEmailPassword.password === undefined && maybeEmailPassword.loginToken === undefined) {
                // FIXME: We shouldn't be dropping the email in the input,
                // but that's all we currently have for communication.
                setEmail(maybeEmailPassword.email);
                setCurrentState("credentials");
                setError(getLocalizedString("emailAddressDoesntHaveAccess", appKind));
            } else if (onLoggedIn !== undefined) {
                setCurrentState("sign-in");
                await onLoggedIn(maybeEmailPassword.email);
            }
        },
        [appKind, credentials, onEnterOAuth2Flow, onLoggedIn, setCurrentState, userAgreed]
    );

    const onMaybeOAuth2Token = React.useCallback(
        async (authToken: string | undefined) => {
            if (authToken === undefined) return;
            // We want to make sure that we only ever respond once for a given
            // auth token, but that we respond at least once for every given
            // auth token. It's unfortunately possible for
            // `samePageAuthToken.current` to be set to multiple auth tokens
            // before we fire for each: we won't rely on the behavior of Watchable
            // to synchronously execute this. So to make sure that we're definitely
            // reacting to all auth tokens, we only reset `current` to `undefined`
            // if it's equal to our `authToken`.
            if (samePageAuthToken.current === authToken) {
                samePageAuthToken.current = undefined;
            }
            await onOAuth2Token(authToken);
        },
        [onOAuth2Token]
    );

    const nativeGoogleOauthCallbacks = React.useMemo(
        () => ({
            onTokenExchangeStart: () => setCurrentState("sign-in"),
            onTokenExchangeError: (err: LocalizedStringKey) => {
                setError(getLocalizedString(err, appKind));
                setCurrentState("credentials");
            },
            onTokenExchangeSuccess: (token: string) => onOAuth2Token(token),
        }),
        [appKind, onOAuth2Token, setCurrentState]
    );

    const onWindowMessageForOauth2Token = React.useCallback(
        async (message: MessageEvent) => {
            if (expectedAuthMessageSource === null) {
                // We used to validate that the message source was definitely the Window
                // we opened, but Mobile Safari on iOS 13.3.1 broke that. So now we
                // only check that the origins are the same (the browser controls those,
                // not third-party scripts) and that we can extract a message.
                return;
            }

            // With one exception: if the message comes from the relevant builder,
            // this is still allowed. We're making this allowance to support
            // SSO plugins, which need to communicate back to the user that
            // an error occurred.
            if (
                message.origin !== window.location.origin &&
                !getLocationSettings().urlPrefix.startsWith(message.origin)
            ) {
                // urlPrefix will be something like https://go.glideapps.com/
                // but message.origin will be something like https://go.glideapps.com without the trailing slash
                // So we'll perform a startsWith to work around this.
                return;
            }

            const authTokenPayload = decodeOAuth2AuthTokenMessage(message.data);
            if (authTokenPayload !== undefined) {
                await onOAuth2Token(authTokenPayload.authToken);
                return;
            }

            const authErrorPayload = decodeOAuth2AuthErrorMessage(message.data);
            if (authErrorPayload !== undefined) {
                setError(getLocalizedString(authErrorPayload.viaSSO === true ? "ssoAuthError" : "tryAgain", appKind));
                return;
            }
        },
        [appKind, onOAuth2Token]
    );

    // Effect
    useEffect(() => {
        setPrivateInviteLinkToken(extractPrivateInviteLinkTokenFromCurrentURL());
    }, []);

    useEffect(() => {
        // In order to support Sign in with Google, et alia, without also leaking more
        // pages into the history state (specifically for PWAs to keep feeling like apps)
        // we have to perform the entire OAuth2 flow in a pop-up of our own creation.
        // The pop-up will send us a message with the token we use to authenticate
        // with Glide. We just need to listen for it.
        window.addEventListener("message", onWindowMessageForOauth2Token);
        return () => {
            window.removeEventListener("message", onWindowMessageForOauth2Token);
        };
    }, [onWindowMessageForOauth2Token]);

    useEffect(() => {
        nativeAuthControllerCallbacks.add(nativeGoogleOauthCallbacks);
        return () => {
            nativeAuthControllerCallbacks.delete(nativeGoogleOauthCallbacks);
        };
    }, [nativeGoogleOauthCallbacks]);

    const isRunning = React.useRef(false);

    useEffect(() => {
        if (isRunning.current) return;
        isRunning.current = true;

        // IdP initiated sign-in won't give us an opener, we'll have to handle
        // OAuth tokens opened on the same page.
        samePageAuthToken.subscribe(onMaybeOAuth2Token);
        if (samePageAuthToken.current !== undefined) {
            void onMaybeOAuth2Token(samePageAuthToken.current);
            return;
        }
        if (previewMode) return;
        const localUsername = localStorageGetItem(getUsernameKey(appID));
        if (localUsername !== undefined) {
            onUsernameChange(localUsername);
        }

        if (getUsername === true && isEmptyOrUndefinedish(localUsername)) {
            setCurrentState("username");
            setUsername(localUsername ?? ""); // TODO Double check this from OG auth controller
        } else {
            void checkEmailPasswordCanAuth();
        }
        return () => {
            samePageAuthToken.unsubscribe(onMaybeOAuth2Token);
        };
    }, [
        appID,
        checkEmailPasswordCanAuth,
        getUsername,
        onMaybeOAuth2Token,
        onUsernameChange,
        previewMode,
        setCurrentState,
    ]);

    return {
        appKind,
        currentState,
        userEmail: email,
        pin,
        userAgreed,
        allowSaveLogin,
        onEmailChange,
        onPinChange,
        onUserAgreedChange,
        onUserUpdateSaveLogin,
        onPressedPin,
        canContinue,
        error,
        authMethod,
        appFeatures,
        isSending,
        onClose,
        canRequestAccess,
        canPressPin,
        pagesSource,
        onUsernamePressedContinue,
        onRequestAccessPressedContinue,
        onPressedBack,
        onEmailPressedContinue,
    };
};
