import { AppKind } from "@glide/location-common";
import { type ActionDescription, ActionKind } from "@glide/app-description";
import {
    type WireActionInflationBackend,
    type WireActionResult,
    type WireActionResultBuilder,
    WireScreenPosition,
    type WireActionHydrator,
} from "@glide/wire";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import type { ActionAvailability } from "@glide/function-utils";
import { actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins";

export class CloseModalHandler extends BaseActionHandler<ActionDescription> {
    public readonly kind = ActionKind.CloseModal;
    public readonly iconName: GlideIconProps = {
        icon: "st-close-circle",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };
    public readonly name: string = "Close overlay";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public get appKinds(): AppKind | "both" {
        return AppKind.Page;
    }

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 5,
            properties: [],
        };
    }

    public inflate(
        _ib: WireActionInflationBackend,
        _desc: ActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        return hb => {
            if (!hb.getIsModalVisible()) return arb.error(true, "No modal is visible");
            return async ab => {
                ab.closeScreen(WireScreenPosition.Modal);
                return arb.success();
            };
        };
    }
}
