import { type LoadedRow, isLoadingValue } from "@glide/computation-model-types";
import { type ActionDescription, type PropertyDescription, ActionKind } from "@glide/app-description";
import { sheetNameForTable } from "@glide/type-schema";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    requestSignatureScreenName,
    getPrimitiveNonHiddenColumnsSpec,
    type ActionAvailability,
} from "@glide/function-utils";
import {
    type WireActionHydrator,
    type WireActionInflationBackend,
    type WireActionResult,
    type WireActionResultBuilder,
    PageScreenTarget,
} from "@glide/wire";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

export interface RequestSignatureActionDescription extends ActionDescription {
    readonly saveTo: PropertyDescription;
}

const columnPropertyHandler = new ColumnPropertyHandler(
    "saveTo",
    "Save to",
    [
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    ["signature", "sign"],
    getPrimitiveNonHiddenColumnsSpec,
    "image-uri",
    PropertySection.Data
);

export class RequestSignatureActionHandler extends BaseActionHandler<RequestSignatureActionDescription> {
    public readonly kind = ActionKind.RequestSignature;

    public readonly iconName: GlideIconProps = {
        icon: "st-signature",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    public readonly name: string = "Request signature";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(): ActionDescriptor {
        const properties: PropertyDescriptor[] = [columnPropertyHandler];

        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 17,
            needsScreenContext: true,
            isLegacy: !getFeatureSetting("requestSignatureAction"),
            properties,
        };
    }

    public getTokenizedDescription(
        desc: RequestSignatureActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const token = tokenForProperty(desc.saveTo, env);
        if (token === undefined) return undefined;
        return [{ kind: "string", value: "into " }, token];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: RequestSignatureActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const { tableAndColumn, setterMaker } = ib.getValueSetterForProperty(desc.saveTo, "set-signature");
        if (tableAndColumn === undefined) return arb.inflationError("Invalid column to save to");

        const isMutatingScreen = ib.mutatingScreenKind !== undefined;

        arb = arb.addData({ tableName: sheetNameForTable(ib.tables.output) });

        return (vp, skipLoading) => {
            const setter = setterMaker(vp);
            if (isLoadingValue(setter)) return arb.errorIfSkipLoading(skipLoading, "Destination column");
            if (setter === undefined) return arb.error(true, "No column to save to");

            const row: LoadedRow = {
                $rowID: makeRowID(),
                $isVisible: false,
                acceptOnFirst: isMutatingScreen,
                // The signature page will set this to the image value
                value: undefined,
            };

            return ab => {
                return new Promise(resolve => {
                    try {
                        ab.addSpecialScreenRow(row);
                        ab.pushFreeScreen(
                            requestSignatureScreenName,
                            [row],
                            "",
                            PageScreenTarget.SmallModal,
                            undefined,
                            async () => {
                                try {
                                    if (row.value === undefined) {
                                        return resolve(arb.error(true, "No value for signature"));
                                    }
                                    const result = await setter(ab, row.value);
                                    return resolve(arb.fromResult(result));
                                } catch (e: unknown) {
                                    return resolve(arb.fromException(true, e));
                                }
                            }
                        );
                    } catch (e: unknown) {
                        return resolve(arb.fromException(true, e));
                    }
                });
            };
        };
    }
}
