import type { NotAllowedToSignInReason } from "@glide/common-core";
import { notAllowedToSignInResponse } from "@glide/common-core";
import { isLeft } from "fp-ts/lib/Either";
import { logError } from "@glide/support";

export async function parseNotAllowedToSignInReason(response: Response): Promise<NotAllowedToSignInReason | undefined> {
    let jsonResponse: unknown;
    try {
        jsonResponse = await response.json();
    } catch (e: unknown) {
        logError("Failed to parse response body", e);
        return undefined;
    }

    const decodeResponseResult = notAllowedToSignInResponse.decode(jsonResponse);
    if (isLeft(decodeResponseResult)) {
        return undefined;
    }

    return decodeResponseResult.right.reason;
}
