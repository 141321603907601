import { isLoadingValue } from "@glide/computation-model-types";
import { areTableNamesEqual, makeTableName, type Description, type Formula, getTableName } from "@glide/type-schema";
import {
    type BuilderAction,
    type ActionDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
    PropertyKind,
    type ConditionalActionNode,
    type FlowActionNode,
    type PrimitiveActionNode,
    type AutomationRootNode,
    type LoopNode,
    ActionNodeKind,
    getCompoundActionProperty,
    makeCompoundActionProperty,
} from "@glide/app-description";
import { type InputOutputTables, makeInputOutputTables } from "@glide/common-core/dist/js/description";
import {
    type AppDescriptionContext,
    type CompoundActionPropertyDescriptorCase,
    type EditedColumnsAndTables,
    type InteractiveComponentConfiguratorContext,
    type RewritingComponentConfiguratorContext,
    PropertySection,
} from "@glide/function-utils";
import { mapFilterUndefined, assert, definedMap, panic } from "@glideapps/ts-necessities";
import { mapFilterUndefinedAsync } from "@glide/support";
import {
    type WireActionBackend,
    type WireActionResultBuilder,
    WireActionResult,
    WireActionResultKind,
    type WireActionHydrator,
    type WireActionInflationBackend,
    type WirePredicate,
} from "@glide/wire";
import { arrayMapSync } from "collection-utils";
import { handlerForPropertyKind } from "../components/description-handlers";
import { type CompoundActionLogger, inflateActions, makeActionRunner } from "../wire/utils";
import type { ActionDescriptor } from "./action-descriptor";
import { BaseActionHandler } from "./base";
import { handlerForActionKind } from ".";
import { getLabelForConditionFormula } from "../formulas/formula-labels";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import type { StaticActionContext } from "../static-context";
import { processCompoundActionFlow } from "../components/walk-action";
import { copyTrigger } from "./copy-actions";

export interface CompoundActionDescription extends ActionDescription {
    readonly kind: ActionKind.Compound;
    readonly actionID: PropertyDescription;
}

function getBuilderAction(
    desc: CompoundActionDescription | undefined,
    adc: AppDescriptionContext
): BuilderAction | undefined {
    const actionID = getCompoundActionProperty(desc?.actionID);
    if (actionID === undefined) return undefined;
    return adc.getBuilderAction(actionID);
}

const propertyDescriptorCase: CompoundActionPropertyDescriptorCase = {
    kind: PropertyKind.CompoundAction,
};

export interface ActionDebugger {
    willUseFlow(key: string): void;
    shouldRun(key: string): Promise<boolean>;
    didRun(key: string, result: WireActionResult): void;
}

export interface SpecializedActionDebugger {
    // FIXME: Also add the log function?
    shouldRun(): Promise<boolean>;
    didRun(result: WireActionResult): void;
}

export function inflateCompoundAction(
    ib: WireActionInflationBackend,
    root: ConditionalActionNode,
    arb: WireActionResultBuilder,
    logger: CompoundActionLogger | undefined,
    actionDebugger: ActionDebugger | undefined
): WireActionHydrator | WireActionResult {
    const { adc } = ib;
    const conditionPredicates = new Map<Formula, WirePredicate>();
    const actionHydrators = new Map<string, WireActionHydrator | WireActionResult>();

    function inflateFlow(flow: FlowActionNode): void {
        for (const a of flow.actions) {
            assert(a.kind === ActionNodeKind.Primitive, "TODO: handle nested flows");

            const { actionDescription, key } = a;
            let specializedActionDebugger: SpecializedActionDebugger | undefined;
            if (actionDebugger !== undefined) {
                specializedActionDebugger = {
                    shouldRun() {
                        return actionDebugger.shouldRun(key);
                    },
                    didRun(success) {
                        return actionDebugger.didRun(key, success);
                    },
                };
            }
            // We don't obey ##conditionsInCustomActions.
            // FIXME: We should also pass this the `key` so it can add it to
            // the `WireActionResultBuilder`.
            const hydrator = inflateActions(ib, [actionDescription], false, specializedActionDebugger);
            if (hydrator === undefined) continue;

            actionHydrators.set(key, hydrator);
        }
    }

    for (const conditionalFlow of root.conditionalFlows) {
        const [predicate] = ib.inflatePredicate(conditionalFlow.condition, false, true) ?? [];
        // TODO: This should arguably not just ignore the error but either
        // error out right away, or make the hydration error out if this
        // predicate is ever used.
        if (predicate === undefined) continue;

        conditionPredicates.set(conditionalFlow.condition, predicate);
        inflateFlow(conditionalFlow.flow);
    }
    if (root.elseFlow !== undefined) {
        inflateFlow(root.elseFlow);
    }

    return (vp, skipLoading, detailScreenTitle) => {
        let flowToUse: FlowActionNode | undefined;
        let conditionUsed: string | undefined;
        let useURL = false;

        function useFlow(flow: FlowActionNode, withURL: boolean): void {
            flowToUse = flow;
            if (withURL) {
                useURL = true;
            }

            actionDebugger?.willUseFlow(flow.key);
        }

        for (const conditionalFlow of root.conditionalFlows) {
            const predicate = conditionPredicates.get(conditionalFlow.condition);
            if (predicate === undefined) continue;

            const conditionValue = predicate(vp);
            if (isLoadingValue(conditionValue)) return arb.maybeSkipLoading(skipLoading, "Condition");

            if (conditionValue) {
                useFlow(conditionalFlow.flow, false);
                conditionUsed = getLabelForConditionFormula(
                    conditionalFlow.condition,
                    adc,
                    ib.tables.input,
                    ib.getActionNodesInScope(),
                    []
                );
                break;
            }
        }

        if (flowToUse === undefined && root.elseFlow !== undefined) {
            useFlow(root.elseFlow, root.conditionalFlows.length === 0);
            if (root.conditionalFlows.length > 0) {
                conditionUsed = "Else";
            }
        }

        // "No action to run" is currently handled the same way as "error
        // hydrating action" - we return `undefined`.  This can cause
        // confusion, and should eventually be resolved by reporting
        // errors to the user.  For a case where we confused ourselves,
        // see this PR:
        //   https://github.com/quicktype/glide/pull/12998
        // which we had to revert because it caused:
        //   https://github.com/quicktype/glide/issues/13100

        if (flowToUse === undefined) return arb.nothingToDo("No condition was met");

        const firstAction = flowToUse.actions[0];
        if (firstAction === undefined) return arb.nothingToDo("No actions to run for the condition");

        return makeActionRunner(
            mapFilterUndefined(flowToUse.actions, a => actionHydrators.get(a.key)),
            useURL,
            vp,
            skipLoading,
            detailScreenTitle,
            arb,
            logger,
            conditionUsed,
            undefined
        );
    };
}

function makeDummyPrimitiveActionNode(appFacilities: ActionAppFacilities): PrimitiveActionNode {
    return {
        kind: ActionNodeKind.Primitive,
        key: appFacilities.makeRowID(),
        actionDescription: { kind: ActionKind.ShowToast },
    };
}

// Exported for testing
export async function duplicateAction(
    desc: CompoundActionDescription,
    rootDesc: Description,
    copyTables: InputOutputTables | undefined,
    iccc: InteractiveComponentConfiguratorContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    screensCreated: Set<string>,
    appFacilities: ActionAppFacilities
): Promise<CompoundActionDescription | undefined> {
    const builderAction = getBuilderAction(desc, iccc);
    if (builderAction === undefined) return undefined;

    const isWorkflow = builderAction.action.kind === ActionNodeKind.AutomationRoot;

    const inputTable = copyTables?.input ?? builderAction.perApp[iccc.appID]?.tableName ?? iccc.schema.tables[0];
    const maybeTable = iccc.schema.tables.find(t => t.name === builderAction.perApp[iccc.appID]?.tableName);
    const guaranteedIOTables = copyTables ?? makeInputOutputTables(maybeTable ?? iccc.schema.tables[0]);

    const perApp = builderAction.perApp[iccc.appID];
    if (perApp === undefined) return undefined;

    const copyFlow = async (flow: FlowActionNode) => {
        const actions: FlowActionNode["actions"] = await mapFilterUndefinedAsync(flow.actions, async p => {
            if (p.kind === ActionNodeKind.Loop) {
                const loopNode: LoopNode = {
                    ...p,
                    flow: await copyFlow(p.flow),
                };
                return loopNode;
            }

            if (p.kind === ActionNodeKind.Conditional) {
                const conditionalNode: ConditionalActionNode = {
                    ...p,
                    conditionalFlows: await arrayMapSync(p.conditionalFlows, async f => {
                        return {
                            ...f,
                            flow: await copyFlow(f.flow),
                        };
                    }),
                    elseFlow: await definedMap(p.elseFlow, copyFlow),
                };
                return conditionalNode;
            }

            const handler = handlerForActionKind(p.actionDescription.kind);
            const actionDescription = await handler.duplicateAction(
                p.actionDescription,
                rootDesc,
                guaranteedIOTables,
                iccc,
                mutatingScreenKind,
                screensCreated,
                appFacilities
            );
            if (actionDescription === undefined) return makeDummyPrimitiveActionNode(appFacilities);
            return { ...p, actionDescription };
        });

        return {
            ...flow,
            actions: actions.length === 0 && !isWorkflow ? [makeDummyPrimitiveActionNode(appFacilities)] : actions,
        };
    };

    let actionCopy: ConditionalActionNode | FlowActionNode | AutomationRootNode;
    if (builderAction.action.kind === ActionNodeKind.Conditional) {
        actionCopy = {
            ...builderAction.action,
            conditionalFlows: await arrayMapSync(builderAction.action.conditionalFlows, async f => ({
                ...f,
                flow: await copyFlow(f.flow),
            })),
            elseFlow: await definedMap(builderAction.action.elseFlow, copyFlow),
        };
    } else if (builderAction.action.kind === ActionNodeKind.AutomationRoot) {
        actionCopy = {
            ...builderAction.action,
            flow: await copyFlow(builderAction.action.flow),
        };
    } else {
        actionCopy = await copyFlow(builderAction.action);
    }

    const actionID = await iccc.saveBuilderAction(
        undefined,
        // We "fix up" the action's table in case we need it for
        // ##copyFormSubmitActions.
        getTableName(inputTable),
        `${builderAction.title ?? "action"} copy`,
        builderAction.description,
        actionCopy,
        perApp.automation === undefined
            ? undefined
            : {
                  ...perApp.automation,
                  triggers: await Promise.all(
                      perApp.automation.triggers.map(t => copyTrigger(iccc.appID, t, appFacilities))
                  ),
              }
    );
    return { ...desc, actionID: makeCompoundActionProperty(actionID) };
}

export class CompoundActionHandler extends BaseActionHandler<CompoundActionDescription> {
    public readonly kind = ActionKind.Compound;
    // FIXME: Should this be configurable?
    public readonly iconName = true;
    public readonly name = "Workflow";

    public getIsApplicable(): boolean {
        return false;
    }

    public getDescriptor(
        desc: CompoundActionDescription | undefined,
        { context: ccc }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const action = getBuilderAction(desc, ccc);

        return {
            name: action?.title ?? this.name,
            needsScreenContext: true,
            properties: [
                {
                    ...propertyDescriptorCase,
                    label: "Action",
                    property: { name: "actionID" },
                    section: PropertySection.Data,
                },
            ],
        };
    }

    public getEditedColumns(
        desc: CompoundActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): EditedColumnsAndTables | undefined {
        const handler = handlerForPropertyKind(PropertyKind.CompoundAction);
        return handler.getEditedColumns(propertyDescriptorCase, desc.actionID, desc, env, true);
    }

    // ##duplicateCompoundAction:
    // FIXME: We should implement this in a more regular way, via the compound
    // description handler, now that we have proper copying of descriptions.
    public async duplicateAction(
        desc: CompoundActionDescription,
        rootDesc: Description,
        copyTables: InputOutputTables | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<CompoundActionDescription | undefined> {
        return await duplicateAction(
            desc,
            rootDesc,
            copyTables,
            iccc,
            mutatingScreenKind,
            screensCreated,
            appFacilities
        );
    }

    // ##duplicateCompoundAction
    public async reuseOrDuplicateAction(
        desc: CompoundActionDescription,
        rootDesc: Description,
        copyTables: InputOutputTables | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<CompoundActionDescription | undefined> {
        if (copyTables === undefined) return undefined;

        const builderAction = getBuilderAction(desc, iccc);
        if (builderAction === undefined) return undefined;

        const perApp = builderAction.perApp[iccc.appID];
        if (perApp === undefined) return undefined;

        if (areTableNamesEqual(perApp.tableName, makeTableName(copyTables.input.name))) return desc;

        return await duplicateAction(
            desc,
            rootDesc,
            copyTables,
            iccc,
            mutatingScreenKind,
            screensCreated,
            appFacilities
        );
    }

    public rewriteAfterReload(
        desc: CompoundActionDescription,
        tables: InputOutputTables,
        ccc: RewritingComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): CompoundActionDescription | undefined {
        const builderAction = getBuilderAction(desc, ccc);
        // We're not discarding it, because the action might simply have failed to
        // load, in which case the `ScreenDescriptionBuilder` will retain all screens,
        // since any screen might be referenced by this action.
        if (builderAction === undefined) return desc;

        function processFlow(flow: FlowActionNode): void {
            for (const a of flow.actions) {
                if (a.kind === ActionNodeKind.Primitive) {
                    const { actionDescription } = a;
                    const handler = handlerForActionKind(actionDescription.kind);
                    // We're not actually rewriting the builder actions, but we need
                    // to run the rewriting code so that linked screens will be
                    // included.
                    handler.rewriteAfterReload(actionDescription, tables, ccc, mutatingScreenKind);
                } else if (a.kind === ActionNodeKind.Conditional) {
                    processCompoundActionFlow(a, processFlow);
                } else {
                    return panic("FIXME: do we have to support this here?");
                }
            }
        }

        if (builderAction.action.kind === ActionNodeKind.Conditional) {
            processCompoundActionFlow(builderAction.action, processFlow);
        } else if (builderAction.action.kind === ActionNodeKind.AutomationRoot) {
            processFlow(builderAction.action.flow);
        } else {
            processFlow(builderAction.action);
        }

        return desc;
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: CompoundActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const builderAction = getBuilderAction(desc, ib.adc);
        if (builderAction === undefined) return arb.inflationError("Custom action not found");
        const root = builderAction.action;
        const actionID = getCompoundActionProperty(desc.actionID);
        const title = builderAction.title;
        arb = arb.addData({ actionID, title });

        function logActionResult(ab: WireActionBackend, startedAt: Date, finishedAt: Date, result: WireActionResult) {
            ab.logActionResult(actionID, startedAt, finishedAt, result);
        }

        const logger: CompoundActionLogger = {
            logActionResult,
            logCondition(ab: WireActionBackend, evaluatedAt: Date, name: string): void {
                logActionResult(
                    ab,
                    evaluatedAt,
                    evaluatedAt,
                    new WireActionResult({
                        kind: WireActionResultKind.Success,
                        desc: { actionID: desc.actionID, kind: "conditional" },
                        data: { name },
                        outputs: {},
                    })
                );
            },
        };

        assert(root.kind === ActionNodeKind.Conditional);
        return inflateCompoundAction(ib, root, arb, logger, undefined);
    }
}
