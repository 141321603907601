import "twin.macro";
import * as React from "react";
import type { WireRenderer } from "../wire-renderer";
import type { WirePageVoiceEntryComponent } from "@glide/fluent-components/dist/js/base-components";
import { ValueChangeSource } from "@glide/wire";
import { insertAtCursor, setCaretPosition } from "./text-insertion-utils";
import { RecordAndTranscribeComponent } from "./record-and-transcribe-component";
import { isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { usePreviousValue } from "@glide/common";

export const WireVoiceEntry: WireRenderer<WirePageVoiceEntryComponent> = React.memo(p => {
    const { backend, transcript, transcriptionState, autoRecord, textareaPlaceholder } = p;
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);
    const [temporaryTranscription, setTemporaryTranscription] = React.useState<string>(transcript.value);

    const previousTranscript = usePreviousValue(transcript.value);
    React.useEffect(() => {
        if (transcript.value !== previousTranscript) {
            setTemporaryTranscription(transcript.value);
        }
    }, [previousTranscript, transcript.value]);

    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    const cursorPositionRef = React.useRef<number | null>(null);

    const onChange = React.useCallback(
        (value: string) => {
            backend.valueChanged(transcript.onChangeToken, value, ValueChangeSource.User);
        },
        [backend, transcript.onChangeToken]
    );

    const { value: state, onChangeToken: stateOnChangeToken } = transcriptionState;

    const setState = React.useCallback(
        (value: "idle" | "recording" | "processing" | "error") => {
            backend.valueChanged(stateOnChangeToken, value, ValueChangeSource.User);
        },
        [backend, stateOnChangeToken]
    );

    const updateTextAreaHeight = React.useCallback(() => {
        if (!textareaRef.current || !isMobile) return;

        const element = textareaRef.current;
        element.style.height = "auto";

        const MIN_HEIGHT = 52;
        const MAX_HEIGHT = 500;
        const newHeight = Math.min(MAX_HEIGHT, Math.max(MIN_HEIGHT, element.scrollHeight));

        element.style.height = `${newHeight}px`;
    }, [isMobile]);

    React.useLayoutEffect(() => {
        updateTextAreaHeight();
        window.addEventListener("resize", updateTextAreaHeight);
        return () => window.removeEventListener("resize", updateTextAreaHeight);
    }, [updateTextAreaHeight]);

    React.useEffect(() => {
        if (transcript.value !== undefined) {
            updateTextAreaHeight();
        }
    }, [transcript.value, updateTextAreaHeight]);

    const scrollToBottom = React.useCallback(() => {
        if (!textareaRef.current) return;
        const element = textareaRef.current;
        element.scrollTop = element.scrollHeight;
    }, []);

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
        updateTextAreaHeight();
        scrollToBottom();
        cursorPositionRef.current = e.target.selectionStart;
    };

    const handleSelect = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
        cursorPositionRef.current = (e.target as HTMLTextAreaElement).selectionStart;
    };

    const onTranscribedText = React.useCallback(
        (text: string) => {
            if (textareaRef.current === null) {
                return;
            }
            const textarea = textareaRef.current;
            let newText: string;
            let newPosition: number;

            if (cursorPositionRef.current === null) {
                newText = transcript.value + text;
                newPosition = newText.length;
            } else {
                newText = insertAtCursor(textarea, text);
                newPosition = cursorPositionRef.current + text.length + 1;
            }

            onChange(newText);
            setCaretPosition(textarea, newPosition);
            cursorPositionRef.current = newPosition;

            requestAnimationFrame(() => {
                updateTextAreaHeight();
                scrollToBottom();
            });
        },
        [onChange, scrollToBottom, updateTextAreaHeight, transcript.value]
    );

    const maybeAddSpace = (text: string) => {
        return text.endsWith(" ") ? text : text + " ";
    };

    const isBusy = state === "recording" || state === "processing";
    const onTemporaryTranscription = React.useCallback(
        (text: string) => {
            if (isBusy && text.length > 0) {
                setTemporaryTranscription(maybeAddSpace(transcript.value) + text);
            }
        },
        [transcript.value, isBusy]
    );

    const isRecordingOrProcessing = state === "recording" || state === "processing";
    const showPlaceholder = !(isRecordingOrProcessing && temporaryTranscription);

    const shouldShowBackgroundTextArea =
        isRecordingOrProcessing &&
        (cursorPositionRef.current === transcript.value.length || cursorPositionRef.current === null);

    return (
        <div tw="flex flex-1 flex-col justify-between pb-4">
            <div tw="relative flex-1">
                {shouldShowBackgroundTextArea && (
                    <div
                        tw="absolute inset-0 w-full h-full pl-4 pr-1 rounded-md text-text-base bg-transparent overflow-hidden pointer-events-none"
                        style={{
                            zIndex: 1,
                            opacity: 0.5,
                        }}>
                        {temporaryTranscription}
                    </div>
                )}

                <textarea
                    ref={textareaRef}
                    placeholder={showPlaceholder ? textareaPlaceholder : ""}
                    disabled={isBusy}
                    tw="w-full h-full border-none rounded-md pl-4 pr-1 text-text-base resize-none bg-transparent disabled:bg-transparent relative transition-all duration-200"
                    style={{
                        zIndex: 2,
                        scrollPaddingBlock: "12px",
                    }}
                    value={transcript.value}
                    onChange={handleTextAreaChange}
                    onSelect={handleSelect}
                    onInput={updateTextAreaHeight}
                    rows={1}
                />
            </div>

            <RecordAndTranscribeComponent
                state={state}
                setState={setState}
                startRecordingOnMount={autoRecord}
                onTranscribedText={onTranscribedText}
                onTemporaryTranscription={onTemporaryTranscription}
            />
        </div>
    );
});
