import type { EminenceFlags } from "@glide/billing-types";
import type { MemberNotificationSettings } from "@glide/common-core/dist/js/Database";
import {
    type AppAnalytics,
    type OrganizationFolder,
    type UserDataAndState,
    AuthenticationMethod,
    isUserData,
} from "@glide/common-core/dist/js/Database";
import { AppKind } from "@glide/location-common";
import type { GetOrganizationMembersResult } from "@glide/common-core/dist/js/firebase-function-types";
import {
    type CreateOrganizationBody,
    type GetOrgFoldersBody,
    type SetOrgAdminBody,
    type SetPublishingActiveBody,
    type SetPublishingActiveResponseBody,
    setPublishingActiveResponseBody,
    getOrganizationMembersResult,
} from "@glide/common-core/dist/js/firebase-function-types";
import type {
    BuilderTileDescription as BuilderTileDescriptionType,
    OrgDetails as OrgDetailsType,
    OrgMember as OrgMemberType,
    OrgWithNumEmployees,
    PlanDescription as PlanDescriptionType,
    TitledRange as TitledRangeType,
} from "@glide/common-core/dist/js/orgs-lib-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import type { TileDescriptionsState } from "@glide/action-reducer-utils";
import { sleep } from "@glideapps/ts-necessities";
import { isResponseOK, logError, maybeAsync } from "@glide/support";
import { isRight } from "fp-ts/lib/Either";

export type OrgMember = OrgMemberType;
export type OrgDetails = OrgDetailsType;
export type PlanDescription = PlanDescriptionType;
export type BuilderTileDescription = BuilderTileDescriptionType;
export type TitledRange = TitledRangeType;

export function isUserOrgID(id: string | undefined): boolean {
    return id === undefined || id === "0";
}

export function getOrgOwner(orgMembers: readonly OrgMember[]): OrgMember | undefined {
    return orgMembers.length > 0 ? orgMembers[0] : undefined;
}

function mapJsonToOrgMember(json: GetOrganizationMembersResult["members"][number]): OrgMember {
    return {
        userID: json.userID,
        displayName: json.displayName ?? "",
        email: json.email ?? "",
        photoURL: json.photoURL,
        status: json.status === "pending" ? "pending" : "active",
        role: json.role,
        emailNotificationsSettings: json.emailNotificationsSettings as MemberNotificationSettings,
    };
}

export async function createOrg(orgDetails: OrgDetails | OrgWithNumEmployees): Promise<string> {
    try {
        const { name } = orgDetails;
        const f = getAppFacilities();
        const createOrgBody: CreateOrganizationBody = {
            displayName: name,
            inviteeEmails: [],
            asAgencyClientTeam: orgDetails.asAgencyClientTeam,
            clientEmail: orgDetails.clientEmail,
            companyInformation: {
                industryKind: "industry" in orgDetails ? orgDetails.industry : "Individual",
                numAppUsers: { min: 0, max: 0 },
                numAppEditors: { min: 0, max: 0 },
                numEmployees: "numEmployees" in orgDetails ? orgDetails.numEmployees : { min: 0, max: 0 },
            },
        };
        const result = await f.callAuthCloudFunction("createOrganization", createOrgBody);
        const json = await result?.json();
        if (json !== undefined) {
            return json.organizationID;
        }
    } catch {
        // do nothing
    }
    return "0";
}

export async function getOrgMembers(orgID: string | undefined, retry = 0): Promise<readonly OrgMember[] | undefined> {
    if (isUserOrgID(orgID)) return undefined;

    try {
        const f = getAppFacilities();
        const result = await f.callAuthIfAvailableCloudFunction(
            "getOrganizationMembers",
            { organizationID: orgID },
            {}
        );
        if (result !== undefined) {
            const json = await result.json();
            const decoded = getOrganizationMembersResult.decode(json);
            if (isRight(decoded)) {
                return decoded.right.members.map(mapJsonToOrgMember);
            }
        }
        return undefined;
    } catch {
        if (retry < 5) {
            await sleep(5000);
            return await getOrgMembers(orgID, retry + 1);
        }
    }
    return undefined;
}

export async function getOrganizationPersistentInviteLink(
    orgID: string | undefined,
    createNew = false
): Promise<string | undefined> {
    if (isUserOrgID(orgID)) return undefined;

    try {
        const f = getAppFacilities();
        const result = await f.callAuthCloudFunction("getOrganizationPersistentInviteLink", {
            organizationID: orgID,
            createNew,
        });
        if (result !== undefined) {
            const json = await result.json();
            return json.persistentInviteLink;
        }
        return undefined;
    } catch {
        return undefined;
    }
}

export async function addOrgMember(
    orgID: string | undefined,
    email: string | string[]
): Promise<readonly OrgMember[] | undefined | "over-quota"> {
    if (isUserOrgID(orgID)) return undefined;
    const emails = typeof email === "string" ? [email] : email;

    try {
        const f = getAppFacilities();
        const result = await f.callAuthCloudFunction("inviteToOrganization", {
            organizationID: orgID,
            inviteeEmails: emails,
        });
        if (result !== undefined && result.ok) {
            const json = await result.json();
            return json.members.map(mapJsonToOrgMember);
        } else if (result?.status === 422) {
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void result.text();
            return "over-quota";
        } else {
            // We have to drain out the body, otherwise we'll just leave the connection
            // around forever.
            void result?.text();
        }
        return undefined;
    } catch {
        return undefined;
    }
}

export async function removeOrgMember(orgID: string | undefined, member: OrgMember): Promise<boolean> {
    if (isUserOrgID(orgID)) return false;

    try {
        const f = getAppFacilities();
        const result = await f.callAuthCloudFunction("removeFromOrganization", {
            organizationID: orgID,
            userID: member.userID,
            userEmail: member.email,
        });
        void result?.text();
        return result?.ok ?? false;
    } catch {
        return false;
    }
}

export async function setOrgAdmin(orgID: string, member: OrgMember, role: SetOrgAdminBody["role"]): Promise<boolean> {
    if (isUserOrgID(orgID)) return false;

    try {
        const f = getAppFacilities();
        const body: SetOrgAdminBody = {
            organizationID: orgID,
            userIDOrEmail: member.userID,
            role,
        };
        const result = await f.callAuthCloudFunction("setOrgAdmin", body);

        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        void result?.text();
        return result?.ok ?? false;
    } catch {
        return false;
    }
}

// `true` iff the org was deleted
export async function deleteOrg(orgID: string): Promise<boolean> {
    try {
        const response = await getAppFacilities().callAuthCloudFunction("deleteOrganization", {
            organizationID: orgID,
        });
        return isResponseOK(response);
    } catch (e: unknown) {
        logError(e);
        return false;
    }
}

const defaultTile: Omit<BuilderTileDescription, "appID"> = {
    title: "Loading…",
    primaryAccentColor: "#FFFFFF",
    authenticationKind: AuthenticationMethod.None,
    url: "#",
    analytics: undefined,
    isLoading: true,
    appKind: AppKind.Page,
};

export function toTileDescription(
    id: string,
    userID: string,
    tiles: TileDescriptionsState,
    loadTileDescriptionFromFirebase: (userID: string) => void
): BuilderTileDescription {
    const r = tiles[id];

    // This is an ugly way to side effect this. I hate every part of it.
    if (r === undefined) {
        loadTileDescriptionFromFirebase(userID);
    }
    return { appID: id, isLoading: false, ...(r === "loading" || r === undefined ? defaultTile : r) };
}

export function getUsageString(analytics: AppAnalytics | undefined, eminenceFlags: EminenceFlags, isPublic: boolean) {
    if (analytics === undefined || !eminenceFlags.isUserMetered) {
        if (isPublic) {
            return "Public app";
        } else {
            return "Private app";
        }
    }
    let message = "A few users";
    const userCount = analytics.lastUniqueAppUsersInWindow;
    if (userCount === 0) {
        if (isPublic) {
            message = "Public app";
        } else {
            message = "Private app";
        }
    } else if (userCount >= 5) {
        message = `${userCount.toLocaleString()} users`;
    }
    return message;
}

export async function setAppIsFavorite(organizationID: string, appID: string, isFavorite: boolean): Promise<void> {
    if (isUserOrgID(organizationID)) return undefined;

    try {
        const f = getAppFacilities();

        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        await f
            .callAuthCloudFunction("setAppIsFavorite", {
                organizationID,
                appID,
                isFavorite,
            })
            .then(r => r?.text());
        return undefined;
    } catch {
        return undefined;
    }
}

export async function setIsPublishingActive(
    appID: string,
    active: boolean
): Promise<SetPublishingActiveResponseBody | undefined> {
    const body: SetPublishingActiveBody = {
        appID,
        active,
    };
    const res = await getAppFacilities().callAuthCloudFunction("setPublishingActive", body);

    if (res === undefined) return undefined;

    return await maybeAsync(async () => {
        const result = await res.json();
        const decoded = setPublishingActiveResponseBody.decode(result);
        return isRight(decoded) ? decoded.right : undefined;
    }, undefined);
}

export function userHasMyApps(userData: UserDataAndState) {
    if (!isUserData(userData)) return false;

    return userData.appIDs.length > 0 || userData.orgUserIDs.length === 0;
}

export async function loadOrgFolders(orgID: string): Promise<OrganizationFolder[]> {
    const payload: GetOrgFoldersBody = {
        organizationID: orgID,
    };
    const response = await getAppFacilities().callAuthCloudFunction("getOrgFolders", payload);
    if (response === undefined || !response.ok) {
        void response?.text();
        throw new Error("Didn't receive a valid response!");
    }
    return await response.json();
}
