import type { PlanKind } from "../billing-vnext/subscriptions";
import type { PageTemplateKind } from "../firebase-function-types";
import type { OnboardingUseCases } from "../use-cases";
import type { WithDataSource, WithProjectType } from "./events";

type FromView<T> = { from_view: T };
export type NewProjectFlows = "modal" | "fullScreen" | "onboarding";
export type UseDataSourceFlows = NewProjectFlows | "dataSourcePanel";
type FromNewProjectView = FromView<NewProjectFlows>;
type FromUseDataSourceView = FromView<UseDataSourceFlows>;

export type NewProjectEvents = {
    "new app": {
        fromPagesAdvert?: boolean;
    };

    "new app created": WithDataSource &
        WithApp &
        FromNewProjectView & {
            preferredFormFactor?: string;
            app_kind?: string;
            no_default_selection?: boolean;
            template?: PageTemplateKind;
        };

    "new project name and kind select": {
        app_kind?: string;
        new_project_session_id?: string;
    };

    "new project name": {
        app_kind?: string;
        new_project_session_id?: string;
    };

    "new project layout select": {
        app_kind?: string;
        page_template?: string;
        new_project_session_id?: string;
    };

    "google sheet source select": FromUseDataSourceView & {
        app_kind?: string;
        new_project_session_id?: string;
    };

    "google sheet select": FromUseDataSourceView & {
        app_kind?: string;
        new_project_session_id?: string;
    };

    "glide data source select": FromUseDataSourceView & {
        app_kind?: string;
        new_project_session_id?: string;
    };

    "excel source select": FromUseDataSourceView & {
        app_kind?: string;
        new_project_session_id?: string;
    };

    "excel source connect": FromUseDataSourceView & {
        user_id?: string;
        new_project_session_id?: string;
    };

    "excel connected": FromUseDataSourceView;

    "onedrive list requested": {};

    "onedrive list received": { drives: number };

    "onedrive select": { personal: boolean };

    "excel list requested": {};

    "excel list received": { workbooks: number; type: "recent" | "shared" | "folders" };

    "excel list continue clicked": {};

    "airtable source select": FromUseDataSourceView & {
        app_kind?: string;
        new_project_session_id?: string;
    };

    "airtable source connect": FromUseDataSourceView & {
        token?: string;
        new_project_session_id?: string;
    };

    "airtable base select": FromUseDataSourceView & {
        base_id?: string;
        new_project_session_id?: string;
    };

    "import source select": FromUseDataSourceView & {
        app_kind?: string;
        file_type: string;
        row_count: number;
        table_count: number;
        new_project_session_id?: string;
    };

    "rich template tile previewed": {
        template_id: string;
    };

    "copy rich template clicked": WithProjectType & {
        template_id: string;
    };

    "view data from template preview": {
        template_id: string;
    };

    "change device preview from template preview": {
        template_id: string;
        device: "phone" | "desktop";
    };

    "changed user from template preview": {
        template_id: string;
    };

    "template preview from qrcode": {
        template_id: string;
    };

    "use case select": FromNewProjectView & {
        use_case: OnboardingUseCases | undefined;
        other_use_case_explanation: string;
    };
    "use case skip": FromNewProjectView;
    "app generator started": FromNewProjectView;
    "app generator skip": FromNewProjectView & {
        problem: string;
        skipReason?: string;
    };
    "app generator close": FromNewProjectView & { didTry: boolean };
    "app generator completed": FromNewProjectView;
    "data source skip": FromNewProjectView;
    "chose classic app": {};
    "chose new app": {
        form_factor: "mobile" | "desktop";
    };
};

export type UpgradeComponentLocation =
    | "top_bar_upgrade"
    | "billing_upgrade_banner"
    | "billing_plan_summary"
    | "usage_upgrade_banner"
    | "share_overlay_publish_manually"
    | "share_overlay_custom_domain"
    | "insert_component_web_embed"
    | "settings_integrations_google_analytics"
    | "settings_share_share_screen"
    | "settings_data_extra_sync_mode"
    | "settings_billing_upgrade_plan"
    | "settings_user_agreements_user_agreement"
    | "insert_component_barcode_scanner"
    | "protected_columns_upgrade"
    | "settings_billing_upgrade_plan_v2"
    | string;

export type PricingV3Events = {
    "upgrade org pricingv3": {
        component_location?: UpgradeComponentLocation;
        owner_id?: string;
        plan_kind?: PlanKind;
    };
    "processing pricingv3 upgrade": {
        phase?: "started" | "failed" | "success";
        owner_id?: string;
        plan_kind?: PlanKind;
    };

    "upgraded to pay as you go": {
        org_id: string;
        plan_kind?: PlanKind;
    };

    "downgraded from pay as you go": {
        org_id: string;
        plan_kind: PlanKind;
    };

    "learn more about pay as you go": {
        org_id: string;
        plan_kind: PlanKind;
    };
};

export type RevenueEvents = {
    "revenue enforcement overprojectlimit": {};
    "revenue enforcement overeditorlimit": {
        source: string;
    };
};

export type RichTooltipEvents = {
    tooltip_hover: { id: string };
    tooltip_click: { id: string };
    tooltip_video_click: { id: string };
    // played goes from 0 to 1, 1 means fully played.
    tooltip_video_close: { id: string; played: number; playedSeconds: number };
    tooltip_doc_click: { id: string };
};

type WithApp = { app_id: string };
type WithLabel = WithLabels<string>;
type WithLabels<T> = { label: T };

// FIXME: Add app to most of these

interface WithBranding {
    id: string;
    hero_image: string;
    hero_text: string;
    confirm_text: string;
    cancel_text: string;
    style: string;
    link_url: string;
    color: string;
}

export type AppAnalyticsEvents = {
    screen_view: WithApp & { screen_name: string };
    // FIXME: Add `WithApp`
    click: { event_category: "outbound"; event_label: string; transport_type?: "beacon" };

    upload_file: WithApp & { content_type: string; content_length: number };

    purchase: WithApp & {
        transaction_id?: string;
        value: number;
        currency: string;
        tax?: number;
        shipping?: string;
        items: number;
        coupon?: string;
    };

    "info opened": WithApp;
    "qr opened": WithApp;

    share: WithApp & { method?: string };

    "feedback opened": WithApp;
    "feedback sent": WithApp;

    "report opened": WithApp;
    "report sent": WithApp;

    web_view: WithApp & { url: string };

    "upload success": WithApp & WithLabel;
    "upload over quota": WithApp & WithLabel;
    "upload failed": WithApp & WithLabel;

    "zap sent": WithApp;
    "zap failure": WithApp;

    "location acquired": WithApp & { lat: number; long: number };
    "location failed": WithApp;

    login: WithApp & { app_user_id: string; method?: string };

    "in app branding selected": WithApp & { selected: boolean };
    "in app branding shown": WithApp & WithBranding;
    "in app branding clicked": WithApp & WithBranding & { converted: boolean; time_shown: number };

    "password logout": WithApp;

    "did right-click": {};
};

type WithPluginID = { plugin_id: string };
type WithAuthProviderID = { auth_provider_id: string };

export type PluginEvents = {
    "plugin turned on": WithApp & WithPluginID;
    "plugin turned off": WithApp & WithPluginID;
    "plugin removed": WithApp & WithPluginID;
    "plugin removal failed": WithApp & WithPluginID;
    "plugin connect started": WithApp & WithPluginID & WithAuthProviderID;
    "plugin connected": WithApp & WithPluginID & WithAuthProviderID;
    "plugin connected to team": WithAuthProviderID & { owner_id: string };
    "plugin connect failed": WithAuthProviderID;
    "plugin select from empty screen": WithApp & WithPluginID & { can_use: boolean };
    "plugin select from menu": WithApp & WithPluginID & { can_use: boolean; is_column: boolean };
};
