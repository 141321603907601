import { useRouteMatch } from "react-router-dom";

import type { AppPatternRouteProps } from "../routes";
import { isPlayer } from "../routes";

// If we render the player outside of Glide we may not have react-router around.
// In that case, set this `window` variable.
function isExternalPlayer(): boolean {
    return (window as any).isExternalGlidePlayer === true;
}

export const useIsPreview = () => {
    if (isExternalPlayer()) {
        return false;
    }
    // Here we're safe ignoring the ESLint rule cause we're not _really_ conditionally calling this hook.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useIsPreviewOrginal();
};

function useIsPreviewOrginal(): boolean {
    const routeProps = useRouteMatch<AppPatternRouteProps>();
    return routeProps?.params.page === "live-preview";
}

export function useIsPlayerOrPreview(): boolean {
    const isPreview = useIsPreview();
    return isPlayer() || isExternalPlayer() || isPreview;
}
