import * as React from "react";
import type { Subtract } from "utility-types";
import { isFirebaseUserVerified, onFirebaseIdTokenChanged } from "@glide/firebase-stuff";

const VerifiedUserContext = React.createContext<boolean>(false);

export const VerifiedUserProvider: React.FC<React.PropsWithChildren> = p => {
    const [isVerified, setIsVerified] = React.useState(isFirebaseUserVerified());

    React.useEffect(() => {
        const cleanup = onFirebaseIdTokenChanged(user => {
            if (user === null) {
                return;
            }

            const newIsVerified = user.emailVerified ?? false;
            setIsVerified(newIsVerified);
        });

        return cleanup;
    }, []);

    return <VerifiedUserContext.Provider value={isVerified}>{p.children}</VerifiedUserContext.Provider>;
};

export function useIsUserVerified(): boolean {
    return React.useContext(VerifiedUserContext);
}

interface WithIsUserVerifiedProps {
    isUserVerified: boolean;
}

export const withIsUserVerified =
    <P extends WithIsUserVerifiedProps>(
        Component: React.ComponentType<P>
    ): React.FC<Subtract<P, WithIsUserVerifiedProps>> =>
    p => {
        const isVerified = useIsUserVerified();

        return <Component {...(p as P)} isUserVerified={isVerified} />;
    };

interface MockedVerifiedUserProviderProps extends React.PropsWithChildren {
    verified: boolean;
}

export const MockedVerifiedUserProvider: React.FC<MockedVerifiedUserProviderProps> = ({ verified, children }) => {
    return <VerifiedUserContext.Provider value={verified}>{children}</VerifiedUserContext.Provider>;
};
