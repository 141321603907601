import "twin.macro";

import { GlideIcon } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { PortalObserver } from "@glide/common-core/dist/js/components/portal-observer/portal-observer";
import type { WireBreadcrumb, WireBreadcrumbsComponent } from "@glide/fluent-components/dist/js/base-components";
import { mapFilterUndefined } from "@glideapps/ts-necessities";
import { isDefined, isEmptyOrUndefinedish } from "@glide/support";
import * as React from "react";

import { runActionAndHandleURL, useTitleBreadcrumbsPortalId } from "../../wire-lib";
import type { WireRenderer } from "../wire-renderer";

export const Breadcrumbs: WireRenderer<
    WireBreadcrumbsComponent,
    { breadcrumbShouldPortal: boolean; className?: string }
> = React.memo(p => {
    const {
        crumbsOmitted,
        crumbs,
        backend: { appKind },
        breadcrumbShouldPortal,
        className,
    } = p;
    const portalId = useTitleBreadcrumbsPortalId();
    const crumbsToRender = React.useMemo(() => {
        const toRender: (WireBreadcrumb | null)[] = [...crumbs];
        if (crumbsOmitted) {
            toRender.splice(1, 0, null);
        }
        return toRender;
    }, [crumbs, crumbsOmitted]);

    const content = React.useMemo(() => {
        return (
            <div data-testid="wire-breadcrumbs" className={className}>
                <div tw="flex items-center flex-wrap">
                    {p.backAction !== undefined && (
                        <button
                            onClick={() => runActionAndHandleURL(p.backAction, p.backend)}
                            tw="text-text-contextual-pale mr-8 text-base hover:(text-text-contextual-dark)
                            focus-visible:(font-semibold text-text-contextual-dark underline) flex items-center"
                        >
                            <GlideIcon
                                tw="text-text-contextual-xpale inline-flex mr-0.5"
                                kind="stroke"
                                icon="st-caret"
                                strokeWidth={1.5}
                                rotateDeg={90}
                                iconSize={12}
                            />
                            {getLocalizedString("back", appKind)}
                        </button>
                    )}
                    {mapFilterUndefined(crumbsToRender, (c, i) => {
                        if (c === null) {
                            return (
                                <React.Fragment key={i}>
                                    <div tw="text-text-contextual-xpale">...</div>
                                    <GlideIcon
                                        tw="text-text-contextual-xpale mx-2"
                                        kind="stroke"
                                        icon="st-caret"
                                        strokeWidth={2}
                                        rotateDeg={-90}
                                        iconSize={12}
                                    />
                                </React.Fragment>
                            );
                        }
                        if (isEmptyOrUndefinedish(c.title)) return undefined;
                        const onClick = isDefined(c.action)
                            ? () => runActionAndHandleURL(c.action, p.backend)
                            : undefined;
                        return (
                            <React.Fragment key={i}>
                                <button
                                    tw="text-text-contextual-pale shrink hover:(text-text-contextual-dark)
                                    focus-visible:(text-text-contextual-dark font-semibold underline) text-base inline-flex items-center"
                                    onClick={onClick}
                                >
                                    <span tw="min-w-0 truncate">{c.title}</span>
                                    <GlideIcon
                                        tw="text-text-contextual-xpale mx-2"
                                        kind="stroke"
                                        icon="st-caret"
                                        strokeWidth={1.5}
                                        rotateDeg={-90}
                                        iconSize={12}
                                    />
                                </button>
                            </React.Fragment>
                        );
                    })}
                    <span tw="text-text-contextual-dark text-base font-semibold shrink min-w-0 truncate">
                        {p.currentPageTitle}
                    </span>
                </div>
            </div>
        );
    }, [className, p.backAction, p.currentPageTitle, p.backend, appKind, crumbsToRender]);

    if (breadcrumbShouldPortal) {
        // We're portaling breadcrumbs inside the Title Component in some cases
        // using forceDocumentObserver prevents the observer to clean up the breadcrumbs
        // when we change between styles in the builder
        return (
            <PortalObserver forceDocumentObserver selector={`#${portalId}`}>
                {content}
            </PortalObserver>
        );
    }

    return content;
});
