import { getMemoizeTheme, type ThemePlatform, useIsOSThemeDark, useIsWireThemeDark } from "@glide/common";
import { AppKind } from "@glide/location-common";
import { DeviceFormFactor, PagePreviewDevice } from "@glide/common-core/dist/js/render/form-factor";
import * as React from "react";
import { PlayerSmartphone } from "sharedUI/ui/views/smartphone/smartphone";
import { ThemedModalRoot } from "sharedUI/ui/views/themed-modal-root";
import AppContainer from "webapp/chrome/smart/app-container/app-container";
import { PageContainer } from "./template-styles";
import { usePreviewerState } from "./usePreviewerState";
import { useTemplateDataGridVisibility } from "./useTemplateDataGridVisibility";
import StatusBar from "webapp/chrome/views/status-bar/status-bar";
import type { BaseTheme } from "@glide/base-theme";

type AppContainerType = React.ComponentProps<typeof AppContainer>;
interface TemplatesAppContainer extends AppContainerType {
    appID: string;
    theme: BaseTheme;
    appKind: AppKind;
}

export const TemplateAppContainer: React.FC<React.PropsWithChildren<TemplatesAppContainer>> = p => {
    const {
        appFeatures,
        authenticator,
        title,
        iconImage,
        theme: baseTheme,
        appID,
        showBranding,
        showingApp,
        useFrame,
    } = p;

    const { appKind, previewDevice } = usePreviewerState();
    const isOSThemeDark = useIsOSThemeDark();

    const theme = React.useMemo(
        () =>
            getMemoizeTheme(
                true,
                baseTheme,
                "iOS",
                previewDevice === PagePreviewDevice.Phone ? DeviceFormFactor.Phone : DeviceFormFactor.Tablet,
                false,
                isOSThemeDark
            ),
        [baseTheme, previewDevice, isOSThemeDark]
    );

    const deviceFormFactor =
        previewDevice === PagePreviewDevice.Phone ? DeviceFormFactor.Phone : DeviceFormFactor.Tablet;

    return (
        <TemplateKindContainer appKind={appKind} deviceFormFactor={deviceFormFactor} previewTheme="iOS" theme={theme}>
            <AppContainer
                appFeatures={appFeatures}
                authenticator={authenticator}
                forceTheme="iOS"
                title={title}
                iconImage={iconImage}
                theme={theme}
                appID={appID}
                isBuilder={true}
                showBranding={showBranding}
                showingApp={showingApp}
                useFrame={useFrame}
                deviceFormFactor={deviceFormFactor}
            >
                {p.children}
            </AppContainer>
        </TemplateKindContainer>
    );
};

interface TemplateKindContainer {
    appKind: AppKind;
    theme: BaseTheme;
    deviceFormFactor: DeviceFormFactor;
    previewTheme: ThemePlatform;
}

// FIXME: This should be more of a global constant. cc Tristan
const MOBILE_BREAKPOINT = 640;

export const TemplateKindContainer: React.FC<React.PropsWithChildren<TemplateKindContainer>> = p => {
    const { theme, appKind, deviceFormFactor } = p;
    const isDataGridVisible = useTemplateDataGridVisibility();
    const isWireThemeDark = useIsWireThemeDark(theme.pageEnvironment);
    const tablet = deviceFormFactor === DeviceFormFactor.Tablet;

    let round = 39;
    if (tablet) {
        round = 18;
    }

    const modalRoot = (
        <ThemedModalRoot
            isPages={false}
            baseTheme={theme}
            platform="iOS"
            deviceFormFactor={tablet ? DeviceFormFactor.Tablet : DeviceFormFactor.Phone}
        />
    );

    // if we are viewing the template preview on a mobile device
    // then we don't to render the "phone in phone" view
    // note - there is a hook of the same name in storybook-utils,
    // but it requires a responsive provider wrapper
    const isSmallScreen = window.innerWidth < MOBILE_BREAKPOINT;
    return (appKind === AppKind.Page && deviceFormFactor !== DeviceFormFactor.Phone) || isSmallScreen ? (
        <PageContainer id="page-container">{p.children}</PageContainer>
    ) : (
        <PlayerSmartphone
            modalRoot={modalRoot}
            onHide={() => ({})}
            setShowOverlay={() => ({})}
            showOverlay={false}
            hidden={isDataGridVisible}
            round={round}
            tablet={tablet}
            theme={theme}
        >
            <StatusBar
                themeOverride={theme}
                appKind={appKind}
                isSplash={false}
                isPageLight={theme.pageTheme === "Highlight" && !isWireThemeDark}
            />
            {p.children}
        </PlayerSmartphone>
    );
};
