/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

// Define constants
const DEFAULT_API_HOST = "https://us.i.posthog.com";

export const plugin = glide.newPlugin({
    id: "posthog",
    name: "PostHog",
    description: "Track user behavior and product analytics with PostHog",

    tier: "starter",
    icon: "https://res.cloudinary.com/glide/image/upload/v1742213327/glideapps.com/integrations/PostHog.png",
    parameters: {
        apiKey: glide.makeParameter({
            type: "string",
            name: "Project API Key",
            description:
                "Your PostHog project API key found in your [project settings](https://app.posthog.com/project/settings)",
            placeholder: "e.g. phc_xxxxxxxxxxxxxxxxxxxx",
            required: true,
        }),
        apiHost: glide.makeParameter({
            type: "string",
            name: "API Host",
            description: "Your PostHog API host",
            placeholder: DEFAULT_API_HOST,
            defaultValue: DEFAULT_API_HOST,
            required: true,
        }),
        personProfiles: glide.makeParameter({
            type: "enum",
            name: "Person Profiles",
            description: "When to create person profiles",
            values: [
                { value: "identified_only", label: "Identified Only" },
                { value: "always", label: "Always" },
            ],
            required: false,
        }),
    },
    documentationUrl: "https://www.glideapps.com/docs/posthog",
});

plugin.setEventTracker((_params, event) => {
    const { posthog } = window as any;
    if (posthog === undefined) return;
    switch (event.kind) {
        case "load":
            posthog.capture("$pageview");
            break;
        case "identify":
            posthog.identify(event.userID, {
                username: event.username,
                email: event.email,
            });
            break;
        case "navigate":
            posthog.capture("$pageview", {
                title: event.title,
            });
            break;
        case "action":
            posthog.capture(event.name, event.data);
            break;
    }
});

plugin.addHeader(({ apiKey, apiHost = DEFAULT_API_HOST, personProfiles }) => {
    // Validate apiKey format (should start with 'phc_' followed by alphanumeric characters)
    const isValidApiKey = typeof apiKey === "string" && /^phc_[a-zA-Z0-9]+$/.test(apiKey);

    // Validate apiHost using URL constructor
    let isValidApiHost = false;
    try {
        const url = new URL(apiHost);
        // Ensure it's using https protocol
        isValidApiHost = url.protocol === "https:";
    } catch (e: unknown) {
        // URL constructor throws if the URL is invalid
        isValidApiHost = false;
    }

    // Validate personProfiles (should be either undefined, empty string, "identified_only", or "always")
    const isValidPersonProfiles =
        personProfiles === undefined ||
        personProfiles === "" ||
        personProfiles === "identified_only" ||
        personProfiles === "always";

    // If any validation fails, return a script that displays an error
    if (!isValidApiKey || !isValidApiHost || !isValidPersonProfiles) {
        let errorMessage = "PostHog initialization error: ";
        if (!isValidApiKey) errorMessage += "Invalid API key format. ";
        if (!isValidApiHost) errorMessage += "Invalid API host format. ";
        if (!isValidPersonProfiles) errorMessage += "Invalid person profiles value. ";

        return `<script>
            console.error("${errorMessage}");
        </script>`;
    }

    // Create options object for PostHog initialization
    const options: Record<string, string> = {
        api_host: apiHost,
    };

    // Add person_profiles if it has a valid value
    if (personProfiles === "identified_only" || personProfiles === "always") {
        options.person_profiles = personProfiles;
    }

    // Convert options to JSON string
    const optionsJson = JSON.stringify(options);

    return `<script>
    !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.crossOrigin="anonymous",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="init capture register register_once register_for_session unregister unregister_for_session getFeatureFlag getFeatureFlagPayload isFeatureEnabled reloadFeatureFlags updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures on onFeatureFlags onSessionId getSurveys getActiveMatchingSurveys renderSurvey canRenderSurvey identify setPersonProperties group resetGroups setPersonPropertiesForFlags resetPersonPropertiesForFlags setGroupPropertiesForFlags resetGroupPropertiesForFlags reset get_distinct_id getGroups get_session_id get_session_replay_url alias set_config startSessionRecording stopSessionRecording sessionRecordingStarted captureException loadToolbar get_property getSessionProperty createPersonProfile opt_in_capturing opt_out_capturing has_opted_in_capturing has_opted_out_capturing clear_opt_in_out_capturing debug getPageViewId captureTraceFeedback captureTraceMetric".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
    posthog.init('${apiKey}', ${optionsJson});
</script>`;
});

const eventName = glide.makeParameter({
    type: "string",
    name: "Event Name",
    description: "The name of the event to track",
    placeholder: "e.g. button_clicked",
    required: true,
    useTemplate: "withLabel",
});

const eventProperties = glide.makeParameter({
    type: "stringObject",
    name: "Properties",
    description: "Event properties to include with the event",
});

plugin.addClientAction({
    id: "track-event",
    name: "Track Event",
    description: "Track a custom event in PostHog",
    parameters: { eventName, eventProperties },
    needsClient: true,
    async execute(ctx, { eventName, eventProperties }) {
        const { posthog } = window as any;

        // If we're in the builder, log an error but do not fail
        if (window.location.hostname === "go.glideapps.com") {
            ctx.error("PostHog is not available in the builder. Publish your app to track events.");
            return glide.Result.Ok();
        }

        if (posthog === undefined) {
            return glide.Result.FailPermanent("Cannot track, PostHog not initialized.", {
                isPluginError: false, // ad blockers can cause this
            });
        }

        posthog.capture(eventName, eventProperties);

        return glide.Result.Ok();
    },
});
