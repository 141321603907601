import { fetchPageMetadata, tryShortenLinkToHostname } from "@glide/support";

import { getFeatureFlag } from "../feature-flags";
import type { Icon } from "@glide/app-description";

interface LinkRecognizer {
    readonly kind: string;
    readonly title?: (match: RegExpMatchArray) => string;
    readonly caption: string;
    readonly icon: Icon;
    readonly regex: RegExp;
}

const youTubeRegex = /^(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/;
const linkRecognizers: LinkRecognizer[] = [
    {
        kind: "youTube",
        caption: "YouTube",
        icon: "youtube",
        regex: youTubeRegex,
    },
    { kind: "wikipedia", caption: "Wikipedia", icon: "globe", regex: /^(?:https?:\/\/)?(?:[^./]+\.)?wikipedia\.org/ },
    { kind: "twitter", caption: "Twitter", icon: "twitter", regex: /^(?:https?:\/\/)?(?:[^./]+\.)?twitter\.com/ },
    { kind: "github", caption: "GitHub", icon: "github", regex: /^(?:https?:\/\/)?(?:[^./]+\.)?github\.com/ },
    { kind: "gitlab", caption: "GitLab", icon: "gitlab", regex: /^(?:https?:\/\/)?(?:[^./]+\.)?gitlab\.com/ },
    { kind: "facebook", caption: "Facebook", icon: "facebook", regex: /^(?:https?:\/\/)?(?:[^./]+\.)?facebook\.com/ },
    { kind: "linkedin", caption: "LinkedIn", icon: "linkedin", regex: /^(?:https?:\/\/)?(?:[^./]+\.)?linkedin\.com/ },
    { kind: "codepen", caption: "CodePen", icon: "codepen", regex: /^(?:https?:\/\/)?(?:[^./]+\.)?codepen\.com/ },
    { kind: "slack", caption: "Slack", icon: "slack", regex: /^(?:https?:\/\/)?(?:[^./]+\.)?slack\.com/ },
    {
        kind: "instagram",
        caption: "Instagram",
        icon: "instagram",
        regex: /^(?:https?:\/\/)?(?:[^./]+\.)?instagram\.com/,
    },
    { kind: "telephone", title: m => m[1], caption: "Phone", icon: "phone", regex: /^tel:(.+)/ },
    { kind: "message", title: m => m[1], caption: "Message", icon: "message-circle", regex: /^sms:(.+)/ },
];

interface RecognizedLink {
    readonly kind: string;
    readonly title: string | undefined;
    readonly caption: string | undefined;
    readonly icon: Icon;
}

export function recognizeLink(url: string): RecognizedLink {
    for (const r of linkRecognizers) {
        const match = url.match(r.regex);
        if (match === null) continue;

        return {
            kind: r.kind,
            title: r.title?.(match),
            caption: r.caption,
            icon: r.icon,
        };
    }
    return {
        kind: "unknown",
        title: undefined,
        caption: undefined,
        icon: "link",
    };
}

export async function fetchPageTitle(url: string): Promise<string> {
    let title = tryShortenLinkToHostname(url);

    const response = await fetchPageMetadata(url, getFeatureFlag("useLocalPageMetadataService"));
    if (response !== undefined) {
        let maybeTitle = response.title;
        maybeTitle = maybeTitle.trim();
        if (maybeTitle.length > 0) {
            title = maybeTitle;
        }
    }

    return title;
}
