import type { DescriptionVisitor } from "@glide/app-description";
import { walkDescriptionUntyped } from "@glide/app-description";
import { assertNever, definedMap } from "@glideapps/ts-necessities";
import { FormulaKind } from "@glide/type-schema";
import type {
    Formula,
    ApplyColumnFormatFormula,
    ArrayContainsFormula,
    AssignVariablesFormula,
    CheckValueFormula,
    ConstructURLFormula,
    ConvertToTypeFormula,
    FilterRowsFormula,
    FilterSortLimitFormula,
    FindRowFormula,
    FormatDateTimeFormula,
    FormatDurationFormula,
    FormatJSONFormula,
    FormatNumberFixedFormula,
    GenerateImageFormula,
    GeocodeAddressFormula,
    GetNthFormula,
    IfThenElseFormula,
    IsInRangeFormula,
    JoinStringsFormula,
    LeftRightFormula,
    MakeArrayFormula,
    MapRowsFormula,
    NotFormula,
    PluginComputationFormula,
    QueryParametersFormulas,
    RandomPickFormula,
    ReduceFormula,
    ReduceToMemberByFormula,
    SplitStringFormula,
    StartOrEndOfDayFormula,
    TextTemplateFormula,
    UnaryMathFormula,
    UserAPIFetchFormula,
    WithFormula,
    WithUserEnteredTextFormula,
    YesCodeFormula,
} from "@glide/type-schema";

export function rewriteFormula<T>(
    rootFormula: Formula,
    rewrite: (f: Formula, subResults: readonly T[]) => [Formula, T],
    // `undefined means don't rewrite descriptions
    descriptionVisitor: DescriptionVisitor | undefined
): [Formula, T] {
    function replace(formula: Formula): [Formula, T] {
        const subResults: T[] = [];

        function recur(f: Formula): Formula;
        function recur(f: Formula | undefined): Formula | undefined;
        function recur(f: Formula | undefined): Formula | undefined {
            if (f === undefined) return undefined;
            const [replaced, sub] = replace(f);
            subResults.push(sub);
            return replaced;
        }

        function replaceQueryParameters(params: QueryParametersFormulas): QueryParametersFormulas {
            return params.map(p => ({ name: recur(p.name), value: recur(p.value) }));
        }

        function mutate<F extends Formula>(f: (formula: F) => Partial<F>): [Formula, T] {
            const resultFormula = { ...(formula as F), ...f(formula as F) };
            return rewrite(resultFormula, subResults);
        }

        switch (formula.kind) {
            case FormulaKind.FilterRows:
            case FormulaKind.FindRow:
                return mutate<FilterRowsFormula | FindRowFormula>(f => ({
                    rows: recur(f.rows),
                    predicate: recur(f.predicate),
                }));
            case FormulaKind.MapRows:
                return mutate<MapRowsFormula>(f => ({
                    rows: recur(f.rows),
                    function: recur(f.function),
                }));
            case FormulaKind.Reduce:
                return mutate<ReduceFormula>(f => ({
                    rows: recur(f.rows),
                    value: recur(f.value),
                }));
            case FormulaKind.ReduceToMemberBy:
                return mutate<ReduceToMemberByFormula>(f => ({
                    rows: recur(f.rows),
                    key: recur(f.key),
                    value: recur(f.value),
                }));
            case FormulaKind.JoinStrings:
                return mutate<JoinStringsFormula>(f => ({
                    rows: recur(f.rows),
                    value: recur(f.value),
                    separator: recur(f.separator),
                }));
            case FormulaKind.SplitString:
                return mutate<SplitStringFormula>(f => ({
                    string: recur(f.string),
                    separator: recur(f.separator),
                }));
            case FormulaKind.IfThenElse:
                return mutate<IfThenElseFormula>(f => ({
                    condition: recur(f.condition),
                    consequent: recur(f.consequent),
                    alternative: recur(f.alternative),
                }));
            case FormulaKind.CompareValues:
            case FormulaKind.ArraysOverlap:
            case FormulaKind.BinaryMath:
            case FormulaKind.And:
            case FormulaKind.Or:
            case FormulaKind.GeoDistance:
                return mutate<LeftRightFormula>(f => ({
                    left: recur(f.left),
                    right: recur(f.right),
                }));
            case FormulaKind.MakeArray:
                return mutate<MakeArrayFormula>(f => ({
                    items: f.items.map(i => recur(i)),
                }));
            case FormulaKind.GetColumn:
            case FormulaKind.Random:
            case FormulaKind.Constant:
            case FormulaKind.Empty:
            case FormulaKind.SpecialValue:
            case FormulaKind.CurrentLocation:
            case FormulaKind.GetContext:
            case FormulaKind.GetVariable:
            case FormulaKind.GetUserProfileRow:
            case FormulaKind.GetTableRows:
            case FormulaKind.GetActionNodeOutput:
                return rewrite(formula, []);
            case FormulaKind.TextTemplate:
                return mutate<TextTemplateFormula>(f => ({
                    template: recur(f.template),
                    replacements: f.replacements.map(r => ({
                        pattern: recur(r.pattern),
                        replacement: recur(r.replacement),
                    })),
                }));
            case FormulaKind.ArrayContains:
                return mutate<ArrayContainsFormula>(f => ({
                    array: recur(f.array),
                    item: recur(f.item),
                }));
            case FormulaKind.CheckValue:
            case FormulaKind.Not:
            case FormulaKind.ConvertToType:
            case FormulaKind.ApplyColumnFormat:
                return mutate<CheckValueFormula | NotFormula | ConvertToTypeFormula | ApplyColumnFormatFormula>(f => ({
                    value: recur(f.value),
                }));
            case FormulaKind.StartOfDay:
            case FormulaKind.EndOfDay:
                return mutate<StartOrEndOfDayFormula>(f => ({
                    dateTime: recur(f.dateTime),
                }));
            case FormulaKind.With:
                return mutate<WithFormula>(f => ({
                    context: recur(f.context),
                    value: recur(f.value),
                }));
            case FormulaKind.GetNth:
            case FormulaKind.GetNthLast:
                return mutate<GetNthFormula>(f => ({
                    array: recur(f.array),
                    index: recur(f.index),
                }));
            case FormulaKind.RandomPick:
                return mutate<RandomPickFormula>(f => ({
                    array: recur(f.array),
                }));
            case FormulaKind.IsInRange:
                return mutate<IsInRangeFormula>(f => ({
                    value: recur(f.value),
                    start: recur(f.start),
                    end: recur(f.end),
                }));
            case FormulaKind.AssignVariables:
                return mutate<AssignVariablesFormula>(f => ({
                    body: recur(f.body),
                    assignments: f.assignments.map(([n, v]) => [n, recur(v)]),
                }));
            case FormulaKind.UnaryMath:
                return mutate<UnaryMathFormula>(f => ({
                    operand: recur(f.operand),
                }));
            case FormulaKind.WithUserEnteredText:
                return mutate<WithUserEnteredTextFormula>(f => ({
                    formula: recur(f.formula),
                }));
            case FormulaKind.FormatNumberFixed:
                return mutate<FormatNumberFixedFormula>(f => ({
                    value: recur(f.value),
                    decimalsAfterPoint: recur(f.decimalsAfterPoint),
                    groupSeparator: recur(f.groupSeparator),
                    currency: recur(f.currency),
                }));
            case FormulaKind.FormatDateTime:
                return mutate<FormatDateTimeFormula>(f => ({
                    value: recur(f.value),
                    dateFormat: recur(f.dateFormat),
                    timeFormat: recur(f.timeFormat),
                }));
            case FormulaKind.FormatDuration:
                return mutate<FormatDurationFormula>(f => ({
                    value: recur(f.value),
                }));
            case FormulaKind.FormatJSON:
                return mutate<FormatJSONFormula>(f => ({
                    value: recur(f.value),
                }));
            case FormulaKind.GeocodeAddress:
                return mutate<GeocodeAddressFormula>(f => ({
                    address: recur(f.address),
                }));
            case FormulaKind.GenerateImage:
                return mutate<GenerateImageFormula>(f => ({
                    input: recur(f.input),
                    imageKind: recur(f.imageKind),
                }));
            case FormulaKind.UserAPIFetch:
                return mutate<UserAPIFetchFormula>(f => ({
                    webhookID: recur(f.webhookID),
                    params: replaceQueryParameters(f.params),
                }));
            case FormulaKind.ConstructURL:
                return mutate<ConstructURLFormula>(f => ({
                    scheme: recur(f.scheme),
                    host: recur(f.host),
                    path: recur(f.path),
                    params: replaceQueryParameters(f.params),
                }));
            case FormulaKind.YesCode:
                return mutate<YesCodeFormula>(f => ({
                    url: recur(f.url),
                    params: replaceQueryParameters(f.params),
                }));
            case FormulaKind.FilterSortLimit:
                return mutate<FilterSortLimitFormula>(f => ({
                    rows: recur(f.rows),
                    predicate: definedMap(f.predicate, recur),
                    orderings: f.orderings.map(o => ({ ...o, sortKey: definedMap(o.sortKey, recur) })),
                    limit: definedMap(f.limit, recur),
                }));
            case FormulaKind.PluginComputation:
                return mutate<PluginComputationFormula>(f => ({
                    pluginID: recur(f.pluginID),
                    computationID: recur(f.computationID),
                    parameters:
                        definedMap(descriptionVisitor, v => walkDescriptionUntyped(f.parameters ?? {}, v)) ??
                        f.parameters,
                    resultName: recur(f.resultName),
                }));
            default:
                return assertNever(formula.kind);
        }
    }

    return replace(rootFormula);
}
