import {
    type ColumnType,
    type Formula,
    type TableGlideType,
    makeTableRef,
    getTableColumn,
    makeArrayType,
    makeUniversalTableRef,
    type SchemaInspector,
} from "@glide/type-schema";
import { decomposeFilterReferenceFormula } from "@glide/formula-specifications";
import { type CompileResult, compileFormulaWithCodeGenerator } from "./compiler";
import type { TypeForActionNodeOutputGetter } from "../static-context";
import { definedMap } from "@glideapps/ts-necessities";

function compileSpecialCase(
    builder: SchemaInspector,
    formula: Formula,
    hostTable: TableGlideType
): CompileResult | undefined {
    const filter = decomposeFilterReferenceFormula(formula);
    if (filter === undefined) return undefined;

    const {
        multiple,
        targetTable: targetUniversalTableName,
        targetColumn: targetColumnName,
        hostColumn: hostColumnName,
    } = filter;

    const hostColumn = getTableColumn(hostTable, hostColumnName);
    if (hostColumn === undefined) return undefined;

    const targetTable = builder.findTable(targetUniversalTableName);
    if (targetTable === undefined) return undefined;

    const targetColumn = getTableColumn(targetTable, targetColumnName);
    if (targetColumn === undefined) return undefined;

    let type: ColumnType = makeUniversalTableRef(targetUniversalTableName);

    if (multiple) {
        type = makeArrayType(type);
    }

    return { success: true, type, typeIsPrecise: true, geocodeQuotaKeys: new Set() };
}

export function compileFormula(
    builder: SchemaInspector,
    outerTable: TableGlideType | undefined,
    formula: Formula,
    getTypeForActionNodeOutput: TypeForActionNodeOutputGetter | undefined
): CompileResult {
    // logInfo("compiling formula", formula, initialBindings, boundContexts);

    if (outerTable !== undefined) {
        const specialCaseResult = compileSpecialCase(builder, formula, outerTable);
        if (specialCaseResult !== undefined) {
            return specialCaseResult;
        }
    }

    return compileFormulaWithCodeGenerator(
        builder,
        definedMap(outerTable, makeTableRef),
        formula,
        getTypeForActionNodeOutput
    );
}
