import type { AppAuthenticationKind, IconImage } from "@glide/app-description";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import { ResponsiveProvider } from "@glide/common-components";
import type { Dispatch, SetStateAction } from "react";
import type { FirebaseAppUserAuthenticator } from "sharedUI/lib/app-user-authenticator";
import type { PagesLoginSource } from "@glide/auth-controller-core";
import type { AuthControllerProps } from "../chrome/smart/auth-controller/auth-controller";
import AuthController from "../chrome/smart/auth-controller/auth-controller";
import * as React from "react";
import { AuthWrapper } from "@glide/auth-controller";

const NewAuthController = React.lazy(() => import("../chrome/smart/auth-controller/new-auth-controller"));

interface PagesAuthScreenProps {
    authRequirements: "none" | "try-email" | AppAuthenticationKind;
    authProps: Omit<AuthControllerProps, "onLoggedIn" | "isOSThemeDark">;
    onAuthLoggedIn: (_email: string) => Promise<void>;
    authenticator: FirebaseAppUserAuthenticator | undefined;
    dontTryEmail: () => void;
    iconImage?: IconImage;
    submitPasscode: (
        authenticator: FirebaseAppUserAuthenticator,
        passcode: string,
        loginToken: AppLoginTokenContainer | undefined
    ) => Promise<boolean>;
}

export const PagesAuthScreen: React.FC<React.PropsWithChildren<PagesAuthScreenProps>> = p => {
    const { authRequirements, authProps, onAuthLoggedIn, authenticator, dontTryEmail, iconImage, submitPasscode } = p;

    return (
        <ResponsiveProvider isRoot id="wire-custom-login" tw="w-full h-full">
            <AuthScreenContent
                authProps={authProps}
                authRequirements={authRequirements}
                authenticator={authenticator}
                onAuthLoggedIn={onAuthLoggedIn}
                dontTryEmail={dontTryEmail}
                iconImage={iconImage}
                submitPasscode={submitPasscode}
            />
        </ResponsiveProvider>
    );
};

const AuthScreenContent: React.FC<React.PropsWithChildren<PagesAuthScreenProps>> = props => {
    const {
        authProps: { flags: eminenceFlags, ...authProps },
        authRequirements,
        onAuthLoggedIn,
        authenticator,
        dontTryEmail,
        submitPasscode,
        iconImage,
    } = props;

    if (authProps.appFeatures.newAuthController === true) {
        const { appID, authKind, previewMode, getUsername, username, onClose, appFeatures, authMethod, pagesSource } =
            authProps;
        return (
            <AuthWrapper>
                <React.Suspense fallback={<></>}>
                    <NewAuthController
                        appID={appID}
                        authKind={authKind}
                        previewMode={previewMode}
                        getUsername={getUsername}
                        username={username}
                        onLoggedIn={onAuthLoggedIn}
                        submitPasscode={(p, t) =>
                            authenticator ? submitPasscode(authenticator, p, t) : Promise.resolve(false)
                        }
                        onClose={onClose}
                        onFailed={authRequirements === "try-email" ? dontTryEmail : undefined}
                        appFeatures={appFeatures}
                        iconImage={iconImage}
                        authMethod={authMethod}
                        pagesSource={pagesSource}
                        removeBranding={eminenceFlags?.removeBranding ?? false}
                        canCustomizeSignIn={eminenceFlags?.canCustomizeSignIn ?? false}
                    />
                </React.Suspense>
            </AuthWrapper>
        );
    }

    return (
        <AuthWrapper>
            <AuthController
                {...authProps}
                pagesSource={"main-sign-in"}
                onLoggedIn={onAuthLoggedIn}
                submitPasscode={(p, t) =>
                    authenticator ? submitPasscode(authenticator, p, t) : Promise.resolve(false)
                }
                onFailed={authRequirements === "try-email" ? dontTryEmail : undefined}
                iconImage={iconImage}
            />
        </AuthWrapper>
    );
};

interface AuthModalContentProps {
    authProps: Omit<AuthControllerProps, "onLoggedIn" | "flags" | "isOSThemeDark">;
    onAuthLoggedIn: (_email: string) => Promise<void>;
    iconImage?: IconImage;
    customCssClassName?: string;
    pagesSource: "main-sign-in" | "modal-sign-in" | "modal-sign-up";
    onPageSourceChanged: Dispatch<SetStateAction<PagesLoginSource>>;
    onFailed: () => void;
}

export const AuthModalContent: React.FC<React.PropsWithChildren<AuthModalContentProps>> = props => {
    const { authProps, iconImage, onAuthLoggedIn, customCssClassName, pagesSource, onPageSourceChanged, onFailed } =
        props;

    return (
        <AuthWrapper customCssClassName={customCssClassName}>
            <AuthController
                {...authProps}
                customCssClassName={customCssClassName}
                pagesSource={pagesSource}
                onPageSourceChanged={onPageSourceChanged}
                onLoggedIn={onAuthLoggedIn}
                onFailed={onFailed}
                iconImage={iconImage}
            />
        </AuthWrapper>
    );
};
