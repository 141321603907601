import * as React from "react";
import { SimpleWrapper, Wrapper } from "./floating-button-style";

export interface Props extends React.PropsWithChildren {
    isEnabled?: boolean;
    yOffset?: number;
    distance?: number;
    color?: string;
    dataTest?: string;
    className?: string;
    title?: string;

    onClick: () => void;
}

export default class FloatingButton extends React.PureComponent<Props> {
    public render(): React.ReactNode {
        const { dataTest, distance, yOffset, isEnabled, onClick, color, className, title } = this.props;

        return (
            <Wrapper
                className={className}
                color={color}
                data-test={dataTest}
                distance={distance ?? 16}
                yOffset={yOffset ?? 0}
                enabled={isEnabled !== false}
                onClick={onClick}
                title={title}>
                <div className="floating-btn-style">{this.props.children}</div>
            </Wrapper>
        );
    }
}

interface SimpleProps extends React.PropsWithChildren {
    isEnabled?: boolean;
    color?: string;
    dataTest?: string;
    className?: string;
    title?: string;

    onClick: () => void;
}

export const FloatingButtonSimple: React.FC<React.PropsWithChildren<SimpleProps>> = p => {
    const { dataTest, color, isEnabled, onClick, className, title } = p;

    return (
        <SimpleWrapper
            className={className}
            color={color}
            data-test={dataTest}
            enabled={isEnabled !== false}
            onClick={onClick}
            title={title}>
            <div className="floating-btn-style">{p.children}</div>
        </SimpleWrapper>
    );
};
