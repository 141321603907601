// NOTE: This is part of the lower level of the New Computation Model, and
// should be kept isolated from non-trivial Glide dependencies.  In
// particular, it shouldn't have to know anything about Glide table/column
// types/schemas.

import type { ErrorResult } from "@glide/plugins";
import type { ChangeObservable } from "@glide/support";
import type { GroundValue, Table, Row, LoadedGroundValue } from "./data";
import type { LoadingValue, LoadingValueWrapper } from "./loading-value";
import type { RootPath, Path, RelativePath } from "./paths";
import type { Query } from "./query-interface";

// One or more columns in a specific row are dirty.
interface RowDirt {
    readonly kind: "row";
    readonly rowID: string;
    // `true` means potentially all columns have changed
    readonly columns: ReadonlySet<string> | true;
}

// One or more columns in all rows are dirty.
interface TableDirt {
    readonly kind: "table";
    readonly columns: ReadonlySet<string> | true;
}

export type Dirt = RowDirt | TableDirt;

export const fullDirt: Dirt = { kind: "table", columns: true };

export interface RootPathResolver {
    // FIXME: Maybe there should be two interfaces, one for use
    // by recomputation, which asserts, and another, public
    // one, for consumers, which doesn't assert.
    get(path: RootPath, assertNotDirty?: boolean): GroundValue;

    // This will not do any fixup.
    fetchQuery(
        query: Query,
        // If the result of the query changes, the callback will be called. If
        // it returns `true`, then dependents will be marked dirty.
        callback: () => boolean,
        handler: Handler
    ): GroundValue;

    // If `maybeQuery` is not a query, then it is just returned as-is.
    // FIXME: Simplify
    resolveQueryWithFixup(
        maybeQuery: GroundValue,
        // The path from which the query is coming from.  If the result of the
        // query changes, dirt will be pushed as if coming from this path.
        path: Path,
        context: GroundValue,
        contextPath: RootPath | undefined,
        handler: Handler,
        asRow: boolean
    ): GroundValue;

    // TODO: Arguably this should be passed the handler, the `ErrorResult` and
    // the context, and then do the message formatting itself.
    setErrorFromComputation(result: ErrorResult): void;
}

export type OnChangeCallback = () => void;

export interface PerformanceCounters {
    readonly numPushes: number;
    readonly numProcesses: number;
    readonly pushDirtAccounts: Map<string, number>;
}

export interface Namespace extends RootPathResolver {
    pushDirt(handler: Handler, dirt: Dirt): void;
    // If a handler is dirtying itself then it has to tell the namespace that
    // it did, which will mark all its dependents.
    handlerWasDirtied(handler: Handler): void;

    // Async handlers must check whether they're still in the namespace before
    // calling back into it.
    hasHandler(handler: Handler): boolean;
    // ##computationModelKeepsChanging:
    // This is used in a hack in the query editor because we're not smart
    // enough for React.
    hasPath(path: RootPath): boolean;

    // This is used for debugging and timing.
    setAllDirty(): void;

    addSubscriber(paths: readonly RootPath[], onChange: OnChangeCallback): void;
    removeSubscriber(onChange: OnChangeCallback): void;

    retire(): void;
    isRetired: boolean;

    // Setting this to true will for all computations to happen synchronously
    // That should only be enabled for debugging situations
    readonly isStrictMode: boolean;

    // For debugging
    readonly numEntities: number;
    debugPrintGraph(): void;

    getAndResetPerformanceCounters(): PerformanceCounters;
}

export interface MutableNamespace<TMetadata> extends Namespace {
    addEntity(name: string, handler: Handler, metadata: TMetadata | undefined): RootPath;
    deleteEntity(path: RootPath): void;

    setErrorFromComputation(result: ErrorResult): void;
    getErrorFromComputation(): ErrorResult | undefined;
    resetErrorFromComputation(): void;

    setTimeOutAt(timeOutAt: number | undefined): void;
}

export class ComputationTimeoutException extends Error {}

export interface IncomingSlot {
    readonly sourcePath: RootPath;
    // Returns `true` if dirty needs to be propagated
    process(dirt: Dirt): boolean;
}

export interface Handler {
    // Used for debugging
    readonly isDirty: boolean;
    readonly symbolicRepresentation: string;

    getSlots(): readonly IncomingSlot[];

    // Handler must recompute if its own column is dirty, or any of its
    // dependencies are dirty.
    // ##wrapLoadingValues
    // It should return a wrapped value
    recompute(ns: Namespace): GroundValue;

    // This is called whenever a direct or indirect dependency is dirtied and
    // this handler's entity is not already dirty.
    dependenciesAreDirty?(): void;

    // Mark as fully dirty, so the next recompute will be total.
    // Used after adding to the namespace, and for timing performance.
    setDirty(): void;

    // Experimental: used for continuously updating values, such
    // as current time.
    connect?(ns: Namespace): void;
    disconnect?(ns: Namespace): void;
}

export interface QueryResolveInfo {
    readonly handler: Handler;
    readonly contextPath: RootPath | undefined;
}

export interface ConditionValuePath {
    readonly path: Path;
    readonly inHostRow: boolean;
}

export interface ComputationValueGetters {
    getValueAt: (ns: RootPathResolver, context: GroundValue, p: Path) => GroundValue;
    follow: (gv: GroundValue, path: RelativePath) => GroundValue;
    getRowColumn: (r: Row, c: string) => GroundValue;
    resolveQueryWithFixup: (
        ns: RootPathResolver,
        maybeQuery: GroundValue,
        path: Path,
        context: GroundValue,
        contextPath: RootPath | undefined,
        handler: Handler,
        asRow: boolean
    ) => GroundValue;
    makePathOrGroundValueGetter: (
        ns: RootPathResolver,
        context: GroundValue,
        hostRow: Row | undefined
    ) => (pov: ConditionValuePath) => GroundValue;
}

export interface Computation {
    readonly symbolicRepresentation: string;

    getPaths(): readonly Path[];
    // FIXME: We're making a closure each time we pass in `setAllDirty`, and
    // then sometimes the computations themselves make another closure
    // wrapping that.  Instead, we should just connect the computation to the
    // handler once, and then not have to pass in the resolver, either.
    compute(
        resolver: RootPathResolver,
        context: GroundValue,
        valueGetters: ComputationValueGetters,
        setAllDirty: () => void,
        qri: QueryResolveInfo
    ): GroundValue;
}

export interface AsyncComputation {
    readonly symbolicRepresentation: string;

    getPaths(): readonly Path[];
    // If an error occurs during the computation, this should throw an
    // exception.  The exception's message is then surfaced to the user.
    compute(
        resolver: RootPathResolver,
        context: GroundValue,
        valueGetters: ComputationValueGetters
    ): Promise<GroundValue | ChangeObservable<GroundValue>> | GroundValue;
}

export interface DirtyState {
    readonly dirtyRowIDs: ReadonlySet<string> | boolean;
    readonly dirtyColumns: ReadonlySet<string> | boolean;
    readonly isDirty: boolean;

    add(d: Dirt): boolean;
    addRowAndColumn(rowID: string, column: string | true): void;
    setAllDirty(): boolean;
    includesRow(rowID: string): boolean;
    includesColumn(column: string): boolean;
    includesPaths(paths: readonly Path[]): boolean;
    clear(): void;
    pushAndClear(ns: Namespace, handler: Handler): void;
    clone(): DirtyState;
}

// There are two types of aggregates:
// * Aggregates without state.  These have to be recomputed every time
//   there's a change to the value column.  Examples are sum, average,
//   count, count unique.
// * Aggregates with state.  These can update without full recompuation
//   sometimes.  In particular:
//
//   Minimum/maximum/minimum row: If the current extremum isn't
//   modified, and the modified row is not a new extremum, they don't
//   have to be updated at all.  If a new extremum is introduced, and
//   the aggregate is over the whole table, they can update directly to
//   that new extremum.

// This keeps the state for stateful aggregates.
export interface MapperState<T> {
    get(): T | undefined;
    set(v: T): void;
    // We're never actually calling this.  It's tempting to call it when a row
    // goes away, but the row might not actually have been deleted, as opposed
    // to just filtered out, and might come back again.  To do this correctly
    // the table keeper would have to keep track of these states, or at least
    // delete rows when they actually go away, but for now we accept the
    // memory leak.
    delete(): void;
}

// FIXME: Better name
export interface ProcessDirtProcessor {
    // This is only used by the single relation computation in the case
    // where the host column is global.  In that case it's an "other"
    // table, and whenever that is dirtied, we set the whole aggregate
    // table to dirty.
    setDirty(): boolean;
}

// ##wrapLoadingValues
// Methods in here return unwrapped values.
export interface TableAggregateDataProvider<T, D = undefined> extends MapperState<T> {
    readonly rootPathResolver: RootPathResolver;
    readonly loadingValueWrapper: LoadingValueWrapper;
    getAggregatedContainer(): GroundValue;
    getAggregatedTable(): Table | LoadingValue | undefined;
    deriveFromAggregatedTable(make: (t: Table) => D): D | LoadingValue | undefined;
    getContextRow(): Row | undefined;
    // Returns `true` if the aggregated table/array was defined and loaded,
    // and the iteration completes before timing out.
    //  - If `f` returns `LoadingValue`, the loop is aborted immediately and
    //    `LoadingValue` is returned.
    // - If the iteration takes too long, a ##resumableComputations
    //    `LoadingValue` may be returned
    forEachInAggregated(f: (v: LoadedGroundValue) => LoadingValue | undefined): true | LoadingValue | undefined;
    getAggregatedAsArray(): readonly GroundValue[] | LoadingValue | undefined;
}

// Aggregations over tables (including over multi-relations) must implement
// this.
export interface TableAggregateComputation<T, D = undefined> {
    // This should be `true` iff the result of the aggregation does not depend
    // on anything in the containing row other than the container itself.  For
    // example, when getting a Single Value at an offset from a relation, if
    // the offset comes from the containing row, this should be `false`, but
    // if the offset is a global value, this should be `true` because in the
    // latter case the result for the same relation will be the same no matter
    // which row it's contained in.  That's also an example where we can
    // ##cacheAggregatesWithOtherInputs.
    readonly canCacheResultsForContainer: boolean;

    // Only used for debugging.
    readonly displayName: string;
    readonly symbolicRepresentation: string;

    // Any root paths contained in either of these will be treated as "other"
    // dirt.
    getContextPaths(): readonly Path[];
    getAggregatePaths(): readonly Path[];
    // This is called whenever dirt comes in that's not for the main table.
    // The `SingleRelationComputation`, for example, can work with a global
    // "host" column.  If that gets dirt, this is called.
    processOtherDirt(proc: ProcessDirtProcessor, d: Dirt): boolean;
    // Recompute whatever is necessary given `aggregatedTableDirtyState`.
    // FIXME: Right now `contextTableDirtyState` isn't used anywhere.
    // Investigate whether it's even potentially useful.
    // ##wrapLoadingValues
    // This should produce an unwrapped value.
    recompute(
        provider: TableAggregateDataProvider<T, D>,
        aggregatedTableDirtyState: DirtyState,
        contextTableDirtyState: DirtyState | undefined,
        setAllDirty: () => void,
        qri: QueryResolveInfo
    ): GroundValue;

    // If `undefined` is returned, then the aggregate doesn't support queries.
    // We shouldn't be creating those in the computation model in the first
    // place.
    // ##wrapLoadingValues
    // This should produce an unwrapped value as well.
    makeQuery(tableQuery: Query, provider: TableAggregateDataProvider<T, D>): Query | LoadingValue | undefined;
}
