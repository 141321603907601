import { GlideIcon } from "@glide/common";
import { trackEvent } from "@glide/common-core/dist/js/analytics";
import type { addableDataSources } from "@glide/component-utils";
import { objectEntries } from "@glide/support";
import React from "react";
import "twin.macro";
import { useIsDarkTheme } from "../../../../hooks/use-builder-theme";
import { DataSourceIcon } from "../../../data-sources/data-source-icon";
import { ImportSourceTile, ImportTitlePill } from "../../../data-sources/import-from-external-source";
import type { TemplateImageProps } from "../../../icons/templates";
import { aiGeneratedTemplate, templateSvgToSourceNameMap } from "../../../icons/templates";
import { RadioTile } from "../../../tiles/radio-tile";
import { Modal } from "../../windowed-modal-next";

export type AddableDataSource = {
    id: keyof typeof addableDataSources;
    label: string;
    grayPillLabel?: string;
    accentPillLabel?: string;
};

type CreateNewAppModalProps = {
    onClose: () => void;
    onContinue: (item: string) => void;
    dataSources: AddableDataSource[];
    selectedSource: string | undefined;
    setSelectedSource: (id: string) => void;
    isLoadingUpgradeURL: boolean;
    needsToUpdate: boolean;
    showAIGeneratedTemplate?: boolean;
};

type TemplateChoiceProps = TemplateImageProps & {
    selected: boolean;
    onClick: (v: string) => void;
    autoFocus?: boolean;
};

const TemplateChoice: React.FC<React.PropsWithChildren<TemplateChoiceProps>> = p => {
    const { className, icon, selected, onClick, autoFocus } = p;
    const desc = templateSvgToSourceNameMap[icon];
    return (
        <RadioTile
            className={className}
            icon={<img alt={`${desc} icon`} src={`/svg/color/clr-${icon}.svg`} tw="aspect-[144/74] my-[3px]" />}
            label={desc}
            onSelect={onClick}
            checked={selected}
            value={`source-${desc.toLowerCase()}`}
            name="new-app-source"
            tw="p-2"
            autoFocus={autoFocus}
        />
    );
};

export const CreateNewAppModalFromSource: React.FC<React.PropsWithChildren<CreateNewAppModalProps>> = p => {
    const {
        onClose,
        onContinue,
        dataSources,
        needsToUpdate,
        selectedSource = "template-basic",
        setSelectedSource,
        isLoadingUpgradeURL,
        showAIGeneratedTemplate = false,
    } = p;

    const continueButtonLabel = React.useMemo(() => {
        // Google Sheets can always continue from this dialog
        // The user will be asked to upgrade depending on whether
        // they choose to import or sync.
        if (selectedSource === "gsheet") {
            return "Continue";
        }
        // check for upgrade requirement
        if (needsToUpdate) {
            return "Upgrade";
        }
        const isTemplateSelected = selectedSource?.includes("template-");
        return isTemplateSelected ? "Create app" : "Continue";
    }, [needsToUpdate, selectedSource]);

    const handleOnContinue = () => {
        onContinue(selectedSource);

        if (selectedSource === aiGeneratedTemplate) {
            trackEvent("new project layout select", {
                app_kind: "template-ai",
                page_template: "template-ai",
            });
        }
    };

    const templates = objectEntries(templateSvgToSourceNameMap).filter(([icon]) => {
        if (showAIGeneratedTemplate) {
            // AI replaces blank template
            return icon !== "template-portal";
        } else {
            return icon !== aiGeneratedTemplate;
        }
    });

    const isDark = useIsDarkTheme();
    return (
        <Modal.Container onClose={onClose}>
            <Modal.Window size={"md"} tw="min-w-fit max-h-[min(616px,calc(100vh-50px))]">
                <Modal.TitleBar tw="items-center py-4 pr-5 pl-8 border-b border-b-border-pale text-builder-2xl">
                    <Modal.Title>Create new app</Modal.Title>
                    <Modal.Close onClose={onClose}>Create new app</Modal.Close>
                </Modal.TitleBar>

                <Modal.Content
                    isDarkMode={isDark}
                    tw="overflow-y-scroll h-full data-[dark-mode=false]:[color-scheme: light] data-[dark-mode=true]:[color-scheme: dark]">
                    <fieldset>
                        <legend tw="sr-only">Select how you would like to start your new app</legend>
                        <div tw="py-4 px-8 mx-px bg-gradient-to-b from-bg-front to-[#99999909] border-b border-b-border-pale">
                            <h2 tw="pb-3 font-semibold text-builder-xl text-text-dark">Start from template</h2>
                            <div tw="grid grid-cols-3 gap-3 w-full">
                                {templates.map(([icon]) => (
                                    <TemplateChoice
                                        autoFocus={selectedSource === icon} // autofocus the selected one
                                        key={icon}
                                        icon={icon}
                                        selected={selectedSource === icon}
                                        onClick={() => setSelectedSource(icon)}
                                    />
                                ))}
                            </div>
                        </div>
                        <div tw="px-8 py-6">
                            <h2 tw="pb-3 font-semibold text-builder-xl text-text-dark">Start with data</h2>
                            <div tw="grid grid-cols-3 gap-3 w-full">
                                <ImportSourceTile
                                    key={"import"}
                                    label="Import File"
                                    icon={
                                        <div tw="rounded-full bg-n200A w-8 h-8 p-1.5 flex items-center justify-center">
                                            <GlideIcon
                                                tw="text-text-dark"
                                                kind="stroke"
                                                icon="st-arrow-full"
                                                rotateDeg={-90}
                                            />
                                        </div>
                                    }
                                    onClick={() => setSelectedSource("import")}
                                    selected={selectedSource === "import"}
                                />
                                {dataSources.map(({ id, label, accentPillLabel, grayPillLabel }) => (
                                    <ImportSourceTile
                                        key={id}
                                        label={label}
                                        icon={<DataSourceIcon size={36} icon={id} />}
                                        selected={selectedSource === id}
                                        onClick={() => setSelectedSource(id)}
                                        pill={
                                            <ImportTitlePill
                                                greyPillLabel={grayPillLabel}
                                                accentPillLabel={
                                                    id === "gsheet"
                                                        ? // We're never going to show a badge on Google Sheets, because
                                                          // all paid plans include it, and free users can import it.
                                                          undefined
                                                        : accentPillLabel
                                                }
                                            />
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    </fieldset>
                </Modal.Content>
                <Modal.ButtonBar tw="px-8 py-6 border-t border-t-border-xpale" align="split">
                    <Modal.Button buttonType="minimal" size="xlg" label="Cancel" variant="default" onClick={onClose} />
                    <Modal.Button
                        className="create-new-app-button"
                        buttonType="primary"
                        size="xlg"
                        label={continueButtonLabel}
                        variant="default"
                        tw="bg-accent"
                        isProcessing={isLoadingUpgradeURL}
                        onClick={handleOnContinue}
                    />
                </Modal.ButtonBar>
            </Modal.Window>
        </Modal.Container>
    );
};
