import { useOAuthLogin } from "./utils/use-oauth-login";

// This component is loaded lazily via route from the index. In a future
// better version of oauth - this doesn't need to exist in the player at
// all, we only need to load the minimal amount of javascript that form
// the useOAuthLogin hook logic.
//
export const OAuthRedirectPage: React.FC = () => {
    const { error } = useOAuthLogin();

    if (error !== undefined) {
        return (
            <div>
                <p>There was an error logging in:</p>
                <p>{error}</p>
            </div>
        );
    }

    return null;
};

// Default export for lazy loading
export default OAuthRedirectPage;
