/* eslint-disable @typescript-eslint/no-shadow */
import { isAbortError } from "@glide/microsoft-graph";
import * as glide from "@glide/plugins";
import { isDefined, isEmptyOrUndefinedish, logError } from "@glide/support";
import { assertNever, definedMap, exceptionToString, isEnumValue, sleep } from "@glideapps/ts-necessities";

const { Result } = glide;

export const plugin = glide.newNativePlugin({
    id: "generic-api",
    name: "Call API",
    description: "Make a request to an API endpoint",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/glide.png", // TODO: Icon?
    tier: "business",
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations", // TODO: Docs?
});

const endpoint = glide.makeParameter({
    type: "string",
    name: "Endpoint",
    description: "The URL you are making the HTTP request to",
    placeholder: "https://example.com",
    required: true,
    useTemplate: "withLabel",
});

const method = glide.makeParameter({
    type: "enum",
    values: [
        {
            value: "GET",
            label: "GET",
        },
        {
            value: "POST",
            label: "POST",
        },
        {
            value: "PUT",
            label: "PUT",
        },
        {
            value: "PATCH",
            label: "PATCH",
        },
        {
            value: "DELETE",
            label: "DELETE",
        },
    ],
    name: "Method",
    description: "The type of HTTP request you want to make",
    required: true,
});

const queryString = glide.makeParameter({
    type: "stringObject",
    withSecretConstants: true,
    name: "Query string",
    description: "Query parameters added to the URL",
    placeholder: "Query",
    propertySection: {
        name: "Query string",
        order: 1,
        collapsed: false,
    },
});

const headers = glide.makeParameter({
    type: "stringObject",
    withSecretConstants: true,
    name: "Headers",
    description: "Additional information sent with your HTTP request like authorization credentials",
    placeholder: "Header",
    propertySection: {
        name: "Headers",
        order: 2,
        collapsed: false,
    },
});

const body = glide.makeParameter({
    type: "string",
    name: "Body",
    description: "The data you are sending in the HTTP request",
    propertySection: {
        name: "Request Body",
        order: 3,
        collapsed: false,
    },
});

const response = glide.makeParameter({
    type: "string",
    name: "Response Body",
    description: "The response body as text",
});

const responseStatusCode = glide.makeParameter({
    type: "number",
    name: "Response Status Code",
    description: "The response HTTP status code",
});

const advancedSection = {
    name: "Advanced",
    // Should be the last thing.
    order: 10,
    collapsed: true,
};

const cacheTimeoutMinutes = glide.makeParameter({
    type: "number",
    name: "Refresh after",
    description:
        "Set the number of minutes until data is refreshed. If not set, the data will be refreshed after 30 days",
    placeholder: "Enter number of minutes",
    emptyByDefault: true,
    propertySection: advancedSection,
});

enum ErrorHandling {
    Strict = "strict",
    ReturnNonRetryable = "return-non-retryable",
    ReturnAll = "return-all",
}

const errorHandling = glide.makeParameter({
    type: "enum",
    name: "Error handling",
    description: "Define which error should cause the action to fail and which should be returned instead",
    values: [
        {
            value: ErrorHandling.Strict,
            label: "Fail with any error",
        },
        {
            value: ErrorHandling.ReturnNonRetryable,
            label: "Only fail with server errors (5xx)",
        },
        {
            value: ErrorHandling.ReturnAll,
            label: "Ignore but return all errors",
        },
    ],
    defaultValue: ErrorHandling.Strict,
    propertySection: advancedSection,
});

// If we need to back off due to rate-limiting, we won't wait for longer than
// this total before making our final request.
const maxTimeMS = 20 * 1000;

function shouldCountAsError(errorHandling: ErrorHandling, response: Response) {
    if (response.ok) {
        return false;
    } else if (errorHandling === ErrorHandling.Strict) {
        // `Strict` means all HTTP errors count as errors.
        return true;
    } else if (errorHandling === ErrorHandling.ReturnNonRetryable) {
        // In this case, only retryable HTTP errors count
        // as errors. Non-retryable ones are returned to
        // the workflow and can be handled by it.
        return response.status >= 500;
    } else if (errorHandling === ErrorHandling.ReturnAll) {
        // For `ReturnAll`, all HTTP errors are returned
        // as regular non-error results.
        return false;
    } else {
        return assertNever(errorHandling);
    }
}

plugin.addComputation({
    id: "fetch",
    name: "Call API",
    keywords: ["trigger", "web hook", "webhook", "zap", "zapier", "make", "api", "http", "get", "post", "endpoint"],
    parameters: {
        endpoint,
        method,
        queryString,
        headers,
        body: body.when(method, "is-not", "GET"),
        cacheTimeoutMinutes: cacheTimeoutMinutes.whenColumn(),
        errorHandling,
    },
    description: "Make an HTTP request to an API endpoint",
    configurationDescriptionPattern: "${capitalize(method)} ${removeProtocol(endpoint)}",
    group: "Communication",
    icon: {
        icon: "mt-header-globe",
        kind: "monotone",
        fgColor: "var(--gv-icon-base)",
        bgColor: "var(--gv-purple500)",
    },
    results: { response, responseStatusCode },
    billablesConsumed: 1,
    fetchBehavior: { headerTimeout: 300_000 },
    // This is often called for side-effects in actions.
    nonIdempotent: true,
    execute: async (
        context,
        { endpoint, method, queryString, headers, body, cacheTimeoutMinutes, errorHandling: maybeErrorHandling }
    ) => {
        // Evaluate. Should we return an empty OK if no endpoint? Sounds glide-y.
        if (isEmptyOrUndefinedish(endpoint)) {
            return Result.Ok({});
        }

        if (method === "GET") {
            // Unfortunately the plugin system will happily send values for
            // parameters that have been `when`-ed out.
            body = undefined;
        }

        let errorHandling: ErrorHandling;
        if (isEnumValue(ErrorHandling, maybeErrorHandling)) {
            errorHandling = maybeErrorHandling;
        } else {
            errorHandling = ErrorHandling.Strict;
        }

        const cacheTimeoutMS = definedMap(cacheTimeoutMinutes, t => t * 60 * 1000);

        const cachedResponse = await context.useCache(
            async () => {
                if (isDefined(method)) {
                    context.trackMetric("genericAPIMethod", method);
                    context.trackEventMetadata("genericAPIMethod", method);
                }

                if (isDefined(headers) && Object.keys(headers).length > 0) {
                    context.trackMetric("genericAPIHeaders", Object.keys(headers));
                }

                if (isDefined(queryString) && Object.keys(queryString).length > 0) {
                    context.trackMetric("genericAPIQuery", Object.keys(queryString));
                }

                context.trackMetric("genericAPIEndpoint", endpoint);
                context.trackMetric("genericAPIRequestBodySize", body?.length ?? 0);

                const endpointWithQuery = appendQueryParams(endpoint, queryString);

                if (endpointWithQuery === undefined) {
                    return Result.FailPermanent("Endpoint is not a valid URL");
                }

                const requestStartTime = performance.now();
                const cutoff = requestStartTime + maxTimeMS;
                let numRetries = 0;

                for (;;) {
                    try {
                        const response = await context.fetch(endpointWithQuery, {
                            method,
                            body,
                            headers,
                        });
                        const headerTime = performance.now();

                        let isPermanent: boolean | undefined;
                        if (response.status === 429) {
                            // We treat a rate-limit reply as a temporary
                            // failure so that we retry them in Automations,
                            // in excess of the short retry limit we do here.
                            // No matter what the error handling, we always
                            // retry 429s up to the limit.
                            isPermanent = false;

                            // We're being rate-limited.
                            let retryAfterSeconds = parseFloat(response.headers.get("Retry-After") ?? "1");
                            if (isNaN(retryAfterSeconds)) {
                                retryAfterSeconds = 1;
                            }
                            const retryAfterMS = retryAfterSeconds * 1000;
                            if (headerTime + retryAfterMS <= cutoff) {
                                await sleep(retryAfterMS);
                                numRetries++;
                                continue;
                            }
                        }

                        const asText = await response.text();
                        const responseTime = performance.now();

                        context.trackEventMetadata("genericAPIStatus", response.status);
                        context.trackEventMetadata("genericAPIEndpoint", endpoint);

                        context.trackMetric("genericAPIStatus", response.status);
                        context.trackMetric("genericAPILatency", responseTime - requestStartTime);
                        context.trackMetric("genericAPIResponseBodySize", asText.length);
                        context.trackMetric("genericAPINumRetries", numRetries);

                        if (shouldCountAsError(errorHandling, response)) {
                            context.trackMetric("genericAPIError", asText);
                            return Result.FailFromHTTPStatus(
                                `Call API returned a failing HTTP status: ${response.status}`,
                                response.status,
                                {
                                    responseBody: asText,
                                    isPermanent,
                                }
                            );
                        }

                        context.consumeBillable();
                        return Result.Ok({ response: asText, responseStatusCode: response.status });
                    } catch (err: unknown) {
                        if (isAbortError(err)) {
                            return Result.Fail("Request timed out");
                        }

                        // we can arrive here for reasons that aren't glide problems
                        // such as network and misconfiguration issues, bad or invalid URLs etc.
                        // rethrowing the error results in plugin execution noise in our logs
                        // which we should treat as a signal of plugin program errors
                        // that need fixing.
                        const retriesString = numRetries > 0 ? ` after ${numRetries} retries` : "";
                        return Result.FailPermanent(`Call API failed${retriesString}: ${exceptionToString(err)}`);
                    }
                }
            },
            [body, endpoint, method, headers, queryString, cacheTimeoutMinutes],
            undefined,
            cacheTimeoutMS
        );

        return cachedResponse;
    },
});

function appendQueryParams(endpoint: string, queryParams: Record<string, string> | undefined): string | undefined {
    if (queryParams === undefined) {
        return endpoint;
    }

    try {
        const url = new URL(endpoint);

        for (const [key, value] of Object.entries(queryParams)) {
            url.searchParams.set(key, value);
        }

        return url.toString();
    } catch (err: unknown) {
        logError(`Could not parse endpoint: ${endpoint}`, err);
        return undefined;
    }
}
