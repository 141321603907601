import "twin.macro";

import { SimpleHeartbeatAndQuotaManager } from "@glide/billing-heartbeat";
import {
    appendGlobalWindowGoogleAnalyticsMeasureID,
    globalWindowGoogleAnalyticsMeasureIDs,
    trackAppLogin,
    trackEvent,
    upsertGoogleAnalyticsMeasureID,
} from "@glide/common-core/dist/js/analytics";
import { setAppEnvironmentForAppID } from "@glide/common-core/dist/js/app-state-context";
import {
    encodeOAuth2AuthErrorMessage,
    encodeOAuth2AuthTokenMessage,
    extractOAuth2AuthTokenFromCurrentURL,
    hasAppLoginTokenFromCurrentURL,
} from "@glide/common-core/dist/js/authorization/auth";
import { ConfirmModalStyle } from "@glide/common-core/dist/js/components/confirm-modal";
import { getLocalizedString } from "@glide/localization";
import { type SerializedApp, AppAuthenticationKind } from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import {
    defaultAppDescriptionText,
    getAppFeatures,
    getAppKind,
    getAppKindFromFeatures,
} from "@glide/common-core/dist/js/components/SerializedApp";
import type { AppFacilities, AppPublishInfo, MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import { isCustomDomain } from "@glide/common-core/dist/js/customDomains";
import {
    type AppLoginAuthData,
    type AppLoginAuthDataFromPlay,
    type PaymentInformationForBuyButtons,
    type PublishedApp,
    type PublishedAppSnapshot,
    AuthenticationMethod,
    appLoginFromDocumentData,
    appNameSearchField,
    listenWhereWithRetry,
    listenWithRetry,
    publishedAppForDocument,
} from "@glide/common-core/dist/js/Database";
import { appLoginsCollectionName, glideAppsCollectionName } from "@glide/common-core/dist/js/database-strings";
import type { EminenceFlags } from "@glide/billing-types";
import { QuotaKind, quotaInfos } from "@glide/common-core/dist/js/Database/quotas";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { withNetworkStatus } from "@glide/common-core/dist/js/hooks/use-network-status";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import { getLocationSettings } from "@glide/common-core/dist/js/location";
import { getPersistentDeviceID } from "@glide/common-core/dist/js/persistent-device-id";
import { getAppFacilities, setAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { getDeviceFormFactor, needsFrame } from "@glide/common-core/dist/js/support/browser-hacks";
import { reloadBrowserWindow } from "@glide/common-core/dist/js/support/browser-reload";
import { standalone } from "@glide/common-core/dist/js/support/device";
import { lazyLoading, makeLazyLoader } from "@glide/common-core/dist/js/support/lazy-loading";
import { beaconCloudFunctionWeb } from "@glide/common-core/dist/js/utility/function-utils";
import { MaterialAppFacilities } from "@glide/common-core/dist/js/utility/material-app-facilities";
import { registerPluginActionHandler } from "@glide/generator/dist/js/plugins/generate";
import type { SerializablePluginMetadata } from "@glide/plugins";
import { forEachPluginAction, makePluginActionKind } from "@glide/plugins-utils";
import { assertNever, definedMap } from "@glideapps/ts-necessities";
import {
    RecurrentBackgroundJob,
    checkString,
    getCurrentTimestampInMilliseconds,
    isEmptyOrUndefined,
    localStorageGetItem,
    localStorageSetItem,
    logError,
    logInfo,
    nonNull,
} from "@glide/support";
import deepEqual from "deep-equal";
import { withPlayerEminenceFlags, makeAppEnvironmentForAppDescription } from "@glide/player-core";
import { getAppFeaturesFromPlay } from "lib/enzyme-utils";
import once from "lodash/once";
import type { DebugTableViewerProps } from "ncm/ncm-debug-table-viewer";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { polyfill } from "seamless-scroll-polyfill/dist/esm/Element.scrollIntoView";
import { FirebaseAppUserAuthenticator } from "sharedUI/lib/app-user-authenticator";
import AppQRCode from "sharedUI/ui/views/app-qr-code/app-qr-code";
import { APP_MODAL_ROOT, softEnforcementDismissTimeoutMS } from "webapp/lib/constants";
import { getWebDatabase } from "@glide/client-database";
import bundledApp from "../serialized-app.json";
import { getLastKnownAppLogin, saveAppLogin } from "./app-login";
import { AuthControllerPropsContextHack } from "./auth-controller-props-context-hack";
import AppContainer from "./chrome/smart/app-container/app-container";
import { clearAppPasswordLocalStorage, getLocalEmailLoginCredentials } from "@glide/auth-controller-core";
import { AppInfoWrapper, QRCodeWrapper } from "@glide/common-components";
import type { AuthControllerProps } from "./chrome/smart/auth-controller/auth-controller";
import AuthController, { setSamePageAuthToken } from "./chrome/smart/auth-controller/auth-controller";
import AppInfoPad from "./chrome/views/app-info-pad/app-info-pad";
import ConfirmModal from "./chrome/views/confirm-modal/confirm-modal";
import { GlideAppDesktopPlayer } from "./glide-app-desktop-player";
import GlideAppMobilePlayer from "./glide-app-mobile-player";
import { GlideAppPlayerSkeleton } from "./glide-app-player-skeleton";
import { urlForApp } from "./lib/appid-utils";
import { getIsDebugDataViewerAllowed } from "./lib/debug-data-viewer";
import { registerEventTrackers } from "@glide/plugin-events";
import { PagesAuthScreen } from "./wire-renderers/pages-auth-screen";
import type { PlayerProps } from "./wire-renderers/pages-player-wire-app-props";
import { PlayerIntegrationsAggregator } from "@glide/generator/dist/js/plugins/integrations-lib";
import { PlayerObservability } from "@glide/player-frontend-observability";

const debugTableViewerLoader = makeLazyLoader(
    "pages-player-wire-app",
    { canFailIfOffline: true, loadImmediately: false },
    () => import("ncm/ncm-debug-table-viewer")
);
const DebugTableViewerElement = React.lazy<React.ComponentType<DebugTableViewerProps>>(debugTableViewerLoader);

if (!("scrollBehavior" in document.documentElement.style)) {
    polyfill({});
}

function DebugTableViewer(props: DebugTableViewerProps): JSX.Element {
    return (
        <React.Suspense fallback={<div />}>
            <DebugTableViewerElement {...props} />
        </React.Suspense>
    );
}

const appFeaturesFromPlay = getAppFeaturesFromPlay();

const pagesWireLoader = makeLazyLoader(
    "pages-player-wire-app",
    { canFailIfOffline: false, loadImmediately: appFeaturesFromPlay !== undefined },
    () => import("./wire-renderers/pages-player-wire-app")
);
const PagesPlayerDesktopWireAppElement = React.lazy<React.ComponentType<PlayerProps>>(pagesWireLoader);

const appsWireLoader = makeLazyLoader(
    "apps-player-wire-app",
    { canFailIfOffline: false, loadImmediately: appFeaturesFromPlay !== undefined },
    () => import("./wire-renderers/apps-player-wire-app")
);
const AppsPlayerDesktopWireAppElement = React.lazy<React.ComponentType<PlayerProps>>(appsWireLoader);

function PlayerDesktopWireApp(props: PlayerProps): JSX.Element {
    const appKind = getAppKind(props.serializedApp);
    if (appKind === AppKind.Page) {
        return (
            <React.Suspense fallback={<div />}>
                <PagesPlayerDesktopWireAppElement {...props} />
            </React.Suspense>
        );
    }
    return (
        <React.Suspense fallback={<div />}>
            <AppsPlayerDesktopWireAppElement {...props} />
        </React.Suspense>
    );
}

function setAppMetadata(login: AppLoginAuthData): void {
    const serializedTitle = login.title;
    const { primaryAccentColor } = login.theme;
    const appName = serializedTitle !== undefined ? serializedTitle : "Glide";
    let data = login.manifest;
    if (data === undefined) {
        const iconUrl = `https://via.placeholder.com/256/${primaryAccentColor.replace(
            "#",
            ""
        )}/ffffff?text=${appName.substr(0, 1)}`;
        data = {
            name: appName,
            short_name: appName,
            display: "standalone",
            description: defaultAppDescriptionText,
            start_url: window.location.href,
            background_color: "#FFFFFF",
            theme_color: primaryAccentColor,
            icons: [
                {
                    src: iconUrl,
                    sizes: "256x256",
                    type: "image/png",
                },
            ],
        };
    }

    const appleTouchIcon = window.document.querySelector("#apple-touch-icon-placeholder");
    if (appleTouchIcon !== null && data.icons.length > 0) {
        // Icons must be specified smallest to biggest. Here we want the biggest icon possible because ipads.
        appleTouchIcon.setAttribute("href", data.icons[data.icons.length - 1].src);
    }

    const { glidePWAAddToHead } = data;
    if (glidePWAAddToHead !== undefined) {
        data = { ...data };
        delete data.glidePWAAddToHead;

        const head = document.getElementsByTagName("head")[0];
        if (head !== undefined) {
            head.insertAdjacentHTML("beforeend", glidePWAAddToHead);
        }
    }
}

function listenToAppLogin(nameOrID: string, onUpdate: (login: AppLoginAuthData, appID: string) => void): () => void {
    const field = appNameSearchField(nameOrID);
    if (field !== undefined) {
        return listenWhereWithRetry(
            getWebDatabase(),
            appLoginsCollectionName,
            [
                {
                    fieldPath: field,
                    opString: "==",
                    value: nameOrID,
                },
            ],
            1,
            async results => {
                if (results.length > 0) {
                    const { data, id } = results[0];
                    onUpdate(appLoginFromDocumentData(data), id);
                }
            }
        );
    } else {
        return listenWithRetry(getWebDatabase(), appLoginsCollectionName, nameOrID, data =>
            onUpdate(appLoginFromDocumentData(data), nameOrID)
        );
    }
}

function listenToPublishedApp(nameOrID: string, onUpdate: (app: PublishedApp) => void): void {
    const field = appNameSearchField(nameOrID);
    if (field !== undefined) {
        const db = getWebDatabase();
        // we have a custom domain or short name
        db.listenWhere(
            glideAppsCollectionName,
            [
                {
                    fieldPath: field,
                    opString: "==",
                    value: nameOrID,
                },
            ],
            undefined,
            undefined,
            undefined,
            results => {
                if (results.length > 0) {
                    const { data, id } = results[0];
                    onUpdate(publishedAppForDocument(db, data, id));
                }
            }
        );
    } else {
        const db = getWebDatabase();
        // we have an appID
        db.listenToDocument(
            glideAppsCollectionName,
            nameOrID,
            data => {
                if (data === undefined) return;
                onUpdate(publishedAppForDocument(db, data, nameOrID));
            },
            undefined
        );
    }
}

interface Props extends React.PropsWithChildren {
    id?: string;
    readonly onLine: boolean;

    // Set from `window.eminenceFlags` by player eminence hook
    readonly eminenceFlags: EminenceFlags;
}

type AuthKind = "none" | "try-email" | AppAuthenticationKind;

function authKindForAuthenticationMethod(method: AuthenticationMethod, optional: boolean): AuthKind {
    if (optional) return "none";

    switch (method) {
        case AuthenticationMethod.Password:
            return AppAuthenticationKind.Password;
        case AuthenticationMethod.PublicEmailPin:
        case AuthenticationMethod.WhitelistEmailPin:
        case AuthenticationMethod.OrgMembers:
        case AuthenticationMethod.JustMe:
        case AuthenticationMethod.UserProfileEmailPin:
        case AuthenticationMethod.AllowedEmailDomains:
            return AppAuthenticationKind.EmailPin;
        case AuthenticationMethod.None:
        case AuthenticationMethod.Disabled:
            return "none";
        default:
            return assertNever(method);
    }
}
const registerForWire = once(async () => {
    await Promise.all([
        (
            await lazyLoading(
                "component-handlers",
                false,
                () => import("@glide/generator/dist/js/components/register-all")
            )
        ).registerComponentHandlers(),
        (
            await lazyLoading(
                "array-screen-handlers",
                false,
                () => import("@glide/generator/dist/js/array-screens/register-all")
            )
        ).registerArrayScreenHandlers(),
        (
            await lazyLoading("action-handlers", false, () => import("@glide/generator/dist/js/actions/register-all"))
        ).registerActionHandlers(),
        (
            await lazyLoading(
                "description-handlers",
                false,
                () => import("@glide/generator/dist/js/components/description-handlers/register-all")
            )
        ).registerDescriptionHandlers(),
    ]);
});

const registeredActionKinds = new Set<string>();
function registerPlugins(pluginMetadata: readonly SerializablePluginMetadata[]): void {
    forEachPluginAction(pluginMetadata, (plugin, action) => {
        const kind = makePluginActionKind(plugin, action);
        if (registeredActionKinds.has(kind)) return;
        registeredActionKinds.add(kind);
        registerPluginActionHandler(plugin, action);
    });

    registerEventTrackers(pluginMetadata);
}

interface State {
    // Set from app login data
    readonly loginData?: AppLoginAuthData;
    readonly authenticator?: FirebaseAppUserAuthenticator;
    readonly id?: string;
    readonly shortName?: string;
    readonly customDomain?: string;

    // Set from the published app
    readonly app?: SerializedApp;
    readonly appEnvironment?: MinimalAppEnvironment;
    // readonly minimalAppEnvironment?: MinimalAppEnvironment;
    readonly paymentInformation?: PaymentInformationForBuyButtons;

    // Set when resizing
    readonly windowWidth: number;
    readonly windowHeight: number;

    // Set initially from app login, updated when the user authenticates.
    readonly authRequirements: AuthKind;

    // This is via a timer
    readonly showContentBlockMessage: boolean;

    // When we first load, we're not sure if we're actually signed in
    // via Firebase Auth or not. It's just indeterminate.
    readonly indeterminateFirebaseAuthState: boolean;

    readonly showPasswordLogoutModal: boolean;

    // We want to prevent firebase's bundles from being loaded until we've rendered at least once.
    readonly allowFirebaseLogin: boolean;
    readonly hasRendered: boolean;
    readonly quotaViolationKind: QuotaKind[];
    readonly canDismissEnforcementOverlay: boolean;
    readonly dismissedBlockingMessage: boolean;

    readonly heartbeatManager: SimpleHeartbeatAndQuotaManager | undefined;

    readonly debugDataViewer: boolean;
}

const debugDataViewerLocalStorageKey = "glide-debug-data-viewer";

const appLoadedTiming = PlayerObservability.makePlayerTiming("app-loaded");

class GlideAppPlayer extends React.Component<Props, State> {
    public state: State = {
        hasRendered: false,
        allowFirebaseLogin: false,
        authRequirements: "none",
        // The .appID on the window is generally the most accurate
        // we can get. This is extremely important for apps whose short
        // name changes.
        id: this.props.id ?? (window as any).appID,
        showContentBlockMessage: false,
        // We don't need to show a splash screen on most devices that run
        // us in full PWA mode. iOS and Android already provide their own
        // splash screen.
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        // This only is valid if we never persist the Firebase Auth tokens,
        // but instead always switch out our local authentication state
        // for non-persistent Firebase Auth tokens.
        indeterminateFirebaseAuthState: !isCustomDomain(window.location.hostname, true),
        showPasswordLogoutModal: false,
        quotaViolationKind: [],
        canDismissEnforcementOverlay: false,
        dismissedBlockingMessage: false,
        heartbeatManager: undefined,
        debugDataViewer: getIsDebugDataViewerAllowed()
            ? localStorageGetItem(debugDataViewerLocalStorageKey) === "true"
            : false,
    };

    private nextAppTimeout: number | undefined;
    private resetInterval: ReturnType<typeof setInterval> | undefined;

    private _lastPublishedAt: Date | undefined;
    private ERROR_TIMEOUT = 7000;
    private _countdown: ReturnType<typeof setInterval> | undefined;

    private fallbackLoginData: AppLoginAuthDataFromPlay | undefined;
    private pathnameIsCorrect = true;

    private readonly resetAppIfNecessaryJob = new RecurrentBackgroundJob(() => this.resetAppIfNecessary());

    constructor(props: Props) {
        super(props);
        // this means id can never be changed, which is kind of okay for our use case
        // but is still bad practice. This should really be watching change updated.
        this.withSavedAppLogin(loginData => {
            // If we tried to .setState here, we'd get flashes of the wrong theme
            // before the React component lifecycle decided to get around to us.
            // So instead we step outside of that, and let the skeleton use this fallback.
            this.fallbackLoginData = loginData;
        });
    }

    // This same component is also responsible for posting OAuth2 Access tokens
    // to its potential parent window. We try to window.close() before we render
    // whenever we need to post the message, but browsers sometimes wait around
    // before actually closing the window, and then we briefly render.
    // We don't want to briefly render in this case.
    private isHandlingExternalAuthentication = false;

    private withSavedAppLogin(onLoad: (appLogin: AppLoginAuthDataFromPlay) => void): void {
        try {
            const appLogin = getLastKnownAppLogin();
            if (appLogin !== undefined) {
                onLoad(appLogin);
            }
        } catch (e: unknown) {
            logError("Something went wrong with the app login from play", e);
        }
    }

    private didBeacon = false;
    private trySendBeacon(): void {
        if (this.didBeacon) return;
        // We don't want to send the beacon when all we have is a domain,
        // it's really best if we have an actual appID to work with.
        if (this.state.id === undefined || this.state.id.indexOf(".") >= 0) return;

        beaconCloudFunctionWeb("appBeacon", {
            appID: this.state.id,
            deviceID: getPersistentDeviceID(this.state.id),
            standalone: standalone ? "true" : "false",
        });
        this.didBeacon = true;
    }

    public componentDidMount(): void {
        window.addEventListener("resize", this.onResize);

        if (getLocationSettings().alwaysLogReloadCauses) {
            (window as any).setFeatureFlagDebugBrowserReload(true);
        }

        setAppFacilities(new MaterialAppFacilities(PlayerIntegrationsAggregator));

        if (
            isCustomDomain(window.location.hostname) &&
            window.location.pathname !== "/" &&
            !window.location.pathname.includes("/dl") &&
            window.location.pathname !== "/full"
        ) {
            this.pathnameIsCorrect = false;
            window.location.assign("/");
        }

        this.resetInterval = setInterval(() => {
            if (this.pathnameIsCorrect) {
                this.resetAppIfNecessaryJob.request();
            }
        }, 10 * 1000);

        if (!this.pathnameIsCorrect) {
            return;
        }

        // In order to support Sign in with Google, et alia, without also leaking more
        // pages into the history state (specifically for PWAs to keep feeling like apps)
        // we have to perform the entire OAuth2 flow in a pop-up of our own creation.
        // If we get to this point, _we_ are the pop-up. Our opener is waiting for the
        // end-stage authorization token that we currently have. We'll give it to them
        // and quietly disappear.
        const oauth2AuthToken = extractOAuth2AuthTokenFromCurrentURL();
        let opener: typeof window.opener | null = null;
        let openerOrigin: string | undefined;
        const { location } = window;
        try {
            opener = window.opener;
            openerOrigin = opener.location.origin;
        } catch {
            // This is liable to be a DOMException if we're not allowed
            // to even inspect it. We can even sometimes get an opener,
            // but not be allowed to look at its location.
            opener = null;
            openerOrigin = undefined;
        }
        // Note that the opener might not be our origin, and in this case
        // we won't attempt to post a message back to it.
        if (opener !== null && openerOrigin === location.origin) {
            if (oauth2AuthToken !== undefined) {
                opener.postMessage(encodeOAuth2AuthTokenMessage(oauth2AuthToken), location.origin);
                this.isHandlingExternalAuthentication = true;
            } else if (location.hash === "#signInWithOAuth2Cancelled") {
                // Do nothing, we were a cancelled redirect
                this.isHandlingExternalAuthentication = true;
            } else if (location.hash === "#signInWithOAuth2Error") {
                opener.postMessage(encodeOAuth2AuthErrorMessage(), location.origin);
                this.isHandlingExternalAuthentication = true;
            }

            if (this.isHandlingExternalAuthentication) {
                window.close();
            }
        } else if (oauth2AuthToken !== undefined) {
            setSamePageAuthToken(oauth2AuthToken);
        }

        this.trySendBeacon();

        if (getIsDebugDataViewerAllowed()) {
            (window as any).setDebugDataViewer = (flag: boolean) => {
                localStorageSetItem(debugDataViewerLocalStorageKey, JSON.stringify(flag));
                this.setState({ debugDataViewer: flag });
            };
        }
    }

    private connectHeartBeat() {
        const win = window as any;
        const efs = this.props.eminenceFlags;

        if (this.state.heartbeatManager !== undefined) {
            this.state.heartbeatManager.isUnmounted(false);
            this.setState({ heartbeatManager: undefined });
        }

        const setQuotaViolationKind = (kinds: QuotaKind[]) => {
            const priorityOrder = [QuotaKind.Updates, QuotaKind.RowsUsed, QuotaKind.FileBytesUsed];
            const newViolations = kinds
                .filter(k => priorityOrder.includes(k))
                .sort((a, b) => {
                    return priorityOrder.indexOf(a) - priorityOrder.indexOf(b);
                });
            this.setState(prevState => ({
                ...prevState,
                quotaViolationKind: newViolations,
            }));
        };

        class Manager extends SimpleHeartbeatAndQuotaManager {
            protected updateQuotasReached(kinds: QuotaKind[]) {
                setQuotaViolationKind(kinds);
            }

            protected getEminenceFlags() {
                return efs;
            }
        }

        const heartbeatManager = new Manager(this.props.id ?? win.appID, this.appFacilities);
        heartbeatManager.isMounted(false);
        this.setState({ heartbeatManager });
    }

    private closeBlockingMessage() {
        if (this.state.canDismissEnforcementOverlay) {
            this.setState({ dismissedBlockingMessage: true });
        }
    }

    public componentDidUpdate(_prevProps: Props, prevState: State): void {
        if (!prevState.allowFirebaseLogin && this.state.allowFirebaseLogin) {
            this.loadAppLogin().catch(e => {
                logError("Error loading App", e);
                this.setState({ showContentBlockMessage: true });
            });
        }

        this.trySendBeacon();
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.onResize);
        if (this.resetInterval !== undefined) {
            clearInterval(this.resetInterval);
        }
        if (this.state.heartbeatManager !== undefined) {
            this.state.heartbeatManager.isUnmounted(false);
            this.setState({ heartbeatManager: undefined });
        }
    }

    private onResize = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    };

    private get appFacilities(): AppFacilities {
        return getAppFacilities();
    }

    private async submitPasscode(
        authenticator: FirebaseAppUserAuthenticator,
        passcode: string,
        loginToken: AppLoginTokenContainer | undefined
    ): Promise<boolean> {
        const result = await (loginToken === undefined
            ? authenticator.authorizeForAppWithPassword(passcode)
            : authenticator.authorizeForAppWithLoginToken(loginToken, loginToken.overrideEmail, passcode));

        if (result) {
            this.setState({ authRequirements: "none" });
        }

        return result;
    }

    // NOTE: Don't call this directly.  Use `resetAppIfNecessaryJob` to avoid
    // race conditions.
    private async resetAppIfNecessary(): Promise<void> {
        const {
            nextAppTimeout,
            state: { id: appID },
        } = this;
        if (nextAppTimeout === undefined || appID === undefined) return;
        if (getCurrentTimestampInMilliseconds() < nextAppTimeout) return;

        const snapshot = await this.appFacilities.loadPublishedAppSnapshot(appID);
        if (snapshot === undefined) return;

        // We're only resetting this timeout if we succeeded loading the
        // snapshot.  If we don't, the timeout stays active and we try again
        // next time.
        this.nextAppTimeout = undefined;
        this.setLoadedApp(snapshot);
    }

    private setLoadedApp(publishedApp: PublishedAppSnapshot): void {
        const { authenticator } = this.state;
        if (authenticator === undefined) {
            logError("We should have an authenticator to set an app");
            return;
        }

        const { app, publishedAt, paymentInformation } = publishedApp;

        this.setApp(app, authenticator, publishedAt, paymentInformation);
    }

    private getPublishInfo = (): AppPublishInfo => {
        return {
            isPublished: true,
            shortName: this.state.loginData?.shortName,
            customDomain: this.state.loginData?.customDomain,
        };
    };

    private setApp(
        app: SerializedApp,
        authenticator: FirebaseAppUserAuthenticator,
        publishedAt: Date | undefined,
        paymentInformation: PaymentInformationForBuyButtons | undefined
    ): void {
        if (paymentInformation === undefined) {
            paymentInformation = {};
        }

        // FIXME: This is so that we don't create a new app environment in the case where
        // the app hasn't actually changed.  The `MaterialViewerGlideApp` won't update if
        // the app hasn't changed, so if we did make a new app environment here, we'd end
        // up with a new app environment, but the old evaluated app, which leads to lots
        // of fun bugs.  Eventually we'll have to have a single place where both the app
        // environment is created and the app is evaluated.
        if (
            deepEqual(app, this.state.app, { strict: true }) &&
            deepEqual(paymentInformation, this.state.paymentInformation, { strict: true })
        ) {
            // We disregard `jsonData` here, because it's historical.  As long as
            // those apps work at all, we're ok.
            return;
        }

        registerPlugins(app.pluginMetadata ?? []);

        const features = getAppFeatures(app);

        const appEnvironment = makeAppEnvironmentForAppDescription(
            app,
            definedMap(this.state.id, id => this.appFacilities.getInitialSchemaForAppID(id)),
            this.appFacilities,
            authenticator,
            features,
            false,
            "player",
            this.getPublishInfo,
            app.pluginMetadata ?? [],
            () => this.props.eminenceFlags
        );

        const finish = () => {
            appLoadedTiming.track();

            const knownGoogleAnalyticsMeasureIDs = globalWindowGoogleAnalyticsMeasureIDs();
            for (const measureID of features.googleAnalyticsMeasureIDs ?? []) {
                if (knownGoogleAnalyticsMeasureIDs.indexOf(measureID) < 0) {
                    upsertGoogleAnalyticsMeasureID(measureID);
                    appendGlobalWindowGoogleAnalyticsMeasureID(measureID);
                }
            }

            this.setState({
                app,
                appEnvironment,
                paymentInformation,
            });

            this.connectHeartBeat();
            this._lastPublishedAt = publishedAt;
        };

        void registerForWire().then(finish);
    }

    private init(appID: string, loginData: AppLoginAuthData): void {
        if ("serviceWorker" in navigator && navigator.serviceWorker.controller !== null) {
            navigator.serviceWorker.controller.postMessage("loadAppID/" + appID);
        } else {
            setTimeout(() => {
                if ("serviceWorker" in navigator && navigator.serviceWorker.controller !== null) {
                    navigator.serviceWorker.controller.postMessage("loadAppID/" + appID);
                }
            }, 2000);
        }

        setAppMetadata(loginData);

        const { ga: googleAnalytics } = window as any;
        if (googleAnalytics !== undefined && standalone) {
            // Track standalone sessions in Google Analytics
            // (Currently not supported by analytics.js)
            googleAnalytics("set", "dimension1", "standalone");
        }
    }

    private trackAppLogin = (appUserID: string | undefined) => {
        const { id } = this.state;
        if (id === undefined || appUserID === undefined) return;
        trackAppLogin(id, appUserID);
    };

    private appLoginAuthDataChanged = (loginData: AppLoginAuthData, appID: string): void => {
        const {
            authenticationMethod,
            authenticationOptional,
            blacklistAppUserIDs,
            passwordSerial,
            shortName,
            customDomain,
            lastPasswordID,
        } = loginData;

        saveAppLogin(loginData, appID, Date.now());

        let { authenticator } = this.state;
        const { onLine } = this.props;

        if (lastPasswordID !== undefined && authenticator !== undefined) {
            // We must call this even if we don't have an app environment,
            // to tell the authenticator that we've seen a password ID.
            if (authenticator.mustSignOutForPasswordID(lastPasswordID)) {
                trackEvent("password logout", { app_id: appID });
                this.setState({ showPasswordLogoutModal: true });
            }
        }

        if (this.state.loginData !== undefined) {
            if (
                this.state.loginData.authenticationMethod !== authenticationMethod ||
                this.state.loginData.authenticationOptional !== authenticationOptional ||
                this.state.loginData.passwordSerial !== passwordSerial
            ) {
                // FIXME: Don't reload, just unset everything regarding the app,
                // and re-listen.
                reloadBrowserWindow("authentication method or password changed", this.state.loginData, loginData);
            }

            const appUserID = authenticator?.appUserID;
            if (appUserID !== undefined && blacklistAppUserIDs.indexOf(appUserID) >= 0) {
                clearAppPasswordLocalStorage(appID);
                // FIXME: See above.
                reloadBrowserWindow("app user is blacklisted", appUserID, loginData);
            }

            this.setState({ loginData });
            this.fallbackLoginData = undefined;

            return;
        }

        if (isCustomDomain(window.location.hostname)) {
            this.init(window.location.hostname, loginData);
        } else {
            this.init(appID, loginData);
        }

        let authKind = authKindForAuthenticationMethod(authenticationMethod, authenticationOptional);

        // FIXME: We'll still need to preserve the email address check when we
        // remove app passwords
        const emailPassword = getLocalEmailLoginCredentials(appID)?.emailPassword;
        if (authKind === "none" && (!isEmptyOrUndefined(emailPassword) || hasAppLoginTokenFromCurrentURL())) {
            authKind = "try-email";
        }

        authenticator = new FirebaseAppUserAuthenticator(this.appFacilities, appID);
        authenticator.addCallback(this.trackAppLogin);

        if (authKind !== "none") {
            authenticator.addCallback(this.loadApp);
        }

        this.setState(
            { authenticator, id: appID, loginData, authRequirements: authKind, shortName, customDomain },
            () => {
                if (authKind === "none" || !onLine) {
                    this.loadApp().catch(err => logError(`load app err: ${err}`));
                }
            }
        );
    };

    private async loadAppLogin(): Promise<void> {
        // We need to make this an any because most of the time the bundled json is empty.
        // Only when we cordovize the source do we get valid json which the types can then
        // understand. Unfortunately TS is a little unhappy with this setup.
        const ba = bundledApp as any;
        if (ba.app !== undefined) {
            this.setApp(ba.app as SerializedApp, ba.id, undefined, ba.paymentInformation);
            return;
        }

        const idOrHostname = this.state.id ?? window.location.hostname;

        this.withSavedAppLogin(appLogin => {
            try {
                this.appLoginAuthDataChanged(appLogin, checkString(appLogin.appID));
            } catch (e: unknown) {
                logError("Something went wrong with the app login from play", e);
            }
        });

        listenToAppLogin(idOrHostname, this.appLoginAuthDataChanged);
    }

    // Only call this once the user is authenticated (or the app not requiring
    // authentication).
    private loadApp = async (): Promise<void> => {
        const { id, authenticator } = this.state;

        if (id === undefined || authenticator === undefined) {
            logError("State is missing data");
            return;
        }

        authenticator.removeCallback(this.loadApp);

        const idOrHostname = id;
        const { appFacilities } = this;

        let publishedAppFromSnapshot: PublishedAppSnapshot | undefined;
        for (let i = 0; i < 10; i++) {
            publishedAppFromSnapshot = await appFacilities.loadPublishedAppSnapshot(id);
            if (publishedAppFromSnapshot === undefined) {
                logError(`Could not load app snapshot after ${i} retries`);
                continue;
            }
            break;
        }

        if (publishedAppFromSnapshot === undefined) {
            // If we can't load from the published app snapshot,
            // we should show the modal instead of just a reload loop.
            // This is mostly to keep alerting quiet for broken client
            // setups.

            if (typeof (window as any).showNetworkRetryButton === "function") {
                (window as any).showNetworkRetryButton();
                return;
            }
            return reloadBrowserWindow("Could not load app snapshot");
        }

        this.setLoadedApp(publishedAppFromSnapshot);

        listenToPublishedApp(idOrHostname, publishedApp => {
            logInfo("Received published app with timestamp", publishedApp.publishedAt, this._lastPublishedAt);

            const { publishedAt } = publishedApp;
            if (
                this._lastPublishedAt !== undefined &&
                (publishedAt === undefined || this._lastPublishedAt > publishedAt)
            ) {
                return;
            }
            this.nextAppTimeout = getCurrentTimestampInMilliseconds() + 5 * 60 * 1000;
        });
    };

    private onAuthLoggedIn = async (_email: string) => {
        this.setState({ authRequirements: "none" });
    };

    private dontTryEmail = async () => {
        if (this.state.id !== undefined) {
            clearAppPasswordLocalStorage(this.state.id);
        }
        this.setState({ authRequirements: "none" }, async () => await this.loadApp());
    };

    private checkContent = (content: React.ReactNode | undefined) => {
        if (content === undefined || content === null) {
            if (this._countdown === undefined) {
                this._countdown = setTimeout(() => {
                    this.setState(() => ({ showContentBlockMessage: true }));
                }, this.ERROR_TIMEOUT);
            }
        } else if (this._countdown) {
            clearTimeout(this._countdown);
        }
    };

    private ensureDeterminateFirebaseAuthState = once(async () => {
        // When the player signs into an authenticated app, our
        // backend gives it a custom Firebase user token, which is
        // basically an anonymous Firebase user, which it logs in
        // as.  That will log out whichever Firebase user was logged
        // in with Glide, which means the builder won't work until
        // the user logs in again, so we try to avoid that by
        // detecting that somebody is logged in, and explicitly
        // authenticating that user to use the app.  However,
        // unless we do the magic incantation below, Firebase won't
        // tell us that there's a user logged in.  To reproduce
        // the problem, make a password-authed app, open the app
        // from the builder and log in in the player.  Unless the
        // code below is there, that will log you out of the
        // builder.

        // Here, we register a callback that fires whenever the
        // Firebase Auth state changes, which necessarily means that
        // the state is determinate. Our callback will also be immediately
        // called if the Firebase Auth state is already determinate,
        // i.e. you are definitely either signed in or not.
        const { onAuthStateChanged } = await lazyLoading("firebase-auth", false, () => import("../lib/firebase-auth"));
        onAuthStateChanged(() => {
            this.setState({ indeterminateFirebaseAuthState: false });
        });
    });

    static getDerivedStateFromProps(_props: Props, state: State) {
        if (state.allowFirebaseLogin) {
            return state;
        } else if (state.hasRendered) {
            return {
                ...state,
                allowFirebaseLogin: true,
            };
        }
        return {
            ...state,
            hasRendered: true,
        };
    }

    public render() {
        // Bad stuff happens when we're on a custom domain and the pathname isn't literally /.
        // We detect this case in the constructor and try to redirect to / as fast as possible,
        // but that still gives us an opportunity to render. So when we know that we're on a
        // custom domain and the pathname isn't /, we just don't render.
        if (!this.pathnameIsCorrect) return null;

        this.ensureDeterminateFirebaseAuthState().catch(e => {
            logError(e);
            if (!isCustomDomain(window.location.hostname, true)) {
                reloadBrowserWindow("Determining Firebase Auth state failed");
            }
        });

        const { onLine, eminenceFlags } = this.props;
        const {
            allowFirebaseLogin,
            app,
            appEnvironment,
            authenticator,
            id,
            paymentInformation,
            indeterminateFirebaseAuthState,
            authRequirements,
            shortName,
            customDomain,
            loginData,
            showContentBlockMessage,
            showPasswordLogoutModal,
            quotaViolationKind,
            canDismissEnforcementOverlay,
            dismissedBlockingMessage,
        } = this.state;

        const loginDataOrFallback = loginData ?? this.fallbackLoginData;
        // const renderMode = getRenderMode(loginDataOrFallback?.features);
        const useFrame =
            needsFrame(loginDataOrFallback?.features) &&
            getAppKindFromFeatures(loginDataOrFallback?.features) !== AppKind.Page;
        const deviceFormFactor = getDeviceFormFactor(loginDataOrFallback?.features, eminenceFlags);

        // Indeterminate Firebase Auth state isn't a problem on custom domains:
        // We don't share a Firebase Auth state with any other "applications" running
        // on the same origin.
        const indeterminateAuthState = indeterminateFirebaseAuthState && !isCustomDomain(window.location.hostname);
        const handlingAuth = this.isHandlingExternalAuthentication;

        const makeSkeleton = (forError?: boolean) => (
            <GlideAppPlayerSkeleton
                useFrame={useFrame}
                deviceFormFactor={deviceFormFactor}
                id={id}
                authenticator={authenticator}
                loginDataOrFallback={loginDataOrFallback}
                showContentBlockMessage={forError ?? showContentBlockMessage}
                checkContent={this.checkContent}
                appInfo={<AppInfoWrapper />}
            />
        );
        if (
            !allowFirebaseLogin ||
            loginDataOrFallback === undefined ||
            authenticator === undefined ||
            id === undefined ||
            indeterminateAuthState ||
            handlingAuth
        ) {
            return makeSkeleton();
        }

        const { features, iconImage, manifest, theme, title } = loginDataOrFallback;
        // We can't use appEnvironment.appIsPro out here. appEnvironment: ForwardingAppEnvironment
        // and its callbacks are set on the mounting of MaterialViewerGlideApp. If we tried to read
        // it now, we'd get its default and we'd be stuck with it. So instead we'll read ahead into
        // the app features, which we definitely have at the first call to render().
        const showBranding = !eminenceFlags.removeBranding;
        const appKind = getAppKindFromFeatures(features);

        let authKind = authRequirements !== "try-email" ? authRequirements : AppAuthenticationKind.EmailPin;
        if (authKind === "none") {
            authKind = AppAuthenticationKind.EmailPin;
        }

        const authProps: Omit<AuthControllerProps, "onLoggedIn" | "isOSThemeDark"> | undefined = {
            appID: id,
            authKind,
            withBranding: showBranding,
            appFeatures: features,
            authMethod: loginData?.authenticationMethod,
            accentColor: theme.primaryAccentColor,
            appTitle: title,
            previewMode: false,
            flags: this.props.eminenceFlags,
        };

        let content: React.ReactNode | null = null;
        let showingApp = false;
        if (
            authRequirements !== "none" &&
            onLine &&
            (authRequirements === AppAuthenticationKind.Password ||
                authRequirements === AppAuthenticationKind.EmailPin ||
                authRequirements === "try-email")
        ) {
            // FIXME: Start pre-connecting to backend in this case if wire-over-wire

            if (appKind === AppKind.Page) {
                content = (
                    <PagesAuthScreen
                        authProps={authProps}
                        authRequirements={authRequirements}
                        authenticator={authenticator}
                        onAuthLoggedIn={this.onAuthLoggedIn}
                        dontTryEmail={this.dontTryEmail}
                        iconImage={iconImage}
                        submitPasscode={this.submitPasscode}
                    />
                );
            } else {
                content = (
                    <AuthController
                        {...authProps}
                        pagesSource="main-sign-in"
                        onLoggedIn={this.onAuthLoggedIn}
                        submitPasscode={(p, t) => this.submitPasscode(authenticator, p, t)}
                        onFailed={authRequirements === "try-email" ? this.dontTryEmail : undefined}
                        onClose={authRequirements === "try-email" ? this.dontTryEmail : undefined}
                        iconImage={iconImage}
                    />
                );
            }
        } else {
            if (appEnvironment !== undefined) {
                setAppEnvironmentForAppID(appEnvironment);
            }

            if (app !== undefined && appEnvironment !== undefined) {
                content = (
                    <PlayerDesktopWireApp
                        appEnvironment={appEnvironment}
                        serializedApp={app}
                        database={appEnvironment.database}
                        paymentInfomation={paymentInformation ?? {}}
                    />
                );
                showingApp = true;
            }
            if (!content) {
                return makeSkeleton();
            }
        }

        this.checkContent(content);

        if (showPasswordLogoutModal) {
            const portal = ReactDOM.createPortal(
                <ConfirmModal
                    modalStyle={ConfirmModalStyle.Confirm}
                    title={getLocalizedString("youllBeSignedOut", appKind)}
                    description={getLocalizedString("freePasswordAppsExplanation", appKind)}
                    accept={getLocalizedString("signOut", appKind)}
                    onFinish={() => reloadBrowserWindow("user signed out")}
                />,
                nonNull(document.getElementById(APP_MODAL_ROOT))
            );
            content = (
                <>
                    <div aria-hidden={true}>{content}</div>
                    {portal}
                </>
            );
        }

        const softEnforcementEnabled =
            (getFeatureSetting("softEnforcementEnabled") &&
                this.props.eminenceFlags.quotaEnforcementScheme === "free") ||
            (getFeatureSetting("paidSoftEnforcementEnabled") &&
                this.props.eminenceFlags.quotaEnforcementScheme === "paid") ||
            this.state.app?.features?.forceSoftEnforcement === true;

        const hardEnforcementEnabled =
            (getFeatureSetting("hardEnforcementEnabled") &&
                this.props.eminenceFlags.quotaEnforcementScheme === "free") ||
            (getFeatureSetting("paidHardEnforcementEnabled") &&
                this.props.eminenceFlags.quotaEnforcementScheme === "paid") ||
            this.state.app?.features?.forceHardEnforcement === true;

        if ((softEnforcementEnabled || hardEnforcementEnabled) && quotaViolationKind.length > 0) {
            const quotaModalContent =
                quotaInfos.find(q => {
                    return q.kind === quotaViolationKind[0];
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                })?.shortName || "Usage";

            const blockingMessage = {
                title: `This team has reached its ${quotaModalContent.toLowerCase()} limit`,
                message: "Please contact the app owner to resolve this issue.",
                cta: "Continue",
            };

            const doSoftEnforcement = !hardEnforcementEnabled && !quotaViolationKind.includes(QuotaKind.Updates);

            // Soft enforcement is enabled if hard enforcement is not enabled or the app is being soft enforced for updates
            if (doSoftEnforcement) {
                setTimeout(() => {
                    const canDismiss =
                        !hardEnforcementEnabled && !this.state.quotaViolationKind.includes(QuotaKind.Updates);
                    this.setState({ canDismissEnforcementOverlay: canDismiss });
                }, softEnforcementDismissTimeoutMS);
            }

            content = (
                <>
                    {content}
                    {!dismissedBlockingMessage && (
                        <div
                            onClick={() => this.closeBlockingMessage()}
                            tw="absolute inset-0 p-10 background-color[rgba(17, 24, 39, 0.33)] z-index[100000]
                                text-text-dark flex flex-col justify-center items-center"
                        >
                            <div tw="w-full max-width[480px] rounded-xl shadow-2xl-dark">
                                <div
                                    tw="rounded-xl overflow-hidden background-color[#374664E0] backdrop-blur
                                        backdrop-filter"
                                >
                                    <div tw="pt-10 pb-8 px-10 bg-bg-front" onClick={e => e.stopPropagation()}>
                                        <h2 tw="font-bold text-xl mb-2">{blockingMessage.title}</h2>
                                        <div tw="text-sm whitespace-pre-wrap break-words mb-2">
                                            {blockingMessage.message}
                                        </div>

                                        {doSoftEnforcement && (
                                            <button
                                                disabled={!canDismissEnforcementOverlay}
                                                tw="transition cursor-pointer rounded-lg px-3 py-2 mr-2 text-white
                                                    font-size[13px] font-semibold background-color[#374664E0] mt-2
                                                    disabled:opacity-20"
                                                onClick={() => this.closeBlockingMessage()}
                                            >
                                                {blockingMessage.cta ?? getLocalizedString("cancel", appKind)}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            );
        }

        const isDevice = !useFrame;
        const forceTheme = isDevice ? undefined : "iOS";

        const authPropsInsideApp = {
            ...authProps,
            onClose: () => window.history.back(),
        };

        const appContainer = (
            <AuthControllerPropsContextHack.Provider value={authPropsInsideApp}>
                <AppContainer
                    appFeatures={features}
                    authenticator={authenticator}
                    forceTheme={forceTheme}
                    title={title}
                    iconImage={iconImage}
                    theme={theme}
                    appID={id}
                    isBuilder={false}
                    showBranding={showBranding}
                    showingApp={showingApp}
                    useFrame={useFrame}
                    deviceFormFactor={deviceFormFactor}
                >
                    {content}
                </AppContainer>
            </AuthControllerPropsContextHack.Provider>
        );

        let debugDataViewer: React.ReactNode;
        if (this.state.debugDataViewer && appEnvironment !== undefined && app !== undefined) {
            debugDataViewer = (
                <DebugTableViewer
                    appEnvironment={appEnvironment}
                    appDescription={app}
                    tables={appEnvironment.dataStore.schema.tables}
                />
            );
        }

        let player: React.ReactNode;
        if (isDevice) {
            player = <GlideAppMobilePlayer appContainer={appContainer} />;
        } else {
            const appInfo = (
                <React.Fragment>
                    <AppInfoWrapper>
                        <AppInfoPad
                            title={title}
                            manifest={manifest}
                            features={features}
                            iconImage={iconImage}
                            allowTabletMode={eminenceFlags.tabletMode}
                            id={id}
                            showBranding={showBranding}
                        />
                    </AppInfoWrapper>
                    {features.disableSharing !== true && (
                        <QRCodeWrapper>
                            <AppQRCode
                                size={200}
                                url={urlForApp(id, appKind, { shortName, customDomain })}
                                hideDocumentationLink={!showBranding}
                                appKind={appKind}
                            />
                        </QRCodeWrapper>
                    )}
                </React.Fragment>
            );

            player = (
                <GlideAppDesktopPlayer
                    appContainer={appContainer}
                    appInfo={appInfo}
                    showBranding={showBranding}
                    isSkeleton={false}
                />
            );
        }

        return (
            <>
                {player}
                {debugDataViewer ?? null}
            </>
        );
    }
}

export default withNetworkStatus(withPlayerEminenceFlags(GlideAppPlayer));
