import { AppIcon } from "@glide/common";
import { trackEvent } from "@glide/common-core/dist/js/analytics";
import {
    type HeaderSection,
    type SuperPropertySection,
    getSectionKey,
    getSectionName,
    getSectionPriority,
    headerSectionForPropertySection,
    headerSectionOrder,
    sectionBelongsToHeader,
} from "@glide/function-utils";
import { defined } from "@glideapps/ts-necessities";
import deepEqual from "deep-equal";
import sortBy from "lodash/sortBy";
import * as React from "react";

import classNames from "classnames";
import type { CustomSectionItem } from "../../../components/group-ui/group-ui";
import SectionTabBar from "../../../components/section-tab-bar/section-tab-bar";
import { RichTooltip } from "../../../components/tooltip/rich-tooltip";
import { SectionTitle, SectionWrapper } from "./grouped-property-configurators-style";

interface ResultType {
    items: CustomSectionItem[];
    section: SuperPropertySection;
}

interface Props extends React.PropsWithChildren {
    items: readonly CustomSectionItem[];
    selectedHeader?: HeaderSection;
    onHeaderSelected?: (header: HeaderSection) => void;
    drawSeparators?: boolean;
    variant?: "normal" | "tight" | "very-tight" | "no-margin";
    foldable?: (section: SuperPropertySection) => boolean;
    readonly docURL?: string;
}

interface State {
    selectedHeader: HeaderSection;
    folded: ReadonlySet<string>;
}

export class GroupedConfigUIs extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { selectedHeader: this.getHeaderSections()[0], folded: new Set() };
    }

    private getHeaderSections(): readonly HeaderSection[] {
        const { items } = this.props;
        const unorderedSections = new Set(items.map(i => headerSectionForPropertySection(i.section)));
        return headerSectionOrder.filter(hs => unorderedSections.has(hs) && hs !== "Hidden");
    }

    private onTabBarChange = (newValue: HeaderSection) => {
        if (this.props.onHeaderSelected === undefined) {
            this.setState({ selectedHeader: newValue });
        } else {
            this.props.onHeaderSelected(newValue);
        }
        trackEvent("config_group_changed", { label: newValue });
    };

    private sortAndGroup(sections: readonly CustomSectionItem[], selectedHeader: HeaderSection): ResultType[] {
        const filtered = sections.filter(s => sectionBelongsToHeader(s.section, defined(selectedHeader)));
        const sorted = sortBy(filtered, fi => getSectionPriority(fi.section));

        const result: ResultType[] = [];
        let last: ResultType | undefined;

        sorted.forEach(i => {
            if (last === undefined || !deepEqual(last.section, i.section)) {
                last = {
                    items: [],
                    section: i.section,
                };
                result.push(last);
            }
            last.items.push(i);
        });

        return result;
    }

    public render(): React.ReactNode {
        const { items, variant, foldable, docURL } = this.props;
        let selectedHeader: HeaderSection | undefined =
            this.props.onHeaderSelected === undefined ? this.state.selectedHeader : this.props.selectedHeader;
        const { folded } = this.state;
        const sections = this.getHeaderSections();

        // Pick the first section if
        // 1. Selection is undefined
        // 2. Selection is set in this.prop.selectedHeader (parent) and navigation happens.
        if (selectedHeader === undefined || sections.indexOf(selectedHeader) === -1) {
            selectedHeader = sections[0];
        }

        const variantNormalized = variant ?? "normal";
        const toRender = this.sortAndGroup(items, selectedHeader);
        let hasAddedTooltip = false;
        const content = toRender.map((s, i) => {
            const name = getSectionName(s.section);
            const key = getSectionKey(s.section, i);
            const canFold = foldable?.(s.section) ?? false;
            const isFolded = folded.has(key);
            let tooltip: React.ReactNode | undefined;
            if (name !== "" && docURL !== undefined && !hasAddedTooltip) {
                tooltip = <RichTooltip docURL={docURL} />;
                hasAddedTooltip = true;
            }
            return (
                <SectionWrapper
                    className={classNames(variantNormalized, `swrapper-${name}`)}
                    key={key}
                    withTopMargin={i !== 0 || sections.length > 1}
                    withBottomMargin={true}
                    drawSeparator={this.props.drawSeparators ?? true}
                >
                    {name !== "" && (
                        <SectionTitle tightMargins={variantNormalized !== "normal"}>
                            {canFold && (
                                <AppIcon
                                    icon="caret"
                                    size={10}
                                    className="caret"
                                    degreesOfRotation={isFolded ? -90 : 0}
                                    onClick={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        const newFolded = new Set(folded);
                                        if (isFolded) {
                                            newFolded.delete(key);
                                        } else {
                                            newFolded.add(key);
                                        }
                                        this.setState({ folded: newFolded });
                                    }}
                                />
                            )}
                            <div className="section-title">{name}</div>
                            {tooltip}
                        </SectionTitle>
                    )}
                    {!isFolded && s.items.map((config, j) => <React.Fragment key={j}>{config.ui}</React.Fragment>)}
                </SectionWrapper>
            );
        });

        return (
            <div className={selectedHeader}>
                {sections.length > 1 && (
                    <SectionTabBar
                        className={selectedHeader}
                        items={sections}
                        value={selectedHeader}
                        onChange={this.onTabBarChange}
                    />
                )}
                <div>{content}</div>
            </div>
        );
    }
}
