import type { GlideFC } from "@glide/common";
import { useState } from "react";
import type { ReactNode } from "react";
import type { WindowedModalV2Props } from "../windowed-modal/windowed-modal";
import WindowedModalV2, { WindowedModalButtonBar } from "../windowed-modal/windowed-modal";
import type { ButtonSizes, ButtonVariants } from "../button/button-lib";

export interface AcceptCancelModalOptions {
    acceptButtonVariance?: ButtonVariants;
    requireUserToType?: string;
    footerDivider?: WindowedModalV2Props["footerDivider"];
    hideClosingButton?: WindowedModalV2Props["hideClosingButton"];
    titleBarBottomPadding?: WindowedModalV2Props["titleBarBottomPadding"];
    titleBarTopPadding?: WindowedModalV2Props["titleBarTopPadding"];
    footerTopMargin?: WindowedModalV2Props["footerTopMargin"];
    footerTopPadding?: WindowedModalV2Props["footerTopPadding"];
    footerBottomPadding?: WindowedModalV2Props["footerBottomPadding"];
    barsPadding?: WindowedModalV2Props["barsPadding"];
    padding?: WindowedModalV2Props["padding"];
    buttonSize?: ButtonSizes;
    smallContentText?: WindowedModalV2Props["smallContentText"];
}

interface AcceptCancelProps extends AcceptCancelModalOptions {
    title: string;
    acceptButtonText: string;
    cancelButtonText: string | "Cancel";
    children: ReactNode;
    onAccept: () => void;
    onCancel: () => void;
}
export const AcceptCancelModal: GlideFC<AcceptCancelProps> = props => {
    const {
        title,
        acceptButtonText,
        cancelButtonText,
        acceptButtonVariance,
        children,
        onAccept,
        onCancel,
        requireUserToType,
        footerDivider,
        hideClosingButton,
        titleBarBottomPadding,
        titleBarTopPadding,
        footerTopMargin,
        footerTopPadding,
        footerBottomPadding,
        barsPadding,
        padding,
        buttonSize,
        smallContentText,
    } = props;
    const [challenge, setChallenge] = useState("");
    const challengeView = (
        <div>
            <p tw="my-4">
                Type <span tw="bg-separator-color text-xs rounded py-1 px-2 font-mono">{requireUserToType}</span> to
                confirm:
            </p>
            <input
                autoFocus
                tw="bg-separator-color rounded-lg text-sm py-2 px-3 w-full"
                type="text"
                value={challenge}
                onChange={e => setChallenge(e.target.value)}
            />
        </div>
    );
    return (
        <WindowedModalV2
            maxWidth="440px"
            title={title}
            canClose={true}
            footerDivider={footerDivider}
            hideClosingButton={hideClosingButton}
            titleBarBottomPadding={titleBarBottomPadding}
            titleBarTopPadding={titleBarTopPadding}
            footerTopMargin={footerTopMargin}
            footerTopPadding={footerTopPadding}
            footerBottomPadding={footerBottomPadding}
            barsPadding={barsPadding}
            padding={padding}
            smallContentText={smallContentText}
            className="click-outside-ignore"
            footer={
                <WindowedModalButtonBar
                    align="right"
                    buttons={[
                        {
                            title: cancelButtonText,
                            type: "secondary",
                            variant: "default",
                            onClick: () => onCancel(),
                            size: buttonSize ?? "md",
                        },
                        {
                            disabled: requireUserToType !== undefined && challenge !== requireUserToType,
                            title: acceptButtonText,
                            type: "primary",
                            variant: acceptButtonVariance ?? "default",
                            onClick: () => onAccept(),
                            size: buttonSize ?? "md",
                            autofocus: true,
                        },
                    ]}
                />
            }
            onClose={() => onCancel()}>
            <>
                {children}
                {requireUserToType !== undefined && challengeView}
            </>
        </WindowedModalV2>
    );
};
