import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { isDefined } from "@glide/support";
import { isBound } from "@glide/computation-model-types";
import { UIBackgroundStyle, UIImageStyle, UIStyleVariant } from "@glide/wire";
import chroma from "chroma-js";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { type HTMLAttributes, type MouseEventHandler, useRef } from "react";
import { css } from "styled-components";
import tw from "twin.macro";
import { Img } from "../../components/img/img";
import { useIsFlat } from "../../utils/use-is-flat";
import { useSectionStyle } from "../wire-container/wire-container";
import { type TablePropertiesBindState, TableActionsButton, TextWithEllipsis } from "./card-collection-table-shared";
import type { CardProps } from "./card-collection-types";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

interface TableRowProps extends CardProps {
    boundProperties: TablePropertiesBindState;
    styleVariant: UIStyleVariant;
    hasHeader: boolean;
    testId: number;
    appID: string;
}

export const TableRow: React.VFC<TableRowProps> = p => {
    const { desc, imageStyle, styleVariant, boundProperties, hasHeader, testId, appID } = p;

    const boundPropertiesCount = Object.values(boundProperties).filter(Boolean).length;

    const menuActions = desc.menuItems;

    const isTouchDevice = useRef(false);

    const onTouchStart = () => {
        isTouchDevice.current = true;
    };

    const onMouseMove = () => {
        isTouchDevice.current = false;
    };

    let a11yProps: HTMLAttributes<HTMLDivElement> = {};

    const hasClickAction = isDefined(desc.onCardClick);
    if (hasClickAction) {
        a11yProps = {
            "aria-pressed": false,
            role: "button",
            tabIndex: 0,
        };
    }

    const theme = useWireAppTheme();
    const accentAlpha = chroma(theme.accent).alpha(0.05).css();
    const isFlat = useIsFlat();
    const sectionStyle = useSectionStyle();
    const hasImage = isBound(desc.image);
    const hasTitle = boundProperties.title;
    const hasSubtitle = boundProperties.subtitle;
    const hasEmphasis = boundProperties.emphasis;

    const hasRenderableSubtitle = hasSubtitle && !isEmpty(desc.subtitle);
    const hasRenderableEmphasis = hasEmphasis && !isEmpty(desc.emphasis);

    const onItemClick: MouseEventHandler<HTMLElement> = e => {
        desc.onCardClick?.();
        e.stopPropagation();
    };

    return (
        <div
            data-testid={`card-collection-table-row-${testId}`}
            {...a11yProps}
            onKeyDown={e => {
                if (e.key === "Enter") desc.onCardClick?.();
            }}
            onClick={onItemClick}
            onTouchStart={onTouchStart}
            onMouseMove={onMouseMove}
            className={classNames(
                hasClickAction && "has-click-action",
                "group",
                styleVariant,
                sectionStyle,
                hasHeader && "has-header",
                "collection-item",
                !hasImage && "no-image",
                (desc.title?.length ?? 0) === 0 && "no-title",
                !hasSubtitle && "no-subtitle",
                !hasEmphasis && "no-emphasis",
                menuActions.length > 0 && "has-menu-action"
            )}
            css={css`
                &.${UIStyleVariant.Default} {
                    ${tw`rounded-none`}
                }

                .flat &.${UIStyleVariant.Default} {
                    ${tw`after:border-none rounded-md odd:(bg-n50)`}
                }

                .flat &.${UIStyleVariant.Default}.has-header {
                    ${tw`even:(bg-n100) odd:(bg-bg-front)`}
                }

                // Fix(mauri) Temporary workaround until we properly support dark theme.
                .dark.flat &.${UIStyleVariant.Default} {
                    ${tw`not-last:after:(border-b border-border-base) rounded-none`}
                }

                &.${UIStyleVariant.Minimal} {
                    ${tw`bg-transparent`}
                }

                &.${UIBackgroundStyle.Image} {
                    ${tw`text-text-dark`}
                }
            `}
            tw="relative bg-bg-front rounded-lg text-text-contextual-dark items-center grid w-full overflow-hidden
                transition-colors [--button-opacity: 0] page-hover:([--button-opacity: 1]) no-hover:([--button-opacity: 1])
                focus-within:([--button-opacity: 1]) focus-visible:([--button-opacity: 1]) after:(content-[''] absolute inset-0
                pointer-events-none border-border-base) not-last:after:(border-b border-border-base) focus-visible:(ring-2 ring-inset
                ring-text-contextual-accent)
                gp-md:[height: 52px]">
            <div
                className={classNames(hasClickAction && "has-click-action", styleVariant, isFlat && "flat")}
                tw="hidden col-start-1 row-start-1 transition-colors duration-75 h-full
                    gp-md:block"
                css={css`
                    &.has-click-action {
                        ${tw`group-page-hover:(bg-bg-hovered cursor-pointer)`}
                    }

                    &.flat.${UIStyleVariant.Default}.has-click-action {
                        --accent-alpha-hover: ${accentAlpha};
                        ${tw`group-page-hover:(background[var(--accent-alpha-hover)])`}
                    }
                `}
            />

            <div
                className={styleVariant}
                tw="col-start-1 row-start-1 grid p-2 text-sm page-md:text-base leading-normal text-text-contextual-pale gap-0.5 w-full
                    gp-md:(gap-2 px-4 w-auto)"
                css={css`
                    --bounded-properties-count: ${boundPropertiesCount};

                    ${tw`[grid-template-columns:46px minmax(0, auto) min-content] grid-rows-[minmax(0, 1fr)] [grid-template-areas: "image title" "image
                      subtitle"]`}

                    .no-image & {
                        ${tw`[grid-template-columns:minmax(0, auto) min-content] grid-rows-[minmax(0, 1fr)] [grid-template-areas: "title" "subtitle"]`}
                    }

                    .${UIBackgroundStyle.Image} & {
                        ${tw`text-text-dark`}
                    }

                    &.${UIStyleVariant.Minimal} {
                        ${tw`px-0 bg-transparent text-text-contextual-dark`}
                    }

                    ${tw`gp-md:([grid-template-columns:repeat(var(--bounded-properties-count), minmax(0, 1fr)) auto]
                          [grid-template-areas: unset])`}
                `}>
                {hasTitle && (
                    <>
                        {hasImage && (
                            <Img
                                data-testid="mobile-image"
                                src={massageImageUrl(desc.image, { thumbnail: true, width: 36, height: 36 }, appID)}
                                tw="w-9 h-9 mr-2.5 shrink-0 object-cover bg-n50 rounded-lg row-start-1
                                    [grid-row-end: -1] self-center text-2xl
                                    gp-md:hidden!"
                                isPages={true}
                                className={imageStyle}
                                css={css`
                                    &.${UIImageStyle.Circle} {
                                        ${tw`rounded-full`}
                                    }
                                `}
                            />
                        )}
                        <div
                            css={css`
                                .${UIBackgroundStyle.Image} & {
                                    ${tw`text-text-dark`}
                                }

                                .${UIStyleVariant.Minimal} & {
                                    ${tw`text-text-contextual-dark`}
                                }

                                .no-subtitle.no-emphasis & {
                                    ${tw`row-start-1 [grid-row-end: -1]`}
                                }
                            `}
                            tw="text-text-contextual-dark font-semibold w-full flex items-center [grid-area: title]
                                gp-md:[grid-area: unset]">
                            {hasImage && (
                                <Img
                                    data-testid="desktop-image"
                                    src={massageImageUrl(desc.image, { thumbnail: true, width: 32, height: 32 }, appID)}
                                    tw="w-8 h-8 hidden! mr-4 shrink-0 object-cover bg-n50 rounded-lg
                                        gp-md:block!"
                                    isPages={true}
                                    className={imageStyle}
                                    css={css`
                                        &.${UIImageStyle.Circle} {
                                            ${tw`rounded-full`}
                                        }
                                    `}
                                />
                            )}
                            <TextWithEllipsis testId="title" clickable={hasClickAction}>
                                {desc.title}
                            </TextWithEllipsis>
                        </div>
                    </>
                )}

                {hasSubtitle && (
                    <div
                        tw="hidden items-center
                            gp-md:flex">
                        <TextWithEllipsis testId="desktop-subtitle" clickable={hasClickAction}>
                            {desc.subtitle}
                        </TextWithEllipsis>
                    </div>
                )}

                {hasEmphasis && (
                    <div
                        tw="hidden items-center
                            gp-md:flex">
                        <TextWithEllipsis testId="desktop-emphasis" clickable={hasClickAction}>
                            {desc.emphasis}
                        </TextWithEllipsis>
                    </div>
                )}

                <div
                    css={css`
                        .no-title & {
                            ${tw`row-start-1 [grid-row-end: -1]`}
                        }

                        .${UIBackgroundStyle.Image} & {
                            ${tw`text-text-pale`}
                        }
                    `}
                    tw="grid grid-area[subtitle] [grid-template-columns:minmax(0, min-content) minmax(0, min-content)]
                        text-text-contextual-pale max-w-full
                        gp-md:hidden">
                    {hasRenderableSubtitle && (
                        <div tw="flex items-center">
                            <TextWithEllipsis testId="mobile-subtitle" clickable={hasClickAction}>
                                {desc.subtitle}
                            </TextWithEllipsis>
                            {hasRenderableEmphasis && (
                                <span
                                    data-testid="mobile-separator"
                                    tw="mx-1 text-text-contextual-xpale
                                        gp-md:hidden">
                                    ·
                                </span>
                            )}
                        </div>
                    )}
                    {hasRenderableEmphasis && (
                        <div tw="flex items-center">
                            <TextWithEllipsis testId="mobile-emphasis" clickable={hasClickAction}>
                                {desc.emphasis}
                            </TextWithEllipsis>
                        </div>
                    )}
                </div>

                <TableActionsButton
                    menuActions={menuActions}
                    hasClickAction={hasClickAction}
                    onItemClick={onItemClick}
                />
            </div>
        </div>
    );
};
