import { useChangeObservable } from "@glide/common";
import type { ChangeObservable } from "@glide/support";
import { Watchable } from "@glide/support";
import React from "react";
import type { Subtract } from "utility-types";
import { NetworkStatus } from "../network-status";

const online: Watchable<NetworkStatus> = new Watchable<NetworkStatus>(
    (function () {
        try {
            return window.navigator.onLine ? NetworkStatus.Online : NetworkStatus.Offline;
        } catch {
            return NetworkStatus.Offline;
        }
    })()
);

type OnlineHandler = (online: NetworkStatus) => void;

export function getNetworkStatusChangeObservable(): ChangeObservable<NetworkStatus> {
    return online;
}

// NOTE: This function should only be called as a last resort in case the caller needs to know the network status
// outside of React or the existing network handling code.  If you do call this, please ensure that you call it at a
// reasonable interval so your caller keeps itself up-to-date on the online status of the application.
export function getIsOnline(): boolean {
    return online.current !== NetworkStatus.Offline;
}

export function setIsOnline(onlineValue: NetworkStatus): void {
    if (online.current === onlineValue) return;
    online.current = onlineValue;
}

export function addOnlineHandler(handler: OnlineHandler): void {
    online.subscribe(handler);
}

export function deleteOnlineHandler(handler: OnlineHandler): void {
    online.unsubscribe(handler);
}

export function useNetworkStatus(): boolean {
    const onLine = useChangeObservable(online);

    // This hook will only run if Firestore errors out while we think we're online.
    // We'll give Firestore a moment to get its act together before we declare the user disconnected.
    React.useEffect(() => {
        if (onLine !== NetworkStatus.Disconnecting) return;
        const timeout = setTimeout(() => {
            setIsOnline(NetworkStatus.Offline);
        }, 5000);
        return () => clearTimeout(timeout);
    }, [onLine]);

    return onLine !== NetworkStatus.Offline;
}

interface WithNetworkStatusProps {
    readonly onLine: boolean;
}

export const withNetworkStatus =
    <P extends WithNetworkStatusProps>(
        Component: React.ComponentType<P>
    ): React.FC<React.PropsWithChildren<Subtract<P, WithNetworkStatusProps>>> =>
    p => {
        const onLine = useNetworkStatus();
        return <Component {...(p as P)} onLine={onLine} />;
    };
