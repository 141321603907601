/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

const { Result } = glide;

const apiKey = glide.makeParameter({
    type: "secret",
    name: "API key",
    placeholder: "Enter your Yelp API Key",
    description: "[Learn more](https://www.yelp.com/developers/v3/manage_app) about getting an API key",
});

export const plugin = glide.newPlugin({
    id: "yelp",
    name: "Yelp",
    description: "Find information about businesses",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/yelp.png",
    tier: "starter",
    parameters: {
        apiKey,
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/yelp",
});

// This secret will automatically be injected into calls to context.fetch
plugin.useSecret({
    baseUrl: "https://api.yelp.com/v3/",
    kind: "authorization-bearer",
    value: apiKey,
});

const address = glide.makeParameter({
    type: "string",
    name: "Address",
    description: "Physical location",
    placeholder: "e.g. 1600 Pennsylvania Ave",
});

const latLng = glide.makeParameter({
    type: "string",
    name: "Latitude, Longitude",
    description: "Latitude and Longitude as a string",
    placeholder: "e.g. 38.8976633,-77.0365739",
});

const name = glide.makeParameter({
    type: "string",
    name: "Name",
});

const rating = glide.makeParameter({
    type: "number",
    name: "Rating",
});

const phoneNumber = glide.makeParameter({
    type: "string",
    name: "Phone Number",
});

const businessId = glide.makeParameter({
    type: "string",
    name: "Business ID",
    description: "Yelp Business ID",
    placeholder: "e.g. yelp-business-id",
});

const alias = glide.makeParameter({
    type: "string",
    name: "Alias",
});

const imageUrl = glide.makeParameter({
    type: "string",
    name: "Image URL",
});

const isClosed = glide.makeParameter({
    type: "boolean",
    name: "Is Closed",
});

const url = glide.makeParameter({
    type: "string",
    name: "URL",
});

const reviewCount = glide.makeParameter({
    type: "number",
    name: "Review Count",
});

const hours = glide.makeParameter({
    type: "string",
    name: "Hours",
});

const category = glide.makeParameter({
    type: "string",
    name: "Category",
});

const price = glide.makeParameter({
    type: "string",
    name: "Price",
});

const displayPhone = glide.makeParameter({
    type: "string",
    name: "Display Phone",
});

function formatHours(hoursArray: any[] | undefined) {
    if (hoursArray === undefined || hoursArray.length === 0) {
        return "";
    }
    const hours = hoursArray[0].open;
    return hours
        .map((hour: any) => {
            const dayOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"][hour.day];
            return `${dayOfWeek}: ${hour.start.slice(0, 2)}:${hour.start.slice(2)}-${hour.end.slice(
                0,
                2
            )}:${hour.end.slice(2)}`;
        })
        .join(", ");
}

plugin.addComputation({
    id: "find-business",
    name: "Find business",
    description: "Find information about a business based on geocoordinates or address",
    billablesConsumed: 1,
    parameters: { latLng, address },
    results: {
        name,
        rating,
        phoneNumber,
        businessId,
        alias,
        imageUrl,
        isClosed,
        url,
        reviewCount,
        category,
        price,
        displayPhone,
    },

    async execute(context, { latLng, address }) {
        if (latLng === undefined && address === undefined) return Result.Ok({});

        const url = new URL("https://api.yelp.com/v3/businesses/search");
        if (latLng !== undefined) {
            const [latitude, longitude] = latLng.split(",");
            url.searchParams.append("latitude", latitude);
            url.searchParams.append("longitude", longitude);
        } else if (address !== undefined) {
            url.searchParams.append("location", address);
        }
        url.searchParams.append("sort_by", "distance");
        url.searchParams.append("limit", "1");

        const fetchBusiness = async () => {
            try {
                const response = await context.fetch(url.toString());
                const data = await response.json();
                if (!response.ok) {
                    return Result.FailFromHTTPStatus(data.error?.description ?? "An error occurred", response.status, {
                        data,
                    });
                }

                const business = data.businesses?.[0];
                if (business === undefined) {
                    return Result.FailPermanent("No business found", {
                        isPluginError: false,
                    });
                }

                context.consumeBillable();
                return Result.Ok({
                    name: business.name,
                    rating: business.rating,
                    phoneNumber: business.phone,
                    businessId: business.id,
                    alias: business.alias,
                    imageUrl: business.image_url,
                    isClosed: business.is_closed,
                    url: business.url,
                    reviewCount: business.review_count,
                    category: business.categories.map((c: any) => c.title).join(", "),
                    price: business.price,
                    displayPhone: business.display_phone,
                });
            } catch {
                return Result.Fail("An unknown error occurred");
            }
        };

        return await context.useCache(fetchBusiness, ["find-business", latLng, address]);
    },
});

plugin.addComputation({
    id: "get-business-hours",
    name: "Get business hours",
    description: "Get hours for a business by its Yelp Business ID",
    billablesConsumed: 1,
    parameters: { businessId },
    results: { hours },

    async execute(context, { businessId }) {
        if (businessId === undefined) return Result.Ok({});

        const url = new URL(`https://api.yelp.com/v3/businesses/${encodeURIComponent(businessId)}`);

        const fetchBusinessHours = async () => {
            try {
                const response = await context.fetch(url.toString());
                const data = await response.json();
                if (!response.ok) {
                    return Result.FailFromHTTPStatus(data.error?.description ?? "An error occurred", response.status, {
                        data,
                    });
                }

                context.consumeBillable();
                return Result.Ok({
                    hours: formatHours(data.hours),
                });
            } catch {
                return Result.Fail("An unknown error occurred");
            }
        };

        return await context.useCache(fetchBusinessHours, ["get-hours", businessId]);
    },
});
