import type { HeightVariation } from "./glide-dropdown-button-style";
import { DropdownValue, DropdownValueItem, IconWrapper } from "./glide-dropdown-button-style";
import type { IconName } from "@glide/common";
import { AppIcon, GlideIcon, getNamedIcon } from "@glide/common";
import { ThemedIcon } from "../../themed-icon/themed-icon";

export interface NameAndIcon {
    name: string;
    icon?: React.ReactNode;
}

interface Props {
    isOpen: boolean;
    values: NameAndIcon[];
    heightVariation?: HeightVariation;
}

export const RenderIcon: React.FC<{ icon: React.ReactNode | string | undefined }> = p => {
    const { icon } = p;
    if (icon === undefined) return null;
    if (typeof icon === "string") {
        if (getNamedIcon(icon as IconName) !== undefined) {
            return <ThemedIcon icon={icon as IconName} />;
        } else {
            return <AppIcon size={16} icon={icon} />;
        }
    } else {
        return <>{icon}</>;
    }
};

export const MultiPartValue: React.FC<Props> = p => {
    const { values, heightVariation, isOpen } = p;

    return (
        <DropdownValue>
            {values.map((v, i) => (
                <DropdownValueItem key={i}>
                    {v.icon !== undefined && (
                        <IconWrapper heightVariation={heightVariation} isOpen={isOpen}>
                            <RenderIcon icon={v.icon} />
                        </IconWrapper>
                    )}
                    <div className="part-text" data-testid="glide-dropdown-button-part-text">
                        {v.name}
                    </div>
                    <div className="caret">
                        <GlideIcon kind="stroke" iconSize={16} icon="st-caret" rotateDeg={-90} />
                    </div>
                </DropdownValueItem>
            ))}
        </DropdownValue>
    );
};
