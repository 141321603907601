export enum ListItemFlags {
    None = 0,
    Inert = 1,
    WrapText = 1 << 1,
    InvertTitleAndSubtitle = 1 << 2,
    SwapImageSide = 1 << 3,
    DisableChevron = 1 << 4,
    LargeImage = 1 << 5,
    DrawSeparator = 1 << 6,
    SmallImage = 1 << 7,
    ReserveImageSpace = 1 << 8,
    ReserveDetailSpace = 1 << 9,
    ReserveCaptionSpace = 1 << 10,
    BigCaption = 1 << 11,
    TruncateWrap = 1 << 12,
    DisableLongPress = 1 << 13,
    LightWeight = 1 << 14,
    ConvertNewlines = 1 << 15,
    HorizontalLayout = 1 << 16,
    ForceListStyle = 1 << 17,
}

export enum ListItemAccessoryPosition {
    Left = "left",
    TopRight = "top-right",
    Right = "right",
}
