import type { Description } from "@glide/type-schema";
import {
    type PropertyDescription,
    getEnumProperty,
    getGeneratedKeyPairProperty,
    getNumberProperty,
    getSecretProperty,
    getStringProperty,
    getSwitchProperty,
} from "@glide/app-description";
import type { ParameterProps, ParameterPropsBase } from "@glide/plugins";
import { isSyncEnumValues } from "@glide/plugins-codecs";
import { assert } from "@glideapps/ts-necessities";

type ParameterPropsWithPropertyName = ParameterProps & {
    // The name of the property in the action description
    readonly propertyName: string;
};

export type Parameter = ParameterPropsWithPropertyName & {
    // The internal parameter name
    readonly parameterName: string;
    readonly withFormat: boolean;
};

export function isParameterActive(
    p: ParameterProps,
    parameters: readonly ParameterPropsWithPropertyName[],
    desc: Description | undefined,
    actionOrColumn: "action" | "column" | undefined
): boolean {
    if (p.whenInContext !== undefined && actionOrColumn !== undefined && p.whenInContext !== actionOrColumn) {
        return false;
    }

    if (p.dependsOn === undefined) {
        return true;
    }

    if (desc === undefined) return false;
    const sourceParam = parameters.find(x => x.name === p.dependsOn);
    if (sourceParam === undefined) return false;
    const pd: PropertyDescription = (desc as any)[sourceParam.propertyName];
    let val: string | number | boolean | undefined;
    if (sourceParam.type === "number") {
        val = getNumberProperty(pd);
    } else if (sourceParam.type === "string" || sourceParam.type === "url") {
        val = getStringProperty(pd);
    } else if (sourceParam.type === "enum") {
        if (isSyncEnumValues(sourceParam.values)) {
            val = getEnumProperty(pd) ?? sourceParam.values[0]?.value;
        } else {
            val = getEnumProperty(pd);
        }
    } else if (sourceParam.type === "boolean") {
        val = getSwitchProperty(pd);
    } else if (sourceParam.type === "secret") {
        val = getSecretProperty(pd);
    } else if (sourceParam.type === "generatedKeyPair") {
        val = getGeneratedKeyPairProperty(pd);
    }

    if (val === undefined) return false;

    assert(p.when !== undefined && p.operator !== undefined);
    const has = p.when.indexOf(val) !== -1;
    if (p.operator === "is" && !has) return false;
    if (p.operator === "is-not" && has) return false;

    return true;
}

export const EMPTY_WARNING_TEXT = "Required";

// We use a prefix for actions because there the description object doesn't
// just contain the parameters, but is also an `ActionDescription`, so we
// can't collide with the properties of that.
export function preProcessParameters(
    parameterProps: Record<string, ParameterPropsBase>,
    namePrefix: string
): readonly Parameter[] {
    return Object.entries(parameterProps ?? {}).map(([parameterName, props]) => {
        return {
            ...props,
            parameterName,
            propertyName: `${namePrefix}${parameterName}`,
            withFormat: props.type === "string",
            showWarning: props.required ?? false,
            emptyWarningText: props.required === true ? EMPTY_WARNING_TEXT : undefined,
        };
    });
}
