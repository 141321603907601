import "twin.macro";
import { Modal } from "../windowed-modal-next";
import { ImportFromSourceWideBox } from "../../data-sources/import-from-external-source";
import React from "react";
import { GlideIcon } from "@glide/common";

type ImportOrSync = "import" | "sync";

type ImportDataOrSyncModalProps = {
    onBack: () => void;
    onClose: () => void;
    onContinue: (importType: ImportOrSync) => void;
};

export const ImportDataOrSyncModal: React.FC<React.PropsWithChildren<ImportDataOrSyncModalProps>> = p => {
    const { onBack, onClose, onContinue } = p;

    const [selectedImportType, setSelectedImportType] = React.useState<ImportOrSync | undefined>();

    const handleContinue = () => {
        if (selectedImportType === undefined) return;
        onContinue(selectedImportType);
    };

    return (
        <Modal.Container onClose={onClose}>
            <Modal.Window size={"md"} tw="min-w-fit">
                <Modal.TitleBar tw="py-4 pr-5 pl-6 text-builder-2xl items-center">
                    <Modal.Title>How would you like to handle your data?</Modal.Title>
                    <Modal.Close onClose={onClose} />
                </Modal.TitleBar>
                <Modal.Content tw="py-2 px-8 space-y-5">
                    <ImportFromSourceWideBox
                        label="Keep app and Google Sheet in sync"
                        icon={<GlideIcon tw="text-accent" kind="stroke" icon="st-sync" iconSize={32} />}
                        selected={selectedImportType === "sync"}
                        onClick={() => setSelectedImportType("sync")}
                        labelDescription="Sheet edits update your app, and app edits update your sheet"
                        accentPillLabel="Upgrade"
                    />
                    <ImportFromSourceWideBox
                        label="Import Google Sheet once"
                        icon={
                            <GlideIcon
                                tw="text-text-dark"
                                kind="stroke"
                                icon="st-arrow-full"
                                rotateDeg={-90}
                                iconSize={32}
                            />
                        }
                        selected={selectedImportType === "import"}
                        onClick={() => setSelectedImportType("import")}
                        labelDescription="Your app will use a separate copy of your sheet's data"
                        greyPillLabel="Free"
                    />
                </Modal.Content>
                <Modal.ButtonBar tw="py-6 px-8 pt-6" align="split">
                    <Modal.Button
                        tw="h-10 m-0"
                        buttonType="minimal"
                        size="lg"
                        label={"Back"}
                        variant="default"
                        onClick={onBack}
                    />
                    <Modal.Button
                        tw="h-10 m-0 bg-accent"
                        buttonType="primary"
                        size="lg"
                        disabled={selectedImportType === undefined}
                        label={selectedImportType === "sync" ? "Upgrade" : "Continue"}
                        variant="default"
                        onClick={handleContinue}
                    />
                </Modal.ButtonBar>
            </Modal.Window>
        </Modal.Container>
    );
};
