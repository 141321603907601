import type { LocationSettings, AppKind } from "@glide/location-common";
import type { ChangeObservable, JSONObject, MapLocation } from "@glide/support";
import { hasOwnProperty } from "@glideapps/ts-necessities";
import type { AppAnalyticsEvents } from "../analytics/app-events";
import type {
    LoadedGroundValue,
    LoadingValue,
    ResolvedGroundValue,
    Row,
    ComputationModel,
    TableKeeper,
    TableKeeperStore,
    RowIndex,
    Query,
} from "@glide/computation-model-types";
import type { Database } from "../Database/core";
import type { DataSnapshot, DocumentDataWithID, PublishedAppSnapshot } from "../Database";
import type {
    TableName,
    TableGlideType,
    TableGlideTypeWithUniversalName,
    TypeSchema,
    SourceMetadata,
    NativeTableID,
    UserProfileTableInfo,
} from "@glide/type-schema";
import type { EminenceFlags } from "@glide/billing-types";
import type { QuotaKind, QuotaValues } from "../Database/quotas";
import type {
    AppSnapshotURLs,
    RunIntegrationsBody,
    RunIntegrationsInstance,
    SQLQueryBase,
    WriteSourceType,
    WritebackResponse,
} from "../firebase-function-types";
import type { AppLoginTokenContainer } from "../integration-types";
import type { YesCodeValue } from "../yes-code-types";
import * as types from "./computation-types";
import type { AppUserData, DataRowStore } from "./datastore/data-row-store";
import type { AppFeatures, PluginConfig } from "@glide/app-description";
import type { AppData, BaseAppData, ParameterRecord, Result, WaitForSignalResult } from "@glide/plugins";

/** @deprecated This is a remnant of the old computation model. */
export const LOADING = null;
/** @deprecated This is a remnant of the old computation model. */
export type Loading = typeof LOADING;

export type ActionSource = types.ActionSource;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionSource = types.ActionSource;

// Version 2:
//
// * The property names of the `rows` are the column names, vs Firestore field
//   names, i.e. they don't need to be converted.
// * Rows have a `convert` field that has the names of all columns with values
//   that need to be converted.
//
// The default is version 1.  We never actually write snapshots that have a
// version set to 1 - it's always implicit.
export type SnapshotFormatVersion = 1 | 2;

export interface NativeTableSnapshotMetadata {
    readonly formatVersion?: SnapshotFormatVersion;
    // indexed by app ID
    readonly appDataVersions: Record<string, number>;
    // This is optional, because historically we haven't set it.
    // We primarily use this to coordinate snapshot rebuilding without having
    // to load everything from Firestore again.
    readonly nativeTableVersion?: number;
    // We want to make sure that we aren't accidentally preserving inconsistent
    // data, though, so we only will reuse the snapshot data for a particular
    // time period. This number, a Unix timestamp in milliseconds, indicates
    // when the last full rebuild occurred.
    readonly lastSnapshotFullRebuildTime?: number;
}

export interface NativeTableSnapshotRow extends DocumentDataWithID {
    // In version 2 this is the names of all columns with data that has to be
    // "converted".  Currently that's only date-times.  Missing means no
    // columns need converting - we do that to save space.
    readonly convert?: readonly string[];
}

export interface NativeTableSnapshot extends NativeTableSnapshotMetadata {
    readonly rows: readonly NativeTableSnapshotRow[];
}

export interface TableSnapshot {
    readonly formatVersion: SnapshotFormatVersion;
    readonly rows: readonly NativeTableSnapshotRow[];
    readonly version: number;
}

// This is for "early" snapshots that we load eagerly.  It would be nice if we
// could unify this interface with `DataSnapshotLoader`.
export interface DataSnapshotProvider {
    getSnapshotForTable(tableName: TableName): TableSnapshot | undefined;
}

// FIXME: Simplify this interface and unify it with `DataSnapshotProvier`.  It
// would be nice if this interface didn't have to deal with what kind of
// snapshot it is.  Or maybe there has to be layer above it?
export interface DataSnapshotLoader {
    // If `tableName` is given, a `TableSnapshot` may be returned and that table data removed from the cache,
    //  which decreases memory consumption. Otherwise, a `DataSnapshot` is returned as before.
    loadPublicDataSnapshot(
        appID: string,
        tableName: string | undefined
    ): Promise<TableSnapshot | DataSnapshot | undefined>;
    loadPrivateDataSnapshot(
        appID: string,
        tableName: string | undefined
    ): Promise<TableSnapshot | DataSnapshot | undefined>;
    loadUnusedDataSnapshot(
        appID: string,
        tableName: string | undefined
    ): Promise<TableSnapshot | DataSnapshot | undefined>;

    loadNativeTableSnapshot(appID: string, tableName: TableName): Promise<NativeTableSnapshot | undefined>;
}

export interface IntegrationInstanceRequest {
    readonly data: RunIntegrationsInstance;
}

export type IntegrationInstanceResult = Result<WritebackResponse | WaitForSignalResult<ParameterRecord>>;

export interface IntegrationsAggregator {
    runIntegration(instanceData: IntegrationInstanceRequest): Promise<IntegrationInstanceResult>;
}

export interface ActionAppFacilities {
    // This must be usable as a first-class function, not just as a method,
    // i.e. it must not assume it's called with a specific `this`.
    readonly fetch: typeof globalThis.fetch;

    // We use these so we can make tests deterministic
    readonly deviceID: string;
    makeRowID(): string;
    makeUUID(): string;

    getAuthUserID(): Promise<string | undefined>;

    callCloudFunction(
        functionName: string,
        body: any,
        headers: { [key: string]: string },
        stringify?: boolean
    ): Promise<Response | undefined>;
    callAuthCloudFunction(
        functionName: string,
        body: any,
        headers?: { [key: string]: string },
        stringify?: boolean
    ): Promise<Response | undefined>;
    callAuthIfAvailableCloudFunction(
        functionName: string,
        body: any,
        headers: { [key: string]: string },
        stringify?: boolean
    ): Promise<Response | undefined>;
    callServiceGateway(
        endpoint: string,
        body: any,
        headers: { [key: string]: string },
        stringify?: boolean,
        method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE"
    ): Promise<Response | undefined>;
    callAuthServiceGateway(
        endpoint: string,
        body: any,
        headers: { [key: string]: string },
        stringify?: boolean,
        method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE"
    ): Promise<Response | undefined>;
    callAuthIfAvailableServiceGateway(
        endpoint: string,
        body: any,
        headers: { [key: string]: string },
        stringify?: boolean,
        method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE"
    ): Promise<Response | undefined>;

    getIntegrationsAggregator(base: Omit<RunIntegrationsBody, "instances">): IntegrationsAggregator;

    mapURL(location: MapLocation): string | undefined;

    getInitialSchemaForAppID?(appID: string): TypeSchema | undefined;
}

export interface MinimalAppFacilities extends DataSnapshotLoader, ActionAppFacilities {
    loadPublishedAppSnapshot(appID: string): Promise<PublishedAppSnapshot | undefined>;

    getInitialSchemaForAppID(appID: string): TypeSchema | undefined;
    getNumRowsUsedByAppID(appID: string): number | undefined;

    trackEvent?<Name extends keyof AppAnalyticsEvents>(event: Name, options: AppAnalyticsEvents[Name]): void;
}

export type YesCodeParameterMap = ReadonlyMap<string, readonly [value: unknown, formatted: unknown]>;

export interface AppFacilities extends MinimalAppFacilities {
    readonly locationSettings: LocationSettings;

    isSignedInToFirebase(): Promise<boolean>;
    getAppUserEmail(): Promise<string | undefined>;
    getIDToken(): Promise<string | undefined>;
    signInWithCustomToken(token: string): Promise<boolean>;

    // FIXME: This does not belong in here.  Anything specific to an app
    // should be in the app environment.
    setSnapshotLocationsForAppID(appID: string, locations: AppSnapshotURLs): void;
    loadPublishedAppFromLocalStorage(appID: string): PublishedAppSnapshot | undefined;
    setInitialSchemaForAppID(appID: string, schema: TypeSchema): void;

    // This is just for optimization.  It's technically ok if it doesn't do
    // anything.
    eagerLoadAppSnapshot(appID: string): Promise<void>;
}

export enum ActionResult {
    CopiedToClipboard = "copied-to-clipboard",
    ZapierTrigger = "zapier-trigger",
}

interface GCReadinessIndicator {
    // This has to be `true` when all queries have been requested.
    readonly isReadyForGC: boolean;
    // Called by the queryable data store when a GC is started.  Has to reset
    // the `isReadyForGC` flag.
    resetReadyForGC(): void;
}

export interface QueryableRowsRootFinder extends GCReadinessIndicator {
    getRowIDsForTable(tableName: TableName): ReadonlySet<string>;
}

export interface ActionOperations {
    readonly addRowToTable: number;
    readonly setColumnsInRow: number;
    readonly deleteRow: number;
}

export const emptyActionOperations: ActionOperations = {
    addRowToTable: 0,
    setColumnsInRow: 0,
    deleteRow: 0,
};

export interface ActionOperationsState {
    readonly started: ActionOperations;
    readonly performed: ActionOperations;

    // This is a subset of the above.  It's the ones that we need to show a
    // "Saving..." message for.
    readonly savingMessageStarted: ActionOperations;
    readonly savingMessagePerformed: ActionOperations;
}

export const emptyActionOperationsState: ActionOperationsState = {
    started: emptyActionOperations,
    performed: emptyActionOperations,
    savingMessageStarted: emptyActionOperations,
    savingMessagePerformed: emptyActionOperations,
};

export type ActionOutstandingOperationsHandler = (ops: ActionOperationsState) => void;

export interface ActionOutstandingOperationSubscribable {
    subscribeToOutstandingOperations(handler: ActionOutstandingOperationsHandler): void;
    unsubscribeFromOutstandingOperations(handler: ActionOutstandingOperationsHandler): void;
    getActionOutstandingOperations(): ActionOperationsState;
}

export interface MutationResult {
    // If this is `undefined`, it means that either no job was created, or we
    // don't know the job ID because we haven't awaited the call to the
    // backend.
    readonly jobID: string | undefined;
    // `true` means it's confirmed, but we don't have a version.  `false`
    // means it failed.  `undefined` means we don't have the version yet.
    readonly confirmedAtVersion: number | boolean | undefined;
}

export interface AddRowToTableResult extends MutationResult {
    readonly didAdd: boolean;
    readonly playerRow: Row | undefined;
    readonly builderRow: Row | undefined;
}

export interface DataStoreMutationMetadata {
    readonly fromDataEditor: boolean;
    readonly screenPath?: string;
}

export interface DataStoreMutationOptions extends DataStoreMutationMetadata {
    readonly tableName: TableName;
    /**
     * If this is `false`, then the mutation will be applied locally, but not
     * sent to the backend to be persisted.  We need this in the case where we
     * have mutations that have already been persisted but not applied
     * locally.  In automations that happens when a run does a mutation that
     * is not confirmed synchronously by the backend (because it goes through
     * the Rube Goldberg machine), and then another worker takes over the run.
     * That other worker might get table data from the backend that does not
     * include the mutation yet, so it has to apply the mutation locally
     * itself.  https://github.com/glideapps/glide/issues/29315
     */
    readonly sendToBackend: boolean;
    readonly setUnderlyingData: boolean;
    readonly awaitSend: boolean;
    readonly onError: ((e: Error) => void) | undefined;
}

export interface DataStore extends ActionOutstandingOperationSubscribable {
    readonly schema: TypeSchema;

    getComputationModelObservable(
        forBuilder: boolean,
        /** If `true`, don't (re)initialize the computation model.  We set
         * this when retiring, so we don't depend on weird initialization
         * order. */
        noInit?: boolean
    ): ChangeObservable<ComputationModel | undefined>;

    fetchTableRows(tableName: TableName, forBuilder: boolean): void;

    // We only use this in the builder for now.
    getFirstRowObservable(tableName: TableName, isBuilder: boolean): ChangeObservable<Row | undefined> | undefined;

    // This is called by the forwarding app environment when its delegate
    // changes.
    appEnvironmentUpdated(): void;

    setEarlySnapshotProvider?(provider: DataSnapshotProvider): void;

    addRowToTable(
        options: DataStoreMutationOptions,
        columnValues: Record<string, LoadedGroundValue>,
        // FIXME: Do we really also need `columnNames` here?  Why not just
        // take whatever's in `values`?
        columnNames: ReadonlySet<string> | undefined
    ): Promise<AddRowToTableResult>;
    setColumnsInRow(
        options: DataStoreMutationOptions,
        rowIndex: RowIndex,
        columnValues: Record<string, LoadedGroundValue>,
        withDebounce: boolean,
        onCompletion: (() => void) | undefined,
        // If this is set, it means that the action document has already been
        // created independently, and that it shouldn't be sent again.  The
        // only thing that should happen is to do the change locally, and to
        // listen to the action document for confirmation of the change.
        existingJobID: string | undefined,
        // If we already have a confirmed version then we don't need to post
        // the action, or listen to the action document.  It also means that
        // `awaitSend` is moot.
        confirmedAtVersion: number | undefined
    ): Promise<MutationResult>;
    deleteRowsAtIndexes(options: DataStoreMutationOptions, rowIndexes: readonly RowIndex[]): Promise<MutationResult>;

    setEmailOwnersColumns(tableName: TableName, emailOwnersColumns: readonly string[]): void;
    afterSetEmailOwnersColumns?(tableName: TableName): void; // this runs after the app data update

    // This is used in the Data Editor to reset the schema
    setSchema(schema: TypeSchema | undefined): void;
    // Will rebuild the computation model.  Called from the app environment.
    userProfileTableChanged(): void;

    isRowOwnedByUser(tableName: TableName, row: Row): boolean;

    // This is specifically for FirestoreDataStore, which needs to re-load
    // from snapshots and listeners on quota changes.
    // ##dataStoreRowQuotaChange
    resetFromUpstream(): void;
    // FIXME: Add methods telling the datastore to start/stop listening

    addRowOwnerChangeCallback(cb: () => void): void;
    removeRowOwnerChangeCallback(cb: () => void): void;

    retire(): void;

    getAppUserID(): string | undefined;
}

export interface LocalDataStore extends DataStore {
    readonly tableKeeperStore: TableKeeperStore<TableKeeper>;
}

export type OnQuerySaveHandler = (table: TableGlideType, queryID: NativeTableID, remoteSerial: number) => Promise<void>;
// `undefined` means the user canceled
export type GetOverrideRowID = (table: TableGlideTypeWithUniversalName) => Promise<string | undefined>;

export interface QueryableDataStore extends DataStore {
    setAppUserDataObservable(observable: ChangeObservable<AppUserData>): void;

    // These methods are used in the player (and probably also the builder).
    getDataRowStoreForTable(tableName: TableName, forBuilder: boolean): (DataRowStore & TableKeeper) | undefined;

    // Will return `undefined` if the query cannot be run for some reason.
    fetchQuery(query: Query, onChange: () => void, forBuilder: boolean): ResolvedGroundValue | LoadingValue;
    getLocallyModifiedRowIDs(tableName: TableName): ReadonlySet<string>;
    // `version` will subsequently require at least that version from the
    // backend.
    resetQueryByTableName(tableName: TableName, version: number | undefined): void;

    // Are there any queries currently running?  We use this in the player to
    // show a toast when queries are running.
    getAreSubscribedQueriesRunning(forBuilder: boolean): ChangeObservable<boolean>;

    // Everything that follows is used by the builder only.
    readonly previewSchemaSerial: ChangeObservable<number>;

    getNumberOfRowsInTable(tableName: TableName): ChangeObservable<number | undefined> | undefined;

    getQueryRunningFlag(tableName: TableName): ChangeObservable<boolean> | undefined;
    getQueryErrorMessage(tableName: TableName): ChangeObservable<string | undefined> | undefined;
    getQueryWarningMessage(tableName: TableName): ChangeObservable<string | undefined> | undefined;
    runQuery(tableName: TableName): void;

    getPreviewTableForNew(sourceKind: string, sourceID: string): TableGlideType;
    getPreviewTableForExisting(queryID: string): TableGlideType;

    getPreviewQueryCanSaveFlagForNew(sourceKind: string, sourceID: string): ChangeObservable<boolean>;
    setPreviewQueryForNew(sourceKind: string, sourceID: string, queryName: string, queryString: String): void;
    clearPreviewQueryForNew(sourceKind: string, sourceID: string): void;
    savePreviewQueryForNew(
        sourceKind: string,
        sourceID: string,
        getOverrideRowID: GetOverrideRowID,
        onSave?: OnQuerySaveHandler
    ): Promise<TableGlideType | undefined>;
    getPreviewQueryForNewSavingFlag(sourceKind: string, sourceID: string): ChangeObservable<boolean>;

    getPreviewQueryCanSaveForExisting(queryID: string): ChangeObservable<boolean>;
    setPreviewQueryForExisting(queryID: string, queryName: string, queryString: string, remoteSerial?: number): void;
    savePreviewQueryForExisting(
        queryID: string,
        getOverrideRowID: GetOverrideRowID,
        onSave?: OnQuerySaveHandler
    ): Promise<TableGlideType | undefined>;
    getPreviewQueryForExistingSavingFlag(queryID: string): ChangeObservable<boolean>;

    // Returns an error message, or `undefined` if successful
    rediscoverQuery(
        tableName: TableName,
        queryName: string,
        queryBase: SQLQueryBase,
        remoteSerial: number,
        getOverrideRowID: GetOverrideRowID
    ): Promise<string | undefined>;

    addQueryableRowsRootFinder(finder: QueryableRowsRootFinder): void;
    removeQueryableRowsRootFinder(finder: QueryableRowsRootFinder): void;
}

/**
 * Enqueue and dequeue data for offline resiliency
 *
 * Enqueueing data is simple: just call `enqueue(item: T)`.
 *
 * Dequeueing has three lifecycle options:
 * - By calling `processIfOnline`,
 *   - If the queue believes itself to be offline, this is a no-op.
 *   - If the queue believes itself to be online,
 *     1. The queue will call `processItem` over all of the enqueued items
 *        it can (more on why this isn't "all items" later).
 *     2. If `processItem` returns `true`, the item is removed from the queue
 *        and will not be visited again, unless explicitly re-enqueued.
 *     3. If `processItem` returns `false`, the item will be processed again,
 *        either by `processIfOnline`, `forEach`, or `previewQueueIfOnline`.
 * - By calling `forEach`, which calls `processItem` over every item in the queue
 *   but does not remove any of them.
 * - By calling `previewQueueIfOnline` to receive a snapshot, and iterating
 *   over the items:
 *   - If the queue believes itself to be offline, the snapshot is empty.
 *   - If the queue believes itself to be online,
 *     1. `previewQueueIfOnline` will return a copy of the internal queue,
 *        although the copy is shallow and if care is not taken, entries can
 *        be modified in place
 *     2. Loops consuming items in the queue must first call `claimItem` on the
 *        item. If `claimItem` returns `false`, this item is already being processed
 *        by some other consumer (this is the "if it can" qualification above) and
 *        should not be processed again.
 *     3. If the processor wishes for the item to be removed from the queue, it should
 *        call `confirmItem` when it is finished. Otherwise, to release the item back
 *        to another processor, it should call `returnItem`.
 */
export interface OfflineQueue<T> {
    enqueue(item: T): void;
    // If `processItem` did process the item, it must return `true`, in which
    // case the queue removes the item.
    processIfOnline(processItem: (item: T) => Promise<boolean>): Promise<void>;
    previewQueueIfOnline(): Iterable<T>;
    claimItem(item: T): boolean;
    confirmItem(item: T): void;
    returnItem(item: T): void;
    forEach(processItem: (item: T) => void): void;
    retire(): void;
}

export interface FrontendAppEnvironment {
    readonly appKind: AppKind;

    suppressNotificationTopic?(topic: string): void;
    unsuppressNotificationTopic?(topic: string): void;
}

export interface AppPublishInfo {
    readonly isPublished: boolean;
    readonly shortName: string | undefined;
    readonly customDomain: string | undefined;
}

export interface GeocodeReporter {
    isGeocodeWithinQuota(key: string): boolean;
    countGeocodeForQuota(key: string): void;
    // This is for quota accounting.
    reportGeocodes(count: number): void;
}

export interface AppEnvironmentActionsState {
    readonly started: number;
    readonly performed: number;

    // This is a subset of the above
    readonly savingMessageStarted: number;
    readonly savingMessagePerformed: number;
}

export const emptyAppEnvironmentActionsState: AppEnvironmentActionsState = {
    started: 0,
    performed: 0,
    savingMessageStarted: 0,
    savingMessagePerformed: 0,
};

export type AppEnvironmentActionStateHandler = (state: AppEnvironmentActionsState) => void;

export interface WireBackendAppEnvironment {
    readonly appID: string;
    readonly appFacilities: MinimalAppFacilities;
    readonly authenticator: AppUserAuthenticator | undefined;
    readonly database: Database | undefined;
    readonly dataStore: DataStore;
    readonly localDataStore: LocalDataStore | undefined;
    readonly queryableDataStore: QueryableDataStore | undefined;
    readonly appData: AppData;

    // FIXME: Why isn't this a `ChangeObservable`?
    subscribeToActionsState(handler: AppEnvironmentActionStateHandler): void;
    unsubscribeFromActionState(handler: AppEnvironmentActionStateHandler): void;
}

export interface ActionAppEnvironment extends GeocodeReporter {
    readonly appID: string;
    readonly appFeatures: AppFeatures;
    readonly sourceMetadata: readonly SourceMetadata[] | undefined;
    readonly pluginConfigs: readonly PluginConfig[];
    readonly userProfileTableInfo: UserProfileTableInfo | undefined;
    readonly appData: AppData;
    readonly appFacilities: ActionAppFacilities;
    readonly authenticator: AppUserProvider;
    readonly database: Database;
    readonly locationSettings: LocationSettings | undefined;
    // Ugh, it sucks we have to have this here
    readonly localDataStore: LocalDataStore | undefined;
    readonly queryableDataStore: QueryableDataStore | undefined;
    readonly eminenceFlags: EminenceFlags;
    readonly isBuilder: boolean;
    readonly writeSource: WriteSourceType;

    preloadYesCodeModule?(url: string): void;
    callYesCode?(
        url: string,
        params: YesCodeParameterMap
    ): YesCodeValue | undefined | Promise<YesCodeValue | undefined>;

    makeOfflineQueue?<T>(
        name: string,
        onOnline: () => void,
        idForItem: (item: T) => string
    ): OfflineQueue<T> | undefined;
    makeReloadResiliencyQueue?<T>(
        name: string,
        onOnline: () => void,
        idForItem: (item: T) => string
    ): OfflineQueue<T> | undefined;
}

// the `FrontendAppEnvironment` will eventually only be present on the
// frontend, and `MinimalAppEnvironment` only on the backend, so the former
// won't extend the latter anymore.
export interface MinimalAppEnvironment extends WireBackendAppEnvironment, GeocodeReporter, FrontendAppEnvironment {
    readonly appFeatures: AppFeatures;
    readonly sourceMetadata: readonly SourceMetadata[] | undefined;
    readonly pluginConfigs: readonly PluginConfig[];
    readonly userProfileTableInfo: UserProfileTableInfo | undefined;
    readonly appData: AppData;
    readonly authenticator: AppUserAuthenticator;
    readonly database: Database;
    readonly locationSettings: LocationSettings | undefined;
    readonly eminenceFlags: EminenceFlags;
    readonly isBuilder: boolean;
    readonly writeSource: WriteSourceType;

    // FIXME: This should really be an observable
    getPublishInfo(): AppPublishInfo;

    makeOfflineQueue<T>(
        name: string,
        onOnline: () => void,
        idForItem: (item: T) => string
    ): OfflineQueue<T> | undefined;

    makeReloadResiliencyQueue<T>(
        name: string,
        onOnline: () => void,
        idForItem: (item: T) => string
    ): OfflineQueue<T> | undefined;

    // FIXME: should not be optional
    getQuotaValues?(kind: QuotaKind): QuotaValues | undefined;

    preloadYesCodeModule?(url: string): void;
    callYesCode?(
        url: string,
        params: YesCodeParameterMap
    ): YesCodeValue | undefined | Promise<YesCodeValue | undefined>;

    updateApp?(
        sourceMetadata: readonly SourceMetadata[] | undefined,
        userProfileTableInfo: UserProfileTableInfo | undefined,
        pluginConfigs: readonly PluginConfig[],
        appData: BaseAppData
    ): void;

    getActionsState(): AppEnvironmentActionsState;
}

export enum ComponentHighlight {
    None,
    App,
    Designer,
}

export enum QuotaBannerMessage {
    List = "list",
    Map = "map",
}

export type ResponseStatus = "Success" | "Forbidden" | "Offline" | "PaymentRequired";

export interface QuotaBannerRequirements {
    readonly needListQuota: boolean;
    readonly needMapQuota: boolean;
}

export type OAuth2TokenAuth = Readonly<{
    email: string;
    displayName: string | undefined;
    password: string | undefined;
    userProfileRow: JSONObject | undefined;
    appLoginToken: AppLoginTokenContainer | undefined;
}>;
export type AppUserChangedCallback = (
    appUserID: string | undefined,
    realEmail: string | undefined,
    virtualEmail: string | undefined,
    loginToken: AppLoginTokenContainer | undefined,
    fromMagicLink: boolean
) => void;

export interface AppUserProvider {
    readonly appUserID: string | undefined;
    readonly realEmail: string | undefined;
    readonly virtualEmail: string | undefined;

    readonly userProfileRow: JSONObject | undefined;

    addCallback(callback: AppUserChangedCallback): void;
    removeCallback(callback: AppUserChangedCallback): void;

    tryGetAppUserID?(): Promise<void>;
}

export interface AppUserAuthenticator extends AppUserProvider {
    readonly appID: string;
    // This only exists so that the Preview-As text entry can save whatever
    // the user enters and have it be preserved.  Ideally this would be
    // managed in that text entry, not here.
    readonly unnormalizedVirtualEmail: string | undefined;
    readonly failedSignIns: number;

    sendPinForEmail(
        email: string,
        glideCommit: string,
        privateMagicLinkToken: string | undefined
    ): Promise<{ status: ResponseStatus; link?: string; target?: string }>;
    getPasswordForOAuth2Token(authToken: string, userAgreed: boolean): Promise<OAuth2TokenAuth | undefined>;
    getPasswordForEmailPin(
        email: string,
        pin: string,
        userAgreed: boolean
    ): Promise<{ password: string | undefined; loginToken: AppLoginTokenContainer | undefined } | undefined>;
    authorizeForAppWithEmail(email: string, passwordForEmail: string): Promise<boolean>;
    authorizeForAppWithPassword(password: string): Promise<boolean>;
    authorizeForAppWithLoginToken(
        loginToken: AppLoginTokenContainer,
        email: string | undefined,
        password: string | undefined
    ): Promise<boolean>;
    authorizeForAppWithInviteMagicLink(token: string, email: string): Promise<boolean>;
    setEmailAddressFromBuilder(email: string, isSelectedByUser: boolean): void;
}

export type UploadProgressHandler = (totalBytes: number, bytesSent: number) => void;

export interface UploadFileResponseError {
    readonly error: Error;
    readonly quotaExceeded: boolean;
}

export type UploadFileResponse = { path: string } | UploadFileResponseError;

export function isUploadFileResponseError(x: UploadFileResponse): x is UploadFileResponseError {
    return hasOwnProperty(x, "error");
}

export interface UploadSession {
    cancel(): void;
    attempt(): Promise<UploadFileResponse>;
}

export type DisplayContext = "default" | "master" | "detail" | "modal" | "map-master" | "map-detail";

export interface BasicUserProfile {
    readonly email: string;
    readonly name?: string;
    readonly image?: string;
}

export enum MenuItemPurpose {
    AddRow = "add-item",
    EditRow = "edit-item",
    SaveRow = "save-item",
    SetValue = "set-value",
    FilterAndSearch = "filter-and-search",
    SignOut = "sign-out",
}
