import { getLocalizedString } from "@glide/localization";
import type { UploadProgressHandler } from "@glide/common-core/dist/js/components/types";
import { getFirstFileFromInput, readFileAsURL } from "@glide/component-utils";
import { isEmptyOrUndefined, logError, parseURL } from "@glide/support";
import * as React from "react";
import type { Subtract } from "utility-types";

import {
    type WireFilePickerMode,
    type WireFilePickerViewProps,
    WireFilePickerView,
} from "../wire-file-picker-view/wire-file-picker-view";

interface ExtraProps {
    // Returns whether or not the upload succeeded.
    // If the upload didn't succeed, we need to clear out
    // the droppedUrl state for retrying.
    readonly onChange: (newValue: string) => void;
    readonly onDropFile: (file: File, onProgress: UploadProgressHandler, includeFilename?: boolean) => Promise<boolean>;
    readonly includeFilename?: boolean;
}

interface Handled {
    readonly onDelete?: (shouldDelete: boolean) => void;
    readonly onClearClicked?: (ev: React.MouseEvent<HTMLDivElement>) => void;
    readonly onInputChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;

    readonly mode?: WireFilePickerMode;
}

const WireCoreFilePicker: React.FC<
    React.PropsWithChildren<Subtract<WireFilePickerViewProps, Handled>> & ExtraProps
> = props => {
    const [mode, setMode] = React.useState<WireFilePickerMode>("normal");
    const [progressPercentage, setProgressPercentage] = React.useState<number>(0);
    const [droppedUrl, setDroppedUrl] = React.useState<string>();
    const [uploadingFileName, setUploadingFileName] = React.useState<string | undefined>();
    const [label, setLabel] = React.useState<string | undefined>();

    const { valueLabel, value, onDropFile, onChange, onCancel, appKind, includeFilename } = props;

    React.useEffect(() => {
        setDroppedUrl(undefined);
        setMode("normal");
        setLabel(valueLabel);
    }, [value, valueLabel]);

    React.useEffect(() => {
        setLabel(valueLabel);
        if (mode === "uploading") {
            setLabel(uploadingFileName ?? getLocalizedString("uploading", appKind));
        } else if (!isEmptyOrUndefined(value)) {
            const uri = parseURL(value);
            if (uri !== undefined) {
                setLabel(uri.pathname.split("/").reduce((_pv, cv) => cv));
            }
        }
    }, [appKind, mode, uploadingFileName, value, valueLabel]);

    const onDelete = React.useCallback(
        (shouldDelete: boolean) => {
            if (shouldDelete) {
                onChange("");
            }
            setMode("normal");
        },
        [onChange]
    );

    const onClearClicked = React.useCallback((ev: React.MouseEvent<HTMLDivElement>) => {
        ev.stopPropagation();
        ev.preventDefault();
        setMode("deleting");
    }, []);

    const onCancelAccept = React.useCallback(
        (cancelling: boolean) => {
            if (cancelling) {
                setDroppedUrl(undefined);
            }
            onCancel?.(cancelling);
            setMode(cancelling ? "normal" : "uploading");
        },
        [onCancel]
    );

    const onInputChange = React.useCallback(
        async (ev: React.ChangeEvent<HTMLInputElement>) => {
            const firstFile = getFirstFileFromInput(ev.target.files);
            if (firstFile === undefined) return;

            setUploadingFileName(firstFile.name);

            let shouldSetDroppedURL = true;
            void readFileAsURL(firstFile).then(contents => {
                if (shouldSetDroppedURL) {
                    setDroppedUrl(contents);
                }
            });
            const clearDroppedURL = () => {
                shouldSetDroppedURL = false;
                setDroppedUrl(undefined);
            };

            const f = onDropFile;
            if (f !== undefined) {
                setMode("uploading");
                setProgressPercentage(0);
                try {
                    await f(
                        firstFile,
                        (total, written) => setProgressPercentage((written * 100) / total),
                        includeFilename
                    );
                } catch (e: unknown) {
                    logError(`Uploading file failed: ${e}`);
                } finally {
                    setMode("normal");
                }
            }
            clearDroppedURL();
            setUploadingFileName(undefined);
        },
        [onDropFile, includeFilename]
    );

    return (
        <WireFilePickerView
            {...props}
            onCancel={onCancelAccept}
            value={droppedUrl ?? value}
            onClearClicked={onClearClicked}
            onInputChange={onInputChange}
            onDelete={onDelete}
            mode={mode}
            valueLabel={label}
            progressPercentage={progressPercentage}
        />
    );
};

export default WireCoreFilePicker;
