export type SettingsElement = {
    path: string;
    icon: React.ReactElement;
    title: string;
    caption: string;
    keywords?: string[];
    page?: React.ReactElement;
    subtitle?: React.ReactNode;
    showDot?: true;
};

export type SettingsElementWithSectionTitle = SettingsElement | string;

export const isSettingsElement = (x: SettingsElementWithSectionTitle): x is SettingsElement => typeof x !== "string";
