import "twin.macro";

export const LockoutMessage: React.FC = () => {
    return (
        <div tw="flex min-h-[100dvh] w-full flex-col items-center text-text-base antialiased justify-center px-4 py-12 sm:px-6 lg:px-8">
            <div tw="px-6 py-10 flex flex-col items-center mx-auto max-w-md text-center leading-tight rounded-3xl bg-bg-front">
                <h1 tw="text-[19px] text-text-dark font-semibold tracking-tight">This app is currently unavailable.</h1>
                <p tw="mt-6 text-sm opacity-70 text-text-dark">Please contact the app owner to resolve the issue.</p>
            </div>
        </div>
    );
};
