import type {
    InlineComputation,
    ActionDescription,
    ArrayFilter,
    ArrayTransform,
    FilterArrayTransform,
    LegacyPropertyDescription,
    PaymentMethod,
    PropertyDescription,
} from "./description";
import type { BasePrimitiveValue } from "@glide/data-types";
import { isBasePrimitiveValue } from "@glide/data-types";
import type { Icon } from "./icons";
import type { TableName, Formula, SourceColumn, SpecialValueDescription } from "@glide/type-schema";
import {
    SpecialValueKind,
    SourceColumnKind,
    getDefaultContextColumnName,
    isSourceColumn,
    makeSourceColumn,
    makeTableName,
    pluginSpecialValueDescriptionCodec,
} from "@glide/type-schema";
import { ArrayTransformKind, PropertyKind, isPaymentMethod } from "./description";
import { checkArray, checkNumber, checkString } from "@glide/support";
import { hasOwnProperty, isArray, isEnumValue, panic } from "@glideapps/ts-necessities";
import type { SwitchDescription } from "./property-descriptions";
import { isRight } from "fp-ts/lib/Either";

// FIXME: Do this check properly, with validation via the description handlers
export function isPropertyDescription(x: unknown): x is PropertyDescription {
    if (!hasOwnProperty(x, "kind")) return false;
    return typeof x.kind === "string";
}

export function makeSourceColumnProperty(sourceColumn: SourceColumn): PropertyDescription {
    return { kind: PropertyKind.Column, value: sourceColumn };
}

export function makeColumnProperty(columnName: string): PropertyDescription;
export function makeColumnProperty(columnName: string | undefined): PropertyDescription | undefined;
export function makeColumnProperty(columnName: string | undefined): PropertyDescription | undefined {
    if (columnName === undefined) return undefined;
    return makeSourceColumnProperty(makeSourceColumn(columnName));
}

export function convertLegacySourceColumn(value: unknown | undefined, allowFullRow: boolean): SourceColumn | undefined {
    if (typeof value === "string") {
        return makeSourceColumn(value);
    } else if (isSourceColumn(value)) {
        return value;
    } else if (allowFullRow && value === true) {
        return { kind: SourceColumnKind.DefaultContext, name: [] };
    }
    return undefined;
}

export function getSourceColumnProperty(propertyDesc: LegacyPropertyDescription | undefined): SourceColumn | undefined;
export function getSourceColumnProperty(
    propertyDesc: LegacyPropertyDescription | PropertyDescription | undefined
): SourceColumn | undefined;
export function getSourceColumnProperty(
    propertyDesc: LegacyPropertyDescription | PropertyDescription | undefined
): SourceColumn | undefined {
    if (propertyDesc === undefined) return undefined;
    if (typeof propertyDesc === "string") {
        return makeSourceColumn(propertyDesc);
    }
    if (!isPropertyDescription(propertyDesc)) return undefined;
    if (propertyDesc.kind !== PropertyKind.Column) return undefined;
    const { value } = propertyDesc;
    if (value === undefined) return undefined;

    return convertLegacySourceColumn(value, false);
}

export function getInlineComputationProperty(
    propertyDesc: LegacyPropertyDescription | PropertyDescription | undefined
): InlineComputation | undefined {
    if (propertyDesc === undefined) return undefined;
    if (!isPropertyDescription(propertyDesc)) return undefined;
    if (propertyDesc.kind !== PropertyKind.InlineComputation) return undefined;

    return propertyDesc.value as InlineComputation;
}

export function makeInlineComputationProperty(computation: InlineComputation): PropertyDescription {
    return {
        kind: PropertyKind.InlineComputation,
        value: computation,
    };
}

export function getColumnProperty(propertyDesc: LegacyPropertyDescription | undefined): string | undefined;
export function getColumnProperty(
    propertyDesc: LegacyPropertyDescription | PropertyDescription | undefined
): string | undefined;
export function getColumnProperty(propertyDesc: LegacyPropertyDescription | undefined): string | undefined;
export function getColumnProperty(
    propertyDesc: LegacyPropertyDescription | PropertyDescription | undefined
): string | undefined;
export function getColumnProperty(
    propertyDesc: LegacyPropertyDescription | PropertyDescription | undefined
): string | undefined {
    const result = getSourceColumnProperty(propertyDesc);
    if (result === undefined) return undefined;
    return getDefaultContextColumnName(result);
}

export function makeTableProperty(tableName: TableName): PropertyDescription;
export function makeTableProperty(tableName: TableName | undefined): PropertyDescription | undefined;
export function makeTableProperty(tableName: TableName | undefined): PropertyDescription | undefined {
    if (tableName === undefined) return undefined;
    return { kind: PropertyKind.Table, value: tableName };
}

export function getTableProperty(propertyDesc: LegacyPropertyDescription | undefined): TableName | undefined {
    if (propertyDesc === undefined) return undefined;
    if (typeof propertyDesc === "string") return makeTableName(propertyDesc);
    const tableDesc = propertyDesc as PropertyDescription;
    const { kind, value } = tableDesc;
    if (kind !== PropertyKind.Table) return undefined;
    if (typeof value === "string") return makeTableName(value);
    return value as TableName;
}

type TableOrColumnForTableView =
    | {
          kind: "table";
          value: TableName;
      }
    | {
          kind: "column";
          value: SourceColumn;
      };

export function makeTableViewProperty(
    tableOrColumn: TableOrColumnForTableView,
    allowedColumns?: readonly string[]
): PropertyDescription {
    return { kind: PropertyKind.TableView, value: { tableOrColumn, allowedColumns } };
}

export type TableViewPropertyValue = {
    tableOrColumn: TableOrColumnForTableView;
    selectedColumns: string[] | undefined;
    nameOverrides: Record<string, string> | undefined;
};

export function getTableViewProperty(propertyDesc: PropertyDescription): TableViewPropertyValue | undefined {
    // This is admittedly a hack, but we deployed some plugins using Table and Column property descriptors,
    // so we're kinda stuck with them until all of the Alpha users fix their configuration. :/
    if (propertyDesc.kind === PropertyKind.Table) {
        const table = getTableProperty(propertyDesc);
        if (table === undefined) return undefined;
        return {
            tableOrColumn: { kind: "table" as const, value: table },
            selectedColumns: undefined,
            nameOverrides: undefined,
        };
    }
    if (propertyDesc.kind === PropertyKind.Column) {
        const column = getSourceColumnProperty(propertyDesc);
        if (column === undefined) return undefined;
        return {
            tableOrColumn: { kind: "column" as const, value: column },
            selectedColumns: undefined,
            nameOverrides: undefined,
        };
    }
    if (!isTableViewProperty(propertyDesc)) return undefined;
    return propertyDesc.value;
}

export function isTableViewPropertyValue(x: unknown): x is TableViewPropertyValue {
    if (!hasOwnProperty(x, "tableOrColumn")) return false;
    const { tableOrColumn } = x;
    if (!hasOwnProperty(tableOrColumn, "kind")) return false;
    return tableOrColumn.kind === "table" || tableOrColumn.kind === "column";
}

export function isTableViewProperty(x: unknown): x is { kind: PropertyKind.TableView; value: TableViewPropertyValue } {
    if (!hasOwnProperty(x, "kind")) return false;
    if (x.kind !== PropertyKind.TableView) return false;
    if (!hasOwnProperty(x, "value")) return false;
    return isTableViewPropertyValue(x.value);
}

export function makeStringProperty(value: string): PropertyDescription;
export function makeStringProperty(value: string | undefined): PropertyDescription | undefined;
export function makeStringProperty(value: string | undefined): PropertyDescription | undefined {
    if (value === undefined) return undefined;
    return { kind: PropertyKind.String, value };
}

export function getStringProperty<T extends string>(
    propertyDesc: LegacyPropertyDescription | undefined
): T | undefined {
    if (propertyDesc === undefined) return undefined;
    if (typeof propertyDesc === "string") return propertyDesc as T;
    if (!isPropertyDescription(propertyDesc)) return undefined;
    if (propertyDesc.kind !== PropertyKind.String) return undefined;
    if (propertyDesc.value === undefined) return undefined;
    return checkString(propertyDesc.value) as T;
}

export function makeNumberProperty(value: number): PropertyDescription {
    return { kind: PropertyKind.Number, value };
}

export function getNumberProperty(
    propertyDesc: PropertyDescription | LegacyPropertyDescription | undefined
): number | undefined {
    if (propertyDesc === undefined) return undefined;
    if (typeof propertyDesc === "number") return propertyDesc;
    if (!isPropertyDescription(propertyDesc)) return undefined;
    if (propertyDesc.kind !== PropertyKind.Number) return undefined;
    if (propertyDesc.value === undefined) return undefined;
    return checkNumber(propertyDesc.value);
}

export function makeSwitchProperty(value: boolean, condition?: FilterArrayTransform | undefined): PropertyDescription;
export function makeSwitchProperty(
    value: boolean | undefined,
    condition?: FilterArrayTransform | undefined
): PropertyDescription | undefined;
export function makeSwitchProperty(
    value: boolean | undefined,
    condition: FilterArrayTransform | undefined
): PropertyDescription | undefined {
    if (value === undefined) return undefined;
    const desc: SwitchDescription = {
        value,
        condition,
    };
    return { kind: PropertyKind.Switch, value: desc };
}

export function normalizeValueForSwitchWithCondition(selectedValue: unknown): SwitchDescription | undefined {
    if (typeof selectedValue === "boolean") {
        return { value: selectedValue, condition: undefined };
    }
    if (hasOwnProperty(selectedValue, "value") && typeof selectedValue.value === "boolean") {
        return selectedValue as SwitchDescription;
    }
    return undefined;
}

export function getSwitchWithConditionProperty(
    propertyDesc: LegacyPropertyDescription | undefined
): SwitchDescription | undefined {
    if (isPropertyDescription(propertyDesc)) {
        return normalizeValueForSwitchWithCondition(propertyDesc.value);
    } else {
        return normalizeValueForSwitchWithCondition(propertyDesc);
    }
}

export function getSwitchProperty(propertyDesc: LegacyPropertyDescription | undefined): boolean | undefined {
    return getSwitchWithConditionProperty(propertyDesc)?.value;
}

export function makeEnumProperty<P extends BasePrimitiveValue>(value: P): PropertyDescription;
export function makeEnumProperty<P extends BasePrimitiveValue>(value: P | undefined): PropertyDescription | undefined;
export function makeEnumProperty<P extends BasePrimitiveValue>(value: P | undefined): PropertyDescription | undefined {
    if (value === undefined) return undefined;
    return { kind: PropertyKind.Enum, value };
}

export function getEnumProperty<P extends BasePrimitiveValue>(
    propertyDesc: LegacyPropertyDescription | undefined
): P | undefined {
    if (propertyDesc === undefined) return undefined;
    if (isBasePrimitiveValue(propertyDesc)) {
        return propertyDesc as P;
    }
    if (!isPropertyDescription(propertyDesc)) return undefined;
    if (propertyDesc.kind !== PropertyKind.Enum) return undefined;
    if (!isBasePrimitiveValue(propertyDesc.value)) {
        return panic(`Enum value has the wrong type: ${typeof propertyDesc.value}`);
    }
    return propertyDesc.value as P;
}

export function getZapProperty(propertyDesc: PropertyDescription | undefined): string | undefined {
    if (propertyDesc === undefined) return undefined;
    if (propertyDesc.kind !== PropertyKind.Zap) return undefined;
    if (propertyDesc.value === undefined) return undefined;
    return checkString(propertyDesc.value) as string;
}

export function getWebhookProperty(propertyDesc: PropertyDescription | undefined): string | undefined {
    if (propertyDesc === undefined) return undefined;
    if (propertyDesc.kind !== PropertyKind.Webhook) return undefined;
    if (propertyDesc.value === undefined) return undefined;
    return checkString(propertyDesc.value) as string;
}

export function makeWebhookProperty(webhookID: string): PropertyDescription {
    return { kind: PropertyKind.Webhook, value: webhookID };
}

export function makeSpecialValueProperty(value: SpecialValueDescription): PropertyDescription {
    return {
        kind: PropertyKind.SpecialValue,
        value,
    };
}

export function getSpecialValueProperty(
    propertyDesc: LegacyPropertyDescription | undefined
): SpecialValueDescription | undefined {
    if (!isPropertyDescription(propertyDesc)) return undefined;
    if (propertyDesc?.kind !== PropertyKind.SpecialValue) return undefined;
    if (typeof propertyDesc.value === "string") {
        if (isEnumValue(SpecialValueKind, propertyDesc.value)) {
            return propertyDesc.value;
        }
    } else {
        const decoded = pluginSpecialValueDescriptionCodec.decode(propertyDesc.value);
        if (isRight(decoded)) {
            return decoded.right;
        }
    }
    return undefined;
}

export function makeSecretProperty(secretID: string): PropertyDescription {
    return {
        kind: PropertyKind.Secret,
        value: secretID,
    };
}

export function getSecretProperty(propertyDesc: LegacyPropertyDescription | undefined): string | undefined {
    if (!isPropertyDescription(propertyDesc)) return undefined;
    if (propertyDesc === undefined) return undefined;
    if (propertyDesc.kind !== PropertyKind.Secret) return undefined;
    if (propertyDesc.value === undefined) return undefined;
    return checkString(propertyDesc.value) as string;
}

export function makeGeneratedKeyPairProperty(secretID: string): PropertyDescription {
    return {
        kind: PropertyKind.GeneratedKeyPair,
        value: secretID,
    };
}

export function getGeneratedKeyPairProperty(propertyDesc: PropertyDescription | undefined): string | undefined {
    if (propertyDesc === undefined) return undefined;
    if (propertyDesc.kind !== PropertyKind.GeneratedKeyPair) return undefined;
    if (propertyDesc.value === undefined) return undefined;
    return checkString(propertyDesc.value) as string;
}

export function makeScreenProperty(screenName: string): PropertyDescription {
    return { kind: PropertyKind.Screen, value: screenName };
}

export function getScreenProperty(propertyDesc: LegacyPropertyDescription | undefined): string | undefined {
    if (propertyDesc === undefined) return undefined;
    if (typeof propertyDesc === "string") return propertyDesc;
    if (!isPropertyDescription(propertyDesc)) return undefined;
    if (propertyDesc.kind !== PropertyKind.Screen) return undefined;
    if (propertyDesc.value === undefined) return undefined;
    return checkString(propertyDesc.value);
}

export function makeFilterProperty(value: ArrayFilter | undefined): PropertyDescription | undefined {
    if (value === undefined) return undefined;
    return { kind: PropertyKind.Filter, value };
}

export function getFilterProperty(propertyDesc: LegacyPropertyDescription): ArrayFilter;
export function getFilterProperty(propertyDesc: LegacyPropertyDescription | undefined): ArrayFilter | undefined;
export function getFilterProperty(propertyDesc: LegacyPropertyDescription | undefined): ArrayFilter | undefined {
    if (propertyDesc === undefined) return undefined;
    if (!isPropertyDescription(propertyDesc)) return propertyDesc as ArrayFilter;
    if (propertyDesc.kind !== PropertyKind.Filter) return undefined;
    return propertyDesc.value as ArrayFilter;
}

export function getPaymentMethodProperty(propertyDesc: PropertyDescription | undefined): PaymentMethod | undefined {
    if (propertyDesc === undefined) return undefined;
    if (propertyDesc.kind !== PropertyKind.PaymentMethod) return undefined;
    if (propertyDesc.value === undefined) return undefined;
    if (!isPaymentMethod(propertyDesc.value)) return undefined;
    return propertyDesc.value;
}

const arrayTransformKinds: Record<ArrayTransformKind, true> = {
    [ArrayTransformKind.Filter]: true,
    [ArrayTransformKind.Sort]: true,
    [ArrayTransformKind.Shuffle]: true,
    [ArrayTransformKind.TableOrder]: true,
    [ArrayTransformKind.Limit]: true,
};

function isArrayTransform(x: unknown): x is ArrayTransform {
    if (!hasOwnProperty(x, "kind")) return false;
    if (typeof x.kind !== "string") return false;
    return hasOwnProperty(arrayTransformKinds, x.kind);
}

export function getTransformsProperty(
    propertyDesc: PropertyDescription | undefined
): readonly ArrayTransform[] | undefined {
    // Unfortunately we're saving transforms properties not a proper
    // descriptions, but as arrays directly.
    if (isArray(propertyDesc) && propertyDesc.every(isArrayTransform)) return propertyDesc as readonly ArrayTransform[];
    if (propertyDesc?.kind !== PropertyKind.Transforms) return undefined;
    if (!isArray(propertyDesc.value)) return undefined;
    return propertyDesc.value.filter(isArrayTransform);
}

export function makeArrayProperty<T>(items: readonly T[]): PropertyDescription {
    return { kind: PropertyKind.Array, value: items };
}

export function getArrayProperty<T>(propertyDesc: PropertyDescription | undefined): readonly T[] | undefined {
    if (propertyDesc === undefined) return undefined;
    if (propertyDesc.kind !== PropertyKind.Array) return undefined;
    if (propertyDesc.value === undefined) return undefined;
    return checkArray(propertyDesc.value);
}

export function getJSONPathProperty(
    propertyDesc: PropertyDescription | LegacyPropertyDescription | undefined
): readonly string[] | undefined {
    if (!isPropertyDescription(propertyDesc)) return undefined;
    if (propertyDesc === undefined) return undefined;
    if (propertyDesc.kind !== PropertyKind.JSONPath) return undefined;
    if (propertyDesc.value === undefined) return undefined;
    return checkArray(propertyDesc.value);
}

export function makeIconProperty(iconName: Icon): PropertyDescription {
    return { kind: PropertyKind.Icon, value: iconName };
}

export function getIconProperty(propertyDesc: PropertyDescription | undefined): Icon | undefined {
    if (propertyDesc?.kind !== PropertyKind.Icon) return undefined;
    return checkString(propertyDesc.value);
}

export function makeEmojiProperty(emoji: string): PropertyDescription {
    return { kind: PropertyKind.Emoji, value: emoji };
}

export function getEmojiProperty(propertyDesc: PropertyDescription | undefined): string | undefined {
    if (propertyDesc?.kind !== PropertyKind.Emoji) return undefined;
    return checkString(propertyDesc.value);
}

export function makeActionProperty(action: ActionDescription): PropertyDescription {
    return { kind: PropertyKind.Action, value: action };
}

export function getActionProperty(propertyDesc: LegacyPropertyDescription | undefined): ActionDescription | undefined {
    if (isArray(propertyDesc)) {
        return propertyDesc[0] as ActionDescription | undefined;
    }
    if (!isPropertyDescription(propertyDesc)) return undefined;
    if (propertyDesc?.kind !== PropertyKind.Action) return undefined;
    if (isArray(propertyDesc.value)) {
        return propertyDesc.value[0] as ActionDescription | undefined;
    }
    return propertyDesc.value as ActionDescription;
}

export function makeCompoundActionProperty(actionID: string): PropertyDescription {
    return { kind: PropertyKind.CompoundAction, value: actionID };
}

export function getCompoundActionProperty(propertyDesc: PropertyDescription | undefined): string | undefined {
    if (propertyDesc?.kind !== PropertyKind.CompoundAction) return undefined;
    return checkString(propertyDesc.value);
}

export function makeFormulaProperty(formula: Formula): PropertyDescription {
    return { kind: PropertyKind.Formula, value: formula };
}

export function getFormulaProperty(propertyDesc: PropertyDescription | undefined): Formula | undefined {
    if (propertyDesc?.kind !== PropertyKind.Formula) return undefined;
    return propertyDesc.value as Formula;
}
