/* eslint-disable no-bitwise */
import { digitsAndLetters } from "@glide/support";

// This is a radix64 binary-to-text encoding, most similar to the encoding
// used by Unix Version 3's `crypt` program to store passwords. We use this
// over any other radix64 binary-to-text encoding because of how it preserves
// byte ordering in ASCII and ASCII-compatible text encodings (you cannot
// say the same of Base64, for example.)
//
// The major addition to the encoding is the introduction of explicit tuple
// delimiters. We aren't just encoding an arbitrary buffer, we're encoding
// an array of arbitrary buffers. This lends itself to additional challenges.
//
// Particularly, we need to ensure tuple-correct comparison order, where the
// rules are, at any offset:
// 1. If both tuples are empty, they are equivalent.
// 2. If one tuple has terminated at the given offset but another hasn't,
//    then the "shorter" tuple is considered less than the "longer" tuple.
// 3. If the values at the current offset differ, then the ordering of both
//    tuples is based on the ordering of these differing values.
// 4. If the values at the current offset are equal, then we increment the
//    value offset and begin again from step 1.
//
// As a motivating example, consider the two tuples
//
// ("abcd", "efgh")
// ("abc", "defgh")
//
// If we were to simply concatenate the strings, then they would be incorrectly
// considered equivalent. We particularly want
//
// ("abc", "defgh") < ("abcd", "efgh")
//
// because "abc" < "abcd".
//
// So, to facilitate this, we have carefully chosen an end-of-tuple delimiter
// ")", which in ASCII sorts under any of the valid encoding characters.
//
// We use the start-of-tuple delimiter "{" despite the percieved asymmetry
// because it sorts higher than any of the valid encoding characters.
// This permits the ordering
//
// (x, y, z) < ((x,), y, z)
//
// Which is to say, tuple64 can support arbitrarily nested arrays.
//
// It is worth mentioning that the mechanism by which we disambiguate
// between empty Buffers and empty arrays involves the definition of an
// "undefined" element, one that sorts below all characters and exists only
// to be skipped.
//
// In particular:
// - {() is the "undefined" element.
// - {) is an empty buffer.
// - {{()) is an empty array.
// - {{)) is an array with a single empty buffer element.

// We assume that digitsAndLetters is already sorted.
// Note that all of these characters should be safe to use as Cloud Firestore
// document IDs.
const encodeSchedule = "-." + digitsAndLetters;
const beginTupleElement = "{";
const endTupleElement = ")";
const undefinedElement = "(";

const decodeSchedule = new Map<string, number>();
for (let i = 0; i < encodeSchedule.length; i++) {
    decodeSchedule.set(encodeSchedule[i], i);
}

function encodeSingle64(data: Buffer): string {
    const rad64s: string[] = [];

    for (let i = 0; i < data.length; i += 3) {
        const b1 = data[i];
        const b2 = data[i + 1];
        const b3 = data[i + 2];

        rad64s.push(encodeSchedule[(b1 & 0xfc) >> 2]);
        if (b2 === undefined) {
            rad64s.push(encodeSchedule[(b1 & 0x3) << 4]);
        } else {
            rad64s.push(encodeSchedule[((b1 & 0x3) << 4) | ((b2 & 0xf0) >> 4)]);
            if (b3 === undefined) {
                rad64s.push(encodeSchedule[(b2 & 0xf) << 2]);
            } else {
                rad64s.push(encodeSchedule[((b2 & 0xf) << 2) | ((b3 & 0xc0) >> 6)]);
                rad64s.push(encodeSchedule[b3 & 0x3f]);
            }
        }
    }

    return rad64s.join("");
}

type Tuple64Input = readonly (Buffer | Tuple64Input)[];
export function encodeTuple64(input: Tuple64Input): string {
    if (input.length === 0) return "";

    const snippets: string[] = [];
    function encodeSubinput(subinput: Tuple64Input) {
        for (const elem of subinput) {
            snippets.push(beginTupleElement);
            if (elem instanceof Buffer) {
                snippets.push(encodeSingle64(elem));
            } else if (elem.length > 0) {
                encodeSubinput(elem);
            } else {
                snippets.push(beginTupleElement, undefinedElement, endTupleElement);
            }
            snippets.push(endTupleElement);
        }
    }
    encodeSubinput(input);
    return snippets.join("");
}

export function encodeTuple64Strings(data: readonly string[]): string {
    return encodeTuple64(data.map(d => Buffer.from(d, "utf-8")));
}

function decodeTuple64Single(encoded: string, from: number, to: number): Buffer | undefined {
    let remainingLength = to - from,
        i = from,
        j = 0;

    if (remainingLength > 0 && encoded[from] === undefinedElement) return undefined;

    let bufSize = Math.floor(remainingLength / 4) * 3;
    const remainder = remainingLength % 4;
    if (remainder === 2) {
        bufSize += 1;
    } else if (remainder === 3) {
        bufSize += 2;
    }

    const buf = Buffer.alloc(bufSize);
    while (remainingLength >= 4) {
        const o1 = decodeSchedule.get(encoded[i]) ?? 0;
        const o2 = decodeSchedule.get(encoded[i + 1]) ?? 0;
        const o3 = decodeSchedule.get(encoded[i + 2]) ?? 0;
        const o4 = decodeSchedule.get(encoded[i + 3]) ?? 0;

        buf[j] = (o1 << 2) | (o2 >> 4);
        buf[j + 1] = ((o2 << 4) & 0xf0) | (o3 >> 2);
        buf[j + 2] = (o3 << 6) | o4;

        remainingLength -= 4;
        i += 4;
        j += 3;
    }

    if (remainingLength >= 2) {
        const o1 = decodeSchedule.get(encoded[i]) ?? 0;
        const o2 = decodeSchedule.get(encoded[i + 1]) ?? 0;
        const o3 = decodeSchedule.get(encoded[i + 2]) ?? 0;

        buf[j] = (o1 << 2) | (o2 >> 4);
        if (remainingLength === 3) {
            buf[j + 1] = ((o2 << 4) & 0xf0) | (o3 >> 2);
        }
    }

    return buf;
}

const unexpectedEOF = "Unexpected end of input";
export type Tuple64Output = (Buffer | Tuple64Output)[];
export function decodeTuple64(encoded: string, startFrom: number = 0, stopAt: number = encoded.length): Tuple64Output {
    function badStartTypeError(i: number): TypeError {
        return new TypeError(`Unexpected start of tuple at index ${i}`);
    }

    function findEndIndex(findIndex: number): number {
        for (let i = findIndex; i < stopAt; i++) {
            if (encoded[i] === endTupleElement) return i;
            if (encoded[i] === beginTupleElement) {
                throw badStartTypeError(i);
            }
        }
        throw new TypeError(unexpectedEOF);
    }

    const stopAfterIndex = stopAt - 1;
    function handleSubArray(depth: number = 0, startIndex: number = startFrom): [Tuple64Output, number] {
        const results: Tuple64Output = [];
        while (startIndex < stopAt) {
            if (startIndex === stopAfterIndex) {
                throw new TypeError(unexpectedEOF);
            }
            if (encoded[startIndex] !== beginTupleElement) {
                throw badStartTypeError(startIndex);
            }
            if (encoded[startIndex + 1] === beginTupleElement) {
                const [subResults, nextStartIndex] = handleSubArray(depth + 1, startIndex + 1);
                results.push(subResults);
                startIndex = nextStartIndex;
            } else {
                const endIndex = findEndIndex(startIndex + 1);
                const decodedElement = decodeTuple64Single(encoded, startIndex + 1, endIndex);
                if (decodedElement !== undefined) {
                    results.push(decodedElement);
                }
                startIndex = endIndex + 1;
            }
            if (startIndex <= stopAfterIndex && encoded[startIndex] === endTupleElement) {
                startIndex++;
                break;
            }

            // Consider the following decode inputs:
            // {{)){)
            // {{))
            //
            // If we're at depth 1:
            // - For the first one, if we didn't consume the ) at offset 3, then
            //   depth 0 would hit the endTupleElement at offset 3 and think
            //   we're done. We'd lose the empty buffer in the second top-level
            //   array element.
            // - For the second one, we've consumed ) at the last character. If we
            //   were to perform this check outside of the loop we'd incorrectly
            //   throw a TypeError.
            if (startIndex >= stopAt && depth > 0) {
                throw new TypeError(unexpectedEOF);
            }
        }
        return [results, startIndex];
    }
    const [results] = handleSubArray();
    return results;
}

export function decodeTuple64Strings(encoded: string): string[] {
    return decodeTuple64(encoded).map(b => b.toString("utf-8"));
}

// We often need to encode IEEE 754 64-bit floats in this encoding.
// Alone, IEEE 754 64-bit isn't great at ensuring lexicographical sorting,
// but we can make it ensure lexicographical sorting with some bit hacks.

export function numberToTuple64Buffer(x: number): Buffer {
    const buf = Buffer.alloc(8);

    // IEEE 754 has a bit of a weird positive-negative situation: unlike'
    // virtually every CPU architecture's treatment of signed integrals,
    // it's not any sort of complemented, it's just a "negative" bit.
    //
    // We need to ensure that positive values are greater than negative
    // values, and higher negative values are less than lower negative
    // values. So we run the complement ourselves, but only a one's
    // complement; two's complement exists to ensure no ambiguity with
    // respect to zero but that ambiguity is baked into the IEEE 754
    // definition.

    buf.writeDoubleBE(x, 0);
    if ((buf[0] & 0x80) === 0) {
        buf[0] |= 0x80;
    } else {
        for (let i = 0; i < buf.length; i++) {
            buf[i] = buf[i] ^ 0xff;
        }
    }
    return buf;
}

export function tuple64BufferToNumber(buf: Buffer): number {
    const copyBuf = buf.slice(0, 8);
    if ((copyBuf[0] & 0x80) === 0) {
        for (let i = 0; i < 8; i++) {
            copyBuf[i] = copyBuf[i] ^ 0xff;
        }
    } else {
        copyBuf[0] &= 0x7f;
    }
    return copyBuf.readDoubleBE(0);
}
