import {
    makeEnumProperty,
    makeSwitchProperty,
    type ComponentDescription,
    type ComponentKind,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
} from "@glide/app-description";
import type { WireAppVideoPlayerComponent } from "@glide/fluent-components/dist/js/base-components";
import type { AppDescriptionContext } from "@glide/function-utils";
import { makeTextPropertyDescriptor } from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import {
    type WireRowComponentHydratorConstructor,
    WireComponentKind,
    type WireInflationBackend,
    UIAspect,
} from "@glide/wire";

import { inflateStringProperty, makeSimpleWireRowComponentHydratorConstructor, spreadComponentID } from "../wire/utils";
import { type SimpleComponentDescription, SimpleComponentHandler } from "./simple-handler";
import { makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";

export const ComponentKindVideo: ComponentKind = "video";

export interface VideoPlayerComponentDescription extends SimpleComponentDescription {
    readonly urlProperty: LegacyPropertyDescription | undefined;
}

export class VideoComponentHandler extends SimpleComponentHandler {
    public readonly appKinds = AppKind.App;

    constructor() {
        super({
            kind: ComponentKindVideo,
            name: "Video",
            group: "Media",
            description: "Embed video from YouTube, Vimeo, etc.",
            img: "co-video",
            helpPath: "videoPlayer",
        });
    }

    protected getPropertyDescriptors(
        _desc: SimpleComponentDescription,
        mutatingScreenKind: MutatingScreenKind | undefined
    ) {
        return [
            {
                descriptor: () =>
                    makeTextPropertyDescriptor("urlProperty", "Video", "Enter URL", true, mutatingScreenKind, {
                        preferredType: "uri",
                        preferredNames: ["video"],
                        searchable: false,
                        columnFirst: true,
                    }),
                argumentName: "url",
            },
        ];
    }

    public inflate(
        ib: WireInflationBackend,
        desc: VideoPlayerComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const [urlGetter, urlType] = inflateStringProperty(ib, desc.urlProperty, true);
        if (urlType === undefined) return undefined;

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const url = urlGetter(hb) ?? "";
            if (url === "") return undefined;

            const component: WireAppVideoPlayerComponent = {
                kind: WireComponentKind.AppVideoPlayer,
                ...spreadComponentID(desc.componentID, forBuilder),
                url,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: VideoPlayerComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return {
            ...makeEmptyComponentDescription(WireComponentKind.SimpleVideo),
            video: desc.urlProperty,
            aspectRatio: makeEnumProperty(UIAspect.SixteenByNine),
            showControls: makeSwitchProperty(true),
        } as ComponentDescription;
    }
}
