import type { BaseTheme } from "@glide/base-theme";
import { fontStyles } from "../font-styles";
import type { CoreTheme } from "@glide/theme";
import { coreTheme } from "@glide/theme";

/**
 * This is the theme for Classic Apps.
 */

export const pageMarginMedium = 16;

export type RuntimeTheme = typeof defaultRuntimeTheme & BaseTheme;

const shadowColor = (opacity: number) => `rgba(62, 65, 86, ${opacity})`;

const appCoreTheme: CoreTheme = {
    ...coreTheme,

    n0: "#FFFFFF",
    n0A: "rgba(255, 255, 255, 0)",
    n100: "#F6F6F6",
    n100A: "rgba(121, 121, 121, 0.06)",
    n200: "#F2F2F2",
    n200A: "rgba(121, 121, 121, 0.1)",

    n300: "#E8E8E8",
    n300A: "rgba(121, 121, 121, 0.16)",
    n400: "#E1E1E1",
    n400A: "rgba(116, 116, 116, 0.22)",
    n500: "#B9B9B9",
    n500A: "rgba(65, 65, 65, 0.37)",

    n600: "#999999",
    n600A: "rgba(45, 45, 45, 0.58)",
    n700: "#555555",
    n700A: "rgba(35, 35, 35, 0.8)",
    n800: "#111111",
    n800A: "rgba(29, 29, 29, 0.92)",
    n900: "#000000",

    textDark: "_n900",
    textBase: "_n800",
    textPale: "_n700",
    textXpale: "_n600",
    textDisabled: "_n500",

    iconDark: "_n800",
    iconBase: "_n700",
    iconPale: "_n600",
    iconXpale: "_n500",
    iconDisabled: "_n400",

    accent: "_primaryAccentColor",

    shadowSoft0: `0px 1px 0px ${shadowColor(0.04)}`,
    shadowSoft1: `box-shadow: 0px 1px 3px ${shadowColor(0.08)};`,
    shadowSoft2: `0px 6px 12px ${shadowColor(0.1)}`,
    shadowSoft3: `0px 10px 20px ${shadowColor(0.1)}`,
    shadowSoft4: `0px 24px 48px ${shadowColor(0.1)}`,

    shadow0: `0px 1px 0px ${shadowColor(0.06)}`,
    shadow1: `0px 0px 0px 1px ${shadowColor(0.06)}, 0px 1px 3px ${shadowColor(0.08)}`,
    shadow2: `0px 0px 0px 1px ${shadowColor(0.06)}, 0px 6px 12px ${shadowColor(0.08)}`,
    shadow3: `0px 0px 1px 1px ${shadowColor(0.06)}, 0px 10px 20px ${shadowColor(0.08)}`,
    shadow4: `0px 0px 1px 1px ${shadowColor(0.06)}, 0px 24px 48px ${shadowColor(0.08)}`,

    shadowDark0: `0px 1px 0px ${shadowColor(0.12)}`,
    shadowDark1: `0px 1px 1px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px ${shadowColor(0.08)}, 0px 1px 3px ${shadowColor(
        0.08
    )}`,
    shadowDark2: `0px 0px 0px 1px ${shadowColor(0.08)}, 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 6px 12px ${shadowColor(
        0.08
    )}`,
    shadowDark3: `0px 0px 0px 1px ${shadowColor(0.08)}, 0px 3px 6px rgba(0, 0, 0, 0.06), 0px 10px 20px ${shadowColor(
        0.1
    )}`,
    shadowDark4: `0px 0px 0px 1px ${shadowColor(0.08)}, 0px 4px 8px rgba(0, 0, 0, 0.06), 0px 24px 48px ${shadowColor(
        0.12
    )}`,

    shadowXdark0: `0px 1px 0px ${shadowColor(0.25)}`,
    shadowXdark1: `0px 1px 1px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px ${shadowColor(0.08)}, 0px 1px 3px ${shadowColor(
        0.25
    )}`,
    shadowXdark2: `0px 0px 0px 1px ${shadowColor(0.08)}, 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 12px ${shadowColor(
        0.25
    )}`,
    shadowXdark3: `0px 0px 0px 1px ${shadowColor(0.08)}, 0px 3px 6px rgba(0, 0, 0, 0.06), 0px 10px 20px ${shadowColor(
        0.25
    )}`,
    shadowXdark4: `0px 6px 12px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px ${shadowColor(0.08)}, 0px 24px 48px ${shadowColor(
        0.25
    )}`,
};

export const defaultRuntimeTheme = {
    overlayName: "default",

    ...appCoreTheme,
    // start of note: These aren't in the appCoreTheme because we want to stop using them in favor of textDark, textBase
    fgColorOffBase: "_n600",
    fgColorLight: "_n600",
    fgColorMedium: "_n700",
    fgColorDark: "_n800",
    fgColorOpaque: "_n900",
    baseColor: "_n0",
    offBaseColor: "_n100",
    skeletonColor: "_n200",
    linkColor: "#106ba3",
    separatorColor: "rgba(0, 0, 0, 0.12)",
    veilColor: "rgba(0, 0, 0, 0.2)",
    destroyColor: "_r500",

    primaryOverlayColor: "#FFFFFFF9",
    transparentOverlayColor: "#FFFFFFC9",
    accentForegroundColor: "_n0",
    // end of note

    builderWidth: 393,
    builderHeight: 852,

    pageMarginMedium,
    componentInnerVerticalPadding: 12,
    componentSmallInnerVerticalPadding: 8,

    preferredRoundingRadius: 10,

    fontStyles,

    mapTheme: "light",
    avoidColors: [
        { color: "#FFFFFF", contrast: 1.8, isBase: true },
        { color: "#000000", contrast: 1.7, isBase: false },
    ],

    textField: {
        underlineColor: "rgba(0, 0, 0, 0.15)",
    },

    calendar: {
        pastEventColor: "_n300",
        pastEventAccent: "_n600",
    },

    statusBar: {
        color: "#000",
        height: 54,
    },

    homeBar: {
        color: "#000",
        height: 34,
    },
    expandableOptions: {
        modal: {
            bgColor: "#FFFFFF",
        },
        options: {
            bgColorSelected: "_primaryAccentColor",
            bgColorUnselected: "#f5f5f5",
            fgColorSelected: "#ffffff",
            fgColorUnselected: "#c4c4c4",
        },
        button: {
            bgColorSelected: "_primaryAccentColor",
            bgColorUnselected: "#f5f5f5",
            fgColorSelected: "#ffffff",
            fgColorUnselected: "#c4c4c4",
        },
    },
    cards: {
        bgColor: "_baseColor",
    },
    navBar: {
        height: 44,
        heightWithSearch: 96,
        titleFontStyle: "_fontStyles.AccentCallout4Fixed",
        style: "iOS",
        fgColor: "_primaryAccentColor",
        titleColor: "_fgColorDark",
        bgColor: "_primaryOverlayColor",
        transparentBgColor: "_transparentOverlayColor",
        fadeTo: "white",
        cancelColor: "_fgColorDark",

        largeTitleHeight: 0,
        borderRadius: 0,
    },
    offlineBanner: {
        height: 64,
        gap: 12,
    },

    scrollView: {
        backgroundColor: "none",
        marginTop: 0,
        marginLeft: 0,
        borderRadius: 0,
        paddingTop: 0,
        paddingLeft: 0,
    },

    tabBar: {
        height: 49,
        unselectedColor: "#999999",

        tab: {
            phone: {
                fontStyle: "_fontStyles.Subheader7Fixed",
            },
            tablet: {
                fontStyle: "_fontStyles.Subheader6Fixed",
            },
        },
    },

    picker: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        hoverBgColor: "rgba(0, 0, 0, 0.075)",
        activeBgColor: "rgba(0, 0, 0, 0.12)",
        currentBgColor: "_baseColor",
        currentColor: "_primaryAccentColor",
    },

    materialDateTimePicker: {
        borderRadius: "12",
        borderWidth: "0",
        borderColor: "transparent",
        borderStyle: "none",

        header: {
            background: "_baseColor",
            textDark: "_fgColorDark",
            textLight: "_fgColorLight",
        },
        tabs: {
            background: "_baseColor",
            iconColor: "_fgColorMedium",
            iconSelectedColor: "_primaryAccentColor",
        },
        cancelConfirm: {
            bg: "_primaryAccentColor",
            fg: "_accentForegroundColor",
        },
    },

    searchBar: {
        style: "Inline",
        height: 36,
        heightWithPadding: 52, // convenience mostly
        paddingSides: 12,
        iconPadding: 8,
        fgColor: "_fgColorLight",
        placeholderColor: "_fgColorLight",
        textColor: "_fgColorDark",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        maxWidth: "100%",
    },

    signature: {
        invert: false,
    },

    pivot: {
        style: "SplitButton",
        height: 32,
        bottomMargin: "_pageMarginMedium",
        troughColor: "_n200A",
        troughFg: "_textDark",
    },

    gridItem: {
        title: {
            largeFontStyle: "_fontStyles.AccentCallout2",
            fontStyle: "_fontStyles.AccentCallout4",
            smallFontStyle: "_fontStyles.AccentCallout5",
        },
        subtitle: {
            largeFontStyle: "_fontStyles.Header4",
            fontStyle: "_fontStyles.Header5",
            smallFontStyle: "_fontStyles.Header6",
        },
    },

    list: {
        topMargin: 0,
    },

    listItem: {
        defaultRowHeight: 72,
        titleFontStyle: "_fontStyles.Header4",
        titleLineHeight: "_fontStyles.Header4Height",
        detailFontStyle: "_fontStyles.Caption5",
        detailLineHeight: "_fontStyles.Caption5Height",
        captionFontStyle: "_fontStyles.Body6",
        captionLineHeight: "_fontStyles.Body6Height",
        captionAlign: "inherit",
        selectedColor: "rgba(0, 0, 0, 0.05)",
        selectedColorOpaque: "rgba(235, 235, 235, 1)",
        textDarkenAmount: 25,
        underlineLeft: "_pageMarginMedium",
        underlineRight: 0,
        initialsFontStyle: "_fontStyles.AccentCallout2Fixed",

        mainImage: {
            width: 48,
            height: 48,
            borderRadius: "50%",
            marginRight: 10,
        },
        actionImage: {
            width: 40,
            height: 40,
            borderRadius: "50%",
            iconSize: 18,
            margin: 10,
            color: "#fff",
        },
    },

    tileItem: {
        padding: 0,
        borderRadius: 6,
        selectedColor: "none",
    },

    summary: {
        height: 300,

        title: {
            fontStyle: "_fontStyles.AccentHeader3",
        },
        detail: {
            fontStyle: "_fontStyles.AccentCallout4",
        },
    },

    youtube: {
        defaultHeight: 300,
    },

    map: {
        defaultHeight: 300,
        locationButtonForegroundColor: "_primaryAccentColor",
    },

    switch: {
        compact: {
            backgroundColor: "#000000",
            thumbColor: "#ffffff",
        },
        big: {
            selectedBackgroundColor: "_primaryAccentColor",
            backgroundColor: "_picker.hoverBgColor",
            thumbColor: "#ffffff",
            borderColor: "_picker.backgroundColor",
        },
    },

    button: {
        filledTextColor: "white",
        borderRadius: 10,
        height: 44,
        uppercase: false,
        shadow: "unset",
        fontStyle: "_fontStyles.Subheader4",
    },

    image: {
        caption: {
            color: "#FFFFFF",
            fontSize: 12,
            margin: 10,
            borderRadius: 20,
            opacity: 0.8,
            paddingVertical: 6,
            paddingHorizontal: 10,
            backgroundColor: "#000000",
        },
    },

    enlargedImageOverlay: {
        backgroundColor: "#000000",
        textColor: "#ffffff",
    },

    formSubmissionSuccessToast: {
        background: "rgba(230, 230, 230, 0.96)",
        translucentBackground: "rgba(230, 230, 230, 0.6)",
        checkmark: "black",
        labelColor: "#000000",
    },

    flyout: {
        overlayColor: "#ffffff99",
        bgColor: "white",
        headerFg: "rgba(0, 0, 0, 0.9)",
        headerBg: "white",
        headerBorderColor: "#E0E0E0",
        rightBorderColor: "#E0E0E0",
        headerImgColor: "#E8E8E8",
    },

    userProfile: {
        title: {
            textColor: "_fgColorOpaque",
            fontStyle: "_fontStyles.AccentHeader3",
            marginBottom: 4,
        },
        subtitle: {
            textColor: "_fgColorMedium",
            fontStyle: "_fontStyles.Body4",
        },
    },

    quotaBar: {
        height: 60,
        bgColor: "_offBaseColor",
    },
};
