/* eslint-disable @typescript-eslint/no-shadow */

// This replaces some Glide AI computations with new ones that use
// updated models (gpt-3.5-turbo-1106) and new features (text-to-json).

import * as glide from "@glide/plugins";

import { isEmptyOrUndefined } from "@glide/support";
import { GlideAI } from "./glide-ai-core";

const models = [
    {
        id: "gpt-3.5-turbo-1106",
        name: "Default",
        description: "GPT-3.5 Turbo models are capable and cost-effective",
        // $0.0020 / 1K tokens
        // So 1 update ($0.02) is 10k tokens
        completionTokensPerUpdate: 4096,
        promptTokensPerUpdate: 4096,
    },
    {
        id: "gpt-4-1106-preview",
        name: "High Power",
        description:
            "With 128k context, fresher knowledge and the broadest set of capabilities, GPT-4 Turbo is more powerful than GPT-4 and offered at a lower price.",

        // GPT 4 Turbo cost:
        //
        //   * $0.01 per 1k prompt tokens
        //   * $0.03 per 1k completion tokens
        //
        // 1 update is $0.02 and we want to cover the cost, so 2 updates buys 2^10 of the more expensive tokens.
        // So, 1 update is 2^9:
        completionTokensPerUpdate: 512,
        promptTokensPerUpdate: 1024,
    },
    {
        id: "gpt-4o",
        name: "High Power v2",
        description:
            "With 128k context, fresher knowledge and the broadest set of capabilities, GPT-4o is faster and cheaper and more up to date than GPT-4 Turbo.",

        /*
         * GPT-4 Cost:
         * - $0.005 per 1k prompt tokens
         * - $0.015 per 1k completion tokens
         *
         * Our system uses an Update currency, and each update costs $0.02.
         * How many prompt tokens can we give for the price of an update? How many completion tokens?
         *
         * ### Calculations:
         *
         * 1. **Prompt Tokens:**
         *    - Cost per 1k prompt tokens = $0.005
         *    - Cost per prompt token = $0.005 / 1000 = $0.000005 per token
         *    - Number of prompt tokens for $0.02 = $0.02 / $0.000005
         *
         *    Number of prompt tokens = 0.02 / 0.000005 = 4000 tokens
         *
         * 2. **Completion Tokens:**
         *    - Cost per 1k completion tokens = $0.015
         *    - Cost per completion token = $0.015 / 1000 = $0.000015 per token
         *    - Number of completion tokens for $0.02 = $0.02 / 0.000015
         *
         *    Number of completion tokens = 0.02 / 0.000015 = 1333.33 tokens
         *
         * So, for the price of one update ($0.02):
         * - You can give **4000 prompt tokens**.
         * - You can give **1333.33 completion tokens** (which typically would be rounded down to 1333 tokens in practical scenarios).
         */

        promptTokensPerUpdate: 4000,
        completionTokensPerUpdate: 1333,
    },
];

const modelCostDescription =
    models
        .map(m => `**${m.completionTokensPerUpdate.toLocaleString()}** tokens for the ${m.name} model`)
        .join("; **1** update per ") + ".";

const DefaultAIColors = {
    fgColor: "var(--gv-icon-base)",
    bgColor: "var(--gv-pink500)",
};

export function init(plugin: glide.NativePlugin<glide.ParameterRecord>) {
    plugin.addComputation({
        id: "complete-prompt-v2",
        name: "Generate Text",
        deprecated: true,
        description: "Generate text based on a prompt. Uses GPT-4 Turbo with 128k token context.",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["complete", "prompt", "completion", "openai", "ai"],
        billablesConsumed: { number: 1, per: modelCostDescription },
        icon: { kind: "monotone", icon: "mt-column-generate-text", ...DefaultAIColors },
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "Add instructions to guide the AI.",
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
            model: glide.makeParameter({
                name: "Model",
                type: "enum",
                defaultValue: models[0].id,
                values: models.map(m => ({ value: m.id, label: m.name })),
                required: true,
                emptyByDefault: true,
            }),
        },
        fetchBehavior: { headerTimeout: 300_000 },
        results: {
            result: glide.makeParameter({ type: "string", name: "Result" }),
        },
        execute: async (context, { message, instructionsForAI: instructions, model: modelId = models[0].id }) => {
            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const model = models.find(m => m.id === modelId);
            if (model === undefined) {
                return glide.Result.FailPermanent(`Unknown model ${modelId}`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const ai = new GlideAI(apiKey, context.fetch);

            const cachedCompletion = await context.useCache(async () => {
                const completion = await ai.generateText(instructions, message, model.id);
                if (!completion.ok) return completion;

                const updatesUsed = Math.ceil(
                    completion.result.prompt_tokens / model.promptTokensPerUpdate +
                        completion.result.completion_tokens / model.completionTokensPerUpdate
                );
                context.consumeBillable(updatesUsed);
                context.trackMetric("tokensUsed", completion.result.total_tokens);

                return completion;
            }, [message, instructions, model]);

            if (cachedCompletion.ok === false) {
                return cachedCompletion;
            }

            const { result } = cachedCompletion;
            return glide.Result.Ok({
                result: result.value,
            });
        },
    });

    plugin.addComputation({
        id: "text-to-choice-v2",
        name: "Text to Choice",
        icon: { kind: "monotone", icon: "mt-column-choice", ...DefaultAIColors },
        description: "Use AI to pick from list of choices.",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["choice", "categorize", "select", "ai", "structure", "parse", "classify"],
        billablesConsumed: 1,
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "e.g. You are reviewing comments for profanity",
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
            choices: glide.makeParameter({
                type: "string",
                name: "Choices",
                placeholder: "e.g. Profane,Neutral",
                required: true,
                emptyByDefault: true,
            }),
        },
        results: {
            result: glide.makeParameter({ type: "string", name: "Result" }),
        },
        execute: async (context, { choices, message, instructionsForAI: instructions = "" }) => {
            if (isEmptyOrUndefined(choices) || !choices.includes(",")) {
                return glide.Result.FailPermanent(`Please provide comma-separated choices`, {
                    isPluginError: false,
                });
            }

            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const ai = new GlideAI(apiKey, context.fetch);
            const choicesCanonical = choices
                .split(",")
                .map(c => c.trim())
                .filter(c => c.length > 0)
                .sort();
            const cachedCompletion = await context.useCache(async () => {
                const completion = await ai.textToChoice(instructions, message, choicesCanonical);
                if (!completion.ok) return completion;

                context.consumeBillable();
                context.trackMetric("tokensUsed", completion.result.total_tokens);

                return glide.Result.Ok({ result: completion.result.value });
            }, [instructions, message, choicesCanonical]);

            return cachedCompletion;
        },
    });

    plugin.addComputation({
        id: "text-to-boolean-v2",
        name: "Text to Boolean",
        description: "Use AI to write a boolean result",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["boolean", "true", "false", "ai", "yes", "no", "structure"],
        icon: { kind: "monotone", icon: "mt-column-boolean", ...DefaultAIColors },
        billablesConsumed: 1,
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "e.g. Does the message need customer service?",
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
        },
        results: {
            result: glide.makeParameter({ type: "boolean", name: "Result" }),
        },
        execute: async (context, { message, instructionsForAI: instructions }) => {
            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const ai = new GlideAI(apiKey, context.fetch);

            const cachedCompletion = await context.useCache(async () => {
                const completion = await ai.textToBoolean(instructions, message);
                if (!completion.ok) return completion;

                const { value, total_tokens } = completion.result;
                context.consumeBillable();
                context.trackMetric("tokensUsed", total_tokens);

                return glide.Result.Ok({ result: value });
            }, [instructions, message]);

            return cachedCompletion;
        },
    });

    plugin.addComputation({
        id: "text-to-number-v2",
        name: "Text to Number",
        description: "Use AI to write a number result",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["number", "ai", "parse", "decimal", "structure"],
        icon: { kind: "monotone", icon: "mt-column-number", ...DefaultAIColors },
        billablesConsumed: 1,
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "e.g. Extract the quantity from the request",
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
        },
        results: {
            result: glide.makeParameter({ type: "number", name: "Result" }),
        },
        execute: async (context, { message = "", instructionsForAI: instructions = "" }) => {
            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const ai = new GlideAI(apiKey, context.fetch);

            const cachedCompletion = await context.useCache(async () => {
                const completion = await ai.textToNumber(instructions, message);
                if (!completion.ok) return completion;

                context.consumeBillable();
                context.trackMetric("tokensUsed", completion.result.total_tokens);

                return glide.Result.Ok({ result: completion.result.number });
            }, [instructions, message]);

            return cachedCompletion;
        },
    });

    plugin.addComputation({
        id: "text-to-json",
        name: "Text to JSON",
        description: "Use AI to generate JSON based on a prompt.",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["json", "prompt", "ai", "extract", "structure"],
        billablesConsumed: 1,
        icon: { kind: "monotone", icon: "mt-column-json", ...DefaultAIColors },
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "Add instructions to guide the AI.",
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
        },
        results: {
            result: glide.makeParameter({ type: "json", name: "Result" }),
        },
        fetchBehavior: { headerTimeout: 300_000 },
        execute: async (context, { message, instructionsForAI: instructions }) => {
            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const ai = new GlideAI(apiKey, context.fetch);

            const cachedCompletion = await context.useCache(async () => {
                const completion = await ai.textToJSON(instructions, message);
                if (!completion.ok) return completion;

                context.trackMetric("tokensUsed", completion.result.total_tokens);
                context.consumeBillable();

                return completion;
            }, [message, instructions]);

            if (cachedCompletion.ok === false) {
                return cachedCompletion;
            }

            const { result } = cachedCompletion;
            return glide.Result.Ok({
                result: JSON.stringify(result.data),
            });
        },
    });

    plugin.addComputation({
        id: "image-to-text-v2",
        name: "Image to Text",
        tier: glide.makeTierList("starter"),
        description: "Extract information from image to text",
        configurationDescriptionPattern: "To ${text}",
        keywords: ["image", "picture", "describe", "extract", "caption", "ai"],
        icon: { kind: "monotone", icon: "mt-column-image", ...DefaultAIColors },
        billablesConsumed: 2,
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "Add instructions to guide the AI.",
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
            images: glide.makeParameter({
                type: "stringArray",
                name: "Images",
                description: "Choose one or more images for analysis",
                required: true,
                emptyByDefault: true,
            }),
        },
        results: {
            value: glide.makeParameter({
                type: "string",
                name: "Text",
            }),
        },
        execute: async (context, { images = [], message, instructionsForAI }) => {
            if (images.length === 0) {
                return glide.Result.Fail(`Please provide an image`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const ai = new GlideAI(apiKey, context.fetch);
            const result = await context.useCache(async () => {
                const apiResult = await ai.imageReasoning(instructionsForAI, images);

                if (apiResult.ok === true) {
                    context.trackMetric("tokensUsed", apiResult.result.total_tokens);
                    context.consumeBillable();
                }
                return apiResult;
            }, [instructionsForAI, message, images, apiKey]);

            return result;
        },
    });

    plugin.addComputation({
        id: "text-to-texts",
        name: "Text to Texts",
        description:
            "Extract multiple text values from the input text. For example, extract all of the questions from a document.",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["text", "extract", "multiple", "ai", "list", "array", "questions"],
        billablesConsumed: 1,
        icon: { kind: "monotone", icon: "mt-column-generate-text", ...DefaultAIColors },
        parameters: {
            instructions: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "e.g. Extract example questions from the document",
                emptyByDefault: true,
                required: true,
                useTemplate: "withLabel",
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to process or select a column",
                required: true,
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
        },
        results: {
            // using stringArray is not supported as a result type yet, so we use json for now
            texts: glide.makeParameter({ type: "json", name: "Texts" }),
        },
        execute: async (context, { instructions, message }) => {
            if (isEmptyOrUndefined(instructions)) {
                return glide.Result.FailPermanent(`Please provide instructions`, {
                    isPluginError: false,
                });
            }
            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide input text`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const ai = new GlideAI(apiKey, context.fetch);
            const cachedCompletion = await context.useCache(async () => {
                const completion = await ai.textToTexts(instructions, message);
                if (!completion.ok) return completion;

                context.trackMetric("tokensUsed", completion.result.total_tokens);
                context.consumeBillable();

                return glide.Result.Ok({ texts: completion.result.values });
            }, [message, instructions]);

            return cachedCompletion;
        },
    });
}
