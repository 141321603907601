import type { BaseTheme } from "@glide/base-theme";
import { DeviceFormFactor, getMemoizeTheme, GlobalStyle, TailwindThemeProvider, useIsOSThemeDark } from "@glide/common";
import MobileDetect from "mobile-detect";
import React from "react";

interface AppThemeProviderProps {
    baseTheme: BaseTheme;
}

export const AppThemeProvider: React.FC<React.PropsWithChildren<AppThemeProviderProps>> = p => {
    const { children, baseTheme } = p;
    const isOSThemeDark = useIsOSThemeDark();

    const platform = React.useMemo(() => {
        if (new MobileDetect(window.navigator.userAgent).is("AndroidOS")) {
            return "Android";
        } else {
            return "iOS";
        }
    }, []);

    const theme = React.useMemo(() => {
        return getMemoizeTheme(true, baseTheme, platform, DeviceFormFactor.Phone, false, isOSThemeDark);
    }, [platform, baseTheme, isOSThemeDark]);

    return (
        <>
            <GlobalStyle />
            <TailwindThemeProvider theme={theme}>{children}</TailwindThemeProvider>
        </>
    );
};
