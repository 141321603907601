import type { TableName } from "@glide/type-schema";
import type { AutomationRootNode, ConditionalActionNode, FlowActionNode } from "./compound-action";
import type { FilterArrayTransform } from "./description";
import type { Schedule } from "@glide/automations-schedule";
import type { PluginConfig } from "./serialized-app";
import type { AutomationTriggerKind } from "@glide/automations-codecs";
import { panic } from "@glideapps/ts-necessities";

interface AutomationTriggerBase {
    readonly enabled: boolean;
    /**
     * A UUID.
     */
    readonly triggerID: string;
}

export interface AutomationScheduleTrigger extends AutomationTriggerBase {
    readonly kind: Extract<AutomationTriggerKind, "schedule">;
    readonly schedule: Schedule;
}

export interface AutomationManualTrigger extends AutomationTriggerBase {
    readonly kind: Extract<AutomationTriggerKind, "manual">;
}

export interface AutomationPluginTrigger extends AutomationTriggerBase {
    readonly kind: Extract<AutomationTriggerKind, "plugin">;
    readonly pluginConfigID: string;
    /**
     * This is the ID of the trigger defined in the plugin.
     */
    readonly pluginTriggerID: string;
    readonly parameters: PluginConfig["parameters"];
}

export type AutomationTrigger = AutomationScheduleTrigger | AutomationManualTrigger | AutomationPluginTrigger;

export interface AutomationDetails {
    readonly triggers: readonly AutomationTrigger[];
    readonly filter: FilterArrayTransform | undefined;
    readonly limit: number;
}

interface AppBuilderActionPerApp {
    readonly tableName: TableName;
    readonly automation?: never;
}

interface AutomationActionPerApp {
    readonly tableName?: TableName;
    readonly automation: AutomationDetails;
}

export type BuilderActionPerApp = AppBuilderActionPerApp | AutomationActionPerApp;

export function makeBuilderActionPerApp(
    tableName: TableName | undefined,
    automation: AutomationDetails | undefined
): BuilderActionPerApp {
    if (automation !== undefined) {
        return {
            tableName: undefined,
            automation,
        };
    }

    if (tableName !== undefined) {
        return {
            tableName,
            automation: undefined,
        };
    }

    panic("tableName or automation must be defined");
}

export interface BuilderAction {
    // zipped JSON
    readonly action: ConditionalActionNode | FlowActionNode | AutomationRootNode;

    readonly title?: string;
    readonly description?: string;

    // We don't delete builder actions unless it's been 24 hours since we've
    // last seen them, because removing them from an app is undable.
    // https://github.com/quicktype/glide/issues/15380
    readonly lastSeenAt?: Date;

    readonly appIDs: readonly string[];

    // indexed by app ID
    readonly perApp: Partial<Record<string, BuilderActionPerApp>>;

    // This might be missing in Firestore.  It will be `true` iff one of the
    // apps has an automation, whether it's enabled or not.  We use this to
    // quickly find all the actions that have automations via a Firestore
    // query.
    readonly hasAutomation: boolean;
}

export type BuilderActionsForApp = ReadonlyMap<string, BuilderAction>;

export interface BuilderActionWithID extends BuilderAction {
    readonly id: string;
}
