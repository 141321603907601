import type { Formula, TableGlideType, SchemaInspector } from "@glide/type-schema";
import type { ActionNodeInScope } from "@glide/function-utils";
import { assert, mapFilterUndefined } from "@glideapps/ts-necessities";
import { decomposePredicateCombinationFormula } from "@glide/formula-specifications";
import { tokenizePredicate } from "./operator-configurator-lib";
import type { SpecialValueDescriptor } from "../components/special-values";

export function getLabelForConditionFormula(
    formula: Formula,
    si: SchemaInspector,
    table: TableGlideType | undefined,
    actionNodesInScope: ActionNodeInScope[],
    specialValueDescriptors: SpecialValueDescriptor[]
): string {
    const decomposed = decomposePredicateCombinationFormula(formula)?.spec;

    assert(decomposed !== undefined);

    return mapFilterUndefined(decomposed.predicates, p => {
        const [hyp, op, con] = tokenizePredicate(p, si, table, undefined, actionNodesInScope, specialValueDescriptors);
        let label = `${hyp} ${op}`;
        if (con !== undefined) {
            label += ` ${con}`;
        }
        return label;
    }).join(` ${decomposed.combinator} `);
}
