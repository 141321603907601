// NOTE: Don't export anything here that would pull in any
// React/styled-components code, or a bunch of places would then automatically
// depend on it and potentially crash because they don't have `window`
// defined.

// DON'T DO THIS. Functions jest will stop working.
// export * from "./analytics";
export * from "./analytics/app-events";
export * from "./description";
export * from "./feature-settings";
export * from "./firebase-function-types";
export type { AppLoginAuthDataFromPlay } from "./Database";
export { makeActionNodeOutputSourceColumn } from "@glide/type-schema";

// These are exported only so that Typescript can "name" these types, which
// are part of the `eminenceFlagsCodec`.
export type { AutomationTriggerKind, ScheduleKind } from "@glide/automations-codecs";
