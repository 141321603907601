import {
    asMaybeString,
    asString,
    type DefinedPrimitiveValue,
    type PrimitiveValue,
    type QuerySearch,
    type SerializedQuery,
} from "@glide/computation-model-types";
import { asMaybeDate } from "@glide/common-core/dist/js/computation-model/data";
import { makeRoleHash } from "@glide/common-core/dist/js/database-strings";
import { type TableColumn, isDateTimeTypeKind, isNumberTypeKind, isStringTypeKind } from "@glide/type-schema";
import { GlideDateTime } from "@glide/data-types";
import { isTzAwareColumn } from "@glide/formula-specifications";
import { asMaybeBoolean, asMaybeNumber } from "@glide/common-core/dist/js/type-conversions";
import { isEmptyOrUndefined, isUndefinedOrNaN } from "@glide/support";
import { assertNever, definedMap } from "@glideapps/ts-necessities";
import { convertColumnToPluginColumn } from "./plugin-column";
import { type ColumnValueGetter, isConditionTrueForRow } from "./refilter";

function getDateValue(gdt: GlideDateTime, c: TableColumn): GlideDateTime {
    return isTzAwareColumn(c)
        ? GlideDateTime.fromDocumentData({ ...gdt.toDocumentData(), tzOffset: 0 })
        : gdt.toOriginTimeZoneAgnostic();
}

// This should match the conversions done in evaluateBinaryForType below.
export function convertValueForColumnType<T>(
    value: PrimitiveValue,
    column: TableColumn,
    defaultValue: T
): DefinedPrimitiveValue | T {
    const typeKind = column.type.kind;
    if (isDateTimeTypeKind(typeKind)) {
        return definedMap(asMaybeDate(value), v => getDateValue(v, column)) ?? defaultValue;
    }
    if (isStringTypeKind(typeKind)) {
        const str = asMaybeString(value);
        return isEmptyOrUndefined(str) ? defaultValue : str;
    }
    if (isNumberTypeKind(typeKind)) {
        const num = asMaybeNumber(value);
        return isUndefinedOrNaN(num) ? defaultValue : num;
    }
    switch (typeKind) {
        case "boolean": {
            return asMaybeBoolean(value) ?? defaultValue;
        }
        case "array":
        case "table-ref":
            return defaultValue;
        default:
            return assertNever(typeKind);
    }
}

// We could use `lowerSearchInQuery` instead of this.
function isRowMatchedBySearch(search: QuerySearch | undefined, getColumnValue: ColumnValueGetter): boolean {
    if (search === undefined) return true;
    const needle = search.needle.trim().toLowerCase();
    if (needle === "") return true;
    for (const column of search.columnNames) {
        const value = asString(getColumnValue(column));
        if (value.toLowerCase().includes(needle)) {
            return true;
        }
    }
    return false;
}

// NOTE: This isn't (meant to be) fast.
export function areConditionsTrueForRow(
    query: Pick<SerializedQuery, "conditions" | "search" | "deviceTzMinutesOffset">,
    getColumnValue: ColumnValueGetter,
    columns: readonly TableColumn[]
): boolean {
    const { conditions, search, deviceTzMinutesOffset } = query;
    return (
        conditions.every(disjunction =>
            disjunction.some(condition =>
                isConditionTrueForRow(
                    condition,
                    getColumnValue,
                    columns.map(convertColumnToPluginColumn),
                    GlideDateTime.now(),
                    makeRoleHash,
                    deviceTzMinutesOffset
                )
            )
        ) && isRowMatchedBySearch(search, getColumnValue)
    );
}
