import type { PagesLoginSource } from "@glide/auth-controller-core";
import { SecondaryLabelWrapper } from "../custom-sign-in/custom-wire-sign-in/secondary-label-wrapper";
import { getLocalizedString } from "@glide/localization";
import type { AppKind } from "@glide/location-common";

interface Props extends React.PropsWithChildren {
    readonly pagesSource?: PagesLoginSource;
    readonly onPageSourceChanged?: (newPageSource: PagesLoginSource) => void;
    readonly appKind: AppKind;
}

export const SignUpFooter: React.VFC<Props> = p => {
    const { pagesSource, onPageSourceChanged, appKind } = p;
    const showSignUpFooter = pagesSource === "modal-sign-in" || pagesSource === "modal-sign-up";
    const descriptionText =
        pagesSource === "modal-sign-in"
            ? getLocalizedString("dontHaveAnAccount", appKind)
            : getLocalizedString("alreadyHaveAnAccount", appKind);
    const actionText =
        pagesSource === "modal-sign-in"
            ? getLocalizedString("signUp", appKind)
            : getLocalizedString("customerLogIn", appKind);
    const action =
        pagesSource === "modal-sign-in"
            ? () => onPageSourceChanged?.("modal-sign-up")
            : () => onPageSourceChanged?.("modal-sign-in");

    return (
        <>
            {showSignUpFooter && (
                <div tw="flex items-center justify-center text-sm mt-6">
                    <SecondaryLabelWrapper>
                        <span>{descriptionText}</span>
                        <span className="secondary" tw="font-medium pl-1 cursor-pointer" onClick={action}>
                            {actionText}
                        </span>
                    </SecondaryLabelWrapper>
                </div>
            )}
        </>
    );
};
