import { frontendSendEvent } from "@glide/common-core/dist/js/tracing";
import { getCurrentTimestampInMilliseconds, logError } from "@glide/support";
import { sleep, exceptionToString } from "@glideapps/ts-necessities";
import { isPassThroughEnqueueError, isLikelyUnrecoverableEnqueueError } from "./enqueue-data-action";

export async function loopUntilSuccessfulAction<T>(name: string, f: () => Promise<T>) {
    let retries = 0,
        likelyUnrecoverableErrors = 0;
    const startTime = getCurrentTimestampInMilliseconds();
    while (true) {
        if (0 < retries && retries <= 7) {
            await sleep(100 + Math.random() * 100 * retries ** 2);
        }

        if (retries > 7) {
            await sleep(100 + Math.random() * 4900);
        }

        try {
            return await f();
        } catch (e: unknown) {
            if (isPassThroughEnqueueError(e)) {
                throw e;
            }
            if (isLikelyUnrecoverableEnqueueError(e)) {
                likelyUnrecoverableErrors++;
            }
            if (likelyUnrecoverableErrors >= 7) throw e;
            retries++;
            logError(`Trying ${name} again`, retries, e);
            if (retries > 2) {
                frontendSendEvent(`repeated ${name} failure`, getCurrentTimestampInMilliseconds() - startTime, {
                    retries,
                    exception: exceptionToString(e),
                });
            }
        }
    }
}
