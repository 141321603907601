// FIXME: Sureply this already exists?
export function arrayComparator<T>(
    itemComparator: (left: T, right: T) => number
): (left: readonly T[], right: readonly T[]) => number {
    return (left, right) => {
        let i = 0;
        for (; i < left.length && i < right.length; i++) {
            const cur = itemComparator(left[i], right[i]);
            if (cur !== 0) return cur;
        }
        if (i < right.length) return -1; // Left is shorter but equal to the end.
        if (i < left.length) return 1; // Right is shorter but equal to the end.
        return 0;
    };
}
