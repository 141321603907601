import {
    type ActionAppFacilities,
    type DataStoreMutationMetadata,
    type MutationResult,
    emptyActionOperationsState,
    type ActionOperationsState,
    type ActionOutstandingOperationsHandler,
} from "@glide/common-core/dist/js/components/types";
import { type WritableValue, type RowIndex, type BaseRowIndex, isBaseRowIndex } from "@glide/computation-model-types";
import type { TableName } from "@glide/type-schema";
import { type DocumentData, logError, mapNewOrUpdate, objectWithUndefinedProperties } from "@glide/support";
import { getFeatureFlag } from "@glide/common-core/dist/js/feature-flags";
import type { WriteSourceType } from "@glide/common-core/dist/js/firebase-function-types";
import type { ActionPoster } from "@glide/post-action";
import {
    ActionManager,
    SimpleActionPoster,
    debounceSetColumnsTimeout,
    debounceDataEditorSetColumnsTimeout,
} from "@glide/post-action";
import { defined, DefaultMap, definedMap } from "@glideapps/ts-necessities";
import { setUnionInto } from "collection-utils";
import { diffDocumentData, getOnlyColumns } from "../row-data";
import type { TableMutationHandler } from "../types";
import type { DataStoreEnvironmentAccessors, DurableStorageController } from ".";
import { getFeatureSetting } from "@glide/common-core";

// Basic ideas:
//
// * We assume the backend serializes each device's actions.  This is
//   implemented now.
// * Once some columns are modified by outstanding actions, ignore all updates
//   to those columns, until those actions are confirmed.
// * User-specific columns add a lot of complexity because of the question of
//   which row(s) they are associated with. However, they are not expected to
//   be modified by more than one device concurrently, so it's ok to ignore
//   updates from the backend for a while. As long as we have any outstanding
//   actions, we just don't update user-specific columns.

// * The durable storage handler should always return updates to documents,
//   never whole new documents (unless there was no previous document)
// * For rows where it doesn’t have a change state it still needs to have the
//   latest whole document, so it can diff.
// * To make things easy, it should just keep the whole latest document in one
//   thing, and then just the set of blocked columns, which never make it
//   through as an update, until they are unblocked.

// Invariant:
//
// If the entry has a confirmed action version
// * then the entry has backend values for blocked columns, with a version
//   older than the confirmed action version.

// A map from user-agnostic row to
interface ConsistencyState {
    // * The last action that updated that row.
    lastUpdatingAction: string;
    // * The confirmed version of that action, if confirmed.
    confirmedVersion: number | undefined;
    // * A list of "blocked" columns.
    readonly blockedColumns: Set<string>;
    // * A flag indicating whether we have ever blocked any changes.
    didBlock: boolean;
}

interface RowState {
    // * Latest backend values for all columns, and their version.
    latestBackendData: DocumentData;
    // If we don't have a version yet, we use `MIN_SAFE_INTEGER`
    // as the oldest possible version.
    latestBackendVersion: number;

    consistencyState: ConsistencyState | undefined;
}

function stringify(entry: RowState): string {
    return JSON.stringify({
        ...entry,
        consistencyState: definedMap(entry.consistencyState, c => ({
            ...c,
            blockedColumns: Array.from(c.blockedColumns),
        })),
    });
}

function maybeLog(..._args: any[]): void {
    // don't spam the log here
}

// This is only exported for testing purposes.
export class PartitionHandler {
    private readonly _rowStatesForIndex: DefaultMap<string, Map<BaseRowIndex, RowState>> = new DefaultMap(
        () => new Map()
    );
    private readonly _rowStatesForHints: DefaultMap<string, DefaultMap<string, Map<unknown, RowState>>> =
        new DefaultMap(() => new DefaultMap(() => new Map()));
    private readonly _debugPrint = getFeatureFlag("logStorageController");
    private _preferRowIndex: boolean | undefined;

    constructor(private readonly _deleteWithOutstandingActions: boolean) {}

    private get preferRowIndex(): boolean {
        // We get this late just to make double sure it's initialized.
        if (this._preferRowIndex === undefined) {
            this._preferRowIndex = !getFeatureSetting("preferRowIDInStorageController");
        }
        return this._preferRowIndex;
    }

    public resetTable(tableName: TableName): void {
        this._rowStatesForIndex.delete(tableName.name);
        this._rowStatesForHints.delete(tableName.name);
    }

    private getEntryForRow(tableName: TableName, index: RowIndex): RowState | undefined {
        if (isBaseRowIndex(index)) {
            return this._rowStatesForIndex.get(tableName.name).get(index);
        } else {
            const { keyColumnName, keyColumnValue, rowIndexHint } = index;
            if (rowIndexHint !== undefined && this.preferRowIndex) {
                return this._rowStatesForIndex.get(tableName.name).get(rowIndexHint);
            } else {
                return this._rowStatesForHints.get(tableName.name).get(keyColumnName).get(keyColumnValue);
            }
        }
    }

    private newOrUpdateEntryForRow(
        tableName: TableName,
        index: RowIndex,
        newEntry: () => RowState,
        updateEntry: (e: RowState) => RowState
    ): RowState {
        if (isBaseRowIndex(index)) {
            return mapNewOrUpdate(this._rowStatesForIndex.get(tableName.name), index, newEntry, updateEntry);
        } else {
            const { keyColumnName, keyColumnValue, rowIndexHint } = index;
            if (rowIndexHint !== undefined && this.preferRowIndex) {
                return mapNewOrUpdate(this._rowStatesForIndex.get(tableName.name), rowIndexHint, newEntry, updateEntry);
            } else {
                return mapNewOrUpdate(
                    this._rowStatesForHints.get(tableName.name).get(keyColumnName),
                    keyColumnValue,
                    newEntry,
                    updateEntry
                );
            }
        }
    }

    // ## When a backend update comes in, the storage controller checks
    public handleDocumentUpdate(
        tableName: TableName,
        rowIndex: RowIndex,
        doc: DocumentData,
        docVersion: number,
        docWillMutate: boolean
    ): DocumentData {
        let result: DocumentData | undefined;

        if (this._debugPrint) {
            maybeLog("### update", JSON.stringify(rowIndex), JSON.stringify(doc), docVersion);
        }

        // * Do we have an entry for that row document?
        this.newOrUpdateEntryForRow(
            tableName,
            rowIndex,
            () => {
                if (this._debugPrint) {
                    maybeLog("### update fully accepted", JSON.stringify(rowIndex), JSON.stringify(doc), docVersion);
                }

                // - If no, the update is fully accepted.
                result = docWillMutate ? Object.assign({}, doc) : doc;
                return { latestBackendData: result, latestBackendVersion: docVersion, consistencyState: undefined };
            },
            entry => {
                if (this._debugPrint) {
                    maybeLog(
                        "### update existing",
                        JSON.stringify(rowIndex),
                        JSON.stringify(doc),
                        docVersion,
                        stringify(entry)
                    );
                }

                if (docVersion < entry.latestBackendVersion) {
                    result = {};
                    return entry;
                }

                const { consistencyState } = entry;
                if (consistencyState === undefined) {
                    // - If yes, but it doesn't have a consistency state, the full diff
                    //   is accepted.
                    result = diffDocumentData(entry.latestBackendData, doc, undefined, undefined);
                } else {
                    if (
                        consistencyState.confirmedVersion !== undefined &&
                        docVersion >= consistencyState.confirmedVersion
                    ) {
                        // - If yes, and the update is as new or newer than the entry's confirmed action
                        //   version, the update is fully accepted and the entry removed.  The blocked
                        //   columns are always included in the diff.
                        result = diffDocumentData(
                            entry.latestBackendData,
                            doc,
                            consistencyState.blockedColumns,
                            undefined
                        );
                        entry.consistencyState = undefined;
                    } else {
                        // - If yes, and the update is older than the entry's confirmed action version,
                        //   or it's not confirmed yet, only the non-blocked columns are accepted.
                        result = diffDocumentData(
                            entry.latestBackendData,
                            doc,
                            undefined,
                            consistencyState.blockedColumns
                        );
                        // FIXME: Only set this if we did actually block anything.  `diff` must return
                        // that.  This would be an optimization.
                        consistencyState.didBlock = true;
                    }
                }
                entry.latestBackendData = docWillMutate ? Object.assign({}, doc) : doc;
                entry.latestBackendVersion = docVersion;
                return entry;
            }
        );

        if (this._debugPrint) {
            maybeLog("### update result", JSON.stringify(result));
        }

        return defined(result);
    }

    // ## When an action is confirmed.  This is `public` because we call it in
    // tests.
    public confirmAction(
        tableName: TableName,
        rowIndex: RowIndex,
        jobID: string,
        confirmedAtSerial: number,
        mutationHandler: TableMutationHandler
    ): void {
        const entry = this.getEntryForRow(tableName, rowIndex);
        if (entry === undefined) return;
        const { consistencyState } = entry;

        // * We check whether it's the last action for any row
        if (consistencyState?.lastUpdatingAction !== jobID) {
            // - If it isn't, ignore it.
            return;
        }

        if (this._debugPrint) {
            maybeLog("### confirming action", jobID, JSON.stringify(rowIndex), confirmedAtSerial, stringify(entry));
        }

        if (!consistencyState.didBlock) {
            // - If it is, and the entry has no blocked column values, remove the entry.
            entry.consistencyState = undefined;
        } else {
            // - If it is, and the entry has blocked column values
            if (entry.latestBackendVersion < confirmedAtSerial) {
                // + If they are older than the confirmed action version, just set the
                //   confirmed action version in the entry.
                consistencyState.confirmedVersion = confirmedAtSerial;
            } else {
                // + If they are as new as, or newer than the confirmed action version,
                //   accept the column values and remove the entry.
                const update = getOnlyColumns(entry.latestBackendData, consistencyState.blockedColumns);
                if (this._debugPrint) {
                    maybeLog("### updating row with partial data", jobID, tableName, rowIndex, update);
                }
                mutationHandler.updateRowWithPartialData(tableName, rowIndex, update);
                entry.consistencyState = undefined;
            }
        }
    }

    public processSetColumnsInRow(
        tableName: TableName,
        rowIndex: RowIndex,
        jobID: string,
        touchedColumns: readonly string[]
    ): void {
        if (touchedColumns.length <= 0) return;

        this.newOrUpdateEntryForRow(
            tableName,
            rowIndex,
            () => {
                if (this._debugPrint) {
                    maybeLog(
                        "### set columns in unknown row",
                        jobID,
                        JSON.stringify(rowIndex),
                        JSON.stringify(touchedColumns)
                    );
                }

                return {
                    // If the entry doesn't exist yet, we need to make it.  This can happen
                    // with user-specific columns.
                    latestBackendData: {},
                    latestBackendVersion: Number.MIN_SAFE_INTEGER,
                    consistencyState: {
                        lastUpdatingAction: jobID,
                        confirmedVersion: undefined,
                        blockedColumns: new Set(touchedColumns),
                        didBlock: false,
                    },
                };
            },
            entry => {
                if (this._debugPrint) {
                    maybeLog(
                        "### set columns",
                        JSON.stringify(rowIndex),
                        JSON.stringify(touchedColumns),
                        stringify(entry)
                    );
                }

                // * If it touches user-agnostic columns, check if an entry for it already
                //   exists.
                const { consistencyState } = entry;
                if (consistencyState === undefined) {
                    // * If it doesn't, make one.
                    entry.consistencyState = {
                        lastUpdatingAction: jobID,
                        confirmedVersion: undefined,
                        blockedColumns: new Set(touchedColumns),
                        didBlock: false,
                    };
                } else {
                    // * It it does, update its last action and list of blocked columns.
                    consistencyState.lastUpdatingAction = jobID;
                    consistencyState.confirmedVersion = undefined;
                    setUnionInto(consistencyState.blockedColumns, touchedColumns);
                }

                return entry;
            }
        );
    }

    // Returns whether the entry existed
    public deleteRow(tableName: TableName, index: RowIndex, force: boolean): boolean {
        // FIXME: This is an ugly fix to the way we listen to user-specific documents.
        //
        // https://github.com/quicktype/glide/issues/7549
        if (!force && !this._deleteWithOutstandingActions) {
            const state = this.getEntryForRow(tableName, index);
            if (state?.consistencyState !== undefined) {
                return false;
            }
        }

        if (isBaseRowIndex(index)) {
            return this._rowStatesForIndex.get(tableName.name).delete(index);
        } else {
            const { keyColumnName, keyColumnValue, rowIndexHint } = index;
            if (rowIndexHint !== undefined && this.preferRowIndex) {
                return this._rowStatesForIndex.get(tableName.name).delete(rowIndexHint);
            } else {
                return this._rowStatesForHints.get(tableName.name).get(keyColumnName).delete(keyColumnValue);
            }
        }
    }

    public purgeConsistentEntries(): void {
        // We don't currently drill down into row states for hints,
        // because quotas aren't enforced there.
        for (const table of this._rowStatesForIndex.keys()) {
            const tableMap = this._rowStatesForIndex.get(table);
            for (const key of Array.from(tableMap.keys())) {
                const mapEntry = tableMap.get(key);
                if (mapEntry?.consistencyState === undefined) {
                    tableMap.delete(key);
                }
            }
        }
    }
}

interface Overrides {
    readonly actionPoster: ActionPoster;
    readonly actionManager: ActionManager;
}

export class NonblockingResilientDurableStorageController implements DurableStorageController {
    private readonly _actionManager: ActionManager | undefined;
    private readonly _userAgnosticHandler = new PartitionHandler(true);
    private readonly _userSpecificHandler = new PartitionHandler(false);
    private readonly _actionCompletionHandlers = new Map<string, () => void>();
    private _retired = false;

    constructor(
        private readonly _accessors: DataStoreEnvironmentAccessors,
        private readonly appFacilities: ActionAppFacilities,
        deviceIDOverride: string | undefined,
        isBuilder: boolean,
        writeSource: WriteSourceType,
        isPersistent: boolean,
        overrides?: Partial<Overrides>
    ) {
        this._actionManager = overrides?.actionManager;
        if (isPersistent && this._actionManager === undefined) {
            const actionPoster = overrides?.actionPoster ?? new SimpleActionPoster(_accessors);
            this._actionManager = new ActionManager(
                _accessors,
                deviceIDOverride,
                debounceSetColumnsTimeout,
                debounceDataEditorSetColumnsTimeout,
                isBuilder,
                writeSource,
                "",
                actionPoster,
                (...args) => this.confirmAction(...args),
                () => undefined,
                () => undefined
            );
        }
    }

    public getActionOutstandingOperations(): ActionOperationsState {
        if (this._actionManager !== undefined) {
            return this._actionManager.getActionOutstandingOperations();
        }
        return emptyActionOperationsState;
    }

    public subscribeToOutstandingOperations(handler: ActionOutstandingOperationsHandler): void {
        this._actionManager?.subscribeToOutstandingOperations(handler);
    }

    public unsubscribeFromOutstandingOperations(handler: ActionOutstandingOperationsHandler): void {
        this._actionManager?.unsubscribeFromOutstandingOperations(handler);
    }

    public handleUserAgnosticDocumentUpdate(
        tableName: TableName,
        rowIndex: RowIndex,
        rowVersion: number,
        doc: DocumentData,
        docWillMutate: boolean
    ): DocumentData {
        return this._userAgnosticHandler.handleDocumentUpdate(tableName, rowIndex, doc, rowVersion, docWillMutate);
    }

    public handleUserSpecificDocumentUpdate(
        tableName: TableName,
        rowIndex: RowIndex,
        rowVersion: number,
        doc: DocumentData
    ): DocumentData {
        return this._userSpecificHandler.handleDocumentUpdate(tableName, rowIndex, doc, rowVersion, false);
    }

    public handleDocumentDeletion(tableName: TableName, rowIndex: RowIndex, _version: number): void {
        this._userAgnosticHandler.deleteRow(tableName, rowIndex, true);
        this._userSpecificHandler.deleteRow(tableName, rowIndex, true);
    }

    public handleUserSpecificDataDeletion(tableName: TableName, rowIndex: RowIndex): DocumentData | undefined {
        const didDelete = this._userSpecificHandler.deleteRow(tableName, rowIndex, false);
        if (!didDelete) return undefined;

        return objectWithUndefinedProperties(this._accessors.userSpecificColumnsForTable(tableName));
    }

    public resetUserSpecificData(tableName: TableName): void {
        this._userSpecificHandler.resetTable(tableName);
    }

    // ## When an action is confirmed
    protected async confirmAction(
        tableName: TableName,
        rowIndex: RowIndex,
        jobID: string,
        confirmedAtSerial: number
    ): Promise<void> {
        if (this._retired) return;

        const mutationHandler = await this._accessors.tableMutationHandler();

        this._userAgnosticHandler.confirmAction(tableName, rowIndex, jobID, confirmedAtSerial, mutationHandler);
        this._userSpecificHandler.confirmAction(tableName, rowIndex, jobID, confirmedAtSerial, mutationHandler);

        const onComplete = this._actionCompletionHandlers.get(jobID);
        if (onComplete !== undefined) {
            this._actionCompletionHandlers.delete(jobID);
            onComplete();
        }
    }

    public async addRowToTable(
        tableName: TableName,
        rowIndex: RowIndex | undefined,
        values: Record<string, WritableValue>,
        metadata: DataStoreMutationMetadata,
        onError: ((e: Error) => void) | undefined
    ): Promise<MutationResult | boolean> {
        if (this._actionManager === undefined) return true;

        return await this._actionManager.addRowToTable(
            tableName,
            rowIndex,
            values,
            undefined,
            metadata,
            onError,
            false
        );
    }

    // ## When an action is posted
    public async setColumnsInRow(
        tableName: TableName,
        rowIndex: RowIndex,
        values: Record<string, WritableValue>,
        withDebounce: boolean,
        metadata: DataStoreMutationMetadata,
        onError: ((e: Error) => void) | undefined,
        onCompletion: (() => void) | undefined,
        existingJobID: string | undefined
    ): Promise<MutationResult> {
        // This should have already been covered by the constructor, but if there's
        // a transient exception that got thrown we might be able to recover.
        this._actionManager?.processSetColumnsInRowResiliencyQueueWhenOnline();

        const jobID = existingJobID ?? this.appFacilities.makeRowID();

        const userAgnosticColumns = this._accessors
            .userAgnosticColumnsForTable(tableName)
            .filter(c => values[c] !== undefined);
        const userSpecificColumns = this._accessors
            .userSpecificColumnsForTable(tableName)
            .filter(c => values[c] !== undefined);

        if (getFeatureFlag("logStorageController")) {
            maybeLog(
                "### post set columns",
                rowIndex,
                values,
                JSON.stringify(userAgnosticColumns),
                JSON.stringify(userSpecificColumns)
            );
        }

        // We're being extra paranoid here, to make sure we're really
        // sending the action.
        try {
            this._userAgnosticHandler.processSetColumnsInRow(tableName, rowIndex, jobID, userAgnosticColumns);
            this._userSpecificHandler.processSetColumnsInRow(tableName, rowIndex, jobID, userSpecificColumns);
        } catch (e: unknown) {
            logError("Error processing set columns", e);
        }

        if (this._actionManager === undefined) return { jobID: undefined, confirmedAtVersion: false };

        if (onCompletion !== undefined) {
            this._actionCompletionHandlers.set(jobID, onCompletion);
        }

        return await this._actionManager.setColumnsInRow(
            tableName,
            rowIndex,
            values,
            existingJobID !== undefined,
            jobID,
            withDebounce && existingJobID === undefined,
            metadata,
            e => {
                this._actionCompletionHandlers.delete(jobID);
                onError?.(e);
            },
            false
        );
    }

    public async deleteRowAtIndex(
        tableName: TableName,
        rowIndex: RowIndex,
        metadata: DataStoreMutationMetadata,
        onError: ((e: Error) => void) | undefined,
        onConfirm: ((serial: number) => void) | undefined
    ): Promise<MutationResult> {
        if (this._actionManager === undefined) return { jobID: undefined, confirmedAtVersion: false };

        return await this._actionManager.deleteRowAtIndex(tableName, rowIndex, metadata, onError, onConfirm, false);
    }

    public retire(): void {
        this._retired = true;
        this._actionManager?.retire();
    }

    public purgeConfirmedEntries(): void {
        // Only needed for the user-agnostic entries;
        // that's where the quota applies.
        this._userAgnosticHandler.purgeConsistentEntries();
    }

    public appEnvironmentUpdated(): void {
        this._actionManager?.updateOfflineQueue();
        this._actionManager?.resetReloadResiliencyQueue();
    }

    public appUserChanged(): void {
        this._actionManager?.updateOfflineQueue();
        this._actionManager?.resetReloadResiliencyQueue();
    }
}
