import * as React from "react";
import { isDefined } from "@glide/support";

// Default state represents the original SVG heights
const defaultBars = {
    bar1: { start: 9.75, end: 14.25 }, // 3.75
    bar2: { start: 3.75, end: 20.25 }, // 7.75
    bar3: { start: 7.75, end: 16.25 }, // 12
    bar4: { start: 5.75, end: 18.25 }, // 16.25
    bar5: { start: 9.75, end: 14.25 }, // 20.25
};

interface InlineVoiceVisualizerProps {
    readonly isRecording: boolean;
    readonly getAudioData: () => Float32Array;
}

export const InlineVoiceVisualizer: React.FC<InlineVoiceVisualizerProps> = p => {
    const { isRecording, getAudioData } = p;

    const pathRef = React.useRef<SVGPathElement>(null);
    const animationFrameRef = React.useRef<number>();
    const defaultPositions = React.useRef([
        defaultBars.bar2.start,
        defaultBars.bar1.start,
        defaultBars.bar3.start,
        defaultBars.bar4.start,
        defaultBars.bar5.start,
    ]);

    React.useEffect(() => {
        if (!isRecording) {
            // Return to default state when not recording
            if (isDefined(pathRef.current)) {
                const d = defaultPositions.current
                    .map((start, i) => {
                        const barIndex =
                            i === 0 ? "bar2" : i === 1 ? "bar1" : i === 2 ? "bar3" : i === 3 ? "bar4" : "bar5";
                        return `M${getBarX(i)} ${start}V${defaultBars[barIndex].end}`;
                    })
                    .join("");
                pathRef.current.setAttribute("d", d);
            }
            return;
        }

        const updateBars = () => {
            if (!pathRef.current) return;

            const audioData = getAudioData();

            // Map audio data to 5 bars
            const binSize = Math.floor(audioData.length / 5);
            const newBars = Array.from({ length: 5 }, (_, i) => {
                let sum = 0;
                for (let j = 0; j < binSize; j++) {
                    const value = Math.abs(audioData[i * binSize + j]);
                    sum += value;
                }
                const average = sum / binSize;

                // Get the default start and end positions for this bar
                const barIndex = i === 0 ? "bar2" : i === 1 ? "bar1" : i === 2 ? "bar3" : i === 3 ? "bar4" : "bar5";
                const defaultStart = defaultBars[barIndex].start;
                const defaultEnd = defaultBars[barIndex].end;
                const defaultHeight = defaultEnd - defaultStart;
                const defaultCenter = defaultStart + defaultHeight / 2;

                // Calculate new height based on audio intensity
                const intensity = Math.min(average * 3, 1);
                const maxExtension = 8; // Maximum pixels to extend beyond default
                const extension = intensity * maxExtension;

                // Calculate new start position based on the default center
                return Math.max(defaultCenter - (defaultHeight / 2 + extension), 0);
            });

            const d = newBars
                .map((start, i) => {
                    const barIndex = i === 0 ? "bar2" : i === 1 ? "bar1" : i === 2 ? "bar3" : i === 3 ? "bar4" : "bar5";
                    const defaultStart = defaultBars[barIndex].start;
                    const defaultEnd = defaultBars[barIndex].end;
                    const defaultHeight = defaultEnd - defaultStart;
                    const defaultCenter = defaultStart + defaultHeight / 2;
                    const currentTopHeight = defaultCenter - start;
                    const end = defaultCenter + currentTopHeight;
                    return `M${getBarX(i)} ${start}V${end}`;
                })
                .join("");

            pathRef.current.setAttribute("d", d);
            animationFrameRef.current = requestAnimationFrame(updateBars);
        };

        updateBars();

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [getAudioData, isRecording]);

    // Helper to get X coordinate for each bar
    const getBarX = (index: number) => {
        switch (index) {
            case 0:
                return "7.75";
            case 1:
                return "3.75";
            case 2:
                return "12";
            case 3:
                return "16.25";
            case 4:
                return "20.25";
            default:
                return "0";
        }
    };

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            tw="transition-all duration-200"
            xmlns="http://www.w3.org/2000/svg">
            <path ref={pathRef} stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
};
