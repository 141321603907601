import { AppIcon, GlideIcon, copyToClipboard as copy } from "@glide/common";
import * as browser from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import type { IconImage } from "@glide/app-description";
import { Icon } from "@glide/common-core/dist/js/render/icon";
import type { AppKind } from "@glide/location-common";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import type { WireAppTheme } from "@glide/theme";
import { useState } from "react";
import { css } from "styled-components";
import tw from "twin.macro";

interface Props extends React.PropsWithChildren {
    readonly isOpen: boolean;
    readonly closeDialog: () => void;
    readonly iconImage: IconImage | undefined;
    readonly appTitle: string;
    readonly appKind: AppKind;
    readonly theme?: WireAppTheme;
    readonly appID: string;
}

interface PopoverProps {
    readonly closeDialog: () => void;
    readonly iconImage: IconImage | undefined;
    readonly theme: WireAppTheme;
    readonly appTitle: string;
    readonly appID: string;
}
interface SafariPopoverProps extends PopoverProps {
    appKind: AppKind;
}

interface ChromePopoverProps extends PopoverProps {}

const popoverContainerStyles = tw`absolute inset-0 justify-center w-full h-full pointer-events-none animate-fade-in`;
const popoverStyles = tw`absolute bottom-0 pointer-events-auto flex flex-col items-center justify-center w-full [padding:0px 30px] z-add-to-homescreen`;

const SafariPopover: React.VFC<SafariPopoverProps> = p => {
    const { closeDialog, iconImage, theme, appTitle, appKind, appID } = p;

    return (
        <div css={[popoverContainerStyles]}>
            <div
                css={[popoverStyles]}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <div tw="text-center rounded-xl bg-bg-front w-full justify-center shadow-2xl">
                    <div tw="absolute right-0.5 mr-11 mt-5 z-10 active:opacity-60" onClick={closeDialog}>
                        <GlideIcon kind="stroke" icon="st-close" strokeColor={theme.fgColorDark} />
                    </div>
                    {iconImage && (
                        <div tw="w-full justify-center mt-5 mb-4">
                            <Icon
                                size={52}
                                rounding={12}
                                fullscreen={true}
                                omitShadow={true}
                                color={theme.primaryAccentColor}
                                image={iconImage}
                                clipCorners={true}
                                appID={appID}
                            />
                        </div>
                    )}
                    <header>
                        <h1 tw="font-bold text-base mb-1.5 text-n900">Install {appTitle}</h1>
                    </header>
                    <div tw="text-sm">
                        <div tw="ml-4 mr-4 mb-5 text-text-pale">
                            {getLocalizedString("addThisAppToYourHomeScreen", appKind)}
                        </div>
                        <div tw="border-t border-n200" />
                        <div tw="flex flex-row justify-center items-center m-4 mt-2.5">
                            <div tw="text-sm text-n900">{getLocalizedString("tapThenAddPrefix", appKind)}</div>
                            <div tw="p-0.5">
                                <GlideIcon kind="stroke" icon="st-ios-share" iconSize={32} tw="[color:#3478F6]" />
                            </div>
                            <div tw="text-n900">{getLocalizedString("tapThenAddSuffix", appKind)}</div>
                        </div>
                    </div>
                </div>
                <div
                    css={css`
                        ${tw`[border-width:18px 18px 0 18px] [border-color:var(--gv-bg-front) transparent transparent
                          transparent] z-add-to-homescreen`}
                    `}
                ></div>
            </div>
        </div>
    );
};

const ChromePopover: React.VFC<ChromePopoverProps> = p => {
    const { closeDialog, iconImage, theme, appTitle, appID } = p;
    const [linkCopied, setLinkCopied] = useState(false);
    const stroke = theme.n700A;

    return (
        <div css={[popoverContainerStyles]}>
            <div
                css={[popoverStyles]}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <div tw="text-center rounded-xl bg-bg-front w-full justify-center shadow-2xl">
                    <div tw="absolute right-0.5 mr-11 mt-5 z-10 active:opacity-60" onClick={closeDialog}>
                        <GlideIcon kind="stroke" icon="st-close" strokeColor={theme.fgColorDark} />
                    </div>
                    {iconImage && (
                        <div tw="w-full justify-center mt-5 mb-4">
                            <Icon
                                size={56}
                                rounding={12}
                                fullscreen={true}
                                omitShadow={true}
                                color={theme.primaryAccentColor}
                                image={iconImage}
                                clipCorners={true}
                                appID={appID}
                            />
                        </div>
                    )}

                    <header>
                        <h1 tw="font-bold text-base mb-1.5 text-n900">Install {appTitle}</h1>
                    </header>
                    <div tw="text-sm ml-4 mr-4">
                        <div tw="text-text-pale mr-6 ml-6 mb-3.5">
                            <div>To add this app to your Home Screen,</div>
                            <div tw="flex gap-x-1 justify-center">
                                <div tw="float-left">open this link in Safari</div>
                                <AppIcon icon="safari" size={20} />
                            </div>
                        </div>
                    </div>
                    <div tw="pl-7 pr-7">
                        <div
                            tw="w-full pt-2.5 pb-2.5 mb-6 items-center flex rounded-lg bg-n300A active:bg-n400A"
                            onClick={() => {
                                const domain = document.location.origin;
                                copy(domain);
                                setLinkCopied(true);
                            }}
                        >
                            <div tw="ml-2.5">
                                <GlideIcon kind="stroke" icon="st-duplicate" strokeColor={stroke} />
                            </div>
                            <div tw="justify-center flex-auto mr-8 text-sm font-semibold text-n900">
                                Copy link to app
                            </div>
                        </div>
                    </div>
                </div>
                {linkCopied && (
                    <div
                        tw="mt-4 pt-1.5 pb-1.5 pl-3 pr-3 bg-bg-front text-n900 rounded-3xl text-sm opacity-0
                            animate-fade-in-out"
                    >
                        Link Copied!
                    </div>
                )}
            </div>
        </div>
    );
};

export const AddToHomescreen: React.VFC<Props> = p => {
    const wireAppTheme = useWireAppTheme();
    const { iconImage, appTitle, isOpen, closeDialog, theme, appKind, appID } = p;

    if (!isOpen) {
        return null;
    }

    // We use Android built in prompt
    if (browser.browserIsAndroidChrome) return null;
    // If the browser being opened is Safari on an iOS device, display the safari popover
    if (browser.browserIsSafari && !browser.isChrome) {
        return (
            <SafariPopover
                appTitle={appTitle}
                closeDialog={closeDialog}
                iconImage={iconImage}
                theme={theme ?? wireAppTheme}
                appKind={appKind}
                appID={appID}
            />
        );
    }

    // If the browser being opened is Chrome on an iOS device, display the chrome popover
    if (browser.isChrome) {
        return (
            <ChromePopover
                closeDialog={closeDialog}
                iconImage={iconImage}
                theme={theme ?? wireAppTheme}
                appTitle={appTitle}
                appID={appID}
            />
        );
    }

    return null;
};
