import { appIsInstalled, browserCertainlyIsOniOS, useLocalStorage, isInIframe } from "@glide/common";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import * as React from "react";

interface AddToHomescreen {
    showingAddToHomescreenDialog: boolean;
    showAddToHomescreenDialog: () => void;
    closeAddToHomescreenDialog: () => void;
    showInstallApp: boolean;
}

function makeDismissedAddToHomescreen(appID: string): string {
    return `dismissed-add-to-homescreen-${appID}`;
}

export function useAddToHomescreen(): AddToHomescreen {
    const appID = useAppID();
    const showInstallApp = browserCertainlyIsOniOS && !appIsInstalled() && !isInIframe();
    const [dismissed, setDismiss] = useLocalStorage(makeDismissedAddToHomescreen(appID ?? ""), !showInstallApp);
    const show = React.useCallback(() => setDismiss(false), [setDismiss]);
    const close = React.useCallback(() => setDismiss(true), [setDismiss]);

    return {
        showingAddToHomescreenDialog: !dismissed,
        showAddToHomescreenDialog: show,
        closeAddToHomescreenDialog: close,
        showInstallApp,
    };
}
