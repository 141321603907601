import React from "react";

import { extractActions } from "../../wire-lib";
import type { WireHeroExtraProps } from "./wire-hero-lib";
import { Title } from "./wire-hero-lib";

export const WireHero: React.VFC<WireHeroExtraProps> = React.memo(p => {
    const {
        image,
        subtitle,
        title,
        titleEmphasized,
        buttons,
        backend,
        imageSize,
        titleStyle,
        customCssClassName,
        isInMultipleColumnLayout,
        headerImage,
        isFirstComponent,
        imageFill,
    } = p;

    const actions = extractActions(buttons, backend);
    return (
        <Title
            actions={actions}
            imageSize={imageSize}
            titleStyle={titleStyle}
            subtitle={subtitle}
            image={image}
            title={title}
            titleEmphasized={titleEmphasized}
            isInMultipleColumnLayout={isInMultipleColumnLayout}
            customCssClassName={customCssClassName}
            headerImage={headerImage}
            isFirstComponent={isFirstComponent}
            imageFill={imageFill}
            appID={backend.appID}
        />
    );
});
