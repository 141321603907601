import deepEqual from "deep-equal";
import * as React from "react";

export function useDeepEqual<T>(value: T): T {
    const [state, setState] = React.useState<T>(value);

    const resolved = React.useMemo(() => {
        if (!deepEqual(state, value)) {
            return value;
        }
        return state;
    }, [value, state]);

    React.useEffect(() => {
        setState(resolved);
    }, [resolved]);

    return resolved;
}
