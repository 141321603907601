import { AppIcon } from "@glide/common";
import classNames from "classnames";
import styled, { css } from "styled-components";

import * as React from "react";
import BaseModalV2 from "../base-modal/base-modal";
import { Button } from "../button/button";
import type { ButtonSizes, ButtonType, ButtonVariants } from "../button/button-lib";

export interface WindowedModalV2Props extends React.PropsWithChildren {
    readonly canClose: boolean;
    readonly onClose: () => void;

    readonly canScroll?: boolean;
    readonly hideClosingButton?: boolean;
    readonly className?: string;
    readonly padding?: number;
    readonly title?: React.ReactNode;
    readonly titleAccessory?: React.ReactNode;
    readonly footer?: React.ReactNode;
    readonly floatingFooter?: React.ReactNode;
    readonly footerDivider?: boolean;
    readonly footerTopMargin?: number;
    readonly footerTopPadding?: number;
    readonly footerBottomPadding?: number;
    readonly bigHeaderSize?: boolean;
    readonly barsPadding?: number;
    readonly titleBarBottomPadding?: number;
    readonly titleBarTopPadding?: number;
    readonly maxWidth?: string;
    readonly titleHeight?: number;
    readonly zIndex?: number;
    readonly noBackDrop?: boolean;
    readonly noPortal?: boolean;
    readonly smallContentText?: boolean;
}

const WindowedModalV2Style = styled.div<{
    padding: number;
    footerTopMargin: number;
    footerDivider: boolean;
    bigHeaderSize: boolean;
    barsPadding: number;
    titleBarTopPadding: number;
    footerTopPadding?: number;
    footerBottomPadding?: number;
    titleBarBottomPadding: number;
    maxWidth?: string;
    titleHeight?: number;
}>`
    border-radius: 12px;
    background-color: ${p => p.theme.bgColorLight};
    box-shadow: ${p => p.theme.shadow4};
    display: flex;
    flex-direction: column;
    color: ${p => p.theme.fgColorDark};
    overflow: hidden;
    position: relative;
    max-width: ${p => p.maxWidth ?? "none"};
    margin: ${p => (p.maxWidth ? "0 auto" : "auto")};
    /* TEST: Need to verify this with existing modals */
    .close-button {
        height: 40px;
        width: 40px;
        margin: 16px 20px;
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 8px;
        cursor: pointer;
        z-index: 1000;
        color: ${p => p.theme.n700};
        transition: color ${p => p.theme.transitionBase};

        &:hover {
            color: ${p => p.theme.fgColorDark};
        }
    }
    .title-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${p => p.titleHeight ?? "auto"};
        padding: ${p => p.titleBarTopPadding ?? p.barsPadding}px ${p => p.barsPadding}px
            ${p => p.titleBarBottomPadding}px;
        .spacer {
            flex-grow: 0;
            flex-shrink: 10000000;
        }
        .title-accessory {
            &.pad-right {
                margin-right: 40px;
            }
        }
        .title-container {
            color: ${p => p.theme.fgColorDarkest};
            flex-grow: 1;
            font-size: ${p => (p.bigHeaderSize ? 26 : 15)}px;
            font-weight: 600;
            line-height: 1.2;
        }
    }
    .content-area {
        padding: 0 ${p => p.padding}px;
        flex-grow: 1;
        white-space: pre-wrap;
    }
    .content-area.small-text {
        font-size: 14px;
        line-height: 1.33;
    }
    .footer {
        margin: ${p => p.footerTopMargin}px ${p => p.barsPadding}px 0px;
        padding: ${p => p.footerTopPadding ?? 26}px 0px ${p => p.barsPadding ?? p.footerBottomPadding ?? 26}px;
        ${p =>
            p.footerDivider &&
            css`
                border-top: 1px solid ${p.theme.borderPale};
            `}
    }

    .transparent & {
        background-color: unset;
    }
`;

const ExtraWindowedModalV2Style = styled.div`
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    color: ${p => p.theme.fgColorDark};
    overflow: hidden;
    z-index: 100;
    margin-top: 20px;
`;

export interface WindowedModalButton {
    title: string;
    variant: ButtonVariants;
    type: ButtonType;
    fullSize?: boolean;
    minWidth?: number;
    isProcessing?: boolean;
    onClick: () => void;
    disabled?: boolean;
    href?: string;
    autofocus?: boolean;
    size?: ButtonSizes;
}

interface Props extends React.PropsWithChildren {
    readonly className?: string;
    readonly align: "left" | "right" | "split" | "stretch";
    readonly buttons: readonly WindowedModalButton[];
    readonly size?: ButtonSizes;
}

const ButtonBarStyle = styled.div`
    display: flex;
    > :not(:last-child) {
        margin-right: 12px;
    }
    &.right {
        justify-content: flex-end;
    }
    &.split {
        justify-content: space-between;
    }

    &.stretch {
        justify-content: stretch;

        > * {
            flex-grow: 1;
        }
    }
`;
function toKebabCase(label: string) {
    const kebabLabel = label
        .toLowerCase() // Convert to lowercase
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/_/g, "-") // Replace underscores with hyphens
        .replace(/[^a-z0-9-]+/g, "") // Remove non-alphanumeric characters except hyphens
        .replace(/--+/g, "-") // Replace multiple hyphens with a single hyphen
        .replace(/^-+|-+$/g, ""); // Remove leading or trailing hyphens
    return kebabLabel;
}

export const WindowedModalButtonBar: React.FC<React.PropsWithChildren<Props>> = p => {
    const autofocusRef = React.useRef<HTMLElement>(null);

    React.useEffect(() => {
        autofocusRef.current?.focus();
    }, []);

    return (
        <ButtonBarStyle className={classNames(p.className, p.align)}>
            {p.buttons.map((b, idx) => (
                <Button
                    className={`btn-${toKebabCase(b.title)}`}
                    buttonRef={b.autofocus ? autofocusRef : undefined}
                    onClick={b.onClick}
                    isProcessing={b.isProcessing}
                    size={p.size ?? b.size ?? "md"}
                    key={`${b.title}-${idx}`}
                    variant={b.variant}
                    buttonType={b.type}
                    isFullWidth={b.fullSize}
                    minWidth={b.minWidth}
                    disabled={b.disabled}
                    label={b.title}
                    href={b.href}
                />
            ))}
        </ButtonBarStyle>
    );
};

/**
 * Windowed Modal V2 is Deprecated.
 * @deprecated Use the Modal.Container + Modal.Window set of components.
 * @link https://app.swimm.io/workspaces/JREOQmtn4IL52t6oYwFB/repos/Z2l0aHViJTNBJTNBZ2xpZGUlM0ElM0FxdWlja3R5cGU=/branch/dev/docs/h3q3ya6e
 */
const WindowedModalV2: React.FC<React.PropsWithChildren<React.PropsWithChildren<WindowedModalV2Props>>> = p => {
    const {
        hideClosingButton,
        canClose,
        onClose,
        title,
        titleAccessory,
        footer,
        floatingFooter,
        padding,
        className,
        footerDivider,
        footerTopMargin,
        bigHeaderSize,
        barsPadding,
        titleBarBottomPadding,
        footerTopPadding,
        footerBottomPadding,
        titleHeight,
        maxWidth,
        zIndex,
        canScroll,
        titleBarTopPadding = p.barsPadding ?? 32,
        noBackDrop,
        noPortal,
        smallContentText = false,
    } = p;

    const hideTitle = title === undefined && titleAccessory === undefined;
    const paddingOrDefault = padding ?? 32;
    const footerDividerOrDefault = footerDivider ?? true;
    const footerTopMarginOrDefault = footerTopMargin ?? 26;
    const footerBottomPaddingOrDefault = footerBottomPadding ?? 0;
    const bigHeaderSizeOrDefault = bigHeaderSize ?? false;
    const barsPaddingOrDefault = barsPadding ?? 32;
    const titleBarBottomPaddingOrDefault = titleBarBottomPadding ?? 26;
    const titleAccessoryClass = canClose ? "title-accessory pad-right" : "title-accessory";

    return (
        <BaseModalV2
            className={className}
            canClickOff={canClose}
            onClickOff={onClose}
            canScroll={canScroll}
            noBackDrop={noBackDrop}
            zIndex={zIndex}
            noPortal={noPortal}>
            <WindowedModalV2Style
                titleHeight={titleHeight}
                padding={paddingOrDefault}
                footerDivider={footerDividerOrDefault}
                footerTopMargin={footerTopMarginOrDefault}
                bigHeaderSize={bigHeaderSizeOrDefault}
                titleBarTopPadding={titleBarTopPadding}
                barsPadding={barsPaddingOrDefault}
                footerTopPadding={footerTopPadding}
                titleBarBottomPadding={titleBarBottomPaddingOrDefault}
                footerBottomPadding={footerBottomPaddingOrDefault}
                maxWidth={maxWidth}>
                {!hideTitle && (
                    <div className="title-bar">
                        <div className="title-container">{title}</div>
                        <div className={titleAccessoryClass}>{titleAccessory}</div>
                        <div className="spacer" />
                    </div>
                )}
                {canClose && !hideClosingButton && (
                    <button className="close-button" onClick={onClose}>
                        <AppIcon icon="close" size={24} />
                    </button>
                )}
                <div className={classNames("content-area", smallContentText && "small-text")}>{p.children}</div>
                {footer && <div className="footer">{footer}</div>}
            </WindowedModalV2Style>
            {floatingFooter && (
                <ExtraWindowedModalV2Style>
                    <div className="floatingFooter">{floatingFooter}</div>
                </ExtraWindowedModalV2Style>
            )}
        </BaseModalV2>
    );
};

export default WindowedModalV2;
