import { assert } from "@glideapps/ts-necessities";
import { formatNumber as defaultFormatNumber } from "@glide/support";
import { asString } from "@glide/computation-model-types";

// This matches all strings that contain at least one digit and and most one
// minus sign and at most one decimal point, with the minus sign coming before
// the decimal point.
//
//   (?=.*\d)  ensures at least one digit is present
//   [^0-9.-]* everything before the minus sign
//   -?        the optional minus sign
//   [^.-]*    everything before the decimal point
//   \.?       the optional decimal point
//   [^.-]*    everything after the decimal point
const numberRegex = /^(?=.*\d)[^0-9.-]*-?[^.-]*\.?[^.-]*$/;

export function asMaybeNumber(v: unknown): number | undefined {
    if (v === undefined) return undefined;
    if (typeof v === "number") return v;
    if (typeof v === "boolean") return v ? 1 : 0;
    if (typeof v === "string") {
        // This is the same method we use in the Postgres function `as_number`
        // Don't change this without updating that too!
        if (!numberRegex.test(v)) return undefined;
        // Remove all characters except digits, decimal points, and the minus
        // sign.
        const cleaned = v.replace(/[^0-9.-]/g, "");
        const n = parseFloat(cleaned);
        assert(!isNaN(n));
        return n;
    }
    return undefined;
}

export function asNumber(v: unknown): number {
    return asMaybeNumber(v) ?? 0;
}

const strictTrueStrings = ["true", "yes", "y", "1"];
const fuzzyTrueStrings = [...strictTrueStrings, "x"];
const strictFalseStrings = ["false", "no", "n", "0"];
const fuzzyFalseStrings = [...strictFalseStrings, "-"];

// ##asMaybeBoolean:
// This is how the computation model interprets values as booleans.
export function asMaybeBoolean(v: unknown): boolean | undefined {
    if (typeof v === "boolean") return v;
    if (typeof v === "number") return v !== 0;
    if (typeof v === "string") {
        const str = v.trim().toLowerCase();
        if (fuzzyTrueStrings.includes(str)) return true;
        if (fuzzyFalseStrings.includes(str)) return false;
    }
    return undefined;
}

// This will only convert if `v` is clearly meant to be a boolean
export function asMaybeBooleanStrict(v: unknown): boolean | undefined {
    if (typeof v === "boolean") return v;
    if (v === 0 || v === 1) return v !== 0;
    if (typeof v === "string") {
        const str = v.trim().toLowerCase();
        if (strictTrueStrings.includes(str)) return true;
        if (strictFalseStrings.includes(str)) return false;
    }
    return undefined;
}

export function asBoolean(v: unknown): boolean {
    return asMaybeBoolean(v) === true;
}

export function asEnum<T>(v: unknown, formatNumber: (x: number) => string = defaultFormatNumber): T {
    return asString(v, formatNumber) as unknown as T;
}
