// Eventual goal here will be to refactor most, or import all of
// app/src/lib/file-import.ts and app/src/designer/builder/ui/smart/data-source-panel/file-import-modal.tsx
// we cannot do this easily yet because of common core deps and no composable context / hooks around this yet
// for now -
// we'll leave business logic there and see if we can at least factor out some UI into designer-components

const acceptedImportSuffixes = [".xlsx", ".ods", ".csv", ".xls", ".xlsm"];

export function filenameIsAcceptableImportViaSuffix(filename: string): boolean {
    for (const suffix of acceptedImportSuffixes) {
        if (filename.endsWith(suffix)) return true;
    }
    return false;
}
export const acceptedImportMIMETypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.oasis.opendocument.spreadsheet",
    "text/csv",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/vnd.ms-excel",
];

export const acceptedImportFileTypes = [...acceptedImportMIMETypes, ...acceptedImportSuffixes].join(", ");

export const defaultMaxFileSize = 5 * 1024 * 1024; // 5 MB
