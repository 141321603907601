import * as t from "io-ts";

// For work
const eventPlanning = "event planning";
const humanResources = "human resources";
const inventoryLogistics = "inventory & logistics";
const projectManagement = "project management";
const restaurantOperations = "restaurant operations";
const realEstateManagement = "real estate management";
const sales = "sales";

// New for work
const fieldOperations = "field operations";
const clientPortal = "client portal";
const adminDashboard = "admin dashboard";
const companyDirectory = "company directory";

// other
const other = "other";
const education = "education";
const personalUse = "personal use";

const workUseCases = t.union([
    t.literal(eventPlanning),
    t.literal(humanResources),
    t.literal(inventoryLogistics),
    t.literal(projectManagement),
    t.literal(restaurantOperations),
    t.literal(realEstateManagement),
    t.literal(sales),
    // t.literal(fieldOperations), // TODO we need to add these before merge and make templates for these
    // t.literal(clientPortal),
    // t.literal(adminDashboard),
    // t.literal(companyDirectory),
]);

const suggestedTemplatesUseCases = t.union([
    workUseCases,
    t.literal(other),
    t.literal(education),
    t.literal(personalUse),
]);
export type SuggestedTemplatesUseCases = t.TypeOf<typeof suggestedTemplatesUseCases>;

export const onboardingUseCases = t.union([
    t.literal(fieldOperations),
    t.literal(inventoryLogistics),
    t.literal(clientPortal),
    t.literal(eventPlanning),
    t.literal(adminDashboard),
    t.literal(companyDirectory),
]);

export type OnboardingUseCases = t.TypeOf<typeof onboardingUseCases>;
