import { assertNever, defined, hasOwnProperty, panic } from "@glideapps/ts-necessities";
import {
    type BinaryPredicateOperator,
    type UnaryPredicateOperator,
    UnaryPredicateCompositeOperator,
} from "@glide/app-description";
import {
    type SourceColumn,
    BinaryPredicateCompositeOperator,
    BinaryPredicateFormulaOperator,
    isSourceColumn,
    UnaryPredicateFormulaOperator,
} from "@glide/type-schema";
import { isMatchEmailOperator } from "@glide/common-core/dist/js/description";
import type { SpecialValueSpecification } from "@glide/formula-specifications";
import { type PredicateSpecification, BinaryPredicateValueKind } from "@glide/formula-specifications";
import type { BinaryConditionKind, Condition, PathOrGroundValue, UnaryConditionKind } from "./conditions";

// [kind, negate]
const unaryOperators: Partial<Record<UnaryPredicateOperator, [UnaryConditionKind, boolean]>> = {
    [UnaryPredicateCompositeOperator.IsEmpty]: ["is-empty", false],
    [UnaryPredicateFormulaOperator.IsNotEmpty]: ["is-empty", true],
    [UnaryPredicateCompositeOperator.IsFalsey]: ["is-truthy", true],
    [UnaryPredicateFormulaOperator.IsTruthy]: ["is-truthy", false],
};
const binaryOperators: Record<
    BinaryPredicateOperator,
    [condition: BinaryConditionKind, negated: boolean, startOrEndOfDuration: "start" | "end" | undefined]
> = {
    [BinaryPredicateFormulaOperator.Equals]: ["equals", false, undefined],
    [BinaryPredicateCompositeOperator.DoesNotEqual]: ["equals", true, undefined],
    [BinaryPredicateFormulaOperator.ContainsString]: ["contains-string", false, undefined],
    [BinaryPredicateCompositeOperator.DoesNotContainString]: ["contains-string", true, undefined],
    [BinaryPredicateFormulaOperator.IsContainedInString]: ["is-contained-in-string", false, undefined],
    [BinaryPredicateCompositeOperator.IsNotContainedInString]: ["is-contained-in-string", true, undefined],
    [BinaryPredicateFormulaOperator.IsLessThan]: ["is-less", false, undefined],
    [BinaryPredicateFormulaOperator.IsGreaterThan]: ["is-greater", false, undefined],
    [BinaryPredicateFormulaOperator.IsLessOrEqualTo]: ["is-less-or-equal", false, undefined],
    [BinaryPredicateFormulaOperator.IsGreaterOrEqualTo]: ["is-greater-or-equal", false, undefined],
    [BinaryPredicateFormulaOperator.MatchesEmailAddress]: ["matches-email-address", false, undefined],
    [BinaryPredicateCompositeOperator.IsBefore]: ["is-before", false, "start"],
    [BinaryPredicateCompositeOperator.IsAfter]: ["is-after", false, "end"],
    [BinaryPredicateCompositeOperator.IsOnOrBefore]: ["is-on-or-before", false, undefined],
    [BinaryPredicateCompositeOperator.IsOnOrAfter]: ["is-on-or-after", false, undefined],
    [BinaryPredicateCompositeOperator.IsWithin]: ["is-within", false, undefined],
    [BinaryPredicateFormulaOperator.ArrayIncludes]: ["array-includes", false, undefined],
    [BinaryPredicateCompositeOperator.ArrayDoesNotInclude]: ["array-includes", true, undefined],
};

export interface ConditionValuePreparer<T> {
    makePathForSourceColumn(sc: SourceColumn): T;
    getVerifiedEmailAddressPath(): T;
    getTimestampPath(): T;
    getStartOrEndOfTodayPath(startOrEnd: "start" | "end"): T;
    makePathForSpecialValue(kind: SpecialValueSpecification): T;
}

export function prepareCondition<T>(
    spec: PredicateSpecification,
    valuePreparer: ConditionValuePreparer<T>
): Condition<T> {
    const left = isSourceColumn(spec.column)
        ? valuePreparer.makePathForSourceColumn(spec.column)
        : valuePreparer.makePathForSpecialValue(spec.column);
    if (spec.kind === "unary") {
        if (isMatchEmailOperator(spec.operator)) {
            const negate = spec.operator === UnaryPredicateCompositeOperator.DoesNotMatchVerifiedEmailAddress;
            const emailPath = valuePreparer.getVerifiedEmailAddressPath();
            return {
                kind: "matches-email-address",
                negate,
                left: { isPath: true, path: left },
                right: { isPath: true, path: emailPath },
            };
        } else if (hasOwnProperty(unaryOperators, spec.operator)) {
            const [kind, negate] = defined(unaryOperators[spec.operator]);
            return { kind, negate, value: { isPath: true, path: left } };
        } else {
            return panic(`No such unary operator ${spec.operator}`);
        }
    } else if (spec.kind === "binary") {
        const [kind, negate, startOrEnd] = binaryOperators[spec.operator];
        let right: PathOrGroundValue<T>;
        if (isSourceColumn(spec.value)) {
            right = { isPath: true, path: valuePreparer.makePathForSourceColumn(spec.value) };
        } else if (spec.value.kind === BinaryPredicateValueKind.Literal) {
            right = { isPath: false, value: spec.value.value };
        } else if (spec.value.kind === BinaryPredicateValueKind.Now) {
            right = { isPath: true, path: valuePreparer.getTimestampPath() };
        } else if (spec.value.kind === BinaryPredicateValueKind.Today) {
            let path: T;
            if (startOrEnd === "start" || startOrEnd === "end") {
                path = valuePreparer.getStartOrEndOfTodayPath(startOrEnd);
            } else if (startOrEnd === undefined) {
                path = valuePreparer.getTimestampPath();
            } else {
                return assertNever(startOrEnd);
            }
            right = { isPath: true, path };
        } else {
            return assertNever(spec.value.kind);
        }
        return {
            kind,
            negate,
            left: { isPath: true, path: left },
            right,
        };
    } else {
        return assertNever(spec);
    }
}
