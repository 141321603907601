import { styled } from "@glide/common";
import { trackEvent } from "@glide/common-core/dist/js/analytics";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import WindowedModalV2 from "../windowed-modal/windowed-modal";
import { GlideDropdown } from "../glide-dropdown/glide-dropdown";
import { HighlightTextArea } from "../highlight-textarea/highlight-textarea";
import { Button } from "../button/button";
import { VerticallyExpandableContainer } from "../vertically-expandable-container/vertically-expandable-container";
import type { ChangeEvent, ReactElement } from "react";
import { useRef, useState } from "react";

type ChurnSurveySubmitCallback = () => Promise<void> | void;

interface ChurnSurveyParams {
    appID: string;
    reason: string;
    explanation?: string;
}

async function storeChurnSurvey(params: ChurnSurveyParams): Promise<void> {
    // We have to drain out the body, otherwise we'll just leave the connection
    // around forever.
    await getAppFacilities()
        .callAuthCloudFunction("storeChurnSurvey", params)
        .then(r => r?.text());
}

const ChurnSurveyStyle = styled.div`
    margin: 48px 0;
    width: 380px;

    h2 {
        font-size: 26px;
        font-weight: 900;
    }

    .dropdown-label {
        font-size: 14px;
        font-weight: 600;
        margin-top: 24px;
    }

    .textarea-label {
        font-size: 14px;
        font-weight: 600;
        margin-top: 24px;
        margin-bottom: 8px;
    }

    .submit-button {
        margin-top: 8px;
    }

    .explanation-container {
        margin-bottom: 8px;
    }
`;

interface ChurnSurveyReasonWithoutExplanation {
    text: string;
    needsExplanation: false;
    explanationRequired: false;
}

interface ChurnSurveyReasonWithExplanation {
    text: string;
    needsExplanation: true;
    explanationRequired: boolean;
    explanationLabel: string;
    explanationPlaceholder: string;
}

type ChurnSurveyReason = ChurnSurveyReasonWithoutExplanation | ChurnSurveyReasonWithExplanation;

function reasonNeedsExplanation(reason: ChurnSurveyReason | undefined): reason is ChurnSurveyReasonWithExplanation {
    return reason?.needsExplanation ?? false;
}

const CHURN_SURVEY_REASONS: ChurnSurveyReason[] = [
    {
        text: "Glide was too hard to learn",
        needsExplanation: true,
        explanationRequired: false,
        explanationLabel: "What did you find difficult about using Glide?",
        explanationPlaceholder: "This question is optional",
    },
    {
        text: "Glide is missing features I need",
        needsExplanation: true,
        explanationRequired: false,
        explanationLabel: "Which features are missing?",
        explanationPlaceholder: "This question is optional",
    },
    {
        text: "I had a temporary or seasonal use case",
        needsExplanation: true,
        explanationRequired: false,
        explanationLabel: "Would you use Glide again?",
        explanationPlaceholder: "This question is optional",
    },
    {
        text: "I no longer have a business need",
        needsExplanation: true,
        explanationRequired: false,
        explanationLabel: "Please explain",
        explanationPlaceholder: "This question is optional",
    },
    {
        text: "I found a better product",
        needsExplanation: true,
        explanationRequired: false,
        explanationLabel: "Which product are you using instead?",
        explanationPlaceholder: "This question is optional",
    },
    {
        text: "Glide is too expensive",
        needsExplanation: true,
        explanationRequired: false,
        explanationLabel: "Please explain",
        explanationPlaceholder: "This question is optional",
    },
    {
        text: "Other",
        needsExplanation: true,
        explanationRequired: true,
        explanationLabel: "Please explain",
        explanationPlaceholder: "",
    },
];

interface ChurnSurveyModalProps {
    appID: string;
    onClose(): void;
    isFreeTrial: boolean;
    onSubmit: ChurnSurveySubmitCallback;
}

export function ChurnSurveyModal(props: ChurnSurveyModalProps): ReactElement {
    const { appID, onClose, onSubmit, isFreeTrial } = props;

    const [selectedReason, setSelectedReason] = useState<ChurnSurveyReason | undefined>(undefined);
    const [explanation, setExplanation] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>();

    const isExplanationInputRequired = selectedReason?.explanationRequired ?? false;

    const isButtonDisabled =
        selectedReason === undefined || (isExplanationInputRequired && explanation.trim() === "") || isSubmitting;

    const isSubmittingRef = useRef(false);
    const submit = async () => {
        if (selectedReason === undefined || isSubmittingRef.current) {
            return;
        }

        isSubmittingRef.current = true;
        setIsSubmitting(true);

        const maybeExplanation = explanation === "" ? undefined : explanation;

        await storeChurnSurvey({
            appID,
            reason: selectedReason.text,
            explanation: maybeExplanation,
        });

        trackEvent("downgrade", { app_id: appID, reason: selectedReason.text, explanation, isFreeTrial });

        await onSubmit();
        onClose();
    };

    const onExplanationChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        setExplanation(e.target.value);
    };

    return (
        <WindowedModalV2 canClose={true} onClose={onClose} padding={48}>
            <ChurnSurveyStyle>
                <VerticallyExpandableContainer isOpen={true}>
                    <h2>We’re sorry to see you go</h2>

                    <label>
                        <p className="dropdown-label">How can we improve?</p>
                        <GlideDropdown<ChurnSurveyReason>
                            items={CHURN_SURVEY_REASONS}
                            selected={selectedReason}
                            descriptionForItem={i => i.text}
                            defaultDisplayLabel="Choose one answer..."
                            onItemSelect={setSelectedReason}
                            heightVariation={"tall"}
                        />
                    </label>

                    {reasonNeedsExplanation(selectedReason) && (
                        <div className="explanation-container">
                            <label>
                                <p className="textarea-label">{selectedReason.explanationLabel}</p>
                                <HighlightTextArea
                                    value={explanation}
                                    onChange={onExplanationChange}
                                    placeholder={selectedReason.explanationPlaceholder}
                                    minHeight={56}
                                    alignTop={true}
                                    disabled={isSubmitting}
                                    maxHeight={200}
                                />
                            </label>
                        </div>
                    )}

                    <Button
                        className="submit-button"
                        variant="danger"
                        buttonType="primary"
                        size="xlg"
                        isFullWidth={true}
                        disabled={isButtonDisabled}
                        isProcessing={isSubmitting}
                        onClick={submit}
                        label="Cancel Plan"
                    />
                </VerticallyExpandableContainer>
            </ChurnSurveyStyle>
        </WindowedModalV2>
    );
}
