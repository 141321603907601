import { ImageAspectRatio } from "@glide/common-core/dist/js/components/image-types";
import type {
    MutatingScreenKind,
    PropertyDescription,
    ScreenDescriptionKind,
    ArrayScreenDescription,
} from "@glide/app-description";
import {
    ArrayScreenFormat,
    getActionProperty,
    getColumnProperty,
    getSourceColumnProperty,
    getSwitchProperty,
    makeActionProperty,
    makeArrayProperty,
    makeEnumProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import {
    ComponentKindInlineList,
    makeEmptyComponentDescription,
    type InputOutputTables,
} from "@glide/common-core/dist/js/description";
import { CardFloatStyle, GroupingSupport } from "@glide/component-utils";
import type { WireAppCardsListComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    type PropertyTableGetter,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    EnumPropertyHandler,
    PropertySection,
    makeImageHeightPropertyHandler,
    makeTextPropertyDescriptor,
    getPrimitiveNonHiddenColumnsSpec,
    getTitlePropertyFromInlineListCaption,
} from "@glide/function-utils";
import type { InlineListComponentDescription } from "@glide/function-utils";
import {
    type WireTableComponentHydratorConstructor,
    type WireInflationBackend,
    CardStyle,
    UIImageStyle,
    UIOrientation,
    UIStyleVariant,
} from "@glide/wire";
import { getActionsForArrayContent } from "../components/component-utils";
import { getOverlayPropertyHandlers, getOverlaySearchProperties } from "../overlay-utils";
import {
    type WireStringGetter,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
} from "../wire/utils";
import {
    allCapsPropertyHandler,
    cornersPropertyHandler,
    pushHorizontalAndTruncatePropertyHandlers,
    textSizePropertyHandler,
    truncateListPropertyHandler,
} from "./properties";
import { type SummaryArrayScreenDescription, SummaryArrayScreenHandler } from "./summary-array-screen";
import type { CardCollectionComponentDescription } from "@glide/fluent-components/dist/js/fluent-components";
import { type TableGlideType, getTableName } from "@glide/type-schema";
import { definedMap } from "collection-utils";
import type { ComponentScreenContextForConversion } from "./array-content";
import {
    getPageAspectFromClassicSize,
    inflateTilesBaseArrayContent,
    isHorizontal,
    type TilesBaseArrayContentDescription,
    getPageSizeFromClassicNumColumns,
} from "./tiles-array-screen";

interface CardsArrayContentDescription extends TilesBaseArrayContentDescription {
    readonly header: PropertyDescription | undefined;
    readonly numTextLines: PropertyDescription;
    readonly floatStyle: PropertyDescription;
    readonly otherCaption: PropertyDescription | undefined;
}

interface CardsArrayScreenDescription extends SummaryArrayScreenDescription, CardsArrayContentDescription {
    readonly kind: ScreenDescriptionKind.Array;
    readonly format: ArrayScreenFormat.Cards;
}

const defaultSize = ImageAspectRatio.ThreeByOne;
const sizePropertyHandler = makeImageHeightPropertyHandler(defaultSize, false, false, true, "Image shape");

const numberOfColumnsPropertyHandler = new EnumPropertyHandler(
    { numColumns: 1 },
    "",
    "Size",
    [
        {
            value: 1,
            label: "Full-Size",
            icon: "fullSizeCard",
        },
        {
            value: 2,
            label: "Half-Size",
            icon: "halfSizeCard",
        },
    ],
    PropertySection.Design,
    "small-images"
);

const numberOfTextLinesPropertyHandler = new EnumPropertyHandler(
    { numTextLines: 3 },
    "",
    "Lines of text",
    [
        { value: 1, label: "1 line of text" },
        { value: 2, label: "2 lines of text" },
        { value: 3, label: "3 lines of text" },
        { value: 4, label: "4 lines of text" },
        { value: 5, label: "5 lines of text" },
        { value: 6, label: "6 lines of text" },
    ],
    PropertySection.TextStyle,
    "slider"
);

const floatStylePropertyHandler = new EnumPropertyHandler(
    { floatStyle: CardFloatStyle.Float },
    "",
    "Card style",
    [
        { value: CardFloatStyle.Float, label: "Float", icon: "cardFloat" },
        { value: CardFloatStyle.Outline, label: "Outline", icon: "cardOutline" },
        { value: CardFloatStyle.None, label: "None", icon: "cardNone" },
    ],
    PropertySection.Design,
    "small-images"
);

export class CardsArrayScreenHandler extends SummaryArrayScreenHandler<
    CardsArrayContentDescription,
    CardsArrayScreenDescription
> {
    protected readonly supportsNonURLImages = true;
    protected readonly supportsEmojiImages = false;
    protected readonly fullTitleFallback = true;
    // We only support optional truncation when vertical
    protected readonly supportsTruncateList = false;

    constructor() {
        super(ArrayScreenFormat.Cards, "Cards", "cards", false);
    }

    protected titleRequired() {
        return false;
    }

    protected get imagePropertyFirst(): boolean {
        return true;
    }

    protected get groupingSupport(): GroupingSupport {
        return GroupingSupport.Regular;
    }

    public adjustContentDescriptionAfterUpdate(
        desc: CardsArrayContentDescription,
        updates: Partial<CardsArrayContentDescription & InlineListComponentDescription> | undefined
    ): CardsArrayContentDescription {
        const numColumnsRaw = numberOfColumnsPropertyHandler.getEnum(desc);
        if (numColumnsRaw < 1 || numColumnsRaw > 2) {
            desc = numberOfColumnsPropertyHandler.setInDescription(
                desc,
                numberOfColumnsPropertyHandler.defaultCaseValue
            ) as CardsArrayContentDescription;
        }

        if (updates?.isHorizontal !== undefined && isHorizontal(desc)) {
            desc = truncateListPropertyHandler.setInDescription(desc, truncateListPropertyHandler.defaultValue);
        }

        return desc;
    }

    protected getInterSummaryPropertyDescriptors(
        getPropertyTable: PropertyTableGetter | undefined,
        _insideInlineList: boolean
    ): readonly PropertyDescriptor[] {
        return [
            new ColumnPropertyHandler(
                "header",
                "Header",
                [ColumnPropertyFlag.Optional, ColumnPropertyFlag.Editable, ColumnPropertyFlag.Searchable],
                getPropertyTable,
                ["header"],
                getPrimitiveNonHiddenColumnsSpec,
                "string",
                PropertySection.Content
            ),
        ];
    }

    public getContentPropertyDescriptors<T extends CardsArrayContentDescription>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined
    ): ReadonlyArray<PropertyDescriptor> {
        const descrs: PropertyDescriptor[] = [
            ...super.getContentPropertyDescriptors(
                getPropertyTable,
                insideInlineList,
                containingScreenTables,
                desc,
                ccc,
                mutatingScreenKind,
                isDefaultArrayScreen,
                withTransforms,
                forEasyTabConfiguration,
                isFirstComponent
            ),
        ];

        descrs.push(sizePropertyHandler, numberOfColumnsPropertyHandler, floatStylePropertyHandler);

        pushHorizontalAndTruncatePropertyHandlers(descrs, desc, isDefaultArrayScreen, true);

        descrs.push(cornersPropertyHandler, textSizePropertyHandler, numberOfTextLinesPropertyHandler);

        const otherCaptionPropertyHandler = makeTextPropertyDescriptor(
            "otherCaption",
            "Caption",
            "Enter caption text",
            false,
            undefined,
            {
                emptyByDefault: true,
                propertySection: PropertySection.Overlays,
                preferredNames: ["caption", "description"],
                getIndirectTable: getPropertyTable,
            }
        );
        descrs.push(
            allCapsPropertyHandler,
            ...getOverlayPropertyHandlers(getPropertyTable, undefined, "Avatar text"),
            otherCaptionPropertyHandler
        );

        return descrs;
    }

    public getBasicSearchProperties(desc: CardsArrayContentDescription): readonly string[] {
        const properties = [...super.getBasicSearchProperties(desc), ...getOverlaySearchProperties(desc)];
        const otherCaption = getColumnProperty(desc.otherCaption);
        if (otherCaption !== undefined) {
            properties.push(otherCaption);
        }
        return properties;
    }

    public inflateContent<T extends CardsArrayContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        captionGetter: WireStringGetter | undefined,
        containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        const [headerGetter] = inflateStringProperty(ib, desc.header, true);

        const numTextLines = numberOfTextLinesPropertyHandler.getEnum(desc);
        const floatStyle = floatStylePropertyHandler.getEnum(desc);

        const hydrateList = inflateTilesBaseArrayContent(
            ib,
            ArrayScreenFormat.Cards,
            desc,
            getActionsForArrayContent(this, ib.tables, desc, ib.adc).actions,
            desc.otherCaption,
            captionGetter,
            this.makeGroupByGetters(ib, desc),
            componentID,
            containingRowIB !== undefined,
            (rhb, base) => ({
                ...base,
                header: headerGetter(rhb),
            })
        );

        return makeSimpleWireTableComponentHydratorConstructor(ib, (thb, chb, searchActive) => {
            const base = hydrateList(thb, chb, searchActive);
            if (base === undefined) return undefined;

            const component: WireAppCardsListComponent = {
                ...base.component,
                numTextLines,
                floatStyle,
            };
            return {
                component,
                isValid: true,
                subsidiaryScreen: base.subsidiaryScreen,
                firstListItemActionToRun: base.firstListItemActionToRun,
            };
        });
    }

    private convertContentToPage(desc: CardsArrayContentDescription) {
        const originalSize = sizePropertyHandler.getEnum(desc);
        const aspect = getPageAspectFromClassicSize(originalSize);
        const numColumns = numberOfColumnsPropertyHandler.getEnum(desc);
        const size = getPageSizeFromClassicNumColumns(numColumns);
        const imageStyle = originalSize === ImageAspectRatio.Circle ? UIImageStyle.Circle : UIImageStyle.Rectilinear;

        const classicIsHorizontal = isHorizontal(desc);
        const orientation = classicIsHorizontal ? UIOrientation.Horizontal : UIOrientation.Vertical;

        return {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            format: makeEnumProperty(ArrayScreenFormat.CardCollection),
            caption: undefined,
            cardStyle: makeEnumProperty(CardStyle.Cover),
            transforms: desc.transforms,
            action: definedMap(getActionProperty(desc.actions), a => makeActionProperty(a)),
            title: definedMap(getSourceColumnProperty(desc.titleProperty), sc => makeSourceColumnProperty(sc)),
            subtitle: definedMap(getSourceColumnProperty(desc.subtitleProperty), sc => makeSourceColumnProperty(sc)),
            image: definedMap(getSourceColumnProperty(desc.imageURLProperty), sc => makeSourceColumnProperty(sc)),
            groupByColumn: desc.groupByColumn,
            aspectRatio: makeEnumProperty(aspect),
            size: makeEnumProperty(size),
            imageStyle: makeEnumProperty(imageStyle),
            orientation: makeEnumProperty(orientation),
            emphasis: desc.header,
            cardCollectionComponentStyle: makeEnumProperty(UIStyleVariant.Default),
        };
    }

    public convertInlineToPage(
        desc: InlineListComponentDescription & CardsArrayContentDescription
    ): CardCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: desc.propertyName,
            allowSearch: desc.allowSearch,
            componentTitle: getTitlePropertyFromInlineListCaption(desc),
        };
    }

    public convertArrayScreenToPage(
        desc: ArrayScreenDescription & CardsArrayContentDescription,
        table: TableGlideType,
        adc: AppDescriptionContext,
        screenContext: ComponentScreenContextForConversion
    ): CardCollectionComponentDescription | undefined {
        return {
            ...this.convertContentToPage(desc),
            propertyName: makeTableProperty(getTableName(table)),
            allowSearch: makeSwitchProperty(getSwitchProperty(desc.search)),
            titleActions: definedMap(screenContext.titleAction, a => makeArrayProperty([a])),
            componentTitle: definedMap(screenContext.screenTitle, t => makeStringProperty(t)),
            multipleDynamicFilters: this.getDynamicMultipleFiltersForPageConversion(
                adc,
                table,
                desc.dynamicFilterColumn,
                desc.pivots
            ),
        };
    }
}
