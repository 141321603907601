import { getFeatureSetting } from "@glide/common-core";
import { AppKind } from "@glide/location-common";
import { type LoadingValue, isLoadingValue } from "@glide/computation-model-types";
import { isRow } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ColumnAssignmentsDescription,
    type PropertyDescription,
    ActionKind,
    MutatingScreenKind,
    makeSourceColumnProperty,
    getStringProperty,
    getSpecialValueProperty,
} from "@glide/app-description";
import {
    type ActionWithOutputRowDescription,
    type InputOutputTables,
    doesMutatingScreenAddRows,
} from "@glide/common-core/dist/js/description";
import {
    isTableWritable,
    SourceColumnKind,
    areSourceColumnsEqual,
    getTableColumnDisplayName,
    getTableName,
    sheetNameForTable,
    SpecialValueKind,
    getTableColumn,
} from "@glide/type-schema";
import {
    type AppDescriptionContext,
    type EditedColumnsAndTables,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    PropertySection,
    thisRowSourceColumn,
    makeSingleRelationOrThisItemPropertyDescriptor,
} from "@glide/function-utils";
import { removeNullFromObject } from "@glide/support";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import { assert, assertNever, defined, mapFilterUndefined } from "@glideapps/ts-necessities";
import fromPairs from "lodash/fromPairs";
import { getWritableColumnAssignments, makePropertyDescriptorsForColumns } from "../components/descriptor-utils";
import { getColumnAssignments, getRelationForDescription } from "../description-utils";
import type { StaticActionContext } from "../static-context";
import { getCanEditRowFromNetworkStatus, inflateColumnAssignments, makeRowGetter } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import type { ActionColumnAssignments, DescriptionToken } from "./action-handler";
import { makeEditedColumnsFromColumnAssignments } from "./add-row";
import { BaseActionHandler, getBillablesConsumedForCRUDActions } from "./base";
import type { BillablesConsumed, GlideIconProps } from "@glide/plugins-codecs";
import { ICON_BASE, LIME_500 } from "../plugins/icon-colors";

export interface SetColumnsActionDescription extends ActionWithOutputRowDescription, ColumnAssignmentsDescription {
    readonly kind: ActionKind.SetColumns;
}

const getRelation = getRelationForDescription({
    inOutputRow: true,
    onlySingleRelations: true,
    defaultToThisRow: true,
    allowFullTable: false,
});

export function getTokenizedSourceColumn(
    desc: PropertyDescription | undefined,
    maybePrefix: string | undefined,
    withExplicitThisRow: boolean,
    env: StaticActionContext<AppDescriptionContext>
): readonly DescriptionToken[] | undefined {
    const outputColumn = getRelation(
        desc,
        env.context,
        env.tables,
        env.priorSteps?.map(s => s.node)
    );
    if (outputColumn === undefined) return undefined;

    const prefix = maybePrefix !== undefined ? maybePrefix + " " : "";

    if (outputColumn.column === undefined) {
        const sourceColumn = defined(outputColumn.sourceColumn);
        switch (sourceColumn.kind) {
            case SourceColumnKind.DefaultContext:
                if (withExplicitThisRow) {
                    return [{ kind: "string", value: `${prefix}this item` }];
                } else {
                    return [];
                }
            case SourceColumnKind.UserProfile:
                return [{ kind: "string", value: `${prefix}the user profile` }];
            case SourceColumnKind.ContainingScreen:
                // We're not supporting this yet
                return [{ kind: "string", value: `${prefix}the containing screen` }];
            case SourceColumnKind.ActionNodeOutput:
                const tableName = sheetNameForTable(outputColumn.table);
                return [{ kind: "string", value: `${prefix}${tableName}` }];
            default:
                return assertNever(sourceColumn.kind);
        }
    }

    return [
        ...(prefix === "" ? [] : [{ kind: "string" as const, value: prefix }]),
        { kind: "column", value: getTableColumnDisplayName(outputColumn.column) },
    ];
}

export function getSupportsUserProfileRowAccess(adc: AppDescriptionContext): boolean {
    if (adc.appDescription === undefined) return false;
    if (adc.userProfileTableInfo === undefined) return false;
    return true;
}

export class SetColumnsActionHandler extends BaseActionHandler<SetColumnsActionDescription> {
    public readonly kind = ActionKind.SetColumns;

    public readonly iconName: GlideIconProps = {
        icon: "st-workflow-set-columns",
        kind: "stroke",
        strokeFgColor: ICON_BASE,
        strokeColor: LIME_500,
    };

    public readonly name: string = "Set column values";

    public getDescriptor(
        desc: SetColumnsActionDescription | undefined,
        env: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const { context: ccc, tables } = env;

        const propertyDescriptors: PropertyDescriptor[] = [
            makeSingleRelationOrThisItemPropertyDescriptor(
                "outputRow",
                "Row",
                PropertySection.Data,
                true,
                getSupportsUserProfileRowAccess(ccc)
            ),
        ];

        let outputTable = tables?.output;
        if (desc !== undefined) {
            const tableAndColumn = getRelation(
                desc.outputRow,
                env.context,
                env.tables,
                env.priorSteps?.map(s => s.node)
            );
            if (tableAndColumn !== undefined) {
                outputTable = tableAndColumn.table;
            }
        }

        if (outputTable !== undefined) {
            propertyDescriptors.push(
                ...makePropertyDescriptorsForColumns<SetColumnsActionDescription>(
                    ccc,
                    tables?.input,
                    outputTable,
                    getColumnAssignments,
                    columns => ({ columnAssignments: columns }),
                    {
                        withActionSource: false,
                        withClearColumn: true,
                        withLinkColumns: true,
                        withArrays: true,
                        forAddingRow: false,
                        allowCustomAndUserProfile: true,
                        emptyByDefault: false,
                    }
                )[0]
            );
        }
        return {
            name: this.name,
            group: ActionGroup.Data,
            groupItemOrder: 3,
            needsScreenContext: true,
            properties: propertyDescriptors,
        };
    }

    public newActionDescription(_env: StaticActionContext<AppDescriptionContext>): SetColumnsActionDescription {
        return {
            kind: this.kind,
            columnAssignments: [],
            outputRow: makeSourceColumnProperty(thisRowSourceColumn),
        };
    }

    public getColumnAssignments(
        desc: SetColumnsActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): (ActionColumnAssignments & { readonly inScreenContext: boolean }) | undefined {
        const tableAndColumn = getRelation(
            desc.outputRow,
            env.context,
            env.tables,
            env.priorSteps?.map(s => s.node)
        );
        if (tableAndColumn === undefined) return undefined;

        const inScreenContext = areSourceColumnsEqual(defined(tableAndColumn.sourceColumn), thisRowSourceColumn);
        const isAddRow = inScreenContext && doesMutatingScreenAddRows(env.mutatingScreenKind);

        const assignments = getWritableColumnAssignments(
            env.context,
            tableAndColumn.table,
            getColumnAssignments(desc),
            isAddRow
        );

        return { assignments, isAddRow, table: tableAndColumn.table, inScreenContext };
    }

    public getEditedColumns(
        desc: SetColumnsActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): EditedColumnsAndTables | undefined {
        const assignments = this.getColumnAssignments(desc, env);
        if (assignments === undefined) return undefined;

        const editedColumns = makeEditedColumnsFromColumnAssignments(
            assignments,
            assignments.inScreenContext,
            env.context
        );

        return { editedColumns, deletedTables: [] };
    }

    public getTokenizedDescription(
        desc: SetColumnsActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const tableAndColumn = getRelation(
            desc.outputRow,
            env.context,
            env.tables,
            env.priorSteps?.map(s => s.node)
        );
        if (tableAndColumn === undefined) return undefined;

        const outputTable = tableAndColumn.table;
        const { columnAssignments } = desc;
        // make sure the column exists in the output table
        const actualAssignments = (columnAssignments ?? [])
            .filter(a => {
                const text = getStringProperty(a.value);
                return text === undefined || text.length > 0;
            })
            .filter(a => getTableColumn(outputTable, a.destColumn) !== undefined);

        if (actualAssignments.length === 0) {
            return undefined;
        }

        let prefix = "in";
        const isOnlyClearing = actualAssignments.every(
            a => getSpecialValueProperty(a.value) === SpecialValueKind.ClearColumn
        );
        prefix = `${isOnlyClearing ? "Clear" : "Set"} `;
        if (actualAssignments.length < 3) {
            prefix += actualAssignments
                .map(a => {
                    const column = getTableColumn(outputTable, a.destColumn);
                    assert(column !== undefined); // assignments are filtered above
                    return getTableColumnDisplayName(column);
                })
                .join(", ");
        } else {
            prefix += `${actualAssignments.length} columns`;
        }
        prefix += " in";
        return getTokenizedSourceColumn(desc.outputRow, prefix, true, env);
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: SetColumnsActionDescription,
        arbBase: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const {
            adc: { appKind, eminenceFlags },
        } = ib;

        const destination = makeRowGetter(ib, desc.outputRow, { inOutputRow: true, defaultToThisRow: true });
        if (destination === undefined || destination === false) return arbBase.inflationError("Invalid row");
        const { table: destTable, rowGetter: destRowGetter } = destination;
        arbBase = arbBase.addData({ tableName: sheetNameForTable(destTable) });

        if (!isTableWritable(destTable)) return arbBase.inflationError("Table is not writable");

        const outputValueGetters = inflateColumnAssignments(ib, destTable, getColumnAssignments(desc), false);

        return (vp, skipLoading) => {
            const destRow = destRowGetter(vp);
            if (isLoadingValue(destRow))
                return getFeatureSetting("setColumnsErrorOnSkipLoading")
                    ? arbBase.errorIfSkipLoading(skipLoading, "Destination row")
                    : arbBase.maybeSkipLoading(skipLoading, "Destination row");
            if (destRow === undefined && appKind === AppKind.App) {
                // In NCM, doing a Set Columns on a relation that is empty is
                // a failure, which means that any action that should run
                // after doesn't run.
                //
                // In OCM this did not count as a failure, which means that
                // for Apps we unfortunately have to copy that behavior
                return arbBase.nothingToDo("No row to set columns in");
            }
            if (destRow === null || !isRow(destRow)) return arbBase.error(true, "No row to set columns in");

            let loadingValue: LoadingValue | undefined;
            const outputValues = mapFilterUndefined(outputValueGetters, ([n, g]) => {
                const v = g(vp);
                // `null` means unbound, which shouldn't happen.
                if (v === null) return undefined;
                if (isLoadingValue(v)) {
                    loadingValue = v;
                    return undefined;
                }
                // `undefined` and empty string are treated the same in Glide,
                // but we can't assign `undefined`.
                return [n, v ?? ""];
            });
            if (loadingValue !== undefined) {
                // If we're asked to hydrate this with `noLoading`, we go with
                // what we have.
                if (!skipLoading) return arbBase.loading();
            }
            const updates = removeNullFromObject(fromPairs(outputValues));
            if (Object.keys(updates).length === 0) {
                // Not assigning anything is still a success.
                return arbBase.nothingToDo("No columns to set");
            }

            const arb = arbBase.addData({ updates });

            // We do this hear as opposed to earlier, because `NothingToDo`
            // takes precedence.
            if (!getCanEditRowFromNetworkStatus(destRow, vp, eminenceFlags, MutatingScreenKind.EditScreen)) {
                return arb.offline();
            }

            return async ab => {
                const result = await ab.setColumnsInRow(
                    getTableName(destTable),
                    destRow,
                    updates,
                    false,
                    undefined,
                    undefined
                );
                return arb.fromResult(result);
            };
        };
    }

    public updateAction(
        desc: SetColumnsActionDescription,
        updates: Partial<SetColumnsActionDescription>,
        _tables: InputOutputTables | undefined,
        _ccc: InteractiveComponentConfiguratorContext,
        _mutatingScreenKind: MutatingScreenKind | undefined
    ): SetColumnsActionDescription {
        if (updates.outputRow !== undefined) {
            return {
                ...desc,
                columnAssignments: undefined,
            };
        }
        return desc;
    }

    public getBillablesConsumed(env: StaticActionContext<AppDescriptionContext>): BillablesConsumed | undefined {
        return getBillablesConsumedForCRUDActions(env);
    }
}
