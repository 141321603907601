// https://storage.googleapis.com/glide-prod.appspot.com/snapshots-private/aiXNiGNKaG5o9ePeUVyX.jzon?GoogleAccessId=glide-full-access%40glide-prod.iam.gserviceaccount.com&Expires=1670077221&Signature=FsCTl2Iw%2BZCDh%2F4De1Uypypd6pDbMCN7uzTk49%2FkTVRYSUkpsdP7o0QhT7LJ8wIUDLYTTEfzezKGij%2B66yk6F0I8pzaEh3An2iCweXem9Zgt1JuN4MkN35A4WH588RKdgBw3T2J2AIZm3qU%2B0%2Bpn7tZ1c4hhU%2B81I%2B3CXJkcVmPM8UvUAbdxHcgqV2LMje6QCVztkvHEvsVPVe392I3vfSoDLhBqNbkhu%2BBuqlF%2BCCjeSyw76fiMUSIdsEKXqGbWz6b5dVNcDp%2BkquRxTFPT0cnKUNBzwaEWoq6%2FVTFOZvHamv9V7xZUfNYITg0%2BdoxqIEub3T%2BmzBg8t0hNj8BJtA%3D%3D#GlideSnapshotVersion=23

import { getFeatureSetting } from "./feature-settings";

interface Rewrite {
    readonly originalHost: string;
    readonly newHost: string;

    readonly originalPathPrefix: string;
    readonly newPathPrefix: string;
}

const rewrites: readonly Rewrite[] = [
    {
        originalHost: "storage.googleapis.com",
        newHost: "api.glid3.com",
        originalPathPrefix: "/glide-prod.appspot.com/",
        newPathPrefix: "/data/",
    },
    {
        originalHost: "storage.googleapis.com",
        newHost: "staging.glid3.com",
        originalPathPrefix: "/glide-staging.appspot.com/",
        newPathPrefix: "/data/",
    },
];

export function rewriteCloudStorage(urlString: string): string {
    const isEnabled = getFeatureSetting("proxyCloudStorage");
    if (!isEnabled) return urlString;

    try {
        for (const r of rewrites) {
            const url = new URL(urlString);
            if (url.host.toLowerCase() !== r.originalHost) continue;
            if (!url.pathname.startsWith(r.originalPathPrefix)) continue;

            url.host = r.newHost;
            url.pathname = r.newPathPrefix + url.pathname.substring(r.originalPathPrefix.length);

            return url.toString();
        }
        return urlString;
    } catch {
        return urlString;
    }
}
