import {
    type ComponentDescription,
    type ComponentKind,
    ArrayScreenFormat,
    makeColumnProperty,
    makeEnumProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import { type Description, getPrimitiveColumns, type SchemaInspector } from "@glide/type-schema";
import { type InputOutputTables, ComponentKindInlineList } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { ChoiceStyle } from "@glide/component-utils";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type InlineListComponentDescription,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    StringPropertyHandler,
    TablePropertyHandler,
    isRequiredPropertyHandler,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { undefinedIfEmptyString } from "@glide/support";
import { definedMap } from "@glideapps/ts-necessities";

import type { ChoiceArrayContentDescription } from "../array-screens/choice";
import { makePrimaryKeyPropertyHandler } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";
import { getAllowedChoiceColumns, getAllowedChoiceTables } from "@glide/fluent-components/dist/js/base-components";

export const ComponentKindChoice: ComponentKind = "choice";

const valuePropertyHandler: ColumnPropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.DefaultCaption,
        ColumnPropertyFlag.EditedInApp,
    ],
    undefined,
    undefined,
    {
        columnTypeIsAllowed: () => true,
        getCandidateColumns: (propertyTable, desc, schema) => {
            if (desc === undefined) return [];
            const columns = getPrimitiveColumns(propertyTable);
            const choiceTable = tablePropertyHandler.lookupTable(desc, schema);
            if (propertyTable !== choiceTable) {
                return columns;
            }
            const choicesColumnName = columnPropertyHandler.getColumnName(desc);
            return columns.filter(c => c.name !== choicesColumnName);
        },
    },
    "string",
    PropertySection.Data
);
const tablePropertyHandler = new TablePropertyHandler(
    "choicesTableName",
    "Table",
    true,
    getAllowedChoiceTables,
    PropertySection.Data
);
const columnPropertyHandler = new ColumnPropertyHandler(
    "choicesColumnName",
    "Values",
    [ColumnPropertyFlag.Required, ColumnPropertyFlag.Editable],
    (
        _componentTables: InputOutputTables | undefined,
        rootDesc: Description,
        _desc: Description,
        schema: SchemaInspector
    ) => definedMap(tablePropertyHandler.lookupTable(rootDesc, schema), t => ({ table: t, inScreenContext: false })),
    ["choices"],
    {
        getCandidateColumns: getAllowedChoiceColumns,
        columnTypeIsAllowed: () => true,
    },
    "string",
    PropertySection.Data
);

const titlePropertyHandler = new StringPropertyHandler(
    "title",
    "Title",
    "the default title",
    false,
    "Choice",
    PropertySection.Design
);

export class ChoiceComponentHandler extends ComponentHandlerBase<ComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindChoice);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public needValidation(desc: ComponentDescription): boolean {
        return isRequiredPropertyHandler.getSwitch(desc);
    }

    public getDescriptor(
        _desc: ComponentDescription,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext
    ): ComponentDescriptor {
        return {
            name: "Choice",
            description: "Choose one out of many options",
            img: "co-choice",
            group: "Pickers",
            helpUrl: getDocURL("choice"),
            properties: [
                valuePropertyHandler,
                titlePropertyHandler,
                tablePropertyHandler,
                columnPropertyHandler,
                ...makePrimaryKeyPropertyHandler(ccc, tables?.input),
                isRequiredPropertyHandler,
                ...this.getBasePropertyDescriptors(),
            ],
            isLegacy: true,
        };
    }

    public getDescriptiveName(
        desc: ComponentDescription,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext
    ): [string, string] {
        const names = super.getDescriptiveName(desc, tables, ccc);
        const caption = titlePropertyHandler.getString(desc);
        return [names[0], undefinedIfEmptyString(caption) ?? names[1]];
    }

    public static convertToNewChoice(desc: ComponentDescription): InlineListComponentDescription | undefined {
        const valueColumnName = valuePropertyHandler.getColumnName(desc);
        const choiceTable = tablePropertyHandler.getTableName(desc);
        const choiceColumnName = columnPropertyHandler.getColumnName(desc);
        const title = titlePropertyHandler.getString(desc);
        const isRequired = isRequiredPropertyHandler.getSwitch(desc);
        if (valueColumnName === undefined || choiceTable === undefined || choiceColumnName === undefined)
            return undefined;

        const newDesc: InlineListComponentDescription & ChoiceArrayContentDescription = {
            kind: ComponentKindInlineList,
            componentID: desc.componentID,
            visibilityFilters: desc.visibilityFilters,
            format: makeEnumProperty(ArrayScreenFormat.Choice),
            propertyName: makeTableProperty(choiceTable),
            caption: definedMap(title, makeStringProperty),
            style: makeEnumProperty(ChoiceStyle.Dropdown),
            valueProperty: makeColumnProperty(valueColumnName),
            optionProperty: makeColumnProperty(choiceColumnName),
            isRequired: makeSwitchProperty(isRequired),
            isMulti: makeSwitchProperty(false),
        };
        return newDesc;
    }

    public convertToPage(): ComponentDescription | undefined {
        // This choice component is legacy and it doesn't even have an inflator.
        // It will be converted to a new choice by `convertToNewChoice` when we run fix-app.
        return undefined;
    }
}
