import { TextComponentStyle } from "@glide/component-utils";
import { css } from "styled-components";
import tw from "twin.macro";
import { signInNormalTextColors } from "./custom-sign-in-styles";
import { Text } from "../text/text";

interface GreetingProps {
    greeting?: string | null;
}

export const GreetingStyles: React.VFC<GreetingProps> = p => {
    const { greeting } = p;

    return (
        <Text
            element="h1"
            variant={TextComponentStyle.headlineMedium}
            css={css`
                ${tw`page-sm:(mt-10 mb-6) mt-6 mb-2 text-center`}

                ${signInNormalTextColors}
            `}
            data-test="app-sign-in-title">
            {greeting}
        </Text>
    );
};
