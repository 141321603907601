import { browserTimeZone, DateFormat, formatDateTime, getFormattedTimeDifference, TimeFormat } from "@glide/data-types";
import upperFirst from "lodash/upperFirst";
export function formatDuration(seconds: number, isLong: boolean = false): string {
    const minutes = Math.floor(seconds / 60);
    if (minutes >= 60) {
        const hours = Math.floor(minutes / 60);
        if (isLong) {
            const m = Math.floor(minutes % 60);
            return `${hours} hour${hours > 1 ? "s" : ""} and ${m} minute${m > 1 ? "s" : ""}`;
        } else {
            return `${hours}h`;
        }
    }
    if (minutes >= 1) {
        if (isLong) {
            const s = Math.floor(seconds % 60);
            return `${minutes} minute${minutes > 1 ? "s" : ""} and ${s} second${s > 1 ? "s" : ""}`;
        } else {
            return `${minutes}m`;
        }
    }
    const roundedSeconds = Math.floor(seconds);
    if (isLong) {
        return `${roundedSeconds} second${seconds > 1 ? "s" : ""}`;
    }
    return `${roundedSeconds}s`;
}

export function formatTime(dt: Date): string {
    const diff = new Date().getTime() - dt.getTime();
    // check if it's in the same day
    const sameDay = new Date().getDate() === dt.getDate();
    if (diff > 1000 * 60 * 60 * 24 || !sameDay) {
        // more than 1 day
        return formatDateTime(dt, DateFormat.Medium, TimeFormat.WithoutSeconds, browserTimeZone(), true) ?? "Unknown";
    } else if (diff > 1000 * 60 * 60) {
        // more than 1 hour
        return `Today at ${formatDateTime(dt, undefined, TimeFormat.WithoutSeconds, browserTimeZone(), true)}`;
    } else {
        return upperFirst(getFormattedTimeDifference(dt));
    }
}
