import { asString, type QuerySort } from "@glide/computation-model-types";
import { rowIndexColumnName, type TableColumn, getTableColumn } from "@glide/type-schema";
import { asPrimitive, evaluateBinaryForType } from "./refilter";
import { convertColumnToPluginColumn } from "./plugin-column";

function compareStringsCaseInsensitively(l: string, r: string, asc: boolean): number {
    const ll = l.toLowerCase();
    const lr = r.toLowerCase();
    if (ll < lr) {
        return asc ? -1 : 1;
    }
    if (ll > lr) {
        return asc ? 1 : -1;
    }
    return 0;
}

function compareStringsCaseSensitively(l: unknown, r: unknown, asc: boolean): number {
    const sort = asc ? 1 : -1;

    if (l === undefined && r === undefined) return 0;
    if (l === undefined) return 1 * sort;
    if (r === undefined) return -1 * sort;

    const ls = asString(l);
    const rs = asString(r);

    if (ls < rs) return -1 * sort;
    if (ls > rs) return 1 * sort;
    return 0;
}

function compareSingle<T extends Record<string, any>>(
    column: TableColumn,
    order: "asc" | "desc",
    a: T,
    b: T,
    getColumnValue: (row: T, columnName: string) => any
): number {
    const asc = order === "asc";
    const va = asPrimitive(getColumnValue(a, column.name));
    const vb = asPrimitive(getColumnValue(b, column.name));
    const columnAsPluginColumn = convertColumnToPluginColumn(column);
    return evaluateBinaryForType(
        va ?? null,
        vb ?? null,
        columnAsPluginColumn,
        columnAsPluginColumn,
        (l, r) => compareStringsCaseInsensitively(l, r, asc),
        (l, r) => (asc ? l - r : r - l),
        (l, r) => (asc ? (l ? 1 : 0) - (r ? 1 : 0) : (r ? 1 : 0) - (l ? 1 : 0)),
        (l, r) => (asc ? l.compareTo(r, { deviceTzMinutesOffset: 0 }) : r.compareTo(l, { deviceTzMinutesOffset: 0 })),
        0,
        {
            // sort missing and wrong typed values to the end...
            lhsDefault: asc ? 1 : -1,
            rhsDefault: asc ? -1 : 1,
            bothDefault: 0,
        }
    );
}

// FIXME: This function will not have the same sort behavior as GBT for row IDs or string columns containing special or
// accented characters. Our best path forward to achieve parity is to remove local fixup altogether.
// https://www.notion.so/glideapps/How-do-we-make-GBT-real-time-ceb81982290349b7b431e0b0a86c0f70?pvs=4#3390879ec86c4833a27e40cf3ac6ad10
// mutates results
export function applySort<T extends Record<string, any>>(
    columns: readonly TableColumn[],
    sort: readonly QuerySort[],
    results: T[],
    getColumnValue: (row: T, columnName: string) => any = (row, columnName) => row[columnName],
    fallbackSortByRowIndex: boolean = true
) {
    if (sort.length === 0 && !fallbackSortByRowIndex) return;
    results.sort((a, b) => {
        for (const { columnName, order } of sort) {
            if (columnName === rowIndexColumnName) {
                const rowIndexA = getColumnValue(a, rowIndexColumnName);
                const rowIndexB = getColumnValue(b, rowIndexColumnName);
                return compareStringsCaseSensitively(rowIndexA, rowIndexB, order !== "desc");
            }

            const column = getTableColumn(columns, columnName);
            if (column === undefined) continue;

            const result = compareSingle(column, order, a, b, getColumnValue);
            if (result !== 0) return result;
        }
        if (fallbackSortByRowIndex) {
            const ascending = sort[sort.length - 1]?.order !== "desc";
            const rowIndexA = getColumnValue(a, rowIndexColumnName);
            const rowIndexB = getColumnValue(b, rowIndexColumnName);
            return compareStringsCaseSensitively(rowIndexA, rowIndexB, ascending);
        }
        return 0;
    });
}
