import type { AppFeatures, PluginConfig } from "@glide/app-description";
import type { AppKind, LocationSettings } from "@glide/location-common";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import {
    emptyAppEnvironmentActionsState,
    type ActionOperations,
    type AppEnvironmentActionStateHandler,
    type AppEnvironmentActionsState,
    type AppPublishInfo,
    type AppUserAuthenticator,
    type DataStore,
    type LocalDataStore,
    type MinimalAppEnvironment,
    type OfflineQueue,
    type QueryableDataStore,
    type YesCodeParameterMap,
} from "@glide/common-core/dist/js/components/types";
import type { Database } from "@glide/common-core/dist/js/Database/core";
import { areTableNamesEqual, type UserProfileTableInfo, type SourceMetadata } from "@glide/type-schema";
import type { EminenceFlags } from "@glide/billing-types";
import { getFeatureFlag } from "@glide/common-core/dist/js/feature-flags";
import type { WriteSourceType } from "@glide/common-core/dist/js/firebase-function-types";
import { supportsOfflineActionQueue } from "@glide/common-core/dist/js/offline-action-queue";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { standalone } from "@glide/common-core/dist/js/support/device";
import { suppressPushNotificationTopic, unsuppressPushNotificationTopic } from "@glide/firebase-stuff";
import type { YesCodeValue } from "@glide/common-core/dist/js/yes-code-types";
import {
    localStorageGetItem,
    localStorageSetItem,
    logError,
    sessionStorageGetItem,
    sessionStorageSetItem,
    Watchable,
} from "@glide/support";
import { callYesCode } from "@glide/yes-code";
import { GeocodeReporterImpl } from "./geocode-reporter";
import { OfflineQueueImpl } from "./offline-queue";
import { type Writable, assert, filterUndefined } from "@glideapps/ts-necessities";
import type { AppData, BaseAppData } from "@glide/plugins";

function sumOperations({ addRowToTable, setColumnsInRow, deleteRow }: ActionOperations): number {
    return addRowToTable + setColumnsInRow + deleteRow;
}

export class WireAppEnvironment extends GeocodeReporterImpl implements MinimalAppEnvironment {
    public readonly appFacilities = getAppFacilities();
    private currentActionOperationsState: AppEnvironmentActionsState = emptyAppEnvironmentActionsState;
    private readonly actionOperationStateSubscribers = new Set<AppEnvironmentActionStateHandler>();
    private maybeQueryableDataStore: QueryableDataStore | undefined;
    private pluginAppData: AppData;

    constructor(
        private readonly features: AppFeatures,
        private sourceMetadataArray: readonly SourceMetadata[] | undefined,
        private userProfile: UserProfileTableInfo | undefined,
        private pluginConfigsArray: readonly PluginConfig[],
        private pluginBaseAppData: BaseAppData,
        public readonly authenticator: AppUserAuthenticator,
        public readonly database: Database,
        public readonly dataStore: DataStore,
        public readonly localDataStore: LocalDataStore,
        public readonly isBuilder: boolean,
        public readonly writeSource: WriteSourceType,
        public readonly getPublishInfo: () => AppPublishInfo,
        private readonly getEminenceFlags: (appID: string) => EminenceFlags
    ) {
        super();
        dataStore.subscribeToOutstandingOperations(this.handleActionOperationStateChange);
        localDataStore.subscribeToOutstandingOperations(this.handleActionOperationStateChange);
        this.pluginAppData = { ...this.pluginBaseAppData, screenSize: new Watchable(undefined) };
    }

    // This must be called before calling any other methods, and it must be
    // called only once.
    public setQueryableDataStore(queryableDataStore: QueryableDataStore): void {
        assert(this.maybeQueryableDataStore === undefined);
        this.maybeQueryableDataStore = queryableDataStore;
        queryableDataStore.subscribeToOutstandingOperations(this.handleActionOperationStateChange);
    }

    public get queryableDataStore(): QueryableDataStore | undefined {
        return this.maybeQueryableDataStore;
    }

    private handleActionOperationStateChange = () => {
        const newState: Writable<AppEnvironmentActionsState> = { ...emptyAppEnvironmentActionsState };
        for (const store of filterUndefined([this.dataStore, this.localDataStore, this.queryableDataStore])) {
            const { started, performed, savingMessageStarted, savingMessagePerformed } =
                store.getActionOutstandingOperations();
            newState.started += sumOperations(started);
            newState.performed += sumOperations(performed);
            newState.savingMessageStarted += sumOperations(savingMessageStarted);
            newState.savingMessagePerformed += sumOperations(savingMessagePerformed);
        }
        this.currentActionOperationsState = newState;
        for (const sub of [...this.actionOperationStateSubscribers]) {
            try {
                sub({ ...this.currentActionOperationsState });
            } catch (e: unknown) {
                logError("Could not send action operation state to subscriber", e);
            }
        }
    };

    public getActionsState(): AppEnvironmentActionsState {
        return { ...this.currentActionOperationsState };
    }

    public subscribeToActionsState(handler: AppEnvironmentActionStateHandler): void {
        const had = this.actionOperationStateSubscribers.has(handler);
        if (!had) {
            this.actionOperationStateSubscribers.add(handler);
            // Doing this synchronously leads to weird React draw cascades
            setTimeout(() => handler(this.getActionsState()), 0);
        }
    }

    public unsubscribeFromActionState(handler: AppEnvironmentActionStateHandler): void {
        this.actionOperationStateSubscribers.delete(handler);
    }

    public get appID(): string {
        return this.authenticator.appID;
    }

    public get appFeatures(): AppFeatures {
        return this.features;
    }

    public get appKind(): AppKind {
        return getAppKindFromFeatures(this.appFeatures);
    }

    public get userProfileTableInfo(): UserProfileTableInfo | undefined {
        return this.userProfile;
    }

    public get sourceMetadata(): readonly SourceMetadata[] | undefined {
        return this.sourceMetadataArray;
    }

    public get pluginConfigs(): readonly PluginConfig[] {
        return this.pluginConfigsArray;
    }

    public get appData(): AppData {
        return this.pluginAppData;
    }

    // FIXME: This method should be async to prevent default Eminence Flags from being returned
    public get eminenceFlags(): EminenceFlags {
        return this.getEminenceFlags(this.appID);
    }

    public get locationSettings(): LocationSettings | undefined {
        return this.appFacilities.locationSettings;
    }

    public callYesCode(
        url: string,
        params: YesCodeParameterMap
    ): YesCodeValue | undefined | Promise<YesCodeValue | undefined> {
        return callYesCode(url, params);
    }

    public updateApp(
        sourceMetadata: readonly SourceMetadata[] | undefined,
        userProfileTableInfo: UserProfileTableInfo | undefined,
        pluginConfigs: readonly PluginConfig[],
        appData: BaseAppData
    ): void {
        this.sourceMetadataArray = sourceMetadata;

        const isSameTable = areTableNamesEqual(this.userProfile?.tableName, userProfileTableInfo?.tableName);
        this.userProfile = userProfileTableInfo;
        if (!isSameTable) {
            this.dataStore.userProfileTableChanged();
        }

        this.pluginAppData = { ...appData, screenSize: this.pluginAppData.screenSize };

        this.pluginConfigsArray = pluginConfigs;
    }

    public suppressNotificationTopic(topic: string): void {
        suppressPushNotificationTopic(this.appID, topic);
    }

    public unsuppressNotificationTopic(topic: string): void {
        unsuppressPushNotificationTopic(this.appID, topic);
    }

    protected setGeocodeQuotaReachedFlag(): void {
        // nothing to do
    }

    public makeReloadResiliencyQueue<T>(
        name: string,
        onOnline: () => void,
        idForItem: (item: T) => string
    ): OfflineQueue<T> | undefined {
        if (this.isBuilder && !getFeatureFlag("forceOfflineQueue")) return undefined;
        return new OfflineQueueImpl(name, onOnline, idForItem, {
            getItem: sessionStorageGetItem,
            setItem: sessionStorageSetItem,
        });
    }

    public makeOfflineQueue<T>(
        name: string,
        onOnline: () => void,
        idForItem: (item: T) => string
    ): OfflineQueue<T> | undefined {
        // We can only support the offline action queue in standalone mode,
        // because our implementation is not safe to use across multiple
        // windows yet.
        if (getFeatureFlag("forceOfflineQueue") || (supportsOfflineActionQueue(this.eminenceFlags) && standalone)) {
            return new OfflineQueueImpl(name, onOnline, idForItem, {
                getItem: localStorageGetItem,
                setItem: localStorageSetItem,
            });
        }

        // If we don't support a real offline queue, we should attempt to use Session Storage
        // to improve resilience against sudden reloads.
        // We can't do this in the builder, though, because it's very easy to accidentally
        // trigger thousands of operations, and trying to serialize all of them will
        // become too computationally expensive to justify the reload resilience.
        return this.makeReloadResiliencyQueue(name, onOnline, idForItem);
    }
}
