import { asMaybeString, getRowColumn } from "@glide/common-core/dist/js/computation-model/data";
import type { Row } from "@glide/computation-model-types";
import { isLoadingValue } from "@glide/computation-model-types";
import { Result } from "@glide/plugins";
import type { TableColumn, TableGlideType } from "@glide/type-schema";
import { getTableColumn, rowIndexColumnName, sheetNameForTable } from "@glide/type-schema";

export function getIDColumnForTable(table: TableGlideType): Result<TableColumn> {
    if (table.rowIDColumn !== undefined) {
        const c = getTableColumn(table, table.rowIDColumn);
        if (c !== undefined) {
            return Result.Ok(c);
        }
    }

    const rowIndex = getTableColumn(table, rowIndexColumnName);
    if (rowIndex !== undefined) {
        return Result.Ok(rowIndex);
    }

    return Result.Fail(`Table ${sheetNameForTable(table)} doesn't have an ID column`);
}

export function getIDForRow(row: Row, idColumn: TableColumn): Result<string> {
    const v = getRowColumn(row, idColumn.name);
    if (isLoadingValue(v)) {
        return Result.FailPermanent(`ID column ${idColumn.name} for row ${row.$rowID} has a loading value`);
    }

    const id = asMaybeString(v);
    if (id === undefined) {
        return Result.FailPermanent(`ID column ${idColumn.name} for row ${row.$rowID} has no value`);
    }

    return Result.Ok(id);
}
