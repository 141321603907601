import type {
    ComputationModel,
    GroundValue,
    LoadedGroundValue,
    LoadedRow,
    LoadingValue,
    PrimitiveValue,
    Row,
    Table,
    RelativePath,
    RootPath,
    RowIndex,
    QueryBase,
    Unbound,
} from "@glide/computation-model-types";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import type { BasePrimitiveValue } from "@glide/data-types";
import type { Database } from "@glide/common-core/dist/js/Database/core";
import type { PaymentInformation } from "@glide/common-core/dist/js/Database";
import type {
    TableName,
    ColumnType,
    Formula,
    SourceColumn,
    TableAndColumn,
    TableGlideType,
    SpecialValueDescription,
} from "@glide/type-schema";
import type {
    ArrayTransform,
    ComponentDescription,
    LegacyPropertyDescription,
    MutatingScreenKind,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import type { WireFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import type { Sound } from "@glide/common-core/dist/js/sound";
import type { ActionNodeInScope, ExistingAppDescriptionContext } from "@glide/function-utils";
import type { DefaultArrayMap } from "@glide/support";
import type { DefaultMap } from "@glideapps/ts-necessities";
import type { WireNavigationAction, WireSubsidiaryScreen } from "./nav-model";
import type { NavigationPath } from "./navigation-path";
import type { OnPopCallback, ParsedScreen } from "./parsed-path";
import type { WireAction, WireComponent, WireEditableValue, WireScreenPosition } from "./types";
import type { AppData, Result } from "@glide/plugins";
import type { WireActionResult } from "./action-result";
import type { WriteSourceType } from "@glide/common-core/dist/js/firebase-function-types";
import type { ActiveScope } from "./scope";

export enum PageScreenTarget {
    Current = "current",
    Main = "main",
    XSmallModal = "x-small-modal",
    SmallModal = "small-modal",
    LargeModal = "large-modal",
    SlideIn = "slide-in",
}

// ##ValueChangeSource:
// `User` means the change was initiated by the user.  We use this in
// follow-up handlers for example to decide whether to trigger on-change
// actions.
export enum ValueChangeSource {
    Internal = "internal",
    User = "user",
}

// These must all easily be implementable over a wire protocol.  In particular
// they can't return values, and they're not expected to execute
// synchronously.
export interface WireFrontendActionCallbacks {
    openLink(url: string): void;
    showToast(success: boolean, message: string): void;
    copyToClipboard(data: string): void;
    playSound(sound: Sound): void;
    showShareSheet(url: string | undefined, message: string | undefined): void;
    signOut(): void;
}

export interface WireBackendCallbacks {
    // These are callbacks that could go to the frontend, but they're
    // synchronous, and not easily made asynchronous.  It's not clear we'll
    // actually ever need them to be async, because we might not need to
    // implement them in the frontend, so we're leaving this for now.
    loadStateValues(key: string): Record<string, unknown>;
    saveStateValues(key: string, values: Record<string, unknown>): void;

    getPaymentInformationForBuyButton(buttonID: string): PaymentInformation | undefined;

    // BUILDER ONLY:

    // If possible, set the preview-as user.  This won't be possible in the
    // player, obviously.
    setPreviewAsUser(): void;

    editComponent?(screenName: string, componentID: string, editor: WireComponentEditor, edit: any): void;
    addMenuScreen?(menuID: string, primaryKeyHash: string, defaultScreenName: string): void;
}

export interface WireValueChangedBackend {
    // This will always return a success result.  The result is for
    // convenience, so an action runner can just return it.
    valueChanged(token: string, value: unknown, source: ValueChangeSource): WireActionResult;
}

// Some of these methods return a `Result` to indicate success/failure.  It
// would seem that a `WireActionResult` would be more appropriate, but that
// type is supposed to carry information about an actual action having
// executed, and these are just helper methods that are used in actions - they
// never have an `ActionDescription`, for example.
// https://github.com/glideapps/glide/pull/26855#discussion_r1593252173
export interface WireActionBackend extends WireValueChangedBackend {
    readonly actionCallbacks: WireFrontendActionCallbacks;
    // `undefined` means the action doesn't have a timeout set
    readonly timesOutAt: Date | undefined;

    invoke(reason: string, runner: WireActionRunner, handledByFrontend: boolean): Promise<WireActionResult>;

    makeBackendForSubAction(): WireRowActionHydrationValueProvider;

    pushFreeScreen(
        screenName: string,
        rows: readonly Row[],
        screenTitle: string | undefined,
        target: PageScreenTarget,
        sourceItemRowID: string | undefined,
        onPop?: OnPopCallback
    ): void;
    pushDefaultClassScreen(
        tableName: TableName,
        row: Row,
        screenTitle: string | undefined,
        target: PageScreenTarget,
        sourceItemRowID: string | undefined
    ): void;
    pushDefaultAddScreen(tableName: TableName, target: PageScreenTarget): void;
    pushDefaultEditScreen(tableName: TableName, row: Row, target: PageScreenTarget): void;
    pushFormScreen(
        screenName: string,
        row: Row | undefined,
        screenTitle: string | undefined,
        target: PageScreenTarget
    ): void;
    pushDefaultArrayScreen(
        tableName: TableName,
        table: Table,
        screenTitle: string | undefined,
        target: PageScreenTarget
    ): void;
    pushMenuScreen(
        defaultScreenName: string,
        menuID: string,
        primaryKey: string,
        row: Row,
        screenTitle: string | undefined,
        sourceItemRowID: string | undefined
    ): void;

    closeScreen(position: WireScreenPosition.Modal): void;

    navigateToTab(tabScreenName: string, handleFlyouts: boolean): Result;
    navigateToPath(path: NavigationPath, navigationAction: WireNavigationAction): Result;
    navigateToUserProfile(): Result;
    navigateUp(): Result;
    dragBack(): Result;

    reshuffle(): void;

    // This will always return the player's row
    addRow(
        tableName: TableName,
        row: Row,
        dataStoreName: string | undefined,
        awaitSend: boolean
    ): Promise<Result<Row | undefined>>;
    setColumnsInRow(
        tableName: TableName,
        row: Row,
        updates: Record<string, LoadedGroundValue>,
        withDebounce: boolean,
        existingJobID: string | undefined,
        // If we have a confirmed version then we don't need to listen to the
        // action document for confirmation.
        confirmedAtVersion: number | undefined
    ): Promise<Result>;
    // `rows` can include invisible rows
    deleteRows(tableName: TableName, rows: readonly Row[], awaitSend: boolean): Promise<Result>;
    deleteRowAtIndex(tableName: TableName, rowIndex: RowIndex, awaitSend: boolean): Promise<Result>;

    addSpecialScreenRow(row: LoadedRow): void;

    saveEditScreenChanges(tableName: TableName, screenContext: HydratedScreenContext): Promise<Result>;

    clearSubComponentStates(stateSaveKey: string): void;

    signIn(type: "sign-in" | "sign-up", onPop: (() => void) | undefined): void;

    // This will use `newRow` as the output row, if it's given (for Form
    // screens), otherwise (for Edit screens) it will use its input row.
    makeActionBackendForOnSubmit(newRow: Row | undefined): WireActionBackend;

    // We currently only use this to run the ##firstListItemActionToRun.
    runAction(token: string): void;

    resetQuery(tableName: TableName): Result;

    logActionResult(actionID: string | undefined, startedAt: Date, finishedAt: Date, result: WireActionResult): void;

    uploadFile(name: string, type: string, contents: string | ArrayBuffer): Promise<Result<string>>;

    withBusy<T>(message: string, f: () => Promise<T>): Promise<T>;

    getAppUserID(): string | undefined;

    getFormFactor(): WireFormFactor;

    readonly appData: AppData;
}

// Allows hydration to influence builder metadata
// Will be undefined on player
export interface BuilderCallbacks {
    // Allows a component that manages its own row/table (e.g. all inline
    // lists, and in particular swipe) to override the table data for the
    // purposes of the builder. For the purposes of the data pad, the first
    // element in the array should be the currently visible row.  The
    // peek-a-boo will display all rows.
    //
    // This can be called multiple times during hydration, and the last one
    // wins.  The Inline List calls this when it's hydrating, for example, but
    // then the content hydrator can call it again if it needs to override it.
    //
    // `componentID` can be undefined if it's an array screen.
    //
    // FIXME: Set the `tableType` during inflation - hydration shouldn't have
    // anything to do with table types.
    // It'd be nice if this also works with Query tables as discused here in
    // https://github.com/glideapps/glide/pull/27526#discussion_r1629445418
    // so if we decide to expand this interface, we should consider that.
    overrideTableData(componentID: string | undefined, table: Table, tableType: TableGlideType): void;
}

export interface HydratedScreenContext {
    // This is always a visible row
    // FIXME: Should this also be a `Table`?
    readonly inputRows: readonly Row[];
    // This row, if present, is always invisible, and has to be deleted from
    // the table when the screen is popped.  All edit screens will have this
    // set. For regular detail screens this is not set, and the input row also
    // acts as the output row.  Unfortunately this is wrong because it makes
    // us guess whether we have the ##correctOutputRow if we have to fall back
    // to the input row.
    readonly outputRow: Row | undefined;
}

export interface HydratedRowContext extends HydratedScreenContext {
    // This is always a visible row
    readonly containingScreenRow: Row | undefined;
}

// When actions run in a sequence, a false return value stops the sequence.
// The `dummy` is here just to make sure we always invoke runners via
// `WireActionBackend.invoke`.
export type WireActionRunner = (
    b: WireActionBackend,
    handledByFrontend: boolean,
    dummy: 123
) => Promise<WireActionResult>;
export type WireActionRunnerWithURL = [runner: WireActionRunner, url: string | undefined];

export type WireActionHydrationResult =
    // These two are the same, the first one is just here for convenience.
    WireActionRunner | WireActionRunnerWithURL | WireActionResult;

// `skipLoading` means to ignore values and actions that are loading, versus
// propagating up the loading result.  We do this when we hydrate an action
// with a timeout and it keeps resulting in loading.  Once the timeout is up,
// we hydrate it with `skipLoading`.
export type WireActionHydrator = (
    hb: WireRowActionHydrationValueProvider,
    skipLoading: boolean,
    detailScreenTitle: string | undefined
) => WireActionHydrationResult;

export interface WireComponentFlags {
    // FIXME: Make this optional for non-edit components, too
    readonly isValid: boolean;
    // `undefined` means it's not an edit component
    readonly editsInContext?: boolean;
    // `undefined` means it's not an edit component
    readonly hasValue?: boolean;
    // In Apps, this must be true iff the component is an Inline List with
    // search turned on that has at least one item before search.
    readonly canBeSearched?: boolean;
}

export interface WireComponentWithFlags extends WireComponentFlags {
    readonly component: WireComponent | undefined;
}

export type WireHydrationFollowUp = (ab: WireActionBackend) => void;

export interface WireComponentHydrationResult extends WireComponentWithFlags {
    readonly subsidiaryScreen?: WireSubsidiaryScreen;
    readonly followUp?: WireHydrationFollowUp;
    readonly editor?: WireComponentEditor;
}

export type WireFilterValueAndFormatGetter = (
    row: Row,
    hb: WireTableTransformValueProvider
) => [PrimitiveValue, string][];

interface DynamicFilterValues {
    readonly valueAndFormatGetter: WireFilterValueAndFormatGetter;
    readonly filterValues: readonly PrimitiveValue[];
    readonly filterEditable: WireEditableValue<readonly PrimitiveValue[]>;
}

export type WireComponentPreHydrationResult = [shouldHydrate: boolean, followUp: WireHydrationFollowUp | undefined];

export interface WireRowComponentHydrator {
    // This will be called whether the component is visible or not.
    preHydrate?(): WireComponentPreHydrationResult;

    // This will be called only if the component is visible.
    hydrate(): WireComponentHydrationResult | undefined;
}

export interface WireRowComponentHydratorConstructor {
    // Only relevant for apps which have a screen search bar.
    readonly wantsSearch: boolean;

    new (
        hb: WireRowComponentHydrationBackend,
        builderCallbacks: BuilderCallbacks | undefined
    ): WireRowComponentHydrator;
}

export interface WireTableComponentHydrationResult extends WireComponentHydrationResult {
    readonly firstListItemActionToRun?: WireAction;
}

export interface WireTableComponentPreHydrator {
    // This will be called whether the component is visible or not.
    preHydrate?(thb: WireTableComponentHydrationBackend): WireComponentPreHydrationResult;
}

export interface WireTableComponentHydrator extends WireTableComponentPreHydrator {
    // This will be called if the data comes from a query.  We currently use
    // this to add the additional sort for grouping.  Returning `undefined`
    // means don't hydrate the component.  This is called before `preHydrate`.
    modifyQuery?(query: QueryBase): QueryBase | undefined;

    // These will be called only if the component is visible.
    prefilterRows?(thb: WireTableComponentHydrationBackend): WireTableComponentHydrationBackend | undefined;
    hydrate(
        thb: WireTableComponentHydrationBackend,
        // Right now only used by Kanban
        dynamicFilterResult: DynamicFilterResult | undefined,
        limit: number | undefined
    ): WireTableComponentHydrationResult | undefined;
}

export interface WireTableComponentQueryHydrator extends WireTableComponentPreHydrator {
    hydrateForQuery(): WireTableComponentHydrationResult | undefined;
}

export interface DynamicFilterEntry {
    // null means "All"
    readonly displayValue: string | null;
    readonly onToggle: WireAction;
    readonly selected: boolean;
    readonly count: number | undefined;
}

export interface WireDynamicFilter {
    // At least one of these has to be set.  If `entries` is present, then
    // `onOpen` can be ignored for the time being.  We might want to change
    // that when/if we do the filter slide-in.
    readonly entries?: readonly DynamicFilterEntry[];
    readonly onOpen?: WireAction;

    // The text to display in the filter button.  `null` means "All"
    readonly displayValue: string | null;
}

export interface DynamicFilterResult {
    readonly dynamicFilterValues: DynamicFilterValues;
    readonly dynamicFilter: WireDynamicFilter;
    readonly table: Table;
    readonly isActive: boolean; // `false` if "all" is selected
}

export interface StringMultipleDynamicFilterEntry {
    readonly kind: "string";
    readonly displayValue: string;
    readonly onToggle: WireAction;
    readonly selected: boolean;
}

interface StringDynamicFilterEntriesWithCaption {
    readonly kind: "string";
    readonly filterEntries: readonly StringMultipleDynamicFilterEntry[];
    readonly caption: string;
}

export interface BooleanMultipleDynamicFilterEntry {
    readonly kind: "boolean";
    readonly onToggle: WireAction;
    readonly selected: boolean;
}

interface BooleanDynamicFilterEntriesWithCaption {
    readonly kind: "boolean";
    readonly filterEntry: BooleanMultipleDynamicFilterEntry;
    readonly caption: string;
}

export type DynamicFilterEntriesWithCaption =
    | StringDynamicFilterEntriesWithCaption
    | BooleanDynamicFilterEntriesWithCaption;

export interface WireMultipleDynamicFilters {
    readonly isLoading: boolean;
    readonly entries: readonly DynamicFilterEntriesWithCaption[];
    readonly isOpen: WireEditableValue<boolean>;
    readonly clearAction: WireAction | undefined;
    readonly numFiltersSelected: number | undefined;
}

export type RowBackends = DefaultMap<Row, WireRowComponentHydrationBackend>;

export interface WireTableComponentHydratorConstructor {
    // For array screens the `rhb` will be `undefined`
    makeHydrator(
        rhb: WireRowComponentHydrationBackend | undefined,
        rowBackends: RowBackends,
        searchActive: boolean,
        builderCallbacks: BuilderCallbacks | undefined
    ): WireTableComponentHydrator;

    makeHydratorForQuery?(
        rhb: WireRowComponentHydrationBackend,
        query: QueryBase,
        contentHB: WireTableComponentHydrationBackend | undefined,
        searchActive: boolean,
        pageIndexState: WireAlwaysEditableValue<number>,
        selectedPageSize: number | undefined,
        rowBackends: RowBackends
    ): WireTableComponentQueryHydrator;
}

export type WireValueGetter = (hb: WireRowHydrationValueProvider) => GroundValue | Unbound;
export type WireRowComponentValueGetter = (hb: WireRowComponentHydrationBackend) => GroundValue | Unbound;
export type WirePredicate = (hb: WireRowHydrationValueProvider) => boolean | LoadingValue;

export interface ContextTableTypes extends InputOutputTables {
    readonly containingScreen: TableGlideType | undefined;
}

export interface ValueGetterOptions {
    readonly inOutputRow?: boolean; // default: false
    readonly columnFirst?: boolean; // default: true
}

export type WireValueGetterGeneric<T> = (hb: WireRowHydrationValueProvider) => T | Unbound;

export type WireRowGetter = (hb: WireRowHydrationValueProvider) => Row | LoadingValue | undefined;

/**
 * `type` will be `undefined` if the value is not bound or invalid.  In that
 * case `errorMessage` might be set.  `hasFormat` is set if the column has a
 * format, whether or not the getter gets the raw or the formatted value.
 */
export type InflatedProperty<T> = readonly [
    getter: WireValueGetterGeneric<T>,
    type: ColumnType | undefined,
    hasFormat: boolean,
    errorMessage?: string
];

// type `undefined` means the value is unbound
export interface InflatedColumn {
    subscribe(ttvp: WireTableTransformValueProvider): void;
    getter(r: Row, ttvp: WireTableTransformValueProvider): GroundValue;
    readonly type: ColumnType | undefined;
    readonly isGlobal: boolean;
    readonly hasFormat: boolean;
}

// If `tableAndColumn` returned is `undefined` then there's an error.
export interface ValueSetterResult {
    readonly tableAndColumn: TableAndColumn | undefined;
    readonly tokenMaker: (
        hb: WireRowComponentHydrationBackend,
        followUp?: ValueChangeFollowUp,
        primaryKeyColumnName?: string,
        keyPrefix?: string
    ) => string | false | undefined;
    readonly setterMaker: (
        vp: WireRowHydrationValueProvider
    ) => ((ab: WireActionBackend, value: LoadedGroundValue) => Promise<Result>) | LoadingValue | undefined;
    readonly isInContext: boolean;
}

export interface WireValueInflationBackend {
    readonly adc: ExistingAppDescriptionContext;
    readonly appFacilities: ActionAppFacilities;
    readonly tables: ContextTableTypes;
    readonly mutatingScreenKind: MutatingScreenKind | undefined;
    // We only hydrate component IDs when hydrating for the builder.
    readonly forBuilder: boolean;
    // When we run for automations we write actions pretending to be the data
    // editor, so we can write to row-owned rows without needing an app user
    // ID.
    readonly forAutomation: boolean;

    readonly writeSource: WriteSourceType;

    getUserProfileRowGetter(): [WireRowGetter, TableGlideType] | undefined;

    // This will return `undefined` if the column doesn't exist
    getValueGetterForColumnInRow(
        columnName: string,
        inOutputTable: boolean,
        withFormat: boolean
    ): InflatedColumn | undefined;
    getValueGetterForSourceColumn(
        sourceColumn: SourceColumn,
        inOutputRow: boolean,
        withFormat: boolean
    ): InflatedProperty<GroundValue>;
    getValueGetterForProperty(
        desc: LegacyPropertyDescription | undefined,
        withFormat: boolean,
        opts?: ValueGetterOptions
    ): InflatedProperty<GroundValue>;

    getValueSetterForProperty(desc: LegacyPropertyDescription | undefined, key: string): ValueSetterResult;

    // This returns `undefined` in case of an error
    inflatePredicate(
        predicate: Formula,
        inOutputRow: boolean,
        // ##shortCircuitPredicateEvaluation:
        // We pass `false` when we need to make sure that each evaluation
        // subscribes to everything that might be needed in any other case.
        // Right now it's only used in the "wait for condition" action.
        // TODO: we can always short-circuit the `true` case, because at that
        // point we're done
        shortCircuit: boolean
    ): [predicate: WirePredicate, numConditions: number] | undefined;
    inflateFilters(
        filters: readonly ArrayTransform[],
        inOutputRow: boolean
    ): [predicate: WirePredicate, numConditions: number];
}

export type WireTableGetter = (hb: WireRowHydrationValueProvider) => Table | LoadingValue | undefined;

export interface InflatedTableGetter {
    readonly getter: WireTableBackendGetter;
    readonly ib: WireInflationBackend;
    readonly limitTransform: WireTableTransformer;
    readonly limit: number | undefined;
    // These are just for statistics
    readonly numFilters: number;
    readonly hasExplicitSort: boolean;
}

export interface WireActionInflationBackend extends WireValueInflationBackend {
    makeInflationBackendForTables(
        tables: ContextTableTypes,
        // In most cases this should be the creating IB's
        // `mutatingScreenKind`.
        mutatingScreenKind: MutatingScreenKind | undefined
    ): WireActionInflationBackend;

    getTableGetter(
        table: LegacyPropertyDescription | TableName,
        allowSingleRelations: boolean
    ):
        | [
              getter: WireTableGetter,
              tableType: TableGlideType,
              numFilters: number,
              selectedColumns?: ReadonlySet<string>,
              nameOverrides?: Record<string, string>
          ]
        | undefined;

    getFormulaGetter(formula: Formula): InflatedProperty<GroundValue>;

    getActionNodesInScope(): ActionNodeInScope[];
}

export interface SearchableColumns {
    // This does not include "slow" columns
    // table name -> column names
    readonly columnsUsedByTable: DefaultArrayMap<TableName, Set<string>>;
    // component ID -> column names
    readonly columnsUsedByInlineLists: DefaultMap<string, Set<string>>;
}

export interface WireInflationBackend extends WireActionInflationBackend {
    readonly db: Database | undefined;
    readonly appFacilities: ActionAppFacilities;
    readonly computationModel: ComputationModel;
    readonly searchableColumns: SearchableColumns;

    makeInflationBackendForTables(
        tables: ContextTableTypes,
        // In most cases this should be the creating IB's
        // `mutatingScreenKind`.
        mutatingScreenKind: MutatingScreenKind | undefined
    ): WireInflationBackend;

    getTableGetterWithTransforms(
        desc: LegacyPropertyDescription,
        transforms: readonly ArrayTransform[],
        applyLimit: boolean,
        allowSingleRelations: boolean
    ): InflatedTableGetter | undefined;

    getQueryGetter(
        desc: LegacyPropertyDescription,
        transforms: readonly ArrayTransform[],
        allowSingleRelations: boolean,
        // If `onlyUseQueries` is `true`, we'll treat tables as queries.
        onlyUseQueries: boolean
    ): [getter: WireQueryGetter, ib: WireInflationBackend, isQueryableSource: boolean] | undefined;

    getValueGetterForSpecialValue(kind: SpecialValueDescription): InflatedProperty<GroundValue>;
}

export type WireTableBackendGetter = (
    hb: WireRowComponentHydrationBackend
) => WireTableComponentHydrationBackend | LoadingValue | undefined;

export type WireQueryGetter = (hb: WireRowComponentHydrationBackend) => QueryBase | Table | LoadingValue | undefined;

export interface WireHydrationBackend {
    // The `key` must be unique within the hydration unit, i.e. component,
    // subcomponent, or row
    registerAction(key: string, run: WireActionRunner): string;
}

interface WireGlobalValueGetter {
    // `column` is either the name of a column we want to subscribe to, or
    // `true`, in which case a change in any column will trigger a
    // rehydration.  If this is a value getter for components, it will unwrap
    // loading values with display values, i.e. the caller won't have to deal
    // with that.  If it's a value getter for actions, it will not unwrap
    // them, and neither should the caller, because actions should never act
    // on loading values.
    getGlobalValue(tableName: TableName | undefined, rootPath: RootPath, column: string | true): GroundValue;
}

export interface WireTableTransformValueProvider extends WireGlobalValueGetter {
    requireColumnInTable(rootPath: RootPath, columnPath: RelativePath): void;
    makeRowValueProvider(row: Row): WireRowHydrationValueProvider;
    getShuffleOrder(): DefaultMap<string, number>; // row id -> order
}

export type WireTableTransformer = (ttvp: WireTableTransformValueProvider, table: Table) => Table;

export interface WireRowHydrationValueProvider extends WireGlobalValueGetter {
    // FIXME: This need to be behind accessors, too, to enable subscribing.
    // Even checking against `undefined` must include some kind of
    // subscription.
    readonly rowContext: HydratedRowContext | undefined;

    // FIXME: These are just used internally in the backend and shouldn't be
    // here, probably.
    getColumnInRow(tableName: TableName, rootPath: RootPath, row: Row, columnPath: RelativePath): GroundValue;

    makeHydrationBackendForRow(
        inputRow: Row | undefined,
        outputRow: Row | undefined,
        tables: InputOutputTables | undefined
    ): WireRowHydrationValueProvider;

    resolveQueryAsTable(query: QueryBase): Table | LoadingValue | undefined;
    // Returns whether `getData` was eventually successful, i.e. it'll return
    // `false` iff it ran into the timeout.
    // NOTE: `getData` must go through all the data it needs every time,
    // otherwise we won't subscribe to it.
    listenForChanges(timeoutMS: number | undefined, getData: () => boolean): Promise<boolean>;

    readonly activeScopes: readonly ActiveScope[];
}

export interface WireRowActionHydrationValueProvider extends WireRowHydrationValueProvider {
    getIsUserSignedIn(): boolean;
    getIsTabVisible(tabScreenName: string): boolean;
    getIsModalVisible(): boolean;
    getIsOnline(): boolean;

    fetchTable(tableName: TableName): void;

    makeHydrationBackendForRow(
        inputRow: Row | undefined,
        outputRow: Row | undefined,
        tables: InputOutputTables | undefined
    ): WireRowActionHydrationValueProvider;

    makeHydrationBackendForAction(): WireRowActionHydrationValueProvider;
}

export interface WireAlwaysEditableValue<T> extends WireEditableValue<T> {
    readonly onChangeToken: string;
}

export interface WireHydratedSubComponents {
    readonly components: readonly (WireComponent | null)[];
    readonly editsInContext: boolean;
    readonly allValid: boolean;
    readonly haveValues: boolean | undefined;
    readonly componentsRowContext: HydratedRowContext | undefined;
    readonly subsidiaryScreen: WireSubsidiaryScreen | undefined;
}

export type ValueChangeFollowUp = (ab: WireActionBackend, source: ValueChangeSource) => Promise<void>;

export type WireSubComponentsGetter = (c: WireComponent) => readonly (WireComponent | null)[];

export interface WireScreenStateProvider {
    getScreenState<T extends GroundValue>(
        name: string,
        validate: (v: GroundValue) => v is T,
        defaultValue: T
    ): WireEditableValue<T>;
}

export interface WireStateSaver {
    // `shouldSave` means save the state in local storage so that it persists
    // across sessions.  `convert`, if present, converts the value coming in
    // from the frontend to whatever the backend actually wants to store.
    getState<T>(
        name: string,
        // This must be defined if `shouldSave` is set.
        validate: ((v: unknown) => v is T) | undefined,
        defaultValue: T,
        shouldSave: boolean,
        debounceMS?: number
    ): WireAlwaysEditableValue<T>;
}

export type WireComponentEditor = (desc: ComponentDescription, edit: any) => ComponentDescription;

interface WireTableTransformValueProviderMaker {
    makeTableTransformValueProvider(tableName: TableName): WireTableTransformValueProvider;
}

export interface WireRowComponentHydrationBackend
    extends WireHydrationBackend,
        WireRowActionHydrationValueProvider,
        WireStateSaver,
        WireScreenStateProvider,
        WireTableTransformValueProviderMaker {
    readonly stateSaveKey: string | undefined;
    readonly screenPosition: WireScreenPosition;
    readonly currentScreenTitle: string | undefined;

    getFormFactor(): WireFormFactor;

    // If we can't identify a row, i.e. know where to write to, we can't edit
    // it.
    isRowIdentified(outputRow: boolean): boolean;
    // `false` means there's an error.  `undefined` means this particular row
    // can't be edited.  Generally that means that
    registerOnValueChange(
        // Must be unique within the component or subcomponent, or row
        key: string,
        columnName: string,
        primaryKeyColumnName?: string,
        followUp?: ValueChangeFollowUp
    ): string | false | undefined;

    registerOnSpecialScreenRowValueChange(
        // Must be unique within the component or subcomponent, or row
        key: string,
        row: Row,
        columnName: string,
        followUp?: ValueChangeFollowUp
    ): string;

    makeHydrationBackendForRow(
        inputRow: Row | undefined,
        outputRow: Row | undefined,
        tables: InputOutputTables | undefined
    ): WireRowComponentHydrationBackend;
    // We don't technically need this anymore because of the way the
    // ##onSubmitActionContext is currently implemented.
    makeHydrationBackendForOutputRow(): WireRowComponentHydrationBackend | undefined;
    makeHydrationBackendForTable(tableType: TableGlideType, table: Table): WireTableComponentHydrationBackend;

    // Returns a hydration backend without an input row, and where the
    // containing screen row is this backend's input row.
    makeHydrationBackendForQuery(tableType: TableGlideType): WireRowComponentHydrationBackend;

    getScreenTitle(screen: ParsedScreen): string | null | undefined;
    getParsedPath(): NavigationPath;

    // May only be called at most once per component.  If `outputTable` is
    // defined, sub-components will be hydrated with an invisible output row
    // from that table.  We use that for form containers.

    // ##subComponentsStateName:
    // `name` must match the name of the property for the subcomponents in the
    // wire component.
    hydrateSubComponents(
        name: string,
        hydrators: readonly WireComponentHydratorWithID[],
        outputTable: TableGlideType | undefined,
        getSubComponents: WireSubComponentsGetter
    ): WireHydratedSubComponents;

    useEffect(subscribe: (ab: WireActionBackend) => () => void, dependencies: readonly BasePrimitiveValue[]): void;
}

export interface WireTableComponentHydrationBackend
    extends WireHydrationBackend,
        WireStateSaver,
        WireScreenStateProvider,
        WireGlobalValueGetter,
        WireTableTransformValueProviderMaker {
    readonly isArrayScreen: boolean;
    readonly stateSaveKey: string | undefined;
    // Used for map quotas
    readonly quotaKey: string;
    readonly screenPosition: WireScreenPosition;
    // Just for statistics
    readonly numRowsBeforeLimit: number;

    readonly tableScreenContext: Table;

    getFormFactor(): WireFormFactor;

    getIsOnline(): boolean;

    makeHydrationBackendForRow(row: Row): WireRowComponentHydrationBackend;

    // After calling this the caller must not use `this` anymore.
    withTable(table: Table): this;

    // Can be called multiple times, but each time `name` must be different,
    // and should be the same from one hydration to the next for the same
    // sub-components.  `name` must match the name of the property for the
    // subcomponents in the wire component.
    hydrateSubComponents(
        name: string,
        row: Row,
        hydrators: readonly WireComponentHydratorWithID[],
        getSubComponents: WireSubComponentsGetter
    ): WireHydratedSubComponents;
}

export type WireComponentHydratorWithID = [
    hydrator: WireRowComponentHydratorConstructor,
    componentID: string | undefined
];
