import {
    type CurrentLocationListener,
    listenToCurrentLocation,
    stopListeningToCurrentLocation,
} from "@glide/common-core/dist/js/components/current-location";
import { isKeyRefObject } from "@glide/common-core/dist/js/components/data";
import {
    type GroundValue,
    type LoadedGroundValue,
    type LoadingValue,
    type Row,
    MutableTable,
    Table,
    isLoadingValue,
    isResumableLoadingValue,
    makeLoadingValue,
    type Path,
    type RelativePath,
    type RootPath,
    type LoadingValueWrapper,
    makeLoadingValueWithDisplayValue,
    fullDirt,
    isColumnPath,
    unwrapLoadingValue,
    getSymbolicRepresentationForPath,
    isRootPath,
    isTopLevelPath,
    makeKeyPath,
    makeRootPath,
    type AsyncComputation,
    type Computation,
    type Dirt,
    type DirtyState,
    type Handler,
    type IncomingSlot,
    type MapperState,
    type Namespace,
    type ProcessDirtProcessor,
    type QueryResolveInfo,
    type RootPathResolver,
    type TableAggregateComputation,
    type TableAggregateDataProvider,
    type BaseRowIndex,
    isBaseRowIndex,
    type SerializedQuery,
    Query,
    isQuery,
    type ComputationError,
    asMaybeString,
} from "@glide/computation-model-types";
import {
    asMaybeNumber,
    asPrimitive,
    asTable,
    deconstructTableColumnPath,
    forEachItem,
    forEachItemResumable,
    getArrayItem,
    getRowColumn,
    getSymbolicRepresentationForGroundValue,
    isArrayValue,
    isTable,
    setThunkColumn,
    tableForEach,
    loadedDefinedMap,
} from "@glide/common-core/dist/js/computation-model/data";
import { convertToRelationKey } from "@glide/common-core/dist/js/computation-model/relation-keys";
import { type TableName, nativeTableRowIDColumnName, rowIndexColumnName, type TableColumn } from "@glide/type-schema";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import type { Ref } from "@glide/common-core/dist/js/ref";
import { GlideDateTime } from "@glide/data-types";
import {
    assert,
    assertNever,
    defined,
    DefaultMap,
    exceptionToString,
    filterUndefined,
    hasOwnProperty,
    sleep,
} from "@glideapps/ts-necessities";
import type { ChangeObservable } from "@glide/support";
import {
    ConstantChangeObservable,
    areSetsOverlapping,
    isArray,
    isChangeObservable,
    MappingChangeObservable,
} from "@glide/support";
import sortBy from "lodash/sortBy";
import { v4 as uuid } from "uuid";
import { makeDirtyState } from "./dirty-state";
import { dirtyFromTheStart } from "./globals";
import { PluginError } from "./support";
import { applySort, areConditionsTrueForRow, evaluateGroupBy } from "@glide/query-conditions";
import { follow, getValueAt, makeWrappedComputationValueGetters } from "./getters";
import { makeLoadingValueWrapper } from "./loading-value";

export const localQueryableRollupLimit = 100;

function containerAsMutatingArray(tableOrArray: LoadedGroundValue): readonly LoadedGroundValue[] | undefined {
    if (isTable(tableOrArray)) {
        return tableOrArray.asMutatingArray();
    } else if (isArrayValue(tableOrArray)) {
        return tableOrArray;
    } else {
        return undefined;
    }
}

// Dirt:
// * never pushes dirt
export class ConstantValueHandler implements Handler {
    constructor(private readonly _value: GroundValue) {}

    public getSlots(): readonly IncomingSlot[] {
        return [];
    }

    public recompute(): GroundValue {
        return this._value;
    }

    public setDirty(): void {
        return;
    }

    public get isDirty(): boolean {
        return false;
    }

    public get symbolicRepresentation(): string {
        return `constant ${getSymbolicRepresentationForGroundValue(this._value)}`;
    }
}

export class MutableLocalValueHandler implements Handler {
    private _ns: Namespace | undefined;

    constructor(private _value?: GroundValue) {}

    // Returns whether the value changed
    public setValue(v: GroundValue): boolean {
        // Slight optimization: we don't have to push dirt if the _value never
        // changed.  FIXME: Compare `GlideDateTime`s and `GlideJSON`s, too.
        if (this._value === v) return false;

        this._value = v;
        this._ns?.pushDirt(this, fullDirt);
        return true;
    }

    public getSlots(): readonly IncomingSlot[] {
        return [];
    }

    public recompute(): GroundValue {
        return this._value;
    }

    public setDirty(): void {
        return;
    }

    public get isDirty(): boolean {
        return false;
    }

    public get symbolicRepresentation(): string {
        return `mutable local ${getSymbolicRepresentationForGroundValue(this._value)}`;
    }

    public connect(ns: Namespace) {
        assert(this._ns === undefined);
        this._ns = ns;
    }

    public disconnect(ns: Namespace) {
        if (this._ns === undefined) return;
        assert(this._ns === ns);
        this._ns = undefined;
    }
}

export class GetRowHandler implements Handler {
    private readonly _slots: readonly IncomingSlot[];
    private _isDirty = dirtyFromTheStart;

    constructor(private readonly _tablePath: RootPath, private readonly _rowIDPath: RootPath) {
        this._slots = [
            {
                sourcePath: _tablePath,
                process: this.processTableDirt,
            },
            {
                sourcePath: _rowIDPath,
                process: this.processRowIDDirt,
            },
        ];
    }

    public getSlots(): readonly IncomingSlot[] {
        return this._slots;
    }

    private processTableDirt = (d: Dirt): boolean => {
        if (this._isDirty) return false;
        if (d.columns === true || d.columns.has(nativeTableRowIDColumnName)) {
            this._isDirty = true;
            return true;
        }
        return false;
    };

    private processRowIDDirt = (): boolean => {
        if (this._isDirty) return false;
        this._isDirty = true;
        return true;
    };

    public recompute(ns: Namespace): GroundValue {
        const wrapper = makeLoadingValueWrapper();
        const tableValue = wrapper.unwrap(ns.get(this._tablePath));
        const rowID = loadedDefinedMap(wrapper.unwrap(ns.get(this._rowIDPath)), asMaybeString);
        let row: Row | LoadingValue | undefined;
        if (isLoadingValue(tableValue) || isLoadingValue(rowID)) {
            row = makeLoadingValue();
        } else if (isTable(tableValue) && typeof rowID === "string") {
            row = tableValue.get(rowID);
        }
        if (this._isDirty) {
            ns.pushDirt(this, fullDirt);
            this._isDirty = false;
        }
        return wrapper.wrap(row);
    }

    public setDirty(): void {
        this._isDirty = true;
    }

    public get isDirty(): boolean {
        return this._isDirty;
    }

    public get symbolicRepresentation(): string {
        return `get-row table: ${getSymbolicRepresentationForPath(
            this._tablePath
        )} rowID: ${getSymbolicRepresentationForPath(this._rowIDPath)}`;
    }
}

// Makes slots for all the root paths in `paths`.
function makeSlotsForPaths(
    paths: readonly Path[],
    panicOnNonRootPaths: boolean,
    processDirt: (d: Dirt) => boolean
): readonly IncomingSlot[] {
    const keys = new DefaultMap<string, RootPath[]>(() => []);
    for (const p of paths) {
        if (!isRootPath(p)) {
            assert(!panicOnNonRootPaths);
            continue;
        }
        keys.get(p.rest.key).push(p);
    }

    return Array.from(keys.entries()).map(([k, ps]) => ({
        sourcePath: makeRootPath(k),
        process: d => {
            if (d.columns !== true) {
                // ##ncmFilterLookupDirt:
                // We're getting dirt for a set of columns.  We only
                // accept it if either the path specifies a full table, or
                // it specifies a column in the table and that column is
                // in the set of dirty columns.
                let found = false;
                for (const p of ps) {
                    if (p.rest.rest === undefined) {
                        found = true;
                        break;
                    }
                    assert(isColumnPath(p.rest.rest));
                    if (d.columns.has(p.rest.rest.column)) {
                        found = true;
                        break;
                    }
                }
                if (!found) return false;
            }
            return processDirt(d);
        },
    }));
}

// This class has some boilerplate for handlers that process one main
// table a bunch of ancillary data.
abstract class TableAndPathsHandler implements Handler {
    private readonly _slots: readonly IncomingSlot[];

    // The callback for `tablePath` is `processTableDirt`.  All the paths
    // in `paths` use `processOtherDirt`.
    constructor(tablePath: RootPath, paths: readonly Path[]) {
        const tableSlot: IncomingSlot = { sourcePath: tablePath, process: d => this.processTableDirt(d) };
        const otherSlots = makeSlotsForPaths(paths, false, d => this.processOtherDirt(d));
        this._slots = [tableSlot, ...otherSlots];
    }

    private get tablePath(): RootPath {
        return defined(this._slots[0]).sourcePath;
    }

    // The main table.  This returns an unwrapped value.
    protected getTable(ns: Namespace, wrapper: LoadingValueWrapper): Table | LoadingValue {
        const tableValue = wrapper.unwrap(ns.get(this.tablePath, true));
        if (isLoadingValue(tableValue)) {
            return tableValue;
        }

        return asTable(tableValue);
    }

    public getSlots(): readonly IncomingSlot[] {
        return this._slots;
    }

    protected abstract processTableDirt(d: Dirt): boolean;
    protected abstract processOtherDirt(d: Dirt): boolean;

    public abstract recompute(ns: Namespace): GroundValue;

    public abstract setDirty(): void;

    public abstract get isDirty(): boolean;
    public abstract get symbolicRepresentation(): string;
}

enum RecomputeMembershipLevel {
    None,
    DirtyRows,
    All,
}

// Handler which produces a filtered version of a given table.
//
// Dirt:
// * pushes dirt when a row becomes included/excluded
// * pushes dirt from changes in included rows
abstract class FilteringHandler extends TableAndPathsHandler {
    // Subclasses must check this in `needRecomputeMembership`, but must not
    // modify it.
    protected readonly tableDirtyState = makeDirtyState(true);

    private _lastResult: Table | LoadingValue = makeLoadingValue();

    protected abstract isRowIncluded(ns: Namespace, row: Row, wrapper: LoadingValueWrapper): boolean;
    protected abstract get needRecomputeMembership(): RecomputeMembershipLevel;

    constructor(
        tablePath: RootPath,
        paths: readonly Path[],
        private readonly _sortKey: RelativePath | undefined,
        private readonly _fallbackRowID: string | undefined
    ) {
        super(tablePath, paths);
    }

    protected processTableDirt(d: Dirt): boolean {
        return this.tableDirtyState.add(d);
    }

    public recompute(ns: Namespace): GroundValue {
        const outgoing = makeDirtyState(true);
        // A filtering handler produces a new table that includes, or
        // excludes, rows from the original table based on a predicate.  The
        // result table is loading if the original table is loading, or if any
        // of the predicates depend on a loading value.  That's why we only
        // need a single wrapper here, instead of one for the table and one
        // for each row.

        const level = this.needRecomputeMembership;

        if (level !== RecomputeMembershipLevel.None) {
            const wrapper = makeLoadingValueWrapper();
            const table = this.getTable(ns, wrapper);
            const lastResult = unwrapLoadingValue(this._lastResult);

            if (isLoadingValue(lastResult) && !isLoadingValue(table)) {
                // When we switch from non-loading to loading, everything
                // dirties.
                outgoing.setAllDirty();
            }

            let rowsIncluded: MutableTable | undefined;
            if (isLoadingValue(table)) {
                rowsIncluded = undefined;
            } else if (level === RecomputeMembershipLevel.All) {
                const oldRowsIncluded = rowsIncluded ?? new Map();
                rowsIncluded = new MutableTable(undefined, table);

                // We have to dirty all rows that were deleted.
                for (const rowID of oldRowsIncluded.keys()) {
                    if (!table.has(rowID)) {
                        outgoing.addRowAndColumn(rowID, true);
                    }
                }

                tableForEach(table, false, row => {
                    let isIncluded = oldRowsIncluded.has(row.$rowID);
                    const newIsIncluded = this.isRowIncluded(ns, row, wrapper);
                    if (isIncluded !== newIsIncluded) {
                        outgoing.addRowAndColumn(row.$rowID, true);
                        isIncluded = newIsIncluded;
                    }
                    if (isIncluded) {
                        defined(rowsIncluded).set(row.$rowID, row);
                    }
                });
            } else if (level === RecomputeMembershipLevel.DirtyRows) {
                const oldRowsIncluded = isLoadingValue(lastResult) ? new Map() : new Map(asTable(lastResult));

                const { dirtyRowIDs } = this.tableDirtyState;
                assert(typeof dirtyRowIDs !== "boolean");

                const { dirtyColumns } = this.tableDirtyState;
                assert(dirtyColumns !== false);

                for (const rowID of dirtyRowIDs) {
                    const row = table.get(rowID);
                    if (row?.$isVisible === false) continue;
                    const oldIsIncluded = oldRowsIncluded.has(rowID);
                    const newIsIncluded = row !== undefined && this.isRowIncluded(ns, row, wrapper);

                    if (oldIsIncluded === newIsIncluded) {
                        outgoing.add({ kind: "row", rowID, columns: dirtyColumns });
                    } else {
                        if (newIsIncluded) {
                            oldRowsIncluded.set(rowID, defined(row));
                        } else {
                            oldRowsIncluded.delete(rowID);
                        }

                        outgoing.addRowAndColumn(rowID, true);
                    }
                }

                rowsIncluded = sortRowsIntoTable(Array.from(oldRowsIncluded.values()), this._sortKey);
            } else {
                assertNever(level);
            }

            if (this._fallbackRowID !== undefined) {
                // If we haven't found any rows we have to add the fallback
                // row. But if we have found at least one row, we have to
                // remove the fallback row if we've previously included it.
                if (!isLoadingValue(table) && (rowsIncluded === undefined || rowsIncluded.size === 0)) {
                    const row = table.get(this._fallbackRowID);
                    if (row !== undefined) {
                        if (rowsIncluded === undefined) {
                            rowsIncluded = new MutableTable(undefined, table);
                        }
                        rowsIncluded.set(this._fallbackRowID, row);
                        outgoing.addRowAndColumn(this._fallbackRowID, true);
                    }
                } else if (
                    rowsIncluded !== undefined &&
                    rowsIncluded.size > 1 &&
                    rowsIncluded.has(this._fallbackRowID)
                ) {
                    rowsIncluded.delete(this._fallbackRowID);
                    outgoing.addRowAndColumn(this._fallbackRowID, true);
                }
            }

            this._lastResult = wrapper.wrap(rowsIncluded ?? makeLoadingValue());
        }

        outgoing.pushAndClear(ns, this);
        this.tableDirtyState.clear();

        return this._lastResult;
    }

    public setDirty(): void {
        this.tableDirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return this.needRecomputeMembership !== RecomputeMembershipLevel.None;
    }
}

export interface FilterPredicate {
    readonly symbolicRepresentation: string;

    // This includes global paths
    getFilteredPaths(): readonly Path[];
    getHostPaths(): readonly RelativePath[];
    isRowIncluded(ns: RootPathResolver, filteredRow: Row, wrapper: LoadingValueWrapper): boolean;
}

function makeSetAllDirty(ns: Namespace, handler: Handler): () => void {
    return () => {
        handler.setDirty();
        if (handler.isDirty) {
            // Some handlers can't be dirtied.
            ns.handlerWasDirtied(handler);
        }
    };
}

// Dirt: see superclass
export class FilterHandler extends FilteringHandler {
    public readonly symbolicRepresentation: string;

    constructor(
        tablePath: RootPath,
        private readonly _predicate: FilterPredicate,
        sortKey: RelativePath | undefined,
        fallbackRowID?: string
    ) {
        super(tablePath, _predicate.getFilteredPaths(), sortKey, fallbackRowID);
        assert(_predicate.getHostPaths().length === 0);

        let str = `filter ${getSymbolicRepresentationForPath(tablePath)} by: ${_predicate.symbolicRepresentation}`;
        if (sortKey !== undefined) {
            str = `${str} sortBy: ${getSymbolicRepresentationForPath(sortKey)}`;
        }
        this.symbolicRepresentation = str;
    }

    protected isRowIncluded(ns: Namespace, row: Row, wrapper: LoadingValueWrapper): boolean {
        assert(row.$isVisible);
        return this._predicate.isRowIncluded(ns, row, wrapper);
    }

    protected get needRecomputeMembership(): RecomputeMembershipLevel {
        if (this.tableDirtyState.dirtyRowIDs === true) {
            return RecomputeMembershipLevel.All;
        } else if (this.tableDirtyState.includesPaths(this._predicate.getFilteredPaths())) {
            return RecomputeMembershipLevel.DirtyRows;
        } else {
            return RecomputeMembershipLevel.None;
        }
    }

    protected processOtherDirt(_d: Dirt): boolean {
        return this.tableDirtyState.setAllDirty();
    }
}

// If `sourceKeyIsArray` then we return an empty array if it's `undefined`.
// Otherwise we return the empty string.  This assumes `v` is already an
// "unwrapped" loading value.
export function getRelationKeys(
    v: GroundValue,
    sourceKeyIsArray: boolean,
    wrapper: LoadingValueWrapper
): LoadingValue | readonly string[] {
    if (isLoadingValue(v)) {
        return v;
    } else if (isArray(v)) {
        const a: string[] = [];
        for (let i = 0; i < v.length; i++) {
            const s = convertToRelationKey(wrapper.unwrap(getArrayItem(v, i)));
            if (isLoadingValue(s)) return s;
            if (s !== undefined) {
                a.push(s);
            }
        }
        return a;
    } else if (isKeyRefObject(v)) {
        // This `$value` can't be a loading value
        const s = convertToRelationKey(v.$value);
        if (isLoadingValue(s)) return s;
        if (s === undefined) return [];
        return [s];
    } else if (sourceKeyIsArray && v === undefined) {
        return [];
    } else {
        const s = convertToRelationKey(v);
        if (isLoadingValue(s)) return s;
        if (s === undefined) return [];
        return [s];
    }
}

// Dirt: see superclass
abstract class MultiRelationHandler extends FilteringHandler {
    private _isSourceDirty = dirtyFromTheStart;

    constructor(
        private readonly _sourceKeyPath: RootPath,
        tablePath: RootPath,
        sortKey: RelativePath | undefined,
        private readonly _sourceKeyIsArray: boolean,
        private readonly _targetKeyIsArray: boolean
    ) {
        super(tablePath, [_sourceKeyPath], sortKey, undefined);
    }

    // This should unwrap
    protected abstract getKeysForRow(ns: Namespace, row: Row, wrapper: LoadingValueWrapper): GroundValue;

    protected isRowIncluded(ns: Namespace, row: Row, wrapper: LoadingValueWrapper): boolean {
        assert(row.$isVisible);
        // FIXME: Only do this once, not for every row.  We might want to
        // make `FilteringHandler` generic and have a `prepare` function
        // which in this case would return this set, and then `isRowIncluded`
        // takes the result of `prepare` as an argument.
        const sourceKeys = getRelationKeys(
            wrapper.unwrap(ns.get(this._sourceKeyPath, true)),
            this._sourceKeyIsArray,
            wrapper
        );
        if (isLoadingValue(sourceKeys)) return false;
        const keys = getRelationKeys(
            wrapper.unwrap(this.getKeysForRow(ns, row, wrapper)),
            this._targetKeyIsArray,
            wrapper
        );
        if (isLoadingValue(keys)) return false;
        return areSetsOverlapping(new Set(sourceKeys), keys);
    }

    protected get needRecomputeMembership(): RecomputeMembershipLevel {
        if (this._isSourceDirty || this.tableDirtyState.dirtyRowIDs === true) {
            return RecomputeMembershipLevel.All;
        } else if (this.tableDirtyState.dirtyRowIDs !== false) {
            return RecomputeMembershipLevel.DirtyRows;
        } else {
            return RecomputeMembershipLevel.None;
        }
    }

    protected processOtherDirt(_d: Dirt): boolean {
        if (this._isSourceDirty) return false;

        // FIXME: This is too pessimistic.  At the very least we have to
        // filter by the source key path, which not be a top-level path.
        this._isSourceDirty = true;
        return true;
    }

    public recompute(ns: Namespace): GroundValue {
        // `super.recompute` already returns a wrapped value
        const result = super.recompute(ns);
        this._isSourceDirty = false;
        return result;
    }
}

export class MultiRelationToColumnHandler extends MultiRelationHandler {
    private readonly _targetColumnPath: RelativePath;
    public readonly symbolicRepresentation: string;

    constructor(
        sourceKeyPath: RootPath,
        targetTableColumnPath: RootPath,
        sortKey: RelativePath | undefined,
        sourceKeyIsArray: boolean,
        targetKeyIsArray: boolean
    ) {
        super(
            sourceKeyPath,
            deconstructTableColumnPath(targetTableColumnPath).tablePath,
            sortKey,
            sourceKeyIsArray,
            targetKeyIsArray
        );
        this._targetColumnPath = deconstructTableColumnPath(targetTableColumnPath).keyPath;

        this.symbolicRepresentation = `multi-relation source: ${getSymbolicRepresentationForPath(
            sourceKeyPath
        )} target: ${getSymbolicRepresentationForPath(
            targetTableColumnPath
        )} sortBy: ${getSymbolicRepresentationForPath(sortKey)}`;
    }

    protected getKeysForRow(_ns: Namespace, row: Row, wrapper: LoadingValueWrapper): GroundValue {
        return wrapper.unwrap(follow(row, this._targetColumnPath));
    }

    protected get needRecomputeMembership(): RecomputeMembershipLevel {
        const fromSuper = super.needRecomputeMembership;
        if (fromSuper !== RecomputeMembershipLevel.All) {
            if (this.tableDirtyState.includesPaths([this._targetColumnPath])) {
                return RecomputeMembershipLevel.DirtyRows;
            }
        }
        return fromSuper;
    }
}

export class MultiRelationToGlobalHandler extends MultiRelationHandler {
    private _isTargetDirty = true;
    public readonly symbolicRepresentation: string;

    constructor(
        sourceKeyPath: RootPath,
        targetTablePath: RootPath,
        private readonly _targetPath: RootPath,
        sortKey: RelativePath | undefined,
        sourceKeyIsArray: boolean,
        targetKeyIsArray: boolean
    ) {
        super(sourceKeyPath, targetTablePath, sortKey, sourceKeyIsArray, targetKeyIsArray);

        this.symbolicRepresentation = `multi-relation source: ${getSymbolicRepresentationForPath(
            sourceKeyPath
        )} target: ${getSymbolicRepresentationForPath(_targetPath)} sortBy: ${getSymbolicRepresentationForPath(
            sortKey
        )}`;
    }

    public getSlots(): readonly IncomingSlot[] {
        return [
            ...super.getSlots(),
            {
                sourcePath: this._targetPath,
                process: this.processTargetDirt,
            },
        ];
    }

    private processTargetDirt = (): boolean => {
        if (this._isTargetDirty) return false;
        this._isTargetDirty = true;
        return true;
    };

    protected getKeysForRow(ns: Namespace, _row: Row, wrapper: LoadingValueWrapper): GroundValue {
        return wrapper.unwrap(ns.get(this._targetPath));
    }

    protected get needRecomputeMembership(): RecomputeMembershipLevel {
        if (this._isTargetDirty) {
            return RecomputeMembershipLevel.All;
        }
        return super.needRecomputeMembership;
    }

    public recompute(ns: Namespace): GroundValue {
        const result = super.recompute(ns);
        this._isTargetDirty = false;
        return result;
    }
}

abstract class AsyncHandlerBase implements Handler {
    private readonly _asyncState: AsyncState = {
        value: makeLoadingValue(),
        changeObservable: undefined,
        state: undefined,
    };
    private _isDirty = dirtyFromTheStart;
    private _ns: Namespace | undefined;

    public setDirty(): void {
        this._isDirty = true;
    }

    public get isDirty(): boolean {
        return this._isDirty;
    }

    protected processDirt = (_d: Dirt): boolean => {
        if (this._isDirty) return false;
        this._isDirty = true;
        return true;
    };

    public abstract get symbolicRepresentation(): string;
    public abstract getSlots(): readonly IncomingSlot[];
    // ##wrapLoadingValues
    // We expect this to return a wrapped value
    protected abstract compute(ns: Namespace): Promise<ChangeObservable<GroundValue>> | ChangeObservable<GroundValue>;

    private setChangeObservable(newChangeObservable: ChangeObservable<GroundValue> | undefined): void {
        this._asyncState.changeObservable?.unsubscribe(this.onChange);
        this._asyncState.changeObservable = newChangeObservable;
        if (this._ns !== undefined) {
            newChangeObservable?.subscribe(this.onChange);
        }
    }

    // Returns whether to dirty.  Dirtying is necessary if we've either
    // started an async computation that's still running, or we ran a sync
    // computation that produced a result different from the current value.
    private startComputation(ns: Namespace): boolean {
        assert(this._asyncState.state === undefined);

        const state = this._asyncState;
        state.state = false;
        state.value = makeLoadingValueWithDisplayValue(state.value);
        // We have to unset it here, because even if we get a change
        // observable from the promise, it will come back asynchronously, so
        // there's a time between now and then where the current change
        // observable is wrong.  If we wanted to be super fancy, we could keep
        // the current change observable until then, but make sure that when
        // we update we wrap the new value in a loading value, so changes from
        // the old one still come through until we have the new one, but
        // that's too much complexity for now.
        this.setChangeObservable(undefined);

        // We don't need to wrap the result, see the abstract `compute` method above.
        const promiseOrCO = this.compute(ns);
        if (promiseOrCO instanceof Promise) {
            void promiseOrCO.then(co => {
                assert(state.state !== undefined);

                if (!ns.hasHandler(this)) return;

                const needsFollowUp = state.state;
                state.state = undefined;

                this.setChangeObservable(co);

                const newValue = co.current;
                if (newValue !== state.value) {
                    state.value = newValue;
                    ns.pushDirt(this, fullDirt);
                }

                if (needsFollowUp) {
                    // ##asyncComputationFollowUp:
                    // TODO: It's a bit silly that we're waiting for the previous
                    // computation to finish, then don't use the result, and start
                    // a new computation.
                    this.startComputation(ns);
                }
            });
        } else {
            assert(state.state === false);
            state.state = undefined;

            this.setChangeObservable(promiseOrCO);

            const newValue = promiseOrCO.current;
            if (newValue === state.value) {
                // No dirtying necessary.
                return false;
            }
            state.value = newValue;
        }

        return true;
    }

    private onChange = (newValue: GroundValue): void => {
        assert(this._asyncState.changeObservable !== undefined);

        if (newValue === this._asyncState.value) return;

        this._asyncState.value = newValue;
        this._ns?.pushDirt(this, fullDirt);
    };

    public recompute(ns: Namespace): GroundValue {
        if (this.isDirty) {
            let needsDirtying: boolean;
            if (this._asyncState.state !== undefined) {
                this._asyncState.state = true;
                needsDirtying = true;
            } else {
                needsDirtying = this.startComputation(ns);
            }
            if (needsDirtying) {
                ns.pushDirt(this, fullDirt);
            }
            this._isDirty = false;
        }

        return this._asyncState.value;
    }

    public connect(ns: Namespace): void {
        assert(this._ns === undefined);
        this._ns = ns;

        this._asyncState.changeObservable?.subscribe(this.onChange);
    }

    public disconnect(ns: Namespace): void {
        this._asyncState.changeObservable?.unsubscribe(this.onChange);

        if (this._ns === undefined) return;
        assert(this._ns === ns);
        this._ns = undefined;
    }
}

// Computes one global aggregate over a whole table, potentially yielding back
// if the aggregation takes too long (##resumableComputations). Should behave
// the same as `TableAggregateHandler` when `_useResumption` is false.
//
// Dirt:
// * pushes dirt whenever the aggregate changes
export class ResumableTableAggregateHandler<T, D = undefined> extends AsyncHandlerBase {
    private readonly _slots: readonly IncomingSlot[];
    private _state: T | undefined;
    private readonly _aggregatedTableDirtyState: DirtyState;
    public readonly symbolicRepresentation: string;
    private readonly _tableQuery: Query | undefined;

    constructor(
        aggregatedTablePath: RootPath,
        private readonly _computation: TableAggregateComputation<T, D>,
        tableNameForQuery: TableName | undefined,
        private readonly _useResumption: boolean,
        private readonly _computeLocally: boolean
    ) {
        super();
        const contextPaths = _computation.getContextPaths();
        const aggregatePaths = _computation.getAggregatePaths();

        this._slots = [
            { sourcePath: aggregatedTablePath, process: this.processDirt },
            ...makeSlotsForPaths([...contextPaths, ...aggregatePaths], false, this.processOtherDirt),
        ];

        this._aggregatedTableDirtyState = makeDirtyState(aggregatePaths, true);

        if (tableNameForQuery !== undefined) {
            this._tableQuery = new Query(tableNameForQuery);
        }

        this.symbolicRepresentation = `aggregate ${getSymbolicRepresentationForPath(
            aggregatedTablePath
        )} computation: ${_computation.symbolicRepresentation}`;
    }

    public getSlots(): readonly IncomingSlot[] {
        return this._slots;
    }

    private makeMapperState(): MapperState<T> {
        return {
            get: () => this._state,
            set: v => (this._state = v),
            delete: () => (this._state = undefined),
        };
    }

    private makeAggregateProcessor(ns: Namespace): TableAggregateDataProvider<T, D> {
        const wrapper = makeLoadingValueWrapper();
        let container: GroundValue;
        let derived: D | LoadingValue | undefined;
        const getAggregatedContainer = () => {
            if (container === undefined) {
                if (this._tableQuery !== undefined && this._computeLocally) {
                    container = this._tableQuery;
                } else {
                    container = wrapper.unwrap(ns.get(this._slots[0].sourcePath, true));
                }
                if (this._computeLocally && isQuery(container)) {
                    container = wrapper.unwrap(
                        ns.resolveQueryWithFixup(
                            container.withLimit(localQueryableRollupLimit),
                            this._slots[0].sourcePath,
                            undefined,
                            undefined,
                            this,
                            false
                        )
                    );
                }
            }
            return container;
        };

        const getAggregatedTable = () => loadedDefinedMap(getAggregatedContainer(), asTable);

        const processor: TableAggregateDataProvider<T, D> = {
            loadingValueWrapper: wrapper,
            getAggregatedContainer,
            getAggregatedTable,
            forEachInAggregated: f => {
                const t = getAggregatedContainer();
                if (t === undefined || isLoadingValue(t)) return t;
                const maybeLoadingValue = this._useResumption ? forEachItemResumable(t, f) : forEachItem(t, f);
                if (maybeLoadingValue !== undefined) return maybeLoadingValue;
                return true;
            },
            getAggregatedAsArray: () => loadedDefinedMap(getAggregatedContainer(), containerAsMutatingArray),
            getContextRow: () => undefined,
            rootPathResolver: ns,
            deriveFromAggregatedTable: f => {
                if (derived === undefined) {
                    derived = loadedDefinedMap(getAggregatedTable(), f);
                }
                return derived;
            },
            ...this.makeMapperState(),
        };
        return processor;
    }

    private makeProcessDirtProcessor(): ProcessDirtProcessor {
        return {
            setDirty: () => {
                this._state = undefined;
                return this._aggregatedTableDirtyState.add(fullDirt);
            },
        };
    }

    protected processDirt = (d: Dirt): boolean => {
        return this._aggregatedTableDirtyState.add(d);
    };

    private processOtherDirt = (d: Dirt): boolean => {
        return this._computation.processOtherDirt(this.makeProcessDirtProcessor(), d);
    };

    protected compute(ns: Namespace): Promise<ChangeObservable<GroundValue>> | ChangeObservable<GroundValue> {
        const proc = this.makeAggregateProcessor(ns);

        let newValue: GroundValue;
        if (this._tableQuery !== undefined && !this._computeLocally) {
            newValue = this._computation.makeQuery(this._tableQuery, proc);
        } else {
            const container = proc.getAggregatedContainer();

            if (!isLoadingValue(container) && isQuery(container)) {
                newValue = this._computation.makeQuery(container, proc);
            } else {
                newValue = this._computation.recompute(
                    proc,
                    this._aggregatedTableDirtyState,
                    undefined,
                    makeSetAllDirty(ns, this),
                    {
                        contextPath: undefined,
                        handler: this,
                    }
                );
            }
        }

        return this._useResumption && isResumableLoadingValue(newValue)
            ? sleep(0).then(() => this.compute(ns))
            : new ConstantChangeObservable(proc.loadingValueWrapper.wrap(newValue));
    }

    public recompute(ns: Namespace): GroundValue {
        const result = super.recompute(ns);
        this._aggregatedTableDirtyState.clear();
        return result;
    }

    public setDirty(): void {
        this._aggregatedTableDirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return this._aggregatedTableDirtyState.isDirty;
    }
}

export function makeTableAggregateHandler<T, D = undefined>(
    aggregatedTablePath: RootPath,
    computation: TableAggregateComputation<T, D>,
    tableNameForQuery: TableName | undefined,
    computeLocally: boolean
): Handler {
    return new ResumableTableAggregateHandler(
        aggregatedTablePath,
        computation,
        tableNameForQuery,
        false,
        computeLocally
    );
}

// This does a global computation.
//
// Dirt:
// * pushes dirt whenever the computation result changes
export class ComputationHandler implements Handler {
    private readonly _slots: readonly IncomingSlot[];
    private _isDirty = dirtyFromTheStart;
    private _value: GroundValue;

    constructor(private readonly _computation: Computation) {
        this._slots = makeSlotsForPaths(_computation.getPaths(), true, this.processDirt);
    }

    public getSlots(): readonly IncomingSlot[] {
        return this._slots;
    }

    private processDirt = (): boolean => {
        if (this._isDirty) return false;
        this._isDirty = true;
        return true;
    };

    public recompute(ns: Namespace): GroundValue {
        if (this._isDirty) {
            const getters = makeWrappedComputationValueGetters();

            const oldValue = this._value;

            const newValue = this._computation.compute(ns, undefined, getters, makeSetAllDirty(ns, this), {
                handler: this,
                contextPath: undefined,
            });

            this._value = getters.wrap(newValue);

            if (this._value !== oldValue) {
                ns.pushDirt(this, fullDirt);
            }

            this._isDirty = false;
        }

        return this._value;
    }

    public setDirty(): void {
        this._isDirty = true;
    }

    public get isDirty(): boolean {
        return this._isDirty;
    }

    public get symbolicRepresentation(): string {
        return `compute ${this._computation.symbolicRepresentation}`;
    }
}

// We have to push dirt when we've computed a "new" value. Usually that means
// that the new value is different from the old value.  There's one case,
// however, where the old value needs to be ignored, namely when we come
// across a row for which we haven't computed a value yet at all.  The old
// value will be `undefined` in that case, but even if the new value is also
// `undefined`, we still have to push dirt.
// https://github.com/quicktype/glide/issues/15395
// Expects `newValue` to be wrapped
function isNewColumnValue(row: Row, columnName: string, newValue: GroundValue): boolean {
    const oldValue = getRowColumn(row, columnName);
    if (oldValue !== newValue) {
        return true;
    }
    // We know that the values are the same.  Now, if the old value is
    // `undefined` and not set in the row, the new value still counts as new.
    if (oldValue !== undefined) {
        // The old value is defined, so it must exist in the row.
        return false;
    }
    // We know the values are `undefined`, so if the column doesn't exist, the
    // value is new.
    return !hasOwnProperty(row, columnName);
}

// The first time our table is non-loading we push a full dirty state to avoid
// the case where the table is empty and we don't push any dirt at all, which
// would make our dependents not recompute.  For example, relation columns
// would stay in a loading state.  Pushing a full dirty state doesn't do any
// harm either, because we will be genuinely fully dirty in any case.
// https://github.com/quicktype/glide/issues/16444
function makeNonLoadingOutgoingDirtyStateMaker(): () => DirtyState {
    let hadNonLoading = false;
    return () => {
        const dirtyState = makeDirtyState(true);
        if (!hadNonLoading) {
            dirtyState.setAllDirty();
            hadNonLoading = true;
        }
        return dirtyState;
    };
}

// Adds a column that is produced by a computation.
//
// Dirt:
// * pushes dirt whenever a value in the computed column changes
// * it does not push dirt for changes in any of the other columns
export class ComputationComputedColumnHandler extends TableAndPathsHandler {
    private readonly _dirtyState: DirtyState;
    public readonly symbolicRepresentation: string;
    private readonly _queryResolveInfo: QueryResolveInfo;
    private readonly _makeNonLoadingOutgoingDirtyState = makeNonLoadingOutgoingDirtyStateMaker();

    constructor(
        tablePath: RootPath,
        private readonly _columnName: string,
        private readonly _computation: Computation,
        private readonly _useThunks: boolean
    ) {
        // Root paths from the computation go into "other" dirt, and dirty the
        // whole computed columns.  Relative paths are columns in the table,
        // and only dirty the rows they're associated with.
        super(tablePath, _computation.getPaths());
        this._dirtyState = makeDirtyState(_computation.getPaths(), true);

        this._queryResolveInfo = {
            contextPath: tablePath,
            handler: this,
        };

        this.symbolicRepresentation = `compute-to-column ${_columnName} table: ${getSymbolicRepresentationForPath(
            tablePath
        )} computation: ${_computation.symbolicRepresentation}`;
    }

    protected processTableDirt(d: Dirt): boolean {
        return this._dirtyState.add(d);
    }

    protected processOtherDirt(_d: Dirt): boolean {
        // Not sure if we can be smarter here, definitely not without
        // knowledge about the specific computation we're doing.
        return this._dirtyState.setAllDirty();
    }

    private recomputeRow(ns: Namespace, row: Row, setAllDirty: () => void): boolean {
        const getters = makeWrappedComputationValueGetters();

        if (this._useThunks && !ns.isStrictMode) {
            setThunkColumn(row, this._columnName, () =>
                getters.wrap(this._computation.compute(ns, row, getters, setAllDirty, this._queryResolveInfo))
            );
        } else {
            const newValue = getters.wrap(
                this._computation.compute(ns, row, getters, setAllDirty, this._queryResolveInfo)
            );
            if (!isNewColumnValue(row, this._columnName, newValue)) {
                return false;
            }
            row[this._columnName] = newValue;
        }

        this._dirtyState.addRowAndColumn(row.$rowID, this._columnName);
        return true;
    }

    public recompute(ns: Namespace): GroundValue {
        const tableWrapper = makeLoadingValueWrapper();
        const table = this.getTable(ns, tableWrapper);

        if (this.isDirty && !isLoadingValue(table)) {
            const setAllDirty = makeSetAllDirty(ns, this);

            const { dirtyRowIDs } = this._dirtyState;
            const outgoing = this._makeNonLoadingOutgoingDirtyState();

            if (dirtyRowIDs === true) {
                tableForEach(table, true, row => {
                    if (this.recomputeRow(ns, row, setAllDirty)) {
                        outgoing.addRowAndColumn(row.$rowID, this._columnName);
                    }
                });
            } else {
                assert(dirtyRowIDs !== false);
                for (const rowID of dirtyRowIDs) {
                    const row = table.get(rowID);
                    // ##dirtForDeletedRows:
                    // If the row is undefined that probably means that it was
                    // deleted, which also means that we have to include it in
                    // the dirt because handlers that depend on us need to
                    // know that the value isn't there anymore.
                    // https://github.com/quicktype/glide/issues/11985
                    if (row === undefined || this.recomputeRow(ns, row, setAllDirty)) {
                        outgoing.addRowAndColumn(rowID, this._columnName);
                    }
                }
            }

            outgoing.pushAndClear(ns, this);
        }

        this._dirtyState.clear();

        return tableWrapper.wrap(table);
    }

    public setDirty(): void {
        this._dirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return this._dirtyState.isDirty;
    }
}

// This maintains `MapperState`s for each row in a table.  Say we want to do a
// Single Relation, but instead of it being a global value, it needs to be a
// true computed column, so instead of needing a single `MapperState` we need
// one for each row in the table that hosts the
class TableMapperState<T> {
    private readonly _state = new Map<string, T>();

    public makeMapperState(rowID: string): MapperState<T> {
        return {
            get: () => this._state.get(rowID),
            set: (v: T) => this._state.set(rowID, v),
            delete: () => this._state.delete(rowID),
        };
    }

    public clear(): void {
        this._state.clear();
    }
}

// We use this for rollups, multi-lookups, and single values that are real
// computed columns, i.e. not global.
//
// Dirt:
// * pushes dirt whenever a value in the computed column changes
// * it does not push dirt for changes in any of the other columns
export class TableAggregateComputedColumnHandler<T, D> implements Handler {
    private readonly _slots: readonly IncomingSlot[];
    private readonly _state = new TableMapperState<T>();
    private readonly _contextDirtyState: DirtyState;
    private readonly _aggregatedDirtyState: DirtyState;
    private _cache: Map<LoadedGroundValue, GroundValue> | undefined;
    private readonly _makeNonLoadingOutgoingDirtyState = makeNonLoadingOutgoingDirtyStateMaker();
    public readonly symbolicRepresentation: string;
    private readonly _queryResolveInfo: QueryResolveInfo;
    private readonly _tableQuery: Query | undefined;

    constructor(
        // The table to which we're adding the computed column
        contextTablePath: RootPath,
        // The path to the multi-relation which we're aggregating over.  This
        // can be relative to the `contextTablePath` or a root path.
        private readonly _relationPath: Path,
        // The table containing the data that we're aggregating, i.e. the
        // target table of the above multi-relation.  If we're aggregating
        // over an array, this is `undefined`.
        aggregatedTablePath: RootPath | undefined,
        private readonly _computation: TableAggregateComputation<T, D>,
        private readonly _columnName: string,
        tableNameForQuery: TableName | undefined,
        private readonly _computeLocally: boolean
    ) {
        const contextPaths = _computation.getContextPaths();
        const aggregatePaths = _computation.getAggregatePaths();

        this._contextDirtyState = makeDirtyState([_relationPath, ...contextPaths], true);
        this._aggregatedDirtyState = makeDirtyState(aggregatePaths, true);

        const slots = [
            { sourcePath: contextTablePath, process: this.processContextDirt },
            ...makeSlotsForPaths([...contextPaths, ...aggregatePaths], false, this.processOtherDirt),
        ];
        // TODO: Don't subscribe to the aggregated table if it's a queryable,
        // because we will get dirt from adding/deleting rows that are not
        // useful to us.  If we're aggregating over a relation query, we need
        // to subscribe to that relation, because its query might change, but
        // not the table behind it.  Maybe the correct solution to this is to
        // not push dirt for added/deleted rows in queryables?
        // https://app.replay.io/recording/query-still-still-not-loading--5f1cda5d-4ea1-44c7-a8ee-20c933b12797
        if (aggregatedTablePath !== undefined) {
            slots.push({ sourcePath: aggregatedTablePath, process: this.processAggregatedDirt });
        }
        if (isRootPath(_relationPath)) {
            // The alternative to subscribing to `_relationPath` would be that
            // the caller combines it with `aggregatedTablePath`.
            slots.push({ sourcePath: _relationPath, process: this.processAggregatedDirt });
        }
        this._slots = slots;

        this._queryResolveInfo = {
            contextPath: contextTablePath,
            handler: this,
        };

        if (tableNameForQuery !== undefined) {
            this._tableQuery = new Query(tableNameForQuery);
        }

        this.symbolicRepresentation = `aggregate-to-column ${_columnName} table: ${getSymbolicRepresentationForPath(
            contextTablePath
        )} relation: ${getSymbolicRepresentationForPath(_relationPath)} computation: ${
            _computation.symbolicRepresentation
        }`;
    }

    public getSlots(): readonly IncomingSlot[] {
        return this._slots;
    }

    private get contextTablePath(): RootPath {
        return this._slots[0].sourcePath;
    }

    private getAggregatedContainer(ns: RootPathResolver, row: Row, wrapper: LoadingValueWrapper): GroundValue {
        let container: GroundValue;
        if (this._tableQuery !== undefined && this._computeLocally) {
            container = this._tableQuery;
        } else {
            container = wrapper.unwrap(getValueAt(ns, row, this._relationPath));
        }
        if (this._computeLocally && isQuery(container)) {
            container = wrapper.unwrap(
                ns.resolveQueryWithFixup(
                    container.withLimit(localQueryableRollupLimit),
                    this._relationPath,
                    row,
                    this._slots[0].sourcePath,
                    this,
                    false
                )
            );
        }
        return container;
    }

    private makeAggregateProcessor(
        ns: Namespace,
        contextRow: Row,
        derivedRef: Ref<D | LoadingValue>,
        // If this is `undefined` then we have to get it ourselves here
        container: LoadedGroundValue | undefined,
        containerWrapper: LoadingValueWrapper
    ): TableAggregateDataProvider<T, D> {
        const getAggregatedContainer = () => container ?? this.getAggregatedContainer(ns, contextRow, containerWrapper);
        const wrapper = makeLoadingValueWrapper();
        const provider: TableAggregateDataProvider<T, D> = {
            getAggregatedContainer,
            loadingValueWrapper: wrapper,
            getAggregatedTable: () => loadedDefinedMap(getAggregatedContainer(), asTable),
            forEachInAggregated: f => {
                const t = getAggregatedContainer();
                if (t === undefined || isLoadingValue(t)) return t;
                const maybeLoadingValue = forEachItem(t, f);
                if (maybeLoadingValue !== undefined) return maybeLoadingValue;
                return true;
            },
            getAggregatedAsArray: () => loadedDefinedMap(getAggregatedContainer(), containerAsMutatingArray),
            rootPathResolver: ns,
            getContextRow: () => contextRow,
            deriveFromAggregatedTable: f => {
                if (isRootPath(this._relationPath)) {
                    if (derivedRef.value === undefined) {
                        derivedRef.value = loadedDefinedMap(provider.getAggregatedTable(), f);
                    }
                    return derivedRef.value;
                } else {
                    return loadedDefinedMap(provider.getAggregatedTable(), f);
                }
            },
            ...this._state.makeMapperState(contextRow.$rowID),
        };
        return provider;
    }

    private postProcessDirt(didDirty: boolean): boolean {
        if (didDirty) {
            this._cache = undefined;
        }
        return didDirty;
    }

    private processContextDirt = (d: Dirt): boolean => {
        return this.postProcessDirt(this._contextDirtyState.add(d));
    };

    private processAggregatedDirt = (d: Dirt): boolean => {
        return this.postProcessDirt(this._aggregatedDirtyState.add(d));
    };

    private processOtherDirt = (d: Dirt): boolean => {
        const didDirty = this._computation.processOtherDirt({ setDirty: () => true }, d);
        if (didDirty) {
            this._state.clear();
        }
        return this.postProcessDirt(this._contextDirtyState.setAllDirty() || didDirty);
    };

    private recomputeForRow(
        ns: Namespace,
        row: Row,
        aggregatedDirtyState: DirtyState,
        contextDirtyState: DirtyState,
        derivedRef: Ref<D | LoadingValue>,
        setAllDirty: () => void
    ): GroundValue {
        const containerWrapper = makeLoadingValueWrapper();

        let container: GroundValue | undefined;
        if (this._computation.canCacheResultsForContainer) {
            container = this.getAggregatedContainer(ns, row, containerWrapper);
            if (container === undefined || isLoadingValue(container)) return container;

            if (this._cache?.has(container) === true) {
                return containerWrapper.wrap(this._cache.get(container));
            }
        } else {
            container = undefined;
        }

        const processor = this.makeAggregateProcessor(ns, row, derivedRef, container, containerWrapper);

        let result: GroundValue;
        if (this._tableQuery !== undefined && !this._computeLocally) {
            result = this._computation.makeQuery(this._tableQuery, processor);
        } else {
            if (container === undefined) {
                container = processor.getAggregatedContainer();
                if (isLoadingValue(container)) return container;
            }
            if (isQuery(container)) {
                result = this._computation.makeQuery(container, processor);
            } else {
                result = this._computation.recompute(
                    processor,
                    aggregatedDirtyState,
                    contextDirtyState,
                    setAllDirty,
                    this._queryResolveInfo
                );
            }
        }
        result = processor.loadingValueWrapper.wrap(result);

        if (this._computation.canCacheResultsForContainer) {
            // Note that we might be caching loading values with display
            // values here.  That's ok because the key to the cache is the
            // container, and we're using a separate `LoadingValueWrapper` for
            // getting the container vs the result, so the container->result
            // function should be pure for the duration of this recomputation,
            // and the cache is invalidated for the next one.
            if (this._cache === undefined) {
                this._cache = new Map();
            }
            assert(!this._cache.has(container));
            this._cache.set(container, result);
        }

        return result;
    }

    public recompute(ns: Namespace): GroundValue {
        const tableWrapper = makeLoadingValueWrapper();
        const contextTableValue = tableWrapper.unwrap(ns.get(this.contextTablePath, true));

        const aggregatedDirtyState = this._aggregatedDirtyState.clone();
        const contextDirtyState = this._contextDirtyState.clone();

        const derivedRef: Ref<D | LoadingValue> = {};

        if (this.isDirty && !isLoadingValue(contextTableValue)) {
            const outgoing = this._makeNonLoadingOutgoingDirtyState();

            const setAllDirty = makeSetAllDirty(ns, this);

            const contextTable = asTable(contextTableValue);
            tableForEach(contextTable, true, row => {
                // FIXME: If only the context table is dirty, we can avoid recomputing
                // if only some rows are dirty.
                if (!ns.isStrictMode) {
                    setThunkColumn(row, this._columnName, () =>
                        this.recomputeForRow(ns, row, aggregatedDirtyState, contextDirtyState, derivedRef, setAllDirty)
                    );
                } else {
                    const newValue = this.recomputeForRow(
                        ns,
                        row,
                        aggregatedDirtyState,
                        contextDirtyState,
                        derivedRef,
                        setAllDirty
                    );
                    if (!isNewColumnValue(row, this._columnName, newValue)) {
                        return;
                    }
                    row[this._columnName] = newValue;
                }
                outgoing.addRowAndColumn(row.$rowID, this._columnName);
            });

            outgoing.pushAndClear(ns, this);
        }

        this._contextDirtyState.clear();
        this._aggregatedDirtyState.clear();

        return tableWrapper.wrap(contextTableValue);
    }

    public setDirty(): void {
        this._contextDirtyState.setAllDirty();
        this._aggregatedDirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return this._contextDirtyState.isDirty || this._aggregatedDirtyState.isDirty;
    }
}

// If `sortKey` is `undefined` then we don't sort.
function sortRowsIntoTable(rows: readonly Row[], sortKey: RelativePath | undefined): MutableTable {
    if (sortKey !== undefined) {
        rows = sortBy(Array.from(rows), r => follow(r, sortKey));
    }
    return new MutableTable(rows);
}

// Dirt:
// * pushes dirt whenever a value in the computed column changes
// * it does not push dirt for changes in any of the other columns
abstract class RelationComputedColumnHandler implements Handler {
    private readonly _sourceSlot: IncomingSlot;
    private readonly _sourceColumnPath: RelativePath;
    private readonly _sourceDirtyState: DirtyState;
    public abstract readonly symbolicRepresentation: string;

    // FIXME: These indexes can also be kept just once for each table/key pair
    // across all handlers.  Here we keep them sorted, so maybe that wouldn't
    // be that easy.  But most sorts want to be by row index, so maybe that's
    // the default, or the only option the global index provides?
    private _targetTablesForKey: DefaultMap<string, Table> | undefined;

    constructor(
        sourceTableColumnPath: RootPath,
        protected readonly columnName: string,
        private readonly _sortKey: RelativePath | undefined,
        private readonly _sourceKeyIsArray: boolean
    ) {
        const source = deconstructTableColumnPath(sourceTableColumnPath);
        this._sourceSlot = { sourcePath: source.tablePath, process: this.processSourceDirt };
        this._sourceColumnPath = source.keyPath;
        this._sourceDirtyState = makeDirtyState(this._sourceColumnPath);
    }

    public getSlots(): readonly IncomingSlot[] {
        return [this._sourceSlot];
    }

    // NOTE: This must only be called from within the thunks, or
    // `this._targetTablesForKey` might not be defined.
    // ##wrapLoadingValues
    // We populate this `_targetTablesForKey` in `recompute`
    // and their keys are "unwrapped".
    protected getTargetRowsForKeys(keys: Set<string>): Table {
        if (keys.size === 0) {
            return new Table();
        }
        if (keys.size === 1) {
            const [key] = Array.from(keys);
            return defined(this._targetTablesForKey).get(key);
        }

        const rows: Row[] = [];
        const rowIDs = new Set<string>();
        for (const key of keys) {
            const table = defined(this._targetTablesForKey).get(key);
            for (const [rowID, row] of table) {
                if (rowIDs.has(rowID)) continue;

                assert(row.$isVisible);
                rows.push(row);
                rowIDs.add(rowID);
            }
        }

        return sortRowsIntoTable(rows, this._sortKey);
    }

    // This will be run in the thunk
    protected abstract getValueForKeys(keys: Set<string>, wrapper: LoadingValueWrapper): GroundValue;

    private getSourceKeys(row: Row, wrapper: LoadingValueWrapper): LoadingValue | Set<string> {
        const keys = getRelationKeys(
            wrapper.unwrap(follow(row, this._sourceColumnPath)),
            this._sourceKeyIsArray,
            wrapper
        );
        if (isLoadingValue(keys)) return keys;
        return new Set(keys);
    }

    protected abstract getTargetKeys(ns: Namespace, row: Row, wrapper: LoadingValueWrapper): LoadingValue | Set<string>;

    private processSourceDirt = (d: Dirt): boolean => {
        return this._sourceDirtyState.add(d);
    };

    private getSourceTable(ns: Namespace, wrapper: LoadingValueWrapper): Table | LoadingValue {
        const v = wrapper.unwrap(ns.get(this._sourceSlot.sourcePath, true));
        if (isLoadingValue(v)) return v;
        return asTable(v);
    }

    protected abstract getTargetTable(ns: Namespace, wrapper: LoadingValueWrapper): Table | LoadingValue;

    // The value in each source row depends on the keys in that row, as well
    // as on the target table, as well as on the keys in the target table. The
    // target table is the same for each source row, however, so we use its
    // own wrapper for it, and then we wrap the result with both the source
    // row wrapper, and the wrapper for the target table.
    //
    // This is all the places we can encounter a loading value in this
    // handler:
    //
    // 1. The source table is loading, which means we're not sure which rows
    //    are in the table.
    // 2. The target table is loading, which means we're not sure which rows
    //    are in that table.
    // 3. The keys in a source row are loading.
    // 4. The keys in a target row are loading.
    //
    // - 1 means the resulting table is loading, but not necessarily the
    //   relations in the rows.
    // - 2 or 4 means the relations in all the rows must be loading.  FIXME:
    //   There is a special case we could optimize for: if the source rows
    //   doesn't have any keys, then the resulting relation must be empty, so
    //   we can ignore the 2 and 4 in that case.
    // - 3 means the relation in that specific row must be loading.
    public recompute(ns: Namespace): GroundValue {
        const sourceTableWrapper = makeLoadingValueWrapper();
        const sourceTable = this.getSourceTable(ns, sourceTableWrapper);

        // FIXME: Only recompute dirty source rows if the target is not dirty.
        if (this.isDirty && !isLoadingValue(sourceTable)) {
            this._targetTablesForKey = undefined;

            const targetTableWrapper = makeLoadingValueWrapper();
            const targetTable = this.getTargetTable(ns, targetTableWrapper);

            if (!isLoadingValue(targetTable)) {
                tableForEach(sourceTable, true, row => {
                    const thunk = (): GroundValue => {
                        if (this._targetTablesForKey === undefined) {
                            const targetRowsForKey = new DefaultMap<string, Row[]>(() => []);

                            tableForEach(targetTable, false, r => {
                                const targetKeys = this.getTargetKeys(ns, r, targetTableWrapper);
                                if (!isLoadingValue(targetKeys)) {
                                    for (const targetKey of targetKeys) {
                                        targetRowsForKey.get(targetKey).push(r);
                                    }
                                }
                            });

                            this._targetTablesForKey = new DefaultMap(() => new Table());
                            for (const [targetKey, targetTableRows] of targetRowsForKey) {
                                this._targetTablesForKey.set(
                                    targetKey,
                                    sortRowsIntoTable(targetTableRows, this._sortKey)
                                );
                            }
                        }

                        const rowWrapper = makeLoadingValueWrapper();
                        const sourceKeys = this.getSourceKeys(row, rowWrapper);
                        if (isLoadingValue(sourceKeys)) return sourceKeys;
                        return targetTableWrapper.wrap(rowWrapper.wrap(this.getValueForKeys(sourceKeys, rowWrapper)));
                    };

                    if (ns.isStrictMode) {
                        row[this.columnName] = thunk();
                    } else {
                        setThunkColumn(row, this.columnName, thunk);
                    }
                });
            } else {
                // If the target table is loading, then the relation is
                // loading for all rows.
                tableForEach(sourceTable, true, row => {
                    row[this.columnName] = targetTable;
                });
            }

            ns.pushDirt(this, { kind: "table", columns: new Set([this.columnName]) });
        }

        this._sourceDirtyState.clear();

        return sourceTableWrapper.wrap(sourceTable);
    }

    public setDirty(): void {
        this._sourceDirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return this._sourceDirtyState.isDirty;
    }
}

abstract class RelationToColumnComputedColumnHandler extends RelationComputedColumnHandler {
    private readonly _targetColumnPath: RelativePath;
    private readonly _targetDirtyState: DirtyState;
    private readonly _targetSlot: IncomingSlot;

    constructor(
        sourceTableColumnPath: RootPath,
        targetTableColumnPath: RootPath,
        columnName: string,
        targetRowIndexDirtiesAll: boolean,
        sortKey: RelativePath | undefined,
        sourceKeyIsArray: boolean,
        private readonly _targetKeyIsArray: boolean
    ) {
        super(sourceTableColumnPath, columnName, sortKey, sourceKeyIsArray);

        const target = deconstructTableColumnPath(targetTableColumnPath);

        this._targetSlot = { sourcePath: target.tablePath, process: this.processTargetDirt };
        this._targetColumnPath = target.keyPath;
        const targetColumns = [this._targetColumnPath];
        if (targetRowIndexDirtiesAll) {
            targetColumns.push(makeKeyPath(rowIndexColumnName));
        }
        this._targetDirtyState = makeDirtyState(targetColumns);
    }

    public getSlots(): readonly IncomingSlot[] {
        return [...super.getSlots(), this._targetSlot];
    }

    private processTargetDirt = (d: Dirt): boolean => {
        return this._targetDirtyState.add(d);
    };

    protected getTargetKeys(_ns: Namespace, row: Row, wrapper: LoadingValueWrapper): LoadingValue | Set<string> {
        const keys = getRelationKeys(
            wrapper.unwrap(follow(row, this._targetColumnPath)),
            this._targetKeyIsArray,
            wrapper
        );
        if (isLoadingValue(keys)) return keys;
        return new Set(keys);
    }

    protected getTargetTable(ns: Namespace, wrapper: LoadingValueWrapper): Table | LoadingValue {
        const v = wrapper.unwrap(ns.get(this._targetSlot.sourcePath, true));
        if (isLoadingValue(v)) return v;
        return asTable(v);
    }

    public recompute(ns: Namespace): GroundValue {
        const result = super.recompute(ns);
        this._targetDirtyState.clear();
        return result;
    }

    public setDirty(): void {
        super.setDirty();
        this._targetDirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return super.isDirty || this._targetDirtyState.isDirty;
    }
}

abstract class RelationToGlobalComputedColumnHandler extends RelationComputedColumnHandler {
    private readonly _targetDirtyState: DirtyState;
    private readonly _targetTableSlot: IncomingSlot;
    private readonly _targetSlot: IncomingSlot;

    constructor(
        sourceTableColumnPath: RootPath,
        targetTablePath: RootPath,
        targetPath: RootPath,
        columnName: string,
        targetRowIndexDirtiesAll: boolean,
        sortKey: RelativePath | undefined,
        sourceKeyIsArray: boolean,
        private readonly _targetKeyIsArray: boolean
    ) {
        super(sourceTableColumnPath, columnName, sortKey, sourceKeyIsArray);

        this._targetTableSlot = { sourcePath: targetTablePath, process: this.processTargetTableDirt };

        const targetColumns = [makeKeyPath(nativeTableRowIDColumnName)];
        if (targetRowIndexDirtiesAll) {
            targetColumns.push(makeKeyPath(rowIndexColumnName));
        }
        this._targetDirtyState = makeDirtyState(targetColumns);

        this._targetSlot = { sourcePath: targetPath, process: this.processTargetDirt };
    }

    public getSlots(): readonly IncomingSlot[] {
        return [...super.getSlots(), this._targetTableSlot, this._targetSlot];
    }

    private processTargetTableDirt = (d: Dirt): boolean => {
        return this._targetDirtyState.add(d);
    };

    private processTargetDirt = (): boolean => {
        return this._targetDirtyState.setAllDirty();
    };

    protected getTargetKeys(ns: Namespace, _row: Row, wrapper: LoadingValueWrapper): LoadingValue | Set<string> {
        const keys = getRelationKeys(
            wrapper.unwrap(ns.get(this._targetSlot.sourcePath)),
            this._targetKeyIsArray,
            wrapper
        );
        if (isLoadingValue(keys)) return keys;
        return new Set(keys);
    }

    protected getTargetTable(ns: Namespace, wrapper: LoadingValueWrapper): Table | LoadingValue {
        const v = wrapper.unwrap(ns.get(this._targetTableSlot.sourcePath, true));
        if (isLoadingValue(v)) return v;
        return asTable(v);
    }

    public recompute(ns: Namespace): GroundValue {
        const result = super.recompute(ns);
        this._targetDirtyState.clear();
        return result;
    }

    public setDirty(): void {
        super.setDirty();
        this._targetDirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return super.isDirty || this._targetDirtyState.isDirty;
    }
}

export class MultiRelationToColumnComputedColumnHandler extends RelationToColumnComputedColumnHandler {
    public readonly symbolicRepresentation: string;

    constructor(
        sourceTableColumnPath: RootPath,
        targetTableColumnPath: RootPath,
        columnName: string,
        sortKey: RelativePath | undefined,
        sourceKeyIsArray: boolean,
        targetKeyIsArray: boolean
    ) {
        super(
            sourceTableColumnPath,
            targetTableColumnPath,
            columnName,
            false,
            sortKey,
            sourceKeyIsArray,
            targetKeyIsArray
        );

        let symbolic = `multi-relation-to-column ${columnName} source: ${getSymbolicRepresentationForPath(
            sourceTableColumnPath
        )} target: ${getSymbolicRepresentationForPath(targetTableColumnPath)}`;
        if (sortKey !== undefined) {
            symbolic = `${symbolic} sortBy: ${getSymbolicRepresentationForPath(sortKey)}`;
        }
        this.symbolicRepresentation = symbolic;
    }

    // ##wrapLoadingValues
    // See the comment in `getTargetRowsForKeys`.
    protected getValueForKeys(keys: Set<string>): GroundValue {
        return this.getTargetRowsForKeys(keys);
    }
}

export class MultiRelationToGlobalComputedColumnHandler extends RelationToGlobalComputedColumnHandler {
    public readonly symbolicRepresentation: string;

    constructor(
        sourceTableColumnPath: RootPath,
        targetTablePath: RootPath,
        targetPath: RootPath,
        columnName: string,
        sortKey: RelativePath | undefined,
        sourceKeyIsArray: boolean,
        targetKeyIsArray: boolean
    ) {
        super(
            sourceTableColumnPath,
            targetTablePath,
            targetPath,
            columnName,
            false,
            sortKey,
            sourceKeyIsArray,
            targetKeyIsArray
        );

        this.symbolicRepresentation = `multi-relation-to-column ${columnName} source: ${getSymbolicRepresentationForPath(
            sourceTableColumnPath
        )} target: ${getSymbolicRepresentationForPath(targetPath)} sortBy: ${getSymbolicRepresentationForPath(
            sortKey
        )}`;
    }

    // ##wrapLoadingValues
    // See the comment in `getTargetRowsForKeys`.
    protected getValueForKeys(keys: Set<string>): GroundValue {
        return this.getTargetRowsForKeys(keys);
    }
}

function getRowIndex(row: Row): BaseRowIndex | undefined {
    const rowIndex = getRowColumn(row, rowIndexColumnName);
    if (rowIndex === undefined) return undefined;
    assert(isBaseRowIndex(rowIndex));
    return rowIndex;
}

function getFirstRow(rows: Table): [BaseRowIndex | undefined, Row] | undefined {
    let firstRow: Row | undefined;
    let firstRowIndex: BaseRowIndex | undefined;
    tableForEach(rows, false, row => {
        const rowIndex = getRowIndex(row);
        if (
            firstRow === undefined ||
            (rowIndex !== undefined && (firstRowIndex === undefined || rowIndex < firstRowIndex))
        ) {
            firstRow = row;
            firstRowIndex = rowIndex;
        }
    });
    if (firstRow === undefined) return undefined;
    return [firstRowIndex, firstRow];
}

// FIXME: This must get dirt on the row index column, as well as use that to
// invalidate.
export class SingleRelationToColumnComputedColumnHandler extends RelationToColumnComputedColumnHandler {
    public readonly symbolicRepresentation: string;

    constructor(
        sourceTableColumnPath: RootPath,
        targetTableColumnPath: RootPath,
        columnName: string,
        sourceKeyIsArray: boolean,
        targetKeyIsArray: boolean
    ) {
        super(
            sourceTableColumnPath,
            targetTableColumnPath,
            columnName,
            true,
            undefined,
            sourceKeyIsArray,
            targetKeyIsArray
        );

        this.symbolicRepresentation = `relation-to-column ${columnName} source: ${getSymbolicRepresentationForPath(
            sourceTableColumnPath
        )} target: ${getSymbolicRepresentationForPath(targetTableColumnPath)}`;
    }

    protected getValueForKeys(keys: Set<string>): GroundValue {
        return getFirstRow(this.getTargetRowsForKeys(keys))?.[1];
    }
}

// FIXME: This must get dirt on the row index column, as well as use that to
// invalidate.
export class SingleRelationToGlobalComputedColumnHandler extends RelationToGlobalComputedColumnHandler {
    public readonly symbolicRepresentation: string;

    constructor(
        sourceTableColumnPath: RootPath,
        targetTablePath: RootPath,
        targetPath: RootPath,
        columnName: string,
        sourceKeyIsArray: boolean,
        targetKeyIsArray: boolean
    ) {
        super(
            sourceTableColumnPath,
            targetTablePath,
            targetPath,
            columnName,
            true,
            undefined,
            sourceKeyIsArray,
            targetKeyIsArray
        );

        this.symbolicRepresentation = `relation-to-column ${columnName} source: ${getSymbolicRepresentationForPath(
            sourceTableColumnPath
        )} target: ${getSymbolicRepresentationForPath(targetPath)}`;
    }

    protected getValueForKeys(keys: Set<string>): GroundValue {
        return getFirstRow(this.getTargetRowsForKeys(keys))?.[1];
    }
}

export class UUIDColumnHandler implements Handler {
    private readonly _dirtyState = makeDirtyState([makeKeyPath(nativeTableRowIDColumnName)]);
    private readonly _slot: IncomingSlot;
    private readonly _uuidForRowID = new DefaultMap<string, string>(() => uuid());
    public readonly symbolicRepresentation: string;

    constructor(tablePath: RootPath, private readonly _columnName: string) {
        this._slot = { sourcePath: tablePath, process: this.processDirt };

        this.symbolicRepresentation = `uuid-to-column ${_columnName} table: ${getSymbolicRepresentationForPath(
            tablePath
        )}`;
    }

    public getSlots(): readonly IncomingSlot[] {
        return [this._slot];
    }

    private readonly processDirt = (d: Dirt): boolean => {
        return this._dirtyState.add(d);
    };

    public recompute(ns: Namespace): GroundValue {
        const wrapper = makeLoadingValueWrapper();
        const tableValue = wrapper.unwrap(ns.get(this._slot.sourcePath));

        const { dirtyRowIDs } = this._dirtyState;
        if (dirtyRowIDs === false) {
            return tableValue;
        }

        if (!isLoadingValue(tableValue)) {
            const outgoing = makeDirtyState(true);
            const table = asTable(tableValue);

            if (dirtyRowIDs === true) {
                tableForEach(table, true, r => {
                    r[this._columnName] = this._uuidForRowID.get(r.$rowID);
                });
                outgoing.add({ kind: "table", columns: new Set([this._columnName]) });
            } else {
                for (const rowID of dirtyRowIDs) {
                    // We need ##dirtForDeletedRows.
                    outgoing.addRowAndColumn(rowID, this._columnName);

                    const r = table.get(rowID);
                    if (r === undefined) continue;
                    r[this._columnName] = this._uuidForRowID.get(rowID);
                }
            }

            outgoing.pushAndClear(ns, this);
        }

        this._dirtyState.clear();

        return wrapper.wrap(tableValue);
    }

    public get isDirty(): boolean {
        return this._dirtyState.isDirty;
    }

    public setDirty(): void {
        this._dirtyState.setAllDirty();
    }
}

// Dirt from `path` will be passed through unmodified.
interface PassThroughCombineSpec {
    readonly kind: "pass-through";
    readonly path: RootPath;
}

// Any dirt from the table `tableColumnPath` dirties the whole column
// `columnToDirty`.
interface FullColumnCombineSpec {
    readonly kind: "full-column";
    readonly tablePath: RootPath;
    readonly columnToDirty: string;
}

// Any dirt from column `columnName` in table `tablePath` dirties the whole
// table.
interface FullTableFromColumnCombineSpec {
    readonly kind: "full-table-from-column";
    readonly tablePath: RootPath;
    readonly columnName: string;
}

// Any dirt from the table `tableColumnPath` dirties the whole table.
interface FullTableCombineSpec {
    readonly kind: "full-table";
    readonly tablePath: RootPath;
}

type CombineSpec =
    | PassThroughCombineSpec
    | FullColumnCombineSpec
    | FullTableFromColumnCombineSpec
    | FullTableCombineSpec;

// Dirt:
// * pushes whatever the specs say
export class CombineHandler implements Handler {
    private readonly _dirtyState = makeDirtyState(true);
    private readonly _slots: readonly IncomingSlot[];
    public readonly symbolicRepresentation: string;

    constructor(private readonly _resultPath: RootPath, specs: readonly CombineSpec[]) {
        const specsForKey = new DefaultMap<string, CombineSpec[]>(() => []);

        assert(isTopLevelPath(_resultPath));

        const symbolicParts: string[] = [`combine ${getSymbolicRepresentationForPath(_resultPath)}`];

        for (const spec of specs) {
            let key: string;
            if (spec.kind === "pass-through") {
                assert(isTopLevelPath(spec.path));
                key = spec.path.rest.key;

                symbolicParts.push(`pass-through: ${getSymbolicRepresentationForPath(spec.path)}`);
            } else if (
                spec.kind === "full-column" ||
                spec.kind === "full-table-from-column" ||
                spec.kind === "full-table"
            ) {
                key = spec.tablePath.rest.key;

                symbolicParts.push(`${spec.kind}: ${getSymbolicRepresentationForPath(spec.tablePath)}`);
            } else {
                assertNever(spec);
            }

            specsForKey.get(key).push(spec);
        }

        this._slots = Array.from(specsForKey).map(([k, s]) => ({
            sourcePath: makeRootPath(k),
            process: d => this.processDirt(d, s),
        }));

        this.symbolicRepresentation = symbolicParts.join(" ");
    }

    public getSlots(): readonly IncomingSlot[] {
        return this._slots;
    }

    private readonly processDirt = (d: Dirt, specs: readonly CombineSpec[]): boolean => {
        let didDirty = false;
        for (const spec of specs) {
            if (spec.kind === "pass-through") {
                if (this._dirtyState.add(d)) {
                    didDirty = true;
                }
            } else if (spec.kind === "full-column") {
                if (this._dirtyState.add({ kind: "table", columns: new Set([spec.columnToDirty]) })) {
                    didDirty = true;
                }
            } else if (spec.kind === "full-table-from-column") {
                if (d.columns === true || d.columns.has(spec.columnName)) {
                    if (this._dirtyState.add(fullDirt)) {
                        didDirty = true;
                    }
                }
            } else if (spec.kind === "full-table") {
                if (this._dirtyState.add(fullDirt)) {
                    didDirty = true;
                }
            } else {
                return assertNever(spec);
            }
        }
        return didDirty;
    };

    public recompute(ns: Namespace): GroundValue {
        this._dirtyState.pushAndClear(ns, this);

        return ns.get(this._resultPath, true);
    }

    public setDirty(): void {
        this._dirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return this._dirtyState.isDirty;
    }
}

// Dirt:
// * pushes dirt whenever the value changes
export class TimestampHandler implements Handler {
    private _updateInterval: ReturnType<typeof setInterval> | undefined;
    public readonly symbolicRepresentation = "timestamp";

    public getSlots(): readonly IncomingSlot[] {
        return [];
    }

    public recompute(_ns: Namespace): GroundValue {
        return GlideDateTime.now();
    }

    public setDirty(): void {
        return;
    }

    public connect(ns: Namespace): void {
        assert(this._updateInterval === undefined);

        this._updateInterval = setInterval(() => {
            ns.pushDirt(this, fullDirt);
        }, 10000);
    }

    public disconnect(): void {
        assert(this._updateInterval !== undefined);
        clearInterval(this._updateInterval);
        this._updateInterval = undefined;
    }

    public get isDirty(): boolean {
        return false;
    }
}

// Dirt:
// * forwards all dirt from its input table
export class SortByHandler implements Handler {
    private readonly _slot: IncomingSlot;
    private readonly _columnName: string;
    private _value: Table | LoadingValue = new Table();
    // The reason we're subscribing to all dirt here is that we need to
    // forward all dirt.
    private readonly _dirtyState = makeDirtyState(true);
    public readonly symbolicRepresentation: string;

    constructor(tableColumnPath: RootPath) {
        const { tablePath, keyPath } = deconstructTableColumnPath(tableColumnPath);
        this._slot = { sourcePath: tablePath, process: this.processDirt };
        assert(keyPath.rest === undefined);
        this._columnName = keyPath.key;

        this.symbolicRepresentation = `sort ${getSymbolicRepresentationForPath(tableColumnPath)}`;
    }

    public getSlots(): readonly IncomingSlot[] {
        return [this._slot];
    }

    private readonly processDirt = (d: Dirt): boolean => {
        return this._dirtyState.add(d);
    };

    public recompute(ns: Namespace): GroundValue {
        const wrapper = makeLoadingValueWrapper();

        // We include the row ID so that we get added/removed rows in case
        // those rows don't included the column we sort by.
        if (
            this._dirtyState.includesColumn(this._columnName) ||
            this._dirtyState.includesColumn(nativeTableRowIDColumnName)
        ) {
            const tableValue = wrapper.unwrap(ns.get(this._slot.sourcePath, true));
            if (!isLoadingValue(tableValue)) {
                const table = asTable(tableValue);
                const sorted = sortBy(Array.from(table.values()), r => {
                    const v = wrapper.unwrap(getRowColumn(r, this._columnName));
                    if (isLoadingValue(v)) return undefined;
                    return asPrimitive(v);
                });
                this._value = wrapper.wrap(new Table(sorted, table));
            } else {
                this._value = wrapper.wrap(tableValue);
            }
        }

        this._dirtyState.pushAndClear(ns, this);

        return this._value;
    }

    public setDirty(): void {
        this._dirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return this._dirtyState.isDirty;
    }
}

export class CurrentLocationHandler implements Handler, CurrentLocationListener {
    private _ns: Namespace | undefined;
    private _row: Row | undefined;
    public readonly symbolicRepresentation = "current-location";

    public getSlots(): readonly IncomingSlot[] {
        return [];
    }

    public recompute(): GroundValue {
        return this._row;
    }

    private pushDirt(): void {
        defined(this._ns).pushDirt(this, fullDirt);
    }

    public updateLocation(latitude: number, longitude: number): void {
        if (this._row === undefined) {
            this._row = {
                $rowID: makeRowID(),
                $isVisible: false,
                latitude,
                longitude,
            };
        } else {
            const oldLatitudeValue = getRowColumn(this._row, "latitude");
            const oldLongitudeValue = getRowColumn(this._row, "longitude");
            if (!isLoadingValue(oldLatitudeValue) && !isLoadingValue(oldLongitudeValue)) {
                const oldLatitude = defined(asMaybeNumber(oldLatitudeValue));
                const oldLongitude = defined(asMaybeNumber(oldLongitudeValue));
                if (latitude === oldLatitude && longitude === oldLongitude) return;
            }

            this._row.latitude = latitude;
            this._row.longitude = longitude;
        }

        this.pushDirt();
    }

    public locationError(): void {
        if (this._row === undefined) return;

        this._row = undefined;

        this.pushDirt();
    }

    public setDirty(): void {
        return;
    }

    public get isDirty(): boolean {
        return false;
    }

    public connect(ns: Namespace): void {
        assert(this._ns === undefined);
        this._ns = ns;
        listenToCurrentLocation(this);
    }

    public disconnect(ns: Namespace): void {
        assert(this._ns === ns);
        stopListeningToCurrentLocation(this);
        this._ns = undefined;
    }
}

interface AsyncState {
    value: GroundValue;
    /**
     * If the computation returned a change observable, this is it. `value`
     * will always have the up-to-date value, even if this is set.
     */
    changeObservable: ChangeObservable<GroundValue> | undefined;
    // Boolean says computation is under way, and `true` means a follow-up is
    // needed.
    state: boolean | undefined;
}

// This runs "global" async computations, i.e. ones that don't depend on the
// current row.
export class AsyncComputationHandler extends AsyncHandlerBase {
    private readonly _slots: readonly IncomingSlot[];

    constructor(
        private readonly _computation: AsyncComputation,
        private readonly setError: (error: ComputationError | undefined) => void
    ) {
        super();
        this._slots = makeSlotsForPaths(_computation.getPaths(), true, this.processDirt);
    }

    public getSlots(): readonly IncomingSlot[] {
        return this._slots;
    }

    protected compute(ns: Namespace): Promise<ChangeObservable<GroundValue>> | ChangeObservable<GroundValue> {
        // Unset the error for global/true
        this.setError(undefined);

        const getters = makeWrappedComputationValueGetters();

        try {
            const promiseOrResult = this._computation.compute(ns, undefined, getters);
            if (promiseOrResult instanceof Promise) {
                return promiseOrResult
                    .then(v => {
                        if (isChangeObservable(v)) {
                            return new MappingChangeObservable(v, x => getters.wrap(x));
                        } else {
                            return new ConstantChangeObservable(getters.wrap(v));
                        }
                    })
                    .catch(e => {
                        if (e instanceof PluginError && e.showInBuilder !== false) {
                            this.setError(e.message);
                        }
                        return new ConstantChangeObservable(getters.wrap(undefined));
                    });
            }
            return new ConstantChangeObservable(getters.wrap(promiseOrResult));
        } catch (err: unknown) {
            if (err instanceof PluginError && err.showInBuilder !== false) {
                this.setError(exceptionToString(err));
            }
            return new ConstantChangeObservable(getters.wrap(undefined));
        }
    }

    public get symbolicRepresentation(): string {
        return `async-computation computation: ${this._computation.symbolicRepresentation}`;
    }
}

// This does async computations that depend on the current row.
export class AsyncComputationComputedColumnHandler implements Handler {
    private readonly _slots: readonly IncomingSlot[];
    private readonly _dirtyState: DirtyState;
    private readonly _recomputedRowIDs = new Set<string>();
    private readonly _stateForRowID = new DefaultMap<string, AsyncState>(() => ({
        changeObservable: undefined,
        value: makeLoadingValue(),
        state: undefined,
    }));
    private readonly _makeNonLoadingOutgoingDirtyState = makeNonLoadingOutgoingDirtyStateMaker();
    public readonly symbolicRepresentation: string;

    constructor(
        tablePath: RootPath,
        private readonly _columnName: string,
        private readonly _computation: AsyncComputation,
        private readonly setRowError: (rowID: string, error: ComputationError | undefined) => void
    ) {
        const computationPaths = _computation.getPaths();

        const tableSlot: IncomingSlot = { sourcePath: tablePath, process: this.processTableDirt };
        const otherSlots = makeSlotsForPaths(computationPaths, false, this.processOtherDirt);
        this._slots = [tableSlot, ...otherSlots];

        this._dirtyState = makeDirtyState(computationPaths);

        this.symbolicRepresentation = `async-computation-to-column ${_columnName} table: ${getSymbolicRepresentationForPath(
            tablePath
        )} computation: ${_computation.symbolicRepresentation}`;
    }

    public getSlots(): readonly IncomingSlot[] {
        return this._slots;
    }

    private readonly processTableDirt = (d: Dirt): boolean => {
        return this._dirtyState.add(d);
    };

    private readonly processOtherDirt = (): boolean => {
        return this._dirtyState.setAllDirty();
    };

    private async doRecompute(ns: Namespace, row: Row): Promise<void> {
        const state = this._stateForRowID.get(row.$rowID);
        state.state = false;

        // Unset the error for the given row
        this.setRowError(row.$rowID, undefined);

        const getters = makeWrappedComputationValueGetters();

        let promiseOrResult: GroundValue | Promise<GroundValue | ChangeObservable<GroundValue>>;
        try {
            promiseOrResult = this._computation.compute(ns, row, getters);
        } catch (err: unknown) {
            if (err instanceof PluginError && err.showInBuilder !== false) {
                this.setRowError(row.$rowID, exceptionToString(err));
            }
            state.value = undefined;
            return;
        }
        if (promiseOrResult instanceof Promise) {
            const newValue = await promiseOrResult
                .then(v => {
                    // FIXME: Support change observables here, too, when we
                    // need it.
                    if (isChangeObservable(v)) {
                        v = v.current;
                    }
                    return getters.wrap(v);
                })
                .catch(e => {
                    if (e instanceof PluginError && e.showInBuilder !== false) {
                        this.setRowError(row.$rowID, e.message);
                    }
                    return undefined;
                });
            assert(state.state !== undefined);

            if (!ns.hasHandler(this)) return;

            const needsFollowUp = state.state;
            state.state = undefined;

            if (newValue !== state.value) {
                state.value = newValue;
                this._recomputedRowIDs.add(row.$rowID);
                ns.handlerWasDirtied(this);
            }

            if (needsFollowUp) {
                // We're doing ##asyncComputationFollowUp here, too.
                this.startComputation(ns, row);
            }
        } else {
            assert(state.state === false);
            state.state = undefined;

            const newValue = getters.wrap(promiseOrResult);
            if (newValue === state.value) return;

            state.value = newValue;
        }
    }

    private startComputation(ns: Namespace, row: Row): void {
        const state = this._stateForRowID.get(row.$rowID);
        assert(state.state === undefined);

        state.value = makeLoadingValueWithDisplayValue(state.value);

        // We rely on this function to synchronously execute if the _computation is not async
        // (i.e if it doesn't return a promise)
        // The spec guarantees that this will work.
        //
        // QUOTE:
        //
        // The body of an async function can be thought of as being split by zero or more await expressions.
        // Top-level code, up to and including the first await expression (if there is one), is run synchronously.
        // In this way, an async function without an await expression will run synchronously.
        // If there is an await expression inside the function body, however,
        // the async function will always complete asynchronously.
        //
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/async_function
        void this.doRecompute(ns, row);
    }

    private recomputeRow(ns: Namespace, row: Row): void {
        const state = this._stateForRowID.get(row.$rowID);
        if (state.state !== undefined) {
            // A computation is already running, so we ask it to do a
            // follow-up instead of making a new thunk.
            state.state = true;
            row[this._columnName] = state.value;
            return;
        }
        const thunk = () => {
            if (state.state !== undefined) {
                state.state = true;
            } else {
                this.startComputation(ns, row);
            }
            return state.value;
        };

        if (ns.isStrictMode) {
            row[this._columnName] = thunk();
        } else {
            setThunkColumn(row, this._columnName, thunk);
        }
    }

    public recompute(ns: Namespace): GroundValue {
        const wrapper = makeLoadingValueWrapper();
        const tableValue = wrapper.unwrap(ns.get(this._slots[0].sourcePath));

        if (this.isDirty && !isLoadingValue(tableValue)) {
            const table = asTable(tableValue);
            const { dirtyRowIDs } = this._dirtyState;
            const outgoing = this._makeNonLoadingOutgoingDirtyState();

            if (dirtyRowIDs === true) {
                tableForEach(table, true, row => {
                    this.recomputeRow(ns, row);
                    outgoing.addRowAndColumn(row.$rowID, this._columnName);
                });
            } else {
                assert(dirtyRowIDs !== false || this._recomputedRowIDs.size > 0);

                for (const rowID of this._recomputedRowIDs) {
                    assert(this._stateForRowID.has(rowID));

                    if (dirtyRowIDs !== false && dirtyRowIDs.has(rowID)) continue;

                    // We need ##dirtForDeletedRows.
                    outgoing.addRowAndColumn(rowID, this._columnName);

                    const row = table.get(rowID);
                    if (row === undefined) continue;

                    row[this._columnName] = this._stateForRowID.get(rowID).value;
                }

                if (dirtyRowIDs !== false) {
                    for (const rowID of dirtyRowIDs) {
                        // We need ##dirtForDeletedRows.
                        outgoing.addRowAndColumn(rowID, this._columnName);

                        const row = table.get(rowID);
                        if (row === undefined) continue;

                        this.recomputeRow(ns, row);
                    }
                }
            }

            this._recomputedRowIDs.clear();
            outgoing.pushAndClear(ns, this);
        }

        this._dirtyState.clear();

        return wrapper.wrap(tableValue);
    }

    public setDirty(): void {
        this._dirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return this._dirtyState.isDirty || this._recomputedRowIDs.size > 0;
    }
}

export class ResolveQueryHandler implements Handler {
    private readonly _slot: IncomingSlot;
    private _isDirty = dirtyFromTheStart;
    private _value: GroundValue;

    constructor(queryPath: RootPath) {
        this._slot = { sourcePath: queryPath, process: this.processDirt };
    }

    public getSlots(): readonly IncomingSlot[] {
        return [this._slot];
    }

    private readonly processDirt = (): boolean => {
        if (this._isDirty) return false;
        this._isDirty = true;
        return true;
    };

    public recompute(ns: Namespace): GroundValue {
        if (this._isDirty) {
            const wrapper = makeLoadingValueWrapper();
            const query = wrapper.unwrap(ns.get(this._slot.sourcePath, true));
            // We need to wrap here because if the query itself was a loading
            // value, the result is loading, too.
            const value = wrapper.wrap(
                ns.resolveQueryWithFixup(query, this._slot.sourcePath, undefined, undefined, this, false)
            );

            // FIXME: We could be more precise here and compare loading values
            // by checking whether their display values are equal.
            if (this._value !== value) {
                this._value = value;
                ns.pushDirt(this, fullDirt);
            }

            this._isDirty = false;
        }
        return this._value;
    }

    public setDirty(): void {
        this._isDirty = true;
    }

    public get isDirty(): boolean {
        return this._isDirty;
    }

    public get symbolicRepresentation(): string {
        return `resolve-query ${getSymbolicRepresentationForPath(this._slot.sourcePath)}`;
    }
}

export class ThunkifyColumnHandler implements Handler {
    private readonly _sourceSlot: IncomingSlot;
    private readonly _sourceColumnPath: RelativePath;
    private readonly _sourceDirtyState: DirtyState;
    private readonly _makeNonLoadingOutgoingDirtyState = makeNonLoadingOutgoingDirtyStateMaker();

    constructor(sourceTableColumnPath: RootPath, private readonly _columnName: string) {
        const source = deconstructTableColumnPath(sourceTableColumnPath);
        this._sourceSlot = { sourcePath: source.tablePath, process: this.processDirt };
        this._sourceColumnPath = source.keyPath;
        this._sourceDirtyState = makeDirtyState(this._sourceColumnPath);
    }

    public getSlots(): readonly IncomingSlot[] {
        return [this._sourceSlot];
    }

    private processDirt = (d: Dirt): boolean => {
        return this._sourceDirtyState.add(d);
    };

    public recompute(ns: Namespace): GroundValue {
        const tableWrapper = makeLoadingValueWrapper();
        const tableValue = tableWrapper.unwrap(ns.get(this._sourceSlot.sourcePath, true));

        if (this._sourceDirtyState.isDirty && !isLoadingValue(tableValue)) {
            const table = asTable(tableValue);

            const { dirtyRowIDs } = this._sourceDirtyState;

            const outgoing = this._makeNonLoadingOutgoingDirtyState();

            const processRow = (row: Row) => {
                setThunkColumn(row, this._columnName, () => {
                    const wrapper = makeLoadingValueWrapper();
                    let value = wrapper.unwrap(getValueAt(ns, row, this._sourceColumnPath));
                    value = wrapper.unwrap(
                        ns.resolveQueryWithFixup(
                            value,
                            this._sourceColumnPath,
                            row,
                            this._sourceSlot.sourcePath,
                            this,
                            false
                        )
                    );
                    return wrapper.wrap(value);
                });
            };

            if (dirtyRowIDs === true) {
                tableForEach(table, true, processRow);
                outgoing.add({ kind: "table", columns: new Set([this._columnName]) });
            } else {
                assert(dirtyRowIDs !== false);

                for (const rowID of dirtyRowIDs) {
                    const row = table.get(rowID);
                    if (row === undefined) continue;

                    processRow(row);
                    outgoing.addRowAndColumn(rowID, this._columnName);
                }
            }

            outgoing.pushAndClear(ns, this);
        }

        this._sourceDirtyState.clear();

        return tableWrapper.wrap(tableValue);
    }

    public setDirty(): void {
        this._sourceDirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return this._sourceDirtyState.isDirty;
    }

    public get symbolicRepresentation(): string {
        return `thunkify column ${this._columnName} table: ${getSymbolicRepresentationForPath(
            this._sourceSlot.sourcePath
        )} columnPath: ${getSymbolicRepresentationForPath(this._sourceColumnPath)}`;
    }
}

// This takes `resultFromQuery`, which is the query result from the database,
// and adds or removes locally modified rows, depending on whether they should
// be included or not, based on `isRowIncluded`.  It will only add rows if
// `fixupIsAccurate` is `true`. `allRows` is all rows present in memory, which
// is a superset of `resultFromQuery` as well a subset of the rows in the
// database.  The function will not call `isRowIncluded` on rows that aren't
// in `locallyMutatedRowIDs`, to avoid unnecessary checks.  It will also
// return `needsSort` which indicates that the resulting row array might not
// be sorted correctly anymore (because one the included rows was modified
// locally).

// exported only for testing
export function addOrDeleteLocallyModifiedRows(
    resultFromQuery: Table,
    allRows: Table,
    locallyMutatedRowIDs: ReadonlySet<string>,
    fixupIsAccurate: boolean,
    isRowIncluded: (row: Row) => boolean
) {
    let needsSort = false;

    const potentialTable = new MutableTable(resultFromQuery.asArray());
    for (const rowID of locallyMutatedRowIDs) {
        const row = allRows.get(rowID);
        if (row === undefined) {
            // The `QueryableDataStore` should have removed this
            // locally deleted row.
            assert(!potentialTable.has(rowID));
            continue;
        }

        const hasRow = potentialTable.has(rowID);
        if (!fixupIsAccurate && !hasRow) continue;

        const isIncluded = isRowIncluded(row);
        if (isIncluded && !hasRow) {
            potentialTable.set(rowID, row);
            needsSort = true;
        } else if (!isIncluded && hasRow) {
            // If we just delete a row, we don't need to
            // re-sort.
            potentialTable.delete(rowID);
        } else if (isIncluded && hasRow) {
            // The membership of the row doesn't change, but
            // since it's been modified we need to re-sort.
            needsSort = true;
        }
    }

    return { needsSort, actualRows: potentialTable.asArray() };
}

// This is the base class for handling queries in the computation model.  We
// have two uses for it:
//
// - For queries from queryable tables we use `FixupQueryHandler` to
//   incorporate local changes that aren't (yet) reflected in the results from
//   the backend.
// - For non-queryable tables we use `RunQueryHandler` to fully run the query,
//   i.e. to make it even possible to run queries on a non-queryable table.
//
// Dirt:
// * forward all dirt from its dependencies
abstract class RunQueryHandlerBase implements Handler {
    private readonly _slots: readonly IncomingSlot[];
    protected readonly dirtyState: DirtyState;
    // `undefined` means invalid, in particular not computed yet
    private _current: MutableTable | LoadingValue | undefined;
    // The serialized query we use locally for fix-up
    protected readonly serialized: SerializedQuery;
    public readonly symbolicRepresentation: string;

    constructor(
        symbolicName: string,
        _fixupQuery: Query,
        // The path of where we get all local rows for, as well as dirt for
        // computed columns from - usually a combine handler.
        computedColumnsPath: RootPath,
        // The paths of columns referenced by the query.
        protected readonly columnPaths: ReadonlyMap<string, Path>,
        // This can return row IDs for rows that don't exist anymore, because
        // they were deleted.
        protected readonly columns: readonly TableColumn[]
    ) {
        this.serialized = _fixupQuery.serialize();

        const columnPathValues = Array.from(columnPaths.values());
        this._slots = filterUndefined([
            // Computed columns will push dirt only for the rows/columns that
            // actually changed.
            { sourcePath: computedColumnsPath, process: this.processDirt },
            ...columnPathValues.map(p => {
                if (!isRootPath(p)) return undefined;
                // Global computations will push dirt for the whole table,
                // which is as it should be.
                return { sourcePath: p, process: this.processDirt };
            }),
        ]);
        // We include the row ID column just in case `columnPathValues` is
        // empty.  It will tell us when rows are added/removed no matter what.
        this.dirtyState = makeDirtyState([...columnPathValues, makeKeyPath(nativeTableRowIDColumnName)]);

        const parts: string[] = [
            symbolicName,
            `computedColumns: ${getSymbolicRepresentationForPath(computedColumnsPath)}`,
        ];
        for (const [c, p] of columnPaths) {
            parts.push(`${c}: ${getSymbolicRepresentationForPath(p)}`);
        }
        this.symbolicRepresentation = parts.join(" ");
    }

    protected get computedColumnsPath(): RootPath {
        return this._slots[0].sourcePath;
    }

    public getSlots(): readonly IncomingSlot[] {
        return this._slots;
    }

    private processDirt = (d: Dirt): boolean => {
        return this.dirtyState.add(d);
    };

    // `allRows` are all the rows we know about from the table in question,
    // which might include rows that were never, and will never be part of the
    // result set.  Note that this can be a loading value, which has to be
    // handled appropriately.  In particular we must not return a table if the
    // data for the query is still loading, but we must also not return a
    // loading value if we have results for the query, even if they're empty.
    //
    // This must return `undefined` to indicate that it's still loading
    protected abstract recomputeQuery(
        ns: Namespace,
        allRows: Table | LoadingValue,
        isRowIncluded: (r: Row) => boolean,
        sort: (rows: Row[]) => void,
        wrapper: LoadingValueWrapper
    ): MutableTable | undefined;

    public recompute(ns: Namespace): GroundValue {
        if (this._current !== undefined && !this.isDirty) {
            return this._current;
        }

        const wrapper = makeLoadingValueWrapper();

        const allRows = wrapper.unwrap(ns.get(this.computedColumnsPath));

        if (isLoadingValue(allRows) || isTable(allRows)) {
            const isRowIncluded = (row: Row) =>
                row.$isVisible &&
                areConditionsTrueForRow(
                    this.serialized,
                    cn => {
                        const path = this.columnPaths.get(cn);
                        if (path === undefined) return undefined;
                        let v = wrapper.unwrap(getValueAt(ns, row, path));
                        if (isQuery(v)) {
                            // The query itself can return a loading value
                            // which we have to unwrap.
                            v = wrapper.unwrap(
                                ns.resolveQueryWithFixup(v, path, row, this.computedColumnsPath, this, false)
                            );
                        }
                        if (isLoadingValue(v)) {
                            // Nothing to do.  The `wrapper` will do the
                            // correct thing below.
                        } else if (isTable(v)) {
                            return v.asArray();
                        }
                        return v;
                    },
                    this.columns
                );

            const sort = (rows: Row[]) =>
                applySort(this.columns, this.serialized.sort, rows, (row, cn) => {
                    const path = this.columnPaths.get(cn);
                    if (path === undefined) return undefined;
                    return wrapper.unwrap(getValueAt(ns, row, path));
                });

            const result = this.recomputeQuery(ns, allRows, isRowIncluded, sort, wrapper);

            this._current = result;
        } else {
            this._current = undefined;
        }

        this.dirtyState.pushAndClear(ns, this);

        this._current = wrapper.wrap(this._current ?? makeLoadingValue());
        return this._current;
    }

    public setDirty(): void {
        this.dirtyState.setAllDirty();
    }

    public get isDirty(): boolean {
        return this.dirtyState.isDirty;
    }
}

export function applyQueryGroupBy(
    serialized: SerializedQuery,
    columns: readonly TableColumn[],
    rows: Row[],
    columnValueGetter: (r: Row, cn: string) => GroundValue
): Row[] {
    if (serialized.groupBy !== undefined) {
        const groupByRows = evaluateGroupBy(serialized.groupBy, columns, rows, columnValueGetter, v => v);
        return groupByRows.map(r => ({
            ...r,
            $rowID: makeRowID(),
            $isVisible: true,
        }));
    }

    return rows;
}

export function applyQueryLimit(serialized: SerializedQuery, rows: Row[]): Row[] {
    if (serialized.limit !== undefined && serialized.limit < rows.length) {
        rows.length = serialized.limit;
    }

    return rows;
}

// Dirt:
// * forward all dirt from its dependencies
export class FixupQueryHandler extends RunQueryHandlerBase {
    private readonly _fixupIsAccurate: boolean;

    constructor(
        // The query we send to the backend.
        private readonly _query: Query,
        // The query we use to fixup rows with local modifications, if
        // different from `_query`.  There can be cases where the full
        // `_query` would require us to do yet more queries to fix up locally,
        // in particular if it includes a Lookup. In those cases `_fixupQuery`
        // will be a simpler query that doesn't require further queries, but
        // will be less strict than `_query`.
        //
        // In those cases we will use `_fixupQuery` only to exclude locally
        // modified rows from the results, never to include rows that the
        // `_query` didn't already return.  That implies that we will also not
        // return locally filtered rows while the query is still loading. This
        // will sometimes exclude rows that should be included, but it's what
        // we can do right now without further queries or a more complex
        // implementation.
        //
        // As an example, consider two collections of to-do items, one of
        // which shows the items where `Done` is `false`, and the other where
        // `Done` is `true`. In addition to that condition, they also both
        // have a condition that involves a Lookup, which will not be included
        // in `_fixupQuery`.  If the user clicks the `Done` checkbox on an
        // item in the `false` collection, the `_fixupQuery` will remove it
        // from that collection.  However, since that row was not in the
        // original result set returned for the `true` collection, we will not
        // consider it for inclusion in the `true` collection.  This may or
        // may not be the correct thing to do, depending on whether the Lookup
        // condition in the two queries is the same or not.
        _fixupQuery: Query | undefined,
        computedColumnsPath: RootPath,
        columnPaths: ReadonlyMap<string, Path>,
        columns: readonly TableColumn[],
        private readonly _getLocallyMutatedRowIDs: () => ReadonlySet<string>
    ) {
        super("fixup-query", _fixupQuery ?? _query, computedColumnsPath, columnPaths, columns);

        this._fixupIsAccurate = _fixupQuery === undefined;

        // This is only for non-aggregate queries
        assert(this.serialized.groupBy === undefined);
    }

    protected recomputeQuery(
        ns: Namespace,
        allRows: Table | LoadingValue,
        isRowIncluded: (r: Row) => boolean,
        sort: (rows: Row[]) => void,
        wrapper: LoadingValueWrapper
    ): MutableTable | undefined {
        const value = wrapper.unwrap(ns.fetchQuery(this._query, () => this.dirtyState.setAllDirty(), this));

        // We have two distinct cases:
        //
        // 1. The query returned a table.  We trust the results of the
        //    query when it comes to which rows are included, but we do
        //    have to check all the locally modified rows for inclusion.
        //    If none of the locally modified rows cause us to change the
        //    results of the query then we also keep its sort, otherwise
        //    we have to re-sort.
        // 2. The query is still loading.  In this case we filter
        //    `allRows` for inclusion and sort it.  We don't have to worry
        //    about locally modified rows, because `allRows` already has
        //    them.

        let needsSort = false;
        let actualRows: Row[] | undefined;
        let errorMessage: string | undefined;
        if (!isLoadingValue(value) && isTable(value)) {
            if (isLoadingValue(allRows)) {
                allRows = new Table();
            }

            const result = addOrDeleteLocallyModifiedRows(
                value,
                allRows,
                this._getLocallyMutatedRowIDs(),
                this._fixupIsAccurate,
                isRowIncluded
            );
            actualRows = result.actualRows;
            needsSort = result.needsSort;
            // If the table has an error message then we have to propagate
            // it to the resulting table.
            errorMessage = value.errorMessage;
        } else if (this._fixupIsAccurate) {
            if (isLoadingValue(allRows)) return undefined;

            actualRows = allRows.asMutatingArray().filter(isRowIncluded);
            needsSort = true;
        } else {
            // Only necessary to make TS happy
            needsSort = false;
        }

        if (actualRows !== undefined) {
            if (needsSort) {
                sort(actualRows);
            }

            actualRows = applyQueryLimit(this.serialized, actualRows);

            return new MutableTable(actualRows, errorMessage);
        } else {
            return undefined;
        }
    }
}

export class RunQueryLocallyHandler extends RunQueryHandlerBase {
    constructor(
        query: Query,
        computedColumnsPath: RootPath,
        columnPaths: ReadonlyMap<string, Path>,
        columns: readonly TableColumn[]
    ) {
        super("run-query-locally", query, computedColumnsPath, columnPaths, columns);
    }

    protected recomputeQuery(
        ns: Namespace,
        allRows: Table | LoadingValue,
        isRowIncluded: (r: Row) => boolean,
        sort: (rows: Row[]) => void,
        wrapper: LoadingValueWrapper
    ): MutableTable | undefined {
        if (isLoadingValue(allRows)) return undefined;

        let actualRows = allRows.asMutatingArray().filter(isRowIncluded);

        sort(actualRows);

        actualRows = applyQueryLimit(this.serialized, actualRows);

        actualRows = applyQueryGroupBy(this.serialized, this.columns, actualRows, (row, cn) => {
            const path = this.columnPaths.get(cn);
            if (path === undefined) return undefined;
            return wrapper.unwrap(getValueAt(ns, row, path));
        });

        return new MutableTable(actualRows);
    }
}
