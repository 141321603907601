let i = 0;

export const BUILDER_MAIN_HFLOW_INDEX = i++;
export const BUILDER_TOP_NAV_BAR_INDEX = i++;

export const BUILDER_CENTER_AREA = i++;
export const BUILDER_LEFT_ADD_PANEL = i++;
export const BUILDER_LEFT_PANEL = i++;
export const BUILDER_RIGHT_ADD_PANEL = i++;
export const BUILDER_RIGHT_PANEL = i++;
export const BUILDER_SEARCH_PANEL = i++;

export const BUILDER_BUSY_CONTAINER = i++;
