/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

const measurementID = glide.makeParameter({
    type: "string",
    name: "Measurement ID",
    description:
        "The Measurement ID of your Google Analytics property. [Learn more](https://support.google.com/analytics/answer/9304153#add-tag&zippy=%2Cadd-the-google-tag-directly-to-your-web-pages).",
    placeholder: "e.g. G-ABC1234567",
    required: true,
});

export const plugin = glide.newPlugin({
    name: "Google Analytics",
    id: "google-analytics-non-ua",
    description: "Track app traffic and user behavior",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/ganalytics.png",
    tier: "starter",

    parameters: { measurementID },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/google-analytics",
});

plugin.addHeader(({ measurementID }) => {
    if (measurementID?.length !== 12) {
        return `<script>console.log("Invalid Google Analytics measurement ID")</script>`;
    }
    return `
        <!-- Google tag (gtag.js) -->
        <script async src="https://www.googletagmanager.com/gtag/js?id=${measurementID}"></script>
        <script>
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${measurementID}');
        </script>
        `;
});

plugin.setEventTracker((_params, event) => {
    const { gtag } = window as any;
    if (gtag === undefined) return;
    switch (event.kind) {
        case "load":
            gtag("event", "load");
            break;
        case "identify":
            gtag("event", "identify", event);
            break;
        case "navigate":
            gtag("event", "navigate", { title: event.title });
            break;
        case "action":
            gtag("event", event.name, event.data);
            break;
    }
});

const event = glide.makeParameter({
    type: "string",
    name: "Event name",
    required: true,
    useTemplate: "withLabel",
});

const properties = glide.makeParameter({
    type: "stringObject",
    name: "properties",
    description: "Event properties",
});

plugin.addClientAction({
    id: "send-gtag-event",
    name: "Track custom event",
    description: "Send a custom event to Google Tag Manager",
    parameters: { event, properties },
    needsClient: true,
    async execute(_context, { event, properties }) {
        const { gtag } = window as any;
        if (gtag === undefined) {
            return glide.Result.Fail("Google Tag Manager not yet loaded.", {
                isPluginError: false, // ad blockers cause this
            });
        }

        gtag("event", event, properties);
        return glide.Result.Ok();
    },
});
