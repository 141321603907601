import type { NotificationTarget } from "@glide/plugins";
import type { JSONObject } from "@glide/support";
import { checkString, parseJSONSafely } from "@glide/support";
import { definedMap, hasOwnProperty } from "@glideapps/ts-necessities";

export function parseSendPinForEmailResponse(responseText: string | undefined): {
    link: string | undefined;
    sendMethod: NotificationTarget["method"];
    sendTarget: string | undefined;
    sendProvider: string;
} {
    const responseBody = definedMap(responseText, parseJSONSafely) as JSONObject | undefined;

    if (responseBody === undefined && typeof responseText === "string") {
        // Backward compatibility
        return { link: responseText, sendMethod: "email", sendTarget: undefined, sendProvider: "glide" };
    }

    let link: string | undefined;
    if (hasOwnProperty(responseBody, "link") && typeof responseBody.link === "string") {
        link = checkString(responseBody.link);
    }

    let sendTarget: string | undefined;
    if (hasOwnProperty(responseBody, "sendTarget") && typeof responseBody.sendTarget === "string") {
        sendTarget = responseBody.sendTarget;
    }

    let sendMethod: NotificationTarget["method"] = "email";
    if (hasOwnProperty(responseBody, "sendMethod") && responseBody.sendMethod === "sms") {
        sendMethod = "sms";
    }

    let sendProvider: string = "glide";
    if (hasOwnProperty(responseBody, "sendProvider") && typeof responseBody.sendProvider === "string") {
        sendProvider = responseBody.sendProvider;
    }

    return { link, sendMethod, sendTarget, sendProvider };
}
