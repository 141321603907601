import * as t from "io-ts";
import { tierCodec } from "@glide/plugins-codecs";
import { isLeft } from "fp-ts/lib/Either";
import { scheduleKindCodec, automationTriggerKind, automationMaxStepsCodec } from "@glide/automations-codecs";

// NOTE: These types are serialized and must not be changed in a
// backward-incompatible way!

/**
 * Definitions
 *
 * EminenceFlags   - The type of a single instance of an entitlement. Name is a legacy reference to the old codebase
 *                   and is not changed in order to minimize migration. Should be Entitlements.
 *
 * Enititlement    - An object containing an EminenceFlags, an id, and a timestamp.
 *
 * SkuEntitlementHistory - A object which representing the entitlements history of a SKU.
 */

const quotaCodec = t.exact(
    t.type({
        prepaidUsage: t.number,
        maxOverage: t.number,
    })
);

export type Quota = t.TypeOf<typeof quotaCodec>;

export const eminenceFlagQuotasCodec = t.exact(
    t.type({
        "airtable-rows-used": quotaCodec,
        "app-editors": quotaCodec,
        "barcodes-scanned": quotaCodec,
        "big-table-rows": quotaCodec,
        "deliver-email": quotaCodec,
        "file-bytes-used": quotaCodec,
        geocodes: quotaCodec,
        "map-pins": quotaCodec,
        "private-users": quotaCodec,
        "public-users": quotaCodec,
        "published-apps": quotaCodec,
        reloads: quotaCodec,
        "rows-used": quotaCodec,
        signatures: quotaCodec,
        "sql-rows": quotaCodec,
        updates: quotaCodec,
        users: quotaCodec, // unified public/private
        zaps: quotaCodec,
    })
);

export type EminenceFlagQuotas = t.TypeOf<typeof eminenceFlagQuotasCodec>;

const booleanEminenceFlagsCodec = t.intersection([
    t.type({
        appLoginsSheet: t.boolean,
        appUserRequestAccessInPrivateProjectsDefault: t.boolean,
        automaticRefresh: t.boolean,
        base64EncodePublicUserProfiles: t.boolean,
        bigQuery: t.boolean,
        bigTables: t.boolean,
        buyButton: t.boolean,
        canAccessAnalytics: t.boolean,
        canChangeAuthType: t.boolean,
        canCreateSupportTicket: t.boolean,
        canCustomizeSignIn: t.boolean,
        canDisableShare: t.boolean,
        canDowngrade: t.boolean,
        canPausePublish: t.boolean,
        canSubmitTemplate: t.boolean,
        canTransferBetweenUsers: t.boolean,
        canTransferToOrg: t.boolean,
        canUpgrade: t.boolean,
        canUseSignInWithGoogle: t.boolean,
        confirmChangeAuthOnUpgrade: t.boolean,
        customDomains: t.boolean,
        customSignInAppearance: t.boolean,
        downgradeBeforeDelete: t.boolean,
        gmailForSendEmails: t.boolean,
        googleAnalytics: t.boolean,
        googleSharedDrive: t.boolean,
        integrationsCountTowardUpdatesQuota: t.boolean,
        isAgency: t.boolean,
        isFreeEminence: t.boolean,
        isPrivateProTrial: t.boolean,
        isUserMetered: t.boolean,
        isV1Pro: t.boolean,
        logOrgLogin: t.boolean,
        lowTransactionFees: t.boolean,
        needsBuilderModifyAppFeatures: t.boolean,
        needsPublishableOrgToTransfer: t.boolean,
        newNewAppFlow: t.boolean,
        offlineActionQueue: t.boolean,
        pagesCustomCss: t.boolean,
        personalUsersOnly: t.boolean,
        proComponents: t.boolean,
        proDisplayQuotas: t.boolean,
        proIcons: t.boolean,
        protectedColumns: t.boolean,
        removeBranding: t.boolean,
        roles: t.boolean,
        rowQuotasIncludeBigTables: t.boolean,
        showQuotaBanners: t.boolean,
        showReportApp: t.boolean,
        shouldUseNewPrivacy: t.boolean,
        signInAgreements: t.boolean,
        supportsQuotaUpgrades: t.boolean,
        tabletMode: t.boolean,
        unlimitedPasswordAndTeamMembersOnlyUsers: t.boolean,
        unlimitedZapier: t.boolean,
        upgradeButton: t.boolean,
        virtualEmailAddressesInPublicProjectsDefault: t.boolean,
        withGrandfathering: t.boolean,
        queryTablesAPICountsTowardUpdatesQuota: t.boolean,
        mutateTablesAPICountsTowardUpdatesQuota: t.boolean,
        signaturesCountTowardUpdatesQuota: t.boolean,
        // CRUD actions on internal data sources should count as an update
        userCRUDActionsOnInternalDataSourcesCountTowardUpdatesQuota: t.boolean,
        // CRUD actions on external data sources should count as an update
        userCRUDActionsOnExternalDataSourcesCountTowardUpdatesQuota: t.boolean,
        // Can the org be deleted by the org's admin, or does it require support?
        // https://github.com/glideapps/glide/issues/24912
        deletingOrgRequiresSupport: t.boolean,
        // Count PgMirror syncs (Airtable, Excel) as updates? (default is false)
        pgMirrorSyncsCountTowardUpdatesQuota: t.boolean,
    }),
    t.partial({
        // Count Glide API v2 usage as updates? (default is false)
        glideAPIv2CountsTowardUpdatesQuota: t.boolean,
    }),
]);

type BooleanEminenceFlags = t.TypeOf<typeof booleanEminenceFlagsCodec>;

type BooleanEminenceFlagsKeys = keyof BooleanEminenceFlags;

export const booleanEminenceFlagsKeys: BooleanEminenceFlagsKeys[] = booleanEminenceFlagsCodec.types.flatMap(
    ty => Object.keys(ty.props) as (keyof typeof ty.props)[]
);

export const eminenceFlagsCodec = t.intersection([
    t.exact(
        t.type({
            allowedPlugins: t.array(t.string),
            allowRealEmails: t.union([t.literal("private"), t.literal("public"), t.boolean]),
            apiCapabilities: t.exact(
                t.type({
                    mutateTables: t.boolean,
                    queryTables: t.boolean,
                    requestReload: t.boolean,
                    createAnonymousUser: t.boolean,
                })
            ),
            badge: t.union([t.string, t.undefined]),
            canAppUserRequestAccess: t.union([t.literal("private"), t.literal("public"), t.boolean]),
            displayName: t.string,
            downgradeBeforeDeleteStripeCancellation: t.union([t.boolean, t.undefined, t.literal("immediately")]),
            includedWhitelabels: t.number,
            maxSingleUploadBytes: t.number,
            pluginTier: tierCodec,
            quotaContext: t.union([t.literal("app"), t.literal("org")]),
            quotaEnforcementScheme: t.union([t.literal("free"), t.literal("paid"), t.undefined]),
            stripeTransactionFeePercentage: t.number,
            transferToOrgAs: t.union([t.literal("internal"), t.literal("public"), t.literal("free"), t.undefined]),
            authenticationSupport: t.exact(
                t.type({
                    public: t.union([t.boolean, t.literal("upgrade")]),
                    publicWithEmail: t.union([t.boolean, t.literal("upgrade")]),
                    publicDisabled: t.union([t.boolean, t.literal("upgrade")]),
                    password: t.union([t.boolean, t.literal("upgrade")]),
                    emailWhitelist: t.union([t.boolean, t.literal("upgrade")]),
                    userProfiles: t.union([t.boolean, t.literal("upgrade")]),
                    allowedEmailDomains: t.union([t.boolean, t.literal("upgrade")]),
                    organizationMembers: t.union([t.boolean, t.literal("upgrade")]),
                    justMe: t.union([t.boolean, t.literal("upgrade")]),
                    optionalPublicWithEmail: t.union([t.boolean, t.literal("upgrade")]),
                    optionalOtherEmail: t.union([t.boolean, t.literal("upgrade")]),
                })
            ),
            pluginAllowList: t.array(t.string),
            pluginDenyList: t.array(t.string),
            quotas: eminenceFlagQuotasCodec,
            actionLogsHistoryLength: t.number,
            automationTriggerScheduleBillableConsumed: t.number,
            automationScheduleKind: t.array(scheduleKindCodec),
            automationTriggerKind: t.array(automationTriggerKind),
            automationMaxStepsLimit: automationMaxStepsCodec,
        })
    ),
    t.exact(
        t.partial({
            // LEAVING THIS TYPO HERE UNTIL WE HAVE BOTH IN PROD
            automaionTriggerKind: t.array(automationTriggerKind),
        })
    ),
    t.exact(booleanEminenceFlagsCodec),
]);

export type EminenceFlags = t.TypeOf<typeof eminenceFlagsCodec>;

export const defaultEminenceFlagsValues: EminenceFlags = {
    allowRealEmails: true,
    apiCapabilities: {
        mutateTables: false,
        queryTables: false,
        requestReload: false,
        createAnonymousUser: false,
    },
    appLoginsSheet: true,
    appUserRequestAccessInPrivateProjectsDefault: true,
    automaticRefresh: false,
    badge: undefined,
    base64EncodePublicUserProfiles: true,
    bigQuery: false,
    bigTables: false,
    buyButton: true,
    canAccessAnalytics: false,
    canAppUserRequestAccess: false,
    canChangeAuthType: true,
    canCreateSupportTicket: false,
    canCustomizeSignIn: false,
    canDisableShare: false,
    canDowngrade: false,
    canPausePublish: false,
    canSubmitTemplate: true,
    canTransferBetweenUsers: false,
    canTransferToOrg: true,
    canUpgrade: false,
    canUseSignInWithGoogle: true,
    confirmChangeAuthOnUpgrade: true,
    customDomains: false,
    customSignInAppearance: false,
    displayName: "Free",
    downgradeBeforeDelete: true,
    downgradeBeforeDeleteStripeCancellation: undefined,
    gmailForSendEmails: false,
    googleAnalytics: false,
    googleSharedDrive: false,
    includedWhitelabels: 0,
    integrationsCountTowardUpdatesQuota: false,
    isAgency: false,
    isFreeEminence: false,
    isPrivateProTrial: false,
    isUserMetered: false,
    isV1Pro: false,
    logOrgLogin: false,
    lowTransactionFees: false,
    maxSingleUploadBytes: 1024,
    needsBuilderModifyAppFeatures: false,
    needsPublishableOrgToTransfer: false,
    newNewAppFlow: false,
    offlineActionQueue: false,
    pagesCustomCss: false,
    personalUsersOnly: false,
    pluginTier: "free",
    proComponents: false,
    proDisplayQuotas: false,
    proIcons: false,
    protectedColumns: false,
    quotaContext: "app",
    quotaEnforcementScheme: undefined,
    removeBranding: false,
    roles: false,
    rowQuotasIncludeBigTables: false,
    shouldUseNewPrivacy: true,
    showQuotaBanners: true,
    showReportApp: true,
    signInAgreements: false,
    stripeTransactionFeePercentage: 5,
    supportsQuotaUpgrades: true,
    tabletMode: false,
    transferToOrgAs: "public",
    unlimitedPasswordAndTeamMembersOnlyUsers: false,
    unlimitedZapier: false,
    upgradeButton: true,
    virtualEmailAddressesInPublicProjectsDefault: false,
    withGrandfathering: false,
    authenticationSupport: {
        public: true,
        publicWithEmail: true,
        publicDisabled: false,
        password: false,
        emailWhitelist: false,
        userProfiles: false,
        allowedEmailDomains: true,
        organizationMembers: false,
        justMe: false,
        optionalPublicWithEmail: true,
        optionalOtherEmail: false,
    },
    pluginAllowList: [],
    pluginDenyList: [],
    allowedPlugins: [],
    quotas: {
        "airtable-rows-used": { prepaidUsage: 500, maxOverage: 0 },
        "app-editors": { prepaidUsage: 2, maxOverage: 0 },
        "barcodes-scanned": { prepaidUsage: 0, maxOverage: 0 },
        "big-table-rows": { prepaidUsage: 100_000, maxOverage: 0 },
        "deliver-email": { prepaidUsage: 0, maxOverage: 0 },
        "file-bytes-used": { prepaidUsage: 200 * 1024 * 1024, maxOverage: 0 },
        geocodes: { prepaidUsage: 10, maxOverage: 0 },
        "map-pins": { prepaidUsage: 10, maxOverage: 0 },
        "private-users": { prepaidUsage: 3, maxOverage: 0 },
        "public-users": { prepaidUsage: 10, maxOverage: 0 },
        "published-apps": { prepaidUsage: 3, maxOverage: 0 },
        reloads: { prepaidUsage: 1000, maxOverage: Number.MAX_SAFE_INTEGER },
        "rows-used": { prepaidUsage: 500, maxOverage: 0 },
        signatures: { prepaidUsage: 0, maxOverage: 0 },
        "sql-rows": { prepaidUsage: 100_000, maxOverage: 0 },
        updates: { prepaidUsage: 1000, maxOverage: 0 },
        users: { prepaidUsage: Number.MAX_SAFE_INTEGER, maxOverage: 0 },
        zaps: { prepaidUsage: Number.MAX_SAFE_INTEGER, maxOverage: 0 },
    },
    queryTablesAPICountsTowardUpdatesQuota: false,
    mutateTablesAPICountsTowardUpdatesQuota: true,
    glideAPIv2CountsTowardUpdatesQuota: false,
    signaturesCountTowardUpdatesQuota: false,
    userCRUDActionsOnInternalDataSourcesCountTowardUpdatesQuota: true,
    userCRUDActionsOnExternalDataSourcesCountTowardUpdatesQuota: true,
    deletingOrgRequiresSupport: false,
    actionLogsHistoryLength: 7,
    automationTriggerScheduleBillableConsumed: 1,
    automationScheduleKind: [],
    automationTriggerKind: ["app"],
    automaionTriggerKind: ["app"],
    automationMaxStepsLimit: 2000,
    pgMirrorSyncsCountTowardUpdatesQuota: false,
};

export type Entitlements = {
    readonly id: string;
    readonly timestamp: Date;
    readonly flags: EminenceFlags;
    readonly shortName?: string;
    readonly comments?: string;
    readonly referenceUrl?: string;
    readonly kind: "fallback" | "legacy" | "defined" | "free-override";
};

export type SkuEntitlementsHistory = {
    readonly id: string;
    readonly name: string;
    readonly entitlements: readonly Entitlements[];
};

export type EntitlementsOverlay = {
    id: string;
    ownerId: string;
    timestamp: Date;
    overlay: object;
};

export interface EntitlementsClient {
    /**
     * This returns the base entitlements history for all SKUs
     */
    readonly getAllEntitlementsHistory: () => Promise<Record<string, SkuEntitlementsHistory>>;
    /**
     * This returns the modified entitlements for an owner based on the SKU entitlements and owner settings
     */
    readonly getEntitlementsForOwnerID: (ownerID: string, allowOverages?: boolean) => Promise<Entitlements>;
    /**
     * This returns the base entitlements for a SKU
     */
    readonly getEntitlementsForPriceID: (priceID: string) => Promise<Entitlements>;
    /**
     * This returns the base entitlements history for a SKU
     */
    readonly getEntitlementsHistoryForPriceID: (priceID: string) => Promise<SkuEntitlementsHistory>;
    readonly updateEntitlementsFieldsForId: (
        id: string,
        comments: string,
        referenceUrl: string,
        shortName: string
    ) => Promise<void>;
    readonly upsertEntitlementsForPriceID: (
        priceID: string,
        flags: EminenceFlags,
        comments?: string,
        referenceUrl?: string,
        shortName?: string
    ) => Promise<boolean>;

    /**
     * Returns the entitlements overlay for a given owner ID, or undefined if not found.
     */
    readonly getEntitlementsOverlayForOwnerID: (ownerID: string) => Promise<EntitlementsOverlay | undefined>;

    /**
     * Returns the entitlements overlays history for a given owner ID.
     */
    readonly getEntitlementsOverlaysHistoryForOwnerID: (ownerID: string) => Promise<EntitlementsOverlay[]>;

    /**
     * Inserts an entitlements overlay for the given owner ID.
     */
    readonly insertEntitlementsOverlayForOwnerID: (ownerID: string, timestamp: Date, overlay: object) => Promise<void>;

    /**
     * Retrieves all the owner IDs that have entitlements overlays,
     */
    readonly getAllOwnerIDsWithEntitlementsOverlays: () => Promise<string[]>;
}

/**
 * Validates an eminence flags object.
 *
 * @param unvalidatedEminenceFlags An unvalidated eminence flags object
 * @returns The validated eminence flags object, or undefined if the input is invalid.
 */
export function validateEminenceFlags(unvalidatedEminenceFlags: unknown): EminenceFlags | undefined {
    const decodeResult = eminenceFlagsCodec.decode(unvalidatedEminenceFlags);
    if (isLeft(decodeResult)) {
        return undefined;
    }

    return decodeResult.right;
}

/**
 * Determines whether an eminence flags object is complete or not.
 * @param eminenceFlags The unvalidated eminence flags object
 * @returns True if the eminence flags object is complete, false otherwise
 */
export function eminenceFlagsAreComplete(eminenceFlags: unknown): boolean {
    const validatedEminenceFlags = validateEminenceFlags(eminenceFlags);
    return validatedEminenceFlags !== undefined;
}
