import type {
    ComponentDescription,
    ComponentKind,
    MutatingScreenKind,
    PropertyDescription,
} from "@glide/app-description";
import { makeEmptyComponentDescription, type InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireAppLocationComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
    isRequiredPropertyHandler,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type WireInflationBackend, type WireRowComponentHydratorConstructor, WireComponentKind } from "@glide/wire";
import isBoolean from "lodash/isBoolean";
import { makeSimpleWireRowComponentHydratorConstructor } from "../wire/utils";
import { ComponentHandlerBase } from "./handler";

const ComponentKindLocationEntry: ComponentKind = "location-entry";

const propertyNamePropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        // ColumnPropertyFlag.DefaultCaption,
    ],
    undefined,
    ["location", "coordinates"],
    getPrimitiveColumnsSpec,
    "string",
    PropertySection.Data
);

interface LocationEntryComponentDescription extends ComponentDescription {
    readonly propertyName: PropertyDescription;
    readonly isRequired: PropertyDescription | undefined;
}

export class LocationEntryComponentHandler extends ComponentHandlerBase<LocationEntryComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindLocationEntry);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public needValidation(): boolean {
        return true;
    }

    public getDescriptor(
        _desc: LocationEntryComponentDescription,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        _mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Location",
            description: "Enter the current location",
            img: "co-location",
            group: "Entry Fields",
            helpUrl: getDocURL("locationEntry"),
            quotaWarning: ccc.eminenceFlags.proComponents ? undefined : "Enter 10 locations per month on free apps.",
            properties: [...this.getBasePropertyDescriptors(), propertyNamePropertyHandler, isRequiredPropertyHandler],
        };
    }

    public inflate(
        _ib: WireInflationBackend,
        desc: LocationEntryComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const isRequired = isRequiredPropertyHandler.getSwitch(desc);
        const columnName = propertyNamePropertyHandler.getColumnName(desc);
        if (columnName === undefined) return undefined;

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const onChangeToken = hb.registerOnValueChange("value", columnName);
            if (onChangeToken === false) return undefined;

            const switchedOn = hb.getState("switchedOn", isBoolean, false, false);

            const component: WireAppLocationComponent = {
                kind: WireComponentKind.AppLocation,
                isRequired,
                switchedOn,
                value: { value: "", onChangeToken },
            };
            return {
                component,
                isValid: !isRequired || switchedOn.value,
                hasValue: switchedOn.value,
            };
        });
    }

    public convertToPage(
        desc: LocationEntryComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return {
            ...makeEmptyComponentDescription(WireComponentKind.Location),
            value: desc.propertyName,
        } as ComponentDescription;
    }
}
