import { assertNever, definedMap } from "@glideapps/ts-necessities";
import { styled } from "@glide/common";
import { css } from "styled-components";

export const HeightVariations = ["xsmall", "medium", "taller", "xtall", "2xtall"] as const;
export type HeightVariation = (typeof HeightVariations)[number];

export const Input = styled.input`
    /* Min will force override the browsers default min-width which can cause issues with overflow in certain browsers. */
    min-width: 0px;
    width: 100%;
    padding: 0 12px;
    align-self: stretch;
    color: ${p => p.theme.fgColorDark};
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    background-color: ${p => p.theme.formControlBg};
    transition: background-color ${p => p.theme.transitionBase};
    &:hover,
    &:hover + .postfix,
    &:focus,
    &:focus + .postfix {
        background-color: ${p => p.theme.formControlBgHover};
    }

    ::placeholder {
        color: ${p => p.theme.fgColorLight};
    }
`;

export const TextArea = styled.textarea`
    ::placeholder {
        color: ${p => p.theme.fgColorLight};
    }
`;

interface InputWrapperProps {
    highlightWarning?: boolean;
    lightBorder?: boolean;
    focusBorder?: boolean;
    disabled?: boolean;
    hasPostfix: boolean;
    heightVariation?: HeightVariation;
    borderStyle?: boolean;
}

const heightVariationToFontSize = (h: HeightVariation): number => {
    switch (h) {
        case "xsmall":
        case "medium":
        case "taller":
            return 13;
        case "xtall":
        case "2xtall":
            return 15;
        default:
            assertNever(h);
    }
};

const heightVariationToXPadding = (h: HeightVariation): number => {
    switch (h) {
        case "xsmall":
            return 8;
        case "medium":
        case "taller":
            return 12;
        case "xtall":
        case "2xtall":
            return 20;
        default:
            assertNever(h);
    }
};

const heightVariationToPx = (h: HeightVariation): number => {
    switch (h) {
        case "xsmall":
            return 24;
        case "medium":
            return 36;
        case "taller":
            return 40;
        case "xtall":
            return 48;
        case "2xtall":
            return 56;
        default:
            assertNever(h);
    }
};

const heightVariationToBorderWidth = (h: HeightVariation | undefined): number => {
    switch (h) {
        case undefined:
        case "xsmall":
        case "medium":
        case "taller":
        case "xtall":
        case "2xtall":
            return 1;
        default:
            assertNever(h);
    }
};

const heightVariationToBorderRadius = (h: HeightVariation | undefined): number => {
    switch (h) {
        case "xsmall":
            return 6;
        case undefined:
        case "medium":
        case "taller":
            return 8;
        case "xtall":
        case "2xtall":
            return 10;
        default:
            assertNever(h);
    }
};

export const InputWrapper = styled.div<InputWrapperProps>`
    align-items: center;
    height: ${p => definedMap(p.heightVariation, heightVariationToPx) ?? 32}px;
    border-radius: ${p => heightVariationToBorderRadius(p.heightVariation)}px;
    display: flex;
    position: relative;

    input {
        ${p =>
            p.heightVariation !== undefined &&
            css`
                font-size: ${heightVariationToFontSize(p.heightVariation)}px;
                padding: 0 ${heightVariationToXPadding(p.heightVariation)}px;
            `}
        border: ${p => heightVariationToBorderWidth(p.heightVariation)}px solid ${p =>
            p.highlightWarning ? p.theme.destroyColor : "transparent"};
        border-radius: ${p => {
            const size = heightVariationToBorderRadius(p.heightVariation);
            return p.hasPostfix ? `${size}px 0px 0px ${size}px` : `${size}px`;
        }};
        &:focus {
            border: ${p => heightVariationToBorderWidth(p.heightVariation)}px solid
                ${p => (p.focusBorder ? p.theme.b400 : "transparent")};
            background-color: ${p => (p.focusBorder ? p.theme.formControlBg : p.theme.formControlBgHover)};
        }
        ${p =>
            p.borderStyle &&
            css`
                background-color: ${p.theme.formControlAltBg};
                border: ${heightVariationToBorderWidth(p.heightVariation)}px solid ${p.theme.n300A};

                &:hover,
                &:hover + .postfix {
                    background-color: ${p.theme.formControlAltBgHover};
                }
                &:focus,
                &:focus + .postfix {
                    background-color: ${p.theme.formControlAltBg};
                    border: ${heightVariationToBorderWidth(p.heightVariation)}px solid
                        ${p.focusBorder ? p.theme.aqua400 : p.theme.n300A};
                }
            `}
    }

    ${p =>
        p.disabled === true &&
        css`
            > input,
            > input:hover,
            > input:focus {
                background-color: ${p.theme.formControlBgDisabled};
                color: ${p.theme.fgColorLight};
            }
        `}
`;

export const Postfix = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${p => p.theme.fgColorMedium};
    background-color: ${p => p.theme.formControlBg};
    transition: background-color ${p => p.theme.transitionBase};
    height: 100%;
    border-radius: 0px 8px 8px 0px;
`;

export const Warning = styled.div`
    font-size: 11px;
    color: ${p => p.theme.destroyColor};
`;

export const BylineWrapper = styled.div`
    margin-top: 4px;
`;
