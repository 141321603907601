import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import {
    type ClassOrArrayScreenDescription,
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    isFormScreen,
} from "@glide/app-description";
import type { Description, TableColumn } from "@glide/type-schema";
import { type InputOutputTables, getScreenComponents } from "@glide/common-core/dist/js/description";
import type {
    AppDescriptionContext,
    InteractiveComponentConfiguratorContext,
    PropertyDescriptor,
} from "@glide/function-utils";
import { assert, definedMap } from "@glideapps/ts-necessities";
import flatten from "lodash/flatten";

import type { ComponentHandler } from "./components/component-handler";
import { handlerForPropertyKind } from "./components/description-handlers";
import { getColumnsUsedInDescription, makePropertyDescriptorsForFormScreen } from "./components/descriptor-utils";
import { getPropertyAndDescriptorFromDescription } from "./description-utils";

const componentHandlers = new Map<ComponentKind, ComponentHandler<ComponentDescription>>();

export function registerComponentHandler<T extends ComponentDescription>(handler: ComponentHandler<T>): void {
    const kind = handler.kind;
    assert(!componentHandlers.has(kind));
    componentHandlers.set(kind, handler as ComponentHandler<any>);
}

export function handlerForComponentKind(kind: ComponentKind): ComponentHandler<ComponentDescription> | undefined {
    return componentHandlers.get(kind);
}

// FIXME: This doesn't take columns used by actions into account.
// It's kinda not really super needed by the current user of this function, but in
// the future we might have to extend this and make it more specific, so that it
// returns input and output columns separately, for example.
function columnsUsedByComponents(
    cds: ReadonlyArray<ComponentDescription>,
    tables: InputOutputTables,
    ccc: AppDescriptionContext,
    includeIndirect: boolean
): readonly TableColumn[] {
    const all = flatten(
        cds.map(cd => definedMap(handlerForComponentKind(cd.kind), h => h.getColumnsUsed(cd, tables, ccc)) ?? [])
    );
    if (includeIndirect) {
        return all;
    } else {
        const directColumns = new Set([...tables.input.columns, ...tables.output.columns]);
        return all.filter(c => directColumns.has(c));
    }
}

// ##columnsAlreadyAssignedInScreen:
// Returns the context's columns used by a class screen.  This is only used to
// determine which columns a new component should be bound to, i.e. it'll
// avoid the columns returned here.
export function columnsUsedByScreen(
    desc: ClassOrArrayScreenDescription,
    tables: InputOutputTables,
    ccc: AppDescriptionContext
): ReadonlySet<TableColumn> {
    const columns = new Set(columnsUsedByComponents(getScreenComponents(desc), tables, ccc, false));
    if (isFormScreen(desc)) {
        const propertyDescriptors = makePropertyDescriptorsForFormScreen(ccc, tables, true);
        const assignmentColumns = getColumnsUsedInDescription(propertyDescriptors, desc, desc, tables, ccc, []).direct;
        for (const c of assignmentColumns) {
            columns.add(c);
        }
    }
    return columns;
}

export function getAllComponentHandlers(): ReadonlyArray<ComponentHandler<ComponentDescription>> {
    return Array.from(componentHandlers.values());
}

export async function duplicateDescription<T extends Description>(
    desc: T,
    rootDesc: Description,
    descriptors: readonly PropertyDescriptor[],
    tables: InputOutputTables,
    iccc: InteractiveComponentConfiguratorContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    screensCreated: Set<string>,
    appFacilities: ActionAppFacilities
): Promise<T> {
    let copy = { ...desc };

    for (const descr of descriptors) {
        const maybeDescription = getPropertyAndDescriptorFromDescription(desc, descr);
        if (maybeDescription === undefined) continue;

        const { description: pd, descriptorCase: pdc } = maybeDescription;
        const handler = handlerForPropertyKind(pdc.kind);

        const updates = await handler.duplicate(
            descr,
            pdc,
            pd,
            desc,
            rootDesc,
            tables,
            mutatingScreenKind,
            iccc,
            screensCreated,
            appFacilities
        );
        copy = { ...copy, ...updates };
    }

    return copy;
}

export async function duplicateComponent(
    c: ComponentDescription,
    copyTables: InputOutputTables,
    iccc: InteractiveComponentConfiguratorContext,
    mutatingScreenKind: MutatingScreenKind | undefined,
    screensCreated: Set<string>,
    appFacilities: ActionAppFacilities
): Promise<ComponentDescription | undefined> {
    return await handlerForComponentKind(c.kind)?.duplicateComponent(
        c,
        c,
        copyTables,
        iccc,
        mutatingScreenKind,
        screensCreated,
        appFacilities
    );
}
