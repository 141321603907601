import { TailwindThemeProvider } from "@glide/common";
import { getFeatureSetting } from "@glide/common-core";
import type { SerializedApp } from "@glide/app-description";
import { getAppFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import {
    InlineCodeScannerRenderer,
    PageCodeScannerScreenRenderer,
    UnifiedChrome,
    useWireAppTheme,
    useWireFrontendActionCallbacks,
    CallbackURLOpenerContext,
    type PagesRendererComponentDependencies,
} from "@glide/wire-renderer";
import { LiveVersionCheckerProvider } from "@glide/common-components";
import { deserializeSearchableColumns } from "@glide/generator/dist/js/components/searchable-columns";
import { fixAppDescription } from "@glide/generator/dist/js/fix-app";
import { AppKind } from "@glide/location-common";
import { assert, defined, definedMap } from "@glideapps/ts-necessities";
import { usePlayerEminenceFlags } from "@glide/player-core";
import * as React from "react";
import { usePluginEvents } from "@glide/plugin-events";
import { type AuthState, usePlayerWireBackend } from "./wire-utils/use-wire-backend";
import { useWireNavigationModel } from "./wire-utils/use-wire-navigation-model";
import type { MinimalAppEnvironment } from "@glide/common-core/dist/js/components/types";
import { useUnconfirmedChangeManager } from "./utils/use-unconfirmed-change-manager";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import type { WirePageNavigationModel } from "@glide/wire";
import { WireAuthController } from "./player-auth/wire-auth-controller";
import { useHistory, useLocation } from "react-router-dom";

interface PagesPlayerWireAppProps {
    readonly appEnvironment: MinimalAppEnvironment;
    readonly serializedApp: SerializedApp;
    readonly authState: AuthState;
}

export const PagesPlayerWireApp: React.FC<PagesPlayerWireAppProps> = p => {
    const { appEnvironment, serializedApp: incomingSerializedApp, authState } = p;
    const { appID, appFacilities } = appEnvironment;
    const [schema, builderActions] = React.useMemo(
        () => [
            defined(appFacilities.getInitialSchemaForAppID?.(appID), "Could not get initial schema for app"),
            new Map(Object.entries(incomingSerializedApp.builderActions ?? {})),
        ],
        [incomingSerializedApp, appFacilities, appID]
    );

    const eminenceFlags = usePlayerEminenceFlags(appID);
    const serializedApp = React.useMemo(
        () =>
            fixAppDescription(appID, incomingSerializedApp, builderActions, schema, eminenceFlags, {
                convertFeatures: false,
                doLog: false,
                fromBuilder: false,
            }),
        [appID, incomingSerializedApp, builderActions, schema, eminenceFlags]
    );
    const theme = useWireAppTheme();

    const routerLocation = useLocation();
    const deepLink = routerLocation.pathname.split("/dl/", 2)[1] ?? "";

    const appFeatures = getAppFeatures(incomingSerializedApp);
    const playSuffix = window.location.hostname === "localhost" ? `/play2/${appID}` : "";

    const searchableColumns = React.useMemo(() => {
        return getFeatureSetting("searchableColumnsInPublishedApp")
            ? definedMap(incomingSerializedApp.searchableColumns, deserializeSearchableColumns)
            : undefined;
    }, [incomingSerializedApp.searchableColumns]);

    const { backend, urlStack } = usePlayerWireBackend(
        appEnvironment,
        serializedApp,
        `${window.location.protocol}//${window.location.host}${playSuffix}`,
        incomingSerializedApp.manifest?.author ?? "",
        builderActions,
        schema,
        eminenceFlags,
        appFeatures,
        searchableColumns,
        deepLink,
        authState
    );
    const navModel = useWireNavigationModel(backend, urlStack);

    let current = navModel === undefined ? undefined : `/dl/${navModel.urlPath}`;

    if (window.location.pathname.startsWith("/play2/")) {
        current = `/play2/${appID}${current}`;
    }
    if (window.location.pathname.startsWith("/play/")) {
        current = `/play/${appID}${current}`;
    }

    usePluginEvents(navModel?.kind === AppKind.Page ? navModel : undefined, serializedApp.pluginConfigs);

    const routerHistory = useHistory();
    React.useEffect(() => {
        if (current !== undefined && window.location.pathname.endsWith(current) === false) {
            routerHistory.push(current);
        }
    }, [current, routerHistory]);

    React.useEffect(() => {
        backend?.urlPathChanged(deepLink);
    }, [backend, deepLink]);

    const [backendProxy, navModelWithChanges] = useUnconfirmedChangeManager(backend, navModel);
    if (backendProxy === undefined || navModelWithChanges === undefined) return null;
    assert(navModelWithChanges.kind === AppKind.Page);

    return (
        <TailwindThemeProvider theme={theme} setPortal={true}>
            <UnifiedWireApp backend={backendProxy} navModel={navModelWithChanges} />
        </TailwindThemeProvider>
    );
};

interface Props extends React.PropsWithChildren {
    readonly navModel: WirePageNavigationModel;
    readonly backend: WireBackendInterface;
}

export const UnifiedWireApp: React.VFC<Props> = p => {
    const { navModel, backend } = p;

    const frontendCallbacks = useWireFrontendActionCallbacks();
    backend.setFrontendActionCallbacks(frontendCallbacks);

    return (
        <CallbackURLOpenerContext.Provider
            value={{
                blockedWindowOpenUrl: frontendCallbacks.blockedWindowOpenUrl,
                resetBlockedWindowUrl: frontendCallbacks.resetBlockedWindowUrl,
            }}>
            <LiveVersionCheckerProvider>
                <UnifiedChrome
                    navModel={navModel}
                    backend={backend}
                    componentDependencies={NOOP_COMPONENT_DEPENDENCIES}
                />
            </LiveVersionCheckerProvider>
        </CallbackURLOpenerContext.Provider>
    );
};

export const NOOP_COMPONENT_DEPENDENCIES: PagesRendererComponentDependencies = {
    WireAuthController,
    PageCodeScannerScreenRenderer,
    InlineCodeScannerRenderer,
    ContainerBuilderEmptyState: () => {
        throw new Error("There shouldn't be any builder dependencies in the player");
    },
};
