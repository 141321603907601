import "twin.macro";

import { type IconName, AppIcon, GlideIcon, getNamedIcon, isMonotoneIcon } from "@glide/common";
import { isEmptyOrUndefinedish } from "@glide/support";
import { Img } from "@glide/wire-renderer";
import classNames from "classnames";
import * as React from "react";
import { mergeRefs } from "react-laag";

import { UpgradePill } from "../paywall/upgrade-pill";
import { type InnerItemDescription, isGroup, isHeader } from "../../lib/dropdown-types";
import { ThemedIcon } from "../themed-icon/themed-icon";
import { DropdownItemStyle } from "./dropdown-item-style";
import { glideIconPropsCodec } from "@glide/plugins-codecs";
import { AccentPill } from "../pills";

declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.JSX.Element
    ): (props: P & React.RefAttributes<T>) => React.JSX.Element;
}

interface Props<T> {
    desc: InnerItemDescription<T>;
    showGroupCounts?: boolean;
    showSearchLineage?: boolean | number; // if true, show all lineage, if number, show the last n items
    onHoverChange?: (hovered: boolean) => void;
    onItemClick: (item: InnerItemDescription<T> | null, e?: React.MouseEvent<Element, MouseEvent> | undefined) => void;
    secondaryActionIcon?: string | React.ReactNode;
    hovered?: boolean;
    onSecondaryActionClick?: (
        item: InnerItemDescription<T> | null,
        e?: React.MouseEvent<Element, MouseEvent> | undefined
    ) => void;
}

function formatAccel(accel: string): React.ReactNode {
    const formatted = accel
        .split("+")
        .map(s =>
            s
                .replace("ctrl", "Ctrl+")
                .replace("alt", "Alt+")
                .replace("shift", "⇪")
                .replace("command", "⌘")
                .replace("up", "↑")
                .replace("delete", "Del")
                .replace("down", "↓")
                .replace("left", "←")
                .replace("right", "→")
                .replace(/^[a-z]$/, sub => sub.toUpperCase())
        )
        .join("");

    if (!formatted.includes("⌘")) return formatted;

    const split = formatted.split("⌘", 2);
    const [left, right] = split;

    return (
        <div className="format-container">
            {!isEmptyOrUndefinedish(left) && <div>{left}</div>}
            <AppIcon className="command-icon" size={8} icon={"01-40-keyboard-command"} />
            {!isEmptyOrUndefinedish(right) && <div>{right}</div>}
        </div>
    );
}

export const DropdownItem = React.forwardRef(DropdownItemInner);

function DropdownItemInner<T>(p: Props<T>, ref: React.Ref<HTMLLIElement>) {
    const {
        desc,
        onItemClick,
        onSecondaryActionClick,
        onHoverChange,
        hovered,
        showGroupCounts,
        showSearchLineage = true,
    } = p;

    function iconForDescription(x: InnerItemDescription<T>) {
        let icon: React.ReactNode;
        if (typeof x.icon === "string") {
            if (x.icon.startsWith("https://") || x.icon.startsWith("/")) {
                icon = <Img src={x.icon} tw="w-4 h-4 rounded" />;
            } else if (isMonotoneIcon(x.icon)) {
                icon = <GlideIcon iconSize={16} kind="monotone" icon={x.icon} />;
            } else if (getNamedIcon(x.icon as IconName) !== undefined) {
                icon = <ThemedIcon icon={x.icon as IconName} />;
            } else {
                icon = <AppIcon size={16} icon={x.icon} />;
            }
        } else if (glideIconPropsCodec.is(x.icon)) {
            icon = <GlideIcon {...x.icon} iconSize={16} />;
        } else {
            icon = x.icon;
        }

        if (x.overlayIcon !== undefined) {
            icon = (
                <div className="overlay-wrapper">
                    {icon}
                    <div className="overlay-icon">
                        <ThemedIcon icon={x.overlayIcon} />
                    </div>
                </div>
            );
        }

        if (icon === undefined) return undefined;
        return (
            <div className="icon-wrapper" style={x.iconColor !== undefined ? { color: x.iconColor } : undefined}>
                {icon}
            </div>
        );
    }

    let actionIcon: React.ReactNode;
    if (typeof desc.actionIcon === "string") {
        if (getNamedIcon(desc.actionIcon as IconName) !== undefined) {
            actionIcon = <ThemedIcon icon={desc.actionIcon as IconName} />;
        } else {
            actionIcon = <AppIcon size={12} icon={desc.actionIcon} />;
        }
    } else {
        actionIcon = desc.actionIcon;
    }

    const icon = iconForDescription(desc);
    const group = isGroup(desc.item);
    let hint = desc.hint;
    if (hint === undefined && group && showGroupCounts === true) {
        const count = desc.children?.length ?? 0;
        hint = count === 1 ? "1 item" : `${count} items`;
    }

    const { badge, lineage, requiredPlan, requiredV4Plan } = desc;
    const hasLineage = showSearchLineage && (lineage?.length ?? 0) > 0;

    const badgeNode =
        badge !== undefined ? (
            <div tw="ml-2 text-builder-xs bg-n300A text-text-pale px-1.5 rounded-full">{badge}</div>
        ) : null;

    let mainContent: React.ReactNode;
    if (hasLineage) {
        let firstHasIcon: boolean | undefined;
        const maybeSlicedLineage =
            showSearchLineage === true || showSearchLineage === undefined
                ? lineage
                : lineage?.slice(-(showSearchLineage - 1));
        mainContent = (
            <div className="wrapping-area">
                <div className={classNames("name", desc.wrapName === true && "name-wrap")}>
                    {maybeSlicedLineage?.map((i, index) => {
                        const groupIcon = iconForDescription(i);
                        if (firstHasIcon === undefined) {
                            firstHasIcon = groupIcon !== undefined;
                        }
                        return (
                            <React.Fragment key={index}>
                                {groupIcon}
                                <div className="name-part">{i.name}</div>
                                <GlideIcon
                                    className="lineage-marker"
                                    kind="stroke"
                                    rotateDeg={-90}
                                    icon={"st-caret"}
                                    strokeWidth={1}
                                    iconSize={16}
                                />
                            </React.Fragment>
                        );
                    })}
                    {icon}
                    <div className="name-trail">{desc.name}</div>
                    {badgeNode}
                </div>
                {hint !== undefined && <div className={classNames("hint", firstHasIcon && "icon-pad")}>{hint}</div>}
            </div>
        );
    } else {
        mainContent = (
            <>
                {icon}
                <div className="wrapping-area">
                    <div className={classNames("name", desc.wrapName === true && "name-wrap")}>
                        <div className="name-part">
                            {desc.namePrefix !== undefined && <span tw="text-text-xpale">{desc.namePrefix} | </span>}
                            {desc.name}
                        </div>
                        {badgeNode}
                    </div>
                    {hint !== undefined && <div className="hint">{hint}</div>}
                </div>
            </>
        );
    }

    const onMouseEnter = React.useCallback(
        (_event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            onHoverChange?.(true);
        },
        [onHoverChange]
    );

    const onMouseLeave = React.useCallback(
        (_event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            onHoverChange?.(false);
        },
        [onHoverChange]
    );

    const rootRef = React.useRef<HTMLLIElement>(null);
    React.useEffect(() => {
        if (hovered && rootRef.current !== null) {
            rootRef.current.scrollIntoView({
                behavior: "auto",
                block: "nearest",
            });
        }
    }, [hovered]);

    return (
        <DropdownItemStyle
            key={desc.name}
            data-testid={desc.name}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={classNames(
                hovered === true && "hovered",
                desc.layoutStyle === "vertical" && "stack",
                desc.isSelected && "selected",
                (desc.prelight || desc.containsPrelight) && "prelight",
                desc.isEnabled === false && "disabled",
                desc.tooltip !== undefined && "hasTooltip",
                desc.visualStyle,
                isHeader(desc.item) && "header",
                desc.name.toLowerCase().replace(/\s/g, "-")
            )}
            onClick={(e: React.MouseEvent<Element, MouseEvent> | undefined) => onItemClick(desc, e)}
            ref={mergeRefs(ref, rootRef)}
        >
            {mainContent}
            {requiredPlan !== undefined && requiredV4Plan === undefined ? (
                <div tw="ml-2 [&>*]:pointer-events-none">
                    <UpgradePill planKind={requiredPlan} />
                </div>
            ) : null}
            {requiredV4Plan !== undefined ? (
                <div tw="ml-2 [&>*]:pointer-events-none">
                    <AccentPill>{requiredV4Plan}</AccentPill>
                </div>
            ) : null}
            {desc.accelerator !== undefined && <div className="accelerator">{formatAccel(desc.accelerator)}</div>}
            {actionIcon !== undefined && (
                <div
                    className="action-icon"
                    onClick={e => {
                        onSecondaryActionClick?.(desc, e);
                        e.stopPropagation();
                    }}
                >
                    {actionIcon}
                </div>
            )}
            {desc.isSelected && (
                <GlideIcon
                    className="checkmark"
                    kind="stroke"
                    icon="st-menu-checkmark"
                    iconSize={16}
                    strokeWidth={1.5}
                />
            )}
            {group && (
                <GlideIcon
                    className="disclosure"
                    kind="stroke"
                    rotateDeg={-90}
                    icon={"st-caret"}
                    strokeWidth={1.5}
                    iconSize={16}
                />
            )}
        </DropdownItemStyle>
    );
}
