import {
    asString,
    type PrimitiveValue,
    isLoadingValue,
    isPrimitive,
    isPrimitiveValue,
    isBound,
} from "@glide/computation-model-types";
import { AppKind } from "@glide/location-common";
import { isNotEmpty } from "@glide/common-core/dist/js/computation-model/data";
import { type ColumnTypeKind, type TableColumn, getTableColumnDisplayName, isPrimitiveType } from "@glide/type-schema";
import {
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getActionProperty,
    getStringProperty,
    makeColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import {
    type DefaultableComponentDescription,
    type InputOutputTables,
    makeEmptyComponentDescription,
} from "@glide/common-core/dist/js/description";
import type { WireFieldComponentBase } from "@glide/fluent-components/dist/js/base-components";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ColumnPropertyHandler,
    type ComponentDescriptor,
    type PropertyDescriptor,
    PropertySection,
    isRequiredPropertyHandler,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { assert, defined, definedMap, sleep } from "@glideapps/ts-necessities";
import { undefinedIfEmptyString } from "@glide/support";
import {
    type ValueChangeFollowUp,
    type WireComponentHydrationResult,
    type WireComponentPreHydrationResult,
    type WireHydrationFollowUp,
    type WireRowComponentHydrator,
    type WireRowComponentHydratorConstructor,
    type WireComponent,
    ValueChangeSource,
    type WireInflationBackend,
    type WireRowComponentHydrationBackend,
    type WireValueGetter,
} from "@glide/wire";
import isBoolean from "lodash/isBoolean";

import { getDefaultPrimitiveActionKinds } from "../actions";
import { type ValueFormatSpecification, decomposeFormatFormula } from "@glide/formula-specifications";
import { hydrateSubAction, inflateActions, inflateComponentEnricher } from "../wire/utils";
import {
    doesMutatingScreenSupportIsRequired,
    labelCaptionStringOptions,
    makeCaptionStringPropertyDescriptor,
    makeDefaultValuePropertyDescriptor,
    makePlaceholderPropertyDescriptor,
} from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

export interface BaseFieldComponentDescription extends DefaultableComponentDescription {
    readonly placeholder: PropertyDescription;
    readonly title: PropertyDescription;
    readonly isRequired?: PropertyDescription;

    // only used in the text field
    // TODO: Once we get rid of OCM, move this to `TextFieldComponentDescription`
    readonly minLength?: PropertyDescription;
    readonly maxLength?: PropertyDescription;

    readonly onChangeAction?: PropertyDescription;
}

export abstract class BaseFieldComponentHandler<
    T extends BaseFieldComponentDescription
> extends ComponentHandlerBase<T> {
    // `_fieldType` is used for the default value.  If it's `undefined`, default
    // values are not supported.
    constructor(componentKind: string, private readonly _fieldType: ColumnTypeKind | undefined) {
        super(componentKind);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public needValidation(desc: T): boolean {
        return isRequiredPropertyHandler.getSwitch(desc);
    }

    protected getFieldDescriptor(
        desc: BaseFieldComponentDescription | undefined,
        ccc: AppDescriptionContext,
        name: string,
        description: string,
        group: string,
        img: string,
        propertyNamePropertyHandler: ColumnPropertyHandler,
        isLegacy: boolean,
        helpUrl: string,
        mutatingScreenKind: MutatingScreenKind | undefined,
        defaultCaption: string,
        additionalPropertyDescriptors?: PropertyDescriptor[]
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [propertyNamePropertyHandler];
        if (this._fieldType !== undefined) {
            const defaultValuePropertyDescriptor = makeDefaultValuePropertyDescriptor(
                ccc,
                desc?.propertyName,
                mutatingScreenKind,
                this._fieldType
            );
            if (defaultValuePropertyDescriptor !== undefined) {
                properties.push(defaultValuePropertyDescriptor);
            }
        }
        properties.push(
            makeCaptionStringPropertyDescriptor(
                defaultCaption,
                false,
                mutatingScreenKind,
                labelCaptionStringOptions,
                "title",
                true
            ),
            makePlaceholderPropertyDescriptor(mutatingScreenKind, PropertySection.Design, undefined, true)
        );
        if (doesMutatingScreenSupportIsRequired(mutatingScreenKind, desc?.propertyName)) {
            properties.push(isRequiredPropertyHandler);
        }
        properties.push(...(additionalPropertyDescriptors ?? []), ...this.getBasePropertyDescriptors());

        if (ccc.appKind === AppKind.Page && ccc.eminenceFlags.pagesCustomCss) {
            properties.push(
                makeTextPropertyDescriptor("customCssClassName", "CSS class", "", false, mutatingScreenKind, {
                    allowColumn: false,
                    propertySection: PropertySection.CustomCSS,
                })
            );
        }

        return {
            name,
            description,
            img,
            group: ccc.appKind === AppKind.Page ? "Form Elements" : group,
            helpUrl,
            isLegacy,
            properties,
        };
    }

    public getActionDescriptors(
        _desc: T | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): readonly ActionPropertyDescriptor[] {
        if (ccc?.appKind !== AppKind.Page || ccc.userFeatures.onChangeAction !== true) return [];

        const actionKinds = getDefaultPrimitiveActionKinds(ccc, mutatingScreenKind);

        return [
            {
                kind: PropertyKind.Action,
                property: { name: "onChangeAction" },
                label: "On change",
                kinds: actionKinds,
                section: PropertySection.Action,
            },
        ];
    }

    public getDescriptiveName(
        desc: T,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext
    ): [string, string] {
        const names = super.getDescriptiveName(desc, tables, ccc);
        const fieldTitle = getStringProperty(desc.title);
        return [names[0], undefinedIfEmptyString(fieldTitle) ?? names[1]];
    }

    protected getDefaultValueGetter(ib: WireInflationBackend, desc: T): WireValueGetter | undefined {
        if (this._fieldType === undefined) return undefined;

        const [defaultValueGetter] = ib.getValueGetterForProperty(desc.defaultValue, false);
        return defaultValueGetter;
    }

    public convertToPage(desc: T): ComponentDescription {
        return desc;
    }

    protected inflateComponent(
        ib: WireInflationBackend,
        desc: T,
        finish: (
            base: Omit<WireFieldComponentBase, "kind">,
            value: PrimitiveValue,
            token: string | undefined,
            hb: WireRowComponentHydrationBackend,
            formatSpec: ValueFormatSpecification | undefined
        ) => WireComponent,
        validate?: (value: PrimitiveValue, hb: WireRowComponentHydrationBackend) => boolean
    ): WireRowComponentHydratorConstructor | undefined {
        const { tokenMaker, tableAndColumn, isInContext } = ib.getValueSetterForProperty(desc.propertyName, "set");
        if (tableAndColumn === undefined) return undefined;

        const formatSpec = definedMap(tableAndColumn.column.displayFormula, decomposeFormatFormula);

        const [titleGetter] = ib.getValueGetterForProperty(desc.title, true);
        const [placeholderGetter] = ib.getValueGetterForProperty(desc.placeholder, true);
        const [valueGetter] = ib.getValueGetterForProperty(desc.propertyName, false, { inOutputRow: true });

        const defaultValueGetter = this.getDefaultValueGetter(ib, desc);

        const isRequired = isRequiredPropertyHandler.getSwitch(desc);

        const onChangeHydrator = definedMap(getActionProperty(desc.onChangeAction), a => inflateActions(ib, [a]));

        const componentEnricher = inflateComponentEnricher<WireComponent>(ib, desc);

        // setTimeout returns a Timeout (which is just a number in practice) but TS doesn't like that.
        // It's safe to use 0 as a starting Timeout.
        let onChangeActionTimeoutID: ReturnType<typeof setTimeout> = 0 as any;
        let isOnChangeRunning = false;

        class Hydrator implements WireRowComponentHydrator {
            public static readonly wantsSearch = false;

            private value: PrimitiveValue | undefined;
            private hasValue: boolean | undefined;
            private isValid: boolean | undefined;
            private token: string | undefined;

            constructor(private readonly hb: WireRowComponentHydrationBackend) {}

            public preHydrate(): WireComponentPreHydrationResult {
                const { hb } = this;

                const value = valueGetter(hb);
                if (isPrimitiveValue(value)) {
                    this.value = value;
                } else {
                    this.value = undefined;
                }

                let onChangeFollowUp: ValueChangeFollowUp | undefined;
                if (onChangeHydrator !== undefined) {
                    onChangeFollowUp = async (ab, source) => {
                        // Only if the ##ValueChangeSource is `User` must we
                        // trigger an on-change action, otherwise we'd trigger
                        // it when we're setting the default value.
                        if (source !== ValueChangeSource.User) return;

                        // We need to hydrate the action in the runner because
                        // it needs the latest data from the change, which it
                        // wouldn't get if we hydrated it when we hydrate the
                        // component.
                        // https://github.com/quicktype/glide/issues/13099
                        const onChange = await hydrateSubAction(ab, onChangeHydrator);
                        clearTimeout(onChangeActionTimeoutID);
                        onChangeActionTimeoutID = setTimeout(async () => {
                            try {
                                while (isOnChangeRunning) {
                                    await sleep(100);
                                }
                                isOnChangeRunning = true;
                                await ab.invoke("field on-change", onChange, false);
                            } finally {
                                isOnChangeRunning = false;
                            }
                        }, 300);
                    };
                }

                const token = tokenMaker(hb, onChangeFollowUp);
                if (token === false) return [false, undefined];
                this.token = token;

                this.hasValue = isBound(this.value) && isNotEmpty(this.value);
                this.isValid = (this.hasValue || !isRequired) && validate?.(this.value, hb) !== false;

                // If we add more places where we do ##ncmDefaultValues like this,
                // we'll have to institutionalize it somehow.
                let followUp: WireHydrationFollowUp | undefined;
                if (defaultValueGetter !== undefined) {
                    if (this.hasValue && this.isValid) {
                        hb.getState("hadValue", isBoolean, true, false);
                    } else {
                        const defaultValue = defaultValueGetter(hb);
                        if (isBound(defaultValue) && !isLoadingValue(defaultValue) && isPrimitive(defaultValue)) {
                            const hadValue = hb.getState("hadValue", isBoolean, false, false);
                            if (!hadValue.value) {
                                followUp = ab => {
                                    if (this.token !== undefined) {
                                        ab.valueChanged(this.token, defaultValue, ValueChangeSource.Internal);
                                    }
                                    ab.valueChanged(hadValue.onChangeToken, true, ValueChangeSource.Internal);
                                };
                            }
                        }
                    }
                }

                return [true, followUp];
            }

            public hydrate(): WireComponentHydrationResult | undefined {
                const { hb } = this;

                const title = titleGetter(hb);
                const placeholder = placeholderGetter(hb);

                return {
                    component: componentEnricher(
                        finish(
                            {
                                title: asString(title),
                                placeholder: asString(placeholder),
                                isRequired,
                            },
                            this.value,
                            this.token,
                            hb,
                            formatSpec
                        )
                    ),
                    isValid: defined(this.isValid),
                    editsInContext: isInContext,
                    hasValue: defined(this.hasValue),
                };
            }
        }

        return Hydrator;
    }
}

export function defaultFieldComponent(column: TableColumn, kind: ComponentKind): BaseFieldComponentDescription {
    assert(isPrimitiveType(column.type));
    return {
        ...makeEmptyComponentDescription(kind),
        propertyName: makeColumnProperty(column.name),
        defaultValue: undefined,
        placeholder: makeStringProperty(""),
        title: makeStringProperty(getTableColumnDisplayName(column)),
        isRequired: makeSwitchProperty(false),
    };
}
