import type { BuilderTheme } from "@glide/theme";
import { useCoreTheme, isBuilderTheme } from "@glide/theme";

export function useBuilderTheme(): BuilderTheme {
    const theme = useCoreTheme();

    if (!isBuilderTheme(theme)) {
        // eslint-disable-next-line no-console
        console.error(`Tried to use a WireAppTheme but got a ${theme.themeKind}`);
    }

    return theme as BuilderTheme;
}

export function useIsDarkTheme(): boolean {
    const theme = useBuilderTheme();
    return /DarkTheme/i.test(theme.overlayName);
}
