import type { WireContainerComponent } from "@glide/fluent-components/dist/js/fluent-components-containers";
import type { WireComponent } from "@glide/wire";
import { WireComponentKind } from "@glide/wire";

import { getRenderableComponentIndexAtPosition } from "../chrome/content/lib/get-renderable-component-for-possition";
import { nullifyUnnecesaryMobileBreadcrumb } from "../chrome/content/lib/use-layer-compoents";

/*  We need to know in the player if a screen is a collection screen.
    This is needed for a proper mobile animation on the collection title.
*/
export const isTitleFirstRenderableComponent = (
    components: readonly (WireComponent | null)[],
    isMobile: boolean
): boolean => {
    const firstRenderableComponentIndex = getRenderableComponentIndexAtPosition(components, 0) ?? 0;
    if (components[firstRenderableComponentIndex]?.kind === WireComponentKind.Hero) {
        return true;
    }

    if (components[firstRenderableComponentIndex]?.kind === WireComponentKind.Container) {
        const containerComponents = nullifyUnnecesaryMobileBreadcrumb(
            (components[firstRenderableComponentIndex] as WireContainerComponent).components,
            isMobile
        );
        return isTitleFirstRenderableComponent(containerComponents, isMobile);
    }

    return false;
};
