import { base64DecodeString } from "@glide/common-core/dist/js/base64";
import { type AppDescription, type TabDescription, getScreenProperty } from "@glide/app-description";
import { getAppTabs } from "@glide/common-core/dist/js/components/SerializedApp";
import { type TableName, areTableNamesEqual, getTableName } from "@glide/type-schema";
import { isClassOrArrayScreenDescription } from "@glide/common-core/dist/js/description";
import type { ExistingAppDescriptionContext } from "@glide/function-utils";
import { inputOutputTablesForClassOrArrayScreen } from "@glide/generator/dist/js/description-utils";
import { checkNumber, checkString } from "@glide/support";
import {
    type NavigationPath,
    type NavigationPathTransformer,
    type OnPopCallback,
    type ParsedMainScreen,
    type ParsedModalScreen,
    type ParsedPath,
    type ParsedScreen,
    type ParsedScreens,
    type WithModalFlag,
    WireScreenPosition,
} from "@glide/wire";
import { assert, defined } from "@glideapps/ts-necessities";
import last from "lodash/last";

import {
    areParsedPathsEqual,
    invisibleParsedPath,
    isParsedPathValid,
    makeRootParsedPath,
    parseURLPath,
    parsedPathPush,
    unparseParsedPath,
} from "./parsed-path";

type TabScreenStacks = Record<string, readonly ParsedScreen[]>;

class NavigationPathTransformerImpl implements NavigationPathTransformer {
    // This has to be lazily constructed or there is a dependency cycle
    // between this class and the one below.
    private _invisiblePath: NavigationPath | undefined;

    constructor(private readonly removeModalIfMain: boolean) {}

    public get invisiblePath(): NavigationPath {
        if (this._invisiblePath === undefined) {
            this._invisiblePath = new NavigationPathImpl(invisibleParsedPath, this.removeModalIfMain, {});
        }
        return this._invisiblePath;
    }

    public makeRootForApp(app: AppDescription): NavigationPath | undefined {
        return new NavigationPathImpl(makeRootParsedPath(app), this.removeModalIfMain, {});
    }

    public parse(urlPath: string, app: AppDescription): NavigationPath | undefined {
        let path = parseURLPath(urlPath, app);
        if (path === undefined) {
            try {
                // Old-school deep links don't have components, but we might
                // still get something like `/full` at the end.
                const decoded = base64DecodeString(decodeURIComponent(urlPath.split("/")[0]));
                if (decoded === undefined) return undefined;
                const json = JSON.parse(decoded);
                const tabIndex = checkNumber(json.t);
                const screenName = checkString(json.s);
                const rowID = checkString(json.r);
                const tab = getAppTabs(app)[tabIndex];
                const tabScreenName = getScreenProperty(tab?.screenName);
                if (tabScreenName === undefined) return undefined;
                path = {
                    isVisible: true,
                    tabScreenName,
                    rootScreen: {
                        kind: WireScreenPosition.Main,
                        screenName: tabScreenName,
                        rowIDs: [],
                        sourceItemRowID: undefined,
                    },
                    screenStack: [
                        {
                            kind: WireScreenPosition.Main,
                            screenName,
                            rowIDs: [rowID],
                            sourceItemRowID: undefined,
                        },
                    ],
                };
            } catch {
                return undefined;
            }
        }
        return new NavigationPathImpl(path, this.removeModalIfMain, {});
    }
}

let pageNavigationPathTransformer: NavigationPathTransformerImpl | undefined;

export function getPageNavigationPathTransformer(): NavigationPathTransformerImpl {
    if (pageNavigationPathTransformer === undefined) {
        pageNavigationPathTransformer = new NavigationPathTransformerImpl(true);
    }
    return pageNavigationPathTransformer;
}

export class NavigationPathImpl implements NavigationPath {
    constructor(
        private readonly path: ParsedPath,
        private readonly removeModalsIfMain: boolean,
        private readonly tabScreenStacks: TabScreenStacks
    ) {}

    private construct(path: ParsedPath, tabScreenStacks?: TabScreenStacks): NavigationPathImpl {
        return new NavigationPathImpl(path, this.removeModalsIfMain, tabScreenStacks ?? this.tabScreenStacks);
    }

    public get transformer(): NavigationPathTransformer {
        return getPageNavigationPathTransformer();
    }

    public isValidForApp(app: AppDescription, allowHidden: boolean): boolean {
        return isParsedPathValid(this.path, app, allowHidden);
    }

    public isVisible(): boolean {
        return this.path.isVisible;
    }

    public isEqualTo(other: NavigationPath, onlyCompareFirstRowID: boolean): boolean {
        assert(other instanceof NavigationPathImpl);
        return areParsedPathsEqual(this.path, other.path, onlyCompareFirstRowID);
    }

    public canPop(): boolean {
        return this.path.isVisible && this.path.screenStack.length > 0;
    }

    public pop(): [NavigationPath, OnPopCallback | undefined] | undefined {
        if (!this.path.isVisible) return undefined;

        const { length } = this.path.screenStack;
        if (length < 1) return undefined;

        const popped = defined(this.path.screenStack[length - 1]);

        return [
            this.construct({
                ...this.path,
                screenStack: this.path.screenStack.slice(0, length - 1),
            }),
            popped.onPop,
        ];
    }

    public popToTab(): [NavigationPath, OnPopCallback | undefined] {
        assert(this.path.isVisible);
        const stack = this.path.screenStack;

        const onPop = () => {
            for (let i = stack.length - 1; i >= 0; i--) {
                stack[i].onPop?.();
            }
        };

        return [
            this.construct({
                isVisible: true,
                tabScreenName: this.path.tabScreenName,
                rootScreen: {
                    kind: WireScreenPosition.Main,
                    screenName: this.path.tabScreenName,
                    rowIDs: [],
                    sourceItemRowID: undefined,
                },
                screenStack: [],
            }),
            onPop,
        ];
    }

    public push(screen: ParsedScreen): NavigationPath {
        return this.construct(parsedPathPush(this.path, screen, this.removeModalsIfMain));
    }

    public closeModal(): [NavigationPath, OnPopCallback | undefined] {
        if (!this.path.isVisible) return [this, undefined];

        const onPops: OnPopCallback[] = [];

        const stack = [...this.path.screenStack];
        for (;;) {
            const popped = last(stack);
            if (popped === undefined) break;
            if (popped.kind !== WireScreenPosition.Modal) break;

            if (popped.onPop !== undefined) {
                onPops.push(popped.onPop);
            }

            stack.pop();
        }

        const onPop = () => {
            for (const f of onPops) {
                f();
            }
        };

        return [this.construct({ ...this.path, screenStack: stack }), onPop];
    }

    public removeModals(): [NavigationPath, OnPopCallback | undefined] {
        if (!this.path.isVisible) return [this, undefined];

        let { screenStack } = this.path;
        const onPops: OnPopCallback[] = [];
        screenStack = screenStack.filter(s => {
            const keep = s.kind !== WireScreenPosition.Modal;
            if (!keep && s.onPop !== undefined) {
                // We unshift so that we run the topmost first.
                onPops.unshift(s.onPop);
            }
            return keep;
        });

        const onPop = () => {
            for (const f of onPops) {
                f();
            }
        };

        return [this.construct({ ...this.path, screenStack }), onPop];
    }

    public removeScreen(screenName: string): NavigationPath {
        if (!this.path.isVisible) return this;

        const screenStack = this.path.screenStack.filter(s => s.screenName !== screenName);
        return this.construct({ ...this.path, screenStack });
    }

    public navigateToTab(tabScreenName: string): NavigationPath {
        if (!this.path.isVisible) return this;
        if (this.path.tabScreenName === tabScreenName) return this;

        const newStacks = {
            ...this.tabScreenStacks,
            [this.path.tabScreenName]: this.path.screenStack,
        };

        const path = this.construct(
            {
                isVisible: true,
                tabScreenName,
                rootScreen: {
                    kind: WireScreenPosition.Main,
                    screenName: tabScreenName,
                    rowIDs: [],
                    sourceItemRowID: undefined,
                },
                screenStack: this.tabScreenStacks[tabScreenName] ?? [],
            },
            newStacks
        );
        assert(path.isVisible());
        return path;
    }

    public unparseForApp(app: AppDescription): string {
        return unparseParsedPath(this.path, app);
    }

    public getTabScreenName(): string | undefined {
        if (!this.path.isVisible) return undefined;
        return this.path.tabScreenName;
    }

    public getRootScreen(): ParsedMainScreen | undefined {
        if (!this.path.isVisible) return undefined;
        return this.path.rootScreen;
    }

    // We want the individual `ParsedScreen`s in the result to be the same
    // object if they're the same screen, i.e. in the same position in the
    // navigation hierarchy.  That way we can trivially compare them for
    // identity.
    public getParsedScreens(): ParsedScreens {
        if (!this.path.isVisible) {
            return { root: undefined, main: undefined, modal: undefined, realModal: undefined, below: undefined };
        }

        let isInModal = false;
        let depth = 0;

        let current: WithModalFlag<ParsedScreen> | undefined;
        let below: WithModalFlag<ParsedScreen> | undefined;
        let main: WithModalFlag<ParsedMainScreen> | undefined;
        let modal: WithModalFlag<ParsedScreen> | undefined;
        let realModal: WithModalFlag<ParsedModalScreen> | undefined;

        function process(s: ParsedScreen) {
            if (current !== undefined) {
                below = current;
            }
            if (s.kind === WireScreenPosition.Modal) {
                isInModal = true;
                realModal = { screen: s, isInModal, depth };
            }
            current = { screen: s, isInModal, depth };

            if (isInModal) {
                modal = current;
            } else {
                assert(current.screen.kind === WireScreenPosition.Main);
                // TypeScript doesn't let us do `main = current`
                main = { screen: current.screen, isInModal: current.isInModal, depth };
            }

            depth += 1;
        }

        process(this.path.rootScreen);
        for (const s of this.path.screenStack) {
            process(s);
        }

        assert(main?.isInModal !== true);
        assert(modal?.isInModal !== false);
        if (below?.isInModal === true) {
            assert(modal !== undefined);
        }

        assert(isInModal === (modal !== undefined));
        if (isInModal) {
            assert(main !== undefined);
            assert(realModal !== undefined);
            assert(below !== undefined);
        }
        if (below !== undefined) {
            assert(main !== undefined);
        }

        return { root: { screen: this.path.rootScreen, isInModal: false, depth: 0 }, main, modal, realModal, below };
    }

    public getTabOfCurrentScreen(app: AppDescription): TabDescription | undefined {
        const { path } = this;
        if (!path.isVisible) return undefined;

        const tabs = getAppTabs(app);
        for (let i = path.screenStack.length - 1; i >= 0; i--) {
            const screen = path.screenStack[i];
            const tab = tabs.find(t => getScreenProperty(t.screenName) === screen.screenName);
            if (tab !== undefined) {
                return tab;
            }
        }
        return tabs.find(t => getScreenProperty(t.screenName) === path.rootScreen.screenName);
    }

    public getScreenStack(): readonly ParsedScreen[] {
        if (!this.path.isVisible) return [];
        return this.path.screenStack;
    }

    public getDepthOfScreen(screen: ParsedScreen): number {
        assert(this.path.isVisible);
        if (screen === this.path.rootScreen) {
            return 0;
        } else {
            const index = this.path.screenStack.indexOf(screen);
            assert(index >= 0);
            return index + 1;
        }
    }

    public withScreenStack(screenStack: readonly ParsedScreen[]): NavigationPath {
        assert(this.path.isVisible);
        return this.construct({
            ...this.path,
            screenStack,
        });
    }

    public updateWithTabVisibility(
        visibleTabScreens: ReadonlySet<string>,
        invisibleTabScreens: ReadonlySet<string>,
        defaultTabScreenName: string | undefined
    ): NavigationPath {
        // What we do here is remove all tab screens that are hidden, and all
        // screen pushed by those tab screens.  Although the path is a linear
        // sequence of screens, we can think of it as a sequence of sequences,
        // where each inner sequence is started by a tab screen.  These
        // additional tab screens are pushed when navigating to a flyout in
        // Apps.  In Pages there should only ever be one tab screen in a path,
        // namely at the root.
        //
        // For an example of such a sequence of sequences, let's say we have
        // three tab screens - A, B, and C - that all have their own
        // sub-screens A1, A2, ..., B1, B2, ..., and we start at tab A, it
        // pushes A1, then we push the flyout tab screen B, which pushes B1,
        // and B2, and then we push the flyout tab screen C, we get this
        // nested sequence:
        //
        //   (A -> A1) -> (B -> B1 -> B2) -> (C)
        //
        // Now, if the tab screen B becomes invisible, we have to remove the
        // whole subsequence for B, resulting in
        //
        //   (A -> A1) -> (C)
        //
        // There's one special case, which is the root screen, which we try to
        // make a "regular" (i.e. not a flyout) tab screen.  That means that
        // if the root becomes invisible, we replace it, and the screens it
        // pushed, with whichever other tab screen is visible right now, which
        // is `defaultTabScreenName`.

        let rootScreenName = this.path.isVisible ? this.path.tabScreenName : undefined;
        if (rootScreenName === undefined || invisibleTabScreens.has(rootScreenName)) {
            // We don't have a root screen, or it became invisible, so we have
            // to reset to the default.

            if (defaultTabScreenName === undefined) {
                // If there's no default tab then no tab is visible, so we're
                // resetting the parsed path.
                //
                // TODO: Allow for restoring the parsed path when tabs become
                // visible again?
                return this.transformer.invisiblePath;
            }

            rootScreenName = defaultTabScreenName;
        }

        assert(!invisibleTabScreens.has(rootScreenName));

        let rootScreen = this.path.isVisible ? this.path.rootScreen : undefined;
        let changedRootScreen: boolean;
        if (rootScreen?.screenName !== rootScreenName) {
            rootScreen = {
                kind: WireScreenPosition.Main,
                screenName: rootScreenName,
                rowIDs: [],
                sourceItemRowID: undefined,
            };
            changedRootScreen = true;
        } else {
            changedRootScreen = false;
        }

        // This is how we remove the invisible sub-sequences: we iterate over
        // the screen stack and keep a flag `isVisible` that tells us whether
        // we're currently in a visible sub-sequence.  We start out visible if
        // we kept the original root screen.
        let isVisible = !changedRootScreen;
        const newScreenStack: ParsedScreen[] = [];
        for (const s of this.path.isVisible ? this.path.screenStack : []) {
            // If the current screen is a tab screen, a new sub-sequence
            // starts, and we have to set `isVisible` to whether that sequence
            // is visible or not.
            if (visibleTabScreens.has(s.screenName)) {
                isVisible = true;
            } else if (invisibleTabScreens.has(s.screenName)) {
                isVisible = false;
            }

            if (isVisible) {
                newScreenStack.push(s);
            }
        }

        return this.construct({
            isVisible: true,
            rootScreen,
            screenStack: newScreenStack,
            tabScreenName: rootScreenName,
        });
    }

    public updateWithDeletedRows(deletedRowIDs: ReadonlySet<string>): NavigationPath {
        if (!this.path.isVisible) return this;
        const newStack = this.path.screenStack.filter(
            s => s.rowIDs.length === 0 || s.rowIDs.some(id => !deletedRowIDs.has(id))
        );
        if (newStack.length === this.path.screenStack.length) return this;
        return this.construct({
            isVisible: true,
            rootScreen: this.path.rootScreen,
            tabScreenName: this.path.tabScreenName,
            screenStack: newStack,
        });
    }

    // This assumes that top screen is an array "master" screen and we're
    // getting its detail screen.
    public getDetailScreenForMaster(): ParsedMainScreen | undefined {
        if (!this.path.isVisible) return undefined;
        const screen = this.path.screenStack[0];
        if (screen?.kind !== WireScreenPosition.Main) return undefined;
        return screen;
    }

    public getRowIDsForTable(adc: ExistingAppDescriptionContext, tableName: TableName): ReadonlySet<string> {
        const result = new Set<string>();

        function processScreen({ screenName, rowIDs }: ParsedScreen) {
            const screen = adc.appDescription.screenDescriptions[screenName];
            if (screen === undefined) return;
            if (!isClassOrArrayScreenDescription(screen)) return;
            const tables = inputOutputTablesForClassOrArrayScreen(screen, adc.schema);
            if (tables === undefined) return;
            if (!areTableNamesEqual(tableName, getTableName(tables.input))) return;
            for (const rowID of rowIDs) {
                result.add(rowID);
            }
        }

        if (this.path.isVisible) {
            processScreen(this.path.rootScreen);
            for (const s of this.path.screenStack) {
                processScreen(s);
            }
        }

        for (const stack of Object.values(this.tabScreenStacks)) {
            for (const s of stack) {
                processScreen(s);
            }
        }

        return result;
    }
}

let appNavigationPathTransformer: NavigationPathTransformerImpl | undefined;

export function getAppNavigationPathTransformer(): NavigationPathTransformerImpl {
    if (appNavigationPathTransformer === undefined) {
        appNavigationPathTransformer = new NavigationPathTransformerImpl(false);
    }
    return appNavigationPathTransformer;
}
