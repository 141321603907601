import { useSelectMode } from "@glide/common-components";
import type { ComponentIndexes } from "@glide/common-core/dist/js/component-indexes";
import { areComponentIndexesEqual } from "@glide/generator/dist/js/description-utils";
import { definedMap } from "@glideapps/ts-necessities";

export interface SelectModeInfo {
    id: string | undefined;
    indexes: ComponentIndexes;
}

export function useSelectModeHandlers(selectModeInfo: SelectModeInfo | undefined) {
    const selectMode = useSelectMode();

    const onMouseOver = definedMap(selectModeInfo, sinfo => () => {
        if (selectMode.isInSelectMode) {
            selectMode.onComponentHovered(sinfo.id);
        }
    });

    const onMouseLeave = definedMap(selectModeInfo, _sinfo => () => {
        if (selectMode.isInSelectMode) {
            selectMode.onComponentHovered(undefined);
        }
    });

    const onClick = definedMap(selectModeInfo, sinfo => () => {
        if (selectMode.isInSelectMode) {
            selectMode.onComponentClicked(sinfo.indexes);
        }
    });

    const isComponentSelected =
        selectMode.isSelectedComponentInCurrentScreen === true &&
        areComponentIndexesEqual(selectMode.selectedComponentIndexes, selectModeInfo?.indexes);

    const blockInteractions = selectMode.isInSelectMode && !isComponentSelected;

    return {
        onMouseOver,
        onMouseLeave,
        onClick,
        blockInteractions,
    };
}
