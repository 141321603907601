import "twin.macro";

import { trackEvent } from "@glide/common-core/dist/js/analytics";
import { getPlanInfoForPlanKind } from "@glide/common-core/dist/js/billing-vnext/org-plan-lib";
import type { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import React from "react";
import { Button } from "../button/button";
import { SubscriptionContext, isSubscriptionContext } from "../paywall/context";
import { UpgradePill } from "../paywall/upgrade-pill";
import { glideIconPropsCodec, type GlideIconProps } from "@glide/plugins-codecs";
import { GlideIcon } from "@glide/common";

type ShowErrorActionModal = (header: string, description: React.ReactNode, actionText: string) => Promise<void>;
type ShowUpgradeModal = (pluginID: string) => void;

interface Props extends React.PropsWithChildren {
    onNewAction: () => void;
    suggestedActions?: PluginActionRecord[];
    showErrorActionModal: ShowErrorActionModal;
    showUpgradeModal: ShowUpgradeModal;
}

export type PluginActionRecord = {
    id: string;
    icon: string | GlideIconProps;
    name: string;
    description: string;
    requiredPlan: PlanKind | undefined;
    onClick: () => Promise<void>;
};

const PluginSuggestionItem: React.FC<{
    data: PluginActionRecord;
    showErrorActionModal: ShowErrorActionModal;
    showUpgradeModal: ShowUpgradeModal;
}> = ({ data, showErrorActionModal, showUpgradeModal }) => {
    const { id, icon, name, description, onClick, requiredPlan } = data;
    const upgradeLocation = `suggested_action_${name}`;

    const subscriptionContext = React.useContext(SubscriptionContext);
    const canUpgrade = isSubscriptionContext(subscriptionContext);
    const subscription = canUpgrade ? subscriptionContext.subscription : undefined;
    const appID = useAppID();

    const didClick = React.useCallback(async () => {
        trackEvent("plugin select from empty screen", {
            app_id: appID ?? "",
            plugin_id: id,
            can_use: requiredPlan === undefined,
        });
        if (requiredPlan) {
            if (subscription !== undefined) {
                showUpgradeModal(id);
            } else {
                await showErrorActionModal(
                    "Upgrade Required",
                    `Upgrade to ${getPlanInfoForPlanKind(requiredPlan).short} to use this action.`,
                    "Close"
                );
            }
        } else {
            await onClick();
        }
    }, [onClick, requiredPlan, showErrorActionModal, subscription, id, showUpgradeModal, appID]);

    return (
        <div
            tw="min-w-[100px] px-3 py-4 flex-1 cursor-pointer rounded-lg
        border-border-base bg-bg-onboarding-left-panel transition-all shadow-sm-dark hover:shadow-2xl-dark [&>*]:pointer-events-none"
            onClick={didClick}
        >
            <div tw="flex justify-between items-start">
                {glideIconPropsCodec.is(icon) && <GlideIcon {...icon} iconSize={36} />}
                {typeof icon === "string" && <img tw="h-[36px] w-[36px] [border-radius:9px]" src={icon} alt={name} />}
                {requiredPlan && <UpgradePill planKind={requiredPlan} location={upgradeLocation} />}
            </div>
            <div tw="mt-[12px] text-text-dark text-builder-base [font-weight:600] leading-[110%]">{name}</div>
            <div tw="mt-[4px] text-text-pale text-builder-sm leading-[133%]">{description}</div>
        </div>
    );
};

interface PluginActionsSuggestionProps {
    records: PluginActionRecord[];
    showErrorActionModal: ShowErrorActionModal;
    showUpgradeModal: ShowUpgradeModal;
}

const PluginActionsSuggestion: React.VFC<PluginActionsSuggestionProps> = p => {
    const { records, showErrorActionModal, showUpgradeModal } = p;

    return (
        <div tw="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 pb-12">
            {records.map((r, i) => {
                return (
                    <PluginSuggestionItem
                        key={i}
                        data={r}
                        showErrorActionModal={showErrorActionModal}
                        showUpgradeModal={showUpgradeModal}
                    />
                );
            })}
        </div>
    );
};

export const ActionEditorEmpty: React.VFC<Props> = p => {
    const { onNewAction, suggestedActions, showErrorActionModal, showUpgradeModal } = p;

    return (
        <div tw="flex flex-col flex-1 items-center relative">
            <img src="/images/bolts.png" alt="Bolts" tw="absolute w-[678px] [opacity: 0.34] [z-index: -1]" />

            <Button
                tw="absolute top-4 right-4"
                buttonType="minimal"
                label="Share feedback"
                size="md"
                variant="default"
                icon="st-message-square-chat"
                href="https://actions-feedback.glide.page"
            />
            <div tw="flex flex-col items-center w-full max-w-[960px] h-full pt-[72px]">
                {suggestedActions === undefined && (
                    <svg
                        tw="mb-8"
                        width="230"
                        height="133"
                        viewBox="0 0 230 133"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M118.737 129.665C127.781 132.198 137.578 131.266 145.966 126.564C161.069 118.092 170.041 99.7425 176 81.5916"
                            stroke="#C100C1"
                            strokeWidth="3"
                            strokeMiterlimit="10"
                        />
                        <path
                            d="M118.776 89.7254C122.915 85.1694 125.93 79.2699 127.39 72.8832C128.919 66.1901 128.51 58.3589 124.434 53.2912C121.885 50.1205 118.162 48.408 114.427 48.0947C102.849 47.1237 92.9319 59.0375 89.7416 71.9468C85.5069 89.0954 90.7378 108.858 102.545 120.33C107.216 124.868 112.814 128.008 118.737 129.668"
                            stroke="#F13C0B"
                            strokeWidth="3"
                            strokeMiterlimit="10"
                        />
                        <path
                            d="M48 59.3335C54.212 74.7627 64.0929 88.8555 77.5042 95.8618C90.9185 102.865 108.113 101.462 118.773 89.7255"
                            stroke="#12CCE6"
                            strokeWidth="3"
                            strokeMiterlimit="10"
                        />
                        <g filter="url(#filter0_d_254_110294)">
                            <rect x="24" y="22.0392" width="44" height="44" rx="22" fill="#070D10" />
                            <path
                                d="M40 50.567V36.7132C40 35.9017 41.1931 35.4267 42.0309 35.9215L53.8859 42.8484C54.5714 43.2443 54.5714 44.0359 53.8859 44.4317L42.0309 51.3587C41.1931 51.8535 40 51.3785 40 50.567Z"
                                fill="white"
                            />
                        </g>
                        <rect x="144" y="22.0392" width="86" height="86" rx="32" fill="#887EF9" />
                        <path
                            d="M186.175 68.2892H175.197C173.927 68.2892 173.227 66.8093 174.038 65.8317L187.265 49.8831C188.246 48.6993 190.161 49.5836 189.897 51.0985L188.031 61.7892H198.868C200.128 61.7892 200.827 63.2479 200.037 64.2294L187.19 80.2004C186.23 81.3946 184.308 80.5494 184.538 79.0344L186.175 68.2892Z"
                            stroke="white"
                            strokeWidth="2"
                        />
                        <rect x="80" width="57" height="57" rx="21.2093" fill="#FF6C44" />
                        <path
                            d="M107.953 30.6541H100.677C99.8351 30.6541 99.3716 29.6732 99.909 29.0253L108.675 18.4547C109.326 17.6701 110.595 18.2562 110.42 19.2602L109.183 26.346H116.366C117.201 26.346 117.664 27.3128 117.141 27.9633L108.626 38.5487C107.989 39.3402 106.716 38.7801 106.868 37.7759L107.953 30.6541Z"
                            stroke="white"
                            strokeWidth="1.32558"
                        />
                        <defs>
                            <filter
                                id="filter0_d_254_110294"
                                x="0"
                                y="10.0392"
                                width="92"
                                height="92"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                            >
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset dy="12" />
                                <feGaussianBlur stdDeviation="12" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.243137 0 0 0 0 0.254902 0 0 0 0 0.337255 0 0 0 0.1 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_254_110294"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_254_110294"
                                    result="shape"
                                />
                            </filter>
                        </defs>
                    </svg>
                )}
                <div tw="text-center">
                    <div tw="text-builder-3xl font-semibold text-text-dark">Supercharge your app with Workflows</div>
                    <div tw="text-text-pale text-builder-base mt-2">
                        Combine actions to create your own workflow.
                        <br />
                        Trigger them from your app or automatically on a schedule.
                    </div>
                </div>
                <div tw="mt-6">
                    <Button
                        variant="inverse"
                        buttonType="primary"
                        size="lg"
                        label="New workflow"
                        onClick={onNewAction}
                        icon="st-plus-add"
                    />
                </div>
                {suggestedActions !== undefined && (
                    <div tw="mt-[60px] px-6">
                        <div tw="text-builder-xl text-text-dark [font-weight:700] mb-3">Start from an existing one</div>
                        <PluginActionsSuggestion
                            records={suggestedActions}
                            showErrorActionModal={showErrorActionModal}
                            showUpgradeModal={showUpgradeModal}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
