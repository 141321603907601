import {
    addOnlineHandler,
    deleteOnlineHandler,
    getIsOnline,
} from "@glide/common-core/dist/js/hooks/use-network-status";
import { OfflineQueueImplWithoutOnlineHooks } from "@glide/common-core/dist/js/offline-action-queue";
import type { OfflineQueueSaveOperations } from "@glide/common-core/dist/js/offline-action-queue";

export class OfflineQueueImpl<T> extends OfflineQueueImplWithoutOnlineHooks<T> {
    constructor(name: string, onOnline: () => void, idForItem: (item: T) => string, ops: OfflineQueueSaveOperations) {
        super(name, onOnline, idForItem, ops);
        addOnlineHandler(this.onlineChanged);
    }

    public getIsOnline(): boolean {
        return getIsOnline();
    }

    public retire(): void {
        deleteOnlineHandler(this.onlineChanged);
    }
}
