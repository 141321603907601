import * as t from "io-ts";
import { gcpGmailScopeCodec, gcpScopeCodec } from "./gcp";
import { msalScopeCodec } from "./msal";
import {
    googleDriveIntegrationHead,
    gcpOAuthIntegrationHead,
    gcpGmailIntegrationHead,
    stripeGenericIntegrationHead,
    microsoftIntegrationHead,
    airtableIntegrationHead,
    gcpIntegrationHead,
    stripeTemplateStoreIntegrationHead,
} from "@glide/app-description";

// FIXME: Shouldn't we use `t.exact` on most types?

// This originally came from the backend, but we are now using it as direct
// responses to login requests, as a replacement for the old password mecahnism.
export const aeadDataCodec = t.intersection([
    t.type({
        iv: t.string,
        data: t.string,
        tag: t.string,
    }),
    t.partial({
        authOnlyData: t.string,
        overrideEmail: t.string,
    }),
]);
export const appLoginTokenContainerCodec = aeadDataCodec;
export type AppLoginTokenContainer = t.TypeOf<typeof appLoginTokenContainerCodec>;

export const aeadKeyedDataCodec = t.intersection([
    aeadDataCodec,
    t.partial({
        keyName: t.string,
        ignoreUnencryptedInHistoryBecause: t.string,
    }),
]);
export type AeadKeyedData = t.TypeOf<typeof aeadKeyedDataCodec>;
export const possiblyEncryptedString = t.union([aeadKeyedDataCodec, t.string]);

export const gcpSavedServiceAccountSchema = t.type({
    projectID: t.string,
    privateKeyID: t.string,
    privateKey: possiblyEncryptedString,
    clientEmail: t.string,
    clientID: t.string,
    authURI: t.string,
    tokenURI: t.string,
    authProviderX509CertURL: t.string,
    clientX509CertURL: t.string,
});
export type GCPSavedServiceAccountCredentials = t.TypeOf<typeof gcpSavedServiceAccountSchema>;

const oAuth2OfflineAuthentication = t.type({
    kind: t.literal("oauth2-offline"),
    idToken: t.string,
    refreshToken: t.string,
});

export type OAuth2OfflineAuthentication = t.TypeOf<typeof oAuth2OfflineAuthentication>;

const googleDriveIntegration = t.intersection([
    googleDriveIntegrationHead,
    t.type({
        authentication: oAuth2OfflineAuthentication,
    }),
]);

export type GoogleDriveIntegration = t.TypeOf<typeof googleDriveIntegration>;

export const gcpOAuthIntegrationCodec = t.intersection([
    gcpOAuthIntegrationHead,
    t.type({
        authentication: t.intersection([
            oAuth2OfflineAuthentication,
            t.type({ availableScopes: t.readonlyArray(gcpScopeCodec) }),
        ]),
    }),
    t.partial({
        expirationDate: t.number,
        username: t.string,
    }),
]);

export type GCPOAuthIntegration = t.TypeOf<typeof gcpOAuthIntegrationCodec>;

const gcpGmailIntegrationCodec = t.intersection([
    gcpGmailIntegrationHead,
    t.type({
        authentication: t.intersection([
            oAuth2OfflineAuthentication,
            t.type({ availableScopes: t.readonlyArray(gcpGmailScopeCodec) }),
        ]),
        username: t.string,
    }),
    t.partial({
        expirationDate: t.number,
    }),
]);

export type GCPGmailIntegration = t.TypeOf<typeof gcpGmailIntegrationCodec>;

const stripeGenericIntegration = t.intersection([
    t.type({
        stripeUserID: t.string,
        authentication: oAuth2OfflineAuthentication,
    }),
    t.partial({
        // These would be mandatory, but we started the closed Beta without them.
        livePublishableKey: t.string,
        testPublishableKey: t.string,
        // This would be readonly, but we need to establish it only if it
        // is defined, because of Cloud Firestore and its bizzare treatment of `undefined`.
        stripeEmail: t.string,
        waiveTransactionFees: t.boolean,
    }),
]);

const microsoftTokens = t.intersection([
    t.type({
        username: t.string,
        accessToken: t.string,
        refreshToken: t.string,
    }),
    t.partial({
        availableScopes: t.readonlyArray(msalScopeCodec),
    }),
]);

export const microsoftIntegrationCodec = t.intersection([microsoftIntegrationHead, microsoftTokens]);
export type MicrosoftIntegration = t.TypeOf<typeof microsoftIntegrationCodec>;

export const airtableTokens = t.intersection([
    t.type({
        accessToken: t.string,
    }),
    t.partial({
        userEmail: t.string,
    }),
]);
export type AirtableTokens = t.TypeOf<typeof airtableTokens>;

export function isValidAirtableAccessToken(accessToken: string): boolean {
    return accessToken.startsWith("key") && accessToken.length > 10;
}

export const airtableIntegrationCodec = t.intersection([airtableIntegrationHead, airtableTokens]);

export type AirtableIntegration = t.TypeOf<typeof airtableIntegrationCodec>;

export const gcpIntegrationCodec = t.intersection([
    gcpIntegrationHead,
    t.type({
        serviceAccount: gcpSavedServiceAccountSchema,
    }),
]);

export type GCPIntegration = t.TypeOf<typeof gcpIntegrationCodec>;

const stripeIntegration = t.intersection([stripeGenericIntegrationHead, stripeGenericIntegration]);
export type StripeIntegration = t.TypeOf<typeof stripeIntegration>;

const stripeTemplateStoreIntegration = t.intersection([stripeTemplateStoreIntegrationHead, stripeGenericIntegration]);
export type StripeTemplateStoreIntegration = t.TypeOf<typeof stripeTemplateStoreIntegration>;

export const integration = t.union([
    googleDriveIntegration,
    stripeIntegration,
    stripeTemplateStoreIntegration,
    microsoftIntegrationCodec,
    airtableIntegrationCodec,
    gcpIntegrationCodec,
    gcpOAuthIntegrationCodec,
    gcpGmailIntegrationCodec,
]);

export type Integration = t.TypeOf<typeof integration>;

export const oauthIntegrationReferenceCodec = t.type({
    providerKind: t.string,
    providerID: t.string,
    credentialID: t.string,
});

export type OAuthIntegrationReference = t.TypeOf<typeof oauthIntegrationReferenceCodec>;
