import * as routes from "@glide/common-core/dist/js/routes";
import { useMemo } from "react";

import type { Organization } from "@glide/common-core/dist/js/Database";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { useRouteMatch } from "react-router-dom";
import { useBuilderSelector } from "./use-builder-selector";

interface RouteProps {
    readonly orgID: string | undefined;
}
interface OrgInfo {
    appID: string | undefined;
    orgID: string | undefined;
    org: Organization | undefined;
}

export function useOrgInfo(): OrgInfo {
    const { organizations } = useBuilderSelector(state => ({
        organizations: state.organizations,
        userData: state.userData,
        tiles: state.tiles,
    }));
    const appID = useAppID();
    const match = useRouteMatch<RouteProps>(routes.orgPattern);

    const orgInfo = useMemo(() => {
        const orgID =
            appID === undefined
                ? match?.params.orgID === "0"
                    ? undefined
                    : match?.params.orgID
                : organizations.find(o => o.appIDs.includes(appID))?.id;
        const org = organizations.find(o => o.id === orgID);

        return { appID, orgID, org };
    }, [appID, match, organizations]);

    return orgInfo;
}
