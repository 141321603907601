import { makeLocalizedNumberOfItems } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import { getAppFeatures, getEmailClient } from "@glide/common-core/dist/js/components/SerializedApp";
import { type LoadedGroundValue, isLoadingValue } from "@glide/computation-model-types";
import { asString, asTable } from "@glide/common-core/dist/js/computation-model/data";
import {
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getArrayProperty,
    getSourceColumnProperty,
    makeArrayProperty,
} from "@glide/app-description";
import {
    type TableColumn,
    getNonHiddenColumns,
    getTableColumnDisplayName,
    getTableName,
    isMultiRelationType,
    isPrimitiveType,
    isSingleRelationType,
    type SchemaInspector,
} from "@glide/type-schema";
import { makeEmptyComponentDescription, type InputOutputTables } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { makeTelephoneURL } from "@glide/common-core/dist/js/urls";
import { SimpleTableRowStyle } from "@glide/component-utils";
import type {
    WireAppSimpleTableComponent,
    WireAppSimpleTableRow,
} from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    ArrayPropertyStyle,
    PropertySection,
    SwitchPropertyHandler,
    arrayScreenName,
    getColumnForSourceColumn,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { mapFilterUndefined, definedMap } from "@glideapps/ts-necessities";
import { isValidEmailAddress, makeMailtoURL, normalizeEmailAddress, tryShortenLinkToHostname } from "@glide/support";
import {
    type WireActionRunner,
    type WireRowComponentHydratorConstructor,
    WireActionResult,
    WireActionResultBuilder,
    PageScreenTarget,
    WireComponentKind,
    type WireActionHydrationResult,
    type WireInflationBackend,
    type WireRowComponentHydrationBackend,
} from "@glide/wire";
import { hydrateOpenWebView } from "../actions/open-link";
import { LinkAppearance } from "../builder-utils";
import {
    hydrateOpenURL,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    registerActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { makeCaptionStringPropertyDescriptor } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

const ComponentKindSimpleTable: ComponentKind = "simple-table";

interface RowDescription {
    readonly caption: PropertyDescription;
    readonly value: PropertyDescription;
}

interface SimpleTableComponentDescription extends ComponentDescription {
    readonly caption: PropertyDescription | undefined;
    readonly footer: PropertyDescription | undefined;
    readonly rows: PropertyDescription;
    readonly allowWrapping: PropertyDescription | undefined;
}

const allowWrappingPropertyHandler = new SwitchPropertyHandler(
    { allowWrapping: false },
    "Allow text wrapping",
    PropertySection.TextStyle
);

function getColumnForRow(
    schema: SchemaInspector,
    tables: InputOutputTables,
    row: RowDescription
): TableColumn | undefined {
    const sc = getSourceColumnProperty(row.value);
    if (sc === undefined) return undefined;
    return getColumnForSourceColumn(schema, sc, tables.input, undefined, []);
}

// This is the same as the above, but for NCM
function inflateStyleAndActionForRow(
    ib: WireInflationBackend,
    column: TableColumn | undefined
):
    | ((
          hb: WireRowComponentHydrationBackend,
          value: LoadedGroundValue
      ) => {
          style: SimpleTableRowStyle;
          hydratedAction: WireActionHydrationResult | undefined;
          formattedValue: string | undefined;
      })
    | undefined {
    switch (column?.type.kind) {
        case "image-uri":
            return () => ({ style: SimpleTableRowStyle.Image, hydratedAction: undefined, formattedValue: undefined });

        case "uri":
        case "audio-uri":
            return (_hb, value) => {
                const url = asString(value);
                const runner = hydrateOpenWebView(WireActionResultBuilder.nondescript(), url, LinkAppearance.PageTitle);
                return {
                    style: SimpleTableRowStyle.Link,
                    hydratedAction: runner,
                    formattedValue: tryShortenLinkToHostname(asString(value)),
                };
            };

        case "phone-number":
            return (_hb, value) => {
                const phoneURL = makeTelephoneURL(asString(value));
                return {
                    style: SimpleTableRowStyle.Link,
                    hydratedAction: definedMap(phoneURL, u => hydrateOpenURL(undefined, u, {})),
                    formattedValue: undefined,
                };
            };

        case "email-address":
            return (_hb, value) => {
                const emailAddress = normalizeEmailAddress(asString(value));
                const emailClient = getEmailClient(getAppFeatures(ib.adc.appDescription));
                const emailURL = isValidEmailAddress(emailAddress)
                    ? makeMailtoURL(emailClient, emailAddress, undefined, undefined, undefined, undefined)
                    : undefined;
                return {
                    style: SimpleTableRowStyle.Link,
                    hydratedAction: definedMap(emailURL, u => hydrateOpenURL(undefined, u, {})),
                    formattedValue: undefined,
                };
            };

        case "emoji":
            return () => ({ style: SimpleTableRowStyle.Emoji, hydratedAction: undefined, formattedValue: undefined });

        case "boolean":
            return () => ({
                style: SimpleTableRowStyle.Checkbox,
                hydratedAction: undefined,
                formattedValue: undefined,
            });

        case "array":
            if (!isSingleRelationType(column.type.items)) return undefined;
            const tableType = ib.adc.findTable(column.type.items);
            const screenTitle = getTableColumnDisplayName(column);
            return (_hb, value) => {
                const table = definedMap(value, asTable);
                const numRows = table?.size ?? 0;
                let runner: WireActionRunner | undefined;
                if (tableType !== undefined && table !== undefined && numRows > 0) {
                    runner = async ab => {
                        ab.pushDefaultArrayScreen(
                            getTableName(tableType),
                            table,
                            screenTitle,
                            PageScreenTarget.Current
                        );
                        return WireActionResult.nondescriptSuccess();
                    };
                }
                return {
                    style: SimpleTableRowStyle.PushScreen,
                    hydratedAction: runner,
                    formattedValue: makeLocalizedNumberOfItems(numRows, ib.adc.appKind),
                };
            };

        default:
            return () => ({ style: SimpleTableRowStyle.None, hydratedAction: undefined, formattedValue: undefined });
    }
}

export class SimpleTableComponentHandler extends ComponentHandlerBase<SimpleTableComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindSimpleTable);
    }

    // NOTE: This needs to be synchronized with the code that handles the
    // items.
    public getScreensUsed(
        desc: SimpleTableComponentDescription,
        schema: SchemaInspector,
        tables: InputOutputTables | undefined
    ): readonly string[] {
        if (tables === undefined) return [];

        const screenNames: string[] = [];

        const rowDescriptions = getArrayProperty<RowDescription>(desc.rows) ?? [];
        for (const row of rowDescriptions) {
            const column = getColumnForRow(schema, tables, row);
            if (column === undefined) continue;

            if (isMultiRelationType(column.type)) {
                screenNames.push(arrayScreenName(schema, column.type.items));
            }
        }

        return screenNames;
    }

    public getDescriptor(
        _desc: SimpleTableComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        return {
            name: "Basic Table",
            description: "A table displaying rows of values",
            img: "co-basic-table",
            group: "Layout",
            helpUrl: getDocURL("simpleTable"),
            properties: [
                makeTextPropertyDescriptor(
                    "caption",
                    "Title",
                    "Enter the component's title",
                    false,
                    mutatingScreenKind,
                    {
                        propertySection: PropertySection.Top,
                    }
                ),
                {
                    kind: PropertyKind.Array,
                    label: "Rows",
                    property: { name: "rows" },
                    section: PropertySection.Data,
                    properties: [
                        makeCaptionStringPropertyDescriptor("Data", true, mutatingScreenKind),
                        makeTextPropertyDescriptor("value", "Right", "Enter text", true, mutatingScreenKind, {
                            columnFirst: true,
                            ignoreColumnsFromProperties: ["caption"],
                            isDefaultCaption: true,
                            columnFilter: {
                                columnTypeIsAllowed: ct => isPrimitiveType(ct) || isMultiRelationType(ct),
                                getCandidateColumns: t => getNonHiddenColumns(t),
                            },
                        }),
                    ],
                    allowEmpty: false,
                    allowReorder: true,
                    addItemLabels: ["Add row"],
                    style: ArrayPropertyStyle.KeyValue,
                },
                makeTextPropertyDescriptor(
                    "footer",
                    "Footer",
                    "Enter the component's footer",
                    false,
                    mutatingScreenKind,
                    {
                        columnFirst: true,
                        emptyByDefault: true,
                    }
                ),
                allowWrappingPropertyHandler,
                ...this.getBasePropertyDescriptors(),
            ],
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: SimpleTableComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);
        const [footerGetter] = inflateStringProperty(ib, desc.footer, true);
        const allowsWrapping = allowWrappingPropertyHandler.getSwitch(desc);

        const rowDescriptions = getArrayProperty<RowDescription>(desc.rows) ?? [];
        const rowHydrators = mapFilterUndefined(rowDescriptions, (row, i) => {
            const [labelGetter, labelType] = inflateStringProperty(ib, row.caption, true);
            const [valueGetter, valueType] = ib.getValueGetterForProperty(row.value, false);
            if (labelType === undefined && valueType === undefined) return undefined;

            const [valueWithFormatGetter] = inflateStringProperty(ib, row.value, true);

            const column = getColumnForRow(ib.adc, ib.tables, row);
            const styleAndActionHydrator = inflateStyleAndActionForRow(ib, column);
            if (styleAndActionHydrator === undefined) return undefined;

            return (hb: WireRowComponentHydrationBackend): WireAppSimpleTableRow | undefined => {
                const label = labelGetter(hb) ?? "";
                const value = valueGetter(hb) ?? undefined;

                const { style, hydratedAction, formattedValue } = styleAndActionHydrator(
                    hb,
                    isLoadingValue(value) ? undefined : value
                );

                const displayValue =
                    formattedValue ?? valueWithFormatGetter(hb) ?? (isLoadingValue(value) ? "" : asString(value));
                if (displayValue === "") return undefined;

                const action = registerActionRunner(hb, `row[${i}]`, hydratedAction ?? WireActionResult.nothingToDo());

                return {
                    label,
                    value: displayValue,
                    style,
                    action,
                };
            };
        });

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const rows = mapFilterUndefined(rowHydrators, rh => rh(hb));
            if (rows.length === 0) return undefined;

            const component: WireAppSimpleTableComponent = {
                kind: WireComponentKind.AppSimpleTable,
                ...spreadComponentID(desc.componentID, forBuilder),
                caption: captionGetter(hb) ?? undefined,
                footer: footerGetter(hb) ?? undefined,
                allowsWrapping,
                rows,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: SimpleTableComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        const classicTableRows = getArrayProperty<RowDescription>(desc.rows)?.map(r => ({
            // old pairs are value, caption, new ones are value, name
            value: r.value,
            name: r.caption,
        }));
        return {
            ...makeEmptyComponentDescription(WireComponentKind.Fields),
            title: desc.caption,
            // footer: desc.footer, // not supported in pages, could append to title?
            fields: makeArrayProperty(classicTableRows ?? []),
        } as ComponentDescription;
    }
}
