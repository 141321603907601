import { assert, filterUndefined, hasOwnProperty, isArray } from "@glideapps/ts-necessities";
import deepEqual from "deep-equal";

export enum SourceColumnKind {
    DefaultContext = "default-ctx",
    UserProfile = "user-profile",
    ContainingScreen = "containing-screen",
    // This kind is only used in builder actions.  It refers to an output from
    // a previous action node.  In this case `name` must have two or three
    // elements:
    // - the action node ID
    // - the name of the output
    // - optionally, the name of the column in the output, if the output is a
    //   row
    ActionNodeOutput = "action-node-output",
}

export interface SourceColumn {
    readonly kind: SourceColumnKind;
    // If this is just a string, it's equivalent to an array of one element.
    // The cases supported here are:
    // - An empty array means the whole row.
    // - A single string means the column with that name.
    // - `SourceColumnKind.ActionNodeOutput` requires two or three elements.
    //   See above.
    // - All other kinds don't support arrays with more than one element, but
    //   the intention is that we will implement inline lookups that way.
    readonly name: string | readonly string[];
}

export type ActionNodeOutputSourceColumn = SourceColumn & { readonly kind: SourceColumnKind.ActionNodeOutput };

type LegacySourceColumn = string | SourceColumn;

export function areSourceColumnsEqual(a: SourceColumn, b: SourceColumn): boolean {
    return deepEqual(a, b, { strict: true });
}

export function getSourceColumnPath({ name }: SourceColumn): readonly string[] {
    if (isArray(name)) {
        return name;
    } else {
        return [name];
    }
}

// `undefined` means the whole row
export function getSourceColumnSinglePath(sc: SourceColumn): string | undefined {
    const path = getSourceColumnPath(sc);
    if (path.length !== 1) return undefined;
    return path[0];
}

export function isSourceColumn(x: unknown): x is SourceColumn {
    if (!hasOwnProperty(x, "kind")) return false;
    if (
        x.kind !== SourceColumnKind.DefaultContext &&
        x.kind !== SourceColumnKind.UserProfile &&
        x.kind !== SourceColumnKind.ContainingScreen &&
        x.kind !== SourceColumnKind.ActionNodeOutput
    ) {
        return false;
    }
    if (!hasOwnProperty(x, "name")) return false;
    if (!isArray(x.name) && typeof x.name !== "string") return false;
    return true;
}

export function makeSourceColumn(name: string, kind?: SourceColumnKind): SourceColumn;
export function makeSourceColumn(sourceColumn: LegacySourceColumn): SourceColumn;
export function makeSourceColumn(value: unknown): SourceColumn | undefined;
export function makeSourceColumn(name: unknown, kind?: SourceColumnKind): SourceColumn | undefined {
    if (isSourceColumn(name)) {
        assert(kind === undefined);
        return name;
    } else if (typeof name === "string") {
        return { kind: kind ?? SourceColumnKind.DefaultContext, name };
    } else {
        return undefined;
    }
}

export function makeActionNodeOutputSourceColumn(
    actionNodeKey: string,
    outputName: string,
    columnInRow: string | undefined
): ActionNodeOutputSourceColumn {
    return { kind: SourceColumnKind.ActionNodeOutput, name: filterUndefined([actionNodeKey, outputName, columnInRow]) };
}

export function isActionNodeOutputSourceColumn(sc: SourceColumn): sc is ActionNodeOutputSourceColumn {
    return sc.kind === SourceColumnKind.ActionNodeOutput;
}

export function decomposeActionNodeOutputSourceColumn(
    sc: SourceColumn
): readonly [actionNodeKey: string, outputName: string, columnInRow: string | undefined] | undefined {
    if (sc.kind !== SourceColumnKind.ActionNodeOutput) return undefined;
    if (sc.name.length !== 2 && sc.name.length !== 3) return undefined;
    return [sc.name[0], sc.name[1], sc.name[2]];
}

export function getDefaultContextColumnName(sc: SourceColumn): string | undefined {
    if (sc.kind !== SourceColumnKind.DefaultContext) return undefined;
    const path = getSourceColumnPath(sc);
    if (path.length !== 1) return undefined;
    return path[0];
}
