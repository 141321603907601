import { AppKind } from "@glide/location-common";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import { type LoadingValue, type Row, isLoadingValue } from "@glide/computation-model-types";
import { asRow } from "@glide/common-core/dist/js/computation-model/data";
import {
    type TableName,
    type Description,
    type TableGlideType,
    getTableRefTableName,
    isSingleRelationType,
    makeTableRef,
    sheetNameForTable,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type ActionDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    ActionKind,
    ScreenDescriptionKind,
    getTableProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeTableProperty,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import {
    type AppDescriptionContext,
    type AppUpdater,
    type EnumPropertyCase,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    type RewritingComponentConfiguratorContext,
    EnumPropertyHandler,
    NewFreeScreenStyle,
    getAppKindFromAppDescriptionContext,
    isFreeScreen,
    thisRowSourceColumn,
    PropertySection,
    makeTableOrRelationPropertyDescriptor,
    type ActionAvailability,
} from "@glide/function-utils";
import { logError, nullToUndefined } from "@glide/support";
import {
    type WireActionInflationBackend,
    type WireRowHydrationValueProvider,
    type WireActionResult,
    type WireActionResultBuilder,
    PageScreenTarget,
    type WireActionHydrator,
} from "@glide/wire";
import { DefaultMap, definedMap } from "@glideapps/ts-necessities";
import { makeScreenTitlePropertyDescriptor } from "../components/descriptor-utils";
import { copyClassOrArrayScreen } from "../copy-screen";
import {
    areColumnTypesEqualExceptPrimitives,
    getRelationForDescription,
    getSourceColumnOrThis,
} from "../description-utils";
import type { StaticActionContext } from "../static-context";
import { makeDefaultClassScreenForPage } from "../pages";
import { getDefaultTable } from "../schema-utils";
import { inflateStringProperty, makeRowGetter } from "../wire/utils";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler, tokenForProperty } from "./base";
import { getSupportsUserProfileRowAccess, getTokenizedSourceColumn } from "./set-columns";
import { ICON_PALE } from "../plugins/icon-colors";

export interface SingleRelationOrThisDescription {
    readonly sourceColumn: PropertyDescription | undefined;
}

export interface NavigationTargetDescription {
    readonly navigationTarget: PropertyDescription | undefined;
}

export interface PushFreeScreenActionDescription
    extends ActionDescription,
        SingleRelationOrThisDescription,
        NavigationTargetDescription {
    readonly kind: ActionKind.PushFreeScreen;
    readonly title: PropertyDescription | undefined;
    readonly screenName: string;
}

function getPushFreeScreenTableName(
    ccc: AppDescriptionContext,
    desc: PushFreeScreenActionDescription
): TableName | undefined {
    const screen = ccc.appDescription?.screenDescriptions[desc.screenName];
    if (screen === undefined) return undefined;
    if (screen.kind !== ScreenDescriptionKind.Class && screen.kind !== ScreenDescriptionKind.Array) {
        return undefined;
    }
    if (!isSingleRelationType(screen.type)) return undefined;
    return getTableRefTableName(screen.type);
}

export function getTableForSourceColumnOrThis(
    desc: PropertyDescription | undefined,
    onlySingleRelations: boolean,
    defaultToThisRow: boolean,
    env: StaticActionContext<SchemaInspector>
): TableGlideType | undefined {
    const relation = getRelationForDescription({
        inOutputRow: false,
        onlySingleRelations,
        defaultToThisRow,
        allowFullTable: false,
    })(
        desc,
        env.context,
        env.tables,
        env.priorSteps?.map(s => s.node)
    );
    return relation?.table;
}

type PublicScreenTargets =
    | PageScreenTarget.Current
    | PageScreenTarget.Main
    | PageScreenTarget.SmallModal
    | PageScreenTarget.LargeModal
    | PageScreenTarget.SlideIn;

function makeNavigationPropertyDescriptor(defaultValue: PublicScreenTargets) {
    const cases: EnumPropertyCase<PublicScreenTargets>[] = [
        {
            label: "Current",
            value: PageScreenTarget.Current,
        },
        {
            label: "Main",
            value: PageScreenTarget.Main,
        },
        {
            label: "Overlay",
            value: PageScreenTarget.SmallModal,
        },
        {
            label: "Slide In",
            value: PageScreenTarget.SlideIn,
        },
    ];

    return new EnumPropertyHandler(
        {
            navigationTarget: defaultValue,
        },
        "Target",
        "Target",
        cases,
        { name: "Navigation", order: 0 },
        "dropdown",
        undefined
    );
}

export const navigationTargetPropertyHandlers = new DefaultMap(makeNavigationPropertyDescriptor);

export function makeNavigationPropertyDescriptors(
    ccc: AppDescriptionContext,
    target: PublicScreenTargets
): readonly PropertyDescriptor[] {
    if (getAppKindFromAppDescriptionContext(ccc) !== AppKind.Page) return [];
    return [navigationTargetPropertyHandlers.get(target)];
}

const getRelation = getRelationForDescription({
    inOutputRow: false,
    onlySingleRelations: false,
    defaultToThisRow: false,
    allowFullTable: false,
});

export class PushFreeScreenActionHandler extends BaseActionHandler<PushFreeScreenActionDescription> {
    public readonly kind = ActionKind.PushFreeScreen;
    public readonly iconName = {
        icon: "st-new-screen" as const,
        kind: "stroke" as const,
        strokeFgColor: ICON_PALE,
    };
    public readonly name: string = "Show new screen";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public get canAutoRun(): boolean {
        return true;
    }

    public getDescriptor(
        _desc: PushFreeScreenActionDescription | undefined,
        env: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const { context: ccc, mutatingScreenKind } = env;

        const sourceProperty = makeTableOrRelationPropertyDescriptor(
            mutatingScreenKind,
            "Data",
            PropertySection.Source,
            {
                allowTables: true,
                allowSingleRelations: true,
                preferFullRow: true,
                allowMultiRelations: ccc.appKind === AppKind.App,
                allowQueryableTables: true,
                allowUserProfileTableAndRow: true,
                sourceIsDefaultCaption: false,
                allowRewrite: false,
                allowUserProfile: getSupportsUserProfileRowAccess(ccc),
                forWriting: false,
            },
            {
                id: "table",
                get: (d: Description): PropertyDescription | undefined => {
                    const a = d as PushFreeScreenActionDescription;
                    if (getSourceColumnOrThis(a.sourceColumn) !== undefined) {
                        return a.sourceColumn;
                    }

                    const tableName = getPushFreeScreenTableName(ccc, a);
                    if (tableName === undefined) return undefined;
                    return makeTableProperty(tableName);
                },
                update: (d: Description, v: PropertyDescription | undefined, updater: AppUpdater | undefined) => {
                    const a = d as PushFreeScreenActionDescription;
                    if (updater === undefined) {
                        logError("No updater for link to screen");
                        return {};
                    }

                    const relationInfo = getRelation(
                        v,
                        env.context,
                        env.tables,
                        env.priorSteps?.map(s => s.node)
                    );
                    if (relationInfo !== undefined) {
                        updater.setFreeScreenTable(
                            a.screenName,
                            false,
                            !relationInfo.isMulti,
                            makeTableRef(relationInfo.table),
                            true,
                            false
                        );

                        return { sourceColumn: v };
                    }

                    const oldTableName = getPushFreeScreenTableName(ccc, a);
                    const oldTable = definedMap(oldTableName, n => ccc.findTable(n));
                    // What does this mean???  Doesn't this mean we're always rewriting?
                    const rewrite = oldTableName === undefined || oldTable !== undefined;

                    const t = definedMap(getTableProperty(v as PropertyDescription), makeTableRef);
                    if (t === undefined) return {};

                    updater.setFreeScreenTable(a.screenName, true, undefined, t, rewrite, false);

                    return { sourceColumn: undefined };
                },
            },
            true
        );

        const properties: PropertyDescriptor[] = [];
        if (ccc.appKind === AppKind.App) {
            properties.push(makeScreenTitlePropertyDescriptor(mutatingScreenKind));
        }

        properties.push(sourceProperty, ...makeNavigationPropertyDescriptors(ccc, PageScreenTarget.Current));

        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 4,
            needsScreenContext: true,
            properties,
        };
    }

    public newActionDescription(
        env: StaticActionContext<InteractiveComponentConfiguratorContext>
    ): PushFreeScreenActionDescription | undefined {
        const { context, tables } = env;
        if (context.appKind === AppKind.App) {
            const screenTable = getDefaultTable(context.schema);
            if (screenTable === undefined) return undefined;

            const screenName = context.addFreeScreenFromTable(
                screenTable,
                false,
                NewFreeScreenStyle.WithComponents,
                undefined
            );
            if (screenName === undefined) return undefined;

            return {
                kind: this.kind,
                screenName,
                sourceColumn: undefined,
                title: makeStringProperty(sheetNameForTable(screenTable)),
                navigationTarget: undefined,
            };
        } else {
            if (tables === undefined) return undefined;

            return {
                kind: this.kind,
                screenName: context.addFreeScreen(new Set(), () =>
                    makeDefaultClassScreenForPage(tables.input, false, false)
                ),
                sourceColumn: makeSourceColumnProperty(thisRowSourceColumn),
                title: undefined,
                navigationTarget: undefined,
            };
        }
    }

    public getScreensUsed(desc: PushFreeScreenActionDescription): readonly string[] {
        return [desc.screenName];
    }

    public rewriteAfterReload(
        desc: PushFreeScreenActionDescription,
        tables: InputOutputTables,
        ccc: RewritingComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): PushFreeScreenActionDescription | undefined {
        if (!ccc.requireScreen(desc.screenName)) return undefined;

        // FIXME: What if the type changes?
        return super.rewriteAfterReload(desc, tables, ccc, mutatingScreenKind);
    }

    public async duplicateAction(
        desc: PushFreeScreenActionDescription,
        _rootDesc: Description,
        copyTables: InputOutputTables | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<PushFreeScreenActionDescription | undefined> {
        const screenDesc = iccc.appDescription.screenDescriptions[desc.screenName];
        if (screenDesc === undefined || !isFreeScreen(desc.screenName, screenDesc)) return undefined;

        if (desc.sourceColumn !== undefined) {
            if (copyTables === undefined) return undefined;

            const relationInfo = getRelation(desc.sourceColumn, iccc, copyTables, undefined);
            if (relationInfo === undefined) return undefined;

            if (!areColumnTypesEqualExceptPrimitives(screenDesc.type, makeTableRef(relationInfo.table)))
                return undefined;
        }

        const screenCopy = await copyClassOrArrayScreen(
            screenDesc,
            iccc,
            mutatingScreenKind,
            screensCreated,
            appFacilities
        );
        if (screenCopy === undefined) return undefined;

        const newScreenName = iccc.addFreeScreen(screensCreated, () => screenCopy);

        return { ...desc, screenName: newScreenName };
    }

    public getTokenizedDescription(
        desc: PushFreeScreenActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const { context: ccc } = env;

        let tokens: readonly DescriptionToken[] | undefined;
        if (desc.sourceColumn === undefined) {
            const tableName = getPushFreeScreenTableName(ccc, desc);
            const table = definedMap(tableName, tn => ccc.findTable(tn));
            if (table === undefined) {
                tokens = [];
            } else {
                tokens = [
                    {
                        kind: "string",
                        value: "for ",
                    },
                    {
                        kind: "column",
                        value: sheetNameForTable(table),
                    },
                ];
            }
        } else {
            tokens = getTokenizedSourceColumn(desc.sourceColumn, "for", true, env);
        }
        if (tokens === undefined) return undefined;

        if (ccc.appKind !== AppKind.App) {
            return tokens;
        }

        const title = tokenForProperty(desc.title, env);
        if (title === undefined) {
            return tokens;
        }

        return [
            ...tokens,
            {
                kind: "string",
                value: " with title ",
            },
            title,
        ];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: PushFreeScreenActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        arb = arb.addData({ screenName: desc.screenName });
        const target = navigationTargetPropertyHandlers.get(PageScreenTarget.Current).getEnum(desc);

        const [titleGetter] = inflateStringProperty(ib, desc.title, true);

        let rowsGetter: ((hb: WireRowHydrationValueProvider) => readonly Row[] | LoadingValue | undefined) | undefined;
        const maybeTableGetter = ib.getTableGetter(desc.sourceColumn, false);
        if (maybeTableGetter !== undefined) {
            rowsGetter = hb => {
                const table = maybeTableGetter[0](hb);
                if (table === undefined || isLoadingValue(table)) return table;
                return table.asArray();
            };
        } else {
            const rowGetter = makeRowGetter(ib, desc.sourceColumn, { inOutputRow: false, defaultToThisRow: false });
            if (rowGetter === false) return arb.inflationError("Invalid data row");
            if (rowGetter !== undefined) {
                rowsGetter = hb => {
                    const maybeRow = nullToUndefined(rowGetter.rowGetter(hb));
                    if (maybeRow === undefined || isLoadingValue(maybeRow)) return maybeRow;
                    const row = asRow(maybeRow);
                    return [row];
                };
            }
        }

        return (vp, skipLoading) => {
            let rows: readonly Row[] | undefined;
            if (rowsGetter !== undefined) {
                const maybeRows = rowsGetter(vp);
                if (isLoadingValue(maybeRows)) return arb.maybeSkipLoading(skipLoading, "Data row");
                if (maybeRows === undefined || maybeRows.length === 0) {
                    return arb.error(true, "No data row to show");
                }
                rows = maybeRows;
            }

            const title = titleGetter(vp) ?? undefined;

            return async ab => {
                const rowID = vp.rowContext?.inputRows[0]?.$rowID;
                ab.pushFreeScreen(desc.screenName, rows ?? [], title, target, rowID);
                return arb.addData({ title, rowID }).success();
            };
        };
    }
}
