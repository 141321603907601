import { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";

import { OrgPlanIcon } from "./org-plan-icon";

export const getIconForPlan = (iconName: PlanKind, size: number) => {
    switch (iconName) {
        case PlanKind.Free:
            return <OrgPlanIcon size={size} hasBackground={false} icon="st-personal-team" />;
        case PlanKind.V3Starter:
            return <OrgPlanIcon size={size} hasBackground={false} icon="st-starter-team" />;
        case PlanKind.V3Pro:
            return <OrgPlanIcon size={size} hasBackground={false} icon="st-pro-team" />;
        case PlanKind.V3Business:
        case PlanKind.V3NonProfit:
            return <OrgPlanIcon size={size} hasBackground={false} icon="st-business-team" />;
        case PlanKind.Unlimited:
            return <OrgPlanIcon size={size} hasBackground={false} icon="st-enterprise-team" />;
        default:
            return "";
    }
};
