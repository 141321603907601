import { assert, isArray, mapFilterUndefined, panic } from "@glideapps/ts-necessities";
import type { CellValue, PrimitiveCellValue, SerializableCellValue } from "./cell-values";
import { isBasePrimitiveValue } from "./cell-values";
import { type GlideDateTimeDocumentData, GlideDateTime, isGlideDateTimeDocumentData } from "./glide-date-time";
import { type GlideJSONDocumentData, GlideJSON, isGlideJSONDocumentData } from "./glide-json";

export function convertValueFromSerializable<T>(
    d: T | GlideDateTimeDocumentData | GlideJSONDocumentData
): T | GlideDateTime | GlideJSON {
    if (isGlideDateTimeDocumentData(d)) return GlideDateTime.fromDocumentData(d);
    if (isGlideJSONDocumentData(d)) return GlideJSON.fromDocumentData(d);

    return d;
}

export function convertValueToSerializable<T>(
    x: T | GlideDateTime | GlideJSON
): T | GlideDateTimeDocumentData | GlideJSONDocumentData {
    if (x instanceof GlideDateTime || x instanceof GlideJSON) {
        return x.toDocumentData();
    } else {
        return x;
    }
}

/**
 * This does not actually always return a `CellValue`, but it tries, and it
 * works recursively through regular objects and arrays.
 */
export function convertSerializableValueToCellValueBestEffort(flattenArrays: boolean, v: unknown): unknown {
    if (!isArray(v)) {
        v = convertValueFromSerializable(v);
    } else {
        if (flattenArrays) {
            const arr: unknown[] = [];

            function recur(x: unknown) {
                if (isArray(x)) {
                    for (const y of x) {
                        recur(y);
                    }
                } else {
                    arr.push(convertValueFromSerializable(x));
                }
            }
            recur(v);

            v = arr;
        } else {
            function recur(x: unknown): unknown {
                if (isArray(x)) {
                    return x.map(recur);
                } else {
                    return convertValueFromSerializable(x);
                }
            }

            v = recur(v);
        }
    }

    return v;
}

interface Options {
    readonly flattenArrays: boolean;
    // semi-strict means return `undefined` for anything that can't be a
    // `CellValue`.
    readonly semiStrictConvertSerializableValue: boolean;
}

export function convertSerializableValueToCellValue(opts: Options, v: unknown): CellValue | undefined {
    if (!opts.semiStrictConvertSerializableValue) {
        return convertSerializableValueToCellValueBestEffort(opts.flattenArrays, v) as CellValue;
    }

    // ##weAreDeserializingGroundValues:
    // These are not actually serializable values, but we do call this
    // function incorrectly with ground values.
    // https://github.com/glideapps/glide/pull/32085
    if (v instanceof GlideDateTime || v instanceof GlideJSON) {
        return v;
    } else if (isGlideDateTimeDocumentData(v)) {
        return GlideDateTime.fromDocumentData(v);
    } else if (isGlideJSONDocumentData(v)) {
        return GlideJSON.fromDocumentData(v);
    } else if (isBasePrimitiveValue(v)) {
        return v;
    } else if (isArray(v)) {
        if (opts.flattenArrays) {
            const arr: CellValue[] = [];

            function recur(x: unknown) {
                if (isArray(x)) {
                    for (const y of x) {
                        recur(y);
                    }
                } else {
                    const r = convertSerializableValueToCellValue(opts, x);
                    if (r === undefined) return;
                    assert(!isArray(r));
                    arr.push(r);
                }
            }
            recur(v);

            return arr;
        } else {
            return mapFilterUndefined(v, y => convertSerializableValueToCellValue(opts, y));
        }
    } else if (v === undefined) {
        return undefined;
    } else {
        return panic(`Could not convert ${JSON.stringify(v)} to CellValue`);
    }
}

type ExtendedCellValue = PrimitiveCellValue | readonly ExtendedCellValue[] | undefined;

export function convertCellValueToSerializableValue(v: ExtendedCellValue): SerializableCellValue | undefined {
    if (!isArray(v)) {
        return convertValueToSerializable(v);
    } else {
        return mapFilterUndefined(v, convertCellValueToSerializableValue);
    }
}
