import { useEventListener } from "@glide/common";
import { isDefined } from "@glide/support";
import classNames from "classnames";
import * as React from "react";
import type { Placement } from "react-laag";
import { useLayer } from "react-laag";
import tw from "twin.macro";
import { css, styled } from "@glide/common-components";
import type { ExtractedAction, ExtractedActions } from "../../wire-lib";
import { APP_MODAL_ROOT } from "../../wire-lib";
import type { WireButtonProps } from "../wire-button/wire-button";
import { WireButton } from "../wire-button/wire-button";

interface Props extends Omit<WireButtonProps, "onClick"> {
    readonly menuItems: ExtractedActions;
    readonly defaultPlacement?: Placement;
    readonly className?: string;
}

// We might use `as` to make an anchor out of this.
const TitleContainer = styled.div``;

interface WireMenuButtonItemProps {
    readonly item: ExtractedAction;
    readonly closeMenu: () => void;
}

const WireMenuButtonItem: React.FC<WireMenuButtonItemProps> = p => {
    const { item, closeMenu } = p;

    // We need this so the mouseDown doesn't close the layer
    const onItemMouseDown = React.useCallback((e: React.MouseEvent<HTMLLIElement | HTMLAnchorElement>) => {
        e.stopPropagation();
    }, []);

    const onItemClick = React.useCallback(
        (e: React.MouseEvent<HTMLLIElement | HTMLAnchorElement>) => {
            e.stopPropagation();
            if (!item.enabled) {
                return;
            }
            item.run?.(item.url !== undefined);
            if (item.url === undefined) {
                closeMenu();
            } else {
                // If we didn't defer this to the next tick, we'd re-render
                // and unmount so fast the browser wouldn't be able to actually
                // click the <a> tag.
                setTimeout(() => closeMenu(), 0);
            }
        },
        [item, closeMenu]
    );

    return (
        <li>
            <TitleContainer
                data-testid="mb-li"
                as={item.url === undefined ? undefined : "a"}
                href={item.url}
                rel={"noopener noreferrer"}
                target={item.url === undefined ? undefined : "_blank"}
                className={classNames({ disabled: !item.enabled })}
                css={css`
                    &.disabled {
                        ${tw`opacity-50 pointer-events-none`}
                    }
                    display: block;
                `}
                tw="px-3 py-2 cursor-pointer rounded-md hover:bg-n100A font-normal text-sm focus-visible:underline
                    text-text-dark"
                onClick={onItemClick}
                onMouseDown={onItemMouseDown}>
                {item.title}
            </TitleContainer>
        </li>
    );
};

interface ControlledProps extends Props {
    readonly isOpen: boolean;
    readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ControlledWireMenuButton: React.VFC<ControlledProps> = p => {
    const { isOpen, setIsOpen, menuItems, defaultPlacement = "bottom-end", ...rest } = p;

    const { layerProps, triggerProps, renderLayer } = useLayer({
        isOpen,
        auto: true,
        placement: defaultPlacement,
        possiblePlacements: ["top-end", "top-start", "bottom-end", "bottom-start"],
        container: APP_MODAL_ROOT,
        triggerOffset: 5,
        onOutsideClick: () => setIsOpen(false),
    });

    useEventListener(
        "keydown",
        e => {
            if (e.key === "Escape") {
                setIsOpen(false);
            }
        },
        window,
        false,
        false
    );

    const onButtonClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            const isKeyboardClick = e.clientX === 0 && e.clientY === 0;
            if (isKeyboardClick) {
                setIsOpen(o => !o);
            }
        },
        [setIsOpen]
    );

    const onButtonMouseDown = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            setIsOpen(o => !o);
        },
        [setIsOpen]
    );

    const closeMenu = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <WireButton
                data-testid="menu-button"
                {...rest}
                {...triggerProps}
                onMouseDown={onButtonMouseDown}
                onClick={onButtonClick}
                iconOnly
                className={classNames(isOpen && "is-opened", p.className)}
                iconName="path:/svg/stroke/st-more.svg">
                Menu
            </WireButton>
            {isOpen &&
                renderLayer(
                    <div {...layerProps}>
                        <WireFloatingMenu menuItems={menuItems} closeMenu={closeMenu} />
                    </div>
                )}
        </>
    );
};

interface WireFloatingMenuProps {
    readonly menuItems: ExtractedActions;
    readonly closeMenu: () => void;
}

export const WireFloatingMenu: React.FC<WireFloatingMenuProps> = p => {
    const { menuItems, closeMenu } = p;
    return (
        <ul
            css={css`
                ${tw`[box-shadow:0 0 0 1px rgba(5, 26,46, 0.05), 0px 2px 20px rgba(0, 0, 0, 0.06)]`}
            `}
            data-testid="mb-dropdown"
            tw="[max-width:300px] [min-width:192px] flex flex-col bg-bg-front rounded-lg p-1 after:(content-[''] rounded-lg absolute inset-0 ring-1 ring-inset ring-w05A pointer-events-none isolate)">
            {menuItems.filter(isDefined).map((item, idx) => (
                <WireMenuButtonItem key={`mb-item-${idx}`} item={item} closeMenu={closeMenu} />
            ))}
        </ul>
    );
};

export const WireMenuButton: React.FC<Props> = p => {
    const [isOpen, setIsOpen] = React.useState(false);

    return <ControlledWireMenuButton isOpen={isOpen} setIsOpen={setIsOpen} {...p} />;
};
