import * as React from "react";

import { GlobalStyle } from "@glide/common";
import { glideViralReferralLink } from "@glide/support";
import { domMovementContainerHandlers } from "./chrome/smart/swipe-out-controller/swipe-out-controller";
import OutputPad from "./chrome/views/output-pad/output-pad";
import {
    FrameInner,
    FrameWrapper,
    GlideBar,
    MainWrapper,
    OutputWrapper,
    PageContainer,
    Scroller,
} from "@glide/common-components";

const Frame: React.VFC = () => {
    return (
        <div tw="w-full h-full max-height[852px] border-radius[55px] box-shadow[0px 0px 0 12px #BDC1C7]">
            <div tw="w-full h-full border-radius[55px] box-shadow[0px 0px 0 8px black]" />
        </div>
    );
};

function MadeWithGlide(): JSX.Element {
    return (
        <GlideBar>
            <a href={glideViralReferralLink("desktop-badge")} target="_blank" rel="noopener noreferrer">
                <img alt="make your own app with glide" src="/images/makeyourownapp-withglide-blue.svg" />
            </a>
        </GlideBar>
    );
}

interface Props extends React.PropsWithChildren {
    showBranding: boolean;
    isSkeleton: boolean;
    appContainer: JSX.Element;
    appInfo: JSX.Element;
}

export class GlideAppDesktopPlayer extends React.PureComponent<Props> {
    private ref = React.createRef<HTMLDivElement>();
    private movementHandlers = domMovementContainerHandlers(this.ref);

    render() {
        const { appContainer, appInfo, showBranding, isSkeleton } = this.props;
        const outputWrapperClass = isSkeleton ? "skeleton" : undefined;
        return (
            <React.Fragment>
                <GlobalStyle />
                {showBranding && !isSkeleton && <MadeWithGlide />}
                <Scroller ref={this.ref} {...this.movementHandlers}>
                    <PageContainer>
                        <MainWrapper>
                            <FrameWrapper>
                                <FrameInner>
                                    <Frame />
                                </FrameInner>
                                <OutputWrapper className={outputWrapperClass}>
                                    <OutputPad>{appContainer}</OutputPad>
                                </OutputWrapper>
                            </FrameWrapper>
                        </MainWrapper>
                        {appInfo}
                    </PageContainer>
                </Scroller>
            </React.Fragment>
        );
    }
}

export const GlideTemplateDesktopPlayer: React.VFC<Props> = p => {
    const { appContainer, appInfo, showBranding, isSkeleton } = p;

    const ref = React.useRef<HTMLDivElement>(null);
    const movementHandlers = React.useMemo(() => domMovementContainerHandlers(ref), [ref]);
    return (
        <React.Fragment>
            <GlobalStyle />
            {showBranding && !isSkeleton && <MadeWithGlide />}
            <Scroller ref={ref} {...movementHandlers}>
                <PageContainer>
                    {appContainer}
                    {appInfo}
                </PageContainer>
            </Scroller>
        </React.Fragment>
    );
};
