import * as React from "react";
import type { WireRenderer } from "../wire-renderer";
import type {
    WireAppCodeScannerScreenComponent,
    WireInlineScannerComponent,
} from "@glide/fluent-components/dist/js/base-components";
import { AppIcon, useChangeObservable } from "@glide/common";
import { useAppEnvironment } from "@glide/common-core/dist/js/use-app-environment";
import { type WireAlwaysEditableValue, ValueChangeSource } from "@glide/wire";
import { getSimpleValue, reportBillable } from "@glide/backend-api";
import { dynamsoftScannerNumUpdates } from "@glide/function-utils";
import { defined, sleep } from "@glideapps/ts-necessities";
import { Watchable, logError } from "@glide/support";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";

const DynamsoftScanner = React.lazy(() => import("./dynamsoft-scanner"));

let isLoadingDynamsoftHandshake = false;
const dynamsoftHandshake = new Watchable<string | undefined>(undefined);

interface UseDynamsoftHandshake {
    handshake: string | undefined;
    isLoading: boolean;
}

function useDynamsoftHandshake(): UseDynamsoftHandshake {
    const appID = defined(useAppID());

    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const loadDynamsoftHandshake = async () => {
            if (isLoadingDynamsoftHandshake) return;
            isLoadingDynamsoftHandshake = true;

            for (let retry = 0; retry < 5; retry++) {
                try {
                    const fetchedHandshake = await getSimpleValue(
                        appID,
                        "dynamsoft-scanner-handshake",
                        getAppFacilities()
                    );
                    if (fetchedHandshake !== undefined) {
                        dynamsoftHandshake.current = fetchedHandshake;
                        break;
                    } else {
                        logError(`Could not get scanner handshake [${retry + 1} / 5]`);
                    }
                } finally {
                    await sleep(2000);
                }
            }

            setIsLoading(false);
        };

        void loadDynamsoftHandshake();
    }, [appID]);

    const handshake = useChangeObservable(dynamsoftHandshake);

    return { isLoading, handshake };
}

interface WireCodeScannerProps {
    readonly backend: WireBackendInterface;
    readonly value: WireAlwaysEditableValue<string>;
}

const WireCodeScanner: React.VFC<WireCodeScannerProps> = p => {
    const { backend, value } = p;

    const appID = defined(useAppID());

    const appEnv = useAppEnvironment(appID);
    const configuredHandshake = appEnv?.appFeatures.dbrScannerHandshake;
    // We use `false` in some orgs to mean "they don't have a handshake anymore"
    const orgScannerHandshake = typeof configuredHandshake === "string" ? configuredHandshake : undefined;

    const { handshake: glideHandshake, isLoading: isLoadingHandshake } = useDynamsoftHandshake();

    const onScannedCode = React.useCallback(
        (code: string) => {
            if (value.onChangeToken === undefined) return;
            backend.valueChanged(value.onChangeToken, code, ValueChangeSource.User);
            if (orgScannerHandshake === undefined && glideHandshake !== undefined) {
                // We only report billables if we're using our own scanner
                // handshake, vs the customer's.
                void reportBillable(
                    {
                        appID: appID,
                        pluginID: "native-barcode-scanner",
                        actionID: "scan-barcode",
                        count: dynamsoftScannerNumUpdates,
                    },
                    getAppFacilities()
                );
            }
        },
        [appID, backend, glideHandshake, orgScannerHandshake, value.onChangeToken]
    );

    const handshake = orgScannerHandshake ?? glideHandshake;

    return (
        <React.Suspense fallback={<AppIcon icon="halfSpinner" />}>
            <DynamsoftScanner
                onScannedCode={onScannedCode}
                isLoadingHandshake={isLoadingHandshake}
                handshake={handshake}
            />
        </React.Suspense>
    );
};

export const PageCodeScannerScreenRenderer: WireRenderer<WireAppCodeScannerScreenComponent> = React.memo(p => {
    const { value, backend } = p;

    return (
        <div tw="w-full h-full relative page-md:(aspect-h-1 aspect-w-1 h-auto)">
            <WireCodeScanner value={value} backend={backend} />
        </div>
    );
});

export const InlineCodeScannerRenderer: WireRenderer<WireInlineScannerComponent> = React.memo(p => {
    const { backend, editableScan } = p;

    return (
        <div tw="w-full h-full relative page-md:(aspect-ratio[1/1] mx-auto h-auto max-width[640px])">
            <WireCodeScanner value={editableScan} backend={backend} />
        </div>
    );
});
