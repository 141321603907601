import { isDefined } from "@glide/support";
import type { WireComponent } from "@glide/wire";
import { WireComponentKind } from "@glide/wire";

/*  We need to know in the player if a screen is a collection screen.
    This is needed for a proper mobile animation on the collection title.
*/
export const isCollectionScreen = (components: readonly (WireComponent | null)[]) => {
    const renderableComponents = components.filter(isDefined);
    return renderableComponents.length === 1 && renderableComponents[0]?.kind === WireComponentKind.List;
};
