import type {
    AppUserData,
    DataRowStore,
    TableDataEntries,
} from "@glide/common-core/dist/js/components/datastore/data-row-store";
import {
    type GroundValue,
    type Row,
    type WritableValue,
    MutableTable,
    makeLoadingValue,
    type TableKeeper,
    type Handler,
    type IncomingSlot,
    type Namespace,
    fullDirt,
    type RowIndex,
    isBaseRowIndex,
} from "@glide/computation-model-types";
import { getRowColumn } from "@glide/common-core/dist/js/computation-model/data";
import type { DocumentData } from "@glide/common-core/dist/js/Database";
import { rowIndexColumnName, type TableGlideType, getTableName } from "@glide/type-schema";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import { convertSerializableValueToCellValue, forceGlideDateTime } from "@glide/data-types";
import { Watchable, logError, memoizeFunction, truthify, type ChangeObservable } from "@glide/support";
import {
    assert,
    definedMap,
    filterUndefined,
    hasOwnProperty,
    mapFilterUndefined,
    panic,
} from "@glideapps/ts-necessities";
import toPairs from "lodash/toPairs";
import { isRowOwnedByUser } from "./is-row-owned";
import { getFeatureSetting } from "@glide/common-core";

export function findRowsInTable(
    table: ReadonlyMap<string, Row>,
    rowIndex: RowIndex,
    rowIDColumnName: string | undefined
): readonly Row[] {
    let keyColumnName: string;
    let keyColumnValue: unknown;
    if (isBaseRowIndex(rowIndex)) {
        keyColumnName = rowIndexColumnName;
        keyColumnValue = rowIndex;
    } else {
        keyColumnName = rowIndex.keyColumnName;
        keyColumnValue = rowIndex.keyColumnValue;
    }
    assert(keyColumnValue !== undefined);

    if (keyColumnName === rowIDColumnName) {
        if (typeof keyColumnValue !== "string") return [];
        return filterUndefined([table.get(keyColumnValue)]);
    }

    const rows: Row[] = [];
    for (const row of table.values()) {
        const column = getRowColumn(row, keyColumnName);
        if (column === keyColumnValue) {
            rows.push(row);
        }
    }
    return rows;
}

// FIXME: Once we're all on NCM this needs to be completely reworked and
// simplified.  It has at least three public methods for modifying rows, for
// example.
export abstract class ComputationModelDataRowStoreBase implements DataRowStore, Handler, TableKeeper {
    private _ns: Namespace | undefined;
    protected readonly allRowsByDocumentID = new Map<string, Row>();
    // This will only be defined if `_filterByEmail` is set.  The reason we
    // need this is because we also need to update rows that filtered out (see
    // https://github.com/quicktype/glide/issues/12453), but they're not in
    // `_tableData`.
    protected readonly allRowsByRowID: Map<string, Row> | undefined;
    // This does not contain the same set of rows as `_allRowsByDocumentID`.
    // Invisible rows are only added here, for example, but rows not owned by
    // the current user won't be added here.
    protected readonly tableData = new MutableTable();
    protected readonly addedRows = new Map<string, Row>();
    private readonly _firstRowObservable = new Watchable<Row | undefined>(undefined);
    // This will be set to `false` when we either got our first data row, or
    // data loading failed.
    // FIXME: Handle data loading failed
    protected dataIsLoading = true;

    constructor(private readonly _filterByEmail: boolean, private readonly _filterAddedRowsByEmail: boolean) {
        if (_filterByEmail) {
            this.allRowsByRowID = new Map();
        }
    }

    protected abstract getTable(): TableGlideType | undefined;
    protected abstract getAppUserData(): AppUserData | undefined;
    protected abstract fetchData(): void;

    public get symbolicRepresentation(): string {
        return `data-row-store ${definedMap(this.getTable(), getTableName)?.name}`;
    }

    public getSlots(): readonly IncomingSlot[] {
        return [];
    }

    public recompute(): GroundValue {
        // This will initiate fetching the data for this table, if that hasn't
        // happened already.
        this.fetchData();

        if (this.dataIsLoading) {
            assert(this.tableData.size === 0);
            return makeLoadingValue();
        }

        return this.tableData;
    }

    public setDirty(): void {
        return;
    }

    public get isDirty(): boolean {
        return false;
    }

    public get firstRowObservable(): ChangeObservable<Row | undefined> {
        return this._firstRowObservable;
    }

    private setFirstRowIfNecessary(): void {
        if (this.firstRowObservable.current !== undefined) {
            assert(!this.dataIsLoading);
            return;
        }

        // We don't need to push dirt for this because whoever called this
        // method is doing it already.
        this.dataIsLoading = false;

        let row: Row | undefined;
        for (const r of this.tableData.values()) {
            if (r.$isVisible) {
                row = r;
                break;
            }
        }
        if (row === undefined) return;
        this._firstRowObservable.current = row;
    }

    protected setRowInTableData(row: Row): void {
        this.tableData.set(row.$rowID, row);
        this.setFirstRowIfNecessary();
    }

    protected isRowIncluded(row: Row, appUserData: AppUserData | undefined, isRowAdded: boolean): boolean {
        if (isRowAdded) {
            if (!this._filterAddedRowsByEmail) return true;
        } else {
            if (!this._filterByEmail) return true;
        }

        return isRowOwnedByUser(row, appUserData, this.getTable());
    }

    protected pushDirtForRow(rowID: string, columns: ReadonlySet<string> | true = true): void {
        if (columns !== true && columns.size === 0) return;

        this._ns?.pushDirt(this, { kind: "row", rowID, columns });
    }

    protected pushDirtForTable() {
        this._ns?.pushDirt(this, fullDirt);
    }

    // There can be more than one row with the same row index.  The main case
    // of this is the user profile row, which we add as an invisible row right
    // after logging in, before we've loaded the data for the user profile
    // table.
    // https://github.com/quicktype/glide/issues/15737
    protected findRows(rowIndex: RowIndex): readonly Row[] {
        const rowIDColumn = this.getTable()?.rowIDColumn;
        let rows = findRowsInTable(this.tableData, rowIndex, rowIDColumn);
        if (rows.length === 0 && this.allRowsByRowID !== undefined) {
            rows = findRowsInTable(this.allRowsByRowID, rowIndex, rowIDColumn);
        }
        return rows;
    }

    private readonly dataColumnNames = memoizeFunction(
        "dataColumnNames",
        (table: TableGlideType | undefined, onlyUserSpecific: boolean) =>
            mapFilterUndefined(table?.columns ?? [], ({ name, isUserSpecific, formula }) => {
                if (onlyUserSpecific && !truthify(isUserSpecific)) return undefined;
                if (formula !== undefined) return undefined;
                return name;
            })
    );

    private getDataColumnNames(onlyUserSpecific: boolean): readonly string[] {
        return this.dataColumnNames(this.getTable(), onlyUserSpecific);
    }

    // This duplicates a lot of what's in ##updateRowData.
    protected updateRowData(
        row: Row,
        updates: DocumentData,
        onlyUserSpecific: boolean,
        isNewRow: boolean,
        isPartialUpdate: boolean
    ): void {
        const dirtyColumns = isNewRow ? undefined : new Set<string>();
        const dataColumnNames = this.getDataColumnNames(onlyUserSpecific);

        // Iterating this way is significantly faster because it doesn't need
        // an iterator.
        const len = dataColumnNames.length;
        for (let i = 0; i < len; i++) {
            const name = dataColumnNames[i];
            if (isPartialUpdate && !hasOwnProperty(updates, name)) continue;

            // NOTE: This method gets called with `updates` having
            // `GroundValue`s, i.e. non-serializable values. We made
            // `convertSerializableValueToCellValue` tolerate those just for
            // that case.  ##weAreDeserializingGroundValues
            const value = forceGlideDateTime(
                convertSerializableValueToCellValue(
                    {
                        flattenArrays: false,
                        semiStrictConvertSerializableValue: getFeatureSetting("semiStrictConvertSerializableValue"),
                    },
                    updates[name]
                )
            );

            if (!isNewRow) {
                const existingValue = getRowColumn(row, name);
                // FIXME: Also check arrays for equality once we support
                // support them.
                if (value === existingValue) continue;
            }

            row[name] = value;
            dirtyColumns?.add(name);
        }

        // If `dirtyColumns` is `undefined`, it defaults to `true`
        this.pushDirtForRow(row.$rowID, dirtyColumns);
    }

    // This is a fast specialized case of the above method, which handles the
    // case where it's a new row and we have no user-specific data, which
    // happens when we process the snapshots.  It's faster because we know
    // which columns we have to do `GlideDateTime`-conversion for instead of
    // having to iterate over all columns and checking.
    protected addNewRowDataFast(
        rowID: string,
        data: DocumentData,
        columnNamesToConvert: readonly string[] | undefined,
        haveOwnership: boolean
    ): Row {
        let row: Row;

        if (haveOwnership) {
            data["$rowID"] = rowID;
            data["$isVisible"] = true;
            row = data as Row;
        } else {
            row = Object.assign({ $rowID: rowID, $isVisible: true }, data);
        }

        if (columnNamesToConvert !== undefined) {
            const len = columnNamesToConvert.length;
            for (let i = 0; i < len; i++) {
                const columnName = columnNamesToConvert[i];
                const v = row[columnName];
                assert(v !== undefined);
                row[columnName] = convertSerializableValueToCellValue(
                    {
                        flattenArrays: false,
                        semiStrictConvertSerializableValue: getFeatureSetting("semiStrictConvertSerializableValue"),
                    },
                    v
                );
            }
        }

        this.pushDirtForRow(rowID);

        return row;
    }

    protected getExistingOrAddedRow(documentID: string, isAdded: boolean, rowID: string | undefined): Row | undefined {
        let row = this.allRowsByDocumentID.get(documentID);
        if (row === undefined) {
            if (!isAdded) return undefined;

            if (rowID !== undefined) {
                row = this.addedRows.get(rowID);
                if (row !== undefined) {
                    this.addedRows.delete(rowID);
                }
            } else {
                for (const r of this.addedRows.values()) {
                    row = r;
                    this.addedRows.delete(row.$rowID);
                    break;
                }
            }

            if (row !== undefined) {
                this.allRowsByDocumentID.set(documentID, row);
                this.allRowsByRowID?.set(row.$rowID, row);
            }
        }
        return row;
    }

    public setAppDataForSortedEntries(
        _sortedEntries: TableDataEntries,
        _setAppDataIfLoading: boolean
    ): number | undefined {
        return panic("Must be implemented by subclass");
    }

    // Takes possession of the row
    public addRow(row: Row): void {
        if (!this.isRowIncluded(row, this.getAppUserData(), true)) return;

        this.addedRows.set(row.$rowID, row);

        this.setRowInTableData(row);
        this.pushDirtForRow(row.$rowID);
    }

    public addInvisibleRow(row: Row): void {
        assert(!row.$isVisible);
        this.setRowInTableData(row);
        this.pushDirtForRow(row.$rowID);
    }

    public deleteInvisibleRow(rowID: string): void {
        const row = this.tableData.get(rowID);
        if (row === undefined) return;

        assert(!row.$isVisible);
        this.deleteRow(rowID);
    }

    public updateRow(
        _documentID: string,
        _isAdded: boolean,
        _mayAddRow: boolean,
        _updates: DocumentData,
        _userDataUpdates: DocumentData | undefined,
        _fullData: DocumentData,
        _fullUserData: DocumentData | undefined,
        _columnNamesToConvert: readonly string[] | undefined,
        _haveOwnership: boolean
    ): { reprocessUserSpecificData: boolean; didAddRow: boolean } | undefined {
        return undefined;
    }

    public updateRowIfPresent(_documentID: string, _updates: DocumentData): void {
        return;
    }

    public addRowWithValues(_values: Record<string, WritableValue>, _rowID: string | undefined): Row | undefined {
        return undefined;
    }

    public setColumnsInRow(rowIndex: RowIndex, values: Record<string, WritableValue>): void {
        for (const row of this.findRows(rowIndex)) {
            const dirtyColumns = new Set<string>();

            for (const [columnName, columnValue] of toPairs(values)) {
                // FIXME: Are we not converting GlideDateTimes here?
                const existingValue = getRowColumn(row, columnName);
                if (columnValue === existingValue) continue;

                row[columnName] = forceGlideDateTime(columnValue);
                dirtyColumns.add(columnName);
            }

            this.pushDirtForRow(row.$rowID, dirtyColumns);
        }
    }

    public updateRowWithPartialData(rowIndex: RowIndex, updates: DocumentData): void {
        for (const row of this.findRows(rowIndex)) {
            this.updateRowData(row, updates, false, false, true);
        }
    }

    public deleteRow(rowID: string): void {
        this.tableData.delete(rowID);
        this.pushDirtForRow(rowID);
    }

    public deleteRowAtIndex(rowIndex: RowIndex): void {
        for (const row of this.findRows(rowIndex)) {
            this.deleteRow(row.$rowID);
        }
    }

    public deleteDataRow(_documentID: string): void {
        return;
    }

    public clear(): void {
        this.allRowsByDocumentID.clear();
        this.allRowsByRowID?.clear();
        this.tableData.clear();
        this.dataIsLoading = true;
        // We do this last because we might have listeners that call back
        this._firstRowObservable.current = undefined;
    }

    public connect(ns: Namespace): void {
        assert(this._ns === undefined);
        this._ns = ns;
    }

    public disconnect(): void {
        assert(this._ns !== undefined);
        this._ns = undefined;
    }
}

export class ComputationModelDataRowStore extends ComputationModelDataRowStoreBase {
    // We keep the timestamps of when we deleted a specific for ID because
    // it's possible (at least in some data sources) that row IDs can come
    // back.  We clear this out after a while so that they can.  FIXME: The
    // super proper way to do this would be to also store the row that has
    // maybe come back, instead of just discarding it, and then when we've
    // decided that enough time has passed, add that row.
    // https://github.com/quicktype/glide/issues/11990
    private _deletedRowIDs: Map<string, number> | undefined;

    constructor(
        filterByEmail: boolean,
        filterAddedRowsByEmail: boolean,
        private readonly _getTable: () => TableGlideType | undefined,
        private readonly _getAppUserData: () => AppUserData | undefined,
        private readonly _fetchData: () => void
    ) {
        super(filterByEmail, filterAddedRowsByEmail);

        const hasProperRowIDs = _getTable()?.rowIDColumn !== undefined;
        // This means that row IDs are not just the row index, or something
        // else that can come back any time.
        if (hasProperRowIDs) {
            this._deletedRowIDs = new Map();
        }
    }

    protected getTable(): TableGlideType | undefined {
        return this._getTable();
    }

    protected getAppUserData(): AppUserData | undefined {
        return this._getAppUserData();
    }

    protected fetchData(): void {
        return this._fetchData();
    }

    public addRowWithValues(values: Record<string, WritableValue>, rowID: string | undefined): Row | undefined {
        if (rowID === undefined) {
            rowID = makeRowID();
        }

        const row: Row = { ...values, $rowID: rowID, $isVisible: true };
        this.addRow(row);

        return row;
    }

    public deleteDataRow(documentID: string): void {
        const row = this.allRowsByDocumentID.get(documentID);
        if (row === undefined) return;

        this.allRowsByDocumentID.delete(documentID);
        this.allRowsByRowID?.delete(row.$rowID);
    }

    public updateRow(
        documentID: string,
        isAdded: boolean,
        mayAddRow: boolean,
        updates: DocumentData,
        userDataUpdates: DocumentData | undefined,
        fullData: DocumentData,
        fullUserData: DocumentData | undefined,
        // `false` means we don't know which columns need converting
        columnNamesToConvert: readonly string[] | undefined | false,
        haveOwnership: boolean
    ): { reprocessUserSpecificData: boolean; didAddRow: boolean } | undefined {
        const rowIDColumn = this.getTable()?.rowIDColumn;

        let row = this.getExistingOrAddedRow(
            documentID,
            isAdded,
            definedMap(rowIDColumn, c => fullData[c])
        );

        let reprocessUserSpecificData = false;
        let didAddRow = false;

        if (row !== undefined) {
            if (rowIDColumn !== undefined && hasOwnProperty(updates, rowIDColumn)) {
                // The row ID for a row has changed, so we will reprocess all
                // user-specific data, since we keep it by row index.
                reprocessUserSpecificData = true;
            }

            this.updateRowData(
                row,
                userDataUpdates === undefined ? updates : { ...updates, ...userDataUpdates },
                false,
                false,
                true
            );
        } else if (mayAddRow) {
            if (!isAdded) {
                logError("Modified row doesn't exist yet", documentID);
            }

            didAddRow = true;

            // If we don't have a row ID, the document ID is the best we can
            // do.
            const rowID = definedMap(rowIDColumn, c => fullData[c]) ?? documentID;

            if (fullUserData === undefined && columnNamesToConvert !== false) {
                row = this.addNewRowDataFast(rowID, fullData, columnNamesToConvert, haveOwnership);
            } else {
                row = { $rowID: rowID, $isVisible: true };

                this.updateRowData(row, { ...fullData, ...fullUserData }, false, true, true);
            }

            this.allRowsByDocumentID.set(documentID, row);
            this.allRowsByRowID?.set(row.$rowID, row);
        }

        return { reprocessUserSpecificData, didAddRow };
    }

    public updateRowIfPresent(documentID: string, updates: DocumentData): void {
        const row = this.allRowsByDocumentID.get(documentID);
        if (row === undefined) return;

        this.updateRowData(row, updates, true, false, true);
    }

    public deleteRow(rowID: string): void {
        this._deletedRowIDs?.set(rowID, Date.now());
        super.deleteRow(rowID);
    }

    private getDeletedRowIDs(): ReadonlyMap<string, number> | undefined {
        if (this._deletedRowIDs === undefined) return undefined;

        // Remove entries older than one minute
        const threshold = Date.now() - 60 * 1000;
        const newMap = new Map<string, number>();
        for (const [rowID, timestamp] of this._deletedRowIDs) {
            if (timestamp < threshold) continue;
            newMap.set(rowID, timestamp);
        }
        this._deletedRowIDs = newMap;
        return this._deletedRowIDs;
    }

    public setAppDataForSortedEntries(
        sortedEntries: TableDataEntries,
        setAppDataIfLoading: boolean
    ): number | undefined {
        const rowIDsNotSeen = new Set(this.tableData.keys());
        const appUserData = this.getAppUserData();
        const deletedRowIDs = this.getDeletedRowIDs();

        let numRowsAdded = 0;
        for (const [documentID] of sortedEntries) {
            const row = this.allRowsByDocumentID.get(documentID);
            // This can happen if the row is deleted, so we don't have its
            // document, but the caller doesn't care and still gives it to us.
            if (row === undefined) continue;

            if (!this.isRowIncluded(row, appUserData, false)) continue;

            numRowsAdded++;

            const rowID = row.$rowID;
            rowIDsNotSeen.delete(rowID);

            if (!this.tableData.has(rowID) && deletedRowIDs?.has(rowID) !== true) {
                this.setRowInTableData(row);
                this.pushDirtForRow(rowID);
            }

            this.addedRows.delete(rowID);
        }

        for (const rowID of this.addedRows.keys()) {
            rowIDsNotSeen.delete(rowID);
            numRowsAdded++;
        }

        for (const rowID of rowIDsNotSeen) {
            const row = this.tableData.get(rowID);
            // We don't delete invisible rows, as they're not really part of
            // the app data.
            if (row?.$isVisible !== true) continue;

            this.tableData.delete(rowID);
            this.pushDirtForRow(rowID);
        }

        if (setAppDataIfLoading && this.dataIsLoading) {
            this.dataIsLoading = false;
            this.pushDirtForTable();
        }

        return numRowsAdded;
    }
}
