import type { BasePrimitiveValue } from "@glide/data-types";
import { type Description, type TableColumn, isRelationType } from "@glide/type-schema";
import {
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getSourceColumnProperty,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import {
    type AppDescriptionContext,
    type EnumPropertyDescriptorCase,
    type PropertyDescriptor,
    type PropertyDescriptorCase,
    type RewritingComponentConfiguratorContext,
    arePropertySourcesEqual,
    getColumnForSourceColumn,
    getPropertyDescription,
    isMultiCasePropertyDescriptor,
    updatesForProperty,
    findCaptionDescriptor,
} from "@glide/function-utils";

import { assert, defined } from "@glideapps/ts-necessities";
import { truthify } from "@glide/support";
import { definedMap, hasOwnProperty } from "collection-utils";
import entries from "lodash/entries";

import { getPropertyDescriptorCaseForDescription } from "../description-utils";
import { type DescriptionHandler, type PopulationMode, handlerForPropertyKind } from "./description-handlers";
import { getDefaultCaption } from "./descriptor-utils";
import { getFeatureSetting } from "@glide/common-core";

function reorderForDefaultCaption(pds: readonly PropertyDescriptor[]): readonly PropertyDescriptor[] {
    const captionDescriptor = findCaptionDescriptor(pds);
    if (captionDescriptor === undefined) {
        return pds;
    } else {
        const reordered = pds.filter(pd => pd !== captionDescriptor);
        reordered.push(captionDescriptor);
        return reordered;
    }
}

export function populateDescription<T extends Description, TRewrite extends boolean>(
    getPropertyDescriptors: (desc: T) => readonly PropertyDescriptor[] | undefined,
    populationMode: PopulationMode,
    rootDesc: Description,
    desc: T,
    tables: InputOutputTables | undefined,
    ccc: TRewrite extends true ? RewritingComponentConfiguratorContext : AppDescriptionContext,
    isRewrite: TRewrite,
    mutatingScreenKind: MutatingScreenKind | undefined,
    // FIXME: Put these into an object
    usedColumns?: ReadonlySet<TableColumn>,
    editedColumns?: ReadonlySet<TableColumn>,
    indirectColumns?: ReadonlySet<TableColumn>,
    ignoreRequired: boolean = false
): TRewrite extends true ? T : T | undefined {
    const isRootDesc = desc === rootDesc;

    function failure(): TRewrite extends true ? T : T | undefined {
        assert(!isRewrite);
        return undefined as TRewrite extends true ? T : T | undefined;
    }

    let pds = definedMap(getPropertyDescriptors(desc), reorderForDefaultCaption);
    if (pds === undefined) {
        if (!isRewrite) return failure();
        pds = [];
    }

    let propertyDescriptorQueue: PropertyDescriptor[] = Array.from(pds);

    function updatePropertyDescriptors(
        descUpdate: Partial<Description>,
        currentDescriptor: PropertyDescriptor,
        pdc: PropertyDescriptorCase
    ): boolean {
        let needUpdate = false;

        if (
            pdc.kind === PropertyKind.Enum &&
            truthify((pdc as EnumPropertyDescriptorCase<BasePrimitiveValue>).changesDescriptor)
        ) {
            needUpdate = true;
        } else {
            for (const [, pd] of entries(descUpdate)) {
                if (!hasOwnProperty(pd, "kind")) continue;

                if (pd.kind === PropertyKind.Column) {
                    const sourceColumn = getSourceColumnProperty(pd as PropertyDescription);
                    if (sourceColumn === undefined) continue;

                    // FIXME: This is ugly.  We shouldn't be guessing where
                    // the column is here.  We have the property descriptors
                    // that tell us.  Though to be fair, there's only a single
                    // case right now of a relation column being in the output
                    // table, which can happen for Choice when writing to a
                    // relation.
                    const column =
                        getColumnForSourceColumn(ccc, sourceColumn, tables?.input, undefined, []) ??
                        getColumnForSourceColumn(ccc, sourceColumn, tables?.output, undefined, []);
                    if (column === undefined) continue;

                    if (isRelationType(column.type)) {
                        needUpdate = true;
                        break;
                    }
                } else if (pd.kind === PropertyKind.Table) {
                    needUpdate = true;
                    break;
                }
            }
        }

        if (!needUpdate) return true;

        pds = definedMap(getPropertyDescriptors(desc), reorderForDefaultCaption);
        if (pds === undefined) {
            if (!isRewrite) return false;
            pds = [];
        }

        const index = pds.findIndex(pd => arePropertySourcesEqual(pd.property, currentDescriptor.property));
        assert(index >= 0);

        propertyDescriptorQueue = pds.slice(index + 1);

        return true;
    }

    function updateDesc(
        update: Partial<Description>,
        currentDescriptor: PropertyDescriptor,
        pdc: PropertyDescriptorCase
    ): boolean {
        desc = { ...desc, ...update };
        if (isRootDesc) {
            rootDesc = desc;
        }
        return updatePropertyDescriptors(update, currentDescriptor, pdc);
    }

    function getCaption(): string | undefined {
        return getDefaultCaption(defined(pds), desc, tables, ccc);
    }

    const avoidDirectColumns = new Set(definedMap(usedColumns, c => Array.from(c)));
    const avoidEditedColumns = new Set(definedMap(editedColumns, c => Array.from(c)));
    const avoidIndirectColumns = new Set(definedMap(indirectColumns, c => Array.from(c)));

    for (;;) {
        const descr = propertyDescriptorQueue.shift();
        if (descr === undefined) break;

        if (descr.when?.(desc, rootDesc, tables, ccc, undefined) === false) {
            continue;
        }

        if (isRewrite && descr.doNotRewrite === true) continue;

        let didRetry = false;
        for (;;) {
            const propertyDesc = getPropertyDescription(desc, descr);

            let pdc = definedMap(propertyDesc, d => getPropertyDescriptorCaseForDescription(d, descr));
            if (pdc === undefined) {
                if (isMultiCasePropertyDescriptor(descr)) {
                    pdc = descr.cases[0];
                    // If we use a `PropertyConfiguratorKind` then this is
                    // allowed to be empty.
                    if (pdc === undefined) break;
                } else {
                    pdc = descr;
                }
            }

            const handler = handlerForPropertyKind(pdc.kind);

            // These two are just to mollify eslint.
            const rootDescForDefault = rootDesc;
            const descForDefault = desc;
            function getDefaultUpdate() {
                function getDefaultUpdateForHandler(
                    innerHandler: DescriptionHandler<PropertyDescriptorCase>,
                    propertyDescriptorCase: PropertyDescriptorCase | undefined
                ) {
                    return innerHandler.defaultUpdateForPropertyCase(
                        defined(descr),
                        rootDescForDefault,
                        descForDefault,
                        defined(propertyDescriptorCase),
                        avoidDirectColumns,
                        avoidEditedColumns,
                        avoidIndirectColumns,
                        tables,
                        mutatingScreenKind,
                        ccc,
                        populationMode,
                        getCaption
                    );
                }

                if (
                    getFeatureSetting("loopMultiCaseDescriptionForPopulation") &&
                    descr !== undefined &&
                    isMultiCasePropertyDescriptor(descr)
                ) {
                    for (const caseDesc of descr.cases) {
                        const newHandler = handlerForPropertyKind(caseDesc.kind);
                        const update = getDefaultUpdateForHandler(newHandler, caseDesc);
                        if (update !== undefined) {
                            return update;
                        }
                    }

                    return undefined;
                }
                return getDefaultUpdateForHandler(handler, pdc);
            }

            if (propertyDesc === undefined || !isRewrite) {
                const update = getDefaultUpdate();

                if (update !== undefined) {
                    if (!updateDesc(update, descr, pdc)) {
                        if (isRewrite) break;
                        return failure();
                    }
                } else {
                    if (!ignoreRequired) {
                        if (isRewrite) break;
                        return failure();
                    }
                    if (!updateDesc(updatesForProperty<any>(descr.property, desc, undefined, undefined), descr, pdc)) {
                        return failure();
                    }
                }
            } else if (isRewrite) {
                let update = handler.rewriteWithCase(
                    descr,
                    pdc,
                    propertyDesc,
                    rootDesc,
                    desc,
                    tables,
                    mutatingScreenKind,
                    ignoreRequired,
                    ccc as RewritingComponentConfiguratorContext,
                    populationMode
                );
                if (update === undefined) {
                    update = getDefaultUpdate();
                    if (update === undefined) break;
                }

                if (!updateDesc(update, descr, pdc)) {
                    return failure();
                }

                if (!didRetry && getPropertyDescription(desc, descr) === undefined) {
                    didRetry = true;
                    continue;
                }
            } else {
                return failure();
            }
            break;
        }
    }

    return desc as TRewrite extends true ? T : T | undefined;
}
