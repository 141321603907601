import { getAllShortnameEndings, type AppKind } from "@glide/location-common";
import type { PluginTier } from "@glide/plugins";
import {
    type JSONObject,
    checkArray,
    checkNumber,
    checkString,
    isUndefinedish,
    logError,
    logInfo,
    replaceArrayItem,
    updateDefined,
    byteLength,
    type DocumentData as DocumentDataType,
    parseJSONSafely,
} from "@glide/support";
import {
    assert,
    exceptionToString,
    filterUndefined,
    mapFilterUndefined,
    panic,
    defined,
    definedMap,
    hasOwnProperty,
} from "@glideapps/ts-necessities";
import { isLeft, isRight } from "fp-ts/lib/Either";
import * as iots from "io-ts";
import toPairs from "lodash/toPairs";
import uniq from "lodash/uniq";
import * as pako from "pako";
import { v4 as uuid } from "uuid";
import type { AppPlanCode } from "../app-plans";
import { base64DecodeString, base64EncodeString } from "../base64";
import type { BillingPeriod, PlanKind } from "../billing-vnext/subscriptions";
import { removeComponentIDsFromAppDescription } from "../components/remove-component-id";
import {
    type AppDescription,
    type AppFeatures,
    type AppLoginData,
    type AppManifest,
    type EmailWithPin,
    type NonUserAppFeatures,
    type PluginConfig,
    type SerializedApp,
    type UserFeatures,
    appDescriptionCodec,
    defaultUserFeatures,
} from "@glide/app-description";
import {
    getAppFeatures,
    isolateAppDescription,
    isolatePersistentAppFeatures,
    isolateUserSettableAppFeatures,
} from "../components/SerializedApp";
import type { TableSnapshot } from "../components/types";
import {
    type TableName,
    rowIndexColumnName,
    type TableColumn,
    type TypeSchema,
    type SourceMetadata,
} from "@glide/type-schema";
import {
    ActionKind,
    allowedEmailsDocumentName,
    appAnalyticsCollectionName,
    appIntegrationsCollection,
    appUserIDFieldName,
    builderAppsCollectionName,
    documentIDForTableName,
    geocodingCollectionName,
    glideAppsCollectionName,
    glideAppsDataCollectionName,
    glideAppsMetadataCollectionName,
    makeActionDocumentPath,
    makeTablesPath,
    makeUserProfileDocumentPathForApp,
    newFeaturesCollectionName,
    newFeaturesSeenCollectionName,
    ownerAppUserIDsFieldName,
    rowVersionFieldName,
    templateSubmissionsCollectionName,
    uniqueUsersFieldName,
    usersCollectionName,
    zapierDocName,
    zapsCollectionForAppID,
} from "../database-strings";
import {
    type BaseRowIndex,
    type RowIndex,
    type UserSpecificRowIndex,
    baseRowIndexCodec,
    checkBaseRowIndex,
    isBaseRowIndex,
    rowIndexCodec,
    userSpecificRowIndexCodec,
} from "@glide/computation-model-types";
import {
    type ActionDocument,
    type ClientResellerWorkRole,
    type CompanyInformation,
    type DeleteColumnDocument,
    type DeleteRowDocument,
    type ScreenshotInfo,
    type SelfReportedReferralSource,
    type SetOrgAdminBody,
    type UserFlags,
    type WorkRole,
    type WriteSourceType,
    tableNameOrStringCodec,
} from "../firebase-function-types";
import type {
    AirtableIntegration,
    GCPGmailIntegration,
    GCPIntegration,
    GCPOAuthIntegration,
    GoogleDriveIntegration,
    Integration,
    MicrosoftIntegration,
    StripeIntegration,
    StripeTemplateStoreIntegration,
} from "../integration-types";
import type { PagePreviewDevice } from "../render/form-factor";
import type { OnboardingUseCases } from "../use-cases";
import type {
    Batch as BatchType,
    Database,
    DataReader as DataReaderType,
    DataReaderWriter as DataReaderWriterType,
    DataWriter as DataWriterType,
    DiffResults as DiffResultsType,
    DocumentDataWithID as DocumentDataWithIDType,
    DocumentDataWithIDAndPath as DocumentDataWithIDAndPathType,
    Query as QueryType,
    QueryableDataReader,
    QueryOpString as QueryOpStringType,
    QueryResults as QueryResultsType,
    SortDirection as SortDirectionType,
    Transaction as TransactionType,
} from "./core";
import type { PrivateOrPublicOnly } from "./eminence";
import { OwnerKind } from "./owner-kind";
import { type Pricing, pricingCodec } from "./pricing";
import type { StripeDate, StripeInvoicePaymentFailure } from "./stripe-info";
import type { TemplateSubmissionStatus } from "./template-submission-status";
import { quotasFromDocument } from "./quota-docs";
import { makeQuotaDocumentID, type QuotaContext, type QuotaDocument, quotasCollectionName } from "./quotas";
import { type ReverseFreeTrialStatuses, validateReverseFreeTrialStatusAgainstCodec } from "@glide/billing-types";
import type { ThemeOverlay } from "@glide/base-theme";

export type { ColumnValues } from "../firebase-function-types";
export { DatabaseBase } from "./core";
export type { Database } from "./core";

export type DocumentData = DocumentDataType;
export type QueryOpString = QueryOpStringType;
export type Query = QueryType;
export type DocumentDataWithID = DocumentDataWithIDType;
export type DocumentDataWithIDAndPath = DocumentDataWithIDAndPathType;
export type QueryResults = QueryResultsType;
export type SortDirection = SortDirectionType;
export type DataReader = DataReaderType;
export type DataWriter = DataWriterType;
export type DataReaderWriter = DataReaderWriterType;
export type Batch = BatchType;
export type Transaction = TransactionType;
export type DiffResults = DiffResultsType;

export const documentIDColumnName = "$documentID";

export type DataSourceOrgs = "google" | "microsoft" | "airtable";

function isGoogleDriveIntegration(i: Integration): i is GoogleDriveIntegration {
    return i.kind === "google-drive";
}

function isStripeIntegration(i: Integration): i is StripeIntegration {
    return i.kind === "stripe";
}

function isStripeTemplateStoreIntegration(i: Integration): i is StripeTemplateStoreIntegration {
    return i.kind === "stripe-template-store";
}

function isMicrosoftIntegration(i: Integration): i is MicrosoftIntegration {
    return i.kind === "microsoft";
}

function isAirtableIntegration(i: Integration): i is AirtableIntegration {
    return i.kind === "airtable";
}

function isGCPIntegration(i: Integration): i is GCPIntegration {
    return i.kind === "gcp-service-account";
}

function isGCPOAuthIntegration(i: Integration): i is GCPOAuthIntegration {
    return i.kind === "gcp-oauth";
}

export function isGCPGmailIntegration(i: Integration): i is GCPGmailIntegration {
    return i.kind === "gcp-gmail";
}

function sanitizeStripeIntegrationForUserSetting<T extends StripeIntegration | StripeTemplateStoreIntegration>(
    stripeIntegration: T
): T {
    const copy = { ...stripeIntegration };
    delete copy.waiveTransactionFees;
    return copy;
}

export interface Subscription {
    readonly id: string;
    readonly status: string;
    readonly planKind: PlanKind;
    readonly trialEnd: Date | undefined;
    readonly billingPeriod: BillingPeriod;
}

export function sanitizeIntegrationForUserSetting(integration: Integration): Integration {
    if (isStripeIntegration(integration) || isStripeTemplateStoreIntegration(integration)) {
        return sanitizeStripeIntegrationForUserSetting(integration);
    }
    return integration;
}

export function setOrUpdateIntegration(
    integrations: ReadonlyArray<Integration>,
    integration: Integration
): ReadonlyArray<Integration> {
    const index = integrations.findIndex(i => i.name === integration.name && i.kind === integration.kind);
    if (index >= 0) {
        return replaceArrayItem(integrations, index, integration);
    } else {
        return integrations.concat(integration);
    }
}

export function removeIntegration(
    integrations: ReadonlyArray<Integration>,
    integration: Integration
): ReadonlyArray<Integration> {
    const index = integrations.findIndex(i => i.name === integration.name && i.kind === integration.kind);
    if (index < 0) return integrations;
    return [...integrations.slice(0, index), ...integrations.slice(index + 1)];
}

export const defaultUserFlags: UserFlags = {
    hasSeenOrganizeTip: false,
    hasSeenNavigationTip: false,
    hasSeenHireAnExpertDialog: false,
    hasSeenHireAnExpertHelpMenuItem: false,
    hasSeenLearningCenter: false,
    onboardingComplete: false,
    tourComplete: false,
    filterWarningComplete: false,
    visibilityWarningComplete: false,
    unprotectedColumnWarningComplete: false,
    allowEmail: false,
    disabledNewComponentsForNewColumns: false,
    disabledLinkTablesModal: false,
    lastAcceptedTermsOfService: 0,
    builderTheme: "light",
    useSystemTheme: true,
    builderTrackingOptOut: false,
    hidePagesAdvert: false,
    hideGettingStartedPrompt: false,
    templateMigrationTip: "not-needed",
    activationChecklist: "complete",
    showActivationChecklist: "pending",
};

export enum StripeMessage {
    DowngradeDeleteFailed = "downgrade-delete-failed",
    DowngradeUpdateQuantityFailed = "downgrade-update-qauntity-failed",
    UpgradeCreateFailed = "upgrade-create-failed",
    UpgradeUpdateQuantityFailed = "upgrade-update-quantity-failed",
    CreateQueued = "create-queued",
    OK = "ok",
}

export interface AppPlanCodeEntry {
    readonly code: AppPlanCode;
    readonly stripeID: string;
    readonly appIDs: readonly string[];
    readonly userCount?: Readonly<Record<string, number>>;
    readonly stripeMessage: StripeMessage;
}

export interface OverageLineItem {
    readonly code: AppPlanCode;
    readonly stripeID: string;
    readonly appIDs: readonly string[];
    readonly stripeMessage: StripeMessage;
}

export enum SubscriptionKind {
    Monthly,
    Annual,
}

interface SubscriptionDiscount {
    readonly promoCode: string;
    readonly reduction: number | { amount: number; currency: string };
    // typeof applies === "number" implies a UTC Unix Epoch timestamp of
    // expiration.
    readonly applies: "once" | "forever" | number;
}

interface SubscriptionBase {
    readonly subscriptionID: string | undefined;
    readonly appPlanCodes?: readonly AppPlanCodeEntry[];
    readonly serial: number;
    // This subscription object doesn't include expiration,
    // payment validity, or any information about the payment method.
    // You'll have to query Stripe for all of that. However, we do
    // keep the state.
    readonly stripeState: "active" | "past_due" | "unpaid";
    readonly nextInvoice?: StripeDate;
    readonly nextTotal?: number;
    readonly currency?: string;
    readonly lastPaymentFailure?: StripeInvoicePaymentFailure;
    readonly appliedDiscount?: SubscriptionDiscount;
}

export interface AnnualSubscription extends SubscriptionBase {
    readonly kind: SubscriptionKind.Annual;
}
export interface MonthlySubscription extends SubscriptionBase {
    readonly kind: SubscriptionKind.Monthly;
    readonly overageItems?: readonly OverageLineItem[];
}

interface OwnerSuspensions {
    // If payment is suspended for an Owner, Buy Buttons do not function.
    readonly payment?: boolean;
}

interface StripeCustomerInformation {
    // This serial is unfortunately shared with expectedSubscriptionSerial
    readonly serial: number;
    readonly customerName?: string;
    readonly additionalInfo?: string;
}

export type AppTrialType = "private-pro";
export interface AppTrialInfo {
    readonly trialType: AppTrialType;
    readonly templateSourceID: string;
    // This should not be undefined but is possible when converting an undefined value from firestore
    readonly trialStarted: number | undefined;
}

interface OwnerBase {
    readonly id: string;
    readonly displayName?: string;
    readonly appIDs: readonly string[];
    readonly integrations: readonly Integration[];
    readonly stripeCustomerIDs: readonly string[];
    // When we finally have annual subscriptions, they
    // will share the expected serial. After all, why not?
    readonly expectedSubscriptionSerial?: number;
    readonly monthlySubscription?: MonthlySubscription;
    readonly annualSubscription?: AnnualSubscription;
    // This was added in retrospect to support EU VAT,
    // and is not generally set.
    readonly customerInformation: StripeCustomerInformation | undefined;
    readonly suspensions?: OwnerSuspensions;
    readonly features?: UserFeatures;

    readonly templateLicenses: readonly string[];
    // Promo codes generally can only be used by new customers.
    // Sometimes, we want them to apply retroactively, so we'll
    // establish a "timeout period" on user accounts for them
    // to do so.
    readonly promoCodeExtensionUntil?: number;

    // appTrials are keyed off appID
    // The AppTrialInfo | undefined here is for type safety because not all apps are trials
    readonly appTrials: Readonly<Record<string, AppTrialInfo | undefined>> | undefined;
    // Get this with Date.now()
    readonly lastEmailVerificationDate?: number;
    // Defaults to false. Tracks if there's a hubspot contact in the waiting area (not verified)
    readonly hasPendingHubspotContact?: boolean;

    // JavaScript compatible timestamp of when the user was created.
    // This would be a Date, but that'd cause serialization issues.
    readonly createdAt?: number;

    // Allows overriding the entitlements for the user when in the free tier.
    readonly freeEntitlementsPriceOverride?: string;
}

export interface ZapData {
    id: string;
    name: string;
}

export enum IntegrationKind {
    Webhook = "webhook",
}

export interface WebhookIntegration {
    readonly kind: IntegrationKind.Webhook;
    readonly name: string;
    readonly url: string;
    readonly password: string;
}

export interface WebhookIntegrationWithID extends WebhookIntegration {
    readonly id: string;
}

interface UserPlanCounts {
    readonly pro: number;
}

interface UserPlanAppIDs {
    readonly pro: readonly string[];
}

export interface TemplateSubmission {
    readonly author: string;
    readonly sourceAppID: string;
    readonly status: TemplateSubmissionStatus;
    readonly subtitle: string;
    readonly description: string;
    readonly usageVideoURL: string | undefined;
    readonly price: number;
    readonly updatedAt: Date;
    readonly screenshots: string[] | ScreenshotInfo[];
    readonly deniedReason?: string;
    readonly requiredTier?: PluginTier;
}

export interface TemplateSubmissions {
    readonly [id: string]: readonly TemplateSubmission[];
}

export interface UserData extends OwnerBase {
    readonly kind: OwnerKind.User;

    readonly authIDs: readonly string[]; // All Firebase user IDs (Google and/or email)
    readonly hubspotContactID?: string; // May not have a hubspot contact id if we still never created this contact
    readonly emails: readonly string[];
    readonly phoneNumber?: string;
    readonly photoURL?: string;

    readonly flags?: UserFlags;
    readonly orgUserIDs: readonly string[];
    readonly planCounts: UserPlanCounts;
    readonly planAppIDs: UserPlanAppIDs;
    readonly referrals: number;
    readonly isGlideExpert?: boolean;
    readonly allowMyApps?: boolean;
    readonly devicePreferences?: Record<string, PagePreviewDevice>;

    // Contains the PartnerStack referral key belonging to the expert.
    // Must be a `isGlideExpert: true` user:
    readonly partnerStackReferralPartnerKey?: string;

    // onboarding
    readonly reasonForGlide?: string;
    readonly selfReportedReferralSource?: SelfReportedReferralSource;
    readonly selfReportedReferralSourceOtherExplanation?: string;
    readonly workRole?: WorkRole;
    readonly otherWorkRole?: string;

    // this is no longer being populated. Check if we delete as the ones on the bottom.
    // https://github.com/glideapps/glide/issues/22553
    readonly useCase?: OnboardingUseCases;
    readonly otherUseCase?: string;

    // to remain empty from Q4 2023 onwards:
    // TODO: related to https://github.com/glideapps/glide/pull/26787 and we should delete if possible.
    //  Search for this PR #26787 to get related lines.
    readonly reasonForGlideOtherExplanation?: string;
    // `clientResellerWorkRole` and `otherClientResellerWorkRole` support the new "for my clients" users,
    // agency expert etc. https://github.com/glideapps/glide/issues/22553
    // Not supported anymore in the embedded onboarding.
    readonly clientResellerWorkRole?: ClientResellerWorkRole;
    readonly otherClientResellerWorkRole?: string;
}

export type EmailNotificationScope = "usage" | "errors" | "access_requests";
export type MemberNotificationSettings = Record<EmailNotificationScope, boolean>;
export type OrgMembersNotificationSettings = Record<string, MemberNotificationSettings>;
// enforeces defaults in case of missing settings
export function getOrgMemberNotificationSettings(
    savedSettings: MemberNotificationSettings | undefined,
    role: Exclude<SetOrgAdminBody["role"], undefined>
): MemberNotificationSettings {
    return {
        usage: savedSettings?.usage ?? role === "admin",
        errors: savedSettings?.errors ?? role === "admin",
        access_requests: savedSettings?.access_requests ?? role === "admin",
    };
}

export type UserDataWithoutID = Omit<UserData, "id">;

export function getUserSingleAuthID(user: UserDataWithoutID): string {
    return defined(user.authIDs[0]);
}

export function getUserSingleEmail(user: UserDataWithoutID): string | undefined {
    return user.emails[0];
}

interface OrganizationPlanCounts {
    readonly organizationPublic: number;
}

interface OrganizationPlanAppIDs {
    readonly organizationPublic: readonly string[];
}

export interface OrganizationFolder {
    readonly id: string;
    readonly name: string;
    readonly appIDs: string[];
    readonly position: string;
}

export interface Organization extends OwnerBase {
    readonly kind: OwnerKind.Organization;

    readonly companyInformation: CompanyInformation;
    readonly displayName: string;
    readonly memberUserIDs: readonly string[];
    readonly adminUserIDs: readonly string[];
    readonly planCounts: OrganizationPlanCounts;
    readonly planAppIDs: OrganizationPlanAppIDs;
    readonly hasSubscription: boolean;
    /**
     * @deprecated This field is no longer used. Agency organizations are now identified through an `isAgency`
     * eminence flag.
     */
    readonly isAgency: boolean;
    readonly referrals?: number;

    // Contains the PartnerStack referral key of the expert who referred this organization:
    readonly partnerStackReferrerPartnerKey: string | undefined;

    readonly reverseFreeTrialStatus?: ReverseFreeTrialStatuses;

    readonly folders?: OrganizationFolder[];
    readonly logoURL?: string;
    readonly defaultAppPrimaryColor?: string;
    readonly isAgencyClientOf?: string;

    // Whether they have opted into AI features and training
    readonly isAIEnabled?: boolean;
}

export type Owner = UserData | Organization;

export function isOrganizationAdmin(org: Organization, userID: string): boolean {
    return org.adminUserIDs.includes(userID);
}

export function makeAppFeatures(
    userData: Owner | null | "loading" | undefined,
    nonUserAppFeatures: NonUserAppFeatures,
    appKindOverride: AppKind | undefined
): AppFeatures {
    if (userData === undefined) {
        userData = null;
    }

    let features: AppFeatures = {
        ...getUserFeatures(userData),
        ...isolateUserSettableAppFeatures(nonUserAppFeatures),
        ...isolatePersistentAppFeatures(nonUserAppFeatures),
    };

    if (appKindOverride !== undefined) {
        features = { ...features, appKind: appKindOverride };
    }
    return features;
}

export type UserDataAndState = UserData | null | "loading";

function isNullOrLoading<T>(x: T | null | "loading"): x is null | "loading" {
    return x === null || x === "loading";
}

export function isUserData(v: UserDataAndState): v is UserData {
    return !isNullOrLoading(v);
}

export function ownerIsOrganization(owner: Owner): owner is Organization {
    return owner.kind === OwnerKind.Organization;
}

export function getUserFlags(user: UserDataAndState): UserFlags {
    if (!isUserData(user)) return defaultUserFlags;
    const { flags } = user;
    if (flags === undefined) return defaultUserFlags;
    return { ...defaultUserFlags, ...flags };
}

export function getUserFeatures(userData: Owner | null | undefined | "loading"): UserFeatures {
    if (userData === null || userData === "loading" || userData?.features === undefined) {
        return defaultUserFeatures;
    }
    return updateDefined(defaultUserFeatures, userData.features);
}

export function getGoogleDriveOAuth2OfflineIntegration(user: UserData): GoogleDriveIntegration | undefined {
    return user.integrations.find(
        (i): i is GoogleDriveIntegration => isGoogleDriveIntegration(i) && i.authentication.kind === "oauth2-offline"
    );
}

export function getStripeIntegration(user: Owner): StripeIntegration | undefined {
    return user.integrations.find(isStripeIntegration);
}

export function getStripeTemplateStoreIntegration(user: Owner): StripeTemplateStoreIntegration | undefined {
    return user.integrations.find(isStripeTemplateStoreIntegration);
}

export function getMicrosoftIntegration(user: Owner, microsoftAccountID: string): MicrosoftIntegration | undefined {
    return user.integrations.filter(i => i.name === microsoftAccountID).find(isMicrosoftIntegration);
}

export function getMicrosoftIntegrations(user: Owner): MicrosoftIntegration[] {
    return user.integrations.filter(isMicrosoftIntegration);
}

export function getAirtableIntegration(user: Owner): AirtableIntegration | undefined {
    return user.integrations.find(isAirtableIntegration);
}

export function getGCPIntegrations(user: Owner): GCPIntegration[] {
    return user.integrations.filter(isGCPIntegration);
}

export function getGCPIntegration(user: Owner, serviceAccountEmail: string): GCPIntegration | undefined {
    return user.integrations.filter(i => i.name === serviceAccountEmail).find(isGCPIntegration);
}

export function getGCPOauthIntegrations(user: Owner): GCPOAuthIntegration[] {
    return user.integrations.filter(isGCPOAuthIntegration);
}

export function getGCPOAuthIntegration(user: Owner, integrationName: string): GCPOAuthIntegration | undefined {
    return user.integrations.filter(i => i.name === integrationName).find(isGCPOAuthIntegration);
}

export function getGCPGmailIntegrations(user: Owner): GCPGmailIntegration[] {
    return user.integrations.filter(isGCPGmailIntegration);
}

export function getAppTrialInfo(owner: Owner, appID: string): AppTrialInfo | undefined {
    if (owner.appTrials === undefined) return undefined;
    const trialInfo = owner.appTrials[appID];
    return trialInfo;
}

export const fourteenDayMax = 14;

/**
 * @param a first date (in Date.now() format)
 * @param b second date (in Date.now() format)
 * @returns amount of days between those
 */
function differenceInDays(a: number, b: number): number {
    return Math.floor(Math.abs(a - b) / (24 * 60 * 60 * 1000));
}

// NOTE: Returns undefined if the trial is not found or trialStarted does not exist on a trial
// Returns the number of days that have elapse between starting a trial and now:
// Returns undefined if no trial exists for that app
export function daysSinceTrialStart(owner: Owner | undefined, appID: string, now: number): number | undefined {
    if (owner === undefined) return undefined;
    const proTrial = getAppTrialInfo(owner, appID);
    if (proTrial === undefined || proTrial.trialStarted === undefined) return undefined;
    // NOTE: Now is likely to be a later date than trial started but since
    // new Date() passed from the frontend, the server timestamp on the owner doc
    // could possibly be in the future so we need to handle that case as well
    const daysFromTrialStartToNow = differenceInDays(now, proTrial.trialStarted);
    return daysFromTrialStartToNow;
}

// Returns undefined no trial exists or there is some issue with the trial start timestamp
// Returns number clamped number from 0 to max number of days in a trial
export function daysLeftInTrial(daysSinceTrialStarted: number | undefined, maxDaysInTrial: number): number | undefined {
    if (daysSinceTrialStarted === undefined) return undefined;

    return maxDaysInTrial - daysSinceTrialStarted >= 0 ? maxDaysInTrial - daysSinceTrialStarted : 0;
}

interface StripePaymentInformation {
    readonly processor: "stripe";
    readonly stripeUserID: string;
    // Note that we don't actually save these in PublishedApp,
    // but instead only use them in the builder.
    readonly livePublishableKey?: string;
    readonly testPublishableKey?: string;
}

export type PaymentInformation = StripePaymentInformation;

export interface PaymentInformationForBuyButtons {
    readonly [buyButtonID: string]: PaymentInformation;
}

export interface PublishedAppBase {
    readonly publishedAt: Date | undefined;
    readonly shortName: string | undefined;
    readonly customDomain: string | undefined;
}

export interface PublishedAppSnapshot extends PublishedAppBase {
    readonly app: SerializedApp;
    readonly paymentInformation: PaymentInformationForBuyButtons | undefined;
    // This is LEGACY. Do not count on it because it should no longer be
    // relevant.
    readonly eminence?: string;
}

// This is additional stuff we need in the `play` function.
export interface PlayData {
    // We try to make this compatible with ##appLoginData
    readonly manifest: AppManifest | undefined;
    readonly features: AppFeatures | undefined;

    readonly themeOverlay: ThemeOverlay | undefined;
    readonly tabIcons: readonly string[] | undefined;
    readonly allowEmbed?: boolean;
}

export interface PublishedApp extends PublishedAppBase, PlayData {
    readonly id: string;
    readonly builderAppTimestamp: Date | undefined;
    readonly desiredDomain: string | undefined;
    readonly blocked?: boolean;

    // We need this to sign the native table snapshots
    readonly sourceMetadataArray: readonly SourceMetadata[] | undefined;

    readonly allowDebugDataViewerUntil?: Date;
    readonly pluginConfigs?: readonly PluginConfig[];
    readonly pluginActionIDs?: readonly string[];
}

export function publishedAppString(publishedApp: PublishedAppSnapshot): string {
    return JSON.stringify({ ...publishedApp, publishedAt: publishedApp.publishedAt?.getTime() });
}

export function zipPublishedAppString(publishedApp: PublishedAppSnapshot): string {
    return zipString(publishedAppString(publishedApp));
}

// Note that this doesn't actually perform type checking.
export function unzipPublishedAppString(serialized: string): PublishedAppSnapshot | undefined {
    try {
        const rehydrated = JSON.parse(unzipString(serialized));
        return publishedAppSnapshotForJSON(rehydrated);
    } catch (e: unknown) {
        logError(`Could not unzip published app string: ${e}`);
        return undefined;
    }
}

type CompatibilityIssues = ReadonlyArray<string>;

export interface MissingTablesAndColumns {
    readonly tables: readonly string[];
    readonly columns: readonly { readonly table: string; readonly columns: readonly string[] }[];
}

export interface CompatibilityProblems {
    readonly compatibilityIssues?: CompatibilityIssues;
    readonly compatibilityMissing?: MissingTablesAndColumns;
}

export function isolateCompatibilityProblems(p: CompatibilityProblems): CompatibilityProblems | undefined {
    const { compatibilityIssues, compatibilityMissing } = p;
    if (compatibilityIssues === undefined && compatibilityMissing === undefined) return undefined;
    return { compatibilityIssues, compatibilityMissing };
}

export interface TableSize {
    readonly name: TableName;
    readonly numRows: number;
}

export interface DataSize {
    readonly totalRows: number;
    readonly tables: ReadonlyArray<TableSize>;
}

export interface SchemaInPublishedAppData {
    // FIXME: Remove `undefined` once we have schemas for all published app datas
    readonly schema: TypeSchema | undefined;
    readonly lastSchemaUpdateAt: Date | undefined;
    readonly lastSchemaUpdateReason: string | undefined;
}

export interface PublishedAppData extends CompatibilityProblems, SchemaInPublishedAppData {
    readonly dataTimestamp: Date | undefined;
    readonly userID: string | undefined;
    readonly password: string | undefined;
    readonly spreadsheetName: string | undefined;
    readonly dataSize: DataSize | undefined;
    // FIXME: Remove this once we use new-style quotas everywhere
    readonly numRowsUsedInApp: number | undefined;
    readonly version: number;
    readonly tablesUnusedInApp: readonly string[];
    // This is used in the Firestore security rules to know whether the app is
    // public, which then implies that anybody can read the tables used in the
    // app, if it's published.
    readonly isPublic: boolean;
}

export interface AppMetadata {
    // Only written
    readonly title?: string;
    // Only written
    readonly createdAtOrBefore?: Date;
    // Used to decide whether we need automatic refresh
    readonly lastRefreshed?: Date;
    readonly refreshStartedAt?: Date;
    // Note that this field doesn't matter at all if refreshStartedAt
    // is undefined. We don't even care in that case.
    readonly refreshRequiresFollowUp?: boolean;
    // This is set for new-style Pro upgrades. We use this to
    // automatically cancel payments when downgrades happen.
    readonly proStripeSubscriptionID?: string;
    readonly automaticRefreshMinutes?: number;
    // Used for tracking provenence of apps
    readonly copiedFromAppID?: string;
    readonly originTemplateAppID?: string;
    // FIXME: We shouldn't use ##lastPublishedAt anymore, and instead use
    // `publishedAt` in the published app document.
    readonly lastPublishedAt?: Date;
    // Defualt `false`. This gets replicated into the published app at publish time
    // so that `play` has quick access to it.
    readonly blocked?: boolean;
}

export enum AuthenticationMethod {
    // `None` is legacy.  We're not "producing" it anymore.  It's now
    // `PublicEmailPin` with `optional` set.
    None = "none", // signing in is not required, but allowed
    Disabled = "disabled", // signing in is not allowed
    Password = "password",
    PublicEmailPin = "public-email-pin",
    WhitelistEmailPin = "whitelist-email-pin",
    UserProfileEmailPin = "user-profile-email-pin",
    OrgMembers = "org-members",
    JustMe = "just-me",
    AllowedEmailDomains = "allowed-email-domains",
}

export function isAuthenticationMethodPublic(method: AuthenticationMethod): boolean {
    return (
        method === AuthenticationMethod.None ||
        method === AuthenticationMethod.Disabled ||
        method === AuthenticationMethod.PublicEmailPin
    );
}

export const appAnalyticsCodec = iots.type({
    // This last30DayUniqueDevices count is "on probation" for being deprecated.
    // It was derived from Google Analytics in the player, and we don't use
    // Google Analytics in the player. The only reason it's not being outright
    // removed is that it is being used as a filter for reload issue notification.
    last30DayUniqueDevices: iots.number,
    lastUniqueAppUsersInWindow: iots.number,
});

export type AppAnalytics = Readonly<iots.TypeOf<typeof appAnalyticsCodec>>;

/**
 * Compresses a payload with gzip
 *
 * Browsers unfortunately have the ability to corrupt the encoding
 * of the output; you should use {@link zipStringBase64} instead
 * to mitigate this on the frontend. This is always safe to do on
 * the backend; we don't expect any string encoding bugs there.
 *
 * Use {@link unzipString} to decompress the output of this function.
 *
 * @param s Payload to compress
 * @returns The compressed payload, as a string
 */
export function zipString(s: string): string {
    return pako.gzip(s, { to: "string" });
}

/**
 * Compresses a payload with gzip, base64 encoding the results.
 *
 * This is particularly useful on the frontend, where environmental
 * misconfiguration can result in irrecoverable string encoding
 * corruption.
 *
 * Use {@link unzipStringBase64} to decompress the output of this function.
 *
 * @param s Payload to compress
 * @returns The compressed payload, encoded in Base64
 */
function zipStringBase64(s: string): string {
    return base64EncodeString(zipString(s));
}

/**
 * Decompresses the output of {@link zipString}
 *
 * @param s The compressed payload, as a bare binary string
 * @returns The decompressed payload corresponding to the input
 */
export function unzipString(s: string): string {
    try {
        return pako.ungzip(s, { to: "string" });
    } catch (e: unknown) {
        // logInfo(
        //     "Error unzipping - this is probably not a problem, just a string that's not zipped in the first place:",
        //     e
        // );
        return s;
    }
}

/**
 * Decompresses the output of {@link zipStringBase64}
 *
 * @param s The compressed payload, as a base64-encoded string
 * @returns The decompressed payload corresponding to the input
 */
function unzipStringBase64(s: string): string | undefined {
    const decoded = base64DecodeString(s);
    if (decoded === undefined) return undefined;
    try {
        return pako.ungzip(decoded, { to: "string" });
    } catch (e: unknown) {
        logInfo("Error unzipping:", exceptionToString(e));
        return decoded;
    }
}

function isAppDomainName(appNameOrID: string): boolean {
    return appNameOrID.includes(".");
}

function isShortName(url: string) {
    return getAllShortnameEndings().some(sn => url.endsWith(sn));
}

export function appNameSearchField(appNameOrID: string): string | undefined {
    if (isAppDomainName(appNameOrID)) {
        return isShortName(appNameOrID) ? "shortName" : "customDomain";
    }
    return undefined;
}

export function isSizeLimitException(e: unknown): boolean {
    return hasOwnProperty(e, "code") && e.code === "invalid-argument" && e.toString().includes("is longer than");
}

export function getPlayDataFromSerializedApp(app: SerializedApp): PlayData {
    return {
        manifest: app.manifest,
        features: getAppFeatures(app),
        themeOverlay: app.theme.themeOverlay ?? "none",
        tabIcons: mapFilterUndefined(app.tabs ?? [], t => (t.hidden ? undefined : t.icon)),
        allowEmbed: app.allowEmbed,
    };
}

export function publishedAppForDocument(db: DataReader, data: DocumentData, id: string): PublishedApp {
    return {
        id,
        publishedAt: definedMap(data.publishedAt, d => db.database.dateFromTimestamp(d)),
        builderAppTimestamp: definedMap(data.builderAppTimestamp, d => db.database.dateFromTimestamp(d)),
        shortName: data.shortName,
        customDomain: data.customDomain,
        desiredDomain: data.desiredDomain,
        blocked: data.blocked,
        manifest: data.manifest,
        features: data.features,
        themeOverlay: data.themeOverlay,
        tabIcons: data.tabIcons,
        sourceMetadataArray: data.sourceMetadataArray,
        allowEmbed: data.allowEmbed,
        allowDebugDataViewerUntil: definedMap(data.allowDebugDataViewerUntil, d => db.database.dateFromTimestamp(d)),
        pluginConfigs: data.pluginConfigs,
    };
}

export function publishedAppSnapshotForJSON(json: JSONObject): PublishedAppSnapshot {
    return {
        ...json,
        publishedAt: definedMap(json.publishedAt, d => new Date(d as number)) ?? new Date(),
    } as PublishedAppSnapshot;
}

export async function loadPublishedAppFromAppID(db: DataReader, appID: string): Promise<PublishedApp | undefined> {
    const data = await db.getDocument(glideAppsCollectionName, appID);
    if (data === undefined) return undefined;
    return publishedAppForDocument(db.database, data, appID);
}

// This is an emergency measure to deal with the poisoning of glide.page and helping with the migration to glide.app
// This allows glide.app to service anything that was on glide.page.
function coerceMirrorDomain(domain: string): string {
    if (domain.endsWith(".staging.glide.app")) {
        return domain.replace(".staging.glide.app", ".staging.glide.page");
    } else if (domain.endsWith(".glide.app")) {
        return domain.replace(".glide.app", ".glide.page");
    }
    return domain;
}

export async function loadPublishedApp(db: QueryableDataReader, nameOrID: string): Promise<PublishedApp | undefined> {
    nameOrID = coerceMirrorDomain(nameOrID);
    const field = appNameSearchField(nameOrID);
    if (field === undefined) {
        return await loadPublishedAppFromAppID(db, nameOrID);
    }

    // we have a custom domain or short name
    const result = await db.getDocumentsWhere(glideAppsCollectionName, [
        {
            fieldPath: field,
            opString: "==",
            value: nameOrID,
        },
    ]);

    if (result.length < 1) return undefined;

    const data = result[0].data;
    const resultId = result[0].id;

    return publishedAppForDocument(db, data, resultId);
}

function decodeSchema(schema: unknown): TypeSchema {
    if (typeof schema === "string") {
        return JSON.parse(unzipString(schema));
    } else {
        return schema as TypeSchema;
    }
}

export function checkTableColumn(c: TableColumn): void {
    assert(c.type !== undefined);
    assert(c.name !== undefined);
}

export function publishedAppDataForDocument(db: Database, data: DocumentData): PublishedAppData {
    const { dataTimestamp, dataSize, schema } = data;
    return {
        dataTimestamp: definedMap(dataTimestamp, db.dateFromTimestamp),
        password: data.password,
        spreadsheetName: data.spreadsheetName,
        userID: data.userID,
        schema: decodeSchema(schema),
        lastSchemaUpdateAt: definedMap(data.lastSchemaUpdateAt, db.dateFromTimestamp),
        lastSchemaUpdateReason: data.lastSchemaUpdateReason,
        compatibilityIssues: data.compatibilityIssues,
        compatibilityMissing: data.compatibilityMissing,
        dataSize: dataSize !== undefined && Array.isArray(dataSize.tables) ? dataSize : undefined,
        numRowsUsedInApp: data.numRowsUsedInApp,
        version: data.version ?? 0,
        tablesUnusedInApp:
            data.tablesUnusedInApp !== undefined && !Array.isArray(data.tablesUnusedInApp)
                ? checkArray(Array.from(Object.values(data.tablesUnusedInApp)), checkString)
                : data.tablesUnusedInApp ?? [],
        isPublic: data.isPublic ?? false,
    };
}

export function publishedAppMetadataFromDocument(db: DataReaderWriter, data: DocumentData): AppMetadata {
    return db.database.convertFromDocument(data);
}

export async function loadAppMetadata(db: DataReaderWriter, id: string): Promise<AppMetadata | undefined> {
    const data = await db.getDocument(glideAppsMetadataCollectionName, id);
    return definedMap(data, d => publishedAppMetadataFromDocument(db, d));
}

export async function loadPublishedAppData(db: DataReader, id: string): Promise<PublishedAppData | undefined> {
    let data: DocumentData | undefined;
    try {
        data = await db.getDocument(glideAppsDataCollectionName, id);
        if (data === undefined) return undefined;
    } catch (e: unknown) {
        // This is what we get when the app data doesn't exist.
        if (hasOwnProperty(e, "code") && e.code === "permission-denied") return undefined;
        throw e;
    }
    return publishedAppDataForDocument(db.database, data);
}

function withRetry(run: (onError: (e: Error) => void) => () => void): () => void {
    let unsubscribe: (() => void) | undefined;
    let timeout: ReturnType<typeof setInterval> | undefined;
    let backOffTime = 10;
    const maxTimeout = 10000;

    function listen(): void {
        timeout = undefined;
        unsubscribe = run(_e => {
            if (unsubscribe !== undefined) {
                unsubscribe();
                unsubscribe = undefined;
            }

            // Back off by a factor of 10 (10ms, 100ms, 1s, 10s, 10s, 10s ...);
            timeout = setTimeout(listen, backOffTime);
            if (backOffTime < maxTimeout) {
                backOffTime *= 10;
            }
        });
    }

    listen();

    return () => {
        if (timeout !== undefined) {
            clearTimeout(timeout);
        }
        if (unsubscribe !== undefined) {
            unsubscribe();
        }
    };
}

export function listenWithRetry(
    db: Database,
    collectionName: string,
    documentID: string,
    onUpdate: (doc: DocumentData) => void
): () => void {
    return withRetry(onError =>
        db.listenToDocument(
            collectionName,
            documentID,
            d => {
                if (d === undefined) return;
                onUpdate(d);
            },
            e => {
                const logFunc = hasOwnProperty(e, "code") && e.code === "permission-denied" ? logInfo : logError;
                logFunc("Error listening to document", collectionName, documentID, e);
                onError(e);
            }
        )
    );
}

export function listenWhereWithRetry(
    db: Database,
    collectionName: string,
    queries: readonly Query[],
    limit: number | undefined,
    onUpdate: (docs: readonly DocumentDataWithID[]) => Promise<void>
): () => void {
    return withRetry(onError =>
        db.listenWhere(collectionName, queries, undefined, undefined, limit, onUpdate, e => {
            logError("Error querying collection", collectionName, queries, e);
            onError(e);
        })
    );
}

export function listenToPublishedAppData(
    db: Database,
    id: string,
    onUpdate: (appData: PublishedAppData) => void
): () => void {
    return listenWithRetry(db, glideAppsDataCollectionName, id, data =>
        onUpdate(publishedAppDataForDocument(db, data))
    );
}

export function listenToPublishedAppMetadata(
    db: Database,
    id: string,
    onUpdate: (appData: AppMetadata) => void
): () => void {
    return listenWithRetry(db, glideAppsMetadataCollectionName, id, data =>
        onUpdate(publishedAppMetadataFromDocument(db, data))
    );
}

export interface AppLoginAuthenticationMethod {
    readonly authenticationMethod: AuthenticationMethod;
    readonly authenticationOptional: boolean; // valid only for auth methods that can be optional
}

export interface AppLoginAuthData extends AppLoginData, AppLoginAuthenticationMethod {
    readonly blacklistAppUserIDs: readonly string[];
    readonly passwordSerial?: number;
}

export interface AppLoginAuthDataFromPlay extends AppLoginAuthData {
    readonly appID: string;
    readonly timestamp: number;
}

export function appLoginFromDocumentData(data: DocumentData): AppLoginAuthData {
    return {
        ...data,
        authenticationMethod: data.authenticationMethod ?? AuthenticationMethod.None,
        authenticationOptional: data.authenticationOptional ?? false,
        blacklistAppUserIDs: data.blacklistAppUserIDs ?? [],
    } as unknown as AppLoginAuthData;
}

const builderAppDocument = iots.intersection([
    iots.union([
        iots.type({ appDescriptionString: iots.string }),
        iots.type({ appDescriptionKey: iots.string }),
        iots.type({ appDescBase64: iots.string }),
    ]),
    iots.partial({
        templatePurchaseInformation: iots.type({
            sourceAppID: iots.string,
            sourceOwnerID: iots.string,
            pricing: pricingCodec,
        }),
        templateAuthorInformation: iots.type({
            name: iots.string,
            email: iots.string,
        }),
        // FIXME: type properly
        timestamp: iots.any,
        serial: iots.number,
        isPublishingActive: iots.boolean,
        restoreToBackupID: iots.string,
    }),
]);

const appDescriptionDocument = appDescriptionCodec;

export type TemplatePurchaseInformation = Readonly<{
    readonly sourceAppID: string;
    readonly sourceOwnerID: string;
    readonly pricing: Pricing;
}>;

export interface TemplateAuthor {
    readonly name: string;
    readonly email: string;
}

export interface BuilderApp {
    readonly appDescription: AppDescription;
    // Note that these are only ever valid for templates.
    // We don't give template authors the ability to directly write
    // the template documents, so there is no risk of this being maliciously
    // overwritten by the author.
    readonly templatePurchaseInformation?: TemplatePurchaseInformation;
    readonly templateAuthorInformation?: TemplateAuthor;
    readonly timestamp: Date | undefined;
    readonly serial: number | undefined;
    // This is default `true`
    readonly isPublishingActive: boolean | undefined;
    readonly restoreToBackupID: string | undefined;
}

export interface AppDescriptionStore {
    fetch(appID: string, appDescriptionKey: string): Promise<string | undefined>;
    store(appID: string, appDescription: string, serial: number): Promise<string | undefined>;
}

export async function makeBuilderAppFromDocument(
    appID: string,
    db: Database,
    document: DocumentData,
    descriptionStore: AppDescriptionStore
): Promise<BuilderApp | undefined> {
    const decodedBuilderApp = builderAppDocument.decode(document);
    if (isLeft(decodedBuilderApp)) {
        logError("Error validating builder app document", appID);
        return undefined;
    }

    const builderApp = decodedBuilderApp.right;
    const {
        templatePurchaseInformation,
        templateAuthorInformation,
        timestamp,
        serial,
        isPublishingActive,
        restoreToBackupID,
    } = builderApp;
    let unzippedString: string;

    if (hasOwnProperty(builderApp, "appDescriptionString")) {
        unzippedString = unzipString(builderApp.appDescriptionString);
    } else if (hasOwnProperty(builderApp, "appDescriptionKey")) {
        const { appDescriptionKey } = builderApp;
        const fetched = await descriptionStore.fetch(appID, appDescriptionKey);
        if (fetched === undefined) {
            logError("Failed to fetch app description", appID, appDescriptionKey);
            return undefined;
        }
        unzippedString = fetched;
    } else {
        const maybeUnzipped = unzipStringBase64(builderApp.appDescBase64);
        if (maybeUnzipped === undefined) {
            logError("Error unzipping app description", appID);
            return undefined;
        }
        unzippedString = maybeUnzipped;
    }

    const parsed = parseJSONSafely(checkString(unzippedString));
    const decodedAppDescription = appDescriptionDocument.decode(parsed);

    if (isLeft(decodedAppDescription)) {
        logError("Error validating app description", appID);
        return undefined;
    }

    const appDescription = decodedAppDescription.right;

    delete (appDescription as any).input;

    return {
        appDescription,
        templatePurchaseInformation,
        templateAuthorInformation,
        timestamp: definedMap(timestamp, ts => db.dateFromTimestamp(ts)),
        serial,
        isPublishingActive,
        restoreToBackupID,
    };
}

const sessionID = uuid();

export async function builderAppDocumentFromParts(
    db: Database,
    appDescription: AppDescription,
    useBase64: boolean,
    userID: string | undefined,
    appId: string,
    descriptionStore: AppDescriptionStore,
    prevSerial: number,
    forUpdate: boolean = true
): Promise<DocumentData> {
    const descJSON = JSON.stringify(removeComponentIDsFromAppDescription(isolateAppDescription(appDescription)));
    const serial = prevSerial + 1;
    const data: DocumentData = {
        timestamp: db.serverTimestampFieldValue,
        serial,
        sessionID,
    };
    // TODO(jpg): Consider defining constants for these and a DocumentData.size() method to handle this for any document
    // https://firebase.google.com/docs/firestore/storage-size
    // 32 byte per-document overhead
    // 8 bytes date & time field
    // 8 bytes integer
    // UTF-8 length of string + 1
    let docSize = 32 + 8 + 8 + byteLength(sessionID) + 1;

    if (userID !== undefined) {
        data.savingUserID = userID;
        docSize += byteLength(userID) + 1;
    }
    if (useBase64) {
        data.appDescBase64 = zipStringBase64(descJSON);
        docSize += byteLength(data.appDescBase64) + 1;
        if (forUpdate) {
            data.appDescriptionString = db.deleteFieldValue;
            data.appDescriptionKey = db.deleteFieldValue;
        }
    } else {
        data.appDescriptionString = zipString(descJSON);
        docSize += byteLength(data.appDescriptionString) + 1;
        if (forUpdate) {
            data.appDescBase64 = db.deleteFieldValue;
            data.appDescriptionKey = db.deleteFieldValue;
        }
    }

    // Handle very-large app descriptions specially
    const appDescriptionStoreConfig = db.deploymentLocationSettings.appDescriptionStoreConfig;
    const appDescriptionMaxBytes = appDescriptionStoreConfig.maxBytes - appDescriptionStoreConfig.headroomBytes;
    if (docSize > appDescriptionMaxBytes) {
        const appDescriptionKey = await descriptionStore.store(appId, descJSON, serial);
        if (appDescriptionKey === undefined) {
            throw new Error(`Error storing app description for app: ${appId}`);
        }
        data.appDescriptionKey = appDescriptionKey;
        if (forUpdate) {
            data.appDescBase64 = db.deleteFieldValue;
            data.appDescriptionString = db.deleteFieldValue;
        } else {
            delete data.appDescBase64;
            delete data.appDescriptionString;
        }
    }

    return data;
}

export async function loadBuilderApp(
    rw: DataReader,
    id: string,
    descriptionStore: AppDescriptionStore
): Promise<BuilderApp | undefined> {
    let document: DocumentData | undefined;
    try {
        document = await rw.getDocument(builderAppsCollectionName, id);
    } catch (e: unknown) {
        logError("Could not load builder app:", exceptionToString(e));
    }

    if (document === undefined) return undefined;
    try {
        return makeBuilderAppFromDocument(id, rw.database, document, descriptionStore);
    } catch (e: unknown) {
        logError("Could not make builder app from document:", exceptionToString(e));
        return undefined;
    }
}

export interface AppWithSchema {
    readonly builderAppDocument: DocumentData;
    readonly builderApp: BuilderApp;
    readonly publishedAppData: PublishedAppData | undefined;
    readonly schema: TypeSchema;
    readonly userID: string | undefined;
}

export async function loadAppWithSchema(
    rw: DataReaderWriter,
    id: string,
    descriptionStore: AppDescriptionStore
): Promise<AppWithSchema | undefined> {
    const builderAppDoc = await rw.getDocument(builderAppsCollectionName, id);
    if (builderAppDoc === undefined) {
        logError("Could not load builder app", id);
        return undefined;
    }
    const builderApp = await makeBuilderAppFromDocument(id, rw.database, builderAppDoc, descriptionStore);
    if (builderApp === undefined) return undefined;

    const publishedAppData = await loadPublishedAppData(rw, id);
    const schema = publishedAppData?.schema;
    if (schema === undefined) {
        logError("No schema in published app", id);
        return undefined;
    }

    return {
        builderApp,
        builderAppDocument: builderAppDoc,
        publishedAppData,
        schema,
        userID: publishedAppData?.userID,
    };
}

export const defaultUserPlanCounts: UserPlanCounts = {
    pro: 0,
};

export const defaultUserPlanAppIDs: UserPlanAppIDs = {
    pro: [],
};

export const defaultOrganizationPlanCounts: OrganizationPlanCounts = {
    organizationPublic: 0,
};

export const defaultOrganizationPlanAppIDs: OrganizationPlanAppIDs = {
    organizationPublic: [],
};

export function coerceAppTrialDates(
    db: Database,
    appTrials: any
): { trials: Record<string, AppTrialInfo | undefined>; brokenDates: boolean } | undefined {
    if (isUndefinedish(appTrials)) return undefined;
    const appTrialsWithDates: Record<string, AppTrialInfo> = {};
    let brokenDates = false;
    for (const [appTrialID, appTrialInfo] of toPairs<AppTrialInfo>(appTrials)) {
        if (appTrialInfo.trialStarted === undefined || typeof appTrialInfo.trialStarted === "number") {
            appTrialsWithDates[appTrialID] = { ...appTrialInfo };
            continue;
        }
        const validDate = db.dateFromTimestamp(appTrialInfo.trialStarted);
        brokenDates = true;
        appTrialsWithDates[appTrialID] = {
            ...appTrialInfo,
            trialStarted: validDate.getTime(),
        };
    }
    return { trials: appTrialsWithDates, brokenDates };
}

export function makeOwnerFromDocument(db: Database, data: DocumentData, id: string): Owner {
    const maybeAppTrialDates = coerceAppTrialDates(db, data.appTrials);
    let coercedAppTrials: Record<string, AppTrialInfo | undefined> | undefined;
    if (maybeAppTrialDates !== undefined) {
        coercedAppTrials = maybeAppTrialDates.trials;
    }

    const base = {
        id,
        displayName: data.displayName,
        appIDs: data.appIDs ?? [],
        integrations: data.integrations ?? [],
        stripeCustomerIDs: data.stripeCustomerIDs ?? [],
        hubspotContactID: data.hubspotContactID,
        monthlySubscription: { ...data.monthlySubscription, kind: SubscriptionKind.Monthly },
        annualSubscription: { ...data.annualSubscription, kind: SubscriptionKind.Annual },
        expectedSubscriptionSerial: data.expectedSubscriptionSerial,
        features: data.features,
        suspensions: data.suspensions,
        appTrials: coercedAppTrials ?? {},
        lastEmailVerificationDate: data.lastEmailVerificationDate,
        createdAt: data.createdAt,
        hasPendingHubspotContact: data.hasPendingHubspotContact,
        referrals: data.referrals ?? 0,
        freeEntitlementsPriceOverride: data.freeEntitlementsPriceOverride,
    };

    if (data.kind === OwnerKind.Organization) {
        const memberUserIDs = data.memberUserIDs ?? [];
        let adminUserIDs = data.adminUserIDs ?? [];
        if (adminUserIDs.length === 0 && memberUserIDs.length > 0) {
            // Just to be defensive
            adminUserIDs = [memberUserIDs[0]];
        }
        let result: Owner = {
            kind: OwnerKind.Organization,
            ...base,
            memberUserIDs,
            adminUserIDs,
            companyInformation: data.companyInformation,
            planCounts: data.planCounts ?? defaultOrganizationPlanCounts,
            planAppIDs: data.planAppIDs ?? defaultOrganizationPlanAppIDs,
            hasSubscription: data.hasSubscription ?? false,
            isAgency: data.isAgency ?? false,
            templateLicenses: data.templateLicenses ?? [],
            customerInformation: data.customerInformation ?? undefined,
            partnerStackReferrerPartnerKey: data.partnerStackReferrerPartnerKey ?? undefined,
            logoURL: data.logoURL ?? undefined,
            defaultAppPrimaryColor: data.defaultAppPrimaryColor ?? undefined,
            isAIEnabled: data.isAIEnabled ?? false,
            isAgencyClientOf: data.isAgencyClientOf ?? undefined,
            reverseFreeTrialStatus: validateReverseFreeTrialStatusAgainstCodec(data.reverseFreeTrialStatus),
        };

        if (result.planAppIDs.organizationPublic === undefined) {
            result = { ...result, planAppIDs: { ...result.planAppIDs, organizationPublic: [] } };
        }
        return result;
    } else {
        const authIDs = uniq(
            filterUndefined([
                ...(definedMap(data.authIDs, a => checkArray(a, checkString)) ?? []),
                definedMap(data.authID, checkString),
            ])
        );
        const emails = uniq(
            filterUndefined([
                ...(definedMap(data.emails, a => checkArray(a, checkString)) ?? []),
                definedMap(data.email, checkString),
            ])
        );
        let result: Owner = {
            kind: OwnerKind.User,
            ...base,
            emails,
            phoneNumber: data.phoneNumber,
            photoURL: data.photoURL,
            authIDs,
            flags: data.flags,
            orgUserIDs: data.orgUserIDs ?? [],
            planCounts: data.planCounts ?? defaultUserPlanCounts,
            planAppIDs: data.planAppIDs ?? defaultUserPlanAppIDs,
            templateLicenses: data.templateLicenses ?? [],
            reasonForGlide: data.reasonForGlide,
            selfReportedReferralSource: data.selfReportedReferralSource,
            selfReportedReferralSourceOtherExplanation: data.selfReportedReferralSourceOtherExplanation,
            partnerStackReferralPartnerKey: data.partnerStackReferralPartnerKey, // should only be attached to `isGlideExpert: true` users.
            customerInformation: data.customerInformation ?? undefined,
            allowMyApps: data.allowMyApps,
            isGlideExpert: data.isGlideExpert,
            devicePreferences: data.devicePreferences,

            // to remain empty from Q4 2023 onwards:
            // TODO: related to https://github.com/glideapps/glide/pull/26787 and we should delete if possible.
            //  Search for this PR #26787 to get related lines.
            reasonForGlideOtherExplanation: data.reasonForGlideOtherExplanation,
        };

        if (result.planAppIDs.pro === undefined) {
            result = { ...result, planAppIDs: { ...result.planAppIDs, pro: [] } };
        }
        return result;
    }
}

async function loadOwnersByQuery(db: QueryableDataReader, query: Query): Promise<Owner[]> {
    const results = await db.getDocumentsWhere(usersCollectionName, [query]);
    return results.map(({ data, id }) => makeOwnerFromDocument(db.database, data, id));
}

export async function loadOwnerByQuery(db: QueryableDataReader, query: Query): Promise<Owner | undefined> {
    const results = await loadOwnersByQuery(db, query);
    return results[0];
}

export async function loadUserByFirebaseID(db: QueryableDataReader, firebaseID: string): Promise<UserData | undefined> {
    const result = await loadOwnerByQuery(db, {
        fieldPath: "authIDs",
        opString: "array-contains",
        value: firebaseID,
    });
    if (result === undefined || result.kind !== OwnerKind.User) return undefined;
    return result;
}

export async function loadOwner(db: DataReader, userID: string): Promise<Owner | undefined> {
    const doc = await db.getDocument(usersCollectionName, userID);
    return definedMap(doc, d => makeOwnerFromDocument(db.database, d, userID));
}

export async function loadQuotas(db: DataReader, context: QuotaContext, id: string): Promise<QuotaDocument> {
    const documentID = makeQuotaDocumentID(context, id);
    const data = await db.getDocument(quotasCollectionName, documentID);
    if (data === undefined) return { quotas: {}, quotaVersions: {} };
    const fromDoc = quotasFromDocument(db.database, data) as any;
    if (fromDoc.quotas === undefined) {
        fromDoc.quotas = {};
    }
    if (fromDoc.quotaVersions === undefined) {
        fromDoc.quotaVersions = {};
    }
    return fromDoc;
}

export function listenToUser(db: Database, userID: string, onUpdate: (userData: UserData) => void): () => void {
    return db.listenToDocument(usersCollectionName, userID, data => {
        if (data === undefined) return;
        const owner = makeOwnerFromDocument(db, data, userID);
        if (owner.kind !== OwnerKind.User) {
            return panic("Owner is not a user");
        }
        onUpdate(owner);
        return;
    });
}

export function listenToTemplateSubmissions(
    db: Database,
    appID: string,
    onUpdate: (templateSubmissions: readonly TemplateSubmission[]) => void
): () => void {
    return listenWhereWithRetry(
        db,
        templateSubmissionsCollectionName,
        [
            {
                fieldPath: "sourceAppID",
                opString: "==",
                value: appID,
            },
        ],
        undefined,
        async docs => {
            const templateSubmissions = docs.map(d => ({
                author: d.data.author,
                sourceAppID: d.data.sourceAppID,
                status: d.data.status,
                categories: d.data.categories ?? [d.data.category],
                subtitle: d.data.subtitle,
                description: d.data.description,
                usageVideoURL: d.data.usageVideoURL,
                updatedAt: db.dateFromTimestamp(d.data.updatedAt),
                price: d.data.price ?? 0,
                features: d.data.features,
                difficulty: d.data.difficulty,
                screenshots: d.data.screenshots,
                deniedReason: d.data.deniedReason,
            }));
            onUpdate(templateSubmissions.sort((a, b) => a.updatedAt.getTime() - b.updatedAt.getTime())); // ASC
            return;
        }
    );
}

function makeAppAnalyticsFromDocument(doc: DocumentData): AppAnalytics {
    return {
        last30DayUniqueDevices: doc.last30DayUniqueDevices ?? 0,
        lastUniqueAppUsersInWindow: doc[uniqueUsersFieldName] ?? 0,
    };
}

export async function loadAppAnalytics(db: DataReader, appID: string): Promise<AppAnalytics | undefined> {
    try {
        const doc = await db.getDocument(appAnalyticsCollectionName, appID);
        if (doc === undefined) return undefined;
        return makeAppAnalyticsFromDocument(doc);
    } catch {
        return undefined;
    }
}

export async function loadAppZapierAPIKey(db: Database, appID: string): Promise<string | undefined> {
    try {
        const doc = await db.getDocument(appIntegrationsCollection(appID), zapierDocName);
        if (doc === undefined) return undefined;
        return definedMap(doc.zapierAPIKey, checkString);
    } catch {
        return undefined;
    }
}

export function listenToAppZaps(db: Database, appID: string, onUpdate: (zaps: readonly ZapData[]) => void): () => void {
    return listenWhereWithRetry(
        db,
        zapsCollectionForAppID(appID),
        [
            {
                fieldPath: "isEnabled",
                opString: "==",
                value: true,
            },
        ],
        20,
        async docs => {
            const zaps = docs.map(d => ({ id: d.data.id, name: d.data.name }));
            onUpdate(zaps);
        }
    );
}

function makeWebhookIntegrationFromDocument(doc: DocumentData, id: string): WebhookIntegrationWithID | undefined {
    if (doc.kind !== IntegrationKind.Webhook) return undefined;
    return { ...doc, id } as unknown as WebhookIntegrationWithID;
}

export async function loadWebhookIntegration(
    db: Database,
    appID: string,
    webhookID: string
): Promise<WebhookIntegrationWithID | undefined> {
    const doc = await db.getDocument(appIntegrationsCollection(appID), webhookID);
    if (doc === undefined) return undefined;
    return makeWebhookIntegrationFromDocument(doc, webhookID);
}

export async function saveWebhookIntegration(
    db: DataWriter,
    appID: string,
    webhookID: string | undefined,
    wh: WebhookIntegration
): Promise<string> {
    const doc = db.database.convertToDocument(wh);
    return await db.setDocument(appIntegrationsCollection(appID), webhookID, doc);
}

export const webhookIntegrationQuery: Query = {
    fieldPath: "kind",
    opString: "==",
    value: IntegrationKind.Webhook,
};

export function listenToAppWebhooks(
    db: Database,
    appID: string,
    onUpdate: (webhooks: readonly WebhookIntegrationWithID[]) => void
): () => void {
    return listenWhereWithRetry(
        db,
        appIntegrationsCollection(appID),
        [webhookIntegrationQuery],
        undefined,
        async docs => {
            const integrations = mapFilterUndefined(docs, d => makeWebhookIntegrationFromDocument(d.data, d.id));
            onUpdate(integrations);
        }
    );
}

export function isRowIndex(x: any): x is RowIndex {
    if (x === undefined) return false;
    return isRight(rowIndexCodec.decode(x));
}

export function areRowIndexesConflicting(a: RowIndex | undefined, b: RowIndex | undefined): boolean {
    if (a === undefined || b === undefined) return false;

    if (isBaseRowIndex(a)) {
        if (isBaseRowIndex(b)) {
            return a === b;
        } else {
            return a === b.rowIndexHint;
        }
    } else {
        if (isBaseRowIndex(b)) {
            return a.rowIndexHint === b;
        } else {
            return a.keyColumnName === b.keyColumnName && a.keyColumnValue === b.keyColumnValue;
        }
    }
}

export function getUserAgnosticRowIndex(x: RowIndex): BaseRowIndex | undefined {
    if (typeof x === "number" || typeof x === "string") return x;
    return x.rowIndexHint;
}

export function getUserSpecificRowIndex(x: RowIndex): UserSpecificRowIndex | undefined {
    if (typeof x !== "object") return undefined;
    return x;
}

export function decomposeRowIndex(ri: RowIndex): UserSpecificRowIndex {
    if (isBaseRowIndex(ri)) {
        return { keyColumnName: rowIndexColumnName, keyColumnValue: ri };
    } else {
        return ri;
    }
}

export const userSpecificRowCodec = iots.intersection([
    userSpecificRowIndexCodec,
    iots.type({
        appUserID: iots.string,
        data: iots.record(iots.string, iots.any),
    }),
    iots.partial({
        [rowVersionFieldName]: iots.number,
    }),
]);

export type UserSpecificRow = iots.TypeOf<typeof userSpecificRowCodec>;

async function postActionDocument(
    db: DataWriter,
    authID: string | undefined,
    appUserID: string | undefined,
    deviceID: string | undefined,
    path: string,
    actionDoc: ActionDocument
): Promise<string> {
    const doc: DocumentData = { ...actionDoc, writtenAt: db.database.serverTimestampFieldValue, receivedAt: false };
    if (authID !== undefined) {
        doc.authID = authID;
    }
    if (appUserID !== undefined) {
        doc.appUserID = appUserID;
    }
    if (deviceID !== undefined) {
        doc.deviceID = deviceID;
    }
    return await db.setDocument(path, undefined, doc);
}

export function tryDecodeTableName(maybeTableName: unknown): TableName | string | undefined {
    const decodeResult = tableNameOrStringCodec.decode(maybeTableName);
    return isLeft(decodeResult) ? undefined : decodeResult.right;
}

export async function postDeleteRowAction(
    db: DataWriter,
    appID: string,
    authID: string | undefined,
    appUserID: string | undefined,
    deviceID: string | undefined,
    tableName: TableName,
    rowIndex: RowIndex,
    fromBuilder: boolean,
    fromDataEditor: boolean,
    screenPath: string | undefined,
    writeSource: WriteSourceType
): Promise<string> {
    const doc: DeleteRowDocument = {
        tableName,
        rowIndex: [rowIndex],
        fromBuilder,
        fromDataEditor,
        screenPath,
        writeSource,
    };
    return await postActionDocument(
        db,
        authID,
        appUserID,
        deviceID,
        makeActionDocumentPath(appID, ActionKind.DeleteRow),
        doc
    );
}

// Returns the full action path
export async function postDeleteColumnAction(
    db: Database,
    appID: string,
    tableName: TableName,
    columnName: string
): Promise<string> {
    // This is only called from the builder.
    const doc: DeleteColumnDocument = {
        tableName,
        columnName,
        fromBuilder: true,
        fromDataEditor: true,
        writeSource: "data-editor",
    };
    const collection = makeActionDocumentPath(appID, ActionKind.DeleteColumn);
    const actionID = await postActionDocument(db, undefined, undefined, undefined, collection, doc);
    return `${collection}/${actionID}`;
}

export enum NewFeatureTag {
    New = "new",
    Update = "update",
}

export interface NewFeature {
    readonly title: string;
    readonly body: string;
    readonly learnMoreURL?: string;
    readonly tag: NewFeatureTag;
}

export async function loadNewFeatures(db: Database, userID: string): Promise<"never seen" | ReadonlyArray<NewFeature>> {
    const newestSeen = await db.getDocument(newFeaturesSeenCollectionName, userID);
    const queries: Query[] = [];
    queries.push({
        fieldPath: "date",
        opString: "<=",
        value: new Date(),
    });
    if (newestSeen === undefined) {
        return "never seen";
    } else {
        queries.push({
            fieldPath: "date",
            opString: ">=",
            value: newestSeen.lastSeen,
        });
    }

    const results = await db.getDocumentsWhere(newFeaturesCollectionName, queries);
    return results.map(({ data }) => data as NewFeature);
}

export async function saveNewestFeatureSeen(db: Database, userID: string): Promise<void> {
    await db.setDocument(newFeaturesSeenCollectionName, userID, { lastSeen: new Date() });
}

export interface RowDocumentDatas {
    readonly publicData?: DocumentData;
    readonly privateData?: DocumentData;
}

export function combineRowDocumentDatas({ publicData, privateData }: RowDocumentDatas): DocumentData {
    return { ...publicData, ...privateData };
}

export function setRowDocumentData(
    entry: RowDocumentDatas | undefined,
    data: DocumentData,
    isPrivate: boolean
): RowDocumentDatas {
    if (isPrivate) {
        entry = { ...entry, privateData: data };
    } else {
        entry = { ...entry, publicData: data };
    }
    return entry;
}

export function deleteRowDocumentData(entry: RowDocumentDatas, isPrivate: boolean): RowDocumentDatas | undefined {
    if (isPrivate) {
        entry = { ...entry, privateData: undefined };
    } else {
        entry = { ...entry, publicData: undefined };
    }
    if (entry.privateData === undefined && entry.publicData === undefined) {
        return undefined;
    }
    return entry;
}

export interface LatLng {
    readonly lat: number;
    readonly lng: number;
}

export async function loadLocationForAddress(db: Database, address: string): Promise<LatLng | undefined> {
    address = address.trim();

    const docs = await db.getDocumentsWhere(
        geocodingCollectionName,
        [{ fieldPath: "address", opString: "==", value: address }],
        undefined,
        undefined,
        1
    );
    if (docs.length !== 1) return undefined;

    const { lat, lng } = docs[0].data.location;
    return {
        lat: checkNumber(lat),
        lng: checkNumber(lng),
    };
}

export function makeAppUserIDQuery(appUserID: string): Query {
    return { fieldPath: appUserIDFieldName, opString: "==", value: appUserID };
}

export function makePrivateRowsQuery(appUserID: string, appUserRoles: Iterable<string>): Query {
    // `array-contains-any` supports no more than 10 items:
    // https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any
    const ids = [appUserID, ...appUserRoles].slice(0, 10);
    return {
        fieldPath: ownerAppUserIDsFieldName,
        opString: "array-contains-any",
        value: ids,
    };
}

export function makeRowVersionQuery(version: number): Query {
    return { fieldPath: rowVersionFieldName, opString: ">", value: version };
}

export enum SnapshotKind {
    // Publicly accessible.
    Public = "public",
    // Accessible only to whoever can sign in to the app.
    Protected = "private",
    // Only accessible to the backend and the builder, contains only tables
    // with row owners.
    Backend = "backend",
    // Only accessible to the backend and the builder, contains tables that
    // are unused in the app.  We don't put those in any other snapshots
    // because the player is not supposed to see tables that aren't used in
    // the app.
    BackendUnused = "backend-unused",
    // Snapshots for individual native tables.  Used by the backend and the
    // builder.  The player will only use these if the table doesn't have row
    // owners.
    NativeTable = "native-table",
}

// This is the logic for which snapshot contains a table, depending on a bunch
// of factors:
//
// function getSnapshotThatHasTable(
//     tableIsNativeTable: boolean,
//     tableIsUsedInApp: boolean,
//     tableHasRowOwners: boolean,
//     appIsPublished: boolean,
//     appNeedsSignIn: boolean
// ): SnapshotKind {
//     if (tableIsNativeTable) {
//         return SnapshotKind.NativeTable;
//     } else {
//         if (tableIsUsedInApp) {
//             if (tableHasRowOwners) {
//                 return SnapshotKind.Backend;
//             } else {
//                 if (appIsPublished) {
//                     if (appNeedsSignIn) {
//                         return SnapshotKind.Protected;
//                     } else {
//                         return SnapshotKind.Public;
//                     }
//                 } else {
//                     return SnapshotKind.Protected;
//                 }
//             }
//         } else {
//             return SnapshotKind.BackendUnused;
//         }
//     }
// }

export function snapshotDirectory(kind: SnapshotKind): string {
    return `snapshots-${kind}`;
}

export function snapshotFilename(appID: string, kind: SnapshotKind): string {
    return `${snapshotDirectory(kind)}/${appID}.jzon`;
}

export interface TableDocuments {
    // Indexed by table name
    [name: string]: readonly DocumentDataWithID[];
}

export interface DataSnapshot {
    readonly data: TableDocuments;
    readonly version: number;
}

export function isDataSnapshot(snapshot: TableSnapshot | DataSnapshot): snapshot is DataSnapshot {
    return "data" in snapshot && typeof snapshot.version === "number";
}

export interface TableData {
    readonly deletedRowIndexes: readonly BaseRowIndex[];
    readonly deletedRowIndexVersions: Record<string, number>;
    readonly version: number | undefined;
}

const deletedRowIndexVersionsCodec = iots.record(baseRowIndexCodec, iots.number);

export function makeTableDataFromDocument(data: DocumentData): TableData {
    let { deletedRowIndexes } = data;
    if (typeof deletedRowIndexes === "string") {
        deletedRowIndexes = JSON.parse(deletedRowIndexes);
    } else if (!Array.isArray(deletedRowIndexes)) {
        deletedRowIndexes = [];
    }
    checkArray(deletedRowIndexes, checkBaseRowIndex);
    let deletedRowIndexVersions: Record<string, number> = {};
    const { deletedRowIndexVersions: docDeletedRowIndexVersions } = data;

    if (docDeletedRowIndexVersions !== undefined) {
        try {
            const decoded = deletedRowIndexVersionsCodec.decode(JSON.parse(docDeletedRowIndexVersions));
            if (isRight(decoded)) {
                deletedRowIndexVersions = decoded.right;
            }
        } catch {
            // Oh no! The JSON didn't parse.
            // There's nothing intelligent we can do here.
        }
    }

    return { deletedRowIndexes, version: definedMap(data.version, checkNumber), deletedRowIndexVersions };
}

export async function loadTableData(
    db: DataReader,
    appID: string,
    tableName: TableName
): Promise<TableData | undefined> {
    const tablesPath = makeTablesPath(appID);
    return definedMap(await db.getDocument(tablesPath, documentIDForTableName(tableName)), makeTableDataFromDocument);
}

export interface AppUserForApp {
    readonly roles: readonly string[];
}

export function makeAppUserForAppFromDocument(doc: DocumentData): AppUserForApp {
    return { roles: checkArray(doc.roles, checkString) };
}

export async function loadAllowedEmails(tx: DataReader, appID: string): Promise<readonly string[] | undefined> {
    const userProfilePath = makeUserProfileDocumentPathForApp(appID);
    const doc = await tx.getDocument(userProfilePath, allowedEmailsDocumentName);
    if (doc === undefined) return undefined;
    return checkArray(JSON.parse(unzipString(doc.allowedEmails)), checkString);
}

interface PrimitiveAuthenticationData {
    readonly kind: AuthenticationMethod.None | AuthenticationMethod.Disabled;
}

interface EmailPinAuthenticationData {
    readonly kind: AuthenticationMethod.PublicEmailPin | AuthenticationMethod.OrgMembers | AuthenticationMethod.JustMe;
    readonly optional: boolean;
}

interface PasswordAuthenticationData {
    readonly kind: AuthenticationMethod.Password;
    readonly password: string;
}

interface WhitelistEmailPinAuthenticationData {
    readonly kind: AuthenticationMethod.WhitelistEmailPin;
    readonly emailTableName: TableName;
    readonly additionalEmailsWithPins: readonly EmailWithPin[];
}

interface UserProfileEmailPinAuthenticationData {
    readonly kind: AuthenticationMethod.UserProfileEmailPin;
    readonly tableName?: TableName;
    readonly columnName?: string;
    readonly optional: boolean;
}

interface AllowlistedDomainsEmailPinAuthenticationData {
    readonly kind: AuthenticationMethod.AllowedEmailDomains;
    readonly optional: boolean;
    readonly tableName?: TableName;
    readonly columnName?: string;
}

/**
 * This is a normalized version of the serialized apps AppAuthentication data. See getAppAuthenticationData for
 * more info on normalization.
 */
export type AuthenticationData =
    | PrimitiveAuthenticationData
    | EmailPinAuthenticationData
    | PasswordAuthenticationData
    | WhitelistEmailPinAuthenticationData
    | UserProfileEmailPinAuthenticationData
    | AllowlistedDomainsEmailPinAuthenticationData;

export function signInAccessControlledEminence(
    flag: PrivateOrPublicOnly | boolean,
    authData: AuthenticationData
): boolean {
    const isPublic: boolean = isAuthenticationMethodPublic(authData.kind);

    if (flag === "private") {
        return !isPublic;
    } else if (flag === "public") {
        return isPublic;
    } else {
        return flag;
    }
}
