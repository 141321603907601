import { lookupWithMutator } from "./utils";
import chroma from "chroma-js";

const shadowColor = (opacity: number) => `rgba(62, 65, 86, ${opacity})`;
const shadowColorDark = (opacity: number) => `rgba(0, 0, 0, ${opacity})`;

export const coreTheme = {
    themeKind: "coreTheme",
    colorScheme: "light",

    // Neutrals
    n0: "#FFFFFF",
    n0A: "rgba(255,255,255,0)",
    n50: "#FAFAFB",
    n50A: "rgba(128,132,138,0.04)",
    n100: "#F5F5F5",
    n100A: "rgba(128,128,128,0.08)",
    n200: "#F0F0F0",
    n200A: "rgba(128,128,128,0.12)",
    n300: "#E8E8E8",
    n300A: "rgba(128,128,128,0.18)",
    n400: "#E1E1E1",
    n400A: "rgba(128,128,128,0.24)",
    n500: "#C1C1C1",
    n500A: "rgba(82,82,82,0.38)",
    n600: "#9D9D9D",
    n600A: "rgba(86,86,86,0.58)",
    n700: "#626262",
    n700A: "rgba(68,68,68,0.84)",
    n800: "#3D3D3D",
    n800A: "rgba(44,44,44,0.92)",
    n900: "#0D0D0D",
    n900A: "rgba(0,0,0,0.95)",

    // White Alpha
    w05A: "rgba(255,255,255,0.05)",
    w10A: "rgba(255,255,255,0.1)",
    w20A: "rgba(255,255,255,0.2)",
    w30A: "rgba(255,255,255,0.3)",
    w40A: "rgba(255,255,255,0.4)",
    w50A: "rgba(255,255,255,0.5)",
    w60A: "rgba(255,255,255,0.6)",
    w70A: "rgba(255,255,255,0.7)",
    w80A: "rgba(255,255,255,0.8)",
    w90A: "rgba(255,255,255,0.9)",
    w100A: "#FFF",

    // System Colors
    // green
    g100: "rgba(0,196,83,0.1)",
    g200: "rgba(0,196,83,0.15)",
    g300: "rgba(0,196,83,0.5)",
    g400: "#00C453", // default
    g500: "#00A851",

    // red
    r100: "rgba(255,68,65,0.1)",
    r200: "rgba(255,68,65,0.15)",
    r300: "rgba(255,68,65,0.5)",
    r400: "#FF4441", // default
    r500: "#E42723",

    // yellow
    y100: "rgba(255,185,0,0.1)",
    y200: "rgba(255,185,0,0.15)",
    y300: "rgba(255,185,0,0.5)",
    y400: "#FFB900", // default
    y500: "#E78C00",

    // blue
    b100: "rgba(62,116,253,0.1)",
    b200: "rgba(58,101,253,0.15)",
    b300: "rgba(79,118,255,0.5)",
    b400: "#4F5DFF", // default
    b500: "#2D3DED",

    // Brand Colors
    // TODO: Rename to aqua
    aqua100: "rgba(48, 224, 249, 0.1)",
    aqua200: "rgba(48, 224, 249, 0.2)",
    aqua300: "rgba(24, 190, 212, 0.5)",
    aqua400: "#15ADC1",
    aqua500: "#1398AA",
    aqua: "_aqua400",

    //TODO: Rename to purple
    purple100: "rgba(154, 145, 255, 0.12)",
    purple200: "rgba(154, 145, 255, 0.222)",
    purple300: "rgba(154, 145, 255, 0.5)",
    purple400: "#9A91FF",
    purple500: "#7B6FF5",

    // pink
    pink100: "rgba(243,123,188,0.10)",
    pink200: "rgba(243,123,188,0.15)",
    pink300: "rgba(243,123,188,0.50)",
    pink400: "#F37BBC", // default
    pink500: "#E359A4",

    // orange
    // TODO: Rename to orange
    orange100: "rgba(255,123,87,0.10)",
    orange200: "rgba(255,123,87,0.15)",
    orange300: "rgba(255,123,87,0.50)",
    orange400: "#FF7B57", // default
    orange500: "#E1603C",

    lime100: "rgba(215, 231, 121, 0.2)",
    lime200: "rgba(215, 231, 121, 0.33)",
    lime300: "rgba(215, 231, 121, 0.5)",
    lime400: "#D7E779",
    lime500: "#98A936",

    beige100: "rgba(149, 132, 97, 0.13)",
    beige200: "rgba(149, 132, 97, 0.25)",
    beige300: "rgba(149, 132, 97, 0.55)",
    beige400: "#958461",
    beige500: "#776A4E",

    // Others
    brandGradient: "radial-gradient(100% 300% at 0% 100%, #64D851 0%, #16B7B2 50%, #3C5DFF 120%), #00C453",
    accent: "_aqua400",
    darkAccent: lookupWithMutator("_primaryAccentColor", (color: string) => chroma(color).set("hsl.l", 0.13).css()),
    darkerAccent: lookupWithMutator("_primaryAccentColor", (color: string) =>
        chroma(color)
            .set("hsl.s", chroma(color).get("hsl.s") - 0.2)
            .set("hsl.l", 0.13)
            .css()
    ),

    // New brand colors
    // TODO: Deprecate
    newBrand1: "#17B5CB",
    newBrand2: "#FF7A56",
    newBrand3: "#6C62E3",
    newBrand4: "#007D8E",
    newBrand5: "#FFB11B",
    newBrand6: "#7C8AA1",
    newBrand7: "#EE8BAE",
    newBrandGradient: "linear-gradient(90deg, #12CCE5 25.62%, #887EF9 74.07%);",

    // StatusBar color used for <meta> theme-color
    OSstatusBar: "#fff",

    //
    // VNEXT - builder overlay
    //
    textDark: "_n900",
    textBase: "_n800A",
    textPale: "_n700A",
    textXpale: "_n600A",
    textDisabled: "_n500A",
    textInverse: "_n0",
    textSuccess: "_g500",
    textWarning: "_y500",
    textDanger: "_r500",
    textAccent: "_b500",

    textContextualDark: "_n900",
    textContextualBase: "_n800A",
    textContextualPale: "_n700A",
    textContextualXpale: "_n600A",
    textContextualDisabled: "_n500A",
    bgContextualDisabled: "_n50A",
    textContextualInverse: "_n0",
    textContextualSuccess: "_g500",
    textContextualWarning: "_y500",
    textContextualDanger: "_r500",
    textContextualAccent: "_b500",

    iconDark: "_n800A",
    iconBase: "_n700A",
    iconPale: "_n600A",
    iconXpale: "_n500A",
    iconDisabled: "_n400A",
    iconInverse: "_n0",
    iconSuccess: "_g400",
    iconWarning: "_y400",
    iconDanger: "_r400",

    bgFronter: "_n0",
    bgFront: "_n0",
    bgMiddle: "_n50",
    bgMiddleTransparent: "rgba(249, 249, 250, .82)",
    bgBack: "_n200",
    bgBehind: "_n300",

    bgContainerBase: "_n0",
    bgContainerHighlight: "_n0",

    bgHovered: "_n50A",

    borderDark: "_n400A",
    borderBase: "_n300A",
    borderPale: "_n200A",
    borderXpale: "_n100A",

    separatorColor: "_n200A",

    shadowSoft0: `0px 0px 1px ${shadowColor(0.25)}`,
    shadowSoft1: `0px 0px 1px ${shadowColor(0.25)}, 0px 1px 2.5px ${shadowColor(0.1)}`,
    shadowSoft2: `0px 0px 1px ${shadowColor(0.25)}, 0px 4px 8px ${shadowColor(0.1)}`,
    shadowSoft3: `0px 0px 1px ${shadowColor(0.25)}, 0px 6px 12px ${shadowColor(0.1)}`,
    shadowSoft4: `0px 0px 1px ${shadowColor(0.25)}, 0px 12px 24px ${shadowColor(0.15)}`,
    shadowSoft5: `0px 0px 1px ${shadowColor(0.25)}, 0px 12px 24px ${shadowColor(0.15)}`,
    shadowSoft6: `0px 0px 1px ${shadowColor(0.25)}, 0px 12px 24px ${shadowColor(0.15)}`,

    shadow0: `0px 0px 1px ${shadowColor(0.25)}`,
    shadow1: `0px 0px 1px ${shadowColor(0.25)}, 0px 1px 2.5px ${shadowColor(0.1)}`,
    shadow2: `0px 0px 1px ${shadowColor(0.25)}, 0px 4px 8px ${shadowColor(0.1)}`,
    shadow3: `0px 0px 1px ${shadowColor(0.25)}, 0px 6px 12px ${shadowColor(0.1)}`,
    shadow4: `0px 0px 1px ${shadowColor(0.25)}, 0px 12px 24px ${shadowColor(0.15)}`,
    shadow5: `0px 0px 1px ${shadowColor(0.25)}, 0px 12px 24px ${shadowColor(0.15)}`,
    shadow6: `0px 0px 1px ${shadowColor(0.25)}, 0px 12px 24px ${shadowColor(0.15)}`,

    shadowDark0: `0px 0px 1px ${shadowColor(0.375)}, 0px 0px 1px ${shadowColor(0.2)}`,
    shadowDark1: `0px 0px 1px ${shadowColor(0.375)}, 0px 1px 2.5px ${shadowColor(0.15)}`,
    shadowDark2: `0px 0px 1px ${shadowColor(0.375)}, 0px 4px 8px ${shadowColor(0.15)}`,
    shadowDark3: `0px 0px 1px ${shadowColor(0.375)}, 0px 6px 12px ${shadowColor(0.15)}`,
    shadowDark4: `0px 0px 1px ${shadowColor(0.375)}, 0px 12px 24px ${shadowColor(0.15)}, 0px 20px 40px ${shadowColor(
        0.1
    )}`,
    shadowDark5: `0px 0px 1px ${shadowColor(0.375)}, 0px 12px 24px ${shadowColor(0.2)}, 0px 20px 40px ${shadowColor(
        0.1
    )}`,
    shadowDark6: `0px 0px 1px ${shadowColor(0.375)}, 0px 12px 24px ${shadowColor(0.2)}, 0px 20px 40px ${shadowColor(
        0.1
    )}`,

    shadowXdark0: `0px 0px 1px ${shadowColor(0.5)}, 0px 0px 1px ${shadowColor(0.33)}`,
    shadowXdark1: `0px 0px 1px ${shadowColor(0.5)}, 0px 1px 3px ${shadowColor(0.33)}`,
    shadowXdark2: `0px 0px 1px ${shadowColor(0.5)}, 0px 4px 8px ${shadowColor(0.33)}`,
    shadowXdark3: `0px 0px 1px ${shadowColor(0.5)}, 0px 6px 12px ${shadowColor(0.33)}`,
    shadowXdark4: `0px 0px 1px ${shadowColor(0.5)}, 0px 12px 24px ${shadowColor(0.33)}, 0px 20px 40px ${shadowColor(
        0.1
    )}`,
    shadowXdark5: `0px 0px 1px ${shadowColor(0.5)}, 0px 12px 24px ${shadowColor(0.33)}, 0px 20px 40px ${shadowColor(
        0.1
    )}`,
    shadowXdark6: `0px 0px 1px ${shadowColor(0.5)}, 0px 12px 24px ${shadowColor(0.33)}, 0px 20px 40px ${shadowColor(
        0.1
    )}`,

    shadowPanelsColor: "rgba(49, 49, 57, 0.05)",
    shadowPanelsColorDarker: "rgba(0, 0, 0, 0.08)",

    transitionBase: "0.125s ease",
    transitionSlow: "0.25s ease",
    transitionFast: "0.1s ease",

    onboardingSecondaryButtonBgColor: "_n0",
    onboardingLeftPanelBgColor: "_n0",
};

export type CoreTheme = typeof coreTheme;

export const darkCoreTheme: CoreTheme = {
    themeKind: "coreTheme",
    colorScheme: "dark",
    n0: "#000",
    n0A: "rgba(0,0,0,0)",
    n50: "#0E0E0E",
    n50A: "rgba(240,240,240,0.06)",
    n100: "#1A1A1A",
    n100A: "rgba(240,240,240,0.09)",
    n200: "#222",
    n200A: "rgba(240,240,240,0.14)",
    n300: "#303030",
    n300A: "rgba(240,240,240,0.20)",
    n400: "#414141",
    n400A: "rgba(240,240,240,0.27)",
    n500: "#676767",
    n500A: "rgba(240,240,240,0.43)",
    n600: "#808080",
    n600A: "rgba(240,240,240,0.54)",
    n700: "#BDBDBD",
    n700A: "rgba(255,255,255,0.75)",
    n800: "#E8E8E8",
    n800A: "rgba(255,255,255,0.92)",
    n900: "#FFF",
    n900A: "rgba(255, 255, 255, 0.98)",

    // White Alpha
    w05A: "rgba(255,255,255,0.05)",
    w10A: "rgba(255,255,255,0.1)",
    w20A: "rgba(255,255,255,0.2)",
    w30A: "rgba(255,255,255,0.3)",
    w40A: "rgba(255,255,255,0.4)",
    w50A: "rgba(255,255,255,0.5)",
    w60A: "rgba(255,255,255,0.6)",
    w70A: "rgba(255,255,255,0.7)",
    w80A: "rgba(255,255,255,0.8)",
    w90A: "rgba(255,255,255,0.9)",
    w100A: "#FFF",

    // System Colors
    // green
    g100: "rgba(0,201,107,0.16)",
    g200: "rgba(0,201,107,0.3)",
    g300: "rgba(0,201,107,0.6)",
    g400: "#00CB5E", // default
    g500: "#68E7A2",

    // red
    r100: "rgba(249,69,66,0.2)",
    r200: "rgba(249,69,66,0.33)",
    r300: "rgba(249,69,66,0.6)",
    r400: "#F94542", // default
    r500: "#FF7674",

    // yellow
    y100: "rgba(250,183,0,0.2)",
    y200: "rgba(250,183,0,0.33)",
    y300: "rgba(250,183,0,0.6)",
    y400: "#FAB700", // default
    y500: "#FFD666",

    // blue
    b100: "rgba(125,140,255,0.14)",
    b200: "rgba(125,140,255,0.33)",
    b300: "rgba(125,140,255,0.6)",
    b400: "#7283FF", // default
    b500: "#929FFF",

    // Brand Colors
    aqua100: "rgba(48, 224, 249, 0.12)",
    aqua200: "rgba(48, 224, 249, 0.23)",
    aqua300: "rgba(24, 190, 212, 0.5)",
    aqua400: "#15ADC1",
    aqua500: "#1398AA",
    aqua: "_aqua400",

    purple100: "rgba(154, 145, 255, 0.2)",
    purple200: "rgba(154, 145, 255, 0.33)",
    purple300: "rgba(154, 145, 255, 0.6)",
    purple400: "#9A91FF",
    purple500: "#AFA8FF",

    // pink
    pink100: "rgba(243,123,188,0.12)",
    pink200: "rgba(243,123,188,0.20)",
    pink300: "rgba(243,123,188,0.50)",
    pink400: "#F37BBC", // default
    pink500: "#FF9FD3",

    // orange
    orange100: "rgba(255,123,87,0.16)",
    orange200: "rgba(255,123,87,0.33)",
    orange300: "rgba(255,123,87,0.60)",
    orange400: "#FF7B57", // default
    orange500: "#FF9174",

    lime100: "rgba(215, 231, 121, 0.25)",
    lime200: "rgba(215, 231, 121, 0.35)",
    lime300: "rgba(215, 231, 121, 0.55)",
    lime400: "#D7E779",
    lime500: "#DEE8A1",

    beige100: "rgba(149, 132, 97, 0.15)",
    beige200: "rgba(149, 132, 97, 0.28)",
    beige300: "rgba(149, 132, 97, 0.6)",
    beige400: "#958461",
    beige500: "#A7997B",

    //Others
    brandGradient: "radial-gradient(100% 550% at 0% 100%, #64D851 0%, #16B7B2 60%, #3C5DFF 100%), #00C453",
    accent: "_aqua",
    darkAccent: coreTheme.darkAccent,
    darkerAccent: coreTheme.darkerAccent,

    // New brand colors
    // TODO: Deprecate
    newBrand1: "#17B5CB",
    newBrand2: "#FF7A56",
    newBrand3: "#6C62E3",
    newBrand4: "#007D8E",
    newBrand5: "#FFB11B",
    newBrand6: "#7C8AA1",
    newBrand7: "#EE8BAE",
    newBrandGradient: "linear-gradient(90deg, #12CCE5 25.62%, #887EF9 74.07%);",

    OSstatusBar: "#000",

    //
    // VNEXT - builder overlay
    //
    textDark: "_n900",
    textBase: "_n800A",
    textPale: "_n700A",
    textXpale: "_n600A",
    textDisabled: "_n500A",
    textInverse: "_n0",
    textSuccess: "_g500",
    textWarning: "_y500",
    textDanger: "_r500",
    textAccent: "_b500",

    textContextualDark: "_n900",
    textContextualBase: "_n800A",
    textContextualPale: "_n700A",
    textContextualXpale: "_n600A",
    textContextualDisabled: "_n500A",
    bgContextualDisabled: "_n50A",
    textContextualInverse: "_n0",
    textContextualSuccess: "_g500",
    textContextualWarning: "_y500",
    textContextualDanger: "_r500",
    textContextualAccent: "_b500",

    iconDark: "_n800",
    iconBase: "_n700A",
    iconPale: "_n600A",
    iconXpale: "_n500A",
    iconDisabled: "_n400A",
    iconInverse: "_n0",
    iconSuccess: "_g400",
    iconWarning: "_y400",
    iconDanger: "_r400",

    bgFronter: "_n300",
    bgFront: "_n200",
    bgMiddle: "_n100",
    bgMiddleTransparent: "rgba(33, 33, 33, .82)",
    bgBack: "_n50",
    bgBehind: "_n0",

    bgContainerBase: "_n100",
    bgContainerHighlight: "_n300",

    bgHovered: "_n100A",

    borderDark: "_n300A",
    borderBase: "_n200A",
    borderPale: "_n100A",
    borderXpale: "_n50A",

    separatorColor: "_n100A",

    shadowSoft0: `0px 0px 1px ${shadowColorDark(0.25)}`,
    shadowSoft1: `0px 0px 1px ${shadowColorDark(0.25)}, 0px 1px 2.5px ${shadowColorDark(0.1)}`,
    shadowSoft2: `0px 0px 1px ${shadowColorDark(0.25)}, 0px 4px 8px ${shadowColorDark(0.1)}`,
    shadowSoft3: `0px 0px 1px ${shadowColorDark(0.25)}, 0px 6px 12px ${shadowColorDark(0.1)}`,
    shadowSoft4: `0px 0px 1px ${shadowColorDark(0.25)}, 0px 12px 24px ${shadowColorDark(0.15)}`,
    shadowSoft5: `0px 0px 1px ${shadowColorDark(0.25)}, 0px 12px 24px ${shadowColorDark(0.15)}`,
    shadowSoft6: `0px 0px 1px ${shadowColorDark(0.25)}, 0px 12px 24px ${shadowColorDark(0.15)}`,

    shadow0: `0px 0px 1px ${shadowColorDark(0.25)}`,
    shadow1: `inset 0 1px 0 rgb(255 255 255 / 3%), 0px 0px 1px ${shadowColorDark(
        0.25
    )}, 0px 1px 2.5px ${shadowColorDark(0.2)}`,
    shadow2: `inset 0 1px 0 rgb(255 255 255 / 3%), 0px 0px 1px ${shadowColorDark(0.25)}, 0px 4px 8px ${shadowColorDark(
        0.2
    )}`,
    shadow3: `inset 0 1px 0 rgb(255 255 255 / 3%), 0px 0px 1px ${shadowColorDark(0.25)}, 0px 6px 12px ${shadowColorDark(
        0.2
    )}`,
    shadow4: `inset 0 1px 0 rgb(255 255 255 / 3%), 0px 0px 1px ${shadowColorDark(
        0.25
    )}, 0px 12px 24px ${shadowColorDark(0.25)}`,
    shadow5: `inset 0 1px 0 rgb(255 255 255 / 3%), 0px 0px 1px ${shadowColorDark(
        0.25
    )}, 0px 12px 24px ${shadowColorDark(0.25)}`,
    shadow6: `inset 0 1px 0 rgb(255 255 255 / 3%), 0px 0px 1px ${shadowColorDark(
        0.25
    )}, 0px 12px 24px ${shadowColorDark(0.25)}`,

    shadowDark0: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 0px 1px ${shadowColorDark(0.3)}`,
    shadowDark1: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 1px 3px ${shadowColorDark(0.3)}`,
    shadowDark2: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 4px 8px ${shadowColorDark(0.3)}`,
    shadowDark3: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 6px 12px ${shadowColorDark(0.3)}`,
    shadowDark4: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 12px 24px ${shadowColorDark(0.3)}, 0px 20px 40px ${shadowColorDark(0.1)}`,
    shadowDark5: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 12px 24px ${shadowColorDark(0.3)}, 0px 20px 40px ${shadowColorDark(0.1)}`,
    shadowDark6: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 12px 24px ${shadowColorDark(0.3)}, 0px 20px 40px ${shadowColorDark(0.1)}`,

    shadowXdark0: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 0px 1px ${shadowColorDark(0.3)}`,
    shadowXdark1: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 1px 3px ${shadowColorDark(0.3)}`,
    shadowXdark2: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 4px 8px ${shadowColorDark(0.3)}`,
    shadowXdark3: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 6px 12px ${shadowColorDark(0.3)}`,
    shadowXdark4: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 12px 24px ${shadowColorDark(0.2)}, 0px 20px 40px ${shadowColorDark(0.1)}`,
    shadowXdark5: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 12px 24px ${shadowColorDark(0.2)}, 0px 20px 40px ${shadowColorDark(0.1)}`,
    shadowXdark6: `inset 0 0 0 1px rgb(255 255 255 / 7%), 0px 0px 1px ${shadowColorDark(
        0.375
    )}, 0px 12px 24px ${shadowColorDark(0.2)}, 0px 20px 40px ${shadowColorDark(0.1)}`,

    shadowPanelsColor: "rgba(0,0,0, 0.25)",
    shadowPanelsColorDarker: "rgba(0, 0, 0, 0.33)",

    transitionBase: "0.125s ease",
    transitionSlow: "0.25s ease",
    transitionFast: "0.1s ease",

    onboardingSecondaryButtonBgColor: "_n300",
    onboardingLeftPanelBgColor: "_n200",
};
