import "twin.macro";

import type { Subscription } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import type { Organization } from "@glide/common-core/dist/js/Database";
import React from "react";

import type { UpgradeModalConfig } from "./upgrade-utils";

export interface SubscriptionContextProps {
    readonly organization: Organization;
    readonly subscription: Subscription;
    readonly showUpgradeModal: (config?: UpgradeModalConfig) => Promise<void>;
}

type WithSubscriptionContext<P> = P & { subscriptionContext: SubscriptionContextProps };

export enum SubscriptionContextAlternateState {
    Loading,
    AccountCannotUpgrade,
}

export const SubscriptionContext = React.createContext<SubscriptionContextAlternateState | SubscriptionContextProps>(
    SubscriptionContextAlternateState.Loading
);

export function isSubscriptionContext(
    v: SubscriptionContextAlternateState | SubscriptionContextProps
): v is SubscriptionContextProps {
    return (
        v !== SubscriptionContextAlternateState.Loading && v !== SubscriptionContextAlternateState.AccountCannotUpgrade
    );
}

export function withSubscriptionContext<P>(
    Component: React.ComponentType<WithSubscriptionContext<P>>,
    hideWarning?: boolean
): React.FC<React.PropsWithChildren<P>> {
    return function (props: P) {
        const subscriptionContext = React.useContext(SubscriptionContext);
        if (subscriptionContext === SubscriptionContextAlternateState.Loading) {
            return null;
        }
        if (subscriptionContext === SubscriptionContextAlternateState.AccountCannotUpgrade) {
            if (hideWarning) {
                return null;
            }
            return (
                <p tw="p-4 rounded-md">
                    This is a legacy account that cannot be upgraded to use this feature. Please migrate to a Team
                    account on your dashboard.
                </p>
            );
        }
        return <Component subscriptionContext={subscriptionContext} {...props} />;
    };
}

export function LegacyContemporaryPricingSwitch(props: {
    legacy?: React.ReactElement;
    contemporary?: React.ReactElement;
}) {
    const { legacy = null, contemporary = null } = props;

    const subscriptionContext = React.useContext(SubscriptionContext);
    if (subscriptionContext === SubscriptionContextAlternateState.Loading) {
        return null;
    } else if (subscriptionContext === SubscriptionContextAlternateState.AccountCannotUpgrade) {
        return legacy;
    }

    return contemporary;
}

// This function is used to render either a free or other pricing switch, depending on the subscription context.
// Free pricing is used when the organization is on a free Eminence plan, and other pricing is used otherwise.
export function FreeOtherPricingSwitch(props: { free?: React.ReactElement; other?: React.ReactElement }) {
    const { free = null, other = null } = props;

    const subscriptionContext = React.useContext(SubscriptionContext);

    if (subscriptionContext === SubscriptionContextAlternateState.Loading) {
        return null;
    } else if (subscriptionContext === SubscriptionContextAlternateState.AccountCannotUpgrade) {
        return other;
    }

    const { subscription } = subscriptionContext;
    return subscription.plan === "free" ? free : other;
}

export function useSubscriptionContext() {
    if (!React.useContext(SubscriptionContext)) {
        throw new Error("useSubscriptionContext must be used within a SubscriptionContextProvider");
    }
    return React.useContext(SubscriptionContext);
}
