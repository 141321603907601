import {
    setSnapshotLocationAndInitialSchemaFromResponse,
    storeCredentialsForFutureSignIn,
    type RootAuthenticator,
} from "@glide/auth-controller-core";
import { isEmptyOrUndefined, isResponseOK } from "@glide/support";
import * as React from "react";
import { Play2AuthKind } from "./auth-kind";
import { PlayerObservability } from "@glide/player-frontend-observability";
import type { AuthorizeUserForAppBody } from "@glide/common-core";
import { extractAppLoginTokenFromCurrentURL } from "@glide/common-core/dist/js/authorization/auth";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";

// Not exporting this cause it's not a pattern that I'd like to see anywhere else
function useEffectOnce(fn: () => void): void {
    const didRun = React.useRef(false);
    React.useEffect(() => {
        if (didRun.current) {
            return;
        }
        didRun.current = true;

        fn();
    }, [fn]);
}

/**
 * pre-authing: is in the process of pre-authing
 * needs-authentication: could not pre-auth and needs to go through the sign in screen
 * authed-or-unnecessary: we're either authenticated or we don't need to be, so move on
 */
type PreAuthState = "pre-authing" | "needs-authentication" | "authed-or-unnecessary";

const preAuthMetric = PlayerObservability.makePlayerMetric("pre-authed", ["status"]);
const preAuthedTiming = PlayerObservability.makePlayerTiming("pre-authed");

async function authorizeWithMagicLinkToken(
    token: AppLoginTokenContainer,
    authenticator: RootAuthenticator,
    appID: string,
    authKind: Play2AuthKind
): Promise<boolean> {
    const body: AuthorizeUserForAppBody = {
        appID: appID,
        email: token.overrideEmail,
        token,
        fromMagicLink: true,
        addUserProfileRow: true,
    };
    const response = await getAppFacilities().callAuthIfAvailableCloudFunction("authorizeUserForApp", body, {});
    if (!isResponseOK(response)) {
        return false;
    }

    const responseBody = await response?.json();
    const { appUserID, userProfileRow, schema: maybeSchema } = responseBody;

    setSnapshotLocationAndInitialSchemaFromResponse(appID, responseBody, maybeSchema);

    const customTokenEmail = token.overrideEmail;
    if (isEmptyOrUndefined(customTokenEmail)) {
        return false;
    }

    // prior to setting the authenticated user, we still need to perform the client side login
    // so we have access to app-user (and possibly other) firestore documents
    // https://github.com/glideapps/glide/issues/32452
    const success = await authenticator.signInFromPreAuthedCredentials(customTokenEmail, customTokenEmail, token);

    if (!success && authKind === Play2AuthKind.mandatory) {
        return false;
    }

    authenticator.setAuthenticatedUser({
        appUserID,
        realEmail: customTokenEmail,
        virtualEmail: customTokenEmail,
        userProfileRow,
        loginToken: token,
    });
    storeCredentialsForFutureSignIn(appID, customTokenEmail, token.tag, token);
    return true;
}

export function usePreAuth(authenticator: RootAuthenticator, authKind: Play2AuthKind, appID: string): PreAuthState {
    const { email, emailPassword, loginToken } = React.useMemo(() => {
        const preAuthedCredentials = authenticator.getPreAuthedUserCredential();
        const { emailPinCredentials, loginToken: loginTokenInner } = preAuthedCredentials;
        const { email: emailInner, emailPassword: emailPasswordInner } = emailPinCredentials ?? {};

        return {
            email: emailInner,
            emailPassword: emailPasswordInner,
            loginToken: loginTokenInner,
        };
    }, [authenticator]);

    const shouldTryToPreauth = !isEmptyOrUndefined(email) && authKindNeedsPreAuth(authKind);

    const magicLink = extractAppLoginTokenFromCurrentURL();

    const [preAuthState, setPreAuthState] = React.useState<PreAuthState>(() => {
        if (shouldTryToPreauth || magicLink !== undefined) {
            return "pre-authing";
        }

        if (authKind === Play2AuthKind.mandatory) {
            return "needs-authentication";
        }

        return "authed-or-unnecessary";
    });

    useEffectOnce(async () => {
        // before we attempt to pre auth with credentials,
        // check for magic link in the URL and use it to pre auth.
        if (magicLink !== undefined) {
            const success = await authorizeWithMagicLinkToken(magicLink, authenticator, appID, authKind);
            if (success) {
                setPreAuthState("authed-or-unnecessary");
                return;
            } else {
                // if the magic link didn't work, set auth state to failed
                setPreAuthState("needs-authentication");
                return;
            }
        }

        const stop = preAuthMetric.start();

        if (!shouldTryToPreauth) {
            stop({ status: "not-necessary" });
            preAuthedTiming.track();
            return;
        }

        const success = await authenticator.signInFromPreAuthedCredentials(email, emailPassword, loginToken);

        preAuthedTiming.track();

        if (!success && authKind === Play2AuthKind.mandatory) {
            stop({ status: "failed" });
            setPreAuthState("needs-authentication");
            return;
        }

        stop({ status: "success" });
        setPreAuthState("authed-or-unnecessary");
    });

    return preAuthState;
}

function authKindNeedsPreAuth(authKind: Play2AuthKind): boolean {
    return authKind === Play2AuthKind.mandatory || authKind === Play2AuthKind.optional;
}
