import { useRef } from "react";

export function usePreviousValue<T>(current: T): T | undefined {
    const prevRef = useRef<T>();
    const currentRef = useRef<T>();

    if (current !== currentRef.current) {
        prevRef.current = currentRef.current;
        currentRef.current = current;
    }

    return prevRef.current;
}
