import { styled } from "@glide/common";

export const HiddenCopyButtonIcon = styled.div`
    margin-right: 8px;
`;

export const HiddenCopyButtonWrapper = styled.div`
    display: flex;

    height: 32px;
    border: 1px solid ${p => p.theme.dropdownButton.borderNormal};
    border-radius: 4px;

    color: ${p => p.theme.fgColorDark};

    :hover {
        border-color: ${p => p.theme.dropdownButton.borderOpen};
    }
`;

export const HiddenCopyButtonButton = styled.button`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
