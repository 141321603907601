import { formatLocalizedString, getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import {
    type LoadedColumnValues,
    type LoadedRow,
    type LoadingValue,
    type PrimitiveValue,
    type Row,
    isLoadingValue,
    isPrimitive,
    isBound,
    UnboundVal,
} from "@glide/computation-model-types";
import { asMaybeString, asPrimitive } from "@glide/common-core/dist/js/computation-model/data";
import {
    type BaseContainerComponentDescription,
    type ColumnAssignmentsDescription,
    type ComponentDescription,
    type PropertyDescription,
    ActionKind,
    MutatingScreenKind,
    PropertyKind,
    getActionProperty,
    getArrayProperty,
    getEnumProperty,
    getTableProperty,
    makeEnumProperty,
    makeStringProperty,
} from "@glide/app-description";
import {
    type Description,
    type TableColumn,
    type TableGlideType,
    getAllowedTablesForAddRow,
    getTableName,
    isPrimitiveType,
    sheetNameForTable,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type ContainerComponentDescription,
    type ContainerLayout,
    type InputOutputTables,
    getContainerLayoutColumnsCount,
    makeEmptyComponentDescription,
    makeInputOutputTables,
} from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import type { WireAnyContainerComponent } from "@glide/fluent-components/dist/js/component";
import type { WireButtonComponent } from "@glide/fluent-components/dist/js/fluent-components";
import {
    type DescriptionOfComponent,
    type FluentComponent,
    type FluentComponentSpec,
    type InternalStateProperties,
    type PrettifyIntersection,
    ContainerKind,
    getSpecialCaseDescriptorFromSpec,
} from "@glide/fluent-components/dist/js/fluent-components-spec";
import {
    type ConstructableSpec,
    type FluentInternalStateSpec,
    type FluentPropertiesSpec,
    makeActionDescriptors,
    makePropertyDescriptors,
} from "@glide/fluent-components/dist/js/fluent-properties-spec";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentDescriptor,
    type ComponentSpecialCaseDescriptor,
    type EditedColumnsAndTables,
    type InteractiveComponentConfiguratorContext,
    type MultiCasePropertyDescriptor,
    type PropertyDescriptor,
    type PropertyTable,
    type RewritingComponentConfiguratorContext,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    combineEditedColumnsAndTables,
    convertEditedColumnsToIndirect,
    PropertySection,
    TablePropertyHandler,
    makeCustomConfiguratorPropertyDescriptor,
    getPrimitiveColumnsSpec,
} from "@glide/function-utils";
import type { BillablesConsumed, PluginTierList } from "@glide/plugins";
import { findLastIndex, isDefined, isValidEmailAddress } from "@glide/support";
import {
    type WireRowComponentHydratorConstructor,
    type WireAction,
    type WireComponent,
    type WireEditableValue,
    type WirePrimitiveValueWithType,
    type WireValueWithFormatted,
    WireActionResult,
    ValueChangeSource,
    WireComponentKind,
    makeContextTableTypes,
    UIBackgroundStyle,
    UIButtonAppearance,
    type WireActionHydrator,
    type WireInflationBackend,
    type WireRowComponentHydrationBackend,
    type WireRowComponentValueGetter,
    type WireRowHydrationValueProvider,
} from "@glide/wire";
import {
    assert,
    defined,
    definedMap,
    mapFilterUndefined,
    hasOwnProperty,
    filterUndefined,
    mapRecord,
} from "@glideapps/ts-necessities";
import fromPairs from "lodash/fromPairs";
import startCase from "lodash/startCase";
import upperFirst from "lodash/upperFirst";
import { getDefaultPrimitiveActionKinds } from "../actions";
import { makeEditedColumnsFromColumnAssignments } from "../actions/add-row";
import { getColumnAssignments } from "../description-utils";
import { handlerForComponentKind } from "../handlers";
import { editComponentsForTable } from "../make-edit-screen";
import {
    type OutputValueGetters,
    hydrateAction,
    hydrateOnSubmitAction,
    inflateActions,
    inflateColumnAssignments,
    inflateComponent,
    inflateComponentEnricher,
    isNotEmptyDisplayValue,
    makeSimpleWireRowComponentHydratorConstructor,
    registerBusyActionRunner,
    shouldActionShow,
} from "../wire/utils";
import type { ComponentHandler } from "./component-handler";
import { type PropertyDescriptorsWithColumns, makePropertyDescriptorsForColumns } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";
import type { StaticActionContext } from "../static-context";

interface GetterResult<T> {
    readonly value: T;
    readonly isDisplayedNonEmpty: boolean;
    readonly showIfCollectionEmpty: boolean;
    readonly isEditable: boolean;
}

interface GenericGetter<TGetter extends (...args: any[]) => any> {
    readonly name: string;
    readonly getter: (...args: Parameters<TGetter>) => GetterResult<ReturnType<TGetter>>;
}

type ValueGetter = GenericGetter<(hb: WireRowComponentHydrationBackend, keyPrefix: string) => unknown>;

interface Getters {
    readonly valueGetters: readonly ValueGetter[];
    readonly arrayBuilders: readonly GenericGetter<
        (hb: WireRowComponentHydrationBackend, keyPrefix: string) => readonly unknown[]
    >[];
    readonly actionHydrators: readonly GenericGetter<
        (hb: WireRowComponentHydrationBackend, key: string) => WireAction | undefined
    >[];
}

function makeUnboundResult(isEditable: boolean): GetterResult<any> {
    return {
        value: UnboundVal,
        isDisplayedNonEmpty: false,
        showIfCollectionEmpty: false,
        isEditable,
    };
}

function makeUndefinedResult(isEditable: boolean): GetterResult<any> {
    return {
        value: undefined,
        isDisplayedNonEmpty: false,
        showIfCollectionEmpty: false,
        isEditable,
    };
}

export function inflateGetters(
    ib: WireInflationBackend,
    { propertySpecs, arraySpecs, actionSpecs }: FluentPropertiesSpec,
    desc: Description
): Getters {
    function isExcluded(p: ConstructableSpec) {
        // FIXME: we're passing `desc` as the root desc here, and we're
        // leaving out quite a lot.
        // FIXME AGAIN: We need this `when` for showing the property _and_
        // for inflating it? Isn't there a way to propagate that knowledge and call
        // this only once?
        return p.when?.(desc, desc, undefined, ib.adc, undefined) === false;
    }

    const valueGetters = mapFilterUndefined(propertySpecs, (p, key) => {
        if (isExcluded(p)) return undefined;

        const { name } = p;

        const { valueGetter, formattedGetter, type, isDisplayed } = p.getValueGetter(desc, ib, p.isEditable !== false);

        function getFormatted(hb: WireRowComponentHydrationBackend): string | undefined {
            const formattedV = formattedGetter?.(hb) ?? undefined;

            if (isLoadingValue(formattedV) || !isPrimitive(formattedV)) {
                return undefined;
            } else {
                return asMaybeString(formattedV);
            }
        }

        let getter: ValueGetter["getter"];
        if (p.isEditable !== false) {
            assert(!p.withType);

            const { tokenMaker, tableAndColumn } = ib.getValueSetterForProperty(
                (desc as any)[name],
                `set-${name}.${key}`
            );

            if (
                p.isEditable === "if-writable" ||
                (tableAndColumn !== undefined && isPrimitiveType(tableAndColumn.column.type))
            ) {
                getter = (hb, keyPrefix) => {
                    const v = valueGetter(hb);
                    if (!isBound(v)) return makeUnboundResult(true);

                    let value: PrimitiveValue;
                    if (isLoadingValue(v) || !isPrimitive(v)) {
                        value = undefined;
                    } else {
                        value = asPrimitive(p.converter(v));
                    }

                    const formatted = getFormatted(hb);

                    const onChangeToken = tokenMaker(hb, undefined, undefined, keyPrefix);
                    if (onChangeToken === false && p.isEditable !== "if-writable") return makeUnboundResult(true);

                    const editableValue: WireEditableValue<unknown> = {
                        value,
                        displayValue: formatted,
                        onChangeToken: onChangeToken === false ? undefined : onChangeToken,
                    };
                    return {
                        value: editableValue,
                        isDisplayedNonEmpty: isDisplayed === true && isNotEmptyDisplayValue(value),
                        showIfCollectionEmpty: false,
                        isEditable: true,
                    };
                };
            } else if (tableAndColumn !== undefined) {
                getter = () => makeUndefinedResult(true);
            } else {
                getter = () => makeUnboundResult(true);
            }
        } else if (p.withType && type !== undefined) {
            if (isPrimitiveType(type)) {
                getter = hb => {
                    const valueGetterWithFormat = p.format === "formatted" ? formattedGetter : valueGetter;

                    const v = valueGetterWithFormat?.(hb);
                    if (!isBound(v)) return makeUnboundResult(false);
                    if (isLoadingValue(v) || !isPrimitive(v)) return makeUndefinedResult(false);
                    const converted = asPrimitive(p.converter(v));

                    const formatted = getFormatted(hb);

                    const result: WirePrimitiveValueWithType = {
                        value: converted,
                        formattedValue: formatted,
                        typeKind: type.kind,
                    };
                    return {
                        value: result,
                        isDisplayedNonEmpty: isDisplayed === true && isNotEmptyDisplayValue(converted),
                        showIfCollectionEmpty: false,
                        isEditable: false,
                    };
                };
            } else {
                getter = () => makeUndefinedResult(false);
            }
        } else if (p.format === "both") {
            getter = hb => {
                const v = valueGetter(hb);
                if (!isBound(v)) return makeUnboundResult(false);
                if (isLoadingValue(v)) return makeUndefinedResult(false);
                const converted = p.converter(v);

                const formatted = getFormatted(hb);

                const value: WireValueWithFormatted<unknown> = {
                    value: converted,
                    formattedValue: formatted,
                };

                return {
                    value,
                    isDisplayedNonEmpty: isDisplayed === true && isNotEmptyDisplayValue(converted),
                    showIfCollectionEmpty: false,
                    isEditable: false,
                };
            };
        } else {
            getter = hb => {
                const valueGetterWithFormat = p.format === "formatted" ? formattedGetter : valueGetter;

                const v = valueGetterWithFormat?.(hb);
                if (!isBound(v)) return makeUnboundResult(false);
                if (isLoadingValue(v)) return makeUndefinedResult(false);
                const converted = p.converter(v);

                return {
                    value: converted,
                    isDisplayedNonEmpty: isDisplayed === true && isNotEmptyDisplayValue(converted),
                    showIfCollectionEmpty: false,
                    isEditable: false,
                };
            };
        }
        return { name, getter };
    });

    const arrayBuilders = mapFilterUndefined(arraySpecs, a => {
        if (isExcluded(a)) return undefined;

        const arr: readonly Description[] = getArrayProperty((desc as any)[a.name]) ?? [];
        const itemGetters = arr.map(item => {
            const copy = { ...item };
            for (const [k, toRemove] of Object.entries(a.removeIfMissing)) {
                if ((item as any)[k] !== undefined) continue;
                for (const r of toRemove) {
                    delete (copy as any)[r];
                }
            }
            return inflateGetters(ib, a.itemsSpec, copy);
        });
        return {
            name: a.name,
            getter: (hb: WireRowComponentHydrationBackend, keyPrefix: string) => {
                const itemsAndFlags = itemGetters.map((getters, j) =>
                    hydrateGetters(getters, hb, `${keyPrefix}[${j}].`)
                );
                const items = itemsAndFlags.map(x => x.result);
                const someDisplayed = itemsAndFlags.some(x => x.isDisplayedNonEmpty);
                const showIfCollectionEmpty = itemsAndFlags.some(x => x.showIfCollectionEmpty);
                return { value: items, isDisplayedNonEmpty: someDisplayed, showIfCollectionEmpty, isEditable: false };
            },
        };
    });

    const actionHydrators = mapFilterUndefined(actionSpecs ?? [], a => {
        if (isExcluded(a)) return undefined;

        const { name, showIfCollectionEmpty } = a;
        const actionDesc = getActionProperty((desc as any)[name]);
        if (actionDesc === undefined) return undefined;

        const hydrator = inflateActions(ib, [actionDesc]);
        if (hydrator === undefined) return undefined;

        const getter = (hb: WireRowComponentHydrationBackend, key: string) => {
            // If an action isn't loaded yet then we don't add it, which
            // means it can't be triggered in the UI until it's loaded.
            const maybeAction = registerBusyActionRunner(hb, key, () => hydrateAction(hydrator, hb, false, undefined));

            const shouldShow = maybeAction !== undefined && shouldActionShow(maybeAction);

            return {
                value: maybeAction,
                isDisplayedNonEmpty: shouldShow,
                showIfCollectionEmpty: showIfCollectionEmpty && shouldShow,
                isEditable: false,
            };
        };

        return { name, getter };
    });

    return { valueGetters, arrayBuilders, actionHydrators };
}

export function hydrateGetters<T>(
    { valueGetters, arrayBuilders, actionHydrators }: Getters,
    hb: WireRowComponentHydrationBackend,
    keyPrefix: string
): {
    readonly result: T;
    readonly isDisplayedNonEmpty: boolean;
    readonly showIfCollectionEmpty: boolean;
    readonly editsInContext: boolean | undefined;
    readonly someEditableHasValue: boolean;
} {
    let isDisplayedNonEmpty = false;
    let showIfCollectionEmpty = false;
    let editsInContext: boolean | undefined = undefined;
    /**
     * This is a crappy simple way of dealing with fluent components in forms.
     * We need to know if the component has a value to know if we can submit the form.
     * Problem is that there's no mechanism for representing "hasValue" from fluent.
     * The simple approach here is:
     * "If any editable value has a defined value, then your component has a value".
     *
     * An alternative without modifying fluent could be:
     * "If all editable values have defined values, then your component has a value".
     *
     * An alternative modifying fluent could be:
     * "If all your _required_ editable values have values, then your component has a value".
     */
    let someEditableHasValue: boolean = false;

    function unwrap(r: GetterResult<unknown>) {
        if (r.isDisplayedNonEmpty) {
            isDisplayedNonEmpty = true;
        }
        if (r.showIfCollectionEmpty) {
            showIfCollectionEmpty = true;
        }
        if (r.isEditable) {
            editsInContext = true;
            const editableValue = r.value;
            // r.value is a WireEditableValue. This could be null, so we're making sure that `value` prop exists here.
            if (hasOwnProperty(editableValue, "value")) {
                someEditableHasValue ||= isDefined(editableValue.value);
            }
        }
        return r.value;
    }

    const hydrated = {
        ...fromPairs(
            valueGetters.map((vg, i) => {
                const result = vg.getter(hb, `${keyPrefix}value[${i}]`);
                return [vg.name, unwrap(result)];
            })
        ),
        ...fromPairs(
            arrayBuilders.map((ab, i) => {
                const result = ab.getter(hb, `${keyPrefix}array[${i}]`);
                return [ab.name, unwrap(result)];
            })
        ),
        ...fromPairs(
            mapFilterUndefined(actionHydrators, (ah, i) => {
                const result = ah.getter(hb, `${keyPrefix}action[${i}]`);
                return [ah.name, unwrap(result)];
            })
        ),
    } as any;

    return {
        result: hydrated,
        isDisplayedNonEmpty,
        showIfCollectionEmpty,
        editsInContext,
        someEditableHasValue,
    };
}

export type ComponentInflator<T> = (
    ib: WireInflationBackend,
    desc: DescriptionOfComponent<T>
) => WireRowComponentHydratorConstructor | undefined;

const targetTablePropertyHandler = new TablePropertyHandler(
    "targetTable",
    "Target table",
    true,
    (_desc, schema) => getAllowedTablesForAddRow(schema.schema),
    PropertySection.Data
);

function getFormTargetTable(
    _tables: InputOutputTables | undefined,
    rootDesc: Description | undefined,
    _desc: Description | undefined,
    schema: SchemaInspector
): PropertyTable | undefined {
    if (rootDesc === undefined) return undefined;
    const table = targetTablePropertyHandler.lookupTable(rootDesc, schema);
    if (table === undefined) return undefined;
    // Form components act like Form screens, and their properties act within
    // that context, which is why we set `inScreenContext`.  If we didn't, the
    // form properties would be counted as Sets, not as Adds.
    return { table, inScreenContext: true };
}

function makeOnSubmitActionDescriptor(
    schema: SchemaInspector | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined
): ActionPropertyDescriptor {
    return {
        kind: PropertyKind.Action,
        property: { name: "onSubmitAction" },
        label: "On submit action",
        kinds: getDefaultPrimitiveActionKinds(schema, mutatingScreenKind),
        getIndirectTable: getFormTargetTable,
        section: { name: "After submit action", order: 1 },
        defaultAction: () => ({ kind: ActionKind.ShowToast }),
    };
}

function makeAdditionalFormColumnAssignmentPropertyDescriptors<TDesc extends FormComponentDescription>(
    tables: InputOutputTables,
    adc: AppDescriptionContext,
    excludeOutputColumns: ReadonlySet<string>
): PropertyDescriptorsWithColumns {
    return makePropertyDescriptorsForColumns<TDesc>(
        adc,
        tables.input,
        tables.output,
        getColumnAssignments,
        columns => ({ columnAssignments: columns } as Partial<TDesc>),
        {
            withActionSource: false,
            withClearColumn: false,
            withLinkColumns: true,
            withArrays: true,
            forAddingRow: true,
            allowCustomAndUserProfile: true,
            emptyByDefault: true,
            propertySection: { name: "Additional columns", order: 0 },
            excludeOutputColumns,
        }
    );
}

function makeFormTargetPropertyDescriptors(
    targetTable: TableGlideType | undefined,
    inputTable: TableGlideType | undefined,
    adc: AppDescriptionContext,
    excludeOutputColumns: ReadonlySet<string>
): PropertyDescriptorsWithColumns {
    if (targetTable === undefined || inputTable === undefined) return [[], []];

    return makeAdditionalFormColumnAssignmentPropertyDescriptors(
        makeInputOutputTables(inputTable, targetTable),
        adc,
        excludeOutputColumns
    );
}

function inflateFormColumnAssignmentsFromDescription(
    ib: WireInflationBackend,
    desc: ColumnAssignmentsDescription,
    targetTable: TableGlideType,
    excludeOutputColumns: ReadonlySet<string>
): OutputValueGetters<WireRowHydrationValueProvider> {
    const [, targetColumns] = makeFormTargetPropertyDescriptors(
        targetTable,
        ib.tables.input,
        ib.adc,
        excludeOutputColumns
    );
    const targetColumnNames = new Set(targetColumns.map(c => c.name));
    const columnAssignments = getColumnAssignments(desc).filter(a => targetColumnNames.has(a.destColumn));
    return inflateColumnAssignments(ib, targetTable, columnAssignments, true);
}

export function hydrateOutputValueGetters(
    hb: WireRowComponentHydrationBackend,
    getters: OutputValueGetters<WireRowComponentHydrationBackend>
): LoadedColumnValues | LoadingValue {
    let loadingValue: LoadingValue | undefined;
    const result = fromPairs(
        mapFilterUndefined(getters, ([n, g]) => {
            const v = g(hb);
            if (v === null) return undefined;
            if (isLoadingValue(v)) {
                loadingValue = v;
                return undefined;
            }
            return [n, v];
        })
    );
    if (loadingValue !== undefined) return loadingValue;
    return result;
}

function getEditedColumnsInFormComponents(
    components: readonly ComponentDescription[],
    tables: InputOutputTables,
    adc: AppDescriptionContext
): ReadonlySet<string> {
    const columnNames = new Set<string>();
    for (const c of components) {
        const handler = handlerForComponentKind(c.kind);
        if (handler === undefined) continue;
        const editedColumns = handler.getEditedColumns(c, tables, adc, MutatingScreenKind.FormScreen, false);
        for (const e of editedColumns.editedColumns) {
            const [columnName, editsInScreenContext] = e;
            if (!editsInScreenContext) continue;
            columnNames.add(columnName);
        }
    }
    return columnNames;
}

function makeFormComponents(
    formTable: TableGlideType,
    appKind: AppKind,
    schema: SchemaInspector
): readonly ComponentDescription[] {
    // We put a maximum of 5 components in.
    const components = editComponentsForTable(formTable, appKind, MutatingScreenKind.FormScreen, schema).slice(0, 5);

    const title: ComponentDescription = {
        ...makeEmptyComponentDescription(WireComponentKind.Hero),
        title: makeStringProperty(formatLocalizedString("addTo", [sheetNameForTable(formTable)], appKind)),
        bgStyle: makeEnumProperty(UIBackgroundStyle.None),
    } as ComponentDescription;

    return [title, ...components];
}

function removeLoadingValues(row: Row): LoadedRow {
    return fromPairs(Object.entries(row).filter(([, v]) => !isLoadingValue(v))) as LoadedRow;
}

export function adjustSeparatorsForContainer<TDesc>(newDesc: TDesc): TDesc {
    const newContainerDescription = newDesc as unknown as ContainerComponentDescription;
    const newDescriptionComponents = newContainerDescription.components ?? [];

    const prevColumnsCount =
        newDescriptionComponents.filter(c => c.kind === WireComponentKind.InternalSeparator).length + 1;
    const newColumnsCount = getContainerLayoutColumnsCount(
        getEnumProperty<ContainerLayout>(newContainerDescription.layout)
    );

    const addedColumnsCount = newColumnsCount - prevColumnsCount;

    if (addedColumnsCount > 0) {
        // If we have to add columns, add separators at the end.
        const newComponents = [...newDescriptionComponents];
        for (let i = 0; i < addedColumnsCount; i++) {
            newComponents.push(makeEmptyComponentDescription(WireComponentKind.InternalSeparator));
        }

        newDesc = { ...newDesc, components: newComponents };
    } else if (addedColumnsCount < 0) {
        // If you have to remove columns, remove separators from the end.
        const newComponents = [...newDescriptionComponents];
        for (let i = 0; i < -addedColumnsCount; i++) {
            const lastSeparator = findLastIndex(newComponents, el => el.kind === WireComponentKind.InternalSeparator);
            if (lastSeparator > -1) {
                newComponents.splice(lastSeparator, 1);
            }
        }

        newDesc = { ...newDesc, components: newComponents };
    }
    return newDesc;
}

function hydrateInternalState<TInternalState extends FluentInternalStateSpec>(
    hb: WireRowComponentHydrationBackend,
    internalState: TInternalState
): InternalStateProperties<TInternalState> {
    const editableValues = mapRecord(internalState, (v, k) => {
        return hb.getState(k, v.validator, v.defaultValue, v.persist);
    });

    // `mapRecord` collapses all the type magic we do to keep types safe.
    return editableValues as InternalStateProperties<TInternalState>;
}

function makeComponentHandler<TDesc extends ComponentDescription>(
    spec: FluentComponentSpec,
    inflator: ComponentInflator<TDesc> | undefined
): ComponentHandler<TDesc> {
    const {
        properties: { propertySpecs, arraySpecs, actionSpecs },
        displayName,
        tier,
        billablesConsumed,
        kind,
        group = "Layout",
        description = "No description",
        icon = "componentTitle",
        containerKind,
        docURL,
        featureSetting,
        experimentFlag,
        userFeatureChecker,
        specialCases,
        includeRegularComponent,
        keywords,
        customConfigurator,
        isBeta,
        internalState,
    } = spec;
    assert(displayName !== undefined && kind !== undefined);
    let formTablePropertyDescriptor: PropertyDescriptor | undefined;
    if (containerKind === ContainerKind.Form) {
        formTablePropertyDescriptor = targetTablePropertyHandler;
    }

    function asFormComponentDescription(desc: Description): FormComponentDescription {
        // TODO: It's ugly that we have to do this cast.  The only way
        // I know to make sure we have this property here is to add an
        // additional `TExtraDesc` argument to the
        // `FluentComponentSpec` to specify additional arguments that
        // make it into the description.
        return desc as unknown as FormComponentDescription;
    }

    class Handler extends ComponentHandlerBase<TDesc> {
        constructor() {
            super(kind);
        }

        // TODO: Implement `needsColumns` if/when we need to support Add
        // screens, or any other context where we don't have input columns.

        public get appKinds(): AppKind | "both" {
            return AppKind.Page;
        }

        public get isBeta(): boolean {
            return isBeta === true;
        }

        public getTier(): PluginTierList | undefined {
            return tier;
        }

        public get prompt(): string | undefined {
            return spec.prompt;
        }

        public getBillablesConsumed(env: StaticActionContext<AppDescriptionContext>): BillablesConsumed | undefined {
            return billablesConsumed?.(env.context);
        }

        protected get configuresScreenTitle(): boolean {
            return spec.configuresScreenTitle === true;
        }

        public getSubComponents(desc: TDesc): readonly ComponentDescription[] | undefined {
            if (containerKind === undefined) return undefined;
            // TODO: This is really ugly.
            const containerDesc = desc as unknown as BaseContainerComponentDescription;
            return containerDesc.components ?? [];
        }

        public getSubComponentTables(
            desc: TDesc,
            containingTables: InputOutputTables,
            schema: SchemaInspector,
            mutatingScreenKind: MutatingScreenKind | undefined
        ): [InputOutputTables, MutatingScreenKind | undefined] | undefined {
            if (containerKind === undefined) return undefined;

            if (formTablePropertyDescriptor === undefined) {
                return [containingTables, mutatingScreenKind];
            }

            const targetTableName = targetTablePropertyHandler.getTableName(desc);
            const targetTable = schema.findTable(targetTableName);
            if (targetTable === undefined) return undefined;

            return [makeInputOutputTables(containingTables.input, targetTable), MutatingScreenKind.FormScreen];
        }

        public getDescriptor(
            desc: TDesc | undefined,
            tables: InputOutputTables | undefined,
            adc: AppDescriptionContext,
            mutatingScreenKind: MutatingScreenKind | undefined,
            forEasyTabConfiguration: boolean,
            isFirstComponent: boolean | undefined
        ): ComponentDescriptor {
            const properties = makePropertyDescriptors([...propertySpecs, ...arraySpecs], {
                desc,
                parentDesc: undefined,
                getPropertyTable: undefined,
                adc,
                tables,
                mutatingScreenKind,
                sectionOverride: undefined,
                isEditedInApp: false,
                forEasyCRUD: false,
                forEasyTabConfiguration,
                isFirstComponent,
                actionKinds: getDefaultPrimitiveActionKinds(adc, mutatingScreenKind),
            });
            if (formTablePropertyDescriptor !== undefined) {
                properties.push(formTablePropertyDescriptor);
                const formTable = definedMap(desc, d => adc.findTable(targetTablePropertyHandler.getTableName(d)));
                if (tables !== undefined && formTable !== undefined) {
                    const formTables = makeInputOutputTables(tables.input, formTable);
                    let editedColumns: ReadonlySet<string> | undefined;
                    if (desc !== undefined) {
                        editedColumns = getEditedColumnsInFormComponents(
                            this.getSubComponents(desc) ?? [],
                            formTables,
                            adc
                        );
                    }
                    const [assignmentDescriptors] = makeAdditionalFormColumnAssignmentPropertyDescriptors(
                        formTables,
                        adc,
                        editedColumns ?? new Set()
                    );
                    properties.push(...assignmentDescriptors);
                }
            }

            const isLegacyBecauseOfFeatureSetting =
                featureSetting !== undefined && getFeatureSetting(featureSetting.value) === featureSetting.isNegated;
            const isLegacyBecauseOfExperiment =
                experimentFlag !== undefined &&
                isExperimentEnabled(experimentFlag.value, adc.userFeatures) === experimentFlag.isNegated;
            const isLegacyBecauseOfUserFeature = userFeatureChecker?.(adc.userFeatures) === false;
            const isLegacy =
                !includeRegularComponent ||
                isLegacyBecauseOfFeatureSetting ||
                isLegacyBecauseOfExperiment ||
                isLegacyBecauseOfUserFeature;

            let customConfiguratorDescr: MultiCasePropertyDescriptor | undefined;
            if (customConfigurator !== undefined) {
                customConfiguratorDescr = makeCustomConfiguratorPropertyDescriptor(
                    customConfigurator.kind,
                    customConfigurator.section,
                    undefined
                );
            }

            return {
                name: defined(displayName),
                description,
                img: icon,
                group,
                helpUrl: getDocURL(docURL ?? "button"),
                isLegacy,
                properties: [
                    ...properties,
                    ...this.getBasePropertyDescriptors(),
                    ...filterUndefined([customConfiguratorDescr]),
                ],
                configuresScreenTitle: false,
                keywords,
            };
        }

        public getSpecialCaseDescriptors(ccc: AppDescriptionContext): readonly ComponentSpecialCaseDescriptor[] {
            return (
                specialCases?.map(([caseSpec]) => getSpecialCaseDescriptorFromSpec(caseSpec, ccc.userFeatures)) ?? []
            );
        }

        public getActionDescriptors(
            desc: TDesc,
            tables: InputOutputTables | undefined,
            adc: AppDescriptionContext,
            mutatingScreenKind: MutatingScreenKind | undefined
        ): readonly ActionPropertyDescriptor[] {
            const descrs = makeActionDescriptors(actionSpecs, {
                desc,
                parentDesc: undefined,
                getPropertyTable: formTablePropertyDescriptor !== undefined ? getFormTargetTable : undefined,
                adc,
                tables,
                mutatingScreenKind,
                sectionOverride: undefined,
                forEasyCRUD: false,
                forEasyTabConfiguration: false,
                isFirstComponent: undefined,
                actionKinds: getDefaultPrimitiveActionKinds(adc, mutatingScreenKind),
            });
            if (formTablePropertyDescriptor !== undefined) {
                descrs.push(makeOnSubmitActionDescriptor(adc, MutatingScreenKind.FormScreen));
            }
            return descrs;
        }

        public getEditedColumns(
            desc: TDesc,
            tables: InputOutputTables,
            adc: AppDescriptionContext,
            mutatingScreenKind: MutatingScreenKind | undefined,
            withActions: boolean
        ): EditedColumnsAndTables {
            const base = super.getEditedColumns(desc, tables, adc, mutatingScreenKind, withActions);

            const formDesc = asFormComponentDescription(desc);

            const tableName = getTableProperty(formDesc.targetTable);
            const targetTable = adc.findTable(tableName);
            if (targetTable === undefined) return base;

            const fromColumnAssignments = makeEditedColumnsFromColumnAssignments(
                {
                    assignments: getColumnAssignments(formDesc),
                    table: targetTable,
                    isAddRow: true,
                },
                false,
                adc
            );

            return combineEditedColumnsAndTables(base, { editedColumns: fromColumnAssignments, deletedTables: [] });
        }

        public rewriteAfterReload(
            desc: TDesc,
            tables: InputOutputTables,
            ccc: RewritingComponentConfiguratorContext,
            mutatingScreenKind: MutatingScreenKind | undefined,
            isRewrite: boolean
        ): TDesc | undefined {
            const newDesc = super.rewriteAfterReload(desc, tables, ccc, mutatingScreenKind, isRewrite);
            if (newDesc === undefined) return undefined;
            const oldComponents = this.getSubComponents(desc);
            if (oldComponents === undefined) {
                return newDesc;
            }

            const components = mapFilterUndefined(oldComponents ?? [], c => {
                const handler = handlerForComponentKind(c.kind);
                if (handler === undefined) return undefined;
                return handler.rewriteAfterReload(c, tables, ccc, mutatingScreenKind, isRewrite);
            });

            return { ...newDesc, components } as TDesc;
        }

        public newComponent(
            tables: InputOutputTables,
            usedColumns: ReadonlySet<TableColumn>,
            editedColumns: ReadonlySet<TableColumn>,
            iccc: InteractiveComponentConfiguratorContext,
            mutatingScreenKind: MutatingScreenKind | undefined
        ): TDesc | undefined {
            let desc = super.newComponent(tables, usedColumns, editedColumns, iccc, mutatingScreenKind);
            if (desc === undefined) return undefined;

            if (formTablePropertyDescriptor !== undefined) {
                const formTables = this.getSubComponentTables(desc, tables, iccc, mutatingScreenKind)?.[0];
                if (formTables !== undefined) {
                    desc = { ...desc, components: makeFormComponents(formTables.output, iccc.appKind, iccc) };
                }
            }

            return desc;
        }

        public newSpecialCaseComponent(
            specialCaseDescriptor: ComponentSpecialCaseDescriptor,
            tables: InputOutputTables,
            usedProperties: ReadonlySet<TableColumn>,
            editedProperties: ReadonlySet<TableColumn>,
            iccc: InteractiveComponentConfiguratorContext,
            mutatingScreenKind: MutatingScreenKind | undefined,
            insideContainer: boolean
        ): TDesc | undefined {
            assert(specialCases !== undefined);

            const desc = this.newComponent(tables, usedProperties, editedProperties, iccc, mutatingScreenKind);
            if (desc === undefined) return undefined;

            for (const [specialCase, f] of specialCases) {
                if (specialCaseDescriptor.name === specialCase.name) {
                    return f(desc, tables, iccc, mutatingScreenKind, insideContainer) as TDesc;
                }
            }
            return undefined;
        }

        public updateComponent(
            desc: TDesc,
            updates: Partial<TDesc>,
            tables: InputOutputTables | undefined,
            ccc: InteractiveComponentConfiguratorContext,
            mutatingScreenKind: MutatingScreenKind | undefined
        ): TDesc {
            let newDesc = super.updateComponent(desc, updates, tables, ccc, mutatingScreenKind);

            // If we change the layout of a Container, we need to make sure to fix up the InternalSeparators.
            if (newDesc.kind === WireComponentKind.Container) {
                newDesc = adjustSeparatorsForContainer(newDesc);
            }

            if (formTablePropertyDescriptor !== undefined && tables !== undefined) {
                const oldFormTables = this.getSubComponentTables(desc, tables, ccc, mutatingScreenKind)?.[0];
                const newFormTables = this.getSubComponentTables(newDesc, tables, ccc, mutatingScreenKind)?.[0];

                if (newFormTables?.output !== undefined && oldFormTables?.output !== newFormTables.output) {
                    newDesc = { ...newDesc, components: makeFormComponents(newFormTables.output, ccc.appKind, ccc) };
                }
            }

            return newDesc;
        }

        public inflate(ib: WireInflationBackend, desc: TDesc): WireRowComponentHydratorConstructor | undefined {
            const {
                adc: { appKind },
            } = ib;

            if (inflator !== undefined) {
                return inflator(ib, desc as DescriptionOfComponent<TDesc>);
            }

            const subComponentDescriptions = this.getSubComponents(desc);

            let formOutputTable: TableGlideType | undefined;
            let componentIB: WireInflationBackend;
            let columnAssignmentGetters: OutputValueGetters<WireRowHydrationValueProvider> | undefined;
            let onSubmitHydrator: WireActionHydrator | WireActionResult;
            if (formTablePropertyDescriptor === undefined) {
                componentIB = ib;
            } else {
                const formTables = this.getSubComponentTables(desc, ib.tables, ib.adc, ib.mutatingScreenKind)?.[0];
                if (formTables === undefined) return undefined;

                assert(formTables.input === ib.tables.input);
                formOutputTable = formTables.output;
                // Form Container components get inflated as if they're in a
                // Form screen.
                // https://github.com/quicktype/glide/issues/17183
                componentIB = ib.makeInflationBackendForTables(
                    makeContextTableTypes(formTables),
                    MutatingScreenKind.FormScreen
                );
                const editedColumns = getEditedColumnsInFormComponents(
                    defined(subComponentDescriptions),
                    formTables,
                    ib.adc
                );
                columnAssignmentGetters = inflateFormColumnAssignmentsFromDescription(
                    ib,
                    desc as ColumnAssignmentsDescription,
                    formOutputTable,
                    editedColumns
                );
                if (columnAssignmentGetters === undefined) return undefined;

                const onSubmitDescription = getActionProperty(asFormComponentDescription(desc).onSubmitAction);
                if (onSubmitDescription !== undefined) {
                    // https://github.com/glideapps/glide/issues/31506
                    const mutatingScreenKind = getFeatureSetting("setMutatingScreenKindUndefinedForOnSubmit")
                        ? undefined
                        : MutatingScreenKind.FormScreen;
                    const onSubmitIB = ib.makeInflationBackendForTables(
                        makeContextTableTypes(makeInputOutputTables(formOutputTable)),
                        mutatingScreenKind
                    );
                    onSubmitHydrator = inflateActions(onSubmitIB, [onSubmitDescription]);
                }
            }

            const getters = inflateGetters(ib, spec.properties, desc);
            const hydrators = definedMap(subComponentDescriptions, cs =>
                cs.map(c =>
                    inflateComponent(
                        componentIB,
                        c,
                        // If this is a form container, the visibility comes
                        // from the form's output row.  If it isn't, we might
                        // still be in an add/edit/form screen, where it comes
                        // from the screen's output row.
                        formOutputTable !== undefined || ib.mutatingScreenKind !== undefined
                    )
                )
            );

            const componentEnricher = inflateComponentEnricher<WireComponent>(ib, desc);

            return makeSimpleWireRowComponentHydratorConstructor(hb => {
                const hydratedGetters = hydrateGetters<any>(getters, hb, "");
                const hydratedInternalState = hydrateInternalState(hb, internalState);
                const hasDisplayedProperties = spec.properties.hasDisplayedProperties;

                // I have to say this is boolean even though it's obviously boolean.
                // For some reason TS thinks it's `any` in the comparison down below.
                const showRegardlessOfEmptyProperties: boolean = spec.showRegardlessOfEmptyProperties ?? false;

                // If a component doesn't have any displayed properties, it
                // means it should always display, like the Separator.
                // FIXME: Don't do this. Just trust `showRegardlessOfEmptyProperties`.
                if (
                    !hydratedGetters.isDisplayedNonEmpty &&
                    hydrators === undefined &&
                    hasDisplayedProperties &&
                    !showRegardlessOfEmptyProperties
                ) {
                    return undefined;
                }
                let hydrated = componentEnricher({ kind, ...hydratedGetters.result, ...hydratedInternalState });

                if (hydrators === undefined) {
                    return {
                        component: hydrated,
                        isValid: true,
                        hasValue: hydratedGetters.someEditableHasValue,
                        editsInContext: hydratedGetters.editsInContext,
                    };
                }

                const { components, allValid, haveValues, editsInContext, componentsRowContext } =
                    hb.hydrateSubComponents(
                        // This ##subComponentsStateName must match the
                        // `components` array in the `WireComponent` we construct
                        // below.
                        "components",
                        hydrators,
                        formOutputTable,
                        c => {
                            assert(c.kind === kind);
                            return (c as WireAnyContainerComponent).components;
                        }
                    );
                let specialComponents: (WireButtonComponent | null)[] | undefined;
                if (formOutputTable !== undefined && componentsRowContext?.outputRow !== undefined) {
                    let action: WireAction | undefined;
                    if (allValid && haveValues === true) {
                        const hydratedColumnAssignments = hydrateOutputValueGetters(
                            hb,
                            defined(columnAssignmentGetters)
                        );
                        if (!isLoadingValue(hydratedColumnAssignments)) {
                            const outputRow: LoadedRow = {
                                ...removeLoadingValues(defined(componentsRowContext?.outputRow)),
                                ...hydratedColumnAssignments,
                            };
                            const onSubmit = hydrateOnSubmitAction(onSubmitHydrator, () =>
                                hb.makeHydrationBackendForRow(
                                    outputRow,
                                    undefined,
                                    makeInputOutputTables(defined(formOutputTable))
                                )
                            );

                            if (onSubmit !== false) {
                                const { stateSaveKey } = hb;
                                action = registerBusyActionRunner(hb, "submitButton", () => [
                                    async (ab, handled) => {
                                        const addResult = await ab.addRow(
                                            getTableName(defined(formOutputTable)),
                                            outputRow,
                                            undefined,
                                            true
                                        );
                                        if (stateSaveKey !== undefined) {
                                            // This will reset the default
                                            // values for the input
                                            // components.
                                            ab.clearSubComponentStates(stateSaveKey);
                                        }
                                        if (!addResult.ok) return WireActionResult.fromResult(addResult);
                                        const newRow = addResult.result;
                                        if (onSubmit !== undefined) {
                                            const onSubmitAB = ab.makeActionBackendForOnSubmit(newRow);
                                            return await onSubmitAB.invoke("submit form container", onSubmit, handled);
                                        }
                                        return WireActionResult.nondescriptSuccess();
                                    },
                                    undefined,
                                ]);
                            }
                        }
                    }
                    const submitButton: WireButtonComponent = {
                        kind: WireComponentKind.Button,
                        title: getLocalizedString("submit", appKind),
                        appearance: UIButtonAppearance.Filled,
                        action,
                    };
                    specialComponents = [submitButton];
                }

                hydrated = { ...hydrated, components, specialComponents } as WireComponent;

                if (formOutputTable !== undefined) {
                    // Form containers don't participate in validation
                    // themselves.
                    return { component: hydrated, isValid: true };
                } else {
                    return { component: hydrated, editsInContext, isValid: allValid, hasValue: haveValues };
                }
            });
        }

        public convertToPage(): ComponentDescription | undefined {
            // Fluent components are Pages-only. We don't need to convert them.
            return undefined;
        }
    }

    return new Handler();
}

interface FormComponentDescription extends ColumnAssignmentsDescription, ComponentDescription {
    readonly targetTable: PropertyDescription | undefined;
    readonly onSubmitAction: PropertyDescription | undefined;
}

function formPropertyName(name: string): string {
    return `form${upperFirst(name)}`;
}

function makeFormComponentHandler<TDesc extends FormComponentDescription>(
    spec: FluentComponentSpec
): ComponentHandler<TDesc> {
    const {
        properties: { propertySpecs, arraySpecs, actionSpecs },
        formProperties: { propertySpecs: formPropertySpecs },
        displayName,
        kind,
        group = "Forms",
        description = "No description",
        icon = "componentTitle",
        docURL,
        featureSetting,
    } = spec;
    assert(kind !== undefined && displayName !== undefined);
    assert(spec.containerKind === undefined);

    const formPropertyHandlers = new Map(
        formPropertySpecs.map(p => {
            const { name } = p;
            const flags = [ColumnPropertyFlag.Editable, ColumnPropertyFlag.EditedInApp, ColumnPropertyFlag.Optional];
            if (p.emptyByDefault) {
                flags.push(ColumnPropertyFlag.EmptyByDefault);
            }
            const handler = new ColumnPropertyHandler(
                formPropertyName(name),
                startCase(name),
                flags,
                getFormTargetTable,
                [name],
                getPrimitiveColumnsSpec,
                p.preferredType ?? "string",
                PropertySection.Data
            );
            return [name, handler];
        })
    );
    const preferredTypeForProperty = new Map(
        formPropertySpecs.map(p => {
            const { name } = p;
            return [name, p.preferredType];
        })
    );

    // returns (form property name -> target column name)
    function getAssignedFormColumns(desc: Description | undefined): ReadonlyMap<string, string> {
        const map = new Map<string, string>();
        if (desc !== undefined) {
            for (const [n, h] of formPropertyHandlers) {
                const c = h.getColumnName(desc);
                if (c === undefined) continue;
                map.set(n, c);
            }
        }
        return map;
    }

    class Handler extends ComponentHandlerBase<TDesc> {
        constructor() {
            super(kind);
        }

        public get appKinds(): AppKind | "both" {
            return AppKind.Page;
        }

        public getActionDescriptors(
            desc: TDesc,
            tables: InputOutputTables | undefined,
            adc: AppDescriptionContext,
            mutatingScreenKind: MutatingScreenKind | undefined
        ): readonly ActionPropertyDescriptor[] {
            return [
                ...makeActionDescriptors(actionSpecs, {
                    desc: undefined,
                    parentDesc: desc,
                    getPropertyTable: getFormTargetTable,
                    adc,
                    tables: tables,
                    mutatingScreenKind,
                    sectionOverride: undefined,
                    forEasyCRUD: false,
                    forEasyTabConfiguration: false,
                    isFirstComponent: undefined,
                    actionKinds: getDefaultPrimitiveActionKinds(adc, mutatingScreenKind),
                }),
                makeOnSubmitActionDescriptor(adc, mutatingScreenKind),
            ];
        }

        public getDescriptor(
            desc: TDesc | undefined,
            tables: InputOutputTables | undefined,
            ccc: AppDescriptionContext,
            mutatingScreenKind: MutatingScreenKind | undefined,
            forEasyTabConfiguration: boolean,
            isFirstComponent: boolean | undefined
        ): ComponentDescriptor {
            const targetTable = getFormTargetTable(tables, desc, desc, ccc)?.table;
            const assignedColumnNames = getAssignedFormColumns(desc).values();
            const [columnAssignmentDescriptors] = makeFormTargetPropertyDescriptors(
                targetTable,
                tables?.input,
                ccc,
                new Set(assignedColumnNames)
            );

            return {
                name: defined(displayName),
                description,
                img: icon,
                group,
                helpUrl: getDocURL(docURL ?? "button"),
                isLegacy:
                    featureSetting !== undefined &&
                    getFeatureSetting(featureSetting.value) === featureSetting.isNegated,
                properties: [
                    ...this.getBasePropertyDescriptors(),
                    ...makePropertyDescriptors([...propertySpecs, ...arraySpecs], {
                        desc,
                        parentDesc: undefined,
                        getPropertyTable: undefined,
                        adc: ccc,
                        tables,
                        mutatingScreenKind,
                        sectionOverride: undefined,
                        isEditedInApp: false,
                        forEasyCRUD: false,
                        forEasyTabConfiguration,
                        isFirstComponent,
                        actionKinds: getDefaultPrimitiveActionKinds(ccc, mutatingScreenKind),
                    }),
                    targetTablePropertyHandler,
                    ...formPropertyHandlers.values(),
                    ...columnAssignmentDescriptors,
                ],
            };
        }

        public getEditedColumns(
            desc: TDesc,
            tables: InputOutputTables,
            adc: AppDescriptionContext,
            _mutatingScreenKind: MutatingScreenKind | undefined,
            withActions: boolean
        ): EditedColumnsAndTables {
            // This components acts to its properties like a Form screen.
            let base = super.getEditedColumns(desc, tables, adc, MutatingScreenKind.FormScreen, withActions);
            // But in the context of the containing screen the columns are
            // "indirect", i.e. written to a different table.
            base = {
                ...base,
                editedColumns: convertEditedColumnsToIndirect(base.editedColumns),
            };

            const tableName = getTableProperty(desc.targetTable);
            const targetTable = adc.findTable(tableName);
            if (tableName === undefined || targetTable === undefined) return base;

            const fromColumnAssignments = makeEditedColumnsFromColumnAssignments(
                {
                    assignments: getColumnAssignments(desc),
                    table: targetTable,
                    isAddRow: true,
                },
                false,
                adc
            );

            return combineEditedColumnsAndTables(base, {
                editedColumns: fromColumnAssignments,
                deletedTables: [],
            });
        }

        public inflate?(ib: WireInflationBackend, desc: TDesc): WireRowComponentHydratorConstructor | undefined {
            const {
                adc: { appKind },
            } = ib;

            const targetTableName = getTableProperty(desc?.targetTable);
            if (targetTableName === undefined) return undefined;
            const targetTable = ib.adc.findTable(targetTableName);
            if (targetTable === undefined) return undefined;

            const getters = inflateGetters(ib, spec.properties, desc);
            const assignedColumnNames = getAssignedFormColumns(desc);
            const onSubmitDescription = getActionProperty(desc.onSubmitAction);
            const onSubmitIB = ib.makeInflationBackendForTables(
                makeContextTableTypes(makeInputOutputTables(targetTable)),
                MutatingScreenKind.FormScreen
            );
            const onSubmitHydrator =
                definedMap(onSubmitDescription, a => inflateActions(onSubmitIB, [a])) ?? WireActionResult.nothingToDo();

            function getFormValue(hb: WireRowComponentHydrationBackend, name: string): WireEditableValue<string> {
                return hb.getState(`input-${name}`, (x): x is string => typeof x === "string", "", true);
            }

            const columnAssignmentGetters = inflateFormColumnAssignmentsFromDescription(
                ib,
                desc,
                targetTable,
                new Set(assignedColumnNames.values())
            );

            const outputValueGetters: OutputValueGetters<WireRowComponentHydrationBackend> = [
                ...(columnAssignmentGetters ?? []),
                ...Array.from(assignedColumnNames).map(
                    // TODO: We're getting the form properties twice - here and below in `formProps`.
                    ([f, o]): [string, WireRowComponentValueGetter] => [
                        o,
                        (hb: WireRowComponentHydrationBackend) => getFormValue(hb, f).value,
                    ]
                ),
            ];

            if (outputValueGetters === undefined) return undefined;

            const componentEnricher = inflateComponentEnricher<WireComponent>(ib, desc);

            return makeSimpleWireRowComponentHydratorConstructor(hb => {
                // TODO: Obey the `hasDisplayedProperties` of the specs and
                // always display if it's `false`.
                let isValid = true;
                const formProps: [string, WireEditableValue<string>][] = Array.from(assignedColumnNames.keys()).map(
                    name => {
                        let ev = getFormValue(hb, name);
                        const preferredType = preferredTypeForProperty.get(name);
                        if (ev.value === "") {
                            isValid = false;
                        }
                        if (preferredType === "email-address") {
                            if (ev.value !== "" && !isValidEmailAddress(ev.value)) {
                                isValid = false;
                                ev = { ...ev, error: getLocalizedString("pleaseEnterValidEmail", appKind) };
                            }
                        }
                        return [name, ev];
                    }
                );
                // TODO: We should be adding this as an invisible row, but to
                // do that it'd have to be persistent between hydrations.  We
                // could use the `isFirstHydration` to make the row, add it as
                // invisible, and store its row ID as the state.  Or better,
                // we use the same mechanism the form container is using,
                // which would mean that we wouldn't even need to store the
                // state of the input components.
                const outputRow: LoadedRow = {
                    ...hydrateOutputValueGetters(hb, outputValueGetters),
                    $rowID: makeRowID(),
                    $isVisible: false,
                };
                const onSubmit = hydrateOnSubmitAction(onSubmitHydrator, () =>
                    hb.makeHydrationBackendForRow(outputRow, undefined, makeInputOutputTables(targetTable))
                );
                let submitAction: WireAction | undefined;
                if (isValid && onSubmit !== false) {
                    submitAction = {
                        token: hb.registerAction("submit", async (ab, handled) => {
                            // Clear the form first
                            for (const [, { onChangeToken }] of formProps) {
                                if (onChangeToken === undefined) continue;
                                ab.valueChanged(onChangeToken, "", ValueChangeSource.Internal);
                            }

                            const addResult = await ab.addRow(targetTableName, outputRow, undefined, true);
                            if (!addResult.ok) return WireActionResult.fromResult(addResult);
                            const newRow = addResult.result;

                            if (onSubmit === undefined) return WireActionResult.nondescriptSuccess();
                            const onSubmitAB = ab.makeActionBackendForOnSubmit(newRow);
                            return await onSubmitAB.invoke("submit form component", onSubmit, handled);
                        }),
                    };
                }
                return {
                    component: componentEnricher({
                        kind,
                        submitAction,
                        ...hydrateGetters<any>(getters, hb, "").result,
                        ...fromPairs(formProps),
                    }) as WireComponent,
                    isValid: true,
                };
            });
        }

        public convertToPage(): ComponentDescription | undefined {
            // Fluent components are Pages-only. We don't need to convert them.
            return undefined;
        }
    }

    return new Handler();
}

// TODO: Don't use `any`
export function makeFluentComponentHandler<TProps, TForm, TWire, TInternalState>(
    component: FluentComponent<TProps, TForm, TWire, TInternalState>,
    inflator?: ComponentInflator<PrettifyIntersection<FluentComponent<TProps, TForm, TWire, TInternalState>>>
): ComponentHandler<any> {
    const { spec } = component;

    if (spec.formProperties.propertySpecs.length === 0) {
        return makeComponentHandler(spec, inflator);
    } else {
        assert(inflator === undefined);
        return makeFormComponentHandler(spec);
    }
}
