import type { Subscription } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { defaultSubscriptionForOwner, isSubscription } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import type { UserDataAndState } from "@glide/common-core/dist/js/Database";
import { isUserData } from "@glide/common-core/dist/js/Database";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import { useQuery } from "@tanstack/react-query";

export async function getSubscription(ownerID: string): Promise<Subscription> {
    const response = await getAppFacilities().callAuthCloudFunction("getGlideSubscription", { ownerID });
    if (response === undefined || !response.ok) {
        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        void response?.text();
        throw new Error("Didn't receive a valid response!");
    }
    const sub = await response.json();
    if (!isSubscription(sub)) throw new Error("Response is not a subscription!");
    return sub;
}

export function useSubscription(
    ownerID: string | undefined,
    userData: UserDataAndState
): { isLoading: boolean; isFetching: boolean; isRefetching: boolean; refetch: () => void; subscription: Subscription } {
    const {
        isLoading,
        isFetching,
        refetch,
        isRefetching,
        data: subscription,
    } = useQuery(
        ["useSubscription", ownerID ?? "never happens"],
        async ({ queryKey: [, id] }) => {
            return await getSubscription(id);
        },
        {
            enabled: ownerID !== undefined && isUserData(userData) && ownerID !== "0",
            keepPreviousData: false,
            staleTime: Infinity,
            cacheTime: 1_000,
            refetchInterval: 60_000,
        }
    );
    return {
        isLoading,
        isFetching,
        isRefetching,
        refetch,
        subscription: subscription ?? defaultSubscriptionForOwner(ownerID ?? ""),
    };
}
