import type { WireSeparatorComponent } from "@glide/fluent-components/dist/js/fluent-components";
import "twin.macro";
import type { WireRenderer } from "../wire-renderer";
import { SeparatorSize } from "@glide/wire";

export const Separator: WireRenderer<WireSeparatorComponent> = p => {
    const { drawLine, size } = p;
    let s = 0;
    if (size === SeparatorSize.Medium) {
        s = 8;
    } else if (size === SeparatorSize.Large) {
        s = 24;
    }

    return <div tw="bg-border-base" style={{ marginTop: s, marginBottom: s, height: drawLine ? 1 : 0 }} />;
};
