import { styled } from "@glide/common";

interface HighlightTextAreaContainerProps {
    focused: boolean;
    showContextButton: boolean;
    font?: string;
    minHeight?: number;
    maxHeight?: number;
    alignTop?: boolean;
}

export const HighlightTextAreaContainer = styled.label<HighlightTextAreaContainerProps>`
    cursor: text;
    display: flex;
    align-items: ${p => (p.alignTop ? "flex-start" : "center")};
    min-height: ${p => p.minHeight ?? 32}px;
    max-height: ${p => (p.maxHeight !== undefined ? p.maxHeight + "px" : "auto")};
    padding: 7px ${p => (p.showContextButton ? 4 : 8)}px 7px 8px;
    background-color: ${p => p.theme.formControlBg};
    border: 1px solid ${p => (p.focused ? p.theme.dropdownButton.borderOpen : p.theme.dropdownButton.borderNormal)};
    border-radius: ${p => p.theme.formControlRadius};
    font-size: 13px;
    line-height: 16px;
    transition: background-color ${p => p.theme.transitionBase};
    overflow-y: auto;

    ${p => p.font !== undefined && `font-family: ${p.font};`}

    > :first-child {
        flex-grow: 1;
    }

    > .context-button {
        display: flex;
        gap: 0 4px;
        min-width: 24px;
        align-self: flex-end;
        justify-content: flex-end;
        cursor: pointer;
    }

    &:hover,
    &:focus {
        background-color: ${p => p.theme.formControlBgHover};
    }
`;

export const Error = styled.div<{ warningType?: "error" | "warn" }>`
    font-size: 12px;
    color: ${p => (p.warningType === "warn" ? p.theme.y500 : p.theme.destroyColor)};
    margin-top: 4px;
`;
