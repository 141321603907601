import { useVoiceRecorder } from "./use-voice-recorder";
import { useDetectSilence } from "./use-detect-silence";
import * as React from "react";
import { isDefined } from "@glide/support";
import type { VoiceRecorderControls } from "./types";

interface UseVoiceRecorderWithSilenceOptions {
    readonly onSilenceDetected: ((partialFile: File) => void) | undefined;
    readonly onRecordingComplete: (file: File) => void;
    readonly onError: (error: Error) => void;
}

export function useVoiceRecorderWithSilence(options: UseVoiceRecorderWithSilenceOptions): VoiceRecorderControls {
    const { onSilenceDetected, onRecordingComplete, onError } = options;

    const voiceRecorder = useVoiceRecorder({ onRecordingComplete, onError });

    const { getPartialRecording, state, startRecording, getStream } = voiceRecorder;

    const onSilenceDetectedImpl = React.useMemo(() => {
        if (!isDefined(onSilenceDetected)) {
            return undefined;
        }

        return () => {
            onSilenceDetected(getPartialRecording());
        };
    }, [getPartialRecording, onSilenceDetected]);

    const { setupAnalyser } = useDetectSilence({
        isRecording: state === "recording",
        onSilenceDetected: onSilenceDetectedImpl,
    });

    const startRecordingImpl = React.useCallback(async () => {
        await startRecording();
        const stream = getStream();
        if (isDefined(stream)) {
            setupAnalyser(stream);
        }
    }, [getStream, setupAnalyser, startRecording]);

    return {
        ...voiceRecorder,
        startRecording: startRecordingImpl,
    };
}
