interface BackoffControllerProbabilityBounds {
    minQuantile: number;
    maxQuantile: number;
}

// Why quadratic and not exponential? Because exponential backoff is unfair and unstable.
//
// Håstad, Leighton, and Rogoff proved in [1] that access channels using exponential backoff as
// their distributed coordination function exhibit instability at slightly above half the
// network carrying capacity as the network grows large. They also proved that any coordination
// function attempt ^ N is unstable for N <= 1 but always stable for N > 1.
//
// Sun and Dai separately proved in [2] that the second moment of access delay, or the expected value
// of (access delay)^2, diverged in exponential backoff but was always finite in quadratic backoff.
// A divergent second moment of access delay implies a divergent variance, which is an indicator
// of access unfairness. That the second moment of access delay is always finite for quadratic backoff
// indicates that it is asymptotically fair, and that access starvation becomes incredibly
// unlikely.
//
// One interesting aspect of [1] is that the delay model is based on Bernoulli trials over access
// probability. For a given coordination function f(attempts), the probability of actually performing
// an attempt is 1 / f(attempts). We are working in continuous time, and Bernoulli trials taken over
// infinitesmally changing continuous time are distributed according to the Exponential distribution,
// with a cumulative distribution function of
//
// CDF_exp(t) = 1 - e^(-rate * t)
//
// Where rate = 1 / f(attempts) in our case.
//
// We can thus simulate an infinite series of Bernoulli trials by asserting the value of CDF_exp
//
// p = CDF_exp(t) = 1 - e^(-rate * t)
//
// where p is uniformly distributed, and solving instead for t.
//
// 1 - p = e^(-rate * t)
// ln(1 - p) / ln(e ^ -rate) = -ln(1 - p) / rate = t
// t = -ln(1 - p) / rate = -ln(1 - p) * f(attempts)
//
// We are setting f(attempts) = initialWindow * attempts^2,
// Thus t = -ln(1 - p) * initialWindow * attempts^2.
//
// [1] "Analysis of Backoff Protocols for Multiple Access Channels"; Johan Håstad, Tom Leighton, and Brian Rogoff;
//     https://www.csc.kth.se/~johanh/ethernetanalysis.pdf
// [2] "Backoff Design for IEEE 802.11 DCF Networks: Fundamental Tradeoff and Design Criterion"; Xinghua Sun and Lin Dai;
//     https://www.ee.cityu.edu.hk/~lindai/poly.pdf
//
// [2] additionally specifies a function for deriving the optimal initial backoff window, using only the number
// of concurrent clients and the known amount of time a protocol spends in a "failed, holding" state as its inputs.
// Unfortunately, the function does not have a closed-form expression because of a dependency on the principal branch
// of the Lambert W Function, but we can derive that using Newton's method, as implemented below.

const defaultMaxQuantile = 0.99995;
export function getQuadraticBackoffWaitTime(
    initialWindow: number,
    attempts: number,
    bounds?: BackoffControllerProbabilityBounds
): number {
    attempts = Math.max(attempts, 1);
    let probabilityRoll = Math.random();
    if (bounds?.minQuantile !== undefined && probabilityRoll < bounds.minQuantile) {
        probabilityRoll = bounds.minQuantile;
    }
    if (bounds?.maxQuantile !== undefined && probabilityRoll > bounds.maxQuantile) {
        probabilityRoll = bounds.maxQuantile;
    } else if (bounds?.maxQuantile === undefined && probabilityRoll > defaultMaxQuantile) {
        probabilityRoll = defaultMaxQuantile;
    }
    // The quantile function of the exponential distribution is
    //
    // Q(p) = -Math.log(1 - p) / rate
    //
    // In our case rate = 1 / (initialWindow * attempts * attempts), so we just
    // multiply instead of divide.
    return -Math.log(1 - probabilityRoll) * initialWindow * attempts * attempts;
}

export class QuadraticBackoffController {
    private _attempts: number;
    constructor(public readonly initialWindow: number, initialAttempts: number = 0) {
        this._attempts = initialAttempts;
    }

    public get attempts(): number {
        return this._attempts;
    }

    public resetAttempts(): void {
        this._attempts = 0;
    }

    public getWaitTime(bounds?: BackoffControllerProbabilityBounds): number {
        this._attempts++;
        return getQuadraticBackoffWaitTime(this.initialWindow, this._attempts, bounds);
    }
}

// The optimal initial window size for maximum throughput in a shared access
// channel is defined in terms of pA*, the optimal value of the "undesired
// stable point" pA, which itself is the fixed point of the probability of
// successful access assuming that the channel can accept the request.
//
// pA* is defined as -(1 + 1/tf)*W0(-[e * (1 + 1/tf)]^-1), where tf is the
// "holding time" of failed access attempts. W0, the principal branch of the
// Lambert W Function, doesn't have a closed-form definition, and is instead
// defined as
//
// w = W0(z) => z = w*e^w => w*e^w - z = 0
// over a domain of (-1/e, Infinity].
//
// We are particularly interested in the much smaller domain (-1/e, 0]
// as our inputs (z) to W0 are always negative. W0(z) is monotonic increasing
// throughout the domain (-1/e, Infinity] with its root at zero, so shifting
// w*e^w by -z will shift the curve in the positive direction.
//
// The properties of w*e^w - z = 0 are sufficient to support convergent
// root derivation via Newton's method, so this is how we evaluate W0.
function newtonMethodInvLambertW0Step(wGuess: number, z: number): number {
    const eW = Math.exp(wGuess);
    const f = wGuess * eW - z;
    const fp = (wGuess + 1) * eW;

    return wGuess - f / fp;
}

const midpoint = -1 / (2 * Math.E);
function lambertW0(z: number, convergePoint: number) {
    let lastGuess = midpoint;
    let nextGuess = newtonMethodInvLambertW0Step(lastGuess, z);
    while (Math.abs(nextGuess - lastGuess) > convergePoint) {
        lastGuess = nextGuess;
        nextGuess = newtonMethodInvLambertW0Step(lastGuess, z);
    }
    return nextGuess;
}

// Once we have W0(-[e * (1 + 1/tf)]^-1), we effectively have pA*.
// The optimial initial backoff window for maximum throughput is then defined as
//
// Wm = (2 * n / ln(pA*) - 1) / [sum(0, K, i => pA* * (1 - pA*)^i * w(i)) + (1 - pA*)^K * w(K)]
//
// Where w(i) = (1 + i)^2 in the case of quadratic backoff, n is the number of concurrent clients,
// and K is the "cutoff phase" constant, which determines the maximum amount of retries a client
// will attempt before giving up.
//
// w(0) = 1 by definition, as we will always make at least one attempt to access the channel.

function probabilityAtPhase(pAStar: number, k: number): number {
    return Math.pow(1 - pAStar, k) * (k + 1) * (k + 1);
}

function sumToPhase(pAStar: number, k: number, epsilon: number): number {
    let ret = 0;
    // The probability at a phase rapidly falls below machine epsilon, to
    // the point that computing it isn't worth the effort. We'll break
    // when we fall below this epsilon, which won't have much in the way
    // of effect on our returned value.
    const minProbability = Math.pow(10, -epsilon);
    for (let i = 0; i < k; i++) {
        const prob = probabilityAtPhase(pAStar, i);
        ret += prob;
        if (prob < minProbability) break;
    }
    return pAStar * ret;
}

/**
 * Derives constant parameters for optimal initial quadratic backoff windows.
 *
 * This function performs iterative numerical methods to compute the returned
 * parameters. While the number of iterations is small (expected to be less
 * than 200), these parameters are expected to be static and can be cached
 * to reduce execution time. This caching, and actual implementation of the
 * optimal window function, is done in `optimalInitialWindowForHoldingTimeFunction`.
 *
 * Consumers should prefer that function instead of calling this one; this one
 * is only exposed for debugging purposes.
 *
 * @param holdingTime - The amount of time a connection attempt is expected to
 *   spend in a "failed" state before the client attempts to connect again.
 * @param cutoffPhase - The number of attempts a client will make to access
 *   a channel using quadratic backoff before giving up.
 * @param convergeFactor - A factor controlling the required precision of
 *   the underlying numerical methods. It is a positive integer to which
 *   1/10 is raised, i.e. the precision will be accurate within 10^convergeFactor.
 * @returns Parameters for use in the optimal initial window for quadratic backoff.
 */
export function parametersForOptimalInitialWindow(
    holdingTime: number,
    cutoffPhase: number = 70,
    convergeFactor: number = 8
): { pAStar: number; dividend: number } {
    // With a holding time of 1, pA* = 0.463922.
    // sumToPhase(0.463922, n) converges with 15 digits of decimal
    // precision after 70 iterations. Higher holding times imply higher
    // pA*, which converge faster than lower pA*. So 70 is as many iterations
    // as we'll ever need to do.
    //
    // We'll very likely end up doing fewer than 70 iterations because of how
    // we treat 2*convergeFactor as the probability epsilon, which allows
    // sumToPhase to break early when probabilities do not materially
    // contribute to the summation anymore.
    cutoffPhase = Math.min(cutoffPhase, 70);
    const onePInvHold = 1 + 1 / holdingTime;
    const pAStar = -onePInvHold * lambertW0(-1 / (Math.E * onePInvHold), Math.pow(10, -convergeFactor));
    const dividend = sumToPhase(pAStar, cutoffPhase, convergeFactor * 2) + probabilityAtPhase(pAStar, cutoffPhase);
    return { pAStar, dividend };
}

/**
 * Returns the optimal initial backoff window for quadratic backoff as a function
 * of concurrent clients.
 *
 * Note that the optimal initial backoff window depends on the number of
 * concurrent clients. This is why the return type is a function instead of
 * a number itself.
 *
 * The static parameters of this function are derived using `parametersForOptimalInitialWindow`.
 * The process of deriving these static parameters uses iterative numerical
 * methods, so there is a degree of overhead in constructing the mapping
 * function. Consumers should hold on to the returned function, as it can be
 * evaluated in constant time.
 *
 * @param holdingTime - The amount of time a connection attempt is expected to
 *   spend in a "failed" state before the client attempts to connect again.
 * @param cutoffPhase - The number of attempts a client will make to access
 *   a channel using quadratic backoff before giving up.
 * @returns A function mapping concurrent clients to the optimal initial backoff window.
 */
export function optimalInitialWindowForHoldingTimeFunction(
    holdingTime: number,
    cutoffPhase: number = 70
): (clients: number) => number {
    const { pAStar, dividend } = parametersForOptimalInitialWindow(holdingTime, cutoffPhase);
    const invDividend = 1 / dividend;
    const invlnPAStar = -1 / Math.log(pAStar);
    return clients => {
        return (2 * clients * invlnPAStar - 1) * invDividend;
    };
}
