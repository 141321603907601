import debounce from "lodash/debounce";
import * as React from "react";

import { withLabelAndByline } from "../../lib/with-label";
import type { HeightVariation } from "./input-label-style";
import { Input, InputWrapper, Postfix } from "./input-label-style";

type InputValue = string | number | readonly string[] | undefined;

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    halfSize?: boolean;
    warning?: React.ReactNode;
    highlightWarning?: boolean;
    lightBorder?: boolean;
    focusBorder?: boolean;
    postfix?: string;
    postfixPadding?: number;
    debounceUpdate?: boolean;
    heightVariation?: HeightVariation;
    onClickLabel?: () => void;
    onInput?: () => void;
    selectOnFocus?: boolean;
    setFocus?: boolean;
    borderStyle?: boolean;
    helpText?: string;
}

interface State {
    lastPropsValue: InputValue;
    debounceValue: InputValue;
}

class InputLabelImpl extends React.PureComponent<Props, State> {
    public state: State = { debounceValue: "", lastPropsValue: "" };

    private ref = React.createRef<HTMLInputElement>();
    private didFocus = false;

    public static getDerivedStateFromProps(props: Props, state: State) {
        if (props.debounceUpdate === true) {
            if (props.value !== state.lastPropsValue) {
                state.debounceValue = props.value;
            }
            state.lastPropsValue = props.value;
        }
        return state;
    }

    private sendUpdate = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.onChange !== undefined) {
            this.props.onChange(e);
        }
    }, 500);

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        this.sendUpdate(e);
        this.setState({ debounceValue: e.target.value });
    };

    public render() {
        const {
            label,
            children,
            warning,
            postfix,
            highlightWarning,
            lightBorder,
            focusBorder,
            value,
            onChange,
            disabled,
            debounceUpdate,
            halfSize,
            onClickLabel,
            onInput,
            postfixPadding,
            heightVariation,
            selectOnFocus,
            setFocus,
            borderStyle,
            ...rest
        } = this.props;

        let useValue = value;
        let useOnChange = onChange;
        if (debounceUpdate === true) {
            useValue = this.state.debounceValue;
            useOnChange = this.onChange;
        }

        // FIXME: This is an ugly hack.
        if (setFocus === true) {
            if (!this.didFocus) {
                // This is outside the `setTimeout` to preserve synch
                // behavior.  It's possible to set the focus while the input
                // is disabled, and then to lose another subsequent focus
                // because the timeout didn't fire yet.
                this.didFocus = true;
                setTimeout(() => {
                    this.ref.current?.focus();
                }, 0);
            }
        } else {
            this.didFocus = false;
        }

        return (
            <InputWrapper
                className="border"
                disabled={disabled}
                highlightWarning={highlightWarning && warning !== undefined}
                lightBorder={lightBorder}
                focusBorder={focusBorder}
                hasPostfix={postfix !== undefined}
                heightVariation={heightVariation}
                borderStyle={borderStyle}>
                <Input
                    ref={this.ref}
                    value={useValue}
                    onChange={useOnChange}
                    onFocus={(e: { target: { select: () => any } }) => selectOnFocus && e.target.select()}
                    disabled={disabled}
                    {...rest}
                />
                {postfix && (
                    <Postfix
                        className="postfix"
                        onClick={() => this.ref.current?.focus()}
                        style={{ paddingRight: postfixPadding ?? 0 }}>
                        {postfix}
                    </Postfix>
                )}
                {this.props.children}
            </InputWrapper>
        );
    }
}

const InputLabel = withLabelAndByline(
    InputLabelImpl,
    props => (props.halfSize ? "2by1" : "split"),
    props => (props.onClickLabel ? props.onClickLabel() : undefined)
);

export const InputLabelBase = InputLabelImpl;
export default InputLabel;
