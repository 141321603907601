import { styled } from "@glide/common";

export const GroupUIStyle = styled.div`
    &:has(.custom-ai-component-property-configurator) {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    .section-title-container {
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        color: ${p => p.theme.textDark};

        .section-title {
            flex-grow: 1;
        }

        .section-title.with-tooltip {
            display: flex;
            align-items: center;
        }

        .section-accessory {
            flex-grow: 10000;
            display: flex;
            justify-content: flex-end;
        }

        .pointer {
            cursor: pointer;
        }

        .secondary {
            color: ${p => p.theme.textXpale};
            transition: color ${p => p.theme.transitionBase};

            &:hover {
                color: ${p => p.theme.textDark};
            }
        }

        .section-title + .section-title {
            margin-left: 20px;
        }

        .caret {
            width: 18px;
            height: 24px;
            padding-left: 8px;
            color: ${p => p.theme.iconBase};
            transition: color ${p => p.theme.transitionBase};
        }

        &:hover .caret {
            color: ${p => p.theme.textDark};
        }

        .accessory-button {
            margin: -4px -4px 0 0;
            padding: 4px;
            border-radius: 8px;
            display: inline-flex;
            transition: all ${p => p.theme.transitionBase};
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: ${p => p.theme.n200A};
            }

            &:disabled {
                pointer-events: none;
                opacity: 0.33;
            }
        }

        .accessory-button.accent:not(:disabled) {
            margin: -4px -4px 0 0;
            padding: 4px;
            border-radius: 8px;
            color: ${p => p.theme.n0};
            background-color: ${p => p.theme.accentInverse};
            box-shadow: ${p => p.theme.shadow2};
            will-change: background-color, box-shadow;

            &:hover,
            &:focus {
                background-color: ${p => p.theme.n800};
                box-shadow: ${p => p.theme.shadowDark3};
            }
        }

        .accessory-button.close:not(:disabled) {
            margin: -4px -4px 0 0;
            padding: 4px;
            border-radius: 10rem;
            color: ${p => p.theme.iconBase};
            background-color: ${p => p.theme.n100A};
            will-change: background-color, color;

            &:hover,
            &:focus {
                background-color: ${p => p.theme.n200A};
                color: ${p => p.theme.iconDark};
            }
        }
    }

    &:has(.custom-ai-component-property-configurator) {
        .stretch + .content-container {
            flex: 1;
            margin-top: 4px;
            display: flex;
            flex-direction: column;

            .section-wrapper {
                flex: 0;
                display: flex;
                flex-direction: column;

                &:has(.custom-ai-component-property-configurator) {
                    flex: 1;
                }

                > *:last-child:not(.section-title-container) {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .custom-ai-component-property-configurator {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        > *:last-child {
                            flex: 1;
                            display: flex;
                            flex-direction: column;

                            > *:last-child {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
        }
    }

    .stretch + .content-container {
        margin-top: 4px;
    }

    .sep {
        width: calc(100% + 36px);
        height: 1px;
        margin: 8px -16px 8px -20px;
        background-color: ${p => p.theme.separatorColor};
    }
`;
