import styled from "styled-components";

export const ActivitySpinnerStyle = styled.div`
    position: fixed;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;

    margin: 0 !important;

    z-index: 1;

    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    & + & {
        display: none;
    }
`;
