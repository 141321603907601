import { GlideIcon } from "@glide/common";
import type { ComponentIndexes } from "@glide/common-core/dist/js/component-indexes";
import cx from "classnames";
import React from "react";
import tw from "twin.macro";
import { css } from "@glide/common-components";
import type { ComponentSelectorState, SelectedComponentPath } from "../../wire-lib";
import { GLIDE_NEW_COMPONENT_DATA_FORMAT } from "../../wire-lib";

interface EmptyStateProps {
    index: ComponentIndexes;
    componentSelector: ComponentSelectorState;
    setComponentSelector: (payload: ComponentSelectorState) => void;
    setSelectedComponent: (payload: SelectedComponentPath | undefined) => void;
}

export const WireEmptyState: React.VFC<EmptyStateProps> = p => {
    const { index, componentSelector, setComponentSelector, setSelectedComponent } = p;

    const isSelected =
        componentSelector.targetIndexes !== undefined &&
        index.toString() === componentSelector.targetIndexes?.toString();

    const unsetSelectedTarget = React.useCallback(
        (e: React.DragEvent) => {
            if (isSelected && e.clientX + e.clientY > 0) {
                setComponentSelector({ isOpen: componentSelector.isOpen, targetIndexes: undefined });
            }
        },
        [componentSelector.isOpen, isSelected, setComponentSelector]
    );

    const setSelectedTarget = React.useCallback(
        (e: React.DragEvent) => {
            if (!isSelected && e.dataTransfer.types.includes(GLIDE_NEW_COMPONENT_DATA_FORMAT)) {
                setSelectedComponent(undefined);

                setComponentSelector({
                    isOpen: true,
                    targetIndexes: index,
                });
            }
        },
        [index, isSelected, setComponentSelector, setSelectedComponent]
    );

    const openDrawer = React.useCallback(() => {
        if (componentSelector.isOpen && isSelected) {
            setComponentSelector({ isOpen: false, targetIndexes: undefined });
            return;
        }
        setSelectedComponent(undefined);

        setComponentSelector({
            isOpen: true,
            targetIndexes: index,
        });
    }, [componentSelector.isOpen, isSelected, setSelectedComponent, setComponentSelector, index]);

    return (
        <div
            onClick={openDrawer}
            onDragEnter={setSelectedTarget}
            onDragLeave={unsetSelectedTarget}
            tw="w-full items-center [max-width:1280px] [min-height:80px] h-full opacity-80 transition hover:opacity-100
                relative"
            className="list-group-item">
            {/* This thing is floating so I can play with opacity in the other element without messing with the icon */}
            <div tw="absolute inset-0 flex justify-center items-center text-text-contextual-dark">
                <GlideIcon className="plus" kind="stroke" icon="st-plus-stroke" iconSize={20} strokeWidth={1.5} />
            </div>
            <div
                tw="relative flex justify-center items-center cursor-pointer transition rounded-lg border border-dashed
                    border-text-contextual-base h-full opacity-20 hover:(opacity-25 bg-n300A)"
                className={cx({
                    active: isSelected,
                })}
                css={css`
                    &.active {
                        ${tw`opacity-25 border-b500 bg-b300`}
                    }
                `}
            />
        </div>
    );
};
