import { ConditionVariable } from "./condition-variable";

export class RecurrentBackgroundJob {
    private lastException: unknown;
    private ongoingPromise: Promise<void> | undefined;
    private ongoingPromiseCV = new ConditionVariable();
    private runAgain: boolean = false;

    constructor(private op: () => Promise<void>) {}

    private opLoop = async () => {
        while (this.runAgain) {
            this.runAgain = false;
            try {
                await this.op();
                this.lastException = undefined;
            } catch (e: unknown) {
                this.lastException = e;
            }
        }
        this.ongoingPromise = undefined;
        this.ongoingPromiseCV.notifyAll();
    };

    public request() {
        this.runAgain = true;
        if (this.ongoingPromise === undefined) {
            this.ongoingPromise = this.opLoop();
        }
    }

    public getLastException(): unknown {
        return this.lastException;
    }

    public async wait() {
        while (this.ongoingPromise !== undefined) {
            await this.ongoingPromiseCV.wait();
        }
        if (this.lastException !== undefined) {
            throw this.lastException;
        }
    }

    public isRunning(): boolean {
        return this.ongoingPromise !== undefined;
    }
}
