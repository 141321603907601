import { trackEvent } from "@glide/common-core/dist/js/analytics";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { PagePreviewDevice } from "@glide/common-core/dist/js/render/form-factor";
import { useAppID } from "@glide/common-core/dist/js/use-app-id";
import { AppKind } from "@glide/location-common";
import { createContext, useCallback, useContext, useMemo } from "react";
import { getLastKnownAppLogin } from "webapp/app-login";

type ChangePreviewerDevice = (deviceFormFactor: PagePreviewDevice) => void;
type ChangeIsScreenshooter = (value: boolean) => void;
interface PreviewerState {
    appKind: AppKind;
    previewDevice: PagePreviewDevice;
    onSetPreviewDevice: ChangePreviewerDevice;
    isScreenshooter: boolean;
    onSetIsScreenshooter: ChangeIsScreenshooter;
}

const defaultContext: PreviewerState = {
    appKind: AppKind.Page,
    previewDevice: PagePreviewDevice.Phone,
    onSetPreviewDevice: (_previewDevice: PagePreviewDevice) => {
        //
    },
    isScreenshooter: false,
    onSetIsScreenshooter: (_v: boolean) => {
        //
    },
};

export const initializeContext = (): PreviewerState => {
    const appLogin = getLastKnownAppLogin();

    if (appLogin !== undefined) {
        const { features } = appLogin;
        const appKind = getAppKindFromFeatures(features);
        defaultContext.appKind = appKind;
    }
    defaultContext.previewDevice = PagePreviewDevice.Phone;

    return defaultContext;
};

export const PreviewerStateContext = createContext<
    [PreviewerState, (update: ((prevState: PreviewerState) => PreviewerState) | PreviewerState) => void]
>([initializeContext(), s => s]);

export const usePreviewerState = (): PreviewerState => {
    const [state, setState] = useContext(PreviewerStateContext);
    const templateID = useAppID();

    const onSetPreviewDevice = useCallback<ChangePreviewerDevice>(
        previewDevice => {
            setState({ ...state, previewDevice });
            if (!state.isScreenshooter) {
                trackEvent("change device preview from template preview", {
                    template_id: templateID ?? "",
                    device: previewDevice,
                });
            }
        },
        [setState, state, templateID]
    );

    const onSetIsScreenshooter = useCallback<ChangeIsScreenshooter>(
        isScreenshooter => {
            setState({ ...state, isScreenshooter });
        },
        [state, setState]
    );

    const previewerState: PreviewerState = useMemo(
        () => ({
            ...state,
            onSetPreviewDevice,
            onSetIsScreenshooter,
        }),
        [onSetPreviewDevice, state, onSetIsScreenshooter]
    );

    return previewerState;
};
