import { builderFontStyles } from "./builder-font-styles.js";
import { coreTheme, darkCoreTheme } from "./core-theme.js";
import type { DeepPartial } from "utility-types";
import { mergeTheme } from "./utils.js";

// We use this to determine which kind of theme you were provided at runtime
export const builderThemeKind = "builderTheme" as const;

const builderThemeBase = {
    ...coreTheme,

    themeKind: builderThemeKind,
    overlayName: "LightTheme",
    accentPrimary: "_b400",
    accentPrimaryHover: "#6470FF", // for builder buttons hover
    accentSecondary: "#12CCE5",
    accentTertiary: "_n200A",
    accentInverse: "_n900",
    accentGradient: "_brandGradient",
    accentSuccess: "_g400",
    accentWarning: "_y400",
    accentDanger: "_r400",
    formControlBg: "_n200A",
    formControlBgHover: "_n300A",
    formControlBgDisabled: "_n100A",
    formControlAltBg: "_n0",
    formControlAltBgHover: "_n100A",
    formControlRadius: "8px",
    iconDuotone: {
        primary: "_n900",
        secondary: "_n600A",
        primarySelected: "_b500",
        secondarySelected: "_b400",
    },
    modals: {
        backgroundTint: "_n500A",
    },
    darkGrey: "_n700",
    layoutTint: "_b400",
    dataTint: "_g400",
    settingsTint: "_n700",
    blue: "_b400",
    blueDark: "_b500",
    blueTransparent: "_b100",
    bgGreen: "_g400",
    fgColorOpaque: "_n800",
    fgColorDarkest: "_n900",
    fgColorDark: "_n800A",
    fgColorMedium: "_n700A",
    fgColorLight: "_n600A",
    fgColorAccent: "_n0",
    bgColorLight: "_bgFront",
    bgColorLightTransparent: "_n100A",
    bgColorMedium: "_bgBack",
    bgColorMediumTransparent: "_n200A",
    bgColorAccent: "_n800",
    bgColorAccentTransparent: "_n800A",
    bgColorDark: "_n900",
    borderColor: "_borderBase",
    borderColorLight: "_borderPale",
    bgColorAlt: "_bgBack",
    bgColorAltLight: "_bgBack",
    acceptColor: "_accentPrimary",
    cancelColor: "_accentTertiary",
    destroyColor: "_accentDanger",
    errorColor: "_accentDanger",
    warningColor: "_accentWarning",
    white: "white",
    heavyFontWeight: "bold",
    ultraHeavyFontWeight: "900",
    headerFontSize: "40px",
    headerWeight: "bold",
    headerFontFamily:
        "Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Icons16, sans-serif",
    subheaderFontSize: "40px",
    subheaderWeight: "normal",
    subheaderFontFamily:
        "Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Icons16, sans-serif",
    labelFontWeight: "normal",
    labelFontSize: "16px",
    labelFontSizeSmall: "14px",
    labelFontFamily:
        "Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Icons16, sans-serif",
    iconShadow: "0px 3px 4px rgba(0, 0, 0, 0.08)",
    modalShadow: "0px 12px 32px rgba(0, 0, 0, 0.2)",
    linkColor: "_acceptColor",
    linkHoverConnectColor: "_acceptColor",
    hoverTransition: "0.1s",
    fontStyles: builderFontStyles,
    dashboard: {
        bgCard: "_bgFront",
        bgCardHover: "_bgFront",
        bgCardDragged: "rgba(255, 255, 255, 0.5)",
    },
    conditionContainer: {
        bgColor: "_bgColorLight",
        bgInner: "_bgColorAlt",
    },
    dropdownButton: {
        borderNormal: "_n0A",
        borderOpen: "_n0A",
        bgColor: "_formControlBg",
        bgHover: "_formControlBgHover",
        bgActive: "_formControlBgHover",
    },
    inlineDropdownButton: {
        borderNormal: "_fgColorLight",
        borderOpen: "#313139",
        bgColor: "_bgColorLight",
        bgActive: "_bgColorMedium",
    },
    dropdown: {
        iconFg: "white",
        bgSelected: "_bgColorMedium",
        bg: "_bgFront",
    },
    buttonTile: {
        newFg: "_acceptColor",
        newBg: "_blueTransparent",
        videoFg: "#ffffff",
        videoBg: "_bgGreen",
    },
    badge: {
        nonProBackground: "_bgGreen",
        proBackground: "_bgColorAccent",
    },
    button: {
        bgHover: "_bgColorMedium",
        fgAccept: "_fgColorAccent",
    },
    buttonOutline: {
        bgColor: "_n0",
        bgHover: "_n0",
        shadow: "_shadow1",
        shadowHover: "_shadowDark1",
    },
    scrollbar: {
        color: "_borderColor",
        thumb: "_fgColorLight",
    },
    dataViewer: {
        accentColor: "#4F5DFF",
        accentLight: "rgba(62, 116, 253, 0.1)",
        textDark: "_textBase",
        textMedium: "_textPale",
        textLight: "_textXpale",
        textBubble: "_textBase",
        bgIconHeader: "_n700",
        fgIconHeader: "_iconBase",
        textHeader: "_textBase",
        textGroupHeader: "_textXpale",
        textHeaderSelected: "_n0",
        bgCell: "_bgFront",
        bgCellMedium: "_n50",
        bgHeader: "_n100",
        bgHeaderHasFocus: "_n300",
        bgHeaderHovered: "_n100A",
        bgBubble: "_bgBack",
        bgBubbleSelected: "_bgFront",
        bgSearchResult: "#fff9e3",
        borderColor: "_borderColor",
        horizontalBorderColor: "_borderPale",
        drilldownBorder: "rgba(0, 0, 0, 0)",
        linkColor: "#4F5DFF",
    },
    leftPanelButton: {
        fgColor: "_fgColorMedium",
        fgActive: "_fgColorDark",
    },
    glideCircle: {
        bgColor: "#082129",
    },
    layoutEnum: {
        fgColor: "_bgFront",
        bgColor: "_b100",
    },
    dragItem: {
        bgColor: "transparent",
        bgColorDragging: "_fgColorAccent",
        bgTop: "transparent",
        bgBottom: "_bgColorMedium",
    },
    sectionTabBar: {
        bgColor: "_formControlBg",
        item: {
            fgColor: "_textPale",
            fgColorHover: "_textBase",
            fgColorSelected: "_textBase",
            bgColorHover: "_n100A",
            bgColorSelected: "_n0",
        },
    },
    enumPicker: {
        fgColor: "_fgColorDark",
        fgColorSelected: "_fgColorDark",
        fgColorHover: "_fgColorDark",
        bgColorSelected: "_blueTransparent",
        bgColorHover: "_bgColorMedium",
        iconColor: "_fgColorMedium",
        iconSelectedColor: "_acceptColor",
        iconColorHover: "_fgColorDark",
    },
    icon: {
        fgColor: "_fgColorAccent",
        bgColor: "_fgColorMedium",
        accentColor: "_fgColorLight",
    },
    componentIcon: {
        containerColor: "_bgColorAccent",
        fgColor: "_fgColorDarkest",
        bgColor: "_bgColorLight",
    },
    colorPicker: {
        warningBgColor: "_bgColorLight",
    },
    switchToggle: {
        background: "_n400",
    },
    upgradeModal: {
        lockColor: "_n900",
        border: "_n400",
        basic: {
            base: "_g400",
            gradientLighter: "rgba(0, 196, 83, 0.1)",
            gradientLightest: "rgba(0, 196, 83, 0.04)",
        },
        pro: {
            base: "_b400",
            gradientLighter: "rgba(79, 118, 255, 0.1)",
            gradientLightest: "rgba(79, 118, 255, 0.04)",
        },
        privatePro: {
            base: "_n900",
            gradientLighter: "rgba(7, 13, 16, 0.1)",
            gradientLightest: "rgba(7, 13, 16, 0.04)",
        },
        plans: {
            panelHover: "_bgMiddle",
        },
        planPreview: {
            background: "_n100",
        },
        promoCode: {
            background: "_n0",
        },
        planConfirm: {
            color: "_textPale",
        },
    },
};

export type BuilderTheme = typeof builderThemeBase;

type BuilderThemeOverlay = DeepPartial<BuilderTheme> & { [s: string]: string | {} } & { overlayName: string };

const darkThemeOverlay: BuilderThemeOverlay = {
    ...darkCoreTheme,

    themeKind: builderThemeKind,
    overlayName: "DarkTheme",
    iconDuotone: {
        primary: "_n900",
        secondary: "_n700A",
        primarySelected: "_b500",
        secondarySelected: "_b400",
    },
    modals: {
        backgroundTint: "rgba(0,0,0, 0.5)",
    },
    settingsTint: "#FFFFFF",
    fgColorOpaque: "_n800",
    fgColorDarkest: "_n900",
    fgColorDark: "_n800A",
    fgColorMedium: "_n700A",
    fgColorLight: "_n600A",
    fgColorAccent: "_n0",
    bgColorLight: "_bgFront",
    bgColorLightTransparent: "_n100A",
    bgColorMedium: "_bgBack",
    bgColorMediumTransparent: "_n200A",
    bgColorAccent: "_n800",
    bgColorAlt: "_bgBack",
    bgColorAltLight: "_bgBack",
    borderColor: "_borderBase",
    borderColorLight: "_borderPale",
    blue: "_accentPrimary",
    acceptColor: "_accentPrimary",
    cancelColor: "_accentTertiary",
    formControlAltBg: "_n200",
    bgColorLighter: "#3D3E47",
    bgColorLightest: "#4A4A56",
    dashboard: {
        bgCard: "_bgFront",
        bgCardHover: "_n400",
        bgCardDragged: "rgba(100, 100, 100, 0.5)",
    },
    conditionContainer: {
        bgColor: "_bgColorLight",
        bgInner: "_bgColorLighter",
    },
    glideCircle: {
        bgColor: "#000000",
    },
    button: {
        bgHover: "_bgColorLightest",
        fgAccept: "_fgColorDark",
    },
    buttonOutline: {
        bgColor: "_n200A",
        bgHover: "_n300A",
        shadow: "none",
        shadowHover: "none",
    },
    dropdownButton: {
        borderNormal: "_n0A",
        borderOpen: "_n0A",
        bgColor: "_formControlBg",
        bgActive: "_formControlBgHover",
    },
    inlineDropdownButton: {
        borderNormal: "_fgColorLight",
        borderOpen: "#313139",
        bgColor: "_bgColorLightest",
        bgActive: "_bgColorLightest",
    },
    dropdown: {
        iconFg: "_bgColorLighter",
        bgSelected: "_n100A",
        bg: "_n400",
    },
    dataViewer: {
        accentColor: "_b400",
        accentLight: "rgba(62, 116, 253, 0.1)",
        textDark: "_textDark",
        textMedium: "_textBase",
        textLight: "_textPale",
        textBubble: "_textDark",
        bgIconHeader: "_n700A",
        fgIconHeader: "_iconBase",
        textHeader: "_textBase",
        textGroupHeader: "_textXpale",
        textHeaderSelected: "_textDark",
        bgCell: "_bgFront",
        bgCellMedium: "_n50A",
        bgHeader: "_bgFronter",
        bgHeaderHasFocus: "_n300",
        bgHeaderHovered: "_n100A",
        bgBubble: "_bgColorLighter",
        bgBubbleSelected: "_bgFront",
        bgSearchResult: "#302003",
        borderColor: "_borderBase",
        horizontalBorderColor: "_borderXpale",
        drilldownBorder: "_borderPale",
        linkColor: "#4F5DFF",
    },
    scrollbar: {
        thumb: "#B2B2C0A6",
    },
    leftPanelButton: {
        fgColor: "#737383",
    },
    layoutEnum: {
        fgColor: "_bgFront",
        bgColor: "_bgBack",
    },
    dragItem: {
        bgColor: "_bgColorLighter",
        bgColorDragging: "_bgColorLightest",
        bgTop: "_bgColorLightest",
        bgBottom: "_bgColorLighter",
    },
    sectionTabBar: {
        bgColor: "_bgBack",
        item: {
            fgColor: "_textBase",
            fgColorHover: "_textDark",
            fgColorSelected: "_textDark",
            bgColorHover: "_n100A",
            bgColorSelected: "_bgFront",
        },
    },
    enumPicker: {
        fgColor: "_fgColorLight",
        fgColorSelected: "_fgColorDark",
        fgColorHover: "_fgColorLight",
        bgColorSelected: "_borderColor",
        bgColorHover: "#3D3E47",
        iconColor: "_iconPale",
        iconColorHover: "_iconBase",
        iconSelectedColor: "_iconDark",
    },
    icon: {
        fgColor: "_bgColorLight",
        bgColor: "_fgColorMedium",
        accentColor: "_bgColorLightest",
    },
    componentIcon: {
        containerColor: "_bgColorAccent",
        fgColor: "_fgColorDarkest",
        bgColor: "_bgColorLight",
    },
    colorPicker: {
        warningBgColor: "_bgColorAccent",
    },
    switchToggle: {
        background: "_n500",
    },
    upgradeModal: {
        lockColor: "_n300",
        border: "_borderPale",
        privatePro: {
            base: "_n0",
            gradientLighter: "rgba(255, 255, 255, 0.1)",
            gradientLightest: "rgba(255, 255, 255, 0.04)",
        },
        plans: {
            panelHover: "_n400",
        },
        planPreview: {
            background: "_n400",
        },
        promoCode: {
            background: "_n400",
        },
        planConfirm: {
            color: "_textBase",
        },
    },
};

export function getBuilderTheme(useDarkTheme: boolean): BuilderTheme {
    return mergeTheme(builderThemeBase, useDarkTheme ? [darkThemeOverlay] : []);
}
