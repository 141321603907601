let cloudRegion: string | undefined;

export function getCloudRegion(): string | undefined {
    return cloudRegion;
}

export function setCloudRegion(newRegion: string | undefined) {
    if (newRegion === undefined) return;
    cloudRegion = newRegion;
}
