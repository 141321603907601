import type { TableName } from "@glide/type-schema";
import type { RowIndex } from "@glide/computation-model-types";
import type { ActionMetadata, ColumnValues, WriteSourceType } from "@glide/common-core/dist/js/firebase-function-types";

export interface ActionMetadataWithAuthID extends ActionMetadata {
    readonly authID?: string;
}

export interface AddRowActionWithMetadataArguments extends ActionMetadataWithAuthID {
    readonly tableName: TableName;
    readonly columnValues: ColumnValues;
    readonly fromBuilder: boolean;
    readonly fromDataEditor: boolean;
    readonly writeSource: WriteSourceType | undefined;
}

export interface SetColumnsActionWithMetadataArguments extends ActionMetadataWithAuthID {
    readonly tableName: TableName;
    readonly columnValues: ColumnValues;
    readonly rowIndex: RowIndex;
    readonly fromBuilder: boolean;
    readonly fromDataEditor: boolean;
    readonly writeSource: WriteSourceType | undefined;
}

export interface DeleteRowActionWithMetadataArguments extends ActionMetadataWithAuthID {
    readonly tableName: TableName;
    readonly rowIndex: readonly RowIndex[];
    readonly fromBuilder: boolean;
    readonly fromDataEditor: boolean;
    readonly writeSource: WriteSourceType | undefined;
}

export interface AddRowQueueItem {
    readonly kind: "add-row";
    readonly data: AddRowActionWithMetadataArguments;
    readonly rowIndex: RowIndex | undefined;
}

export class EnqueueActionException extends Error {
    constructor(
        public readonly appID: string,
        public readonly kind: string,
        public readonly jobID: string,
        message: string,
        public readonly originalMessage: string | undefined
    ) {
        super(
            `${message} actions/${appID}/${kind}/${jobID}` +
                (originalMessage !== undefined ? `: ${originalMessage}` : "")
        );
    }
}
