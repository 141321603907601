/**
 * This is the static result of generateFontStyleFromSystem(fontSystem) in the main repo.
 *
 * This exists becaues we wanted to have like "themable fonts" but this hasn't changed in 4 years
 * so I think it's safe to assume it's not going to change anytime soon.
 *
 * In any case, this would not be a nice way of doing fonts,
 * so this is just for compatibility with existing components.
 * Nothing new should use these.
 */
export const builderFontStyles = {
    defaultFontFamily: "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
    Header1: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n                  font-size: ",
        "2.125",
        "rem;\n                  line-height: ",
        "2.3125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header1Fixed: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header1Height: 37,
    Header2: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n                  font-size: ",
        "1.5",
        "rem;\n                  line-height: ",
        "1.8125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header2Fixed: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header2Height: 29,
    Header3: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n                  font-size: ",
        "1.25",
        "rem;\n                  line-height: ",
        "1.5",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header3Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header3Height: 24,
    Header4: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n                  font-size: ",
        "1",
        "rem;\n                  line-height: ",
        "1.1875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header4Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header4Height: 19,
    Header5: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.875",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header5Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header5Height: 17,
    Header6: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.75",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header6Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header6Height: 17,
    Header7: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n                  font-size: ",
        "0.625",
        "rem;\n                  line-height: ",
        "0.875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header7Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Header7Height: 14,
    Subheader1: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n                  font-size: ",
        "2.125",
        "rem;\n                  line-height: ",
        "2.3125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader1Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader1Height: 37,
    Subheader2: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n                  font-size: ",
        "1.5",
        "rem;\n                  line-height: ",
        "1.8125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader2Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader2Height: 29,
    Subheader3: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n                  font-size: ",
        "1.25",
        "rem;\n                  line-height: ",
        "1.5",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader3Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader3Height: 24,
    Subheader4: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n                  font-size: ",
        "1",
        "rem;\n                  line-height: ",
        "1.1875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader4Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader4Height: 19,
    Subheader5: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.875",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader5Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader5Height: 17,
    Subheader6: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.75",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader6Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader6Height: 17,
    Subheader7: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n                  font-size: ",
        "0.625",
        "rem;\n                  line-height: ",
        "0.875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader7Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Subheader7Height: 14,
    Callout1: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n                  font-size: ",
        "2.125",
        "rem;\n                  line-height: ",
        "2.3125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout1Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout1Height: 37,
    Callout2: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n                  font-size: ",
        "1.5",
        "rem;\n                  line-height: ",
        "1.8125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout2Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout2Height: 29,
    Callout3: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n                  font-size: ",
        "1.25",
        "rem;\n                  line-height: ",
        "1.5",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout3Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout3Height: 24,
    Callout4: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n                  font-size: ",
        "1",
        "rem;\n                  line-height: ",
        "1.1875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout4Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout4Height: 19,
    Callout5: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.875",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout5Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout5Height: 17,
    Callout6: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.75",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout6Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout6Height: 17,
    Callout7: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n                  font-size: ",
        "0.625",
        "rem;\n                  line-height: ",
        "0.875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout7Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Callout7Height: 14,
    Body1: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n                  font-size: ",
        "2.125",
        "rem;\n                  line-height: ",
        "2.3125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body1Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body1Height: 37,
    Body2: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n                  font-size: ",
        "1.5",
        "rem;\n                  line-height: ",
        "1.8125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body2Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body2Height: 29,
    Body3: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n                  font-size: ",
        "1.25",
        "rem;\n                  line-height: ",
        "1.5",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body3Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body3Height: 24,
    Body4: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n                  font-size: ",
        "1",
        "rem;\n                  line-height: ",
        "1.1875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body4Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body4Height: 19,
    Body5: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.875",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body5Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body5Height: 17,
    Body6: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.75",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body6Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body6Height: 17,
    Body7: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n                  font-size: ",
        "0.625",
        "rem;\n                  line-height: ",
        "0.875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body7Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Body7Height: 14,
    AccentHeader1: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n                  font-size: ",
        "2.125",
        "rem;\n                  line-height: ",
        "2.3125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader1Fixed: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader1Height: 37,
    AccentHeader2: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n                  font-size: ",
        "1.5",
        "rem;\n                  line-height: ",
        "1.8125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader2Fixed: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader2Height: 29,
    AccentHeader3: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n                  font-size: ",
        "1.25",
        "rem;\n                  line-height: ",
        "1.5",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader3Fixed: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader3Height: 24,
    AccentHeader4: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n                  font-size: ",
        "1",
        "rem;\n                  line-height: ",
        "1.1875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader4Fixed: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader4Height: 19,
    AccentHeader5: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.875",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader5Fixed: [
        "\n        font-weight: ",
        "700",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader5Height: 17,
    AccentHeader6: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.75",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader6Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader6Height: 17,
    AccentHeader7: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n                  font-size: ",
        "0.625",
        "rem;\n                  line-height: ",
        "0.875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader7Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentHeader7Height: 14,
    AccentSubheader1: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n                  font-size: ",
        "2.125",
        "rem;\n                  line-height: ",
        "2.3125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader1Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader1Height: 37,
    AccentSubheader2: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n                  font-size: ",
        "1.5",
        "rem;\n                  line-height: ",
        "1.8125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader2Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader2Height: 29,
    AccentSubheader3: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n                  font-size: ",
        "1.25",
        "rem;\n                  line-height: ",
        "1.5",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader3Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader3Height: 24,
    AccentSubheader4: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n                  font-size: ",
        "1",
        "rem;\n                  line-height: ",
        "1.1875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader4Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader4Height: 19,
    AccentSubheader5: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.875",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader5Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader5Height: 17,
    AccentSubheader6: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.75",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader6Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader6Height: 17,
    AccentSubheader7: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n                  font-size: ",
        "0.625",
        "rem;\n                  line-height: ",
        "0.875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader7Fixed: [
        "\n        font-weight: ",
        "600",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentSubheader7Height: 14,
    AccentCallout1: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n                  font-size: ",
        "2.125",
        "rem;\n                  line-height: ",
        "2.3125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout1Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout1Height: 37,
    AccentCallout2: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n                  font-size: ",
        "1.5",
        "rem;\n                  line-height: ",
        "1.8125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout2Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout2Height: 29,
    AccentCallout3: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n                  font-size: ",
        "1.25",
        "rem;\n                  line-height: ",
        "1.5",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout3Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout3Height: 24,
    AccentCallout4: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n                  font-size: ",
        "1",
        "rem;\n                  line-height: ",
        "1.1875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout4Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout4Height: 19,
    AccentCallout5: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.875",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout5Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout5Height: 17,
    AccentCallout6: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.75",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout6Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout6Height: 17,
    AccentCallout7: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n                  font-size: ",
        "0.625",
        "rem;\n                  line-height: ",
        "0.875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout7Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentCallout7Height: 14,
    AccentBody1: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n                  font-size: ",
        "2.125",
        "rem;\n                  line-height: ",
        "2.3125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody1Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody1Height: 37,
    AccentBody2: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n                  font-size: ",
        "1.5",
        "rem;\n                  line-height: ",
        "1.8125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody2Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody2Height: 29,
    AccentBody3: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n                  font-size: ",
        "1.25",
        "rem;\n                  line-height: ",
        "1.5",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody3Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody3Height: 24,
    AccentBody4: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n                  font-size: ",
        "1",
        "rem;\n                  line-height: ",
        "1.1875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody4Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody4Height: 19,
    AccentBody5: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.875",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody5Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody5Height: 17,
    AccentBody6: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.75",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody6Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody6Height: 17,
    AccentBody7: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n                  font-size: ",
        "0.625",
        "rem;\n                  line-height: ",
        "0.875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody7Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    AccentBody7Height: 14,
    Tag1: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "34",
        "px;\n        ",
        "\n                  font-size: ",
        "2.125",
        "rem;\n                  line-height: ",
        "2.125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag1Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "34",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag1Height: 34,
    Tag2: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n                  font-size: ",
        "1.5",
        "rem;\n                  line-height: ",
        "1.5",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag2Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag2Height: 24,
    Tag3: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "20",
        "px;\n        ",
        "\n                  font-size: ",
        "1.25",
        "rem;\n                  line-height: ",
        "1.25",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag3Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "20",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag3Height: 20,
    Tag4: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "16",
        "px;\n        ",
        "\n                  font-size: ",
        "1",
        "rem;\n                  line-height: ",
        "1",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag4Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "16",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag4Height: 16,
    Tag5: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "15",
        "px;\n        line-height: ",
        "15",
        "px;\n        ",
        "\n                  font-size: ",
        "0.9375",
        "rem;\n                  line-height: ",
        "0.9375",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag5Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "15",
        "px;\n        line-height: ",
        "15",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag5Height: 15,
    Tag6: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "13",
        "px;\n        line-height: ",
        "13",
        "px;\n        ",
        "\n                  font-size: ",
        "0.8125",
        "rem;\n                  line-height: ",
        "0.8125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag6Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "13",
        "px;\n        line-height: ",
        "13",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag6Height: 13,
    Tag7: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "11",
        "px;\n        line-height: ",
        "11",
        "px;\n        ",
        "\n                  font-size: ",
        "0.6875",
        "rem;\n                  line-height: ",
        "0.6875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag7Fixed: [
        "\n        font-weight: ",
        "500",
        ";\n        font-size: ",
        "11",
        "px;\n        line-height: ",
        "11",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n            text-transform: lowercase;\n            font-variant: ",
        "all-small-caps",
        ";\n        ",
        "\n    ",
    ],
    Tag7Height: 11,
    Caption1: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n                  font-size: ",
        "2.125",
        "rem;\n                  line-height: ",
        "2.3125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption1Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "34",
        "px;\n        line-height: ",
        "37",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption1Height: 37,
    Caption2: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n                  font-size: ",
        "1.5",
        "rem;\n                  line-height: ",
        "1.8125",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption2Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "24",
        "px;\n        line-height: ",
        "29",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption2Height: 29,
    Caption3: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n                  font-size: ",
        "1.25",
        "rem;\n                  line-height: ",
        "1.5",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption3Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "20",
        "px;\n        line-height: ",
        "24",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption3Height: 24,
    Caption4: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n                  font-size: ",
        "1",
        "rem;\n                  line-height: ",
        "1.1875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption4Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "16",
        "px;\n        line-height: ",
        "19",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption4Height: 19,
    Caption5: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.875",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption5Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "14",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption5Height: 17,
    Caption6: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n                  font-size: ",
        "0.75",
        "rem;\n                  line-height: ",
        "1.0625",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption6Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "12",
        "px;\n        line-height: ",
        "17",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption6Height: 17,
    Caption7: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n                  font-size: ",
        "0.625",
        "rem;\n                  line-height: ",
        "0.875",
        "rem;\n              ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption7Fixed: [
        "\n        font-weight: ",
        "400",
        ";\n        font-size: ",
        "10",
        "px;\n        line-height: ",
        "14",
        "px;\n        ",
        "\n        font-family: ",
        "-apple-system, BlinkMacSystemFont, Roboto, sans-serif",
        ";\n        ",
        "\n    ",
    ],
    Caption7Height: 14,
};
