import * as glide from "@glide/plugins";
import { isEmptyOrUndefined } from "@glide/support";

const apiKey = glide.makeParameter({
    type: "secret",
    name: "API Key",
    description: "Your ElevenLabs API key",
    required: true,
});

export const plugin = glide.newPlugin({
    id: "elevenlabs",
    name: "ElevenLabs",
    description: "Generate realistic text-to-speech audio using ElevenLabs AI",
    icon: "https://res.cloudinary.com/glide/image/upload/v1741682976/glideapps.com/integrations/ElevenLabs.png",
    documentationUrl: "https://www.glideapps.com/docs/elevenlabs",
    tier: "starter",
    parameters: { apiKey },
});

const inputText = glide.makeParameter({
    type: "string",
    name: "Text",
    description: "The text to convert to speech",
    required: true,
    multiLine: true,
    useTemplate: "withLabel",
});

interface Voice {
    voice_id: string;
    name: string;
}

const inputVoiceId = plugin.makeAsyncParameter({
    key: "voice-id",
    name: "Voice",
    defaultDisplayLabel: "Select a voice",
    description: "The voice to use for speech synthesis",
    defaultValue: "21m00Tcm4TlvDq8ikWAM",
    emptyByDefault: true,
    values: async (ctx, { apiKey: apiKeyValue }) => {
        const headers: Record<string, string> = {
            Accept: "application/json",
        };

        if (!isEmptyOrUndefined(apiKeyValue)) {
            headers["xi-api-key"] = apiKeyValue;
        }

        const response = await ctx.fetch("https://api.elevenlabs.io/v1/voices", {
            method: "GET",
            headers,
        });

        if (!response.ok) return [];

        const data = await response.json();
        return data.voices
            .sort((a: Voice, b: Voice) => a.name.localeCompare(b.name))
            .map((voice: Voice) => ({
                value: voice.voice_id,
                label: voice.name,
            }));
    },
});

const AUDIO_MIME_TYPE = "audio/mpeg";

const ADVANCED_SECTION = {
    name: "Advanced",
    order: 10,
    collapsed: true,
} as const;

const modelId = glide.makeParameter({
    type: "string",
    name: "Model ID",
    description: "The ID of the model to use for synthesis (default: eleven_monolingual_v1)",
    required: false,
    propertySection: ADVANCED_SECTION,
});

const languageCode = glide.makeParameter({
    type: "string",
    name: "Language Code",
    description: "The language code for the input text (e.g., en, es, fr)",
    required: false,
    propertySection: ADVANCED_SECTION,
});

const previousText = glide.makeParameter({
    type: "string",
    name: "Previous Text",
    description: "Text that immediately precedes the input text for better context",
    required: false,
    multiLine: true,
    propertySection: ADVANCED_SECTION,
});

const nextText = glide.makeParameter({
    type: "string",
    name: "Next Text",
    description: "Text that immediately follows the input text for better context",
    required: false,
    multiLine: true,
    propertySection: ADVANCED_SECTION,
});

const audioUrl = glide.makeParameter({
    type: "url",
    name: "Audio URL",
    description: "The URL of the generated audio file",
});

const removeUndefined = <T extends Record<string, string | undefined>>(obj: T): Partial<T> => {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => !isEmptyOrUndefined(value))) as Partial<T>;
};

plugin.addAction({
    id: "text-to-speech",
    name: "Text to Speech",
    description: "Convert text to realistic speech using ElevenLabs AI",
    icon: { kind: "monotone", icon: "mt-column-audio" },
    keywords: ["audio", "voice", "speech", "tts", "text-to-speech", "elevenlabs", "ai"],
    billablesConsumed: 2,
    parameters: {
        text: inputText,
        voiceId: inputVoiceId,
        modelId,
        languageCode,
        previousText,
        nextText,
    },
    results: { audioUrl },
    execute: async (
        context,
        {
            apiKey: apiKeyValue,
            text,
            voiceId = "21m00Tcm4TlvDq8ikWAM",
            modelId: modelIdValue = "eleven_monolingual_v1",
            languageCode: languageCodeValue,
            previousText: previousTextValue,
            nextText: nextTextValue,
        }
    ) => {
        if (isEmptyOrUndefined(text)) {
            return glide.Result.FailPermanent("Text is required", {
                isPluginError: false,
            });
        }

        if (isEmptyOrUndefined(apiKeyValue)) {
            return glide.Result.FailPermanent("API key is required", {
                isPluginError: false,
            });
        }

        const response = await context.fetch(`https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`, {
            method: "POST",
            headers: {
                Accept: AUDIO_MIME_TYPE,
                "Content-Type": "application/json",
                "xi-api-key": apiKeyValue,
            },
            body: JSON.stringify(
                removeUndefined({
                    text,
                    model_id: modelIdValue,
                    language_code: languageCodeValue,
                    previous_text: previousTextValue,
                    next_text: nextTextValue,
                })
            ),
        });

        if (!response.ok) {
            const errorText = await response.text();
            return glide.Result.FailFromHTTPStatus(`ElevenLabs API error: ${errorText}`, response.status);
        }

        const audioBlob = await response.blob();
        const buffer = await audioBlob.arrayBuffer();

        // Upload the audio file to Glide's storage
        const uploadResponse = await context.uploadFile("speech.mp3", AUDIO_MIME_TYPE, buffer);

        if (!uploadResponse.ok) {
            return uploadResponse;
        }

        context.consumeBillable();
        return glide.Result.Ok({
            audioUrl: uploadResponse.result,
        });
    },
});
