/* eslint-disable @typescript-eslint/no-shadow */

import * as glide from "@glide/plugins";

import { isEmptyOrUndefined } from "@glide/support";
import { GlideAI } from "./glide-ai-core";

const textGenerationModel = {
    id: "gpt-4o-mini",
    name: "Default",
    description: "Mini is the cheapest model, but it has a smaller context window and is less up to date than GPT-4o.",
    promptTokensPerUpdate: 10_000,
    completionTokensPerUpdate: 10_000,
};

const advancedReasoningModel = {
    id: "o1-mini",
    name: "Default",
    description: "o1-mini is an advanced reasoning model tailored to coding, math, and science use cases.",
    tokensPerUpdate: 500,
};

const DefaultAIColors = {
    fgColor: "var(--gv-icon-base)",
    bgColor: "var(--gv-pink500)",
};

const genTextCostDescription = `**10k** tokens.`;
const advancedReasoningCostDescription = `**${advancedReasoningModel.tokensPerUpdate.toLocaleString()}** tokens.`;
export function init(plugin: glide.NativePlugin<glide.ParameterRecord>) {
    plugin.addComputation({
        id: "complete-prompt-v3",
        name: "Generate Text",
        description: "Generate text based on a prompt.",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["complete", "prompt", "completion", "openai", "ai"],
        billablesConsumed: { number: 1, per: genTextCostDescription },
        icon: { kind: "monotone", icon: "mt-column-generate-text", ...DefaultAIColors },
        parameters: {
            instructions: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "Add instructions to guide the AI.",
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
        },
        fetchBehavior: { headerTimeout: 300_000 },
        results: {
            result: glide.makeParameter({ type: "string", name: "Result" }),
        },
        execute: async (context, { message, instructions: instructions = "" }) => {
            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const ai = new GlideAI(apiKey, context.fetch);
            const { id, promptTokensPerUpdate, completionTokensPerUpdate } = textGenerationModel;
            const cachedCompletion = await context.useCache(async () => {
                const completion = await ai.generateText(instructions, message, id);
                if (!completion.ok) return completion;

                const updatesUsed = Math.ceil(
                    completion.result.prompt_tokens / promptTokensPerUpdate +
                        completion.result.completion_tokens / completionTokensPerUpdate
                );
                context.consumeBillable(updatesUsed);
                context.trackMetric("tokensUsed", completion.result.total_tokens);

                return completion;
            }, [message, instructions, promptTokensPerUpdate, completionTokensPerUpdate]);

            if (cachedCompletion.ok === false) {
                return cachedCompletion;
            }

            const { result } = cachedCompletion;
            return glide.Result.Ok({
                result: result.value,
            });
        },
    });

    plugin.addAction({
        id: "advanced-reasoning",
        name: "Advanced Reasoning",
        description:
            "Use AI for advanced reasoning tasks with the o1-mini model tailored to coding, math, and science use cases.",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["advanced", "reasoning", "o1-mini", "ai"],
        billablesConsumed: { number: 1, per: advancedReasoningCostDescription },
        tier: glide.makeTierList("enterprise"),
        icon: { kind: "monotone", icon: "mt-column-generate-text", ...DefaultAIColors },
        parameters: {
            instructions: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "Add instructions to guide the AI.",
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
        },
        fetchBehavior: { headerTimeout: 300_000 },
        results: {
            result: glide.makeParameter({ type: "string", name: "Result" }),
        },
        execute: async (context, { instructions: instructions }) => {
            if (isEmptyOrUndefined(instructions)) {
                return glide.Result.FailPermanent(`Please provide instructions`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const ai = new GlideAI(apiKey, context.fetch);
            const { id, tokensPerUpdate } = advancedReasoningModel;
            const cachedCompletion = await context.useCache(async () => {
                const completion = await ai.generateText(instructions, id);
                if (!completion.ok) return completion;

                const updatesUsed = Math.ceil(completion.result.total_tokens / tokensPerUpdate);
                context.consumeBillable(updatesUsed);
                context.trackMetric("tokensUsed", completion.result.total_tokens);

                return completion;
            }, [instructions]);

            if (!cachedCompletion.ok) return cachedCompletion;
            return glide.Result.Ok({ result: cachedCompletion.result.value });
        },
    });
}
