import { GlideIcon } from "@glide/common";
import { isEmptyOrUndefined } from "@glide/support";
import * as React from "react";

import { Tooltip } from "@glide/common-components";
import type { HeightVariation } from "./glide-dropdown-button-style";
import {
    DropdownLabel,
    ErrorMessage,
    IconWrapper,
    TranformableIconWrapper,
    Wrapper,
} from "./glide-dropdown-button-style";
import type { NameAndIcon } from "./multi-part-value";
import { MultiPartValue, RenderIcon } from "./multi-part-value";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    isOpen: boolean;
    value: NameAndIcon[];
    // FIXME: Remove this and just have `warnType`
    warn?: boolean;
    warnString?: string;
    warnType?: "error" | "warn";
    label?: string;
    icon?: React.ReactNode | string;
    drawBorder?: boolean;
    slim?: boolean;
    isEnabled?: boolean;
    isInline?: boolean;
    heightVariation?: HeightVariation;
    showSpinner?: boolean;
}

export const GlideDropdownButton: React.VFC<Props> = p => {
    const {
        label,
        value,
        isOpen,
        icon,
        warn,
        drawBorder,
        onClick,
        slim,
        warnString,
        isEnabled,
        warnType,
        isInline,
        onMouseEnter,
        onMouseLeave,
        heightVariation,
        showSpinner,
        ...rest
    } = p;

    const onClickInner = React.useCallback(
        (evnt: React.MouseEvent<HTMLButtonElement>) => {
            evnt.stopPropagation();
            onClick?.(evnt);
        },
        [onClick]
    );

    const [hovered, setHovered] = React.useState(false);
    const onMouseEnterInner = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            onMouseEnter?.(e);
            setHovered(true);
        },
        [onMouseEnter]
    );

    const onMouseLeaveInner = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            onMouseLeave?.(e);
            setHovered(false);
            setShowTooltip(false);
        },
        [onMouseLeave]
    );

    const [showTooltip, setShowTooltip] = React.useState(false);
    React.useEffect(() => {
        if (hovered) {
            const h = window.setTimeout(() => setShowTooltip(true), 1500);
            return () => window.clearTimeout(h);
        }
        return undefined;
    }, [hovered]);

    const ref = React.useRef<HTMLDivElement>(null);

    const tooltipContent = value.map(i => i.name).join(" → ");

    return (
        <div ref={ref} className={warnType}>
            <Wrapper
                data-testid="glide-dropdown-button"
                drawBorder={drawBorder !== false}
                isOpen={isOpen}
                slim={slim === true}
                warn={warn === true}
                isEnabled={isEnabled !== false}
                isInline={isInline === true}
                heightVariation={heightVariation ?? "medium"}
                onMouseEnter={onMouseEnterInner}
                onMouseLeave={onMouseLeaveInner}
                {...rest}
                onClick={onClickInner}>
                {!isEmptyOrUndefined(label) && <DropdownLabel>{label}</DropdownLabel>}
                {icon !== undefined && (
                    <IconWrapper heightVariation={heightVariation ?? "medium"} isOpen={isOpen}>
                        <RenderIcon icon={icon} />
                    </IconWrapper>
                )}
                <MultiPartValue values={value} heightVariation={heightVariation ?? "medium"} isOpen={isOpen} />
                {showSpinner === true && (
                    <TranformableIconWrapper heightVariation={heightVariation}>
                        <GlideIcon kind="stroke" icon={"st-half-spinner"} iconSize={20} spin={true} tw="m-auto" />
                    </TranformableIconWrapper>
                )}
                <TranformableIconWrapper heightVariation={heightVariation}>
                    <GlideIcon kind="stroke" icon="st-caret" iconSize={16} strokeWidth={1.5} />
                </TranformableIconWrapper>
            </Wrapper>
            {tooltipContent !== "" && (
                <Tooltip target={ref} position="top" show={showTooltip}>
                    {tooltipContent}
                </Tooltip>
            )}
            {warnString !== undefined && <ErrorMessage>{warnString}</ErrorMessage>}
        </div>
    );
};
