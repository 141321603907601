// FIXME: This likely should not be in app/src/common.
import type { IDBPObjectStore, StoreNames } from "idb";
import { logError, logInfo } from "@glide/support";
import type { WritableValue } from "@glide/computation-model-types";
import type { TableName } from "@glide/type-schema";
import { getFeatureFlag } from "@glide/common-core/dist/js/feature-flags";
import { IndexedDBContext } from "@glide/indexeddb";

function nameLocalDatastore(appID: string): string {
    return `glide-local-data-cache-${appID}`;
}

interface TableMetadataSchema {
    readonly tableName: string;
    readonly itemCount: number;
}

interface TableEntrySchema {
    readonly tableName: string;
    readonly rowID?: number;
    readonly columns: Record<string, WritableValue>;
}

type CacheDBSchema = {
    tableMetadata: {
        key: string;
        value: TableMetadataSchema;
    };
    tableStore: {
        key: number;
        value: TableEntrySchema;
        indexes: {
            tableName: string;
        };
    };
};

function shouldInjectFaults(): boolean {
    return getFeatureFlag("injectLocalDatastorePersistenceFaults");
}

export class DatastoreIndexedDBCache {
    private indexedDBContext: IndexedDBContext<CacheDBSchema>;

    constructor(public appID: string) {
        this.indexedDBContext = new IndexedDBContext({
            name: nameLocalDatastore(appID),
            version: 2,
            onUpgrade: async (db, oldVersion) => {
                if (oldVersion === 1) {
                    db.deleteObjectStore("tableStore");
                }
                const existingNames = db.objectStoreNames;
                if (!existingNames.contains("tableMetadata")) {
                    db.createObjectStore("tableMetadata", { keyPath: "tableName" });
                }
                if (!existingNames.contains("tableStore")) {
                    const store = db.createObjectStore("tableStore", { keyPath: "rowID", autoIncrement: true });
                    store.createIndex("tableName", "tableName");
                }
            },
            injectFaults: shouldInjectFaults(),
        });
    }

    private checkDatabaseAvailability() {
        return this.indexedDBContext.checkAvailability();
    }

    private async dropIndexedDB(): Promise<void> {
        try {
            this.indexedDBContext.abandonCurrentConnection(async () => {
                try {
                    const req = indexedDB.deleteDatabase(this.indexedDBContext.name);
                    await new Promise<void>(resolve => {
                        req.onsuccess = () => resolve();
                        req.onerror = () => resolve();
                    });
                } catch (e: unknown) {
                    logError("Dropping Indexed DB", e);
                }
            });
        } catch (e: unknown) {
            logError("Abandoning connection to drop Indexed DB", e);
        }
    }

    private async withStores<R>(
        fn: (stores: {
            metadata: IDBPObjectStore<CacheDBSchema, StoreNames<CacheDBSchema>[], "tableMetadata">;
            store: IDBPObjectStore<CacheDBSchema, StoreNames<CacheDBSchema>[], "tableStore">;
        }) => Promise<R>,
        readWrite: boolean = true
    ): Promise<R> {
        let triedRecreateStoresFix = false;
        while (true) {
            try {
                return await this.indexedDBContext.runTransaction(
                    ["tableMetadata", "tableStore"],
                    readWrite,
                    async txn => {
                        const metadata = txn.objectStore("tableMetadata");
                        const store = txn.objectStore("tableStore");
                        return await fn({ metadata, store });
                    }
                );
            } catch (e: unknown) {
                // FIXME: Should probably use isMissingObjectStoreError here, but import is giving TS errors
                if (e instanceof DOMException && e.name === "NotFoundError" && !triedRecreateStoresFix) {
                    triedRecreateStoresFix = true;
                    await this.dropIndexedDB();
                    continue;
                }
                throw e;
            }
        }
    }

    private injectFaultWithProbability(
        store: IDBPObjectStore<CacheDBSchema, StoreNames<CacheDBSchema>[], "tableMetadata">,
        probability: number = 0.25
    ) {
        if (shouldInjectFaults() && Math.random() < probability) {
            store.transaction.abort();
        }
    }

    public async restore(tableName: TableName): Promise<{ columns: Record<string, WritableValue>; rowID?: number }[]> {
        if (!(await this.checkDatabaseAvailability())) return [];

        return await this.withStores(async ({ metadata, store }) => {
            this.injectFaultWithProbability(metadata);
            const expectedCount = await metadata.get(tableName.name);
            if (expectedCount === undefined) return [];

            this.injectFaultWithProbability(metadata);
            const allItems = await store.index("tableName").getAll(tableName.name);

            this.injectFaultWithProbability(metadata, 0.25);
            if (allItems.length < expectedCount.itemCount) {
                const difference = expectedCount.itemCount - allItems.length;
                const plural = difference === 1 ? `item` : `items`;
                logError(`${difference} ${plural} went missing from local cache for ${tableName.name}`);
                for (const { rowID } of allItems) {
                    if (rowID !== undefined) {
                        await store.delete(rowID);
                    }
                }
                return [];
            }

            return allItems;
        }, false);
    }

    public async persist(
        tableName: TableName,
        columns: Readonly<Record<string, WritableValue>>
    ): Promise<number | undefined> {
        if (!(await this.checkDatabaseAvailability())) return;

        try {
            return await this.withStores(async ({ metadata, store }) => {
                this.injectFaultWithProbability(metadata);
                const nextID = await store.put({ tableName: tableName.name, columns });

                this.injectFaultWithProbability(metadata, 0.15);
                const prior = await metadata.get(tableName.name);

                this.injectFaultWithProbability(metadata, 0.15);
                await metadata.put({
                    tableName: tableName.name,
                    itemCount: prior === undefined ? 1 : prior.itemCount + 1,
                });
                return nextID;
            });
        } catch (e: unknown) {
            logInfo(`Could not persist locally`, e);
            return undefined;
        }
    }

    public async remove(tableName: TableName, rowID: number): Promise<void> {
        if (!(await this.checkDatabaseAvailability())) return;

        try {
            return await this.withStores(async ({ metadata, store }) => {
                this.injectFaultWithProbability(metadata);
                const expectedCount = await metadata.get(tableName.name);
                if (expectedCount === undefined) return;

                await metadata.put({ ...expectedCount, itemCount: expectedCount.itemCount - 1 });
                this.injectFaultWithProbability(metadata);

                await store.delete(rowID);
                this.injectFaultWithProbability(metadata);
            });
        } catch (e: unknown) {
            logInfo(`Could not remove locally`, e);
        }
    }
}
