import { type ActionDescription, type PropertyDescription, ActionKind } from "@glide/app-description";
import {
    type TableGlideType,
    getTableName,
    sheetNameForTable,
    isBigTableOrExternal,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type AppDescriptionContext,
    PropertySection,
    TablePropertyHandler,
    type ActionAvailability,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import type { StaticActionContext } from "../static-context";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { type DescriptionToken, actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";

interface ReloadQueryActionDescription extends ActionDescription {
    readonly kind: ActionKind.ReloadQuery;
    readonly tableName: PropertyDescription | undefined;
}

const tablePropertyHandler = new TablePropertyHandler(
    "tableName",
    "Table",
    true,
    (_tableDesc, schema) => schema.schema.tables.filter(isBigTableOrExternal),
    PropertySection.Data
);

function getTable(schema: SchemaInspector, desc: ReloadQueryActionDescription): TableGlideType | undefined {
    const tableName = tablePropertyHandler.getTableName(desc);
    if (tableName === undefined) return undefined;
    const table = schema.findTable(tableName);
    if (table === undefined || !isBigTableOrExternal(table)) return undefined;
    return table;
}

export class ReloadQueryActionHandler extends BaseActionHandler<ReloadQueryActionDescription> {
    public readonly kind = ActionKind.ReloadQuery;
    public readonly iconName = "refresh-cw";
    public readonly name: string = "Reload query";
    // We don't support "Reload query" in Automations because it would require
    // recomputing.
    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getIsIdempotent(): boolean {
        return true;
    }

    public getDescriptor(
        _desc: ReloadQueryActionDescription | undefined,
        { context: ccc }: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Data,
            groupItemOrder: 6,
            isLegacy: ccc.appKind !== AppKind.Page || !ccc.schema.tables.some(isBigTableOrExternal),
            needsScreenContext: false,
            properties: [tablePropertyHandler],
        };
    }

    public getTokenizedDescription(
        desc: ReloadQueryActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const table = getTable(env.context, desc);
        if (table === undefined) return undefined;
        return [
            {
                kind: "string",
                value: "table ",
            },
            {
                kind: "column",
                value: sheetNameForTable(table),
            },
        ];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: ReloadQueryActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const table = getTable(ib.adc, desc);
        if (table === undefined) return arb.inflationError("Table not found");
        return () => {
            return async ab => {
                const result = ab.resetQuery(getTableName(table));
                return arb.addData({ tableName: sheetNameForTable(table) }).fromResult(result);
            };
        };
    }
}
