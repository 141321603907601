import * as glide from "@glide/plugins";

export const plugin = glide.newPlugin({
    id: "zendesk",
    name: "Zendesk",
    description: "Add Zendesk Chat to your app",
    tier: "starter",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/zendesk.png",
    parameters: {
        key: glide.makeParameter({
            name: "Key",
            type: "string",
            description: "Your key can be found in Zendesk's Web Widget code snippet.",
            required: true,
        }),
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/zendesk",
});

plugin.addHeader(
    ({ key }) => `<script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=${key}"> </script>`
);
