import { getLocalizedString } from "@glide/localization";
import {
    isFavoritedColumnName,
    type TableAndColumn,
    getTableColumn,
    getTableName,
    makeSourceColumn,
    SourceColumnKind,
} from "@glide/type-schema";
import type { ActionDescription } from "@glide/app-description";
import {
    ActionKind,
    ArrayTransformKind,
    makeActionProperty,
    makeArrayProperty,
    makeEnumProperty,
    makeIconProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    UnaryPredicateCompositeOperator,
    type ComponentDescription,
    type ComponentKind,
    type MutatingScreenKind,
    type PropertyDescription,
} from "@glide/app-description";
import {
    ContainerPadding,
    ContainerWidth,
    type InputOutputTables,
    doesMutatingScreenAddRows,
    makeEmptyComponentDescription,
} from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { type WireToggleComponent, WireToggleKind } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type EditedColumnsAndTables,
    PropertySection,
    makeTextPropertyDescriptor,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import {
    type WireRowComponentHydratorConstructor,
    WireComponentKind,
    type WireInflationBackend,
    UIBackgroundStyle,
} from "@glide/wire";

import {
    inflateFavorite,
    inflateStringProperty,
    makeSimpleWireRowComponentHydratorConstructor,
    spreadComponentID,
} from "../wire/utils";
import { makePrimaryKeyPropertyHandler } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";
import type { WireContainerComponentDescription } from "@glide/fluent-components/dist/js/fluent-components-containers";
import type { WireActionRowDescription } from "@glide/fluent-components/dist/js/fluent-components";
import { makeUnaryPredicateFormula } from "@glide/formula-specifications";

export const ComponentKindFavorite: ComponentKind = "favorite";

interface FavoriteComponentDescription extends ComponentDescription {
    readonly primaryKeyProperty: PropertyDescription | undefined;
    readonly caption: PropertyDescription | undefined;
}

export class FavoriteComponentHandler extends ComponentHandlerBase<FavoriteComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindFavorite);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public get isNonEmptyValidationStopper(): boolean {
        return true;
    }

    public getDescriptor(
        _desc: FavoriteComponentDescription | undefined,
        tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties = [
            ...makePrimaryKeyPropertyHandler(ccc, tables?.input, false, true),
            makeTextPropertyDescriptor("caption", "Label", "Favorite", false, mutatingScreenKind, {
                propertySection: PropertySection.Data,
                searchable: false,
                emptyByDefault: true,
            }),
            ...this.getBasePropertyDescriptors(),
        ];
        if (tables?.input.rowIDColumn === undefined) {
            properties.unshift();
        }
        return {
            name: "Favorite",
            description: "Mark an item as a favorite",
            img: "co-favorite",
            group: "Buttons",
            helpUrl: getDocURL("favorite"),
            needsInputContext: true,
            properties,
        };
    }

    public getAdditionalColumnsRead(tables: InputOutputTables): readonly TableAndColumn[] {
        const c = getTableColumn(tables.output, isFavoritedColumnName);
        if (c === undefined) return [];
        return [{ table: tables.output, column: c }];
    }

    public getEditedColumns(
        _desc: FavoriteComponentDescription,
        tables: InputOutputTables,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): EditedColumnsAndTables {
        return {
            editedColumns: [
                [
                    isFavoritedColumnName,
                    true,
                    doesMutatingScreenAddRows(mutatingScreenKind),
                    getTableName(tables.output),
                ],
            ],
            deletedTables: [],
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: FavoriteComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const {
            forBuilder,
            adc: { appKind },
        } = ib;

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);

        const favoriteHydrator = inflateFavorite(ib, desc.primaryKeyProperty);
        if (favoriteHydrator === undefined) return undefined;

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const favorite = favoriteHydrator(hb);
            if (favorite === undefined) return undefined;
            const { editable: value, onToggle, subsidiaryScreen } = favorite;

            let title = captionGetter(hb) ?? "";
            if (title === "") {
                title = getLocalizedString("favorite", appKind);
            }

            const component: WireToggleComponent = {
                kind: WireComponentKind.Toggle,
                ...spreadComponentID(desc.componentID, forBuilder),
                toggleKind: WireToggleKind.Favorite,
                title,
                value,
                onToggle,
                allowWrapping: false,
                isRequired: false,
            };
            return {
                component,
                isValid: true,
                // We're not counting Favorite as providing a value to make
                // mutating screens submittable.
                subsidiaryScreen,
            };
        });
    }

    public convertToPage(desc: FavoriteComponentDescription): WireContainerComponentDescription | undefined {
        const favoriteOn: WireActionRowDescription = {
            ...makeEmptyComponentDescription(WireComponentKind.ActionRow),
            text: desc.caption ?? makeStringProperty("Favorite"),
            visibilityFilters: [
                {
                    kind: ArrayTransformKind.Filter,
                    predicate: makeUnaryPredicateFormula({
                        kind: "unary",
                        column: makeSourceColumn(isFavoritedColumnName),
                        operator: UnaryPredicateCompositeOperator.IsFalsey,
                    }),
                    isActive: true,
                },
            ],
            actions: makeArrayProperty([
                {
                    title: makeStringProperty("Set favorite"),
                    icon: makeIconProperty("path:/svg/stroke/st-heart.svg"),
                    action: makeActionProperty({
                        kind: ActionKind.SetColumns,
                        outputRow: makeSourceColumnProperty({ kind: SourceColumnKind.DefaultContext, name: [] }),
                        columnAssignments: [
                            {
                                destColumn: isFavoritedColumnName,
                                // This is a string property cause otherwise _any_ change to the configurator
                                // breaks this binding.
                                value: makeStringProperty("true"),
                            },
                        ],
                    } as ActionDescription),
                },
            ]),
        };

        const favoriteOff: WireActionRowDescription = {
            ...makeEmptyComponentDescription(WireComponentKind.ActionRow),
            text: desc.caption ?? makeStringProperty("Favorite"),
            visibilityFilters: [
                {
                    kind: ArrayTransformKind.Filter,
                    predicate: makeUnaryPredicateFormula({
                        kind: "unary",
                        column: makeSourceColumn(isFavoritedColumnName),
                        operator: "is-truthy",
                    }),
                    isActive: true,
                },
            ],
            actions: makeArrayProperty([
                {
                    title: makeStringProperty("Unset favorite"),
                    icon: makeIconProperty("path:/svg/monotone/mt-heart.svg"),
                    action: makeActionProperty({
                        kind: ActionKind.SetColumns,
                        outputRow: makeSourceColumnProperty({ kind: SourceColumnKind.DefaultContext, name: [] }),
                        columnAssignments: [
                            {
                                destColumn: isFavoritedColumnName,
                                // This is a string property cause otherwise _any_ change to the configurator
                                // breaks this binding.
                                value: makeStringProperty("false"),
                            },
                        ],
                    } as ActionDescription),
                },
            ]),
        };

        return {
            ...makeEmptyComponentDescription(WireComponentKind.Container),
            containerStyle: makeEnumProperty(UIBackgroundStyle.None),
            padding: makeEnumProperty(ContainerPadding.Medium),
            width: makeEnumProperty(ContainerWidth.Normal),
            components: [favoriteOn, favoriteOff],
        };
    }
}
