import { assert, defined, hasOwnProperty, panic, mapRecord } from "@glideapps/ts-necessities";
// This is thankfully still safe to import in Node.
import * as b64 from "base64-arraybuffer";
import { setIntersect, setSubtract, setUnion } from "collection-utils";
import createEmojiRegExp from "emoji-regex";
import entries from "lodash/entries";
import sample from "lodash/sample";
import truncate from "lodash/truncate";
import numeral from "numeral";

import { logError, logInfo } from "./debug-print";

export type JSONData = unknown;
export type JSONObject = Record<string, JSONData>;

// FIXME: This `any` should be `unknown`
export interface DocumentData {
    [field: string]: any;
}

export type AsDocumentData<T> = { readonly [P in keyof T]: any };

export const digitsAndLetters = Array.from("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
    .sort()
    .join("");

/* eslint-disable */
export const emailDomainRegexp =
    /^@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
const emailAddressRegexp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable */

export function isValidEmailAddress(s: string): boolean {
    return emailAddressRegexp.test(s);
}

export function isExampleEmailAddress(s: string): boolean {
    return s.endsWith("@example.com");
}

export function isUrl(maybeURL: string): boolean {
    try {
        new URL(maybeURL);
        return true;
    } catch {
        return false;
    }
}

export function getShortNameProblem(s: string): string | undefined {
    if (s.length < 5) {
        return "The name must be at least 5 characters long";
    }
    if (s.length > 32) {
        return "The name can't be more than 32 characters long";
    }
    if (!/^([a-z]|[0-9]|-)*$/.test(s)) {
        return "The name may only contain letters, digits, and the dash";
    }
    if (s.startsWith("-") || s.endsWith("-")) {
        // https://datatracker.ietf.org/doc/html/rfc3696#section-2
        // If the hyphen is used, it is not permitted to appear at
        // either the beginning or end of a label.
        return "The name can not start/end with a dash";
    }
    return undefined;
}

export function isValidShortName(s: string): boolean {
    return getShortNameProblem(s) === undefined;
}

function areTripleEqual<T>(a: T, b: T): boolean {
    return a === b;
}

export function shallowEqualArrays<T>(
    arrA: readonly T[] | undefined,
    arrB: readonly T[] | undefined,
    compareElements: (a: T, b: T) => boolean = areTripleEqual
): boolean {
    if (arrA === arrB) {
        return true;
    }

    if (arrA === undefined || arrB === undefined) {
        return false;
    }

    const len = arrA.length;

    if (arrB.length !== len) {
        return false;
    }

    for (let i = 0; i < len; i++) {
        if (!compareElements(arrA[i], arrB[i])) {
            return false;
        }
    }

    return true;
}

// FIXME: This doesn't belong here
export const MaxPinLifeMins = 15;

// This exists purely to prevent buggy autocomplete from putting postal codes in tab names.
//
// It would be real neat if Chrome supported actual web standards.
// You're supposed to be able to say `autocomplete="off"` as an attribute to disable it,
// but Chrome adamantly refuses to support this (see https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164)
// What they recommend we do instead is "set a semantic tag". So here's the semantic meaning of _this_ attribute:
// stop autocompleting, you jerks.
//
// This works in Chrome and Firefox, but not Safari. Right now, Safari autocomplete
// hasn't done anything catastrophic so we may just have to live with this.
export const disableBrowserAutocompleteToken = "browsers-should-never-autocomplete-this";

export function checkBoolean(x: unknown): boolean {
    if (typeof x === "boolean") return x;
    return panic(`Value should be a boolean: ${x}`);
}

export function checkString(x: unknown): string {
    if (typeof x === "string") return x;
    return panic(`Value should be a string: ${x}`);
}

export function checkNumber(x: unknown): number {
    if (typeof x === "number") return x;
    return panic(`Value should be a number: ${x}`);
}

// We have this because `Array.isArray(x)` returns `x is any[]`, which means
// that this code compiles without error, for example:
//
// function thisShouldNotCompile(x: string | readonly string[]) {
//     if (Array.isArray(x)) {
//         const s = x[0];
//         s.thisIsAnAny("but it's actually a string");
//     }
// }
export function isArray<T, U>(x: readonly T[] | U): x is readonly T[];
export function isArray<T, U>(x: T[] | U): x is T[];
export function isArray<T, U>(x: readonly T[] | U): x is readonly T[] {
    return Array.isArray(x);
}

export function checkArray<T, U = unknown>(x: T[] | U, checkItem?: (item: unknown) => T): T[] {
    if (!Array.isArray(x)) {
        return panic(`Value should be an array: ${x}`);
    }
    if (checkItem !== undefined) {
        x.forEach(checkItem);
    }
    return x;
}

export function swapArray<T>(array: T[], srcOffset: number, dstOffset: number) {
    if (srcOffset === dstOffset) return;

    const tmp = array[dstOffset];
    array[dstOffset] = array[srcOffset];
    array[srcOffset] = tmp;
}

export function nonNull<T>(v: T | null, msg?: string): T {
    if (v === null) return panic(msg ?? "Value was null but should be non-null");
    return v;
}

export function isUndefinedish<T>(v: T | null | undefined): v is null | undefined {
    return v === null || v === undefined;
}

export function isDefined<T>(v: T | null | undefined): v is T {
    return v !== null && v !== undefined;
}

export function isEmptyOrUndefined<T>(v: string | readonly T[] | undefined): v is undefined {
    return v === undefined || v.length === 0;
}

export function isEmptyOrUndefinedish(v: string | undefined | null): v is undefined | null | "" {
    return v === undefined || v === null || v.length === 0;
}

export function isUndefinedOrNaN(v: number | undefined): v is undefined {
    return v === undefined || isNaN(v);
}

export function isEmpty(val: unknown): boolean {
    return val === null || val === undefined || val === "" || Number.isNaN(val) || (isArray(val) && val.length === 0);
}

export function nullToUndefined<T>(v: T | null): T | undefined {
    if (v === null) return undefined;
    return v;
}

export function truthify(x: unknown): boolean {
    return !!(x as boolean);
}

// This makes it type-safe
export function fillArray<T>(length: number, value: T): T[] {
    return new Array(length).fill(value);
}

export function undefinedIfEmptyString(s: unknown): string | undefined {
    if (s === undefined || s === "") return undefined;
    return checkString(s);
}

export function undefinedIfEmptyOrWhitespaceString(s: unknown): string | undefined {
    if (typeof s !== "string") return undefined;
    if (s.trim() === "") return undefined;
    return s;
}

export function removeArrayItem<T>(arr: ReadonlyArray<T>, index: number): T[] {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function replaceArrayItem<T>(arr: ReadonlyArray<T>, index: number, newItem: T): T[] {
    return [...arr.slice(0, index), newItem, ...arr.slice(index + 1)];
}

export function insertArrayItems<T>(arr: readonly T[], index: number, ...items: T[]): T[] {
    const newArr = [...arr];
    newArr.splice(index, 0, ...items);
    return newArr;
}

export function updateDefined<T>(old: T, updates: Partial<T>): T {
    const result: T = { ...old };
    for (const key of Object.keys(updates)) {
        const value = (updates as any)[key];
        if (value === undefined) continue;
        (result as any)[key] = value;
    }
    return result;
}

export function updateDeleteUndefined<T>(old: T, updates: Partial<T>): T {
    const result: T = { ...old };
    for (const key of Object.keys(updates)) {
        const value = (updates as any)[key];
        if (value !== undefined) {
            (result as any)[key] = value;
        } else {
            delete (result as any)[key];
        }
    }
    return result;
}

const extensionRegex = /^.+(\.[^./\\]+)$/;

export function pathExtension(path: string): string | undefined {
    const matches = path.match(extensionRegex);
    if (matches === null) return undefined;
    return matches[1];
}

export function isEmoji(str: string): boolean {
    return getEmoji(str) !== undefined;
}

export function getEmoji(str: string): string | undefined {
    const result = createEmojiRegExp().exec(str);
    if (result === null || !str.trimLeft().startsWith(result[0])) {
        return undefined;
    }

    // The Unicode Consortium considers [#*0-9] to be emoji in themselves.
    // They definitely can be modified into Emoji with diacritics but we don't
    // consider them alone to be emoji.
    if (result[0].match(/^[#*0-9]+$/) !== null) return undefined;

    return result[0];
}

// https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/simple/simple.jsx
export function reorder<T>(list: ReadonlyArray<T>, startIndex: number, endIndex: number): Array<T> {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export async function mapFilterUndefinedAsync<T, U>(
    iterable: Iterable<T>,
    f: (x: T, i: number) => Promise<U | undefined>
): Promise<U[]> {
    const result: U[] = [];
    let i = 0;
    for (const x of iterable) {
        const y = await f(x, i);
        i += 1;
        if (y === undefined) continue;
        result.push(y);
    }
    return result;
}

export function removeUndefinedProperties<T extends object>(obj: T): T {
    const result: any = {};
    for (const k of Object.keys(obj) as (keyof T)[]) {
        const v = obj[k];
        if (v === undefined) continue;
        result[k] = v;
    }
    return result;
}

async function fetchGet(
    basePath: string,
    searchParams: { [key: string]: string },
    headers: { [key: string]: string } = {}
): Promise<Response | undefined> {
    const url = basePath + "?" + new URLSearchParams(searchParams).toString();
    try {
        return await fetch(url, { method: "GET", headers });
    } catch (e: unknown) {
        // FIXME: Appropriately handle exceptions here
        logError(`In fetchGet: ${e}`);
        return undefined;
    }
}

export async function fetchPageMetadata(
    target: string,
    locally: boolean = false
): Promise<{ title: string; description?: string; image?: string; video?: string } | undefined> {
    const baseURL = locally
        ? "http://localhost:5050/fetchPageMetadata"
        : "https://glide-page-metadata.firebaseapp.com/fetchPageMetadata";
    const result = await fetchGet(baseURL, { url: target });
    if (result === undefined || result.status !== 200) {
        // We need to drain the response or else we'll get stuck with an open connection
        void result?.text();
        return undefined;
    }
    return await result.json();
}

// NOTE: At some point we started adding beginner tutorial apps to teams whose
// app IDs are "beginner-tutorial-<IDish-thing>".  Prior to that, only
// template app IDs had dashes in them, and we didn't have to worry about
// those apps having users, but some customers are actually building those
// tutorials out into proper apps and expecting them to work, so we have to
// support those dashes.  Unfortunately we also use the dash here to separate
// the UID parts.  We're going with the first ID-ish part being the app user
// ID, and not containing any dashes.  The app ID after that is allowed to
// contain dashes.
//
// There is an awkward ambiguity because we make UIDs without app user IDs for
// password logins, so we could in theory getting be getting an
// `anonymous-user-ABC-DEF` where `ABC-DEF` is actually the app ID.  The
// saving grace is that we've phased out password auth, so hopefully these new
// dashful app IDs will never have password auth.
const anonymousUserRegex = /^anonymous-user-(([0-9a-zA-Z]+)-)?([0-9a-zA-Z-]+)$/;

export function parseAnonymousUserID(uid: string): { appID: string; appUserID: string | undefined } | undefined {
    const match = uid.split("-{")[0].match(anonymousUserRegex);
    if (match === null) return undefined;
    const appID = match[3];
    let appUserID: string | undefined = match[2];
    if (appUserID !== undefined && appUserID.length === 0) {
        appUserID = undefined;
    }
    return { appID, appUserID };
}

export function parseNumber(str: string): number | undefined {
    try {
        // Treat commas as decimal points, or remove them if there's
        // already a decimal point.
        if (str.includes(".")) {
            str = str.replace(/,/g, "");
        } else {
            str = str.replace(/,/g, ".");
        }
        const n = numeral(str).value();
        if (typeof n !== "number") return undefined;
        if (isNaN(n)) return undefined;
        return n;
    } catch {
        return undefined;
    }
}

// Only parse  strickly if the number will stringify back to the original string after parsing.
// ex: 05 would return undefined
export function parseNumberDiligently(str: string): number | undefined {
    const x = parseNumber(str);
    if (x === undefined) return undefined;
    if (x.toString() !== str) return undefined;
    return x;
}

export function getFirstNameOfUsername(username: string): string {
    return username.split(" ")[0];
}

export function parseURL(s: string): URL | undefined {
    try {
        return new URL(s);
    } catch {
        return undefined;
    }
}

export function changeSearchToHash(url: URL): void {
    url.hash = url.search.substring(1);
    url.search = "";
}

export function changeHashToSearch(url: URL): void {
    let str = url.hash.substring(1);
    if (str === "") return;
    if (url.search !== "") str = url.search + "&" + str;
    url.search = str;
    url.hash = "";
}

// We have to engage in generics because URL is already in the global
// scope in browsers, but needs to be imported as a package in Node.
export function liftURLAssumingHTTP<T>(possibleURL: string, urlConstructor: (p: string) => T): T | undefined {
    try {
        return urlConstructor(possibleURL);
    } catch {
        // Yuck, but... people do this.
        try {
            return urlConstructor(`http://${possibleURL}`);
        } catch {
            return undefined;
        }
    }
}

export function findType<T, U extends T>(iterable: Iterable<T>, f: (t: T) => t is U): U | undefined {
    for (const t of iterable) {
        if (f(t)) {
            return t;
        }
    }
    return undefined;
}

export function filterType<T, U extends T>(iterable: Iterable<T>, f: (t: T) => t is U): U[] {
    const result: U[] = [];
    for (const t of iterable) {
        if (f(t)) {
            result.push(t);
        }
    }
    return result;
}

export function objectKeyForValue<T extends string, U>(
    o: { [k in T]?: U },
    v: U,
    equal?: (a: U | undefined, b: U) => boolean
): T | undefined {
    for (const k of Object.keys(o) as T[]) {
        const ov = o[k];
        const eq = equal !== undefined ? equal(ov, v) : ov === v;
        if (eq) return k;
    }
    return undefined;
}

// ##normalizeEmailAddress:
// We don't do any email parsing/validation, but just trim and lowercase.
export function normalizeEmailAddress(s: string): string {
    return s.toLowerCase().trim();
}

export function mapNewOrUpdate<K, V>(map: Map<K, V>, k: K, newValue: () => V, updateValue: (v: V) => V): V {
    let v = map.get(k);
    if (v === undefined) {
        v = newValue();
    } else {
        v = updateValue(v);
    }
    map.set(k, v);
    return v;
}

export function isResponseOK(r: Response | undefined): r is Response & { ok: true } {
    return r !== undefined && r.ok;
}

export function ignore(..._args: any) {
    /// does nothing
}

export async function getResponseErrorMessage(r: Response | undefined): Promise<string | undefined> {
    assert(!isResponseOK(r));
    if (r === undefined) return undefined;
    try {
        const json = await r.json();
        if (hasOwnProperty(json, "message") && typeof json.message === "string") {
            return json.message;
        }
    } catch (e: unknown) {
        // probably not a JSON - nothing to do
    }
    return undefined;
}

export function escapeHtml(unsafe: string) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

// https://makandracards.com/makandra/15879-javascript-how-to-generate-a-regular-expression-from-a-string
export function escapeStringAsRegexp(string: string): string {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
}

export type TextTemplateToken<T> =
    | { readonly isText: true; readonly text: string }
    | { readonly isText: false; readonly value: T };

export function tokenizeTemplateString(
    template: string,
    patterns: readonly string[]
): readonly TextTemplateToken<string>[] {
    patterns = patterns.filter(p => p !== "");
    if (patterns.length === 0) {
        return [{ isText: true, text: template }];
    }

    const regexp = new RegExp(patterns.map(escapeStringAsRegexp).join("|"), "g");
    const tokens: TextTemplateToken<string>[] = [];

    function pushLiteral(text: string) {
        if (text === "") return;
        tokens.push({ isText: true, text });
    }

    let lastIndex = 0;
    for (;;) {
        const match = regexp.exec(template);
        if (match === null) break;

        const { index } = match;
        const pattern = match[0];

        pushLiteral(template.substr(lastIndex, index - lastIndex));
        lastIndex = index + pattern.length;

        tokens.push({ isText: false, value: pattern });
    }
    pushLiteral(template.substr(lastIndex));

    return tokens;
}

export function isOlderThan(date: Date, ms: number, now: Date = new Date()): boolean {
    const timeout = new Date(date.getTime() + ms);
    return now > timeout;
}

export function makeGoogleSheetURL(fileID: string): string {
    return `https://docs.google.com/spreadsheets/d/${fileID}`;
}

export function getBrowserLanguage(): string | undefined {
    try {
        const { language } = navigator;
        if (typeof language !== "string") return undefined;
        return language;
    } catch {
        return undefined;
    }
}

export async function maybeAsync<T>(fn: () => Promise<T>, defaultValue: T) {
    try {
        const result = await fn();
        return result;
    } catch {
        return defaultValue;
    }
}

export function maybe<T>(fn: () => T, defaultValue: T) {
    try {
        const result = fn();
        return result;
    } catch {
        return defaultValue;
    }
}

const charCodeA = "A".charCodeAt(0);

function lettersFromInteger(i: number): string {
    if (i < 26) {
        return String.fromCharCode(charCodeA + i);
    } else {
        const x = i % 26;
        const y = (i - x) / 26;
        return lettersFromInteger(y - 1) + lettersFromInteger(x);
    }
}

// We export this for testing.
export const uniqueRandomNameMinimumLength = 5;
export function makeUniqueRandomName(nameExists: (n: string) => boolean): string {
    let name: string = "";
    while (name.length < uniqueRandomNameMinimumLength || nameExists(name)) {
        name = name + defined(sample(digitsAndLetters));
    }
    return name;
}

export function makeNameUnique(name: string, nameExists: (n: string) => boolean): string {
    if (!nameExists(name)) {
        return name;
    }

    let index = 0;
    for (;;) {
        const withIndex = name + " " + lettersFromInteger(index);
        if (!nameExists(withIndex)) {
            return withIndex;
        }
        index++;
    }
}

const urlSchemaRegex = /^[a-zA-Z][a-zA-Z0-9+.-]+:/;

// s: string | undefined to simplify some usages.
// See ListItem.ts as an example: href is an optional prop; if we just pass
// in the `undefined` we expect from a non-passed prop then the logic for
// checking whether to make the item clickable is still valid.
export function safeURL(s: string | null | undefined): string | undefined {
    if (s === null || s === undefined) return undefined;
    s = s.trim();
    if (s === "") return undefined;

    if (s.match(urlSchemaRegex) === null) {
        const emoji = getEmoji(s);
        if (emoji !== undefined) {
            s = `https://emojipedia.org/${emoji}`;
        } else {
            s = "https://" + s;
        }
    }

    const parsed = parseURL(s);
    if (parsed === undefined) return undefined;

    // FIXME: What other schemes do we not want?
    // eslint-disable-next-line
    if (parsed.protocol === "javascript:") return undefined;

    return s;
}

export function objectWithUndefinedProperties(keys: readonly string[]): JSONObject {
    const o: JSONObject = {};
    for (const c of keys) {
        o[c] = undefined;
    }
    return o;
}

export function keyValuePairs<K extends keyof any, V>(obj: Record<K, V>): [K, V][] {
    return entries(obj) as [K, V][];
}

// The performance.now() clock is paused in Mobile Safari when the app is backgrounded,
//  which is good when you want to accurately measure the duration of something and not
//  have your timings skewed by the user leaving and returning to the app.
export function getCurrentTimestampInMilliseconds(): number {
    try {
        if (self.performance !== undefined && self.performance.now !== undefined) {
            return self.performance.now();
        }
    } catch {
        // nothing to do
    }
    return Date.now();
}

export function glideViralReferralLink(utm_content: string): string {
    const params = {
        utm_source: "glide",
        utm_campaign: "player",
        utm_content,
        dr: window.location.href,
    };
    const query = Object.entries(params)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join("&");
    return `https://www.glideapps.com?${query}`;
}

export function filterRecordByKey<S extends string, T extends S, V>(
    r: Record<S, V>,
    f: (n: S) => n is T
): Partial<Record<T, V>> {
    const result: Partial<Record<T, V>> = {};
    for (const [name, value] of entries(r) as [S, V][]) {
        if (f(name)) {
            result[name] = value;
        }
    }
    return result;
}

export function areSetsEqual<T>(left: Iterable<T>, right: Iterable<T>): boolean {
    let rightSet: Set<T>;
    if (right instanceof Set) {
        rightSet = right;
    } else {
        rightSet = new Set(right);
    }
    return setSubtract(setUnion(left, rightSet), setIntersect(left, rightSet)).size === 0;
}

// Proves that type U is a subtype of type T.
// Does nothing at runtime.
export function proveSubtype<_T, _U extends _T>() {
    return;
}

export function withStopwatch<T>(f: () => T): [number, T] {
    const start = Date.now();
    const result = f();
    const time = Date.now() - start;
    return [time, result];
}

const numberPrefixRegexp = /^-?[0-9,.]+/;

function getNumberPrefix(s: string): { n: number; rest: string } | undefined {
    const match = s.match(numberPrefixRegexp);
    if (match === null) return undefined;
    const numberString = match[0];
    const n = parseNumber(numberString);
    if (n === undefined) return undefined;
    return {
        n,
        rest: s.substring(numberString.length),
    };
}

function asMaybeTrueOrFalse(s: string): boolean | undefined {
    s = s.toLowerCase();
    if (s === "true" || s === "1") return true;
    if (s === "false" || s === "0") return false;
    return undefined;
}

// ##compareStrings:
// We handle numbers and booleans specially.
export function compareStrings(one: string, two: string): number {
    const p1 = getNumberPrefix(one);
    if (p1 !== undefined) {
        const p2 = getNumberPrefix(two);
        if (p2 !== undefined) {
            if (p1.n < p2.n) return -1;
            if (p1.n > p2.n) return 1;

            one = p1.rest;
            two = p2.rest;
        }
    }

    // FIXME: This leads to inconsistent results.  `false` is less than `1`,
    // but `fa` is greater than `1`, but `fa` is also less than `false`.  So
    //
    //   false < 1 < fa < false
    const b1 = asMaybeTrueOrFalse(one);
    if (b1 !== undefined) {
        const b2 = asMaybeTrueOrFalse(two);
        if (b2 !== undefined) {
            if (b1 === b2) return 0;
            if (b1 === false) return -1;
            return 1;
        }
    }

    return one.localeCompare(two);
}

export function mapFilter<K, V>(m: ReadonlyMap<K, V>, pred: (v: V) => boolean): Map<K, V> {
    const result = new Map<K, V>();
    for (const [k, v] of m) {
        if (!pred(v)) continue;
        result.set(k, v);
    }
    return result;
}

export function areSetsOverlapping<T>(a: ReadonlySet<T>, b: Iterable<T> | ReadonlySet<T>): boolean {
    if (b instanceof Set && a.size < b.size) {
        for (const key of a) {
            if (b.has(key)) return true;
        }
    } else {
        for (const key of b) {
            if (a.has(key)) return true;
        }
    }
    return false;
}

// `afterItem === undefined` means move it to the start.
export function moveItemInArray<T>(items: readonly T[], item: T, afterItem: T | undefined): readonly T[] | undefined {
    const startIndex = items.indexOf(item);
    if (startIndex < 0) return undefined;

    if (afterItem === undefined) {
        return reorder(items, startIndex, 0);
    } else {
        const newItems = items.filter(t => t !== item);
        const afterIndex = newItems.indexOf(afterItem);
        if (afterIndex < 0) return undefined;

        newItems.splice(afterIndex + 1, 0, item);
        return newItems;
    }
}

export enum VisitResult {
    Stop = "glide_visit_result_stop",
    SkipChildren = "glide_visit_result_skip",
    Continue = "glide_visit_result_continue",
}

export function visit<T, TResult>(
    items: readonly T[],
    childrenForItem: (i: T) => undefined | readonly T[],
    onVisit: (i: T) => VisitResult | TResult
): TResult | undefined {
    const toVisit = [...items].reverse();
    while (toVisit.length > 0) {
        const item = toVisit.pop();
        if (item === undefined) return undefined;

        const result = onVisit(item);
        if (result === VisitResult.Stop) return undefined;
        if (result === VisitResult.SkipChildren) continue;
        if (result !== VisitResult.Continue) {
            return result;
        }
        const children = childrenForItem(item);
        if (children !== undefined && children.length > 0) {
            toVisit.push(...children);
        }
    }
    return undefined;
}

export function anyDefined(...args: any[]): boolean {
    return args.some(a => {
        if (typeof a === "string") {
            return a !== undefined && a !== "";
        }
        return a !== undefined;
    });
}

export function objectKeys<T extends string | symbol | number>(obj: Record<T, unknown>): T[] {
    return Object.keys(obj) as T[];
}

export function objectEntries<T extends string | symbol | number, U>(
    obj: Record<T, U> | Partial<Record<T, U>>
): [T, U][] {
    return Object.entries(obj) as [T, U][];
}

export function removeNullFromObject<T>(obj: Record<string, T | null>): Record<string, T> {
    const cleaned: Record<string, T> = {};
    for (const [k, v] of Object.entries(obj)) {
        if (v === null) continue;
        cleaned[k] = v;
    }
    return cleaned;
}

export function findLastIndex<T>(arr: readonly T[], cb: (elem: T) => boolean): number {
    for (let i = arr.length - 1; i >= 0; i--) {
        if (cb(arr[i])) {
            return i;
        }
    }

    return -1;
}

export function sortNestedObject(obj: object): object {
    return Object.keys(obj)
        .sort()
        .reduce(function (result, key) {
            const val = (obj as any)[key];
            return { ...result, [key]: typeof val === "object" ? sortNestedObject(val) : val };
        }, {});
}

// See IETF RFC 4648, Section 5
export function base64URLEncodeArrayBuffer(buf: ArrayBuffer): string {
    return b64.encode(buf).replace(/\+/g, "-").replace(/\//g, "_");
}

export function base64URLEncodeNodeBuffer(buf: Buffer): string {
    return buf.toString("base64").replace(/\+/g, "-").replace(/\//g, "_");
}

export function isValidJSON(value: string) {
    try {
        JSON.parse(value);
        return true;
    } catch (_: unknown) {
        return false;
    }
}

export function assertArray<T, U extends T>(arr: readonly T[], f: (t: T) => t is U): asserts arr is readonly U[] {
    for (const a of arr) {
        assert(f(a));
    }
}

export function truncateStringProperties(obj: JSONObject | undefined, maxLength: number = 500): JSONObject | undefined {
    if (obj === undefined) return undefined;
    function parseValue(value: unknown): unknown {
        if (typeof value === "string") {
            return truncate(value, { length: maxLength });
        } else if (Array.isArray(value)) {
            return value.map(v => parseValue(v));
        } else if (typeof value === "object" && value !== null) {
            return truncateStringProperties(value as JSONObject, maxLength);
        }
        return value;
    }
    return mapRecord(obj, parseValue);
}

export function isRecord(value: unknown): value is Record<string, unknown> {
    return typeof value === "object" && value !== null && !Array.isArray(value);
}

export function replaceObjectsInJSON(json: unknown, replacements: Map<unknown, unknown>): unknown {
    if (replacements.has(json)) {
        const result = replacements.get(json);
        logInfo("replacing", JSON.stringify(json), "with", JSON.stringify(result));
        return result;
    } else if (isArray(json)) {
        let didReplace = false;
        const result = json.map(j => {
            const r = replaceObjectsInJSON(j, replacements);
            if (r !== j) {
                didReplace = true;
            }
            return r;
        });
        return didReplace ? result : json;
    } else if (typeof json === "object" && json !== null) {
        let didReplace = false;
        const result: Record<string, unknown> = {};
        for (const [k, j] of Object.entries(json)) {
            const r = replaceObjectsInJSON(j, replacements);
            if (r !== j) {
                didReplace = true;
            }
            result[k] = r;
        }
        return didReplace ? result : json;
    } else {
        return json;
    }
}
