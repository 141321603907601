import { assert, DefaultMap, hasOwnProperty } from "@glideapps/ts-necessities";
import { logError } from "@glide/support";
import { frontendTrace, frontendTraceSync } from "@glide/common-core/dist/js/tracing";
import { convertYesCodeValue, getYesCodeManifest, makeParams, unloadYesCodeManifest } from "./yes-code";
import type { YesCodeParameter, YesCodeValue } from "@glide/common-core/dist/js/yes-code-types";
import { approvedYesCodeHost } from "@glide/common-core/dist/js/yes-code-types";
import type { YesCodeParameterMap } from "@glide/common-core/dist/js/components/types";

type CallYesCodeOptions = { forceDirect: boolean; onlyTrusted: boolean };

interface YesCodeAsyncInterface {
    importURL(url: string): Promise<any>;

    callAsync(url: string, paramsMap: YesCodeParameterMap): Promise<YesCodeValue | undefined>;

    unload(url: string): void;
}

let asyncInterface: YesCodeAsyncInterface | undefined;

function getAsyncInterface(): YesCodeAsyncInterface | undefined {
    if (asyncInterface === undefined) {
        logError("Yes-code interface not registered");
    }
    return asyncInterface;
}

export function registerYesCodeAsyncInterface(iface: YesCodeAsyncInterface): void {
    assert(asyncInterface === undefined);
    asyncInterface = iface;
}

interface Module {
    run(...params: YesCodeParameter[]): YesCodeValue | Promise<YesCodeValue>;
}

function makeURL(url: string): URL | undefined {
    try {
        const urlObject = new URL(url);
        // Force https: for security!
        urlObject.protocol = "https:";
        return urlObject;
    } catch {
        return undefined;
    }
}

function isTrustedURL(url: URL): boolean {
    return url.host.toLowerCase() === approvedYesCodeHost.toLowerCase();
}

const modulePromises = new DefaultMap<string, Promise<Module | undefined>>(importModuleAsync);
const modules = new Map<string, Module>();

const callCounts = new DefaultMap<string, number>(() => 0);

async function importModuleAsync(url: string): Promise<Module | undefined> {
    try {
        const maybeModule = await getAsyncInterface()?.importURL(url + "/function.js");
        if (!hasOwnProperty(maybeModule, "default")) return undefined;
        if (!hasOwnProperty(maybeModule.default, "run")) return undefined;
        const module = { run: maybeModule.default.run } as Module;
        modules.set(url, module);
        modulePromises.delete(url);
        return module;
    } catch (e: unknown) {
        logError("Could not load module", url, e);
        return undefined;
    }
}

function importModule(url: string): Module | Promise<Module | undefined> {
    const maybeModule = modules.get(url);
    if (maybeModule !== undefined) {
        return maybeModule;
    }
    return modulePromises.get(url);
}

export async function traceAsync<T>(url: string, f: () => Promise<T>): Promise<T> {
    let count = callCounts.update(url, id => id + 1);
    if ((count & (count - 1)) === 0) {
        // `count` will be a power of two here, and it's the number of calls
        // total to this URL so far, but we only want to count half of them,
        // since we've already counted the other half.  The only exception is
        // when `count` is 1, which is why we need the max to give 1.
        count = Math.max(1, count / 2);

        return frontendTrace("invokeYesCode", { "yes_code.url": url, "yes_code.count": count }, f, "always");
    } else {
        return f();
    }
}

function traceSync<T>(url: string, f: () => T): T {
    let count = callCounts.update(url, id => id + 1);
    if ((count & (count - 1)) === 0) {
        // `count` will be a power of two here, and it's the number of calls
        // total to this URL so far, but we only want to count half of them,
        // since we've already counted the other half.  The only exception is
        // when `count` is 1, which is why we need the max to give 1.
        count = Math.max(1, count / 2);

        return frontendTraceSync("invokeYesCode", { "yes_code.url": url, "yes_code.count": count }, f, "always");
    } else {
        return f();
    }
}

async function callDirectYesCodeAsync(
    url: string,
    paramsMap: YesCodeParameterMap,
    opts: CallYesCodeOptions
): Promise<YesCodeValue | undefined> {
    const [module, manifest] = await Promise.all([importModule(url), getYesCodeManifest(url)]);
    if (typeof manifest === "string" || module === undefined) return undefined;

    if (manifest.released !== "direct") {
        if (opts.onlyTrusted) return undefined;
        if (!opts.forceDirect) return getAsyncInterface()?.callAsync(url, paramsMap);
    }

    const params = makeParams(manifest, paramsMap);

    const resultValue = await traceAsync(url, async () => module.run(...params));
    return convertYesCodeValue([resultValue, resultValue], manifest.result.type).value;
}

function callDirectYesCode(
    url: string,
    paramsMap: YesCodeParameterMap,
    opts: CallYesCodeOptions
): YesCodeValue | undefined | Promise<YesCodeValue | undefined> {
    const manifest = getYesCodeManifest(url);
    if (typeof manifest === "string") return undefined;

    if (manifest instanceof Promise) {
        return callDirectYesCodeAsync(url, paramsMap, opts);
    }
    if (manifest.released !== "direct") {
        if (opts.onlyTrusted) return undefined;
        if (!opts.forceDirect) return getAsyncInterface()?.callAsync(url, paramsMap);
    }

    const module = importModule(url);
    if (module instanceof Promise) {
        return callDirectYesCodeAsync(url, paramsMap, opts);
    }

    const params = makeParams(manifest, paramsMap);

    // NOTE: In the case where this returns a promise, the duration will not
    // be accurate because we're not awaiting it inside the trace.
    return traceSync(url, () => {
        try {
            const result = module.run(...params);
            if (result instanceof Promise) {
                return result.then(v => convertYesCodeValue([v, v], manifest.result.type).value).catch(() => undefined);
            } else {
                return convertYesCodeValue([result, result], manifest.result.type).value;
            }
        } catch {
            return undefined;
        }
    });
}

// This will call the Yes-Code column if it's trusted and direct.  If it's not
// both of those then it will return `undefined`.
export function callYesCodeIfDirectTrusted(
    url: string,
    paramsMap: YesCodeParameterMap
): YesCodeValue | undefined | Promise<YesCodeValue | undefined> {
    const urlObject = makeURL(url);
    if (urlObject === undefined) return undefined;

    const isTrusted = isTrustedURL(urlObject);
    if (!isTrusted) return undefined;

    return callDirectYesCode(urlObject.href, paramsMap, { onlyTrusted: true, forceDirect: false });
}

export function callYesCode(
    url: string,
    paramsMap: YesCodeParameterMap,
    opts: CallYesCodeOptions = { forceDirect: false, onlyTrusted: false }
): YesCodeValue | undefined | Promise<YesCodeValue | undefined> {
    const urlObject = makeURL(url);
    if (urlObject === undefined) return undefined;

    const isTrusted = isTrustedURL(urlObject);
    if (!isTrusted && opts.onlyTrusted) return undefined;

    if (isTrusted || opts.forceDirect) {
        return callDirectYesCode(urlObject.href, paramsMap, opts);
    }
    return getAsyncInterface()?.callAsync(urlObject.href, paramsMap);
}

export function unloadYesCode(url: string): void {
    getAsyncInterface()?.unload(url);
    unloadYesCodeManifest(url);
    modulePromises.delete(url);
}

export function isTrustedYesCodeURL(url: string): boolean {
    const urlObject = makeURL(url);
    if (urlObject === undefined) return false;

    return isTrustedURL(urlObject);
}
