import type {
    AppScopedUsageMetadata,
    MillisecondsSinceEpoch,
    QuotaType,
    ResettableQuota,
    UsageRecord,
} from "./billing";
import type { Quota } from "./entitlements";

export interface UsageResetRecord {
    readonly orgID: string;
    readonly timestamp: MillisecondsSinceEpoch;
}

export interface IsUsageWithinQuotaRequest<TUsageType extends QuotaType = QuotaType> {
    readonly usageType: TUsageType;

    readonly orgID: string;

    readonly quantity: number;
    readonly quantityFloat: number;

    // If this is passed, then the usage request is approved if there was any previous usage reported
    //  with the same idempotency key, owner, and usageType, regardless of `qty` and the current quota level.
    // Useful for cases where we only count a fixed amount of usage toward quota, no matter
    //   how many times an action is taken (e.g. public/private login is only counted once per email).
    readonly idempotencyKey?: string;
}

export interface GetUsageOptions {
    /**
     * The range of timestamps for which to return usage records. If not provided, then
     * the range will be from the last reset event timestamp to the current timestamp.
     */
    readonly range?: {
        readonly inclusiveStart: Date;
        readonly exclusiveEnd: Date;
    };
    /**
     * If provided, then the returned usage records will be filtered to only include those
     * that match the given appID and/or are types of the given usage type (e.g. there exists
     * a usage record with the same idempotency key, timestamp, and ownerID, but with that usage type)
     */
    readonly where?: Partial<Pick<AppScopedUsageMetadata, "appID">> & { readonly typeOf?: ResettableQuota };
}

export enum UsageGroupBy {
    UsageType,
    AppIDUsageType,
    AppIDUsageTypePluginID,
    UsageTypePluginID,
}

export interface GetUsageArrayOptions extends GetUsageOptions {
    /**
     * The usage types to return. If not provided, then all usage types will be returned.
     */
    readonly usageTypes?: readonly ResettableQuota[];

    /**
     * A value from `UsageGroupBy`. Defaults to `UsageGroupBy.UsageType`.
     */
    readonly groupBy?: UsageGroupBy;
}

export type UsageRecordSubset = Pick<UsageRecord, "kind" | "metadata" | "quantity">;

export interface EnforcementClient {
    // -- Ingestion --
    readonly handleUsageRecord: (quota: Quota, record: UsageRecord, useRollingWindow: boolean) => Promise<void>;
    readonly handleResetRecord: (record: UsageResetRecord) => Promise<void>;

    // -- Querying --
    readonly isUsageWithinQuota: (quota: Quota, req: IsUsageWithinQuotaRequest) => Promise<boolean>;

    getUsage(ownerID: string, usageType: ResettableQuota, options?: GetUsageOptions): Promise<number>;
    getUsage<TUsageType extends ResettableQuota>(
        ownerID: string,
        usageTypes: readonly TUsageType[],
        options?: GetUsageOptions
    ): Promise<Record<TUsageType, ResettableQuota extends TUsageType ? number | undefined : number>>;

    getUsageArray(ownerID: string, options: GetUsageArrayOptions): Promise<readonly UsageRecordSubset[]>;

    readonly getLastResetEventTimestamps: (
        ownerID: string,
        count: number
    ) => Promise<readonly MillisecondsSinceEpoch[]>;
}
