import { AppKind } from "@glide/location-common";
import {
    isFavoritedColumnName,
    type TableColumn,
    type TableGlideType,
    SourceColumnKind,
    getPrimitiveNonHiddenColumns,
    getTableColumnDisplayName,
    getTableName,
    makeTableRef,
    sheetNameForTable,
} from "@glide/type-schema";
import {
    type ActionDescription,
    type ClassScreenDescription,
    type ComponentDescription,
    type PropertyDescription,
    type ScreenDescription,
    ActionKind,
    ArrayScreenFormat,
    ScreenDescriptionKind,
    getColumnProperty,
    makeActionProperty,
    makeArrayProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeIconProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import { ComponentKindInlineList, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import {
    type ActionWithTitleDescription,
    type CardCollectionComponentDescription,
    type HeroSectionDescription,
    type WireFieldsDescription,
    makeActionWithTitle,
} from "@glide/fluent-components/dist/js/fluent-components";
import { type AppDescriptionContext, getAppKindFromAppDescriptionContext } from "@glide/function-utils";
import { WireComponentKind, CardStyle, UISize, UITitleStyle, UIWireTitleStyle } from "@glide/wire";
import { assertNever, mapFilterUndefined, panic } from "@glideapps/ts-necessities";
import { definedMap } from "collection-utils";
import type { NonUndefined } from "utility-types";

import type { FormScreenActionDescription } from "./actions/push-form-screen";
import { getDefaultArrayScreenHandler } from "./array-screens";
import { summaryColumnsForTable } from "./description-utils";
import { makeArrayScreenDescription, makeClassScreenDescription, makeComponentsForTable } from "./make-screen";
import { getGlideIcon } from "@glide/common";

type Summary = NonUndefined<ReturnType<typeof summaryColumnsForTable>>;

function makeSummary(table: TableGlideType): Summary | undefined {
    return summaryColumnsForTable(table, false, true, false, false);
}

function makeBreadcrumbs(): ComponentDescription {
    return makeEmptyComponentDescription(WireComponentKind.Breadcrumbs);
}

function makeHero(summary: Summary): HeroSectionDescription {
    const titleStyle = summary.imageURLProperty !== undefined ? UIWireTitleStyle.Image : UIWireTitleStyle.Simple;

    const editAction: ActionWithTitleDescription = {
        title: makeStringProperty("Edit"),
        icon: makeIconProperty(getGlideIcon("st-edit-pencil")),
        action: makeActionProperty({
            kind: ActionKind.PushEditScreen,
            sourceColumn: makeSourceColumnProperty({ kind: SourceColumnKind.DefaultContext, name: [] }),
        } as ActionDescription),
    };

    const actions: ActionWithTitleDescription[] = [editAction];
    if (titleStyle === UIWireTitleStyle.Image) {
        actions.unshift({});
    }

    return {
        ...makeEmptyComponentDescription(WireComponentKind.Hero),
        title: summary.titleProperty,
        subtitle: summary.subtitleProperty,
        image: summary.imageURLProperty,
        titleStyle: makeEnumProperty(titleStyle),
        buttons: makeArrayProperty(actions),
    };
}

function makeFields(columns: TableColumn[]): WireFieldsDescription {
    return {
        ...makeEmptyComponentDescription(WireComponentKind.Fields),
        fields: makeArrayProperty(
            columns.slice(0, 12).map(c => {
                return {
                    name: makeStringProperty(getTableColumnDisplayName(c)),
                    value: makeColumnProperty(c.name),
                };
            })
        ),
    };
}

export function makeClassScreen(
    table: TableGlideType,
    fetchesData: boolean,
    components: readonly ComponentDescription[],
    isDetails: boolean,
    withTitle: boolean
): ClassScreenDescription {
    let title: PropertyDescription | undefined;
    if (withTitle) {
        if (isDetails) {
            const summary = makeSummary(table);
            title = summary?.titleProperty;
        } else {
            title = makeStringProperty(sheetNameForTable(table));
        }
    }
    return {
        kind: ScreenDescriptionKind.Class,
        type: makeTableRef(table),
        canEdit: false,
        canEditFilters: [],
        canDelete: false,
        canDeleteFilters: [],
        searchPlaceholder: undefined,
        isForm: false,
        fetchesData,
        title,
        components,
        transforms: [],
    };
}

function makeDefaultClassComponentsFromSummary(
    table: TableGlideType,
    omitNavigation: boolean
): readonly ComponentDescription[] {
    const summary = makeSummary(table);

    const components: ComponentDescription[] = [];
    if (!omitNavigation) {
        components.push(makeBreadcrumbs());
    }

    if (summary === undefined) {
        return components;
    }

    components.push(makeHero(summary));

    // We already used a few columns for the Hero component
    // we don't need to add them to the Fields
    const columnsToFilterOut = mapFilterUndefined(
        [summary.titleProperty, summary.subtitleProperty, summary.imageURLProperty],
        getColumnProperty
    );
    columnsToFilterOut.push(isFavoritedColumnName);

    const primitiveNonHiddenColumns = getPrimitiveNonHiddenColumns(table).filter(
        c => !columnsToFilterOut.includes(c.name)
    );

    components.push(makeFields(primitiveNonHiddenColumns));

    return components;
}

export function makeDefaultClassComponentsForPage(
    table: TableGlideType,
    omitNavigation: boolean
): readonly ComponentDescription[] {
    return makeDefaultClassComponentsFromSummary(table, omitNavigation);
}

export function makeDefaultClassComponents(
    ccc: AppDescriptionContext,
    table: TableGlideType,
    omitNavigation: boolean
): readonly ComponentDescription[] {
    const appKind = getAppKindFromAppDescriptionContext(ccc);
    switch (appKind) {
        case AppKind.App:
            return makeComponentsForTable(ccc, table);
        case AppKind.Page:
            return makeDefaultClassComponentsForPage(table, omitNavigation);
        default:
            return assertNever(appKind);
    }
}

function makeDefaultArrayComponentsFromSummary(
    table: TableGlideType,
    summary: Summary,
    isRootScreen: boolean,
    formScreenName?: string
): readonly ComponentDescription[] {
    const tableName = getTableName(table);
    // FIXME: This should use the component handler, but that's hard because
    // we don't have an ICCC yet.

    const titleActions = definedMap(formScreenName, n => {
        const innerFormScreenAction: FormScreenActionDescription = {
            kind: ActionKind.FormScreen,
            // This might get fully ignored in Pages.
            title: makeStringProperty("Add"),
            formScreenName: n,
            navigationTarget: undefined,
        };

        // FIXME: We should localize this `Add`, right?
        const formScreenAction = makeActionWithTitle("Add", innerFormScreenAction);

        return makeArrayProperty([formScreenAction]);
    });

    const cardStyle = summary.imageURLProperty !== undefined ? CardStyle.Cover : CardStyle.List;

    const cardCollection = {
        ...makeEmptyComponentDescription(ComponentKindInlineList),
        componentTitle: makeStringProperty(sheetNameForTable(table)),
        cardStyle: makeEnumProperty(cardStyle),
        size: makeEnumProperty(UISize.Medium),
        titleStyle: makeEnumProperty(UITitleStyle.Simple),
        propertyName: makeTableProperty(tableName),
        format: makeEnumProperty(ArrayScreenFormat.CardCollection),
        title: summary.titleProperty,
        subtitle: summary.subtitleProperty,
        image: summary.imageURLProperty,
        action: makeActionProperty({ kind: ActionKind.PushDetailScreen }),
        caption: undefined,
        allowSearch: makeSwitchProperty(true),
        reverse: undefined,
        groupByColumn: undefined,
        components: undefined,
        transforms: undefined,
        actions: undefined,
        pageSize: undefined,
        titleActions,
        forEasyTabConfiguration: makeSwitchProperty(true),
        easyCRUDAdd: makeSwitchProperty(true),
        easyCRUDEdit: makeSwitchProperty(true),
        easyCRUDDelete: makeSwitchProperty(false),
    } as CardCollectionComponentDescription;
    if (isRootScreen) {
        return [cardCollection];
    } else {
        return [makeBreadcrumbs(), cardCollection];
    }
}

export function makeDefaultClassScreenForPage(
    table: TableGlideType,
    fetchesData: boolean,
    isRootScreen: boolean
): ClassScreenDescription {
    return makeClassScreen(table, fetchesData, makeDefaultClassComponentsForPage(table, isRootScreen), true, true);
}

export function makeDefaultClassScreen(
    table: TableGlideType,
    ccc: AppDescriptionContext,
    fetchesData: boolean,
    isRootScreen: boolean,
    forNewApp: boolean
): ClassScreenDescription {
    const appKind = getAppKindFromAppDescriptionContext(ccc);
    switch (appKind) {
        case AppKind.App:
            return makeClassScreenDescription(table, ccc, fetchesData, forNewApp);
        case AppKind.Page:
            return makeDefaultClassScreenForPage(table, fetchesData, isRootScreen);
        default:
            return assertNever(appKind);
    }
}

export function makePageScreenComponents(
    table: TableGlideType,
    isRootScreen: boolean,
    formScreenName?: string
): [components: readonly ComponentDescription[], isDetails: boolean] {
    const summary = makeSummary(table);
    if (summary === undefined) return [[], false];

    if (table.numDataRows === 1) {
        return [makeDefaultClassComponentsFromSummary(table, isRootScreen), true];
    } else {
        return [makeDefaultArrayComponentsFromSummary(table, summary, isRootScreen, formScreenName), false];
    }
}

export function makePageScreen(
    table: TableGlideType,
    isRootScreen: boolean,
    withComponents: boolean,
    formScreenName?: string
): ClassScreenDescription {
    if (withComponents) {
        return makeClassScreen(
            table,
            true,
            ...makePageScreenComponents(table, isRootScreen, formScreenName),
            !isRootScreen
        );
    } else {
        return makeClassScreen(table, true, [], false, false);
    }
}

export function makeArrayishScreenDescription(
    table: TableGlideType,
    ccc: AppDescriptionContext,
    isRootScreen: boolean,
    forNewApp: boolean,
    withComponents: boolean
): ScreenDescription {
    const appKind = getAppKindFromAppDescriptionContext(ccc);
    if (appKind === AppKind.App) {
        return makeArrayScreenDescription(getDefaultArrayScreenHandler(), makeTableRef(table), ccc, true, forNewApp);
    } else if (appKind === AppKind.Page) {
        return makePageScreen(table, isRootScreen, withComponents);
    } else {
        return panic(appKind);
    }
}
