import { styled } from "@glide/common";
import { assertNever } from "@glideapps/ts-necessities";

export type Size = 26 | 15;

function getMinHeight(size: Size, minimal: boolean) {
    switch (size) {
        case 26:
            return 32;
        case 15:
            return minimal ? 20 : 24;
        default:
            assertNever(size);
    }
}

export const BreadcrumbStyle = styled.div<{ size: Size }>`
    display: flex;
    align-items: center;

    .brcmb-item {
        transition: color ${p => p.theme.transitionBase};
        cursor: pointer;
        color: ${p => p.theme.textBase};
        font-weight: 600;
        font-size: ${p => p.size}px;
        letter-spacing: -0.015em;
        line-height: 1.2;
        min-height: ${p => getMinHeight(p.size, false)}px;
        min-width: 32px;
        display: flex;
        align-items: center;

        &:hover,
        &:focus {
            color: ${p => p.theme.textBase};
        }

        & > button {
            margin-left: 8px;
        }
    }

    .brcmb-item + .brcmb-item {
        flex-shrink: 0;
        margin-left: 4px;
    }

    .brcmb-current:not(input) {
        color: ${p => p.theme.textDark};
    }

    .brcmb-editable {
        color: ${p => p.theme.textDark};
        cursor: text;
        box-shadow: 0 1px 0 ${p => p.theme.n400A};

        &:hover {
            color: ${p => p.theme.textBase};
        }
        &:focus {
            background: ${p => p.theme.n0};
            box-shadow: 0 0 0 2px ${p => p.theme.b400};
            border-radius: 4px;
            margin-right: 4px;
        }
    }

    input {
        width: 240px;

        &:focus,
        &:hover {
            color: ${p => p.theme.textBase};
            box-shadow: 0 1px 0 ${p => p.theme.b300};
        }
    }

    .edit-button {
        margin-left: 9px;
        display: flex;
        align-items: center;
        color: ${p => p.theme.fgColorMedium};

        :hover {
            cursor: pointer;
            color: ${p => p.theme.fgColorDark};
        }
    }

    .save-btn {
        margin-left: 8px;
    }

    &.is-minimal {
        height: ${p => getMinHeight(p.size, true)}px;
        input {
            width: 176px;
        }

        button {
            visibility: hidden;
            opacity: 0;
            transition: none;
        }

        .brcmb-item {
            min-height: ${p => getMinHeight(p.size, true)}px;
        }

        .brcmb-editable {
            box-shadow: 0 1px 0 transparent;
        }

        .brcmb-wrapper:hover {
            button {
                visibility: visible;
                opacity: 1;
                transition: opacity ${p => p.theme.transitionBase};
            }

            .brcmb-editable {
                transition: all ${p => p.theme.transitionBase};
                transition-property: color, box-shadow;
                box-shadow: 0 1px 0 ${p => p.theme.n400A};
            }
        }

        .brcmb-is-editing + button {
            visibility: visible;
            opacity: 1;
            transition: none;

            > * {
                transition: none;
            }
        }

        .brcmb-is-editing:focus {
            background: transparent;
            box-shadow: 0 1px 0 ${p => p.theme.n400A};
            border-radius: 0;
        }
    }
`;
