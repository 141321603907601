import posthog from "posthog-js";

const publicKey = "phc_CYeeEQAYy3aBXVNKkGixozIAYtyiNhxde4q30cnK533";

export function initializePosthog() {
    posthog.init(publicKey, { api_host: "https://us.i.posthog.com" });
}

type Trait = "email" | "name" | "created_at";

// https://posthog.com/docs/product-analytics/identify
export function identifyPosthogUser(userId: string, traits?: Record<Trait, any>) {
    posthog.identify(userId, traits);
}

// https://posthog.com/docs/product-analytics/capture-events
export function capturePosthogEvent(eventName: string, properties?: Record<string, any>) {
    posthog.capture(eventName, properties);
}
