export enum DeviceFormFactor {
    Phone = "phone",
    Tablet = "tablet",
    Desktop = "desktop",
}

// Right now, Tablet in Pages means Desktop. This is to mess with Apps as little as possible
// but consider using Desktop instead.
export type WireFormFactor = DeviceFormFactor.Phone | DeviceFormFactor.Tablet;

export enum PagePreviewDevice {
    Phone = "phone",
    Desktop = "desktop",
}

export const ScreenshotSizes = {
    [DeviceFormFactor.Phone]: {
        width: 393,
        height: 852,
    },
    [DeviceFormFactor.Tablet]: {
        width: 768,
        height: 1024,
    },
    [DeviceFormFactor.Desktop]: {
        width: 1260,
        height: 874,
    },
};
