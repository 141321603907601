import type { ActionDescription, ActionKind, MutatingScreenKind } from "@glide/app-description";
import type { TableColumn, SchemaInspector } from "@glide/type-schema";
import { type InputOutputTables, hasInputContext } from "@glide/common-core/dist/js/description";
import type { RewritingComponentConfiguratorContext } from "@glide/function-utils";
import { assert, mapFilterUndefined } from "@glideapps/ts-necessities";
import { logError } from "@glide/support";
import type { ActionHandler } from "./action-handler";
import { DummyActionHandler } from "./dummy-action";

const actionHandlers: Map<ActionKind, ActionHandler<ActionDescription>> = new Map();

export function registerActionHandler(handler: ActionHandler<ActionDescription>): void {
    const { kind } = handler;
    assert(!actionHandlers.has(kind));
    actionHandlers.set(kind, handler);
}

let dummyActionHandler: DummyActionHandler | undefined;

export function handlerForActionKind(kind: ActionKind): ActionHandler<ActionDescription> {
    const handler = actionHandlers.get(kind);
    if (handler === undefined) {
        logError("No handler for action kind", kind);
        if (dummyActionHandler === undefined) {
            dummyActionHandler = new DummyActionHandler();
        }
        return dummyActionHandler;
    }
    return handler;
}

export function getPrimitiveActionKinds(
    screenHasInputContext: boolean,
    appHasUserProfiles: boolean
): readonly ActionKind[] {
    return Array.from(actionHandlers.values())
        .filter(h => h.getIsApplicable(screenHasInputContext, appHasUserProfiles))
        .map(h => h.kind);
}

export function getDefaultPrimitiveActionKinds(
    schema: SchemaInspector | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined
): readonly ActionKind[] {
    return getPrimitiveActionKinds(
        hasInputContext(mutatingScreenKind),
        schema === undefined || schema.userProfileTableInfo !== undefined
    );
}

export function defaultActionForColumn(column: TableColumn): ActionDescription | undefined {
    for (const handler of actionHandlers.values()) {
        const desc = handler.newDefaultActionDescription(column);
        if (desc !== undefined) {
            return desc;
        }
    }
    return undefined;
}

export function rewriteActions(
    actions: readonly ActionDescription[] | undefined,
    tables: InputOutputTables,
    ccc: RewritingComponentConfiguratorContext,
    mutatingScreenKind: MutatingScreenKind | undefined
): readonly ActionDescription[] | undefined {
    if (actions === undefined) return undefined;
    return mapFilterUndefined(actions, action => {
        const handler = handlerForActionKind(action.kind);
        return handler.rewriteAfterReload(action, tables, ccc, mutatingScreenKind);
    });
}
