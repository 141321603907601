/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { assert, definedMap, isArray, mapFilterUndefined } from "@glideapps/ts-necessities";
import { checkString, isEmpty, maybeParseJSON } from "@glide/support";
import flatMap from "lodash/flatMap";
import { v4 as uuid } from "uuid";
import { parseWaitDuration, waitDurationParameter } from "./human-in-the-loop";
import { parseURLEncoded } from "./parse-urlencoded";

const { Result } = glide;

const botName = glide.makeParameter({
    type: "string",
    name: "Bot name",
    description: "The display name of your Slack bot. If not set, your app's name will be used.",
    placeholder: "My App",
});

const botImage = glide.makeParameter({
    type: "string",
    name: "Bot avatar",
    description: "You can use a URL of an image or an emoji as the avatar. If not set, your app's icon will be used.",
    placeholder: ":rocket:",
});

const botDetailsPropertySection = {
    name: "Bot",
    order: 0,
    collapsed: false,
};

const botNameOverride = glide.makeParameter({
    type: "string",
    name: "Name",
    description: "Override the bot's name.",
    placeholder: "My App",
    propertySection: botDetailsPropertySection,
    useTemplate: "withLabel",
});

const botImageOverride = glide.makeParameter({
    type: "string",
    name: "Avatar",
    description: "Override the bot image. Use an emoji or a URL of an image.",
    placeholder: ":rocket:",
    propertySection: botDetailsPropertySection,
    useTemplate: "withLabel",
});

export const plugin = glide.newPlugin({
    id: "slack-bot",
    name: "Slack",
    description: "Send messages to your Slack workspace",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/slack.png",
    tier: "starter",
    auth: {
        provider: "slack-bot",
        scopes: ["chat:write", "chat:write.customize", "channels:read", "app_mentions:read", "files:read"],
    },
    parameters: {
        botName,
        botImage,
    },
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/slack",
});

const channel = glide.makeParameter({
    type: "string",
    name: "Channel",
    description: "Set the channel where the message should be sent",
    placeholder: "e.g. #general",
    required: true,
    useTemplate: "withLabel",
});

const message = glide.makeParameter({
    type: "string",
    name: "Message",
    required: true,
    useTemplate: "withLabel",
});

const advancedSection = {
    name: "Advanced",
    order: 1,
    collapsed: true,
};

const rawBlocks = glide.makeParameter({
    type: "json",
    name: "Blocks",
    description:
        "Use Slack blocks to send more complex messages. Read more in [Slack's API docs](https://api.slack.com/block-kit)",
    propertySection: advancedSection,
});

const threadTs = glide.makeParameter({
    type: "string",
    name: "Thread",
    description: "Posts the message to a thread. If not present, posts the message to the channel.",
    propertySection: advancedSection,
});

const threadTsResult = glide.makeParameter({
    type: "string",
    name: "Thread",
    description: "Thread ID of the message",
    propertySection: {
        name: "Result",
        order: 2,
        collapsed: false,
    },
});

function coerceBlocks(blocks: unknown): glide.Result<unknown[]> {
    const failure = Result.FailPermanent("Blocks must be a JSON array of objects", { data: blocks });
    if (typeof blocks === "string") {
        try {
            blocks = JSON.parse(blocks);
        } catch (e: unknown) {
            return failure;
        }
    }
    if (typeof blocks === "object" && !Array.isArray(blocks)) {
        blocks = [blocks];
    }
    if (!Array.isArray(blocks)) return failure;
    return glide.Result.Ok(blocks);
}

interface PayloadBase {
    readonly username: string;
    readonly icon_emoji?: string;
    readonly icon_url?: string;
    readonly channel: string;
    readonly text: string;
    readonly thread_ts?: string;
}

interface Payload extends PayloadBase {
    readonly blocks: readonly unknown[];
}

async function getPayloadBase(
    app: glide.BaseAppData,
    channel: string | undefined,
    message: string | undefined,
    botName: string | undefined,
    botImage: string | undefined,
    threadTs: string | undefined
): Promise<glide.Result<PayloadBase>> {
    channel = channel ?? "";
    if (channel === "") return Result.FailPermanent("Channel must be specified");

    message = message ?? "";
    if (message === "") return Result.FailPermanent("Message parameter must not be empty");

    botName = botName ?? app.name;
    if (botImage === undefined) {
        const icon = await app.getIcon();
        if (icon === undefined) return Result.Fail("Could not get icon");
        botImage = icon;
    }

    const iconIsEmoji = /^:(.*):$/.test(botImage);
    const iconProps = iconIsEmoji ? { icon_emoji: botImage } : { icon_url: botImage };

    return glide.Result.Ok({
        username: botName,
        ...iconProps,
        channel,
        text: message,
        thread_ts: threadTs,
    });
}

async function postMessage(context: glide.ServerExecutionContext, payload: Payload) {
    function explainError(response: string): string {
        switch (response) {
            case "not_in_channel":
                return `You must add the Glide Slack bot to ${payload.channel} channel`;
            case "invalid_blocks":
                return `The message was empty or broken`;
            default:
                return response;
        }
    }

    try {
        // https://api.slack.com/methods/chat.postMessage
        const response = await context.fetch("https://slack.com/api/chat.postMessage", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        const asText = await response.text();

        if (!response.ok) {
            return Result.FailFromHTTPStatus(explainError(asText), response.status, {
                data: maybeParseJSON(asText),
            });
        } else {
            const asJSON = JSON.parse(asText);
            if (asJSON.ok === true) {
                context.consumeBillable();
                return Result.Ok({ threadTs: asJSON.ts });
            }
            return Result.FailFromHTTPStatus(explainError(asJSON.error), response.status, {
                data: maybeParseJSON(asText),
            });
        }
    } catch (e: unknown) {
        return Result.Fail("There was an unknown error", {
            data: maybeParseJSON(e),
        });
    }
}

function makeDefaultRawBlocks(message: string | undefined) {
    return [
        {
            type: "section",
            text: {
                type: "mrkdwn",
                text: message,
            },
        },
    ];
}

plugin.addAction({
    id: "send-slack-message",
    name: "Send message to channel",
    description: "Send a message to a channel",
    parameters: { channel, message, rawBlocks, botNameOverride, botImageOverride, threadTs },
    results: { threadTs: threadTsResult },
    billablesConsumed: 1,
    execute: async (
        context,
        {
            channel,
            message,
            rawBlocks = makeDefaultRawBlocks(message),
            threadTs,
            // These parameters are defined at the integration level, and used as defaults
            botName: botNameIntegration,
            botImage: botImageIntegration,
            // The overrides are defined at the action level, and override the integration defaults if present
            botNameOverride: botName = botNameIntegration,
            botImageOverride: botImage = botImageIntegration,
        }
    ) => {
        const payloadBase = await getPayloadBase(context.app, channel, message, botName, botImage, threadTs);
        if (!payloadBase.ok) return payloadBase;

        const blocks = coerceBlocks(rawBlocks);
        if (!blocks.ok) return blocks;

        const payload = {
            ...payloadBase.result,
            blocks: blocks.result,
        };

        if (isEmpty(payload.text)) {
            return Result.FailPermanent("Message parameter must not be empty");
        }

        return await postMessage(context, payload);
    },
});

const channelIDsParameter = glide.makeParameter({
    type: "string",
    name: "Channel IDs",
    description: "Only trigger when the message is sent in one of these channels (comma-separated)",
    placeholder: "C12345678, C87654321",
});
const userIDsParameter = glide.makeParameter({
    type: "string",
    name: "User IDs",
    description: "Only trigger when the message is sent by one of these users (comma-separated)",
    placeholder: "U12345678, U87654321",
});
// const requireFilesParameter = glide.makeParameter({
//     type: "boolean",
//     name: "Only with files",
//     description: "Only trigger if the message has one or more files attached",
// });

const userIDResult = glide.makeParameter({
    type: "string",
    name: "User ID",
    description: "The ID of the user who sent the message",
    placeholder: "U12345678",
});
const channelIDResult = glide.makeParameter({
    type: "string",
    name: "Channel ID",
    description: "The ID of the channel where the message was sent",
    placeholder: "C12345678",
});
const messageTextResult = glide.makeParameter({
    type: "string",
    name: "Message",
    description: "The message that was sent",
    placeholder: "Hello, world!",
});
const messageBlocksResult = glide.makeParameter({
    type: "json",
    name: "Blocks",
    description: "The message blocks that were sent",
    propertySection: advancedSection,
    placeholder: JSON.stringify(
        {
            type: "markdown",
            text: "**Lots of information here!!**",
        },
        null,
        4
    ),
});
const tsResult = glide.makeParameter({
    type: "string",
    name: "Message ID",
    description: "The ID of the message",
    propertySection: advancedSection,
    placeholder: "1405894322.002768",
});
const threadTSResult = glide.makeParameter({
    type: "string",
    name: "Thread ID",
    description: "The thread's ID, for replying to the message",
    propertySection: advancedSection,
    placeholder: "1234567890.123456",
});
const isInThreadResult = glide.makeParameter({
    type: "boolean",
    name: "In thread?",
    description: "Is the message in a thread, as opposed to on the top level?",
    propertySection: advancedSection,
});
// const filesResult = glide.makeParameter({
//     type: "stringArray",
//     name: "Files",
//     description: "URLs to files in the message",
//     propertySection: advancedSection,
// });

// interface File {
//     readonly url: string;
//     readonly name: string | undefined;
//     readonly mimeType: string | undefined;
// }

interface TriggerRawData {
    readonly userID: string | undefined;
    readonly channelID: string;
    readonly messageText: string;
    // This array can't be `readonly` or `unknown` because of some plugin type
    // stuff.
    readonly messageBlocks: any[] | undefined;
    readonly ts: string;
    readonly threadTS: string;
    readonly isInThread: boolean;
    // readonly files: readonly File[];
}

const triggerParameter = plugin.addTrigger({
    id: "message-trigger",
    name: "Slack message",
    description: "Triggered by a Slack message",
    experimentFlag: "pluginSlackTrigger",
    parameters: {
        channelIDs: channelIDsParameter,
        userIDs: userIDsParameter,
        // requireFiles: requireFilesParameter,
    },
    results: {
        userID: userIDResult,
        channelID: channelIDResult,
        messageText: messageTextResult,
        messageBlocks: messageBlocksResult,
        ts: tsResult,
        threadTS: threadTSResult,
        isInThread: isInThreadResult,
        // files: filesResult,
    },
    badge: "Beta",
    shouldTrigger: async (
        { channelIDs: channelIDsString, userIDs: userIDsString /* requireFiles */ },
        rawData: TriggerRawData
        // serverExecutionContext
    ) => {
        const channelIDs = mapFilterUndefined(channelIDsString?.split(",") ?? [], s => {
            s = s.trim();
            if (s === "") return undefined;
            return s;
        });
        const userIDs = mapFilterUndefined(userIDsString?.split(",") ?? [], s => {
            s = s.trim();
            if (s === "") return undefined;
            return s;
        });
        if (channelIDs.length > 0) {
            if (!channelIDs.includes(rawData.channelID)) return Result.Ok(undefined);
        }
        if (userIDs.length > 0) {
            if (rawData.userID === undefined) return Result.Ok(undefined);
            if (!userIDs.includes(rawData.userID)) return Result.Ok(undefined);
        }

        // if (requireFiles === true && rawData.files.length === 0) return Result.Ok(undefined);

        // const uploadResults: glide.Result<string>[] = await allSettled(
        //     rawData.files.map(async f => serverExecutionContext.rehostFile(f.name ?? "Unnamed", f.url, f.mimeType))
        // );
        // const urls: string[] = [];
        // for (const r of uploadResults) {
        //     if (!r.ok) return r;
        //     urls.push(r.result);
        // }

        // return Result.Ok({ ...rawData, files: urls });

        return Result.Ok(rawData);
    },
});

const optionResult = glide.makeParameter({
    type: "string",
    name: "Option",
    description: "The option chosen by the user",
    required: true,
});

const optionSignalParameter = plugin.addSignal({
    id: "slack-bot-option-signal",
    results: { option: optionResult, userID: userIDResult, threadTs: threadTsResult },
});

function makeBlockID(signalID: string, appID: string) {
    return `${signalID}:${appID}`;
}

function parseBlockID(blockID: string) {
    const index = blockID.indexOf(":");
    if (index === -1) return undefined;
    const signalID = blockID.substring(0, index);
    const appID = blockID.substring(index + 1);
    return { signalID, appID };
}

plugin.addEndpoint({
    name: "slack-bot-webhook",
    handle: async (endpointExecutionContext, req) => {
        if (req.method !== "POST") return endpointExecutionContext.makeResponseResult(400, "Only POST allowed");

        let json: any;
        if (req.headers["content-type"] === "application/x-www-form-urlencoded") {
            const headers = parseURLEncoded(req);
            json = JSON.parse(headers["payload"]);
        } else {
            json = req.body;
        }

        endpointExecutionContext.log("slack webhook body", JSON.stringify(json));

        const verificationToken = endpointExecutionContext.getSecret("verificationToken");
        if (verificationToken === undefined) {
            return endpointExecutionContext.makeResponseResult(500, "verificationToken is not set up");
        }
        if (json.token !== verificationToken) {
            return endpointExecutionContext.makeResponseResult(400, "Invalid verification token");
        }

        if (json.type === "url_verification") {
            return endpointExecutionContext.makeResponseResult(200, json.challenge, {
                "Content-Type": "text/plain",
            });
        } else if (json.type === "event_callback" && json.event.type === "app_mention") {
            assert(json.authorizations.length === 1, `Expected 1 authorization, got ${json.authorizations.length}`);
            const botUserID = checkString(json.authorizations[0].user_id);
            const ts = checkString(json.event.ts);
            const threadTS = definedMap(json.event.thread_ts ?? json.event.message?.thread_ts, checkString);

            const rawData: TriggerRawData = {
                userID: definedMap(json.event.user ?? json.event.message?.user, checkString),
                channelID: checkString(json.event.channel),
                messageText: checkString(json.event.text ?? json.event.message?.text),
                messageBlocks: json.event.blocks ?? json.event.message?.blocks,
                ts,
                threadTS: threadTS ?? ts,
                isInThread: threadTS !== undefined,
                // files: (json.event.files ?? []).map((f: any) => ({
                //     name: definedMap(f.name ?? undefined, checkString),
                //     url: checkString(f.url_private),
                //     mimeType: checkString(f.mimetype),
                // })),
            };

            const numInvoked = await endpointExecutionContext.invokeTriggers(
                triggerParameter,
                { oauthCredentialID: botUserID },
                rawData,
                checkString(json.event_id)
            );

            // For some reason eslint doesn't accept `!numInvoked.ok` here.
            if (numInvoked.ok !== true) return endpointExecutionContext.makeResponseResultFromPluginResult(numInvoked);

            if (numInvoked.result === 0) {
                endpointExecutionContext.log("No Slack triggers applied", JSON.stringify(rawData));
            }

            return endpointExecutionContext.makeResponseResultFromPluginResult(numInvoked);
        } else if (json.type === "block_actions") {
            assert(isArray(json.actions));
            const action = json.actions.find((a: any) => a?.type === "button");
            const message = json.message;
            assert(typeof message === "object" && message !== null);
            assert(isArray(message.blocks));

            if (action !== undefined) {
                const parsed = parseBlockID(checkString(action.block_id));
                if (parsed !== undefined) {
                    const { signalID, appID } = parsed;
                    const userID = checkString(json.user.id);
                    const threadTS = checkString(message.ts);
                    const pickedOption = checkString(action.value);

                    return endpointExecutionContext.finishWithApp(appID, false, async (_params, context) => {
                        const sendResult = await endpointExecutionContext.sendSignal(optionSignalParameter, signalID, {
                            option: pickedOption,
                            userID,
                            threadTs: threadTS,
                        });
                        if (!sendResult.ok) {
                            return endpointExecutionContext.makeResponseResultFromPluginResult(sendResult);
                        }

                        const responseURL = definedMap(json.response_url ?? undefined, checkString);
                        if (responseURL !== undefined) {
                            await context.fetch(responseURL, {
                                method: "POST",
                                body: JSON.stringify({
                                    replace_original: true,
                                    blocks: [
                                        ...message.blocks.filter((b: any) => checkString(b.type) !== "actions"),
                                        {
                                            type: "section",
                                            text: {
                                                type: "mrkdwn",
                                                text: `<@${userID}> picked *${pickedOption}*.`,
                                            },
                                        },
                                    ],
                                }),
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            });
                        }

                        return endpointExecutionContext.makeResponseResultFromPluginResult(sendResult);
                    });
                }
            }
        }
        return endpointExecutionContext.makeResponseResult(200, `Ignoring event ${json.type} / ${json.event?.type}`);
    },
});

const optionsParameter = glide.makeParameter({
    type: "stringArray",
    name: "Options",
    description: "The options to choose from",
    required: true,
});

plugin.addAction({
    id: "slack-ask-option",
    name: "Ask a user to choose an option",
    description: "Ask a user to choose an option",
    parameters: {
        channel,
        message,
        rawBlocks,
        options: optionsParameter,
        waitDuration: waitDurationParameter,
        botNameOverride,
        botImageOverride,
        threadTs,
    },
    results: optionSignalParameter.results,
    billablesConsumed: 1,
    experimentFlag: "humanInTheLoop",
    execute: async (
        context,
        {
            channel,
            message,
            rawBlocks = makeDefaultRawBlocks(message),
            threadTs,
            // These parameters are defined at the integration level, and used as defaults
            botName: botNameIntegration,
            botImage: botImageIntegration,
            // The overrides are defined at the action level, and override the integration defaults if present
            botNameOverride: botName = botNameIntegration,
            botImageOverride: botImage = botImageIntegration,
            options,
            waitDuration,
        }
    ) => {
        options = flatMap(options ?? [], x => x.split(",").map(y => y.trim())).filter(x => x !== "");
        if (options.length === 0) return Result.FailPermanent("At least one option must be specified");

        const waitDurationMS = parseWaitDuration(waitDuration);
        if (!waitDurationMS.ok) return waitDurationMS;

        const payloadBase = await getPayloadBase(context.app, channel, message, botName, botImage, threadTs);
        if (!payloadBase.ok) return payloadBase;

        const blocks = coerceBlocks(rawBlocks);
        if (!blocks.ok) return blocks;

        const signalID = uuid();

        const payload = {
            ...payloadBase.result,
            blocks: [
                ...blocks.result,
                {
                    type: "actions",
                    block_id: makeBlockID(signalID, context.app.id),
                    elements: options.map((text, i) => ({
                        type: "button",
                        text: {
                            type: "plain_text",
                            text,
                        },
                        value: text,
                        // We put the app ID in each `action_id` because Slack
                        // doesn't let us put a JSON string into the
                        // `block_id`.
                        action_id: `action_${i}`,
                    })),
                },
            ],
        };

        const result = await postMessage(context, payload);
        if (!result.ok) return result;

        return context.waitForSignal(optionSignalParameter, signalID, waitDurationMS.result);
    },
});
