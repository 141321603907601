import {
    DeviceFormFactor,
    GlideGlobalStyle,
    browserMightBeOniOS,
    useIsWireThemeDark,
    useResizeDetector,
} from "@glide/common";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { useIsPreview } from "@glide/common-core/dist/js/hooks/use-is-player-or-preview";
import { isPlayer } from "@glide/common-core/dist/js/routes";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { emptyScreen } from "@glide/hydrated-ui";
import {
    ResponsiveProvider,
    isSmallScreen,
    useResponsiveSizeClass,
    useRootResponsiveSizeClass,
} from "@glide/common-components";
import { ignore, isDefined, nonNull, Watchable } from "@glide/support";
import type { ResponsiveSizeClass, WirePageNavigationModel } from "@glide/wire";
import { WireModalSize, WireScreenFlag } from "@glide/wire";
import chroma from "chroma-js";
import classNames from "classnames";
import { definedMap } from "collection-utils";
import { AnimatePresence, LazyMotion, domMax, useMotionValue } from "framer-motion";
import * as React from "react";
import { createPortal } from "react-dom";
import toast, { Toaster } from "react-hot-toast";
import { css } from "styled-components";
import tw from "twin.macro";
import { useSpinDelay } from "spin-delay";

import { OverlayBackdrop, PageTransitions } from "../chrome-common";
import { MadeWithGlide, MadeWithGlideIcon } from "../components/made-with-glide";
import type { PagesRendererComponentDependencies } from "../renderers/pages-component-renderer";
import { isSimpleTitle } from "../renderers/wire-hero/wire-hero-lib";
import { getComponentTitle } from "../utils/get-collection-title";
import { isCollectionScreen } from "../utils/is-collection-screen";
import { isTitleFirstRenderableComponent } from "../utils/is-title-first-renderable-component";
import { runActionAndHandleURL } from "../wire-lib";
import { AddToHomescreen } from "./add-to-homescreen/add-to-homescreen";
import { useAddToHomescreen } from "./add-to-homescreen/use-add-to-homescreen";
import { getRenderableComponentIndexAtPosition } from "./content/lib/get-renderable-component-for-possition";
import { useScreenComponents } from "./content/lib/use-layer-compoents";
import type { MainContentRef } from "./content/main-content";
import { ChromeMainContent } from "./content/main-content";
import { ChromeOverlayContent } from "./content/overlay-content";
import { ChromeSlideInContent } from "./content/slide-in-content";
import { ChromeMobileSidemenu, pushedContentCSS } from "./nav-bar/mobile-sidemenu";
import { ChromeMobileTabBar } from "./nav-bar/mobile-tab-bar";
import type { UnifiedNavBarProps } from "./nav-bar/nav-bar-common";
import { getFormOrEditControls } from "./nav-bar/nav-bar-common";
import { UnifiedNavBar } from "./nav-bar/unified-nav-bar";
import { UnifiedSideBar } from "./nav-bar/unified-side-bar";
import { useWireAppTheme } from "../utils/use-wireapp-theme";
import { useAppEnvironment } from "@glide/common-core/dist/js/use-app-environment";

const globalPageCss = css`
    .accent-bg {
        background: ${p => p.theme.primaryAccentColor};
    }

    --gv-color-scheme: light;
    &.dark-theme {
        --gv-color-scheme: dark;
    }

    .accent-translucent {
        background: ${p => chroma(p.theme.primaryAccentColor).alpha(0.9).css()};
        backdrop-filter: blur(12px);
    }

    & .no-sidebar {
        flex-direction: column !important;
        .topbar-root {
            display: flex !important;
        }

        .sidebar-root {
            display: none !important;
        }
    }

    @keyframes push-in {
        from {
            transform: translateX(20px);
        }

        to {
            transform: translateX(0);
        }
    }

    @keyframes pop-out {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(20px);
        }
    }

    @media (prefers-reduced-motion) {
        @keyframes push-in {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

        @keyframes pop-out {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
            }
        }
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    & .${PageTransitions.pushIn} {
        animation-name: push-in;
    }
    & .${PageTransitions.popOut} {
        animation-name: pop-out;
    }
    & .${PageTransitions.fadeIn} {
        animation-name: fade-in;
    }
    & .${PageTransitions.fadeOut} {
        animation-name: fade-out;
    }

    .${PageTransitions.pushIn}, .${PageTransitions.popOut}, .${PageTransitions.fadeIn}, .${PageTransitions.fadeOut} {
        animation-iteration-count: 1;
        animation-duration: 130ms;
        animation-timing-function: ease-out;
        animation-direction: normal;
        will-change: transform, opacity;
    }
`;

interface FontSizeProviderProps {
    className?: string;
}

const FontSizeProvider: React.FC<React.PropsWithChildren<FontSizeProviderProps>> = p => {
    const { children, className } = p;

    return (
        <div
            className={className}
            tw="[--text-size-base: 16px] [--text-size-xs: 12px] [--text-size-sm: 14px] [--text-size-lg: 18px]
            page-md:([--text-size-base: 14px] [--text-size-xs: 11px] [--text-size-sm: 12px] [--text-size-lg: 16px])">
            {children}
        </div>
    );
};

interface Props extends React.PropsWithChildren {
    readonly navModel: WirePageNavigationModel;
    readonly backend: WireBackendInterface;
    readonly componentDependencies: PagesRendererComponentDependencies;
}

export const UnifiedChrome: React.VFC<Props> = p => {
    const { backend, navModel, componentDependencies } = p;

    const theme = useWireAppTheme();
    const isWireThemeDark = useIsWireThemeDark(theme.pageEnvironment);
    const className = classNames(navModel.lastNavigation?.navigationAction, isWireThemeDark && "dark-theme");
    return (
        <LazyMotion features={domMax}>
            <ResponsiveProvider
                testID="chrome-root"
                id="page-root"
                isRoot
                tw="absolute inset-0 text-base [min-width:320px] [&.dark-theme]:[color-scheme: dark]"
                className={className}
                css={globalPageCss}>
                <FontSizeProvider tw="absolute inset-0">
                    <UnifiedChromeImpl
                        backend={backend}
                        navModel={navModel}
                        componentDependencies={componentDependencies}
                    />
                </FontSizeProvider>
            </ResponsiveProvider>
        </LazyMotion>
    );
};

const mobileFormButtonsHeightInPX = 80;

function useUpdateScreenSizeAppData(appID: string) {
    const appEnv = useAppEnvironment(appID);
    const screenSize = useRootResponsiveSizeClass();
    if (appEnv === undefined) return;

    if (appEnv.appData.screenSize instanceof Watchable) {
        appEnv.appData.screenSize.current = isSmallScreen(screenSize) ? "small" : "large";
    }
}

const UnifiedChromeImpl: React.VFC<Props> = p => {
    const { backend, navModel, componentDependencies } = p;
    const currentScreen = navModel.screen;
    const belowScreen = navModel.belowScreen;
    const modalScreen = navModel.modal;
    const lastNavigation = navModel.lastNavigation;
    useUpdateScreenSizeAppData(backend.appID);

    const { ref: navBarRef, height: navBarHeight } = useResizeDetector();

    const [dismissedBlockingMessage, setDismissedBlockingMessage] = React.useState(false);

    const theme = useWireAppTheme();

    const maxSize: ResponsiveSizeClass =
        theme.pageContainer === "Narrow" ? "lg" : theme.pageContainer === "Tight" ? "xl" : "2xl";

    const mainLayerScrollingRegion = React.useRef<MainContentRef>(null);
    const scrollToTop = () => {
        mainLayerScrollingRegion.current?.scrollToTop();
    };

    const [showNavMenu, setShowNavMenu] = React.useState(false);

    const [mustShowTitleInNavBar, setMustShowTitleInNavBar] = React.useState(false);

    const sizeClass = useResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);
    const onScroll: React.UIEventHandler<HTMLDivElement> = React.useCallback(
        e => {
            if (isMobile) {
                setMustShowTitleInNavBar(e.currentTarget.scrollTop > 30);
            }
        },
        [isMobile]
    );

    React.useEffect(() => {
        if (isMobile) {
            backend.setFormFactor(DeviceFormFactor.Phone);
        } else {
            // setFormFactor only supports Phone and Tablet, cause it comes from Classic Apps
            // I'm using Tablet as "not mobile" for simplicity
            backend.setFormFactor(DeviceFormFactor.Tablet);
        }
    }, [backend, isMobile]);

    const detailsTitle = modalScreen?.title ?? currentScreen.title;
    const onDragBack = definedMap(currentScreen.dragBackAction?.token, t => () => {
        backend.runAction(t, false);
        setMustShowTitleInNavBar(false);
    });

    const mainCanGoBack = currentScreen.backAction?.token !== undefined;
    const modalCanGoBack = modalScreen?.backAction?.token !== undefined && modalScreen.size !== WireModalSize.SlideIn;
    const canGoBack = mainCanGoBack || modalCanGoBack;
    const onBack = () => {
        if (mainCanGoBack) {
            runActionAndHandleURL(currentScreen.backAction, backend);
        }

        if (modalCanGoBack) {
            runActionAndHandleURL(modalScreen.backAction, backend);
        }
    };

    const onModalClose = React.useCallback(() => {
        runActionAndHandleURL(modalScreen?.closeAction, backend);
    }, [backend, modalScreen?.closeAction]);

    const modalSpecialComponents = modalScreen?.specialComponents.filter(isDefined);
    const modalFormControls = definedMap(modalSpecialComponents, c => getFormOrEditControls(c, backend));
    const isModalFormOrEdit = modalFormControls !== undefined;

    const mainScreenSpecialComponents = currentScreen.specialComponents.filter(isDefined);
    const mainScreenFormControls = definedMap(mainScreenSpecialComponents, c => getFormOrEditControls(c, backend));
    const isMainScreenFormOrEdit = mainScreenFormControls !== undefined;

    const { tabs, flyoutTabs = [] } = navModel;
    const tabsInPrimaryNav = tabs.slice(0, 6);
    const tabsInSecondaryNav =
        tabs.length === 1 && isMobile ? [...tabs, ...flyoutTabs] : [...tabs.slice(6), ...flyoutTabs];
    const allTabs = {
        primary: tabsInPrimaryNav,
        secondary: tabsInSecondaryNav,
    };

    const belowScreenCanGoBack = belowScreen?.backAction?.token !== undefined;
    const belowScreenTitle = belowScreenCanGoBack ? belowScreen.title : navModel.appTitle;
    const navBarCrossfadeMotionValue = useMotionValue(0);

    const topComponents = useScreenComponents(currentScreen);
    const topFirstRenderableComponentIndex = getRenderableComponentIndexAtPosition(topComponents, 0) ?? 0;
    const topFirstComponent = topComponents[topFirstRenderableComponentIndex];
    const topFirstComponentTitle = getComponentTitle(topFirstComponent, isMobile);
    const isTopIsCollectionScreen = isCollectionScreen(topComponents);
    const isTopHasTitleAsFirstRenderableComponent = isTitleFirstRenderableComponent(topComponents, isMobile);

    const showComponentTitleAsPageTitle = isTopIsCollectionScreen || isTopHasTitleAsFirstRenderableComponent;

    const belowComponents = useScreenComponents(belowScreen ?? emptyScreen);
    const belowFirstRenderableComponentIndex = getRenderableComponentIndexAtPosition(belowComponents, 0) ?? 0;
    const belowFirstComponent = belowComponents[belowFirstRenderableComponentIndex];
    const belowFirstComponentTitle = getComponentTitle(belowFirstComponent, isMobile);

    const isInModal = modalScreen !== undefined && modalScreen.size !== WireModalSize.SlideIn;

    const signedInUserEmail = navModel.userProfileButton?.signedInUserEmail;
    const signedInUserName = navModel.userProfileButton?.signedInUserName;
    const signedInUserImage = massageImageUrl(
        navModel.userProfileButton?.signedInUserImage,
        { thumbnail: true },
        backend.appID
    );

    const onUserButtonPressed = definedMap(
        navModel.userProfileButton?.userProfileAction?.token,
        _ => () => runActionAndHandleURL(navModel.userProfileButton?.userProfileAction, backend)
    );
    const onSignInPressed = definedMap(
        navModel.signInAction?.token,
        _ => () => runActionAndHandleURL(navModel.signInAction, backend)
    );
    const onSignUpPressed = definedMap(
        navModel.signUpAction?.token,
        _ => () => runActionAndHandleURL(navModel.signUpAction, backend)
    );
    const onSignOutPressed = definedMap(
        navModel.signOutAction?.token,
        _ => () => runActionAndHandleURL(navModel.signOutAction, backend)
    );

    const title = isMobile
        ? showComponentTitleAsPageTitle
            ? topFirstComponentTitle
            : currentScreen.title
        : navModel.appTitle;

    const hasSignIn = [onUserButtonPressed, onSignInPressed, onSignOutPressed, onSignUpPressed].some(isDefined);

    const {
        showingAddToHomescreenDialog,
        closeAddToHomescreenDialog,
        showAddToHomescreenDialog: showAddToHomescreenDialogImpl,
        showInstallApp,
    } = useAddToHomescreen();

    const showMenuButton = hasSignIn || tabsInSecondaryNav.length > 0 || showInstallApp;

    const showTransparentNavBar =
        isTopHasTitleAsFirstRenderableComponent &&
        !mustShowTitleInNavBar &&
        !isSimpleTitle(topFirstComponent, isMobile);

    const navbarProps: UnifiedNavBarProps = {
        title,
        logo: theme.signInLogo,
        iconImage: navModel.iconImage,
        tabs: allTabs,
        isUserProfileActive: navModel.userProfileButton?.userProfileScreenIsOpen === true,
        signedInUserEmail,
        signedInUserName,
        signedInUserImage,
        onUserButtonPressed,
        selected: allTabs.primary.find(x => x.isActive),
        onSelectionChange: x => runActionAndHandleURL(x.action, backend),
        onSignInPressed,
        onSignUpPressed,
        onSignOutPressed,
        navigateToRoot: definedMap(
            navModel.appTitleAction?.token,
            _ => () => runActionAndHandleURL(navModel.appTitleAction, backend)
        ),
        detailsTitle,
        belowScreenTitle,
        isInDetailsScreen: !navModel.isTabRootScreen,
        isInModal,
        scrollToTop,
        canGoBack,
        belowScreenCanGoBack,
        onBack,
        setShowMenu: setShowNavMenu,
        isInFormOrEditModal: isModalFormOrEdit,
        onModalClose,
        modalFormControls,
        containerRef: navBarRef,
        navBarCrossfadeMotionValue,
        topFirstComponentHasTitle: topFirstComponentTitle.length > 0,
        belowFirstComponentHasTitle: belowFirstComponentTitle.length > 0,
        mustShowTitleInNavBar,
        showMenuButton,
        showTransparentNavBar,
        appID: backend.appID,
    };

    const [isOverlayScrolled, setIsOverlayScrolled] = React.useState(false);

    const backdropOpacity = modalScreen !== undefined ? 1 : 0;
    const backdropZIndex = modalScreen !== undefined ? 20 : 0;
    const onBackdropClick = React.useCallback(() => {
        if (!isModalFormOrEdit) {
            onModalClose();
        }

        setShowNavMenu(false);
    }, [isModalFormOrEdit, onModalClose]);

    const { ref: mobileTabBarRef, height: mobileTabBarHeight } = useResizeDetector();

    const isSlideIn = modalScreen?.size === WireModalSize.SlideIn;
    // We want to raise the made with Glide badge when we're in slide in and we don't have a side menu
    const raiseMadeWithGlideBadge = isSlideIn && !showMenuButton;

    const isTabBarVisible = modalScreen === undefined && tabsInPrimaryNav.length > 1 && !isMainScreenFormOrEdit;
    const actualMobileTabBarHeight = isTabBarVisible ? mobileTabBarHeight ?? 0 : 0;

    const madeWithGlideBadgeYPosition = raiseMadeWithGlideBadge
        ? mobileFormButtonsHeightInPX
        : actualMobileTabBarHeight;

    // requests take a while to load in dev, so we increase the delay to 3 seconds
    const delay = window.location.hostname === "localhost" ? 3000 : 2000;
    const showToast = useSpinDelay(navModel.busyMessage !== undefined, { delay, minDuration: 100 });

    React.useEffect(() => {
        if (showToast && navModel.busyMessage !== undefined) {
            const toastId = toast.loading(navModel.busyMessage, { className: "glide-toast-dark" });
            return () => toast.dismiss(toastId);
        }
        return undefined;
    }, [showToast, navModel.busyMessage]);

    React.useLayoutEffect(() => {
        // Yes, I'm doing this. I'm not sorry.
        ignore(currentScreen.key);
        setMustShowTitleInNavBar(false);
    }, [currentScreen.key]);

    const showAddToHomescreenDialog = () => {
        setShowNavMenu(false);
        showAddToHomescreenDialogImpl();
    };
    return (
        <>
            {navModel.blockingMessage !== undefined && !dismissedBlockingMessage && (
                <div
                    onClick={() => setDismissedBlockingMessage(true)}
                    tw="absolute inset-0 p-10 [background-color: rgba(17, 24, 39, 0.33)] z-blocking-message text-text-dark
                        flex flex-col justify-center items-center">
                    <div tw="w-full [max-width:480px] rounded-xl shadow-2xl-dark">
                        <div tw="rounded-xl overflow-hidden [background-color: #374664E0] backdrop-blur">
                            <div tw="px-10 pt-10 pb-8 bg-bg-front" onClick={e => e.stopPropagation()}>
                                <h2 tw="mb-2 text-xl font-bold">{navModel.blockingMessage.title}</h2>
                                <div tw="mb-2 text-sm whitespace-pre-wrap break-words">
                                    {navModel.blockingMessage.message}
                                </div>
                                {navModel.blockingMessage.link !== undefined && (
                                    <a
                                        tw="text-sm text-b400"
                                        href={navModel.blockingMessage.link.url}
                                        target="_blank"
                                        rel="noreferrer noopener">
                                        {navModel.blockingMessage.link.text}
                                    </a>
                                )}
                            </div>
                            <a
                                href="https://glideapps.com"
                                target="_blank"
                                rel="noreferrer noopener"
                                tw="flex items-center px-8 h-16 text-white all-child:h-8">
                                <MadeWithGlideIcon />
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {isPlayer() &&
                createPortal(
                    <div id="toaster-hack">
                        <GlideGlobalStyle />
                        <Toaster
                            toastOptions={{
                                error: {
                                    style: {
                                        background: "#DC2C26",
                                    },
                                },
                            }}
                            position={browserMightBeOniOS ? "top-center" : "bottom-center"}
                            containerStyle={{
                                top: browserMightBeOniOS ? 64 : 32,
                                left: 32,
                                bottom: browserMightBeOniOS ? 72 : 32,
                                right: 32,
                            }}
                        />
                    </div>,
                    nonNull(document.getElementById("portal"))
                )}
            <div data-testid="main-made-with-glide-badge">
                <MadeWithGlideBadge
                    className={classNames(isMobile && "mobile", !isMobile && "desktop", showMenuButton && "hidden")}
                    css={css`
                        &.mobile.hidden {
                            ${tw`invisible hidden`}
                        }
                    `}
                    removeBranding={navModel.removeBranding}
                    badgeYOffset={madeWithGlideBadgeYPosition}
                />
            </div>
            <MainContentWrapper
                className={classNames(isTabBarVisible && "has-tab-bar")}
                isMobileSidemenuOpen={!currentScreen.flags.includes(WireScreenFlag.IsSignIn) && showNavMenu}
                tw="absolute inset-0 pointer-events-auto flex flex-col page-md:flex-row overflow-y-auto
                    overflow-x-hidden [--tab-bar-height: var(--safe-area-inset-bottom)]
                    [&.has-tab-bar]:(
                        [--tab-bar-padding: 8px]
                        [--tab-bar-content-height: 48px]
                        [--tab-bar-bottom-padding: max(var(--tab-bar-padding), var(--safe-area-inset-bottom))]
                        [--tab-bar-height: calc(var(--tab-bar-bottom-padding) + var(--tab-bar-content-height) + var(--tab-bar-padding))]
                    page-md:[--tab-bar-height: var(--safe-area-inset-bottom)])">
                {!currentScreen.flags.includes(WireScreenFlag.IsSignIn) && (
                    <>
                        <ResponsiveProvider
                            css={pushedContentCSS}
                            maxSize={maxSize}
                            id="nav-root"
                            className="topbar-root"
                            tw="flex sticky top-0 flex-col items-center transition-transform duration-200 z-unified-nav-bar page-md:hidden">
                            <UnifiedNavBar tw="w-full" {...navbarProps} />
                        </ResponsiveProvider>

                        <UnifiedSideBar
                            className="sidebar-root"
                            tw="hidden sticky top-0 page-md:flex"
                            {...navbarProps}
                        />
                    </>
                )}
                <ChromeMainContent
                    navBarCrossfadeMotionValue={navBarCrossfadeMotionValue}
                    currentScreen={currentScreen}
                    belowScreen={belowScreen}
                    canGoBack={mainCanGoBack}
                    onDragBack={onDragBack}
                    lastNavigation={lastNavigation}
                    hasModal={modalScreen !== undefined}
                    backend={backend}
                    navBarHeight={navBarHeight ?? 0}
                    maxSize={maxSize}
                    onScroll={onScroll}
                    ref={mainLayerScrollingRegion}
                    componentDependencies={componentDependencies}
                    iconImage={navModel.iconImage}
                    isTabBarVisible={isTabBarVisible}
                />
                {!currentScreen.flags.includes(WireScreenFlag.IsSignIn) && (
                    <>
                        <ChromeMobileSidemenu
                            title={navModel.appTitle}
                            logo={theme.signInLogo}
                            iconImage={navModel.iconImage}
                            tabs={tabsInSecondaryNav}
                            showMenu={showNavMenu}
                            setShowMenu={navbarProps.setShowMenu}
                            selected={navbarProps.selected}
                            onSelectionChange={navbarProps.onSelectionChange}
                            signedInUserEmail={signedInUserEmail}
                            signedInUserName={signedInUserName}
                            signedInUserImage={signedInUserImage}
                            onSignInPressed={onSignInPressed}
                            onSignOutPressed={onSignOutPressed}
                            onSignUpPressed={onSignUpPressed}
                            onUserButtonPressed={onUserButtonPressed}
                            removeBranding={navModel.removeBranding}
                            showAddToHomescreenDialog={showAddToHomescreenDialog}
                            showInstallApp={showInstallApp}
                            navigateToRoot={navbarProps.navigateToRoot}
                            appID={backend.appID}
                        />
                        {isTabBarVisible && (
                            <ChromeMobileTabBar
                                tabs={tabsInPrimaryNav}
                                measureRef={mobileTabBarRef}
                                onSelectionChange={navbarProps.onSelectionChange}
                            />
                        )}
                    </>
                )}
            </MainContentWrapper>
            <AnimatePresence>
                {backdropOpacity > 0 && (
                    <OverlayBackdrop
                        modalSize={modalScreen?.size}
                        opacity={backdropOpacity}
                        onClick={onBackdropClick}
                        zIndex={backdropZIndex}
                    />
                )}
            </AnimatePresence>
            <ChromeOverlayContent
                modalScreen={modalScreen}
                onBackdropClick={onBackdropClick}
                navBarHeight={navBarHeight ?? 0}
                onClose={onModalClose}
                maxSize={maxSize}
                isFormOrEdit={isModalFormOrEdit}
                isScrolled={isOverlayScrolled}
                setIsScrolled={setIsOverlayScrolled}
                backend={backend}
                lastNavigation={lastNavigation}
                componentDependencies={componentDependencies}
            />
            <ChromeSlideInContent
                modalScreen={modalScreen}
                onBackdropClick={onBackdropClick}
                onClose={onModalClose}
                maxSize={maxSize}
                isScrolled={isOverlayScrolled}
                backend={backend}
                lastNavigation={lastNavigation}
                setIsScrolled={setIsOverlayScrolled}
                componentDependencies={componentDependencies}
            />

            <AddToHomescreen
                isOpen={showingAddToHomescreenDialog}
                appKind={navModel.kind}
                appTitle={navModel.appTitle}
                closeDialog={closeAddToHomescreenDialog}
                iconImage={navModel.iconImage}
                appID={backend.appID}
            />
        </>
    );
};

interface MainContentWrapperProps {
    readonly isMobileSidemenuOpen: boolean;
    readonly className?: string;
}

// Extracted to a component only cause I needed the responsiveSizeClass, and the provider is up there
const MainContentWrapper: React.FC<React.PropsWithChildren<MainContentWrapperProps>> = p => {
    const { isMobileSidemenuOpen, className } = p;

    const size = useResponsiveSizeClass();
    const isMobile = size === undefined || size === "sm";
    const isSidemenuOpen = isMobileSidemenuOpen && isMobile;

    const theme = useWireAppTheme();

    return (
        <div
            css={css`
                .browser-pages-previewer &.builder {
                    --safe-area-inset-bottom: 0px;
                    --safe-area-inset-top: 0px;
                    --safe-area-inset-left: 0px;
                    --safe-area-inset-right: 0px;
                }
            `}
            className={classNames(
                !theme.showDesktopSideBar ? "no-sidebar" : "with-sidebar",
                isSidemenuOpen && "sidemenu-open",
                !isPlayer() && "builder",
                className
            )}>
            {p.children}
        </div>
    );
};

interface MadeWithGlideBadgeProps {
    readonly badgeYOffset: number;
    readonly removeBranding: boolean;
    readonly className?: string;
}

const MadeWithGlideBadge: React.VFC<MadeWithGlideBadgeProps> = p => {
    const { removeBranding, badgeYOffset, className } = p;
    const isPreview = useIsPreview();
    const size = useResponsiveSizeClass();
    const isMobile = size === undefined || size === "sm";

    if (removeBranding) {
        return null;
    }
    const desktopYOffset = isPreview ? 52 : 0;
    const yOffset = isMobile ? badgeYOffset : desktopYOffset;

    return <MadeWithGlide className={className} yOffset={yOffset} />;
};
