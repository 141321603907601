import { frontendTrace } from "@glide/common-core/dist/js/tracing";
import type {
    AddRowActionWithMetadataArguments,
    DeleteRowActionWithMetadataArguments,
    SetColumnsActionWithMetadataArguments,
} from "./types";
import type { EnqueueDataActionResult } from "./enqueue-data-action";
import {
    postAddRowToTableActionViaHTTP,
    postDeleteRowActionViaHTTP,
    postSetColumnsInRowActionViaHTTP,
} from "./http-crud-actions";
import { loopUntilSuccessfulAction } from "./loop-until-successful";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";

export async function postAddRowToTableActionReliably(
    appFacilities: ActionAppFacilities,
    actionData: AddRowActionWithMetadataArguments
): Promise<EnqueueDataActionResult> {
    return await loopUntilSuccessfulAction("postAddRowToTable", () =>
        frontendTrace(
            "enqueue addRowToTable",
            { http: true, deviceID: actionData.deviceID },
            async () => await postAddRowToTableActionViaHTTP(appFacilities, actionData)
        )
    );
}

export async function postSetColumnsInRowActionReliably(
    appFacilities: ActionAppFacilities,
    postingPayload: SetColumnsActionWithMetadataArguments
): Promise<EnqueueDataActionResult> {
    return await loopUntilSuccessfulAction("postSetColumnsInRow", () =>
        frontendTrace(
            "enqueue setColumnsInRow",
            { http: true, deviceID: postingPayload.deviceID, resiliencyQueue: false },
            async () => await postSetColumnsInRowActionViaHTTP(appFacilities, postingPayload)
        )
    );
}

export async function postDeleteRowActionReliably(
    appFacilities: ActionAppFacilities,
    actionData: DeleteRowActionWithMetadataArguments
): Promise<EnqueueDataActionResult> {
    return await loopUntilSuccessfulAction("postDeleteRow", () =>
        frontendTrace(
            "enqueue deleteRow",
            { http: true, deviceID: actionData.deviceID },
            async () => await postDeleteRowActionViaHTTP(appFacilities, actionData)
        )
    );
}
