import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import type { LoadedGroundValue } from "@glide/computation-model-types";
import { type PropertyDescription, ActionKind, PropertyKind, getWebhookProperty } from "@glide/app-description";
import { isDateTimeTypeKindUntyped } from "@glide/type-schema";
import { getFeatureSetting } from "@glide/common-core/dist/js/feature-settings";
import type {
    TriggerAppWebhookActionBody,
    WebhookValue,
    WebhookValues,
} from "@glide/common-core/dist/js/firebase-function-types";
import { GlideDateTime } from "@glide/data-types";
import { type AppDescriptionContext, type PropertyDescriptor, PropertySection } from "@glide/function-utils";
import { assertNever, mapRecord, sleep, definedMap } from "@glideapps/ts-necessities";
import { isArray } from "@glide/support";
import type {
    WireActionResultBuilder,
    WireActionResult,
    WireActionBackend,
    WireActionInflationBackend,
} from "@glide/wire";
import { type YesCodeValueWithType, convertYesCodeValue } from "@glide/yes-code";
import type { StaticActionContext } from "../static-context";
import type { DescriptionToken } from "./action-handler";
import { type KVPInOutActionDescription, type KVPWriteBackTo, KVPInOutActionHandler } from "./kvp-in-out-action";
import { ICON_PALE } from "../plugins/icon-colors";
import type { GlideIconProps } from "@glide/plugins-codecs";

interface WebhookActionDescription extends KVPInOutActionDescription {
    readonly webhook: PropertyDescription;
}

function convertYesCodeValueToWebhookValue(yesCode: YesCodeValueWithType): WebhookValue | undefined {
    if (yesCode.value === undefined) return undefined;
    if (typeof yesCode.type !== "string" || isArray(yesCode.value)) return undefined;
    if (typeof yesCode.value === "number") {
        return { type: "number", value: yesCode.value };
    }
    if (typeof yesCode.value === "boolean") {
        return { type: "boolean", value: yesCode.value };
    }
    if (typeof yesCode.value === "string") {
        if (isDateTimeTypeKindUntyped(yesCode.type)) {
            return { type: "date-time", value: yesCode.value };
        } else {
            return { type: "string", value: yesCode.value };
        }
    }
    assertNever(yesCode.value);
}

export function convertToWebhookValue(value: LoadedGroundValue): WebhookValue | undefined {
    if (value === undefined) return undefined;
    if (value instanceof GlideDateTime) {
        // We used to only have time-zone agnostic values, which were
        // transferred as-is as UTC strings.  Now we also have time-zone aware
        // values, for which we also transfer their UTC strings, i.e. it's the
        // same code for both.
        return { type: "date-time", value: value.asUTCDate().toISOString() };
    }
    return convertYesCodeValueToWebhookValue(convertYesCodeValue([value, value], "primitive"));
}

async function loopUntilSuccessful(op: () => Promise<Response | undefined>): Promise<Response> {
    let retries = 0;
    while (true) {
        const response = await op();
        if (response === undefined) continue;
        if (response.ok) return response;
        if (response.status === 402) return response;
        // HTTP 400, 401, 403, 404, 429, and all 5xx should be retried into oblivion.
        void response.text();

        // We shouldn't be _that_ incessant with webhook retries, otherwise
        // we'd likely knock ourselves over.
        if (0 < retries) {
            // After the 7th retry, we want to sleep at most 5 seconds between attempts.
            await sleep(100 + Math.random() * Math.min(100 * retries ** 2, 4900));
        }
        retries++;

        continue;
    }
}

interface WebhookActionData {
    readonly webhookID: string;
}

export class WebhookActionHandler extends KVPInOutActionHandler<WebhookActionDescription, WebhookActionData> {
    public readonly kind = ActionKind.Webhook;
    public readonly iconName: GlideIconProps = {
        icon: "st-connect",
        kind: "stroke",
        strokeFgColor: ICON_PALE,
    };

    protected readonly actionName = "Trigger webhook";

    protected getSupportsWriteBack(): boolean {
        return getFeatureSetting("webhookWriteBack");
    }

    protected getIsLegacy(_ccc: AppDescriptionContext): boolean {
        return getFeatureSetting("deprecateWebhookAction");
    }

    protected getPropertyDescriptors(): readonly PropertyDescriptor[] {
        return [
            {
                kind: PropertyKind.Webhook,
                label: "Webhook",
                property: { name: "webhook" },
                section: PropertySection.Data,
            },
        ];
    }

    public getTokenizedDescription(
        desc: WebhookActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const webhookID = getWebhookProperty(desc.webhook);
        if (webhookID === undefined) return undefined;

        const integration = env.context.webhookIntegrations.find(i => i.id === webhookID);
        if (integration === undefined) return undefined;

        return [{ kind: "string", value: integration.name }];
    }

    protected inflateData(
        _ib: WireActionInflationBackend,
        desc: WebhookActionDescription,
        arb: WireActionResultBuilder
    ): WebhookActionData | WireActionResult {
        const webhookID = getWebhookProperty(desc.webhook);
        if (webhookID === undefined) return arb.inflationError("Invalid webhook");

        return { webhookID };
    }

    protected async runAction(
        _ab: WireActionBackend,
        appID: string,
        appFacilities: ActionAppFacilities,
        params: WebhookValues,
        writeBackTo: KVPWriteBackTo | undefined,
        awaitSend: boolean,
        data: WebhookActionData,
        arb: WireActionResultBuilder
    ): Promise<WireActionResult> {
        const body: TriggerAppWebhookActionBody = {
            appID,
            webhookID: data.webhookID,
            params,
            deviceID: appFacilities.deviceID,
            idempotencyKey: appFacilities.makeRowID(),
            writeBackTo: definedMap(writeBackTo, w => ({
                // We're spelling this out one by one because `writeBackTo`
                // contains more properties than we should send.
                results: mapRecord(w.results, v => ({
                    tableName: v.tableName,
                    rowIndex: v.rowIndex,
                    columnName: v.columnName,
                })),
                fromBuilder: w.fromBuilder,
                fromDataEditor: w.fromDataEditor,
                appUserID: w.appUserID,
                writeSource: w.writeSource,
            })),
        };

        const promise = loopUntilSuccessful(() =>
            appFacilities.callAuthIfAvailableCloudFunction("triggerAppWebhookAction", body, {})
        );

        // We have to drain out the body, otherwise we'll just leave the connection
        // around forever.
        if (!awaitSend) {
            void promise.then(r => r?.text());
            return arb.success();
        }

        const result = await promise;
        void result.text();
        if (result.ok) {
            return arb.success();
        } else {
            return arb.errorFromHTTPStatus(result.status, result.statusText ?? "Failed to trigger webhook");
        }
    }
}
