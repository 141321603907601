import type { OrgMember } from "../../lib/orgs-lib";
import "twin.macro";
import { SimpleTooltip } from "../tooltip/simple-tooltip";

const MAX_VISIBLE_AVATARS = 5;

const AVATAR_MASK = `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjMzMzggMTguODg4NkM1LjAwNjM1IDE2LjcwMDkgNiAxMy45NjY1IDYgMTFDNiA4LjAzMzUxIDUuMDA2MzUgNS4yOTkwNyAzLjMzMzggMy4xMTEzOUM1LjMxNDk0IDEuMTg1NzkgOC4wMTg5OCAwIDExIDBDMTcuMDc1MSAwIDIyIDQuOTI0ODcgMjIgMTFDMjIgMTcuMDc1MSAxNy4wNzUxIDIyIDExIDIyQzguMDE4OTggMjIgNS4zMTQ5NCAyMC44MTQyIDMuMzMzOCAxOC44ODg2WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+')`;
const OTHERS_MASK = `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjMiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCA2MyAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjMzMzgxIDE4Ljg4ODZDNS4wMDYzOCAxNi43MDA5IDUuOTk5OTggMTMuOTY2NSA1Ljk5OTk4IDExQzUuOTk5OTggOC4wMzM1MyA1LjAwNjM3IDUuMjk5MTEgMy4zMzM4IDMuMTExNDJDNS4zMTQ5NCAxLjE4NTc5IDguMDE4OTggMCAxMSAwTDYzIDIuMzg0MTllLTA2VjIyTDExIDIyQzguMDE4OTkgMjIgNS4zMTQ5NSAyMC44MTQyIDMuMzMzODEgMTguODg4NloiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPg==')`;

const Avatar: React.VFC<{ member: OrgMember; addMask: boolean }> = props => {
    const {
        member: { photoURL },
        addMask,
    } = props;

    return (
        <div
            tw="w-[22px] h-[22px] rounded-full overflow-hidden -ml-1 first:ml-0"
            style={
                addMask
                    ? {
                          maskImage: AVATAR_MASK,
                          WebkitMaskImage: AVATAR_MASK,
                      }
                    : undefined
            }>
            {photoURL !== undefined ? (
                <img tw="w-full h-full" src={photoURL} alt="Member avatar" />
            ) : (
                <div tw="bg-n500A w-full h-full" /> // photo seems to be always present, this shouldn't be visible
            )}
        </div>
    );
};

interface Props extends React.PropsWithChildren {
    readonly members: readonly OrgMember[];
    readonly onClick: () => void;
}

export const MembersAvatars: React.VFC<Props> = props => {
    const { members, onClick } = props;
    if (members.length === 0) {
        return null;
    }
    const visibleAvatars = members.slice(0, MAX_VISIBLE_AVATARS);
    const others = members.slice(MAX_VISIBLE_AVATARS);
    return (
        <SimpleTooltip text="View members">
            <div tw="flex rounded-full p-[5px] cursor-pointer transition hover:bg-n200A" onClick={onClick}>
                {visibleAvatars.map((m, index) => (
                    <Avatar member={m} addMask={index > 0} key={m.userID} />
                ))}
                {others.length > 0 && (
                    <div
                        tw="font-medium leading-[22px] text-builder-xs text-text-pale bg-n200A rounded-full px-2 -ml-1"
                        style={{ maskImage: OTHERS_MASK, WebkitMaskImage: OTHERS_MASK }}>
                        <span tw="text-text-xpale">+</span>
                        {others.length}
                    </div>
                )}
            </div>
        </SimpleTooltip>
    );
};
