import { assertNever } from "@glideapps/ts-necessities";
import type { ActionDescription, FilterArrayTransform, PropertyDescription } from "./description";
import type { Formula, TableName, ActionNodeOutputSourceColumn, TableColumnLike } from "@glide/type-schema";

export enum ActionNodeKind {
    Conditional = "compound",
    ConditionalFlow = "conditional-flow",
    Primitive = "primitive",
    Flow = "flow",
    Loop = "loop",
    AutomationRoot = "automation-root",
}

export type ActionNode =
    | PrimitiveActionNode
    | FlowActionNode
    | ConditionalFlowNode
    | ConditionalActionNode
    | LoopNode
    | AutomationRootNode;

interface ActionNodeBase {
    readonly key: string;
    readonly customTitle?: string;
}

export interface ConditionalActionNode extends ActionNodeBase {
    readonly kind: ActionNodeKind.Conditional;

    readonly conditionalFlows: readonly ConditionalFlowNode[];
    readonly elseFlow: FlowActionNode | undefined;
}

export interface ConditionalFlowNode extends ActionNodeBase {
    readonly kind: ActionNodeKind.ConditionalFlow;

    readonly condition: Formula;
    readonly flow: FlowActionNode;
}

export interface FlowActionNode extends ActionNodeBase {
    readonly kind: ActionNodeKind.Flow;

    readonly actions: readonly (PrimitiveActionNode | ConditionalActionNode | LoopNode)[];
}

export interface PrimitiveActionNode extends ActionNodeBase {
    readonly kind: ActionNodeKind.Primitive;

    readonly actionDescription: ActionDescription;
}

export enum LoopSourceKind {
    Table = "table",
    Relation = "relation",
    Range = "range",
    Array = "array",
}

interface RowsLoopBase {
    // FIXME: We should probably allow specifying a sort, like in
    // `FilterSortLimitSpecification`.
    readonly filter: FilterArrayTransform | undefined;
    readonly limit: number;
}

export interface TableLoopSource extends RowsLoopBase {
    readonly kind: LoopSourceKind.Table;
    readonly tableName: TableName;
}

export interface RelationLoopSource extends RowsLoopBase {
    readonly kind: LoopSourceKind.Relation;
    readonly sourceColumn: ActionNodeOutputSourceColumn;
}

export interface RangeLoopSource {
    readonly kind: LoopSourceKind.Range;
    readonly start: PropertyDescription;
    readonly repetitions: PropertyDescription;
    readonly increment: PropertyDescription;
}

export interface ArrayLoopSource {
    readonly kind: LoopSourceKind.Array;
    readonly sourceColumn: ActionNodeOutputSourceColumn;
    readonly limit: number;
}

interface BaseLoopNode extends ActionNodeBase {
    readonly kind: ActionNodeKind.Loop;
    readonly flow: FlowActionNode;
}

export interface TableLoopNode extends BaseLoopNode {
    readonly source: TableLoopSource;
}

export interface RelationLoopNode extends BaseLoopNode {
    readonly source: RelationLoopSource;
}

export interface RangeLoopNode extends BaseLoopNode {
    readonly source: RangeLoopSource;
}

export interface ArrayLoopNode extends BaseLoopNode {
    readonly source: ArrayLoopSource;
}

export type LoopNode = TableLoopNode | RelationLoopNode | RangeLoopNode | ArrayLoopNode;

export type LoopSource = LoopNode["source"];

export function isTableLoopNode(loop: LoopNode): loop is TableLoopNode {
    return loop.source.kind === LoopSourceKind.Table;
}

export function isRelationLoopNode(loop: LoopNode): loop is RelationLoopNode {
    return loop.source.kind === LoopSourceKind.Relation;
}

export function isRangeLoopNode(loop: LoopNode): loop is RangeLoopNode {
    return loop.source.kind === LoopSourceKind.Range;
}

export function isArrayLoopNode(loop: LoopNode): loop is ArrayLoopNode {
    return loop.source.kind === LoopSourceKind.Array;
}

// I know this looks like nonsense, all cases return the same thing.
// If you don't make an explicit case for each source kind TS will error.
export function makeLoopNode(baseNode: BaseLoopNode, source: LoopSource): LoopNode {
    switch (source.kind) {
        case LoopSourceKind.Table: {
            return {
                ...baseNode,
                source,
            };
        }
        case LoopSourceKind.Relation: {
            return {
                ...baseNode,
                source,
            };
        }
        case LoopSourceKind.Range: {
            return {
                ...baseNode,
                source,
            };
        }
        case LoopSourceKind.Array: {
            return {
                ...baseNode,
                source,
            };
        }
        default:
            return assertNever(source);
    }
}

export interface ActionOutputDescriptor extends TableColumnLike {
    readonly description?: string;
}

export interface AutomationRootNode extends ActionNodeBase {
    readonly kind: ActionNodeKind.AutomationRoot;

    readonly inputs: readonly ActionOutputDescriptor[];
    readonly flow: FlowActionNode;
}
