import { assert, assertNever } from "@glideapps/ts-necessities";
import { compareStrings, isDefined, getTimezoneOffsetForDate } from "@glide/support";
import { isLeft, isRight } from "fp-ts/lib/Either";
import * as iots from "io-ts";
import {
    type GlideDateTimeFormatSpecification,
    DateFormat,
    DateTimeParts,
    TimeFormat,
    makeDateTimeFormat,
} from "./format-date-time";
import {
    convertDateFromTimeZoneAgnostic,
    makeDateFromString,
    makeStartOfDay,
    makeStartOfDayTimeZoneAgnostic,
    makeStartOfDayUTC,
} from "./support";

const glideDateTimeZoneCodec = iots.union([iots.literal("local"), iots.literal("agnostic")]);
export type GlideDateTimeZone = iots.TypeOf<typeof glideDateTimeZoneCodec>;

export const glideDateTimeDocumentDataCodec = iots.intersection([
    iots.type({
        kind: iots.literal("glide-date-time"),
        // This is the `x` in `new Date(x)`
        value: iots.number,
        // If this is missing (or null), the date is time-zone agnostic.  If
        // it's present, the data is in UTC, and this is the time-zone offset
        // (as returned by `date.getTimeZoneOffset()`) from wherever the date
        // originated.  We're not actually using this offset right now, other
        // than to check for its presence.
        //
        // ##updateAppDataObjects:
        // When we update objects in app data on the backend, we "merge" them
        // with what already exists in the document, which means that if
        // `tzOffset` is not set, but there's an existing one, that existing
        // one is preserved, which is not what we want, so we set it to
        // `null`.  We need to keep the `undefined` here to not break on
        // existing objects, but the constructor will make it `null`.
        tzOffset: iots.union([iots.number, iots.null, iots.undefined]),
    }),
    iots.partial({
        // The date in whatever format the user chose in their spreadsheet.
        // We don't parse this, it's only used for display.
        repr: iots.string,
    }),
]);

export type GlideDateTimeDocumentData = iots.TypeOf<typeof glideDateTimeDocumentDataCodec>;

export function isGlideDateTimeDocumentData(x: unknown): x is GlideDateTimeDocumentData {
    // This check significantly speeds up the case where `x` is not a
    // `GlideDateTime`.
    if (typeof x !== "object") return false;
    return isRight(glideDateTimeDocumentDataCodec.decode(x));
}

export function areGlideDateTimeDocumentDatasEqual(
    a: GlideDateTimeDocumentData,
    b: GlideDateTimeDocumentData
): boolean {
    if (a.value !== b.value) return false;
    if (a.tzOffset !== b.tzOffset) return false;
    if (a.repr !== b.repr) return false;
    return true;
}

const timeZoneGranularity = 15;

// We're being very conservative about comparing days because we want to avoid
// having to deal with time zones.  Instead we rely on time zones having a
// granulaty of 15 minutes, so if two date-times are within the same 15 minute
// window, we know they're definitely on the same day, in any time zone.
export function canGlideDateTimeDocumentDatasBeOnTheSameDay(
    a: GlideDateTimeDocumentData,
    b: GlideDateTimeDocumentData
): boolean {
    if (a.tzOffset !== b.tzOffset) return false;

    if (Math.abs(a.value - b.value) > timeZoneGranularity * 60 * 1000) return false;

    const aDate = new Date(a.value);
    const bDate = new Date(b.value);
    if (aDate.getUTCHours() !== bDate.getUTCHours()) return false;
    if (
        Math.floor(aDate.getUTCMinutes() / timeZoneGranularity) !==
        Math.floor(bDate.getUTCMinutes() / timeZoneGranularity)
    ) {
        return false;
    }

    return true;
}

// Take note of the calls to .getTimezoneOffset() in Date objects.
// We used to cache a single .getTimezoneOffset() for those purposes, but this was not correct.
// Many timezones observe DST, and during-DST dates in these timezones actually have a different
// .getTimezoneOffset() than non-DST dates in the same timezones.

export class GlideDateTime {
    private constructor(private readonly _docData: GlideDateTimeDocumentData) {
        if (_docData.tzOffset === undefined) {
            this._docData = { ..._docData, tzOffset: null };
        }
    }

    public get isTimeZoneAware(): boolean {
        return isDefined(this._docData.tzOffset);
    }

    public static fromDocumentData(d: GlideDateTimeDocumentData): GlideDateTime {
        return new GlideDateTime(d);
    }

    public static fromTimeZoneAgnosticValue(value: number, repr?: string): GlideDateTime {
        return new GlideDateTime({
            kind: "glide-date-time",
            value,
            repr,
            tzOffset: null,
        });
    }

    // NOTE: `value` must come from the same time zone as this method is run
    // in, unless tzOffset is passed.
    public static fromTimeZoneAwareValue(value: number, repr?: string, tzOffset?: number): GlideDateTime {
        const dateValue = new Date(value);
        return new GlideDateTime({
            kind: "glide-date-time",
            value,
            repr,
            tzOffset: tzOffset ?? getTimezoneOffsetForDate(dateValue),
        });
    }

    // NOTE: `d` must come from the same time zone as this method is run in.
    public static fromTimeZoneAwareDate(d: Date, repr?: string): GlideDateTime {
        return GlideDateTime.fromTimeZoneAwareValue(d.getTime(), repr);
    }

    public static fromTimeZoneAgnosticDate(d: Date, repr?: string): GlideDateTime {
        return GlideDateTime.fromTimeZoneAgnosticValue(d.getTime(), repr);
    }

    public static now(): GlideDateTime {
        return GlideDateTime.fromTimeZoneAwareValue(Date.now());
    }

    private getTimezoneOffsetMillis(deviceTzMinutesOffset: number | undefined): number {
        if (deviceTzMinutesOffset === undefined) {
            const dateValue = new Date(this._docData.value);
            deviceTzMinutesOffset = getTimezoneOffsetForDate(dateValue);
        }
        return deviceTzMinutesOffset * 60 * 1000;
    }

    // A time-zone aware date/time should be interpreted according to the
    // current time zone, not to whichever time zone produced it. In Airtable,
    // for example, we always set `tzOffset` to zero because we don't even
    // know what the "original" time zone was, but when presenting it locally,
    // it uses the local time-zone.
    public getLocalTimeZoneAgnosticValue(deviceTzMinutesOffset?: number): number {
        if (isDefined(this._docData.tzOffset)) {
            // FIXME: Shouldn't this be using
            // `convertDateFromTimeZoneAgnostic`?  And in that case, don't we
            // have to make ##deviceTimeZoneInAgnosticDates work?
            // https://github.com/quicktype/glide/issues/17646
            return this._docData.value - this.getTimezoneOffsetMillis(deviceTzMinutesOffset);
        } else {
            return this._docData.value;
        }
    }

    // We use this to convert ##nativeTableTimeZoneAgnostic for example on the
    // backend, where the local time zone is irrelevant and we want exactly
    // the same behavior as Google Sheets.  The rule is that on the backend
    // you always want to use the origin time zone (the local time zone makes
    // no sense), and on the frontend you probably want to use the local time
    // zone.
    public getOriginTimeZoneAgnosticValue(): number {
        if (isDefined(this._docData.tzOffset)) {
            return this._docData.value - this._docData.tzOffset * 60 * 1000;
        } else {
            return this._docData.value;
        }
    }

    // This returns the raw date value.
    public getUTCValue(): number {
        return this._docData.value;
    }

    public getRepr(): string | undefined {
        return this._docData.repr;
    }

    // This is what we would use to write the date to Google Sheets, for
    // example.  This will return the date from the point of view of the
    // creator, not the viewer, i.e. if it was 2pm in the creator's local
    // timezone, this will return 2pm GMT, even if in our local timezone it
    // was 3pm.  This is compatible with how time-zone agnostic dates work. At
    // this point we should only have to use this in the OCM and on the
    // backend when writing to data sources that don't support time zones.
    // There's one exception: we use it for date math in NCM where it's
    // convenient.
    public asOriginTimeZoneAgnosticDate(): Date {
        return new Date(this.getOriginTimeZoneAgnosticValue());
    }

    public asLocalTimeZoneAgnosticDate(deviceTzMinutesOffset?: number): Date {
        return new Date(this.getLocalTimeZoneAgnosticValue(deviceTzMinutesOffset));
    }

    // This returns the raw date.
    public asUTCDate(): Date {
        return new Date(this.getUTCValue());
    }

    // ##convertToTimeZoneAware:
    // We need to ensure that
    //
    //   D === fromTimeZoneAwareValue(D.getTimeZoneAwareValue())
    //
    // even if D is time zone agnostic
    public getTimeZoneAwareValue(deviceTzMinutesOffset?: number): number {
        if (isDefined(this._docData.tzOffset)) {
            return this._docData.value;
        } else {
            // Let's say our time-zone agnostic value is 2am, and we're in
            // GMT-1.  The time-zone aware value will be 3am GMT, because
            // that will display as 2am in GMT-1
            return this._docData.value + this.getTimezoneOffsetMillis(deviceTzMinutesOffset);
        }
    }

    public asTimeZoneAwareDate(deviceTzMinutesOffset?: number): Date {
        return new Date(this.getTimeZoneAwareValue(deviceTzMinutesOffset));
    }

    public toOriginTimeZoneAgnostic(): GlideDateTime {
        if (isDefined(this._docData.tzOffset)) {
            return GlideDateTime.fromTimeZoneAgnosticValue(this.getOriginTimeZoneAgnosticValue(), this._docData.repr);
        } else {
            return this;
        }
    }

    public toLocalTimeZoneAgnostic(deviceTzMinutesOffset?: number): GlideDateTime {
        if (isDefined(this._docData.tzOffset)) {
            return GlideDateTime.fromTimeZoneAgnosticValue(
                this.getLocalTimeZoneAgnosticValue(deviceTzMinutesOffset),
                this._docData.repr
            );
        } else {
            return this;
        }
    }

    public toTimeZoneAware(): GlideDateTime {
        if (isDefined(this._docData.tzOffset)) {
            return this;
        } else {
            return GlideDateTime.fromTimeZoneAwareDate(convertDateFromTimeZoneAgnostic(new Date(this._docData.value)));
        }
    }

    public transformTimeZoneAwareValue(f: (v: number) => number): GlideDateTime {
        return new GlideDateTime({
            kind: "glide-date-time",
            value: f(this._docData.value),
            tzOffset: this._docData.tzOffset,
        });
    }

    // Should give you the start of day in the given time zone, or
    // whichever time zone you're in, if none is passed.
    public localStartOfDay(deviceTzMinutesOffset?: number): GlideDateTime {
        // FIXME: Implement efficiently
        if (isDefined(this._docData.tzOffset)) {
            if (deviceTzMinutesOffset !== undefined) {
                // FIXME: I'm fairly confident this `if` could be removed and
                // the code in its body could be used instead of the original code
                // following it.. however, this is all very fiddly, and I don't want to
                // risk breaking anything.
                const startOfDayUTC = makeStartOfDayUTC(this.asLocalTimeZoneAgnosticDate(deviceTzMinutesOffset));
                return GlideDateTime.fromTimeZoneAwareValue(
                    startOfDayUTC.getTime() + this.getTimezoneOffsetMillis(deviceTzMinutesOffset),
                    undefined,
                    deviceTzMinutesOffset
                );
            }

            // Note the we're not carrying over the `tzOffset` of the original
            // date, because it's now bound to the local time zone.  Also not
            // that we're getting the time-zone agnostic date here, not the
            // aware one, because the aware one might land us on the wrong
            // day.
            return GlideDateTime.fromTimeZoneAwareDate(
                makeStartOfDay(this.asLocalTimeZoneAgnosticDate(deviceTzMinutesOffset))
            );
        } else {
            return GlideDateTime.fromTimeZoneAgnosticDate(
                makeStartOfDayTimeZoneAgnostic(this.asLocalTimeZoneAgnosticDate(deviceTzMinutesOffset))
            );
        }
    }

    public localEndOfDay(deviceTzMinutesOffset?: number): GlideDateTime {
        const startDay = this.localStartOfDay(deviceTzMinutesOffset);
        return startDay.transformTimeZoneAwareValue(v => v + 24 * 60 * 60 * 1000 - 1);
    }

    public format(
        dateFormat: DateFormat | undefined,
        timeFormat: TimeFormat | undefined,
        timeZone: GlideDateTimeZone
    ): string | undefined {
        // `DateTimeFormat.format` can crash:
        // https://github.com/quicktype/glide/issues/13594
        try {
            let date: Date;
            if (timeZone === "local") {
                date = this.asLocalTimeZoneAgnosticDate();
            } else if (timeZone === "agnostic") {
                date = this.asUTCDate();
            } else {
                return assertNever(timeZone);
            }
            const format = makeDateTimeFormat(dateFormat, timeFormat);
            const formatted = format.format(date);
            return formatted;
        } catch {
            return undefined;
        }
    }

    // This exists primarily to make `` and + function.
    public toString(): string {
        return this.asUTCDate().toISOString();
    }

    // This returns an ISO 8601 string with a TZ designator based on our tzOffset (or without, if agnostic)
    public toISOStringWithTimeZone(): string {
        const offset = this._docData.tzOffset;
        if (offset === 0) return this.asUTCDate().toISOString();

        const isoString = this.asOriginTimeZoneAgnosticDate().toISOString().slice(0, -1); // chop off the Z

        if (!isDefined(offset)) {
            // For TZ agnostic dates, don't add a TZ designator, which means assume local time.
            // https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators
            return isoString;
        }

        // Otherwise, add the TZ designator.
        // Note the sign is intentionally flipped
        const sign = offset < 0 ? "+" : "-";
        const absOffset = Math.abs(offset);
        const hours = String(Math.floor(absOffset / 60)).padStart(2, "0");
        const minutes = String(absOffset % 60).padStart(2, "0");
        return `${isoString}${sign}${hours}:${minutes}`;
    }

    public formatWithSpecification(
        spec: GlideDateTimeFormatSpecification,
        timeZone: GlideDateTimeZone
    ): string | undefined {
        const { parts, dateFormat, timeFormat } = spec;
        switch (parts) {
            case DateTimeParts.DateOnly:
                return this.format(dateFormat, undefined, timeZone);
            case DateTimeParts.TimeOnly:
                return this.format(undefined, timeFormat, timeZone);
            case DateTimeParts.DateTime:
                return this.format(dateFormat, timeFormat, timeZone);
            default:
                return assertNever(parts);
        }
    }

    public asLocalString(): string | undefined {
        const { repr } = this._docData;
        if (repr !== undefined) return repr;
        return this.format(DateFormat.Short, TimeFormat.WithSeconds, "local");
    }

    public clone(): GlideDateTime {
        return GlideDateTime.fromDocumentData({ ...this._docData });
    }

    public toDocumentData(): GlideDateTimeDocumentData {
        return { ...this._docData };
    }

    public toJSON(): GlideDateTimeDocumentData {
        return this.toDocumentData();
    }

    // `deviceTzMinutesOffset` can only be passed when `other` is a GlideDateTime
    public compareTo(
        other: unknown,
        opts: { compareTimesOnly?: boolean; deviceTzMinutesOffset?: number } = {}
    ): number {
        if (!(other instanceof GlideDateTime)) {
            // Let's hope we never need ##deviceTimeZoneInAgnosticDates.
            // `convertDateFromTimeZoneAgnostic` can't be easily made to work
            // with another time zone.
            assert(opts.deviceTzMinutesOffset === undefined);
        }
        // We compare time-zone aware values, because those are what the user
        // would see displayed.
        const leftValue = this.getTimeZoneAwareValue(opts.deviceTzMinutesOffset);
        let rightValue: number;

        if (other instanceof GlideDateTime) {
            rightValue = other.getTimeZoneAwareValue(opts.deviceTzMinutesOffset);
        } else if (other instanceof Date) {
            rightValue = convertDateFromTimeZoneAgnostic(other).getTime();
        } else if (typeof other === "string") {
            const { repr } = this._docData;

            // This is all very nasty but our users love to mix strings and dates.
            if (repr !== undefined) {
                // Special case for string comparison when repr? is available;
                // we only will end up dropping repr? when a displayFormula is
                // set on the appropriate column.
                if (repr === other) return 0;

                // If they aren't equal then we'll try parsing the date.
                const otherAsDate = makeDateFromString(other, true, opts.compareTimesOnly);

                if (otherAsDate === undefined) {
                    return compareStrings(repr, other);
                }

                rightValue = otherAsDate.getTime();
            } else {
                const otherAsDate = makeDateFromString(other, true, opts.compareTimesOnly);
                if (otherAsDate === undefined) {
                    return 1;
                }

                rightValue = otherAsDate.getTime();
            }
        } else {
            if (leftValue === other) return 0;
            // This follows the convention of Array.sort
            return leftValue < (other as any) ? -1 : 1;
        }

        if (opts.compareTimesOnly === true) {
            return (leftValue % (24 * 60 * 60 * 1000)) - (rightValue % (24 * 60 * 60 * 1000));
        }

        return leftValue - rightValue;
    }
}

export function forceGlideDateTime<T>(d: T | Date): T | GlideDateTime {
    if (d instanceof Date) {
        return GlideDateTime.fromTimeZoneAgnosticDate(d);
    } else {
        return d;
    }
}

export const glideDateTimeCodec = new iots.Type<GlideDateTime, GlideDateTimeDocumentData, unknown>(
    "glideDateTimeCodec",
    (i: unknown): i is GlideDateTime => i instanceof GlideDateTime,
    i => {
        if (i instanceof GlideDateTime) return iots.success(i);
        const docData = glideDateTimeDocumentDataCodec.decode(i);
        return isLeft(docData) ? docData : iots.success(GlideDateTime.fromDocumentData(docData.right));
    },
    i => i.toDocumentData()
);
