import type { JSONObject } from "@glide/support";

export function getVirtualEmailAddress(
    userProfileRow: JSONObject | undefined,
    emailColumnName: string | undefined
): string | undefined {
    if (emailColumnName === undefined || userProfileRow === undefined) return undefined;
    const email = userProfileRow[emailColumnName];
    if (typeof email !== "string") return undefined;
    return email;
}
