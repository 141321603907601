import type { GeocodeReporter } from "@glide/common-core/dist/js/components/types";
import type { EminenceFlags } from "@glide/billing-types";
import { QuotaKind } from "@glide/common-core/dist/js/Database/quotas";
import type { ReportGeocodesInAppBody } from "@glide/common-core/dist/js/firebase-function-types";
import { callCloudFunctionWeb } from "@glide/common-core/dist/js/utility/function-utils";
import { DefaultMap } from "@glideapps/ts-necessities";

const reportQuotaIncreaseDebouncePeriod = 2000;

export abstract class GeocodeReporterImpl implements GeocodeReporter {
    protected readonly geocodeCounts: DefaultMap<string, number> = new DefaultMap(() => 0);

    private _inFlightGeocodes: number = 0;

    protected abstract get appID(): string;
    protected abstract get eminenceFlags(): EminenceFlags;
    protected abstract setGeocodeQuotaReachedFlag(): void;

    protected getGeocodeQuota(): number {
        return this.eminenceFlags.quotas[QuotaKind.Geocodes].prepaidUsage;
    }

    public isGeocodeWithinQuota(key: string): boolean {
        const c = this.geocodeCounts.get(key);
        return c < this.getGeocodeQuota();
    }

    public countGeocodeForQuota(key: string): void {
        this.geocodeCounts.update(key, c => c + 1);
        this.setGeocodeQuotaReachedFlag();
    }

    private reportGeocodesAfterDebounce = async () => {
        const geocodes = this._inFlightGeocodes;
        if (geocodes === 0) return;

        const { appID } = this;
        this._inFlightGeocodes = 0;
        const body: ReportGeocodesInAppBody = { appID, geocodes };
        const report = await callCloudFunctionWeb("reportGeocodesInApp", body, {});
        // Empty the response so we don't leave a channel open.
        // This should have been a 204, so this isn't supposed to matter.
        await report?.text();
    };

    public reportGeocodes(count: number): void {
        const { _inFlightGeocodes: prevInFlightGeocodes } = this;
        this._inFlightGeocodes += count;
        if (prevInFlightGeocodes === 0) {
            setTimeout(this.reportGeocodesAfterDebounce, reportQuotaIncreaseDebouncePeriod);
        }
    }
}
