import type { UserFeatures } from "@glide/app-description";
import { AppKind } from "@glide/location-common";

export const dynamsoftScannerNumUpdates = 5;

export function mustUseDynamsoftScanner(appKind: AppKind): boolean {
    return appKind !== AppKind.App;
}

export function hasCustomKeyForDynamsoftScanner(features: UserFeatures | undefined): boolean {
    return typeof features?.dbrScannerHandshake === "string";
}
