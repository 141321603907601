import { AppKind } from "@glide/location-common";
import { type ChangeObservable, Watchable, ConditionVariable } from "@glide/support";
import {
    type UserFeatures,
    type FeatureSettingName,
    type FeatureSettings,
    defaultFeatureSettings,
} from "@glide/app-description";
import type { Database } from "./Database/core";
import type { DataReader, DocumentData, Owner } from "./Database";
import { globalSettingsCollectionName } from "./database-strings";
import { OwnerKind } from "./Database/owner-kind";
import { getFeatureFlag } from "./feature-flags";

const experimentalSettings: FeatureSettingName[] = [];

const featureSettings = new Watchable<FeatureSettings>(defaultFeatureSettings);
export const featureSettingsObservable: ChangeObservable<FeatureSettings> = featureSettings;

export const featureSettingsDocumentID = "features";
let didInitFeatureSettings = false;
const featureSettingsCV = new ConditionVariable();

function flagFeatureSettingsInit() {
    didInitFeatureSettings = true;
    featureSettingsCV.notifyAll();
}

export async function waitForFeatureSettings(): Promise<void> {
    while (!didInitFeatureSettings) {
        await featureSettingsCV.wait();
    }
}

// Shamelessly stolen from ChatGPT
function hashString(str: string) {
    // Simple hash function (FNV-1a) to hash the string
    let hash = 2166136261;
    for (let i = 0; i < str.length; i++) {
        hash ^= str.charCodeAt(i);
        hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
    }
    return hash >>> 0;
}

export function getAppIDFraction(featureSettingName: string, appID: string | undefined): number {
    if (appID === undefined) return 1;

    const combinedString = appID + featureSettingName;
    const hashValue = hashString(combinedString);
    return hashValue / 4294967295; // 2^32 - 1
}

function clearDisallowed(fs: FeatureSettings): FeatureSettings {
    const r = { ...fs };
    for (const setting of experimentalSettings) {
        r[setting] = false;
    }
    return r;
}

export function getFeatureSettingsFromDocumentData(data: DocumentData | undefined, db: DataReader): FeatureSettings {
    let r: FeatureSettings = { ...defaultFeatureSettings, ...data };

    if (!db.database.deploymentLocationSettings.allowExperimentalSettings) {
        r = clearDisallowed(r);
    }

    return r;
}

export async function getFeatureSettingsFromDatabase(db: DataReader): Promise<FeatureSettings> {
    return getFeatureSettingsFromDocumentData(
        await db.getDocument(globalSettingsCollectionName, featureSettingsDocumentID),
        db
    );
}

export function isFeatureSetting(name: string): name is FeatureSettingName {
    return defaultFeatureSettings.hasOwnProperty(name);
}

export function isFeatureSettingEnabled(settings: FeatureSettings, name: FeatureSettingName, appID?: string): boolean {
    const value = settings[name];
    if (value === "experiment") {
        return getFeatureFlag("showExperimental");
    }
    if (typeof value === "boolean") {
        return value;
    }
    return getAppIDFraction(name, appID) < value;
}

export function setFeatureSettings(fs: Partial<FeatureSettings>, db?: DataReader): FeatureSettings {
    let r = { ...defaultFeatureSettings, ...fs };
    if (db !== undefined && !db.database.deploymentLocationSettings.allowExperimentalSettings) {
        r = clearDisallowed(r);
    }

    featureSettings.current = r;
    flagFeatureSettingsInit();

    return featureSettings.current;
}

// This function should only be used for stories/tests
export function getCurrentFeatureSettings() {
    return featureSettings.current;
}

// This function is intended to be used in tests where many test need to be executed with specific feature settings and
// then reset afterward.
//
// Example:
//   describe("some behavior", () => {
//     const reset = overrideFeatureSettings({ pluginConcurrentCache: true });
//     afterAll(reset);
//
//     test("is awesome", async () => { ... });
//     test("is amazing", async () => { ... });
//   });
export function overrideFeatureSettingsWithReset(fs: Partial<FeatureSettings>): () => void {
    const oldDidInitFeatureSettings = didInitFeatureSettings;
    const oldFeatureSettings = featureSettings.current;

    didInitFeatureSettings = true;
    featureSettings.current = { ...featureSettings.current, ...fs };

    return () => {
        featureSettings.current = oldFeatureSettings;
        didInitFeatureSettings = oldDidInitFeatureSettings;
    };
}

export function withFeatureSettings<T>(fs: Partial<FeatureSettings>, f: () => T): T {
    const oldDidInitFeatureSettings = didInitFeatureSettings;
    const oldFeatureSettings = featureSettings.current;
    try {
        didInitFeatureSettings = true;
        featureSettings.current = { ...featureSettings.current, ...fs };
        return f();
    } finally {
        featureSettings.current = oldFeatureSettings;
        didInitFeatureSettings = oldDidInitFeatureSettings;
    }
}

export async function withFeatureSettingsAsync<T>(fs: Partial<FeatureSettings>, f: () => Promise<T>): Promise<T> {
    const oldDidInitFeatureSettings = didInitFeatureSettings;
    const oldFeatureSettings = featureSettings.current;
    try {
        didInitFeatureSettings = true;
        featureSettings.current = { ...featureSettings.current, ...fs };
        return await f();
    } finally {
        featureSettings.current = oldFeatureSettings;
        didInitFeatureSettings = oldDidInitFeatureSettings;
    }
}

export function listenFeatureSettings(
    db: Database,
    onFeatureSettingsChange: (featureSettings: FeatureSettings) => void
): void {
    db.listenToDocument(globalSettingsCollectionName, featureSettingsDocumentID, data => {
        if (data === undefined) return;

        featureSettings.current = getFeatureSettingsFromDocumentData(data, db);
        flagFeatureSettingsInit();

        onFeatureSettingsChange(featureSettings.current);
    });
}

export async function refreshFeatureSettings(db: DataReader): Promise<void> {
    const newVal = await getFeatureSettingsFromDatabase(db);
    featureSettings.current = newVal;
    flagFeatureSettingsInit();
}

export async function initFeatureSettings(db: DataReader): Promise<void> {
    if (didInitFeatureSettings) return;

    await refreshFeatureSettings(db);
}

/**
 * This function returns the probability of a feature setting being enabled.
 * It checks the current value of the feature setting and returns a number based on its type and value.
 *
 * @param {FeatureSettingName} name - The name of the feature setting.
 *
 */
export function getFeatureSettingProbability(name: FeatureSettingName): number {
    const value = featureSettings.current[name];
    if (value === "experiment") {
        return getFeatureFlag("showExperimental") ? 1 : 0;
    }
    if (typeof value === "boolean") {
        return value ? 1 : 0;
    }
    return value;
}

/**
 * This function returns the number value of a feature setting. It checks the
 * current value of the feature setting and returns the number value if it is
 * a number.
 *
 * @param {FeatureSettingName} name - The name of the feature setting.
 *
 */
export function getFeatureSettingNumber(name: FeatureSettingName): number | undefined {
    const value = featureSettings.current[name];
    if (typeof value === "number") {
        return value;
    }
    return undefined;
}

/**
 * Gets the *global* feature setting for the given name. Feature setting values are the same for all users of Glide.
 *
    const isTrackingEnforcement = getFeatureFlag("isTrackingEnforcement");

 * Use `defaultUserFeatures` for per-team feature settings.
 */
export function getFeatureSetting(name: FeatureSettingName, appID?: string): boolean {
    return isFeatureSettingEnabled(featureSettings.current, name, appID);
}

function getHasV3TeamBasedPricingFromParts(ownerKind: OwnerKind, features: UserFeatures | undefined): boolean {
    // returns false if isMyApps legacy org - o/0/
    const isMyApps = ownerKind === OwnerKind.User;
    if (isMyApps) return false;
    if (features?.hasV3TeamBasedPricing === true) return true;
    return false;
}

export function getHasV3TeamBasedPricing(owner: Owner): boolean {
    return getHasV3TeamBasedPricingFromParts(owner.kind, owner.features);
}

function getIsV3FreePlanFromParts(ownerKind: OwnerKind, features: UserFeatures | undefined): boolean {
    if (getHasV3TeamBasedPricingFromParts(ownerKind, features)) return true;
    // returns false if isMyApps legacy org - o/0/
    const isMyApps = ownerKind === OwnerKind.User;
    if (isMyApps) return false;
    if (features?.isV3FreePlan === true) return true;
    return false;
}

export function getIsV3FreePlan(owner: Owner): boolean {
    return getIsV3FreePlanFromParts(owner.kind, owner.features);
}

const featureSettingsOnce: Partial<Record<keyof typeof defaultFeatureSettings, boolean>> = {};

export async function getFeatureSettingStable(name: FeatureSettingName, appID?: string): Promise<boolean> {
    await waitForFeatureSettings();
    const maybeOnce = featureSettingsOnce[name];
    if (maybeOnce !== undefined) return maybeOnce;

    const result = getFeatureSetting(name, appID);
    featureSettingsOnce[name] = result;
    return result;
}

export function getHasReusableActions(appKind: AppKind): boolean {
    return appKind === AppKind.Page;
}
