import { useCoreTheme } from "@glide/theme";

// Some random string to number hash I found.
function stringToHash(string: string): number {
    let hash = 0;

    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
    }

    return hash;
}

export function useColorFromSeed(seed: string): string {
    const theme = useCoreTheme();

    const colors = [
        theme.newBrand1,
        theme.newBrand2,
        theme.newBrand3,
        theme.newBrand4,
        theme.newBrand5,
        theme.newBrand6,
        theme.newBrand7,
    ];

    const index = Math.abs(stringToHash(seed)) % colors.length;

    return colors[index];
}
