import { logError } from "@glide/support";

export interface CurrentLocationListener {
    updateLocation(latitude: number, longitude: number): void;
    locationError(): void;
}

const locationListeners: Set<CurrentLocationListener> = new Set();
let watchID: number | undefined;
let lastPosition: GeolocationPosition | undefined;

function onWatchSuccess(position: GeolocationPosition): void {
    // logInfo("got geolocation", position);

    lastPosition = position;

    for (const l of locationListeners) {
        try {
            l.updateLocation(position.coords.latitude, position.coords.longitude);
        } catch (e: unknown) {
            logError("Error calling position update", e);
        }
    }
}

function onWatchError(error: GeolocationPositionError): void {
    logError("geolocation error", error);

    lastPosition = undefined;

    for (const l of locationListeners) {
        try {
            l.locationError();
        } catch (e: unknown) {
            logError("Error calling position error handler", e);
        }
    }
}

export function listenToCurrentLocation(listener: CurrentLocationListener): void {
    locationListeners.add(listener);
    if (watchID === undefined) {
        try {
            watchID = navigator.geolocation.watchPosition(onWatchSuccess, onWatchError);
            // logInfo("listening to geolocation", watchID);
        } catch (e: unknown) {
            logError("Cannot watch geolocation", e);
            listener.locationError();
        }
    } else if (lastPosition !== undefined) {
        const { latitude, longitude } = lastPosition.coords;
        setTimeout(() => listener.updateLocation(latitude, longitude), 0);
    }
}

export function stopListeningToCurrentLocation(listener: CurrentLocationListener): void {
    locationListeners.delete(listener);
    if (locationListeners.size === 0 && watchID !== undefined) {
        try {
            // logInfo("stopping listening to geolocation", watchID);
            navigator.geolocation.clearWatch(watchID);
            watchID = undefined;
        } catch (e: unknown) {
            logError("Error unwatching location", e);
            watchID = undefined;
        }
    }
}
