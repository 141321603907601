import {
    formatNumber,
    keyValuePairs,
    logError,
    isAlphabetic,
    isAlphanumeric,
    isDigit,
    isWhitespace,
} from "@glide/support";
import type {
    BinaryMathFormula,
    ConstantFormula,
    GetVariableFormula,
    UnaryMathFormula,
    WithUserEnteredTextFormula,
    Formula,
} from "@glide/type-schema";
import { BinaryMathFunction, UnaryMathFunction, FormulaKind } from "@glide/type-schema";
import { panic, assert, defined } from "@glideapps/ts-necessities";

interface TokenBase {
    readonly index: number;
    readonly length: number;
}

interface ErrorToken extends TokenBase {
    readonly kind: "error";
    readonly message: string;
}

type BinaryOperatorTokenKind = "+" | "-" | "*" | "/" | "^";

interface BasicToken extends TokenBase {
    readonly kind: "(" | ")" | "," | BinaryOperatorTokenKind;
}

interface IdentifierToken extends TokenBase {
    readonly kind: "identifier";
    readonly name: string;
}

interface NumberToken extends TokenBase {
    readonly kind: "number";
    readonly value: number;
}

type Token = ErrorToken | BasicToken | IdentifierToken | NumberToken;

type TokenKind = Token extends { kind: infer T } ? T : never;

class Scanner {
    private _index: number = 0;
    constructor(private readonly _s: string, private readonly _curlyVariables: boolean) {}

    public get index(): number {
        return this._index;
    }

    private consumeChar(): void {
        this._index += 1;
    }

    private getChar(): string | undefined {
        return this._s[this._index];
    }

    private skipWhitespace(): void {
        for (;;) {
            const c = this.getChar();
            if (c === undefined || (c !== "\n" && !isWhitespace(c))) {
                break;
            }
            this.consumeChar();
        }
    }

    private getTokenStartingAt(index: number, endIndex?: number): { length: number; s: string } {
        if (endIndex === undefined) {
            endIndex = this._index;
        }
        const length = endIndex - index;
        assert(length > 0);
        const s = this._s.substr(index, length);
        return { length, s };
    }

    private scanNumber(index: number, first: string): Token {
        let haveComma = first === ".";
        let haveDigit = !haveComma;
        for (;;) {
            const c = this.getChar();
            if (c === undefined) break;

            if (c === ".") {
                if (haveComma) break;
                haveComma = true;
                this.consumeChar();
            } else if (isDigit(c)) {
                haveDigit = true;
                this.consumeChar();
            } else {
                break;
            }
        }

        const { length, s } = this.getTokenStartingAt(index);

        if (!haveDigit) {
            return { kind: "error", index, length, message: "This decimal point needs a number to go with it" };
        }

        return { kind: "number", index, length, value: parseFloat(s) };
    }

    private scanIdentifier(index: number): Token {
        let endIndex: number | undefined;

        for (;;) {
            let c = this.getChar();
            if (c === undefined) {
                endIndex = this._index;
                break;
            }
            if (!isAlphanumeric(c)) {
                endIndex = this._index;
                this.skipWhitespace();
                c = this.getChar();
                if (c === undefined) break;
                if (!isAlphanumeric(c)) break;
                continue;
            }
            this.consumeChar();
        }

        const { length, s } = this.getTokenStartingAt(index, endIndex);
        return { kind: "identifier", index, length, name: s };
    }

    public nextToken(): Token | undefined {
        this.skipWhitespace();

        let index = this._index;

        function invalid(ch: string): Token {
            return { kind: "error", index, length: 0, message: `The character "${ch}" is not valid in a formula` };
        }

        function notVariableChar(ch: string): Token {
            return {
                kind: "error",
                index,
                length: 1,
                message: `Only letters and digits are allowed in variable names, not "${ch}"`,
            };
        }

        let c = this.getChar();

        switch (c) {
            case undefined:
                return undefined;

            case "(":
            case ")":
            case ",":
            case "+":
            case "-":
            case "*":
            case "/":
            case "^":
                this.consumeChar();
                return { kind: c, index, length: 1 };

            case "{":
                if (!this._curlyVariables) return invalid(c);
                this.consumeChar();
                this.skipWhitespace();
                index = this._index;
                c = this.getChar();
                if (c === undefined) {
                    return {
                        kind: "error",
                        index,
                        length: 0,
                        message: "Missing variable name and closing curly brace",
                    };
                }
                if (!isAlphanumeric(c)) return notVariableChar(c);
                const t = this.scanIdentifier(index);
                this.skipWhitespace();
                index = this._index;
                c = this.getChar();
                if (c === undefined) {
                    return { kind: "error", index, length: 0, message: "Missing closing curly brace" };
                }
                if (c !== "}") return notVariableChar(c);
                this.consumeChar();
                return t;

            default:
                if (c === "." || isDigit(c)) {
                    this.consumeChar();
                    return this.scanNumber(index, c);
                }
                if (isAlphabetic(c)) {
                    this.consumeChar();
                    return this.scanIdentifier(index);
                }
                return invalid(c);
        }
    }
}

interface SuccessMathParseResult {
    readonly success: true;
    readonly formula: Formula;
    readonly variableNames: readonly string[];
}

interface ErrorMathParseResult {
    readonly success: false;
    readonly message: string;
    readonly index: number;
}

export type MathParseResult = SuccessMathParseResult | ErrorMathParseResult;

type Function = { kind: "fn"; fn: string };
type OperatorOrFunction<K> = { kind: K; precedence: number } | Function;

const binaryOperators: Record<BinaryMathFunction, OperatorOrFunction<BinaryOperatorTokenKind>> = {
    [BinaryMathFunction.Add]: { kind: "+", precedence: 1 },
    [BinaryMathFunction.Subtract]: { kind: "-", precedence: 1 },
    [BinaryMathFunction.Multiply]: { kind: "*", precedence: 2 },
    [BinaryMathFunction.Divide]: { kind: "/", precedence: 2 },
    [BinaryMathFunction.Modulo]: { kind: "fn", fn: "mod" },
    [BinaryMathFunction.Minimum]: { kind: "fn", fn: "min" },
    [BinaryMathFunction.Maximum]: { kind: "fn", fn: "max" },
    [BinaryMathFunction.Power]: { kind: "^", precedence: 3 },
    [BinaryMathFunction.Logarithm]: { kind: "fn", fn: "log" },
    [BinaryMathFunction.ArcTangent2]: { kind: "fn", fn: "atan2" },
    [BinaryMathFunction.Round]: { kind: "fn", fn: "round" },
    [BinaryMathFunction.Truncate]: { kind: "fn", fn: "trunc" },
};

const unaryOperatorPrecedence = 4;

const unaryOperators: Record<UnaryMathFunction, OperatorOrFunction<"-">> = {
    [UnaryMathFunction.Negate]: { kind: "-", precedence: unaryOperatorPrecedence },
    [UnaryMathFunction.Absolute]: { kind: "fn", fn: "abs" },
    [UnaryMathFunction.Floor]: { kind: "fn", fn: "floor" },
    [UnaryMathFunction.Ceiling]: { kind: "fn", fn: "ceiling" },
    [UnaryMathFunction.Round]: { kind: "fn", fn: "round" },
    [UnaryMathFunction.Sign]: { kind: "fn", fn: "sign" },
    [UnaryMathFunction.Truncate]: { kind: "fn", fn: "trunc" },
    [UnaryMathFunction.SquareRoot]: { kind: "fn", fn: "sqrt" },
    [UnaryMathFunction.Sine]: { kind: "fn", fn: "sin" },
    [UnaryMathFunction.Cosine]: { kind: "fn", fn: "cos" },
    [UnaryMathFunction.Tangent]: { kind: "fn", fn: "tan" },
    [UnaryMathFunction.ArcSine]: { kind: "fn", fn: "asin" },
    [UnaryMathFunction.ArcCosine]: { kind: "fn", fn: "acos" },
    [UnaryMathFunction.ArcTangent]: { kind: "fn", fn: "atan" },
    [UnaryMathFunction.Logarithm]: { kind: "fn", fn: "log" },
    [UnaryMathFunction.Year]: { kind: "fn", fn: "year" },
    [UnaryMathFunction.Month]: { kind: "fn", fn: "month" },
    [UnaryMathFunction.Day]: { kind: "fn", fn: "day" },
    [UnaryMathFunction.Hour]: { kind: "fn", fn: "hour" },
    [UnaryMathFunction.Minute]: { kind: "fn", fn: "minute" },
    [UnaryMathFunction.Second]: { kind: "fn", fn: "second" },
    [UnaryMathFunction.Weekday]: { kind: "fn", fn: "weekday" },
    [UnaryMathFunction.WeekNumber]: { kind: "fn", fn: "weeknum" },
};

const outerPrecedence = 0;

function getFunctionForBinaryOperator(op: BinaryOperatorTokenKind): BinaryMathFunction {
    for (const k of Object.keys(binaryOperators) as BinaryMathFunction[]) {
        if (binaryOperators[k].kind === op) {
            return k;
        }
    }
    return panic(`Binary operator not found: ${op}`);
}

export function parseMath(s: string, curlyVariables: boolean): MathParseResult {
    const scanner = new Scanner(s, curlyVariables);
    let t = scanner.nextToken();
    // maps from lowercase to preferred case
    const variableNames = new Map<string, string>();
    let errorIndex: number | undefined;
    let errorMessage: string | undefined;

    function nextToken(): Token | undefined {
        t = scanner.nextToken();
        return t;
    }

    function error(index: number, message: string): undefined {
        errorIndex = index;
        errorMessage = message;
        return undefined;
    }

    function consume(kind: TokenKind): boolean {
        if (t === undefined) {
            error(scanner.index, `There's a ${kind} missing`);
            return false;
        } else if (t.kind === "error") {
            error(t.index, t.message);
            return false;
        } else if (t.kind !== kind) {
            error(t.index, `There should be a ${kind}`);
            return false;
        }
        nextToken();
        return true;
    }

    function makeBinaryFunctionCall(
        name: string,
        lowerName: string,
        args: readonly Formula[],
        index: number
    ): Formula | undefined {
        for (const [fn, op] of keyValuePairs(binaryOperators)) {
            if (op === undefined || op.kind !== "fn" || op.fn !== lowerName) continue;

            if (args.length !== 2) {
                return error(index, `The "${name}" function takes two arguments`);
            }
            return {
                kind: FormulaKind.BinaryMath,
                fn,
                left: args[0],
                right: args[1],
            } as BinaryMathFormula;
        }
        return undefined;
    }

    function makeUnaryFunctionCall(
        name: string,
        lowerName: string,
        args: readonly Formula[],
        index: number
    ): Formula | undefined {
        for (const [fn, op] of keyValuePairs(unaryOperators)) {
            if (op === undefined || op.kind !== "fn" || op.fn !== lowerName) continue;

            if (args.length !== 1) {
                return error(index, `The "${name}" function takes one argument`);
            }
            return {
                kind: FormulaKind.UnaryMath,
                fn,
                operand: args[0],
            } as UnaryMathFormula;
        }
        return undefined;
    }

    function makeRandomFunctionCall(
        name: string,
        lowerName: string,
        args: readonly Formula[],
        index: number
    ): Formula | undefined {
        if (lowerName === "rand") {
            if (args.length !== 0) {
                return error(index, `The "${name}" function takes no arguments`);
            }
            return {
                kind: FormulaKind.Random,
            };
        }
        return undefined;
    }

    function makeFunctionCall(
        name: string,
        lowerName: string,
        args: readonly Formula[],
        index: number
    ): Formula | undefined {
        if (lowerName === "round" || lowerName === "trunc") {
            if (args.length === 1) {
                return makeUnaryFunctionCall(name, lowerName, args, index);
            } else if (args.length === 2) {
                return makeBinaryFunctionCall(name, lowerName, args, index);
            } else {
                return error(index, `The "${name}" function takes one or two arguments`);
            }
        }
        const result =
            makeBinaryFunctionCall(name, lowerName, args, index) ??
            makeUnaryFunctionCall(name, lowerName, args, index) ??
            makeRandomFunctionCall(name, lowerName, args, index);
        if (result === undefined && errorMessage === undefined) {
            return error(index, `"${name}" is not a function`);
        }
        return result;
    }

    function prematureEnd(): undefined {
        return error(scanner.index, "There's something missing");
    }

    function parsePrimary(): Formula | undefined {
        if (t === undefined) return prematureEnd();

        switch (t.kind) {
            case "error":
                return error(t.index, t.message);
            case "identifier":
                const startIndex = t.index;
                const { name } = t;
                const lower = name.toLowerCase();

                let tok = nextToken();

                if (tok?.kind === "(") {
                    tok = nextToken();
                    const args: Formula[] = [];
                    for (;;) {
                        // At this point we have parsed the open paren, or the
                        // last argument. That means that we expect either a
                        // close paren, or the first argument, or a semicolon
                        // and the next argument.
                        if (tok === undefined) return prematureEnd();
                        if (tok.kind === ")") {
                            nextToken();
                            break;
                        }
                        if (args.length > 0) {
                            if (tok.kind !== ",") {
                                return error(scanner.index, 'There should be a "," or a ")"');
                            }
                            nextToken();
                        }

                        const arg = parseSum();
                        if (arg === undefined) return undefined;
                        args.push(arg);

                        tok = t;
                    }

                    return makeFunctionCall(name, lower, args, startIndex);
                }

                let preferred = variableNames.get(lower);
                if (preferred === undefined) {
                    preferred = name;
                    variableNames.set(lower, preferred);
                }
                return {
                    kind: FormulaKind.GetVariable,
                    name: preferred,
                } as GetVariableFormula;
            case "number":
                const { value } = t;
                nextToken();
                return { kind: FormulaKind.Constant, value } as ConstantFormula;
            case "(": {
                nextToken();
                const expr = parseSum();
                if (expr !== undefined) {
                    if (!consume(")")) return undefined;
                }
                return expr;
            }
            case "-": {
                nextToken();
                const operand = parsePrimary();
                if (operand === undefined) return undefined;
                return { kind: FormulaKind.UnaryMath, fn: UnaryMathFunction.Negate, operand } as UnaryMathFormula;
            }
            default:
                return error(t.index, `There should be a number, name, or "("`);
        }
    }

    function parseBinary(
        parseOperand: () => Formula | undefined,
        operators: readonly BinaryOperatorTokenKind[]
    ): Formula | undefined {
        let f = parseOperand();
        if (f === undefined) return undefined;

        while (t !== undefined && (operators as readonly string[]).indexOf(t.kind) >= 0) {
            const kind = t.kind as BinaryOperatorTokenKind;

            nextToken();

            const right = parseOperand();
            if (right === undefined) return undefined;

            f = {
                kind: FormulaKind.BinaryMath,
                fn: getFunctionForBinaryOperator(kind),
                left: f,
                right,
            } as BinaryMathFormula;
        }

        return f;
    }

    const parsePower = () => parseBinary(parsePrimary, ["^"]);
    const parseProduct = () => parseBinary(parsePower, ["*", "/"]);
    const parseSum = () => parseBinary(parseProduct, ["+", "-"]);

    function parseExpr(): Formula | undefined {
        const result = parseSum();
        if (result === undefined) return undefined;
        if (t !== undefined) {
            if (t.kind === "error") {
                return error(t.index, t.message);
            } else {
                return error(t.index, `There should be a "+", "-", "*", "/", or "^"`);
            }
        }
        return result;
    }

    try {
        const formula = parseExpr();
        if (formula === undefined) {
            return {
                success: false,
                message: defined(errorMessage),
                index: defined(errorIndex),
            };
        } else {
            return {
                success: true,
                formula: { kind: FormulaKind.WithUserEnteredText, text: s, formula } as WithUserEnteredTextFormula,
                variableNames: Array.from(variableNames.values()),
            };
        }
    } catch (e: unknown) {
        logError("Error parsing math", e, s);
        return {
            success: false,
            message: "Unknown error parsing math",
            index: errorIndex ?? 0,
        };
    }
}

export function unparseMath(rootFormula: Formula): string | undefined {
    function unparse(formula: Formula, enclosingPrecedence: number): string | undefined {
        switch (formula.kind) {
            case FormulaKind.GetVariable:
                return (formula as GetVariableFormula).name;
            case FormulaKind.Constant: {
                const f = formula as ConstantFormula;
                if (typeof f.value !== "number") return undefined;
                return formatNumber(f.value);
            }
            case FormulaKind.UnaryMath: {
                const f = formula as UnaryMathFormula;
                const unop = unaryOperators[f.fn];
                if (unop.kind === "fn") {
                    const operand = unparse(f.operand, outerPrecedence);
                    if (operand === undefined) return undefined;
                    return unop.fn + "(" + operand + ")";
                } else {
                    const { kind, precedence } = unop;
                    const operand = unparse(f.operand, precedence);
                    if (operand === undefined) return undefined;
                    return kind + operand;
                }
            }
            case FormulaKind.BinaryMath: {
                const f = formula as BinaryMathFormula;
                const binop = binaryOperators[f.fn];
                if (binop.kind === "fn") {
                    const left = unparse(f.left, outerPrecedence);
                    const right = unparse(f.right, outerPrecedence);
                    if (left === undefined || right === undefined) return undefined;
                    return binop.fn + "(" + left + "," + right + ")";
                } else {
                    const { kind, precedence } = binop;
                    const left = unparse(f.left, precedence);
                    const right = unparse(f.right, precedence);
                    if (left === undefined || right === undefined) return undefined;
                    const expr = left + kind + right;
                    if (enclosingPrecedence <= precedence) {
                        return expr;
                    } else {
                        return "(" + expr + ")";
                    }
                }
            }
            case FormulaKind.WithUserEnteredText:
                return (formula as WithUserEnteredTextFormula).text;
            default:
                return undefined;
        }
    }

    try {
        return unparse(rootFormula, outerPrecedence);
    } catch (e: unknown) {
        logError("Error unparsing math", e);
        return undefined;
    }
}
