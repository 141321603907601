import { hasOwnProperty } from "@glideapps/ts-necessities";

export const localizedDevStrings = {};

export const intentionallyUntranslatedLocalizedDevStrings = {};

type DevLanguageStrings = typeof localizedDevStrings & typeof intentionallyUntranslatedLocalizedDevStrings;
export type DevStringKey = keyof DevLanguageStrings;

export function getDevLocalizedString(name: DevStringKey): string {
    if (hasOwnProperty(localizedDevStrings, name)) {
        return localizedDevStrings[name] as string;
    }
    return (intentionallyUntranslatedLocalizedDevStrings as any)[name];
}
