import { hasOwnProperty, isEnumValue } from "@glideapps/ts-necessities";

// Please prefix the non-free values with v1/v2/etc based on which iteration of Glide's pricing model they belong to.

// Refers to https://github.com/quicktype/glide/pull/12454/files using here for temp use.
export enum PlanKind {
    Free = "free",
    // AKA "Enterprise". Named this way in anticipation of a possible future
    // "Enterprise Lite" plan that isn't unlimited like the current offering is.
    Unlimited = "unlimited",
    V3Starter = "v3-starter",
    V3Pro = "v3-pro",
    V3Business = "v3-business",
    V3NonProfit = "v3-non-profit",
    // "NoUP" means "No Updates/Private Users".
    // It is meant to be sold by sales with the Packaged Users and Updates to customize a plan.
    V3EnterpriseBaseNoUP = "v3-enterprise-base-no-up",
    // These plans are for testing purposes. They may be removed at any time.
    TestPro = "test-pro",
}

// If true, users should be forbidden from intentionally upgrading to, or
// downgrading away from, the given `PlanKind`.
// Under no circumstances should these be handled by anyone but the sales team.
export function planKindIsUpgradeLocked(
    x: PlanKind
): x is PlanKind.Unlimited | PlanKind.V3NonProfit | PlanKind.V3EnterpriseBaseNoUP {
    switch (x) {
        case PlanKind.Unlimited:
        case PlanKind.V3NonProfit:
        case PlanKind.V3EnterpriseBaseNoUP:
            return true;
        default:
            return false;
    }
}

export enum AddonKind {
    /* CURRENT ADDONS */
    // Whitelabels governed by team-based plans
    V3StarterPlanWhitelabel = "v3-starter-plan-whitelabel",
    V3ProPlanWhitelabel = "v3-pro-plan-whitelabel",
    V3BusinessPlanWhitelabel = "v3-business-plan-whitelabel",
    // These overages will enable the overage permission in Eminence.
    V3StarterPlanUpdateOverage = "v3-starter-plan-updates-overage",
    V3ProPlanUpdateOverage = "v3-pro-plan-updates-overage",
    V3BusinessPlanUpdateOverage = "v3-business-plan-updates-overage",
    V3NonProfitPlanUpdateOverage = "v3-non-profit-plan-updates-overage",
    // Package plans, to be sold only by the sales team.
    V3SalesPackagedUpdate = "v3-sales-packaged-update",
    V3SalesPackagedPrivateUser = "v3-sales-packaged-private-user",
    V3SalesPackagedDeliverEmail = "v3-sales-packaged-deliver-email",
    /* DEPRECATED APP PLANS */
    // These addons grant individual apps their own plan.
    // They are backward-compatible with previous billing systems.
    V1ProApp = "v1-pro-app",
    V1OrgProPublic = "v1-org-pro-public",
    // This addon is both an app plan addon, as well as a metered addon.
    V1OrgProInternalUserOverage = "v1-org-pro-internal-user-overage",
    // These addons represent "app plans" in the unified pricing model.
    V2BasicPublicApp = "v2-basic-public-app",
    V2BasicPrivateApp = "v2-basic-private-app",
    V2ProPublicApp = "v2-pro-public-app",
    V2ProPrivateApp = "v2-pro-private-app",
    V2ProPrivatePage = "v2-pro-private-page",
    /* DEPRECATED ADDONS */
    // These represent currently-existing addons in the unified pricing model.
    V2BasicPrivateUser = "v2-basic-private-user",
    V2ProPrivateUser = "v2-pro-private-user",
    V2ProPrivatePageUser = "v2-pro-private-page-user",
    // Whitelabel for Glide Pages. This is v2, despite the v3 name
    V3AllowWhiteLabelling = "v3-allow-white-labelling",
    /* DEPRECATED NON-SUBSCRIPTION ADDONS */
    // These aren't overages in a traditional sense, because they aren't metered
    // like other overages. These overages are added to invoices at the end of a
    // billing cycle, so they aren't present on a subscription at all and thus
    // should not affect Eminence.
    V2BasicPrivateUserOverage = "v2-basic-private-user-overage",
    V2ProPrivateUserOverage = "v2-pro-private-user-overage",
    V2ProPrivatePageUserOverage = "v2-pro-private-page-user-overage",
}

export function planWhitelabelAddon(plan: PlanKind): AddonKind | undefined {
    switch (plan) {
        case PlanKind.V3Starter:
            return AddonKind.V3StarterPlanWhitelabel;
        case PlanKind.V3Pro:
            return AddonKind.V3ProPlanWhitelabel;
        case PlanKind.V3Business:
            return AddonKind.V3BusinessPlanWhitelabel;
        // V3Free and V3NonProfit can't whitelabel.
        // Enterprise/Unlimited always whitelabels.
        default:
            return undefined;
    }
}

export function planPaidUpdatesAddon(plan: PlanKind): AddonKind | undefined {
    switch (plan) {
        case PlanKind.V3Starter:
            return AddonKind.V3StarterPlanUpdateOverage;
        case PlanKind.V3Pro:
            return AddonKind.V3ProPlanUpdateOverage;
        case PlanKind.V3Business:
            return AddonKind.V3BusinessPlanUpdateOverage;
        case PlanKind.V3NonProfit:
            return AddonKind.V3NonProfitPlanUpdateOverage;
        // V3Free can't buy additional updates.
        // Unlimited has unlimited updates.
        default:
            return undefined;
    }
}

export type BillingPeriod = "monthly" | "annual";

export function isBillingPeriod(x: unknown): x is BillingPeriod {
    return x === "monthly" || x === "annual";
}

// TODO (post-launch): Make the App/Page plans into an enum that is mutually exclusive with the other Addons. They
// should still be stored as addons inside Subscriptions, but their type definitions ought to be separate to avoid
// needing to use this function.
export function addonIsAppPlan(x: AddonKind) {
    switch (x) {
        case AddonKind.V1ProApp:
        case AddonKind.V1OrgProPublic:
        case AddonKind.V1OrgProInternalUserOverage:
        case AddonKind.V2BasicPublicApp:
        case AddonKind.V2BasicPrivateApp:
        case AddonKind.V2ProPublicApp:
        case AddonKind.V2ProPrivateApp:
        case AddonKind.V2ProPrivatePage:
            return true;
        default:
            return false;
    }
}

// If an app plan is being canceled, these addons must be canceled as well.
function addonCancelsWithAppPlan(x: AddonKind) {
    switch (x) {
        case AddonKind.V2BasicPrivateUser:
        case AddonKind.V2ProPrivateUser:
        case AddonKind.V2ProPrivatePageUser:
        case AddonKind.V3AllowWhiteLabelling:
            return true;
        default:
            return false;
    }
}

// If we're upgrading an app to an app plan, these app addons must be canceled beforehand.
export function addonCancelsWhenSubscribingToAppPlan(x: AddonKind) {
    return addonIsAppPlan(x) || addonCancelsWithAppPlan(x);
}

function addonIsPlanWhitelabel(x: AddonKind): boolean {
    switch (x) {
        case AddonKind.V3StarterPlanWhitelabel:
        case AddonKind.V3ProPlanWhitelabel:
        case AddonKind.V3BusinessPlanWhitelabel:
            return true;
        default:
            return false;
    }
}

function addonIsPaidUpdates(x: AddonKind): boolean {
    switch (x) {
        case AddonKind.V3StarterPlanUpdateOverage:
        case AddonKind.V3ProPlanUpdateOverage:
        case AddonKind.V3BusinessPlanUpdateOverage:
            return true;
        default:
            return false;
    }
}

// Certain addons are only available on certain team-based plans.
export function addonIsPlanLocked(x: AddonKind): boolean {
    return addonIsPlanWhitelabel(x) || addonIsPaidUpdates(x);
}

export function addonIsMetered(x: AddonKind) {
    switch (x) {
        case AddonKind.V1OrgProInternalUserOverage:
        case AddonKind.V2BasicPrivateUserOverage:
        case AddonKind.V2ProPrivateUserOverage:
        case AddonKind.V2ProPrivatePageUserOverage:
        case AddonKind.V3StarterPlanUpdateOverage:
        case AddonKind.V3ProPlanUpdateOverage:
        case AddonKind.V3BusinessPlanUpdateOverage:
        case AddonKind.V3NonProfitPlanUpdateOverage:
            return true;
        default:
            return false;
    }
}

export function addonIsLimitedPerApp(x: AddonKind): boolean {
    if (addonIsAppPlan(x)) return true;
    switch (x) {
        case AddonKind.V3AllowWhiteLabelling:
        case AddonKind.V3StarterPlanWhitelabel:
        case AddonKind.V3ProPlanWhitelabel:
        case AddonKind.V3BusinessPlanWhitelabel:
            return true;
        default:
            return false;
    }
}

export function addonIsUpgradeLocked(x: AddonKind): boolean {
    switch (x) {
        case AddonKind.V3SalesPackagedUpdate:
        case AddonKind.V3SalesPackagedPrivateUser:
        case AddonKind.V3SalesPackagedDeliverEmail:
            return true;
        default:
            return false;
    }
}

export function privateUserAddonKind(appPlanKind: AddonKind): AddonKind | undefined {
    if (!addonIsAppPlan(appPlanKind)) return undefined;
    switch (appPlanKind) {
        case AddonKind.V2BasicPrivateApp:
            return AddonKind.V2BasicPrivateUser;
        case AddonKind.V2ProPrivateApp:
            return AddonKind.V2ProPrivateUser;
        case AddonKind.V2ProPrivatePage:
            return AddonKind.V2ProPrivatePageUser;
        default:
            return undefined;
    }
}

// NOTE REGARDING STRIPE DATA
// Broadly speaking the types that contain Stripe data should be used only in the back-end.  When sending a Subscription
// to the front-end (if necessary at all), cast it from SubscriptionWithStripeData to Subscription instead, so we don't
// leak any Stripe information.

interface SubscriptionItemStripeData {
    stripeSubscriptionItemID: string;
    stripePriceID: string;
}

function isSubscriptionItemStripeData(x: unknown): x is SubscriptionItemStripeData {
    return (
        hasOwnProperty(x, "stripeSubscriptionItemID") &&
        typeof x.stripeSubscriptionItemID === "string" &&
        hasOwnProperty(x, "stripePriceID") &&
        typeof x.stripePriceID === "string"
    );
}

export interface SubscriptionAddon {
    // Some addons are applied on a per-app basis, such as all legacy/unified plans, or white-labelling.
    appID?: string;
    kind: AddonKind;
    count: number;
    period?: BillingPeriod;
}

function isSubscriptionAddon(x: unknown): x is SubscriptionAddon {
    return (
        (!hasOwnProperty(x, "appID") || x.appID === undefined || typeof x.appID === "string") &&
        hasOwnProperty(x, "kind") &&
        isEnumValue(AddonKind, x.kind) &&
        hasOwnProperty(x, "count") &&
        typeof x.count === "number" &&
        (!hasOwnProperty(x, "period") || x.period === undefined || x.period === "monthly" || x.period === "annual")
    );
}

export interface SubscriptionAddonWithStripeData extends SubscriptionAddon, SubscriptionItemStripeData {}

export function isSubscriptionAddonWithStripeData(x: unknown): x is SubscriptionAddonWithStripeData {
    return isSubscriptionAddon(x) && isSubscriptionItemStripeData(x);
}

interface SubscriptionBase {
    subscriptionStatus: string;
    plan: PlanKind;
    planIteration: number;
    period: BillingPeriod;
    trialEnd?: Date;
    stripePromoCode?: string;
    isCustomerPaymentMethodAttached?: boolean;
    isSubscriptionPaymentMethodAttached?: boolean;
}

export function isSubscriptionValid(subscriptionStatus: string): boolean {
    switch (subscriptionStatus) {
        case "incomplete_expired":
        case "canceled":
            return false;
        default:
            return true;
    }
}

export function isSubscriptionComplete(subscriptionStatus: string): boolean {
    if (!isSubscriptionValid(subscriptionStatus)) return false;
    switch (subscriptionStatus) {
        case "incomplete":
        case "past_due":
            return false;
        default:
            return true;
    }
}

function isSubscriptionBase(x: unknown): x is SubscriptionBase {
    return (
        hasOwnProperty(x, "subscriptionStatus") &&
        typeof x.subscriptionStatus === "string" &&
        hasOwnProperty(x, "plan") &&
        isEnumValue(PlanKind, x.plan) &&
        hasOwnProperty(x, "planIteration") &&
        typeof x.planIteration === "number" &&
        hasOwnProperty(x, "period") &&
        isBillingPeriod(x.period) &&
        (!hasOwnProperty(x, "stripePromoCode") ||
            x.stripePromoCode === undefined ||
            typeof x.stripePromoCode === "string")
    );
}

interface SubscriptionPlan extends SubscriptionBase {
    addons: SubscriptionAddon[];
}

function isSubscriptionPlan(x: unknown): x is SubscriptionPlan {
    return (
        isSubscriptionBase(x) &&
        hasOwnProperty(x, "addons") &&
        Array.isArray(x.addons) &&
        x.addons.every(isSubscriptionAddon)
    );
}

export interface SubscriptionPlanWithStripeData extends SubscriptionBase {
    stripeSubscriptionItemID: string;
    stripePriceID: string;
    addons: SubscriptionAddonWithStripeData[];
}

function isSubscriptionPlanWithStripeData(x: unknown): x is SubscriptionPlanWithStripeData {
    return (
        isSubscriptionBase(x) &&
        hasOwnProperty(x, "stripeSubscriptionItemID") &&
        typeof x.stripeSubscriptionItemID === "string" &&
        hasOwnProperty(x, "stripePriceID") &&
        typeof x.stripePriceID === "string" &&
        hasOwnProperty(x, "addons") &&
        Array.isArray(x.addons) &&
        x.addons.every(isSubscriptionAddonWithStripeData)
    );
}

export interface Subscription extends SubscriptionPlan {
    ownerID: string;
    // Only explicitly used by Billing V4 so far, it's used to hide the whitelabeling toggles in the app
    // because Billing V4 supports it in all cases.
    disableWhitelabeling?: true;
}

export function isSubscription(x: unknown): x is Subscription {
    return isSubscriptionPlan(x) && hasOwnProperty(x, "ownerID") && typeof x.ownerID === "string";
}

interface SubscriptionStripeData {
    stripeSubscriptionID: string;
    stripeCustomerID: string;
}

export interface SubscriptionWithStripeData extends SubscriptionStripeData, SubscriptionPlanWithStripeData {
    ownerID: string;
}

export function isSubscriptionWithStripeData(x: unknown): x is SubscriptionWithStripeData {
    return (
        hasOwnProperty(x, "stripeSubscriptionID") &&
        typeof x.stripeSubscriptionID === "string" &&
        hasOwnProperty(x, "stripeCustomerID") &&
        typeof x.stripeCustomerID === "string" &&
        hasOwnProperty(x, "ownerID") &&
        typeof x.ownerID === "string" &&
        isSubscriptionPlanWithStripeData(x)
    );
}

export function defaultSubscriptionForOwner(ownerID: string, period: BillingPeriod = "monthly"): Subscription {
    return {
        ownerID,
        subscriptionStatus: "",
        plan: PlanKind.Free,
        planIteration: 0,
        period,
        addons: [],
    };
}

function removeAddonStripeData(addon: SubscriptionAddonWithStripeData): SubscriptionAddon {
    return {
        appID: addon.appID ?? undefined,
        kind: addon.kind,
        count: addon.count,
        period: addon.period ?? undefined,
    };
}

export function removeSubscriptionStripeData(sub: SubscriptionWithStripeData): Subscription {
    return {
        ownerID: sub.ownerID,
        subscriptionStatus: sub.subscriptionStatus,
        plan: sub.plan,
        planIteration: sub.planIteration,
        period: sub.period,
        addons: sub.addons.map(removeAddonStripeData),
        stripePromoCode: sub.stripePromoCode ?? undefined,
        trialEnd: sub.trialEnd,
    };
}

// SubscriptionState is the global store's version of a Subscription. It's supposed
// to be a smaller version of the original object with only the properties we need in
// the frontend.

export interface SubscriptionState {
    readonly id: string;
    readonly status: string;
    readonly planKind: PlanKind;
    readonly trialEnd: Date | undefined;
    readonly billingPeriod: BillingPeriod;
}

// This is for bookkeeping, so that we can change prices in real-time without
// needing to deploy changes to our code. Price data is only necessary for one
// kind of operation: making changes to a Stripe subscription. It won't be used
// elsewhere.

interface PriceBase {
    stripePriceID: string;
    stripeProductID: string;
    currency: string;
    unitAmount: number;
    period: BillingPeriod;
    allowSelfService: boolean | undefined;
    // This number is a timestamp from Stripe, which is the number of seconds
    // since the start of Time (1 Jan 1970). It's different from a JavaScript
    // Date, which is milliseconds since the start of Time.
    created: number;
    active: boolean;
}

function isPriceBase(x: unknown): x is PriceBase {
    return (
        hasOwnProperty(x, "stripePriceID") &&
        typeof x.stripePriceID === "string" &&
        hasOwnProperty(x, "stripeProductID") &&
        typeof x.stripeProductID === "string" &&
        hasOwnProperty(x, "currency") &&
        typeof x.currency === "string" &&
        hasOwnProperty(x, "unitAmount") &&
        typeof x.unitAmount === "number" &&
        hasOwnProperty(x, "period") &&
        (x.period === "monthly" || x.period === "annual") &&
        hasOwnProperty(x, "created") &&
        typeof x.created === "number" &&
        hasOwnProperty(x, "allowSelfService") &&
        (x.allowSelfService === undefined || typeof x.allowSelfService === "boolean")
    );
}

interface PlanPrice extends PriceBase {
    planKind: PlanKind;
    planIteration: number;
}

export function isPlanPrice(x: unknown): x is PlanPrice {
    return (
        isPriceBase(x) &&
        hasOwnProperty(x, "planIteration") &&
        typeof x.planIteration === "number" &&
        hasOwnProperty(x, "planKind") &&
        typeof x.planKind === "string" &&
        isEnumValue(PlanKind, x.planKind)
    );
}

export interface AddonPrice extends PriceBase {
    addonKind: AddonKind;
}

export function isAddonPrice(x: unknown): x is AddonPrice {
    return (
        isPriceBase(x) &&
        hasOwnProperty(x, "addonKind") &&
        typeof x.addonKind === "string" &&
        isEnumValue(AddonKind, x.addonKind)
    );
}

export type Price = PlanPrice | AddonPrice;

export function isPrice(x: unknown): x is Price {
    // In the absence of a boolean XOR, inequality is the next best comparison.
    return isPlanPrice(x) !== isAddonPrice(x);
}
