import { Semaphore, logInfo, base64UrlDecode } from "@glide/support";
import { isLeft } from "fp-ts/lib/Either";
import * as t from "io-ts";

import type { AppLoginTokenContainer } from "../integration-types";
import { appLoginTokenContainerCodec } from "../integration-types";
import { replaceLocation } from "../support/browser-hacks";
import { lazyLoading } from "../support/lazy-loading";
import { oAuth2AuthErrorMessageKind } from "./auth-constants";
import { getFeatureSetting } from "../feature-settings";
// Typescript really doesn't like that we're not importing firebase here,
// but that's too bad, because if we tried to import firebase at a module level
// we'd balloon our chunk sizes.
// @ts-ignore
export async function importFirebase(): Promise<typeof firebase.default> {
    const p1 = lazyLoading("firebase-app", false, () => import("firebase/compat/app"));
    const p2 = lazyLoading("firebase-auth", false, () => import("firebase/compat/auth"));
    const [fb] = await Promise.all([p1, p2]);
    return fb.default;
}

const signInSemaphore = new Semaphore();

export async function waitForFirebaseSignIn(): Promise<void> {
    await signInSemaphore.wait();
}

export async function signInToFirebaseWithCustomToken(token: string): Promise<firebase.default.auth.UserCredential> {
    signInSemaphore.acquire();
    try {
        const fb = await importFirebase();
        const auth = fb.auth();
        const result = await auth.signInWithCustomToken(token);
        await auth.setPersistence(fb.auth.Auth.Persistence.NONE);
        return result;
    } finally {
        signInSemaphore.release();
    }
}

export async function hookFirebaseSignOutTransitionEvent(handler: () => void) {
    const fb = await importFirebase();
    const auth = fb.auth();
    let signedInBefore = false;
    // Note that this handler will fire immediately if we are signed in,
    // but defer until determinate (which could also be "not logged in").
    // Hence the weird logic here.
    //
    // We fire immediately on determinate login state. This could have
    // already happened before we are called. By "determinate" we mean
    // that we definitely know whether or not we are authenticated with
    // Firebase. This could mean that we have a User, or we could not
    // have a User. But we definitely know what we do or don't have.
    //
    // The expected authentication transition for any given app is
    // not signed in -> signed in. We might have missed that transition,
    // but we'll still be able to capture it if we're already signed in.
    // Once we're signed in, we need to fire the handler when we sign out.
    auth.onAuthStateChanged(u => {
        logInfo("Player Firebase auth state changed", u);
        if (u !== null && !signedInBefore) {
            signedInBefore = true;
            return;
        }

        if (u === null && signedInBefore) {
            handler();
        }
    });
}

export function convertOAuth2RedirectTokenToState(): string | undefined {
    const { oauth2RedirectToken } = window as any;
    if (oauth2RedirectToken === undefined) return undefined;

    return btoa(JSON.stringify({ source: window.location.href, redirectToken: oauth2RedirectToken }));
}

export function extractOAuth2AuthTokenFromCurrentURL(): string | undefined {
    // OAuth2 Auth tokens are sent in the hash fragment to avoid calling the `play` function again.
    // If we put these in the search parameters, as far as nginx and the browser know
    // we're making a request to something completely different, but if only the hash fragment has
    // changed then both the browser and nginx can read the `play` function response from
    // the cache again.
    // FIXME: This usage of the hash fragment is pretty fragile. We should standardize on something.
    // window.location.hash[0] === "#" if there's actually a hash, so we need to get rid of it.
    const { location } = window;
    const [hashKey, hashValue] = location.hash.substring(1).split("=").map(decodeURIComponent);
    const oauth2AuthToken = hashKey === "signInWithOauth2Token" ? hashValue : undefined;
    if (oauth2AuthToken !== undefined) {
        const hashlessURL = `${location.protocol}//${location.host}${location.pathname}`;
        replaceLocation(hashlessURL);
    }
    return oauth2AuthToken;
}

const oauth2AuthTokenMessageKind = "oauth2-auth-token";
const oAuth2AuthTokenMessageCodec = t.type({
    kind: t.literal(oauth2AuthTokenMessageKind),
    authToken: t.string,
});

type OAuth2AuthTokenMessage = t.TypeOf<typeof oAuth2AuthTokenMessageCodec>;

export function encodeOAuth2AuthTokenMessage(authToken: string): OAuth2AuthTokenMessage {
    return { kind: oauth2AuthTokenMessageKind, authToken };
}

export function decodeOAuth2AuthTokenMessage(o: object): OAuth2AuthTokenMessage | undefined {
    const decoded = oAuth2AuthTokenMessageCodec.decode(o);
    return isLeft(decoded) ? undefined : decoded.right;
}

const oAuth2AuthErrorMessageCodec = t.intersection([
    t.type({
        kind: t.literal(oAuth2AuthErrorMessageKind),
    }),
    t.partial({
        viaSSO: t.boolean,
    }),
]);

type OAuth2AuthErrorMessage = t.TypeOf<typeof oAuth2AuthErrorMessageCodec>;

// FIXME: Expose error conditions in a more granular format
export function encodeOAuth2AuthErrorMessage(): OAuth2AuthErrorMessage {
    return { kind: oAuth2AuthErrorMessageKind };
}

export function decodeOAuth2AuthErrorMessage(o: object): OAuth2AuthErrorMessage | undefined {
    const decoded = oAuth2AuthErrorMessageCodec.decode(o);
    return isLeft(decoded) ? undefined : decoded.right;
}

export function hasAppLoginTokenFromCurrentURL(): boolean {
    const { location } = window;
    const [hashKey] = location.hash.substring(1).split("=").map(decodeURIComponent);
    return hashKey === "appLoginToken";
}

function replaceURLWithHashless() {
    const { location } = window;

    const hashlessURL = `${location.protocol}//${location.host}${location.pathname}`;
    replaceLocation(hashlessURL);
}

export function extractAppLoginTokenFromCurrentURL(): AppLoginTokenContainer | undefined {
    const { location } = window;
    const [hashKey, encodedHashValue] = location.hash.substring(1).split("=").map(decodeURIComponent);
    if (hashKey !== "appLoginToken") return undefined;

    let decodedAppLoginToken = encodedHashValue;
    if (getFeatureSetting("useBase64EncodingForAppLoginToken")) {
        decodedAppLoginToken = base64UrlDecode(encodedHashValue);
    }

    replaceURLWithHashless();

    try {
        const tokenContainer = appLoginTokenContainerCodec.decode(JSON.parse(decodedAppLoginToken));
        if (isLeft(tokenContainer)) return undefined;
        return tokenContainer.right;
    } catch {
        return undefined;
    }
}

export function extractPrivateInviteLinkTokenFromCurrentURL(): string | undefined {
    const { location } = window;
    const [hashKey, hashValue] = location.hash.substring(1).split("=").map(decodeURIComponent);
    if (hashKey !== "t") return undefined;

    replaceURLWithHashless();

    return hashValue;
}
