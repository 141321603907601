function youTubeVideoIDFromLink(link: string): string | undefined {
    const match = link.match(
        /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^\/&\?]{10,12})/
    );
    return match?.[1];
}

export function youTubeThumbnailFromLink(link: string): string | undefined {
    const id = youTubeVideoIDFromLink(link);
    return id === undefined ? undefined : `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
}
