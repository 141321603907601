import type { Sound } from "./sound";

export class AudioPlayer {
    private sound: HTMLAudioElement;

    constructor(name: Sound) {
        this.sound = new Audio(`/media/sound/${name}.mp3`);
    }

    public play() {
        void this.sound.play();
    }
}
