import type { BasePrimitiveValue } from "@glide/data-types";
import type {
    LoadingValue,
    ResolvedGroundValue,
    Row,
    Table,
    RootPath,
    QueryFromRows,
    TableKeeper,
    MutableNamespace,
    Query,
    QueryPathGetter,
} from "@glide/computation-model-types";
import type { SimpleTableKeeper } from "@glide/computation-model";
import type { PaymentInformation } from "@glide/common-core/dist/js/Database";
import type { TableName, TableGlideType } from "@glide/type-schema";
import type {
    HydratedRowContext,
    WireActionRunner,
    WirePredicate,
    WireRowComponentHydrationBackend,
    WireScreen,
    HydratedScreenContext,
    ValueChangeFollowUp,
    WireComponentEditor,
    WireComponentFlags,
    WireHydrationFollowUp,
    NavigationPath,
    ParsedScreen,
    WireAction,
    WireModalSize,
    WireScreenPosition,
} from "@glide/wire";
import type { AppKind } from "@glide/location-common";
import type { DefaultMap } from "@glideapps/ts-necessities";
import type { SubscriptionHandler } from "./subscription";

export type ActionRunnerWithContext = [WireActionRunner, HydratedRowContext];

// This is a class instead of a type definition to aid in memory leak
// debugging efforts. It's significantly easier to pick ActionTokenMap
// out of a heap snapshot.
export class ActionTokenMap extends Map<string, ActionRunnerWithContext> {}

export interface OnChangeFollowUp {
    readonly rowScreenContext: HydratedRowContext | undefined;
    readonly screenPosition: WireScreenPosition | undefined;
    readonly modalSize: WireModalSize | undefined;

    readonly followUp: ValueChangeFollowUp;
}

export interface OnChangeData {
    readonly kind: "column";
    readonly source:
        | {
              readonly kind: "table";
              readonly tableName: TableName;
              // This is legacy, and only used by the Favorite component
              readonly primaryKeyColumnName: string | undefined;
          }
        | {
              readonly kind: "keeper";
              readonly keeper: TableKeeper;
          }
        | {
              readonly kind: "special-row";
          };
    readonly columnName: string;
    // Right now we're assuming that if the row is visible, the data has to be
    // written to the data store.  If it's invisible, it's only modified
    // locally.
    readonly row: Row;
    readonly followUp: OnChangeFollowUp | undefined;
}

type OnChangeDataTokenMap = Map<string, OnChangeData>;

export interface SubscriptionHandlerAndPath {
    readonly path: RootPath;
    readonly handler: SubscriptionHandler;
}

export interface EffectSubscription {
    readonly dependencies: readonly BasePrimitiveValue[];
    readonly unsubscribe: (() => void) | undefined;
}

// ##subscriptionNeeds:
// If anything is added here, it needs to be also be added where we check for
// changes.
export interface SubscriptionNeeds {
    readonly parsedPath: boolean;
    readonly shuffleOrder: boolean;
    readonly online: boolean;

    // If this is true in a change, we'll always rehydrate.
    readonly override: boolean;
}

export interface SubscriptionInfo {
    // This must be `false` unless it's a `ComponentState`.
    readonly isComponentState: boolean;

    readonly handlerAndPath: SubscriptionHandlerAndPath | undefined;
    readonly tokens: ReadonlySet<string>;
    readonly needs: SubscriptionNeeds;
    readonly effect: EffectSubscription | undefined;
    readonly subsidiaries: readonly SubscriptionInfo[];
}

// name -> [value, change token, should save]
export type StateValuesMap = Map<string, [unknown, string, boolean]>;

export interface BuilderTableData {
    readonly tableType: TableGlideType;
    readonly table: Table;
}

export interface ComponentState extends SubscriptionInfo, WireComponentFlags {
    readonly isComponentState: true;

    readonly stateValues: StateValuesMap;
    readonly stateSaveKey: string | undefined;
    stateChanged: boolean;

    // Only used in apps
    readonly canBeSearched: boolean;

    readonly editor: WireComponentEditor | undefined;

    readonly subComponentStates: Record<string, [HydratedScreenContext | undefined, readonly ComponentState[]]>;

    readonly additionalInputs: readonly unknown[];

    readonly builderTableData: Record<string, BuilderTableData> | undefined;

    readonly subsidiaryScreen: WireScreen | undefined;
}

export function isComponentState(si: SubscriptionInfo): si is ComponentState {
    return si.isComponentState;
}

export interface ScreenBuilderTableData {
    // Can be undefined if the data is not loaded yet, for example.
    readonly screen: BuilderTableData | undefined;
    // component ID -> table data
    readonly components: Record<string, BuilderTableData>;
}

export interface HydratedScreen {
    readonly wireScreen: WireScreen;

    readonly screenContext: HydratedScreenContext | LoadingValue | undefined;
    readonly screenContextSubscriptionInfo: SubscriptionInfo | undefined;

    // This is the data to be displayed in the data pad/peek-a-boo in the
    // builder. In the player this will be `undefined`.
    readonly builderTableData: ScreenBuilderTableData | undefined;

    // These two are kept in sync with the components and special buttons on
    // the screen.
    readonly componentStates: readonly ComponentState[];
    readonly specialComponentStates: readonly ComponentState[];

    readonly titleSubscriptionInfo: SubscriptionInfo;

    readonly hasSearchableComponent: boolean;

    readonly actions: ActionTokenMap;
    readonly onChangeData: OnChangeDataTokenMap;

    // ##firstListItemActionToRun:
    // This is only used in the App player, where in Tablet mode if we're in
    // master/detail and there's no detail screen, we run the first list
    // item's action in the master if it's a non-modal push action.
    readonly firstListItemActionToRun?: WireAction;
}

export interface InternalScreenContext {
    readonly inputRowIDs: readonly string[];
}

export interface InternalScreen {
    readonly screenName: string;
    readonly defaultTitle: string | undefined;
    readonly titleOverride: string | undefined;
    readonly isInModal: boolean;
    // The ##tabIconOfScreen
    readonly tabIcon: string;
    // Apps on phone, every screen but the root screen needs a back
    // action.  On tablet, the top "detail" screen doesn't need a back action,
    // In pages, modals would need this if they're nested.
    readonly needsBackAction: boolean;
    readonly context: InternalScreenContext;
    readonly hydratedScreen: HydratedScreen | undefined;
}

export interface ValueProviderContext extends QueryPathGetter {
    readonly namespace: MutableNamespace<unknown> | undefined;

    fetchTableData(tableName: TableName): void;
    // FIXME: remove once we're committed to `queriesInComputationModel`
    resolveQuery(query: Query): ResolvedGroundValue | LoadingValue;
    resolveQueryFromRows(query: QueryFromRows, sources: MutableSubscriptionSources): Table | undefined;

    // We clear out all hydration backends because it's not hard to keep a
    // reference to them around, which can lead to dragging behind a growing
    // object graph.
    // https://github.com/quicktype/glide/issues/16120
    registerObjectToRetire(obj: any): void;
}

export interface ValueProviderActionContext extends ValueProviderContext {
    readonly actions: ActionTokenMap;
    readonly parsedPath: NavigationPath;
    readonly isOnline: boolean;
    // After we've subscribed to these paths, we also have to `get` them, in
    // case there is any outstanding dirt.  But we have to do it after adding
    // all the subscriptions, because getting one path can get another, and
    // we can't control the order.
    readonly pathsSubscribedTo: RootPath[];
    retireSubscriptionInfo: (si: SubscriptionInfo) => void;

    // FIXME: remove once we're committed to `queriesInComputationModel`
    addQueryChangedCallback(callback: () => void): void;
    removeQueryChangedCallback(callback: () => void): void;
}

export interface ActionHydrationContext extends ValueProviderActionContext {
    readonly tabScreenVisibilityPredicates: ReadonlyMap<string, WirePredicate>;
    readonly verifiedEmailAddressPath: RootPath | undefined;
}

export interface ScreenHydrationContext extends ActionHydrationContext {
    readonly appKind: AppKind;
    readonly internalScreen: InternalScreen;
    readonly onChangeValueRows: OnChangeDataTokenMap;
    readonly position: WireScreenPosition;
    readonly size: WireModalSize | undefined;
    readonly screenKey: string;
    readonly needsChanged: SubscriptionNeeds;
    readonly screenStateKeeper: [SimpleTableKeeper, RootPath] | undefined;
    readonly shuffleOrder: DefaultMap<string, number>;

    getScreenTitle(screen: ParsedScreen, hb: WireRowComponentHydrationBackend): string | null | undefined;
    getPaymentInformationForBuyButton(buttonID: string): PaymentInformation | undefined;
    followUpWith(followUp: WireHydrationFollowUp): void;
    getBaseRootPathForTable(tableName: TableName): RootPath | undefined;
    retireSubscriptionInfo: (si: SubscriptionInfo) => void;
}

export interface SubscriptionSources {
    // A specific set of column names means only trigger when one of those
    // columns changes.  `true` means any change triggers.  When listening to
    // a table to get updates when the table gets loaded and/or rows are
    // added/removed, this should just be the `nativeTableRowIDColumnName`.
    // When listening to some global value that's not a full table, it should
    // be `true`.
    // https://github.com/quicktype/glide/issues/17436
    readonly globalKeys: ReadonlyMap<string, ReadonlySet<string> | true>;

    // Subscriptions to specific rows
    // root key -> row ID -> column names
    readonly columnsInRows: ReadonlyMap<string, ReadonlyMap<string, ReadonlySet<string>>>;

    // Subscriptions to all rows
    // root key -> column names
    readonly columnsInAllDirectRows: ReadonlyMap<string, ReadonlySet<string>>;
    // If one of these gets dirtied, it dirties all rows.  An example of this
    // would be a filter that depends on a column in the user profile row.  If
    // the value in that column changes, the filter needs to be reevaluated
    // for all rows.
    readonly columnsInAllIndirectRows: ReadonlyMap<string, ReadonlySet<string>>;

    // I guess this doesn't belong here because it's not actually a
    // subscription?
    readonly needs: SubscriptionNeeds;
}

export interface MutableSubscriptionSources extends SubscriptionSources {
    readonly globalKeys: Map<string, Set<string> | true>;

    readonly columnsInRows: DefaultMap<string, DefaultMap<string, Set<string>>>;
    readonly columnsInAllDirectRows: DefaultMap<string, Set<string>>;
    readonly columnsInAllIndirectRows: DefaultMap<string, Set<string>>;

    needs: SubscriptionNeeds;
}

export interface InternalTab {
    readonly isVisible: boolean;
    readonly visibilityPredicate: WirePredicate;
    readonly visibilitySubscriptionInfo: SubscriptionInfo;
    // This is a copy of screen name in the tab description, for easier access
    readonly tabScreenName: string;
}
