import { type GlideFC, getGlideIcon } from "@glide/common";
import { getFirstElementFromArrayOrSingleElement } from "@glide/common-core/dist/js/components/component-helpers";
import { isCardStyle, type WireListCardCollection } from "@glide/fluent-components/dist/js/fluent-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { useResponsiveSizeClass, css } from "@glide/common-components";
import { isEmptyOrUndefinedish } from "@glide/support";
import {
    type UIAlignment,
    type UIImageStyle,
    type UISizeSimple,
    type WirePaging,
    type UILayoutVariant,
    CardStyle,
    UIAspect,
    UIBackgroundStyle,
    UIButtonAppearance,
    UIImageFill,
    UIOrientation,
    UISize,
    UIStyleVariant,
    ValueChangeSource,
} from "@glide/wire";
import { isBound } from "@glide/computation-model-types";
import { definedMap, proveNever } from "@glideapps/ts-necessities";
import chroma from "chroma-js";
import classNames from "classnames";
import * as React from "react";
import tw from "twin.macro";
import { WireSectionThemer, useIsFlat } from "../../utils/use-is-flat";
import { extractActions, runActionAndHandleURL } from "../../wire-lib";
import { WireButton } from "../wire-button/wire-button";
import { useSectionStyle } from "../wire-container/wire-container";
import {
    WireListContainer,
    useDynamicFilter,
    useMultipleDynamicFilters,
    useSearchBar,
} from "../wire-list-container/wire-list-container";
import type { WireRenderer } from "../wire-renderer";
import { Card, CardContainer } from "./card-collection-card";
import { Checklist, List } from "./card-collection-list";
import { TableHeader } from "./card-collection-table-header";
import { TableRow } from "./card-collection-table-row";
import type { TablePropertiesBindState } from "./card-collection-table-shared";
import type { ItemDescription } from "./card-collection-types";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

function rangeForPage(page: number, numPages: number, size: number): (number | null)[] {
    let start = page - size;
    let end = page + size;

    const upper = size * 2;

    if (start < 0) {
        end += -start;
        start = 0;
    }
    if (end >= numPages) {
        const d = end - (numPages - 1);
        start = Math.max(0, start - d);
        end = numPages - 1;
    }

    const result: (number | null)[] = [];
    for (let x = start; x <= end; x++) {
        result.push(x);
    }

    if (result[0] !== 0) {
        result[0] = 0;
        result[1] = null;
    }

    if (numPages > upper && result[upper] !== numPages - 1) {
        result[upper] = numPages - 1;
        result[upper - 1] = null;
    }

    return result;
}

interface Props<T> {
    readonly cardStyle: CardStyle;
    readonly pageItems: readonly T[];
    readonly page: number;
    readonly size: UISizeSimple;
    readonly headerTitle?: string;
    readonly headerSubtitle?: string;
    readonly headerEmphasis?: string;
    readonly imageStyle: UIImageStyle;
    readonly imageFill?: UIImageFill;
    readonly imageSize: UISize;
    readonly alignment: UIAlignment;
    readonly aspect: UIAspect;
    readonly styleVariant: UIStyleVariant;
    readonly numPages: number;
    readonly describeItem: (item: T, index: number) => ItemDescription;
    readonly onPageChange?: (page: number) => void;
    readonly orientation: UIOrientation;
    readonly onSeeAll: (() => void) | undefined;
    readonly showSeeAll?: boolean;
    readonly layoutVariant?: UILayoutVariant;
    readonly cardCollectionComponentStyle?: UIStyleVariant;
    readonly appID: string;
}

export function CardCollection<T>(p: Props<T>): React.ReactElement | null {
    const {
        describeItem,
        numPages,
        page,
        pageItems,
        onPageChange,
        cardStyle,
        imageFill = UIImageFill.Cover,
        size,
        aspect,
        imageStyle,
        imageSize,
        alignment,
        styleVariant,
        headerTitle = "",
        headerSubtitle = "",
        headerEmphasis = "",
        orientation,
        onSeeAll,
        showSeeAll,
        layoutVariant,
        cardCollectionComponentStyle,
        appID,
    } = p;
    const isFlat = useIsFlat();
    const sectionStyle = useSectionStyle();

    /**
     * FIXME:
     *
     * Don't do this `content` thing.
     * Make each style have a component, and pass the pager as prop.
     */
    let content: React.ReactNode;

    if (cardStyle === CardStyle.Table) {
        /**
         * If there are no items, we currently show nothing.
         * Once we show an empty state, we'll have no way of knowing which properties are bound.
         * In that case, we'll rely on the header properties.
         */
        const boundProperties: TablePropertiesBindState =
            pageItems.length === 0
                ? {
                      title: isEmptyOrUndefinedish(headerTitle),
                      subtitle: isEmptyOrUndefinedish(headerSubtitle),
                      emphasis: isEmptyOrUndefinedish(headerEmphasis),
                  }
                : {
                      // Is this reasonable? If it is, maybe we should have a style for only-image title.
                      title:
                          isBound(describeItem(pageItems[0], 0).title) || isBound(describeItem(pageItems[0], 0).image),
                      subtitle: isBound(describeItem(pageItems[0], 0).subtitle),
                      emphasis: isBound(describeItem(pageItems[0], 0).emphasis),
                  };

        const hasHeader = headerTitle !== "" || headerSubtitle !== "" || headerEmphasis !== "";
        content = (
            <div tw="self-stretch pt-1 pb-3">
                <div
                    tw="inline-block min-w-full w-full
                        gp-md:w-auto">
                    <WireSectionThemer
                        className={classNames(
                            styleVariant,
                            // Fix(mauri): Temporary workaround until we properly support dark theme.
                            sectionStyle,
                            isFlat && "flat"
                        )}
                        css={css`
                            &.${UIStyleVariant.Default} {
                                ${tw`rounded-lg ring-1 ring-border-base`}
                            }
                            &.${UIStyleVariant.Default}.flat {
                                ${tw`rounded-none ring-0`}
                            }

                            // Fix(mauri): Temporary workaround until we properly support dark theme
                            &.${UIStyleVariant.Default}.flat.dark {
                                ${tw`rounded-lg ring-1`}
                            }
                        `}
                        backgroundStyle={
                            // Fix(mauri): Temporary workaround until we properly support dark theme.
                            (sectionStyle === UIBackgroundStyle.Accent || sectionStyle === UIBackgroundStyle.Dark) &&
                            styleVariant === UIStyleVariant.Default
                                ? UIBackgroundStyle.Highlight
                                : sectionStyle
                        }
                        tw="min-w-full flex flex-col overflow-hidden
                            gp-md:[width:700px]">
                        {
                            // TODO: If `pageItems` is empty we should draw an empty state
                            hasHeader && (
                                <TableHeader
                                    boundProperties={boundProperties}
                                    title={headerTitle}
                                    subtitle={headerSubtitle}
                                    emphasis={headerEmphasis}
                                />
                            )
                        }
                        {pageItems.map((item, index) => {
                            const desc = describeItem(item, index);
                            const key = `${index}-${page}`;
                            return (
                                <TableRow
                                    boundProperties={boundProperties}
                                    key={key}
                                    desc={desc}
                                    imageStyle={imageStyle}
                                    styleVariant={styleVariant}
                                    hasHeader={hasHeader}
                                    testId={index}
                                    appID={appID}
                                />
                            );
                        })}
                    </WireSectionThemer>
                </div>
            </div>
        );
    } else if (isCardStyle(cardStyle)) {
        content = (
            <CardContainer orientation={orientation} size={size} onSeeAll={onSeeAll} showSeeAll={showSeeAll}>
                {pageItems.map((item, index) => {
                    const desc = describeItem(item, index);
                    const key = `${index}-${page}`;
                    const isUIStyleMinimal = cardCollectionComponentStyle === UIStyleVariant.Minimal;
                    return (
                        <Card
                            key={key}
                            desc={desc}
                            size={size}
                            imageFill={isUIStyleMinimal ? UIImageFill.Cover : imageFill}
                            imageStyle={imageStyle}
                            imageSize={imageSize}
                            alignment={alignment}
                            aspect={aspect}
                            viewType={isUIStyleMinimal ? "grid" : "card"}
                            orientation={orientation}
                            appID={appID}
                        />
                    );
                })}
            </CardContainer>
        );
    } else if (cardStyle === CardStyle.List) {
        const styles =
            styleVariant === UIStyleVariant.Minimal
                ? css`
                      ${tw`grid w-full grid-cols-1 -mt-2 gp-md:mt-0`}
                  `
                : css`
                      ${tw`grid w-full grid-cols-1 overflow-hidden rounded-xl ring-1 ring-border-base`}

                      &.flat {
                          ${tw`ring-0 rounded-none gap-0.5`}
                      }
                  `;

        content = (
            <WireSectionThemer
                // Fix(mauri): Temporary workaround until we properly support dark theme
                backgroundStyle={
                    (sectionStyle === UIBackgroundStyle.Accent || sectionStyle === UIBackgroundStyle.Dark) &&
                    styleVariant === UIStyleVariant.Default
                        ? UIBackgroundStyle.Highlight
                        : sectionStyle
                }
                css={styles}
                className={classNames(size, cardStyle, isFlat && "flat", sectionStyle)}>
                {pageItems.map((item, index) => {
                    const desc = describeItem(item, index);
                    const key = `${index}-${page}`;
                    return (
                        <List
                            key={key}
                            dataTestId={`collection-item-${index}`}
                            desc={desc}
                            imageStyle={imageStyle}
                            styleVariant={styleVariant}
                            layoutVariant={layoutVariant}
                            appID={appID}
                        />
                    );
                })}
            </WireSectionThemer>
        );
    } else {
        if (cardStyle !== CardStyle.Checklist) {
            proveNever(cardStyle, "Unknown card style", undefined);
        }

        content = (
            <WireSectionThemer
                backgroundStyle={sectionStyle}
                css={css`
                    ${tw`grid w-full grid-cols-1 -mt-2 gp-md:mt-0`}
                `}
                className={classNames(size, cardStyle, isFlat && "flat", sectionStyle)}>
                {pageItems.map((item, index) => {
                    const rawDesc = describeItem(item, index);
                    const desc: ItemDescription = {
                        title: rawDesc.title,
                        subtitle: rawDesc.subtitle,
                        body: rawDesc.body,
                        onCardClick: rawDesc.onCardClick,
                        menuItems: rawDesc.menuItems,
                        checked: rawDesc.checked,
                        onCheckedChange: rawDesc.onCheckedChange,
                        canCheck: rawDesc.canCheck,
                    };

                    const key = `${index}-${page}`;
                    return <Checklist key={key} desc={desc} />;
                })}
            </WireSectionThemer>
        );
    }

    return (
        <div tw="flex flex-col items-center">
            {content}
            {numPages > 1 && orientation !== UIOrientation.Horizontal && (
                <Pager numPages={numPages} page={page} onPageChange={onPageChange} />
            )}
        </div>
    );
}

interface PagerProps {
    readonly page: number;
    readonly numPages: number;
    readonly onPageChange?: (page: number) => void;
    readonly className?: string;
}

export const Pager: GlideFC<PagerProps> = p => {
    const { numPages, page, onPageChange } = p;

    const theme = useWireAppTheme();
    const sizeClass = useResponsiveSizeClass();

    const numItems = sizeClass === undefined ? 2 : 3;

    const selectedBackgroundColor = chroma(theme.textContextualAccent).alpha(0.1).css();
    const hoveredBackgroundColor = chroma(theme.textContextualBase).alpha(0.03).css();

    const goForwards = () => {
        onPageChange?.(Math.min(numPages - 1, page + 1));
    };

    const goBack = () => {
        onPageChange?.(Math.max(0, page - 1));
    };

    return (
        <div className={p.className} tw="flex mt-4 self-stretch justify-center">
            <PageButton
                css={css`
                    svg {
                        ${tw`transform rotate-90`}
                    }
                `}
                disabled={page === 0}
                onClick={goBack}>
                {getLocalizedString("previous", AppKind.Page)}
            </PageButton>
            <div
                tw="flex overflow-hidden mx-2
                    gp-lg:(mx-6)">
                {rangeForPage(page, numPages, numItems).map((x, ind) => (
                    <button
                        key={ind}
                        onClick={() => x !== null && onPageChange?.(x)}
                        style={{
                            backgroundColor: x === page ? selectedBackgroundColor : undefined,
                        }}
                        disabled={x === null || x === page}
                        tw="transition-colors text-text-contextual-dark rounded-lg disabled:(cursor-auto) mx-px"
                        className={x === page ? "selected-page-number" : undefined}
                        css={css`
                            &.selected-page-number {
                                ${tw`text-text-contextual-accent`}
                            }

                            &:hover:not(&:disabled),
                            &:focus-visible:not(&:disabled) {
                                background-color: ${hoveredBackgroundColor};
                            }
                        `}>
                        {/* For some reason `box-content` doesn't get applied because of a CSS reset overwriting it*/}
                        <div
                            tw="flex justify-center items-center h-6 p-1.5 tabular-nums [min-width:24px] box-content!
                                text-sm select-none">
                            {x === null ? "…" : x + 1}
                        </div>
                    </button>
                ))}
            </div>
            <PageButton
                css={css`
                    svg {
                        ${tw`transform -rotate-90`}
                    }
                `}
                disabled={page === numPages - 1}
                onClick={goForwards}>
                {getLocalizedString("next", AppKind.Page)}
            </PageButton>
        </div>
    );
};

interface PageButtonProps {
    disabled: boolean;
    onClick(): void;
    className?: string;
}

const PageButton: React.FC<React.PropsWithChildren<PageButtonProps>> = p => {
    const { onClick, disabled, children, className } = p;

    return (
        <WireButton
            className={className}
            appearance={UIButtonAppearance.Bordered}
            disabled={disabled}
            onClick={onClick}
            iconOnly
            iconName="path:/svg/stroke/st-caret.svg"
            tw="w-9
                gp-md:(w-16)">
            {children}
        </WireButton>
    );
};

type WireListCardCollectionRenderer = WireRenderer<WireListCardCollection, { isFirstComponent: boolean }>;

export const CardCollectionRenderer: WireListCardCollectionRenderer = React.memo(p => {
    const {
        backend,
        titleStyle,
        isFirstComponent,
        dynamicFilter,
        showSeeAll,
        multipleDynamicFilters,
        searchBar,
        layoutVariant,
    } = p;
    const pageIndexToken = p.paging?.pageIndex.onChangeToken;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const scrollToTheTop = () => {
        if (containerRef.current === null) {
            return;
        }

        containerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const filterArgs = useDynamicFilter(dynamicFilter, backend);

    const multipleFilterProps = useMultipleDynamicFilters(multipleDynamicFilters, backend);
    const toggleSeeAllAction = p.groups[0]?.toggleSeeAllAction;
    const hasOneGroup = p.groups.length === 1;
    const hasNoSearch = p.searchBar === undefined;
    const hasNoFilter = !filterArgs.hasFilter;
    const hasNoActions = p.titleActions === undefined || p.titleActions.length === 0;
    const hasSeeAllAction = toggleSeeAllAction !== undefined && showSeeAll;
    const showSeeAllInTitleActions = hasOneGroup && hasNoSearch && hasNoFilter && hasNoActions && hasSeeAllAction;
    const titleActions = showSeeAllInTitleActions
        ? extractActions(
              [
                  {
                      title: getLocalizedString("seeAll", AppKind.Page),
                      icon: getGlideIcon("st-chevron-right"),
                      iconPlacement: "right",
                      action: toggleSeeAllAction,
                      appearanceOverride: UIButtonAppearance.MinimalPrimary,
                  },
              ],
              backend
          )
        : extractActions(p.titleActions, backend);

    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, pageIndexToken);

    return (
        <WireListContainer
            ref={containerRef}
            title={p.componentTitle ?? ""}
            titleStyle={titleStyle}
            titleActions={titleActions}
            {...filterArgs}
            multipleFilterProps={multipleFilterProps}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            styleVariant={UIStyleVariant.Default}
            appKind={backend.appKind}
            isFirstComponent={isFirstComponent}>
            <TopGroupPaging paging={p.paging} backend={backend} />
            {p.groups.map((group, idx) => {
                const onGourpSeeAll = showSeeAllInTitleActions
                    ? undefined
                    : definedMap(
                          group?.toggleSeeAllAction,
                          action => () => runActionAndHandleURL(action, backend, true)
                      );
                return (
                    <div
                        key={idx}
                        css={css`
                            & + & {
                                ${tw`mt-6 page-md:mt-8`}
                            }
                        `}>
                        {!isEmptyOrUndefinedish(group.title) && (
                            <h3 tw="text-lg pb-3 font-semibold text-text-contextual-dark">{group.title}</h3>
                        )}

                        <CardCollection
                            pageItems={group.items ?? []}
                            size={p.size ?? UISize.Medium}
                            numPages={group.paging.numPages}
                            page={group.paging.pageIndex.value}
                            aspect={p.aspectRatio ?? UIAspect.SixteenByNine}
                            imageStyle={p.imageStyle}
                            imageSize={p.imageSize}
                            alignment={p.alignment}
                            imageFill={p.imageFill}
                            styleVariant={p.style ?? UIStyleVariant.Default}
                            cardCollectionComponentStyle={p.cardCollectionComponentStyle}
                            headerTitle={p.headerTitle ?? ""}
                            headerSubtitle={p.headerSubtitle ?? ""}
                            headerEmphasis={p.headerEmphasis ?? ""}
                            orientation={p.orientation}
                            onSeeAll={onGourpSeeAll}
                            showSeeAll={showSeeAll}
                            layoutVariant={layoutVariant}
                            appID={backend.appID}
                            describeItem={x => {
                                const { action, itemActions, emphasis, checked, image, ...rest } = x;

                                const onItemAction = definedMap(
                                    action?.token,
                                    _ => () => runActionAndHandleURL(action, backend)
                                );

                                const extractedActions = extractActions(itemActions, backend);

                                const checkedValue = checked?.value;
                                const onChangeToken = checked?.onChangeToken;
                                const onCheckedChange = definedMap(onChangeToken, t => {
                                    return (newValue: boolean) => {
                                        backend.valueChanged(t, newValue, ValueChangeSource.User);
                                    };
                                });

                                return {
                                    ...rest,
                                    image: getFirstElementFromArrayOrSingleElement(image),
                                    onCardClick: onItemAction,
                                    menuItems: extractedActions,
                                    emphasis: emphasis,
                                    checked: checkedValue,
                                    onCheckedChange,
                                    canCheck: onChangeToken !== undefined,
                                };
                            }}
                            cardStyle={p.cardStyle}
                            onPageChange={definedMap(group.paging.pageIndex.onChangeToken, t => pageIndex => {
                                backend.valueChanged(t, pageIndex, ValueChangeSource.User);
                            })}
                        />
                    </div>
                );
            })}
            <BottomGroupPaging paging={p.paging} backend={backend} scrollToTheTop={scrollToTheTop} />
        </WireListContainer>
    );
});

interface TopGroupPagingProps {
    paging: WirePaging | undefined;
    backend: WireBackendInterface;
}

const TopGroupPaging: React.VFC<TopGroupPagingProps> = p => {
    const { paging, backend } = p;

    if (paging === undefined) {
        return null;
    }

    const { numPages, pageIndex, pageSize } = paging;
    const { value: currentPage, onChangeToken } = pageIndex;

    if (numPages === 1 || currentPage === 0 || onChangeToken === undefined) {
        return null;
    }

    const onClick = () => {
        backend.valueChanged(onChangeToken, currentPage - 1, ValueChangeSource.User);
    };

    return (
        <WireButton onClick={onClick} tw="mb-6" appearance={UIButtonAppearance.Bordered}>
            Show {pageSize} previous groups
        </WireButton>
    );
};

interface BottomGroupPagingProps {
    paging: WirePaging | undefined;
    backend: WireBackendInterface;
    scrollToTheTop(): void;
}

const BottomGroupPaging: React.VFC<BottomGroupPagingProps> = p => {
    const { paging, backend, scrollToTheTop } = p;

    if (paging === undefined) {
        return null;
    }

    const { numPages, pageIndex, pageSize } = paging;
    const { value: currentPage, onChangeToken } = pageIndex;

    if (numPages <= 1 || currentPage === numPages - 1 || onChangeToken === undefined) {
        return null;
    }

    const onClick = () => {
        backend.valueChanged(onChangeToken, currentPage + 1, ValueChangeSource.User);
        scrollToTheTop();
    };

    return (
        <WireButton onClick={onClick} tw="mt-6" appearance={UIButtonAppearance.Bordered}>
            Show {pageSize} more groups
        </WireButton>
    );
};
