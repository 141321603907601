import { GlideIcon, useDebouncedValue } from "@glide/common";
import { formatLocalizedString, getLocalizedString } from "@glide/localization";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { ChoiceStyle, TextComponentStyle } from "@glide/component-utils";
import type { WireChoiceComponent, WireChoiceItem } from "@glide/fluent-components/dist/js/base-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { AppKind } from "@glide/location-common";
import { type WireAlwaysEditableValue, ValueChangeSource } from "@glide/wire";
import classNames from "classnames";
import * as React from "react";
import tw from "twin.macro";

import { Img } from "../../components/img/img";
import { PagesCheckbox } from "../../components/pages-checkbox";
import { css, isSmallScreen, useRootResponsiveSizeClass } from "@glide/common-components";
import { Text } from "../../components/text/text";
import { runActionAndHandleURL } from "../../wire-lib";
import { useSectionStyle } from "../wire-container/wire-container";
import { WireDropdown } from "../wire-dropdown/wire-dropdown";
import type { WireRenderer } from "../wire-renderer";
import chroma from "chroma-js";

export const WireChoice: WireRenderer<WireChoiceComponent> = React.memo(props => {
    const {
        title: componentTitle,
        items,
        hasImages,
        backend,
        isRequired,
        style,
        isMulti,
        maxChoices,
        pagesSearch,
        labelAndImageForChosenItems,
        onTap,
    } = props;
    // const currentIndex = items.findIndex(i => i.value === selectedValue.value);
    // Use selectedValue.onChangeToken to change the value.  If it's
    // `undefined` then the component should be disabled.

    let content: React.ReactNode | null = null;

    if (style === ChoiceStyle.Chips) {
        content = <ChipsChoice hasImages={hasImages} items={items ?? []} backend={backend} maxChoices={maxChoices} />;
    } else if (style === ChoiceStyle.RadioButtons) {
        if (isMulti) {
            content = (
                <CheckboxChoice hasImages={hasImages} items={items ?? []} backend={backend} maxChoices={maxChoices} />
            );
        } else {
            content = (
                <RadioChoice hasImages={hasImages} items={items ?? []} backend={backend} maxChoices={maxChoices} />
            );
        }
    } else {
        const onOpen = () => runActionAndHandleURL(onTap, backend);
        content = (
            <WireDropdown
                items={
                    items === undefined
                        ? undefined
                        : items.map(x => ({
                              ...x,
                              onChange: () => {
                                  runActionAndHandleURL(x.onChange, backend);
                              },
                          }))
                }
                hasImages={hasImages}
                labelAndImageForChosenItems={labelAndImageForChosenItems}
                isMulti={isMulti}
                maxChoices={maxChoices}
                onOpen={onOpen}
                limit={100}
                headerNode={<MaybeChoiceSearch pagesSearch={pagesSearch} backend={backend} />}
                appID={backend.appID}
            />
        );
    }

    return (
        <div tw="flex flex-col" role="group" aria-labelledby="choice-title">
            <div tw="mb-2 text-sm font-semibold flex justify-between items-baseline gp-md:text-base">
                <h3 id="choice-title" tw="shrink text-text-contextual-dark">
                    {componentTitle}
                </h3>
                {isRequired && (
                    <div tw="shrink-0 ml-2 text-xs text-text-contextual-pale font-normal">
                        {maxChoices !== undefined
                            ? formatLocalizedString("maxChoices", [maxChoices.toString()], backend.appKind)
                            : ""}
                        {getLocalizedString("required", backend.appKind)}
                    </div>
                )}
            </div>
            {content}
        </div>
    );
});

interface ChoiceProps {
    readonly items: readonly WireChoiceItem[];
    readonly backend: WireBackendInterface;
    readonly maxChoices: number | undefined;
    readonly hasImages: boolean;
}

const ChipsChoice: React.VFC<ChoiceProps> = p => {
    const { items, backend, maxChoices, hasImages } = p;
    const selectedOptions = items.filter(i => i.isSelected).length;
    const canSelectMore = maxChoices === undefined || selectedOptions < maxChoices;
    const sectionStyle = useSectionStyle();

    return (
        <ul
            tw="flex flex-wrap all-child:(mr-2 mb-2)"
            role="listbox"
            aria-multiselectable="true"
            aria-label={`Choose options, ${items.length} items available${
                maxChoices ? `, maximum ${maxChoices} selections` : ""
            }`}>
            {items.map((i, index) => (
                <li
                    key={`${index}`}
                    role="option"
                    aria-selected={i.isSelected}
                    aria-disabled={!i.isSelected && !canSelectMore}
                    aria-setsize={items.length}
                    aria-posinset={index + 1}
                    tabIndex={0}
                    onKeyDown={e => {
                        if (e.key === "Enter" || e.key === " ") {
                            e.preventDefault();
                            runActionAndHandleURL(i.onChange, backend);
                        }
                    }}
                    className={classNames(
                        i.isSelected && "selected",
                        !i.isSelected && !canSelectMore && "disabled",
                        hasImages ? "with-images" : "without-images",
                        `style-${sectionStyle}`
                    )}
                    css={css`
                        &.selected {
                            ${tw`text-text-contextual-accent border-text-contextual-accent`}
                            background: ${props => chroma(props.theme.primaryAccentColor).alpha(0.1).css()}
                        }
                        &:hover.selected {
                            background: ${props => chroma(props.theme.primaryAccentColor).alpha(0.2).css()};
                        }

                        &.disabled {
                            ${tw`opacity-50`}
                        }

                        &:not(.disabled) {
                            ${tw`cursor-pointer`}
                        }

                        &:focus {
                            outline: none;
                        }

                        &:focus-visible {
                            ${tw`ring-2 ring-accent ring-offset-2`}
                            outline: none;
                        }

                        &.style-accent-bg,
                        &.style-dark {
                            ${tw`border-w30A text-w80A hover:(bg-w10A border-w30A)`}
                        }
                        &.style-accent-bg.selected,
                        &.style-dark.selected {
                            ${tw`text-w100A border-w100A bg-w20A hover:(bg-w30A)`}
                        }

                        &.with-images {
                            ${tw`py-1.5 pl-1.5 pr-4`}
                        }

                        &.without-images {
                            ${tw`px-4 py-2`}
                        }
                    `}
                    tw="rounded-full font-medium text-sm border flex items-center transition border-n300 text-text-contextual-base hover:bg-n50A gp-md:text-base"
                    onClick={e => {
                        e.stopPropagation();
                        runActionAndHandleURL(i.onChange, backend);
                    }}>
                    {hasImages && (
                        <Img
                            tw="h-6 w-6 mr-2 rounded-full object-cover"
                            src={massageImageUrl(i.image, { thumbnail: true }, backend.appID)}
                            alternate={<ImageAlternate />}
                            altBehavior="both"
                            isPages={true}
                            alt={i.displayAs ?? undefined}
                        />
                    )}
                    {i.displayAs}
                </li>
            ))}
        </ul>
    );
};

const RadioChoiceItemContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
    <label tw="flex cursor-pointer gap-x-2.5 py-2 items-start page-md:w-fit">{children}</label>
);

const CheckboxChoice: React.FC<React.PropsWithChildren<ChoiceProps>> = p => {
    const { backend, items, maxChoices, hasImages } = p;
    const selectedOptions = items.filter(i => i.isSelected).length;
    const canSelectMore = maxChoices === undefined || selectedOptions < maxChoices;
    return (
        <div role="group" aria-label="Multiple choice options">
            {items.map((item, i) => {
                return (
                    <RadioChoiceItemContainer key={i}>
                        <PagesCheckbox
                            data-testid={`item-${i}`}
                            onChange={() => runActionAndHandleURL(item.onChange, backend)}
                            checked={item.isSelected}
                            disabled={!canSelectMore && !item.isSelected}
                            aria-label={`Select ${item.displayAs}`}
                        />
                        {hasImages && (
                            <Img
                                tw="h-6 w-6 rounded-full object-cover"
                                src={massageImageUrl(item.image, { thumbnail: true }, backend.appID)}
                                alternate={<ImageAlternate />}
                                altBehavior="both"
                                isPages={true}
                                alt={item.displayAs ?? undefined}
                            />
                        )}
                        <Text variant={TextComponentStyle.regular} tw="text-text-contextual-base">
                            {item.displayAs}
                        </Text>
                    </RadioChoiceItemContainer>
                );
            })}
        </div>
    );
};

const RadioChoice: React.FC<React.PropsWithChildren<ChoiceProps>> = p => {
    const { backend, items, maxChoices, hasImages } = p;
    const selectedOptions = items.filter(i => i.isSelected).length;
    const canSelectMore = maxChoices === undefined || selectedOptions < maxChoices;
    return (
        <div role="radiogroup" aria-label="Single choice options">
            {items.map((item, i) => {
                return (
                    <RadioChoiceItemContainer key={i}>
                        <input
                            data-testid={`item-${i}`}
                            css={css`
                                .style-accent-bg &,
                                .style-dark & {
                                    ${tw`border-border-dark ring-border-dark checked:after:bg-accent`}
                                }
                            `}
                            tw="relative flex items-center justify-center w-4 h-4 mt-[3px] shrink-0 border-solid border border-n400A ring-n400A rounded-full cursor-pointer
                            after:(content-['']  rounded-full w-1.5 h-1.5)
                            checked:(ring-text-contextual-accent bg-text-contextual-accent)
                            checked:after:bg-text-contextual-inverse active:ring-1 focus-visible:ring-1 page-hover:ring-1 transition-all"
                            onChange={() => runActionAndHandleURL(item.onChange, backend)}
                            checked={item.isSelected}
                            disabled={!canSelectMore && !item.isSelected}
                            type="radio"
                            aria-label={`Select ${item.displayAs}`}
                        />
                        {hasImages && (
                            <Img
                                tw="h-6 w-6 rounded-full object-cover"
                                src={massageImageUrl(item.image, { thumbnail: true }, backend.appID)}
                                alternate={<ImageAlternate />}
                                altBehavior="both"
                                isPages={true}
                                alt={item.displayAs ?? undefined}
                            />
                        )}
                        <Text variant={TextComponentStyle.regular} tw="text-text-contextual-base">
                            {item.displayAs}
                        </Text>
                    </RadioChoiceItemContainer>
                );
            })}
        </div>
    );
};

const ImageAlternate: React.VFC = () => {
    return <div tw="bg-n200A h-full w-full rounded-md" />;
};

interface MaybeChoiceSearchProps {
    readonly pagesSearch: WireAlwaysEditableValue<string> | undefined;
    readonly backend: WireBackendInterface;
}

const MaybeChoiceSearch: React.VFC<MaybeChoiceSearchProps> = p => {
    const { pagesSearch, backend } = p;

    if (pagesSearch === undefined) {
        return null;
    }

    return <ChoiceSearch backend={backend} pagesSearch={pagesSearch} />;
};

interface ChoiceSearchProps {
    readonly pagesSearch: WireAlwaysEditableValue<string>;
    readonly backend: WireBackendInterface;
}

const ChoiceSearch: React.VFC<ChoiceSearchProps> = p => {
    const { backend, pagesSearch } = p;
    const sizeClass = useRootResponsiveSizeClass();
    const isMobile = isSmallScreen(sizeClass);

    const [value, setValue] = React.useState(pagesSearch.displayValue ?? "");
    const debouncedValue = useDebouncedValue(value, 200);

    React.useEffect(() => {
        backend.valueChanged(pagesSearch.onChangeToken, debouncedValue, ValueChangeSource.User);
    }, [backend, debouncedValue, pagesSearch.onChangeToken]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    return (
        <label
            tw="rounded-lg bg-bg-front h-9 pl-2 pr-3 m-3 mb-0 border border-border-base text-icon-xpale flex
                items-center cursor-text transition hover:(border-border-dark text-icon-pale) focus-within:(ring-1
                ring-accent text-icon-pale border-transparent)">
            <GlideIcon iconSize={16} kind="stroke" icon="st-search" tw="mr-2 shrink-0" />
            <input
                tw="text-text-base leading-none [min-width:60px] w-full flex-shrink"
                placeholder={getLocalizedString("search", AppKind.Page)}
                value={value}
                onChange={onChange}
                autoFocus={!isMobile}
                role="searchbox"
                aria-label="Search through available options"
            />
        </label>
    );
};
