import { nullToUndefined } from ".";

const sessionTestSentinel = "glideSessionTest";
export function hasUsableSessionStorage(): boolean {
    try {
        // Trying to access sessionStorage is not as straightforward as it should be.
        // Simply trying to access the property can result in a thrown SecurityError
        // (which is exactly what the spec says it can do), hence the existence check
        // being guarded in a try/catch block.
        //
        // Then, just because you can access it doesn't mean you can use it.
        // Hence the attempt to actually put something in it.
        if (window.sessionStorage === undefined) return false;
        window.sessionStorage.setItem(sessionTestSentinel, "yes");
        return true;
    } catch {
        return false;
    }
}

function haveSessionStorage(): boolean {
    try {
        return sessionStorage !== null && sessionStorage !== undefined;
    } catch {
        return false;
    }
}

export function sessionStorageGetItem(key: string): string | undefined {
    if (!haveSessionStorage()) return undefined;

    try {
        return nullToUndefined(sessionStorage.getItem(key));
    } catch {
        return undefined;
    }
}

export function sessionStorageSetItem(key: string, value: string): void {
    if (!haveSessionStorage()) return;

    try {
        sessionStorage.setItem(key, value);
    } catch {
        // Well, we tried.
    }
}

export function sessionStorageRemoveItem(key: string): void {
    if (!haveSessionStorage()) return;

    try {
        sessionStorage.removeItem(key);
    } catch {
        // Well, we tried.
    }
}
