import { assert, defined, panic } from "@glideapps/ts-necessities";
import { digitsAndLetters } from ".";

// This is quite similar to, but not exactly the same as
// https://observablehq.com/@dgreensp/implementing-fractional-indexing
//
// Differences:
// * We never have trailing zeroes.
// * For midpoint, we always have a definite lower and upper bound.  If you
//   want the equivalent of an indefinite upper bound, just increment your
//   number.
// * We don't support decrementing yet, but we do support one hardcoded "minus
//   one", which is what we use as the lower bound for the case where we
//   insert before the first possible row index.

export abstract class FractionalIndexer {
    private readonly numberForDigit: Map<string, number>;

    protected readonly base: number;
    protected readonly middleDigitIndex: number;

    public abstract minusOne: string;
    public abstract zero: string;

    public readonly zeroDigit: string;

    constructor(private readonly allDigits: string) {
        this.base = allDigits.length;
        this.middleDigitIndex = Math.floor(this.base / 2);

        this.zeroDigit = allDigits[0];

        this.numberForDigit = new Map(allDigits.split("").map((s, i) => [s, i]));
    }

    private toNumber(stringDigit: string): number {
        assert(stringDigit.length === 1);
        return defined(this.numberForDigit.get(stringDigit));
    }

    private toNumberArray(n: string): number[] {
        assert(n.length > 0);
        const r: number[] = [];
        for (let i = 0; i < n.length; i++) {
            r.push(this.toNumber(n[i]));
        }
        return r;
    }

    private toString(arr: readonly number[]): string {
        let lastNonZeroIndex = arr.length - 1;
        while (lastNonZeroIndex >= 0 && arr[lastNonZeroIndex] === 0) {
            lastNonZeroIndex--;
        }
        assert(lastNonZeroIndex >= 0);
        return arr
            .slice(0, lastNonZeroIndex + 1)
            .map(d => defined(this.allDigits[d]))
            .join("");
    }

    protected abstract numberOfIntegerDigitsForFirstDigit(first: number): number;

    private numberOfIntegerDigits(r: readonly number[]): number {
        assert(r.length > 0);
        const first = r[0];
        return this.numberOfIntegerDigitsForFirstDigit(first);
    }

    // This mutates `arr`
    private setExactLength(arr: number[], len: number): void {
        while (arr.length < len) {
            arr.push(0);
        }
        arr.length = len;
    }

    private toIntegerPartNumberArray(n: string): number[] {
        const arr = this.toNumberArray(n);
        this.setExactLength(arr, this.numberOfIntegerDigits(arr));
        return arr;
    }

    public cleanup(mostlyNumber: string): string {
        const cleaned = mostlyNumber
            .split("")
            .filter(s => this.numberForDigit.has(s))
            .join("");
        if (cleaned.length > 0) {
            return cleaned;
        } else {
            return this.zero;
        }
    }

    public nextNumber(previousNumber: string): string {
        const arr = this.toIntegerPartNumberArray(previousNumber);
        const numDigits = arr.length;

        for (let i = numDigits - 1; i >= 0; i--) {
            const next = arr[i] + 1;
            assert(next <= this.base);
            if (next === this.base) {
                // The only way this can be violated is if leading digit is
                // the last digit in our repertoire, i.e. we've exhausted our
                // range of numbers, which should be practically impossible.
                assert(i > 0);
                arr[i] = 0;
                // we carry to the next most significant digit
            } else {
                arr[i] = next;
                return this.toString(arr);
            }
        }

        // It shouldn't be possible to get here even if the number range is
        // exhausted because the assertion above should have caught it.
        return panic("Number range exhausted");
    }

    // Precondition:
    //   low <= high
    // Postcondition:
    //   if low < high then
    //     low < midpoint < high
    //   else
    //     low === midpoint === high
    public midpoint(low: string, high: string): string {
        assert(low <= high);

        const lowArr = this.toNumberArray(low);
        const highArr = this.toNumberArray(high);
        const numDigits = Math.max(lowArr.length, highArr.length);
        this.setExactLength(lowArr, numDigits);
        this.setExactLength(highArr, numDigits);

        // Find common prefix
        let index = 0;
        while (index < numDigits && lowArr[index] === highArr[index]) {
            index++;
        }

        if (index === numDigits) {
            // All digits are the same, which means that `low` and `high`
            // represent the same number, so the midpoint is either one of them.
            return low;
        }

        // We will mutate `lowArr` to represent the midpoint.

        for (;;) {
            const lowDigit = lowArr[index];
            const highDigit = highArr[index];
            assert(lowDigit < highDigit);

            if (lowDigit + 1 < highDigit) {
                // There's at least one digit between the two, so we can pick the
                // digit that's in the middle.
                const middleDigit = Math.round((lowDigit + highDigit) / 2);
                assert(lowDigit < middleDigit && middleDigit < highDigit);
                lowArr[index] = middleDigit;
                // There might be more digits to `lowArr`, but we don't want them.
                lowArr.length = index + 1;
                break;
            } else {
                // `lowDigit` and `highDigit` are adjacent, i.e. there's no digit
                // between them, like `5` and `6`.
                if (index + 1 >= numDigits) {
                    // We are at the last digit, which means that the only way to
                    // get between `low` and `high` is to add another digit.
                    lowArr.push(this.middleDigitIndex);
                    break;
                } else {
                    // There are more digits left.  We might have, for example:
                    //
                    //   357
                    //   362
                    //    ^
                    //
                    // For simplicy's sake we truncate `high` after this digit and
                    // find the midpoint from there on.  In this example, we would
                    // find the midpoint between `357` and `360`.  The only
                    // problem is that we can't skip to the next digit because the
                    // digit in `high` would be `0`, which is the lowest digit,
                    // but we're assuming `high` is always higher.  So what we do
                    // instead is use a digit that's one higher than the highest
                    // digit we have. For the sake of this example say that's `A`,
                    // coming after `9`. We transform `high` to `35A` and then we
                    // continue after this digit, which guarantees that we'll find
                    // a midpoint.

                    // Technically we don't need to do this, since we're
                    // continuing at `index+1` anyway.
                    highArr[index] = lowArr[index];
                    index++;
                    // `base` is one more than the highest digit.
                    highArr[index] = this.base;
                    // Fill the rest with zeroes if necessary.
                    highArr.length = index + 1;
                    this.setExactLength(highArr, numDigits);
                }
            }
        }

        return this.toString(lowArr);
    }

    public fromNonNegativeInteger(n: number): string {
        // Just to be safe
        n = Math.floor(n);
        assert(n >= 0);

        let firstDigit = this.middleDigitIndex;
        let numRestDigits = 0;

        for (;;) {
            // The number of integer numbers that start with `firstDigit`.
            const numNumbers = Math.pow(this.base, numRestDigits);

            if (n < numNumbers) {
                const digits: number[] = [];
                for (let i = 0; i < numRestDigits; i++) {
                    digits.unshift(n % this.base);
                    n = Math.floor(n / this.base);
                }
                digits.unshift(firstDigit);
                return this.toString(digits);
            }

            n -= numNumbers;

            firstDigit++;
            // We can't ever encounter an integer this big
            assert(firstDigit < this.base);
            numRestDigits++;
        }
    }
}

// NOTE: This is not used anywhere outside this module, but it needs to be
// exported or Webpack will screw up the inheritance chain or something.
export class FixedFractionalIndexer extends FractionalIndexer {
    public readonly minusOne: string;
    public readonly zero: string;

    constructor(allDigits: string, private readonly integerLength: number) {
        super(allDigits);

        this.minusOne = allDigits[0];
        this.zero = allDigits[0] + allDigits[1];
    }

    protected numberOfIntegerDigitsForFirstDigit(): number {
        return this.integerLength;
    }
}

// NOTE: This is not used anywhere outside this module, but it needs to be
// exported or Webpack will screw up the inheritance chain or something.
export class LogarithmicFractionalIndexer extends FractionalIndexer {
    public readonly zero: string;
    public readonly minusOne: string;

    constructor(allDigits: string) {
        super(allDigits);

        this.minusOne = [allDigits[this.middleDigitIndex - 1], allDigits[this.base - 1]].join("");
        this.zero = allDigits[this.middleDigitIndex];
    }

    protected numberOfIntegerDigitsForFirstDigit(first: number): number {
        if (first < this.middleDigitIndex) {
            return this.middleDigitIndex - first + 1;
        } else {
            return first - this.middleDigitIndex + 1;
        }
    }
}

export const nativeTableIndexer = new LogarithmicFractionalIndexer(digitsAndLetters);

const tuple64Digits = "\t()-." + digitsAndLetters + "{";
export const tuple64Indexer = new FixedFractionalIndexer(tuple64Digits, 20);

// The tuple64 indexer is used for Glide tables connected to external data
// sources, but those can get disconnected, in which case we're left with
// regular Glide tables.  We won't rewrite their row indexes, so we have to
// work with the tuple64 ones.  This detects which indexer to use in that
// case.
function getIndexerForNumber(n: string): FractionalIndexer {
    const arr = Array.from(n);
    if (arr.every(c => digitsAndLetters.includes(c))) {
        return nativeTableIndexer;
    } else {
        assert(arr.every(c => tuple64Digits.includes(c)));
        return tuple64Indexer;
    }
}

export function nextNumber(n: string): string {
    const result = getIndexerForNumber(n).nextNumber(n);
    // logInfo("next number", n, result);
    return result;
}
