import { reloadBrowserWindow } from "./browser-reload";

export const standalone = (function () {
    try {
        return (
            (window.navigator as any).standalone === true ||
            (typeof window.matchMedia === "function" && window.matchMedia("(display-mode: standalone)").matches)
        );
    } catch {
        return false;
    }
})();

export function initRefreshOnStandalone() {
    if (typeof window.matchMedia !== "function") return;
    let wasFullScreen = window.matchMedia("(display-mode: fullscreen)").matches;
    const standaloneQuery = window.matchMedia("(display-mode: standalone)");
    // Safari mis-implements media query causing this to come back undefined. See MDN:
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
    standaloneQuery.addEventListener?.("change", () => {
        // This could be trigered if we're in standalone mode and we change to fullscreen mode.
        // In that case we don't want to reload the browser.
        // See: https://github.com/quicktype/glide/issues/21389
        const fullScreenQuery = window.matchMedia("(display-mode: fullscreen)");
        if (fullScreenQuery.matches) {
            wasFullScreen = true;
            return;
        }

        // This means we were on fullscreen mode and we're back on standalone mode
        // We don't want to reload here.
        if (wasFullScreen) {
            wasFullScreen = false;
            return;
        }

        // Because moving from PWA to browser should technically also disable persistence, I opted to make this action
        // always reload the window, so that Firestore can re-initialize properly in both cases.
        reloadBrowserWindow("Standalone mode was switched.  Reloading...");
    });
}
