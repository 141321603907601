/* eslint-disable indent */
import { coreTheme } from "./core-theme.js";
import * as deepmerge from "deepmerge";
import { lookupWithMutator, mergeTheme } from "./utils.js";
import { assertNever } from "@glideapps/ts-necessities";
import chroma from "chroma-js";
import type { BaseTheme } from "@glide/base-theme";

// We use this to determine which kind of theme you were provided at runtime
export const wireAppThemeKind = "wireAppTheme" as const;

const defaultWireAppTheme = {
    ...coreTheme,
    themeKind: wireAppThemeKind,
    overlayName: "defaultWireApp",
    n0: "#FFFFFF",
    n0A: "rgba(255, 255, 255, 0)",
    n50: "#FAFAFA",
    n50A: "rgba(51, 51, 51, 0.03)",
    n100: "#F5F5F5",
    n100A: "rgba(51, 51, 51, 0.05)",
    n200: "#EFEFEF",
    n200A: "rgba(51, 51, 51, 0.08)",
    n300: "#E0E0E0",
    n300A: "rgba(51, 51, 51, 0.15)",
    n400: "#ADADAD",
    n400A: "rgba(51, 51, 51, 0.40)",
    n500: "#8F8F8F",
    n500A: "rgba(51, 51, 51, 0.55)",
    n600: "#707070",
    n600A: "rgba(51, 51, 51, 0.70)",
    n700: "#4F4F4F",
    n700A: "rgba(0, 0, 0, 0.69)",
    n800: "#333333",
    n800A: "rgba(0, 0, 0, 0.80)",
    n900: "#0D0D0D",
    n900A: "rgba(0, 0, 0, 0.95)",
    w05A: "rgba(255,255,255,0.05)",
    w10A: "rgba(255,255,255,0.1)",
    w20A: "rgba(255,255,255,0.2)",
    w30A: "rgba(255,255,255,0.3)",
    w40A: "rgba(255,255,255,0.4)",
    w50A: "rgba(255,255,255,0.5)",
    w60A: "rgba(255,255,255,0.6)",
    w70A: "rgba(255,255,255,0.7)",
    w80A: "rgba(255,255,255,0.8)",
    w90A: "rgba(255,255,255,0.9)",
    w100A: "#FFF",
    b100: "#E0E9F3",
    b200: "#D6E2F4",
    b300: "#A0C2F9",
    b400: "#4F78FF",
    b500: "#1D4ED8",
    g100: "#E4F2E9",
    g200: "#CEEBDA",
    g300: "#79DBA2",
    g400: "#10B97A",
    g500: "#059669",
    r100: "#F8ECEC",
    r200: "#F2D9D8",
    r300: "#F3A9A5",
    r400: "#F0554C",
    r500: "#DC2C26",
    y100: "#FCF7E8",
    y200: "#F9ECCD",
    y300: "#F7D98B",
    y400: "#FBBF24",
    y500: "#E18F05",
    pink100: "rgba(212,33,180,0.1)",
    pink200: "rgba(212,33,180,0.15)",
    pink300: "rgba(212,33,180,0.5)",
    pink400: "#D421B4",
    pink500: "#AF008E",
    aqua: "#15ADC1",
    aqua100: "rgba(48, 224, 249, 0.1)",
    aqua200: "rgba(48, 224, 249, 0.2)",
    aqua300: "rgba(24, 190, 212, 0.5)",
    aqua400: "#15ADC1",
    aqua500: "#1398AA",
    purple100: "rgba(136, 126, 249, 0.1)",
    purple200: "rgba(136, 126, 249, 0.2)",
    purple300: "rgba(136, 126, 249, 0.5)",
    purple400: "#887EF9",
    purple500: "#6F64F0",
    brandGradient: "radial-gradient(100% 300% at 0% 100%, #64D851 0%, #16B7B2 50%, #3C5DFF 120%), #00C453",
    accent: "_primaryAccentColor",
    newBrand1: "#17B5CB",
    newBrand2: "#FF7A56",
    newBrand3: "#6C62E3",
    newBrand4: "#007D8E",
    newBrand5: "#FFB11B",
    newBrand6: "#7C8AA1",
    newBrand7: "#EE8BAE",
    newBrandGradient: "linear-gradient(90deg, #12CCE5 25.62%, #887EF9 74.07%);",
    OSstatusBar: "#fff",
    textDark: "_n900A",
    textBase: "_n900A",
    textPale: "_n600A",
    textXpale: "_n500A",
    textDisabled: "_n400A",
    textInverse: "_n0",
    textSuccess: "_g500",
    textWarning: "_y500",
    textDanger: "_r500",
    textAccent: "_primaryAccentColor",
    textContextualDark: "_n900A",
    textContextualBase: "_n900A",
    textContextualPale: "_n600A",
    textContextualXpale: "_n500A",
    textContextualDisabled: "_n400A",
    bgContextualDisabled: "_n50A",
    textContextualInverse: "_n0",
    textContextualSuccess: "_g500",
    textContextualWarning: "_y500",
    textContextualDanger: "_r500",
    textContextualAccent: "_primaryAccentColor",
    iconDark: "_n800A",
    iconBase: "_n600A",
    iconPale: "_n500A",
    iconXpale: "_n400A",
    iconDisabled: "_n300A",
    iconInverse: "_n0",
    iconSuccess: "_g400",
    iconWarning: "_y400",
    iconDanger: "_r400",
    bgFronter: "_n0",
    bgFront: "_n0",
    bgMiddle: "_n0",
    bgMiddleTransparent: "rgba(249, 249, 250, .82)",
    bgBack: "_n50",
    bgBehind: "_n100",
    bgContainerBase: "_n50",
    bgContainerHighlight: "_n0",
    bgHovered: "_n50A",
    borderDark: "_n300A",
    borderBase: "_n200A",
    borderPale: "_n100A",
    borderXpale: "_n50A",
    separatorColor: "rgba(0, 0, 0, 0.12)",
    shadowSoft0: "0 0 0 1px rgba(0, 0, 0, 0.08)",
    shadowSoft1: "0 1px 2.5px rgba(0, 0, 0, 0.1)",
    shadowSoft2: "0 6px 12px -3px rgba(0, 0, 0, 0.1)",
    shadowSoft3: "0 16px 20 -6px rgba(0, 0, 0, 0.1)",
    shadowSoft4: "0 28px 48px -8px rgba(0, 0, 0, 0.1)",
    shadowSoft5: "0 32px 64px -12px rgba(0, 0, 0, 0.12)",
    shadowSoft6: "0 48px 96px -10 rgba(0, 0, 0, 0.1)",
    shadow0: "0 0 0 1px rgba(0, 0, 0, 0.12)",
    shadow1: "0 0 0 0.5px rgba(0, 0, 0, 0.08), 0 1px 2.5px rgba(0, 0, 0, 0.1)",
    shadow2: "0 0 0 0.5px rgba(0, 0, 0, 0.08), 0 6px 12px -3px rgba(0, 0, 0, 0.14)",
    shadow3: "0 0 0 0.5px rgba(0, 0, 0, 0.08), 0 20px 40px -6px rgba(0, 0, 0, 0.16)",
    shadow4: "0 0 0 0.5px rgba(0, 0, 0, 0.08), 0 24px 48px -8px rgba(0, 0, 0, 0.18)",
    shadow5: "0 0 0 0.5px rgba(0, 0, 0, 0.08), 0 48px 64px -8px rgba(0, 0, 0, 0.2)",
    shadow6: "0 0 0 0.5px rgba(0, 0, 0, 0.08), 0 48px 96px -10px rgba(0, 0, 0, 0.36)",
    shadowDark0: "0 0 0 1px rgba(0, 0, 0, 0.18)",
    shadowDark1: "0 0 0 0.5px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.14)",
    shadowDark2: "0 0 0 0.5px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.06), 0 6px 12px -3px rgba(0, 0, 0, 0.1)",
    shadowDark3: "0 0 0 0.5px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.06), 0 20px 40px -6px rgba(0, 0, 0, 0.2)",
    shadowDark4: "0 0 0 0.5px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06), 0 28px 64px -8px rgba(0, 0, 0, 0.32)",
    shadowDark5: "0 0 0 0.5px rgba(0, 0, 0, 0.12), 0 6px 12px rgba(0, 0, 0, 0.06), 0 40 64px -10px rgba(0, 0, 0, 0.32)",
    shadowDark6:
        "0 0 0 0.5px rgba(0, 0, 0, 0.12), 0 32px 64px rgba(0, 0, 0, 0.08), 0 48px 96px -10px rgba(0, 0, 0, 0.36)",
    shadowXdark0: "0 0 0 1px rgba(0, 0, 0, 0.22)",
    shadowXdark1: "0 0 0 0.5px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.24)",
    shadowXdark2: "0 0 0 0.5px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.06), 0 4px 12px -2px rgba(0, 0, 0, 0.14)",
    shadowXdark3: "0 0 0 1px rgba(0, 0, 0, 0.14), 0 3px 6px rgba(0, 0, 0, 0.06), 0 20px 40px -6px rgba(0, 0, 0, 0.2)",
    shadowXdark4:
        "0 0 0 0.5px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.06), 0 28px 64px -8px rgba(0, 0, 0, 0.32)",
    shadowXdark5:
        "0 0 0 0.5px rgba(0, 0, 0, 0.16), 0 16px 24px -4px rgba(0, 0, 0, 0.16), 0 40 64px -4px rgba(0, 0, 0, 0.2)",
    shadowXdark6:
        "0 0 0 0.5px rgba(0, 0, 0, 0.16), 0 20 32px -4px rgba(0, 0, 0, 0.14), 0 48px 96px -6px rgba(0, 0, 0, 0.42)",
    shadowPanelsColor: "rgba(49, 49, 57, 0.05)",
    shadowPanelsColorDarker: "rgba(0, 0, 0, 0.08)",
    transitionBase: "0.125s ease",
    transitionSlow: "0.25s ease",
    transitionFast: "0.1s ease",
    onboardingSecondaryButtonBgColor: "_n0",
    onboardingLeftPanelBgColor: "_n0",
    fgColorOffBase: "_n600",
    fgColorLight: "_n600",
    fgColorMedium: "_n700",
    fgColorDark: "_n800",
    fgColorOpaque: "_n900",
    baseColor: "_n0",
    offBaseColor: "_n100",
    skeletonColor: "_n200",
    linkColor: "#106ba3",
    veilColor: "rgba(0, 0, 0, 0.2)",
    destroyColor: "_r500",
    primaryOverlayColor: "#FFFFFFF9",
    transparentOverlayColor: "#FFFFFFC9",
    accentForegroundColor: "_n0",
    builderWidth: 393,
    builderHeight: 852,
    navBar: {
        height: 44,
        heightWithSearch: 96,
        titleFontStyle: "_fontStyles.AccentCallout4Fixed",
        style: "iOS",
        fgColor: "_primaryAccentColor",
        titleColor: "_fgColorDark",
        bgColor: "_primaryOverlayColor",
        transparentBgColor: "_transparentOverlayColor",
        fadeTo: "white",
        cancelColor: "_fgColorDark",
        largeTitleHeight: 0,
        borderRadius: 0,
    },
    preferredRoundingRadius: 10,
    tileItem: {
        padding: 0,
        borderRadius: 6,
        selectedColor: "none",
    },
    button: {
        filledTextColor: "white",
        borderRadius: 10,
        height: 44,
        uppercase: false,
        shadow: "unset",
        fontStyle: "_fontStyles.Subheader4",
    },
    formSubmissionSuccessToast: {
        background: "rgba(230, 230, 230, 0.96)",
        translucentBackground: "rgba(230, 230, 230, 0.6)",
        checkmark: "black",
        labelColor: "#000000",
    },
    youtube: {
        defaultHeight: 300,
    },
    picker: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        hoverBgColor: "rgba(0, 0, 0, 0.075)",
        activeBgColor: "rgba(0, 0, 0, 0.12)",
        currentBgColor: "_baseColor",
        currentColor: "_primaryAccentColor",
    },
    wireappTest: {
        silly: "#00FFFF",
    },
    textField: {
        underlineColor: "rgba(0, 0, 0, 0.15)",
    },
    statusBar: {
        color: "#000",
        height: 54,
    },
    homeBar: {
        color: "#000",
        height: 34,
    },
    materialDateTimePicker: {
        borderRadius: "12",
        borderWidth: "0",
        borderColor: "transparent",
        borderStyle: "none",
        header: {
            background: "_baseColor",
            textDark: "_fgColorDark",
            textLight: "_fgColorLight",
        },
        tabs: {
            background: "_baseColor",
            iconColor: "_fgColorMedium",
            iconSelectedColor: "_primaryAccentColor",
        },
        cancelConfirm: {
            bg: "_primaryAccentColor",
            fg: "_accentForegroundColor",
        },
    },
};

function increaseLuminanceToPassContrast(accentColor: string) {
    const baseColor = darkWireAppTheme.n0;
    let currentLuminance = chroma(accentColor).luminance();
    let newAccentColor = chroma(accentColor).css();

    // At most retry 10 times
    for (let i = 0; i < 10; i++) {
        if (chroma.contrast(newAccentColor, baseColor) >= 4.5) {
            break;
        }

        currentLuminance += 0.1;
        newAccentColor = chroma(accentColor).set("hsl.l", currentLuminance).css();
    }

    return newAccentColor;
}

const darkWireAppTheme = {
    ...defaultWireAppTheme,
    overlayName: "defaultDarkWireApp",
    n0: "#0A0A0A",
    n0A: "rgba(255, 255, 255, 0)",
    n50: "#171717",
    n50A: "rgba(255, 255, 255, 0.03)",
    n100: "#1F1F1F",
    n100A: "rgba(255, 255, 255, 0.07)",
    n200: "#262626",
    n200A: "rgba(255, 255, 255, 0.1)",
    n300: "#333333",
    n300A: "rgba(255, 255, 255, 0.16)",
    n400: "#4F4F4F",
    n400A: "rgba(255, 255, 255, 0.28)",
    n500: "#707070",
    n500A: "rgba(255, 255, 255, 0.42)",
    n600: "#919191",
    n600A: "rgba(255, 255, 255, 0.56)",
    n700: "#B7B7B7",
    n700A: "rgba(255, 255, 255, 0.71)",
    n800: "#E6E6E6",
    n800A: "rgba(255, 255, 255, 0.9)",
    n900: "#FFFFFF",
    n900A: "rgba(255, 255, 255, 1)",
    b100: "#171E30",
    b200: "#293554",
    b300: "#4B6199",
    b400: "#729AFF",
    b500: "#92B1FF",
    g100: "#00291D",
    g200: "#003C2A",
    g300: "#007954",
    g400: "#00CB81",
    g500: "#68E7BA",
    r100: "#371210",
    r200: "#531B19",
    r300: "#98322E",
    r400: "#FD534D",
    r500: "#FF7674",
    y100: "#322607",
    y200: "#533F0D",
    y300: "#977316",
    y400: "#FBBF23",
    y500: "#FFD666",
    bgBehind: "_n100",
    bgBack: "_n0",
    bgMiddle: "_n50",
    bgFront: "_n100",
    bgContainerBase: "_n0",
    bgContainerHighlight: "_n50",
    borderBase: "_n100A",
    borderDark: "_n300A",
    borderPale: "_n50A",
    borderXpale: "_n50A",
    textContextualAccent: lookupWithMutator("_primaryAccentColor", (color: string) => {
        const adjustedColor = chroma(color).brighten(0.75).desaturate(0.01).css();
        return increaseLuminanceToPassContrast(adjustedColor);
    }),
};

export type WireAppTheme = typeof defaultWireAppTheme & BaseTheme;

type WireAppThemeOverlay = Partial<WireAppTheme> & {
    overlayName: string;
};

export const defaultWireAppAccentContextOverlay: WireAppThemeOverlay = {
    overlayName: "lightContext",
    textContextualAccent: "rgba(255, 255, 255, 1)",
    textContextualBase: "rgba(255, 255, 255, 0.9)",
    textContextualDark: "rgba(255, 255, 255, 1)",
    textContextualDisabled: "rgba(255, 255, 255, 0.6)",
    bgContextualDisabled: "rgba(255, 255, 255, 0.05)",
    bgContainerHighlight: "rgba(255, 255, 255, 0.05)",
    textContextualInverse: "_n900",
    textContextualPale: "rgba(255, 255, 255, 0.8)",
    textContextualXpale: "rgba(255, 255, 255, 0.7)",
    borderBase: "rgba(255, 255, 255, 0.15)",
    bgHovered: "rgba(255, 255, 255, 0.1)",
    borderDark: "_w50A",
};

const highlightBackgroundWireAppOverlay: WireAppThemeOverlay = {
    overlayName: "highlightBackground",
    bgContainerBase: "_bgMiddle",
    bgContainerHighlight: "_bgFront",
};

export const defaultWireAppImageBrightenContextOverlay: WireAppThemeOverlay = {
    overlayName: "imageBrightenContext",
    textContextualAccent: "_textAccent",
};

export const defaultWireAppImageDarkenContextOverlay: WireAppThemeOverlay = {
    overlayName: "imageDarkenContext",
    textContextualAccent: "_textAccent",
    textContextualBase: "rgba(255, 255, 255, 0.9)",
    textContextualDark: "rgba(255, 255, 255, 1)",
    textContextualDisabled: "rgba(255, 255, 255, 0.6)",
    bgContextualDisabled: "rgba(255, 255, 255, 0.05)",
    textContextualInverse: "_n900",
    textContextualPale: "rgba(255, 255, 255, 0.8)",
    textContextualXpale: "rgba(255, 255, 255, 0.7)",
    bgHovered: "rgba(206, 229, 253, 0.05)", // n50A dark.
    borderDark: "_w50A",
};

export const defaultWireAppAccentContextOverlayUndo: WireAppThemeOverlay = {
    overlayName: "lightContextUndo",
    textContextualAccent: "_textAccent",
    textContextualBase: "_textBase",
    textContextualDark: "_textDark",
    textContextualDisabled: "_textDisabled",
    bgContextualDisabled: "_n50A",
    bgContainerHighlight: "_bgFront",
    textContextualInverse: "_textInverse",
    textContextualPale: "_textPale",
    textContextualXpale: "_textXpale",
    borderBase: "_n200",
};

const someColors = ["#731358", "#D85722", "#D99E00", "#789436", "#2F6783", "#3B47C6", "#613DC7", "#4B5D67"];

function randomTheme(): BaseTheme {
    const randomColorIndex = Math.floor(Math.random() * someColors.length);
    const randomColor = someColors[randomColorIndex];

    return {
        primaryAccentColor: randomColor,
        showTabLabels: true,
        increaseContrast: false,
        themeOverlay: "none",
        showDesktopSideBar: false,
        showIconsInNavBar: true,
        showLabelsInTabBar: true,
        pageTheme: "Highlight",
        pageBackground: "Highlight",
    };
}

function isUsingDarkTheme(environmentTheme: BaseTheme["pageEnvironment"], isOSThemeDark: boolean) {
    if (environmentTheme === "Auto") {
        return isOSThemeDark;
    }

    return environmentTheme === "Dark";
}

function getDefaultTheme(
    environmentTheme: BaseTheme["pageEnvironment"],
    isOSThemeDark: boolean
): typeof defaultWireAppTheme {
    if (isUsingDarkTheme(environmentTheme, isOSThemeDark)) {
        return darkWireAppTheme;
    }

    return defaultWireAppTheme;
}

// FIXME: This function should be pure. Get rid of the `randomTheme` and `setStatusBarMetaTags`.
// Make maybeAppTheme mandatory, and export `getStatusBarColors` and `setStatusBarMetaTags` so consumers call it when appropriate.
export function getWireAppThemeForPlatform(maybeAppTheme?: BaseTheme, isOSThemeDark: boolean = false): WireAppTheme {
    const baseTheme = randomTheme();

    const appTheme = maybeAppTheme ?? baseTheme;
    const defaultTheme = getDefaultTheme(appTheme.pageEnvironment, isOSThemeDark);

    const overlays: WireAppThemeOverlay[] = [];
    if (appTheme.pageBackground === "Highlight") {
        overlays.push(highlightBackgroundWireAppOverlay);
    }

    const deepMergedThemes = deepmerge.all([baseTheme, appTheme, defaultTheme]) as WireAppTheme;
    const finalTheme = mergeTheme(deepMergedThemes, [...overlays]);

    const statusBarTheme = getStatusBarColors(finalTheme, isOSThemeDark);
    setStatusBarMetaTags(statusBarTheme);

    return finalTheme;
}

interface StatusBarTheme {
    readonly statusBar: string;
    readonly themeColor: string;
}

function getStatusBarColors(theme: WireAppTheme, isOSThemeDark: boolean): StatusBarTheme {
    const isDarkTheme = isUsingDarkTheme(theme.pageEnvironment, isOSThemeDark);

    switch (theme.pageTheme) {
        case "Highlight": {
            if (isDarkTheme) {
                return {
                    statusBar: "black-translucent",
                    themeColor: theme.n50,
                };
            } else {
                return {
                    statusBar: "white",
                    themeColor: "white",
                };
            }
        }

        case "Dark": {
            return {
                statusBar: "black-translucent",
                themeColor: theme.darkAccent,
            };
        }

        case undefined:
        case "Accent": {
            return {
                statusBar: "black-translucent",
                themeColor: theme.accent,
            };
        }

        default: {
            assertNever(theme.pageTheme, "Invalid page theme");
        }
    }
}

function setStatusBarMetaTags(statusBarTheme: StatusBarTheme): void {
    const statusBarMeta = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
    const themeColorMeta = document.querySelector('meta[name="theme-color"]');

    if (statusBarMeta === null) {
        // eslint-disable-next-line no-console
        console.warn("meta tag for apple-mobile-web-app-status-bar-style not found");
        return;
    }

    if (themeColorMeta === null) {
        // eslint-disable-next-line no-console
        console.warn("meta tag for theme-color not found");
        return;
    }

    const { statusBar, themeColor } = statusBarTheme;

    statusBarMeta.setAttribute("content", statusBar);
    themeColorMeta.setAttribute("content", themeColor);
}
