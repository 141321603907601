import { useChangeObservable } from "@glide/common";
import type { AppDescription, UserFeatures, BuilderActionsForApp } from "@glide/app-description";
import { getAppKind } from "@glide/common-core/dist/js/components/SerializedApp";
import type { WireBackendAppEnvironment } from "@glide/common-core/dist/js/components/types";
import type { PaymentInformationForBuyButtons } from "@glide/common-core/dist/js/Database";
import type { EminenceFlags } from "@glide/billing-types";
import { QuotaKind, areQuotaLimitsEqual } from "@glide/common-core/dist/js/Database/quotas";
import type { TypeSchema } from "@glide/type-schema";
import { getNetworkStatusChangeObservable } from "@glide/common-core/dist/js/hooks/use-network-status";
import type { WireFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import { makeSimpleAppDescriptionContext } from "@glide/generator/dist/js/components/simple-ccc";
import type { WireNavigationModel, SearchableColumns, WireBackendCallbacks } from "@glide/wire";
import { type WireBackend, makeWireBackend } from "@glide/hydrated-ui";
import { localStorageGetItem, localStorageSetItem } from "@glide/support";

import type ScreenConfigContext from "designer/builder/lib/screen-config-context";
import React from "react";

import { useCleanupMemo } from "./use-cleanup-memo";

interface WireBackendResult {
    readonly backend?: WireBackend;
    readonly urlStack: string[];
    readonly onBack: () => void;
}

interface BuilderProps {
    readonly screenConfigContext: ScreenConfigContext;
    readonly setPreviewAsUser: () => void;
    readonly addMenuScreen: (menuID: string, primaryKeyHash: string, defaultScreenName: string) => void;
}

export function useWireBackend(
    appDescription: AppDescription | undefined,
    appHost: string,
    fallbackAuthorName: string,
    formFactor: WireFormFactor,
    builderActions: BuilderActionsForApp | undefined,
    schema: TypeSchema | undefined,
    appEnvironment: WireBackendAppEnvironment | undefined,
    paymentInformation: PaymentInformationForBuyButtons,
    eminenceFlags: EminenceFlags,
    ownerFeatures: UserFeatures,
    builderProps: BuilderProps | undefined,
    precomputedSearchableColumns: SearchableColumns | undefined,
    startPath?: string
): WireBackendResult {
    const urlStack = React.useRef<string[]>(startPath === undefined ? [] : [startPath]);
    const { screenConfigContext, setPreviewAsUser, addMenuScreen } = builderProps ?? {};

    const backendRef = React.useRef<WireBackend>();
    const onBack = React.useCallback(() => {
        urlStack.current.pop();
        backendRef.current?.urlPathChanged(urlStack.current.slice(-1)[0] ?? "");
    }, []);
    const lastEminenceFlags = React.useRef(eminenceFlags);

    const formFactorRef = React.useRef(formFactor);
    formFactorRef.current = formFactor;

    const backend = useCleanupMemo(() => {
        const appKind = getAppKind(appDescription);
        if (appDescription === undefined || schema === undefined || appEnvironment === undefined) {
            return [undefined, () => undefined];
        }
        const { appID } = appEnvironment;
        const adc = makeSimpleAppDescriptionContext(
            appID,
            appKind,
            appDescription,
            builderActions,
            schema,
            ownerFeatures,
            eminenceFlags
        );
        let backendCallbacks: WireBackendCallbacks = {
            loadStateValues: key => {
                try {
                    const s = localStorageGetItem(`state-${key}`);
                    if (s === undefined) return {};
                    return JSON.parse(s);
                } catch {
                    return {};
                }
            },
            saveStateValues: (key, values) => localStorageSetItem(`state-${key}`, JSON.stringify(values)),
            setPreviewAsUser: () => setPreviewAsUser?.(),
            getPaymentInformationForBuyButton: id => paymentInformation[id],
            addMenuScreen: (menuID, primaryKeyHash, defaultScreenName) =>
                addMenuScreen?.(menuID, primaryKeyHash, defaultScreenName),
        };
        if (screenConfigContext !== undefined) {
            backendCallbacks = {
                ...backendCallbacks,
                editComponent: (screenName, componentID, editor, edit) =>
                    screenConfigContext?.editComponent(screenName, componentID, desc => editor(desc, edit)),
            };
        }

        const result = makeWireBackend(
            appEnvironment,
            adc,
            precomputedSearchableColumns,
            getNetworkStatusChangeObservable(),
            backendCallbacks,
            screenConfigContext !== undefined,
            appHost,
            fallbackAuthorName,
            formFactorRef.current,
            window.location.href,
            backendRef.current ?? urlStack.current.slice(-1)[0],
            screenConfigContext !== undefined ? "builder" : "player"
        );

        if (
            !areQuotaLimitsEqual(
                lastEminenceFlags.current.quotas[QuotaKind.RowsUsed],
                eminenceFlags.quotas[QuotaKind.RowsUsed]
            )
        ) {
            // Handle ##dataStoreRowQuotaChange
            appEnvironment.dataStore.resetFromUpstream();
        }

        lastEminenceFlags.current = eminenceFlags;

        return [result, () => setTimeout(() => result.retire(), 0)];
    }, [
        appDescription,
        schema,
        builderActions,
        ownerFeatures,
        eminenceFlags,
        screenConfigContext,
        appEnvironment,
        precomputedSearchableColumns,
        appHost,
        fallbackAuthorName,
        setPreviewAsUser,
        paymentInformation,
        addMenuScreen,
    ]);

    backendRef.current = backend;

    return React.useMemo(() => ({ backend, urlStack: urlStack.current, onBack }), [backend, urlStack, onBack]);
}

export const WireBackendContext = React.createContext<WireBackendResult>({ urlStack: [], onBack: () => undefined });

export function useWireBackendContext(): WireBackendResult {
    return React.useContext(WireBackendContext);
}

export function useWireNavigationModel(
    backend: WireBackend | undefined,
    urlStack: string[]
): WireNavigationModel | undefined {
    const navModel = useChangeObservable(backend?.wireNavigationModelObservable);

    const urlPath = navModel?.urlPath ?? "";
    React.useEffect(() => {
        if (urlPath !== urlStack.slice(-1)[0]) {
            urlStack.push(urlPath);
        }
    }, [urlPath, urlStack]);

    return navModel;
}

export const WireNavigationModelContext = React.createContext<WireNavigationModel | undefined>(undefined);

export function useWireNavigationModelContext(): WireNavigationModel | undefined {
    return React.useContext(WireNavigationModelContext);
}
