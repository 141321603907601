import type { MonotoneIcons, StrokeIcons, SerializablePluginMetadata } from "@glide/plugins";
import { AppIcon, GlideIcon } from "@glide/common";
import { getPlanInfoForPlanKind } from "@glide/common-core/dist/js/billing-vnext/org-plan-lib";
import { formatStripePrice } from "@glide/common-core/dist/js/billing-vnext/stripe-ui";
import { PlanKind } from "@glide/common-core/dist/js/billing-vnext/subscriptions";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { ComponentDescriptor } from "@glide/function-utils";

import { useMemo, useState } from "react";
import { css } from "styled-components";
import tw from "twin.macro";

import BaseModalV2 from "../base-modal/base-modal";
import { Button } from "../button/button";
import { isPluginArrType } from "../plugins-app-settings/plugin-config-from-usage";
import { PluginDescription } from "../plugins-app-settings/plugin-description";
import { SettingsSectionDividerFullWidth, SettingsSectionHeader } from "../plugins-app-settings/setting-section-header";

export type UpgradeFeatureType = "big-tables" | "big-query";

type ContextType = SerializablePluginMetadata[] | ComponentDescriptor | UpgradeFeatureType;

export function isUpgradeFeatureType(context: ContextType): context is UpgradeFeatureType {
    return context === "big-tables" || context === "big-query";
}

interface Item {
    icon: StrokeIcons;
    text: string;
}

interface PlanContent {
    description: React.ReactNode;
    items: Item[];
    imageUrl?: string;
    videoUrl?: string;
}

function getPlanContent(plan: PlanKind): PlanContent {
    switch (plan) {
        case PlanKind.Free:
        case PlanKind.TestPro:
        case PlanKind.V3NonProfit:
            return {
                description: "",
                items: [],
                imageUrl: "",
            };
        case PlanKind.V3Starter:
            return {
                description: (
                    <>
                        <p>Create an app for your idea, community, or school.</p>
                    </>
                ),
                items: [
                    { icon: "st-users", text: "Unlimited personal users" },
                    { icon: "st-sync", text: "Sync to Google Sheets" },
                    { icon: "st-design-brush", text: "Custom branding" },
                    { icon: "st-star-4", text: "Glide AI" },
                ],
            };
        case PlanKind.V3Pro:
            return {
                description: (
                    <>
                        <p>Turn spreadsheets into tools for your team.</p>
                    </>
                ),
                items: [
                    { icon: "st-users", text: "20 users included" },
                    { icon: "st-sync", text: "Sync to Airtable and Excel" },
                    { icon: "st-components", text: "Unlimited apps" },
                    { icon: "st-connect", text: "Glide Basic API" },
                ],
            };
        case PlanKind.V3Business:
            return {
                description: (
                    <>
                        <p>Turn spreadsheets into tools for your team.</p>
                    </>
                ),
                items: [
                    { icon: "st-users", text: "30 users included" },
                    { icon: "st-briefcase", text: "Users with business emails" },
                    { icon: "st-connect", text: "Glide API" },
                    { icon: "st-workflow", text: "Glide Workflows" },
                ],
            };

        case PlanKind.V3EnterpriseBaseNoUP:
        case PlanKind.Unlimited:
            return {
                description: (
                    <>
                        <p>Turn spreadsheets into tools for your team.</p>
                    </>
                ),
                items: [
                    { icon: "st-users", text: "High-scale use cases" },
                    { icon: "st-check-circle", text: "Custom terms" },
                    { icon: "st-check-task", text: "Service-level agreement" },
                    { icon: "st-message-square-chat", text: "Priority support" },
                ],
                imageUrl: "/images/queryables.png",
            };
        default:
            throw new Error(`Cannot get modal content for plan ${plan}`);
    }
}

interface ContextMedia {
    imageUrl?: string;
    videoUrl?: string;
}

const getContextMedia = (context: ContextType): ContextMedia => {
    if (context !== undefined) {
        if (context === "big-tables") {
            return {
                videoUrl: "https://res.cloudinary.com/glide/video/upload/v1684848901/growth/big-tables-video.mp4",
            };
        } else if (context === "big-query") {
            return {
                imageUrl: "https://res.cloudinary.com/glide/image/upload/v1684851831/growth/big-query.png",
            };
        } else if (isPluginArrType(context)) {
            // Add here metadata for plugins
            const { id } = context[0];
            switch (id) {
                case "glide-ai":
                    return {
                        videoUrl: "https://res.cloudinary.com/glide/video/upload/v1694097810/growth/glide-ai-video.mp4",
                    };
            }
        } else {
            // Add here metadata for components
            const { name } = context;
            switch (name) {
                case "Web Embed":
                    return {
                        imageUrl: "https://res.cloudinary.com/glide/image/upload/v1684851461/growth/web-embed.png",
                    };
                case "Scanner":
                    return {
                        imageUrl:
                            "https://res.cloudinary.com/glide/image/upload/v1684859162/growth/barcode-scanner.jpg",
                    };
            }
        }
    }
    return {};
};

const LONG_COMPONENT_DESCRIPTIONS: Record<string, string> = {
    "Web Embed": "Add the power of embedded web pages in your Glide apps.",
    Scanner: "Scan barcodes and QR codes with your Glide app.",
    "big-tables":
        "Scale your apps with a platform-grade database designed for high performance. Build apps with up to 10 million rows for your most demanding workflows.",
    "big-query": "Bring your BigQuery data into Glide and build more powerful apps at the scale you need.",
};

const ComponentDescription: React.FC<React.PropsWithChildren<{ component: ComponentDescriptor }>> = p => {
    const {
        component: { name, description, img },
    } = p;

    return (
        <div tw="text-text-base">
            <div tw="flex">
                <div
                    tw="w-16 h-16 shrink-0 mr-3 rounded-lg flex items-center justify-center"
                    css={css`
                        background-color: var(--gv-component8);
                    `}>
                    <GlideIcon icon={img as MonotoneIcons} kind="monotone" iconSize={48} fgColor="white" />
                </div>
                <div>
                    <div tw="flex items-center mb-1">
                        <h3 tw="mr-2 [font-size:18px] font-semibold">{name}</h3>
                    </div>
                    <p tw="text-text-base text-builder-base">{LONG_COMPONENT_DESCRIPTIONS[name] ?? description}</p>
                </div>
            </div>
        </div>
    );
};

const FeatureDescription: React.FC<React.PropsWithChildren<{ feature: UpgradeFeatureType }>> = p => {
    const { feature } = p;
    const name = feature === "big-tables" ? "Glide Big Tables" : "BigQuery";
    const docsURL = feature === "big-tables" ? getDocURL("bigTables") : getDocURL("bigQuery");
    return (
        <div tw="text-text-base">
            <div tw="flex items-center mb-1">
                <h3 tw="mr-2 [font-size:18px] font-semibold">{name}</h3>
            </div>
            <p tw="text-text-base text-builder-base">
                {LONG_COMPONENT_DESCRIPTIONS[feature]}
                {docsURL && (
                    <>
                        {" "}
                        <a
                            href={docsURL}
                            target="_blank"
                            rel="noreferrer"
                            tw="text-aqua500 font-medium hover:!underline">
                            Learn more
                        </a>
                    </>
                )}
            </p>
        </div>
    );
};

interface Props extends React.PropsWithChildren {
    context?: ContextType;
    requiredPlan: PlanKind;
    onShowPluginSettings: (pluginID: string) => void;
    onUpgrade: () => void;
    onShowAllPlans: () => void;
    onClose: () => void;
}

export const UpgradeModalContent: React.FC<React.PropsWithChildren<Props>> = p => {
    const { context, requiredPlan, onShowPluginSettings, onUpgrade, onShowAllPlans, onClose } = p;

    const { short, price, units } = getPlanInfoForPlanKind(requiredPlan);
    const planPrice = formatStripePrice(price * 100).split(".")[0];
    const shortUnit = units === "month" ? "mo" : units;
    const isPlugin = context !== undefined && isPluginArrType(context);
    const [modalHeight, setModalHeight] = useState<number | undefined>(undefined);

    const title = useMemo(() => {
        if (context === undefined) {
            if (requiredPlan === PlanKind.V3Business) {
                // don't show price for trials
                return `Go ${short}`;
            } else {
                return `Go ${short} for ${planPrice}/${shortUnit}`;
            }
        }
        if (context === "big-tables") {
            return "Unlock millions of rows of data";
        }
        if (context === "big-query") {
            return "Unlock the power of BigQuery";
        }
        if (isPluginArrType(context) && context.length > 1) {
            return "Upgrade to use all features";
        }
        return `Upgrade to ${isPlugin ? "use" : "add"} ${isPlugin ? context[0].name : context.name}`;
    }, [context, isPlugin, planPrice, short, shortUnit, requiredPlan]);

    const planContent = getPlanContent(requiredPlan);
    const { description, items, imageUrl: planImageUrl } = planContent;
    const { imageUrl: contextImageUrl, videoUrl } = context !== undefined ? getContextMedia(context) : planContent;
    const requireEnterprisePlan = [PlanKind.V3EnterpriseBaseNoUP, PlanKind.Unlimited].includes(requiredPlan);

    const imageUrl = contextImageUrl ?? planImageUrl;

    return (
        <BaseModalV2 tw="fixed inset-0" canClickOff={true} onClickOff={onClose} noPortal={true}>
            <div
                tw="rounded-xl overflow-hidden bg-bg-front text-text-base shadow-2xl relative flex items-stretch"
                ref={el => {
                    setModalHeight(el?.clientHeight);
                    return el;
                }}>
                <button
                    tw="absolute p-2 bg-n500A rounded-full top-4 right-5 text-white hover:bg-n600A h-10 z-10"
                    onClick={onClose}>
                    <AppIcon icon="close" size={24} />
                </button>
                <div tw="w-[556px] px-12 pt-8 pb-6 flex flex-col justify-center">
                    <div tw="font-weight[800] text-n900 line-height[120%] text-builder-3xl mb-6">{title}</div>
                    {context === undefined ? (
                        <div
                            tw="text-builder-lg -mb-3"
                            css={css`
                                p {
                                    ${tw`mb-4`}
                                }
                            `}>
                            {typeof description === "string" ? <p>{description}</p> : description}
                            <SettingsSectionHeader>Includes</SettingsSectionHeader>
                        </div>
                    ) : (
                        <>
                            {isUpgradeFeatureType(context) ? (
                                <FeatureDescription feature={context} />
                            ) : (
                                <div tw="pt-2">
                                    {isPlugin ? (
                                        context.length === 1 ? (
                                            <PluginDescription
                                                plugin={context[0]}
                                                requiredPlan={requiredPlan}
                                                size={"large"}
                                                onExpandFeatures={() => onShowPluginSettings(context[0].id)}
                                                extended={false}
                                                splitFeaturesInTwoColumns={true}
                                                hideDescription={true}
                                            />
                                        ) : (
                                            <div tw="text-builder-base">
                                                Some of the features used by this app required a{" "}
                                                <span tw="font-semibold">{short}</span> plan to run.
                                            </div>
                                        )
                                    ) : (
                                        <ComponentDescription component={context} />
                                    )}
                                </div>
                            )}
                            <SettingsSectionDividerFullWidth />
                            <div tw="font-size[18px] font-semibold">Unlock more with {short}</div>
                        </>
                    )}
                    <div tw="grid grid-cols-2 gap-2 mt-4">
                        {items.map((i, k) => (
                            <div tw="flex items-center" key={k}>
                                <div
                                    className={requireEnterprisePlan ? "enterprise" : "non-enterprise"}
                                    tw="p-1.5 object-cover rounded-full mr-2 [&.enterprise]:(bg-n200A text-text-pale) [&.non-enterprise]:(text-aqua500 bg-aqua100)">
                                    <GlideIcon kind="stroke" icon={i.icon} iconSize={20} strokeWidth={1.5} />
                                </div>
                                <div tw="text-builder-lg">{i.text}</div>
                            </div>
                        ))}
                    </div>
                    <div tw="flex flex-col space-y-3 mt-6">
                        {requireEnterprisePlan ? (
                            <Button
                                label={`Upgrade to ${short}`}
                                href="https://glideapps.com/enterprise/?utm_source=glide-upgrade&utm_medium=referral&utm_campaign=enterprise-upgrade"
                                variant="gradient"
                                size="lg"
                                isFullWidth={true}
                                buttonType="primary"
                            />
                        ) : (
                            <Button
                                label={`Upgrade to ${short}`}
                                onClick={onUpgrade}
                                variant="gradient"
                                size="lg"
                                isFullWidth={true}
                                buttonType="primary"
                            />
                        )}
                        <div tw="text-center text-builder-base">
                            {context === undefined ? (
                                <>
                                    Need more?{" "}
                                    <button tw="text-aqua500 font-medium hover:underline" onClick={onShowAllPlans}>
                                        View all plans
                                    </button>
                                </>
                            ) : (
                                <button tw="text-aqua500 font-medium hover:underline" onClick={onShowAllPlans}>
                                    Compare all plans
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {(imageUrl !== undefined || videoUrl !== undefined) && (
                    <div tw="relative bg-n100 hidden xl:block" style={{ width: modalHeight ?? 455 }}>
                        {videoUrl !== undefined ? (
                            <video
                                muted={true}
                                autoPlay={true}
                                loop={true}
                                poster={imageUrl}
                                tw="absolute w-full h-full object-cover">
                                <source src={videoUrl} type="video/mp4" />
                            </video>
                        ) : (
                            <img alt="feature demo" src={imageUrl} tw="absolute w-full h-full object-cover" />
                        )}
                    </div>
                )}
            </div>
        </BaseModalV2>
    );
};
