import type { DocumentData } from "@glide/common-core/dist/js/Database";
import { nativeTableRowIDColumnName } from "@glide/type-schema";
import { isGlideDateTimeDocumentData, GlideDateTime, isGlideJSONDocumentData } from "@glide/data-types";
import { type JSONObject, logError, areSetsEqual } from "@glide/support";
import { assert } from "@glideapps/ts-necessities";

export interface ExpectedAndActual {
    readonly expected: Map<string, DocumentData>;
    readonly actual: readonly JSONObject[];
    readonly ignoreColumnNames: readonly string[];
}

export function compare({ expected, actual, ignoreColumnNames }: ExpectedAndActual): boolean {
    if (expected.size !== actual.length) {
        logError("Expected length", expected.size, "doesn't match actual", actual.length);
        return false;
    }

    for (const fromActual of actual) {
        const rowID = fromActual[nativeTableRowIDColumnName];
        assert(typeof rowID === "string");
        const fromExpected = expected.get(rowID);
        if (fromExpected === undefined) {
            logError("Actual has row that's not expected:", rowID);
            return false;
        }
        const expectedRow = { ...fromExpected };
        const actualRow = { ...fromActual };
        for (const cn of ignoreColumnNames) {
            delete expectedRow[cn];
            delete actualRow[cn];
        }

        const expectedKeys = Object.keys(expectedRow);
        const actualKeys = Object.keys(actualRow);
        if (!areSetsEqual(actualKeys, expectedKeys)) {
            logError("Keys don't match for row", rowID);
            logError("actual", JSON.stringify(actualKeys));
            logError("expected", JSON.stringify(expectedKeys));
            return false;
        }

        for (const k of actualKeys) {
            const expectedValue = expectedRow[k];
            const actualValue = actualRow[k];
            let areEqual: boolean;
            if (isGlideDateTimeDocumentData(expectedValue)) {
                if (isGlideDateTimeDocumentData(actualValue)) {
                    const expectedDateTime = GlideDateTime.fromDocumentData(expectedValue);
                    const actualDateTime = GlideDateTime.fromDocumentData(actualValue);
                    areEqual = actualDateTime.compareTo(expectedDateTime) === 0;
                } else {
                    areEqual = false;
                }
            } else if (isGlideJSONDocumentData(expectedValue)) {
                if (isGlideJSONDocumentData(actualValue)) {
                    areEqual = actualValue.value === expectedValue.value;
                } else {
                    areEqual = false;
                }
            } else {
                areEqual = actualValue === expectedValue;
            }
            if (!areEqual) {
                logError("Value doesn't match for row", rowID, k);
                logError("actual", JSON.stringify(actualValue));
                logError("expected", JSON.stringify(expectedValue));
                return false;
            }
        }
    }
    return true;
}
