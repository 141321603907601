import { useWireAppTheme } from "./use-wireapp-theme";
import tw from "twin.macro";
import { useSectionStyle } from "../renderers/wire-container/wire-container";
import { UIBackgroundStyle } from "@glide/wire";

const styles = {
    // highlightWithBg is used when the background is either Accent or Dark
    highlightWithBg: tw`shadow-none border-w10A bg-w20A text-text-contextual-dark placeholder:text-w70A`,
    // when app background is Highlight
    highlight: tw`border-transparent bg-n200A placeholder:text-n500`,
    // when app background is Neutral
    neutral: tw`bg-bg-front border-border-dark`,
    // default is shared for every case
    default: tw`text-base border border-solid outline-none rounded-lg transition duration-75 page-hover:(ring-1 ring-border-dark border-border-dark shadow-none) focus:focus:focus:(ring-1 ring-text-contextual-accent border-text-contextual-accent) focus-visible:focus-visible:focus-visible:(ring-1 border-text-contextual-accent) disabled:(pointer-events-none opacity-40) data-[state=open]:(ring-1 ring-text-contextual-accent border-text-contextual-accent)`,
};

export function useFieldStyles() {
    const { pageBackground } = useWireAppTheme();
    const sectionStyle = useSectionStyle();

    let selectedStyle;

    const isHighlightBackground =
        (sectionStyle === UIBackgroundStyle.None || sectionStyle === UIBackgroundStyle.Card) &&
        pageBackground === "Highlight";
    const isDefinedBackground =
        sectionStyle === UIBackgroundStyle.Dark ||
        sectionStyle === UIBackgroundStyle.Accent ||
        sectionStyle === UIBackgroundStyle.Image;

    if (isHighlightBackground) {
        selectedStyle = styles.highlight;
    } else if (isDefinedBackground) {
        selectedStyle = styles.highlightWithBg;
    } else {
        selectedStyle = styles.neutral;
    }

    const style = { ...styles.default, ...selectedStyle };

    return style;
}
