import type { IconName } from "@glide/common";
import { Table } from "@glide/computation-model-types";
import { type TableName, type TableColumn, getTableName, makeTableRef, type SchemaInspector } from "@glide/type-schema";
import type {
    ArrayScreenFormat,
    ArrayContentDescription,
    ArrayScreenDescription,
    LegacyPropertyDescription,
    MutatingScreenKind,
    ScreenDescriptionKind,
} from "@glide/app-description";
import { ActionKind } from "@glide/app-description";
import { type InputOutputTables, type TitleDescription, ImageKind } from "@glide/common-core/dist/js/description";
import { GroupingSupport, ListItemFlags } from "@glide/component-utils";
import type {
    WireAppListGroup,
    WireAppListListComponent,
    WireImageFallback,
    WireListItem,
    WireListListItem,
} from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type PropertyDescriptor,
    type PropertyTableGetter,
    PropertySection,
    SwitchPropertyHandler,
    arrayScreenName,
} from "@glide/function-utils";
import {
    type InflatedColumn,
    type WireActionHydrator,
    type WireInflationBackend,
    type WireRowComponentHydrationBackend,
    type WireTableComponentHydrationBackend,
    type WireTableComponentHydratorConstructor,
    type WireAction,
    type WireEditableValue,
    PageScreenTarget,
    WireComponentKind,
    WireScreenPosition,
    WireActionResult,
} from "@glide/wire";
import { defined, mapFilterUndefined } from "@glideapps/ts-necessities";
import { handlerForActionKind } from "../actions";
import { getActionsForArrayContent } from "../components/component-utils";
import {
    makeSubtitlePropertyDescriptor,
    makeSummaryImageKindPropertyDescriptor,
    makeSummaryImagePropertyDescriptor,
    makeTitlePropertyDescriptor,
    useFallbackInitialsPropertyHandler,
} from "../components/descriptor-utils";
import { getSummarySearchProperties } from "../description-utils";
import type { ValueFormatSpecification } from "@glide/formula-specifications";
import {
    type WireStringGetter,
    getAppArrayScreenEmptyMessage,
    hydrateAndRegisterAction,
    inflateBuilderEditableImage,
    inflateStringProperty,
    makeGroups,
    makeSimpleWireTableComponentHydratorConstructor,
    registerActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { ArrayScreenHandlerBase } from "./array-screen";
import { pushHorizontalAndTruncatePropertyHandlers, truncateListPropertyHandler } from "./properties";

export interface SummaryArrayContentDescription extends TitleDescription, ArrayContentDescription {}

export interface SummaryArrayScreenDescription extends ArrayScreenDescription, SummaryArrayContentDescription {
    readonly kind: ScreenDescriptionKind.Array;

    readonly format: ArrayScreenFormat;
}

export function getNumberToTruncateTo(
    desc: SummaryArrayContentDescription,
    insideInlineList: boolean,
    roundTo: number,
    support: GroupingSupport
): number | undefined {
    let numToTruncate: number | undefined;
    if (insideInlineList || support !== GroupingSupport.None) {
        numToTruncate = truncateListPropertyHandler.getNumber(desc);
        if (numToTruncate === undefined && legacyTruncateListPropertyHandler.getSwitch(desc)) {
            numToTruncate = Math.round(Math.ceil(4 / roundTo) * roundTo);
        }
    }
    return numToTruncate;
}

const legacyTruncateListPropertyHandler = new SwitchPropertyHandler(
    { truncate: false },
    "Only show a few items",
    PropertySection.Options
);

function makeSummaryPropertyDescriptors(
    titleRequired: boolean,
    includeDescription: boolean,
    // if `imageRequired` is `undefined`, don't include an image
    imageRequired: boolean | undefined,
    imageKind: ImageKind | undefined,
    getPropertyTable: PropertyTableGetter | undefined,
    withEmoji: boolean,
    imageFirst: boolean,
    additionalDescriptorsBeforeImage: readonly PropertyDescriptor[] = []
): readonly PropertyDescriptor[] {
    const descrs: PropertyDescriptor[] = [
        makeTitlePropertyDescriptor(getPropertyTable, titleRequired, false, PropertySection.Content),
    ];

    if (includeDescription) {
        descrs.push(makeSubtitlePropertyDescriptor(getPropertyTable, PropertySection.Content));
    }
    if (imageFirst) {
        descrs.unshift(...additionalDescriptorsBeforeImage);
    } else {
        descrs.push(...additionalDescriptorsBeforeImage);
    }

    if (imageRequired !== undefined) {
        const imageDescriptor = makeSummaryImagePropertyDescriptor(
            getPropertyTable,
            imageRequired,
            false,
            withEmoji,
            PropertySection.Content
        );
        const imageKindDescriptor = makeSummaryImageKindPropertyDescriptor(imageKind);

        if (imageFirst) {
            descrs.unshift(imageDescriptor);
        } else {
            descrs.push(imageDescriptor);
        }

        descrs.push(imageKindDescriptor);
    }

    return descrs;
}

export abstract class SummaryArrayScreenHandler<
    TContentDesc extends SummaryArrayContentDescription,
    TScreenDesc extends SummaryArrayScreenDescription & TContentDesc
> extends ArrayScreenHandlerBase<TContentDesc, TScreenDesc> {
    protected abstract readonly supportsNonURLImages: boolean;
    protected abstract readonly supportsEmojiImages: boolean;
    protected abstract readonly supportsTruncateList: boolean;

    constructor(
        format: ArrayScreenFormat,
        label: string,
        icon: IconName,
        private readonly _imageRequired: boolean | undefined
    ) {
        super(format, label, icon);
    }

    protected get imagePropertyFirst(): boolean {
        return false;
    }

    public getBasicSearchProperties(desc: TContentDesc): readonly string[] {
        return getSummarySearchProperties(desc);
    }

    protected includeDescription(): boolean {
        return true;
    }

    protected titleRequired(): boolean {
        return true;
    }

    protected getInterSummaryPropertyDescriptors<T extends TContentDesc>(
        _getPropertyTable: PropertyTableGetter | undefined,
        _insideInlineList: boolean,
        _desc: T | undefined
    ): readonly PropertyDescriptor[] {
        return [];
    }

    public getContentPropertyDescriptors<T extends TContentDesc>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined
    ): readonly PropertyDescriptor[] {
        const result = [
            ...super.getContentPropertyDescriptors(
                getPropertyTable,
                insideInlineList,
                containingScreenTables,
                desc,
                ccc,
                mutatingScreenKind,
                isDefaultArrayScreen,
                withTransforms,
                forEasyTabConfiguration,
                isFirstComponent,
                undefined,
                undefined
            ),
            ...makeSummaryPropertyDescriptors(
                this.titleRequired(),
                this.includeDescription(),
                this._imageRequired,
                this.supportsNonURLImages ? undefined : ImageKind.URL,
                getPropertyTable,
                this.supportsEmojiImages,
                this.imagePropertyFirst,
                this.getInterSummaryPropertyDescriptors(getPropertyTable, insideInlineList, desc)
            ),
        ];

        if (this.supportsTruncateList) {
            pushHorizontalAndTruncatePropertyHandlers(result, desc, isDefaultArrayScreen, false);
        }

        return result;
    }

    public getScreensUsed(
        desc: TContentDesc,
        tables: InputOutputTables | undefined,
        schema: SchemaInspector,
        insideInlineList: boolean
    ): readonly string[] {
        if (
            tables !== undefined &&
            getNumberToTruncateTo(desc, insideInlineList, 1, this.groupingSupport) !== undefined
        ) {
            return [arrayScreenName(schema, makeTableRef(tables.input))];
        }

        return [];
    }
}

export interface FallbackInitialsSummaryArrayContentDescription extends SummaryArrayContentDescription {
    readonly useFallbackInitials?: LegacyPropertyDescription;
}
export interface FallbackInitialsSummaryArrayScreenDescription
    extends SummaryArrayScreenDescription,
        FallbackInitialsSummaryArrayContentDescription {}

export abstract class FallbackInitialsSummaryArrayScreenHandler<
    TContentDesc extends FallbackInitialsSummaryArrayContentDescription,
    TScreenDesc extends FallbackInitialsSummaryArrayScreenDescription & TContentDesc
> extends SummaryArrayScreenHandler<TContentDesc, TScreenDesc> {
    protected readonly fullTitleFallback: boolean = false;

    protected getAdditionalPropertyDescriptors<T extends TContentDesc>(
        _getPropertyTable: PropertyTableGetter | undefined,
        _insideInlineList: boolean,
        _desc: T | undefined,
        _isDefaultArrayScreen: boolean
    ): readonly PropertyDescriptor[] {
        return [];
    }

    protected getEnableFallbackInitials(_desc: TContentDesc | undefined): boolean {
        return true;
    }

    public getContentPropertyDescriptors<T extends TContentDesc>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined
    ): ReadonlyArray<PropertyDescriptor> {
        return [
            ...super.getContentPropertyDescriptors(
                getPropertyTable,
                insideInlineList,
                containingScreenTables,
                desc,
                ccc,
                mutatingScreenKind,
                isDefaultArrayScreen,
                withTransforms,
                forEasyTabConfiguration,
                isFirstComponent
            ),
            ...this.getAdditionalPropertyDescriptors(getPropertyTable, insideInlineList, desc, isDefaultArrayScreen),
            ...(this.getEnableFallbackInitials(desc) ? [useFallbackInitialsPropertyHandler] : []),
        ];
    }

    public makeIconAndFlagsForDefaultAction(
        desc: TContentDesc,
        flags: ListItemFlags,
        adc: AppDescriptionContext
    ): { icon: string | undefined; flags: ListItemFlags } {
        let icon: string | undefined;
        const action = getActionsForArrayContent<TContentDesc>(this, undefined, desc, adc).actions[0];
        if (action !== undefined) {
            const iconName = handlerForActionKind(action.kind).iconName;
            if (iconName === undefined) {
                flags |= ListItemFlags.DisableChevron;
            }
            if (action.kind !== ActionKind.PushDetailScreen && typeof iconName === "string") {
                icon = iconName;
            }
        }
        return { icon, flags };
    }
}

export function isolateFallbackInitialsSummaryArrayContentDescription(
    desc: FallbackInitialsSummaryArrayContentDescription
): FallbackInitialsSummaryArrayContentDescription {
    return {
        useFallbackInitials: desc.useFallbackInitials,
        reverse: desc.reverse,
        groupByColumn: desc.groupByColumn,
        actions: desc.actions,
        components: desc.components,
        filter: desc.filter,
        transforms: desc.transforms,
        titleProperty: desc.titleProperty,
        subtitleProperty: desc.subtitleProperty,
        imageKind: desc.imageKind,
        imageURLProperty: desc.imageURLProperty,
    };
}

interface SummaryGetters {
    readonly titleGetter: WireStringGetter;
    readonly subtitleGetter: WireStringGetter;
    readonly imageGetter: (hb: WireRowComponentHydrationBackend) => WireEditableValue<string> | null;
}

// FIXME: Support map and emoji image types
// FIXME: Support carousel
export function inflateSummary(ib: WireInflationBackend, desc: TitleDescription): SummaryGetters {
    const [titleGetter] = inflateStringProperty(ib, desc.titleProperty, true);
    const [subtitleGetter] = inflateStringProperty(ib, desc.subtitleProperty, true);
    const imageGetter = inflateBuilderEditableImage(ib, desc.imageURLProperty);

    return { titleGetter, subtitleGetter, imageGetter };
}

export function hydrateListItem(
    rhb: WireRowComponentHydrationBackend,
    { titleGetter, subtitleGetter }: SummaryGetters,
    actionHydrator: WireActionHydrator | WireActionResult
): WireListItem {
    const title = titleGetter(rhb);
    return {
        title,
        subtitle: subtitleGetter(rhb),
        action: hydrateAndRegisterAction("tap", actionHydrator, rhb, false, title ?? undefined),
    };
}

function hydrateListListItem(
    rhb: WireRowComponentHydrationBackend,
    summaryGetters: SummaryGetters,
    actionHydrator: WireActionHydrator | WireActionResult,
    icon: string | undefined,
    captionGetter: WireStringGetter | undefined
): WireListListItem {
    return {
        ...hydrateListItem(rhb, summaryGetters, actionHydrator),
        // It's important that the key is the row ID because we use it for the
        // ##selectedMasterItemKey.
        key: defined(rhb.rowContext?.inputRows[0].$rowID),
        image: summaryGetters.imageGetter(rhb),
        caption: captionGetter?.(rhb) ?? null,
        icon: icon ?? null,
    };
}

export interface GroupByGetters {
    readonly column: TableColumn;
    readonly valueGetter: InflatedColumn;
    // This can be the same as `valueGetter`, for the case where we don't have
    // a separate group title, but usually it's a getter for the value, but
    // with the column format.
    readonly titleGetter: InflatedColumn;
    readonly format: ValueFormatSpecification | undefined;
}

export function makeAppListGroups<T>(
    thb: WireTableComponentHydrationBackend,
    groupByGetters: GroupByGetters | undefined,
    numToTruncate: number | undefined,
    tableName: TableName,
    hydrateItem: (rhb: WireRowComponentHydrationBackend) => T | undefined
): readonly WireAppListGroup<T>[] | undefined {
    return makeGroups(thb, groupByGetters, tableName, (title, rows, _groupValue, i) => {
        let action: WireAction | undefined;
        let rowsTruncated = rows;
        if (numToTruncate !== undefined) {
            rowsTruncated = rowsTruncated.slice(0, numToTruncate);
        }
        const items = mapFilterUndefined(rowsTruncated, r => {
            const rhb = thb.makeHydrationBackendForRow(r);
            return hydrateItem(rhb);
        });
        if (rows.length > items.length) {
            action = registerActionRunner(thb, `onSeeAll-${i}`, async ab => {
                ab.pushDefaultArrayScreen(tableName, new Table(rows), title, PageScreenTarget.Current);
                return WireActionResult.nondescriptSuccess();
            });
        }
        return { title: title ?? "", items, seeAllAction: action };
    });
}

export function makeListListHydrator<T extends ArrayScreenFormat.List | ArrayScreenFormat.SmallList>(
    ib: WireInflationBackend,
    format: T,
    componentID: string | undefined,
    flags: ListItemFlags,
    allowWrapping: boolean,
    imageFallback: WireImageFallback,
    captionGetter: WireStringGetter | undefined,
    summaryGetters: SummaryGetters,
    groupByGetters: GroupByGetters | undefined,
    numToTruncate: number | undefined,
    actionHydrator: WireActionHydrator | WireActionResult,
    canAutoRunAction: boolean,
    icon: string | undefined,
    itemCaptionGetter: WireStringGetter | undefined
): WireTableComponentHydratorConstructor {
    const {
        forBuilder,
        adc: { appKind },
    } = ib;
    const tableName = getTableName(ib.tables.input);

    return makeSimpleWireTableComponentHydratorConstructor(ib, (thb, chb, searchActive) => {
        let firstListItemActionToRun: WireAction | undefined;
        const groups = makeAppListGroups(thb, groupByGetters, numToTruncate, tableName, rhb => {
            const item = hydrateListListItem(rhb, summaryGetters, actionHydrator, icon, itemCaptionGetter);
            if (canAutoRunAction && firstListItemActionToRun === undefined) {
                firstListItemActionToRun = item.action;
            }
            return item;
        });
        if (groups === undefined) return undefined;

        if (thb.screenPosition === WireScreenPosition.Master) {
            flags |= ListItemFlags.DisableChevron;
        }

        const component: WireAppListListComponent<T> = {
            kind: WireComponentKind.List,
            ...spreadComponentID(componentID, forBuilder),
            format,
            title: captionGetter?.(defined(chb)) ?? "",
            emptyMessage: getAppArrayScreenEmptyMessage(searchActive, appKind),
            allowWrapping,
            imageFallback,
            flags,
            groups,
        };
        return {
            component,
            isValid: true,
            firstListItemActionToRun,
        };
    });
}
