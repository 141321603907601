import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import { ActionKind } from "@glide/common-core/dist/js/database-strings";
import type {
    AddRowToTableDocument,
    DeleteRowDocument,
    EnqueueSingleActionRequest,
    SetColumnsInRowDocument,
} from "@glide/common-core/dist/js/firebase-function-types";
import { assert } from "@glideapps/ts-necessities";
import { ConcurrencyLimiterWithBackpressure } from "@glide/support";
import { type EnqueueDataActionResult, callEnqueueDataAction, handleFaultInjection } from "./enqueue-data-action";
import { cleanupColumnValues } from "./row-data";
import type {
    AddRowActionWithMetadataArguments,
    DeleteRowActionWithMetadataArguments,
    SetColumnsActionWithMetadataArguments,
} from "./types";

// We want to prevent any single client from overloading any more than one
// backend pod with requests.
const concurrencyLimiter = new ConcurrencyLimiterWithBackpressure(30);

export function makeAddRowToTableActionRequest({
    tableName,
    columnValues,
    fromBuilder,
    fromDataEditor,
    writeSource,
    ...actionMetadata
}: AddRowActionWithMetadataArguments): EnqueueSingleActionRequest {
    columnValues = cleanupColumnValues(columnValues);
    const payload: AddRowToTableDocument = { tableName, columnValues, fromBuilder, fromDataEditor, writeSource };
    return { kind: ActionKind.AddRowToTable, payload, actionMetadata };
}

export async function postAddRowToTableActionViaHTTP(
    appFacilities: ActionAppFacilities,
    args: AddRowActionWithMetadataArguments
): Promise<EnqueueDataActionResult> {
    handleFaultInjection(args.appID, ActionKind.AddRowToTable, args.jobID);
    const actionRequest = makeAddRowToTableActionRequest(args);
    const result = await concurrencyLimiter.runSync(() => callEnqueueDataAction(appFacilities, actionRequest));
    assert(result.kind === "success");
    return result.result;
}

export function makeSetColumnsInRowActionRequest({
    tableName,
    columnValues,
    rowIndex,
    fromBuilder,
    fromDataEditor,
    writeSource,
    ...actionMetadata
}: SetColumnsActionWithMetadataArguments): EnqueueSingleActionRequest {
    columnValues = cleanupColumnValues(columnValues);
    const payload: SetColumnsInRowDocument = {
        tableName,
        rowIndex,
        columnValues,
        fromBuilder,
        fromDataEditor,
        writeSource,
    };
    return { kind: ActionKind.SetColumnsInRow, payload, actionMetadata };
}

export async function postSetColumnsInRowActionViaHTTP(
    appFacilities: ActionAppFacilities,
    args: SetColumnsActionWithMetadataArguments
): Promise<EnqueueDataActionResult> {
    handleFaultInjection(args.appID, ActionKind.SetColumnsInRow, args.jobID);
    const actionRequest = makeSetColumnsInRowActionRequest(args);
    const result = await concurrencyLimiter.runSync(() => callEnqueueDataAction(appFacilities, actionRequest));
    assert(result.kind === "success");
    return result.result;
}

export function makeDeleteRowActionRequest({
    tableName,
    rowIndex: rowIndexes,
    fromBuilder,
    fromDataEditor,
    writeSource,
    ...actionMetadata
}: DeleteRowActionWithMetadataArguments): EnqueueSingleActionRequest {
    const payload: DeleteRowDocument = { tableName, rowIndex: rowIndexes, fromBuilder, fromDataEditor, writeSource };
    return { kind: ActionKind.DeleteRow, payload, actionMetadata };
}

export async function postDeleteRowActionViaHTTP(
    appFacilities: ActionAppFacilities,
    args: DeleteRowActionWithMetadataArguments
): Promise<EnqueueDataActionResult> {
    handleFaultInjection(args.appID, ActionKind.DeleteRow, args.jobID);
    const actionRequest = makeDeleteRowActionRequest(args);
    const result = await concurrencyLimiter.runSync(() => callEnqueueDataAction(appFacilities, actionRequest));
    assert(result.kind === "success");
    return result.result;
}
