import { type TableGlideType, isBigTableOrExternal } from "@glide/type-schema";
import type { Row, RootPath } from "@glide/computation-model-types";
import { memoizeFunction, isArray } from "@glide/support";
import type { HydratedRowContext } from "@glide/wire";
import { DefaultMap, defined } from "@glideapps/ts-necessities";

import type {
    MutableSubscriptionSources,
    SubscriptionInfo,
    SubscriptionNeeds,
    ValueProviderActionContext,
} from "./internal-types";
import type { SubscriptionHandler } from "./subscription";

export const emptySubscriptionNeeds: SubscriptionNeeds = {
    parsedPath: false,
    shuffleOrder: false,
    online: false,
    override: false,
};

export const emptySubscriptionInfo: SubscriptionInfo = {
    isComponentState: false,
    handlerAndPath: undefined,
    tokens: new Set(),
    needs: emptySubscriptionNeeds,
    effect: undefined,
    subsidiaries: [],
};

export function makeMutableSubscriptionSources(): MutableSubscriptionSources {
    return {
        globalKeys: new Map(),
        columnsInRows: new DefaultMap(() => new DefaultMap(() => new Set())),
        columnsInAllDirectRows: new DefaultMap(() => new Set()),
        columnsInAllIndirectRows: new DefaultMap(() => new Set()),
        needs: emptySubscriptionNeeds,
    };
}

const makeSimpleHydratedScreenContext = memoizeFunction(
    "makeSimpleHydratedScreenContext",
    (
        inputRow: Row | undefined,
        outputRow: Row | undefined,
        containingScreenRow: Row | undefined
    ): HydratedRowContext => {
        let inputRows: readonly Row[];
        if (inputRow === undefined) {
            inputRows = [];
        } else {
            inputRows = [inputRow];
        }
        return { inputRows, outputRow, containingScreenRow };
    }
);

export function makeHydratedScreenContext(
    inputRowOrRows: Row | readonly Row[] | undefined,
    outputRow: Row | undefined,
    containingScreenRow: Row | undefined
): HydratedRowContext {
    if (isArray(inputRowOrRows)) {
        if (inputRowOrRows.length < 2) {
            return makeSimpleHydratedScreenContext(inputRowOrRows[0], outputRow, containingScreenRow);
        } else {
            return { inputRows: inputRowOrRows, outputRow, containingScreenRow };
        }
    } else {
        return makeSimpleHydratedScreenContext(inputRowOrRows, outputRow, containingScreenRow);
    }
}

export const emptyHydratedScreenContext: HydratedRowContext = makeHydratedScreenContext(
    undefined,
    undefined,
    undefined
);

export function addSubscriptionHandler(
    hydrationContext: ValueProviderActionContext,
    name: string,
    handler: SubscriptionHandler
): RootPath {
    const path = defined(hydrationContext.namespace).addEntity(name, handler, undefined);
    hydrationContext.pathsSubscribedTo.push(path);
    return path;
}

export class ObjectRetirer {
    private readonly objects = new Set<any>();

    // This has to be an arrow function because we use it as a first-class
    // function.
    public readonly registerObjectToRetire = (obj: any) => {
        this.objects.add(obj);
    };

    public retire(): void {
        for (const obj of this.objects) {
            // eslint-disable-next-line guard-for-in
            for (const prop in obj) {
                delete obj[prop];
            }
        }
    }
}

// Exported for mocking
export function getShouldShowOriginalErrorMessageForTable(table: TableGlideType): boolean {
    return isBigTableOrExternal(table);
}
