/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";
import { ConcurrencyLimiterWithBackpressure, isUndefinedish, maybeParseJSON } from "@glide/support";
import isUndefined from "lodash/isUndefined";
import fetch from "node-fetch";
const { Result } = glide;
import * as t from "io-ts";
import { isLeft } from "fp-ts/lib/Either";

export const plugin = glide.newPlugin({
    id: "google-vision",
    name: "Google Cloud Vision",
    description: "Use AI tools from Google Cloud",
    disclosure: `Glide's use and transfer to any other app of information received from Google APIs will adhere to <a
        href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
        rel="noreferrer"
        target="_blank">
        Google API Services User Data Policy
    </a>, including the Limited Use requirements.`,
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/google-cloud.png",
    tier: "starter",
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/google-cloud-vision",
    parameters: {
        apiKey: glide.makeParameter({
            type: "secret",
            name: "API key",
            placeholder: "e.g. abc123...",
            description: "[Learn more](https://console.cloud.google.com/apis/credentials) about getting an API key",
            required: true,
        }),
    },
});

async function blobToBase64(blob: Blob): Promise<string> {
    const buffer = await blob.arrayBuffer();
    const uint8Array = new Uint8Array(buffer);
    let base64 = "";

    for (let i = 0; i < uint8Array.length; i++) {
        base64 += String.fromCharCode(uint8Array[i]);
    }

    return Buffer.from(base64, "binary").toString("base64");
}

const DEFAULT_OCR_LANGUAGE_CODE = "en";

export enum ocrFeature {
    Text = "TEXT_DETECTION",
    Document = "DOCUMENT_TEXT_DETECTION",
}
const DEFAULT_FEATURE = ocrFeature.Text;

const ocrTextCodec = t.type({
    responses: t.array(
        t.type({
            fullTextAnnotation: t.type({
                text: t.string,
            }),
        })
    ),
});

export async function getOcrText(
    context: Omit<glide.ServerExecutionContext, "uploadFile" | "rehostFile">,
    apiKey: string,
    file: string,
    feature: ocrFeature,
    languageCode: string // DEFAULT_OCR_LANGUAGE_CODE
) {
    const url = `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`;
    const fileData = await context.fetch(file).then(r => r.blob());
    const encodedImage = await blobToBase64(fileData);

    const body = {
        requests: [
            {
                image: {
                    content: encodedImage,
                },
                features: [
                    {
                        type: feature,
                    },
                ],
                imageContext: {
                    languageHints: languageCode,
                },
            },
        ],
    };

    const response = await context.fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });

    let responseText: string = "";
    let json;
    try {
        responseText = await response.text();
        json = JSON.parse(responseText);
    } catch (e: unknown) {
        return glide.Result.Fail(`No json returned`, {
            status: response.status,
            data: responseText,
        });
    }

    if (response.ok !== true) {
        try {
            if (json.error.message !== null) {
                return Result.FailFromHTTPStatus(json.error.message, response.status, {
                    data: json,
                });
            }
        } catch {
            return Result.Fail(`Unknown error`, {
                status: response.status,
                data: json,
            });
        }
        return Result.FailFromHTTPStatus("Could not convert image to text", response.status, {
            data: json,
        });
    }

    const decoded = ocrTextCodec.decode(json);
    if (isLeft(decoded)) {
        return Result.Fail(`No value returned from OCR Engine`, {
            data: json,
        });
    }
    const text = decoded.right.responses[0].fullTextAnnotation.text.trim();

    return Result.Ok({ text });
}

plugin.addComputation({
    id: "ocr",
    name: "Extract text from image",
    description: "Convert an image into text using Google Cloud's Vision API",
    billablesConsumed: 1,
    parameters: {
        file: glide.makeParameter({
            type: "url",
            name: "File",
            required: true,
        }),
        languageCode: glide.makeParameter({
            type: "string",
            name: "Language",
            description:
                "The languageHints code from [google OCR docs](https://cloud.google.com/vision/docs/languages)",
            placeholder: `e.g. ${DEFAULT_OCR_LANGUAGE_CODE}`,
            defaultValue: DEFAULT_OCR_LANGUAGE_CODE,
        }),
        feature: glide.makeParameter({
            type: "enum",
            name: "Model",
            values: [
                { value: ocrFeature.Text, label: "Text" },
                { value: ocrFeature.Document, label: "Document" },
            ],
            defaultValue: DEFAULT_FEATURE,
            description:
                "When dealing with traditional documents, use Document otherwise use Text for general purpose text recognition",
        }),
    },
    results: { text: glide.makeParameter({ type: "string", name: "Text" }) },

    async execute(context, { file, feature = DEFAULT_FEATURE, languageCode = DEFAULT_OCR_LANGUAGE_CODE, apiKey }) {
        if (file === undefined) {
            return Result.FailPermanent("No file provided", {
                isPluginError: false,
            });
        }
        if (apiKey === undefined) {
            return Result.FailPermanent("API key not provided");
        }

        if (!(feature === ocrFeature.Text || feature === ocrFeature.Document)) {
            return Result.FailPermanent("Provide a valid model.");
        }

        const result = await context.useCache(async () => {
            const result = await getOcrText(context, apiKey, file, feature, languageCode);
            if (result.ok) {
                context.consumeBillable();
            }

            return result;
        }, [file, feature, languageCode, apiKey]);

        return result;
    },
});

// PDF To Text Acation
const PDF_FILE = { extension: ".pdf", mimeType: "application/pdf" };

export const convertLinkFromSharingService = (file: string) => {
    // File sharing services like gdrive, dropbox, onedrive, etc. have a different link for sharing and downloading.
    // Typically, the user will grab the share link and we'll transform it here to the download link.

    if (file.includes("drive.google.com")) {
        const driveURL = "https://drive.google.com/file/d/";
        const startIndex = file.indexOf(driveURL) + driveURL.length;
        const endIndex = file.indexOf("/view");
        const fileId = file.substring(startIndex, endIndex);
        return `https://drive.google.com/u/0/uc?id=${fileId}&export=download`;
    }
    if (file.includes("dropbox.com")) {
        const dropboxDownloadURL = file.replace("?dl=0", "");
        return `${dropboxDownloadURL}?dl=1`;
    }
    if (file.includes("1drv.ms") || file.includes("onedrive.live")) {
        const encodedString = Buffer.from(file).toString("base64");
        const modifiedString = encodedString.replace(/=$/g, "").replace(/\//g, "_").replace(/\+/g, "-");
        return `https://api.onedrive.com/v1.0/shares/u!${modifiedString}/root/content`;
    }

    return file;
};

const downloadPDF = async (file: string) => {
    const response = await fetch(convertLinkFromSharingService(file));
    const buffer = await response.arrayBuffer();
    return buffer;
};

export function getTotalNumberOfPages(pdf: ArrayBuffer): number {
    const matches = pdf.toString().match(/\/Type\s*\/Page([^s]|$)/g);
    return isUndefinedish(matches) ? 1 : matches.length;
}

export const readPagesInput = (pdf: ArrayBuffer, pages: string | undefined) => {
    let pagesString: string = "";

    const totalNumberOfPages = getTotalNumberOfPages(pdf);
    if (isUndefined(pages)) {
        pagesString = `1 - ${totalNumberOfPages}`;
    } else {
        pagesString = pages.toString().replaceAll(" ", "").replace("*", totalNumberOfPages.toString());
    }

    return pagesString
        ?.split(",")
        .map(p => {
            if (p.includes("-")) {
                const [start, end] = p.split("-").map(Number);
                const range = Array.from({ length: end - start + 1 }, (_, i) => i + start);
                return range.join();
            }
            return p;
        })
        .join()
        .split(",");
};

plugin.addAction({
    id: "read",
    name: "Extract text from PDF files",
    description: "Convert a PDF into text sorted by page using Google Cloud's Vision API",
    billablesConsumed: 5,
    parameters: {
        file: glide.makeParameter({
            type: "url",
            name: "File",
            required: true,
            placeholder: "https://mydomain.com/demo.pdf",
        }),
        languageCode: glide.makeParameter({
            type: "string",
            name: "Language",
            description:
                "The languageHints code from [google OCR docs](https://cloud.google.com/vision/docs/languages)",
            placeholder: DEFAULT_OCR_LANGUAGE_CODE,
        }),
        feature: glide.makeParameter({
            type: "enum",
            name: "Model",
            values: [
                { value: "TEXT_DETECTION", label: "Text" },
                { value: "DOCUMENT_TEXT_DETECTION", label: "Document" },
            ],
            defaultValue: DEFAULT_FEATURE,
            description:
                "When dealing with traditional documents, use Document otherwise use Text for general purpose text recognition",
        }),
        pages: glide.makeParameter({
            type: "string",
            name: "Pages",
            description:
                "Range of pages to extract text from. For example, 1-3,5,7-9 will extract text from pages 1,2,3,5,7,8,9. Use * to select all pages.",
            placeholder: "1-3,5,7-*",
        }),
    },

    async execute(context, { file, feature = DEFAULT_FEATURE, languageCode, apiKey, pages }) {
        if (file === undefined)
            return Result.FailPermanent("No file provided", {
                isPluginError: false,
            });

        const pdfVisionEndpoint = `https://vision.googleapis.com/v1/files:annotate?key=${apiKey}`;

        const result = await context.useCache(async () => {
            const pdf = await downloadPDF(file);

            // rehost the pdf to google cloud storage so we can use it with the vision api
            const rehostedPDF = await context.uploadFile(`generated${PDF_FILE.extension}`, PDF_FILE.mimeType, pdf);
            if (rehostedPDF.ok === false) return rehostedPDF;

            const gsURI = rehostedPDF.result.replace("https://storage.googleapis.com/", "gs://");

            // convert the page selection string to an array of pages
            const pagesToOCR = readPagesInput(pdf, pages);

            // vision API has a 5 page limit per call so we transform the array of pages into 2D array where each subarray has a max length of 5
            const batches: string[][] = [[]];
            pagesToOCR?.forEach(page => {
                const latestBatchIndex = batches.length - 1;
                //check if latest batch is full?
                if (batches[latestBatchIndex].length === 5) {
                    //if yes, create new batch
                    batches.push([page]);
                } else {
                    //push page to current batch
                    batches[latestBatchIndex].push(page);
                }
            });

            const text: string[] = [];
            let error: string | undefined = undefined;

            // we use concuncery to fetch preform OCR on each batch in parallel. As text is returned from the API
            // it gets pushed to the text array. If an error occurs, we set the error variable and abort the rest of the requests

            const limiter = new ConcurrencyLimiterWithBackpressure(10);
            const controller = new AbortController();
            const timeoutMS = 1000 * 60 * 5;

            setTimeout(() => controller.abort(), timeoutMS);

            if (!isUndefined(batches)) {
                for (const [_index, batch] of batches.entries()) {
                    await limiter.run(async () => {
                        const body = {
                            requests: [
                                {
                                    inputConfig: {
                                        gcsSource: {
                                            uri: gsURI,
                                        },
                                        mimeType: PDF_FILE.mimeType,
                                    },
                                    features: [
                                        {
                                            type: feature,
                                        },
                                    ],
                                    imageContext: {
                                        languageHints: languageCode,
                                    },

                                    pages: batch,
                                },
                            ],
                        };

                        const response = await context.fetch(pdfVisionEndpoint, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(body),
                        });

                        if (!response.ok) {
                            const asText = await response.text();
                            error = asText;
                            return Result.FailFromHTTPStatus(asText, response.status, {
                                data: maybeParseJSON(asText),
                            });
                        }

                        const asJSON = await response.json();

                        asJSON.responses.forEach((r: any) => {
                            r.responses.forEach((rs: any) => {
                                const content = rs.fullTextAnnotation.text.trim() as string;
                                text[rs.context.pageNumber - 1] = content;
                            });
                        });
                        return;
                    }, controller.signal);
                }
                await limiter.finish();
            }

            if (!isUndefined(error)) {
                return Result.Fail(error);
            }

            context.consumeBillable();

            return Result.Ok({ text: text.join("\n") });
        }, [file, feature, languageCode, apiKey, languageCode, pages]);

        return result;
    },

    results: { text: glide.makeParameter({ type: "string", name: "Text" }) },
});
