import {
    type TagOrChoiceItem,
    type SuperTableBooleanCell,
    type SuperTableButtonCell,
    type SuperTableCell,
    type SuperTableChoiceCell,
    type SuperTableColumn,
    type SuperTableExtraActionsCell,
    type SuperTableImageCell,
    type SuperTableLinkCell,
    type SuperTableRow,
    type SuperTableRowHeaderCell,
    type SuperTableTagsCell,
    type SuperTableTextCell,
    TextComponentStyle,
} from "@glide/component-utils";
import * as Table from "./new-table-lib";
import { assertNever } from "@glideapps/ts-necessities";
import { WireButton } from "../../renderers/wire-button/wire-button";
import { AppIcon, GlideIcon, getGlideIcon } from "@glide/common";
import { APP_MODAL_ROOT, UIButtonAppearance, UISize } from "@glide/wire";
import { Img } from "../img/img";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import { isDefined, isEmptyOrUndefined } from "@glide/support";
import type { QuerySort } from "@glide/computation-model-types";
import { formatLocalizedString } from "@glide/localization";
import * as WireDropdown from "../../renderers/wire-dropdown-menu/wire-dropdown-menu";
import chroma from "chroma-js";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { PagesSwitch } from "../pages-switch";
import { Text } from "../text/text";
import { css } from "@glide/common-components";
import tw from "twin.macro";
import { AppKind } from "@glide/location-common";

interface NewTableProps {
    columns: readonly SuperTableColumn[];
    rows: readonly SuperTableRow[];
    activeSort: QuerySort | undefined;
    style: "minimal" | "striped";
    title?: string;
    appID: string;
}

const getAriaSort = (activeSort: NewTableProps["activeSort"], columnName: SuperTableColumn["columnName"]) => {
    if (activeSort?.columnName !== columnName) return undefined;
    return activeSort?.order === "asc" ? "ascending" : "descending";
};

const newTableCellSizesStyles = css`
    &.auto {
        ${tw`max-w-xs gp-sm:max-w-md gp-md:max-w-xl`}
    }

    &.${UISize.XSmall} {
        ${tw`max-w-[2.5rem] min-w-[2.5rem]`}
    }

    &.${UISize.Small} {
        ${tw`max-w-[5rem] min-w-[5rem]`}
    }

    &.${UISize.Medium} {
        ${tw`max-w-[8rem] min-w-[8rem] gp-sm:(max-w-[10rem] min-w-[10rem]) gp-md:(max-w-[15rem] min-w-[15rem])`}
    }

    &.${UISize.Large} {
        ${tw`max-w-[14rem] min-w-[14rem] gp-sm:(max-w-[20rem] min-w-[20rem]) gp-md:(max-w-[25rem] min-w-[25rem])`}
    }

    &.${UISize.XLarge} {
        ${tw`max-w-[20rem] min-w-[20rem] gp-sm:(max-w-[30rem] min-w-[30rem]) gp-md:(max-w-[40rem] min-w-[40rem])`}
    }
`;

function getCellSize(sizeOptions: SuperTableColumn["sizeOptions"]): UISize | "auto" {
    if (sizeOptions.sizeKind === "fixed") {
        return sizeOptions.size;
    }
    return "auto";
}

export const NewTable: React.FC<NewTableProps> = p => {
    const { rows, style, columns, activeSort, title, appID } = p;
    return (
        <Table.TableBody>
            {!isEmptyOrUndefined(title) && (
                <Table.TableRow
                    css={css`
                        border-bottom: none;
                    `}
                >
                    <Table.TableCell colSpan={columns.length} css={newTableCellSizesStyles} tw={"pt-3"}>
                        <Text variant={TextComponentStyle.headlineXSmall} element="h3">
                            {title}
                        </Text>
                    </Table.TableCell>
                </Table.TableRow>
            )}

            <Table.TableRow data-style={style} tw="data-[style=striped]:border-transparent mb-3">
                {columns.map((column, idx) => (
                    <Table.TableCell
                        css={newTableCellSizesStyles}
                        key={idx}
                        className={getCellSize(column.sizeOptions)}
                        aria-sort={getAriaSort(activeSort, column.columnName)}
                    >
                        <HeaderCell
                            aria-sort={getAriaSort(activeSort, column.columnName)}
                            key={idx}
                            column={column}
                            sortByColumn={isDefined(column.headerAction) ? column.headerAction : undefined}
                            activeSort={activeSort}
                        />
                    </Table.TableCell>
                ))}
            </Table.TableRow>

            {rows.map((row, idx) => {
                const onClickRow = (row.find(cell => cell.kind === "row-header") as SuperTableRowHeaderCell | undefined)
                    ?.onClick;

                return (
                    <Table.TableRow
                        className={isDefined(onClickRow) ? "has-click" : undefined}
                        key={idx}
                        data-style={style}
                        tw="[&>td]:h-11 data-[style=striped]:border-transparent data-[style=striped]:even:bg-bg-hovered [&.has-click]:(cursor-pointer page-hover:bg-n100A active:bg-n100A)"
                        onClick={e => {
                            if (isDefined(onClickRow)) {
                                e.stopPropagation();
                                onClickRow();
                            }
                        }}
                    >
                        {row.map((cell, jdx) => (
                            <Table.TableCell
                                key={jdx}
                                css={newTableCellSizesStyles}
                                className={getCellSize(columns[jdx].sizeOptions)}
                                data-dropdown={["choice", "tag"].includes(cell.kind) || undefined}
                                tw="data-[dropdown]:p-0 data-[dropdown]:overflow-visible"
                            >
                                <NewTableCell cell={cell} appID={appID} />
                            </Table.TableCell>
                        ))}
                    </Table.TableRow>
                );
            })}
        </Table.TableBody>
    );
};

interface NewTableCellProps<SuperTableCellType extends SuperTableCell = SuperTableCell> {
    cell: SuperTableCellType;
    appID: string;
}

const NewTableCell: React.FC<NewTableCellProps> = p => {
    const { cell, appID } = p;
    const { kind } = cell;
    switch (kind) {
        case "text":
            return <TextCell cell={cell} appID={appID} />;
        case "link":
            return <LinkCell cell={cell} appID={appID} />;
        case "row-header":
            return <RowHeaderCell cell={cell} appID={appID} />;
        case "boolean":
            return <BooleanCell cell={cell} appID={appID} />;
        case "image":
            return <ImageCell cell={cell} appID={appID} />;
        case "button":
            return <ButtonCell cell={cell} appID={appID} />;
        case "extra-actions":
            return <ExtraActionsCell cell={cell} appID={appID} />;
        case "choice":
            return <ChoiceCell cell={cell} appID={appID} />;
        case "tag":
            return <TagsCell cell={cell} appID={appID} />;
        default:
            return assertNever(kind);
    }
};

const ButtonCell: React.FC<NewTableCellProps<SuperTableButtonCell>> = p => {
    const { cell } = p;
    const { label, onClick, icon, style } = cell;

    if (!isDefined(onClick)) return null;

    const onClickCell = (event: React.MouseEvent) => {
        event.stopPropagation();
        onClick?.();
    };

    const iconOnly = icon !== undefined && isEmptyOrUndefined(label);

    const appearance = style === "Default" ? UIButtonAppearance.Bordered : UIButtonAppearance.Filled;

    return (
        <WireButton appearance={appearance} size="xs" onClick={onClickCell} iconName={icon} iconOnly={iconOnly}>
            {label}
        </WireButton>
    );
};

const TextCell: React.FC<NewTableCellProps<SuperTableTextCell>> = p => {
    const { cell, appID } = p;
    const { value, accessoryImage } = cell;
    return (
        <div tw="flex items-center gap-x-1.5">
            {accessoryImage !== undefined && (
                <Img
                    tw="w-7 min-w-[28px] h-7 min-h-[28px] rounded-md select-none object-cover"
                    src={massageImageUrl(
                        accessoryImage,
                        {
                            thumbnail: true,
                            width: 28,
                            height: 28,
                        },
                        appID
                    )}
                    isPages={true}
                />
            )}
            <span tw="text-sm text-text-contextual-base page-md:text-base">{value}</span>
        </div>
    );
};

const LinkCell: React.FC<NewTableCellProps<SuperTableLinkCell>> = p => {
    const { cell } = p;
    const { displayValue, value } = cell;
    return (
        <a href={value} target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}>
            <span tw="text-sm font-medium text-text-contextual-accent underline-offset-2 page-md:text-base page-hover:underline">
                {displayValue}
            </span>
        </a>
    );
};

const RowHeaderCell: React.FC<NewTableCellProps<SuperTableRowHeaderCell>> = p => {
    const { cell, appID } = p;
    const { value, accessoryImage, onClick } = cell;
    const onClickCell = (event: React.MouseEvent) => {
        event.stopPropagation();
        onClick?.();
    };
    return (
        <button
            tw="w-full flex items-center gap-x-1.5 text-left font-semibold underline decoration-transparent transition underline-offset-2 page-hover:(underline decoration-text-contextual-pale) rounded-md focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-text-contextual-accent truncate"
            onClick={onClickCell}
            data-button
        >
            {accessoryImage !== undefined && (
                <Img
                    tw="w-7 min-w-[28px] h-7 min-h-[28px] rounded-md select-none object-cover"
                    src={massageImageUrl(
                        accessoryImage,
                        {
                            thumbnail: true,
                            width: 28,
                            height: 28,
                        },
                        appID
                    )}
                    isPages={true}
                />
            )}
            <span tw="text-sm text-text-contextual-dark page-md:text-base">{value}</span>
        </button>
    );
};

const BooleanCell: React.FC<NewTableCellProps<SuperTableBooleanCell>> = p => {
    const { cell } = p;
    const { value, onValueChange } = cell;

    if (onValueChange === undefined) {
        const icon = value ? "st-check" : "st-close";
        return (
            <div tw="flex">
                <AppIcon
                    className={value ? "true-boolean" : ""}
                    tw="[&.true-boolean]:text-text-contextual-accent text-text-contextual-disabled"
                    size={20}
                    icon={getGlideIcon(icon)}
                />
            </div>
        );
    }

    return (
        <div tw="flex" onClick={event => event.stopPropagation()}>
            <PagesSwitch
                size={"small"}
                checked={value}
                onChange={event => {
                    onValueChange(event.currentTarget.checked);
                }}
            />
        </div>
    );
};

const ExtraActionsCell: React.FC<NewTableCellProps<SuperTableExtraActionsCell>> = p => {
    const { cell } = p;
    const { onClick } = cell;
    const onClickCell = (event: React.MouseEvent) => {
        event.stopPropagation();
        onClick(event.clientX, event.clientY);
    };
    return (
        <div tw="flex justify-end">
            <WireButton
                appearance={UIButtonAppearance.MinimalSecondary}
                onClick={onClickCell}
                size="xs"
                tw="px-2"
                iconOnly
                iconName={getGlideIcon("st-more")}
            />
        </div>
    );
};

function getChoiceLabel(tagItems: TagOrChoiceItem[]): string {
    if (tagItems.length === 0) {
        return "—";
    }

    if (tagItems.length === 1) {
        return tagItems[0].displayValue;
    }

    return formatLocalizedString("numberOfItems", [tagItems.length.toLocaleString()], AppKind.Page);
}

const ChoiceCell: React.FC<NewTableCellProps<SuperTableChoiceCell>> = p => {
    const { cell } = p;
    const { value: tagItems, options, isMulti } = cell;

    if (tagItems === undefined) {
        return null;
    }

    const title = getChoiceLabel(tagItems);

    return (
        <div tw="flex gap-2 w-full">
            <WireDropdown.Root>
                <WireDropdown.Trigger
                    tw="p-2 flex rounded-lg max-w-full data-[state=open]:ring-2  page-hover:[&>[data-icon]]:text-text-contextual-base data-[state=open]:ring-text-contextual-accent focus-visible:ring-2 focus-visible:ring-text-contextual-accent items-center justify-between h-9 page-hover:bg-n100A gap-1 data-[state=open]:shadow-xl-dark data-[state=open]:bg-n100A"
                    title={title}
                    onClick={e => e.stopPropagation()}
                >
                    <div tw="overflow-hidden flex-1 text-left whitespace-nowrap text-ellipsis text-text-contextual-base">
                        {title}
                    </div>
                    <div data-icon tw="transition-colors text-text-contextual-xpale">
                        <GlideIcon tw="-mb-px shrink-0" kind="stroke" icon="st-caret" iconSize={14} />
                    </div>
                </WireDropdown.Trigger>
                <WireDropdown.Portal container={document.getElementById(APP_MODAL_ROOT)}>
                    <WireDropdown.Content align="start" side="bottom" sideOffset={2}>
                        {options?.map(o => {
                            const isSelected = tagItems.some(t => t.value === o.value);

                            const onSelect = (e: Event) => {
                                if (isMulti) {
                                    e.preventDefault();
                                }

                                o.onSelect?.();
                            };

                            return (
                                <WireDropdown.CheckboxItem
                                    key={o.value}
                                    checked={isSelected}
                                    onClick={e => e.stopPropagation()}
                                    tw="p-1"
                                    onSelect={onSelect}
                                >
                                    {o.displayValue}
                                </WireDropdown.CheckboxItem>
                            );
                        })}
                    </WireDropdown.Content>
                </WireDropdown.Portal>
            </WireDropdown.Root>
        </div>
    );
};

function getTagChromaColor(configuredColor: string | undefined, accentColor: string): chroma.Color {
    if (configuredColor === undefined) {
        return chroma(accentColor);
    }

    if (!chroma.valid(configuredColor)) {
        return chroma(accentColor);
    }

    return chroma(configuredColor);
}

interface ColoredTagProps {
    tag: TagOrChoiceItem;
}

const ColoredTag: React.FC<ColoredTagProps> = p => {
    const { tag } = p;

    const theme = useWireAppTheme();

    const chromaColor = getTagChromaColor(tag.color, theme.primaryAccentColor);

    const style: React.CSSProperties = {
        backgroundColor: chromaColor.alpha(0.125).css(),
        color: chromaColor.css(),
    };

    return (
        <span
            tw="flex items-center px-2 h-5 text-xs font-medium leading-none whitespace-nowrap rounded-full"
            style={style}
        >
            {tag.displayValue}
        </span>
    );
};

const TagsCell: React.FC<NewTableCellProps<SuperTableTagsCell>> = p => {
    const { cell } = p;
    const { value: tagItems, options, isEditable, isMulti } = cell;

    if (tagItems === undefined) {
        return null;
    }

    // If it's not editable, just show the tag. No dropdown.
    if (!isEditable) {
        return (
            <div tw="p-2 pl-1.5 flex items-center gap-x-2 overflow-x-hidden">
                {tagItems.map((t, idx) => {
                    return <ColoredTag tag={t} key={`${t.value}-${idx}`} />;
                })}
            </div>
        );
    }

    return (
        <WireDropdown.Root>
            <WireDropdown.Trigger
                tw="p-2 pl-1.5 rounded-lg data-[state=open]:ring-2 page-hover:[&>[data-icon]]:text-text-contextual-base data-[state=open]:ring-text-contextual-accent focus-visible:ring-2 focus-visible:ring-text-contextual-accent flex items-center justify-between h-9 page-hover:bg-n100A gap-1 data-[state=open]:shadow-xl-dark data-[state=open]:bg-n100A max-w-full overflow-x-hidden"
                onClick={e => e.stopPropagation()}
            >
                {tagItems.map((t, idx) => {
                    return <ColoredTag key={`${t.value}-${idx}`} tag={t} />;
                })}
                <div data-icon tw="transition-colors text-text-contextual-xpale">
                    <GlideIcon tw="-mb-px shrink-0" kind="stroke" icon="st-caret" iconSize={14} />
                </div>
            </WireDropdown.Trigger>
            <WireDropdown.Portal container={document.getElementById(APP_MODAL_ROOT)}>
                <WireDropdown.Content align="start" side="bottom" sideOffset={2}>
                    {options?.map(o => {
                        const isSelected = tagItems.some(t => t.value === o.value);

                        const onSelect = (e: Event) => {
                            if (isMulti) {
                                e.preventDefault();
                            }

                            o.onSelect?.();
                        };

                        return (
                            <WireDropdown.CheckboxItem
                                key={o.value}
                                checked={isSelected}
                                onClick={e => e.stopPropagation()}
                                tw="p-1"
                                onSelect={onSelect}
                            >
                                <ColoredTag tag={o} />
                            </WireDropdown.CheckboxItem>
                        );
                    })}
                </WireDropdown.Content>
            </WireDropdown.Portal>
        </WireDropdown.Root>
    );
};

const ImageCell: React.FC<NewTableCellProps<SuperTableImageCell>> = p => {
    const { cell, appID } = p;
    const images = Array.isArray(cell.value) ? cell.value : [cell.value];
    return (
        <div tw="flex gap-x-1">
            {images.map((img, idx) => (
                <Img
                    tw="w-8 min-w-[32px] h-8 min-h-[32px] rounded-md select-none object-cover"
                    key={idx}
                    src={massageImageUrl(
                        img,
                        {
                            thumbnail: true,
                            width: 32,
                            height: 32,
                        },
                        appID
                    )}
                    isPages={true}
                />
            ))}
        </div>
    );
};

interface HeaderCell {
    column: SuperTableColumn;
    sortByColumn?: () => void;
    activeSort: QuerySort | undefined;
    className?: string;
}

const HeaderCell: React.FC<HeaderCell> = p => {
    const { column, sortByColumn, activeSort, className } = p;
    if (isDefined(sortByColumn)) {
        const hasActiveSort = activeSort?.columnName === column.columnName;
        return (
            <button
                className={className}
                tw="w-full relative flex items-center text-xs font-semibold uppercase text-text-contextual-base opacity-80 transition page-hover:opacity-100 rounded-md gap-0.5 page-hover:[&_[data-icon]]:opacity-100  focus-visible:[&_[data-icon]]:opacity-100 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-text-contextual-accent"
                onClick={sortByColumn}
            >
                <span data-active={hasActiveSort} tw="text-left data-[active=true]:text-text-dark truncate">
                    {column.header}
                </span>
                <span aria-hidden="true">
                    <GlideIcon
                        kind="monotone"
                        data-order={hasActiveSort ? activeSort?.order : undefined}
                        data-active={hasActiveSort}
                        data-icon
                        iconSize={16}
                        icon="mt-carret-down"
                        tw="transition text-text-contextual-disabled opacity-0 rotate-0 data-[active=true]:data-[order=desc]:rotate-180 data-[active=true]:opacity-100 data-[active=true]:text-text-contextual-accent"
                    />
                </span>
            </button>
        );
    }
    return (
        <span tw="w-full relative flex items-center text-xs font-semibold uppercase text-text-contextual-base opacity-80">
            {column.header}
        </span>
    );
};
