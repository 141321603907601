import type { WireBackendInterface } from "@glide/hydrated-ui";
import { emptyScreen } from "@glide/hydrated-ui";
import { ResponsiveProvider, useResponsiveSizeClass } from "@glide/common-components";
import { ignore, isDefined } from "@glide/support";
import type { ResponsiveSizeClass, WireComponent, WireModalScreen, WireNavigation } from "@glide/wire";
import {
    UIButtonAppearance,
    WireComponentKind,
    WireModalSize,
    WireNavigationAction,
    WireScreenFlag,
} from "@glide/wire";
import classNames from "classnames";
import { definedMap } from "collection-utils";
import type { PanInfo, Transition } from "framer-motion";
import { AnimatePresence, m } from "framer-motion";
import * as React from "react";
import { css } from "styled-components";
import tw from "twin.macro";

import type { LayerInfo, LayerTransition, PageTransitionInfo } from "../../chrome-common";
import {
    LayerPositionProvider,
    OverlayHeader,
    OverlayHeaderBackButton,
    OverlayHeaderCloseButton,
    PageTransitions,
    TransitionProvider,
    getFullLayerTransition,
    useOverlayHeaderHeight,
} from "../../chrome-common";
import type { PagesRendererComponentDependencies } from "../../renderers/pages-component-renderer";
import { PagesComponentRenderer } from "../../renderers/pages-component-renderer";
import {
    PortalIdContext,
    createSlideInPortalIdFromKey,
    makeSingleActionSpreadProps,
    runActionAndHandleURL,
    usePortalBaseName,
} from "../../wire-lib";
import { FloatingPortalLayer, ScrollRefProvider, SpecialGroupComponents } from "./content-common";
import { ScreenSpecialCasesProvider } from "./lib/screen-special-cases";
import { IsInsideOverlayProvider } from "./lib/use-is-inside-overlay";
import { useScreenComponents } from "./lib/use-layer-compoents";
import { SlideInHeightContextProvider, useSlideInHeight } from "./lib/use-slide-in-size";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import type { WireButtonComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { WireButton } from "../../renderers/wire-button/wire-button";

interface SlideInContentProps {
    readonly modalScreen: WireModalScreen | undefined;
    readonly onBackdropClick: () => void;
    readonly onClose: () => void;
    readonly maxSize: ResponsiveSizeClass;
    readonly isScrolled: boolean;
    readonly setIsScrolled: React.Dispatch<React.SetStateAction<boolean>>;
    readonly backend: WireBackendInterface;
    readonly lastNavigation: WireNavigation | undefined;
    readonly componentDependencies: PagesRendererComponentDependencies;
}

export const ChromeSlideInContent: React.VFC<SlideInContentProps> = p => {
    const {
        modalScreen,
        onBackdropClick,
        onClose,
        maxSize,
        isScrolled,
        setIsScrolled,
        backend,
        lastNavigation,
        componentDependencies,
    } = p;

    const onBack = definedMap(modalScreen?.backAction, a => () => runActionAndHandleURL(a, backend));

    const { layers, onAnimationComplete } = useSlideInTransitions(modalScreen, lastNavigation);

    const mainOverlayLayer = React.useMemo<LayerInfo>(() => {
        return {
            screen: modalScreen ?? emptyScreen,
            transition: undefined,
            persist: true,
        };
    }, [modalScreen]);

    const mainLayerSpecialComponents = mainOverlayLayer.screen.specialComponents.filter(isDefined) ?? [];
    const mainLayerFlags = mainOverlayLayer.screen.flags;

    return (
        <AnimatePresence>
            {modalScreen !== undefined && modalScreen.size === WireModalSize.SlideIn && (
                <div tw="grow flex flex-col relative h-full" data-testid="slide-in-content">
                    <SlideInOverlay
                        title={modalScreen.title}
                        isOverlayScrolled={isScrolled}
                        onBack={onBack}
                        onBackdropClick={onBackdropClick}
                        onClose={onClose}
                        specialComponents={mainLayerSpecialComponents}
                        screenFlags={mainLayerFlags}
                        backend={backend}>
                        <div tw="h-full">
                            <SlideInHeightContextProvider>
                                <ResponsiveProvider
                                    maxSize={maxSize}
                                    id="sidebar-root"
                                    tw="w-full flex-grow grid grid-cols-1 h-full">
                                    {layers === undefined ? (
                                        <>
                                            <SlideInContentLayer
                                                key={modalScreen.key}
                                                maxSize={maxSize}
                                                backend={backend}
                                                onAnimationComplete={ignore}
                                                layer={mainOverlayLayer}
                                                setIsOverlayScrolled={setIsScrolled}
                                                componentDependencies={componentDependencies}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <SlideInContentLayer
                                                key={layers.bottom.screen.key}
                                                maxSize={maxSize}
                                                backend={backend}
                                                onAnimationComplete={onAnimationComplete}
                                                layer={layers.bottom}
                                                setIsOverlayScrolled={setIsScrolled}
                                                componentDependencies={componentDependencies}
                                            />

                                            <SlideInContentLayer
                                                key={layers.top.screen.key}
                                                maxSize={maxSize}
                                                backend={backend}
                                                onAnimationComplete={onAnimationComplete}
                                                layer={layers.top}
                                                setIsOverlayScrolled={setIsScrolled}
                                                componentDependencies={componentDependencies}
                                            />
                                        </>
                                    )}
                                </ResponsiveProvider>
                            </SlideInHeightContextProvider>
                        </div>
                    </SlideInOverlay>
                </div>
            )}
        </AnimatePresence>
    );
};

interface BaseSlideInOverlayProps {
    readonly onClose: () => void;
    readonly onBack: (() => void) | undefined;
    readonly title: string;
    readonly isOverlayScrolled: boolean;
    readonly onBackdropClick: () => void;
    readonly specialComponents: WireComponent[];
    readonly screenFlags: readonly WireScreenFlag[];
    readonly backend: WireBackendInterface;
}

interface SlideInOverlayProps extends BaseSlideInOverlayProps {
    readonly specialComponents: WireComponent[];
    readonly screenFlags: readonly WireScreenFlag[];
    readonly backend: WireBackendInterface;
}

const SlideInOverlay: React.FC<React.PropsWithChildren<SlideInOverlayProps>> = p => {
    const {
        children,
        onClose,
        title,
        onBack,
        isOverlayScrolled,
        onBackdropClick,
        specialComponents,
        screenFlags,
        backend,
    } = p;

    const sizeClass = useResponsiveSizeClass();

    if (sizeClass === "sm" || sizeClass === undefined)
        return (
            <SheetOverlay
                isOverlayScrolled={isOverlayScrolled}
                onClose={onClose}
                title={title}
                onBack={onBack}
                onBackdropClick={onBackdropClick}
                specialComponents={specialComponents}
                screenFlags={screenFlags}
                backend={backend}>
                {children}
            </SheetOverlay>
        );

    return (
        <SlideInDesktopOverlay
            isOverlayScrolled={isOverlayScrolled}
            specialComponents={specialComponents}
            backend={backend}
            onClose={onClose}
            title={title}
            onBack={onBack}
            screenFlags={screenFlags}
            onBackdropClick={onBackdropClick}>
            {children}
        </SlideInDesktopOverlay>
    );
};

const ANIMATED_ACTIONS: (WireNavigationAction | undefined)[] = [WireNavigationAction.Push, WireNavigationAction.Pop];

function useSlideInTransitions(
    currentScreen: WireModalScreen | undefined,
    lastNavigation: WireNavigation | undefined
): PageTransitionInfo {
    const [lastNavigationKey, setLastAnimationKey] = React.useState<string | undefined>();

    const onAnimationComplete = React.useCallback(() => {
        setLastAnimationKey(lastNavigation?.priorScreen.key);
    }, [lastNavigation?.priorScreen.key]);

    // We'll only animate slide in, cause the overlay won't have a fixed height anymore.
    // TODO: Figure out if we still want to animate in overlay.
    const animationCompleted =
        lastNavigationKey === lastNavigation?.priorScreen.key ||
        !ANIMATED_ACTIONS.includes(lastNavigation?.navigationAction);

    const layers = React.useMemo<LayerTransition | undefined>(() => {
        if (!animationCompleted && currentScreen !== undefined) {
            switch (lastNavigation?.navigationAction) {
                case WireNavigationAction.Push: {
                    return {
                        bottom: {
                            screen: lastNavigation.priorScreen,
                            transition: undefined,
                            persist: false,
                        },
                        top: {
                            screen: currentScreen,
                            transition: PageTransitions.fadeIn,
                            persist: true,
                        },
                    };
                }
                case WireNavigationAction.Pop: {
                    return {
                        bottom: {
                            screen: currentScreen,
                            transition: PageTransitions.fadeIn,
                            persist: false,
                        },
                        top: {
                            screen: lastNavigation.priorScreen,
                            transition: PageTransitions.fadeOut,
                            persist: true,
                        },
                    };
                }
            }
        }

        return undefined;
    }, [animationCompleted, currentScreen, lastNavigation?.navigationAction, lastNavigation?.priorScreen]);

    return {
        layers,
        onAnimationComplete,
    };
}

interface SlideInContentLayerProps {
    readonly maxSize: ResponsiveSizeClass;
    readonly backend: WireBackendInterface;
    readonly layer: LayerInfo;
    readonly onAnimationComplete: () => void;
    readonly setIsOverlayScrolled: React.Dispatch<React.SetStateAction<boolean>>;
    readonly componentDependencies: PagesRendererComponentDependencies;
}

const SlideInContentLayer: React.VFC<SlideInContentLayerProps> = p => {
    const { layer, backend, onAnimationComplete, maxSize, setIsOverlayScrolled, componentDependencies } = p;

    const specialComponents = layer.screen.specialComponents.filter(isDefined) ?? [];

    const [finished, setFinished] = React.useState(false);

    const onAnimationCompleteImpl = React.useCallback(() => {
        onAnimationComplete();
        setFinished(true);
    }, [onAnimationComplete]);

    const fullLayerTransition = getFullLayerTransition(layer.transition);
    // This ugly thing is basically getDerivedStateFromProps
    if (layer.persist && finished) {
        setFinished(false);
    }

    // Yeah, I know... But when this component goes away, we're no longer scrolled, so...
    React.useEffect(() => {
        return () => {
            setIsOverlayScrolled(false);
        };
    }, [setIsOverlayScrolled]);

    const onScroll: React.UIEventHandler<HTMLDivElement> = e => {
        if (e.currentTarget.scrollTop > 0) {
            setIsOverlayScrolled(true);
        } else {
            setIsOverlayScrolled(false);
        }
    };

    const components = useScreenComponents(layer.screen);

    const fixedHeight = useSlideInHeight();

    const portalBaseName = usePortalBaseName();
    const portalId = createSlideInPortalIdFromKey(portalBaseName, layer.screen.key);

    const scrollRef = React.useRef<HTMLDivElement | null>(null);
    if (finished) {
        return null;
    }

    const isScannerLayer = layer.screen.components.some(c => c?.kind === WireComponentKind.InlineScanner);

    const isVoiceEntry = layer.screen.flags.some(f => f === WireScreenFlag.IsVoiceEntry);
    const shouldHideSpecialComponents = isVoiceEntry;

    // Defaulting to top layer since it's the only one in overlays
    return (
        <LayerPositionProvider position="top">
            <IsInsideOverlayProvider isInsideOverlay={true}>
                <TransitionProvider transition={layer.transition}>
                    <PortalIdContext.Provider value={portalId}>
                        <ScreenSpecialCasesProvider components={components}>
                            <ScrollRefProvider scrollRef={scrollRef}>
                                <div
                                    ref={scrollRef}
                                    css={css`
                                        .sheet & {
                                            max-height: min(640px, 80vh);

                                            ::-webkit-scrollbar {
                                                display: none;
                                            }
                                            scrollbar-width: none;
                                        }

                                        max-height: 100%;
                                    `}
                                    className={classNames(fullLayerTransition, "slide-in-layer")}
                                    onAnimationEnd={onAnimationCompleteImpl}
                                    tw="w-full overflow-y-auto bg-bg-front"
                                    onScroll={onScroll}
                                    style={{ gridRowStart: 1, gridColumnStart: 1, height: fixedHeight }}>
                                    <ResponsiveProvider
                                        maxSize={maxSize}
                                        id="overlay-root"
                                        tw="w-full min-h-full flex flex-col relative">
                                        <div
                                            id="overlay-scroll"
                                            tw="all-child:shrink-0 flex flex-col relative pb-12 flex-grow [&.voice-entry-layer]:pb-4"
                                            className={classNames(
                                                isScannerLayer && "code-scanner-layer",
                                                isVoiceEntry && "voice-entry-layer"
                                            )}
                                            css={css`
                                                &.code-scanner-layer {
                                                    ${tw`pb-0`}

                                                    .component-root:first-of-type {
                                                        &.code-scanner-container {
                                                            ${tw`mt-0 [min-height:400px]`}
                                                        }
                                                    }
                                                }
                                            `}>
                                            <PagesComponentRenderer
                                                components={components}
                                                backend={backend}
                                                componentDependencies={componentDependencies}
                                            />
                                        </div>
                                        {!shouldHideSpecialComponents && (
                                            <SpecialGroupComponents
                                                specialComponents={specialComponents}
                                                backend={backend}
                                            />
                                        )}
                                    </ResponsiveProvider>
                                </div>
                                <FloatingPortalLayer maxSize={maxSize} />
                            </ScrollRefProvider>
                        </ScreenSpecialCasesProvider>
                    </PortalIdContext.Provider>
                </TransitionProvider>
            </IsInsideOverlayProvider>
        </LayerPositionProvider>
    );
};

const entryTransition: Transition = {
    type: "spring",
    mass: 1,
    stiffness: 850,
    damping: 55,
};

const exitTransition: Transition = {
    duration: 0.2,
};

const THRESHOLD = 40;

interface SpecialComponentButtonProps {
    readonly component: WireComponent | undefined;
    readonly backend: WireBackendInterface;
    readonly kind: "submit" | "cancel";
}

const SpecialComponentButton: React.FC<SpecialComponentButtonProps> = p => {
    const { component, backend, kind } = p;

    // need variant desktop / mobile so we can render the correct UI here
    // mobile size mini and text-sm desktop size sm and text-base

    const buttonComponent = component as WireButtonComponent;
    if (buttonComponent === undefined || buttonComponent.kind !== WireComponentKind.Button) {
        // Span for layout purposes
        return <span />;
    }

    const buttonProps = makeSingleActionSpreadProps(buttonComponent.action, backend);

    const className = kind === "submit" ? "special-component-submit" : "special-component-cancel";
    const appearence = kind === "submit" ? UIButtonAppearance.Filled : UIButtonAppearance.Transparent;

    return (
        <WireButton
            {...buttonProps}
            className={className}
            tw="max-w-fit text-builder-base [&.special-component-submit]:justify-self-end [&.special-component-cancel]:justify-self-start"
            appearance={appearence}
            size="sm">
            {buttonComponent.title}
        </WireButton>
    );
};

const SheetOverlay: React.FC<React.PropsWithChildren<SlideInOverlayProps>> = p => {
    const { children, onClose, title, onBack, isOverlayScrolled, specialComponents, screenFlags, backend } = p;
    // Yes, this is how it works in all the other cases. First one is submit, second one is clear.
    const [submit, clear] = specialComponents;

    const maybeCloseOnDrag = (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        const submitButton = submit as WireButtonComponent;
        const clearButton = clear as WireButtonComponent;

        const submitProps = makeSingleActionSpreadProps(submitButton.action, backend);
        const clearProps = makeSingleActionSpreadProps(clearButton.action, backend);

        if (submitProps.disabled && clearProps.disabled) {
            return;
        }

        if (info.offset.y > THRESHOLD) {
            onClose();
        }
    };

    const { headerRef, height } = useOverlayHeaderHeight();

    const isVoiceEntry = screenFlags.includes(WireScreenFlag.IsVoiceEntry);

    const leftButton = isVoiceEntry ? (
        <SpecialComponentButton component={clear} backend={backend} kind="cancel" />
    ) : (
        <OverlayHeaderBackButton onBack={onBack} />
    );

    const rightButton = isVoiceEntry ? (
        <SpecialComponentButton component={submit} backend={backend} kind="submit" />
    ) : (
        <OverlayHeaderCloseButton onClose={onClose} />
    );

    return (
        <m.div
            style={{ willChange: "transform, opacity" }}
            initial={{ opacity: 0, y: "100%" }}
            animate={{ opacity: 1, y: 0, transition: entryTransition }}
            exit={{ opacity: 0, y: "100%", transition: exitTransition }}
            drag="y"
            dragSnapToOrigin
            dragConstraints={{ top: 0, bottom: 0 }}
            dragElastic={{ top: 0, bottom: 1 }}
            dragMomentum={false}
            onDragEnd={maybeCloseOnDrag}
            tw="absolute z-overlay-layer w-full bottom-0">
            <m.div
                transition={entryTransition}
                layout
                style={{ willChange: "transform, opacity" }}
                tw="[border-top-right-radius:20px] [border-top-left-radius:20px] overflow-hidden shrink flex
                    flex-col w-full shadow-2xl-dark bg-bg-front after:(content-[''] [border-top-right-radius:20px] [border-top-left-radius:20px] rounded-3xl rounded-b-none absolute inset-0 
                    ring-1 ring-inset ring-w10A pointer-events-none z-overlay-layer isolate)">
                <div className="sidebar-overlay" tw="overflow-hidden w-full shadow-2xl-dark relative">
                    <OverlayHeader
                        className={isVoiceEntry ? "voice-entry" : undefined}
                        tw="[&.voice-entry]:[grid-template-columns:1fr auto 1fr]"
                        isOverlayScrolled={isVoiceEntry || isOverlayScrolled}
                        isDraggable={true}
                        title={title}
                        headerRef={headerRef}
                        leftButton={leftButton}
                        rightButton={rightButton}
                    />
                    <m.div
                        className="sheet" // We use this to setting the max-height in mobile.
                        layout="position"
                        transition={exitTransition}
                        style={{ willChange: "transform, opacity", marginTop: height }}>
                        {children}
                    </m.div>
                </div>
            </m.div>
        </m.div>
    );
};

const SlideInDesktopOverlay: React.FC<React.PropsWithChildren<BaseSlideInOverlayProps>> = p => {
    const {
        children,
        onClose,
        title,
        onBack,
        isOverlayScrolled,
        onBackdropClick,
        screenFlags,
        backend,
        specialComponents,
    } = p;
    const theme = useWireAppTheme();
    const { headerRef, height } = useOverlayHeaderHeight();
    const colorTheme = theme.bgFront;

    const [submit, clear] = specialComponents;

    const isVoiceEntry = screenFlags.includes(WireScreenFlag.IsVoiceEntry);

    const leftButton = isVoiceEntry ? (
        <SpecialComponentButton component={clear} backend={backend} kind="cancel" />
    ) : (
        <OverlayHeaderBackButton onBack={onBack} />
    );

    const rightButton = isVoiceEntry ? (
        <SpecialComponentButton component={submit} backend={backend} kind="submit" />
    ) : (
        <OverlayHeaderCloseButton onClose={onClose} />
    );

    return (
        <div
            tw="absolute z-overlay-layer right-2 top-2 bottom-2"
            style={{ width: "calc(100% - 16px)" }}
            onClick={onBackdropClick}>
            <m.div
                onClick={e => e.stopPropagation()}
                style={{ willChange: "opacity, transform" }}
                initial={{ opacity: 0, x: "80%" }}
                animate={{ opacity: 1, x: 0, transition: entryTransition }}
                exit={{ opacity: 0, x: "80%", transition: exitTransition }}
                tw="rounded-xl overflow-hidden shrink ml-auto flex flex-col h-full [min-width:420px] w-1/3
                    shadow-2xl-dark [box-sizing:border-box]">
                <div
                    style={{ backgroundColor: colorTheme }}
                    className="sidebar-overlay"
                    tw="overflow-hidden h-full shadow-2xl-dark flex flex-col
                    after:(content-[''] rounded-xl absolute inset-0 ring-1 ring-inset ring-w05A pointer-events-none z-overlay-layer isolate)">
                    <OverlayHeader
                        headerRef={headerRef}
                        isOverlayScrolled={isOverlayScrolled}
                        isDraggable={false}
                        title={title}
                        leftButton={leftButton}
                        rightButton={rightButton}
                    />
                    <div tw="relative" style={{ height: `calc(100% - ${height})`, marginTop: height }}>
                        {children}
                    </div>
                </div>
            </m.div>
        </div>
    );
};
