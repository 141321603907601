import {
    getNonHiddenColumns,
    areTableNamesEqual,
    getTableName,
    isBigTableOrExternal,
    isMultiRelationType,
    isSingleRelationType,
    isTableWritable,
    type ColumnType,
    type Description,
    type TableGlideType,
    type SchemaInspector,
} from "@glide/type-schema";
import type { BasePrimitiveValue } from "@glide/data-types";
import { MutatingScreenKind, PropertyKind } from "@glide/app-description";
import { defined, assert } from "@glideapps/ts-necessities";
import { isDefined } from "@glide/support";
import {
    type ColumnFilterSpec,
    getPrimitiveOrPrimitiveArrayNonHiddenColumnsSpec,
    getStringTypeOrStringTypeArrayColumnsSpec,
    getPrimitiveColumnsSpec,
    applyColumnFilterSpec,
} from "../column-filter-spec";
import { getRelationTableRef } from "../schema-utils";
import { imageProperties, isImageType } from "./description-utils";
import {
    type ColumnPropertyDescriptorCase,
    type ColumnTypePredicate,
    type EnumPropertyDescriptorCase,
    type IsEditedInApp,
    type JSONPathPropertyDescriptorCase,
    type MultiCasePropertyDescriptor,
    type NumberPropertyDescriptorCase,
    type PropertyConfiguratorKind,
    type PropertyDescriptor,
    type PropertyDescriptorCase,
    type PropertyLabel,
    type PropertyTableGetter,
    type PropertyVisitor,
    type SpecialValuePropertyDescriptorCase,
    type StringPropertyDescriptorCase,
    type Subcomponent,
    type TablePropertyDescriptorCase,
    type TableViewPropertyDescriptorCase,
    type WhenPredicate,
    componentErrorAndLinkForReference,
    RequiredKind,
} from "./lib";
import { ColumnPropertyFlag, ColumnPropertyHandler, InlineComputationPropertyHandler } from "./property-handlers";
import { type SuperPropertySection, PropertySection } from "./property-sections";
import type { PropertySource } from "./property-source";

export function doesMutatingScreenKindHaveInputRow(mutatingScreenKind: MutatingScreenKind | undefined): boolean {
    return mutatingScreenKind !== MutatingScreenKind.AddScreen;
}

export interface ColumnPropertyDescriptorFlags {
    readonly preferredNames?: readonly string[]; // default: `[]`
    readonly preferredType?: ColumnTypePredicate; // default: `"string"`
    readonly ignoreColumnsFromProperties?: readonly string[]; // default: `[]`
    readonly isDefaultCaption?: boolean; // default: `false`
    readonly editable?: boolean; // default: `true`
    readonly searchable?: boolean; // default: `true`
    readonly isEditedInApp?: IsEditedInApp; // default: `false`
    readonly isAddedInApp?: boolean; // default: `false`
    readonly applyFormat?: boolean; // default: `true`
    readonly allowUserProfileColumns?: boolean; // default: `true`, unless `isEditedInApp` is set
    readonly forFilteringRows?: boolean; // default: `false`
    readonly emptyByDefault?: boolean; // default: `false`
    readonly propertySection?: SuperPropertySection; // default: `PropertySection.Data`
    // Don't allow picking values from the row to be added in an Add screen.
    // Only used for default value properties right now, which can't come from
    // the row to be added.
    readonly needsExistingValue?: boolean; // default: `false`
    readonly columnFilter?: ColumnFilterSpec; // default: `getPrimitiveColumns` implementation
    // Only used in properties where the column comes from an indirection, such
    // as with Inline Lists or Relations.
    readonly getIndirectTable?: PropertyTableGetter; // default: `undefined`
    readonly helpText?: string;
    readonly emptyWarningText?: string;
}

interface NumberPropertyDescriptorFlags extends ColumnPropertyDescriptorFlags {
    readonly columnFirst?: boolean; // default: `false`
}

export interface TextPropertyDescriptorFlags extends ColumnPropertyDescriptorFlags {
    readonly textMenuLabel?: string; // default: `"Custom"`
    readonly isCaption?: string; // default: `undefined`
    readonly isMultiLine?: boolean; // default: `false`
    readonly isImageURL?: boolean; // default: `false`
    readonly subcomponent?: Subcomponent;
    // This is a hack for super old properties.  Back then each property was
    // either always a string (custom value) or a columns.  When we changed it
    // so that the user can pick between string and column we needed a way to
    // interpret the existing values correctly, since they were both
    // represented as strings. columnFirst means that if the value doesn’t
    // specify whether it’s a string or a column, it’s a column.
    readonly columnFirst?: boolean; // default: `false`
    readonly defaultValue?: string; // default: `undefined`
    readonly allowLiteral?: boolean; // default: `true`
    readonly allowColumn?: boolean; // default: `true`
    readonly syntaxMode?: string; // default: `undefined`
    readonly placeholder?: string;
    readonly checkForValidJSON?: boolean; // default: `false`
    readonly showInlineWarning?: boolean; // default: `false`
    readonly withSecretConstants?: boolean; // default `false`
}

interface ImagePropertyDescriptorFlags extends ColumnPropertyDescriptorFlags {
    readonly allowLiteral?: boolean; // default: `true`
}

interface DatePropertyDescriptorFlags extends ColumnPropertyDescriptorFlags {
    readonly allowLiteral?: boolean; // default: `true`
}

export function makeColumnCase(
    required: boolean,
    flags: ColumnPropertyDescriptorFlags,
    defaultPreferredType: ColumnTypePredicate
): ColumnPropertyDescriptorCase {
    return {
        kind: PropertyKind.Column,
        required,
        isEditedInApp: flags.isEditedInApp ?? false,
        isAddedInApp: flags.isAddedInApp ?? false,
        editable: flags.editable ?? true,
        searchable: flags.searchable ?? true,
        columnFilter: flags.columnFilter ?? getPrimitiveColumnsSpec,
        preferredNames: flags.preferredNames ?? [],
        preferredType: flags.preferredType ?? defaultPreferredType,
        isDefaultCaption: flags.isDefaultCaption ?? false,
        applyFormat: flags.applyFormat ?? true,
        emptyByDefault: flags.emptyByDefault ?? false,
        needsExistingValue: flags.needsExistingValue ?? false,
        // User profile columns are allowed if the column is not edited in the
        // app, unless they are explicitly disallowed.
        allowUserProfileColumns:
            flags.allowUserProfileColumns === true ||
            (flags.isEditedInApp !== true && flags.allowUserProfileColumns !== false),
        forFilteringRows: flags.forFilteringRows === true,
        ignoreColumnsFromProperties: flags.ignoreColumnsFromProperties ?? [],
        getIndirectTable: flags.getIndirectTable,
    };
}

type ColumnOrLiteralPropertyDescriptorCase<P extends BasePrimitiveValue> =
    | StringPropertyDescriptorCase
    | NumberPropertyDescriptorCase
    | EnumPropertyDescriptorCase<P>
    | SpecialValuePropertyDescriptorCase
    | JSONPathPropertyDescriptorCase;

export function makeColumnOrLiteralPropertyDescriptor<P extends BasePrimitiveValue>(
    property: string | PropertySource,
    label: PropertyLabel,
    mutatingScreenKind: MutatingScreenKind | undefined,
    propertySection: SuperPropertySection,
    subcomponent: Subcomponent | undefined,
    helpText: string | undefined,
    emptyWarningText: string | undefined,
    columnFirst: boolean,
    columnCase: ColumnPropertyDescriptorCase | undefined,
    otherCases: readonly ColumnOrLiteralPropertyDescriptorCase<P>[],
    when?: WhenPredicate<any, any>,
    builderOnly?: boolean
): PropertyDescriptor | undefined {
    if (typeof property === "string") {
        property = { name: property };
    }

    const propertyDescriptorBase = {
        property,
        label,
        section: propertySection,
        subcomponent,
        helpText,
        emptyWarningText,
        when,
        builderOnly,
    };

    // The image property in the Image component needs this clause.  It's edited
    // in the app, so in the Add screen it has to have a column picker, too.
    // FIXME: This is really a hack, where we special-cased the Image component,
    // and then we special-cased the default value case.
    if (
        columnCase === undefined ||
        (columnCase.needsExistingValue !== true &&
            mutatingScreenKind === MutatingScreenKind.AddScreen &&
            // We do explicitly allow binding to user profile columns in Add
            // screens.
            // https://github.com/quicktype/glide/issues/14376
            columnCase.allowUserProfileColumns !== true &&
            columnCase.isEditedInApp !== true)
    ) {
        if (otherCases.length === 0) {
            return undefined;
        }

        if (otherCases.length === 1) {
            const descr: PropertyDescriptor = {
                ...propertyDescriptorBase,
                ...otherCases[0],
            };
            return descr;
        } else {
            return {
                ...propertyDescriptorBase,
                cases: otherCases,
            };
        }
    }

    if (otherCases.length === 0) {
        return {
            ...propertyDescriptorBase,
            ...columnCase,
        };
    } else {
        return {
            ...propertyDescriptorBase,
            cases: columnFirst ? [columnCase, ...otherCases] : [...otherCases, columnCase],
        };
    }
}

function isColumnCaseFirst(
    flags: TextPropertyDescriptorFlags,
    mutatingScreenKind: MutatingScreenKind | undefined
): boolean {
    return (
        (flags.columnFirst ?? false) &&
        (flags.isEditedInApp === true || doesMutatingScreenKindHaveInputRow(mutatingScreenKind))
    );
}

export function makeInlineTemplatePropertyDescriptor(
    propertyName: string,
    label: string,
    placeholder: string,
    required: boolean,
    useTemplate: "withoutLabel" | "withLabel" | "withLabelAndFullWidth" | boolean | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined,
    flags: TextPropertyDescriptorFlags,
    when?: WhenPredicate<any, any>,
    specialValueCase?: SpecialValuePropertyDescriptorCase,
    allowUpload?: boolean,
    builderOnly?: boolean
): PropertyDescriptor {
    const propertySection = flags.propertySection ?? PropertySection.Data;

    const columnCase = makeColumnCase(required ?? false, flags, "string");

    const stringCase: StringPropertyDescriptorCase = {
        kind: PropertyKind.String,
        required,
        placeholder: placeholder,
        defaultValue: flags.defaultValue,
        isCaption: flags.isCaption,
    };

    const templateCase = new InlineComputationPropertyHandler(
        propertyName,
        label,
        propertySection,
        applyColumnFilterSpec(flags.columnFilter ?? getPrimitiveColumnsSpec),
        flags.getIndirectTable,
        undefined,
        {
            withLabel: useTemplate === "withLabel",
            defaultValue: flags.defaultValue,
            fullWidth: useTemplate === "withLabelAndFullWidth",
            allowUpload,
            isCaption: flags.isCaption,
        }
    );

    const cases: PropertyDescriptorCase[] = [templateCase, stringCase];
    if (specialValueCase !== undefined) {
        cases.push(specialValueCase);
    }
    const columnFirst = isColumnCaseFirst(flags, mutatingScreenKind);
    if (columnFirst) {
        cases.unshift(columnCase);
    } else {
        cases.push(columnCase);
    }

    const multi: MultiCasePropertyDescriptor = {
        property: { name: propertyName },
        label,
        cases,
        helpText: flags.helpText,
        section: flags.propertySection ?? PropertySection.Data,
        when,
        builderOnly,
    };

    return multi;
}

export function makeTextPropertyDescriptor(
    property: string | PropertySource,
    label: PropertyLabel,
    placeholder: string,
    required: boolean,
    mutatingScreenKind: MutatingScreenKind | undefined,
    flags: TextPropertyDescriptorFlags,
    otherCases: readonly (SpecialValuePropertyDescriptorCase | JSONPathPropertyDescriptorCase)[] = [],
    when?: WhenPredicate<any, any>,
    builderOnly?: boolean
): PropertyDescriptor {
    const cases: ColumnOrLiteralPropertyDescriptorCase<BasePrimitiveValue>[] = [];

    if (flags.allowLiteral !== false && flags.isEditedInApp !== true) {
        const stringCase: StringPropertyDescriptorCase = {
            kind: PropertyKind.String,
            required,
            isCaption: flags.isCaption,
            placeholder,
            isMultiLine: flags.isMultiLine,
            isImageURL: flags.isImageURL,
            menuLabel: flags.textMenuLabel ?? "Custom",
            defaultValue: flags.defaultValue ?? (flags.emptyByDefault === true ? "" : undefined),
            syntaxMode: flags.syntaxMode,
            emptyWarningText: flags.emptyWarningText,
            checkForValidJSON: flags.checkForValidJSON ?? false,
            showInlineWarning: flags.showInlineWarning ?? false,
            isSecret: flags.withSecretConstants ?? false,
        };
        cases.push(stringCase);
    }

    cases.push(...otherCases);
    const columnFirst = isColumnCaseFirst(flags, mutatingScreenKind);
    return defined(
        makeColumnOrLiteralPropertyDescriptor(
            property,
            label,
            mutatingScreenKind,
            flags.propertySection ?? PropertySection.Data,
            flags.subcomponent,
            flags.helpText,
            flags.emptyWarningText,
            // When auto-assigning properties we always use the first case, so
            // if we don't have input columns then we shouldn't have columns
            // be the first case.
            columnFirst,
            flags.allowColumn !== false ? makeColumnCase(required, flags, "string") : undefined,
            cases,
            when,
            builderOnly
        )
    );
}

export function makeNumberPropertyDescriptor(
    propertyName: string,
    label: PropertyLabel,
    placeholder: string,
    required: RequiredKind,
    defaultValue: number,
    mutatingScreenKind: MutatingScreenKind | undefined,
    flags: NumberPropertyDescriptorFlags,
    when?: WhenPredicate<any, any>,
    minValue?: number
): PropertyDescriptor {
    const columnCase = makeColumnCase(required === RequiredKind.Required, flags, "number");
    const numberCase: NumberPropertyDescriptorCase = {
        kind: PropertyKind.Number,
        required,
        placeholder,
        defaultValue,
        minValue,
    };

    return defined(
        makeColumnOrLiteralPropertyDescriptor(
            propertyName,
            label,
            mutatingScreenKind,
            flags.propertySection ?? PropertySection.Data,
            undefined,
            flags.helpText,
            flags.emptyWarningText,
            flags.columnFirst ?? false,
            columnCase,
            [numberCase],
            when
        )
    );
}

export function makeImagePropertyDescriptor(
    propertyName: string,
    label: string,
    customMenuLabel: string,
    customPlaceholder: string,
    required: boolean,
    emptyByDefault: boolean | undefined,
    isEditedInApp: boolean,
    withEmoji: boolean,
    withArray: boolean,
    getIndirectTable: PropertyTableGetter | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined,
    flags: ImagePropertyDescriptorFlags,
    propertySection?: SuperPropertySection,
    subcomponent?: Subcomponent,
    when?: WhenPredicate<any, any>,
    useTemplate?: "withoutLabel" | "withLabel" | "withLabelAndFullWidth" | boolean | undefined
): PropertyDescriptor {
    const textPropertyDescriptorFlags: TextPropertyDescriptorFlags = {
        ...flags,
        textMenuLabel: customMenuLabel,
        isEditedInApp,
        searchable: false,
        isImageURL: true,
        emptyByDefault,
        columnFilter: withArray ? getStringTypeOrStringTypeArrayColumnsSpec : getPrimitiveColumnsSpec,
        preferredNames: imageProperties,
        preferredType: !withEmoji && !withArray ? "image-uri" : t => isImageType(t, withEmoji, withArray),
        columnFirst: true,
        getIndirectTable,
        propertySection,
        subcomponent,
    };
    if (useTemplate !== undefined && isDefined(useTemplate) && useTemplate !== false) {
        return makeInlineTemplatePropertyDescriptor(
            propertyName,
            label,
            customPlaceholder,
            required,
            useTemplate,
            mutatingScreenKind,
            textPropertyDescriptorFlags,
            when,
            undefined,
            true
        );
    }

    return makeTextPropertyDescriptor(
        propertyName,
        label,
        customPlaceholder,
        required,
        mutatingScreenKind,
        textPropertyDescriptorFlags,
        undefined,
        when
    );
}

export function makeDateTimePropertyDescriptor<P extends BasePrimitiveValue>(
    propertyName: string,
    label: PropertyLabel,
    placeholder: string,
    required: RequiredKind,
    mutatingScreenKind: MutatingScreenKind | undefined,
    flags: DatePropertyDescriptorFlags,
    otherCases: readonly ColumnOrLiteralPropertyDescriptorCase<P>[] = [],
    when?: WhenPredicate<any, any>
): PropertyDescriptor {
    const isRequired = required === RequiredKind.Required;
    const cases: ColumnOrLiteralPropertyDescriptorCase<P>[] = [];
    if (flags.allowLiteral !== false && flags.isEditedInApp !== true) {
        const stringCase: StringPropertyDescriptorCase = {
            kind: PropertyKind.String,
            required: isRequired,
            placeholder,
        };
        cases.push(stringCase);
    }
    cases.push(...otherCases);
    return defined(
        makeColumnOrLiteralPropertyDescriptor(
            propertyName,
            label,
            mutatingScreenKind,
            flags.propertySection ?? PropertySection.Data,
            undefined,
            flags.helpText,
            flags.emptyWarningText,
            true,
            makeColumnCase(isRequired, flags, flags.preferredType ?? "date-time"),
            cases,
            when
        )
    );
}

export function makeDynamicFilterColumnPropertyHandler(
    getIndirectTable: PropertyTableGetter | undefined,
    label: string = "Show filter",
    section: SuperPropertySection = PropertySection.Search,
    additionalFlags: readonly ColumnPropertyFlag[] = []
) {
    return new ColumnPropertyHandler(
        "dynamicFilterColumn",
        label,
        [
            ColumnPropertyFlag.Editable,
            ColumnPropertyFlag.EmptyByDefault,
            ColumnPropertyFlag.Optional,
            ColumnPropertyFlag.ForFilteringRows,
            ...additionalFlags,
        ],
        getIndirectTable,
        ["category", "kind"],
        getPrimitiveOrPrimitiveArrayNonHiddenColumnsSpec,
        "string",
        section
    );
}

export const dynamicFilterColumnPropertyHandler = makeDynamicFilterColumnPropertyHandler(undefined);

export function makeJSONPathPropertyDescriptor(
    property: string | PropertySource,
    label: PropertyLabel,
    placeholder: string,
    required: boolean,
    mutatingScreenKind: MutatingScreenKind | undefined,
    flags: TextPropertyDescriptorFlags,
    valueFromProperty: string,
    actionOrColumn: "action" | "column"
): PropertyDescriptor {
    const JSONPathCase: JSONPathPropertyDescriptorCase = {
        kind: PropertyKind.JSONPath,
        valueFromProperty: actionOrColumn === "action" ? `param_${valueFromProperty}` : valueFromProperty,
    };
    return makeTextPropertyDescriptor(property, label, placeholder, required, mutatingScreenKind, flags, [
        JSONPathCase,
    ]);
}

export function makeCustomConfiguratorPropertyDescriptor<TRootDesc extends Description>(
    kind: PropertyConfiguratorKind,
    section: SuperPropertySection,
    visitProperty: ((rootDesc: TRootDesc, visitor: PropertyVisitor) => void) | undefined
): MultiCasePropertyDescriptor {
    return {
        // Unfortunately we have to bind this to a property, even
        // though that property doesn't really exist.
        property: { name: "THIS IS NOT A REAL PROPERTY" },
        label: "THE COMPONENT SHOULD DO ITS OWN LABEL",
        section,
        configuratorKind: kind,
        visitProperty: visitProperty as MultiCasePropertyDescriptor["visitProperty"],
        cases: [],
    };
}

export interface TableAllowedPropertyOptions {
    readonly allowQueryableTables: boolean;
    readonly allowUserProfileTableAndRow: boolean;
    // `forWriting` allows picking "this row" in mutating screens, too, if
    // it's a writable table.
    readonly forWriting: boolean;
}

interface TableOrRelationPropertyOptions extends TableAllowedPropertyOptions {
    readonly allowTables: boolean;
    readonly allowSingleRelations: boolean;
    // Only relevant if `allowSingleRelations` is set
    readonly preferFullRow: boolean;
    readonly allowMultiRelations: boolean;
    readonly sourceIsDefaultCaption: boolean;
    readonly allowRewrite: boolean;
    readonly allowUserProfile: boolean;
    readonly getIndirectTable?: PropertyTableGetter;
}

export function isTableAllowedForProperty(
    schema: SchemaInspector,
    t: TableGlideType,
    forMultipleRows: boolean,
    { allowQueryableTables, allowUserProfileTableAndRow, forWriting }: TableAllowedPropertyOptions
): boolean {
    if (forMultipleRows) {
        // For single rows (like for pushing a screen) we already have the row
        // in memory, so using a queryable table is never a problem, but for
        // multiple rows, where we might have to do a query, we might not want
        // to allow them.
        if (isBigTableOrExternal(t)) {
            if (!allowQueryableTables) return false;
        }
    }

    if (!allowUserProfileTableAndRow) {
        const userProfileTableName = schema.userProfileTableInfo?.tableName;
        if (userProfileTableName !== undefined) {
            if (areTableNamesEqual(userProfileTableName, getTableName(t))) return false;
        }
    }

    if (forWriting) {
        if (!isTableWritable(t)) return false;
    }

    return true;
}

function isColumnTypeAllowedForRelation(
    t: ColumnType,
    schema: SchemaInspector,
    opts: TableOrRelationPropertyOptions
): boolean {
    const { allowMultiRelations, allowSingleRelations, forWriting } = opts;

    if (isMultiRelationType(t)) {
        if (!allowMultiRelations) return false;
        const itemsTable = schema.findTable(t.items);
        if (itemsTable === undefined || !isTableAllowedForProperty(schema, itemsTable, true, opts)) return false;
    } else if (isSingleRelationType(t)) {
        if (!allowSingleRelations) return false;
    } else {
        return false;
    }

    if (forWriting) {
        const tableRef = getRelationTableRef(t);
        if (tableRef === undefined) return false;
        const table = schema.findTable(tableRef);
        if (table === undefined) return false;
        return isTableWritable(table);
    }

    return true;
}

export function makeTableOrRelationPropertyDescriptor(
    mutatingScreenKind: MutatingScreenKind | undefined,
    label: string,
    propertySection: SuperPropertySection,
    opts: TableOrRelationPropertyOptions,
    propertySource: PropertySource,
    required: boolean
): PropertyDescriptor {
    const {
        allowTables,
        allowSingleRelations,
        allowMultiRelations,
        sourceIsDefaultCaption,
        allowRewrite,
        allowUserProfile,
        forWriting,
        preferFullRow,
        getIndirectTable,
    } = opts;
    // If we're writing then we use the row/columns of the output row.
    // Otherwise we need an input row, which some screens don't have.
    const haveRowAndColumns = forWriting || doesMutatingScreenKindHaveInputRow(mutatingScreenKind);
    const withColumns = (allowSingleRelations || allowMultiRelations) && haveRowAndColumns;
    const tableCase: TablePropertyDescriptorCase = {
        kind: PropertyKind.Table,
        required,
        isDefaultCaption: sourceIsDefaultCaption,
        getAllowedTables: (_desc, schema) =>
            schema.schema.tables.filter(t => isTableAllowedForProperty(schema, t, true, opts)),
    };
    const base = {
        property: propertySource as PropertySource,
        label,
        section: propertySection,
        doNotRewrite: !allowRewrite,
    };
    const cases: PropertyDescriptorCase[] = [];
    if (withColumns) {
        const columnCase: ColumnPropertyDescriptorCase = {
            kind: PropertyKind.Column,
            required,
            editable: true,
            searchable: false,
            isDefaultCaption: sourceIsDefaultCaption,
            getIndirectTable,
            allowFullRow: (t, schema) => {
                if (!isTableAllowedForProperty(schema, t, false, opts)) return false;
                return allowSingleRelations && haveRowAndColumns ? (preferFullRow ? "preferred" : true) : false;
            },
            allowUserProfileColumns: allowUserProfile,
            columnFilter: {
                getCandidateColumns: t => t.columns,
                columnTypeIsAllowed: (t, s) => isColumnTypeAllowedForRelation(t, s, opts),
            },
            getEmptyCase: t => {
                const { errorMessage, linkURL } = componentErrorAndLinkForReference(t.input, allowMultiRelations);
                return {
                    label: errorMessage,
                    linkURL,
                };
            },
        };
        cases.push(columnCase);
    }
    if (allowTables) {
        cases.push(tableCase);
    }
    assert(cases.length > 0);
    if (cases.length === 1) {
        const singleCase = cases[0];
        assert(singleCase.kind === PropertyKind.Column || singleCase.kind === PropertyKind.Table);
        return { ...base, ...(singleCase as ColumnPropertyDescriptorCase | TablePropertyDescriptorCase) };
    } else {
        return { ...base, cases };
    }
}

export function makeTableViewPropertyDescriptor(
    mutatingScreenKind: MutatingScreenKind | undefined,
    label: string,
    propertySection: SuperPropertySection,
    propertySource: PropertySource,
    required: boolean
): PropertyDescriptor {
    const tableOpts: TableOrRelationPropertyOptions = {
        allowMultiRelations: true,
        // FIXME: Shouldn't we allow queryable tables as inputs to plugins?
        allowQueryableTables: false,
        allowTables: true,
        allowRewrite: true,
        allowSingleRelations: true,
        allowUserProfile: true,
        allowUserProfileTableAndRow: true,
        preferFullRow: true,
        sourceIsDefaultCaption: true,
        // We don't yet support writing through a table view.
        forWriting: false,
    };

    const haveRowAndColumns = doesMutatingScreenKindHaveInputRow(mutatingScreenKind);
    const tableViewCase: TableViewPropertyDescriptorCase = {
        kind: PropertyKind.TableView,
        required,
        getAllowedTables: (_desc, schema) =>
            schema.schema.tables.filter(t => isTableAllowedForProperty(schema, t, true, tableOpts)),
        columnFilter: {
            getCandidateColumns: t => (haveRowAndColumns ? getNonHiddenColumns(t) : []),
            columnTypeIsAllowed: (t, s) => isColumnTypeAllowedForRelation(t, s, tableOpts),
        },
    };

    return {
        property: propertySource as PropertySource,
        label,
        section: propertySection,
        doNotRewrite: true,
        cases: [tableViewCase],
    };
}

export function makeSingleRelationOrThisItemPropertyDescriptor(
    propertyName: string,
    label: string,
    section: SuperPropertySection,
    forWriting: boolean,
    withUserProfileRow: boolean
): PropertyDescriptor {
    const flags = [ColumnPropertyFlag.Editable, ColumnPropertyFlag.Required, ColumnPropertyFlag.AllowFullRow];
    if (withUserProfileRow) {
        flags.push(ColumnPropertyFlag.AllowUserProfileColumns);
    }
    if (!forWriting) {
        flags.push(ColumnPropertyFlag.AllowFullRowReadonly);
    }
    return new ColumnPropertyHandler(
        propertyName,
        label,
        flags,
        undefined,
        undefined,
        {
            getCandidateColumns: t => getNonHiddenColumns(t),
            columnTypeIsAllowed: (t, s) => {
                if (!isSingleRelationType(t)) return false;
                if (!forWriting) return true;
                const table = s.findTable(t);
                return table !== undefined && isTableWritable(table);
            },
        },
        "table-ref",
        section
    );
}
