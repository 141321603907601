import type {
    AppUserAuthenticator,
    AppUserChangedCallback,
    OAuth2TokenAuth,
    ResponseStatus,
} from "@glide/common-core/dist/js/components/types";
import type { AppLoginTokenContainer } from "@glide/common-core/dist/js/integration-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import {
    undefinedIfEmptyString,
    type JSONObject,
    normalizeEmailAddress,
    isResponseOK,
    logError,
    parseAnonymousUserID,
} from "@glide/support";
import { definedMap } from "collection-utils";
import {
    type LocalEmailPinCredentials,
    getEmailPinCredentialsFromStorage,
    getLoginTokenFromStorage,
    setEmailPinCredentialsFromStorage,
    setLoginTokenFromStorage,
} from "../utils/existing-user";
import {
    type AppSnapshotURLs,
    type GetAppUserForAuthenticatedUserBody,
    asAppLoginTokenContainer,
    type GetCustomTokenForAppBody,
} from "@glide/common-core";
import { decodeTypeSchema } from "@glide/type-schema";
import { getDeviceID } from "@glide/common-core/dist/js/device-id";
import { hasOwnProperty } from "@glideapps/ts-necessities";
import { getVirtualEmailAddress } from "../utils/get-virtual-email-address";

interface PlayAuthenticatedUser {
    readonly appUserID: string;
    readonly realEmail: string;
    readonly virtualEmail: string;
    readonly userProfileRow: JSONObject | undefined;
    readonly loginToken: AppLoginTokenContainer | undefined;
}

interface PreAuthedUserCredentials {
    emailPinCredentials: LocalEmailPinCredentials | undefined;
    loginToken: AppLoginTokenContainer | undefined;
}

export interface RootAuthenticator {
    setAuthenticatedUser(authedUser: PlayAuthenticatedUser): void;
    getPreAuthedUserCredential(): PreAuthedUserCredentials;
    signInFromPreAuthedCredentials(
        email: string,
        password: string | undefined,
        token: AppLoginTokenContainer | undefined
    ): Promise<boolean>;
    fetchAppUserID(): Promise<string | undefined>;
}

// Need to implement `AppUserAuthenticator` to conform with the appEnvironment.
// Ideally we shouldn't do this, but it takes a bigger refactor.
export class Play2Authenticator implements AppUserAuthenticator, RootAuthenticator {
    readonly failedSignIns = 0;

    private authedUser: PlayAuthenticatedUser | undefined;
    private _unnormalizedVirtualEmail: string | undefined;
    private readonly callbacks: Set<AppUserChangedCallback> = new Set();

    constructor(public readonly appID: string) {}

    public get appUserID(): string | undefined {
        return this.authedUser?.appUserID;
    }

    public get realEmail(): string | undefined {
        return undefinedIfEmptyString(definedMap(this.authedUser?.realEmail, normalizeEmailAddress));
    }

    public get virtualEmail(): string | undefined {
        return undefinedIfEmptyString(definedMap(this.authedUser?.virtualEmail, normalizeEmailAddress));
    }

    public get unnormalizedVirtualEmail(): string | undefined {
        return this._unnormalizedVirtualEmail;
    }

    public get userProfileRow(): JSONObject | undefined {
        return this.authedUser?.userProfileRow;
    }

    get appFacilities() {
        return getAppFacilities();
    }

    public async signInFromPreAuthedCredentials(
        email: string,
        password: string | undefined,
        token: AppLoginTokenContainer | undefined
    ): Promise<boolean> {
        const body: GetCustomTokenForAppBody = {
            appID: this.appID,
            addUserProfileRow: true,
            deviceID: getDeviceID(),
            password,
            email,
            token,
            fromMagicLink: true,
            privateMagicLinkToken: undefined,
        };

        const response = await this.appFacilities.callAuthIfAvailableCloudFunction("getCustomTokenForApp", body, {});
        if (!isResponseOK(response)) {
            logError("Could not get custom token", response);
            return false;
        }

        let responseBody: unknown;
        try {
            responseBody = await response.json();
        } catch (e: unknown) {
            logError("Reading getCustomTokenForApp body", e);
            return false;
        }

        const { customToken, possiblySchema, newLoginToken, userProfileRow, emailColumnName, snapshotURLs } =
            getCustomTokenForAppInfoFromResponse(responseBody);

        setSnapshotLocationAndInitialSchemaFromResponse(this.appID, snapshotURLs, possiblySchema);

        const wasSignInSuccessful = await this.appFacilities.signInWithCustomToken(customToken);

        if (!wasSignInSuccessful) {
            return false;
        }

        const loginToken = asAppLoginTokenContainer(newLoginToken);
        storeCredentialsForFutureSignIn(this.appID, email, password, loginToken);

        const appUserID = await this.fetchAppUserID();

        if (appUserID === undefined) {
            return false;
        }

        this.setAuthenticatedUser({
            appUserID,
            realEmail: email,
            virtualEmail: getVirtualEmailAddress(userProfileRow, emailColumnName) ?? email,
            userProfileRow,
            loginToken,
        });

        return true;
    }

    public async fetchAppUserID(): Promise<string | undefined> {
        const uid = await this.appFacilities.getAuthUserID();
        if (uid === undefined) return undefined;
        const anonymousUser = parseAnonymousUserID(uid);
        if (anonymousUser !== undefined) {
            const { appUserID } = anonymousUser;
            if (appUserID !== undefined) {
                return appUserID;
            }
            return undefined;
        }

        const idToken = await this.appFacilities.getIDToken();
        if (idToken === undefined) return undefined;

        const body: GetAppUserForAuthenticatedUserBody = { appID: this.appID };
        const response = await this.appFacilities.callAuthCloudFunction("getAppUserForAuthenticatedUser", body, {});
        if (!isResponseOK(response)) {
            return undefined;
        }

        let responseBody: unknown;
        try {
            responseBody = await response.json();
            if (!hasOwnProperty(responseBody, "appUserID") || typeof responseBody.appUserID !== "string") {
                return undefined;
            }

            return responseBody.appUserID;
        } catch (e: unknown) {
            logError("Reading getAppUserForAuthenticatedUser", e);
            return undefined;
        }
    }

    public getPreAuthedUserCredential(): PreAuthedUserCredentials {
        const credentialsFromStorage = getEmailPinCredentialsFromStorage(this.appID);
        const loginTokenFromStorage = getLoginTokenFromStorage(this.appID);

        return {
            emailPinCredentials: credentialsFromStorage,
            loginToken: loginTokenFromStorage,
        };
    }

    public setAuthenticatedUser(authedUser: PlayAuthenticatedUser) {
        this.authedUser = authedUser;
        for (const callback of this.callbacks) {
            callback(this.appUserID, this.realEmail, this.virtualEmail, this.authedUser.loginToken, false);
        }
    }

    public addCallback(callback: AppUserChangedCallback): void {
        this.callbacks.add(callback);
    }

    public removeCallback(callback: AppUserChangedCallback): void {
        this.callbacks.delete(callback);
    }

    public sendPinForEmail(
        _email: string,
        _glideCommit: string,
        _privateMagicLinkToken: string | undefined
    ): Promise<{ status: ResponseStatus; link?: string | undefined }> {
        throw new Error(
            "Do not call `sendPinForEmail` from the root authenticator. User `EmailPinAuthenticator` instead"
        );
    }

    public getPasswordForOAuth2Token(_authToken: string, _userAgreed: boolean): Promise<OAuth2TokenAuth | undefined> {
        throw new Error("getPasswordForOAuth2Token not implemented.");
    }

    public getPasswordForEmailPin(
        _email: string,
        _pin: string,
        _userAgreed: boolean
    ): Promise<
        | {
              password: string | undefined;
              loginToken: AppLoginTokenContainer | undefined;
          }
        | undefined
    > {
        throw new Error(
            "Do not call `getPasswordForEmailPin` from the root authenticator. User `EmailPinAuthenticator` instead"
        );
    }

    public authorizeForAppWithEmail(_email: string, _passwordForEmail: string): Promise<boolean> {
        throw new Error(
            "Do not call `authorizeForAppWithEmail` from the root authenticator. User `EmailPinAuthenticator` instead"
        );
    }

    public authorizeForAppWithPassword(_password: string): Promise<boolean> {
        throw new Error("authorizeForAppWithPassword not implemented.");
    }

    public authorizeForAppWithLoginToken(
        _loginToken: AppLoginTokenContainer,
        _email: string | undefined,
        _password: string | undefined
    ): Promise<boolean> {
        throw new Error("authorizeForAppWithLoginToken not implemented.");
    }

    public authorizeForAppWithInviteMagicLink(_token: string, _email: string): Promise<boolean> {
        throw new Error("authorizeForAppWithInviteMagicLink not implemented.");
    }

    public tryGetAppUserID(): Promise<void> {
        return Promise.resolve();
    }

    public setEmailAddressFromBuilder(_email: string, _isSelectedByUser: boolean): void {
        throw new Error("The Play2Authenticator should not be used in the builder.");
    }
}

interface CustomTokenForAppInfo {
    customToken: string;
    possiblySchema: unknown;
    newLoginToken: unknown;
    userProfileRow: JSONObject | undefined;
    emailColumnName: string | undefined;
    snapshotURLs: AppSnapshotURLs;
}

function getCustomTokenForAppInfoFromResponse(response: unknown): CustomTokenForAppInfo {
    const anyResponse = response as any;

    return {
        customToken: anyResponse.customToken,
        possiblySchema: anyResponse.schema,
        newLoginToken: anyResponse.newLoginToken,
        userProfileRow: anyResponse.userProfileRow,
        emailColumnName: anyResponse.emailColumnName,
        snapshotURLs: {
            dataSnapshot: anyResponse.dataSnapshot,
            privateDataSnapshot: anyResponse.privateDataSnapshot,
            unusedDataSnapshot: anyResponse.unusedDataSnapshot,
            publishedAppSnapshot: anyResponse.publishedAppSnapshot,
            nativeTableSnapshots: anyResponse.nativeTableSnapshots,
        },
    };
}

export function setSnapshotLocationAndInitialSchemaFromResponse(
    appID: string,
    snapshotURLs: AppSnapshotURLs,
    possiblySchema: unknown
) {
    const appFacilities = getAppFacilities();
    appFacilities.setSnapshotLocationsForAppID(appID, snapshotURLs);
    const schema = decodeTypeSchema(possiblySchema);
    if (schema !== undefined) {
        appFacilities.setInitialSchemaForAppID(appID, schema);
    }
}

export function storeCredentialsForFutureSignIn(
    appID: string,
    email: string,
    emailPassword: string | undefined,
    loginToken: AppLoginTokenContainer | undefined
) {
    setEmailPinCredentialsFromStorage(appID, { email, emailPassword });
    if (loginToken !== undefined) {
        setLoginTokenFromStorage(appID, loginToken);
    }
}
