import { definedMap } from "@glideapps/ts-necessities";
import { asMaybeNumber } from "../computation-model/data";

function extractIframeElement(maybeIframe: string): HTMLIFrameElement | undefined {
    try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(maybeIframe, "text/html");
        return doc.getElementsByTagName("iframe")[0];
    } catch {
        return undefined;
    }
}

export function extractIframe(linkOrIframe: string | undefined): {
    source: string | undefined;
    height: number | undefined;
    scrollable: boolean;
} {
    if (linkOrIframe === undefined) {
        return {
            source: undefined,
            height: undefined,
            scrollable: true,
        };
    }

    const possibleIframe = extractIframeElement(linkOrIframe);
    if (possibleIframe === undefined) {
        return {
            source: linkOrIframe,
            height: undefined,
            scrollable: true,
        };
    }

    const sourceAttr = possibleIframe.getAttribute("src") ?? linkOrIframe;
    const heightAttr = definedMap(possibleIframe.getAttribute("height") ?? undefined, asMaybeNumber);
    const scrollableAttr = definedMap(possibleIframe.getAttribute("scrollable") ?? undefined, v => v !== "no") ?? true;
    return {
        source: sourceAttr,
        height: heightAttr,
        scrollable: scrollableAttr,
    };
}
