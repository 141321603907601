import { assertNever } from "@glideapps/ts-necessities";
import { type SubscriptionAddon, AddonKind } from "./billing-vnext/subscriptions";
import { AppKind } from "@glide/location-common";

// NOTE: The entirety of this file is legacy and subject to removal sometime after the new billing system is merged.

export function displayDollars(n: number, forceCents?: boolean, currency?: string): string {
    const digits = forceCents !== true && Math.round(n) === n ? 0 : 2;
    return n.toLocaleString("en-US", {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
        style: "currency",
        currency: currency ?? "USD",
    });
}

// NOTE: Used in functions
export enum SubscriptionType {
    Annual = "annual",
    Monthly = "monthly",
}

export enum PlanType {
    Free = "free",
    Basic = "basic",
    Pro = "pro",
    Enterprise = "enterprise",
}

export enum PlanPrivacy {
    Public = "public",
    Private = "private",
}

// NOTE: Used in functions
export enum AppPlanCode {
    Free = "free",
    BasicMonthly = "basic-monthly",
    BasicAnnual = "basic-annual",
    BasicPrivateMonthly = "basic-private-monthly",
    BasicPrivateAnnual = "basic-private-annual",
    ProMonthly = "pro-monthly",
    ProAnnual = "pro-annual",
    ProPrivateMonthly = "private-monthly",
    ProPrivateMonthlyPage = "private-monthly-page",
    ProPrivateAnnual = "private-annual",
    LegacyOrgPublicMonthly = "legacy-org-public-monthly",
    LegacyOrgInternalMonthly = "legacy-org-internal-monthly",
}

export function appKindFromAppPlanCode(plan: AppPlanCode): AppKind {
    switch (plan) {
        case AppPlanCode.ProPrivateMonthlyPage:
            return AppKind.Page;
        default:
            return AppKind.App;
    }
}

// TODO: Remove this once everyone is on the new billing system.
export function appPlanToSubscriptionAddonKind(plan: AppPlanCode): AddonKind | undefined {
    switch (plan) {
        case AppPlanCode.Free:
            return undefined;
        case AppPlanCode.LegacyOrgPublicMonthly:
            return AddonKind.V1OrgProPublic;
        case AppPlanCode.LegacyOrgInternalMonthly:
            return AddonKind.V1OrgProInternalUserOverage;
        case AppPlanCode.BasicMonthly:
        case AppPlanCode.BasicAnnual:
            return AddonKind.V2BasicPublicApp;
        case AppPlanCode.BasicPrivateMonthly:
        case AppPlanCode.BasicPrivateAnnual:
            return AddonKind.V2BasicPrivateApp;
        case AppPlanCode.ProMonthly:
        case AppPlanCode.ProAnnual:
            return AddonKind.V2ProPublicApp;
        case AppPlanCode.ProPrivateMonthly:
        case AppPlanCode.ProPrivateAnnual:
            return AddonKind.V2ProPrivateApp;
        case AppPlanCode.ProPrivateMonthlyPage:
            return AddonKind.V2ProPrivatePage;
        default:
            assertNever(plan, "AppPlanCode is not handled in appPlanToSubscriptionPlanKind");
    }
}

export function addonToAppPlanCode(addon: SubscriptionAddon | undefined): AppPlanCode | undefined {
    if (addon === undefined) return undefined;
    const { kind, period } = addon;
    if (period === "annual") {
        switch (kind) {
            case AddonKind.V2BasicPublicApp:
                return AppPlanCode.BasicAnnual;
            case AddonKind.V2BasicPrivateApp:
                return AppPlanCode.BasicPrivateAnnual;
            case AddonKind.V2ProPublicApp:
                return AppPlanCode.ProAnnual;
            case AddonKind.V2ProPrivateApp:
                return AppPlanCode.ProPrivateAnnual;
            default:
                return undefined;
        }
    }
    switch (kind) {
        case AddonKind.V1OrgProPublic:
            return AppPlanCode.LegacyOrgPublicMonthly;
        case AddonKind.V1OrgProInternalUserOverage:
            return AppPlanCode.LegacyOrgInternalMonthly;
        case AddonKind.V2BasicPublicApp:
            return AppPlanCode.BasicMonthly;
        case AddonKind.V2BasicPrivateApp:
            return AppPlanCode.BasicPrivateMonthly;
        case AddonKind.V2ProPublicApp:
            return AppPlanCode.ProMonthly;
        case AddonKind.V2ProPrivateApp:
            return AppPlanCode.ProPrivateMonthly;
        case AddonKind.V2ProPrivatePage:
            return AppPlanCode.ProPrivateMonthlyPage;
        case AddonKind.V1ProApp:
            return AppPlanCode.ProMonthly;
        default:
            return undefined;
    }
}

export function splitPlanCodeForAnalytics(
    code: AppPlanCode
): { plan: "basic" | "pro"; period: SubscriptionType; privacy: PlanPrivacy; kind: AppKind } | undefined {
    if (code === AppPlanCode.Free) return undefined;
    switch (code) {
        case AppPlanCode.BasicMonthly:
            return { plan: "basic", period: SubscriptionType.Monthly, privacy: PlanPrivacy.Public, kind: AppKind.App };
        case AppPlanCode.BasicAnnual:
            return { plan: "basic", period: SubscriptionType.Annual, privacy: PlanPrivacy.Public, kind: AppKind.App };
        case AppPlanCode.BasicPrivateMonthly:
            return { plan: "basic", period: SubscriptionType.Monthly, privacy: PlanPrivacy.Private, kind: AppKind.App };
        case AppPlanCode.BasicPrivateAnnual:
            return { plan: "basic", period: SubscriptionType.Annual, privacy: PlanPrivacy.Private, kind: AppKind.App };
        case AppPlanCode.ProMonthly:
            return { plan: "pro", period: SubscriptionType.Monthly, privacy: PlanPrivacy.Public, kind: AppKind.App };
        case AppPlanCode.ProPrivateMonthlyPage:
            return { plan: "pro", period: SubscriptionType.Monthly, privacy: PlanPrivacy.Private, kind: AppKind.Page };
        case AppPlanCode.ProAnnual:
            return { plan: "pro", period: SubscriptionType.Annual, privacy: PlanPrivacy.Public, kind: AppKind.App };
        case AppPlanCode.ProPrivateMonthly:
            return { plan: "pro", period: SubscriptionType.Monthly, privacy: PlanPrivacy.Private, kind: AppKind.App };
        case AppPlanCode.ProPrivateAnnual:
            return { plan: "pro", period: SubscriptionType.Annual, privacy: PlanPrivacy.Private, kind: AppKind.App };
        case AppPlanCode.LegacyOrgInternalMonthly:
        // fallthrough
        case AppPlanCode.LegacyOrgPublicMonthly:
            return undefined;
        default:
            return assertNever(code);
    }
}

export function getPlanName(plan: AppPlanCode) {
    switch (plan) {
        case AppPlanCode.Free:
            return {
                short: "Personal",
                long: "Personal",
            };
        case AppPlanCode.BasicMonthly:
            return {
                short: "Basic",
                long: "Basic, Monthly",
            };
        case AppPlanCode.BasicAnnual:
            return {
                short: "Basic",
                long: "Basic, Annual",
            };
        case AppPlanCode.ProMonthly:
            return {
                short: "Pro",
                long: "Pro, Monthly",
            };
        case AppPlanCode.ProAnnual:
            return {
                short: "Pro",
                long: "Pro, Annual",
            };
        case AppPlanCode.ProPrivateMonthly:
            return {
                short: "Private Pro",
                long: "Private Pro, Monthly",
            };
        case AppPlanCode.ProPrivateMonthlyPage:
            return {
                short: "Pro",
                long: "Pro, Monthly",
            };
        case AppPlanCode.ProPrivateAnnual:
            return {
                short: "Private Pro",
                long: "Private Pro, Annual",
            };
        case AppPlanCode.BasicPrivateMonthly:
            return {
                short: "Private Basic",
                long: "Private Basic, Monthly",
            };
        case AppPlanCode.BasicPrivateAnnual:
            return {
                short: "Private Basic",
                long: "Private Basic, Annual",
            };
        case AppPlanCode.LegacyOrgPublicMonthly:
            return {
                short: "Pro",
                long: "Legacy Org Public, Monthly",
            };
        case AppPlanCode.LegacyOrgInternalMonthly:
            return {
                short: "Private",
                long: "Legacy Org Internal, Monthly",
            };
        default:
            assertNever(plan);
    }
}

type AllPlanStrings = "legacy" | AppPlanCode;

// NOTE: Used in functions
const appPlans: Record<AllPlanStrings, AppPlanInfo> = {
    legacy: {
        code: AppPlanCode.ProMonthly,
        price: 29,
        title: "Legacy Pro app",
        units: "per month",
        isPrivate: false,
        isMonthly: true,
        isLegacy: true,
        numberOfUsers: 20,
    },
    [AppPlanCode.Free]: {
        code: AppPlanCode.Free,
        price: 0,
        title: "Free",
        units: "",
        isPrivate: false,
        isMonthly: false,
        isLegacy: false,
        numberOfUsers: Number.MAX_SAFE_INTEGER,
    },
    [AppPlanCode.BasicAnnual]: {
        code: AppPlanCode.BasicAnnual,
        price: 9,
        title: "Basic app",
        units: "per month",
        isPrivate: false,
        isMonthly: false,
        isLegacy: false,
        numberOfUsers: 20,
    },
    [AppPlanCode.BasicMonthly]: {
        code: AppPlanCode.BasicMonthly,
        price: 12,
        title: "Basic app",
        units: "per month",
        isPrivate: false,
        isMonthly: true,
        isLegacy: false,
        numberOfUsers: 20,
    },
    [AppPlanCode.BasicPrivateMonthly]: {
        code: AppPlanCode.BasicPrivateMonthly,
        price: 1,
        title: "Private Basic app",
        units: "per user, per month",
        isPrivate: true,
        isMonthly: true,
        isLegacy: false,
        numberOfUsers: 20,
    },
    [AppPlanCode.ProPrivateMonthly]: {
        code: AppPlanCode.ProPrivateMonthly,
        price: 2,
        title: "Private Pro app",
        units: "per month",
        isPrivate: true,
        isMonthly: true,
        isLegacy: false,
        numberOfUsers: 20,
    },
    [AppPlanCode.ProPrivateMonthlyPage]: {
        code: AppPlanCode.ProPrivateMonthlyPage,
        price: 2,
        title: "Pro page",
        units: "per month",
        isPrivate: true,
        isMonthly: true,
        isLegacy: false,
        numberOfUsers: 20,
    },
    [AppPlanCode.BasicPrivateAnnual]: {
        code: AppPlanCode.BasicPrivateAnnual,
        price: 0.75,
        title: "Private Basic app",
        units: "per month",
        isPrivate: true,
        isMonthly: false,
        isLegacy: false,
        numberOfUsers: 20,
    },
    [AppPlanCode.ProPrivateAnnual]: {
        code: AppPlanCode.ProPrivateAnnual,
        price: 1.5,
        title: "Private Pro app",
        units: "per month",
        isPrivate: true,
        isMonthly: false,
        isLegacy: false,
        numberOfUsers: 20,
    },
    [AppPlanCode.ProAnnual]: {
        code: AppPlanCode.ProAnnual,
        price: 24,
        title: "Pro app",
        units: "per month",
        isPrivate: false,
        isMonthly: false,
        isLegacy: false,
        numberOfUsers: 20,
    },
    [AppPlanCode.ProMonthly]: {
        code: AppPlanCode.ProMonthly,
        price: 32,
        title: "Pro app",
        units: "per month",
        isPrivate: false,
        isMonthly: true,
        isLegacy: false,
        numberOfUsers: 20,
    },
    [AppPlanCode.LegacyOrgInternalMonthly]: {
        code: AppPlanCode.LegacyOrgInternalMonthly,
        price: 8,
        title: "Legacy Org Internal",
        units: "per user monthly",
        isPrivate: true,
        isMonthly: true,
        isLegacy: true,
        numberOfUsers: 20,
    },
    [AppPlanCode.LegacyOrgPublicMonthly]: {
        code: AppPlanCode.LegacyOrgPublicMonthly,
        price: 29,
        title: "Legacy Org Public",
        units: "per month",
        isPrivate: false,
        isMonthly: true,
        isLegacy: true,
        numberOfUsers: 20,
    },
};

export function planCodeToPlanType(code: AppPlanCode): PlanType | undefined {
    switch (code) {
        case AppPlanCode.Free:
            return PlanType.Free;
        case AppPlanCode.BasicAnnual:
        case AppPlanCode.BasicMonthly:
        case AppPlanCode.BasicPrivateMonthly:
        case AppPlanCode.BasicPrivateAnnual:
            return PlanType.Basic;
        case AppPlanCode.LegacyOrgInternalMonthly:
        case AppPlanCode.LegacyOrgPublicMonthly:
            return undefined;
        case AppPlanCode.ProMonthly:
        case AppPlanCode.ProPrivateMonthly:
        case AppPlanCode.ProPrivateMonthlyPage:
        case AppPlanCode.ProAnnual:
        case AppPlanCode.ProPrivateAnnual:
            return PlanType.Pro;
        default:
            return assertNever(code);
    }
}

export function planCodeToSubscriptionType(code: AppPlanCode): SubscriptionType {
    switch (code) {
        case AppPlanCode.Free:
        case AppPlanCode.BasicMonthly:
        case AppPlanCode.BasicPrivateMonthly:
        case AppPlanCode.ProMonthly:
        case AppPlanCode.ProPrivateMonthly:
        case AppPlanCode.ProPrivateMonthlyPage:
        case AppPlanCode.LegacyOrgPublicMonthly:
        case AppPlanCode.LegacyOrgInternalMonthly:
            return SubscriptionType.Monthly;
        case AppPlanCode.BasicAnnual:
        case AppPlanCode.BasicPrivateAnnual:
        case AppPlanCode.ProAnnual:
        case AppPlanCode.ProPrivateAnnual:
            return SubscriptionType.Annual;
        default:
            return assertNever(code);
    }
}

export function planCodeToPlanPrivacy(code: AppPlanCode): PlanPrivacy {
    switch (code) {
        case AppPlanCode.ProPrivateAnnual:
        case AppPlanCode.ProPrivateMonthly:
        case AppPlanCode.BasicPrivateMonthly:
        case AppPlanCode.BasicPrivateAnnual:
        case AppPlanCode.ProPrivateMonthlyPage:
        case AppPlanCode.LegacyOrgInternalMonthly:
            return PlanPrivacy.Private;
        case AppPlanCode.Free:
        case AppPlanCode.BasicMonthly:
        case AppPlanCode.ProMonthly:
        case AppPlanCode.LegacyOrgPublicMonthly:
        case AppPlanCode.BasicAnnual:
        case AppPlanCode.ProAnnual:
            return PlanPrivacy.Public;
        default:
            return assertNever(code);
    }
}

export interface AppPlanInfo {
    code: AppPlanCode;
    price: number;
    title: string;
    units: string;
    isPrivate: boolean;
    isMonthly: boolean;
    isLegacy: boolean;
    numberOfUsers: number;
}

export function getAppPlanInfo(
    planType: PlanType,
    planPrivacy: PlanPrivacy,
    subscriptionType: SubscriptionType,
    appKind: AppKind,
    numberOfUsers: number = 20
): AppPlanInfo | undefined {
    const isPrivate = planPrivacy === PlanPrivacy.Private;
    const isAnnual = subscriptionType === SubscriptionType.Annual;

    let planInfo: AppPlanInfo = {
        ...appPlans[AppPlanCode.Free],
    };
    if (planType === PlanType.Free) {
        return planInfo;
    }

    // FIXME: Hack for now for pages enterprise plan
    if (planType === PlanType.Enterprise) return undefined;

    if (appKind === AppKind.Page) {
        if (!isAnnual && isPrivate) {
            planInfo = {
                ...appPlans[AppPlanCode.ProPrivateMonthlyPage],
                numberOfUsers: 20,
            };
        } else {
            // Only monthly private supported for Pages
            return undefined;
        }
    } else if (planType === PlanType.Basic) {
        if (isAnnual && isPrivate) {
            planInfo = {
                ...appPlans[AppPlanCode.BasicPrivateAnnual],
                numberOfUsers,
            };
        } else if (isAnnual && !isPrivate) {
            planInfo = {
                ...appPlans[AppPlanCode.BasicAnnual],
                numberOfUsers: 1,
            };
        } else if (!isAnnual && isPrivate) {
            planInfo = {
                ...appPlans[AppPlanCode.BasicPrivateMonthly],
                numberOfUsers: 20,
            };
        } else if (!isAnnual && !isPrivate) {
            planInfo = {
                ...appPlans[AppPlanCode.BasicMonthly],
                numberOfUsers: 1,
            };
        }
    } else if (planType === PlanType.Pro) {
        if (isAnnual && isPrivate) {
            planInfo = {
                ...appPlans[AppPlanCode.ProPrivateAnnual],
                numberOfUsers,
            };
        } else if (isAnnual && !isPrivate) {
            planInfo = {
                ...appPlans[AppPlanCode.ProAnnual],
                numberOfUsers: 1,
            };
        } else if (!isAnnual && isPrivate) {
            planInfo = {
                ...appPlans[AppPlanCode.ProPrivateMonthly],
                numberOfUsers: 20,
            };
        } else if (!isAnnual && !isPrivate) {
            planInfo = {
                ...appPlans[AppPlanCode.ProMonthly],
                numberOfUsers: 1,
            };
        }
    } else {
        assertNever(planType);
    }

    return planInfo;
}
