import { ignore } from "@glide/support";
import React from "react";

interface OrgPageHeaderContextProps {
    readonly viewAccountSettings: () => void;
    readonly signOut: () => void;
    readonly showInviteModal: () => void;
}

export const OrgPageHeaderContext = React.createContext<OrgPageHeaderContextProps>({
    viewAccountSettings: ignore,
    signOut: ignore,
    showInviteModal: ignore,
});

export function useOrgPageHeaderContext(): OrgPageHeaderContextProps {
    return React.useContext(OrgPageHeaderContext);
}
