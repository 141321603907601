import "twin.macro";

import type { GlideFC } from "@glide/common";
import { useForceUpdate } from "@glide/common";
import { defined } from "@glideapps/ts-necessities";
import * as React from "react";
import { createPortal } from "react-dom";

import type { AcceptCancelModalOptions } from "../accept-cancel-modal/accept-cancel-modal";
import { AcceptCancelModal } from "../accept-cancel-modal/accept-cancel-modal";
import WindowedModalV2, { WindowedModalButtonBar } from "../windowed-modal/windowed-modal";
import { bindModals, unbindModals } from "./externalAPI";
import type { RenderModalAction, SimpleModalContextProps } from "./modal-context";
import type { Subtract } from "utility-types";
import { getErrorModalSnippet } from "./modals-impl/modal-snippet-library";

const ModalContext = React.createContext<SimpleModalContextProps | undefined>(undefined);

interface SimpleModalProviderV2Props {
    children: React.ReactNode;
}

export const SimpleModalProviderV2: GlideFC<SimpleModalProviderV2Props> = p => {
    const curModalIndex = React.useRef(0);
    const modalsRef = React.useRef<Record<string, React.ReactElement>>({});
    const forceUpdate = useForceUpdate();
    const mintIndex = () => {
        const r = curModalIndex.current;
        curModalIndex.current = r + 1;
        return r;
    };
    const addModal = React.useCallback(
        (modal: React.ReactElement) => {
            const result = mintIndex();
            modalsRef.current = {
                ...modalsRef.current,
                [result]: modal,
            };
            forceUpdate();
            return result;
        },
        [forceUpdate]
    );

    const removeModal = React.useCallback(
        (key: number) => {
            const newModals = { ...modalsRef.current };
            delete newModals[key];
            modalsRef.current = newModals;
            forceUpdate();
        },
        [forceUpdate]
    );

    /**
     * @deprecated instead trigger modals with `showModal`
     */
    const DEPRECATED_removeAllModals = React.useCallback(() => {
        modalsRef.current = {};
        forceUpdate();
    }, [forceUpdate]);

    const showModal = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
        <T extends unknown>(renderModal: RenderModalAction<T>): Promise<T> => {
            let key: number = 0;
            const result = new Promise<T>(resolve => {
                const resolveModal = (value: T) => {
                    removeModal(key);
                    resolve(value);
                };
                const newModal = renderModal(resolveModal);
                key = addModal(newModal);
            });

            return result;
        },
        [addModal, removeModal]
    );

    const showInfoActionModal = React.useCallback(
        (header: string, description: React.ReactNode, actionText: string, infoText: string): Promise<boolean> => {
            return showModal<boolean>(resolve => {
                return (
                    <WindowedModalV2
                        title={header}
                        canClose={true}
                        onClose={() => {
                            resolve(false);
                        }}
                        footer={
                            <WindowedModalButtonBar
                                align="right"
                                buttons={[
                                    {
                                        title: infoText,
                                        type: "secondary",
                                        variant: "default",
                                        onClick: () => resolve(false),
                                    },
                                    {
                                        title: actionText,
                                        variant: "default",
                                        type: "primary",
                                        onClick: () => resolve(true),
                                    },
                                ]}
                            />
                        }>
                        {description}
                    </WindowedModalV2>
                );
            });
        },
        [showModal]
    );

    /**
     * @deprecated instead trigger modals with `showErrorModal`
     */
    const showErrorActionModal = React.useCallback(
        (header: string, description: React.ReactNode, actionText: string): Promise<void> => {
            return showModal<void>(resolve => {
                return (
                    <WindowedModalV2
                        className={"click-outside-ignore"}
                        title={header}
                        canClose={false}
                        onClose={resolve}
                        footer={
                            <WindowedModalButtonBar
                                align="right"
                                buttons={[{ title: actionText, variant: "danger", type: "primary", onClick: resolve }]}
                            />
                        }>
                        {description}
                    </WindowedModalV2>
                );
            });
        },
        [showModal]
    );

    // Usage:
    // const {showErrorModal} = useModals();
    // showErrorModal(1001)

    const showErrorModal = React.useCallback(
        (errorCode: number, errorMessage?: string): Promise<void> => {
            const { title, bodyContent, buttonText, onClick } = getErrorModalSnippet(errorCode, errorMessage);
            return showModal<void>(resolve => {
                return (
                    <WindowedModalV2
                        className={"click-outside-ignore"}
                        title={title}
                        titleBarBottomPadding={8}
                        footerTopMargin={0}
                        barsPadding={24}
                        footerTopPadding={20}
                        canClose={false}
                        padding={24}
                        onClose={resolve}
                        footerDivider={false}
                        maxWidth="500px"
                        footer={
                            <div tw="flex justify-between items-end">
                                <p tw="text-builder-sm text-text-contextual-pale font-normal">Error: {errorCode}</p>
                                <WindowedModalButtonBar
                                    align="right"
                                    buttons={[
                                        {
                                            title: buttonText ?? "close",
                                            variant: "default",
                                            type: "primary",
                                            onClick: () => {
                                                if (onClick !== undefined) {
                                                    onClick();
                                                }
                                                resolve();
                                            },
                                            minWidth: 90,
                                        },
                                    ]}
                                />
                            </div>
                        }>
                        {bodyContent}
                    </WindowedModalV2>
                );
            });
        },
        [showModal]
    );

    const showSimpleInfoModal = React.useCallback(
        (title: string, bodyContent: React.ReactNode, actionText?: string): Promise<void> => {
            return showModal<void>(resolve => {
                return (
                    <WindowedModalV2
                        title={title}
                        canClose={true}
                        maxWidth="500px"
                        footer={
                            <WindowedModalButtonBar
                                align="right"
                                buttons={[
                                    {
                                        title: actionText ?? "OK",
                                        fullSize: true,
                                        variant: "default",
                                        type: "primary",
                                        onClick: () => resolve(),
                                    },
                                ]}
                            />
                        }
                        onClose={() => resolve()}>
                        {bodyContent}
                    </WindowedModalV2>
                );
            });
        },
        [showModal]
    );

    const showAcceptCancelModal = React.useCallback(
        (
            title: string,
            bodyContent: React.ReactNode,
            acceptButtonText: string,
            options?: AcceptCancelModalOptions
        ): Promise<boolean> => {
            const {
                acceptButtonVariance,
                requireUserToType,
                footerDivider = false,
                hideClosingButton = true,
                titleBarTopPadding = 24,
                titleBarBottomPadding = 8,
                footerTopMargin = 20,
                footerTopPadding = 0,
                footerBottomPadding = 20,
                barsPadding = 24,
                padding = 24,
                buttonSize = "lg",
                smallContentText = true,
            } = options ?? {};
            return showModal<boolean>(resolve => {
                return (
                    <AcceptCancelModal
                        title={title}
                        acceptButtonText={acceptButtonText}
                        cancelButtonText="Cancel"
                        onAccept={() => resolve(true)}
                        onCancel={() => resolve(false)}
                        acceptButtonVariance={acceptButtonVariance}
                        requireUserToType={requireUserToType}
                        footerDivider={footerDivider}
                        hideClosingButton={hideClosingButton}
                        titleBarBottomPadding={titleBarBottomPadding}
                        titleBarTopPadding={titleBarTopPadding}
                        footerTopMargin={footerTopMargin}
                        footerTopPadding={footerTopPadding}
                        barsPadding={barsPadding}
                        padding={padding}
                        footerBottomPadding={footerBottomPadding}
                        buttonSize={buttonSize}
                        smallContentText={smallContentText}>
                        {bodyContent}
                    </AcceptCancelModal>
                );
            });
        },
        [showModal]
    );

    const showWindowedModal = React.useCallback(
        (
            bodyContent: (onClose: () => void, onComplete: () => void) => React.ReactNode,
            canClose: boolean = true,
            title?: string
        ): Promise<boolean> => {
            return showModal<boolean>(resolve => {
                return (
                    <WindowedModalV2 canClose={canClose} onClose={() => resolve(false)} title={title}>
                        {bodyContent(
                            () => resolve(false),
                            () => resolve(true)
                        )}
                    </WindowedModalV2>
                );
            });
        },
        [showModal]
    );

    const contextProps = React.useMemo(
        () => ({
            showInfoActionModal,
            showSimpleInfoModal,
            showAcceptCancelModal,
            showWindowedModal,
            showModal,
            DEPRECATED_removeAllModals,
            showErrorActionModal,
            showErrorModal,
        }),
        [
            showInfoActionModal,
            showSimpleInfoModal,
            showAcceptCancelModal,
            showWindowedModal,
            showModal,
            DEPRECATED_removeAllModals,
            showErrorActionModal,
            showErrorModal,
        ]
    );

    React.useEffect(() => {
        bindModals(contextProps);

        return () => unbindModals();
    }, [contextProps]);

    const content = Object.entries(modalsRef.current).map(([key, element]) => {
        return <React.Fragment key={key}>{element}</React.Fragment>;
    });

    const portal = createPortal(<>{content}</>, document.getElementById("portal") as HTMLElement);
    return (
        <ModalContext.Provider value={contextProps}>
            <>
                {p.children}
                {portal}
            </>
        </ModalContext.Provider>
    );
};

export function useModals(): SimpleModalContextProps {
    return defined(React.useContext(ModalContext), "Cannot use modals outside of SimpleModalProviderV2");
}

interface ModalProviderProps {
    modals: SimpleModalContextProps;
}

export const withModalProvider =
    <P extends ModalProviderProps>(Component: React.ComponentType<P>): React.FC<Subtract<P, ModalProviderProps>> =>
    p => {
        const modals = useModals();

        return <Component {...(p as P)} modals={modals} />;
    };
