import { type RuntimeTheme, UrlHandling, appIsInstalled, browserOnPhone, getUrlHandling } from "@glide/common";
import { formatLocalizedString, getAnyLocalizedString, getLocalizedString } from "@glide/localization";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { isRunningInNativeIos } from "@glide/common-core/dist/js/native-hooks";
import { getStringProperty } from "@glide/app-description";
import { defined } from "@glideapps/ts-necessities";
import { isEmptyOrUndefined, isValidEmailAddress } from "@glide/support";
import * as React from "react";
import { withTheme } from "styled-components";

import type { BaseProps } from "./custom-sign-in-props";
import {
    Button,
    ButtonWrapper,
    ContentContainer,
    ErrorContainer,
    Input,
    Subtitle,
    Title,
} from "./custom-sign-in-style";
import { signInNormalTextColors } from "./custom-sign-in-styles";
import { type SignInState, CheckBoxContainer, Checkbox } from "@glide/common-components";
import { ExternalAuthProviderButtons } from "./google-sso";
import type { WireAppTheme } from "@glide/theme";

interface Props extends BaseProps {
    readonly theme: RuntimeTheme | WireAppTheme;
}

interface State {
    currentState: SignInState;
    lastState: SignInState | undefined;
}

class EmailInputImpl extends React.PureComponent<Props, State> {
    public state: State = {
        currentState: "credentials",
        lastState: undefined,
    };

    // types make withTheme mad
    public static getDerivedStateFromProps(props: any, state: any): any {
        if (state.currentState !== props.currentState) {
            state.lastState = state.currentState;
            state.currentState = props.currentState;
        }

        return state;
    }

    private onEmailChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onEmailChange(ev.target.value);
    };

    private onPinChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onPinChange(ev.target.value);
    };

    private onFormSubmit = async (event: React.FormEvent) => {
        const { onPressedContinue, userAgreed, theme } = this.props;
        event.preventDefault();

        const userAgreementRequired = theme.requiresAgreement === true;
        const userAgreedValidated = !userAgreementRequired || (userAgreementRequired && userAgreed);

        if (onPressedContinue && userAgreedValidated) onPressedContinue();
    };

    private onEmailPressedContinue = () => {
        const { onPressedContinue } = this.props;
        if (onPressedContinue) {
            onPressedContinue();
        }
    };

    private onPressedNeedAnotherPin = () => {
        const { onPressedPin } = this.props;

        if (onPressedPin) {
            onPressedPin();
        }
    };

    private toggleUserAgreed = () => {
        const { onUserAgreedChange } = this.props;
        onUserAgreedChange(!this.props.userAgreed);
    };

    private toggleSaveLogin = () => {
        const { onUserUpdateSaveLogin } = this.props;
        onUserUpdateSaveLogin(!this.props.allowSaveLogin);
    };

    public render() {
        const {
            currentState,
            colorTheme,
            isPro,
            onPressedContinue,
            greeting,
            description,
            userEmail,
            pin,
            canContinue,
            canPressPin,
            error,
            isSending,
            theme,
            userAgreed,
            allowSaveLogin,
            onUserAgreedChange,
            onUserUpdateSaveLogin,
            appFeatures,
            onPressedBack,
        } = this.props;
        const appKind = getAppKindFromFeatures(appFeatures);
        const { requiresAgreement, agreementText: agreementTextProperty, policyLinks } = theme;
        const agreementText = getStringProperty(agreementTextProperty);
        const agreementTextOrDefault = isEmptyOrUndefined(agreementText)
            ? getLocalizedString("userAgreement", appKind)
            : agreementText;

        const userAgreementRequired = requiresAgreement === true;
        const userAgreedValidated = !userAgreementRequired || (userAgreementRequired && userAgreed);

        const canProgressToPin = canContinue === true && isValidEmailAddress(userEmail) && userAgreedValidated;
        const reverse = currentState === "credentials" && this.state.lastState === "challenge";

        let topContent: React.ReactNode;
        let buttons: React.ReactNode;
        if (currentState === "challenge") {
            topContent = (
                <div>
                    <Subtitle data-test="app-sign-in-subtitle">
                        {formatLocalizedString("weHaveSentAPinTo", [userEmail], appKind)}
                        <br />
                        <br />
                        {getLocalizedString("checkYourSpamFolderForPin", appKind)}
                    </Subtitle>
                    <form action="" onSubmit={this.onFormSubmit}>
                        <Input
                            data-test="app-pin-input"
                            className={error !== undefined ? "error" : ""}
                            type="tel"
                            pattern="[0-9]*"
                            placeholder={getLocalizedString("enterPin", appKind)}
                            value={pin}
                            onChange={this.onPinChange}
                            colorTheme={colorTheme}
                        />
                    </form>
                    {error !== undefined && <ErrorContainer>{error}</ErrorContainer>}
                </div>
            );

            buttons = (
                <ButtonWrapper colorTheme={colorTheme} showFooter={isPro ?? false}>
                    <Button onClick={onPressedContinue} disabled={canContinue === false}>
                        {getLocalizedString("signIn", appKind)}
                    </Button>
                    <Button className="minimal" onClick={this.onPressedNeedAnotherPin} disabled={canPressPin === false}>
                        {getLocalizedString("iNeedAnotherPin", appKind)}
                    </Button>
                </ButtonWrapper>
            );
        } else if (currentState === "request-access-prompt" || currentState === "request-access-complete") {
            topContent = (
                <div>
                    <Title data-test="app-sign-in-title" css={signInNormalTextColors}>
                        {greeting}
                    </Title>
                    <Subtitle id="sign-in-description" data-test="app-sign-in-subtitle">
                        {description}
                    </Subtitle>
                    <div
                        data-test="request-access-email"
                        tw="height[46px] line-height[46px] font-semibold text-align[center] select-text"
                    >
                        {userEmail}
                    </div>
                    {error !== undefined && <ErrorContainer>{error}</ErrorContainer>}
                </div>
            );
            buttons = (
                <ButtonWrapper colorTheme={colorTheme} showFooter={isPro ?? false}>
                    {currentState === "request-access-prompt" && (
                        <Button onClick={this.onEmailPressedContinue} disabled={!canProgressToPin}>
                            {isSending
                                ? getAnyLocalizedString("requestingAccess", appKind)
                                : getAnyLocalizedString("requestAccess", appKind)}
                        </Button>
                    )}
                    {onPressedBack !== undefined && (
                        <Button className="back" onClick={onPressedBack}>
                            {getAnyLocalizedString("backToSignIn", appKind)}
                        </Button>
                    )}
                </ButtonWrapper>
            );
        } else {
            const policyLinksNode =
                policyLinks === undefined || policyLinks.length === 0 ? null : (
                    <div className="links-container">
                        {policyLinks.map((pl, i) => {
                            const title = getStringProperty(pl.title);
                            const href = getStringProperty(pl.url);
                            const handling = getUrlHandling(defined(href), false);
                            const useTag =
                                handling === UrlHandling.UseATag || handling === UrlHandling.UseATagWithBlank;
                            const target = handling === UrlHandling.UseATagWithBlank ? "_blank" : undefined;

                            if (isEmptyOrUndefined(title) || isEmptyOrUndefined(href))
                                return <React.Fragment key={i} />;

                            return (
                                <a className="link" href={useTag ? href : undefined} target={target} key={i}>
                                    {title}
                                </a>
                            );
                        })}
                    </div>
                );

            topContent = (
                <div>
                    <Title data-test="app-sign-in-title" css={signInNormalTextColors}>
                        {greeting}
                    </Title>
                    <Subtitle id="sign-in-description" data-test="app-sign-in-subtitle">
                        {description}
                    </Subtitle>
                    <form action="" onSubmit={this.onFormSubmit}>
                        <Input
                            data-test="app-email-input"
                            disabled={isSending}
                            className={error !== undefined ? "error" : ""}
                            type="email"
                            placeholder={getLocalizedString("emailAddress", appKind)}
                            colorTheme={colorTheme}
                            value={userEmail}
                            onChange={this.onEmailChange}
                            autoFocus={!browserOnPhone || appIsInstalled()}
                        />
                    </form>
                    {userAgreementRequired && (
                        <CheckBoxContainer>
                            {policyLinksNode}
                            <div className="checkbox-container" onClick={this.toggleUserAgreed}>
                                <Checkbox
                                    checked={userAgreed}
                                    isEnabled={true}
                                    disablePadding={true}
                                    colorTheme={colorTheme}
                                    onChanged={onUserAgreedChange}
                                />
                                <div className="agreement">{agreementTextOrDefault}</div>
                            </div>
                        </CheckBoxContainer>
                    )}
                    {appFeatures.askUserToSaveAuthCookie && (
                        <CheckBoxContainer>
                            <div className="checkbox-container" onClick={this.toggleSaveLogin}>
                                <Checkbox
                                    checked={allowSaveLogin}
                                    isEnabled={true}
                                    disablePadding={true}
                                    colorTheme={colorTheme}
                                    onChanged={onUserUpdateSaveLogin}
                                />
                                <div className="allow-auth">
                                    <div className="allow-auth-primary">
                                        {getLocalizedString("saveLoginPrimay", appKind)}
                                    </div>
                                    <div className="allow-auth-secondary">
                                        {getLocalizedString("saveLoginSecondary", appKind)}
                                    </div>
                                </div>
                            </div>
                        </CheckBoxContainer>
                    )}
                    {error !== undefined && <ErrorContainer>{error}</ErrorContainer>}
                </div>
            );
            buttons = (
                <ButtonWrapper colorTheme={colorTheme} showFooter={isPro ?? false}>
                    <Button onClick={this.onEmailPressedContinue} disabled={!canProgressToPin}>
                        {isSending
                            ? getLocalizedString("sendingPin", appKind)
                            : getLocalizedString("continue", appKind)}
                    </Button>
                    {!isRunningInNativeIos() && (
                        <ExternalAuthProviderButtons
                            authMethod={this.props.authMethod}
                            {...this.props}
                            userAgreed={userAgreedValidated}
                            appKind={appKind}
                        />
                    )}
                </ButtonWrapper>
            );
        }

        return (
            <>
                <ContentContainer className={reverse ? "reverse" : undefined}>{topContent}</ContentContainer>
                {buttons}
            </>
        );
    }
}

const EmailInput = withTheme(EmailInputImpl);
export default EmailInput;
