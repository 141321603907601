import type { GlideDateTimeDocumentData, GlideJSONDocumentData } from "@glide/data-types";
import {
    GlideDateTime,
    glideDateTimeDocumentDataCodec,
    GlideJSON,
    glideJSONDocumentDataCodec,
} from "@glide/data-types";
import { hasOwnProperty, isArray } from "@glideapps/ts-necessities";
import type { Either } from "fp-ts/lib/Either";
import { isRight, isLeft } from "fp-ts/lib/Either";
import * as t from "io-ts";

export type PluginValue =
    | {
          type: "number";
          value: number;
      }
    | {
          type: "string";
          value: string;
      }
    | {
          type: "boolean";
          value: boolean;
      }
    | {
          type: "date-time";
          value: GlideDateTime;
      }
    | {
          type: "string-array";
          value: readonly string[];
      }
    | {
          type: "glide-json";
          value: GlideJSON | readonly PluginValue[];
      };

type EncodedPluginValue =
    | {
          type: "number";
          value: number;
      }
    | {
          type: "string";
          value: string;
      }
    | {
          type: "boolean";
          value: boolean;
      }
    | {
          type: "date-time";
          value: GlideDateTimeDocumentData;
      }
    | {
          type: "string-array";
          value: readonly string[];
      }
    | {
          type: "glide-json";
          value: GlideJSONDocumentData | readonly EncodedPluginValue[];
      };

export const pluginValue = new t.Type<PluginValue, EncodedPluginValue, unknown>(
    "pluginType",
    // is
    (u: unknown): u is PluginValue => {
        if (!hasOwnProperty(u, "type")) return false;
        if (!hasOwnProperty(u, "value")) return false;
        if (u.type === "number") {
            return typeof u.value === "number";
        } else if (u.type === "string") {
            return typeof u.value === "string";
        } else if (u.type === "boolean") {
            return typeof u.value === "boolean";
        } else if (u.type === "date-time") {
            return u.value instanceof GlideDateTime;
        } else if (u.type === "string-array") {
            return isArray(u.value) && u.value.every(v => typeof v === "string");
        } else if (u.type === "glide-json") {
            if (u.value instanceof GlideJSON) {
                return true;
            } else if (Array.isArray(u.value)) {
                return u.value.every(v => pluginValue.is(v));
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
    // validate
    (i: unknown, context): Either<t.Errors, PluginValue> => {
        if (!hasOwnProperty(i, "type")) return t.failure(i, context, "Missing type");
        if (!hasOwnProperty(i, "value")) return t.failure(i, context, "Missing value");
        if (i.type === "number") {
            if (typeof i.value === "number") {
                return t.success(i as PluginValue);
            } else {
                return t.failure(i, context, "Expected number");
            }
        } else if (i.type === "string") {
            if (typeof i.value === "string") {
                return t.success(i as PluginValue);
            } else {
                return t.failure(i, context, "Expected string");
            }
        } else if (i.type === "boolean") {
            if (typeof i.value === "boolean") {
                return t.success(i as PluginValue);
            } else {
                return t.failure(i, context, "Expected boolean");
            }
        } else if (i.type === "date-time") {
            const decoded = glideDateTimeDocumentDataCodec.decode(i.value);
            if (isLeft(decoded)) return decoded;
            return t.success({ type: "date-time", value: GlideDateTime.fromDocumentData(decoded.right) });
        } else if (i.type === "string-array") {
            if (isArray(i.value) && i.value.every(v => typeof v === "string")) {
                return t.success(i as PluginValue);
            } else {
                return t.failure(i, context, "Expected array of strings");
            }
        } else if (i.type === "glide-json") {
            const decodedJSON = glideJSONDocumentDataCodec.decode(i.value);
            if (isRight(decodedJSON)) {
                return t.success({ type: "glide-json", value: GlideJSON.fromDocumentData(decodedJSON.right) });
            }
            if (isArray(i.value)) {
                const results: PluginValue[] = [];
                for (const v of i.value) {
                    const decoded = pluginValue.decode(v);
                    if (isLeft(decoded)) {
                        return decoded;
                    }
                    results.push(decoded.right);
                }
                return t.success({ type: "glide-json", value: results });
            }
            return t.failure(i, context, "Expected GlideJSON or array");
        } else {
            return t.failure(i, context, "Unknown type");
        }
    },
    // encode
    (a: PluginValue): EncodedPluginValue => {
        if (a.type === "date-time") {
            return { type: "date-time", value: a.value.toDocumentData() };
        } else if (a.type === "glide-json") {
            if (a.value instanceof GlideJSON) {
                return { type: "glide-json", value: a.value.toDocumentData() };
            } else {
                return { type: "glide-json", value: a.value.map(v => pluginValue.encode(v)) };
            }
        } else {
            return a;
        }
    }
);

const pluginValues = t.record(t.string, pluginValue);
export type PluginValues = t.TypeOf<typeof pluginValues>;
export const resultPluginValues = pluginValues;
export type ResultPluginValues = t.TypeOf<typeof pluginValues>;
