import type {
    ComponentDescription,
    ComponentKind,
    LegacyPropertyDescription,
    MutatingScreenKind,
} from "@glide/app-description";
import type { TableColumn } from "@glide/type-schema";
import { makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import type { WireAppAudioPlayerComponent } from "@glide/fluent-components/dist/js/base-components";
import type { AppDescriptionContext } from "@glide/function-utils";
import { PropertySection, makeTextPropertyDescriptor } from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";

import { inflateStringProperty, makeSimpleWireRowComponentHydratorConstructor, spreadComponentID } from "../wire/utils";
import {
    type SimpleComponentDescription,
    SimpleComponentHandler,
    SimplePrimitiveColumnPropertyHandler,
} from "./simple-handler";

const ComponentKindAudioPlayer: ComponentKind = "audio-player";

const audioProperty = new SimplePrimitiveColumnPropertyHandler({
    name: "propertyName",
    label: "Audio",
    preferredType: "audio-uri",
    mustBeString: true,
    preferredNames: ["audio", "sound", "mp3"],
    section: PropertySection.Data,
});

interface AudioPlayerComponentDescription extends SimpleComponentDescription {
    readonly propertyName: LegacyPropertyDescription | undefined;
}

export class AudioPlayerComponentHandler extends SimpleComponentHandler {
    public readonly appKinds = AppKind.App;

    constructor() {
        super({
            kind: ComponentKindAudioPlayer,
            name: "Audio",
            group: "Media",
            description: "Play an audio file from a link",
            img: "co-audio",
            helpPath: "audioPlayer",
        });
    }

    protected getPropertyDescriptors(
        _desc: SimpleComponentDescription,
        mutatingScreenKind: MutatingScreenKind | undefined
    ) {
        return [
            {
                descriptor: () =>
                    makeTextPropertyDescriptor("propertyName", "Audio", "Enter URL", true, mutatingScreenKind, {
                        preferredType: "audio-uri",
                        preferredNames: ["audio", "sound", "mp3"],
                        searchable: false,
                        columnFirst: true,
                    }),
                argumentName: "audio",
            },
        ];
    }

    public inflate(
        ib: WireInflationBackend,
        desc: AudioPlayerComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const [urlGetter, urlType] = inflateStringProperty(ib, desc.propertyName, true);
        if (urlType === undefined) return undefined;

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const url = urlGetter(hb) ?? "";
            if (url === "") return undefined;

            const component: WireAppAudioPlayerComponent = {
                kind: WireComponentKind.AppAudioPlayer,
                ...spreadComponentID(desc.componentID, forBuilder),
                url,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: AudioPlayerComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return {
            ...makeEmptyComponentDescription(WireComponentKind.PageAudio),
            audio: desc.propertyName,
        } as ComponentDescription;
    }
}

export function defaultAudioComponent(column: TableColumn): SimpleComponentDescription {
    return audioProperty.setInDescription<SimpleComponentDescription>(
        { ...makeEmptyComponentDescription(ComponentKindAudioPlayer), actions: undefined },
        column.name
    );
}
