import { useCallback, useEffect } from "react";
import { orgSettings } from "@glide/common-core/dist/js/routes";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import type { EditOrganizationSettingsBody } from "@glide/common-core";
import { GlideIcon } from "@glide/common";

import { useIsDarkTheme } from "../../hooks/use-builder-theme";
import { Button } from "../button/button";
import { useModals } from "../modal-provider-v2/modal-provider-v2";
import { useOrgInfo } from "../../hooks/use-org-info";
import ModalDescription from "./ai-modal";
import "twin.macro";

interface EmptyStateProps {
    enabled: boolean;
    isAdmin: boolean;
    isUserVerified: boolean;
}

const EmptyState = ({ enabled, isAdmin, isUserVerified }: EmptyStateProps) => {
    const isDarkTheme = useIsDarkTheme();
    const org = useOrgInfo().org;
    let emptyStateText: React.ReactNode = (
        <>
            Please describe what you want to build. You can keep refining it along the way.
            <br />
            <a
                tw="text-aqua500 hover:underline!"
                href="https://youtu.be/hbTeegJva_c?si=LXclPKDrVf2TIT6p"
                target="_blank"
                rel="noreferrer">
                Watch intro video
            </a>
        </>
    );
    if (!enabled) {
        emptyStateText = isAdmin
            ? "Please enable AI Features in your team settings to generate a custom component."
            : "Ask your team admin to enable AI Features in the team settings to use the AI Custom component";
        if (!isUserVerified) {
            emptyStateText = "Please verify your email to get the full Glide experience.";
        }
    }

    const modals = useModals();

    const runModal = useCallback(async () => {
        if (org === undefined) return;

        const accepted = await modals.showWindowedModal((_, resolve) => (
            <ModalDescription displayName={org.displayName} onClick={resolve} />
        ));
        if (accepted) {
            await getAppFacilities().callAuthCloudFunction("editOrganizationSettings", {
                organizationID: org.id,
                isAIEnabled: true,
            } as EditOrganizationSettingsBody);
        }
    }, [modals, org]);

    useEffect(() => {
        if (!enabled && isUserVerified && isAdmin) {
            void runModal();
        }
    }, [enabled, isAdmin, isUserVerified, runModal]);

    return (
        <div tw="flex flex-col gap-5 justify-center items-center p-6 my-auto grow">
            {enabled ? (
                <svg width={80} height={80} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        data-is-dark={isDarkTheme}
                        tw="data-[is-dark=true]:fill-bg-middle data-[is-dark=false]:fill-bg-behind"
                        d="M33.904 4.474c2.094-5.659 10.098-5.659 12.192 0l6.912 18.678a6.5 6.5 0 003.84 3.84l18.678 6.912c5.659 2.094 5.659 10.098 0 12.192l-18.678 6.912a6.5 6.5 0 00-3.84 3.84l-6.912 18.678c-2.094 5.659-10.098 5.659-12.192 0l-6.912-18.678a6.5 6.5 0 00-3.84-3.84L4.474 46.096c-5.659-2.094-5.659-10.098 0-12.192l18.678-6.912a6.5 6.5 0 003.84-3.84l6.912-18.678z"
                    />
                </svg>
            ) : (
                <GlideIcon kind="monotone" icon="mt-warning" iconSize={64} tw="text-icon-disabled" />
            )}
            <p tw="text-builder-base text-text-pale text-center [word-break: break-word]">{emptyStateText}</p>
            {!enabled &&
                isUserVerified &&
                org !== undefined &&
                (isAdmin ? (
                    <Button
                        buttonType="primary"
                        variant="default"
                        size="md"
                        onClick={() => window.open(orgSettings(org.id), "_blank")}
                        label="Go to Team Settings"
                        tw="bg-aqua400"
                    />
                ) : (
                    <Button
                        buttonType="primary"
                        variant="default"
                        size="md"
                        disabled={true}
                        label="Admin Role Required"
                        tw="bg-n100A"
                    />
                ))}
        </div>
    );
};

export default EmptyState;
