import { AppIcon } from "@glide/common";

export interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
    size?: number;
    color?: string;
}

export const AlignLeft = (props: IconProps) => <AppIcon icon="01-paragraph-left-align" {...props} />;
export const Check = (props: IconProps) => <AppIcon icon="01-check-1" {...props} />;
export const ChevronLeft = (props: IconProps) => <AppIcon icon="52-arrow-left-1" {...props} />;
export const Clock = (props: IconProps) => <AppIcon icon="01-time-clock-circle-1" {...props} />;
export const Command = (props: IconProps) => <AppIcon icon="01-keyboard-command" {...props} />;
export const Copy = (props: IconProps) => <AppIcon icon="16-common-file-double-2" {...props} />;
export const Database = (props: IconProps) => <AppIcon icon="04-database-2" {...props} />;
export const Edit2 = (props: IconProps) => <AppIcon icon="11-content-pen-3" {...props} />;
export const ExternalLink = (props: IconProps) => <AppIcon icon="01-expand-6" {...props} />;
export const EyeOff = (props: IconProps) => <AppIcon icon="01-view-off" {...props} />;
export const Eye = (props: IconProps) => <AppIcon icon="01-view-1" {...props} />;
export const FilePlus = (props: IconProps) => <AppIcon icon="16-common-file-add" {...props} />;
export const File = (props: IconProps) => <AppIcon icon="16-common-file-empty" {...props} />;
export const Layout = (props: IconProps) => <AppIcon icon="01-layout-6" {...props} />;
export const Link = (props: IconProps) => <AppIcon icon="01-hyperlink-3" {...props} />;
export const List = (props: IconProps) => <AppIcon icon="01-list-bullets" {...props} />;
export const Mail = (props: IconProps) => <AppIcon icon="19-envelope" {...props} />;
export const Map = (props: IconProps) => <AppIcon icon="48-maps" {...props} />;
export const Mic = (props: IconProps) => <AppIcon icon="14-microphone" {...props} />;
export const Minus = (props: IconProps) => <AppIcon icon="01-subtract" {...props} />;
export const Navigation2 = (props: IconProps) => <AppIcon icon="48-compass-arrow" {...props} />;
export const Plus = (props: IconProps) => <AppIcon icon="01-add" {...props} />;
export const RefreshCw = (props: IconProps) => <AppIcon icon="01-button-refresh-arrows" {...props} />;
export const Search = (props: IconProps) => <AppIcon icon="01-search-1" {...props} />;
export const Settings = (props: IconProps) => <AppIcon icon="01-cog" {...props} />;
export const Share = (props: IconProps) => <AppIcon icon="01-share-1" {...props} />;
export const Smartphone = (props: IconProps) => <AppIcon icon="20-mobile-phone" {...props} />;
export const Trash2 = (props: IconProps) => <AppIcon icon="01-bin" {...props} />;
export const Trash = (props: IconProps) => <AppIcon icon="01-bin-1" {...props} />;
export const Upload = (props: IconProps) => <AppIcon icon="05-upload-bottom" {...props} />;
export const User = (props: IconProps) => <AppIcon icon="17-single-neutral" {...props} />;
export const X = (props: IconProps) => <AppIcon icon="01-close" {...props} />;

export type Icon = React.ComponentType<IconProps>;
