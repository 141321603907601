import {
    type TableName,
    type SourceColumn,
    type TableAndColumn,
    type TableColumn,
    type TableGlideType,
    areSourceColumnsEqual,
    SourceColumnKind,
    getNonHiddenColumns,
    getSourceColumnSinglePath,
    getTableName,
    isSourceColumn,
    makeSourceColumn,
    isSingleRelationType,
    makeActionNodeOutputSourceColumn,
} from "@glide/type-schema";
import type { AllowedColumns } from "@glide/generator/dist/js/allowed-columns";
import { getColumnNameAndGroup, makeMissingColumnMessage } from "@glide/generator/dist/js/description-utils";
import { getIconForActionNode } from "@glide/generator/dist/js/prior-step";
import { getEmoji } from "@glide/support";
import { DefaultMap, assert, defined, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import { hasOwnProperty } from "collection-utils";
import * as React from "react";

import {
    type Separator,
    type ItemsType,
    type Group,
    separator,
    isSeparator,
    type ItemDescription,
} from "../../lib/dropdown-types";
import { Button } from "../button/button";
import { type ColumnItem, BaseColumnSelect, isColumnItem, ActionOutputItem } from "./base-column-select";
import type { GetColumnValue } from "./get-column-value";
import { getHeaderIcon, getHeaderIconForTypeKind } from "../../lib/header-icons";
import { ActionIcon } from "../workflows/action-icon";
import { GlideIcon } from "@glide/common";

function groupColumnsInner<T>(
    column: readonly (ColumnItem | Separator | ActionOutputItem)[]
): ItemsType<ColumnItem | ActionOutputItem | T>[] {
    const groups = new DefaultMap<
        string,
        Group<ColumnItem | ActionOutputItem> & { readonly items: ItemsType<ColumnItem | ActionOutputItem>[] }
    >(n => {
        const emoji = getEmoji(n);

        let icon: React.ReactNode = "16-02-folder-empty-1";
        if (emoji !== undefined) {
            n = n.replace(emoji, "");
            icon = <div className="emoji-container">{emoji}</div>;
        }

        return {
            kind: "group",
            name: n,
            icon,
            items: [],
        };
    });
    const items: ItemsType<ColumnItem | ActionOutputItem>[] = [];

    for (const c of column) {
        if (c === separator) {
            items.push(c);
            continue;
        }

        if (c instanceof ActionOutputItem) {
            const groupAndName = definedMap(c.actionOutput.displayName, getColumnNameAndGroup);
            const group = groupAndName?.[0];
            if (group === undefined) {
                items.push(c);
            } else {
                const g = groups.get(group);
                if (g.items.length === 0) {
                    items.push(g);
                }
                g.items.push(c);
            }
        } else {
            const group = definedMap(c.column, getColumnNameAndGroup)?.[0];
            if (group === undefined) {
                items.push(c);
            } else {
                const g = groups.get(group);
                if (g.items.length === 0) {
                    items.push(g);
                }
                g.items.push(c);
            }
        }
    }

    return items;
}

export function groupColumns<T>(column: readonly (ColumnItem | Separator)[]): ItemsType<ColumnItem | T>[] {
    return groupColumnsInner<T>(column) as ItemsType<ColumnItem | T>[];
}

export interface ColumnSelectProps<T> {
    readonly displayLabel?: string;
    readonly label?: string;
    readonly allowedColumns: AllowedColumns;
    readonly selectedValue: SourceColumn | T | undefined;
    readonly allowNone: boolean;
    readonly updateProperty: (v: SourceColumn | T | undefined) => void;
    readonly getColumnValue?: GetColumnValue;
    readonly includeMargins?: boolean;
    readonly showWarning?: boolean;
    readonly drawBorder?: boolean;
    readonly warningMessage?: string;
    readonly defaultDisplayLabel?: string;
    readonly allowProtected: boolean;
    readonly writesToColumn?: TableAndColumn;
    readonly searchable?: boolean; // defaults to `true`
    readonly isEnabled?: boolean; // defaults to `true`
    readonly helpText?: string;
    readonly emptyWarningText?: string;
    readonly warningText?: string;
    readonly warningType?: "error" | "warn";
    readonly showContextAsContainingScreen?: boolean; // defaults to `false`
    readonly showInlineWarning?: boolean; // defaults to `false`
    readonly constantIsSecret?: boolean; // defaults to `false`

    /**
     * Renders items in addition to columns.
     *
     * Elements of this array may contain a `selectColumnsAction` callback
     * that has the same function as the `selectColumnsAction` property.
     * The last `selectColumnsAction` of this array is preferred over
     * the `selectColumnsAction` property, if such a callback can be found.
     */
    readonly extraItems?: readonly ItemsType<T>[];
    readonly nameForExtraItem?: (item: T) => string;
    readonly iconForExtraItem?: (item: T) => React.ReactNode;
    readonly hintForExtraItem?: (item: T) => string | undefined;
    readonly allowWrappingForExtraItem?: (item: T) => boolean;
    readonly columnWrittenForExtraItem?: (item: T) => [TableName, string] | undefined;

    readonly customInputValue?: string;
    readonly customInputPlaceholder?: string;
    readonly onCustomInputChanged?: (newValue: string) => void;
    readonly validateCustomValue?: (v: string) => boolean;

    readonly onRemove?: () => void;

    /**
     * The columns already selected for a TableView by way of a relation.
     *
     * This is only valid if we cannot find a `selectColumnsAction` in the
     * extraItems. The expected use-case as a prop is column selection through
     * a relation instead of a whole table. If we find a `selectColumnsAction`
     *
     * Currently this is not used, but will eventually be ignored in favor
     * of the `selectedColumns` on `extraItems` if an `extraItems` has
     * a `selectColumnsAction`.
     *
     */
    readonly selectedColumns?: readonly string[];

    /**
     * An action to run to select columns through a relation.
     *
     * The presence of this value causes a "Select Columns" button to
     * be rendered.
     *
     * This is only valid if we cannot find a `selectColumnsAction` in
     * the `extraItems`. If we find a `selectColumnsAction` in the extra
     * items, the last such callback is preferred over this one.
     *
     * @returns void
     */
    readonly selectColumnsAction?: () => void;

    readonly navigateToColumn: (appID: string, tableName: TableName, columnName: string | undefined) => Promise<void>;

    readonly testId?: string;
}

interface FlattenableGroup<T> extends Group<T> {
    // We're mutating this when constructing `groupsWithSeparators`
    items: readonly ItemsType<T>[];
    readonly flatten: boolean;
}

class NoneItem {}
const noneItem = new NoneItem();

export function ColumnSelect<T>({
    displayLabel,
    label,
    allowedColumns,
    selectedValue,
    allowNone,
    updateProperty,
    getColumnValue,
    includeMargins,
    showWarning,
    warningMessage,
    onRemove,
    extraItems,
    allowWrappingForExtraItem,
    nameForExtraItem,
    iconForExtraItem,
    hintForExtraItem,
    columnWrittenForExtraItem,
    defaultDisplayLabel,
    customInputValue,
    onCustomInputChanged,
    validateCustomValue,
    customInputPlaceholder,
    drawBorder,
    allowProtected,
    writesToColumn,
    searchable,
    isEnabled,
    helpText,
    emptyWarningText,
    navigateToColumn,
    warningText,
    warningType,
    showContextAsContainingScreen,
    selectColumnsAction,
    showInlineWarning,
    constantIsSecret,
    testId,
}: ColumnSelectProps<T>): JSX.Element {
    const [
        columnItems,
        userProfileColumnItems,
        containingScreenColumnItems,
        selectedItem,
        missingColumn,
        priorColumns,
    ] = React.useMemo(() => {
        const selectedSourceColumn = isSourceColumn(selectedValue) ? selectedValue : undefined;
        let selectedItemInner: ColumnItem | T | ActionOutputItem | undefined;
        let missingColumnInner: SourceColumn | undefined;

        function makeItems(
            table: TableGlideType | undefined,
            fullRow: boolean,
            columns: readonly TableColumn[],
            kind: SourceColumnKind
        ): ItemsType<ColumnItem | T | NoneItem | ActionOutputItem>[] {
            const tableName = definedMap(table, getTableName);
            const isSelectedKind = selectedSourceColumn?.kind === kind;
            const selectedColumnName = isSelectedKind ? getSourceColumnSinglePath(selectedSourceColumn) : undefined;
            const items: (ColumnItem | Separator)[] = mapFilterUndefined(
                getNonHiddenColumns(columns, allowProtected),
                c => {
                    const item: ColumnItem = { kind, column: c, table: tableName };
                    if (c.name === selectedColumnName) {
                        selectedItemInner = item;
                    }
                    return item;
                }
            );
            if (fullRow) {
                const rowItem: ColumnItem = {
                    kind,
                    column: undefined,
                    table: tableName,
                };
                if (isSelectedKind && selectedColumnName === undefined) {
                    selectedItemInner = rowItem;
                }
                if (items.length > 0) {
                    items.unshift(separator);
                }
                items.unshift(rowItem);
            }
            return groupColumns(items);
        }

        const allItems = [
            makeItems(
                allowedColumns.contextTable,
                allowedColumns.contextRow,
                allowedColumns.context,
                SourceColumnKind.DefaultContext
            ),
            makeItems(
                allowedColumns.userProfileTable,
                allowedColumns.userProfileRow,
                allowedColumns.userProfile,
                SourceColumnKind.UserProfile
            ),
            makeItems(
                allowedColumns.containingScreenTable,
                false,
                allowedColumns.containingScreen ?? [],
                SourceColumnKind.ContainingScreen
            ),
        ] as const;

        const pc: FlattenableGroup<ActionOutputItem | ColumnItem>[] = [];
        for (const priorStep of allowedColumns.priorStepsOutputs ?? []) {
            if (priorStep.outputs.length === 0) continue;
            const priorStepKey = priorStep.node.node.key;
            const priorStepName = priorStep.displayName;
            const flatten = priorStep.outputs.length === 1;

            const icon = getIconForActionNode(priorStep.node.node);
            pc.push({
                kind: "group",
                name: priorStepName,
                icon: <ActionIcon iconName={icon} iconSize={20} />,
                items: groupColumnsInner(
                    priorStep.outputs.map(o => {
                        const aoi = new ActionOutputItem(o, !flatten, priorStepName, priorStepKey, priorStep.icon);
                        if (
                            selectedItemInner === undefined &&
                            isSourceColumn(selectedValue) &&
                            areSourceColumnsEqual(
                                selectedValue,
                                makeActionNodeOutputSourceColumn(priorStepKey, o.name, o.columnName)
                            )
                        ) {
                            selectedItemInner = aoi;
                        }

                        return aoi;
                    })
                ),
                flatten,
            });
        }

        if (selectedItemInner === undefined) {
            if (isSourceColumn(selectedValue)) {
                missingColumnInner = selectedValue;
            } else {
                selectedItemInner = selectedValue;
            }
        }

        return [...allItems, selectedItemInner, missingColumnInner, pc];
    }, [allowedColumns, allowProtected, selectedValue]);

    if (warningMessage === undefined) {
        warningMessage = definedMap(missingColumn, makeMissingColumnMessage);
        if (warningMessage !== undefined) {
            showWarning = true;
        }
    }

    let rowGroup: FlattenableGroup<ColumnItem | T | NoneItem | ActionOutputItem> | undefined;
    let userGroup: FlattenableGroup<ColumnItem | T | NoneItem | ActionOutputItem> | undefined;
    let screenGroup: FlattenableGroup<ColumnItem | T | NoneItem | ActionOutputItem> | undefined;

    let needNone = allowNone;
    if (columnItems.length > 0) {
        if (needNone) {
            columnItems.unshift(noneItem);
            needNone = false;
        }
        if (showContextAsContainingScreen) {
            screenGroup = {
                kind: "group",
                name: allowedColumns?.containingScreenTableLabel ?? "Screen",
                items: columnItems,
                icon: "containingScreenContext",
                flatten: false,
            };
        } else {
            rowGroup = {
                kind: "group",
                name: "Columns",
                items: columnItems,
                flatten: true,
            };
        }
    }
    if (userProfileColumnItems.length > 0) {
        userGroup = {
            kind: "group",
            name: "User Profile",
            items: userProfileColumnItems,
            icon: "userCircle",
            flatten: false,
        };
    }
    if (containingScreenColumnItems.length > 0) {
        assert(screenGroup === undefined);
        screenGroup = {
            kind: "group",
            name: allowedColumns?.containingScreenTableLabel ?? "Screen",
            items: containingScreenColumnItems,
            icon: "containingScreenContext",
            flatten: false,
        };
    }

    const groups: FlattenableGroup<ColumnItem | T | NoneItem | ActionOutputItem>[] = [];
    if (userGroup !== undefined) {
        groups.push(userGroup);
    }
    if (screenGroup !== undefined) {
        groups.push(screenGroup);
    }
    if (rowGroup !== undefined) {
        groups.push(rowGroup);
    }

    const groupsWithSeparators: (FlattenableGroup<ColumnItem | T | NoneItem | ActionOutputItem> | Separator)[] = [];
    for (let i = 0; i < groups.length; i++) {
        const g = groups[i];
        const next = groups[i + 1];

        if (next === undefined) {
            groupsWithSeparators.push(g);
        } else if (g.flatten === true) {
            g.items = [...g.items, separator];
            groupsWithSeparators.push(g);
        } else if (next.flatten === true) {
            next.items = [separator, ...next.items];
            groupsWithSeparators.push(g);
        } else {
            groupsWithSeparators.push(g);
            groupsWithSeparators.push(null);
        }
    }

    for (const priorColumn of priorColumns) {
        groupsWithSeparators.push(priorColumn);
    }

    const items: ItemsType<ColumnItem | T | NoneItem | ActionOutputItem>[] = [];
    let needSeparator = false;
    if (extraItems !== undefined && extraItems.length > 0) {
        items.push(...extraItems);
        needSeparator = true;
    }

    function push(...a: readonly ItemsType<ColumnItem | T | NoneItem | ActionOutputItem>[]) {
        if (a.length === 0) return;
        if (needSeparator) {
            items.push(separator);
            needSeparator = false;
        }
        items.push(...a);
    }

    if (needNone) {
        items.unshift(noneItem);
        needNone = false;
    }
    for (const g of groupsWithSeparators) {
        if (isSeparator(g)) {
            push(g);
        } else if (g.flatten) {
            push(...g.items);
        } else {
            push(g);
        }
    }

    const defaultLabel = defaultDisplayLabel ?? "—";

    const descriptionForItem = React.useCallback(
        (c: T | NoneItem | ActionOutputItem): ItemDescription => {
            if (c instanceof NoneItem) {
                return {
                    name: "—",
                    layoutStyle: "vertical",
                };
            } else if (c instanceof ActionOutputItem) {
                const outputName =
                    definedMap(c.actionOutput.displayName, getColumnNameAndGroup)?.[1] ?? c.actionOutput.name;

                const column = definedMap(c.actionOutput.table, t =>
                    t.columns.find(col => col.name === c.actionOutput.columnName)
                );

                const shouldShowActionIcon =
                    c.actionOutput.table !== undefined || isSingleRelationType(c.actionOutput.type);

                return {
                    name: c.isGrouped ? outputName : c.actionName,
                    layoutStyle: "vertical",
                    actionIcon: shouldShowActionIcon ? "01-04-logout-alternate" : undefined,
                    icon:
                        column !== undefined ? (
                            <GlideIcon {...getHeaderIcon(column)} />
                        ) : c.isGrouped ? (
                            <GlideIcon {...getHeaderIconForTypeKind(c.actionOutput.type.kind)} />
                        ) : (
                            <ActionIcon iconName={c.icon} iconSize={20} />
                        ),
                };
            } else {
                return {
                    name: nameForExtraItem?.(c) ?? defaultLabel,
                    icon: iconForExtraItem?.(c),
                    hint: hintForExtraItem?.(c),
                    wrapName: allowWrappingForExtraItem?.(c),
                    columnWritten: columnWrittenForExtraItem?.(c),
                    layoutStyle: "vertical",
                };
            }
        },
        [
            nameForExtraItem,
            defaultLabel,
            iconForExtraItem,
            hintForExtraItem,
            allowWrappingForExtraItem,
            columnWrittenForExtraItem,
        ]
    );

    const onItemSelect = React.useCallback(
        (i: T | ColumnItem | NoneItem | ActionOutputItem) => {
            if (isColumnItem(i)) {
                if (i.column === undefined) {
                    updateProperty({ kind: i.kind, name: [] });
                } else {
                    updateProperty(makeSourceColumn(i.column.name, i.kind));
                }
            } else if (i instanceof NoneItem) {
                updateProperty(undefined);
            } else if (i instanceof ActionOutputItem) {
                updateProperty(makeActionNodeOutputSourceColumn(i.key, i.actionOutput.name, i.actionOutput.columnName));
            } else {
                updateProperty(i);
            }
        },
        [updateProperty]
    );

    // We don't always get a selectColumnsAction for our items;
    // instead it might be laundered in through the items. In this case
    // we have to extract it.
    //
    // The precise reason this would have happened is when we pick a table
    // by way of a relation column picker. It's actually a column picker, it
    // just has tables as "extra items" that are going to contain the relevant
    // selectColumnsAction.
    //
    // This is a very tricky action to get right: it has to "know" which of the entries
    // it's bound to. This means that you should only set it for the selected item.
    // If this item is a column, it needs to get passed in as a direct prop. If this
    // item is a table, it needs to be passed in as the `selectColumnsAction` for the
    // relevant item.
    if (selectColumnsAction === undefined) {
        for (const item of items) {
            if (hasOwnProperty(item, "selectColumnsAction") && typeof item.selectColumnsAction === "function") {
                selectColumnsAction = item.selectColumnsAction as () => void;
                break;
            }
        }
    }

    return (
        <>
            <BaseColumnSelect<T | NoneItem | ActionOutputItem>
                testId={testId}
                displayLabel={displayLabel}
                label={label}
                items={items}
                searchable={searchable !== false}
                isEnabled={isEnabled}
                getColumnValue={getColumnValue}
                slim={includeMargins === false}
                defaultDisplayLabel={defaultLabel}
                navigateToColumn={navigateToColumn}
                descriptionForItem={descriptionForItem}
                onItemSelect={onItemSelect}
                selected={selectedItem}
                showWarning={showWarning}
                warnMessage={warningMessage}
                onRemove={onRemove}
                drawBorder={drawBorder}
                validateCustomValue={validateCustomValue}
                customInputValue={customInputValue}
                customInputPlaceholder={customInputPlaceholder}
                onCustomInputChanged={onCustomInputChanged}
                writesToColumn={writesToColumn}
                helpText={helpText}
                emptyWarningText={emptyWarningText}
                warningText={warningText}
                warningType={warningType}
                showInlineWarning={showInlineWarning}
                constantIsSecret={constantIsSecret}
            />
            {selectColumnsAction !== undefined && (
                <div tw="flex justify-end">
                    <Button
                        label="Select columns"
                        variant="accent"
                        size="xsm"
                        buttonType="minimal"
                        onClick={() => defined(selectColumnsAction)()}
                    />
                </div>
            )}
        </>
    );
}
