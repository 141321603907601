import { styled } from "@glide/common";

export const TitleArea = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    color: ${p => p.theme.fgColorDarkest};
    font-size: 13px;
    font-weight: 400;
    cursor: default;

    &.highlight {
        font-weight: 600;
        color: ${p => p.theme.b500};
        filter: drop-shadow(0 0 4px ${p => p.theme.b300});
    }
`;
