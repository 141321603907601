import { freeSubscription, org } from "./lib/test-mocks";
import type { PropsWithChildren } from "react";
import { SubscriptionContext } from "./context";
import type { SubscriptionContextProps } from "./context";

const defaultContext: SubscriptionContextProps = {
    subscription: freeSubscription,
    organization: org,
    showUpgradeModal: async () => {
        // eslint-disable-next-line no-console
        console.log("showUpgradeModal");
    },
};

export const SubscriptionStoryContext: React.FC<
    React.PropsWithChildren<PropsWithChildren<Partial<SubscriptionContextProps>>>
> = ({ children, ...props }) => {
    return (
        <SubscriptionContext.Provider value={{ ...defaultContext, ...props }}>{children}</SubscriptionContext.Provider>
    );
};
