import {
    type Description,
    type ColumnType,
    type TableColumn,
    type TableGlideType,
    getTableColumn,
    getTableName,
    isSingleRelationType,
    type SchemaInspector,
} from "@glide/type-schema";
import { isLoadingValue } from "@glide/computation-model-types";
import { asRow, loadedDefinedMap } from "@glide/common-core/dist/js/computation-model/data";
import {
    ActionKind,
    ArrayScreenFormat,
    type ComponentDescription,
    type ComponentKind,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    PropertyKind,
    getColumnProperty,
    makeActionProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import {
    ComponentKindInlineList,
    type InputOutputTables,
    makeEmptyComponentDescription,
    makeInputOutputTables,
} from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { ListItemFlags } from "@glide/component-utils";
import type { WireAppAdaptiveListItemComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type ComponentErrorAndLink,
    type InteractiveComponentConfiguratorContext,
    type PropertyDescriptor,
    type PropertyTable,
    PropertySection,
    classScreenName,
    componentErrorAndLinkForReference,
} from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { panic, definedMap } from "@glideapps/ts-necessities";
import { extractInitials, isEmptyOrUndefined, logError, nullToUndefined } from "@glide/support";
import {
    type WireInflationBackend,
    type WireRowComponentHydratorConstructor,
    WireActionResult,
    PageScreenTarget,
    WireComponentKind,
    makeContextTableTypes,
    CardStyle,
    UIStyleVariant,
    UISize,
    UIImageStyle,
} from "@glide/wire";
import { summaryColumnsForTable } from "../description-utils";
import {
    inflateBuilderEditableImage,
    inflateStringProperty,
    makeRowGetter,
    makeSimpleWireRowComponentHydratorConstructor,
    registerActionRunner,
    spreadComponentID,
} from "../wire/utils";
import { makeTitleSubtitleImagePropertyDescriptors, useFallbackInitialsPropertyHandler } from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";
import type { CardCollectionComponentDescription } from "@glide/fluent-components/dist/js/fluent-components";

const ComponentKindReference: ComponentKind = "reference";

interface ReferenceComponentDescription extends ComponentDescription {
    readonly propertyName: LegacyPropertyDescription | undefined;

    readonly titleProperty: PropertyDescription | undefined;
    readonly subtitleProperty: PropertyDescription | undefined;
    readonly imageURLProperty: PropertyDescription | undefined;

    readonly useFallbackInitials?: PropertyDescription;
}

function getIndirectTable(
    tables: InputOutputTables | undefined,
    rootDesc: Description,
    _desc: Description,
    schema: SchemaInspector
): PropertyTable | undefined {
    const columnProperty = getColumnProperty((rootDesc as ReferenceComponentDescription).propertyName);
    if (columnProperty === undefined) return undefined;

    const column = definedMap(tables, t => getTableColumn(t.input, columnProperty));
    if (column === undefined) return undefined;

    if (!isSingleRelationType(column.type)) {
        logError("Reference column is not a table-ref");
        return undefined;
    }

    const relationTable = schema.findTable(column.type);
    if (relationTable === undefined) return undefined;

    return { table: relationTable, inScreenContext: false };
}

function isAllowedType(t: ColumnType): boolean {
    return isSingleRelationType(t);
}

function getAllowedColumns(table: TableGlideType): readonly TableColumn[] {
    return table.columns.filter(c => isAllowedType(c.type));
}

export class ReferenceComponentHandler extends ComponentHandlerBase<ReferenceComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindReference);
    }

    public getDescriptor(
        _desc: ReferenceComponentDescription,
        _tables: InputOutputTables | undefined,
        _ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [
            {
                kind: PropertyKind.Column,
                property: { name: "propertyName" },
                label: "Column",
                required: true,
                editable: true,
                searchable: false,
                columnFilter: { getCandidateColumns: t => t.columns, columnTypeIsAllowed: isAllowedType },
                section: PropertySection.Data,
            },
            ...makeTitleSubtitleImagePropertyDescriptors(mutatingScreenKind, true, true, getIndirectTable),
            useFallbackInitialsPropertyHandler,
            ...this.getBasePropertyDescriptors(),
        ];

        return {
            name: "Relation",
            description: "A link to another part of your app",
            img: "co-relation",
            group: "Buttons",
            helpUrl: getDocURL("reference"),
            properties,
        };
    }

    public getScreensUsed(
        desc: ReferenceComponentDescription,
        schema: SchemaInspector,
        tables: InputOutputTables | undefined
    ): readonly string[] {
        if (tables === undefined) return [];
        const table = getIndirectTable(tables, desc, desc, schema)?.table;
        if (table === undefined) return [];
        return [classScreenName(getTableName(table))];
    }

    public static defaultComponent(
        schema: SchemaInspector,
        column: TableColumn
    ): ReferenceComponentDescription | undefined {
        if (!isSingleRelationType(column.type)) {
            return panic("Reference column is not a table-ref");
        }

        const desc: ReferenceComponentDescription = {
            ...makeEmptyComponentDescription(ComponentKindReference),
            propertyName: makeColumnProperty(column.name),
            titleProperty: undefined,
            subtitleProperty: undefined,
            imageURLProperty: undefined,
            useFallbackInitials: useFallbackInitialsPropertyHandler.defaultProperty,
        };

        const summary = definedMap(schema.findTable(column.type), t => summaryColumnsForTable(t, true, false));
        if (summary === undefined) return undefined;
        Object.assign(desc, summary);

        return desc;
    }

    // This is only here so it's possible to add this component without there being
    // a reference column.
    public newComponent(
        tables: InputOutputTables,
        usedColumns: ReadonlySet<TableColumn>,
        editedColumns: ReadonlySet<TableColumn>,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ReferenceComponentDescription | undefined {
        const desc = super.newComponent(tables, usedColumns, editedColumns, iccc, mutatingScreenKind);
        if (desc !== undefined) return desc;

        return {
            ...makeEmptyComponentDescription(ComponentKindReference),
            propertyName: undefined,
            titleProperty: undefined,
            subtitleProperty: undefined,
            imageURLProperty: undefined,
            useFallbackInitials: useFallbackInitialsPropertyHandler.defaultProperty,
        };
    }

    public getErrorAndLink(
        desc: ReferenceComponentDescription,
        table: TableGlideType
    ): ComponentErrorAndLink | undefined {
        if (getAllowedColumns(table).length > 0 || getColumnProperty(desc.propertyName) !== undefined) {
            return undefined;
        }

        return componentErrorAndLinkForReference(table, false);
    }

    public inflate(
        ib: WireInflationBackend,
        desc: ReferenceComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const maybeRowGetter = makeRowGetter(ib, desc.propertyName, { inOutputRow: false, defaultToThisRow: false });
        if (maybeRowGetter === undefined || maybeRowGetter === false) return undefined;
        const { rowGetter, table: relationTable } = maybeRowGetter;
        const relationTables = makeContextTableTypes(makeInputOutputTables(relationTable));

        const relationIB = ib.makeInflationBackendForTables(relationTables, ib.mutatingScreenKind);

        const [titleGetter] = inflateStringProperty(relationIB, desc.titleProperty, true);
        const [subtitleGetter] = inflateStringProperty(relationIB, desc.subtitleProperty, true);
        const imageURLGetter = inflateBuilderEditableImage(relationIB, desc.imageURLProperty);

        const useFallbackInitials = useFallbackInitialsPropertyHandler.getSwitch(desc);

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            const row = loadedDefinedMap(nullToUndefined(rowGetter(hb)), asRow);
            if (row === undefined || isLoadingValue(row)) return undefined;

            const relationHB = hb.makeHydrationBackendForRow(row, undefined, relationTables);

            const title = titleGetter(relationHB) ?? undefined;
            const subtitle = subtitleGetter(relationHB);
            const imageURL = imageURLGetter(relationHB);

            let initials: string | undefined;
            if (useFallbackInitials && !isEmptyOrUndefined(title)) {
                initials = extractInitials(title);
            }

            const onTap = registerActionRunner(hb, "", [
                async ab => {
                    ab.pushDefaultClassScreen(
                        getTableName(relationTable),
                        row,
                        undefined,
                        PageScreenTarget.Current,
                        undefined
                    );
                    return WireActionResult.nondescriptSuccess();
                },
                undefined,
            ]);

            const component: WireAppAdaptiveListItemComponent = {
                kind: WireComponentKind.AppAdaptiveListItem,
                ...spreadComponentID(desc.componentID, forBuilder),
                title: title ?? null,
                subtitle,
                caption: null,
                image: imageURL,
                initials,
                onTap,
                flags: ListItemFlags.ForceListStyle,
            };
            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(desc: ReferenceComponentDescription): CardCollectionComponentDescription | undefined {
        return {
            ...makeEmptyComponentDescription(ComponentKindInlineList),
            caption: undefined,
            format: makeEnumProperty(ArrayScreenFormat.CardCollection),
            cardStyle: makeEnumProperty(CardStyle.List),
            propertyName: desc.propertyName,
            allowSearch: makeSwitchProperty(false),
            action: makeActionProperty({ kind: ActionKind.PushDetailScreen }),
            cardCollectionComponentStyle: makeEnumProperty(UIStyleVariant.Minimal),
            size: makeEnumProperty(UISize.Medium),
            imageStyle: makeEnumProperty(UIImageStyle.Circle),
            title: desc.titleProperty,
            subtitle: desc.subtitleProperty,
            image: desc.imageURLProperty,
        };
    }
}
