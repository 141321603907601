import { GlideIcon } from "@glide/common";
import { css } from "styled-components";
import tw from "twin.macro";

interface PagesCheckboxProps {
    readonly checked: boolean;
    readonly onChange: (newValue: boolean) => void;
    readonly disabled?: boolean;
    readonly className?: string;
    readonly testID?: string;
}

export const PagesCheckbox: React.VFC<PagesCheckboxProps> = p => {
    const { checked, onChange, disabled, className, testID } = p;

    const onChangeImpl = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
    };

    return (
        <div tw="relative shrink-0 w-4 h-4 my-0.5 mr-2" className={className}>
            <input
                data-testid={testID}
                type="checkbox"
                tw="w-0 h-0 absolute opacity-0"
                checked={checked}
                onChange={onChangeImpl}
                disabled={disabled}
            />
            <div
                tw="w-full min-h-full rounded ring-1 ring-n400A transition-all cursor-pointer page-hover:(ring-2)"
                css={css`
                    input:checked + & {
                        ${tw`ring-text-contextual-accent bg-text-contextual-accent`}
                    }

                    input:active + &,
                    input:focus-visible + & {
                        ${tw`ring-2`}
                    }

                    input:disabled + & {
                        ${tw`bg-icon-disabled`}
                    }
                    .style-accent-bg &,
                    .style-dark & {
                        ${tw`ring-border-dark`}
                    }
                `}
            />
            {checked && (
                <div tw="absolute inset-0 flex items-center justify-center pointer-events-none">
                    <GlideIcon kind="stroke" iconSize={16} icon="st-checkmark" tw="text-text-contextual-inverse" />
                </div>
            )}
        </div>
    );
};
