import type { AnyIcon } from "@glide/plugins";
import { type IconImage, isIconImage } from "@glide/app-description";
import { hasOwnProperty } from "@glideapps/ts-necessities";
import type { ButtonHTMLAttributes } from "react";

export const ButtonTypeValues = ["primary", "secondary", "tertiary", "minimal"] as const;
export type ButtonType = (typeof ButtonTypeValues)[number];

export const ButtonVariantsValue = [
    "default",
    "accent",
    "gradient",
    "inverse",
    "success",
    "warning",
    "danger",
] as const;

export type ButtonVariants = (typeof ButtonVariantsValue)[number];
const IconTypeValue = ["default", "iconLeading", "iconTrailing", "segmented", "iconOnly"] as const;
export type IconType = (typeof IconTypeValue)[number];
export const ButtonSizesValue = ["xsm", "sm", "md", "lg", "xlg", "2xlg"] as const;
export type ButtonSizes = (typeof ButtonSizesValue)[number];
const SplitTypeValue = ["left", "right", "middle"] as const;
export type SplitType = (typeof SplitTypeValue)[number];

interface CustomButtonIcon {
    image: IconImage;
    color: string;
}

export function isCustomButtonIcon(icon: unknown): icon is CustomButtonIcon {
    return (
        icon !== undefined &&
        icon !== null &&
        typeof icon === "object" &&
        hasOwnProperty(icon, "image") &&
        isIconImage(icon.image) &&
        hasOwnProperty(icon, "color") &&
        typeof icon.color === "string"
    );
}

export type ButtonIcon = CustomButtonIcon | AnyIcon;

export type ButtonStyleProps = {
    readonly variant: ButtonVariants;
    readonly buttonType: ButtonType;
    readonly size: ButtonSizes;
    readonly label: string;

    readonly onClick?: (event: React.MouseEvent) => void;
    readonly onPointerDown?: (event: React.MouseEvent) => void;
    // Need to `any` this ref cause it can be a button or an anchor and TS doesn't like the union type or HTMLElement.
    readonly buttonRef?: React.Ref<any>;
    readonly split?: SplitType;
    readonly iconType?: IconType;
    readonly href?: string;
    readonly localHref?: string;
    readonly className?: string;
    readonly disabled?: boolean;
    readonly isFullWidth?: boolean;
    readonly isProcessing?: boolean;
    readonly minWidth?: number;
    readonly icon?: ButtonIcon;
    readonly type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
    readonly id?: string;
    readonly isDropdown?: boolean;
    readonly centeredTextAndIcon?: boolean;
    readonly iconRotation?: number | "spin";
    readonly testId?: string;
};

export const IconSize = {
    xsm: 14,
    sm: 16,
    md: 16,
    lg: 16,
    xlg: 24,
    "2xlg": 24,
};

const SegmentedIconSize = {
    xsm: 12,
    sm: 16,
    md: 16,
    lg: 20,
    xlg: 24,
    "2xlg": 24,
};

export const ButtonSizeMap = {
    xsm: 24,
    sm: 28,
    md: 32,
    lg: 36,
    xlg: 40,
    "2xlg": 48,
};

export const getIconSize = (size: ButtonSizes, iconType?: IconType, split?: SplitType) => {
    if (split && iconType === "iconOnly") {
        return SegmentedIconSize[size];
    }
    return IconSize[size];
};

export type ButtonStyles =
    | "primarydefault"
    | "primarygradient"
    | "primaryinverse"
    | "primarysuccess"
    | "primarywarning"
    | "primarydanger"
    | "secondarydefault"
    | "secondaryaccent"
    | "secondarygradient"
    | "secondarysuccess"
    | "secondarywarning"
    | "secondarydanger"
    | "tertiarydefault"
    | "tertiaryaccent"
    | "tertiarygradient"
    | "tertiarysuccess"
    | "tertiarywarning"
    | "tertiarydanger"
    | "minimaldefault"
    | "minimalaccent"
    | "minimalgradient"
    | "minimalsuccess"
    | "minimalwarning"
    | "minimaldanger";

export const backgroundColorMapping: { [key: string]: string } = {
    primarydefault: "accentPrimary",
    primarygradient: "aqua",
    primaryinverse: "n900",
    primarysuccess: "g400",
    primarywarning: "y400",
    primarydanger: "r400",
    secondarydefault: "n200A",
    secondaryaccent: "b100",
    secondarygradient: "aqua100",
    secondaryinverse: "n200A",
    secondarysuccess: "g100",
    secondarywarning: "y100",
    secondarydanger: "r100",
};

export const backgroundHoverStyles: { [key in ButtonStyles]?: string } = {
    secondarydefault: "n300A",
    secondarygradient: "aqua200",
    secondaryaccent: "b200",
    secondarysuccess: "g200",
    secondarywarning: "y200",
    secondarydanger: "r200",
    minimaldefault: "n200A",
    minimalgradient: "n100A",
    minimalaccent: "b100",
    minimalsuccess: "g100",
    minimalwarning: "y100",
    minimaldanger: "r100",
};

// exception for Gradients will be made else where
export const fontColorMapping: { [key: string]: string } = {
    secondarydefault: "n800A",
    secondaryaccent: "b400",
    secondaryinverse: "n800A",
    secondarysuccess: "g500",
    secondarywarning: "y500",
    secondarydanger: "r500",
    tertiarydefault: "n800A",
    tertiaryaccent: "b400",
    tertiarysuccess: "g500",
    tertiarywarning: "y500",
    tertiarydanger: "r500",
    minimaldefault: "n800A",
    minimalaccent: "b400",
    minimalsuccess: "g500",
    minimalwarning: "y500",
    minimaldanger: "r500",
};
