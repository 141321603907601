export const answerQuestion = (
    question: string
) => `I am a highly intelligent question answering bot. If you ask me a question that is rooted in truth, I will give you the answer. If you ask me a question that is nonsense, trickery, or has no clear answer, I will respond with "Unknown".

Q: What is human life expectancy in the United States?
A: Human life expectancy in the United States is 78 years.

Q: Who was president of the United States in 1955?
A: Dwight D. Eisenhower was president of the United States in 1955.

Q: Which party did he belong to?
A: He belonged to the Republican Party.

Q: What is the square root of banana?
A: Unknown

Q: How does a telescope work?
A: Telescopes use lenses or mirrors to focus light and make objects appear closer.

Q: Where were the 1992 Olympics held?
A: The 1992 Olympics were held in Barcelona, Spain.

Q: How many squigs are in a bonk?
A: Unknown

Q: ${question}
A: `;

export const sentimentAnalysis = (
    phrase: string
) => `You classify text as positive, negative, or neutral. (Ensure the answer is lowercase)

Q: The food sucks at this restaurant.
A: negative

Q: The food is good at this restaurant.
A: positive

Q: The food is okay at this restaurant.
A: neutral

Q: ${phrase.replace(/\n/g, "")}
A: `;

export const suggestEmoji = (
    phrase: string
) => `You suggest emoji for any given text. You only suggest a single emoji for any text.

Q: The food sucks at this restaurant.
A: 👎

Q: The food is good at this restaurant.
A: 👍

Q: I love you
A: ❤️

Q: I am melting!
A: 🫠

Q: 🤗
A: 🤗

Q: ${phrase.replace(/\n/g, "")}
A: `;

export const tableQuestion = (
    questionPrompt: string,
    promptContext: string,
    question: string
) => `You are a helpful and intelligent person, answering questions about the table. ${questionPrompt}

Table<column1: value1 | column2: value2 | ...>: ###
${promptContext}
###

Question: ${question}
Answer: `;
