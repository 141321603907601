import * as React from "react";

export function useMediaQuery(query: string): boolean {
    const match = window.matchMedia(query);
    const [doesMatch, setDoesMatch] = React.useState(match.matches);

    React.useEffect(() => {
        const onChangeMatch = () => {
            setDoesMatch(match.matches);
        };

        // First, make sure we're in sync by the time this effect is triggered.
        onChangeMatch();

        // Safari still doesn't implement the standard `addEventListener` here.
        // It uses the deprecated `addListener`.
        if ("addEventListener" in match) {
            match.addEventListener("change", onChangeMatch);

            return () => {
                match.removeEventListener("change", onChangeMatch);
            };
        } else {
            match.addListener(onChangeMatch);

            return () => {
                match.removeListener(onChangeMatch);
            };
        }
    }, [match]);

    return doesMatch;
}
