import type {
    ActionAppFacilities,
    IntegrationInstanceRequest,
    IntegrationInstanceResult,
    IntegrationsAggregator,
} from "@glide/common-core/dist/js/components/types";
import {
    type RunIntegrationsBody,
    runIntegrationsInstanceResponse,
    writebackResponseCodec,
} from "@glide/common-core/dist/js/firebase-function-types";
import { Result } from "@glide/plugins";
import { assert, defined } from "@glideapps/ts-necessities";
import { isEmptyOrUndefined, sortNestedObject } from "@glide/support";
import throttle from "lodash/throttle";
import { isLeft } from "fp-ts/lib/Either";

interface InstanceResult {
    readonly resolver: (result: IntegrationInstanceResult) => void;
    readonly instanceData: IntegrationInstanceRequest;
    readonly id: string;
}

export class PlayerIntegrationsAggregator implements IntegrationsAggregator {
    private instanceResults: InstanceResult[] = [];

    // Memory leak
    static instances: Map<string, PlayerIntegrationsAggregator> = new Map();
    public static get(appFacilities: ActionAppFacilities, props: Omit<RunIntegrationsBody, "instances">) {
        const fetchKey = JSON.stringify(sortNestedObject(props));
        let r = PlayerIntegrationsAggregator.instances.get(fetchKey);
        if (r === undefined) {
            r = new PlayerIntegrationsAggregator(appFacilities, props);
            PlayerIntegrationsAggregator.instances.set(fetchKey, r);
        }
        return r;
    }

    /** For testing/debugging only! */
    public static clearInstances(): void {
        PlayerIntegrationsAggregator.instances.clear();
    }

    private constructor(
        private readonly appFacilities: ActionAppFacilities,
        private readonly base: Omit<RunIntegrationsBody, "instances">
    ) {}

    public runIntegration(instanceData: IntegrationInstanceRequest): Promise<IntegrationInstanceResult> {
        const result = new Promise<IntegrationInstanceResult>(resolver => {
            this.instanceResults.push({
                resolver,
                instanceData,
                id: instanceData.data.instanceID,
            });
        });

        this.onRun();

        return result;
    }

    private onRun = throttle(() => {
        void this.runFetch();
    }, 30);

    private runFetch = async () => {
        const toRun = this.instanceResults;
        this.instanceResults = [];

        const fetchBody: RunIntegrationsBody = {
            ...this.base,
            instances: toRun.map(x => x.instanceData.data),
        };

        const response: Response | undefined = await this.appFacilities.callAuthIfAvailableCloudFunction(
            "runIntegrations",
            fetchBody,
            {}
        );

        if (response?.status === 402) {
            const reason = await response.text();
            for (const r of toRun) {
                r.resolver(Result.Fail(reason));
            }
            return;
        }

        const reader = response?.body?.getReader();
        if (reader === undefined) return;
        const handledIDs = new Set<string>();

        const textDecoder = new TextDecoder();
        let buffer = "";

        const processEvents = (chunk: string) => {
            buffer += chunk;
            const events = buffer.split("\n");
            while (events.length > 1) {
                const event = defined(events.shift());
                let parsed;
                try {
                    parsed = JSON.parse(event);
                } catch {
                    continue;
                }
                if (!runIntegrationsInstanceResponse.is(parsed)) continue;
                const { instanceID, statusCode, isPermanent, data, continueWithSignal } = parsed;
                // We only support signals in automations
                assert(continueWithSignal === undefined);
                const d = toRun.find(x => x.id === instanceID);
                if (d === undefined) continue;
                handledIDs.add(instanceID);
                if (statusCode >= 300)
                    d.resolver(
                        Result.FailFromHTTPStatus(
                            typeof data?.message === "string"
                                ? data.message
                                : `Unknown error running integration ${this.base.actionKind}`,
                            statusCode,
                            {
                                ...(typeof data?.data === "object" ? data.data : undefined),
                                isPermanent,
                            }
                        )
                    );

                const decoded = writebackResponseCodec.decode(data);
                if (isLeft(decoded)) {
                    d.resolver(Result.Fail("Could not decode integration response", { data }));
                } else {
                    d.resolver(Result.Ok(decoded.right));
                }
            }
            buffer = events[0];
        };

        while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            const chunk = textDecoder.decode(value);
            processEvents(chunk);
        }

        // Process any remaining events in the buffer
        if (!isEmptyOrUndefined(buffer)) {
            processEvents("\n");
        }

        for (const r of toRun) {
            if (!handledIDs.has(r.instanceData.data.instanceID)) {
                r.resolver(Result.Fail("No response"));
            }
        }
    };
}
