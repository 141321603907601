import type { ColumnType, ColumnTypeKind, TableColumn, TableGlideType } from "@glide/type-schema";
import {
    getNonHiddenColumns,
    getTableColumn,
    getTableColumnDisplayName,
    isDateOrDateTimeTypeKind,
    isLinkTypeKind,
    isNumberTypeKind,
    isPrimitiveType,
} from "@glide/type-schema";
import { definedMap } from "@glideapps/ts-necessities";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { titleProperties } from "./property/description-utils";
import type { ColumnTypePredicate } from "./property/lib";

// `preferredDisplayNames` must be lowercase. `excludeNames` must have exact
// case, since it contains table names, not display names.
export function findFittingColumn(
    acceptedColumnns: ReadonlyArray<TableColumn>,
    excludeColumn: TableColumn | undefined,
    preferredDisplayNames: ReadonlyArray<string>,
    avoidColumnNames: ReadonlySet<string> | undefined,
    avoidColumnNamesSecondary: ReadonlySet<string> | undefined,
    excludeNames: ReadonlySet<string> | undefined,
    preferredType: ((t: ColumnType) => boolean) | ColumnTypeKind | undefined,
    excludeWrongPrimitiveTypes: boolean
): TableColumn | undefined {
    // FIXME: Don't use strict sorting, but give scores.  A full name match
    // would score high, a prefix or postfix match would score medium, and
    // a partial match in the middle would score low, for example.
    const preferType = typeof preferredType === "string" ? (t: ColumnType) => t.kind === preferredType : preferredType;
    let excludeType: ((t: ColumnType) => boolean) | undefined;
    if (excludeWrongPrimitiveTypes) {
        if (preferredType === "string") {
            excludeType = ({ kind }: ColumnType) =>
                isLinkTypeKind(kind) || isDateOrDateTimeTypeKind(kind) || isNumberTypeKind(kind);
        } else if (preferredType === "image-uri") {
            excludeType = ({ kind }: ColumnType) => kind !== "image-uri";
        } else if (typeof preferredType === "string" && isLinkTypeKind(preferredType)) {
            excludeType = ({ kind }: ColumnType) => !isLinkTypeKind(kind);
        }
    }
    const filtered = reverse(Array.from(acceptedColumnns)).filter(
        c =>
            c !== excludeColumn &&
            (excludeNames === undefined || !excludeNames.has(c.name)) &&
            excludeType?.(c.type) !== true
    );
    const sorted = sortBy(
        filtered,
        (c: TableColumn) => avoidColumnNames === undefined || !avoidColumnNames.has(c.name),
        (c: TableColumn) => avoidColumnNamesSecondary === undefined || !avoidColumnNamesSecondary.has(c.name),
        (c: TableColumn) => {
            let idx = preferredDisplayNames.indexOf(getTableColumnDisplayName(c).toLowerCase());
            if (idx < 0) idx = preferredDisplayNames.length;
            return -idx;
        },
        (c: TableColumn) => preferType === undefined || preferType(c.type)
    );
    return reverse(sorted)[0];
}

export function findFittingColumnInTable(
    table: TableGlideType,
    preferredDisplayNames: ReadonlyArray<string>,
    excludeNames: ReadonlySet<string> | undefined,
    preferredType: ColumnTypePredicate | undefined,
    acceptedType: ColumnTypePredicate,
    excludeWrongPrimitiveTypes: boolean
): TableColumn | undefined {
    const acceptType = typeof acceptedType === "string" ? (t: ColumnType) => t.kind === acceptedType : acceptedType;
    const acceptedColumns = getNonHiddenColumns(table).filter(c => c.isUserSpecific !== true && acceptType(c.type));
    const avoid = definedMap(table.rowIDColumn, n => getTableColumn(table, n));
    return findFittingColumn(
        acceptedColumns,
        avoid,
        preferredDisplayNames,
        undefined,
        undefined,
        excludeNames,
        preferredType,
        excludeWrongPrimitiveTypes
    );
}

function findFittingProperty(
    table: TableGlideType,
    preferredDisplayNames: ReadonlyArray<string>,
    excludeNames: ReadonlySet<string> | undefined,
    preferredType: ColumnTypePredicate | undefined,
    acceptedType: ColumnTypePredicate
): string | undefined {
    return findFittingColumnInTable(table, preferredDisplayNames, excludeNames, preferredType, acceptedType, false)
        ?.name;
}

export function findTitleColumnForTable(
    table: TableGlideType,
    mustBeCorrectType: boolean,
    excludeColumns: ReadonlySet<string> | undefined
): string | undefined {
    return findFittingProperty(
        table,
        titleProperties,
        excludeColumns,
        "string",
        mustBeCorrectType ? "string" : t => isPrimitiveType(t) && !isLinkTypeKind(t.kind)
    );
}
