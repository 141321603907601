import { css, styled } from "@glide/common-components";

export const Smartphone = styled.div<{
    round: number;
    width?: number;
    height?: number;
    scale?: number;
}>`
    position: relative;
    ${p =>
        css`
            border-radius: ${p.round}px;
        `}
    box-shadow: 0px 36px 42px rgba(0, 0, 0, 0.1);

    width: ${p => (p.width ? p.width : "393")}px;
    min-width: ${p => (p.width ? p.width : "393")}px;
    max-height: ${p => (p.height ? p.height : "852")}px;
    height: ${p => (p.height ? p.height + "px" : "100%")};
    ${p => (p.scale ? `transform: scale(${p.scale})` : "transform: translate3d(0, 0, 0)")};
    overflow: hidden;

    #loader-1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
