import {
    type Currency,
    defaultCurrency,
    findCurrency,
    formatPrice,
} from "@glide/common-core/dist/js/components/buy-button-defaults";
import {
    asNumber,
    type GroundValue,
    Table,
    isLoadingValue,
    makeKeyPath,
    isBaseRowIndex,
} from "@glide/computation-model-types";
import { ImageAspectRatio } from "@glide/common-core/dist/js/components/image-types";
import { getLocalizedString } from "@glide/localization";
import { AppKind } from "@glide/location-common";
import { getAppFeatures, getAppTabs } from "@glide/common-core/dist/js/components/SerializedApp";
import { MenuItemPurpose } from "@glide/common-core/dist/js/components/types";
import {
    asBoolean,
    asString,
    asTable,
    getRowColumn,
    isLoadedRow,
} from "@glide/common-core/dist/js/computation-model/data";
import type { TableName } from "@glide/type-schema";
import { commentsTableName, shoppingCartTableName } from "@glide/common-core/dist/js/database-strings";
import {
    type ScreenDescription,
    ArrayScreenFormat,
    ScreenDescriptionKind,
    getScreenProperty,
} from "@glide/app-description";
import { makeInputOutputTables } from "@glide/common-core/dist/js/description";
import type { SendAppFeedbackBody } from "@glide/common-core/dist/js/firebase-function-types";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import { type WireFormFactor, DeviceFormFactor } from "@glide/common-core/dist/js/render/form-factor";
import { Appearance, ListItemAccessoryPosition, ListItemFlags, Mood, TextEntrySize } from "@glide/component-utils";
import {
    type BuyScreenTransactionItemWithFlags,
    type WireAppButtonComponent,
    type WireAppBuyScreenComponent,
    type WireAppLabelComponent,
    type WireAppListListComponent,
    type WireAppMapListComponent,
    type WireAppMenuItem,
    type WireAppShareScreenComponent,
    type WireAppWebViewComponent,
    type WireEmailFieldComponent,
    type WireListListItem,
    type WireTextFieldComponent,
    WireImageFallback,
} from "@glide/fluent-components/dist/js/base-components";
import type { WireListComponentGeneric } from "@glide/fluent-components/dist/js/fluent-components-spec";
import {
    buyScreenName,
    codeScannerScreenName,
    liveLinearBarcodeScannerScreenName,
    sendAppFeedbackScreenName,
    shareScreenName,
    signInScreenName,
    signUpScreenName,
    userProfileScreenName,
    webViewScreenName,
} from "@glide/function-utils";
import {
    getAppAuthenticationData,
    isAuthenticationRequired,
    isEmailBasedAuthentication,
} from "@glide/generator/dist/js/authentication-method";
import { globalChatTopic } from "@glide/generator/dist/js/builder";
import {
    type CommentUserGetters,
    hydrateComments,
    inflateCommentUserGetters,
} from "@glide/generator/dist/js/components/comments-hydrator";
import {
    type HydratedScreenContext,
    type WireHydrationFollowUp,
    type WireTableComponentHydrationBackend,
    type WireAppNavigationModel,
    type WireAppPhoneScreens,
    type WireAppScreens,
    type WireNavigation,
    type WireNavigationModelBase,
    type WireScreen,
    type NavigationPath,
    type ParsedMainScreen,
    type ParsedScreen,
    type WithModalFlag,
    type WireAction,
    WireActionResult,
    PageScreenTarget,
    ValueChangeSource,
    WireComponentKind,
    makeContextTableTypes,
    WireNavigationAction,
    WireActionOffline,
    WireModalSize,
    WireScreenPosition,
} from "@glide/wire";
import { getUserProfileTableInfo } from "@glide/generator/dist/js/user-profile-info";
import { encodeScreenKey, registerActionRunner } from "@glide/generator/dist/js/wire/utils";
import { Result } from "@glide/plugins";
import {
    type ArraySet,
    checkString,
    isEmptyOrUndefined,
    isValidEmailAddress,
    normalizeEmailAddress,
} from "@glide/support";
import {
    assert,
    assertNever,
    defined,
    definedMap,
    filterUndefined,
    mapFilterUndefined,
} from "@glideapps/ts-necessities";
import isBoolean from "lodash/isBoolean";
import isString from "lodash/isString";
import {
    type AuxHydrationData,
    type InternalNavigationModelBase,
    type ScreenHydrationResult,
    WireBackendBase,
    emptyScreen,
    getSubsidiaryScreen,
    makeScreenKey,
    makeStateSaveKey,
} from "./backend";
import { StateManager, TableComponentHydrationBackend } from "./hydration";
import { InflationBackend } from "./inflation";
import { emptyHydratedScreenContext, makeMutableSubscriptionSources } from "./internal";
import type {
    ActionRunnerWithContext,
    HydratedScreen,
    InternalScreen,
    ScreenBuilderTableData,
    ScreenHydrationContext,
    SubscriptionNeeds,
} from "./internal-types";

interface InternalAppPhoneScreens {
    readonly form: DeviceFormFactor.Phone;
    readonly topScreen: InternalScreen | undefined;
    readonly belowScreen: InternalScreen | undefined;
}

interface InternalAppTabletScreens {
    readonly form: DeviceFormFactor.Tablet;

    readonly modalScreen: InternalScreen | undefined;
    readonly detailScreen: InternalScreen | undefined;
    readonly masterScreen: InternalScreen | undefined;
}

type InternalAppScreens = InternalAppPhoneScreens | InternalAppTabletScreens;

function asInternalPhoneScreens(screens: InternalAppScreens | undefined): InternalAppPhoneScreens {
    if (screens?.form === DeviceFormFactor.Phone) {
        return screens;
    } else {
        return {
            form: DeviceFormFactor.Phone,
            topScreen: undefined,
            belowScreen: undefined,
        };
    }
}

function asInternalTabletScreens(screens: InternalAppScreens | undefined): InternalAppTabletScreens {
    if (screens?.form === DeviceFormFactor.Tablet) {
        return screens;
    } else {
        return {
            form: DeviceFormFactor.Tablet,
            modalScreen: undefined,
            detailScreen: undefined,
            masterScreen: undefined,
        };
    }
}

function asWirePhoneScreens(screens: WireAppScreens | undefined): WireAppPhoneScreens | undefined {
    if (screens?.form === DeviceFormFactor.Phone) {
        return screens;
    } else {
        return undefined;
    }
}

function makeEmptyWireScreens(formFactor: WireFormFactor): WireAppScreens {
    if (formFactor === DeviceFormFactor.Phone) {
        return {
            form: DeviceFormFactor.Phone,
            screen: emptyScreen,
            belowScreen: emptyScreen,
        };
    } else if (formFactor === DeviceFormFactor.Tablet) {
        return {
            form: DeviceFormFactor.Tablet,
            modalScreen: undefined,
            detailScreen: undefined,
            masterScreen: undefined,
            selectedMasterItemKey: undefined,
        };
    } else {
        return assertNever(formFactor);
    }
}

interface InternalAppNavigationModel extends InternalNavigationModelBase {
    readonly screens: InternalAppScreens;
}

function makeBuyScreenItemFromRow(
    getColumn: (name: string) => GroundValue
): (BuyScreenTransactionItemWithFlags & { readonly currency: Currency }) | undefined {
    const itemSKU = getColumn("itemSKU");
    const itemName = getColumn("itemName");
    const itemDescription = getColumn("itemDescription");
    const itemPrice = getColumn("itemPrice");
    const currencyCode = getColumn("currencyCode");
    const itemImage = getColumn("itemImage");
    const withShipping = getColumn("withShipping");
    const showStripeLogo = getColumn("showStripeLogo");
    const buttonID = getColumn("buttonID");
    const rowIndex = getColumn("rowIndex");
    if (isLoadingValue(itemSKU) || isLoadingValue(itemName) || isLoadingValue(itemPrice) || isLoadingValue(buttonID))
        return undefined;

    const itemCurrency = findCurrency(isLoadingValue(currencyCode) ? defaultCurrency.code : asString(currencyCode));
    if (itemCurrency === undefined) return undefined;

    return {
        sku: asString(itemSKU),
        name: asString(itemName),
        description: isLoadingValue(itemDescription) ? undefined : asString(itemDescription),
        price: asNumber(itemPrice),
        currency: itemCurrency,
        withShipping: isLoadingValue(withShipping) ? false : asBoolean(withShipping),
        showStripeLogo: isLoadingValue(showStripeLogo) ? false : asBoolean(showStripeLogo),
        buttonID: asString(buttonID),
        rowIndex: isBaseRowIndex(rowIndex) ? rowIndex : undefined,
        image: isLoadingValue(itemImage) ? undefined : asString(itemImage),
    };
}

export class WireAppBackend extends WireBackendBase<InternalAppNavigationModel, WireAppNavigationModel> {
    private lastPhoneNavigation: WireNavigation | undefined;
    private commentUserGetters: CommentUserGetters | undefined;
    private showShareScreenAction: WireAction | undefined;

    private getTabletParsedScreens(path: NavigationPath): {
        modal: WithModalFlag<ParsedScreen> | undefined;
        detail: WithModalFlag<ParsedMainScreen> | undefined;
        master: WithModalFlag<ParsedMainScreen> | undefined;
    } {
        const { root, main, modal } = path.getParsedScreens();

        let rootIsArrayScreen = false;
        if (root !== undefined) {
            const screen = this.appDescription.screenDescriptions[root.screen.screenName];
            if (screen !== undefined) {
                rootIsArrayScreen = screen.kind === ScreenDescriptionKind.Array;
            }
        }

        // We have a separate master screen if the root is an array screen
        // and the top screen is not already the root screen.
        let master: WithModalFlag<ParsedMainScreen> | undefined;
        if (rootIsArrayScreen) {
            master = root;
        }

        let detail: WithModalFlag<ParsedMainScreen> | undefined;
        if (!rootIsArrayScreen || root?.screen !== main?.screen) {
            detail = main;
        }

        return { modal, detail, master };
    }

    private makeScreenKeys(): [mainKey: string, belowKey: string, detailKey: string, masterKey: string] {
        const { parsedPath: path } = this;
        if (!path.isVisible()) return ["", "", "", ""];

        // For phone
        const { main, modal, below } = path.getParsedScreens();
        const top = modal ?? main;
        const mainKey = makeScreenKey(path, top?.screen);
        const belowKey = makeScreenKey(path, below?.screen);

        // For tablet
        const { detail, master } = this.getTabletParsedScreens(path);
        const detailKey = makeScreenKey(path, detail?.screen);
        const masterKey = makeScreenKey(path, master?.screen);

        return [mainKey, belowKey, detailKey, masterKey];
    }

    public navigateToUserProfile(): Result {
        return this.pushScreen(
            { screenName: userProfileScreenName, rowIDs: [] },
            PageScreenTarget.LargeModal,
            this.getCurrentScreenPosition()[0],
            WireModalSize.Large,
            undefined,
            undefined
        );
    }

    public runNavigateUp(): Result {
        const { modal } = this.parsedPath.getParsedScreens();
        const popped = this.parsedPath.pop();
        if (popped === undefined) return Result.FailPermanent("Cannot navigate up from the root screen");

        let navigationAction: WireNavigationAction;
        const override = definedMap(modal, m => this.getNavigationActionOverride(m.screen.screenName));
        if (override !== undefined) {
            navigationAction = override.pop;
        } else if (modal?.screen.kind === WireScreenPosition.Modal) {
            navigationAction = WireNavigationAction.PopModal;
        } else {
            navigationAction = WireNavigationAction.Pop;
        }

        popped[1]?.();
        this.setParsedPath(popped[0], navigationAction);

        return Result.Ok();
    }

    protected getHasUnconfigurableModal(aux: AuxHydrationData): boolean {
        return aux.hasSubsidiaryScreen;
    }

    protected getCurrentBuilderTableData(navModel: InternalAppNavigationModel): ScreenBuilderTableData | undefined {
        if (navModel.screens.form === DeviceFormFactor.Phone) {
            return navModel.screens.topScreen?.hydratedScreen?.builderTableData;
        } else if (navModel.screens.form === DeviceFormFactor.Tablet) {
            const screen = navModel.screens.modalScreen ?? navModel.screens.detailScreen;
            return screen?.hydratedScreen?.builderTableData;
        } else {
            return assertNever(navModel.screens);
        }
    }

    protected makeEmptyNavigationModel(base: WireNavigationModelBase): WireAppNavigationModel {
        const navModel: WireAppNavigationModel = {
            ...base,
            kind: AppKind.App,
            screens: makeEmptyWireScreens(this.formFactor),
            flyoutTabs: [],
            infoButtonAction: undefined,
            isTabletTopLevelMap: false,
        };
        return navModel;
    }

    protected makeNavigationModel(
        internalModel: InternalAppNavigationModel,
        base: WireNavigationModelBase,
        previous: { navModel: WireAppNavigationModel; aux: AuxHydrationData | undefined } | undefined
    ): [WireAppNavigationModel, AuxHydrationData] {
        const { appDescription } = this;
        const isShareSheetEnabled =
            !this.adc.eminenceFlags.canDisableShare || getAppFeatures(appDescription).enableShareScreen === true;
        if (this.showShareScreenAction === undefined && isShareSheetEnabled) {
            const showShareScreenActionToken = makeRowID();
            this.showShareScreenAction = { token: showShareScreenActionToken };
            this.globalActions.set(showShareScreenActionToken, [
                async ab => {
                    ab.pushFreeScreen(shareScreenName, [], "", PageScreenTarget.LargeModal, undefined);
                    return WireActionResult.nondescriptSuccess();
                },
                emptyHydratedScreenContext,
            ]);
        }

        let flyoutTabs = base.flyoutTabs ?? [];

        const authData = getAppAuthenticationData(appDescription);
        const mustShowFlyout =
            flyoutTabs.length > 0 ||
            getUserProfileTableInfo(appDescription) !== undefined ||
            (isEmailBasedAuthentication(authData) && isAuthenticationRequired(authData));

        let infoButtonAction: WireAction | undefined;

        if (this.showShareScreenAction !== undefined) {
            if (mustShowFlyout) {
                flyoutTabs = [
                    ...flyoutTabs,
                    {
                        title: getLocalizedString("about", this.appKind),
                        action: this.showShareScreenAction,
                        icon: "share",
                        // Apps don't care about flyout tabs being active
                        isActive: false,
                    },
                ];
            } else {
                infoButtonAction = this.showShareScreenAction;
            }
        }

        let hasSubsidiaryScreen: boolean;

        let screens: WireAppScreens;
        if (this.formFactor === DeviceFormFactor.Phone) {
            const internalScreens = asInternalPhoneScreens(internalModel.screens);
            const subsidiaryScreen = getSubsidiaryScreen(internalScreens.topScreen?.hydratedScreen);
            hasSubsidiaryScreen = subsidiaryScreen !== undefined;

            const previousScreens = asWirePhoneScreens(previous?.navModel.screens);
            const previousHadSubsidiaryScreen = previous?.aux?.hasSubsidiaryScreen === true;

            if (previousScreens === undefined) {
                // Phone mode sets this when navigating, but tablet mode
                // doesn't.
                this.lastPhoneNavigation = undefined;
            } else if (hasSubsidiaryScreen && !previousHadSubsidiaryScreen) {
                // We pushed a subsidiary screen
                this.lastPhoneNavigation = {
                    priorScreen: previousScreens.screen,
                    navigationAction: WireNavigationAction.PushModal,
                };
            } else if (previousHadSubsidiaryScreen && !hasSubsidiaryScreen) {
                // We popped a subsidiary screen
                this.lastPhoneNavigation = {
                    priorScreen: previousScreens.screen,
                    navigationAction: WireNavigationAction.PopModal,
                };
            } else {
                // Whatever `this.lastNavigation` says is accurate
            }

            screens = {
                form: DeviceFormFactor.Phone,
                screen: subsidiaryScreen ?? internalScreens.topScreen?.hydratedScreen?.wireScreen ?? emptyScreen,
                belowScreen:
                    (hasSubsidiaryScreen
                        ? internalScreens.topScreen?.hydratedScreen?.wireScreen
                        : internalScreens.belowScreen?.hydratedScreen?.wireScreen) ?? emptyScreen,
                lastNavigation: this.lastPhoneNavigation,
            };
        } else if (this.formFactor === DeviceFormFactor.Tablet) {
            const internalScreens = asInternalTabletScreens(internalModel.screens);

            const subsidiaryScreen =
                getSubsidiaryScreen(internalScreens.modalScreen?.hydratedScreen) ??
                getSubsidiaryScreen(internalScreens.detailScreen?.hydratedScreen) ??
                getSubsidiaryScreen(internalScreens.masterScreen?.hydratedScreen);
            hasSubsidiaryScreen = subsidiaryScreen !== undefined;

            const masterScreen = internalScreens.masterScreen?.hydratedScreen?.wireScreen;
            let selectedMasterItemKey: string | undefined;
            if (masterScreen !== undefined) {
                // ##selectedMasterItemKey:
                // Right now we use the items' row IDs as their keys, so we
                // can just do this.
                selectedMasterItemKey = this.parsedPath.getDetailScreenForMaster()?.sourceItemRowID;
            }

            screens = {
                form: DeviceFormFactor.Tablet,
                modalScreen: subsidiaryScreen ?? internalScreens.modalScreen?.hydratedScreen?.wireScreen,
                detailScreen: internalScreens.detailScreen?.hydratedScreen?.wireScreen,
                masterScreen,
                selectedMasterItemKey,
            };
        } else {
            return assertNever(this.formFactor);
        }

        const navModel: WireAppNavigationModel = {
            ...base,
            kind: AppKind.App,
            screens,
            flyoutTabs: flyoutTabs.length > 0 || mustShowFlyout ? flyoutTabs : undefined,
            infoButtonAction,
            isTabletTopLevelMap: false,
        };
        return [navModel, { hasSubsidiaryScreen }];
    }

    protected postProcessNavigationModel(model: WireAppNavigationModel): WireAppNavigationModel {
        const { screens } = model;
        if (screens.form !== DeviceFormFactor.Tablet) return model;

        const masterComponents = screens.masterScreen?.components;
        if (masterComponents?.length !== 1) return model;
        const component = masterComponents[0];

        if (component?.kind !== WireComponentKind.List) return model;
        const list = component as WireListComponentGeneric<ArrayScreenFormat>;
        if (list.format !== ArrayScreenFormat.Map) return model;

        const mapList = list as WireAppMapListComponent;
        const detailMapList: WireAppMapListComponent = {
            ...mapList,
            displayContext: "map-detail",
        };

        let masterScreen: WireScreen;
        if (screens.detailScreen === undefined) {
            const masterMapList: WireAppMapListComponent = {
                ...mapList,
                displayContext: "map-master",
            };
            masterScreen = {
                ...defined(screens.masterScreen),
                components: [masterMapList],
            };
        } else {
            // When we have a map, even a "top-level" detail screen has a back
            // action because it can pop back to showing the map as a list and
            // a map.
            masterScreen = { ...screens.detailScreen, backAction: this.navigateUpAction };
        }

        const switchedScreens: WireAppScreens = {
            ...screens,
            masterScreen,
            detailScreen: { ...defined(screens.masterScreen), components: [detailMapList] },
        };

        return {
            ...model,
            screens: switchedScreens,
            isTabletTopLevelMap: true,
        };
    }

    protected updateInternalNavigationModel(
        oldModel: InternalAppNavigationModel | undefined
    ): InternalAppNavigationModel {
        const mainScreenTab = this.parsedPath.getTabOfCurrentScreen(this.adc.appDescription);

        let screens: InternalAppScreens;
        if (this.formFactor === DeviceFormFactor.Phone) {
            const { main, modal, below } = this.parsedPath.getParsedScreens();
            const top = modal ?? main;

            const oldScreens = asInternalPhoneScreens(oldModel?.screens);
            const topScreen = this.updateInternalScreen(top, oldScreens.topScreen, mainScreenTab);
            let belowScreen: InternalScreen | undefined;
            if (getSubsidiaryScreen(topScreen?.hydratedScreen) === undefined) {
                const belowScreenTab = this.parsedPath.pop()?.[0].getTabOfCurrentScreen(this.adc.appDescription);
                belowScreen = this.updateInternalScreen(below, oldScreens.belowScreen, belowScreenTab);
            }
            screens = {
                form: DeviceFormFactor.Phone,
                topScreen,
                belowScreen,
            };
        } else if (this.formFactor === DeviceFormFactor.Tablet) {
            const { modal, master, detail } = this.getTabletParsedScreens(this.parsedPath);

            const oldScreens = asInternalTabletScreens(oldModel?.screens);

            const modalScreen = this.updateInternalScreen(modal, oldScreens.modalScreen, mainScreenTab);
            const masterScreen = this.updateInternalScreen(master, oldScreens.masterScreen, mainScreenTab);
            const detailScreen = this.updateInternalScreen(detail, oldScreens.detailScreen, mainScreenTab);

            screens = {
                form: DeviceFormFactor.Tablet,
                modalScreen,
                detailScreen,
                masterScreen,
            };
        } else {
            return assertNever(this.formFactor);
        }
        return {
            screens,
            tabs: oldModel?.tabs,
            userProfile: oldModel?.userProfile ?? {
                userProfileButton: undefined,
                signInAction: undefined,
            },
            userProfileSubscriptionInfo: oldModel?.userProfileSubscriptionInfo,
        };
    }

    protected rebuildScreens(
        oldModel: InternalAppNavigationModel,
        newBase: InternalNavigationModelBase,
        tablesToFetch: ArraySet<TableName>,
        needsChanged: SubscriptionNeeds
    ): [InternalAppNavigationModel, readonly WireHydrationFollowUp[]] {
        const [mainKey, belowKey, detailKey, masterKey] = this.makeScreenKeys();

        if (this.formFactor === DeviceFormFactor.Phone) {
            const oldScreens = asInternalPhoneScreens(oldModel.screens);
            const [newTopScreen, topScreenFollowUp] =
                definedMap(oldScreens.topScreen, m =>
                    this.rebuildScreen(
                        m,
                        WireScreenPosition.Main,
                        undefined,
                        tablesToFetch,
                        newBase.tabs,
                        mainKey,
                        needsChanged
                    )
                ) ?? [];
            const [newBelowScreen, belowScreenFollowUp] =
                definedMap(oldScreens.belowScreen, m =>
                    this.rebuildScreen(
                        m,
                        WireScreenPosition.Main,
                        undefined,
                        tablesToFetch,
                        newBase.tabs,
                        belowKey,
                        needsChanged
                    )
                ) ?? [];
            return [
                {
                    ...newBase,
                    screens: {
                        form: DeviceFormFactor.Phone,
                        topScreen: newTopScreen,
                        belowScreen: newBelowScreen,
                    },
                },
                filterUndefined([topScreenFollowUp, belowScreenFollowUp]),
            ];
        } else if (this.formFactor === DeviceFormFactor.Tablet) {
            const oldScreens = asInternalTabletScreens(oldModel.screens);
            const [newModalScreen, modalScreenFollowUp] =
                definedMap(oldScreens.modalScreen, m =>
                    this.rebuildScreen(
                        m,
                        WireScreenPosition.Modal,
                        undefined,
                        tablesToFetch,
                        newBase.tabs,
                        mainKey,
                        needsChanged
                    )
                ) ?? [];
            const [newDetailScreen, detailScreenFollowUp] =
                definedMap(oldScreens.detailScreen, m =>
                    this.rebuildScreen(
                        m,
                        WireScreenPosition.Main,
                        undefined,
                        tablesToFetch,
                        newBase.tabs,
                        detailKey,
                        needsChanged
                    )
                ) ?? [];
            const [newMasterScreen, masterScreenFollowUp] =
                definedMap(oldScreens.masterScreen, m =>
                    this.rebuildScreen(
                        m,
                        WireScreenPosition.Master,
                        undefined,
                        tablesToFetch,
                        newBase.tabs,
                        masterKey,
                        needsChanged
                    )
                ) ?? [];

            let autoRunFirstItemFollowUp: WireHydrationFollowUp | undefined;
            if (
                !this.isBuilder &&
                newMasterScreen?.hydratedScreen?.wireScreen !== undefined &&
                newDetailScreen?.hydratedScreen?.wireScreen === undefined
            ) {
                const masterComponent = newMasterScreen.hydratedScreen.wireScreen.components[0];
                // The Map is special and does not auto-push.
                if (
                    masterComponent?.kind !== WireComponentKind.List ||
                    (masterComponent as WireListComponentGeneric<ArrayScreenFormat>).format !== ArrayScreenFormat.Map
                ) {
                    const token = newMasterScreen.hydratedScreen.firstListItemActionToRun?.token;
                    if (token !== undefined && token !== null) {
                        autoRunFirstItemFollowUp = ab => ab.runAction(token);
                    }
                }
            }

            return [
                {
                    ...newBase,
                    screens: {
                        form: DeviceFormFactor.Tablet,
                        modalScreen: newModalScreen,
                        detailScreen: newDetailScreen,
                        masterScreen: newMasterScreen,
                    },
                },
                filterUndefined([
                    modalScreenFollowUp,
                    detailScreenFollowUp,
                    masterScreenFollowUp,
                    autoRunFirstItemFollowUp,
                ]),
            ];
        } else {
            return assertNever(this.formFactor);
        }
    }

    private getAllInternalScreens({
        screens,
    }: InternalAppNavigationModel): readonly [InternalScreen, WireScreenPosition][] {
        const results: [InternalScreen, WireScreenPosition][] = [];
        function push(s: InternalScreen | undefined, p: WireScreenPosition) {
            if (s === undefined) return;
            results.push([s, p]);
        }
        if (screens.form === DeviceFormFactor.Phone) {
            push(
                screens.topScreen,
                screens.topScreen?.isInModal === true ? WireScreenPosition.Master : WireScreenPosition.Main
            );
            push(
                screens.belowScreen,
                screens.belowScreen?.isInModal === true ? WireScreenPosition.Master : WireScreenPosition.Main
            );
        } else {
            push(screens.modalScreen, WireScreenPosition.Modal);
            push(screens.detailScreen, WireScreenPosition.Main);
            push(screens.masterScreen, WireScreenPosition.Master);
        }
        return results;
    }

    protected getActionFromScreens(
        navModel: InternalAppNavigationModel,
        token: string
    ):
        | { action: ActionRunnerWithContext; source: WireScreenPosition; sourceModalSize: WireModalSize | undefined }
        | undefined {
        for (const [s, p] of this.getAllInternalScreens(navModel)) {
            const action = s.hydratedScreen?.actions.get(token);
            if (action === undefined) continue;
            return { action, source: p, sourceModalSize: undefined };
        }
        return undefined;
    }

    protected getNeedsBackAction({ screen, isInModal, depth }: WithModalFlag<ParsedScreen>): boolean {
        if (isInModal) return true;

        const { root } = this.parsedPath.getParsedScreens();

        if (this.formFactor === DeviceFormFactor.Phone) {
            return screen !== root?.screen;
        } else if (this.formFactor === DeviceFormFactor.Tablet) {
            const { master } = this.getTabletParsedScreens(this.parsedPath);
            if (screen === master?.screen) return false;
            if (master === undefined) return screen !== root?.screen;
            return depth >= 2;
        } else {
            return assertNever(this.formFactor);
        }
    }

    protected processInHydratedScreens<T>(
        navModel: InternalAppNavigationModel,
        processScreen: (hydratedScreen: HydratedScreen, internalScreen: InternalScreen) => T | undefined
    ): T | undefined {
        for (const [s] of this.getAllInternalScreens(navModel)) {
            if (s.hydratedScreen === undefined) continue;
            const result = processScreen(s.hydratedScreen, s);
            if (result !== undefined) {
                return result;
            }
        }
        return undefined;
    }

    protected forEachInternalScreen(navModel: InternalAppNavigationModel, f: (screen: InternalScreen) => void): void {
        for (const [s] of this.getAllInternalScreens(navModel)) {
            f(s);
        }
    }

    protected getCurrentScreen(
        navModel: InternalAppNavigationModel | undefined,
        parsedPath: NavigationPath
    ): [screenName: string, screen: InternalScreen | undefined] {
        assert(parsedPath.isVisible());

        const { main, modal } = parsedPath.getParsedScreens();
        const top = modal ?? main;

        const { screenName } = defined(top).screen;
        if (navModel === undefined) {
            return [screenName, undefined];
        }
        const { screens } = navModel;

        if (screens.form === DeviceFormFactor.Phone) {
            return [screenName, screens.topScreen];
        } else {
            return [screenName, screens.modalScreen ?? screens.detailScreen ?? screens.masterScreen];
        }
    }

    protected setParsedPath(parsedPath: NavigationPath, navigationAction: WireNavigationAction): boolean {
        const didChange = super.setParsedPath(parsedPath, navigationAction);
        if (!didChange) return false;

        const { screens } = this.navigationModelWatchable.current;
        if (screens.form === DeviceFormFactor.Phone) {
            this.lastPhoneNavigation = {
                priorScreen: screens.screen,
                navigationAction,
            };
        } else {
            this.lastPhoneNavigation = undefined;
        }
        return true;
    }

    protected getNavigationActionOverride(
        screenName: string
    ): { push: WireNavigationAction.Push; pop: WireNavigationAction.Pop } | undefined {
        // Flyout tabs display as modals but use the normal push animation
        const tabs = getAppTabs(this.appDescription);
        const tab = tabs.find(t => !t.hidden && getScreenProperty(t.screenName) === screenName);
        if (tab?.inFlyout === true) {
            return { push: WireNavigationAction.Push, pop: WireNavigationAction.Pop };
        } else {
            return undefined;
        }
    }

    private hydrateSubscribingTableSpecialScreen(
        hydrationContext: ScreenHydrationContext,
        title: string,
        screenContext: HydratedScreenContext,
        table: Table,
        hydrate: (thb: WireTableComponentHydrationBackend) => [WireScreen, WireScreen | undefined] | undefined
    ): ScreenHydrationResult | undefined {
        const {
            internalScreen: { screenName },
        } = hydrationContext;

        const rootPath = this.computationModel?.getPathForLocalTable(shoppingCartTableName);
        if (rootPath === undefined) return undefined;

        // This is ugly - we're explicitly adding the shopping cart table to
        // the sources for this screen so it gets rehydrated if a row is
        // added/removed.  We just haven't built nice infrastructure for
        // "special" screen yet.
        // https://github.com/quicktype/glide/issues/15946
        const sources = makeMutableSubscriptionSources();
        sources.columnsInAllDirectRows.get(rootPath.rest.key).add("$rowID");

        return this.hydrateSubscribingSpecialScreen<TableComponentHydrationBackend>(
            hydrationContext,
            screenContext,
            oldComponentState =>
                new TableComponentHydrationBackend(
                    hydrationContext,
                    table,
                    undefined,
                    shoppingCartTableName,
                    false,
                    title,
                    new StateManager(oldComponentState?.stateValues),
                    makeStateSaveKey(undefined, `array-${screenName}`, undefined),
                    "",
                    undefined,
                    sources,
                    true,
                    () => this.formFactor,
                    []
                ),
            hydrate
        );
    }

    // FIXME: All these special screen hydrators really belong in
    // `AppScreenInflator`.
    private hydrateShoppingCartScreen(
        hydrationContext: ScreenHydrationContext,
        title: string,
        screenContext: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        if (this.computationModel === undefined) return undefined;

        const rootPath = this.computationModel.getPathForLocalTable(shoppingCartTableName);
        if (rootPath === undefined) return undefined;

        const maybeRows = this.computationModel.ns.get(rootPath);
        const rows = isLoadingValue(maybeRows) ? new Table() : asTable(maybeRows);

        return this.hydrateSubscribingTableSpecialScreen(hydrationContext, title, screenContext, rows, thb => {
            const isEditing = thb.getScreenState("isEditing", isBoolean, false);

            let currency: Currency | undefined;
            // `undefined` means that there was a currency conflict
            let totalCost: number | undefined = 0;
            const items = mapFilterUndefined(thb.tableScreenContext.asArray(), r => {
                const rhb = thb.makeHydrationBackendForRow(r);
                const item = makeBuyScreenItemFromRow(n =>
                    rhb.getColumnInRow(shoppingCartTableName, rootPath, r, makeKeyPath(n))
                );
                if (item === undefined) return undefined;

                if (currency === undefined) {
                    currency = item.currency;
                } else if (currency !== item.currency) {
                    totalCost = undefined;
                }

                if (totalCost !== undefined) {
                    totalCost += item.price;
                }

                const listItem: WireListListItem = {
                    key: r.$rowID,
                    title: item.name,
                    subtitle: item.description ?? "",
                    caption: formatPrice(item.price, item.currency),
                    image: { value: item.image ?? "", onChangeToken: undefined },
                    icon: null,
                    action: undefined,
                    accessory: isEditing.value
                        ? {
                              component: {
                                  kind: WireComponentKind.AppCircleButtonAccessory,
                                  icon: "01-43-remove-bold",
                                  action:
                                      this.localDataStore !== undefined
                                          ? registerActionRunner(rhb, "remove", async ab => {
                                                await defined(this.localDataStore).deleteRowsAtIndexes(
                                                    {
                                                        fromDataEditor: false,
                                                        tableName: shoppingCartTableName,
                                                        sendToBackend: true,
                                                        setUnderlyingData: true,
                                                        awaitSend: true,
                                                        onError: () =>
                                                            ab.actionCallbacks.showToast(
                                                                false,
                                                                // FIXME: Introduce better error message to indicate row deletion didn't work
                                                                getLocalizedString("error", hydrationContext.appKind)
                                                            ),
                                                    },
                                                    [
                                                        {
                                                            keyColumnName: "$rowID",
                                                            keyColumnValue: r.$rowID,
                                                        },
                                                    ]
                                                );
                                                return WireActionResult.nondescriptSuccess();
                                            })
                                          : undefined,
                              },
                              position: ListItemAccessoryPosition.Right,
                          }
                        : undefined,
                };
                return listItem;
            });

            const list: WireAppListListComponent<ArrayScreenFormat.List> = {
                kind: WireComponentKind.List,
                format: ArrayScreenFormat.List,
                allowWrapping: true,
                imageFallback: WireImageFallback.None,
                flags:
                    ListItemFlags.LargeImage |
                    ListItemFlags.DisableChevron |
                    ListItemFlags.DrawSeparator |
                    ListItemFlags.BigCaption |
                    ListItemFlags.DisableLongPress,
                title: "",
                emptyMessage: getLocalizedString("yourCartIsEmpty", AppKind.App),
                groups: [
                    {
                        title: "",
                        items,
                        seeAllAction: undefined,
                    },
                ],
            };

            let label: WireAppLabelComponent | undefined;
            let button: WireAppButtonComponent | undefined;
            if (!isEditing.value && currency !== undefined && totalCost !== undefined) {
                label = {
                    kind: WireComponentKind.AppLabel,
                    text: `${getLocalizedString("total", AppKind.App)} ${formatPrice(totalCost, currency)}`,
                };

                let onTap: WireAction | undefined;
                if (thb.getIsOnline()) {
                    onTap = registerActionRunner(thb, "buy", async ab => {
                        for (const r of rows.values()) {
                            if (!isLoadedRow(r)) continue;
                            ab.addSpecialScreenRow(r);
                        }
                        ab.pushFreeScreen(buyScreenName, rows.asArray(), "", PageScreenTarget.Current, undefined);
                        return WireActionResult.nondescriptSuccess();
                    });
                } else {
                    onTap = {
                        token: WireActionOffline,
                    };
                }
                if (onTap !== undefined) {
                    button = {
                        kind: WireComponentKind.AppButton,
                        title: getLocalizedString("checkout", AppKind.App),
                        style: Appearance.Filled,
                        mood: Mood.Default,
                        onTap,
                    };
                }
            }

            const menuItem: WireAppMenuItem = {
                kind: WireComponentKind.AppMenuItem,
                title: getLocalizedString(isEditing.value ? "done" : "edit", AppKind.App),
                icon: isEditing.value ? "00-01-glide-check" : "00-01-glide-edit",
                purpose: MenuItemPurpose.SetValue,
                style: "text",
                action: registerActionRunner(thb, "doneEditing", async ab =>
                    ab.valueChanged(defined(isEditing.onChangeToken), !isEditing.value, ValueChangeSource.User)
                ),
            };

            return [
                {
                    key: encodeScreenKey(hydrationContext.screenKey),
                    title,
                    components: filterUndefined([list, label, button]),
                    specialComponents: [menuItem],
                    flags: [],
                    isInModal: false,
                    tabIcon: hydrationContext.internalScreen.tabIcon,
                },
                undefined,
            ];
        });
    }

    private hydrateBuyScreen(
        hydrationContext: ScreenHydrationContext,
        screenContext: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        const rows = screenContext.inputRows;
        if (rows.length === 0) return undefined;

        const title = getLocalizedString("checkout", AppKind.App);
        return this.hydrateSubscribingTableSpecialScreen(
            hydrationContext,
            title,
            screenContext,
            new Table(Array.from(rows)),
            thb => {
                const items = mapFilterUndefined(rows, r => makeBuyScreenItemFromRow(n => getRowColumn(r, n)));

                const onSuccess = registerActionRunner(thb, "succes", async ab => {
                    ab.navigateUp();
                    for (const r of rows) {
                        // This is the only place where we
                        // ##deleteIndividualShoppingCartRow.
                        await this.localDataStore?.deleteRowsAtIndexes(
                            {
                                tableName: shoppingCartTableName,
                                fromDataEditor: false,
                                setUnderlyingData: true,
                                sendToBackend: true,
                                awaitSend: true,
                                onError: () =>
                                    ab.actionCallbacks.showToast(
                                        false,
                                        // FIXME: Introduce better error message to indicate row deletion didn't work
                                        getLocalizedString("error", hydrationContext.appKind)
                                    ),
                            },
                            [
                                {
                                    keyColumnName: "$rowID",
                                    keyColumnValue: r.$rowID,
                                },
                            ]
                        );
                    }
                    return WireActionResult.nondescriptSuccess();
                });
                if (onSuccess === undefined) return undefined;

                const firstItem = defined(items[0]);
                const component: WireAppBuyScreenComponent = {
                    kind: WireComponentKind.AppBuyScreen,
                    currencyCode: firstItem.currency.code,
                    items: items.map(i => ({ ...i, currency: undefined })),
                    paymentInformation: hydrationContext.getPaymentInformationForBuyButton(firstItem.buttonID),
                    onSuccess,
                };

                const cancelItem: WireAppMenuItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title: getLocalizedString("cancel", AppKind.App),
                    style: "platform-cancel",
                    icon: "00-01-glide-close",
                    action: this.navigateUpAction,
                };

                return [
                    {
                        key: encodeScreenKey(hydrationContext.screenKey),
                        title,
                        components: [component],
                        specialComponents: [cancelItem],
                        flags: [],
                        isInModal: true,
                        tabIcon: hydrationContext.internalScreen.tabIcon,
                    },
                    undefined,
                ];
            }
        );
    }

    // FIXME: Actually put this in the flyout/info button
    private hydrateShareScreen(
        hydrationContext: ScreenHydrationContext,
        screenContext: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        const { appDescription } = this.adc;
        const features = getAppFeatures(appDescription);
        const disableSharing = features.disableSharing === true;
        const appTitle = appDescription.title;
        const author = appDescription.author ?? this.fallbackAuthorName;

        let title: string;
        if (!isEmptyOrUndefined(features.shareScreenTitle)) {
            title = features.shareScreenTitle;
        } else {
            title = getLocalizedString("about", AppKind.App);
        }

        return this.hydrateSubscribingRowSpecialScreen(hydrationContext, title, screenContext, hb => {
            let onFeedback: WireAction | undefined;
            if (hb.getIsOnline()) {
                onFeedback = registerActionRunner(hb, "onFeedback", async ab => {
                    ab.pushFreeScreen(sendAppFeedbackScreenName, [], "", PageScreenTarget.Main, undefined);
                    return WireActionResult.nondescriptSuccess();
                });
            }

            const displayQRCode = hb.getState("displayQRCode", isBoolean, false, false);

            const component: WireAppShareScreenComponent = {
                kind: WireComponentKind.AppShareScreen,
                title: appTitle,
                author,
                displayQRCode: displayQRCode.value,
                onFeedback,
                appIcon: appDescription.iconImage ?? { url: "🤷‍♀️" },
            };
            const doneItem: WireAppMenuItem = {
                kind: WireComponentKind.AppMenuItem,
                title: getLocalizedString("done", AppKind.App),
                icon: "00-01-glide-close",
                style: "platform-accept",
                action: this.navigateUpAction,
            };
            let switchItem: WireAppMenuItem | undefined;
            if (!disableSharing) {
                switchItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title: "qr",
                    icon: displayQRCode.value ? "01-14-information-circle" : "09-06-qr-code",
                    style: "menu",
                    action: registerActionRunner(hb, "switch", async ab =>
                        ab.valueChanged(displayQRCode.onChangeToken, !displayQRCode.value, ValueChangeSource.User)
                    ),
                };
            }

            return [
                {
                    key: encodeScreenKey(hydrationContext.screenKey),
                    title,
                    components: [component],
                    specialComponents: filterUndefined([doneItem, switchItem]),
                    flags: [],
                    isInModal: true,
                    tabIcon: hydrationContext.internalScreen.tabIcon,
                },
                undefined,
            ];
        });
    }

    private hydrateSendAppFeedbackScreen(
        hydrationContext: ScreenHydrationContext,
        screenContext: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        const { appID, appFacilities } = this;
        const title = getLocalizedString("sendFeedback", AppKind.App);

        const emailPath = this.computationModel?.getRealEmailAddressPath();

        return this.hydrateSubscribingRowSpecialScreen(hydrationContext, title, screenContext, hb => {
            let defaultEmail: string | undefined;
            if (emailPath !== undefined) {
                const maybeEmail = hb.getGlobalValue(undefined, emailPath, true);
                if (typeof maybeEmail === "string") {
                    defaultEmail = maybeEmail;
                }
            }

            const emailValue = hb.getState("email", isString, defaultEmail ?? "", false);
            const messageValue = hb.getState("message", isString, "", false);

            const emailField: WireEmailFieldComponent = {
                kind: WireComponentKind.EmailField,
                title: getLocalizedString("from", AppKind.App),
                placeholder: "",
                value: emailValue,
                isRequired: true,
            };
            const messageField: WireTextFieldComponent = {
                kind: WireComponentKind.TextField,
                title: getLocalizedString("message", AppKind.App),
                placeholder: "",
                size: TextEntrySize.Large,
                value: messageValue,
                isRequired: false,
                autofocus: false,
            };

            const email = normalizeEmailAddress(emailValue.value);
            const message = messageValue.value.trim();
            const canSend = isValidEmailAddress(email) && message !== "" && hb.getIsOnline();

            const sendItem: WireAppMenuItem = {
                kind: WireComponentKind.AppMenuItem,
                title: getLocalizedString("send", AppKind.App),
                icon: "00-01-glide-check",
                style: "platform-accept",
                purpose: MenuItemPurpose.SaveRow,
                action: canSend
                    ? registerActionRunner(hb, "send", async ab => {
                          const body: SendAppFeedbackBody = {
                              appID: appID,
                              from: email,
                              message,
                          };

                          void appFacilities.callCloudFunction("sendAppFeedback", body, {});
                          appFacilities.trackEvent?.("feedback sent", { app_id: appID });

                          ab.navigateUp();
                          ab.actionCallbacks.showToast(true, getLocalizedString("added", AppKind.App));

                          return WireActionResult.nondescriptSuccess();
                      })
                    : undefined,
            };

            return [
                {
                    key: encodeScreenKey(hydrationContext.screenKey),
                    title,
                    components: [emailField, messageField],
                    specialComponents: [sendItem],
                    flags: [],
                    isInModal: true,
                    tabIcon: hydrationContext.internalScreen.tabIcon,
                    backAction: this.navigateUpAction,
                },
                undefined,
            ];
        });
    }

    private hydrateChatScreen(
        hydrationContext: ScreenHydrationContext,
        title: string,
        screenContext: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        let userGetters = this.commentUserGetters;
        if (userGetters === undefined) {
            const { adc, computationModel } = this;
            if (computationModel === undefined) return undefined;

            const commentsTable = adc.findTable(commentsTableName);
            if (commentsTable === undefined) return undefined;

            const ib = new InflationBackend(
                this.appFacilities,
                this.adc,
                makeContextTableTypes(makeInputOutputTables(commentsTable)),
                computationModel,
                undefined,
                this.isBuilder,
                this.db,
                this.precomputedSearchableColumns,
                this.writeSource
            );

            userGetters = inflateCommentUserGetters(ib);
            this.commentUserGetters = userGetters;
        }

        return this.hydrateSubscribingRowSpecialScreen(hydrationContext, title, screenContext, rhb => {
            if (this.db === undefined) return undefined;
            const component = hydrateComments(
                rhb,
                this.db,
                this.appID,
                this.appKind,
                globalChatTopic,
                this.isBuilder,
                globalChatTopic,
                "asc",
                WireComponentKind.AppChat,
                defined(userGetters)
            );
            if (component.component === undefined) return undefined;
            return [
                {
                    key: encodeScreenKey(hydrationContext.screenKey),
                    title,
                    components: [component.component],
                    specialComponents: [],
                    flags: [],
                    isInModal: false,
                    tabIcon: hydrationContext.internalScreen.tabIcon,
                },
                component.subsidiaryScreen,
            ];
        });
    }

    private hydrateWebViewScreen(
        hydrationContext: ScreenHydrationContext,
        titleOverride: string | undefined,
        context: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        const title = titleOverride ?? "";

        return this.hydrateSubscribingRowSpecialScreen(hydrationContext, title, context, hb => {
            const [row] = context.inputRows;
            if (row === undefined) return undefined;

            const link = checkString(row.link);

            const component: WireAppWebViewComponent = {
                kind: WireComponentKind.AppWebView,
                link,
                aspectRatio: ImageAspectRatio.Fit,
                allowScrolling: true,
            };

            const doneItem: WireAppMenuItem = {
                kind: WireComponentKind.AppMenuItem,
                title: getLocalizedString("done", AppKind.App),
                icon: "00-01-glide-close",
                style: "platform-cancel",
                action: this.navigateUpAction,
            };
            const shareItem: WireAppMenuItem = {
                kind: WireComponentKind.AppMenuItem,
                title: getLocalizedString("share", AppKind.App),
                icon: "01-28-share-1-alternate",
                style: "icon",
                action: registerActionRunner(hb, "share", async ab => {
                    ab.actionCallbacks.showShareSheet(link, undefined);
                    return WireActionResult.nondescriptSuccess();
                }),
            };

            return [
                {
                    key: encodeScreenKey(hydrationContext.screenKey),
                    title,
                    components: [component],
                    specialComponents: [doneItem, shareItem],
                    flags: [],
                    isInModal: true,
                    tabIcon: hydrationContext.internalScreen.tabIcon,
                },
                undefined,
            ];
        });
    }

    protected hydrateSpecialScreen(
        screen: ScreenDescription | undefined,
        hydrationContext: ScreenHydrationContext,
        titleOverride: string | undefined,
        context: HydratedScreenContext
    ): ScreenHydrationResult | undefined {
        const {
            internalScreen: { screenName },
        } = hydrationContext;
        if (screenName === signInScreenName || screenName === signUpScreenName) {
            return this.hydrateSignInOutSpecialScreen(screenName);
        } else if (screenName === webViewScreenName) {
            return this.hydrateWebViewScreen(hydrationContext, titleOverride, context);
        } else if (screenName === codeScannerScreenName || screenName === liveLinearBarcodeScannerScreenName) {
            const cancelItem: WireAppMenuItem = {
                kind: WireComponentKind.AppMenuItem,
                title: getLocalizedString("cancel", AppKind.App),
                icon: "00-01-glide-close",
                style: "platform-cancel",
                action: this.navigateUpAction,
            };

            return this.hydrateCodeScannerSpecialScreen(hydrationContext, context, [cancelItem]);
        } else if (screenName === shareScreenName) {
            return this.hydrateShareScreen(hydrationContext, context);
        } else if (screenName === sendAppFeedbackScreenName) {
            return this.hydrateSendAppFeedbackScreen(hydrationContext, context);
        } else if (screen?.kind === ScreenDescriptionKind.ShoppingCart) {
            // FIXME: Get the title from the tab
            return this.hydrateShoppingCartScreen(hydrationContext, titleOverride ?? "", context);
        } else if (screen?.kind === ScreenDescriptionKind.Chat) {
            // FIXME: Get the title from the tab
            return this.hydrateChatScreen(hydrationContext, titleOverride ?? "", context);
        } else if (screenName === buyScreenName) {
            return this.hydrateBuyScreen(hydrationContext, context);
        } else {
            return undefined;
        }
    }
}
