export const AppearanceIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.6665 9.99992C1.6665 5.59539 5.43064 2.08325 9.99984 2.08325C14.569 2.08325 18.3332 5.59539 18.3332 9.99992C18.3332 11.5731 17.9113 12.6265 17.0286 13.1768C16.2074 13.6888 15.1667 13.6337 14.2912 13.5196C13.9843 13.4797 13.664 13.4267 13.3588 13.3762C13.2179 13.3528 13.0803 13.3301 12.9485 13.3093C12.5152 13.2412 12.1248 13.1912 11.7778 13.1861C11.0815 13.1759 10.7436 13.3408 10.559 13.7104C10.449 13.9308 10.4494 14.1968 10.5713 14.5587C10.6789 14.8781 10.851 15.1897 11.0361 15.5249C11.0688 15.5843 11.102 15.6444 11.1353 15.7053C11.2379 15.8933 11.3473 16.1013 11.4229 16.3019C11.4937 16.4895 11.5728 16.7641 11.5133 17.0549C11.4418 17.4048 11.2027 17.6427 10.9074 17.77C10.6472 17.8823 10.3325 17.9166 9.99984 17.9166C5.43064 17.9166 1.6665 14.4044 1.6665 9.99992ZM8.5415 5.20825C7.85114 5.20825 7.2915 5.76789 7.2915 6.45825C7.2915 7.14861 7.85114 7.70825 8.5415 7.70825C9.23184 7.70825 9.7915 7.14861 9.7915 6.45825C9.7915 5.76789 9.23184 5.20825 8.5415 5.20825ZM6.0415 8.74992C5.35115 8.74992 4.7915 9.30959 4.7915 9.99992C4.7915 10.6903 5.35115 11.2499 6.0415 11.2499C6.73186 11.2499 7.2915 10.6903 7.2915 9.99992C7.2915 9.30959 6.73186 8.74992 6.0415 8.74992ZM12.7082 6.45825C12.0178 6.45825 11.4582 7.01789 11.4582 7.70825C11.4582 8.39859 12.0178 8.95825 12.7082 8.95825C13.3985 8.95825 13.9582 8.39859 13.9582 7.70825C13.9582 7.01789 13.3985 6.45825 12.7082 6.45825Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
    </svg>
);

export const AppInfoIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.73609 2.131C7.58029 2.09285 7.85019 2.0835 9.99992 2.0835C12.1497 2.0835 12.4195 2.09213 13.2638 2.13171C13.9204 2.14459 14.5702 2.2687 15.1853 2.49876C15.7135 2.69762 16.1919 3.0093 16.5873 3.41205C16.9904 3.80755 17.3023 4.28625 17.5013 4.81475C17.7317 5.42989 17.856 6.07962 17.8691 6.73634C17.9073 7.58054 17.9166 7.85043 17.9166 10.0002C17.9166 12.1499 17.9079 12.4197 17.8683 13.264C17.8555 13.9207 17.7314 14.5704 17.5013 15.1856C17.2979 15.7117 16.9868 16.1894 16.588 16.5882C16.1892 16.9871 15.7114 17.2982 15.1853 17.5016C14.5702 17.7319 13.9204 17.8562 13.2638 17.8693C12.4195 17.9075 12.1497 17.9168 9.99992 17.9168C7.85019 17.9168 7.58029 17.9082 6.73609 17.8686C6.07942 17.8557 5.42969 17.7317 4.8145 17.5016C4.28633 17.3027 3.80789 16.991 3.41254 16.5882C3.00943 16.1927 2.6975 15.7141 2.49852 15.1856C2.2682 14.5704 2.14385 13.9207 2.13075 13.264C2.09261 12.4197 2.08325 12.1499 2.08325 10.0002C2.08325 7.85043 2.09189 7.58054 2.13147 6.73634C2.14434 6.07966 2.26845 5.42993 2.49852 4.81475C2.69738 4.28657 3.00905 3.80813 3.41181 3.41278C3.8073 3.00967 4.286 2.69775 4.8145 2.49876C5.42964 2.26845 6.07938 2.1441 6.73609 2.131ZM12.0799 8.37574C12.1198 8.42691 12.158 8.47965 12.1942 8.53389C12.4842 8.96789 12.6391 9.47814 12.6391 10.0001C12.6391 10.7001 12.361 11.3714 11.8661 11.8663C11.3711 12.3612 10.6998 12.6393 9.99989 12.6393C9.47789 12.6393 8.96764 12.4845 8.53364 12.1945C8.5184 12.1843 8.50327 12.174 8.48826 12.1635C8.23628 11.9874 8.01813 11.7694 7.84258 11.5203C7.73097 11.3619 7.63657 11.1909 7.56166 11.0101C7.36191 10.5279 7.30964 9.99722 7.41148 9.4853C7.51331 8.9733 7.76466 8.50305 8.13375 8.134C8.50281 7.7649 8.97306 7.51355 9.48506 7.41172C9.99698 7.30989 10.5276 7.36215 11.0098 7.5619C11.1871 7.63535 11.355 7.72754 11.5108 7.83632C11.7257 7.98639 11.9177 8.16806 12.0799 8.37574Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
    </svg>
);

export const PrivacyIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.5"
            d="M5.8335 6.04167C5.8335 3.74048 7.69898 1.875 10.0002 1.875C12.3013 1.875 14.1668 3.74048 14.1668 6.04167V7.56251L12.9168 7.5V6.04167C12.9168 4.43083 11.611 3.125 10.0002 3.125C8.38933 3.125 7.0835 4.43083 7.0835 6.04167V7.5L5.8335 7.56251V6.04167Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.3335 7.5C5.12436 7.5 3.3335 9.29086 3.3335 11.5V14.3333C3.3335 16.5425 5.12435 18.3333 7.33349 18.3333H12.6668C14.876 18.3333 16.6668 16.5425 16.6668 14.3333V11.5C16.6668 9.29086 14.876 7.5 12.6668 7.5H7.3335ZM10.6252 11.6667C10.6252 11.3215 10.3453 11.0417 10.0002 11.0417C9.655 11.0417 9.37516 11.3215 9.37516 11.6667V14.1667C9.37516 14.5118 9.655 14.7917 10.0002 14.7917C10.3453 14.7917 10.6252 14.5118 10.6252 14.1667V11.6667Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
    </svg>
);

export const NotificationsIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.6663 1.66669C14.8254 1.66669 13.333 3.15907 13.333 5.00002C13.333 6.84097 14.8254 8.33335 16.6663 8.33335C18.5073 8.33335 19.9997 6.84097 19.9997 5.00002C19.9997 3.15907 18.5073 1.66669 16.6663 1.66669ZM14.583 5.00002C14.583 3.84943 15.5158 2.91669 16.6663 2.91669C17.8169 2.91669 18.7497 3.84943 18.7497 5.00002C18.7497 6.15061 17.8169 7.08335 16.6663 7.08335C15.5158 7.08335 14.583 6.15061 14.583 5.00002Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            d="M16.667 9.63539C16.3354 9.63539 16.012 9.60056 15.7002 9.53439C13.9763 10.3667 12.0428 10.8333 10.0003 10.8333C6.83617 10.8333 3.93353 9.71364 1.66699 7.84888V14.375C1.66699 15.6406 2.69301 16.6666 3.95866 16.6666H16.042C17.3077 16.6666 18.3337 15.6406 18.3337 14.375V9.32673C17.8164 9.52614 17.2545 9.63539 16.667 9.63539Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            d="M12.0316 4.99998C12.0316 4.41249 12.1409 3.85053 12.3402 3.33331H3.95866C2.69301 3.33331 1.66699 4.35933 1.66699 5.62498V6.16829C3.81073 8.28014 6.75334 9.58331 10.0003 9.58331C11.4399 9.58331 12.8197 9.32715 14.0964 8.85798C12.8516 8.02681 12.0316 6.60915 12.0316 4.99998Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
    </svg>
);

export const SharingIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.1665 1.6665C12.3256 1.6665 10.8332 3.15889 10.8332 4.99984C10.8332 5.34155 10.8846 5.67125 10.9801 5.98163L7.59956 7.91337C6.98861 7.15311 6.05105 6.6665 4.99984 6.6665C3.15889 6.6665 1.6665 8.15889 1.6665 9.99984C1.6665 11.8408 3.15889 13.3332 4.99984 13.3332C6.05112 13.3332 6.98873 12.8465 7.59967 12.0862L10.9802 14.0178C10.8846 14.3283 10.8332 14.6581 10.8332 14.9998C10.8332 16.8408 12.3256 18.3332 14.1665 18.3332C16.0074 18.3332 17.4998 16.8408 17.4998 14.9998C17.4998 13.1589 16.0074 11.6665 14.1665 11.6665C13.1153 11.6665 12.1777 12.1531 11.5668 12.9134L8.18622 10.9817C8.28175 10.6713 8.33317 10.3416 8.33317 9.99984C8.33317 9.65809 8.28173 9.32825 8.18617 9.01784L11.5667 7.08614C12.1776 7.8465 13.1153 8.33317 14.1665 8.33317C16.0074 8.33317 17.4998 6.84079 17.4998 4.99984C17.4998 3.15889 16.0074 1.6665 14.1665 1.6665Z"
            fill="currentColor"
            fillOpacity="0.8"
        />
    </svg>
);

export const SignInScreenIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.833496 10C0.833496 7.23857 3.07207 5 5.8335 5C7.47813 5 8.93733 5.79492 9.84725 7.01793C9.87058 7.04936 9.89466 7.0681 9.91091 7.07715C9.91841 7.08131 9.923 7.08283 9.925 7.08333H16.683C17.1892 7.08333 17.6682 7.31348 17.9844 7.70884L18.9844 8.95883C19.4714 9.5675 19.4714 10.4325 18.9844 11.0412L17.9844 12.2912C17.6682 12.6865 17.1892 12.9167 16.683 12.9167H15.0002C14.8708 12.9167 14.7432 12.8866 14.6275 12.8287L13.3335 12.1817L12.0395 12.8287C11.9238 12.8866 11.7962 12.9167 11.6668 12.9167H9.925C9.923 12.9172 9.91841 12.9187 9.91091 12.9228C9.89466 12.9319 9.87058 12.9507 9.84725 12.9821C8.93733 14.2051 7.47813 15 5.8335 15C3.07207 15 0.833496 12.7614 0.833496 10ZM5.8335 11.25C6.52385 11.25 7.0835 10.6903 7.0835 10C7.0835 9.30967 6.52385 8.75 5.8335 8.75C5.14314 8.75 4.5835 9.30967 4.5835 10C4.5835 10.6903 5.14314 11.25 5.8335 11.25Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
    </svg>
);

export const TabletAndDesktopIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.6665 5.62516C1.6665 4.35951 2.69252 3.3335 3.95817 3.3335H16.0415C17.3072 3.3335 18.3332 4.35951 18.3332 5.62516V14.3752C18.3332 15.6408 17.3072 16.6668 16.0415 16.6668H3.95817C2.69252 16.6668 1.6665 15.6408 1.6665 14.3752V5.62516ZM7.2915 12.9168C6.94633 12.9168 6.6665 13.1967 6.6665 13.5418C6.6665 13.887 6.94633 14.1668 7.2915 14.1668H12.7082C13.0533 14.1668 13.3332 13.887 13.3332 13.5418C13.3332 13.1967 13.0533 12.9168 12.7082 12.9168H7.2915Z"
            fill="currentColor"
            fillOpacity="0.8"
        />
    </svg>
);

export const UserAgreementsIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.001 1.6665C7.92991 1.6665 6.25098 3.34544 6.25098 5.4165C6.25098 7.48757 7.92991 9.1665 10.001 9.1665C12.0721 9.1665 13.751 7.48757 13.751 5.4165C13.751 3.34544 12.0721 1.6665 10.001 1.6665Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            d="M10.5012 13.9167C10.1657 14.364 10.0027 14.8871 10.001 15.4062V15.4233C10.0036 16.1804 10.3487 16.9274 11.0012 17.4167L11.1122 17.5H4.66636C3.7204 17.5 2.83944 16.6548 3.08092 15.5803C3.15389 15.2557 3.24679 14.9391 3.35863 14.6323C3.36279 14.6204 3.36707 14.6086 3.37146 14.5967C3.76337 13.537 4.38169 12.5974 5.18316 11.8483C6.40902 10.7018 8.06494 10 10.001 10C11.7114 10 13.2031 10.5477 14.3728 11.4658L13.402 13.0839C12.3838 12.6902 11.1883 13.0004 10.5012 13.9167Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            opacity="0.5"
            d="M17.3821 12.9287C17.6189 12.534 17.4909 12.0222 17.0962 11.7854C16.7017 11.5486 16.1897 11.6765 15.9529 12.0712L13.9283 15.4455L13.0008 14.75C12.6327 14.4738 12.1103 14.5485 11.8342 14.9166C11.5581 15.2848 11.6327 15.8071 12.0008 16.0833L13.6675 17.3333C13.8562 17.4749 14.0962 17.5298 14.3277 17.4844C14.5592 17.439 14.7607 17.2977 14.8821 17.0954L17.3821 12.9287Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
    </svg>
);

export const DataSyncIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.66536 6.8042C5.57179 4.7605 7.61839 3.3335 10.0002 3.3335C12.9644 3.3335 15.4122 5.54445 15.7845 8.40716C17.7107 8.77408 19.1668 10.4671 19.1668 12.5002C19.1668 14.8013 17.3013 16.6668 15.0002 16.6668H5.8335C3.07207 16.6668 0.833496 14.4282 0.833496 11.6668C0.833496 9.30733 2.46717 7.33059 4.66536 6.8042ZM10.0002 10.0892V9.37425C9.30983 9.37425 8.75016 9.93391 8.75016 10.6242C8.75016 11.3146 9.30983 11.8742 10.0002 11.8742C10.4086 11.8742 10.7712 11.6792 11.0007 11.3737C11.2771 11.0057 11.7995 10.9315 12.1675 11.2079C12.5354 11.4843 12.6097 12.0067 12.3332 12.3747C11.8026 13.0812 10.9549 13.5409 10.0002 13.5409C8.38933 13.5409 7.0835 12.2351 7.0835 10.6242C7.0835 9.01341 8.38933 7.70757 10.0002 7.70757V6.99256C10.0002 6.62933 10.4326 6.44002 10.6994 6.6864L12.3768 8.23474C12.5556 8.39975 12.5556 8.68208 12.3768 8.84708L10.6994 10.3954C10.4326 10.6417 10.0002 10.4525 10.0002 10.0892Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
    </svg>
);

export const TemplateIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.9262 8.3335C13.0618 8.3335 13.1891 8.39516 13.2818 8.494C13.913 9.1665 14.8094 9.5835 15.7976 9.5835C16.4202 9.5835 17.0001 9.41991 17.5 9.1355V15.2086C17.5 16.4742 16.474 17.5002 15.2083 17.5002H12.2916C11.9465 17.5002 11.6666 17.2204 11.6666 16.8752V14.5835C11.6666 13.663 10.9205 12.9168 9.99998 12.9168C9.07948 12.9168 8.33332 13.663 8.33332 14.5835V16.8752C8.33332 17.2204 8.05349 17.5002 7.70832 17.5002H4.79167C3.52602 17.5002 2.5 16.4742 2.5 15.2085V9.13566C2.99983 9.41991 3.5796 9.5835 4.20218 9.5835C5.19031 9.5835 6.0867 9.1665 6.71789 8.494C6.81068 8.39516 6.93792 8.3335 7.07349 8.3335C7.20639 8.3335 7.33148 8.39275 7.4237 8.4885C8.07245 9.16175 8.98398 9.5835 9.99982 9.5835C11.0157 9.5835 11.9272 9.16183 12.576 8.4885C12.6682 8.39275 12.7933 8.3335 12.9262 8.3335Z"
            fill="currentColor"
            fillOpacity="0.8"
        />
        <path
            opacity="0.5"
            d="M2.2191 4.46758C2.38039 3.33859 3.34729 2.5 4.48774 2.5H6.66685L6.39607 6.29086C6.31387 7.44169 5.35627 8.33333 4.2025 8.33333C2.8642 8.33333 1.83618 7.14801 2.02545 5.82317L2.2191 4.46758Z"
            fill="currentColor"
            fillOpacity="0.8"
        />
        <path
            opacity="0.5"
            d="M13.3335 2.5H15.5127C16.6531 2.5 17.62 3.33859 17.7812 4.46758L17.9749 5.82317C18.1642 7.14802 17.1362 8.33333 15.7978 8.33333C14.6441 8.33333 13.6865 7.44169 13.6043 6.29086L13.3335 2.5Z"
            fill="currentColor"
            fillOpacity="0.8"
        />
        <path
            opacity="0.5"
            d="M7.91645 2.5H12.0831L12.3217 5.83969C12.4179 7.18718 11.3507 8.33333 9.99977 8.33333C8.64885 8.33333 7.58166 7.18718 7.67791 5.83968L7.91645 2.5Z"
            fill="currentColor"
            fillOpacity="0.8"
        />
    </svg>
);

export const IntegrationsIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.5"
            d="M10 1.875C7.92893 1.875 6.25 3.55393 6.25 5.625C6.25 7.69607 7.92893 9.375 10 9.375C12.0711 9.375 13.75 7.69607 13.75 5.625C13.75 3.55393 12.0711 1.875 10 1.875Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            opacity="0.75"
            d="M5.4165 9.79175C3.34544 9.79175 1.6665 11.4707 1.6665 13.5417C1.6665 15.6128 3.34544 17.2917 5.4165 17.2917C7.48757 17.2917 9.1665 15.6128 9.1665 13.5417C9.1665 11.4707 7.48757 9.79175 5.4165 9.79175Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            d="M14.5835 9.79175C12.5124 9.79175 10.8335 11.4707 10.8335 13.5417C10.8335 15.6128 12.5124 17.2917 14.5835 17.2917C16.6546 17.2917 18.3335 15.6128 18.3335 13.5417C18.3335 11.4707 16.6546 9.79175 14.5835 9.79175Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
    </svg>
);

export const BillingSettingsIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.5"
            d="M1.6665 5.8335C1.6665 4.45279 2.7858 3.3335 4.1665 3.3335H15.8332C17.2138 3.3335 18.3332 4.45245 18.3332 5.83329V7.50016H1.6665V5.8335Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            d="M1.6665 9.1665V14.1665C1.6665 15.5473 2.7858 16.6665 4.1665 16.6665H15.8332C17.2139 16.6665 18.3332 15.5473 18.3332 14.1665V9.1665H1.6665Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
    </svg>
);

export const UsersSettingsIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.91602 5.83333C2.91602 3.99238 4.4084 2.5 6.24935 2.5C8.0903 2.5 9.58266 3.99238 9.58266 5.83333C9.58266 7.67428 8.0903 9.16667 6.24935 9.16667C4.4084 9.16667 2.91602 7.67428 2.91602 5.83333Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            opacity="0.3"
            d="M10.416 5.83333C10.416 3.99238 11.9084 2.5 13.7493 2.5C15.5903 2.5 17.0827 3.99238 17.0827 5.83333C17.0827 7.67428 15.5903 9.16667 13.7493 9.16667C11.9084 9.16667 10.416 7.67428 10.416 5.83333Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            d="M6.24913 10C8.64141 10 10.9107 11.6506 11.7553 14.6779C12.1969 16.2609 10.8468 17.5 9.46083 17.5H3.03742C1.65145 17.5 0.301362 16.2609 0.742977 14.6779C1.58756 11.6506 3.85681 10 6.24913 10Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
        <path
            opacity="0.3"
            d="M13.3612 14.2301C12.9547 12.773 12.2558 11.5405 11.3574 10.5851C12.1037 10.1988 12.9193 10 13.7496 10C16.1419 10 18.4112 11.6506 19.2558 14.6779C19.6973 16.2609 18.3473 17.5 16.9613 17.5H12.7748C13.4148 16.6291 13.7071 15.4701 13.3612 14.2301Z"
            fill="currentColor"
            fillOpacity="0.92"
        />
    </svg>
);
