import * as glide from "@glide/plugins";
import { MappingChangeObservable } from "@glide/support";
import MobileDetect from "mobile-detect";

export const plugin = glide.newPlugin({
    id: "device-features",
    name: "Device Info",
    icon: "https://res.cloudinary.com/glide/image/upload/t_integration-logo/plugins/device-features.png",
    description: "Information about the current device and environment",
    tier: "free",
    documentationUrl: "https://www.glideapps.com/docs/automation/integrations/device-info",
});

interface DeviceInfo {
    platform: string;
    platformVersion: string | undefined;
    model: string | undefined;
}

const macOSExtraction = /\(Macintosh; [^)]+ ([0-9_\.]+)\)/;
const macOSFirefoxExtraction = /\(Macintosh; [^);]+ ([0-9\.]+);/;
function extractMacOSVersion(userAgent: string) {
    const match = macOSExtraction.exec(userAgent);
    if (match !== null) return match[1]?.replace(/_/g, ".") ?? "";

    const fxMatch = macOSFirefoxExtraction.exec(userAgent);
    if (fxMatch !== null) return fxMatch[1];

    return "";
}

const iPhoneOSExtraction = /iPhone OS ([0-9_]+)/;
// Since iPadOS 13, Safari lies about being desktop MacOS.
// This only exist to catch really old versions of iOS, back before
// iPadOS was ever a thing.
const iPadOSExtraction = /CPU OS ([0-9_]+) like Mac OS X/;
function extractiOSVersion(userAgent: string) {
    const iOSMatch = iPhoneOSExtraction.exec(userAgent);
    if (iOSMatch !== null) {
        return iOSMatch[1].replace(/_/g, ".");
    }
    const iPadMatch = iPadOSExtraction.exec(userAgent);
    if (iPadMatch !== null) {
        return iPadMatch[1].replace(/_/g, ".");
    }
    return "";
}

function mobileDetectToGetHighEntropyValuesPlatform(md: MobileDetect, userAgent: string) {
    const maybeMobileOS = md.os();
    switch (maybeMobileOS) {
        case "AndroidOS":
            return ["Android", md.versionStr("Android")];
        case "iOS":
        // fallthrough
        case "iPadOS":
            return ["iOS", extractiOSVersion(userAgent)];
        case "webOS":
            return [maybeMobileOS, md.versionStr("webOS")];
        case "WindowsPhoneOS":
            return ["Windows Phone", md.versionStr("Windows Phone OS")];
        case "WindowsMobileOS":
            // FIXME: What was this supposed to be?
            return ["Windows Mobile", ""];
    }
    const extractedMacOSVersion = extractMacOSVersion(userAgent);
    if (extractedMacOSVersion !== "") return ["MacOS", extractedMacOSVersion];

    const extractediOSVersion = extractiOSVersion(userAgent);
    if (extractediOSVersion !== "") return ["iOS", extractediOSVersion];

    if (userAgent.includes("Linux")) return ["Linux", ""];
    if (userAgent.indexOf("Windows") >= 0) return ["Windows", md.versionStr("Windows NT")];
    // FIXME: This isn't right.
    if (userAgent.indexOf("X11; CrOS") >= 0) return ["Chrome OS", md.versionStr("Chrome")];
    if (maybeMobileOS === null) return ["", ""];
    return [maybeMobileOS.replace(/OS$/, ""), ""];
}

const safariVersionExtraction = /Version\/([0-9\.]+)/;
const chromeVersionExtraction = /Chrome\/([0-9\.]+)/;
const firefoxVersionExtraction = /Firefox\/([0-9\.]+)/;
const chromeiOSVersionExtraction = /CriOS\/([0-9\.]+)/;
const firefoxiOSVersionExtraction = /FxiOS\/([0-9\.]+)/;
function fallbackBrowserVersion(userAgent: string) {
    const crMatch = chromeVersionExtraction.exec(userAgent);
    if (crMatch !== null) return crMatch[1];

    const fxMatch = firefoxVersionExtraction.exec(userAgent);
    if (fxMatch !== null) return fxMatch[1];

    const criOSMatch = chromeiOSVersionExtraction.exec(userAgent);
    if (criOSMatch !== null) return criOSMatch[1];

    const fxiOSMatch = firefoxiOSVersionExtraction.exec(userAgent);
    if (fxiOSMatch !== null) return fxiOSMatch[1];

    const sfMatch = safariVersionExtraction.exec(userAgent);
    if (sfMatch !== null) return sfMatch[1];

    return "";
}

function fallbackBrowserName(userAgent: string) {
    const crMatch = chromeVersionExtraction.exec(userAgent);
    if (crMatch !== null) {
        if (crMatch[1] !== undefined) return "Chrome";
    }
    const fxMatch = firefoxVersionExtraction.exec(userAgent);
    if (fxMatch !== null) {
        if (fxMatch[1] !== undefined) return "Firefox";
    }
    if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") < 0) return "Safari";
    return "";
}

// Utility function to get device info
// Conveniently, it's easy to test!
export async function getDeviceInfo(userAgent: string, screen: Screen) {
    const anyNavigator = (typeof navigator === "undefined" ? {} : navigator) as any;
    const md = new MobileDetect(userAgent);
    let device: DeviceInfo | undefined;
    if (typeof anyNavigator.userAgentData?.getHighEntropyValues === "function") {
        try {
            device = await anyNavigator.userAgentData.getHighEntropyValues([
                "platform",
                "model",
                "platformVersion",
                "architecture",
            ]);
        } catch (e: unknown) {
            // We might not have been allowed to get this information.
            // We'd get a DOMException with the code NotAllowedError when this happens.
        }
    }
    if (device === undefined) {
        const [platform, platformVersion] = mobileDetectToGetHighEntropyValuesPlatform(md, userAgent);
        device = {
            platform,
            platformVersion,
            model: undefined,
        };
    }

    return {
        deviceType: device.platform,
        deviceBrand:
            device.platform === "Android" || device.platform === "Chrome OS"
                ? "Google"
                : device.platform === "Windows"
                ? "Microsoft"
                : device.platform === "Linux"
                ? "Linux"
                : device.platform === ""
                ? ""
                : "Apple",
        deviceName: device.model ?? "",
        screenHeight: screen.height,
        screenWidth: screen.width,
        windowWidth: typeof window === "undefined" ? 0 : window.innerWidth,
        windowHeight: typeof window === "undefined" ? 0 : window.innerHeight,
        screenDensity: typeof window === "undefined" ? 1 : window.devicePixelRatio,
        gpu: anyNavigator.gpu?.adapter?.name,
        // md.userAgent() can fail and return `null` so we have to fallback from the fallback.
        browserName: anyNavigator.userAgentData?.brands[0]?.brand ?? md.userAgent() ?? fallbackBrowserName(userAgent),
        browserVersion: anyNavigator.userAgentData?.brands[0]?.version ?? fallbackBrowserVersion(userAgent),
        osName: device.platform,
        osVersion: device.platformVersion,
        isTouchscreen:
            (typeof window !== "undefined" && "ontouchstart" in window) ||
            (anyNavigator.maxTouchPoints ?? 0) > 0 ||
            (anyNavigator.msMaxTouchPoints ?? 0) > 0,
    };
}

const deviceType = glide.makeParameter({
    name: "DeviceType",
    type: "string",
});
const deviceBrand = glide.makeParameter({
    name: "DeviceBrand",
    type: "string",
});
const deviceName = glide.makeParameter({
    name: "DeviceName",
    type: "string",
});
const screenHeight = glide.makeParameter({
    name: "ScreenHeight",
    type: "number",
});
const screenWidth = glide.makeParameter({
    name: "ScreenWidth",
    type: "number",
});
const windowHeight = glide.makeParameter({
    name: "WindowHeight",
    type: "number",
});
const windowWidth = glide.makeParameter({
    name: "WindowWidth",
    type: "number",
});
const screenDensity = glide.makeParameter({
    name: "ScreenDensity",
    type: "number",
});
const gpu = glide.makeParameter({
    name: "Gpu",
    type: "string",
});
const browserName = glide.makeParameter({
    name: "BrowserName",
    type: "string",
});
const browserVersion = glide.makeParameter({
    name: "BrowserVersion",
    type: "string",
});
const osName = glide.makeParameter({
    name: "OsName",
    type: "string",
});
const osVersion = glide.makeParameter({
    name: "OsVersion",
    type: "string",
});
const isTouchscreen = glide.makeParameter({
    name: "IsTouchscreen",
    type: "boolean",
});

// We really have no business doing this more than once per window invocation.
// If you have to debug it, you can afford to reload :P
// - Dani
let deviceInfoPromise: ReturnType<typeof getDeviceInfo> | undefined;

// Add columns for each device feature
plugin.addClientComputation({
    id: "get-device-info",
    name: "Get device info",
    description: "Get all sorts of useful info about the users device",
    parameters: {},
    results: {
        deviceType,
        deviceBrand,
        deviceName,
        screenHeight,
        screenWidth,
        screenDensity,
        gpu,
        browserName,
        browserVersion,
        osName,
        osVersion,
        isTouchscreen,
        windowWidth,
        windowHeight,
    },
    needsClient: true,
    async execute() {
        if (deviceInfoPromise === undefined) {
            deviceInfoPromise = getDeviceInfo(navigator.userAgent, window.screen);
        }
        const allData = await deviceInfoPromise;
        return glide.Result.Ok(allData);
    },
});

// Add a computation that returns the UNIX time:
plugin.addClientComputation({
    id: "get-time",
    name: "Get time",
    description: "Look at the clock",
    parameters: {},
    results: {
        unixTime: glide.makeParameter({
            name: "UNIX Time",
            type: "number",
            description: "The number of seconds since the Unix Epoch",
        }),
        dayOfWeek: glide.makeParameter({
            name: "Day of Week",
            type: "string",
            description: "The day of the week",
        }),
    },
    async execute() {
        const baseTime = new Date();
        return glide.Result.Ok({
            unixTime: Math.floor(baseTime.getTime() / 1_000),
            dayOfWeek: baseTime.toLocaleDateString(undefined, { weekday: "long" }),
        });
    },
});

const screenSizeParam = glide.makeParameter({
    name: "Screen Size",
    type: "enum",
    description: "The size of the screen",
    values: [
        {
            label: "Small",
            value: "small",
            icon: "st-mobile",
        },
        {
            label: "Large",
            value: "large",
            icon: "st-tablet",
        },
    ],
});

plugin.addClientComputation({
    id: "device-special-values",
    name: "Device",
    description: "Special values for device info",
    parameters: {},
    experimentFlag: "deviceSpecialValuesPlugin",
    icon: {
        icon: "st-templates",
        kind: "stroke",
    },
    results: {
        screenSize: screenSizeParam,
    },
    showAsSpecialValue: true,
    needsClient: true,
    execute: async ctx => {
        const { screenSize } = ctx.app;

        return new MappingChangeObservable(screenSize, newScreenSize => glide.Result.Ok({ screenSize: newScreenSize }));
    },
});
