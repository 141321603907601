import { ConfirmModalStyle } from "@glide/common-core/dist/js/components/confirm-modal";
import { Corners } from "@glide/common-core/dist/js/components/image-types";
import { getLocalizedString } from "@glide/localization";
import { areValuesEqual } from "@glide/common-core/dist/js/components/primitives";
import { AppKind } from "@glide/location-common";
import { getAppTabs } from "@glide/common-core/dist/js/components/SerializedApp";
import { TextBoxJustify } from "@glide/common-core/dist/js/components/text-box-types";
import { generateImageFromSeed } from "@glide/common-core/dist/js/components/triangle-image";
import {
    type ComputationModel,
    type GroundValue,
    type LoadingValue,
    type Row,
    Table,
    isLoadingValue,
    type RootPath,
    Query,
    unwrapLoadingValue,
} from "@glide/computation-model-types";
import { type MinimalAppFacilities, MenuItemPurpose } from "@glide/common-core/dist/js/components/types";
import { asMaybeString, asRow, getRowColumn } from "@glide/common-core/dist/js/computation-model/data";
import type { Database } from "@glide/common-core/dist/js/Database/core";
import {
    type TableName,
    isFavoritedColumnName,
    rowIndexColumnName,
    type TableColumn,
    type TableGlideType,
    BinaryPredicateFormulaOperator,
    SourceColumnKind,
    SpecialValueKind,
    getTableColumnDisplayName,
    getTableName,
    getTableRefTableName,
    isStringTypeKind,
    isTableWritable,
    isBigTableOrExternal,
} from "@glide/type-schema";
import {
    type ArrayScreenDescription,
    type ArrayTransform,
    type ClassOrArrayScreenDescription,
    type ClassScreenDescription,
    type EditScreenDescription,
    ArrayScreenFormat,
    ArrayTransformKind,
    MutatingScreenKind,
    ScreenDescriptionKind,
    isFormScreen,
    type ConditionalActionNode,
    getScreenProperty,
    getStringProperty,
    getSwitchProperty,
} from "@glide/app-description";
import {
    type InputOutputTables,
    doesMutatingScreenAddRows,
    getScreenComponents,
    isClassOrArrayScreenDescription,
    makeInputOutputTables,
} from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import { Appearance, ListItemAccessoryPosition, ListItemFlags, Mood } from "@glide/component-utils";
import {
    type WireAppButtonComponent,
    type WireAppListListComponent,
    type WireAppMenuItem,
    type WireAppPivotBar,
    type WireAppSearchBar,
    type WireAppUserProfileComponent,
    type WireListListItem,
    type WireListListItemAccessory,
    WireImageFallback,
} from "@glide/fluent-components/dist/js/base-components";
import type { WireButtonComponent } from "@glide/fluent-components/dist/js/fluent-components";
import {
    type ExistingAppDescriptionContext,
    getMutatingKindForScreen,
    isAddClassScreenName,
    isAddOrEditOrFormScreenDescription,
    isEditClassScreenName,
    userProfileScreenName,
} from "@glide/function-utils";
import { type ActionDebugger, inflateCompoundAction } from "@glide/generator/dist/js/actions/compound-handler";
import {
    appSortAscendingIconName,
    appSortDescendingIconName,
    appSortShuffleIconName,
    handlerForArrayScreenFormat,
} from "@glide/generator/dist/js/array-screens";
import {
    type ContextTableTypes,
    type HydratedScreenContext,
    type WireActionBackend,
    type WireComponentHydratorWithID,
    type WirePredicate,
    type WireRowComponentHydrationBackend,
    type WireValueGetter,
    type BuilderCallbacks,
    type DynamicFilterResult,
    type HydratedRowContext,
    type InflatedColumn,
    type RowBackends,
    type SearchableColumns,
    type WireActionHydrator,
    type WireComponentHydrationResult,
    type WireComponentPreHydrationResult,
    type WireRowComponentHydrator,
    type WireRowHydrationValueProvider,
    type WireTableComponentHydrationBackend,
    type WireTableComponentHydrationResult,
    type WireTableComponentHydrator,
    type WireTableComponentHydratorConstructor,
    type WireTableTransformer,
    type WireScreen,
    type WireAction,
    type WireComponent,
    WireActionResultBuilder,
    WireActionResult,
    PageScreenTarget,
    ValueChangeSource,
    WireComponentKind,
    makeContextTableTypes,
    HeroImageEffect,
    HeroSize,
    UIBackgroundStyle,
    UIButtonAppearance,
} from "@glide/wire";
import {
    decomposeFavoritesPivot,
    findTabInAppDescription,
    getArrayScreenDynamicFilterAndSorts,
    getCanAddRow,
    getColumnAssignments,
    makeInputOutputTablesForClassOrArrayScreen,
} from "@glide/generator/dist/js/description-utils";
import { getEditOnSubmitActions } from "@glide/generator/dist/js/form-on-submit";
import { decomposePredicateCombinationFormula } from "@glide/formula-specifications";
import { getUserProfileTableInfo } from "@glide/generator/dist/js/user-profile-info";
import * as utils from "@glide/generator/dist/js/wire/utils";
import { isEmptyOrUndefined, nullToUndefined } from "@glide/support";
import {
    assert,
    assertNever,
    defined,
    definedMap,
    filterUndefined,
    mapFilterUndefined,
    panic,
} from "@glideapps/ts-necessities";
import fromPairs from "lodash/fromPairs";
import { type InternalTabHydrator, RowHydrationValueProvider, TableTransformValueProvider } from "./hydration";
import { makeValueGetterForSpecialValue } from "./action-inflation-backend";
import { InflationBackend } from "./inflation";
import {
    ObjectRetirer,
    addSubscriptionHandler,
    emptyHydratedScreenContext,
    emptySubscriptionInfo,
    emptySubscriptionNeeds,
    makeHydratedScreenContext,
    makeMutableSubscriptionSources,
} from "./internal";
import {
    type ScreenHydrationContext,
    type SubscriptionHandlerAndPath,
    type SubscriptionInfo,
    type SubscriptionSources,
    type ValueProviderActionContext,
    ActionTokenMap,
} from "./internal-types";
import { makeSubscriptionHandler } from "./subscription";
import { resolveQueryAsTable } from "./resolve-query";
import { Result } from "@glide/plugins";
import type { WriteSourceType } from "@glide/common-core";

type ScreenContextGetter = (
    hydrationContext: ScreenHydrationContext,
    oldSubscriptionInfo: SubscriptionInfo | undefined
) => [HydratedScreenContext | LoadingValue, SubscriptionInfo | undefined] | undefined;

export type SpecialComponentHydrator = (
    hb: WireRowComponentHydrationBackend,
    isEditValid: boolean,
    haveSearchableComponent: boolean
) => { readonly component: WireComponent; readonly subsidiaryScreen?: WireScreen } | undefined;

interface InflatedScreenCommon {
    readonly tables: InputOutputTables;
    // This will be `undefined` for array screens
    readonly componentHydrators: readonly WireComponentHydratorWithID[] | undefined;
    readonly titleGetter: WireValueGetter;
    readonly filterTransform: WireTableTransformer;
    readonly sortTransform: WireTableTransformer;
    readonly limitTransform: WireTableTransformer;
}

interface InflatedClassScreen {
    readonly tables: InputOutputTables;
    readonly componentHydrators: readonly WireComponentHydratorWithID[];
    readonly specialComponentHydrators: readonly SpecialComponentHydrator[];
    readonly titleGetter: WireValueGetter;

    readonly getScreenContext: ScreenContextGetter;
}

interface InflatedSearch {
    readonly placeholder: string;
    readonly inflatedColumns: readonly InflatedColumn[];
}

interface InflatedPivot {
    readonly favoritesLabel: string;
}

export type DynamicTransformsApplicator = <T extends WireTableComponentHydrationBackend>(
    thb: T,
    table: Table,
    sortTransform: WireTableTransformer,
    limitTransform: WireTableTransformer
) => [thb: T, searchActive: boolean, dynamicFilterResult: DynamicFilterResult | undefined];

interface InflatedArrayScreen {
    readonly table: TableGlideType;
    readonly contentHydratorConstructor: WireTableComponentHydratorConstructor;
    readonly specialComponentHydrators: readonly SpecialComponentHydrator[];

    // If this returns `LoadingValue` as the row context, the `Table` is
    // ignored.
    getContextTable(
        hydrationContext: ScreenHydrationContext,
        oldSubscriptionInfo: SubscriptionInfo | undefined
    ):
        | [
              table: Table,
              si: SubscriptionInfo,
              sortTransform: WireTableTransformer,
              limitTransform: WireTableTransformer,
              context: HydratedRowContext | LoadingValue
          ]
        | undefined;
    // FIXME: This should be combined with `getContextTable` I think.  The
    // only problem is that when we call `getContextTable` we don't have the
    // hydration backend yet, but I don't think there's a fundamental reason
    // why we couldn't.
    readonly applyDynamicTransforms: DynamicTransformsApplicator;
}

interface InflatedUserProfile {
    readonly emailValueGetter: WireValueGetter;
    readonly nameValueGetter: WireValueGetter | undefined;
    readonly imageValueGetter: WireValueGetter | undefined;
}

const dummyUserProfileTable: TableGlideType = {
    name: "$userProfiles",
    columns: [],
    emailOwnersColumn: undefined,
};
const dummyUserProfileTables: ContextTableTypes = makeContextTableTypes(makeInputOutputTables(dummyUserProfileTable));

function inflateFilters(
    ib: InflationBackend | undefined,
    filters: readonly ArrayTransform[],
    inOutputRow: boolean
): WirePredicate {
    if (ib !== undefined) {
        const [predicate] = ib.inflateFilters(filters, inOutputRow);
        return predicate;
    } else {
        for (const filterTransform of filters) {
            if (filterTransform.kind !== ArrayTransformKind.Filter) continue;
            if (filterTransform.isActive === false) continue;
            const condition = decomposePredicateCombinationFormula(filterTransform.predicate)?.spec;
            if (condition === undefined || condition.predicates.length > 0 || condition.combinator !== "and") {
                return () => false;
            }
        }
        return () => true;
    }
}

function inflateBooleanWithFilters(
    ib: InflationBackend | undefined,
    enabled: boolean,
    filters: readonly ArrayTransform[] | undefined,
    inOutputRow: boolean
): boolean | WirePredicate {
    if (!enabled) {
        return false;
    }
    if (filters === undefined) {
        return true;
    }
    return inflateFilters(ib, filters, inOutputRow);
}

function makeHydratorWithDynamicSortFilterScreen(
    screenName: string,
    sortColumns: readonly TableColumn[],
    defaultIsShuffle: boolean,
    dynamicFilterColumn: TableColumn | undefined,
    baseConstructor: WireTableComponentHydratorConstructor
): WireTableComponentHydratorConstructor {
    class Hydrator implements WireTableComponentHydrator {
        private readonly baseHydrator: WireTableComponentHydrator;

        constructor(
            rhb: WireRowComponentHydrationBackend | undefined,
            rowBackends: RowBackends,
            searchActive: boolean,
            builderCallbacks?: BuilderCallbacks
        ) {
            this.baseHydrator = baseConstructor.makeHydrator(rhb, rowBackends, searchActive, builderCallbacks);
        }

        // This will be called whether the component is visible or not.
        public preHydrate(thb: WireTableComponentHydrationBackend): WireComponentPreHydrationResult {
            return this.baseHydrator.preHydrate?.(thb) ?? [true, undefined];
        }

        // These will be called only if the component is visible.
        public prefilterRows(thb: WireTableComponentHydrationBackend): WireTableComponentHydrationBackend | undefined {
            return this.baseHydrator.prefilterRows?.(thb);
        }

        public hydrate(
            thb: WireTableComponentHydrationBackend,
            dynamicFilterResult: DynamicFilterResult | undefined,
            limit: number | undefined
        ): WireTableComponentHydrationResult | undefined {
            const result = this.baseHydrator.hydrate(thb, dynamicFilterResult, limit);
            if (result === undefined) return undefined;
            if (dynamicFilterResult === undefined && sortColumns.length === 0) return result;

            const showFilterSortScreen = utils.getShowFilterSortScreenState(thb);
            if (!showFilterSortScreen.value) return result;

            let filterList: WireAppListListComponent<ArrayScreenFormat.SmallList> | undefined;
            if (dynamicFilterResult !== undefined) {
                // ##dynamicFilterInApps:
                // We don't handle the "open" action for the dynamic filter in
                // Apps, so we make sure that the entries are always hydrated.
                assert(dynamicFilterResult.dynamicFilter.entries !== undefined);
                const items: WireListListItem[] = dynamicFilterResult.dynamicFilter.entries.map((e, i) => {
                    return {
                        key: i.toString(),
                        title: e.displayValue ?? getLocalizedString("all", AppKind.App),
                        subtitle: null,
                        image: null,
                        caption: null,
                        icon: null,
                        action: e.onToggle,
                        accessory: e.selected
                            ? {
                                  component: {
                                      kind: WireComponentKind.AppIconAccessory,
                                      icon: "01-check-1",
                                      action: undefined,
                                  },
                                  position: ListItemAccessoryPosition.Right,
                              }
                            : undefined,
                    };
                });

                filterList = {
                    kind: WireComponentKind.List,
                    format: ArrayScreenFormat.SmallList,
                    title: sortColumns.length > 0 ? getTableColumnDisplayName(defined(dynamicFilterColumn)) : "",
                    emptyMessage: "",
                    allowWrapping: false,
                    imageFallback: WireImageFallback.None,
                    flags: ListItemFlags.DisableChevron | ListItemFlags.DrawSeparator,
                    groups: [{ title: "", items, seeAllAction: undefined }],
                };
            }

            let sortList: WireAppListListComponent<ArrayScreenFormat.SmallList> | undefined;
            if (sortColumns.length > 0) {
                const [sortColumn, sortDirection] = utils.getSortScreenStates(thb);
                function makeItem(column: TableColumn | undefined): WireListListItem {
                    const columnName = column?.name ?? "";
                    const title =
                        definedMap(column, getTableColumnDisplayName) ??
                        getLocalizedString(defaultIsShuffle ? "random" : "default", AppKind.App);

                    let accessory: WireListListItemAccessory | undefined;
                    const isSortedColumn = sortColumn.value === columnName;
                    if (isSortedColumn) {
                        accessory = {
                            component: {
                                kind: WireComponentKind.AppIconAccessory,
                                icon:
                                    column === undefined && defaultIsShuffle
                                        ? appSortShuffleIconName
                                        : sortDirection.value
                                        ? appSortAscendingIconName
                                        : appSortDescendingIconName,
                                action: undefined,
                            },
                            position: ListItemAccessoryPosition.Right,
                        };
                    }
                    const onTap = utils.registerActionRunner(thb, `sort-${columnName}`, [
                        async ab => {
                            if (defaultIsShuffle && columnName === "") {
                                if (isSortedColumn) return WireActionResult.nondescriptSuccess();
                                if (sortColumn.onChangeToken !== undefined) {
                                    ab.valueChanged(sortColumn.onChangeToken, columnName, ValueChangeSource.User);
                                }
                                if (!sortDirection.value && sortDirection.onChangeToken !== undefined) {
                                    ab.valueChanged(sortDirection.onChangeToken, true, ValueChangeSource.User);
                                }
                            } else if (isSortedColumn) {
                                if (sortDirection.onChangeToken === undefined)
                                    return WireActionResult.nondescriptError(true, "Cannot change sort direction");
                                ab.valueChanged(
                                    sortDirection.onChangeToken,
                                    !sortDirection.value,
                                    ValueChangeSource.User
                                );
                            } else {
                                if (sortColumn.onChangeToken === undefined)
                                    return WireActionResult.nondescriptError(true, "Cannot change sort direction");
                                ab.valueChanged(sortColumn.onChangeToken, columnName, ValueChangeSource.User);
                            }
                            return WireActionResult.nondescriptSuccess();
                        },
                        undefined,
                    ]);
                    return {
                        key: definedMap(column, c => "col" + c.name) ?? "none",
                        title,
                        subtitle: null,
                        image: null,
                        caption: null,
                        icon: null,
                        action: onTap,
                        accessory,
                    };
                }

                const items: WireListListItem[] = [makeItem(undefined)];
                items.push(...sortColumns.map(c => makeItem(c)));

                sortList = {
                    kind: WireComponentKind.List,
                    format: ArrayScreenFormat.SmallList,
                    title: dynamicFilterResult !== undefined ? getLocalizedString("sortBy", AppKind.App) : "",
                    emptyMessage: "",
                    allowWrapping: false,
                    imageFallback: WireImageFallback.None,
                    flags: ListItemFlags.DisableChevron | ListItemFlags.DrawSeparator,
                    groups: [{ title: "", items, seeAllAction: undefined }],
                };
            }

            const onDone = utils.registerActionRunner(thb, "onDoneFilterSort", [
                async ab => {
                    if (showFilterSortScreen.onChangeToken === undefined) {
                        return WireActionResult.nondescriptError(true, "Cannot show filter screen");
                    }
                    return ab.valueChanged(showFilterSortScreen.onChangeToken, false, ValueChangeSource.User);
                },
                undefined,
            ]);
            const doneItem: WireAppMenuItem = {
                kind: WireComponentKind.AppMenuItem,
                title: getLocalizedString("done", AppKind.App),
                icon: "00-01-glide-close",
                style: "platform-cancel",
                action: onDone,
            };

            let screenTitle: string;
            if (dynamicFilterResult !== undefined && sortColumns.length > 0) {
                screenTitle = getLocalizedString("filterAndSort", AppKind.App);
            } else if (dynamicFilterResult !== undefined) {
                screenTitle = getLocalizedString("filterBy", AppKind.App);
            } else {
                screenTitle = getLocalizedString("sortBy", AppKind.App);
            }

            const subsidiaryScreen = {
                key: utils.encodeScreenKey(`filter-sort-${screenName}`),
                title: screenTitle,
                flags: [],
                specialComponents: [doneItem],
                components: filterUndefined([filterList, sortList]),
                isInModal: true,
                tabIcon: "",
            };

            return {
                ...result,
                subsidiaryScreen,
            };
        }
    }
    return {
        makeHydrator(...args) {
            return new Hydrator(...args);
        },
    };
}

// If `reference` is `undefined`, then we don't make a reference row.  We do
// that when adding a new/empty row, vs a copy of an existing one.  When
// `reference` is set, then it must be the original row, which we will compare
// against when saving changes.
// https://github.com/quicktype/glide/issues/18406
export type RowToCopyMaker = () => [toCopy: Row, reference: Row | undefined];

export interface ScreenInflatorCallbacks {
    readonly navigateUpAction: WireAction;
    // If sign-in is disabled then you can't sign out, either.
    readonly signOutAction: WireAction | undefined;

    // This has to be callable without a `this`.
    onDataChange(): void;

    fetchTableRows(tableName: TableName): void;

    setColumnsInInvisibleRow(tableName: TableName, row: Row, update: Record<string, GroundValue>): void;
    retireSubscriptionInfo(si: SubscriptionInfo): void;

    getOrAddEmptyRow(
        tableName: TableName,
        key: string,
        makeColumnsToSet: () => Record<string, GroundValue>
    ): Row | undefined;
    getOrCopyRowWithKey(tableName: TableName, makeRow: RowToCopyMaker, key: string): Row | undefined;
    makeRowKeyForScreen(screenName: string, rowID: string | undefined): string;
}

export abstract class ScreenInflator {
    // ##cleanUpInflatedStuff:
    // These all have to be cleaned up when the computation model changes.
    private readonly inflatedClassScreenCommons = new Map<string, InflatedScreenCommon>();
    private readonly inflatedClassScreens = new Map<string, InflatedClassScreen>();
    private inflatedUserProfile: InflatedUserProfile | undefined;
    private inflatedInternalTabs: readonly InternalTabHydrator[] | undefined;
    private readonly inflatedScreenTitles = new Map<
        string,
        readonly [WireValueGetter, InputOutputTables | undefined]
    >();

    constructor(
        public readonly appID: string,
        private readonly appFacilities: MinimalAppFacilities,
        protected readonly context: ExistingAppDescriptionContext,
        public readonly isBuilder: boolean,
        protected readonly computationModel: ComputationModel | undefined,
        private readonly db: Database | undefined,
        protected readonly callbacks: ScreenInflatorCallbacks,
        private readonly precomputedSearchableColumns: SearchableColumns | undefined,
        public readonly writeSource: WriteSourceType
    ) {}

    protected abstract getIsClassScreenSearchNeeded(
        componentHydrators: readonly WireComponentHydratorWithID[]
    ): boolean;
    protected abstract readonly cancelEditSpecialComponent: WireComponent;
    protected abstract makeEditDoneButton(
        action: WireAction | undefined,
        mutatingScreenKind: MutatingScreenKind
    ): WireComponent;
    protected abstract makeAdditionalEditScreenComponents(
        ib: InflationBackend,
        screen: EditScreenDescription,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind
    ): readonly WireComponentHydratorWithID[] | undefined;
    protected abstract makeAdditionalClassScreenSpecialComponents(
        ib: InflationBackend,
        screen: ClassScreenDescription,
        tables: InputOutputTables,
        componentHydrators: readonly WireComponentHydratorWithID[]
    ): readonly SpecialComponentHydrator[] | undefined;
    protected abstract makeDummyUserProfileScreenComponents(
        realEmailAddressGetter: WireValueGetter,
        nameGetter: WireValueGetter
    ): readonly WireComponentHydratorWithID[] | undefined;
    protected abstract makeUserProfileScreenSpecialComponents(
        ib: InflationBackend | undefined,
        screen: ClassScreenDescription | undefined,
        table: TableGlideType
    ): readonly SpecialComponentHydrator[] | undefined;
    protected abstract makeUserProfileScreenAdditionalComponents(
        ib: InflationBackend | undefined,
        screen: ClassScreenDescription | undefined,
        table: TableGlideType
    ): readonly WireComponentHydratorWithID[] | undefined;

    private getRowIndexRootPath(tableName: TableName): RootPath | undefined {
        const maybePaths = defined(this.computationModel).getColumnPaths(tableName)?.get(rowIndexColumnName);
        // This can happen if for whatever reason the comments table is bound
        // to a screen, for example.
        if (maybePaths === undefined) return undefined;
        const [[, rowIndexRootPath]] = maybePaths;
        return rowIndexRootPath;
    }

    protected makeInflationBackend(
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): InflationBackend | undefined {
        if (this.computationModel === undefined) return undefined;

        return new InflationBackend(
            this.appFacilities,
            this.context,
            makeContextTableTypes(tables),
            this.computationModel,
            mutatingScreenKind,
            this.isBuilder,
            this.db,
            this.precomputedSearchableColumns,
            this.writeSource
        );
    }

    public inflateScreenTitle(screenName: string): readonly [WireValueGetter, InputOutputTables | undefined] {
        let tables: InputOutputTables | undefined = undefined;

        function makeUnboundResult(): readonly [WireValueGetter, InputOutputTables | undefined] {
            return [() => null, tables];
        }

        let titleGetter = this.inflatedScreenTitles.get(screenName);
        if (titleGetter !== undefined) {
            return titleGetter;
        }

        let fallbackTitle =
            screenName === userProfileScreenName ? getLocalizedString("profile", this.context.appKind) : undefined;

        if (screenName === userProfileScreenName) {
            const tableName = getUserProfileTableInfo(this.context.appDescription)?.tableName;
            const table = this.context.findTable(tableName);
            return [() => fallbackTitle, definedMap(table, makeInputOutputTables)];
        }

        const screen = this.context.appDescription.screenDescriptions[screenName];
        if (!isClassOrArrayScreenDescription(screen)) return makeUnboundResult();

        const tab = findTabInAppDescription(screenName, this.context.appDescription);
        if (tab !== undefined) {
            const tabTitle = tab[0].title;
            if (!isEmptyOrUndefined(tabTitle)) {
                fallbackTitle = tabTitle;
            }
        }

        tables = makeInputOutputTablesForClassOrArrayScreen(screen, t => this.context.findTable(t));
        if (tables === undefined) return makeUnboundResult();

        // Array screens don't need a title getter
        if (screen.kind !== ScreenDescriptionKind.Class) return makeUnboundResult();

        const mutatingScreenKind = getMutatingKindForScreen(screenName, screen);
        const ib = this.makeInflationBackend(tables, mutatingScreenKind);
        if (ib === undefined) return makeUnboundResult();

        const titleValueGetter = ib.getValueGetterForProperty(screen.title, true)[0];
        titleGetter = [
            hb => {
                const v = titleValueGetter(hb);
                if (utils.isNotEmptyDisplayValue(v)) {
                    return v;
                } else {
                    return fallbackTitle;
                }
            },
            tables,
        ];
        this.inflatedScreenTitles.set(screenName, titleGetter);
        return titleGetter;
    }

    // This caches its results, which means it's ok to call it any number of
    // times for the same screen and get the same result.
    public inflateScreenCommon(
        screenName: string,
        screen: ClassOrArrayScreenDescription
    ): InflatedScreenCommon | undefined {
        let common = this.inflatedClassScreenCommons.get(screenName);
        if (common !== undefined) {
            return common;
        }

        const [titleGetter, tables] = this.inflateScreenTitle(screenName);
        if (tables === undefined) return undefined;

        this.callbacks.fetchTableRows(getTableName(tables.input));

        const mutatingScreenKind = getMutatingKindForScreen(screenName, screen);
        const ib = this.makeInflationBackend(tables, mutatingScreenKind);
        if (ib === undefined) return undefined;

        const componentHydrators =
            screen.kind === ScreenDescriptionKind.Class
                ? getScreenComponents(screen).map(desc =>
                      utils.inflateComponent(ib, desc, mutatingScreenKind !== undefined)
                  )
                : undefined;

        const {
            filter: filterTransform,
            sort: sortTransform,
            limit: limitTransform,
        } = ib.inflateTransforms(screen.transforms ?? []);

        common = { tables, componentHydrators, titleGetter, filterTransform, sortTransform, limitTransform };
        this.inflatedClassScreenCommons.set(screenName, common);
        return common;
    }

    private subscribeToScreenContext(
        screenName: string,
        screen: ClassOrArrayScreenDescription,
        hydrationContext: ScreenHydrationContext,
        sources: SubscriptionSources
    ): SubscriptionInfo {
        const handler = makeSubscriptionHandler(sources, this.callbacks.onDataChange);
        let handlerAndPath: SubscriptionHandlerAndPath | undefined;
        if (handler !== undefined) {
            const subscriptionPath = addSubscriptionHandler(
                hydrationContext,
                `${screen.kind}-screen-context ${screenName}`,
                handler
            );
            handlerAndPath = {
                handler,
                path: subscriptionPath,
            };
        }
        const subscriptionInfo: SubscriptionInfo = {
            isComponentState: false,
            handlerAndPath,
            tokens: new Set(),
            needs: sources.needs,
            effect: undefined,
            subsidiaries: [],
        };
        return subscriptionInfo;
    }

    // Maybe this doesn't belong in here?
    public fetchScreenData(
        screenName: string,
        screen: ClassOrArrayScreenDescription,
        hydrationContext: ScreenHydrationContext,
        applySortAndLimit: boolean
    ):
        | [
              context: HydratedRowContext | LoadingValue,
              si: SubscriptionInfo,
              table: Table,
              sortTransform: WireTableTransformer,
              limitTransform: WireTableTransformer
          ]
        | undefined {
        const { computationModel } = this;
        if (computationModel === undefined) return undefined;

        const common = this.inflateScreenCommon(screenName, screen);
        if (common === undefined) return undefined;
        const { tables, filterTransform, sortTransform, limitTransform } = common;

        const tableName = getTableName(tables.input);

        const sources = makeMutableSubscriptionSources();
        const rowIndexRootPath = this.getRowIndexRootPath(tableName);
        if (rowIndexRootPath === undefined) return undefined;

        // TODO: We don't need to subscribe to the row index if
        // the transformer subscribes to any column.
        sources.columnsInAllDirectRows.get(rowIndexRootPath.rest.key).add(rowIndexColumnName);

        const ttvp = new TableTransformValueProvider(hydrationContext, tableName, sources, undefined, true, []);

        // We have to subscribe after adding sources, not here.
        const subscribe = () => this.subscribeToScreenContext(screenName, screen, hydrationContext, sources);

        let tableData: Table | LoadingValue | undefined;
        if (isBigTableOrExternal(tables.input)) {
            // FIXME: this has to be memoized
            const ib = this.makeInflationBackend(tables, getMutatingKindForScreen(screenName, screen));
            if (ib === undefined) return undefined;

            const queryTransformer = ib.inflateQueryTransformer(tables.input, screen.transforms ?? []);

            const hb = ttvp.makeRowValueProvider(undefined);
            const query = queryTransformer(hb, new Query(tableName));

            if (query === undefined || isLoadingValue(query)) {
                tableData = query;
            } else {
                // Screen contexts are a single row
                tableData = resolveQueryAsTable(query.withLimit(1), hydrationContext, sources, unwrapLoadingValue);
            }
        } else {
            hydrationContext.fetchTableData(tableName);
            tableData = computationModel.getBaseDataForTable(tableName);
        }

        if (isLoadingValue(tableData)) {
            return [tableData, subscribe(), new Table(), sortTransform, limitTransform];
        }

        let rows: Table | undefined;
        if (isBigTableOrExternal(tables.input)) {
            rows = tableData;
        } else {
            rows = definedMap(tableData, t => filterTransform(ttvp, t));
            if (applySortAndLimit) {
                rows = definedMap(rows, t => limitTransform(ttvp, sortTransform(ttvp, t)));
            }
        }
        rows = rows ?? new Table();

        let screenContext: HydratedRowContext;
        if (rows.size === 0) {
            screenContext = emptyHydratedScreenContext;
        } else {
            screenContext = makeHydratedScreenContext(rows.asArray(), undefined, undefined);
        }

        return [screenContext, subscribe(), rows, sortTransform, limitTransform];
    }

    protected fetchSpecificInputRows(
        screenName: string,
        screen: ClassOrArrayScreenDescription,
        hydrationContext: ScreenHydrationContext,
        withFilter: boolean
    ):
        | {
              readonly table: Table | LoadingValue;
              readonly subscriptionInfo: SubscriptionInfo;
              readonly sortTransform: WireTableTransformer;
              readonly limitTransform: WireTableTransformer;
          }
        | undefined {
        const { inputRowIDs } = hydrationContext.internalScreen.context;

        const common = this.inflateScreenCommon(screenName, screen);
        if (common === undefined) return undefined;
        const { tables, filterTransform, sortTransform, limitTransform } = common;

        // Get input rows and subscribe
        const tableName = getTableName(tables.input);
        const isQueryable = isBigTableOrExternal(tables.input);

        const sources = makeMutableSubscriptionSources();
        const rowIndexRootPath = this.getRowIndexRootPath(tableName);
        if (rowIndexRootPath === undefined) return undefined;

        // First we try to get the input rows from the table data we have.  If
        // there are rows we can't find, and this is a queryable data source,
        // we make a query.
        hydrationContext.fetchTableData(tableName);
        let tableData = this.computationModel?.getBaseDataForTable(tableName);

        let tableDataFromQuery: Table | LoadingValue | undefined;
        if (isQueryable) {
            const rowIDColumnName = tables.input.rowIDColumn;
            if (inputRowIDs.length > 0 && rowIDColumnName !== undefined) {
                // We're only fetching the first row ID.  Apps support
                // multiple, in array screens.
                const query = new Query(tableName).withCondition({
                    kind: BinaryPredicateFormulaOperator.Equals,
                    lhs: { columnName: rowIDColumnName },
                    rhs: inputRowIDs[0],
                    negated: false,
                });
                // We do this query whether we get the row from the
                // computation model or not, because the query might be stale,
                // i.e. we might have done a "Reload Query" action since we
                // last got this row.  In that case this will re-query from
                // the data source and update the row in the computation
                // model.
                tableDataFromQuery = resolveQueryAsTable(query, hydrationContext, sources, unwrapLoadingValue);
            }
        }

        let rows: Row[] = [];

        for (const fromQuery of [false, true]) {
            if (fromQuery) {
                if (tableDataFromQuery === undefined) break;
                assert(isQueryable);
                tableData = tableDataFromQuery;
            }

            rows = [];
            for (const rowID of inputRowIDs) {
                sources.columnsInRows.get(rowIndexRootPath.rest.key).get(rowID).add(rowIndexColumnName);

                if (isLoadingValue(tableData)) continue;

                const row = tableData?.get(rowID);
                if (row === undefined) continue;
                rows.push(row);
            }

            // If we've gotten all the rows, we're done
            if (rows.length === inputRowIDs.length) break;
        }

        let resultTable: Table | LoadingValue;
        if (isLoadingValue(tableData)) {
            resultTable = tableData;
        } else {
            resultTable = new Table(rows);
            if (withFilter) {
                const ttvp = new TableTransformValueProvider(hydrationContext, tableName, sources, undefined, true, []);
                resultTable = filterTransform(ttvp, resultTable);
            }
        }

        const subscriptionInfo = this.subscribeToScreenContext(screenName, screen, hydrationContext, sources);

        return { table: resultTable, subscriptionInfo, sortTransform, limitTransform };
    }

    private inflateRegularClassScreen(
        screenName: string,
        screen: ClassScreenDescription
    ): InflatedClassScreen | undefined {
        const common = this.inflateScreenCommon(screenName, screen);
        if (common === undefined) return undefined;
        const { tables, titleGetter } = common;
        let { componentHydrators } = common;
        assert(componentHydrators !== undefined);

        const mutatingScreenKind = getMutatingKindForScreen(screenName, screen);
        const ib = this.makeInflationBackend(tables, mutatingScreenKind);
        if (ib === undefined) return undefined;

        const specialComponentHydrators: SpecialComponentHydrator[] = [];

        const outputTableName = getTableName(tables.output);

        // Apps set column assignments when the edit/form screen is pushed,
        // not when it's submitted.
        // https://github.com/quicktype/glide/issues/15547
        const assignsValuesOnPush = this.context.appKind === AppKind.App;
        let getOutputValues: ((hb: WireRowHydrationValueProvider) => Record<string, GroundValue>) | undefined;

        if (mutatingScreenKind !== undefined) {
            assert(isAddOrEditOrFormScreenDescription(screenName, screen));

            const outputValueGetters = utils.inflateColumnAssignments(
                ib,
                tables.output,
                getColumnAssignments(screen),
                doesMutatingScreenAddRows(mutatingScreenKind)
            );

            getOutputValues = (hb: WireRowHydrationValueProvider) => {
                return fromPairs(
                    mapFilterUndefined(outputValueGetters, ([n, g]) => {
                        const v = g(hb);
                        // We ignore unbound values
                        if (v === null) return undefined;
                        return [n, v];
                    })
                );
            };

            const onSubmitHydrator = utils.inflateActions(
                ib.makeInflationBackendForOutputTable(),
                getEditOnSubmitActions(screen)
            );

            specialComponentHydrators.push(
                (hb, isEditValid) => {
                    let action: WireAction | undefined;

                    if (
                        isEditValid &&
                        utils.getCanEditFromNetworkStatus(hb, this.context.eminenceFlags, mutatingScreenKind)
                    ) {
                        const { rowContext: rowScreenContext } = hb;
                        assert(rowScreenContext !== undefined);

                        const { outputRow } = rowScreenContext;
                        // This can happen when the
                        // ##outputTableIsntLoadedYet.  Once it's loaded we'll
                        // rehydrate and everything will be fine.
                        if (outputRow === undefined) return undefined;

                        const onSubmit = utils.hydrateOnSubmitAction(onSubmitHydrator, () =>
                            hb.makeHydrationBackendForOutputRow()
                        );

                        if (onSubmit !== false) {
                            const columnAssignmentValues = assignsValuesOnPush
                                ? undefined
                                : defined(getOutputValues)(hb);

                            let saveChanges: (
                                ab: WireActionBackend
                            ) => Promise<[result: Result, newRow: Row | undefined]>;
                            // The "row that's being edited" becomes the
                            // input row in the on-submit action. In Edit
                            // screens, however, the output row is a copy
                            // of the edited row, and has a different row
                            // ID, which we don't want to expose, so we
                            // use the input row, which is the original
                            // row that we've just written back to. In
                            // Form screens that is not a problem, but we
                            // do need to use the new row there, because
                            // the invisible row that's our current output
                            // row has already been removed from the
                            // table.
                            // https://github.com/quicktype/glide/issues/12551
                            if (mutatingScreenKind === MutatingScreenKind.EditScreen) {
                                saveChanges = async ab => [
                                    await ab.saveEditScreenChanges(outputTableName, rowScreenContext),
                                    undefined,
                                ];
                            } else {
                                saveChanges = async ab => {
                                    const addResult = await ab.addRow(outputTableName, outputRow, undefined, true);
                                    if (addResult.ok) {
                                        return [Result.Ok(), addResult.result];
                                    } else {
                                        return [addResult, undefined];
                                    }
                                };
                            }

                            action = utils.registerBusyActionRunner(hb, "onSubmitButton", () => [
                                async (ab, handled) => {
                                    ab.navigateUp();
                                    if (columnAssignmentValues !== undefined) {
                                        this.callbacks.setColumnsInInvisibleRow(
                                            outputTableName,
                                            outputRow,
                                            columnAssignmentValues
                                        );
                                    }
                                    const [result, newRow] = await saveChanges(ab);
                                    if (!result.ok) return WireActionResult.fromResult(result);
                                    // The ##onSubmitActionContext is the
                                    // output row.
                                    if (onSubmit !== undefined) {
                                        const onSubmitAB = ab.makeActionBackendForOnSubmit(newRow);
                                        return await onSubmitAB.invoke(
                                            "submit add/edit/form screen",
                                            onSubmit,
                                            handled
                                        );
                                    }
                                    return WireActionResult.nondescriptSuccess();
                                },
                                undefined,
                            ]);
                        }
                    }

                    return { component: this.makeEditDoneButton(action, mutatingScreenKind) };
                },
                () => ({ component: this.cancelEditSpecialComponent })
            );
        } else {
            specialComponentHydrators.push(
                ...(this.makeAdditionalClassScreenSpecialComponents(ib, screen, tables, componentHydrators) ?? [])
            );
        }

        const isSearchActive = this.getIsClassScreenSearchNeeded(componentHydrators);
        if (isSearchActive) {
            // Modify component hydrators to not show non-searched components
            // if search is active.
            componentHydrators = componentHydrators.map(hydratorWithID => {
                const [hydrator, id] = hydratorWithID;
                // Components that want search handle it themselves, so we
                // just return their original hydrator.  All other components
                // need to disappear when search becomes active.
                if (hydrator.wantsSearch) {
                    return hydratorWithID;
                }

                class HydratorWrapper implements WireRowComponentHydrator {
                    public static readonly wantsSearch = true;

                    // If the search is active then we don't set this.
                    private readonly hydrator: WireRowComponentHydrator | undefined;

                    constructor(hb: WireRowComponentHydrationBackend, builder: BuilderCallbacks | undefined) {
                        const [, needle] = utils.getScreenSearchState(hb);
                        if (needle !== "") return;

                        this.hydrator = new hydrator(hb, builder);
                    }

                    public preHydrate(): WireComponentPreHydrationResult {
                        return this.hydrator?.preHydrate?.() ?? [true, undefined];
                    }

                    public hydrate(): WireComponentHydrationResult | undefined {
                        return this.hydrator?.hydrate();
                    }
                }

                return [HydratorWrapper, id];
            });
        }

        if (mutatingScreenKind === MutatingScreenKind.EditScreen) {
            assert(isAddOrEditOrFormScreenDescription(screenName, screen));

            componentHydrators = [
                ...componentHydrators,
                ...(this.makeAdditionalEditScreenComponents(ib, screen, tables, mutatingScreenKind) ?? []),
            ];
        }

        return {
            tables,
            componentHydrators,
            specialComponentHydrators,
            titleGetter,
            getScreenContext: (hydrationContext, oldSubscriptionInfo) => {
                // TODO: Don't get new context if subscription is not dirty.

                // TODO: I'm not sure this subscription stuff should be
                // in here, or cloistered off somewhere else.
                if (oldSubscriptionInfo !== undefined) {
                    this.callbacks.retireSubscriptionInfo(oldSubscriptionInfo);
                }

                if (screen.fetchesData === true) {
                    // TODO: Don't make new subscription if we have one.

                    // We've already done all the things that could fail in
                    // this function, so we're asserting that it doesn't.

                    const maybeScreenData = this.fetchScreenData(screenName, screen, hydrationContext, true);
                    // We need to return a screen context, vs `undefined`
                    // here, because some apps have incorrectly configured
                    // tabs (they're bound to the "comments" table, for
                    // example), but still expect the tab to act "regularly",
                    // because OCM did that.
                    if (maybeScreenData === undefined) return [emptyHydratedScreenContext, emptySubscriptionInfo];
                    const [hydratedScreenContext, subscriptionInfo] = maybeScreenData;
                    return [hydratedScreenContext, subscriptionInfo];
                } else {
                    const result = this.fetchSpecificInputRows(screenName, screen, hydrationContext, false);
                    if (result === undefined) return undefined;
                    const { table, subscriptionInfo } = result;

                    if (isLoadingValue(table)) {
                        return [table, subscriptionInfo];
                    }

                    const rows = table.asArray();

                    function maybeGetOutputValues() {
                        if (!assignsValuesOnPush) return {};
                        // FIXME: We make this kind of objects in plenty other
                        // places, too.  Unify.
                        const retirer = new ObjectRetirer();
                        const context: ValueProviderActionContext = {
                            namespace: hydrationContext.namespace,
                            fetchTableData: tn => hydrationContext.fetchTableData(tn),
                            getPathForQuery: q => hydrationContext.getPathForQuery(q),
                            registerObjectToRetire: retirer.registerObjectToRetire,
                            parsedPath: hydrationContext.parsedPath,
                            isOnline: hydrationContext.isOnline,
                            actions: new ActionTokenMap(),
                            pathsSubscribedTo: [],
                            retireSubscriptionInfo: hydrationContext.retireSubscriptionInfo,
                            // FIXME: remove once we're committed to `queriesInComputationModel`
                            resolveQuery: q => hydrationContext.resolveQuery(q),
                            resolveQueryFromRows: (q, s) => hydrationContext.resolveQueryFromRows(q, s),
                            addQueryChangedCallback: hydrationContext.addQueryChangedCallback,
                            removeQueryChangedCallback: hydrationContext.removeQueryChangedCallback,
                        };
                        const vp = new RowHydrationValueProvider(
                            context,
                            makeHydratedScreenContext(rows[0], undefined, undefined),
                            undefined,
                            undefined,
                            undefined,
                            false,
                            []
                        );
                        const outputValues = getOutputValues?.(vp) ?? {};
                        retirer.retire();
                        return outputValues;
                    }

                    // Get/make output row for mutating screens
                    let outputRow: Row | undefined;
                    if (isAddClassScreenName(screenName)) {
                        const tn = getTableRefTableName(screen.type);
                        outputRow = this.callbacks.getOrAddEmptyRow(tn, screenName, maybeGetOutputValues);
                    } else {
                        const row = rows[0];
                        const key = this.callbacks.makeRowKeyForScreen(screenName, row?.$rowID);
                        if (isFormScreen(screen)) {
                            const tn = getTableRefTableName(screen.formType);
                            outputRow = this.callbacks.getOrAddEmptyRow(tn, key, maybeGetOutputValues);
                        } else if (isEditClassScreenName(screenName) && row !== undefined) {
                            const tn = getTableRefTableName(screen.type);
                            outputRow = this.callbacks.getOrCopyRowWithKey(
                                tn,
                                () => [
                                    {
                                        ...row,
                                        ...maybeGetOutputValues(),
                                        $rowID: makeRowID(),
                                        $isVisible: false,
                                    },
                                    row,
                                ],
                                key
                            );
                        }
                    }

                    return [makeHydratedScreenContext(rows, outputRow, undefined), subscriptionInfo];
                }
            },
        };
    }

    private inflateUserProfileScreen(screen: ClassScreenDescription | undefined): InflatedClassScreen | undefined {
        let tables: InputOutputTables;
        let componentHydrators: readonly WireComponentHydratorWithID[];
        let getScreenContext: ScreenContextGetter;
        let titleGetter: WireValueGetter;

        if (this.computationModel === undefined) return undefined;

        const info = getUserProfileTableInfo(this.context.appDescription);

        let ib: InflationBackend | undefined;

        // The user profile screen won't work if the user profile `info` isn't
        // set up correctly, so we just make the dummy one in that case.
        if (screen !== undefined && info !== undefined) {
            // We ignore the table transformer here because the user profile
            // screen isn't supposed to have transforms.
            const common = this.inflateScreenCommon(userProfileScreenName, screen);
            if (common === undefined) return undefined;

            tables = common.tables;
            componentHydrators = defined(common.componentHydrators);
            titleGetter = common.titleGetter;

            getScreenContext = (hydrationContext, oldSubscriptionInfo) => {
                if (oldSubscriptionInfo !== undefined) {
                    this.callbacks.retireSubscriptionInfo(oldSubscriptionInfo);
                }

                let row: Row | undefined;
                let subscriptionInfo: SubscriptionInfo | undefined;
                const { computationModel } = this;
                if (computationModel !== undefined) {
                    const path = computationModel.getUserProfileRowPath();
                    if (path !== undefined) {
                        const handler = defined(
                            makeSubscriptionHandler(
                                {
                                    globalKeys: new Map([[path.rest.key, true]]),
                                    columnsInRows: new Map(),
                                    columnsInAllDirectRows: new Map(),
                                    columnsInAllIndirectRows: new Map(),
                                    needs: emptySubscriptionNeeds,
                                },
                                this.callbacks.onDataChange
                            )
                        );
                        const subscriptionPath = addSubscriptionHandler(
                            hydrationContext,
                            "user-profile-screen-context",
                            handler
                        );
                        subscriptionInfo = {
                            isComponentState: false,
                            handlerAndPath: { handler, path: subscriptionPath },
                            tokens: new Set(),
                            needs: emptySubscriptionNeeds,
                            effect: undefined,
                            subsidiaries: [],
                        };
                        const rowValue = computationModel.ns.get(path);
                        if (!isLoadingValue(rowValue)) {
                            row = definedMap(rowValue, asRow);
                        }
                    }
                }
                return [makeHydratedScreenContext(row, undefined, undefined), subscriptionInfo];
            };

            ib = this.makeInflationBackend(tables, undefined);
        } else {
            tables = dummyUserProfileTables;

            const [realEmailAddressGetter] = makeValueGetterForSpecialValue(
                SpecialValueKind.RealEmailAddress,
                this.computationModel
            );
            const [userNameGetter] = makeValueGetterForSpecialValue(SpecialValueKind.UserName, this.computationModel);

            componentHydrators =
                this.makeDummyUserProfileScreenComponents(realEmailAddressGetter, userNameGetter) ?? [];

            getScreenContext = () => [emptyHydratedScreenContext, undefined];

            titleGetter = () => getLocalizedString("profile", this.context.appKind);
        }

        const specialComponentHydrators = this.makeUserProfileScreenSpecialComponents(ib, screen, tables.input) ?? [];
        const additionalComponents = this.makeUserProfileScreenAdditionalComponents(ib, screen, tables.input);
        if (additionalComponents !== undefined) {
            componentHydrators = componentHydrators.concat(additionalComponents);
        }

        return {
            tables,
            getScreenContext,
            titleGetter,
            componentHydrators,
            specialComponentHydrators,
        };
    }

    public inflateClassScreen(
        screenName: string,
        screen: ClassScreenDescription | undefined
    ): InflatedClassScreen | undefined {
        let inflatedScreen = this.inflatedClassScreens.get(screenName);
        if (inflatedScreen === undefined) {
            if (screenName === userProfileScreenName) {
                inflatedScreen = this.inflateUserProfileScreen(screen);
            } else {
                inflatedScreen = this.inflateRegularClassScreen(screenName, defined(screen));
            }
            if (inflatedScreen === undefined) return undefined;
            this.inflatedClassScreens.set(screenName, inflatedScreen);
        }
        return inflatedScreen;
    }

    public inflateArrayScreen(_screenName: string, _screen: ArrayScreenDescription): InflatedArrayScreen | undefined {
        return panic("Array screens are not supported");
    }

    public inflateUserProfile(): InflatedUserProfile | undefined {
        if (this.inflatedUserProfile !== undefined) return this.inflatedUserProfile;

        if (this.computationModel === undefined) return undefined;

        const ib = this.makeInflationBackend(dummyUserProfileTables, undefined);
        if (ib === undefined) return undefined;

        const info = getUserProfileTableInfo(this.context.appDescription);

        const [emailGetter] = makeValueGetterForSpecialValue(SpecialValueKind.RealEmailAddress, this.computationModel);
        const [nameGetter] = makeValueGetterForSpecialValue(SpecialValueKind.UserName, this.computationModel);

        let inflated: InflatedUserProfile = {
            emailValueGetter: emailGetter,
            nameValueGetter: nameGetter,
            imageValueGetter: undefined,
        };

        if (info !== undefined) {
            const [imageGetter, imageType] = ib.getValueGetterForSourceColumn(
                { kind: SourceColumnKind.UserProfile, name: [info.imageColumnName] },
                false,
                false
            );
            if (imageType !== undefined && isStringTypeKind(imageType.kind)) {
                inflated = { ...inflated, imageValueGetter: imageGetter };
            }
        }

        this.inflatedUserProfile = inflated;
        return inflated;
    }

    public inflateInternalTabs(): readonly InternalTabHydrator[] {
        if (this.inflatedInternalTabs !== undefined) {
            return this.inflatedInternalTabs;
        }

        const appTabs = getAppTabs(this.context.appDescription);

        const userProfileTableName = this.context.userProfileTableInfo?.tableName;
        const userProfileTable = definedMap(userProfileTableName, tn => this.context.findTable(tn));

        let ib: InflationBackend | undefined;
        if (userProfileTable !== undefined) {
            ib = this.makeInflationBackend(makeInputOutputTables(userProfileTable), undefined);
        }

        const inflatedInternalTabs: InternalTabHydrator[] = appTabs.map(tab => {
            const tabScreenName = getScreenProperty(tab.screenName);
            let predicate: WirePredicate;
            if (tab.hidden === true) {
                predicate = () => false;
            } else {
                predicate = inflateFilters(ib, tab.visibilityFilters ?? [], false);
            }

            return hb => {
                if (userProfileTableName !== undefined) {
                    // If we don't do this, an app that has conditions on all
                    // tabs might never fetch any data.
                    hb.context.fetchTableData(userProfileTableName);
                }

                let isVisible = predicate(hb);
                if (isLoadingValue(isVisible)) {
                    isVisible = false;
                }
                return {
                    isVisible,
                    visibilityPredicate: predicate,
                    visibilitySubscriptionInfo: hb.subscribe(
                        `tab-visibility ${tabScreenName}`,
                        this.callbacks.onDataChange
                    ),
                    tabScreenName: tabScreenName ?? "Missing tab screen name",
                };
            };
        });
        // If we don't have an inflation backend it means that we'll have to
        // re-inflate the tabs when we have one, so we don't save the inflated
        // tabs.
        if (ib !== undefined) {
            this.inflatedInternalTabs = inflatedInternalTabs;
        }
        return inflatedInternalTabs;
    }

    public inflateCompoundAction(
        action: ConditionalActionNode,
        tables: InputOutputTables,
        actionDebugger: ActionDebugger
    ): WireActionHydrator | WireActionResult {
        const ib = this.makeInflationBackend(tables, undefined);
        if (ib === undefined) return WireActionResult.nondescriptError(false, "Not initialized");

        return inflateCompoundAction(ib, action, WireActionResultBuilder.nondescript(), undefined, actionDebugger);
    }
}

class PageScreenInflator extends ScreenInflator {
    protected get cancelEditSpecialComponent(): WireButtonComponent {
        return {
            kind: WireComponentKind.Button,
            title: getLocalizedString("cancel", AppKind.Page),
            appearance: UIButtonAppearance.Bordered,
            action: this.callbacks.navigateUpAction,
        };
    }

    protected getIsClassScreenSearchNeeded(): boolean {
        // There is no screen search in Pages.
        return false;
    }

    protected makeEditDoneButton(
        action: WireAction | undefined,
        mutatingScreenKind: MutatingScreenKind
    ): WireComponent {
        if (
            mutatingScreenKind === MutatingScreenKind.AddScreen ||
            mutatingScreenKind === MutatingScreenKind.FormScreen
        ) {
            const button: WireButtonComponent = {
                kind: WireComponentKind.Button,
                title: getLocalizedString("submit", AppKind.Page),
                appearance: UIButtonAppearance.Filled,
                action,
            };
            return button;
        } else if (mutatingScreenKind === MutatingScreenKind.EditScreen) {
            const button: WireButtonComponent = {
                kind: WireComponentKind.Button,
                title: getLocalizedString("submit", AppKind.Page),
                appearance: UIButtonAppearance.Filled,
                action,
            };
            return button;
        } else {
            return assertNever(mutatingScreenKind);
        }
    }

    protected makeAdditionalEditScreenComponents(): readonly WireComponentHydratorWithID[] | undefined {
        return undefined;
    }

    protected makeAdditionalClassScreenSpecialComponents(): readonly SpecialComponentHydrator[] | undefined {
        return undefined;
    }

    protected makeDummyUserProfileScreenComponents(
        realEmailAddressGetter: WireValueGetter
    ): readonly WireComponentHydratorWithID[] | undefined {
        const heroHydrator: WireComponentHydratorWithID = [
            utils.makeSimpleWireRowComponentHydratorConstructor(hb => {
                const emailAddressValue = nullToUndefined(realEmailAddressGetter(hb));
                const emailAddress = isLoadingValue(emailAddressValue) ? undefined : asMaybeString(emailAddressValue);
                const image = generateImageFromSeed(emailAddress ?? "", false);
                return {
                    component: {
                        kind: WireComponentKind.Hero,
                        subtitle: emailAddress,
                        image,
                        backgroundEffect: HeroImageEffect.None,
                        withBlur: false,
                        bgStyle: UIBackgroundStyle.Card,
                        imageCorners: Corners.Rounded,
                        size: HeroSize.Medium,
                        buttons: [],
                    },
                    isValid: true,
                };
            }),
            makeRowID(),
        ];
        const hintHydrator: WireComponentHydratorWithID = [
            utils.makeSimpleWireRowComponentHydratorConstructor(hb => {
                const token = hb.registerAction("profile", async ab => {
                    ab.actionCallbacks.openLink(getDocURL("userProfile"));
                    return WireActionResult.nondescriptSuccess();
                });
                return {
                    component: {
                        kind: WireComponentKind.Hint,
                        description: "You haven't set up the User Profile table yet.",
                        mood: Mood.Neutral,
                        actionTitle: "Learn more",
                        action: { token },
                    },
                    isValid: true,
                };
            }),
            makeRowID(),
        ];

        return [hintHydrator, heroHydrator];
    }

    protected makeUserProfileScreenSpecialComponents(): readonly SpecialComponentHydrator[] | undefined {
        return undefined;
    }

    protected makeUserProfileScreenAdditionalComponents(): readonly WireComponentHydratorWithID[] | undefined {
        return undefined;
    }
}

class AppScreenInflator extends ScreenInflator {
    private readonly inflatedArrayScreens = new Map<string, InflatedArrayScreen>();

    protected get cancelEditSpecialComponent(): WireAppMenuItem {
        return {
            kind: WireComponentKind.AppMenuItem,
            title: getLocalizedString("cancel", AppKind.App),
            icon: "00-01-glide-close",
            style: "platform-cancel",
            action: this.callbacks.navigateUpAction,
        };
    }

    protected makeEditDoneButton(
        action: WireAction | undefined,
        mutatingScreenKind: MutatingScreenKind
    ): WireComponent {
        if (
            mutatingScreenKind === MutatingScreenKind.AddScreen ||
            mutatingScreenKind === MutatingScreenKind.FormScreen
        ) {
            const submitItem: WireAppMenuItem = {
                kind: WireComponentKind.AppMenuItem,
                title: getLocalizedString(
                    mutatingScreenKind === MutatingScreenKind.FormScreen ? "submit" : "add",
                    AppKind.App
                ),
                icon: "00-01-glide-check",
                style: "platform-accept",
                purpose: MenuItemPurpose.AddRow,
                action,
            };
            return submitItem;
        } else if (mutatingScreenKind === MutatingScreenKind.EditScreen) {
            const doneItem: WireAppMenuItem = {
                kind: WireComponentKind.AppMenuItem,
                title: getLocalizedString("done", AppKind.App),
                icon: "00-01-glide-check",
                style: "platform-accept",
                purpose: MenuItemPurpose.SaveRow,
                action,
            };
            return doneItem;
        } else {
            return assertNever(mutatingScreenKind);
        }
    }

    protected makeAdditionalEditScreenComponents(
        ib: InflationBackend,
        screen: EditScreenDescription,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind
    ): readonly WireComponentHydratorWithID[] | undefined {
        if (mutatingScreenKind !== MutatingScreenKind.EditScreen) return undefined;
        if (screen.canDelete !== true) return undefined;

        const predicate = inflateBooleanWithFilters(ib, true, screen.canDeleteFilters, false);
        if (predicate === false) return undefined;

        return [
            [
                utils.makeSimpleWireRowComponentHydratorConstructor(hb => {
                    const canDelete = predicate === true || predicate(hb) === true;
                    if (!canDelete) return undefined;

                    const row = hb.rowContext?.inputRows[0];
                    if (row === undefined) return undefined;

                    let action: WireAction | undefined;
                    if (utils.getCanEditFromNetworkStatus(hb, this.context.eminenceFlags, mutatingScreenKind)) {
                        action = utils.registerActionRunner(hb, "onDelete", [
                            async ab => {
                                ab.navigateUp();
                                ab.navigateUp();
                                const result = await ab.deleteRows(getTableName(tables.output), [row], true);
                                return WireActionResult.fromResult(result);
                            },
                            undefined,
                        ]);
                    }

                    const button: WireAppButtonComponent = {
                        kind: WireComponentKind.AppButton,
                        title: getLocalizedString("deleteItem", AppKind.App),
                        style: Appearance.Simple,
                        mood: Mood.Danger,
                        onTap: action ?? { token: undefined },
                        confirm: {
                            title: getLocalizedString("areYouSureYouWantToDelete", AppKind.App),
                            description: getLocalizedString("actionCannotBeUndone", AppKind.App),
                            cancel: getLocalizedString("cancel", AppKind.App),
                            accept: getLocalizedString("delete", AppKind.App),
                            modalStyle: ConfirmModalStyle.Delete,
                        },
                    };
                    return { component: button, hasValue: false, isValid: true };
                }),
                undefined,
            ],
        ];
    }

    private makeSearchBar(hb: WireRowComponentHydrationBackend, placeholder: string): WireAppSearchBar {
        const [searchEditable] = utils.getScreenSearchState(hb);
        const searchBar: WireAppSearchBar = {
            kind: WireComponentKind.AppSearchBar,
            value: searchEditable,
            placeholder,
        };
        return searchBar;
    }

    protected getIsClassScreenSearchNeeded(componentHydrators: readonly WireComponentHydratorWithID[]): boolean {
        return componentHydrators.some(([h]) => h.wantsSearch);
    }

    protected makeAdditionalClassScreenSpecialComponents(
        ib: InflationBackend,
        screen: ClassScreenDescription,
        tables: InputOutputTables,
        componentHydrators: readonly WireComponentHydratorWithID[]
    ): readonly SpecialComponentHydrator[] | undefined {
        const hydrators: SpecialComponentHydrator[] = [];

        // We don't show ##addEditButtonsInReadonlyTables.
        if (screen.canEdit === true && isTableWritable(tables.input)) {
            const predicate = inflateBooleanWithFilters(ib, true, screen.canEditFilters, false);
            if (predicate !== false) {
                hydrators.push(hb => {
                    const row = hb.rowContext?.inputRows[0];
                    if (row === undefined) return undefined;

                    const canEdit = predicate === true || predicate(hb) === true;
                    if (!canEdit) return undefined;

                    let action: WireAction | undefined;
                    if (hb.isRowIdentified(true) && hb.getIsOnline()) {
                        action = utils.registerActionRunner(hb, "onEditButton", [
                            async ab => {
                                ab.pushDefaultEditScreen(getTableName(tables.input), row, PageScreenTarget.LargeModal);
                                return WireActionResult.nondescriptSuccess();
                            },
                            undefined,
                        ]);
                    }

                    const menuItem: WireAppMenuItem = {
                        kind: WireComponentKind.AppMenuItem,
                        title: getLocalizedString("edit", AppKind.App),
                        icon: "00-01-glide-edit",
                        purpose: MenuItemPurpose.EditRow,
                        action,
                    };
                    return { component: menuItem };
                });
            }
        }

        if (this.getIsClassScreenSearchNeeded(componentHydrators)) {
            hydrators.push((hb, _isEditValue, haveSearchableComponent) => {
                if (!haveSearchableComponent) return undefined;
                return { component: this.makeSearchBar(hb, getLocalizedString("search", AppKind.App)) };
            });
        }

        return hydrators;
    }

    private makeArrayScreenSpecialComponents(
        ib: InflationBackend,
        screen: ArrayScreenDescription,
        table: TableGlideType,
        search: InflatedSearch | undefined,
        pivot: InflatedPivot | undefined,
        dynamicFilter: utils.InflatedDynamicFilter | undefined,
        sortColumns: readonly TableColumn[]
    ): readonly SpecialComponentHydrator[] {
        const hydrators: SpecialComponentHydrator[] = [];

        const predicate = getCanAddRow(screen) && inflateBooleanWithFilters(ib, true, screen.canAddRowFilters, false);
        // We don't show ##addEditButtonsInReadonlyTables.
        if (predicate !== false && table.isReadOnly !== true) {
            hydrators.push(hb => {
                const canAdd = predicate === true || predicate(hb) === true;
                if (!canAdd) return undefined;

                let action: WireAction | undefined;
                if (utils.getCanEditFromNetworkStatus(hb, this.context.eminenceFlags, MutatingScreenKind.AddScreen)) {
                    action = utils.registerActionRunner(hb, "onAddButton", [
                        async ab => {
                            ab.pushDefaultAddScreen(getTableName(table), PageScreenTarget.LargeModal);
                            return WireActionResult.nondescriptSuccess();
                        },
                        undefined,
                    ]);
                }

                const menuItem: WireAppMenuItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title: getLocalizedString("add", AppKind.App),
                    icon: "00-01-glide-add",
                    purpose: MenuItemPurpose.AddRow,
                    action,
                };
                return { component: menuItem };
            });
        }

        if (search !== undefined) {
            hydrators.push(hb => ({ component: this.makeSearchBar(hb, search.placeholder) }));
        }

        if (pivot !== undefined) {
            const { appKind } = ib.adc;
            hydrators.push(hb => {
                const pivotEditable = utils.getPivotState(hb);
                const pivotBar: WireAppPivotBar = {
                    kind: WireComponentKind.AppPivotBar,
                    titles: [getLocalizedString("all", appKind), pivot.favoritesLabel],
                    selectedIndex: pivotEditable,
                };
                return { component: pivotBar };
            });
        }

        if (dynamicFilter !== undefined || sortColumns.length > 0) {
            hydrators.push(hb => {
                const showFilterSortScreen = utils.getShowFilterSortScreenState(hb);

                const onShow = utils.registerActionRunner(hb, "onShowFilterSort", [
                    async ab => {
                        if (showFilterSortScreen.onChangeToken === undefined) {
                            return WireActionResult.nondescriptError(true, "Cannot show filter screen");
                        }
                        return ab.valueChanged(showFilterSortScreen.onChangeToken, true, ValueChangeSource.User);
                    },
                    undefined,
                ]);
                const filterState = utils.getDynamicFilterState(hb, false);
                const { value: filterValue } = filterState.filterEditable;
                const menuItem: WireAppMenuItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title: getLocalizedString("filter", AppKind.App),
                    icon: filterValue.length === 0 ? "01-15-filter-1" : "00-01-glide-filter-active",
                    purpose: MenuItemPurpose.FilterAndSearch,
                    action: onShow,
                };
                return { component: menuItem };
            });
        }

        return hydrators;
    }

    protected makeDummyUserProfileScreenComponents(
        realEmailAddressGetter: WireValueGetter,
        nameGetter: WireValueGetter
    ): readonly WireComponentHydratorWithID[] | undefined {
        const namePath = this.computationModel?.getUserNamePath();
        if (namePath === undefined) return undefined;

        let hintHydrator: WireComponentHydratorWithID | undefined;
        if (this.isBuilder) {
            hintHydrator = [
                utils.makeSimpleWireRowComponentHydratorConstructor(_hb => {
                    return {
                        component: {
                            kind: WireComponentKind.AppHint,
                            title: "",
                            text: "You haven't set up the User Profile table yet.",
                            mood: Mood.Neutral,
                            justify: TextBoxJustify.Left,
                        },
                        isValid: true,
                    };
                }),
                makeRowID(),
            ];
        }
        return filterUndefined([
            hintHydrator,
            [
                utils.makeSimpleWireRowComponentHydratorConstructor(hb => {
                    const emailAddressValue = nullToUndefined(realEmailAddressGetter(hb));
                    const emailAddress = isLoadingValue(emailAddressValue)
                        ? undefined
                        : asMaybeString(emailAddressValue);
                    const nameValue = nullToUndefined(nameGetter(hb));
                    const name = isLoadingValue(nameValue) ? undefined : asMaybeString(nameValue);
                    const component: WireAppUserProfileComponent = {
                        kind: WireComponentKind.AppUserProfile,
                        name: name ?? null,
                        email: emailAddress ?? null,
                        image: null,
                    };
                    return {
                        component,
                        isValid: true,
                    };
                }),
                makeRowID(),
            ],
        ]);
    }

    protected makeUserProfileScreenSpecialComponents(
        ib: InflationBackend | undefined,
        screen: ClassScreenDescription | undefined,
        table: TableGlideType
    ): readonly SpecialComponentHydrator[] | undefined {
        let canEditPredicate: boolean | WirePredicate;
        if (ib !== undefined && screen !== undefined) {
            canEditPredicate = inflateBooleanWithFilters(ib, screen.canEdit === true, screen.canEditFilters, false);
        } else {
            canEditPredicate = false;
        }

        return [
            () => {
                const closeItem: WireAppMenuItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title: getLocalizedString("close", AppKind.App),
                    icon: "00-01-glide-close",
                    style: "platform-cancel",
                    action: this.callbacks.navigateUpAction,
                };
                return { component: closeItem };
            },
            hb => {
                if (this.callbacks.signOutAction === undefined) return undefined;

                const canEdit =
                    canEditPredicate !== false && (canEditPredicate === true || canEditPredicate(hb) === true);
                if (canEdit) return undefined;

                const title = getLocalizedString("signOut", AppKind.App);
                const item: WireAppMenuItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title,
                    action: this.callbacks.signOutAction,
                    purpose: MenuItemPurpose.SignOut,
                    style: "text",
                };
                return { component: item };
            },
            hb => {
                const canEdit =
                    canEditPredicate !== false && (canEditPredicate === true || canEditPredicate(hb) === true);
                if (!canEdit) return undefined;

                const row = hb.rowContext?.inputRows[0];

                const action = utils.registerActionRunner(hb, "onEditButton", [
                    async ab => {
                        ab.pushDefaultEditScreen(getTableName(table), defined(row), PageScreenTarget.LargeModal);
                        return WireActionResult.nondescriptSuccess();
                    },
                    undefined,
                ]);

                const item: WireAppMenuItem = {
                    kind: WireComponentKind.AppMenuItem,
                    title: getLocalizedString("edit", AppKind.App),
                    icon: "00-01-glide-edit",
                    action: row === undefined ? undefined : action,
                    purpose: MenuItemPurpose.EditRow,
                };
                return { component: item };
            },
        ];
    }

    protected makeUserProfileScreenAdditionalComponents(
        ib: InflationBackend | undefined,
        screen: ClassScreenDescription | undefined,
        _table: TableGlideType
    ): readonly WireComponentHydratorWithID[] | undefined {
        let canEditPredicate: boolean | WirePredicate;
        if (ib !== undefined && screen !== undefined) {
            canEditPredicate = inflateBooleanWithFilters(ib, screen.canEdit === true, screen.canEditFilters, false);
        } else {
            canEditPredicate = false;
        }

        return [
            [
                utils.makeSimpleWireRowComponentHydratorConstructor(hb => {
                    if (this.callbacks.signOutAction === undefined) return undefined;

                    const canEdit =
                        canEditPredicate !== false && (canEditPredicate === true || canEditPredicate(hb) === true);
                    if (!canEdit) return undefined;

                    const title = getLocalizedString("signOut", AppKind.App);
                    const button: WireAppButtonComponent = {
                        kind: WireComponentKind.AppButton,
                        title,
                        style: Appearance.Transparent,
                        mood: Mood.Default,
                        onTap: this.callbacks.signOutAction,
                    };
                    return {
                        component: button,
                        isValid: true,
                    };
                }),
                undefined,
            ],
        ];
    }

    public inflateArrayScreen(screenName: string, screen: ArrayScreenDescription): InflatedArrayScreen | undefined {
        let inflatedScreen = this.inflatedArrayScreens.get(screenName);
        if (inflatedScreen !== undefined) return inflatedScreen;

        const handler = handlerForArrayScreenFormat(screen.format);
        if (handler === undefined) return undefined;

        const maybeFilterAndSorts = getArrayScreenDynamicFilterAndSorts(
            screen,
            this.context,
            handler.getNeedsOrder(screen)
        );
        if (maybeFilterAndSorts === undefined) return undefined;
        const { table, filterColumn, sortColumns } = maybeFilterAndSorts;
        const tableName = getTableName(table);
        const tables = makeInputOutputTables(table);

        this.callbacks.fetchTableRows(tableName);

        const ib = this.makeInflationBackend(tables, undefined);
        if (ib === undefined) return undefined;

        const sortColumnGetters = new Map<string, InflatedColumn>();
        for (const c of sortColumns) {
            const inflatedColumn = ib.getValueGetterForColumnInRow(c.name, false, false);
            if (inflatedColumn === undefined) continue;
            sortColumnGetters.set(c.name, inflatedColumn);
        }

        let contentHydratorConstructor = handler.inflateContent(
            ib,
            screen,
            undefined,
            undefined,
            undefined,
            filterColumn?.name
        );
        if (contentHydratorConstructor === undefined) return undefined;

        const defaultIsShuffle = screen.transforms?.some(t => t.kind === ArrayTransformKind.Shuffle) === true;
        contentHydratorConstructor = makeHydratorWithDynamicSortFilterScreen(
            screenName,
            sortColumns,
            defaultIsShuffle,
            filterColumn,
            contentHydratorConstructor
        );

        let search: InflatedSearch | undefined;
        if (getSwitchProperty(screen.search) ?? false) {
            const properties = ib.searchableColumns.columnsUsedByTable.get(tableName) ?? [];
            const inflatedColumns = utils.makeSearchPropertyGetters(ib, properties);

            if (inflatedColumns !== undefined) {
                let placeholder = getStringProperty(screen.searchPlaceholder) ?? "";
                if (placeholder === "") {
                    placeholder = getLocalizedString("search", AppKind.App);
                }

                search = { placeholder, inflatedColumns };
            }
        }

        const dynamicFilter = utils.inflateDynamicFilter(ib, screen, { viaQueries: false });

        let pivot: InflatedPivot | undefined;
        const decomposedPivot = decomposeFavoritesPivot(screen.pivots);
        if (decomposedPivot !== undefined) {
            pivot = {
                favoritesLabel: decomposedPivot.favoritesLabel ?? getLocalizedString("favorites", AppKind.App),
            };
        }

        const specialComponentHydrators = this.makeArrayScreenSpecialComponents(
            ib,
            screen,
            table,
            search,
            pivot,
            dynamicFilter,
            sortColumns
        );

        inflatedScreen = {
            table,
            contentHydratorConstructor,
            specialComponentHydrators,
            getContextTable: (hydrationContext, oldSubscriptionInfo) => {
                if (oldSubscriptionInfo !== undefined) {
                    this.callbacks.retireSubscriptionInfo(oldSubscriptionInfo);
                }

                if (screen.fetchesData === true) {
                    // This handles the static filter and returns the sort
                    // transformer.
                    const result = this.fetchScreenData(screenName, screen, hydrationContext, false);
                    if (result === undefined) return undefined;

                    const [screenContext, subscriptionInfo, rows, sortTransform, limitTransform] = result;
                    return [rows, subscriptionInfo, sortTransform, limitTransform, screenContext];
                } else {
                    const result = this.fetchSpecificInputRows(screenName, screen, hydrationContext, true);
                    if (result === undefined) return undefined;
                    const { table: tableData, subscriptionInfo, sortTransform, limitTransform } = result;

                    if (isLoadingValue(tableData)) {
                        return [new Table(), subscriptionInfo, sortTransform, limitTransform, tableData];
                    }

                    return [
                        tableData,
                        subscriptionInfo,
                        sortTransform,
                        limitTransform,
                        makeHydratedScreenContext(tableData.asArray(), undefined, undefined),
                    ];
                }
            },
            applyDynamicTransforms: (thb, rows, sortTransform, limitTransform) => {
                const ttvp = thb.makeTableTransformValueProvider(getTableName(table));

                let filterResult: DynamicFilterResult | undefined;
                if (dynamicFilter !== undefined) {
                    filterResult = utils.applyDynamicFilter(
                        thb,
                        ttvp,
                        dynamicFilter,
                        rows,
                        utils.getDynamicFilterState(thb, false),
                        AppKind.App,
                        false,
                        undefined
                    );
                    rows = filterResult.table;
                    thb = thb.withTable(rows);
                }

                // TODO: Don't sort here if a dynamic sort is active
                if (sortTransform !== undefined) {
                    rows = sortTransform(ttvp, rows);
                    thb = thb.withTable(rows);
                }

                let searchActive = false;
                if (search !== undefined) {
                    const [, searchString] = utils.getScreenSearchState(thb);
                    if (searchString !== "") {
                        searchActive = true;
                        rows = utils.searchRows(searchString, rows, search.inflatedColumns, ttvp, undefined);
                        thb = thb.withTable(rows);
                    }
                }

                rows = limitTransform(ttvp, rows);
                thb = thb.withTable(rows);

                if (pivot !== undefined) {
                    const pivotEditable = utils.getPivotState(thb);
                    const onlyFavorites = pivotEditable.value === 1;

                    if (onlyFavorites) {
                        rows = new Table(
                            Array.from(rows.values()).filter(r => {
                                const v = getRowColumn(r, isFavoritedColumnName);
                                if (isLoadingValue(v)) return false;
                                return areValuesEqual(v, true);
                            }),
                            rows
                        );
                        thb = thb.withTable(rows);
                    }
                }

                if (sortColumns.length > 0) {
                    const [sortColumn, sortAscending] = utils.getSortScreenStates(thb);
                    const getter = sortColumnGetters.get(sortColumn.value);
                    if (getter !== undefined) {
                        rows = utils.sortRows(getter, !sortAscending.value, rows, ttvp);
                        thb = thb.withTable(rows);
                    } else if (!sortAscending.value) {
                        rows = new Table(Array.from(rows.values()).reverse(), rows);
                        thb = thb.withTable(rows);
                    }
                }

                return [thb, searchActive, filterResult];
            },
        };
        this.inflatedArrayScreens.set(screenName, inflatedScreen);
        return inflatedScreen;
    }
}

export function makeScreenInflator(...args: ConstructorParameters<typeof ScreenInflator>): ScreenInflator {
    const [, , { appKind }] = args;
    if (appKind === AppKind.Page) {
        return new PageScreenInflator(...args);
    } else if (appKind === AppKind.App) {
        return new AppScreenInflator(...args);
    } else {
        return assertNever(appKind);
    }
}
