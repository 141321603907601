import type { RuntimeTheme } from "@glide/common";
import type {
    GlobalStyleComponent,
    Interpolation,
    ThemedCssFunction,
    ThemedStyledInterface,
    ThemedStyledProps,
} from "styled-components";
import baseStyled, { css as baseCss, createGlobalStyle as createGlobalStyleUntyped } from "styled-components";

export const styled = baseStyled as ThemedStyledInterface<RuntimeTheme>;
export const css = baseCss as ThemedCssFunction<RuntimeTheme>;

export function createGlobalStyle<P = {}>(
    strings: TemplateStringsArray,
    ...interpolations: Array<Interpolation<ThemedStyledProps<P, RuntimeTheme>>>
): GlobalStyleComponent<P, RuntimeTheme> {
    // sorry for the as any's, TS Server wont shuttup about this one, it's just wrong
    return createGlobalStyleUntyped(strings, ...(interpolations as any)) as any;
}

export const ImagePlaceholder = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.76923 3.69231C5.06989 3.69231 3.69231 5.06989 3.69231 6.76923V41.2308C3.69231 42.6937 4.71332 43.9182 6.08164 44.2306L32.5407 17.7715C33.2617 17.0505 34.4306 17.0505 35.1516 17.7715L44.3077 26.9276V6.76923C44.3077 5.06989 42.9301 3.69231 41.2308 3.69231H6.76923ZM48 31.3828V6.76923C48 3.03069 44.9693 0 41.2308 0H6.76923C3.03069 0 0 3.03069 0 6.76923V41.2308C0 44.9693 3.03069 48 6.76923 48H41.2308C44.9693 48 48 44.9693 48 41.2308V31.3864C48 31.3852 48 31.384 48 31.3828ZM44.3077 32.1493L33.8462 21.6878L11.2262 44.3077H41.2308C42.9301 44.3077 44.3077 42.9301 44.3077 41.2308V32.1493ZM15.3846 13.5385C14.365 13.5385 13.5385 14.365 13.5385 15.3846C13.5385 16.4042 14.365 17.2308 15.3846 17.2308C16.4042 17.2308 17.2308 16.4042 17.2308 15.3846C17.2308 14.365 16.4042 13.5385 15.3846 13.5385ZM9.84615 15.3846C9.84615 12.3258 12.3258 9.84615 15.3846 9.84615C18.4434 9.84615 20.9231 12.3258 20.9231 15.3846C20.9231 18.4434 18.4434 20.9231 15.3846 20.9231C12.3258 20.9231 9.84615 18.4434 9.84615 15.3846Z"
                fill="#E0E0E0"
            />
        </svg>
    );
};

export const disabledProps = baseCss`
    opacity: 0.4;
    pointer-events: none;
`;

export const blurBehindMixin = (props: { backgroundColor: string; fadeTo?: string }) => css`
    @supports (backdrop-filter: blur(10px)) {
        ${props.fadeTo !== undefined &&
        "background: linear-gradient(" + props.fadeTo + ", " + props.backgroundColor + ")"};
        ${props.fadeTo === undefined && "background-color: " + props.backgroundColor};
        backdrop-filter: blur(10px);
    }
`;

export const rippleMixin = css`
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    :after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, #ddd 10%, transparent 10.01%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10, 10);
        opacity: 0;
        transition: transform 0.5s, opacity 1s;
    }

    :active:after {
        transform: scale(0, 0);
        opacity: 0.3;
        transition: 0s;
    }
`;
