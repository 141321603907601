import {
    type ComponentDescription,
    type ComponentKind,
    type PropertyDescription,
    getColumnProperty,
} from "@glide/app-description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import type { WireAppUserProfileComponent } from "@glide/fluent-components/dist/js/base-components";
import type { AppDescriptionContext, ComponentDescriptor } from "@glide/function-utils";
import { PropertySection } from "@glide/function-utils";

import { AppKind } from "@glide/location-common";
import { type Unbound, UnboundVal } from "@glide/computation-model-types";
import {
    type WireInflationBackend,
    type WireRowComponentHydratorConstructor,
    type WireEditableValue,
    WireComponentKind,
} from "@glide/wire";
import { definedMap } from "@glideapps/ts-necessities";

import { allowImageUploadPropertyHandler } from "../user-profile";
import { inflateStringProperty, makeSimpleWireRowComponentHydratorConstructor, spreadComponentID } from "../wire/utils";
import {
    makeSubtitlePropertyDescriptor,
    makeSummaryImagePropertyDescriptor,
    makeTitlePropertyDescriptor,
} from "./descriptor-utils";
import { ComponentHandlerBase } from "./handler";

export const ComponentKindUserProfile: ComponentKind = "user-profile";

export interface UserProfileComponentDescription extends ComponentDescription {
    readonly titleProperty: PropertyDescription | undefined;
    readonly subtitleProperty: PropertyDescription | undefined;
    readonly imageURLProperty: PropertyDescription | undefined;
    readonly allowImageUpload: PropertyDescription | undefined;
}

export class UserProfileComponentHandler extends ComponentHandlerBase<UserProfileComponentDescription> {
    public readonly appKinds = AppKind.App;

    constructor() {
        super(ComponentKindUserProfile);
    }

    public get isGlideManaged(): boolean {
        return true;
    }

    // Describes to glide what the configurator in the builder looks like
    public getDescriptor(desc: UserProfileComponentDescription | undefined): ComponentDescriptor {
        const titleDescriptor = makeTitlePropertyDescriptor(undefined, true, false, PropertySection.Data);
        // FIXME: This should include the case for the special value.
        const subtitleDescriptor = makeSubtitlePropertyDescriptor(undefined, PropertySection.Data);
        const allowUpload = allowImageUploadPropertyHandler.getSwitch(desc);
        const imageDescriptor = makeSummaryImagePropertyDescriptor(
            undefined,
            true,
            allowUpload,
            false,
            PropertySection.Data
        );

        return {
            name: "User Profile",
            helpUrl: getDocURL("userProfile"), // TODO: Change this
            description: "A Test User Profile",
            img: "co-title",
            group: "Text",
            isLegacy: true,
            properties: [
                titleDescriptor,
                subtitleDescriptor,
                imageDescriptor,
                allowImageUploadPropertyHandler,
                ...this.getBasePropertyDescriptors(),
            ],
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: UserProfileComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const { forBuilder } = ib;

        const [nameGetter] = inflateStringProperty(ib, desc.titleProperty, true);
        const [emailGetter] = inflateStringProperty(ib, desc.subtitleProperty, false);
        const [imageGetter] = inflateStringProperty(ib, desc.imageURLProperty, false);

        let imageColumnName: string | undefined;
        if (allowImageUploadPropertyHandler.getSwitch(desc)) {
            imageColumnName = getColumnProperty(desc.imageURLProperty);
        }

        return makeSimpleWireRowComponentHydratorConstructor(hb => {
            let image: WireEditableValue<string> | Unbound | undefined;
            const imageValue = imageGetter(hb);
            if (imageValue !== null) {
                const token = definedMap(imageColumnName, n => hb.registerOnValueChange("image", n));
                if (token !== false) {
                    image = { value: imageValue, onChangeToken: token };
                }
            }
            if (image === undefined) {
                image = UnboundVal;
            }

            const component: WireAppUserProfileComponent = {
                kind: WireComponentKind.AppUserProfile,
                ...spreadComponentID(desc.componentID, forBuilder),
                name: nameGetter(hb),
                email: emailGetter(hb),
                image,
            };

            return {
                component,
                isValid: true,
            };
        });
    }

    public convertToPage(
        desc: UserProfileComponentDescription,
        ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return this.defaultConvertToPage(desc, ccc);
    }
}
