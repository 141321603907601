import type { ArrayContentDescription, ComponentDescription, MutatingScreenKind } from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import type { ActionsRecord, AppDescriptionContext } from "@glide/function-utils";

import type { ArrayContentHandler } from "../array-screens/array-content";
import { type ActionsWithDescriptors, getActionsForDescriptors, getActionsRecord } from "../description-utils";
import type { ComponentHandler } from "./component-handler";

export function getActionsForComponent<T extends ComponentDescription>(
    handler: ComponentHandler<T>,
    desc: T,
    tables: InputOutputTables | undefined,
    adc: AppDescriptionContext | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined
): ActionsRecord {
    const descriptors = handler.getActionDescriptors(desc, tables, adc, mutatingScreenKind);
    return getActionsRecord(desc, descriptors);
}

export function getActionsWithDescriptorsForComponent<T extends ComponentDescription>(
    handler: ComponentHandler<T>,
    desc: T,
    tables: InputOutputTables | undefined,
    adc: AppDescriptionContext | undefined,
    mutatingScreenKind: MutatingScreenKind | undefined
): ActionsWithDescriptors {
    const descriptors = handler.getActionDescriptors(desc, tables, adc, mutatingScreenKind);
    return getActionsForDescriptors(desc, descriptors);
}

export function getActionsForArrayContent<T extends ArrayContentDescription>(
    handler: ArrayContentHandler<T>,
    tables: InputOutputTables | undefined,
    desc: T,
    adc: AppDescriptionContext
): ActionsRecord {
    // NOTE: We pass `undefined` for the indirect table here, assuming
    // that the actions we get aren't dependent on that.
    const descrs = handler.getActionDescriptors(desc, tables, undefined, adc);
    return getActionsRecord(desc, descrs);
}

export function getActionsWithDescriptorsForArrayContent<T extends ArrayContentDescription>(
    handler: ArrayContentHandler<T>,
    tables: InputOutputTables | undefined,
    desc: T,
    adc: AppDescriptionContext
): ActionsWithDescriptors {
    // NOTE: We pass `undefined` for the indirect table here, assuming
    // that the actions we get aren't dependent on that.
    const descrs = handler.getActionDescriptors(desc, tables, undefined, adc);
    return getActionsForDescriptors(desc, descrs);
}
