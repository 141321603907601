import type { ActionNodeInScope, AppDescriptionContext } from "@glide/function-utils";
import {
    getIconForActionNode,
    type ExtendedActionOutputDescriptor,
    type PriorStep,
} from "@glide/generator/dist/js/prior-step";
import { isColumnWritable, isSingleRelationType } from "@glide/type-schema";
import flatten from "lodash/flatten";
import { getColumnsShownForApp } from "@glide/generator/dist/js/favorites";
import type { PreprocessedAction } from "@glide/generator/dist/js/actions/automation-types";
import { getStepTitle } from "./step-titles";
import { ActionNodeKind } from "@glide/app-description";
export function makePriorStepsFromPreviousNodeKeys(
    nodeForKey: ReadonlyMap<string, ActionNodeInScope>,
    adc: AppDescriptionContext,
    previousNodeKeys: readonly string[]
): readonly PriorStep[] {
    const r: PriorStep[] = [];

    for (const key of previousNodeKeys) {
        const node = nodeForKey.get(key);
        if (node === undefined) continue;

        let displayName = getStepTitle(node.node, adc, r);
        if (node.node.kind === ActionNodeKind.AutomationRoot) {
            displayName = node.node.customTitle ?? "Trigger";
        }
        if (displayName === undefined) {
            displayName = node.node.key;
        }

        const outputs: ExtendedActionOutputDescriptor[] = flatten(
            node.outputs.map(o => {
                const res: ExtendedActionOutputDescriptor[] = [
                    {
                        ...o,
                        columnName: undefined,
                        writeable: false,
                        table: undefined,
                        formula: undefined,
                        icon: undefined,
                    },
                ];
                if (!isSingleRelationType(o.type)) return res;
                const table = adc.findTable(o.type);
                if (table === undefined) return res;
                const columnShown = getColumnsShownForApp(adc);
                const tableColumns: ExtendedActionOutputDescriptor[] = table.columns
                    .filter(c => columnShown.isColumnShown(table, c, false))
                    .map(c => {
                        return {
                            displayName: c.displayName ?? c.name,
                            columnName: c.name,
                            writeable: isColumnWritable(c, table, false, undefined),
                            type: c.type,
                            name: o.name,
                            description: undefined,
                            table: table,
                            formula: c.formula,
                            icon: undefined,
                        };
                    });
                return res.concat(tableColumns ?? []);
            })
        );

        r.push({ node, outputs, displayName, icon: getIconForActionNode(node.node) });
    }

    return r;
}

export function makePriorSteps(
    pre: Pick<PreprocessedAction, "nodeForKey">,
    adc: AppDescriptionContext,
    nodeKey: string
): readonly PriorStep[] {
    const n = pre.nodeForKey.get(nodeKey);

    if (n === undefined) return [];

    return makePriorStepsFromPreviousNodeKeys(pre.nodeForKey, adc, n.previousNodeKeys);
}
