import {
    type Icon,
    type ActionDescription,
    type ActionKind,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    getEnumProperty,
    getJSONPathProperty,
    getSecretProperty,
    getSwitchProperty,
    getNumberProperty,
    getSourceColumnProperty,
    getStringProperty,
    getTableProperty,
    isPropertyDescription,
} from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import {
    type TableName,
    type Description,
    type TableColumn,
    getTableColumnDisplayName,
    sheetNameForTable,
    type SchemaInspector,
} from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import {
    type ActionAvailability,
    type ActionsRecord,
    type AppDescriptionContext,
    type EditedColumnsAndTables,
    type InteractiveComponentConfiguratorContext,
    type RewritingComponentConfiguratorContext,
    getColumnForSourceColumn,
} from "@glide/function-utils";
import type { PluginTierList, BillablesConsumed, GlideIconProps as GlideIcon } from "@glide/plugins";
import { PopulationMode } from "../components/description-handlers";
import { getEditedColumnsForProperties } from "../components/descriptor-utils";
import { populateDescription } from "../components/populate-description";
import type {
    WireActionHydrator,
    WireActionInflationBackend,
    WireActionResult,
    WireActionResultBuilder,
} from "@glide/wire";
import { duplicateDescription } from "../handlers";
import type { ActionDescriptor } from "./action-descriptor";
import {
    type ActionColumnAssignments,
    type ActionHandler,
    type DescriptionToken,
    actionAvailabilityBoth,
} from "./action-handler";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import type { StaticActionContext } from "../static-context";
import { getFeatureSetting } from "@glide/common-core";

export abstract class BaseActionHandler<T extends ActionDescription> implements ActionHandler<T> {
    public abstract readonly kind: ActionKind;
    public abstract readonly iconName: GlideIcon | Icon | true | undefined;
    public abstract readonly name: string;

    public getIsApplicable(_screenHasInputContext: boolean, _appHasUserProfiles: boolean): boolean {
        return true;
    }

    public getIsIdempotent(_desc: T): boolean {
        return false;
    }

    public getTier(_appKind: AppKind): PluginTierList | undefined {
        return undefined;
    }

    public getBillablesConsumed(_env: StaticActionContext<AppDescriptionContext>): BillablesConsumed | undefined {
        return undefined;
    }

    public get needsData(): boolean {
        return false;
    }

    public get availability(): ActionAvailability {
        return actionAvailabilityBoth;
    }

    public get handlesSequencing(): boolean {
        return false;
    }

    public get appKinds(): AppKind | "both" {
        if (this.inflate !== undefined) {
            return "both";
        } else {
            return AppKind.App;
        }
    }

    public get canAutoRun(): boolean {
        return false;
    }

    public abstract getDescriptor(
        desc: T | undefined,
        env: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor;

    public getActions(_desc: T): ActionsRecord {
        return { actions: [] };
    }

    public getDescriptionToWalk(desc: T, _ccc: AppDescriptionContext): T {
        return desc;
    }

    public getScreensUsed(_desc: T, _env: StaticActionContext<AppDescriptionContext>): readonly string[] {
        return [];
    }

    public newActionDescription(env: StaticActionContext<InteractiveComponentConfiguratorContext>): T | undefined {
        const desc = { kind: this.kind } as T;
        const { tables, context, mutatingScreenKind } = env;
        return populateDescription(
            d => this.getDescriptor(d, env).properties,
            PopulationMode.Default,
            desc,
            desc,
            tables,
            context,
            false,
            mutatingScreenKind,
            undefined,
            undefined,
            undefined,
            true
        );
    }

    public rewriteAfterReload(
        desc: T,
        tables: InputOutputTables,
        ccc: RewritingComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): T | undefined {
        return populateDescription(
            d => this.getDescriptor(d, { tables, context: ccc, mutatingScreenKind, isAutomation: false }).properties,
            PopulationMode.Rewrite,
            desc,
            desc,
            tables,
            ccc,
            true,
            mutatingScreenKind
        );
    }

    public getAdditionalTablesUsed(_tables: InputOutputTables): readonly TableName[] {
        return [];
    }

    public getEditedColumns(
        desc: T,
        env: StaticActionContext<AppDescriptionContext>
    ): EditedColumnsAndTables | undefined {
        const descr = this.getDescriptor(desc, env);
        return getEditedColumnsForProperties(descr.properties, desc, desc, true, env);
    }

    public getColumnAssignments(
        _desc: T,
        _env: StaticActionContext<AppDescriptionContext>
    ): ActionColumnAssignments | undefined {
        return undefined;
    }

    public async duplicateAction(
        desc: T,
        rootDesc: Description,
        copyTables: InputOutputTables | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<T | undefined> {
        if (copyTables === undefined) return undefined;

        const properties = this.getDescriptor(desc, {
            tables: copyTables,
            context: iccc,
            mutatingScreenKind,
            isAutomation: false,
        })?.properties;

        return await duplicateDescription(
            desc,
            rootDesc,
            properties,
            copyTables,
            iccc,
            mutatingScreenKind,
            screensCreated,
            appFacilities
        );
    }

    public async reuseOrDuplicateAction(
        desc: T,
        rootDesc: Description,
        copyTables: InputOutputTables | undefined,
        iccc: InteractiveComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        screensCreated: Set<string>,
        appFacilities: ActionAppFacilities
    ): Promise<T | undefined> {
        return await this.duplicateAction(
            desc,
            rootDesc,
            copyTables,
            iccc,
            mutatingScreenKind,
            screensCreated,
            appFacilities
        );
    }

    public newDefaultActionDescription(_column: TableColumn): T | undefined {
        return undefined;
    }

    public fixActionDescription(desc: T): T {
        return desc;
    }

    public getTokenizedDescription(
        _desc: T,
        _env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        return undefined;
    }

    public inflate?(
        ib: WireActionInflationBackend,
        desc: T,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult;
}

export function tokenForProperty(
    p: LegacyPropertyDescription | undefined,
    env: StaticActionContext<SchemaInspector>
): DescriptionToken | undefined {
    if (isPropertyDescription(p)) {
        const constant =
            getStringProperty(p) ??
            getNumberProperty(p) ??
            getSwitchProperty(p) ??
            getEnumProperty(p) ??
            getSecretProperty(p) ??
            getJSONPathProperty(p);
        if (constant !== undefined) {
            return {
                kind: "const",
                value: constant.toString(),
            };
        }
    }

    const { context: schema, tables } = env;

    const tableName = getTableProperty(p);
    if (tableName !== undefined) {
        const table = schema.findTable(tableName);
        if (table === undefined) return undefined;
        return {
            kind: "column",
            value: sheetNameForTable(table),
        };
    }

    const sourceColumn = getSourceColumnProperty(p);
    if (sourceColumn === undefined) return undefined;

    const column = getColumnForSourceColumn(
        schema,
        sourceColumn,
        tables?.input,
        undefined,
        env.priorSteps?.map(priorStep => priorStep.node) ?? []
    );
    if (column === undefined) return undefined;

    return {
        kind: "column",
        value: getTableColumnDisplayName(column),
    };
}

// This should be handled by the billing service, but sadly we don't have the infrastructure nor the capacity
// for doing that yet, so instead we're doing it here for now so we keep it centralized.

export function getBillablesConsumedForCRUDActions(
    env: StaticActionContext<AppDescriptionContext>
): BillablesConsumed | undefined {
    if (!getFeatureSetting("crudCountsInAutomations") || !env.isAutomation) return undefined;

    return 1;
}
