import type { ComponentDescription } from "@glide/app-description";
import { ArrayScreenFormat, makeEnumProperty } from "@glide/app-description";
import { GroupingSupport, ListItemFlags } from "@glide/component-utils";
import { WireImageFallback } from "@glide/fluent-components/dist/js/base-components";
import {
    type PropertyDescriptor,
    type PropertyTableGetter,
    PropertySection,
    SwitchPropertyHandler,
} from "@glide/function-utils";
import type { WireTableComponentHydratorConstructor, WireInflationBackend } from "@glide/wire";

import { getActionsForArrayContent } from "../components/component-utils";
import { useFallbackInitialsPropertyHandler } from "../components/descriptor-utils";
import { ListItemSize } from "../components/list-types";
import { type WireStringGetter, inflateActionsWithCanAutoRun } from "../wire/utils";
import type { FixedArrayContent } from "./array-content";
import type { ListArrayContentDescription } from "./list-array-screen";
import {
    type FallbackInitialsSummaryArrayContentDescription,
    type FallbackInitialsSummaryArrayScreenDescription,
    FallbackInitialsSummaryArrayScreenHandler,
    getNumberToTruncateTo,
    inflateSummary,
    isolateFallbackInitialsSummaryArrayContentDescription,
    makeListListHydrator,
} from "./summary-array-screen";

const allowWrappingPropertyHandler = new SwitchPropertyHandler(
    { allowWrapping: false },
    "Allow text wrapping",
    PropertySection.TextStyle
);

type SmallListArrayContentDescription = FallbackInitialsSummaryArrayContentDescription;
type SmallListArrayScreenDescription = FallbackInitialsSummaryArrayScreenDescription;

export class SmallListArrayScreenHandler extends FallbackInitialsSummaryArrayScreenHandler<
    SmallListArrayContentDescription,
    SmallListArrayScreenDescription
> {
    protected readonly supportsNonURLImages = false;
    protected readonly supportsEmojiImages = true;
    protected readonly supportsTruncateList = true;

    constructor() {
        super(ArrayScreenFormat.SmallList, "Compact", "compactList", false);
    }

    public get isLegacy(): boolean {
        return true;
    }

    protected get groupingSupport(): GroupingSupport {
        return GroupingSupport.Regular;
    }

    public getAdditionalPropertyDescriptors<T extends SmallListArrayContentDescription>(
        _getPropertyTable: PropertyTableGetter | undefined,
        _insideInlineList: boolean,
        _desc: T | undefined
    ): ReadonlyArray<PropertyDescriptor> {
        const descrs: PropertyDescriptor[] = [];
        descrs.push(allowWrappingPropertyHandler);

        return descrs;
    }

    public fixContentDescription(gridContent: SmallListArrayContentDescription): FixedArrayContent | undefined {
        return {
            properties: {
                ...isolateFallbackInitialsSummaryArrayContentDescription(gridContent),
                captionProperty: undefined,
                itemSize: makeEnumProperty(ListItemSize.Compact),
            } as ListArrayContentDescription,
            format: ArrayScreenFormat.List,
        };
    }

    // FIXME: support fallback initials
    public inflateContent<T extends SmallListArrayContentDescription>(
        ib: WireInflationBackend,
        desc: T,
        captionGetter: WireStringGetter | undefined,
        containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        const summaryGetters = inflateSummary(ib, desc);

        const iconAndFlags = this.makeIconAndFlagsForDefaultAction(
            desc,
            ListItemFlags.SmallImage | ListItemFlags.DrawSeparator | ListItemFlags.HorizontalLayout,
            ib.adc
        );

        let flags = iconAndFlags.flags | ListItemFlags.DisableLongPress;

        const allowWrapping = allowWrappingPropertyHandler.getSwitch(desc);
        const useFallbackInitials = useFallbackInitialsPropertyHandler.getSwitch(desc);

        if (allowWrapping) {
            flags |= ListItemFlags.WrapText;
        }

        const numToTruncate = getNumberToTruncateTo(desc, containingRowIB !== undefined, 1, this.groupingSupport);

        const { actionHydrator, canAutoRunAction } = inflateActionsWithCanAutoRun(
            ib,
            getActionsForArrayContent(this, ib.tables, desc, ib.adc).actions
        );

        return makeListListHydrator(
            ib,
            ArrayScreenFormat.SmallList,
            componentID,
            flags,
            allowWrapping,
            useFallbackInitials
                ? this.fullTitleFallback
                    ? WireImageFallback.Title
                    : WireImageFallback.Initials
                : WireImageFallback.None,
            captionGetter,
            summaryGetters,
            this.makeGroupByGetters(ib, desc),
            numToTruncate,
            actionHydrator,
            canAutoRunAction,
            iconAndFlags.icon,
            undefined
        );
    }

    public convertArrayScreenToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }

    public convertInlineToPage(): ComponentDescription | undefined {
        return this.defaultArrayContentConvertToPage();
    }
}
