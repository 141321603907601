import type { DocumentData } from "@glide/common-core/dist/js/Database";
import { nativeTableRowDeletedColumnName, nativeTableRowIDColumnName, rowIndexColumnName } from "@glide/type-schema";
import type { ColumnValues } from "@glide/common-core/dist/js/firebase-function-types";
import { GlideDateTime } from "@glide/data-types";
import type { TableSpec } from "@glide/generator/dist/js/__tests__/schema-support";
import { nativeTableIndexer } from "@glide/support";
import { assert, defined } from "@glideapps/ts-necessities";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import isString from "lodash/isString";
import sample from "lodash/sample";

export class ActionAggregator {
    // rowID -> data
    public readonly rows = new Map<string, DocumentData>();

    public addRow(rowID: string, columnValues: DocumentData): void {
        assert(!this.rows.has(rowID));
        this.rows.set(rowID, columnValues);
    }

    public setColumns(rowID: string, columnValues: DocumentData): void {
        const row = defined(this.rows.get(rowID));
        this.rows.set(rowID, { ...row, ...columnValues });
    }

    public deleteRow(rowID: string): void {
        assert(this.rows.has(rowID));
        this.rows.delete(rowID);
    }
}

interface Entry {
    readonly tableSpec: TableSpec;
    count: number;
    nextRowIndex: string;
    numSimulatedErrors: number;
    readonly aggregator: ActionAggregator;
}

export function makeRandomActions(
    testTableSpecs: readonly TableSpec[],
    count: number,
    // Once we've reached this many rows, the probability of adding or
    // deleting rows goes down a lot.
    numTargetRows: number,
    numSimulatedErrors: number,
    // If these return `false` it means they've rejected the action, which
    // means it won't be added to the aggregator.
    registerAddRow: (rowID: string, columnValues: ColumnValues, tableSpec: TableSpec) => ColumnValues | false,
    registerSetColumns: (rowID: string, columnValues: ColumnValues, tableSpec: TableSpec) => boolean,
    registerDeleteRow: (rowID: string, tableSpec: TableSpec) => boolean,
    registerSimulatedError: (tableSpec: TableSpec) => void
): readonly Map<string, DocumentData>[] {
    const possibleValues = [1, 2, 3, "a", "b", "c", false, true, GlideDateTime.now().toDocumentData()];

    function makeColumnValues(tableSpec: TableSpec) {
        const values: ColumnValues = {};
        for (const columnName of tableSpec.columns.filter(isString).filter(n => !n.startsWith("$"))) {
            if (Math.random() < 0.5) {
                values[columnName] = sample(possibleValues);
            }
        }
        return values;
    }

    function makeAddRow(entry: Entry): () => void {
        return () => {
            const rowID = makeRowID();
            const columnValues = {
                ...makeColumnValues(entry.tableSpec),
                [nativeTableRowIDColumnName]: rowID,
                [nativeTableRowDeletedColumnName]: undefined,
            };
            const addedColumnValues = registerAddRow(rowID, columnValues, entry.tableSpec);
            if (addedColumnValues === false) return;
            entry.aggregator.addRow(rowID, {
                [rowIndexColumnName]: entry.nextRowIndex,
                ...addedColumnValues,
            });
            entry.nextRowIndex = nativeTableIndexer.nextNumber(entry.nextRowIndex);
        };
    }

    function makeSetColumns(entry: Entry): (() => void) | undefined {
        const rowID = sample(Array.from(entry.aggregator.rows.keys()));
        if (rowID === undefined) return undefined;
        return () => {
            const columnValues = makeColumnValues(entry.tableSpec);
            if (registerSetColumns(rowID, columnValues, entry.tableSpec)) {
                entry.aggregator.setColumns(rowID, columnValues);
            }
        };
    }

    function makeDeleteRow(entry: Entry): (() => void) | undefined {
        const rowID = sample(Array.from(entry.aggregator.rows.keys()));
        if (rowID === undefined) return undefined;
        return () => {
            if (registerDeleteRow(rowID, entry.tableSpec)) {
                entry.aggregator.deleteRow(rowID);
            }
        };
    }

    const entries = testTableSpecs.map(tableSpec => ({
        tableSpec,
        count,
        nextRowIndex: nativeTableIndexer.zero,
        numSimulatedErrors,
        aggregator: new ActionAggregator(),
    }));

    while (entries.some(e => e.count > 0)) {
        const entry = defined(sample(entries.filter(e => e.count > 0)));

        if (Math.random() < entry.numSimulatedErrors / entry.count) {
            entry.numSimulatedErrors--;
            registerSimulatedError(entry.tableSpec);
        } else if (entry.aggregator.rows.size === 0) {
            makeAddRow(entry)();
        } else if (entry.aggregator.rows.size < numTargetRows) {
            const r = Math.random();
            if (r < 0.5) {
                makeAddRow(entry)();
            } else if (r < 0.8) {
                defined(makeSetColumns(entry))();
            } else {
                defined(makeDeleteRow(entry))();
            }
        } else {
            const r = Math.random();
            if (r < 0.6) {
                defined(makeSetColumns(entry))();
            } else if (r < 0.8) {
                makeAddRow(entry)();
            } else {
                defined(makeDeleteRow(entry))();
            }
        }
        entry.count--;
    }

    return entries.map(e => e.aggregator.rows);
}
