import { truthify } from "@glide/support";

import { findCurrency } from "../components/buy-button-defaults";
import type { Pricing } from "../Database/pricing";

// DEDUP THIS IMPORT WITH THE APP ONE
export function formatStripePrice(priceInformation: Pricing | number) {
    const price = typeof priceInformation === "number" ? priceInformation : priceInformation.value;
    const currency = typeof priceInformation === "number" ? "USD" : priceInformation.currency;
    const centsAmount = price % 100;
    const dollarAmount = Math.round((price - centsAmount) / 100);

    const localeString = currency === "INR" ? "en-IN" : "en-US";
    // FIXME: Maybe findCurrency shouldn't be in buy-button-defaults
    const currencyInfo = findCurrency(currency.toLowerCase());
    const currencyString = currencyInfo !== undefined ? currency : "USD";
    const formatted = truthify(currencyInfo?.isZeroDecimal)
        ? price
        : Number.parseFloat(`${dollarAmount}.${centsAmount}`);

    return formatted.toLocaleString(localeString, {
        style: "currency",
        currency: currencyString,
    });
}
