import { styled } from "@glide/common";
import type { LineDef } from "./prompt-conversation";

const OUTER_PADDING = 0;
const RIGHT_GUTTER = 32;

export const Container = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    word-break: break-word;
`;

export const Lines = styled.div`
    overflow-y: auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
    height: calc(100%);
    padding: 12px ${OUTER_PADDING}px 0 ${OUTER_PADDING}px;
`;

export const Line = styled.div`
    position: relative;
    user-select: text !important;
`;

export const LineContent = styled.span<{ isWorking?: boolean; type: LineDef["type"] }>`
    display: inline-block;
    font-size: 13px;
    line-height: 1.3;
    background: ${p => (p.type === "text" ? p.theme.n200A : "transparent")};
    color: ${p => p.theme.textDark};
    opacity: ${p => (p.isWorking ? 0.5 : 1)};
    transition: opacity 0.2s ease-in-out;
    border-radius: 12px;
    padding: 8px 10px;
    margin-right: ${RIGHT_GUTTER}px;
    overflow: hidden;
    white-space: pre;
    text-wrap: balance;
`;

export const LineActions = styled.div`
    width: ${RIGHT_GUTTER}px;
    height: ${RIGHT_GUTTER}px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RemoveButton = styled.button`
    border-radius: 50%;
    background: ${p => p.theme.bgColorLight};
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -50%);
    border: 1px solid ${p => p.theme.aqua400};

    display: flex;
    justify-content: center;
    align-items: center;
`;

const CHANGES_HEIGHT = 40;

export const InputContainer = styled.div<{ hasChanges?: boolean }>`
    flex: 0;
    position: relative;
    padding-top: ${p => (p.hasChanges ? CHANGES_HEIGHT : 0)}px;
    margin-top: 8px;
`;

export const HasChanges = styled.div`
    background: ${p => p.theme.n0};
    border: 1px solid ${p => p.theme.aqua400};
    padding: 8px;
    border-radius: 9px;
    border-width: 1px 1px 0 1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${CHANGES_HEIGHT}px;
    z-index: 100;

    display: flex;
    gap: 2px;

    &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 8px;
        right: 8px;
        height: 1px;
        border-bottom: 1px solid ${p => p.theme.borderPale};
    }
`;

export const ChangeChiclet = styled.div`
    border-radius: 6px;
    padding: 0 0 0 6px;
    background: ${p => p.theme.aqua200};
    font-size: 13px;
    font-weight: 500;
    color: ${p => p.theme.textDark};
    line-height: 24px;
    height: 24px;
    display: flex;
    align-items: center;
`;
