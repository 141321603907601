import type { UIAlignment } from "@glide/wire";
import { UIBackgroundStyle } from "@glide/wire";
import chroma from "chroma-js";
import classNames from "classnames";
import * as React from "react";
import tw from "twin.macro";

import { getInnerLayersTransition, useCurrentTransition } from "../../chrome-common";
import { css } from "@glide/common-components";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import type { WireAppTheme } from "@glide/theme";
import {
    defaultWireAppAccentContextOverlay,
    defaultWireAppAccentContextOverlayUndo,
    defaultWireAppImageDarkenContextOverlay,
    mergeTheme,
} from "@glide/theme";
import { TailwindThemeProvider } from "@glide/common";

export enum WireContainerSpacing {
    collapsed = "collapsed",
    xsmall = "xsmall",
    small = "small",
    normal = "normal",
}

interface Props extends React.PropsWithChildren {
    background?: UIBackgroundStyle;
    backgroundElement?: React.ReactNode;
    className?: string;
    ignoreHighlight?: boolean;
    isInMultipleColumnLayout?: boolean;
    itemsAlignment?: UIAlignment | undefined;
    spacing?: WireContainerSpacing;
    customCssClassName?: string;
    expand?: boolean;
    dontAnimate?: boolean;
    allowFloatingOutside?: boolean;
    fullWidthBleed?: boolean;
    style?: React.CSSProperties;
}

export function getContainerBackgroundColor(
    theme: WireAppTheme,
    backgroundStyle: UIBackgroundStyle,
    darken: boolean,
    lighten: boolean
): string | undefined {
    if (backgroundStyle === UIBackgroundStyle.Dark) {
        return theme.darkAccent;
    }

    if (backgroundStyle === UIBackgroundStyle.Image) {
        if (darken) {
            return theme.darkAccent;
        }

        if (lighten) {
            return theme.bgContainerHighlight;
        }

        return theme.n200;
    }

    if (backgroundStyle === UIBackgroundStyle.DarkTranslucent) {
        return chroma(theme.n800).alpha(0.9).css();
    }

    if (backgroundStyle === UIBackgroundStyle.Highlight) {
        return theme.bgContainerHighlight;
    }

    if (backgroundStyle === UIBackgroundStyle.HighlightTranslucent) {
        return chroma(theme.bgContainerHighlight).alpha(0.9).css();
    }

    if (backgroundStyle === UIBackgroundStyle.Accent) {
        return theme.accent;
    }

    if (backgroundStyle === UIBackgroundStyle.AccentTranslucent) {
        return chroma(theme.accent).alpha(0.9).css();
    }

    if (backgroundStyle === UIBackgroundStyle.White) {
        return theme.bgMiddle;
    }

    return undefined;
}

export const WireContainer = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((p, ref) => {
    const {
        children,
        className,
        ignoreHighlight,
        backgroundElement,
        isInMultipleColumnLayout,
        itemsAlignment,
        spacing = WireContainerSpacing.normal,
        customCssClassName,
        expand,
        dontAnimate,
        allowFloatingOutside,
        fullWidthBleed,
        style,
    } = p;

    let { background = UIBackgroundStyle.None } = p;

    if (background === UIBackgroundStyle.AccentLegacy) background = UIBackgroundStyle.Accent;

    let theme = useWireAppTheme();

    const pageTransition = useCurrentTransition();
    const componentTransition = dontAnimate ? undefined : getInnerLayersTransition(pageTransition);

    let content = children;
    if (background === UIBackgroundStyle.Card) {
        theme = mergeTheme(theme, [defaultWireAppAccentContextOverlayUndo]);
        content = (
            <div tw="px-6 rounded-lg shadow-md bg-bg-front gp-xl:px-8">
                <TailwindThemeProvider theme={theme}>{content}</TailwindThemeProvider>
            </div>
        );
    } else if (background === UIBackgroundStyle.Image) {
        theme = mergeTheme(theme, [defaultWireAppImageDarkenContextOverlay]);
        content = <TailwindThemeProvider theme={theme}>{content}</TailwindThemeProvider>;
    } else if (
        background === UIBackgroundStyle.Accent ||
        background === UIBackgroundStyle.AccentTranslucent ||
        background === UIBackgroundStyle.Transparent ||
        background === UIBackgroundStyle.Dark ||
        background === UIBackgroundStyle.DarkTranslucent ||
        background === UIBackgroundStyle.TransparentDark
    ) {
        theme = mergeTheme(theme, [defaultWireAppAccentContextOverlay]);
        content = <TailwindThemeProvider theme={theme}>{content}</TailwindThemeProvider>;
    } else if (
        background === UIBackgroundStyle.Highlight ||
        background === UIBackgroundStyle.HighlightTranslucent ||
        background === UIBackgroundStyle.TransparentLight ||
        background === UIBackgroundStyle.White
    ) {
        theme = mergeTheme(theme, [defaultWireAppAccentContextOverlayUndo]);
        content = <TailwindThemeProvider theme={theme}>{content}</TailwindThemeProvider>;
    }

    const isFullBleed = background !== UIBackgroundStyle.None && background !== UIBackgroundStyle.Card;

    const fullClassName = classNames(
        componentTransition,
        "section-wrap",
        ignoreHighlight !== true && "reactive",
        isFullBleed && "full-bleed",
        background === UIBackgroundStyle.Image && "blur",
        `style-${background.toLowerCase()}`,
        `spacing-${spacing}`,
        className,
        customCssClassName,
        expand === true && "expanded",
        background,
        !allowFloatingOutside && "use-relative"
    );

    // These two come from "background effect" (see WireComponentStack)
    // I don't even know if they're still used, but okay.
    const shouldDarken = fullClassName.split(" ").includes("darken");
    const shouldLighten = fullClassName.split(" ").includes("lighten");

    const currentBackgroundColor = useContainerBackground();
    const backgroundColor = getContainerBackgroundColor(theme, background, shouldDarken, shouldLighten);

    return (
        <SectionStyleProvider value={background}>
            <ContainerBackgroundProvider value={currentBackgroundColor ?? backgroundColor}>
                <div
                    style={
                        {
                            "--container-bg-color": backgroundColor,
                            ...style,
                        } as React.CSSProperties
                    }
                    ref={ref}
                    data-testid={`wire-container${customCssClassName ? `-${customCssClassName}` : ""}`}
                    className={fullClassName}
                    tw="isolate w-full flex transition duration-100 justify-center mt-4 [--text-size-base: 16px] page-md:[--text-size-base: 14px] bg-[var(--container-bg-color)] "
                    css={css`
                        &.style-dark-translucent {
                            backdrop-filter: blur(12px);
                        }

                        &.style-highlight-translucent {
                            backdrop-filter: blur(12px);
                        }

                        &.style-accent-translucent {
                            backdrop-filter: blur(12px);
                        }

                        &:first-child.full-bleed,
                        .full-bleed + &.full-bleed {
                            margin-top: 0;
                        }

                        &.full-bleed:last-child {
                            padding-bottom: 0;
                        }

                        &.reactive {
                            opacity: var(--component-highlight-opacity);
                        }
                        &.use-relative {
                            ${tw`relative`}
                        }
                        &.expanded {
                            ${tw`h-full`}
                        }

                        &.code-scanner-container {
                            ${tw`h-full gp-md:h-auto`}
                        }

                        .spacing-${WireContainerSpacing.collapsed} + & {
                            ${tw`mt-0`}
                        }

                        .spacing-${WireContainerSpacing.xsmall} + & {
                            ${tw`mt-2`}
                        }

                        .spacing-${WireContainerSpacing.small} + & {
                            ${tw`mt-4`}
                        }

                        .spacing-${WireContainerSpacing.normal} + & {
                            ${tw`mt-4 page-sm:mt-5 page-md:mt-6`}
                        }
                    `}>
                    {backgroundElement}
                    <div
                        className={classNames(
                            "section-container",
                            isInMultipleColumnLayout === true ? "multi-column" : "single-column",
                            itemsAlignment ? `align-${itemsAlignment}` : "justify-center",
                            fullWidthBleed && "width-bleed-no-padding"
                        )}
                        // These classes are manually mirrored in wire-custom to
                        // allow embedded custom components to control their own
                        // container (e.g. draw shadows) but also match Glide's
                        // container layouts. Please update those as well.
                        tw="z-wire-container w-full relative flex flex-col 
                        gp-2xl:([max-width:1260px])"
                        css={css`
                            &.multi-column {
                                ${tw`px-0`}
                            }

                            &.single-column {
                                ${tw`[--container-x-pad: 16px] px-[var(--container-x-pad)]
                              gp-sm:([--container-x-pad: 20px])
                              gp-md:([--container-x-pad: 24px])
                              gp-lg:([--container-x-pad: 28px])
                              gp-xl:([--container-x-pad: 32px])`}
                            }

                            &.width-bleed-no-padding {
                                ${tw`px-0 py-0 gp-sm:px-0`}
                            }

                            .max-size-lg & {
                                max-width: 768px;
                            }

                            .max-size-xl & {
                                max-width: 1024px;
                            }

                            &.align-start {
                                justify-content: start;
                            }
                            &.align-center {
                                justify-content: center;
                                div {
                                    margin: 0 auto;
                                }
                            }
                            &.align-end {
                                justify-content: end;
                                div {
                                    margin-left: auto;
                                }
                            }
                        `}>
                        {content}
                    </div>
                </div>
            </ContainerBackgroundProvider>
        </SectionStyleProvider>
    );
});

const SectionStyleContext = React.createContext<UIBackgroundStyle>(UIBackgroundStyle.None);

export function useSectionStyle() {
    return React.useContext(SectionStyleContext);
}

export const SectionStyleProvider: React.FC<React.PropsWithChildren<{ value: UIBackgroundStyle }>> = p => {
    let { value } = p;
    const parent = useSectionStyle();
    if (value === UIBackgroundStyle.None) {
        value = parent;
    }
    return <SectionStyleContext.Provider value={value}>{p.children}</SectionStyleContext.Provider>;
};

const ContainerBackgroundContext = React.createContext<string | undefined>(undefined);

export function useContainerBackground() {
    return React.useContext(ContainerBackgroundContext);
}

export const ContainerBackgroundProvider: React.FC<React.PropsWithChildren<{ value: string | undefined }>> = p => {
    return <ContainerBackgroundContext.Provider value={p.value}>{p.children}</ContainerBackgroundContext.Provider>;
};
