import { type ActionDescription, type PropertyDescription, ActionKind } from "@glide/app-description";
import { Sound } from "@glide/common-core/dist/js/sound";
import { EnumPropertyHandler, PropertySection, type ActionAvailability } from "@glide/function-utils";
import type {
    WireActionResult,
    WireActionResultBuilder,
    WireActionHydrator,
    WireActionInflationBackend,
} from "@glide/wire";
import { type ActionDescriptor, ActionGroup } from "./action-descriptor";
import { actionAvailabilityApps } from "./action-handler";
import { BaseActionHandler } from "./base";
import type { GlideIconProps } from "@glide/plugins-codecs";

interface PlaySoundActionDescription extends ActionDescription {
    readonly sound: PropertyDescription;
}

const labelForSound: Record<Sound, string> = {
    [Sound.Alert]: "Alert",
    [Sound.Blip]: "Blip",
    [Sound.CalmThought]: "Calm Thought",
    [Sound.CloseEncounters]: "Close Encounters",
    [Sound.Hint]: "Hint",
    [Sound.NewThought]: "New Thought",
    [Sound.Ping]: "Ping",
    [Sound.Sonar]: "Sonar",
    [Sound.Pop]: "Pop",
};

const soundPropertyHandler = new EnumPropertyHandler(
    { sound: Sound.Alert },
    "Sound",
    "Sound",
    Object.values(Sound).map(s => ({ label: labelForSound[s], value: s })),
    PropertySection.Data,
    "dropdown"
);

export class PlaySoundHandler extends BaseActionHandler<PlaySoundActionDescription> {
    public readonly kind = ActionKind.PlaySound;
    public readonly iconName: GlideIconProps = {
        icon: "st-play-sound",
        kind: "stroke",
        strokeFgColor: "var(--gv-icon-pale)",
    };

    public readonly name: string = "Play sound";

    public get availability(): ActionAvailability {
        return actionAvailabilityApps;
    }

    public getDescriptor(): ActionDescriptor {
        return {
            name: this.name,
            group: ActionGroup.Interaction,
            groupItemOrder: 13,
            needsScreenContext: false,
            properties: [soundPropertyHandler],
        };
    }

    public inflate(
        _ib: WireActionInflationBackend,
        desc: PlaySoundActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const sound = soundPropertyHandler.getEnum(desc);

        return () => {
            return async ab => {
                ab.actionCallbacks.playSound(sound);
                return arb.success();
            };
        };
    }
}
