import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { GlideIcon } from "@glide/common";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import type { LabelAndImageForChosenItems, WireChoiceItem } from "@glide/fluent-components/dist/js/base-components";
import { APP_MODAL_ROOT } from "@glide/wire";
import classNames from "classnames";
import React from "react";
import { useLayer } from "react-laag";
import tw from "twin.macro";

import { Img } from "../../components/img/img";
import { ImageAlternate } from "../../components/shared";
import { css } from "@glide/common-components";
import { useFieldStyles } from "../../utils/use-field-styles";

export type ChoiceItem = Omit<WireChoiceItem, "onChange"> & { onChange?: () => void };

interface DropdownChoiceProps {
    readonly items: readonly ChoiceItem[] | undefined;
    readonly labelAndImageForChosenItems: LabelAndImageForChosenItems;
    readonly isMulti?: boolean;
    readonly headerNode?: React.ReactNode;
    readonly hasImages?: boolean;
    readonly maxChoices?: number;
    readonly className?: string;
    readonly targetId?: string;
    readonly limit?: number;
    readonly onOpen?: () => void;
    readonly appID: string;
}

export const WireDropdown: React.VFC<DropdownChoiceProps> = p => {
    const {
        items,
        labelAndImageForChosenItems,
        isMulti = false,
        maxChoices,
        headerNode,
        hasImages = false,
        targetId,
        limit,
        onOpen,
        appID,
        ...rest
    } = p;

    const fieldStyles = useFieldStyles();
    const { pageBackground } = useWireAppTheme();
    const [isOpen, setIsOpen] = React.useState(false);
    const listboxId = React.useId();
    const [activeIndex, setActiveIndex] = React.useState<number>(-1);
    const listboxRef = React.useRef<HTMLUListElement>(null);
    const searchRef = React.useRef<HTMLDivElement>(null);

    const { renderLayer, layerProps, triggerProps, triggerBounds } = useLayer({
        isOpen,
        possiblePlacements: ["bottom-center", "top-center"],
        placement: "bottom-center",
        auto: true,
        onOutsideClick: () => setIsOpen(false),
        triggerOffset: 4,
        container: targetId ?? APP_MODAL_ROOT,
    });

    const selectedItems = items?.filter(i => i.isSelected);
    const selectedOptions = selectedItems?.length ?? 0;
    const canSelectMore = maxChoices === undefined || selectedOptions < maxChoices;
    const hasSelection = selectedOptions > 0;

    const { label: selectedValueText, image: singleSelectedImage } = labelAndImageForChosenItems;

    const shouldLimit = items !== undefined && limit !== undefined && items.length > limit;
    const limitedItems = shouldLimit ? items.slice(0, limit) : items;

    React.useEffect(() => {
        if (isOpen) {
            // Focus the search input if it exists, otherwise focus the listbox
            if (searchRef.current?.querySelector("input")) {
                searchRef.current.querySelector("input")?.focus();
            } else {
                listboxRef.current?.focus();
                if (limitedItems?.length) {
                    setActiveIndex(0);
                }
            }
        } else {
            setActiveIndex(-1);
        }
    }, [isOpen, limitedItems?.length]);

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent) => {
            if (!isOpen || !limitedItems) return;

            switch (e.key) {
                case "ArrowDown":
                    e.preventDefault();
                    setActiveIndex(prev => {
                        const next = prev < limitedItems.length - 1 ? prev + 1 : prev;
                        const element = listboxRef.current?.children[next] as HTMLElement;
                        element?.scrollIntoView({ block: "nearest" });
                        return next;
                    });
                    break;
                case "ArrowUp":
                    e.preventDefault();
                    setActiveIndex(prev => {
                        const next = prev > 0 ? prev - 1 : prev;
                        const element = listboxRef.current?.children[next] as HTMLElement;
                        element?.scrollIntoView({ block: "nearest" });
                        return next;
                    });
                    break;
                case "Enter":
                case " ":
                    e.preventDefault();
                    if (activeIndex >= 0 && activeIndex < limitedItems.length) {
                        if (!isMulti) {
                            setIsOpen(false);
                        }
                        limitedItems[activeIndex].onChange?.();
                    }
                    break;
                case "Escape":
                    e.preventDefault();
                    setIsOpen(false);
                    break;
                case "Tab":
                    // Allow tabbing between search and listbox, but prevent leaving the dialog
                    if (searchRef.current) {
                        e.preventDefault();
                        const searchInput = searchRef.current.querySelector("input");
                        if (document.activeElement === searchInput) {
                            listboxRef.current?.focus();
                        } else {
                            searchInput?.focus();
                        }
                    }
                    break;
                case "Home":
                    e.preventDefault();
                    if (limitedItems.length > 0) {
                        setActiveIndex(0);
                        const element = listboxRef.current?.firstElementChild as HTMLElement;
                        element?.scrollIntoView({ block: "nearest" });
                    }
                    break;
                case "End":
                    e.preventDefault();
                    if (limitedItems.length > 0) {
                        setActiveIndex(limitedItems.length - 1);
                        const element = listboxRef.current?.lastElementChild as HTMLElement;
                        element?.scrollIntoView({ block: "nearest" });
                    }
                    break;
            }
        },
        [isOpen, limitedItems, activeIndex, isMulti]
    );

    const hasItems = items !== undefined;
    const onClick = React.useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
            if (!hasItems && !isOpen) {
                onOpen?.();
            }
        },
        [hasItems, isOpen, onOpen]
    );

    return (
        <>
            <button
                {...triggerProps}
                {...rest}
                data-testid="wc-trigger"
                css={fieldStyles}
                tw="px-3 flex items-center justify-between h-10"
                onClick={onClick}
                onKeyDown={e => {
                    if (e.key === "ArrowDown" && !isOpen) {
                        e.preventDefault();
                        setIsOpen(true);
                    }
                }}
                data-state={isOpen ? "open" : "closed"}
                data-background={pageBackground}
                role="combobox"
                aria-haspopup="dialog"
                aria-expanded={isOpen}
                aria-controls={listboxId}
                aria-label={`Select ${isMulti ? "options" : "an option"}${
                    selectedValueText ? `: ${selectedValueText} selected` : ""
                }`}>
                {singleSelectedImage !== undefined && (
                    <Img
                        tw="h-6 w-6 mr-2 rounded-md object-cover"
                        src={massageImageUrl(singleSelectedImage, { thumbnail: true }, appID)}
                        alternate={<ImageAlternate />}
                        altBehavior="both"
                        alt={selectedValueText}
                        isPages={true}
                    />
                )}
                <div
                    className={classNames(hasSelection ? "has-selection" : undefined)}
                    css={css`
                        &.has-selection {
                            ${tw`text-text-contextual-dark`}
                        }
                    `}
                    tw="flex-1 text-left text-text-contextual-pale truncate">
                    {selectedValueText}
                </div>
                <div tw="transition-colors text-text-contextual-pale">
                    <GlideIcon tw="shrink-0" kind="stroke" icon="st-caret" iconSize={16} />
                </div>
            </button>
            {isOpen &&
                renderLayer(
                    <div
                        {...layerProps}
                        role="dialog"
                        aria-label="Select options"
                        aria-modal="true"
                        style={{ width: triggerBounds?.width, ...layerProps.style }}
                        tw="bg-bg-front text-text-dark rounded-lg shadow-lg-dark overflow-hidden">
                        <div ref={searchRef}>{headerNode}</div>
                        <ul
                            ref={listboxRef}
                            id={listboxId}
                            role="listbox"
                            aria-multiselectable={isMulti}
                            tabIndex={0}
                            onKeyDown={handleKeyDown}
                            tw="flex flex-col outline-none focus-visible:ring-2 focus-visible:ring-accent [max-height:calc(50vh - 120px)] overflow-y-auto my-2">
                            {limitedItems === undefined ? (
                                <div tw="flex items-center justify-center h-10">
                                    <GlideIcon kind="stroke" icon="st-half-spinner" iconSize={24} spin={true} />
                                </div>
                            ) : (
                                limitedItems.map((i, index) => (
                                    <li
                                        key={index}
                                        role="option"
                                        aria-selected={i.isSelected}
                                        aria-disabled={!i.isSelected && !canSelectMore}
                                        tabIndex={index === activeIndex ? 0 : -1}
                                        data-active={index === activeIndex}
                                        data-testid="wc-item"
                                        className={classNames(
                                            i.isSelected && "selected",
                                            !i.isSelected && !canSelectMore && "disabled",
                                            index === activeIndex && "active"
                                        )}
                                        css={css`
                                            &.selected {
                                                ${tw`font-medium bg-n200A`}
                                            }
                                            &.active {
                                                ${tw`bg-n100A`}
                                            }
                                            &.disabled {
                                                ${tw`hocus:bg-none`}
                                            }
                                            &:not(.disabled) {
                                                ${tw`cursor-pointer hocus:bg-n100A`}
                                            }
                                        `}
                                        tw="w-full text-sm font-normal px-3 py-1.5 not-last:mb-px select-none flex
                                        items-center"
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (!isMulti) {
                                                setIsOpen(false);
                                            }
                                            i.onChange?.();
                                        }}>
                                        {hasImages && (
                                            <Img
                                                tw="h-6 w-6 mr-2 rounded-md object-cover"
                                                src={massageImageUrl(i.image, { thumbnail: true }, appID)}
                                                alternate={<ImageAlternate />}
                                                altBehavior="both"
                                                isPages={true}
                                                alt={i.displayAs ?? undefined}
                                            />
                                        )}
                                        <span tw="grow truncate">{i.displayAs}</span>
                                        {isMulti && i.isSelected && (
                                            <GlideIcon
                                                tw="text-text-accent [min-width:24px]"
                                                kind="stroke"
                                                icon="st-check"
                                                iconSize={20}
                                            />
                                        )}
                                    </li>
                                ))
                            )}
                            {shouldLimit && (
                                <li>
                                    <p tw="text-sm text-text-xpale text-center py-2">Search to find more...</p>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
        </>
    );
};
