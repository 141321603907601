import React from "react";
import { AppKind } from "@glide/location-common";
import { getAppKindFromFeatures } from "@glide/common-core/dist/js/components/SerializedApp";
import { formatLocalizedString, getLocalizedString } from "@glide/localization";
import type { NonUserAppFeatures } from "@glide/app-description";
import { GreetingStyles } from "../custom-sign-in/greeting-styles";
import { signInNormalTextColors } from "../custom-sign-in/custom-sign-in-styles";

import { WireField } from "../../renderers/wire-field/wire-field";
import type { EmailPinAuthenticator } from "@glide/auth-controller-core";
import type { NotificationTarget } from "@glide/plugins";
import { PlayerObservability } from "@glide/player-frontend-observability";
import { Text } from "../text/text";
import "twin.macro";
import { TextComponentStyle } from "@glide/component-utils";
import { marked } from "marked";
import { SignInPrimaryButton, SignInSecondaryButton } from "./sign-in-buttons";
import type { SignInState } from "@glide/common-components";

interface Props extends React.PropsWithChildren {
    readonly appFeatures: NonUserAppFeatures;
    readonly onRequestAnotherPin: () => void;
    readonly sendPinForEmail: EmailPinAuthenticator["sendPinForEmail"];
    readonly userEmail: string;
    readonly pinMethod: NotificationTarget["method"];
    readonly pinTarget: string;
    readonly pinProvider: string;
    readonly onLoggedIn: () => void;
    readonly getPasswordForPin: EmailPinAuthenticator["getPasswordForPin"];
    readonly signinState: SignInState;
    readonly onSigninStateChange: (state: SignInState) => void;
}

interface RequestStatus {
    isSending: boolean;
    error: string | undefined;
    startSending(): void;
    setError(error: string): void;
}

function useRequestStatus(): RequestStatus {
    const [error, setErrorInner] = React.useState<string | undefined>();
    const [isSending, setIsSending] = React.useState(false);

    const startSending = React.useCallback(() => {
        setIsSending(true);
        setErrorInner(undefined);
    }, []);

    const setError = React.useCallback((err: string) => {
        setErrorInner(err);
        setIsSending(false);
    }, []);

    return {
        isSending,
        error,
        startSending,
        setError,
    };
}

const LargeText: React.FC<{ mdText: string }> = p => {
    return (
        <Text
            element="p"
            variant={TextComponentStyle.regular}
            css={signInNormalTextColors}
            tw="mb-6 text-center [&_strong]:whitespace-nowrap" // make sure emails don't get wrapped
            dangerouslySetInnerHTML={{ __html: marked(p.mdText) }}
        />
    );
};

const signInWithEmailMetric = PlayerObservability.makePlayerMetric("sign-in-with-email", ["status", "reason"]);
const senderEmail = "no-reply@auth.appnotify.io";
const docURL = "https://help.glideapps.com/en/articles/10228334-users-don-t-get-the-pin-sign-in-email";

function makeMDBold(text: string): string {
    return `**${text}**`;
}

export const PinStage: React.FC<Props> = p => {
    const {
        userEmail,
        pinTarget,
        pinMethod,
        pinProvider,
        appFeatures,
        onRequestAnotherPin,
        sendPinForEmail,
        onLoggedIn,
        getPasswordForPin,
        signinState,
        onSigninStateChange,
    } = p;

    const [pin, setPin] = React.useState("");
    const { isSending, error, startSending, setError } = useRequestStatus();
    const canContinue = pin.length > 3 && !isSending;

    const onContinue = React.useCallback(async () => {
        startSending();

        const stop = signInWithEmailMetric.start();
        const pw = await getPasswordForPin(userEmail, pin);

        if (pw === undefined) {
            stop({ status: "failed", reason: "wrong pin" });
            setError(getLocalizedString("wrongPasscode", AppKind.Page));
            return;
        }

        stop({ status: "success", reason: "" });
        onLoggedIn();
    }, [getPasswordForPin, onLoggedIn, pin, setError, startSending, userEmail]);

    const onFormSubmit = React.useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();
            await onContinue();
        },
        [onContinue]
    );

    const appKind = getAppKindFromFeatures(appFeatures);

    const composeEmailForIT = React.useCallback(() => {
        const currentURL = window.location.href;
        const subject = getLocalizedString("pinITEmailSubject", appKind);

        let body = formatLocalizedString("pinITEmailBody", [currentURL, senderEmail, docURL], appKind);
        body = body.replace(/\n/g, "\r\n"); // Preserve new lines

        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(body);

        window.open(`mailto:?subject=${encodedSubject}&body=${encodedBody}`);
    }, [appKind]);

    const requestAnotherPin = React.useCallback(() => {
        onRequestAnotherPin();
        onSigninStateChange("sign-in");
    }, [onRequestAnotherPin, onSigninStateChange]);

    return (
        <>
            {signinState === "insert-pin" ? (
                <>
                    <div>
                        <GreetingStyles
                            greeting={getLocalizedString(
                                pinMethod === "sms" ? "checkYourMessages" : "checkYourEmail",
                                appKind
                            )}
                        />

                        <form action="" onSubmit={onFormSubmit}>
                            <WireField
                                isSignInEmail={true}
                                data-test="app-pin-input"
                                title={formatLocalizedString("weHaveSentAPinTo", [pinTarget], appKind)}
                                isEnabled={!isSending}
                                placeholder="00000"
                                value={pin}
                                onChange={setPin}
                                dataType="tel"
                                dataTypePattern="[0-9]*"
                                error={error}
                                autoFocus={true}
                            />
                        </form>
                    </div>
                    <div>
                        <SignInPrimaryButton
                            onClick={onContinue}
                            disabled={!canContinue}
                            label={getLocalizedString("signIn", appKind)}
                        />
                        {pinMethod === "sms" ? (
                            <SignInSecondaryButton
                                onClick={requestAnotherPin}
                                label={getLocalizedString("iNeedAnotherPin", appKind)}
                            />
                        ) : (
                            <SignInSecondaryButton
                                onClick={() => onSigninStateChange("check-spam")}
                                label={getLocalizedString("iDidntGetPinEmail", appKind)}
                            />
                        )}
                    </div>
                </>
            ) : signinState === "check-spam" ? (
                <>
                    <div>
                        <GreetingStyles greeting={getLocalizedString("checkYourSpam", appKind)} />
                        <LargeText
                            mdText={formatLocalizedString("checkYourSpamMessage", [makeMDBold(userEmail)], appKind)}
                        />
                    </div>
                    <div>
                        <SignInPrimaryButton
                            onClick={() => onSigninStateChange("insert-pin")}
                            label={getLocalizedString("iFoundThePin", appKind)}
                        />
                        {pinProvider === "glide" ? (
                            <SignInSecondaryButton
                                onClick={() => {
                                    onSigninStateChange("search-email");
                                    void sendPinForEmail(userEmail);
                                }}
                                label={getLocalizedString("notFoundInSpam", appKind)}
                            />
                        ) : (
                            <SignInSecondaryButton
                                onClick={requestAnotherPin}
                                label={getLocalizedString("iNeedAnotherPin", appKind)}
                            />
                        )}
                    </div>
                </>
            ) : signinState === "search-email" ? (
                <>
                    <div>
                        <GreetingStyles greeting={getLocalizedString("searchAllEmail", appKind)} />
                        <LargeText
                            mdText={formatLocalizedString(
                                "searchAllEmailMessage",
                                [makeMDBold(senderEmail), makeMDBold(userEmail)],
                                appKind
                            )}
                        />
                    </div>
                    <div>
                        <SignInPrimaryButton
                            onClick={() => onSigninStateChange("insert-pin")}
                            label={getLocalizedString("iFoundThePin", appKind)}
                        />
                        <SignInSecondaryButton
                            onClick={() => onSigninStateChange("contact-it")}
                            label={getLocalizedString("notFoundEmailSearch", appKind)}
                        />
                    </div>
                </>
            ) : signinState === "contact-it" ? (
                <>
                    <div>
                        <GreetingStyles greeting={getLocalizedString("contactIT", appKind)} />
                        <LargeText
                            mdText={formatLocalizedString("contactITMessage", [makeMDBold(senderEmail)], appKind)}
                        />
                    </div>
                    <div>
                        <SignInPrimaryButton
                            onClick={composeEmailForIT}
                            label={getLocalizedString("composeEmail", appKind)}
                        />
                        <SignInSecondaryButton
                            onClick={() => onSigninStateChange("insert-pin")}
                            label={getLocalizedString("iFoundThePin", appKind)}
                        />
                    </div>
                </>
            ) : null}
        </>
    );
};
