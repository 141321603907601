import { GlideIcon } from "@glide/common";
import type { GetPluginSecretBody } from "@glide/common-core/dist/js/firebase-function-types";
import { getAppFacilities } from "@glide/common-core/dist/js/support/app-renderer";
import type { ParameterProps } from "@glide/plugins";
import { checkString, isResponseOK, isUndefinedish } from "@glide/support";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { v4 as uuid } from "uuid";
import React from "react";
import { Tooltip } from "@glide/common-components";
import InputLabel from "../../components/input-label/input-label";
import "twin.macro";
import { Button } from "../button/button";
import { setPluginSecret } from "@glide/backend-api";
import { isErrorInfo } from "@glide/error-info";
import { useModals } from "../modal-provider-v2/modal-provider-v2";

function useEnqueueSecretSend(appID: string, secretID: string | undefined, showSecret: boolean) {
    const mounted = React.useRef(false);
    React.useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const queueRef = React.useRef<(() => Promise<void>)[]>([]);

    const queryClient = useQueryClient();
    const queryKey = ["getPluginSecret", appID, secretID, showSecret];
    const {
        data: secretString,
        isLoading: isSecretLoading,
        refetch,
    } = useQuery(
        queryKey,
        async () => {
            if (secretID === undefined || !showSecret) return null;
            const body: GetPluginSecretBody = { appID, secretID, secretKind: "string" };
            const response = await getAppFacilities().callAuthCloudFunction("getPluginSecret", body, {});
            if (!isResponseOK(response)) return null;

            const json = await response.json();
            return checkString(json?.string);
        },
        {}
    );

    const enqueueSecretSend = React.useCallback(async (thunk: () => Promise<void>) => {
        queueRef.current.push(thunk);
        if (queueRef.current.length !== 1) return;

        while (queueRef.current.length > 0) {
            const todo = queueRef.current.shift();
            if (todo !== undefined) {
                await todo();
            }
        }
    }, []);

    return { secretString, isSecretLoading, enqueueSecretSend, queryClient, queryKey, mounted, refetch };
}

type SequencingInputPropsBase = ParameterProps & {
    readonly value: string;

    readonly secretID: string | undefined;
    // These two are used if `secretID` is defined
    readonly secretIsSet: boolean;
    readonly showSecret: boolean;

    readonly appID: string;
    readonly isPristine: boolean;
};

type SequencingInputProps = SequencingInputPropsBase & {
    readonly onChange: (newVal: string) => void;
};

export const SequencingInput: React.VFC<SequencingInputProps> = props => {
    const {
        description,
        name,
        type,
        placeholder,
        value,
        onChange,
        secretID,
        appID,
        secretIsSet,
        showSecret,
        required,
        isPristine,
    } = props;

    const { secretString, isSecretLoading, enqueueSecretSend, queryClient, queryKey, mounted } = useEnqueueSecretSend(
        appID,
        secretID,
        showSecret
    );

    const [focused, setFocused] = React.useState(false);
    const modals = useModals();

    let valueToDisplay: string;
    // `useQuery` might return `undefined`, and we will return `null` if we
    // don't have a secret for some reason.
    if (!isUndefinedish(secretString)) {
        valueToDisplay = secretString;
    } else if (secretID !== undefined && secretIsSet && !focused && value === "") {
        valueToDisplay = "••••••••••";
    } else {
        valueToDisplay = value;
    }

    return (
        <InputLabel
            label={name}
            onFocus={() => setFocused(true)}
            onBlur={() => setTimeout(() => setFocused(false), 500)} // because debounceUpdate === true will write blank values otherwise within this window
            placeholder={placeholder}
            debounceUpdate={true}
            value={isSecretLoading ? "" : valueToDisplay}
            type={type === "secret" && !showSecret ? "password" : undefined}
            helpText={description}
            onChange={async e => {
                await enqueueSecretSend(async () => {
                    const newValue = e.target.value;
                    if (secretID !== undefined) {
                        if (showSecret) {
                            queryClient.setQueryData(queryKey, newValue);
                        }
                        const maybeNewSecretID = await setPluginSecret(appID, secretID, newValue, getAppFacilities());
                        if (!mounted.current) return;
                        if (isErrorInfo(maybeNewSecretID)) {
                            void modals.showErrorModal(1079, maybeNewSecretID.message);
                            return;
                        }

                        onChange(maybeNewSecretID ?? secretID);
                    } else {
                        onChange(newValue);
                    }
                });
            }}
            required={required}
            highlightWarning={required}
            disabled={isSecretLoading}
            warning={required && !secretIsSet && value === "" && !isPristine ? "Required" : undefined}>
            {isSecretLoading && (
                <div tw="absolute h-full top-0 left-0 ml-2 flex items-center opacity-50 text-text-base">
                    <GlideIcon kind="stroke" icon="st-half-spinner" iconSize={24} spin={true} />
                    <span tw="text-builder-sm ml-2">Loading...</span>
                </div>
            )}
        </InputLabel>
    );
};

type UuidPickerInputProps = SequencingInputPropsBase & {
    readonly setSecretID: (overrideSecretID: string | undefined) => void;
    readonly clearSecretID: () => void;
    readonly isRequired: boolean;
};

export const UuidPickerInput: React.FC<UuidPickerInputProps> = props => {
    const { name, description, setSecretID, clearSecretID, secretID, appID, showSecret, isRequired } = props;

    const { secretString, isSecretLoading, enqueueSecretSend, queryClient, queryKey, mounted, refetch } =
        useEnqueueSecretSend(appID, secretID, showSecret);
    const modals = useModals();

    let valueToCopy: string | undefined;
    if (!isUndefinedish(secretString)) {
        valueToCopy = secretString;
    } else {
        valueToCopy = undefined;
    }

    const resetUuid = React.useCallback(
        async (newValue: string, set: typeof setSecretID) => {
            await enqueueSecretSend(async () => {
                if (secretID !== undefined) {
                    if (showSecret) {
                        queryClient.setQueryData(queryKey, newValue);
                    }
                    const maybeNewSecretID = await setPluginSecret(appID, secretID, newValue, getAppFacilities());
                    if (!mounted.current) return;
                    if (isErrorInfo(maybeNewSecretID)) {
                        void modals.showErrorModal(1079, maybeNewSecretID.message);
                    } else {
                        set(maybeNewSecretID);
                    }

                    void refetch();
                    return;
                }
            });
        },
        [enqueueSecretSend, secretID, showSecret, appID, mounted, refetch, queryClient, queryKey, modals]
    );

    const refForTooltip = React.useRef<HTMLDivElement | null>(null);

    let tooltipContent: React.ReactElement | undefined;
    if (description !== undefined) {
        tooltipContent = <div>{description}</div>;
    }

    React.useEffect(() => {
        if (!isRequired) return;
        if (secretID === undefined) return;
        if (isSecretLoading) return;
        if (valueToCopy !== undefined) return;

        setTimeout(() => {
            void resetUuid(uuid(), setSecretID);
        }, 0);
    }, [isRequired, secretID, isSecretLoading, valueToCopy, resetUuid, setSecretID]);

    return (
        <div ref={refForTooltip} tw="flex flex-row items-center gap-1">
            <Button
                tw="grow"
                variant="default"
                buttonType="secondary"
                label={isSecretLoading ? "Loading..." : valueToCopy !== undefined ? `Copy ${name}` : `Generate ${name}`}
                onClick={() => {
                    if (isSecretLoading) return;
                    if (valueToCopy !== undefined) {
                        void navigator.clipboard.writeText(valueToCopy);
                    } else {
                        const newUuid = uuid();
                        void navigator.clipboard.writeText(newUuid);
                        void resetUuid(newUuid, setSecretID);
                    }
                }}
            />
            <Button
                variant="default"
                buttonType="minimal"
                iconType="iconOnly"
                icon="st-reload"
                onClick={() => {
                    const newUuid = uuid();
                    void navigator.clipboard.writeText(newUuid);
                    void resetUuid(newUuid, setSecretID);
                }}
                disabled={valueToCopy === undefined || isSecretLoading}
                label="Regenerate"
            />
            {!isRequired && (
                <Button
                    variant="default"
                    buttonType="minimal"
                    iconType="iconOnly"
                    icon="st-close"
                    onClick={clearSecretID}
                    disabled={valueToCopy === undefined || isSecretLoading}
                    label="Clear"
                />
            )}
            {tooltipContent !== undefined && (
                <Tooltip target={refForTooltip} position="top">
                    {tooltipContent}
                </Tooltip>
            )}
        </div>
    );
};
