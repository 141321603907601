// Cherry picked from https://github.com/gcanti/io-ts-types/blob/master/src/date.ts
import * as t from "io-ts";

function fromRefinement<A>(name: string, is: (u: unknown) => u is A): t.Type<A, A, unknown> {
    return new t.Type(name, is, (u, c) => (is(u) ? t.success(u) : t.failure(u, c)), t.identity);
}

export interface DateC extends t.Type<Date, Date, unknown> {}

const isDate = (u: unknown): u is Date => u instanceof Date;

export const iotsdate: DateC = fromRefinement("Date", isDate);
