import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Extension } from "@tiptap/core";
import type { EditorView } from "@tiptap/pm/view";
import type { Node } from "@tiptap/pm/model";
import { sleep } from "@glideapps/ts-necessities";
import { isDefined } from "@glide/support";

const retryFn = async <T extends Record<string, unknown>>(fn: () => Promise<T>): Promise<T> => {
    const delays = [3000]; // 3 seconds

    for (const delay of delays) {
        try {
            return await fn();
        } catch (err: unknown) {
            await sleep(delay);
        }
    }
    throw new Error("Max retries reached");
};

const findImageNodeByAlt = (doc: Node, schema: any, alt: string): { node: Node; pos: number } | null => {
    let foundNode = null;
    doc.descendants((n, pos) => {
        if (n.type === schema.nodes.image && n.attrs.alt === alt) {
            foundNode = { node: n, pos };
            return false;
        }
        return true;
    });
    return foundNode;
};

const handleImageUpload = async (
    view: EditorView,
    node: Node,
    image: File,
    onUpload: (file: File) => Promise<{ url?: string }>
) => {
    const { schema } = view.state;

    try {
        const result = await retryFn(() => onUpload(image));

        if (isDefined(result.url)) {
            const tr = view.state.tr;
            const updatedNode = node.type.create({
                ...node.attrs,
                src: result.url,
                alt: image.name,
                state: "loaded",
            });

            const imageNode = findImageNodeByAlt(tr.doc, schema, image.lastModified.toString());

            if (isDefined(imageNode) && imageNode.node.attrs.alt === image.lastModified.toString()) {
                tr.setNodeMarkup(imageNode.pos, null, updatedNode.attrs);
                view.dispatch(tr);
            }
        }
    } catch (error: unknown) {
        const tr = view.state.tr;
        const imageNode = findImageNodeByAlt(tr.doc, schema, image.lastModified.toString());

        const updatedNode = node.type.create({
            ...node.attrs,
            src: node.attrs.src,
            alt: image.name,
            state: "error",
        });

        if (isDefined(imageNode) && imageNode.node.attrs.alt === image.lastModified.toString()) {
            tr.setNodeMarkup(imageNode.pos, null, updatedNode.attrs);
            view.dispatch(tr);
        }
    }
};

const processImage = (
    view: EditorView,
    image: File,
    onUpload: (file: File) => Promise<{ url?: string }>,
    singleImage?: boolean
) => {
    const reader = new FileReader();
    reader.onload = async (readerEvent: ProgressEvent<FileReader>) => {
        const { schema } = view.state;
        const node = schema.nodes.image.create({
            src: readerEvent.target?.result as string,
            title: image.name,
            alt: image.lastModified.toString(),
            state: "loading",
        });

        if (singleImage === true) {
            const transaction = view.state.tr.replaceWith(0, view.state.doc.content.size, node);
            view.dispatch(transaction);
            await handleImageUpload(view, node, image, onUpload);
        } else {
            const transaction = view.state.tr.replaceSelectionWith(node);
            view.dispatch(transaction);

            await handleImageUpload(view, node, image, onUpload);
        }
    };
    reader.readAsDataURL(image);
};

interface HandleImageEventParams {
    view: EditorView;
    event: DragEvent | ClipboardEvent | React.ChangeEvent<HTMLInputElement>;
    onUpload: (file: File) => Promise<{ url?: string }>;
    allowedMimeTypes: string[];
    maxFileSize: number;
    singleImage?: boolean;
}

export const handleImageEvent = ({
    view,
    event,
    onUpload,
    allowedMimeTypes,
    maxFileSize,
    singleImage,
}: HandleImageEventParams) => {
    let files: FileList | null = null;

    if (event instanceof DragEvent) {
        files = event.dataTransfer?.files ?? null;
    } else if (event instanceof ClipboardEvent) {
        files = event.clipboardData?.files ?? null;
    } else if (isDefined(event.target) && isDefined(event.target.files)) {
        files = event.target.files;
    }

    if (singleImage === true) {
        files = files?.length === 1 ? files : null;
    }

    const hasFiles = files !== null && files.length > 0;

    if (!hasFiles) {
        return false;
    }

    if (files === null) {
        return;
    }

    const images = Array.from(files).filter(file => allowedMimeTypes.includes(file.type) && file.size <= maxFileSize);

    if (images.length === 0) {
        return false;
    }

    event.preventDefault();

    images.forEach(image => processImage(view, image, onUpload, singleImage));

    return true;
};

export const defaultAllowedMimeTypes = ["image/png", "image/jpg", "image/jpeg", "image/webp"];
const defaultMaxFileSize = 20 * 1024 * 1024; // 20 MB in bytes

export const DropPasteImage = Extension.create<{
    singleImage?: boolean;
    allowedMimeTypes?: string[];
    maxFileSize?: number;
    onUpload: (file: File) => Promise<{ url?: string }>;
}>({
    name: "dropPasteImage",

    addOptions() {
        return {
            singleImage: false,
            allowedMimeTypes: defaultAllowedMimeTypes,
            maxFileSize: defaultMaxFileSize,
            onUpload: () => Promise.resolve({}),
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey("dropPasteImage"),
                props: {
                    handleDOMEvents: {
                        drop: (view: EditorView, event: DragEvent) => {
                            return handleImageEvent({
                                view,
                                event,
                                onUpload: this.options.onUpload,
                                singleImage: this.options.singleImage,
                                allowedMimeTypes: this.options.allowedMimeTypes ?? defaultAllowedMimeTypes,
                                maxFileSize: this.options.maxFileSize ?? defaultMaxFileSize,
                            });
                        },
                        paste: (view: EditorView, event: ClipboardEvent) => {
                            return handleImageEvent({
                                view,
                                event,
                                onUpload: this.options.onUpload,
                                singleImage: this.options.singleImage,
                                allowedMimeTypes: this.options.allowedMimeTypes ?? defaultAllowedMimeTypes,
                                maxFileSize: this.options.maxFileSize ?? defaultMaxFileSize,
                            });
                        },
                    },
                },
            }),
        ];
    },
});
