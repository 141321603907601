import { useRouteMatch } from "react-router-dom";

import type { AppPatternRouteProps } from "./routes";
import { appPattern } from "./routes";

export function useAppID(): string | undefined {
    // We tend to have "appID" in the player, because it's set for us
    // by the play function. It's not set in the builder. If it's here,
    // use it, as the player deals with one appID exclusively.
    if ("appID" in window) {
        return (window as any).appID;
    }
    // Here we're safe ignoring the ESLint rule cause we're not _really_ conditionally calling this hook.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useAppIDFromRoute();
}

const useAppIDFromRoute = () => {
    const match = useRouteMatch<AppPatternRouteProps>(appPattern);
    return match?.params.id;
};
