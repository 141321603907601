import type { MinimalAppEnvironment, QuotaBannerRequirements } from "@glide/common-core/dist/js/components/types";
import type {
    ArrayTransform,
    ArrayContentDescription,
    ArrayScreenFormat,
    MutatingScreenKind,
    PropertyDescription,
    TransformableContentDescription,
    ComponentDescription,
    ArrayScreenDescription,
    ArrayPivot,
} from "@glide/app-description";
import {
    ActionKind,
    ArrayTransformKind,
    PropertyKind,
    getColumnProperty,
    getFilterProperty,
    getSourceColumnProperty,
    makeArrayProperty,
    makeFilterProperty,
    makeSourceColumnProperty,
    makeStringProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import {
    type Description,
    type TableColumn,
    type TableGlideType,
    getTableColumn,
    isBigTableOrExternal,
    type SchemaInspector,
    getTableColumnDisplayName,
    makeSourceColumn,
    isFavoritedColumnName,
} from "@glide/type-schema";
import { type InputOutputTables, makeInputOutputTables } from "@glide/common-core/dist/js/description";
import type { Doc } from "@glide/common-core/dist/js/docUrl";
import { doesTableSupportRollups } from "@glide/common-core/dist/js/schema-properties";
import { GroupingSupport } from "@glide/component-utils";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ComponentSpecialCaseDescriptor,
    type EditedColumnsAndTables,
    type InlineListComponentDescription,
    type InteractiveComponentConfiguratorContext,
    type ListSourcePropertyDescription,
    type PropertyDescriptor,
    type PropertyTableGetter,
    type RewritingComponentConfiguratorContext,
    NumberPropertyStyle,
    PropertySection,
    QueryableTableSupport,
    RequiredKind,
    combineEditedColumnsAndTables,
    convertEditedColumnsToIndirect,
    mapActionsRecord,
    getPrimitiveNonHiddenColumnsSpec,
    resolveSourceColumn,
} from "@glide/function-utils";
import type { WireTableComponentHydratorConstructor, WireInflationBackend } from "@glide/wire";
import { filterUndefined, mapFilterUndefined } from "@glideapps/ts-necessities";
import { definedMap } from "collection-utils";
import values from "lodash/values";
import { getDefaultPrimitiveActionKinds, handlerForActionKind, rewriteActions } from "../actions";
import type { ComponentEasyTabConfiguration, ScreenContext } from "../components/component-handler";
import { getActionsForArrayContent } from "../components/component-utils";
import { PopulationMode } from "../components/description-handlers";
import {
    type CaptionPropertyDescriptorFlags,
    getEditedColumnsForProperties,
    rewriteFilter,
} from "../components/descriptor-utils";
import { populateDescription } from "../components/populate-description";
import { getEmailArrayFilter } from "../description-utils";
import { handlerForComponentKind } from "../handlers";
import { doesColumnSupportRollups } from "../computed-columns";
import { type WireStringGetter, getFormatForInputColumn } from "../wire/utils";
import type { GroupByGetters } from "./summary-array-screen";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import type { ActionWithTitleDescription } from "@glide/fluent-components/dist/js/fluent-components";

export interface FixedArrayContent {
    readonly properties: ArrayContentDescription;
    readonly format: ArrayScreenFormat;
}

export interface SearchSupport {
    readonly enabledByDefault: boolean;
}

export interface DynamicFilterSupport {
    // If this is `true`, then on queryable data sources the dynamic filter is
    // implemented via aggregate queries.  Otherwise they also just do it
    // in-memory.  Kanban is the only one for which this is `false`.
    readonly viaQueries: boolean;
}

export interface ComponentScreenContextForConversion {
    readonly titleAction: ActionWithTitleDescription | undefined;
    readonly screenTitle: string | undefined;
}

export interface ArrayContentHandler<TContentDesc extends ArrayContentDescription> {
    // Title for the breadcrumb if there's no single special case.
    readonly displayName?: string;
    readonly icon?: string;
    readonly format: ArrayScreenFormat;
    readonly helpPath: Doc;

    readonly quotaBannerRequirements: QuotaBannerRequirements;
    readonly needsOutputContext: boolean;
    readonly sourcePropertyLabel?: readonly [label: string, isDefaultCaption: boolean];
    readonly sourcePropertySection?: PropertySection;
    readonly isEditor: boolean;
    readonly defaultIsTable: boolean;
    readonly supportsEmptyArrays: boolean;
    // The dynamic filter is supported if this is defined.
    readonly supportsDynamicFilter: DynamicFilterSupport | undefined;
    readonly needsDynamicFilterValues: boolean;
    readonly hasComponents: boolean;
    // `undefined` means no paging
    readonly defaultPageSize: number | undefined;
    readonly maxPageSize: number | undefined;
    readonly allowChangePageSize: boolean;
    // If this is set, the Inline List hydrator will not even put `paging` in
    // the component.  Note that `defaultPageSize` can still be used by the
    // component itself if this is set.
    readonly doesCustomPaging: boolean;
    readonly hasFixedPaging: boolean;
    readonly supportsEasyTabConfiguration: boolean;
    readonly supportsSearch: SearchSupport | undefined;
    // If this is `true`, then the hydrator implements limit itself.
    readonly doesCustomLimit: boolean;
    readonly queryableTableSupport: QueryableTableSupport;
    readonly allowsQueryableSort: boolean;
    readonly showIfEmpty: boolean;
    readonly onlyQueries: boolean;

    getNeedsOrder(desc: TContentDesc | undefined): boolean;

    // If `sourcePicker` is `false`, the Inline List will not allow the user
    // to pick the source of the list.  We use this in Choice when writing to
    // a link column.
    getListSourceProperty(
        desc: TContentDesc & InlineListComponentDescription,
        containingScreenTables: InputOutputTables | undefined,
        schema: SchemaInspector
    ): [desc: ListSourcePropertyDescription, sourcePicker: boolean] | undefined;

    defaultContentDescription<TBase extends Description>(
        baseDesc: TBase,
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        getIndirectTable: PropertyTableGetter | undefined,
        specialCaseDescriptor: ComponentSpecialCaseDescriptor | undefined,
        usedColumns: ReadonlySet<TableColumn> | undefined,
        editedColumns: ReadonlySet<TableColumn> | undefined
    ): (TBase & TContentDesc) | undefined;

    adjustContentDescriptionAfterUpdate(
        desc: TContentDesc,
        updates: Partial<TContentDesc & InlineListComponentDescription> | undefined,
        tables: InputOutputTables,
        ccc: InteractiveComponentConfiguratorContext,
        getPropertyTable: PropertyTableGetter | undefined
    ): TContentDesc;

    getContentPropertyDescriptors(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        // This can only be defined if we're inside an Inline List.
        containingScreenTables: InputOutputTables | undefined,
        desc: TransformableContentDescription | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        isFirstComponent: boolean | undefined,
        screenContext: ScreenContext | undefined,
        appEnvironment: MinimalAppEnvironment | undefined
    ): ReadonlyArray<PropertyDescriptor>;
    getActionDescriptors<T extends TContentDesc>(
        desc: T,
        containingScreenTables: InputOutputTables | undefined,
        getPropertyTable: PropertyTableGetter | undefined,
        adc: AppDescriptionContext
    ): readonly ActionPropertyDescriptor[];

    // Only used for Inline Lists
    lowerDescriptionForBuilding<T extends TContentDesc>(desc: T, forGC: boolean): T;
    getEasyTabConfiguration<T extends TContentDesc>(desc: T): ComponentEasyTabConfiguration | undefined;

    getSpecialCaseDescriptors?(ccc: AppDescriptionContext): readonly ComponentSpecialCaseDescriptor[];

    // Does not include screens used by actions
    getScreensUsed(
        desc: TContentDesc,
        tables: InputOutputTables | undefined,
        schema: SchemaInspector,
        insideInlineList: boolean
    ): readonly string[];

    // If this returns `undefined` then we don't add a caption property.
    getCaptionFlags(desc: TContentDesc | undefined): CaptionPropertyDescriptorFlags | undefined;

    needValidation(desc: TContentDesc): boolean;

    getEditedColumns<T extends TContentDesc>(
        getPropertyTable: PropertyTableGetter | undefined,
        desc: T,
        // If `getPropertyTable` is defined, this will be the containing
        // component's tables, otherwise it will be the array's tables.
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        isDefaultArrayScreen: boolean,
        withActions: boolean
    ): EditedColumnsAndTables;

    // Returns the properties to be searched just via the content, not taking
    // into account the detail screen or anything else.
    getBasicSearchProperties(desc: TContentDesc): readonly string[];

    getDescriptiveName(
        _desc: TContentDesc,
        _caption: string | undefined,
        inlineListHostTable: TableGlideType | undefined,
        propertyDisplayName: string | undefined
    ): [string, string] | undefined;

    fixContentDescription(desc: TContentDesc): FixedArrayContent | undefined;

    rewriteContentAfterReload<T extends TContentDesc>(
        desc: T,
        // These are the tables of the array
        tables: InputOutputTables,
        getPropertyDescriptors: (desc: T) => readonly PropertyDescriptor[],
        // These are the tables of the properties from `getPropertyDescriptors`, which,
        // in the case of an Inline List, will be different from the tables of the array,
        // because they'll be the tables of the containing screen.
        propertyTables: InputOutputTables,
        ccc: RewritingComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isRewrite: boolean
    ): T | undefined;

    inflateContent<T extends TContentDesc>(
        b: WireInflationBackend,
        desc: T,
        captionGetter: WireStringGetter | undefined,
        // This is defined iff we're an Inline List.
        containingRowIB: WireInflationBackend | undefined,
        componentID: string | undefined,
        // If this is `undefined` it doesn't necessarily mean that there's no
        // filter set!  This is currently used by Kanban - it uses the dynamic
        // filter column as the tag column.
        filterColumnName: string | undefined
    ): WireTableComponentHydratorConstructor | undefined;

    convertInlineToPage(desc: InlineListComponentDescription & TContentDesc): ComponentDescription | undefined;

    convertArrayScreenToPage(
        desc: ArrayScreenDescription & TContentDesc,
        table: TableGlideType,
        adc: AppDescriptionContext,
        screenContext: ComponentScreenContextForConversion
    ): ComponentDescription | undefined;
}

export function propertyDescriptorsForTransforms(
    getPropertyTable: PropertyTableGetter | undefined,
    withOrder: boolean,
    withLimit: boolean,
    withContainingScreen: boolean
): readonly PropertyDescriptor[] {
    const descriptors: PropertyDescriptor[] = [
        {
            kind: PropertyKind.Transforms,
            property: { name: "transforms" },
            // FIXME: We don't actually use this label
            label: "Filter",
            getIndirectTable: getPropertyTable,
            section: PropertySection.FilterData,
            addText: "Add Filter",
            description: "Limit the items displayed based on their properties.",
            allowContextTable: true,
            allowLHSUserProfileColumns: false,
            forFilteringRows: true,
            withContainingScreen,
            allowSpecialValues: false,
        },
    ];

    if (withLimit) {
        descriptors.push({
            // ##limitTransformInGlobalSearch:
            // NOTE: If we ever make this configurable as a column, we will
            // have to walk this property description in the app walker!
            kind: PropertyKind.Number,
            property: {
                id: "limit",
                get: (d: Description) => {
                    const td = d as TransformableContentDescription;
                    if (td.transforms === undefined) return undefined;
                    return mapFilterUndefined(td.transforms, t =>
                        t.kind === ArrayTransformKind.Limit ? t.numRows : undefined
                    )[0];
                },
                update: (d: Description, v: PropertyDescription | undefined) => {
                    const td = d as TransformableContentDescription;
                    const transforms: ArrayTransform[] =
                        td.transforms?.filter(t => t.kind !== ArrayTransformKind.Limit) ?? [];
                    if (v !== undefined) {
                        transforms.push({ kind: ArrayTransformKind.Limit, numRows: v });
                    }
                    return { transforms };
                },
            },
            label: "Limit number of items",
            placeholder: "10",
            defaultValue: 10,
            getMaxValue: (tables, d, s) => {
                const table = getPropertyTable?.(tables, d, d, s, []);
                if (table !== undefined && isBigTableOrExternal(table.table)) {
                    return 1000;
                } else {
                    return undefined;
                }
            },
            section: PropertySection.Options,
            style: NumberPropertyStyle.Stepper,
            required: RequiredKind.NotRequiredDefaultMissing,
        });
    }
    if (withOrder) {
        descriptors.push({
            kind: PropertyKind.Sorts,
            property: { name: "transforms" },
            // FIXME: We don't actually use this label
            label: "Sort",
            getIndirectTable: getPropertyTable,
            section: PropertySection.Sort,
        });
    }
    return descriptors;
}

function getGroupByColumn(
    desc: ArrayContentDescription,
    adc: AppDescriptionContext,
    table: TableGlideType,
    support: GroupingSupport
): TableColumn | undefined {
    if (support === GroupingSupport.None) return undefined;
    const groupByColumnName = getColumnProperty(desc.groupByColumn);
    if (groupByColumnName === undefined) return undefined;
    const column = getTableColumn(table, groupByColumnName);
    if (column === undefined) return undefined;
    const gbtComputedColumnsAlpha = isExperimentEnabled("gbtComputedColumnsAlpha", adc.userFeatures);
    const gbtDeepLookups = isExperimentEnabled("gbtDeepLookups", adc.userFeatures);
    if (!doesColumnSupportRollups(adc, table, column, gbtComputedColumnsAlpha, gbtDeepLookups)) return undefined;
    return column;
}

export function makeGroupByGetters(
    ib: WireInflationBackend,
    desc: ArrayContentDescription,
    support: GroupingSupport
): GroupByGetters | undefined {
    const groupByColumn = getGroupByColumn(desc, ib.adc, ib.tables.input, support);
    if (groupByColumn === undefined) return undefined;

    const groupValueGetter = ib.getValueGetterForColumnInRow(groupByColumn.name, false, false);
    if (groupValueGetter === undefined) return undefined;

    const groupTitleGetter = ib.getValueGetterForColumnInRow(groupByColumn.name, false, true);

    const format = getFormatForInputColumn(ib, groupByColumn.name);

    return {
        column: groupByColumn,
        valueGetter: groupValueGetter,
        titleGetter: groupTitleGetter ?? groupValueGetter,
        format,
    };
}

function getTable(
    desc: Description | undefined,
    containingScreenTables: InputOutputTables | undefined,
    schema: SchemaInspector,
    getIndirectTable: PropertyTableGetter | undefined
): TableGlideType | undefined {
    if (getIndirectTable === undefined) return containingScreenTables?.input;
    if (desc === undefined || containingScreenTables === undefined) return undefined;
    return getIndirectTable(containingScreenTables, desc, desc, schema, [])?.table;
}

export abstract class ArrayContentHandlerBase<TContentDesc extends ArrayContentDescription>
    implements ArrayContentHandler<TContentDesc>
{
    constructor(public readonly format: ArrayScreenFormat) {}

    public get helpPath(): Doc {
        return "inlineList";
    }

    public get allowChangePageSize(): boolean {
        return true;
    }

    public get quotaBannerRequirements(): QuotaBannerRequirements {
        return { needListQuota: true, needMapQuota: false };
    }

    protected get canReverse(): boolean {
        return true;
    }

    public getNeedsOrder(_desc: TContentDesc | undefined): boolean {
        return true;
    }

    public get supportsSearch(): SearchSupport | undefined {
        return { enabledByDefault: false };
    }

    public get queryableTableSupport(): QueryableTableSupport {
        return QueryableTableSupport.LoadOnDemand;
    }

    public get allowsQueryableSort(): boolean {
        return true;
    }

    public get needsOutputContext(): boolean {
        return false;
    }

    public get isEditor(): boolean {
        return false;
    }

    public get defaultIsTable(): boolean {
        return false;
    }

    protected get groupingSupport(): GroupingSupport {
        return GroupingSupport.None;
    }

    protected get supportsLimit(): boolean {
        return true;
    }

    public get doesCustomLimit(): boolean {
        return false;
    }

    public get supportsDynamicFilter(): DynamicFilterSupport | undefined {
        return undefined;
    }

    public get needsDynamicFilterValues(): boolean {
        return false;
    }

    public get supportsEmptyArrays(): boolean {
        return false;
    }

    public get hasComponents(): boolean {
        return false;
    }

    public get defaultPageSize(): number | undefined {
        return undefined;
    }

    public get maxPageSize(): number | undefined {
        return undefined;
    }

    public get doesCustomPaging(): boolean {
        return false;
    }

    public get hasFixedPaging(): boolean {
        return false;
    }

    public get supportsEasyTabConfiguration(): boolean {
        return false;
    }

    public get showIfEmpty(): boolean {
        return false;
    }

    public get onlyQueries(): boolean {
        return false;
    }

    public getListSourceProperty(
        desc: TContentDesc & InlineListComponentDescription,
        _containingScreenTables: InputOutputTables | undefined,
        _schema: SchemaInspector
    ): [desc: ListSourcePropertyDescription, sourcePicker: boolean] | undefined {
        return [desc.propertyName, true];
    }

    public defaultContentDescription<TBase extends Description>(
        baseDesc: TBase,
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isDefaultArrayScreen: boolean,
        getIndirectTable: PropertyTableGetter | undefined,
        _specialCaseDescriptor: ComponentSpecialCaseDescriptor | undefined,
        usedColumns: ReadonlySet<TableColumn> | undefined,
        editedColumns: ReadonlySet<TableColumn> | undefined
    ): (TBase & TContentDesc) | undefined {
        const table = getTable(baseDesc, tables, ccc, getIndirectTable);
        if (table === undefined) return undefined;
        if (this.queryableTableSupport === QueryableTableSupport.Disable && isBigTableOrExternal(table))
            return undefined;

        const desc: TBase & ArrayContentDescription = {
            ...baseDesc,
            actions: [{ kind: ActionKind.PushDetailScreen }],
            components: [],
            reverse: makeSwitchProperty(false),
            groupByColumn: undefined,
            transforms: [],
            limit: undefined,
        };
        return populateDescription(
            d =>
                this.getContentPropertyDescriptors(
                    getIndirectTable,
                    insideInlineList,
                    containingScreenTables,
                    d,
                    ccc,
                    mutatingScreenKind,
                    isDefaultArrayScreen,
                    false,
                    false,
                    undefined,
                    undefined,
                    undefined
                ),
            PopulationMode.Default,
            desc as TBase & TContentDesc,
            desc as TBase & TContentDesc,
            tables,
            ccc,
            false,
            undefined,
            usedColumns,
            editedColumns
        );
    }

    public adjustContentDescriptionAfterUpdate(
        desc: TContentDesc,
        _updates: Partial<TContentDesc & InlineListComponentDescription> | undefined,
        _tables: InputOutputTables,
        _ccc: InteractiveComponentConfiguratorContext,
        _getPropertyTable: PropertyTableGetter | undefined
    ): TContentDesc {
        return desc;
    }

    public getContentPropertyDescriptors<T extends TContentDesc>(
        getPropertyTable: PropertyTableGetter | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        desc: T | undefined,
        ccc: AppDescriptionContext,
        _mutatingScreenKind: MutatingScreenKind | undefined,
        _isDefaultArrayScreen: boolean,
        withTransforms: boolean,
        forEasyTabConfiguration: boolean,
        _isFirstComponent: boolean | undefined,
        _screenContext: ScreenContext | undefined,
        _appEnvironment: MinimalAppEnvironment | undefined
    ): readonly PropertyDescriptor[] {
        const descrs: PropertyDescriptor[] = [];

        if (desc !== undefined && getEmailArrayFilter(desc) !== undefined && !forEasyTabConfiguration) {
            descrs.push({
                kind: PropertyKind.Filter,
                property: { name: "filter" },
                // FIXME: We don't actually use this label
                label: "Data filter",
                getIndirectTable: getPropertyTable,
                section: PropertySection.FilterByUser,
            });
        }

        const table =
            containingScreenTables !== undefined && desc !== undefined
                ? getPropertyTable?.(containingScreenTables, desc, desc, ccc, [])?.table
                : undefined;

        if (withTransforms) {
            const sortsInMemory =
                table === undefined ||
                !isBigTableOrExternal(table) ||
                // Our BigQuery data source doesn't support aggregation, so we
                // aggregate and sort in memory.
                table.sourceMetadata?.externalSource?.type === "bigquery";
            const withSort =
                (sortsInMemory || this.allowsQueryableSort) && (definedMap(desc, d => this.getNeedsOrder(d)) ?? true);
            descrs.push(
                ...propertyDescriptorsForTransforms(getPropertyTable, withSort, this.supportsLimit, insideInlineList)
            );
        }

        const { groupingSupport } = this;
        if (
            groupingSupport !== GroupingSupport.None &&
            !forEasyTabConfiguration &&
            // The `table` shouldn't be missing, but we're defensive here.  If
            // we have one, then we check it doesn't support rollups
            // (BigQuery) because those don't support grouping.
            (table === undefined || groupingSupport === GroupingSupport.InMemory || doesTableSupportRollups(table))
        ) {
            descrs.push({
                kind: PropertyKind.Column,
                property: { name: "groupByColumn" },
                label: "Group by",
                required: false,
                editable: true,
                searchable: false,
                emptyByDefault: true,
                getIndirectTable: getPropertyTable,
                columnFilter: getPrimitiveNonHiddenColumnsSpec,
                section: PropertySection.GroupBy,
                forFilteringRows: true,
            });
        }

        return descrs;
    }

    public getActionDescriptors<T extends TContentDesc>(
        _desc: T,
        _containingScreenTables: InputOutputTables | undefined,
        getPropertyTable: PropertyTableGetter | undefined,
        adc: AppDescriptionContext
    ): readonly ActionPropertyDescriptor[] {
        return [
            {
                kind: PropertyKind.Action,
                property: { name: "actions" },
                label: "Action",
                kinds: getDefaultPrimitiveActionKinds(adc, undefined),
                defaultAction: true,
                defaultActionForUndefined: true,
                required: false,
                getIndirectTable: getPropertyTable,
                section: PropertySection.ImportantAction,
            },
        ];
    }

    public lowerDescriptionForBuilding<T extends TContentDesc>(desc: T, _forGC: boolean): T {
        return desc;
    }

    public getEasyTabConfiguration<T extends TContentDesc>(_desc: T): ComponentEasyTabConfiguration | undefined {
        return undefined;
    }

    public getScreensUsed(
        _desc: TContentDesc,
        _tables: InputOutputTables | undefined,
        _schema: SchemaInspector,
        _insideInlineList: boolean
    ): readonly string[] {
        return [];
    }

    public getCaptionFlags(_desc: TContentDesc | undefined): CaptionPropertyDescriptorFlags | undefined {
        return { propertySection: PropertySection.DataTop };
    }

    public getEditedColumns<T extends TContentDesc>(
        getPropertyTable: PropertyTableGetter | undefined,
        desc: T,
        tables: InputOutputTables,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        insideInlineList: boolean,
        containingScreenTables: InputOutputTables | undefined,
        isDefaultArrayScreen: boolean,
        withActions: boolean
    ): EditedColumnsAndTables {
        const descriptors = this.getContentPropertyDescriptors(
            getPropertyTable,
            insideInlineList,
            containingScreenTables,
            desc,
            ccc,
            mutatingScreenKind,
            isDefaultArrayScreen,
            false,
            false,
            undefined,
            undefined,
            undefined
        );

        let editedColumns = getEditedColumnsForProperties(descriptors, desc, desc, withActions, {
            tables,
            mutatingScreenKind,
            context: ccc,
            isAutomation: false,
        });

        if (withActions) {
            let tablesForActions: InputOutputTables | undefined = tables;
            let inScreenContext = true;
            if (getPropertyTable !== undefined) {
                const propertyTable = getPropertyTable(tables, desc, desc, ccc, []);
                tablesForActions = definedMap(propertyTable?.table, makeInputOutputTables);
                inScreenContext = propertyTable?.inScreenContext === true;
            }

            if (tablesForActions !== undefined) {
                const actionsRecord = getActionsForArrayContent<TContentDesc>(this, tablesForActions, desc, ccc);
                for (const actions of values(actionsRecord)) {
                    for (const action of actions) {
                        const handler = handlerForActionKind(action.kind);

                        let editedInAction = handler.getEditedColumns(action, {
                            tables: tablesForActions,
                            context: ccc,
                            mutatingScreenKind,
                            isAutomation: false,
                        });
                        if (!inScreenContext) {
                            editedInAction = {
                                editedColumns: convertEditedColumnsToIndirect(editedInAction?.editedColumns ?? []),
                                deletedTables: editedInAction?.deletedTables ?? [],
                            };
                        }

                        editedColumns = combineEditedColumnsAndTables(editedColumns, editedInAction);
                    }
                }
            }
        }

        return editedColumns;
    }

    public abstract getBasicSearchProperties(desc: TContentDesc): readonly string[];

    public needValidation(_desc: TContentDesc): boolean {
        return false;
    }

    public getDescriptiveName(
        _desc: TContentDesc,
        _caption: string | undefined,
        _inlineListHostTable: TableGlideType | undefined,
        _propertyDisplayName: string | undefined
    ): [string, string] | undefined {
        return undefined;
    }

    public fixContentDescription(_desc: TContentDesc): FixedArrayContent | undefined {
        return undefined;
    }

    public rewriteContentAfterReload<T extends TContentDesc>(
        desc: T,
        contentTables: InputOutputTables,
        getPropertyDescriptors: (desc: T) => readonly PropertyDescriptor[],
        propertyTables: InputOutputTables,
        ccc: RewritingComponentConfiguratorContext,
        mutatingScreenKind: MutatingScreenKind | undefined,
        isRewrite: boolean
    ): T | undefined {
        desc = {
            ...desc,
            filter: definedMap(getFilterProperty(desc.filter), f =>
                makeFilterProperty(rewriteFilter(f, contentTables.input))
            ),
        };
        desc = populateDescription(
            getPropertyDescriptors,
            PopulationMode.Rewrite,
            desc,
            desc,
            propertyTables,
            ccc,
            true,
            mutatingScreenKind
        );
        desc = {
            ...desc,
            ...mapActionsRecord(
                getActionsForArrayContent<TContentDesc>(this, contentTables, desc, ccc),
                actions => rewriteActions(actions, contentTables, ccc, mutatingScreenKind) ?? []
            ),
        };
        if (this.hasComponents) {
            const components = mapFilterUndefined(desc.components ?? [], cd =>
                handlerForComponentKind(cd.kind)?.rewriteAfterReload(
                    cd,
                    contentTables,
                    ccc,
                    mutatingScreenKind,
                    isRewrite
                )
            );
            desc = { ...desc, components };
        } else {
            // We keep components around as a courtesy, to make switching between
            // array and detail screens non-disruptive, but we don't rewrite them
            // because then you might get "hidden" screens and actions clogging up
            // the app description.  That means when we do rewrite we need to
            // remove the components, or else switching back to them will produce
            // dangling references.
            desc = { ...desc, components: undefined };
        }
        return desc;
    }

    protected makeGroupByGetters(ib: WireInflationBackend, desc: TContentDesc): GroupByGetters | undefined {
        return makeGroupByGetters(ib, desc, this.groupingSupport);
    }

    public inflateContent<T extends TContentDesc>(
        _ib: WireInflationBackend,
        _desc: T,
        _captionGetter: WireStringGetter | undefined,
        _containingRowIB: WireInflationBackend | undefined,
        _componentID: string | undefined,
        _filterColumnName: string | undefined
    ): WireTableComponentHydratorConstructor | undefined {
        return undefined;
    }

    public abstract convertInlineToPage(
        desc: InlineListComponentDescription & TContentDesc
    ): ComponentDescription | undefined;

    public abstract convertArrayScreenToPage(
        desc: ArrayScreenDescription & TContentDesc,
        table: TableGlideType,
        adc: AppDescriptionContext,
        screenContext: ComponentScreenContextForConversion
    ): ComponentDescription | undefined;

    public defaultArrayContentConvertToPage(): ComponentDescription | undefined {
        return undefined;
    }

    protected getDynamicMultipleFiltersForPageConversion(
        adc: AppDescriptionContext,
        table: TableGlideType,
        dynamicFilterColumn: PropertyDescription | undefined,
        pivots: readonly ArrayPivot[] | undefined
    ): PropertyDescription | undefined {
        const itemForDynamicFilter = definedMap(getSourceColumnProperty(dynamicFilterColumn), sc => {
            const resolvedSourceColumn = resolveSourceColumn(adc, sc, table, undefined, undefined);
            const tableColumn = resolvedSourceColumn?.tableAndColumn?.column;
            if (tableColumn === undefined) return undefined;
            const displayName = getTableColumnDisplayName(tableColumn);
            return {
                caption: makeStringProperty(displayName),
                column: makeSourceColumnProperty(sc),
            };
        });

        // We either have `undefined` pivots, or a tuple where the second has the title for the "favorite" tag.
        const itemForFavorites = definedMap(pivots?.[1], p => {
            const caption = p.title ?? "Favorites";
            return {
                caption: makeStringProperty(caption),
                column: makeSourceColumnProperty(makeSourceColumn(isFavoritedColumnName)),
            };
        });

        if (itemForDynamicFilter === undefined && itemForFavorites === undefined) {
            return undefined;
        }

        return makeArrayProperty(filterUndefined([itemForDynamicFilter, itemForFavorites]));
    }
}
