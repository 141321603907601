import {
    type TableColumn,
    type ColumnType,
    type TableGlideType,
    SourceColumnKind,
    getTableName,
    isComputedColumn,
    isPrimitiveType,
    makeSourceColumn,
    isBigTableOrExternal,
} from "@glide/type-schema";
import {
    type PropertyDescription,
    type ArrayTransform,
    getColumnProperty,
    getEnumProperty,
    getSourceColumnProperty,
    makeSourceColumnProperty,
} from "@glide/app-description";
import { type InputOutputTables, makeInputOutputTables } from "@glide/common-core";
import { type GroundValue, type Query, type QuerySort, isLoadingValue } from "@glide/computation-model-types";
import {
    type WireTagOrChoiceItem,
    getColumnLinkTargetFromValueProperty,
} from "@glide/fluent-components/dist/js/base-components";
import {
    type WireAlwaysEditableValue,
    type WireInflationBackend,
    type WireRowComponentHydrationBackend,
    type WireTableComponentHydrationBackend,
    type WireTableGetter,
    type WireValueGetterGeneric,
    ValueChangeSource,
    makeContextTableTypes,
} from "@glide/wire";
import { getQueryLimitForPaging, inflateStringProperty } from "../wire/utils";
import { type AppDescriptionContext, getTableAndColumnForSourceColumn } from "@glide/function-utils";
import { definedMap } from "collection-utils";
import { isDefined, isEmptyOrUndefined } from "@glide/support";
import { asString } from "@glide/common-core/dist/js/computation-model/data";
import type { GroupByGetters } from "../array-screens/summary-array-screen";
import { getLimitFromArrayTransforms, getSortFromArrayTransforms } from "../description-utils";
import type { FluentArrayContent } from "@glide/fluent-components/dist/js/fluent-components-spec";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { isColumnAllowedForFilteringRows } from "../allowed-columns";

function getTagManualColor(
    rhb: WireRowComponentHydrationBackend,
    tagColorKind: "Auto" | "Manual",
    colorGetter: WireValueGetterGeneric<string> | undefined
) {
    if (tagColorKind === "Manual") {
        return colorGetter?.(rhb) ?? undefined;
    }

    return undefined;
}

interface ChoiceColumnInfo {
    readonly choiceSourceGetter: WireTableGetter | undefined;
    readonly choiceSourceTableType: TableGlideType | undefined;
    readonly choiceValuesGetter: WireValueGetterGeneric<string> | undefined;
    readonly choiceColorGetter: WireValueGetterGeneric<string> | undefined;
    readonly tagColorKind: "Auto" | "Manual";
    readonly choiceDisplayGetter: WireValueGetterGeneric<string> | undefined;
    readonly choiceTokenMaker: (hb: WireRowComponentHydrationBackend) => string | false | undefined;
    readonly columnName: string | undefined;
}

export type BaseTagOrChoiceItem = Omit<WireTagOrChoiceItem, "onSelect">;

export function makeWireTagOrChoiceItemFromBase(
    rhb: WireRowComponentHydrationBackend,
    c: ChoiceColumnInfo,
    baseOptions: BaseTagOrChoiceItem[],
    selectedValues: string[],
    isMulti: boolean,
    rowIdx: number
): WireTagOrChoiceItem[] {
    const maybeOnChangeToken = c.choiceTokenMaker(rhb);
    const onChangeToken = maybeOnChangeToken === false ? undefined : maybeOnChangeToken;

    const options: WireTagOrChoiceItem[] = baseOptions.map(o => {
        const onSelectToken = definedMap(onChangeToken, t =>
            rhb.registerAction(`choose-${c.columnName}-${rowIdx}-${o.value}`, async ab => {
                if (isMulti) {
                    const newValues = selectedValues.includes(o.value)
                        ? selectedValues.filter(v => v !== o.value)
                        : [...selectedValues, o.value];
                    const stringifiedNewValues = newValues.filter(v => !isEmptyOrUndefined(v)).join(",");

                    return ab.valueChanged(t, stringifiedNewValues, ValueChangeSource.User);
                } else {
                    return ab.valueChanged(t, o.value, ValueChangeSource.User);
                }
            })
        );

        return {
            ...o,
            onSelect: definedMap(onSelectToken, token => ({ token })),
        };
    });

    return options;
}

// `rhb` is used to hydrate the choices, and `chb` is used to make a hydration
// backend for the choice table.
export function getBaseChoiceItems(
    rhb: WireRowComponentHydrationBackend,
    chb: WireRowComponentHydrationBackend,
    c: ChoiceColumnInfo
): BaseTagOrChoiceItem[] {
    const choiceOptions: Map<string, BaseTagOrChoiceItem> = new Map();

    const {
        choiceSourceTableType,
        choiceSourceGetter,
        choiceValuesGetter,
        choiceColorGetter,
        choiceDisplayGetter,
        tagColorKind,
    } = c;

    if (choiceSourceTableType !== undefined && choiceSourceGetter !== undefined) {
        const table = choiceSourceGetter(rhb);

        if (table !== undefined && !isLoadingValue(table)) {
            const choiceThB = chb.makeHydrationBackendForTable(choiceSourceTableType, table);

            for (const choiceRow of table.asArray()) {
                const choiceRhb = choiceThB.makeHydrationBackendForRow(choiceRow);
                const value = choiceValuesGetter?.(choiceRhb) ?? undefined;
                const color = getTagManualColor(choiceRhb, tagColorKind, choiceColorGetter);
                const displayValue = choiceDisplayGetter?.(choiceRhb) ?? value ?? "";
                const isRowVisible = choiceRow.$isVisible;

                if (value !== undefined && isRowVisible) {
                    choiceOptions.set(value, { value, displayValue, color });
                }
            }
        }
    }

    return [...choiceOptions.values()];
}

function canSortColumn(table: TableGlideType, column: TableColumn, ccc: AppDescriptionContext): boolean {
    if (isBigTableOrExternal(table)) {
        return isColumnAllowedForFilteringRows(
            false,
            true,
            ccc,
            table,
            column,
            undefined, // We don't need the computation model for queryables
            isExperimentEnabled("gbtComputedColumnsAlpha", ccc.userFeatures),
            isExperimentEnabled("gbtDeepLookups", ccc.userFeatures)
        );
    }

    return isPrimitiveType(column.type) && column.type.kind !== "json";
}

interface InflatedBaseChoiceColumn {
    readonly valueGetter: WireValueGetterGeneric<GroundValue>;
    readonly formattedValueGetter: WireValueGetterGeneric<string>;
    readonly type: ColumnType | undefined; // Type is undefined if the value property badly (e.g bound to a non existing column)
    readonly choicesAreConstant: boolean; // Same choices for all rows?
    readonly choiceSourceGetter: WireTableGetter | undefined;
    readonly choiceSourceTableType: TableGlideType | undefined;
    readonly choiceValuesGetter: WireValueGetterGeneric<string> | undefined;
    readonly choiceColorGetter: WireValueGetterGeneric<string> | undefined;
    readonly choiceDisplayGetter: WireValueGetterGeneric<string> | undefined;
    readonly columnName: string | undefined; // columnName is undefined if the value property is badly configured
    readonly canSort: boolean;
    readonly choiceTokenMaker: (hb: WireRowComponentHydrationBackend) => string | false | undefined;
    readonly isEditable: boolean;
}

export interface BaseChoiceColumnDescription {
    readonly value: PropertyDescription | undefined;
    readonly choiceSource: PropertyDescription | undefined;
    readonly choiceValues: PropertyDescription | undefined;
    readonly choiceColor: PropertyDescription | undefined;
    readonly choiceDisplay: PropertyDescription | undefined;
    readonly viewOrEdit: "View" | "Edit";
}

// We inflate things a bit differently if the value is bound to a relation.
// Similar to the link picker choice.
export function inflateBaseChoiceColumn(
    ib: WireInflationBackend,
    tables: InputOutputTables,
    c: BaseChoiceColumnDescription
): InflatedBaseChoiceColumn {
    const linkTarget = getColumnLinkTargetFromValueProperty(ib.adc, tables, c.value);

    if (linkTarget === undefined) {
        const columnName = getColumnProperty(c.value);

        const [valueGetter, type] = ib.getValueGetterForProperty(c.value, false);
        const { tokenMaker } = ib.getValueSetterForProperty(c.value, `choice-${columnName}`);
        const [formattedValueGetter] = inflateStringProperty(ib, c.value, true);
        const [choiceSourceGetter, choiceSourceTableType] = ib.getTableGetter(c.choiceSource, false) ?? [];

        // If the choices either don't come out of a column at all, or the
        // column they come out of is not the displayed row, then they must be
        // the same across all rows and only need to be computed once.
        const choicesAreConstant = getSourceColumnProperty(c.choiceSource)?.kind !== SourceColumnKind.DefaultContext;

        const choiceIB = definedMap(choiceSourceTableType, t =>
            ib.makeInflationBackendForTables(makeContextTableTypes(makeInputOutputTables(t)), ib.mutatingScreenKind)
        );

        const choiceValuesGetter = definedMap(choiceIB, cib => inflateStringProperty(cib, c.choiceValues, false)[0]);

        const choiceColorGetter = definedMap(choiceIB, cib => inflateStringProperty(cib, c.choiceColor, true)[0]);
        const choiceDisplayGetter = definedMap(choiceIB, cib => inflateStringProperty(cib, c.choiceDisplay, true)[0]);

        const maybeColumn = definedMap(getSourceColumnProperty(c.value), sc =>
            getTableAndColumnForSourceColumn(ib.adc, sc, ib.tables.input, undefined)
        )?.column;
        const canSort = isDefined(maybeColumn) && canSortColumn(ib.tables.input, maybeColumn, ib.adc);

        const isComputed = isDefined(maybeColumn) && isComputedColumn(maybeColumn);
        const isEditable = c.viewOrEdit === "Edit" && !isComputed;

        return {
            valueGetter,
            formattedValueGetter,
            type,
            choicesAreConstant,
            choiceSourceGetter,
            choiceSourceTableType,
            choiceValuesGetter,
            choiceColorGetter,
            choiceDisplayGetter,
            columnName,
            canSort,
            choiceTokenMaker: tokenMaker,
            isEditable,
        };
    } else {
        const columnName = linkTarget.hostColumn.name;

        const [valueGetter, type] = ib.getValueGetterForSourceColumn(linkTarget.hostSourceColumn, true, false);
        const { tokenMaker } = ib.getValueSetterForProperty(
            makeSourceColumnProperty(linkTarget.hostSourceColumn),
            `choice-${columnName}`
        );
        const [rawFormattedValueGetter] = ib.getValueGetterForSourceColumn(linkTarget.hostSourceColumn, true, true);

        const formattedValueGetter: WireValueGetterGeneric<string> = hb => {
            const v = rawFormattedValueGetter(hb);
            if (v === null) return null;
            if (isLoadingValue(v)) return "";
            return asString(v);
        };

        const targetSourceColumn = makeSourceColumn(linkTarget.targetColumn.name);

        const [choiceSourceGetter, choiceSourceTableType] =
            ib.getTableGetter(getTableName(linkTarget.targetTable), true) ?? [];

        const choiceIB = ib.makeInflationBackendForTables(
            makeContextTableTypes(makeInputOutputTables(linkTarget.targetTable)),
            ib.mutatingScreenKind
        );

        const rawChoiceValuesGetter = definedMap(
            choiceIB,
            cib => cib.getValueGetterForSourceColumn(targetSourceColumn, true, false)[0]
        );

        const choiceValuesGetter: WireValueGetterGeneric<string> | undefined = definedMap(
            rawChoiceValuesGetter,
            getter => hb => {
                const v = getter(hb);
                if (v === null) return null;
                if (isLoadingValue(v)) return "";
                return asString(v);
            }
        );

        const choiceColorGetter = definedMap(choiceIB, cib => inflateStringProperty(cib, c.choiceColor, true)[0]);
        const choiceDisplayGetter = definedMap(choiceIB, cib => inflateStringProperty(cib, c.choiceDisplay, true)[0]);

        const canSort = canSortColumn(ib.tables.input, linkTarget.hostColumn, ib.adc);
        const viewOrEdit = getEnumProperty<"View" | "Edit">(c.viewOrEdit) ?? "Edit";
        const isEditable = viewOrEdit === "Edit";

        return {
            valueGetter,
            formattedValueGetter,
            type,
            choicesAreConstant: true,
            choiceSourceGetter,
            choiceSourceTableType,
            choiceValuesGetter,
            choiceColorGetter,
            choiceDisplayGetter,
            columnName,
            canSort,
            choiceTokenMaker: tokenMaker,
            isEditable,
        };
    }
}

export function sortAndLimitQuery(
    ib: WireInflationBackend,
    query: Query,
    thb: WireTableComponentHydrationBackend,
    selectedPageSize: number | undefined,
    pageIndexState: WireAlwaysEditableValue<number>,
    groupByGetters: GroupByGetters | undefined,
    transforms: readonly ArrayTransform[] | undefined,
    arrayContent: FluentArrayContent<unknown, unknown, unknown>
) {
    let maybeGroupAggregateQuery = query;

    const sortByKey = thb.getState<QuerySort | undefined>("sortByKey", undefined, undefined, false);
    const doesSort = isDefined(sortByKey.value);
    const maybeSort = getSortFromArrayTransforms(transforms ?? []);

    const newTableSort: QuerySort[] = [];

    if (doesSort) {
        newTableSort.push(sortByKey.value);
    }

    if (maybeSort !== undefined) {
        newTableSort.push(maybeSort);
    }

    if (groupByGetters !== undefined) {
        const groupByColumnName = groupByGetters.column.name;

        const order = newTableSort.find(sort => sort.columnName === groupByColumnName)?.order ?? "asc";
        const groupByQuery: QuerySort = {
            columnName: groupByColumnName,
            order,
        };

        maybeGroupAggregateQuery = maybeGroupAggregateQuery.withoutLimit().withGroupBy({
            columns: [groupByColumnName],
            limit: 1000,
            aggregates: [],
            sort: [groupByQuery],
        });
    }

    if (doesSort && groupByGetters === undefined) {
        maybeGroupAggregateQuery = maybeGroupAggregateQuery.withoutLimit().withSort(newTableSort);
    }

    if (isBigTableOrExternal(ib.tables.input)) {
        maybeGroupAggregateQuery = maybeGroupAggregateQuery.withLimit(
            getQueryLimitForPaging(arrayContent.spec.queryableTableSupport, selectedPageSize, pageIndexState)
        );
    } else {
        const maybeLimit = getLimitFromArrayTransforms(transforms ?? []);
        if (maybeLimit === undefined) {
            maybeGroupAggregateQuery = maybeGroupAggregateQuery.withoutLimit();
        } else {
            maybeGroupAggregateQuery = maybeGroupAggregateQuery.withLimit(maybeLimit);
        }
    }
    return { maybeGroupAggregateQuery, newTableSort };
}
