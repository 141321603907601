import * as glide from "@glide/plugins";

export const plugin = glide.newPlugin({
    id: "native-barcode-scanner",
    name: "Native barcode scanner",
    tier: "business",
    isExperimental: true,
});

plugin.addClientAction({
    id: "scan-barcode",
    name: "Scan barcode or QR code",
    description: "Scan a barcode or QR code",
    execute: () => {
        throw new Error("This is a dummy");
    },
});
