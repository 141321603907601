import React from "react";

interface PreventDragBackContext {
    isPrevented: boolean;
    setIsPrevented: React.Dispatch<React.SetStateAction<boolean>>;
}

const PreventDragBackContextImpl = React.createContext<PreventDragBackContext | undefined>(undefined);

export const PreventDragBackProvider: React.FC<React.PropsWithChildren> = p => {
    const { children } = p;

    const [isPrevented, setIsPrevented] = React.useState(false);

    const value: PreventDragBackContext = React.useMemo(() => {
        return {
            isPrevented,
            setIsPrevented,
        };
    }, [isPrevented]);

    return <PreventDragBackContextImpl.Provider value={value}>{children}</PreventDragBackContextImpl.Provider>;
};

export function usePreventDragBack(): PreventDragBackContext | undefined {
    return React.useContext(PreventDragBackContextImpl);
}
