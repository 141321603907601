const cache = new Map<string, number>();

export function getScrollBarWidth(desiredThemeWidth?: number, thin: boolean = false): number {
    const cacheKey = [desiredThemeWidth, thin].join("-");

    const cacheResult = cache.get(cacheKey);
    if (cacheResult !== undefined) {
        return cacheResult;
    }

    const inner = document.createElement("p");
    inner.style.width = "100%";
    inner.style.height = "200px";

    const outer = document.createElement("div");
    outer.id = "testScrollbar";
    if (desiredThemeWidth !== undefined) {
        outer.innerHTML = `<style>#testScrollbar::-webkit-scrollbar {
            width: ${desiredThemeWidth}px;
            height: ${desiredThemeWidth}px;
        }</style>`;
    }
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    (outer.style as any).scrollbarWidth = thin ? "thin" : "auto";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    const w1 = inner.offsetWidth;
    outer.style.overflow = "scroll";
    let w2 = inner.offsetWidth;
    if (w1 === w2) {
        w2 = outer.clientWidth;
    }

    document.body.removeChild(outer);

    cache.set(cacheKey, w1 - w2);

    return w1 - w2;
}
