// FIXME: Surely this is implemented elsewhere?
export function* iterableFilter<T>(it: Iterable<T>, pred: (entry: T) => boolean): IterableIterator<T> {
    for (const entry of it) {
        if (pred(entry)) yield entry;
    }
}

// FIXME: Surely this is implemented elsewhere?
export function iterableTake<T>(it: Iterable<T>, entries: number): T[] {
    const result: T[] = [];
    let i = 0;
    for (const entry of it) {
        if (i >= entries) break;
        result.push(entry);
        i++;
    }
    return result;
}

export function* concatIterableIterators<T>(...iterators: IterableIterator<T>[]): IterableIterator<T> {
    for (const it of iterators) {
        yield* it;
    }
}
