import "twin.macro";

import type { GlideFC } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { TextEntrySize } from "@glide/component-utils";
import type { AppKind } from "@glide/location-common";
import { UIButtonAppearance } from "@glide/wire";

import { WireButton } from "../wire-button/wire-button";
import { WireField } from "../wire-field/wire-field";

interface Props extends React.PropsWithChildren {
    title?: string;
    description?: string;

    name?: string;
    nameError?: string;
    onNameChange?: (newVal: string) => void;
    email?: string;
    emailError?: string;
    onEmailChange?: (newVal: string) => void;
    phone?: string;
    phoneError?: string;
    onPhoneChange?: (newVal: string) => void;
    message?: string;
    messageError?: string;
    onMessageChange?: (newVal: string) => void;
    onSubmit?: () => void;
    appKind: AppKind;
}

export const FormSection: GlideFC<Props> = p => {
    const {
        title,
        description,
        email,
        message,
        name,
        onEmailChange,
        onMessageChange,
        onNameChange,
        onPhoneChange,
        phone,
        onSubmit,
        emailError,
        messageError,
        nameError,
        phoneError,
        appKind,
    } = p;

    return (
        <div tw="w-full relative">
            <div tw="flex-1 flex flex-col items-center">
                <div
                    tw="flex flex-col justify-center max-w-xl w-full py-5 space-y-3
                        gp-sm:p-6
                        gp-md:(py-8 space-y-5)"
                >
                    <div>
                        {title !== undefined && <h2 tw="text-text-contextual-dark font-bold text-3xl">{title}</h2>}
                        {description !== undefined && (
                            <div tw="text-base text-text-contextual-xpale mt-2">{description}</div>
                        )}
                    </div>
                    {onNameChange && (
                        <div>
                            <WireField
                                title={getLocalizedString("name", appKind)}
                                placeholder="John Doe"
                                value={name ?? ""}
                                onChange={onNameChange}
                                isEnabled={true}
                                error={nameError}
                            />
                        </div>
                    )}
                    {onEmailChange && (
                        <div>
                            <WireField
                                title={getLocalizedString("emailAddress", appKind)}
                                placeholder="myemail@example.com"
                                dataType="email"
                                value={email ?? ""}
                                onChange={onEmailChange}
                                isEnabled={true}
                                error={emailError}
                            />
                        </div>
                    )}
                    {onPhoneChange && (
                        <div>
                            <WireField
                                title={getLocalizedString("phoneNumber", appKind)}
                                placeholder="555-555-5555"
                                dataType="phone"
                                value={phone ?? ""}
                                onChange={onPhoneChange}
                                isEnabled={true}
                                error={phoneError}
                            />
                        </div>
                    )}
                    {onMessageChange && (
                        <div>
                            <WireField
                                title={getLocalizedString("message", appKind)}
                                placeholder={getLocalizedString("aQuickMessage", appKind)}
                                value={message ?? ""}
                                dataType="text"
                                onChange={onMessageChange}
                                size={TextEntrySize.Medium}
                                isEnabled={true}
                                error={messageError}
                            />
                        </div>
                    )}
                    <div tw="self-start py-2">
                        <WireButton
                            tw="w-auto"
                            disabled={onSubmit === undefined}
                            appearance={UIButtonAppearance.Filled}
                            onClick={onSubmit}
                        >
                            {getLocalizedString("submit", appKind)}
                        </WireButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
