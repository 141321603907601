// These must be the CSS name for the `background-size` property.
export enum Sizing {
    Cover = "cover",
    Contain = "contain",
}

export enum ImageFitStyle {
    FullWidth = "full-width",
    Content = "content",
}

export enum ImageGravity {
    Faces = "faces",
    Center = "center",
}

// actual string values retained for historical purposes
export enum ImageAspectRatio {
    ThreeByOne = "small",
    ThreeByTwo = "medium",
    FourByThree = "four-by-three",
    Square = "large",
    ThreeByFour = "three-by-four",
    TwoByThree = "two-by-three",
    Fit = "fit",
    Circle = "circle",
    Expand = "expand",
}

export enum TilesPadding {
    Loose = "loose",
    Tight = "tight",
}

export enum Corners {
    Square = "square",
    Rounded = "rounded",
    Circle = "circle",
}

export enum TextPosition {
    Overlay = "overlay",
    Below = "below",
}

export enum OverlayTextAlignment {
    Center = "center",
    TopLeft = "top-left",
    TopRight = "top-right",
    BottomLeft = "bottom-left",
    BottomRight = "bottom-right",
}
