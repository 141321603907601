import { hasOwnProperty } from "@glideapps/ts-necessities";
import type { ComponentDescription, AppDescription } from "@glide/app-description";
import { isArray } from "@glide/support";

// ##removeComponentIDsFromAppDescription:
// It turns out that the component IDs make up a significant part of the size
// of the compressed app descriptions, and published apps. Their actual values
// don't matter, as long as they are unique, so we just remove them when we
// save the app, and we make new ones when fixing the app on load.
//
// https://github.com/quicktype/glide/issues/10974
export function removeComponentIDsFromAppDescription<T extends AppDescription>(desc: T): T {
    function processComponents(components: readonly ComponentDescription[]): readonly ComponentDescription[] {
        return components.map(c => {
            const d = { ...c };
            delete (d as any).componentID;
            if (hasOwnProperty(d, "components") && isArray(d.components)) {
                d.components = processComponents(d.components as ComponentDescription[]);
            }
            return d;
        });
    }

    const screenDescriptions = { ...desc.screenDescriptions };
    for (const [screenName, screen] of Object.entries(screenDescriptions)) {
        const newScreen = { ...screen };
        if (hasOwnProperty(newScreen, "components") && Array.isArray(newScreen.components)) {
            newScreen.components = processComponents(newScreen.components);
        }
        screenDescriptions[screenName] = newScreen;
    }
    return { ...desc, screenDescriptions };
}
