import { isUserData } from "@glide/common-core/dist/js/Database";
import { useRouteMatch } from "react-router-dom";
import { useBuilderSelector } from "../../hooks/use-builder-selector";
import { Button } from "../button/button";
import { AccountMenu } from "./account-menu";
import { useOrgPageHeaderContext } from "./context";
import * as routes from "@glide/common-core/dist/js/routes";
import { GlideIcon } from "@glide/common";

import { MembersAvatars } from "./members-avatars";
import { useMembers } from "../../hooks/use-members";
import { useMemo } from "react";
import { useIsUserVerified } from "../../lib/is-user-verified";
import { useEminenceFlags } from "@glide/common-components";

interface Props extends React.PropsWithChildren {
    pageName: string;
    isLoading?: boolean;
}

export const OrgPageHeader: React.FC<React.PropsWithChildren<Props>> = props => {
    const { pageName, children, isLoading } = props;

    const match = useRouteMatch<routes.OrgPatternRouteProps>(routes.orgPattern);
    const orgID = match?.params.orgID;

    const { userData } = useBuilderSelector(state => ({
        userData: state.userData,
    }));

    const { showInviteModal } = useOrgPageHeaderContext();

    const { orgMembers, viewMembers } = useMembers(orgID);
    const isVerified = useIsUserVerified();

    const membersWithoutMe = useMemo(
        () => orgMembers.filter(u => isUserData(userData) && u.email !== userData.emails[0]),
        [orgMembers, userData]
    );

    const { data: maybeEminenceFlags, isLoading: isLoadingEminenceFlags, isSuccess } = useEminenceFlags(orgID);
    const showContactSales = !isLoadingEminenceFlags && isSuccess && !maybeEminenceFlags.isFreeEminence;

    return (
        <div>
            <div tw="flex justify-between items-center w-full">
                <div tw="flex gap-4 items-start">
                    <div tw="h-9 font-semibold leading-tight text-builder-3xl text-text-dark">{pageName}</div>
                    {isLoading && (
                        <GlideIcon icon="st-spinner" kind="stroke" tw="text-icon-base mt-1.5" spin iconSize={20} />
                    )}
                </div>
                <div tw="flex gap-4 items-center">
                    {children}
                    {isVerified && (
                        <>
                            <MembersAvatars members={membersWithoutMe} onClick={viewMembers} />
                            <Button
                                label="Invite"
                                variant={"default"}
                                buttonType={"secondary"}
                                size={"md"}
                                tw="rounded-full"
                                onClick={showInviteModal}
                            />
                            {showContactSales && (
                                <Button
                                    tw="rounded-full w-fit bg-aqua400"
                                    variant="accent"
                                    label="Contact Sales"
                                    href={
                                        "https://www.glideapps.com/enterprise?contactSales=true&source=dashboard"
                                    }></Button>
                            )}
                        </>
                    )}
                    {isUserData(userData) && <AccountMenu userData={userData} />}
                </div>
            </div>
        </div>
    );
};
