import {
    type AppDescription,
    defaultUserFeatures,
    type BuilderActionsForApp,
    type ActionDescription,
    type ArrayContentDescription,
    type ArrayTransform,
    type BaseContainerComponentDescription,
    type ClassOrArrayScreenDescription,
    type ComponentDescription,
    type EditScreenDescription,
    type FilterArrayTransform,
    type FormScreenDescription,
    type PropertyDescription,
    type ScreenDescription,
    type TabDescription,
    type TransformableContentDescription,
    ActionKind,
    ArrayScreenFormat,
    ArrayTransformKind,
    PropertyKind,
    ScreenDescriptionKind,
    UnaryPredicateCompositeOperator,
    getArrayProperty,
    getEnumProperty,
    getScreenProperty,
    getSourceColumnProperty,
    getSwitchProperty,
    makeEnumProperty,
    makeScreenProperty,
    makeSwitchProperty,
} from "@glide/app-description";
import { AppKind } from "@glide/location-common";
import {
    getAppFeatures,
    getAppKind,
    getAppTabs,
    getSourceMetadata,
    getSourceMetadataForTable,
} from "@glide/common-core/dist/js/components/SerializedApp";
import {
    areTableNamesEqual,
    type Formula,
    type TypeSchema,
    FormulaKind,
    findTable,
    getTableName,
    getTableRefTableName,
    isComputedColumn,
    isPrimitiveType,
    makeSourceColumn,
    makeTableName,
    makeTableRef,
    makeTypeSchema,
    type SourceMetadata,
    getNativeTableIDFromTableName,
} from "@glide/type-schema";
import type { EminenceFlags } from "@glide/billing-types";
import { eminenceFull } from "@glide/common-core/dist/js/Database/eminence";
import {
    ComponentKindInlineList,
    getArrayTransforms,
    getScreenComponentsArray,
    isClassOrArrayScreenDescription,
} from "@glide/common-core/dist/js/description";
import {
    type ActionsRecord,
    type AppDescriptionContext,
    type InlineListComponentDescription,
    addClassScreenName,
    arrayScreenName,
    chatScreenName,
    classScreenName,
    editClassScreenName,
    freeScreenPrefix,
    getAddClassScreenTableName,
    getArrayScreenTableName,
    getClassOrArrayScreenTableName,
    getClassScreenTableName,
    getEditClassScreenTableName,
    isAddClassScreenName,
    isAddOrEditOrFormScreenDescription,
    isEditClassScreenName,
    isFormScreenName,
    isFreeScreen,
    isFreeScreenName,
    isNamedPropertySource,
    isVariantScreenName,
    mapActionsRecord,
    shoppingCartScreenName,
    userProfileScreenName,
} from "@glide/function-utils";
import {
    assert,
    defined,
    mapFilterUndefined,
    panic,
    type Writable,
    DefaultMap,
    hasOwnProperty,
} from "@glideapps/ts-necessities";
import { isArray, logError, truthify } from "@glide/support";
import { WireComponentKind, UIAspect } from "@glide/wire";
import { definedMap } from "collection-utils";
import { produce } from "immer";
import toPairs from "lodash/toPairs";
import { handlerForActionKind } from "./actions";
import { forEachActionInApp } from "./actions/for-each-action";
import type { PushFreeScreenActionDescription } from "./actions/link-to-screen";
import { getDefaultArrayScreenHandler, handlerForArrayScreenFormat } from "./array-screens";
import { ButtonComponentHandler, ComponentKindButton } from "./components/button";
import { ComponentKindBuyButton } from "./components/buy-button";
import { ChoiceComponentHandler, ComponentKindChoice } from "./components/choice";
import { getActionsForArrayContent, getActionsForComponent } from "./components/component-utils";
import { isInlineListComponentDescription } from "./components/inline-list";
import { makeSimpleComponentConfiguratorContext } from "./components/simple-ccc";
import { type VideoPlayerComponentDescription, ComponentKindVideo } from "./components/video-player";
import {
    type AppDescriptionVisitor,
    type Location,
    AppVisitorCache,
    walkAppDescription,
    getScreenNameForLocation,
} from "./components/walk-app-description";
import { getColumnAssignmentsWithLegacy, getEmailArrayFilter } from "./description-utils";
import { ToastIcon, makeShowToastAction } from "./form-on-submit";
import {
    type PredicateCombinationSpecification,
    type UnaryPredicateSpecification,
    decomposeAll,
    decomposeFormatFormula,
    decomposePredicateCombinationFormula,
    makePredicateCombinationFormula,
} from "@glide/formula-specifications";
import { handlerForComponentKind } from "./handlers";
import { makeAddOrEditScreenDescriptionForTable } from "./make-edit-screen";
import { makeArrayScreenDescription } from "./make-screen";
import { makeDefaultClassScreen } from "./pages";
import { getDefaultTable } from "./schema-utils";
import {
    addOrRemoveSpecialTabsIfNecessary,
    chatScreen,
    copyToFreeScreen,
    makeScreenNameWithPrefix,
    shoppingCartScreen,
} from "./screens";

function isPrimitiveScreenName(name: string): boolean {
    return name.startsWith("primitive-");
}

function doesLinkToScreenFetchData(source: PropertyDescription | undefined): boolean {
    return getSourceColumnProperty(source) === undefined && getEnumProperty(source) === undefined;
}

interface PushActionData {
    readonly fromScreenName: string | undefined;
    readonly pushedScreenName: string;
    readonly fetchesData: boolean;
}

class CheckActionVisitor implements AppDescriptionVisitor<PushActionData[]> {
    public readonly state: PushActionData[] = [];
    private currentState: PushActionData[] | undefined;

    public addState(state: PushActionData[]): void {
        assert(this.currentState === undefined);
        this.state.push(...state);
    }

    public willVisitWithState(state: PushActionData[]): void {
        assert(this.currentState === undefined);
        this.currentState = state;
    }

    public finishedVisitingWithState(state: PushActionData[]): void {
        assert(this.currentState === state);
        this.state.push(...state);
        this.currentState = undefined;
    }

    public visitAction(location: Location, action: ActionDescription): void {
        const fromScreenName = getScreenNameForLocation(location);

        if (action.kind !== ActionKind.PushFreeScreen) return;

        const pushAction = action as PushFreeScreenActionDescription;
        const pushedScreenName = pushAction.screenName;

        const state = this.currentState ?? this.state;

        state.push({
            fromScreenName,
            pushedScreenName,
            fetchesData: doesLinkToScreenFetchData(pushAction.sourceColumn),
        });
    }
}

const checkActionCache = new AppVisitorCache<PushActionData[]>(() => []);

// Returns a list of issues, or `undefined` if everything's ok.
// If `schema` is defined, we do more strict checks.
export function checkAppDescriptionConsistency(
    app: AppDescription,
    schema: TypeSchema | undefined,
    isSaved: boolean
): readonly string[] | undefined {
    const strict = schema !== undefined;
    const issues: string[] = [];
    const appKind = getAppKind(app);

    function checkArrayTransforms(transforms: readonly ArrayTransform[], where: string): void {
        for (const t of transforms) {
            if (t.kind === ArrayTransformKind.Filter) {
                const decomposed = decomposePredicateCombinationFormula(t.predicate)?.spec;
                if (decomposed === undefined) {
                    issues.push(`Cannot decompose filter for ${where}`);
                }
            } else if (t.kind === ArrayTransformKind.Sort) {
                for (const k of t.keys) {
                    if (k.key.kind !== FormulaKind.GetColumn) {
                        issues.push(`Sort key is invalid in ${where}`);
                    }
                }
            }
        }
    }

    function checkAction({ fromScreenName, pushedScreenName, fetchesData }: PushActionData): void {
        if (fromScreenName !== undefined && pushedScreenName === fromScreenName) {
            issues.push(`Link to screen links to the same screen ${fromScreenName}`);
            return;
        }

        const screen = app.screenDescriptions[pushedScreenName];
        if (screen === undefined) {
            issues.push(`Missing screen ${pushedScreenName} for Show New Screen in ${fromScreenName}`);
            return;
        }
        if (screen.kind !== ScreenDescriptionKind.Class && screen.kind !== ScreenDescriptionKind.Array) {
            issues.push(`Link to screen ${pushedScreenName} is not class or array screen`);
            return;
        }

        if (isSaved) {
            if (truthify(screen.fetchesData) !== fetchesData) {
                issues.push(`Link to screen ${pushedScreenName} does not correctly fetch data`);
                return;
            }
        }
    }

    const counts = getTableNameCounts(getTabScreenCounts(app));
    for (const [n, c] of Array.from(counts)) {
        if (c > 1) {
            issues.push(`More than one tab with table ${n}`);
        }
    }
    for (const screenName of Object.keys(app.screenDescriptions)) {
        if (screenName === userProfileScreenName) continue;

        if (strict && isPrimitiveScreenName(screenName)) {
            issues.push(`Primitive screen still exists ${screenName}`);
        }

        const screen = app.screenDescriptions[screenName];
        const tableName = getClassOrArrayScreenTableName(screenName);

        if (isFormScreenName(screenName)) {
            if (screen.kind !== ScreenDescriptionKind.Class) {
                issues.push(`Form screen is not a class screen ${screenName}`);
            } else {
                const formScreen = screen as FormScreenDescription;
                if (screen.isForm !== true) {
                    issues.push(`Form screen doesn't have form flag set ${screenName}`);
                }
                if (appKind === AppKind.App && screen.title !== undefined) {
                    // ##formScreensHaveNoTitle:
                    // Form screen titles in apps are configured in the
                    // action, not in the screen, but in Pages it's the other
                    // way around.
                    issues.push(`Form screen has a title ${screenName}`);
                }
                if (formScreen.onSubmitActions !== undefined) {
                    if (!isArray(formScreen.onSubmitActions)) {
                        issues.push(`Form screen has no actions ${screenName}`);
                    } else if (formScreen.onSubmitActions.some(a => (a ?? undefined) === undefined)) {
                        issues.push(`Form screen has undefined actions ${screenName}`);
                    }
                }
            }
        } else {
            if (screen.kind === ScreenDescriptionKind.Class) {
                if (screen.isForm === true) {
                    issues.push(`Non-form class screen has form flag set ${screenName}`);
                }
                if (isFreeScreen(screenName, screen)) {
                    if (appKind === AppKind.App && screen.title !== undefined) {
                        issues.push(`Free screen has a title ${screenName}`);
                    }
                }
            }
        }

        const addOrEditClassScreenTableName =
            getAddClassScreenTableName(screenName) ?? getEditClassScreenTableName(screenName);
        if (addOrEditClassScreenTableName !== undefined) {
            if (screen.kind !== ScreenDescriptionKind.Class) {
                issues.push(`Add or edit screen is not a class screen ${screenName}`);
                continue;
            }

            const screenTableName = getTableRefTableName(screen.type);
            if (
                !screenTableName.isSpecial &&
                !areTableNamesEqual(screenTableName, makeTableName(addOrEditClassScreenTableName))
            ) {
                issues.push(`Add or edit screen has the wrong type ${screenName}`);
            }
        }

        if (screen.kind === ScreenDescriptionKind.Array || screen.kind === ScreenDescriptionKind.Class) {
            if (isAddOrEditOrFormScreenDescription(screenName, screen)) {
                if (strict) {
                    if (screen.columns !== undefined) {
                        issues.push(`Screen still has columns ${screenName}`);
                    }
                }
            } else {
                if (isVariantScreenName(screenName)) {
                    // We don't care about variant screens
                } else if (screen.kind === ScreenDescriptionKind.Array && screen.type.kind !== "table-ref") {
                    // We don't care about primitive screens
                } else if (isFreeScreen(screenName, screen) && tableName !== undefined) {
                    issues.push(`Regular class/array screen fetches data ${screenName}`);
                }
            }
        }

        const classScreenTableName = getClassScreenTableName(screenName);
        if (classScreenTableName !== undefined) {
            if (screen.kind !== ScreenDescriptionKind.Class) {
                issues.push(`Screen named as class screen is not a class screen ${screenName}`);
                continue;
            }
            if (isFreeScreen(screenName, screen)) {
                issues.push(`Class screen cannot fetch data ${screenName}`);
            }

            const screenTableName = getTableRefTableName(screen.type);
            if (
                !screenTableName.isSpecial &&
                !areTableNamesEqual(screenTableName, makeTableName(classScreenTableName))
            ) {
                issues.push(`Class screen has the wrong type ${screenName}`);
            }
        }

        if (getArrayScreenTableName(screenName) !== undefined) {
            if (screen.kind !== ScreenDescriptionKind.Array) {
                issues.push(`Screen named as array screen is not an array screen ${screenName}`);
                continue;
            }
            if (isFreeScreen(screenName, screen)) {
                issues.push(`Array screen cannot fetch data ${screenName}`);
            }
        }

        if (screen.kind === ScreenDescriptionKind.Class || screen.kind === ScreenDescriptionKind.Array) {
            if (strict) {
                if (screen.filter !== undefined) {
                    issues.push(`Array screen still has filter set ${screenName}`);
                }
            }
            for (const c of getScreenComponentsArray(screen)) {
                if (c.kind === undefined) {
                    issues.push(`Screen has components without kind ${screenName}`);
                    break;
                }
                if (isInlineListComponentDescription(c)) {
                    if (strict) {
                        if (c.reverse !== undefined) {
                            issues.push(`Inline list still has reverse set in screen ${screenName}`);
                        }
                        if (c.filter !== undefined) {
                            issues.push(`Inline list still has filter set in screen ${screenName}`);
                        }
                    }

                    checkArrayTransforms(getArrayTransforms(c), `inline list in screen ${screenName}`);
                }
            }
        }
        if (screen.kind === ScreenDescriptionKind.Class) {
            if (screen.components === undefined) {
                issues.push(`Class screen has no components array ${screenName}`);
            }
        }
        if (screen.kind === ScreenDescriptionKind.Array) {
            if (strict) {
                if (screen.reverse !== undefined) {
                    issues.push(`Array screen still has reverse set ${screenName}`);
                }
            }

            checkArrayTransforms(getArrayTransforms(screen), `screen ${screenName}`);
        }
    }

    const ccc = makeSimpleComponentConfiguratorContext(
        "DUMMY",
        app,
        new Map(),
        schema ?? makeTypeSchema([]),
        defaultUserFeatures,
        eminenceFull
    );

    const visitor = new CheckActionVisitor();
    walkAppDescription(ccc, visitor, [], {
        includeHiddenTabs: true,
        includeDefaultScreens: true,
        cache: checkActionCache,
    });
    for (const action of visitor.state) {
        checkAction(action);
    }

    for (const tab of app.tabs ?? []) {
        const screenName = getScreenProperty(tab.screenName);
        if (screenName === undefined) {
            issues.push(`No screen name for tab`);
            continue;
        }
        const screen = app.screenDescriptions[screenName];
        if (screen === undefined) {
            issues.push(`No screen for tab ${screenName}`);
            continue;
        }
        if (tab.propertyName !== undefined) {
            issues.push(`Tab has a property set for screen ${screenName}`);
        }

        if (screen.kind === ScreenDescriptionKind.Array || screen.kind === ScreenDescriptionKind.Class) {
            if (!isFreeScreen(screenName, screen)) {
                issues.push(`Tab screen is not free screen ${screenName}`);
            } else if (screen.fetchesData !== true) {
                issues.push(`Tab screen does not fetch data ${screenName}`);
            }
        }

        checkArrayTransforms(tab.visibilityFilters ?? [], `tab ${tab.screenName}`);
    }

    if (schema !== undefined) {
        for (const table of schema.tables) {
            const tableName = getTableName(table);
            if (tableName.isSpecial) continue;

            const tableRef = makeTableRef(table);
            if (app.screenDescriptions[arrayScreenName(ccc, tableRef)] === undefined) {
                issues.push(`Array screen missing ${tableName.name}`);
            }
            if (app.screenDescriptions[classScreenName(tableRef)] === undefined) {
                issues.push(`Class screen missing ${tableName.name}`);
            }
            if (app.screenDescriptions[editClassScreenName(tableRef)] === undefined) {
                issues.push(`Edit class screen missing ${tableName.name}`);
            }
            if (app.screenDescriptions[addClassScreenName(tableRef)] === undefined) {
                issues.push(`Add class screen missing ${tableName.name}`);
            }
        }
    }

    if (issues.length === 0) return undefined;
    return issues;
}

export function checkSchemaFormulas(schema: TypeSchema): readonly string[] | undefined {
    const issues: string[] = [];

    for (const table of schema.tables) {
        const tableName = getTableName(table);
        for (const column of table.columns) {
            if (!isComputedColumn(column)) continue;

            const columnSpec = decomposeAll(column.formula);
            if (columnSpec === undefined) {
                issues.push(
                    `Cannot decompose computed column formula in table ${tableName.name} column ${column.name}`
                );
            }

            if (column.displayFormula !== undefined) {
                const formatSpec = decomposeFormatFormula(column.displayFormula);
                if (formatSpec === undefined) {
                    issues.push(
                        `Cannot decompose computed column display formula in table ${tableName.name} column ${column.name}`
                    );
                }
            }
        }
    }

    if (issues.length === 0) return undefined;
    return issues;
}

function replaceFilterArrayTransform(
    transforms: readonly ArrayTransform[],
    predicate: Formula
): readonly ArrayTransform[] {
    return [
        ...transforms.filter(t => t.kind !== ArrayTransformKind.Filter),
        { kind: ArrayTransformKind.Filter, predicate, isActive: true },
    ];
}

function convertTransforms<T extends TransformableContentDescription>(
    desc: T,
    log: (...args: readonly any[]) => void
): T {
    const filter = getEmailArrayFilter(desc);
    if (filter === undefined) return desc;

    const { value } = filter;
    if (value.kind !== PropertyKind.Column || typeof value.value !== "string") {
        log("Email filter value is not a column, but is", value.kind);
        return { ...desc, filter: undefined };
    }
    const column = value.value;

    let filterTransforms = getArrayTransforms(desc).filter(
        t => t.kind === ArrayTransformKind.Filter
    ) as FilterArrayTransform[];
    if (filterTransforms.length > 1) {
        return panic(`More than one array filter`);
    }

    if (filterTransforms.length === 1 && filterTransforms[0].isActive === false) {
        filterTransforms = [];
    }

    let predicates: UnaryPredicateSpecification[] = [
        {
            kind: "unary",
            column: makeSourceColumn(column),
            operator: UnaryPredicateCompositeOperator.MatchesVerifiedEmailAddress,
        },
        {
            kind: "unary",
            column: makeSourceColumn(column),
            operator: UnaryPredicateCompositeOperator.IsEmpty,
        },
    ];

    let spec: PredicateCombinationSpecification;
    if (filterTransforms.length === 1) {
        const maybeSpec = decomposePredicateCombinationFormula(filterTransforms[0].predicate)?.spec;
        if (maybeSpec === undefined) {
            logError(`Could not decompose filter predicate`);
            return desc;
        }
        if (maybeSpec.combinator !== "and" && maybeSpec.predicates.length > 1) {
            logError("We have more than one predicates that are ored");

            // In this case we cannot represent the filter condition, so we leave
            // the existing email filter as-is.
            return desc;
        }

        // We can't add the or of both our conditions, so we just use the first
        // one, which is conservative.
        predicates = predicates.slice(0, 1);
        spec = { ...maybeSpec, combinator: "and" };
    } else {
        spec = { kind: "predicate-combination", combinator: "or", predicates: [] };
    }

    spec = { ...spec, predicates: [...spec.predicates, ...predicates] };
    const predicate = makePredicateCombinationFormula(spec);

    const transforms = replaceFilterArrayTransform(getArrayTransforms(desc), predicate);
    return { ...desc, filter: undefined, transforms };
}

function fixActions(actionsRecord: ActionsRecord): ActionsRecord | undefined {
    let didChange = false;
    const newActionsRecord = mapActionsRecord(actionsRecord, actions => {
        const newActions: ActionDescription[] = [];
        for (const action of actions) {
            const actionHandler = handlerForActionKind(action.kind);
            const newAction = actionHandler.fixActionDescription(action);
            if (newAction !== action) {
                newActions.push(newAction);
                didChange = true;
            } else {
                newActions.push(action);
            }
        }
        return newActions;
    });

    if (!didChange) return undefined;
    return newActionsRecord;
}

function convertArrayContent(
    adc: AppDescriptionContext,
    format: ArrayScreenFormat,
    content: ArrayContentDescription,
    convertFeatures: boolean,
    log: (...args: readonly any[]) => void
): { properties: ArrayContentDescription; format: ArrayScreenFormat } | undefined {
    let didChange = false;

    const fixedTransforms = convertTransforms(content, log);
    if (fixedTransforms !== content) {
        content = fixedTransforms;
        didChange = true;
    }

    if (!convertFeatures) {
        if (didChange) {
            return { properties: content, format };
        } else {
            return undefined;
        }
    }

    if ((content.transforms ?? []).length === 0 && truthify(getSwitchProperty(content.reverse))) {
        content = { ...content, transforms: [{ kind: ArrayTransformKind.TableOrder, reverse: true }] };
        didChange = true;
    }
    if (content.reverse !== undefined) {
        content = { ...content, reverse: undefined };
        didChange = true;
    }

    const handler = handlerForArrayScreenFormat(format);
    const actions = definedMap(handler, h => fixActions(getActionsForArrayContent(h, undefined, content, adc)));
    const fixedContent = handler?.fixContentDescription(content);
    if (fixedContent !== undefined) {
        if (actions === undefined) {
            return fixedContent;
        } else {
            return { properties: { ...fixedContent.properties, ...actions }, format: fixedContent.format };
        }
    } else {
        if (didChange) {
            if (actions !== undefined) {
                content = { ...content, ...actions };
            }
            return { properties: content, format };
        }
        return undefined;
    }
}

function convertComponent(
    adc: AppDescriptionContext,
    desc: ComponentDescription,
    convertFeatures: boolean,
    log: (...args: readonly any[]) => void
): ComponentDescription | undefined {
    function withActions(d: ComponentDescription | undefined): ComponentDescription | undefined {
        if (!convertFeatures) return d;

        let actions: ActionsRecord | undefined;

        const componentHandler = handlerForComponentKind((d ?? desc).kind);
        if (componentHandler !== undefined) {
            actions = fixActions(getActionsForComponent(componentHandler, d ?? desc, undefined, undefined, undefined));
        }

        if (actions === undefined) return d;
        return { ...(d ?? desc), ...actions };
    }

    if (convertFeatures) {
        if (desc.kind === ComponentKindChoice) {
            return ChoiceComponentHandler.convertToNewChoice(desc);
        }

        if (desc.kind === ComponentKindButton) {
            return ButtonComponentHandler.convertToNewActions(desc);
        }
    }

    if (!isInlineListComponentDescription(desc)) return withActions(undefined);

    const format = getEnumProperty<ArrayScreenFormat>(desc.format);
    if (format === undefined) return withActions(undefined);

    const converted = convertArrayContent(adc, format, desc, convertFeatures, log);
    if (converted === undefined) return withActions(undefined);

    const fixed: InlineListComponentDescription = {
        ...desc,
        ...converted.properties,
        format: makeEnumProperty(converted.format),
    };

    return withActions(fixed);
}

function fixColumnAssignments(screen: EditScreenDescription): EditScreenDescription {
    if (screen.columns === undefined) return screen;
    const columnAssignments = getColumnAssignmentsWithLegacy(screen);
    return { ...screen, columns: undefined, columnAssignments };
}

function fixOnSubmitActions(screen: EditScreenDescription): EditScreenDescription {
    // `isFormScreen` is just here to make Typescript happy
    if (screen.onSubmitActions === undefined) return screen;

    if (!isArray(screen.onSubmitActions)) {
        return { ...screen, onSubmitActions: [] };
    } else if (screen.onSubmitActions.some(a => (a ?? undefined) === undefined)) {
        return {
            ...screen,
            onSubmitActions: screen.onSubmitActions.filter(a => (a ?? undefined) !== undefined),
        };
    } else {
        return screen;
    }
}

function getTabScreenCounts(appDescription: AppDescription): DefaultMap<string, number> {
    const screenCounts = new DefaultMap<string, number>(() => 0);
    for (const tab of getAppTabs(appDescription)) {
        const screenName = getScreenProperty(tab.screenName);
        if (screenName === undefined) continue;

        screenCounts.update(screenName, n => n + 1);
    }
    return screenCounts;
}

function getTableNameCounts(tabScreenCounts: Map<string, number>): DefaultMap<string, number> {
    const nameCounts = new DefaultMap<string, number>(() => 0);
    for (const [screenName, count] of tabScreenCounts) {
        const tableName = getClassOrArrayScreenTableName(screenName);
        if (tableName === undefined) continue;

        nameCounts.update(tableName, n => n + count);
    }
    return nameCounts;
}

// Given a component from NCM Apps, returns a "closest" aproximation for Pages.
function maybeMigrateComponentFromAppsToUnified(c: ComponentDescription): ComponentDescription {
    if (c.kind === ComponentKindVideo) {
        const videoDesc = c as VideoPlayerComponentDescription;

        return {
            kind: WireComponentKind.SimpleVideo,
            builderDisplayName: c.builderDisplayName,
            componentID: c.componentID,
            visibilityFilters: c.visibilityFilters,
            video: videoDesc.urlProperty,
            aspectRatio: makeEnumProperty(UIAspect.FourByThree),
        } as ComponentDescription;
    }

    return c;
}

interface FixAppDescriptionOptions {
    readonly convertFeatures?: boolean;
    readonly doLog?: boolean;
    readonly fromBuilder: boolean;
    readonly removeBuilderOnlyProperties?: boolean;
}

// FIXME: There's a bunch of stuff in here that we shouldn't carry around in
// perpetuity.  We should instead just run this function on all existing apps
// and then remove the stuff in here that's not necessary from then on.
//
// One thing in here that is necessary is the assignment of component IDs.  We
// used to have random component IDs, which turned out to consume a large
// proportion of the saved app description, because everything else compressed
// so well.  What we now do instead is to remove them before we save and make
// new ones, in this function, when we load the app.  One complication is that
// the component IDs need to be stable because NCM uses them to key component
// state, and we want to persist some of that state.  They also need to be
// unique inside the app because otherwise components might share state with
// other components.
export function fixAppDescription(
    appID: string,
    appDescription: AppDescription,
    builderActions: BuilderActionsForApp,
    schema: TypeSchema,
    appEminenceFlags: EminenceFlags,
    { convertFeatures = true, doLog = true, fromBuilder, removeBuilderOnlyProperties = false }: FixAppDescriptionOptions
): AppDescription {
    function log(...args: readonly any[]): void {
        if (!doLog) return;
        logError(...args);
    }

    const ccc = makeSimpleComponentConfiguratorContext(
        appID,
        appDescription,
        builderActions,
        schema,
        defaultUserFeatures,
        appEminenceFlags
    );
    const isPage = getAppKind(appDescription) === AppKind.Page;

    const screenDescriptions = { ...appDescription.screenDescriptions };

    function makeFreeCopy(screenName: string, screen: ClassOrArrayScreenDescription): string {
        const copyName = makeScreenNameWithPrefix(freeScreenPrefix, n => screenDescriptions[n] !== undefined);
        screenDescriptions[copyName] = copyToFreeScreen(screenName, screen);
        return copyName;
    }

    if (isPage) {
        if (screenDescriptions[chatScreenName] !== undefined) {
            delete screenDescriptions[chatScreenName];
        }
        if (screenDescriptions[shoppingCartScreenName] !== undefined) {
            delete screenDescriptions[shoppingCartScreenName];
        }
    } else {
        if (screenDescriptions[chatScreenName] === undefined) {
            screenDescriptions[chatScreenName] = chatScreen;
        }
        if (screenDescriptions[shoppingCartScreenName] === undefined) {
            screenDescriptions[shoppingCartScreenName] = shoppingCartScreen;
        }
    }

    // FIXME: Do the same for the shopping cart.

    const screenNameReassignments = new Map<string, string>();

    for (let [name, desc] of toPairs(screenDescriptions)) {
        if (isPrimitiveScreenName(name)) {
            delete screenDescriptions[name];
            continue;
        }

        let fixed = desc;

        // Here we should be removing every property that's builder-only.
        // Sadly, we don't have descriptors for screen properties, so we just... ad-hoc remove the notes.
        if (removeBuilderOnlyProperties && fixed.kind === ScreenDescriptionKind.Class && fixed.notes !== undefined) {
            const { notes, ...rest } = fixed;
            fixed = rest;
        }

        // Components in screens with a missing table aren't really
        // configurable anymore, so we pick a default table.
        // Only do this for sceens that fetch their own data (see https://github.com/quicktype/glide/issues/16692)
        if (isClassOrArrayScreenDescription(fixed) && !isPrimitiveType(fixed.type) && fixed.fetchesData === true) {
            let table = ccc.findTable(fixed.type);
            if (table === undefined) {
                table = getDefaultTable(ccc.schema);
                if (table !== undefined) {
                    fixed = { ...fixed, type: makeTableRef(table) };
                }
            }
        }

        if (name === userProfileScreenName) {
            if (fixed.kind !== ScreenDescriptionKind.Class) {
                fixed = { ...fixed, kind: ScreenDescriptionKind.Class } as ScreenDescription;
            }

            assert(isClassOrArrayScreenDescription(fixed));
            const components = fixComponents(getScreenComponentsArray(fixed), `${name}-`);
            if (components !== undefined) {
                fixed = { ...fixed, components };
            }

            screenDescriptions[name] = fixed;
            continue;
        }

        if (isAddClassScreenName(name) || isEditClassScreenName(name) || isFormScreenName(name)) {
            if (fixed.kind !== ScreenDescriptionKind.Class) {
                fixed = { ...fixed, kind: ScreenDescriptionKind.Class } as ScreenDescription;
            }
        }

        if (isFormScreenName(name)) {
            assert(fixed.kind === ScreenDescriptionKind.Class);

            assert(fixed.isForm === true);
            // if (fixed.isForm !== true) {
            //     fixed = { ...fixed, isForm: true };
            // }

            if (!isPage && fixed.title !== undefined) {
                fixed = { ...fixed, title: undefined };
            }
        }

        function reassignToFree(screenDesc: ClassOrArrayScreenDescription): void {
            const freeName = makeFreeCopy(name, screenDesc);
            screenNameReassignments.set(name, freeName);
            fixed = desc = screenDescriptions[freeName];
            delete screenDescriptions[name];
            name = freeName;
        }

        function fixComponents(
            components: readonly ComponentDescription[],
            componentIDPrefix: string
        ): ComponentDescription[] | undefined {
            let changed = false;
            const countByKind = new DefaultMap<ComponentDescription["kind"], number>(() => 0);
            // let maxSpan = 0;

            const fixedComponents = mapFilterUndefined(components, c => {
                // This is not just for fixing "broken" apps, because when
                // we're saving apps we
                // ##removeComponentIDsFromAppDescription.
                if (c.componentID === undefined) {
                    // This is our attempt at giving stable component IDs. The
                    // new component model needs those to save component
                    // state.  This obviously won't be stable across app
                    // modifications, in particular when component on a screen
                    // are reordered, but that's probably fine for most
                    // circumstances.  We might want to think about
                    // reintroducing component IDs, but make them super short.
                    // They can just be an auto-incrementing number, for
                    // example.
                    const componentID = `${componentIDPrefix}${c.kind}-${countByKind.update(c.kind, n => n + 1)}`;
                    c = { ...c, componentID };
                    changed = true;
                }

                if (hasOwnProperty(c, "components") && isArray(c.components)) {
                    let cc = c as BaseContainerComponentDescription;
                    cc = {
                        ...cc,
                        components: fixComponents(defined(cc.components), `${c.componentID}-`) ?? cc.components,
                    };
                    c = cc;
                    changed = true;
                }

                if (removeBuilderOnlyProperties) {
                    const { component, changed: propertiesWereRemoved } = removeBuilderOnlyPropertiesFromComponent(
                        c,
                        ccc
                    );
                    c = component;
                    changed ||= propertiesWereRemoved;
                }

                if (c.kind === ComponentKindBuyButton && !appEminenceFlags.buyButton) {
                    changed = true;
                    return undefined;
                }

                const appFeatures = getAppFeatures(appDescription);
                if (appFeatures.useUnifiedApps ?? false) {
                    c = maybeMigrateComponentFromAppsToUnified(c);
                }

                if (c.kind === WireComponentKind.Hero && (c as any).buttons === undefined) {
                    if (hasOwnProperty(c, "actions") && getArrayProperty(c.actions as any) !== undefined) {
                        c = { ...c, actions: [], buttons: c.actions } as ComponentDescription;
                        changed = true;
                    }
                }

                if (
                    c.kind === ComponentKindInlineList &&
                    getEnumProperty((c as any).format) === ArrayScreenFormat.Kanban
                ) {
                    // `allowInlineAdding` and `allowInlineEditing` was once a
                    // single switch `inlineEditing`.
                    if (
                        (c as any).allowInlineAdding === undefined &&
                        (c as any).allowInlineEditing === undefined &&
                        (c as any).inlineEditing !== undefined
                    ) {
                        const allow = getSwitchProperty((c as any).inlineEditing) ?? false;
                        c = {
                            ...c,
                            allowInlineEditing: makeSwitchProperty(allow),
                            allowInlineAdding: makeSwitchProperty(allow),
                            inlineEditing: undefined,
                        } as ComponentDescription;
                    }
                }

                const f = convertComponent(ccc, c, convertFeatures, log);
                if (f === undefined) return c;
                changed = true;
                return f;
            });
            if (!changed) return undefined;
            return fixedComponents;
        }

        if (fixed.kind === ScreenDescriptionKind.Class) {
            if (getArrayScreenTableName(name) !== undefined && fixed.fetchesData === true) {
                reassignToFree(fixed);
            }

            const classScreenTableName = definedMap(
                getClassScreenTableName(name) ?? getAddClassScreenTableName(name) ?? getEditClassScreenTableName(name),
                makeTableName
            );
            const screenTableName = getTableRefTableName(fixed.type);
            if (
                !screenTableName.isSpecial &&
                classScreenTableName !== undefined &&
                !areTableNamesEqual(classScreenTableName, screenTableName)
            ) {
                fixed = { ...fixed, type: makeTableRef(classScreenTableName) };
            }

            if (classScreenTableName !== undefined && fixed.fetchesData === true) {
                fixed = { ...fixed, fetchesData: false };
            } else if (isFreeScreenName(name)) {
                if (!isPage && fixed.title !== undefined) {
                    fixed = { ...fixed, title: undefined };
                }
            }

            if (!isArray(fixed.components)) {
                fixed = { ...fixed, components: [] };
            }

            if (fixed.components.some(c => c.kind === undefined)) {
                fixed = { ...fixed, components: fixed.components.filter(c => c.kind !== undefined) };
            }

            if (convertFeatures) {
                fixed = convertTransforms(fixed, log);
            }
        } else if (fixed.kind === ScreenDescriptionKind.Array) {
            if (getClassScreenTableName(name) !== undefined && fixed.fetchesData === true) {
                reassignToFree(fixed);
            }

            if (getArrayScreenTableName(name) !== undefined && fixed.fetchesData === true) {
                fixed = { ...fixed, fetchesData: false };
            }

            const converted = convertArrayContent(ccc, fixed.format, fixed, convertFeatures, log);
            if (converted !== undefined) {
                fixed = { ...fixed, ...converted.properties, format: converted.format };
            }
        }

        if (fixed.kind === ScreenDescriptionKind.Class || fixed.kind === ScreenDescriptionKind.Array) {
            const components = fixComponents(getScreenComponentsArray(fixed), `${name}-`);
            if (components !== undefined) {
                fixed = { ...fixed, components };
            }
        }

        if (isAddOrEditOrFormScreenDescription(name, fixed)) {
            if (convertFeatures) {
                fixed = fixColumnAssignments(fixed);
            }

            fixed = fixOnSubmitActions(fixed as EditScreenDescription);

            if (isEditClassScreenName(name)) {
                if (fixed.kind !== ScreenDescriptionKind.Class) {
                    return panic("Edit screen is not class screen");
                }

                const addName = addClassScreenName(fixed.type);
                const addDesc = screenDescriptions[addName];
                // We have a bug where somehow an add screen can become an array screen.
                // I assume there's some weird state the builder can get into where it
                // allows that.  We just wipe that screen and replace it with a copy of
                // the edit screen.
                if (addDesc === undefined || addDesc.kind !== ScreenDescriptionKind.Class) {
                    screenDescriptions[addName] = fixed;
                }
            }
        }

        if (fixed !== desc) {
            screenDescriptions[name] = fixed;
        }
    }

    for (const table of schema.tables) {
        if (getTableName(table).isSpecial) continue;

        const tableRef = makeTableRef(table);

        const screenName = classScreenName(tableRef);
        let classDesc = screenDescriptions[screenName];
        if (classDesc === undefined || classDesc.kind !== ScreenDescriptionKind.Class) {
            classDesc = makeDefaultClassScreen(table, ccc, false, false, false);
            screenDescriptions[screenName] = classDesc;
        }

        const arrayName = arrayScreenName(ccc, tableRef);
        let arrayDesc = screenDescriptions[arrayName];
        if (arrayDesc === undefined || arrayDesc.kind !== ScreenDescriptionKind.Array) {
            arrayDesc = makeArrayScreenDescription(getDefaultArrayScreenHandler(), tableRef, ccc, false, false);
            screenDescriptions[arrayName] = arrayDesc;
        }

        const editName = editClassScreenName(tableRef);
        let editDesc = screenDescriptions[editName];
        if (editDesc === undefined || editDesc.kind !== ScreenDescriptionKind.Class) {
            editDesc = makeAddOrEditScreenDescriptionForTable(table, ccc.appKind, true, false, undefined, ccc);
            screenDescriptions[editName] = editDesc;
        }

        const addName = addClassScreenName(tableRef);
        const addDesc = screenDescriptions[addName];
        if (addDesc === undefined || addDesc.kind !== ScreenDescriptionKind.Class) {
            screenDescriptions[addName] = editDesc;
        }
    }

    const screenCounts = getTabScreenCounts(appDescription);
    const tableNameCounts = getTableNameCounts(screenCounts);

    const tabs1: TabDescription[] = [];
    const slugsUsed = new Set<string>();
    for (let tab of getAppTabs(appDescription)) {
        let screenName = getScreenProperty(tab.screenName);
        if (screenName === undefined) {
            log("No screen name for tab");
            continue;
        }

        const reassignedName = screenNameReassignments.get(screenName);
        if (reassignedName !== undefined) {
            tab = { ...tab, screenName: makeScreenProperty(reassignedName) };
            screenName = reassignedName;
        }

        const screen: ScreenDescription | undefined = screenDescriptions[screenName];
        if (screen === undefined) {
            log("Screen not found", screenName);
            continue;
        }

        if (tab.slug !== undefined) {
            if (slugsUsed.has(tab.slug)) {
                tab = { ...tab, slug: undefined };
            } else {
                slugsUsed.add(tab.slug);
            }
        }

        const tableName = getClassOrArrayScreenTableName(screenName);

        const isFree = isFreeScreen(screenName, screen);
        if (
            tab.propertyName === undefined &&
            screenName !== chatScreenName &&
            screenName !== shoppingCartScreenName &&
            !isFree
        ) {
            if (tab.title === "Chat") {
                log("Chat screen misconfigured");
                tabs1.push({ ...tab, screenName: chatScreenName });
            } else if (tableName !== undefined) {
                if (
                    (screen as ScreenDescription).kind !== ScreenDescriptionKind.Array &&
                    (screen as ScreenDescription).kind !== ScreenDescriptionKind.Class
                ) {
                    log("Screen named class or array screen is not actually one");

                    tabs1.push(tab);
                } else if (tableNameCounts.get(tableName) === 0) {
                    log("No property on class/array screen - setting the property");

                    tabs1.push({ ...tab, propertyName: tableName });
                    tableNameCounts.update(tableName, i => i + 1);
                } else {
                    log("No property on class/array screen - copying to a free screen");

                    const copyName = makeFreeCopy(screenName, screen as unknown as ClassOrArrayScreenDescription);
                    tabs1.push({ ...tab, screenName: makeScreenProperty(copyName), propertyName: undefined });
                }
            } else {
                log("Unknown screen without property", screenName);
                tabs1.push(tab);
            }
            continue;
        } else if (tab.propertyName !== undefined && isFree) {
            log("Free screen has propertyName set in tab");
            tabs1.push({ ...tab, propertyName: undefined });
            continue;
        }

        if (tableName !== undefined && tableNameCounts.get(tableName) > 1) {
            log("Duplicate screen for table", screenName, tableName);
            if (tab.propertyName === undefined) {
                log("No property on duplicate screen");
                tabs1.push(tab);
                continue;
            }

            const table = findTable(schema, makeTableName(tab.propertyName));
            if (table === undefined) {
                log("Table for property not found", tab.propertyName);
                tabs1.push(tab);
                continue;
            }

            let newScreenName: string;
            if (getClassScreenTableName(screenName) !== undefined) {
                newScreenName = classScreenName(getTableName(table));
            } else if (getArrayScreenTableName(screenName) !== undefined) {
                newScreenName = arrayScreenName(ccc, makeTableRef(table));
            } else {
                log("Neither array nor class screen");
                tabs1.push(tab);
                continue;
            }

            if (appDescription.screenDescriptions[newScreenName] === undefined) {
                log("Correct screen does not exist", newScreenName);
                tabs1.push(tab);
                continue;
            }

            log("rewriting tab", screenName, newScreenName);

            tabs1.push({ ...tab, screenName: makeScreenProperty(newScreenName) });
            continue;
        }

        tabs1.push(tab);
    }

    const tabs2: TabDescription[] = [];
    for (const tab of tabs1) {
        const screenName = defined(getScreenProperty(tab.screenName));
        const screen = defined(screenDescriptions[screenName]);

        if (screen.kind === ScreenDescriptionKind.Array || screen.kind === ScreenDescriptionKind.Class) {
            if (!isFreeScreen(screenName, screen)) {
                log("non-free top-level screen");
                const copyName = makeFreeCopy(screenName, screen);
                tabs2.push({ ...tab, propertyName: undefined, screenName: copyName });
                continue;
            }
        }

        tabs2.push(tab);
    }

    const tabs3 = addOrRemoveSpecialTabsIfNecessary(tabs2, isPage);

    for (const [name, screen] of toPairs(screenDescriptions)) {
        if (screen.kind === ScreenDescriptionKind.Array || screen.kind === ScreenDescriptionKind.Class) {
            if (isFreeScreenName(name)) continue;

            let fixed = { ...screen, filter: undefined, transforms: undefined };
            if (fixed.kind === ScreenDescriptionKind.Array) {
                fixed = { ...fixed, reverse: undefined };
            }

            screenDescriptions[name] = fixed;
        }
    }

    // Return whether the action was handled
    function fixLinkToScreen(
        action: ActionDescription,
        fromScreenName: string,
        screens: Writable<Record<string, Writable<ScreenDescription>>>
    ): boolean {
        if (action.kind !== ActionKind.PushFreeScreen) return false;

        const pushAction = action as PushFreeScreenActionDescription;
        const pushedScreenName = pushAction.screenName;
        if (pushedScreenName !== fromScreenName) {
            const screen = screens[pushedScreenName];
            if (screen?.kind === ScreenDescriptionKind.Class || screen?.kind === ScreenDescriptionKind.Array) {
                const fetchesData = doesLinkToScreenFetchData(pushAction.sourceColumn);
                if (truthify(screen.fetchesData) !== fetchesData) {
                    screen.fetchesData = fetchesData;
                }
                return true;
            }
        }

        // We can't fix this action, so we replace it with a failure toast.
        // Removing the action is actually quite involved, since we have to
        // change the array the action was in, and that can come from a variety
        // of different sources, so we do this right now.
        Object.assign(action, makeShowToastAction(undefined, ToastIcon.Failure));

        return true;
    }

    let fixedAppDescription = { ...appDescription, screenDescriptions, tabs: tabs3 };

    // `fixLinkToScreen` still needs to run in the builder.
    if (fromBuilder || getAppFeatures(fixedAppDescription).noLegacyActions !== true) {
        fixedAppDescription = produce(fixedAppDescription, draftApp => {
            const draftADC = makeSimpleComponentConfiguratorContext(
                appID,
                draftApp,
                ccc.builderActions,
                ccc.schema,
                ccc.userFeatures,
                ccc.eminenceFlags
            );
            forEachActionInApp(draftADC, (action, location) => {
                const screenName = getScreenNameForLocation(location);
                if (screenName !== undefined) {
                    // FIXME: Do this via `fixActionDescrioption`.
                    if (fixLinkToScreen(action, screenName, draftApp.screenDescriptions)) return;
                }

                // not a problem
                const handler = handlerForActionKind(action.kind);
                // We don't delete it, because we might mess up an app that
                // was made a future build.
                if (handler === undefined) return;
                handler.fixActionDescription(action);
            });
            draftApp.features = { ...getAppFeatures(draftApp), noLegacyActions: true };
        });
    }

    // This is a bad hack for a fundamental issue we currently have: The
    // frontend is responsible for updating the builder app, and the backend
    // is responsible for updating the schema.  Adding a native table requires
    // both, where the frontend makes a call to the backend, the backend adds
    // the native table to the schema, returns the native table ID to the
    // frontend, then the frontend adds it in the builder app.  If the
    // frontend fails to do that, however, we end up with missing source
    // metadata, which trips up `getSourceMetadataForTable`.  This just adds
    // that source metadata if it's missing, but it depends on the native
    // table name to correctly identify its ID, which it might not always:
    // when we copy an app with a native table, we copy the native table, so
    // it gets a new ID, but we keep the original name.  This is not great.
    //
    // There are the cases of "drift" that can occur and which this handles:
    //
    // * We added a Glide Table, but failed to save the builder app just
    //   afterwards.
    //
    // * The Glide Table isn't present in the schema, but is present in the
    //   sourceMetadata. One of the symptoms of this is that you permanently
    //   cannot re-add a Glide Table to an app once you unlink it if your
    //   network fails in the middle of the process. So to correct for that we
    //   clear out the Glide Tables that aren't present in the schema.
    //
    // * The external source in the metadata changed because
    //   ##nativeTablesCanGetDisconnected.
    const originalSourceMetadata = getSourceMetadata(fixedAppDescription);
    const fixedMetadata: SourceMetadata[] = [];
    for (const t of schema.tables) {
        const tableName = getTableName(t);
        if (tableName.isSpecial) continue;

        let original: SourceMetadata | undefined;
        try {
            original = getSourceMetadataForTable(originalSourceMetadata, t);
        } catch {
            // nothing to do
        }

        if (original !== undefined && original.type !== "Native table") continue;

        let sm: SourceMetadata;
        if (original !== undefined) {
            if (t.sourceMetadata?.type === "Native table") {
                // The work on queryable data sources produced source metadata
                // for native tables with empty strings as IDs, which are
                // wrong.
                if (original.id === "") continue;

                sm = {
                    ...t.sourceMetadata,
                    id: original.id,
                    tableName: original.tableName,
                };
            } else {
                sm = original;
            }
        } else if (t.sourceMetadata !== undefined) {
            sm = t.sourceMetadata;
        } else {
            const nativeTableID = getNativeTableIDFromTableName(tableName);
            if (nativeTableID === undefined) continue;
            sm = { type: "Native table", id: nativeTableID, tableName };
        }

        fixedMetadata.push(sm);
    }

    for (const sm of originalSourceMetadata) {
        if (sm.type === "Native table") continue;

        fixedMetadata.push(sm);
    }

    fixedAppDescription = {
        ...fixedAppDescription,
        sourceMetadataArray: fixedMetadata,
    };

    // We don't check strict after fixing, because it also checks for converting
    // features and email filters, which we might not have fixed.
    const issues = checkAppDescriptionConsistency(fixedAppDescription, undefined, true);
    if (issues !== undefined) {
        log("App is still inconsistent", issues);
    }

    return fixedAppDescription;
}

interface ComponentWithChangedStatus {
    component: ComponentDescription;
    changed: boolean;
}

function removeBuilderOnlyPropertiesFromComponent(
    desc: ComponentDescription,
    ccc: AppDescriptionContext
): ComponentWithChangedStatus {
    const fixed = { ...desc };
    let changed = false;

    const componentHandler = handlerForComponentKind(fixed.kind);
    if (componentHandler === undefined) return { component: fixed, changed };

    const { properties } = componentHandler.getDescriptor(
        fixed,
        undefined,
        ccc,
        undefined,
        false,
        false,
        undefined,
        undefined
    );
    for (const descriptor of properties) {
        if (!isNamedPropertySource(descriptor.property)) continue;

        if (descriptor.builderOnly !== true) continue;

        const propertyName = descriptor.property.name;
        delete (fixed as any)[propertyName];
        changed = true;
    }

    return { component: fixed, changed };
}
