import { getGenerativeColor } from "@glide/common";
import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import "twin.macro";
import { useIsDarkTheme } from "../../hooks/use-builder-theme";

interface Props extends React.PropsWithChildren {
    loginLogs: any[];
}

export const UsageGraph: React.VFC<Props> = p => {
    const { loginLogs } = p;
    const isDark = useIsDarkTheme();

    const keys = React.useMemo(() => {
        const k = Object.getOwnPropertyNames(loginLogs[0]).filter(x => x !== "dateTime");
        return k;
    }, [loginLogs]);

    return (
        <div tw="text-builder-base">
            <ResponsiveContainer width="100%" height={350}>
                <LineChart
                    width={500}
                    height={200}
                    data={loginLogs}
                    margin={{
                        top: 12,
                        right: 0,
                        left: -22,
                        bottom: 0,
                    }}>
                    <CartesianGrid horizontal={false} strokeDasharray="3 3" />
                    <XAxis fontSize={"13px"} tickLine={false} dataKey="dateTime" />
                    <YAxis fontSize={"13px"} axisLine={false} tickLine={false} />
                    <Tooltip animationDuration={200} position={{ y: 0 }} />
                    <Legend iconType={"circle"} fontSize={"13px"} fontSizeAdjust="13px" />
                    {keys.map((k, i) => (
                        <Line
                            animationDuration={400}
                            key={k}
                            type="monotone"
                            dataKey={k}
                            stroke={getGenerativeColor("#887ef9", i, isDark)}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};
