import * as React from "react";
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    onClickOutside: (event: MouseEvent | KeyboardEvent) => void;
    ignoredTargetClassNames?: string[];
    stopPropagation?: boolean;
}

export class ClickOutsideContainer extends React.PureComponent<Props> {
    private wrapperRef = React.createRef<any>();

    public componentDidMount() {
        document.addEventListener(this.props.stopPropagation === true ? "click" : "mousedown", this.clickOutside, true);
        document.addEventListener("contextmenu", this.clickOutside, true);
        document.addEventListener("keydown", this.handleKeyDown, true);
    }

    public componentWillUnmount() {
        document.removeEventListener(
            this.props.stopPropagation === true ? "click" : "mousedown",
            this.clickOutside,
            true
        );
        document.removeEventListener("contextmenu", this.clickOutside, true);
        document.removeEventListener("keydown", this.handleKeyDown, true);
    }

    private handleKeyDown = (event: KeyboardEvent): void => {
        if (event.key === "Escape") {
            this.props.onClickOutside(event);
        }
    };

    private clickOutside = (event: MouseEvent) => {
        const { ignoredTargetClassNames = [] } = this.props;
        const ignoredClasses = ["click-outside-ignore", ...ignoredTargetClassNames];
        if (this.wrapperRef.current !== null && !this.wrapperRef.current.contains(event.target)) {
            let node = event.target as Element | null;
            // bubble up the dom looking for ignored class names
            while (node !== null) {
                if (ignoredClasses.filter(ignored => node?.classList.contains(ignored)).length > 0) {
                    return;
                }
                node = node.parentElement;
            }
            if (this.props.stopPropagation === true) {
                event.stopPropagation();
                event.preventDefault();
            }
            this.props.onClickOutside(event);
        }
    };

    public render(): React.ReactNode {
        const { onClickOutside, stopPropagation, ignoredTargetClassNames, ...rest } = this.props;
        return (
            <div {...rest} ref={this.wrapperRef}>
                {this.props.children}
            </div>
        );
    }
}
