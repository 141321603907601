import type { OAuthProvider } from "@glide/plugins";
import { getAuthPlugin, pluginOAuthStage1 } from "@glide/plugins-utils";
import { logDebug } from "@glide/support";

import { getFeatureSetting } from "./feature-settings";
import { getLocation, getLocationSettings } from "./location";

interface PluginOauthStageOneParams {
    authProvider: OAuthProvider;
    explicitScopes?: readonly string[];
    ownerID: string;
    appID: string | undefined;
    pluginID: string | undefined;
    configID: string | undefined;
    // In certain circumstances, we already know what credential we _wanted_,
    // and need to verify that the credential we got was the credential we
    // actually wanted. In order to remember which credential we actually
    // wanted, and also perform the OAuth redirect in the same window, we
    // save this information as part of OAuth Stage 1 in local storage.
    //
    // (It's unclear whether session storage could safely be used for this,
    // the documentation does not guarantee that a session persists between
    // redirecting to an entirely different origin)
    expectedProviderID?: string;
    expectedCredentialID?: string;
    // If this is set to `false`, we perform the OAuth redirect within
    // the same window. This is generally only used to recover existing
    // OAuth credentials.
    openInNewWindow?: boolean;
}

export async function performOAuthStageOne({
    authProvider,
    explicitScopes,
    ownerID,
    appID,
    pluginID,
    configID,
    expectedCredentialID,
    expectedProviderID,
    openInNewWindow,
}: PluginOauthStageOneParams) {
    const forOwner = appID === undefined || appID.trim().length === 0;

    const location = getLocation();
    const locationSettings = getLocationSettings();

    let urlPrefix = locationSettings.urlPrefix;
    const authPlugin = getAuthPlugin(authProvider);

    const isLocalhost = locationSettings.isDevelopment && window.location.hostname === "localhost";
    const forceDeployedRedirect = authPlugin.forceStage2RedirectThroughDeployed === true && isLocalhost;

    if (forceDeployedRedirect && getFeatureSetting("forceRedirectThroughDeployedInPluginOAuth")) {
        logDebug(`Resetting URL prefix to deployed`, locationSettings.deployedURLPrefix);
        urlPrefix = locationSettings.deployedURLPrefix;
    } else if (isLocalhost && authPlugin.needsRedirectHackForLocalhost === true) {
        urlPrefix = "https://localhostr.net/3000/";
    } else if (isLocalhost && location === "local") {
        urlPrefix = "http://localhost:3000/"; // Override for plugin playground env
    }

    const { mandatoryScopes, proofKeyChallengeMethod: proofKeyMethod, proofKeySize } = authPlugin.stage1;

    const clientID = locationSettings.oauthClientIDs[authProvider];
    await pluginOAuthStage1(
        {
            provider: authProvider,
            scopes: explicitScopes ?? mandatoryScopes ?? [],
        },
        {
            clientID,
            redirectURL: `${urlPrefix}authorizeplugin/${authProvider}${
                forOwner && authPlugin.suffixForOwner === true ? "/for-owner" : ""
            }`,
            orgID: ownerID,
            appID: appID ?? "",
            pluginID: pluginID ?? "",
            instanceID: configID ?? "",
            proofKeyMethod,
            proofKeySize,
            forceDeployedRedirect,
            forOwner,
            expectedCredentialID,
            expectedProviderID,
            openInNewWindow,
        }
    );
}
