import {
    type AppAuthentication,
    type AppDescription,
    type ChatScreenDescription,
    type ClassOrArrayScreenDescription,
    type ComponentDescription,
    type ScreenDescription,
    type ShoppingCartScreenDescription,
    type TabDescription,
    ScreenDescriptionKind,
    getScreenProperty,
    makeScreenProperty,
} from "@glide/app-description";
import {
    type TableColumn,
    type TableGlideType,
    type TypeSchema,
    findTable,
    getTableColumn,
    getTableName,
} from "@glide/type-schema";
import type { AppSettings, PageTemplateKind } from "@glide/common-core/dist/js/firebase-function-types";
import {
    type AppDescriptionContext,
    chatScreenName,
    classScreenName,
    isAddOrEditOrFormScreenDescription,
    shoppingCartScreenName,
} from "@glide/function-utils";
import { assert, assertNever, defined, panic } from "@glideapps/ts-necessities";
import { logInfo } from "@glide/support";
import randomSample from "lodash/sample";
import { randomTheme } from "@glide/common";
import type { BaseTheme } from "@glide/base-theme";

import { componentDescriptionForColumn } from "./make-screen";

export const MAX_TABS = 4;

export const chatScreen: ChatScreenDescription = { kind: ScreenDescriptionKind.Chat };
export const shoppingCartScreen: ShoppingCartScreenDescription = { kind: ScreenDescriptionKind.ShoppingCart };

export function isChatTab(tab: TabDescription): boolean {
    return getScreenProperty(tab.screenName) === chatScreenName;
}

export function isShoppingCartTab(tab: TabDescription): boolean {
    return getScreenProperty(tab.screenName) === shoppingCartScreenName;
}

export function copyToFreeScreen(screenName: string, screen: ClassOrArrayScreenDescription): ScreenDescription {
    assert(!isAddOrEditOrFormScreenDescription(screenName, screen));
    const newScreen = { ...screen, fetchesData: true };
    if (newScreen.kind === ScreenDescriptionKind.Class) {
        return { ...newScreen, title: undefined };
    } else {
        return newScreen;
    }
}

export function addOrRemoveSpecialTabsIfNecessary(
    tabs: readonly TabDescription[],
    isPage: boolean
): readonly TabDescription[] {
    if (isPage) {
        return tabs.filter(t => {
            const screenName = getScreenProperty(t.screenName);
            return screenName !== undefined && screenName !== chatScreenName && screenName !== shoppingCartScreenName;
        });
    } else {
        const newTabs = [...tabs];
        if (!tabs.some(isChatTab)) {
            newTabs.push({
                title: "Chat",
                icon: "message-circle",
                hidden: true,
                inFlyout: false,
                screenName: makeScreenProperty(chatScreenName),
            });
        }
        if (!tabs.some(isShoppingCartTab)) {
            newTabs.push({
                title: "Shopping Cart",
                icon: "09-07-shopping-bag-side",
                hidden: true,
                inFlyout: false,
                screenName: makeScreenProperty(shoppingCartScreenName),
            });
        }
        return newTabs;
    }
}

const defaultEmojis = [
    "💼",
    "🤖",
    "🚀",
    "💎",
    "⚡",
    "✏️",
    "☕",
    "📈",
    "🗒️",
    "🗂️",
    "🏛️",
    "🏬",
    "🚚",
    "✈️",
    "💻",
    "📊",
    "📘",
    "📚",
    "🔔",
    "📫",
];

function applyTemplateKindToTheme(theme: BaseTheme, templateKind: PageTemplateKind | undefined): BaseTheme {
    if (templateKind === undefined) return theme;

    switch (templateKind) {
        case "basic":
        case "directory": {
            theme.pageBackground = "Highlight";
            theme.pageTheme = "Highlight";
            theme.showDesktopSideBar = false;
            break;
        }
        case "form": {
            theme.pageBackground = "Highlight";
            theme.pageTheme = "Accent";
            theme.showDesktopSideBar = false;
            theme.pageContainer = "Narrow";
            break;
        }
        case "blank": {
            theme.pageBackground = "Highlight";
            theme.pageTheme = "Highlight";
            theme.showDesktopSideBar = false;
            break;
        }
        case "portal": {
            theme.pageBackground = "Highlight";
            theme.pageTheme = "Accent";
            theme.showDesktopSideBar = true;
            break;
        }
        case "field operations":
        case "inventory & logistics":
        case "client portal":
        case "event planning":
        case "admin dashboard":
        case "company directory":
        // temporary data source templates
        case "glide-big-tables":
        case "mysql":
        case "postgresql":
        case "sqlserver":
        case "google-cloud-sql":
        case "bigquery":
            theme.pageTheme = "Accent";
            break;
        default:
            assertNever(templateKind);
    }

    return theme;
}

export function makeRandomAppSettings(
    title: string,
    authentication: AppAuthentication | undefined,
    previousTheme: BaseTheme | undefined = undefined,
    templateKind: PageTemplateKind | undefined = undefined,
    primaryAccentColor: string | undefined = undefined
): AppSettings {
    const theme = previousTheme ?? applyTemplateKindToTheme(randomTheme(), templateKind);
    if (primaryAccentColor !== undefined) {
        theme.primaryAccentColor = primaryAccentColor;
    }
    return {
        title,
        iconImage: { emoji: defined(randomSample(defaultEmojis)) },
        theme,
        authentication,
    };
}

interface NewComponent {
    readonly table: TableGlideType;
    readonly column: TableColumn;
    readonly desc: ComponentDescription;
}

export function addNewComponents(appDesc: AppDescription, newComponents: ReadonlyArray<NewComponent>): AppDescription {
    const updatedScreenDescriptions: { [name: string]: ScreenDescription } = {};

    for (const { table, desc } of newComponents) {
        const screenName = classScreenName(getTableName(table));
        const screenDesc = appDesc.screenDescriptions[screenName];

        if (screenDesc.kind !== ScreenDescriptionKind.Class) {
            return panic(`Screen is not a class screen ${screenName} but a ${screenDesc.kind}`);
        }

        const newScreenDesc = { ...screenDesc, components: [...screenDesc.components, desc] };
        updatedScreenDescriptions[screenName] = newScreenDesc;
    }

    return { ...appDesc, screenDescriptions: { ...appDesc.screenDescriptions, ...updatedScreenDescriptions } };
}

export function newComponentsForNewColumns(
    appDesc: AppDescription,
    oldCCC: AppDescriptionContext,
    newSchema: TypeSchema
): ReadonlyArray<NewComponent> {
    const newComponents: NewComponent[] = [];

    const oldSchema = oldCCC.schema;
    for (const table of oldSchema.tables) {
        const newTable = findTable(newSchema, getTableName(table));
        if (newTable === undefined) continue;

        const screenName = classScreenName(getTableName(newTable));
        const screenDesc = appDesc.screenDescriptions[screenName];
        if (screenDesc === undefined) continue;
        if (screenDesc.kind !== ScreenDescriptionKind.Class) {
            return panic(`Screen is not a class screen ${screenName} but a ${screenDesc.kind}`);
        }

        for (const column of newTable.columns) {
            if (column.hidden === true) continue;

            const oldColumn = getTableColumn(table, column.name);
            if (oldColumn !== undefined) continue;

            logInfo("Column not found in old schema", column.name, table, newTable);

            const desc = componentDescriptionForColumn(oldCCC, newTable, column);
            if (desc === undefined) continue;

            newComponents.push({ table: newTable, column, desc });
        }
    }

    if (newComponents.length > 0) {
        logInfo("New components", newComponents, oldSchema, newSchema);
    }

    return newComponents;
}

export function makeScreenNameWithPrefix(prefix: string, nameExists: (n: string) => boolean): string {
    function makeName(suffix: string) {
        return prefix + suffix;
    }

    for (let i = 0; ; i++) {
        const n = makeName(i.toString());
        if (!nameExists(n)) {
            return n;
        }
    }
}
