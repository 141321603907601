import "twin.macro";

import { ErrorBoundary, GlideIcon } from "@glide/common";
import * as React from "react";
import { trackEvent } from "@glide/common-core/dist/js/analytics";
import { DragItem, SelectedState } from "../drag-item/drag-item";
import type { CustomSectionItem } from "../group-ui/group-ui";
import { GroupUI, SectionAccessoryButton } from "../group-ui/group-ui";
import { SimpleTooltip } from "../tooltip/simple-tooltip";
import { compareStringsSmartly } from "@glide/support";
import type { AutomationTriggerKind } from "@glide/automations-codecs";
import { useHover, useLayer } from "react-laag";
import type { GlideIconProps } from "@glide/plugins-codecs";
import type { AutomationTrigger, BuilderActionsForApp } from "@glide/app-description";
import { ActionNodeKind } from "@glide/app-description";
import type { ConfiguredPluginTrigger } from "../windowed-modal/modals/select-action-trigger-type";
import { getFolderName } from "./action-editor-sidebar-utils";

interface SidebarAction {
    readonly name: string;
    readonly tableName?: string;
    readonly id: string;
    readonly inUse: boolean;
    readonly triggerKind: AutomationTriggerKind;
    readonly icon: string | GlideIconProps;
    readonly pluginConfigID: string | undefined;
}

interface TableItem {
    readonly name: string;
    readonly id: string;
}

interface Props extends React.PropsWithChildren {
    readonly actions: readonly SidebarAction[];
    readonly actionErrors?: Record<string, string>;
    readonly appID?: string;
    readonly selected?: SidebarAction;
    readonly onChange: (newVal: SidebarAction) => void;
    readonly onNewAction?: (table: TableItem) => void;
    readonly tables: TableItem[];
    readonly buildMenuPopout: (
        x: number,
        y: number,
        actionID: string,
        isInUse: boolean,
        onClose: () => void,
        table: TableItem
    ) => React.ReactNode;
    readonly onRenameAction: (actionID: string, newName: string) => void;
}

const ErrorIcon: React.FC<{ message: string }> = ({ message }) => {
    const [isOver, hoverProps] = useHover();

    const { layerProps, renderLayer, triggerProps } = useLayer({
        isOpen: isOver,
        container: "portal",
        auto: true,
        triggerOffset: 4,
        placement: "top-center",
    });

    return (
        <>
            <div {...hoverProps} {...triggerProps} tw="p-1 text-r400">
                <GlideIcon kind="stroke" icon="st-alert-warning" iconSize={16} />
            </div>
            {isOver &&
                renderLayer(
                    <div tw="bg-n0 rounded-lg overflow-hidden shadow-lg max-w-[320px]" {...layerProps}>
                        <div tw="bg-r100 text-text-danger px-3 py-2 text-builder-sm">
                            Last run failed with error: {message}
                        </div>
                    </div>
                )}
        </>
    );
};

type ActionGroup = {
    name: string;
    children: SidebarAction[];
};

function isActionGroup(item: ListItem): item is ActionGroup {
    return "children" in item;
}

type ListItem = ActionGroup | SidebarAction;

type ListItemViewPropsBase = {
    readonly onSelect: (action: SidebarAction) => void;
    readonly onShowMenu: (e: React.MouseEvent, action: SidebarAction) => void;
    readonly selectedAction: SidebarAction | undefined;
    readonly actionErrors?: Record<string, string>;
    readonly onRenameAction: (actionID: string, newName: string) => void;
};

const ListActionItemIcon: React.FC<{
    icon: string | GlideIconProps;
    inUse: boolean;
    triggerKind: SidebarAction["triggerKind"];
}> = ({ icon, inUse, triggerKind }) => {
    const i = React.useMemo(
        () => (
            <div style={{ opacity: inUse ? 1 : 0.5 }}>
                {typeof icon === "string" ? (
                    <img src={icon} tw="w-4 h-4 rounded" alt="Trigger icon" />
                ) : (
                    <GlideIcon {...icon} tw="text-icon-base" iconSize={16} />
                )}
            </div>
        ),
        [icon, inUse]
    );

    if (inUse) {
        return i;
    }

    return (
        <SimpleTooltip
            text={triggerKind !== "app" ? "Not scheduled for running" : "Not used by any component"}
            placement="top">
            <span>{i}</span>
        </SimpleTooltip>
    );
};

const ListActionItemView: React.FC<ListItemViewPropsBase & { item: SidebarAction }> = p => {
    const { item: a, onSelect, onShowMenu, selectedAction, actionErrors, onRenameAction } = p;
    const hasTable = a.tableName !== undefined;
    const errorMessage = actionErrors?.[a.id];

    const [folder, cleanedName] = React.useMemo(() => {
        return getFolderName(a.name);
    }, [a.name]);

    const onRename = React.useCallback(
        (newName: string) => {
            onRenameAction(a.id, `${folder !== undefined ? `${folder}/` : ""}${newName}`);
        },
        [a.id, onRenameAction, folder]
    );

    return (
        <DragItem
            key={a.id}
            isHighlighted={false}
            onContextMenu={e => onShowMenu(e, a)}
            isDraggable={false}
            isDragging={false}
            icon={<ListActionItemIcon icon={a.icon} inUse={a.inUse} triggerKind={a.triggerKind} />}
            tail={errorMessage !== undefined ? <ErrorIcon message={errorMessage} /> : undefined}
            tailOnHover={
                <div tw="p-1 text-icon-base hover:bg-n200A rounded" onClick={e => onShowMenu(e, a)}>
                    <GlideIcon kind="monotone" icon="mt-dots-horizontal" iconSize={16} />
                </div>
            }
            selected={a.id === selectedAction?.id ? SelectedState.Selected : SelectedState.None}
            name={cleanedName}
            onClick={() => onSelect?.(a)}
            secondary={hasTable ? a.tableName : ""}
            onNameChanged={onRename}
        />
    );
};

const ListActionGroupView: React.FC<ListItemViewPropsBase & { item: ActionGroup }> = p => {
    const { item, ...rest } = p;
    const previousSelectedAction = React.useRef(p.selectedAction);
    const [isExpanded, setIsExpanded] = React.useState(
        () => p.selectedAction !== undefined && p.item.children.includes(p.selectedAction)
    );

    const showAsSelected = React.useMemo(
        () => isExpanded === false && item.children.some(a => a.id === p.selectedAction?.id),
        [isExpanded, item, p.selectedAction]
    );

    if (p.selectedAction !== previousSelectedAction.current) {
        if (p.selectedAction !== undefined && item.children.includes(p.selectedAction)) {
            setIsExpanded(true);
        }
        previousSelectedAction.current = p.selectedAction;
    }

    return (
        <div>
            <div
                tw="flex items-center gap-2 h-8 pl-2.5 pr-2 rounded-lg [&.selected]:bg-n200 hover:bg-n100 cursor-pointer mb-[1px]"
                className={showAsSelected ? "selected" : undefined}
                onClick={() => setIsExpanded(!isExpanded)}>
                <GlideIcon tw="text-icon-base" kind="stroke" icon="st-folder" iconSize={16} />
                <div tw="grow text-builder-base text-text-base">{item.name}</div>
                <GlideIcon
                    tw="text-icon-base"
                    kind="stroke"
                    icon="st-chevron-right"
                    iconSize={16}
                    rotateDeg={isExpanded ? 90 : 0}
                />
            </div>
            {isExpanded && (
                <div tw="pl-2.5 flex flex-col gap-[1px] mb-[1px]">
                    {item.children.map(action => (
                        <ListActionItemView key={action.id} item={action} {...rest} />
                    ))}
                </div>
            )}
        </div>
    );
};

const ListItemView: React.FC<ListItemViewPropsBase & { item: ListItem }> = p => {
    const { item, ...rest } = p;
    if (isActionGroup(item)) {
        return <ListActionGroupView item={item} {...rest} />;
    } else {
        return <ListActionItemView item={item} {...rest} />;
    }
};

function sortListItems(list: ListItem[]): ListItem[] {
    // Sort top-level
    const sortedList = [...list].sort((a, b) => {
        const inUseA = isActionGroup(a) ? true : a.inUse;
        const inUseB = isActionGroup(b) ? true : b.inUse;
        if (inUseA !== inUseB) {
            return inUseA ? -1 : 1; // in-use items come first
        }
        return compareStringsSmartly(a.name, b.name);
    });

    // Sort children if it’s a group
    return sortedList.map(item => {
        if (isActionGroup(item)) {
            const sortedChildren = [...item.children].sort((a, b) => {
                if (a.inUse !== b.inUse) {
                    return a.inUse ? -1 : 1;
                }
                return compareStringsSmartly(a.name, b.name);
            });
            return {
                ...item,
                children: sortedChildren,
            };
        }
        return item;
    });
}

export const ActionEditorSidebar: React.FC<Props> = p => {
    const { appID, actions, actionErrors, onChange, selected, tables, onNewAction, buildMenuPopout, onRenameAction } =
        p;
    const [showMenu, setShowMenu] = React.useState<[[number, number], SidebarAction]>();
    const [searchQuery, setSearchQuery] = React.useState<string>("");
    const onShow = React.useCallback((e: React.MouseEvent, action: SidebarAction) => {
        e.preventDefault();
        e.stopPropagation();
        setShowMenu([[e.clientX, e.clientY], action]);
    }, []);

    const onClose = React.useCallback(() => {
        setShowMenu(undefined);
    }, []);

    const menuPopout = React.useMemo(() => {
        if (!showMenu) return null;
        const [[x, y], action] = showMenu;
        return buildMenuPopout(x, y, action.id, action.inUse, onClose, tables[0]);
    }, [showMenu, buildMenuPopout, onClose, tables]);

    const sortedActions = React.useMemo(() => {
        return [...actions].sort((a, b) => compareStringsSmartly(a.name, b.name));
    }, [actions]);

    const listItems: ListItem[] = React.useMemo(() => {
        // Build initial structure with consecutive grouping
        const grouped = sortedActions.reduce<ListItem[]>((acc, action) => {
            const [folderName] = getFolderName(action.name);

            if (folderName !== undefined) {
                const lastItem = acc[acc.length - 1];
                const lastItemIsSameGroup =
                    lastItem && isActionGroup(lastItem) && lastItem.name.toLowerCase() === folderName.toLowerCase();

                if (lastItemIsSameGroup) {
                    (lastItem as ActionGroup).children.push(action);
                } else {
                    acc.push({
                        name: folderName,
                        children: [action],
                    });
                }
            } else {
                // No folder name; push action directly
                acc.push(action);
            }

            return acc;
        }, []);

        // Sort top-level and children
        return sortListItems(grouped);
    }, [sortedActions]);

    const items: CustomSectionItem[] = React.useMemo(() => {
        return [
            {
                section: {
                    name: "Workflows",
                    order: 0,
                    onSearch: setSearchQuery,
                },
                ui:
                    searchQuery.length > 0
                        ? sortedActions
                              .filter(a => a.name.toLocaleLowerCase().indexOf(searchQuery) > -1)
                              .map(a => (
                                  <ListActionItemView
                                      key={a.id}
                                      item={a}
                                      onSelect={onChange}
                                      onShowMenu={onShow}
                                      selectedAction={selected}
                                      actionErrors={actionErrors}
                                      onRenameAction={onRenameAction}
                                  />
                              ))
                        : listItems.map(item => (
                              <ListItemView
                                  key={isActionGroup(item) ? item.name : item.id}
                                  item={item}
                                  onSelect={onChange}
                                  onShowMenu={onShow}
                                  selectedAction={selected}
                                  actionErrors={actionErrors}
                                  onRenameAction={onRenameAction}
                              />
                          )),
            },
        ];
    }, [sortedActions, onShow, onChange, searchQuery, actionErrors, listItems, selected, onRenameAction]);

    return (
        <div
            data-testid="action-editor-sidebar"
            tw="w-full h-full bg-bg-front overflow-y-auto px-2.5 pb-11"
            css={`
                .section-title-container {
                    height: 44px !important;
                    padding: 1px 6px 0px 10px;
                }
            `}>
            <GroupUI
                items={items}
                accessoryForSection={() => {
                    return (
                        <div>
                            <SectionAccessoryButton
                                onClick={() => {
                                    onNewAction?.(tables[0]);
                                    trackEvent("action created", { app_id: appID ?? "" });
                                }}>
                                <GlideIcon kind="stroke" icon="st-plus-stroke" iconSize={20} />
                            </SectionAccessoryButton>
                        </div>
                    );
                }}
            />
            {showMenu !== undefined && (
                <ErrorBoundary>
                    <React.Suspense fallback={<div />}>{menuPopout}</React.Suspense>
                </ErrorBoundary>
            )}
        </div>
    );
};

function getIconForTrigger(
    trigger: AutomationTrigger,
    configuredPluginTriggers: readonly ConfiguredPluginTrigger[]
): GlideIconProps | string {
    let icon: GlideIconProps | string = {
        kind: "stroke",
        icon: "st-clock",
    };

    if (trigger.kind === "plugin") {
        const triggerConfiguration = configuredPluginTriggers.find(c => c.pluginConfigID === trigger.pluginConfigID);
        icon = triggerConfiguration?.trigger.icon ?? { kind: "stroke", icon: "st-null" }; // loading state
    }

    return icon;
}

export function useAutomationsForSidebar(
    builderActions: BuilderActionsForApp,
    appID: string,
    configuredPluginTriggers: readonly ConfiguredPluginTrigger[]
): SidebarAction[] {
    const allAutomations = React.useMemo(() => {
        const icccBuilderactions = builderActions.entries() ?? [];
        const automations: SidebarAction[] = [];
        for (const [builderActionID, builderAction] of icccBuilderactions) {
            if (builderAction.action.kind !== ActionNodeKind.AutomationRoot) continue;

            const automationDetails = builderAction.perApp[appID]?.automation;
            const firstTrigger = automationDetails?.triggers[0];
            if (firstTrigger === undefined) {
                continue;
            }

            const triggerKind = firstTrigger.kind;
            const pluginConfigID = triggerKind === "plugin" ? firstTrigger.pluginConfigID : undefined;

            const sideBarAutomation: SidebarAction = {
                name: builderAction.title ?? "",
                id: builderActionID,
                inUse: firstTrigger.enabled,
                icon: getIconForTrigger(firstTrigger, configuredPluginTriggers),
                triggerKind,
                pluginConfigID,
            };
            automations.push(sideBarAutomation);
        }
        return automations;
    }, [appID, builderActions, configuredPluginTriggers]);

    return allAutomations;
}
