import { styled } from "@glide/common";

export type SearchFieldVariant = "minimal" | "default";

export const SearchFieldStyle = styled.label<{
    variant: SearchFieldVariant;
    showClearButton: boolean;
    isFocused: boolean;
}>`
    height: 32px;
    overflow: hidden;
    font-family: ${p => p.theme.labelFontFamily};
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 10px;

    & .sf-slider {
        height: 100%;
        padding: 0 10px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        border-radius: 8px;
        background-color: ${p => (p.variant === "minimal" && !p.isFocused ? "transparent" : p.theme.n200A)};
        :hover {
            transition: background-color ${p => p.theme.transitionBase};
            background-color: ${p => p.theme.n100A};
        }

        transition: transform 0.2s linear;
    }

    & .sf-close-button {
        position: absolute;
        inset: 0;
        left: auto;
        padding-right: 8px;
        display: flex;
        align-items: center;
        color: ${p => p.theme.fgColorMedium};

        &:hover {
            color: ${p => p.theme.iconDark};
        }
    }

    & .sf-icon {
        margin-right: 8px;
        color: ${p => p.theme.n600};
    }

    & .sf-input {
        font-size: 13px;
        font-weight: 400;
        flex-grow: 1;
        min-width: 0;
        margin-right: 2px;
        color: ${p => p.theme.fgColorDark};

        ::placeholder {
            color: ${p => p.theme.textXpale};
        }
    }
`;
