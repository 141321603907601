import type { LocationSettings } from "@glide/location-common";
import { type JSONObject, type DocumentData, isArray } from "@glide/support";
import { assert, panic } from "@glideapps/ts-necessities";

export type QueryOpString =
    | "=="
    | "!="
    | "<"
    | "<="
    | ">="
    | ">"
    | "in"
    | "array-contains"
    | "array-contains-any"
    | "not-in";

export interface Query {
    readonly fieldPath: string;
    readonly opString: QueryOpString;
    readonly value: unknown;
}

export interface DocumentDataWithID {
    readonly data: DocumentData;
    readonly id: string;
}

export interface DocumentDataWithIDAndPath extends DocumentDataWithID {
    readonly path: string;
}

export type QueryResults = readonly DocumentDataWithIDAndPath[];

export type SortDirection = "asc" | "desc";

export interface DataReader {
    readonly database: Database;
    getDocument(collectionName: string, id: string): Promise<DocumentData | undefined>;
}

export interface QueryableDataReader extends DataReader {
    getDocumentsWhere(
        collectionName: string,
        queries: readonly Query[],
        sortFieldPath?: string,
        sortDirection?: SortDirection,
        limit?: number
    ): Promise<QueryResults>;
}

export interface DataWriter {
    readonly database: Database;
    setDocument(collectionName: string, id: string | undefined, data: DocumentData): Promise<string>;
    updateDocument(collectionName: string, id: string | undefined, updates: DocumentData): Promise<string>;
    updateDocumentWithNesting(collectionName: string, id: string, data: DocumentData): Promise<void>;
    deleteDocument(collectionName: string, id: string): Promise<void>;
}

export interface DataReaderWriter extends DataReader, DataWriter {}

export interface Batch extends DataWriter {}

export interface Transaction extends DataReaderWriter {}

export interface DiffResult extends DocumentDataWithID {
    readonly kind: "added" | "modified" | "removed";
}

export type DiffResults = readonly DiffResult[];

export interface PollingOptions {
    initialVersion?: number;
    makeRowVersionQuery(lastVersion: number): [query: Query, sortFieldPath: string];
    getVersionFromData(data: DocumentData): number;
}

export interface Database extends DataReaderWriter {
    get deleteFieldValue(): unknown;
    get serverTimestampFieldValue(): unknown;
    get deploymentLocationSettings(): LocationSettings;
    get database(): Database;
    get projectID(): string;

    arrayUnionFieldValue(...values: unknown[]): unknown;
    arrayRemoveFieldValue(...values: unknown[]): unknown;
    incrementFieldValue(increment?: number): unknown;

    makeDocumentID(): Promise<string>;

    getDocument(collectionName: string, id: string): Promise<DocumentData | undefined>;
    getDocumentsWhere(
        collectionName: string,
        queries: ReadonlyArray<Query>,
        sortFieldPath?: string,
        sortDirection?: SortDirection,
        limit?: number
    ): Promise<QueryResults>;
    getDocumentsWherePaginated(
        collectionName: string,
        queries: ReadonlyArray<Query>,
        pageSize: number,
        concurrently: boolean,
        callback: (page: QueryResults) => Promise<unknown>
    ): Promise<void>;

    setDocument(collectionName: string, id: string | undefined, data: DocumentData): Promise<string>;
    updateDocument(collectionName: string, id: string | undefined, updates: DocumentData): Promise<string>;
    updateDocumentWithNesting(collectionName: string, id: string, data: DocumentData): Promise<void>;
    deleteDocument(collectionName: string, id: string): Promise<void>;

    runBatch<T>(name: string, f: (b: Batch) => Promise<T>): Promise<T>;
    runTransaction<T>(name: string, f: (t: Transaction) => Promise<T>): Promise<T>;

    listenToDocument(
        collectionName: string,
        id: string,
        // `undefined` means the document doesn't exist
        onUpdate: (data: DocumentData | undefined) => void,
        onError?: (e: Error) => void
    ): () => void;
    listenWhere(
        collectionName: string,
        queries: readonly Query[],
        sortFieldPath: string | undefined,
        sortDirection: SortDirection | undefined,
        limit: number | undefined,
        onUpdate: (results: QueryResults) => void,
        onError?: (e: Error) => void,
        // If this is provided, we use paginated polling instead of firestore listen (which can fall over for big data changes)
        polling?: PollingOptions
    ): () => void;
    listenDiffWhere(
        collectionName: string,
        queries: readonly Query[],
        sortFieldPath: string | undefined,
        sortDirection: SortDirection | undefined,
        onUpdate: (results: DiffResults, durationMs?: number) => void,
        onError: () => void,
        // If this is provided, we use paginated polling instead of firestore listen (which can fall over for big data changes)
        polling?: PollingOptions
    ): () => void;

    dateFromTimestamp(timestamp: unknown): Date;
    convertDocumentProperty(x: unknown): unknown;

    convertFromDocument(data: DocumentData): JSONObject;
    convertToDocument(data: DocumentData): DocumentData;

    enterNetworkConnectionLock(): Promise<void>;
    exitNetworkConnectionLock(): void;
}

export abstract class DatabaseBase implements Database {
    public get deleteFieldValue(): unknown {
        throw new Error();
    }
    public get serverTimestampFieldValue(): unknown {
        throw new Error();
    }
    public abstract get deploymentLocationSettings(): LocationSettings;
    public abstract arrayUnionFieldValue(...values: unknown[]): unknown;
    public abstract arrayRemoveFieldValue(...values: unknown[]): unknown;
    public abstract incrementFieldValue(increment?: number): unknown;

    public get database(): Database {
        return this;
    }

    public abstract get projectID(): string;

    public abstract makeDocumentID(): Promise<string>;

    public abstract getDocument(collectionName: string, id: string): Promise<DocumentData | undefined>;
    public abstract getDocumentsWhere(
        collectionName: string,
        queries: ReadonlyArray<Query>,
        sortFieldPath?: string,
        sortDirection?: SortDirection,
        limit?: number
    ): Promise<QueryResults>;
    public abstract getDocumentsWherePaginated(
        collectionName: string,
        queries: ReadonlyArray<Query>,
        pageSize: number,
        concurrently: boolean,
        callback: (page: QueryResults) => Promise<unknown>
    ): Promise<void>;

    public abstract setDocument(collectionName: string, id: string | undefined, data: DocumentData): Promise<string>;
    public abstract updateDocument(
        collectionName: string,
        id: string | undefined,
        updates: DocumentData
    ): Promise<string>;
    public abstract updateDocumentWithNesting(collectionName: string, id: string, data: DocumentData): Promise<void>;
    public abstract deleteDocument(collectionName: string, id: string): Promise<void>;

    public abstract runBatch<T>(name: string, f: (b: Batch) => Promise<T>): Promise<T>;
    public abstract runTransaction<T>(name: string, f: (t: Transaction) => Promise<T>): Promise<T>;

    public abstract listenToDocument(
        collectionName: string,
        id: string,
        // `undefined` means the document doesn't exist
        onUpdate: (data: DocumentData | undefined) => void,
        onError?: (e: Error) => void
    ): () => void;
    public abstract listenWhere(
        collectionName: string,
        queries: readonly Query[],
        sortFieldPath: string | undefined,
        sortDirection: SortDirection | undefined,
        limit: number | undefined,
        onUpdate: (results: QueryResults) => void,
        onError?: (e: Error) => void,
        // If this is provided, we use paginated polling instead of firestore listen (which can fall over for big data chanages)
        polling?: PollingOptions
    ): () => void;
    public abstract listenDiffWhere(
        collectionName: string,
        queries: readonly Query[],
        sortFieldPath: string | undefined,
        sortDirection: SortDirection | undefined,
        onUpdate: (results: DiffResults) => void,
        onError: () => void,
        // If this is provided, we use paginated polling instead of firestore listen (which can fall over for big data chanages)
        polling?: PollingOptions
    ): () => void;

    public abstract dateFromTimestamp(timestamp: unknown): Date;
    public abstract convertDocumentProperty(x: unknown): unknown;

    public abstract convertFromDocument(data: DocumentData): JSONObject;

    private convertValueToDocument(v: unknown): unknown {
        assert(v !== undefined);
        if (isArray(v)) {
            return v.map(x => this.convertValueToDocument(x));
        } else if (
            v === null ||
            v instanceof Date ||
            typeof v === "string" ||
            typeof v === "number" ||
            typeof v === "boolean"
        ) {
            return v;
        } else if (typeof v === "object" && v !== null) {
            return this.convertToDocument(v as DocumentData);
        } else {
            return panic(`Invalid value in Firestore document ${v}`);
        }
    }

    public convertToDocument(data: DocumentData): DocumentData {
        const result: DocumentData = {};
        // Purposefully not using Object.entries here because that allocates
        //  an array for each key-value pair.
        for (const k of Object.keys(data)) {
            const v = data[k];
            if (v === undefined) continue;
            result[k] = this.convertValueToDocument(v);
        }
        return result;
    }

    public abstract enterNetworkConnectionLock(): Promise<void>;
    public abstract exitNetworkConnectionLock(): void;
}
