import * as t from "io-ts";

export const pricingCodec = t.type({
    currency: t.string,
    // This value is recorded in the smallest possible unit
    // for the currency. E.g., for USD this is in cents.
    // For Yen, this is just the value.
    value: t.number,
});

export type Pricing = Readonly<t.TypeOf<typeof pricingCodec>>;
