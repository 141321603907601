import { AppKind } from "@glide/location-common";
import { getLocalizedString } from "@glide/localization";
import { GreetingStyles } from "../custom-sign-in/greeting-styles";
import { WireField } from "../../renderers/wire-field/wire-field";
import type { ResponseStatus } from "@glide/common-core/dist/js/components/types";
import { SignInPrimaryButton, SignInSecondaryButton } from "./sign-in-buttons";

interface Props extends React.PropsWithChildren {
    readonly userEmail: string;
    readonly onPressedBack?: () => void;
    readonly onPressedContinue: () => Promise<void>;
    readonly status?: "Sending" | ResponseStatus;
}

export const RequestAccessStage: React.FC<Props> = p => {
    const { userEmail, onPressedBack, onPressedContinue, status } = p;

    const greeting =
        status === "Success"
            ? getLocalizedString("requestSent", AppKind.Page)
            : getLocalizedString("requestAppUserAccessHeader", AppKind.Page);

    const descriptionText =
        status === "Success"
            ? getLocalizedString("requestCompleteAppUserAccessDescription", AppKind.Page)
            : getLocalizedString("requestAppUserAccessDescription", AppKind.Page);

    const buttonText =
        status === "Sending"
            ? getLocalizedString("requestingAccess", AppKind.Page)
            : getLocalizedString("requestAccess", AppKind.Page);

    const buttons = (
        <div>
            {status !== "Success" && (
                <SignInPrimaryButton onClick={onPressedContinue} disabled={status === "Sending"} label={buttonText} />
            )}
            {onPressedBack !== undefined && (
                <SignInSecondaryButton
                    onClick={onPressedBack}
                    label={getLocalizedString("backToSignIn", AppKind.Page)}
                />
            )}
        </div>
    );

    return (
        <>
            <div>
                <GreetingStyles greeting={greeting} />
                <WireField
                    isSignInEmail={true}
                    title={descriptionText}
                    data-test="app-email-input"
                    isEnabled={false}
                    value={userEmail}
                    dataType="email"
                    error={status !== "Success" && status !== "Sending" ? status : undefined} // TODO localized strings?
                    autoFocus={true}
                />
            </div>
            {buttons}
        </>
    );
};
