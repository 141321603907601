import { type WireWebViewComponent, WEBVIEW_DEFAULT_URL } from "@glide/fluent-components/dist/js/fluent-components";
import { isDefined, isUrl } from "@glide/support";
import React from "react";
import { css } from "@glide/common-components";
import type { WireRenderer } from "../wire-renderer";

import { GlideIcon } from "@glide/common";
import { extractIframe } from "@glide/common-core/dist/js/components/extract-iframe";
import { getLocalizedString } from "@glide/localization";
import { useIsPlayerOrPreview } from "@glide/common-core/dist/js/hooks/use-is-player-or-preview";
import { UISize } from "@glide/wire";
import tw from "twin.macro";
import { getFirstElementFromArrayOrSingleElement } from "@glide/common-core/dist/js/components/component-helpers";

interface ExtraProps {
    isInMultipleColumnLayout?: boolean;
}

const allowed = ["clipboard-write", "fullscreen", "picture-in-picture", "autoplay", "camera", "microphone"];

export const WireWebView: WireRenderer<WireWebViewComponent, ExtraProps> = React.memo(p => {
    const { url: arrayOrSingleUrl, size, scrollable, isInMultipleColumnLayout, backend } = p;

    const isPlayerOrPreview = useIsPlayerOrPreview();

    const url = getFirstElementFromArrayOrSingleElement(arrayOrSingleUrl);

    if (!isDefined(url)) {
        return null;
    }

    let realURL: string | undefined;
    if (url === WEBVIEW_DEFAULT_URL) {
        realURL = url;
    } else {
        const extracted = extractIframe(url);
        realURL = extracted.source;
    }

    if (realURL === undefined || (!isUrl(realURL) && realURL !== WEBVIEW_DEFAULT_URL)) {
        if (isPlayerOrPreview) {
            return null;
        } else {
            return (
                <div tw="w-full flex flex-col justify-center items-center bg-n200A rounded-xl opacity-75 py-4 px-6">
                    <p tw="flex items-center text-sm w-full justify-center">
                        <GlideIcon tw="[min-width:22px]" kind="monotone" icon="mt-warning" iconSize={22} />
                        <pre tw="ml-2 pr-2 overflow-hidden whitespace-nowrap overflow-ellipsis">{url}</pre>
                        <span tw="[min-width:fit-content]">{getLocalizedString("notValidUrl", backend.appKind)}</span>
                    </p>
                    <p tw="mt-2 text-sm">{getLocalizedString("httpOrHttps", backend.appKind)}</p>
                </div>
            );
        }
    }

    const canScroll = scrollable ? "yes" : "no";
    const sizeClass = isInMultipleColumnLayout ? "fill" : size;
    const iframeStyles = css`
        ${tw`w-full`}

        &.fill {
            ${tw`h-full min-h-[600px]`}
        }

        &.${UISize.Full} {
            ${tw`h-screen min-h-[900px]`}
        }

        &.${UISize.Large} {
            ${tw`h-[900px]`}
        }

        &.${UISize.Medium} {
            ${tw`h-[600px]`}
        }

        &.${UISize.Small} {
            ${tw`h-80`}
        }
    `;

    return (
        <iframe
            scrolling={canScroll}
            title={realURL}
            src={realURL}
            className={sizeClass}
            tw="rounded"
            css={iframeStyles}
            allow={allowed.join(";")}
        />
    );
});
