import type { AppDescription } from "@glide/app-description";
import type { BaseAppData } from "@glide/plugins";
import type { ActionAppFacilities } from "@glide/common-core/dist/js/components/types";
import { getSimpleValue } from "@glide/backend-api";

export function makeAppData(
    appID: string,
    appDescription: AppDescription,
    appFacilities: ActionAppFacilities | undefined
): BaseAppData {
    // This is just being used in tests so we mock some props of BaseAppData:
    const appPrimaryAccentColor = appDescription.theme.primaryAccentColor;
    const appIconSubtitle = "by the app team";
    const isFreeEminence = true;

    return {
        id: appID,
        name: appDescription.title,
        appIconSubtitle,
        isFreeEminence,
        appPrimaryAccentColor,
        description: appDescription.description,
        getIcon: async () => {
            if (appFacilities === undefined) return undefined;
            return await getSimpleValue(appID, "app-icon-url", appFacilities);
        },
        getPlayURL: async () => {
            if (appFacilities === undefined) return undefined;
            return await getSimpleValue(appID, "app-play-url", appFacilities);
        },
    };
}
