// https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa

import { logError } from "@glide/support";
import { exceptionToString } from "@glideapps/ts-necessities";

// Convert a Uint8Array or Uint16Array to a string,
// with each value in the array being interpreted as a unicode code point.
// Adapted from Uint8ToString() in https://stackoverflow.com/questions/12710001/how-to-convert-uint8-array-to-base64-encoded-string/12713326#12713326
function arrayToString(array: Uint8Array | Uint16Array) {
    // We can't use String.fromCharCode(...array) as this fails when array is too large.
    // Instead, we do it in chunks then join them.
    const CHUNK_SIZE = 0x8000;
    const chunks = [];
    for (let i = 0; i < array.length; i += CHUNK_SIZE) {
        chunks.push(String.fromCharCode(...array.subarray(i, i + CHUNK_SIZE)));
    }
    return chunks.join("");
}

// convert a Unicode string to a string in which
// each 16-bit unit occupies only one byte
function toBinary(s: string): string {
    const codeUnits = new Uint16Array(s.length);
    for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = s.charCodeAt(i);
    }
    return arrayToString(new Uint8Array(codeUnits.buffer));
}

function fromBinary(binary: string): string {
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return arrayToString(new Uint16Array(bytes.buffer));
}

let b64Encoder: (s: string) => string;
try {
    b64Encoder = btoa;
} catch {
    // We have to support both browsers and Node in the same file.
    // Browsers have btoa; Node has Buffer methods.
    // "binary" is presumed to be the internal string encoding used
    // by btoa in browsers.
    b64Encoder = s => Buffer.from(s, "binary").toString("base64");
}

export const base64Encode = b64Encoder;

export function base64EncodeString(s: string): string {
    return b64Encoder(toBinary(s));
}

let b64Decoder: (s: string) => string;
try {
    b64Decoder = atob;
} catch {
    // We have to support both browsers and Node in the same file.
    // Browsers have atob; Node has Buffer methods.
    // "binary" is presumed to be the internal string encoding used
    // by atob in browsers.
    b64Decoder = s => Buffer.from(s, "base64").toString("binary");
}

export function base64DecodeString(s: string): string | undefined {
    try {
        return fromBinary(b64Decoder(s));
    } catch (e: unknown) {
        logError("Error decoding base64 string", exceptionToString(e));
        return undefined;
    }
}
