import "twin.macro";

export const ActionCostPill: React.FC = () => {
    return (
        <div tw="rounded-full bg-aqua100 flex items-center justify-center text-aqua400 w-[18px] h-[18px]">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.04105 1.56601C8.31367 0.752806 7.28398 0.113384 6.68878 0.763352L2.08795 5.78756C1.6179 6.3009 1.97521 7.13923 2.67915 7.13923H4.89289C4.96812 7.13923 5.02793 7.21467 5.0028 7.29438L4.00824 10.4478C3.75139 11.2623 4.78309 11.8832 5.36961 11.2316L9.9171 6.18003C10.3806 5.66514 10.022 4.83347 9.32127 4.83347H7.10756C7.03153 4.83347 6.97148 4.7564 6.99834 4.67627L8.04105 1.56601Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};
