import { getGenerativeColor } from "@glide/common";
import type { WireChartData, WireChartsComponent } from "@glide/fluent-components/dist/js/base-components";
import { CHART_X_AXIS_DATA_KEY, getValueDataKeys } from "@glide/fluent-components/dist/js/base-components";
import { BarChartType, BarChartWeights, ChartType, UIStyleVariant, ValueChangeSource } from "@glide/wire";
import { assert, defined } from "@glideapps/ts-necessities";
import * as React from "react";
import { css } from "styled-components";
import tw from "twin.macro";

import { Pager } from "../card-collection/card-collection";
import { WireContainer } from "../wire-container/wire-container";
import {
    WireListContainer,
    useDynamicFilter,
    useMultipleDynamicFilters,
    useSearchBar,
} from "../wire-list-container/wire-list-container";
import type { WireRenderer } from "../wire-renderer";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import { useResponsiveSizeClass } from "@glide/common-components";
import { isEmptyOrUndefinedish } from "@glide/support";
import { TextComponentStyle } from "@glide/component-utils";
import { Text } from "../../components/text/text";
import { defaultWireAppAccentContextOverlayUndo, mergeTheme, TailwindThemeProvider } from "@glide/theme";
import { isDarkAppTheme } from "../../utils/is-dark-theme";

const LineCharts = React.lazy(() => import("./line-charts"));
const BarCharts = React.lazy(() => import("./bar-charts"));

export function useChartHeight(nonResponsiveValue: number = 200) {
    const size = useResponsiveSizeClass();

    if (size === undefined) {
        return nonResponsiveValue;
    }

    if (size === "sm") {
        return 300;
    }

    return 450;
}

export const WireCharts: WireRenderer<WireChartsComponent> = React.memo(p => {
    const {
        chartType,
        captions,
        graphTitle,
        chartData,
        barChartType,
        barChartWeight = BarChartWeights.Line,
        showXLabels,
        showYLabels,
        showGridLines,
        showLegend,
        backend,
        searchBar,
        paging,
    } = p;

    assert(paging !== undefined);

    const { numPages, pageIndex } = paging;

    const theme = useWireAppTheme();
    const isDark = isDarkAppTheme(theme);

    const getColor = React.useCallback(
        (i: number): string => {
            return getGenerativeColor(theme.accent, i, isDark);
        },
        [theme.accent, isDark]
    );

    const onPageChange = function (v: any) {
        paging?.pageIndex.onChangeToken &&
            backend.valueChanged(paging.pageIndex.onChangeToken, v, ValueChangeSource.User);
    };

    const dataKeys = React.useMemo(() => {
        if (chartData.length > 0) {
            return getValueDataKeys(chartData[0]);
        } else {
            return [];
        }
    }, [chartData]);

    const getCaptionFromDataKey = React.useCallback(
        (dataKey: string): string => {
            // Practically this will never be undefined.
            return defined(captions[dataKey], "Chart caption missing. This should never happen.");
        },
        [captions]
    );

    // So, we need to tell the charts what's the width of the Y axis labels container.
    // We have arbitrary text in there. This is a kind of ad-hoc solution that will have a few
    // ugly edge cases (like, what about a huge outlier?)
    // What would be a good way of doing this?
    const yAxisWidth = React.useMemo(() => {
        if (!showYLabels) {
            return 0;
        }

        let longest = "";

        for (const dataKey of dataKeys) {
            for (const yData of chartData) {
                const value = yData[dataKey] as number;
                const strValue = Math.round(value).toString();

                if (strValue.length > longest.length) {
                    longest = strValue;
                }
            }
        }

        return (longest.length + 1) * 8;
    }, [chartData, dataKeys, showYLabels]);

    const wrapperCSS = css`
        span,
        tspan {
            ${tw`text-xs text-text-xpale`}
        }
    `;

    const filterArgs = useDynamicFilter(p.dynamicFilter, backend);
    const multipleFilterProps = useMultipleDynamicFilters(p.multipleDynamicFilters, backend);
    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, undefined);

    const height = useChartHeight();
    const mergedTheme = mergeTheme(useWireAppTheme(), [defaultWireAppAccentContextOverlayUndo]);

    const xDataTypeIsDate = chartData.length > 0 && chartData[0][CHART_X_AXIS_DATA_KEY] instanceof Date;

    return (
        <div tw="px-4 pb-4 rounded-xl border border-border-base bg-bg-front gp-sm:px-5 gp-md:px-6" css={wrapperCSS}>
            <WireContainer isInMultipleColumnLayout={true} tw="bg-bg-front">
                {!isEmptyOrUndefinedish(graphTitle) && (
                    <TailwindThemeProvider theme={mergedTheme}>
                        <Text
                            tw="my-3 font-semibold [font-size: 22px]
                        gp-md:([font-size: 23px] tracking-tight)
                        gp-lg:[font-size: 24px]
                        gp-sm:([letter-spacing: -0.01em])"
                            variant={TextComponentStyle.headlineMedium}
                            element="h2">
                            {graphTitle}
                        </Text>
                    </TailwindThemeProvider>
                )}
                <WireListContainer
                    appKind={backend.appKind}
                    titleActions={[]}
                    {...filterArgs}
                    multipleFilterProps={multipleFilterProps}
                    styleVariant={UIStyleVariant.Default}
                    searchValue={searchValue}
                    onSearchChange={onSearchChange}
                    isFirstComponent={false}>
                    <div>
                        {chartType === ChartType.Line && (
                            <React.Suspense fallback={<ChartLoadingFallback height={height} />}>
                                <LineCharts
                                    color={getColor}
                                    getCaptionFromDataKey={getCaptionFromDataKey}
                                    chartData={chartData}
                                    showXLabels={showXLabels ?? true}
                                    showYLabels={showYLabels ?? true}
                                    showGridLines={showGridLines ?? true}
                                    showLegend={showLegend ?? true}
                                    yAxisWidth={yAxisWidth}
                                    dataKeys={dataKeys}
                                    height={height}
                                />
                            </React.Suspense>
                        )}
                        {chartType === ChartType.Bar && (
                            <React.Suspense fallback={<ChartLoadingFallback height={height} />}>
                                <BarCharts
                                    color={getColor}
                                    getCaptionFromDataKey={getCaptionFromDataKey}
                                    chartData={chartData}
                                    chartType={barChartType ?? BarChartType.Single}
                                    chartWeight={barChartWeight}
                                    showXLabels={showXLabels ?? true}
                                    showYLabels={showYLabels ?? true}
                                    showGridLines={showGridLines ?? true}
                                    showLegend={showLegend ?? true}
                                    yAxisWidth={yAxisWidth}
                                    dataKeys={dataKeys}
                                    height={height}
                                />
                            </React.Suspense>
                        )}

                        {showXLabels === true && (!xDataTypeIsDate || chartType !== ChartType.Line) && (
                            <CustomXAxis chartData={chartData} yAxisWidth={yAxisWidth} />
                        )}
                    </div>
                    {numPages > 1 && (
                        <div tw="self-center">
                            <Pager numPages={numPages} page={pageIndex.value} onPageChange={onPageChange} />
                        </div>
                    )}
                </WireListContainer>
            </WireContainer>
        </div>
    );
});

interface CustomXAxisProps {
    chartData: WireChartData[];
    yAxisWidth: number;
}

const CustomXAxis: React.VFC<CustomXAxisProps> = p => {
    const { chartData, yAxisWidth } = p;

    const style: React.CSSProperties = {
        marginLeft: yAxisWidth,
        gridTemplateColumns: `repeat(${chartData.length}, 1fr)`,
    };

    return (
        <div tw="grid px-1 mt-3" style={style}>
            {chartData.map((d, idx) => {
                const label = `${d[CHART_X_AXIS_DATA_KEY]}`;
                return (
                    <span tw="text-center truncate px-0.5" key={idx}>
                        {label}
                    </span>
                );
            })}
        </div>
    );
};

interface ChartLoadingFallbackProps {
    height: number;
}

const ChartLoadingFallback: React.FC<ChartLoadingFallbackProps> = p => {
    const { height } = p;

    return <div style={{ height }} tw="w-full" />;
};
