import { styled } from "@glide/common";
import { css } from "styled-components";

const lineStyle = css`
    :not(:first-child)::after {
        content: "";
        position: absolute;
        left: -20px;
        right: -20px;
        height: 1px;
        top: -16px;
        background: ${p => p.theme.borderColorLight};
    }
`;

export const SectionWrapper = styled.div<{ withTopMargin: boolean; withBottomMargin: boolean; drawSeparator: boolean }>`
    position: relative;

    margin-top: ${p => (p.withTopMargin ? 32 : 4)}px;
    margin-bottom: ${p => (p.withBottomMargin ? 32 : 0)}px;

    &:last-child {
        margin-bottom: 0;
    }

    ${p => p.drawSeparator && p.withTopMargin && lineStyle}

    &.tight {
        margin-top: ${p => (p.withTopMargin ? 24 : 12)}px;
        margin-bottom: ${p => (p.withBottomMargin ? 24 : 6)}px;
    }

    &.very-tight {
        margin-top: ${p => (p.withTopMargin ? 12 : 4)}px;
        margin-bottom: ${p => (p.withBottomMargin ? 12 : 0)}px;
    }

    &.no-margin {
        margin-top: 0px;
        margin-bottom: 0px;
    }
`;

export const SectionTitle = styled.div<{ elideMargin?: boolean; tightMargins?: boolean; topMargin?: boolean }>`
    ${p => p.elideMargin !== true && `margin-bottom: ${p.tightMargins === true ? 6 : 12}px`};

    ${p => (p.topMargin ? "margin-top: 8px;" : "")}

    height: 16px;
    font-weight: 600;
    font-size: 11px;
    line-height: 120%;
    text-transform: uppercase;
    color: ${p => p.theme.textDark};

    display: flex;
    align-items: center;

    &.indent {
        padding-left: 12px;
    }

    .caret {
        position: relative;
        margin-right: 6px;
        cursor: pointer;
    }

    .section-title {
        color: ${p => p.theme.fgColorDark};
    }
`;

export const SectionSubtitle: React.VFC<{ children: React.ReactNode }> = ({ children }) => {
    return <div tw="-mt-1 mb-2 text-text-pale text-builder-sm">{children}</div>;
};
