import type { ColumnType, TableGlideType, SchemaInspector } from "@glide/type-schema";
import type { MutatingScreenKind } from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core";
import type { PriorStep } from "./prior-step";

/**
 * @returns The type or an error message
 */
export type TypeForActionNodeOutputGetter = (
    nodeKey: string,
    outputName: string,
    columnInRow: string | undefined
) => ColumnType | string;

interface StaticContextBase<TContext extends SchemaInspector> {
    readonly context: TContext;
    readonly priorSteps?: readonly PriorStep[];
    // Right now we're only using this to determine whether to allow referring
    // to user profile columns.
    readonly isAutomation: boolean;
}

export interface StaticComputationContext<TContext extends SchemaInspector> extends StaticContextBase<TContext> {
    readonly table: TableGlideType | undefined;
}

export interface StaticActionContext<TContext extends SchemaInspector> extends StaticContextBase<TContext> {
    readonly tables: InputOutputTables | undefined;
    readonly mutatingScreenKind: MutatingScreenKind | undefined;
}

export function makeTypeForActionNodeOutputGetterFromStaticContext(
    env: Pick<StaticContextBase<SchemaInspector>, "priorSteps">
): TypeForActionNodeOutputGetter {
    return (nodeKey: string, outputName: string, columnInRow: string | undefined) => {
        for (const priorStep of env.priorSteps ?? []) {
            if (priorStep.node.node.key !== nodeKey) continue;

            for (const output of priorStep.outputs) {
                if (output.name !== outputName) continue;
                // This also works if `columnInRow` is `undefined`, because in
                // that case `output.columnName` also has to be `undefined`.
                if (output.columnName !== columnInRow) continue;

                return output.type;
            }
        }

        return "The computation refers to the output of a step that doesn't exist";
    };
}
