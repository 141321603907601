import * as t from "io-ts";
import type { TypeOfIOTS } from "@glide/support";
import { makeUncheckedStringCodec } from "@glide/support";

export const strokeIconValues = [
    "st-signature",
    "st-go-back",
    "st-condition",
    "st-clock-wait",
    "st-data",
    "st-generate-csv",
    "st-add-set",
    "st-array-set",
    "st-arrows-loop",
    "st-clock-snooze",
    "st-close-circle",
    "st-empty-set",
    "st-form-screen",
    "st-navigation",
    "st-new-screen",
    "st-notification",
    "st-open-address",
    "st-open-link",
    "st-paper-plane-top-right",
    "st-pencil",
    "st-play-sound",
    "st-scan-barcode",
    "st-set-contains",
    "st-set-size",
    "st-show-details",
    "st-sign-in",
    "st-user-profile",
    "st-workflow-advanced",
    "st-workflow-delete-row",
    "st-workflow-set-columns",
    "st-action-bolt",
    "st-action-bolt-detached",
    "st-column-action",
    "st-address-book",
    "st-alert-warning",
    "st-apps",
    "st-arrow-down",
    "st-arrow-external",
    "st-arrow-full",
    "st-arrow-inspect",
    "st-arrow-reload",
    "st-arrow-right",
    "st-arrow-sync",
    "st-arrow-up",
    "st-arrow-up-right",
    "st-arrows-expand",
    "st-at",
    "st-bap",
    "st-bird-house",
    "st-blog-content",
    "st-book",
    "st-briefcase",
    "st-buildings-business",
    "st-burger",
    "st-business-plan",
    "st-business-team",
    "st-calendar",
    "st-caret",
    "st-chart",
    "st-chat",
    "st-chart-up",
    "st-check-circle",
    "st-check-task",
    "st-check",
    "st-checkbox",
    "st-checkmark",
    "st-chevron-down",
    "st-chevron-left",
    "st-chevron-right",
    "st-chevron-selector",
    "st-chevron-up",
    "st-chevrons-vertical",
    "st-circle",
    "st-circle-strike",
    "st-clipboard",
    "st-clock",
    "st-close",
    "st-components",
    "st-component-favorite",
    "st-compose-mail",
    "st-conditional-flow",
    "st-connect",
    "st-copy",
    "st-credit-card",
    "st-custom-input",
    "st-data-editor",
    "st-data",
    "st-database-server",
    "st-design-brush",
    "st-desktop",
    "st-diagram",
    "st-dial",
    "st-disconnected",
    "st-download",
    "st-drag",
    "st-drag2",
    "st-duplicate",
    "st-edit-browser",
    "st-edit-content",
    "st-edit-notebook",
    "st-edit-pencil",
    "st-else-flow",
    "st-email-table",
    "st-emoji-happy",
    "st-emoji-hmm",
    "st-emoji-ok",
    "st-emoji-sad",
    "st-enterprise-team",
    "st-eye",
    "st-feature",
    "st-filter",
    "st-filter-2",
    "st-flag",
    "st-folder",
    "st-form-factor-desktop",
    "st-form-factor-phone",
    "st-form-factor-tablet",
    "st-four-circles",
    "st-gague",
    "st-glide-symbol",
    "st-globe",
    "st-grid",
    "st-guide-book",
    "st-half-spinner",
    "st-heart",
    "st-help-donut",
    "st-help",
    "st-house",
    "st-html",
    "st-image",
    "st-info-circle",
    "st-integrations",
    "st-ios-share",
    "st-telephone",
    "st-text-fmt-blockquote",
    "st-text-fmt-bold",
    "st-text-fmt-bulletList",
    "st-text-fmt-caption",
    "st-text-fmt-h1",
    "st-text-fmt-h2",
    "st-text-fmt-highlight",
    "st-text-fmt-hr",
    "st-text-fmt-italic",
    "st-text-fmt-italics-cursor",
    "st-text-fmt-orderedList",
    "st-text-fmt-paragraph",
    "st-json",
    "st-language-website",
    "st-laptop-remote",
    "st-light-bulb",
    "st-link",
    "st-list",
    "st-locked",
    "st-login",
    "st-luggage",
    "st-mail-opened",
    "st-mail",
    "st-menu-checkmark",
    "st-message-circle",
    "st-message-circle-workflows",
    "st-message-square-chat",
    "st-message-square-remove",
    "st-message-square",
    "st-mobile",
    "st-microphone",
    "st-more",
    "st-moon",
    "st-network-pin",
    "st-notebook",
    "st-null",
    "st-offline",
    "st-open-external",
    "st-page",
    "st-pages",
    "st-password",
    "st-pause",
    "st-pdf-file",
    "st-people",
    "st-personal-team", // do not use 💣
    "st-pdf-file",
    "st-pin",
    "st-play",
    "st-plus-add",
    "st-plus-stroke",
    "st-pro-team",
    "st-products",
    "st-pricing-business",
    "st-profile",
    "st-profile-no",
    "st-profile-optional",
    "st-project-app",
    "st-project-page",
    "st-radio",
    "st-redo",
    "st-reload-arrow",
    "st-reload",
    "st-research-lab",
    "st-search",
    "st-send",
    "st-settings",
    "st-shuffle",
    "st-sound",
    "st-ios-share",
    "st-scan",
    "st-share",
    "st-shield-security",
    "st-single-man-flag",
    "st-small-grid",
    "st-snorkle",
    "st-sort",
    "st-spinner",
    "st-stairs",
    "st-star-4",
    "st-star-5",
    "st-starter-team",
    "st-success-check",
    "st-sun",
    "st-sync-auto",
    "st-sync",
    "st-tablet",
    "st-templates",
    "st-terminal",
    "st-test-team", // do not use 💣
    "st-tool",
    "st-trash",
    "st-trolley-cart",
    "st-undo",
    "st-unlocked",
    "st-upload",
    "st-user",
    "st-users",
    "st-verified",
    "st-video",
    "st-wellbeing-peace",
    "st-address-book",
    "st-rocket",
    "st-truck",
    "st-topbar-layout",
    "st-topbar-layout-mobile",
    "st-topbar-layout-desktop",
    "st-topbar-settings",
    "st-topbar-actions",
    "st-sso",
    "st-workflow",
    "st-compose-email",
    "st-exposure",
    // 'as const' is needed because without it, the type definition would just
    // be a string instead of the string literals
] as const;

export type StrokeIcons = (typeof strokeIconValues)[number];

const strokeIconValuesCodec = makeUncheckedStringCodec<StrokeIcons>();

const twoToneIconValues = [
    "tt-activation-complete",
    "tt-activation-current",
    "tt-glide-table",
    // note, the plural is the data source icon that looks like the DB,
    // the singular is the table icon has the sheet "T" in it
    "tt-glide-big-table",
    "tt-glide-big-tables",
    "tt-large-play",
    "tt-containing-screen-context",
    "tt-containing-screen-overlay",
    "tt-edit-pencil",
    "tt-header-string",
    "tt-file-upload",
    "tt-player-camera",
    "tt-glide-circle",
    "tt-excel-sheet",
    "tt-google-sheet",
    "tt-pages-crm",
    "tt-pages-delivery",
    "tt-pages-field-sales",
    "tt-pages-inventory",
    "tt-pages-property-management",
    "tt-user-table",
] as const;

export type TwotoneIcons = (typeof twoToneIconValues)[number];

const twoToneIconValuesCodec = makeUncheckedStringCodec<TwotoneIcons>();

export const monotoneIconValues = [
    "mt-activation-not-complete",
    "mt-add-new-small",
    "mt-ai",
    "mt-microphone",
    "mt-airtable-sheet-small",
    "mt-airtable-sheet",
    "mt-android",
    "mt-apple",
    "mt-apps",
    "mt-avatar-empty",
    "mt-bar-chart",
    "mt-bigquery",
    "mt-border-3by2",
    "mt-border-circle",
    "mt-border-square",
    "mt-briefcase",
    "mt-carret-down",
    "mt-carret-left",
    "mt-carret-right",
    "mt-carret-top",
    "mt-center-gravity",
    "mt-chat",
    "mt-checkbox-checked",
    "mt-chevron-left",
    "mt-chevron-left",
    "mt-chevron-right",
    "mt-chevron-right",
    "mt-circular-cross",
    "mt-clear",
    "mt-columns",
    "mt-column-ai",
    "mt-column-audio",
    "mt-column-boolean",
    "mt-column-choice",
    "mt-column-document",
    "mt-column-generate-text",
    "mt-column-image",
    "mt-column-json",
    "mt-column-number",
    "mt-column-text-to-date",
    "mt-header-boolean",
    "mt-header-string",
    "mt-header-row-id",
    "mt-header-date",
    "mt-header-globe",
    "mt-header-code",
    "mt-header-json",
    "mt-header-workflow",
    "mt-header-integration",
    "mt-header-lookup",
    "mt-header-number",
    "mt-header-duration",
    "mt-header-audio-uri",
    "mt-header-video-uri",
    "mt-header-emoji",
    "mt-header-generate-image",
    "mt-header-image",
    "mt-header-uri",
    "mt-header-phone",
    "mt-header-markdown",
    "mt-header-time",
    "mt-header-email",
    "mt-header-unique-id",
    "mt-header-reference",
    "mt-header-filter-sort-limit",
    "mt-header-if-then-else",
    "mt-header-single-value",
    "mt-header-text-template",
    "mt-header-math",
    "mt-header-rollup",
    "mt-header-join-strings",
    "mt-header-split-string",
    "mt-header-geo-distance",
    "mt-header-make-array",
    "mt-header-multiple-images",
    "mt-header-multiple-files",
    "mt-header-multiple-strings",
    "mt-header-array",
    "mt-component-big-numbers",
    "mt-component-breadcrumbs",
    "mt-component-button-bar",
    "mt-component-button",
    "mt-component-calendar",
    "mt-component-checklist",
    "mt-component-collection-article",
    "mt-component-collection-cards",
    "mt-component-collection-grid",
    "mt-component-collection-list",
    "mt-component-collection-table",
    "mt-component-collection",
    "mt-component-container",
    "mt-component-custom",
    "mt-component-custom-collection",
    "mt-component-data-grid",
    "mt-component-favorite",
    "mt-component-fields",
    "mt-component-hint",
    "mt-component-image",
    "mt-component-map",
    "mt-component-rich-text",
    "mt-component-separator",
    "mt-component-video",
    "mt-container-width-md",
    "mt-container-width-sm",
    "mt-container-width-xs",
    "mt-cursor-move",
    "mt-cursor",
    "mt-donut-chart-reverse",
    "mt-donut-chart",
    "mt-dots-horizontal",
    "mt-drag",
    "mt-duplicate",
    "mt-edit",
    "mt-enum-choice-chips",
    "mt-enum-choice-radio",
    "mt-excel",
    "mt-expert-badge",
    "mt-eye-hide",
    "mt-eye",
    "mt-face-gravity",
    "mt-filter",
    "mt-folder",
    "mt-g-logo",
    "mt-g-sheet",
    "mt-glide-app-icon",
    "mt-glide-logo-new",
    "mt-glide-logo",
    "mt-glide-page-icon",
    "mt-glide-table-fixed-color",
    "mt-info-alert",
    "mt-info-circle-filled",
    "mt-info-circle",
    "mt-info",
    "mt-lock-open",
    "mt-lock",
    "mt-loop-link-create",
    "mt-loop-link-wait",
    "mt-menu",
    "mt-more-horizontal",
    "mt-more-vertical",
    "mt-no-color-g-sheet",
    "mt-page-calendar",
    "mt-page-camera-upload",
    "mt-page-file-upload",
    "mt-page-image-upload",
    "mt-pages-blank",
    "mt-pages-directory",
    "mt-pages-form",
    "mt-pages-portal",
    "mt-pages",
    "mt-pie-chart",
    "mt-pin",
    "mt-platform-android",
    "mt-platform-ios",
    "mt-play",
    "mt-play",
    "mt-project-app",
    "mt-project-page",
    "mt-radio-selected",
    "mt-random-sort",
    "mt-rec",
    "mt-reorder",
    "mt-replace",
    "mt-resize-h",
    "mt-send",
    "mt-settings",
    "mt-sql-table",
    "mt-st-chart",
    "mt-st-message-circle",
    "mt-st-components",
    "mt-st-edit-notebook",
    "mt-st-stairs",
    "mt-stacked-bar-chart",
    "mt-star-empty",
    "mt-star",
    "mt-stop",
    "mt-symbol",
    "mt-text-area",
    "mt-topbar-actions",
    "mt-topbar-data",
    "mt-topbar-layout-desktop",
    "mt-topbar-layout-mobile",
    "mt-topbar-layout-page",
    "mt-topbar-layout-tablet",
    "mt-user-profile",
    "mt-user",
    "mt-users",
    "mt-vertical-triple-dot",
    "mt-warning",
    "mt-location-on",
    "mt-location-off",
    "mt-page-title-cover",
    "mt-page-title-image",
    "mt-page-title-profile",
    "mt-page-title-simple",
] as const;

export type MonotoneIcons = (typeof monotoneIconValues)[number];

const monotoneIconValuesCodec = makeUncheckedStringCodec<MonotoneIcons>();

const colorIconValues = [
    "clr-airtable",
    "clr-bigquery",
    "clr-csv",
    "clr-excel",
    "clr-glide",
    "clr-google-cloud-sql",
    "clr-google",
    "clr-gsheet",
    "clr-hubspot",
    "clr-microsoft",
    "clr-mysql",
    "clr-oracle",
    "clr-postgresql",
    "clr-sap",
    "clr-sqlserver",
    "clr-template-basic",
    "clr-template-blank",
    "clr-template-form",
    "clr-template-portal",
] as const;

export type ColorIcons = (typeof colorIconValues)[number];

const colorIconValuesCodec = makeUncheckedStringCodec<ColorIcons>();

export const componentIconValues = [
    "co-action-row",
    "co-action-text",
    "co-basic-table",
    "co-basic-text",
    "co-button-bar",
    "co-button",
    "co-buy-button",
    "co-charts",
    "co-checkbox",
    "co-choice",
    "co-tag",
    "co-column",
    "co-comments",
    "co-contact",
    "co-date-picker",
    "co-date-time",
    "co-date",
    "co-email-entry",
    "co-email",
    "co-favorite",
    "co-file-picker",
    "co-floating-button",
    "co-form-button",
    "co-hint-text",
    "co-image-picker",
    "co-image",
    "co-inline-list",
    "co-link",
    "co-list-relation",
    "co-location",
    "co-map",
    "co-notes",
    "co-number-entry",
    "co-phone-entry",
    "co-phone",
    "co-progress-bar",
    "co-relation",
    "co-rich-text",
    "co-separator",
    "co-signature",
    "co-stopwatch",
    "co-switch",
    "co-text-entry",
    "co-title",
    "co-unique-id",
    "co-video",
    "co-web-view",
    "co-rating",
] as const;

export type ComponentIcons = (typeof componentIconValues)[number];

const componentIconValuesCodec = makeUncheckedStringCodec<ComponentIcons>();

export type AnyIcon = StrokeIcons | TwotoneIcons | MonotoneIcons | ComponentIcons | ColorIcons;
export type IconKinds = "stroke" | "twotone" | "monotone" | "component" | "color";

const baseIconPropsCodec = t.partial({
    iconSize: t.number,
    rotateDeg: t.number,
    spin: t.boolean,
});

const componentPropsCodec = t.intersection([
    baseIconPropsCodec,
    t.intersection([
        t.type({
            kind: t.literal("component"),
            icon: componentIconValuesCodec,
        }),
        t.partial({
            fgColor: t.string,
            bgColor: t.string,
        }),
    ]),
]);

export type ComponentProps = t.TypeOf<typeof componentPropsCodec>;

const monotonePropsCodec = t.intersection([
    baseIconPropsCodec,
    t.intersection([
        t.type({
            kind: t.literal("monotone"),
            icon: monotoneIconValuesCodec,
        }),
        t.partial({
            fgColor: t.string,
            bgColor: t.string,
        }),
    ]),
]);

const twotonePropsCodec = t.intersection([
    baseIconPropsCodec,
    t.intersection([
        t.type({
            kind: t.literal("twotone"),
            icon: twoToneIconValuesCodec,
        }),
        t.partial({
            primaryColor: t.string,
            accentColor: t.string,
        }),
    ]),
]);

const strokePropsCodec = t.intersection([
    baseIconPropsCodec,
    t.intersection([
        t.type({
            kind: t.literal("stroke"),
            icon: strokeIconValuesCodec,
        }),
        t.partial({
            strokeColor: t.string,
            strokeFgColor: t.string,
            strokeWidth: t.number,
        }),
    ]),
]);

const colorPropsCodec = t.intersection([
    baseIconPropsCodec,
    t.type({
        kind: t.literal("color"),
        icon: colorIconValuesCodec,
    }),
]);

const stylePropsCodec = t.union([
    monotonePropsCodec,
    twotonePropsCodec,
    strokePropsCodec,
    componentPropsCodec,
    colorPropsCodec,
]);

export const glideIconPropsCodec = t.intersection([
    stylePropsCodec,
    t.partial({
        className: t.string,
        hideStroke: t.boolean,
        borderRadiusWithoutStroke: t.boolean,
    }),
]);
export type GlideIconProps = TypeOfIOTS<typeof glideIconPropsCodec>;
