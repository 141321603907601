import { AppKind } from "@glide/location-common";
import type {
    ComponentDescription,
    ComponentKind,
    MutatingScreenKind,
    PropertyDescription,
} from "@glide/app-description";
import { makeColumnProperty, makeEnumProperty, makeStringProperty } from "@glide/app-description";
import { type TableColumn, getTableColumnDisplayName, isPrimitiveArrayType, isPrimitiveType } from "@glide/type-schema";
import { type InputOutputTables, makeEmptyComponentDescription } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import { ImagePickerSourceHint } from "@glide/component-utils";
import {
    type AppDescriptionContext,
    type ComponentDescriptor,
    type EnumPropertyCase,
    type PropertyDescriptor,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    EnumPropertyHandler,
    PropertySection,
    imageProperties,
    isImageType,
    isRequiredPropertyHandler,
    getPrimitiveOrPrimitiveArrayNonHiddenColumnsSpec,
} from "@glide/function-utils";
import { assert } from "@glideapps/ts-necessities";
import { type WireRowComponentHydratorConstructor, WireComponentKind, type WireInflationBackend } from "@glide/wire";
import {
    doesMutatingScreenSupportIsRequired,
    labelCaptionStringOptions,
    makeCaptionStringPropertyDescriptor,
    makeDefaultValuePropertyDescriptor,
} from "./descriptor-utils";
import { type FilePickerComponentDescription, inflateFileOrImagePicker } from "./file-picker";
import { ComponentHandlerBase } from "./handler";

export const ComponentKindImagePicker: ComponentKind = "image-picker";

const propertyNamePropertyHandler = new ColumnPropertyHandler(
    "propertyName",
    "Column",
    [
        ColumnPropertyFlag.Required,
        ColumnPropertyFlag.Editable,
        ColumnPropertyFlag.EditedInApp,
        ColumnPropertyFlag.DefaultCaption,
        ColumnPropertyFlag.AllowUserProfileColumns,
    ],
    undefined,
    imageProperties,
    getPrimitiveOrPrimitiveArrayNonHiddenColumnsSpec,
    t => isImageType(t, false, true),
    PropertySection.Data
);

const allSources: EnumPropertyCase<ImagePickerSourceHint>[] = [
    {
        value: ImagePickerSourceHint.Any,
        label: "Camera and Photo Roll",
    },
    {
        value: ImagePickerSourceHint.CameraOnly,
        label: "Camera Only",
    },
];

const cameraHintPropertyHandler = new EnumPropertyHandler(
    { cameraHint: ImagePickerSourceHint.Any },
    "Upload From",
    "Upload From",
    allSources,
    {
        name: "Upload From",
        order: 1000,
    },
    "radio"
);

interface ImagePickerComponentDescription extends FilePickerComponentDescription {
    readonly cameraHint: PropertyDescription | undefined;
}

export class ImagePickerComponentHandler extends ComponentHandlerBase<ImagePickerComponentDescription> {
    constructor() {
        super(ComponentKindImagePicker);
    }

    public getIsEditor(): boolean {
        return true;
    }

    public get appKinds(): AppKind | "both" {
        return "both";
    }

    public needValidation(): boolean {
        return true;
    }

    public getDescriptor(
        desc: ImagePickerComponentDescription | undefined,
        _tables: InputOutputTables | undefined,
        ccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ComponentDescriptor {
        const properties: PropertyDescriptor[] = [
            propertyNamePropertyHandler,
            makeCaptionStringPropertyDescriptor("Image", false, mutatingScreenKind, labelCaptionStringOptions),
        ];
        if (doesMutatingScreenSupportIsRequired(mutatingScreenKind, desc?.propertyName)) {
            properties.push(isRequiredPropertyHandler);
        }

        if (ccc.appKind === AppKind.App) {
            const defaultDescr = makeDefaultValuePropertyDescriptor(
                ccc,
                desc?.propertyName,
                mutatingScreenKind,
                "image-uri"
            );
            if (defaultDescr !== undefined) {
                properties.push(defaultDescr);
            }
        }

        properties.push(cameraHintPropertyHandler);

        properties.push(...this.getBasePropertyDescriptors());
        return {
            name: "Image Picker",
            description: "Upload an image",
            img: "co-image-picker",
            group: ccc.appKind === AppKind.Page ? "Form Elements" : "Pickers",
            helpUrl: getDocURL("imagePicker"),
            properties,
        };
    }

    public inflate(
        ib: WireInflationBackend,
        desc: ImagePickerComponentDescription
    ): WireRowComponentHydratorConstructor | undefined {
        const sourceHint = cameraHintPropertyHandler.getEnum(desc);

        return inflateFileOrImagePicker(ib, desc, p => ({
            ...p,
            kind: WireComponentKind.ImagePicker,
            sourceHint,
        }));
    }

    public convertToPage(
        desc: ImagePickerComponentDescription,
        _ccc: AppDescriptionContext
    ): ComponentDescription | undefined {
        return desc; // these are identical in classic apps and pages
    }

    public static defaultComponent(column: TableColumn): ImagePickerComponentDescription {
        assert(isPrimitiveType(column.type) || isPrimitiveArrayType(column.type));
        return {
            ...makeEmptyComponentDescription(ComponentKindImagePicker),
            propertyName: makeColumnProperty(column.name),
            defaultValue: undefined,
            caption: makeStringProperty(getTableColumnDisplayName(column)),
            visibilityFilters: [],
            cameraHint: makeEnumProperty(ImagePickerSourceHint.Any),
        };
    }
}
